import { ObjectAssignment } from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../detail-complete.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentDetailCompleteCommercialSpecificationComponentProps {
  assignment: ObjectAssignment;
  renderSection: (section: { title: string; data: Array<any> }) => void;
}
interface State {}
type Props = AssignmentDetailCompleteCommercialSpecificationComponentProps;
export const AssignmentDetailCompleteCommercialSpecificationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignment, renderSection }) => {
      // Render
      return (
        <>
          {renderSection({
            title: "featuresAndFigures",
            data: [
              {
                type: "frontWidth",
                prefix: "frontWidth",
                values: [
                  get(assignment, "frontWidth")
                    ? get(assignment, "frontWidth") + " m2"
                    : null,
                ],
              },
              {
                type: "inUnitsFrom",
                prefix: "inUnitsFrom",
                values: [
                  get(assignment.inUnitsFrom, "area")
                    ? get(assignment.inUnitsFrom, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "vVO",
                prefix: "vVO",
                values: [
                  get(assignment.vVO, "area")
                    ? get(assignment.vVO, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "wvo",
                prefix: "wvo",
                values: [
                  get(assignment.wvo, "area")
                    ? get(assignment.wvo, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "publicOrientedServices",
                prefix: "publicOrientedServices",
                values: [get(assignment, "publicOrientedServices")],
              },
              {
                type: "showroom",
                prefix: "showroom",
                values: [get(assignment, "showroom")],
              },
              {
                type: "sectorLimitation",
                prefix: "sectorLimitation",
                values: [get(assignment, "sectorLimitation")],
              },
              {
                type: "retail",
                prefix: "retail",
                values: [get(assignment, "retail")],
              },
              {
                type: "hospitalityPermitted",
                prefix: "hospitalityPermitted",
                values: [get(assignment, "hospitalityPermitted")],
              },
              {
                type: "welfareState",
                prefix: "welfareState",
                values: [get(assignment, "welfareState")],
              },
              {
                type: "typesOfCommunalArea",
                prefix: "typesOfCommunalArea",
                values: get(assignment, "typesOfCommunalArea", [null]),
              },
            ],
          })}
          {renderSection({
            title: "energyAndCosts",
            data: [
              {
                type: "energyLabel",
                prefix: "energyClassOptions",
                values: [get(assignment.energyLabel, "energyClass")],
              },
              {
                type: "energyIndex",
                prefix: "energyClassOptions",
                values: [get(assignment.energyLabel, "energyIndex")],
              },
              {
                type: "selectIsolationType",
                prefix: "isolationTypeOptions",
                values: get(assignment, "insulationTypes", [null]),
              },
              {
                type: "warmWater",
                prefix: "heatingWaterMethodOptions",
                values: get(assignment, "warmWaterTypes", [null]),
              },
              {
                type: "heatingLabelv2",
                prefix: "heatingMethodOptions",
                values: get(assignment, "heating", [null]),
              },
            ],
          })}

          {renderSection({
            title: "maintenance",
            data: [
              {
                type: "maintenanceInside",
                prefix: "conditionOptions",
                values: [get(assignment.maintenanceInside, "rating")],
              },
              {
                type: "maintenanceOutside",
                prefix: "conditionOptions",
                values: [get(assignment.maintenanceOutside, "rating")],
              },
            ],
          })}
        </>
      );
    }
  )
);
