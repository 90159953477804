import { ActionType, ActionTypes } from "./actions";

export type EntityListStatusState = {
  assignments: boolean;
  acquisitions: boolean;
  tasks: boolean;
  relations: boolean;
  searchAssignments: boolean;
  invoices: boolean;
  mls: boolean;
};

const INITIAL: EntityListStatusState = {
  assignments: false,
  acquisitions: false,
  tasks: false,
  relations: false,
  searchAssignments: false,
  invoices: false,
  mls: false,
};

export const entityListStatusReducer = (
  state = INITIAL,
  action: ActionTypes
): EntityListStatusState => {
  switch (action.type) {
    case ActionType.ClearListStatus: {
      const { key } = action;

      return {
        ...state,
        [key]: false,
      };
    }
    case ActionType.SetListStatus: {
      const { key } = action;

      return {
        ...state,
        [key]: true,
      };
    }
    default: {
      return state;
    }
  }
};
