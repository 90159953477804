import {
  AssignmentSnapShot,
  AssignmentType,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import I18n from "@haywork/components/i18n";
import AssignmentInfo from "@haywork/components/ui/assignment-info";
import Icon from "@haywork/components/ui/icon";
import RelationInfo from "@haywork/components/ui/relation-info";
import { Colors } from "@haywork/enum/colors";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  type: "relation" | "assignment";
  item: any;
  onClicked: (item: any) => void;
  onMouseDown: () => void;
  emailCallback?: (relation: RelationSnapShot) => void;
  idx: number;
}
export const FormSuggestionResultComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, type, onClicked, onMouseDown, emailCallback, idx }: Props) => {
      const [hover, toggleHover] = useState(false);
      const contextMenuParentRef = useRef<HTMLDivElement>();
      const contextMenuParentRef1 = useRef<HTMLDivElement>();
      const timeoutRef = useRef<any>(null);

      const saleRentLabel = () => {
        const objectType = () => {
          switch (type === "assignment" && item.typeOfAssignment) {
            case AssignmentType.Acquisition:
            case AssignmentType.AcquisitionObject:
              return "acquisition";
            default:
              return "object";
          }
        };

        switch (true) {
          case item.forRent && item.forSale:
            return `${objectType()}.forSaleAndForRentShort`;
          case item.forSale:
            return `${objectType()}.forSaleShort`;
          case item.forRent:
            return `${objectType()}.forRentShort`;
          default:
            break;
        }
      };

      const assignment = useMemo(
        () =>
          type === "assignment" ? (item as AssignmentSnapShot) : undefined,
        [item, type]
      );

      const mouseEnterCallback = useCallback(() => {
        if (!!timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        toggleHover(true);
      }, [toggleHover]);

      const mouseLeaveCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          toggleHover(false);
        }, 50);
      }, [toggleHover]);

      return (
        <div
          styleName="item"
          onClick={() => onClicked(item)}
          onMouseDown={onMouseDown}
        >
          <div styleName="name">{item.displayName}</div>
          {(item.forRent || item.forSale) && (
            <div styleName="extraInfo">
              <I18n value={saleRentLabel()} />
            </div>
          )}

          {!item.isActive && (
            <div styleName="archived">
              <I18n value="archived" />
            </div>
          )}

          <FeatureSwitch feature="RELATION_INFO">
            <div
              styleName="context-menu-trigger"
              onMouseEnter={mouseEnterCallback}
              onMouseLeave={mouseLeaveCallback}
              ref={contextMenuParentRef}
            >
              <Icon name="info-circle" color={Colors.Primary} size={14} />
            </div>

            <ContextMenu
              visible={hover}
              parent={contextMenuParentRef}
              width={500}
              position={ContextMenuPosition.TopRight}
            >
              <div
                styleName="context-menu"
                onMouseEnter={mouseEnterCallback}
                onMouseLeave={mouseLeaveCallback}
              >
                {type === "relation" && (
                  <RelationInfo
                    item={item}
                    visible={hover}
                    emailCallback={emailCallback ? emailCallback : undefined}
                  />
                )}
                {type === "assignment" && (
                  <AssignmentInfo
                    item={assignment}
                    visible={hover}
                    emailCallback={emailCallback ? emailCallback : undefined}
                  />
                )}
              </div>
            </ContextMenu>
          </FeatureSwitch>
        </div>
      );
    }
  )
);
