import {
  AgeRangeOption,
  AlvAgriculturalFunctionOption,
  AlvContinuationOption,
  AlvNonAgriculturalFunctionOption,
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  BuyerTypeOption,
  ChildAgeRangeOption,
  FamilyIncomeOption,
  HouseCharacteristicOption,
  HouseholdCompositionOption,
  HouseSort,
  HouseSortOption,
  HouseTypeOption,
  ListingTypeOption,
  MovingReasonOption,
  ObjectAssignment,
  PublicationSnapShot,
  SaleConditionOption,
  TypeOfHabitationOption,
  UpdateAvailabilityRentedSettings,
  UpdateAvailabilitySoldSettings,
} from "@haywork/api/kolibri";
import {
  AssignmentThunks,
  MapDispatchToProps,
  PublicationsThunk,
  TransactionMetaDataResponse,
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import {
  buyerTypes,
  houseCharacteristics,
  listingTypes,
  saleConditions,
} from "./selectors";
import {
  TransactionModalComponent,
  TransactionModalComponentProps,
} from "./transaction-modal.component";

interface StateProps {
  saleConditions: SaleConditionOption[];
  listingTypes: ListingTypeOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  buyerTypeOptions: BuyerTypeOption[];
  movingReasonOptions: MovingReasonOption[];
  householdCompositionOptions: HouseholdCompositionOption[];
  ageRangeOptions: AgeRangeOption[];
  childAgeRangeOptions: ChildAgeRangeOption[];
  familyIncomeOptions: FamilyIncomeOption[];
  typeOfHabitationOptions: TypeOfHabitationOption[];
  alvContinuationOptions: AlvContinuationOption[];
  alvAgriculturalFunctionOptions: AlvAgriculturalFunctionOption[];
  alvNonAgriculturalFunctionOptions: AlvNonAgriculturalFunctionOption[];
}
interface DispatchProps {
  transitionObjectAssignment: (
    assignment: ObjectAssignment,
    data: TransactionMetaDataResponse,
    soldSettings?: UpdateAvailabilitySoldSettings,
    rentedSettings?: UpdateAvailabilityRentedSettings
  ) => Promise<void>;
  getPublications: (assignmentId: string) => Promise<PublicationSnapShot[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  TransactionModalComponentProps,
  AppState
> = (state, ownProps) => {
  const {
    houseSorts,
    houseTypes,
    apartmentSorts,
    apartmentCharacteristics,
    movingReasonOptions,
    householdCompositionOptions,
    ageRangeOptions,
    childAgeRangeOptions,
    familyIncomeOptions,
    typeOfHabitationOptions,
    alvContinuationOptions,
    alvAgriculturalFunctionOptions,
    alvNonAgriculturalFunctionOptions,
  } = state.main.mastertable.kolibri;

  return {
    saleConditions: saleConditions(state),
    listingTypes: listingTypes(state),
    houseSorts,
    houseTypes,
    houseCharacteristics: houseCharacteristics(HouseSort.Bungalow)(state),
    apartmentSorts,
    apartmentCharacteristics,
    buyerTypeOptions: buyerTypes(get(ownProps, "assignment.realEstateGroup"))(
      state
    ),
    movingReasonOptions,
    householdCompositionOptions,
    ageRangeOptions,
    childAgeRangeOptions,
    familyIncomeOptions,
    typeOfHabitationOptions,
    alvContinuationOptions,
    alvAgriculturalFunctionOptions,
    alvNonAgriculturalFunctionOptions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  TransactionModalComponentProps
> = (dispatch) => ({
  transitionObjectAssignment: (
    assignment: ObjectAssignment,
    data: TransactionMetaDataResponse,
    soldSettings?: UpdateAvailabilitySoldSettings,
    rentedSettings?: UpdateAvailabilityRentedSettings
  ) =>
    dispatch(
      AssignmentThunks.transitionObjectAssignment(
        assignment,
        data,
        soldSettings,
        rentedSettings
      )
    ),
  getPublications: (assignmentId: string) =>
    dispatch(PublicationsThunk.getPublications(assignmentId)),
});

export type TransactionModalContainerProps = StateProps & DispatchProps;
export const TransactionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionModalComponent);
