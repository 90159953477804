import { FolderCategory } from "@haywork/api/mail";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { EmailFolder } from "@haywork/stores/email-v2";
import * as React from "react";
import { FC, memo, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  folder: EmailFolder;
  onClick: (folderId: string) => void;
};

const FolderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ folder, onClick }) => {
    const { category, id, displayName } = folder;

    const icon = useMemo(() => {
      switch (category) {
        case FolderCategory.Inbox: {
          return (
            <Icon
              name="inbox"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Sent: {
          return (
            <Icon
              name="paper-plane"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Trash: {
          return (
            <Icon
              name="trash-alt"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Archive: {
          return (
            <Icon
              name="archive"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Spam: {
          return (
            <Icon
              name="ban"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Important: {
          return (
            <Icon
              name="exclamation-circle"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        case FolderCategory.Drafts: {
          return (
            <Icon
              name="edit"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
        default: {
          return (
            <Icon
              name="folder"
              size={16}
              containIn={20}
              color={Colors.Gray}
              regular
            />
          );
        }
      }
    }, [category]);

    const onClickCallback = useCallback(() => {
      onClick(id);
    }, [id, onClick]);

    return (
      <div styleName="folder" onClick={onClickCallback}>
        <div styleName="icon">{icon}</div>
        <div styleName="label">{displayName}</div>
      </div>
    );
  })
);

export default FolderComponent;
