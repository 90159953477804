import {
  Language,
  ObjectTypeAssignment,
  TranslatedText,
  TypePART,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ObjectTypesEditGeneralContainerProps } from "@haywork/modules/object-types";
import {
  ResourceText,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { UnitAndSizesDefault, UnitAndSizesPlot } from "./units-and-sizes";

const styles = require("./edit-general.component.scss");

export interface ObjectTypesEditGeneralComponentProps {}
type Props = ObjectTypesEditGeneralComponentProps &
  ObjectTypesEditGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditGeneralComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const {
      currentType,
      buildingStartText,
      deliveryStartText,
      saleStartText,
    } = this.props;
    const {
      buildingTypeStart,
      deliveryStart,
      saleStart,
      waitingTime,
      saleOffer,
      rentOffer,
      hideBuildingStartDate,
      hideDeliveryStartDate,
      hideSaleStartDate,
    } = currentType;

    this.formControls = {
      buildingTypeStart: { value: buildingTypeStart },
      hideBuildingStartDate: { value: hideBuildingStartDate },
      buildingStartText: { value: buildingStartText },
      deliveryStart: { value: deliveryStart },
      hideDeliveryStartDate: { value: hideDeliveryStartDate },
      deliveryStartText: { value: deliveryStartText },
      saleStart: { value: saleStart },
      hideSaleStartDate: { value: hideSaleStartDate },
      saleStartText: { value: saleStartText },
      rentStart: { value: saleStart },
      hideRentStartDate: { value: hideSaleStartDate },
      rentStartText: { value: saleStartText },
      waitingTime: { value: waitingTime },
      salePriceMin: { value: get(saleOffer, "salePrice") },
      salePriceMax: { value: get(saleOffer, "salePriceMax") },
      rentPriceMin: { value: get(rentOffer, "rentPrice") },
      rentPriceMax: { value: get(rentOffer, "rentPriceMax") },
    };

    this.onUpdateObjectType = this.onUpdateObjectType.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.currentType, "dateTimeModified") !==
        get(this.props.currentType, "dateTimeModified")
    ) {
      const {
        currentType,
        buildingStartText,
        deliveryStartText,
        saleStartText,
      } = this.props;
      const {
        buildingTypeStart,
        deliveryStart,
        saleStart,
        waitingTime,
        saleOffer,
        rentOffer,
        hideBuildingStartDate,
        hideDeliveryStartDate,
        hideSaleStartDate,
      } = currentType;

      this.formRef.update(
        {
          buildingTypeStart,
          hideBuildingStartDate,
          buildingStartText,
          deliveryStart,
          hideDeliveryStartDate,
          deliveryStartText,
          saleStart,
          hideSaleStartDate,
          saleStartText,
          rentStart: saleStart,
          hideRentStartDate: hideSaleStartDate,
          rentStartText: saleStartText,
          waitingTime,
          salePriceMin: get(saleOffer, "salePrice"),
          salePriceMax: get(saleOffer, "salePriceMax"),
          rentPriceMin: get(rentOffer, "rentPrice"),
          rentPriceMax: get(rentOffer, "rentPriceMax"),
        },
        true
      );
    }
  }

  public render() {
    const { forSale, typePART } = this.props.currentType;

    return (
      <div styleName="general" id="scroll-to-top">
        <div className="container-fluid">
          <Form
            name="project-type-general"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.formRef = form)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              <StepComponent
                title="projectTypesUnitsAndMeasurementsTab"
                data-cy="CY-TypeFirstStepButton"
              >
                {typePART !== TypePART.BuildingGround && (
                  <UnitAndSizesDefault
                    currentType={this.props.currentType}
                    onChange={this.onUpdateObjectType}
                  />
                )}

                {typePART === TypePART.BuildingGround && (
                  <UnitAndSizesPlot
                    currentType={this.props.currentType}
                    onChange={this.onUpdateObjectType}
                  />
                )}
              </StepComponent>

              <StepComponent
                title="projectTypesDatesTab"
                data-cy="CY-TypeSecondStepButton"
              >
                {this.renderDateFormRow(
                  "buildingTypeStart",
                  "hideBuildingStartDate",
                  "buildingStartText"
                )}
                {forSale
                  ? this.renderDateFormRow(
                      "saleStart",
                      "hideSaleStartDate",
                      "saleStartText",
                      !forSale
                    )
                  : this.renderDateFormRow(
                      "rentStart",
                      "hideRentStartDate",
                      "rentStartText",
                      !forSale
                    )}
                {this.renderDateFormRow(
                  "deliveryStart",
                  "hideDeliveryStartDate",
                  "deliveryStartText"
                )}

                {!forSale && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="range-label">
                        <label htmlFor="waitingTime" className="pre">
                          <ResourceText resourceKey="projectTypesLabel.waitingTime" />
                        </label>
                      </div>
                      <div className="column" styleName="range-input">
                        <Input.NewSelect
                          name="waitingTime"
                          placeholder="makeAChoice"
                          addEmptyOption
                          emptyOptionLabel="noChoice"
                          values={this.props.waitingTimeOptions}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </StepComponent>

              <StepComponent
                title="projectTypesFinancialTab"
                data-cy="CY-TypeThirdStepButton"
              >
                {!!forSale && this.renderPriceRangeFormRow("salePrice")}
                {!forSale && this.renderPriceRangeFormRow("rentPrice")}
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>
      </div>
    );
  }

  private renderDateFormRow(
    dateName: string,
    partsName: string,
    dateTextName: string,
    last: boolean = false
  ) {
    return (
      <React.Fragment>
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor={dateName} className="pre">
                <ResourceText resourceKey={`projectTypesLabel.${dateName}`} />
              </label>
            </div>
            <div className="column" styleName="date-input">
              <Input.Datepicker name={dateName} data-cy={"CY-" + dateName} />
              <div styleName="switch">
                <Input.Switch
                  name={partsName}
                  label="hide"
                  labelPosition={SwitchLabelPosition.Post}
                  on={true}
                  off={false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor={dateTextName} className="pre">
                <ResourceText
                  resourceKey={`projectTypesLabel.${dateTextName}`}
                />
              </label>
            </div>
            <div className="column" styleName="date-input">
              <Input.Textarea
                name={dateTextName}
                placeholder="projectTypesPlaceholderStartText"
                placeholderValues={{ year: new Date().getFullYear() }}
                autoSize
                maxCount={256}
                data-cy={"CY-" + dateTextName}
              />
            </div>
          </div>
        </div>
        {!!last && <div styleName="date-divider" />}
      </React.Fragment>
    );
  }

  private renderPriceRangeFormRow(name: string) {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column" styleName="price-label">
            <label htmlFor={`${name}Min`} className="pre">
              <ResourceText resourceKey={`projectTypesLabel.${name}`} />
            </label>
          </div>
          <div className="column" styleName="price-input">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name={`${name}Min`}
                pretty
                min={0}
                max={999999999}
                data-cy="CY-minPriceInput"
              />
            </div>
          </div>
          <div className="column__textspacer">
            <ResourceText resourceKey="untilAnd" />
          </div>
          <div className="column" styleName="price-input">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name={`${name}Max`}
                pretty
                min={0}
                max={999999999}
                data-cy="CY-maxPriceInput"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    let { currentType } = this.props;

    let buildingStartText: TranslatedText[];
    if (values.buildingStartText) {
      let ref = false;
      buildingStartText = get(currentType, "buildingStartText", []);
      buildingStartText = buildingStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.buildingStartText,
          };
        }
        return text;
      });

      if (!ref) {
        buildingStartText.push({
          language: Language.Dutch,
          text: values.buildingStartText,
        });
      }
    }

    let deliveryStartText: TranslatedText[];
    if (values.deliveryStartText) {
      let ref = false;
      deliveryStartText = get(currentType, "deliveryStartText", []);
      deliveryStartText = deliveryStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.deliveryStartText,
          };
        }
        return text;
      });

      if (!ref) {
        deliveryStartText.push({
          language: Language.Dutch,
          text: values.deliveryStartText,
        });
      }
    }

    let saleStartText: TranslatedText[];
    if (values.saleStartText || values.rentStartText) {
      let ref = false;
      saleStartText = get(currentType, "saleStartText", []);
      saleStartText = saleStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: currentType.forSale
              ? values.saleStartText
              : values.rentStartText,
          };
        }
        return text;
      });

      if (!ref) {
        saleStartText.push({
          language: Language.Dutch,
          text: currentType.forSale
            ? values.saleStartText
            : values.rentStartText,
        });
      }
    }

    currentType = {
      ...currentType,
      hideBuildingStartDate: values.hideBuildingStartDate,
      buildingStartDateParts: undefined,
      buildingStartText,
      buildingTypeStart: values.buildingTypeStart,
      deliveryStart: values.deliveryStart,
      hideDeliveryStartDate: values.hideDeliveryStartDate,
      deliveryStartDateParts: undefined,
      deliveryStartText,
      saleStart: currentType.forSale ? values.saleStart : values.rentStart,
      hideSaleStartDate: currentType.forSale
        ? values.hideSaleStartDate
        : values.hideRentStartDate,
      saleStartDateParts: undefined,
      saleStartText,
    };

    if (currentType.forSale) {
      currentType = {
        ...currentType,
        saleOffer: {
          ...currentType.saleOffer,
          salePrice: values.salePriceMin,
          salePriceMax: values.salePriceMax,
        },
      };
    } else {
      currentType = {
        ...currentType,
        rentOffer: {
          ...currentType.rentOffer,
          rentPrice: values.rentPriceMin,
          rentPriceMax: values.rentPriceMax,
        },
        waitingTime: values.waitingTime,
      };
    }

    this.props.updateCurrentType(currentType, this.props.path);
  }

  private onUpdateObjectType(type: ObjectTypeAssignment) {
    this.props.updateCurrentType(type, this.props.path);
  }
}
