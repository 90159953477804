import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import sortBy from "lodash-es/sortBy";

const mutationsSelector = (state: AppState) =>
  state.mls.single?.realEstateResponse?.mutations || [];

export const mutations = createSelector(mutationsSelector, (mutations) => {
  return sortBy(mutations, (mutation) => mutation.mutationDateTime).reverse();
});
