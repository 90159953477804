import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquistionAssignmentEditObjectScreen,
  AcquistionAssignmentEditObjectScreenProps
} from "./assignments-edit-object";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditObjectScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable.currentComponentState;
  return {
    acquisitionObjectAssignment
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditObjectScreenProps
> = (dispatch) => ({});

export type AcquistionAssignmentEditObjectScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditObjectScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditObjectScreen);
