import {
  AvailabilityStatus,
  AssignmentPhase,
  ListingType,
  RealEstateGroup,
  AssignmentSnapShot,
  AssignmentType,
  Furnishing,
} from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";

import { PagedResults, StoreHelper, Status, Action } from "../../helpers";
import * as ActionType from "./overview.types";

export interface AssignmentsFilters {
  localities: string[] | null;
  employeeIds: string[] | null;
  officeIds: string[] | null;
  availabilityStatuses: AvailabilityStatus[] | null;
  assignmentPhases: AssignmentPhase[] | null;
  listingTypes: ListingType[] | null;
  filterByRealEstateGroups: RealEstateGroup[] | null;
  filterByAssignmentTypes: AssignmentType[] | null;
  kindOfAssignment: string[] | null;
  filterByKeyNumbers: string[] | null;
  filterByFurnishings: Furnishing[] | null;
  filterByMinPrice: number | null;
  filterByMaxPrice: number | null;
  filterByNumberOfBedroomsMin: number | null;
  filterByNumberOfBedroomsMax: number | null;
}

export interface SelectedAssignmentFilter {
  filter: string;
  value: string;
  label: string;
}

export interface OverviewAssignmentState {
  assignments: PagedResults<AssignmentSnapShot>;
  assignmentsCommissionTotal: number;
  assignmentsLocalities: string[];
  assignmentsListingTypes: ListingType[];
  assignmentsFilters: AssignmentsFilters;
  keyNumbers: number[];
}

const INITIAL_STATE: OverviewAssignmentState = {
  assignments: StoreHelper.createInitialPagedResult(),
  assignmentsCommissionTotal: 0,
  assignmentsLocalities: [],
  assignmentsListingTypes: [],
  assignmentsFilters: {
    localities: null,
    employeeIds: null,
    officeIds: null,
    availabilityStatuses: [AvailabilityStatus.Available],
    assignmentPhases: [AssignmentPhase.Initiated],
    listingTypes: null,
    filterByRealEstateGroups: null,
    filterByAssignmentTypes: [],
    kindOfAssignment: null,
    filterByKeyNumbers: [],
    filterByFurnishings: [],
    filterByMinPrice: null,
    filterByMaxPrice: null,
    filterByNumberOfBedroomsMin: null,
    filterByNumberOfBedroomsMax: null,
  },
  keyNumbers: [],
};

export const overviewAssignmentReducer = (
  state: OverviewAssignmentState = INITIAL_STATE,
  action: Action
): OverviewAssignmentState => {
  switch (action.type) {
    case REDUX.ASSIGNMENT.SET_ASSIGNMENTS_STATUS: {
      const assignments = {
        ...state.assignments,
        ...StoreHelper.setObjectStatus(<Status>action),
      };

      return {
        ...state,
        assignments,
      };
    }
    case REDUX.ASSIGNMENT.UPDATE_SINGLE_LIST_ASSIGNMENT: {
      const { snapshot } = <ActionType.Snapshot>action;
      const assignments = StoreHelper.updateSingleResultInResults(
        snapshot,
        "id",
        state.assignments
      );

      return {
        ...state,
        assignments,
      };
    }
    case REDUX.ASSIGNMENT.SET_ASSIGNMENTS: {
      const { statistics } = <ActionType.AssignmentsResponse>action;
      const assignments = StoreHelper.setPagedResults(
        <ActionType.AssignmentsResponse>action
      );

      return {
        ...state,
        assignments,
        assignmentsCommissionTotal: statistics.commissionTotal,
        assignmentsLocalities: statistics.localities || [],
        assignmentsListingTypes: statistics.listingTypes || [],
        keyNumbers: statistics.keyNumbers || [],
      };
    }
    case REDUX.ASSIGNMENT.APPEND_ASSIGNMENTS: {
      const { statistics } = <ActionType.AssignmentsResponse>action;
      const assignments = StoreHelper.updatePagedResults(
        <ActionType.AssignmentsResponse>action,
        state.assignments
      );

      return {
        ...state,
        assignments,
        assignmentsCommissionTotal: statistics.commissionTotal,
        assignmentsLocalities: statistics.localities || [],
        assignmentsListingTypes: statistics.listingTypes || [],
        keyNumbers: statistics.keyNumbers || [],
      };
    }
    case REDUX.ASSIGNMENT.SET_ASSIGNMENTS_FILTERS: {
      const { assignmentsFilters } = <ActionType.Filters>action;

      return {
        ...state,
        assignmentsFilters,
      };
    }
    case REDUX.ASSIGNMENT.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        assignmentsFilters: {
          localities: null,
          employeeIds: null,
          officeIds: null,
          availabilityStatuses: null,
          assignmentPhases: null,
          listingTypes: null,
          filterByRealEstateGroups: null,
          filterByAssignmentTypes: null,
          kindOfAssignment: null,
          filterByKeyNumbers: null,
          filterByFurnishings: [],
          filterByMinPrice: null,
          filterByMaxPrice: null,
          filterByNumberOfBedroomsMin: null,
          filterByNumberOfBedroomsMax: null,
        },
      };
    }
    case REDUX.ASSIGNMENT.CLEAR_FILTER: {
      const { filter } = <ActionType.Filter>action;

      let values;
      switch (filter.filter) {
        case "filterByKeyNumbers":
        case "filterByMinPrice":
        case "filterByMaxPrice":
        case "filterByNumberOfBedroomsMin":
        case "filterByNumberOfBedroomsMax": {
          const initialValue = INITIAL_STATE.assignmentsFilters[filter.filter];
          values = initialValue;
          break;
        }
        default: {
          const reference = state.assignmentsFilters[filter.filter];
          values = reference.filter((item) => item !== filter.value);
          break;
        }
      }

      return {
        ...state,
        assignmentsFilters: {
          ...state.assignmentsFilters,
          [filter.filter]: values,
        },
      };
    }
    case REDUX.ASSIGNMENT.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const assignments = StoreHelper.deleteSingleResultInResults(
        id,
        "id",
        state.assignments
      );

      return {
        ...state,
        assignments,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
