import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./avatar-trigger.component.scss");

interface AvatarUploadTriggerComponentProps {
  customText?: string;
  icon?: string;
}
interface AvatarUploadTriggerComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AvatarUploadTriggerComponent extends React.Component<AvatarUploadTriggerComponentProps, AvatarUploadTriggerComponentState> {
  public render() {
    const resourceKey = this.props.customText || "fileUploadAvatarPlaceholder";
    const icon = this.props.icon || "fa fa-user";

    return <div styleName="trigger">
      <div styleName="trigger__inner">
        <div styleName="trigger__placeholder">
          <i className={ icon } />
          <ResourceText resourceKey={ resourceKey } asHtml />
        </div>
      </div>
    </div>;
  }
}
