import { DragLayer, DragLayerCollector } from "react-dnd";
import { DossierDragPreviewComponent } from "./drag-preview";
import { FolderTreeFileEntity } from "@haywork/api/kolibri";

export interface DossierDragPreviewContainerProps {
  item?: {
    file: FolderTreeFileEntity;
    pathname: string;
    selectedFilesCount: number;
  };
  itemType?: string;
  isDragging?: boolean;
}

const collect: DragLayerCollector<any, any> = (monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging()
});

export const DossierDragPreviewContainer = DragLayer<{}>(collect)(
  DossierDragPreviewComponent
);
