import {
  ActiveFilter,
  LinkedSearchAssignment,
  RealEstateGroup,
  SearchAssignmentSnapShot
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { InputComponentProps } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Relation } from "@haywork/request";
import { RouteUtil, StringUtil } from "@haywork/util";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import { Pill } from "./pill.component";

const route = RouteUtil.mapStaticRouteValues;

interface SearchAssigmentQueryComponentProps {
  realEstateGroups?: RealEstateGroup[];
  multiple?: boolean;
  placeholder?: string;
  onNavigateToSearchAssignment?: (path: string) => void;
  supportForRent?: boolean;
  supportForSale?: boolean;
  numberOfResults?: number;
  filterByActive?: ActiveFilter;
}
interface State {
  value: LinkedSearchAssignment[];
  placeholder: string;
}
type Props = SearchAssigmentQueryComponentProps & InputComponentProps;

export class SearchAssigmentQueryComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    let value = isArray(this.props.value)
      ? this.props.value
      : [this.props.value];
    value = value.filter((v) => !!v);

    this.state = {
      value,
      placeholder: this.props.placeholder || "searchSearchAssignmentPlaceholder"
    };

    this.renderSelectedValuePill.bind(this);
    this.onSearchAssignmentClickHandler = this.onSearchAssignmentClickHandler.bind(
      this
    );
    this.onRemoveSearchAssignmentClickHandler = this.onRemoveSearchAssignmentClickHandler.bind(
      this
    );
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedValue = this.renderSelectedValue.bind(this);
    this.renderDisabledOption = this.renderDisabledOption.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    const take = this.props.numberOfResults || 25;

    return (
      <div className="assignment__query">
        <BaseQueryComponent
          {...this.props}
          {...this.state}
          hasCustomPill
          asyncValues={(value) =>
            Relation.searchRelationsForSearchAssignments(value, take)
          }
          optionValue={this.renderOptionValue}
          selectedValue={this.renderSelectedValue}
          disableOption={this.renderDisabledOption}
          onChange={this.onChangeHandler}
          data-cy={this.props["data-cy"]}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    let value = isArray(nextProps.value) ? nextProps.value : [nextProps.value];
    value = value.filter((v) => !!v);

    this.setState({ value });
  }

  private onChangeHandler(value: SearchAssignmentSnapShot[]) {
    this.fireOnChange(value);
  }

  private fireOnChange(value: any[]) {
    const mappedValues = this.prepareValues(value);
    const mappedValue = !!this.props.multiple
      ? mappedValues
      : first(mappedValues);

    this.props.onChange(mappedValue);
  }

  private prepareValues(
    values: SearchAssignmentSnapShot[]
  ): LinkedSearchAssignment[] {
    return values.map((value) => {
      const { id } = value;

      const returnValue: LinkedSearchAssignment = {
        id
      };

      return returnValue;
    });
  }

  private renderOptionValue(
    assignment: SearchAssignmentSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div className="query-component__option">
        <div
          dangerouslySetInnerHTML={StringUtil.highlight(assignment.name, query)}
        />
        {!assignment.isActive && (
          <div className="query-component__archived-label">
            <ResourceText resourceKey="archived" />
          </div>
        )}
      </div>
    );
  }

  private renderSelectedValue(
    value: SearchAssignmentSnapShot
  ): QueryResultReturnValue<LinkedSearchAssignment> {
    const template = this.renderSelectedValuePill(value);

    return {
      value,
      template
    };
  }

  private renderDisabledOption(
    value: SearchAssignmentSnapShot,
    values: SearchAssignmentSnapShot[]
  ) {
    const ids = values.map((v) => v.id);
    return ids.indexOf(value.id) !== -1;
  }

  private renderSelectedValuePill(
    searchAssignment: SearchAssignmentSnapShot
  ): React.ReactElement<HTMLDivElement> {
    return (
      <Pill
        searchAssignment={searchAssignment}
        onSearchAssignmentClick={this.onSearchAssignmentClickHandler}
        onRemoveSearchAssignmentClick={
          this.onRemoveSearchAssignmentClickHandler
        }
        data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Pill`}
      />
    );
  }

  private onSearchAssignmentClickHandler(
    searchAssignment: SearchAssignmentSnapShot
  ) {
    if (!this.props.onNavigateToSearchAssignment) return;

    const { id } = searchAssignment;
    const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onNavigateToSearchAssignment(path);
  }

  private onRemoveSearchAssignmentClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    assignment: SearchAssignmentSnapShot
  ) {
    event.stopPropagation();
    const value = this.state.value.filter((v) => v.id !== assignment.id);

    this.setState({ value });
    this.fireOnChange(value);
  }
}
