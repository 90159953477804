import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  BatchItem,
  BatchItemType,
  BatchOption,
  FolderTreeFileEntity,
  InvoiceStatus,
} from "@haywork/api/kolibri";
import { INVOICEROUTES } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Input } from "@haywork/modules/form";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import { AuthorizationWidgets } from "../../..";
import { ResourceText } from "../../../resource-text/resource-text.component";
import ActionList, { Action } from "../action-list";
import LinkedEntities from "../linked-entities";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  file: FolderTreeFileEntity;
  rootId: string;
  bulkSelectedFiles: FolderTreeFileEntity[];
  canSendEmail: boolean;
  onNavigate: (path: string) => void;
  onRefresh: () => void;
  onArchive: (id: string) => Promise<void>;
  onUnArchive: (id: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onToggleSelectForBulk: (file: FolderTreeFileEntity) => void;
  onEmail: (file: BatchItem) => void;
  onDownload: (id: string) => Promise<void>;
}
const DossierItemInvoiceComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      file,
      rootId,
      onNavigate,
      onRefresh,
      onArchive,
      onUnArchive,
      onDelete,
      bulkSelectedFiles,
      onToggleSelectForBulk,
      onEmail,
      canSendEmail,
      onDownload,
    }: Props) => {
      const { linkedInvoice, id } = file;

      const [actionListVisible, setActionListVisible] = useState(false);
      const actionRef = useRef(null);

      const linkedEntities = useMemo(() => {
        if (!linkedInvoice) return [];
        const { linkedRelations, linkedAssignment } = file.linkedInvoice;
        const linkedEntities = [
          ...(linkedRelations || []),
          linkedAssignment,
        ].filter((d) => !!d && d.id !== rootId);
        return linkedEntities;
      }, [linkedInvoice]);

      const onActionListClose = useCallback(() => {
        setActionListVisible(false);
      }, [setActionListVisible]);

      const onEditClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        const path = route(INVOICEROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [setActionListVisible, linkedInvoice]);

      const onEmailClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        onEmail({
          id,
          type: BatchItemType.Invoice,
          options: [BatchOption.TempAccessUrl],
        });
      }, [linkedInvoice, onEmail]);

      const onDownloadClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        onDownload(id);
      }, [linkedInvoice, onDownload]);

      const onArchiveClickHandler = useCallback(async () => {
        setActionListVisible(false);
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        await onArchive(id);
        onRefresh();
      }, [linkedInvoice, setActionListVisible]);

      const onUnarchiveClickHandler = useCallback(async () => {
        setActionListVisible(false);
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        await onUnArchive(id);
        onRefresh();
      }, [linkedInvoice, setActionListVisible]);

      const onDeleteHandler = useCallback(async () => {
        if (!linkedInvoice) return;
        const { id } = linkedInvoice;
        await onDelete(id);
        onRefresh();
      }, [linkedInvoice]);

      const bulkSelected = useMemo(() => {
        return !!bulkSelectedFiles.find((f) => f.id === id);
      }, [id, bulkSelectedFiles]);

      if (!linkedInvoice) return null;
      const {
        invoiceTotalAmount,
        displayName,
        dueDate,
        status,
        isOverdue,
        isActive,
      } = linkedInvoice;

      return (
        <div styleName={classNames("file", "invoice")}>
          <div styleName="bulk-select">
            <Input.CheckBox
              name={`bulk-${id}`}
              asSingleInput
              value={bulkSelected}
              onChange={() => onToggleSelectForBulk(file)}
            />
          </div>
          <div styleName="icon">
            <i className="fal fa-euro-sign" />
          </div>
          <div styleName="meta">
            <div styleName="meta__name">
              <div styleName="name__wrapper">
                {!isActive && (
                  <div styleName="not-active">
                    <ResourceText resourceKey="archived" />
                  </div>
                )}
                <div styleName="filename">
                  <span styleName="faux-link" onClick={onEditClickHandler}>
                    {!!displayName ? (
                      displayName
                    ) : (
                      <ResourceText resourceKey="dossier.invoice.conceptInvoice" />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              styleName={classNames("meta__status", {
                overdue: isOverdue,
              })}
            >
              <ResourceText
                masterKey="invoiceStatuses"
                resourceKey={status.toString()}
              />
            </div>
            <div
              styleName={classNames("meta__amount", {
                overdue: isOverdue,
              })}
            >
              <FormattedNumber
                value={invoiceTotalAmount}
                style="currency"
                currency="EUR"
              />
            </div>
            <div
              styleName={classNames("meta__creation-date", {
                overdue: isOverdue,
              })}
            >
              {!!dueDate && moment(dueDate).format("DD-MM-YYYY")}
            </div>
            <div styleName="meta__entities-description">
              <LinkedEntities linkedEntities={linkedEntities} />
            </div>
          </div>
          <div
            styleName="action"
            onClick={() => setActionListVisible(!actionListVisible)}
            ref={actionRef}
          >
            <i className="fal fa-ellipsis-v" />
          </div>

          <ActionList
            visible={actionListVisible}
            onClose={onActionListClose}
            parent={actionRef}
          >
            <Action
              label="dossier.invoiceAction.edit"
              icon="fal fa-pencil"
              onClick={onEditClickHandler}
            />

            <Action
              label="dossier.invoiceAction.download"
              icon="fal fa-download"
              onClick={() => onDownloadClickHandler()}
            />

            {canSendEmail && (
              <Action
                label="dossier.invoiceAction.email"
                icon="fal fa-paper-plane"
                onClick={() => onEmailClickHandler()}
              />
            )}

            {isActive ? (
              <Action
                label="dossier.invoiceAction.archive"
                icon="fal fa-archive"
                onClick={onArchiveClickHandler}
              />
            ) : (
              <Action
                label="dossier.invoiceAction.unarchive"
                icon="fal fa-inbox-out"
                onClick={onUnarchiveClickHandler}
              />
            )}

            {status === InvoiceStatus.Draft && (
              <Action
                label="dossier.invoiceAction.delete"
                icon="fal fa-trash"
                onClick={onDeleteHandler}
              />
            )}

            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={WidgetEntityType.Invoice}
                location={WidgetEntityLocation.Dossier}
                id={id}
                onActionClick={() => setActionListVisible(false)}
              />
            </FeatureSwitch>
          </ActionList>
        </div>
      );
    }
  )
);

export default DossierItemInvoiceComponent;
