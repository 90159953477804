import * as React from "react";
import { FC, memo, useMemo, useCallback } from "react";
import * as CSSmodules from "react-css-modules";
import { RealEstatePropertySearchItem } from "@haywork/api/mls";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import head from "lodash-es/head";
import Pill from "@haywork/components/ui/pill";
import Button from "@haywork/components/ui/button";
import { RouteUtil } from "@haywork/util";
import { MLSROUTES } from "@haywork/constants";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";

const styles = require("../../style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: RealEstatePropertySearchItem;
  loading: boolean;
  onAction: (assignment: RealEstatePropertySearchItem) => void;
  onNavigate: (path: string) => void;
};

export const MlsRowComponent: FC<Props> = memo(
  CSSmodules(styles, { allowMultiple: true })(
    ({ assignment, loading, onAction, onNavigate }) => {
      const {
        imageUrls,
        bundleId,
        appClientKeys,
        agency,
        propertyInfo,
        financial,
        address,
      } = assignment;

      const displayName = useMemo(() => {
        const houseNumber = address?.houseNumber;
        const houseNumberPostfix = address?.houseNumberAddendum;
        const streetname = address?.street?.name;
        const fullHouseNumber = [houseNumber, houseNumberPostfix]
          .filter((d) => ![null, undefined].includes(d))
          .join("-");
        const postalCode = address?.postalCode;
        const cityName = address?.locality?.name;

        const addressLine1 = [streetname, fullHouseNumber]
          .filter((d) => ![null, undefined].includes(d))
          .join(" ");
        const addressLine2 = [postalCode, cityName]
          .filter((d) => !!d)
          .join(" ");

        return [addressLine1, addressLine2].join(", ");
      }, [address]);

      const thumb = useMemo(() => {
        const filtered = (imageUrls || []).filter((d) => !!d);
        return head(filtered);
      }, [imageUrls]);

      const realtor = useMemo(() => {
        return agency?.name || null;
      }, [agency]);

      const status = useMemo(() => {
        const status = propertyInfo?.availability;
        return !status ? null : (
          <I18n prefix="statusTypeOptions" value={status.toString()} />
        );
      }, [propertyInfo]);

      const mandateDate = useMemo(() => {
        const date = propertyInfo?.mandateDate;
        return !date ? null : (
          <I18n
            value="address.matchingProperties.mandateDate"
            values={{ date: moment(date).format("DD-MM-YYYY") }}
          />
        );
      }, []);

      const price = useMemo(() => {
        if (!financial) return null;
        const {
          purchasePrice,
          rentPrice,
          transactionPrice,
          purchaseConditionType,
        } = financial;

        if (!!rentPrice) {
          return (
            <I18n
              value="address.matchingProperties.rentPrice"
              values={{ price: rentPrice }}
              asHtml
            />
          );
        }

        if (!!purchasePrice) {
          const condition = !purchaseConditionType ? null : (
            <I18n
              prefix="purchaseConditionTypeOptions"
              value={purchaseConditionType.toString()}
            />
          );
          return (
            <>
              <I18n
                value="address.matchingProperties.purchasePrice"
                values={{ price: purchasePrice }}
                asHtml
              />
              {!condition ? null : <> {condition}</>}
            </>
          );
        }

        if (!!transactionPrice) {
          const condition = !purchaseConditionType ? null : (
            <I18n
              prefix="purchaseConditionTypeOptions"
              value={purchaseConditionType.toString()}
            />
          );
          return (
            <>
              <I18n
                value="address.matchingProperties.transactionPrice"
                values={{ price: transactionPrice }}
                asHtml
              />
              {!condition ? null : <> {condition}</>}
            </>
          );
        }

        return null;
      }, [financial]);

      const onNavigateCallback = useCallback(() => {
        const id = bundleId;
        const source = head(appClientKeys);
        if (!id || !source) return;

        const path = route(MLSROUTES.DETAIL.URI, { id, source });
        onNavigate(path);
      }, [bundleId, appClientKeys, onNavigate]);

      const onActionCallback = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onAction(assignment);
        },
        [assignment, onAction]
      );

      return (
        <div styleName="row" onClick={onNavigateCallback}>
          <div styleName="row__column preview">
            <div
              styleName="thumb"
              style={{
                backgroundImage: !thumb
                  ? null
                  : `url(${JSON.stringify(thumb)})`,
              }}
            >
              {!thumb && (
                <div styleName="thumb__inner">
                  <Icon name="camera-alt" size={24} color={Colors.Gray} light />
                </div>
              )}
            </div>
          </div>
          <div styleName="row__column meta">
            {!!displayName && <div styleName="meta address">{displayName}</div>}
            {!!status && <div styleName="meta strong">{status}</div>}
            {!!mandateDate && <div styleName="meta">{mandateDate}</div>}
            {!!price && <div styleName="meta">{price}</div>}
            <div styleName="meta">
              <Pill
                label="address.matchingProperties.sourceMLS"
                color={Colors.Warning}
                tiny
                fullWidth
              />
              {!!realtor && (
                <Pill label={realtor} color={Colors.Primary} tiny fullWidth />
              )}
            </div>
          </div>
          <div styleName="row__column action">
            <Button
              category="primary"
              label="address.matchingProperties.action"
              onClick={onActionCallback}
              disabled={loading}
            />
          </div>
        </div>
      );
    }
  )
);
