import * as React from "react";
import { FC, memo, useMemo, useState, useCallback } from "react";
import {
  LinkedAssignment,
  LinkedRelation,
  RelationType,
  AssignmentType
} from "@haywork/api/kolibri";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import * as CSSModules from "react-css-modules";
import {
  RELATIONROUTES,
  OFFICESROUTES,
  EMPLOYEEROUTES,
  ASSIGNMENTROUTES,
  ACQUISITIONROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  ACQUISITIONOBJECTROUTES
} from "@haywork/constants";
import { RouteUtil } from "@haywork/util";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type LinkedEntity = {
  type: "assignment" | "relation";
  entity: LinkedAssignment | LinkedRelation;
};
type Props = {
  linkedAssignments: LinkedAssignment[];
  linkedRelations: LinkedRelation[];
  parentId: string;
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

export const TimelineLinkedEntitiesComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      linkedRelations,
      linkedAssignments,
      parentId,
      onNavigate,
      onShowAllLinkedEntities
    }) => {
      const [extraCount, setExtraCount] = useState(0);
      const mappedEntities = useMemo(() => {
        return [
          ...(linkedRelations || [])
            .filter((entity) => entity.id !== parentId)
            .map((entity) => ({
              type: "relation",
              entity
            })),
          ...(linkedAssignments || [])
            .filter((entity) => entity.id !== parentId)
            .map((entity) => ({
              type: "assignment",
              entity
            }))
        ] as LinkedEntity[];
      }, [linkedRelations, linkedAssignments]);
      const entities = useMemo(() => {
        const totalCount = mappedEntities.length;
        if (totalCount > 3) {
          setExtraCount(totalCount - 2);
          return mappedEntities.slice(0, 2);
        } else {
          return mappedEntities;
        }
      }, [mappedEntities]);

      const navigateToRelation = useCallback(
        (id: string, relationType: RelationType) => {
          let path: string;

          switch (relationType) {
            case RelationType.ContactCompany: {
              path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            }
            case RelationType.ContactPerson: {
              path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
              break;
            }
            case RelationType.Employee: {
              path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            }
            case RelationType.Office: {
              path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            }
            default:
              break;
          }

          if (!!path) onNavigate(path);
        },
        [onNavigate]
      );

      const navigateToAssignment = useCallback(
        (id: string, assignmentType: AssignmentType) => {
          let path: string;

          switch (assignmentType) {
            case AssignmentType.Acquisition: {
              path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.AcquisitionObject: {
              path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.Object: {
              path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.ObjectType: {
              path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.Project: {
              path = route(PROJECTROUTES.DETAIL.URI, { id });
              break;
            }
            default:
              break;
          }

          if (!!path) onNavigate(path);
        },
        [onNavigate]
      );

      return (
        <div styleName="linked-entities">
          {entities.map((entity) =>
            entity.type === "assignment" ? (
              <Pill
                label={(entity.entity as LinkedAssignment).displayName}
                icon="folder"
                color={Colors.Primary}
                onClick={() =>
                  navigateToAssignment(
                    (entity.entity as LinkedAssignment).id,
                    (entity.entity as LinkedAssignment).typeOfAssignment
                  )
                }
                key={entity.entity.id}
              />
            ) : (
              <Pill
                label={(entity.entity as LinkedRelation).displayName}
                icon={
                  (entity.entity as LinkedRelation).typeOfRelation ===
                  RelationType.ContactCompany
                    ? "building"
                    : "user"
                }
                color={
                  (entity.entity as LinkedRelation).typeOfRelation ===
                  RelationType.ContactCompany
                    ? Colors.Secondary
                    : Colors.Primary
                }
                onClick={() =>
                  navigateToRelation(
                    (entity.entity as LinkedRelation).id,
                    (entity.entity as LinkedRelation).typeOfRelation
                  )
                }
                key={entity.entity.id}
              />
            )
          )}
          {!!extraCount && (
            <Pill
              label={`+${extraCount}`}
              color={Colors.Primary}
              onClick={() => onShowAllLinkedEntities(mappedEntities)}
            />
          )}
        </div>
      );
    }
  )
);
