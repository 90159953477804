import { REDUX } from "@haywork/constants";
import * as ActionType from "./global-search.types";

const setInitialResults = (payload: ActionType.InitialResults) => ({
  type: REDUX.GLOBALSEARCH.SET_INITIAL_RESULTS,
  ...payload
});

const setRelationResults = (payload: ActionType.InitialResults) => ({
  type: REDUX.GLOBALSEARCH.SET_RELATION_RESULTS,
  ...payload
});

const setAssignmentResults = (payload: ActionType.InitialResults) => ({
  type: REDUX.GLOBALSEARCH.SET_ASSIGNMENT_RESULTS,
  ...payload
});

const setInvoiceResults = (payload: ActionType.InitialResults) => ({
  type: REDUX.GLOBALSEARCH.SET_INVOICE_RESULTS,
  ...payload
});

const setTaskResults = (payload: ActionType.InitialResults) => ({
  type: REDUX.GLOBALSEARCH.SET_TASK_RESULTS,
  ...payload
});

const setSearchStatus = (searchStatus: string) => ({
  type: REDUX.GLOBALSEARCH.SET_RESULTS_STATUS,
  searchStatus
});

export const GlobalSearchActions = {
  setInitialResults,
  setRelationResults,
  setAssignmentResults,
  setInvoiceResults,
  setSearchStatus,
  setTaskResults
};
