import { Bid, LinkedRelation } from "@haywork/api/kolibri";
import { BidThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { BidsModalComponent, BidsModalComponentProps } from "./bids-modal";

type StateProps = {};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  saveBid: (bid: Bid) => Promise<void>;
  acceptBid: (id: string) => Promise<Bid>;
  rejectBid: (id: string) => Promise<Bid>;
  createCounterOffer: (
    originalBid: Bid,
    linkedRelations?: LinkedRelation[]
  ) => Promise<Bid>;
  readBid: (id: string) => Promise<Bid>;
  deleteBid: (id: string, undeleteCallback: () => void) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  BidsModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  BidsModalComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  saveBid: (bid: Bid) => dispatch(BidThunk.saveBid(bid)),
  acceptBid: (id: string) => dispatch(BidThunk.acceptBid(id)),
  rejectBid: (id: string) => dispatch(BidThunk.rejectBid(id)),
  createCounterOffer: (originalBid: Bid, linkedRelations?: LinkedRelation[]) =>
    dispatch(BidThunk.createCounterOffer(originalBid, linkedRelations)),
  readBid: (id: string) => dispatch(BidThunk.readBid(id)),
  deleteBid: (id: string, undeleteCallback: () => void) =>
    dispatch(BidThunk.deleteBid(id, undeleteCallback))
});

export type BidsModalContainerProps = StateProps & DispatchProps;
export const BidsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BidsModalComponent);
