import {
  AppClientClient,
  AppClientOrderByField,
  ArchiveFilter,
  CompanySettingsClient,
  EmployeeSettingsClient,
  SettingField,
  SortOrder,
} from "@haywork/api/authorization";
import { APPXCHANGEROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import { AppState, EditableActions } from "@haywork/stores";
import { AuthorizationActions } from "@haywork/stores/authorization";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { RouteUtil } from "@haywork/util";
import { Dispatch } from "../";
import { push } from "connected-react-router";
import { Widgets } from "./widgets";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getAppClients = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AuthorizationActions.AppClients.setClientsStatus(REQUEST.PENDING));

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AppClient = new AppClientClient(authorizationHost);

    try {
      const appClients = await parseRequest.response(
        AppClient.search(
          {
            orderBy: AppClientOrderByField.Rating,
            filterByArchived: ArchiveFilter.ArchivedAndNot,
            order: SortOrder.Descending,
            skip: 0,
            take: 100,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      const specials = await parseRequest.response(
        AppClient.search(
          {
            orderBy: AppClientOrderByField.Rating,
            filterByArchived: ArchiveFilter.ArchivedAndNot,
            order: SortOrder.Descending,
            skip: 0,
            take: 10,
            isSpecial: true,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(
        AuthorizationActions.AppClients.setClients(appClients, specials)
      );
    } catch (error) {
      dispatch(AuthorizationActions.AppClients.setClientsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getClient = (id: string, isUpdate: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AuthorizationActions.SingleAppClient.setStatus(REQUEST.PENDING));

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AppClient = new AppClientClient(authorizationHost);

    try {
      const singleAppClient = await parseRequest.response(
        AppClient.read(id, realEstateAgencyId).then(
          (response) => response.appClient
        )
      );

      if (
        singleAppClient.isFirstParty ||
        (!!singleAppClient.linkedConsent &&
          singleAppClient.linkedConsent.dateTimeCreated)
      ) {
        await dispatch(getSettings(singleAppClient.id));
      } else {
        dispatch(AuthorizationActions.SingleAppClient.clearSettings());
      }

      const path = route(APPXCHANGEROUTES.DETAIL.URI, {
        id: singleAppClient.id,
      });
      const componentState = {
        icon: MAINROUTES.APP_XCHANGE.ICON,
        componentState: singleAppClient,
        path,
        title: singleAppClient.displayName,
        hasChanges: false,
        entityType: null,
        entityId: singleAppClient.id,
      };

      dispatch(AuthorizationActions.SingleAppClient.setClient(singleAppClient));

      if (isUpdate) {
        dispatch(
          EditableActions.updateComponentState({
            ignoreChanges: true,
            path,
            componentState: singleAppClient,
          })
        );
      } else {
        dispatch(EditableActions.addState(componentState));
      }

      return singleAppClient;
    } catch (error) {
      dispatch(AuthorizationActions.SingleAppClient.setStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const toggleConsent = (appClientId: string, hasConsent: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AuthorizationActions.SingleAppClient.setConsentStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(APPXCHANGEROUTES.ABOUT.URI, { id: appClientId });

    const AppClient = new AppClientClient(authorizationHost);

    try {
      await parseRequest.response(
        AppClient.toggleConsent({ appClientId, hasConsent }, realEstateAgencyId)
      );

      const client = await dispatch(getClient(appClientId, true));
      await dispatch(Widgets.getConsentedWidgets());

      if (!hasConsent) {
        dispatch(push(path));
      }

      const toast: ToastProps = {
        value: hasConsent
          ? "appClientConsentGranted"
          : "appClientConsentRetracted",
        values: { displayName: client.displayName },
        icon: "rocket",
      };

      dispatch(SnackbarActions.addToast(toast));
      dispatch(
        AuthorizationActions.SingleAppClient.setConsentStatus(REQUEST.SUCCESS)
      );
      dispatch(getSettings(client.id, !hasConsent));
    } catch (error) {
      dispatch(
        AuthorizationActions.SingleAppClient.setConsentStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getRecommendations = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AppClient = new AppClientClient(authorizationHost);

    try {
      const recommendations = await parseRequest.response(
        AppClient.search(
          {
            orderBy: AppClientOrderByField.Installs,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Descending,
            skip: 0,
            take: 6,
            hasConsent: false,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(
        AuthorizationActions.SingleAppClient.setRecommendations(recommendations)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getSettings = (appClientId: string, clear: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!!clear) {
      dispatch(AuthorizationActions.SingleAppClient.clearSettings());
      return;
    }

    dispatch(
      AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: employeeId } = state.account.employee;
    const { culture } = state.main;

    const EmployeeSettings = new EmployeeSettingsClient(authorizationHost);
    const CompanySettings = new CompanySettingsClient(authorizationHost);

    try {
      const employeeSettings = await parseRequest.response(
        EmployeeSettings.read(
          appClientId,
          employeeId,
          culture,
          realEstateAgencyId
        )
      );
      const companySettings = await parseRequest.response(
        CompanySettings.read(appClientId, culture, realEstateAgencyId)
      );

      dispatch(
        AuthorizationActions.SingleAppClient.setSettings(
          employeeSettings,
          companySettings
        )
      );
    } catch (error) {
      dispatch(
        AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const saveCompanySettings = (appClientId: string, fields: SettingField[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;

    const CompanySettings = new CompanySettingsClient(authorizationHost);

    try {
      const companySettings = await parseRequest.response(
        CompanySettings.save(
          {
            appClientId,
            fields,
            culture,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        AuthorizationActions.SingleAppClient.updateCompanySettings(
          companySettings
        )
      );

      const toast: ToastProps = {
        value: "toastAppXchangeSettingsSaved",
        icon: "rocket",
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      dispatch(
        AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const saveEmployeeSettings = (appClientId: string, fields: SettingField[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;
    const { id: employeeId } = state.account.employee;

    const EmployeeSettings = new EmployeeSettingsClient(authorizationHost);

    try {
      const employeeSettings = await parseRequest.response(
        EmployeeSettings.save(
          {
            appClientId,
            fields,
            culture,
            employeeId,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        AuthorizationActions.SingleAppClient.updateEmployeeSettings(
          employeeSettings
        )
      );

      const toast: ToastProps = {
        value: "toastAppXchangeSettingsSaved",
        icon: "rocket",
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      dispatch(
        AuthorizationActions.SingleAppClient.setSettingsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

export const AppClient = {
  getAppClients,
  getClient,
  toggleConsent,
  getRecommendations,
  getSettings,
  saveCompanySettings,
  saveEmployeeSettings,
};
