import { RealEstateGroup } from "@haywork/api/kolibri";
import { NewSearchAssignmentContainerProps } from "@haywork/modules/search-assignment";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RouteComponentProps } from "react-router-dom";

const styles = require("./new.component.scss");

export interface NewSearchAssignmentComponentProps {}
interface State {}
type Props = NewSearchAssignmentComponentProps &
  NewSearchAssignmentContainerProps &
  RouteComponentProps<any>;

@CSSModules(styles, { allowMultiple: true })
export class NewSearchAssignmentComponent extends React.Component<
  Props,
  State
> {
  public render() {
    return (
      <div styleName="new">
        <div styleName="new__inner">
          <h1>
            <ResourceText resourceKey="whatDoYouLookFor" />
          </h1>
          <div styleName="new__types">
            <div
              styleName="type"
              onClick={() =>
                this.createNewSearchAssignment(RealEstateGroup.Residential)
              }
            >
              <i className="fal fa-home" />
              <ResourceText
                masterKey="realEstateGroups"
                resourceKey="Residential"
              />
            </div>

            <div
              styleName="type"
              onClick={() =>
                this.createNewSearchAssignment(RealEstateGroup.Commercial)
              }
            >
              <i className="fal fa-building" />
              <ResourceText
                masterKey="realEstateGroups"
                resourceKey="Commercial"
              />
            </div>

            <div
              styleName="type"
              onClick={() =>
                this.createNewSearchAssignment(RealEstateGroup.Agricultural)
              }
            >
              <i className="fal fa-tree" />
              <ResourceText
                masterKey="realEstateGroups"
                resourceKey="Agricultural"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private createNewSearchAssignment(type: RealEstateGroup) {
    const { id } = this.props.match.params;
    this.props.createSearchAssignment(type, id);
  }
}
