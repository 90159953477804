import {
  Address,
  CadastralLotSize,
  CadastralLotSizeOption,
  Cadastre,
  FixedVariableOption,
  GroundLeasePeriod,
  GroundLeasePeriodOption,
  LeaseholdOwnerTypeOption,
  OwnershipType,
  OwnershipTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, NumberUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";

const styles = require("./cadastre-form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface AssignmentCadastreFormComponentProps {
  cadastre: Cadastre;
  cadastralLotSizeOptions: CadastralLotSizeOption[];
  ownershipTypeOptions: OwnershipTypeOption[];
  fixedVariableOptions: FixedVariableOption[];
  groundLeasePeriodOptions: GroundLeasePeriodOption[];
  address: Address;
  displayName: string;
  municipality: string;
  idToken: string;
  leaseholdOwnerTypeOptions: LeaseholdOwnerTypeOption[];
  onChange: (cadastre: Cadastre) => void;
  onRemove: (id: string) => void;
}
interface State {
  appartmentShareVisible: boolean;
  endDateVisible: boolean;
  ownershipDataVisible: boolean;
  buyOffDataVisible: boolean;
}
type Props = AssignmentCadastreFormComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentCadastreFormComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  private id: string = uuid();

  constructor(props) {
    super(props);

    const { cadastre, municipality } = this.props;

    this.state = {
      appartmentShareVisible: this.showHideAppartmentShare(
        value(
          cadastre,
          "cadastralDetails.cadastralLotSize",
          CadastralLotSize.EntireLot
        )
      ),
      endDateVisible: this.showHideEndDate(
        value(cadastre, "ownershipDetails.groundLeasePeriod")
      ),
      ownershipDataVisible: this.showHideOwnershipData(
        value(cadastre, "ownershipDetails.ownershipType")
      ),
      buyOffDataVisible: !!value(cadastre, "ownershipDetails.hasBuyoutOption"),
    };

    const surface = NumberUtil.surfaceToSurfacesObject(
      value(cadastre, "cadastralDetails.surface")
    );

    const surfaceByDivision = NumberUtil.surfaceToSurfacesObject(
      value(cadastre, "cadastralDetails.surfaceByDivision")
    );

    this.formControls = {
      displayName: { value: this.props.displayName },
      municipality: {
        value: value(cadastre, "cadastralDetails.municipality", municipality),
      },
      municipalCode: {
        value: value(cadastre, "cadastralDetails.municipalCode"),
      },
      section: { value: value(cadastre, "cadastralDetails.section") },
      parcel: { value: value(cadastre, "cadastralDetails.parcel") },
      cadastralLotSize: {
        value: value(
          cadastre,
          "cadastralDetails.cadastralLotSize",
          CadastralLotSize.EntireLot
        ),
        onChange: (ref) => {
          this.setState({
            appartmentShareVisible: this.showHideAppartmentShare(ref.value),
          });
          if (ref.value === CadastralLotSize.Condominium)
            return {
              surface: null,
              surfaceHa: null,
              surfaceA: null,
              surfaceCa: null,
            };
        },
      },
      share: { value: value(cadastre, "cadastralDetails.share") },
      indexNumber: { value: value(cadastre, "cadastralDetails.indexNumber") },
      surface: {
        value: value(cadastre, "cadastralDetails.surface"),
        onChange: (ref) => {
          const { hectare, are, centiare } = NumberUtil.surfaceToSurfacesObject(
            ref.value
          );
          return {
            surfaceHa: hectare,
            surfaceA: are,
            surfaceCa: centiare,
          };
        },
      },
      surfaceHa: {
        value: surface.hectare,
        onChange: (ref, get) => {
          const surface = NumberUtil.surfacesToSurface(
            ref.value,
            get("surfaceA").value,
            get("surfaceCa").value
          );
          return {
            surface,
          };
        },
      },
      surfaceA: {
        value: surface.are,
        onChange: (ref, get) => {
          const surface = NumberUtil.surfacesToSurface(
            get("surfaceHa").value,
            ref.value,
            get("surfaceCa").value
          );
          return {
            surface,
          };
        },
      },
      surfaceCa: {
        value: surface.centiare,
        onChange: (ref, get) => {
          const surface = NumberUtil.surfacesToSurface(
            get("surfaceHa").value,
            get("surfaceA").value,
            ref.value
          );
          return {
            surface,
          };
        },
      },
      surfaceByDivision: {
        value: value(cadastre, "cadastralDetails.surfaceByDivision"),
        onChange: (ref) => {
          const { hectare, are, centiare } = NumberUtil.surfaceToSurfacesObject(
            ref.value
          );
          return {
            surfaceHaByDivision: hectare,
            surfaceAByDivision: are,
            surfaceCaByDivision: centiare,
          };
        },
      },
      surfaceHaByDivision: {
        value: surfaceByDivision.hectare,
        onChange: (ref, get) => {
          const surfaceByDivision = NumberUtil.surfacesToSurface(
            ref.value,
            get("surfaceAByDivision").value,
            get("surfaceCaByDivision").value
          );
          return {
            surfaceByDivision,
          };
        },
      },
      surfaceAByDivision: {
        value: surfaceByDivision.are,
        onChange: (ref, get) => {
          const surfaceByDivision = NumberUtil.surfacesToSurface(
            get("surfaceHaByDivision").value,
            ref.value,
            get("surfaceCaByDivision").value
          );
          return {
            surfaceByDivision,
          };
        },
      },
      surfaceCaByDivision: {
        value: surfaceByDivision.centiare,
        onChange: (ref, get) => {
          const surfaceByDivision = NumberUtil.surfacesToSurface(
            get("surfaceHaByDivision").value,
            get("surfaceAByDivision").value,
            ref.value
          );
          return {
            surfaceByDivision,
          };
        },
      },
      municipalityByDevision: {
        value: value(cadastre, "apartmentRights.municipalityByDevision"),
      },
      sectionByDevision: {
        value: value(cadastre, "apartmentRights.sectionByDevision"),
      },
      parcelNumberByDevision: {
        value: value(cadastre, "apartmentRights.parcelNumberByDevision"),
      },
      isOriginatedFromMultipleBusinesses: {
        value: value(
          cadastre,
          "apartmentRights.isOriginatedFromMultipleBusinesses"
        ),
      },
      complex: {
        value: value(cadastre, "apartmentRights.complex"),
      },

      notarialDescription: { value: value(cadastre, "notarialDescription") },

      hasNoteOrDrag: { value: value(cadastre, "hasNoteOrDrag", false) },
      isMoreRealEstatePresent: {
        value: value(cadastre, "isMoreRealEstatePresent", false),
      },
      indicationEstimatedSize: {
        value: value(
          cadastre,
          "cadastralDetails.indicationEstimatedSize",
          false
        ),
      },

      ownershipType: {
        value: value(cadastre, "ownershipDetails.ownershipType"),
        onChange: (ref) => {
          this.setState({
            ownershipDataVisible: this.showHideOwnershipData(ref.value),
          });
        },
      },
      leaseholdPrice: {
        value: value(cadastre, "ownershipDetails.leaseholdPrice"),
      },
      purchasePrice: {
        value: value(cadastre, "ownershipDetails.purchasePrice"),
      },
      fixedVariable: {
        value: value(cadastre, "ownershipDetails.fixedVariable"),
      },
      groundLeasePeriod: {
        value: value(cadastre, "ownershipDetails.groundLeasePeriod"),
        onChange: (ref) => {
          this.setState({ endDateVisible: this.showHideEndDate(ref.value) });
        },
      },
      hasBuyoutOption: {
        value: value(cadastre, "ownershipDetails.hasBuyoutOption"),
        onChange: (ref) => {
          this.setState({ buyOffDataVisible: !!ref.value });
        },
      },
      endDate: { value: value(cadastre, "ownershipDetails.endDate") },
      boughtOutUntil: {
        value: value(cadastre, "ownershipDetails.boughtOutUntil"),
      },
      leaseholdBoughtOffForever: {
        value: value(
          cadastre,
          "ownershipDetails.leaseholdBoughtOffForever",
          false
        ),
      },
      leaseholdOwnerType: {
        value: value(cadastre, "ownershipDetails.leaseholdOwnerType"),
      },
    };

    // Bind this
    this.onRemoveCadastreClick = this.onRemoveCadastreClick.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.address, this.props.address)) {
      const cadastre: Cadastre = {
        ...this.props.cadastre,
        address: {
          ...(this.props.cadastre.address || {}),
          ...this.props.address,
        },
      };

      this.props.onChange(cadastre);
    }
  }

  public render() {
    return (
      <div styleName="cadastre__form">
        <Form
          name="cadastre"
          formControls={this.formControls}
          form={(form) => (this.form = form)}
          onChange={this.onChangeHandler}
        >
          <div styleName="header">
            <h2>
              <ResourceText resourceKey="cadastralData" />
            </h2>
            {this.props.cadastre.map && (
              <div
                className="btn btn-primary icon-left"
                onClick={() =>
                  this.onFileDownload(this.props.cadastre.map.urlOriginal)
                }
              >
                <span className="fal fa-download" />
                <ResourceText resourceKey="downloadCadastreMap" />
              </div>
            )}
            {this.props.cadastre.message && (
              <div
                className="btn btn-primary icon-left"
                onClick={() =>
                  this.onFileDownload(this.props.cadastre.message.urlOriginal)
                }
              >
                <span className="fal fa-download" />
                <ResourceText resourceKey="downloadCadastreMessage" />
              </div>
            )}
            <div
              styleName="close"
              onClick={this.onRemoveCadastreClick}
              data-cy="CY-removeCadastre"
            >
              <i className="fal fa-times" />
              <ResourceText resourceKey="remove" />
            </div>
          </div>
          <div styleName="form">
            <div styleName="basic-and-attention">
              <div styleName="basic">
                <h2 styleName="section__label">
                  <ResourceText resourceKey="cadastralBasicData" />
                </h2>
                <div className="form__row">
                  <label htmlFor="displayName">
                    <ResourceText resourceKey="cadastreDisplayname" />
                  </label>
                  <Input.Text name="displayName" disabled />
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="municipality">
                        <ResourceText resourceKey="cadastreMunicipality" />
                      </label>
                      <Input.Text
                        name="municipality"
                        data-cy="CY-cadastreMunicipality"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="municipalCode">
                        <ResourceText resourceKey="cadastreMunicipalCode" />
                      </label>
                      <Input.Text
                        name="municipalCode"
                        data-cy="CY-cadastreMunicipalCode"
                        maxLength={5}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="section">
                        <ResourceText resourceKey="cadastreSection" />
                      </label>
                      <Input.Text
                        name="section"
                        data-cy="CY-cadastreSection"
                        maxLength={2}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="parcel">
                        <ResourceText resourceKey="cadastreParcel" />
                      </label>
                      <Input.Text
                        name="parcel"
                        maxLength={5}
                        data-cy="CY-cadastreParcel"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div styleName="attention">
                <h2 styleName="section__label">
                  <ResourceText resourceKey="cadastralAttentionData" />
                </h2>

                <div className="form__row">
                  <Input.Switch
                    name="hasNoteOrDrag"
                    label="cadastreHasNoteOrDrag"
                    labelPosition={SwitchLabelPosition.Post}
                    on={true}
                    off={false}
                    data-cy="CY-cadastreHasNoteOrDrag"
                  />

                  <Input.Switch
                    name="isMoreRealEstatePresent"
                    label="cadastreIsMoreRealEstatePresent"
                    labelPosition={SwitchLabelPosition.Post}
                    on={true}
                    off={false}
                    data-cy="CY-cadastreIsMoreRealEstatePresent"
                  />

                  <Input.Switch
                    name="indicationEstimatedSize"
                    label="cadastreIndicationEstimatedSize"
                    labelPosition={SwitchLabelPosition.Post}
                    on={true}
                    off={false}
                    data-cy="CY-cadastreIndicationEstimatedSize"
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <h2 styleName="section__label">
                <ResourceText resourceKey="cadastralSizeData" />
              </h2>

              <label htmlFor="cadastralLotSize">
                <ResourceText resourceKey="cadastreCadastralLotSize" />
              </label>

              <Input.NewSelect
                name="cadastralLotSize"
                placeholder="makeAChoiceExtended"
                values={this.props.cadastralLotSizeOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>

            {this.state.appartmentShareVisible && (
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="share">
                      <ResourceText resourceKey="cadastreShare" />
                    </label>
                    <Input.Text name="share" data-cy="CY-cadastreShare" />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="indexNumber">
                      <ResourceText resourceKey="cadastreIndexNumber" />
                    </label>
                    <Input.Text
                      name="indexNumber"
                      data-cy="CY-cadastreIndexNumber"
                      maxLength={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {!this.state.appartmentShareVisible && (
              <div className="form__row">
                <label htmlFor="surface">
                  <ResourceText resourceKey="parcelSurface" />
                </label>
                <div className="form__group">
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number
                        name="surface"
                        round
                        min={0}
                        max={99999999999999}
                        data-cy="CY-cadastreSurface"
                      />
                      <div className="post">
                        <ResourceText
                          resourceKey="cadastreAcreageAffix"
                          asHtml
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number
                        name="surfaceHa"
                        round
                        min={0}
                        max={9999999999}
                      />
                      <div className="post">
                        <ResourceText resourceKey="cadastreAcreageHAAffix" />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="surfaceA" round min={0} max={99} />
                      <div className="post">
                        <ResourceText resourceKey="cadastreAcreageAAffix" />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="surfaceCa" round min={0} max={99} />
                      <div className="post">
                        <ResourceText resourceKey="cadastreAcreageCAAffix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form__row">
              <label htmlFor="notarialDescription">
                <ResourceText resourceKey="cadastreNotarialDescription" />
              </label>
              <Input.Textarea
                name="notarialDescription"
                data-cy="CY-cadastreNotarialDescription"
              />
            </div>

            {this.state.appartmentShareVisible && (
              <div className="form__row">
                <h2 styleName="section__label">
                  <ResourceText resourceKey="splittingTime" />
                </h2>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="municipalityByDevision">
                        <ResourceText resourceKey="cadastreMunicipality" />
                      </label>
                      <Input.Text
                        name="municipalityByDevision"
                        data-cy="CY-municipalityByDevision"
                      />
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group stretch">
                      <div className="column">
                        <label htmlFor="section">
                          <ResourceText resourceKey="cadastreSection" />
                        </label>
                        <Input.Text
                          name="sectionByDevision"
                          data-cy="CY-sectionByDevision"
                          maxLength={2}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="parcelNumberByDevision">
                          <ResourceText resourceKey="parcelNumberByDevision" />
                        </label>
                        <Input.Text
                          name="parcelNumberByDevision"
                          data-cy="CY-parcelNumberByDevision"
                          maxLength={5}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <label htmlFor="surface">
                      <ResourceText resourceKey="parcelSurface" />
                    </label>
                    <div className="form__group">
                      <div className="column">
                        <div className="input__helper">
                          <Input.Number
                            name="surfaceByDivision"
                            round
                            min={0}
                            max={99999999999999}
                            data-cy="CY-surfaceByDivision"
                          />
                          <div className="post">
                            <ResourceText
                              resourceKey="cadastreAcreageAffix"
                              asHtml
                            />
                          </div>
                        </div>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <div className="input__helper">
                          <Input.Number
                            name="surfaceHaByDivision"
                            round
                            min={0}
                            max={9999999999}
                          />
                          <div className="post">
                            <ResourceText resourceKey="cadastreAcreageHAAffix" />
                          </div>
                        </div>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <div className="input__helper">
                          <Input.Number
                            name="surfaceAByDivision"
                            round
                            min={0}
                            max={99}
                          />
                          <div className="post">
                            <ResourceText resourceKey="cadastreAcreageAAffix" />
                          </div>
                        </div>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <div className="input__helper">
                          <Input.Number
                            name="surfaceCaByDivision"
                            round
                            min={0}
                            max={99}
                          />
                          <div className="post">
                            <ResourceText resourceKey="cadastreAcreageCAAffix" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <label htmlFor="complex">
                    <ResourceText resourceKey="complexDescription" />
                  </label>
                  <Input.Textarea name="complex" data-cy="CY-cadastreComplex" />
                </div>
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="isOriginatedFromMultipleBusinesses"
                        label="isOriginatedFromMultipleBusinesses"
                        labelPosition={SwitchLabelPosition.Pre}
                        on={true}
                        off={false}
                        data-cy="CY-isOriginatedFromMultipleBusinesses"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form__row">
              <h2 styleName="section__label">
                <ResourceText resourceKey="cadastralOwnershipData" />
              </h2>
              <Input.NewSelect
                name="ownershipType"
                placeholder="makeAChoiceExtended"
                values={this.props.ownershipTypeOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>

            {this.state.ownershipDataVisible && (
              <div>
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="leaseholdPrice"
                          pretty
                          asPrice
                          data-cy="CY-cadastreLeaseHoldPrice"
                        />
                        <div className="post full">
                          <ResourceText resourceKey="perYear" />
                        </div>
                      </div>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.RadioGroup
                        asButtonList
                        name="fixedVariable"
                        data-cy="CY-cadastreFixedVariable"
                      >
                        {this.props.fixedVariableOptions.map(
                          (fixedVariableOption, idx) => (
                            <Input.Radio
                              value={fixedVariableOption.value}
                              label={fixedVariableOption.displayName}
                              key={idx}
                              data-cy="CY-cadastreFixedVariableItem"
                            />
                          )
                        )}
                      </Input.RadioGroup>
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.RadioGroup
                        asButtonList
                        name="groundLeasePeriod"
                        data-cy="CY-cadastreGroundLeasePeriod"
                      >
                        {this.props.groundLeasePeriodOptions.map(
                          (groundLeasePeriodOption, idx) => (
                            <Input.Radio
                              value={groundLeasePeriodOption.value}
                              label={groundLeasePeriodOption.displayName}
                              key={idx}
                              data-cy="CY-cadastreGroundLeasePeriodItem"
                            />
                          )
                        )}
                      </Input.RadioGroup>
                    </div>
                    {this.state.endDateVisible && (
                      <div className="column__join">
                        <div className="column__spacer" />
                        <div className="column">
                          <label htmlFor="endDate" className="pre">
                            <ResourceText resourceKey="cadastreEndDate" />
                          </label>
                        </div>
                        <div className="column__spacer" />
                        <div className="column">
                          <Input.Datepicker
                            name="endDate"
                            data-cy="CY-cadastreEndDate"
                          />
                        </div>
                      </div>
                    )}
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Switch
                        name="hasBuyoutOption"
                        label="hasBuyoutOption"
                        labelPosition={SwitchLabelPosition.Pre}
                        on={true}
                        off={false}
                        data-cy="CY-cadastreHasBuyoutOptions"
                      />
                    </div>
                  </div>
                </div>

                {this.state.buyOffDataVisible && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="boughtOutUntil">
                          <ResourceText resourceKey="cadastreLeaseHoldUntill" />
                        </label>
                        <Input.Datepicker
                          name="boughtOutUntil"
                          data-cy="CY-cadastreBoughtOutUntil"
                        />
                      </div>
                      <div className="column__textspacer push-label">
                        <ResourceText resourceKey="andOr" />
                      </div>
                      <div className="column push-label">
                        <Input.Switch
                          name="leaseholdBoughtOffForever"
                          label="leaseholdBoughtOffForever"
                          labelPosition={SwitchLabelPosition.Post}
                          on={true}
                          off={false}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__row">
                  <label htmlFor="leaseholdOwnerType">
                    <ResourceText resourceKey="leaseholdOwnerType" />
                  </label>
                  <Input.RadioGroup
                    asButtonList
                    canUnselect
                    name="leaseholdOwnerType"
                  >
                    {this.props.leaseholdOwnerTypeOptions.map(
                      (leaseholdOwnerTypeOption, idx) => (
                        <Input.Radio
                          value={leaseholdOwnerTypeOption.value}
                          label={leaseholdOwnerTypeOption.displayName}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }

  private showHideAppartmentShare(value: CadastralLotSize): boolean {
    switch (value) {
      case CadastralLotSize.Condominium:
        return true;
      default:
        return false;
    }
  }

  private showHideEndDate(value: GroundLeasePeriod): boolean {
    switch (value) {
      case GroundLeasePeriod.Permanent:
        return false;
      default:
        return true;
    }
  }

  private showHideOwnershipData(value: OwnershipType): boolean {
    const reference = this.props.ownershipTypeOptions.find(
      (o) => o.value === value
    );
    return !!reference && !!reference.isLeasehold;
  }

  private onFileDownload(file: string) {
    if (!file) return;
    const connector = /\?/g.test(file) ? "&" : "?";

    window.open(
      `${file}${connector}access_token=${this.props.idToken}`,
      "_blank"
    );
  }

  private onRemoveCadastreClick() {
    this.props.onRemove(this.id);
  }

  private onChangeHandler(values: FormReturnValue) {
    const { cadastre } = this.props;
    const adminAreaLevel2 = get(cadastre.address, "adminAreaLevel2") || {};
    const updatedCadastre: Cadastre = {
      ...cadastre,
      address: {
        ...cadastre.address,
        adminAreaLevel2: {
          ...adminAreaLevel2,
          name: values.municipality,
        },
      },
      cadastralDetails: {
        ...cadastre.cadastralDetails,
        cadastralLotSize: values.cadastralLotSize,
        indexNumber: values.indexNumber,
        indicationEstimatedSize: values.indicationEstimatedSize,
        municipalCode: values.municipalCode,
        parcel: values.parcel,
        section: values.section,
        share: values.share,
        surface: NumberUtil.surfacesToSurface(
          values.surfaceHa,
          values.surfaceA,
          values.surfaceCa
        ),
        surfaceByDivision: NumberUtil.surfacesToSurface(
          values.surfaceHaByDivision,
          values.surfaceAByDivision,
          values.surfaceCaByDivision
        ),
        municipality: values.municipality,
      },
      ownershipDetails: {
        ...cadastre.ownershipDetails,
        boughtOutUntil: values.boughtOutUntil,
        leaseholdBoughtOffForever: values.leaseholdBoughtOffForever,
        leaseholdOwnerType: values.leaseholdOwnerType || null,
        endDate: values.endDate,
        fixedVariable: values.fixedVariable,
        groundLeasePeriod: values.groundLeasePeriod,
        hasBuyoutOption: values.hasBuyoutOption,
        leaseholdPrice: values.leaseholdPrice,
        ownershipType: values.ownershipType,
        purchasePrice: values.purchasePrice,
      },
      hasNoteOrDrag: values.hasNoteOrDrag,
      isMoreRealEstatePresent: values.isMoreRealEstatePresent,
      notarialDescription: values.notarialDescription,
      apartmentRights: {
        ...cadastre.apartmentRights,
        municipalityByDevision: values.municipalityByDevision,
        sectionByDevision: values.sectionByDevision,
        parcelNumberByDevision: values.parcelNumberByDevision,
        isOriginatedFromMultipleBusinesses:
          values.isOriginatedFromMultipleBusinesses,
        complex: values.complex,
      },
    };

    this.props.onChange(updatedCadastre);
  }
}
