import * as React from "react";
import { FC, memo, useMemo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  numberOfLines?: number;
  style?: CSSProperties;
};

export const TextComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ numberOfLines, style }) => {
    const renderLines = useMemo(() => {
      const mapper = new Array(numberOfLines || 1).fill(null);

      return (
        <>
          {mapper.map((_, idx) => (
            <div
              styleName="text"
              style={{
                width: `${100 - idx * 12.5}%`
              }}
              key={idx}
            />
          ))}
        </>
      );
    }, [numberOfLines]);

    return (
      <div styleName="wrapper" style={style}>
        {renderLines}
      </div>
    );
  })
);
