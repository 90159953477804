import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Hint, ResourceText } from "@haywork/modules/shared";
import noop from "lodash-es/noop";

const styles = require("./important-hint.component.scss");

interface UiImportantHintProps {
  message: string;
  asHint?: boolean;
  url?: string;
}

export const UiImportantHint = CSSModules(styles, { allowMultiple: true })(
  ({ message, asHint, url }: UiImportantHintProps) => {
    return (
      <div
        styleName={classNames("important-hint", { "as-link": !!url })}
        onClick={() => (!!url ? window.open(url, "_blank") : noop)}
      >
        <Hint message={message} shouldRender={!!asHint}>
          <div styleName="inner">
            <i className="fa fa-exclamation-circle" />
            {!asHint && <ResourceText resourceKey={message} />}
          </div>
        </Hint>
      </div>
    );
  }
);
