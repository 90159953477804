import { RelationSnapShot } from "@haywork/api/kolibri";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { PresenceComponent, PresenceComponentProps } from "./presence";
import { employeeIds } from "./selectors";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {
  employees: RelationSnapShot[];
  employeeIds: string[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  PresenceComponentProps,
  AppState
> = (state, ownProps) => {
  const { employees } = state.account;
  const { entityId, entityType } = ownProps;

  return {
    employees,
    employeeIds: employeeIds(state, entityType, entityId)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PresenceComponentProps
> = (dispatch) => ({});

export type PresenceContainerProps = StateProps & DispatchProps;
export const PresenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PresenceComponent);
