import {
  AssignmentSnapShot,
  SortOrder,
  AssignmentOrderByField
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum listActions {
  SetAssignments = "Acquisition.List.SetAssignments",
  SetAssignmentsStatus = "Acquisition.List.SetAssignmentsStatus",
  UpdateListItem = "Acquisition.List.UpdateListItem",
  DeleteListItem = "Acquisition.List.DeleteListItem",
  SetOrdering = "Acquisition.List.SetOrdering",
  SetFilters = "Acquisition.List.SetFilters",
  SetScrollOffset = "Acquisition.List.SetScrollOffset",
  ResetApp = "RESET_APP"
}

type SetAssignments = {
  type: listActions.SetAssignments;
  assignments: AssignmentSnapShot[];
  total: number;
  reset: boolean;
};
const setAssignments = (
  assignments: AssignmentSnapShot[],
  total: number,
  reset: boolean
) => ({
  type: listActions.SetAssignments,
  assignments,
  total,
  reset
});

type SetAssignmentsStatus = {
  type: listActions.SetAssignmentsStatus;
  assignmentsStatus: string;
};
const setAssignmentsStatus = (assignmentsStatus: string) => ({
  type: listActions.SetAssignmentsStatus,
  assignmentsStatus
});

type UpdateListItem = {
  type: listActions.UpdateListItem;
  snapshot: AssignmentSnapShot;
};
const updateListItem = (snapshot: AssignmentSnapShot) => ({
  type: listActions.UpdateListItem,
  snapshot
});

type DeleteListItem = {
  type: listActions.DeleteListItem;
  id: string;
};
const deleteListItem = (id: string) => ({
  type: listActions.DeleteListItem,
  id
});

type SetOrdering = {
  type: listActions.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: AssignmentOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: AssignmentOrderByField
) => ({
  type: listActions.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: listActions.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: listActions.SetFilters,
  filters
});

type SetScrollOffset = {
  type: listActions.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: listActions.SetScrollOffset,
  scrollOffset
});

type ResetApp = {
  type: listActions.ResetApp;
};

export type ListActions =
  | SetAssignments
  | SetAssignmentsStatus
  | SetFilters
  | UpdateListItem
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | DeleteListItem
  | ResetApp;
export const Actions = {
  setAssignments,
  setAssignmentsStatus,
  setFilters,
  updateListItem,
  deleteListItem,
  setOrdering,
  setScrollOffset
};
