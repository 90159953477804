import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { PROJECTROUTES } from "@haywork/constants";
import {
  ProjectDetailGeneralContainer,
  ProjectDetailDossierContainer,
  ProjectDetailNetworkContainer,
  ProjectDetailTimelineContainer,
  ProjectEditContainer,
  ProjectDetailTypesContainer,
  ProjectDetailBuildnumbersContainer
} from "@haywork/modules/project";

const route = RouteUtil.mapStaticRouteValues;
export const ProjectDetailRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ PROJECTROUTES.DETAIL.URI }
      render={ () => <Redirect to={ route(PROJECTROUTES.GENERAL.URI, { id }) } /> } />
    <Route exact path={ PROJECTROUTES.GENERAL.URI } component={ ProjectDetailGeneralContainer } />
    <Route path={ PROJECTROUTES.TIMELINE.URI } component={ ProjectDetailTimelineContainer } />
    <Route path={ PROJECTROUTES.NETWORK.URI } component={ ProjectDetailNetworkContainer } />
    <Route path={ PROJECTROUTES.DOSSIER.URI } component={ ProjectDetailDossierContainer } />
    <Route path={ PROJECTROUTES.TYPES.URI } component={ ProjectDetailTypesContainer } />
    <Route path={ PROJECTROUTES.BUILD_NUMBERS.URI } component={ ProjectDetailBuildnumbersContainer } />
    <Route path={ PROJECTROUTES.EDIT.URI } component={ ProjectEditContainer } />
  </Switch>;
};
