import * as PropTypes from "prop-types";
import * as React from "react";
import { v4 as uuid } from "uuid";

import {
  Form,
  FormReturnValue,
  FormReference,
  FormControls
} from "@haywork/modules/form";
import { FormArrayRow } from "./array.component";

interface ArrayItemComponentProps {
  row: FormArrayRow;
  name: string;
  idx: number;
  showActions: boolean;
  showAddAction: boolean;
  className?: string;

  onAddClick: () => void;
  onSubtractClick: (idx: number, id: string, values?: FormReturnValue) => void;
  onFormChange: (values: FormReturnValue, valid: boolean, idx: number) => void;
}
interface ArrayItemComponentState {
  values: { [key: string]: any };
}

export class ArrayItemComponent extends React.Component<
  ArrayItemComponentProps,
  ArrayItemComponentState
> {
  public static childContextTypes = {
    values: PropTypes.object
  };
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      values: this.mapFormControlsToValues(this.props.row.formControls)
    };

    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.bindFormReference = this.bindFormReference.bind(this);
    this.onSubtractClickHandler = this.onSubtractClickHandler.bind(this);
  }

  public getChildContext() {
    return {
      values: this.state.values
    };
  }

  public render() {
    const { row } = this.props;
    return (
      <div className="arrayinput__row">
        <Form
          name={this.props.name}
          formControls={row.formControls}
          asSubForm
          onChange={this.onFormChangeHandler}
          form={this.bindFormReference}
          className={this.props.className}
        >
          {this.props.children}
        </Form>
        {this.props.showActions && (
          <div className="arrayinput__actions">
            {this.props.showAddAction && (
              <div
                onClick={this.props.onAddClick}
                className="action add"
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.ItemAddButton`
                }
              >
                <i id="addArrayItem" className="fal fa-plus-circle" />
              </div>
            )}
            <div
              onClick={this.onSubtractClickHandler}
              className="action subtract"
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.ItemSubtractButton`
              }
            >
              <i id="subtractArrayItem" className="fal fa-minus-circle" />
            </div>
          </div>
        )}
      </div>
    );
  }

  private onFormChangeHandler(values: FormReturnValue, valid: boolean) {
    this.props.onFormChange(
      { ...values, arrayId: this.props.row.id },
      valid,
      this.props.idx
    );
    this.setState({ values });
  }

  private onSubtractClickHandler() {
    const values = this.form ? this.form.getValues() : undefined;
    if (this.form && this.props.idx === 0) this.form.clear();

    this.props.onSubtractClick(this.props.idx, this.props.row.id, values);
  }

  private bindFormReference(form: FormReference) {
    if (!!form && !this.form) this.form = form;
  }

  private mapFormControlsToValues(controls: FormControls) {
    const values = {};
    for (const key in controls) {
      values[key] = controls[key].value;
    }

    return values;
  }
}
