import { WidgetEntityType } from "@haywork/api/authorization";
import {
  AssignmentType,
  LinkedRelation,
  ProjectAssignment,
  ProjectAssignmentLinkRelationRequest,
  RelationRole,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes from "@haywork/modules/notes-v3";
import { ProjectDetailNetworkContainerProps } from "@haywork/modules/project";
import { PageLoader } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { AssignmentUtil, FormControlUtil, RelationUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentDetailNetworkItemComponent } from "./list-item.component";

const styles = require("./detail-network.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface ExtendedLinkedRelation extends LinkedRelation {
  relationRole: RelationRole;
}
export interface ProjectDetailNetworkComponentProps {}
interface State {
  addingContact: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = ProjectDetailNetworkComponentProps &
  ProjectDetailNetworkContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailNetworkComponent extends React.Component<
  Props,
  State
> {
  private inputRef: HTMLInputElement;
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      addingContact: false,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedContact: { value: "", validators: [Validators.required()] },
      relationRole: { value: RelationRole.Applicant },
    };

    this.toggleAddingContact = this.toggleAddingContact.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.contactLinkTypeChangeHandler = this.contactLinkTypeChangeHandler.bind(
      this
    );
    this.contactLinkRemoveFromListHandler = this.contactLinkRemoveFromListHandler.bind(
      this
    );
    this.renderAddContact = this.renderAddContact.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.projectAssignment.id,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    const { forSale } = this.props.projectAssignment;
    const contactLinks = AssignmentUtil.getAssignmentAroRelations(
      this.props.projectAssignment
    );

    return (
      <div styleName="body">
        <PageHeader
          title="aroNetworkHeader"
          subTitle={this.props.projectAssignment.displayName}
          actions={
            <>
              <Notes />
              <Button
                label="addContact"
                icon={<Icon name="plus" size={18} light />}
                category="primary"
                onClick={this.toggleAddingContact}
              />
            </>
          }
        />

        {this.props.saveContactState === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}

        {this.props.saveContactState === REQUEST.ERROR &&
          this.renderContactAddError()}

        {this.state.addingContact && (
          <div styleName="add-contact">
            <div styleName="add-contact__avatar">
              <div styleName="avatar" />
            </div>
            {this.renderAddContact()}
          </div>
        )}

        {contactLinks.length > 0 && (
          <div styleName="list">
            {contactLinks.map((contactLink, idx) => (
              <ErrorBoundary key={contactLink.id}>
                <AssignmentDetailNetworkItemComponent
                  contact={contactLink}
                  zebra={idx % 2 !== 0}
                  contactLinkRemoveFromList={
                    this.contactLinkRemoveFromListHandler
                  }
                  contactLinkTypeChange={this.contactLinkTypeChangeHandler}
                  relationRoles={this.props.relationRoles}
                  navigate={this.props.navigate}
                  forSale={forSale}
                  projectId={this.props.projectAssignment.id}
                />
              </ErrorBoundary>
            ))}
          </div>
        )}
        {contactLinks.length === 0 && (
          <div styleName="emptyState">
            <div styleName="content">
              <div styleName="icon" />
              <div styleName="text">
                <h2>
                  <I18n value="emptyStateNoNetworkTitle" />
                </h2>
                <p>
                  <I18n value="emptyStateNoNetworkText" />
                </p>
              </div>
            </div>
          </div>
        )}

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  private contactLinkRemoveFromListHandler(contact: ExtendedLinkedRelation) {
    if (
      !get(contact, "id") ||
      !get(contact, "typeOfRelation") ||
      !get(contact, "relationRole")
    )
      return;

    const relation: ProjectAssignmentLinkRelationRequest = {
      relationId: get(contact, "id"),
      relationType: get(contact, "typeOfRelation"),
      projectAssignmentId: this.props.projectAssignment.id,
      relationRole: get(contact, "relationRole"),
    };

    const updatedProjectAssignment = AssignmentUtil.removeLinkedRelation(
      this.props.projectAssignment,
      contact
    ) as ProjectAssignment;
    this.props.unlinkRelation(relation, updatedProjectAssignment);
    this.setState({ addingContact: false });
  }

  private contactLinkTypeChangeHandler(
    type: RelationRole,
    contact: ExtendedLinkedRelation
  ) {
    const updatedProjectAssignment = AssignmentUtil.updateLinkedRelation(
      this.props.projectAssignment,
      type,
      contact
    ) as ProjectAssignment;
    const newState: SingleProjectState = {
      ...this.props.currentComponentState,
      projectAssignment: updatedProjectAssignment,
    };

    this.props.updateProject(newState, this.props.path);
    this.props.saveProject(updatedProjectAssignment);
  }

  private toggleAddingContact() {
    this.setState({ addingContact: !this.state.addingContact }, () => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    });
  }

  private renderAddContact(): React.ReactElement<HTMLDivElement> {
    const relationRoles = RelationUtil.mapRelationRoles(
      this.props.relationRoles,
      AssignmentType.Project,
      this.props.projectAssignment.forSale
    );

    return (
      <div styleName="add-contact__body">
        <Form
          formControls={this.formControls}
          name="contactLink"
          onSubmit={this.onSubmitHandler}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.RelationQuery
                  name="linkedContact"
                  filterByRelationTypes={[
                    RelationType.ContactPerson,
                    RelationType.ContactCompany,
                  ]}
                  onAdd={this.addNewRelation}
                />
              </div>
              <div styleName="function">
                <Input.NewSelect
                  name="relationRole"
                  values={relationRoles}
                  displayProp="label"
                  valuesProp="value"
                />
              </div>
            </div>
            <div styleName="action">
              <button type="submit" className="btn btn-success">
                <I18n value="add" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    if (
      !get(values.linkedContact, "id") ||
      !get(values.linkedContact, "typeOfRelation") ||
      !get(values, "relationRole")
    )
      return;

    const relation: ProjectAssignmentLinkRelationRequest = {
      relationId: get(values.linkedContact, "id"),
      relationType: get(values.linkedContact, "typeOfRelation"),
      projectAssignmentId: this.props.projectAssignment.id,
      relationRole: get(values, "relationRole"),
    };

    const updatedProjectAssignment = AssignmentUtil.addLinkedRelation(
      this.props.projectAssignment,
      values.relationRole,
      values.linkedContact
    ) as ProjectAssignment;
    this.props.linkRelation(relation, updatedProjectAssignment);
    this.setState({ addingContact: false });
  }

  private renderContactAddError() {
    return (
      <div styleName="error" className="alert alert-danger">
        <I18n value="contactAlreadyExistsError" />
      </div>
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedContact: RelationSnapShot) {
    this.formRef.update({
      linkedContact,
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
