import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REALESTATEAGENCYROUTES } from "@haywork/constants";
import { SettingsRealEstateAgencyContainerProps } from "@haywork/modules/settings/modules/real-estate-agency";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./real-estate-agency.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsRealEstateAgencyComponentProps {}
interface State {}
type Props = SettingsRealEstateAgencyComponentProps &
  SettingsRealEstateAgencyContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsRealEstateAgencyComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.editRealEstateAgency = this.editRealEstateAgency.bind(this);
  }

  public render() {
    const { realEstateAgency, realEstateAssociations } = this.props;
    const realEstateAccociation = realEstateAssociations
      ? realEstateAssociations.find(
          (info) =>
            info.value ===
            realEstateAgency.associationInfo.realEstateAssociation
        )
      : null;

    return (
      <div styleName="general">
        <PageHeader
          title="realEstateAgency"
          subTitle="settingsMenuMyCompany"
          actions={
            <Button
              label="showEdit"
              category="success"
              onClick={this.editRealEstateAgency}
            />
          }
        />

        <div styleName="general__body">
          <div styleName="inner">
            <h1>{realEstateAgency.name}</h1>
            <div styleName="general__meta">
              <div styleName="row">
                <div styleName="column">
                  <h3>
                    <I18n value="branchOrganisation" />
                  </h3>
                  {!!realEstateAccociation && realEstateAccociation.displayName}
                </div>
                <div styleName="column">
                  <h3>
                    <I18n value="membershipNumber" />
                  </h3>
                  {realEstateAgency.associationInfo.membershipNumber}
                </div>
              </div>

              <div styleName="row">
                <div styleName="column">
                  <h3>
                    <I18n value="commerceNumber" />
                  </h3>
                  {realEstateAgency.legalRegistration.number}
                </div>
                <div styleName="column">
                  <h3>
                    <I18n value="realEstateAgencyRegisteredOn" />
                  </h3>
                  <FormattedDate
                    value={realEstateAgency.legalRegistration.date}
                  />
                </div>
                <div styleName="column">
                  <h3>
                    <I18n value="realEstateAgencyRegisteredAt" />
                  </h3>
                  {realEstateAgency.legalRegistration.locality}
                </div>
              </div>

              <div styleName="row">
                <div styleName="column">
                  <h3>
                    <I18n value="realEstateAgencyIBAN" />
                  </h3>
                  {realEstateAgency.bankAccount.number}
                </div>
                <div styleName="column">
                  <h3>
                    <I18n value="realEstateAgencyAppointment" />
                  </h3>
                  {realEstateAgency.bankAccount.name}
                </div>
                <div styleName="column">
                  <h3>
                    <I18n value="realEstateAgencyLocality" />
                  </h3>
                  {realEstateAgency.bankAccount.localityName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private editRealEstateAgency() {
    const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
      id: this.props.realEstateAgency.id,
    });
    this.props.navigate(path);
  }
}
