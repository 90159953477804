import {
  RealEstateAgency,
  RealEstateAssociationOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  SettingsRealEstateAgencyComponent,
  SettingsRealEstateAgencyComponentProps,
} from "@haywork/modules/settings/modules/real-estate-agency";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgency: RealEstateAgency;
  realEstateAssociations: RealEstateAssociationOption[];
}
interface DispatchProps {
  navigate: (url: string) => void;
}

const mapStateToProps = <StateProps, SettingsRealEstateAgencyComponentProps>(
  state: AppState
) => ({
  realEstateAgency: state.realEstateAgency.realEstateAgency,
  realEstateAssociations: state.main.mastertable.kolibri.realEstateAssociations,
});

const mapDispatchToProps = <
  DispatchProps,
  SettingsRealEstateAgencyComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
});

export type SettingsRealEstateAgencyContainerProps = StateProps & DispatchProps;
export const SettingsRealEstateAgencyContainer = connect<
  StateProps,
  DispatchProps,
  SettingsRealEstateAgencyComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsRealEstateAgencyComponent);
