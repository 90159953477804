import {
  AgriculturalSubtypeOtherOption,
  AlvBuildingPlotDestinationOption,
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  BeverageHospitalityTypeOption,
  CattleFarmingSubtypeOption,
  FastfoodHospitalitySectorTypeOption,
  HorecaSectorTypeOption,
  HorseCompanySubtypeOption,
  HorticulturalCompanyTypeOption,
  HotelHospitalitySectorTypeOption,
  HouseCharacteristic,
  HouseCharacteristicOption,
  HouseSort,
  HouseSortOption,
  HouseTypeOption,
  InvestmentTypeOption,
  LeisureTypeOption,
  ListingType,
  ListingTypeOption,
  NatureRegistrationOption,
  ObjectAssignment,
  ParkingTypeOption,
  PigCompanyTypeOption,
  RealEstateGroup,
  RealEstateGroupOption,
  RegisterReasonOption,
  RelationSnapShot,
  ResidentialSubtypeOtherOption,
  RestaurantHospitalitySectorTypeOption,
  SocialPropertyTypeOption,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditClientComponent,
  AssignmentEditClientComponentProps,
} from "@haywork/modules/assignment";
import { AppState, SingleAssignmentState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import sortBy from "lodash-es/sortBy";
import { connect, MapStateToProps } from "react-redux";
import { realEstateGroups } from "./selectors";

interface StateProps {
  employees: RelationSnapShot[];
  offices: RelationSnapShot[];
  realEstateGroups: RealEstateGroupOption[];
  listingTypes: ListingTypeOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  parkingTypeOptions: ParkingTypeOption[];
  residentialSubtypeOthers: ResidentialSubtypeOtherOption[];
  realEstateAgencyId: string;
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  host: string;
  path: string;
  investmentTypeOptions: InvestmentTypeOption[];
  horecaSectorTypeOptions: HorecaSectorTypeOption[];
  leisureTypeOptions: LeisureTypeOption[];
  socialPropertyTypeOptions: SocialPropertyTypeOption[];
  beverageHospitalitySectorTypes: BeverageHospitalityTypeOption[];
  restaurantHospitalitySectorTypes: RestaurantHospitalitySectorTypeOption[];
  fastfoodHospitalitySectorTypes: FastfoodHospitalitySectorTypeOption[];
  hotelHospitalitySectorTypes: HotelHospitalitySectorTypeOption[];
  horseCompanySubtypes: HorseCompanySubtypeOption[];
  cattleFarmingSubtypes: CattleFarmingSubtypeOption[];
  horticulturalCompanyTypes: HorticulturalCompanyTypeOption[];
  pigCompanyTypes: PigCompanyTypeOption[];
  agriculturalSubtypeOthers: AgriculturalSubtypeOtherOption[];
  natureRegistrationOptions: NatureRegistrationOption[];
  registerReasonOptions: RegisterReasonOption[];
  alvBuildingPlotDestinationOptions: AlvBuildingPlotDestinationOption[];
}
interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  navigate: (url: string) => void;
}

const mapListingTypes = (
  listingTypes: ListingTypeOption[],
  realEstateGroup: RealEstateGroup
): ListingTypeOption[] => {
  let filteredListingTypes = listingTypes.filter(
    (type) => type.realEstateGroups.indexOf(realEstateGroup) !== -1
  );

  if (realEstateGroup === RealEstateGroup.Agricultural) {
    filteredListingTypes = sortBy(filteredListingTypes, "displayName");
  }
  filteredListingTypes.filter(
    (listingType) => listingType.value !== ListingType.ProductionRights
  );
  return filteredListingTypes;
};

const mapHouseCharacteristics = (
  houseCharacteristics: HouseCharacteristicOption[],
  houseSort: HouseSort
): HouseCharacteristicOption[] => {
  return houseCharacteristics.filter((characteristic) => {
    return (
      characteristic.value !== HouseCharacteristic.SemiBungalow ||
      houseSort === HouseSort.Bungalow
    );
  });
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditClientComponentProps,
  AppState
> = (state) => {
  const {
    listingTypes,
    investmentTypeOptions,
    horecaSectorTypeOptions,
    leisureTypeOptions,
    socialPropertyTypeOptions,
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
    beverageHospitalitySectorTypes,
    restaurantHospitalitySectorTypes,
    fastfoodHospitalitySectorTypes,
    hotelHospitalitySectorTypes,
    horseCompanySubtypes,
    cattleFarmingSubtypes,
    horticulturalCompanyTypes,
    pigCompanyTypes,
    agriculturalSubtypeOthers,
    natureRegistrationOptions,
    registerReasonOptions,
    alvBuildingPlotDestinationOptions,
  } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { currentComponentState } = state.editable;
  const objectAssignment: ObjectAssignment =
    currentComponentState.objectAssignment;

  return {
    employees: state.account.employees,
    offices: state.account.offices,
    realEstateGroups: realEstateGroups(state),
    listingTypes: mapListingTypes(
      listingTypes,
      objectAssignment.realEstateGroup
    ),
    houseSorts,
    houseTypes,
    houseCharacteristics: mapHouseCharacteristics(
      houseCharacteristics,
      objectAssignment.houseSort
    ),
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
    realEstateAgencyId,
    objectAssignment,
    currentComponentState,
    host: state.appSettings.host,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    }),
    investmentTypeOptions,
    horecaSectorTypeOptions,
    leisureTypeOptions,
    socialPropertyTypeOptions,
    beverageHospitalitySectorTypes,
    restaurantHospitalitySectorTypes,
    fastfoodHospitalitySectorTypes,
    hotelHospitalitySectorTypes,
    horseCompanySubtypes,
    cattleFarmingSubtypes,
    horticulturalCompanyTypes,
    pigCompanyTypes,
    agriculturalSubtypeOthers,
    natureRegistrationOptions,
    registerReasonOptions,
    alvBuildingPlotDestinationOptions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditClientComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
  navigate: (url: string) => dispatch(push(url)),
});

export type AssignmentEditClientContainerProps = StateProps & DispatchProps;
export const AssignmentEditClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditClientComponent);
