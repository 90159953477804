import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { LinkedRelationWithRole } from "@haywork/util/assignment";
import * as React from "react";
import { FC, memo, useCallback, useRef, useState } from "react";

export enum NetworkBulkAction {
  CreateEmail = "CreateEmail",
  Deselect = "Deselect",
}

type Props = {
  visible: boolean;
  canEmail: boolean;
  selectedContactLinks: LinkedRelationWithRole[];
  onClick: (action: NetworkBulkAction) => void;
};

export const SearchAssignmentActionsComponent: FC<Props> = memo(
  ({ canEmail, onClick, selectedContactLinks, visible }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const emails = selectedContactLinks.filter(
      (contactLink) => !!contactLink.email
    );

    const onClickHandler = useCallback(
      (action: NetworkBulkAction) => {
        onClick(action);
        setActionsVisible(false);
      },
      [setActionsVisible]
    );

    return !visible ? null : (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="bulkActions.label"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          <Action
            label="network.bulkAction.deselect"
            icon={
              <Icon
                light
                name="arrow-from-right"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(NetworkBulkAction.Deselect)}
          />
          {!!canEmail && !!emails.length && (
            <Action
              label="network.bulkAction.createEmail"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(NetworkBulkAction.CreateEmail)}
            />
          )}
        </ActionList>
      </>
    );
  }
);

export default SearchAssignmentActionsComponent;
