import * as React from "react";
import * as CSSModules from "react-css-modules";

import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";

import { EditorSignaturesContainerProps } from "./signatures.container";
import { ModalPortal } from "@haywork/portals";
import { ResourceText } from "../../..";
import { SETTINGSROUTES } from "@haywork/constants";

const styles = require("./signatures.component.scss");

export interface EditorSignaturesComponentProps {
  parent: HTMLElement;
  visible: boolean;
  onSelectTemplate: (template: TemplateDefinitionSnapShot) => void;
  onHide: () => void;
}
interface State {
  signaturesStyle: React.CSSProperties;
  signaturesPointerStyle: React.CSSProperties;
}
type Props = EditorSignaturesComponentProps & EditorSignaturesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EditorSignaturesComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      signaturesStyle: null,
      signaturesPointerStyle: null
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onSettingsClick = this.onSettingsClick.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    if (!this.props.visible || !this.props.parent) return null;

    return (
      <ModalPortal>
        <div
          styleName="signatures"
          style={this.state.signaturesStyle}
          ref={(ref) => (this.ref = ref)}
        >
          <div styleName="signatures__wrapper">
            {this.props.signatures.map((signature, idx) => (
              <div
                key={idx}
                data-cy="CY-addSignatureItem"
                styleName="signature"
                onClick={() => this.props.onSelectTemplate(signature)}
              >
                {signature.name}
              </div>
            ))}

            <div styleName="config" onClick={this.onSettingsClick}>
              <i className="fal fa-cog" />
              <ResourceText resourceKey="emailSignature.ToSettings" />
            </div>

            {!this.props.signatures.length && (
              <div styleName="no-results">
                <ResourceText resourceKey="noSignatures" />
              </div>
            )}
          </div>
          <div
            styleName="signatures__pointer"
            style={this.state.signaturesPointerStyle}
          />
        </div>
      </ModalPortal>
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!!this.props.visible && !prevProps.visible) {
      this.setState({ ...this.calculateSignaturesStyles() });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    if (!this.props.visible || !this.ref || !this.props.parent) return;

    const clickedInside =
      this.ref.contains(event.target) ||
      this.props.parent.contains(event.target);

    if (!clickedInside) {
      this.props.onHide();
    }
  }

  private onSettingsClick() {
    this.props.navigate(SETTINGSROUTES.EMAIL_SIGNATURES.URI);
  }

  private calculateSignaturesStyles(): {
    signaturesStyle: React.CSSProperties;
    signaturesPointerStyle: React.CSSProperties;
  } {
    const windowWidth = window.innerWidth;
    const { width: refWidth } = this.ref.getBoundingClientRect();
    const {
      top: parentTop,
      left: parentLeft,
      width: parentWidth,
      height: parentHeight
    } = this.props.parent.getBoundingClientRect();
    const offsetWidth = 4;

    let signaturesStyle: React.CSSProperties = {
      left: parentLeft + parentWidth + offsetWidth,
      top: parentTop
    };
    let signaturesPointerStyle: React.CSSProperties = {
      left: -5,
      top: parentHeight / 2 - 5
    };

    if (parentLeft + parentWidth + offsetWidth + refWidth > windowWidth - 16) {
      signaturesStyle = {
        ...signaturesStyle,
        left: parentLeft - refWidth - offsetWidth
      };
      signaturesPointerStyle = {
        ...signaturesPointerStyle,
        left: refWidth - 5
      };
    }

    return {
      signaturesStyle,
      signaturesPointerStyle
    };
  }
}
