import {
  AssignmentPhase,
  AvailabilityStatus,
  LinkedRelation,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES, REQUEST } from "@haywork/constants";
import {
  AssignmentDetailContainerProps,
  AssignmentDetailRouting,
} from "@haywork/modules/assignment";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { NotesList } from "@haywork/modules/notes-v3";
import {
  AuthorizationWidgets,
  ObjectTimestamps,
  ResourceText,
  SlideShow,
} from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { AssignmentSaveModalComponent } from "../save-modal";
import {
  AssignmentStatusModalRentedComponent,
  AssignmentStatusModalSoldComponent,
} from "../status-modal";
import { TransactionModal } from "../transaction-modal";
import { AssignmentWithdrawModalComponent } from "../withdraw-modal";
import { intlContext } from "@haywork/app";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentDetailComponentProps {}
interface State {
  linkedApplicants: LinkedRelation[];
}
type Props = AssignmentDetailComponentProps & AssignmentDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      linkedApplicants: get(this.props, "assignment.linkedApplicants", []),
    };

    this.onSlideClickHandler = this.onSlideClickHandler.bind(this);
    this.onCloseAvailabilityModal = this.onCloseAvailabilityModal.bind(this);
    this.onCloseWithdrawModal = this.onCloseWithdrawModal.bind(this);
  }

  public render() {
    if (!this.props.assignment) return null;

    const {
      assignment,
      statusState,
      showStatusModal,
      withdrawReasonOptions,
      withdrawState,
      showWithdrawModal,
      realEstateAgencyId,
      host,
      mixedForms,
      statusError,
    } = this.props;
    const {
      id,
      linkedCreatedBy,
      linkedModifiedBy,
      dateTimeCreated,
      dateTimeModified,
      displayName,
      isNew,
      linkedObjectAssignments,
      realEstateGroup,
      forSale,
      availabilityStatus,
      assignmentPhase,
      saleOffer,
      linkedApplicants,
      rentOffer,
    } = assignment;
    const photos = assignment.photos
      ? assignment.photos.filter((p) => !!p)
      : [];
    const showSidebar = !this.props.location.pathname.match(/edit/);
    const statusModalVisible =
      showStatusModal || statusState === REQUEST.PENDING;

    const modalTitle = !forSale
      ? "rented"
      : assignmentPhase === AssignmentPhase.Concept
      ? "soldDirectly"
      : availabilityStatus === AvailabilityStatus.Available
      ? "sold"
      : "changeSaleInfo";

    return (
      <div styleName="detail" data-cy="CY-assignmentParentDiv">
        {!isNew && <NotesList parentId={id} />}
        {showSidebar && (
          <div styleName="sideBar">
            <div styleName="preview-slider" className="hidden-xs hidden-sm">
              {photos.length > 0 && (
                <SlideShow
                  slides={photos}
                  onSlideClick={this.onSlideClickHandler}
                />
              )}
              {photos.length === 0 && (
                <i className="fal fa-fw fa-camera-retro" styleName="icon" />
              )}
            </div>
            <div styleName="menu">
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(ASSIGNMENTROUTES.GENERAL.URI, { id })}
              >
                <i className="fal fa-fw fa-folder" />
                <span styleName="text">
                  <ResourceText resourceKey="tabGeneral" />
                </span>
              </NavLink>
              <FeatureSwitch feature="FULL_OBJECT_SPECIFICATION">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={route(ASSIGNMENTROUTES.COMPLETE.URI, { id })}
                >
                  <i className="fal fa-fw fa-list-alt" />
                  <span styleName="text">
                    <ResourceText resourceKey="tabComplete" />
                  </span>
                </NavLink>
              </FeatureSwitch>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(ASSIGNMENTROUTES.TIMELINE.URI, { id })}
              >
                <i className="fal fa-fw fa-align-left" />
                <span styleName="text">
                  <ResourceText resourceKey="tabTimeline" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(ASSIGNMENTROUTES.NETWORK.URI, { id })}
              >
                <i className="fal fa-fw fa-users" />
                <span styleName="text">
                  <ResourceText resourceKey="tabNetwork" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(ASSIGNMENTROUTES.SEARCH_ASSIGNMENTS.URI, { id })}
              >
                <i className="fal fa-fw fa-search" />
                <span styleName="text">
                  <ResourceText resourceKey="searchers" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(ASSIGNMENTROUTES.DOSSIER.URI, { id })}
              >
                <i className="fal fa-fw fa-archive" />
                <span styleName="text">
                  <ResourceText resourceKey="tabDossier" />
                </span>
              </NavLink>
              {!!linkedObjectAssignments && !!linkedObjectAssignments.length && (
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={route(ASSIGNMENTROUTES.VERSIONS.URI, { id })}
                >
                  <i className="fal fa-fw fa-code-branch" />
                  <span styleName="text">
                    <ResourceText resourceKey="tabVersions" />
                  </span>
                </NavLink>
              )}
              <AuthorizationWidgets
                location={WidgetEntityLocation.Menu}
                type={WidgetEntityType.ObjectAssignment}
                menuType="menu-button"
                id={id}
              />
            </div>
            <ObjectTimestamps
              linkedCreatedBy={linkedCreatedBy}
              linkedModifiedBy={linkedModifiedBy}
              dateTimeCreated={dateTimeCreated}
              dateTimeModified={dateTimeModified}
            />
          </div>
        )}

        <div styleName="body">
          <AssignmentDetailRouting id={id} />
        </div>

        {!isNew &&
          !!this.props.assignment &&
          realEstateGroup !== RealEstateGroup.Commercial &&
          !this.props.isInitial && (
            <TransactionModal
              visible={statusModalVisible}
              assignment={this.props.assignment}
              transactionMetaData={this.props.transactionMetaData}
              onClose={this.onCloseAvailabilityModal}
            />
          )}

        {!isNew &&
          !!this.props.assignment &&
          realEstateGroup === RealEstateGroup.Commercial &&
          !this.props.isInitial && (
            <Modal
              visible={statusModalVisible}
              onClose={this.onCloseAvailabilityModal}
            >
              <ModalHeader title={modalTitle} close />
              <ModalBody noPadding>
                {forSale ? (
                  <AssignmentStatusModalSoldComponent
                    availabilityStatus={availabilityStatus}
                    assignmentPhase={assignmentPhase}
                    realEstateAgencyId={realEstateAgencyId}
                    assignmentId={id}
                    host={host}
                    displayName={displayName}
                    mixedForms={mixedForms}
                    saleOffer={saleOffer}
                    onCloseAvailabilityModal={this.onCloseAvailabilityModal}
                    linkedApplicants={linkedApplicants}
                    sellAssignment={this.props.sellAssignment}
                    changeAvailabilityStatus={
                      this.props.changeAvailabilityStatus
                    }
                    realEstateGroup={this.props.assignment.realEstateGroup}
                    navigate={this.props.navigate}
                    isTransactionInformationConfidential={
                      this.props.assignment.isTransactionInformationConfidential
                    }
                  />
                ) : (
                  <AssignmentStatusModalRentedComponent
                    realEstateAgencyId={realEstateAgencyId}
                    host={host}
                    assignmentId={id}
                    displayName={displayName}
                    rentOffer={rentOffer}
                    statusState={statusState}
                    statusError={statusError}
                    onCancel={this.onCloseAvailabilityModal}
                    onCloseAvailabilityModal={this.onCloseAvailabilityModal}
                    linkedApplicants={linkedApplicants}
                    rentAssignment={this.props.rentAssignment}
                    changeAvailabilityStatus={
                      this.props.changeAvailabilityStatus
                    }
                    realEstateGroup={this.props.assignment.realEstateGroup}
                    navigate={this.props.navigate}
                    isTransactionInformationConfidential={
                      this.props.assignment.isTransactionInformationConfidential
                    }
                  />
                )}
              </ModalBody>
            </Modal>
          )}

        <Modal visible={showWithdrawModal} onClose={this.onCloseWithdrawModal}>
          <ModalHeader title="withDrawAssignment" close />
          <ModalBody noPadding>
            <AssignmentWithdrawModalComponent
              displayName={displayName}
              assignmentId={id}
              withdrawReasonOptions={withdrawReasonOptions}
              withdrawState={withdrawState}
              onCancel={this.onCloseWithdrawModal}
              withdrawAssignment={this.props.withdrawAssignment}
              changeAvailabilityStatus={this.props.changeAvailabilityStatus}
              onCloseAvailabilityModal={this.onCloseWithdrawModal}
              mandateDateTime={get(
                this.props,
                "objectAssignment.mandateDateTime"
              )}
            />
          </ModalBody>
        </Modal>

        <AssignmentSaveModalComponent
          visible={this.props.showSaveModal}
          objectAssignment={this.props.objectAssignment}
          onClose={() => this.props.toggleSaveModal(false)}
        />
      </div>
    );
  }

  public componentDidMount() {
    const title =
      get(this.props.assignment, "displayName") ||
      intlContext.formatMessage({
        id: "newAssignment",
        defaultMessage: "",
      });
    this.props.setTabTitle(title);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveAndCloseAssignment();
    }

    if (!!nextProps.showStatusModal && !this.props.showStatusModal) {
      this.setState({
        linkedApplicants: get(
          nextProps,
          "objectAssignment.linkedApplicants",
          []
        ),
      });
    }

    if (
      get(nextProps, "objectAssignment.displayName") !==
      get(this.props, "objectAssignment.displayName")
    ) {
      const title =
        get(nextProps.assignment, "displayName") ||
        intlContext.formatMessage({
          id: "newAssignment",
          defaultMessage: "",
        });
      this.props.setTabTitle(title);
    }
  }

  private onSlideClickHandler(idx: number) {
    this.props.openLightbox(this.props.assignment.photos, idx);
  }

  private onCloseAvailabilityModal() {
    this.props.toggleStatusModal(false);
    this.props.clearAvailabilityStatus();
  }

  private onCloseWithdrawModal() {
    this.props.toggleWithdrawModal(false);
    this.props.clearAvailabilityStatus();
  }
}
