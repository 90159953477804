import {
  MediaContractSnapShot,
  MediaContractStatus,
} from "@haywork/api/kolibri";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import { PublishType } from "@haywork/modules/settings";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import { Fragment } from "react";
import * as CSSModules from "react-css-modules";
import DeactivateModal from "../confirm-deactivate-modal";

const styles = require("./overview.component.scss");

type Props = {
  mediaContract: MediaContractSnapShot;
  zebra: boolean;
  publishValues: PublishType[];
  resetPublicationStateToManual: boolean;
  onShowInfo: (mediaContract: MediaContractSnapShot) => void;
  onActivateMediaContract: (mediaContractId: string) => void;
  onDeActivateMediaContract: (mediaContract: MediaContractSnapShot) => void;
  onToggleFavorite: (mediaContractId: string, isFavorite: boolean) => void;
  onPublicationTypeChange: (
    mediaContract: MediaContractSnapShot,
    isAutoPublish: boolean
  ) => void;
};
type State = {
  publishType: string;
  requestDeactivation: boolean;
};

@CSSModules(styles, { allowMultiple: true })
export class MediaContractComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.deactivateMediaPartner = this.deactivateMediaPartner.bind(this);
    this.cancelDeactivateMediaPartner =
      this.cancelDeactivateMediaPartner.bind(this);
    this.onPublishTypeChangeHandler =
      this.onPublishTypeChangeHandler.bind(this);

    this.state = {
      publishType: !!this.props.mediaContract.isAutoPublish
        ? PublishType.Automatic
        : PublishType.Manual,
      requestDeactivation: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !!nextProps.resetPublicationStateToManual &&
      !this.props.resetPublicationStateToManual
    ) {
      this.setState({
        publishType: PublishType.Manual,
      });
    }
  }

  public render() {
    const {
      status,
      isFavorite,
      mediaPartnerLogoUrl,
      id,
      category,
      mediaPartnerName,
      publicationCount,
      autoPublishAvailable,
      manualPublishAvailable,
    } = this.props.mediaContract;

    const showPublishType =
      status === MediaContractStatus.Active &&
      !!autoPublishAvailable &&
      !!manualPublishAvailable;
    const mediaContractStyle = classNames("media-contract", {
      zebra: this.props.zebra,
    });
    const triggerStyle = classNames("trigger", { favorite: isFavorite });
    const statusStyle = classNames("media-contract__status", {
      "has-publish-type": showPublishType,
    });
    const triggerIconStyle = isFavorite ? "fa fa-star" : "fal fa-star";
    const imageStyles: React.CSSProperties = !!mediaPartnerLogoUrl
      ? { backgroundImage: `url(${JSON.stringify(mediaPartnerLogoUrl)})` }
      : null;

    return (
      <>
        <div styleName={mediaContractStyle}>
          <div styleName="column">
            <div
              styleName={triggerStyle}
              onClick={() => this.props.onToggleFavorite(id, !isFavorite)}
            >
              <i className={triggerIconStyle} />
            </div>
          </div>
          <div styleName="column image">
            <div styleName="media-contract__image" style={imageStyles}>
              {!mediaPartnerLogoUrl && <i className="fal fa-cloud" />}
            </div>
          </div>
          <div styleName="column meta">
            <div styleName="media-contract__meta">
              <h2>
                <ResourceText
                  masterKey="mediaPartnerCategories"
                  resourceKey={category.toString()}
                />
              </h2>
              <h1>{mediaPartnerName}</h1>
              <span
                className="as-link"
                onClick={() => this.props.onShowInfo(this.props.mediaContract)}
              >
                <ResourceText resourceKey="moreInfo" />
              </span>
            </div>
          </div>
          <div styleName="column">
            {!!publicationCount && (
              <ResourceText
                resourceKey="publicationCount"
                values={{ count: publicationCount }}
              />
            )}
          </div>
          <div styleName="column status">
            <div styleName={statusStyle}>
              {showPublishType && (
                <Fragment>
                  <label>
                    <ResourceText resourceKey="publishWhen" />
                  </label>
                  <Input.NewSelect
                    name="publishType"
                    values={this.props.publishValues}
                    value={this.state.publishType}
                    onChange={this.onPublishTypeChangeHandler}
                    translate
                  />
                </Fragment>
              )}
              {this.renderStatusTag()}
            </div>
          </div>
          <div styleName="column">{this.renderActivationButton()}</div>
        </div>
        <DeactivateModal
          visible={this.state.requestDeactivation}
          mediaPartnerName={mediaPartnerName}
          onClose={this.cancelDeactivateMediaPartner}
          onDeactivate={this.deactivateMediaPartner}
        />
      </>
    );
  }

  private renderStatusTag(): React.ReactElement<HTMLDivElement> {
    const {
      status,
      autoPublishAvailable,
      manualPublishAvailable,
      isAutoPublish,
    } = this.props.mediaContract;

    switch (status) {
      case MediaContractStatus.Active: {
        if (!autoPublishAvailable || !manualPublishAvailable) {
          return (
            <Pill
              label={
                !!isAutoPublish ? "automaticPublication" : "manualPublication"
              }
              color={Colors.Success}
            />
          );
        }
        return;
      }
      case MediaContractStatus.PendingActivation:
      case MediaContractStatus.PendingDeactivation: {
        return (
          <Pill
            labelPrefix="mediaContractStatusOptions"
            label={status.toString()}
            color={Colors.Warning}
          />
        );
      }
      case MediaContractStatus.Denied:
      case MediaContractStatus.Suspended: {
        return (
          <Pill
            labelPrefix="mediaContractStatusOptions"
            label={status.toString()}
            color={Colors.Danger}
          />
        );
      }
      case MediaContractStatus.Inactive: {
        switch (true) {
          case autoPublishAvailable && manualPublishAvailable: {
            return <Pill label="mediaContract.publish.manualOrAuto" />;
          }
          case autoPublishAvailable: {
            return <Pill label="mediaContract.publish.auto" />;
          }
          case manualPublishAvailable: {
            return <Pill label="mediaContract.publish.manual" />;
          }
          default: {
            return null;
          }
        }
      }
      default: {
        return null;
      }
    }
  }

  private renderActivationButton(): React.ReactElement<HTMLDivElement> {
    const { status } = this.props.mediaContract;

    switch (status) {
      case MediaContractStatus.Inactive:
        return (
          <div
            className="btn btn-success text-center"
            onClick={() =>
              this.props.onActivateMediaContract(this.props.mediaContract.id)
            }
          >
            <ResourceText resourceKey="activate" />
          </div>
        );
      case MediaContractStatus.Active:
      case MediaContractStatus.PendingActivation:
        return (
          <div
            className="btn btn-danger inverse text-center"
            onClick={() => this.deactivateMediaPartner()}
          >
            <ResourceText resourceKey="deActivate" />
          </div>
        );
      default:
        return null;
    }
  }

  private onPublishTypeChangeHandler(publishType: PublishType) {
    if (publishType === this.state.publishType) return;

    switch (publishType) {
      case PublishType.Automatic: {
        this.props.onPublicationTypeChange(this.props.mediaContract, true);
        break;
      }
      case PublishType.Manual: {
        this.props.onPublicationTypeChange(this.props.mediaContract, false);
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      publishType,
    });
  }

  private deactivateMediaPartner(force = false) {
    if (force) {
      this.setState({ requestDeactivation: false });
      this.props.onDeActivateMediaContract(this.props.mediaContract);
    } else {
      this.setState({ requestDeactivation: true });
    }
  }

  private cancelDeactivateMediaPartner() {
    this.setState({ requestDeactivation: false });
  }
}
