import { MapDispatchToProps, RelationThunks } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { RealtorInfoComponent, RealtorInfoProps } from "./realtor-info";
import { RelationType, RelationSnapShot } from "@haywork/api/kolibri";

type StateProps = {};
type DispatchProps = {
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    emailAddresses?: string[],
    phoneNumber?: string
  ) => Promise<RelationSnapShot[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  RealtorInfoProps,
  AppState
> = (state) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  RealtorInfoProps
> = (dispatch) => ({
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    emailAddresses?: string[],
    phoneNumber?: string
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddressOrPhoneNumber(
        emailAddresses,
        [RelationType.ContactCompany],
        phoneNumber
      )
    ),
});

export type RealtorInfoContainerProps = StateProps & DispatchProps;
export const RealtorInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RealtorInfoComponent);
