import { intlContext } from "@haywork/app";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";

interface TextareaInputComponentProps {
  placeholder?: string;
  placeholderValues?: any;
  minHeight?: number;
  maxHeight?: number;
  overflow?: "visible" | "hidden" | "auto";
  autoSize?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  reference?: (ref: HTMLTextAreaElement) => void;
  onSelectionChange?: (start: number, end: number) => void;
  large?: boolean;
  maxLength?: number;
  hasPhrases?: boolean;
  maxCount?: number;
}
interface TextareaInputComponentState {
  value: string;
  fauxValue: string;
}

export class TextareaComponent extends React.Component<
  TextareaInputComponentProps & InputComponentProps,
  TextareaInputComponentState
> {
  private ref: HTMLTextAreaElement;

  constructor(props) {
    super(props);

    const value =
      [null, undefined].indexOf(this.props.value) === -1
        ? this.props.value
        : "";
    this.state = {
      value,
      fauxValue: value,
    };

    this.onSelectCaptureChange = this.onSelectCaptureChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.fireOnChange = this.fireOnChange.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage(
          {
            id: this.props.placeholder,
            defaultMessage: this.props.placeholder,
          },
          this.props.placeholderValues
        )
      : null;
    const textareaStyles = { minHeight: this.props.minHeight || 0 };
    const textareaFauxStyles: React.CSSProperties = {
      maxHeight: this.props.maxHeight || "none",
      minHeight: this.props.minHeight || 0,
      overflow: this.props.overflow || "visible",
    };
    const textareaStyle = classNames("textarea__input", {
      "auto-size": this.props.autoSize,
      large: this.props.large,
      "has-phrases": this.props.hasPhrases,
      "has-maxcount": this.props.maxCount,
    });
    const maxCountStyle = classNames("textarea__max-count", {
      "auto-size": this.props.autoSize,
    });

    return (
      <div className={textareaStyle}>
        <textarea
          name={this.props.name}
          id={this.props.name}
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          onFocus={this.props.onFocus}
          style={textareaStyles}
          onKeyDown={this.props.onKeyDown}
          ref={this.bindRef}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          maxLength={this.props.maxLength}
          data-cy={this.props["data-cy"]}
          onSelectCapture={this.onSelectCaptureChange}
          data-lpignore="true"
        />
        {this.props.autoSize && (
          <div className="textarea__faux" style={textareaFauxStyles}>
            {this.state.fauxValue}
          </div>
        )}
        {this.props.maxCount && (
          <div className={maxCountStyle}>
            <span
              className={classNames("current-value", {
                error: this.state.value.length > this.props.maxCount,
                warning: this.state.value.length / this.props.maxCount > 0.75,
              })}
            >
              {this.state.value.length}
            </span>
            /{this.props.maxCount}
          </div>
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TextareaInputComponentProps & InputComponentProps
  ) {
    const value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    if (this.state.value !== value) {
      this.setState({ value }, () => {
        if (!!this.ref) {
          const { value: fauxValue } = this.ref;
          this.setState({ fauxValue });
        }
      });
    }
    if (!!nextProps.focus && !!this.ref) {
      this.ref.focus();
    }
  }

  private bindRef(ref: HTMLTextAreaElement) {
    this.ref = ref;
    if (this.props.reference) this.props.reference(ref);
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = event.target;

    this.setState({ value, fauxValue: value });

    if (!!this.props.fireAllChanges) {
      this.fireOnChange(value);
    }
  }

  private onSelectCaptureChange() {
    if (!!this.props.onSelectionChange) {
      this.props.onSelectionChange(
        this.ref.selectionStart,
        this.ref.selectionEnd
      );
    }
  }

  private onBlurHandler(event: React.FocusEvent<HTMLTextAreaElement>) {
    const { value } = this.state;
    this.fireOnChange(value);
    this.props.onBlur(event);
  }

  private fireOnChange(value: string) {
    if (!!value && !this.props.disableTrim) value = value.trim();
    value = value.replace(/\\u2028|↵|&#8232;/g, "\n");
    this.props.onChange(value);
  }
}
