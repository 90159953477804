import { intlContext } from "@haywork/app";
import * as moment from "moment";
import isString from "lodash-es/isString";

type DateValue = string | Date;

export enum DiffStatus {
  Positive = "Positive",
  Today = "Today",
  Negative = "Negative",
  Unknown = "Unknown",
}

export enum DateTimeFrame {
  Just = "Just",
  LastHour = "LastHour",
  LastDay = "LastDay",
  LastYear = "LastYear",
  Other = "Other",
}

interface DiffInDaysValue {
  value: number;
  status: DiffStatus;
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export class Dates {
  public static sameSecond(
    date1?: Date | string | null,
    date2?: Date | string | null
  ) {
    if (!date1 || !date2) return true;

    const dt1 = isString(date1) ? moment(date1).toDate() : date1;
    const dt2 = isString(date2) ? moment(date2).toDate() : date2;

    return moment(dt1).diff(dt2, "second", true) <= 1;
  }

  public static diffInDays(
    date1: DateValue,
    date2: DateValue
  ): DiffInDaysValue {
    if (!date1 || !date2) return { value: null, status: DiffStatus.Unknown };
    const dt1 = typeof date1 === "string" ? new Date(date1) : date1;
    const dt2 = typeof date2 === "string" ? new Date(date2) : date2;
    const fix1 = new Date(
      dt1.getFullYear(),
      dt1.getMonth(),
      dt1.getDate(),
      12,
      0,
      0,
      0
    );
    const fix2 = new Date(
      dt2.getFullYear(),
      dt2.getMonth(),
      dt2.getDate(),
      12,
      0,
      0,
      0
    );

    const timeDiff = fix2.getTime() - fix1.getTime();
    const diff = Math.floor(timeDiff / DAY);

    const status =
      diff < 0
        ? DiffStatus.Negative
        : diff === 0
        ? DiffStatus.Today
        : DiffStatus.Positive;
    const value = Math.abs(diff);

    return { value, status };
  }

  public static getNormalizedMonth(date: Date): string {
    return `0${date.getMonth() + 1}`.slice(-2);
  }

  public static getUTCNow(): Date {
    const now = new Date();
    return new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
  }

  public static convertToUTC(date: Date): Date {
    const utcString = date.toUTCString();
    return new Date(date);
  }

  public static getTimeAgoText(date: Date, culture: string): string {
    const todaysDate = new Date();
    const difference = Dates.diffInDays(date, todaysDate);

    if (difference.status === DiffStatus.Today) {
      return intlContext.formatMessage({ id: "today" });
    } else if (
      difference.status === DiffStatus.Positive &&
      difference.value === 1
    ) {
      return intlContext.formatMessage({ id: "yesterday" });
    } else if (
      difference.status === DiffStatus.Positive &&
      difference.value > 1 &&
      difference.value < 7
    ) {
      return moment(date).locale(culture).fromNow();
    } else {
      return intlContext.formatDate(date, {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    }
  }

  public static getTimeAgoSubTitle(date: Date, culture: string): string {
    const now = new Date().getTime();
    const ref = new Date(date.toString()).getTime();

    const timeframe = Dates.getTimeFrame(date.toString());

    switch (timeframe) {
      case DateTimeFrame.Just: {
        return intlContext.formatMessage({
          id: "just",
          defaultMessage: "just",
        });
      }
      case DateTimeFrame.LastHour: {
        const minutes = Math.ceil((now - ref) / 60000);
        return intlContext.formatMessage(
          { id: "minutesAgo", defaultMessage: "minutesAgo" },
          { minutes }
        );
      }
      case DateTimeFrame.LastDay: {
        const hours = Math.ceil((now - ref) / 3600000);
        return intlContext.formatMessage(
          { id: "hoursAgo", defaultMessage: "hoursAgo" },
          { hours }
        );
      }
      case DateTimeFrame.LastYear: {
        const daysAgo = moment(date).locale(culture).fromNow();
        return daysAgo;
      }
      default: {
        return intlContext.formatDate(date, {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
      }
    }
  }

  public static dateAndTimeToDate(date: string, time: string): Date {
    try {
      if (!date || !time) {
        return null;
      }

      const rawDate = new Date(date);
      const timeSections = time.split(":");
      const hours = parseInt(timeSections[0]);
      const minutes = parseInt(timeSections[1]);

      return new Date(
        rawDate.getFullYear(),
        rawDate.getMonth(),
        rawDate.getDate(),
        hours,
        minutes
      );
    } catch (error) {
      return null;
    }
  }

  public static getTimeFrame(date: string): DateTimeFrame {
    const now = new Date().getTime();
    const ref = new Date(date).getTime();
    const diff = ref - now;

    if (diff <= 300000) {
      return DateTimeFrame.Just;
    } else if (diff < 3600000) {
      return DateTimeFrame.LastHour;
    } else if (diff < 86400000) {
      return DateTimeFrame.LastDay;
    } else if (diff < 31536000000) {
      return DateTimeFrame.LastYear;
    } else {
      return DateTimeFrame.Other;
    }
  }
}
