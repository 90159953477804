import { PhotoBlob, UploadResponse } from "@haywork/api/kolibri";
import isString from "lodash-es/isString";

export enum FileTypeCategory {
  Archive = "Archive",
  Excel = "Excel",
  Image = "Image",
  Movie = "Movie",
  Other = "Other",
  Pdf = "Pdf",
  PowerPoint = "PowerPoint",
  Sound = "Sound",
  Text = "Text",
  Word = "Word"
}

export enum MimeCategory {
  Image = "Image",
  Audio = "Audio",
  Video = "Video",
  Excel = "Excel",
  PowerPoint = "Powerpoint",
  Word = "Word",
  PDF = "PDF",
  Document = "Document",
  Archive = "Archive",
  Executable = "Executable",
  Code = "Code",
  Other = "Other"
}

export interface DetailedMime {
  category: MimeCategory;
}

export class File {
  public static getDetailedMime(contentType: string): DetailedMime {
    let mappedType = MimeCategory.Other;

    if (contentType && contentType.includes("audio")) {
      mappedType = MimeCategory.Audio;
    } else if (contentType && contentType.includes("image")) {
      mappedType = MimeCategory.Image;
    } else if (contentType && contentType.includes("video")) {
      mappedType = MimeCategory.Video;
    } else {
      switch (contentType) {
        case "application/msword":
        case "application/x-abiword":
        case "application/rtf":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          mappedType = MimeCategory.Word;
          break;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          mappedType = MimeCategory.Excel;
          break;
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          mappedType = MimeCategory.PowerPoint;
          break;
        case "application/xml":
        case "application/xhtml+xml":
        case "application/typescript":
        case "font/ttf":
        case "text/html":
        case "text/css":
        case "text/csv":
          mappedType = MimeCategory.Code;
          break;
        case "application/x-rar-compressed":
        case "application/x-tar":
        case "application/zip":
        case "application/x-7z-compressed":
        case "application/octet-stream":
        case "application/x-bzip":
        case "application/x-bzip2":
        case "application/java-archive":
          mappedType = MimeCategory.Archive;
          break;
        case "application/pdf":
          mappedType = MimeCategory.PDF;
          break;
        default:
          mappedType = MimeCategory.Other;
          break;
      }
    }
    const category = mappedType ? mappedType : MimeCategory.Other;

    return {
      category
    };
  }

  public static convertUploadResponseToPhotoBlob(
    uploadResponse: UploadResponse
  ): PhotoBlob {
    const photoBlob: PhotoBlob = {
      dateTimeCreated: uploadResponse.dateTimeCreated,
      dateTimeModified: uploadResponse.dateTimeModified,
      fileDataId: uploadResponse.fileDataId,
      fileExtension: uploadResponse.fileExtension,
      fileName: uploadResponse.fileName,
      fileSize: uploadResponse.fileSize,
      height: uploadResponse.height,
      md5Hash: uploadResponse.md5Hash,
      urlPreview: uploadResponse.thumbUrl,
      urlOriginal: uploadResponse.url,
      urlBig: uploadResponse.url,
      urlMedium: uploadResponse.url,
      name: uploadResponse.fileName,
      width: uploadResponse.width
    };

    return photoBlob;
  }

  public static getFileSize(bytes: number, decimals: number = 2): string {
    if (!bytes || bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
      sizes[i]
    }`;
  }

  public static getFileType(extension: string): FileTypeCategory {
    switch (extension.toLowerCase()) {
      case ".zip":
      case ".tar":
      case ".rar":
        return FileTypeCategory.Archive;
      case ".xls":
      case ".xlsx":
        return FileTypeCategory.Excel;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
      case ".bmp":
        return FileTypeCategory.Image;
      case ".mov":
      case ".mp4":
        return FileTypeCategory.Movie;
      case ".pdf":
        return FileTypeCategory.Pdf;
      case ".ppt":
      case ".pptx":
        return FileTypeCategory.PowerPoint;
      case ".mp3":
      case ".wav":
        return FileTypeCategory.Sound;
      case ".txt":
        return FileTypeCategory.Text;
      case ".doc":
      case ".docx":
        return FileTypeCategory.Word;
      default:
        return FileTypeCategory.Other;
    }
  }

  public static sanatizeFilename(filename: string): string {
    if (!filename || !isString(filename)) return filename;
    return filename.replace(/[^a-zA-Z0-9\. _-]/gi, "");
  }
}
