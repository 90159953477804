import { PublicationsClient } from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();

const getPublications = (assignmentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Publications = new PublicationsClient(host);

    try {
      const publications = await parseRequest.response(
        Publications.search(
          {
            assignmentId
          },
          realEstateAgencyId
        ).then((response) => response.results || [])
      );

      return publications;
    } catch (error) {
      throw error;
    }
  };
};

const activate = (assignmentId: string, mediaPartnerId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Publications = new PublicationsClient(host);

    try {
      await parseRequest.response(
        Publications.activate(
          { assignmentId, mediaPartnerId },
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const deactivate = (assignmentId: string, mediaPartnerId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Publications = new PublicationsClient(host);

    try {
      await parseRequest.response(
        Publications.deactivate(
          { assignmentId, mediaPartnerId },
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

export const PublicationsThunk = {
  getPublications,
  activate,
  deactivate
};
