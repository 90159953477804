import { MatchedPropertySnapShot } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { MultiListRowProps } from "@haywork/components/ui/multi-list";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil, SearchAssignmentUtil } from "@haywork/util";
import classNames from "classnames";
import head from "lodash-es/head";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber, useIntl } from "react-intl";
import { EmailAssignment } from "@haywork/util/email-v2";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = MultiListRowProps<MatchedPropertySnapShot> & {
  selectedAssignmentsIds: string[];
  inEditMode: boolean;
  onNavigate: (path: string) => void;
  onToggleSelected: (assignment: EmailAssignment) => void;
};

export const MatchedPropertyRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      style,
      zebra,
      data,
      selectedAssignmentsIds,
      inEditMode,
      onNavigate,
      onToggleSelected,
    }) => {
      const {
        photo1Url,
        photo2Url,
        photo3Url,
        displayName,
        forSale,
        salePrice,
        rentPrice,
        id,
        saleCondition,
        listingType,
      } = data as MatchedPropertySnapShot;
      const intl = useIntl();

      const photo = useMemo(
        () => head([photo1Url, photo2Url, photo3Url].filter((d) => !!d)),
        [photo1Url, photo2Url, photo3Url]
      );

      const emailAssignment = useMemo(
        () =>
          SearchAssignmentUtil.mapMatchedPropertySnapShotToEmailAssignment(
            data as MatchedPropertySnapShot,
            intl
          ),
        [data, intl]
      );

      const isSelected = useMemo(() => {
        return selectedAssignmentsIds.includes(id);
      }, [id, selectedAssignmentsIds]);

      const onClickHandler = useCallback(() => {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [id, onNavigate]);

      const toggleSelectedCallback = useCallback(
        (event: React.MouseEvent) => {
          event.stopPropagation();
          onToggleSelected(emailAssignment);
        },
        [emailAssignment, onToggleSelected]
      );

      return (
        <div
          style={style}
          styleName={classNames("row", { zebra })}
          onClick={onClickHandler}
        >
          {!inEditMode && (
            <div styleName="selected">
              <div
                styleName="selected__trigger"
                onClick={toggleSelectedCallback}
              >
                <Icon
                  name={isSelected ? "check-square" : "square"}
                  size={16}
                  light={!isSelected}
                  solid={isSelected}
                  color={isSelected ? Colors.Primary : Colors.MediumGray}
                />
              </div>
            </div>
          )}
          <div styleName={classNames("column", "thumb", { push: inEditMode })}>
            <div
              styleName="preview"
              style={{
                backgroundImage: !photo
                  ? null
                  : `url(${JSON.stringify(photo)})`,
              }}
            >
              {!photo && (
                <div styleName="preview__empty-state">
                  <Icon
                    name="camera-alt"
                    size={20}
                    color={Colors.MediumGray}
                    light
                  />
                </div>
              )}
            </div>
          </div>
          <div styleName="column meta">
            {!!displayName && <div styleName="title">{displayName}</div>}

            {!!listingType && (
              <div styleName="column-row">
                <div styleName="column-row__label">
                  <I18n value="searchAssignment.label.listingType" />
                </div>
                <div styleName="column-row__value">
                  <I18n prefix="listingTypes" value={listingType.toString()} />
                </div>
              </div>
            )}

            <div styleName="column-row">
              <div styleName="column-row__label">
                <I18n
                  value={
                    !!forSale
                      ? "searchAssignment.label.salePrice"
                      : "searchAssignment.label.rentPrice"
                  }
                />
              </div>
              <div styleName="column-row__value">
                <FormattedNumber
                  value={(!!forSale ? salePrice : rentPrice) || 0}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
                {!!saleCondition && (
                  <>
                    {" "}
                    <I18n
                      prefix="saleConditions"
                      value={saleCondition.toString()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);
