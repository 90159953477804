import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import findIndex from "lodash-es/findIndex";

const styles = require("./stepper.component.scss");

export enum StepperType {
  Numbered = "Numbered",
  Accordion = "Accordion"
}

interface StepperComponentProps {
  initial?: number;
  step?: number;
  type?: StepperType;
  scrollToElementId?: string;
  openAll?: boolean;
}

interface StepperComponentState {}

interface Step {
  id: string;
  onToggle: (active: boolean) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class StepperComponent extends React.Component<
  StepperComponentProps,
  StepperComponentState
> {
  public static childContextTypes = {
    registerStep: PropTypes.func.isRequired,
    focusStep: PropTypes.func.isRequired,
    unRegisterStep: PropTypes.func.isRequired
  };

  private ref: HTMLDivElement;
  private steps: Step[] = [];
  private current: string = null;

  public getChildContext() {
    return {
      registerStep: this.registerStep.bind(this),
      focusStep: this.focusStep.bind(this),
      unRegisterStep: this.unRegisterStep.bind(this)
    };
  }

  public render() {
    return (
      <div
        styleName="stepper"
        ref={(ref) => (this.ref = ref)}
        data-cy={this.props["data-cy"]}
      >
        {this.props.children}
      </div>
    );
  }

  private registerStep(
    id: string,
    onToggle: (active: boolean) => void
  ): { idx: number; type: StepperType; active: boolean } {
    let idx = findIndex(this.steps, (s) => s.id === id);
    if (idx === -1) {
      this.steps.push({
        id,
        onToggle
      });
      idx = this.steps.length - 1;
    }

    if (this.props.initial !== undefined && this.props.initial === idx) {
      this.current = id;
    }
    return {
      idx,
      type: this.props.type || StepperType.Numbered,
      active: this.props.openAll ? this.props.openAll : this.current === id
    };
  }

  private unRegisterStep(id: string) {
    this.steps = this.steps.filter((s) => {
      return s.id !== id;
    });
  }

  private focusStep(id: string) {
    if (this.props.openAll) return;
    this.current = id === this.current ? null : id;
    this.steps.map((step) => {
      step.onToggle(step.id === this.current);
    });
    this.scrollToTop();
  }

  private scrollToTop() {
    if (
      !!this.props.scrollToElementId &&
      !!document.querySelector(`#${this.props.scrollToElementId}`)
    ) {
      $(`#${this.props.scrollToElementId}`).animate({ scrollTop: 0 });
    }
  }
}
