import { ProviderType } from "@haywork/api/mail";
import { COUNTS, REQUEST } from "@haywork/constants";
import { EmailBucket } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { InfiniteScroll, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { MessageItem } from "./message.component";
import { EmailMessage } from "@haywork/stores/email-v2";

const styles = require("./list.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  currentMessage: EmailMessage;
  messages: EmailMessage[];
  currentFolder: ExtendedEmailFolder;
  currentFolderIsTrash: boolean;
  providerType: ProviderType;
  onScrollEnd: () => void;
  onMessageClick: (message: EmailMessage, unread: boolean) => void;
  onMessageToggleUnread: (message: EmailMessage) => void;
  onMessageToggleBookmarked: (id: string, bookmarked: boolean) => void;
  onMessageDelete: (message: EmailMessage) => void;
}
interface State {
  groups: SortedEmailMessages[];
}
interface SortedEmailMessages {
  bucket: EmailBucket;
  messages: EmailMessage[];
}

@CSSModules(styles, { allowMultiple: true })
export class MessagesComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      groups: []
    };

    this.mapMessagesToEmailBucket = this.mapMessagesToEmailBucket.bind(this);
  }

  public componentDidMount() {
    this.mapMessagesToEmailBucket(this.props.messages);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (
      !!nextProps.currentFolder &&
      !!nextProps.currentFolder.canLoadMoreMessages &&
      !!this.props.messages &&
      !!nextProps.messages &&
      nextProps.messages.length < this.props.messages.length &&
      nextProps.messages.length < COUNTS.EMAIL_MESSAGES / 2
    ) {
      this.props.onScrollEnd();
    }

    this.mapMessagesToEmailBucket(nextProps.messages);
  }

  public render() {
    const messagesStatus = value(
      this.props.currentFolder,
      "canLoadMoreMessagesStatus"
    );
    const currentMessageId = !!this.props.currentMessage
      ? this.props.currentMessage.id || null
      : null;

    return (
      <InfiniteScroll scrollEnd={this.props.onScrollEnd}>
        {this.state.groups.map((group, idx) => (
          <div styleName="list__group" key={idx}>
            <div styleName="group-title">
              <ResourceText
                resourceKey={`emailBucket.${group.bucket.toString()}`}
              />
            </div>
            {group.messages.map((message) => (
              <ErrorBoundary key={message.id}>
                <MessageItem
                  message={message}
                  currentMessageId={currentMessageId}
                  bucket={group.bucket}
                  currentFolderIsTrash={this.props.currentFolderIsTrash}
                  onClick={this.props.onMessageClick}
                  onToggleUnread={this.props.onMessageToggleUnread}
                  onToggleBookmarked={this.props.onMessageToggleBookmarked}
                  folder={this.props.currentFolder}
                  onDeleteMessage={this.props.onMessageDelete}
                  providerType={this.props.providerType}
                  folderCategory={get(this.props.currentFolder, "category")}
                />
              </ErrorBoundary>
            ))}
          </div>
        ))}

        {this.state.groups.length === 0 && messagesStatus === REQUEST.SUCCESS && (
          <div styleName="empty-state">
            <ResourceText resourceKey="emptyStateEmailMessageList" />
          </div>
        )}

        {messagesStatus === REQUEST.PENDING && <Ui.Loaders.List />}
      </InfiniteScroll>
    );
  }

  private mapMessagesToEmailBucket(messages: EmailMessage[]) {
    if (!messages) {
      return;
    }

    const now = moment().startOf("day");
    const weekNumber = now.week();
    const groups: SortedEmailMessages[] = messages.reduce<
      SortedEmailMessages[]
    >((state, message) => {
      const messageDate = moment(message.date).startOf("day");
      const isSameDay = now.isSame(messageDate, "day");
      const diffInDays = Math.ceil(now.diff(messageDate, "days"));
      const messageWeekNumber = moment(messageDate).week();
      let groupBucket;

      switch (true) {
        case isSameDay:
          groupBucket = EmailBucket.Today;
          break;
        case !isSameDay && diffInDays === 1:
          groupBucket = EmailBucket.Yesterday;
          break;
        case messageWeekNumber === weekNumber && diffInDays > 1:
          groupBucket = EmailBucket.ThisWeek;
          break;
        case weekNumber - messageWeekNumber === 1:
          groupBucket = EmailBucket.LastWeek;
          break;
        default:
          groupBucket = EmailBucket.Older;
          break;
      }

      const bucket = state.find((group) => group.bucket === groupBucket);
      if (!bucket) {
        state.push({
          bucket: groupBucket,
          messages: [message]
        });
      } else {
        bucket.messages.push(message);
      }

      return state;
    }, []);

    this.setState({ groups });
  }
}
