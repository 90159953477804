import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquistionAssignmentEditMediaScreen,
  AcquistionAssignmentEditMediaScreenProps,
} from "./assignments-edit-media";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  acquisitionStatusOptions: any;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditMediaScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable.currentComponentState;
  const { acquisitionStatusOptions } = state.main.mastertable.kolibri;
  return {
    acquisitionObjectAssignment,
    acquisitionStatusOptions,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditMediaScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
});

export type AcquistionAssignmentEditMediaScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditMediaScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditMediaScreen);
