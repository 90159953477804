import { LinkedRelation } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";

interface PartnerComponentProps {
  relation: LinkedRelation;
  onClick: (relation: LinkedRelation) => void;
}
interface PartnerComponentState {}

export class PartnerComponent extends React.Component<
  PartnerComponentProps,
  PartnerComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const { displayName, linkedPartner } = this.props.relation;

    return (
      <div
        className="partner"
        onClick={this.onClickHandler}
        data-cy={this.props["data-cy"]}
      >
        <ResourceText
          resourceKey="linkedPartner"
          values={{ name: displayName, partner: linkedPartner.displayName }}
          asHtml
        />
      </div>
    );
  }

  private onClickHandler() {
    const relation: LinkedRelation = {
      ...this.props.relation,
      togetherWithPartner: true,
    };

    this.props.onClick(relation);
  }
}
