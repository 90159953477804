import Icon from "@haywork/components/ui/icon";
import { KEYCODE } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { SelectPortal } from "@haywork/portals";
import classNames from "classnames";
import findIndex from "lodash-es/findIndex";
import get from "lodash-es/get";
import * as React from "react";
import {
  ChangeEvent,
  CSSProperties,
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import { InputComponentProps } from "../input.component";
import Option from "./option";
import escapeRegExp from "lodash-es/escapeRegExp";
import I18n from "@haywork/components/i18n";
import debounce from "lodash-es/debounce";
import isObject from "lodash-es/isObject";

const styles = require("./style.scss");
const equals = require("react-fast-compare");
const MIN_HEIGHT_TURNPOINT = 500;
const VERTICAL_SPACE = 16;

type SelectComponentProps = {
  values: any[];
  valueProp?: string;
  valuesProp?: string;
  valueOutMapper?: (value: any) => any;
  valueInMapper?: (value: any) => any;
  displayProp?: string;
  displayFn?: (
    value: any,
    query: string,
    active: boolean,
    selected: boolean
  ) => ReactNode;
  displayValueFn?: (value: any) => ReactNode;
  addEmptyOption?: boolean;
  emptyOption?: any;
  emptyOptionLabel?: string;
  filterProp?: string;
  translate?: boolean;
  translatePrefix?: string;
  translateValue?: (value: any) => string | string;
  translateValues?: (value: any) => Record<string, any> | Record<string, any>;
  placeholder?: string;
  addActionOption?: boolean;
  actionOptionClick?: () => void;
  actionOptionLabel?: string | { value: string; values: Record<string, any> };
  outerRef?: (ref: MutableRefObject<HTMLDivElement>) => void;
};
type Props = SelectComponentProps & InputComponentProps;
export type ValueProp = {
  value: any;
  filter: string;
};

export const SelectComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      values: outerValues,
      value,
      valueProp,
      valuesProp,
      onChange,
      valueInMapper,
      valueOutMapper,
      displayProp,
      displayFn,
      displayValueFn,
      addEmptyOption,
      emptyOption,
      emptyOptionLabel,
      placeholder: outerPlaceholder,
      filterProp,
      translate,
      translatePrefix,
      translateValues,
      disabled,
      addActionOption,
      actionOptionClick,
      actionOptionLabel,
      translateValue,
      outerRef,
    }) => {
      const intl = useIntl();
      const inputRef = useRef<HTMLInputElement | null>(null);
      const triggerRef = useRef<HTMLButtonElement | null>(null);
      const inputWrapperRef = useRef<HTMLDivElement | null>(null);
      const prevFocusElement = useRef<HTMLElement | null>(null);
      const nextFocusElement = useRef<HTMLElement | null>(null);
      const [query, setQuery] = useState("");
      const [focussed, setFocussed] = useState(false);
      const [expanded, setExpanded] = useState(false);
      const [inputStyle, setInputStyle] = useState<CSSProperties | null>(null);
      const [activeIndex, setActiveIndex] = useState(0);
      const settingListPosition = useRef(false);
      const settingListPositionTimer = useRef(null);
      const [alignOptionsTop, setAlignOptionsTop] = useState(false);

      if (!!outerRef) {
        outerRef(inputWrapperRef);
      }

      const queryPlaceholder = useMemo(() => {
        return intl.formatMessage({
          id: "select.filter.placeholder",
          defaultMessage: "Type to filter",
        });
      }, [intl]);

      const triggerIcon = useMemo(() => {
        return (
          <Icon
            name={expanded ? "chevron-up" : "chevron-down"}
            size={18}
            light
            color={focussed ? Colors.Primary : Colors.Gray}
          />
        );
      }, [expanded, focussed]);

      const values = useMemo(() => {
        const escapedQuery = escapeRegExp((query || "").trim());
        let values = outerValues;

        if (addEmptyOption) {
          const label = intl.formatMessage({
            id: emptyOptionLabel || "noChoice",
          });
          const option =
            (!!valueProp || !!valuesProp) && !!displayProp
              ? { [valuesProp || valueProp]: "", [displayProp]: label }
              : "";

          values = [option, ...outerValues];
        }

        const returnValues = values
          .map((value) => {
            let filter = !!filterProp
              ? get(value, filterProp)
              : !!displayProp
              ? get(value, displayProp)
              : value;

            if (translate) {
              if (!!translateValue) {
                filter =
                  typeof translateValue === "function"
                    ? translateValue(value)
                    : translateValue;
              }
              const id = !!translatePrefix
                ? `${translatePrefix}.${filter}`
                : filter;
              const mappedValues =
                typeof translateValues === "function"
                  ? translateValues(value)
                  : translateValues;

              filter = intl.formatMessage(
                { id, defaultMessage: filter },
                mappedValues
              );
            }

            return {
              value,
              filter,
            } as ValueProp;
          })
          .filter(
            (value) =>
              !query || new RegExp(escapedQuery, "gi").test(value.filter)
          );

        return returnValues;
      }, [
        outerValues,
        addEmptyOption,
        emptyOption,
        emptyOptionLabel,
        valueProp,
        valuesProp,
        displayProp,
        intl,
        filterProp,
        translate,
        translatePrefix,
        translateValues,
        translateValue,
        query,
      ]);

      const totalCount = useMemo(() => values.length, [values.length]);

      const selectedIndex = useMemo(() => {
        let index = findIndex(values, (v) => {
          const m1 = !!valuesProp
            ? get(v.value, valuesProp)
            : !!valueProp
            ? get(v.value, valueProp)
            : v.value;
          let m2 = !!valueProp ? get(value, valueProp) : value;
          if (!!valueInMapper) {
            m2 = valueInMapper(m2);
          }

          if (
            isObject(m1) &&
            !!(m1 as any).id &&
            isObject(m2) &&
            !!(m2 as any).id
          ) {
            return (m1 as any).id === (m2 as any).id;
          }

          return equals(m1, m2);
        });

        if (index === -1 && addEmptyOption) {
          index = 0;
        }

        return index;
      }, [value, values, valueProp, valuesProp, addEmptyOption, valueInMapper]);

      const valueDisplay = useMemo(() => {
        const value = values[selectedIndex];
        if (!value) return null;

        switch (true) {
          case !!displayValueFn: {
            return displayValueFn(value.value);
          }
          case !!displayFn: {
            return displayFn(value.value, "", false, false);
          }
          default: {
            return <div styleName="value">{value.filter}</div>;
          }
        }
      }, [displayFn, displayValueFn, values, selectedIndex]);

      const placeholder = useMemo(() => {
        return intl.formatMessage({
          id: outerPlaceholder || "select.default.placeholder",
        });
      }, [outerPlaceholder, intl]);

      const toggleExpanded = useCallback(
        (toExpanded: boolean) => {
          setQuery("");
          if (!triggerRef.current) return;
          if (toExpanded) {
            setActiveIndex(selectedIndex);

            const {
              top: refTop,
              left,
              height,
              width,
            } = triggerRef.current.getBoundingClientRect();
            const { innerHeight } = window;
            const spaceAtTop = refTop + height;
            const spaceAtBottom = innerHeight - refTop;

            let top: string | number = refTop;
            let bottom: string | number = "auto";
            let maxHeight = spaceAtBottom - VERTICAL_SPACE;

            if (spaceAtBottom < MIN_HEIGHT_TURNPOINT) {
              top = "auto";
              bottom = innerHeight - spaceAtTop;
              maxHeight = spaceAtTop - VERTICAL_SPACE;
            }

            setAlignOptionsTop(top === "auto");

            setInputStyle({
              top,
              bottom,
              left,
              width,
              maxHeight,
            });
          }

          if (!toExpanded) {
            triggerRef.current.focus();
          }

          setExpanded(toExpanded);
        },
        [
          setExpanded,
          setInputStyle,
          setActiveIndex,
          selectedIndex,
          setQuery,
          setAlignOptionsTop,
        ]
      );

      const emptyValue = useMemo(() => {
        if (!!values.length) return null;
        return (
          <div styleName={classNames("option__value", "empty-value")}>
            <I18n value="select.empty.placeholder" />
          </div>
        );
      }, [values]);

      const actionOption = useMemo(() => {
        if (!addActionOption || !actionOptionClick || !actionOptionLabel)
          return null;
        const { value, values } =
          typeof actionOptionLabel === "string"
            ? { value: actionOptionLabel, values: undefined }
            : actionOptionLabel;
        const clickCallback = () => {
          actionOptionClick();
          toggleExpanded(false);
        };

        return (
          <div styleName="option" onClick={clickCallback}>
            <div styleName={classNames("option__value", "as-action")}>
              <I18n value={value} values={values} />
            </div>
          </div>
        );
      }, [
        addActionOption,
        actionOptionClick,
        actionOptionLabel,
        toggleExpanded,
      ]);

      const focusOnInput = useCallback(() => {
        if (!inputRef.current) return;
        setFocussed(true);
        toggleExpanded(true);
        inputRef.current.focus();
      }, [setFocussed, toggleExpanded]);

      const inputChangeCallback = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          setQuery(event.target.value);
        },
        [setQuery]
      );

      const buttonKeyDownCallback = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>) => {
          switch (event.keyCode) {
            case KEYCODE.TAB: {
              setFocussed(false);
              break;
            }
            case KEYCODE.ESCAPE:
            case KEYCODE.ALT:
            case KEYCODE.CTRL:
            case KEYCODE.CMD:
            case KEYCODE.DELETE:
            case KEYCODE.BACKSPACE:
            case KEYCODE.SHIFT: {
              break;
            }
            default: {
              focusOnInput();
              break;
            }
          }
        },
        [setFocussed, focusOnInput]
      );

      const optionSelectCallback = useCallback(
        (value: any) => {
          toggleExpanded(false);
          onChange(
            valueOutMapper
              ? valueOutMapper(value)
              : valuesProp
              ? get(value, valuesProp)
              : value
          );
        },
        [onChange, valuesProp, valueOutMapper, toggleExpanded]
      );

      const optionEnterCallback = useCallback(() => {
        const option = values[activeIndex];
        if (!option) {
          toggleExpanded(false);
        } else {
          optionSelectCallback(option.value);
        }
      }, [values, activeIndex, optionSelectCallback, toggleExpanded]);

      const settingListPositionCallback = useCallback(() => {
        settingListPosition.current = true;
        if (!!settingListPositionTimer.current) {
          clearTimeout(settingListPositionTimer.current);
        }
        settingListPositionTimer.current = setTimeout(() => {
          settingListPosition.current = false;
        }, 1000);
      }, []);

      const inputKeyDownCallback = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
          switch (event.keyCode) {
            case KEYCODE.ESCAPE: {
              event.preventDefault();
              toggleExpanded(false);
              break;
            }
            case KEYCODE.TAB: {
              event.preventDefault();
              setQuery("");
              if (event.shiftKey && prevFocusElement.current) {
                setExpanded(false);
                setFocussed(false);
                prevFocusElement.current.focus();
              }
              if (!event.shiftKey && nextFocusElement.current) {
                setExpanded(false);
                setFocussed(false);
                nextFocusElement.current.focus();
              }
              break;
            }
            case KEYCODE.ENTER: {
              event.preventDefault();
              optionEnterCallback();
              break;
            }
            case KEYCODE.DOWN_ARROW: {
              event.preventDefault();
              settingListPositionCallback();
              setActiveIndex((activeIndex) =>
                activeIndex === totalCount - 1 ? 0 : activeIndex + 1
              );
              break;
            }
            case KEYCODE.UP_ARROW: {
              event.preventDefault();
              settingListPositionCallback();
              setActiveIndex((activeIndex) =>
                activeIndex === 0 ? totalCount - 1 : activeIndex - 1
              );
              break;
            }
            default: {
              break;
            }
          }
        },
        [
          setExpanded,
          setFocussed,
          setActiveIndex,
          totalCount,
          optionEnterCallback,
          settingListPositionCallback,
        ]
      );

      const setActiveIndexCallback = useCallback(
        (activeIndex: number) => {
          if (!!settingListPosition.current) return;
          setActiveIndex(activeIndex);
        },
        [setActiveIndex]
      );

      useEffect(() => {
        setActiveIndex(selectedIndex === -1 ? 0 : selectedIndex);
      }, [selectedIndex, setActiveIndex, values.length]);

      useEffect(() => {
        if (triggerRef.current) {
          let i = 0;
          const getParent = (el: HTMLElement) => {
            const matches = el.querySelectorAll<HTMLElement>(
              "input, a, button, textarea"
            );
            if (matches.length > 1) {
              let triggerIdx: number;
              matches.forEach((item, idx) => {
                if (item === triggerRef.current) triggerIdx = idx;
              });

              if (triggerIdx !== undefined) {
                if (triggerIdx > 0) {
                  prevFocusElement.current = matches.item(triggerIdx - 1);
                  i = 5;
                }
                if (triggerIdx < matches.length - 1) {
                  nextFocusElement.current = matches.item(triggerIdx + 1);
                  i = 5;
                }
              }
            }
            i++;
            if (i <= 5) {
              getParent(el.parentElement);
            }
          };

          getParent(triggerRef.current);
        }
      }, []);

      useEffect(() => {
        const mouseOutsideClick = (event: MouseEvent) => {
          const clickedInside =
            (!!triggerRef.current &&
              triggerRef.current.contains(event.target as Node)) ||
            (!!inputWrapperRef.current &&
              inputWrapperRef.current.contains(event.target as Node));
          if (!clickedInside) {
            setQuery("");
            setExpanded(false);
            setFocussed(false);
          }
        };

        document.addEventListener("click", mouseOutsideClick, true);

        return () => {
          document.removeEventListener("click", mouseOutsideClick, true);
        };
      }, [setFocussed, setExpanded, setQuery]);

      useEffect(() => {
        const onScroll = debounce(
          (event: MouseEvent) => {
            if (
              expanded &&
              (!inputWrapperRef.current ||
                !inputWrapperRef.current.contains(event.target as Node))
            ) {
              setQuery("");
              setExpanded(false);
            }
          },
          500,
          { leading: true }
        );

        document.addEventListener("scroll", onScroll, true);

        return () => {
          document.addEventListener("scroll", onScroll, true);
        };
      }, [expanded, setExpanded, setQuery]);

      useEffect(() => {
        const onResize = debounce(
          () => {
            if (expanded) {
              setQuery("");
              setExpanded(false);
            }
          },
          500,
          { leading: true }
        );

        window.addEventListener("resize", onResize);

        return () => {
          window.addEventListener("resize", onResize);
        };
      }, [expanded, setExpanded, setQuery]);

      return (
        <>
          <button
            type="button"
            onClick={focusOnInput}
            ref={triggerRef}
            styleName={classNames("trigger", { focussed })}
            onKeyDown={buttonKeyDownCallback}
            onFocus={() => setFocussed(true)}
            disabled={disabled}
          >
            <div styleName="trigger__label">
              {valueDisplay || <div styleName="value">{placeholder}</div>}
            </div>
            <div styleName="trigger__icon">{triggerIcon}</div>
          </button>
          <SelectPortal>
            <div
              styleName={classNames("input", {
                expanded,
                reversed: alignOptionsTop,
              })}
              style={inputStyle}
              ref={inputWrapperRef}
            >
              <div styleName="input__wrapper">
                <input
                  type="text"
                  placeholder={queryPlaceholder}
                  ref={inputRef}
                  value={query}
                  onChange={inputChangeCallback}
                  onKeyDown={inputKeyDownCallback}
                />
                <div
                  styleName="trigger__icon"
                  onClick={() => toggleExpanded(false)}
                >
                  {triggerIcon}
                </div>
              </div>
              <div styleName="options">
                {values.map((value, idx) => (
                  <Option
                    value={value}
                    onSelect={optionSelectCallback}
                    key={idx}
                    index={idx}
                    displayFn={displayFn}
                    query={query}
                    activeIndex={activeIndex}
                    expanded={expanded}
                    selectedIndex={selectedIndex}
                    onActiveIndexChange={setActiveIndexCallback}
                  />
                ))}
                {emptyValue}
                {actionOption}
              </div>
            </div>
          </SelectPortal>
        </>
      );
    }
  )
);
