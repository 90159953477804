import { listActions, ListActions } from "./list.actions";
import {
  AssignmentSnapShot,
  RealEstateGroup,
  AssignmentPhase
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";

export type AcquisitionFilters = {
  filterByRealEstateGroups: RealEstateGroup[];
  kindOfAssignment: string[];
  assignmentPhases: AssignmentPhase[];
  employeeIds: string[];
  officeIds: string[];
};

export type ListState = {
  assignments: AssignmentSnapShot[];
  total: number;
  assignmentsStatus: string;
  filters: AcquisitionFilters;
};

const INITIAL: ListState = {
  assignments: [],
  total: 0,
  assignmentsStatus: REQUEST.IDLE,
  filters: {
    filterByRealEstateGroups: [],
    kindOfAssignment: [],
    assignmentPhases: [AssignmentPhase.Concept, AssignmentPhase.Initiated],
    employeeIds: [],
    officeIds: []
  }
};

export const listReducer = (state = INITIAL, action: ListActions) => {
  switch (action.type) {
    case listActions.SetAssignments: {
      const { assignments, total, reset } = action;

      return {
        ...state,
        assignments: reset
          ? assignments
          : [...state.assignments, ...assignments],
        total,
        assignmentsStatus: REQUEST.SUCCESS
      };
    }
    case listActions.SetAssignmentsStatus: {
      const { assignmentsStatus } = action;

      return {
        ...state,
        assignmentsStatus
      };
    }
    case listActions.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        filters,
        assignments: [],
        assignmentsStatus: REQUEST.PENDING,
        total: 0
      };
    }
    case listActions.ClearFilter: {
      const { filter } = action;

      const reference = state.filters[filter.filter];
      const values = reference.filter((item) => item !== filter.value);

      return {
        ...state,
        filters: {
          ...state.filters,
          [filter.filter]: values
        },
        assignments: [],
        assignmentsStatus: REQUEST.PENDING,
        total: 0
      };
    }
    case listActions.ClearAllFilters: {
      return {
        ...state,
        filters: {
          filterByRealEstateGroups: [],
          kindOfAssignment: [],
          assignmentPhases: [],
          employeeIds: [],
          officeIds: []
        },
        assignments: [],
        assignmentsStatus: REQUEST.PENDING,
        total: 0
      };
    }
    case listActions.UpdateListItem: {
      const { snapshot } = action;
      const assignments = state.assignments.map((assignment) => {
        if (assignment.id === snapshot.id) {
          return snapshot;
        }
        return assignment;
      });

      return {
        ...state,
        assignments
      };
    }
    case listActions.DeleteListItem: {
      const { id } = action;
      const assignments = state.assignments.filter(
        (assignment) => assignment.id !== id
      );

      return {
        ...state,
        assignments
      };
    }
    default: {
      return state;
    }
  }
};
