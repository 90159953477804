import { Dispatch } from "@haywork/middleware";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect } from "react-redux";
import {
  UiWindowFrameComponent,
  UiWindowFrameComponentProps
} from "./window-frame.component";

interface StateProps {
  windowFramePath: string;
  windowFrameVisible: boolean;
  windowFrameTitle: string | { key: string; values: object };
}
interface DispatchProps {
  close: () => void;
}

const mapStateToProps = <StateProps, UiWindowFrameComponentProps>(
  state: AppState
) => {
  const {
    windowFramePath,
    windowFrameVisible,
    windowFrameTitle
  } = state.layout;

  return {
    windowFramePath,
    windowFrameVisible,
    windowFrameTitle
  };
};

const mapDispatchToProps = <DispatchProps, UiWindowFrameComponentProps>(
  dispatch: Dispatch<any>
) => ({
  close: () => dispatch(LayoutActions.closeWindowFrame())
});

export type UiWindowFrameContainerProps = StateProps & DispatchProps;
export const UiWindowFrameContainer = connect<
  StateProps,
  DispatchProps,
  UiWindowFrameComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UiWindowFrameComponent);
