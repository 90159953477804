import * as React from "react";
import { FC, memo, useMemo, useRef, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { Attachment, FileType } from "@haywork/api/mls";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import head from "lodash-es/head";
import Hint from "@haywork/components/ui/hint";
import { useIntl } from "react-intl";
import { saveAs } from "file-saver";

const styles = require("./style.scss");

type Props = {
  attachment: Attachment;
  onClick: (attachment: Attachment) => void;
};

export const AttachmentComponent: FC<Props> = memo(
  CSSModules(styles)(({ attachment, onClick }) => {
    const ref = useRef<HTMLDivElement>();
    const intl = useIntl();

    const {
      fileType,
      fileTypeNormalizedFile,
      urlThumbFile,
      urlNormalizedFile,
      urlMediumFile,
      urlOriginalFile,
      title,
      description,
      tags,
      subType,
      type,
    } = attachment;

    const previewImage = useMemo(() => {
      const filteredFiles = [
        urlThumbFile,
        urlMediumFile,
        urlNormalizedFile,
        urlOriginalFile,
      ].filter((d) => !!d);
      const first = head(filteredFiles);

      return first || null;
    }, [urlThumbFile, urlNormalizedFile, urlMediumFile, urlOriginalFile]);

    const downloadUrl = useMemo(() => {
      const filteredFiles = [
        urlOriginalFile,
        urlNormalizedFile,
        urlMediumFile,
        urlThumbFile,
      ].filter((d) => !!d);
      const first = head(filteredFiles);

      return first || null;
    }, [urlThumbFile, urlNormalizedFile, urlMediumFile, urlOriginalFile]);

    const name = useMemo(() => {
      if (!!subType) {
        const id = `attachmentSubTypeOptions.${subType.toString()}`;
        return intl.formatMessage({ id, defaultMessage: subType.toString() });
      }

      if (!!title) {
        const str = title.find((t) => t.language === "nl-NL");
        if (!!str?.value) {
          return str.value;
        }
      }

      if (!!description) {
        const desc = description.find((t) => t.language === "nl-NL");
        if (!!desc?.value) {
          return desc.value;
        }
      }

      if (!!tags) {
        return tags;
      }

      if (!!type) {
        const id = `attachmentTypeOptions.${type.toString()}`;
        return intl.formatMessage({ id, defaultMessage: type.toString() });
      }

      return null;
    }, [title, description, tags, subType, type, intl]);

    const downloadCallback = useCallback(
      (event: React.MouseEvent, url: string) => {
        if (!url) return;
        event.preventDefault();
        event.stopPropagation();
        saveAs(url);
      },
      []
    );

    const preview = useMemo(() => {
      const type = fileTypeNormalizedFile || fileType;

      switch (type) {
        case FileType.BMP:
        case FileType.GIF:
        case FileType.JPG:
        case FileType.PNG: {
          return !previewImage ? (
            <>
              <Icon name="camera-alt" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          ) : (
            <div
              styleName="preview"
              style={{
                backgroundImage: `url(${JSON.stringify(previewImage)})`,
              }}
            >
              <div
                styleName="download"
                onClick={(e) => downloadCallback(e, downloadUrl)}
              >
                <Icon
                  name="arrow-down"
                  size={12}
                  color={Colors.White}
                  regular
                />
              </div>
            </div>
          );
        }
        case FileType.AVI:
        case FileType.FLV:
        case FileType.MOV:
        case FileType.MPG:
        case FileType.WMV: {
          return (
            <>
              <Icon name="file-video" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.MP3: {
          return (
            <>
              <Icon name="file-audio" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.YOUTUBE: {
          return (
            <>
              <Icon name="video" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.MSG: {
          return (
            <>
              <Icon name="envelope" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.PDF: {
          return (
            <>
              <Icon name="file-pdf" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.CSV: {
          return (
            <>
              <Icon name="file-csv" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.DOC:
        case FileType.DOCX: {
          return (
            <>
              <Icon name="file-word" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.DOC:
        case FileType.DOCX: {
          return (
            <>
              <Icon name="file-word" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.PPS:
        case FileType.PPSX:
        case FileType.PPT:
        case FileType.PPTX: {
          return (
            <>
              <Icon
                name="file-powerpoint"
                size={24}
                color={Colors.Gray}
                light
              />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.XLS:
        case FileType.XLSX: {
          return (
            <>
              <Icon name="file-excel" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        case FileType.ZIP: {
          return (
            <>
              <Icon name="file-archive" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          );
        }
        default: {
          return !previewImage ? (
            <>
              <Icon name="file" size={24} color={Colors.Gray} light />
              {!!name && <div styleName="label">{name}</div>}
            </>
          ) : (
            <div
              styleName="preview"
              style={{
                backgroundImage: `url(${JSON.stringify(previewImage)})`,
              }}
            />
          );
        }
      }
    }, [type, previewImage, name, downloadUrl, downloadCallback]);

    const onClickCallback = useCallback(() => {
      onClick(attachment);
    }, [attachment, onClick]);

    return (
      <div styleName="attachment" ref={ref} onClick={onClickCallback}>
        {preview}
        {!!name && <Hint parentRef={ref} label={name} />}
      </div>
    );
  })
);
