import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import { InvoiceSnapShot } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import { Fragment } from "react";

const styles = require("./invoice-item.component.scss");

interface GlobalSearchInvoiceItemComponentProps {
  onInvoiceClick: (id: string) => void;
  invoice: InvoiceSnapShot;
  query: string;
  active: boolean;
  list: HTMLDivElement;
}
interface GlobalSearchInvoiceItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class GlobalSearchInvoiceItemComponent extends React.Component<
  GlobalSearchInvoiceItemComponentProps,
  GlobalSearchInvoiceItemComponentState
> {
  private ref: HTMLDivElement;

  public render() {
    const { displayName } = this.props.invoice;
    const assignmentStyle = classNames("invoice", {
      active: this.props.active,
    });

    if (this.props.active && this.props.list) this.setListPosition();

    return (
      <div
        styleName={assignmentStyle}
        onClick={() => this.props.onInvoiceClick(this.props.invoice.id)}
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="invoice__avatar">
          <i className="fal fa-euro-sign" />
        </div>
        <div styleName="invoice__name">
          <div
            styleName="section"
            dangerouslySetInnerHTML={StringUtil.highlight(
              displayName,
              this.props.query
            )}
          />
          {this.renderMetaData()}
        </div>
      </div>
    );
  }

  private setListPosition() {
    if (!this.ref) return;
    const { clientHeight, scrollTop } = this.props.list;
    const top = this.ref.offsetTop;
    const bottom = top + this.ref.clientHeight;

    if (bottom > clientHeight + scrollTop || top < scrollTop) {
      this.props.list.scrollTop =
        top + this.ref.clientHeight / 2 - clientHeight / 2;
    }
  }

  private renderMetaData(): React.ReactElement<HTMLDivElement> {
    const { linkedRelations } = this.props.invoice;
    if (!linkedRelations || linkedRelations.length === 0) return null;

    return (
      <Fragment>
        {linkedRelations.map((relation, idx) => (
          <div styleName="section extra" key={idx}>
            <span className="fal fa-user" />
            <span>{relation.displayName}</span>
          </div>
        ))}
      </Fragment>
    );
  }
}
