import * as React from "react";
import get from "lodash-es/get";
import * as CSSModules from "react-css-modules";

import {
  ObjectAssignment,
  ListingType,
  ObjectTypeAssignment,
  TypePART,
} from "@haywork/api/kolibri";
import {
  FormControls,
  FormReference,
  Form,
  Input,
  FormReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AssignmentUtil } from "@haywork/util";

const styles = require("./form.component.scss");

interface Props {
  objectAssignment: ObjectAssignment | ObjectTypeAssignment;
  onChange: (assignment: ObjectAssignment | ObjectTypeAssignment) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentFloorLevelComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { floorLevel } = this.props.objectAssignment;

    this.formControls = {
      floorLevel: { value: !floorLevel && floorLevel !== 0 ? "" : floorLevel },
    };

    this.onFloorLevelChange = this.onFloorLevelChange.bind(this);
    this.bindFormRef = this.bindFormRef.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "floorLevel") !==
        get(this.props.objectAssignment, "floorLevel")
    ) {
      const { floorLevel } = this.props.objectAssignment;

      this.formRef.update({
        floorLevel: !floorLevel && floorLevel !== 0 ? "" : floorLevel,
      });
    }
  }

  public render() {
    if (AssignmentUtil.isObjectAssigment(this.props.objectAssignment)) {
      const { listingType } = this.props.objectAssignment;
      if (listingType !== ListingType.Apartment) return null;
    } else {
      const { typePART } = this.props.objectAssignment;
      if (typePART !== TypePART.Apartment) return null;
    }

    return (
      <div styleName="floor-level">
        <Form
          name="floorLevel"
          formControls={this.formControls}
          onChange={this.onFloorLevelChange}
          form={this.bindFormRef}
        >
          <div className="form__row">
            <label htmlFor="floorLevel">
              <ResourceText resourceKey="floorLevel" />
              <Ui.RequiredForPublish />
            </label>
            <Input.Number name="floorLevel" max={99} />
          </div>
        </Form>
      </div>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private onFloorLevelChange(values: FormReturnValue) {
    const objectAssignment: ObjectAssignment | ObjectTypeAssignment = {
      ...this.props.objectAssignment,
      floorLevel: values.floorLevel,
    };

    this.props.onChange(objectAssignment);
  }
}
