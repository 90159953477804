import { ArrayComponent, ArrayInputShouldShowComponent } from "./array";
import { AvatarUploadTriggerComponent } from "./avatar/avatar-trigger.component";
import { AvatarComponent } from "./avatar/avatar.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ColorComponent } from "./color/color.component";
import { DatepickerComponent } from "./datepicker/datepicker.component";
import { InputEmailQueryComponent } from "./email-query/email-query.component";
import EmailWithRelationGroupsComponent from "./email-with-relation-groups";
import { EmailComponent } from "./email/email.component";
import { ExtendedSelectComponent } from "./extended-select/extended-select.component";
import { HiddenComponent } from "./hidden/hidden.component";
import { ImageBase64Component } from "./image-base64/image-base64.component";
import { formControl, InputComponentProps } from "./input.component";
import {
  LinkedListComponent,
  LinkedListObject,
  LinkedValue,
} from "./linked-list/linked-list.component";
import { ListComponent } from "./list/list.component";
import { MultiComponent } from "./multi/multi.component";
import { NumberComponent } from "./number/number.component";
import { ObjectComponent } from "./object/object.component";
import { PasswordComponent } from "./password/password.component";
import { PhoneComponent } from "./phone/phone.component";
import { PostalcodeComponent } from "./postalcode";
import {
  AssigmentQueryComponent,
  GlobalSearchQueryComponent,
  LocationQueryComponent,
  LocationQueryV2,
  LocationSearchType,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  QueryResultStringReturnValue,
  QueryV3Component,
  RelationGroupQueryComponentV2,
  RelationQuery,
  SearchAssigmentQueryComponent,
} from "./query-v3";
import { QueryComponent, QueryResultType } from "./query/query.component";
import { RadioComponent, RadioGroupComponent } from "./radio-group";
import { RelationGroupQueryComponent } from "./relation-group-query/relation-group-query.component";
import { RelationPartnerQueryComponent } from "./relation-partner-query/relation-partner-query.component";
import { InputReviewComponent } from "./review/review.component";
import { Option, SelectComponent } from "./select";
import SelectV2 from "./select-v2";
import { SocialComponent } from "./social/social.component";
import SuggestionComponent from "./suggestion";
import {
  SwitchComponent,
  SwitchLabelPosition,
} from "./switch/switch.component";
import { TextWithMergeFieldComponent } from "./text-with-mergefield/text-with-mergefield.component";
import { TextComponent } from "./text/text.component";
import { TextareaComponent } from "./textarea/textarea.component";
import { TimeComponent } from "./time/time.component";
import { ToggleComponent } from "./toggle/toggle.component";
import { TranslateTextComponent } from "./translate-text/translate-text.component";
import { UrlComponent } from "./url/url.component";

export const Input = {
  Text: formControl(TextComponent),
  Phone: formControl(PhoneComponent),
  Email: formControl(EmailComponent),
  Number: formControl(NumberComponent),
  Password: formControl(PasswordComponent),
  CheckBox: formControl(CheckboxComponent),
  Select: formControl(SelectComponent, true),
  Option,
  Textarea: formControl(TextareaComponent),
  QueryLegacy: formControl(QueryComponent, true),
  Query: formControl(QueryV3Component),
  QueryV3: formControl(QueryV3Component),
  RelationQuery: formControl(RelationQuery),
  LocationQuery: formControl(LocationQueryComponent),
  LocationQueryV2: formControl(LocationQueryV2),
  AssigmentQuery: formControl(AssigmentQueryComponent),
  RelationGroupQueryV2: formControl(RelationGroupQueryComponentV2, true),
  RelationGroupQuery: formControl(RelationGroupQueryComponent),
  RelationPartnerQuery: formControl(RelationPartnerQueryComponent, true),
  GlobalSearchQuery: formControl(GlobalSearchQueryComponent),
  Datepicker: formControl(DatepickerComponent),
  RadioGroup: formControl(RadioGroupComponent, true),
  Radio: RadioComponent,
  Toggle: formControl(ToggleComponent),
  Switch: formControl(SwitchComponent),
  LinkedList: formControl(LinkedListComponent),
  Array: formControl(ArrayComponent, true),
  ArrayInputShouldShow: ArrayInputShouldShowComponent,
  Multi: formControl(MultiComponent, true),
  TranslateText: formControl(TranslateTextComponent),
  Time: formControl(TimeComponent),
  Social: formControl(SocialComponent),
  Object: formControl(ObjectComponent, true),
  Avatar: formControl(AvatarComponent),
  Hidden: formControl(HiddenComponent),
  ExtendedSelect: formControl(ExtendedSelectComponent, true),
  List: formControl(ListComponent),
  Url: formControl(UrlComponent),
  EmailQuery: formControl(InputEmailQueryComponent, false, true),
  Review: formControl(InputReviewComponent),
  Color: formControl(ColorComponent),
  TextWithMergeField: formControl(TextWithMergeFieldComponent),
  Postalcode: formControl(PostalcodeComponent),
  Suggestion: formControl(SuggestionComponent, true, true),
  EmailWithRelationGroups: formControl(
    EmailWithRelationGroupsComponent,
    true,
    true
  ),
  SearchAssignmentQuery: formControl(SearchAssigmentQueryComponent, true),
  NewSelect: formControl(SelectV2, true),
  SelectV2: formControl(SelectV2, true),
  ImageBase64: formControl(ImageBase64Component),
};

export {
  LinkedValue,
  LinkedListObject,
  formControl,
  InputComponentProps,
  SwitchLabelPosition,
  QueryResultType,
  AvatarUploadTriggerComponent,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  QueryResultStringReturnValue,
  LocationSearchType,
};
