import {
  InitializedWidget,
  Widget,
  WidgetEntityType,
  WidgetSnapShot,
} from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState, AuthorizationActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AuthorizationWidgetsComponent,
  AuthorizationWidgetsComponentProps,
} from "./authorization-widgets";
import { consentedWidgetsForEntity } from "./selectors";

interface StateProps {
  widgets: WidgetSnapShot[];
  initializedWidgets: InitializedWidget[];
  initializedWidgetsEntityId: string | string[];
}
interface DispatchProps {
  getInitializedWidgets: (
    entityId: string | string[],
    entityType: WidgetEntityType,
    cancelStoreUpdate?: boolean
  ) => Promise<InitializedWidget[]>;
  showModal: (
    widgetModalWidget: Widget,
    widgetModalInitializedWidget: InitializedWidget
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AuthorizationWidgetsComponentProps,
  AppState
> = (state, ownProps) => {
  const { initializedWidgets, initializedWidgetsEntityId } =
    state.authorization.widgets;

  return {
    widgets: consentedWidgetsForEntity(ownProps.location, ownProps.type)(state),
    initializedWidgets,
    initializedWidgetsEntityId,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AuthorizationWidgetsComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (
    entityId: string | string[],
    entityType: WidgetEntityType,
    cancelStoreUpdate?: boolean
  ) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(
        entityId,
        entityType,
        cancelStoreUpdate
      )
    ),
  showModal: (
    widgetModalWidget: Widget,
    widgetModalInitializedWidget: InitializedWidget
  ) =>
    dispatch(
      AuthorizationActions.Widgets.showModal(
        widgetModalWidget,
        widgetModalInitializedWidget
      )
    ),
});

export type AuthorizationWidgetsContainerProps = StateProps & DispatchProps;
export const AuthorizationWidgetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizationWidgetsComponent);
