import {
  ActiveFilter,
  SortOrder,
  TaskOrderByField,
  TaskSnapShot,
  TaskStatus,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { TaskOrder } from ".";
import { ActionType, ActionTypes } from "./actions";
import { FilterType } from "@haywork/enum/list-filter-types";

export type ListState = {
  tasks: TaskSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  order: TaskOrder;
  scrollOffset: number;
};

const INITIAL: ListState = {
  tasks: [],
  totalCount: 0,
  order: {
    sortOrder: SortOrder.Ascending,
    sortColumn: TaskOrderByField.DefaultOrder,
  },
  filters: {
    filterByActive: {
      type: FilterType.Array,
      value: [ActiveFilter.ActiveOnly],
      emptyValue: [],
      prefix: "tasks.activeFilter",
    },
    taskStatuses: {
      type: FilterType.Array,
      value: [TaskStatus.NotStarted],
      emptyValue: [],
      prefix: "taskStatuses",
    },
    taskCategories: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "taskCategories",
    },
    employees: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "employees",
    },
    delegated: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "tasks.delegateFilter",
    },
  },
  scrollOffset: 0,
};

export const listReducer = (state = INITIAL, action: ActionTypes) => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, startIndex } = action;

      let tasks = [...state.tasks];
      if (!tasks.length) {
        tasks = new Array(totalCount).fill(null);
      }

      tasks = items.reduce((state, assignment, idx) => {
        state[startIndex + idx] = assignment;
        return state;
      }, tasks);

      return {
        ...state,
        tasks,
        totalCount,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        tasks: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        tasks: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;

      return {
        ...state,
        tasks: state.tasks.map((task) =>
          !!task && task.id === snapshot.id ? snapshot : task
        ),
      };
    }
    case ActionType.SetEmployee: {
      const { employee } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          employees: {
            ...state.filters.employees,
            value: [employee.id],
          },
        },
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
