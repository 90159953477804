import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import isString from "lodash-es/isString";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./action-list-item.component.scss");

interface UiActionListItemComponentProps {
  actions: React.StatelessComponent[];
  actionWidth?: number;
  actionCooldown?: number;
  actionTitle?: string | { key: string; values?: object };
  actionVisible?: boolean;
  zebra?: boolean;
  onActionStart?: () => void;
  onActionCancel?: () => void;
  onActionDone?: () => void;
}
interface UiActionListItemComponentState {
  actionsWidth: number;
  actionsVisible: boolean;
  actionCooldown: number;
  actionTitle: string | { key: string; values?: object };
  showActionOverlay: boolean;
  focussed: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class UiActionListItemComponent extends React.Component<
  UiActionListItemComponentProps,
  UiActionListItemComponentState
> {
  private ref: HTMLDivElement;
  private actionCooldown: any;

  constructor(props) {
    super(props);

    this.state = {
      actionsWidth: this.props.actions.length * (this.props.actionWidth || 48),
      actionsVisible: false,
      actionCooldown: this.props.actionCooldown || 5,
      actionTitle: this.props.actionTitle || null,
      showActionOverlay: false,
      focussed: false,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.startActionCooldown = this.startActionCooldown.bind(this);
    this.clearIntervalAndResetState =
      this.clearIntervalAndResetState.bind(this);
    this.onCancelClickHandler = this.onCancelClickHandler.bind(this);
    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: UiActionListItemComponentProps
  ) {
    if (!nextProps) return;

    if (!!nextProps.actionVisible && !this.props.actionVisible) {
      this.setState({
        actionTitle: nextProps.actionTitle,
        actionCooldown: nextProps.actionCooldown || 5,
        showActionOverlay: true,
        actionsVisible: false,
      });
      this.startActionCooldown();
    }
  }

  public render() {
    const itemStyle = classNames("item", {
      zebra: this.props.zebra,
      focussed: this.state.focussed,
    });
    const actionOverlayStyle = classNames("action-overlay", {
      visible: this.state.showActionOverlay,
    });
    const actionStyles: React.CSSProperties = {
      width: this.props.actionWidth || 48,
    };
    const actionsStyles: React.CSSProperties = {
      width: this.state.actionsWidth,
    };
    const innerStyles: React.CSSProperties = this.state.actionsVisible
      ? { left: -this.state.actionsWidth }
      : {};
    const { key, values } = isString(this.props.actionTitle)
      ? { key: this.props.actionTitle, values: {} }
      : !!this.props.actionTitle
      ? this.props.actionTitle
      : { key: "empty", values: {} };

    return (
      <div
        styleName={itemStyle}
        ref={(ref) => (this.ref = ref)}
        id="listItem"
        data-cy="CY-listItem"
      >
        <div styleName={actionOverlayStyle}>
          <div styleName="cancel" onClick={this.onCancelClickHandler}>
            <ResourceText resourceKey={key} values={values} />
            <span>({this.state.actionCooldown})</span>
          </div>
        </div>
        <div styleName="inner" style={innerStyles}>
          <div styleName="children">{this.props.children}</div>
          {!!this.props.actions.length ? (
            <div
              styleName="toggle-actions"
              id="toggle-actions"
              data-cy="CY-toggleActions"
              onClick={this.onToggleClickHandler}
            >
              <i className="fal fa-ellipsis-v" />
            </div>
          ) : (
            <div styleName="toggle-actions__faux" />
          )}
        </div>
        {!!this.props.actions.length && (
          <div styleName="actions" style={actionsStyles}>
            {this.props.actions.map((Action, idx) => (
              <div
                key={idx}
                id={"action" + idx}
                styleName="action"
                style={actionStyles}
              >
                <Action />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    if (!!this.ref && this.ref.contains(event.target) && !this.state.focussed) {
      this.setState({ focussed: true });
    }
    if (
      !!this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.focussed
    ) {
      this.setState({ focussed: false });
    }

    if (
      !!this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.actionsVisible
    ) {
      this.setState({ actionsVisible: false });
    }
  }

  private onToggleClickHandler() {
    this.setState({
      actionsVisible: !this.state.actionsVisible,
    });
  }

  private onCancelClickHandler() {
    this.clearIntervalAndResetState();
    if (this.props.onActionCancel) {
      this.props.onActionCancel();
    }
  }

  private startActionCooldown() {
    if (this.props.onActionStart) {
      this.props.onActionStart();
    }

    this.actionCooldown = setInterval(() => {
      const actionCooldown = this.state.actionCooldown - 1;
      if (actionCooldown === 0) {
        this.clearIntervalAndResetState();
        if (this.props.onActionDone) {
          this.props.onActionDone();
        }
      } else {
        this.setState({ actionCooldown });
      }
    }, 1000);
  }

  private clearIntervalAndResetState() {
    if (!!this.actionCooldown) {
      clearInterval(this.actionCooldown);
      this.actionCooldown = undefined;
    }

    this.setState({
      actionCooldown: this.props.actionCooldown || 5,
      actionTitle: null,
      showActionOverlay: false,
    });
  }
}
