import {
  LinkedAssignment,
  LinkedRelation,
  ObjectAssignment,
  RelationType,
  TemplateDefinitionSnapShot,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Draft,
  EmailAddress,
  File as MailFile,
  Folder,
  FolderCategory,
  Message,
  UploadResponse,
} from "@haywork/api/mail";
import { intlContext } from "@haywork/app";
import { REQUEST } from "@haywork/constants";
import { DraftType } from "@haywork/enum";
import * as moment from "moment";

export interface ExtendedEmailFolder extends Folder {
  accountId: string;
  unreadCount: number;
  canLoadMoreFolders: boolean;
  canLoadMoreFoldersStatus: string;
  canLoadMoreMessages: boolean;
  canLoadMoreMessagesStatus: string;
  folders?: ExtendedEmailFolder[];
  depth?: number;
}

export interface ExtendedEmailAddress extends EmailAddress {
  id?: string;
}
export interface ExtendedEmailDraft extends Draft {
  accountId: string;
  folderId: string;
  hasChanges: boolean;
  isNew?: boolean;
  temporaryId?: string;
  type?: DraftType;
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  selectedTemplate?: TemplateDefinitionSnapShot;
  changesAfterTemplateSelect?: boolean;
  to?: ExtendedEmailAddress[] | null;
}

export enum MergeFieldDataType {
  Company = "Company",
  Office = "Office",
  Employee = "Employee",
  Tenant = "Tenant",
  Purchaser = "Purchaser",
  Notary = "Notary",
  Lessor = "Lessor",
  Vendor = "Vendor",
  WebsiteVisitor = "WebsiteVisitor",
  HouseHunter = "HouseHunter",
  ObjectAssignment = "ObjectAssignment",
  AcquisitionObjectAssignment = "AcquisitionObjectAssignment",
  AgendaItem = "AgendaItem",
  Relation = "Relation",
}

export interface MappedMergeField {
  type: MergeFieldDataType;
  id: string;
  relationType?: RelationType;
}

export class Email {
  public static getFolderIcon(category: FolderCategory): string {
    switch (category) {
      case FolderCategory.All:
        return "fa-archive";
      case FolderCategory.Archive:
        return "fa-envelope";
      case FolderCategory.Drafts:
        return "fa-edit";
      case FolderCategory.Important:
        return "fa-exclamation";
      case FolderCategory.Inbox:
        return "fa-inbox";
      case FolderCategory.Sent:
        return "fa-paper-plane";
      case FolderCategory.Spam:
        return "fa-ban";
      case FolderCategory.Trash:
        return "fa-trash-alt";
      default:
        return "fa-folder";
    }
  }

  public static mapFolderToExtendedFolder(
    folder: Folder,
    accountId: string,
    canLoadMoreFolders: boolean = false
  ): ExtendedEmailFolder {
    return {
      ...folder,
      accountId,
      unreadCount: 0,
      canLoadMoreFolders,
      canLoadMoreFoldersStatus: REQUEST.IDLE,
      canLoadMoreMessages: true,
      canLoadMoreMessagesStatus: REQUEST.IDLE,
    };
  }

  public static mapDraftToExtendedDraft(
    draft: Draft,
    accountId: string,
    folderId: string,
    hasChanges: boolean = false
  ): ExtendedEmailDraft {
    return {
      ...draft,
      accountId,
      folderId,
      hasChanges,
      temporaryId: draft.id,
    };
  }

  public static parseEmailsAsString(
    emailAddresses: EmailAddress[]
  ): string | null {
    if (!emailAddresses || emailAddresses.length === 0) return null;

    return emailAddresses
      .map((address) => {
        return !!address.name && address.name !== address.email
          ? `${address.name} <${address.email}>`
          : address.email;
      })
      .join(", ");
  }

  public static mapExtendedDraftToDraft(draft: ExtendedEmailDraft): Draft {
    return {
      id: draft.id,
      threadId: draft.threadId,
      subject: draft.subject,
      to: draft.to,
      cc: draft.cc,
      bcc: draft.bcc,
      snippet: draft.snippet,
      body: draft.body,
      date: draft.date,
      files: draft.files,
      userData: draft.userData,
      sendRequested: draft.sendRequested,
    };
  }

  public static mapFileUploadResponseToMailFile(
    file: UploadResponse,
    isAttachment: boolean = false
  ): MailFile {
    return {
      id: file.fileId,
      fileName: file.fileName,
      size: file.size,
      contentType: file.contentType,
      contentId: file.fileId,
      isAttachment,
      uri: file.uri,
    };
  }

  public static prepareEmailBody(
    from: EmailAddress[],
    to: EmailAddress[],
    cc: EmailAddress[],
    date: Date,
    subject: string,
    body: string,
    messages: { [id: string]: string }
  ): string {
    const filteredBody = (body || "").replace(
      /<\/?haywork(template|signature|body)>/gi,
      ""
    );

    const fromString = Email.parseEmailsAsString(from);
    const toString = Email.parseEmailsAsString(to);
    const ccString = Email.parseEmailsAsString(cc);

    const messageData = [];
    messageData.push(
      `<p style="font-family: Arial, sans-serif; font-size: 14px; color: #000000">`
    );
    messageData.push(
      `<strong>${intlContext.formatMessage({
        id: "email.info.sent",
        defaultMessage: "Sent",
      })}</strong>: ${moment(date).format("DD MMMM YYYY, HH:mm:ss")}<br />`
    );
    if (!!fromString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.from",
          defaultMessage: "From",
        })}</strong>: ${fromString}<br />`
      );
    }
    if (!!toString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.to",
          defaultMessage: "To",
        })}</strong>: ${toString}<br />`
      );
    }
    if (!!ccString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.cc",
          defaultMessage: "CC",
        })}</strong>: ${ccString}<br />`
      );
    }
    messageData.push(
      `<strong>${intlContext.formatMessage({
        id: "email.info.subject",
        defaultMessage: "Subject",
      })}</strong>: ${subject}<br />`
    );

    if (!/<body[^>]*>/gi.test(filteredBody)) {
      return [
        `<hayworktemplate><p>&nbsp;</p></hayworktemplate>`,
        `<hayworksignature></hayworksignature>`,
        `<hr style="border: none; height: 1px; background-color: #cccccc; margin: 24px 0;" />`,
        `<hayworkbody>${messageData.join("") + filteredBody}</hayworkbody>`,
      ].join("");
    }

    const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/gim;
    const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/gim;

    let result;
    const styles = [];
    while ((result = styleRegex.exec(filteredBody)) !== null) {
      styles.push((result[0] || "").replace(/body/gi, "contentbody"));
    }

    const bodyParts = [];
    while ((result = bodyRegex.exec(filteredBody)) !== null) {
      let part = result[0] || "";
      part = part.replace(/<(\s+)?body/gi, "<contentbody");
      part = part.replace(/<(\s+)?\/(\s+)?body/gi, "</contentbody");

      bodyParts.push(part);
    }

    const newBody = [...bodyParts, ...styles]
      .join("")
      .replace(/body/g, "contentbody");

    return [
      `<hayworktemplate><p>&nbsp;</p></hayworktemplate>`,
      `<hayworksignature></hayworksignature>`,
      `<hr style="border: none; height: 1px; background-color: #cccccc; margin: 24px 0;" />`,
      `<hayworkbody>`,
      messageData.join(""),
      newBody,
      `</hayworkbody>`,
    ].join("");
  }

  public static mapAcquisitionAssignmentDataToMergeData(
    acquisitionObjectAssignment?: AcquisitionObjectAssignment
  ): MappedMergeField[] {
    const fields: MappedMergeField[] = [];

    if (!!acquisitionObjectAssignment) {
      const { id } = acquisitionObjectAssignment;

      fields.push({ type: MergeFieldDataType.AcquisitionObjectAssignment, id });
    }

    return fields;
  }

  public static mapAssignmentDataToMergeData(
    assignment?: ObjectAssignment
  ): MappedMergeField[] {
    let fields: MappedMergeField[] = [];

    if (!!assignment) {
      const {
        id,
        forSale,
        linkedApplicants,
        linkedNotaries,
        linkedVendors,
        linkedPotentials,
        linkedClients,
      } = assignment;

      fields.push({ type: MergeFieldDataType.ObjectAssignment, id });

      if (!!linkedApplicants && !!linkedApplicants.length) {
        const mapped = linkedApplicants.map((linkedApplicant) => ({
          type: !!forSale
            ? MergeFieldDataType.Purchaser
            : MergeFieldDataType.Tenant,
          id: linkedApplicant.id,
          relationType: linkedApplicant.typeOfRelation,
        }));

        fields = [...fields, ...mapped];
      }

      if (!!linkedNotaries && !!linkedNotaries.length) {
        const mapped = linkedNotaries.map((linkedNotary) => ({
          type: MergeFieldDataType.Notary,
          id: linkedNotary.id,
          relationType: linkedNotary.typeOfRelation,
        }));

        fields = [...fields, ...mapped];
      }

      if (!!linkedVendors && !!linkedVendors.length) {
        const mapped = linkedVendors.map((linkedVendor) => ({
          type: !!forSale
            ? MergeFieldDataType.Vendor
            : MergeFieldDataType.Lessor,
          id: linkedVendor.id,
          relationType: linkedVendor.typeOfRelation,
        }));

        fields = [...fields, ...mapped];
      }

      if (!!linkedPotentials && !!linkedPotentials.length) {
        const mapped = linkedPotentials.map((linkedPotential) => ({
          type: MergeFieldDataType.HouseHunter,
          id: linkedPotential.id,
          relationType: linkedPotential.typeOfRelation,
        }));

        fields = [...fields, ...mapped];
      }

      if (!!linkedClients && !!linkedClients.length) {
        const mapped = linkedClients.map((linkedClient) => ({
          type: MergeFieldDataType.Relation,
          id: linkedClient.id,
          relationType: linkedClient.typeOfRelation,
        }));

        fields = [...fields, ...mapped];
      }
    }

    return fields;
  }
}
