import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { OFFICESROUTES } from "@haywork/constants";
import {
  OfficeGeneralContainerProps,
  OfficeReadonlyComponent,
} from "@haywork/modules/settings";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface OfficeGeneralComponentProps {}
interface State {}
type Props = OfficeGeneralComponentProps & OfficeGeneralContainerProps;

const styles = require("./office-general.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class OfficeGeneralComponent extends React.Component<Props, State> {
  public render() {
    if (!this.props.selectedOffice) return null;

    return (
      <div styleName="general">
        <PageHeader
          title="pageTitle.office.general"
          subTitle={this.props.selectedOffice.name}
          actions={
            <Button
              label="editOffice"
              category="success"
              onClick={() =>
                this.props.navigate(
                  route(OFFICESROUTES.EDIT_OFFICE.URI, {
                    id: this.props.selectedOfficeId,
                  })
                )
              }
            />
          }
        />

        <div styleName="body">
          <div styleName="detail-component">
            <OfficeReadonlyComponent
              phoneNumberTypes={this.props.phoneNumberTypes}
              emailAddressTypes={this.props.emailAddressTypes}
              countries={this.props.countries}
              office={this.props.selectedOffice}
              officeName={this.props.selectedOffice.name}
              realEstateAgency={this.props.realEstateAgency}
            />
          </div>
        </div>
      </div>
    );
  }
}
