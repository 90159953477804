import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { FileUtil, FileUploadObject } from "@haywork/util";

const styles = require("./attachments.component.scss");

interface Props {
  file: FileUploadObject;
  onDownload: (file: FileUploadObject) => void;
  onRemove: (file: FileUploadObject) => void;
}
interface State {
  tagRemoved: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class EditorAttachmentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      tagRemoved: false,
    };

    this.onDownload = this.onDownload.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  public render() {
    const { file, response } = this.props.file;

    return (
      <div
        styleName={classNames("attachment", {
          "tag-removed": this.state.tagRemoved,
        })}
        onClick={this.onDownload}
      >
        <div styleName="attachment__icon">
          <i
            className={classNames(
              "fal",
              !!file && /image/gi.test(file.type) ? "fa-file-image" : "fa-file"
            )}
          />
          <i className="fal fa-arrow-down" />
        </div>
        <div styleName="attachment__meta">
          <div styleName="name">{response ? response.name : file.name}</div>
          <div styleName="size">
            {FileUtil.getFileSize(response ? response.size : file.size)}
          </div>
          <div
            styleName="close"
            onMouseEnter={() => this.setState({ tagRemoved: true })}
            onMouseLeave={() => this.setState({ tagRemoved: false })}
            onClick={this.onRemove}
          >
            <i className="fal fa-times" />
          </div>
        </div>
      </div>
    );
  }

  private onDownload() {
    this.props.onDownload(this.props.file);
  }

  private onRemove(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.onRemove(this.props.file);
  }
}
