import { BlobFileDataPointer } from "@haywork/api/kolibri";
import { CompanySettingsThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsDocumentsComponent,
  SettingsDocumentsComponentProps,
} from "./documents";

interface StateProps {
  companyLogo: BlobFileDataPointer | null;
}
interface DispatchProps {
  setDocumentsLogo: (file: File | null) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsDocumentsComponentProps,
  AppState
> = (state) => ({
  companyLogo: state.company.settings?.documentSettings?.companyLogo || null,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsDocumentsComponentProps
> = (dispatch) => ({
  setDocumentsLogo: (file: File | null) =>
    dispatch(CompanySettingsThunks.setDocumentsLogo(file)),
});

export type SettingsDocumentsContainerProps = StateProps & DispatchProps;
export const SettingsDocumentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsDocumentsComponent);
