import {
  Employee,
  LinkedAssignment,
  LinkedRelation,
  Priority,
  RelationSnapShot,
  TaskCategoryOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  ColorUtil,
  FormControlUtil,
  RelationUtil,
  StringUtil,
} from "@haywork/util";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import first from "lodash-es/first";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("../new-entity.component.scss");

export interface TaskValues {
  subject?: string;
  linkedAssignments?: LinkedAssignment[];
  linkedRelations?: LinkedRelation[];
}
interface Props extends TaskValues {
  loading: boolean;
  employees: RelationSnapShot[];
  employee: Employee;
  taskCategories: TaskCategoryOption[];
  onSubmit: (values: FormReturnValue) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class TaskComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const linkedEmployee = RelationUtil.mapEmployeeToLinkedEmployee(
      this.props.employee
    );

    this.renderCategoryOption = this.renderCategoryOption.bind(this);

    this.formControls = {
      subject: {
        value: value(this.props, "subject"),
        validators: [Validators.required()],
      },
      priority: { value: Priority.Medium },
      linkedEmployee: { value: linkedEmployee },
      linkedAssignments: { value: value(this.props, "linkedAssignments", []) },
      linkedRelations: { value: value(this.props, "linkedRelations", []) },
      endDate: { value: "" },
      categoryId: { value: first(this.props.taskCategories).value },
      description: { value: "" },
    };
  }

  public render() {
    return (
      <React.Fragment>
        <div styleName="body__inner">
          {this.props.loading && <Ui.Loaders.Fullscreen mask />}
          <Form
            name="new-entity-task"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.props.onSubmit}
          >
            <div className="form__row">
              <label htmlFor="subject">
                <ResourceText resourceKey="simpleTaskSubject" />
              </label>
              <Input.Text
                name="subject"
                placeholder="simpleTaskSubjectPlaceholder"
              />
            </div>

            <div className="form__row">
              <label htmlFor="categoryId">
                <ResourceText resourceKey="simpleTaskCategory" />
              </label>
              <Input.NewSelect
                name="categoryId"
                values={this.props.taskCategories}
                valuesProp="value"
                filterProp="displayName"
                displayFn={this.renderCategoryOption}
              />
            </div>

            <div className="form__row">
              <Input.Switch
                name="priority"
                on={Priority.High}
                off={Priority.Medium}
                label="simpleTaskPriorityLabel"
                labelPosition={SwitchLabelPosition.Post}
              />
            </div>

            <div className="form__row">
              <label htmlFor="linkedAssignments">
                <ResourceText resourceKey="simpleTaskLinkedAssignments" />
              </label>
              <Input.AssigmentQuery name="linkedAssignments" multiple />
            </div>

            <div className="form__row">
              <label htmlFor="linkedRelations">
                <ResourceText resourceKey="simpleTaskLinkedRelations" />
              </label>
              <Input.RelationQuery name="linkedRelations" multiple />
            </div>

            <div className="form__row">
              <label htmlFor="linkedEmployee">
                <ResourceText resourceKey="simpleTaskLinkedEmployee" />
              </label>
              <Input.Query
                name="linkedEmployee"
                optionValue={this.renderQueryEmployeeOption}
                values={this.props.employees}
                matchOn={this.employeeQueryMatchOn}
                selectedValue={this.renderSelectedEmployeeValue}
                placeholder="simpleTaskLinkedEmployeePlaceholder"
              />
            </div>

            <div className="form__row">
              <label htmlFor="endDate">
                <ResourceText resourceKey="simpleTaskEndDate" />
              </label>
              <Input.Datepicker
                name="endDate"
                placeholder="simpleTaskEndDatePlaceholder"
              />
            </div>

            <div className="form__row">
              <label htmlFor="description">
                <ResourceText resourceKey="simpleTaskDescription" />
              </label>
              <Input.Textarea
                name="description"
                placeholder="simpleTaskDescriptionPlaceholder"
                autoSize
              />
            </div>
          </Form>
        </div>
        <div styleName="body__footer">
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.props.loading}
            onClick={() => this.formRef.submit()}
          >
            <ResourceText resourceKey="save" />
          </button>
        </div>
      </React.Fragment>
    );
  }

  private renderCategoryOption(
    category: TaskCategoryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div
        className={classNames("task__category-option", { selected, active })}
      >
        <div
          className="task__category-hint"
          style={{
            backgroundColor: this.categoryColor(category.categoryBackColor),
          }}
        />
        <div
          className="task__category-hint-label"
          dangerouslySetInnerHTML={StringUtil.highlight(
            category.displayName,
            query
          )}
        />
      </div>
    );
  }

  private renderQueryEmployeeOption(
    value: any,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private employeeQueryMatchOn(query: string, value: any): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedEmployeeValue(value: any): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private categoryColor(color: string | null): string {
    return !!color ? ColorUtil.hexToRgb(color) : null;
  }
}
