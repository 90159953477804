import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { Account, FolderCategory } from "@haywork/api/mail";
import { DraftType } from "@haywork/enum";
import {
  Dispatch,
  EmailMessageThunks,
  RelationThunks
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  EmailDetailComponent,
  EmailDetailComponentProps
} from "@haywork/modules/email";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import { connect } from "react-redux";

interface StateProps {
  currentMessage: EmailMessage;
  currentMessageStatus: string;
  currentAccount: Account;
  currentFolder: ExtendedEmailFolder;
  currentFolderIsArchive: boolean;
  currentFolderIsTrash: boolean;
}
interface DispatchProps {
  toggleBookmarked: (
    accountId: string,
    id: string,
    bookmarked: boolean
  ) => void;
  toggleUnread: (accountId: string, message: EmailMessage) => void;
  moveToFolder: (
    accountId: string,
    message: EmailMessage,
    folder: ExtendedEmailFolder
  ) => Promise<void>;
  deleteMessage: (accountId: string, message: EmailMessage) => Promise<void>;
  messageToDraft: (
    message: EmailMessage,
    type: DraftType,
    accountId: string,
    folderId: string
  ) => Promise<void>;
  loadExternalReferences: (message: EmailMessage) => void;
  archiveMessage: (accountId: string, messageId: string) => void;
  createNewEmail: (email?: string) => void;
  getRelationsWithMatchingEmailAddress: (
    emailAdresses: string[],
    relationTypes: RelationType[]
  ) => Promise<RelationSnapShot[]>;
}

const mapStateToProps = <StateProps, EmailDetailComponentProps>(
  state: AppState
) => {
  const { currentAccount } = state.email.accounts;
  const { currentFolder } = state.email.folders;
  const { currentMessage, currentMessageStatus } = state.email.messages;

  return {
    currentMessage,
    currentMessageStatus,
    currentAccount,
    currentFolder,
    currentFolderIsArchive:
      (!!currentFolder && currentFolder.category === FolderCategory.Archive) ||
      (!!currentAccount &&
        !!currentFolder &&
        currentAccount.archiveFolderId === currentFolder.id),
    currentFolderIsTrash:
      !!currentFolder && currentFolder.category === FolderCategory.Trash
  };
};

const mapDispatchToProps = <DispatchProps, EmailDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  toggleBookmarked: (accountId: string, id: string, bookmarked: boolean) =>
    dispatch(EmailMessageThunks.toggleBookmarked(accountId, id, bookmarked)),
  toggleUnread: (accountId: string, message: EmailMessage) =>
    dispatch(EmailMessageThunks.toggleUnread(accountId, message)),
  moveToFolder: (
    accountId: string,
    message: EmailMessage,
    folder: ExtendedEmailFolder
  ) => dispatch(EmailMessageThunks.moveToFolder(accountId, message, folder)),
  deleteMessage: (accountId: string, message: EmailMessage) =>
    dispatch(EmailMessageThunks.deleteMessage(accountId, message)),
  messageToDraft: (
    message: EmailMessage,
    type: DraftType,
    accountId: string,
    folderId: string
  ) =>
    dispatch(
      EmailMessageThunks.messageToDraft(message, type, accountId, folderId)
    ),
  loadExternalReferences: (message: EmailMessage) =>
    dispatch(EmailMessageThunks.loadExternalReferences(message)),
  archiveMessage: (accountId: string, messageId: string) =>
    dispatch(EmailMessageThunks.archiveMessage(accountId, messageId)),
  createNewEmail: (email?: string) =>
    dispatch(EmailThunk.Main.createNewEmail(!email ? [] : [email])),
  getRelationsWithMatchingEmailAddress: (
    emailAdresses: string[],
    relationTypes: RelationType[]
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        emailAdresses,
        relationTypes
      )
    )
});

export type EmailDetailContainerProps = StateProps & DispatchProps;
export const EmailDetailContainer = connect<
  StateProps,
  DispatchProps,
  EmailDetailComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailDetailComponent);
