import * as React from "react";
import { FC, memo, useEffect, useState, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { SearchAssignmentSuggestionsContainerProps } from "./search-assignment-suggestions.container";
import { SearchAssignment } from "@haywork/api/kolibri";
import Suggestion from "./suggestion";
import I18n from "@haywork/components/i18n";
import FormattedText from "@haywork/components/ui/formatted-text";
import head from "lodash-es/head";
import { PageLoader } from "@haywork/modules/shared";

const styles = require("./style.scss");

export type SearchAssignmentSuggestionsComponentProps = {
  relationIds: string[];
  searchAssignmentIds: string[];
  toggleSearchAssignment: (id: string) => void;
};
type Props = SearchAssignmentSuggestionsComponentProps &
  SearchAssignmentSuggestionsContainerProps;

export const SearchAssignmentSuggestionsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      relationIds,
      searchAssignmentIds,
      getSearchAssignmentsByRelationIds,
      toggleSearchAssignment
    }) => {
      const [loading, setLoading] = useState(false);
      const [searchAssignments, setSearchAssignments] = useState<
        SearchAssignment[]
      >([]);

      const [oneAssignmentId, setOneAssignmentId] = useState(null);
      const [clearAssignments, setClearAssignments] = useState(null);

      useEffect(() => {
        if (
          oneAssignmentId &&
          !searchAssignmentIds.includes(head(searchAssignmentIds))
        ) {
          toggleSearchAssignment(oneAssignmentId);
        }
      }, [setOneAssignmentId, oneAssignmentId, searchAssignments]);

      useEffect(() => {
        if (clearAssignments) toggleSearchAssignment(null);
      }, [clearAssignments]);

      const getSearchAssignments = useCallback(
        async (relationIds: string[]) => {
          try {
            setLoading(true);
            const searchAssignments = await getSearchAssignmentsByRelationIds(
              relationIds
            );
            switch (true) {
              case searchAssignments && searchAssignments.length === 0: {
                setSearchAssignments([]);
                setClearAssignments(true);
                break;
              }
              case searchAssignments && searchAssignments.length === 1: {
                setSearchAssignments(searchAssignments);
                const { id } = head(searchAssignments);
                setOneAssignmentId(id);
                break;
              }
              case searchAssignments && searchAssignments.length > 1: {
                setSearchAssignments(searchAssignments);
                break;
              }
              default:
                break;
            }
          } finally {
            setLoading(false);
          }
        },
        []
      );

      useEffect(() => {
        if (!!relationIds && !!relationIds.length) {
          getSearchAssignments(relationIds);
        } else {
          setSearchAssignments([]);
        }
      }, [relationIds]);

      if (!relationIds || !relationIds.length) return null;

      return (
        <>
          <FormattedText
            value="acquisitions.edit.linkedSearchAssignments"
            asSection
          />
          <div className="form__row">
            <div styleName="suggestions__title">
              {!loading && (
                <I18n
                  value={
                    searchAssignments && searchAssignments.length > 0
                      ? "acquisition.label.linkedSearchAssignment"
                      : "acquisition.label.noLinkedSearchAssignment"
                  }
                />
              )}
            </div>
            <div styleName="suggestions">
              {loading && <PageLoader loading />}
              {!loading &&
                searchAssignments.map((searchAssignment) => (
                  <Suggestion
                    key={searchAssignment.id}
                    searchAssignment={searchAssignment}
                    searchAssignmentIds={searchAssignmentIds}
                    onToggle={toggleSearchAssignment}
                  />
                ))}
            </div>
          </div>
        </>
      );
    }
  )
);
