import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { FolderTreeEntityType } from "@haywork/middleware";
import { AssignmentActions, assignmentActions } from "./assignment.action";

export type AssignmentState = {
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
  acquisitionAssignmentObjectStatus: string;
  brochureLink: string;
  brochureStatus: string;
  brochureId: number;
};

const INITIAL: AssignmentState = {
  acquisitionAssignmentObject: null,
  acquisitionAssignmentObjectStatus: REQUEST.IDLE,
  brochureLink: null,
  brochureStatus: REQUEST.IDLE,
  brochureId: undefined
};

export const assignmentReducer = (
  state = INITIAL,
  action: AssignmentActions
): AssignmentState => {
  switch (action.type) {
    case assignmentActions.SetAcquisitionObjectAssignment: {
      const { acquisitionAssignmentObject } = action;

      return {
        ...state,
        acquisitionAssignmentObject,
        acquisitionAssignmentObjectStatus: REQUEST.SUCCESS
      };
    }
    case assignmentActions.SetStatus: {
      const { acquisitionAssignmentObjectStatus } = action;

      return {
        ...state,
        acquisitionAssignmentObjectStatus
      };
    }
    case assignmentActions.SetBrochureStatus: {
      const { brochureStatus, brochureId } = action;

      return {
        ...state,
        brochureId,
        brochureStatus
      };
    }
    case assignmentActions.SetBrochureLink: {
      const { brochureLink } = action;

      return {
        ...state,
        brochureLink,
        brochureStatus: REQUEST.SUCCESS,
        brochureId: undefined
      };
    }
    case assignmentActions.SetEntityFolderTreeId: {
      const { id, entityType, entityId } = <any>action;
      if (
        entityType === FolderTreeEntityType.AcquisitionObjectAssignment &&
        !!state.acquisitionAssignmentObject &&
        state.acquisitionAssignmentObject.id === entityId
      ) {
        return {
          ...state,
          acquisitionAssignmentObject: {
            ...state.acquisitionAssignmentObject,
            linkedFolderTree: {
              id
            }
          }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
