import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { PhotoBlob } from "@haywork/api/kolibri";

const styles = require("./slide-show.component.scss");

enum SlideShowDirection {
  Right = "Right",
  Left = "Left",
}

interface SlideShowComponentProps {
  slides: PhotoBlob[];
  onSlideClick?: (idx: number) => void;
}

interface SlideShowComponentState {
  current: number;
  count: number;
  direction: SlideShowDirection;
}

@CSSModules(styles, { allowMultiple: true })
export class SlideShow extends React.Component<
  SlideShowComponentProps,
  SlideShowComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      count: this.props.slides.length,
      direction: SlideShowDirection.Right,
    };

    this.onPreviousClickHandler = this.onPreviousClickHandler.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.onSlideClickHandler = this.onSlideClickHandler.bind(this);
  }

  public render() {
    const { current, count, direction } = this.state;
    const triggerPreviousStyle = classNames("trigger", "previous", {
      visible: current !== 0 && count !== 1,
    });
    const triggerNextStyle = classNames("trigger", "next", {
      visible: current !== count - 1 && count !== 1,
    });
    const slidesStyle = classNames("slides", {
      left: direction === SlideShowDirection.Left,
      right: direction === SlideShowDirection.Right,
    });

    return (
      <div styleName="slideshow" data-cy={this.props["data-cy"]}>
        <div
          styleName={triggerPreviousStyle}
          onClick={this.onPreviousClickHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.PreviousButton`
          }
        >
          <i className="fal fa-fw fa-chevron-left faIcon" />
        </div>

        <div styleName={slidesStyle}>
          {this.props.slides.map((slide, idx) => {
            const previous =
              direction === SlideShowDirection.Left ? current + 1 : current - 1;
            const style = {
              backgroundImage: `url(${JSON.stringify(slide.urlPreview)})`,
            };
            const slideStyle = classNames("slide", {
              current: idx === current,
              previous: idx === previous,
            });

            return (
              <div
                styleName={slideStyle}
                style={style}
                onClick={() => this.onSlideClickHandler(idx)}
                key={idx}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.SlideClickable`
                }
              />
            );
          })}
        </div>

        <div
          styleName={triggerNextStyle}
          onClick={this.onNextClickHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.NextButton`
          }
        >
          <i className="far fa-fw fa-chevron-right faIcon" />
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: SlideShowComponentProps) {
    if (!nextProps) return;
    if (nextProps.slides.length !== this.props.slides.length) {
      this.setState({ count: nextProps.slides.length });
    }
  }

  private onPreviousClickHandler() {
    const { current, count } = this.state;
    if (current === 0) return;
    this.setState({ current: current - 1, direction: SlideShowDirection.Left });
  }

  private onNextClickHandler() {
    const { current, count } = this.state;
    if (count - 1 === current) return;
    this.setState({
      current: current + 1,
      direction: SlideShowDirection.Right,
    });
  }

  private onSlideClickHandler(idx: number) {
    if (this.props.onSlideClick) this.props.onSlideClick(idx);
  }
}
