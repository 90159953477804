import { connect, MapStateToProps } from "react-redux";
import { AppTitleComponent, AppTitleComponentProps } from "./app-title";
import { AppState } from "@haywork/stores";
import { MapDispatchToProps } from "@haywork/middleware";
import { FeatureHelper } from "@haywork/modules/feature-switch/helpers";
import { appTitle } from "./selectors";

type StateProps = {
  notificationCount: number;
  appTitle: string;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AppTitleComponentProps,
  AppState
> = (state) => {
  const { features } = state.appSettings;

  let notificationCount = 0;
  if (FeatureHelper.executeBlock(features, "EVENTCENTER_V2")) {
    const { statistics } = state.notifications.eventCenter;
    notificationCount = statistics?.numberOfNotArchivedEvents || 0;
  } else {
    const {
      numberOfGeneralUnprocessedNotifications,
      numberOfPersonalUnprocessedNotifications,
    } = state.eventCenter.notifications;

    notificationCount =
      numberOfGeneralUnprocessedNotifications +
      numberOfPersonalUnprocessedNotifications;
  }

  return {
    notificationCount,
    appTitle: appTitle(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AppTitleComponentProps
> = (dispatch) => ({});

export type AppTitleContainerProps = StateProps & DispatchProps;
export const AppTitleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppTitleComponent);
