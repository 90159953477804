import * as React from "react";
import { FC, memo, useContext } from "react";
import { ListColumnConfig, ListContext } from "../..";
import HeaderColumn from "../header-column";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  listName: string;
};

export const HeaderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ listName }) => {
    const { columnConfig } = useContext(ListContext);
    const { columns, withActions } = columnConfig;

    return (
      <div styleName={classNames("header", { "with-actions": withActions })}>
        {columns.map((column, idx) => (
          <HeaderColumn key={idx} config={column} listName={listName} />
        ))}
      </div>
    );
  })
);
