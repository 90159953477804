import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionDetailDossierScreen,
  AcquisitionDetailDossierScreenProps
} from "./dossier";

type StateProps = {
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailDossierScreenProps,
  AppState
> = (state) => {
  const { acquisitionAssignmentObject } = state.acquisition.assignment;

  return {
    acquisitionAssignmentObject
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailDossierScreenProps
> = (dispatch) => ({});

export type AcquisitionDetailDossierScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionDetailDossierScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailDossierScreen);
