import { Action } from "redux";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";

import { REDUX, MAINROUTES } from "@haywork/constants";
import { PhotoBlob } from "@haywork/api/kolibri";
import { ArrayUtil } from "@haywork/util";

import * as ActionType from "./layout.types";

export interface ConfirmConfig {
  title: { key: string; values?: { [key: string]: any } };
  body: { key: string; values?: { [key: string]: any } };
  positiveFn?: Function;
  negativeFn?: Function;
  positiveLabel?: { key: string; values?: { [key: string]: any } };
  negativeLabel?: { key: string; values?: { [key: string]: any } };
}

export interface ConfirmState extends ConfirmConfig {
  visible: boolean;
}

export interface LayoutState {
  sidebarCollapsed: boolean;
  fullscreen: boolean;
  lightboxVisible: boolean;
  lightboxSlides: PhotoBlob[];
  lightboxCurrent: number;
  lightboxCount: number;
  confirm: ConfirmState;
  onboardingVisibility: boolean;
  windowFrameTitle: string | { key: string; values: object };
  windowFramePath: string;
  windowFrameVisible: boolean;
  createLoaderVisible: boolean;
  blobError: string;
}

const FULLSCREEN_ROUTES = [
  MAINROUTES.ASSIGNMENTS.URI,
  MAINROUTES.DYNAMICDOCUMENTS.URI,
  MAINROUTES.OBJECTTYPES.URI,
];

const INITIAL_STATE: LayoutState = {
  sidebarCollapsed: window.innerWidth <= 480,
  fullscreen: false,
  lightboxVisible: false,
  lightboxSlides: [],
  lightboxCurrent: 0,
  lightboxCount: 0,
  confirm: {
    title: { key: "confirmTitle" },
    body: { key: "confirmBody" },
    positiveFn: null,
    negativeFn: null,
    negativeLabel: { key: "no" },
    positiveLabel: { key: "yes" },
    visible: false,
  },
  onboardingVisibility: false,
  windowFrameTitle: "",
  windowFramePath: "",
  windowFrameVisible: false,
  createLoaderVisible: false,
  blobError: null,
};

export const layoutReducer = (
  state: LayoutState = INITIAL_STATE,
  action: Action
): LayoutState => {
  switch (action.type) {
    case REDUX.LAYOUT.TOGGLE_FULLSCREEN: {
      const { status } = <ActionType.Status>action;
      const fullscreen = status === undefined ? !state.fullscreen : status;
      return { ...state, fullscreen };
    }
    case REDUX.LAYOUT.TOGGLE_SIDEBAR: {
      return { ...state, sidebarCollapsed: !state.sidebarCollapsed };
    }
    case REDUX.LAYOUT.LIGHTBOX_SHOW: {
      const { lightboxSlides, lightboxCurrent } = <ActionType.LightboxSlides>(
        action
      );

      return {
        ...state,
        lightboxVisible: true,
        lightboxSlides,
        lightboxCurrent,
        lightboxCount: lightboxSlides.length,
      };
    }
    case REDUX.LAYOUT.LIGHTBOX_NEXT: {
      const lightboxCurrent =
        state.lightboxCurrent < state.lightboxCount - 1
          ? state.lightboxCurrent + 1
          : state.lightboxCount - 1;

      return { ...state, lightboxCurrent };
    }
    case REDUX.LAYOUT.LIGHTBOX_PREVIOUS: {
      const lightboxCurrent =
        state.lightboxCurrent === 0 ? 0 : state.lightboxCurrent - 1;

      return { ...state, lightboxCurrent };
    }
    case REDUX.LAYOUT.LIGHTBOX_HIDE: {
      return { ...state, lightboxVisible: false, lightboxSlides: [] };
    }
    case REDUX.LAYOUT.LIGHTBOX_SET_SLIDE: {
      const { lightboxCurrent } = <ActionType.LightboxCurrent>action;

      return { ...state, lightboxCurrent };
    }
    case LOCATION_CHANGE: {
      const { payload } = <LocationChangeAction>action;
      const segments = payload.location.pathname.split("/");
      const path = segments[2];
      const fullscreen = ArrayUtil.hasMatchInArray(path, FULLSCREEN_ROUTES)
        ? state.fullscreen
        : false;

      return { ...state, fullscreen };
    }
    case REDUX.LAYOUT.CONFIRM_SHOW: {
      const {
        title,
        body,
        positiveFn,
        negativeFn,
        positiveLabel,
        negativeLabel,
      } = <ActionType.Confirm>action;
      const pLabel = positiveLabel || { key: "yes" };
      const nLabel = negativeLabel || { key: "no" };

      const confirm = {
        ...state.confirm,
        visible: true,
        title,
        body,
        positiveFn,
        negativeFn,
        positiveLabel: pLabel,
        negativeLabel: nLabel,
      };

      return { ...state, confirm };
    }
    case REDUX.LAYOUT.CONFIRM_HIDE: {
      const confirm = {
        ...state.confirm,
        visible: false,
        saveFn: null,
        dontSaveFn: null,
      };

      return { ...state, confirm };
    }
    case REDUX.LAYOUT.TOGGLE_ONBOARDING: {
      const { onboardingVisibility } = <ActionType.OnboardingVisibility>action;

      return { ...state, onboardingVisibility };
    }
    case REDUX.LAYOUT.OPEN_WINDOW_FRAME: {
      const { path: windowFramePath, title: windowFrameTitle } = <
        ActionType.WindowFrame
      >action;

      return {
        ...state,
        windowFramePath,
        windowFrameTitle,
        windowFrameVisible: true,
      };
    }
    case REDUX.LAYOUT.CLOSE_WINDOW_FRAME: {
      return {
        ...state,
        windowFramePath: "",
        windowFrameTitle: "",
        windowFrameVisible: false,
      };
    }
    case REDUX.LAYOUT.CLOSE_SIDEBAR_MOBILE: {
      return {
        ...state,
        sidebarCollapsed:
          window.innerWidth <= 480 ? true : state.sidebarCollapsed,
      };
    }
    case REDUX.LAYOUT.SET_CREATE_LOADER_VISIBILITY: {
      const { createLoaderVisible } = <ActionType.CreateLoaderVisible>action;

      return {
        ...state,
        createLoaderVisible,
      };
    }
    case REDUX.LAYOUT.SET_BLOB_ERROR: {
      const { blobError } = <ActionType.BlobError>action;

      return {
        ...state,
        blobError,
      };
    }
    default: {
      return state;
    }
  }
};
