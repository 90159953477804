import {
  Filter,
  FilterConfig,
  FilterSection,
  ListFilter,
  ListRefProps,
  SingleFilterConfig,
} from "@haywork/components/ui/list";
import * as React from "react";
import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FiltersContainerProps } from "./filters.container";
import { TaskDelegateFilter } from "@haywork/api/kolibri";

export type FiltersComponentProps = {
  list: MutableRefObject<ListRefProps>;
};
type Props = FiltersComponentProps & FiltersContainerProps;

export const FiltersComponent: FC<Props> = memo(
  ({
    filters,
    list,
    setFilters,
    employeeValues,
    getEmployees,
    taskStatusValuesOld,
    categoryValues,
    filterByActiveValues,
    delegatedValues,
  }) => {
    const [inited, setInited] = useState(false);

    const updateFilters = useCallback(
      (filters: FilterConfig) => {
        if (!list || !list.current) return;
        setFilters(filters);
        list.current.refresh();
      },
      [list, setFilters]
    );

    const onDelegatedChange = useCallback(
      (filter: SingleFilterConfig) => {
        if (
          filters.delegated.value.some(
            (val) => val === TaskDelegateFilter.AssignedToMe
          ) &&
          filter.value.some((value) => value === TaskDelegateFilter.Delegated)
        ) {
          const removedAssignedFilter = filter.value.filter(
            (val) => val !== TaskDelegateFilter.AssignedToMe
          );
          const delegated = { ...filter, value: removedAssignedFilter };
          const updatedFilters = { ...filters, delegated };
          setFilters(updatedFilters);
          list.current?.refresh();
          return;
        }
        if (
          filters.delegated.value.some(
            (val) => val === TaskDelegateFilter.Delegated
          ) &&
          filter.value.some(
            (value) => value === TaskDelegateFilter.AssignedToMe
          )
        ) {
          const removedDelegatedFilter = filter.value.filter(
            (val) => val !== TaskDelegateFilter.Delegated
          );
          const delegated = { ...filter, value: removedDelegatedFilter };
          const updatedFilters = { ...filters, delegated };
          setFilters(updatedFilters);
          list.current?.refresh();
          return;
        }
        const updatedFilters = { ...filters, delegated: filter };
        setFilters(updatedFilters);
        list.current?.refresh();
      },
      [setFilters, filters]
    );

    useEffect(() => {
      if (inited) return;
      if (!employeeValues.length) getEmployees();
    }, [employeeValues.length, getEmployees, inited]);

    useEffect(() => {
      setInited(true);
    }, [setInited]);

    return (
      <ListFilter filters={filters} onChange={updateFilters}>
        <FilterSection title="tasks.filter.title.status">
          <Filter.CheckboxArray
            configKey="filterByActive"
            values={filterByActiveValues}
          />
          <Filter.CheckboxArray
            configKey="taskStatuses"
            values={taskStatusValuesOld}
          />
        </FilterSection>
        <FilterSection title="tasks.filter.delegation">
          <Filter.CheckboxArray
            configKey="delegated"
            values={delegatedValues}
            onChange={onDelegatedChange}
            blockUpdate
          />
        </FilterSection>

        <FilterSection
          title="tasks.filter.title.category"
          collapsable
          collapsed
        >
          <Filter.CheckboxArray
            configKey="taskCategories"
            values={categoryValues}
          />
        </FilterSection>

        <FilterSection
          title="tasks.filter.title.employees"
          collapsable
          collapsed
        >
          <Filter.CheckboxArray configKey="employees" values={employeeValues} />
        </FilterSection>
      </ListFilter>
    );
  }
);
