import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { LocationsComponent, LocationsComponentProps } from "./locations";

type StateProps = {};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  LocationsComponentProps,
  AppState
> = (state) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LocationsComponentProps
> = (dispatch) => ({});

export type LocationsContainerProps = StateProps & DispatchProps;
export const LocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsComponent);
