import {
  ObjectAssignment,
  TypeOfCommunalAreaOption,
  WelfareStateOption,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  welfareStateOptions: WelfareStateOption[];
  typesOfCommunalArea: TypeOfCommunalAreaOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogRetailSpaceComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const hasRetailersContributionPath = objectAssignment.forSale
      ? "saleOffer.hasRetailersContribution"
      : "rentOffer.hasRetailersContribution";

    this.formControls = {
      publicOrientedServices: {
        value: value(objectAssignment, "publicOrientedServices", false),
      },
      retail: { value: value(objectAssignment, "retail", false) },
      showroom: { value: value(objectAssignment, "showroom", false) },
      hasRetailersContribution: {
        value: value(objectAssignment, hasRetailersContributionPath, false),
      },
      sectorLimitation: {
        value: value(objectAssignment, "sectorLimitation", false),
      },
      hospitalityPermitted: {
        value: value(objectAssignment, "hospitalityPermitted", false),
      },

      vVO: { value: value(objectAssignment, "vVO.area") },
      inUnitsFrom: { value: value(objectAssignment, "inUnitsFrom.area") },
      wvo: { value: value(objectAssignment, "wvo.area") },
      frontWidth: { value: value(objectAssignment, "frontWidth") },
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) },

      welfareState: { value: value(objectAssignment, "welfareState") },
      typesOfCommunalArea: {
        value: value(objectAssignment, "typesOfCommunalArea", []),
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const hasRetailersContributionPath = objectAssignment.forSale
        ? "saleOffer.hasRetailersContribution"
        : "rentOffer.hasRetailersContribution";

      this.formRef.update(
        {
          publicOrientedServices: value(
            objectAssignment,
            "publicOrientedServices",
            false
          ),
          retail: value(objectAssignment, "retail", false),
          showroom: value(objectAssignment, "showroom", false),
          hasRetailersContribution: value(
            objectAssignment,
            hasRetailersContributionPath,
            false
          ),
          sectorLimitation: value(objectAssignment, "sectorLimitation", false),
          hospitalityPermitted: value(
            objectAssignment,
            "hospitalityPermitted",
            false
          ),
          vVO: value(objectAssignment, "vVO.area"),
          inUnitsFrom: value(objectAssignment, "inUnitsFrom.area"),
          wvo: value(objectAssignment, "wvo.area"),
          frontWidth: value(objectAssignment, "frontWidth"),
          numberOfFloors: value(objectAssignment, "numberOfFloors", 0),
          welfareState: value(objectAssignment, "welfareState"),
          typesOfCommunalArea: value(
            objectAssignment,
            "typesOfCommunalArea",
            []
          ),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="retail-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="publicOrientedServices"
            label="publicOrientedServices"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="retail"
            label="retail"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="showroom"
            label="showroom"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="hasRetailersContribution"
            label="hasRetailersContribution"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="sectorLimitation"
            label="sectorLimitation"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="hospitalityPermitted"
            label="hospitalityPermitted"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="surface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="inUnitsFrom">
                <ResourceText resourceKey="inUnitsFrom" />
              </label>
              <div className="input__helper">
                <Input.Number name="inUnitsFrom" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="wvo">
                <ResourceText resourceKey="wvo" />
              </label>
              <div className="input__helper">
                <Input.Number name="wvo" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="frontWidth">
                <ResourceText resourceKey="frontWidth" />
              </label>
              <div className="input__helper">
                <Input.Number name="frontWidth" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="meterShort" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="numberOfFloors">
                <ResourceText resourceKey="numberOfFloorsBog" />
              </label>
              <Input.Number name="numberOfFloors" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="welfareState">
            <ResourceText resourceKey="welfareState" />
          </label>
          <Input.RadioGroup name="welfareState" asButtonList>
            {this.props.welfareStateOptions.map((welfareStateOption, idx) => (
              <Input.Radio
                value={welfareStateOption.value}
                key={idx}
                label={welfareStateOption.displayName}
              />
            ))}
          </Input.RadioGroup>
        </div>

        <div className="form__row">
          <label htmlFor="typesOfCommunalArea">
            <ResourceText resourceKey="typesOfCommunalArea" />
          </label>
          <Input.Multi
            name="typesOfCommunalArea"
            values={this.props.typesOfCommunalArea}
          />
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const fields: any = {
      publicOrientedServices: values.publicOrientedServices,
      retail: values.retail,
      showroom: values.showroom,
      sectorLimitation: values.sectorLimitation,
      hospitalityPermitted: values.hospitalityPermitted,
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      inUnitsFrom: {
        ...objectAssignment.inUnitsFrom,
        area: values.inUnitsFrom,
      },
      wvo: {
        ...objectAssignment.wvo,
        area: values.wvo,
      },
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      frontWidth: values.frontWidth,
      numberOfFloors: values.numberOfFloors,
      welfareState: values.welfareState,
      typesOfCommunalArea: values.typesOfCommunalArea,
    };

    if (objectAssignment.forSale) {
      fields.saleOffer = {
        ...objectAssignment.saleOffer,
        hasRetailersContribution: values.hasRetailersContribution,
      };
    } else {
      fields.rentOffer = {
        ...objectAssignment.rentOffer,
        hasRetailersContribution: values.hasRetailersContribution,
      };
    }

    this.props.onChange(fields);
  }
}
