import { RelationSnapShot } from "@haywork/api/kolibri";
import { MappedDropdownValue } from "@haywork/modules/filter";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface EmployeeListItemComponentProps {
  employee?: RelationSnapShot;
  onEmployeeClickHandler: (employee: RelationSnapShot) => void;
  zebra: boolean;
  officeFilterOptions: MappedDropdownValue[];
  onArchiveEmployee: (employee: RelationSnapShot) => void;
  onUnArchiveEmployee: (employee: RelationSnapShot) => void;
  onEditButtonClick: (employee: RelationSnapShot) => void;
  removeFromList: (employee: RelationSnapShot) => void;
}
interface EmployeeListItemComponentState {
  showActions: boolean;
  preppedForRemove: boolean;
  deleteCountdown: number;
}

/**
 * This component shows employee data in a list item.
 * It throws events when a employee has been clicked, archived or un-archived.
 */
@CSSModules(styles, { allowMultiple: true })
export class EmployeeListItemComponent extends React.Component<
  EmployeeListItemComponentProps,
  EmployeeListItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.state = {
      showActions: false,
      preppedForRemove: false,
      deleteCountdown: 5,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.toggleShowActions = this.toggleShowActions.bind(this);
    this.onEditButtonClickHandler = this.onEditButtonClickHandler.bind(this);
    this.toggleShowActions = this.toggleShowActions.bind(this);
    this.onUnRemoveClickHandler = this.onUnRemoveClickHandler.bind(this);
    this.onArchiveClickHandler = this.onArchiveClickHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const combined = classNames("item ", {
      zebra: this.props.zebra,
      "show-actions": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
    });
    const archiveAction = classNames("action", { actions__archive: true });
    const editAction = classNames("action", { actions__edit: true });
    const officeList: any[] = this.makeArrayUnique(this.makeOfficesArray());
    const id = this.props.employee.id;
    const archiveButton = this.props.employee.isActive
      ? "fal fa-fw fa-archive"
      : "fal fa-fw fa-plus";

    return (
      <div id={id} styleName={combined} ref={(ref) => (this.ref = ref)}>
        <div styleName="item__data">
          <div styleName="relationAvatar">
            {this.props.employee.avatarUrl ? (
              <span
                styleName="image"
                style={{
                  backgroundImage: `url(${JSON.stringify(
                    this.props.employee.avatarUrl
                  )})`,
                }}
              />
            ) : (
              <span styleName="noImage">
                {this.props.employee.letterAvatar}
              </span>
            )}
          </div>
          <div styleName="item__meta__container">
            <div styleName="item__meta" onClick={this.onClickHandler}>
              <div styleName="meta">
                <span styleName="displayName">
                  {this.props.employee.displayName}{" "}
                </span>
                <span styleName="typeOfRelation">
                  <ResourceText resourceKey="ContactEmployee" />{" "}
                </span>
              </div>
              <div styleName="meta contact">
                {this.props.employee.email ? (
                  <span styleName="email">
                    <MailTo email={this.props.employee.email} />
                  </span>
                ) : (
                  ""
                )}
                {this.props.employee.phoneNumber ? (
                  <span styleName="phoneNumber">
                    {this.props.employee.phoneNumber}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div styleName="toggle" onClick={this.toggleShowActions}>
            <i id="toggleEmployee" className="fa fa-fw fa-ellipsis-v" />
          </div>
          <div styleName="undo-delete" onClick={this.onUnRemoveClickHandler}>
            {this.props.employee.isActive ? (
              <div>
                <ResourceText resourceKey="undoDelete" /> (
                {this.state.deleteCountdown})
              </div>
            ) : (
              <div>
                <ResourceText resourceKey="undoActivate" /> (
                {this.state.deleteCountdown})
              </div>
            )}
          </div>
        </div>
        <div styleName="actions">
          <div
            styleName="action actions__edit"
            onClick={this.onEditButtonClickHandler}
          >
            <i className="fal fa-fw fa-pencil" />
          </div>
          <div
            styleName="action actions__delete"
            onClick={this.onArchiveClickHandler}
          >
            <i id="archiveEmployee" className={archiveButton} />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickHandler() {
    this.props.onEmployeeClickHandler(this.props.employee);
  }

  private onEditButtonClickHandler(event) {
    event.stopPropagation();
    this.props.onEditButtonClick(this.props.employee);
  }

  private makeArrayUnique(array: any[]) {
    const officeList = array.concat();
    for (let i = 0; i < officeList.length; ++i) {
      for (let j = i + 1; j < officeList.length; ++j) {
        if (officeList[i].id === officeList[j].id) {
          officeList.splice(j--, 1);
        }
      }
    }
    return officeList;
  }

  private makeOfficesArray() {
    const allOffices: any[] = [];
    const myOffices = undefined; // TODO!!!!!!! this.props.employee.linkedOffices;
    if (myOffices) {
      myOffices.map((value) =>
        allOffices.push({
          id: value.id,
          displayName: value.displayName,
          isActive: value.isActive,
        })
      );
    }
    const allActiveOffices = this.props.officeFilterOptions;
    delete allActiveOffices[0];
    allActiveOffices.map((value) =>
      allOffices.push({
        id: value.value,
        displayName: value.label,
        isActive: null,
      })
    );
    return allOffices;
  }

  private onArchiveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ preppedForRemove: true, showActions: false });
    if (this.props.employee.isActive) {
      this.props.onArchiveEmployee(this.props.employee);
    } else if (!this.props.employee.isActive) {
      this.props.onUnArchiveEmployee(this.props.employee);
    }

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.removeFromList(this.props.employee);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private onUnRemoveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    if (!this.props.employee.isActive) {
      this.props.onArchiveEmployee(this.props.employee);
    } else if (this.props.employee.isActive) {
      this.props.onUnArchiveEmployee(this.props.employee);
    }
  }

  private toggleShowActions() {
    this.setState({ showActions: !this.state.showActions });
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.showActions
    )
      this.setState({ showActions: false });
  }
}
