import {
  AgendaItem,
  EmailAddress,
  EmailAddressType,
  PhoneNumber,
  PhoneNumberType,
  RelationSnapShot,
  RelationType,
  Task,
} from "@haywork/api/kolibri";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  DuplicateModalState,
  initialDuplicateModalState,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import { KEYCODE } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { FormReturnValue } from "@haywork/modules/form";
import { NewEntityContainerProps } from "@haywork/modules/new-entity";
import { ResourceText } from "@haywork/modules/shared";
import { ModalPortal } from "@haywork/portals";
import { FormControlUtil } from "@haywork/util";
import classNames from "classnames";
import has from "lodash-es/has";
import head from "lodash-es/head";
import isArray from "lodash-es/isArray";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AppointmentComponent, AppointmentValues } from "./appointment";
import { RelationComponent, RelationValues } from "./relation";
import { TaskComponent, TaskValues } from "./task";

const styles = require("./new-entity.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface NewEntityOptions {
  type: NewEntityType;
  fieldName?: string;
  newRelation?: RelationValues;
  newTask?: TaskValues;
  newAppointment?: AppointmentValues;
  meta?: { [key: string]: any };
}

export interface NewEntityComponentProps {
  options?: NewEntityOptions;
  visible: boolean;
  onClose: () => void;
  onNewRelation?: (
    relation: RelationSnapShot,
    fieldName?: string,
    meta?: { [key: string]: any }
  ) => void;
  onNewTask?: (
    task: Task,
    fieldName?: string,
    meta?: { [key: string]: any }
  ) => void;
  onNewAppoinment?: (
    appointment: AgendaItem,
    fieldName?: string,
    meta?: { [key: string]: any }
  ) => void;
}
interface State {
  type: NewEntityType;
  loading: boolean;
  duplicateModalState: DuplicateModalState;
  newRelation: {
    type: RelationType;
    values: FormReturnValue;
  };
}
type Props = NewEntityComponentProps & NewEntityContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class NewEntityComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      type: value(this.props.options, "type", undefined),
      loading: false,
      duplicateModalState: initialDuplicateModalState,
      newRelation: null,
    };

    this.onDocumentKeyDownHandler = this.onDocumentKeyDownHandler.bind(this);
    this.onRelationFormSubmitHandler =
      this.onRelationFormSubmitHandler.bind(this);
    this.onTaskSubmitHandler = this.onTaskSubmitHandler.bind(this);
    this.onAppointmentSubmitHandler =
      this.onAppointmentSubmitHandler.bind(this);

    document.addEventListener("keydown", this.onDocumentKeyDownHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (nextProps.visible && !this.props.visible) {
      this.setState({
        type: nextProps.options.type,
      });
    }

    if (this.props.visible && !nextProps.visible) {
      setTimeout(() => {
        this.setState({
          type: undefined,
          loading: false,
        });
      }, 500);
    }
  }

  public render() {
    const newEntityStyle = classNames("new-entity", {
      visible: this.props.visible,
    });

    return (
      <>
        <ModalPortal>
          <div styleName={newEntityStyle}>
            <div styleName="new-entity__header">
              <div styleName="title">{this.renderTitle()}</div>
              <div styleName="close" onClick={this.props.onClose}>
                <i className="fal fa-times" />
              </div>
            </div>
            <div styleName="new-entity__body">
              {!!this.state.type && this.renderTypeForm()}
            </div>
          </div>
        </ModalPortal>
        <DuplicatesModal
          visible={this.state.duplicateModalState.visible}
          view={this.state.duplicateModalState.view}
          relations={this.state.duplicateModalState.relations}
          relationData={this.state.duplicateModalState.relationData}
          onClose={() => this.clearState()}
          onRelationSelect={(relation) => {
            this.props.onNewRelation(
              relation,
              this.props.options.fieldName,
              this.props.options.meta
            );
            this.clearState();
          }}
          onNavigate={() => {
            this.clearState();
          }}
          onCreateNewRelation={() => {
            this.createNewRelation(
              this.state.newRelation.type,
              this.state.newRelation.values
            );
            this.clearState();
            this.props.onClose();
          }}
          mode={"create"}
        />
      </>
    );
  }

  private renderTypeForm() {
    switch (this.state.type) {
      case NewEntityType.Relation:
        if (!has(this.props.options, "newRelation")) return null;
        return (
          <RelationComponent
            {...this.props.options.newRelation}
            loading={this.state.loading}
            titleSuggestions={this.props.titleSuggestions}
            emailAddressTypes={this.props.emailAddressTypes}
            phoneNumberTypes={this.props.phoneNumberTypes}
            onSubmit={this.onRelationFormSubmitHandler}
            countries={this.props.countries}
            countryIso2={this.props.countryIso2}
            culture={this.props.culture}
            relationGroups={this.props.relationGroups}
          />
        );
      case NewEntityType.Task:
        if (!has(this.props.options, "newTask")) return null;
        return (
          <TaskComponent
            {...this.props.options.newTask}
            loading={this.state.loading}
            employees={this.props.employees}
            employee={this.props.employee}
            taskCategories={this.props.taskCategories}
            onSubmit={this.onTaskSubmitHandler}
          />
        );
      case NewEntityType.Appointment:
        if (!has(this.props.options, "newAppointment")) return null;
        return (
          <AppointmentComponent
            {...this.props.options.newAppointment}
            loading={this.state.loading}
            employees={this.props.employees}
            employee={this.props.employee}
            agendaItemCategories={this.props.agendaItemCategories}
            reminderOptions={this.props.reminderOptions}
            onSubmit={this.onAppointmentSubmitHandler}
          />
        );
      default:
        return null;
    }
  }

  private onDocumentKeyDownHandler(event: KeyboardEvent) {
    if (!this.props.visible) return;

    switch (event.keyCode) {
      case KEYCODE.ESCAPE:
        return this.props.onClose();
      default:
        return;
    }
  }

  private async onRelationFormSubmitHandler(
    type: RelationType,
    values: FormReturnValue
  ) {
    if (this.state.loading) return;
    this.setState({ loading: true });

    values = {
      ...values,
      emailAddresses: (values.emailAddresses || [])
        .map((address: EmailAddress) =>
          !address.type ? { ...address, type: EmailAddressType.Home } : address
        )
        .filter((address: EmailAddress) => !!address.address),
      phoneNumbers: (values.phoneNumbers || [])
        .map((phone: PhoneNumber) =>
          !phone.type ? { ...phone, type: PhoneNumberType.Mobile } : phone
        )
        .filter((phone: PhoneNumber) => !!phone.number),
    };

    const emailAddresses = (values.emailAddresses || []).map(
      (e) => e.address as string
    );
    const phoneNumbers = head<string>(
      (values.phoneNumbers || []).map((p) => p.number as string)
    );

    const relations =
      await this.props.getRelationsWithMatchingEmailAddressOrPhoneNumber(
        emailAddresses,
        phoneNumbers
      );

    if (!!relations.length) {
      this.setState({
        duplicateModalState: {
          visible: true,
          view: "duplicates",
          relations,
          relationData: {
            email: head(emailAddresses),
            telephone: phoneNumbers,
          },
        },
        newRelation: {
          type,
          values,
        },
      });
      return;
    }

    this.createNewRelation(type, values);
  }

  private async createNewRelation(type: RelationType, values: FormReturnValue) {
    switch (type) {
      case RelationType.ContactPerson:
        return this.createNewContactPerson(values);
      case RelationType.ContactCompany:
        return this.createNewContactCompany(values);
      default:
        return this.setState({ loading: false });
    }
  }

  private clearState() {
    this.setState({
      duplicateModalState: initialDuplicateModalState,
      newRelation: { type: null, values: null },
      loading: false,
    });
  }

  private async onTaskSubmitHandler(values: FormReturnValue) {
    if (this.state.loading) return;
    this.setState({ loading: true });

    try {
      const task = await this.props.newTask({
        subject: values.subject,
        linkedEmployee: {
          id: values.linkedEmployee.id,
        },
        endDate: values.endDate,
        priority: values.priority,
        linkedAssignments: values.linkedAssignments.map((linkedAssignment) => ({
          id: linkedAssignment.id,
        })),
        linkedRelations: values.linkedRelations.map((linkedRelation) => ({
          id: linkedRelation.id,
          typeOfRelation: linkedRelation.typeOfRelation,
        })),
        categoryId: values.categoryId,
        description: values.description,
      });

      if (this.props.onNewTask) {
        this.props.onNewTask(
          task,
          this.props.options.fieldName,
          this.props.options.meta
        );
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  private async onAppointmentSubmitHandler(values: FormReturnValue) {
    if (this.state.loading) return;
    this.setState({ loading: true });

    try {
      const end = new Date(values.endDateTime);
      const start = new Date(values.startDateTime);
      const [endHour, endMinutes] = values.endTime.split(":");
      const [startHour, startMinutes] = values.startTime.split(":");

      const endDateTime = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        endHour,
        endMinutes
      );
      const startDateTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        startHour,
        startMinutes
      );

      const appointment = await this.props.newAppointment({
        allDayEvent: values.allDayEvent,
        endDateTime,
        linkedEmployees: isArray(values.linkedEmployees)
          ? values.linkedEmployees
          : [values.linkedEmployees],
        startDateTime,
        linkedAgendaItemCategory: values.linkedAgendaItemCategory,
        linkedAssignments: values.linkedAssignments,
        location: !!values.location ? values.location.description : "",
        linkedRelations: values.linkedRelations,
        subject: values.subject,
        isPrivate: values.isPrivate,
        description: values.description,
      });

      if (this.props.onNewAppoinment) {
        this.props.onNewAppoinment(
          appointment,
          this.props.options.fieldName,
          this.props.options.meta
        );
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  private async createNewContactPerson(values: FormReturnValue) {
    try {
      const relation = await this.props.newContactPerson({
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        nameLetters: values?.nameLetters,
        emailAddresses: values.emailAddresses,
        title: values.title,
        gender: values.gender,
        phoneNumbers: values.phoneNumbers,
        useFormalAppelation: values.useFormalAppelation,
        salutation: values.salutation,
        address: values.address,
        linkedRelationGroups: values.linkedRelationGroups,
      });

      if (this.props.onNewRelation) {
        this.props.onNewRelation(
          relation,
          this.props.options.fieldName,
          this.props.options.meta
        );
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  private async createNewContactCompany(values: FormReturnValue) {
    try {
      const relation = await this.props.newContactCompany({
        displayName: values.displayName,
        webAddress: values.webAddress,
        emailAddresses: values.emailAddresses,
        phoneNumbers: values.phoneNumbers,
        address: values.address,
        linkedRelationGroups: values.linkedRelationGroups,
      });

      if (this.props.onNewRelation) {
        this.props.onNewRelation(
          relation,
          this.props.options.fieldName,
          this.props.options.meta
        );
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  private renderTitle() {
    switch (this.state.type) {
      case NewEntityType.Relation:
        return <ResourceText resourceKey="simpleCreateNewRelation" />;
      case NewEntityType.Task:
        return <ResourceText resourceKey="simpleCreateNewTask" />;
      case NewEntityType.Appointment:
        return <ResourceText resourceKey="simpleCreateNewAppointment" />;
      default:
        return null;
    }
  }
}
