import {
  CountryOption,
  EmailAddressType,
  LinkedRelationGroup,
  Office,
  PhoneNumberType,
  SocialMediaType,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { OFFICESROUTES } from "@haywork/constants/routes";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { OfficeContainerProps } from "@haywork/modules/settings";
import {
  PageLoader,
  ResourceText,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import { AddressRequest } from "@haywork/request";
import { FormControlUtil, RouteUtil, StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;
const styles = require("./edit.component.scss");

export interface OfficeEditComponentProps {
  changeTabTitle?: (newTitle: string) => void;
}
interface State {
  country: string;
  addressResult: google.maps.places.AutocompletePrediction;
  postalCountry: string;
  postalAddressResult: google.maps.places.AutocompletePrediction;
  countryString: string;
  postalCountryString: string;
  countryCode: string;
  postalCountryCode: string;
  isRelationGroupLoading: boolean;
  relationGroupsThatCantBeDeleted: LinkedRelationGroup[];
}
type Props = OfficeEditComponentProps & OfficeContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class OfficeEditComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private form: FormReference;
  private isSaving: boolean;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.renderCountryString = this.renderCountryString.bind(this);
    this.onAddressSearchChangeHandler =
      this.onAddressSearchChangeHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onAddManualAddress = this.onAddManualAddress.bind(this);
    this.onAddManualLanguage = this.onAddManualLanguage.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);

    this.state = {
      ...this.state,
      country: this.props.office.visitAddress.countryIso2,
      postalCountry: this.props.office.postalAddress
        ? this.props.office.postalAddress.countryIso2
        : this.props.office.visitAddress.countryIso2,
      postalAddressResult: null,
      countryString: this.renderCountryString(
        this.props.office.visitAddress.countryIso2
      ),
      postalCountryString: this.renderCountryString(
        this.props.office.postalAddress.countryIso2
      ),
      countryCode: this.props.office.visitAddress.countryIso2,
      postalCountryCode: this.props.office.postalAddress.countryIso2,
      isRelationGroupLoading: false,
    };

    // Set formcontrols
    const office = this.props.office;

    this.formControls = {
      name: {
        value: value(office, "name"),
        validators: [Validators.required()],
      },
      discription: { value: value(office, "aboutMe", "") },
      webAddress: {
        value: value(office, "webAddress"),
        validators: [Validators.url()],
      },
      association: { value: value(office, "associationInfo.association") },
      membershipNumber: {
        value: value(office, "associationInfo.membershipNumber"),
      },
      legalRegistrationNumber: {
        value: value(office, "legalRegistration.number"),
      },
      registrationLocality: {
        value: value(office, "legalRegistration.locality"),
      },
      legalRegistrationDate: { value: value(office, "legalRegistration.date") },
      bankAccountNumber: { value: value(office, "bankAccount.number") },
      socialMedia: { value: value(office, "socialMedia") },
      phoneNumbers: { value: value(office, "phoneNumbers") },
      emailAddresses: { value: value(office, "emailAddresses") },
      streetname: { value: value(office, "visitAddress.street.name") },
      houseNumber: { value: value(office, "visitAddress.houseNumber") },
      houseNumberPostfix: {
        value: value(office, "visitAddress.houseNumberPostfix"),
      },
      postalCode: { value: value(office, "visitAddress.postalCode") },
      localityName: { value: value(office, "visitAddress.locality.name") },
      spokenLanguages: { value: value(office, "spokenLanguages", []) },
      postalStreetname: { value: value(office, "postalAddress.street.name") },
      postalHouseNumber: { value: value(office, "postalAddress.houseNumber") },
      postalHouseNumberPostfix: {
        value: value(office, "postalAddress.houseNumberPostfix"),
      },
      postalPostalCode: { value: value(office, "postalAddress.postalCode") },
      postalLocalityName: {
        value: value(office, "postalAddress.locality.name"),
      },
      postalCountry: { value: value(office, "postalAddress.countryIso2") },
      country: { value: value(office, "visitAddress.countryIso2") },
      addressSearch: {
        value: "",
        onChange: (ref) => {
          if (ref.value && ref.value.length > 0) {
            this.props.searchAddress(
              ref.value[0].description,
              this.state.countryCode,
              false,
              this.props.office.id
            );
          }
        },
      },
      postalAddressSearch: {
        value: "",
        onChange: (ref) => {
          if (ref.value && ref.value.length > 0) {
            this.props.searchAddress(
              ref.value[0].description,
              this.state.postalCountryCode,
              true,
              this.props.office.id
            );
          }
        },
      },
    };

    this.phoneNumbersFormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Mobile },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Work },
    };
  }

  public render() {
    const platforms = [
      SocialMediaType.Facebook,
      SocialMediaType.LinkedIn,
      SocialMediaType.YouTube,
      SocialMediaType.Twitter,
    ];

    return (
      <div styleName="edit" ref={(ref) => (this.ref = ref)} id="scroll-to-top">
        {this.props.saveOfficeState === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}

        <div styleName="body">
          <Form
            formControls={this.formControls}
            name="office"
            onSubmit={this.onFormSubmitHandler}
            model={this.props.office}
            form={(formRef) => (this.form = formRef)}
            onChange={this.onChangeHandler}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              {/* Basic tab */}
              <StepComponent title="contactBasis">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="displayName">
                      <ResourceText resourceKey="officeName" />
                    </label>
                    <Input.Text name="name" placeholder="officeName" />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Phone numbers */}
                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="phoneNumbers" />
                    </label>
                    <Input.Array
                      name="phoneNumbers"
                      formControls={this.phoneNumbersFormControls}
                      max={4}
                      className="form__group stretch"
                      canBeEmpty={true}
                    >
                      <div className="column" styleName="phone__field">
                        <Input.Text name="number" placeholder="phoneNumber" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="type__field">
                        <Input.NewSelect
                          name="type"
                          values={this.props.phoneNumberTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>
                  <div styleName="relation-form__divider" />

                  {/* Email addresses */}
                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="emailAddresses" />
                    </label>
                    <Input.Array
                      name="emailAddresses"
                      formControls={this.emailAddressesFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column" styleName="email__field">
                        <Input.Text name="address" placeholder="address" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="type__field">
                        <Input.NewSelect
                          name="type"
                          values={this.props.emailAddressTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>
                  <div styleName="relation-form__divider" />

                  {/* Web Address */}
                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="website" />
                    </label>
                    <Input.Url
                      name="webAddress"
                      placeholder={this.props.office.webAddress}
                    />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Spoken Languages */}
                  <div className="form__row">
                    <label htmlFor="spokenLanguages">
                      <ResourceText resourceKey="spokenLanguages" />
                    </label>
                    {/* TODO replace asap */}
                    <Input.QueryLegacy
                      name="spokenLanguages"
                      placeholder="spokenLanguages"
                      values={this.props.spokenLanguages}
                      multiple
                      matchPath="displayName"
                      displayPath="displayName"
                      valuePath="value"
                      asSelect
                      onAddManualOption={this.onAddManualLanguage}
                    />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Address */}

                  <div className="form__row">
                    {(this.props.office.visitAddress &&
                      this.props.office.postalAddress &&
                      this.props.office.visitAddress.street &&
                      this.props.office.visitAddress.street.name) ||
                    this.props.office.visitAddress.postalCode ||
                    (this.props.office.visitAddress.locality &&
                      this.props.office.visitAddress.locality.name) ? (
                      <div>{this.renderAddress(false)}</div>
                    ) : (
                      <div>{this.renderSearchAddressBar(false)}</div>
                    )}
                    {!this.props.office.postalAddressDiffersVisitAddress && (
                      <div styleName="addLink">
                        <a
                          onClick={() =>
                            this.props.togglePostalAddress(this.props.office.id)
                          }
                        >
                          <ResourceText resourceKey="addPostalAddressQuestion" />
                        </a>
                      </div>
                    )}
                  </div>

                  {this.props.office.postalAddressDiffersVisitAddress && (
                    <div className="form__row">
                      {(this.props.office.postalAddress.street &&
                        this.props.office.postalAddress.street.name) ||
                      this.props.office.postalAddress.postalCode ||
                      (this.props.office.postalAddress.locality &&
                        this.props.office.postalAddress.locality.name) ? (
                        <div>{this.renderAddress(true)}</div>
                      ) : (
                        <div styleName="addressBarRemoveOption">
                          {this.renderSearchAddressBar(true)}
                          <button
                            styleName="deleteItem"
                            type="button"
                            onClick={() => {
                              this.props.togglePostalAddress(
                                this.props.office.id
                              );
                            }}
                          >
                            <i className="fal fa-minus-circle" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {this.props.addressSearchState === REQUEST.ERROR && (
                    <div>
                      <ResourceText
                        resourceKey="couldNotFindAddress"
                        values={{
                          path: SUPPORTURI.ADDRESSNOTFOUND,
                        }}
                        asHtml
                      />
                    </div>
                  )}
                </div>
              </StepComponent>

              {/* Social tab */}

              <StepComponent title="contactSocial">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="aboutTheOffice">
                      <ResourceText resourceKey="aboutTheOffice" />
                    </label>
                    <Input.TranslateText
                      name="discription"
                      languages={this.props.languages}
                      defaultLanguage={this.props.defaultLanguage}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="socialMedia" />
                    <Input.Social name="socialMedia" platforms={platforms} />
                  </div>
                </div>
              </StepComponent>
            </StepperComponent>
            <button className="btn btn-success" type="submit">
              <ResourceText resourceKey="save" />
            </button>
          </Form>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: OfficeEditComponentProps & OfficeContainerProps
  ) {
    if (nextProps.office && nextProps.office.visitAddress && !this.isSaving) {
      const address = nextProps.office.visitAddress;
      this.form.update({
        streetname: address.street ? address.street.name : "",
        houseNumber: address.houseNumber ? address.houseNumber : "",
        houseNumberPostfix: address.houseNumberPostfix
          ? address.houseNumberPostfix
          : "",
        postalCode: address.postalCode ? address.postalCode : "",
        localityName: address.locality ? address.locality.name : "",
        addressSearch: "",
      });
    }
    if (nextProps.office && nextProps.office.postalAddress && !this.isSaving) {
      const postalAddress = nextProps.office.postalAddress;
      this.form.update({
        postalStreetname: postalAddress.street ? postalAddress.street.name : "",
        postalHouseNumber: postalAddress.houseNumber
          ? postalAddress.houseNumber
          : "",
        postalHouseNumberPostfix: postalAddress.houseNumberPostfix
          ? postalAddress.houseNumberPostfix
          : "",
        postalPostalCode: postalAddress.postalCode
          ? postalAddress.postalCode
          : "",
        postalLocalityName: postalAddress.locality
          ? postalAddress.locality.name
          : "",
        postalAddressSearch: "",
      });
    }
  }

  /* render functions*/
  private renderPhoneNumbers(phonenumberFormControls: FormControls) {
    return (
      <div className="form__row">
        <Input.Array
          name="phoneNumbers"
          formControls={phonenumberFormControls}
          max={4}
          className="form__group stretch"
        >
          <div className="column">
            <Input.Phone name="number" placeholder="phoneNumber" />
          </div>
          <div className="column__spacer" />
          <div className="column">
            <Input.NewSelect
              name="type"
              values={this.props.phoneNumberTypes}
              displayProp="displayName"
              valuesProp="value"
            />
          </div>
        </Input.Array>
      </div>
    );
  }

  private renderAddress(isPostalAddress: boolean) {
    return (
      <div styleName="address-block-container">
        <span styleName="title">
          <ResourceText
            resourceKey={isPostalAddress ? "postalAddress" : "visitAddress"}
          />
        </span>
        <div styleName="address-block">
          <div styleName="fields">
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="street">
                  <label htmlFor="streetname">
                    <ResourceText resourceKey="streetname" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalStreetname" : "streetname"}
                    placeholder="streetname"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="houseNumber">
                    <ResourceText resourceKey="houseNumber" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalHouseNumber" : "houseNumber"}
                    placeholder="houseNumber"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="houseNumberPostfix">
                    <ResourceText resourceKey="houseNumberPostfix" />
                  </label>
                  <Input.Text
                    name={
                      isPostalAddress
                        ? "postalHouseNumberPostfix"
                        : "houseNumberPostfix"
                    }
                    placeholder="houseNumberPostfix"
                  />
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="postalcode">
                  <label htmlFor="postalCode">
                    <ResourceText resourceKey="postalCode" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalPostalCode" : "postalCode"}
                    placeholder="postalCode"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="localityName">
                    <ResourceText resourceKey="locality" />
                  </label>
                  <Input.Text
                    name={
                      isPostalAddress ? "postalLocalityName" : "localityName"
                    }
                    placeholder="locality"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            styleName="deleteItem"
            type="button"
            onClick={() =>
              this.props.removeAddress(isPostalAddress, this.props.office.id)
            }
          >
            <i className="fal fa-minus-circle" />
          </button>
        </div>
      </div>
    );
  }

  private renderSearchAddressBar(isPostalAddress: boolean) {
    return (
      <div styleName="addressBar">
        <label styleName="title">
          <ResourceText
            resourceKey={isPostalAddress ? "postalAddress" : "visitAddress"}
          />
        </label>
        <div className="form__group">
          <div className="column" styleName="address-search-country">
            <Input.NewSelect
              name="country"
              values={this.props.countries}
              valuesProp="iso2CodeValue"
              filterProp="displayName"
              displayValueFn={this.renderCountryValue}
              displayFn={this.renderCountryOption}
              onChange={(value) => {
                if (isPostalAddress) {
                  this.setState({
                    postalCountryString: this.renderCountryString(value),
                    postalCountryCode: value,
                  });
                } else {
                  this.setState({
                    countryString: this.renderCountryString(value),
                    countryCode: value,
                  });
                }
              }}
            />
          </div>
          <div className="column__spacer" />
          <div className="column" styleName="address-search-query">
            <Input.QueryLegacy
              name={isPostalAddress ? "postalAddressSearch" : "addressSearch"}
              asyncValues={(value) =>
                AddressRequest.addressSearch(
                  value,
                  isPostalAddress
                    ? this.state.postalCountry
                    : this.state.countryCode
                )
              }
              placeholder="addressPlaceholder"
              displayPath="description"
              isLoading={this.props.addressSearchState === REQUEST.PENDING}
              noItemsFoundText="addressNotFoundAddManually"
              onAddManualOption={(value: string) => {
                this.onAddManualAddress(value, isPostalAddress);
              }}
              onChange={(value) => this.onAddressSearchChangeHandler(value)}
            />
          </div>
        </div>
      </div>
    );
  }

  private onFormSubmitHandler(values: FormReturnValue) {
    this.isSaving = true;
    const office = this.props.office;
    const editedOffice = this.getContactOfficeFromFormValues(values);

    const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id: office.id });
    this.props.saveOffice(editedOffice, path);
  }

  private getContactOfficeFromFormValues(values: FormReturnValue) {
    const office: Office = this.props.office;
    let spokenLanguages: any[] = values.spokenLanguages;
    /**
     * somehow, when the spokenlanguages are not changed the values.spokenLanguages will contain an entire object instead of an array.
     * therefore it has to be mapped to an array once more.
     */

    if (spokenLanguages.length > 0) {
      if (
        values.spokenLanguages[0].displayName &&
        values.spokenLanguages[0].id &&
        values.spokenLanguages[0].iso2CodeValue &&
        values.spokenLanguages[0].value
      ) {
        spokenLanguages = this.mapSpokenLanguageObject(values.spokenLanguages);
      }
    }

    const result: Office = {
      ...office,
      name: values.name,
      aboutMe: values.discription,
      associationInfo: {
        association: values.association,
        membershipNumber: values.membershipNumber,
      },
      phoneNumbers: values.phoneNumbers,
      emailAddresses: values.emailAddresses,
      webAddress: values.webAddress,
      socialMedia: values.socialMedia,
      spokenLanguages,
      postalAddress: {
        ...this.props.office.postalAddress,
        houseNumber: values.postalHouseNumber,
        houseNumberPostfix: values.postalHouseNumberPostfix,
        postalCode: values.postalPostalCode,
        street: {
          ...this.props.office.postalAddress.street,
          name: values.postalStreetname,
        },
        locality: {
          ...this.props.office.postalAddress.locality,
          name: values.postalLocalityName,
        },
      },
      visitAddress: {
        ...this.props.office.visitAddress,
        houseNumber: values.houseNumber,
        houseNumberPostfix: values.houseNumberPostfix,
        postalCode: values.postalCode,
        street: {
          ...this.props.office.visitAddress.street,
          name: values.streetname,
        },
        locality: {
          ...this.props.office.visitAddress.locality,
          name: values.localityName,
        },
      },
    };

    result.emailAddresses = result.emailAddresses.map((emailAddress) => {
      if (!emailAddress.type) {
        emailAddress.type = EmailAddressType.Work;
      }
      return emailAddress;
    });

    result.phoneNumbers = result.phoneNumbers.map((phoneNumber) => {
      if (!phoneNumber.type) {
        phoneNumber.type = PhoneNumberType.Work;
      }
      return phoneNumber;
    });

    return result;
  }

  private onAddressSearchChangeHandler(result: FormReturnValue) {
    if (result.country !== this.state.country) {
      this.setState({ country: result.country });
    }
    if (
      result.addressSearch &&
      (!this.state.addressResult ||
        this.state.addressResult.place_id !== result.addressSearch[0].place_id)
    ) {
      this.setState({
        country: result.country,
        addressResult: result.addressSearch,
      });
    }
    this.props.resetSearchState();
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private renderCountryString(countryCode: string): string {
    return this.props.intl.formatMessage({
      id: `countryCode${countryCode}`,
      defaultMessage: countryCode,
    });
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const editedOffice = this.getContactOfficeFromFormValues(formReturnValue);
    this.props.updateOffice(editedOffice, this.props.path);
  }

  private onAddManualAddress(value: string, isPostalAddress: boolean) {
    this.props.addManualAddress(value, isPostalAddress, this.props.office.id);
  }

  private onAddManualLanguage(value) {
    this.props.addSpokenLanguage(value);
  }

  private mapSpokenLanguageObject(objectToMap: any): any[] {
    const mappedArray = [];
    objectToMap.map((values) => {
      mappedArray.push(values.value);
    });
    return mappedArray;
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}
