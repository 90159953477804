import {
  AssignmentType,
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Pill from "@haywork/components/ui/pill";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { EmailMessage } from "@haywork/stores/email-v2";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import * as CSSModules from "react-css-modules";
import SaveModal from "../save-modal";
import { CommunicationLogContainerProps } from "./communication-log.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type CommunicationLogComponentProps = {
  message: EmailMessage;
  linkedCommunicationLogId: string | undefined;
  onUpdated: (communicationLog: CommunicationLog) => void;
};
type Props = CommunicationLogComponentProps & CommunicationLogContainerProps;

export const CommunicationLogComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      message,
      linkedCommunicationLogId,
      getCommunicationLog,
      navigate,
      onUpdated,
      readMessage,
    }) => {
      const [linkedRelations, setLinkedRelations] = useState(
        [] as LinkedRelation[]
      );
      const [linkedAssignments, setLinkedAssignments] = useState(
        [] as LinkedAssignment[]
      );
      const [saveModalVisible, setSaveModalVisible] = useState(false);
      const [loading, setLoading] = useState(false);
      const [communicationLog, setCommunicationLog] = useState(
        null as CommunicationLog | null
      );
      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] = useState(
        null as NewEntityOptions
      );

      const fetchDataFromCommunicationLog = useCallback(
        async (linkedCommunicationLogId: string | undefined) => {
          if (!linkedCommunicationLogId) return;

          try {
            setLoading(true);

            const communicationLog = await getCommunicationLog(
              linkedCommunicationLogId
            );

            if (!communicationLog) return;
            setCommunicationLog(communicationLog);
            const { linkedRelations, linkedAssignments } = communicationLog;

            setLinkedRelations(linkedRelations || []);
            setLinkedAssignments(linkedAssignments || []);
            onUpdated(communicationLog);

            return communicationLog;
          } finally {
            setLoading(false);
          }
        },
        [getCommunicationLog, onUpdated]
      );

      useEffect(() => {
        if (!!linkedCommunicationLogId)
          fetchDataFromCommunicationLog(linkedCommunicationLogId);
      }, [linkedCommunicationLogId, fetchDataFromCommunicationLog]);

      const onOpenSaveModal = useCallback(async () => {
        if (!communicationLog) {
          const response = await readMessage(
            message.id,
            message.accountId,
            message.folderId
          );
          if (!!response?._metaData?.linkedCommunicationLogId) {
            const log = await getCommunicationLog(
              response._metaData.linkedCommunicationLogId
            );
            setCommunicationLog(log);
          }
        }
        setSaveModalVisible(true);
      }, [communicationLog, message, readMessage, getCommunicationLog]);

      const onCloseSaveModal = useCallback(
        async (refresh = false) => {
          setSaveModalVisible(false);
          if (refresh) {
            const communicationLog = await fetchDataFromCommunicationLog(
              linkedCommunicationLogId
            );
            if (!!onUpdated && !!communicationLog) {
              onUpdated(communicationLog);
            }
          }
        },
        [linkedCommunicationLogId, fetchDataFromCommunicationLog, onUpdated]
      );

      const onSaveModalAddRelation = useCallback(
        (name: string, communicationLog: CommunicationLog) => {
          setNewEntityOptions({
            type: NewEntityType.Relation,
            newRelation: {
              name,
            },
          });

          setSaveModalVisible(false);
          setNewEntityVisible(true);
          setCommunicationLog(communicationLog);
        },
        []
      );

      const onNavigateToRelation = useCallback(
        (relation: LinkedRelation) => {
          const { typeOfRelation, id } = relation;

          if (saveModalVisible) {
            setSaveModalVisible(false);
          }

          switch (typeOfRelation) {
            case RelationType.ContactPerson: {
              navigate(route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id }));
              return;
            }
            case RelationType.ContactCompany: {
              navigate(
                route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id })
              );
              return;
            }
            case RelationType.Employee: {
              navigate(route(EMPLOYEEROUTES.EMPLOYEE.URI, { id }));
              return;
            }
            case RelationType.Office: {
              navigate(route(OFFICESROUTES.OFFICE_DETAIL.URI, { id }));
              return;
            }
            default:
              return;
          }
        },
        [navigate, saveModalVisible]
      );

      const onNavigateToAssignment = useCallback(
        (assignment: LinkedAssignment) => {
          const { typeOfAssignment, id } = assignment;

          if (saveModalVisible) {
            setSaveModalVisible(false);
          }

          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              navigate(route(ACQUISITIONROUTES.DETAIL.URI, { id }));
              return;
            }
            case AssignmentType.AcquisitionObject: {
              navigate(route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id }));
              return;
            }
            case AssignmentType.Object: {
              navigate(route(ASSIGNMENTROUTES.DETAIL.URI, { id }));
              return;
            }
            case AssignmentType.ObjectType: {
              navigate(route(OBJECTTYPESROUTES.DETAIL.URI, { id }));
              return;
            }
            case AssignmentType.Project: {
              navigate(route(PROJECTROUTES.DETAIL.URI, { id }));
              return;
            }
            default:
              return;
          }
        },
        [navigate, saveModalVisible]
      );

      const onNewEntityCloseHandler = useCallback(() => {
        setNewEntityVisible(false);
        setNewEntityOptions(null);
      }, [setNewEntityOptions, setNewEntityVisible]);

      const onNewRelationHandler = useCallback(
        (relation: RelationSnapShot) => {
          onNewEntityCloseHandler();
          setCommunicationLog({
            ...communicationLog,
            linkedRelations: [
              ...(communicationLog.linkedRelations || []),
              relation,
            ],
          });
          setSaveModalVisible(true);
        },
        [onNewEntityCloseHandler, communicationLog]
      );

      return loading ? null : (
        <div styleName="wrapper">
          {!!linkedRelations.length || !!linkedAssignments.length ? (
            <div>
              <div styleName="title">
                <I18n value="communicationLogWidget.title.savedInTimeline" />
              </div>

              {linkedRelations.map((relation) => (
                <Pill
                  label={relation.displayName}
                  key={relation.id}
                  color={
                    relation.typeOfRelation === RelationType.ContactCompany
                      ? Colors.Secondary
                      : Colors.Primary
                  }
                  icon="users"
                  onClick={() => onNavigateToRelation(relation)}
                  solid
                />
              ))}
              {linkedAssignments.map((assignment) => (
                <Pill
                  label={assignment.displayName || assignment.publicReference}
                  key={assignment.id}
                  color={Colors.Primary}
                  icon={
                    assignment.typeOfAssignment === AssignmentType.Acquisition
                      ? "handshake-alt"
                      : "folder-open"
                  }
                  onClick={() => onNavigateToAssignment(assignment)}
                  solid
                />
              ))}
              <Pill
                label="communicationLogWidget.action.edit"
                color={Colors.Primary}
                icon="pencil"
                onClick={onOpenSaveModal}
              />
            </div>
          ) : (
            <Pill
              label="communicationLogWidget.action.saveMessage"
              color={Colors.Success}
              icon="save"
              onClick={onOpenSaveModal}
              solid
            />
          )}

          <SaveModal
            visible={saveModalVisible}
            message={message}
            communicationLog={communicationLog}
            onClose={onCloseSaveModal}
            onAddRelation={onSaveModalAddRelation}
          />

          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={onNewEntityCloseHandler}
            onNewRelation={onNewRelationHandler}
          />
        </div>
      );
    }
  )
);
