import {
  AssignmentPhase,
  AvailabilityStatus,
  ObjectTypeAssignment
} from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";
import { Colors } from "@haywork/enum/colors";

const SOLD_STATUSES = [
  AvailabilityStatus.Farmed,
  AvailabilityStatus.Leased,
  AvailabilityStatus.Rented,
  AvailabilityStatus.Sold,
  AvailabilityStatus.Withdrawn
];

export enum ObjectTypeAction {
  FinalizeRent = "FinalizeRent",
  FinalizeSale = "FinalizeSale",
  WithDraw = "WithDraw",
  Publish = "Publish",
  AddBuildNumbers = "AddBuildNumbers",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Remove = "Remove",
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAppointment = "CreateAppointment",
  CreateInvoice = "CreateInvoice",
  OpenParentProject = "OpenParentProject"
}

type Props = {
  objectType: ObjectTypeAssignment;
  canSendEmail: boolean;
  onClick: (action: ObjectTypeAction) => void;
};

export const ObjectTypeGeneralActionsComponent: FC<Props> = memo(
  ({ objectType, canSendEmail, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const {
      assignmentPhase,
      availabilityStatus,
      forRent,
      forSale,
      isActive,
      linkedProjectAssignment
    } = objectType;

    const showFinalizeRent =
      !!isActive &&
      !!forRent &&
      assignmentPhase === AssignmentPhase.Initiated &&
      availabilityStatus === AvailabilityStatus.Available;
    const showFinalizeSale =
      !!isActive &&
      !!forSale &&
      assignmentPhase === AssignmentPhase.Initiated &&
      availabilityStatus === AvailabilityStatus.Available;
    const showWithdraw =
      !!isActive &&
      assignmentPhase === AssignmentPhase.Initiated &&
      availabilityStatus === AvailabilityStatus.Available;
    const showPublish =
      !!isActive && assignmentPhase === AssignmentPhase.Concept;
    const showArchive =
      !!isActive &&
      ([AssignmentPhase.Concept, AssignmentPhase.Completed].indexOf(
        assignmentPhase
      ) !== -1 ||
        SOLD_STATUSES.indexOf(availabilityStatus) !== -1) &&
      !!isActive;
    const showUnArchive = !isActive;
    const showRemove = assignmentPhase === AssignmentPhase.Concept;
    const showCreateEmail = canSendEmail;
    const showOpenParentProject =
      !!linkedProjectAssignment && !!linkedProjectAssignment.id;

    const onClickHandler = (action: ObjectTypeAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={<Icon name="chevron-down" light size={16} />}
          iconPosition="end"
          category="primary"
          label="actions"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          {showFinalizeRent && (
            <Action
              label="completeAssignment"
              icon={
                <Icon
                  light
                  name="forward"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.FinalizeRent)}
            />
          )}
          {showFinalizeSale && (
            <Action
              label="completeAssignment"
              icon={
                <Icon
                  light
                  name="arrows-h"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.FinalizeSale)}
            />
          )}
          {showWithdraw && (
            <Action
              label="withDrawAssignment"
              icon={
                <Icon
                  light
                  name="arrow-left"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.WithDraw)}
            />
          )}
          {showPublish && (
            <Action
              label="assignmentMenuPublish"
              icon={
                <Icon
                  light
                  name="globe"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.Publish)}
            />
          )}
          <Action
            label="assignmentMenuAddBuildNumbers"
            icon={
              <Icon
                light
                name="plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ObjectTypeAction.AddBuildNumbers)}
          />

          <Spacer label="assignment.actions.label.other" />
          {showOpenParentProject && (
            <Action
              label="assignment.actions.label.openParentProject"
              icon={
                <Icon
                  light
                  name="folder-open"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.OpenParentProject)}
            />
          )}
          {showCreateEmail && (
            <Action
              label="assignment.actions.label.email"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ObjectTypeAction.CreateEmail)}
            />
          )}
          <Action
            label="assignment.actions.label.task"
            icon={
              <Icon
                light
                name="tasks"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ObjectTypeAction.CreateTask)}
          />
          <Action
            label="assignment.actions.label.appointment"
            icon={
              <Icon
                light
                name="calendar-plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ObjectTypeAction.CreateAppointment)}
          />
          <Action
            label="assignment.actions.label.invoice"
            icon={
              <Icon
                light
                name="euro-sign"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ObjectTypeAction.CreateInvoice)}
          />

          {(showArchive || showUnArchive || showRemove) && (
            <>
              <Spacer />
              {showArchive && (
                <Action
                  label="assignmentMenuArchive"
                  icon={
                    <Icon
                      light
                      name="archive"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(ObjectTypeAction.Archive)}
                />
              )}
              {showUnArchive && (
                <Action
                  label="assignmentMenuUnArchive"
                  icon={
                    <Icon
                      light
                      name="inbox-out"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(ObjectTypeAction.UnArchive)}
                />
              )}

              {showRemove && (
                <Action
                  label="assignmentMenuRemove"
                  icon={
                    <Icon
                      light
                      name="trash-alt"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(ObjectTypeAction.Remove)}
                />
              )}
            </>
          )}
        </ActionList>
      </>
    );
  }
);

export default ObjectTypeGeneralActionsComponent;
