import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { store, ErrorActions } from "@haywork/stores";

const styles = require("./error-boundary.component.scss");

enum ErrorBoundaryView {
  RootDevelopment = "RootDevelopment",
  RootProduction = "RootProduction",
  InlineDevelopment = "InlineDevelopment",
  InlineProduction = "InlineProduction",
}

export interface ErrorBoundaryComponentProps {
  isRoot?: boolean;
  placeholder?: (
    error: Error,
    info: React.ErrorInfo
  ) => React.ReactElement<HTMLDivElement>;
}
interface State {
  hasError: boolean;
  error: Error;
  info: React.ErrorInfo;
  showStackTrace: boolean;
}
type Props = ErrorBoundaryComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class ErrorBoundaryComponent extends React.Component<Props, State> {
  private view: ErrorBoundaryView;

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null,
      showStackTrace: false,
    };

    this.view = this.getErrorBoundaryView();

    this.onToggleClick = this.onToggleClick.bind(this);
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public render() {
    return this.state.hasError
      ? this.renderErrorMessage()
      : this.props.children;
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error, info });
    if (!!store) {
      const stringError = JSON.stringify({
        timestamp: new Date().toString(),
        error: !!error ? error.toString() : "",
        stack: !!error && !!error.stack ? error.stack : "",
        info: !!info && !!info.componentStack ? info.componentStack : "",
      });

      store.dispatch(ErrorActions.addError({ error: stringError }));
    }
  }

  private onToggleClick() {
    this.setState({ showStackTrace: !this.state.showStackTrace });
  }

  private renderErrorMessage(): React.ReactElement<HTMLDivElement> {
    switch (this.view) {
      case ErrorBoundaryView.RootDevelopment:
        return this.renderRootStackTrace();
      case ErrorBoundaryView.RootProduction:
        return this.renderRootError();
      case ErrorBoundaryView.InlineDevelopment:
        return this.renderInlineStackTrace();
      default:
        return this.renderInlineError();
    }
  }

  private renderRootStackTrace(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="stack-trace is-root">
        <h1>An error has occurred</h1>
        {this.state.error && <h2>{this.state.error.toString()}</h2>}
        {this.renderStackTrace()}
      </div>
    );
  }

  private renderRootError(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="app-error">
        <div styleName="app-error__inner">
          <div styleName="app-error__logo" />
          <h1>
            <ResourceText resourceKey="userErrorTitle" />
          </h1>
          <ResourceText resourceKey="userErrorBody" asHtml />
        </div>
      </div>
    );
  }

  private renderInlineStackTrace(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="stack-trace">
        <h1>An error has occurred</h1>
        {this.state.error && <h2>{this.state.error.toString()}</h2>}
        <div styleName="stack-trace__toggle" onClick={this.onToggleClick}>
          toggle stacktrace
        </div>
        {this.state.showStackTrace && this.renderStackTrace()}
      </div>
    );
  }

  private renderInlineError(): React.ReactElement<HTMLDivElement> {
    return !!this.props.placeholder
      ? this.props.placeholder(this.state.error, this.state.info)
      : null;
  }

  private renderStackTrace(): React.ReactElement<HTMLPreElement> {
    return (
      <pre>
        {this.state.error && this.state.error.stack && this.state.error.stack}
        <br />
        {this.state.info &&
          this.state.info.componentStack &&
          this.state.info.componentStack}
      </pre>
    );
  }

  private getErrorBoundaryView(): ErrorBoundaryView {
    let view = ErrorBoundaryView.InlineDevelopment;

    // Component is root component
    if (!!this.props.isRoot) {
      switch (process.env.NODE_ENV) {
        case "development":
          view = ErrorBoundaryView.RootDevelopment;
          break;
        default:
          view = ErrorBoundaryView.RootProduction;
          break;
      }
    }

    // Component is child component and environment doesn't equal development
    if (!this.props.isRoot && process.env.NODE_ENV !== "development") {
      view = ErrorBoundaryView.InlineProduction;
    }

    return view;
  }
}
