import { Garage, Garden, Space } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { GarageContainer, GardenContainer } from "../forms";
import { FloorSpace, RoomType } from "../room-layout.component";

const styles = require("./sub-section.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface RoomLayoutSubSectionComponentProps {
  space: FloorSpace<Space | Garden | Garage>;
  spaces: FloorSpace<Space | Garden | Garage>[];
  roomType: RoomType;
  floors: number[];
  number: number;
  expanded: boolean;
  emptyTitle: string;
  onExpandClick: (id: string) => void;
  onDelete: (id: string) => void;
  onSubmit: (
    space: FloorSpace<Space | Garden | Garage>,
    id: string,
    closeExpand: boolean
  ) => void;
}
interface RoomLayoutSubSectionComponentState {
  title: string;
  bodyStyles: React.CSSProperties;
}

@CSSModules(styles, { allowMultiple: true })
export class RoomLayoutSubSectionComponent extends React.Component<
  RoomLayoutSubSectionComponentProps,
  RoomLayoutSubSectionComponentState
> {
  private bodyRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.onExpandClickHandler = this.onExpandClickHandler.bind(this);
    this.bindBodyRef = this.bindBodyRef.bind(this);

    this.state = {
      title: value(this.props.space, "space.type", this.props.emptyTitle),
      bodyStyles: null,
    };
  }

  public render() {
    return (
      <div styleName="section">
        <div styleName="section__header">
          <div styleName="key">{this.props.number}</div>
          <div styleName="title">
            <ResourceText
              masterKey="spaceTypes"
              resourceKey={this.state.title}
            />
          </div>
          <div
            styleName="expand"
            onClick={this.onExpandClickHandler}
            data-cy="CY-specifications"
          >
            <div styleName="icon">
              <span className="fal fa-fw fa-pencil" />
            </div>
            <ResourceText resourceKey="specs" />
          </div>
        </div>
        <div styleName="section__body" style={this.state.bodyStyles}>
          <div styleName="inner" ref={this.bindBodyRef}>
            {this.renderTypeForm()}
          </div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RoomLayoutSubSectionComponentProps
  ) {
    if (!nextProps) return;
    if (!!this.bodyRef && nextProps.expanded !== this.props.expanded) {
      if (!nextProps.expanded) {
        this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
        setTimeout(() => {
          this.setState({ bodyStyles: { height: 0, overflow: "hidden" } });
        }, 1);
        setTimeout(() => {
          this.setState({ bodyStyles: null });
        }, 451);
      } else {
        this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
        setTimeout(() => {
          this.setState({ bodyStyles: { height: "auto" } });
        }, 450);
      }
    }
  }

  private onExpandClickHandler() {
    this.props.onExpandClick(this.props.space.id);
  }

  private renderTypeForm() {
    switch (this.props.roomType) {
      case RoomType.Garden:
        return (
          <GardenContainer
            space={this.props.space}
            onDelete={() => this.props.onDelete(this.props.space.id)}
            onSubmit={(space, closeExpand) =>
              this.props.onSubmit(space, this.props.space.id, closeExpand)
            }
          />
        );
      case RoomType.Garage:
        return (
          <GarageContainer
            space={this.props.space}
            onDelete={() => this.props.onDelete(this.props.space.id)}
            onSubmit={(space, closeExpand) =>
              this.props.onSubmit(space, this.props.space.id, closeExpand)
            }
          />
        );
      default:
        return null;
    }
  }

  private bindBodyRef(ref: HTMLDivElement) {
    if (!!ref && !this.bodyRef) this.bodyRef = ref;
  }
}
