import { LinkedMessage } from "@haywork/api/event-center";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Ui } from "@haywork/modules/ui";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import * as CSSModules from "react-css-modules";
import { Message } from "./message";
import { SystemMessagesContainerProps } from "./system-messages.container";

const styles = require("./style.scss");

export interface SystemMessagesComponentProps {}

type Props = SystemMessagesComponentProps & SystemMessagesContainerProps;

export const SystemMessages: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ getSystemMessages }) => {
    const [messages, setMessages] = useState<LinkedMessage[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchEvents = useCallback(async () => {
      try {
        setLoading(true);
        const messages = await getSystemMessages();
        setMessages(messages);
      } finally {
        setLoading(false);
      }
    }, [getSystemMessages, setMessages, setLoading]);

    useEffect(() => {
      fetchEvents();
    }, [fetchEvents]);

    return !messages || !messages.length ? null : (
      <div className="widget-wrapper">
        <article className="widget" styleName="widget-system-message">
          <header>
            <span className="icon">
              <Icon name="megaphone" color="#231c11" light size={14} />
            </span>
            <span className="text">
              <I18n value="messagesWidgetTitle" />
            </span>
          </header>
          <div
            className={classNames("content", {
              emptyState: !loading && messages.length === 0,
            })}
          >
            {loading && <Ui.Loaders.List />}

            {!loading &&
              messages.map((message) => (
                <Message message={message} key={message.id} />
              ))}

            {!loading && messages.length === 0 && (
              <span>
                <I18n value="widgets.systemmessage.noNewMessages" />
              </span>
            )}
          </div>
        </article>
      </div>
    );
  })
);
