import * as React from "react";
import { FC, memo, useState, useEffect, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { PublishPublicationsContainerProps } from "./publish-publications.container";
import {
  PublicationSnapShot,
  PublicationStatus,
  ListingType,
} from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import Publication from "./components/publication";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

export type PublishPublicationsComponentProps = {
  assignmentId: string;
  listingType?: ListingType;
  isAssignmentConcept: boolean;
};
type Props = PublishPublicationsComponentProps &
  PublishPublicationsContainerProps;

export const PublishPublicationsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignmentId, getPublications, isAssignmentConcept, listingType }) => {
      const [publications, setPublications] = useState<PublicationSnapShot[]>(
        []
      );
      const [loading, setLoading] = useState(true);
      const [expanded, setExpanded] = useState(false);

      const toggleExpanded = () => {
        setExpanded(!expanded);
      };

      const fetchPublications = async () => {
        try {
          const contracts = await getPublications(assignmentId);
          setPublications(contracts);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchPublications();
      }, [assignmentId]);

      const activePublications = useMemo(() => {
        return publications.filter(
          (publication) =>
            publication.mediaPartnerIsActive &&
            publication.publicationStatus !== PublicationStatus.Inactive
        );
      }, [publications]);

      const inActivePublications = useMemo(() => {
        return publications.filter(
          (publication) =>
            publication.mediaPartnerIsActive &&
            publication.publicationStatus === PublicationStatus.Inactive
        );
      }, [publications]);

      const combinedPublications = [
        ...activePublications,
        ...inActivePublications,
      ];

      const visiblePublications = useMemo(() => {
        return expanded
          ? combinedPublications
          : combinedPublications.slice(0, 3);
      }, [combinedPublications, expanded]);

      if (loading) return null;

      const hasActivePublications = !!activePublications.length;
      const iconName = hasActivePublications
        ? "check-circle"
        : "exclamation-triangle";
      const iconColor = hasActivePublications ? Colors.Success : Colors.Warning;

      return (
        <div styleName="publications">
          <div styleName="title">
            <div styleName="title__icon">
              <Icon name={iconName} color={iconColor} light size={24} />
            </div>
            <div styleName="title__text">
              <I18n
                value="publishPublications.title"
                values={{ count: activePublications.length }}
              />
            </div>
          </div>
          <div styleName="list">
            {visiblePublications.map((publication) => (
              <ErrorBoundary key={publication.id + publication.mediaPartnerId}>
                <Publication
                  publication={publication}
                  assignmentId={assignmentId}
                  listingType={listingType}
                  isAssignmentConcept={isAssignmentConcept}
                  onUpdatedPublication={fetchPublications}
                />
              </ErrorBoundary>
            ))}
          </div>
          {combinedPublications.length > 3 && (
            <div styleName="toggle-expanded">
              <span className="as-link" onClick={toggleExpanded}>
                <I18n
                  value={
                    expanded
                      ? "publishPublications.collapse"
                      : "publishPublications.expand"
                  }
                  values={{ count: combinedPublications.length - 2 }}
                />
              </span>
            </div>
          )}
        </div>
      );
    }
  )
);
