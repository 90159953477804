import { AssignmentPhase, ObjectTypeAssignment } from "@haywork/api/kolibri";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { ObjectTypesEditComponent } from "@haywork/modules/object-types";
import { AppState, EditableItem, LayoutActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { editableDisplayName, hasChanges, savingType } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  fullscreen: boolean;
  canPublish: boolean;
  saving: boolean;
  displayName: string;
  hasChanges: boolean;
}
interface DispatchProps {
  toggleFullscreen: () => void;
  saveType: (currentType: ObjectTypeAssignment, close?: boolean) => void;
  navigate: (path: string) => void;
  backToDashboard: () => Promise<void>;
  reloadObjectType: (editable: EditableItem) => Promise<void>;
}

const currentTypeSelector = (state: AppState) =>
  state.editable.currentComponentState;
const canPublish = createSelector(
  currentTypeSelector,
  (currentType: ObjectTypeAssignment) => {
    if (!currentType) return false;
    return (
      !!currentType.isNew ||
      currentType.assignmentPhase === AssignmentPhase.Concept
    );
  }
);

const mapStateToProps = <StateProps, ObjectTypesEditComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;
  const { fullscreen } = state.layout;

  return {
    currentType,
    fullscreen,
    canPublish: canPublish(state),
    saving: savingType(state),
    displayName: editableDisplayName(state),
    hasChanges: hasChanges(state),
  };
};

const mapDispatchToProps = <DispatchProps, ObjectTypesEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  toggleFullscreen: () => dispatch(LayoutActions.toggleFullscreen()),
  saveType: (currentType: ObjectTypeAssignment, close?: boolean) =>
    dispatch(ProjectsThunks.Types.saveType(currentType, close)),
  navigate: (path: string) => dispatch(push(path)),
  backToDashboard: () => dispatch(ProjectsThunks.Types.backToDashboard()),
  reloadObjectType: (editable: EditableItem) =>
    dispatch(ProjectsThunks.Types.reloadObjectType(editable)),
});

export type ObjectTypesEditContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  WithIntlProps<any>;
export const ObjectTypesEditContainer = injectIntl(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(ObjectTypesEditComponent)
  )
);
