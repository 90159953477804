import { WidgetEntityType } from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  LinkedAssignment,
  LinkedRelation,
  TaskCategoryOption,
  TimelineActionSubType,
  TimelineActionType,
  TimelineEvent,
  TimelineEventsStatistics,
  CommunicationLog,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import {
  INVOICEROUTES,
  REQUEST,
  SCHEDULERROUTES,
  SEARCHASSIGNMENTROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { EmailMessageBlobContainer } from "@haywork/modules/email";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  DropdownFilter,
  Filter,
  MappedDropdownValue,
} from "@haywork/modules/filter";
import Notes from "@haywork/modules/notes-v3";
import {
  InfiniteScroll,
  ListLoader,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import AddCommunicationLogModal from "@haywork/modules/shared/components/add-communication-log";
import ContactMeModal from "@haywork/modules/shared/components/contact-me-modal";
import VoipInfoModal from "@haywork/modules/shared/components/voip-info-modal";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { DateUtil, DiffStatus, RouteUtil } from "@haywork/util";
import head from "lodash-es/head";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import ListItem from "./components/list-item";
import { TimelineContainerProps } from "./timeline.container";
import { SaveFilesToDossier } from "@haywork/modules/shared/components/dossier-v2";
import { File } from "@haywork/api/mail";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface TimelineComponentProps {
  name?: string;
  title?: string;
  timelineEvents: TimelineEvent[];
  timeLineStatistics?: TimelineEventsStatistics;
  relationTimelineStatus: string;
  emptyStateTitle: string;
  emptyStateText: string;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  parentId: string;
  mainEmptyStateTitle?: string;
  mainEmptyStateText?: string;
  canLoadMore?: boolean;
  authorizationWidgetEntityType: WidgetEntityType;
  linkedRelation?: LinkedRelation;
  linkedAssignment?: LinkedAssignment;
  addNotes: boolean;
  onTimelineActionTypeChange: (actionTypes: TimelineActionType[]) => void;
  onScrollEndOfTimeline: (activeTimelineFilter: TimelineActionType[]) => void;
  onRefresh: () => void;
}
interface State {
  availableFilters?: MappedDropdownValue[];
  selectedFilter?: TimelineActionType[];
  emailMessageModalVisible: boolean;
  voipModalVisible: boolean;
  contactMeModalVisible: boolean;
  entityId: string;
  addCommunicationLogModalVisible: boolean;
  saveToDossierVisible: boolean;
  saveToDossierLinkedRelations: LinkedRelation[];
  saveToDossierLinkedAssignments: LinkedAssignment[];
  saveToDossierFiles: File[];
  originalCommunicationLog?: CommunicationLog;
}
type Props = TimelineComponentProps & TimelineContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class TimelineComponent extends React.PureComponent<Props, State> {
  private availableFilters: MappedDropdownValue[] = [
    {
      label: "allEventsFilter",
      value: [],
    },
    { label: "meetingsFilter", value: [TimelineActionType.AgendaItem] },
    { label: "invoicesFilter", value: [TimelineActionType.Invoice] },
    { label: "tasksFilter", value: [TimelineActionType.Task] },
    { label: "changesFilter", value: [TimelineActionType.Transaction] },
    { label: "communicationLog", value: [TimelineActionType.CommunicationLog] },
    { label: "bids", value: [TimelineActionType.Bid] },
  ];

  constructor(props) {
    super(props);

    this.onFilterChangeHandler = this.onFilterChangeHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.onListItemClick = this.onListItemClick.bind(this);
    this.onAddCommunicationLogModalClose =
      this.onAddCommunicationLogModalClose.bind(this);
    this.deleteCommunicationLog = this.deleteCommunicationLog.bind(this);
    this.unDeleteCommunicationLog = this.unDeleteCommunicationLog.bind(this);
    this.onCloseSaveToDossier = this.onCloseSaveToDossier.bind(this);
    this.onSaveAttachmentsToDossier =
      this.onSaveAttachmentsToDossier.bind(this);

    this.state = {
      availableFilters: this.availableFilters,
      selectedFilter: this.availableFilters[0].value,
      emailMessageModalVisible: false,
      voipModalVisible: false,
      contactMeModalVisible: false,
      entityId: "",
      addCommunicationLogModalVisible: false,
      saveToDossierVisible: false,
      saveToDossierLinkedRelations: [],
      saveToDossierLinkedAssignments: [],
      saveToDossierFiles: [],
      originalCommunicationLog: undefined,
    };
  }

  public render() {
    let events = { today: [], future: [], past: [], other: [] };

    if (this.props.timelineEvents) {
      events = this.props.timelineEvents.reduce(
        (state, timelineEvent) => {
          if (
            timelineEvent.actionType === TimelineActionType.Task &&
            !timelineEvent.endDate
          ) {
            state.other.push(timelineEvent);
          } else {
            const dayDiff = DateUtil.diffInDays(
              new Date(),
              timelineEvent.endDate || timelineEvent.date
            );

            switch (dayDiff.status) {
              case DiffStatus.Negative:
                state.past.push(timelineEvent);
                break;
              case DiffStatus.Today:
                state.today.push(timelineEvent);
                break;
              case DiffStatus.Positive:
                state.future.push(timelineEvent);
                break;
              default:
                break;
            }
          }
          return state;
        },
        { today: [], future: [], past: [], other: [] }
      );
    }
    const timelineItemsToday: TimelineEvent[] = events.today;
    const timelineItemsInTheFuture: TimelineEvent[] = events.future;
    const timelineItemsInThePast: TimelineEvent[] = events.past;
    const timelineItemsOther: TimelineEvent[] = events.other;
    const todayCount = this.props.timeLineStatistics
      ? this.props.timeLineStatistics.todayCount
      : 0;
    const futureCount = this.props.timeLineStatistics
      ? this.props.timeLineStatistics.futureCount
      : 0;
    const pastCount = this.props.timeLineStatistics
      ? this.props.timeLineStatistics.pastCount
      : 0;
    const otherCount = this.props.timeLineStatistics
      ? this.props.timeLineStatistics.noDateCount
      : 0;

    return (
      <div styleName="timeline">
        <PageHeader
          title="pageTitle.timeline"
          subTitle={this.props.name}
          actions={
            <>
              {this.props.addNotes && <Notes />}
              <FeatureSwitch feature="VOIP">
                <Button
                  label="addCommunicationLog.trigger"
                  category="primary"
                  icon={<Icon name="plus" light size={18} />}
                  iconPosition="start"
                  onClick={() =>
                    this.setState({ addCommunicationLogModalVisible: true })
                  }
                />
              </FeatureSwitch>
            </>
          }
        />

        <div styleName="filters">
          <Filter changeFilter={(filter) => this.onFilterChangeHandler(filter)}>
            <ResourceText resourceKey="youreWatching" />
            <DropdownFilter
              name="eventFilter"
              inline
              values={this.state.availableFilters}
              value={this.state.selectedFilter}
            />
          </Filter>
        </div>

        <div styleName="body">
          {this.props.timelineEvents &&
            this.props.timelineEvents.length === 0 &&
            this.props.relationTimelineStatus !== REQUEST.PENDING && (
              <Ui.EmptyState
                type={UiEmptyStateType.List}
                stickman={UiEmptyStateStickMan.NoTimeline}
                title={
                  this.props.mainEmptyStateTitle || "emptyStateNoTimelineTitle"
                }
                subtitle={
                  this.props.mainEmptyStateText || "emptyStateNoTimelineText"
                }
                fullscreen
              />
            )}

          {this.props.relationTimelineStatus === REQUEST.PENDING &&
            (!this.props.timelineEvents ||
              this.props.timelineEvents.length === 0) && (
              <PageLoader loading fullscreen={true} />
            )}

          {this.props.timelineEvents && this.props.timelineEvents.length > 0 && (
            <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
              <>
                {timelineItemsInTheFuture.length > 0 && (
                  <div className="future">
                    <div styleName="divider">
                      <ResourceText
                        resourceKey="eventsInFuture"
                        values={{ count: futureCount }}
                      />
                    </div>
                    {this.renderBody(timelineItemsInTheFuture)}
                  </div>
                )}
                {timelineItemsToday.length > 0 && (
                  <div className="today">
                    <div styleName="divider">
                      <ResourceText
                        resourceKey="eventsToday"
                        values={{ count: todayCount }}
                      />
                    </div>
                    {this.renderBody(timelineItemsToday)}
                  </div>
                )}
                {timelineItemsInThePast.length > 0 && (
                  <div className="future">
                    <div styleName="divider">
                      <ResourceText
                        resourceKey="eventsInPast"
                        values={{ count: pastCount }}
                      />
                    </div>
                    {this.renderBody(timelineItemsInThePast)}
                  </div>
                )}
                {timelineItemsOther.length > 0 && (
                  <div className="future">
                    <div styleName="divider">
                      <ResourceText
                        resourceKey="eventsOther"
                        values={{ count: otherCount }}
                      />
                    </div>
                    {this.renderBody(timelineItemsOther)}
                  </div>
                )}
              </>
              {this.props.canLoadMore && <ListLoader />}
            </InfiniteScroll>
          )}
        </div>

        <EmailMessageBlobContainer
          modalVisible={this.state.emailMessageModalVisible}
          onModalCloseHandler={() => {
            this.setState({ emailMessageModalVisible: false });
          }}
          communicationLogId={this.state.entityId}
          onSaveAttachmentsToDossier={this.onSaveAttachmentsToDossier}
          onUpdatedCommunicationLog={this.props.onRefresh}
        />

        <ContactMeModal
          visible={this.state.contactMeModalVisible}
          messageId={this.state.entityId}
          onClose={() => this.setState({ contactMeModalVisible: false })}
        />

        <VoipInfoModal
          visible={this.state.voipModalVisible}
          communicationLogId={this.state.entityId}
          onClose={() => this.setState({ voipModalVisible: false })}
        />

        <AddCommunicationLogModal
          visible={this.state.addCommunicationLogModalVisible}
          linkedRelation={this.props.linkedRelation}
          linkedAssignment={this.props.linkedAssignment}
          onClose={this.onAddCommunicationLogModalClose}
        />

        <SaveFilesToDossier
          visible={this.state.saveToDossierVisible}
          linkedAssignments={this.state.saveToDossierLinkedAssignments}
          linkedRelations={this.state.saveToDossierLinkedRelations}
          onClose={this.onCloseSaveToDossier}
          files={this.state.saveToDossierFiles}
        />
      </div>
    );
  }

  private renderBody(timelineEvents: TimelineEvent[]): JSX.Element {
    return (
      <>
        {timelineEvents && timelineEvents.length > 0 ? (
          timelineEvents.map((timelineEvent, idx) => (
            <ErrorBoundary key={idx}>
              <ListItem
                timelineEvent={timelineEvent}
                onTimelineClick={this.onListItemClick}
                zebra={idx % 2 !== 0}
                agendaItemCategories={this.props.agendaItemCategories}
                taskCategories={this.props.taskCategories}
                parentId={this.props.parentId}
                authorizationWidgetEntityType={
                  this.props.authorizationWidgetEntityType
                }
                deleteCommunicationLog={this.deleteCommunicationLog}
                unDeleteCommunicationLog={this.unDeleteCommunicationLog}
                onRefresh={this.props.onRefresh}
              />
            </ErrorBoundary>
          ))
        ) : (
          <div styleName="emptyState">
            <div styleName="content">
              <div styleName="icon" />
              <div styleName="text">
                <h2>
                  <ResourceText resourceKey={this.props.emptyStateTitle} />
                </h2>
                <p>
                  <ResourceText resourceKey={this.props.emptyStateText} />
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  private async deleteCommunicationLog(timelineEvent: TimelineEvent) {
    const { id } = timelineEvent;
    const originalCommunicationLog = await this.props.deleteCommunicationLog(
      id,
      this.props.parentId
    );
    this.setState({ originalCommunicationLog });
  }

  private unDeleteCommunicationLog(timelineEvent: TimelineEvent) {
    const { id } = timelineEvent;
    this.props.unDeleteCommunicationLog(
      id,
      this.state.originalCommunicationLog
    );
  }

  private onFilterChangeHandler(filter: any) {
    this.setState({ selectedFilter: filter.eventFilter });
    this.props.onTimelineActionTypeChange(filter.eventFilter);
  }

  private onScrollEndHandler() {
    if (
      this.props.canLoadMore &&
      this.props.relationTimelineStatus !== REQUEST.PENDING
    ) {
      this.props.onScrollEndOfTimeline(this.state.selectedFilter);
    }
  }

  private onListItemClick(timelineEvent: TimelineEvent) {
    const { actionType, actionSubType } = timelineEvent;

    switch (actionType) {
      case TimelineActionType.AgendaItem:
        this.props.navigate(
          route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id: timelineEvent.id })
        );
        break;
      case TimelineActionType.Task:
        this.props.navigate(
          route(TASKROUTES.TASK.URI, { id: timelineEvent.id })
        );
        break;
      case TimelineActionType.Invoice:
        this.props.navigate(
          route(INVOICEROUTES.DETAIL.URI, { id: timelineEvent.id })
        );
        break;
      case TimelineActionType.SearchProfile:
        this.props.navigate(
          route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id: timelineEvent.id })
        );
        break;
      case TimelineActionType.CommunicationLog:
        switch (actionSubType) {
          case TimelineActionSubType.Email: {
            this.setState({
              emailMessageModalVisible: true,
              entityId: timelineEvent.id,
            });
            break;
          }
          case TimelineActionSubType.PhoneCall: {
            this.setState({
              voipModalVisible: true,
              entityId: timelineEvent.id,
            });
            break;
          }
          default:
            break;
        }
        break;
      case TimelineActionType.ContactMe: {
        if (!!(timelineEvent.linkedMessages || []).length) {
          const message = head(timelineEvent.linkedMessages);
          this.setState({ contactMeModalVisible: true, entityId: message.id });
        }
        break;
      }
      default:
        break;
    }
  }

  private onAddCommunicationLogModalClose(refresh: boolean) {
    this.setState({ addCommunicationLogModalVisible: false });
    if (!!refresh) this.props.onRefresh();
  }

  private onSaveAttachmentsToDossier(
    files: File[],
    linkedRelations: LinkedRelation[],
    linkedAssignments: LinkedAssignment[]
  ) {
    this.setState({
      emailMessageModalVisible: false,
      saveToDossierFiles: files,
      saveToDossierVisible: true,
      saveToDossierLinkedRelations: linkedRelations,
      saveToDossierLinkedAssignments: linkedAssignments,
    });
  }

  private onCloseSaveToDossier() {
    this.setState({
      saveToDossierVisible: false,
      saveToDossierFiles: [],
      saveToDossierLinkedAssignments: [],
      saveToDossierLinkedRelations: [],
    });
  }
}
