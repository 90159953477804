import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { StatusType, SubstatusType } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  status: StatusType | null | undefined;
  substatus: SubstatusType | null | undefined;
};

export const StatusComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ status, substatus }) => {
    const color = useMemo(() => {
      switch (status) {
        case StatusType.AVAILABLE: {
          return "success";
        }
        case StatusType.RENTED:
        case StatusType.RENTED_UNDER_CONDITIONS:
        case StatusType.SOLD:
        case StatusType.SOLD_UNDER_CONDITIONS: {
          return "warning";
        }
        case StatusType.WITHDRAWN: {
          return "danger";
        }
        default: {
          return null;
        }
      }
    }, [status]);

    if (!status) return null;

    return (
      <div styleName="status">
        <div styleName={classNames("status__main", color)}>
          <I18n prefix="statusTypeOptions" value={status.toString()} />
        </div>
        {!!substatus && (
          <div styleName="status__sub">
            <I18n prefix="subStatusTypeOptions" value={substatus.toString()} />
          </div>
        )}
      </div>
    );
  })
);
