import { Company, PersonSettings } from "@haywork/api/mail";
import { EmailMessage } from "..";
import { ActionType, ActionTypes } from "./actions";

export enum EmailRequestState {
  FetchingAccounts = "FetchingAccounts",
  FetchingFolders = "FetchingFolders",
  FetchingMessages = "FetchingMessages",
  FetchingDrafts = "FetchingDrafts",
  Idle = "Idle",
  Error = "Error",
}

export type FilterState = {
  unread?: boolean;
  bookmarked?: boolean;
  searchQuery?: string;
};

export type State = {
  currentAccount: string | null;
  currentFolder: string | null;
  currentMessage: EmailMessage | null;
  filters: FilterState;
  idsMatchingSearchQuery: string[];
  personSettings: PersonSettings | null;
  companySettings: Company | null;
  accountsAndFoldersVisible: boolean;
  requestState: EmailRequestState;
  searchSelectedMessage: string | null;
  autoRemoveAfterMessagePersists: boolean | null;
};

const INITIAL: State = {
  currentAccount: null,
  currentFolder: null,
  currentMessage: null,
  filters: {},
  idsMatchingSearchQuery: [],
  personSettings: null,
  companySettings: null,
  accountsAndFoldersVisible: true,
  requestState: EmailRequestState.Idle,
  searchSelectedMessage: null,
  autoRemoveAfterMessagePersists: null,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetInitialAccountAndFolder: {
      const { accountId, folderId } = action;
      const currentAccount = state.currentAccount || accountId;
      const currentFolder = state.currentFolder || folderId;

      return {
        ...state,
        currentAccount,
        currentFolder,
      };
    }
    case ActionType.SetCurrentAccount: {
      const { id } = action;

      return {
        ...state,
        currentAccount: id,
      };
    }
    case ActionType.SetCurrentFolder: {
      const { folder } = action;

      return {
        ...state,
        currentFolder: folder.id,
        currentAccount: folder.accountId,
      };
    }
    case ActionType.ResetFilters: {
      return {
        ...state,
        filters: {},
        idsMatchingSearchQuery: [],
        searchSelectedMessage: null,
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;
      const idsMatchingSearchQuery = !!filters.searchQuery
        ? state.idsMatchingSearchQuery
        : [];

      return {
        ...state,
        filters,
        searchSelectedMessage: !filters.searchQuery
          ? null
          : state.searchSelectedMessage,
        idsMatchingSearchQuery,
      };
    }
    case ActionType.SetIdsMatchingSearchQuery: {
      const { idsMatchingSearchQuery } = action;

      return {
        ...state,
        idsMatchingSearchQuery,
      };
    }
    case ActionType.SetPersonSettings: {
      const { personSettings } = action;

      return {
        ...state,
        personSettings,
      };
    }
    case ActionType.SetCompanySettings: {
      const { companySettings } = action;

      return {
        ...state,
        companySettings,
      };
    }
    case ActionType.ToggleAccountsAndFoldersVisibility: {
      const { accountsAndFoldersVisible } = action;

      return {
        ...state,
        accountsAndFoldersVisible,
      };
    }
    case ActionType.SetAutoRemoveAfterMessagePersists: {
      const { toggleValue } = action;
      return {
        ...state,
        autoRemoveAfterMessagePersists: toggleValue,
      };
    }
    case ActionType.SetEmailRequestState: {
      const { requestState } = action;

      return {
        ...state,
        requestState,
      };
    }
    case ActionType.SetCurrentMessage: {
      const { selectedMessage } = action;

      return !!state.filters.searchQuery
        ? { ...state, searchSelectedMessage: selectedMessage }
        : { ...state, searchSelectedMessage: null };
    }
    case ActionType.Clear: {
      return {
        ...state,
        currentAccount: null,
        currentFolder: null,
        currentMessage: null,
        filters: {},
        idsMatchingSearchQuery: [],
        accountsAndFoldersVisible: true,
        requestState: EmailRequestState.Idle,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
