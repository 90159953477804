import * as React from "react";
import { FormattedNumber } from "react-intl";
import * as CSSModules from "react-css-modules";

import { Form, Input, FormControls, FormReturnValue } from "@haywork/modules/form";
import {
  SaleOffer,
  CommissionType,
  CommissionTypeOption,
  Language,
  RentOffer,
  RentCondition,
  ObjectAssignment,
  RealEstateGroup
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("./form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface CommisionTotal {
  percentage: number;
  percentageTotal: number;
  months: number;
  fixed: number;
  total: number;
}

interface AssignmentEditFinancialCommissionComponentProps {
  commissionTypeOptions: CommissionTypeOption[];
  objectAssignment: ObjectAssignment;
  offer: SaleOffer | RentOffer;
  languages: Language[];
  defaultLanguage: Language;
  onChange: (values: FormReturnValue) => void;
}
interface AssignmentEditFinancialCommissionComponentState {
  commission: CommisionTotal;
  commissionContactType: CommissionType;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialCommissionComponent extends React.Component<
  AssignmentEditFinancialCommissionComponentProps,
  AssignmentEditFinancialCommissionComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    const { offer } = this.props;

    this.state = {
      commission: this.props.objectAssignment.forSale
        ? this.calculateSaleCommission(offer)
        : this.calculateRentCommission(offer),
      commissionContactType: value(offer, "commissionContactType", CommissionType.FixedAndPercentage)
    };

    this.formControls = {
      commissionContactType: {
        value: value(offer, "commissionContactType", CommissionType.FixedAndPercentage),
        onChange: (ref) => this.setState({ commissionContactType: ref.value })
      },
      commissionContactGross: { value: value(offer, "commissionContactGross") },
      commissionContactPercent: { value: value(offer, "commissionContactPercent") },
      commissionContactNote: { value: value(offer, "commissionContactNote") },
      commissionContactMonthsRent: { value: value(offer, "commissionContactMonthsRent") }
    };

    this.onCommissionChangeHandler = this.onCommissionChangeHandler.bind(this);
  }

  public render() {
    const { offer, commissionTypeOptions } = this.props;
    let showCommissionContactMonthsRent = false;
    if (!this.props.objectAssignment.forSale) {
      const rentOffer: RentOffer = offer;
      showCommissionContactMonthsRent = rentOffer.rentCondition !== RentCondition.PricePerContract;
    }

    const commissionContactMonthsRecourseKey =
      this.props.objectAssignment.realEstateGroup === RealEstateGroup.Agricultural
        ? "commissionContactMonthsLease"
        : "commissionContactMonthsRent";

    return (
      <Form name="commission" onChange={this.onCommissionChangeHandler} formControls={this.formControls} asSubForm>
        {/* Commission type */}
        <div className="form__row">
          <Input.RadioGroup name="commissionContactType" asButtonList>
            {commissionTypeOptions.map((commissionTypeOption, idx) => (
              <Input.Radio label={commissionTypeOption.displayName} value={commissionTypeOption.value} key={idx} />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Courtage and total */}
        <div className="form__row">
          <div className="form__group top">
            <div className="column">
              {showCommissionContactMonthsRent && (
                <div styleName="inline-value">
                  <label htmlFor="commissionContactMonthsRent">
                    <ResourceText resourceKey={commissionContactMonthsRecourseKey} />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="commissionContactMonthsRent" step={1} />
                    <div className="post full">
                      <ResourceText resourceKey="months" />
                    </div>
                  </div>
                </div>
              )}

              {this.state.commissionContactType !== CommissionType.Percentage && (
                <div styleName="inline-value">
                  <label htmlFor="commissionContactGross">
                    <ResourceText resourceKey="commissionContactGross" />
                  </label>
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number name="commissionContactGross" pretty step={100} asPrice />
                  </div>
                </div>
              )}

              {this.state.commissionContactType !== CommissionType.Fixed && (
                <div styleName="inline-value">
                  <label htmlFor="commissionContactPercent">
                    <ResourceText resourceKey="commissionContactPercent" />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="commissionContactPercent" pretty max={100} />
                    <div className="post">%</div>
                  </div>
                </div>
              )}

              <div styleName="inline-value align-top">
                <label htmlFor="commissionContactNote">
                  <ResourceText resourceKey="commissionContactNote" />
                </label>
                <div className="input__helper">
                  <Input.TranslateText
                    name="commissionContactNote"
                    languages={this.props.languages}
                    defaultLanguage={this.props.defaultLanguage}
                  />
                </div>
              </div>
            </div>
            <div className="column__spacer wide" />
            <div className="column column__right">
              <div styleName="section__total">
                <h2>
                  <ResourceText resourceKey="commissionTotal" />
                </h2>
                {showCommissionContactMonthsRent && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText resourceKey="monthsTotal" />
                    </div>
                    <div styleName="value">
                      <FormattedNumber value={this.state.commission.months} style="currency" currency="EUR" />
                    </div>
                  </div>
                )}

                {this.state.commissionContactType !== CommissionType.Percentage && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText resourceKey="fixed" />
                    </div>
                    <div styleName="value">
                      <FormattedNumber value={this.state.commission.fixed} style="currency" currency="EUR" />
                    </div>
                  </div>
                )}
                {this.state.commissionContactType !== CommissionType.Fixed && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText resourceKey="percentage" /> {this.state.commission.percentage}%
                    </div>
                    <div styleName="value">
                      <FormattedNumber value={this.state.commission.percentageTotal} style="currency" currency="EUR" />
                    </div>
                  </div>
                )}
                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="total" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber value={this.state.commission.total} style="currency" currency="EUR" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: AssignmentEditFinancialCommissionComponentProps) {
    switch (this.props.objectAssignment.forSale) {
      case true: {
        const nextOffer: SaleOffer = nextProps.offer;
        const offer: SaleOffer = this.props.offer;
        if (nextOffer.salePrice !== offer.salePrice) {
          this.setState({ commission: this.calculateSaleCommission(nextOffer) });
        }
        break;
      }
      case false: {
        const nextOffer: RentOffer = nextProps.offer;
        const offer: RentOffer = this.props.offer;
        if (nextOffer.rentPrice !== offer.rentPrice || nextOffer.rentCondition !== offer.rentCondition) {
          this.setState({ commission: this.calculateRentCommission(nextOffer) });
        }
      }
      default:
        return;
    }
  }

  private onCommissionChangeHandler(values: FormReturnValue) {
    let { offer } = this.props;
    offer = {
      ...offer,
      commissionContactGross: values.commissionContactGross,
      commissionContactPercent: values.commissionContactPercent,
      commissionContactType: values.commissionContactType,
      commissionContactMonthsRent: values.commissionContactMonthsRent
    };
    const commission = this.props.objectAssignment.forSale
      ? this.calculateSaleCommission(offer)
      : this.calculateRentCommission(offer);

    this.setState({ commission });
    this.props.onChange(values);
  }

  private calculateSaleCommission(offer: SaleOffer): CommisionTotal {
    let commission: CommisionTotal = { percentage: 0, percentageTotal: 0, fixed: 0, months: 0, total: 0 };
    const price = parseFloat(value(offer, "salePrice", 0));
    const fixed = parseFloat(value(offer, "commissionContactGross", 0));
    const percentage = parseFloat(value(offer, "commissionContactPercent", 0));
    const percentageTotal = (percentage / 100) * price;

    switch (offer.commissionContactType) {
      case CommissionType.Fixed: {
        commission = {
          ...commission,
          fixed,
          total: fixed
        };
        break;
      }
      case CommissionType.FixedAndPercentage:
      default: {
        commission = {
          ...commission,
          fixed,
          percentage,
          percentageTotal,
          total: fixed + percentageTotal
        };
        break;
      }
      case CommissionType.Percentage: {
        commission = {
          ...commission,
          percentage,
          percentageTotal,
          total: percentageTotal
        };
        break;
      }
    }

    return commission;
  }

  private calculateRentCommission(offer: RentOffer): CommisionTotal {
    let commission: CommisionTotal = { percentage: 0, percentageTotal: 0, fixed: 0, months: 0, total: 0 };
    const price = parseFloat(value(offer, "rentPrice", 0));
    const percentage = parseFloat(value(offer, "commissionContactPercent", 0));
    const months = parseFloat(value(offer, "commissionContactMonthsRent", 0)) * this.priceToMonth(price, offer);
    const percentageTotal = (percentage / 100) * price;
    const fixed = parseFloat(value(offer, "commissionContactGross", 0));

    switch (offer.commissionContactType) {
      case CommissionType.Fixed: {
        commission = {
          ...commission,
          fixed,
          months,
          total: fixed + months
        };
        break;
      }
      case CommissionType.FixedAndPercentage:
      default: {
        commission = {
          ...commission,
          fixed,
          months,
          percentage,
          percentageTotal,
          total: fixed + percentageTotal + months
        };
        break;
      }
      case CommissionType.Percentage: {
        commission = {
          ...commission,
          months,
          percentage,
          percentageTotal,
          total: percentageTotal + months
        };
        break;
      }
    }

    return commission;
  }

  private priceToMonth(price: number, offer: RentOffer): number {
    if (this.props.objectAssignment.forSale) return 0;

    switch (offer.rentCondition) {
      case RentCondition.PricePerDay:
        return price * 31;
      case RentCondition.PricePerWeek:
        return price * 4;
      case RentCondition.PricePerMonth:
        return price;
      case RentCondition.PricePerQuarter:
        return price / 3;
      case RentCondition.PricePerHalfYear:
        return price / 6;
      case RentCondition.PricePerYear:
        return price / 12;
      default:
        return 0;
    }
  }
}
