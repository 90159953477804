export const SCHEDULER_CATEGORIES = {
  APPEND_SCHEDULER_CATEGORIES: "APPEND_SCHEDULER_CATEGORIES",
  SET_SCHEDULER_CATEGORIES: "SET_SCHEDULER_CATEGORIES",
  SET_SCHEDULER_CATEGORIES_STATISTICS: "SET_SCHEDULER_CATEGORIES_STATISTICS",
  SET_SCHEDULER_CATEGORY: "SET_SCHEDULER_CATEGORY",
  NEW_SCHEDULER_CATEGORY: "NEW_SCHEDULER_CATEGORY",
  DELETE_SCHEDULER_CATEGORY: "DELETE_SCHEDULER_CATEGORY",
  UNDELETE_SCHEDULER_CATEGORY: "UNDELETE_SCHEDULER_CATEGORY",
  DELETE_FROM_STATE: "SCHEDULER_CATEGORY_DELETE_FROM_STATE",
  SET_SCHEDULER_CATEGORIES_STATUS: "SET_SCHEDULER_CATEGORIES_STATUS",
  SET_SCHEDULER_CATEGORY_STATUS: "SET_SCHEDULER_CATEGORY_STATUS",
  SET_SCHEDULER_CATEGORY_ITEM: "SET_SCHEDULER_CATEGORY_ITEM"
};
