import { combineReducers } from "redux";

import { errorsReducer, FormErrorsState, FormErrorsActions, FormErrors, FormError } from "./errors";

const formReducer = combineReducers({
  errors: errorsReducer
});

interface FormState {
  errors: FormErrorsState;
}

export {
  FormErrorsActions,
  formReducer,
  FormState,
  FormError,
  FormErrors
};
