import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AssignmentPhase,
  AssignmentSnapShot,
  AssignmentType,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import FormattedText from "@haywork/components/ui/formatted-text";
import PageHeader from "@haywork/components/ui/page-header";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import Notes, { PinnedNotes } from "@haywork/modules/notes-v3";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import head from "lodash-es/head";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import Actions, {
  AcquisitionAssignmentAction,
} from "../../components/acquisition-actions";
import Info from "../../components/acquisition-info";
import AssignmentsInNegotiationOrWon from "../../components/assignments-in-negotiation";
import Sidebar from "../../components/detail-sidebar";
import LinkedSearchAssignments from "../../components/linked-search-assignments";
import NetworkWidget from "../../components/network-widget";
import { AcquisitionDetailGeneralScreenContainerProps } from "./general.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquisitionDetailGeneralScreenProps = {};
type Props = AcquisitionDetailGeneralScreenProps &
  AcquisitionDetailGeneralScreenContainerProps;

export const AcquisitionDetailGeneralScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisition,
      navigate,
      getAcquisitionObjectsInNegotiationOrWon,
      canSendEmail,
      createAgendaItemWithLinkedEntities,
      createInvoiceWithLinkedEntities,
      createNewEmail,
      createTaskWithLinkedEntities,
      archiveAcquisition,
      unArchiveAcquisition,
      deleteAcquisition,
      createNew,
      getInitializedWidgets,
      setAcquisitionStatus,
    }) => {
      const {
        assignmentPhase,
        isCancelled,
        linkedEmployee,
        linkedClients,
        linkedVendors,
        linkedApplicants,
        linkedNotaries,
        linkedPropertyManagers,
        linkedAppraisers,
        linkedFinancialAdvisors,
        linkedInspectors,
        linkedContactPersons,
        linkedPurchasingBrokers,
        linkedSearchAssignment,
        id,
        isActive,
        isNew,
        publicReference,
        forRent,
        forSale,
      } = acquisition;

      const intl = useIntl();

      const [assignmentsInNegotiationOrWon, setAssignmentsInNegotiationOrWon] =
        useState<AssignmentSnapShot[]>([]);

      const status = useMemo(() => {
        let color = Colors.TextBlack;

        switch (true) {
          case !isActive: {
            return (
              <FormattedText
                type="header"
                value="archived"
                color={Colors.Gray}
                bold
              />
            );
          }
          case isCancelled: {
            return (
              <FormattedText
                type="header"
                value="acquisition.isCancelled"
                color={Colors.Danger}
                bold
              />
            );
          }
          case assignmentPhase === AssignmentPhase.Concept: {
            color = Colors.Gray;
            break;
          }
          case assignmentPhase === AssignmentPhase.Completed: {
            color = Colors.Primary;
            break;
          }
          case assignmentPhase === AssignmentPhase.Initiated: {
            color = Colors.Success;
            break;
          }
          default: {
            break;
          }
        }

        return (
          <FormattedText
            type="header"
            prefix="assignmentPhases"
            value={acquisition.assignmentPhase.toString()}
            color={color}
            bold
          />
        );
      }, [assignmentPhase, isCancelled, isActive]);

      const networkPath = useMemo(() => {
        const { id } = acquisition;
        return route(ACQUISITIONROUTES.NETWORK.URI, { id });
      }, [acquisition]);

      const assignmentPath = useMemo(() => {
        const { id } = acquisition;
        return route(ACQUISITIONROUTES.ACQUISITION_ASSIGNMENTS.URI, { id });
      }, [acquisition]);

      const editPath = useMemo(() => {
        const { id } = acquisition;
        return route(ACQUISITIONROUTES.EDIT_DETAILS.URI, { id });
      }, [acquisition]);

      const getAssignmentsInNegotiationOrWon = useCallback(async () => {
        const assignments = await getAcquisitionObjectsInNegotiationOrWon(id);
        setAssignmentsInNegotiationOrWon(assignments);
      }, [setAssignmentsInNegotiationOrWon, id]);

      const onActionsClick = useCallback(
        (action: AcquisitionAssignmentAction) => {
          const { id, title, publicReference, linkedClients } = acquisition;
          const linkedAssignment: LinkedAssignment = {
            id,
            typeOfAssignment: AssignmentType.Acquisition,
            displayName:
              title ||
              publicReference ||
              intl.formatMessage({
                id: "acquisition.assignment.emptyTabTitle",
                defaultMessage: "Acquisition assignment",
              }),
          };

          switch (action) {
            case AcquisitionAssignmentAction.Archive: {
              archiveAcquisition(id);
              return;
            }
            case AcquisitionAssignmentAction.CreateAppointment: {
              createAgendaItemWithLinkedEntities(
                linkedClients || [],
                linkedAssignment
              );
              return;
            }
            case AcquisitionAssignmentAction.CreateEmail: {
              createNewEmail(linkedClients || [], linkedAssignment, "");
              return;
            }
            case AcquisitionAssignmentAction.CreateInvoice: {
              createInvoiceWithLinkedEntities(
                head(linkedClients || []),
                linkedAssignment
              );
              return;
            }
            case AcquisitionAssignmentAction.CreateObject: {
              createNew(acquisition);
              return;
            }
            case AcquisitionAssignmentAction.CreateTask: {
              createTaskWithLinkedEntities(
                linkedClients || [],
                linkedAssignment
              );
              return;
            }
            case AcquisitionAssignmentAction.Delete: {
              deleteAcquisition(id);
              return;
            }
            case AcquisitionAssignmentAction.UnArchive: {
              unArchiveAcquisition(id);
              return;
            }
            case AcquisitionAssignmentAction.SetAssignmentPhaseCompleted: {
              const updatedAcquisition = {
                ...acquisition,
                ...{ assignmentPhase: AssignmentPhase.Completed },
              };
              setAcquisitionStatus(updatedAcquisition, false);
              return;
            }
            default: {
              return;
            }
          }
        },
        []
      );

      useEffect(() => {
        getAssignmentsInNegotiationOrWon();
      }, []);

      useEffect(() => {
        getInitializedWidgets(id, WidgetEntityType.AcquisitionAssignment);
      }, []);

      const networkTitle = useMemo(() => {
        switch (true) {
          case !!forRent && !!forSale:
          default: {
            return "acquisition.networkWidget.linkedRelations.forSaleAndRent";
          }
          case !!forRent: {
            return "acquisition.networkWidget.linkedRelations.forRent";
          }
          case !!forSale: {
            return "acquisition.networkWidget.linkedRelations.forSale";
          }
        }
      }, [forRent, forSale]);

      return (
        <div styleName="general">
          <div styleName="sidebar">
            <Sidebar />
          </div>
          <div styleName="body">
            <PageHeader
              title="acquisition.detailGeneral.title"
              subTitle={
                isActive ? "acquisition.detailGeneral.subTitle" : "archived"
              }
              actions={
                <>
                  {!isNew && <Notes />}
                  <Button
                    label="acquisition.detailGeneral.edit"
                    category="success"
                    onClick={() => navigate(editPath)}
                  />
                  <Actions
                    acquisitionAssignment={acquisition}
                    canSendEmail={canSendEmail}
                    onClick={onActionsClick}
                  />
                </>
              }
            />
            <div styleName="info">
              <div styleName="info__data">
                <div styleName="section">
                  {!!publicReference && (
                    <div styleName="pubref">{publicReference}</div>
                  )}
                  {!!acquisition && <Info acquisition={acquisition} />}
                </div>

                <PinnedNotes />

                <div styleName="section">
                  <FormattedText
                    value="acquisition.sectionTitle.status"
                    asSection
                  />
                  {status}
                </div>

                {!!assignmentsInNegotiationOrWon.length && (
                  <AssignmentsInNegotiationOrWon
                    assignments={assignmentsInNegotiationOrWon}
                    onGotoAssignments={() => navigate(assignmentPath)}
                    onNavigate={navigate}
                  />
                )}

                <LinkedSearchAssignments
                  linkedSearchAssignments={linkedSearchAssignment || []}
                />
              </div>

              <div styleName="info__sidebar">
                <NetworkWidget
                  linkedEmployee={linkedEmployee}
                  linkedRelations={linkedClients || []}
                  linkedApplicants={linkedApplicants || []}
                  linkedNotaries={linkedNotaries || []}
                  linkedPropertyManagers={linkedPropertyManagers || []}
                  linkedAppraisers={linkedAppraisers || []}
                  linkedFinancialAdvisors={linkedFinancialAdvisors || []}
                  linkedInspectors={linkedInspectors || []}
                  linkedContactPersons={linkedContactPersons || []}
                  linkedPurchasingBrokers={linkedPurchasingBrokers || []}
                  onGotoNetwork={() => navigate(networkPath)}
                  isAcquisitionAssignment={true}
                  isForSale={forSale}
                  onNavigate={navigate}
                />

                {/* Authorization widgets */}
                <FeatureSwitch feature="APP_XCHANGE">
                  <AuthorizationWidgets
                    type={WidgetEntityType.AcquisitionAssignment}
                    location={WidgetEntityLocation.Sidebar}
                    id={id}
                  />
                </FeatureSwitch>
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);
