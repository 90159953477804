import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SortEnd } from "react-sortable-hoc";
import { AssignmentEditMarketingFloorplansContainerProps } from "@haywork/modules/assignment";
import { PhotosComponent } from "@haywork/modules/shared";

import { arrayMoveImmutable } from "array-move";
const styles = require("./marketing-floorplans.component.scss");

export interface AssignmentEditMarketingFloorplansComponentProps {}
interface AssignmentEditMarketingFloorplansComponentState {}
@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingFloorplansComponent extends React.Component<
  AssignmentEditMarketingFloorplansComponentProps &
    AssignmentEditMarketingFloorplansContainerProps,
  AssignmentEditMarketingFloorplansComponentState
> {
  constructor(props) {
    super(props);

    this.filesDeletedHandler = this.filesDeletedHandler.bind(this);
    this.sortedHandler = this.sortedHandler.bind(this);
    this.filesEditedHandler = this.filesEditedHandler.bind(this);
    this.uploadCompleteHandler = this.uploadCompleteHandler.bind(this);
  }

  public render() {
    let { maps } = this.props.objectAssignment;
    maps = maps || [];

    return (
      <div>
        <PhotosComponent
          photos={maps}
          path={this.props.path}
          onUploadCompleteHandler={this.uploadCompleteHandler}
          fileDeleted={this.filesDeletedHandler}
          sorted={this.sortedHandler}
          fileEdited={this.filesEditedHandler}
          showMainPhotoLabel={false}
        />
      </div>
    );
  }

  private uploadCompleteHandler(files: any[]) {
    const { objectAssignment, currentComponentState } = this.props;
    const photoArray = objectAssignment.maps || [];
    const maps = files.filter((f) => !!f);
    const updatedObjectAssignment = {
      ...objectAssignment,
      maps: [...photoArray, ...maps],
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private filesDeletedHandler(idx: number) {
    const { objectAssignment, currentComponentState } = this.props;
    const maps = objectAssignment.maps.filter((p, k) => k !== idx);
    const updatedObjectAssignment = {
      ...objectAssignment,
      maps,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private sortedHandler(sort: SortEnd) {
    const { objectAssignment, currentComponentState } = this.props;
    const maps = arrayMoveImmutable(
      this.props.objectAssignment.maps,
      sort.oldIndex,
      sort.newIndex
    );
    const updatedObjectAssignment = {
      ...objectAssignment,
      maps,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private filesEditedHandler(maps: any[]) {
    const { objectAssignment, currentComponentState } = this.props;

    const updatedObjectAssignment = {
      ...objectAssignment,
      maps,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
    this.setState({ showFileEditModal: false });
  }
}
