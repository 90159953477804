import { ScrollPositionState } from "@haywork/stores";
import * as React from "react";
import { FC, useEffect, useRef } from "react";
import * as CSSModules from "react-css-modules";
import { ScrollerWrapperContainerProps } from "./scroller-wrapper.container";

const styles = require("./scroller-wrapper.scss");

type Props = {
  children: React.ReactNode;
  scrollKey: string;
} & ScrollerWrapperContainerProps;

export interface ScrollerWrapperComponentProps {
  scrollPositions?: ScrollPositionState;
}

const ScrollerWrapperComponent: FC<ScrollerWrapperComponentProps & Props> =
  CSSModules(styles, {
    allowMultiple: true,
  })((props) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const innerContainerRef = useRef<HTMLDivElement | null>(null);
    const [timeoutRef, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(
      null
    );

    useEffect(() => {
      const scrollPosition = props.scrollPositions[props.scrollKey] || 0;
      if (scrollPosition) {
        scrollContainerRef?.current?.scrollTo(0, scrollPosition);
      }
    }, [props.children]);

    const scrollHandler = (e: any) => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
      const scrollPosition = e.target.scrollTop;
      const newTimeoutRef = setTimeout(() => {
        props.setDynamicScrollPosition(props.scrollKey, scrollPosition);
      }, 300);
      setTimeoutRef(newTimeoutRef);
    };

    return (
      <div
        ref={scrollContainerRef}
        onScroll={scrollHandler}
        styleName="scroll__wrapper"
        id="scroll-container"
      >
        <div ref={innerContainerRef}>{props.children}</div>
      </div>
    );
  });

export { ScrollerWrapperComponent };
