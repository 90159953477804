import * as React from "react";
import * as CSSModules from "react-css-modules";
import { InitializedWidget, WidgetSnapShot } from "@haywork/api/authorization";
import head from "lodash-es/head";
import { DropdownNavigation, DropdownAlignment } from "@haywork/modules/shared";

const styles = require("./style.scss");

interface Props {
  widgets: WidgetSnapShot[];
  initializedWidgets: InitializedWidget[];
  showModal: (
    widgetModalWidget: WidgetSnapShot,
    widgetModalInitializedWidget: InitializedWidget
  ) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsTimelineComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.handlePartnerClick = this.handlePartnerClick.bind(this);
  }

  public render() {
    const { widgets, initializedWidgets } = this.props;
    if (!widgets.length) return null;
    const disabled = !initializedWidgets.length;

    if (widgets.length === 1) {
      const widget = head(widgets);
      return (
        <div styleName="widgets">
          <button
            className="btn btn-white squeeze"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              this.handlePartnerClick(widget);
            }}
          >
            {widget.displayName}
          </button>
        </div>
      );
    }

    return (
      <div styleName="widgets" onClick={(e) => e.stopPropagation()}>
        <DropdownNavigation
          buttonLabel="authorizationWidget.SelectTimelineAction"
          alignment={DropdownAlignment.BottomRight}
          disabled={disabled}
          buttonClass="btn-white icon-right squeeze"
        >
          <ul className="dropdown__list">
            {widgets.map((widget) => (
              <li
                className="dropdown__listitem"
                key={widget.id}
                onClick={() => this.handlePartnerClick(widget)}
              >
                <div className="faux-link">{widget.displayName}</div>
              </li>
            ))}
          </ul>
        </DropdownNavigation>
      </div>
    );
  }

  private handlePartnerClick(widget: WidgetSnapShot) {
    const initializedWidget = this.props.initializedWidgets.find(
      (initializedWidget) => initializedWidget.id === widget.id
    );
    if (!!initializedWidget && !!initializedWidget.signedCallbackUri) {
      this.props.showModal(widget, initializedWidget);
    }
  }
}
