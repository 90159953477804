import {
  Priority,
  TimelineActionType,
  TimelineEvent,
} from "@haywork/api/kolibri";
import {
  INVOICEROUTES,
  SCHEDULERROUTES,
  SEARCHASSIGNMENTROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const route = RouteUtil.mapStaticRouteValues;

interface Props {
  timelineEvent: TimelineEvent;
  onClick: (path: string) => void;
}
interface State {}

const styles = require("./style.scss");

@CSSModules(styles, { allowMultiple: true })
export class WidgetListItemComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    if (!this.props.timelineEvent) return null;

    const eventStyle = classNames("timelineEvent", {
      "is-clickable":
        [
          TimelineActionType.AgendaItem,
          TimelineActionType.Invoice,
          TimelineActionType.Task,
          TimelineActionType.SearchProfile,
        ].indexOf(this.props.timelineEvent.actionType) !== -1 &&
        !this.props.timelineEvent.accessDeniedForCurrentUser,
    });

    return !this.props.timelineEvent ? null : (
      <li onClick={this.onClickHandler}>
        <div styleName={eventStyle}>
          <div styleName="icon">
            <i
              className={
                "fal fa-fw " +
                this.getFaIconForType(this.props.timelineEvent.actionType)
              }
            />
            {this.props.timelineEvent.actionType ===
              TimelineActionType.Task && (
              <div>
                {this.props.timelineEvent.priority === Priority.High && (
                  <span styleName="item__important">
                    <i className="fa fa-fw fa-exclamation" aria-hidden="true" />
                  </span>
                )}
              </div>
            )}
          </div>
          <div>
            {this.props.timelineEvent.actionType ===
              TimelineActionType.Invoice && (
              <div>
                {!!this.props.timelineEvent.invoiceNumber ? (
                  <ResourceText
                    resourceKey="invoiceNumber"
                    values={{
                      invoiceNumber: this.props.timelineEvent.invoiceNumber,
                    }}
                  />
                ) : (
                  <ResourceText resourceKey="concept" />
                )}
                {this.props.timelineEvent.subject ? (
                  <ResourceText
                    resourceKey="dashInvoiceSubject"
                    values={{
                      invoiceSubject: this.props.timelineEvent.subject,
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            {this.props.timelineEvent.actionType !==
              TimelineActionType.Invoice && (
              <div>
                <b>{this.props.timelineEvent.subject}</b>
              </div>
            )}
            <div styleName="date">
              {this.props.timelineEvent.endDate
                ? this.renderEndDate(this.props.timelineEvent.endDate)
                : this.renderEndDate(this.props.timelineEvent.date)}
            </div>
          </div>
        </div>
      </li>
    );
  }

  private renderEndDate(endDate: Date): JSX.Element {
    return (
      <span styleName="date">
        {endDate ? (
          <FormattedDate
            value={endDate}
            day="numeric"
            month="long"
            year="numeric"
          />
        ) : (
          <ResourceText resourceKey="taskListItemNoDate" />
        )}
      </span>
    );
  }

  private getFaIconForType(type: TimelineActionType) {
    switch (type) {
      case TimelineActionType.AgendaItem:
        return "fa-calendar-alt";
      case TimelineActionType.Invoice:
        return "fa-euro-sign";
      case TimelineActionType.Task:
        return "fa-tasks";
      case TimelineActionType.Unknown:
        return "fa-file-o";
      case TimelineActionType.Email:
        return "fa-comment-alt";
      case TimelineActionType.Website:
        return "fa-globe";
      case TimelineActionType.Transaction:
        return "fa-handshake";
      case TimelineActionType.SearchProfile:
        return "fa-search";
      case TimelineActionType.CommunicationLog:
        return "fa-envelope";
      default:
        return "fa-question-circle-o";
    }
  }

  private onClickHandler() {
    if (this.props.timelineEvent.accessDeniedForCurrentUser) return;

    switch (this.props.timelineEvent.actionType) {
      case TimelineActionType.AgendaItem:
        this.props.onClick(
          route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
            id: this.props.timelineEvent.id,
          })
        );
        break;
      case TimelineActionType.Task:
        this.props.onClick(
          route(TASKROUTES.TASK.URI, { id: this.props.timelineEvent.id })
        );
        break;
      case TimelineActionType.Invoice:
        this.props.onClick(
          route(INVOICEROUTES.DETAIL.URI, { id: this.props.timelineEvent.id })
        );
        break;
      case TimelineActionType.SearchProfile:
        this.props.onClick(
          route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
            id: this.props.timelineEvent.id,
          })
        );
        break;
      default:
        break;
    }
  }
}
