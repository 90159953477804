import * as React from "react";
import {
  FC,
  memo,
  useState,
  useCallback,
  useContext,
  useRef,
  MouseEvent,
  useMemo,
} from "react";
import * as CSSModules from "react-css-modules";
import { EmailFolder } from "@haywork/stores/email-v2";
import Folder from "../folder";
import { Account, SyncStatus } from "@haywork/api/mail";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";
import { EmailContext } from "../../email.context";
import { useDrop } from "react-dnd";
import { DragDropType } from "@haywork/enum/drag-drop";
import classNames from "classnames";
import { AccountContainerProps } from "./account.container";
import { ConfirmComponent } from "@haywork/modules/shared";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

export type AccountWithFolders = {
  account: Account;
  folders: EmailFolder[];
};
export type AccountComponentProps = {
  item: AccountWithFolders;
  moveFolder: (id: string, newParentId: string) => Promise<void>;
};
type Props = AccountComponentProps & AccountContainerProps;

export const AccountComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, moveFolder, removeFolder }) => {
      const { account, folders } = item;
      const [expanded, setExpanded] = useState(true);
      const { onAddFolder } = useContext(EmailContext);
      const forceVisibleTimeout = useRef<any>(null);
      const [droppable, setDroppable] = useState(false);
      const [folderDeleteVisible, setFolderDeleteVisible] = useState(false);
      const [folderToDelete, setFolderToDelete] = useState({
        id: "",
        displayName: "",
      });

      const [_, drop] = useDrop({
        accept: [DragDropType.EmailFolder, DragDropType.Email],
        canDrop: (item) => {
          switch (item.type) {
            case DragDropType.EmailFolder: {
              const { accountId: refAccountId } = item as any;
              return !!refAccountId && refAccountId === account.id;
            }
            default: {
              return false;
            }
          }
        },
        drop: (item) => {
          switch (item.type) {
            case DragDropType.EmailFolder: {
              const { id: refId } = item as any;
              moveFolder(refId, "");
              return;
            }
            default: {
              return;
            }
          }
        },
        collect: (monitor) => {
          if (monitor.isOver()) {
            forceVisibleTimeout.current = setTimeout(() => {
              setExpanded(true);
            }, 500);
          } else {
            if (!!forceVisibleTimeout.current) {
              clearTimeout(forceVisibleTimeout.current);
              forceVisibleTimeout.current = null;
            }
          }

          if (monitor.canDrop() && monitor.isOver()) {
            setDroppable(true);
          } else {
            setDroppable(false);
          }
        },
      });

      const toggleExpanded = useCallback(() => {
        setExpanded(!expanded);
      }, [expanded, setExpanded]);

      const addFolderCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          onAddFolder(null, account.id);
        },
        [account, onAddFolder]
      );

      const onDeleteCallback = useCallback(
        (id: string, displayName: string) => {
          setFolderToDelete({ id, displayName });
          setFolderDeleteVisible(true);
        },
        [setFolderDeleteVisible, setFolderToDelete]
      );

      const folderDeleteCloseCallback = useCallback(() => {
        setFolderToDelete({ id: "", displayName: "" });
        setFolderDeleteVisible(false);
      }, [setFolderDeleteVisible, setFolderToDelete]);

      const folderDeleteConfirmCallback = useCallback(() => {
        removeFolder(folderToDelete.id);
        folderDeleteCloseCallback();
      }, [removeFolder, folderToDelete, folderDeleteCloseCallback]);

      const hasNotableSyncStatus = useMemo(
        () =>
          [
            SyncStatus.Connected,
            SyncStatus.Error,
            SyncStatus.InvalidCredentials,
            SyncStatus.Stopped,
          ].includes(account.syncStatus),
        [account.syncStatus]
      );

      const icon = useMemo(() => {
        switch (account.syncStatus) {
          case SyncStatus.Connected: {
            return "sync";
          }
          case SyncStatus.Error: {
            return "times";
          }
          case SyncStatus.InvalidCredentials:
          case SyncStatus.Stopped: {
            return "exclamation-triangle";
          }
          default: {
            return null;
          }
        }
      }, [account.syncStatus]);

      return (
        <div>
          <div
            styleName={classNames("account", { droppable })}
            ref={drop}
            onClick={toggleExpanded}
          >
            <div styleName="expand">
              <Icon
                name={expanded ? "caret-down" : "caret-right"}
                size={14}
                containIn={16}
                color={droppable ? Colors.White : Colors.Gray}
              />
            </div>
            <div
              styleName={classNames(
                "label",
                account.syncStatus.toString().toLowerCase(),
                { expanded }
              )}
            >
              <div styleName="label__inner">
                {account.accountName || account.displayName}
              </div>
              {!expanded && !!icon && (
                <div styleName="label__icon">
                  <Icon
                    name={icon}
                    size={12}
                    containIn={16}
                    color={Colors.White}
                    regular
                  />
                </div>
              )}
            </div>
            <div styleName="add" onClick={addFolderCallback}>
              <Icon
                name="plus-circle"
                size={14}
                containIn={16}
                color={droppable ? Colors.White : Colors.Gray}
              />
            </div>
          </div>
          {!!expanded && (
            <div>
              {!!hasNotableSyncStatus && (
                <div
                  styleName={classNames(
                    "sync-status",
                    account.syncStatus.toString().toLowerCase()
                  )}
                >
                  {!!icon && (
                    <div styleName="sync-status__icon">
                      <Icon
                        name={icon}
                        size={12}
                        containIn={16}
                        color={Colors.White}
                        regular
                      />
                    </div>
                  )}
                  <div styleName="sync-status__label">
                    <I18n
                      value={`syncStatus.${account.syncStatus.toString()}`}
                    />
                  </div>
                </div>
              )}
              {folders.map((folder) => (
                <Folder
                  folder={folder}
                  key={folder.id}
                  parentExpanded={false}
                  onDelete={onDeleteCallback}
                />
              ))}
            </div>
          )}

          <ConfirmComponent
            visible={folderDeleteVisible}
            titleResourceKey="email.confirmFolderRemove.title"
            bodyResourceKey="email.confirmFolderRemove.body"
            bodyValues={{ name: folderToDelete.displayName }}
            onClose={folderDeleteCloseCallback}
            onConfirm={folderDeleteConfirmCallback}
          />
        </div>
      );
    }
  )
);
