import { connect, MapStateToProps } from "react-redux";
import {
  ConfirmOverwriteComponent,
  ConfirmOverwriteComponentProps
} from "./confirm-overwrite";
import { AppState, EditableChangedEntity } from "@haywork/stores";
import { createSelector } from "reselect";
import { RootEntityType } from "@haywork/api/event-center";
import { MapDispatchToProps } from "@haywork/middleware";

const editablesSelector = (state: AppState) => state.editable.states || [];
const externalChangesData = createSelector(
  editablesSelector,
  (_: any, id: string, type: RootEntityType) => ({ id, type }),
  (editables, { id: entityId, type: entityType }) => {
    if (!entityId || !entityType || !editables || !editables.length)
      return null;
    const ref = editables.find(
      (editable) =>
        editable.entityId === entityId &&
        editable.entityType === entityType &&
        !!editable.hasNewerExternalChanges
    );
    return !ref || !ref.externalChangesData ? null : ref.externalChangesData;
  }
);

type StateProps = {
  externalChangesData: {
    dateTimeModified: Date;
    modifiedBy: string;
    updatedEntity: EditableChangedEntity;
  };
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  ConfirmOverwriteComponentProps,
  AppState
> = (state, ownProps) => ({
  externalChangesData: externalChangesData(
    state,
    ownProps.entityId,
    ownProps.entityType
  )
});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ConfirmOverwriteComponentProps
> = (dispatch) => ({});

export type ConfirmOverwriteContainerProps = StateProps & DispatchProps;
export const ConfirmOverwriteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOverwriteComponent);
