import { AssignmentPhase, ObjectAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditComponent,
  AssignmentEditComponentProps,
} from "@haywork/modules/assignment";
import { AppState, EditableItem, LayoutActions } from "@haywork/stores";
import {
  AssignmentSingleActions,
  SingleAssignmentState,
} from "@haywork/stores/assignment/single";
import { AssignmentUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import get from "lodash-es/get";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect, MapStateToProps } from "react-redux";
import { hasChanges } from "./selectors";

interface StateProps {
  assignment: ObjectAssignment;
  fullscreen: boolean;
  canSave: boolean;
  canPublish: boolean;
  saveAssignmentState: string;
  lastMarketingRoute: string;
  hasChanges: boolean;
}
interface DispatchProps {
  toggleFullscreen: () => void;
  saveAssignment: (close: boolean) => void;
  publish: (id: string) => void;
  toggleSaveModal: (status: boolean) => void;
  saveInitialAssignment: () => void;
  backToDashboard: () => void;
  reloadAssignment: (editable: EditableItem) => Promise<void>;
}

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditComponentProps,
  AppState
> = (state) => {
  const assignment: SingleAssignmentState =
    state.editable.currentComponentState;
  const assignmentPhase = get(
    assignment?.objectAssignment,
    "assignmentPhase",
    null
  );
  const isNew = get(assignment?.objectAssignment, "isNew", false);
  const { saveAssignmentState } = state.assignment.single;

  return {
    assignment: assignment?.objectAssignment,
    fullscreen: state.layout.fullscreen,
    canSave: AssignmentUtil.validForSave(assignment?.objectAssignment),
    canPublish: !isNew && assignmentPhase === AssignmentPhase.Concept,
    saveAssignmentState,
    lastMarketingRoute: assignment?.lastMarketingRoute,
    hasChanges: hasChanges(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditComponentProps
> = (dispatch) => ({
  toggleFullscreen: () => dispatch(LayoutActions.toggleFullscreen()),
  saveAssignment: (close: boolean) =>
    dispatch(AssignmentThunks.saveAssignment(undefined, close)),
  saveInitialAssignment: () =>
    dispatch(AssignmentThunks.saveAssignment(undefined, false, true)),
  publish: (id: string) =>
    dispatch(push(route(ASSIGNMENTROUTES.PUBLISH.URI, { id }))),
  toggleSaveModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleSaveModal(status)),
  backToDashboard: () => dispatch(AssignmentThunks.backToDashboard()),
  reloadAssignment: (editable: EditableItem) =>
    dispatch(AssignmentThunks.reloadAssignment(editable)),
});

export type AssignmentEditContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const AssignmentEditContainer = injectIntl(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AssignmentEditComponent)
);
