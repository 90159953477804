import PageHeader from "@haywork/components/ui/page-header";
import { EXTERNALROUTES, SETTINGSROUTES } from "@haywork/constants/routes";
import { OverviewContainerProps } from "@haywork/modules/settings";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import I18n from "@haywork/components/i18n";
import { FC, useEffect, useState } from "react";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const styles = require("./overview.component.scss");

export interface SettingsOverviewComponentProps {}
type Props = SettingsOverviewComponentProps & OverviewContainerProps;

declare let window: ExtendedWindow;

export const SettingsOverviewComponent: FC<Props> = CSSModules(styles, {
  allowMultiple: true,
})(
  ({
    employee,
    amountOfEmployees,
    amountOfOffices,
    getAmountOfEmployees,
    getAmountOfOffices,
  }) => {
    const [avatarStyles, setAvatarStyles] = useState(null);

    useEffect(() => {
      getAmountOfOffices();
      getAmountOfEmployees();
    }, []);

    useEffect(() => {
      const avatarStyle = employee.passportPhotoBlob
        ? {
            backgroundImage: `url(${JSON.stringify(
              employee.passportPhotoBlob.urlPreview
            )})`,
          }
        : null;
      setAvatarStyles(avatarStyle);
    }, [employee]);

    return (
      <div styleName="overviewBody">
        <PageHeader title="settingsMenuOverview" />
        <div styleName="body">
          <div styleName="section mainSection">
            <div styleName="sectionOption">
              <div styleName="meta">
                <div styleName="userInfo">
                  {employee.passportPhotoBlob && avatarStyles ? (
                    <div styleName="avatar" style={avatarStyles} />
                  ) : (
                    <div styleName="avatar">
                      <div styleName="letterAvatar">
                        {employee.letterAvatar}
                      </div>
                    </div>
                  )}
                  <div styleName="meta">
                    <div styleName="name">{employee.displayName}</div>
                    {employee.phoneNumbers &&
                      employee.phoneNumbers.length > 0 && (
                        <a href={"tel:" + employee.phoneNumbers[0].number}>
                          {employee.phoneNumbers[0].number}
                        </a>
                      )}
                    {employee.emailAddresses &&
                      employee.emailAddresses.length > 0 && (
                        <MailTo email={employee.emailAddresses[0].address} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div styleName="section">
            <h2 styleName="title">
              <I18n value="settingsAcountYourOffice" />
            </h2>
            <div styleName="sectionOption">
              <div styleName="meta">
                <p>
                  <ResourceText
                    resourceKey="settingsUsingKolibri"
                    values={{
                      amountOfOffices,
                      amountOfEmployees,
                    }}
                  />
                </p>
                <div styleName="actions">
                  <Link to={SETTINGSROUTES.BRANCHES.URI}>
                    <I18n value="allOffices" />
                  </Link>
                  <Link to={SETTINGSROUTES.USERS.URI}>
                    <I18n value="allEmployees" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div styleName="section">
            <h2 styleName="title">
              <I18n value="settingsForAllYourQuestionsHeader" />
            </h2>
            <div styleName="sectionOption">
              <div styleName="meta">
                <I18n value="settingsForAllYourQuestions" asHtml />
                <div styleName="actions">
                  <a
                    href={EXTERNALROUTES.KOLIBRI_FREQUENT_QUESTIONS.URI}
                    target="_blank"
                  >
                    <I18n value="settingsFrequentlyAskedQuestions" />
                  </a>
                  {/* <Link
                    to={EXTERNALROUTES.KOLIBRI_WEBINARS.URI}
                    target="_blank"
                  >
                    <I18n value="settingsBookAWebinar" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
