import {
  AcquisitionStatus,
  AssignmentSnapShot,
  BidStatus,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity,
} from "@haywork/components/ui/linked-entities";
import { MultiListRowProps } from "@haywork/components/ui/multi-list";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil, AssignmentUtil } from "@haywork/util";
import classNames from "classnames";
import head from "lodash-es/head";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber, useIntl } from "react-intl";
import { EmailAssignment } from "@haywork/util/email-v2";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = MultiListRowProps<AssignmentSnapShot> & {
  selectedAssignmentsIds: string[];
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
  onToggleSelected: (assignment: EmailAssignment) => void;
};

export const AssignmentRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      style,
      zebra,
      data,
      selectedAssignmentsIds,
      onNavigate,
      onShowAllLinkedEntities,
      onToggleSelected,
    }) => {
      const {
        photo1Url,
        photo2Url,
        photo3Url,
        displayName,
        forSale,
        salePrice,
        rentPrice,
        acquisitionStatus,
        id,
        linkedBids,
        linkedVendors,
      } = data as AssignmentSnapShot;
      const intl = useIntl();

      const photo = useMemo(
        () => head([photo1Url, photo2Url, photo3Url].filter((d) => !!d)),
        [photo1Url, photo2Url, photo3Url]
      );

      const lastOffer = useMemo(() => {
        if (
          acquisitionStatus !== AcquisitionStatus.UnderNegociation ||
          !linkedBids ||
          !linkedBids.length
        )
          return null;
        const bids = sortBy(
          linkedBids.filter((bid) =>
            [BidStatus.Active, BidStatus.Accepted].includes(bid.status)
          ),
          (bid) => moment(bid.bidDateTime).format("x")
        ).reverse();
        if (!bids.length) return null;
        const { amount, bidDateTime } = head(bids);
        const price = intl.formatNumber(amount || 0, {
          style: "currency",
          currency: "EUR",
        });
        const date = moment(bidDateTime || new Date()).format("D MMMM");

        return (
          <I18n
            value="acquisitionAssignment.value.offer"
            values={{ price, date }}
          />
        );
      }, [id, linkedBids, acquisitionStatus, intl]);

      const statusStyleName = useMemo(() => {
        switch (acquisitionStatus) {
          case AcquisitionStatus.Canceled:
          case AcquisitionStatus.Dissolved: {
            return "danger";
          }
          case AcquisitionStatus.New: {
            return "primary";
          }
          case AcquisitionStatus.Purchased:
          case AcquisitionStatus.Rented: {
            return "success";
          }
          case AcquisitionStatus.UnderNegociation: {
            return "warning";
          }
          default: {
            return "primary";
          }
        }
      }, [acquisitionStatus]);

      const emailAssignment = useMemo(
        () =>
          AssignmentUtil.mapAssignmentSnapShotToEmailAssignment(
            data as AssignmentSnapShot,
            intl
          ),
        [data, intl]
      );

      const isSelected = useMemo(() => {
        return selectedAssignmentsIds.includes(id);
      }, [id, selectedAssignmentsIds]);

      const onClickHandler = useCallback(() => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [id, onNavigate]);

      const toggleSelectedCallback = useCallback(
        (event: React.MouseEvent) => {
          event.stopPropagation();
          onToggleSelected(emailAssignment);
        },
        [emailAssignment, onToggleSelected]
      );

      return (
        <div
          style={style}
          styleName={classNames("row", { zebra })}
          onClick={onClickHandler}
        >
          <div styleName="selected">
            <div styleName="selected__trigger" onClick={toggleSelectedCallback}>
              <Icon
                name={isSelected ? "check-square" : "square"}
                size={16}
                light={!isSelected}
                solid={isSelected}
                color={isSelected ? Colors.Primary : Colors.MediumGray}
              />
            </div>
          </div>
          <div styleName="column thumb">
            <div
              styleName="preview"
              style={{
                backgroundImage: !photo
                  ? null
                  : `url(${JSON.stringify(photo)})`,
              }}
            >
              {!photo && (
                <div styleName="preview__empty-state">
                  <Icon
                    name="camera-alt"
                    size={20}
                    color={Colors.MediumGray}
                    light
                  />
                </div>
              )}
            </div>
          </div>
          <div styleName="column meta">
            {!!displayName && <div styleName="title">{displayName}</div>}

            <div styleName="column-row">
              <div styleName="column-row__label">
                <I18n
                  value={
                    !!forSale
                      ? "acquisitionAssignment.label.salePrice"
                      : "acquisitionAssignment.label.rentPrice"
                  }
                />
              </div>
              <div styleName="column-row__value">
                <FormattedNumber
                  value={(!!forSale ? salePrice : rentPrice) || 0}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
              </div>
            </div>

            {!!lastOffer && (
              <div styleName="column-row">
                <div styleName="column-row__label">
                  <I18n value="acquisitionAssignment.label.offer" />
                </div>
                <div styleName="column-row__value">{lastOffer}</div>
              </div>
            )}

            {!!(linkedVendors || []).length && (
              <div styleName="column-row">
                <div styleName="column-row__label">
                  <I18n
                    value={
                      !!forSale
                        ? "acquisitionAssignment.label.vendorSale"
                        : "acquisitionAssignment.label.vendorRent"
                    }
                  />
                </div>
                <div styleName="column-row__value">
                  <LinkedEntities
                    linkedAssignments={[]}
                    linkedRelations={linkedVendors}
                    parentId={id}
                    onNavigate={onNavigate}
                    onShowAllLinkedEntities={onShowAllLinkedEntities}
                  />
                </div>
              </div>
            )}
          </div>

          <div styleName={classNames("column", "status", statusStyleName)}>
            <I18n
              prefix="acquisitionStatusOptions"
              value={
                !acquisitionStatus
                  ? AcquisitionStatus.New.toString()
                  : acquisitionStatus.toString()
              }
            />
          </div>
        </div>
      );
    }
  )
);
