import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState } from "@haywork/stores";
import {
  AcquisitionActions,
  AcquisitionFilters
} from "@haywork/stores/acquisition";
import { SimpleLabelOriginal } from "@haywork/util";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { AcquisitionListScreen, AcquisitionListScreenProps } from "./list";
import { selectedFilters } from "./selectors";

type StateProps = {
  filters: AcquisitionFilters;
  selectedFilters: SimpleLabelOriginal[];
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  clearFilter: (filter: SimpleLabelOriginal) => void;
  clearAllFilters: () => void;
  getAcquisitions: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionListScreenProps,
  AppState
> = (state) => {
  const { filters } = state.acquisition.list;

  return {
    filters,
    selectedFilters: selectedFilters(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionListScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  clearFilter: (filter: SimpleLabelOriginal) =>
    dispatch(AcquisitionActions.List.clearFilter(filter)),
  clearAllFilters: () => dispatch(AcquisitionActions.List.clearAllFilters()),
  getAcquisitions: () =>
    dispatch(AcquisitionThunks.Acquisition.getAcquisitions(0, 100))
});

export type AcquisitionListScreenContainerProps = StateProps & DispatchProps;
export const AcquisitionListScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionListScreen);
