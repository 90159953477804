import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { InfoBarComponent } from "./info-bar";
import { InfoBarComponentProps } from "./info-bar";
type StateProps = {};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  InfoBarComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  InfoBarComponentProps
> = (dispatch) => ({});

export type InfoBarContainerProps = StateProps & DispatchProps;
export const InfoBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoBarComponent);
