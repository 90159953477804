import { TableResponse } from "@haywork/api/kolibri";
import { TableResponse as EventCenterTableResponse } from "@haywork/api/event-center";
import { MasterTableResponse as AuthorizationTableResponse } from "@haywork/api/authorization";

export interface ExtendedTableResponse
  extends TableResponse,
    EventCenterTableResponse,
    AuthorizationTableResponse {}

export * from "./main.actions";
export * from "./main.reducer";
