import { BASEROUTES, MAINROUTES } from "@haywork/constants";
import { ErrorNotFound } from "@haywork/modules/error";
import { DefaultLayoutContainer } from "@haywork/modules/layout";
import { history } from "@haywork/stores";
import * as React from "react";
import { FC, memo } from "react";
import { DndProvider } from "react-dnd";
import Html5Backend from "react-dnd-html5-backend";
import { Redirect, Route, Switch } from "react-router-dom";
import { MainContainer } from "./main.container";
import { ConnectedRouter } from "connected-react-router";

const AppRouting: FC = memo(() => {
  return (
    <DndProvider backend={Html5Backend}>
      <MainContainer>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/">
              <Redirect to={MAINROUTES.DASHBOARD.URI} />
            </Route>
            <Route path={BASEROUTES.APP} component={DefaultLayoutContainer} />
            <Route path={BASEROUTES.NOT_FOUND} component={ErrorNotFound} />
            <Route render={() => <Redirect to={BASEROUTES.NOT_FOUND} />} />
          </Switch>
        </ConnectedRouter>
      </MainContainer>
    </DndProvider>
  );
});

export { AppRouting, history };
