import { ReviewSnapShot } from "@haywork/api/authorization";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { CreateReviewRequest } from "@haywork/middleware";
import { AppXchangeReviewsContainerProps } from "@haywork/modules/app-xchange";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ConfirmComponent, ResourceText } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType
} from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import ReviewForm from "../review-form";
import { ToggleConsent } from "../toggle-consent";
import { Review } from "./review.component";
import { ReviewVisual } from "./visual.component";

const styles = require("./reviews.component.scss");

export interface AppXchangeReviewsComponentProps {}
interface State {
  reviewFormVisible: boolean;
  submitting: boolean;
  deleteConfirmVisible: boolean;
  reviewToDelete: string;
  review: ReviewSnapShot;
}
type Props = AppXchangeReviewsComponentProps & AppXchangeReviewsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeReviewsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      reviewFormVisible: false,
      submitting: false,
      deleteConfirmVisible: false,
      reviewToDelete: null,
      review: null
    };

    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onFormCloseHandler = this.onFormCloseHandler.bind(this);
    this.onEditReview = this.onEditReview.bind(this);
    this.onDeleteReview = this.onDeleteReview.bind(this);
    this.onConfirmDeleteReview = this.onConfirmDeleteReview.bind(this);
  }

  public componentDidMount() {
    this.props.getReviews(this.props.client.id);
  }

  public render() {
    const hasConsent =
      !!this.props.client.linkedConsent || this.props.client.isFirstParty;

    return (
      <div styleName="reviews">
        <PageHeader
          title="appXchangeReviewsTitle"
          actions={<ToggleConsent />}
        />

        <div styleName="reviews__body">
          <ReviewVisual statistics={this.props.client.appClientStatistics} />

          <div styleName="review-list__header">
            <h2>
              <ResourceText resourceKey="appXchangeReviewList" />
            </h2>
            <button
              type="button"
              onClick={() => this.setState({ reviewFormVisible: true })}
              className="btn btn-primary"
              disabled={!hasConsent}
            >
              <ResourceText resourceKey="appXchangeAddReview" />
            </button>
          </div>

          <div styleName="review-list__list">
            {this.state.reviewFormVisible && (
              <ReviewForm
                review={this.state.review}
                employee={this.props.employee}
                client={this.props.client}
                submitting={this.state.submitting}
                onFormCloseHandler={this.onFormCloseHandler}
                onSubmitReview={this.onSubmitReview}
              />
            )}

            {this.props.reviewsStatus === REQUEST.SUCCESS &&
              !this.props.reviews.length && (
                <div styleName="empty-state">
                  <Ui.EmptyState
                    type={UiEmptyStateType.List}
                    stickman={UiEmptyStateStickMan.NoAssignments}
                    title="emptyStateNoReviewsTitle"
                    subtitle="emptyStateNoReviewsText"
                  />
                </div>
              )}

            {this.props.reviews.map((review, idx) => (
              <ErrorBoundary key={idx}>
                <Review
                  review={review}
                  employee={this.props.employee}
                  onEdit={this.onEditReview}
                  onDelete={this.onConfirmDeleteReview}
                />
              </ErrorBoundary>
            ))}

            {this.props.reviewsStatus === REQUEST.PENDING && (
              <Ui.Loaders.List />
            )}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.deleteConfirmVisible}
          titleResourceKey="deleteReviewConfirmTitle"
          bodyResourceKey="deleteReviewConfirmMessage"
          bodyValues={{ displayName: this.props.client.displayName }}
          onClose={() =>
            this.setState({ deleteConfirmVisible: false, reviewToDelete: null })
          }
          onConfirm={this.onDeleteReview}
        />
      </div>
    );
  }

  private onEditReview(review: ReviewSnapShot) {
    this.setState({
      reviewFormVisible: true,
      review
    });
  }

  private onConfirmDeleteReview(review: ReviewSnapShot) {
    this.setState({ deleteConfirmVisible: true, reviewToDelete: review.id });
  }

  private onDeleteReview() {
    this.props.deleteReview(this.state.reviewToDelete, this.props.client.id);
    this.setState({ deleteConfirmVisible: false, reviewToDelete: null });
  }

  private async onSubmitReview(values: CreateReviewRequest) {
    if (this.state.submitting) return;
    this.setState({ submitting: true });

    try {
      if (!!values.id) {
        await this.props.updateReview(values);
      } else {
        await this.props.createAndSaveReview(values);
      }

      this.onFormCloseHandler();
    } catch (error) {
      throw error;
    } finally {
      this.setState({ submitting: false });
    }
  }

  private onFormCloseHandler() {
    this.setState({
      reviewFormVisible: false,
      review: null
    });
  }
}
