import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import { ManageFiltersContainerProps } from "./container";

const styles = require("./style.scss");

export type ManageFiltersProps = {
  visible: boolean;
  onClose: () => void;
};

type Props = ManageFiltersProps & ManageFiltersContainerProps;

export const ManageFiltersComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, onClose, savedFilters, removeFilter }) => {
      const [loading, setLoading] = useState(false);

      const handleRemove = useCallback(
        async (savedFilter: MlsFilter) => {
          try {
            if (loading) return;
            setLoading(true);
            await removeFilter(savedFilter);
          } finally {
            setLoading(false);
          }
        },
        [removeFilter, loading, setLoading]
      );

      return (
        <>
          <Modal visible={visible} onClose={onClose}>
            <ModalHeader close title="mls.manageFilters.modal.title" />
            <ModalBody noPadding>
              {!!savedFilters.length && (
                <div styleName="saved-filters">
                  {savedFilters.map((savedFilter, idx) => {
                    return (
                      <div
                        styleName={classNames("saved-filter", {
                          zebra: idx % 2 === 0,
                        })}
                        key={savedFilter.id || idx}
                      >
                        <div styleName="saved-filter__label">
                          {savedFilter.name}
                        </div>
                        <div
                          styleName="saved-filter__trigger"
                          onClick={() => handleRemove(savedFilter)}
                        >
                          <Icon
                            size={14}
                            name="times"
                            light
                            color={Colors.Gray}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                category="primary"
                label="mls.manageFilters.modal.footer.close"
                disabled={loading}
                icon={
                  !loading ? null : (
                    <Icon name="spinner" color={Colors.Gray} spin />
                  )
                }
                onClick={onClose}
              />
            </ModalFooter>
          </Modal>
        </>
      );
    }
  )
);
