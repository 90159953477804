import { AssignmentType, LinkedAssignment } from "@haywork/api/kolibri";
import Pill from "@haywork/components/ui/pill";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback } from "react";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  linkedEntity: LinkedAssignment;
  onNavigate: (path: string) => void;
};

export const LinkedAssignmentComponent: FC<Props> = memo(
  ({ linkedEntity, onNavigate }) => {
    const { displayName, id, typeOfAssignment } = linkedEntity;

    const navigateToAssignment = useCallback(() => {
      let path: string;

      switch (typeOfAssignment) {
        case AssignmentType.Acquisition: {
          path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
          break;
        }
        case AssignmentType.AcquisitionObject: {
          path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
          break;
        }
        case AssignmentType.Object: {
          path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
          break;
        }
        case AssignmentType.ObjectType: {
          path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
          break;
        }
        case AssignmentType.Project: {
          path = route(PROJECTROUTES.DETAIL.URI, { id });
          break;
        }
        default:
          break;
      }

      if (!!path) onNavigate(path);
    }, [onNavigate, id, typeOfAssignment]);

    return (
      <Pill
        label={displayName}
        icon="folder"
        color={Colors.Primary}
        onClick={navigateToAssignment}
      />
    );
  }
);

export default LinkedAssignmentComponent;
