import * as React from "react";
import * as CSSModules from "react-css-modules";
import { REQUEST } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./map.component.scss");

export interface MapCenterChangedValues {
  lat: number;
  lng: number;
}

interface MapComponentProps {
  lat?: number;
  lng?: number;
  zoom?: number;
  addMarker?: boolean;
  canAssignNewLocation?: boolean;
  centerChanged?: (position: MapCenterChangedValues) => void;
  addMarkerToMap?: () => void;
  loadingState?: string;
}
interface MapComponentState { }

@CSSModules(styles, { allowMultiple: true })
export class MapComponent extends React.Component<MapComponentProps, MapComponentState> {
  private mapReference: HTMLDivElement;
  private map: google.maps.Map;
  private marker: google.maps.Marker;

  public render() {
    return <div
      styleName="map__wrapper"
      data-cy={ this.props["data-cy"] }
    >
      <div styleName="map" ref={ (ref) => this.mapReference = ref } />
      { !!this.props.loadingState && this.props.loadingState === REQUEST.PENDING &&
        <div styleName="loading">
          <i className="fal fa-fw fa-map-marker" />
          <div styleName="loader">
            <div styleName="indeterminate" />
          </div>
        </div>
      }
      { (!!this.props.loadingState && this.props.loadingState !== REQUEST.PENDING) && (!this.props.lat || !this.props.lng) &&
        <div styleName="not-found">
          <i className="fal fa-fw fa-map-marker" />
          <ResourceText resourceKey="locationNotProvided" />
        </div>
      }
    </div>;
  }

  public componentDidMount() {
    const { lat, lng } = this.props;
    const center = (!!lat && !!lng) ? new google.maps.LatLng(lat, lng) : new google.maps.LatLng(51.984737, 5.907681);
    const mapOptions = {
      zoom: this.props.zoom || 15,
      clickableIcons: false,
      center,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapReference, mapOptions);

    if (this.props.addMarker) {
      this.marker = new google.maps.Marker({ map: this.map, position: center });
    }

    if (this.props.canAssignNewLocation && this.marker && this.props.centerChanged) {
      this.marker.setDraggable(true);

      google.maps.event.addListener(this.map, "click", (event: { latLng: google.maps.LatLng }) => {
        const { latLng } = event;
        const returnValue: MapCenterChangedValues = { lat: latLng.lat(), lng: latLng.lng() };

        this.marker.setPosition(latLng);
        this.map.panTo(latLng);
        this.props.centerChanged(returnValue);
      });

      google.maps.event.addListener(this.marker, "dragend", (event: { latLng: google.maps.LatLng }) => {
        const { latLng } = event;

        const returnValue: MapCenterChangedValues = { lat: latLng.lat(), lng: latLng.lng() };
        this.map.panTo(latLng);
        this.props.centerChanged(returnValue);
      });
    }

    if (this.props.canAssignNewLocation && !this.marker && this.props.addMarkerToMap) {
      google.maps.event.addListener(this.map, "click", (event: { latLng: google.maps.LatLng }) => {
        const { latLng } = event;

        const returnValue: MapCenterChangedValues = { lat: latLng.lat(), lng: latLng.lng() };
        this.map.panTo(latLng);
        this.props.centerChanged(returnValue);

        this.props.addMarkerToMap();
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: MapComponentProps) {
    if (nextProps.lat && nextProps.lng) {
      const center = new google.maps.LatLng(nextProps.lat, nextProps.lng);
      if (this.marker) this.marker.setPosition(center);
      this.map.panTo(center);
    }
    if (nextProps.addMarker && !this.marker) {
      const { lat, lng } = nextProps;
      const center = (!!lat && !!lng) ? new google.maps.LatLng(lat, lng) : new google.maps.LatLng(51.984737, 5.907681);
      this.marker = new google.maps.Marker({ map: this.map, position: center });

      if (this.props.canAssignNewLocation && this.props.centerChanged) {
        this.marker.setDraggable(true);

        google.maps.event.addListener(this.marker, "dragend", (event) => {
          const { latLng } = event;

          const returnValue: MapCenterChangedValues = { lat: latLng.lat(), lng: latLng.lng() };
          this.map.panTo(latLng);
          this.props.centerChanged(returnValue);
        });
      }
    }
    if (this.props.zoom !== nextProps.zoom) {
      this.map.setZoom(nextProps.zoom);
    }
  }

  public componentWillUnmount() {
    google.maps.event.clearInstanceListeners(this.mapReference);
    delete this.map;
  }
}
