import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";

export enum SwitchLabelPosition {
  Pre = "Pre",
  Post = "Post",
}

interface SwitchInputComponentProps {
  on: any;
  off: any;
  label?: string;
  labelPosition?: SwitchLabelPosition;
}
interface SwitchInputComponentState {
  labelPre: boolean;
}

export class SwitchComponent extends React.Component<
  SwitchInputComponentProps & InputComponentProps,
  SwitchInputComponentState
> {
  private inputRef: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      labelPre:
        this.props.labelPosition &&
        this.props.labelPosition === SwitchLabelPosition.Pre,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
  }

  public render() {
    const active = this.props.value === this.props.on;
    const switchInputStyle = classNames("input__switch", {
      active,
      "label-pre": this.state.labelPre,
    });

    return (
      <div className={switchInputStyle} onClick={this.onClickHandler}>
        <input
          type="checkbox"
          ref={(ref) => (this.inputRef = ref)}
          checked={active}
          onChange={this.onInputChangeHandler}
        />
        <div
          id={this.props.label}
          className="element"
          data-cy={this.props["data-cy"]}
        >
          <div className="toggle" />
        </div>
        {!!this.props.label && (
          <label>
            <ResourceText resourceKey={this.props.label} />
          </label>
        )}
      </div>
    );
  }

  public onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (!!this.props.disabled) return;
    if (this.inputRef) this.inputRef.focus();
    const active = this.props.value === this.props.on;
    const value = active ? this.props.off : this.props.on;

    this.props.onChange(value);
  }

  private onInputChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const active = event.target.checked;
    const value = active ? this.props.on : this.props.off;

    this.props.onChange(value);
  }
}
