import { WidgetEntityType } from "@haywork/api/authorization";
import {
  TimelineActionType,
  TimelineEvent,
  LinkedRelation,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import Icon from "@haywork/components/ui/icon";
import { LinkedEntity } from "@haywork/components/ui/linked-entities";
import PageHeader from "@haywork/components/ui/page-header";
import { EmailMessageBlobContainer } from "@haywork/modules/email";
import { Input } from "@haywork/modules/form";
import Notes from "@haywork/modules/notes-v3";
import AddCommunicationLogModal from "@haywork/modules/shared/components/add-communication-log";
import ContactMeModal from "@haywork/modules/shared/components/contact-me-modal";
import VoipInfoModal from "@haywork/modules/shared/components/voip-info-modal";
import { Ui } from "@haywork/modules/ui";
import head from "lodash-es/head";
import * as React from "react";
import {
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfinitLoader from "react-window-infinite-loader";
import LinkedEntitiesModal from "../linked-entities-modal";
import EmptyItem from "./components/empty-item";
import Event from "./components/item";
import { TimelineContainerProps } from "./timeline.container";
import { File } from "@haywork/api/mail";
import { SaveFilesToDossier } from "../dossier-v2";

const styles = require("./style.scss");

const InnerElementType = forwardRef<any, any>(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, loading, total, ...rest }, ref) => {
      return (
        <div {...rest} ref={ref}>
          {loading && total === 0 && (
            <div styleName="loader">
              <Ui.Loaders.List />
            </div>
          )}
          {!loading && total === 0 && (
            <div styleName="empty-state">
              <EmptyState
                icon="align-left"
                title="timeline.emptyStateList.title"
                subTitle="timeline.emptyStateList.subTitle"
              />
            </div>
          )}
          {children}
        </div>
      );
    }
  )
);

export type TimelineComponentProps = {
  entityId: string;
  entityType: "relation" | "assignment";
  subTitle?: string;
  subTitleValues?: { [key: string]: any };
  subTitlePrefix?: string;
  addNotes?: boolean;
  widgetEntityType?: WidgetEntityType;
  includeTimelineEventsOfChildItems?: boolean;
};
type Props = TimelineComponentProps & TimelineContainerProps;

export const TimelineComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      entityId,
      entityType,
      getTimelineEvents,
      taskCategories,
      agendaItemCategories,
      subTitle,
      subTitleValues,
      subTitlePrefix,
      navigate,
      addNotes,
      widgetEntityType,
      deleteCommunicationLog,
      includeTimelineEventsOfChildItems,
    }) => {
      const [events, setEvents] = useState<TimelineEvent[]>([]);
      const [total, setTotal] = useState(0);
      const [loading, setLoading] = useState(false);
      const [
        timelineActionType,
        setTimelineActionType,
      ] = useState<TimelineActionType | null>(null);
      const [linkedEntities, setLinkedEntities] = useState<LinkedEntity[]>([]);
      const [modalEntity, setModalEntity] = useState<string | null>(null);
      const [visibleModal, setVisibleModal] = useState<
        | "email"
        | "contactme"
        | "voip"
        | "addlog"
        | "linkedentities"
        | "savefilestodossier"
        | null
      >(null);
      const [saveToDossierFiles, setSaveToDossierFiles] = useState<File[]>([]);
      const [
        saveToDossierLinkedRelations,
        setSaveToDossierLinkedRelations,
      ] = useState<LinkedRelation[]>([]);
      const [
        saveToDossierLinkedAssignments,
        setSaveToDossierLinkedAssignments,
      ] = useState<LinkedAssignment[]>([]);

      const timelineActionTypeValues = useMemo(() => {
        switch (entityType) {
          case "assignment": {
            return [
              {
                label: TimelineActionType.AgendaItem,
                value: TimelineActionType.AgendaItem,
              },
              {
                label: TimelineActionType.CommunicationLog,
                value: TimelineActionType.CommunicationLog,
              },
              {
                label: TimelineActionType.Invoice,
                value: TimelineActionType.Invoice,
              },
              {
                label: TimelineActionType.Task,
                value: TimelineActionType.Task,
              },
              {
                label: TimelineActionType.Transaction,
                value: TimelineActionType.Transaction,
              },
            ];
          }
          case "relation": {
            return [
              {
                label: TimelineActionType.AgendaItem,
                value: TimelineActionType.AgendaItem,
              },
              {
                label: TimelineActionType.CommunicationLog,
                value: TimelineActionType.CommunicationLog,
              },
              {
                label: TimelineActionType.Invoice,
                value: TimelineActionType.Invoice,
              },
              {
                label: TimelineActionType.Task,
                value: TimelineActionType.Task,
              },
            ];
          }
          default:
            return [];
        }
      }, [entityType]);

      const fetchEvents = async (
        startIndex: number,
        stopIndex: number,
        filterByActionType: TimelineActionType,
        clear: boolean = false
      ) => {
        setLoading(true);

        const { events: newEvents, total } = await getTimelineEvents(
          startIndex,
          stopIndex,
          entityId,
          undefined,
          !!filterByActionType ? [filterByActionType] : [],
          entityType === "assignment"
            ? includeTimelineEventsOfChildItems
            : undefined
        );

        setEvents(clear ? newEvents : [...events, ...newEvents]);
        setTotal(total);
        setLoading(false);
      };

      const isItemLoaded = (index: number) => !!events[index];
      const loadMoreItems = (startIndex: number, stopIndex: number) => {
        return fetchEvents(startIndex, stopIndex, timelineActionType);
      };

      const onChangeFilter = (
        timelineActionType: TimelineActionType | null
      ) => {
        setTimelineActionType(timelineActionType);
      };

      const onDeleteEventClickHandler = useCallback(
        async (event: TimelineEvent) => {
          await deleteCommunicationLog(event.id, () =>
            fetchEvents(0, 25, timelineActionType, true)
          );
          fetchEvents(0, 25, timelineActionType, true);
        },
        [timelineActionType, fetchEvents]
      );

      const onUpdatedCommunicationLog = useCallback(() => {
        fetchEvents(0, 25, timelineActionType, true);
      }, [fetchEvents, timelineActionType]);

      const onEventClickHandler = (
        event: TimelineEvent,
        type: "email" | "phone" | "contactme"
      ) => {
        switch (type) {
          case "email": {
            setModalEntity(event.id);
            setVisibleModal("email");
            return;
          }
          case "phone": {
            setModalEntity(event.id);
            setVisibleModal("voip");
            return;
          }
          case "contactme": {
            if (!(event.linkedMessages || []).length) return;
            const message = head(event.linkedMessages);
            setModalEntity(message.id);
            setVisibleModal("contactme");
            return;
          }
          default:
            return;
        }
      };

      const onShowAllLinkedEntities = (linkedEntities: LinkedEntity[]) => {
        setLinkedEntities(linkedEntities);
        setVisibleModal("linkedentities");
      };

      const row: FC<ListChildComponentProps> = (props) => {
        const { index } = props;
        const rowData = events[index];

        return !rowData ? (
          <EmptyItem zebra={index % 2 !== 0} {...props} />
        ) : (
          <Event
            {...props}
            event={rowData}
            parentId={entityId}
            key={rowData.id}
            zebra={index % 2 !== 0}
            taskCategories={taskCategories}
            agendaItemCategories={agendaItemCategories}
            widgetEntityType={widgetEntityType}
            onNavigate={navigate}
            onDelete={onDeleteEventClickHandler}
            onClick={onEventClickHandler}
            onShowAllLinkedEntities={onShowAllLinkedEntities}
          />
        );
      };

      useEffect(() => {
        fetchEvents(0, 25, timelineActionType, true);
      }, [timelineActionType]);

      const closeModal = (refresh: boolean = false) => {
        setVisibleModal(null);
        setModalEntity(null);
        setLinkedEntities([]);

        if (refresh) {
          fetchEvents(0, 25, timelineActionType, true);
        }
      };

      const onSaveAttachmentsToDossier = useCallback(
        (
          files: File[],
          linkedRelations: LinkedRelation[],
          linkedAssignments: LinkedAssignment[]
        ) => {
          setSaveToDossierFiles(files);
          setSaveToDossierLinkedAssignments(linkedAssignments);
          setSaveToDossierLinkedRelations(linkedRelations);
          setVisibleModal("savefilestodossier");
        },
        [
          setSaveToDossierFiles,
          setSaveToDossierLinkedAssignments,
          setSaveToDossierLinkedRelations,
          setVisibleModal,
        ]
      );

      const onCloseSaveToDossier = useCallback(() => {
        setSaveToDossierFiles([]);
        setSaveToDossierLinkedAssignments([]);
        setSaveToDossierLinkedRelations([]);
        setVisibleModal(null);
      }, [
        setSaveToDossierFiles,
        setSaveToDossierLinkedAssignments,
        setSaveToDossierLinkedRelations,
        setVisibleModal,
      ]);

      return (
        <div styleName="timeline">
          <PageHeader
            title="timeline.title"
            subTitle={subTitle}
            subTitleValues={subTitleValues}
            subTitlePrefix={subTitlePrefix}
            actions={
              <>
                {!!addNotes && <Notes />}
                <Button
                  category="primary"
                  icon={<Icon name="plus" light size={18} />}
                  iconPosition="start"
                  label="timeline.addPhoneLog"
                  onClick={() => {
                    setVisibleModal("addlog");
                  }}
                />
              </>
            }
          />
          <div styleName="timeline__filter">
            <I18n value="timeline.filter.prefix" />
            <div styleName="filter__input">
              <Input.NewSelect
                name="timelineActionType"
                values={timelineActionTypeValues}
                valuesProp="value"
                displayProp="label"
                addEmptyOption
                emptyOptionLabel="timelineActionType.All"
                translate
                translatePrefix="timelineActionType"
                value={timelineActionType}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div styleName="timeline__list">
            <AutoSizer>
              {({ width, height }) => (
                <InfinitLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={total}
                  loadMoreItems={loadMoreItems}
                  minimumBatchSize={25}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      innerElementType={(props) => (
                        <InnerElementType
                          {...props}
                          loading={loading}
                          total={total}
                        />
                      )}
                      itemSize={100}
                      itemCount={total}
                      height={height}
                      width={width}
                      ref={ref}
                      onItemsRendered={onItemsRendered}
                    >
                      {row}
                    </FixedSizeList>
                  )}
                </InfinitLoader>
              )}
            </AutoSizer>
          </div>

          <EmailMessageBlobContainer
            modalVisible={visibleModal === "email"}
            communicationLogId={modalEntity}
            onModalCloseHandler={closeModal}
            onSaveAttachmentsToDossier={onSaveAttachmentsToDossier}
            onUpdatedCommunicationLog={onUpdatedCommunicationLog}
          />

          <ContactMeModal
            visible={visibleModal === "contactme"}
            messageId={modalEntity}
            onClose={closeModal}
          />

          <VoipInfoModal
            visible={visibleModal === "voip"}
            communicationLogId={modalEntity}
            onClose={closeModal}
          />

          <AddCommunicationLogModal
            visible={visibleModal === "addlog"}
            linkedRelation={entityType === "relation" ? { id: entityId } : null}
            linkedAssignment={
              entityType === "assignment" ? { id: entityId } : null
            }
            onClose={closeModal}
          />

          <LinkedEntitiesModal
            linkedEntities={linkedEntities}
            visible={visibleModal === "linkedentities"}
            onClose={closeModal}
            onNavigate={navigate}
          />

          <SaveFilesToDossier
            visible={visibleModal === "savefilestodossier"}
            linkedAssignments={saveToDossierLinkedAssignments}
            linkedRelations={saveToDossierLinkedRelations}
            onClose={onCloseSaveToDossier}
            files={saveToDossierFiles}
          />
        </div>
      );
    }
  )
);
