import { MapDispatchToProps } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  LocationInputComponent,
  LocationInputComponentProps,
} from "./location-input";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";

type StateProps = {};
type DispatchProps = {
  querySuggestions: (term: string) => Promise<ExtendedLocationSuggestionItem[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  LocationInputComponentProps,
  AppState
> = (state, ownProps) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LocationInputComponentProps
> = (dispatch) => ({
  querySuggestions: (term: string) =>
    dispatch(MLSThunk.List.queryLocationSuggestions(term)),
});

export type LocationInputContainerProps = StateProps & DispatchProps;
export const LocationInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationInputComponent);
