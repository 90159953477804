import { DistanceFromTo } from "@haywork/enum";
import { PointOfInterest } from "@haywork/api/kolibri";

export class Distance {

  public static getPoiDistanceFromTo(poi: PointOfInterest): DistanceFromTo {
    if ((poi.distanceFrom === undefined || poi.distanceFrom === 0) && poi.distanceTo === 500) return DistanceFromTo.LessThanFiveHundred;
    if (poi.distanceFrom === 500 && poi.distanceTo === 1000) return DistanceFromTo.FiveHundredUntillOneThousand;
    if (poi.distanceFrom === 1000 && poi.distanceTo === 1500) return DistanceFromTo.OneThousandUntillFifteenHundred;
    if (poi.distanceFrom === 1500 && poi.distanceTo === 2000) return DistanceFromTo.FifteenHundredUntillTwoThousand;
    if (poi.distanceFrom === 2000 && poi.distanceTo === 3000) return DistanceFromTo.TwoThousandUntillThreeThousand;
    if (poi.distanceFrom === 3000 && poi.distanceTo === 4000) return DistanceFromTo.ThreeThousandUntillFourThousand;
    if (poi.distanceFrom === 4000 && poi.distanceTo === 5000) return DistanceFromTo.FourThousandUntillFiveThousand;
    if (poi.distanceFrom === 5000 && poi.distanceTo === undefined) return DistanceFromTo.MoreThanFiveThousand;
  }
}
