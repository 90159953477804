import { Dispatch } from "../";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import {
  AccountsClient,
  SortOrder,
  AccountOrderBy,
  Account,
} from "@haywork/api/mail";
import { COUNTS } from "@haywork/constants";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { AccountsActions } from "@haywork/stores/email/accounts";
import first from "lodash-es/first";
import { EmailRequestState } from "@haywork/stores/email-v2/main";

const parseRequest = new ParseRequest();

const getAccounts = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(
          EmailRequestState.FetchingAccounts
        )
      );

      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: personId } = state.account.employee;
      const client = new AccountsClient(emailHost);
      const skip = 0;
      const request = {
        personId,
        skip,
        take: COUNTS.EMAIL_ACCOUNTS,
        order: SortOrder.Ascending,
        orderBy: AccountOrderBy.DefaultAccount,
      };

      const response = await parseRequest.response(
        client.search(request, realEstateAgencyId)
      );

      if (!response) throw new Error("E-mail accounts request failed");
      const { totalResults, resultCount, results } = response;
      let accounts = results || [];

      if (totalResults > resultCount) {
        const diff = totalResults - resultCount;
        const count = Math.ceil(diff / COUNTS.EMAIL_ACCOUNTS);
        const promises: Promise<Account[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            client
              .search(
                {
                  ...request,
                  skip: i * COUNTS.EMAIL_ACCOUNTS,
                },
                realEstateAgencyId
              )
              .then((response) => response.results)
          );
        }

        const response = await Promise.all(promises);
        const results = response.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <Account[]>[]);

        accounts = [...accounts, ...results];
      }

      const emailStateAccounts = { ...response, take: COUNTS.EMAIL_ACCOUNTS };
      const currentAccount = first(accounts);
      dispatch(EmailActionsV2.Accounts.setAccounts(accounts));
      dispatch(AccountsActions.setAccounts(emailStateAccounts));
      dispatch(AccountsActions.setCurrentAccount({ account: currentAccount }));
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Idle)
      );

      return accounts;
    } catch (error) {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Error)
      );
      throw error;
    }
  };
};

export default {
  getAccounts,
};
