import { ActionType, ActionTypes } from "./actions";
import { AccountShare } from "@haywork/api/mail";

export type State = {
  shares: AccountShare[];
};

const INITIAL: State = {
  shares: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetShares: {
      const { shares } = action;

      return {
        ...state,
        shares,
      };
    }
    case ActionType.Clear:
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
