import {
  ActiveFilter,
  AssignmentType,
  RelationType,
  RelationSnapShot,
  AssignmentOrderByField,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  KEYCODE,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ModalPortal } from "@haywork/portals";
import { Assignment, Relation } from "@haywork/request";
import { AssignmentUtil, RelationUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import throttle from "lodash-es/throttle";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { InputComponentProps } from "..";
import Input from "./components/input";
import Item from "./components/item";
import Pill from "./components/pill";
import Result from "./components/result";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface PillItem {
  id: string;
  displayName: string;
  isActive: boolean;
}
export interface FormSuggestionComponentProps {
  type: "relation" | "assignment";
  relationTypes?: RelationType[];
  assignmentTypes?: AssignmentType[];
  placeholder?: string;
  fetchingResults?: boolean;
  onNavigate: (path: string) => void;
  onAdd?: (query: string) => void;
  infoModalCallback?: (relation: RelationSnapShot) => void;
}
interface State {
  items: any[];
  collapsed: boolean;
  listStyle: React.CSSProperties;
  query: string;
  results: any[];
  mouseDown: boolean;
  searchInArchive: boolean;
  refValues: any[];
  loading: boolean;
}
type Props = FormSuggestionComponentProps & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class FormSuggestionComponent extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  private focusRef: React.RefObject<HTMLInputElement> = React.createRef();
  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  private prevInput: HTMLElement;
  private nextInput: HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      items: (this.props.value || []).map((item) => ({
        displayName: item.displayName || item.publicRefefence,
        id: item.id,
        isActive: item.isActive,
        ...item,
      })),
      collapsed: true,
      listStyle: null,
      query: "",
      results: [],
      mouseDown: false,
      searchInArchive: false,
      refValues: this.props.value || [],
      loading: false,
    };

    this.onRemove = this.onRemove.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
    this.onBlur = throttle(this.onBlur.bind(this), 50, { leading: false });
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.triggerFocus = this.triggerFocus.bind(this);
    this.query = debounce(this.query.bind(this), 250);
    this.onAdd = this.onAdd.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
    this.onAddAsNewRelation = this.onAddAsNewRelation.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.toggleSearchInArchive = this.toggleSearchInArchive.bind(this);
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);

    window.addEventListener("scroll", this.handleWindowScroll, true);
  }

  public async componentDidUpdate(prevProps: Props) {
    const prevLength = (get(prevProps, "value") || []).length;
    const curLength = (get(this.props, "value") || []).length;
    // const intLength = this.state.items.length;

    if (prevLength !== curLength /* || curLength !== intLength*/) {
      const items = (this.props.value || []).map((item) => ({
        displayName: item.displayName || item.publicReference,
        id: item.id,
        isActive: item.isActive,
        ...item,
      }));
      this.setState({ items, refValues: this.props.value || [] });
    }
  }

  public async componentDidMount() {
    const parent = this.getParentForm(this.wrapperRef.current);
    if (!parent) return null;
    const inputs: NodeListOf<HTMLElement> = parent.querySelectorAll(
      "input, div[contenteditable=true]"
    );
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs.item(i);
      if (input === this.focusRef.current) {
        if (i < inputs.length - 1) {
          this.nextInput = inputs.item(i + 1);
        }
        if (i > 0) {
          this.prevInput = inputs.item(i - 1);
        }
        break;
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleWindowScroll, true);
  }

  public render() {
    const { items, listStyle, query, results, collapsed } = this.state;
    const itemsToDisplay = items.slice(0, 2);
    const remaining = items.length - itemsToDisplay.length;
    const itemIds = items.map((item) => item.id);
    const resultsToDisplay = results.filter(
      (item) => !itemIds.includes(item.id)
    );
    const placeholder = !!this.props.placeholder
      ? this.props.placeholder
      : this.props.type === "relation"
      ? "suggestionInput.searchRelations"
      : "suggestionInput.searchAssignments";

    return (
      <div styleName="suggestion__wrapper" ref={this.wrapperRef}>
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          ref={this.focusRef}
          onFocus={this.triggerFocus}
          styleName="focus-element"
          className="skip"
          data-lpignore="true"
        />

        {(!!this.props.fetchingResults ||
          (this.state.loading && !collapsed)) && (
          <div styleName="loader">
            <div styleName="indeterminate" />
          </div>
        )}

        <div styleName={classNames("suggestion", { collapsed })}>
          <div styleName="suggestion__value">
            {itemsToDisplay.map((item) => {
              return (
                <Pill
                  displayName={
                    item.displayName && item.displayName !== ""
                      ? item.displayName
                      : item.publicReference
                  }
                  id={item.id}
                  key={item.id}
                  onRemove={this.onRemove}
                  onClick={this.onClick}
                  item={item}
                  type={this.props.type}
                  emailCallback={
                    this.props.infoModalCallback
                      ? this.props.infoModalCallback
                      : undefined
                  }
                />
              );
            })}
            {remaining > 0 && (
              <div styleName="remaining" onClick={this.focusOnInput}>
                +{remaining}
              </div>
            )}
            {!itemsToDisplay.length && (
              <div styleName="placeholder" onClick={this.focusOnInput}>
                <I18n value={placeholder} />
              </div>
            )}
          </div>
          {items.length > 0 && (
            <div styleName="suggestion__clear" onClick={this.onClear}>
              <i className="fal fa-times" />
            </div>
          )}
          <div
            styleName="suggestion__trigger"
            onClick={this.toggleFocus}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
          >
            <i className="fal fa-chevron-down" />
          </div>
        </div>

        <ModalPortal>
          <div
            styleName={classNames("suggestions", { collapsed })}
            style={listStyle}
          >
            {!!items.length && (
              <div styleName="suggestions__list">
                {items.map((item) => (
                  <Item
                    item={item}
                    key={item.id}
                    onClick={this.onClick}
                    onRemove={this.onRemove}
                    onMouseDown={this.onMouseDown}
                    type={this.props.type}
                    emailCallback={
                      this.props.infoModalCallback
                        ? this.props.infoModalCallback
                        : undefined
                    }
                  />
                ))}
              </div>
            )}

            <div styleName="suggestions__query">
              <Input
                type={this.props.type}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                inputRef={this.inputRef}
                query={query}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
              />
              <div
                styleName={classNames("toggle-archive", {
                  active: this.state.searchInArchive,
                })}
                onMouseUp={this.toggleSearchInArchive}
                onMouseDown={this.onMouseDown}
              >
                <I18n value="suggestionComponent.toggleArchive" />
              </div>
              {!this.state.query.length ? (
                <div styleName="query-trigger" onClick={this.focusOnInput}>
                  <i className="fal fa-search" />
                </div>
              ) : (
                <div styleName="query-trigger clear" onClick={this.clearQuery}>
                  <i className="fal fa-times" />
                </div>
              )}
            </div>

            {(!!resultsToDisplay.length || !!query) && (
              <div styleName="results__list">
                {resultsToDisplay.map((item, idx) => (
                  <Result
                    item={item}
                    key={item.id}
                    onClicked={this.onAdd}
                    onMouseDown={this.onMouseDown}
                    idx={idx}
                    type={this.props.type}
                    emailCallback={
                      this.props.infoModalCallback
                        ? this.props.infoModalCallback
                        : undefined
                    }
                  />
                ))}
                {this.props.type === "relation" && this.props.onAdd && (
                  <div
                    styleName="add-new-relation"
                    onMouseUp={this.onAddAsNewRelation}
                    onMouseDown={this.onMouseDown}
                  >
                    <I18n
                      value="addAsNewRelation"
                      values={{ query }}
                      asHtml={true}
                    />
                  </div>
                )}
                {this.props.type === "assignment" && !resultsToDisplay.length && (
                  <div styleName="empty-state">
                    <I18n value="assignmentSearchEmptyState" />
                  </div>
                )}
              </div>
            )}
          </div>
        </ModalPortal>
      </div>
    );
  }

  private toggleFocus() {
    if (this.state.collapsed) {
      this.focusOnInput();
    } else {
      if (this.state.items && this.state.items.length > 0) {
        this.onBlur();
      } else {
        this.setState({
          collapsed: true,
          results: [],
          query: "",
        });
      }
    }
  }

  private clearQuery() {
    this.setState({ query: "", results: [] });
    this.focusOnInput();
  }

  private triggerFocus() {
    if (!this.inputRef.current) return;
    this.inputRef.current.focus();
  }

  private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode, shiftKey } = e;

    switch (keyCode) {
      case KEYCODE.TAB: {
        if (!!this.nextInput && !shiftKey) {
          e.preventDefault();
          this.nextInput.focus();
        }
        if (!!this.prevInput && shiftKey) {
          e.preventDefault();
          this.prevInput.focus();
        }
        return;
      }
      default: {
        return;
      }
    }
  }

  private getParentForm(el: HTMLElement): HTMLFormElement | null {
    switch (true) {
      case el.parentElement === document.body: {
        return null;
      }
      case el.parentElement.tagName === "FORM": {
        return el.parentElement as HTMLFormElement;
      }
      default: {
        return this.getParentForm(el.parentElement);
      }
    }
  }

  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value || "";
    this.setState({ query });
    this.query(query);
  }

  private onFocus() {
    if (!this.wrapperRef.current) return;
    const { top, height, left, width } =
      this.wrapperRef.current.getBoundingClientRect();

    this.setState({
      collapsed: false,
      listStyle: {
        top: top + height,
        left,
        width,
      },
    });
  }

  private onBlur() {
    if (this.state.mouseDown) return;
    if (this.inputRef.current !== document.activeElement) {
      this.setState({ collapsed: true, results: [], query: "" });
      this.props.onChange(
        this.props.type === "relation"
          ? this.state.refValues.map(RelationUtil.mapSnapshotToLinkedRelation)
          : this.state.refValues.map(
              AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment
            )
      );
    }
  }

  private focusOnInput() {
    if (!this.inputRef.current) return;
    this.inputRef.current.focus();
  }

  private onRemove(id: string) {
    const items: any = this.state.items.filter((item) => item.id !== id);
    const validIds = items.map((item) => item.id);
    const value = (this.props.value || []).filter((item) =>
      validIds.includes(item.id)
    );

    this.setState({ items, mouseDown: false });
    this.props.onChange(
      this.props.type === "relation"
        ? value.map(RelationUtil.mapSnapshotToLinkedRelation)
        : value.map(AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment)
    );

    if (!this.state.collapsed) {
      this.focusOnInput();
    }
  }

  private onAdd(item: any) {
    this.setState({ mouseDown: false });
    const selectedIds = this.state.refValues.map((i) => i.id);

    if (!selectedIds.includes(item.id)) {
      const refValues = [...this.state.refValues, item];
      const items = refValues.map((i) => {
        return {
          displayName: i.displayName || i.publicReference,
          id: i.id,
          isActive: i.isActive,
          ...(i.id === item.id ? item : {}),
        };
      });

      this.setState({
        refValues,
        items,
      });

      // this.props.onChange(value);
    }

    this.focusOnInput();
  }

  private onMouseDown() {
    this.setState({ mouseDown: true });
  }

  private onMouseUp() {
    this.setState({ mouseDown: false });
  }

  private onClick(id: string) {
    const item = (this.props.value || []).find((item) => item.id === id);
    if (!item) return;

    switch (this.props.type) {
      case "relation": {
        switch (item.typeOfRelation) {
          case RelationType.ContactCompany: {
            const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case RelationType.ContactPerson: {
            const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case RelationType.Employee: {
            const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case RelationType.Office: {
            const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          default: {
            return;
          }
        }
      }
      case "assignment": {
        switch (item.typeOfAssignment) {
          case AssignmentType.Acquisition: {
            const path = route(ACQUISITIONROUTES.DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case AssignmentType.AcquisitionObject: {
            const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case AssignmentType.Object: {
            const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case AssignmentType.ObjectType: {
            const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
              id,
            });
            this.props.onNavigate(path);
            return;
          }
          case AssignmentType.Project: {
            const path = route(PROJECTROUTES.DETAIL.URI, { id });
            this.props.onNavigate(path);
            return;
          }
          default: {
            return;
          }
        }
      }
      default: {
        return;
      }
    }
  }

  private onClear() {
    this.setState({ items: [], results: [], query: "" });
    this.props.onChange([]);
    if (!this.state.collapsed && !!this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  private async query(query: string) {
    try {
      this.setState({ loading: true });

      if (query.length < 2) {
        this.setState({ results: [] });
        return;
      }

      const activeFilter = this.state.searchInArchive
        ? ActiveFilter.InactiveOnly
        : ActiveFilter.ActiveOnly;

      switch (this.props.type) {
        case "relation": {
          const filterByRelationTypes = this.props.relationTypes || [];
          const results = await Relation.searchV3(
            query.trim(),
            25,
            null,
            activeFilter,
            filterByRelationTypes
          );
          this.setState({ results });
          break;
        }
        case "assignment": {
          const filterByAssignmentTypes = this.props.assignmentTypes || [];
          const results = await Assignment.search(
            query.trim(),
            25,
            [],
            true,
            true,
            activeFilter,
            filterByAssignmentTypes,
            undefined,
            AssignmentOrderByField.ActivityAndDateTimeModified
          );
          this.setState({
            results: results.map((item) => ({
              ...item,
              displayName: item.displayName || item.publicReference,
            })),
          });
          break;
        }
        default: {
          return;
        }
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  private onAddAsNewRelation() {
    this.setState({ mouseDown: false });
    if (!this.props.onAdd) return;
    this.onBlur();
    this.props.onAdd(this.state.query);
  }

  private toggleSearchInArchive() {
    const { searchInArchive: oldSearchInArchiveState, query } = this.state;
    const searchInArchive = !oldSearchInArchiveState;

    this.setState({
      searchInArchive,
      mouseDown: false,
    });
    this.focusOnInput();

    if (!!query) {
      this.query(query);
    }
  }

  private handleWindowScroll() {
    if (this.state.collapsed || !this.wrapperRef.current) return;

    const { top, height, left, width } =
      this.wrapperRef.current.getBoundingClientRect();

    this.setState({
      listStyle: {
        top: top + height,
        left,
        width,
      },
    });
  }
}
