import * as React from "react";
import {
  FC,
  memo,
  useContext,
  useMemo,
  useCallback,
  ChangeEvent,
  useRef,
  MutableRefObject,
  useEffect,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { ListFilterContext } from "../../list-filter.context";
import { SingleFilterValue, SingleFilterConfig } from "../../";
import I18n, { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import { useIntl } from "react-intl";

const styles = require("./style.scss");

type CheckboxProps = {
  filter: SingleFilterConfig;
  item: SingleFilterValue;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => void;
  onSubfilterClick: (
    ref: MutableRefObject<HTMLSpanElement>,
    value: string
  ) => void;
};

const Checkbox: FC<CheckboxProps> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ filter, item, handleInputChange, onSubfilterClick }) => {
      const subfilterAnchorRef = useRef<HTMLSpanElement>();
      const checked = !!filter.value.find(
        (filter) => filter.label === item?.value?.label
      );
      const { value, values } = isString(item.label)
        ? { value: item.label, values: undefined }
        : item.label;

      const toggleSubFilter = useMemo(() => {
        if (
          !checked ||
          !filter.subFilter ||
          !filter.subFilter[item.value.toString()]
        ) {
          return null;
        }

        return (
          <div styleName="checkbox__subfilter">
            <span
              className="as-link"
              ref={subfilterAnchorRef}
              onClick={() =>
                onSubfilterClick(
                  subfilterAnchorRef,
                  filter.subFilter[item.value.toString()]
                )
              }
            >
              <I18n value="listFiltering.specify" />
            </span>
          </div>
        );
      }, [checked, filter, item, onSubfilterClick]);

      return (
        <div styleName="checkbox__wrapper">
          <label
            styleName={classNames("checkbox", { "has-count": !!item.count })}
          >
            <div styleName="checkbox__icon">
              <Icon
                name={checked ? "check-square" : "square"}
                light={!checked}
                solid={checked}
                size={16}
                color={checked ? Colors.Primary : Colors.MediumGray}
              />
            </div>
            <input
              type="checkbox"
              checked={checked}
              onChange={(event) => handleInputChange(event, item.value)}
            />
            <div styleName="checkbox__label">
              <I18n value={value} values={values} asHtml />
              {!!item.count && <div styleName="count">{item.count}</div>}
            </div>
          </label>
          {toggleSubFilter}
        </div>
      );
    }
  )
);

type Props = {
  configKey: string;
  values: SingleFilterValue[];
  title?: Translateable;
  show?: number;
  sortByAlphabet?: boolean;
  onChange?: (updatedFilter: SingleFilterConfig) => void;
  onToggleSubfilter?: (
    ref: MutableRefObject<HTMLSpanElement>,
    value: string
  ) => void;
};

export const SearchLocation: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      configKey,
      values,
      onToggleSubfilter,
      sortByAlphabet,
      onChange,
      title,
    }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);
      const [sortedValues, setSortedValues] = useState(null);
      const intl = useIntl();

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, value: any) => {
          if (!filter) return;

          let updatedFilter = filter;
          if (event.target.checked) {
            updatedFilter = {
              ...updatedFilter,
              value: [...updatedFilter.value, value],
            };
          } else {
            updatedFilter = {
              ...updatedFilter,
              value: updatedFilter.value.filter((v) => v !== value),
            };
          }
          if (onChange) {
            onChange(updatedFilter);
          }

          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, onFilterChange]
      );

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const onSubfilterClick = useCallback(
        (ref: MutableRefObject<HTMLSpanElement>, value: string) => {
          if (!onToggleSubfilter) return;
          onToggleSubfilter(ref, value);
        },
        [onToggleSubfilter]
      );

      useEffect(() => {
        if (values && sortByAlphabet)
          setSortedValues(
            values
              .map((item) => {
                item.translatedLabel = intl.formatMessage({
                  id: item.label.toString(),
                });
                return item;
              })
              .sort((a, b) => {
                if (a.translatedLabel)
                  return a.translatedLabel.localeCompare(b.translatedLabel);
              })
          );
      }, [values]);

      if (!filter) return null;

      return (
        <div styleName="checkboxes">
          {!!label && <h2>{label}</h2>}
          {(sortedValues || values).map((item, idx) => (
            <Checkbox
              item={item}
              filter={filter}
              handleInputChange={handleInputChange}
              onSubfilterClick={onSubfilterClick}
              key={idx}
            />
          ))}
        </div>
      );
    }
  )
);
