import { MailTo } from "@haywork/modules/shared";
import { ExtendedEventNotification } from "@haywork/util";
import first from "lodash-es/first";
import * as nl2br from "nl2br";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as sanitize from "sanitize-html";

const styles = require("./body.component.scss");

interface Props {
  message: ExtendedEventNotification;
  onLinkClicked?: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class RelationBodyComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onLinkClickedHandler = this.onLinkClickedHandler.bind(this);
  }

  public render() {
    let {
      linkedMessages,
      linkedContactPersons,
      linkedObjectAssignments,
    } = this.props.message.linkedEvent;
    linkedMessages = linkedMessages || [];
    linkedContactPersons = linkedContactPersons || [];
    linkedObjectAssignments = linkedObjectAssignments || [];

    if (!linkedMessages.length && !linkedContactPersons.length) return null;

    let name, location, emailAddress, phoneNumber, mobilePhoneNumber, body;

    if (!!linkedContactPersons.length) {
      const person = first(linkedContactPersons);
      name = person.displayName;
      emailAddress = person.email;
    }

    if (!!linkedMessages.length) {
      const message = first(linkedMessages);

      if (!name) {
        name = [message.firstName, message.lastName]
          .filter((s) => !!s)
          .join(" ")
          .trim();
      }

      if (!emailAddress) {
        emailAddress = (message.emailAddress || "").trim();
      }

      if (!linkedObjectAssignments.length) {
        location = [
          message.objectStreetName,
          message.objectHouseNumber,
          message.objectLocality,
        ]
          .filter((s) => !!s)
          .join(" ")
          .trim();
      }

      phoneNumber = (message.phoneNumber || "").trim();
      mobilePhoneNumber = (message.mobilePhoneNumber || "").trim();
      body = (message.body || "").trim();
    }

    if (
      !name &&
      !location &&
      !emailAddress &&
      !phoneNumber &&
      !mobilePhoneNumber &&
      !body
    ) {
      return null;
    }

    return (
      <div styleName="body">
        {!!name && <h3>{name}</h3>}

        {(!!emailAddress || !!phoneNumber || !!mobilePhoneNumber) && (
          <div styleName="row">
            {!!emailAddress && (
              <div styleName="column">
                <i className="fa fa-envelope" />
                <MailTo
                  email={emailAddress}
                  onClick={this.onLinkClickedHandler}
                />
              </div>
            )}
            {!!mobilePhoneNumber && (
              <div styleName="column">
                <i className="fa fa-mobile" />
                <a
                  href={`tel:${mobilePhoneNumber}`}
                  onClick={this.onLinkClickedHandler}
                >
                  {mobilePhoneNumber}
                </a>
              </div>
            )}
            {!!phoneNumber && (
              <div styleName="column">
                <i className="fa fa-phone" />
                <a
                  href={`tel:${phoneNumber}`}
                  onClick={this.onLinkClickedHandler}
                >
                  {phoneNumber}
                </a>
              </div>
            )}
          </div>
        )}

        {!!body && (
          <div styleName="row">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(nl2br(body), { allowedTags: ["br"] }),
              }}
            />
          </div>
        )}

        {!!location && (
          <div styleName="row">
            <i className="fa fa-folder-open" />
            {location}
          </div>
        )}
      </div>
    );
  }

  private onLinkClickedHandler() {
    if (this.props.onLinkClicked) this.props.onLinkClicked();
  }
}
