import {
  ListingTypeOption,
  RealEstateGroupOption,
  SearchAssignmentSnapShot,
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentOverviewComponent,
  SearchAssignmentOverviewComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import {
  SearchAssignmentActions,
  SearchAssignmentsFilters,
} from "@haywork/stores/search-assignment";
import { SearchAssignmentSortedHeaderType } from "@haywork/stores/search-assignment/overview.reducer";
import {
  RouteUtil,
  SimpleLabelOriginal,
  SimpleLabelValue,
} from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  searchAssignments: SearchAssignmentSnapShot[];
  searchAssignmentsStatus: string;
  selectedFilters: SimpleLabelOriginal[];
  canLoadMore: boolean;
  searchAssignmentsFilters: SearchAssignmentsFilters;
  realEstateGroups: SimpleLabelValue[];
  matchmailPeriods: SimpleLabelValue[];
  partTypes: SimpleLabelValue[];
  bogTypes: SimpleLabelValue[];
  alvTypes: SimpleLabelValue[];
  assignmentPhases: SimpleLabelValue[];
  offerTypes: SimpleLabelValue[];
  searchAssignmentsTotal: number;
  selectedHeaderSort: SearchAssignmentSortedHeaderType;
}

interface DispatchProps {
  getSearchAssignments: (init?: boolean, take?: number) => void;
  clearAllFilters: () => void;
  clearFilter: (filter: SimpleLabelOriginal) => void;
  setSelectedheaderSort: (
    selectedHeaderSort: SearchAssignmentSortedHeaderType
  ) => void;
  setSearchAssignmentsFilters: (filters: SearchAssignmentsFilters) => void;
  goToSearchAssignment: (id: string) => void;
}

const route = RouteUtil.mapStaticRouteValues;

const mapSelectedFilters = (filters: SearchAssignmentsFilters) => {
  let selectedFilters = [];

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const values = filters[key];
      if (values && values.length > 0) {
        let mappedValues;

        switch (key) {
          case "assignmentPhases":
            mappedValues = values.map((item) => ({
              label: `${key}.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "filterByRealEstateGroups":
            mappedValues = values.map((item) => ({
              label: `realEstateGroups.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "offerTypes":
            mappedValues = values.map((item) => ({
              label: `offerTypeOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "partTypes":
            mappedValues = values.map((item) => ({
              label: `typePARTOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "bogTypes":
            mappedValues = values.map((item) => ({
              label: `typeBOGOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "alvTypes":
            mappedValues = values.map((item) => ({
              label: `typeALVOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "matchmailPeriods":
            mappedValues = values.map((item) => ({
              label: `matchMailPeriodOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          default:
            mappedValues = values.map((item) => ({
              label: item,
              value: item,
              filter: key,
            }));
            break;
        }

        selectedFilters = [...selectedFilters, ...mappedValues];
      }
    }
  }

  return selectedFilters;
};

const mapListingTypeOption = (
  array: ListingTypeOption[]
): SimpleLabelValue[] => {
  return array.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapMastertableLists = (list: any): SimpleLabelValue[] => {
  return list.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapRealestateGroups = (
  list: RealEstateGroupOption[]
): SimpleLabelValue[] => {
  return list.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => ({
  searchAssignments: state.searchAssignment.overview.searchAssignments,
  searchAssignmentsStatus:
    state.searchAssignment.overview.searchAssignmentsStatus,
  selectedFilters: mapSelectedFilters(
    state.searchAssignment.overview.searchAssignmentsFilters
  ),
  canLoadMore:
    state.searchAssignment.overview.searchAssignmentsPage <
    state.searchAssignment.overview.searchAssignmentsPageCount,
  searchAssignmentsFilters:
    state.searchAssignment.overview.searchAssignmentsFilters,
  realEstateGroups: mapRealestateGroups(
    state.main.mastertable.kolibri.realEstateGroups
  ),
  searchAssignmentsTotal:
    state.searchAssignment.overview.searchAssignmentsTotal,
  selectedHeaderSort: state.searchAssignment.overview.selectedHeaderSort,
  listingTypes: mapListingTypeOption(
    state.main.mastertable.kolibri.listingTypes
  ),
  assignmentPhases: mapMastertableLists(
    state.main.mastertable.kolibri.assignmentPhases
  ),
  offerTypes: mapMastertableLists(
    state.main.mastertable.kolibri.offerTypeOptions
  ),
  matchmailPeriods: mapMastertableLists(
    state.main.mastertable.kolibri.matchMailPeriodOptions
  ),
  partTypes: mapMastertableLists(
    state.main.mastertable.kolibri.typePARTOptions
  ),
  bogTypes: mapMastertableLists(state.main.mastertable.kolibri.typeBOGOptions),
  alvTypes: mapMastertableLists(state.main.mastertable.kolibri.typeALVOptions),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getSearchAssignments: (init?: boolean, take?: number) =>
    dispatch(SearchAssignmentThunks.getSearchAssignments(init, take)),
  clearAllFilters: () => dispatch(SearchAssignmentActions.clearAllFilters()),
  clearFilter: (filter: SimpleLabelOriginal) =>
    dispatch(SearchAssignmentActions.clearFilter({ filter })),
  setSelectedheaderSort: (
    selectedHeaderSort: SearchAssignmentSortedHeaderType
  ) =>
    dispatch(
      SearchAssignmentActions.setSelectedheaderSort({ selectedHeaderSort })
    ),
  setSearchAssignmentsFilters: (filters: SearchAssignmentsFilters) =>
    dispatch(
      SearchAssignmentActions.setSearchAssignmentsFilters({
        searchAssignmentsFilters: filters,
      })
    ),
  goToSearchAssignment: (id: string) =>
    dispatch(push(route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id }))),
});

export type SearchAssignmentOverviewContainerProps = StateProps & DispatchProps;
export const SearchAssignmentOverviewContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentOverviewComponent);
