import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { NotesActions } from "@haywork/stores/notes-v3";
import { connect, MapStateToProps } from "react-redux";
import { NotesComponent, NotesComponentProps } from "./notes";

interface StateProps {
  notesVisible: boolean;
  totalCount: number;
}
interface DispatchProps {
  toggleListVisibility: (status: boolean) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  NotesComponentProps,
  AppState
> = (state) => {
  const { notesVisible, totalCount } = state.notesV3;

  return {
    notesVisible,
    totalCount
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  NotesComponentProps
> = (dispatch) => ({
  toggleListVisibility: (status: boolean) =>
    dispatch(NotesActions.toggleListVisibility(status))
});

export type NotesContainerProps = StateProps & DispatchProps;
export const NotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesComponent);
