import { ShareType } from "@haywork/api/mail";

export * from "./account.thunk";
export * from "./folder.thunk";
export * from "./message.thunk";
export * from "./draft.thunk";
export * from "./initial.thunk";
export * from "./templates.thunk";

export interface AccountShareReference {
  id: string;
  shareType: ShareType;
}
