import {
  AssignmentSnapShot,
  AcquisitionAssignment,
  AssignmentType,
} from "@haywork/api/kolibri";

export const mapAcquisitionAssignmentToAssignmentSnapshot = (
  assignment: AcquisitionAssignment
): AssignmentSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    assignmentPhase,
    forRent,
    forSale,
    linkedApplicants,
    linkedClients,
    linkedEmployee,
    linkedNotaries,
    linkedOffice,
    linkedPropertyManagers,
    linkedVendors,
    mandateDateTime,
    realEstateGroup,
    title,
    publicReference,
  } = assignment;

  return {
    id,
    assignmentPhase,
    displayName: title || publicReference,
    forRent,
    forSale,
    hasCadastres: false,
    isActive,
    isPartOfProject: false,
    linkedApplicants,
    linkedEmployee,
    linkedNotaries,
    linkedOffice,
    linkedPropertyManagers,
    linkedVendors,
    linkedClients,
    mandateDateTime,
    publicReference,
    realEstateAgencyId,
    realEstateGroup,
    typeOfAssignment: AssignmentType.Acquisition,
    dateTimeCreated,
    dateTimeModified,
    linkedCreatedBy,
    linkedModifiedBy,
    isConfidential: false,
  };
};
