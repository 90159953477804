import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { EMPLOYEEROUTES } from "@haywork/constants";
import { EmployeeGeneralContainer, EmployeeAssignmentsContainer, EmployeeDossierContainer, EmployeeEditContainer } from "@haywork/modules/settings";
import { RouteUtil } from "@haywork/util";

const route = RouteUtil.mapStaticRouteValues;
export const EmployeeDetailRouting = ( id ) => {
  return(
    <Switch>
      <Route exact path={"/app/employees/:id"} render={() => <Redirect to={ route(EMPLOYEEROUTES.EMPLOYEE_GENERAL.URI, id)} />}/>
      <Route path={ EMPLOYEEROUTES.EMPLOYEE_GENERAL.URI } component={ EmployeeGeneralContainer }/>
      <Route path={ EMPLOYEEROUTES.EMPLOYEE_ASSIGNMENT.URI } component={ EmployeeAssignmentsContainer }/>
      <Route path={ EMPLOYEEROUTES.EMPLOYEE_DOSSIER.URI } component={ EmployeeDossierContainer }/>
      <Route path={ EMPLOYEEROUTES.EMPLOYEE_EDIT.URI } component={ EmployeeEditContainer} />
    </Switch>
  );
};
