import {
  AmenitiesOption,
  GarageTypeOption,
  IsolationTypeOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import {
  RoomLayoutGarageFormComponent,
  RoomLayoutGarageFormComponentProps,
} from "../garage.component";

interface StateProps {
  garageTypeOptions: GarageTypeOption[];
  isolationTypeOptions: IsolationTypeOption[];
  amenitiesOptions: AmenitiesOption[];
}
interface DispatchProps {}

const mapStateToProps = <StateProps, GarageComponentProps>(state: AppState) => {
  const {
    garageTypeOptions,
    isolationTypeOptions,
    amenitiesOptions,
  } = state.main.mastertable.kolibri;

  return {
    garageTypeOptions,
    isolationTypeOptions,
    amenitiesOptions,
  };
};

const mapDispatchToProps = <DispatchProps, GarageComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type GarageContainerProps = StateProps & DispatchProps;
export const GarageContainer = connect<
  StateProps,
  DispatchProps,
  RoomLayoutGarageFormComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RoomLayoutGarageFormComponent);
