import { EmailAddress } from "@haywork/api/mail";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEmailTemplateSendPreview,
  SettingsEmailTemplateSendPreviewProps
} from "./send-preview.component";

interface StateProps {}
interface DispatchProps {
  sendPreviewEmail: (
    body: string,
    to: EmailAddress[],
    subject: string
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEmailTemplateSendPreviewProps,
  AppState
> = (state) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEmailTemplateSendPreviewProps
> = (dispatch) => ({
  sendPreviewEmail: (body: string, to: EmailAddress[], subject: string) =>
    dispatch(EmailTemplatesThunks.sendPreviewEmail(body, to, subject))
});

export type SettingsEmailTemplateSendPreviewContainerProps = StateProps &
  DispatchProps;
export const SettingsEmailTemplateSendPreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEmailTemplateSendPreview);
