import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";
import { ModalPortal } from "@haywork/portals";

const styles = require("./tooltip.component.scss");

interface ToolTipComponentProps {
  visible: boolean;
  position: ClientRect;
}
interface ToolTipComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ToolTipComponent extends React.Component<
  ToolTipComponentProps,
  ToolTipComponentState
> {
  private ref: HTMLDivElement;

  public render() {
    const { styleName, style } = this.calculateTooltipStyles();

    return (
      <ModalPortal>
        <div
          styleName={styleName}
          style={style}
          ref={(ref) => (this.ref = ref)}
        >
          <ResourceText resourceKey="fieldFromExternalEntity" />
        </div>
      </ModalPortal>
    );
  }

  private calculateTooltipStyles(): {
    styleName: string;
    style: React.CSSProperties;
  } {
    if (!this.props.position) return { styleName: "tooltip", style: null };
    const { bottom, left, right, top, width, height } = this.props.position;
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;
    const { width: tWidth, height: tHeight } = this.ref.getBoundingClientRect();

    const alignBottom = bottom + tHeight + 26 < wHeight;

    const style = {
      top: alignBottom ? bottom + 10 : top - tHeight - 10,
      left: left + width / 2 - tWidth / 2,
    };

    const styleName = classNames("tooltip", {
      visible: this.props.visible,
      "bottoms-up": !alignBottom,
    });

    return {
      styleName,
      style,
    };
  }
}
