import { createSelector } from "reselect";

import { AppState } from "@haywork/stores";
import { AppClientSnapShot } from "@haywork/api/authorization";

const clientsSelector = (state: AppState) =>
  state.authorization.appClients.clients;
const cultureSelector = (state: AppState) => state.main.culture;
const categoriesSelector = (state: AppState) =>
  state.authorization.appClients.selectedClientCategories;
const remunerationCategoriesSelector = (state: AppState) =>
  state.authorization.appClients.selectedRemunerationCategories;

export const clients = createSelector(
  clientsSelector,
  cultureSelector,
  categoriesSelector,
  remunerationCategoriesSelector,
  (clients, culture, categories, remunerationCategories) => {
    let filteredClients = clients;
    if (!!categories.length) {
      filteredClients = filteredClients.filter(
        (client) => categories.indexOf(client.category) !== -1
      );
    }
    if (!!remunerationCategories.length) {
      filteredClients = filteredClients.filter(
        (client) =>
          remunerationCategories.indexOf(client.remunerationCategory) !== -1
      );
    }

    return filteredClients.map((client) => {
      let translationSnapShots = client.translationSnapShots || [];
      const translationSnapShot = translationSnapShots.find(
        (snapshot) => snapshot.languageCultureName === culture
      );

      if (!!translationSnapShot) {
        translationSnapShots = [translationSnapShot];
      }

      return {
        ...client,
        translationSnapShots
      } as AppClientSnapShot;
    });
  }
);
