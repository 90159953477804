import { AssignmentType, LinkedAssignment } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES
} from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: LinkedAssignment;
  onNavigate: (path: string) => void;
};
export const NetworkAssignmentComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ assignment, onNavigate }) => {
    const { id, typeOfAssignment, forRent, publicReference } = assignment;

    const onClickHandler = useCallback(() => {
      switch (typeOfAssignment) {
        case AssignmentType.Acquisition: {
          const path = route(ACQUISITIONROUTES.DETAIL.URI, {
            id
          });
          return onNavigate(path);
        }
        case AssignmentType.AcquisitionObject: {
          const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
            id
          });
          return onNavigate(path);
        }
        case AssignmentType.Object: {
          const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
          return onNavigate(path);
        }
        case AssignmentType.ObjectType: {
          const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
          return onNavigate(path);
        }
        case AssignmentType.Project: {
          const path = route(PROJECTROUTES.DETAIL.URI, { id });
          return onNavigate(path);
        }
        default:
          return;
      }
    }, [typeOfAssignment, id, onNavigate]);

    return (
      <div styleName="linked-relation" onClick={onClickHandler}>
        <div styleName="avatar assignment">
          <Icon name="handshake-alt" regular />
        </div>
        <div styleName="meta">
          <div styleName="name">
            <I18n value="acquisition.assignment.linkedAssignment" />
            {!!publicReference && (
              <div styleName="pill">
                <Pill label={publicReference} color={Colors.Gray} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  })
);
