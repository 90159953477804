import {
  TemplateDefinitionCategorySnapShot,
  TemplateDefinitionSnapShot
} from "@haywork/api/kolibri";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEmailTemplatesOverviewComponent,
  SettingsEmailTemplatesOverviewComponentProps
} from "../components";
import { categories, templates } from "./selectors";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";

interface StateProps {
  templates: TemplateDefinitionSnapShot[];
  categories: TemplateDefinitionCategorySnapShot[];
  status: string;
}
interface DispatchProps {
  defineNew: () => Promise<void>;
  navigate: (path: string) => void;
  deleteTemplate: (id: string) => Promise<void>;
  unDeleteTemplate: (id: string) => Promise<void>;
  getInitialTemplatesState: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEmailTemplatesOverviewComponentProps,
  AppState
> = (state) => {
  const { templatesStatus: status } = state.email.templates;

  return {
    templates: templates(state),
    categories: categories(state),
    status
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEmailTemplatesOverviewComponentProps
> = (dispatch) => ({
  defineNew: () => dispatch(EmailTemplatesThunks.defineNewTemplate()),
  navigate: (path: string) => dispatch(push(path)),
  deleteTemplate: (id: string) =>
    dispatch(EmailTemplatesThunks.deleteTemplate(id)),
  unDeleteTemplate: (id: string) =>
    dispatch(EmailTemplatesThunks.unDeleteTemplate(id)),
  getInitialTemplatesState: () =>
    dispatch(EmailThunk.Templates.getInitialTemplatesState())
});

export type SettingsEmailTemplatesOverviewContainerProps = StateProps &
  DispatchProps;
export const SettingsEmailTemplatesOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEmailTemplatesOverviewComponent);
