import { AppState } from "@haywork/stores";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

export type Dispatch<A> = ThunkDispatch<AppState, {}, Action<A>>;

interface MapDispatchToPropsFunction<TDispatchProps, TOwnProps> {
  (dispatch: Dispatch<any>, ownProps: TOwnProps): TDispatchProps;
}

export type MapDispatchToProps<TDispatchProps, TOwnProps> =
  | MapDispatchToPropsFunction<TDispatchProps, TOwnProps>
  | TDispatchProps;

export * from "./access.thunk";
export * from "./account.thunk";
export * from "./acquisitions/acquisition.thunk";
export * from "./assignment.thunk";
export * from "./authorization";
export * from "./bid.thunk";
export * from "./blob.thunk";
export * from "./brochures.thunk";
export * from "./communication-log";
export * from "./company-settings.thunk";
export * from "./dossier.thunk";
export * from "./dynamic-documents.thunk";
export * from "./editable.thunk";
export * from "./email";
export * from "./employee.thunk";
export * from "./event-center.thunk";
export * from "./financial.thunk";
export * from "./folder-trees";
export * from "./global-search.thunk";
export * from "./invoice.thunk";
export * from "./keys.thunk";
export * from "./live-data.thunk";
export * from "./main.thunk";
export * from "./mediapartners.thunk";
export * from "./message.thunk";
export * from "./new-entity.thunk";
export * from "./offices.thunk";
export * from "./phrases.thunk";
export * from "./projects";
export * from "./publications";
export * from "./real-estate-agency.thunk";
export * from "./relation-groups.thunk";
export * from "./relation.thunk";
export * from "./reminder.thunk";
export * from "./scheduler-category.thunk";
export * from "./scheduler.thunk";
export * from "./search-assignment.thunk";
export * from "./statistics.thunk";
export * from "./task.thunk";
export * from "./voip";
