import * as React from "react";
import {
  memo,
  useEffect,
  ReactNode,
  RefObject,
  useState,
  CSSProperties,
  useRef
} from "react";
import * as CSSModules from "react-css-modules";
import { ModalPortal } from "@haywork/portals";

const styles = require("./style.scss");

interface Props {
  visible: boolean;
  children: ReactNode;
  parent: RefObject<HTMLElement>;
  onClose: () => void;
}

export const DossierActionListComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, children, parent, onClose }: Props) => {
      const [styling, setSetyling] = useState<CSSProperties>(null);
      const ref = useRef(null);

      const handleClickOutside = (event: MouseEvent) => {
        if (
          (!ref.current || !ref.current.contains(event.target as Node)) &&
          (!parent.current || !parent.current.contains(event.target as Node))
        ) {
          onClose();
        }
      };

      useEffect(() => {
        window.addEventListener("resize", onClose, true);
        document.addEventListener("click", handleClickOutside, true);
        document.addEventListener("scroll", onClose, true);

        return () => {
          window.removeEventListener("resize", onClose, true);
          document.removeEventListener("click", handleClickOutside, true);
          document.removeEventListener("scroll", onClose, true);
        };
      }, []);

      useEffect(() => {
        if (!visible || !parent.current) return;
        const {
          top,
          left,
          height,
          width
        } = parent.current.getBoundingClientRect();

        setSetyling({
          top: top + height / 2,
          right: window.innerWidth - left - width / 2
        });
      }, [visible]);

      if (!visible) return null;

      return (
        <ModalPortal>
          <div styleName="action-list" style={styling} ref={ref}>
            <div styleName="inner">{children}</div>
          </div>
        </ModalPortal>
      );
    }
  )
);
