import { AppClientTranslation } from "@haywork/api/authorization";
import { PhotoBlob } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { urlToPhotoBlob } from "@haywork/util/url";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Screenshot from "./screenshot";

const styles = require("./about.component.scss");

interface Props {
  translation: AppClientTranslation;
  showLightbox: (slides: PhotoBlob[], current: number) => void;
}

export const Screenshots = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const { screenShots } = props.translation;
    if (!screenShots || !screenShots.length) return null;
    const slides = screenShots.map((screenshot) =>
      urlToPhotoBlob(screenshot.url)
    );

    return (
      <React.Fragment>
        <h2 styleName="section-title">
          <ResourceText resourceKey="appXchangeScreenshots" />
        </h2>
        <div styleName="screenshots">
          <div styleName="screenshots__wrapper">
            {screenShots.map((screenshot, idx) => (
              <Screenshot
                key={idx}
                url={screenshot.url}
                onClick={() => props.showLightbox(slides, idx)}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
);
