import { FolderCategory } from "@haywork/api/mail";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./header.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  loading: boolean;
  currentFolder: ExtendedEmailFolder;
  unread: boolean;
  term: string;
  onSetSearchValues: (term: string, folder: ExtendedEmailFolder) => void;
  onToggleUnread: (unread: boolean | undefined) => void;
}
interface State {
  dirty: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class EmailListHeaderComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      term: { value: this.props.term || "" },
    };

    this.state = {
      dirty: !!this.props.term,
    };

    this.toggleUnreadHandler = this.toggleUnreadHandler.bind(this);
    this.handleSearchChange = debounce(
      this.handleSearchChange.bind(this),
      1500
    );
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onClearClickHandler = this.onClearClickHandler.bind(this);
  }

  public render() {
    const hasSearchQuery = !!this.props.term;
    const category = value(
      this.props.currentFolder,
      "category",
      FolderCategory.All
    );

    return (
      <div styleName="header">
        <div styleName="header__section">
          <div styleName="form-row">
            <Form
              name="search-email-messages"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              form={(form) => (this.formRef = form)}
            >
              <div className="form__row">
                <div className="form__group stretch">
                  <div className="column">
                    <Input.Text
                      name="term"
                      maxLength={50}
                      allowSubmitOnEnter={true}
                      onChange={this.handleSearchChange}
                      data-cy="CY-searchInEmail"
                      placeholder="searchInEmails"
                      fireOnBlur={false}
                    />
                  </div>
                  <div className="column fit">
                    {!this.state.dirty ? (
                      <button type="submit" disabled={this.props.loading}>
                        <i className="fal fa-search" />
                      </button>
                    ) : (
                      <button type="button" onClick={this.onClearClickHandler}>
                        <i className="fal fa-times" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div styleName="header__section">
          <div
            styleName={classNames("filters", {
              "has-search-query": hasSearchQuery,
            })}
          >
            <div styleName="left">
              <div
                styleName={classNames("filter-button", {
                  active: !this.props.unread,
                  disabled: category === FolderCategory.Drafts,
                })}
                onClick={() => this.toggleUnreadHandler(undefined)}
              >
                <ResourceText resourceKey="all" />
              </div>
              <div
                styleName={classNames("filter-button", {
                  active: !!this.props.unread,
                  disabled: category === FolderCategory.Drafts,
                })}
                onClick={() => this.toggleUnreadHandler(true)}
              >
                <ResourceText resourceKey="unread" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private toggleUnreadHandler(unread: boolean | undefined) {
    if (
      !!this.props.term ||
      this.props.unread === unread ||
      value(this.props.currentFolder, "category") === FolderCategory.Drafts
    )
      return;
    this.props.onToggleUnread(unread);
  }

  private handleSearchChange(value: string) {
    if (!this.formRef || this.props.loading) return;

    if (!!value && !!value.trim()) {
      this.props.onSetSearchValues(value, this.props.currentFolder);
      this.setState({
        dirty: true,
      });
    } else {
      this.onClearClickHandler();
    }
  }

  private onSubmitHandler(values: FormReturnValue) {
    const { term } = values;

    if (!!term && !!term.trim()) {
      this.props.onSetSearchValues(term, this.props.currentFolder);
      this.setState({
        dirty: true,
      });
    }
  }

  private onClearClickHandler() {
    this.formRef.update({
      term: "",
    });

    this.setState({
      dirty: false,
    });

    this.props.onSetSearchValues(null, this.props.currentFolder);
  }
}
