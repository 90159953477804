import { LinkedMessage } from "@haywork/api/event-center";
import Button from "@haywork/components/ui/button";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import CSSModules = require("react-css-modules");

const styles = require("./style.scss");

type Props = {
  message: LinkedMessage;
};
export const Message: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ message }) => {
    const windowOpen = useCallback(
      (url) => {
        if (!url.match(/^https?:\/\//i)) {
          url = "https://" + url;
        }
        return url;
      },
      [message]
    );

    return message ? (
      <div styleName="message">
        <div styleName="message__meta">
          <h2 styleName="title">{message.displayName}</h2>
          <div styleName="body">{message.body}</div>
        </div>
        {message && message.infoUrl && (
          <div styleName="message__actions">
            <Button
              label="widgets.systemmessage.watchnow"
              href={windowOpen(message.infoUrl)}
            />
          </div>
        )}
      </div>
    ) : null;
  })
);
