import {
  ConstructionOptionOption,
  ObjectAssignment,
  AcquisitionObjectAssignment
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  constructionOptionOptions: ConstructionOptionOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogLotComponent extends React.Component<Props> {
  private formControls: FormControls;
  private buildingCapacityFormControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      constructionOptions: {
        value: value(objectAssignment, "constructionOptions", [])
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
      inUnitsFrom: { value: value(objectAssignment, "inUnitsFrom.area") },
      buildingCapacity: { value: value(objectAssignment, "buildingCapacity") }
    };

    this.buildingCapacityFormControls = {
      area: { value: "" },
      height: { value: "" }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update({
        constructionOptions: value(objectAssignment, "constructionOptions", []),
        parcelSurface: value(objectAssignment, "parcelSurface.area"),
        inUnitsFrom: value(objectAssignment, "inUnitsFrom.area"),
        buildingCapacity: value(objectAssignment, "buildingCapacity")
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="lot"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <label htmlFor="constructionOptions">
            <ResourceText resourceKey="constructionOptions" />
          </label>
          <Input.Multi
            name="constructionOptions"
            values={this.props.constructionOptionOptions}
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="inUnitsFrom">
                <ResourceText resourceKey="inUnitsFrom" />
              </label>
              <div className="input__helper">
                <Input.Number name="inUnitsFrom" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Input.Object
          name="buildingCapacity"
          formControls={this.buildingCapacityFormControls}
        >
          <div className="form__row">
            <div className="form__group">
              <div className="column" styleName="size-field">
                <label htmlFor="area">
                  <ResourceText resourceKey="maximumBuilding" />
                </label>
                <div className="input__helper">
                  <Input.Number name="area" step={50} pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column" styleName="size-field">
                <label htmlFor="height">
                  <ResourceText resourceKey="terrainBuildingVolumeHeight" />
                </label>
                <div className="input__helper">
                  <Input.Number name="height" step={50} pretty />
                  <div className="post">
                    <ResourceText resourceKey="centimeterShort" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Input.Object>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const fields: any = {
      constructionOptions: values.constructionOptions,
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface
      },
      inUnitsFrom: {
        ...objectAssignment.inUnitsFrom,
        area: values.inUnitsFrom
      },
      buildingCapacity: {
        ...objectAssignment.buildingCapacity,
        area: get(values, "buildingCapacity.area"),
        height: get(values, "buildingCapacity.height")
      }
    };

    this.props.onChange(fields);
  }
}
