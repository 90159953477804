import * as React from "react";
import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  SearchAssignmentDetailGeneralContainer,
  SearchAssignmentDetailLinkedAssignmentsContainer,
  SearchAssignmentDetailMatchmailContainer,
  SearchAssignmentDetailSearchersContainer,
  SearchAssignmentEditContainer,
} from "@haywork/modules/search-assignment";
import { EmailAssignment } from "@haywork/util/email-v2";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  id: string;
  clearSelectedAssignments: () => void;
  selectedAssignmentsIds: string[];
  onToggleSelected: (assignment: EmailAssignment) => void;
};

export const SearchAssignmentDetailRouting: FC<Props> = ({
  id,
  selectedAssignmentsIds,
  clearSelectedAssignments,
  onToggleSelected,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={SEARCHASSIGNMENTROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(SEARCHASSIGNMENTROUTES.GENERAL.URI, { id })} />
        )}
      />
      <Route
        exact
        path={SEARCHASSIGNMENTROUTES.GENERAL.URI}
        component={SearchAssignmentDetailGeneralContainer}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.SEARCHERS.URI}
        component={SearchAssignmentDetailSearchersContainer}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.LINKED_ASSIGNMENTS.URI}
        render={() => (
          <SearchAssignmentDetailLinkedAssignmentsContainer
            clearSelectedAssignments={clearSelectedAssignments}
            selectedAssignmentsIds={selectedAssignmentsIds}
            onToggleSelected={onToggleSelected}
            inEditMode={false}
          />
        )}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.MATCHMAIL.URI}
        component={SearchAssignmentDetailMatchmailContainer}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.EDIT.URI}
        component={SearchAssignmentEditContainer}
      />
    </Switch>
  );
};
