import { Dispatch } from ".";
import { AppState } from "@haywork/stores";
import { ParseRequest } from "@haywork/services";
import sortBy from "lodash-es/sortBy";
import uniqBy from "lodash-es/uniqBy";
import { KeysClient, AvailableFilter } from "@haywork/api/kolibri";
import head from "lodash-es/head";

const parseRequest = new ParseRequest();

export type ExtendedKey = {
  id: number;
  isAvailable: boolean;
};

const getKeys = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Keys = new KeysClient(host);

    try {
      const availableKeys = await parseRequest.response(
        Keys.search(
          {
            availableFilter: AvailableFilter.Available,
            skip: 0,
            take: 999
          },
          realEstateAgencyId
        ).then((response) =>
          response.results.map(
            (result) => ({ id: result, isAvailable: true } as ExtendedKey)
          )
        )
      );

      const usedKeys = await parseRequest.response(
        Keys.search(
          {
            availableFilter: AvailableFilter.InUse,
            skip: 0,
            take: 999
          },
          realEstateAgencyId
        ).then((response) =>
          response.results.map(
            (result) => ({ id: result, isAvailable: false } as ExtendedKey)
          )
        )
      );

      let combinedKeys = [...availableKeys, ...usedKeys];
      combinedKeys = sortBy(combinedKeys, (key) => key.id);

      return uniqBy(combinedKeys, (key) => key.id);
    } catch (error) {
      throw error;
    }
  };
};

const getFirstAvailableKey = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new KeysClient(host);

    try {
      const keys = await parseRequest.response(
        client
          .search(
            {
              availableFilter: AvailableFilter.Available,
              skip: 0,
              take: 1
            },
            realEstateAgencyId
          )
          .then((response) => response.results)
      );

      return head(keys);
    } catch (error) {
      throw error;
    }
  };
};

export const KeysThunk = {
  getKeys,
  getFirstAvailableKey
};
