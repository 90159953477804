import * as React from "react";
import { FC, memo } from "react";
import { PresenceWrapperContainerProps } from "./presence-wrapper.container";
import { RootEntityType } from "@haywork/api/event-center";
import BlockedView from "./components/blocked-view";

export type PresenceWrapperProps = {
  entityId: string;
  entityType: RootEntityType | null;
  inEditMode: boolean;
  onClose: () => void;
};
type Props = PresenceWrapperProps & PresenceWrapperContainerProps;

export const PresenceWrapperComponent: FC<Props> = memo(
  ({ children, blockDoubleView, entityType, features, onClose }) => {
    const featureEnabled = (features || []).includes("PRESENCE_V2");

    return (
      <>
        {featureEnabled && blockDoubleView ? (
          <BlockedView entityType={entityType} onClose={onClose}>
            {children}
          </BlockedView>
        ) : (
          children
        )}
      </>
    );
  }
);
