import { RootEntityType } from "@haywork/api/event-center";
import {
  AcquisitionAssignment,
  AssignmentSnapShot,
  ObjectAssignment,
  RelationSnapShot,
  Task,
  TaskCategoryOption,
  TaskStatus,
} from "@haywork/api/kolibri";
import { TASKROUTES } from "@haywork/constants";
import {
  AcquisitionThunk,
  AssignmentThunks,
  Dispatch,
  TaskThunks,
} from "@haywork/middleware/thunk";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { TaskComponent, TaskComponentProps } from "@haywork/modules/task";
import {
  AppState,
  EditableActions,
  EditableItem,
  TaskActions,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  task: Task;
  taskStatus: string;
  categories: TaskCategoryOption[];
  employees: RelationSnapShot[];
  realEstateAgencyId: string;
  host: string;
}

interface DispatchProps {
  save: (task: Task, path: string) => void;
  toggleTaskStatus: (
    taskId: string,
    status: TaskStatus,
    refresh: boolean
  ) => Promise<void>;
  updateTask: (componentState: Task, path: string) => void;
  saveAndCloseTask: () => void;
  navigate: (url: string) => void;
  getLinkedAssignment: (id: string) => Promise<ObjectAssignment>;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
  deleteFromDetail: (id: string) => Promise<void>;
  reloadTask: (editable: EditableItem) => Promise<void>;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  getLinkedAcquisition: (id: string) => Promise<AcquisitionAssignment>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  TaskComponentProps,
  AppState
> = (state: AppState) => ({
  task: state.editable.currentComponentState,
  taskStatus: state.task.taskStatus,
  categories: state.main.mastertable.kolibri.taskCategories,
  employees: state.account.employees,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  host: state.appSettings.host,
  path: RouteUtil.mapStaticRouteValues(TASKROUTES.TASK.URI, {
    id: state.task.task.id,
  }),
});

const mapDispatchToProps = <DispatchProps, TaskComponentProps>(
  dispatch: Dispatch<any>
) => ({
  save: (task: Task, path: string) => dispatch(TaskThunks.save(task, path)),
  toggleTaskStatus: (taskId: string, status: TaskStatus, refresh: boolean) =>
    dispatch(TaskThunks.updateStatus(taskId, status, refresh)),
  updateTask: (componentState: Task, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  saveAndCloseTask: () => dispatch(TaskThunks.saveAndCloseTask()),
  navigate: (url: string) => dispatch(push(url)),
  getLinkedAssignment: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
  archive: (id: string) => dispatch(TaskThunks.archive(id)),
  unArchive: (id: string) => dispatch(TaskThunks.unArchive(id)),
  deleteFromDetail: (id: string) => dispatch(TaskThunks.deleteFromDetail(id)),
  reloadTask: (editable: EditableItem) =>
    dispatch(TaskThunks.reloadTask(editable)),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  getLinkedAcquisition: (id: string) =>
    dispatch(AcquisitionThunk.getLinkedAcquisition(id)),
});

export type TaskContainerProps = StateProps & DispatchProps & EditableHocProps;
export const TaskContainer = editable(
  connect<StateProps, DispatchProps, TaskComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(TaskComponent),
  {
    entityType: RootEntityType.Task,
    icon: "tasks",
    thunk: TaskThunks.getTask,
    status: "task.currentTaskStatus",
    statePath: "task.task",
    action: TaskActions.setCurrentEditableTask,
    route: TASKROUTES.TASK.URI,
    confirm: {
      title: { key: "saveTaskConfirmTitle" },
      body: { key: "saveTaskConfirmBody" },
    },
  }
);
