import { MergeFieldCategory, TemplateDefinition } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import {
  EMAILSIGNATURESROUTES,
  EXTERNALROUTES,
  REQUEST,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { EditableHocProps } from "@haywork/modules/editable";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { Editor, EditorType } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FileUploadObject, RouteUtil, UploadUtil } from "@haywork/util";
import first from "lodash-es/first";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { SettingsEmailSignatureEditContainerProps } from "../../containers";
import { PreviewModal } from "../preview";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsEmailSignatureEditComponentProps {}
interface State {
  previewVisible: boolean;
  mergedTemplate: TemplateDefinition;
}
type Props = SettingsEmailSignatureEditComponentProps &
  SettingsEmailSignatureEditContainerProps &
  EditableHocProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailSignatureEditComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  private path: string;

  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      mergedTemplate: null,
    };

    this.formControls = {
      name: {
        value: get(this.props.template, "name", ""),
        validators: [Validators.required()],
      },
    };

    this.path = route(EMAILSIGNATURESROUTES.DETAIL.URI, {
      id: this.props.template.id,
    });

    this.onEditorChange = this.onEditorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAttachmentChange = this.onAttachmentChange.bind(this);
    this.showTemplatePreview = this.showTemplatePreview.bind(this);
    this.onPreviewClose = this.onPreviewClose.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveAndCloseSignature(nextProps.template);
    }
  }

  public componentDidMount() {
    const name = get(this.props.template, "name");
    if (!!name) this.props.setTabTitle(name);
  }

  public render() {
    if (!this.props.template) return null;

    const { mergeTemplates } = this.props.template;
    const initialValue =
      !!mergeTemplates && !!mergeTemplates.length
        ? first(mergeTemplates).value || ""
        : "";
    const loading = this.props.status === REQUEST.PENDING;

    return (
      <div styleName="signature">
        <PageHeader
          title={this.props.template.name || "emailSignatures.defaultTitle"}
          subTitle="emailSignatures"
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={() => this.form.submit()}
                disabled={loading}
              />
              <Button
                label="emailSignature.TemplatePreview"
                icon={
                  <Icon
                    name="eye"
                    size={18}
                    light
                    color={loading ? Colors.Gray : Colors.White}
                  />
                }
                category="primary"
                onClick={this.showTemplatePreview}
                disabled={loading}
              />
            </>
          }
        />

        <div className="alert alert-info">
          <i className="fal fa-fw fa-info-circle" />
          <I18n
            value="emailSignatureHint"
            values={{ url: EXTERNALROUTES.EMAIL_SIGNATURES_HELPDESK.URI }}
            asHtml
          />
        </div>

        <div styleName="signature__body">
          {this.props.status === REQUEST.PENDING && (
            <Ui.Loaders.Fullscreen mask={true} />
          )}

          <div styleName="form-fields">
            <Form
              name="signature"
              formControls={this.formControls}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              form={(form) => (this.form = form)}
            >
              <div className="form__row">
                <label htmlFor="name">
                  <I18n value="editEmailSignatureLabel.name" />
                </label>
                <Input.Text
                  name="name"
                  placeholder="editEmailSignaturePlaceholder.name"
                  data-cy="CY-signatureTitle"
                />
              </div>
            </Form>
          </div>
          <div styleName="editor">
            <Editor
              initialValue={initialValue}
              uploadUrl={this.props.uploadUrl}
              type={EditorType.CreateTemplate}
              onChange={this.onEditorChange}
              onAttachmentChange={this.onAttachmentChange}
              attachments={this.props.template.attachmentBlobs}
              mergefieldCategoryWhitelist={[
                MergeFieldCategory.Company,
                MergeFieldCategory.Employee,
                MergeFieldCategory.Office,
              ]}
            />
          </div>
        </div>

        <PreviewModal
          visible={this.state.previewVisible}
          signature={this.state.mergedTemplate}
          onClose={this.onPreviewClose}
        />
      </div>
    );
  }

  private onEditorChange(content: string) {
    const { mergeTemplates } = this.props.template;
    let template =
      !!mergeTemplates && !!mergeTemplates.length
        ? first(mergeTemplates)
        : {
            id: uuid(),
            key: "signature",
            value: "",
            culture: this.props.culture,
          };

    template = {
      ...template,
      value: content,
    };

    const cache: TemplateDefinition = {
      ...this.props.template,
      mergeTemplates: [template],
    };

    this.props.updateCache(cache, this.path);
  }

  private onAttachmentChange(attachments: FileUploadObject[]) {
    const attachmentBlobs = !attachments.length
      ? undefined
      : attachments.map((attachment) =>
          UploadUtil.staticResponseToTemplateDefintionAttachmentBlob(
            attachment.response
          )
        );

    const cache: TemplateDefinition = {
      ...this.props.template,
      attachmentBlobs,
    };

    this.props.updateCache(cache, this.path);
  }

  private onChange(values: FormReturnValue) {
    const cache: TemplateDefinition = {
      ...this.props.template,
      name: values.name,
    };

    this.props.updateCache(cache, this.path);
  }

  private onSubmit() {
    this.props.saveTemplate(this.props.template);
  }

  private async showTemplatePreview() {
    try {
      const mergedTemplate = await this.props.getSignaturePreview(
        this.props.template
      );

      this.setState({
        previewVisible: true,
        mergedTemplate,
      });
    } catch (error) {
      throw error;
    }
  }

  private onPreviewClose() {
    this.setState({ previewVisible: false });
  }
}
