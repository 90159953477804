import {
  CountryOption,
  EmailAddressTypeOption,
  Employee,
  PhoneNumberTypeOption,
} from "@haywork/api/kolibri";
import { Dispatch, EmployeeThunk } from "@haywork/middleware";
import { EmployeeGeneralComponentProps } from "@haywork/modules/settings";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { EmployeeGeneralComponent } from "../components/general/index";

interface StateProps {
  selectedEmployee?: Employee;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
}

interface DispatchProps {
  getSelectedEmployee: (employeeId: string) => void;
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EmployeeGeneralComponentProps,
  AppState
> = (state: AppState) => ({
  selectedEmployee: state.employee.selectedEmployee,
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  countries: state.main.mastertable.kolibri.countries,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getSelectedEmployee: (employeeId: string) =>
    dispatch(EmployeeThunk.updateSelectedEmployee(employeeId)),
  navigate: (path: string) => dispatch(push(path)),
});

export type EmployeeGeneralContainerProps = StateProps & DispatchProps;
export const EmployeeGeneralContainer = connect<
  StateProps,
  DispatchProps,
  EmployeeGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeGeneralComponent);
