import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";
import { AppClient, WidgetEntityType } from "@haywork/api/authorization";
import uniqBy from "lodash-es/uniqBy";
import { WidgetEntityLocation } from "@haywork/api/authorization";
import classNames from "classnames";
import { createSelector } from "reselect";

const styles = require("./about.component.scss");

interface Props {
  client: AppClient;
}
interface IntegrationProps {
  location: string;
}

const Integration = CSSModules(styles, { allowMultiple: true })(
  ({ location }: IntegrationProps) => {
    let icon = "";
    let styleName = location;
    switch (true) {
      case location === WidgetEntityLocation.Dashboard.toString(): {
        icon = "fa-tachometer-alt";
        break;
      }
      case location === WidgetEntityLocation.Dossier.toString(): {
        icon = "fa-archive";
        break;
      }
      case location === WidgetEntityLocation.Network.toString(): {
        icon = "fa-users";
        break;
      }
      case location.includes(WidgetEntityLocation.Sidebar.toString()): {
        icon = "fa-columns";
        break;
      }
      case location === WidgetEntityLocation.Timeline.toString(): {
        icon = "fa-align-left";
        break;
      }
      case location === WidgetEntityLocation.Menu.toString(): {
        icon = "fa-rocket";
        break;
      }
      default:
        icon = "fa-folder-open";
        styleName = "Unknown";
        break;
    }

    return (
      <div styleName="integration" key={location}>
        <div styleName="integration__title">
          <i className={`fal fa-fw ${icon}`} />
          <ResourceText resourceKey={`appXchangeIntegration.${location}`} />
        </div>
        <div styleName={classNames("integration__background", styleName)} />
      </div>
    );
  }
);

const widgetLocations = createSelector(
  (client: AppClient) => client.linkedWidgets || [],
  (linkedWidgets) => {
    const dirtyLocations = [];
    linkedWidgets.map((widget) => {
      (widget.entityTypes || []).map((entity) =>
        dirtyLocations.push({ entity, location: widget.location })
      );
    });

    const filteredDirtyLocations = dirtyLocations.filter((location) => {
      return (
        ![WidgetEntityLocation.Sidebar, WidgetEntityLocation.Unknown].includes(
          location.location
        ) ||
        (location.location === WidgetEntityLocation.Sidebar &&
          [
            WidgetEntityType.ContactPerson,
            WidgetEntityType.ContactCompany,
            WidgetEntityType.ObjectAssignment,
            WidgetEntityType.ProjectAssignment,
            WidgetEntityType.ObjectTypeAssignment,
            WidgetEntityType.SearchAssignment,
            WidgetEntityType.AcquisitionAssignment,
          ].includes(location.entity)) ||
        (location.location === WidgetEntityLocation.Menu &&
          [
            WidgetEntityType.ContactPerson,
            WidgetEntityType.ContactCompany,
            WidgetEntityType.ObjectAssignment,
            WidgetEntityType.ProjectAssignment,
            WidgetEntityType.ObjectTypeAssignment,
            WidgetEntityType.SearchAssignment,
            WidgetEntityType.AcquisitionAssignment,
          ].includes(location.entity))
      );
    });

    return uniqBy(
      filteredDirtyLocations.map((dirtyLocation) =>
        [WidgetEntityLocation.Sidebar, WidgetEntityLocation.Menu].includes(
          dirtyLocation.location
        )
          ? dirtyLocation.entity.toString() + dirtyLocation.location.toString()
          : dirtyLocation.location.toString()
      ),
      (location) => location
    );
  }
);

export const Integrations = CSSModules(styles, { allowMultiple: true })(
  ({ client }: Props) => {
    if (!client.linkedWidgets || !client.linkedWidgets.length) return null;
    const locations = widgetLocations(client);

    return (
      <React.Fragment>
        <h2 styleName="section-title">
          <ResourceText resourceKey="appXchangeIntegrations" />
        </h2>
        <div styleName="integrations">
          <div styleName="integrations__wrapper">
            {locations.map((location, idx) => (
              <Integration location={location} key={location + idx} />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
);
