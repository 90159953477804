import * as ActionType from "./office.types";
import { RelationSnapShot, Office } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import { OfficeReturn } from "@haywork/stores";

const setFetchOfficeOverviewState = (officeOverviewState: string) => ({
  type: REDUX.OFFICES.SET_FETCH_OFFICE_OVERVIEW_STATE,
  officeOverviewState
});

const setOffices = (payload: ActionType.OfficeResponse) => ({
  type: REDUX.OFFICES.SET_OFFICES,
  ...payload
});
const appendOffice = (payload: ActionType.OfficeResponse) => ({
  type: REDUX.OFFICES.APPEND_OFFICES,
  ...payload
});

const setInitialFetchOfficeOverviewState = (officeOverviewState: string) => ({
  type: REDUX.OFFICES.SET_INITIAL_FETCH_OFFICE_OVERVIEW_STATE,
  officeOverviewState
});

const setOffice = (payload: ActionType.SingleOfficeResponse) => ({
  type: REDUX.OFFICES.SET_OFFICE,
  ...payload
});

const setOfficeDirectly = (office: Office) => ({
  type: REDUX.OFFICES.SET_OFFICE,
  office
});

const setFetchOfficeState = (officeState?: string) => ({
  type: REDUX.OFFICES.SET_FETCH_OFFICES_STATE,
  officeState
});

const setOfficeID = (id: string) => ({
  type: REDUX.OFFICES.SET_OFFICE_ID,
  id
});

const setOfficeAssignmentState = (officeAssignmentsState?: string) => ({
  type: REDUX.OFFICES.SET_OFFICE_ASSIGNMENT_STATE,
  officeAssignmentsState
});

const searchAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.OFFICES.SEARCH_OFFICE_ASSIGNMENTS,
  ...payload
});

const appendAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.OFFICES.APPEND_OFFICE_ASSIGNMENTS,
  ...payload
});

const clearOffices = () => ({
  type: REDUX.OFFICES.CLEAR_OFFICES
});

const searchOffice = (payload: ActionType.SingleOfficeResponse) => ({
  type: REDUX.OFFICES.SEARCH_OFFICE,
  ...payload
});
const setSaveOfficeInfoState = (saveOfficeInfoState: string) => ({
  type: REDUX.OFFICES.SET_SAVE_OFFICE_INFO_STATE,
  saveOfficeInfoState
});

const setAddressSearchState = (saveOfficeAddressInfoState: string) => ({
  type: REDUX.OFFICES.SET_OFFICE_ADDRESS_SEARCH_STATE,
  saveOfficeAddressInfoState
});

const setOfficeEmployeeInitialOverviewState = (officeEmployeeInitialOverviewState?: string) => ({
  type: REDUX.OFFICES.SET_INITAL_OFFICE_EMPLOYEE_OVERVIEW_STATE,
  officeEmployeeInitialOverviewState
});

const clearEmployeeList = (office: Office) => ({
  type: REDUX.OFFICES.CLEAR_EMPLOYEE_LIST,
  office
});

const setOfficeEmployees = (payload: ActionType.OfficeEmployeeList) => ({
  type: REDUX.OFFICES.SET_EMPLOYEE_LIST,
  ...payload
});

const addSpokenLanguage = (payload: ActionType.OfficeAddSpokenLanguage) => ({
  type: REDUX.OFFICES.ADD_SPOKEN_LANGUAGE,
  ...payload
});

const resetSearchState = () => ({
  type: REDUX.OFFICES.RESET_SEARCH_STATE
});

const setOfficeAssignmentInitialState = (officeAssignmentInitialState?: string) => ({
  type: REDUX.OFFICES.SET_OFFICE_ASSIGNMENT_INITIAL_STATE,
  officeAssignmentInitialState
});

const clearAssignmentList = () => ({
  type: REDUX.OFFICES.CLEAR_ASSIGNMENT_LIST
});

const archiveOffice = (office: RelationSnapShot) => ({
  type: REDUX.OFFICES.ARCHIVE_OFFICE,
  office
});

const unArchiveOffice = () => ({
  type: REDUX.OFFICES.UN_ARCHIVE_OFFICE
});

const removeOfficeFromList = () => ({
  type: REDUX.OFFICES.REMOVE_OFFICE_FROM_LIST
});

const archiveOfficeEmployee = (payload: ActionType.ArchiveOfficeEmployee) => ({
  type: REDUX.OFFICES.ARCHIVE_OFFICE_EMPLOYEE,
  ...payload
});

const setTotalOffices = (totalOffices: number) => ({
  type: REDUX.OFFICES.SET_TOTAL_OFFICES,
  totalOffices
});

const setOfficeEmployeeSaveState = (officeEmployeeSaveState: string) => ({
  type: REDUX.OFFICES.SET_OFFICE_EMPLOYEE_SAVE_STATE,
  officeEmployeeSaveState
});

export const OfficeActions = {
  setFetchOfficeOverviewState,
  setOffices,
  appendOffice,
  setInitialFetchOfficeOverviewState,
  setOffice,
  setFetchOfficeState,
  setOfficeID,
  setOfficeDirectly,
  setOfficeAssignmentState,
  searchAssignments,
  appendAssignments,
  clearOffices,
  searchOffice,
  setSaveOfficeInfoState,
  setAddressSearchState,
  setOfficeEmployeeInitialOverviewState,
  clearEmployeeList,
  setOfficeEmployees,
  addSpokenLanguage,
  resetSearchState,
  setOfficeAssignmentInitialState,
  clearAssignmentList,
  archiveOffice,
  unArchiveOffice,
  removeOfficeFromList,
  archiveOfficeEmployee,
  setTotalOffices,
  setOfficeEmployeeSaveState
};
