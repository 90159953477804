import { RootEntityType } from "@haywork/api/event-center";
import {
  ContactPerson,
  ContactPersonPartner,
  CountryOption,
  EmailAddress,
  EmailAddressType,
  Gender,
  LinkedRelationGroup,
  PhoneNumber,
  PhoneNumberType,
  RelationGroupSnapShot,
  RelationSnapShot,
  RelationType,
  SocialMediaType,
} from "@haywork/api/kolibri";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  DuplicateModalState,
  initialDuplicateModalState,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import ExternalChanges from "@haywork/components/external-changes";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import { RELATIONROUTES, REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes from "@haywork/modules/notes-v3";
import { ContactPersonEditWrapperContainerProps } from "@haywork/modules/relation/containers/contact-person-edit.container";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  FormControlUtil,
  RelationUtil,
  RouteUtil,
  StringUtil,
} from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import head from "lodash-es/head";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

export interface ContactPersonEditComponentProps {}
interface State {
  gender: Gender;
  salutation: string;
  isRelationGroupLoading: boolean;
  relationGroupsThatCantBeDeleted: LinkedRelationGroup[];
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  showFutureAddress: boolean;
  confirmSaveAndClose: boolean;
  duplicateModalState: DuplicateModalState;
  newRelation: {
    relation: ContactPerson;
    path: string;
  };
}
type Props = ContactPersonEditComponentProps &
  ContactPersonEditWrapperContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ContactPersonEditComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onAddManualAddress = this.onAddManualAddress.bind(this);
    this.getRelationGroupsThatCantGetDeleted =
      this.getRelationGroupsThatCantGetDeleted.bind(this);
    this.mapRelationGroups = this.mapRelationGroups.bind(this);
    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.toggleFutureAddress = this.toggleFutureAddress.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);
    this.onRelationAddHandler = this.onRelationAddHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);

    const person = this.props.contactPerson;
    const {
      gender,
      salutation,
      futureAddress,
      futureAddressActivationDateTime,
      firstName,
    } = person;
    let { nickname } = person;
    nickname = nickname || "";

    this.state = {
      gender: gender ? gender : Gender.Unknown,
      salutation,
      isRelationGroupLoading: false,
      relationGroupsThatCantBeDeleted:
        this.getRelationGroupsThatCantGetDeleted(),
      newEntityVisible: false,
      newEntityOptions: null,
      showFutureAddress:
        !!futureAddress &&
        (!futureAddressActivationDateTime ||
          moment().isBefore(moment(futureAddressActivationDateTime))),
      confirmSaveAndClose: false,
      duplicateModalState: initialDuplicateModalState,
      newRelation: null,
    };

    this.formControls = {
      gender: { value: gender },
      title: { value: value(person, "title") },
      nickName: {
        value: nickname,
        onChange: (ref, get) => {
          const firstName = get("firstName");
          if (!firstName.value && ref.value) {
            return {
              firstName: ref.value,
              nickName: StringUtil.capitalize(ref.value),
              nameLetters: this.formatNameLetters(ref.value),
            };
          } else if (ref.value) {
            return { nickName: StringUtil.capitalize(ref.value) };
          }
        },
      },
      middleName: { value: value(person, "middleName") },
      lastName: {
        value: value(person, "lastName"),
        onChange: (ref) => {
          if (ref.value) {
            return { lastName: StringUtil.capitalize(ref.value) };
          }
        },
        validators: [Validators.required()],
      },
      phoneNumbers: { value: value(person, "phoneNumbers") },
      emailAddresses: { value: value(person, "emailAddresses") },

      visitAddress: { value: value(person, "address") },
      postalAddress: {
        value: person.postalAddressDiffersVisitAddress
          ? value(person, "postalAddress")
          : "",
      },
      futureAddress: {
        value: value(person, "futureAddress"),
      },
      futureAddressActivationDateTime: {
        value: value(person, "futureAddressActivationDateTime"),
      },

      useFormalAppelation: {
        value: person.useFormalAppelation ? true : false,
        onChange: (ref, get) => {
          this.setState({
            salutation: RelationUtil.getFormalAppelation(
              ref.value,
              get("gender").value,
              get("nickName").value,
              get("middleName").value,
              get("lastName").value
            ),
          });
        },
      },
      salutation: { value: value(person, "salutation") },
      nameLetters: { value: value(person, "nameLetters") },
      firstName: {
        value: value(person, "firstName"),
        onChange: (ref) => {
          return { nameLetters: this.formatNameLetters(ref.value) };
        },
      },
      dateOfBirth: { value: value(person, "dateOfBirth") },
      cityOfBirth: { value: value(person, "cityOfBirth") },
      countryIso2OfBirth: {
        value: person.countryIso2OfBirth ? person.countryIso2OfBirth : "NL",
      },

      civilState: { value: value(person, "civilState") },
      linkedPartner: { value: person.linkedPartner },
      linkedRelationGroups: {
        value: person.linkedRelationGroups ? person.linkedRelationGroups : [],
      },
      keepAddressesInSync: {
        value: person.linkedPartner
          ? person.linkedPartner.keepAddressesInSyncWithPartner
          : false,
      },
      identificationCardType: {
        value: value(person, "identificationCard.type"),
      },
      identificationCardNumber: {
        value: value(person, "identificationCard.number"),
      },
      socialSecurityNumber: { value: value(person, "socialSecurityNumber") },
      identificationCardValidUntilDate: {
        value: value(person, "identificationCard.validUntilDate"),
      },
      bankAccountNumber: {
        value: value(person, "bankAccount.number"),
        validators: [Validators.iban()],
      },
      socialMedia: { value: value(person, "socialMedia") },
    };

    this.phoneNumbersFormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Mobile },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Home },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.form &&
      get(prevProps.contactPerson, "dateTimeModified") !==
        get(this.props.contactPerson, "dateTimeModified")
    ) {
      const person = this.props.contactPerson;
      const {
        gender,
        salutation,
        futureAddress,
        futureAddressActivationDateTime,
        firstName,
      } = person;
      let { nickname } = person;
      nickname = nickname || firstName || "";

      this.setState({
        gender: gender ? gender : Gender.Unknown,
        salutation,
        relationGroupsThatCantBeDeleted:
          this.getRelationGroupsThatCantGetDeleted(),
        showFutureAddress:
          !!futureAddress &&
          (!futureAddressActivationDateTime ||
            moment().isBefore(moment(futureAddressActivationDateTime))),
      });

      this.form.update(
        {
          gender,
          title: value(person, "title"),
          nickName: nickname,
          middleName: value(person, "middleName"),
          lastName: value(person, "lastName"),
          phoneNumbers: value(person, "phoneNumbers"),
          emailAddresses: value(person, "emailAddresses"),
          visitAddress: value(person, "address"),
          postalAddress: person.postalAddressDiffersVisitAddress
            ? value(person, "postalAddress")
            : "",
          futureAddress: value(person, "futureAddress"),
          futureAddressActivationDateTime: value(
            person,
            "futureAddressActivationDateTime"
          ),
          useFormalAppelation: person.useFormalAppelation ? true : false,
          salutation: value(person, "salutation"),
          nameLetters: value(person, "nameLetters"),
          firstName: value(person, "firstName"),
          dateOfBirth: value(person, "dateOfBirth"),
          cityOfBirth: value(person, "cityOfBirth"),
          countryIso2OfBirth: person.countryIso2OfBirth
            ? person.countryIso2OfBirth
            : "nl",
          civilState: value(person, "civilState"),
          linkedPartner: person.linkedPartner,
          linkedRelationGroups: person.linkedRelationGroups
            ? person.linkedRelationGroups
            : [],
          keepAddressesInSync: person.linkedPartner
            ? person.linkedPartner.keepAddressesInSyncWithPartner
            : false,
          identificationCardType: value(person, "identificationCard.type"),
          identificationCardNumber: value(person, "identificationCard.number"),
          socialSecurityNumber: value(person, "socialSecurityNumber"),
          identificationCardValidUntilDate: value(
            person,
            "identificationCard.validUntilDate"
          ),
          bankAccountNumber: value(person, "bankAccount.number"),
          socialMedia: value(person, "socialMedia"),
        },
        true
      );
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.relationAddGroupsStatus === REQUEST.PENDING &&
      nextProps.relationAddGroupsStatus === REQUEST.SUCCESS
    ) {
      let linkedRelationGroups = [];
      if (nextProps.contactPerson.linkedRelationGroups) {
        linkedRelationGroups = [
          ...nextProps.contactPerson.linkedRelationGroups,
          nextProps.latestRelationGroupAdded,
        ];
      } else {
        linkedRelationGroups = [nextProps.latestRelationGroupAdded];
      }

      this.form.update({
        linkedRelationGroups,
      });
      this.setState({ isRelationGroupLoading: false });
    }

    if (
      nextProps.contactPerson.gender !== this.props.contactPerson.gender &&
      !!this.form
    ) {
      const formValues = this.form.getValues();
      const foundTitle = nextProps.contactPersonTitleSuggestions.find(
        (c) => c.displayName === formValues.title
      );

      if (!foundTitle) {
        this.form.update({
          title: nextProps.contactPersonTitleSuggestions[0].displayName,
        });
      }
    }
  }

  public onClickHandler() {
    this.form.update({ nickName: this.props.contactPerson.firstName });
  }

  public render() {
    const platforms = [
      SocialMediaType.Facebook,
      SocialMediaType.LinkedIn,
      SocialMediaType.YouTube,
      SocialMediaType.Twitter,
    ];
    const { contactPerson, saveRelationContactInfoState } = this.props;
    let { displayName } = contactPerson;
    displayName = displayName || "contactPerson.pageHeader.emptyTitle";
    const saving = saveRelationContactInfoState === REQUEST.PENDING;

    return (
      <div styleName="edit">
        <PageHeader
          title={displayName}
          subTitle="contactPerson.pageHeader.subtitle"
          actions={
            <>
              <Presence
                entityId={contactPerson.id}
                entityType={RootEntityType.ContactPerson}
              />
              <Notes />
              <Button
                label="save"
                category="success"
                disabled={saving}
                onClick={() => this.setState({ confirmSaveAndClose: true })}
              />
            </>
          }
        />

        <ExternalChanges
          entityId={contactPerson.id}
          entityType={RootEntityType.ContactPerson}
          onReloadEntity={this.props.reloadContactPerson}
        />

        <div styleName="edit__body" id="scroll-to-top">
          {saving && <Ui.Loaders.Fullscreen mask />}

          <Form
            formControls={this.formControls}
            name="relation"
            onSubmit={this.onFormSubmitHandler}
            onChange={this.onChangeHandler}
            form={(formRef) => (this.form = formRef)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              {/* Basic tab */}
              <StepComponent title="contactBasis">
                <div styleName="relation-form">
                  <div className="form__row">
                    <Input.RadioGroup name="gender" asButtonList>
                      <Input.Radio label="genderMale" value={Gender.Male} />
                      <Input.Radio label="genderFemale" value={Gender.Female} />
                      <Input.Radio label="genderOther" value={Gender.Other} />
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="title">
                          <I18n value="title" />
                        </label>
                        <Input.NewSelect
                          name="title"
                          addEmptyOption
                          emptyOptionLabel="empty"
                          values={this.props.contactPersonTitleSuggestions}
                          displayProp="displayName"
                          valuesProp="displayName"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="linkColumn">
                        <div className="column">
                          <label htmlFor="singleFirstName">
                            <I18n value="nickName" />
                          </label>
                          <Input.Text
                            name="nickName"
                            placeholder="singleFirstName"
                            id="nickName"
                          />
                        </div>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="middleName">
                          <I18n value="middleName" />
                        </label>
                        <Input.Text
                          name="middleName"
                          placeholder="middleName"
                          maxLength={45}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="lastName">
                          <I18n value="lastName" />
                        </label>
                        <Input.Text name="lastName" placeholder="lastName" />
                      </div>
                    </div>

                    {this.props.contactPerson.firstName &&
                      !this.props.contactPerson.nickname && (
                        <div styleName="nickname-suggestion">
                          <span
                            className="as-link"
                            onClick={this.onClickHandler}
                          >
                            <I18n
                              value="nickNameSuggestion"
                              values={{
                                name: StringUtil.capitalize(
                                  this.props.contactPerson.firstName
                                ),
                              }}
                              asHtml
                            />
                          </span>
                        </div>
                      )}
                  </div>

                  <div styleName="relation-form__divider" />

                  <div className="form__row">
                    <label htmlFor="linkedRelationGroups">
                      <I18n value="toWhichRelationGroupDoesThisRelationBelong" />
                    </label>
                    <Input.RelationGroupQueryV2
                      name="linkedRelationGroups"
                      placeholder="relationAroAddNewRelationGroup"
                      relationGroups={this.props.relationGroups}
                    />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Phone numbers */}
                  <div className="form__row">
                    <label>
                      <I18n value="phoneNumbers" />
                    </label>
                    <Input.Array
                      name="phoneNumbers"
                      formControls={this.phoneNumbersFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column">
                        <Input.Text
                          name="number"
                          placeholder="phoneNumber"
                          fireAllChanges
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="type__field">
                        <Input.NewSelect
                          name="type"
                          values={this.props.phoneNumberTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Email addresses */}
                  <div className="form__row">
                    <label>
                      <I18n value="emailAddresses" />
                    </label>
                    <Input.Array
                      name="emailAddresses"
                      formControls={this.emailAddressesFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column" styleName="email__field">
                        <Input.Text
                          name="address"
                          placeholder="address"
                          fireAllChanges
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="type__field">
                        <Input.NewSelect
                          name="type"
                          values={this.props.emailAddressTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  <div className="form__row">
                    <label>
                      <I18n value="visitAddress" />
                    </label>
                    <Input.LocationQueryV2
                      name="visitAddress"
                      countries={this.props.countries}
                      countryIso2={this.props.countryIso2}
                      culture={this.props.culture}
                      showManualAddressOnAddress={true}
                    />
                    {!this.props.contactPerson
                      .postalAddressDiffersVisitAddress && (
                      <div styleName="addLink">
                        <a
                          onClick={() =>
                            this.props.togglePostalAddress(
                              this.props.contactPerson.id
                            )
                          }
                        >
                          <I18n value="addPostalAddressQuestion" />
                        </a>
                      </div>
                    )}
                  </div>

                  {this.props.contactPerson
                    .postalAddressDiffersVisitAddress && (
                    <div className="form__row">
                      <label>
                        <I18n value="postalAddress" />
                        &nbsp;
                        <span
                          className="as-link"
                          onClick={() =>
                            this.props.togglePostalAddress(
                              this.props.contactPerson.id
                            )
                          }
                        >
                          <I18n value="removePostalAddress" />
                        </span>
                      </label>
                      <Input.LocationQueryV2
                        name="postalAddress"
                        countries={this.props.countries}
                        countryIso2={this.props.countryIso2}
                        culture={this.props.culture}
                        showManualAddressOnAddress={true}
                      />
                    </div>
                  )}

                  <FeatureSwitch feature="FUTURE_ADDRESS">
                    {!this.state.showFutureAddress ? (
                      <div className="form__row">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            this.setState({ showFutureAddress: true })
                          }
                        >
                          <I18n value="relation.futureAddress.toggle" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="form__row">
                          <label htmlFor="futureAddress">
                            <I18n value="relation.futureAddress.futureAddress" />
                            &nbsp;
                            <span
                              className="as-link"
                              onClick={this.toggleFutureAddress}
                            >
                              <I18n value="relation.futureAddress.remove" />
                            </span>
                          </label>
                          <Input.LocationQueryV2
                            name="futureAddress"
                            countries={this.props.countries}
                            countryIso2={this.props.countryIso2}
                            culture={this.props.culture}
                            showManualAddressOnAddress={true}
                          />
                        </div>

                        <div className="form__row">
                          <label htmlFor="futureAddressActivationDateTime">
                            <I18n value="relation.futureAddress.futureAddressActivationDateTime" />
                          </label>
                          <div className="form__group">
                            <div
                              className="column"
                              styleName="future-address__date"
                            >
                              <Input.Datepicker name="futureAddressActivationDateTime" />
                            </div>
                            <div className="column__spacer" />
                            <div
                              className="column"
                              styleName="future-address__info"
                            >
                              <i className="fa fa-info-circle" />
                              <I18n value="relation.futureAddress.info" />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </FeatureSwitch>
                </div>
              </StepComponent>

              {/* Detail tab */}
              <StepComponent title="contactDetails">
                <div styleName="relation-form">
                  <div className="form__row">
                    <Input.RadioGroup name="useFormalAppelation" asButtonList>
                      <Input.Radio label="formal" value={true} />
                      <Input.Radio label="informal" value={false} />
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">{this.state.salutation}</div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="firstName">
                          <I18n value="fullFirstName" />
                        </label>
                        <Input.Text name="firstName" placeholder="firstName" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="nameLetters">
                          <I18n value="nameLetters" />
                        </label>
                        <Input.Text
                          name="nameLetters"
                          placeholder="nameLetters"
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>
                  <div styleName="relation-form__divider" />
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="dateOfBirth">
                          <I18n value="dateOfBirth" />
                        </label>
                        <Input.Datepicker name="dateOfBirth" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="cityOfBirth">
                          <I18n value="cityOfBirth" />
                        </label>
                        <Input.Text
                          name="cityOfBirth"
                          placeholder="cityOfBirth"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="countryIso2OfBirth">
                          <I18n value="countryIso2OfBirth" />
                        </label>
                        <Input.NewSelect
                          name="countryIso2OfBirth"
                          values={this.props.countries}
                          valuesProp="iso2CodeValue"
                          filterProp="displayName"
                          displayValueFn={this.renderCountryValue}
                          displayFn={this.renderCountryOption}
                        />
                      </div>
                    </div>
                  </div>
                  <div styleName="relation-form__divider" />
                  <div className="form__row">
                    <label htmlFor="civilState">
                      <I18n value="civilState" />
                    </label>
                    <Input.NewSelect
                      name="civilState"
                      values={this.props.civilStateOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>

                  <div className="form__row">
                    <label htmlFor="linkedPartner">
                      <I18n value="civilStatePartner" />
                    </label>
                    <div className="form__group">
                      <div className="column grow1">
                        <Input.RelationQuery
                          name="linkedPartner"
                          placeholder="relationAroAddNewRelation"
                          filterByRelationTypes={[RelationType.ContactPerson]}
                          onAdd={this.onRelationAddHandler}
                          onNavigateToRelation={this.props.navigate}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.CheckBox
                          name="keepAddressesInSync"
                          label="linkedPartner.keepAddressesInSyncWithPartner"
                        />
                      </div>
                    </div>
                  </div>

                  <div styleName="relation-form__divider" />

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="identificationCard.type">
                          <I18n value="identificationCard.type" />
                        </label>
                        <Input.NewSelect
                          name="identificationCardType"
                          values={this.props.identificationTypeOptions}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="identificationCard.number">
                          <I18n value="identificationCard.number" />
                        </label>
                        <Input.Text
                          name="identificationCardNumber"
                          placeholder="identificationCard.number"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="identificationCard.validUntilDate">
                          <I18n value="identificationCard.validUntilDate" />
                        </label>
                        <Input.Datepicker name="identificationCardValidUntilDate" />
                      </div>
                    </div>
                  </div>
                  <div styleName="relation-form__divider" />
                  <div className="form__row">
                    <label htmlFor="bankAccount.number">
                      <I18n value="bankAccount.number" />
                    </label>
                    <Input.Text
                      name="bankAccountNumber"
                      placeholder="IBANNumberExample"
                    />
                  </div>
                </div>
              </StepComponent>

              {/* Social tab */}
              <StepComponent title="contactSocial">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="socialMedia" />
                    <Input.Social name="socialMedia" platforms={platforms} />
                  </div>
                </div>
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ContactPerson}
          entityId={contactPerson.id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => {
            this.form.submit();
            this.setState({ confirmSaveAndClose: false });
          }}
        />
        <DuplicatesModal
          visible={this.state.duplicateModalState.visible}
          view={this.state.duplicateModalState.view}
          relations={this.state.duplicateModalState.relations}
          relationData={this.state.duplicateModalState.relationData}
          onClose={() => this.clearState()}
          onNavigate={() => {
            this.clearState();
          }}
          onCreateNewRelation={() => {
            this.props.saveContactPerson(
              this.state.newRelation.relation,
              this.state.newRelation.path
            );
            this.clearState();
          }}
          mode={"create"}
        />
      </div>
    );
  }

  public async onFormSubmitHandler(formValues: FormReturnValue) {
    const editedContactPerson = this.getContactPersonFromFormValues(formValues);

    const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
      id: editedContactPerson.id,
    });
    const {
      emailAddresses: email,
      phoneNumbers: phone,
      isNew,
    } = editedContactPerson;

    if (isNew) {
      const emailAddresses = (email || []).map((e) => e.address as string);
      const phoneNumbers = head<string>(
        (phone || []).map((p) => p.number as string)
      );

      const relations =
        await this.props.getRelationsWithMatchingEmailAddressOrPhoneNumber(
          emailAddresses,
          phoneNumbers
        );

      if (!!relations.length) {
        this.setState({
          duplicateModalState: {
            visible: true,
            view: "duplicates",
            relations,
            relationData: {
              email: head(emailAddresses),
              telephone: phoneNumbers,
            },
          },
          newRelation: {
            relation: editedContactPerson,
            path,
          },
        });
        return;
      }
    }

    this.props.saveContactPerson(editedContactPerson, path);
  }

  private clearState() {
    this.setState({
      duplicateModalState: initialDuplicateModalState,
      newRelation: { relation: null, path: null },
    });
  }

  private getContactPersonFromFormValues(
    result: FormReturnValue
  ): ContactPerson {
    let linkedPartner: ContactPersonPartner;
    if (result.linkedPartner) {
      linkedPartner = {
        displayName: result.linkedPartner.displayName,
        id: result.linkedPartner.id,
        keepAddressesInSyncWithPartner: result.keepAddressesInSync,
      };
    }

    const contactPerson: ContactPerson = {
      ...this.props.contactPerson,
      gender: result.gender,
      title: result.title,
      nickname: result.nickName,
      middleName: result.middleName,
      emailAddresses: (result.emailAddresses || [])
        .map((address: EmailAddress) =>
          !address.type ? { ...address, type: EmailAddressType.Home } : address
        )
        .filter((address: EmailAddress) => !!address.address),
      phoneNumbers: (result.phoneNumbers || [])
        .map((phone: PhoneNumber) =>
          !phone.type ? { ...phone, type: PhoneNumberType.Mobile } : phone
        )
        .filter((phone: PhoneNumber) => !!phone.number),
      address: result.visitAddress,
      futureAddress: result.futureAddress,
      futureAddressActivationDateTime: result.futureAddressActivationDateTime,
      postalAddress: result.postalAddress,
      useFormalAppelation: result.useFormalAppelation,
      salutation: RelationUtil.getFormalAppelation(
        result.useFormalAppelation,
        result.gender,
        result.nickName,
        result.middleName,
        result.lastName
      ),
      nameLetters: result.nameLetters,
      firstName: result.firstName,
      lastName: result.lastName,
      dateOfBirth: result.dateOfBirth,
      cityOfBirth: result.cityOfBirth,
      countryIso2OfBirth: result.countryIso2OfBirth,
      civilState: result.civilState,
      linkedPartner,
      identificationCard: {
        ...this.props.contactPerson.identificationCard,
        number: result.identificationCardNumber,
        type: result.identificationCardType,
        validUntilDate: result.identificationCardValidUntilDate,
      },
      socialSecurityNumber: result.socialSecurityNumber,
      bankAccount: {
        ...this.props.contactPerson.bankAccount,
        number: result.bankAccountNumber,
      },
      socialMedia: result.socialMedia,
      linkedRelationGroups: this.mapRelationGroups(result.linkedRelationGroups),
    };

    contactPerson.emailAddresses = contactPerson.emailAddresses.map(
      (emailAddress) => {
        if (!emailAddress.type) {
          emailAddress.type = EmailAddressType.Home;
        }
        return emailAddress;
      }
    );

    contactPerson.phoneNumbers = contactPerson.phoneNumbers.map(
      (phoneNumber) => {
        if (!phoneNumber.type) {
          phoneNumber.type = PhoneNumberType.Work;
        }
        return phoneNumber;
      }
    );

    return contactPerson;
  }

  private mapRelationGroups(
    newRelations: RelationGroupSnapShot[]
  ): LinkedRelationGroup[] {
    let result = this.props.contactPerson.linkedRelationGroups
      ? [...this.props.contactPerson.linkedRelationGroups]
      : [];

    for (let i = 0; i < newRelations.length; i++) {
      const newRelation = newRelations[i];
      if (!newRelation) break;

      const foundRelation = result.find(
        (relation) => relation.id === newRelation.id
      );

      if (!foundRelation) {
        result.push({
          backColor: newRelation.backColor,
          displayName: newRelation.name,
          frontColor: newRelation.frontColor,
          id: newRelation.id,
          isActive: true,
          isReadOnly: false,
        });
      }
    }

    result = result.filter((item) => {
      const foundRelation = newRelations.find(
        (relation) => relation.id === item.id
      );
      return foundRelation;
    });

    return result;
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private formatNameLetters(names: string) {
    let nameLetters = "";
    const splitNames = names.split(" ");
    for (let i = 0; i < splitNames.length; i++) {
      const name = splitNames[i];
      if (name) {
        nameLetters = nameLetters + name[0] + ".";
      }
    }
    return nameLetters;
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const editedContactPerson =
      this.getContactPersonFromFormValues(formReturnValue);
    this.props.updateContactPerson(editedContactPerson, this.props.path);
  }

  private onAddManualAddress(value: string, isPostalAddress: boolean) {
    this.props.addManualAddress(
      value,
      isPostalAddress,
      this.props.contactPerson.id,
      RelationType.ContactPerson
    );
  }

  private getRelationGroupsThatCantGetDeleted(): LinkedRelationGroup[] {
    if (!this.props.contactPerson.linkedRelationGroups) return [];
    const result = this.props.contactPerson.linkedRelationGroups.filter(
      (relationGroup) => relationGroup.isReadOnly
    );
    return result;
  }

  private onRelationAddHandler(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedPartner: RelationSnapShot) {
    this.form.update({
      linkedPartner,
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private toggleFutureAddress() {
    if (!this.form) return;
    this.form.update({
      futureAddress: "",
      futureAddressActivationDateTime: "",
    });
    this.setState({ showFutureAddress: false });
  }
}
