import * as React from "react";
import { ObjectAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import {
  FormControls,
  FormReference,
  Form,
  Input,
  FormReturnValue
} from "@haywork/modules/form";
import get from "lodash-es/get";

const styles = require("../widgets.component.scss");

interface Props {
  objectAssignment: ObjectAssignment;
  onChange: (hideOnFundaUntil: Date | null) => void;
}
interface State {
  formVisible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentHideOnFundaWidget extends React.PureComponent<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      formVisible: false
    };

    this.formControls = {
      hideOnFundaUntil: {
        value: get(this.props.objectAssignment, "hideOnFundaUntil", "")
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      get(this.props.objectAssignment, "hideOnFundaUntil", "") !==
      get(prevProps.objectAssignment, "hideOnFundaUntil", "")
    ) {
      if (!this.form) return;
      this.form.update({
        hideOnFundaUntil: get(
          this.props.objectAssignment,
          "hideOnFundaUntil",
          ""
        )
      });
    }
  }

  public render() {
    if (
      !this.props.objectAssignment ||
      !this.props.objectAssignment.hideOnFundaUntil
    )
      return null;

    const { hideOnFundaUntil } = this.props.objectAssignment;

    return (
      <div styleName="widget">
        <h2 styleName="title">
          <ResourceText resourceKey="hiddenOnFundaTitle" />
        </h2>

        {!this.state.formVisible ? (
          <div>
            <ResourceText resourceKey="hiddenOnFunda" />{" "}
            <strong>
              <FormattedDate
                value={hideOnFundaUntil}
                day="2-digit"
                month="long"
                year="numeric"
              />
            </strong>{" "}
            <span
              className="as-link"
              onClick={() => this.setState({ formVisible: true })}
            >
              <ResourceText resourceKey="changeHideOnFunda" />
            </span>
          </div>
        ) : (
          <div>
            <Form
              name="hideOnFundaUntil"
              formControls={this.formControls}
              form={(form) => (this.form = form)}
              onSubmit={this.onSubmit}
            >
              <div styleName="hide-on-funda__row">
                <Input.Datepicker name="hideOnFundaUntil" />
                <button type="submit" className="btn btn-primary">
                  <ResourceText resourceKey="save" />
                </button>

                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.onCancel}
                >
                  <ResourceText resourceKey="cancel" />
                </button>
              </div>
            </Form>
          </div>
        )}
      </div>
    );
  }

  private onSubmit(values: FormReturnValue) {
    this.props.onChange(values.hideOnFundaUntil);
    this.setState({ formVisible: false });
  }

  private onCancel() {
    this.setState({ formVisible: false });
    if (!this.form) return;
    this.form.update({
      hideOnFundaUntil: get(this.props.objectAssignment, "hideOnFundaUntil", "")
    });
  }
}
