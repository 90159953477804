import {
  Address,
  AddressDetail,
  CountryOption,
  LocationPlaceOption,
  ProjectAssignment,
} from "@haywork/api/kolibri";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { ProjectEditProjectComponent } from "@haywork/modules/project/components/edit-project/edit-project.component";
import { AppState, EditableActions, SingleProjectState } from "@haywork/stores";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  editableBuildingStartText,
  editableDeliveryStartText,
  editableSaleStartText,
} from "./selectors";

export interface ProjectEditProjectContainerProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  locationPlaces: LocationPlaceOption[];
  countries: CountryOption[];
  countryIso2: string;
  sublocalities: AddressDetail[];
  addressSearchState: string;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;
  culture: string;

  searchAddress: (value: string, country: string) => Promise<Address>;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
  setCaller: (caller: string, path: string) => any;
  searchSublocalities: (id: number) => any;
}

const mapStateToProps = (state: AppState) => {
  const { currentComponentState } = state.editable;
  const { addressSearchState } = state.assignment.single;
  const { culture } = state.main;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    locationPlaces: state.main.mastertable.kolibri.locationPlaces,
    countries: state.main.mastertable.kolibri.countries,
    countryIso2: "NL",
    sublocalities: currentComponentState.sublocalities,
    addressSearchState,
    buildingStartText: editableBuildingStartText(state),
    deliveryStartText: editableDeliveryStartText(state),
    saleStartText: editableSaleStartText(state),
    culture,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  searchAddress: (value: string, country: string) =>
    dispatch(ProjectsThunks.Projects.searchAddress(value, country)),
  searchSublocalities: (id: number) =>
    dispatch(ProjectsThunks.Projects.searchSublocalities(id)),
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      ProjectsThunks.Projects.updateProjectEditable(componentState, path)
    ),
  updateTabTitle: (title: string, path: string) =>
    dispatch(EditableActions.updateTabTitle({ path, title })),
  setCaller: (caller: string, path: string) =>
    dispatch(EditableActions.setCaller({ caller, path })),
});
export const ProjectEditProjectContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectEditProjectComponent)
);
