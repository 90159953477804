import { RootEntityType } from "@haywork/api/event-center";
import { EventCenterContext } from "@haywork/modules/live-data";
import { PresentEmployee } from "@haywork/stores";
import * as React from "react";
import { FC, memo, useCallback, useContext, useEffect } from "react";
import { EntityContainerProps } from "./entity.container";

export type EntityComponentProps = {
  entityId: string;
  entityType: RootEntityType;
};
type Props = EntityComponentProps & EntityContainerProps;

export const EntityComponent: FC<Props> = memo(
  ({ employee, entityId, entityType, children }) => {
    const presence = useContext(EventCenterContext);

    const enterOrleavePresence = useCallback(
      (enter = true) => {
        if (!presence || !employee || !entityType || !entityId) return;
        let { entities } = employee;
        entities = entities.filter(
          (entity) => entity.type !== RootEntityType.Unknown
        );

        if (enter) {
          entities = [
            ...entities,
            {
              type: entityType,
              id: entityId,
              openedOn: new Date(),
              inEditMode: /\/edit/gi.test(location.pathname),
            },
          ];
        }

        const data = {
          ...employee,
          entities,
        } as PresentEmployee;

        presence.presence.update(data);
      },
      [presence, entityType, entityId]
    );

    useEffect(() => {
      enterOrleavePresence();
      return () => {
        enterOrleavePresence(false);
      };
    }, [enterOrleavePresence]);

    return <>{children}</>;
  }
);
