import classNames from "classnames";
import * as React from "react";
import {
  CSSProperties,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { LightboxDirection } from "./lightbox.component";

const styles = require("./lightbox.component.scss");

type Props = {
  direction: LightboxDirection;
  current: number;
  index: number;
  url: string;
  lightBoxVisible: boolean;
};

const SlideComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ direction, current, index, url, lightBoxVisible }) => {
      const slideRef = useRef<HTMLDivElement>();
      const iframeRef = useRef<HTMLDivElement>();
      const [player, setPlayer] = useState<any>(null);
      const [inited, setInited] = useState(false);

      const previous = useMemo(() => {
        return direction === LightboxDirection.Left ? current + 1 : current - 1;
      }, [direction, current]);

      const slideStyleName = useMemo(() => {
        return classNames("slide", {
          current: index === current,
          previous: index === previous,
        });
      }, [index, current, previous]);

      const isVideoUrl = useMemo(() => {
        return new RegExp(/(youtube.com|youtu.be)/gi).test(url);
      }, [url]);

      const slideStyles = useMemo(() => {
        if (isVideoUrl) return;

        return {
          backgroundImage: `url(${JSON.stringify(url)})`,
        } as CSSProperties;
      }, [url, isVideoUrl]);

      const videoStyles = useMemo(() => {
        if (!isVideoUrl || !slideRef.current) return;
        const results = url.match("embed/([^&#]*)");
        const { height } = slideRef.current.getBoundingClientRect();

        if (!!results && results.length) {
          return {
            backgroundImage: `url(https://img.youtube.com/vi/${results[1]}/0.jpg)`,
            height,
            width: (height / 9) * 16,
          } as CSSProperties;
        } else {
          return;
        }
      }, [url, isVideoUrl, slideRef, inited]);

      const playVideo = useCallback(() => {
        if (!player || player.getPlayerState() === 1) return;
        player.playVideo();
      }, [player]);

      const stopVideo = useCallback(() => {
        if (!player) return;
        player.stopVideo();
      }, [player]);

      const setPlayerReady = useCallback(
        (event: any) => {
          setPlayer(event.target);
        },
        [setPlayer]
      );

      useEffect(() => {
        if (!isVideoUrl || !!player || !window["YT"] || !iframeRef.current)
          return;
        const results = url.match("embed/([^&#]*)");
        if (!results || !results.length) return;

        const instance = new window["YT"].Player(iframeRef.current, {
          height: "100%",
          width: "100%",
          videoId: results[1],
          events: {
            onReady: setPlayerReady,
          },
        });
      }, [isVideoUrl, iframeRef, player, setPlayerReady, url]);

      useEffect(() => {
        if (index !== current || !lightBoxVisible) {
          stopVideo();
        } else {
          playVideo();
        }
      }, [index, current, playVideo, stopVideo, lightBoxVisible]);

      useEffect(() => {
        if (!inited) setInited(true);
      }, [inited, setInited]);

      return (
        <div styleName={slideStyleName} style={slideStyles} ref={slideRef}>
          {isVideoUrl && (
            <div styleName="iframe__wrapper">
              <div styleName="iframe" style={videoStyles}>
                <div ref={iframeRef} id={uuid()} />
              </div>
            </div>
          )}
        </div>
      );
    }
  )
);

export default SlideComponent;
