import { ErrorMessage } from "@haywork/modules/form";
import { PATTERN } from "@haywork/constants";

export const url = (errorMessage?: ErrorMessage, checkForSchema: boolean = false) => (value?: string): null | ErrorMessage => {
  if (value === "") return null;
  if (checkForSchema && value.match(PATTERN.URL) && value.match(PATTERN.SCHEMA)) return null;
  if (!checkForSchema && value.match(PATTERN.URL)) return null;
  return errorMessage || { key: "validatorUrl" };
};

export const schema = (errorMessage?: ErrorMessage) => (value?: string): null | ErrorMessage => {
  if (value === "" || value.match(PATTERN.SCHEMA)) return null;
  return errorMessage || { key: "validatorSchemaUrl" };
};
