import { SearchAssignment, MatchMailTrigger } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch } from "@haywork/middleware";
import {
  SearchAssignmentEditContactComponent,
  SearchAssignmentEditContactComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil, SimpleLabelValue } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignment: SearchAssignment;
  realEstateAgencyId: string;
  host: string;
  matchmailPeriods: SimpleLabelValue[];
  matchmailTriggers: SimpleLabelValue[];
  path: string;
}
interface DispatchProps {
  updateSearchAssignment: (
    componentState: SearchAssignment,
    path: string
  ) => void;
  navigate: (url: string) => void;
}

const mapStateToProps = <StateProps, AssignmentEditClientComponentProps>(
  state: AppState
) => {
  const searchAssignment: SearchAssignment =
    state.editable.currentComponentState;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  return {
    searchAssignment,
    realEstateAgencyId,
    host: state.appSettings.host,
    matchmailPeriods: mapMastertableLists(
      state.main.mastertable.kolibri.matchMailPeriodOptions
    ),
    matchmailTriggers: mapMastertableLists(
      state.main.mastertable.kolibri.matchMailTriggerOptions,
      (item) => item.value !== MatchMailTrigger.AllChanges
    ),
    source: [
      { label: "sourceOther", value: "sourceOther" },
      { label: "sourceUnknown", value: "sourceUnknown" },
      { label: "sourceViaFriend", value: "sourceViaFriend" },
      { label: "sourceViaColleague", value: "sourceViaColleague" },
      { label: "sourceViaInternet", value: "sourceViaInternet" },
      { label: "sourceViaHousenet", value: "sourceViaHousenet" },
      { label: "sourceViaWalkIn", value: "sourceViaWalkIn" },
    ],
    path: RouteUtil.mapStaticRouteValues(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
      id: searchAssignment.id,
    }),
  };
};

const mapMastertableLists = (
  list: any,
  filter?: (item: any) => boolean
): SimpleLabelValue[] => {
  return list
    .map((item) => ({ label: item.displayName, value: item.value }))
    .filter((item) => (!filter ? true : filter(item)));
};

const mapDispatchToProps = <DispatchProps, AssignmentEditClientComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateSearchAssignment: (componentState: SearchAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  navigate: (url: string) => dispatch(push(url)),
});

export type SearchAssignmentEditContactContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentEditContactContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentEditContactComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentEditContactComponent);
