import * as React from "react";
import * as CSSModules from "react-css-modules";

import { LinkedDraft } from "@haywork/api/event-center";

const styles = require("./body.component.scss");

export interface PublicationBodyProps {
  linkedDrafts: LinkedDraft[];
}

export const EmailBodyComponent = CSSModules(styles, {
  allowMultiple: true
})((props: PublicationBodyProps) => {
  let linkedDrafts = props.linkedDrafts || [];
  linkedDrafts = linkedDrafts.filter((draft) => !!draft.systemMessage);

  if (!linkedDrafts.length) return null;

  return (
    <div styleName="body">
      {linkedDrafts.map((draft, idx) => (
        <div styleName="row" key={idx}>
          <i className="fa fa-exclamation-triangle" />
          {draft.systemMessage}
        </div>
      ))}
    </div>
  );
});
