import { intlContext } from "@haywork/app";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";

interface PasswordInputComponentProps {
  canToggle?: boolean;
  icon?: string;
  large?: boolean;
  placeholder?: string;
  newPassword?: boolean;
  maxLength?: number;
  onKeyDown?: (value) => void;
}
interface PasswordInputComponentState {
  type: string;
  toggleIcon: string;
}

export class PasswordComponent extends React.Component<
  PasswordInputComponentProps & InputComponentProps,
  PasswordInputComponentState
> {
  private ref: HTMLInputElement;

  constructor(props, context) {
    super(props, context);

    // Initial state
    this.state = {
      type: "password",
      toggleIcon: "fal fa-fw fa-eye",
    };

    // Event bindings
    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const passwordInputStyle = classNames("input__password", {
      "has-icon": this.props.icon,
      "has-toggle": this.props.canToggle,
      large: this.props.large,
    });

    return (
      <div>
        <div className={passwordInputStyle}>
          {this.props.icon && <i className={"icon " + this.props.icon} />}
          <input
            type={this.state.type}
            name={this.props.name}
            id={this.props.name}
            placeholder={placeholder}
            value={this.props.value}
            onChange={this.onChangeHandler}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            autoComplete={this.props.newPassword ? "new-password" : ""}
            maxLength={this.props.maxLength}
            ref={this.bindRef}
            disabled={this.props.disabled}
            onKeyDown={this.props.onKeyDown}
            data-cy={this.props["data-cy"]}
          />
          {this.props.canToggle && (
            <div
              className="toggle"
              onClick={this.onToggleClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.ToggleButton`
              }
            >
              <i className={this.state.toggleIcon} />
            </div>
          )}
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: PasswordInputComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.props.onChange(value);
  }

  private onToggleClickHandler() {
    if (this.props.canToggle) {
      const type =
        this.state.type === "password"
          ? { type: "text", toggleIcon: "fal fa-fw fa-eye-slash" }
          : { type: "password", toggleIcon: "fal fa-fw fa-eye" };
      this.setState(type);
    }
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
    }
  }
}
