export const KEYCODE = {
  ESCAPE: 27,
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  LEFT_ARROW: 37,
  ALT: 18,
  CTRL: 17,
  SHIFT: 16,
  BACKSPACE: 8,
  DELETE: 46,
  TAB: 9,
  CMD: 91
};
