import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AssignmentType,
  LinkedAssignment,
  ProjectAssignment,
  UpdateAvailabilityAction,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { PROJECTROUTES, REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import Notes, { PinnedNotes } from "@haywork/modules/notes-v3";
import { ProjectDetailGeneralContainerProps } from "@haywork/modules/project";
import {
  AddBuildnumbers,
  AuthorizationWidgets,
  ConfirmComponent,
  LinkedEmployeeComponent,
  LinkedEventComponent,
  LinkedRelationComponent,
  PartnersWidgetComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { SingleProjectState } from "@haywork/stores";
import { ArrayUtil, RouteUtil, StringUtil } from "@haywork/util";
import { addHttp } from "@haywork/util/url";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { ProjectAction } from "./actions";
import { ProjectProgress } from "./progress/progress.component";
import {
  ProjectDetailGeneralWidgetsDatesComponent,
  ProjectDetailGeneralWidgetsNumbersComponent,
  ProjectDetailGeneralWidgetsStatusComponent,
} from "./widgets";

const styles = require("./detail-general.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ProjectDetailGeneralComponentProps {}
interface State {
  showConfirmDelete: boolean;
  showConfirmRetract: boolean;
}
type Props = ProjectDetailGeneralComponentProps &
  ProjectDetailGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailGeneralComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmDelete: false,
      showConfirmRetract: false,
    };

    this.onConfirmDeleteCloseHandler =
      this.onConfirmDeleteCloseHandler.bind(this);
    this.onConfirmDeleteHandler = this.onConfirmDeleteHandler.bind(this);
    this.onShowDeleteConfirmHandler =
      this.onShowDeleteConfirmHandler.bind(this);
    this.onRetractClickHandler = this.onRetractClickHandler.bind(this);
    this.onConfirmRetractCloseHandler =
      this.onConfirmRetractCloseHandler.bind(this);
    this.onConfirmRetractHandler = this.onConfirmRetractHandler.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.hideOnFundaChanged = this.hideOnFundaChanged.bind(this);
    this.onAddBuildnumberSuccessHandler =
      this.onAddBuildnumberSuccessHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.projectAssignment.id,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    const { projectAssignment, isInitial } = this.props;
    const {
      assignmentPhase,
      linkedApplicants,
      linkedVendors,
      linkedNotaries,
      linkedEmployees,
      id,
      forSale,
      titleText,
      amountOfUnits,
      isActive,
    } = projectAssignment;

    const subTitle = !!assignmentPhase
      ? assignmentPhase.toString()
      : this.props.projectAssignment.displayName || "";
    const loading = this.props.saveAssignmentState === REQUEST.PENDING;

    return (
      <div styleName="general">
        <PageHeader
          title="pageTitle.project.general"
          subTitle={isActive ? subTitle : "archived"}
          subTitlePrefix={isActive ? "assignmentPhases" : null}
          actions={
            <>
              <Notes />
              <Button
                label="showEdit"
                category="success"
                onClick={() => this.props.edit(id)}
                disabled={isInitial || loading}
              />

              <Actions
                project={this.props.projectAssignment}
                loading={isInitial || loading}
                onClick={this.onActionClick}
                canSendEmail={this.props.canSendEmail}
                canCreateObjectTypeAssignment={
                  this.props.canCreateObjectTypeAssignment
                }
                canCreateObjectAssignment={this.props.canCreateObjectAssignment}
              />
            </>
          }
        />

        <div styleName="general__body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <div styleName="section detail" className="clearfix">
            <div styleName="assignment__info">
              <h2>
                <I18n value={"Project"} />,{" "}
                {forSale ? (
                  <I18n value="forSale" />
                ) : (
                  <I18n value={"forRent"} />
                )}
              </h2>
              <h1>
                {titleText && titleText.length > 0 ? titleText[0].text : ""}
              </h1>

              {!!this.props.projectAssignment.webAddress && (
                <div styleName="web-address">
                  <i className="fal fa-globe" />
                  <a
                    href={addHttp(this.props.projectAssignment.webAddress)}
                    target="_blank"
                  >
                    {StringUtil.truncate(
                      this.props.projectAssignment.webAddress,
                      64,
                      30
                    )}
                  </a>
                </div>
              )}

              <div styleName="build-numbers">
                <div styleName="bsection">
                  <i className="fa fa-puzzle-piece" />
                  <I18n
                    value="projectNumberOfObjectTypes"
                    values={{ count: this.props.objectTypes.length || 0 }}
                  />
                </div>
                <div styleName="bsection">
                  <i className="fa fa-home" />
                  <I18n
                    value="projectTypesDetailUnitsAmount"
                    values={{ count: amountOfUnits || 0 }}
                  />
                </div>
              </div>

              <PinnedNotes />
            </div>

            <ErrorBoundary>
              <ProjectProgress
                objectTypes={this.props.objectTypes}
                buildnumbers={this.props.buildnumbers}
                forSale={this.props.projectAssignment.forSale}
                forRent={this.props.projectAssignment.forRent}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <ProjectDetailGeneralWidgetsStatusComponent
                project={this.props.projectAssignment}
                onRetractClick={this.onRetractClickHandler}
                onModifyClick={() => this.props.toggleStatusModal(true)}
                onHideOnFundaChange={this.hideOnFundaChanged}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <ProjectDetailGeneralWidgetsNumbersComponent
                project={this.props.projectAssignment}
                priceRange={this.props.priceRange}
                parcelRange={this.props.parcelRange}
                livingRoomRange={this.props.livingRoomRange}
                contentsRange={this.props.contentsRange}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <ProjectDetailGeneralWidgetsDatesComponent
                project={this.props.projectAssignment}
                buildingStartText={this.props.buildingStartText}
                saleStartText={this.props.saleStartText}
                deliveryStartText={this.props.deliveryStartText}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <PartnersWidgetComponent
                assignmentPhase={this.props.projectAssignment.assignmentPhase}
                isTemporarilyRemovedFromFunda={
                  this.props.projectAssignment.isTemporarilyRemovedFromFunda
                }
                id={this.props.projectAssignment.id}
                webAddress={this.props.projectAssignment.webAddress}
                publications={this.props.mediaPartners}
                publicationsRoute={route(
                  PROJECTROUTES.EDIT_MARKETING_PARTNERS.URI,
                  { id }
                )}
              />
            </ErrorBoundary>
          </div>

          <div styleName="section meta" className="clearfix">
            {/* Relations */}
            {ArrayUtil.arraysNotEmpty([
              linkedVendors,
              linkedApplicants,
              linkedNotaries,
              linkedEmployees,
            ]) && (
              <div styleName="meta__network">
                {ArrayUtil.existsAndNotEmpty(linkedVendors) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="vendors" />
                    </h3>
                    {linkedVendors.map((linkedVendor, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedVendor}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedEmployees) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="employees" />
                    </h3>
                    {linkedEmployees.map((linkedEmployee, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedEmployeeComponent
                          employee={linkedEmployee}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                <div styleName="actions">
                  <NavLink to={route(PROJECTROUTES.NETWORK.URI, { id })}>
                    <I18n value="goToNetwork" />
                  </NavLink>
                </div>
              </div>
            )}

            {/* Relations empty state */}
            {!ArrayUtil.arraysNotEmpty([
              linkedVendors,
              linkedApplicants,
              linkedNotaries,
            ]) && (
              <div styleName="meta__network">
                <div styleName="relations">
                  <h3 styleName="meta__title">
                    <I18n value="linkedRelations" />
                  </h3>
                  <div styleName="empty-state">
                    <div styleName="body">
                      <I18n value="linkedRelationsEmptyState" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Authorization widgets */}
            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={WidgetEntityType.ProjectAssignment}
                location={WidgetEntityLocation.Sidebar}
                id={projectAssignment.id}
              />
            </FeatureSwitch>

            {/* Events */}
            {ArrayUtil.arraysNotEmpty([
              this.props.pastEvents,
              this.props.futureEvents,
            ]) && (
              <div styleName="meta__events">
                {ArrayUtil.existsAndNotEmpty(this.props.futureEvents) && (
                  <div styleName="list">
                    <h3 styleName="meta__title">
                      <I18n value="upcomingEvents" />
                    </h3>
                    {this.props.futureEvents.map((futureEvent, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedEventComponent
                          event={futureEvent}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}

                {ArrayUtil.existsAndNotEmpty(this.props.pastEvents) && (
                  <div styleName="list">
                    <h3 styleName="meta__title">
                      <I18n value="pastEvents" />
                    </h3>
                    {this.props.pastEvents.map((pastEvent, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedEventComponent
                          event={pastEvent}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}

                <div styleName="actions">
                  <NavLink to={route(PROJECTROUTES.TIMELINE.URI, { id })}>
                    <I18n value="goToTimeline" />
                  </NavLink>
                </div>
              </div>
            )}

            {/* Events empty state */}
            {!ArrayUtil.arraysNotEmpty([
              this.props.pastEvents,
              this.props.futureEvents,
            ]) && (
              <div styleName="meta__events">
                <div styleName="list">
                  <h3 styleName="meta__title">
                    <I18n value="linkedEvents" />
                  </h3>
                  <div styleName="empty-state">
                    <div styleName="body">
                      <I18n value="linkedEventsEmptyState" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.showConfirmDelete}
          titleResourceKey="assignmentDeleteModalTitle"
          bodyResourceKey="assignmentDeleteModalMessage"
          onClose={this.onConfirmDeleteCloseHandler}
          onConfirm={this.onConfirmDeleteHandler}
        />

        <ConfirmComponent
          visible={this.state.showConfirmRetract}
          titleResourceKey="assignmentRetractConfirmTitle"
          bodyResourceKey="assignmentRetractConfirmMessage"
          onClose={this.onConfirmRetractCloseHandler}
          onConfirm={this.onConfirmRetractHandler}
        />

        <AddBuildnumbers
          project={this.props.projectAssignment}
          price={this.props.price}
          onClose={() => this.props.toggleAddBuildnumbers(false)}
          onSuccess={this.onAddBuildnumberSuccessHandler}
        />
      </div>
    );
  }

  private hideOnFundaChanged(hideOnFundaUntil: Date) {
    const { projectAssignment, currentComponentState } = this.props;

    const updatedObjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      hideOnFundaUntil,
    };

    const newState: SingleProjectState = {
      ...currentComponentState,
      projectAssignment: updatedObjectAssignment,
    };

    this.props.updateProject(newState, this.props.path);
    this.props.saveProject();
  }

  private onRetractClickHandler() {
    this.setState({ showConfirmRetract: true });
  }

  private onShowDeleteConfirmHandler() {
    this.setState({ showConfirmDelete: true });
  }

  private onConfirmDeleteCloseHandler() {
    this.setState({ showConfirmDelete: false });
  }

  private onConfirmRetractCloseHandler() {
    this.setState({ showConfirmRetract: false });
  }

  private onConfirmDeleteHandler() {
    this.setState({ showConfirmDelete: false });
    this.props.deleteAssignment(this.props.projectAssignment.id);
  }

  private onConfirmRetractHandler() {
    const { projectAssignment } = this.props;
    this.setState({ showConfirmRetract: false });

    this.props.updateAvailability(
      UpdateAvailabilityAction.ToAvailable,
      projectAssignment.id
    );
  }

  private onAddBuildnumberSuccessHandler() {
    const { id } = this.props.projectAssignment;
    this.props.toggleAddBuildnumbers(false);
    this.props.navigate(route(PROJECTROUTES.BUILD_NUMBERS.URI, { id }));
  }

  private onActionClick(action: ProjectAction) {
    const { id, displayName, linkedEmployees, linkedVendors } =
      this.props.projectAssignment;
    const linkedAssignment: LinkedAssignment = {
      id,
      displayName,
      typeOfAssignment: AssignmentType.Project,
    };

    const linkedRelations = [...(linkedVendors || [])];

    switch (action) {
      case ProjectAction.AddBuildNumbers: {
        this.props.toggleAddBuildnumbers(true);
        break;
      }
      case ProjectAction.AddObjectType: {
        this.props.onAddNewType(id);
        break;
      }
      case ProjectAction.Archive: {
        this.props.archive(id);
        break;
      }
      case ProjectAction.UnArchive: {
        this.props.unArchive(id);
        break;
      }
      case ProjectAction.Complete: {
        this.props.toggleStatusModal(true);
        break;
      }
      case ProjectAction.Publish: {
        this.props.navigate(route(PROJECTROUTES.PUBLISH.URI, { id }));
        break;
      }
      case ProjectAction.Remove: {
        this.onShowDeleteConfirmHandler();
        break;
      }
      case ProjectAction.WithDraw: {
        this.props.toggleWithdrawModal(true);
        break;
      }
      case ProjectAction.CreateTask: {
        return this.props.createTaskWithLinkedAssignment(linkedAssignment);
      }
      case ProjectAction.CreateInvoice: {
        return this.props.createInvoiceWithLinkedAssignment(
          linkedRelations[0] || undefined,
          linkedAssignment
        );
      }
      case ProjectAction.CreateAppointment: {
        return this.props.createAgendaItemWithLinkedAssignment(
          linkedRelations,
          linkedAssignment,
          linkedEmployees
        );
      }
      case ProjectAction.CreateEmail: {
        return this.props.createNewEmail(linkedAssignment, displayName);
      }
      default: {
        break;
      }
    }
  }
}
