import * as React from "react";
import * as deepEqual from "deep-equal";
import omit from "lodash-es/omit";
import * as CSSModules from "react-css-modules";

import {
  FormControls,
  Form,
  Input,
  FormReference,
  FormReturnValue,
} from "@haywork/modules/form";
import {
  FloorTypeOption,
  FloorType,
  AtticOptionOption,
  Language,
  TranslatedText,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { ExtendedFloor } from "../..";

const ATTIC_LIKE = [FloorType.Attic, FloorType.TopFloor];
const styles = require("../../floors-and-spaces.component.scss");

interface Props {
  floor: ExtendedFloor;
  floors: ExtendedFloor[];
  floorTypeOptions: FloorTypeOption[];
  atticOptions: AtticOptionOption[];
  canEditMainFloors: boolean;
  onChange: (floor: ExtendedFloor) => void;
}
interface State {
  isAtticLike: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class FloorFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const {
      atticOptions,
      floorType,
      name /*,
      numberOfSpaces,
      numberOfBedrooms*/,
    } = this.props.floor;

    this.state = {
      isAtticLike: ATTIC_LIKE.indexOf(floorType) !== -1,
    };

    this.formControls = {
      atticOptions: { value: atticOptions || [] },
      description: { value: this.getDescription() },
      floorType: {
        value: floorType,
        onChange: (ref) => {
          this.setState({
            isAtticLike: ATTIC_LIKE.indexOf(ref.value) !== -1,
          });
        },
      },
      name: {
        value: name || "",
      } /*,
      numberOfBedrooms: { value: numberOfBedrooms || 0 },
      numberOfSpaces: { value: numberOfSpaces || 0 }*/,
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getDescription = this.getDescription.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!!this.formRef && !deepEqual(prevProps.floor, this.props.floor)) {
      const {
        atticOptions,
        floorType,
        name /*,
        numberOfBedrooms,
        numberOfSpaces*/,
      } = this.props.floor;

      this.formRef.update({
        atticOptions: atticOptions || [],
        description: this.getDescription(),
        floorType,
        name: name || "" /*,
        numberOfBedrooms: numberOfBedrooms || 0,
        numberOfSpaces: numberOfSpaces || 0*/,
      });
    }
  }

  public render() {
    const { floorTypeOptions, floors } = this.props;
    const { atticOptions, floor } = this.props;
    const typeDisabled =
      floor.floorType === FloorType.GroundFloor &&
      !this.props.canEditMainFloors;
    const numOfSpacesDisabled = !!floor.spaces.length;

    const selectedSingleTypes = floors
      .filter((floor) => floor.floorType !== FloorType.Floor)
      .map((floor) => floor.floorType);

    return (
      <Form
        name="floor-details"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChange}
        asSubForm={true}
      >
        <div className="form__row" styleName="limit-form-row">
          <label htmlFor="floorType">
            <ResourceText resourceKey="floorDetails.label.floorType" />
          </label>
          <Input.Select name="floorType" disabled={typeDisabled}>
            {floorTypeOptions.map((option) => (
              <Input.Option
                value={option.value}
                key={option.id}
                disabled={selectedSingleTypes.indexOf(option.value) !== -1}
              >
                {option.displayName}
              </Input.Option>
            ))}
          </Input.Select>
        </div>

        <div className="form__row">
          <label htmlFor="name">
            <ResourceText resourceKey="floorDetails.label.name" />
          </label>
          <Input.Text name="name" maxLength={25} />
        </div>

        <div className="form__row">
          <label htmlFor="description">
            <ResourceText resourceKey="floorDetails.label.description" />
          </label>
          <Input.Textarea
            name="description"
            autoSize={true}
            maxLength={1024}
            maxCount={1024}
          />
        </div>

        {!!this.state.isAtticLike && (
          <div className="form__row">
            <label htmlFor="atticOptions">
              <ResourceText resourceKey="floorDetails.label.atticOptions" />
            </label>
            <Input.Multi name="atticOptions" values={atticOptions} />
          </div>
        )}

        {/* <div className="form__row">
          <div className="form__group">
            <div className="column__textspacer first">
              <label htmlFor="numberOfSpaces">
                <ResourceText resourceKey="floorDetails.label.numberOfSpaces" />
              </label>
            </div>
            <div className="column">
              <Input.Number
                name="numberOfSpaces"
                disabled={numOfSpacesDisabled}
              />
            </div>
            <div className="column__textspacer">
              <label htmlFor="numberOfBedrooms">
                <ResourceText resourceKey="floorDetails.label.numberOfBedrooms" />
              </label>
            </div>
            <div className="column">
              <Input.Number
                name="numberOfBedrooms"
                disabled={numOfSpacesDisabled}
              />
            </div>
          </div>
        </div> */}
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private onChange(values: FormReturnValue) {
    let floorValues = values;

    if (!this.state.isAtticLike) {
      floorValues = omit(floorValues, ["atticOptions"]);
    }

    const floor: ExtendedFloor = {
      ...this.props.floor,
      ...floorValues,
      description: this.setDescription(values.description),
    };

    /*const { spaces } = this.props.floor;
    if (!spaces || !spaces.length) {
      floor = {
        ...floor,
        numberOfBedrooms: values.numberOfBedrooms,
        numberOfSpaces: values.numberOfSpaces
      };
    }*/

    this.props.onChange(floor);
  }

  private getDescription(): string {
    const { description: descriptions } = this.props.floor;
    let description = (descriptions || []).find(
      (description) => description.language === Language.Dutch
    );
    description = description || { language: Language.Dutch, text: "" };
    return description.text || "";
  }

  private setDescription(text: string): TranslatedText[] {
    const { description: descriptions } = this.props.floor;
    let updated = false;
    let updatedDescriptions = (descriptions || []).map((description) => {
      if (description.language === Language.Dutch) {
        updated = true;
        return { language: Language.Dutch, text };
      }
      return description;
    });

    if (!updated) {
      updatedDescriptions = [
        ...updatedDescriptions,
        { language: Language.Dutch, text },
      ];
    }

    return updatedDescriptions;
  }
}
