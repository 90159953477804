import {
  Bid,
  BidType,
  LinkedRelation,
  AssignmentType
} from "@haywork/api/kolibri";
import { Modal, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { BidsModalContainerProps } from "./bids-modal.container";
import Accept from "./components/accept";
import Bids from "./components/bids";
import Offer from "./components/offer";
import Reject from "./components/reject";
import Dossier from "./components/dossier";
import { BidAction } from "../..";
import { BidDossier } from "@haywork/util";

export type BidsModalView =
  | "create"
  | "edit"
  | "accept"
  | "reject"
  | "bids"
  | "dossier";
export type BidsModalComponentProps = {
  visible: boolean;
  bid: Bid | null;
  originalBid: Bid | null;
  view: BidsModalView | null;
  linkedVendors: LinkedRelation[];
  assignmentId: string;
  typeOfAssignment: AssignmentType;
  dossier: BidDossier | null;
  onClose: () => void;
  onUpdated: (close: boolean) => void;
  onBidAccepted?: () => void;
};
type Props = BidsModalComponentProps & BidsModalContainerProps;

export const BidsModalComponent: FC<Props> = memo(
  ({
    visible,
    onClose,
    bid: refBid,
    originalBid: originalBidRef,
    saveBid,
    acceptBid,
    rejectBid,
    onUpdated,
    view: refView,
    linkedVendors,
    createCounterOffer,
    readBid,
    assignmentId,
    navigate,
    onBidAccepted,
    typeOfAssignment,
    dossier,
    deleteBid
  }) => {
    const [bid, setBid] = useState(refBid);
    const [originalBid, setOriginalBid] = useState(originalBidRef);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(refView);
    const [actionFromModal, setActionFromModal] = useState(false);

    useEffect(() => {
      setView(refView);
    }, [refView]);

    useEffect(() => {
      setBid(refBid);
    }, [refBid]);

    useEffect(() => {
      setOriginalBid(originalBidRef);
    }, [originalBidRef]);

    const title = useMemo(() => {
      const type =
        typeOfAssignment === AssignmentType.Object ? "object" : "acquisition";

      if (view === "create" && !!originalBid) {
        return `bidsModal.header.${type}.${view}.${bid.type
          .toString()
          .toLowerCase()}`;
      }

      if (view === "reject") {
        return `bidsModal.header.${type}.${view}.${bid.type
          .toString()
          .toLowerCase()}`;
      }

      return `bidsModal.header.${type}.${view}`;
    }, [bid, originalBid, view, typeOfAssignment]);

    const relationsToNotify = useMemo(() => {
      if (!bid || view === "edit") return null;
      const { type } = bid;

      switch (true) {
        case type === BidType.Outgoing && !!originalBid: {
          const { linkedRelations } = originalBid;
          return linkedRelations || [];
        }
        case view === "create" && type === BidType.Outgoing: {
          return linkedVendors || [];
        }
        case view === "reject" && type === BidType.Outgoing: {
          return linkedVendors || [];
        }
        case ["accept", "reject"].includes(view) && type === BidType.Incoming: {
          const { linkedRelations } = bid;
          return linkedRelations || [];
        }
        default: {
          return null;
        }
      }
    }, [bid, originalBid, view, linkedVendors]);

    const onSaveBid = useCallback(async () => {
      try {
        setLoading(true);
        await saveBid(bid);
        if (actionFromModal) {
          setView(
            typeOfAssignment === AssignmentType.Object ? "dossier" : "bids"
          );
        }
        onUpdated(!actionFromModal);
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal, typeOfAssignment]);

    const onAcceptBid = useCallback(async () => {
      try {
        setLoading(true);
        await saveBid(bid);
        await acceptBid(bid.id);
        if (actionFromModal) {
          setView(
            typeOfAssignment === AssignmentType.Object ? "dossier" : "bids"
          );
        }
        onUpdated(!actionFromModal);
        if (onBidAccepted) {
          onBidAccepted();
        }
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal]);

    const onRejectBid = useCallback(async () => {
      try {
        setLoading(true);
        await rejectBid(bid.id);
        if (actionFromModal) {
          setView(
            typeOfAssignment === AssignmentType.Object ? "dossier" : "bids"
          );
        }
        onUpdated(!actionFromModal);
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal]);

    const onCloseHandler = useCallback(() => {
      if (actionFromModal) {
        setActionFromModal(false);
        onUpdated(true);
      } else {
        onClose();
      }
    }, [actionFromModal, setActionFromModal]);

    const onActionHandler = useCallback(
      async (id: string, action: BidAction) => {
        const bid = await readBid(id);
        if (!bid) return;

        switch (action) {
          case BidAction.Accept: {
            setActionFromModal(true);
            setBid(bid);
            setView("accept");
            break;
          }
          case BidAction.CounterOffer: {
            const { type } = bid;
            const relations =
              type === BidType.Outgoing ? linkedVendors || [] : undefined;
            const counterOffer = await createCounterOffer(bid, relations);
            if (!counterOffer) return;
            setActionFromModal(true);
            setBid(counterOffer);
            setOriginalBid(bid);
            setView("create");
            break;
          }
          case BidAction.Edit: {
            setActionFromModal(true);
            setBid(bid);
            setView("edit");
            break;
          }
          case BidAction.Reject: {
            setActionFromModal(true);
            setBid(bid);
            setView("reject");
            break;
          }
          case BidAction.Delete: {
            const shouldClose =
              typeOfAssignment === AssignmentType.Object &&
              !!dossier &&
              dossier.bids.length === 1;
            await deleteBid(bid.id, () => onUpdated(false));
            onUpdated(shouldClose);
            break;
          }
          default:
            return;
        }
      },
      [
        readBid,
        setActionFromModal,
        setBid,
        setView,
        createCounterOffer,
        setOriginalBid,
        deleteBid,
        onUpdated,
        typeOfAssignment,
        dossier
      ]
    );

    const navigateTo = (path: string) => {
      visible = false;
      navigate(path);
    };

    const onBackToList = useCallback(() => {
      setView(typeOfAssignment === AssignmentType.Object ? "dossier" : "bids");
    }, [setView, typeOfAssignment]);

    const renderedView = useMemo(() => {
      switch (view) {
        case "create":
        case "edit": {
          return (
            <Offer
              bid={bid}
              originalBid={originalBid}
              loading={loading}
              actionFromModal={actionFromModal}
              relationsToNotify={relationsToNotify}
              onUpdate={setBid}
              onSave={onSaveBid}
              onBack={onBackToList}
            />
          );
        }
        case "accept": {
          return (
            <Accept
              bid={bid}
              loading={loading}
              actionFromModal={actionFromModal}
              relationsToNotify={relationsToNotify}
              onUpdate={setBid}
              onAccept={onAcceptBid}
              onBack={onBackToList}
            />
          );
        }
        case "reject": {
          return (
            <Reject
              loading={loading}
              type={bid && bid.type}
              actionFromModal={actionFromModal}
              relationsToNotify={relationsToNotify}
              onReject={onRejectBid}
              onBack={onBackToList}
            />
          );
        }
        case "bids": {
          return (
            <Bids
              assignmentId={assignmentId}
              onClose={onCloseHandler}
              onAction={onActionHandler}
              onNavigate={(path) => navigateTo(path)}
            />
          );
        }
        case "dossier": {
          return (
            <Dossier
              dossier={dossier}
              onClose={onCloseHandler}
              onAction={onActionHandler}
              onNavigate={(path) => navigateTo(path)}
            />
          );
        }
        default: {
          return null;
        }
      }
    }, [
      view,
      bid,
      originalBid,
      loading,
      dossier,
      setBid,
      onSaveBid,
      onAcceptBid,
      onBackToList,
      actionFromModal
    ]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title={title} close />
        {renderedView}
      </Modal>
    );
  }
);
