import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch } from "@haywork/middleware";
import {
  ProjectEditMarketingPhotosComponent,
  ProjectEditMarketingPhotosComponentProps
} from "@haywork/modules/project/components/marketing-photos/marketing-photos.component";
import { AppState, EditableActions, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";

interface StateProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  path: string;
}

interface DispatchProps {
  updateProject: (componentState: SingleProjectState, path: string) => void;
}

const mapStateToProps = <StateProps, ProjectEditMarketingPhotosComponentProps>(
  state: AppState
) => {
  const { currentComponentState } = state.editable;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id
    })
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ProjectEditMarketingPhotosComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type ProjectEditMarketingPhotosContainerProps = StateProps &
  DispatchProps;
export const ProjectEditMarketingPhotosContainer = connect<
  StateProps,
  DispatchProps,
  ProjectEditMarketingPhotosComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingPhotosComponent);
