import {
  AirTreatmentOption,
  FacilityOption,
  IndustrialBuildingFacilityOption,
  ObjectAssignment,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  airTreatmentOptions: AirTreatmentOption[];
  industrialBuildingFacilityOptions: IndustrialBuildingFacilityOption[];
  facilityOptions: FacilityOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogProductionHallComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      logisticFunction: {
        value: value(objectAssignment, "logisticFunction", false),
      },

      vVO: { value: value(objectAssignment, "vVO.area") },
      inUnitsFrom: { value: value(objectAssignment, "inUnitsFrom.area") },
      freeHeight: { value: value(objectAssignment, "freeHeight") },
      freeOvercurrent: { value: value(objectAssignment, "freeOvercurrent") },
      floorLoad: { value: value(objectAssignment, "floorLoad") },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },

      airTreatments: { value: value(objectAssignment, "airTreatments", []) },
      industrialBuildingFacilities: {
        value: value(objectAssignment, "industrialBuildingFacilities", []),
      },

      officeSpaceSurface: {
        value: value(objectAssignment, "officeSpaceSurface.area"),
      },
      officeSpaceBuildingLayerNumber: {
        value: value(objectAssignment, "officeSpaceBuildingLayerNumber", 0),
      },
      officeSpaceAirTreatments: {
        value: value(objectAssignment, "officeSpaceAirTreatments", []),
      },
      officeSpaceFacilities: {
        value: value(objectAssignment, "officeSpaceFacilities", []),
      },

      terrainSurface: { value: value(objectAssignment, "terrainSurface.area") },
      terrainBuildingVolumeArea: {
        value: value(objectAssignment, "terrainBuildingVolume.area"),
        onChange: (ref, get) => {
          const value = ref.value;
          const terrainSurface = get("terrainSurface");
          const terrainBuildingVolumeAreaPercentage = parseFloat(
            (
              (parseFloat(value) / parseFloat(terrainSurface.value)) *
              100
            ).toFixed(2)
          );
          if (!isNaN(terrainBuildingVolumeAreaPercentage)) {
            return { terrainBuildingVolumeAreaPercentage };
          }
        },
      },
      terrainBuildingVolumeAreaPercentage: {
        value: value(objectAssignment, "terrainBuildingVolumeAreaPercentage"),
        onChange: (ref, get) => {
          const value = ref.value;
          const terrainSurface = get("terrainSurface");
          const terrainBuildingVolumeArea = parseFloat(
            (
              (parseFloat(terrainSurface.value) / 100) *
              parseFloat(value)
            ).toFixed(2)
          );
          if (!isNaN(terrainBuildingVolumeArea)) {
            return { terrainBuildingVolumeArea };
          }
        },
      },
      terrainBuildingVolumeHeight: {
        value: value(objectAssignment, "terrainBuildingVolume.height"),
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update(
        {
          logisticFunction: value(objectAssignment, "logisticFunction", false),
          vVO: value(objectAssignment, "vVO.area"),
          inUnitsFrom: value(objectAssignment, "inUnitsFrom.area"),
          freeHeight: value(objectAssignment, "freeHeight"),
          freeOvercurrent: value(objectAssignment, "freeOvercurrent"),
          floorLoad: value(objectAssignment, "floorLoad"),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
          airTreatments: value(objectAssignment, "airTreatments", []),
          industrialBuildingFacilities: value(
            objectAssignment,
            "industrialBuildingFacilities",
            []
          ),
          officeSpaceSurface: value(
            objectAssignment,
            "officeSpaceSurface.area"
          ),
          officeSpaceBuildingLayerNumber: value(
            objectAssignment,
            "officeSpaceBuildingLayerNumber",
            0
          ),
          officeSpaceAirTreatments: value(
            objectAssignment,
            "officeSpaceAirTreatments",
            []
          ),
          officeSpaceFacilities: value(
            objectAssignment,
            "officeSpaceFacilities",
            []
          ),
          terrainSurface: value(objectAssignment, "terrainSurface.area"),
          terrainBuildingVolumeArea: value(
            objectAssignment,
            "terrainBuildingVolume.area"
          ),
          terrainBuildingVolumeAreaPercentage: value(
            objectAssignment,
            "terrainBuildingVolumeAreaPercentage"
          ),
          terrainBuildingVolumeHeight: value(
            objectAssignment,
            "terrainBuildingVolume.height"
          ),
        },
        true
      );
    }
  }

  public render() {
    const { forSale } = this.props.objectAssignment;

    return (
      <Form
        name="production-hall"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <Input.Switch
            name="logisticFunction"
            label="logisticFunction"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <h2 styleName="section-title">
          <ResourceText resourceKey="productionHallTitle" />
        </h2>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="productionHallSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="inUnitsFrom">
                <ResourceText resourceKey="inUnitsFrom" />
              </label>
              <div className="input__helper">
                <Input.Number name="inUnitsFrom" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="freeHeight">
                <ResourceText resourceKey="freeHeight" />
              </label>
              <div className="input__helper">
                <Input.Number name="freeHeight" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="meterShort" />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="freeOvercurrent">
                <ResourceText resourceKey="freeOvercurrent" />
              </label>
              <div className="input__helper">
                <Input.Number name="freeOvercurrent" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="meterShort" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="floorLoad">
                <ResourceText resourceKey="floorLoad" />
              </label>
              <div className="input__helper">
                <Input.Number name="floorLoad" pretty />
                <div className="post full">
                  <ResourceText resourceKey="kgPerSquareMeter" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="airTreatments">
            <ResourceText resourceKey="airTreatments" />
          </label>
          <Input.Multi
            name="airTreatments"
            values={this.props.airTreatmentOptions}
          />
        </div>

        <div className="form__row">
          <label htmlFor="industrialBuildingFacilities">
            <ResourceText resourceKey="industrialBuildingFacilities" />
          </label>
          <Input.Multi
            name="industrialBuildingFacilities"
            values={this.props.industrialBuildingFacilityOptions}
          />
        </div>

        {/* Office space */}
        {this.props.objectAssignment.hasOfficeSpace && (
          <div>
            <h2 styleName="section-title">
              <ResourceText resourceKey="productionHallOfficeSpace" />
            </h2>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="officeSpaceSurface">
                    <ResourceText resourceKey="surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="officeSpaceSurface" step={50} pretty />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="officeSpaceBuildingLayerNumber">
                    <ResourceText resourceKey="numberOfFloorsBog" />
                  </label>
                  <Input.Number name="officeSpaceBuildingLayerNumber" />
                </div>
              </div>
            </div>

            <div className="form__row">
              <label htmlFor="officeSpaceAirTreatments">
                <ResourceText resourceKey="airTreatments" />
              </label>
              <Input.Multi
                name="officeSpaceAirTreatments"
                values={this.props.airTreatmentOptions}
              />
            </div>

            <div className="form__row">
              <label htmlFor="officeSpaceFacilities">
                <ResourceText resourceKey="officeSpaceFacilities" />
              </label>
              <Input.Multi
                name="officeSpaceFacilities"
                values={this.props.facilityOptions}
              />
            </div>
          </div>
        )}

        {/* Terrain */}
        {this.props.objectAssignment.hasTerrain && (
          <div>
            <h2 styleName="section-title">
              <ResourceText resourceKey="productionHallTerrain" />
            </h2>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="terrainSurface">
                    <ResourceText resourceKey="surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="terrainSurface"
                      step={50}
                      pretty
                      min={0}
                      max={999999999999}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2 styleName="section-title sub">
              <ResourceText resourceKey="productionHallExtensionPossibilities" />
            </h2>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="terrainBuildingVolumeAreaPercentage">
                    <ResourceText resourceKey="terrainBuildingVolumeAreaPercentage" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="terrainBuildingVolumeAreaPercentage"
                      min={0}
                      max={1000}
                    />
                    <div className="post">%</div>
                  </div>
                </div>
                <div className="column__textspacer no-label">=</div>
                <div className="column" styleName="size-field">
                  <label htmlFor="terrainBuildingVolumeArea">
                    <ResourceText resourceKey="surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="terrainBuildingVolumeArea"
                      step={50}
                      pretty
                      min={0}
                      max={999999999999}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="terrainBuildingVolumeHeight">
                    <ResourceText resourceKey="terrainBuildingVolumeHeight" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="terrainBuildingVolumeHeight"
                      step={50}
                      pretty
                    />
                    <div className="post full">
                      <ResourceText resourceKey="centimeterShort" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let fields: any = {
      logisticFunction: values.logisticFunction,
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      inUnitsFrom: {
        ...objectAssignment.inUnitsFrom,
        area: values.inUnitsFrom,
      },
      freeHeight: values.freeHeight,
      freeOvercurrent: values.freeOvercurrent,
      floorLoad: values.floorLoad,
      parcelSurface: {
        ...objectAssignment.inUnitsFrom,
        area: values.parcelSurface,
      },
      airTreatments: values.airTreatments,
      industrialBuildingFacilities: values.industrialBuildingFacilities,
    };

    if (objectAssignment.hasOfficeSpace) {
      fields = {
        ...fields,
        officeSpaceSurface: {
          ...objectAssignment.officeSpaceSurface,
          area: values.officeSpaceSurface,
        },
        officeSpaceBuildingLayerNumber: values.officeSpaceBuildingLayerNumber,
        officeSpaceAirTreatments: values.officeSpaceAirTreatments,
        officeSpaceFacilities: values.officeSpaceFacilities,
      };
    }

    if (objectAssignment.hasTerrain) {
      fields = {
        ...fields,
        terrainBuildingVolume: {
          ...objectAssignment.terrainBuildingVolume,
          area: values.terrainBuildingVolumeArea,
          height: values.terrainBuildingVolumeHeight,
        },
        terrainBuildingVolumeAreaPercentage:
          values.terrainBuildingVolumeAreaPercentage,
        terrainSurface: {
          ...objectAssignment.terrainSurface,
          area: values.terrainSurface,
        },
      };
    }

    this.props.onChange(fields);
  }
}
