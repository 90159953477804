import {
  Account,
  FolderCategory,
  FolderOrderBy,
  FoldersClient,
  FolderStatistics,
  FolderUnreadCount,
  SearchFoldersRequest,
  SortOrder,
} from "@haywork/api/mail";
import { COUNTS } from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, LoggingActions, LogType } from "@haywork/stores";
import { EmailActionsV2, EmailFolder } from "@haywork/stores/email-v2";
import { EmailRequestState } from "@haywork/stores/email-v2/main";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { EmailUtil } from "@haywork/util/email-v2";
import get from "lodash-es/get";
import { v4 as uuid } from "uuid";
import { Dispatch } from "../";
import Messages from "./messages";

const parseRequest = new ParseRequest();

const getFolders = (accounts: Account[], flushCache = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(
          EmailRequestState.FetchingFolders
        )
      );
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new FoldersClient(emailHost);

      let folders: EmailFolder[] = [];

      await Promise.all(
        accounts.map(async (account) => {
          const request: SearchFoldersRequest = {
            accountId: account.id,
            skip: 0,
            take: COUNTS.EMAIL_FOLDERS,
            order: SortOrder.Ascending,
            orderBy: FolderOrderBy.Default,
            flushCache,
            includeStatistics: false,
          };

          const response = await parseRequest.response(
            client.search(request, realEstateAgencyId)
          );

          if (!response) throw new Error("E-mail folders request failed");
          const { totalResults, resultCount, results } = response;
          const { folders: refs } = state.emailV2.folders;

          folders = [
            ...folders,
            ...results.map((result) =>
              EmailUtil.mapFolderToEmailFolder(result, account.id, refs)
            ),
          ];

          if (totalResults > resultCount) {
            const diff = totalResults - resultCount;
            const count = Math.ceil(diff / COUNTS.EMAIL_FOLDERS);

            for (let i = 1; i <= count; i++) {
              const response = await client
                .search(
                  {
                    ...request,
                    skip: i * COUNTS.EMAIL_FOLDERS,
                    flushCache: false,
                  },
                  realEstateAgencyId
                )
                .then((response) => response.results);

              folders = [
                ...folders,
                ...response.map((result) =>
                  EmailUtil.mapFolderToEmailFolder(result, account.id, refs)
                ),
              ];
            }
          }

          // Add syncingMessage folder
          const syncingMessageFolder: EmailFolder = {
            category: FolderCategory.SynchronizingMessage,
            hasSubFolders: false,
            accountId: account.id,
            unreadCount: 0,
            position: 0,
            id: uuid(),
            displayName: FolderCategory.SynchronizingMessage.toString(),
            fullPath: FolderCategory.SynchronizingMessage.toString(),
          };

          folders = [...folders, syncingMessageFolder];
        })
      );

      dispatch(EmailActionsV2.Folders.setFolders(folders));
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Idle)
      );

      return folders;
    } catch (error) {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Error)
      );
      throw error;
    }
  };
};

const getAccountFolders = (account: Account) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(
          EmailRequestState.FetchingFolders
        )
      );
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new FoldersClient(emailHost);

      let folders: EmailFolder[] = [];

      const request: SearchFoldersRequest = {
        accountId: account.id,
        skip: 0,
        take: COUNTS.EMAIL_FOLDERS,
        order: SortOrder.Ascending,
        orderBy: FolderOrderBy.Default,
        flushCache: true,
        includeStatistics: false,
      };

      const response = await parseRequest.response(
        client.search(request, realEstateAgencyId)
      );

      if (!response) throw new Error("E-mail folders request failed");
      const { totalResults, resultCount, results } = response;
      const { folders: refs } = state.emailV2.folders;

      folders = [
        ...folders,
        ...results.map((result) =>
          EmailUtil.mapFolderToEmailFolder(result, account.id, refs)
        ),
      ];

      if (totalResults > resultCount) {
        const diff = totalResults - resultCount;
        const count = Math.ceil(diff / COUNTS.EMAIL_FOLDERS);

        for (let i = 1; i <= count; i++) {
          const response = await client
            .search(
              {
                ...request,
                skip: i * COUNTS.EMAIL_FOLDERS,
                flushCache: false,
              },
              realEstateAgencyId
            )
            .then((response) => response.results);

          folders = [
            ...folders,
            ...response.map((result) =>
              EmailUtil.mapFolderToEmailFolder(result, account.id, refs)
            ),
          ];
        }
      }

      dispatch(EmailActionsV2.Folders.setAccountFolders(folders, account.id));
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Idle)
      );
    } catch (error) {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Error)
      );
      throw error;
    }
  };
};

const getFolderCounts = (flushCache = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { accounts } = state.emailV2.accounts;
      const client = new FoldersClient(emailHost);

      let folderStatistics: FolderStatistics = { counts: [] };

      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        const request: SearchFoldersRequest = {
          accountId: account.id,
          skip: 0,
          take: COUNTS.EMAIL_FOLDERS,
          order: SortOrder.Ascending,
          orderBy: FolderOrderBy.Default,
          flushCache,
          includeStatistics: true,
          folderCategory: FolderCategory.Inbox,
        };

        const response = await parseRequest.response(
          client.search(request, realEstateAgencyId),
          ApiType.Email,
          { displayError: false }
        );

        if (!response)
          throw new Error("E-mail folder statistics request failed");
        const { statistics, totalResults, resultCount } = response;
        const counts = get(statistics, "counts", [] as FolderUnreadCount[]);

        folderStatistics = {
          ...folderStatistics,
          counts: [...folderStatistics.counts, ...counts],
        };

        if (totalResults > resultCount) {
          const diff = totalResults - resultCount;
          const count = Math.ceil(diff / COUNTS.EMAIL_FOLDERS);
          const promises: Promise<FolderStatistics>[] = [];

          for (let i = 1; i <= count; i++) {
            promises.push(
              client
                .search(
                  {
                    ...request,
                    skip: i * COUNTS.EMAIL_FOLDERS,
                  },
                  realEstateAgencyId
                )
                .then((response) => response.statistics)
            );
          }

          const response = await Promise.all(promises);
          response.forEach((statistics) => {
            const counts = get(statistics, "counts", [] as FolderUnreadCount[]);
            folderStatistics = {
              ...folderStatistics,
              counts: [...folderStatistics.counts, ...counts],
            };
          });
        }
      }

      dispatch(EmailActionsV2.Folders.setFoldersCount(folderStatistics));

      return folderStatistics;
    } catch (error) {
      throw error;
    }
  };
};

const saveFolder = (
  accountId: string,
  parentId: string | null,
  displayName: string,
  id?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { folders } = state.emailV2.folders;
      const client = new FoldersClient(emailHost);

      const folder = await parseRequest.response(
        client
          .saveFolder(
            { accountId, parentId, displayName, id },
            realEstateAgencyId
          )
          .then((response) => response.folder)
      );
      if (!folder) throw new Error("Folder save request failed");
      const emailFolder = EmailUtil.mapFolderToEmailFolder(
        folder,
        accountId,
        folders
      );

      if (!!id) {
        dispatch(EmailActionsV2.Folders.updateFolder(emailFolder));
      } else {
        dispatch(EmailActionsV2.Folders.addFolder(emailFolder));
      }

      return emailFolder;
    } catch (error) {
      throw error;
    }
  };
};

const removeFolder = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let folder: EmailFolder;

    try {
      const state = getState();
      const { folders } = state.emailV2.folders;
      folder = folders.find((folder) => folder.id === id);

      if (!folder) throw new Error("Folder to delete not found");
      const { accountId, id: folderId } = folder;

      dispatch(EmailActionsV2.Folders.deleteFolder(id));
      const hasMessages = await dispatch(
        Messages.checkIfFolderHasMessages(accountId, folderId)
      );
      if (hasMessages) {
        const toast: ToastProps = {
          value: "emailFolder.remove.notEmpty.error",
          icon: "exclamation-triangle",
          persistFor: 10000,
        };
        dispatch(SnackbarActions.addToast(toast));
        throw new Error("Email folder is not empty");
      }

      await dispatch(deleteFolder(accountId, folderId));
    } catch (error) {
      dispatch(EmailActionsV2.Folders.addFolder(folder));
      throw error;
    }
  };
};

const deleteFolder = (accountId: string, folderId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new FoldersClient(emailHost);

      await parseRequest.response(
        client.deleteFolder(accountId, folderId, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const moveFolder = (folderId: string, parentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let folder: EmailFolder;

    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { folders } = state.emailV2.folders;
      const client = new FoldersClient(emailHost);

      folder = folders.find((folder) => folder.id === folderId);
      if (!folder) throw new Error("Email folder could not be found.");
      const { id, accountId, displayName } = folder;

      dispatch(
        EmailActionsV2.Folders.updateFolder({
          ...folder,
          parentId,
        })
      );

      await parseRequest.response(
        client.saveFolder(
          { accountId, id, parentId, displayName },
          realEstateAgencyId
        )
      );
    } catch (error) {
      if (!!folder) {
        dispatch(EmailActionsV2.Folders.updateFolder(folder));
      }

      throw error;
    }
  };
};

const refreshAccountFolders = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { accounts } = state.emailV2.accounts;
      const match = accounts.find((account) => account.id === id);

      if (!match) {
        dispatch(
          LoggingActions.addLogging({
            logType: LogType.EmailThunk,
            label: "No match for account found in current state",
            payload: JSON.stringify({
              thunk: "emailV2/folders.ts/refreshAccountFolders",
              id,
            }),
          })
        );
        return;
      }

      dispatch(getAccountFolders(match));
    } catch (error) {
      dispatch(
        LoggingActions.addLogging({
          logType: LogType.EmailThunk,
          label: "Error",
          payload: JSON.stringify({
            thunk: "emailV2/folders.ts/refreshAccountFolders",
            error,
          }),
        })
      );
      throw error;
    }
  };
};

export default {
  getFolders,
  getFolderCounts,
  saveFolder,
  removeFolder,
  moveFolder,
  refreshAccountFolders,
};
