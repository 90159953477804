import { CompanyListing } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import * as ActionType from "./single.types";

const setAssignment = (payload: ActionType.SingleAssignment) => ({
  type: REDUX.ASSIGNMENT.SET_ASSIGNMENT,
  ...payload,
});

const updateSingleAssignment = (payload: ActionType.SingleAssignment) => ({
  type: REDUX.ASSIGNMENT.UPDATE_SINGLE_ASSIGNMENT,
  ...payload,
});

const setAssignmentStatus = (objectAssignmentState: string) => ({
  type: REDUX.ASSIGNMENT.SET_ASSIGNMENT_STATUS,
  objectAssignmentState,
});

const setAssignmentAddress = (payload: ActionType.SingleAddress) => ({
  type: REDUX.ASSIGNMENT.SET_ADDRESS,
  ...payload,
});

const updateAssignment = (payload: ActionType.SingleObjectAssignment) => ({
  type: REDUX.ASSIGNMENT.UPDATE_ASSIGNMENT,
  ...payload,
});

const updateCompanyListings = (companyListings: CompanyListing[]) => ({
  type: REDUX.ASSIGNMENT.UPDATE_COMPANY_LISTINGS,
  companyListings,
});

const setPublishStatus = (payload: ActionType.PublishState) => ({
  type: REDUX.ASSIGNMENT.SET_PUBLISH_STATUS,
  ...payload,
});

const setChangeAvailabilityStatus = (
  payload: ActionType.ChangeAvailabilityStatus
) => ({
  type: REDUX.ASSIGNMENT.SET_CHANGE_AVAILABILITY_STATUS,
  ...payload,
});

const toggleStatusModal = (showStatusModal: boolean) => ({
  type: REDUX.ASSIGNMENT.TOGGLE_STATUS_MODAL,
  showStatusModal,
});

const setSaveAssignmentStatus = (saveAssignmentState: string) => ({
  type: REDUX.ASSIGNMENT.SET_SAVE_ASSIGNMENTS_STATUS,
  saveAssignmentState,
});

const setAddressSearchState = (addressSearchState: string) => ({
  type: REDUX.ASSIGNMENT.SET_ADDRESS_SEARCH_STATUS,
  addressSearchState,
});

const toggleWithdrawModal = (showWithdrawModal: boolean) => ({
  type: REDUX.ASSIGNMENT.TOGGLE_WITHDRAW_MODAL,
  showWithdrawModal,
});

const setWithdrawState = (payload: ActionType.WithdrawState) => ({
  type: REDUX.ASSIGNMENT.SET_WITHDRAW_STATE,
  ...payload,
});

const toggleSaveModal = (showSaveModal: boolean) => ({
  type: REDUX.ASSIGNMENT.TOGGLE_SAVE_MODAL,
  showSaveModal,
});

const setTimelineEventsStatus = (timelineStatus: string) => ({
  type: REDUX.ASSIGNMENT.SET_TIMELINE_STATUS,
  timelineStatus,
});

const setTimelineEvents = (payload: ActionType.Events) => ({
  type: REDUX.ASSIGNMENT.SET_TIMELINE_EVENTS,
  ...payload,
});

const appendTimelineEvents = (payload: ActionType.Events) => ({
  type: REDUX.ASSIGNMENT.APPEND_TIMELINE_EVENTS,
  ...payload,
});

const setMediaPartnerState = (payload: ActionType.MediaPartnerState) => ({
  type: REDUX.ASSIGNMENT.SET_MEDIAPARTNER_STATE,
  ...payload,
});

const setCadastreState = (payload: ActionType.CadastreState) => ({
  type: REDUX.ASSIGNMENT.SET_CADASTRE_STATE,
  ...payload,
});

const removeCompanyListing = (id: string) => ({
  type: REDUX.ASSIGNMENT.REMOVE_COMPANY_LISTING,
  id,
});

const setBrochureStatus = (brochureStatus: string, brochureId?: string) => ({
  type: REDUX.ASSIGNMENT.SET_BROCHURE_STATUS,
  brochureStatus,
  brochureId,
});

const setBrochureLink = (brochureLink: string) => ({
  type: REDUX.ASSIGNMENT.SET_BROCHURE_LINK,
  brochureLink,
});

const setLastMarketingRoute = (payload: ActionType.LastMarketingRoute) => ({
  type: REDUX.ASSIGNMENT.SET_LAST_MARKETING_ROUTE,
  ...payload,
});

export const AssignmentSingleActions = {
  setAssignment,
  updateSingleAssignment,
  setAssignmentStatus,
  setAssignmentAddress,
  updateAssignment,
  updateCompanyListings,
  setPublishStatus,
  setChangeAvailabilityStatus,
  toggleStatusModal,
  setSaveAssignmentStatus,
  setAddressSearchState,
  toggleWithdrawModal,
  setWithdrawState,
  toggleSaveModal,
  setTimelineEventsStatus,
  setTimelineEvents,
  appendTimelineEvents,
  setMediaPartnerState,
  setCadastreState,
  removeCompanyListing,
  setBrochureStatus,
  setBrochureLink,
  setLastMarketingRoute,
};
