import { VideoBlob } from "@haywork/api/kolibri";

export enum VideoCategory {
  Youtube = "Youtube",
  Vimeo = "Vimeo",
  Other = "Other"
}

export class Video {
  public static createVideoBlob(urlOriginal: string): VideoBlob {
    return {
      urlOriginal,
      height: 0,
      width: 0,
      fileSize: 0,
      description: "",
      name: "",
      dateTimeCreated: new Date(),
      dateTimeModified: new Date()
   };
  }
}
