import {
  ArchiveFilter,
  LinkedAppClient,
  LinkedEmployee,
  ReviewClient,
  ReviewOrderByField,
  SortOrder
} from "@haywork/api/authorization";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { AuthorizationActions } from "@haywork/stores/authorization";
import omit from "lodash-es/omit";
import { Dispatch } from "../";
import { AppClient } from "./app-clients";
import { ToastProps, SnackbarActions } from "@haywork/stores/snackbar-v2";

const parseRequest = new ParseRequest();

const getReviews = (appClientId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AuthorizationActions.SingleAppClient.setReviewsStatus(REQUEST.PENDING)
    );
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Review = new ReviewClient(authorizationHost);

    try {
      const reviews = await parseRequest.response(
        Review.search(
          {
            orderBy: ReviewOrderByField.Rating,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Descending,
            skip: 0,
            take: 100,
            appClientId
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AuthorizationActions.SingleAppClient.setReviews(reviews));
    } catch (error) {
      dispatch(
        AuthorizationActions.SingleAppClient.setReviewsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

export interface CreateReviewRequest {
  id?: string;
  linkedEmployee: LinkedEmployee;
  linkedAppClient: LinkedAppClient;
  rate: number;
  reviewText: string;
}
const createAndSaveReview = (request: CreateReviewRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Review = new ReviewClient(authorizationHost);

    try {
      let review = await parseRequest.response(
        Review.defineNew(
          { appClientId: request.linkedAppClient.id },
          realEstateAgencyId
        ).then((response) => response.review)
      );

      const cleanedRequest = omit(request, ["id"]);

      review = {
        ...review,
        ...cleanedRequest
      };

      await parseRequest.response(
        Review.saveReview({ review }, realEstateAgencyId)
      );

      dispatch(getReviews(request.linkedAppClient.id));
      dispatch(AppClient.getClient(request.linkedAppClient.id, true));
    } catch (error) {
      throw error;
    }
  };
};

const updateReview = (request: CreateReviewRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Review = new ReviewClient(authorizationHost);

    try {
      let review = await parseRequest.response(
        Review.readReview(request.id, realEstateAgencyId).then(
          (response) => response.review
        )
      );

      review = {
        ...review,
        ...request
      };

      await parseRequest.response(
        Review.saveReview({ review }, realEstateAgencyId)
      );

      dispatch(getReviews(request.linkedAppClient.id));
      dispatch(AppClient.getClient(request.linkedAppClient.id, true));
    } catch (error) {
      throw error;
    }
  };
};

const deleteReview = (id: string, appClientId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Review = new ReviewClient(authorizationHost);

    try {
      await parseRequest.response(Review.deleteReview(id, realEstateAgencyId));

      dispatch(getReviews(appClientId));
      dispatch(AppClient.getClient(appClientId, true));

      const toast: ToastProps = {
        value: "appClientReviewDeleted",
        icon: "rocket"
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      throw error;
    }
  };
};

export const Reviews = {
  getReviews,
  createAndSaveReview,
  updateReview,
  deleteReview
};
