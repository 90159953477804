import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../loaders.scss");

export const IndeterminateLoader = CSSModules(styles, { allowMultiple: true })(
  () => (
    <div styleName="progress">
      <div styleName="indeterminate"></div>
    </div>
  )
);
