import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { ActiveFilter, AssignmentSnapShot } from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks
} from "@haywork/middleware/thunk";
import {
  ObjectTypesDetailBuildnumbersComponent,
  ObjectTypesDetailBuildnumbersComponentProps
} from "@haywork/modules/object-types";
import { AppState, ProjectsActions } from "@haywork/stores";
import { ExtendedObjectTypeAssignment } from "@haywork/stores/project/types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  buildnumbers,
  buildnumbersLoading,
  fullyBakedDisplayName,
  objectTypePrice
} from "./selectors";

interface StateProps {
  currentType: ExtendedObjectTypeAssignment;
  buildnumbers: AssignmentSnapShot[];
  buildnumbersCanLoadMore: boolean;
  buildnumbersTotal: number;
  loading: boolean;
  price: number;
  displayName: string;
}
interface DispatchProps {
  getBuildnumbers: (
    objectTypeId: string,
    filterByActive: ActiveFilter,
    reset?: boolean
  ) => void;
  navigate: (path: string) => void;
  toggleAddBuildnumbers: (visible: boolean) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <
  StateProps,
  ObjectTypesDetailBuildnumbersComponentProps
>(
  state: AppState
) => {
  const {
    buildnumbersCanLoadMore,
    buildnumbersTotal,
    currentType
  } = state.project.types;

  return {
    currentType,
    buildnumbers: buildnumbers(state),
    buildnumbersCanLoadMore,
    buildnumbersTotal,
    loading: buildnumbersLoading(state),
    price: objectTypePrice(state),
    displayName: fullyBakedDisplayName(state)
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesDetailBuildnumbersComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getBuildnumbers: (
    objectTypeId: string,
    filterByActive: ActiveFilter,
    reset?: boolean
  ) =>
    dispatch(
      ProjectsThunks.Types.getBuildnumbers(objectTypeId, filterByActive, reset)
    ),
  navigate: (path: string) => dispatch(push(path)),
  toggleAddBuildnumbers: (visible: boolean) =>
    dispatch(ProjectsActions.Buildnumbers.toggleAddBuildnumbers(visible)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type ObjectTypesDetailBuildnumbersContainerProps = StateProps &
  DispatchProps;
export const ObjectTypesDetailBuildnumbersContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesDetailBuildnumbersComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesDetailBuildnumbersComponent);
