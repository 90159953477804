import { ResourceText } from "@haywork/modules/shared";
import { ArrayUtil } from "@haywork/util";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { BaseFilter } from "../base/base-filter.component";
import { intlContext } from "@haywork/app";

const styles = require("./query.component.scss");

export interface MappedQueryValue {
  label: string;
  value: any;
  selected?: boolean;
  id?: string;
}

interface Props {
  inline?: boolean;
  placeholder: string;
  values: MappedQueryValue[];
  activeValues?: string[];
}
interface State {
  values: MappedQueryValue[];
  inputValue: string;
  visible: boolean;
  selectedValues: string[];
}

@CSSModules(styles, { allowMultiple: true })
export class QueryFilter extends BaseFilter<Props, State> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired,
  };
  private ref: HTMLDivElement;
  private refItemList: MappedQueryValue[];
  private activeValues: string[];

  constructor(props) {
    super(props);

    this.refItemList = this.props.values.map((item) => ({
      ...item,
      selected: false,
      id: uuid(),
    }));

    this.activeValues = this.props.activeValues || [];

    // Set initial state
    this.state = {
      ...this.state,
      values: this.refItemList,
      visible: false,
      inputValue: "",
      selectedValues: [],
    };

    // Event bindings
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onRemoveSelectedValue = this.onRemoveSelectedValue.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const queryStyleName = classNames("query", { inline: this.props.inline });
    const listStyleName = classNames("list", { visible: this.state.visible });
    const placeholder = intlContext.formatMessage({
      id: this.props.placeholder,
      defaultMessage: this.props.placeholder,
    });

    return (
      <div styleName={queryStyleName} ref={(ref) => (this.ref = ref)}>
        {this.state.selectedValues.map((value, idx) => (
          <div styleName="selected-value-pill" key={idx}>
            <div styleName="pill__label">{value.label}</div>
            <div
              styleName="pill__remove"
              onClick={() => this.onRemoveSelectedValue(value)}
            >
              <i className="fal fa-fw fa-times faIcon" />
            </div>
          </div>
        ))}
        <div styleName="input-wrapper">
          <input
            type="text"
            placeholder={placeholder}
            value={this.state.inputValue}
            onChange={this.onChangeHandler}
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            data-lpignore="true"
          />
          <div styleName={listStyleName}>
            {this.state.values.length === 0 && (
              <div styleName="item no-roles">
                <ResourceText resourceKey="noRoles" />
              </div>
            )}
            {this.state.values.map((item, idx) => {
              const itemStyle = classNames("item", { selected: item.selected });
              return (
                <div
                  styleName={itemStyle}
                  key={idx}
                  onClick={() => this.onItemClickHandler(idx)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const mappedValues = nextProps.values.map((item) => item.value);
    const mappedRefValues = this.refItemList.map((item) => item.value);

    if (
      !nextProps.values ||
      (ArrayUtil.isArrayEqual(mappedValues, mappedRefValues) &&
        this.activeValues.length === 0)
    )
      return;

    const innerSelectedValues = this.refItemList.reduce((state, ref) => {
      if (ref.selected) state.push(ref.value);
      return state;
    }, []);

    const values = (this.refItemList = nextProps.values.map((item) => ({
      ...item,
      selected:
        innerSelectedValues.indexOf(item.value) !== -1 ||
        this.activeValues.includes(item.value),
      id: uuid(),
    })));

    const selectedValues = values.filter((item) => item.selected);

    this.setState({ values, selectedValues });
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedValues.length !== this.state.selectedValues.length) {
      this.updateContext();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const match = new RegExp(escapeRegExp(value), "gi");
    const values = this.refItemList.filter((val) => match.test(val.label));

    this.setState({ values, inputValue: value });
  }

  public onFocusHandler(event: React.FocusEvent<HTMLInputElement>) {
    this.setState({ visible: true });
  }

  public onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    return;
  }

  private onItemClickHandler(idx: number) {
    const value = this.state.values[idx];
    const values = (this.refItemList = this.refItemList.map((item) =>
      item.id === value.id ? { ...item, selected: !value.selected } : item
    ));
    const selectedValues = values.filter((item) => !!item.selected);

    this.setState({ visible: false, inputValue: "", selectedValues, values });
  }

  private onRemoveSelectedValue(value) {
    this.activeValues = this.activeValues.filter(
      (item) => item !== value.value
    );
    const selectedValues = this.state.selectedValues.filter(
      (selectedValue) => selectedValue.value !== value.value
    );
    const values = (this.refItemList = this.refItemList.map((item) =>
      item.value === value.value ? { ...item, selected: false } : item
    ));

    this.setState({ selectedValues, values });
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.visible)
      this.setState({ visible: false });
  }

  private updateContext() {
    this.context.updateField(
      this.props.name,
      this.state.selectedValues.map((item) => item.value)
    );
  }
}
