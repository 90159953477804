import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { TutorialContainerProps } from "./container";

const styles = require("./style.scss");

export type TutorialProps = {
  visible: boolean;
  tutorialVideoLink: string;
  tutorialTitle: string;
  tutorialSubText?: string;
  onCloseHandler: () => void;
};

type Props = TutorialProps & TutorialContainerProps;

export const TutorialComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      visible,
      tutorialVideoLink,
      tutorialTitle,
      tutorialSubText,
      onCloseHandler,
    }) => {
      return (
        <Modal visible={visible} onClose={onCloseHandler}>
          <ModalHeader title={tutorialTitle} close />
          <ModalBody>
            <iframe styleName="tutorialFrame" src={tutorialVideoLink} />
            <div styleName="subText">
              <I18n value={tutorialSubText} />
            </div>
          </ModalBody>
        </Modal>
      );
    }
  )
);
