import { actions as EventCenterActions } from "./actions";
import { State, reducer as eventCenterReducer } from "./reducer";
import {
  SortOrder,
  EventNotificationsOrderByField
} from "@haywork/api/event-center";

export type NotificationsOrder = {
  sortOrder: SortOrder;
  sortColumn: EventNotificationsOrderByField;
};

export type EventCenterState = State;
export { eventCenterReducer, EventCenterActions };
