import { Account, Folder } from "@haywork/api/mail";
import {
  Form,
  FormControls,
  FormReference,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
} from "@haywork/modules/form";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { EmailRequest } from "@haywork/request";
import { StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./move-modal.component.scss");

interface EmailMoveModalComponentProps {
  visible: boolean;
  account: Account;
  onClose: () => void;
  onSelectFolder: (folder: Folder) => void;
}
interface EmailMoveModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class EmailMoveModalComponent extends React.Component<
  EmailMoveModalComponentProps,
  EmailMoveModalComponentState
> {
  private form: FormReference;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);

    this.formControls = {
      folder: {
        value: "",
        onChange: (ref) => {
          if (!!ref.value) {
            this.props.onSelectFolder(ref.value);
            return {
              folder: "",
            };
          }
        },
      },
    };
  }

  public render() {
    return (
      <Modal visible={this.props.visible} onClose={this.onCloseHandler}>
        <ModalHeader title="emailSearchMoveFolderTitle" close />
        <ModalBody>
          <div styleName="move">
            <Form
              name="search-move-folder"
              formControls={this.formControls}
              form={(form) => (this.form = form)}
            >
              <Input.Query
                name="folder"
                optionValue={this.renderQueryBaseOption}
                selectedValue={this.renderQueryBaseValue}
                asyncValues={(term) =>
                  EmailRequest.searchFolder(term, this.props.account.id)
                }
                placeholder="emailSearchMoveFolderPlaceholder"
              />
            </Form>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  private onCloseHandler() {
    this.form.update({ folder: "" });
    this.props.onClose();
  }

  private renderQueryBaseOption(
    value: Folder,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div className="result">
        <div
          className="result__name"
          dangerouslySetInnerHTML={StringUtil.highlight(
            value.displayName,
            query
          )}
        />
        <div className="result__path">{value.fullPath}</div>
      </div>
    );
  }

  private renderQueryBaseValue(value: Folder): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }
}
