import { WidgetEntityType } from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  TaskCategoryOption,
  TimelineActionSubType,
  TimelineActionType,
  TimelineEvent,
} from "@haywork/api/kolibri";
import classNames from "classnames";
import * as React from "react";
import { CSSProperties, FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import AgendaItem from "./agenda-item";
import BidItem from "./bid-item";
import CommunicationEmailItem from "./communication-email-item";
import CommunicationPhoneItem from "./communication-phone-item";
import ContactMeItem from "./contact-me-item";
import InvoiceItem from "./invoice-item";
import RelocationItem from "./relocation-item";
import SearchprofileItem from "./searchprofile-item";
import TaskItem from "./task-item";
import TransactionItem from "./transaction-item";
import UnknownItem from "./unknown-item";
import WebsiteItem from "./website-item";
import { LinkedEntity } from "@haywork/components/ui/linked-entities";

const styles = require("./style.scss");

type Props = {
  event: TimelineEvent;
  parentId: string;
  style: CSSProperties;
  zebra: boolean;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  widgetEntityType?: WidgetEntityType;
  onNavigate: (path: string) => void;
  onDelete: (event: TimelineEvent) => void;
  onClick: (
    event: TimelineEvent,
    type: "email" | "phone" | "contactme"
  ) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

export const TimelineItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      event,
      style,
      zebra,
      parentId,
      taskCategories,
      agendaItemCategories,
      onNavigate,
      onClick,
      onDelete,
      onShowAllLinkedEntities,
    }) => {
      const { actionType, actionSubType } = event;
      const item = useMemo(() => {
        switch (actionType) {
          case TimelineActionType.AgendaItem: {
            return (
              <AgendaItem
                event={event}
                parentId={parentId}
                agendaItemCategories={agendaItemCategories}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Bid: {
            return (
              <BidItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.CommunicationLog: {
            switch (actionSubType) {
              case TimelineActionSubType.Email: {
                return (
                  <CommunicationEmailItem
                    event={event}
                    parentId={parentId}
                    onDelete={onDelete}
                    onNavigate={onNavigate}
                    onClick={(event) => onClick(event, "email")}
                    onShowAllLinkedEntities={onShowAllLinkedEntities}
                  />
                );
              }
              case TimelineActionSubType.PhoneCall: {
                return (
                  <CommunicationPhoneItem
                    event={event}
                    parentId={parentId}
                    onDelete={onDelete}
                    onNavigate={onNavigate}
                    onClick={(event) => onClick(event, "phone")}
                    onShowAllLinkedEntities={onShowAllLinkedEntities}
                  />
                );
              }
              default:
                return (
                  <UnknownItem
                    event={event}
                    parentId={parentId}
                    onNavigate={onNavigate}
                    onShowAllLinkedEntities={onShowAllLinkedEntities}
                  />
                );
            }
          }
          case TimelineActionType.ContactMe: {
            return (
              <ContactMeItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onClick={(event) => onClick(event, "contactme")}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Invoice: {
            return (
              <InvoiceItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Relocation: {
            return (
              <RelocationItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.SearchProfile: {
            return (
              <SearchprofileItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Task: {
            return (
              <TaskItem
                event={event}
                parentId={parentId}
                taskCategories={taskCategories}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Transaction: {
            return (
              <TransactionItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Unknown: {
            return (
              <UnknownItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          case TimelineActionType.Website: {
            return (
              <WebsiteItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
          }
          default:
            return (
              <UnknownItem
                event={event}
                parentId={parentId}
                onNavigate={onNavigate}
                onShowAllLinkedEntities={onShowAllLinkedEntities}
              />
            );
        }
      }, [actionType, actionSubType]);

      return (
        <div styleName={classNames("event", { zebra })} style={style}>
          {item}
        </div>
      );
    }
  )
);
