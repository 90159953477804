import { connect, MapStateToProps } from "react-redux";
import { VoipComponent, VoipComponentProps } from "./voip";
import { AppState, VoipActions } from "@haywork/stores";
import { VoipDetails } from "@haywork/api/voip";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {};
type DispatchProps = {
  addCall: (voipDetails: VoipDetails) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  VoipComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  VoipComponentProps
> = (dispatch) => ({
  addCall: (voipDetails: VoipDetails) =>
    dispatch(VoipActions.Calls.addCall(voipDetails))
});

export type VoipContainerProps = StateProps & DispatchProps;
export const VoipContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoipComponent);
