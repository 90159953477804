import { Dispatch } from "@haywork/middleware";
import { ModalComponent } from "@haywork/modules/modal";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface OwnProps {
  visible: boolean;
  large?: boolean;
  onClose?: () => void;
  closeOnBackDropClick?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export interface ModalContainerProps extends OwnProps {
  onClose: () => void;
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  visible: ownProps.visible,
  className: ownProps.className,
  style: ownProps.style,
});

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
});
export const Modal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalComponent);
