import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { RelationType, SearchAssignmentSnapShot } from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  Dispatch,
  SearchAssignmentThunks
} from "@haywork/middleware";
import {
  RelationSearchAssignmentsComponent,
  RelationSearchAssignmentsComponentProps
} from "@haywork/modules/relation";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignments: SearchAssignmentSnapShot[];
  relationSearchAssignmentsStatus: string;
  relationName: string;
  relationId: string;
  selectedRelationType: RelationType;
}

interface DispatchProps {
  getMatchingSearchAssignments: (relationId: string) => void;
  navigate: (url: string) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, RelationSearchAssignmentsComponentProps>(
  state: AppState
) => ({
  searchAssignments: state.relation.detail.searchAssignments,
  relationSearchAssignmentsStatus:
    state.relation.detail.relationSearchAssignmentsStatus,
  relationName: state.relation.detail.selectedRelationDisplayName,
  relationId: state.relation.detail.selectedRelationId,
  selectedRelationType: state.relation.detail.selectedRelationType
});

const mapDispatchToProps = <
  DispatchProps,
  RelationSearchAssignmentsComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getMatchingSearchAssignments: (relationId: string) =>
    dispatch(SearchAssignmentThunks.getMatchingSearchAssignments(relationId)),
  navigate: (url: string) => dispatch(push(url)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type RelationSearchAssignmentsContainerProps = StateProps &
  DispatchProps;
export const RelationSearchAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  RelationSearchAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationSearchAssignmentsComponent);
