import { FormElement } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import { Hint } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface DateComponentProps {
  formElement: FlattenedFormElement;
  value?: string;
  focus: boolean;
  onChange: (formElement: FormElement) => void;
  onFocus: (name: string) => void;
}
interface DateComponentState {
  value: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DateComponent extends React.Component<
  DateComponentProps,
  DateComponentState
> {
  constructor(props) {
    super(props);

    this.onChangehandler = this.onChangehandler.bind(this);

    this.state = {
      value: this.props.value || "",
    };
  }

  public render() {
    const {
      name,
      type,
      preText,
      postText,
      maximumLength,
      format,
      disabled,
    } = this.props.formElement;

    return (
      <div styleName="element">
        {!!preText && <div styleName="element__pre">{preText}</div>}
        <div styleName="element__input">
          <Hint message="fieldNotNeeded" shouldRender={disabled}>
            <Input.Datepicker
              name={name}
              value={this.state.value}
              onChange={this.onChangehandler}
              onFocus={() => this.props.onFocus(name)}
              focus={this.props.focus}
              disabled={disabled}
            />
          </Hint>
        </div>
        {!!postText && <div styleName="element__pre">{postText}</div>}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: DateComponentProps) {
    if (!nextProps) return false;
    return (
      nextProps.focus !== this.props.focus ||
      nextProps.formElement.disabled !== this.props.formElement.disabled
    );
  }

  private onChangehandler(value: string) {
    const oldDate = new Date(this.state.value).getTime();
    const newDate = new Date(value).getTime();
    if (oldDate === newDate) return;

    this.setState({ value });
    this.forceUpdate();

    const { name, type } = this.props.formElement;
    const formElement: FormElement = {
      name,
      type,
      dateInfo: {
        value: new Date(value),
      },
    };

    this.props.onChange(formElement);
  }
}
