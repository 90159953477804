import { Provider } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");

interface Props {
  redirectUri: string;
  provider: Provider;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AccountDataOAuthComponent extends React.Component<Props, State> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.onCopyHandler = this.onCopyHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="oauth">
        <div
          styleName="avatar"
          style={{
            backgroundImage: `url(${JSON.stringify(
              this.props.provider.logoUrl
            )})`,
          }}
        />
        <div styleName="oauth__body">
          <ResourceText
            resourceKey="addOAuthEmailbody"
            values={{ provider: this.props.provider.displayName }}
            asHtml
          />
        </div>
        <div styleName="oauth__link">
          <input
            type="text"
            value={this.props.redirectUri}
            ref={(ref) => (this.ref = ref)}
            readOnly
            data-lpignore="true"
          />
          <div styleName="copy" onClick={this.onCopyHandler}>
            <i className="fal fa-copy" />
          </div>
        </div>
      </div>
    );
  }

  private onCopyHandler() {
    if (!this.ref) return;
    this.ref.select();
    const success = document.execCommand("copy");
  }
}
