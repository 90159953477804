import { Task, TaskStatus } from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum TaskAction {
  Remove = "Remove",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Complete = "Complete",
  UnComplete = "UnComplete"
}

type Props = {
  task: Task;
  loading: boolean;
  onClick: (action: TaskAction) => void;
};

export const TaskActionsComponent: FC<Props> = memo(
  ({ task, loading, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const { isActive, isNew, status } = task;
    const showArchive = !isNew && isActive;
    const showUnArchive = !isNew && !isActive;
    const showRemove = !isNew && !isActive;
    const showCompleted = !isNew && status !== TaskStatus.Completed;
    const showUnComplete = !isNew && status === TaskStatus.Completed;

    const onClickHandler = (action: TaskAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="task.actions.label.actions" />
          {showCompleted && (
            <Action
              label="task.actions.label.complete"
              icon={
                <Icon
                  light
                  name="check"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(TaskAction.Complete)}
            />
          )}
          {showUnComplete && (
            <Action
              label="task.actions.label.uncomplete"
              icon={
                <Icon
                  light
                  name="square"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(TaskAction.UnComplete)}
            />
          )}
          {showArchive && (
            <Action
              label="task.actions.label.archive"
              icon={
                <Icon
                  light
                  name="archive"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(TaskAction.Archive)}
            />
          )}
          {showUnArchive && (
            <Action
              label="task.actions.label.unArchive"
              icon={
                <Icon
                  light
                  name="inbox-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(TaskAction.UnArchive)}
            />
          )}
          {showRemove && (
            <Action
              label="task.actions.label.remove"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(TaskAction.Remove)}
            />
          )}
        </ActionList>
      </>
    );
  }
);

export default TaskActionsComponent;
