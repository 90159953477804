export enum Colors {
  White = "rgba(255, 255, 255, 1)",
  Black = "rgba(0, 0, 0, 1)",
  TextBlack = "rgba(30, 30, 30, 1)",
  DarkGray = "rgba(60, 60, 60, 1)",
  Gray = "rgba(150, 150, 150, 1)",
  MediumGray = "rgba(200, 200, 200, 1)",
  LightGray = "rgba(230, 230, 230, 1)",
  ActionListIcon = "rgba(170, 170, 170, 1)",
  Primary = "rgba(0, 122, 204, 1)",
  Secondary = "#8e44ad",
  Warning = "rgba(231, 126, 35, 1)",
  Danger = "rgba(217, 24, 40, 1)",
  Info = "#ffc010",
  Success = "rgba(108, 161, 36, 1)"
}
