import { REQUEST } from "@haywork/constants";
import { OutstandingBrokerageWidgetContainerProps } from "@haywork/modules/dashboard/containers/widgets/outstanding-brokerage-widget.container";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { PriceUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./outstanding-brokerage-widget.component.scss");

export type DashboardOutstandingBrokerageWidgetComponentProps = {};
type Props = DashboardOutstandingBrokerageWidgetComponentProps &
  OutstandingBrokerageWidgetContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DashboardOutstandingBrokerageWidgetComponent extends React.Component<Props> {
  public componentDidMount() {
    this.props.getEmployeeStatistics();
  }

  public render() {
    let thisWeekEmployeeComissions = 0;
    let lastWeekEmployeeComission = 0;
    if (this.props.statisticsThisWeek) {
      thisWeekEmployeeComissions =
        this.props.statisticsThisWeek.saleCommissionTotal +
        this.props.statisticsThisWeek.rentCommissionTotal;
    }
    if (this.props.statisticsLastWeek) {
      lastWeekEmployeeComission =
        this.props.statisticsLastWeek.saleCommissionTotal +
        this.props.statisticsLastWeek.rentCommissionTotal;
    }

    if (!thisWeekEmployeeComissions && !lastWeekEmployeeComission) {
      return null;
    }

    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetOutstandingBrokerage">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-chart-bar" />
            </span>
            <span className="text">
              <ResourceText resourceKey="brokerageWidgetTitle" />
            </span>
          </header>

          {this.props.employeeStatisticsStatus === REQUEST.PENDING && (
            <div className="content loading">
              <PageLoader loading />
            </div>
          )}

          {this.props.employeeStatisticsStatus === REQUEST.SUCCESS && (
            <div className="content" styleName="content">
              <span className="mainValue small">
                {thisWeekEmployeeComissions
                  ? PriceUtil.getPriceNL(thisWeekEmployeeComissions)
                  : "-"}
              </span>
            </div>
          )}

          <footer>
            {lastWeekEmployeeComission ? (
              <div>
                <ResourceText resourceKey="courtageWidgetLastWeekItWas" />
                &nbsp;
                {thisWeekEmployeeComissions < lastWeekEmployeeComission ? (
                  <span className="result negative">
                    {PriceUtil.getPriceNL(lastWeekEmployeeComission)}
                  </span>
                ) : (
                  <span className="result positive">
                    {PriceUtil.getPriceNL(lastWeekEmployeeComission)}
                  </span>
                )}
                <span />
              </div>
            ) : (
              <div>-</div>
            )}
          </footer>
        </article>
      </div>
    );
  }
}
