import {
  ExtendedKey,
  KeysThunk,
  MapDispatchToProps
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { KeysModalComponent, KeysModalComponentProps } from "./keys-modal";

type StateProps = {};
type DispatchProps = {
  getKeys: () => Promise<ExtendedKey[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  KeysModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  KeysModalComponentProps
> = (dispatch) => ({
  getKeys: () => dispatch(KeysThunk.getKeys())
});

export type KeysModalContainerProps = StateProps & DispatchProps;
export const KeysModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeysModalComponent);
