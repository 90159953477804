import * as React from "react";
import { memo, FC, useState, useCallback, useEffect, useMemo } from "react";
import { FolderTreeFileEntity, FolderTreeFileType } from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import Item from "../check-item";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { ConvertableFile, BlobUtil } from "@haywork/util";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");
const fileSizeThreshold = 20000000;

type Props = {
  files: FolderTreeFileEntity[];
  loading: boolean;
  canMail: boolean;
  onUseFiles: (files: ConvertableFile[]) => void;
};

export const CheckComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ files, loading, canMail, onUseFiles }) => {
      const [convertedFiles, setConvertedFiles] = useState<ConvertableFile[]>(
        files.map(BlobUtil.mapFolderTreeFileEntityToConvertableFile)
      );

      useEffect(() => {
        setConvertedFiles(
          files.map(BlobUtil.mapFolderTreeFileEntityToConvertableFile)
        );
      }, [files, setConvertedFiles]);

      const onToggleConvert = useCallback(
        (id: string) => {
          const mappedFiles = convertedFiles.map((convertedFile) => {
            if (convertedFile.file.id === id) {
              return {
                ...convertedFile,
                convertToPDF: !convertedFile.convertToPDF,
              };
            }
            return convertedFile;
          });

          setConvertedFiles(mappedFiles);
        },
        [convertedFiles, setConvertedFiles]
      );

      const onDelete = useCallback(
        (id: string) => {
          const files = convertedFiles.filter((file) => file.file.id !== id);
          setConvertedFiles(files);
        },
        [convertedFiles, setConvertedFiles]
      );

      const toMixALot = useMemo(() => {
        const size = convertedFiles.reduce((state, file) => {
          if (
            file.file.type === FolderTreeFileType.DossierItem &&
            !!file.file.linkedDossierItem
          ) {
            const { fileSize } = file.file.linkedDossierItem;
            return state + (fileSize || 0);
          }
          return state;
        }, 0);

        return size > fileSizeThreshold;
      }, [convertedFiles]);

      const handleUseFiles = useCallback(() => {
        if (toMixALot) return;
        onUseFiles(convertedFiles);
      }, [onUseFiles, convertedFiles, toMixALot]);

      return (
        <>
          <ModalBody noPadding>
            <div styleName="body">
              <I18n value="dossier.selectedFilesModal.check.body" />
            </div>

            {!!convertedFiles.length && (
              <div styleName="file-list">
                {convertedFiles.map((file) => (
                  <Item
                    key={file.file.id}
                    file={file}
                    loading={loading}
                    onToggleConvert={onToggleConvert}
                    onDelete={onDelete}
                  />
                ))}
              </div>
            )}

            {toMixALot && (
              <div styleName="threshold-warning">
                <I18n value="dossier.selectedFilesModal.fileSizeThresholdWarning" />
              </div>
            )}

            {loading && (
              <div styleName="uploading-message">
                <I18n value="dossier.selectedFilesModal.uploadText" />
              </div>
            )}
          </ModalBody>
          <ModalFooter style={loading || toMixALot ? "no-border" : null}>
            <Button
              label="dossier.selectedFilesModal.action.use"
              category="primary"
              disabled={
                loading || toMixALot || !convertedFiles.length || !canMail
              }
              onClick={handleUseFiles}
              icon={
                loading ? (
                  <Icon
                    name="spinner"
                    spin
                    regular
                    containIn={24}
                    color={Colors.Gray}
                  />
                ) : null
              }
              iconPosition="start"
            />
          </ModalFooter>
        </>
      );
    }
  )
);
