import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import {
  NotesClient,
  NoteOrderByField,
  ActiveFilter,
  SortOrder,
  NoteSnapShot
} from "@haywork/api/kolibri";
import { NotesActions } from "@haywork/stores/notes-v3";
import { RequestStatus } from "@haywork/enum";
import { COUNTS } from "@haywork/constants";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { StringUtil } from "@haywork/util";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();

const getNotes = (
  parentId: string,
  startIndex: number = 0,
  reset: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(NotesActions.setNotesStatus(RequestStatus.Pending));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { parentId: currentParentId } = state.notesV3;

    const Notes = new NotesClient(host);
    const skip = startIndex;
    const take = COUNTS.NOTES;

    if (parentId !== currentParentId) {
      dispatch(NotesActions.setNotes(parentId, [], 0, reset));
    }

    try {
      const notes = await parseRequest.response(
        Notes.search(
          {
            parentId,
            orderBy: NoteOrderByField.CreationDateTime,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Descending,
            skip,
            take
          },
          realEstateAgencyId
        )
      );

      if (!notes) return;
      const { results, totalResults } = notes;
      dispatch(
        NotesActions.setNotes(parentId, results || [], totalResults, reset)
      );
    } catch (error) {
      dispatch(NotesActions.setNotesStatus(RequestStatus.Error));
      throw error;
    }
  };
};

const addNote = (description: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(NotesActions.setNotesStatus(RequestStatus.Pending));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { parentId } = state.notesV3;

    const Notes = new NotesClient(host);

    try {
      await parseRequest.response(
        Notes.save({ parentId, description }, realEstateAgencyId)
      );
      dispatch(getNotes(parentId, undefined, true));
    } catch (error) {
      dispatch(NotesActions.setNotesStatus(RequestStatus.Error));
      throw error;
    }
  };
};

const updateNote = (note: NoteSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(NotesActions.setNotesStatus(RequestStatus.Pending));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Notes = new NotesClient(host);

    try {
      await parseRequest.response(
        Notes.save(
          {
            id: note.id,
            parentId: note.parentId,
            description: note.description,
            isImportant: note.isImportant
          },
          realEstateAgencyId
        )
      );
      dispatch(getNotes(note.parentId, undefined, true));
    } catch (error) {
      dispatch(NotesActions.setNotesStatus(RequestStatus.Error));
      throw error;
    }
  };
};

const deleteNote = (id: string, description: string = "") => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(NotesActions.setNotesStatus(RequestStatus.Pending));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { parentId } = state.notesV3;

    const Notes = new NotesClient(host);

    try {
      await parseRequest.response(Notes.delete(id, realEstateAgencyId));
      dispatch(getNotes(parentId, undefined, true));
      dispatch(
        SnackbarActions.addToast({
          value: "note.toast.deleted",
          values: { description: StringUtil.trim(description, 48) },
          callback: () => {
            dispatch(unDeleteNote(id));
          },
          callbackLabel: "note.toast.action.undelete",
          icon: "trash-alt"
        })
      );
    } catch (error) {
      dispatch(NotesActions.setNotesStatus(RequestStatus.Error));
      throw error;
    }
  };
};

const unDeleteNote = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(NotesActions.setNotesStatus(RequestStatus.Pending));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { parentId } = state.notesV3;

    const Notes = new NotesClient(host);

    try {
      await parseRequest.response(Notes.undelete({ id }, realEstateAgencyId));
      dispatch(getNotes(parentId, undefined, true));
    } catch (error) {
      dispatch(NotesActions.setNotesStatus(RequestStatus.Error));
      throw error;
    }
  };
};

export const NotesThunk = {
  getNotes,
  addNote,
  updateNote,
  deleteNote
};
