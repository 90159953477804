import { PhotoBlob } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { OFFICESROUTES } from "@haywork/constants/routes";
import {
  AvatarUploadTriggerComponent,
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  OfficeDetailContainerProps,
  OfficeDetailRouting,
} from "@haywork/modules/settings";
import { ObjectTimestamps, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./office-detail.component.scss");

export interface OfficeDetailComponentProps {}
interface State {}
type Props = OfficeDetailComponentProps & OfficeDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class OfficeDetailComponent extends React.Component<Props, State> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      relationAvatar: { value: this.props.selectedOffice.logo },
    };

    this.onDisabledClick = this.onDisabledClick.bind(this);
    this.avatarChange = this.avatarChange.bind(this);
  }

  public componentDidMount() {
    this.props.selectedOffice.isNew
      ? this.props.setTabTitle(
          intlContext.formatMessage({
            id: "newOffice",
            defaultMessage: "newOffice",
          })
        )
      : this.props.setTabTitle(this.props.selectedOffice.name);

    this.props.getOfficeInfo(this.props.selectedOfficeId);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: OfficeDetailComponentProps & OfficeDetailContainerProps
  ) {
    if (
      this.props.selectedOffice &&
      nextProps.selectedOffice &&
      this.props.selectedOffice.name !== nextProps.selectedOffice.name &&
      this.props.selectedOffice.id === nextProps.selectedOffice.id
    ) {
      this.props.setTabTitle(
        nextProps.selectedOffice.name
          ? nextProps.selectedOffice.name
          : this.props.selectedOffice.isNew
          ? intlContext.formatMessage({
              id: "newOffice",
              defaultMessage: "newOffice",
            })
          : intlContext.formatMessage({
              id: "changeOffice",
              defaultMessage: "changeOffice",
            })
      );
    }
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      const { selectedOffice } = nextProps;
      const path = this.props.path;
      this.props.saveAndCloseOffice(selectedOffice, path);
    }
  }

  public render() {
    const { id } = this.props.selectedOffice;
    const inEditMode = this.props.location.pathname.match(/edit/);

    return (
      <div styleName="detailContainer">
        {/* <NotesContainer parentId={this.props.selectedOfficeId} /> */}
        <div styleName="sideBar">
          <div styleName="avatar" className="hidden-xs hidden-sm">
            <Form
              name="relationDetailAvatar"
              formControls={this.formControls}
              onChange={this.avatarChange}
            >
              <Input.Avatar name="relationAvatar">
                <AvatarUploadTriggerComponent customText="fileUploadLogo" />
              </Input.Avatar>
            </Form>
          </div>

          <div styleName="menu">
            <div styleName="menu">
              {!inEditMode &&
                this.renderMenuLink(
                  route(OFFICESROUTES.OFFICE_GENERAL.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral"
                )}
              {!!inEditMode &&
                this.renderMenuLink(
                  route(OFFICESROUTES.EDIT_OFFICE.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral",
                  true
                )}
              {this.renderMenuLink(
                route(OFFICESROUTES.OFFICE_LINKED_EMPLOYEES.URI, { id }),
                "fa-users",
                "selectEmployees"
              )}
              {this.renderMenuLink(
                route(OFFICESROUTES.OFFICE_ASSIGNMENT.URI, { id }),
                "fa-folder-open",
                "tabAssignments"
              )}
              {this.renderMenuLink(
                route(OFFICESROUTES.OFFICE_DOSSIER.URI, { id }),
                "fa-archive",
                "tabDossier"
              )}
            </div>
          </div>

          <ObjectTimestamps
            linkedCreatedBy={this.props.selectedOffice.linkedCreatedBy}
            linkedModifiedBy={this.props.selectedOffice.linkedModifiedBy}
            dateTimeCreated={this.props.selectedOffice.dateTimeCreated}
            dateTimeModified={this.props.selectedOffice.dateTimeModified}
          />
        </div>

        <div styleName="detailContent">
          <OfficeDetailRouting id={id} />
        </div>
      </div>
    );
  }

  private renderMenuLink(
    path: string,
    icon: string,
    label: string,
    overrideDisabled: boolean = false
  ) {
    const isNewOrEdit =
      !overrideDisabled &&
      (this.props.selectedOffice.isNew ||
        this.props.location.pathname.match(/edit/));
    const itemStyle = classNames({ disabled: isNewOrEdit });
    const iconStyle = classNames("fal fa-fw", icon);

    return !isNewOrEdit ? (
      <NavLink
        styleName="item"
        className={itemStyle}
        activeClassName="active"
        to={path}
      >
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </NavLink>
    ) : (
      <div styleName="item" className={itemStyle}>
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </div>
    );
  }

  private onDisabledClick(event: React.MouseEvent<HTMLElement>) {
    if (this.props.selectedOffice.isNew) {
      event.preventDefault();
    }
  }

  private avatarChange(values: FormReturnValue) {
    const avatar: PhotoBlob = values.relationAvatar;
    this.props.updateOfficeLogo(
      avatar ? avatar : null,
      this.props.selectedOfficeId
    );
  }
}
