import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  ProjectAssignment,
  TaskCategoryOption,
  TimelineActionType,
  TimelineEvent,
  TimelineEventsStatistics,
} from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks,
} from "@haywork/middleware";
import {
  ProjectDetailTimelineComponent,
  ProjectDetailTimelineComponentProps,
} from "@haywork/modules/project/components/detail-timeline/detail-timeline.component";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  projectAssignment: ProjectAssignment;
  events: TimelineEvent[];
  timelineStatus: string;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  canLoadMore: boolean;
  timelineStatistics: TimelineEventsStatistics;
}

interface DispatchProps {
  getTimelineEvents: (
    assignmentId: string,
    actionType?: TimelineActionType[],
    init?: boolean
  ) => void;
  navigate: (route: string) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, AssignmentDetailTimelineComponentProps>(
  state: AppState
) => {
  const {
    projectAssignment,
    events,
    timelineStatus,
    timelinePage,
    timelinePageCount,
    timelineStatistics,
  } = state.project.single;

  return {
    projectAssignment,
    events,
    timelineStatus,
    taskCategories: state.main.mastertable.kolibri.taskCategories,
    agendaItemCategories: state.scheduler.agendaItemCategories,
    canLoadMore: timelinePage < timelinePageCount,
    timelineStatistics,
  };
};

const mapDispatchToProps = <DispatchProps, ProjectDetailTimelineComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getTimelineEvents: (
    assignmentId: string,
    actionType?: TimelineActionType[],
    init?: boolean
  ) =>
    dispatch(
      ProjectsThunks.Projects.getTimelineEvents(assignmentId, actionType, init)
    ),
  navigate: (route: string) => dispatch(push(route)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type ProjectDetailTimelineContainerProps = StateProps & DispatchProps;
export const ProjectDetailTimelineContainer = connect<
  StateProps,
  DispatchProps,
  ProjectDetailTimelineComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailTimelineComponent);
