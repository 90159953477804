import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryStatistics,
  AgendaItemCategory
} from "@haywork/api/kolibri";

import * as ActionType from "./scheduler-category.types";

export interface SchedulerCategoryState {
  agendaItemCategories: AgendaItemCategorySnapShot[];
  latestAgendaItemCategoryAdded: AgendaItemCategorySnapShot;
  deletedSchedulerCategoryIds: string[];
  schedulerCategoryStatus: string;
  schedulerAddCategoryStatus: string;
  agendaItemCategoryStatistics: AgendaItemCategoryStatistics;
  agendaItemCategory: AgendaItemCategory;
  schedulerCategoryDetailStatus: string;
}

const INITIAL_STATE: SchedulerCategoryState = {
  agendaItemCategories: [],
  latestAgendaItemCategoryAdded: null,
  schedulerCategoryStatus: "",
  schedulerAddCategoryStatus: "",
  deletedSchedulerCategoryIds: [],
  agendaItemCategoryStatistics: null,
  agendaItemCategory: null,
  schedulerCategoryDetailStatus: REQUEST.IDLE
};

export const schedulerCategoryReducer = (
  state: SchedulerCategoryState = INITIAL_STATE,
  action: Action
): SchedulerCategoryState => {
  switch (action.type) {
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORIES: {
      const { agendaItemCategories, agendaItemCategoryStatistics } = <
        ActionType.SchedulerCategories
      >action;

      return {
        ...state,
        agendaItemCategories,
        agendaItemCategoryStatistics,
        schedulerCategoryStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY: {
      const { singleAgendaItemCategory } = <
        ActionType.SingleAgendaItemCategory
      >action;
      const results = state.agendaItemCategories.map((c) => {
        if (c.id === singleAgendaItemCategory.id) {
          return singleAgendaItemCategory;
        }
        return c;
      });

      return { ...state, agendaItemCategories: results };
    }
    case REDUX.SCHEDULER_CATEGORIES.NEW_SCHEDULER_CATEGORY: {
      const { agendaItemCategory } = <
        ActionType.AgendaItemCategorySnapShotPayload
      >action;

      return {
        ...state,
        latestAgendaItemCategoryAdded: agendaItemCategory,
        schedulerAddCategoryStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.SCHEDULER_CATEGORIES.DELETE_SCHEDULER_CATEGORY: {
      const { id } = <ActionType.Id>action;
      const agendaItemCategories = state.agendaItemCategories.filter(
        (note) => note.id !== id
      );
      return { ...state, agendaItemCategories };
    }
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORIES_STATUS: {
      const { status } = <ActionType.Status>action;
      return { ...state, schedulerCategoryStatus: status };
    }
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY_STATUS: {
      const { status } = <ActionType.Status>action;
      return { ...state, schedulerCategoryDetailStatus: status };
    }
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY_ITEM: {
      const { agendaItemCategory } = <ActionType.AgendaItemCategoryPayload>(
        action
      );

      return {
        ...state,
        agendaItemCategory,
        schedulerCategoryDetailStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
