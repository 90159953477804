import {
  AcquisitionObjectAssignment,
  RelationRoleOption,
} from "@haywork/api/kolibri";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AssignmentDetailNetworkComponent,
  AssignmentDetailNetworkComponentProps,
} from "./detail-network.component";

interface StateProps {
  currentComponentState: AcquisitionObjectAssignment;
  relationRoles: RelationRoleOption[];
  realEstateAgencyId: string;
  host: string;
  path: string;
  acquisitionAssignmentObjectStatus: string;
}
interface DispatchProps {
  navigate: (url: string) => void;
  saveAcquisition: (acquisition: AcquisitionObjectAssignment) => Promise<void>;
}

const x = 10;

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailNetworkComponentProps,
  AppState
> = (state) => {
  const { acquisitionAssignmentObjectStatus } = state.acquisition.assignment;
  const currentComponentState: AcquisitionObjectAssignment =
    state.editable.currentComponentState;
  const { relationRoles } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const path = RouteUtil.mapStaticRouteValues(ACQUISITIONROUTES.DETAIL.URI, {
    id: currentComponentState.id,
  });

  return {
    currentComponentState,
    relationRoles,
    realEstateAgencyId,
    host,
    path,
    acquisitionAssignmentObjectStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailNetworkComponentProps
> = (dispatch) => ({
  navigate: (url: string) => dispatch(push(url)),
  saveAcquisition: (acquisition: AcquisitionObjectAssignment) =>
    dispatch(AcquisitionThunks.AcquisitionObject.save(acquisition, false)),
});

export type AssignmentDetailNetworkContainerProps = StateProps & DispatchProps;
export const AssignmentDetailNetworkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailNetworkComponent);
