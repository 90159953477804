import {
  AssignmentOrderByField,
  AssignmentSnapShot,
  SortOrder
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState } from "@haywork/stores";
import { AcquisitionActions } from "@haywork/stores/acquisition";
import { AssignmentOrder } from "@haywork/stores/acquisition/listV2";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionListComponent,
  AcquisitionListComponentProps
} from "./list";
import { activeFilterGuidValues } from "./selectors";

type StateProps = {
  assignments: AssignmentSnapShot[];
  assignmentsStatus: string;
  total: number;
  filters: FilterConfig;
  activeFilterGuidValues: FilterGuidValues;
  order: AssignmentOrder;
  scrollOffset: number;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  createAcquisition: () => Promise<void>;
  openAssignment: (id: string, snapshot: AssignmentSnapShot) => void;
  setFilters: (filters: FilterConfig) => void;
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: AssignmentOrderByField
  ) => void;
  setScrollOffset: (offset: number) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionListComponentProps,
  AppState
> = (state) => {
  const {
    assignments,
    assignmentsStatus,
    total,
    filters,
    order,
    scrollOffset
  } = state.acquisition.listV2;

  return {
    assignments,
    assignmentsStatus,
    total,
    filters,
    order,
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionListComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(AcquisitionThunks.Acquisition.getList(startIndex, stopIndex)),
  createAcquisition: () =>
    dispatch(AcquisitionThunks.Acquisition.createAcquisition()),
  openAssignment: (id: string) =>
    dispatch(AcquisitionThunks.Acquisition.getAcquisition(id)),
  setOrdering: (sortOrder: SortOrder, sortColumn: AssignmentOrderByField) =>
    dispatch(AcquisitionActions.ListV2.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(AcquisitionActions.ListV2.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(AcquisitionActions.ListV2.setScrollOffset(offset))
});

export type AcquisitionListContainerProps = StateProps & DispatchProps;
export const AcquisitionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionListComponent);
