import {
  AssignmentSnapShot,
  ConstructionPeriodOption,
  Language,
  ObjectTypeAssignment,
  PhotoBlob,
  ProjectAssignment,
  RentOffer,
  SaleOffer,
} from "@haywork/api/kolibri";
import { TypeOfOffer } from "@haywork/constants";
import { FormControl } from "@haywork/util/form-control";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import some from "lodash-es/some";
import { urlToPhotoBlob } from "./url";

const value = FormControl.returnObjectPathOrNull;

export interface ProjectAssignmentValidatedSubRoutes {
  client: boolean;
  address: boolean;
  marketing: boolean;
}

export enum ProjectSaveError {
  NoLinkedEmployee = "NoLinkedEmployee",
  NoLinkedOffice = "NoLinkedOffice",
  NoRealEstateGroup = "NoRealEstateGroup",
  NoTypeOf = "NoTypeOf",
  NoListingType = "NoListingType",
  IncompleteAddress = "IncompleteAddress",
  NoLeisureType = "NoLeisureType",
}

export enum ProjectPublishError {
  NoParcelSurface = "NoParcelSurface",
  NoBuildPeriodOrYear = "NoBuildPeriodOrYear",
  NoRoomsCount = "NoRoomsCount",
  NoLivingSurface = "NoLivingSurface",
  NoContents = "NoContents",
  NoSaleCondition = "NoSaleCondition",
  NoRentCondition = "NoRentCondition",
  NoAcceptance = "NoAcceptance",
  ConstructionYearMismatch = "ConstructionYearMismatch",
}

export interface ValidatedObjectTypesSubRoutes {
  type: boolean;
  general: boolean;
  specifications: boolean;
  marketing: boolean;
}

export class Project {
  public static Mappers = class {
    public static mapSnapshotToProjectAssignment(
      snapshot: AssignmentSnapShot
    ): ProjectAssignment {
      if (!snapshot) return;
      const {
        assignmentPhase,
        availabilityStatus,
        forRent,
        forSale,
        linkedEmployee,
        linkedOffice,
        isActive,
        id,
        keyNote,
        keyNr,
        linkedApplicants,
        linkedNotaries,
        linkedPropertyManagers,
        linkedVendors,
        photo1Url,
        photo2Url,
        photo3Url,
        displayName,
      } = snapshot;

      const photos: PhotoBlob[] = [];
      if (!!photo1Url) photos.push(urlToPhotoBlob(photo1Url));
      if (!!photo2Url) photos.push(urlToPhotoBlob(photo2Url));
      if (!!photo3Url) photos.push(urlToPhotoBlob(photo3Url));

      const saleOffer: SaleOffer = !forSale
        ? undefined
        : Project.Mappers.mapAssignmentSnapShotToRentOrSaleOffer(
            snapshot,
            TypeOfOffer.Sale
          );
      const rentOffer: RentOffer = !forRent
        ? undefined
        : Project.Mappers.mapAssignmentSnapShotToRentOrSaleOffer(
            snapshot,
            TypeOfOffer.Rent
          );

      return {
        dateTimeCreated: null,
        dateTimeModified: null,
        isNew: false,
        realEstateAgencyId: null,
        assignmentPhase,
        availabilityStatus,
        forRent,
        forSale,
        linkedEmployees: [linkedEmployee],
        linkedOffice,
        isActive,
        id,
        keyNote,
        keyNr,
        linkedApplicants,
        linkedNotaries,
        linkedPropertyManagers,
        linkedVendors,
        photos,
        saleOffer,
        rentOffer,
        titleText: [{ language: Language.Dutch, text: displayName }],
      };
    }

    private static mapAssignmentSnapShotToRentOrSaleOffer(
      assignmentSnapshot: AssignmentSnapShot,
      typeOfOffer: TypeOfOffer
    ): RentOffer | SaleOffer {
      const { saleCondition, salePrice, rentCondition, rentPrice } =
        assignmentSnapshot;
      const forSale = typeOfOffer === TypeOfOffer.Sale;
      const offerSpecifics = forSale
        ? { saleCondition, salePrice }
        : { rentCondition, rentPrice };

      return {
        ...offerSpecifics,
        priceHistory: [
          {
            dateTimeModified: null,
            newPrice: forSale ? salePrice : rentPrice || 0,
          },
        ],
      };
    }
  };

  public static Validation = class {
    public static preflightPublishProjectAssignment(
      projectAssignment: ProjectAssignment,
      constructionPeriods: ConstructionPeriodOption[]
    ): ProjectPublishError[] {
      const errors: ProjectPublishError[] = [];
      const {
        forSale,
        forRent,
        yearOfConstruction,
        acceptanceDetails,
        saleOffer,
        rentOffer,
      } = projectAssignment;

      if (!!forSale && !value(saleOffer, "saleCondition")) {
        errors.push(ProjectPublishError.NoSaleCondition);
      }
      if (!!forRent && !value(rentOffer, "rentCondition")) {
        errors.push(ProjectPublishError.NoRentCondition);
      }
      if (!value(acceptanceDetails, "acceptance")) {
        errors.push(ProjectPublishError.NoAcceptance);
      }
      if (
        !value(yearOfConstruction, "constructionYear") &&
        !value(yearOfConstruction, "period")
      ) {
        errors.push(ProjectPublishError.NoBuildPeriodOrYear);
      }
      if (
        !!value(yearOfConstruction, "constructionYear") &&
        !!value(yearOfConstruction, "period")
      ) {
        const year = parseInt(value(yearOfConstruction, "constructionYear"));
        const period = constructionPeriods.find(
          (p) => p.minYear <= year && p.maxYear >= year
        );

        if (!period || period.value !== value(yearOfConstruction, "period")) {
          errors.push(ProjectPublishError.ConstructionYearMismatch);
        }
      }

      return errors;
    }

    public static validForSave(projectAssignment: ProjectAssignment): boolean {
      return Project.Validation.getErrors(projectAssignment).length === 0;
    }

    public static getErrors(
      projectAssignment: ProjectAssignment
    ): ProjectSaveError[] {
      const errors: ProjectSaveError[] = [];
      const { linkedEmployees, linkedOffice, address } = projectAssignment;

      if (!linkedEmployees || linkedEmployees.length === 0)
        errors.push(ProjectSaveError.NoLinkedEmployee);
      if (!linkedOffice) errors.push(ProjectSaveError.NoLinkedOffice);
      if (!address || !has(address, "locality.name"))
        errors.push(ProjectSaveError.IncompleteAddress);

      return errors;
    }

    public static validateSubRoutes(
      projectAssignment: ProjectAssignment
    ): ProjectAssignmentValidatedSubRoutes {
      return {
        client: Project.Validation.validateClientSubRoute(projectAssignment),
        address: Project.Validation.validateAddressSubRoute(projectAssignment),
        marketing:
          Project.Validation.validateMarketingSubRoute(projectAssignment),
      };
    }

    private static validateClientSubRoute(
      projectAssignment: ProjectAssignment
    ) {
      return (
        has(projectAssignment, "linkedOffice") &&
        has(projectAssignment, "linkedEmployees")
      );
    }

    private static validateAddressSubRoute(
      projectAssignment: ProjectAssignment
    ) {
      return (
        has(projectAssignment, "address.postalCode") &&
        has(projectAssignment, "address.locality") &&
        projectAssignment.address.locality.name !== ""
      );
    }

    private static validateMarketingSubRoute(
      projectAssignment: ProjectAssignment
    ) {
      const { photos, isSpecial, isTopper, maps, videos } = projectAssignment;
      return (
        (photos && photos.length > 0) ||
        has(projectAssignment, "advertisementText") ||
        has(projectAssignment, "groundFloorText") ||
        has(projectAssignment, "firstFloorText") ||
        has(projectAssignment, "secondFloorText") ||
        has(projectAssignment, "otherFloorsText") ||
        has(projectAssignment, "gardensText") ||
        has(projectAssignment, "balconyText") ||
        has(projectAssignment, "peculiaritiesText") ||
        has(projectAssignment, "titleText") ||
        has(projectAssignment, "testimonial.iList") ||
        has(projectAssignment, "testimonial.testimonialPhoto") ||
        isSpecial ||
        isTopper ||
        (maps && maps.length > 0) ||
        (videos && videos.length > 0)
      );
    }
  };

  public static validateSubRoutes(
    projectType: ObjectTypeAssignment
  ): ValidatedObjectTypesSubRoutes {
    const {
      titleText,
      typePART,
      usableArea,
      livingRoomSurface,
      parcelSurface,
      contents,
      amountUnits,
      amountFreeUnits,
      buildingTypeStart,
      deliveryStart,
      saleStart,
      saleOffer,
      rentOffer,
      floors,
      garages,
      gardens,
      photos,
      videos,
      advertisementText,
      environmentText,
      registrationConditions,
      partiesDescriptionText,
      maps,
      numberOfFloors,
      insulationTypes,
      heating,
      warmWaterTypes,
      boilerYearOfConstruction,
      boilerType,
      hasCombiBoiler,
      boilerFuel,
      boilerProperty,
      comfortQuality,
      roof,
      residentialFacilities,
      parkingFacilities,
    } = projectType;

    const valid: ValidatedObjectTypesSubRoutes = {
      type: false,
      general: false,
      specifications: false,
      marketing: false,
    };

    if (isArray(titleText) && !!titleText.length && !!typePART) {
      valid.type = true;
    }

    const generalSizeKeys = ["minArea", "maxArea", "minVolume", "maxVolume"];
    const generalSizeFields = [
      usableArea,
      livingRoomSurface,
      parcelSurface,
      contents,
    ];
    const generalAmountKeys = [
      "salePrice",
      "salePriceMax",
      "rentPrice",
      "rentPriceMax",
    ];
    const generalAmountFields = [saleOffer, rentOffer];
    if (
      amountUnits ||
      amountFreeUnits ||
      buildingTypeStart ||
      deliveryStart ||
      saleStart ||
      some(generalSizeFields, (value) =>
        some(value, (v, k) => generalSizeKeys.indexOf(k) !== -1 && !!v)
      ) ||
      some(generalAmountFields, (value) =>
        some(value, (v, k) => generalAmountKeys.indexOf(k) !== -1 && !!v)
      )
    ) {
      valid.general = true;
    }

    if (
      !!numberOfFloors ||
      (isArray(insulationTypes) && !!insulationTypes.length) ||
      (isArray(heating) && !!heating.length) ||
      (isArray(warmWaterTypes) && !!warmWaterTypes.length) ||
      (isArray(residentialFacilities) && !!residentialFacilities.length) ||
      (isArray(parkingFacilities) && !!parkingFacilities.length) ||
      !!boilerYearOfConstruction ||
      !!boilerType ||
      !!hasCombiBoiler ||
      !!boilerFuel ||
      !!boilerProperty ||
      !!comfortQuality ||
      !!roof ||
      (isArray(floors) && !!floors.length) ||
      (isArray(gardens) && !!gardens.length) ||
      (isArray(garages) && !!garages.length)
    ) {
      valid.specifications = true;
    }

    if (
      (isArray(photos) && !!photos.length) ||
      (isArray(videos) && !!videos.length) ||
      (isArray(advertisementText) && !!advertisementText.length) ||
      (isArray(environmentText) && !!environmentText.length) ||
      (isArray(registrationConditions) && !!registrationConditions.length) ||
      (isArray(partiesDescriptionText) && !!partiesDescriptionText.length) ||
      (isArray(maps) && !!maps.length)
    ) {
      valid.marketing = true;
    }

    return valid;
  }
}
