import {
  SortOrder,
  TemplateDefinitionCategoryOrderByField
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import {
  EMAILSIGNATURESROUTES,
  EXTERNALROUTES,
  REQUEST
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
  UiTableColumnConfig
} from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SettingsEmailSignaturesOverviewContainerProps } from "../../containers";
import { SignatureListItem } from "../list-item";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./overview.component.scss");

export interface SettingsEmailSignaturesOverviewComponentProps {}
interface State {}
type Props = SettingsEmailSignaturesOverviewComponentProps &
  SettingsEmailSignaturesOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailSignaturesOverviewComponent extends React.Component<
  Props,
  State
> {
  private columnConfig: UiTableColumnConfig[];

  constructor(props) {
    super(props);

    this.columnConfig = [
      {
        title: "settingsEmailSignature.Signature",
        width: 200,
        isAutoColumn: true
      },
      {
        title: "settingsEmailSignature.DefaultSignature",
        width: 320
      }
    ];

    this.onSignatureOpen = this.onSignatureOpen.bind(this);
  }

  public render() {
    const { signatures, status } = this.props;

    return (
      <div styleName="signatures">
        <PageHeader
          title="emailSignatures"
          subTitle="settingsMenuEmail"
          actions={
            <Button
              label="newEmailSignature"
              icon={<Icon name="plus" size={18} light />}
              category="primary"
              onClick={this.props.defineNew}
            />
          }
        />

        <div className="alert alert-info">
          <i className="fal fa-fw fa-info-circle" />
          <I18n
            value="emailSignaturesHint"
            values={{ url: EXTERNALROUTES.EMAIL_SIGNATURES_HELPDESK.URI }}
            asHtml
          />
        </div>

        <div styleName="signatures__body">
          <Ui.Table
            columnConfig={this.columnConfig}
            orderBy={TemplateDefinitionCategoryOrderByField.Name}
            defaultOrderBy={TemplateDefinitionCategoryOrderByField.Name}
            defaultOrder={SortOrder.Ascending}
            order={SortOrder.Ascending}
            pushActions
          >
            {signatures.map((signature, idx) => (
              <ErrorBoundary key={signature.id}>
                <SignatureListItem
                  signature={signature}
                  zebra={idx % 2 === 0}
                  defaultSignatureGuid={this.props.defaultSignatureTemplateId}
                  onDefaultSignatureChange={
                    this.props.setDefaultSignatureTemplateId
                  }
                  onOpen={this.onSignatureOpen}
                  onDelete={this.props.deleteTemplate}
                  onUndelete={this.props.unDeleteTemplate}
                  onDeleteDone={(id) =>
                    this.props.deleteTemplateFromState(
                      id,
                      id === this.props.defaultSignatureTemplateId
                    )
                  }
                />
              </ErrorBoundary>
            ))}

            {signatures.length === 0 && status !== REQUEST.PENDING && (
              <Ui.EmptyState
                stickman={UiEmptyStateStickMan.NoRelations}
                type={UiEmptyStateType.List}
                title="emptyStateNoEmailSignaturesOverviewTitle"
                subtitle="emptyStateNoEmailSignaturesOverviewBody"
              >
                <div
                  className="btn btn-primary"
                  styleName="empty-state-button"
                  onClick={this.props.defineNew}
                >
                  <I18n value="newEmailSignature" />
                </div>
              </Ui.EmptyState>
            )}

            {status === REQUEST.PENDING && <Ui.Loaders.List />}
          </Ui.Table>
        </div>
      </div>
    );
  }

  private onSignatureOpen(id: string) {
    const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, { id });
    this.props.navigate(path);
  }
}
