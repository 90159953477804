import { RootEntityType } from "@haywork/api/event-center";
import {
  ContactCompany,
  CountryOption,
  EmailAddressTypeOption,
  PhoneNumberTypeOption,
  PhotoBlob,
  RelationType,
} from "@haywork/api/kolibri";
import { RELATIONROUTES, REQUEST } from "@haywork/constants";
import {
  AssignmentThunks,
  Dispatch,
  RelationThunks,
} from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { ContactCompanyDetailComponent } from "@haywork/modules/relation";
import { AppState, RelationActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  selectedRelationId?: string;
  selectedRelationType?: RelationType;
  relationContactInfoState?: string;
  selectedRelationAvatar?: PhotoBlob;
  selectedRelationIsNew: boolean;
  contactCompany: ContactCompany;
  saveRelationContactInfoState: string;
  isLoading: boolean;
  onRelationOptionClickHandler?: (routePath: string) => void;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
}
interface DispatchProps {
  searchAddress: (value: string, country: string) => void;
  getContactInfo: (id: string, relationType: RelationType) => void;
  saveContactCompany: (contactCompany: ContactCompany, path: string) => void;
  updateContactCompanyLogo: (
    newAvatar: PhotoBlob,
    contactCompanyId: string
  ) => void;
}

const mapStateToProps = <StateProps, ContactCompanyDetailComponentProps>(
  state: AppState
) => ({
  selectedRelationId: state.relation.detail.selectedRelationId,
  selectedRelationType: state.relation.detail.selectedRelationType,
  relationContactInfoState: state.relation.detail.relationContactInfoState,
  selectedRelationAvatar: state.relation.detail.selectedRelationAvatar,
  selectedRelationIsNew: state.relation.detail.selectedRelationIsNew,
  contactCompany: state.editable.currentComponentState,
  saveRelationContactInfoState:
    state.relation.detail.saveRelationContactInfoState,
  isLoading: !(
    state.relation.detail.relationContactInfoState === REQUEST.SUCCESS
  ),
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  countries: state.main.mastertable.kolibri.countries,
});

const mapDispatchToProps = <DispatchProps, ContactCompanyDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  onRelationOptionClickHandler: (routePath: string) =>
    dispatch(push(routePath)),
  getContactInfo: (relationId: string, relationType: RelationType) =>
    dispatch(RelationThunks.getContactInfo(relationId, relationType)),
  searchAddress: (value: string, country: string) =>
    dispatch(AssignmentThunks.searchAddress(value, country)),
  saveContactCompany: (contactCompany: ContactCompany, path: string) =>
    dispatch(RelationThunks.saveContactCompany(contactCompany, path)),
  updateContactCompanyLogo: (newAvatar: PhotoBlob, contactCompanyId: string) =>
    dispatch(
      RelationThunks.updateContactCompanyLogo(newAvatar, contactCompanyId)
    ),
});

export type ContactCompanyDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  EditableHocProps;
export const ContactCompanyDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(ContactCompanyDetailComponent)
  ),
  {
    entityType: RootEntityType.ContactCompany,
    icon: "address-book",
    thunk: RelationThunks.getContactCompanyInfo,
    status: "relation.detail.relationContactInfoState",
    statePath: "relation.detail.selectedContactCompany",
    action: RelationActions.searchContactCompany2,
    route: RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
    confirm: {
      title: { key: "saveCompanyConfirmTitle" },
      body: { key: "saveCompanyConfirmBody" },
    },
  }
);
