import { REDUX } from "@haywork/constants";
import * as ActionType from "./buildnumbers.types";

const setObjectTypes = (payload: ActionType.ObjectTypes) => ({
  type: REDUX.BUILDNUMBERS.SET_OBJECT_TYPES,
  ...payload
});

const setObjectTypesStatus = (objectTypesStatus: string) => ({
  type: REDUX.BUILDNUMBERS.SET_OBJECT_TYPES_STATUS,
  objectTypesStatus
});

const toggleAddBuildnumbers = (addBuildnumbersVisible: boolean) => ({
  type: REDUX.BUILDNUMBERS.TOGGLE_ADD_BUILDNUMBERS,
  addBuildnumbersVisible
});

export const BuildnumbersActions = {
  setObjectTypes,
  setObjectTypesStatus,
  toggleAddBuildnumbers
};
