import { Account, Provider, SyncStatus } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";
import { Ui, uiTableRow, UiTableRowProps } from "@haywork/modules/ui";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");

interface ListItemComponentProps {
  zebra: boolean;
  account: Account;
  providers: Provider[];
  isDefaultAccount: boolean;
  onEdit: (account: Account) => void;
  onDelete: (accountId: string) => void;
}
interface ListItemComponentState {
  showActionOverlay: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class ListItemComponent extends React.Component<
  ListItemComponentProps & UiTableRowProps,
  ListItemComponentState
> {
  constructor(props) {
    super(props);

    this.onActionDoneHandler = this.onActionDoneHandler.bind(this);
    this.onActionCancelHandler = this.onActionCancelHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);

    this.state = {
      showActionOverlay: false,
    };
  }

  public render() {
    const { accountName, emailAddress, type, id, sharesCount } =
      this.props.account;
    const avatar = this.props.providers.find((p) => p.type === type);
    const avatarStyles: React.CSSProperties =
      !!avatar && !!avatar.logoUrl
        ? { backgroundImage: `url("${JSON.stringify(avatar.logoUrl)}")` }
        : null;

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
        actionTitle="cancelEmailDelete"
        actionVisible={this.state.showActionOverlay}
        onActionDone={this.onActionDoneHandler}
        onActionCancel={this.onActionCancelHandler}
      >
        <div styleName="list-item" style={this.props.gridTemplateColumns}>
          <div styleName="column">
            <div styleName="email">
              <div styleName="avatar" style={avatarStyles} />
              <div styleName="address">
                <span styleName="emailaddress">{emailAddress}</span>
                {this.renderAccountStatus()}
              </div>
            </div>
          </div>
          <div styleName="column">
            <div styleName="account-name">
              {accountName}
              {this.props.isDefaultAccount && (
                <strong>
                  <ResourceText resourceKey="isDefaultEmailAccount" />
                </strong>
              )}
            </div>
          </div>
          <div styleName="column">
            <ResourceText
              resourceKey="emailAccountSharedWith"
              values={{ count: sharesCount }}
            />
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private onDeleteClickHandler() {
    this.setState({
      showActionOverlay: true,
    });
  }

  private onEditClickHandler() {
    this.props.onEdit(this.props.account);
  }

  private onActionDoneHandler() {
    const { id } = this.props.account;
    this.props.onDelete(id);
    this.setState({
      showActionOverlay: false,
    });
  }

  private onActionCancelHandler() {
    this.setState({
      showActionOverlay: false,
    });
  }

  private renderListActions(): React.StatelessComponent[] {
    const deleteButton = () => (
      <button
        className="btn btn-danger"
        onClick={this.onDeleteClickHandler}
        data-cy="CY-deleteEmailSetting"
      >
        <i className="fal fa-times" />
      </button>
    );

    const editButton = () => (
      <button className="btn btn-success" onClick={this.onEditClickHandler}>
        <i className="fal fa-pencil" />
      </button>
    );

    return [deleteButton, editButton];
  }

  private renderAccountStatus() {
    const { syncStatus } = this.props.account;
    let status = "";
    let statusIcon = "";

    switch (syncStatus) {
      default:
        status = "active";
        statusIcon = "far fa-sync";
        break;
      case SyncStatus.Unknown:
        status = "unknown";
        statusIcon = "fas fa-question-circle";
        break;
      case SyncStatus.Running:
        status = "okay";
        statusIcon = "far fa-check";
        break;
      case SyncStatus.InvalidCredentials:
      case SyncStatus.Stopped:
        status = "warning";
        statusIcon = "far fa-exclamation-triangle";
        break;
      case SyncStatus.Error:
        status = "danger";
        statusIcon = "far fa-times";
        break;
    }

    const statusStyle = classNames("status", status);

    return (
      <div styleName={statusStyle}>
        <i className={statusIcon} />
        <ResourceText resourceKey={`syncStatus.${syncStatus.toString()}`} />
      </div>
    );
  }
}

export const ListItem = uiTableRow(ListItemComponent);
