import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./relation.types";
import {
  ContactLinkSnapShot,
  Address
} from "@haywork/api/kolibri";

export interface RelationDetailContactlinkState {
  contactLinks: ContactLinkSnapShot[];
  relationContactLinkState: string;
  relationSaveContactLinkState: string;
  contactLinkPage: number;
  contactLinkPageCount: number;
  contactAddError: boolean;
}

const INITIAL_STATE: RelationDetailContactlinkState = {
  contactLinks: null,
  relationContactLinkState: REQUEST.IDLE,
  relationSaveContactLinkState: REQUEST.IDLE,
  contactLinkPage: 0,
  contactLinkPageCount: 0,
  contactAddError: false
};

export const relationDetailContactlinkReducer = (state: RelationDetailContactlinkState = INITIAL_STATE, action: Action): RelationDetailContactlinkState => {
  switch (action.type) {
    case REDUX.RELATION.SET_FETCH_RELATION_CONTACT_LINK_STATE: {
      const { relationContactLinkState } = <ActionType.RelationContactLinkState> action;

      return { ...state, relationContactLinkState };
    }
    case REDUX.RELATION.SET_RELATION_SAVE_CONTACT_LINK_STATE: {
      const { relationSaveContactLinkState } = <ActionType.RelationSaveContactLinkState> action;

      return { ...state, relationSaveContactLinkState };
    }
    case REDUX.RELATION.SEARCH_CONTACT_LINKS: {
      const {results, totalResults, resultCount} = <ActionType.ContactLinksResponse> action;
      const contactLinkPageCount = Math.round(totalResults / resultCount);

      return {
        ...state,
        contactLinks: results,
        contactLinkPage: 1,
        contactLinkPageCount
      };
    }
    case REDUX.RELATION.APPEND_CONTACT_LINKS: {
      const {results, totalResults, resultCount} = <ActionType.ContactLinksResponse> action;
      const contactLinkPageCount = Math.round(totalResults / resultCount);
      const contactLinkPage = state.contactLinkPage + 1;

      return {
        ...state,
        contactLinks: [...state.contactLinks, ...results],
        contactLinkPage,
        contactLinkPageCount
      };
    }
    case REDUX.RELATION.APPEND_SINGLE_CONTACT_LINK: {
      const {contactLinkSnapShot} = <ActionType.SingleContactLinkResponse> action;

      const toAdd = state.contactLinks.find((item) => contactLinkSnapShot.sourceRelation.id === item.sourceRelation.id);
      if (!toAdd) {
        return {
          ...state,
          contactLinks: [contactLinkSnapShot, ...state.contactLinks],
          contactAddError: false
        };
      }
      else {
        return {...state, contactAddError: true}; // contact is already in list, so return error
      }
    }
    case REDUX.RELATION.UPDATE_CONTACTLINK: {
      const {contactLinkSnapShot} = <ActionType.SingleContactLinkResponse> action;
      const contactLinks = state.contactLinks.map((contact) => {
        if (contact.sourceRelation.id === contactLinkSnapShot.sourceRelation.id && contact.targetRelation.id === contactLinkSnapShot.targetRelation.id) return contactLinkSnapShot;
        return contact;
      });

      return {
        ...state,
        contactLinks
      };
    }
    case REDUX.RELATION.REMOVE_FROM_LIST: {
      const { contactLink } = <ActionType.ContactLink> action;
      const { sourceRelation, targetRelation } = contactLink;
      const contactLinks = state.contactLinks.filter((c) => {
        return !(c.sourceRelation.id === sourceRelation.id && c.targetRelation.id === targetRelation.id);
      });

      return {
        ...state,
        contactLinks
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
