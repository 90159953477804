import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SortEnd } from "react-sortable-hoc";
import { AssignmentEditMarketingPhotosContainerProps } from "@haywork/modules/assignment";
import { FormControlUtil } from "@haywork/util";
import { PhotosComponent } from "@haywork/modules/shared";

const styles = require("./marketing-photos.component.scss");
import { arrayMoveImmutable } from "array-move";
const value = FormControlUtil.returnObjectPathOrNull;

export interface AssignmentEditMarketingPhotosComponentProps {}
interface AssignmentEditMarketingPhotosComponentState {}
@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingPhotosComponent extends React.Component<
  AssignmentEditMarketingPhotosComponentProps &
    AssignmentEditMarketingPhotosContainerProps,
  AssignmentEditMarketingPhotosComponentState
> {
  constructor(props) {
    super(props);

    this.filesDeletedHandler = this.filesDeletedHandler.bind(this);
    this.sortedHandler = this.sortedHandler.bind(this);
    this.filesEditedHandler = this.filesEditedHandler.bind(this);
    this.uploadCompleteHandler = this.uploadCompleteHandler.bind(this);
  }

  public render() {
    let { photos } = this.props.objectAssignment;
    photos = photos || [];

    return (
      <div>
        <PhotosComponent
          photos={photos}
          path={this.props.path}
          onUploadCompleteHandler={this.uploadCompleteHandler}
          fileDeleted={this.filesDeletedHandler}
          sorted={this.sortedHandler}
          fileEdited={this.filesEditedHandler}
          showMainPhotoLabel={true}
        />
      </div>
    );
  }

  private uploadCompleteHandler(files: any[]) {
    const { objectAssignment, currentComponentState } = this.props;
    const photoArray = objectAssignment.photos || [];
    const photos = files.filter((f) => !!f);
    const updatedObjectAssignment = {
      ...objectAssignment,
      photos: [...photoArray, ...photos],
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private filesDeletedHandler(idx: number) {
    const { objectAssignment, currentComponentState } = this.props;
    const photos = objectAssignment.photos.filter((p, k) => k !== idx);
    const updatedObjectAssignment = {
      ...objectAssignment,
      photos,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private sortedHandler(sort: SortEnd) {
    const { objectAssignment, currentComponentState } = this.props;
    const photos = arrayMoveImmutable(
      this.props.objectAssignment.photos,
      sort.oldIndex,
      sort.newIndex
    );
    const updatedObjectAssignment = {
      ...objectAssignment,
      photos,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private filesEditedHandler(photos: any[]) {
    const { objectAssignment, currentComponentState } = this.props;

    const updatedObjectAssignment = {
      ...objectAssignment,
      photos,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
    this.setState({ showFileEditModal: false });
  }
}
