import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import get from "lodash-es/get";

import { AssignmentSnapShot, AvailabilityStatus } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { intlContext } from "@haywork/app";

const styles = require("./progress.component.scss");

interface Props {
  objectTypes: AssignmentSnapShot[];
  buildnumbers: AssignmentSnapShot[];
  forSale: boolean;
  forRent: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectProgress extends React.Component<Props> {
  private title: string;
  private barLabel: string;

  constructor(props) {
    super(props);

    const { forSale, forRent } = this.props;

    switch (true) {
      case forSale && forRent: {
        this.title = "projectProgressTitleSaleAndRent";
        this.barLabel = intlContext.formatMessage({
          id: "progressSaleAndRent",
        });
        break;
      }
      case forSale: {
        this.title = "projectProgressTitleSale";
        this.barLabel = intlContext.formatMessage({ id: "progressSale" });
        break;
      }
      case forRent: {
        this.title = "projectProgressTitleRent";
        this.barLabel = intlContext.formatMessage({ id: "progressRent" });
        break;
      }
      default:
        this.title = "projectProgressTitle";
        break;
    }

    this.calculateTotalProgress = this.calculateTotalProgress.bind(this);
    this.getObjectTypeProgress = this.getObjectTypeProgress.bind(this);
    this.renderObjectTypeProgress = this.renderObjectTypeProgress.bind(this);
    this.renderProgressBar = this.renderProgressBar.bind(this);
  }

  public render() {
    const { buildnumbers, objectTypes, forRent, forSale } = this.props;
    if (!buildnumbers || buildnumbers.length === 0) return null;
    const progress = this.calculateTotalProgress();

    return (
      <div styleName="progress">
        <h2 styleName="title">
          <ResourceText resourceKey={this.title} />
        </h2>
        {this.renderProgressBar(progress, "")}
        {!!objectTypes && objectTypes.map(this.renderObjectTypeProgress)}
      </div>
    );
  }

  private calculateTotalProgress() {
    const { buildnumbers } = this.props;
    const count = buildnumbers.length;
    const soldCount = buildnumbers.filter(
      (buildnumber) =>
        [AvailabilityStatus.Rented, AvailabilityStatus.Sold].indexOf(
          buildnumber.availabilityStatus
        ) !== -1
    ).length;

    if (soldCount === 0) return 0;

    return Math.round((soldCount / count) * 100);
  }

  private getObjectTypeProgress(objectTypeId: string) {
    const buildnumbers = this.props.buildnumbers.filter(
      (buildnumber) =>
        get(buildnumber, "linkedObjectTypeAssignment.id") === objectTypeId
    );
    const count = buildnumbers.length;
    const soldCount = buildnumbers.filter(
      (buildnumber) =>
        [AvailabilityStatus.Rented, AvailabilityStatus.Sold].indexOf(
          buildnumber.availabilityStatus
        ) !== -1
    ).length;

    if (soldCount === 0) return 0;

    return Math.round((soldCount / count) * 100);
  }

  private renderObjectTypeProgress(objectType: AssignmentSnapShot) {
    const { id, displayName } = objectType;
    const progress = this.getObjectTypeProgress(objectType.id);

    return this.renderProgressBar(progress, displayName, id, true);
  }

  private renderProgressBar(
    progress: number,
    label: string,
    idx: string = "0",
    isObjectType: boolean = false
  ) {
    return (
      <div
        styleName={classNames("progress-bar__wrapper", {
          "for-object-type": isObjectType,
        })}
        key={idx}
      >
        {label && (
          <h2>
            <ResourceText resourceKey={label} />
          </h2>
        )}
        <div styleName="progress-bar">
          <ResourceText
            resourceKey="projectProgressBarProgress"
            values={{ progress, label: this.barLabel }}
          />
          <div styleName="bar" style={{ width: `${progress}%` }}>
            <ResourceText
              resourceKey="projectProgressBarProgress"
              values={{ progress, label: this.barLabel }}
            />
          </div>
        </div>
      </div>
    );
  }
}
