import { Dispatch } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { FileUploadComponent } from "./file-upload.component";

interface StateProps {
  token: string;
  host: string;
  apiVersion: string;
  realEstateAgencyId: string;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, FileUploadComponentProps>(
  state: AppState
) => ({
  token: state.access.token,
  host: state.appSettings.host,
  apiVersion: state.appSettings.apiVersion,
  realEstateAgencyId: state.account.currentRealestateAgency.id
});

const mapDispatchToProps = <DispatchProps, FileUploadComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type FileUploadContainerProps = StateProps & DispatchProps;
export const FileUploadContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadComponent);
