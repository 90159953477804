export const SEARCH_ASSIGNMENT = {
  SET_SEARCH_ASSIGNMENTS: "SET_SEARCH_ASSIGNMENTS",
  SET_SEARCH_ASSIGNMENT: "SET_SEARCH_ASSIGNMENT",
  SET_SEARCH_ASSIGNMENT_DETAIL_STATUS: "SET_SEARCH_ASSIGNMENT_DETAIL_STATUS",
  APPEND_SEARCH_ASSIGNMENTS: "APPEND_SEARCH_ASSIGNMENTS",
  SET_SEARCH_ASSIGNMENTS_STATUS: "SET_SEARCH_ASSIGNMENTS_STATUS",
  SET_SEARCH_ASSIGNMENTS_FILTERS: "SET_SEARCH_ASSIGNMENTS_FILTERS",
  CLEAR_ALL_SEARCHASSIGNMENTS_FILTERS: "CLEAR_ALL_SEARCHASSIGNMENTS_FILTERS",
  CLEAR_SEARCH_ASSIGNMENTS_FILTER: "CLEAR_SEARCH_ASSIGNMENTS_FILTER",
  SET_ADDRESS_SEARCH_STATUS: "SET_ADDRESS_SEARCH_STATUS",
  SET_SELECTED_ROW_HEADER: "SET_SELECTED_ROW_HEADER",
  SET_SEARCH_ASSIGNMENT_PUBLISH_STATUS: "SET_SEARCH_ASSIGNMENT_PUBLISH_STATUS",
  SET_SAVE_SEARCH_ASSIGNMENTS_STATUS: "SET_SAVE_SEARCH_ASSIGNMENTS_STATUS",
  UPDATE_SEARCH_ASSIGNMENT: "UPDATE_SEARCH_ASSIGNMENT",
  TOGGLE_SEARCH_ASSIGNMENT_SAVE_MODAL: "TOGGLE_SEARCH_ASSIGNMENT_SAVE_MODAL",
  SET_MATCHMAILS_STATE: "SET_MATCHMAILS_STATE",
  SET_MATCHMAILS: "SET_MATCHMAILS",
  APPEND_MATCHMAILS: "APPEND_MATCHMAILS",
  SET_MODAL_MATCHING_PROPERTIES_STATE: "SET_MODAL_MATCHING_PROPERTIES_STATE",
  SET_MODAL_MATCHING_PROPERTIES: "SET_MODAL_MATCHING_PROPERTIES",
  CLEAR_MODAL_MATCHING_PROPERTIES: "CLEAR_MODAL_MATCHING_PROPERTIES",
  UPDATE_LIST_ITEM: "SEARCH_ASSIGNMENTS_UPDATE_LIST_ITEM",
  DELETE_LIST_ITEM: "SEARCH_ASSIGNMENTS_DELETE_LIST_ITEM",
};
