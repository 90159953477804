import { InitializedWidget, WidgetSnapShot } from "@haywork/api/authorization";
import { WidgetsActions, widgetsActions } from "./widgets.actions";

export interface WidgetsState {
  consentedWidgets: WidgetSnapShot[];
  initializedWidgets: InitializedWidget[];
  initializedWidgetsEntityId: string | string[];
  widgetModalVisibility: boolean;
  widgetModalWidget: WidgetSnapShot;
  widgetModalInitializedWidget: InitializedWidget;
}

const INITIAL_STATE: WidgetsState = {
  consentedWidgets: [],
  initializedWidgets: [],
  initializedWidgetsEntityId: null,
  widgetModalVisibility: false,
  widgetModalWidget: null,
  widgetModalInitializedWidget: null,
};

export const widgetsReducer = (
  state: WidgetsState = INITIAL_STATE,
  action: WidgetsActions
): WidgetsState => {
  switch (action.type) {
    case widgetsActions.SetConsentedWidgets: {
      const { widgets } = action;

      return {
        ...state,
        consentedWidgets: widgets,
      };
    }
    case widgetsActions.SetInitializedWidgets: {
      const { initializedWidgets, initializedWidgetsEntityId } = action;

      return {
        ...state,
        initializedWidgets,
        initializedWidgetsEntityId,
      };
    }
    case widgetsActions.ShowModal: {
      const { widgetModalWidget, widgetModalInitializedWidget } = action;

      return {
        ...state,
        widgetModalVisibility: true,
        widgetModalWidget,
        widgetModalInitializedWidget,
      };
    }
    case widgetsActions.HideModal: {
      return {
        ...state,
        widgetModalVisibility: false,
      };
    }
    case widgetsActions.ResetModal: {
      return {
        ...state,
        widgetModalWidget: null,
        widgetModalInitializedWidget: null,
      };
    }
    case widgetsActions.ResetApp: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
