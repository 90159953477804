import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditMarketingComponent,
  ObjectTypesEditMarketingComponentProps
} from "@haywork/modules/object-types";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  currentType: ObjectTypeAssignment;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, ObjectTypesEditMarketingComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;

  return {
    currentType
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesEditMarketingComponentProps
>(
  dispatch: Dispatch<any>
) => ({});

export type ObjectTypesEditMarketingContainerProps = StateProps & DispatchProps;
export const ObjectTypesEditMarketingContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditMarketingComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditMarketingComponent);
