import * as React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import { InputComponentProps } from "../input.component";

type RadioGroupInputComponentProps = {
  asButtonList?: boolean;
  multiline?: boolean;
  canUnselect?: boolean;
};
type Props = RadioGroupInputComponentProps & InputComponentProps;

export class RadioGroupComponent extends React.Component<Props> {
  public static childContextTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.any,
    updateValue: PropTypes.func.isRequired,
  };

  public getChildContext() {
    return {
      disabled: this.props.disabled,
      value: this.props.value,
      updateValue: this.updateValue.bind(this),
    };
  }

  public render() {
    const radioGroupInputStyle = classNames("radio-group__input", {
      "button-list": this.props.asButtonList,
      multiline: this.props.multiline,
    });

    return <div className={radioGroupInputStyle}>{this.props.children}</div>;
  }

  private updateValue(value: any) {
    if (this.props.disabled) return;
    const changeValue =
      value === this.props.value && this.props.canUnselect ? "" : value;
    this.props.onChange(changeValue);
  }
}
