import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import { PagedResults, StoreHelper, Status } from "@haywork/stores/helpers";
import { AccountShare } from "@haywork/api/mail";

import * as ActionType from "./shares.types";

export interface SharesState {
  shares: PagedResults<AccountShare>;
}

const INITIAL_STATE: SharesState = {
  shares: StoreHelper.createInitialPagedResult()
};

export const sharesReducer = (state: SharesState = INITIAL_STATE, action: Action): SharesState => {
  switch (action.type) {
    case REDUX.EMAILV2.SET_SHARES_STATUS: {
      const shares = {
        ...state.shares,
        ...StoreHelper.setObjectStatus(<Status> action)
      };

      return {
        ...state,
        shares
      };
    }
    case REDUX.EMAILV2.SET_SHARES: {
      const shares = StoreHelper.setPagedResults(<ActionType.SharesPayload> action);

      return {
        ...state,
        shares
      };
    }
    case REDUX.EMAILV2.CLEAR_SHARES: {
      return INITIAL_STATE;
    }
    case REDUX.EMAILV2.ADD_SINGLE_SHARE: {
      const { share } = <ActionType.Share> action;
      const shares = {
        ...state.shares,
        results: [
          ...state.shares.results,
          share
        ],
        status: REQUEST.SUCCESS
      };

      return {
        ...state,
        shares
      };
    }
    case REDUX.EMAILV2.REMOVE_SINGLE_SHARE: {
      const { share } = <ActionType.Share> action;
      const shares = {
        ...state.shares,
        results: state.shares.results.filter((s) => s.id !== share.id),
        status: REQUEST.SUCCESS
      };

      return {
        ...state,
        shares
      };
    }
    case REDUX.EMAILV2.SET_SHARE_VISIBILITY: {
      const { id, visible } = <ActionType.ShareVisibility> action;
      const results = state.shares.results.map((share) => {
        if (share.id === id) {
          return {
            ...share,
            isHidden: !visible
          };
        }
        return share;
      });

      return {
        ...state,
        shares: {
          ...state.shares,
          results
        }
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
