import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue } from "../../";
import { ListFilterContext } from "../../list-filter.context";
import { Input } from "@haywork/modules/form";
import { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");
const SelectEmptyValue = "no-option-selected";

type Props = {
  configKey: string;
  value: SingleFilterValue;
  values: SingleFilterValue[];
  title?: Translateable;
};

export const MlsPriceRangeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ configKey, value, values, title }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);
      const [minValue, setMinValue] = useState(value?.value?.min || "");
      const [maxValue, setMaxValue] = useState(value?.value?.max || "");
      const [priceType, setPriceType] = useState(value?.value?.type || "");

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const minPlaceholder = useMemo(() => {
        if (!filter) return undefined;
        return `range.placeholder.min.${configKey}`;
      }, [configKey]);

      const maxPlaceholder = useMemo(() => {
        if (!filter) return undefined;
        return `range.placeholder.max.${configKey}`;
      }, [configKey]);

      const handleInputBlur = useCallback(() => {
        if (!filter || !priceType || (!minValue && !maxValue)) return;

        const updatedFilter = {
          ...filter,
          value: {
            min: minValue || undefined,
            max: maxValue || undefined,
            type: priceType || undefined,
          },
        };

        onFilterChange({
          ...filters,
          [configKey]: updatedFilter,
        });
      }, [
        filter,
        filters,
        configKey,
        onFilterChange,
        minValue,
        maxValue,
        priceType,
      ]);

      const handleInputChange = useCallback(
        (value: any) => {
          setPriceType(value);

          if (!filter || !value || (!minValue && !maxValue)) return;

          const updatedFilter = {
            ...filter,
            value: {
              min: minValue || undefined,
              max: maxValue || undefined,
              type: value || undefined,
            },
          };

          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [
          setPriceType,
          minValue,
          maxValue,
          filter,
          filters,
          configKey,
          onFilterChange,
        ]
      );

      useEffect(() => {
        setMinValue(value?.value?.min || "");
        setMaxValue(value?.value?.max || "");
        setPriceType(value?.value?.type || "");
      }, [value, setMinValue, setMaxValue, setPriceType]);

      if (!filter) return null;

      return (
        <div styleName="wrapper">
          {!!label && <h2>{label}</h2>}
          <div styleName="select">
            <Input.NewSelect
              name={`${configKey}.type`}
              value={priceType}
              values={values}
              onChange={handleInputChange}
              asSingleInput
              valueOutMapper={(value) => value.value}
              valuesProp="value"
              translate
              translateValue={(value) =>
                isString(value.label) ? value.label : value.label.value
              }
              translateValues={(value) =>
                isString(value.label) ? {} : value.label.values
              }
            />
          </div>

          <div styleName="range">
            <div styleName="input">
              <div className="input__helper">
                <div className="pre small">&euro;</div>
                <Input.Number
                  name={`${configKey}.min`}
                  value={minValue}
                  onBlur={handleInputBlur}
                  onChange={setMinValue}
                  placeholder={minPlaceholder}
                  pretty
                  asSingleInput
                  fireAllChanges
                />
              </div>
            </div>
            <div styleName="input">
              <div className="input__helper">
                <div className="pre small">&euro;</div>
                <Input.Number
                  name={`${configKey}.max`}
                  value={maxValue}
                  onBlur={handleInputBlur}
                  onChange={setMaxValue}
                  placeholder={maxPlaceholder}
                  pretty
                  asSingleInput
                  fireAllChanges
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);
