import {
  AssignmentPhase,
  CommissionPaidBy,
  CommissionType,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { ASSIGNMENTROUTES, REQUEST } from "@haywork/constants";
import { AssignmentPublishContainerProps } from "@haywork/modules/assignment";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import Publications from "@haywork/modules/shared/components/publish-publications";
import { SingleAssignmentState } from "@haywork/stores";
import { AssignmentUtil, FormControlUtil, RouteUtil } from "@haywork/util";
import { AssignmentPublishError } from "@haywork/util/assignment";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Mls from "./mls";

const styles = require("./publish.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;
const preflight = AssignmentUtil.preflightPublishObjectAssignment;

interface AssignmentPublishComponentProps {}
interface AssignmentPublishComponentState {
  showFundaDate: boolean;
  hideOnFundaUntil: Date;
  showConfirmModal: boolean;
  fromSubmit: boolean;
  publishErrors: AssignmentPublishError[];
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentPublishComponent extends React.Component<
  AssignmentPublishComponentProps & AssignmentPublishContainerProps,
  AssignmentPublishComponentState
> {
  private formControls: FormControls;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      showFundaDate: this.props.objectAssignment.hideOnFundaUntil
        ? true
        : false,
      hideOnFundaUntil: this.props.objectAssignment.hideOnFundaUntil,
      showConfirmModal: false,
      fromSubmit: false,
      publishErrors: preflight(
        this.props.objectAssignment,
        this.props.constructionPeriods
      ),
    };

    this.formControls = {
      toggleFunda: {
        value: this.props.objectAssignment.hideOnFundaUntil ? true : false,
        onChange: (ref) => {
          if (!ref.value) {
            this.setState({
              showFundaDate: false,
              hideOnFundaUntil: undefined,
            });
            return { hideOnFundaUntil: "" };
          } else {
            this.setState({ showFundaDate: true });
          }
        },
      },
      hideOnFundaUntil: {
        value: this.props.objectAssignment.hideOnFundaUntil,
        onChange: (ref) => {
          this.setState({ hideOnFundaUntil: ref.value });
        },
        validators: [
          Validators.required(undefined, () => !this.state.showFundaDate),
          Validators.dateShouldBeAfter(new Date()),
        ],
      },
    };

    this.onNavigateClickHandler = this.onNavigateClickHandler.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onConformModalCloseHandler =
      this.onConformModalCloseHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    const { id, photos, videos, displayName, forSale } =
      this.props.objectAssignment;
    const total = intlContext.formatNumber(
      forSale ? this.calculateCourtage() : 0,
      {
        style: "currency",
        currency: "EUR",
      }
    );
    const photoCount = !!photos ? photos.length : 0;
    const videoCount = !!videos ? videos.length : 0;
    const firstPhoto: any = photoCount ? photos[0] : null;
    const previewStyles = photoCount
      ? {
          backgroundImage: `url(${JSON.stringify(
            firstPhoto.urlPreview || firstPhoto.thumbUrl
          )})`,
        }
      : null;

    return (
      <div styleName="publish" ref={(ref) => (this.ref = ref)}>
        <div className="container-fluid">
          <h1>
            <ResourceText resourceKey="publishStarting" />
          </h1>

          <div styleName="publish__info">
            <div styleName="worth">
              {forSale && (
                <ResourceText
                  resourceKey="publishWorth"
                  values={{ value: total }}
                  asHtml
                />
              )}
            </div>
            <div styleName="preview" style={previewStyles}>
              {!photoCount && <i className="fal fa-camera-retro" />}
            </div>
            <div styleName="address">{displayName}</div>
          </div>

          <div styleName="publish__list">
            <FeatureSwitch feature="PUBLICATIONS_V2" disable>
              {this.renderListItem(
                "publishMediaPartners",
                this.props.publicationCount,
                route(ASSIGNMENTROUTES.EDIT_MARKETING_PARTNERS.URI, { id })
              )}
            </FeatureSwitch>

            <FeatureSwitch feature="PUBLICATIONS_V2">
              <Publications
                assignmentId={this.props.objectAssignment.id}
                listingType={this.props.objectAssignment.listingType}
                isAssignmentConcept={
                  this.props.objectAssignment.assignmentPhase ===
                  AssignmentPhase.Concept
                }
              />
            </FeatureSwitch>

            {this.props.agencyGroups.map((group, idx) => (
              <Mls
                group={group}
                mlsGroup={this.props.objectAssignment.mlsGroup}
                mlsReleaseDate={this.props.objectAssignment.mlsReleaseDate}
                onNavigate={() =>
                  this.onNavigateClickHandler(
                    route(ASSIGNMENTROUTES.EDIT_MARKETING_MLS.URI, { id })
                  )
                }
                key={idx}
              />
            ))}

            {this.renderListItem(
              "publishPhotos",
              photoCount,
              route(ASSIGNMENTROUTES.EDIT_MARKETING_PHOTOS.URI, { id })
            )}

            {this.renderListItem(
              "publishVideos",
              videoCount,
              route(ASSIGNMENTROUTES.EDIT_MARKETING_VIDEOS.URI, { id })
            )}
          </div>

          <div styleName="publish__form">
            <Form
              name="publish"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              onChange={this.onChangeHandler}
            >
              {this.props.objectAssignment.realEstateGroup !==
                RealEstateGroup.Agricultural && (
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="toggleFunda"
                        on={true}
                        off={false}
                        label="hideOnFunda"
                        labelPosition={SwitchLabelPosition.Pre}
                      />
                    </div>
                    {this.state.showFundaDate && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="untill" />
                        </div>
                        <div className="column">
                          <Input.Datepicker
                            name="hideOnFundaUntil"
                            hour={0}
                            minutes={0}
                            seconds={0}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.showFundaDate && (
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <div styleName="hide-on-funda-info">
                        <ResourceText
                          resourceKey="hideOnFunda.extraInfo"
                          asHtml
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <button
                      type="submit"
                      className="btn btn-primary emphasize"
                      data-cy="CY-submitPublishButton"
                    >
                      <ResourceText resourceKey="publishAssignment" />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          visible={this.state.showConfirmModal}
          onClose={this.onConformModalCloseHandler}
        >
          <ModalHeader close title="publishAssignment" />
          <ModalBody>
            <ResourceText resourceKey="startPublish" />
          </ModalBody>
          <ModalFooter style="no-padding">
            <button
              className="btn btn-success text-center full emphasize"
              disabled={this.props.publishState === REQUEST.PENDING}
              onClick={this.onPublishClickHandler}
              data-cy="CY-publishAssignment"
            >
              <ButtonLoader
                resourceKey="publish"
                loading={this.props.publishState === REQUEST.PENDING}
              />
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentPublishComponentProps & AssignmentPublishContainerProps
  ) {
    if (
      nextProps &&
      nextProps.publishState === REQUEST.ERROR &&
      this.state.showConfirmModal === true &&
      !this.state.fromSubmit
    ) {
      this.setState({ showConfirmModal: false });
    }
  }

  private onPublishErrorClickHandler() {
    this.props.setFormErrors(
      this.state.publishErrors,
      this.props.objectAssignment.id
    );
  }

  private renderListItem(
    label: string,
    count: number,
    editRoute: string
  ): React.ReactElement<HTMLDivElement> {
    const iconType = classNames(
      "fal",
      "fa-fw",
      count === 0 ? "fa-exclamation-triangle" : "fa-check-circle"
    );
    const iconStyle = classNames("listitem__icon", { warn: count === 0 });
    const btnStyle = classNames(
      "btn",
      count === 0 ? "btn-warning" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          <ResourceText resourceKey={label} values={{ count }} />
        </div>
        <div styleName="listitem__action">
          <div
            className={btnStyle}
            onClick={() => this.onNavigateClickHandler(editRoute)}
          >
            <ResourceText resourceKey="adjust" />
          </div>
        </div>
      </div>
    );
  }

  private onNavigateClickHandler(route: string) {
    this.props.navigate(route);
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.setState({ showConfirmModal: true, fromSubmit: true }, () => {
      this.setState({ fromSubmit: false });
    });
  }

  private onChangeHandler(values: FormReturnValue) {
    const { currentComponentState } = this.props;

    const newState: SingleAssignmentState = {
      ...currentComponentState,
      objectAssignment: {
        ...currentComponentState.objectAssignment,
        hideOnFundaUntil: values.hideOnFundaUntil,
      },
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private onConformModalCloseHandler() {
    this.setState({ showConfirmModal: false });
  }

  private onPublishClickHandler() {
    const { id } = this.props.objectAssignment;
    this.props.publishAssignment(id, this.state.hideOnFundaUntil);
  }

  private calculateCourtage(): number {
    let total = 0;
    const { saleOffer } = this.props.objectAssignment;
    const commissionCustomerGross = parseFloat(
      value(saleOffer, "commissionCustomerGross", 0)
    );
    const commissionOwnerGross = parseFloat(
      value(saleOffer, "commissionOwnerGross", 0)
    );
    const commissionCustomerPercent = parseFloat(
      value(saleOffer, "commissionCustomerPercent", 0)
    );
    const commissionOwnerPercent = parseFloat(
      value(saleOffer, "commissionOwnerPercent", 0)
    );

    const salePrice = parseFloat(value(saleOffer, "salePrice", 0));

    const fixedCustomer = commissionCustomerGross;
    const fixedOwner = commissionOwnerGross;
    const percentageCustomer = (commissionCustomerPercent / 100) * salePrice;
    const percentageOwner = (commissionOwnerPercent / 100) * salePrice;

    switch (saleOffer.commissionPaidBy) {
      case CommissionPaidBy.Customer: {
        switch (saleOffer.commissionType) {
          case CommissionType.Fixed: {
            total = fixedCustomer;
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            total = fixedCustomer + percentageCustomer;
            break;
          }
          case CommissionType.Percentage: {
            total = percentageCustomer;
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.Owner: {
        switch (saleOffer.commissionType) {
          case CommissionType.Fixed: {
            total = fixedOwner;
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            total = fixedOwner + percentageOwner;
            break;
          }
          case CommissionType.Percentage: {
            total = percentageOwner;
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.OwnerAndCustomer:
      default: {
        switch (saleOffer.commissionType) {
          case CommissionType.Fixed: {
            total = fixedOwner + fixedCustomer;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            total =
              fixedOwner + percentageOwner + fixedCustomer + percentageCustomer;
            break;
          }
          case CommissionType.Percentage: {
            total = percentageOwner + percentageCustomer;
            break;
          }
        }
        break;
      }
    }

    return total;
  }
}
