import * as React from "react";
import { LinkedRelation } from "@haywork/api/kolibri";
import { FC, useState, useCallback, useRef, useMemo } from "react";
import { FeatureSwitch } from "@haywork/modules/feature-switch/components/feature-switch.component";
import RelationInfo from "@haywork/components/ui/relation-info";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import CSSModules = require("react-css-modules");
import { RelationUtil } from "@haywork/util";

const styles = require("./style.scss");

interface ComponentProps {
  relation: LinkedRelation;
  onRelationClick: (relation: LinkedRelation, isPartner?: boolean) => void;
  onRemoveRelationClick: (
    event: React.MouseEvent<HTMLDivElement>,
    relation: LinkedRelation
  ) => void;
}

type Props = ComponentProps;

export const SinglePill: FC<Props> = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ relation, onRelationClick, onRemoveRelationClick }: Props) => {
      const [touched, setTouched] = useState(false);
      const [firstTouch, setFirstTouch] = useState(true);

      const [hover, toggleHover] = useState(false);
      const contextMenuParentRef = useRef<HTMLDivElement>();
      const timeoutRef = useRef<any>(null);

      const onRelationClickHandler = useCallback(
        (relation: LinkedRelation) => {
          if (touched && firstTouch) {
            setFirstTouch(false);
          }

          onRelationClick(relation);

          setTouched(false);
          setFirstTouch(true);
        },
        [onRelationClick, setTouched, setFirstTouch]
      );

      const mouseEnterCallback = useCallback(() => {
        if (!!timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        toggleHover(true);
      }, [toggleHover]);

      const mouseLeaveCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          toggleHover(false);
        }, 50);
      }, [toggleHover]);

      const item = useMemo(
        () => RelationUtil.mapLinkedRelationToSnapshot(relation),
        [relation]
      );

      return (
        <>
          <div
            className="single-pill"
            onTouchStart={() => setTouched(true)}
            onClick={() => onRelationClickHandler(relation)}
            data-cy="CY-singlePill"
            onMouseEnter={mouseEnterCallback}
            onMouseLeave={mouseLeaveCallback}
            ref={contextMenuParentRef}
          >
            <div className="value">{relation.displayName}</div>
            <div
              className="remove"
              onClick={(event) => onRemoveRelationClick(event, relation)}
            >
              <i className="fal fa-times" />
            </div>
          </div>
          <FeatureSwitch feature="RELATION_INFO">
            <ContextMenu
              visible={hover}
              parent={contextMenuParentRef}
              width={500}
              position={ContextMenuPosition.TopRight}
            >
              <div
                styleName="context-menu"
                onMouseEnter={mouseEnterCallback}
                onMouseLeave={mouseLeaveCallback}
              >
                <RelationInfo item={item} visible={hover} />
              </div>
            </ContextMenu>
          </FeatureSwitch>
        </>
      );
    }
  )
);
