import * as React from "react";
import classNames from "classnames";

interface TimeListItemComponentProps {
  value: string;
  selectedValue: string;
  list: HTMLDivElement;
  onItemClick: (value: string) => void;
}
interface TimeListItemComponentState {
  selected: boolean;
}

export class TimeListItemComponent extends React.Component<
  TimeListItemComponentProps,
  TimeListItemComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.value === this.props.selectedValue,
    };
  }

  public render() {
    const { value } = this.props;
    const optionStyle = classNames("form__select-option", {
      active: this.state.selected,
    });

    return (
      <div
        className={optionStyle}
        onClick={() => this.props.onItemClick(value)}
        ref={(ref) => (this.ref = ref)}
        data-cy={this.props["data-cy"]}
      >
        {value}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TimeListItemComponentProps
  ) {
    let selected = false;
    const ref = this.ref;

    if (nextProps.value === nextProps.selectedValue && !!ref) {
      selected = true;
      setTimeout(() => {
        const { clientHeight, scrollTop } = nextProps.list;
        const top = ref.offsetTop;
        const bottom = top + ref.clientHeight;

        if (bottom > clientHeight + scrollTop || top < scrollTop) {
          nextProps.list.scrollTop =
            top + ref.clientHeight / 2 - clientHeight / 2;
        }
      }, 5);
    }

    this.setState({ selected });
  }
}
