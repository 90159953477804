import { Language, TypePART } from "@haywork/api/kolibri";
import { OBJECTTYPESROUTES, REQUEST } from "@haywork/constants";
import { EditableHocProps } from "@haywork/modules/editable";
import { AppState } from "@haywork/stores";
import { ExtendedObjectTypeAssignment } from "@haywork/stores/project/types";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import { RouteComponentProps } from "react-router-dom";
import { createSelector } from "reselect";
import { useIntl } from "react-intl";

const route = RouteUtil.mapStaticRouteValues;

const currentTypeSelector = (state: AppState) =>
  state.project.types.currentType;
const currentEditableTypeSelector = (state: AppState) =>
  state.editable.currentComponentState;
const locationSelector = (
  ownProps: EditableHocProps & RouteComponentProps<any>
) => ownProps.location;
const mastertableSelector = (state: AppState) => state.main.mastertable.kolibri;
const savingTypeSelector = (state: AppState) =>
  state.project.types.saveObjectTypeStatus;
const loadingBuildnumbersSelector = (state: AppState) =>
  state.project.types.buildnumbersStatus;
const buildnumbersSelector = (state: AppState) =>
  state.project.types.buildnumbers;
const editableStatesSelector = (state: AppState) => state.editable.states || [];

const subtractDisplayName = (currentType: ExtendedObjectTypeAssignment) => {
  let text: string;
  if (!!currentType && isArray(currentType.titleText)) {
    text = get(
      currentType.titleText.find((text) => text.language === Language.Dutch),
      "text"
    );
  }
  return text;
};
export const displayName = createSelector(
  currentTypeSelector,
  subtractDisplayName
);
export const editableDisplayName = createSelector(
  currentEditableTypeSelector,
  subtractDisplayName
);

const subtractObjectTypeInfo = (currentType: ExtendedObjectTypeAssignment) => {
  const {
    typePART,
    houseSort,
    houseType,
    houseCharacteristic,
    apartmentSort,
    apartmentCharacteristic,
  } = currentType;

  const intl = useIntl();

  const typePARTValue = !!typePART
    ? intl.formatMessage({
        id: `typePARTOptions.${typePART.toString()}`,
        defaultMessage: `typePARTOptions.${typePART.toString()}`,
      })
    : null;
  const houseSortValue = !!houseSort
    ? intl.formatMessage({
        id: `houseSorts.${houseSort.toString()}`,
        defaultMessage: `houseSorts.${houseSort.toString()}`,
      })
    : null;
  const houseTypeValue = !!houseType
    ? intl.formatMessage({
        id: `houseTypes.${houseType.toString()}`,
        defaultMessage: `houseTypes.${houseType.toString()}`,
      })
    : null;
  const houseCharacteristicValue = !!houseCharacteristic
    ? intl.formatMessage({
        id: `houseCharacteristics.${houseCharacteristic.toString()}`,
        defaultMessage: `houseCharacteristics.${houseCharacteristic.toString()}`,
      })
    : null;
  const apartmentSortValue = !!apartmentSort
    ? intl.formatMessage({
        id: `apartmentSorts.${apartmentSort.toString()}`,
        defaultMessage: `apartmentSorts.${apartmentSort.toString()}`,
      })
    : null;
  const apartmentCharacteristicValue = !!apartmentCharacteristic
    ? intl.formatMessage({
        id: `apartmentCharacteristics.${apartmentCharacteristic.toString()}`,
        defaultMessage: `apartmentCharacteristics.${apartmentCharacteristic.toString()}`,
      })
    : null;

  const houseInfo = [
    typePARTValue,
    houseSortValue,
    houseTypeValue,
    houseCharacteristicValue,
    apartmentSortValue,
    apartmentCharacteristicValue,
  ];

  return houseInfo.filter((value) => !!value).join(", ");
};
export const objectTypeInfo = createSelector(
  currentTypeSelector,
  subtractObjectTypeInfo
);
export const editableObjectTypeInfo = createSelector(
  currentEditableTypeSelector,
  subtractObjectTypeInfo
);

const subtractBuildingStartText = (
  currentType: ExtendedObjectTypeAssignment
) => {
  let text: string;
  if (isArray(currentType.buildingStartText)) {
    text = get(
      currentType.buildingStartText.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const buildingStartText = createSelector(
  currentTypeSelector,
  subtractBuildingStartText
);
export const editableBuildingStartText = createSelector(
  currentEditableTypeSelector,
  subtractBuildingStartText
);

const subtractDeliveryStartText = (
  currentType: ExtendedObjectTypeAssignment
) => {
  let text: string;
  if (isArray(currentType.deliveryStartText)) {
    text = get(
      currentType.deliveryStartText.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const deliveryStartText = createSelector(
  currentTypeSelector,
  subtractDeliveryStartText
);
export const editableDeliveryStartText = createSelector(
  currentEditableTypeSelector,
  subtractDeliveryStartText
);

const subtractsaleStartText = (currentType: ExtendedObjectTypeAssignment) => {
  let text: string;
  if (isArray(currentType.saleStartText)) {
    text = get(
      currentType.saleStartText.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const saleStartText = createSelector(
  currentTypeSelector,
  subtractsaleStartText
);
export const editableSaleStartText = createSelector(
  currentEditableTypeSelector,
  subtractsaleStartText
);

export const priceRange = createSelector(currentTypeSelector, (currentType) => {
  let range: NumberRange;
  switch (true) {
    case currentType.forSale:
      if (
        !!currentType.saleOffer.salePrice ||
        currentType.saleOffer.salePriceMax
      ) {
        range = {
          min: currentType.saleOffer.salePrice,
          max: currentType.saleOffer.salePriceMax,
        };
      }
      break;
    case currentType.forRent:
      if (
        !!currentType.rentOffer.rentPrice ||
        currentType.rentOffer.rentPriceMax
      ) {
        range = {
          min: currentType.rentOffer.rentPrice,
          max: currentType.rentOffer.rentPriceMax,
        };
      }
      break;
    default:
      break;
  }
  return range;
});

export const parcelRange = createSelector(
  currentTypeSelector,
  (currentType) => {
    let range: NumberRange;
    const parcelSurface = get(currentType, "parcelSurface");
    if (
      !!parcelSurface &&
      (has(parcelSurface, "minArea") || has(parcelSurface, "maxArea"))
    ) {
      range = {
        min: parcelSurface.minArea,
        max: parcelSurface.maxArea,
      };
    }
    return range;
  }
);

export const usableAreaRange = createSelector(
  currentTypeSelector,
  (currentType) => {
    let range: NumberRange;
    const usableAreaRange = get(currentType, "usableArea");
    if (
      !!usableAreaRange &&
      (has(usableAreaRange, "minArea") || has(usableAreaRange, "maxArea"))
    ) {
      range = {
        min: usableAreaRange.minArea,
        max: usableAreaRange.maxArea,
      };
    }
    return range;
  }
);

export const livingRoomRange = createSelector(
  currentTypeSelector,
  (currentType) => {
    let range: NumberRange;
    const livingRoomSurface = get(currentType, "livingRoomSurface");
    if (
      !!livingRoomSurface &&
      (has(livingRoomSurface, "minArea") || has(livingRoomSurface, "maxArea"))
    ) {
      range = {
        min: livingRoomSurface.minArea,
        max: livingRoomSurface.maxArea,
      };
    }
    return range;
  }
);

export const contentsRange = createSelector(
  currentTypeSelector,
  (currentType) => {
    let range: NumberRange;
    const contents = get(currentType, "contents");
    if (!!contents && (has(contents, "minArea") || has(contents, "maxArea"))) {
      range = {
        min: contents.minVolume,
        max: contents.maxVolume,
      };
    }
    return range;
  }
);

export const isInEditMode = createSelector(
  locationSelector,
  (location) => !!location && /edit/.test(location.pathname)
);

export const photos = createSelector(
  currentTypeSelector,
  (currentType) => currentType.photos || []
);

export const listingTypes = createSelector(
  mastertableSelector,
  (mastertable) => {
    const validTypes = [
      TypePART.ResidentialBuilding,
      TypePART.Apartment,
      TypePART.BuildingGround,
    ];
    return mastertable.typePARTOptions.filter(
      (type) => validTypes.indexOf(type.value) !== -1
    );
  }
);

export const path = createSelector(currentTypeSelector, ({ id }) =>
  route(OBJECTTYPESROUTES.DETAIL.URI, { id })
);

export const savingType = createSelector(
  savingTypeSelector,
  (status) => status === REQUEST.PENDING
);

export const objectTypePrice = createSelector(
  currentTypeSelector,
  (currentType) => {
    const { forSale, saleOffer, rentOffer } = currentType;
    let price = 0;

    if (!!forSale) {
      price = get(saleOffer, "salePrice", 0);
    } else {
      price = get(rentOffer, "rentPrice", 0);
    }

    return price;
  }
);

export const buildnumbersLoading = createSelector(
  loadingBuildnumbersSelector,
  (loadingStatus) => loadingStatus === REQUEST.PENDING
);

export const projectName = createSelector(currentTypeSelector, (currentType) =>
  get(currentType, "parentProject.displayName", "")
);

export const buildnumbers = createSelector(
  [currentTypeSelector, buildnumbersSelector],
  (currentType, buildnumbers) => {
    return buildnumbers.filter(
      (buildnumber) =>
        get(buildnumber, "linkedObjectTypeAssignment.id") ===
        get(currentType, "id")
    );
  }
);

export const fullyBakedDisplayName = createSelector(
  [displayName, currentTypeSelector],
  (objectTypeDisplayName, currentType) => {
    let displayName = objectTypeDisplayName;
    const projectName = get(currentType, "linkedProjectAssignment.displayName");

    if (!!projectName) {
      displayName = `${displayName} (${projectName})`;
    }

    return displayName;
  }
);

const subtractCurrentDestinationText = (
  currentType: ExtendedObjectTypeAssignment
) => {
  let text: string;
  if (isArray(currentType.currentDestination)) {
    text = get(
      currentType.currentDestination.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const currentDestinationText = createSelector(
  currentTypeSelector,
  subtractCurrentDestinationText
);
export const editableCurrentDestinationText = createSelector(
  currentEditableTypeSelector,
  subtractCurrentDestinationText
);

const subtractCurrentUsageText = (
  currentType: ExtendedObjectTypeAssignment
) => {
  let text: string;
  if (isArray(currentType.currentUsage)) {
    text = get(
      currentType.currentUsage.find((text) => text.language === Language.Dutch),
      "text"
    );
  }
  return text;
};
export const currentUsageText = createSelector(
  currentTypeSelector,
  subtractCurrentUsageText
);
export const editableCurrentUsageText = createSelector(
  currentEditableTypeSelector,
  subtractCurrentDestinationText
);

export const hasChanges = createSelector(editableStatesSelector, (states) => {
  let hasChanges = false;

  states.map((state) => {
    if (state.active) {
      hasChanges = state.hasChanges;
    }
  });

  return hasChanges;
});
