import { SearchAssignment } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { AddressUtil, RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl, FormatNumberOptions } from "react-intl";
import classNames from "classnames";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  searchAssignment: SearchAssignment;
  onOpenSearchAssignment: (path: string) => void;
};

const LinkedSearchAssignmentsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ searchAssignment, onOpenSearchAssignment }) => {
      const {
        minNrOfRooms,
        maxNrOfRooms,
        minNrOfBedrooms,
        maxNrOfBedrooms,
        minBuildableArea,
        maxBuildableArea,
        priceStart,
        priceEnd,
        locations,
        offerType,
      } = searchAssignment;

      const intl = useIntl();

      const [hover, setHover] = useState(false);

      const renderPriceRange = () => {
        const currencyFormat = {
          style: "currency",
          currency: "EUR",
        } as FormatNumberOptions;
        const min = intl.formatNumber(priceStart || 0, currencyFormat);
        const max = intl.formatNumber(priceEnd || 0, currencyFormat);

        switch (true) {
          case !!priceStart && !!priceEnd: {
            return (
              <I18n value="searchAssignmentPriceMinMax" values={{ min, max }} />
            );
          }
          case !!priceStart: {
            return <I18n value="searchAssignmentPriceMin" values={{ min }} />;
          }
          case !!priceEnd: {
            return <I18n value="searchAssignmentPriceMax" values={{ max }} />;
          }
          default:
            return null;
        }
      };

      const renderMinMaxValue = (name: string, min?: number, max?: number) => {
        switch (true) {
          case !!min && !!max: {
            return (
              <I18n value={`${name}MinMax`} values={{ min, max }} asHtml />
            );
          }
          case !!min: {
            return <I18n value={`${name}Min`} values={{ min }} asHtml />;
          }
          case !!max: {
            return <I18n value={`${name}Max`} values={{ max }} asHtml />;
          }
          default:
            return null;
        }
      };

      const renderRow = useCallback(
        (name: string, min?: number, max?: number) => {
          if (!min && !max) return null;

          return (
            <div styleName="row">
              <div styleName="row__label">
                <I18n value={`acquisition.searchAssignmentLabel.${name}`} />
              </div>
              <div styleName="row__value">
                {renderMinMaxValue(name, min, max)}
              </div>
            </div>
          );
        },
        [searchAssignment]
      );

      const rooms = useMemo(() => {
        return renderRow("rooms", minNrOfRooms, maxNrOfRooms);
      }, [minNrOfRooms, maxNrOfRooms]);

      const bedrooms = useMemo(() => {
        return renderRow("bedrooms", minNrOfBedrooms, maxNrOfBedrooms);
      }, [minNrOfBedrooms, maxNrOfBedrooms]);

      const buildableArea = useMemo(() => {
        return renderRow("buildableArea", minBuildableArea, maxBuildableArea);
      }, [minBuildableArea, maxBuildableArea]);

      const locationsRow = useMemo(() => {
        if (!locations || !locations.length) return null;

        const locationsString = locations
          .map((location) =>
            AddressUtil.createTranslatedSearchAssignmentLocation(location)
          )
          .map(
            (location) =>
              location.displayName +
              (!!location.radius ? ` + ${location.radius}km` : "")
          )
          .filter((v) => !!v)
          .join(", ");

        return (
          <div styleName="row">
            <div styleName="row__label">
              <I18n
                value="acquisition.searchAssignmentLabel.locations"
                values={{ count: locations.length }}
              />
            </div>
            <div styleName="row__value">{locationsString}</div>
          </div>
        );
      }, [locations, intl]);

      const priceRow = useMemo(() => {
        const price = renderPriceRange();
        if (!price) return null;

        return (
          <div styleName="row">
            <div styleName="row__label">
              <I18n prefix="offerTypeOptions" value={offerType.toString()} />
            </div>
            <div styleName="row__value">{price}</div>
          </div>
        );
      }, [priceStart, priceEnd]);

      const openSearchAssignment = useCallback(() => {
        const { id } = searchAssignment;
        const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });
        onOpenSearchAssignment(path);
      }, []);

      return (
        <div styleName={classNames("search-assignment", { hover })}>
          <div
            styleName="search-assignment__action"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={openSearchAssignment}
          >
            <Icon
              name="external-link"
              color={hover ? Colors.White : Colors.Gray}
              regular
              size={16}
            />
          </div>
          {locationsRow}
          {priceRow}
          {rooms}
          {bedrooms}
          {buildableArea}
        </div>
      );
    }
  )
);

export default LinkedSearchAssignmentsComponent;
