import { Language, ObjectTypeAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditMarketingTextComponent,
  ObjectTypesEditMarketingTextComponentProps
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { connect } from "react-redux";
import { path } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  path: string;
  languages: Language[];
  defaultLanguage: Language;
}
interface DispatchProps {
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <
  StateProps,
  ObjectTypesEditMarketingTextComponentProps
>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;
  const {
    enabledLanguages: languages,
    defaultLanguage
  } = state.company.settings;

  return {
    currentType,
    path: path(state),
    languages,
    defaultLanguage
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesEditMarketingTextComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type ObjectTypesEditMarketingTextContainerProps = StateProps &
  DispatchProps;
export const ObjectTypesEditMarketingTextContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditMarketingTextComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditMarketingTextComponent);
