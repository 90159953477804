import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { SearchAssignment, BuildingType } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";

const styles = require("./wishes.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentWidgetWishesComponentProps {
  searchAssignment: SearchAssignment;
}
interface SearchAssignmentWidgetWishesComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetWishesComponent extends React.Component<
  SearchAssignmentWidgetWishesComponentProps,
  SearchAssignmentWidgetWishesComponentState
> {
  public render() {
    const {
      minNrOfRooms,
      maxNrOfRooms,
      minNrOfBedrooms,
      maxNrOfBedrooms,
      minNrOfBathrooms,
      maxNrOfBathrooms,
      minBuildableArea,
      maxBuildableArea,
      minGardenArea,
      maxGardenArea,
      constructionType,
      constructionYearBegin,
      constructionYearEnd,
      orientationMainGarden,
      id,
    } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.EDIT_WHAT.URI, { id }, "wishes");

    return (
      <div>
        <h2>
          <ResourceText resourceKey="wishes" />
        </h2>

        {/* Rooms */}
        {!!(minNrOfRooms || maxNrOfRooms) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="rooms" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue("rooms", minNrOfRooms, maxNrOfRooms)}
            </div>
          </div>
        )}

        {/* Bedrooms */}
        {!!(minNrOfBedrooms || maxNrOfBedrooms) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="bedrooms" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue(
                "bedrooms",
                minNrOfBedrooms,
                maxNrOfBedrooms
              )}
            </div>
          </div>
        )}

        {/* Bathrooms */}
        {!!(minNrOfBathrooms || maxNrOfBathrooms) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="bathrooms" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue(
                "bathrooms",
                minNrOfBathrooms,
                maxNrOfBathrooms
              )}
            </div>
          </div>
        )}

        {/* Buildable area */}
        {!!(minBuildableArea || maxBuildableArea) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="buildableArea" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue(
                "buildableArea",
                minBuildableArea,
                maxBuildableArea
              )}
            </div>
          </div>
        )}

        {/* Construction type */}
        {!!constructionType && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="constructionType" />
            </div>
            <div styleName="row__value">
              <ResourceText
                masterKey="buildingTypeOptions"
                resourceKey={constructionType.toString()}
              />
            </div>
          </div>
        )}

        {/* Construction year */}
        {!!(constructionYearBegin || constructionYearEnd) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="constructionYear" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue(
                "constructionYear",
                constructionYearBegin,
                constructionYearEnd
              )}
            </div>
          </div>
        )}

        {/* Main garden orientation */}
        {!!orientationMainGarden && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="orientationMainGarden" />
            </div>
            <div styleName="row__value">
              <ResourceText
                masterKey="orientations"
                resourceKey={orientationMainGarden.toString()}
              />
            </div>
          </div>
        )}

        {/* Garden area */}
        {!!(minGardenArea || maxGardenArea) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText resourceKey="gardenArea" />
            </div>
            <div styleName="row__value">
              {this.renderRowMinMaxValue(
                "gardenArea",
                minGardenArea,
                maxGardenArea
              )}
            </div>
          </div>
        )}
        {!this.props.searchAssignment.foreignId && (
          <p>
            <Link to={url}>
              <ResourceText resourceKey="viewAllWishes" />
            </Link>
          </p>
        )}
      </div>
    );
  }

  private renderRowMinMaxValue(
    name: string,
    min: number,
    max: number
  ): JSX.Element {
    if (!max) {
      return (
        <ResourceText resourceKey={`${name}Min`} values={{ min }} asHtml />
      );
    }
    if (!min) {
      return (
        <ResourceText resourceKey={`${name}Max`} values={{ max }} asHtml />
      );
    }
    return (
      <ResourceText
        resourceKey={`${name}MinMax`}
        values={{ min, max }}
        asHtml
      />
    );
  }
}
