export const ASSIGNMENT = {
  SET_ASSIGNMENTS: "SET_ASSIGNMENTS",
  APPEND_ASSIGNMENTS: "APPEND_ASSIGNMENTS",
  SET_ASSIGNMENTS_STATUS: "SET_ASSIGNMENTS_STATUS",
  SET_ASSIGNMENTS_FILTERS: "SET_ASSIGNMENTS_FILTERS",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_ASSIGNMENTS_FILTERS",
  CLEAR_FILTER: "CLEAR_ASSIGNMENTS_FILTER",
  SET_ASSIGNMENT: "SET_ASSIGNMENT",
  UPDATE_SINGLE_ASSIGNMENT: "UPDATE_SINGLE_ASSIGNMENT",
  UPDATE_ASSIGNMENT: "UPDATE_ASSIGNMENT",
  UPDATE_COMPANY_LISTINGS: "UPDATE_COMPANY_LISTINGS",
  SET_ASSIGNMENT_STATUS: "SET_ASSIGNMENT_STATUS",
  SET_ASSIGNMENT_EDIT_STATUS: "SET_ASSIGNMENT_EDIT_STATUS",
  SET_ADDRESS: "SET_ASSIGNMENT_ADDRESS",
  SET_PUBLISH_STATUS: "SET_PUBLISH_STATUS",
  SET_CHANGE_AVAILABILITY_STATUS: "SET_CHANGE_AVAILABILITY_STATUS",
  TOGGLE_STATUS_MODAL: "TOGGLE_STATUS_MODAL",
  SET_STATUS_STATE: "SET_STATUS_STATE",
  SET_SAVE_ASSIGNMENTS_STATUS: "SET_SAVE_ASSIGNMENTS_STATUS",
  SET_ADDRESS_SEARCH_STATUS: "SET_ADDRESS_SEARCH_STATUS",
  TOGGLE_WITHDRAW_MODAL: "TOGGLE_WITHDRAW_MODAL",
  SET_WITHDRAW_STATE: "SET_WITHDRAW_STATE",
  TOGGLE_SAVE_MODAL: "TOGGLE_ASSIGNMENT_SAVE_MODAL",
  SET_TIMELINE_EVENTS: "SET_TIMELINE_EVENTS",
  APPEND_TIMELINE_EVENTS: "APPEND_TIMELINE_EVENTS",
  SET_TIMELINE_STATUS: "SET_ASSIGNMENT_TIMELINE_STATUS",
  SET_MEDIAPARTNER_STATE: "SET_MEDIAPARTNER_STATE",
  SET_CADASTRE_STATE: "SET_CADASTRE_STATE",
  REMOVE_COMPANY_LISTING: "REMOVE_COMPANY_LISTING",
  SET_BROCHURE_STATUS: "ASSIGNMENT_SET_BROCHURE_STATUS",
  SET_BROCHURE_LINK: "ASSIGNMENT_SET_BROCHURE_LINK",
  SET_WIDGET_LAST_ASSIGNMENTS: "SET_WIDGET_LAST_ASSIGNMENTS",
  SET_WIDGET_LAST_ASSIGNMENTS_STATUS: "SET_WIDGET_LAST_ASSIGNMENTS_STATUS",
  SET_LAST_MARKETING_ROUTE: "SET_LAST_MARKETING_ROUTE",
  SET_PRINT_TEMPLATES: "ASSIGNMENT_SET_PRINT_TEMPLATES",
  SET_PRINT_EXPORT_OPTIONS: "ASSIGNMENT_SET_PRINT_EXPORT_OPTIONS",
  UPDATE_SINGLE_LIST_ASSIGNMENT: "ASSIGNMENT_UPDATE_SINGLE_LIST_ASSIGNMENT",
  DELETE_LIST_ITEM: "ASSIGNMENT_DELETE_LIST_ITEM",
};
