import * as React from "react";
import {
  InitializedWidget,
  WidgetEntityType,
  WidgetSnapShot,
} from "@haywork/api/authorization";
import * as CSSModules from "react-css-modules";
import { COMMON } from "@haywork/constants";

const styles = require("./style.scss");

interface Props {
  widget: WidgetSnapShot;
  getInitializedWidgets: (
    ids: string[],
    type: WidgetEntityType,
    cancelStoreUpdate?: boolean
  ) => Promise<InitializedWidget[]>;
}
interface State {
  initializedWidgets: InitializedWidget[];
}

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsDashboardComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedWidgets: [],
    };
  }

  public async componentDidMount() {
    const initializedWidgets = await this.props.getInitializedWidgets(
      [COMMON.EMPTY_GUID],
      WidgetEntityType.Dashboard
    );

    this.setState({ initializedWidgets });
  }

  public render() {
    const { widget } = this.props;
    const { displayName, height, id } = widget;
    const initializedWidget = (this.state.initializedWidgets || []).find(
      (widget) => widget.id === id
    );

    return (
      <div className="widget-wrapper">
        <div styleName="widget">
          <div styleName="widget__header">
            <h2>{displayName}</h2>
          </div>
          <div style={{ minHeight: height }} styleName="widget__content">
            {!!initializedWidget && (
              <iframe
                style={{ height, display: "block", margin: 0 }}
                frameBorder={0}
                src={initializedWidget.signedCallbackUri}
                // sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
