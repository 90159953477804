import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

const styles = require("./finance-form.component.scss");

interface Props {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
}

@CSSModules(styles, { allowMultiple: true })
export class FinanceFormComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { projectAssignment } = this.props;

    this.formControls = {
      salePriceMin: { value: value(projectAssignment, "saleOffer.salePrice") },
      salePriceMax: {
        value: value(projectAssignment, "saleOffer.salePriceMax")
      },
      rentPriceMin: { value: value(projectAssignment, "rentOffer.rentPrice") },
      rentPriceMax: {
        value: value(projectAssignment, "rentOffer.rentPriceMax")
      }
    };

    this.onFinanceChangeHandler = this.onFinanceChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;

      this.formRef.update({
        salePriceMin: value(projectAssignment, "saleOffer.salePrice"),
        salePriceMax: value(projectAssignment, "saleOffer.salePriceMax"),
        rentPriceMin: value(projectAssignment, "rentOffer.rentPrice"),
        rentPriceMax: value(projectAssignment, "rentOffer.rentPriceMax")
      }, true);
    }
  }

  public render() {
    const { forSale } = this.props.projectAssignment;

    return (
      <div styleName="finance-form">
        <Form
          name="finance-form"
          onChange={this.onFinanceChangeHandler}
          formControls={this.formControls}
          form={(form) => (this.formRef = form)}
        >
          {!!forSale && this.renderPriceRangeFormRow("salePrice")}
          {!forSale && this.renderPriceRangeFormRow("rentPrice")}
        </Form>
      </div>
    );
  }

  private onFinanceChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id
    });

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      saleOffer: {
        salePrice: values.salePriceMin,
        salePriceMax: values.salePriceMax
      },
      rentOffer: {
        rentPrice: values.rentPriceMin,
        rentPriceMax: values.rentPriceMax
      }
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, pathname);
  }

  private renderPriceRangeFormRow(name: string) {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column" styleName="price-label">
            <label htmlFor={`${name}Min`} className="pre">
              <ResourceText resourceKey={`projectTypesLabel.${name}`} />
            </label>
          </div>
          <div className="column" styleName="price-input">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name={`${name}Min`}
                pretty
                min={0}
                max={999999999}
                data-cy="CY-minPriceProject"
              />
            </div>
          </div>
          <div className="column__textspacer">
            <ResourceText resourceKey="untilAnd" />
          </div>
          <div className="column" styleName="price-input">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name={`${name}Max`}
                pretty
                min={0}
                max={999999999}
                data-cy="CY-maxPriceProject"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
