import {
  ImagePlaceholder,
  TextPlaceholder,
} from "@haywork/components/ui/empty-state";
import { Column, Row } from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo } from "react";

type Props = {
  zebra: boolean;
};

export const LoadingStateComponent: FC<Props> = memo(({ zebra }) => {
  return (
    <Row zebra={zebra}>
      <Column index={0}></Column>
      <Column index={1}>
        <ImagePlaceholder
          width={135}
          height={85}
          style={{
            padding: 8,
          }}
        />
      </Column>
      <Column index={2}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={3}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={4}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={5}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={6}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={7}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={8}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={9}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
      <Column index={10}>
        <TextPlaceholder
          numberOfLines={4}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%",
          }}
        />
      </Column>
    </Row>
  );
});
