import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { FormattedDate, FormattedNumber } from "react-intl";

import { ProductOrder } from "@haywork/api/kolibri";
import { ExtendedProductOrder } from "@haywork/modules/invoice";

const styles = require("./marketing-budget-modal.component.scss");

interface ProductOrderComponentProps {
  productOrder: ExtendedProductOrder;
  currency: string;
  isChecked: (checked: boolean, id: string) => void;
}

interface ProductOrderComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ProductOrderComponent extends React.Component<
  ProductOrderComponentProps,
  ProductOrderComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const checkedStyle = this.props.productOrder.checked
      ? "fal fa-fw fa-check-square"
      : "fal fa-fw fa-square";

    return (
      <div styleName="item">
        <div styleName="option check">
          <i
            className={checkedStyle}
            onClick={this.onClickHandler}
            id={this.props.productOrder.displayName}
          />
        </div>
        <div styleName="option date">
          <FormattedDate
            value={this.props.productOrder.productOrder.dateTime}
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        </div>
        <div styleName="option product">
          {this.props.productOrder.displayName}
        </div>
        <div styleName="option description">
          {this.props.productOrder.productOrder.description}
        </div>
        <div styleName="option amount">
          {this.props.currency ? this.props.currency + " " : "€ "}
          <FormattedNumber
            value={this.props.productOrder.productOrder.unitPriceNet}
            style="decimal"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        </div>
      </div>
    );
  }

  private onClickHandler() {
    this.props.isChecked(
      !this.props.productOrder.checked,
      this.props.productOrder.productOrder.id
    );
  }
}
