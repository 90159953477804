import { OFFICESROUTES } from "@haywork/constants";
import {
  OfficeAssignmentContainer,
  OfficeEditContainer,
  OfficeEmployeeContainer,
  OfficeGeneralContainer
} from "@haywork/modules/settings";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dossier from "../components/detail-dossier";

const route = RouteUtil.mapStaticRouteValues;
export const OfficeDetailRouting = (id) => {
  return (
    <Switch>
      <Route
        exact
        path={"/app/offices/office/:id"}
        render={() => (
          <Redirect to={route(OFFICESROUTES.OFFICE_GENERAL.URI, id)} />
        )}
      />
      <Route
        path={OFFICESROUTES.OFFICE_GENERAL.URI}
        component={OfficeGeneralContainer}
      />
      <Route
        path={OFFICESROUTES.OFFICE_ASSIGNMENT.URI}
        component={OfficeAssignmentContainer}
      />
      <Route
        path={OFFICESROUTES.OFFICE_LINKED_EMPLOYEES.URI}
        component={OfficeEmployeeContainer}
      />
      <Route
        path={OFFICESROUTES.EDIT_OFFICE.URI}
        component={OfficeEditContainer}
      />
      <Route path={OFFICESROUTES.OFFICE_DOSSIER.URI} component={Dossier} />
    </Switch>
  );
};
