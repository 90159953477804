import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { folders, accounts } from "../../selectors";
import {
  UpdateFolderComponent,
  UpdateFolderComponentProps,
} from "./update-folder";
import { MapDispatchToProps } from "@haywork/middleware";
import { Account } from "@haywork/api/mail";

type StateProps = {
  folders: EmailFolder[];
  accounts: Account[];
};
type DispatchProps = {
  saveFolder: (
    accountId: string,
    parentId: string,
    displayName: string,
    id: string
  ) => Promise<EmailFolder>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  UpdateFolderComponentProps,
  AppState
> = (state, ownProps) => {
  return {
    folders: folders(state),
    accounts: accounts(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  UpdateFolderComponentProps
> = (dispatch) => ({
  saveFolder: (
    accountId: string,
    parentId: string | null,
    displayName: string,
    id: string
  ) =>
    dispatch(
      EmailThunk.Folders.saveFolder(accountId, parentId, displayName, id)
    ),
});

export type UpdateFolderContainerProps = StateProps & DispatchProps;
export const UpdateFolderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateFolderComponent);
