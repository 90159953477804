import { Account, ReauthenticateAccountRequest } from "@haywork/api/mail";
import {
  Dispatch,
  EmailAccountThunks,
  EmailMessageThunks
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  EmailLayoutComponent,
  EmailLayoutComponentProps
} from "@haywork/modules/email";
import { AppState, EmailActions, PagedResults } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import { connect } from "react-redux";

interface StateProps {
  currentAccount: Account;
  currentFolder: ExtendedEmailFolder;
  currentMessage: EmailMessage;
  accounts: PagedResults<Account>;
  employeeName: string;
  hasAccount: boolean;
  accountAddedVisibility: boolean;
}
interface DispatchProps {
  deleteMessage: (accountId: string, message: EmailMessage) => void;
  moveToFolder: (
    accountId: string,
    message: EmailMessage,
    folder: ExtendedEmailFolder
  ) => void;
  reAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
  setAccountAddedVisibility: (visible: boolean) => void;
  createNewEmail: () => void;
}

const mapStateToProps = <StateProps, EmailLayoutComponentProps>(
  state: AppState
) => {
  const { currentAccount, accounts } = state.email.accounts;
  const { firstName: employeeName } = state.account.employee;
  const { accountAddedVisibility } = state.email.settings;
  const { currentFolder } = state.email.folders;
  const { currentMessage } = state.email.messages;

  return {
    currentAccount,
    currentFolder,
    currentMessage,
    accounts,
    employeeName,
    hasAccount: false,
    accountAddedVisibility
  };
};

const mapDispatchToProps = <DispatchProps, EmailLayoutComponentProps>(
  dispatch: Dispatch<any>
) => ({
  deleteMessage: (accountId: string, message: EmailMessage) =>
    dispatch(EmailMessageThunks.deleteMessage(accountId, message)),
  moveToFolder: (
    accountId: string,
    message: EmailMessage,
    folder: ExtendedEmailFolder
  ) => dispatch(EmailMessageThunks.moveToFolder(accountId, message, folder)),
  reAuthenticate: (request: ReauthenticateAccountRequest) =>
    dispatch(EmailAccountThunks.reAuthenticate(request)),
  setAccountAddedVisibility: (visible: boolean) =>
    dispatch(EmailActions.Settings.setAccountAddedVisibility(visible)),
  createNewEmail: () => dispatch(EmailThunk.Main.createNewEmail())
});

export type EmailLayoutContainerProps = StateProps & DispatchProps;
export const EmailLayoutContainer = connect<
  StateProps,
  DispatchProps,
  EmailLayoutComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailLayoutComponent);
