import { REQUEST } from "@haywork/constants";
import { ActionTypes, ActionType } from "./actions";
import { ReadRealEstatePropertyResponse } from "@haywork/api/mls";

export type State = {
  realEstateResponse: ReadRealEstatePropertyResponse | null;
  status: string;
};

const INITIAL: State = {
  realEstateResponse: null,
  status: REQUEST.IDLE,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetSingle: {
      const { realEstateResponse } = action;

      return {
        ...state,
        realEstateResponse,
        status: REQUEST.SUCCESS,
      };
    }
    case ActionType.SetSingleStatus: {
      const { status } = action;

      return {
        ...state,
        status,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
