import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { RelationType } from "@haywork/api/kolibri";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  RelationDossierComponent,
  RelationDossierComponentProps
} from "@haywork/modules/relation";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  parentId: string;
  dossierTitle: string;
  selectedRelationType: RelationType;
  linkedFolderTreeId: string | null | undefined;
}
interface DispatchProps {
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  RelationDossierComponentProps,
  AppState
> = (state) => {
  const {
    selectedRelationId,
    selectedRelationDisplayName,
    selectedRelationType,
    selectedContactPerson,
    selectedContactCompany
  } = state.relation.detail;
  const { linkedFolderTree } =
    selectedRelationType === RelationType.ContactPerson
      ? selectedContactPerson
      : selectedContactCompany;

  return {
    parentId: selectedRelationId,
    dossierTitle: selectedRelationDisplayName,
    selectedRelationType,
    linkedFolderTreeId: get(linkedFolderTree, "id")
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  RelationDossierComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type RelationDossierContainerProps = StateProps & DispatchProps;
export const RelationDossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationDossierComponent);
