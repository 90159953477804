import {
  BusinessPartnerSnapShot,
  OfferType,
  ProjectAssignment,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ProjectEditClientContainerProps } from "@haywork/modules/project";
import { ResourceText } from "@haywork/modules/shared";
import { Relation } from "@haywork/request";
import { FormControlUtil, StringUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./edit-client.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface ProjectEditClientComponentProps {}
interface State {
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = ProjectEditClientComponentProps & ProjectEditClientContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditClientComponent extends React.Component<Props, State> {
  private readonly formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      newEntityVisible: false,
      newEntityOptions: null,
    };

    const { projectAssignment } = this.props;
    const { forSale, forRent } = projectAssignment;

    const RentSaleValue: OfferType =
      forSale && forRent
        ? OfferType.MixingForm
        : forSale
        ? OfferType.Sale
        : OfferType.Rent;

    this.formControls = {
      linkedVendors: { value: value(projectAssignment, "linkedVendors", []) },
      linkedContactPersons: {
        value: value(projectAssignment, "linkedContactPersons", []),
      },
      linkedEmployees: {
        value: value(projectAssignment, "linkedEmployees"),
        validators: [Validators.required()],
      },
      linkedOffice: { value: value(projectAssignment, "linkedOffice") },
      businessPartners: {
        value: value(projectAssignment, "businessPartners", []),
      },
      offerType: {
        value: RentSaleValue,
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;
      const { forSale, forRent } = projectAssignment;

      const RentSaleValue: OfferType =
        forSale && forRent
          ? OfferType.MixingForm
          : forSale
          ? OfferType.Sale
          : OfferType.Rent;

      this.formRef.update(
        {
          linkedVendors: value(projectAssignment, "linkedVendors", []),
          linkedContactPersons: value(
            projectAssignment,
            "linkedContactPersons",
            []
          ),
          linkedEmployees: value(projectAssignment, "linkedEmployees"),
          linkedOffice: value(projectAssignment, "linkedOffice"),
          businessPartners: value(projectAssignment, "businessPartners", []),
          offerType: RentSaleValue,
        },
        true
      );
    }
  }

  public render() {
    const { offices } = this.props;

    return (
      <div styleName="client">
        <div className="container-fluid">
          <Form
            name="client"
            onChange={this.onChangeHandler}
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
          >
            {/* Client */}
            <div className="form__row">
              <label htmlFor="subject">
                <ResourceText resourceKey="assignmentWhoIsTheClient" />
              </label>
              <div className="input__wrapper">
                <Input.RelationQuery
                  name="linkedVendors"
                  data-cy="CY-linkedVendorsInput"
                  filterByRelationTypes={[
                    RelationType.ContactPerson,
                    RelationType.ContactCompany,
                  ]}
                  multiple
                  onAdd={this.addNewRelation}
                  onNavigateToRelation={this.props.navigate}
                />
              </div>
            </div>

            {/* Employee */}
            <div className="form__row">
              <label htmlFor="linkedEmployee">
                <ResourceText resourceKey="whosAssignment" />
              </label>

              <div className="input__wrapper">
                <Input.RelationQuery
                  name="linkedEmployees"
                  filterByRelationTypes={[RelationType.Employee]}
                  multiple
                  onNavigateToRelation={this.props.navigate}
                />
              </div>
            </div>

            {/* Agency */}
            <div className="form__row" data-cy="CY-linkedOffice">
              <label htmlFor="linkedOffice">
                <ResourceText resourceKey="whichAgency" />
              </label>
              <Input.NewSelect
                name="linkedOffice"
                values={offices}
                displayProp="displayName"
              />
            </div>

            {/* Co-signers */}
            <div className="form__row">
              <label htmlFor="">
                <ResourceText resourceKey="businessPartners" />
              </label>
              <div className="input__wrapper">
                <Input.Query
                  name="businessPartners"
                  asyncValues={(term) => Relation.searchBusinessPartners(term)}
                  multiple
                  data-cy="CY-businessPartners"
                  optionValue={this.renderBusinessPartnerOption}
                  selectedValue={this.renderSelectedBusinessPartner}
                />
              </div>
            </div>

            {/* Type */}
            <div styleName="editRealEstateGroup" className="form__row">
              <label htmlFor="realEstateGroup">
                <ResourceText resourceKey="whatKindOfProject" />
              </label>
              <div className="form__group">
                <div className="column">
                  <Input.RadioGroup
                    name="offerType"
                    asButtonList
                    disabled={!this.props.projectAssignment.isNew}
                  >
                    <Input.Radio
                      label={"sale"}
                      value={OfferType.Sale}
                      data-cy="CY-offerSaleButton"
                    />
                    <Input.Radio
                      label={"rent"}
                      value={OfferType.Rent}
                      data-cy="CY-offerRentButton"
                    />
                  </Input.RadioGroup>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ProjectEditClientComponentProps & ProjectEditClientContainerProps
  ) {
    const { projectAssignment: newObjectAssignment } = nextProps;
    const { projectAssignment: oldObjectAssignment } = this.props;

    if (
      has(newObjectAssignment, "linkedVendors") &&
      get(newObjectAssignment, "linkedVendors", []).length !==
        get(oldObjectAssignment, "linkedVendors", []).length &&
      !!this.formRef
    ) {
      const { linkedVendors } = newObjectAssignment;
      this.formRef.update({ linkedVendors });
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const { currentComponentState } = this.props;

    const projectAssignment: ProjectAssignment = {
      ...this.props.projectAssignment,
      forSale:
        values.offerType === OfferType.Sale ||
        values.offerType === OfferType.MixingForm,
      forRent:
        values.offerType === OfferType.Rent ||
        values.offerType === OfferType.MixingForm,
      businessPartners: values.businessPartners,
      linkedEmployees: values.linkedEmployees,
      linkedOffice: values.linkedOffice,
      linkedVendors: values.linkedVendors,
    };

    const newState = {
      ...currentComponentState,
      projectAssignment,
    };

    this.props.updateProject(newState, this.props.path);
  }

  private renderEmployeePlaceholder(): React.ReactElement<HTMLDivElement> {
    return (
      <div className="form__select-option">
        <div>
          <ResourceText resourceKey="chooseEmployee" />
        </div>
      </div>
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedVendors } = this.formRef.getValues();
    linkedVendors = linkedVendors || [];

    this.formRef.update({
      linkedVendors: [...linkedVendors, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private renderBusinessPartnerOption(
    value: BusinessPartnerSnapShot,
    query: string
  ): QueryOptionReturnValue {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return (
      <div dangerouslySetInnerHTML={StringUtil.highlight(displayName, query)} />
    );
  }

  private renderSelectedBusinessPartner(
    value: BusinessPartnerSnapShot
  ): QueryResultReturnValue<any> {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return {
      value,
      template: <div>{displayName}</div>,
    };
  }
}
