import {
  AcquisitionAssignmentsClient,
  AcquisitionObjectAssignmentsClient,
  AssignmentType,
  BlobsClient,
  ContactCompaniesClient,
  ContactPersonsClient,
  DocumentSessionsClient,
  DossierItemsClient,
  DossierItemSnapShot,
  EmployeesClient,
  FolderTree,
  FolderTreeFileType,
  FolderTreesClient,
  GlobalSearchType,
  InvoicesClient,
  ObjectAssignmentsClient,
  ObjectTypeAssignmentsClient,
  OfficesClient,
  ProjectAssignmentsClient,
  RelationType,
  UploadResponse,
} from "@haywork/api/kolibri";
import { File } from "@haywork/api/mail";
import { ParseRequest } from "@haywork/services";
import { AppState, DossierActions } from "@haywork/stores";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { FileUtil } from "@haywork/util";
import { FolderTreeUtil } from "@haywork/util/folder-tree";
import get from "lodash-es/get";
import { Dispatch } from ".";
import { FolderTreeEntityType, FolderTreesThunk } from "./folder-trees";

const parseRequest = new ParseRequest();

const updateDossierItem = (dossierItem: DossierItemSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DossierClient = new DossierItemsClient(host);

    try {
      const item = await parseRequest.response(
        DossierClient.read(dossierItem.id, realEstateAgencyId).then(
          (response) => response.dossierItem
        )
      );

      await parseRequest.response(
        DossierClient.save(
          {
            dossierItem: {
              ...item,
              parentId: dossierItem.parentId,
              id: dossierItem.id,
              fileName: dossierItem.fileName,
              fileExtension: dossierItem.fileExtension,
              fileDataId: dossierItem.fileDataId,
              fileSize: dossierItem.fileSize,
              md5Hash: dossierItem.md5Hash,
              name: dossierItem.name,
              description: dossierItem.description,
            },
          },
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const uploadDossierItems = (items: any[], parentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state: AppState = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const DossierClient = new DossierItemsClient(host);

    try {
      const uploadedIds: string[] = [];
      for (const key in items) {
        const item = items[key];
        let dossierItem = await parseRequest.response(
          DossierClient.defineNew({ parentId }, realEstateAgencyId).then(
            (response) => response.dossierItem
          )
        );

        dossierItem = {
          ...dossierItem,
          fileDataId: item.fileDataId,
          fileExtension: item.fileExtension ? item.fileExtension : ".temp",
          fileName: item.fileName,
          fileSize: item.fileSize,
          md5Hash: item.md5Hash,
          name: item.name,
        };

        dossierItem = await parseRequest.response(
          DossierClient.save({ dossierItem }, realEstateAgencyId).then(
            (response) => response.dossierItem
          )
        );

        uploadedIds.push(dossierItem.id);
      }

      return uploadedIds;
    } catch (error) {
      throw error;
    }
  };
};

const deleteDossier = (
  id: string,
  parentId: string,
  undeleteCallback?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const DossierClient = new DossierItemsClient(host);

    try {
      await parseRequest.response(DossierClient.delete(id, realEstateAgencyId));

      if (!!undeleteCallback) {
        dispatch(
          SnackbarActions.addToast({
            value: "dossier.toast.deleted",
            callback: async () => {
              await dispatch(unDeleteDossier(id, parentId));
              undeleteCallback();
            },
            callbackLabel: "dossier.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteDossier = (id: string, parentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const DossierClient = new DossierItemsClient(host);

    try {
      await parseRequest.response(
        DossierClient.undelete({ id, parentId }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const readFolderTree = (
  parentId: string,
  type: GlobalSearchType,
  typeOfRelation?: RelationType,
  typeOfAssignment?: AssignmentType,
  createIfUndefined?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;

    const folderTreeClient = new FolderTreesClient(host);
    let entityType: FolderTreeEntityType;

    try {
      let id: string;
      switch (type) {
        case GlobalSearchType.Assignments: {
          if (!typeOfAssignment) return null;
          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              const client = new AcquisitionAssignmentsClient(host);
              const assignment = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.acquisitionAssignment)
              );
              id = get(assignment, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.AcquisitionAssignment;
              break;
            }
            case AssignmentType.AcquisitionObject: {
              const client = new AcquisitionObjectAssignmentsClient(host);
              const assignment = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.acquisitionObjectAssignment)
              );
              id = get(assignment, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.AcquisitionObjectAssignment;
              break;
            }
            case AssignmentType.Object: {
              const client = new ObjectAssignmentsClient(host);
              const assignment = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.objectAssignment)
              );
              id = get(assignment, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.ObjectAssignment;
              break;
            }
            case AssignmentType.ObjectType: {
              const client = new ObjectTypeAssignmentsClient(host);
              const assignment = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.objectTypeAssignment)
              );
              id = get(assignment, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.ObjectTypeAssignment;
              break;
            }
            case AssignmentType.Project: {
              const client = new ProjectAssignmentsClient(host);
              const assignment = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.projectAssignment)
              );
              id = get(assignment, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.ProjectAssignment;
              break;
            }
            default:
              return null;
          }
          break;
        }
        case GlobalSearchType.Relations: {
          if (!typeOfRelation) return null;
          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              const client = new ContactCompaniesClient(host);
              const relation = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.contactCompany)
              );
              id = get(relation, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.ContactCompany;
              break;
            }
            case RelationType.ContactPerson: {
              const client = new ContactPersonsClient(host);
              const relation = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.contactPerson)
              );
              id = get(relation, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.ContactPerson;
              break;
            }
            case RelationType.Employee: {
              const client = new EmployeesClient(host);
              const relation = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.employee)
              );
              id = get(relation, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.Employee;
              break;
            }
            case RelationType.Office: {
              const client = new OfficesClient(host);
              const relation = await parseRequest.response(
                client
                  .read(parentId, realEstateAgencyId)
                  .then((response) => response.office)
              );
              id = get(relation, "linkedFolderTree.id");
              entityType = FolderTreeEntityType.Office;
              break;
            }
            default: {
              return null;
            }
          }
          break;
        }
        default: {
          return null;
        }
      }

      let folderTree: FolderTree;
      if (!id) {
        if (!!createIfUndefined) {
          folderTree = await parseRequest.response(
            folderTreeClient
              .defineNew({ parentId }, realEstateAgencyId)
              .then((response) => response.folderTree)
          );
          folderTree = await parseRequest.response(
            folderTreeClient
              .save({ folderTree }, realEstateAgencyId)
              .then((response) => response.folderTree)
          );

          if (!!entityType) {
            dispatch(
              DossierActions.setEntityFolderTreeId(
                folderTree.id,
                entityType,
                parentId
              )
            );
          }
        } else {
          return null;
        }
      } else {
        folderTree = await parseRequest.response(
          folderTreeClient
            .read(culture, id, realEstateAgencyId)
            .then((response) => response.folderTree)
        );
      }

      folderTree = {
        ...folderTree,
        rootFolderTree: FolderTreeUtil.sortRootFiles(folderTree.rootFolderTree),
      };

      return folderTree;
    } catch (error) {
      throw error;
    }
  };
};

export type DeleteDossierItem = {
  type: FolderTreeFileType;
  id: string;
  parentId?: string;
};
const bulkDeleteDossierItems = (
  items: DeleteDossierItem[],
  undeleteCallback?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const documentSessionsClient = new DocumentSessionsClient(host);
    const dossierItemsClient = new DossierItemsClient(host);
    const invoicesClient = new InvoicesClient(host);

    try {
      const promises = items.map((item) => {
        switch (item.type) {
          case FolderTreeFileType.DocumentSession: {
            return parseRequest.response(
              documentSessionsClient.delete(item.id, realEstateAgencyId)
            );
          }
          case FolderTreeFileType.DossierItem: {
            return parseRequest.response(
              dossierItemsClient.delete(item.id, realEstateAgencyId)
            );
          }
          case FolderTreeFileType.Invoice: {
            return parseRequest.response(
              invoicesClient.delete(item.id, realEstateAgencyId)
            );
          }
          default:
            return parseRequest.response(
              new Promise<void>((resolve) => resolve()).then((_) => {
                return;
              })
            );
        }
      });

      await Promise.all(promises);

      if (!!undeleteCallback) {
        dispatch(
          SnackbarActions.addToast({
            value: "bulk.toast.deleted",
            callback: async () => {
              await dispatch(bulkUnDeleteDossierItems(items));
              undeleteCallback();
            },
            callbackLabel: "bulk.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const bulkUnDeleteDossierItems = (items: DeleteDossierItem[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const documentSessionsClient = new DocumentSessionsClient(host);
    const dossierItemsClient = new DossierItemsClient(host);
    const invoicesClient = new InvoicesClient(host);

    try {
      const promises = items.map((item) => {
        switch (item.type) {
          case FolderTreeFileType.DocumentSession: {
            return parseRequest.response(
              documentSessionsClient.undelete(
                { id: item.id },
                realEstateAgencyId
              )
            );
          }
          case FolderTreeFileType.DossierItem: {
            return parseRequest.response(
              dossierItemsClient
                .undelete(
                  { id: item.id, parentId: item.parentId },
                  realEstateAgencyId
                )
                .then((_) => {
                  return;
                })
            );
          }
          case FolderTreeFileType.Invoice: {
            return parseRequest.response(
              invoicesClient.undelete({ id: item.id }, realEstateAgencyId)
            );
          }
          default:
            return parseRequest.response(
              new Promise<void>((resolve) => resolve()).then((_) => {
                return;
              })
            );
        }
      });

      await Promise.all(promises);
    } catch (error) {
      throw error;
    }
  };
};

const saveUrisToDossier = (
  files: File[],
  folderTree: FolderTree,
  path: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const blobsClient = new BlobsClient(host);
    const { parentId } = folderTree;

    try {
      const uploadedBlobs: UploadResponse[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.fileName
          ? file.fileName.replace(/\.[0-9a-z]+$/i, "")
          : file.id;
        const fileExtension =
          file.fileName && file.fileName.match(/\.[0-9a-z]+$/i)[0]
            ? file.fileName.match(/\.[0-9a-z]+$/i)[0]
            : ".temp";
        const blob = await parseRequest.response(
          blobsClient.uploadFromUri(
            {
              uri: file.uri,
              isPrivate: true,
              fileName: FileUtil.sanatizeFilename(fileName),
              fileExtension,
            },
            realEstateAgencyId
          )
        );
        const blobExtend = {
          ...blob,
          name: fileName,
        };
        uploadedBlobs.push(blobExtend);
      }

      const dossierItemIds = await dispatch(
        uploadDossierItems(uploadedBlobs, parentId)
      );

      folderTree = FolderTreeUtil.placeUploadsInFolderTree(
        folderTree,
        path,
        dossierItemIds
      );

      await dispatch(FolderTreesThunk.updateFolderTree(folderTree));
    } catch (error) {
      throw error;
    }
  };
};

export const DossierThunks = {
  updateDossierItem,
  uploadDossierItems,
  deleteDossier,
  readFolderTree,
  bulkDeleteDossierItems,
  saveUrisToDossier,
};
