import * as React from "react";
import { FC, memo, ReactElement } from "react";
import { Ui } from "@haywork/modules/ui";

type Props = {
  loading: boolean;
  itemCount: number;
  emptyState: ReactElement;
};

const InnerElementComponent: FC<Props> = memo(
  ({ children, loading, itemCount, emptyState, ...props }) => {
    return (
      <div {...props}>
        {!!loading && <Ui.Loaders.List />}
        {!loading && !itemCount && emptyState}
        {!loading && !!itemCount && children}
      </div>
    );
  }
);

export default InnerElementComponent;
