import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import { FC, memo, useState } from "react";
import * as CSSModules from "react-css-modules";
import Logo from "./components/logo";
import { SettingsDocumentsContainerProps } from "./documents.container";

const styles = require("./style.scss");

export interface SettingsDocumentsComponentProps {}
type Props = SettingsDocumentsComponentProps & SettingsDocumentsContainerProps;

export const SettingsDocumentsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ companyLogo, setDocumentsLogo }) => {
      const [blob, setBlob] = useState<File | null>(null);
      const [logo, setLogo] = useState(companyLogo?.largeImageUrl || null);
      const [loading, setLoading] = useState(false);

      const createPreview = (file: File | null) => {
        if (!file) {
          setLogo(null);
          return;
        }

        const reader = new FileReader();
        const maxHeight = 500;
        const maxWidth = 500;

        reader.onloadend = () => {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d"),
            img = new Image();

          img.onload = () => {
            const { width, height } = img;
            const childRatio = height / width;
            let newHeight = maxHeight,
              newWidth = maxWidth;

            if (childRatio > 1) {
              newWidth = Math.floor(newHeight / childRatio);
            } else {
              newHeight = Math.floor(newWidth * childRatio);
            }

            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const logo = canvas.toDataURL() || null;
            setLogo(logo);
          };

          img.src = reader.result as string;
        };

        reader.readAsDataURL(file);
      };

      const updateLogo = (logo: File | null) => {
        setBlob(logo);
        createPreview(logo);
      };

      const onSave = async () => {
        try {
          if (loading || companyLogo?.largeImageUrl === logo) return;
          setLoading(true);
          await setDocumentsLogo(blob);
        } finally {
          setLoading(false);
        }
      };

      return (
        <div styleName="documents">
          <PageHeader
            title="settings.documents.title"
            subTitle="settings.documents.subTitle"
            actions={
              <Button label="save" disabled={loading} onClick={onSave} />
            }
          />
          <div styleName="documents__body">
            {loading && <Ui.Loaders.Fullscreen mask />}
            <h1>
              <I18n value="settings.documents.logo.title" />
            </h1>
            <I18n value="settings.documents.logo.body" asHtml />
            <Logo logo={logo} onChange={updateLogo} />
          </div>
        </div>
      );
    }
  )
);
