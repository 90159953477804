import * as React from "react";
import { FC, useMemo, memo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import Icon from "@haywork/components/ui/icon";

const styles = require("./about.component.scss");

type Props = {
  url: string;
  onClick: () => void;
};

const ScreenshotComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ onClick, url }) => {
    const isVideoUrl = useMemo(() => {
      return /(youtube.com|youtu.be)/gi.test(url);
    }, [url]);

    const thumbnailStyles = useMemo(() => {
      if (!url) return;

      let thumbnail = url;
      if (isVideoUrl) {
        const results = thumbnail.match("embed/([^&#]*)");
        if (!!results && results.length) {
          thumbnail = `https://img.youtube.com/vi/${results[1]}/0.jpg`;
        }
      }

      return {
        backgroundImage: `url(${thumbnail})`
      } as CSSProperties;
    }, [url, isVideoUrl]);

    return (
      <div styleName="screenshot" style={thumbnailStyles} onClick={onClick}>
        {isVideoUrl && (
          <div styleName="play">
            <div styleName="play__trigger">
              <Icon name="play" size={24} />
            </div>
          </div>
        )}
      </div>
    );
  })
);

export default ScreenshotComponent;
