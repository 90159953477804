import { FinancialAdministrationTaxRate } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./tax-rates.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface TaxRateComponentProps {
  taxRate: FinancialAdministrationTaxRate;
  onRemove: (id: number) => void;
  onChange: (values: FormReturnValue) => void;
}
interface TaxRateComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class TaxRateComponent extends React.Component<
  TaxRateComponentProps,
  TaxRateComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);

    this.formControls = {
      id: { value: value(this.props.taxRate, "id") },
      description: { value: value(this.props.taxRate, "description") },
      taxPercentage: { value: value(this.props.taxRate, "taxPercentage", 0) },
      isActive: { value: value(this.props.taxRate, "isActive", false) },
      exportTaxCode: { value: value(this.props.taxRate, "exportTaxCode") },
      exportLedgerAccountCode: {
        value: value(this.props.taxRate, "exportLedgerAccountCode"),
      },
    };
  }

  public render() {
    return (
      <Form
        name={`tax-rate-${this.props.taxRate.id}`}
        formControls={this.formControls}
        onChange={this.props.onChange}
      >
        <div styleName="tax-rate">
          <div styleName="column">
            <Input.Text
              name="description"
              maxLength={128}
              placeholder="taxRateDescriptionPlaceholder"
            />
          </div>
          <div styleName="column">
            <Input.Number name="taxPercentage" max={99} />
          </div>
          <div styleName="column centered">
            <Input.CheckBox name="isActive" />
          </div>
          <div styleName="column">
            <Input.Text name="exportTaxCode" maxLength={64} />
          </div>
          <div styleName="column">
            <Input.Text
              name="exportLedgerAccountCode"
              maxLength={64}
              placeholder="exportLedgerAccountCodePlaceholder"
            />
          </div>
          <div styleName="column remove">
            <div styleName="trigger" onClick={this.onRemoveClickHandler}>
              <i className="fal fa-minus-circle" />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onRemoveClickHandler() {
    const { id } = this.props.taxRate;
    this.props.onRemove(id);
  }
}
