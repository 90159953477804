import {
  Bid,
  BidOrderByField,
  BidSnapShot,
  LinkedRelation
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import FormattedText from "@haywork/components/ui/formatted-text";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import {
  FC,
  memo,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import * as CSSModules from "react-css-modules";
import SingleBid, { BidAction } from "../bid";
import { BidsWidgetContainerProps } from "./bids-widget.container";

const styles = require("./style.scss");

export type BidsWidgetRef = {
  refresh: () => Promise<void>;
};
export type BidsWidgetComponentProps = {
  assignmentId: string;
  myForwardedRef?: Ref<BidsWidgetRef>;
  onAction: (
    action: BidAction,
    bid: Bid,
    linkedRelations: LinkedRelation[]
  ) => void;
};
type Props = BidsWidgetComponentProps & BidsWidgetContainerProps;

export const BidsWidgetComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      getBids,
      assignmentId,
      navigate,
      readBid,
      myForwardedRef,
      onAction,
      deleteBid
    }) => {
      const [bids, setBids] = useState<BidSnapShot[]>([]);
      const [orderBy, setOrderBy] = useState(BidOrderByField.BidDateTime);
      const [total, setTotal] = useState(0);
      const [_, setLoading] = useState(false);

      const fetchBids = useCallback(async () => {
        try {
          setLoading(true);
          const { results, total } = await getBids(assignmentId, orderBy, 3);
          setBids(results);
          setTotal(total);
        } finally {
          setLoading(false);
        }
      }, [assignmentId, orderBy, setLoading, setBids, setTotal]);

      useEffect(() => {
        myForwardedRef["current"] = { refresh: fetchBids };
      }, [fetchBids]);

      const amountFilter = useMemo(() => {
        const active = orderBy === BidOrderByField.Amount;
        return (
          <Pill
            label="bids.widget.sortBy.amount"
            color={active ? Colors.Primary : Colors.Gray}
            solid={active}
            onClick={() => setOrderBy(BidOrderByField.Amount)}
          />
        );
      }, [orderBy]);
      const bidDateTimeFilter = useMemo(() => {
        const active = orderBy === BidOrderByField.BidDateTime;
        return (
          <Pill
            label="bids.widget.sortBy.bidDateTime"
            color={active ? Colors.Primary : Colors.Gray}
            solid={active}
            onClick={() => setOrderBy(BidOrderByField.BidDateTime)}
          />
        );
      }, [orderBy]);

      const onActionHandler = useCallback(
        async (id: string, action: BidAction) => {
          if (action === BidAction.Delete) {
            await deleteBid(id, fetchBids);
            fetchBids();
            return;
          }
          const bid = await readBid(id);

          let linkedRelations: LinkedRelation[] = [];
          if (!!bid.linkedBid) {
            const linkedBid = await readBid(bid.linkedBid.id);
            linkedRelations = linkedBid.linkedRelations;
          }

          onAction(action, bid, linkedRelations);
        },
        [readBid, onAction, deleteBid, bids, setBids]
      );

      useEffect(() => {
        fetchBids();
      }, [orderBy]);

      if (!bids.length) return null;

      return (
        <div styleName="bids-widget">
          <div styleName="bids-widget__header">
            <FormattedText
              value="bids.widget.title"
              type="subheader"
              bold
              chain
              asSection
              addStyle={{ width: "100%" }}
            />
            <div styleName="sorting">
              <span>
                <I18n value="bids.widget.label.show" />
              </span>
              {amountFilter}
              {bidDateTimeFilter}
            </div>
            {total > 3 && (
              <div styleName="show-all">
                <span
                  className="as-link"
                  onClick={() => onAction(BidAction.ShowAllBids, null, [])}
                >
                  <I18n value="bids.widget.action.showAll" />
                </span>
              </div>
            )}
          </div>
          <div>
            {bids.map((bid) => (
              <SingleBid
                bid={bid}
                key={bid.id}
                onNavigate={navigate}
                onAction={onActionHandler}
              />
            ))}
          </div>
        </div>
      );
    }
  )
);
