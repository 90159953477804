import { actions as DraftsActions } from "./actions";
import { reducer as draftsReducer, State } from "./reducer";
import { Draft } from "@haywork/api/mail";

export type EmailDraft = Draft & {
  accountId: string;
  folderId: string;
  searchString: string;
  temporaryId?: string;
};
export type DraftsState = State;
export { draftsReducer, DraftsActions };
