import {
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType
} from "@haywork/api/kolibri";
import {
  EmailDraftThunks,
  EventCenterThunk,
  MapDispatchToProps,
  RelationThunks
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  EventsModalComponent,
  EventsModalComponentProps
} from "@haywork/modules/event-center";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import {
  EventNotificationsFilter,
  ExtendedEventNotification
} from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { canLoadMore, notifications } from "./selectors";

interface StateProps {
  currentFilter: EventNotificationsFilter;
  notifications: ExtendedEventNotification[];
  numberOfNotArchivedEvents: number;
  numberOfGeneralUnprocessedNotifications: number;
  numberOfPersonalUnprocessedNotifications: number;
  notificationsStatus: string;
  canSendEmail: boolean;
  currentOfficeName: string;
  canLoadMore: boolean;
  features: string[];
}
interface DispatchProps {
  navigate: (path: string) => void;
  setNotificationsFilter: (filter: EventNotificationsFilter) => void;
  getNotifications: () => Promise<void>;
  archive: (message: ExtendedEventNotification) => Promise<void>;
  unArchive: (message: ExtendedEventNotification) => Promise<void>;
  createNewEmail: (
    emails: string[],
    linkedRelations?: LinkedRelation[],
    linkedAssigmnents?: LinkedAssignment[],
    subject?: string
  ) => void;
  toggleAllIsRead: () => Promise<void>;
  processAll: () => Promise<void>;
  readDraft: (id: string, accountId: string) => Promise<void>;
  fetchNotificationsIfNeeded: () => Promise<void>;
  getRelationsWithMatchingEmailAddress: (
    email: string
  ) => Promise<RelationSnapShot[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EventsModalComponentProps,
  AppState
> = (state) => {
  const {
    currentFilter,
    notificationsStatus,
    numberOfGeneralUnprocessedNotifications,
    numberOfPersonalUnprocessedNotifications
  } = state.eventCenter.notifications;
  const { name: currentOfficeName } = state.account.currentRealestateAgency;
  const { features } = state.appSettings;

  return {
    currentFilter,
    notifications: notifications(state),
    numberOfGeneralUnprocessedNotifications,
    numberOfPersonalUnprocessedNotifications,
    numberOfNotArchivedEvents:
      numberOfGeneralUnprocessedNotifications +
      numberOfPersonalUnprocessedNotifications,
    notificationsStatus,
    canSendEmail: canSendEmail(state),
    currentOfficeName,
    canLoadMore: canLoadMore(state),
    features
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EventsModalComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  setNotificationsFilter: (filter: EventNotificationsFilter) =>
    dispatch(EventCenterThunk.setNotificationsFilter(filter)),
  getNotifications: () => dispatch(EventCenterThunk.getNotifications()),
  archive: (message: ExtendedEventNotification) =>
    dispatch(EventCenterThunk.archive(message)),
  unArchive: (message: ExtendedEventNotification) =>
    dispatch(EventCenterThunk.unArchive(message)),
  createNewEmail: (
    emails: string[],
    linkedRelations?: LinkedRelation[],
    linkedAssigmnents?: LinkedAssignment[],
    subject?: string
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail(
        emails,
        linkedRelations,
        linkedAssigmnents,
        subject
      )
    ),
  toggleAllIsRead: () => dispatch(EventCenterThunk.toggleAllIsRead()),
  processAll: () => dispatch(EventCenterThunk.processAll()),
  readDraft: (id: string, accountId: string) =>
    dispatch(EmailDraftThunks.readDraft(id, accountId)),
  fetchNotificationsIfNeeded: () =>
    dispatch(EventCenterThunk.fetchNotificationsIfNeeded()),
  getRelationsWithMatchingEmailAddress: (email: string) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        [email],
        [RelationType.ContactPerson]
      )
    )
});

export type EventsModalContainerProps = StateProps & DispatchProps;
export const EventsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsModalComponent);
