import first from "lodash-es/first";
import { Dispatch } from ".";

import {
  ActiveFilter,
  Address,
  AgendaItemCategory,
  AgendaItemsClient,
  ContactCompaniesClient,
  ContactPersonsClient,
  EmailAddress,
  Gender,
  LinkedAssignment,
  LinkedEmployee,
  LinkedRelation,
  PhoneNumber,
  Priority,
  RelationGroupSnapShot,
  RelationOrderByField,
  RelationsClient,
  SortOrder,
  TasksClient,
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";

const parseRequest = new ParseRequest();

export interface NewContactPersonRequest {
  firstName: string;
  middleName: string;
  lastName: string;
  nameLetters?: string;
  emailAddresses: EmailAddress[];
  phoneNumbers: PhoneNumber[];
  title: string;
  gender: Gender;
  useFormalAppelation: boolean;
  salutation: string;
  address: Address;
  linkedRelationGroups: RelationGroupSnapShot[];
}
const newContactPerson = (request: NewContactPersonRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const Relations = new RelationsClient(host);

    try {
      let contactPerson = await parseRequest.response(
        ContactPersons.defineNew({}, realEstateAgencyId).then(
          (response) => response.contactPerson
        )
      );

      contactPerson = {
        ...contactPerson,
        ...request,
        nickname: request.firstName,
      };

      if (contactPerson.isNew) {
        const existing = await parseRequest.response(
          Relations.search(
            {
              orderBy: RelationOrderByField.DateTimeCreated,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              relationId: contactPerson.id,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          contactPerson = {
            ...contactPerson,
            isNew: false,
          };
        }
      }

      const person = await parseRequest.response(
        ContactPersons.save({ contactPerson }, realEstateAgencyId).then(
          (response) => response.contactPerson
        )
      );

      const relations = await parseRequest.response(
        Relations.search(
          {
            relationIds: [person.id],
            includeStatistics: false,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: 0,
            take: 1,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (relations.length > 0) {
        const relation = first(relations);
        return relation;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };
};

export interface NewContactCompanyRequest {
  displayName: string;
  webAddress: string;
  emailAddresses: EmailAddress[];
  phoneNumbers: PhoneNumber[];
  address: Address;
  linkedRelationGroups: RelationGroupSnapShot[];
}
const newContactCompany = (request: NewContactCompanyRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);
    const Relations = new RelationsClient(host);

    try {
      let contactCompany = await parseRequest.response(
        ContactCompanies.defineNew({}, realEstateAgencyId).then(
          (response) => response.contactCompany
        )
      );

      contactCompany = {
        ...contactCompany,
        ...request,
      };

      const company = await parseRequest.response(
        ContactCompanies.save({ contactCompany }, realEstateAgencyId).then(
          (response) => response.contactCompany
        )
      );

      const relations = await parseRequest.response(
        Relations.search(
          {
            relationIds: [company.id],
            includeStatistics: false,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: 0,
            take: 1,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (relations.length > 0) {
        const relation = first(relations);
        return relation;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };
};

export interface NewTaskRequest {
  subject: string;
  linkedEmployee: LinkedEmployee;
  endDate: Date;
  priority: Priority;
  linkedAssignments: LinkedAssignment[];
  linkedRelations: LinkedRelation[];
  categoryId: string;
  description: string;
}
const newTask = (request: NewTaskRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      let task = await parseRequest.response(
        Tasks.defineNew(
          { employeeId: request.linkedEmployee.id },
          realEstateAgencyId
        ).then((response) => response.task)
      );

      task = {
        ...task,
        ...request,
      };

      return await parseRequest.response(
        Tasks.save({ task }, realEstateAgencyId).then(
          (response) => response.task
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

export interface NewAppointmentRequest {
  allDayEvent: boolean;
  endDateTime: Date;
  linkedEmployees: LinkedEmployee[];
  startDateTime: Date;
  linkedAgendaItemCategory: AgendaItemCategory;
  linkedAssignments: LinkedAssignment[];
  location: string;
  linkedRelations: LinkedRelation[];
  subject: string;
  isPrivate: boolean;
  description: string;
}
const newAppointment = (request: NewAppointmentRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      const { endDateTime, linkedEmployees, startDateTime } = request;
      let agendaItem = await parseRequest.response(
        AgendaItems.defineNew(
          {
            endDateTime,
            linkedEmployeeIds: linkedEmployees.map((employee) => employee.id),
            startDateTime,
          },
          realEstateAgencyId
        ).then((response) => response.agendaItem)
      );

      agendaItem = {
        ...agendaItem,
        ...request,
      };

      return await parseRequest.response(
        AgendaItems.save({ agendaItem }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

export const NewEntityThunks = {
  newContactPerson,
  newContactCompany,
  newTask,
  newAppointment,
};
