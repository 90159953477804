import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { ResourceText, MarkDown } from "@haywork/modules/shared";
import { AppClientTranslation } from "@haywork/api/authorization";

const styles = require("./about.component.scss");

interface Props {
  translation: AppClientTranslation;
}

interface State {
  visible: boolean;
  showToggle: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class MoreInfo extends React.Component<Props, State> {
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      showToggle: false,
    };
  }

  public componentDidMount() {
    if (!!this.ref.current) {
      const { height } = this.ref.current.getBoundingClientRect();
      const showToggle = height > 256;
      this.setState({ showToggle, visible: !showToggle });
    }
  }

  public render() {
    const { generalDescription } = this.props.translation;
    if (!generalDescription) return null;

    return (
      <React.Fragment>
        <h2 styleName="section-title">
          <ResourceText resourceKey="appXchangeMoreInfo" />
        </h2>
        <div
          styleName={classNames("more-info", { visible: this.state.visible })}
          ref={this.ref}
        >
          <MarkDown markdown={generalDescription} />
          {this.state.showToggle && (
            <div
              styleName="more-info__toggle"
              onClick={() => this.setState({ visible: !this.state.visible })}
            >
              <ResourceText
                resourceKey={this.state.visible ? "showLess" : "showMore"}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
