import {
  ObjectAssignment,
  AcquisitionObjectAssignment,
  ListingType,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogOtherComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      vVO: { value: value(objectAssignment, "vVO.area") },
      inUnitsFrom: { value: value(objectAssignment, "inUnitsFrom.area") },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update(
        {
          vVO: value(objectAssignment, "vVO.area"),
          inUnitsFrom: value(objectAssignment, "inUnitsFrom.area"),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
          numberOfFloors: value(objectAssignment, "numberOfFloors", 0),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="other"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="surface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="inUnitsFrom">
                <ResourceText resourceKey="inUnitsFrom" />
              </label>
              <div className="input__helper">
                <Input.Number name="inUnitsFrom" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            {[
              ListingType.Office,
              ListingType.BusinessSpace,
              ListingType.Other,
              ListingType.RetailSpace,
            ].indexOf(this.props.objectAssignment.listingType) > -1 && (
              <>
                <div className="column" styleName="size-field">
                  <label htmlFor="numberOfFloors">
                    <ResourceText resourceKey="numberOfFloorsBog" />
                  </label>
                  <Input.Number name="numberOfFloors" />
                </div>
                <div className="column__spacer" />
              </>
            )}

            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*
      <div className="form__row" styleName="size-field">
        <label htmlFor="buildingLayerAmount">
          <ResourceText resourceKey="numberOfFloorsBog" />
        </label>
        <Input.Number name="buildingLayerAmount" showActions />
      </div>
      */}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const fields: any = {
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      inUnitsFrom: {
        ...objectAssignment.inUnitsFrom,
        area: values.inUnitsFrom,
      },
      numberOfFloors: values.numberOfFloors,
    };

    this.props.onChange(fields);
  }
}
