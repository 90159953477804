import * as ActionType from "./search-assignment.types";
import { REDUX } from "@haywork/constants";
import {
  SearchAssignment,
  MatchSearchPropertiesResponse,
} from "@haywork/api/kolibri";

const setSearchAssignmentsStatus = (searchAssignmentsStatus: string) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS_STATUS,
  searchAssignmentsStatus,
});

const setSearchAssignments = (
  payload: ActionType.SearchAssignmentsResponse,
  take: number
) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS,
  ...payload,
  take,
});

const appendSearchAssignments = (
  payload: ActionType.SearchAssignmentsResponse,
  take: number
) => ({
  type: REDUX.SEARCH_ASSIGNMENT.APPEND_SEARCH_ASSIGNMENTS,
  ...payload,
  take,
});

const setSearchAssignmentsFilters = (payload: ActionType.Filters) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS_FILTERS,
  ...payload,
});

const clearAllFilters = () => ({
  type: REDUX.SEARCH_ASSIGNMENT.CLEAR_ALL_SEARCHASSIGNMENTS_FILTERS,
});

const clearFilter = (payload: ActionType.Filter) => ({
  type: REDUX.SEARCH_ASSIGNMENT.CLEAR_SEARCH_ASSIGNMENTS_FILTER,
  ...payload,
});

const setSearchAssignment = (payload: ActionType.SingleSearchAssignment) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT,
  ...payload,
});

const setSearchAssignmentDirectly = (searchAssignment: SearchAssignment) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT,
  searchAssignment,
});

const setAddressSearchState = (payload: ActionType.AddressSearchState) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_ADDRESS_SEARCH_STATUS,
  ...payload,
});

const setSelectedheaderSort = (payload: ActionType.HeaderSort) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SELECTED_ROW_HEADER,
  ...payload,
});

const setPublishStatus = (payload: ActionType.PublishState) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT_PUBLISH_STATUS,
  ...payload,
});

const setSaveSearchAssignmentStatus = (saveSearchAssignmentStatus: string) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SAVE_SEARCH_ASSIGNMENTS_STATUS,
  saveSearchAssignmentStatus,
});

const setDetailStatus = (searchAssignmentDetailStatus: string) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT_DETAIL_STATUS,
  searchAssignmentDetailStatus,
});

const updateSearchAssignment = (
  payload: ActionType.SingleSearchAssignment
) => ({
  type: REDUX.SEARCH_ASSIGNMENT.UPDATE_SEARCH_ASSIGNMENT,
  ...payload,
});

const toggleSaveModal = (showSaveModal: boolean) => ({
  type: REDUX.SEARCH_ASSIGNMENT.TOGGLE_SEARCH_ASSIGNMENT_SAVE_MODAL,
  showSaveModal,
});

const setMatchMailsState = (matchMailsState: string) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_MATCHMAILS_STATE,
  matchMailsState,
});

const setMatchMails = (payload: ActionType.Matchmails) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_MATCHMAILS,
  ...payload,
});

const appendMatchMails = (payload: ActionType.Matchmails) => ({
  type: REDUX.SEARCH_ASSIGNMENT.APPEND_MATCHMAILS,
  ...payload,
});

const setModalMatchingPropertiesState = (
  modalMatchingPropertiesState: string
) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_MODAL_MATCHING_PROPERTIES_STATE,
  modalMatchingPropertiesState,
});

const setModalMatchingProperties = (
  payload: ActionType.MatchGetProperties
) => ({
  type: REDUX.SEARCH_ASSIGNMENT.SET_MODAL_MATCHING_PROPERTIES,
  ...payload,
});

const clearModalMatchingProperties = () => ({
  type: REDUX.SEARCH_ASSIGNMENT.CLEAR_MODAL_MATCHING_PROPERTIES,
});

const updateListItem = (payload: ActionType.SnapShot) => ({
  type: REDUX.SEARCH_ASSIGNMENT.UPDATE_LIST_ITEM,
  ...payload,
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.SEARCH_ASSIGNMENT.DELETE_LIST_ITEM,
  ...payload,
});

export const SearchAssignmentActions = {
  setSearchAssignmentsStatus,
  appendSearchAssignments,
  clearAllFilters,
  clearFilter,
  setSearchAssignments,
  setSearchAssignmentsFilters,
  setSearchAssignment,
  setSearchAssignmentDirectly,
  setAddressSearchState,
  setSelectedheaderSort,
  setDetailStatus,
  setSaveSearchAssignmentStatus,
  updateSearchAssignment,
  setMatchMailsState,
  setMatchMails,
  appendMatchMails,
  clearModalMatchingProperties,
  setModalMatchingPropertiesState,
  setModalMatchingProperties,
  toggleSaveModal,
  updateListItem,
  deleteListItem,
};
