import * as React from "react";
import { FC, memo, useState, useCallback, useMemo } from "react";
import { AssignmentSnapShot, AssignmentType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";

type Props = {
  assignment: AssignmentSnapShot;
  onAssignmentClick: (assignment: AssignmentSnapShot) => void;
  onRemoveAssignmentClick: (
    event: React.MouseEvent<HTMLDivElement>,
    assignment: AssignmentSnapShot
  ) => void;
};

export const Pill: FC<Props> = memo(
  ({ assignment, onAssignmentClick, onRemoveAssignmentClick }) => {
    const {
      displayName,
      publicReference,
      typeOfAssignment,
      forSale,
      forRent
    } = assignment;

    const [touched, setTouched] = useState(false);
    const [firstTouch, setFirstTouch] = useState(true);

    const onClickHandler = useCallback(() => {
      if (touched && firstTouch) {
        setFirstTouch(false);
        return;
      }

      onAssignmentClick(assignment);
      setTouched(false);
      setFirstTouch(true);
    }, [assignment, touched, firstTouch, setFirstTouch, setTouched]);

    const name = useMemo(() => {
      switch (true) {
        case !!displayName: {
          return <span>{displayName}</span>;
        }
        case !!publicReference: {
          return <span>{publicReference}</span>;
        }
        case typeOfAssignment === AssignmentType.Acquisition: {
          switch (true) {
            case !!forRent && !!forSale:
            default: {
              return <I18n value="acquisition.type.forSaleAndRent" />;
            }
            case !!forRent: {
              return <I18n value="acquisition.type.forRent" />;
            }
            case !!forSale: {
              return <I18n value="acquisition.type.forSale" />;
            }
          }
        }
        default: {
          return null;
        }
      }
    }, [displayName, publicReference, typeOfAssignment, forSale, forRent]);

    return (
      <div
        className="pill"
        onTouchStart={() => setTouched(true)}
        onClick={onClickHandler}
      >
        <div className="value">{name}</div>
        <div
          className="remove"
          onClick={(event) => onRemoveAssignmentClick(event, assignment)}
        >
          <i className="fal fa-times" />
        </div>
      </div>
    );
  }
);
