import { EventType, GenderType } from "@haywork/api/event-center";
import {
  LinkedAssignment,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import {
  ASSIGNMENTROUTES,
  RELATIONROUTES,
  SCHEDULERROUTES,
  SEARCHASSIGNMENTROUTES,
  SETTINGSROUTES,
  ACQUISITIONOBJECTROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Hint, ResourceText } from "@haywork/modules/shared";
import {
  EventCenterUtil,
  EventNotificationsFilter,
  ExtendedEventNotification,
  RouteUtil,
} from "@haywork/util";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import first from "lodash-es/first";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import {
  AssignmentBody,
  EmailBody,
  PublicationBody,
  RelationBody,
} from "./body";
import {
  EventNotificationActionType,
  FooterComponent,
} from "./footer.component";
import { TitleComponent } from "./title.component";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { intlContext } from "@haywork/app";

const styles = require("./modal-message.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const relationBodyEventTypes = [
  EventType.AccountDeleted,
  EventType.ViewingRequest,
  EventType.OfferListing,
  EventType.ContactMeRequest,
  EventType.Searchprofile,
  EventType.AccountCreated,
  EventType.BrochureDownloaded,
  EventType.PersonalMessage,
  // EventType.SystemMessage
];
const relationAssignmentEventTypes = [
  EventType.AccountDeleted,
  EventType.ViewingRequest,
  EventType.OfferListing,
  EventType.ContactMeRequest,
  EventType.Searchprofile,
  EventType.AccountCreated,
  EventType.BrochureDownloaded,
  EventType.Valuation,
  EventType.PersonalMessage,
];
const publicationBodyEventTypes = [
  EventType.PublicationFailed,
  EventType.PublicationSucceeded,
  EventType.BundleMutationNew,
  EventType.BundleMutationWithdrawn,
  EventType.BundleMutationPriceChange,
  EventType.BundleMutationTransferred,
  EventType.BundleMutationUnderBid,
  EventType.BundleMutationUnderOption,
  EventType.BundleMutationUnderCondition,
  EventType.BundleMutationDeedSigned,
  EventType.BundleMutationAvailable,
];

interface ModalMessageProps {
  message: ExtendedEventNotification;
  canSendEmail: boolean;
  currentOfficeName: string;
  features: string[];
  navigate: (path: string) => void;
  archive: (message: ExtendedEventNotification) => void;
  unArchive: (message: ExtendedEventNotification) => void;
  onLinkClicked?: () => void;
  onEmail: (
    emails: string | string[],
    linkedRelations?: LinkedRelation[],
    linkedAssigmnents?: LinkedAssignment[],
    subject?: string
  ) => void;
  createNewTask: (
    subject?: string,
    linkedAssignments?: LinkedAssignment[],
    linkedRelations?: LinkedRelation[]
  ) => void;
  createNewRelation: (
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType
  ) => void;
  createNewAppointment: (
    subject?: string,
    linkedAssignments?: LinkedAssignment[],
    linkedRelations?: LinkedRelation[]
  ) => void;
  openDraft: (id: string, accountId: string) => void;
  onCheckRelationForDuplicates?: (
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType,
    linkedMessageId?: string
  ) => void;
  onUpdateLinkedRelation?: (
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType,
    linkedMessageId?: string
  ) => void;
}
type Props = ModalMessageProps;

@CSSModules(styles, { allowMultiple: true })
export class ModalMessageComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onUpdateLinkedRelation = this.onUpdateLinkedRelation.bind(this);
    this.unProcessMessage = this.unProcessMessage.bind(this);
    this.onProcessMessage = this.onProcessMessage.bind(this);
    this.onActionClickHandler = this.onActionClickHandler.bind(this);
  }

  public render() {
    const { linkedEvent, filter, icon, isRead } = this.props.message;
    const processTriggerIcon =
      filter === EventNotificationsFilter.Processed
        ? "fal fa-times"
        : "fal fa-clipboard-check";
    const messageStyle = classNames("message", {
      unread: !isRead && filter !== EventNotificationsFilter.Processed,
    });

    return (
      <div styleName={messageStyle}>
        <div styleName="message__header">
          <div styleName={classNames("icon", linkedEvent.eventType.toString())}>
            <i className={icon} />
          </div>
          <div styleName="titles">
            <h2>
              <TitleComponent
                message={this.props.message}
                onNavigate={this.props.navigate}
              />
            </h2>
            <h3>
              <div styleName="section">
                <i className="far fa-clock" />
                {this.renderNotificationTime()}
              </div>
              {this.renderSender()}
              {this.renderDelegatedBy()}
            </h3>
          </div>
          {filter === EventNotificationsFilter.Processed ? (
            <div styleName="unarchive" onClick={this.unProcessMessage}>
              <i className="far fa-undo-alt" />
            </div>
          ) : (
            <Hint message="processEvent" forceInline={true}>
              <div styleName="trigger" onClick={this.onProcessMessage}>
                <i className={processTriggerIcon} />
              </div>
            </Hint>
          )}

          {/* <div styleName="trigger" onClick={this.onProcessMessage}>
            <i className={processTriggerIcon} />
          </div>
          {filter === EventNotificationsFilter.Processed && (
            <div styleName="unarchive" onClick={this.unProcessMessage}>
              <i className="far fa-undo-alt" />
            </div>
          )} */}
        </div>
        <div styleName="message__body">
          {relationBodyEventTypes.includes(linkedEvent.eventType) && (
            <ErrorBoundary>
              <RelationBody
                onLinkClicked={this.props.onLinkClicked}
                message={this.props.message}
              />
            </ErrorBoundary>
          )}
          {relationAssignmentEventTypes.includes(linkedEvent.eventType) && (
            <ErrorBoundary>
              <AssignmentBody
                onLinkClicked={this.props.onLinkClicked}
                message={this.props.message}
              />
            </ErrorBoundary>
          )}
          {publicationBodyEventTypes.includes(linkedEvent.eventType) && (
            <ErrorBoundary>
              <PublicationBody
                linkedPublications={
                  this.props.message.linkedEvent.linkedPublications
                }
              />
            </ErrorBoundary>
          )}
          {linkedEvent.eventType === EventType.SendMailFailed && (
            <ErrorBoundary>
              <EmailBody
                linkedDrafts={this.props.message.linkedEvent.linkedDrafts}
              />
            </ErrorBoundary>
          )}
        </div>
        <FooterComponent
          message={this.props.message}
          onActionClick={this.onActionClickHandler}
          onUpdateLinkedRelation={this.onUpdateLinkedRelation}
          canSendEmail={this.props.canSendEmail}
          showDuplicateContacts={relationBodyEventTypes.includes(
            linkedEvent.eventType
          )}
        />
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: Props) {
    return !deepEqual(nextProps.message, this.props.message);
  }

  private renderNotificationTime() {
    const { dateTimeNotify } = this.props.message;
    const absDate = moment(dateTimeNotify);
    const absNowDate = moment();

    const date = moment(
      new Date(absDate.year(), absDate.month(), absDate.day(), 12, 0, 0)
    );
    const now = moment(
      new Date(
        absNowDate.year(),
        absNowDate.month(),
        absNowDate.day(),
        12,
        0,
        0
      )
    );

    switch (now.diff(date, "days")) {
      case 0:
        return (
          <FormattedDate
            value={dateTimeNotify}
            hour="2-digit"
            minute="2-digit"
          />
        );
      case 1:
        return <ResourceText resourceKey="yesterday" />;
      case 2:
        return <ResourceText resourceKey="dayBeforeYesterday" />;
      default:
        return (
          <FormattedDate
            value={dateTimeNotify}
            day="2-digit"
            month="long"
            year="numeric"
          />
        );
    }
  }

  private onActionClickHandler(type: EventNotificationActionType) {
    const {
      linkedObjectAssignments,
      linkedAcquisitionObjectAssignments,
      linkedContactPersons,
      linkedMessages,
      linkedSearchAssignments,
      eventType,
      eventDateTime,
      linkedAgendaItems,
      linkedDrafts,
    } = this.props.message.linkedEvent;

    const linkedRelations: LinkedRelation[] = linkedContactPersons.map(
      (person) => {
        return {
          ...person,
          typeOfRelation: RelationType.ContactPerson,
        } as LinkedRelation;
      }
    );

    switch (type) {
      case EventNotificationActionType.CreateAppointment: {
        return this.props.createNewAppointment(
          null,
          linkedObjectAssignments,
          linkedRelations
        );
      }
      case EventNotificationActionType.RefreshCollegialListingToken: {
        return this.props.navigate(SETTINGSROUTES.MATCHMAIL.URI);
      }
      case EventNotificationActionType.OpenAssignment: {
        const id = first(linkedObjectAssignments).id;
        return this.props.navigate(route(ASSIGNMENTROUTES.DETAIL.URI, { id }));
      }
      case EventNotificationActionType.OpenAcquisitionAssignment: {
        const id = first(linkedAcquisitionObjectAssignments).id;
        return this.props.navigate(
          route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id })
        );
      }
      case EventNotificationActionType.OpenRelation: {
        const id = first(linkedRelations).id;
        return this.props.navigate(
          route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
        );
      }
      case EventNotificationActionType.CreateEmail: {
        let subject = null;
        const emails = [];

        if (!!linkedMessages.length) {
          linkedMessages
            .filter((message) => !!message.emailAddress)
            .map((message) => emails.push(message.emailAddress));
        }

        if (!!linkedRelations.length) {
          linkedRelations
            .filter((person) => !!person.email)
            .map((person) => emails.push(person.email));
        }

        switch (true) {
          case eventType === EventType.ViewingRequest &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "emailSubject.ViewingRequest" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.ContactMeRequest &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "emailSubject.ContactMeRequest" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.OfferListing: {
            const displayName = this.props.currentOfficeName;
            subject = intlContext.formatMessage(
              { id: "emailSubject.OfferListing" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.Searchprofile: {
            const displayName = this.props.currentOfficeName;
            subject = intlContext.formatMessage(
              { id: "emailSubject.Searchprofile" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.AccountCreated: {
            const displayName = this.props.currentOfficeName;
            subject = intlContext.formatMessage(
              { id: "emailSubject.AccountCreated" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.Birthday: {
            subject = intlContext.formatMessage({
              id: "emailSubject.Birthday",
            });
            break;
          }
          default:
            break;
        }

        return this.props.onEmail(
          emails,
          linkedRelations,
          linkedObjectAssignments,
          subject
        );
      }
      case EventNotificationActionType.CreateTask: {
        let subject = null;

        switch (true) {
          case eventType === EventType.AccountCreated &&
            EventCenterUtil.hasName(this.props.message.linkedEvent): {
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.AccountCreated" },
              {
                displayName: EventCenterUtil.getName(
                  this.props.message.linkedEvent
                ),
              }
            );
            break;
          }
          case eventType === EventType.BankwarrantyExpired &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.BankwarrantyExpired" },
              {
                displayName,
                date: intlContext.formatDate(eventDateTime, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              }
            );
            break;
          }
          case eventType === EventType.Birthday &&
            EventCenterUtil.hasName(this.props.message.linkedEvent): {
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.Birthday" },
              {
                displayName: EventCenterUtil.getName(
                  this.props.message.linkedEvent
                ),
                date: intlContext.formatDate(eventDateTime, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              }
            );
            break;
          }
          case eventType === EventType.ContactMeRequest &&
            EventCenterUtil.hasName(this.props.message.linkedEvent) &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName: assignmentDisplayName } = first(
              linkedObjectAssignments
            );
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.ContactMeRequest" },
              {
                assignmentDisplayName,
                displayName: EventCenterUtil.getName(
                  this.props.message.linkedEvent
                ),
              }
            );
            break;
          }
          case eventType === EventType.PublicationFailed &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.PublicationFailed" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.PublicationSucceeded &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.PublicationSucceeded" },
              { displayName }
            );
            break;
          }
          case eventType === EventType.OfferListing &&
            EventCenterUtil.hasName(this.props.message.linkedEvent): {
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.OfferListing" },
              {
                displayName: EventCenterUtil.getName(
                  this.props.message.linkedEvent
                ),
              }
            );
            break;
          }
          case eventType === EventType.RentedUntilExpired &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.RentedUntilExpired" },
              {
                displayName,
                date: intlContext.formatDate(eventDateTime, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              }
            );
            break;
          }
          case eventType === EventType.ReservationExpired &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length: {
            const { displayName } = first(linkedObjectAssignments);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.ReservationExpired" },
              {
                displayName,
                date: intlContext.formatDate(eventDateTime, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              }
            );
            break;
          }
          case eventType === EventType.Valuation &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length &&
            !!linkedRelations &&
            !!linkedRelations.length: {
            const { displayName: assignmentDisplayName } = first(
              linkedObjectAssignments
            );
            const { displayName } = first(linkedRelations);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.Valuation" },
              {
                displayName,
                assignmentDisplayName,
              }
            );
            break;
          }
          case eventType === EventType.Viewing &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length &&
            !!linkedRelations &&
            !!linkedRelations.length: {
            const { displayName: assignmentDisplayName } = first(
              linkedObjectAssignments
            );
            const { displayName } = first(linkedRelations);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.Viewing" },
              {
                displayName,
                assignmentDisplayName,
              }
            );
            break;
          }
          case eventType === EventType.ViewingRequest &&
            !!linkedObjectAssignments &&
            !!linkedObjectAssignments.length &&
            !!linkedRelations &&
            !!linkedRelations.length: {
            const { displayName: assignmentDisplayName } = first(
              linkedObjectAssignments
            );
            const { displayName } = first(linkedRelations);
            subject = intlContext.formatMessage(
              { id: "quickTaskSubject.ViewingRequest" },
              {
                displayName,
                assignmentDisplayName,
              }
            );
            break;
          }
          default:
            break;
        }

        return this.props.createNewTask(
          subject,
          linkedObjectAssignments,
          linkedRelations
        );
      }
      case EventNotificationActionType.CreateRelation: {
        let name, email, phone, mobilePhone, genderType, linkedMessageId;
        if (!!linkedMessages && !!linkedMessages.length) {
          const {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            mobilePhoneNumber,
            gender,
            id,
          } = first(linkedMessages);
          name = [firstName, lastName].filter((d) => !!d).join(" ");
          email = emailAddress;
          phone = phoneNumber;
          mobilePhone = mobilePhoneNumber;
          genderType = gender;
          linkedMessageId = id;
        }

        if (
          FeatureHelper.executeBlock(this.props.features, "DUPLICATE_CONTACTS")
        ) {
          return this.props.onCheckRelationForDuplicates(
            name,
            email,
            phone,
            mobilePhone,
            genderType,
            linkedMessageId
          );
        } else {
          return this.props.createNewRelation(
            name,
            email,
            phone,
            mobilePhone,
            genderType
          );
        }
      }
      case EventNotificationActionType.OpenSearchAssignment: {
        const { id } = first(linkedSearchAssignments);
        return this.props.navigate(
          route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id })
        );
      }
      case EventNotificationActionType.OpenAppointment: {
        const id = first(linkedAgendaItems).id;
        return this.props.navigate(
          route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id })
        );
      }
      case EventNotificationActionType.OpenDraft: {
        const draft = first(linkedDrafts);
        if (!draft) return;
        return this.props.openDraft(draft.id, draft.accountId);
      }
      case EventNotificationActionType.OpenLink: {
        let { infoUrl } = first(linkedMessages);
        if (!infoUrl.match(/^https?:\/\//i)) {
          infoUrl = "http://" + infoUrl;
        }
        window.open(infoUrl, "_blank");
      }
      default:
        return;
    }
  }

  private onProcessMessage() {
    switch (this.props.message.filter) {
      case EventNotificationsFilter.Processed:
        return;
      default:
        return this.props.archive(this.props.message);
    }
  }

  private unProcessMessage() {
    this.props.unArchive(this.props.message);
  }

  private renderSender() {
    let { linkedMessages } = this.props.message.linkedEvent;
    linkedMessages = linkedMessages || [];
    const message = first(linkedMessages);

    if (!message || !message.sender) return null;

    return (
      <div styleName="section">
        <i className="fal fa-globe" />
        {message.sender}
      </div>
    );
  }

  private renderDelegatedBy() {
    const { delegatedByEmployee } = this.props.message;
    if (!delegatedByEmployee) return null;

    return (
      <div styleName="section">
        <ResourceText
          resourceKey="notificationDelegatedBy"
          values={{ displayName: delegatedByEmployee.displayName }}
        />
      </div>
    );
  }

  private onUpdateLinkedRelation() {
    const { message, onUpdateLinkedRelation } = this.props;
    const { linkedEvent } = message;
    if (!linkedEvent) return;
    const { linkedMessages } = linkedEvent;

    let name, email, phone, mobilePhone, genderType, linkedMessageId;
    if (!!linkedMessages && !!linkedMessages.length) {
      const {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        mobilePhoneNumber,
        gender,
        id,
      } = first(linkedMessages);
      name = [firstName, lastName].filter((d) => !!d).join(" ");
      email = emailAddress;
      phone = phoneNumber;
      mobilePhone = mobilePhoneNumber;
      genderType = gender;
      linkedMessageId = id;
    }

    if (!!onUpdateLinkedRelation) {
      onUpdateLinkedRelation(
        name,
        email,
        phone,
        mobilePhone,
        genderType,
        linkedMessageId
      );
    }
  }
}
