export enum EmailBucket {
  Today = "Today",
  Yesterday = "Yesterday",
  ThisWeek = "ThisWeek",
  LastWeek = "LastWeek",
  Older = "Older"
}

export enum DraftType {
  New = "New",
  Reply = "Reply",
  ReplyAll = "ReplyAll",
  Forward = "Forward"
}
