import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import {
  BaseQueryComponent,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  QueryResultStringReturnValue,
} from "./base.component";

interface QueryComponentProps<G> {
  values?: G[];
  multiple?: boolean;
  placeholder?: string;
  forceAdd?: boolean;
  onAddResource?: string;
  emptyResource?: string;
  asyncValues?: (value: string) => Promise<G[]>;
  matchOn?: (query: string, value: G) => boolean;
  optionValue: (value: G, query: string) => QueryOptionReturnValue;
  selectedValue?: (value: G) => QueryResultReturnValue<G>;
  selectedStringValue?: (value: G) => QueryResultStringReturnValue<any>;
  onAdd?: (query: string) => void;
  disableOption?: (value: G, values: any[]) => boolean;
  autoFocus?: boolean;
  openOnDeleteOption: boolean;
}
interface QueryComponentState {
  value: any[];
}

export class QueryV3Component<G> extends React.PureComponent<
  QueryComponentProps<G> & InputComponentProps,
  QueryComponentState
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.state = {
      value: isArray(this.props.value) ? this.props.value : [this.props.value],
    };
  }

  public render() {
    return (
      <BaseQueryComponent
        {...this.props}
        {...this.state}
        onChange={this.onChangeHandler}
      />
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: QueryComponentProps<G> & InputComponentProps
  ) {
    if (!nextProps) return;

    const value = isArray(nextProps.value)
      ? nextProps.value
      : [nextProps.value];
    this.setState({ value });
  }

  private onChangeHandler(values: any[]) {
    const mappedValue = this.props.multiple ? values : first(values);
    this.props.onChange(mappedValue);
  }
}
