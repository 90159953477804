import { Action } from "redux";

import { REDUX } from "@haywork/constants";
import * as ActionType from "./access.types";

export interface AccessState {
  token?: string;
  employeeId?: string;
}

export const INITIAL_STATE: AccessState = {
  token: null,
  employeeId: null,
};

export const accessReducer = (
  state: AccessState = INITIAL_STATE,
  action: Action
): AccessState => {
  switch (action.type) {
    case REDUX.ACCESS.SET_INITIAL_ACCESS: {
      const { token, employeeId } = <ActionType.Initial>action;
      return { ...state, token, employeeId };
    }
    case REDUX.ACCESS.UPDATE_TOKEN: {
      const { token } = <ActionType.AccessToken>action;
      return { ...state, token };
    }
    case REDUX.ACCESS.LOGOUT: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
