import { Phrase } from "@haywork/api/kolibri";
import { PhrasesThunks } from "@haywork/middleware/thunk/phrases.thunk";
import {
  UiPhrasesComponent,
  UiPhrasesComponentProps
} from "@haywork/modules/ui/phrases";
import { AppState, PhrasesActions } from "@haywork/stores";
import { connect } from "react-redux";
import { Dispatch } from "@haywork/middleware";

interface StateProps {
  phrase: Phrase;
  phraseStatus: string;
}
interface DispatchProps {
  getPhrases: (id: number) => void;
  getPhrase: (id: string) => void;
  unsetPhrase: () => void;
  updatePhrase: (phrase: Phrase) => void;
  savePhrase: (
    title: string,
    description: string,
    phraseCategoryId: number
  ) => void;
  clearPhrases: () => void;
}

const mapStateToProps = <StateProps, UiPhrasesComponentProps>(
  state: AppState
) => {
  const { phrase, phraseStatus } = state.phrases;

  return {
    phrase,
    phraseStatus
  };
};

const mapDispatchToProps = <DispatchProps, UiPhrasesComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getPhrases: (id: number) => dispatch(PhrasesThunks.getPhrases(id)),
  getPhrase: (id: string) => dispatch(PhrasesThunks.getPhrase(id)),
  unsetPhrase: () => dispatch(PhrasesActions.unsetPhrase()),
  updatePhrase: (phrase: Phrase) =>
    dispatch(PhrasesThunks.updatePhrase(phrase)),
  savePhrase: (title: string, description: string, phraseCategoryId: number) =>
    dispatch(PhrasesThunks.savePhrase(title, description, phraseCategoryId)),
  clearPhrases: () => dispatch(PhrasesActions.setPhrases({ phrases: [] }))
});

export type UiPhrasesContainerProps = StateProps & DispatchProps;
export const UiPhrasesContainer = connect<
  StateProps,
  DispatchProps,
  UiPhrasesComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UiPhrasesComponent);
