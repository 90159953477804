import { combineReducers } from "redux";
import {
  AssignmentListActions,
  assignmentListReducer,
  AssignmentListState
} from "./list";
import {
  AssignmentOverviewActions,
  AssignmentsFilters,
  overviewAssignmentReducer,
  OverviewAssignmentState
} from "./overview";
import {
  AssignmentSingleActions,
  singleAssignmentReducer,
  SingleAssignmentState
} from "./single";
import {
  AssignmentTemplatesActions,
  AssignmentTemplatesState,
  templatesReducer
} from "./templates";
import {
  AssigmentWidgetsActions,
  AssignmentsWidgetsState,
  widgetsReducer
} from "./widgets";

const assignmentReducer = combineReducers({
  single: singleAssignmentReducer,
  overview: overviewAssignmentReducer,
  widgets: widgetsReducer,
  templates: templatesReducer,
  list: assignmentListReducer
});

interface AssignmentState {
  single: SingleAssignmentState;
  overview: OverviewAssignmentState;
  widgets: AssignmentsWidgetsState;
  templates: AssignmentTemplatesState;
  list: AssignmentListState;
}

export {
  AssignmentSingleActions,
  AssignmentOverviewActions,
  assignmentReducer,
  AssignmentState,
  AssignmentsFilters,
  SingleAssignmentState,
  widgetsReducer,
  AssignmentsWidgetsState,
  AssigmentWidgetsActions,
  templatesReducer,
  AssignmentTemplatesState,
  AssignmentTemplatesActions,
  AssignmentListActions
};
