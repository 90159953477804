import { combineReducers } from "redux";
import { VoipCallsState, voipCallsReducer, Actions as Calls } from "./calls";

export interface VoipState {
  calls: VoipCallsState;
}

export const voipReducer = combineReducers({
  calls: voipCallsReducer
});

export const VoipActions = {
  Calls
};
