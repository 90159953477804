import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { ObjectAssignment } from "@haywork/api/kolibri";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentDetailVersionsComponent,
  AssignmentDetailVersionsComponentProps
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
}
interface DispatchProps {
  navigate: (path: string) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailVersionsComponentProps,
  AppState
> = (state) => {
  const { objectAssignment } = state.assignment.single;

  return {
    objectAssignment
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailVersionsComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type AssignmentDetailVersionsContainerProps = StateProps & DispatchProps;
export const AssignmentDetailVersionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailVersionsComponent);
