import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  ObjectTypeAssignment,
  ObjectTypeAssignmentLinkRelationRequest,
  RelationRoleOption,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks,
} from "@haywork/middleware";
import {
  ObjectTypesDetailNetworkComponent,
  ObjectTypesDetailNetworkComponentProps,
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { fullyBakedDisplayName } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  relationRoles: RelationRoleOption[];
  currentComponentState: ObjectTypeAssignment;
  realEstateAgencyId: string;
  host: string;
  path: string;
  saveContactState: string;
  displayName: string;
}

interface DispatchProps {
  navigate: (url: string) => void;
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
  saveObjectType: (objectType: ObjectTypeAssignment) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  linkRelation: (
    relation: ObjectTypeAssignmentLinkRelationRequest,
    updatedObjectTypeAssignment: ObjectTypeAssignment
  ) => Promise<void>;
  unlinkRelation: (
    relation: ObjectTypeAssignmentLinkRelationRequest,
    updatedObjectTypeAssignment: ObjectTypeAssignment
  ) => Promise<void>;
}

const mapStateToProps = <StateProps, AssignmentDetailNetworkComponentProps>(
  state: AppState
) => {
  const { currentComponentState } = state.editable;
  const { relationRoles } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const { currentType } = state.project.types;

  const path = RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
    id: currentType.id,
  });

  return {
    currentType,
    currentComponentState,
    relationRoles,
    realEstateAgencyId,
    host,
    path,
    saveContactState: state.project.types.saveObjectTypeStatus,
    displayName: fullyBakedDisplayName(state),
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AssignmentDetailNetworkComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  saveObjectType: (objectType: ObjectTypeAssignment) =>
    dispatch(ProjectsThunks.Types.saveType(objectType, false)),
  linkRelation: (
    relation: ObjectTypeAssignmentLinkRelationRequest,
    updatedObjectTypeAssignment: ObjectTypeAssignment
  ) =>
    dispatch(
      ProjectsThunks.Types.linkRelation(relation, updatedObjectTypeAssignment)
    ),
  unlinkRelation: (
    relation: ObjectTypeAssignmentLinkRelationRequest,
    updatedObjectTypeAssignment: ObjectTypeAssignment
  ) =>
    dispatch(
      ProjectsThunks.Types.unlinkRelation(relation, updatedObjectTypeAssignment)
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type ObjectTypesDetailNetworkContainerProps = StateProps & DispatchProps;
export const ObjectTypesDetailNetworkContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesDetailNetworkComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesDetailNetworkComponent);
