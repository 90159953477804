import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import {
  WidgetEntityType,
  WidgetEntityLocation,
} from "@haywork/api/authorization";

export enum MlsListAction {
  ExportToPDF = "ExportToPDF",
  ExportToExcel = "ExportToExcel",
  Follow = "Follow",
  Email = "Email",
  CreateRoute = "CreateRoute",
  ShowOnMap = "ShowOnMap",
  Clear = "Clear",
  Print = "Print",
}

type Props = {
  count: number;
  selectedIds: string[];
  canSendEmail: boolean;
  onClick: (action: MlsListAction) => void;
};

export const ActionsComponent: FC<Props> = memo(
  ({ count, selectedIds, canSendEmail, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const onClickHandler = (action: MlsListAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="actions"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          {/* <Spacer label="mls.listActionsLabel.export" />
          <Action
              label={`mls.listAction.${MlsListAction.ExportToPDF.toString()}`}
              icon={
                <Icon
                  light
                  name="file-pdf"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(MlsListAction.ExportToPDF)}
            />
            <Action
              label={`mls.listAction.${MlsListAction.ExportToExcel.toString()}`}
              icon={
                <Icon
                  light
                  name="file-excel"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(MlsListAction.ExportToExcel)}
            />
            <Spacer label="mls.listActionsLabel.other" /> */}
          <Spacer label="actions" />
          <Action
            label={`mls.listAction.${MlsListAction.Follow.toString()}`}
            icon={
              <Icon light name="eye" color={Colors.ActionListIcon} fixedWidth />
            }
            onClick={() => onClickHandler(MlsListAction.Follow)}
          />

          {!!canSendEmail && (
            <Action
              label={`mls.listAction.${MlsListAction.Email.toString()}`}
              icon={
                <Icon
                  light
                  name="envelope"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(MlsListAction.Email)}
            />
          )}

          {count <= 9 && (
            <Action
              label={`mls.listAction.${MlsListAction.CreateRoute.toString()}`}
              icon={
                <Icon
                  light
                  name="route"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(MlsListAction.CreateRoute)}
            />
          )}
          <Action
            label={`mls.listAction.${MlsListAction.ShowOnMap.toString()}`}
            icon={
              <Icon
                light
                name="map-marker-alt"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(MlsListAction.ShowOnMap)}
          />
          <Action
            label={`mls.listAction.${MlsListAction.Clear.toString()}`}
            icon={
              <Icon
                light
                name="eraser"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(MlsListAction.Clear)}
          />
          <Action
            label={`mls.listAction.${MlsListAction.Print.toString()}`}
            icon={
              <Icon
                light
                name="print"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(MlsListAction.Print)}
          />
          <FeatureSwitch feature="APP_XCHANGE">
            <AuthorizationWidgets
              type={WidgetEntityType.MlsProperty}
              location={WidgetEntityLocation.ListContextMenu}
              selectedIds={selectedIds}
              onActionClick={() => setActionsVisible(false)}
            />
          </FeatureSwitch>
        </ActionList>
      </>
    );
  }
);

export default ActionsComponent;
