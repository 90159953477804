import { singleActions, SingleActions } from "./single.actions";
import { REQUEST } from "@haywork/constants";
import { AcquisitionAssignment } from "@haywork/api/kolibri";
import { FolderTreeEntityType } from "@haywork/middleware";

export type SingleState = {
  acquisition: AcquisitionAssignment;
  acquisitionStatus: string;
};

const INITIAL: SingleState = {
  acquisition: null,
  acquisitionStatus: REQUEST.IDLE
};

export const singleReducer = (
  state = INITIAL,
  action: SingleActions
): SingleState => {
  switch (action.type) {
    case singleActions.SetAcquisition: {
      const { acquisition } = action;

      return {
        ...state,
        acquisition,
        acquisitionStatus: REQUEST.SUCCESS
      };
    }
    case singleActions.SetStatus: {
      const { acquisitionStatus } = action;

      return {
        ...state,
        acquisitionStatus
      };
    }
    case singleActions.SetEntityFolderTreeId: {
      const { id, entityType, entityId } = action;
      if (
        entityType === FolderTreeEntityType.AcquisitionAssignment &&
        !!state.acquisition &&
        state.acquisition.id === entityId
      ) {
        return {
          ...state,
          acquisition: {
            ...state.acquisition,
            linkedFolderTree: {
              id
            }
          }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
