import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SingleFilterValue } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";

const masterTableSelector = (state: AppState) => state.main.mastertable;
const officesSelector = (state: AppState) => state.offices.offices;
const employeesSelector = (state: AppState) => state.employee.employees;
const localitiesSelector = (state: AppState) =>
  state.assignment.list.statistics.localities;
const keyNumbersSelector = (state: AppState) =>
  state.assignment.list.statistics.keyNumbers;
const filtersSelector = (state: AppState) => state.assignment.list.filters;

export const listingTypeValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { listingTypes } = mastertable.kolibri;
    return (listingTypes || []).map(
      (listingType) =>
        ({
          label: listingType.displayName,
          value: listingType.value,
        } as SingleFilterValue)
    );
  }
);

export const assignmentPhaseValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { assignmentPhases } = mastertable.kolibri;
    return (assignmentPhases || []).map(
      (assignmentPhase) =>
        ({
          label: assignmentPhase.displayName,
          value: assignmentPhase.value,
        } as SingleFilterValue)
    );
  }
);

export const availabilityStatusValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { availabilityStatuses } = mastertable.kolibri;
    return (availabilityStatuses || []).map(
      (availabilityStatus) =>
        ({
          label: availabilityStatus.displayName,
          value: availabilityStatus.value,
        } as SingleFilterValue)
    );
  }
);

export const officeValues = createSelector(officesSelector, (offices) => {
  return (offices || []).map(
    (office) =>
      ({
        label: office.displayName,
        value: office.id,
      } as SingleFilterValue)
  );
});

export const employeeValues = createSelector(employeesSelector, (employees) => {
  return (employees || []).map(
    (employee) =>
      ({
        label: employee.displayName,
        value: employee.id,
      } as SingleFilterValue)
  );
});

export const localityValues = createSelector(
  localitiesSelector,
  (localities) => {
    return (localities || []).map(
      (locality) =>
        ({
          label: locality,
          value: locality,
        } as SingleFilterValue)
    );
  }
);

export const activeFilterGuidValues = createSelector(
  employeesSelector,
  officesSelector,
  (employees, offices) => {
    return {
      employees: (employees || []).map((employee) => ({
        value: employee.displayName,
        id: employee.id,
      })),
      offices: (offices || []).map((office) => ({
        value: office.displayName,
        id: office.id,
      })),
    } as FilterGuidValues;
  }
);

export const furnishingValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { furnishingOptions } = mastertable.kolibri;
    return (furnishingOptions || []).map(
      (furnishingOption) =>
        ({
          label: furnishingOption.displayName,
          value: furnishingOption.value,
        } as SingleFilterValue)
    );
  }
);

export const keyNumberValues = createSelector(
  keyNumbersSelector,
  (keyNumbers) => {
    return (keyNumbers || []).map(
      (keyNumber) =>
        ({
          label: keyNumber.toString(),
          value: keyNumber,
        } as SingleFilterValue)
    );
  }
);

export const priceRangeValue = createSelector(filtersSelector, (filters) => {
  return { value: filters.priceRange.value } as SingleFilterValue;
});

export const numberOfBedroomsValue = createSelector(
  filtersSelector,
  (filters) => {
    return { value: filters.numberOfBedrooms.value } as SingleFilterValue;
  }
);

export const availableFromValue = createSelector(filtersSelector, (filters) => {
  return { value: filters.availableFrom.value } as SingleFilterValue;
});

export const availableUntillValue = createSelector(
  filtersSelector,
  (filters) => {
    return { value: filters.availableUntill.value } as SingleFilterValue;
  }
);
