import Button from "@haywork/components/ui/button";
import { LinkedEntity } from "@haywork/components/ui/linked-entities";
import PageHeader from "@haywork/components/ui/page-header";
import Notes from "@haywork/modules/notes-v3";
import LinkedEntityModal from "@haywork/modules/shared/components/linked-entities-modal";
import * as React from "react";
import { FC, memo, useCallback, useState, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import Sidebar from "../../components/detail-sidebar";
import { AcquisitionDetailAssignmentsScreenContainerProps } from "./assignments.container";
import MultiList from "@haywork/components/ui/multi-list";
import AssignmentRow from "./components/assignment-row";
import MlsRow from "./components/mls-row";
import EmptyRow from "./components/empty-row";
import EmptyState from "./components/empty-state";
import { EmailAssignment } from "@haywork/util/email-v2";
import Actions, { AcquisitionObjectAction } from "./actions";

const styles = require("./style.scss");

export type AcquisitionDetailAssignmentsScreenProps = {};
type Props = AcquisitionDetailAssignmentsScreenProps &
  AcquisitionDetailAssignmentsScreenContainerProps;

export const AcquisitionDetailAssignmentsScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      createNew,
      acquisition,
      navigate,
      getAcquisitionObjects,
      getMlsObjectsMatchingSearchAssignments,
      createNewAcquisitionObjectAssignmentFromMLS,
      mailAssignmentSelection,
    }: Props) => {
      const { id, isNew, linkedSearchAssignment, linkedClients } = acquisition;
      const [linkedEntities, setLinkedEntities] = useState<LinkedEntity[]>([]);
      const [linkedEntityModalVisible, setLinkedEntityModalVisible] = useState(
        false
      );
      const [selectedAssignments, setSelectedAssignments] = useState<
        EmailAssignment[]
      >([]);

      const selectedAssignmentsIds = useMemo(
        () => selectedAssignments.map((assignment) => assignment.id),
        [selectedAssignments]
      );

      const addAssignment = useCallback(() => {
        createNew(acquisition);
      }, []);

      const onShowAllLinkedEntities = useCallback(
        (linkedEntities: LinkedEntity[]) => {
          setLinkedEntities(linkedEntities);
          setLinkedEntityModalVisible(true);
        },
        [setLinkedEntities, setLinkedEntityModalVisible]
      );

      const onCloseLinkedEntityModal = useCallback(() => {
        setLinkedEntities([]);
        setLinkedEntityModalVisible(false);
      }, [setLinkedEntities, setLinkedEntityModalVisible]);

      const negotiateMlsObject = useCallback(
        (bundleId: string, appClientKey: string) => {
          createNewAcquisitionObjectAssignmentFromMLS(
            bundleId,
            appClientKey,
            acquisition
          );
        },
        [acquisition, createNewAcquisitionObjectAssignmentFromMLS]
      );

      const onToggleSelected = useCallback(
        (assignment: EmailAssignment) => {
          if (selectedAssignmentsIds.includes(assignment.id)) {
            setSelectedAssignments((selectedAssignments) =>
              selectedAssignments.filter(
                (selectedAssignment) => selectedAssignment.id !== assignment.id
              )
            );
          } else {
            setSelectedAssignments((selectedAssignments) => [
              ...selectedAssignments,
              assignment,
            ]);
          }
        },
        [selectedAssignmentsIds, setSelectedAssignments]
      );

      const onActionClick = useCallback(
        (action: AcquisitionObjectAction) => {
          switch (action) {
            case AcquisitionObjectAction.New: {
              addAssignment();
              return;
            }
            case AcquisitionObjectAction.Email: {
              setSelectedAssignments((assignments) => {
                mailAssignmentSelection(assignments, linkedClients);
                return [];
              });
              return;
            }
            case AcquisitionObjectAction.ClearSelection: {
              setSelectedAssignments([]);
              return;
            }
            default:
              return;
          }
        },
        [
          addAssignment,
          setSelectedAssignments,
          mailAssignmentSelection,
          linkedClients,
        ]
      );

      return (
        <div styleName="assignments">
          <div styleName="sidebar">
            <Sidebar />
          </div>
          <div styleName="body">
            <PageHeader
              title="acquisition.detailAssignments.title"
              actions={
                <>
                  {!isNew && <Notes />}
                  {!!selectedAssignmentsIds.length ? (
                    <Actions onClick={onActionClick} />
                  ) : (
                    <Button
                      label="acquisition.addAssignment"
                      category="primary"
                      onClick={addAssignment}
                    />
                  )}
                </>
              }
            />

            <MultiList
              apis={[
                {
                  name: "acquisitionAssignments",
                  endpoint: (startIndex, stopIndex) =>
                    getAcquisitionObjects(id, startIndex, stopIndex),
                  row: (props) => (
                    <AssignmentRow
                      {...props}
                      selectedAssignmentsIds={selectedAssignmentsIds}
                      onNavigate={navigate}
                      onShowAllLinkedEntities={onShowAllLinkedEntities}
                      onToggleSelected={onToggleSelected}
                    />
                  ),
                },
                {
                  name: "acquisitionMls",
                  endpoint: (startIndex, stopIndex) =>
                    getMlsObjectsMatchingSearchAssignments(
                      linkedSearchAssignment,
                      startIndex,
                      stopIndex
                    ),
                  row: (props) => (
                    <MlsRow
                      {...props}
                      selectedAssignmentsIds={selectedAssignmentsIds}
                      onNavigate={navigate}
                      onNegotiateMlsObject={negotiateMlsObject}
                      onToggleSelected={onToggleSelected}
                    />
                  ),
                },
              ]}
              emptyRow={EmptyRow}
              emptyState={
                <EmptyState onEmptyStateActionClick={addAssignment} />
              }
              itemSize={150}
            />
          </div>

          <LinkedEntityModal
            linkedEntities={linkedEntities}
            visible={linkedEntityModalVisible}
            onClose={onCloseLinkedEntityModal}
            onNavigate={navigate}
          />
        </div>
      );
    }
  )
);
