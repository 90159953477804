import {
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;
const isLinkedRelation = (
  entity: LinkedAssignment | LinkedRelation
): entity is LinkedRelation => {
  return (entity as LinkedRelation).typeOfRelation !== undefined;
};

interface Props {
  entity: LinkedAssignment | LinkedRelation;
  forList?: boolean;
  onNavigate: (path: string) => void;
}

const DossierLinkedEntitiesPillComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ entity, forList, onNavigate }: Props) => {
      const gotoEntity = (entity: LinkedAssignment | LinkedRelation) => {
        if (isLinkedRelation(entity)) {
          const { id, typeOfRelation } = entity;

          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
                id,
              });
              return onNavigate(path);
            }
            case RelationType.ContactPerson: {
              const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
                id,
              });
              return onNavigate(path);
            }
            case RelationType.Employee: {
              const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              return onNavigate(path);
            }
            case RelationType.Office: {
              const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              return onNavigate(path);
            }
            default: {
              return;
            }
          }
        } else {
          const { id, typeOfAssignment } = entity;

          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
              return onNavigate(path);
            }
            case AssignmentType.AcquisitionObject: {
              const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
              return onNavigate(path);
            }
            case AssignmentType.Object: {
              const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
              return onNavigate(path);
            }
            case AssignmentType.ObjectType: {
              const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
              return onNavigate(path);
            }
            case AssignmentType.Project: {
              const path = route(PROJECTROUTES.DETAIL.URI, { id });
              return onNavigate(path);
            }
            default: {
              return;
            }
          }
        }
      };
      const icon = isLinkedRelation(entity)
        ? "fal fa-user"
        : "fal fa-folder-open";

      return (
        <div
          styleName={classNames("pill", "entity", { "for-list": forList })}
          key={entity.id}
          onClick={() => gotoEntity(entity)}
        >
          <i className={icon} />
          <span>{entity.displayName}</span>
        </div>
      );
    }
  )
);

export default DossierLinkedEntitiesPillComponent;
