import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";
import { ModalPortal } from "@haywork/portals";

const styles = require("./alert.component.scss");

interface Props {
  visible: boolean;
  titleResourceKey: string;
  bodyResourceKey: string;
  bodyValues?: any;
  btnResourceKey?: string;
  onClose: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class Alert extends React.Component<Props, State> {
  public render() {
    const {
      visible,
      titleResourceKey,
      bodyResourceKey,
      bodyValues,
      btnResourceKey,
    } = this.props;
    const alertStyle = classNames("alert", { visible });

    return (
      <ModalPortal>
        <div styleName={alertStyle}>
          <div styleName="alert__backdrop" />
          <div styleName="alert__object">
            <header>
              <ResourceText resourceKey={titleResourceKey} />
              <div
                styleName="alert__close"
                onClick={() => this.props.onClose()}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.AlertCloseButton`
                }
              >
                <div className="fal fa-times" />
              </div>
            </header>
            <section>
              <ResourceText
                resourceKey={bodyResourceKey}
                values={bodyValues}
                asHtml
              />
            </section>
            <footer>
              <div
                className="btn btn-primary"
                onClick={() => this.props.onClose()}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.AlertOkayButton`
                }
              >
                <ResourceText
                  resourceKey={
                    !!this.props.btnResourceKey
                      ? this.props.btnResourceKey
                      : "okay"
                  }
                />
              </div>
            </footer>
          </div>
        </div>
      </ModalPortal>
    );
  }
}
