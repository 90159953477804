import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState, EditableItem } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AcquisitionAssignmentEditScreenProps,
  AcquistionAssignmentEditScreen
} from "./assignments-edit";
import { hasChanges } from "./selectors";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  hasChanges: boolean;
  loading: boolean;
};
type DispatchProps = {
  save: (
    acquisitionObjectAssignment: AcquisitionObjectAssignment,
    close: boolean
  ) => Promise<void>;
  backToDashboard: () => Promise<void>;
  reloadAcquisitionObjectAssignment: (editable: EditableItem) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionAssignmentEditScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment: AcquisitionObjectAssignment =
    state.editable.currentComponentState;
  const { acquisitionAssignmentObjectStatus } = state.acquisition.assignment;

  return {
    acquisitionObjectAssignment,
    hasChanges: hasChanges(state),
    loading: acquisitionAssignmentObjectStatus === REQUEST.PENDING
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionAssignmentEditScreenProps
> = (dispatch) => ({
  save: (
    acquisitionAssignmentObject: AcquisitionObjectAssignment,
    close: boolean
  ) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.save(
        acquisitionAssignmentObject,
        close
      )
    ),
  backToDashboard: () =>
    dispatch(AcquisitionThunks.AcquisitionObject.backToDashboard()),
  reloadAcquisitionObjectAssignment: (editable: EditableItem) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.reloadAcquisitionObjectAssignment(
        editable
      )
    )
});

export type AcquisitionAssignmentEditScreenContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const AcquisitionAssignmentEditScreenContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcquistionAssignmentEditScreen)
);
