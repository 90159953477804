import * as React from "react";
import {
  FC,
  memo,
  useState,
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import DropArea from "./drop-area";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  logo: string | null;
  onChange: (file: File | null) => void;
};

export const SettingsCompanySettingsLogoComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ logo, onChange }) => {
    const [style, setStyle] = useState({ width: 0, height: 0 });

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const file = event.target.files.item(0);
      onChange(file);
    };

    const clearLogo = () => {
      onChange(null);
    };

    const resizeImage = useCallback(() => {
      if (!logo) {
        setStyle({ width: 0, height: 0 });
        return;
      }

      const image = new Image();

      image.addEventListener("load", () => {
        const { width, height } = image;
        const ratio = height / width;
        let newWidth = 500,
          newHeight = 500;

        if (ratio > 1) {
          newWidth = Math.floor(newHeight / ratio);
        } else {
          newHeight = Math.floor(newWidth * ratio);
        }

        setStyle({ width: newWidth, height: newHeight });
      });
      image.src = logo;
    }, [logo]);

    useEffect(() => {
      resizeImage();
    }, [resizeImage]);

    return (
      <div styleName="logo__wrapper">
        <div styleName="logo">
          <div
            styleName={classNames("logo__placeholder", {
              "has-logo": !!logo,
            })}
          >
            <DropArea onDrop={onChange}>
              {!logo ? (
                <>
                  <label>
                    <input type="file" onChange={onChangeHandler} />
                  </label>
                  <div styleName="empty-state">
                    <I18n value="settings.companySettings.logo.emptyState" />
                  </div>
                </>
              ) : (
                <div>
                  <img src={logo} style={style} />
                </div>
              )}
            </DropArea>
          </div>
        </div>
        {!!logo && (
          <div styleName="clear">
            <span className="as-link" onClick={clearLogo}>
              <I18n value="settings.companySettings.logo.clear" />
            </span>
          </div>
        )}
      </div>
    );
  })
);
