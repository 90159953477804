import * as Ably from "ably";
import * as React from "react";
import { FC, memo, useContext, useEffect } from "react";
import { LiveDataContext } from "../../live-data.context";
import { Channel } from "@haywork/api/voip";

type Props = {
  onConnectionChange: (channels: Channel[], realtime: Ably.Realtime) => void;
};

const Connecter: FC<Props> = memo(({ onConnectionChange }) => {
  const { voipChannels, voipRealtime } = useContext(LiveDataContext);

  useEffect(() => {
    onConnectionChange(voipChannels, voipRealtime);
  }, [voipChannels, voipRealtime, onConnectionChange]);

  return null;
});

export default Connecter;
