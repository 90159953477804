import {
  ContactCompany,
  ContactPerson,
  PhotoBlob,
  RelationType,
  SearchAssignmentSnapShot
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { FolderTreeEntityType } from "@haywork/middleware";
import { Action } from "redux";
import * as ActionType from "./relation.types";

export interface NewRelationPath {
  id: string;
  path: string;
  property: string;
}

export interface RelationDetailState {
  relationContactInfoState: string;
  saveRelationContactInfoState: string;
  relationAddressSearchState: string;
  selectedContactCompany: ContactCompany;
  selectedContactPerson: ContactPerson;
  selectedRelationDisplayName: string;
  selectedRelationId: string;
  selectedRelationIsNew: boolean;
  selectedRelationType: RelationType;
  selectedRelationAvatar: PhotoBlob;
  pathForNewRelation: NewRelationPath[];
  relationSearchAssignmentsStatus: string;
  searchAssignments: SearchAssignmentSnapShot[];
}

const INITIAL_STATE: RelationDetailState = {
  relationContactInfoState: REQUEST.IDLE,
  saveRelationContactInfoState: REQUEST.SUCCESS,
  relationAddressSearchState: REQUEST.IDLE,
  selectedContactCompany: null,
  selectedContactPerson: null,
  selectedRelationDisplayName: null,
  selectedRelationId: null,
  selectedRelationType: null,
  selectedRelationAvatar: null,
  selectedRelationIsNew: false,
  pathForNewRelation: [],
  relationSearchAssignmentsStatus: REQUEST.IDLE,
  searchAssignments: []
};

export const relationDetailReducer = (
  state: RelationDetailState = INITIAL_STATE,
  action: Action
): RelationDetailState => {
  switch (action.type) {
    case REDUX.RELATION.SET_FETCH_RELATION_GENERAL_CONTACT_INFO_STATE: {
      const { relationContactInfoState } = <
        ActionType.RelationContactInfoState
      >action;
      return { ...state, relationContactInfoState };
    }
    case REDUX.RELATION.SET_PATH_FOR_NEW_RELATION: {
      const { pathForNewRelation, id, propertyToAddNewRelationTo } = <
        ActionType.PathForNewRelation
      >action;
      return {
        ...state,
        pathForNewRelation: [
          ...state.pathForNewRelation,
          { id, path: pathForNewRelation, property: propertyToAddNewRelationTo }
        ]
      };
    }
    case REDUX.RELATION.SET_SAVE_CONTACT_INFO_STATE: {
      const { saveRelationContactInfoState } = <
        ActionType.SaveRelationContactInfoState
      >action;
      return { ...state, saveRelationContactInfoState };
    }
    case REDUX.RELATION.SET_RELATION_ADDRESS_SEARCH_STATE: {
      const { relationAddressSearchState } = <
        ActionType.RelationAddressSearchState
      >action;
      return { ...state, relationAddressSearchState };
    }
    case REDUX.RELATION.SEARCH_CONTACT_PERSON: {
      const { contactPerson } = <ActionType.ContactPersonResponse>action;
      return {
        ...state,
        selectedContactPerson: contactPerson,
        selectedRelationId: contactPerson.id,
        selectedRelationType: RelationType.ContactPerson,
        selectedRelationDisplayName: contactPerson.displayName,
        selectedRelationAvatar: contactPerson.passportPhotoBlob,
        selectedRelationIsNew: contactPerson.isNew,
        relationContactInfoState: REQUEST.SUCCESS,
        saveRelationContactInfoState: REQUEST.SUCCESS
      };
    }
    case REDUX.RELATION.SEARCH_CONTACT_COMPANY: {
      const { contactCompany } = <ActionType.ContactCompanyResponse>action;
      return {
        ...state,
        selectedContactCompany: contactCompany,
        selectedRelationId: contactCompany.id,
        selectedRelationType: RelationType.ContactCompany,
        selectedRelationDisplayName: contactCompany.displayName,
        selectedRelationAvatar: contactCompany.logo,
        selectedRelationIsNew: contactCompany.isNew,
        relationContactInfoState: REQUEST.SUCCESS,
        saveRelationContactInfoState: REQUEST.SUCCESS
      };
    }
    case REDUX.RELATION.SET_FETCH_RELATION_TIMELINE_STATE: {
      const { relationAddressSearchState } = <
        ActionType.RelationAddressSearchState
      >action;
      return { ...state, relationAddressSearchState };
    }
    case REDUX.RELATION.SET_FETCH_RELATION_SEARCH_ASSIGNMENTS_STATUS: {
      const { relationSearchAssignmentsStatus } = <
        ActionType.RelationSearchAssignmentsStatus
      >action;
      return { ...state, relationSearchAssignmentsStatus };
    }
    case REDUX.RELATION.SET_RELATION_SEARCH_ASSIGNMENTS: {
      const { searchAssignments } = <ActionType.SearchAssignments>action;
      return {
        ...state,
        searchAssignments,
        relationSearchAssignmentsStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.RELATION.SET_CONTACT_PERSON: {
      const { contactPerson } = <ActionType.ContactPersonResponse>action;

      return {
        ...state,
        selectedContactPerson: contactPerson,
        selectedRelationId: contactPerson.id,
        selectedRelationType: RelationType.ContactPerson,
        selectedRelationDisplayName: contactPerson.displayName,
        selectedRelationAvatar: contactPerson.passportPhotoBlob,
        selectedRelationIsNew: contactPerson.isNew,
        relationContactInfoState: REQUEST.SUCCESS
      };
    }
    case REDUX.RELATION.SET_CONTACT_COMPANY: {
      const { contactCompany } = <ActionType.ContactCompanyResponse>action;

      return {
        ...state,
        selectedContactCompany: contactCompany,
        selectedRelationId: contactCompany.id,
        selectedRelationType: RelationType.ContactCompany,
        selectedRelationDisplayName: contactCompany.displayName,
        selectedRelationAvatar: contactCompany.logo,
        selectedRelationIsNew: contactCompany.isNew,
        relationContactInfoState: REQUEST.SUCCESS
      };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityType, entityId } = <any>action;
      switch (true) {
        case entityType === FolderTreeEntityType.ContactCompany &&
          !!state.selectedContactCompany &&
          state.selectedContactCompany.id === entityId: {
          return {
            ...state,
            selectedContactCompany: {
              ...state.selectedContactCompany,
              linkedFolderTree: {
                id
              }
            }
          };
        }
        case entityType === FolderTreeEntityType.ContactPerson &&
          !!state.selectedContactPerson &&
          state.selectedContactPerson.id === entityId: {
          return {
            ...state,
            selectedContactPerson: {
              ...state.selectedContactPerson,
              linkedFolderTree: {
                id
              }
            }
          };
        }
        default: {
          return state;
        }
      }
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
