import { ListComponent, ListRefProps } from "./list";
import Column from "./components/column";
import Row from "./components/row";
import {
  ListContext,
  ListContextConsumer,
  ListContextProvider,
} from "./list.context";
import ActionsColumn from "./components/actions";
import ListWrapper from "./components/wrapper";
import ListFilter, {
  ListFilterContext,
  ListFilterContextConsumer,
  ListFilterContextProvider,
  FilterSection,
  SingleFilterConfig,
  FilterConfig,
  SingleFilterValue,
  Filter,
  FilterSectionColumn,
  FilterContextMenu,
} from "./components/list-filter";
import { Translateable } from "@haywork/components/i18n";
import ActiveFilters from "./components/active-filters";
import { FilterType } from "@haywork/enum/list-filter-types";

export type ColumnConfig = {
  label: Translateable;
  sortConfig?: any[];
  width: number;
  autoFill?: boolean;
};

export type ListColumnConfig = {
  columns: ColumnConfig[];
  withActions?: boolean;
};

export {
  ListComponent as default,
  Column,
  ActionsColumn,
  Row,
  ListContext,
  ListContextConsumer,
  ListContextProvider,
  ListWrapper,
  ListFilter,
  ListFilterContext,
  ListFilterContextConsumer,
  ListFilterContextProvider,
  FilterSection,
  FilterType,
  SingleFilterConfig,
  FilterConfig,
  SingleFilterValue,
  Filter,
  ListRefProps,
  ActiveFilters,
  FilterSectionColumn,
  FilterContextMenu,
};
