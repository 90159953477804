import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  ProjectDetailDossierComponent,
  ProjectDetailDossierComponentProps
} from "@haywork/modules/project/components/detail-dossier/detail-dossier.component";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  projectId?: string;
  displayName?: string;
  linkedFolderTreeId: string | null | undefined;
  isInitial: boolean;
}
interface DispatchProps {
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ProjectDetailDossierComponentProps,
  AppState
> = (state) => ({
  projectId: state.project.single.projectAssignment.id,
  displayName:
    state.project.single.projectAssignment.titleText &&
    state.project.single.projectAssignment.titleText.length > 0
      ? state.project.single.projectAssignment.titleText[0].text
      : "",
  linkedFolderTreeId: get(
    state.project.single.projectAssignment.linkedFolderTree,
    "id"
  ),
  isInitial: state.project.single.isInitial
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ProjectDetailDossierComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type ProjectDetailDossierContainerProps = StateProps & DispatchProps;
export const ProjectDetailDossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailDossierComponent);
