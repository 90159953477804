import {
  AssignmentSnapShot,
  CommunicationLog,
  CommunicationLogsClient,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import { Account, DraftsClient, File, FilesClient } from "@haywork/api/mail";
import { COUNTS, EMAILROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EditableActions, EmailActions } from "@haywork/stores";
import { DraftsActions } from "@haywork/stores/email/drafts";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { EmailUtil, FileUtil, RouteUtil, UploadUtil } from "@haywork/util";
import { ExtendedEmailDraft, ExtendedEmailFolder } from "@haywork/util/email";
import { push } from "connected-react-router";
import chunk from "lodash-es/chunk";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const saveDraft = (
  accountId: string,
  extendedDraft: ExtendedEmailDraft,
  confirm: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Drafts = new DraftsClient(emailHost);

    try {
      // If the accountId in the file url differs from the accountId with which we are trying to send
      // The files need to be re-uploaded to prevent the nylus invalid public block id error
      const files = extendedDraft.files || [];

      // Loop through all attached files
      const incorrectFiles = files.filter(
        // Check if fileName does not include current accountId
        (file) => !file.uri.includes(accountId)
      );

      // If there are incorrect files
      if (incorrectFiles.length > 0) {
        // Re-upload all incorrect files
        const updatedDraft = await dispatch(
          EmailDraftThunks.reUploadFilesForAccount(
            files,
            accountId,
            extendedDraft.temporaryId || extendedDraft.id
          )
        );
        // Update the current draft with the draft from the dispatch
        extendedDraft = updatedDraft;
      }

      const draft = EmailUtil.mapExtendedDraftToDraft(extendedDraft);
      const draftResponse = await parseRequest.response(
        Drafts.saveDraft({ accountId, draft }, realEstateAgencyId).then(
          (r) => r.draft
        ),
        ApiType.Email
      );

      const oldPath = route(EMAILROUTES.CREATE.URI, {
        id: extendedDraft.temporaryId,
      });
      const path = route(EMAILROUTES.CREATE.URI, { id: draftResponse.id });

      dispatch(
        EditableActions.changeEditablePath({
          oldPath,
          newPath: path,
          hasChanges: false,
        })
      );
      dispatch(push(path));

      const mappedDraft = {
        ...extendedDraft,
        ...draftResponse,
        temporaryId: draftResponse.id,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          ignoreChanges: true,
          componentState: mappedDraft,
        })
      );
      dispatch(EmailActions.Drafts.setDraft(mappedDraft));

      if (confirm) {
        const toast: ToastProps = {
          value: "toastDraftSaved",
          icon: "save",
        };

        dispatch(SnackbarActions.addToast(toast));
      }

      return mappedDraft;
    } catch (error) {
      throw error;
    }
  };
};

const getDraftsForAccountAndFolder = (
  account: Account,
  folder: ExtendedEmailFolder
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Accounts.setCurrentAccount({ account }));
    dispatch(EmailActions.Folders.setCurrentFolder({ folder }));
    dispatch(EmailActions.Messages.clearCurrentMessage());
    dispatch(getDrafts(true));
  };
};

const getDrafts = (init: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { currentFolder } = state.email.folders;

    if (!currentAccount || !currentFolder) return;

    const { drafts } = state.email.drafts;
    const skip = init
      ? 0
      : drafts.filter(
          (draft) =>
            draft.folderId === currentFolder.id &&
            draft.accountId === currentAccount.id
        ).length;
    const take = COUNTS.EMAIL_MESSAGES;

    dispatch(
      EmailActions.Folders.setFolderMessagesStatus({
        canLoadMoreMessagesStatus: REQUEST.PENDING,
        id: currentFolder.id,
      })
    );

    const Drafts = new DraftsClient(emailHost);

    try {
      const drafts = await parseRequest.response(
        Drafts.search(
          {
            accountId: currentAccount.id,
            skip,
            take,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const extendedDrafts = drafts.results.map((draft) =>
        EmailUtil.mapDraftToExtendedDraft(
          draft,
          currentAccount.id,
          currentFolder.id
        )
      );
      dispatch(EmailActions.Drafts.appendDrafts({ drafts: extendedDrafts }));
      dispatch(
        EmailActions.Folders.setFolderMessagesStatus({
          canLoadMoreMessagesStatus: REQUEST.SUCCESS,
          id: currentFolder.id,
        })
      );

      if (drafts.resultCount === 0) {
        dispatch(
          EmailActions.Folders.setFolderCanLoadMore({
            canLoadMoreMessages: false,
            id: currentFolder.id,
          })
        );
      }
    } catch (error) {
      dispatch(
        EmailActions.Folders.setFolderMessagesStatus({
          canLoadMoreMessagesStatus: REQUEST.ERROR,
          id: currentFolder.id,
        })
      );
      throw error;
    }
  };
};

const readDraft = (id: string, accountId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Drafts.setDraftStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { currentFolder } = state.email.folders;

    const Drafts = new DraftsClient(emailHost);
    const currentAccountId = accountId || currentAccount.id;

    try {
      const draft = await parseRequest.response(
        Drafts.read(currentAccountId, id, realEstateAgencyId).then(
          (result) => result.draft
        ),
        ApiType.Email
      );

      const path = route(EMAILROUTES.CREATE.URI, { id: draft.id });
      const extendedDraft = EmailUtil.mapDraftToExtendedDraft(
        draft,
        currentAccountId,
        currentFolder.id
      );

      dispatch(EmailActions.Drafts.setDraft(extendedDraft));
      dispatch(push(path));
    } catch (error) {
      dispatch(EmailActions.Drafts.setDraftStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const sendDraft = (
  accountId: string,
  draft: ExtendedEmailDraft,
  communicationLogId?: string,
  tracking?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const client = new DraftsClient(emailHost);

    draft = {
      ...draft,
      accountId,
    };

    try {
      // If the accountId in the file url differs from the accountId with which we are trying to send
      // The files need to be re-uploaded to prevent the nylus invalid public block id error
      const files = draft.files || [];

      // Loop through all attached files
      const incorrectFiles = files.filter(
        // Check if fileName does not include current accountId
        (file) => !file.uri.includes(accountId)
      );

      // If there are incorrect files
      if (incorrectFiles.length > 0) {
        // Re-upload all incorrect files
        const updatedDraft = await dispatch(
          EmailDraftThunks.reUploadFilesForAccount(
            files,
            accountId,
            draft.temporaryId || draft.id
          )
        );
        // Update the current draft with the draft from the dispatch
        draft = updatedDraft;
      }

      let body = draft.body || "";
      body = body.replace(/<\/?haywork(template|signature|body)>/gi, "");
      body = body.replace(/\{{.*?\}}/gi, ""); // Strip mergefield placeholders

      draft = { ...draft, body };

      const draftResponse = await parseRequest.response(
        client
          .saveDraft({ accountId, draft }, realEstateAgencyId)
          .then((r) => r.draft),
        ApiType.Email
      );

      const relatedMessageRef = has(draft, "userData")
        ? draft.userData.find((data) => data.key === "relatedMessage")
        : null;
      const relatedMessage = !!relatedMessageRef
        ? JSON.parse(relatedMessageRef.value)
        : undefined;

      await parseRequest.response(
        client.send(
          {
            accountId,
            id: draftResponse.id,
            tracking: {
              opens: !!tracking,
            },
            relatedMessage,
            communicationLogId,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const id = !!draft.temporaryId ? draft.temporaryId : draftResponse.id;
      const path = route(EMAILROUTES.CREATE.URI, { id });

      dispatch(EditableThunks.remove(path));

      return draftResponse.id;
    } catch (error) {
      throw error;
    }
  };
};

const deleteDraft = (accountId: string, draft: ExtendedEmailDraft) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Drafts = new DraftsClient(emailHost);

    if (!!draft.isNew) {
      const path = route(EMAILROUTES.CREATE.URI, { id: draft.temporaryId });

      dispatch(EditableThunks.remove(path));
      return;
    }

    try {
      await parseRequest.response(
        Drafts.delete(accountId, draft.id, realEstateAgencyId),
        ApiType.Email
      );

      dispatch(push(MAINROUTES.EMAIL.URI));
      dispatch(EmailActions.Drafts.deleteDraft(draft.id));
    } catch (error) {
      throw error;
    }
  };
};

const saveAndCloseDraft = (accountId: string, id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const currentComponentState = state.editable.states.find((state) =>
      path.includes(state.path)
    );
    if (!currentComponentState) return;
    const { componentState: draft } = currentComponentState;

    const Drafts = new DraftsClient(emailHost);

    try {
      await parseRequest.response(
        Drafts.saveDraft({ draft, accountId }, realEstateAgencyId)
      );

      dispatch(EditableThunks.remove(path));
    } catch (error) {
      throw error;
    }
  };
};

const setDraftFiles = (newFiles: File[], id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const currentComponentState = state.editable.states.find((state) =>
      path.includes(state.path)
    );
    if (!currentComponentState) return;
    const { componentState: draft } = currentComponentState;

    let files = (draft.files || []).filter((file) => !!file.isAttachment);
    files = [...files, ...newFiles];

    const componentState: ExtendedEmailDraft = {
      ...draft,
      files,
    };

    dispatch(EditableActions.updateComponentState({ path, componentState }));
  };
};

const setDraftAttachments = (newFiles: File[], id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const currentComponentState = state.editable.states.find((state) =>
      path.includes(state.path)
    );
    if (!currentComponentState) return;
    const { componentState: draft } = currentComponentState;

    let files = (draft.files || []).filter((file) => !file.isAttachment);
    files = [...files, ...newFiles];

    const componentState: ExtendedEmailDraft = {
      ...draft,
      files,
    };

    dispatch(EditableActions.updateComponentState({ path, componentState }));
  };
};

const appendFile = (file: File, id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const currentComponentState = state.editable.states.find((state) =>
      path.includes(state.path)
    );
    if (!currentComponentState) return;
    const { componentState: draft } = currentComponentState;

    const componentState: ExtendedEmailDraft = {
      ...draft,
      files: isArray(draft.files) ? [...draft.files, file] : [file],
    };

    dispatch(EditableActions.updateComponentState({ path, componentState }));
  };
};

const removeFile = (fileId: string, id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const currentComponentState = state.editable.states.find((state) =>
      path.includes(state.path)
    );
    if (!currentComponentState) return;
    const { componentState: draft } = currentComponentState;
    const files = draft.files.filter((file) => file.id !== fileId);

    const componentState: ExtendedEmailDraft = {
      ...draft,
      files,
    };

    dispatch(EditableActions.updateComponentState({ path, componentState }));
  };
};

const getDraft = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Drafts.setDraftStatus(REQUEST.IDLE));

    setTimeout(() => {
      dispatch(EmailActions.Drafts.setDraftStatus(REQUEST.SUCCESS));
    }, 150);
  };
};

const saveCommunicationLogToDraft = (
  linkedRelations: RelationSnapShot[],
  linkedAssignments: AssignmentSnapShot[],
  draft: ExtendedEmailDraft,
  communicationLog?: CommunicationLog
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const CommunicationLog = new CommunicationLogsClient(host);

    try {
      // Is New
      if (!communicationLog) {
        communicationLog = await parseRequest.response(
          CommunicationLog.defineNew({}, realEstateAgencyId).then(
            (response) => response.communicationLog
          ),
          ApiType.Email
        );
      }

      communicationLog = {
        ...communicationLog,
        subject: draft.subject,
        date: new Date(),
        linkedRelations,
        linkedAssignments,
      };

      const saveResponse = await parseRequest.response(
        CommunicationLog.save({ communicationLog }, realEstateAgencyId),
        ApiType.Email
      );

      return saveResponse.communicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const reUploadFilesForAccount = (
  files: File[],
  accountId: string,
  id: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(DraftsActions.setDraftStatus(REQUEST.PENDING));

    let state = getState();
    const { emailHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Files = new FilesClient(emailHost);

    try {
      const uploadedFiles = [];
      const chunks = chunk(files, 5);

      for (let i = 0; i < chunks.length; i++) {
        const promises = chunks[i].map((file) =>
          parseRequest
            .response(
              Files.upload(
                {
                  accountId,
                  fileName: FileUtil.sanatizeFilename(file.fileName),
                  fileUri: file.uri,
                },
                realEstateAgencyId
              )
            )
            .then((uploadedFile) => {
              uploadedFiles.push(
                UploadUtil.mapMailUploadResponseToEmailFile(
                  uploadedFile,
                  file.isAttachment
                )
              );
            })
        );

        await Promise.all(promises);
      }

      state = getState();
      const path = route(EMAILROUTES.CREATE.URI, { id });
      const currentComponentState = state.editable.states.find((state) =>
        path.includes(state.path)
      );

      if (!currentComponentState) return;
      const { componentState: draft } = currentComponentState;
      const componentState: ExtendedEmailDraft = {
        ...draft,
        files: uploadedFiles,
      };

      dispatch(EditableActions.updateComponentState({ path, componentState }));
      dispatch(DraftsActions.setDraft(componentState));
      return componentState;
    } catch (error) {
      dispatch(DraftsActions.setDraftStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export const EmailDraftThunks = {
  saveDraft,
  getDrafts,
  readDraft,
  sendDraft,
  deleteDraft,
  saveAndCloseDraft,
  appendFile,
  removeFile,
  getDraft,
  getDraftsForAccountAndFolder,
  saveCommunicationLogToDraft,
  setDraftFiles,
  setDraftAttachments,
  reUploadFilesForAccount,
};
