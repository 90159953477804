import * as React from "react";
import { FC, memo } from "react";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { EmailAddress } from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  emailAddresses: EmailAddress[];
  visible: boolean;
  onClose: () => void;
  onSelect: (email: string | null) => void;
};
export const ChooseEmailModalComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, emailAddresses, onClose, onSelect }) => {
      const selectEmailAddress = (emailAddress: EmailAddress) => {
        onSelect(emailAddress.address);
      };

      return (
        <Modal visible={visible} onClose={onClose}>
          <ModalHeader title="chooseEmail.modal.title" close />
          <ModalBody>
            <div styleName="email-adresses">
              {emailAddresses.map((email) => (
                <div
                  styleName="email"
                  onClick={() => selectEmailAddress(email)}
                >
                  <div styleName="type">
                    <I18n
                      prefix="emailAddressTypes"
                      value={email.type.toString()}
                    />
                  </div>
                  <div styleName="address">{email.address}</div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      );
    }
  )
);
