import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";
import { NavLink } from "react-router-dom";
import { RouteUtil } from "@haywork/util";
import { APPXCHANGEROUTES } from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  title: string;
  appClientId: string;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsWidgetModalHeader extends React.Component<
  Props,
  State
> {
  public static contextTypes = {
    close: PropTypes.func.isRequired
  };

  constructor(props: Props) {
    super(props);
    this.close = this.close.bind(this);
  }

  public render() {
    return (
      <div styleName="header">
        <div styleName="title">{this.props.title}</div>
        {!!this.props.appClientId && (
          <div styleName="support">
            <NavLink
              to={route(APPXCHANGEROUTES.SUPPORT.URI, {
                id: this.props.appClientId
              })}
              onClick={this.close}
            >
              <ResourceText resourceKey="authorizationModal.supportLink" />
            </NavLink>
          </div>
        )}
        <div styleName="close" onClick={this.close}>
          <i className="fal fa-fw fa-times" />
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.appClientId !== this.props.appClientId)
      this.setState(nextProps);
  }

  public close() {
    if (this.context.close) this.context.close();
  }
}
