import I18n, { Translateable } from "@haywork/components/i18n";
import { Input } from "@haywork/modules/form";
import isString from "lodash-es/isString";
import * as React from "react";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue, SingleFilterConfig } from "../../";
import { ListFilterContext } from "../../list-filter.context";

const styles = require("./style.scss");
export const SelectEmptyValue = "no-option-selected";

type Props = {
  configKey: string;
  values: SingleFilterValue[];
  title?: Translateable;
  disabled?: boolean;
  onChange?: (updatedFilter: SingleFilterConfig) => void;
};

export const SelectComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ configKey, values, title, disabled, onChange }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const handleInputChange = useCallback(
        (value: any) => {
          if (!filter) return;

          const updatedFilter = {
            ...filter,
            value: value === SelectEmptyValue ? undefined : value,
          };
          if (onChange) {
            onChange(updatedFilter);
          }
          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, onFilterChange, onChange]
      );

      const value = useMemo(() => {
        if (!filter || !filter.value) return SelectEmptyValue;
        return filter.value;
      }, [filter]);

      if (!filter) return null;

      return (
        <div>
          {!!label && <h2>{label}</h2>}
          <Input.NewSelect
            name={configKey}
            value={value}
            values={values}
            onChange={handleInputChange}
            asSingleInput
            valueOutMapper={(value) => value.value}
            valuesProp="value"
            translate
            translateValue={(value) =>
              isString(value.label) ? value.label : value.label.value
            }
            translateValues={(value) =>
              isString(value.label) ? {} : value.label.values
            }
            disabled={disabled}
          />
        </div>
      );
    }
  )
);
