import * as PropTypes from "prop-types";
import * as React from "react";
import { createContext } from "react";
import debounce from "lodash-es/debounce";
import noop from "lodash-es/noop";

type FilterFieldValue = string | string[] | boolean | boolean[];
interface FilterField {
  name: string;
  value: FilterFieldValue;
}
interface Props {
  changeFilter: (values: any) => void;
}

export type FilterContextType = {
  updateField: (name: string, value: FilterFieldValue) => void;
  registerField: (name: string, value: FilterFieldValue) => void;
  unRegisterField: (name: string) => void;
};

export const FilterContext = createContext<FilterContextType>({
  updateField: noop,
  registerField: noop,
  unRegisterField: noop
});

export class Filter extends React.Component<Props> {
  public static childContextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired
  };
  public fields: FilterField[] = [];

  constructor(props) {
    super(props);

    this.updateField = this.updateField.bind(this);
    this.registerField = this.registerField.bind(this);
    this.unRegisterField = this.unRegisterField.bind(this);
    this.changeFilterHandler = debounce(
      this.changeFilterHandler.bind(this),
      50
    );
  }

  public getChildContext() {
    return {
      updateField: this.updateField,
      registerField: this.registerField,
      unRegisterField: this.unRegisterField
    };
  }

  public render() {
    return (
      <FilterContext.Provider
        value={{
          updateField: this.updateField,
          registerField: this.registerField,
          unRegisterField: this.unRegisterField
        }}
      >
        <div className="filter-cmp">{this.props.children}</div>
      </FilterContext.Provider>
    );
  }

  public updateField(name: string, value: FilterFieldValue) {
    const match = this.fields.filter((field) => field.name === name);
    if (match.length === 0) throw new Error("Field is not registered");
    const field = match[0];
    field.value = value;
    this.changeFilterHandler();
  }

  public registerField(name: string, value: FilterFieldValue) {
    const match = this.fields.filter((field) => field.name === name);
    if (match.length > 0) throw new Error("Field name already exists");
    this.fields.push({
      name,
      value: value || null
    });
  }

  public unRegisterField(name: string) {
    this.fields = this.fields.filter((field) => field.name !== name);
  }

  private mapFilterValues(): any {
    const values = {};
    this.fields.forEach((field) => {
      values[field.name] = field.value;
    });
    return values;
  }

  private changeFilterHandler() {
    this.props.changeFilter(this.mapFilterValues());
  }
}
