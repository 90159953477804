import { AppClient, AppClientTranslation } from "@haywork/api/authorization";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AppXchangeCostsComponent,
  AppXchangeCostsComponentProps,
} from "@haywork/modules/app-xchange";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { translation } from "./selectors";
import { PhotoBlob } from "@haywork/api/kolibri";

interface StateProps {
  client: AppClient;
  translation: AppClientTranslation;
}
interface DispatchProps {
  showLightbox: (slide: PhotoBlob) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AppXchangeCostsComponentProps,
  AppState
> = (state) => {
  const { client } = state.authorization.singleAppClient;

  return {
    client,
    translation: translation(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AppXchangeCostsComponentProps
> = (dispatch) => ({
  showLightbox: (slide: PhotoBlob) =>
    dispatch(
      LayoutActions.showLightbox({
        lightboxSlides: [slide],
        lightboxCurrent: 0,
      })
    ),
});

export type AppXchangeCostsContainerProps = StateProps & DispatchProps;
export const AppXchangeCostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppXchangeCostsComponent);
