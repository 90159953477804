import { SearchLocation, SearchLocationClient } from "@haywork/api/mls";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { Dispatch } from "react-redux";
import { MlsUtil } from "@haywork/util";

const parseRequest = new ParseRequest();

const getSearchLocations = (forceFilterUpdate = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new SearchLocationClient(mlsHost);
      const take = 100;

      const response = await parseRequest.response(
        client.search({ skip: 0, take }, realEstateAgencyId)
      );

      if (!response) throw new Error("Search locations could not be found");
      const { totalCount, result } = response;
      let searchLocations = result || [];

      if (totalCount > searchLocations.length) {
        const diff = totalCount - searchLocations.length;
        const count = Math.ceil(diff / take);
        const promises: Promise<SearchLocation[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            client
              .search(
                {
                  skip: i * take,
                  take,
                },
                realEstateAgencyId
              )
              .then((response) => response.result || [])
          );
        }

        const response = await Promise.all(promises);
        const results = response.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <SearchLocation[]>[]);

        searchLocations = [...searchLocations, ...results];
      }

      dispatch(MLSActions.SearchLocations.setSearchLocations(searchLocations));

      if (!!forceFilterUpdate) {
        const defaultLocations = (searchLocations || []).filter(
          (location) => !!location.isDefault
        );
        if (!!defaultLocations.length) {
          const { filters } = state.mls.list;
          dispatch(
            MLSActions.List.setFilters(
              MlsUtil.resetFilterToDefaultValues(filters)
            )
          );
          dispatch(MLSActions.List.setDefaultSearchLocations(defaultLocations));
        }
      }

      return searchLocations;
    } catch (error) {
      throw error;
    }
  };
};

const createNewSearchLocation = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new SearchLocationClient(mlsHost);

      let searchLocation = await parseRequest.response(
        client
          .defineNew({ name: "..." }, realEstateAgencyId)
          .then((response) => response.searchLocation)
      );
      if (!searchLocation)
        throw new Error("Search location could not be created");

      searchLocation = {
        ...searchLocation,
        name: "", // Reset to blank name for UI
      };

      return searchLocation;
    } catch (error) {
      throw error;
    }
  };
};

const batchUpdate = (
  deletedItems: SearchLocation[],
  newItems: SearchLocation[],
  updatedItems: SearchLocation[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new SearchLocationClient(mlsHost);

      const promises = [] as Promise<any>[];

      deletedItems.forEach((searchLocation) => {
        promises.push(
          parseRequest.response(
            client.delete(searchLocation.id, realEstateAgencyId)
          )
        );
      });

      newItems.forEach((searchLocation) => {
        promises.push(
          parseRequest.response(
            client.save({ searchLocation }, realEstateAgencyId)
          )
        );
      });

      updatedItems.forEach((searchLocation) => {
        promises.push(
          parseRequest.response(
            client.save({ searchLocation }, realEstateAgencyId)
          )
        );
      });

      await Promise.all(promises);
      await dispatch(getSearchLocations(true));
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getSearchLocations,
  createNewSearchLocation,
  batchUpdate,
};
