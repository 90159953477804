import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditMediaPhotosScreenContainerProps } from "./assignments-edit-media-photos.container";
import { PhotosComponent } from "@haywork/modules/shared";
import { SortEnd } from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
const styles = require("./assignments-edit-media-photos.scss");

export type AcquistionAssignmentEditMediaPhotosScreenProps = {};
type Props = AcquistionAssignmentEditMediaPhotosScreenProps &
  AcquistionAssignmentEditMediaPhotosScreenContainerProps;

export const AcquistionAssignmentEditMediaPhotosScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ currentComponentState, updateAcquisitionAssignmentObject, path }) => {
      let { photos } = currentComponentState;
      photos = photos || [];

      const uploadCompleteHandler = (files: any[]) => {
        const photoArray = currentComponentState.photos || [];
        const photos = files.filter((f) => !!f);
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          photos: [...photoArray, ...photos],
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const filesDeletedHandler = (idx: number) => {
        const photos = currentComponentState.photos.filter((p, k) => k !== idx);
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          photos,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const sortedHandler = (sort: SortEnd) => {
        const photos = arrayMoveImmutable(
          currentComponentState.photos,
          sort.oldIndex,
          sort.newIndex
        );
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          photos,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const filesEditedHandler = (photos: any[]) => {
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          photos,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      return (
        <div>
          <PhotosComponent
            photos={photos}
            path={path}
            onUploadCompleteHandler={uploadCompleteHandler}
            fileDeleted={filesDeletedHandler}
            sorted={sortedHandler}
            fileEdited={filesEditedHandler}
            showMainPhotoLabel={true}
          />
        </div>
      );
    }
  )
);
