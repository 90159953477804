import {
  AssignmentPhase,
  LinkedRelation,
  MatchMailPeriod,
  RelationType,
  SearchAssignment,
} from "@haywork/api/kolibri";
import {
  RELATIONROUTES,
  REQUEST,
  SEARCHASSIGNMENTROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FormReturnValue } from "@haywork/modules/form";
import {
  SearchAssignmentPublishContainerProps,
  SearchAssignmentWidgetLocationsComponent,
} from "@haywork/modules/search-assignment";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const styles = require("./publish.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentPublishComponentProps {}
interface SearchAssignmentPublishComponentState {
  showConfirmModal: boolean;
  fromSubmit: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentPublishComponent extends React.Component<
  SearchAssignmentPublishComponentProps & SearchAssignmentPublishContainerProps,
  SearchAssignmentPublishComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmModal: false,
      fromSubmit: false,
    };

    this.onNavigateClickHandler = this.onNavigateClickHandler.bind(this);
    this.renderSearchAmountListItem =
      this.renderSearchAmountListItem.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onConformModalCloseHandler =
      this.onConformModalCloseHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.renderSingleLinkedRelation =
      this.renderSingleLinkedRelation.bind(this);
  }

  public render() {
    const { locations } = this.props.searchAssignment;
    const hasLocations = !!locations.length;

    return (
      <div styleName="publish">
        <div className="container-fluid">
          <h1>
            <ResourceText resourceKey="activateStarting" />
          </h1>

          {hasLocations && (
            <div styleName="publish__info">
              <ErrorBoundary>
                <div styleName="body__section">
                  <SearchAssignmentWidgetLocationsComponent
                    searchAssignment={this.props.searchAssignment}
                  />
                </div>
              </ErrorBoundary>
            </div>
          )}

          <div styleName="publish__list">
            {this.renderSearchAmountListItem()}
            {this.renderSearchersListItem()}
            {this.renderMatchmailListItem()}
          </div>

          <div styleName="publish__form">
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <button
                    type="submit"
                    className="btn btn-primary emphasize"
                    onClick={this.onPublishClickHandler}
                    disabled={!hasLocations}
                  >
                    <ResourceText resourceKey="publishSearchProfile" />
                  </button>
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <div
                    className="btn btn-white"
                    onClick={this.onSaveClickHandler}
                  >
                    <ResourceText resourceKey="saveForLater" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SearchAssignmentPublishComponentProps &
      SearchAssignmentPublishContainerProps
  ) {
    if (
      nextProps &&
      nextProps.publishState === REQUEST.ERROR &&
      this.state.showConfirmModal === true &&
      !this.state.fromSubmit
    ) {
      this.setState({ showConfirmModal: false });
    }
  }

  private renderSearchAmountListItem(): React.ReactElement<HTMLDivElement> {
    const count = this.props.searchAssignment.locations
      ? this.props.searchAssignment.locations.length
      : 0;
    const editRoute = route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, {
      id: this.props.searchAssignment.id,
    });

    const iconType = classNames(
      "fa-fw",
      count === 0 ? "fa fa-exclamation-triangle" : "fal fa-map-marker-alt"
    );
    const iconStyle = classNames("listitem__icon", { danger: count === 0 });
    const btnStyle = classNames(
      "btn",
      count === 0 ? "btn-danger" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          <ResourceText resourceKey="searchInPlaces" values={{ count }} />
        </div>
        {!this.props.searchAssignment.foreignId && (
          <div styleName="listitem__action">
            <div
              className={btnStyle}
              onClick={() => this.onNavigateClickHandler(editRoute)}
            >
              <ResourceText resourceKey="adjust" />
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderSearchersListItem(): React.ReactElement<HTMLDivElement> {
    const { linkedRelations } = this.props.searchAssignment;
    const count = linkedRelations ? linkedRelations.length : 0;
    const editRoute = route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, {
      id: this.props.searchAssignment.id,
    });

    const iconType = classNames(
      "fa-fw",
      count === 0 ? "fa fa-exclamation-triangle" : "fal fa-user-circle"
    );
    const iconStyle = classNames("listitem__icon", { warn: count === 0 });
    const btnStyle = classNames(
      "btn",
      count === 0 ? "btn-warning" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          {linkedRelations && linkedRelations.length > 0 ? (
            <span>
              <ResourceText resourceKey="willSearchFor" />{" "}
              {linkedRelations.map(this.renderSingleLinkedRelation)}
            </span>
          ) : (
            <ResourceText resourceKey="noLinkedRelations" />
          )}
        </div>
        <div styleName="listitem__action">
          <div
            className={btnStyle}
            onClick={() => this.onNavigateClickHandler(editRoute)}
          >
            <ResourceText resourceKey="adjust" />
          </div>
        </div>
      </div>
    );
  }

  private renderSingleLinkedRelation(
    linkedRelation: LinkedRelation,
    idx: number
  ) {
    const count = this.props.searchAssignment.linkedRelations.length;
    const relation = this.renderLinkedRelation(linkedRelation);
    let connector: any = "";

    if (count > 1 && idx < count - 2) {
      connector = ", ";
    }
    if (count > 1 && count - 2 === idx) {
      connector = (
        <span>
          {" "}
          <ResourceText resourceKey="and" />{" "}
        </span>
      );
    }

    return (
      <span key={idx}>
        {relation}
        {connector}
      </span>
    );
  }

  private renderMatchmailListItem(): React.ReactElement<HTMLDivElement> {
    const { matchMailPeriod } = this.props.searchAssignment;
    const editRoute = route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, {
      id: this.props.searchAssignment.id,
    });

    const iconType = classNames(
      "fa-fw",
      matchMailPeriod === MatchMailPeriod.Never
        ? "fa fa-exclamation-triangle"
        : "fal fa-envelope-square"
    );
    const iconStyle = classNames("listitem__icon", {
      warn: matchMailPeriod === MatchMailPeriod.Never,
    });
    const btnStyle = classNames(
      "btn",
      matchMailPeriod === MatchMailPeriod.Never ? "btn-warning" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          {this.renderMatchmailPeriod(matchMailPeriod)}
        </div>
        <div styleName="listitem__action">
          <div
            className={btnStyle}
            onClick={() => this.onNavigateClickHandler(editRoute)}
          >
            <ResourceText resourceKey="adjust" />
          </div>
        </div>
      </div>
    );
  }

  private renderLinkedRelation(linkedRelation: LinkedRelation): JSX.Element {
    const relationRoute =
      linkedRelation.typeOfRelation === RelationType.ContactPerson
        ? route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
            id: linkedRelation.id,
          })
        : route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
            id: linkedRelation.id,
          });

    return (
      <Link
        to={relationRoute}
        key={linkedRelation.id}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {linkedRelation.displayName}
      </Link>
    );
  }

  private renderMatchmailPeriod(matchmailPeriod: MatchMailPeriod) {
    switch (matchmailPeriod) {
      case MatchMailPeriod.Never:
        return <ResourceText resourceKey="matchmailNeverSent" />;
      case MatchMailPeriod.Daily:
        return <ResourceText resourceKey="matchmailSentDaily" />;
      case MatchMailPeriod.Weekly:
        return <ResourceText resourceKey="matchmailSentWeekly" />;
      default:
        break;
    }
  }

  private onNavigateClickHandler(route: string) {
    this.props.navigate(route);
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.setState({ showConfirmModal: true, fromSubmit: true }, () => {
      this.setState({ fromSubmit: false });
    });
  }

  private onConformModalCloseHandler() {
    this.setState({ showConfirmModal: false });
  }

  private onPublishClickHandler() {
    const searchAssignment: SearchAssignment = {
      ...this.props.searchAssignment,
      assignmentPhase: AssignmentPhase.Initiated,
    };

    this.props.saveSearchAssignment(searchAssignment);
  }

  private onSaveClickHandler() {
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.props.saveSearchAssignment(this.props.searchAssignment);
  }
}
