import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDropTarget,
  DropTargetCollector,
  DropTarget,
  DropTargetSpec,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type SettingsCompanySettingsDropAreaComponentProps = {
  onDrop: (file: File) => void;
};
type Props = SettingsCompanySettingsDropAreaComponentProps & {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
};

const SettingsCompanySettingsDropAreaComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ connectDropTarget, isOver, children }) => {
      if (!connectDropTarget) return null;

      return connectDropTarget(
        <div styleName={classNames("logo__drop-area", { "is-over": isOver })}>
          {isOver ? (
            <div styleName="drop-hint">
              <I18n value="settings.companySettings.logo.dropHint" />
            </div>
          ) : (
            children
          )}
        </div>
      );
    }
  )
);

const dropSpec: DropTargetSpec<SettingsCompanySettingsDropAreaComponentProps> =
  {
    drop: (props, monitor) => {
      const { files } = monitor.getItem() as { files: File[] };
      props.onDrop(files[0]);
    },
  };

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default DropTarget(
  [NativeTypes.FILE],
  dropSpec,
  dropCollect
)(SettingsCompanySettingsDropAreaComponent);
