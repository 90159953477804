import { ErrorResponse as KolibriErrorResponse } from "@haywork/api/kolibri";
import { ErrorResponse as MailErrorResponse } from "@haywork/api/mail";
import { Dispatch } from "@haywork/middleware";
import { ErrorComponent, ErrorComponentProps } from "@haywork/modules/error";
import { AppState, ErrorActions } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  response: KolibriErrorResponse | MailErrorResponse | any;
  persistent: boolean;
}
interface DispatchProps {
  removeError: () => void;
}

const mapStateToProps = <StateProps, ErrorComponentProps>(state: AppState) => {
  const { response, persistent } = state.error;

  return {
    response,
    persistent
  };
};

const mapDispatchToProps = <DispatchProps, ErrorComponentProps>(
  dispatch: Dispatch<any>
) => ({
  removeError: () => dispatch(ErrorActions.removeError())
});

export type ErrorContainerProps = StateProps & DispatchProps;
export const ErrorContainer = connect<
  StateProps,
  DispatchProps,
  ErrorComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ErrorComponent);
