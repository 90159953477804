import { AssignmentOrderByField, SortOrder } from "@haywork/api/kolibri";
import { OrderBy } from "@haywork/api/mls";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import List, {
  ActiveFilters,
  FilterConfig,
  ListColumnConfig,
  ListRefProps,
  ListWrapper,
} from "@haywork/components/ui/list";
import PrintLoader from "@haywork/components/ui/print-loader";
import { MAINROUTES, REQUEST } from "@haywork/constants";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import ManageFiltersModal from "@haywork/modules/mls/modals/manage-filters";
import Onboarding from "@haywork/modules/mls/modals/onboarding";
import PriceHistoryModal from "@haywork/modules/mls/modals/price-history";
import PublicationHistoryModal from "@haywork/modules/mls/modals/publication-history";
import RealtorInfoModal from "@haywork/modules/mls/modals/realtor";
import SaveFilterModal from "@haywork/modules/mls/modals/save-filter";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import LocationsModal from "../../modals/locations";
import Actions, { MlsListAction } from "./components/actions";
import Filters from "./components/filters";
import Row from "./components/row";
import { ListContainerProps } from "./list.container";

import { useRealEstatePropertyValueGroups } from "../../helpers";
import { TutorialComponent } from "../../modals/tutorial/tutorial";

const styles = require("./style.scss");
const ROWHEIGHT = 178;

export type ListComponentProps = {};
type Props = ListComponentProps & ListContainerProps;
export type FilterModalData = {
  savedFilters: MlsFilter[];
  filters: FilterConfig;
};

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      assignments,
      requestStatus,
      totalCount,
      order,
      hasSelectedIds,
      mlsAccessData,
      filters,
      scrollOffset,
      activeFilterGuidValues,
      agencyGroups,
      canSendEmail,
      setScrollOffset,
      setFilters,
      setOrdering,
      navigate,
      setSelectedIdsFilter,
      getListItems,
      mailSelection,
      bulkFollow,
      printRealEstateProperties,
      getPropertiesById,
    }) => {
      const intl = useIntl();
      const listRef = useRef<ListRefProps>();
      const [realtorInfoModal, setRealtorInfoModal] = useState({
        isVisible: false,
        data: {},
      });
      const [priceHistoryModal, setPriceHistoryModal] = useState({
        isVisible: false,
        data: [],
      });
      const [publicationsHistoryModal, setPublicationsHistoryModal] = useState({
        isVisible: false,
        data: [],
      });
      const [locationsModal, setLocationsModal] = useState({
        isVisible: false,
        data: [],
      });
      const [selectedIds, setSelectedIds] = useState([] as string[]);
      const [saveFilterModal, setSaveFilterModal] = useState<{
        isVisible: boolean;
        data: FilterModalData;
      }>({
        isVisible: false,
        data: { filters: {}, savedFilters: [] },
      });
      const [showManageFiltersModal, setShowManageFiltersModal] =
        useState(false);
      const [printLoading, setPrintLoading] = useState(false);
      const [mlsIsActive, setMlsIsActive] = useState(mlsAccessData?.isActive);
      const [tutorialIsActive, setTutorialIsActive] = useState(false);

      useRealEstatePropertyValueGroups(null, intl);

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: "",
              width: 32,
            },
            {
              label: {
                value: "totalCount",
                values: { count: totalCount },
              },
              width: 165,
            },
            {
              label: "address",
              width: 240,
              sortConfig: [
                OrderBy.Address,
                OrderBy.PostalCode,
                OrderBy.RealtorName,
              ],
            },
            {
              label: "status",
              width: 240,
              sortConfig: [
                OrderBy.AvailablilityStatus,
                OrderBy.PurchasePrice,
                OrderBy.RentPrice,
                OrderBy.PriceChanges,
                OrderBy.PricePerSquareMeter,
              ],
            },
            {
              label: "dates",
              width: 240,
              autoFill: true,
              sortConfig: [OrderBy.MandateDate, OrderBy.ModificationDate],
            },
            {
              label: "measurements",
              width: 240,
              sortConfig: [
                OrderBy.EffectiveArea,
                OrderBy.LandArea,
                OrderBy.LivingRoom,
              ],
            },
            {
              label: "construction",
              width: 240,
              sortConfig: [OrderBy.ConstructionYear],
            },
            {
              label: "layers",
              width: 240,
              sortConfig: [
                OrderBy.NumberOfBedrooms,
                OrderBy.NumberOfFloors,
                OrderBy.NumberOfRooms,
              ],
            },
            {
              label: "energy",
              width: 240,
              sortConfig: [OrderBy.EnergyClass],
            },
            {
              label: "outside",
              width: 240,
              sortConfig: [
                OrderBy.GardenOrientation,
                OrderBy.GardenQuality,
                OrderBy.GardenSurface,
                OrderBy.BackyardSurface,
              ],
            },
            {
              label: "garage",
              width: 240,
              sortConfig: [OrderBy.GarageCapacity, OrderBy.GarageType],
            },
          ],
        } as ListColumnConfig;
      }, [totalCount]);

      const resetSelectedIds = useCallback(
        (refresh = false) => {
          setSelectedIdsFilter([]);
          setSelectedIds([]);
          if (refresh) {
            listRef.current.refresh();
          }
        },
        [setSelectedIdsFilter, setSelectedIds]
      );

      const onSortChange = useCallback(
        (sortColumn: AssignmentOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const toggleSelectedCallback = useCallback(
        (id: string) => {
          setSelectedIds((selectedIds) => {
            if (selectedIds.includes(id)) {
              if (selectedIds.length === 1) {
                resetSelectedIds(true);
              }
              return selectedIds.filter((selectedId) => selectedId !== id);
            } else {
              return [...selectedIds, id];
            }
          });
        },
        [setSelectedIds, resetSelectedIds]
      );

      const showLocationsOnMap = useCallback(async () => {
        try {
          const assignments = await getPropertiesById(selectedIds);
          const data = assignments.filter(
            (assignment) => !!assignment && selectedIds.includes(assignment.id)
          );

          setLocationsModal({ isVisible: true, data });
        } catch (error) {
          throw error;
        }
      }, [selectedIds, getPropertiesById, setLocationsModal]);

      const printSelection = useCallback(async () => {
        try {
          setPrintLoading(true);
          const assignments = await getPropertiesById(selectedIds);
          const data = assignments.filter(
            (assignment) => !!assignment && selectedIds.includes(assignment.id)
          );

          await printRealEstateProperties(data, intl);
        } finally {
          setPrintLoading(false);
        }
      }, [
        selectedIds,
        getPropertiesById,
        printRealEstateProperties,
        intl,
        setPrintLoading,
      ]);

      const createRoute = useCallback(async () => {
        try {
          const assignments = await getPropertiesById(selectedIds);
          const locations = assignments
            .filter(
              (assignment) =>
                !!assignment &&
                selectedIds.includes(assignment.id) &&
                !!assignment?.address?.coordinates?.latitude &&
                !!assignment?.address?.coordinates?.longitude
            )
            .map((assignment) =>
              [
                assignment?.address?.coordinates?.latitude,
                assignment?.address?.coordinates?.longitude,
              ].join(",")
            )
            .join("/");

          const route = `https://www.google.nl/maps/dir/${locations}`;
          window.open(route, "_blank");
        } catch (error) {
          throw error;
        }
      }, [selectedIds, getPropertiesById]);

      const actionClickCallback = useCallback(
        (action: MlsListAction) => {
          switch (action) {
            case MlsListAction.Clear: {
              resetSelectedIds(true);
              return;
            }
            case MlsListAction.CreateRoute: {
              createRoute();
              return;
            }
            case MlsListAction.Email: {
              mailSelection(selectedIds, intl);
              return;
            }
            case MlsListAction.ExportToExcel: {
              // TODO
              return;
            }
            case MlsListAction.ExportToPDF: {
              // TODO
              return;
            }
            case MlsListAction.Follow: {
              bulkFollow(selectedIds, intl);
              return;
            }
            case MlsListAction.ShowOnMap: {
              showLocationsOnMap();
              return;
            }
            case MlsListAction.Print: {
              printSelection();
              return;
            }
            default: {
              return;
            }
          }
        },
        [
          resetSelectedIds,
          selectedIds,
          showLocationsOnMap,
          createRoute,
          bulkFollow,
          intl,
        ]
      );

      const toggleSelectedIdsCallback = useCallback(() => {
        setSelectedIdsFilter(hasSelectedIds ? [] : selectedIds);
        listRef.current.refresh();
      }, [setSelectedIdsFilter, selectedIds, hasSelectedIds]);

      const closeLocationsModal = useCallback(() => {
        setLocationsModal({ isVisible: false, data: [] });
      }, [setLocationsModal]);

      useEffect(() => {
        if (requestStatus === REQUEST.SUCCESS)
          setMlsIsActive(mlsAccessData?.isActive);
      }, [setMlsIsActive, mlsAccessData, requestStatus]);

      return (
        <div styleName="list">
          {requestStatus === REQUEST.PENDING && <Ui.Loaders.List />}
          {requestStatus === REQUEST.SUCCESS && (
            <>
              <ListWrapper
                sidebar={
                  <Filters
                    list={listRef}
                    setSaveFilterModal={setSaveFilterModal}
                    showManageFiltersModal={setShowManageFiltersModal}
                    onClearFilters={resetSelectedIds}
                  />
                }
                sidebarWide
                filters={
                  <ActiveFilters
                    prefix="mls"
                    filters={filters}
                    guidValues={activeFilterGuidValues}
                    fixedWhitelist={["realEstateGroups"]}
                    prefixBlacklist={[
                      "searchLocations",
                      "groups",
                      "postalCodeRanges",
                    ]}
                    onChange={onActiveFiltersChange}
                  />
                }
                actions={
                  !!selectedIds.length && (
                    <>
                      <Button
                        label={
                          hasSelectedIds
                            ? "mls.listAction.showAll"
                            : "mls.listAction.showOnlySelection"
                        }
                        labelValues={{ count: selectedIds.length }}
                        category={hasSelectedIds ? "success" : "white"}
                        onClick={toggleSelectedIdsCallback}
                      />
                      <Actions
                        count={selectedIds.length}
                        selectedIds={selectedIds}
                        canSendEmail={canSendEmail}
                        onClick={actionClickCallback}
                      />
                    </>
                  )
                }
              >
                {mlsIsActive && (
                  <List
                    name="mls"
                    rowHeight={ROWHEIGHT}
                    columnConfig={columnConfig}
                    data={assignments}
                    totalCount={totalCount}
                    sortColumn={order.sortColumn}
                    sortOrder={order.sortOrder}
                    loadMore={getListItems}
                    allowCancel={true}
                    minimumBatchSize={50}
                    onSortChange={onSortChange}
                    initialScrollOffset={scrollOffset}
                    onScroll={setScrollOffset}
                    ref={listRef}
                    emptyState={
                      <EmptyState
                        icon="exchange"
                        title="mls.list.emptyStateTitle"
                        subTitle="mls.list.emptyStateSubtitle"
                      />
                    }
                  >
                    {(data: any | null, idx) => (
                      <Row
                        data={data}
                        zebra={idx % 2 === 0}
                        selectedIds={selectedIds}
                        agencyGroups={agencyGroups}
                        setRealtorInfoModal={setRealtorInfoModal}
                        setPriceHistoryModal={setPriceHistoryModal}
                        setPublicationsHistoryModal={
                          setPublicationsHistoryModal
                        }
                        onOpenDetail={navigate}
                        onToggleSelected={toggleSelectedCallback}
                      />
                    )}
                  </List>
                )}
              </ListWrapper>
            </>
          )}

          <RealtorInfoModal
            visible={realtorInfoModal.isVisible}
            onCloseHandler={() =>
              setRealtorInfoModal({ isVisible: false, data: {} })
            }
            data={realtorInfoModal.data}
          />

          <PriceHistoryModal
            visible={priceHistoryModal.isVisible}
            onCloseHandler={() =>
              setPriceHistoryModal({ isVisible: false, data: [] })
            }
            data={priceHistoryModal.data}
          />

          <PublicationHistoryModal
            visible={publicationsHistoryModal.isVisible}
            onCloseHandler={() =>
              setPublicationsHistoryModal({
                isVisible: false,
                data: [],
              })
            }
            data={publicationsHistoryModal.data}
          />

          <SaveFilterModal
            visible={saveFilterModal.isVisible}
            onClose={() =>
              setSaveFilterModal({
                isVisible: false,
                data: { filters: {}, savedFilters: [] },
              })
            }
            data={saveFilterModal.data}
          />

          <ManageFiltersModal
            visible={showManageFiltersModal}
            onClose={() => setShowManageFiltersModal(false)}
          />

          <LocationsModal
            visible={locationsModal.isVisible}
            assignments={locationsModal.data}
            onClose={closeLocationsModal}
          />
          <TutorialComponent
            visible={tutorialIsActive}
            tutorialVideoLink={"https://www.youtube.com/embed/xg-cU9vjHIw"}
            tutorialTitle={"mls.infoBar.tutorial.title"}
            tutorialSubText={"mls.infoBar.tutorial.subText"}
            onCloseHandler={() => setTutorialIsActive(false)}
          />
          <Onboarding
            visible={!mlsIsActive && requestStatus !== REQUEST.PENDING}
            onCloseHandler={() => {
              if (!mlsIsActive) navigate(MAINROUTES.DASHBOARD.URI);
            }}
          />
          <PrintLoader visible={printLoading} type="mls" />
        </div>
      );
    }
  )
);
