import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { DynamicDocumentsDownloadContainerProps } from "@haywork/modules/dynamic-documents";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DownloadMetaComponent } from "./meta.component";
import I18n from "@haywork/components/i18n";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./download.component.scss");

export interface DynamicDocumentsDownloadComponentProps {}
interface State {}
type Props = DynamicDocumentsDownloadComponentProps &
  DynamicDocumentsDownloadContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsDownloadComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onCloseClickHandler = this.onCloseClickHandler.bind(this);
    this.onDownloadClickHandler = this.onDownloadClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
  }

  public render() {
    const documentPreviewImage = require("static/document-preview.svg");

    return (
      <div styleName="download">
        <div styleName="download__inner">
          {/* Header */}
          <div styleName="header">
            <h1>
              <I18n value="documentIsReady" />
            </h1>
            <h2>{this.props.template.name}</h2>
          </div>

          {/* Body */}
          <div styleName="body">
            <div styleName="body__info">
              <h3>
                <I18n value="documentStoredIn" />
              </h3>
              {this.props.links.map((link, idx) => (
                <ErrorBoundary key={idx}>
                  <DownloadMetaComponent
                    link={link}
                    template={this.props.template}
                    linkedAssignment={this.props.session.linkedAssignment}
                  />
                </ErrorBoundary>
              ))}
            </div>
            <div styleName="body__preview">
              <img src={documentPreviewImage} />
            </div>
          </div>

          {/* Footer */}
          <div styleName="footer">
            <div
              className="btn btn-default-alt"
              onClick={this.onCloseClickHandler}
            >
              <I18n value="close" />
            </div>
            <div
              className="btn btn-primary"
              onClick={this.onDownloadClickHandler}
            >
              <I18n value="downloadDocument" />
            </div>

            <FeatureSwitch feature="WEBDAV">
              <div
                className="btn btn-primary"
                onClick={this.onEditClickHandler}
              >
                <I18n value="documentSession.editWithOffice" />
              </div>
            </FeatureSwitch>
          </div>
        </div>
      </div>
    );
  }

  private onCloseClickHandler() {
    const { id } = this.props.session;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id });

    this.props.closeDocument(path);
  }

  private onDownloadClickHandler() {
    const { id } = this.props.session;

    this.props.downloadDocument(id);
  }

  private onEditClickHandler() {
    const { id } = this.props.session;

    this.props.createDocumentSessionEditSession(id);
  }
}
