import { LinkedAssignment, LinkedRelation } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import FormattedText from "@haywork/components/ui/formatted-text";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import Assignment from "../network-assignment";
import Relation from "../network-widget-item";

const styles = require("./style.scss");

type Props = {
  linkedRelations: LinkedRelation[];
  linkedAcquisitionAssignment?: LinkedAssignment;
  linkedClients?: LinkedRelation[];
  linkedApplicants?: LinkedRelation[];
  linkedNotaries?: LinkedRelation[];
  linkedPropertyManagers?: LinkedRelation[];
  linkedAppraisers?: LinkedRelation[];
  linkedFinancialAdvisors?: LinkedRelation[];
  linkedInspectors?: LinkedRelation[];
  linkedContactPersons?: LinkedRelation[];
  linkedPurchasingBrokers?: LinkedRelation[];
  isForSale?: boolean;
  isAcquisitionAssignment?: boolean;
  title?: string;
  onGotoNetwork?: () => void;
  onNavigate: (path: string) => void;
};
export const NetworkWidgetComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      linkedClients,
      linkedAcquisitionAssignment,
      linkedRelations,
      linkedApplicants,
      linkedNotaries,
      linkedPropertyManagers,
      linkedAppraisers,
      linkedFinancialAdvisors,
      linkedInspectors,
      linkedContactPersons,
      linkedPurchasingBrokers,
      isForSale,
      isAcquisitionAssignment,
      onGotoNetwork,
      onNavigate,
    }) => {
      const maxLinkedRelations = 3;

      return (
        <div>
          <div styleName="row">
            <FormattedText
              value={
                isAcquisitionAssignment
                  ? "acquisitions.edit.whoarethevendors"
                  : !!isForSale
                  ? "acquisition.assignment.networkWidget.linkedRelationsSale"
                  : "acquisition.assignment.networkWidget.linkedRelationsRent"
              }
              values={{ count: linkedRelations.length }}
              asSection
            />

            {linkedRelations.slice(0, maxLinkedRelations).map((relation) => (
              <Relation
                relation={relation}
                relationType={relation.typeOfRelation}
                key={relation.id}
                onNavigate={onNavigate}
              />
            ))}

            {!linkedRelations.length && (
              <div styleName="empty-state">
                <I18n value="acquisition.linkedRelations.emptyState" />
              </div>
            )}
          </div>

          {linkedClients && linkedClients.length && (
            <div styleName="row">
              <FormattedText
                value={
                  !!isForSale
                    ? "acquisition.networkWidget.linkedRelations.forSale"
                    : "acquisition.networkWidget.linkedRelations.forRent"
                }
                values={{ count: linkedClients.length }}
                asSection
              />
              {linkedClients.slice(0, maxLinkedRelations).map((relation) => (
                <Relation
                  relation={relation}
                  relationType={relation.typeOfRelation}
                  key={relation.id}
                  onNavigate={onNavigate}
                />
              ))}
            </div>
          )}

          {linkedApplicants && linkedApplicants.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitleApplicants"}
                values={{ count: linkedApplicants.length }}
                asSection
              />
              {linkedApplicants.slice(0, maxLinkedRelations).map((relation) => (
                <Relation
                  relation={relation}
                  relationType={relation.typeOfRelation}
                  key={relation.id}
                  onNavigate={onNavigate}
                />
              ))}
            </div>
          )}

          {linkedNotaries && linkedNotaries.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitleNotaries"}
                values={{ count: linkedNotaries.length }}
                asSection
              />
              {linkedNotaries.slice(0, maxLinkedRelations).map((relation) => (
                <Relation
                  relation={relation}
                  relationType={relation.typeOfRelation}
                  key={relation.id}
                  onNavigate={onNavigate}
                />
              ))}
            </div>
          )}

          {linkedPropertyManagers && linkedPropertyManagers.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitlePropertyManagers"}
                values={{ count: linkedPropertyManagers.length }}
                asSection
              />
              {linkedPropertyManagers
                .slice(0, maxLinkedRelations)
                .map((relation) => (
                  <Relation
                    relation={relation}
                    relationType={relation.typeOfRelation}
                    key={relation.id}
                    onNavigate={onNavigate}
                  />
                ))}
            </div>
          )}

          {linkedAppraisers && linkedAppraisers.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitleAppraisers"}
                values={{ count: linkedAppraisers.length }}
                asSection
              />
              {linkedAppraisers.slice(0, maxLinkedRelations).map((relation) => (
                <Relation
                  relation={relation}
                  relationType={relation.typeOfRelation}
                  key={relation.id}
                  onNavigate={onNavigate}
                />
              ))}
            </div>
          )}

          {linkedFinancialAdvisors && linkedFinancialAdvisors.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={
                  "acquisition.networkWidget.networkTitleFinancialAdvisors"
                }
                values={{ count: linkedFinancialAdvisors.length }}
                asSection
              />
              {linkedFinancialAdvisors
                .slice(0, maxLinkedRelations)
                .map((relation) => (
                  <Relation
                    relation={relation}
                    relationType={relation.typeOfRelation}
                    key={relation.id}
                    onNavigate={onNavigate}
                  />
                ))}
            </div>
          )}

          {linkedInspectors && linkedInspectors.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitleInspectors"}
                values={{ count: linkedInspectors.length }}
                asSection
              />
              {linkedInspectors.slice(0, maxLinkedRelations).map((relation) => (
                <Relation
                  relation={relation}
                  relationType={relation.typeOfRelation}
                  key={relation.id}
                  onNavigate={onNavigate}
                />
              ))}
            </div>
          )}

          {linkedContactPersons && linkedContactPersons.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={"acquisition.networkWidget.networkTitleContactPersons"}
                values={{ count: linkedContactPersons.length }}
                asSection
              />
              {linkedContactPersons
                .slice(0, maxLinkedRelations)
                .map((relation) => (
                  <Relation
                    relation={relation}
                    relationType={relation.typeOfRelation}
                    key={relation.id}
                    onNavigate={onNavigate}
                  />
                ))}
            </div>
          )}

          {linkedPurchasingBrokers && linkedPurchasingBrokers.length > 0 && (
            <div styleName="row">
              <FormattedText
                value={
                  "acquisition.networkWidget.networkTitlePurchasingBrokers"
                }
                values={{ count: linkedPurchasingBrokers.length }}
                asSection
              />
              {linkedPurchasingBrokers
                .slice(0, maxLinkedRelations)
                .map((relation) => (
                  <Relation
                    relation={relation}
                    relationType={relation.typeOfRelation}
                    key={relation.id}
                    onNavigate={onNavigate}
                  />
                ))}
            </div>
          )}

          {!!linkedAcquisitionAssignment && (
            <div styleName="row">
              <FormattedText
                value="acquisition.networkWidget.linkedAcquisitionAssignment"
                asSection
              />
              <Assignment
                assignment={linkedAcquisitionAssignment}
                onNavigate={onNavigate}
              />
            </div>
          )}

          {!!onGotoNetwork && (
            <span className="as-link" onClick={onGotoNetwork}>
              <I18n value="acquisition.gotoNetwork" />
            </span>
          )}
        </div>
      );
    }
  )
);
