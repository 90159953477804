import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import isString from "lodash-es/isString";

import { ExternalPortal } from "@haywork/portals";

import { UiWindowFrameContainerProps } from "./window-frame.container";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./window-frame.component.scss");

export interface UiWindowFrameComponentProps {}
interface UiWindowFrameComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class UiWindowFrameComponent extends React.Component<
  UiWindowFrameComponentProps & UiWindowFrameContainerProps,
  UiWindowFrameComponentState
> {
  public render() {
    const wrapperStyle = classNames("window-frame__wrapper", {
      visible: this.props.windowFrameVisible,
    });
    const { key, values } = isString(this.props.windowFrameTitle)
      ? { key: this.props.windowFrameTitle, values: {} }
      : this.props.windowFrameTitle;

    if (!this.props.windowFramePath) return;

    return (
      <ExternalPortal>
        <div styleName={wrapperStyle}>
          <div styleName="window-frame">
            <div styleName="window-frame__header">
              <div styleName="path">
                <ResourceText resourceKey={key} values={values} />
              </div>
              <div styleName="close" onClick={this.props.close}>
                <i className="fal fa-times" />
              </div>
            </div>
            <div styleName="window-frame__body">
              <iframe src={this.props.windowFramePath} />
            </div>
          </div>
        </div>
      </ExternalPortal>
    );
  }
}
