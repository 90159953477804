import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { AcquisitionEditDetailScreenContainer } from "../edit-details/edit-detail.container";

const route = RouteUtil.mapStaticRouteValues;
export const AcquisitionEditRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ACQUISITIONROUTES.EDIT.URI}
        render={() => (
          <Redirect to={route(ACQUISITIONROUTES.EDIT_DETAILS.URI, { id })} />
        )}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.EDIT_DETAILS.URI}
        component={AcquisitionEditDetailScreenContainer}
      />
    </Switch>
  );
};
