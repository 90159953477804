import { ObjectAssignment, RealEstateGroup } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentEditMarketingTextContainerProps } from "@haywork/modules/assignment";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { SingleAssignmentState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-text.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentEditMarketingTextComponentProps {}
type Props = AssignmentEditMarketingTextComponentProps &
  AssignmentEditMarketingTextContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingTextComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    // Set FormControls
    const { objectAssignment } = this.props;
    this.formControls = {
      advertisementText: {
        value: value(objectAssignment, "advertisementText", "")
      },
      groundFloorText: {
        value: value(objectAssignment, "groundFloorText", "")
      },
      firstFloorText: { value: value(objectAssignment, "firstFloorText", "") },
      secondFloorText: {
        value: value(objectAssignment, "secondFloorText", "")
      },
      otherFloorsText: {
        value: value(objectAssignment, "otherFloorsText", "")
      },
      gardensText: { value: value(objectAssignment, "gardensText", "") },
      balconyText: { value: value(objectAssignment, "balconyText", "") },
      peculiaritiesText: {
        value: value(objectAssignment, "peculiaritiesText", "")
      }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update({
        advertisementText: value(objectAssignment, "advertisementText", ""),
        groundFloorText: value(objectAssignment, "groundFloorText", ""),
        firstFloorText: value(objectAssignment, "firstFloorText", ""),
        secondFloorText: value(objectAssignment, "secondFloorText", ""),
        otherFloorsText: value(objectAssignment, "otherFloorsText", ""),
        gardensText: value(objectAssignment, "gardensText", ""),
        balconyText: value(objectAssignment, "balconyText", ""),
        peculiaritiesText: value(objectAssignment, "peculiaritiesText", "")
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="marketing-text"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <StepperComponent initial={0} scrollToElementId="scroll-to-top">
          <StepComponent title="advertisementText">
            <Input.TranslateText
              name="advertisementText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              maxCount={10000}
              data-cy="Cy-advertisementText"
            />
          </StepComponent>

          <StepComponent title="groundFloorText">
            <Input.TranslateText
              name="groundFloorText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              maxCount={10000}
            />
          </StepComponent>

          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <StepComponent title="firstFloorText">
              <Input.TranslateText
                name="firstFloorText"
                languages={this.props.languages}
                defaultLanguage={this.props.defaultLanguage}
                maxCount={10000}
              />
            </StepComponent>
          )}

          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <StepComponent title="secondFloorText">
              <Input.TranslateText
                name="secondFloorText"
                languages={this.props.languages}
                defaultLanguage={this.props.defaultLanguage}
                maxCount={10000}
              />
            </StepComponent>
          )}

          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <StepComponent title="otherFloorsText">
              <Input.TranslateText
                name="otherFloorsText"
                languages={this.props.languages}
                defaultLanguage={this.props.defaultLanguage}
                maxCount={10000}
              />
            </StepComponent>
          )}

          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <StepComponent title="balconyText">
              <Input.TranslateText
                name="balconyText"
                languages={this.props.languages}
                defaultLanguage={this.props.defaultLanguage}
                maxCount={10000}
              />
            </StepComponent>
          )}

          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <StepComponent title="gardensText">
              <Input.TranslateText
                name="gardensText"
                languages={this.props.languages}
                defaultLanguage={this.props.defaultLanguage}
                maxCount={10000}
              />
            </StepComponent>
          )}

          <StepComponent title="peculiaritiesText">
            <Input.TranslateText
              name="peculiaritiesText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              maxCount={10000}
            />
          </StepComponent>
        </StepperComponent>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment, currentComponentState } = this.props;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id
    });

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      ...values
    };

    const newState: SingleAssignmentState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment
    };

    this.props.updateAssignment(newState, path);
  }
}
