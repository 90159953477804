import { NewEntityType, NewEntityRequester } from "@haywork/enum";
import { ContactPerson, ContactCompany, ObjectAssignment } from "@haywork/api/kolibri";

export interface NewEntity {
  id: string;
  entity: ContactPerson | ContactCompany | ObjectAssignment | null;
  requester: NewEntityRequester;
  fieldNames?: {
    [key: string]: string;
  };
}

export * from "./new-entity.actions";
export * from "./new-entity.reducer";
