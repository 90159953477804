import { AcquisitionAssignment } from "@haywork/api/kolibri";
import { DOSSIER } from "@haywork/constants/redux/dossier";
import { FolderTreeEntityType } from "@haywork/middleware";

export enum singleActions {
  SetAcquisition = "Acquisition.Single.SetAcquisition",
  SetStatus = "Acquisition.Single.SetStatus",
  SetEntityFolderTreeId = "DOSSIER_SET_ENTITY_FOLDER_TREE_ID"
}

type SetAcquisition = {
  type: singleActions.SetAcquisition;
  acquisition: AcquisitionAssignment;
};
const setAcquisition = (acquisition: AcquisitionAssignment) => ({
  type: singleActions.SetAcquisition,
  acquisition
});

type SetStatus = {
  type: singleActions.SetStatus;
  acquisitionStatus: string;
};
const setStatus = (acquisitionStatus: string) => ({
  type: singleActions.SetStatus,
  acquisitionStatus
});

type SetEntityFolderTreeId = {
  type: singleActions.SetEntityFolderTreeId;
  id: string;
  entityType: FolderTreeEntityType;
  entityId: string;
};

export type SingleActions = SetAcquisition | SetStatus | SetEntityFolderTreeId;
export const Actions = {
  setAcquisition,
  setStatus
};
