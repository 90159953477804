import { Garage, Garden, Space } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import findIndex from "lodash-es/findIndex";
import isString from "lodash-es/isString";
import last from "lodash-es/last";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { FloorSpace, RoomType } from "../room-layout.component";
import { RoomLayoutSubSectionComponent } from "./sub-section.component";

const styles = require("./section.component.scss");

interface RoomLayoutSectionComponentProps {
  count: number;
  roomType: RoomType;
  spaces: FloorSpace<Space | Garden | Garage>[];
  floors: number[];
  expanded: boolean;
  title: string | { key: string; values: object };
  onExpandedClick: (type: RoomType) => void;
  onDelete: (id: string, roomType: RoomType) => void;
  onAdd: (roomType: RoomType) => void;
  onSubmit: (
    space: FloorSpace<Space | Garden | Garage>,
    id: string,
    roomType: RoomType
  ) => void;
  onCountChange: (count: number, roomType: RoomType) => void;
}

interface RoomLayoutSectionComponentState {
  expandedChild: number;
  bodyStyles: React.CSSProperties;
}

@CSSModules(styles, { allowMultiple: true })
export class RoomLayoutSectionComponent extends React.Component<
  RoomLayoutSectionComponentProps,
  RoomLayoutSectionComponentState
> {
  private bodyRef: HTMLDivElement;
  private formControls: FormControls;
  private formRef: FormReference;
  private id: string = uuid();

  constructor(props) {
    super(props);

    this.state = {
      expandedChild: null,
      bodyStyles: null,
    };

    if (this.props.roomType) {
      this.formControls = {
        [this.props.roomType.toString()]: {
          value: this.props.count || "",
        },
      };
    }

    this.getEmptyTitle = this.getEmptyTitle.bind(this);
    this.onSubtractClickHandler = this.onSubtractClickHandler.bind(this);
    this.onCountChangeHandler = this.onCountChangeHandler.bind(this);
    this.onAddClickHandler = this.onAddClickHandler.bind(this);
    this.bindBodyRef = this.bindBodyRef.bind(this);
    this.onExpandClickHandler = this.onExpandClickHandler.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  public render() {
    if (!this.props.roomType) return null;
    const collapseLabel = !this.props.expanded ? "expand" : "collapse";
    const title = this.props.title || "";
    const { key: titleKey, values: titleValues } = isString(title)
      ? { key: title, values: {} }
      : title;
    const collapseTriggerStyle = classNames("collapse-trigger", {
      expanded: this.props.expanded,
    });
    const spaces = this.props.spaces || [];

    return (
      <div styleName="section" key={this.id}>
        <div styleName="section__header" data-cy={this.props["data-cy"]}>
          <div styleName="count">
            <div
              styleName="subtract"
              onClick={this.onSubtractClickHandler}
              data-cy="CY-subtract"
            >
              <span className="fal fa-fw fa-minus" />
            </div>
            <Form
              name={`${this.props.roomType.toString()}.Form`}
              onChange={this.onCountChangeHandler}
              formControls={this.formControls}
              asSubForm
              form={(ref) => (this.formRef = ref)}
            >
              <Input.Number name={this.props.roomType.toString()} max={20} />
            </Form>
            <div
              styleName="add"
              onClick={this.onAddClickHandler}
              data-cy="CY-add"
            >
              <span className="fal fa-fw fa-plus" />
            </div>
          </div>
          <div styleName="title">
            <ResourceText resourceKey={titleKey} values={titleValues} />
          </div>
          {spaces.length > 0 && (
            <div
              styleName={collapseTriggerStyle}
              onClick={() => this.props.onExpandedClick(this.props.roomType)}
              data-cy="CY-expand"
            >
              <div styleName="icon">
                <span className="fal fa-fw fa-chevron-down" />
              </div>
              <ResourceText resourceKey={collapseLabel} />
            </div>
          )}
        </div>

        <div styleName="section__body" style={this.state.bodyStyles}>
          <div styleName="inner" ref={this.bindBodyRef}>
            {spaces.map((space, idx) => (
              <RoomLayoutSubSectionComponent
                key={space.id}
                space={space}
                spaces={spaces}
                roomType={this.props.roomType}
                floors={this.props.floors}
                number={idx + 1}
                expanded={idx === this.state.expandedChild}
                onExpandClick={this.onExpandClickHandler}
                onDelete={this.onDeleteHandler}
                onSubmit={this.onSubmitHandler}
                emptyTitle={this.getEmptyTitle()}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RoomLayoutSectionComponentProps
  ) {
    if (!nextProps) return;
    if (!!this.bodyRef && nextProps.expanded !== this.props.expanded) {
      if (!nextProps.expanded) {
        this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
        setTimeout(() => {
          this.setState({ bodyStyles: { height: 0, overflow: "hidden" } });
        }, 1);
        setTimeout(() => {
          this.setState({ bodyStyles: null });
        }, 451);
      } else {
        this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
        setTimeout(() => {
          this.setState({ bodyStyles: { height: "auto" } });
        }, 450);
      }
    }
    if (nextProps.count !== this.props.count && !!this.formRef) {
      this.formRef.update({
        [this.props.roomType.toString()]: nextProps.count || "",
      });
    }
  }

  private onCountChangeHandler(values: FormReturnValue) {
    this.props.onCountChange(
      parseInt(values[this.props.roomType.toString()] || "0"),
      this.props.roomType
    );
  }

  private onSubtractClickHandler() {
    if (!this.props.spaces || this.props.spaces.length === 0) return;
    const { id } = last(this.props.spaces);
    this.props.onDelete(id, this.props.roomType);
  }

  private onAddClickHandler() {
    this.props.onAdd(this.props.roomType);
  }

  private onExpandClickHandler(id: string) {
    const idx = findIndex(this.props.spaces, (s) => s.id === id);
    const expandedChild = idx === this.state.expandedChild ? null : idx;
    this.setState({ expandedChild });
  }

  private onDeleteHandler(id: string) {
    this.setState({ expandedChild: null });
    this.props.onDelete(id, this.props.roomType);
  }

  private onSubmitHandler(
    space: FloorSpace<Space | Garden | Garage>,
    id: string,
    closeExpand: boolean
  ) {
    this.props.onSubmit(space, id, this.props.roomType);
    if (closeExpand) {
      this.setState({ expandedChild: null });
    }
  }

  private onChangeHandler(
    space: FloorSpace<Space | Garden | Garage>,
    id: string
  ) {
    this.props.onSubmit(space, id, this.props.roomType);
  }

  private bindBodyRef(ref: HTMLDivElement) {
    if (!!ref && !this.bodyRef) this.bodyRef = ref;
  }

  private getEmptyTitle(): string {
    switch (this.props.roomType) {
      default:
        return "roomLayoutOtherEmptyTitle";
      case RoomType.Garden:
        return "roomLayoutGardenEmptyTitle";
      case RoomType.Garage:
        return "roomLayoutGarageEmptyTitle";
    }
  }
}
