import { combineReducers } from "redux";
import { ZohoAsapState, zohoAsapReducer, Actions as Asap } from "./asap";

export interface ZohoState {
  asap: ZohoAsapState;
}

export const zohoReducer = combineReducers({
  asap: zohoAsapReducer
});

export const ZohoActions = {
  Asap
};
