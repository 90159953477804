import { File } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import Item from "../check-item";

const styles = require("./style.scss");

type Props = {
  files: File[];
  onSetFiles: (files: File[]) => void;
  onUseFiles: () => void;
};

export const CheckComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ files, onSetFiles, onUseFiles }) => {
      const onDelete = useCallback(
        (id: string) => {
          onSetFiles(files.filter((file) => file.id !== id));
        },
        [files, onSetFiles]
      );

      return (
        <>
          <ModalBody noPadding>
            <div styleName="body">
              <I18n value="dossier.saveFilesModal.check.body" />
            </div>

            {!!files.length && (
              <div styleName="file-list">
                {files.map((file) => (
                  <Item key={file.id} file={file} onDelete={onDelete} />
                ))}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              label="dossier.saveFilesModal.action.next"
              category="primary"
              disabled={!files.length}
              onClick={onUseFiles}
            />
          </ModalFooter>
        </>
      );
    }
  )
);
