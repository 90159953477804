import { REDUX } from "@haywork/constants";
import * as ActionType from "./task.types";
import { Task } from "@haywork/api/kolibri";

const setTasks = (payload: ActionType.TasksResponse, take: number) => ({
  type: REDUX.TASK.SET_TASKS,
  ...payload,
  take
});

const setUpcomingTasks = (payload: ActionType.TasksResponse) => ({
  type: REDUX.TASK.SET_UPCOMING_TASKS,
  ...payload
});

const appendTasks = (payload: ActionType.TasksResponse, take: number) => ({
  type: REDUX.TASK.APPEND_TASKS,
  ...payload,
  take
});

const setTaskOverviewStatus = (taskOverviewStatus: string) => ({
  type: REDUX.TASK.SET_TASK_OVERVIEW_STATUS,
  taskOverviewStatus
});

const setTaskInitialOverviewStatus = (taskOverviewStatus: string) => ({
  type: REDUX.TASK.SET_TASK_INITIAL_OVERVIEW_STATUS,
  taskOverviewStatus
});

const setTaskWidgetState = (taskWidgetState: string) => ({
  type: REDUX.TASK.SET_TASK_WIDGET_STATE,
  taskWidgetState
});

const setTaskStatus = (payload: ActionType.Status) => ({
  type: REDUX.TASK.SET_TASK_STATUS,
  ...payload
});

const setTaskWidgetItemStatus = (payload: ActionType.TaskStatus) => ({
  type: REDUX.TASK.SET_TASK_WIDGET_ITEM_STATUS,
  ...payload
});

const setCurrentTask = (payload: ActionType.Task) => ({
  type: REDUX.TASK.SET_CURRENT_TASK,
  ...payload
});

const setCurrentEditableTask = (task: Task) => ({
  type: REDUX.TASK.SET_CURRENT_TASK,
  task
});

const updateTasksFilters = (payload: ActionType.TasksFilters) => ({
  type: REDUX.TASK.UPDATE_TASKS_FILTERS,
  ...payload
});

const setCurrentTaskStatus = (status: string) => ({
  type: REDUX.TASK.SET_CURRENT_TASK_STATUS,
  status
});

const removeTaskFromOverview = (payload: ActionType.Identifier) => ({
  type: REDUX.TASK.REMOVE_TASK_FROM_OVERVIEW,
  ...payload
});

const setOverviewTaskStatus = (payload: ActionType.TaskStatus) => ({
  type: REDUX.TASK.SET_OVERVIEW_TASK_STATUS,
  ...payload
});

const setTasksStatus = (payload: ActionType.TasksStatus) => ({
  type: REDUX.TASK.SET_TASKS_STATUS,
  ...payload
});

const updateListItem = (payload: ActionType.SnapShot) => ({
  type: REDUX.TASK.UPDATE_LIST_ITEM,
  ...payload
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.TASK.DELETE_LIST_ITEM,
  ...payload
});

export const TaskActions = {
  setTasks,
  appendTasks,
  setTaskOverviewStatus,
  setTaskInitialOverviewStatus,
  setTaskStatus,
  setTaskWidgetItemStatus,
  setCurrentTask,
  updateTasksFilters,
  setCurrentTaskStatus,
  setCurrentEditableTask,
  removeTaskFromOverview,
  setOverviewTaskStatus,
  setUpcomingTasks,
  setTaskWidgetState,
  setTasksStatus,
  updateListItem,
  deleteListItem
};
