import {
  AmenitiesOption,
  CompanyListing,
  ConditionOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  HouseTypeOption,
  IsolationTypeOption,
  ObjectAssignment,
  OrientationOption,
  SituatedTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  CollapsableComponent,
  ResourceText,
  StepComponent,
  StepperComponent,
  StepperType,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";

const styles = require("./company-listings.component.scss");

interface Props {
  objectAssignment: ObjectAssignment;
  companyListings: CompanyListing[];
  houseTypes: HouseTypeOption[];
  situatedTypeOptions: SituatedTypeOption[];
  orientations: OrientationOption[];
  gardenQualityOptions: GardenQualityOption[];
  garageTypeOptions: GarageTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  isolationTypeOptions: IsolationTypeOption[];
  gardenTypeOptions: GardenTypeOption[];
  conditionOptions: ConditionOption[];
  onChange: (values: FormReturnValue) => void;
  onRemoveCompanyListing: (id: string) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalCompanyListingsComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private companyListingsFormControls: FormControls;
  private gardenFormControls: FormControls;
  private garageFormControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onItemRemovedHandler = this.onItemRemovedHandler.bind(this);
    this.onAreaChangeHandler = this.onAreaChangeHandler.bind(this);
    this.bindFormRef = this.bindFormRef.bind(this);

    this.formControls = {
      companyListings: { value: this.props.companyListings || [] },
    };

    this.companyListingsFormControls = {
      id: { value: "" },
      houseType: { value: "" },
      situatedType: { value: "" },
      buildingLayerNumber: { value: "" },
      roomNumber: { value: "" },
      bedroomNumber: { value: "" },
      useSurface: { value: "" },
      parcelSurface: { value: "" },
      content: { value: "" },
      yearOfConstruction: { value: "" },
      garden: { value: "" },
      garage: { value: "" },
      isMainBuilding: {
        value: false,
      },
      maintenanceInside: { value: "" },
      maintenanceOutside: { value: "" },
    };

    this.gardenFormControls = {
      gardenType: { value: "" },
      size: { value: "" },
      orientation: { value: "" },
      gardenQuality: { value: "" },
      isMainGarden: { value: "" },
      hasBackEntrance: { value: "" },
    };

    this.garageFormControls = {
      garageType: { value: "" },
      size: { value: "" },
      amenities: { value: "" },
      insulationTypes: { value: "" },
      capacity: { value: "" },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.formRef.update(
        {
          companyListings: this.props.companyListings,
        },
        true
      );
    }
  }

  public render() {
    const useSurfaceControls: FormControls = {
      area: { value: "" },
    };
    const parcelSurfaceControls: FormControls = {
      area: { value: "" },
    };
    const contentControls: FormControls = {
      volume: { value: "" },
    };
    const yearOfConstructionControls: FormControls = {
      constructionYear: { value: "" },
    };
    const gardenSize: FormControls = {
      length: {
        value: "",
        onChange: this.onAreaChangeHandler,
      },
      width: {
        value: "",
        onChange: this.onAreaChangeHandler,
      },
      area: { value: "" },
    };
    const garageSize: FormControls = {
      length: {
        value: "",
        onChange: this.onAreaChangeHandler,
      },
      width: {
        value: "",
        onChange: this.onAreaChangeHandler,
      },
      height: { value: "" },
      area: { value: "" },
    };

    return (
      <Form
        name="business-homes"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={this.bindFormRef}
      >
        <Input.Array
          name="companyListings"
          formControls={this.companyListingsFormControls}
          onItemRemoved={this.onItemRemovedHandler}
          canBeEmpty={true}
          emptyTriggerLabel={{ key: "addCompanyListing" }}
        >
          <CollapsableComponent title="businessHouse">
            <div styleName="business-house">
              {/* ID */}
              <Input.Hidden name="id" noWrapper />

              {/* House info */}
              <div styleName="business-house__house">
                <div styleName="business-house__type">
                  {/* House type */}
                  <div className="form__row">
                    <label htmlFor="houseType">
                      <ResourceText resourceKey="businessHouseType" />
                    </label>
                    <Input.List
                      name="houseType"
                      values={this.props.houseTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
                <div styleName="business-house__other">
                  {/* Situated type */}
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column push-label">
                        <Input.Switch
                          name="isMainBuilding"
                          on={true}
                          off={false}
                          label="isMainBuilding"
                          labelPosition={SwitchLabelPosition.Post}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="situatedType">
                          <ResourceText resourceKey="situatedType" />
                        </label>
                        <Input.RadioGroup name="situatedType" asButtonList>
                          {this.props.situatedTypeOptions.map(
                            (situatedTypeOption, idx) => (
                              <Input.Radio
                                value={situatedTypeOption.value}
                                label={situatedTypeOption.displayName}
                                key={idx}
                              />
                            )
                          )}
                        </Input.RadioGroup>
                      </div>
                    </div>
                  </div>

                  {/* Room and floor count */}
                  <div className="form__row">
                    <div className="form__group">
                      {/* Building layer number */}
                      <div className="column">
                        <label htmlFor="buildingLayerNumber">
                          <ResourceText resourceKey="buildingLayerNumber" />
                        </label>
                        <Input.Number name="buildingLayerNumber" max={100} />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="roomNumber">
                          <ResourceText resourceKey="roomNumber" />
                        </label>
                        <Input.Number name="roomNumber" max={100} />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="bedroomNumber">
                          <ResourceText resourceKey="bedroomNumber" />
                        </label>
                        <Input.Number name="bedroomNumber" max={100} />
                      </div>
                    </div>
                  </div>

                  {/* Sizes and construction year */}
                  <div className="form__row">
                    <div className="form__group">
                      {/* Living surface */}
                      <Input.Object
                        name="useSurface"
                        formControls={useSurfaceControls}
                      >
                        <div className="column">
                          <label htmlFor="area">
                            <ResourceText resourceKey="useSurfaceArea" />
                          </label>
                          <div className="input__helper">
                            <Input.Number name="area" pretty />
                            <div className="post">
                              <ResourceText resourceKey="squareMeters" asHtml />
                            </div>
                          </div>
                        </div>
                      </Input.Object>
                      <div className="column__spacer" />

                      {/* Parcel surface */}
                      <Input.Object
                        name="parcelSurface"
                        formControls={parcelSurfaceControls}
                      >
                        <div className="column">
                          <label htmlFor="area">
                            <ResourceText resourceKey="parcelSurface" />
                          </label>
                          <div className="input__helper">
                            <Input.Number name="area" pretty />
                            <div className="post">
                              <ResourceText resourceKey="squareMeters" asHtml />
                            </div>
                          </div>
                        </div>
                      </Input.Object>
                      <div className="column__spacer" />

                      {/* Contents */}
                      <Input.Object
                        name="content"
                        formControls={contentControls}
                      >
                        <div className="column">
                          <label htmlFor="volume">
                            <ResourceText resourceKey="content" />
                          </label>
                          <div className="input__helper">
                            <Input.Number name="volume" pretty />
                            <div className="post">
                              <ResourceText resourceKey="cubicMeters" asHtml />
                            </div>
                          </div>
                        </div>
                      </Input.Object>
                      <div className="column__spacer" />

                      {/* Construction year */}
                      <Input.Object
                        name="yearOfConstruction"
                        formControls={yearOfConstructionControls}
                      >
                        <div className="column">
                          <label htmlFor="constructionYear">
                            <ResourceText resourceKey="yearOfConstruction" />
                          </label>
                          <Input.Number
                            name="constructionYear"
                            max={new Date().getFullYear() + 50}
                          />
                        </div>
                      </Input.Object>
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="maintenanceInside">
                          <ResourceText resourceKey="maintenanceInside" />
                        </label>
                        <Input.NewSelect
                          name="maintenanceInside"
                          placeholder="makeAChoice"
                          values={this.props.conditionOptions}
                          displayProp="displayName"
                          valueProp="rating"
                          valuesProp="value"
                          valueOutMapper={(condition: ConditionOption) => ({
                            rating: condition.value,
                          })}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="maintenanceOutside">
                          <ResourceText resourceKey="maintenanceOutside" />
                        </label>
                        <Input.NewSelect
                          name="maintenanceOutside"
                          placeholder="makeAChoice"
                          values={this.props.conditionOptions}
                          displayProp="displayName"
                          valueProp="rating"
                          valuesProp="value"
                          valueOutMapper={(condition: ConditionOption) => ({
                            rating: condition.value,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <StepperComponent type={StepperType.Accordion}>
                {/* Garden */}
                <StepComponent title="garden">
                  <Input.Object
                    name="garden"
                    formControls={this.gardenFormControls}
                  >
                    <div styleName="garden">
                      {/* Garden type */}
                      <div className="form__row">
                        <label htmlFor="gardenType">
                          <ResourceText resourceKey="gardenType" />
                        </label>
                        <Input.RadioGroup
                          name="gardenType"
                          asButtonList
                          multiline
                        >
                          {this.props.gardenTypeOptions.map(
                            (gardenTypeOption, idx) => (
                              <Input.Radio
                                value={gardenTypeOption.value}
                                label={gardenTypeOption.displayName}
                                key={idx}
                              />
                            )
                          )}
                        </Input.RadioGroup>
                      </div>

                      {/* Garden size */}
                      <div className="form__row">
                        <Input.Object name="size" formControls={gardenSize}>
                          <div className="form__group">
                            {/* Length */}
                            <div className="column">
                              <label htmlFor="length">
                                <ResourceText resourceKey="gardenLength" />
                              </label>
                              <div className="input__helper">
                                <Input.Number name="length" pretty />
                                <div className="post">
                                  <ResourceText resourceKey="meterShort" />
                                </div>
                              </div>
                            </div>
                            <div className="column__spacer" />

                            {/* Width */}
                            <div className="column">
                              <label htmlFor="width">
                                <ResourceText resourceKey="gardenWidth" />
                              </label>
                              <div className="input__helper">
                                <Input.Number name="width" pretty />
                                <div className="post">
                                  <ResourceText resourceKey="meterShort" />
                                </div>
                              </div>
                            </div>
                            <div className="column__spacer" />

                            {/* Area */}
                            <div className="column">
                              <label htmlFor="area">
                                <ResourceText resourceKey="gardenArea" />
                              </label>
                              <div className="input__helper">
                                <Input.Number name="area" pretty />
                                <div className="post">
                                  <ResourceText
                                    resourceKey="squareMeters"
                                    asHtml
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Input.Object>
                      </div>

                      {/* Garden orientation */}
                      <div className="form__row">
                        <label htmlFor="orientation">
                          <ResourceText resourceKey="gardenOrientation" />
                        </label>
                        <Input.RadioGroup
                          name="orientation"
                          asButtonList
                          multiline
                        >
                          {this.props.orientations.map((orientation, idx) => (
                            <Input.Radio
                              value={orientation.value}
                              label={orientation.displayName}
                              key={idx}
                            />
                          ))}
                        </Input.RadioGroup>
                      </div>

                      {/* Garden quality */}
                      <div className="form__row">
                        <label htmlFor="gardenQuality">
                          <ResourceText resourceKey="gardenQuality" />
                        </label>
                        <Input.RadioGroup
                          name="gardenQuality"
                          asButtonList
                          multiline
                        >
                          {this.props.gardenQualityOptions.map(
                            (gardenQualityOption, idx) => (
                              <Input.Radio
                                value={gardenQualityOption.value}
                                label={gardenQualityOption.displayName}
                                key={idx}
                              />
                            )
                          )}
                        </Input.RadioGroup>
                      </div>

                      {/* Garden options */}
                      <div className="form__row">
                        <div className="form__group">
                          <div className="column">
                            <Input.Switch
                              name="isMainGarden"
                              label="isMainGarden"
                              on={true}
                              off={false}
                            />
                          </div>
                          <div className="column__spacer" />
                          <div className="column">
                            <Input.Switch
                              name="hasBackEntrance"
                              label="hasBackEntrance"
                              on={true}
                              off={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Input.Object>
                </StepComponent>

                {/* Garage */}
                <StepComponent title="garage">
                  <Input.Object
                    name="garage"
                    formControls={this.garageFormControls}
                  >
                    <div styleName="garage">
                      {/* Garage type */}
                      <div className="form__row">
                        <label htmlFor="garageType">
                          <ResourceText resourceKey="garageType" />{" "}
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.RadioGroup
                          name="garageType"
                          asButtonList
                          multiline
                        >
                          {this.props.garageTypeOptions.map(
                            (garageTypeOption, idx) => (
                              <Input.Radio
                                value={garageTypeOption.value}
                                label={garageTypeOption.displayName}
                                key={idx}
                              />
                            )
                          )}
                        </Input.RadioGroup>
                      </div>

                      {/* Garage size */}
                      <div className="form__row">
                        <div className="form__group">
                          <div className="column">
                            <Input.Object
                              name="size"
                              formControls={garageSize}
                              className="column form__group"
                            >
                              <div className="form__group">
                                {/* Length */}
                                <div className="column">
                                  <label htmlFor="length">
                                    <ResourceText resourceKey="garageLength" />
                                  </label>
                                  <div className="input__helper">
                                    <Input.Number name="length" pretty />
                                    <div className="post">
                                      <ResourceText resourceKey="meterShort" />
                                    </div>
                                  </div>
                                </div>
                                <div className="column__spacer" />

                                {/* Width */}
                                <div className="column">
                                  <label htmlFor="width">
                                    <ResourceText resourceKey="garageWidth" />
                                  </label>
                                  <div className="input__helper">
                                    <Input.Number name="width" pretty />
                                    <div className="post">
                                      <ResourceText resourceKey="meterShort" />
                                    </div>
                                  </div>
                                </div>
                                <div className="column__spacer" />

                                {/* Height */}
                                <div className="column">
                                  <label htmlFor="height">
                                    <ResourceText resourceKey="garageHeight" />
                                  </label>
                                  <div className="input__helper">
                                    <Input.Number name="height" pretty />
                                    <div className="post">
                                      <ResourceText resourceKey="meterShort" />
                                    </div>
                                  </div>
                                </div>
                                <div className="column__spacer" />

                                {/* Area */}
                                <div className="column">
                                  <label htmlFor="area">
                                    <ResourceText resourceKey="garageArea" />
                                  </label>
                                  <div className="input__helper">
                                    <Input.Number name="area" pretty />
                                    <div className="post">
                                      <ResourceText
                                        resourceKey="squareMeters"
                                        asHtml
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Input.Object>
                          </div>
                          <div className="column__spacer" />

                          {/* Capacity */}
                          <div className="column">
                            <label htmlFor="capacity">
                              <ResourceText resourceKey="capacity" />
                            </label>
                            <div className="input__helper">
                              <Input.Number name="capacity" max={100} />
                              <div className="post full">
                                <ResourceText resourceKey="cars" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Amenities */}
                      <div className="form__row">
                        <label htmlFor="amenities">
                          <ResourceText resourceKey="amenities" />
                        </label>
                        <Input.Multi
                          name="amenities"
                          values={this.props.amenitiesOptions}
                        />
                      </div>

                      {/* Isolation types */}
                      <div className="form__row">
                        <label htmlFor="insulationTypes">
                          <ResourceText resourceKey="insulationTypes" />
                        </label>
                        <Input.Multi
                          name="insulationTypes"
                          values={this.props.isolationTypeOptions}
                        />
                      </div>
                    </div>
                  </Input.Object>
                </StepComponent>
              </StepperComponent>
            </div>
          </CollapsableComponent>
        </Input.Array>
      </Form>
    );
  }

  private onAreaChangeHandler(
    ref: RawFormControl,
    get: (name: string) => RawFormControl
  ): object | null {
    const length = get("length");
    const width = get("width");

    const values = {};

    if (length.value && width.value) {
      const area = parseFloat(length.value) * parseFloat(width.value);
      values["area"] = area;
    }

    return Object.keys(values).length > 0 ? values : null;
  }

  private onChangeHandler(values: FormReturnValue) {
    const listings: CompanyListing[] = values.companyListings || [];

    const companyListings = listings.map((listing) => {
      return {
        ...listing,
        isNew: !listing.id,
        id: listing.id || uuid(),
        objectAssignmentId:
          listing.objectAssignmentId || this.props.objectAssignment.id,
        isActive: true,
      };
    });

    this.props.onChange(companyListings);
  }

  private onItemRemovedHandler(values: FormReturnValue) {
    const id: string = get(values, "id");
    if (!!id) this.props.onRemoveCompanyListing(id);
  }

  private bindFormRef(form: FormReference) {
    if (!!form && !this.formRef) {
      this.formRef = form;
    }
  }
}
