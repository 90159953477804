import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import isString from "lodash-es/isString";

import { FontStyle, ResourceText } from "@haywork/modules/shared";

const styles = require("./collapsable.component.scss");

interface CollapsableComponentProps {
  title: string | { key: string; values: object };
  className?: string;
  initiallyCollapsed?: boolean;
  fontStyle?: FontStyle;
}
interface CollapsableComponentState {
  height: number | string;
  collapsed: boolean;
  className: string;
}

@CSSModules(styles, { allowMultiple: true })
export class CollapsableComponent extends React.Component<
  CollapsableComponentProps,
  CollapsableComponentState
> {
  private innerRef: HTMLDivElement;

  constructor(props, context) {
    super(props, context);

    this.state = {
      height: this.props.initiallyCollapsed ? 0 : "auto",
      collapsed: !!this.props.initiallyCollapsed,
      className: this.props.className,
    };

    this.onToggleHandler = this.onToggleHandler.bind(this);
  }

  public render() {
    const bodyStyles = { height: this.state.height };
    const headerStyle = classNames("collapsable__header", {
      collapsed: this.state.collapsed,
    });
    const containerClass = classNames(
      this.props.className ? "collapsable__" + this.props.className : null
    );
    const bodyClass = classNames(
      this.props.className ? "collapsable__body__" + this.props.className : null
    );
    const innerBodyClass = classNames(
      this.props.className
        ? "collapsable__inner__" + this.props.className
        : null
    );
    const headerClass = classNames(
      this.props.className
        ? "collapsable__header__" + this.props.className
        : null
    );
    const { key, values } = isString(this.props.title)
      ? { key: this.props.title, values: {} }
      : this.props.title;

    return (
      <div styleName="collapsable" className={containerClass}>
        <div
          styleName={headerStyle}
          onClick={this.onToggleHandler}
          className={headerClass}
          data-cy={this.props["data-cy"]}
        >
          <h2>
            <ResourceText
              resourceKey={key}
              values={values}
              fontStyle={this.props.fontStyle || FontStyle.UcFirst}
            />
          </h2>
          <div styleName="trigger">
            <i className="fal fa-fw fa-chevron-up" />
          </div>
        </div>
        <div
          styleName="collapsable__body"
          style={bodyStyles}
          className={bodyClass}
        >
          <div
            styleName="collapsable__inner"
            className={innerBodyClass}
            ref={(ref) => (this.innerRef = ref)}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  private onToggleHandler() {
    const innerHeight = this.innerRef ? this.innerRef.clientHeight : 0;
    const { collapsed } = this.state;
    const promise = new Promise<void>((resolve, reject) => {
      if (!collapsed) {
        this.setState({ height: innerHeight });
        setTimeout(resolve, 5);
      } else {
        resolve();
      }
    });

    promise.then(() => {
      this.setState(
        {
          collapsed: !collapsed,
          height: collapsed ? innerHeight : 0,
        },
        () => {
          if (collapsed) {
            setTimeout(() => this.setState({ height: "auto" }), 400);
          }
        }
      );
    });
  }
}
