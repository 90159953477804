import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue } from "../../";
import { ListFilterContext } from "../../list-filter.context";
import { Input } from "@haywork/modules/form";
import { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  configKey: string;
  value: SingleFilterValue;
  title?: Translateable;
  disabled?: boolean;
};

export const DateRangeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ configKey, value, title, disabled }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);
      const [minValue, setMinValue] = useState(value.value.min || "");
      const [maxValue, setMaxValue] = useState(value.value.max || "");

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const minPlaceholder = useMemo(() => {
        if (!filter) return undefined;
        return `range.placeholder.min.${configKey}`;
      }, [configKey]);

      const maxPlaceholder = useMemo(() => {
        if (!filter) return undefined;
        return `range.placeholder.max.${configKey}`;
      }, [configKey]);

      const handleChangeMin = useCallback(
        (value: Date) => {
          if (!filter) return;

          setMinValue(value || undefined);
          const updatedFilter = {
            ...filter,
            value: {
              min: value || undefined,
              max: maxValue || undefined,
            },
          };

          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, onFilterChange, maxValue]
      );

      const handleChangeMax = useCallback(
        (value: Date) => {
          if (!filter) return;

          setMaxValue(value || undefined);
          const updatedFilter = {
            ...filter,
            value: {
              min: minValue || undefined,
              max: value || undefined,
            },
          };

          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, onFilterChange, minValue]
      );

      useEffect(() => {
        setMinValue(value.value.min || "");
        setMaxValue(value.value.max || "");
      }, [value]);

      if (!filter) return null;

      return (
        <div styleName="wrapper">
          {!!label && <h2>{label}</h2>}
          <div styleName="range">
            <div styleName="input">
              <Input.Datepicker
                name={`${configKey}.min`}
                value={minValue}
                onChange={handleChangeMin}
                placeholder={minPlaceholder}
                asSingleInput
                disabled={disabled}
              />
            </div>
            <div styleName="input">
              <Input.Datepicker
                name={`${configKey}.max`}
                value={maxValue}
                onChange={handleChangeMax}
                placeholder={maxPlaceholder}
                asSingleInput
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      );
    }
  )
);
