import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import {
  DocumentSession,
  DocumentTemplate,
  ListOfGoodsValue,
} from "@haywork/api/kolibri";
import { DynamicDocumentsUtil } from "@haywork/util";

import * as ActionType from "./single.types";

export interface ListOfGoodsValueExtended extends ListOfGoodsValue {
  id: string;
  extendedSubEntries: ListOfGoodsValueExtended[];
}

export interface DynamicDocumentsSingleState {
  session: DocumentSession;
  template: DocumentTemplate;
  documentStatus: string;
  listOfGoods: ListOfGoodsValueExtended;
  showSaveModal: boolean;
}

const INITIAL_STATE: DynamicDocumentsSingleState = {
  session: null,
  template: null,
  documentStatus: REQUEST.IDLE,
  showSaveModal: false,
  listOfGoods: null,
};

export const singleReducer = (
  state: DynamicDocumentsSingleState = INITIAL_STATE,
  action: Action
): DynamicDocumentsSingleState => {
  switch (action.type) {
    case REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT: {
      const { session, template, listOfGoods } = <ActionType.DynamicDocument>(
        action
      );
      const formElementValues =
        DynamicDocumentsUtil.mapTemplateToFormReturnValues(template, session);

      return {
        ...state,
        session: {
          ...session,
          formElementValues,
        },
        template,
        documentStatus: REQUEST.SUCCESS,
        listOfGoods,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_SESSION: {
      const { session } = <ActionType.DocumentSession>action;
      const formElementValues =
        DynamicDocumentsUtil.mapTemplateToFormReturnValues(
          state.template,
          session
        );

      return {
        ...state,
        session: {
          ...session,
          formElementValues,
        },
        documentStatus: REQUEST.SUCCESS,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_STATUS: {
      const { documentStatus } = <ActionType.DocumentStatus>action;

      return {
        ...state,
        documentStatus,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.TOGGLE_DYNAMIC_DOCUMENTS_SAVE_MODAL: {
      const { showSaveModal } = <ActionType.SaveModal>action;
      return { ...state, showSaveModal };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
