import * as React from "react";
import * as CSSModules from "react-css-modules";

import { AssignmentCadastreContainerProps } from "@haywork/modules/assignment";
import {
  FormControls,
  Form,
  Input,
  FormReference,
  FormReturnValue,
  QueryOptionReturnValue,
  QueryResultStringReturnValue
} from "@haywork/modules/form";
import { ResourceText, ButtonLoader } from "@haywork/modules/shared";
import { Cadastre, BuyAndSaveMunicipality } from "@haywork/api/kolibri";
import { EXTERNALROUTES, ASSIGNMENTROUTES, REQUEST } from "@haywork/constants";
import { RouteUtil, FormControlUtil, StringUtil } from "@haywork/util";
import { AssignmentCadastreFormComponent } from "./cadastre-form.component";
import { SingleAssignmentState } from "@haywork/stores";
import { Assignment } from "@haywork/request";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { intlContext } from "@haywork/app";

const styles = require("./cadastre.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentCadastreComponentProps {}
interface AssignmentCadastreComponentState {
  showExtraCadastreForm: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentCadastreComponent extends React.Component<
  AssignmentCadastreComponentProps & AssignmentCadastreContainerProps,
  AssignmentCadastreComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      showExtraCadastreForm: false
    };

    this.formControls = {
      municipality: { value: "" },
      parcelNumber: { value: "" },
      sectionNumber: { value: "" }
    };

    // Bind this
    this.onAddCadastreClick = this.onAddCadastreClick.bind(this);
    this.onFetchCadastreClick = this.onFetchCadastreClick.bind(this);
    this.onCadastreChangeHandler = this.onCadastreChangeHandler.bind(this);
    this.onCadastreRemoveHandler = this.onCadastreRemoveHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  public render() {
    const cadastres = this.props.cadastres || [];
    const displayName = this.renderAddressDisplayName();

    let cadastreResource = "cadastreFetch";
    if (this.props.cadastreState === REQUEST.PENDING) {
      cadastreResource = "fetchingCadastre";
    }

    return (
      <div styleName="cadastre">
        <h2>
          <ResourceText resourceKey="cadastralDataOf" />
        </h2>
        <div styleName="cadastre__address">{displayName}</div>

        {cadastres.length === 0 && (
          <div styleName="cadastre__actions">
            <div styleName="action">
              <button
                type="button"
                className="btn btn-primary icon-left"
                onClick={this.onAddCadastreClick}
                disabled={
                  this.props.cadastreState === REQUEST.PENDING ||
                  !this.props.validForCadastreFetch
                }
                data-cy="CY-addCadastreButton"
              >
                <i className="fal fa-pencil" />
                <ResourceText resourceKey="cadastreAdd" />
              </button>
            </div>
            <div styleName="section">
              <ResourceText resourceKey="or" />
            </div>
            <div styleName="action">
              <button
                type="button"
                className="btn btn-primary icon-left"
                onClick={this.onFetchCadastreClick}
                disabled={
                  this.props.cadastreState === REQUEST.PENDING ||
                  !this.props.validForCadastreFetch
                }
                data-cy="CY-fetchCadastreButton"
              >
                <i className="fal fa-arrow-down" />
                <ButtonLoader
                  resourceKey={cadastreResource}
                  loading={this.props.cadastreState === REQUEST.PENDING}
                />
              </button>
              <div styleName="info">
                <ResourceText
                  resourceKey="cadastreFetchInfo"
                  values={{ url: EXTERNALROUTES.CADASTRE.URI }}
                  asHtml
                />
              </div>
            </div>
          </div>
        )}

        {cadastres.map((cadastre, idx) => (
          <ErrorBoundary key={idx}>
            <AssignmentCadastreFormComponent
              cadastre={cadastre}
              cadastralLotSizeOptions={this.props.cadastralLotSizeOptions}
              ownershipTypeOptions={this.props.ownershipTypeOptions}
              fixedVariableOptions={this.props.fixedVariableOptions}
              groundLeasePeriodOptions={this.props.groundLeasePeriodOptions}
              onChange={(values) => this.onCadastreChangeHandler(values, idx)}
              onRemove={() => this.onCadastreRemoveHandler(idx)}
              address={this.props.objectAssignment.address}
              displayName={displayName}
              municipality={this.renderMunicipalityName()}
              idToken={this.props.idToken}
              leaseholdOwnerTypeOptions={this.props.leaseholdOwnerTypeOptions}
            />
          </ErrorBoundary>
        ))}

        {cadastres.length > 0 && (
          <div styleName="extra-cadastre">
            {!this.state.showExtraCadastreForm && (
              <React.Fragment>
                <div
                  className="btn btn-primary icon-left"
                  onClick={this.onAddCadastreClick}
                >
                  <i className="fal fa-pencil" />
                  <ButtonLoader
                    resourceKey="addExtraCadastreManual"
                    loading={this.props.cadastreState === REQUEST.PENDING}
                  />
                </div>

                <div
                  className="btn btn-primary icon-left"
                  onClick={() => this.setState({ showExtraCadastreForm: true })}
                >
                  <i className="fal fa-plus" />
                  <ResourceText resourceKey="addExtraCadastre" />
                </div>
              </React.Fragment>
            )}
            {this.state.showExtraCadastreForm && (
              <Form
                name="extra-cadastre"
                formControls={this.formControls}
                onSubmit={this.onSubmitHandler}
              >
                <h2>
                  <ResourceText resourceKey="addExtraCadastre" />
                </h2>
                <div className="form__row">
                  <div className="form__group">
                    <div className="column" styleName="municipality-input">
                      <label htmlFor="municipality">
                        <ResourceText resourceKey="municipality" />
                      </label>
                      <Input.Query
                        name="municipality"
                        asyncValues={(term) =>
                          Assignment.buyAndSaveMunicipalities(
                            term,
                            this.props.realEstateAgencyId,
                            10,
                            this.props.host
                          )
                        }
                        optionValue={this.renderQueryBaseStringOption}
                        selectedStringValue={this.renderQueryBaseStringValue}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="sectionNumber">
                        <ResourceText resourceKey="sectionNumber" />
                      </label>
                      <Input.Text
                        name="sectionNumber"
                        placeholder="sectionNumber"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="parcelNumber">
                        <ResourceText resourceKey="parcelNumber" />
                      </label>
                      <Input.Text
                        name="parcelNumber"
                        placeholder="parcelNumber"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column push-label">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={this.props.cadastreState === REQUEST.PENDING}
                      >
                        <ButtonLoader
                          loading={this.props.cadastreState === REQUEST.PENDING}
                          resourceKey="fetch"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </div>
        )}
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    const { address } = this.props.objectAssignment;

    this.props.getExtraCadastre(
      address.countryIso2,
      value(values.municipality, "municipalityId", null),
      value(values.municipality, "municipalityName", null),
      values.parcelNumber,
      values.sectionNumber
    );
  }

  private renderQueryBaseStringOption(
    value: BuyAndSaveMunicipality,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(
          value.municipalityName,
          query
        )}
      />
    );
  }

  private renderQueryBaseStringValue(
    value: BuyAndSaveMunicipality
  ): QueryResultStringReturnValue<any> {
    return {
      value,
      resultString: value.municipalityName
    };
  }

  private onCadastreChangeHandler(cadastre: Cadastre, idx: number) {
    let surfaceChanged = false;
    const cadastres = this.props.cadastres.map((c, k) => {
      if (k === idx) {
        if (c.cadastralDetails.surface !== cadastre.cadastralDetails.surface) {
          surfaceChanged = true;
        }
        return { ...c, ...cadastre };
      }
      return c;
    });

    let { currentComponentState } = this.props;
    const { objectAssignment } = this.props;

    if (surfaceChanged) {
      const area = cadastres.reduce((state, cadastre) => {
        if (!!cadastre.cadastralDetails.surface)
          return state + cadastre.cadastralDetails.surface;
        return state;
      }, 0);

      currentComponentState = {
        ...currentComponentState,
        cadastres,
        objectAssignment: {
          ...currentComponentState.objectAssignment,
          parcelSurface: {
            ...currentComponentState.objectAssignment.parcelSurface,
            area: area || 0
          }
        }
      };
    }

    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id
    });
    const newState: SingleAssignmentState = {
      ...currentComponentState,
      cadastres
    };

    this.props.updateComponentState(newState, pathname);
  }

  private onCadastreRemoveHandler(idx: number) {
    const cadastres = this.props.cadastres.filter((c, k) => k !== idx);
    const { currentComponentState, objectAssignment } = this.props;
    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id
    });
    const newState: SingleAssignmentState = {
      ...currentComponentState,
      cadastres
    };
    this.setState({ showExtraCadastreForm: false });

    this.props.updateComponentState(newState, pathname);
  }

  private onAddCadastreClick() {
    if (this.props.objectAssignment.isNew && !this.props.canSave)
      return this.props.toggleSaveModal(true);
    if (this.props.saveAssignmentState !== REQUEST.PENDING)
      this.props.createCadastre(this.props.objectAssignment.id);
  }

  private onFetchCadastreClick() {
    if (this.props.objectAssignment.isNew && !this.props.canSave)
      return this.props.toggleSaveModal(true);

    const { address } = this.props.objectAssignment;

    this.props.getCadastre(
      address.countryIso2,
      address.houseNumber,
      address.houseNumberPostfix,
      address.postalCode
    );
  }

  private renderAddressDisplayName(): string {
    const { address } = this.props.objectAssignment;
    if (!address)
      return intlContext.formatMessage({
        id: "noCadastralAddressFound",
        defaultMessage: "noCadastralAddressFound"
      });
    const addressLine = [
      value(address, "street.name"),
      value(address, "houseNumber"),
      value(address, "houseNumberPostfix")
    ];
    const localityLine = [
      value(address, "postalCode"),
      value(address, "locality.name")
    ];
    return `${addressLine.filter((a) => !!a).join(" ")}, ${localityLine
      .filter((l) => !!l)
      .join(" ")}`;
  }

  private renderMunicipalityName(): string {
    const { address } = this.props.objectAssignment;
    if (!address || !address.adminAreaLevel2)
      return intlContext.formatMessage({
        id: "noCadastralMunicipalityFound",
        defaultMessage: "noCadastralMunicipalityFound"
      });
    return address.adminAreaLevel2.name;
  }
}
