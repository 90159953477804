import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  LinkedAssignment,
  LinkedRelation,
  MatchMailPeriod,
  ObjectAssignment,
  SearchSearchAssignmentsResponse,
} from "@haywork/api/kolibri";
import { EmailAddress } from "@haywork/api/mail";
import {
  AssignmentThunks,
  AuthorizationThunks,
  MapDispatchToProps,
  RelationThunks,
  SearchAssignmentThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  AssignmentDetailSearchAssignmentsComponent,
  AssignmentDetailSearchAssignmentsComponentProps,
} from "@haywork/modules/assignment";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  canEmail: boolean;
}
interface DispatchProps {
  navigate: (url: string) => void;
  getObjectAssignmentSearchAssignments: (
    objectAssignmentId: string,
    isConcept: boolean,
    matchMailPeriod: MatchMailPeriod[] | null,
    skip?: number,
    take?: number
  ) => Promise<SearchSearchAssignmentsResponse>;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  exportSearchAssignmentRelations: (relationIds: string[]) => void;
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    subject: string,
    toBcc?: boolean
  ) => void;
  exportAssignmentSearchAssignments: (
    objectAssignmentId: string,
    take: number,
    isConceptAssignment: boolean,
    matchMailPeriods: MatchMailPeriod[]
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailSearchAssignmentsComponentProps,
  AppState
> = (state) => {
  const { objectAssignment } = state.assignment.single;

  return {
    objectAssignment,
    canEmail: canSendEmail(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailSearchAssignmentsComponentProps
> = (dispatch) => ({
  navigate: (url: string) => dispatch(push(url)),
  getObjectAssignmentSearchAssignments: (
    objectAssignmentId: string,
    isConcept: boolean,
    matchMailPeriod: MatchMailPeriod[] | null,
    skip?: number,
    take?: number
  ) =>
    dispatch(
      SearchAssignmentThunks.getObjectAssignmentSearchAssignments(
        objectAssignmentId,
        isConcept,
        matchMailPeriod,
        skip,
        take
      )
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  exportSearchAssignmentRelations: (relationIds: string[]) =>
    dispatch(RelationThunks.exportSearchAssignmentRelations(relationIds)),
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    subject: string,
    toBcc?: boolean
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail(
        emails,
        linkedRelations,
        [linkedAssignment],
        subject,
        undefined,
        toBcc
      )
    ),
  exportAssignmentSearchAssignments: (
    objectAssignmentId: string,
    take: number,
    isConceptAssignment: boolean,
    matchMailPeriods?: MatchMailPeriod[]
  ) =>
    dispatch(
      AssignmentThunks.exportAssignmentSearchAssignments(
        objectAssignmentId,
        take,
        isConceptAssignment,
        matchMailPeriods
      )
    ),
});

export type AssignmentDetailSearchAssignmentsContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const AssignmentDetailSearchAssignmentsContainer = injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssignmentDetailSearchAssignmentsComponent)
);
