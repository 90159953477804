import * as React from "react";
import has from "lodash-es/has";
import get from "lodash-es/get";
import * as CSSModules from "react-css-modules";

import { MessageEventType, Message, MessageEvent } from "@haywork/api/mail";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

const styles = require("./list.component.scss");

interface FlagProps {
  event: MessageEvent;
}
interface MessageEventProps {
  message: Message;
}

const EventFlag = CSSModules(styles, { allowMultiple: true })(
  (props: FlagProps) => {
    const { type } = props.event;
    let icon;

    switch (type) {
      case MessageEventType.Forwarded:
        icon = "fa fa-share";
        break;
      case MessageEventType.Replied:
        icon = "fa fa-reply";
        break;
      case MessageEventType.Opened:
        icon = "fal fa-envelope-open-text";
        break;
      default:
        break;
    }

    if (!icon) return null;

    return (
      <div styleName="flag event">
        <i className={icon} />
      </div>
    );
  }
);

export const MessageEvents = (props: MessageEventProps) => {
  if (!has(props.message._metaData, "messageEvents")) return null;
  let events = get(props.message._metaData, "messageEvents");

  if (!events) return null;

  events = events.reduce((state: MessageEvent[], event) => {
    const types = state.map((item) => item.type);
    if (types.indexOf(event.type) === -1) {
      state.push(event);
    }
    return state;
  }, []);

  return (
    <React.Fragment>
      {events.map((event, idx) => (
        <ErrorBoundary key={idx}>
          <EventFlag event={event} />
        </ErrorBoundary>
      ))}
    </React.Fragment>
  );
};
