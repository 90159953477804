import { MapDispatchToProps } from "@haywork/middleware";
import {
  AppState,
  ScrollPositionActions,
  ScrollPositionState,
} from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  ScrollerWrapperComponent,
  ScrollerWrapperComponentProps,
} from "./scroller-wrapper";

interface StateProps {
  scrollPositions: ScrollPositionState;
}

interface DispatchProps {
  setDynamicScrollPosition: (key: string, value: number) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ScrollerWrapperComponentProps,
  AppState
> = (state) => {
  const scrollPositions = state.scrollPosition;
  return { scrollPositions };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ScrollerWrapperComponentProps
> = (dispatch) => ({
  setDynamicScrollPosition: (key: string, value: number) =>
    dispatch(ScrollPositionActions.setDynamicScrollPosition({ key, value })),
});

export type ScrollerWrapperContainerProps = StateProps & DispatchProps;
export const ScrollerWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollerWrapperComponent);
