import { Company, PersonSettings } from "@haywork/api/mail";
import { EmailFolder } from "..";
import { FilterState, EmailRequestState } from "./reducer";

export enum ActionType {
  SetInitialAccountAndFolder = "EmailV2.Main.SetInitialAccountAndFolder",
  SetCurrentAccount = "EmailV2.Main.SetCurrentAccount",
  SetCurrentFolder = "EmailV2.Main.SetCurrentFolder",
  ResetFilters = "EmailV2.Main.ResetFilters",
  SetFilters = "EmailV2.Main.SetFilters",
  SetIdsMatchingSearchQuery = "EmailV2.Main.SetIdsMatchingSearchQuery",
  SetPersonSettings = "EmailV2.Main.SetPersonSettings",
  SetCompanySettings = "EmailV2.Main.SetCompanySettings",
  ToggleAccountsAndFoldersVisibility = "EmailV2.Main.ToggleAccountsAndFoldersVisibility",
  SetEmailRequestState = "EmailV2.Main.SetEmailRequestState",
  ResetApp = "RESET_APP",
  Clear = "EmailV2.Main.Clear",
  SetCurrentMessage = "EmailV2.Main.SetCurrentMessage",
  SetAutoRemoveAfterMessagePersists = "EmailV2.Main.SetAutoRemoveAfterMessagePersists",
}

type SetAutoRemoveAfterMessagePersists = {
  type: ActionType.SetAutoRemoveAfterMessagePersists;
  toggleValue: boolean | undefined;
};

const setAutoRemoveAfterMessagePersists = (toggleValue: boolean) => ({
  type: ActionType.SetAutoRemoveAfterMessagePersists,
  toggleValue,
});

type SetInitialAccountAndFolder = {
  type: ActionType.SetInitialAccountAndFolder;
  accountId: string | null;
  folderId: string | null;
};

const setInitialAccountAndFolder = (
  accountId: string | null,
  folderId: string | null
) => ({
  type: ActionType.SetInitialAccountAndFolder,
  accountId,
  folderId,
});

type SetCurrentAccount = {
  type: ActionType.SetCurrentAccount;
  id: string;
};

const setCurrentAccount = (id: string) => ({
  type: ActionType.SetCurrentAccount,
  id,
});

type SetCurrentFolder = {
  type: ActionType.SetCurrentFolder;
  folder: EmailFolder;
};

const setCurrentFolder = (folder: EmailFolder) => ({
  type: ActionType.SetCurrentFolder,
  folder,
});

type ResetFilters = {
  type: ActionType.ResetFilters;
};

const resetFilters = () => ({
  type: ActionType.ResetFilters,
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterState;
};

const setFilters = (filters: FilterState) => ({
  type: ActionType.SetFilters,
  filters,
});

type SetIdsMatchingSearchQuery = {
  type: ActionType.SetIdsMatchingSearchQuery;
  idsMatchingSearchQuery: string[];
};

const setIdsMatchingSearchQuery = (idsMatchingSearchQuery: string[]) => ({
  type: ActionType.SetIdsMatchingSearchQuery,
  idsMatchingSearchQuery,
});

type SetPersonSettings = {
  type: ActionType.SetPersonSettings;
  personSettings: PersonSettings;
};

const setPersonSettings = (personSettings: PersonSettings) => ({
  type: ActionType.SetPersonSettings,
  personSettings,
});

type SetCompanySettings = {
  type: ActionType.SetCompanySettings;
  companySettings: Company;
};

const setCompanySettings = (companySettings: Company) => ({
  type: ActionType.SetCompanySettings,
  companySettings,
});

type ToggleAccountsAndFoldersVisibility = {
  type: ActionType.ToggleAccountsAndFoldersVisibility;
  accountsAndFoldersVisible: boolean;
};

const toggleAccountsAndFoldersVisibility = (
  accountsAndFoldersVisible: boolean
) => ({
  type: ActionType.ToggleAccountsAndFoldersVisibility,
  accountsAndFoldersVisible,
});

type SetEmailRequestState = {
  type: ActionType.SetEmailRequestState;
  requestState: EmailRequestState;
};

const setEmailRequestState = (requestState: EmailRequestState) => ({
  type: ActionType.SetEmailRequestState,
  requestState,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

type Clear = {
  type: ActionType.Clear;
};

const clear = () => ({
  type: ActionType.Clear,
});

type SetCurrentMessage = {
  type: ActionType.SetCurrentMessage;
  id: string;
  selectedMessage: string;
};

export type ActionTypes =
  | SetInitialAccountAndFolder
  | SetCurrentAccount
  | SetCurrentFolder
  | ResetFilters
  | SetFilters
  | SetIdsMatchingSearchQuery
  | SetPersonSettings
  | SetCompanySettings
  | ToggleAccountsAndFoldersVisibility
  | SetEmailRequestState
  | ResetApp
  | Clear
  | SetCurrentMessage
  | SetAutoRemoveAfterMessagePersists;

export const actions = {
  setInitialAccountAndFolder,
  setCurrentAccount,
  setCurrentFolder,
  resetFilters,
  setFilters,
  setIdsMatchingSearchQuery,
  setPersonSettings,
  setCompanySettings,
  toggleAccountsAndFoldersVisibility,
  setEmailRequestState,
  setAutoRemoveAfterMessagePersists,
  clear,
};
