import { Account, Provider, SaveFolderRequest } from "@haywork/api/mail";
import {
  Dispatch,
  EmailDraftThunks,
  EmailFolderThunks,
  EmailMessageThunks
} from "@haywork/middleware";
import {
  EmailAccountsComponent,
  EmailAccountsComponentProps
} from "@haywork/modules/email";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import { push } from "connected-react-router";
import get from "lodash-es/get";
import { connect } from "react-redux";

interface StateProps {
  accounts: {
    account: Account;
    folders: ExtendedEmailFolder[];
  }[];
  accountsStatus: string;
  currentFolder: ExtendedEmailFolder;
  currentAccount: Account;
  rootCanLoadmore: boolean;
  providers: Provider[];
}
interface DispatchProps {
  searchFolders: (
    parentFolderId: string,
    accountId: string,
    init?: boolean
  ) => Promise<void>;
  getMessagesForAccountAndFolder: (
    account: Account,
    folder: ExtendedEmailFolder
  ) => void;
  getRootFolders: () => void;
  moveToFolder: (
    message: EmailMessage,
    folder: ExtendedEmailFolder,
    accountId: string
  ) => void;
  getDraftsForAccountAndFolder: (
    account: Account,
    folder: ExtendedEmailFolder
  ) => void;
  saveFolder: (request: SaveFolderRequest, isNew?: boolean) => Promise<void>;
  moveFolder: (
    folder: ExtendedEmailFolder,
    targetFolder: ExtendedEmailFolder,
    accountId: string
  ) => Promise<void>;
  checkIfFolderEligableForDelete: (
    folderId: string,
    accountId: string
  ) => Promise<boolean>;
  removeFolder: (folder: ExtendedEmailFolder, accountId: string) => void;
  navigate: (path: string) => void;
}

const mapSubFolders = (
  folders: ExtendedEmailFolder[],
  accountId: string,
  parentId: string = "",
  depth: number = 0
): ExtendedEmailFolder[] => {
  let filteredFolders = folders.filter(
    (folder) => folder.accountId === accountId && folder.parentId === parentId
  );
  filteredFolders = filteredFolders.map((folder) => {
    if (folder.hasSubFolders) {
      return {
        ...folder,
        depth,
        folders: mapSubFolders(folders, accountId, folder.id, depth + 1)
      };
    }
    return {
      ...folder,
      depth
    };
  });

  return filteredFolders;
};

const mapStateToProps = <StateProps, EmailAccountsComponentProps>(
  state: AppState
) => {
  const { accounts, currentAccount } = state.email.accounts;
  const { providers } = state.emailV2.providers;
  const { shares } = state.emailV2.shares;
  const {
    folders,
    currentFolder,
    rootCanLoadMore: roots
  } = state.email.folders;

  const hiddenShareIds = shares
    .filter((share) => share.isHidden)
    .map((share) => share.accountId);
  const accountsAndFolders = accounts.results
    .filter((account) => hiddenShareIds.indexOf(account.id) === -1)
    .map((account) => {
      return {
        account,
        folders: mapSubFolders(folders, account.id)
      };
    });

  const accountId = get(currentAccount, "id");
  const root = roots.find((root) => root.accountId === accountId);
  const rootCanLoadmore = !!root ? root.canLoadMore : false;

  return {
    accounts: accountsAndFolders,
    accountsStatus: accounts.status,
    currentFolder,
    currentAccount,
    rootCanLoadmore,
    providers
  };
};

const mapDispatchToProps = <DispatchProps, EmailAccountsComponentProps>(
  dispatch: Dispatch<any>
) => ({
  searchFolders: (parentFolderId: string, accountId: string, init?: boolean) =>
    dispatch(EmailFolderThunks.searchFolders(parentFolderId, accountId, init)),
  getMessagesForAccountAndFolder: (
    account: Account,
    folder: ExtendedEmailFolder
  ) =>
    dispatch(
      EmailMessageThunks.getMessagesForAccountAndFolder(account, folder)
    ),
  getRootFolders: () => dispatch(EmailFolderThunks.getRootFolders()),
  moveToFolder: (
    message: EmailMessage,
    folder: ExtendedEmailFolder,
    accountId: string
  ) => dispatch(EmailMessageThunks.moveToFolder(accountId, message, folder)),
  getDraftsForAccountAndFolder: (
    account: Account,
    folder: ExtendedEmailFolder
  ) => dispatch(EmailDraftThunks.getDraftsForAccountAndFolder(account, folder)),
  saveFolder: (request: SaveFolderRequest, isNew?: boolean) =>
    dispatch(EmailFolderThunks.saveFolder(request, isNew)),
  moveFolder: (
    folder: ExtendedEmailFolder,
    targetFolder: ExtendedEmailFolder,
    accountId: string
  ) => dispatch(EmailFolderThunks.moveFolder(folder, targetFolder, accountId)),
  checkIfFolderEligableForDelete: (folderId: string, accountId: string) =>
    dispatch(
      EmailMessageThunks.checkIfFolderEligableForDelete(folderId, accountId)
    ),
  removeFolder: (folder: ExtendedEmailFolder, accountId: string) =>
    dispatch(EmailFolderThunks.removeFolder(folder, accountId)),
  navigate: (path: string) => dispatch(push(path))
});

export type EmailAccountsContainerProps = StateProps & DispatchProps;
export const EmailAccountsContainer = connect<
  StateProps,
  DispatchProps,
  EmailAccountsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailAccountsComponent);
