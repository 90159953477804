import {
  AcceptanceType,
  AddressType,
  AgeRange,
  AirTreatmentType,
  BoardingType,
  BuildingMaterialType,
  BuildingType,
  BuildStyleType,
  BusinessRight,
  BuyerType,
  CellarType,
  CertificateType,
  ChildAgeRange,
  ComfortQualityType,
  CommunalAreaType,
  ConditionType,
  ConstructionPeriodType,
  ContinuationType,
  CountryType,
  DimensionsType,
  DoorsType,
  EncodingType,
  EnergyCertificateType,
  EnergyClassType,
  ExclusiveStatusType,
  FamilyIncome,
  FascilityType,
  FencingType,
  FirePlaceType,
  FlatCategoryType,
  FrontageSegmentationType,
  FurnitureType,
  Garage,
  GarageFacilityType,
  GarageType,
  Garden,
  GardenQualityType,
  GardenType,
  GenderType,
  GlazingType,
  GroundUsageType,
  HabitationType,
  HeatingAllocationType,
  HeatingMethodType,
  HeatingMethodWaterType,
  HouseholdComposition,
  IndustrialFacilityType,
  IsolationType,
  KitchenType,
  LegalStatusType,
  LeisureFacilityType,
  LimitationType,
  LinkedObjectStatusType,
  LocationPlaceType,
  MilkingSystemType,
  MovingReason,
  MultilanguageStringType,
  NatureRegistrationType,
  NoisePollutionType,
  OfficeFacilityType,
  OpenHouseEventType,
  OpenHouseOption,
  OrientationType,
  OutdoorAreaFacilityType,
  OwnershipOptionType,
  OwnershipType,
  ParkingType,
  PavementType,
  PointOfInterestType,
  PolutionType,
  PoultryHousingType,
  PriceCodeType,
  PriceUnitType,
  PropertyType,
  PurchaseConditionType,
  PurchaseMixformType,
  PurchasePriceType,
  PurchaseSpecificationType,
  QualityType,
  RealEstateProperty,
  RealEstatePropertySearchItem,
  ReconstructionType,
  RegisterReasonType,
  RelationType,
  RentPriceType,
  RentSpecificationType,
  RoofMaterialType,
  RoofType,
  SectorType,
  SecurityMeasureType,
  ShopLocationType,
  SituatedType,
  SocialPropertyFacilityType,
  StatusType,
  StorageRoomType,
  StructureType,
  SubstatusType,
  SurfaceType,
  SwimmingPoolAttributeType,
  TakeOverItemType,
  TakeOverSpecificationType,
  TypeOfFuelType,
  TypeOfRelation,
  UpholsteredType,
  VentilationEnergySourceType,
  VentilationMethodType,
  WayOfConstructionPrefabricatedHouseType,
  WelfareStateType,
  WindowType,
  WithdrawReasonType,
  FileType,
  AttachmentType,
  AttachmentSubType,
  Attachment,
  AddressComponent,
  MutationType,
  Mutation,
  FacilitySearchType,
  OtherSearchType,
  BathroomFacilityType,
  RemainingFacilityType,
  BogPresenceOfType,
  RealEstatePropertyFloorsFloor,
  FloorType,
  BalconyType,
  KitchenFacilityType,
  LivingRoomType,
  RoomType,
} from "@haywork/api/mls";
import { v4 as uuid } from "uuid";
// import { Floor, FloorType, AtticOption, Language, Space, BalconyType, BathroomFacility, KitchenFacility } from "@haywork/api/kolibri";

const attachment = (
  fileType: FileType,
  attachmentType: AttachmentType,
  url: string
) =>
  ({
    creationDateTime: new Date(),
    foreignID: stringValue,
    hash: stringValue,
    fileType,
    fileTypeNormalizedFile: fileType,
    modificationDateTime: new Date(),
    urlNormalizedFile: url,
    urlMediumFile: url,
    index: 1,
    urlOriginalFile: url,
    urlThumbFile: url,
    title: [multilanguageStringType],
    type: attachmentType,
    tags: stringValue,
    subType: AttachmentSubType.BEDROOM,
    description: [multilanguageStringType],
  } as Attachment);

const stringValue = "String waarde";

const multilanguageStringType: MultilanguageStringType = {
  value: "Multi language omschrijving",
  language: "nl-NL",
  encoding: EncodingType.PLAINTEXT,
};

const facilityType: FascilityType = {
  available: true,
  comments: [multilanguageStringType],
  evaluation: [multilanguageStringType],
};

const dimensionsType: DimensionsType = {
  length: 10,
  width: 10,
  height: 10,
  area: 10,
  content: 10,
};

const energyCertificateType: EnergyCertificateType = {
  hasEnergyCertificate: true,
  dueDate: new Date(),
  dateOfIssue: new Date(),
  number: "A1",
  energyClass: EnergyClassType.APLUSPLUSPLUS,
  energyIndex: 100,
  heatingDemandClass: EnergyClassType.APLUSPLUSPLUS,
  heatingDemand: 100,
  energyPerformanceFactor: 100,
  energyPerformanceFactorClass: EnergyClassType.APLUSPLUSPLUS,
};

const floor: RealEstatePropertyFloorsFloor = {
  atticFixStair: true,
  atticRoomPossible: true,
  countOfBedrooms: 4,
  countOfRooms: 5,
  description: [multilanguageStringType],
  dimensions: dimensionsType,
  level: 4,
  name: [multilanguageStringType],
  orientation: OrientationType.NORTH,
  type: FloorType.FLOOR_3,
  rooms: [
    {
      balconyTypes: [BalconyType.FRENCH_BALCONY, BalconyType.TERRACE],
      bathroomFacilities: [
        BathroomFacilityType.BIDET,
        BathroomFacilityType.JACUZZI,
      ],
      description: [multilanguageStringType],
      dimensions: dimensionsType,
      hasBuiltInAppliances: true,
      hasStairs: true,
      isRenovated: true,
      kitchenFacilities: [
        KitchenFacilityType.BUILT_IN_EQUIPMENT,
        KitchenFacilityType.GAS_STOVE,
      ],
      kitchenTypes: [KitchenType.LARGE_KITCHEN, KitchenType.OPEN],
      livingRoomTypes: [LivingRoomType.CONSERVATORY, LivingRoomType.GARDENROOM],
      name: [multilanguageStringType],
      orientation: OrientationType.NORTH_EAST,
      renovationYear: 1998,
      type: RoomType.CONSERVATORY,
    },
    {
      balconyTypes: [BalconyType.FRENCH_BALCONY, BalconyType.TERRACE],
      bathroomFacilities: [
        BathroomFacilityType.BIDET,
        BathroomFacilityType.JACUZZI,
      ],
      description: [multilanguageStringType],
      dimensions: dimensionsType,
      hasBuiltInAppliances: true,
      hasStairs: true,
      isRenovated: true,
      kitchenFacilities: [
        KitchenFacilityType.BUILT_IN_EQUIPMENT,
        KitchenFacilityType.GAS_STOVE,
      ],
      kitchenTypes: [KitchenType.LARGE_KITCHEN, KitchenType.OPEN],
      livingRoomTypes: [LivingRoomType.CONSERVATORY, LivingRoomType.GARDENROOM],
      name: [multilanguageStringType],
      orientation: OrientationType.NORTH_EAST,
      renovationYear: 1998,
      type: RoomType.CONSERVATORY,
    },
  ],
};

const garage: Garage = {
  carCapacity: 10,
  comments: [multilanguageStringType],
  dimensions: dimensionsType,
  evaluation: [multilanguageStringType],
  facilities: [
    GarageFacilityType.ATTIC,
    GarageFacilityType.ELECTRICITY,
    GarageFacilityType.ELECTRIC_DOORS,
  ],
  name: [multilanguageStringType],
  type: GarageType.ATTACHED_STONE,
  isolationTypes: [
    IsolationType.INSULATED_GLAZING,
    IsolationType.ROOF,
    IsolationType.TRIPLE_GLAZING,
  ],
};

const garden: Garden = {
  name: [
    {
      language: "nl-NL",
      encoding: EncodingType.PLAINTEXT,
      value: "Achtertuin",
    },
  ],
  quality: GardenQualityType.BEAUTIFULLY_LANDSCAPED,
  dimensions: dimensionsType,
  isMainGarden: true,
  orientation: OrientationType.EAST,
  description: [multilanguageStringType],
  hasBackyardEntrance: true,
  type: GardenType.BACK_YARD,
};

const openHouseEventType: OpenHouseEventType = {
  option: OpenHouseOption.DATE,
  date: new Date(),
  from: new Date(),
  until: new Date(),
  title: [multilanguageStringType],
};

const addressType: AddressType = {
  addressLine1: [multilanguageStringType],
  addressLine2: [multilanguageStringType],
  addressLine3: [multilanguageStringType],
  addressLine4: [multilanguageStringType],
  streetname: [multilanguageStringType],
  houseNumber: stringValue,
  houseNumberPostfix: stringValue,
  postalCode: stringValue,
  district: [multilanguageStringType],
  cityName: [multilanguageStringType],
  subRegion: [multilanguageStringType],
  region: [multilanguageStringType],
  countryCode: CountryType.NLD,
  longitude: 10,
  latitude: 10,
};

const relationType: RelationType = {
  companyName: stringValue,
  dateOfBirth: new Date(),
  displayName: stringValue,
  email1: stringValue,
  email2: stringValue,
  email3: stringValue,
  fax: stringValue,
  firstName: stringValue,
  foreignID: stringValue,
  gender: GenderType.UNKNOWN,
  lastName: stringValue,
  middleName: stringValue,
  mobile: stringValue,
  phoneHome: stringValue,
  phoneWork: stringValue,
  photoURL: stringValue,
  languagesSpoken: [],
  socialMedias: [],
  aboutMes: [],
  linkedEmployees: [],
  relationType: TypeOfRelation.PERSON,
  tags: stringValue,
  title: stringValue,
  postalAddress: addressType,
  visitAddress: addressType,
};

const addressComponent: AddressComponent = {
  id: 1,
  name: stringValue,
  iso2LanguageCode: stringValue,
};

const mutation: Mutation = {
  mutationDateTime: new Date(),
  oldPrice: 10000,
  newPrice: 15000,
  type: MutationType.PriceChange,
};

export const realEstatePropertySearchItem: RealEstatePropertySearchItem = {
  id: uuid(),
  bundleId: uuid(),
  appClientKeys: ["mls_kolibri_sync_alpha"],
  address: {
    addressHash: stringValue,
    country: addressComponent,
    adminAreaLevel1: addressComponent,
    adminAreaLevel2: addressComponent,
    adminAreaLevel3: addressComponent,
    locality: addressComponent,
    sublocality: addressComponent,
    street: addressComponent,
    postalCode: stringValue,
    houseNumber: 1,
    houseNumberAddendum: stringValue,
    coordinates: {
      latitude: 10,
      longitude: 10,
    },
  },
  mutations: [mutation, mutation, mutation, mutation, mutation],
  financial: {
    purchasePrice: 1000,
    rentPrice: 1000,
    transactionPrice: 1000,
    pricePerSquareMeter: 1000,
    purchaseConditionType: PurchaseConditionType.COSTS_BUYER,
    priceCodeType: PriceCodeType.PRICE_IN_CONSULTATION,
  },
  areaTotals: {
    effectiveArea: 100,
    landArea: 100,
    content: 100,
    livingRoom: 100,
    storageAreaExternal: 100,
    outsideArea: 100,
    floorArea: 100,
    availableInUnitsFrom: 100,
  },
  imageUrls: [
    "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg",
    "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg",
    "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg",
    "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg",
  ],
  climateControl: {
    energyClass: EnergyClassType.APLUSPLUSPLUS,
    heatingMethods: [
      HeatingMethodType.DISTRICT_HEATING,
      HeatingMethodType.ELECTRIC_HEATING,
      HeatingMethodType.FIREPLACE,
    ],
    heatingWaterMethods: [
      HeatingMethodWaterType.ELECTRIC_BOILER,
      HeatingMethodWaterType.GAS_BOILER,
      HeatingMethodWaterType.SUN_BOILER,
    ],
    typeOfBoiler: stringValue,
  },
  construction: {
    constructionYearFrom: 2000,
    constructionYearTo: 2000,
    isolationTypes: [
      IsolationType.CAVITY_WALL,
      IsolationType.ECO_CONSTRUCTION,
      IsolationType.LARGELY_DOUBLE_GLAZING,
    ],
  },
  // agency: Agency | null;
  counts: {
    numberOfPriceChanges: 10,
    numberOfRooms: 10,
    numberOfBedrooms: 10,
    numberOfFloors: 10,
    numberOfBathrooms: 10,
    numberOfParkingPlaces: 10,
  },
  evaluation: {
    maintenanceInside: ConditionType.GOOD,
    maintenanceOutside: ConditionType.GOOD,
    isAdaptedHouse: true,
    isAccessibleWithDisabilities: true,
    isAccessibleForSeniors: true,
    hasBasement: true,
  },
  garage: {
    types: [
      GarageType.OTHER,
      GarageType.ATTACHED_WOOD,
      GarageType.DESIGNATED_PARKING,
    ],
    capacity: 4,
  },
  location: {
    floorNumber: 3,
  },
  offer: {
    auctionDate: new Date(),
    isAuction: true,
    isForSale: true,
    isForRent: true,
  },
  outside: {
    hasBalcony: true,
    hasRoofTerrace: true,
    hasGarden: true,
    gardenOrientations: [
      OrientationType.EAST,
      OrientationType.NORTH,
      OrientationType.NORTH_WEST,
    ],
    gardenQualities: [
      GardenQualityType.BEAUTIFULLY_LANDSCAPED,
      GardenQualityType.CONSTRUCTED_UNDER_ARCHITECTURE,
      GardenQualityType.NEGLECTED,
    ],
    gardenTypes: [
      GardenType.BACK_YARD,
      GardenType.ENCLOSED_YARD,
      GardenType.FRONT_YARD,
    ],
    totalGardenArea: 200,
    totalBackyardGardenArea: 100,
    hasBackyardEntrance: true,
  },
  propertyInfo: {
    foreignId: stringValue,
    mandateDate: new Date(),
    modificationDateTime: new Date(),
    availability: StatusType.AVAILABLE,
    substatus: SubstatusType.HAS_RECEIVED_OPTION,
  },
  surroundings: {
    locations: [
      LocationPlaceType.FOREST_BORDER,
      LocationPlaceType.FURNITUREBOULEVARD,
      LocationPlaceType.NEAR_RAILWAY_STATION,
    ],
  },
  type: {
    isResidential: true,
    isCommercial: true,
    isAgricultural: true,
    types: [
      PropertyType.INVESTMENT,
      PropertyType.INVESTMENT_LOT,
      PropertyType.KAVLASHVILI,
    ],
  },
  transaction: {
    transactionDateTime: new Date(),
    waivingOfTerminationTo: new Date(),
  },
  differentRegistrations: [
    { bundleId: uuid(), appClientKeys: ["mls_kolibri_sync_alpha"] },
    { bundleId: uuid(), appClientKeys: ["mls_kolibri_sync_alpha"] },
    { bundleId: uuid(), appClientKeys: ["mls_kolibri_sync_alpha"] },
  ],
  legal: {
    businessRights: [
      BusinessRight.BP_RIGHTS,
      BusinessRight.PROTECTED_CITY_OR_VILLAGE,
      BusinessRight.PROTECTED_MONUMENT,
    ],
  },
  facilities: {
    filterFacilities: [
      FacilitySearchType.JACUZZI,
      FacilitySearchType.STEAM_CABIN,
      FacilitySearchType.RENEWABLE_ENERGY,
    ],
    otherFacilities: [
      OtherSearchType.HAS_BATHROOM_ON_GROUND_FLOOR,
      OtherSearchType.IS_EVEN_HOUSE_NUMBER,
      OtherSearchType.IS_PARTLY_UPHOLSTERED,
    ],
    bathroomFacilities: [
      BathroomFacilityType.SAUNA,
      BathroomFacilityType.UNDERFLOORHEATING,
      BathroomFacilityType.WASHINGMACHINE_CONNECTION,
    ],
    remainingFacilities: [
      RemainingFacilityType.SKYLIGHT,
      RemainingFacilityType.SUN_COLLECTORS,
      RemainingFacilityType.WINDMILL,
    ],
    bogPresenceOf: [
      BogPresenceOfType.FLEXIBLE_OFFICE_SPACES,
      BogPresenceOfType.PEAK_COOLING,
      BogPresenceOfType.SUSPENDED_CEILING,
    ],
    hasCompanyListing: true,
  },
};

export const realEstateProperty: RealEstateProperty = {
  areaTotals: {
    agriculturalArea: 100,
    atticArea: 100,
    availableInUnitsFrom: 100,
    balconyArea: 100,
    basementArea: 100,
    beveragesHospitalityIndustryArea: 100,
    beveragesNonHospitalityIndustryArea: 100,
    blockableArea: 100,
    buildableArea: 100,
    buildingRelatedOutdoorSpaceArea: 100,
    cellarArea: 100,
    effectiveArea: 100,
    expansionArea: 100,
    expansionAreaTerrain: 100,
    expansionAreaPercentage: 100,
    expansionAreaTerrainPercentage: 100,
    fastfoodHospitalityIndustryArea: 100,
    floorArea: 100,
    floorAreaGross: 100,
    forestArea: 100,
    freeApartmentArea: 100,
    garageArea: 100,
    gardenArea: 100,
    glassCoverings: 100,
    grasslandArea: 100,
    greenArea: 100,
    homePlotArea: 100,
    hotelHospitalityIndustryArea: 100,
    hotelNonHospitalityIndustryArea: 100,
    kitchenArea: 100,
    livableArea: 100,
    livingArea: 100,
    loggiaArea: 100,
    obstructedArea: 100,
    otherIndoorSpaceArea: 100,
    parkingSpaceArea: 100,
    professionArea: 100,
    restaurantHospitalityIndustryArea: 100,
    restaurantNonHospitalityIndustryArea: 100,
    salesArea: 100,
    showroomArea: 100,
    storageArea: 100,
    storageAreaAttached: 100,
    storageAreaExternal: 100,
    storageAreaInternal: 100,
    terraceArea: 100,
    tradeAreaFree: 100,
    vineyardArea: 100,
    watersArea: 100,
    windowArea: 100,
  },
  attachments: [
    attachment(
      FileType.JPG,
      AttachmentType.BROCHURE,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.CADASTRAL_MAP,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.CADASTRAL_MESSAGE,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.ENERGY_CERTIFICATE,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.FLOORPLAN,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.OTHER,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.PHOTO,
      "https://interactive-examples.mdn.mozilla.net/media/examples/grapefruit-slice-332-332.jpg"
    ),
    attachment(
      FileType.JPG,
      AttachmentType.VIDEO,
      "https://www.youtube.com/watch?v=z4gBMw64aqk"
    ),
  ],
  // cadastre: RealEstatePropertyCadastre | null;
  climatControl: {
    energyCertificate: energyCertificateType,
    energyConsumptionKWH: 100,
    energyConsumptionKWHCubicUnit: 100,
    energyConsumptionKWHSquareUnit: 100,
    heating: {
      heatingAllocation: HeatingAllocationType.AUTONOMOUS,
      energySource: TypeOfFuelType.OIL,
      typeOfBoiler: [multilanguageStringType],
      isCombiBoiler: true,
      yearOfManufacture: 1999,
      ownership: OwnershipType.LEASED,
      heatingMethods: [
        HeatingMethodType.AIRCO,
        HeatingMethodType.ALL_BURNER,
        HeatingMethodType.AUTOMATIC_HEATING,
      ],
      heatingMethodsWater: [
        HeatingMethodWaterType.CENTRALLY_SUPPLIED,
        HeatingMethodWaterType.CENTRAL_HEATING_SYSTEM,
        HeatingMethodWaterType.DISTRICT_HEATING,
      ],
    },
    ventilation: {
      ventilationMethod: [
        VentilationMethodType.AIR_CONDITIONING,
        VentilationMethodType.BALANCED,
        VentilationMethodType.CONDITIONED_VENTILATION,
      ],
      energySource: VentilationEnergySourceType.CEILING,
    },
    epc: 100,
  },
  construction: {
    boarding: BoardingType.LAMINATE,
    buildingMaterial: BuildingMaterialType.BRICK,
    buildingRefurbishedInYear: 2000,
    buildStyle: BuildStyleType.ARTDECO,
    cellarType: CellarType.FULL,
    clearHeight: 100,
    clearSpan: 100,
    constructionComment: [multilanguageStringType],
    constructionHeight: 100,
    constructionHeightTerrain: 100,
    constructionPeriod: ConstructionPeriodType.Item1991_2000,
    constructionOptions: [
      PropertyType.ADDITIONAL_HOSPITALITY,
      PropertyType.AGRICULTURAL_HOUSE,
      PropertyType.BELETAGE,
    ],
    constructionWidth: 100,
    constructionYearFrom: 100,
    constructionYearTo: 100,
    doors: DoorsType.FIRE,
    flatCategory: FlatCategoryType.CATEGORY_B,
    floorLoadCapacity: 100,
    frontageSegmentation: FrontageSegmentationType.HEAVILY_CLUSTERED_FACADE,
    glazing: {
      glazingComments: [multilanguageStringType],
      glazingEvaluation: [multilanguageStringType],
      glazingType: GlazingType.DOUBLE,
    },
    hasAsbestus: true,
    hasIsolation: true,
    isAtticRough: true,
    isNewEstate: true,
    isolationComment: [multilanguageStringType],
    isolationEvaluation: [multilanguageStringType],
    isolationTypes: [
      IsolationType.CRACKING_SEAL,
      IsolationType.ECO_CONSTRUCTION,
      IsolationType.HR_GLAZING,
    ],
    isPrefabricatedHouse: true,
    isReadyForConstruction: true,
    isUnderConstruction: true,
    orientationMainRooms: OrientationType.EAST,
    propertyCondition: ConditionType.BAD,
    reconstruction: ReconstructionType.PARTIALLY_RENOVATED,
    renovationYear: 2000,
    roof: {
      roofMaterials: [
        RoofMaterialType.ASBESTOS,
        RoofMaterialType.BITUMEN,
        RoofMaterialType.CONCRETE,
      ],
      roofType: RoofType.BUTTERBLY,
      roofComments: [multilanguageStringType],
      roofEvaluation: [multilanguageStringType],
    },
    roomHeight: 100,
    wayOfConstructionPrefabricatedHouse:
      WayOfConstructionPrefabricatedHouseType.CONCRETE_OR_BRICK,
    windows: {
      windowEvaluation: [multilanguageStringType],
      windowComments: [multilanguageStringType],
      type: WindowType.FABRIC,
    },
  },
  // contact: RealEstatePropertyContact | null;
  counts: {
    capacityInNumberOfPersons: 10,
    countOfAccomodations: 10,
    countOfAnimals: 10,
    countOfAnimalPlaces: 10,
    countOfBalconies: 10,
    countOfBathrooms: 10,
    countOfBedrooms: 10,
    countOfBeds: 10,
    countOfBreedingPigs: 10,
    countOfBreedingPigsties: 10,
    countOfConferenceRoom: 10,
    countOfDoubleBedBedRooms: 10,
    countOfFinishingPigs: 10,
    countOfFinishingPigsties: 10,
    countOfFloors: 10,
    countOfFloorsOffice: 10,
    countOfGaragePlaces: 10,
    countOfGarages: 10,
    countOfGardens: 10,
    countOfHorseStalls: 10,
    countOfHotelrooms: 10,
    countOfKitchens: 10,
    countOfLoggias: 10,
    countOfMeetingRooms: 10,
    countOfMooringsCattles: 10,
    countOfMooringsDairyCattles: 10,
    countOfMultiBedBedrooms: 10,
    countOfNextRooms: 10,
    countOfOffices: 10,
    countOfOneBedBedrooms: 10,
    countOfParkingPlaces: 10,
    countOfParkingPlacesCovered: 10,
    countOfPitches: 10,
    countOfRooms: 10,
    countOfSeatsBeverages: 10,
    countOfSeatsFastfood: 10,
    countOfSeatsGastronomy: 10,
    countOfSeatsRestaurant: 10,
    countOfSeparatelyLettableHousingUnits: 10,
    countOfShowers: 10,
    countOfSowPlaces: 10,
    countOfTenants: 10,
    countOfTerraces: 10,
    countOfToilettes: 10,
    countOfVealCalves: 10,
    countOfWeanedPigs: 10,
    countOfWeanedPigsties: 10,
  },
  current: {
    animalProductionRights: 10,
    associationOfOwnersHasAnnualMeeting: true,
    associationOfOwnersHasPeriodicContribution: true,
    associationOfOwnersHasReserveFund: true,
    associationOfOwnersHasMaintenanceForecast: true,
    associationOfOwnersHasLongTermMaintenancePlan: true,
    associationOfOwnersHasHomeInsurance: true,
    associationOfOwnersIsRegisteredAtChamberOfCommerce: true,
    brancheLimitation: true,
    cateringAllowed: true,
    checkListAssociationOfOwnersAvailable: true,
    currentUsageDescription: [multilanguageStringType],
    currentDestinationDescription: [multilanguageStringType],
    forTakeOverItems: [
      {
        item: TakeOverItemType.GOODWILL,
        specification: TakeOverSpecificationType.FOR_TAKEOVER,
        price: 1000,
      },
      {
        item: TakeOverItemType.PERSONELL,
        specification: TakeOverSpecificationType.NOT_APPLICABLE,
        price: 100000,
      },
    ],
    isPartiallyRented: true,
    hasAnnualPitches: true,
    isTurnkey: true,
    isVacated: true,
    limitations: [
      LimitationType.IN_MORTGAGE,
      LimitationType.OTHER_LIMITATIONS,
      LimitationType.PLEDGE,
    ],
    partialMutationsParkingplaces: [
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 50000,
        },
      },
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 49000,
        },
      },
    ],
    partialMutationsParkingplacesCovered: [
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 50000,
        },
      },
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 49000,
        },
      },
    ],
    partialMutationsArea: [
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 50000,
        },
      },
      {
        partialMutation: {
          mutationDate: new Date(),
          mutation: 49000,
        },
      },
    ],
    pavement: {
      percentage: 10,
      type: PavementType.STELCON_CONCRETE_SLABS,
    },
    percentageRented: 10,
    propensityInPercentage: 10,
    publicOrientedServices: true,
    remainingTermOfAContract: 10,
    retail: true,
    returnOfServices: true,
    revenuePerYear: 10,
    sectorTypes: [
      SectorType.BEVERAGES,
      SectorType.FASTFOOD,
      SectorType.HOTEL,
      SectorType.RESTAURANT,
    ],
    vacancy: {
      rentalValue: 10000,
      area: 100,
      content: 100,
      height: 100,
      length: 100,
      width: 100,
    },
  },
  descriptions: {
    title: [multilanguageStringType],
    adText: [multilanguageStringType],
    groundFloorDescription: [multilanguageStringType],
    firstFloorDescription: [multilanguageStringType],
    secondFloorDescription: [multilanguageStringType],
    otherFloorDescription: [multilanguageStringType],
    gardenDescription: [multilanguageStringType],
    balconyDescription: [multilanguageStringType],
    detailsDescription: [multilanguageStringType],
    availableFrom: [multilanguageStringType],
    roomEquipment: [multilanguageStringType],
    shares: [multilanguageStringType],
    specialGovernmentAid: [multilanguageStringType],
    surfaceDedication: [multilanguageStringType],
    parking: [multilanguageStringType],
  },
  dimensions: {
    lot: dimensionsType,
    frontage: 100,
    content: 100,
    land: dimensionsType,
    officeDimensions: dimensionsType,
    terrainDimensions: dimensionsType,
    surfaces: [
      {
        surfaceType: SurfaceType.CONSTRUCTION,
        ...dimensionsType,
      },
      {
        surfaceType: SurfaceType.FIELD,
        ...dimensionsType,
      },
      {
        surfaceType: SurfaceType.HOUSING,
        ...dimensionsType,
      },
    ],
  },
  evaluations: {
    buildingCondition: ConditionType.BAD,
    certifications: [
      CertificateType.AANGEPASTE_WONING,
      CertificateType.ALGEMENE_WONING_KEUR,
      CertificateType.BOUWKUNDIGE_KEURING,
    ],
    communalAreas: [
      CommunalAreaType.CAFETERIA,
      CommunalAreaType.CONFERENCEROOM,
      CommunalAreaType.PANTRY,
    ],
    generalState: ConditionType.BAD,
    groundUsage: GroundUsageType.ASPARAGUS,
    hasGreenLabel: true,
    hasGroundPollution: true,
    hasLandUsePlan: true,
    hasLogisticFunction: true,
    hasMonumentalProtection: true,
    isAccesible: true,
    isChildFriendly: true,
    isProtected: true,
    isQualifiedForPeopleWithDisabilities: true,
    isQualifiedForFamilies: true,
    isQualifiedForSeniors: true,
    isQualifiedForStudents: true,
    isReadyForOccupancy: true,
    maintenanceInside: ConditionType.BAD,
    maintenanceOutside: ConditionType.BAD,
    needsRenovation: true,
    partialOfficeUsePossible: true,
    partialOrdinationPossible: true,
    petsAllowed: true,
    redesignationPossible: true,
    securityMeasures: [
      SecurityMeasureType.NEIGHBORHOOD_WATCH,
      SecurityMeasureType.SECURITY_CAMERA,
      SecurityMeasureType.SECURITY_DOOR,
    ],
    suitableForBranches: [multilanguageStringType],
    suitableForCountOfPeople: 10,
    forSpecialTargetAudience: true,
    comfortQuality: ComfortQualityType.LUXURY,
    polutionTypes: [
      PolutionType.FILLED,
      PolutionType.REMOVED,
      PolutionType.WITH_CERTIFICATE,
    ],
    isSKALCertified: true,
  },
  facilities: {
    accommodations: {
      ...facilityType,
      countOfHousingUnits: 10,
      isShared: true,
      sharedSanitaryFacilities: true,
    },
    airco: facilityType,
    airTreatments: [
      AirTreatmentType.AIRCONDITIONING,
      AirTreatmentType.PEAK_COOLING,
      AirTreatmentType.VENTILATION,
    ],
    airTreatmentsOffice: [
      AirTreatmentType.AIRCONDITIONING,
      AirTreatmentType.PEAK_COOLING,
      AirTreatmentType.VENTILATION,
    ],
    alarm: facilityType,
    attick: facilityType,
    backYard: facilityType,
    balcony: facilityType,
    bathroom: facilityType,
    bedroom: facilityType,
    boiler: facilityType,
    buildingHasElevator: true,
    builtInLight: facilityType,
    cable_TV: facilityType,
    cctv: facilityType,
    cellar: facilityType,
    companyListings: [
      {
        constructionYear: 2000,
        content: dimensionsType,
        countOfBedrooms: 3,
        countOfFloors: 3,
        countOfRooms: 3,
        effectiveArea: dimensionsType,
        energyCertificate: energyCertificateType,
        garage,
        garden,
        isMainListing: true,
        landArea: dimensionsType,
        maintenanceInside: ConditionType.BAD,
        maintenanceOutside: ConditionType.BAD,
        propertyTypes: [
          PropertyType.CAFETERIA,
          PropertyType.CLAY,
          PropertyType.CULTURAL_INSTITUTION,
        ],
        situated: SituatedType.ANNEX,
      },
      // {
      //   constructionYear: 2000,
      //   content: dimensionsType,
      //   countOfBedrooms: 3,
      //   countOfFloors: 3,
      //   countOfRooms: 3,
      //   effectiveArea: dimensionsType,
      //   energyCertificate: energyCertificateType,
      //   garage,
      //   garden,
      //   isMainListing: true,
      //   landArea: dimensionsType,
      //   maintenanceInside: ConditionType.BAD,
      //   maintenanceOutside: ConditionType.BAD,
      //   propertyTypes: [
      //     PropertyType.CAFETERIA,
      //     PropertyType.CLAY,
      //     PropertyType.CULTURAL_INSTITUTION,
      //   ],
      //   situated: SituatedType.ANNEX,
      // },
    ],
    digi_TV: facilityType,
    diningRoom: facilityType,
    drainage: facilityType,
    electricity: facilityType,
    electricityConnection: facilityType,
    elevator: facilityType,
    entry: facilityType,
    fencing: [
      FencingType.ELECTRIC,
      FencingType.VINYL_COATED_WIRE,
      FencingType.VINYL_RAIL,
    ],
    firePlace: facilityType,
    firePlaceType: FirePlaceType.OPEN,
    flueTube: facilityType,
    footbridge: facilityType,
    frenchBalcony: facilityType,
    fibreOpticCable: facilityType,
    furniture: facilityType,
    furnitureType: FurnitureType.FURNISHED,
    garage: facilityType,
    garden: facilityType,
    gasConnection: facilityType,
    gastronomy: facilityType,
    generalConnectionComments: [multilanguageStringType],
    greenHouse: facilityType,
    hasRoomSeparateEntrance: true,
    heating: facilityType,
    homeAutomation: facilityType,
    horseTroughIndoor: facilityType,
    horseTroughOutdoor: facilityType,
    horseTroughDrainage: facilityType,
    horseWalker: facilityType,
    house: {
      ...facilityType,
      effectiveArea: 100,
      revenuePerYear: 1000,
      typeBuilding: BuildingType.APARTMENT,
      countOfVacantUnits: 10,
    },
    industrialFacilities: [
      IndustrialFacilityType.POWER_FLOW,
      IndustrialFacilityType.SKYLIGHTS,
      IndustrialFacilityType.SPRINKLER,
    ],
    installations: [multilanguageStringType],
    intercom: facilityType,
    internetConnection: facilityType,
    jacuzzi: facilityType,
    kitchen: facilityType,
    kitchenType: KitchenType.AMERICAN_KITCHEN,
    leisureFacilities: [
      LeisureFacilityType.CATERING,
      LeisureFacilityType.ELECTRICITY,
      LeisureFacilityType.MINIMARKET,
    ],
    livingRoom: facilityType,
    localSewer: facilityType,
    localWasteWaterSystem: facilityType,
    manureStorage: [{ volume: 5000 }, { volume: 4000 }, { volume: 3000 }],
    milkingSystemTypes: [
      MilkingSystemType.DEGREE,
      MilkingSystemType.HERRINGBONE,
      MilkingSystemType.OTHER,
    ],
    office: facilityType,
    officeFacilities: [
      OfficeFacilityType.CABLE_TRAYS,
      OfficeFacilityType.ELEVATORS,
      OfficeFacilityType.FLEXIBLE_OFFICE_SPACES,
    ],
    officeFacilitiesOffice: [
      OfficeFacilityType.CABLE_TRAYS,
      OfficeFacilityType.ELEVATORS,
      OfficeFacilityType.FLEXIBLE_OFFICE_SPACES,
    ],
    openPorch: facilityType,
    outdoorAreaFacilities: [
      OutdoorAreaFacilityType.CLOSEABLE,
      OutdoorAreaFacilityType.FENCE,
    ],
    outdoorAwnings: facilityType,
    parkingPlace: facilityType,
    parkingPlaceOwnership: OwnershipOptionType.BOTH_POSSIBLE,
    parkingTypes: [
      ParkingType.CAR_PARK,
      ParkingType.ON_CLOSED_GROUND,
      ParkingType.ON_OWN_TERRAIN,
    ],
    phoneLine: facilityType,
    poultryHousing: [
      PoultryHousingType.CAGE_HOUSING,
      PoultryHousingType.OTHER,
      PoultryHousingType.TRADITIONAL_HOUSING,
    ],
    professionArea: {
      ...facilityType,
      capacity: 1000,
    },
    professionRoom: facilityType,
    rollerBlinds: facilityType,
    retail: facilityType,
    sanitary: facilityType,
    sanitationLock: facilityType,
    satelliteDish: facilityType,
    sauna: facilityType,
    sewerConnection: facilityType,
    showroom: facilityType,
    skyLight: facilityType,
    slidingDoors: facilityType,
    socialPropertyFacilities: [
      SocialPropertyFacilityType.DAY_NURSERY,
      SocialPropertyFacilityType.ELEVATOR,
      SocialPropertyFacilityType.FIRE_DETECTION_SYSTEM_CONNECTED_TO_COMMUNICATIONSROOM,
    ],
    solarBlinds: facilityType,
    solarium: facilityType,
    solarPanels: facilityType,
    steamCabin: facilityType,
    stock: facilityType,
    storageRoom: {
      ...facilityType,
      attributes: {
        hasHeating: true,
        hasElectricity: true,
        hasWater: true,
        hasAttic: true,
      },
      isolationTypes: [
        IsolationType.WALL,
        IsolationType.ANCHOR_FREE_CAVITY_WALL,
        IsolationType.CAVITY_WALL,
      ],
      type: StorageRoomType.INTERNAL,
    },
    structures: [
      {
        constructionYear: 2000,
        description: "Omschrijving",
        hasCanteen: true,
        structureType: StructureType.ALV_STRUCTURE,
        area: 250,
      },
      {
        constructionYear: 2000,
        description: "Omschrijving",
        hasCanteen: true,
        structureType: StructureType.MACHINE_SHED,
        area: 250,
      },
      {
        constructionYear: 2000,
        description: "Omschrijving",
        hasCanteen: true,
        structureType: StructureType.WAREHOUSE_STRUCTURE,
        area: 250,
      },
    ],
    sunCollectors: facilityType,
    swimmingPool: facilityType,
    swimmingPoolAttributes: [
      SwimmingPoolAttributeType.BIOTOPE,
      SwimmingPoolAttributeType.ELEMENTAL_CONSTRUCTION_SYSTEM,
      SwimmingPoolAttributeType.INDOOR,
    ],
    tank: facilityType,
    terrace: facilityType,
    terrain: facilityType,
    toilet: facilityType,
    toiletSeparated: facilityType,
    upholstered: facilityType,
    upholsteredType: UpholsteredType.FULLY_UPHOLSTERED,
    utilityRoom: facilityType,
    ventilation: facilityType,
    washingMachine: facilityType,
    washPlace: facilityType,
    waterConnection: facilityType,
    welnessArea: facilityType,
    windmill: facilityType,
  },
  financials: {
    advancedPaymentAmount: 1000,
    buildingLeaseFee: 1000,
    buildingLeaseFeeSquareUnit: 1000,
    calculationTypeMainRent: 1000,
    commentsMonthlyCosts: [multilanguageStringType],
    communalCostsIncluded: true,
    commissions: {
      commissionContactBmm: 1000,
      commissionContactGross: 1000,
      commissionContactPercent: 1000,
      commissionContactNotes: [multilanguageStringType],
      commissionCustomerBmm: 1000,
      commissionCustomerGross: 1000,
      commissionCustomerPercent: 1000,
      commissionCustomerNotes: [multilanguageStringType],
      commissionOwnerBmm: 1000,
      commissionOwnerGross: 1000,
      commissionOwnerPercent: 1000,
      commissionOwnerNotes: [multilanguageStringType],
    },
    deposit: 1000,
    freeParking: true,
    furnitureCosts: 1000,
    generalSalesTax: 1000,
    grossRentalIncome: 1000,
    hasPaidLandDevelopmentCosts: true,
    hasRetailersAssociationContribution: true,
    heatingCostsNet: 1000,
    indexLinkedInPercent: 1000,
    indications: {
      electricityCosts: 100,
      gasCosts: 100,
      heatingCosts: 100,
      waterCosts: 100,
    },
    isPartOfInvestmentPortfolio: true,
    landDevelopmentCosts: 1000,
    leaseInYears: 1000,
    leaseNet: 1000,
    leaseSquareUnitNet: 1000,
    mainRentNet: 1000,
    mainRentNetSquareUnit: 1000,
    maintenanceFund: 1000,
    monthlyCosts: 1000,
    monthlyCostsSquareUnit: 1000,
    obtainableRent: 1000,
    obtainableRentSquareUnit: 1000,
    operationalCostsNet: 1000,
    ownershipOfGarage: OwnershipOptionType.BOTH_POSSIBLE,
    paymentRequestGros: 1000,
    purchaseCondition: PurchaseConditionType.COSTS_BUYER,
    purchaseMixform: PurchaseMixformType.BUY_GROUNDLEASE,
    purchasePrice: 1000,
    purchasePriceInUnits: 1000,
    purchasePriceUnit: PriceUnitType.PER_M2,
    purchasePriceType: PurchasePriceType.PRICE_PER_ACRE,
    purchaseVATApplies: true,
    purchaseVATPercentage: 1000,
    purchasePriceSquareUnit: 1000,
    purchasePriceParkingLot: 1000,
    purchasePriceParkingLotCovered: 1000,
    purchaseSpecificationParkingLot:
      PurchaseSpecificationType.EXCLUSIVE_INTERIM_INTEREST,
    purchaseSpecification: PurchaseSpecificationType.EXCLUSIVE_INTERIM_INTEREST,
    purchasePriceIncludedTerrain: 1000,
    purchasePriceIncludedOffice: 1000,
    realEstateTaxDate: new Date(),
    realEstateTaxValue: 1000,
    redemptionAmount: 1000,
    rentPrice: 1000,
    rentPriceUnit: PriceUnitType.PER_M2,
    rentPriceInUnits: 1000,
    rentPriceParkingLot: 1000,
    rentPriceParkingLotCovered: 1000,
    rentPriceCodeParkingLot: PriceCodeType.HIGHER_BUDGET,
    rentPriceType: RentPriceType.M2_PER_YEAR,
    rentSpecification: [
      RentSpecificationType.INCL_ELECTRICITY,
      RentSpecificationType.INCL_FURNITURE,
      RentSpecificationType.INCL_GAS,
    ],
    rentVATPercentage: 1000,
    rentVATApplies: true,
    rentPriceIncludedTerrain: 1000,
    rentPriceIncludedOffice: 1000,
    retailersAssociationContribution: 1000,
    revenue: 1000,
    serviceCosts: 1000,
    specialCostsNet: 1000,
    specialCostsOnetimeNet: 1000,
    subsidizedHousing: 1000,
    summOneTimeCosts: 1000,
    taxReleaseInMonth: 1000,
    yearlyCosts: 1000,
    yearlyCostsSquareUnit: 1000,
    priceCode: PriceCodeType.PRICE_TO_BE_NEGOTIATED,
    priceHistory: {
      purchasePrice: [
        {
          date: new Date(),
          price: 150000,
        },
        {
          date: new Date(),
          price: 151000,
        },
      ],
      rentPrice: [
        {
          date: new Date(),
          price: 1500,
        },
        {
          date: new Date(),
          price: 1510,
        },
      ],
    },
    constructionInterest: true,
    yieldReturn: 1000,
    priceOfLand: 1000,
  },
  floors: [floor, floor, floor],
  garages: [garage, garage, garage],
  gardens: [garden, garden, garden],
  legal: {
    businessRights: [
      BusinessRight.BP_RIGHTS,
      BusinessRight.COOPERATIVE,
      BusinessRight.PROTECTED_CITY_OR_VILLAGE,
    ],
    legalStatus: LegalStatusType.APARTMENT_OWNERSHIP,
    environmentalPermits: "Omschrijving",
    productionRights: [multilanguageStringType],
  },
  // localizationInfo: RealEstatePropertyLocalizationInfo | null;
  location: {
    floor: FloorType.FLOOR_3,
    floorNumber: 4,
    frequencyRange: true,
    frontage: 3,
    level: [multilanguageStringType],
    locationDescription: [multilanguageStringType],
    regions: "regions",
    residence: "residence",
    topNo: [multilanguageStringType],
    vicinity: [multilanguageStringType],
  },
  // locationDetails: GeoAddressDetailType[] | null;
  offer: {
    acceptance: AcceptanceType.BY_DATE,
    acceptanceDate: new Date(),
    acceptanceDescription: [multilanguageStringType],
    isAcquisition: true,
    isConventioned: true,
    isForPacht: true,
    isForRent: true,
    isForSale: true,
    isIncentive: true,
    isSaleAndLeaseBack: true,
    isSpecial: true,
    isTopper: true,
    openHouse: [openHouseEventType, openHouseEventType, openHouseEventType],
    selfIntrest: true,
    specialText: [multilanguageStringType],
    tenancyInYears: 10,
    availableFromDate: new Date(),
    availableUntilDate: new Date(),
    auctionDate: new Date(),
    owners: [relationType, relationType],
    natureRegistration:
      NatureRegistrationType.FORCED_DUE_TO_BUSINESS_ECONOMIC_REASONS,
    registerReason: RegisterReasonType.DIVEST_BUSINESS_UNIT_OR_LAND,
    linkedObject: {
      area: 250,
      situated: SituatedType.ANNEX,
      status: LinkedObjectStatusType.PRESENT,
      type: PropertyType.BREEDING_BROILER,
    },
  },
  projectInfo: {
    constructionNumber: stringValue,
    foreignObjectTypeID: stringValue,
    foreignProjectID: stringValue,
    objectTypeTitle: [multilanguageStringType],
    projectTitle: [multilanguageStringType],
  },
  propertyInfo: {
    confidential: true,
    creationDateTime: new Date(),
    foreignAgencyID: stringValue,
    foreignID: stringValue,
    hideAddress: true,
    hideHousenumber: true,
    hidePrice: true,
    id: 1,
    ignore: true,
    mandateDate: new Date(),
    modificationDateTime: new Date(),
    nameOfBuilding: stringValue,
    origin: stringValue,
    photoModificationDate: new Date(),
    priceModificationDate: new Date(),
    propertyCompanyName: stringValue,
    publicReferenceNumber: stringValue,
    status: StatusType.AVAILABLE,
    substatus: SubstatusType.HAS_RECEIVED_OPTION,
    exclusiveStatus: ExclusiveStatusType.EXCLUSIVE,
    withdrawReason: WithdrawReasonType.ASSIGNMENTTERMINATEDBYREALTOR,
    cadastrallCode: stringValue,
    tags: stringValue,
    temporaryHideProperty: true,
    title: stringValue,
    url: stringValue,
  },
  surroundings: {
    countOfApartments: 10,
    countOfFreeApartments: 10,
    hillsideLocation: true,
    isInHighConcentrationArea: true,
    isInIndustrialEstate: true,
    isInOfficeBlock: true,
    isInPark: true,
    isLakeLocation: true,
    location: [
      LocationPlaceType.CATERING,
      LocationPlaceType.CENTRAL_CITY_CENTER,
      LocationPlaceType.FOREST_BORDER,
    ],
    noisePollution: NoisePollutionType.HEIGHTENED,
    northFacingSlope: true,
    pedestrianArea: true,
    pointsOfInterest: [
      {
        type: PointOfInterestType.CASHMACHINE,
        distanceFrom: 100,
        distanceUntil: 100,
      },
      {
        type: PointOfInterestType.METROJUNCTION,
        distanceFrom: 100,
        distanceUntil: 100,
      },
      {
        type: PointOfInterestType.SUPERMARKET,
        distanceFrom: 100,
        distanceUntil: 100,
      },
    ],
    protectionZone: true,
    qualityOfLocation: QualityType.GOOD,
    shopLocation: ShopLocationType.SHOPPING_GALLERY,
    southFacingSlope: true,
    welfareState: WelfareStateType.B2,
    withGreenView: true,
    withView: true,
  },
  // thirdPartyMedias: ThirdPartyMediaType[] | null;
  transaction: {
    date: new Date(),
    dateUntil: new Date(),
    waivingOfTerminationTo: new Date(),
    contractDate: new Date(),
    contractFreePeriodInMonths: 12,
    isPublic: true,
    realisedPrice: 100000,
    newOwners: {
      relation: [relationType, relationType, relationType],
      metaData: {
        isResident: true,
        numberOfHouseholdMembers: 4,
        buyerType: BuyerType.GOVERNMENT,
        continuation: ContinuationType.NON_AGRICULTURAL_FUNCTION,
        continuationPropertyType: PropertyType.BREEDING_YOUNG_CATTLE,
        expropriation: {
          additionalCompensation: 1000,
          financialLoss: 1000,
          reinvestmentDamage: 1000,
          valueRealEstate: 1000,
        },
        householdComposition:
          HouseholdComposition.PARTNERS_WITH_KIDS_LIVING_AT_HOME,
        ageMainMoneyMaker: AgeRange.FORTY_FIVE_TO_FIFTY_FOUR,
        ageOldestChild: ChildAgeRange.TWELVE_AND_OLDER,
        familyIncome: FamilyIncome.MODAL_TO_TWICE_MODAL,
        leftBehindObject: {
          transactionPrice: 100000,
          rentalPrice: 100000,
          typeOfHabitation: HabitationType.BUY,
          propertyTypes: [
            PropertyType.BREEDING_YOUNG_CATTLE,
            PropertyType.CAFE_RESTAURANT,
            PropertyType.DUPLEX,
          ],
          houseNumber: 100000,
          houseNumberPostfix: stringValue,
          postalCode: stringValue,
        },
        movingReasons: [
          MovingReason.BETTER_ACCESSIBILITY,
          MovingReason.HEALTHCARE_OR_NEED_FOR_CARE,
          MovingReason.RESIDENTS,
        ],
      },
    },
    isEconomicTransfer: true,
  },
  type: {
    isResidential: true,
    isCommercial: true,
    isAgricultural: true,
    forRecreation: true,
    forPermanentResidence: true,
    isInvestment: true,
    isApartmentSharingCommunity: true,
    isOrganicCompany: true,
    isResidentialLot: true,
    propertyTypes: [
      PropertyType.EDUCATIONAL_INSTITUTION,
      PropertyType.FLOWER_BULB_COMPANY,
      PropertyType.INVESTMENT,
    ],
    secondaryPropertyTypes: [
      PropertyType.EDUCATIONAL_INSTITUTION,
      PropertyType.FLOWER_BULB_COMPANY,
      PropertyType.INVESTMENT,
    ],
    foreignPropertyTags: stringValue,
  },
  version: "1",
};
