import { singleActions, SingleActions } from "./actions";
import { REQUEST } from "@haywork/constants";
import { Task } from "@haywork/api/kolibri";

export type SingleState = {
  task: Task;
  taskRequestStatus: string;
};

const INITIAL: SingleState = {
  task: null,
  taskRequestStatus: REQUEST.IDLE
};

export const singleReducer = (
  state = INITIAL,
  action: SingleActions
): SingleState => {
  switch (action.type) {
    case singleActions.SetTask: {
      const { task } = action;

      return {
        ...state,
        task,
        taskRequestStatus: REQUEST.SUCCESS
      };
    }
    case singleActions.SetStatus: {
      const { taskRequestStatus } = action;

      return {
        ...state,
        taskRequestStatus
      };
    }
    default: {
      return state;
    }
  }
};
