import {
  LinkedRelation,
  MatchedSearchAssignmentSnapshot,
  RelationType,
} from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
  SEARCHASSIGNMENTROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentWidgetsSearchAssignmentsItemComponentProps {
  searchAssignment: MatchedSearchAssignmentSnapshot;
  onNavigateToSearchAssignment: (url: string) => void;
}
interface AssignmentWidgetsSearchAssignmentsItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentWidgetsSearchAssignmentsItemComponent extends React.Component<
  AssignmentWidgetsSearchAssignmentsItemComponentProps,
  AssignmentWidgetsSearchAssignmentsItemComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="item" onClick={this.onClickHandler}>
        <div styleName="item__icon">
          <i className="fal fa-fw fa-search" />
        </div>
        <div styleName="item__meta">{this.renderLinkedRelations()}</div>
      </div>
    );
  }

  private onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    const { id } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onNavigateToSearchAssignment(url);
  }

  private disablePropagation(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }

  private renderLinkedRelations(): React.ReactElement<HTMLDivElement> {
    const linkedRelations = this.props.searchAssignment.linkedRelations || [];

    if (linkedRelations.length === 0) {
      return (
        <div>
          <ResourceText resourceKey="noLinkedRelations" />
        </div>
      );
    } else {
      return (
        <div>
          {linkedRelations.map((linkedRelation, idx) => (
            <span key={idx}>
              {idx !== 0 && <span>, </span>}
              {this.renderLinkedRelation(linkedRelation)}
            </span>
          ))}
        </div>
      );
    }
  }

  private renderLinkedRelation(
    relation: LinkedRelation
  ): React.ReactElement<HTMLAnchorElement> {
    const { id, typeOfRelation } = relation;
    let url = null;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        break;
      case RelationType.ContactCompany:
        url = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        break;
      case RelationType.Employee:
        url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
        break;
      case RelationType.Office:
        url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
        break;
      default:
        return null;
    }

    return (
      <NavLink to={url} onClick={this.disablePropagation}>
        {relation.displayName}
      </NavLink>
    );
  }
}
