import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../loaders.scss");

export const ListLoader = CSSModules(styles, { allowMultiple: true })(() => (
  <div styleName="list-loader">
    <div styleName="line-scale">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
));
