import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import {
  TimelineEvent,
  AgendaItemCategorySnapShot
} from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import { ColorUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import { SCHEDULERROUTES } from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  event: TimelineEvent;
  parentId: string;
  agendaItemCategories: AgendaItemCategorySnapShot[];
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const AgendaItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      event,
      parentId,
      agendaItemCategories,
      onNavigate,
      onShowAllLinkedEntities
    }) => {
      const {
        id,
        subject,
        linkedAssignments,
        linkedRelations,
        categoryId,
        date,
        linkedCreatedBy,
        allDayEvent,
        endDate,
        isCanceled,
        isConfirmed
      } = event;
      const agendaItemCategory = useMemo(
        () =>
          agendaItemCategories.find((category) => category.id === categoryId),
        [categoryId]
      );
      const backgroundColor = useMemo(
        () =>
          !!agendaItemCategory && !!agendaItemCategory.backColor
            ? ColorUtil.hexToRgb(agendaItemCategory.backColor)
            : null,
        [agendaItemCategory]
      );
      const categoryName = useMemo(
        () =>
          !!agendaItemCategory ? agendaItemCategory.displayName || null : null,
        [agendaItemCategory]
      );
      const dateField = useMemo(() => {
        const startDate = !!date ? moment(date) : null;
        const stopDate = !!endDate ? moment(endDate) : null;
        const isAfterStartDate =
          !!startDate && !!stopDate ? stopDate.isAfter(startDate, "day") : null;

        switch (true) {
          case !!isAfterStartDate: {
            return (
              <div styleName="date">
                <I18n
                  value="timeline.dateFromUntill"
                  values={{
                    from: startDate.format("DD MMMM YYYY HH:mm"),
                    untill: stopDate.format("DD MMMM YYYY HH:mm")
                  }}
                />
              </div>
            );
          }
          default: {
            return (
              <div styleName="date">{startDate.format("DD MMMM YYYY")}</div>
            );
          }
        }
      }, []);
      const postField = useMemo(() => {
        const startDate = !!date ? moment(date) : null;
        const stopDate = !!endDate ? moment(endDate) : null;
        const sameDay =
          !!startDate && !!stopDate ? startDate.isSame(stopDate, "day") : null;

        switch (true) {
          case !!allDayEvent: {
            return (
              <div styleName="date__info">
                <I18n value="isAllDay" />
              </div>
            );
          }
          case !!sameDay: {
            return (
              <div styleName="date__info">
                <I18n
                  value="timeline.dateFromUntill"
                  values={{
                    from: startDate.format("HH:mm"),
                    untill: stopDate.format("HH:mm")
                  }}
                />
              </div>
            );
          }
          default: {
            return null;
          }
        }
      }, []);
      const createdByField = useMemo(() => {
        const displayName = get(linkedCreatedBy, "displayName");
        return !!displayName ? (
          <div styleName="date__extra">
            <I18n value="timeline.createdBy" values={{ displayName }} />
          </div>
        ) : null;
      }, []);
      const path = useMemo(() => {
        return route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id });
      }, [id]);
      const openAgendaItem = () => {
        onNavigate(path);
      };

      return (
        <div styleName="event__item is-clickable" onClick={openAgendaItem}>
          <div styleName="event__icon">
            <Icon
              name={
                isCanceled
                  ? "calendar-times"
                  : isConfirmed
                  ? "calendar-check"
                  : "calendar-alt"
              }
              light
              size={24}
              color={Colors.Gray}
            />
          </div>
          <div styleName="event__divider" style={{ backgroundColor }}></div>
          <div styleName="event__data">
            <div styleName="status">
              <I18n
                value={
                  isCanceled
                    ? "timeline.canceled"
                    : isConfirmed
                    ? "timeline.confirmed"
                    : "timeline.open"
                }
              />
            </div>
            <div
              styleName="subject"
              style={isCanceled ? { textDecoration: "line-through" } : {}}
            >
              {subject || <I18n value="timeline.missingSubject" />}
            </div>
            {!!categoryName && <div styleName="info">{categoryName}</div>}
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          <div styleName="event__date">
            {dateField}
            {postField}
            {createdByField}
          </div>
          <div styleName="event__actions"></div>
        </div>
      );
    }
  )
);

export default AgendaItemComponent;
