import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ObjectTypesEditMarketingTextContainerProps } from "@haywork/modules/object-types";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-text.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface ObjectTypesEditMarketingTextComponentProps {}
type Props = ObjectTypesEditMarketingTextComponentProps &
  ObjectTypesEditMarketingTextContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditMarketingTextComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    // Set FormControls
    const { currentType } = this.props;
    this.formControls = {
      advertisementText: { value: value(currentType, "advertisementText") },
      environmentText: { value: value(currentType, "environmentText") },
      registrationConditions: {
        value: value(currentType, "registrationConditions")
      },
      partiesDescriptionText: {
        value: value(currentType, "partiesDescriptionText")
      }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.currentType, "dateTimeModified") !==
        get(this.props.currentType, "dateTimeModified")
    ) {
      const { currentType } = this.props;

      this.formRef.update({
        advertisementText: value(currentType, "advertisementText"),
        environmentText: value(currentType, "environmentText"),
        registrationConditions: value(currentType, "registrationConditions"),
        partiesDescriptionText: value(currentType, "partiesDescriptionText")
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="marketing-text"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <StepperComponent initial={0} scrollToElementId="scroll-to-top">
          <StepComponent
            title="advertisementText"
            data-cy="CY-advertisementTextStep"
          >
            <Input.TranslateText
              name="advertisementText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-advertisementText"
            />
          </StepComponent>

          <StepComponent
            title="environmentText"
            data-cy="CY-environmentTextStep"
          >
            <Input.TranslateText
              name="environmentText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-environmentText"
            />
          </StepComponent>

          <StepComponent
            title="registrationConditions"
            data-cy="CY-registrationConditionsTextStep"
          >
            <Input.TranslateText
              name="registrationConditions"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-registrationConditionsText"
            />
          </StepComponent>

          <StepComponent
            title="partiesDescriptionText"
            data-cy="CY-partiesDescriptionTextStep"
          >
            <Input.TranslateText
              name="partiesDescriptionText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-partiesDescriptionText"
            />
          </StepComponent>
        </StepperComponent>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { currentType, path } = this.props;

    const updatedCurrentType = {
      ...currentType,
      ...values
    };

    this.props.updateCurrentType(updatedCurrentType, path);
  }
}
