import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { SearchAssignmentDetailSearchersContainerProps } from "@haywork/modules/search-assignment";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SearchAssignmentNetworkItemComponent } from "./list-item.component";

const styles = require("./detail-searchers.component.scss");

export interface SearchAssignmentDetailSearchersComponentProps {}
interface SearchAssignmentDetailSearchersComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentDetailSearchersComponent extends React.Component<
  SearchAssignmentDetailSearchersComponentProps &
    SearchAssignmentDetailSearchersContainerProps,
  SearchAssignmentDetailSearchersComponentState
> {
  private formControls: FormControls;
  private inputRef: HTMLInputElement;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onRelationRemoveHandler = this.onRelationRemoveHandler.bind(this);

    this.formControls = {
      relation: {
        value: "",
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    const linkedRelations = this.props.searchAssignment.linkedRelations || [];

    return (
      <div styleName="searchers">
        {/* <div styleName="searchers__action"></div> */}

        <div styleName="searchers__header">
          <ResourceText
            resourceKey="searchersCount"
            values={{ count: linkedRelations.length }}
          />
        </div>

        <div styleName="searchers__list">
          {linkedRelations.map((relation, idx) => (
            <ErrorBoundary key={idx}>
              <SearchAssignmentNetworkItemComponent
                relation={relation}
                zebra={idx % 2 === 0}
                onNavigateToRelation={this.props.navigate}
                onRelationRemove={this.onRelationRemoveHandler}
              />
            </ErrorBoundary>
          ))}

          {this.props.saveSearchAssignmentStatus === REQUEST.PENDING && (
            <PageLoader loading />
          )}

          {linkedRelations.length === 0 && (
            <div styleName="empty-state">
              <div styleName="content">
                <div styleName="icon" />
                <div styleName="text">
                  <h2>
                    <ResourceText resourceKey="searchAssignmentSearchersEmptyStateTitle" />
                  </h2>
                  <p>
                    <ResourceText resourceKey="searchAssignmentSearchersEmptyStateBody" />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    const linkedRelations = this.props.searchAssignment.linkedRelations || [];
    const matchingRelation = linkedRelations.find(
      (l) => l.id === values.relation.id
    );
    if (!matchingRelation) {
      const { searchAssignment } = this.props;
      const updatedSearchAssignment = {
        ...searchAssignment,
        linkedRelations: [values.relation, ...linkedRelations],
      };

      this.props.saveSearchAssignment(updatedSearchAssignment);
    }
  }

  private onRelationRemoveHandler(relation: LinkedRelation) {
    const { searchAssignment } = this.props;

    const linkedRelations = searchAssignment.linkedRelations.filter(
      (r) => r.id !== relation.id
    );
    const updatedSearchAssignment = {
      ...searchAssignment,
      linkedRelations,
    };

    this.props.saveSearchAssignment(updatedSearchAssignment);
  }
}
