import { Dispatch } from "../";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import {
  SortOrder,
  PermissionClient,
  ArchiveFilter,
  PermissionOrderByField
} from "@haywork/api/authorization";

const parseRequest = new ParseRequest();

const getPermissionsForClient = (appClientId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Permission = new PermissionClient(authorizationHost);

    try {
      const permissions = await parseRequest.response(
        Permission.search(
          {
            order: SortOrder.Descending,
            orderBy: PermissionOrderByField.Default,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            skip: 0,
            take: 100,
            appClientId
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      return permissions;
    } catch (error) {
      throw error;
    }
  };
};

export const Permissions = {
  getPermissionsForClient
};
