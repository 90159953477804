import { Dispatch } from ".";
import first from "lodash-es/first";

import { AccountActions, AppState, SchedulerActions } from "@haywork/stores";
import { Employee, EmployeeRole } from "@haywork/api/kolibri";

const setEmployee = (employee: Employee) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { employees, currentRealestateAgency } = state.account;
    const { employeeId } = state.access;
    const { role } = currentRealestateAgency;
    let { schedulerFilters } = state.scheduler;

    if (role === EmployeeRole.BackOffice) {
      const employeeIds = first(
        (employees || []).map((employee) => employee.id)
      );

      schedulerFilters = {
        ...schedulerFilters,
        employeeIds: !employeeIds ? [] : [employeeIds]
      };
    } else if (schedulerFilters.employeeIds.length === 0) {
      schedulerFilters = {
        ...schedulerFilters,
        employeeIds: [employeeId]
      };
    } else {
      schedulerFilters = {
        ...schedulerFilters
      };
    }

    dispatch(SchedulerActions.setSchedulerFilters({ schedulerFilters }));
    dispatch(AccountActions.setEmployee(employee));
  };
};

export const AccountThunks = {
  setEmployee
};
