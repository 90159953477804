import * as React from "react";
import { FC, useEffect, useState, memo, ReactNode, useMemo } from "react";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import CSSModules = require("react-css-modules");

const styles = require("./style.scss");

type Props = {
  messages: ReactNode[];
  visible: boolean;
  mode?: "box";
  onClick?: () => void;
};

export const ErrorLintComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ messages, visible, mode, onClick }) => {
      const [showLint, setShowLint] = useState(false);

      const errorLintStyle = useMemo(() => {
        let style: string;

        if (onClick) {
          style = `errorLint clickable`;
        } else {
          style = `errorLint`;
        }
        if (mode === "box") {
          style = `${style} box`;
        }
        return style;
      }, [onClick]);

      useEffect(() => {
        setShowLint(visible);
      }, [visible]);

      if (showLint && messages.length > 0) {
        return (
          <div styleName={errorLintStyle} onClick={onClick}>
            {messages.map((message, idx) => {
              return (
                <div styleName="messageContainer" key={idx}>
                  <Icon
                    name="exclamation-triangle"
                    color={Colors.Warning}
                  ></Icon>
                  <div styleName="message">{message}</div>
                </div>
              );
            })}
          </div>
        );
      } else {
        return null;
      }
    }
  )
);
