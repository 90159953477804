import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum EmailTemplateAction {
  Preview = "Preview",
  TestEmail = "TestEmail"
}

type Props = {
  loading: boolean;
  onClick: (action: EmailTemplateAction) => void;
};

export const EmailTemplateActionsComponent: FC<Props> = memo(
  ({ loading, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const onClickHandler = (action: EmailTemplateAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="emailTemplates.actions.label.actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="emailTemplates.actions.label.actions" />
          <Action
            label="emailTemplate.TemplatePreview"
            icon={<Icon light name="eye" color={Colors.ActionListIcon} fixedWidth />}
            onClick={() => onClickHandler(EmailTemplateAction.Preview)}
          />
          <Action
            label="emailTemplate.SendConcept"
            icon={<Icon light name="paper-plane" color={Colors.ActionListIcon} fixedWidth />}
            onClick={() => onClickHandler(EmailTemplateAction.TestEmail)}
          />
        </ActionList>
      </>
    );
  }
);

export default EmailTemplateActionsComponent;
