import * as React from "react";
import { FC } from "react";
import { Input } from "@haywork/modules/form";
import { AddressDetail } from "@haywork/api/kolibri";
import escapeRegExp from "lodash-es/escapeRegExp";
import I18n from "@haywork/components/i18n";

type Props = {
  name: string;
  value: AddressDetail;
  values: AddressDetail[];
  disabled?: boolean;
  onChange: (value: AddressDetail) => void;
};
export const AddressDetailQueryComponent: FC<Props> = ({
  name,
  value,
  values,
  disabled,
  onChange
}) => {
  return (
    <>
      <label htmlFor="street">
        <I18n value={`address.label.${name}`} />
      </label>
      <Input.Query
        name={name}
        placeholder={`address.placeholder.${name}`}
        value={value}
        values={values}
        matchOn={(q, v: AddressDetail) =>
          new RegExp(escapeRegExp(q), "gi").test(v.name)
        }
        selectedStringValue={(c: AddressDetail) => ({
          value: c,
          resultString: c.name
        })}
        optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
        multiple={false}
        disabled={disabled}
        asSingleInput
        onChange={onChange}
      />
    </>
  );
};
