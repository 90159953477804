import {
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  HouseCharacteristicOption,
  HouseSortOption,
  HouseTypeOption,
  ObjectTypeAssignment,
  RelationSnapShot,
  TypePARTOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditTypeComponent,
  ObjectTypesEditTypeComponentProps,
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { connect } from "react-redux";
import { editableDisplayName, listingTypes, path } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  displayName: string;
  listingTypes: TypePARTOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  path: string;
  employees: RelationSnapShot[];
}
interface DispatchProps {
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, ObjectTypesEditTypeComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;
  const {
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
  } = state.main.mastertable.kolibri;
  const { employees } = state.account;

  return {
    currentType,
    displayName: editableDisplayName(state),
    listingTypes: listingTypes(state),
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    path: path(state),
    employees,
  };
};

const mapDispatchToProps = <DispatchProps, ObjectTypesEditTypeComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type ObjectTypesEditTypeContainerProps = StateProps & DispatchProps;
export const ObjectTypesEditTypeContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditTypeComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditTypeComponent);
