import { v4 as uuid } from "uuid";

import { store } from "@haywork/stores";
import {
  FoldersClient,
  SortOrder,
  FolderOrderBy,
  Message,
  MessagesClient,
} from "@haywork/api/mail";
import {
  CommunicationLogsClient,
  CommunicationLog,
  CommunicationLogBlobsClient,
  CommunicationLogBlobOrderByField,
  ActiveFilter,
  CommunicationLogBlobSnapShot,
  CommunicationLogBlobEmailMessage,
} from "@haywork/api/kolibri";
import { ParseRequest, ApiType } from "@haywork/services";

const parseRequest = new ParseRequest();

const searchFolder = async (term: string, accountId: string) => {
  const state = store.getState();
  const { emailHost } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const Folders = new FoldersClient(emailHost);

  try {
    return await parseRequest.response(
      Folders.search(
        {
          accountId,
          skip: 0,
          take: 5,
          term,
          orderBy: FolderOrderBy.Default,
          order: SortOrder.Ascending,
          flushCache: false,
          includeStatistics: false,
        },
        realEstateAgencyId
      ).then((response) => response.results)
    );
  } catch (error) {
    throw error;
  }
};

const getCommunicationLog = async (communicationLogId: string) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const CommunicationLogs = new CommunicationLogsClient(host);

  try {
    return await parseRequest.response(
      CommunicationLogs.read(communicationLogId, realEstateAgencyId).then(
        (response) => response.communicationLog
      )
    );
  } catch (error) {
    throw error;
  }
};

const getCommunicationBlobs = async (communicationLog: CommunicationLog) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const CommunicationLogsBlobs = new CommunicationLogBlobsClient(host);

  try {
    return await parseRequest.response(
      CommunicationLogsBlobs.search(
        {
          communicationLogId: communicationLog.id,
          orderBy: CommunicationLogBlobOrderByField.FileName,
          filterByActive: ActiveFilter.ActiveOnly,
          order: SortOrder.Ascending,
          skip: 0,
          take: 99,
        },
        realEstateAgencyId
      ).then((response) => response.results)
    );
  } catch (error) {
    throw error;
  }
};

const getCommunicationBlobMailMessage = async (blobId: string) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const CommunicationLogsBlobs = new CommunicationLogBlobsClient(host);

  try {
    return await parseRequest.response(
      CommunicationLogsBlobs.downloadAsEmailMessage(
        blobId,
        realEstateAgencyId
      ).then((response) => response.emailMessage)
    );
  } catch (error) {
    throw error;
  }
};

export const EmailRequest = {
  searchFolder,
  getCommunicationLog,
  getCommunicationBlobs,
  getCommunicationBlobMailMessage,
};
