import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./sticky-headers.component.scss");

interface StickyHeadersComponentProps {
  isMain?: boolean;
  isSub?: boolean;
  display: any;
  obj: any;
}

interface StickyHeadersComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class StickyHeadersComponent extends React.Component<
  StickyHeadersComponentProps,
  StickyHeadersComponentState
> {
  public render() {
    const stickyHeaderStyle = classNames("sticky-header", {
      main: this.props.isMain,
      sub: this.props.isSub,
    });
    const value =
      typeof this.props.display === "function"
        ? this.props.display(this.props.obj)
        : this.props.display;

    return (
      <div styleName={stickyHeaderStyle} data-cy={this.props["data-cy"]}>
        <div styleName="inner">{value}</div>
      </div>
    );
  }
}
