import { AppClient, ReviewSnapShot } from "@haywork/api/authorization";
import { Employee } from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  CreateReviewRequest,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  AppXchangeReviewsComponent,
  AppXchangeReviewsComponentProps
} from "@haywork/modules/app-xchange";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  client: AppClient;
  reviews: ReviewSnapShot[];
  employee: Employee;
  reviewsStatus: string;
}
interface DispatchProps {
  createAndSaveReview: (request: CreateReviewRequest) => Promise<void>;
  getReviews: (appClientId: string) => Promise<void>;
  updateReview: (request: CreateReviewRequest) => Promise<void>;
  deleteReview: (id: string, appClientId: string) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AppXchangeReviewsComponentProps,
  AppState
> = (state) => {
  const {
    client,
    reviews,
    reviewsStatus
  } = state.authorization.singleAppClient;
  const { employee } = state.account;

  return {
    client,
    reviews,
    employee,
    reviewsStatus
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AppXchangeReviewsComponentProps
> = (dispatch) => ({
  getReviews: (appClientId: string) =>
    dispatch(AuthorizationThunks.Reviews.getReviews(appClientId)),
  createAndSaveReview: (request: CreateReviewRequest) =>
    dispatch(AuthorizationThunks.Reviews.createAndSaveReview(request)),
  updateReview: (request: CreateReviewRequest) =>
    dispatch(AuthorizationThunks.Reviews.updateReview(request)),
  deleteReview: (id: string, appClientId: string) =>
    dispatch(AuthorizationThunks.Reviews.deleteReview(id, appClientId))
});

export type AppXchangeReviewsContainerProps = StateProps & DispatchProps;
export const AppXchangeReviewsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppXchangeReviewsComponent);
