import * as React from "react";
import * as PropTypes from "prop-types";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  FormReference,
} from "@haywork/modules/form";
import { AssignmentsFilters } from "@haywork/stores";
import { ResourceText } from "@haywork/modules/shared";
import { FurnishingOption } from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  filters: AssignmentsFilters;
  furnishings: FurnishingOption[];
}
interface State {
  collapsed: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentsSpecFiltersComponent extends React.PureComponent<
  Props,
  State
> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
  };

  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };

    const {
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    } = this.props.filters;

    this.formControls = {
      filterByFurnishings: {
        value: filterByFurnishings || [],
        onChange: (ref) => {
          this.context.updateField("filterByFurnishings", ref.value || []);
        },
      },
      filterByMinPrice: {
        value: filterByMinPrice || "",
        onChange: (ref) => {
          this.context.updateField("filterByMinPrice", ref.value || "");
        },
      },
      filterByMaxPrice: {
        value: filterByMaxPrice || "",
        onChange: (ref) => {
          this.context.updateField("filterByMaxPrice", ref.value || "");
        },
      },
      filterByNumberOfBedroomsMin: {
        value: filterByNumberOfBedroomsMin || "",
        onChange: (ref) => {
          this.context.updateField(
            "filterByNumberOfBedroomsMin",
            ref.value || ""
          );
        },
      },
      filterByNumberOfBedroomsMax: {
        value: filterByNumberOfBedroomsMax || "",
        onChange: (ref) => {
          this.context.updateField(
            "filterByNumberOfBedroomsMax",
            ref.value || ""
          );
        },
      },
    };
  }

  public componentDidMount() {
    const {
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    } = this.props.filters;

    this.context.registerField("filterByFurnishings", filterByFurnishings);
    this.context.registerField("filterByMinPrice", filterByMinPrice);
    this.context.registerField("filterByMaxPrice", filterByMaxPrice);
    this.context.registerField(
      "filterByNumberOfBedroomsMin",
      filterByNumberOfBedroomsMin
    );
    this.context.registerField(
      "filterByNumberOfBedroomsMax",
      filterByNumberOfBedroomsMax
    );
  }

  public componentDidUpdate() {
    const {
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    } = this.props.filters;

    this.form.update({
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    });
  }

  public render() {
    const { collapsed } = this.state;

    return (
      <div styleName="specs">
        <div
          styleName={classNames("header", { collapsed })}
          onClick={() => this.setState({ collapsed: !collapsed })}
        >
          <ResourceText resourceKey="assignments.specFilterTitle" />
          <i className="fal fa-fw fa-chevron-down" />
        </div>
        <div styleName={classNames("form", { collapsed })}>
          <Form
            name="assignment-spec-filtering"
            formControls={this.formControls}
            form={(form) => (this.form = form)}
          >
            <div styleName="row">
              <label htmlFor="filterByMinPrice">
                <ResourceText resourceKey="assignments.specFilterLabel.filterByPrice" />
              </label>
              <div styleName="inputs">
                <div className="input__helper">
                  <div className="pre small">&euro;</div>
                  <Input.Number
                    name="filterByMinPrice"
                    placeholder="assignments.specFilterPlaceholder.from"
                  />
                </div>
                <div styleName="spacer" />
                <div className="input__helper">
                  <div className="pre small">&euro;</div>
                  <Input.Number
                    name="filterByMaxPrice"
                    placeholder="assignments.specFilterPlaceholder.untill"
                  />
                </div>
              </div>
            </div>

            <div styleName="row">
              <label htmlFor="filterByNumberOfBedroomsMin">
                <ResourceText resourceKey="assignments.specFilterLabel.filterByBedrooms" />
              </label>
              <div styleName="inputs">
                <Input.Number
                  name="filterByNumberOfBedroomsMin"
                  placeholder="assignments.specFilterPlaceholder.from"
                />
                <div styleName="spacer" />
                <Input.Number
                  name="filterByNumberOfBedroomsMax"
                  placeholder="assignments.specFilterPlaceholder.untill"
                />
              </div>
            </div>

            <div styleName="row">
              <label htmlFor="filterByFurnishings">
                <ResourceText resourceKey="assignments.specFilterLabel.filterByFurnishings" />
              </label>
              <Input.Multi
                name="filterByFurnishings"
                values={this.props.furnishings}
                asList={true}
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
