import {
  AssignmentSnapShot,
  AssignmentType,
  LinkedAssignment
} from "@haywork/api/kolibri";
import {
  ACQUISITIONROUTES,
  ACQUISITIONOBJECTROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import Pill from ".";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: LinkedAssignment | AssignmentSnapShot;
  color?: string;
  onClick?: (path: string) => void;
};

export const AssignmentPillComponent: FC<Props> = memo(
  ({ assignment, color, onClick }) => {
    const { displayName, id, typeOfAssignment } = assignment;

    const onClickHandler = useCallback(() => {
      if (!onClick) return;

      switch (typeOfAssignment) {
        case AssignmentType.Acquisition: {
          const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case AssignmentType.AcquisitionObject: {
          const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case AssignmentType.Object: {
          const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case AssignmentType.ObjectType: {
          const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case AssignmentType.Project: {
          const path = route(PROJECTROUTES.DETAIL.URI, { id });
          onClick(path);
          break;
        }
        default: {
          break;
        }
      }
    }, [id, onClick, typeOfAssignment]);

    return (
      <Pill
        label={displayName}
        color={color || Colors.Primary}
        icon="folder-open"
        solid
        onClick={!onClick ? undefined : onClickHandler}
      />
    );
  }
);
