import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditMarketingFloorplansComponent,
  ObjectTypesEditMarketingFloorplansComponentProps
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { connect } from "react-redux";
import { path } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  path: string;
}
interface DispatchProps {
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <
  StateProps,
  ObjectTypesEditMarketingFloorplansComponentProps
>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;

  return {
    currentType,
    path: path(state)
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesEditMarketingFloorplansComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type ObjectTypesEditMarketingFloorplansContainerProps = StateProps &
  DispatchProps;
export const ObjectTypesEditMarketingFloorplansContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditMarketingFloorplansComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditMarketingFloorplansComponent);
