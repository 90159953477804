import { SyncStatus } from "@haywork/api/mail";
import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState, LoggingActions, LogMessage } from "@haywork/stores";
import { EmailActionsV2, EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { hiddenShareIds } from "../../selectors";
import { EmailComponent, EmailComponentProps } from "./email";
import { DraftsActions } from "@haywork/stores/email-v2/drafts";

type StateProps = {
  folders: EmailFolder[];
  hiddenShareIds: string[];
};
type DispatchProps = {
  checkAndUpdateMessage: (messageId: string) => Promise<void>;
  appendMessage: (
    messageId: string,
    accountId: string,
    folderId: string,
    silent: boolean
  ) => Promise<void>;
  deleteMessage: (messageId: string) => void;
  refreshAccountFolders: (accountId: string) => Promise<void>;
  deleteFolder: (id: string) => void;
  setAccountSyncStatus: (accountId: string, syncStatus: SyncStatus) => void;
  setAccountInboxUnreadCount: (accountId: string, unreadCount: number) => void;
  addLogging: (message: LogMessage) => void;
  refreshDraftFolder: (accountId: string) => Promise<void>;
  removeDraft: (id: string) => void;
  refreshDraft: (accountId: string, draftId: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  EmailComponentProps,
  AppState
> = (state) => {
  const { folders } = state.emailV2.folders;

  return {
    folders,
    hiddenShareIds: hiddenShareIds(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EmailComponentProps
> = (dispatch) => ({
  checkAndUpdateMessage: (messageId: string) =>
    dispatch(EmailThunk.Messages.checkAndUpdateMessage(messageId)),
  appendMessage: (
    messageId: string,
    accountId: string,
    folderId: string,
    silent: boolean
  ) =>
    dispatch(
      EmailThunk.Messages.appendMessage(messageId, accountId, folderId, silent)
    ),
  deleteMessage: (messageId: string) =>
    dispatch(EmailActionsV2.Messages.deleteMessage(messageId)),
  refreshAccountFolders: (accountId: string) =>
    dispatch(EmailThunk.Folders.refreshAccountFolders(accountId)),
  deleteFolder: (id: string) =>
    dispatch(EmailActionsV2.Folders.deleteFolder(id)),
  setAccountSyncStatus: (accountId: string, syncStatus: SyncStatus) =>
    dispatch(
      EmailActionsV2.Accounts.setAccountSyncStatus(accountId, syncStatus)
    ),
  setAccountInboxUnreadCount: (accountId: string, unreadCount: number) =>
    dispatch(
      EmailActionsV2.Folders.setAccountInboxUnreadCount(accountId, unreadCount)
    ),
  addLogging: (message: LogMessage) =>
    dispatch(LoggingActions.addLogging(message)),
  refreshDraftFolder: (accountId: string) =>
    dispatch(EmailThunk.Drafts.refreshDraftFolder(accountId)),
  removeDraft: (id: string) => dispatch(DraftsActions.deleteDraft(id)),
  refreshDraft: (accountId: string, draftId: string) =>
    dispatch(EmailThunk.Drafts.refreshDraft(accountId, draftId)),
});

export type EmailContainerProps = StateProps & DispatchProps;
export const EmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailComponent);
