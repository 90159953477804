import {
  MAINROUTES,
  MAINROUTES_URIS,
  REDUX,
  REQUEST,
} from "@haywork/constants";
import { EditableCalleeType } from "@haywork/enum";
import { FolderTreeEntityType } from "@haywork/middleware";
import { ConfirmConfig } from "@haywork/stores";
import * as deepEqual from "deep-equal";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import { LocationChangeAction, LOCATION_CHANGE } from "connected-react-router";
import { Action } from "redux";
import * as ActionType from "./editable.types";
import { RootEntityType } from "@haywork/api/event-center";
import {
  ProjectAssignment,
  ObjectTypeAssignment,
  ObjectAssignment,
  Task,
  ContactCompany,
  ContactPerson,
  SearchAssignment,
  Invoice,
  AgendaItemsSingleItemResponse,
  AcquisitionAssignment,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";

export type EditableChangedEntity =
  | ObjectAssignment
  | ProjectAssignment
  | ObjectTypeAssignment
  | AcquisitionAssignment
  | AcquisitionObjectAssignment
  | AgendaItemsSingleItemResponse
  | Task
  | ContactCompany
  | ContactPerson
  | SearchAssignment
  | Invoice;

export interface EditableItem {
  path: string;
  currentPath: string;
  componentState: any;
  title: string;
  icon: string;
  active: boolean;
  hasChanges: boolean;
  preppedForSave: boolean;
  confirm?: ConfirmConfig;
  caller?: string;
  calleeType?: EditableCalleeType;
  calleeId?: string;
  calleeState?: string;
  entityType: RootEntityType | null;
  entityId?: string;
  hasNewerExternalChanges?: boolean;
  externalChangesData?: {
    dateTimeModified: Date;
    modifiedBy: string;
    updatedEntity: EditableChangedEntity;
  };
  referrer?: string;
  openedOn: Date;
}

export interface EditableState {
  states?: EditableItem[];
  mainReturnPath?: string;
  currentPath?: string;
  currentComponentState?: any;
  previousPath?: string;
}

const INITIAL_STATE: EditableState = {
  states: [],
  mainReturnPath: MAINROUTES.DASHBOARD.URI,
  currentPath: null,
  currentComponentState: null,
  previousPath: location.pathname,
};

export const editableReducer = (
  state: EditableState = INITIAL_STATE,
  action: Action
): EditableState => {
  switch (action.type) {
    case REDUX.EDITABLE.ADD_STATE: {
      const {
        path,
        componentState,
        title,
        icon,
        confirm,
        caller,
        hasChanges,
        entityType,
        entityId,
      } = <ActionType.Item>action;
      const matches = state.states.filter((state) =>
        new RegExp(escapeRegExp(state.path), "gi").test(path)
      );

      if (isEmpty(matches)) {
        const referrer =
          state.currentPath === path ? state.previousPath : state.currentPath;

        const newState: EditableItem = {
          path,
          currentPath: path,
          componentState,
          title,
          icon,
          active: true,
          hasChanges: !!hasChanges,
          confirm,
          preppedForSave: false,
          caller,
          entityType,
          entityId,
          referrer,
          openedOn: new Date(),
        };
        const states = [...state.states, newState];
        return { ...state, states, currentComponentState: componentState };
      }

      return { ...state };
    }
    case LOCATION_CHANGE: {
      const { payload } = <LocationChangeAction>action;
      const pathname = payload.location.pathname;
      let currentComponentState = state.currentComponentState;

      const mainReturnPath =
        MAINROUTES_URIS.find((uri) => uri === pathname) || state.mainReturnPath;
      const states = state.states
        .filter((editable) => !!editable)
        .map((editable) => {
          const matched = new RegExp(escapeRegExp(editable.path), "gi").test(
            pathname
          );
          if (matched) {
            const openedOn =
              !/\/edit/gi.test(editable.currentPath) &&
              /\/edit/gi.test(pathname)
                ? new Date()
                : editable.openedOn;

            const updatedEditable = {
              ...editable,
              currentPath: pathname,
              active: true,
              openedOn,
            };
            currentComponentState = updatedEditable.componentState;
            return updatedEditable;
          }
          return {
            ...editable,
            active: false,
          };
        });

      return {
        ...state,
        states,
        mainReturnPath,
        currentPath: pathname,
        currentComponentState,
        previousPath: state.currentPath,
      };
    }
    case REDUX.EDITABLE.UPDATE_COMPONENT_STATE: {
      const { componentState, path, ignoreChanges, resetExternalChanges } = <
        ActionType.ComponentStateWithPath
      >action;
      let currentComponentState = state.currentComponentState;

      const states = state.states.map((editable) => {
        if (editable.path === path) {
          const hasChanges = editable.hasChanges
            ? true
            : !!editable.componentState &&
              !deepEqual(componentState, editable.componentState);
          if (state.currentPath && state.currentPath.includes(path)) {
            currentComponentState = componentState;
          }

          return {
            ...editable,
            componentState,
            hasChanges: ignoreChanges ? false : hasChanges,
            hasNewerExternalChanges: !!resetExternalChanges
              ? undefined
              : editable.hasNewerExternalChanges,
            externalChangesData: !!resetExternalChanges
              ? undefined
              : editable.externalChangesData,
          };
        }
        return editable;
      });

      return { ...state, states, currentComponentState };
    }
    case REDUX.EDITABLE.UPDATE_SINGLE_COMPONENT_STATE: {
      const { componentState, path } = <ActionType.ComponentStateWithPath>(
        action
      );

      const states = state.states.map((editable) => {
        if (editable.path === path) {
          const hasChanges = editable.hasChanges
            ? true
            : !!editable.componentState &&
              !deepEqual(componentState, editable.componentState);
          return { ...editable, componentState, hasChanges };
        }
        return editable;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.REMOVE_STATE: {
      const { path } = <ActionType.Path>action;
      const stateToRemove = state.states.find(
        (cmpState) => cmpState.path === path
      );
      if (!stateToRemove) return state;

      const states = state.states.reduce((state, editable) => {
        let ref = { ...editable };
        const matched = new RegExp(escapeRegExp(ref.referrer), "gi").test(
          escapeRegExp(stateToRemove.currentPath)
        );

        if (matched) {
          const isSelf = new RegExp(
            escapeRegExp(stateToRemove.referrer),
            "gi"
          ).test(escapeRegExp(ref.currentPath));

          ref = {
            ...ref,
            referrer: isSelf ? undefined : stateToRemove.referrer,
          };
        }

        if (ref.path !== path) {
          state.push(ref);
        }

        return state;
      }, [] as EditableItem[]);

      const currentComponentState =
        states.length === 0 ? undefined : { ...state.currentComponentState };

      return { ...state, states, currentComponentState };
    }
    case REDUX.EDITABLE.UPDATE_TAB_TITLE: {
      const { path, title } = <ActionType.Title>action;
      const states = state.states.map((state) => {
        if (state.path === path) {
          return { ...state, title };
        }
        return state;
      });

      return { ...state, states };
    }
    case REDUX.EDITABLE.SET_HAS_CHANGES: {
      const { path, hasChanges } = <ActionType.Changes>action;

      const states = state.states.map((editable) => {
        if (path === editable.path) {
          return {
            ...editable,
            hasChanges: hasChanges === undefined ? true : hasChanges,
          };
        }
        return editable;
      });

      return { ...state, states };
    }
    case REDUX.EDITABLE.SET_PREPPED_FOR_SAVE: {
      const { path } = <ActionType.Path>action;

      const states = state.states.map((editable) => {
        if (path === editable.path) {
          return { ...editable, preppedForSave: true };
        }
        return editable;
      });

      return { ...state, states };
    }
    case REDUX.EDITABLE.DISABLE_PREPPED_FOR_SAVE: {
      const { path } = <ActionType.Path>action;

      const states = state.states.map((editable) => {
        if (path === editable.path) {
          return { ...editable, preppedForSave: false };
        }
        return editable;
      });

      return { ...state, states };
    }
    case REDUX.EDITABLE.CLEAR_ALL: {
      return INITIAL_STATE;
    }
    case REDUX.ACCESS.LOGOUT: {
      return INITIAL_STATE;
    }
    case REDUX.EDITABLE.SET_CALLEE: {
      const { path, calleeType, calleeId } = <ActionType.Callee>action;
      const states = state.states.map((s) => {
        if (s.path === path) {
          return {
            ...s,
            calleeType,
            calleeId,
            calleeState: REQUEST.IDLE,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.SET_CALLER: {
      const { path, caller } = <ActionType.Caller>action;
      const states = state.states.map((s) => {
        if (s.path === path) {
          return {
            ...s,
            caller,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.SET_CALLEE_ID: {
      const { id, path } = <ActionType.CalleeId>action;

      const states = state.states.map((s) => {
        if (s.currentPath === path) {
          return {
            ...s,
            calleeId: id,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.REMOVE_CALLEE: {
      const { path } = <ActionType.Path>action;
      const states = state.states.map((s) => {
        if (s.path === path) {
          return {
            ...s,
            calleeId: undefined,
            calleeType: undefined,
            calleeState: undefined,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.REMOVE_CALLER: {
      const { path } = <ActionType.Path>action;

      const states = state.states.map((s) => {
        if (s.path === path) {
          return {
            ...s,
            caller: undefined,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.SET_CALLEE_STATE: {
      const { path, calleeState } = <ActionType.CalleeState>action;

      const states = state.states.map((s) => {
        if (s.path === path) {
          return {
            ...s,
            calleeState,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.CHANGE_EDITABLE_PATH: {
      const { oldPath, newPath, hasChanges } = <ActionType.ChangePaths>action;

      const states = state.states.map((s) => {
        if (s.path === oldPath) {
          return {
            ...s,
            path: newPath,
            hasChanges: hasChanges !== undefined ? hasChanges : s.hasChanges,
          };
        }
        return s;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityId, entityType } = <any>action;

      switch (entityType) {
        case FolderTreeEntityType.ObjectAssignment: {
          const states = state.states.map((item) => {
            if (get(item.componentState, "objectAssignment.id") === entityId) {
              return {
                ...item,
                componentState: {
                  ...item.componentState,
                  objectAssignment: {
                    ...item.componentState.objectAssignment,
                    linkedFolderTree: {
                      id,
                    },
                  },
                },
              };
            }
            return item;
          });

          return {
            ...state,
            states,
            currentComponentState:
              get(state.currentComponentState, "objectAssignment.id") ===
              entityId
                ? {
                    ...state.currentComponentState,
                    objectAssignment: {
                      ...state.currentComponentState.objectAssignment,
                      linkedFolderTree: {
                        id,
                      },
                    },
                  }
                : state.currentComponentState,
          };
        }
        case FolderTreeEntityType.ProjectAssignment: {
          const states = state.states.map((item) => {
            if (get(item.componentState, "projectAssignment.id") === entityId) {
              return {
                ...item,
                componentState: {
                  ...item.componentState,
                  projectAssignment: {
                    ...item.componentState.projectAssignment,
                    linkedFolderTree: {
                      id,
                    },
                  },
                },
              };
            }
            return item;
          });

          return {
            ...state,
            states,
            currentComponentState:
              get(state.currentComponentState, "projectAssignment.id") ===
              entityId
                ? {
                    ...state.currentComponentState,
                    projectAssignment: {
                      ...state.currentComponentState.projectAssignment,
                      linkedFolderTree: {
                        id,
                      },
                    },
                  }
                : state.currentComponentState,
          };
        }
        default: {
          const states = state.states.map((item) => {
            if (get(item.componentState, "id") === entityId) {
              return {
                ...item,
                componentState: {
                  ...item.componentState,
                  linkedFolderTree: {
                    id,
                  },
                },
              };
            }
            return item;
          });

          return {
            ...state,
            states,
            currentComponentState:
              get(state.currentComponentState, "id") === entityId
                ? {
                    ...state.currentComponentState,
                    linkedFolderTree: {
                      id,
                    },
                  }
                : state.currentComponentState,
          };
        }
      }
    }
    case REDUX.EDITABLE.SET_EXTERNAL_CHANGES: {
      const {
        entityType,
        entityId,
        dateTimeModified,
        modifiedBy,
        updatedEntity,
      } = <ActionType.HasExternalChanges>action;

      const states = state.states.map((editable) => {
        if (
          editable.entityId === entityId &&
          editable.entityType === entityType
        ) {
          return {
            ...editable,
            hasNewerExternalChanges: true,
            externalChangesData: {
              dateTimeModified,
              modifiedBy,
              updatedEntity,
            },
          };
        }
        return editable;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.REMOVE_EXTERNAL_CHANGES: {
      const { entityType, entityId } = <ActionType.RemoveExternalChanges>action;

      const states = state.states.map((editable) => {
        if (
          editable.entityId === entityId &&
          editable.entityType === entityType
        ) {
          return {
            ...editable,
            hasNewerExternalChanges: undefined,
            externalChangesData: undefined,
          };
        }
        return editable;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.EDITABLE.UPDATE_REFERRER: {
      const { referrer, path } = <ActionType.Referrer>action;
      const states = state.states.map((editable) => {
        const matched = new RegExp(escapeRegExp(editable.path), "gi").test(
          path
        );

        if (matched) {
          return {
            ...editable,
            referrer,
          };
        }
        return editable;
      });

      return {
        ...state,
        states,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
