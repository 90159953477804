import { TemplateDefinition } from "@haywork/api/kolibri";
import { EMAILSIGNATURESROUTES } from "@haywork/constants";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { editable } from "@haywork/modules/editable";
import { AppState, EmailActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEmailSignatureEditComponent,
  SettingsEmailSignatureEditComponentProps
} from "../components/edit/edit.component";

interface StateProps {
  status: string;
  template: TemplateDefinition;
  culture: string;
  uploadUrl: string;
}
interface DispatchProps {
  saveTemplate: (template: TemplateDefinition) => Promise<void>;
  saveAndCloseSignature: (
    templateDefinition: TemplateDefinition
  ) => Promise<void>;
  getSignaturePreview: (
    template: TemplateDefinition
  ) => Promise<TemplateDefinition>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEmailSignatureEditComponentProps,
  AppState
> = (state) => {
  const { singleStatus: status } = state.email.templates;
  const { currentComponentState: template } = state.editable;
  const { culture } = state.main;
  const { currentRealestateAgency } = state.account;
  const { apiVersion, host } = state.appSettings;

  const uploadUrl = `${host}/${apiVersion}/${currentRealestateAgency.id}/Blobs/Upload`;

  return {
    status,
    template,
    culture,
    uploadUrl
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEmailSignatureEditComponentProps
> = (dispatch) => ({
  saveTemplate: (template: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.saveSignature(template)),
  saveAndCloseSignature: (templateDefinition: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.saveAndCloseSignature(templateDefinition)),
  getSignaturePreview: (template: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.getSignaturePreview(template))
});

export type SettingsEmailSignatureEditContainerProps = StateProps &
  DispatchProps;
export const SettingsEmailSignatureEditContainer = editable(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsEmailSignatureEditComponent),
  {
    entityType: null,
    icon: "file-signature",
    thunk: EmailTemplatesThunks.readSignature,
    status: "email.templates.singleStatus",
    statePath: "email.templates.single",
    action: EmailActions.Templates.setSingle,
    route: EMAILSIGNATURESROUTES.DETAIL.URI,
    confirm: {
      title: { key: "emailSignatureConfirmTitle" },
      body: { key: "emailSignatureConfirmBody" }
    }
  }
);
