import { ProjectAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./widgets.component.scss");

interface ProjectDetailGeneralWidgetsPartnersComponentProps {
  project: ProjectAssignment;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailGeneralWidgetsDatesComponent extends React.Component<
  ProjectDetailGeneralWidgetsPartnersComponentProps
> {
  public render() {
    const {
      buildingStartText,
      deliveryStartText,
      saleStartText,
      project,
    } = this.props;
    const { deliveryStart, saleStart, buildingStart, forRent } = project;

    if (
      !project ||
      (!buildingStartText &&
        !buildingStart &&
        !deliveryStartText &&
        !deliveryStart &&
        !saleStart &&
        !saleStartText)
    )
      return null;

    return (
      <div styleName="widget">
        <div styleName="title">
          <ResourceText resourceKey="projectTypesDetailDatesTitle" />
        </div>
        {(!!buildingStartText ||
          !!buildingStart ||
          !!deliveryStartText ||
          !!deliveryStart) && (
          <div styleName="row">
            {(!!buildingStartText || !!buildingStart) && (
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailStartBuild" />
                </h3>
                <div styleName="range" data-cy="CY-buildStartText">
                  {this.renderDateText(buildingStartText, buildingStart)}
                </div>
              </div>
            )}
            {(!!deliveryStartText || !!deliveryStart) && (
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailStartDelivery" />
                </h3>
                <div styleName="range" data-cy="CY-deliveryStartText">
                  {this.renderDateText(deliveryStartText, deliveryStart)}
                </div>
              </div>
            )}
          </div>
        )}
        {(!!saleStart || !!saleStartText) && (
          <div styleName="row">
            <div styleName="column">
              <h3>
                <ResourceText
                  resourceKey={
                    !!forRent
                      ? "projectTypesDetailStartRent"
                      : "projectTypesDetailStartSale"
                  }
                />
              </h3>
              <div styleName="range" data-cy="CY-saleStartText">
                {this.renderDateText(saleStartText, saleStart)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderDateText(text: string, date: Date) {
    const fragments = [];
    let idx = 0;

    if (!!text) {
      fragments.push(<span key={idx}>{text}</span>);
      idx++;
    }

    if (!!date) {
      if (!!text) {
        fragments.push(<span key={idx}>|</span>);
        idx++;
      }

      fragments.push(
        <FormattedDate
          value={date}
          day="2-digit"
          month="long"
          year="numeric"
          key={idx}
        />
      );
    }

    return <React.Fragment children={fragments} />;
  }
}
