import { CompanySettings } from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./company.types";

export interface CompanyState {
  settings: CompanySettings;
  customCompanySettings: { [key: string]: any } | null | "";
  settingsStatus: string;
  collegialListingsStatus: string;
}

const INITIAL_STATE: CompanyState = {
  settings: null,
  customCompanySettings: null,
  settingsStatus: REQUEST.IDLE,
  collegialListingsStatus: REQUEST.IDLE
};

export const companyReducer = (
  state: CompanyState = INITIAL_STATE,
  action: Action
): CompanyState => {
  switch (action.type) {
    case REDUX.COMPANY.SET_COMPANY_SETTINGS: {
      const { settings } = <ActionType.CompanySettings>action;
      let customCompanySettings = null;

      if (!!settings.customCompanySettings) {
        customCompanySettings = JSON.parse(settings.customCompanySettings);
      }

      return {
        ...state,
        settings,
        customCompanySettings,
        settingsStatus: REQUEST.SUCCESS,
        collegialListingsStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.COMPANY.SET_COMPANY_SETTINGS_STATUS: {
      const { settingsStatus } = <ActionType.SettingsStatus>action;

      return {
        ...state,
        settingsStatus
      };
    }
    case REDUX.COMPANY.SET_COLLEGIAL_SETTINGS_STATUS: {
      const { collegialListingsStatus } = <ActionType.CollegialListingsStatus>(
        action
      );

      return {
        ...state,
        collegialListingsStatus
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
