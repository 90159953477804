import { StatisticsSingleItemResponse, WeekStats } from "@haywork/api/kolibri";
import { MapDispatchToProps, StatisticsThunk } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import * as moment from "moment";
import { connect, MapStateToProps } from "react-redux";
import {
  DashboardOutstandingBrokerageWidgetComponent,
  DashboardOutstandingBrokerageWidgetComponentProps,
} from "../../components/widgets/outstanding-brokerage-widget.component";

interface StateProps {
  statisticsThisWeek: WeekStats;
  statisticsLastWeek: WeekStats;
  employeeStatisticsStatus: string;
}
interface DispatchProps {
  getEmployeeStatistics: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DashboardOutstandingBrokerageWidgetComponentProps,
  AppState
> = (state) => ({
  statisticsThisWeek: getStatisticsForWeek(
    state.statistics.employeeStatistics,
    moment().week()
  ),
  statisticsLastWeek: getStatisticsForWeek(
    state.statistics.employeeStatistics,
    moment().week() - 1
  ),
  employeeStatistics: state.statistics.employeeStatistics,
  employeeStatisticsStatus: state.statistics.employeeStatisticsStatus,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DashboardOutstandingBrokerageWidgetComponentProps
> = (dispatch) => ({
  getEmployeeStatistics: () =>
    dispatch(StatisticsThunk.getEmployeeStatistics()),
});

const getStatisticsForWeek = (
  statistics: StatisticsSingleItemResponse,
  weekNumber: number
): WeekStats => {
  if (!statistics) return;
  return statistics.weekStats.find(
    (statistics) => statistics.weekNr === weekNumber
  );
};

export type OutstandingBrokerageWidgetContainerProps = StateProps &
  DispatchProps;
export const OutstandingBrokerageWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardOutstandingBrokerageWidgetComponent);
