import { EventNotification, EventType } from "@haywork/api/event-center";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./reminders.types";

export interface RemindersState {
  reminders: EventNotification[];
  remindersStatus: string;
  remindersTotal: number;
  remindersModalVisible: boolean;
}

const INITIAL_STATE: RemindersState = {
  reminders: [],
  remindersStatus: REQUEST.IDLE,
  remindersTotal: 0,
  remindersModalVisible: false
};

export const remindersReducer = (
  state: RemindersState = INITIAL_STATE,
  action: Action
): RemindersState => {
  switch (action.type) {
    case REDUX.REMINDERS.SET_REMINDERS_STATUS: {
      const { remindersStatus } = <ActionType.RemindersStatus>action;

      return {
        ...state,
        remindersStatus
      };
    }
    case REDUX.REMINDERS.SET_REMINDERS: {
      const { reminders } = <ActionType.Reminders>action;

      return {
        ...state,
        reminders,
        remindersStatus: REQUEST.SUCCESS,
        remindersTotal: reminders.length,
        remindersModalVisible: reminders.length > 0
      };
    }
    case REDUX.REMINDERS.CLEAR_REMINDERS: {
      return {
        ...state,
        reminders: [],
        remindersStatus: REQUEST.SUCCESS,
        remindersTotal: 0
      };
    }
    case REDUX.REMINDERS.CLEAR_REMINDER: {
      const { id } = <ActionType.Id>action;
      const reminders = state.reminders.filter((reminder) => {
        switch (reminder.linkedEvent.eventType) {
          case EventType.AgendaItemReminder: {
            const item = reminder.linkedEvent.linkedAgendaItems.find(
              (agendaItem) => agendaItem.id === id
            );
            return !item;
          }
          case EventType.TaskReminder: {
            const item = reminder.linkedEvent.linkedTasks.find(
              (task) => task.id === id
            );
            return !item;
          }
          default:
            return true;
        }
      });

      return {
        ...state,
        reminders,
        remindersStatus: REQUEST.SUCCESS,
        remindersTotal: reminders.length
      };
    }
    case REDUX.REMINDERS.CLOSE_REMINDERS_MODAL: {
      return {
        ...state,
        remindersModalVisible: false
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
