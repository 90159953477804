import {
  LinkedRelation,
  MatchedSearchAssignmentSnapshot,
  SearchSearchAssignmentsResponse,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { RequestStatus } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { AssignmentWidgetsSearchAssignmentsItemComponent } from "./list-item.component";

const styles = require("./search-assignments.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface State {
  searchAssignments: MatchedSearchAssignmentSnapshot[];
  searchAssignmentsStatus: RequestStatus;
}
interface Props {
  assignmentId: string;
  onNavigateToSearchAssignment: (url: string) => void;
  getObjectAssignmentSearchAssignments: (
    assignmentId: string
  ) => Promise<SearchSearchAssignmentsResponse>;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentWidgetSearchAssignmentsComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      searchAssignments: [],
      searchAssignmentsStatus: RequestStatus.Idle,
    };
  }

  public async componentDidMount() {
    try {
      this.setState({ searchAssignmentsStatus: RequestStatus.Pending });

      const response = await this.props.getObjectAssignmentSearchAssignments(
        this.props.assignmentId
      );

      this.setState({
        searchAssignmentsStatus: RequestStatus.Success,
        searchAssignments: response.results || [],
      });
    } catch (error) {
      this.setState({ searchAssignmentsStatus: RequestStatus.Error });
      throw error;
    }
  }

  public render() {
    const { searchAssignments, searchAssignmentsStatus } = this.state;

    const linkedRelations: LinkedRelation[] = searchAssignments.reduce(
      (state, searchAssignment) => {
        if (
          searchAssignment.linkedRelations &&
          searchAssignment.linkedRelations.length > 0
        ) {
          state = [...state, ...searchAssignment.linkedRelations];
        }
        return state;
      },
      []
    );

    return (
      <div styleName="search-assignments">
        <h3 styleName="meta__title">
          <ResourceText resourceKey="searchers" />
        </h3>
        {searchAssignments.length > 0 && (
          <div styleName="search-assignments__result">
            {searchAssignments.map((searchAssignment, idx) => (
              <ErrorBoundary key={idx}>
                <AssignmentWidgetsSearchAssignmentsItemComponent
                  searchAssignment={searchAssignment}
                  onNavigateToSearchAssignment={
                    this.props.onNavigateToSearchAssignment
                  }
                />
              </ErrorBoundary>
            ))}
            <div styleName="actions">
              <NavLink
                to={route(ASSIGNMENTROUTES.SEARCH_ASSIGNMENTS.URI, {
                  id: this.props.assignmentId,
                })}
              >
                <ResourceText resourceKey="goToSearchers" />
              </NavLink>
            </div>
          </div>
        )}
        {searchAssignmentsStatus === RequestStatus.Pending && (
          <div styleName="search-assignments__loader">
            <PageLoader loading />
          </div>
        )}
        {searchAssignmentsStatus === RequestStatus.Success &&
          linkedRelations.length === 0 && (
            <div styleName="emptyState">
              <div styleName="text">
                <h2>
                  <ResourceText resourceKey="assignmentSearchAssignmentsEmptyStateTitleAlt" />
                </h2>
              </div>
            </div>
          )}
      </div>
    );
  }
}
