import { NotificationSettings } from "@haywork/api/event-center";
import { RelationSnapShot } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./forms.component.scss");

interface Props {
  setting: NotificationSettings;
  employees: RelationSnapShot[];
  onChange: (delegates: RelationSnapShot[]) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class DelegatesFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.formControls = {
      delegates: { value: get(this.props.setting, "delegates", []) },
    };
  }

  public render() {
    return (
      <Form
        name={`delegate.${this.props.setting.eventType.toString()}`}
        formControls={this.formControls}
        onChange={this.onChangeHandler}
      >
        <div className="form__row" styleName="row">
          <div className="form__group">
            <div className="column">
              <label className="pre">
                <ResourceText resourceKey="notificationSettingsLabelDelegates" />
              </label>
            </div>

            <div className="column__spacer" />

            <div className="column grow1">
              <Input.Query
                name="delegates"
                optionValue={this.renderQueryEmployeeOption}
                values={this.props.employees}
                matchOn={this.employeeQueryMatchOn}
                selectedValue={this.renderSelectedEmployeeValue}
                disableOption={this.disableOption}
                placeholder="eventNotificationDelegatesPlaceholder"
                multiple
                data-cy={"CY-delegates" + this.props.setting.eventType}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private renderQueryEmployeeOption(
    value: any,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private employeeQueryMatchOn(query: string, value: any): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedEmployeeValue(value: any): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private disableOption(value: RelationSnapShot, values: RelationSnapShot[]) {
    const match = values.find((relation) => relation.id === value.id);
    return !!match;
  }

  private onChangeHandler(values: FormReturnValue) {
    this.props.onChange(values.delegates);
  }
}
