import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryType,
  AgendaStandardDuration,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import PageHeader from "@haywork/components/ui/page-header";
import { COLOR_PICKER, REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  SchedulerCategoryContainerProps,
  SchedulerCategoryItemComponent,
  SettingsSchedulerCategoryDeleteModalComponent,
} from "@haywork/modules/settings";
import {
  ButtonLoader,
  InfiniteScroll,
  PageLoader,
  ResourceText,
  StickyConditions,
} from "@haywork/modules/shared";
import * as React from "react";
import { ColorResult, GithubPicker } from "react-color";
import * as CSSModules from "react-css-modules";

const styles = require("./scheduler-category.component.scss");
const colorPickerColors = [
  "#808080",
  "#BDC3C7",
  "#A9A9A9",
  "#C0C0C0",
  "#800000",
  "#E74C3C",
  "#BA2700",
  "#D35400",
  "#8E692D",
  "#E9E03F",
  "#808000",
  "#68CC66",
  "#349933",
  "#27AE60",
  "#2ECC71",
  "#16A085",
  "#4FB3B9",
  "#1273DE",
  "#2980B9",
  "#2C3E50",
  "#68A6F6",
  "#000080",
  "#8E44AD",
  "#9B59B6",
];

export interface SchedulerCategoryComponentProps {}
interface State {
  displayColorPicker: boolean;
  selectedColor: string;
  newCategoryName: string;
  newStandardDuration: AgendaStandardDuration;
  newNeedsToBeConfirmed: boolean;
  newCategoryType: AgendaItemCategoryType;
  showDeleteModal: boolean;
  agendaItemCategorySnapShotToDelete: AgendaItemCategorySnapShot;
}
type Props = SchedulerCategoryComponentProps & SchedulerCategoryContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SchedulerCategoryComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;
  private focusRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      selectedColor: COLOR_PICKER.DEFAULT_COLOR,
      newCategoryName: "",
      newStandardDuration: AgendaStandardDuration.OneHour,
      newNeedsToBeConfirmed: false,
      newCategoryType: null,
      showDeleteModal: false,
      agendaItemCategorySnapShotToDelete: null,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.onNewSchedulerCategorySubmit = this.onNewSchedulerCategorySubmit.bind(
      this
    );
    this.colorChanged = this.colorChanged.bind(this);
    this.deleteAndMoveAgendaitemCategory = this.deleteAndMoveAgendaitemCategory.bind(
      this
    );
    this.deleteAgendaItemCategoryItem = this.deleteAgendaItemCategoryItem.bind(
      this
    );

    this.formControls = {
      newCategoryName: { value: this.state.newCategoryName },
      newStandardDuration: { value: this.state.newStandardDuration },
      newCategoryType: { value: this.state.newCategoryType },
      newNeedsToBeConfirmed: { value: this.state.newNeedsToBeConfirmed },
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.schedulerAddCategoryStatus === REQUEST.PENDING &&
      nextProps.schedulerAddCategoryStatus === REQUEST.SUCCESS
    ) {
      this.setState({ selectedColor: COLOR_PICKER.DEFAULT_COLOR });
      this.formRef.update({
        newCategoryName: "",
        newStandardDuration: AgendaStandardDuration.OneHour,
        newNeedsToBeConfirmed: false,
      });
    }
  }

  public render() {
    return (
      <div
        styleName="schedulerCategoryBody"
        ref={(ref) => (this.focusRef = ref)}
      >
        <PageHeader title="manageSchedulerCategories" />

        <div styleName="addASchedulerCategory" className="section__container">
          <Form
            name="new-category"
            onChange={this.onFormChangeHandler}
            formControls={this.formControls}
            onSubmit={this.onNewSchedulerCategorySubmit}
            form={(ref) => (this.formRef = ref)}
          >
            <div styleName="form-row">
              <div styleName="picker">
                <label htmlFor="colorPicker">
                  <ResourceText resourceKey="color" />
                </label>
                <div
                  styleName="swatch"
                  onClick={() => {
                    this.setState({
                      displayColorPicker: !this.state.displayColorPicker,
                    });
                  }}
                >
                  <div styleName="arrow">
                    <i className="fal fa-chevron-down" />
                  </div>
                  <div
                    styleName="color"
                    style={{ backgroundColor: this.state.selectedColor }}
                  />
                </div>

                {this.state.displayColorPicker && (
                  <div styleName="popover">
                    <div
                      styleName="cover"
                      onClick={() => {
                        this.setState({ displayColorPicker: false });
                      }}
                    />
                    <GithubPicker
                      colors={colorPickerColors}
                      width="212px"
                      onChange={this.colorChanged}
                    />
                  </div>
                )}
              </div>

              <div styleName="description">
                <label htmlFor="newCategoryName">
                  <ResourceText resourceKey="whatIsTheNameOfTheCategory" />
                </label>
                <Input.Text
                  name="newCategoryName"
                  placeholder={intlContext.formatMessage({
                    id: "newSchedulerCategoryName",
                  })}
                  disabled={
                    this.props.schedulerAddCategoryStatus === REQUEST.PENDING
                  }
                  fireAllChanges
                />
              </div>

              <div styleName="drop">
                <label htmlFor="newCategoryType">
                  <ResourceText resourceKey="subCategoryOf" />
                </label>
                <Input.NewSelect
                  name="newCategoryType"
                  values={this.props.agendaItemCategoryTypes}
                  displayProp="displayName"
                  valuesProp="value"
                  disabled={
                    this.props.schedulerAddCategoryStatus === REQUEST.PENDING
                  }
                />
              </div>

              <div styleName="drop">
                {/* new category standard duration */}
                <label htmlFor="newStandardDuration">
                  <ResourceText resourceKey="standardDuration" />
                </label>
                <Input.NewSelect
                  name="newStandardDuration"
                  values={this.props.agendaItemCategoryTypes}
                  displayProp="displayName"
                  valuesProp="value"
                  disabled={
                    this.props.schedulerAddCategoryStatus === REQUEST.PENDING
                  }
                />
              </div>

              <div styleName="statistics">
                {/* new category needs to be confirmed */}
                <Input.Switch
                  name="newNeedsToBeConfirmed"
                  label="needsToBeConfirmed"
                  disabled={
                    this.props.schedulerAddCategoryStatus === REQUEST.PENDING
                  }
                  on={true}
                  off={false}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!this.state.newCategoryName}
              >
                <ButtonLoader
                  resourceKey="addSchedulerCategoryButton"
                  loading={
                    this.props.schedulerAddCategoryStatus === REQUEST.PENDING
                  }
                />
              </button>
            </div>
          </Form>
        </div>
        <div styleName="list-body">
          {this.props.schedulerCategoryStatus === REQUEST.PENDING && (
            <PageLoader loading />
          )}
          {this.props.agendaItemCategories.length > 0 && (
            <InfiniteScroll
              scrollEnd={() => {
                // do nothing at scroll end
              }}
              sticky={this.renderStickyConditions()}
            >
              {this.props.agendaItemCategories.map(
                (agendaItemCategory, idx) => {
                  const { latestAgendaItemCategoryAdded } = this.props;
                  const shouldHaveFocus =
                    latestAgendaItemCategoryAdded &&
                    latestAgendaItemCategoryAdded.id === agendaItemCategory.id;

                  return (
                    <ErrorBoundary key={agendaItemCategory.id}>
                      <SchedulerCategoryItemComponent
                        agendaItemCategory={agendaItemCategory}
                        agendaItemCategoryChanged={
                          null /*this.props.updateAgendaItemCategory*/
                        }
                        deleteAgendaItemCategoryItem={
                          this.deleteAgendaItemCategoryItem
                        }
                        zebra={idx % 2 === 0}
                        shouldHaveFocus={shouldHaveFocus}
                        agendaStandardDurationOptions={
                          this.props.agendaStandardDurationOptions
                        }
                        agendaItemCategoryTypes={
                          this.props.agendaItemCategoryTypes
                        }
                        navigate={null /*this.props.navigate*/}
                      />
                    </ErrorBoundary>
                  );
                }
              )}
            </InfiniteScroll>
          )}
        </div>
        <SettingsSchedulerCategoryDeleteModalComponent
          visible={this.state.showDeleteModal}
          onClose={() => this.setState({ showDeleteModal: false })}
          agendaItemCategorySnapShot={
            this.state.agendaItemCategorySnapShotToDelete
          }
          agendaItemCategoryStatistics={this.props.agendaItemCategoryStatistics}
          agendaitemCategories={this.props.agendaItemCategories}
          onDelete={this.deleteAndMoveAgendaitemCategory}
        />
      </div>
    );
  }

  private deleteAgendaItemCategoryItem(
    agendaItemCategorySnapShotToDelete: AgendaItemCategorySnapShot
  ) {
    this.setState({
      showDeleteModal: true,
      agendaItemCategorySnapShotToDelete,
    });
  }

  private deleteAndMoveAgendaitemCategory(
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo: AgendaItemCategorySnapShot
  ) {
    // this.props.deleteAgendaItemCategoryItem(categoryToDelete, categoryToMoveItemsTo);
    this.setState({ showDeleteModal: false });
  }

  private renderStickyConditions(): StickyConditions<
    AgendaItemCategorySnapShot
  > {
    return {
      main: {
        path: "agendaItemCategory",
        matchFn: (agendaItemCategory) => ({
          match: null,
          hideSubs: false,
          display: (task) => (
            <ResourceText resourceKey="AllSchedulerCategories" />
          ),
        }),
      },
      sub: {
        path: "agendaItemCategory",
        matchFn: (agendaItemCategory) => ({
          match: agendaItemCategory.categoryType,
          display: (agendaItemCategory) =>
            agendaItemCategory.categoryType ? (
              <ResourceText
                masterKey="agendaItemCategoryTypes"
                resourceKey={agendaItemCategory.categoryType.toString()}
              />
            ) : (
              <ResourceText resourceKey="noCategory" />
            ),
        }),
      },
    };
  }

  private onFormChangeHandler(values: FormReturnValue) {
    this.setState({
      newCategoryName: values.newCategoryName,
      newNeedsToBeConfirmed: values.newNeedsToBeConfirmed,
      newStandardDuration: values.newStandardDuration,
      newCategoryType: values.newCategoryType,
    });
  }

  private onNewSchedulerCategorySubmit(values: FormReturnValue) {
    this.onAddSchedulerCategory();
  }

  private onAddSchedulerCategory() {
    if (
      this.state.newCategoryName &&
      this.state.selectedColor &&
      this.props.schedulerAddCategoryStatus !== REQUEST.PENDING
    ) {
      // this.props.addNewAgendaItemCategory(
      //   ColorUtil.rgbToHex(this.state.selectedColor),
      //   this.state.newCategoryName,
      //   this.state.newStandardDuration,
      //   this.state.newNeedsToBeConfirmed,
      //   this.state.newCategoryType
      // );
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      this.onAddSchedulerCategory();
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    value.trim().length > 0
      ? this.setState({ newCategoryName: value })
      : this.setState({ newCategoryName: "" });
  }

  private colorChanged(color: ColorResult) {
    this.setState({ selectedColor: color.hex, displayColorPicker: false });
  }
}
