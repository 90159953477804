import { combineReducers } from "redux";

import {
  AppClientsActions,
  AppClientsState,
  appClientsReducer
} from "./app-clients";
import {
  SingleAppClientActions,
  SingleAppClientState,
  singleAppClientReducer
} from "./single-app-client";
import { WidgetsActions, WidgetsState, widgetsReducer } from "./widgets";

const authorizationReducer = combineReducers({
  appClients: appClientsReducer,
  singleAppClient: singleAppClientReducer,
  widgets: widgetsReducer
});

interface AuthorizationState {
  appClients: AppClientsState;
  singleAppClient: SingleAppClientState;
  widgets: WidgetsState;
}

const AuthorizationActions = {
  AppClients: AppClientsActions,
  SingleAppClient: SingleAppClientActions,
  Widgets: WidgetsActions
};

export { authorizationReducer, AuthorizationState, AuthorizationActions };
