import * as React from "react";
import { memo } from "react";
import { DefaultFolderTreesGroup } from "../../selectors";
import { DefaultFolderTreeSnapShot } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import ListItem from "../list-item";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  group: DefaultFolderTreesGroup;
  openDefaultFolderTree: (category: DefaultFolderTreeSnapShot) => void;
}
export const SettingsDossierFoldersListGroupComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ group, openDefaultFolderTree }: Props) => {
      if (!group || !group.categories || !group.categories.length) return null;

      return (
        <div>
          <div styleName="header">
            <ResourceText
              resourceKey={`dossierFolder.groupType.${group.type}`}
            />
          </div>
          <div>
            {group.categories.map((category, idx) => (
              <ListItem
                category={category}
                key={category.id + idx}
                zebra={idx % 2 !== 0}
                openDefaultFolderTree={openDefaultFolderTree}
              />
            ))}
          </div>
        </div>
      );
    }
  )
);
