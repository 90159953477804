import * as React from "react";
import { memo, useMemo, FC } from "react";
import { useIntl } from "react-intl";

interface Props {
  value: string;
  values?: { [key: string]: string | number };
  prefix?: string;
  fallback?: string;
  asHtml?: boolean;
}

export const I18nComponent: FC<Props> = memo(
  ({ value, values, prefix, fallback, asHtml }) => {
    const intl = useIntl();

    const translation = useMemo(() => {
      if (!intl || !value) return value;
      const id = [prefix, value].filter((d) => !!d).join(".") || "";
      return intl.formatMessage(
        {
          id,
          defaultMessage:
            process.env.NODE_ENV === "development"
              ? id
              : fallback || value || "",
        },
        values
      );
    }, [value, values, !!intl]);

    return !asHtml ? (
      <>{translation}</>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: translation }} />
    );
  }
);
