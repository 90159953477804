import { RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  Address,
  AssignmentOrderByField,
  AssignmentsClient,
  Employee,
  EmployeesClient,
  GeoClient,
  PhotoBlob,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  RelationType,
  SortOrder,
  AssignmentType
} from "@haywork/api/kolibri";
import { EMPLOYEEROUTES, REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  AccountActions,
  AppState,
  EditableActions,
  EmployeeActions
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const EMPTY_ADDRESS: Address = {
  adminAreaLevel1: null,
  adminAreaLevel2: null,
  street: null,
  sublocality: null,
  claimInProgress: false,
  usesHouseNumberRange: false,
  countryIso2: null
};

export interface EmployeeFilter {
  activeOrInactiveFilter: ActiveFilter | null;
  officeFilter: string | null;
}

const getEmployees = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setFetchEmployeeOverviewState(REQUEST.PENDING));
    if (init) {
      dispatch(
        EmployeeActions.setInitialFetchEmployeeOverviewState(REQUEST.PENDING)
      );
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeOverviewPage } = state.employee;
    const { host } = state.appSettings;
    const { activeOrInactiveFilter } = state.employee.selectedEmployeeFilters;

    const Relations = new RelationsClient(host);

    try {
      const result = await parseRequest.response(
        Relations.search(
          {
            filterByActive: activeOrInactiveFilter,
            orderBy: RelationOrderByField.DisplayName,
            filterByRelationTypes: [RelationType.Employee],
            skip: init ? 0 : employeeOverviewPage * take,
            take,
            order: SortOrder.Ascending,
            includeStatistics: true
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(EmployeeActions.setEmployees(result))
        : dispatch(EmployeeActions.appendEmployee(result));

      dispatch(EmployeeActions.setFetchEmployeeOverviewState(REQUEST.SUCCESS));
    } catch (error) {
      throw error;
    }
  };
};

const archiveEmployee = (
  employee: RelationSnapShot,
  afterCountdown: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Employees = new EmployeesClient(host);
    const RelationEmployees = new RelationsClient(host);

    try {
      await parseRequest.response(
        Employees.archive({ id: employee.id }, realEstateAgencyId)
      );

      const relations = await parseRequest.response(
        RelationEmployees.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office]
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveEmployee = (employee: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Employees = new EmployeesClient(host);
    const RelationEmployees = new RelationsClient(host);

    try {
      await parseRequest.response(
        Employees.unarchive({ id: employee.id }, realEstateAgencyId)
      );

      const relations = await parseRequest.response(
        RelationEmployees.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office]
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      throw error;
    }
  };
};

const getEmployeeInfo = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setEmployeeState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const Employees = new EmployeesClient(host);

    if (
      state.employee.selectedEmployee &&
      state.employee.selectedEmployeeId === id &&
      state.employee.selectedEmployee.isNew
    ) {
      dispatch(EmployeeActions.setEmployeeState(REQUEST.SUCCESS));
      return;
    }

    try {
      const result = await parseRequest.response(
        Employees.read(id, realEstateAgencyId)
      );

      const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, {
        id: result.employee.id
      });

      dispatch(
        EditableActions.addState({
          icon: "user-alt",
          componentState: result.employee,
          path,
          title: result.employee.firstName,
          entityType: RootEntityType.Employee,
          entityId: result.employee.id
        })
      );
      dispatch(EmployeeActions.setEmployee(result));
      dispatch(EmployeeActions.setEmployeeState(REQUEST.SUCCESS));
    } catch (error) {
      throw error;
    }
  };
};

const getAssignmentsForRelationDetail = (
  employeeId: string,
  init: boolean = true,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setEmployeeState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { relationAssignmentPage } = state.relation.detailAssignment;
    const { host } = state.appSettings;

    const Assignments = new AssignmentsClient(host);

    try {
      const assignments = await parseRequest.response(
        Assignments.search(
          {
            employeeIds: [employeeId],
            filterByAssignmentTypes: [
              AssignmentType.Object,
              AssignmentType.Project,
              AssignmentType.ObjectType,
              AssignmentType.AcquisitionObject
            ],
            forSale: true,
            forRent: true,
            orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOrInactive,
            skip: init ? 0 : relationAssignmentPage * take,
            take,
            order: SortOrder.Ascending
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(EmployeeActions.searchAssignments(assignments))
        : dispatch(EmployeeActions.appendAssignments(assignments));
      dispatch(EmployeeActions.setEmployeeState(REQUEST.SUCCESS));
    } catch (error) {
      throw error;
    }
  };
};

const removeEmployeeAddress = (
  isPostalAddress: boolean,
  employeeId: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
      id: employeeId
    });

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...state.editable.currentComponentState,
            postalAddressDiffersVisitAddress: false,
            postalAddress: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2
            }
          },
          path: route
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            address: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2
            }
          },
          path: route
        })
      );
    }
  };
};

const addManualAddress = (
  newAddress: string,
  isPostalAddress: boolean,
  employeeId: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
      id: employeeId
    });

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...state.editable.currentComponentState,
            postalAddress: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: newAddress, id: 0 }
            }
          },
          path: route
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            address: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: newAddress, id: 0 }
            }
          },
          path: route
        })
      );
    }
  };
};

const toggleEmployeePostalAddress = (employeeId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
      id: employeeId
    });

    dispatch(
      EditableActions.updateComponentState({
        componentState: {
          ...currentComponentState,
          postalAddressDiffersVisitAddress: !currentComponentState.postalAddressDiffersVisitAddress
        },
        path: route
      })
    );
  };
};

const searchAddress = (
  location: string,
  countryIso2: string,
  isPostalAddress: boolean,
  employeeId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setAddressSearchState(REQUEST.PENDING));

    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const route = RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
      id: employeeId
    });
    const { currentComponentState } = state.editable;

    const Geo = new GeoClient(host);

    try {
      const addressResponse = await parseRequest.response(
        Geo.addressSearch(
          {
            countryIso2,
            location,
            culture
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (isPostalAddress) {
        const postalAddress = !!addressResponse
          ? addressResponse
          : {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: location, id: 0 }
            };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, postalAddress },
            path: route
          })
        );
      } else {
        const address = !!addressResponse
          ? addressResponse
          : {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: location, id: 0 }
            };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, address },
            path: route
          })
        );
      }

      dispatch(EmployeeActions.setAddressSearchState(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(EmployeeActions.setAddressSearchState(REQUEST.ERROR));

      if (isPostalAddress) {
        const postalAddress = {
          ...EMPTY_ADDRESS,
          countryIso2: currentComponentState.address.countryIso2,
          street: { name: location, id: 0 }
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, postalAddress },
            path: route
          })
        );
      } else {
        const address = {
          ...EMPTY_ADDRESS,
          countryIso2: currentComponentState.address.countryIso2,
          street: { name: location, id: 0 }
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, address },
            path: route
          })
        );
      }

      throw error;
    }
  };
};

const saveEmployee = (employee: Employee, path: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setEmployeeSaveState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const route = RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
      id: employee.id
    });

    // Set displayname to null so API refreshes it.
    employee = {
      ...employee,
      displayName: null
    };

    const Employees = new EmployeesClient(host);
    const RelationEmployees = new RelationsClient(host);

    try {
      const employeeResponse = await parseRequest.response(
        Employees.save({ employee }, realEstateAgencyId)
      );

      dispatch(EmployeeActions.setEmployee(employeeResponse));
      dispatch(EmployeeActions.setEmployeeSaveState(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState: employeeResponse.employee,
          path: route,
          ignoreChanges: true
        })
      );
      dispatch(push(path));

      const relations = await parseRequest.response(
        RelationEmployees.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office]
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      dispatch(EmployeeActions.setEmployeeState(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateEmployeePassportPhoto = (avatar: PhotoBlob, employeeId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmployeeActions.setEmployeeSaveState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Employees = new EmployeesClient(host);

    try {
      let passportPhotoBlob = null;

      if (!!avatar) {
        passportPhotoBlob = await parseRequest.response(
          Employees.updatePassportPhoto(
            {
              fileDataId: avatar.fileDataId,
              fileExtension: avatar.fileExtension,
              fileName: avatar.fileName,
              fileSize: avatar.fileSize,
              height: avatar.height,
              md5Hash: avatar.md5Hash,
              parentId: employeeId
            },
            realEstateAgencyId
          ).then((response) => response.photoBlob)
        );
      } else {
        await parseRequest.response(
          Employees.removePassportPhoto(
            {
              parentId: employeeId
            },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EditableActions.updateComponentState({
          componentState: { ...currentComponentState, passportPhotoBlob },
          path: RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
            id: employeeId
          }),
          ignoreChanges: true
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const getAmountOfEmployees = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeOverviewPage } = state.employee;
    const { host } = state.appSettings;

    const Relations = new RelationsClient(host);

    try {
      const totalRelations = await parseRequest.response(
        Relations.search(
          {
            filterByActive: ActiveFilter.ActiveOnly,
            orderBy: RelationOrderByField.DisplayName,
            filterByRelationTypes: [RelationType.Employee],
            skip: 0,
            take: 0,
            order: SortOrder.Ascending,
            includeStatistics: false
          },
          realEstateAgencyId
        ).then((response) => response.totalResults)
      );

      dispatch(EmployeeActions.setTotalAmountOfEmployees(totalRelations));
    } catch (error) {
      throw error;
    }
  };
};

const updateSelectedEmployee = (employeeId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(getEmployeeInfo(employeeId));
  };
};

export const EmployeeThunk = {
  getEmployees,
  archiveEmployee,
  unArchiveEmployee,
  getEmployeeInfo,
  getAssignmentsForRelationDetail,
  removeEmployeeAddress,
  addManualAddress,
  toggleEmployeePostalAddress,
  searchAddress,
  saveEmployee,
  updateEmployeePassportPhoto,
  getAmountOfEmployees,
  updateSelectedEmployee
};
