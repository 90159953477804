import {
  ConstructionPeriodOption,
  ListingType,
  ObjectAssignment,
  RealEstateGroup,
  ResidentialSubtypeOther,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  constructionPeriods: ConstructionPeriodOption[];
  asSubForm?: boolean;
  showRequiredHints?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditContentFeaturesAndFiguresComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      constructionYear: {
        value: value(objectAssignment, "yearOfConstruction.constructionYear"),
        onChange: this.setConstructionPeriod.bind(this),
      },
      constructionPeriod: {
        value: value(objectAssignment, "yearOfConstruction.period"),
      },
      usableArea: { value: value(objectAssignment, "usableArea.area") },
      otherInsideSpaces: {
        value: value(objectAssignment, "otherInsideSpaces.area"),
      },
      buildingRelatedOutdoorSpace: {
        value: value(objectAssignment, "buildingRelatedOutdoorSpace.area"),
      },
      externalStorage: {
        value: value(objectAssignment, "externalStorage.area"),
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
      contents: { value: value(objectAssignment, "contents.volume") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update(
        {
          constructionYear: value(
            objectAssignment,
            "yearOfConstruction.constructionYear"
          ),
          constructionPeriod: value(
            objectAssignment,
            "yearOfConstruction.period"
          ),
          usableArea: value(objectAssignment, "usableArea.area"),
          otherInsideSpaces: value(objectAssignment, "otherInsideSpaces.area"),
          buildingRelatedOutdoorSpace: value(
            objectAssignment,
            "buildingRelatedOutdoorSpace.area"
          ),
          externalStorage: value(objectAssignment, "externalStorage.area"),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
          contents: value(objectAssignment, "contents.volume"),
        },
        true
      );
    }
  }

  public render() {
    const {
      listingType,
      realEstateGroup,
      residentialSubtypeOther,
    } = this.props.objectAssignment;

    const yearOfConstructionRequired =
      [
        ListingType.House,
        ListingType.Apartment,
        ListingType.ProductionHall,
        ListingType.HotelRestaurantCafe,
        ListingType.Office,
        ListingType.PracticeSpace,
        ListingType.RetailSpace,
      ].indexOf(listingType) !== -1;
    const usableAreaRequired =
      [ListingType.House, ListingType.Apartment].indexOf(listingType) !== -1 ||
      (realEstateGroup === RealEstateGroup.Residential &&
        listingType === ListingType.Other &&
        residentialSubtypeOther ===
          ResidentialSubtypeOther.ResidentialShopPremises);
    const contentsRequired =
      [ListingType.House, ListingType.Apartment].indexOf(listingType) !== -1 ||
      (realEstateGroup === RealEstateGroup.Residential &&
        listingType === ListingType.Other &&
        residentialSubtypeOther ===
          ResidentialSubtypeOther.ResidentialShopPremises);

    return (
      <div styleName="features-and-figures">
        <Form
          name="features-figures"
          formControls={this.formControls}
          onChange={this.onChangeHandler}
          form={(form) => (this.formRef = form)}
          asSubForm={this.props.asSubForm}
        >
          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <>
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="constructionYear">
                      <ResourceText resourceKey="constructionYear" />
                      {yearOfConstructionRequired &&
                        this.props.showRequiredHints !== false && (
                          <Ui.RequiredForPublish message="constructionYearOrPeriodRequired" />
                        )}
                    </label>
                    <Input.Number
                      name="constructionYear"
                      data-cy={"CY-YearOfConstruction"}
                      max={new Date().getFullYear() + 50}
                    />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="constructionPeriod">
                      <ResourceText resourceKey="constructionPeriod" />
                      {yearOfConstructionRequired &&
                        this.props.showRequiredHints !== false && (
                          <Ui.RequiredForPublish message="constructionYearOrPeriodRequired" />
                        )}
                    </label>
                    <Input.NewSelect
                      name="constructionPeriod"
                      addEmptyOption
                      emptyOptionLabel="makeAChoice"
                      values={this.props.constructionPeriods}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                </div>
              </div>
              <div className="form__divider" />
            </>
          )}

          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="usableArea" className="pre">
                    <ResourceText resourceKey="usableArea" />
                    {usableAreaRequired &&
                      this.props.showRequiredHints !== false && (
                        <Ui.RequiredForPublish />
                      )}
                  </label>
                </div>
                <div className="column">
                  <div className="input__helper">
                    <Input.Number
                      name="usableArea"
                      data-cy={"CY-UsableArea"}
                      pretty
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="otherInsideSpaces" className="pre">
                    <ResourceText resourceKey="otherInsideSpaces" />
                  </label>
                </div>
                <div className="column">
                  <div className="input__helper">
                    <Input.Number name="otherInsideSpaces" pretty />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="buildingRelatedOutdoorSpace" className="pre">
                    <ResourceText resourceKey="buildingRelatedOutdoorSpace" />
                  </label>
                </div>
                <div className="column">
                  <div className="input__helper">
                    <Input.Number name="buildingRelatedOutdoorSpace" pretty />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="externalStorage" className="pre">
                    <ResourceText resourceKey="externalStorage" />
                  </label>
                </div>
                <div className="column">
                  <div className="input__helper">
                    <Input.Number name="externalStorage" pretty />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="parcelSurface" className="pre">
                  <ResourceText resourceKey="parcelSurface" />
                  {this.props.objectAssignment.listingType ===
                    ListingType.House && <Ui.RequiredForPublish />}
                </label>
              </div>
              <div className="column">
                <div className="input__helper">
                  <Input.Number
                    name="parcelSurface"
                    data-cy={"CY-ParcelSurface"}
                    pretty
                  />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {![ListingType.Parking, ListingType.Plot].includes(listingType) && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="contents" className="pre">
                    <ResourceText resourceKey="contents" />
                    {this.props.showRequiredHints !== false && (
                      <Ui.RequiredForPublish />
                    )}
                  </label>
                </div>
                <div className="column">
                  <div className="input__helper">
                    <Input.Number
                      name="contents"
                      data-cy={"CY-Contents"}
                      pretty
                    />
                    <div className="post">
                      <ResourceText resourceKey="cubicMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const value = {
      yearOfConstruction: {
        ...objectAssignment.yearOfConstruction,
        constructionYear: values.constructionYear,
        period: values.constructionPeriod,
      },
      usableArea: {
        ...objectAssignment.usableArea,
        area: values.usableArea,
      },
      otherInsideSpaces: {
        ...objectAssignment.otherInsideSpaces,
        area: values.otherInsideSpaces,
      },
      buildingRelatedOutdoorSpace: {
        ...objectAssignment.buildingRelatedOutdoorSpace,
        area: values.buildingRelatedOutdoorSpace,
      },
      externalStorage: {
        ...objectAssignment.externalStorage,
        area: values.externalStorage,
      },
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      contents: {
        ...objectAssignment.contents,
        volume: values.contents,
      },
    };

    this.props.onChange(value);
  }

  private setConstructionPeriod(control: RawFormControl) {
    const { value } = control;
    const year = parseInt(value);

    const period = this.props.constructionPeriods.find(
      (p) => p.minYear <= year && p.maxYear >= year
    );
    if (period) return { constructionPeriod: period.value };
  }
}
