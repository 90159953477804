import { PublicationSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps, PublicationsThunk } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  PublicationsComponent,
  PublicationsComponentProps
} from "./publications";

type StateProps = {};
type DispatchProps = {
  getPublications: (id: string) => Promise<PublicationSnapShot[]>;
  navigate: (path: string) => RouterAction;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  PublicationsComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PublicationsComponentProps
> = (dispatch) => ({
  getPublications: (id: string) =>
    dispatch(PublicationsThunk.getPublications(id)),
  navigate: (path: string) => dispatch(push(path))
});

export type PublicationsContainerProps = StateProps & DispatchProps;
export const PublicationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationsComponent);
