import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";

import * as ActionType from "./preview.types";

export interface DynamicDocumentsPreviewState {
  preview: string;
  previewStatus: string;
  previewRefreshStatus: string;
  anchorName: string;
  userFieldName: string;
  visibleFormElements: string[];
}

const INITIAL_STATE: DynamicDocumentsPreviewState = {
  preview: null,
  previewStatus: REQUEST.IDLE,
  previewRefreshStatus: REQUEST.IDLE,
  anchorName: null,
  userFieldName: null,
  visibleFormElements: [],
};

export const previewReducer = (
  state: DynamicDocumentsPreviewState = INITIAL_STATE,
  action: Action
): DynamicDocumentsPreviewState => {
  switch (action.type) {
    case REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW: {
      const { preview, visibleFormElements } = <ActionType.Preview>action;
      return {
        ...state,
        preview,
        visibleFormElements,
        previewStatus: REQUEST.SUCCESS,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW_STATUS: {
      const { previewStatus } = <ActionType.PreviewStatus>action;
      const preview = previewStatus === REQUEST.PENDING ? null : state.preview;

      return {
        ...state,
        preview,
        previewStatus,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_ANCHOR_NAME: {
      const { name: anchorName } = <ActionType.Name>action;

      return {
        ...state,
        anchorName,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW_REFRESH_STATUS: {
      const { previewRefreshStatus } = <ActionType.PreviewRefreshStatus>action;

      return {
        ...state,
        previewRefreshStatus,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.REFRESH_HTML_PREVIEW: {
      const { preview, visibleFormElements } = <ActionType.Preview>action;

      return {
        ...state,
        preview,
        previewRefreshStatus: REQUEST.SUCCESS,
        visibleFormElements,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_USERFIELD_NAME: {
      const { name: userFieldName } = <ActionType.Name>action;

      return {
        ...state,
        userFieldName,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.CLEAR_HTML_PREVIEW: {
      return {
        ...state,
        preview: null,
        previewStatus: REQUEST.IDLE,
      };
    }
    case REDUX.DYNAMIC_DOCUMENTS.RESET_FOCUS: {
      return {
        ...state,
        userFieldName: null,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
