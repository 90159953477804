import {
  LinkedRelation,
  MatchedSearchAssignmentSnapshot,
  ObjectAssignment,
} from "@haywork/api/kolibri";
import { EmailAddress } from "@haywork/api/mail";
import {
  ReadRealEstatePropertyResponse,
  RealEstatePropertySearchItem,
} from "@haywork/api/mls";
import {
  MapDispatchToProps,
  RelationThunks,
  SearchAssignmentThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  SearchAssignmentComponent,
  SearchAssignmentComponentProps,
} from "./search-assignments";
import { objectAssignment, realestateProperty } from "./selectors";

interface StateProps {
  objectAssignment: ObjectAssignment | null;
  realestateProperty: ReadRealEstatePropertyResponse;
  canSendEmail: boolean;
}
interface DispatchProps {
  findSearchAssignmentByObjectAssignment: (
    objectAssignment: ObjectAssignment
  ) => Promise<MatchedSearchAssignmentSnapshot[]>;
  navigate: (url: string) => void;
  exportSearchAssignmentRelations: (relationIds: string[]) => Promise<void>;
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    subject: string,
    body: string,
    toBcc?: boolean
  ) => void;
  getRealEstatePropertySearchResponse: (
    bundleId: string,
    appClient: string
  ) => Promise<RealEstatePropertySearchItem>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SearchAssignmentComponentProps,
  AppState
> = (state) => {
  return {
    objectAssignment: objectAssignment(state),
    canSendEmail: canSendEmail(state),
    realestateProperty: realestateProperty(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SearchAssignmentComponentProps
> = (dispatch) => ({
  findSearchAssignmentByObjectAssignment: (
    objectAssignment: ObjectAssignment
  ) =>
    dispatch(
      SearchAssignmentThunks.findSearchAssignmentByObjectAssignment(
        objectAssignment
      )
    ),
  navigate: (url: string) => dispatch(push(url)),
  exportSearchAssignmentRelations: (relationIds: string[]) =>
    dispatch(RelationThunks.exportSearchAssignmentRelations(relationIds)),
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    subject: string,
    body: string,
    toBcc?: boolean
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail(
        emails,
        linkedRelations,
        [],
        subject,
        body,
        toBcc
      )
    ),
  getRealEstatePropertySearchResponse: (bundleId: string, appClient: string) =>
    dispatch(
      MLSThunk.Single.getRealEstatePropertySearchResponse(bundleId, appClient)
    ),
});

export type SearchAssignmentsContainerProps = StateProps & DispatchProps;
export const SearchAssignmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentComponent);
