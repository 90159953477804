import { AuthenticateResponse as EventCenterAuthenticateResponse } from "@haywork/api/event-center";
import {
  AuthenticateResponse as KolibriAuthenticateResponse,
  EmployeeRole
} from "@haywork/api/kolibri";
import { AuthenticateResponse as EmailAuthenticateResponse } from "@haywork/api/mail";
import { AuthenticationResponse as VoipAuthenticationResponse } from "@haywork/api/voip";
import { LiveDataThunk, MapDispatchToProps } from "@haywork/middleware/thunk";
import { AppState } from "@haywork/stores";
import { LiveDataActions, LiveDataConnection } from "@haywork/stores/live-data";
import * as Ably from "ably";
import { connect, MapStateToProps } from "react-redux";
import { LiveDataComponent, LiveDataComponentProps } from "./live-data";

type StateProps = {
  role: EmployeeRole;
  features: string[];
};
type DispatchProps = {
  authenticateMail: () => Promise<EmailAuthenticateResponse>;
  authenticateVoip: () => Promise<VoipAuthenticationResponse>;
  authenticateKolibri: () => Promise<KolibriAuthenticateResponse>;
  authenticateEventCenter: () => Promise<EventCenterAuthenticateResponse>;
  handleConnectionChange: (
    status: Ably.Types.ConnectionState,
    connection: LiveDataConnection
  ) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  LiveDataComponentProps,
  AppState
> = (state) => {
  const { role } = state.account.currentRealestateAgency;
  const { features } = state.appSettings;

  return {
    role,
    features
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LiveDataComponentProps
> = (dispatch) => ({
  authenticateMail: () => dispatch(LiveDataThunk.authenticateMail()),
  authenticateVoip: () => dispatch(LiveDataThunk.authenticateVoip()),
  authenticateKolibri: () => dispatch(LiveDataThunk.authenticateKolibri()),
  authenticateEventCenter: () =>
    dispatch(LiveDataThunk.authenticateEventCenter()),
  handleConnectionChange: (
    status: Ably.Types.ConnectionState,
    connection: LiveDataConnection
  ) => dispatch(LiveDataActions.setStatus(status, connection))
});

export type LiveDataContainerProps = StateProps & DispatchProps;
export const LiveDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveDataComponent);
