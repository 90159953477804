import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { ObjectTimestamps, SlideShow } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { AcquisitionAssignmentDetailSidebarContainerProps } from "./detail-sidebar.container";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquisitionAssignmentDetailSidebarComponentProps = {};
type Props = AcquisitionAssignmentDetailSidebarComponentProps &
  AcquisitionAssignmentDetailSidebarContainerProps;

export const AcquisitionAssignmentDetailSidebarComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionAssignmentObject, openLightbox }) => {
      const {
        id,
        dateTimeModified,
        linkedModifiedBy,
        dateTimeCreated,
        linkedCreatedBy,
        photos
      } = acquisitionAssignmentObject;

      const onSlideClickHandler = useCallback(
        (idx: number) => {
          openLightbox(photos, idx);
        },
        [photos]
      );

      return (
        <div>
          <div styleName="preview-slider">
            {!!photos && photos.length > 0 ? (
              <SlideShow slides={photos} onSlideClick={onSlideClickHandler} />
            ) : (
              <i className="fal fa-fw fa-camera-retro" />
            )}
          </div>
          <NavLink
            styleName="action"
            activeClassName="active"
            to={route(ACQUISITIONOBJECTROUTES.GENERAL.URI, { id })}
          >
            <Icon name="folder-open" size={13} color="inherit" light />
            <div styleName="label">
              <I18n value="acquisitionAssignmentObject.menuLabel.general" />
            </div>
          </NavLink>

          <FeatureSwitch feature="ACQUISITION_OBJECT_MODULES">
            <>
              <NavLink
                styleName="action"
                activeClassName="active"
                to={route(ACQUISITIONOBJECTROUTES.TIMELINE.URI, { id })}
              >
                <Icon name="align-left" size={13} color="inherit" light />
                <div styleName="label">
                  <I18n value="acquisitionAssignmentObject.menuLabel.timeline" />
                </div>
              </NavLink>
              <NavLink
                styleName="action"
                activeClassName="active"
                to={route(ACQUISITIONOBJECTROUTES.NETWORK.URI, { id })}
              >
                <Icon name="users" size={13} color="inherit" light />
                <div styleName="label">
                  <I18n value="acquisitionAssignmentObject.menuLabel.network" />
                </div>
              </NavLink>
              <NavLink
                styleName="action"
                activeClassName="active"
                to={route(ACQUISITIONOBJECTROUTES.DOSSIER.URI, { id })}
              >
                <Icon name="archive" size={13} color="inherit" light />
                <div styleName="label">
                  <I18n value="acquisitionAssignmentObject.menuLabel.dossier" />
                </div>
              </NavLink>
            </>
          </FeatureSwitch>

          <ObjectTimestamps
            linkedCreatedBy={linkedCreatedBy}
            linkedModifiedBy={linkedModifiedBy}
            dateTimeCreated={dateTimeCreated}
            dateTimeModified={dateTimeModified}
          />
        </div>
      );
    }
  )
);
