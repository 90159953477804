import { RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  LinkedAssignment,
  LinkedEmployee,
  PhotoBlob,
  UpdateAvailabilityAction,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { MAINROUTES, OBJECTTYPESROUTES } from "@haywork/constants";
import {
  Dispatch,
  InvoiceThunk,
  ProjectsThunks,
  SchedulerThunks,
  TaskThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { ObjectTypesDetailComponent } from "@haywork/modules/object-types";
import { canSendEmail } from "@haywork/selector";
import { AppState, LayoutActions, ProjectsActions } from "@haywork/stores";
import { ExtendedObjectTypeAssignment } from "@haywork/stores/project/types";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fullyBakedDisplayName,
  isInEditMode,
  objectTypePrice,
  photos,
} from "./selectors";

interface StateProps {
  currentType: ExtendedObjectTypeAssignment;
  currentTypeStatus: string;
  isInEditMode: boolean;
  photos: PhotoBlob[];
  displayName: string;
  addBuildnumbersVisible: boolean;
  price: number;
  canSendEmail: boolean;
}
interface DispatchProps {
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
  edit: (id: string) => void;
  saveAndCloseObjectType: () => void;
  navigate: (path: string) => void;
  updateAvailability: (id: string, action: UpdateAvailabilityAction) => void;
  toggleAddBuildnumbers: (visible: boolean) => void;
  getBuildnumbers: (objectTypeId: string) => void;
  removeType: (typeId: string, projectId: string) => Promise<void>;
  createTaskWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployee: LinkedEmployee
  ) => Promise<void>;
  createNewEmail: (
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) => void;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
}

const mapStateToProps = <StateProps, ObjectTypesDetailComponentProps>(
  state: AppState,
  ownProps: EditableHocProps & RouteComponentProps<any>
) => {
  const { currentType, currentTypeStatus } = state.project.types;
  const { addBuildnumbersVisible } = state.project.buildnumbers;

  return {
    currentType,
    currentTypeStatus,
    isInEditMode: isInEditMode(ownProps),
    photos: photos(state),
    displayName: fullyBakedDisplayName(state),
    addBuildnumbersVisible,
    price: objectTypePrice(state),
    canSendEmail: canSendEmail(state),
  };
};

const mapDispatchToProps = <DispatchProps, ObjectTypesDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
  edit: (id: string) =>
    dispatch(
      push(RouteUtil.mapStaticRouteValues(OBJECTTYPESROUTES.EDIT.URI, { id }))
    ),
  saveAndCloseObjectType: () =>
    dispatch(ProjectsThunks.Types.saveAndCloseObjectType()),
  navigate: (path: string) => dispatch(push(path)),
  updateAvailability: (id: string, action: UpdateAvailabilityAction) =>
    dispatch(ProjectsThunks.Types.updateAvailability(id, action)),
  toggleAddBuildnumbers: (visible: boolean) =>
    dispatch(ProjectsActions.Buildnumbers.toggleAddBuildnumbers(visible)),
  getBuildnumbers: (objectTypeId: string) =>
    dispatch(
      ProjectsThunks.Types.getBuildnumbers(
        objectTypeId,
        ActiveFilter.ActiveOnly,
        true
      )
    ),
  removeType: (typeId: string, projectId: string) =>
    dispatch(ProjectsThunks.Types.removeType(typeId, projectId)),
  createTaskWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(
      TaskThunks.createTaskWithLinkedEntities(undefined, [linkedAssignment])
    ),
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        linkedRelation,
        linkedAssignment,
        undefined,
        undefined,
        "Consumer"
      )
    ),
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployee: LinkedEmployee
  ) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities(
        linkedRelations,
        [linkedAssignment],
        [linkedEmployee]
      )
    ),
  createNewEmail: (linkedAssignment: LinkedAssignment, displayName: string) =>
    dispatch(
      EmailThunk.Main.createNewEmail([], [], [linkedAssignment], displayName)
    ),
  archive: (id: string) => dispatch(ProjectsThunks.Types.archive(id)),
  unArchive: (id: string) => dispatch(ProjectsThunks.Types.unArchive(id)),
});

export type ObjectTypesDetailContainerProps = StateProps &
  DispatchProps &
  EditableHocProps &
  RouteComponentProps<any>;
export const ObjectTypesDetailContainer = editable(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ObjectTypesDetailComponent)
  ),
  {
    entityType: RootEntityType.ObjectTypeAssignment,
    icon: MAINROUTES.OBJECTTYPES.ICON,
    thunk: ProjectsThunks.Types.getType,
    status: "project.types.currentTypeStatus",
    statePath: "project.types.currentType",
    objectName: "currentType",
    action: ProjectsActions.Types.setType,
    route: OBJECTTYPESROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveObjectTypeConfirmTitle" },
      body: { key: "saveObjectTypeConfirmBody" },
    },
  }
);
