import { AssignmentPhase, ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { ProjectEditComponent } from "@haywork/modules/project/components/edit/edit.component";
import {
  AppState,
  EditableItem,
  LayoutActions,
  ProjectsActions,
} from "@haywork/stores";
import { ProjectUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { hasChanges } from "./selectors";

interface StateProps {
  project: ProjectAssignment;
  fullscreen: boolean;
  canSave: boolean;
  canPublish: boolean;
  saveAssignmentState: string;
  hasChanges: boolean;
}

interface DispatchProps {
  toggleFullscreen: () => void;
  saveProject: (close: boolean) => void;
  publish: (id: string) => void;
  toggleSaveModal: (status: boolean) => void;
  saveInitialAssignment: () => void;
  backToDashboard: () => Promise<void>;
  reloadProject: (editable: EditableItem) => Promise<void>;
}

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps = <StateProps, ProjectEditComponentProps>(
  state: AppState
) => {
  const { projectAssignment } = state.editable.currentComponentState;
  const { assignmentPhase, isNew }: ProjectAssignment = projectAssignment;
  const { saveAssignmentState } = state.project.single;

  return {
    project: projectAssignment,
    fullscreen: state.layout.fullscreen,
    canSave: ProjectUtil.Validation.validForSave(projectAssignment),
    canPublish: !isNew && assignmentPhase === AssignmentPhase.Concept,
    saveAssignmentState,
    hasChanges: hasChanges(state),
  };
};

const mapDispatchToProps = <DispatchProps, AssignmentEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  toggleFullscreen: () => dispatch(LayoutActions.toggleFullscreen()),
  saveProject: (close: boolean) =>
    dispatch(ProjectsThunks.Projects.saveProject(undefined, close)),
  saveInitialAssignment: () =>
    dispatch(ProjectsThunks.Projects.saveProject(undefined, false, true)),
  publish: (id: string) =>
    dispatch(push(route(PROJECTROUTES.PUBLISH.URI, { id }))),
  toggleSaveModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleSaveModal(status)),
  backToDashboard: () => dispatch(ProjectsThunks.Projects.backToDashboard()),
  reloadProject: (editable: EditableItem) =>
    dispatch(ProjectsThunks.Projects.reloadProject(editable)),
});

export type ProjectEditContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const ProjectEditContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectEditComponent)
);
