import { SearchAssignmentLocation } from "@haywork/api/kolibri";
import {
  SearchAssignmentEditWhereContainerProps,
  SearchLocationComponent,
} from "@haywork/modules/search-assignment";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { PostalCodeRangeComponent } from "./postalcode-range.component";

const styles = require("./edit-where.component.scss");

export interface SearchAssignmentEditWhereComponentProps {}
interface State {
  postalCodeRangeVisible: boolean;
}
type Props = SearchAssignmentEditWhereComponentProps &
  SearchAssignmentEditWhereContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentEditWhereComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      postalCodeRangeVisible: false,
    };

    this.updateSearchLocations = this.updateSearchLocations.bind(this);
    this.updateSearchLocation = this.updateSearchLocation.bind(this);
    this.onAddPostalCodeRange = this.onAddPostalCodeRange.bind(this);
    this.onClosePostalCodeRange = this.onClosePostalCodeRange.bind(this);
  }

  public render() {
    const { searchAssignment } = this.props;
    const locations = searchAssignment.locations || [];
    const highestSearchLocationId = locations.reduce((state, location) => {
      if (location.id > state) return location.id;
      return state;
    }, 0);

    return (
      <div styleName="address">
        <div className="container-fluid">
          {this.props.shouldWarnAboutCollegialListings && (
            <div styleName="warning">
              <i className="fal fa-exclamation-triangle" />
              <ResourceText
                resourceKey="searchAssignments.warning.collegialListings"
                asHtml
              />
            </div>
          )}

          <div styleName="address-search">
            {locations.map((location, idx) => (
              <SearchLocationComponent
                idx={idx + 1}
                key={location?.id || idx}
                addressSearchState={this.props.addressSearchState}
                addressSearchStateLocationId={
                  this.props.addressSearchStateLocationId
                }
                countries={this.props.countries}
                radiusOptions={this.props.radiusOptions}
                searchLocation={location}
                createNewSearchLocation={() =>
                  this.props.createNewSearchLocation(searchAssignment.id)
                }
                amountOfLocations={this.props.amountOfLocations}
                searchAddress={this.props.searchAddress}
                removeSearchLocation={(id) =>
                  this.props.removeSearchLocation(id, searchAssignment.id)
                }
                updateSearchLocation={this.updateSearchLocation}
                countryIso2={this.props.countryIso2}
                addPostalCodeRange={this.onAddPostalCodeRange}
                dateTimeModified={this.props.searchAssignment.dateTimeModified}
              />
            ))}

            <PostalCodeRangeComponent
              highestSearchLocationId={highestSearchLocationId}
              visible={this.state.postalCodeRangeVisible}
              onChange={this.updateSearchLocations}
              onClose={this.onClosePostalCodeRange}
            />
          </div>
        </div>
      </div>
    );
  }

  private updateSearchLocation(
    searchAssignmentLocation: SearchAssignmentLocation
  ) {
    this.props.searchAssignment.locations =
      this.props.searchAssignment.locations &&
      this.props.searchAssignment.locations.length > 0
        ? this.props.searchAssignment.locations
        : [];
    const locations = this.props.searchAssignment.locations.map((location) =>
      location.id === searchAssignmentLocation.id
        ? searchAssignmentLocation
        : location
    );

    const searchAssignment = { ...this.props.searchAssignment, locations };

    this.props.updateSearchAssignment(searchAssignment, this.props.path);
  }

  private updateSearchLocations(locations: SearchAssignmentLocation[]) {
    const searchAssignment = {
      ...this.props.searchAssignment,
      locations: [...this.props.searchAssignment.locations, ...locations],
    };
    this.props.updateSearchAssignment(searchAssignment, this.props.path);
    this.setState({ postalCodeRangeVisible: false });
  }

  private onAddPostalCodeRange() {
    this.setState({ postalCodeRangeVisible: true });
  }

  private onClosePostalCodeRange() {
    this.setState({ postalCodeRangeVisible: false });
  }
}
