import { RootEntityType } from "@haywork/api/event-center";
import {
  AppState,
  ConfirmConfig,
  EditableActions,
  EditableItem,
} from "@haywork/stores";
import { Dispatch, EditableThunks } from "@haywork/middleware";
import { currentComponentStateEntityId } from "./selectors";

export interface EditableHocContainerProps {
  store: AppState;
  states: EditableItem[];
  realEstateAgencyId: string;
  cache: any;
  currentComponentStateEntityId: string | null;

  addEditableItem: (
    title: string,
    icon: string,
    path: string,
    componentState: any,
    confirm: ConfirmConfig,
    entityType: RootEntityType,
    entityId?: string
  ) => void;
  setTabTitle: (title: string, path: string) => void;
  dispatch: (dispatchable: any) => void;
  setCacheWithPath: (
    componentState: any,
    path: string,
    ignoreChanges: boolean
  ) => void;
  hasChanges: (path: string) => void;
  removeState: (path: string) => void;
  closeTab: (path: string) => void;
}

export const editableMapStateToProps = (state: AppState) => ({
  store: state,
  states: state.editable.states,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  cache: state.editable.currentComponentState,
  currentComponentStateEntityId: currentComponentStateEntityId(state),
});

export const editableMapDispatchToProps = (dispatch: Dispatch<any>) => ({
  addEditableItem: (
    title: string,
    icon: string,
    path: string,
    componentState: any,
    confirm: ConfirmConfig,
    entityType: RootEntityType,
    entityId?: string
  ) =>
    dispatch(
      EditableActions.addState({
        title,
        icon,
        path,
        componentState,
        confirm,
        entityType,
        entityId,
      })
    ),
  setTabTitle: (title: string, path: string) =>
    dispatch(EditableActions.updateTabTitle({ title, path })),
  setCacheWithPath: (
    componentState: any,
    path: string,
    ignoreChanges: boolean
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
        ignoreChanges,
      })
    ),
  dispatch: (dispatchable: any) => dispatch(dispatchable),
  hasChanges: (path: string) =>
    dispatch(EditableActions.setHasChanges({ path })),
  closeTab: (path: string) => dispatch(EditableThunks.remove(path)),
});
