import * as React from "react";
import { PureComponent } from "react";
import { DossierDragPreviewContainerProps as Props } from "./drag-preview.container";
import Preview from "./preview";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

@CSSModules(styles, { allowMultiple: true })
export class DossierDragPreviewComponent extends PureComponent<
  Props,
  { mouseX: number; mouseY: number }
> {
  constructor(props) {
    super(props);

    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.state = {
      mouseX: 0,
      mouseY: 0
    };

    document.addEventListener("mousemove", this.handleMouseMove, true);
    document.addEventListener("drag", this.handleMouseMove, true);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove, true);
    document.removeEventListener("drag", this.handleMouseMove, true);
  }

  public render() {
    const { isDragging, itemType, item } = this.props;
    if (itemType !== "DossierFile" || !isDragging) return null;
    const { mouseX, mouseY } = this.state;
    const transform = `translate3d(${mouseX + 10}px, ${mouseY - 16}px, 0)`;

    return (
      <div
        styleName="drag-preview"
        style={{
          transform,
          WebkitTransform: transform
        }}
      >
        <Preview
          file={item.file}
          selectedFilesCount={item.selectedFilesCount}
        />
      </div>
    );
  }

  private handleMouseMove(event: MouseEvent) {
    const { pageX: mouseX, pageY: mouseY } = event;
    this.setState({ mouseX, mouseY });
  }
}
