import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./not-found.component.scss");

export const ErrorNotFound = CSSModules(styles, { allowMultiple: true })(() => {
  return (
    <div styleName="error">
      <div styleName="error__content">
        <div styleName="error__icon" />
        <div styleName="error__text">
          <h1>
            <ResourceText resourceKey="pageNotFoundTitle" />
          </h1>
          <ResourceText resourceKey="pageNotFoundText" asHtml />
          <button className="btn btn-primary" onClick={() => history.go(-2)}>
            <ResourceText resourceKey="pageNotFoundAction" />
          </button>
        </div>
      </div>
    </div>
  );
});
