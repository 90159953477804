import {
  AirTreatmentOption,
  BogCombinationObjectSituationOption,
  BogCombinationObjectStatusOption,
  CertificationOption,
  ConditionOption,
  ConstructionOptionOption,
  ConstructionPeriodOption,
  EnergyClassOption,
  FacilityOption,
  FurnishingOption,
  IndustrialBuildingFacilityOption,
  LeisureFacilityOption,
  ListingType,
  ObjectAssignment,
  ParkingFacilityOption,
  PavementTypeOption,
  ResidentialBuildingTypeOption,
  SocialPropertyFacilityOption,
  TypeOfBusinessLawOption,
  TypeOfCommunalAreaOption,
  WelfareStateOption
} from "@haywork/api/kolibri";
import { FormReturnValue } from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import * as React from "react";
import {
  AssignmentBogEnergyAndCostsComponent,
  AssignmentBogFeaturesAndFiguresComponent,
  AssignmentBogParticularsComponent,
  AssignmentEditBogHotelRestaurantCafeComponent,
  AssignmentEditBogInvestmentComponent,
  AssignmentEditBogLeisureComponent,
  AssignmentEditBogLotComponent,
  AssignmentEditBogOfficeComponent,
  AssignmentEditBogOtherComponent,
  AssignmentEditBogOutdoorAreaComponent,
  AssignmentEditBogPoiAccessibilityComponent,
  AssignmentEditBogPoiServicesComponent,
  AssignmentEditBogProductionHallComponent,
  AssignmentEditBogRetailSpaceComponent,
  AssignmentEditBogSocialPropertyComponent
} from "./bog";
import AssignmentEditContentCombinationObject from "./shared";

interface Props {
  objectAssignment: ObjectAssignment;
  welfareStateOptions: WelfareStateOption[];
  typesOfCommunalArea: TypeOfCommunalAreaOption[];
  airTreatmentOptions: AirTreatmentOption[];
  industrialBuildingFacilityOptions: IndustrialBuildingFacilityOption[];
  facilityOptions: FacilityOption[];
  constructionOptionOptions: ConstructionOptionOption[];
  leisureFacilityTypeOptions: LeisureFacilityOption[];
  pavementTypeOptions: PavementTypeOption[];
  residentialBuildingTypeOptions: ResidentialBuildingTypeOption[];
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[];
  constructionPeriods: ConstructionPeriodOption[];
  energyClassOptions: EnergyClassOption[];
  conditionOptions: ConditionOption[];
  certificationOptions: CertificationOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  furnishingOptions: FurnishingOption[];
  bogCombinationObjectSituationOptions: BogCombinationObjectSituationOption[];
  bogCombinationObjectStatusOptions: BogCombinationObjectStatusOption[];
  onChange: (values: FormReturnValue) => void;
}
interface State {}

export class EditContentCommercialComponent extends React.Component<
  Props,
  State
> {
  public render() {
    const {
      objectAssignment,
      welfareStateOptions,
      typesOfCommunalArea,
      airTreatmentOptions,
      industrialBuildingFacilityOptions,
      facilityOptions,
      constructionOptionOptions,
      leisureFacilityTypeOptions,
      pavementTypeOptions,
      residentialBuildingTypeOptions,
      socialPropertyFacilityTypeOptions,
      constructionPeriods,
      energyClassOptions,
      conditionOptions,
      certificationOptions,
      parkingFacilityOptions,
      bogCombinationObjectSituationOptions,
      bogCombinationObjectStatusOptions
    } = this.props;

    return (
      <StepperComponent initial={0} scrollToElementId="scroll-to-top">
        {objectAssignment.listingType === ListingType.RetailSpace && (
          <StepComponent title="retailSpace">
            <AssignmentEditBogRetailSpaceComponent
              objectAssignment={objectAssignment}
              welfareStateOptions={welfareStateOptions}
              typesOfCommunalArea={typesOfCommunalArea}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.ProductionHall && (
          <StepComponent title="productionHall">
            <AssignmentEditBogProductionHallComponent
              objectAssignment={objectAssignment}
              airTreatmentOptions={airTreatmentOptions}
              industrialBuildingFacilityOptions={
                industrialBuildingFacilityOptions
              }
              facilityOptions={facilityOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.Office && (
          <StepComponent title="bogOffice">
            <AssignmentEditBogOfficeComponent
              objectAssignment={objectAssignment}
              airTreatmentOptions={airTreatmentOptions}
              facilityOptions={facilityOptions}
              typesOfCommunalArea={typesOfCommunalArea}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.HotelRestaurantCafe && (
          <StepComponent title="hotelRestaurantCafe">
            <AssignmentEditBogHotelRestaurantCafeComponent
              objectAssignment={objectAssignment}
              welfareStateOptions={welfareStateOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.Lot && (
          <StepComponent title="lot">
            <AssignmentEditBogLotComponent
              objectAssignment={objectAssignment}
              constructionOptionOptions={constructionOptionOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.Leisure && (
          <StepComponent title="leisure">
            <AssignmentEditBogLeisureComponent
              objectAssignment={objectAssignment}
              leisureFacilityTypeOptions={leisureFacilityTypeOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.OutdoorArea && (
          <StepComponent title="outdoorArea">
            <AssignmentEditBogOutdoorAreaComponent
              objectAssignment={objectAssignment}
              pavementTypeOptions={pavementTypeOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {(objectAssignment.listingType === ListingType.Other ||
          objectAssignment.listingType === ListingType.Garage ||
          objectAssignment.listingType === ListingType.PracticeSpace ||
          objectAssignment.listingType === ListingType.WarehouseProduction) && (
          <StepComponent title="otherTitle">
            <AssignmentEditBogOtherComponent
              objectAssignment={objectAssignment}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.Investment && (
          <StepComponent title="investment">
            <AssignmentEditBogInvestmentComponent
              objectAssignment={objectAssignment}
              residentialBuildingTypeOptions={residentialBuildingTypeOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        {objectAssignment.listingType === ListingType.SocialProperty && (
          <StepComponent title="socialProperty">
            <AssignmentEditBogSocialPropertyComponent
              objectAssignment={objectAssignment}
              socialPropertyFacilityTypeOptions={
                socialPropertyFacilityTypeOptions
              }
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}

        <StepComponent title="buildingPeriod">
          <AssignmentBogFeaturesAndFiguresComponent
            objectAssignment={objectAssignment}
            constructionPeriods={constructionPeriods}
            onChange={this.props.onChange}
          />
        </StepComponent>

        <StepComponent title="energyAndCosts">
          <AssignmentBogEnergyAndCostsComponent
            objectAssignment={objectAssignment}
            energyClassOptions={energyClassOptions}
            onChange={this.props.onChange}
          />
        </StepComponent>

        <StepComponent title="particulars">
          <AssignmentBogParticularsComponent
            objectAssignment={objectAssignment}
            conditionOptions={conditionOptions}
            certificationOptions={certificationOptions}
            parkingFacilityOptions={parkingFacilityOptions}
            typeOfBusinessLawOptions={this.props.typeOfBusinessLawOptions}
            furnishingOptions={this.props.furnishingOptions}
            onChange={this.props.onChange}
          />
        </StepComponent>

        <StepComponent title="pointOfInterestAccessibility">
          <AssignmentEditBogPoiAccessibilityComponent
            objectAssignment={objectAssignment}
            onChange={this.props.onChange}
          />
        </StepComponent>

        <StepComponent title="pointOfInterestServices">
          <AssignmentEditBogPoiServicesComponent
            objectAssignment={objectAssignment}
            onChange={this.props.onChange}
          />
        </StepComponent>
        <StepComponent title="combinationObjectCommercial">
          <AssignmentEditContentCombinationObject
            objectAssignment={objectAssignment}
            bogCombinationObjectSituationOptions={
              bogCombinationObjectSituationOptions
            }
            bogCombinationObjectStatusOptions={
              bogCombinationObjectStatusOptions
            }
            onChange={this.props.onChange}
          />
        </StepComponent>
      </StepperComponent>
    );
  }
}
