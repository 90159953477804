import { AssignmentPhase } from "@haywork/api/kolibri";
import { Publications } from "@haywork/modules/assignment";
import { Hint, HintAlignment, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./partners.component.scss");

interface Props {
  id: string;
  assignmentPhase: AssignmentPhase;
  webAddress: string;
  publications: Publications;
  publicationsRoute: string;
  isTemporarilyRemovedFromFunda: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class PartnersWidgetComponent extends React.Component<Props> {
  public render() {
    const { assignmentPhase, webAddress, publications } = this.props;

    let shouldRender = true;

    if (
      [AssignmentPhase.Completed, AssignmentPhase.Concept].includes(
        assignmentPhase
      )
    ) {
      shouldRender = false;
    }

    if (publications.rejected.length) {
      shouldRender = true;
    }

    return !shouldRender ? null : (
      <div styleName="widget partners-widget">
        <h2 styleName="title">
          <ResourceText resourceKey="partners" />
        </h2>

        <div styleName="media__partners__special">
          {this.renderHiddenOnFunda()}
        </div>

        <div styleName="media__partners">{this.renderMediapartners()}</div>
        {!!webAddress && (
          <div styleName="web-address">
            <h2>
              <ResourceText resourceKey="publicWebAddress" />
            </h2>
            <a href={webAddress} target="_blank">
              {webAddress}
            </a>
          </div>
        )}
        <NavLink to={this.props.publicationsRoute}>
          <ResourceText resourceKey="assignmentPublicationWidgetLink" />
        </NavLink>
      </div>
    );
  }
  private renderHiddenOnFunda(): React.ReactElement<HTMLDivElement> {
    if (!this.props.isTemporarilyRemovedFromFunda) return;
    return (
      <div styleName="status">
        <h2>
          <ResourceText resourceKey="partners.special.status" />
        </h2>
        <ResourceText resourceKey={"isTemporarilyRemovedFromFunda"} />
        <Hint
          message="isTemporarilyRemovedFromFundaHint"
          align={HintAlignment.Bottom}
        >
          <i className="fal fa-question-circle" />
        </Hint>
      </div>
    );
  }

  private renderMediapartners() {
    const { publications } = this.props;

    return Object.keys(publications).map(
      (key) =>
        publications[key].length > 0 && (
          <div
            styleName={classNames("partners__section", {
              rejected: key === "rejected",
            })}
            key={key}
          >
            <h2>
              <ResourceText
                resourceKey={key}
                values={{ count: publications[key].length }}
              />
              {key === "rejected" && (
                <>
                  &nbsp;
                  <NavLink to={this.props.publicationsRoute}>
                    <ResourceText resourceKey="showAssignmentErrors" />
                  </NavLink>
                </>
              )}
            </h2>
            {publications[key].map((partners, idx) => (
              <span key={idx}>{partners.mediaPartnerName}</span>
            ))}
          </div>
        )
    );
  }
}
