import * as React from "react";
import { FC, memo, useState, useCallback, useMemo } from "react";
import { PhoneNumber, EmailAddress } from "@haywork/api/kolibri";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  phones: PhoneNumber[];
  emails: EmailAddress[];
  loading: boolean;
  onCancel: () => void;
  onEnrichRelation: (phones: PhoneNumber[], emails: EmailAddress[]) => void;
};

const UpdateDataComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ phones, emails, onCancel, onEnrichRelation, loading }) => {
      const [selectedPhones, setSelectedPhones] = useState<PhoneNumber[]>([]);
      const [selectedEmails, setSelectedEmails] = useState<EmailAddress[]>([]);

      const disabled = useMemo(() => {
        return loading || (!selectedPhones.length && !selectedEmails.length);
      }, [selectedPhones, selectedEmails, loading]);

      const togglePhone = useCallback(
        (phone: PhoneNumber) => {
          const ref = selectedPhones.find(
            (p) => p.number === phone.number && p.type === phone.type
          );
          const phones = !!ref
            ? selectedPhones.filter(
                (p) => p.number !== phone.number && p.type !== phone.type
              )
            : [...selectedPhones, phone];
          setSelectedPhones(phones);
        },
        [selectedPhones, setSelectedPhones]
      );

      const toggleEmail = useCallback(
        (email: EmailAddress) => {
          const ref = selectedEmails.find((e) => e.address === email.address);
          const emails = !!ref
            ? selectedEmails.filter((e) => e.address !== email.address)
            : [...selectedEmails, email];
          setSelectedEmails(emails);
        },
        [selectedEmails, setSelectedEmails]
      );

      const enrichRelation = useCallback(() => {
        onEnrichRelation(selectedPhones, selectedEmails);
      }, [selectedPhones, selectedEmails]);

      return (
        <>
          <ModalBody>
            <I18n value="duplicateContactModal.updateData.body" />

            <div styleName="selected-data">
              {phones.map((phone, idx) => (
                <div
                  key={idx}
                  styleName={classNames("data", {
                    active: !!selectedPhones.find(
                      (p) => p.number === phone.number && p.type === phone.type
                    ),
                  })}
                  onClick={() => togglePhone(phone)}
                >
                  <div styleName="selected-data__dot" />
                  <I18n
                    value={`duplicateContactModal.phone.${phone.type.toString()}`}
                  />
                </div>
              ))}
              {emails.map((email, idx) => (
                <div
                  key={idx}
                  styleName={classNames("data", {
                    active: !!selectedEmails.find(
                      (e) => e.address === email.address
                    ),
                  })}
                  onClick={() => toggleEmail(email)}
                >
                  <div styleName="selected-data__dot" />
                  <I18n
                    value={`duplicateContactModal.email.${email.type.toString()}`}
                  />
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              category="white"
              label="duplicateContactModal.action.cancel"
              onClick={onCancel}
              disabled={loading}
            />
            <Button
              category="primary"
              label="duplicateContactModal.action.enrichRelation"
              onClick={enrichRelation}
              disabled={disabled || loading}
              icon={
                !loading ? null : (
                  <Icon
                    name="spinner"
                    spin
                    regular
                    containIn={24}
                    color={Colors.Gray}
                  />
                )
              }
            />
          </ModalFooter>
        </>
      );
    }
  )
);

export default UpdateDataComponent;
