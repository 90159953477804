import { NoteSnapShot } from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import Icon from "@haywork/components/ui/icon";
import { COUNTS, KEYCODE } from "@haywork/constants";
import { RequestStatus } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import { NotesPortal } from "@haywork/portals";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized";
import { Ui } from "../ui";
import { NotesListContainerProps } from "./list-container";
import Row from "./row";

const styles = require("./style.scss");
const cache = new CellMeasurerCache({
  defaultHeight: 75,
  fixedWidth: true,
});
const keyDiffs = (a: string[], b: string[]) => {
  return a.reduce((state, val, idx) => {
    if (val !== b[idx]) state.push(idx);
    return state;
  }, [] as number[]);
};

export interface NotesListComponentProps {
  parentId: string;
}
type Props = NotesListComponentProps & NotesListContainerProps;

export const NotesListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      totalCount,
      notes,
      notesStatus,
      parentId,
      employeeId,
      getNotes,
      addNote,
      updateNote,
      notesVisible,
      toggleListVisibility,
      deleteNote,
    }) => {
      const [description, setDescription] = useState("");
      const [editNote, setEditNote] = useState<NoteSnapShot | null>(null);
      const pendingRequest = notesStatus === RequestStatus.Pending;
      const canAddNote = !!description && !pendingRequest && notesVisible;
      const listRef = useRef<List | null>(null);
      const [loading, setLoading] = useState(true);
      const newNoteRefs = useMemo(
        () =>
          (notes || []).map(
            (note) => note.id + note.dateTimeModified.toString()
          ),
        [notes]
      );
      const [notesRefs, setNoteRefs] = useState([]);

      useEffect(() => {
        toggleListVisibility(false);
      }, []);

      useEffect(() => {
        const diffs = keyDiffs(notesRefs, newNoteRefs);

        if (diffs.length) {
          diffs.map((idx) => cache.clear(idx, 0));
          if (listRef.current) {
            listRef.current.recomputeRowHeights();
          }
        }

        setNoteRefs(newNoteRefs);
      }, [totalCount, newNoteRefs]);

      useEffect(() => {
        if (!parentId) return;
        getInitialNotes();
      }, [parentId]);

      const handleKeyUp = (event: KeyboardEvent) => {
        if (!notesVisible || event.keyCode !== KEYCODE.ESCAPE) return;

        switch (true) {
          case !!editNote: {
            setEditNote(null);
            setDescription("");
            break;
          }
          case !!description: {
            setDescription("");
            break;
          }
          default: {
            toggleListVisibility(false);
            break;
          }
        }
      };

      useEffect(() => {
        document.addEventListener("keyup", handleKeyUp);

        return () => {
          document.removeEventListener("keyup", handleKeyUp);
        };
      });

      const getInitialNotes = async () => {
        setLoading(true);

        try {
          await getNotes(parentId, 0, true);
        } finally {
          setLoading(false);
        }
      };

      const onSubmit = async () => {
        if (!canAddNote) return;
        setLoading(true);

        if (listRef.current) {
          listRef.current.scrollToPosition(0);
        }

        try {
          (await !editNote)
            ? addNote(description)
            : updateNote({
                ...editNote,
                description,
              });
          setDescription("");
          setEditNote(null);
        } finally {
          setLoading(false);
        }
      };

      const onMarkImportant = async (note: NoteSnapShot) => {
        setLoading(true);

        if (listRef.current) {
          listRef.current.scrollToPosition(0);
        }

        const updatedNote = {
          ...note,
          isImportant: !note.isImportant,
        } as NoteSnapShot;

        try {
          await updateNote(updatedNote);
        } finally {
          setLoading(false);
        }
      };

      const onEditNote = (note) => {
        setEditNote(note);
        setDescription(note.description || "");
      };

      const isRowLoaded = ({ index }: Index) => {
        return !!notes[index];
      };

      const loadMoreRows = ({ startIndex }: IndexRange) => {
        return getNotes(parentId, startIndex);
      };

      const onClose = () => {
        toggleListVisibility(false);
        setDescription("");
        setEditNote(null);
      };

      const onDelete = (note: NoteSnapShot) => {
        deleteNote(note.id, note.description);
      };

      const rowRenderer = ({ style, parent, index, key }: ListRowProps) => {
        const note = notes[index];
        const isSelf = get(note, "linkedCreatedBy.id") === employeeId;
        const isEditing = get(note, "id") === get(editNote, "id");

        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {() => (
              <div style={style}>
                <Row
                  note={note}
                  isSelf={isSelf}
                  isEditing={isEditing}
                  onDelete={onDelete}
                  onEdit={onEditNote}
                  onMarkImportant={onMarkImportant}
                />
              </div>
            )}
          </CellMeasurer>
        );
      };

      return (
        <NotesPortal>
          <div
            styleName={classNames("list__container", {
              expanded: notesVisible,
            })}
          >
            <div styleName="list">
              <div styleName="list__input">
                <Input.Textarea
                  name="note"
                  value={description}
                  onChange={setDescription}
                  autoSize
                  maxHeight={"98vh"}
                  overflow={"hidden"}
                  placeholder="notes.input.placeholder"
                  fireAllChanges
                  disableTrim
                  submitOnEnter={onSubmit}
                  disabled={pendingRequest || !notesVisible}
                />
                <Button
                  icon={
                    <Icon
                      name="paper-plane"
                      light
                      color={canAddNote ? Colors.White : Colors.Gray}
                    />
                  }
                  onClick={onSubmit}
                  disabled={!canAddNote}
                />
                <Button
                  category="none"
                  onClick={onClose}
                  label="notes.action.close"
                />
              </div>
              <div styleName="list__wrapper">
                {loading && <Ui.Loaders.Fullscreen mask />}
                {!loading && totalCount === 0 && (
                  <EmptyState
                    icon="comment-alt-slash"
                    title="notes.emptyState.title"
                    subTitle="notes.emptyState.subTitle"
                    centered
                  />
                )}
                <AutoSizer>
                  {({ width, height }) => (
                    <InfiniteLoader
                      isRowLoaded={isRowLoaded}
                      loadMoreRows={loadMoreRows}
                      rowCount={totalCount}
                      minimumBatchSize={COUNTS.NOTES}
                    >
                      {({ onRowsRendered }) => (
                        <List
                          ref={listRef}
                          width={width}
                          height={height}
                          rowRenderer={rowRenderer}
                          rowHeight={cache.rowHeight}
                          rowCount={totalCount}
                          deferredMeasurementCache={cache}
                          onRowsRendered={onRowsRendered}
                        />
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        </NotesPortal>
      );
    }
  )
);
