import { RequestStatus } from "@haywork/enum";

export enum zohoAsapActions {
  SetStatus = "Zoho.Asap.SetStatus",
  ToggleVisibility = "Zoho.Asap.ToggleVisibility"
}

interface SetStatus {
  type: zohoAsapActions.SetStatus;
  asapStatus: RequestStatus;
}
const setStatus = (asapStatus: RequestStatus) => ({
  type: zohoAsapActions.SetStatus,
  asapStatus
});

interface ToggleVisibility {
  type: zohoAsapActions.ToggleVisibility;
  asapVisible: boolean;
}
const toggleVisibility = (asapVisible: boolean) => ({
  type: zohoAsapActions.ToggleVisibility,
  asapVisible
});

export type ZohoAsapActions = SetStatus | ToggleVisibility;
export const Actions = {
  setStatus,
  toggleVisibility
};
