import { createContext } from "react";
import noop from "lodash-es/noop";

export type EmailContextProps = {
  loading: boolean;
  onAddFolder: (parentFolder: string | null, accountId: string) => void;
  onUpdateFolder: (folderId: string) => void;
  getMessages: (skip: number, accountId: string, folderId: string) => void;
  getDrafts: (skip: number, accountId: string, folderId: string) => void;
};

export const EmailContext = createContext<EmailContextProps>({
  loading: false,
  onAddFolder: noop,
  onUpdateFolder: noop,
  getMessages: noop,
  getDrafts: noop
});
export const EmailContextConsumer = EmailContext.Consumer;
export const EmailContextProvider = EmailContext.Provider;
