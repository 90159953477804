import { ObjectAssignment, RentCondition } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { AsyncUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RenewRentOfferModalContainerProps } from "./renew-rent-offer-modal.container";

enum KeyAction {
  Keep = "Keep",
  Remove = "Remove",
  Migrate = "Migrate",
  KeepAndMigrate = "KeepAndMigrate",
}

const styles = require("./update-rent-offer-modal.component.scss");

export interface RenewRentOfferModalComponentProps {
  visible: boolean;
  displayName: string;
  assignment: ObjectAssignment;
  onClose: () => void;
}
interface State {
  loading: boolean;
  availabilityIsTemporary: boolean;
}
type Props = RenewRentOfferModalComponentProps &
  RenewRentOfferModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class RenewRentOfferModalComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    const availabilityIsTemporary = get(
      this.props.assignment,
      "rentOffer.availabilityIsTemporary",
      true
    );

    this.state = {
      loading: false,
      availabilityIsTemporary,
    };

    const date = moment();

    this.formControls = {
      availabilityIsTemporary: {
        value: availabilityIsTemporary,
        onChange: (ref) => {
          this.setState({ availabilityIsTemporary: ref.value });
        },
      },
      availableFrom: { value: date.toDate() },
      availableUntil: { value: date.add(1, "year").toDate() },
      keyAction: {
        value: !!this.props.assignment.keyNr
          ? KeyAction.KeepAndMigrate
          : KeyAction.Remove,
      },
      rentPrice: { value: get(this.props.assignment, "rentOffer.rentPrice") },
      rentCondition: {
        value: get(
          this.props.assignment,
          "rentOffer.rentCondition",
          RentCondition.PricePerMonth
        ),
      },
    };
  }

  public async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!this.props.visible && !nextProps.visible) {
      await AsyncUtil.wait(500);

      const date = moment();
      this.form.update({
        availabilityIsTemporary: true,
        availableFrom: date.toDate(),
        availableUntil: date.add(1, "year").toDate(),
        rentPrice: get(nextProps.assignment, "rentOffer.rentPrice", 0),
        rentCondition: get(
          nextProps.assignment,
          "rentOffer.rentCondition",
          RentCondition.PricePerMonth
        ),
      });
    }

    if (!this.props.visible && !!nextProps.visible) {
      const availabilityIsTemporary = get(
        nextProps.assignment,
        "rentOffer.availabilityIsTemporary",
        true
      );

      this.form.update({
        rentPrice: get(nextProps.assignment, "rentOffer.rentPrice", 0),
        availabilityIsTemporary,
      });

      this.setState({
        availabilityIsTemporary,
      });
    }
  }

  public render() {
    const disabled = this.state.loading;
    const { keyNr } = this.props.assignment;

    return (
      <Modal visible={this.props.visible} onClose={this.onCloseHandler}>
        <ModalHeader
          title="renewRentOfferModalTitle"
          titleValues={{ displayName: this.props.displayName }}
          close={true}
        />
        <ModalBody>
          <Form
            name="renew-rent-offer"
            formControls={this.formControls}
            form={(form) => (this.form = form)}
            onSubmit={this.onFormSubmit}
          >
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="pre-label">
                  <label htmlFor="availableFrom" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.availability" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.RadioGroup name="availabilityIsTemporary" asButtonList>
                    <Input.Radio
                      label="availabilityNotTemporary"
                      value={false}
                      checked={this.state.availabilityIsTemporary === false}
                    />
                    <Input.Radio
                      label="availabilityTemporary"
                      value={true}
                      checked={this.state.availabilityIsTemporary === true}
                    />
                  </Input.RadioGroup>
                </div>
              </div>
            </div>

            {this.state.availabilityIsTemporary && (
              <div className="form__row">
                <div className="form__group">
                  {this.state.availabilityIsTemporary}
                  <div className="column" styleName="pre-label">
                    <label htmlFor="availableFrom" className="pre">
                      <ResourceText resourceKey="updateRentOfferModal.availableFrom" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column" styleName="input">
                    <Input.Datepicker
                      name="availableFrom"
                      disabled={disabled}
                    />
                  </div>
                  <div className="column" styleName="label">
                    <label htmlFor="availableUntil" className="pre">
                      <ResourceText resourceKey="updateRentOfferModal.availableUntil" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column" styleName="input">
                    <Input.Datepicker
                      name="availableUntil"
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="pre-label">
                  <label htmlFor="rentPrice" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.rentPrice" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="input">
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="rentPrice"
                      min={0}
                      max={99999}
                      pretty={true}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="column" styleName="label">
                  <label htmlFor="rentCondition" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.rentCondition" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="input">
                  <Input.NewSelect
                    name="rentCondition"
                    values={this.props.rentConditions}
                    displayProp="displayName"
                    valuesProp="value"
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            {!!keyNr && (
              <div className="form__row">
                <label htmlFor="">
                  <I18n value="updateRentOfferModal.hasKeyNumber" />
                </label>
                <Input.RadioGroup name="keyAction" asButtonList>
                  <Input.Radio
                    label={`keyAction.${KeyAction.Remove.toString()}`}
                    value={KeyAction.Remove}
                  />
                  <Input.Radio
                    label={`keyAction.${KeyAction.Keep.toString()}`}
                    value={KeyAction.Keep}
                  />
                  <Input.Radio
                    label={`keyAction.${KeyAction.KeepAndMigrate.toString()}`}
                    value={KeyAction.KeepAndMigrate}
                  />
                  <Input.Radio
                    label={`keyAction.${KeyAction.Migrate.toString()}`}
                    value={KeyAction.Migrate}
                  />
                </Input.RadioGroup>
              </div>
            )}

            <div styleName="tip">
              <i className="far fa-info-circle" />
              <ResourceText resourceKey="renewRentOfferInfo" />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.form.submit()}
            disabled={disabled}
          >
            <ButtonLoader
              resourceKey="renewRentOfferModalSave"
              loading={this.state.loading}
            />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private onCloseHandler() {
    if (!!this.state.loading) return;
    this.props.onClose();
  }

  private async onFormSubmit(values: FormReturnValue) {
    if (!this.form || !!this.state.loading) return;
    this.setState({ loading: true });

    let copyKeyNumber = false;
    let keepKeyNumber = false;

    switch (values.keyAction) {
      case KeyAction.Keep: {
        keepKeyNumber = true;
        break;
      }
      case KeyAction.KeepAndMigrate: {
        keepKeyNumber = true;
        copyKeyNumber = true;
        break;
      }
      case KeyAction.Migrate: {
        copyKeyNumber = true;
        break;
      }
      default: {
        break;
      }
    }

    try {
      await this.props.renewRentOffer(
        this.props.assignment,
        values.availableFrom,
        values.availableUntil,
        values.rentPrice,
        values.rentCondition,
        values.availabilityIsTemporary,
        copyKeyNumber,
        keepKeyNumber
      );
      this.props.onClose();
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }
}
