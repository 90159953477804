import {
  ArchiveFilter,
  SortOrder,
  WidgetClient,
  WidgetEntityType,
  WidgetOrderByField,
  WidgetInitializeRequest,
} from "@haywork/api/authorization";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { AuthorizationActions } from "@haywork/stores/authorization";
import { AsyncUtil } from "@haywork/util";
import head from "lodash-es/head";
import { Dispatch } from "../";
import * as moment from "moment";
import isArray from "lodash-es/isArray";

const parseRequest = new ParseRequest();

const getConsentedWidgets = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Widgets = new WidgetClient(authorizationHost);

    try {
      const widgets = await parseRequest.response(
        Widgets.search(
          {
            filterByArchived: ArchiveFilter.ArchivedAndNot,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            orderBy: WidgetOrderByField.Default,
            hasConsent: true,
            isEnabled: true,
          },
          realEstateAgencyId
        ).then((response) => response.results || [])
      );

      dispatch(AuthorizationActions.Widgets.setConsentedWidgets(widgets));
    } catch (error) {
      throw error;
    }
  };
};

const getInitializedWidgets = (
  entityId: string | string[],
  entityType: WidgetEntityType,
  cancelStoreUpdate: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { authorizationHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { features } = state.appSettings;
    const { initializedWidgets } = state.authorization.widgets;

    if (!!initializedWidgets && !!initializedWidgets.length) {
      try {
        const first = head(initializedWidgets);
        const { signedCallbackUri } = first;
        const [uri, paramString] = signedCallbackUri.split("?");
        const params = paramString.split("&");
        const paramObj = params.reduce((state, param) => {
          const [key, value] = param.split("=");
          state[key] = value;
          return state;
        }, {} as { [key: string]: string });

        const { entityId: paramEntityId, validUntil } = paramObj;
        if (
          !!paramEntityId &&
          !!validUntil &&
          paramEntityId === entityId &&
          moment()
            .add(15, "seconds")
            .isBefore(moment.unix(parseInt(validUntil)))
        ) {
          return initializedWidgets;
        }
      } catch {
        // Do nothing
      }
    }

    if (!cancelStoreUpdate) {
      dispatch(
        AuthorizationActions.Widgets.setInitializedWidgets([], entityId)
      );
    }

    if (FeatureHelper.ignoreBlock(features, "APP_XCHANGE")) {
      return [];
    }

    const Widgets = new WidgetClient(authorizationHost);

    try {
      let request: WidgetInitializeRequest = { entityType };

      if (isArray(entityId)) {
        request = {
          ...request,
          entityIds: entityId,
        };
      } else {
        request = {
          ...request,
          entityId,
        };
      }

      const widgets = await parseRequest.response(
        Widgets.initialize(request, realEstateAgencyId).then(
          (response) => response.initializedWidgets || []
        )
      );

      if (!cancelStoreUpdate) {
        dispatch(
          AuthorizationActions.Widgets.setInitializedWidgets(widgets, entityId)
        );
      }

      return widgets;
    } catch (error) {
      throw error;
    }
  };
};

const hideModal = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(AuthorizationActions.Widgets.hideModal());
    await AsyncUtil.wait(500);
    dispatch(AuthorizationActions.Widgets.resetModal());
  };
};

export const Widgets = {
  getConsentedWidgets,
  getInitializedWidgets,
  hideModal,
};
