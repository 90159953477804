import { RootEntityType } from "@haywork/api/event-center";
import { LinkedRelation, SearchAssignment } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  MapDispatchToProps,
  SearchAssignmentThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { editable } from "@haywork/modules/editable";
import {
  SearchAssignmentDetailComponent,
  SearchAssignmentDetailComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState, EditableActions } from "@haywork/stores";
import { SearchAssignmentActions } from "@haywork/stores/search-assignment";
import { SearchAssignmentUtil } from "@haywork/util";
import { EmailAssignment } from "@haywork/util/email-v2";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { canSendEmail } from "@haywork/selector";

type StateProps = {
  searchAssignment: SearchAssignment;
  showSaveModal: boolean;
  canSave: boolean;
  canSendEmail?: boolean;
  saveSearchAssignmentStatus: string;
};
type DispatchProps = {
  navigate: (url: string) => void;
  saveAndCloseSearchAssignment: (searchAssignment: SearchAssignment) => void;
  saveSearchAssignment: (searchAssignment: SearchAssignment) => void;
  toggleSaveModal: (status: boolean) => void;
  stateDisablePreppedForSave: (path: string) => void;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
  remove: (id: string) => Promise<void>;
  mailSelection: (
    assignments: EmailAssignment[],
    linkedRelations: LinkedRelation[]
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SearchAssignmentDetailComponentProps,
  AppState
> = (state) => {
  return {
    canSendEmail: canSendEmail(state),
    searchAssignment: state.editable.currentComponentState,
    showSaveModal: state.searchAssignment.single.showSaveModal,
    canSave: SearchAssignmentUtil.validForSave(
      state.editable.currentComponentState
    ),
    currentPath: state.editable.currentPath,
    saveSearchAssignmentStatus:
      state.searchAssignment.single.saveSearchAssignmentStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SearchAssignmentDetailComponentProps
> = (dispatch) => ({
  navigate: (url: string) => dispatch(push(url)),
  saveAndCloseSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(
      SearchAssignmentThunks.saveSearchAssignment(searchAssignment, false, true)
    ),
  saveSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(
      SearchAssignmentThunks.saveSearchAssignment(searchAssignment, false)
    ),
  toggleSaveModal: (status: boolean) =>
    dispatch(SearchAssignmentActions.toggleSaveModal(status)),
  stateDisablePreppedForSave: (path: string) =>
    dispatch(EditableActions.stateDisablePreppedForSave({ path })),
  archive: (id: string) => dispatch(SearchAssignmentThunks.archive(id)),
  unArchive: (id: string) => dispatch(SearchAssignmentThunks.unArchive(id)),
  remove: (id: string) => dispatch(SearchAssignmentThunks.remove(id)),
  mailSelection: (
    assignments: EmailAssignment[],
    linkedRelations: LinkedRelation[]
  ) =>
    dispatch(
      EmailThunk.Main.mailAssignmentSelection(assignments, linkedRelations)
    ),
});

export type SearchAssignmentDetailContainerProps = StateProps & DispatchProps;

export const SearchAssignmentDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(SearchAssignmentDetailComponent)
  ),
  {
    entityType: RootEntityType.SearchAssignment,
    icon: "folder-open",
    thunk: SearchAssignmentThunks.getSearchAssignment,
    status: "searchAssignment.single.detailStatus",
    statePath: "searchAssignment.single.searchAssignment",
    action: SearchAssignmentActions.setSearchAssignmentDirectly,
    route: SEARCHASSIGNMENTROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveSearchAssignmentConfirmTitle" },
      body: { key: "saveSearchAssignmentConfirmBody" },
    },
  }
);
