import {
  AvailabilityStatus,
  ObjectAssignment,
  PublicationStatus,
  RealEstateGroup,
  TransactionMetaData,
  UpdateAvailabilityRentedSettings,
  UpdateAvailabilitySoldSettings,
} from "@haywork/api/kolibri";
import { TransactionMetaDataResponse } from "@haywork/middleware";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ConfirmComponent, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AsyncUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  Breadcrumbs,
  ObjectLeftBehindForm,
  ObjectLeftBehindFormALV,
  PersonalForm,
  PersonalFormALV,
  TransactionRentForm,
  TransactionSaleForm,
} from "./components";
import { TransactionModalContainerProps } from "./transaction-modal.container";

const styles = require("./transaction-modal.component.scss");

export enum TransactionState {
  Transaction = "Transaction",
  PersonalData = "PersonalData",
  ObjectLeftBehind = "ObjectLeftBehind",
}

export interface TransactionModalComponentProps {
  visible: boolean;
  assignment: ObjectAssignment;
  transactionMetaData: TransactionMetaData;
  onClose: () => void;
}
interface State {
  currentState: TransactionState;
  validStates: TransactionState[];
  updatedObjectAssignment: ObjectAssignment;
  rentSettings: UpdateAvailabilityRentedSettings;
  soldSettings: UpdateAvailabilitySoldSettings;
  transactionMetaData: TransactionMetaDataResponse;
  loading: boolean;
  submitTransactionData: boolean;
  confirmedDirectTransaction: boolean;
  confirmDirectTransactionVisible: boolean;
  confirmedDirectTransactionTitle: string;
  confirmedDirectTransactionBody: string;
  showTiaraInfo: boolean;
}
type Props = TransactionModalComponentProps & TransactionModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class TransactionModalComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      currentState: TransactionState.Transaction,
      validStates: [],
      updatedObjectAssignment: this.props.assignment,
      rentSettings: this.mapRentSettings(this.props.assignment),
      soldSettings: this.mapSoldSettings(this.props.assignment),
      transactionMetaData: this.props.transactionMetaData,
      loading: false,
      submitTransactionData: false,
      confirmedDirectTransaction: false,
      confirmDirectTransactionVisible: false,
      confirmedDirectTransactionTitle: "",
      confirmedDirectTransactionBody: "",
      showTiaraInfo: false,
    };

    this.onStateChange = this.onStateChange.bind(this);
    this.navigateNext = this.navigateNext.bind(this);
    this.navigatePrev = this.navigatePrev.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onAssignmentSaleUpdate = this.onAssignmentSaleUpdate.bind(this);
    this.onAssignmentRentUpdate = this.onAssignmentRentUpdate.bind(this);
    this.onTransactionMetaDataUpdate =
      this.onTransactionMetaDataUpdate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.mapRentSettings = this.mapRentSettings.bind(this);
    this.mapSoldSettings = this.mapSoldSettings.bind(this);
    this.onInitialValidState = this.onInitialValidState.bind(this);
    this.checkIfPublishingToTiara = this.checkIfPublishingToTiara.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.visible && this.props.visible) {
      this.checkIfPublishingToTiara();
    }

    if (!prevProps.visible && !!this.props.visible) {
      this.resetState();
    }
  }

  public render() {
    if (!this.props.assignment) return null;
    let { displayName, forSale } = this.props.assignment;
    displayName = displayName || "";
    forSale = !!forSale;

    return (
      <Modal visible={this.props.visible} onClose={this.onClose}>
        <ModalHeader
          close={true}
          title="transactionModal.title"
          titleValues={{ displayName }}
        />
        <ModalBody noPadding={true}>
          <Breadcrumbs
            currentState={this.state.currentState}
            onStateChange={this.onStateChange}
            validStates={this.state.validStates}
            forSale={forSale}
          />

          {this.state.showTiaraInfo && (
            <div styleName="info-block full important">
              <i className="fal fa-info-circle" />
              <ResourceText
                resourceKey={
                  forSale
                    ? "saleTransaction.tiaraExtraInfo"
                    : "rentTransaction.tiaraExtraInfo"
                }
                values={{ url: "" }}
                asHtml={true}
              />
            </div>
          )}

          <div styleName="forms">
            {this.state.loading && <Ui.Loaders.Fullscreen mask={true} />}

            {this.props.visible && (
              <>
                {this.state.currentState === TransactionState.Transaction && (
                  <div styleName="form">
                    {forSale ? (
                      <TransactionSaleForm
                        saleConditions={this.props.saleConditions}
                        assignment={this.state.updatedObjectAssignment}
                        onUpdate={this.onAssignmentSaleUpdate}
                        onInitialValidState={this.onInitialValidState}
                        submitTransactionData={this.state.submitTransactionData}
                      />
                    ) : (
                      <TransactionRentForm
                        assignment={this.state.updatedObjectAssignment}
                        onUpdate={this.onAssignmentRentUpdate}
                        onInitialValidState={this.onInitialValidState}
                        submitTransactionData={this.state.submitTransactionData}
                      />
                    )}
                  </div>
                )}

                {this.state.currentState === TransactionState.PersonalData && (
                  <div styleName="form">
                    {this.props.assignment.realEstateGroup ===
                    RealEstateGroup.Agricultural ? (
                      <PersonalFormALV
                        assignment={this.state.updatedObjectAssignment}
                        transactionMetaData={this.state.transactionMetaData}
                        buyerTypeOptions={this.props.buyerTypeOptions}
                        householdCompositionOptions={
                          this.props.householdCompositionOptions
                        }
                        ageRangeOptions={this.props.ageRangeOptions}
                        alvAgriculturalFunctionOptions={
                          this.props.alvAgriculturalFunctionOptions
                        }
                        alvContinuationOptions={
                          this.props.alvContinuationOptions
                        }
                        alvNonAgriculturalFunctionOptions={
                          this.props.alvNonAgriculturalFunctionOptions
                        }
                        onUpdate={this.onTransactionMetaDataUpdate}
                      />
                    ) : (
                      <PersonalForm
                        assignment={this.state.updatedObjectAssignment}
                        transactionMetaData={this.state.transactionMetaData}
                        buyerTypeOptions={this.props.buyerTypeOptions}
                        movingReasonOptions={this.props.movingReasonOptions}
                        householdCompositionOptions={
                          this.props.householdCompositionOptions
                        }
                        ageRangeOptions={this.props.ageRangeOptions}
                        childAgeRangeOptions={this.props.childAgeRangeOptions}
                        familyIncomeOptions={this.props.familyIncomeOptions}
                        onUpdate={this.onTransactionMetaDataUpdate}
                      />
                    )}
                  </div>
                )}
                {this.state.currentState ===
                  TransactionState.ObjectLeftBehind && (
                  <div styleName="form">
                    {this.props.assignment.realEstateGroup ===
                    RealEstateGroup.Agricultural ? (
                      <ObjectLeftBehindFormALV
                        assignment={this.state.updatedObjectAssignment}
                        transactionMetaData={this.state.transactionMetaData}
                        selectedBuyerType={get(
                          this.state.transactionMetaData,
                          "buyerType"
                        )}
                        typeOfHabitationOptions={
                          this.props.typeOfHabitationOptions
                        }
                        onUpdate={this.onTransactionMetaDataUpdate}
                      />
                    ) : (
                      <ObjectLeftBehindForm
                        assignment={this.state.updatedObjectAssignment}
                        transactionMetaData={this.state.transactionMetaData}
                        selectedBuyerType={get(
                          this.state.transactionMetaData,
                          "buyerType"
                        )}
                        listingTypes={this.props.listingTypes}
                        houseSorts={this.props.houseSorts}
                        houseTypes={this.props.houseTypes}
                        houseCharacteristics={this.props.houseCharacteristics}
                        apartmentSorts={this.props.apartmentSorts}
                        apartmentCharacteristics={
                          this.props.apartmentCharacteristics
                        }
                        typeOfHabitationOptions={
                          this.props.typeOfHabitationOptions
                        }
                        onUpdate={this.onTransactionMetaDataUpdate}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div styleName="footer">
            <div styleName="column left">
              {this.state.currentState !== TransactionState.Transaction && (
                <button className="btn btn-primary" onClick={this.navigatePrev}>
                  <ResourceText resourceKey="transactionModal.Prev" />
                </button>
              )}
            </div>
            <div styleName="column center" />
            <div styleName="column right">
              <button className="btn btn-success" onClick={this.onSave}>
                <ResourceText resourceKey="transactionModal.Save" />
              </button>
              {this.state.currentState !==
                TransactionState.ObjectLeftBehind && (
                <button className="btn btn-primary" onClick={this.navigateNext}>
                  <ResourceText resourceKey="transactionModal.Next" />
                </button>
              )}
            </div>
          </div>
        </ModalFooter>

        <ConfirmComponent
          visible={this.state.confirmDirectTransactionVisible}
          titleResourceKey={this.state.confirmedDirectTransactionTitle}
          bodyResourceKey={this.state.confirmedDirectTransactionBody}
          onClose={() =>
            this.setState({ confirmDirectTransactionVisible: false })
          }
          onConfirm={() => {
            this.setState(
              {
                confirmDirectTransactionVisible: false,
                confirmedDirectTransaction: true,
              },
              () => {
                this.onSave();
              }
            );
          }}
        />
      </Modal>
    );
  }

  private onStateChange(currentState: TransactionState) {
    this.setState({ currentState, submitTransactionData: false });
  }

  private navigateNext() {
    this.setState(({ currentState }) => ({
      currentState:
        currentState === TransactionState.Transaction
          ? TransactionState.PersonalData
          : TransactionState.ObjectLeftBehind,
      submitTransactionData: false,
    }));
  }

  private navigatePrev() {
    this.setState(({ currentState }) => {
      return {
        currentState:
          currentState === TransactionState.ObjectLeftBehind
            ? TransactionState.PersonalData
            : TransactionState.Transaction,
        submitTransactionData: false,
      };
    });
  }

  private async onClose() {
    this.props.onClose();
    await AsyncUtil.wait(500);
    this.resetState();
  }

  private onInitialValidState(valid: boolean) {
    const validStates = (this.state.validStates || []).filter((state) =>
      [
        TransactionState.Transaction,
        TransactionState.ObjectLeftBehind,
      ].includes(state)
    );

    if (valid) {
      validStates.push(TransactionState.Transaction);
      const { realEstateGroup } = this.props.assignment;

      if (realEstateGroup !== RealEstateGroup.Residential) {
        validStates.push(TransactionState.ObjectLeftBehind);
      }
    }

    this.setState({ validStates });
  }

  private onAssignmentSaleUpdate(
    soldSettings: UpdateAvailabilitySoldSettings,
    valid: boolean
  ) {
    const validStates = (this.state.validStates || []).filter(
      (state) => state === TransactionState.Transaction
    );

    if (valid) {
      validStates.push(TransactionState.Transaction);
    }

    const assignment = {
      ...this.state.updatedObjectAssignment,
      saleOffer: {
        ...this.state.updatedObjectAssignment.saleOffer,
        dateTransfer: soldSettings.dateOfTransfer,
        dateReservation: soldSettings.dateReservation,
        dateSold: soldSettings.dateSold,
        endDateBankWarranty: soldSettings.endDateBankWarranty,
        agreementDate: soldSettings.dateAgreement,
        purchasePrice: soldSettings.transactionPrice,
      },
      linkedApplicants: uniqBy(
        [
          ...(this.state.updatedObjectAssignment.linkedApplicants || []),
          ...soldSettings.linkedApplicants,
        ],
        (relation) => relation.id
      ),
      dateAgreement:
        this.state.updatedObjectAssignment.realEstateGroup ===
        RealEstateGroup.Agricultural
          ? soldSettings.dateAgreement
          : undefined,
    };

    this.setState({
      soldSettings,
      validStates,
      updatedObjectAssignment: assignment,
    });
  }

  private onAssignmentRentUpdate(
    rentSettings: UpdateAvailabilityRentedSettings,
    valid: boolean
  ) {
    const validStates = (this.state.validStates || []).filter(
      (state) => state === TransactionState.Transaction
    );

    if (valid) {
      validStates.push(TransactionState.Transaction);
    }

    const assignment = {
      ...this.state.updatedObjectAssignment,
      rentOffer: {
        dateReservation: rentSettings.dateReservation,
        rentPrice: rentSettings.transactionPrice,
        realizedPerMonth: rentSettings.transactionPrice,
        rentedFrom: rentSettings.dateRentedFrom,
        rentedUntil: rentSettings.dateRentedUntil,
        dateSignDeed: rentSettings.dateSignDeed,
        isVacantPropertyAct:
          this.state.updatedObjectAssignment.realEstateGroup ===
          RealEstateGroup.Agricultural
            ? rentSettings.isVacancyLaw
            : undefined,
      },
      linkedApplicants: uniqBy(
        [
          ...(this.state.updatedObjectAssignment.linkedApplicants || []),
          ...rentSettings.linkedApplicants,
        ],
        (relation) => relation.id
      ),
    };

    this.setState({
      rentSettings,
      validStates,
      updatedObjectAssignment: assignment,
    });
  }

  private onTransactionMetaDataUpdate(data: TransactionMetaDataResponse) {
    const pickedData: TransactionMetaDataResponse = {};

    for (const key in data) {
      const value = data[key];
      pickedData[key] = !value ? undefined : value;
    }

    this.setState({
      transactionMetaData: { ...this.state.transactionMetaData, ...pickedData },
    });
  }

  private async onSave() {
    const isMinimalValid = this.state.validStates.includes(
      TransactionState.Transaction
    );

    if (this.state.loading) return;

    if (!isMinimalValid) {
      this.setState({
        currentState: TransactionState.Transaction,
        submitTransactionData: true,
      });
      return;
    }

    const { assignment } = this.props;
    const { forSale, forRent } = assignment;

    switch (true) {
      case forRent: {
        if (
          !this.state.rentSettings.dateReservation &&
          !this.state.confirmedDirectTransaction &&
          assignment.availabilityStatus !== AvailabilityStatus.Rented
        ) {
          this.setState({
            confirmDirectTransactionVisible: true,
            confirmedDirectTransactionTitle:
              "confirmedDirectTransactionTitle.Rent",
            confirmedDirectTransactionBody:
              "confirmedDirectTransactionBody.Rent",
          });
          return;
        }
        break;
      }
      default: {
        if (
          !this.state.soldSettings.dateReservation &&
          !this.state.confirmedDirectTransaction
        ) {
          this.setState({
            confirmDirectTransactionVisible: true,
            confirmedDirectTransactionTitle:
              "confirmedDirectTransactionTitle.Sale",
            confirmedDirectTransactionBody:
              "confirmedDirectTransactionBody.Sale",
          });
          return;
        }
        break;
      }
    }

    this.setState({ loading: true });

    try {
      if (!!forSale) {
        await this.props.transitionObjectAssignment(
          assignment,
          this.state.transactionMetaData,
          this.state.soldSettings,
          undefined
        );
      } else {
        await this.props.transitionObjectAssignment(
          assignment,
          this.state.transactionMetaData,
          undefined,
          this.state.rentSettings
        );
      }

      this.onClose();
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false, confirmedDirectTransaction: false });
    }
  }

  private mapRentSettings(
    assignment: ObjectAssignment
  ): UpdateAvailabilityRentedSettings {
    if (!assignment.forRent) return null;
    const { rentOffer, linkedApplicants } = assignment;
    const {
      rentedFrom,
      rentedUntil,
      dateReservation,
      rentPrice,
      dateSignDeed,
      realizedPerMonth,
      isVacantPropertyAct,
    } = rentOffer;

    return {
      dateRentedFrom: rentedFrom,
      dateRentedUntil: rentedUntil,
      dateReservation,
      linkedApplicants,
      dateSignDeed,
      transactionPrice: realizedPerMonth || rentPrice,
      isVacancyLaw: isVacantPropertyAct,
    };
  }

  private mapSoldSettings(
    assignment: ObjectAssignment
  ): UpdateAvailabilitySoldSettings {
    if (!assignment.forSale) return null;
    const { saleOffer, linkedApplicants } = assignment;

    return {
      dateOfTransfer: saleOffer.dateTransfer,
      dateReservation: saleOffer.dateReservation,
      dateAgreement: saleOffer.agreementDate,
      dateSold: saleOffer.dateSold,
      endDateBankWarranty: saleOffer.endDateBankWarranty,
      linkedApplicants,
      transactionPrice: saleOffer.purchasePrice,
      isSaleAndLeaseBack: saleOffer.isSaleAndLeaseBack,
    };
  }

  private async checkIfPublishingToTiara() {
    let { showTiaraInfo } = this.state;
    const { id } = this.props.assignment;

    try {
      const publications = await this.props.getPublications(id);
      showTiaraInfo = publications.reduce((state, publication) => {
        if (
          /tiara/gi.test(publication.mediaPartnerName || "") &&
          publication.publicationStatus !== PublicationStatus.Inactive
        ) {
          state = true;
        }
        return state;
      }, showTiaraInfo);

      this.setState({ showTiaraInfo });
    } catch (error) {
      throw error;
    }
  }

  private resetState() {
    this.setState({
      currentState: TransactionState.Transaction,
      updatedObjectAssignment: this.props.assignment,
      rentSettings: this.mapRentSettings(this.props.assignment),
      soldSettings: this.mapSoldSettings(this.props.assignment),
      transactionMetaData: this.props.transactionMetaData,
      loading: false,
      submitTransactionData: false,
      confirmedDirectTransaction: false,
      confirmDirectTransactionVisible: false,
      confirmedDirectTransactionTitle: "",
      confirmedDirectTransactionBody: "",
      showTiaraInfo: false,
    });
  }
}
