import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import {
  LinkedRelation,
  RelationType,
  MailHistorySnapShot,
} from "@haywork/api/kolibri";
import { FormattedDate } from "react-intl";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./matchmail-item.component.scss");

interface SearchAssignmentWidgetMatchMailItemComponentProps {
  matchMail: MailHistorySnapShot;
  onMailClick: (properties: string[], dateTimeSent: Date) => void;
}
interface SearchAssignmentWidgetMatchMailItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetMatchMailItemComponent extends React.Component<
  SearchAssignmentWidgetMatchMailItemComponentProps,
  SearchAssignmentWidgetMatchMailItemComponentState
> {
  public render() {
    const { dateTimeSent, matchedPropertyCount, matchedProperties } =
      this.props.matchMail;

    return (
      <div styleName="matchmail-item">
        <div styleName="matchmail-item__icon">
          <i className="fal fa-fw fa-envelope-open" />
        </div>
        <div styleName="matchmail-item__meta">
          <strong>
            <FormattedDate
              value={dateTimeSent}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </strong>
          ,{" "}
          <span
            className="as-link"
            onClick={() =>
              this.props.onMailClick(matchedProperties, dateTimeSent)
            }
          >
            <ResourceText
              resourceKey="matchMailPropertyMatches"
              values={{ count: matchedPropertyCount }}
            />
          </span>
        </div>
      </div>
    );
  }
}
