import { DocumentTemplateSnapShot } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { NewDocumentContainerProps } from "@haywork/modules/dynamic-documents";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DynamicDocumentsCategoryComponent } from "./category.component";

const styles = require("./new-document.component.scss");

export interface NewDocumentComponentProps {}
interface NewDocumentComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class NewDocumentComponent extends React.Component<
  NewDocumentComponentProps & NewDocumentContainerProps,
  NewDocumentComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.props.getDocumentTemplates();

    this.formControls = {
      documentTemplate: { value: "" },
    };

    this.onSelectDocumentTemplate = this.onSelectDocumentTemplate.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="newDocument">
        {this.props.documentStatus === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}
        <div styleName="wrapper">
          <div className="container-fluid">
            <div styleName="documentSearch">
              <Form
                name="document-search"
                onChange={this.onFormChangeHandler}
                formControls={this.formControls}
              >
                <div className="form__row">
                  <label styleName="sectionTitle" htmlFor="documentTemplate">
                    <ResourceText resourceKey="whichDocumentDoYouWantToMake" />
                  </label>
                  <Input.QueryLegacy
                    name="documentTemplate"
                    values={this.props.documentTemplates}
                    placeholder="templateSearchExample"
                    displayPath="name"
                    matchPath="name"
                    resultPath="results"
                  />
                  <div styleName="recentlyUsed">
                    <div styleName="title">
                      <ResourceText resourceKey="recentlyUsed" />
                    </div>
                    {this.renderRecentlyUsed()}
                  </div>
                </div>
              </Form>
            </div>

            <div styleName="documentCategories">
              <h2 styleName="sectionTitle">
                <ResourceText resourceKey="chooseOneOfTheTemplates" />
              </h2>
              <div styleName="categories">
                <div styleName="row">
                  {this.props.documentCategoryTemplates.map((category, idx) => (
                    <ErrorBoundary key={idx}>
                      <DynamicDocumentsCategoryComponent
                        category={category}
                        onClick={this.onSelectDocumentTemplate}
                      />
                    </ErrorBoundary>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onFormChangeHandler(result: FormReturnValue) {
    if (result.documentTemplate && result.documentTemplate.length > 0) {
      const template: DocumentTemplateSnapShot = result.documentTemplate[0];
      this.onSelectDocumentTemplate(template.id);
    }
  }

  private onSelectDocumentTemplate(id: string) {
    this.props.createDynamicDocument(id);
  }

  private renderRecentlyUsed(): React.ReactElement<HTMLDivElement> {
    const { recentlyUsed } = this.props;

    return (
      <div styleName="documents">
        {recentlyUsed.map((template, idx) => (
          <span
            styleName="document"
            key={idx}
            onClick={() => this.onSelectDocumentTemplate(template.id)}
          >
            <span styleName="documentName">{template.name}</span>
            {idx !== recentlyUsed.length - 1 && (
              <span styleName="separator">-</span>
            )}
          </span>
        ))}
      </div>
    );
  }
}
