import {
  ActiveFilter,
  AssignmentType,
  GlobalSearchClient,
  GlobalSearchType,
  RealEstateGroup,
  RelationType,
} from "@haywork/api/kolibri";
import { AppState, GlobalSearchActions } from "@haywork/stores";
import { Dispatch } from ".";
import { REQUEST } from "@haywork/constants";
import { ParseRequest, ApiType } from "@haywork/services";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import AbortController from "@haywork/services/abort.service";

const parseRequest = new ParseRequest();

const abortGlobalSearch = new AbortController();
const search = (
  query: string,
  type?: GlobalSearchType,
  takePerType: number = 5
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      await abortGlobalSearch.process();

      if (!query || query.length < 2) return;
      dispatch(GlobalSearchActions.setSearchStatus(REQUEST.PENDING));

      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { features } = state.appSettings;
      const client = new GlobalSearchClient(host, abortGlobalSearch.fetch());

      const filterByTypes = !!type
        ? [type]
        : [
            GlobalSearchType.Assignments,
            GlobalSearchType.Relations,
            GlobalSearchType.Invoices,
            GlobalSearchType.Tasks,
          ];
      const filterByRealEstateGroups = [
        RealEstateGroup.Residential,
        RealEstateGroup.Commercial,
        RealEstateGroup.Agricultural,
      ];
      const term = query.substr(0, 50);
      const filterByAssignmentTypes = FeatureHelper.executeBlock(
        features,
        "PROJECTS"
      )
        ? null
        : [AssignmentType.Object];

      const result = await parseRequest.response(
        client.search(
          {
            filterByActive: ActiveFilter.ActiveOrInactive,
            takePerType,
            filterByAssignmentTypes,
            filterByRealEstateGroups,
            term: (term || "").trim(),
            filterByTypes,
            filterByRelationTypes: [
              RelationType.ContactCompany,
              RelationType.ContactPerson,
              RelationType.Employee,
            ],
          },
          realEstateAgencyId
        ),
        ApiType.Kolibri,
        { displayError: false }
      );

      switch (type) {
        case GlobalSearchType.Assignments:
          dispatch(GlobalSearchActions.setAssignmentResults(result));
          break;
        case GlobalSearchType.Relations:
          dispatch(GlobalSearchActions.setRelationResults(result));
          break;
        case GlobalSearchType.Invoices:
          dispatch(GlobalSearchActions.setInvoiceResults(result));
          break;
        case GlobalSearchType.Tasks:
          dispatch(GlobalSearchActions.setTaskResults(result));
          break;
        default:
          dispatch(GlobalSearchActions.setInitialResults(result));
          break;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const GlobalSearchThunks = {
  search,
};
