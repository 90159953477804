import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { ExtendedKey } from "@haywork/middleware";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  linkedKey: ExtendedKey;
  activeKey: number | null;
  onClick: (key: ExtendedKey) => void;
};

export const KeyComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ linkedKey, activeKey, onClick }) => {
      const prettyKey = useMemo(() => {
        if (!linkedKey || !linkedKey.id) return null;
        let pre = "";
        for (let i = 0; i < 3 - linkedKey.id.toString().length; i++) {
          pre = `0${pre}`;
        }

        return (
          <span>
            {!!pre && <span styleName="pre">{pre}</span>}
            {linkedKey.id}
          </span>
        );
      }, [linkedKey]);

      const active = activeKey === linkedKey.id;

      const inUse = useMemo(() => {
        if (!linkedKey.isAvailable || active) return "inUse";
      }, [linkedKey, active]);

      return (
        <div
          styleName={classNames("key", {
            active,
            inUse,
          })}
          onClick={() => onClick(linkedKey)}
        >
          <div styleName="spacer" />
          <div styleName="keyNr">{prettyKey}</div>
          <div styleName="spacer">
            {(!linkedKey.isAvailable || active) && (
              <I18n
                value={active ? "keyNumber.active" : "keyNumber.alreadyUsed"}
              />
            )}
          </div>
        </div>
      );
    }
  )
);
