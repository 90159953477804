import { SearchAssignment } from "@haywork/api/kolibri";
import {
  MapDispatchToProps,
  SearchAssignmentThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  LinkedSearchAssignmentsComponent,
  LinkedSearchAssignmentsComponentProps
} from "./linked-search-assignments";

type StateProps = {};
type DispatchProps = {
  getSearchAssignmentsByIds: (ids: string[]) => Promise<SearchAssignment[]>;
  navigate: (path: string) => RouterAction;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  LinkedSearchAssignmentsComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LinkedSearchAssignmentsComponentProps
> = (dispatch) => ({
  getSearchAssignmentsByIds: (ids: string[]) =>
    dispatch(SearchAssignmentThunks.getSearchAssignmentsByIds(ids)),
  navigate: (path: string) => dispatch(push(path))
});

export type LinkedSearchAssignmentsContainerProps = StateProps & DispatchProps;
export const LinkedSearchAssignmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedSearchAssignmentsComponent);
