import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  RelationType,
  TaskCategoryOption,
  TimelineActionType,
  TimelineEvent,
  TimelineEventsStatistics,
} from "@haywork/api/kolibri";
import { AuthorizationThunks, Dispatch } from "@haywork/middleware";
import { RelationThunks } from "@haywork/middleware/thunk/relation.thunk";
import {
  RelationTimelineComponent,
  RelationTimelineComponentProps,
} from "@haywork/modules/relation";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  relationName?: string;
  relationTitle?: string;
  timelineEvents?: TimelineEvent[];
  timeLineStatistics?: TimelineEventsStatistics;
  relationTimelineStatus?: string;
  selectedRelationId?: string;
  canLoadMore?: boolean;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  relationType: RelationType;
}

interface DispatchProps {
  navigate: (route: string) => void;
  getTimelineEvents: (
    relationId?: string,
    init?: boolean,
    timelineActionTypes?: TimelineActionType[]
  ) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, RelationTimelineComponentProps>(
  state: AppState
) => ({
  relationName: state.relation.detail.selectedRelationDisplayName,
  relationTitle: state.relation.detail.selectedRelationDisplayName,
  relationType: state.relation.detail.selectedRelationType,
  timelineEvents: state.relation.detailTimeline.timelineEvents,
  timeLineStatistics: state.relation.detailTimeline.timeLineStatistics,
  relationTimelineStatus: state.relation.detailTimeline.relationTimelineStatus,
  selectedRelationId: state.relation.detail.selectedRelationId,
  canLoadMore:
    state.relation.detailTimeline.timelinePage <
    state.relation.detailTimeline.timelinePageCount,
  taskCategories: state.main.mastertable.kolibri.taskCategories,
  agendaItemCategories: state.scheduler.agendaItemCategories,
});

const mapDispatchToProps = <DispatchProps, RelationTimelineComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getTimelineEvents: (
    relationId?: string,
    init?: boolean,
    timelineActionTypes?: TimelineActionType[]
  ) =>
    dispatch(
      RelationThunks.getTimelineEvents(
        relationId,
        init,
        50,
        timelineActionTypes
      )
    ),
  navigate: (route: string) => dispatch(push(route)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type RelationTimelineContainerProps = StateProps & DispatchProps;
export const RelationTimelineContainer = connect<
  StateProps,
  DispatchProps,
  RelationTimelineComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationTimelineComponent);
