import {
  EventNotification,
  EventType,
  LinkedBundle,
} from "@haywork/api/event-center";
import I18n from "@haywork/components/i18n";
import Hint from "@haywork/components/ui/hint";
import UiIcon from "@haywork/components/ui/icon";
import {
  ACQUISITIONOBJECTROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  SCHEDULERROUTES,
  MLSROUTES,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import firstItem from "lodash-es/first";
import * as moment from "moment";
import * as React from "react";
import {
  forwardRef,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import Body from "../event-body";
import Icon from "../event-type-icon";
import { RelationProps } from "../../list";
import { EmailDraft } from "@haywork/stores/email-v2";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

enum LinkedEntityType {
  AgendaItem = "AgendaItem",
  ContactPersons = "ContactPersons",
  Employees = "Employees",
  ObjectAssignments = "ObjectAssignments",
  AcquisitionObjectAssignments = "AcquisitionObjectAssignments",
  ProjectAssignments = "ProjectAssignments",
  Publications = "Publications",
  Bundle = "Bundle",
  None = "None",
}

type Props = {
  data: EventNotification;
  first: boolean;
  last: boolean;
  emailEnabled: boolean;
  onCreateNewEmail: (emails: string) => void;
  onNavigate: (path: string) => void;
  onToggleStatus: (
    notificationId: string,
    eventId: string,
    isActive: boolean
  ) => Promise<void>;
  onMeasure: () => void;
  onCreateNewRelation: (data: EventNotification) => void;
  onCreateNewTask: (data: EventNotification) => void;
  onCreateNewAppointment: (data: EventNotification) => void;
  onUpdateRelation: (data: EventNotification, relation: RelationProps) => void;
  onOpenDraft: (draft: EmailDraft) => void;
};
type MatchedEntity = {
  id: string;
  displayName: string;
  type: LinkedEntityType;
};

export const DataRowComponent = memo(
  forwardRef(
    CSSModules(styles, { allowMultiple: true })(
      (
        {
          data,
          first,
          last,
          onNavigate,
          onToggleStatus,
          onMeasure,
          emailEnabled,
          onCreateNewEmail,
          onCreateNewAppointment,
          onCreateNewRelation,
          onCreateNewTask,
          onUpdateRelation,
          onOpenDraft,
        }: Props,
        ref
      ) => {
        const statusActionRef = useRef<HTMLDivElement>();
        const [processing, setProcessing] = useState(false);
        const {
          id,
          message,
          messageTemplate,
          linkedEvent,
          delegatedByEmployee,
          dateTimeNotify,
        } = data;

        const getLinkedEntity = useCallback((id: string): MatchedEntity => {
          if (!linkedEvent)
            return { id: null, displayName: null, type: LinkedEntityType.None };
          const {
            linkedAgendaItems,
            linkedContactPersons,
            linkedEmployees,
            linkedObjectAssignments,
            linkedProjectAssignments,
            linkedPublications,
            linkedAcquisitionObjectAssignments,
            linkedBundles,
          } = linkedEvent;
          let entity;

          entity = (linkedAgendaItems || []).find((item) => item.id === id);
          if (!!entity) return { ...entity, type: LinkedEntityType.AgendaItem };

          entity = (linkedContactPersons || []).find((item) => item.id === id);
          if (!!entity)
            return { ...entity, type: LinkedEntityType.ContactPersons };

          entity = (linkedEmployees || []).find((item) => item.id === id);
          if (!!entity) return { ...entity, type: LinkedEntityType.Employees };

          entity = (linkedObjectAssignments || []).find(
            (item) => item.id === id
          );
          if (!!entity)
            return { ...entity, type: LinkedEntityType.ObjectAssignments };

          entity = (linkedAcquisitionObjectAssignments || []).find(
            (item) => item.id === id
          );
          if (!!entity)
            return {
              ...entity,
              type: LinkedEntityType.AcquisitionObjectAssignments,
            };

          entity = (linkedProjectAssignments || []).find(
            (item) => item.id === id
          );
          if (!!entity)
            return { ...entity, type: LinkedEntityType.ProjectAssignments };

          entity = (linkedPublications || []).find((item) => item.id === id);
          if (!!entity)
            return { ...entity, type: LinkedEntityType.Publications };

          entity = (linkedBundles || []).find((item) => item.id === id);
          if (!!entity) return { ...entity, type: LinkedEntityType.Bundle };

          return { id: null, displayName: null, type: LinkedEntityType.None };
        }, []);

        const onNavigateCallback = useCallback(
          (entity: MatchedEntity) => {
            const { id, type } = entity;

            switch (type) {
              case LinkedEntityType.AgendaItem:
                return onNavigate(
                  route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id })
                );
              case LinkedEntityType.ContactPersons:
                return onNavigate(
                  route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
                );
              case LinkedEntityType.Employees:
                return onNavigate(route(EMPLOYEEROUTES.EMPLOYEE.URI, { id }));
              case LinkedEntityType.ObjectAssignments:
                return onNavigate(route(ASSIGNMENTROUTES.DETAIL.URI, { id }));
              case LinkedEntityType.AcquisitionObjectAssignments:
                return onNavigate(
                  route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id })
                );
              case LinkedEntityType.ProjectAssignments:
                return onNavigate(route(PROJECTROUTES.DETAIL.URI, { id }));
              case LinkedEntityType.Bundle:
                const linkedBundle = entity as LinkedBundle;
                const { appClientKey: source } = linkedBundle;
                return onNavigate(route(MLSROUTES.DETAIL.URI, { id, source }));
              default:
                return;
            }
          },
          [onNavigate]
        );

        const title = useMemo(() => {
          if (data?.linkedEvent?.eventType === EventType.ContactMeRequest) {
            const messages = data?.linkedEvent?.linkedMessages || [];
            const message = messages.find((message) => !!message.displayName);
            if (!!message?.displayName) {
              return message?.displayName;
            }
          }

          const template = messageTemplate || message || "";
          const matches = template.match(/\<\@\@(.*?)\@\@\>/g) || [];
          const slices = [];

          let stop = 0;

          matches.map((match, idx) => {
            const start = template.indexOf(match);
            const id = match.replace("<@@", "").replace("@@>", "");
            const entity = getLinkedEntity(id);

            slices.push(template.substring(stop, start));
            slices.push(
              <span
                key={idx}
                className="as-link"
                onClick={() => onNavigateCallback(entity)}
              >
                {entity.displayName}
              </span>
            );

            stop = start + match.length;
            if (idx === matches.length - 1) {
              slices.push(template.substring(stop, template.length));
            }
          });

          return slices.length === 0 ? template : slices;
        }, [
          message,
          messageTemplate,
          data,
          getLinkedEntity,
          onNavigateCallback,
        ]);

        const subtitle = useMemo(() => {
          if (!linkedEvent && !delegatedByEmployee && !dateTimeNotify)
            return null;
          const sections: ReactElement[] = [];

          if (!!dateTimeNotify) {
            const time = moment(dateTimeNotify);

            if (time.isValid()) {
              const now = moment();
              const clone = time.clone();

              switch (true) {
                case now.isSame(clone, "day"): {
                  sections.push(
                    <div styleName="section" key={sections.length}>
                      <I18n value="notification.subtitle.today" />
                    </div>
                  );
                  break;
                }
                case now.isSame(clone.subtract(1, "day"), "day"): {
                  sections.push(
                    <div styleName="section" key={sections.length}>
                      <I18n value="notification.subtitle.yesterday" />
                    </div>
                  );
                  break;
                }
                case now.isSame(clone.subtract(1, "day"), "day"): {
                  sections.push(
                    <div styleName="section" key={sections.length}>
                      <I18n value="notification.subtitle.dayBeforeYesterday" />
                    </div>
                  );
                  break;
                }
                default: {
                  sections.push(
                    <div styleName="section" key={sections.length}>
                      {time.format("DD MMMM YYYY HH:mm")}
                    </div>
                  );
                }
              }
            }
          }

          if (!!linkedEvent) {
            let { linkedMessages } = linkedEvent;
            linkedMessages = linkedMessages || [];
            const message = firstItem(linkedMessages);

            if (!!message?.sender) {
              sections.push(
                <div styleName="section" key={sections.length}>
                  {message.sender}
                </div>
              );
            }
          }

          if (!!delegatedByEmployee?.displayName) {
            sections.push(
              <div styleName="section" key={sections.length}>
                <I18n
                  value="notification.subtitle.delegatedBy"
                  values={{ displayName: delegatedByEmployee.displayName }}
                />
              </div>
            );
          }

          if (!sections.length) return null;

          return <div styleName="subtitle">{sections}</div>;
        }, [linkedEvent, delegatedByEmployee, dateTimeNotify]);

        const toggleStatus = useCallback(
          async (isActive: boolean) => {
            if (processing) return;

            try {
              setProcessing(true);
              await onToggleStatus(id, linkedEvent?.id, isActive);
            } finally {
              setProcessing(false);
            }
          },
          [onToggleStatus, id, linkedEvent, setProcessing, processing]
        );

        const statusAction = useMemo(() => {
          if (!linkedEvent) return null;
          const { archivedDate, archivedBy } = linkedEvent;
          const isActive = !archivedDate && !archivedBy;
          const label = isActive
            ? "notification.action.process"
            : "notification.action.unarchive";
          const icon = processing ? "spinner" : isActive ? "check" : "undo-alt";

          return (
            <div
              styleName={classNames("status-action", {
                active: isActive,
                processing,
              })}
              ref={statusActionRef}
              onClick={() => toggleStatus(isActive)}
            >
              <UiIcon
                name={icon}
                spin={processing}
                color={processing ? Colors.Gray : Colors.White}
                regular
                size={14}
              />
              <Hint parentRef={statusActionRef} label={label} />
            </div>
          );
        }, [linkedEvent, toggleStatus, processing]);

        const onUpdateRelationCallback = useCallback(
          (relation: RelationProps) => {
            onUpdateRelation(data, relation);
          },
          [data, onUpdateRelation]
        );

        const onCreateNewAppointmentCallback = useCallback(() => {
          onCreateNewAppointment(data);
        }, [data, onCreateNewAppointment]);

        const onCreateNewTaskCallback = useCallback(() => {
          onCreateNewTask(data);
        }, [data, onCreateNewTask]);

        const onCreateNewRelationCallback = useCallback(() => {
          onCreateNewRelation(data);
        }, [data, onCreateNewRelation]);

        const onOpenUrlCallback = useCallback(() => {
          const messages = data?.linkedEvent?.linkedMessages || [];
          const urls = messages.filter((message) => !!message.infoUrl);

          if (urls[0]?.infoUrl) {
            window.open(urls[0]?.infoUrl, "_blank");
          }
        }, [data]);

        useEffect(() => {
          onMeasure();
        }, [data, onMeasure]);

        return (
          <div styleName={classNames("row", { first, last })} ref={ref}>
            <div styleName="inner">
              <div styleName="header">
                <div styleName="header__icon">
                  <Icon event={linkedEvent} />
                </div>
                <div styleName="header__title">
                  <div styleName="title">{title}</div>
                  {subtitle}
                </div>
                <div styleName="header__actions">{statusAction}</div>
              </div>
              <Body
                emailEnabled={emailEnabled}
                onCreateNewEmail={onCreateNewEmail}
                event={linkedEvent}
                onNavigate={onNavigate}
                onUpdateRelation={onUpdateRelationCallback}
                onCreateNewAppointment={onCreateNewAppointmentCallback}
                onCreateNewTask={onCreateNewTaskCallback}
                onCreateNewRelation={onCreateNewRelationCallback}
                onOpenUrl={onOpenUrlCallback}
                onOpenDraft={onOpenDraft}
              />
            </div>
          </div>
        );
      }
    )
  )
);
