import * as React from "react";
import * as CSSModules from "react-css-modules";
import uniqBy from "lodash-es/uniqBy";
import { MutableRefObject } from "react";
import { ModalPortal } from "@haywork/portals";
import { Input, Form } from "@haywork/modules/form";
import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";

import { EmailTemplatesContainerProps } from "./email-templates.container";
import { Results } from "./results.component";

const styles = require("./email-templates.component.scss");

export interface EmailTemplatesComponentProps {
  visible: boolean;
  parent: HTMLElement;
  onHide: () => void;
  onAdd: (field: TemplateDefinitionSnapShot) => void;
}
interface State {
  emailTemplateStyle: React.CSSProperties;
  emailTemplatePointerStyle: React.CSSProperties;
  emailTemplateCategoryValue: string;
  emailTemplateFilterValue: string;
}
type Props = EmailTemplatesComponentProps & EmailTemplatesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailTemplatesComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;
  private outerRefs: HTMLDivElement[] = [];

  constructor(props) {
    super(props);

    this.state = {
      emailTemplateStyle: null,
      emailTemplatePointerStyle: null,
      emailTemplateCategoryValue: "",
      emailTemplateFilterValue: "",
    };

    this.calculateMergeFieldStyles = this.calculateMergeFieldStyles.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.bindOuterRefs = this.bindOuterRefs.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    if (!this.props.visible || !this.props.parent) return null;
    const { emailTemplateCategoryValue, emailTemplateFilterValue } = this.state;
    const categories = uniqBy(
      this.props.emailTemplates,
      (template) => template.linkedTemplateDefinitionCategory.id
    );

    const emailTemplates = this.props.emailTemplates.filter((field) => {
      const inCategory = !!emailTemplateCategoryValue
        ? field.linkedTemplateDefinitionCategory.id ===
          emailTemplateCategoryValue
        : true;
      const hasMatch = !!emailTemplateFilterValue
        ? field.name
            .toLowerCase()
            .includes(emailTemplateFilterValue.toLowerCase())
        : true;

      return inCategory && hasMatch;
    });

    return (
      <ModalPortal>
        <div
          styleName="merge-fields"
          style={this.state.emailTemplateStyle}
          ref={(ref) => (this.ref = ref)}
        >
          <div styleName="merge-fields__wrapper">
            <div styleName="filters">
              <Form
                name="emailTemplatesControl"
                asSubForm={true}
                formControls={{
                  emailTemplateCategory: { value: "" },
                  emailTemplateSubject: { value: "" },
                }}
              >
                <div styleName="row">
                  <div styleName="row__label">
                    <label htmlFor="emailTemplateCategory">
                      <ResourceText resourceKey="emailTemplatesLabel.emailTemplateCategory" />
                    </label>
                  </div>
                  <div styleName="row__input">
                    <Input.NewSelect
                      name="emailTemplateCategory"
                      values={categories}
                      value={this.state.emailTemplateCategoryValue}
                      onChange={this.onCategoryChange}
                      addEmptyOption
                      emptyOptionLabel="allEmailTemplates"
                      displayProp="linkedTemplateDefinitionCategory.name"
                      valuesProp="linkedTemplateDefinitionCategory.id"
                      outerRef={this.bindOuterRefs}
                    />
                  </div>
                </div>

                <div styleName="row">
                  <div styleName="row__label">
                    <label htmlFor="emailTemplateSubject">
                      <ResourceText resourceKey="emailTemplatesLabel.emailTemplateSubject" />
                    </label>
                  </div>
                  <div styleName="row__input">
                    <Input.Text
                      name="emailTemplateSubject"
                      placeholder="emailTemplatesPlaceholder.emailTemplateSubject"
                      onChange={this.onFilterChange}
                      fireAllChanges={true}
                      data-cy="CY-emailTemplateSubject"
                      value={this.state.emailTemplateFilterValue}
                    />
                  </div>
                </div>
              </Form>
            </div>

            <Results
              results={emailTemplates}
              searchString={this.state.emailTemplateFilterValue}
              onClick={this.props.onAdd}
              navigate={this.props.navigate}
            />
          </div>
          <div
            styleName="merge-fields__pointer"
            style={this.state.emailTemplatePointerStyle}
          />
        </div>
      </ModalPortal>
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!!this.props.visible && !prevProps.visible) {
      this.setState({
        emailTemplateCategoryValue: "",
        emailTemplateFilterValue: "",
        ...this.calculateMergeFieldStyles(),
      });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onCategoryChange(emailTemplateCategoryValue: string) {
    this.setState({ emailTemplateCategoryValue });
  }

  private onFilterChange(emailTemplateFilterValue: string) {
    this.setState({ emailTemplateFilterValue });
  }

  private onClickOutsideHandler(event: any) {
    if (!this.props.visible || !this.ref || !this.props.parent) return;

    let clickedInside =
      this.ref.contains(event.target) ||
      this.props.parent.contains(event.target);

    this.outerRefs.forEach((ref) => {
      if (!clickedInside && ref.contains(event.target)) {
        clickedInside = true;
      }
    });

    if (!clickedInside) {
      this.props.onHide();
    }
  }

  private calculateMergeFieldStyles(): {
    emailTemplateStyle: React.CSSProperties;
    emailTemplatePointerStyle: React.CSSProperties;
  } {
    const windowWidth = window.innerWidth;
    const { width: refWidth } = this.ref.getBoundingClientRect();
    const {
      top: parentTop,
      left: parentLeft,
      width: parentWidth,
      height: parentHeight,
    } = this.props.parent.getBoundingClientRect();
    const offsetWidth = 4;

    let emailTemplateStyle: React.CSSProperties = {
      left: parentLeft + parentWidth + offsetWidth,
      top: parentTop,
    };
    let emailTemplatePointerStyle: React.CSSProperties = {
      left: -5,
      top: parentHeight / 2 - 5,
    };

    if (parentLeft + parentWidth + offsetWidth + refWidth > windowWidth - 16) {
      emailTemplateStyle = {
        ...emailTemplateStyle,
        left: parentLeft - refWidth - offsetWidth,
      };
      emailTemplatePointerStyle = {
        ...emailTemplatePointerStyle,
        left: refWidth - 5,
      };
    }

    return {
      emailTemplateStyle,
      emailTemplatePointerStyle,
    };
  }

  private bindOuterRefs(ref: MutableRefObject<HTMLDivElement>) {
    if (!ref || !ref.current) return;
    const outerRef = this.outerRefs.find((el) => el === ref.current);
    if (!!outerRef) return;
    this.outerRefs = [...this.outerRefs, ref.current];
  }
}
