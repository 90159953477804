import { StatisticsSingleItemResponse } from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./statistics.types";

export interface StatisticsState {
  companyStatistics: StatisticsSingleItemResponse;
  employeeStatistics: StatisticsSingleItemResponse;
  companyStatisticsStatus: string;
  employeeStatisticsStatus: string;
}

const INITIAL_STATE: StatisticsState = {
  companyStatistics: null,
  employeeStatistics: null,
  companyStatisticsStatus: REQUEST.IDLE,
  employeeStatisticsStatus: REQUEST.IDLE,
};

export const statisticsReducer = (
  state: StatisticsState = INITIAL_STATE,
  action: Action
): StatisticsState => {
  switch (action.type) {
    case REDUX.STATISTICS.SET_COMPANY_STATISTICS: {
      const { statistics } = <ActionType.Statistics>action;
      return {
        ...state,
        companyStatistics: statistics,
        companyStatisticsStatus: REQUEST.SUCCESS,
      };
    }
    case REDUX.STATISTICS.SET_EMPLOYEE_STATISTICS: {
      const { statistics } = <ActionType.Statistics>action;
      return {
        ...state,
        employeeStatistics: statistics,
        employeeStatisticsStatus: REQUEST.SUCCESS,
      };
    }
    case REDUX.STATISTICS.SET_COMPANY_STATISTICS_STATUS: {
      const { statisticsStatus } = <ActionType.StatisticsStatus>action;
      return { ...state, companyStatisticsStatus: statisticsStatus };
    }
    case REDUX.STATISTICS.SET_EMPLOYEE_STATISTICS_STATUS: {
      const { statisticsStatus } = <ActionType.StatisticsStatus>action;
      return { ...state, employeeStatisticsStatus: statisticsStatus };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
