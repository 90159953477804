import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { ProjectUtil, RouteUtil } from "@haywork/util";
import { ProjectSaveError } from "@haywork/util/project";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./save-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface ProjectSaveModalComponentProps {
  visible: boolean;
  projectAssignment: ProjectAssignment;
  onClose: () => void;
}

interface ProjectSaveModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ProjectSaveModalComponent extends React.Component<
  ProjectSaveModalComponentProps,
  ProjectSaveModalComponentState
> {
  constructor(props) {
    super(props);

    this.mapError = this.mapError.bind(this);
  }

  public render() {
    const errors = ProjectUtil.Validation.getErrors(
      this.props.projectAssignment
    );

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="saveAssignmentMissingFields" close />
        <ModalBody>
          <ResourceText
            resourceKey="saveAssignmentMissingFieldsBody"
            values={{ count: errors.length }}
          />
          {errors.map(this.mapError)}
        </ModalBody>
        <ModalFooter>
          <div id="cancelError" className="btn" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private mapError(
    error: ProjectSaveError,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { id } = this.props.projectAssignment;
    let link;

    switch (error) {
      case ProjectSaveError.NoLinkedOffice:
      case ProjectSaveError.NoLinkedEmployee:
      case ProjectSaveError.NoListingType:
      case ProjectSaveError.NoRealEstateGroup:
      case ProjectSaveError.NoTypeOf:
      case ProjectSaveError.NoLeisureType:
        link = route(PROJECTROUTES.EDIT_CLIENT.URI, { id });
        break;
      case ProjectSaveError.IncompleteAddress:
        link = route(PROJECTROUTES.EDIT_ADDRESS.URI, { id });
        break;
      default:
        link = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
    }

    return (
      <div styleName="error" onClick={this.props.onClose} key={idx}>
        <NavLink to={link}>
          <ResourceText resourceKey={error.toString()} />
        </NavLink>
      </div>
    );
  }
}
