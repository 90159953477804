import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./calender.component.scss");

interface CalenderComponentProps {
  expanded: boolean;
  date: Date;
  inputPosition: ClientRect;
  onDateSelect: (date: Date) => void;
  onClose: () => void;
}
interface CalenderComponentState {
  currentDate: Date;
  selectedDate: Date;
  showMonths: boolean;
  showYears: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class Calender extends React.Component<
  CalenderComponentProps,
  CalenderComponentState
> {
  private ref: HTMLDivElement;
  private windowHeight: number;
  private topInPx: number;
  private renderAbove: boolean = false;

  constructor(props) {
    super(props);
    this.state = {
      currentDate: this.props.date,
      selectedDate: this.props.date,
      showMonths: false,
      showYears: false,
    };

    this.onDayClickHandler = this.onDayClickHandler.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.onPreviousClickHandler = this.onPreviousClickHandler.bind(this);
    this.onTodayClickHandler = this.onTodayClickHandler.bind(this);
    this.onPeriodClickHandler = this.onPeriodClickHandler.bind(this);
    this.onMonthClickHandler = this.onMonthClickHandler.bind(this);
    this.onYearClickHandler = this.onYearClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const calenderStyle = classNames("calender", {
      expanded: this.props.expanded,
    });
    const detailViewStyle = classNames("detail-view", {
      "show-months": this.state.showMonths,
    });
    const calenderStyles = this.renderCalenderStyles();

    return (
      <div styleName={calenderStyle} style={calenderStyles} ref={this.bindRef}>
        {this.renderMonths()}
        {this.renderYears()}
        <div styleName={detailViewStyle}>
          <div styleName="header">
            <div
              styleName="period"
              onClick={this.onPeriodClickHandler}
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.PeriodSelectButton`
              }
            >
              <div styleName="month">
                <FormattedDate
                  value={this.state.currentDate || new Date()}
                  month="long"
                />
              </div>
              <div styleName="year">
                {(this.state.currentDate || new Date()).getFullYear()}
              </div>
              <div styleName="toggle">
                <i className="far fa-fw fa-chevron-down faIcon" />
              </div>
            </div>
            <div styleName="navigation">
              <div
                styleName="previous"
                onClick={this.onPreviousClickHandler}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.PreviousButton`
                }
              >
                <i className="far fa-fw fa-chevron-left faIcon" />
              </div>
              <div styleName="today">
                <i
                  className="far fa-fw fa-calendar-day faIcon"
                  onClick={this.onTodayClickHandler}
                  data-cy={
                    this.props["data-cy"] &&
                    `${this.props["data-cy"]}.TodayButton`
                  }
                />
              </div>
              <div
                styleName="next"
                onClick={this.onNextClickHandler}
                data-cy={
                  this.props["data-cy"] && `${this.props["data-cy"]}.NextButton`
                }
              >
                <i className="far fa-fw fa-chevron-right faIcon" />
              </div>
            </div>
          </div>
          <div styleName="weekdays">
            <div styleName="day">
              <ResourceText resourceKey="mondayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="tuesdayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="wednesdayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="thursdayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="fridayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="saturdayAbbr" />
            </div>
            <div styleName="day">
              <ResourceText resourceKey="sundayAbbr" />
            </div>
          </div>
          <div styleName="month-view">{this.renderMonth()}</div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: CalenderComponentProps) {
    if (!!nextProps.date && nextProps.date !== this.state.currentDate)
      this.setState({
        currentDate: nextProps.date,
        selectedDate: nextProps.date,
      });
    if (!!nextProps.expanded) {
      this.addWindowEvents();
    } else {
      this.removeWindowEvents();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    if (!!this.ref && !this.ref.contains(event.target) && !!this.props.expanded)
      this.props.onClose();
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
    }
  }

  private renderCalenderStyles(): React.CSSProperties {
    if (!this.props.inputPosition || !this.ref || !this.props.expanded)
      return null;

    const {
      top: refTop,
      left: refLeft,
      right: refRight,
      bottom: refBottom,
    } = this.props.inputPosition;

    const { width, height } = this.ref.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const sameWindowHeight = this.windowHeight === window.innerHeight;

    if (!sameWindowHeight) {
      this.windowHeight = window.innerHeight;
      this.topInPx = refTop - height;
      this.renderAbove = false;
    }

    const roomLeft = refRight;
    const roomBottom = windowHeight - refBottom;

    let top: number;

    if (this.renderAbove) {
      top = this.topInPx;
    } else {
      if (roomBottom - height > 80) {
        top = refBottom;
      } else {
        top = this.topInPx;
        this.renderAbove = true;
      }
    }

    return {
      left: roomLeft > width ? refRight - width : refLeft,
      top,
    };
  }

  private renderMonths(): React.ReactElement<HTMLDivElement> {
    const year = (this.state.currentDate || new Date()).getFullYear();
    const monthsViewStyle = classNames("months-view", {
      "show-years": this.state.showYears,
    });
    const months = [];

    for (let i = 0; i < 12; i++) {
      const date = new Date(year, i, 1, 12);
      months.push(date);
    }

    return (
      <div styleName={monthsViewStyle}>
        <div
          styleName="year"
          onClick={() => this.setState({ showYears: true })}
          data-cy={
            this.props["data-cy"] && this.props["data-cy"] + "YearSelectButton"
          }
        >
          {year}
          <i
            className="far fa-fw fa-chevron-down faIcon"
            style={{ marginLeft: 3 }}
          />
        </div>
        {months.map((date, idx) => (
          <div
            styleName="month"
            key={idx}
            onClick={() => this.onMonthClickHandler(date)}
            data-cy={
              this.props["data-cy"] && this.props["data-cy"] + "MonthButton"
            }
          >
            <FormattedDate value={date} month="long" />
          </div>
        ))}
      </div>
    );
  }

  private renderYears(): React.ReactElement<HTMLDivElement> {
    const date = this.state.currentDate || new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const prevYear = new Date(year - 10, month, 1, 12);
    const nextYear = new Date(year + 10, month, 1, 12);
    const years = [];

    for (let i = year - 5; i < year + 5; i++) {
      years.push(new Date(i, month, 1, 12));
    }

    return (
      <div styleName="years-view">
        <div
          styleName="year"
          onClick={() => this.setState({ currentDate: prevYear })}
          data-cy={
            this.props["data-cy"] &&
            this.props["data-cy"] + "YearPreviousButton"
          }
        >
          <i className="far fa-fw fa-chevron-left faIcon" />
        </div>
        {years.map((year, idx) => (
          <div
            styleName="year"
            key={idx}
            onClick={() => this.onYearClickHandler(year)}
            data-cy={
              this.props["data-cy"] && this.props["data-cy"] + "YearButton"
            }
          >
            <FormattedDate value={year} year="numeric" />
          </div>
        ))}
        <div
          styleName="year"
          onClick={() => this.setState({ currentDate: nextYear })}
          data-cy={
            this.props["data-cy"] && this.props["data-cy"] + "YearNextButton"
          }
        >
          <i className="far fa-fw fa-chevron-right faIcon" />
        </div>
      </div>
    );
  }

  private renderMonth() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();

    const selectedDate = this.state.selectedDate || new Date();
    const currentDate = this.state.currentDate || new Date();
    const startDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const count = endDay.getDate();
    const start = startDay.getDate();
    const offset = [1, 2, 3, 4, 5, 6, 0].indexOf(startDay.getDay());
    const total = offset + count;
    const isCurrentPeriod =
      month === currentDate.getMonth() && year === currentDate.getFullYear();
    const isSelectedPeriod =
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear();
    const selectedDay = selectedDate.getDate();

    const weeks = [];
    let dayKey = 0;

    for (let i = 0; i < total; i++) {
      if (weeks.length === 0 || i % 7 === 0) weeks.push({ days: [] });
      if (i >= offset) dayKey++;
      const week = weeks[weeks.length - 1];
      week.days.push({
        current: isCurrentPeriod && dayKey === day,
        active: isSelectedPeriod && dayKey === selectedDay,
        day: i < offset ? null : dayKey,
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
      });
    }

    return weeks.map((week, idx) => this.renderWeekRows(week, idx));
  }

  private renderWeekRows(
    week: { days: any[] },
    key: number
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="week-row" key={key}>
        {week.days.map((day, idx) => this.renderWeekDays(day, idx))}
      </div>
    );
  }

  private renderWeekDays(
    date: any,
    key: number
  ): React.ReactElement<HTMLDivElement> {
    const dayStyle = classNames("day", {
      active: date.active,
      current: date.current,
    });

    return (
      <div
        styleName={dayStyle}
        key={key}
        onClick={() => this.onDayClickHandler(date)}
      >
        <div
          styleName="label"
          data-cy={this.props["data-cy"] && this.props["data-cy"] + "DayButton"}
        >
          {date.day}
        </div>
      </div>
    );
  }

  private onPeriodClickHandler() {
    this.setState({ showMonths: true });
  }

  private onDayClickHandler(date) {
    const selectedDate = new Date(date.year, date.month, date.day, 0, 0, 0);
    this.props.onDateSelect(selectedDate);
    this.setState({ selectedDate });
  }

  private onNextClickHandler() {
    const current = this.state.currentDate || new Date();
    const currentDate = new Date(
      current.getFullYear(),
      current.getMonth() + 1,
      1
    );
    this.setState({ currentDate });
  }

  private onPreviousClickHandler() {
    const current = this.state.currentDate || new Date();
    const currentDate = new Date(
      current.getFullYear(),
      current.getMonth() - 1,
      1
    );
    this.setState({ currentDate });
  }

  private onTodayClickHandler() {
    const now = new Date();
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    this.setState({ currentDate, selectedDate: currentDate });
    this.props.onDateSelect(currentDate);
  }

  private onMonthClickHandler(date: Date) {
    this.setState({ currentDate: date, showMonths: false });
  }

  private onYearClickHandler(date: Date) {
    this.setState({ currentDate: date, showMonths: true, showYears: false });
  }

  private addWindowEvents() {
    window.addEventListener("resize", this.onCloseHandler, true);
    window.addEventListener("scroll", this.onCloseHandler, true);
  }

  private removeWindowEvents() {
    window.removeEventListener("resize", this.onCloseHandler, true);
    window.removeEventListener("scroll", this.onCloseHandler, true);
  }

  private onCloseHandler() {
    this.props.onClose();
  }
}
