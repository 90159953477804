import * as React from "react";
import { FC, memo, useCallback } from "react";
import Pill from ".";
import {
  LinkedRelation,
  RelationSnapShot,
  RelationType
} from "@haywork/api/kolibri";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES
} from "@haywork/constants";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  relation: LinkedRelation | RelationSnapShot;
  color?: string;
  onClick?: (path: string) => void;
};

export const RelationPillComponent: FC<Props> = memo(
  ({ relation, color, onClick }) => {
    const { displayName, id, typeOfRelation } = relation;

    const onClickHandler = useCallback(() => {
      if (!onClick) return;

      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case RelationType.ContactPerson: {
          const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
          onClick(path);
          break;
        }
        case RelationType.Employee: {
          const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
          onClick(path);
          break;
        }
        case RelationType.Office: {
          const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
          onClick(path);
          break;
        }
        default: {
          break;
        }
      }
    }, [id, onClick, typeOfRelation]);

    return (
      <Pill
        label={displayName}
        color={color || Colors.Primary}
        icon="users"
        solid
        onClick={!onClick ? undefined : onClickHandler}
      />
    );
  }
);
