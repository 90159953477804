import * as React from "react";
import * as CSSModules from "react-css-modules";

import { KeyboardContainerProps } from "../containers";
import { KeyDetail } from "./key-detail.component";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Ui,
  UiEmptyStateType,
  UiEmptyStateStickMan,
} from "@haywork/modules/ui";
import { REQUEST, ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentSnapShot, ActiveFilter } from "@haywork/api/kolibri";
import { Route } from "@haywork/util/route";
import {
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form/components/input";

const styles = require("./keyboard.component.scss");
const route = Route.mapStaticRouteValues;

export interface KeyBoardComponentProps {}
interface State {}
type Props = KeyBoardComponentProps & KeyboardContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class KeyBoardComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onAssignmentClick = this.onAssignmentClick.bind(this);
    this.toggleShowActiveAndInactive = this.toggleShowActiveAndInactive.bind(
      this
    );
  }

  public componentDidMount() {
    this.props.getAssignmentsWithKey(this.props.showAllKeyboardAssignments);
  }

  public render() {
    const showAllKeyboardAssignments =
      this.props.showAllKeyboardAssignments === ActiveFilter.ActiveOrInactive
        ? true
        : false;

    return (
      <div styleName="keyboard">
        <div styleName="keyboard__wrapper">
          {this.props.status !== REQUEST.PENDING && (
            <div styleName="inner">
              <div styleName="toggle">
                <Input.Switch
                  name={"toggleMyAssignmentsOnly"}
                  label="keyboard.showActiveAndInactive"
                  labelPosition={SwitchLabelPosition.Pre}
                  value={showAllKeyboardAssignments}
                  on={true}
                  off={false}
                  onChange={this.toggleShowActiveAndInactive}
                />
              </div>
              <div styleName="break"></div>
              {this.props.assignments.map((assignment) => (
                <ErrorBoundary key={assignment.id}>
                  <KeyDetail
                    assignment={assignment}
                    onClick={this.onAssignmentClick}
                  />
                </ErrorBoundary>
              ))}
            </div>
          )}
          {this.props.status === REQUEST.PENDING && <Ui.Loaders.List />}
          {this.props.status === REQUEST.SUCCESS &&
            this.props.assignments.length === 0 && (
              <Ui.EmptyState
                type={UiEmptyStateType.List}
                stickman={UiEmptyStateStickMan.NoAssignments}
                title="emptyStateNoKeyBoardOverviewTitle"
                subtitle="emptyStateNoKeyBoardOverviewText"
              />
            )}
        </div>
      </div>
    );
  }

  private onAssignmentClick(assignment: AssignmentSnapShot) {
    const { id } = assignment;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.navigate(path);
  }

  private toggleShowActiveAndInactive(showArchived: boolean) {
    const activeFilter = showArchived
      ? ActiveFilter.ActiveOrInactive
      : ActiveFilter.ActiveOnly;
    this.props.getAssignmentsWithKey(activeFilter);
  }
}
