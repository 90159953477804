import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryStatistics,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./delete-modal.component.scss");

export interface SettingsSchedulerCategoryDeleteModalComponentProps {
  visible: boolean;
  agendaItemCategoryStatistics: AgendaItemCategoryStatistics;
  agendaItemCategorySnapShot: AgendaItemCategorySnapShot;
  agendaitemCategories: AgendaItemCategorySnapShot[];

  onClose: () => void;
  onDelete: (
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo: AgendaItemCategorySnapShot
  ) => void;
}

interface SettingsSchedulerCategoryDeleteModalComponentState {
  selectedCategory: AgendaItemCategorySnapShot;
  totalCount: number;
}

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerCategoryDeleteModalComponent extends React.Component<
  SettingsSchedulerCategoryDeleteModalComponentProps,
  SettingsSchedulerCategoryDeleteModalComponentState
> {
  private ref: HTMLDivElement;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.state = {
      totalCount: 0,
      selectedCategory: null,
    };

    this.formControls = {
      selectedCategory: {
        value: this.state.selectedCategory ? this.state.selectedCategory : "",
      },
    };

    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  public render() {
    const categoryName = this.props.agendaItemCategorySnapShot
      ? this.props.agendaItemCategorySnapShot.displayName
      : "";
    const deleteButtonKey =
      this.state.totalCount > 0 ? "deleteAndMoveCategory" : "deleteCategory";

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="deleteAgendaCategory" close />
        <ModalBody>
          <Form
            name={"categoryDeleteForm"}
            onChange={this.onFormChangeHandler}
            formControls={this.formControls}
          >
            {this.state.totalCount === 0 ? (
              <p>
                <ResourceText
                  resourceKey="areYouSureYouWantToDeleteAgendaCategory"
                  values={{ categoryName }}
                  asHtml
                />
              </p>
            ) : (
              <div>
                <p>
                  <ResourceText
                    resourceKey="deleteCategoryWithItems"
                    values={{ categoryName }}
                    asHtml
                  />
                </p>
                <label>
                  <ResourceText resourceKey="moveCategoriesTo" />
                </label>
                <Input.NewSelect
                  name="selectedCategory"
                  values={this.props.agendaitemCategories}
                  displayProp="displayName"
                />
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-danger" onClick={this.onDelete}>
            <ResourceText resourceKey={deleteButtonKey} />
          </div>
          <div className="btn btn-default" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SettingsSchedulerCategoryDeleteModalComponentProps
  ) {
    if (
      nextProps.agendaItemCategorySnapShot &&
      (!this.props.agendaItemCategorySnapShot ||
        nextProps.agendaItemCategorySnapShot.id !==
          this.props.agendaItemCategorySnapShot.id)
    ) {
      const count = nextProps.agendaItemCategoryStatistics.counts.find(
        (count) =>
          count.agendaItemCategoryId === nextProps.agendaItemCategorySnapShot.id
      );
      if (count) {
        this.setState({ totalCount: count.totalCount });
      }
    }
  }

  private onDelete() {
    this.props.onDelete(
      this.props.agendaItemCategorySnapShot,
      this.state.selectedCategory
    );
  }

  private onFormChangeHandler(values: FormReturnValue) {
    this.setState({ selectedCategory: values.selectedCategory });
  }
}
