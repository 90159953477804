import { REDUX } from "@haywork/constants";
import * as ActionType from "./shares.types";
import { Status } from "@haywork/stores/helpers";

const setShares = (payload: ActionType.SharesPayload) => ({
  type: REDUX.EMAILV2.SET_SHARES,
  ...payload
});

const setSharesStatus = (payload: Status) => ({
  type: REDUX.EMAILV2.SET_SHARES_STATUS,
  ...payload
});

const clearShares = () => ({
  type: REDUX.EMAILV2.CLEAR_SHARES
});

const setShareVisibility = (payload: ActionType.ShareVisibility) => ({
  type: REDUX.EMAILV2.SET_SHARE_VISIBILITY,
  ...payload
});

const removeSingleShare = (payload: ActionType.Share) => ({
  type: REDUX.EMAILV2.REMOVE_SINGLE_SHARE,
  ...payload
});

const appendSingleShare = (payload: ActionType.Share) => ({
  type: REDUX.EMAILV2.ADD_SINGLE_SHARE,
  ...payload
});

export const SharesActions = {
  setShares,
  setSharesStatus,
  clearShares,
  setShareVisibility,
  removeSingleShare,
  appendSingleShare
};
