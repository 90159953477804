import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  EmailAddressType,
  Employee,
  Gender,
  PhoneNumberType,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
  AvatarUploadTriggerComponent,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { OnboardingModalContainerProps } from "@haywork/modules/onboarding";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./onboarding-modal.component.scss");

export interface OnboardingModalComponentProps {}
interface OnboardingModalComponentState {
  step: number;
  employee: Employee;
  uploading: boolean;
  hasUploadError: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class OnboardingModalComponent extends React.Component<
  OnboardingModalComponentProps & OnboardingModalContainerProps,
  OnboardingModalComponentState
> {
  private step1FormControls: FormControls;
  private step2FormControls: FormControls;
  private step1Form: FormReference;
  private step2Form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      employee: this.props.employee,
      uploading: false,
      hasUploadError: false,
    };

    // Form state
    const {
      firstName,
      middleName,
      lastName,
      gender,
      emailAddresses,
      dateOfBirth,
      phoneNumbers,
      passportPhotoBlob,
    } = this.props.employee;
    this.step1FormControls = {
      firstName: { value: firstName, validators: [Validators.required()] },
      middleName: { value: middleName },
      lastName: { value: lastName, validators: [Validators.required()] },
      gender: { value: gender },
      emailAddresses: { value: emailAddresses },
    };
    this.step2FormControls = {
      dateOfBirth: {
        value: dateOfBirth,
        validators: [Validators.dateShouldBeBefore(new Date())],
      },
      phoneNumbers: { value: phoneNumbers },
      passportPhotoBlob: { value: passportPhotoBlob },
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onBackClickHandler = this.onBackClickHandler.bind(this);
    this.onUploadCompleteHandler = this.onUploadCompleteHandler.bind(this);
    this.onUploadStartedHandler = this.onUploadStartedHandler.bind(this);
  }

  public render() {
    const step1Style = classNames("onboarding step", {
      visible: this.state.step === 0,
    });
    const step2Style = classNames("onboarding step", {
      visible: this.state.step === 1,
    });
    const { employee } = this.props;

    const emailFormControls: FormControls = {
      address: {
        value: "",
        validators: [Validators.required(), Validators.email()],
      },
      type: { value: EmailAddressType.Work },
    };
    const phonenumberFormControls: FormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Mobile },
    };

    return (
      <Modal visible={this.props.showOnboarding} className="onboardingModal">
        <ModalBody>
          <div styleName={step1Style}>
            <div styleName="onboardingImage" />
            <div styleName="intro">
              <h1>
                <ResourceText resourceKey="onboardingTitle" />
              </h1>
              <p>
                <ResourceText resourceKey="complementUserDataPrimaryFormDescription" />
              </p>
            </div>
            <Form
              name="onboarding-step1"
              formControls={this.step1FormControls}
              onSubmit={this.onSubmitHandler}
              form={(formRef) => (this.step1Form = formRef)}
            >
              <div className="form__row">
                <div className="form__group stretch">
                  <div className="column">
                    <label htmlFor="firstName">
                      <ResourceText resourceKey="firstName" />
                    </label>
                    <Input.Text
                      name="firstName"
                      placeholder="firstName"
                      maxLength={100}
                    />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="middleName">
                      <ResourceText resourceKey="middleName" />
                    </label>
                    <Input.Text
                      name="middleName"
                      placeholder="middleName"
                      maxLength={50}
                    />
                  </div>
                </div>
              </div>
              <div className="form__row">
                <div className="form__group stretch">
                  <div className="column">
                    <label htmlFor="lastName">
                      <ResourceText resourceKey="lastName" />
                    </label>
                    <Input.Text
                      name="lastName"
                      placeholder="lastName"
                      maxLength={150}
                    />
                  </div>
                </div>
              </div>
              <div className="form__row">
                <div className="column">
                  <label htmlFor="gender">
                    <ResourceText resourceKey="gender" />
                  </label>
                  <Input.RadioGroup
                    name="gender"
                    asButtonList
                    className="btn-block"
                  >
                    <Input.Radio label="genderMale" value={Gender.Male} />
                    <Input.Radio label="genderFemale" value={Gender.Female} />
                    <Input.Radio label="genderOther" value={Gender.Other} />
                  </Input.RadioGroup>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="emailAddresses">
                  <ResourceText resourceKey="emailAddresses" />
                </label>
                <Input.Array
                  name="emailAddresses"
                  formControls={emailFormControls}
                  max={4}
                >
                  <div className="form__group stretch">
                    <div className="column" styleName="email__field">
                      <Input.Email name="address" placeholder="emailAddress" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="type__field">
                      <Input.NewSelect
                        name="type"
                        values={this.props.emailAddressTypes}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                  </div>
                </Input.Array>
              </div>
            </Form>
          </div>

          <div styleName={step2Style}>
            <div styleName="onboardingImage" />
            <div styleName="intro">
              <h1>
                <ResourceText resourceKey="complementUserDataSecondaryFormTitle" />
              </h1>
            </div>
            <Form
              name="onboarding-step2"
              formControls={this.step2FormControls}
              onSubmit={this.onSubmitHandler}
              form={(formRef) => (this.step2Form = formRef)}
            >
              <div className="form__row">
                <div styleName="profile-picture">
                  <Input.Avatar
                    name="passportPhotoBlob"
                    onUploadComplete={this.onUploadCompleteHandler}
                    onUploadStarted={this.onUploadStartedHandler}
                  >
                    <AvatarUploadTriggerComponent />
                  </Input.Avatar>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="dateOfBirth">
                  <ResourceText resourceKey="dateOfBirth" />
                </label>
                <Input.Datepicker
                  placeholder="dateOfBirth"
                  name="dateOfBirth"
                />
              </div>

              <div className="form__row">
                <label htmlFor="phoneNumbers">
                  <ResourceText resourceKey="phoneNumbers" />
                </label>
                <Input.Array
                  name="phoneNumbers"
                  formControls={phonenumberFormControls}
                  max={4}
                >
                  <div className="form__group stretch">
                    <div className="column" styleName="phone__field">
                      <Input.Phone name="number" placeholder="phoneNumber" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="type__field">
                      <Input.NewSelect
                        name="type"
                        values={this.props.phoneNumberTypes}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                  </div>
                </Input.Array>
              </div>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter style="flex">
          {this.state.step === 0 && (
            <button
              className="btn btn-success"
              type="button"
              onClick={() => this.step1Form.submit()}
            >
              <ResourceText resourceKey="next" />
            </button>
          )}

          {this.state.step === 1 && (
            <button
              className="btn btn-danger"
              type="button"
              disabled={this.state.uploading}
              onClick={this.onBackClickHandler}
            >
              <ResourceText resourceKey="back" />
            </button>
          )}
          {this.state.step === 1 && (
            <button
              className="btn btn-success"
              type="button"
              disabled={this.state.uploading}
              onClick={() => this.step2Form.submit()}
            >
              <ResourceText resourceKey="complementUserDataSecondaryFormSubmit" />
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  private onUploadCompleteHandler(success: boolean) {
    this.setState({ uploading: false });
  }

  private onUploadStartedHandler() {
    this.setState({ uploading: true });
  }

  private onSubmitHandler(values: FormReturnValue) {
    const { step, employee } = this.state;
    const updatedEmployee = { ...employee, ...values };

    this.setState({ employee: updatedEmployee, step: 1 });

    if (step === 0) return;
    this.props.saveEmployee(updatedEmployee);
    this.props.setSeenContactForm();
  }

  private onBackClickHandler() {
    this.setState({ step: 0 });
  }
}
