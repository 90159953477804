import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import Pill from "@haywork/components/ui/pill";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  linkedEntity: LinkedRelation;
  onNavigate: (path: string) => void;
};

export const LinkedRelationComponent: FC<Props> = memo(
  ({ linkedEntity, onNavigate }) => {
    const { displayName, id, typeOfRelation } = linkedEntity;

    const icon = useMemo(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany:
        case RelationType.Office: {
          return "building";
        }
        default: {
          return "user";
        }
      }
    }, [typeOfRelation]);

    const color = useMemo(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany:
        case RelationType.Office: {
          return Colors.Secondary;
        }
        default: {
          return Colors.Primary;
        }
      }
    }, [typeOfRelation]);

    const navigateToRelation = useCallback(() => {
      let path: string;

      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
          break;
        }
        case RelationType.ContactPerson: {
          path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
          break;
        }
        case RelationType.Employee: {
          path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
          break;
        }
        case RelationType.Office: {
          path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
          break;
        }
        default:
          break;
      }

      if (!!path) onNavigate(path);
    }, [onNavigate, id, typeOfRelation]);

    return (
      <Pill
        label={displayName}
        icon={icon}
        color={color}
        onClick={navigateToRelation}
      />
    );
  }
);

export default LinkedRelationComponent;
