import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import {
  DynamicDocumentsThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  DynamicDocumentsSignaturesComponent,
  DynamicDocumentsSignaturesComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState } from "@haywork/stores";
import { DynamicDocumentsSingleState } from "@haywork/stores/dynamic-documents/single";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  componentState: DynamicDocumentsSingleState;
  session: DocumentSession;
  template: DocumentTemplate;
}
interface DispatchProps {
  updateDocumentSession: (session: DocumentSession) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsSignaturesComponentProps,
  AppState
> = (state) => {
  const componentState: DynamicDocumentsSingleState =
    state.editable.currentComponentState;
  const { session, template } = componentState;

  return {
    componentState,
    session,
    template
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsSignaturesComponentProps
> = (dispatch) => ({
  updateDocumentSession: (session: DocumentSession) =>
    dispatch(DynamicDocumentsThunks.updateDocumentSession(session, true))
});

export type DynamicDocumentsSignaturesContainerProps = StateProps &
  DispatchProps;
export const DynamicDocumentsSignaturesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsSignaturesComponent);
