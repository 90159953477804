import * as React from "react";
import { FC, memo, useMemo, useRef, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { RelationsListContainerProps } from "./list.container";
import List, {
  ListWrapper,
  ListColumnConfig,
  ListRefProps,
  ActiveFilters,
  FilterConfig
} from "@haywork/components/ui/list";
import {
  SortOrder,
  RelationSnapShot,
  RelationOrderByField,
  RelationType
} from "@haywork/api/kolibri";
import Filters from "./components/filters";
import Row from "./components/row";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { RelationListAction } from "./components/row-actions/row-actions";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES
} from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type RelationsListComponentProps = {};
type Props = RelationsListComponentProps & RelationsListContainerProps;

export const RelationsListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      navigate,
      totalCount,
      getListItems,
      filters,
      activeFilterGuidValues,
      setFilters,
      setOrdering,
      order,
      scrollOffset,
      setScrollOffset,
      relations,
      create,
      createNewEmail,
      exportListToExcel,
      unArchive,
      archive,
      updateListItem
    }) => {
      const listRef = useRef<ListRefProps>();

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: "",
              width: 80
            },
            {
              label: {
                value: "name"
              },
              width: 300,
              autoFill: true,
              sortConfig: [RelationOrderByField.DisplayName]
            },
            {
              label: {
                value: "email"
              },
              width: 240
            },
            {
              label: {
                value: "phone"
              },
              width: 240
            },
            {
              label: {
                value: "address"
              },
              width: 200
            }
          ],
          withActions: true
        } as ListColumnConfig;
      }, []);

      const onNavigatePath = useCallback((path: string) => {
        navigate(path);
      }, []);

      const onSortChange = useCallback(
        (sortColumn: RelationOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const onAction = useCallback(
        (item: RelationSnapShot, action: RelationListAction) => {
          const { typeOfRelation, id } = item;
          switch (action) {
            case RelationListAction.Edit: {
              switch (typeOfRelation) {
                case RelationType.ContactCompany: {
                  const path = route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, {
                    id
                  });
                  navigate(path);
                  break;
                }
                case RelationType.ContactPerson: {
                  const path = route(RELATIONROUTES.CONTACT_PERSON_EDIT.URI, {
                    id
                  });
                  navigate(path);
                  break;
                }
                case RelationType.Employee: {
                  const path = route(EMPLOYEEROUTES.EMPLOYEE_EDIT.URI, { id });
                  navigate(path);
                  break;
                }
                case RelationType.Office: {
                  const path = route(OFFICESROUTES.EDIT_OFFICE.URI, { id });
                  navigate(path);
                  break;
                }
                default: {
                  break;
                }
              }
              break;
            }
            case RelationListAction.Archive: {
              updateListItem({ ...item, isActive: false });
              archive(item);
              break;
            }
            case RelationListAction.UnArchive: {
              updateListItem({ ...item, isActive: true });
              unArchive(item);
              break;
            }
            default: {
              break;
            }
          }
        },
        [navigate, unArchive, archive, updateListItem]
      );

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="relations"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
            actions={
              <Button
                label="relations.list.export"
                category="export"
                icon={<Icon name="file-excel" light color={Colors.White} />}
                onClick={exportListToExcel}
              />
            }
          >
            <List
              name="relations"
              rowHeight={100}
              columnConfig={columnConfig}
              data={relations}
              totalCount={totalCount}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              ref={listRef}
              onAction={onAction}
              emptyState={
                <EmptyState
                  icon="user"
                  title="relations.list.emptyStateTitle"
                  subTitle="relations.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="relations.list.emptyState.action"
                      category="primary"
                      onClick={() => create()}
                    />
                  }
                />
              }
            >
              {(data: RelationSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigatePath={onNavigatePath}
                  onEmail={createNewEmail}
                />
              )}
            </List>
          </ListWrapper>
        </div>
      );
    }
  )
);
