import { ResourceText } from "@haywork/modules/shared";
import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetCollector,
  DropTargetSpec,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { v4 as uuid } from "uuid";
import { FileUploadModalComponent } from "./file-upload-modal.component";
import { FileUploadContainerProps } from "./file-upload.container";

const styles = require("./file-upload.component.scss");

export enum FileUploadIcon {
  User = "User",
  Upload = "Upload",
}

export enum FileUploadType {
  Default = "Default",
  Avatar = "Avatar",
}

export interface Upload {
  file: File;
  uploaded: number;
  completed: boolean;
  id: string;
  error?: any;
  response: any;
}
export interface FileUploadComponentProps {
  multiple?: boolean;
  whitelist?: string[];
  shouldBePrivate?: boolean;
  icon: FileUploadIcon;
  title: string;
  subtitle?: string;
  helperText?: string;
  size?: FileUploadType;
  clearOutline?: boolean;
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  passedFiles?: File[];
  onCompleted: (files: any[]) => void;
}
interface State {
  modalVisible: boolean;
  uploadPercentage: number;
  fileSize: number;
  loadedSize: number;
  files: Upload[];
  cancelTokenSource: CancelTokenSource;
  completed: boolean;
  errored: Upload[];
}
type Props = FileUploadComponentProps & FileUploadContainerProps;

@CSSModules(styles, { allowMultiple: true })
class FileUploadInstance extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      uploadPercentage: 0,
      fileSize: 0,
      loadedSize: 0,
      files: [],
      cancelTokenSource: null,
      completed: false,
      errored: [],
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.handleSelectedFileList = this.handleSelectedFileList.bind(this);
    this.onDropHandler = debounce(this.onDropHandler.bind(this), 500);
    this.onInputClickHandler = this.onInputClickHandler.bind(this);
    this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    this.onModalCloseHandler = this.onModalCloseHandler.bind(this);
    this.checkCompletedState = this.checkCompletedState.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.props.passedFiles &&
      !deepEqual(prevProps.passedFiles, this.props.passedFiles)
    ) {
      this.onDropHandler(this.props.passedFiles);
    }
  }

  public render() {
    const fileUploadStyle = classNames(
      "file-upload",
      { hover: this.props.isOver, "no-outline": this.props.clearOutline },
      this.props.size ? this.props.size.toString().toLowerCase() : "default"
    );

    return this.props.connectDropTarget(
      <div styleName={fileUploadStyle} data-cy={this.props["data-cy"]}>
        <div styleName="upload-trigger">
          <label htmlFor="file-upload-input" styleName="label">
            <input
              type="file"
              accept={
                this.props.whitelist ? this.props.whitelist.join(",") : ""
              }
              id="file-upload-input"
              name="file-upload-input"
              multiple={this.props.multiple}
              styleName="input"
              onClick={this.onInputClickHandler}
              onChange={this.onInputChangeHandler}
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.FileUploadInput`
              }
            />
          </label>
        </div>
        <div styleName="placeholder">
          <div styleName="inner">
            <span className={this.mapIcon()} />
            <div styleName="titles">
              <div styleName="title">
                <ResourceText resourceKey={this.props.title} />
              </div>
              {!!this.props.subtitle && (
                <div styleName="subtitle">
                  <ResourceText resourceKey={this.props.subtitle} />
                </div>
              )}
            </div>
          </div>
          {!!this.props.helperText && (
            <div styleName="helper-text">
              <ResourceText resourceKey={this.props.helperText} />
            </div>
          )}
        </div>
        <FileUploadModalComponent
          visible={this.state.modalVisible}
          uploadedPercentage={this.state.uploadPercentage}
          errored={this.state.errored}
          completed={this.state.completed}
          onClose={this.onModalCloseHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.FileUpload`
          }
        />
      </div>
    );
  }

  private onInputChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const files: File[] = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files.item(i));
    }
    this.handleSelectedFileList(files);
  }

  private onInputClickHandler(event: React.MouseEvent<HTMLInputElement>) {
    event.currentTarget.value = null;
  }

  private onModalCloseHandler(cancelUpload: boolean) {
    if (!!cancelUpload && this.state.cancelTokenSource) {
      this.state.cancelTokenSource.cancel();
    }

    this.setState({ modalVisible: false });
    setTimeout(() => {
      this.setState({
        uploadPercentage: 0,
        fileSize: 0,
        loadedSize: 0,
        files: [],
        cancelTokenSource: null,
        completed: false,
        errored: [],
      });
    }, 450);
  }

  private onDropHandler(files: File[] | null) {
    if (!files || !files.length) return;
    this.handleSelectedFileList(files);
  }

  private async handleSelectedFileList(files: File[]) {
    const processedFiles = this.processFiles(files);

    if (processedFiles.length > 0) {
      const fileSize = processedFiles.reduce((state, file) => {
        return state + file.file.size;
      }, 0);

      this.setState({
        fileSize,
        uploadPercentage: 0,
        files: processedFiles,
        cancelTokenSource: Axios.CancelToken.source(),
        completed: false,
        modalVisible: true,
        errored: [],
      });

      for (const file of processedFiles) {
        await this.uploadFile(file);
      }
    }
  }

  private processFiles(files: File[]): Upload[] {
    const { whitelist } = this.props;

    return files
      .filter((file) => !whitelist || whitelist.includes(file.type))
      .map((file) => ({
        file,
        uploaded: 0,
        completed: false,
        id: uuid(),
        response: null,
      }));
  }

  private uploadFile(file: Upload) {
    const formData = new FormData();
    const uploadUrl = `${this.props.host}/${this.props.apiVersion}/${this.props.realEstateAgencyId}/blobs/upload`;

    formData.append("isPrivate", this.props.shouldBePrivate ? "true" : "false");
    formData.append("upload", file.file);

    const config: AxiosRequestConfig = {
      onUploadProgress: (event: ProgressEvent) => {
        const files = this.state.files.map((f) => {
          if (f.id === file.id) return { ...f, uploaded: event.loaded };
          return f;
        });
        const loadedSize = files.reduce((s, f) => s + f.uploaded, 0);
        let uploadPercentage = Math.round(
          (loadedSize / this.state.fileSize) * 100
        );

        if (uploadPercentage > 100) uploadPercentage = 100;

        this.setState({
          loadedSize,
          uploadPercentage,
          files,
        });
      },
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
    };

    return Axios.post(uploadUrl, formData, config)
      .then((result) => this.handleUploadSuccess(result, file))
      .catch((error) => this.handleUploadError(error, file));
  }

  private handleUploadSuccess(result: AxiosResponse<any>, file: Upload) {
    const files = this.state.files.map((f) => {
      if (f.id === file.id)
        return { ...f, completed: true, response: result.data };
      return f;
    });
    this.setState({ files }, this.checkCompletedState);
  }

  private handleUploadError(error: any, file: Upload) {
    const errorMessage = get(error, "response.data.error", error);

    const files = this.state.files.map((f) => {
      if (f.id === file.id)
        return { ...f, error: errorMessage, completed: true };
      return f;
    });

    this.setState({ files }, this.checkCompletedState);
  }

  private checkCompletedState() {
    const unCompleted = this.state.files.filter((file) => !file.completed);
    const errored = this.state.files.filter((file) => !!file.error);

    if (unCompleted.length === 0) {
      this.setState({ completed: true, errored });
      this.props.onCompleted(
        this.state.files
          .filter((f) => !!f.response)
          .map((file) => {
            const r = file.response;
            const name = file.file.name;
            return { ...r, name };
          })
      );
    }
  }

  private mapIcon(): string {
    switch (this.props.icon) {
      case FileUploadIcon.Upload:
      default:
        return "fa fa-cloud-upload";
      case FileUploadIcon.User:
        return "fa fa-user";
    }
  }
}

const dropSpec: DropTargetSpec<FileUploadComponentProps> = {
  drop: (props, monitor, component) => {
    const { files } = monitor.getItem() as { files: File[] };
    if (!component || !(component as any).onDropHandler) return;
    (component as any).onDropHandler(files);
  },
};

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export const FileUploadComponent = DropTarget(
  [NativeTypes.FILE],
  dropSpec,
  dropCollect
)(FileUploadInstance);
