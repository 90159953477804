import { ExtensionInfo, ReportTemplate } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  WindowPresentationPrintComponent,
  WindowPresentationPrintComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  realEstateAgencyId: string;
  token: string;
  apiVersion: string;
  host: string;
  printExportOptions: ExtensionInfo[];
  printTemplates: ReportTemplate[];
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  WindowPresentationPrintComponentProps,
  AppState
> = (state) => {
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { token } = state.access;
  const {
    reportsApiVersion: apiVersion,
    reportsHost: host,
  } = state.appSettings;
  const { printExportOptions, printTemplates } = state.assignment.templates;

  return {
    realEstateAgencyId,
    token,
    apiVersion,
    host,
    printExportOptions,
    printTemplates,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  WindowPresentationPrintComponentProps
> = (dispatch) => ({});

export type WindowPresentationPrintContainerProps = StateProps & DispatchProps;
export const WindowPresentationPrintContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WindowPresentationPrintComponent);
