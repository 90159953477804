import { createContext } from "react";
import noop from "lodash-es/noop";
import { FilterConfig } from "./";

export type ListFilterContextProps = {
  filters: FilterConfig;
  onFilterChange: (value: FilterConfig) => void;
};

export const ListFilterContext = createContext<ListFilterContextProps>({
  filters: {},
  onFilterChange: noop
});
export const ListFilterContextProvider = ListFilterContext.Provider;
export const ListFilterContextConsumer = ListFilterContext.Consumer;
