import {
  Address,
  AddressDetail,
  AssignmentType,
  GeoLocation,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { Input, SwitchLabelPosition } from "@haywork/modules/form";
import { AddressRequest } from "@haywork/request";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import isString from "lodash-es/isString";
import pickBy from "lodash-es/pickBy";
import debounce from "lodash-es/debounce";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
  ReactNode,
} from "react";
import * as CSSModules from "react-css-modules";
import { AddressExtendedContainerProps } from "./address-extended.container";
import AddressDetailQuery from "./components/address-detail-query";
import Map from "./components/map";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import FundaModal, { FundaAddressFields } from "./components/funda-modal";

const styles = require("./style.scss");

type AddressState = {
  street?: AddressDetail;
  houseNumber?: number;
  houseNumberPostfix?: string;
  postalCode?: string;
  locality?: AddressDetail;
  sublocality?: AddressDetail;
  adminAreaLevel1?: AddressDetail;
  adminAreaLevel2?: AddressDetail;
  countryIso2?: string;
  geoLocation?: GeoLocation;
  constructionNumber?: string;
  hideHouseNumber?: boolean;
  nameOfBuilding?: string;
  fundaPostalCode?: string;
  fundaStreet?: string;
  fundaLocality?: string;
};
type SuggestionsState = {
  streets: AddressDetail[];
  localities: AddressDetail[];
  subLocalities: AddressDetail[];
  adminAreasLevel1: AddressDetail[];
  adminAreasLevel2: AddressDetail[];
  countries: AddressDetail[];
};
type AddressAction = { type: string; payload: any };
const addressReducer = (
  state: AddressState,
  action: AddressAction
): AddressState => {
  switch (action.type) {
    case "update": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
const suggestionsReducer = (
  state: SuggestionsState,
  action: AddressAction
): SuggestionsState => {
  switch (action.type) {
    case "update": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export type AddressExtendedResponse = {
  address: Address;
  constructionNumber?: string;
  hideHouseNumber?: boolean;
  nameOfBuilding?: string;
  fundaPostalCode?: string;
  fundaStreet?: string;
  fundaLocality?: string;
};
export type AddressExtendedComponentProps = {
  address?: Address;
  typeOfAssignment: AssignmentType;
  partOfProject?: boolean;
  isCommercialObject?: boolean;
  hideHouseNumber?: boolean;
  constructionNumber?: string;
  nameOfBuilding?: string;
  fundaPostalCode?: string;
  fundaStreet?: string;
  fundaLocality?: string;
  children?: (address: Address) => ReactNode;
  onChange: (response: AddressExtendedResponse) => void;
};
type Props = AddressExtendedComponentProps & AddressExtendedContainerProps;

export const AddressExtendedComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      address,
      typeOfAssignment,
      hideHouseNumber,
      constructionNumber,
      countryIso2,
      culture,
      countries,
      nameOfBuilding,
      partOfProject,
      isCommercialObject,
      onChange,
      fundaStreet,
      fundaLocality,
      fundaPostalCode,
      children,
    }) => {
      const [state, setState] = useReducer(addressReducer, {
        street: get(address, "street"),
        houseNumber: get(address, "houseNumber"),
        houseNumberPostfix: get(address, "houseNumberPostfix"),
        postalCode: get(address, "postalCode"),
        locality: get(address, "locality"),
        sublocality: get(address, "sublocality"),
        adminAreaLevel1: get(address, "adminAreaLevel1"),
        adminAreaLevel2: get(address, "adminAreaLevel2"),
        countryIso2: get(address, "countryIso2", countryIso2),
        geoLocation: get(address, "geoLocation"),
        constructionNumber,
        hideHouseNumber,
        nameOfBuilding,
        fundaLocality,
        fundaPostalCode,
        fundaStreet,
      });
      const [suggestions, setSuggestions] = useReducer(suggestionsReducer, {
        streets: [],
        localities: [],
        subLocalities: [],
        adminAreasLevel1: [],
        adminAreasLevel2: [],
        countries: [],
      });
      const [showFundaModal, setShowFundaModal] = useState(false);

      const getInitialData = useCallback(async () => {
        const countryValues = await AddressRequest.getCountries();
        setSuggestions({
          type: "update",
          payload: { countries: countryValues },
        });
      }, [state]);

      const getAdminAreasLevel1 = useCallback(async (countryId: number) => {
        const adminAreasLevel1 = await AddressRequest.getAdminAreaOneSuggestions(
          countryId
        );
        setSuggestions({ type: "update", payload: { adminAreasLevel1 } });
      }, []);

      useEffect(() => {
        if (!state.countryIso2) {
          setSuggestions({
            type: "update",
            payload: {
              adminAreasLevel1: [],
            },
          });
          return;
        }
        const country = countries.find(
          (country) => country.iso2CodeValue === state.countryIso2
        );
        if (!country) return;
        const refCountry = (suggestions.countries || []).find(
          (ref) => ref.name === country.displayName
        );
        if (!refCountry) return;

        getAdminAreasLevel1(refCountry.id);
      }, [state.countryIso2, suggestions.countries]);

      const getAdminAreasLevel2 = useCallback(
        async (adminAreaLevel1Id: number) => {
          const adminAreasLevel2 = await AddressRequest.getAdminAreaTwoSuggestions(
            adminAreaLevel1Id
          );
          setSuggestions({ type: "update", payload: { adminAreasLevel2 } });
        },
        []
      );

      useEffect(() => {
        if (!state.adminAreaLevel1) {
          setSuggestions({
            type: "update",
            payload: {
              adminAreasLevel2: [],
            },
          });
          return;
        }
        getAdminAreasLevel2(state.adminAreaLevel1.id);
      }, [state.adminAreaLevel1]);

      const getLocalities = useCallback(
        async (adminAreaLevel1Id: number, adminAreaLevel2Id: number) => {
          const localities = await AddressRequest.getLocalitySuggestions(
            adminAreaLevel1Id,
            adminAreaLevel2Id
          );
          setSuggestions({ type: "update", payload: { localities } });
        },
        []
      );

      useEffect(() => {
        if (!state.adminAreaLevel1 || !state.adminAreaLevel2) {
          setSuggestions({
            type: "update",
            payload: {
              localities: [],
            },
          });
          return;
        }
        getLocalities(state.adminAreaLevel1.id, state.adminAreaLevel2.id);
      }, [state.adminAreaLevel1, state.adminAreaLevel2]);

      const getSubLocalities = useCallback(async (localityId: number) => {
        const subLocalities = await AddressRequest.getSublocalitySuggestions(
          localityId
        );
        setSuggestions({ type: "update", payload: { subLocalities } });
      }, []);

      const getStreets = useCallback(async (localityId: number) => {
        const streets = await AddressRequest.getStreetSuggestions(localityId);
        setSuggestions({ type: "update", payload: { streets } });
      }, []);

      useEffect(() => {
        if (!state.locality) {
          setSuggestions({
            type: "update",
            payload: {
              streets: [],
              subLocalities: [],
            },
          });
          return;
        }
        getSubLocalities(state.locality.id);
        getStreets(state.locality.id);
      }, [state.locality]);

      useEffect(() => {
        getInitialData();
      }, []);

      const updateCountry = useCallback(
        (countryIso2: string) => {
          if (!countryIso2) return;
          setState({
            type: "update",
            payload: {
              countryIso2,
              adminAreaLevel1: "",
              adminAreaLevel2: "",
              locality: "",
              sublocality: "",
              street: "",
            },
          });
        },
        [countries, suggestions.countries]
      );

      const onLocationChange = useCallback(
        (address: Address) => {
          if (!address) return;
          const street = get(address, "street", "");
          const postalCode = get(address, "postalCode", "");
          const locality = get(address, "locality", "");
          const sublocality = get(address, "sublocality", "");
          const adminAreaLevel1 = get(address, "adminAreaLevel1", "");
          const adminAreaLevel2 = get(address, "adminAreaLevel2", "");
          const houseNumber = get(address, "houseNumber", "");
          const houseNumberPostfix = get(address, "houseNumberPostfix", "");
          const countryIso2 = get(address, "countryIso2", "");
          const geoLocation = get(address, "geoLocation", "");

          if (!!countryIso2) {
            updateCountry(countryIso2);
          }

          setState({
            type: "update",
            payload: {
              street,
              postalCode,
              locality,
              sublocality,
              adminAreaLevel1,
              adminAreaLevel2,
              houseNumber,
              houseNumberPostfix,
              countryIso2,
              geoLocation,
            },
          });
        },
        [updateCountry]
      );

      const updateAdminAreaLevel1 = useCallback(
        (adminAreaLevel1: AddressDetail) => {
          setState({
            type: "update",
            payload: {
              adminAreaLevel1,
              adminAreaLevel2: "",
              locality: "",
              sublocality: "",
              street: "",
            },
          });
        },
        []
      );

      const updateAdminAreaLevel2 = useCallback(
        (adminAreaLevel2: AddressDetail) => {
          setState({
            type: "update",
            payload: {
              adminAreaLevel2,
              locality: "",
              sublocality: "",
              street: "",
            },
          });
        },
        []
      );

      const updateLocality = useCallback((locality: AddressDetail) => {
        setState({
          type: "update",
          payload: {
            locality,
            sublocality: "",
            street: "",
          },
        });
      }, []);

      const updateSublocality = useCallback((sublocality: AddressDetail) => {
        setState({ type: "update", payload: { sublocality } });
      }, []);

      const updatePostalCode = useCallback((postalCode: string) => {
        setState({ type: "update", payload: { postalCode } });
      }, []);

      const updateHideHouseNumber = useCallback((hideHouseNumber: boolean) => {
        setState({ type: "update", payload: { hideHouseNumber } });
      }, []);

      const updateConstructionNumber = useCallback(
        (constructionNumber: string) => {
          setState({ type: "update", payload: { constructionNumber } });
        },
        []
      );

      const updateHouseNumberPostfix = useCallback(
        (houseNumberPostfix: string) => {
          setState({ type: "update", payload: { houseNumberPostfix } });
        },
        []
      );

      const updateHouseNumber = useCallback((houseNumber: number) => {
        setState({ type: "update", payload: { houseNumber } });
      }, []);

      const updateStreet = useCallback((street: AddressDetail) => {
        setState({ type: "update", payload: { street } });
      }, []);

      const updateNameOfBuilding = useCallback((nameOfBuilding: string) => {
        setState({ type: "update", payload: { nameOfBuilding } });
      }, []);

      const updateGeoLocation = useCallback((geoLocation: GeoLocation) => {
        setState({ type: "update", payload: { geoLocation } });
      }, []);

      const handleOnChange = useCallback(
        debounce(
          (
            state: AddressState,
            onChange: (response: AddressExtendedResponse) => void
          ) => {
            const identity = (v: any) => !["", null, undefined].includes(v);
            const {
              street,
              houseNumber,
              houseNumberPostfix,
              constructionNumber,
              hideHouseNumber,
              postalCode,
              locality,
              sublocality,
              adminAreaLevel2,
              adminAreaLevel1,
              countryIso2,
              geoLocation,
              nameOfBuilding,
              fundaLocality,
              fundaPostalCode,
              fundaStreet,
            } = state;

            let address: Address = {
              street,
              houseNumber,
              houseNumberPostfix,
              postalCode,
              locality,
              sublocality,
              adminAreaLevel2,
              adminAreaLevel1,
              countryIso2,
              geoLocation,
            };
            address = pickBy(address, identity);

            onChange({
              address,
              constructionNumber,
              hideHouseNumber,
              nameOfBuilding,
              fundaLocality,
              fundaPostalCode,
              fundaStreet,
            });
          },
          100
        ),
        []
      );

      useEffect(() => {
        handleOnChange(state, onChange);
      }, [state]);

      const hasFullFundaAddress = useMemo(() => {
        return (
          !!state.fundaLocality &&
          !!state.fundaPostalCode &&
          !!state.fundaStreet
        );
      }, [state.fundaLocality, state.fundaPostalCode, state.fundaStreet]);

      const fundaResultRow = useMemo(() => {
        if (
          !hasFullFundaAddress ||
          typeOfAssignment !== AssignmentType.Object ||
          !["DE", "BE", "LU"].includes(state.countryIso2)
        )
          return null;

        return (
          <FeatureSwitch feature="BORDER_ADDRESS">
            <div styleName="row">
              <div styleName="column">
                <label>
                  <I18n value="hasFundaAddress.addressLabel" />
                </label>
                <div
                  styleName="funda-address"
                  onClick={() => setShowFundaModal(true)}
                >
                  <div styleName="inner">{`${state.fundaStreet}, ${state.fundaPostalCode}, ${state.fundaLocality}`}</div>
                  <i className="fal fa-pencil" />
                </div>
              </div>
            </div>
          </FeatureSwitch>
        );
      }, [
        state.countryIso2,
        typeOfAssignment,
        hasFullFundaAddress,
        state.fundaLocality,
        state.fundaPostalCode,
        state.fundaStreet,
      ]);

      const onAddFundaAddress = useCallback((values: FundaAddressFields) => {
        setState({ type: "update", payload: values });
        setShowFundaModal(false);
      }, []);

      return (
        <div styleName="address">
          <label htmlFor="location">
            <I18n value="address.extended.label.location" />
          </label>
          <div styleName="location">
            <Input.LocationQueryV2
              name="location"
              countries={countries}
              countryIso2={state.countryIso2}
              culture={culture}
              backgroundColor="#f7f7f7"
              asSingleInput
              onChange={onLocationChange}
            />
          </div>

          {!!children && typeof (children === "function") && children(address)}

          <label>
            <I18n value="address.extended.label.manual" />
          </label>
          <div styleName="form__wrapper">
            <div styleName="form">
              {fundaResultRow}

              <div styleName="row">
                <div styleName="column">
                  <AddressDetailQuery
                    name="street"
                    value={state.street}
                    values={suggestions.streets}
                    disabled={!state.street && !suggestions.streets.length}
                    onChange={updateStreet}
                  />
                </div>
                <div styleName="column small">
                  <label htmlFor="houseNumber">
                    <I18n value="address.label.houseNumber" />
                  </label>
                  <Input.Number
                    name="houseNumber"
                    placeholder="address.placeholder.houseNumber"
                    value={state.houseNumber}
                    asSingleInput
                    onChange={updateHouseNumber}
                  />
                </div>
                <div styleName="column small">
                  <label htmlFor="houseNumberPostfix">
                    <I18n value="address.label.houseNumberPostfix" />
                  </label>
                  <Input.Text
                    name="houseNumberPostfix"
                    placeholder="address.placeholder.houseNumberPostfix"
                    value={state.houseNumberPostfix}
                    asSingleInput
                    onChange={updateHouseNumberPostfix}
                  />
                </div>
                {!!partOfProject && (
                  <div styleName="column small">
                    <label htmlFor="constructionNumber">
                      <I18n value="address.label.constructionNumber" />
                    </label>
                    <Input.Text
                      name="constructionNumber"
                      placeholder="address.placeholder.constructionNumber"
                      value={state.constructionNumber}
                      asSingleInput
                      onChange={updateConstructionNumber}
                    />
                  </div>
                )}
              </div>

              {typeOfAssignment === AssignmentType.Object && (
                <div styleName="row">
                  <Input.Switch
                    name="hideHouseNumber"
                    label="address.label.hideHouseNumber"
                    on={true}
                    off={false}
                    value={state.hideHouseNumber}
                    labelPosition={SwitchLabelPosition.Pre}
                    asSingleInput
                    onChange={updateHideHouseNumber}
                  />
                </div>
              )}

              <div styleName="row">
                <div styleName="column medium">
                  <label htmlFor="postalCode">
                    <I18n value="address.label.postalCode" />
                  </label>
                  <Input.Text
                    name="postalCode"
                    placeholder="address.placeholder.postalCode"
                    value={state.postalCode}
                    asSingleInput
                    onChange={updatePostalCode}
                  />
                </div>
                <div styleName="column">
                  <AddressDetailQuery
                    name="locality"
                    value={state.locality}
                    values={suggestions.localities}
                    disabled={!state.locality && !suggestions.localities.length}
                    onChange={updateLocality}
                  />
                </div>
              </div>

              <div styleName="row">
                <div styleName="column">
                  <AddressDetailQuery
                    name="sublocality"
                    value={state.sublocality}
                    values={suggestions.subLocalities}
                    disabled={
                      !state.sublocality && !suggestions.subLocalities.length
                    }
                    onChange={updateSublocality}
                  />
                </div>
                <div styleName="column">
                  <AddressDetailQuery
                    name="adminAreaLevel2"
                    value={state.adminAreaLevel2}
                    values={suggestions.adminAreasLevel2}
                    disabled={
                      !state.adminAreaLevel2 &&
                      !suggestions.adminAreasLevel2.length
                    }
                    onChange={updateAdminAreaLevel2}
                  />
                </div>
              </div>

              <div styleName="row">
                <div styleName="column">
                  <AddressDetailQuery
                    name="adminAreaLevel1"
                    value={state.adminAreaLevel1}
                    values={suggestions.adminAreasLevel1}
                    disabled={
                      !state.adminAreaLevel1 &&
                      !suggestions.adminAreasLevel1.length
                    }
                    onChange={updateAdminAreaLevel1}
                  />
                </div>
                <div styleName="column">
                  <label htmlFor="countryIso2">
                    <I18n value="address.label.countryIso2" />
                  </label>
                  <Input.Query
                    name="countryIso2"
                    placeholder="address.placeholder.countryIso2"
                    value={state.countryIso2}
                    values={suggestions.countries}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: string | AddressDetail) => {
                      const resultString = isString(c)
                        ? (
                            countries.find(
                              (country) => country.iso2CodeValue === c
                            ) || { displayName: "" }
                          ).displayName
                        : c.name;

                      return {
                        value: isString(c)
                          ? c
                          : (
                              countries.find(
                                (country) => country.displayName === c.name
                              ) || { iso2CodeValue: "NL" }
                            ).iso2CodeValue,
                        resultString,
                      };
                    }}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    asSingleInput
                    onChange={updateCountry}
                  />
                </div>
              </div>

              {!!isCommercialObject && (
                <div styleName="row">
                  <div styleName="column">
                    <label htmlFor="nameOfBuilding">
                      <I18n value="address.label.nameOfBuilding" />
                    </label>
                    <Input.Text
                      name="nameOfBuilding"
                      placeholder="address.placeholder.nameOfBuilding"
                      value={state.nameOfBuilding}
                      asSingleInput
                      onChange={updateNameOfBuilding}
                    />
                  </div>
                </div>
              )}
            </div>
            <div styleName="map">
              <Map
                geoLocation={state.geoLocation}
                onGeoLocationChange={updateGeoLocation}
              />
            </div>
          </div>

          <FundaModal
            visible={showFundaModal}
            fields={{
              fundaLocality: state.fundaLocality,
              fundaPostalCode: state.fundaPostalCode,
              fundaStreet: state.fundaStreet,
            }}
            hasFullAddress={hasFullFundaAddress}
            onClose={() => setShowFundaModal(false)}
            onAddFundaAddress={onAddFundaAddress}
          />
        </div>
      );
    }
  )
);
