import * as React from "react";
import { FormattedNumber } from "react-intl";
import * as CSSModules from "react-css-modules";

import { Form, FormReturnValue, Input, FormControls } from "@haywork/modules/form";
import { SaleOffer, BusinessExpenses } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("./form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface AssignmentEditFinancialAdditionalComponentProps {
  businessExpenses: BusinessExpenses;
  saleOffer: SaleOffer;
  onChange: (values: FormReturnValue) => void;
}
interface AssignmentEditFinancialAdditionalComponentState {
  totalMonthly: number;
  totalYearly: number;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialAdditionalComponent extends React.Component<
  AssignmentEditFinancialAdditionalComponentProps,
  AssignmentEditFinancialAdditionalComponentState
> {
  constructor(props) {
    super(props);

    const { saleOffer, businessExpenses } = this.props;
    const totalMonthly =
      parseFloat(value(saleOffer, "serviceCosts", 0)) +
      parseFloat(value(saleOffer, "indicationGas", 0)) +
      parseFloat(value(saleOffer, "indicationWater", 0)) +
      parseFloat(value(saleOffer, "indicationElectra", 0)) +
      parseFloat(value(saleOffer, "indicationHeatingCosts", 0));
    const totalYearly =
      parseFloat(value(businessExpenses, "oZBOccupiersShare", 0)) +
      parseFloat(value(businessExpenses, "oZBBusinessShare", 0)) +
      parseFloat(value(businessExpenses, "sewerageCharges", 0)) +
      parseFloat(value(businessExpenses, "waterAuthorityCharges", 0));

    this.state = {
      totalMonthly,
      totalYearly
    };

    this.onAdditionalChangeHandler = this.onAdditionalChangeHandler.bind(this);
  }

  public render() {
    const { saleOffer, businessExpenses } = this.props;
    const formControls: FormControls = {
      serviceCosts: { value: value(saleOffer, "serviceCosts", "") },
      indicationGas: { value: value(saleOffer, "indicationGas", "") },
      indicationWater: { value: value(saleOffer, "indicationWater", "") },
      indicationElectra: { value: value(saleOffer, "indicationElectra", "") },
      indicationHeatingCosts: { value: value(saleOffer, "indicationHeatingCosts", "") },
      oZBOccupiersShare: { value: value(businessExpenses, "oZBOccupiersShare", "") },
      oZBBusinessShare: { value: value(businessExpenses, "oZBBusinessShare", "") },
      sewerageCharges: { value: value(businessExpenses, "sewerageCharges", "") },
      waterAuthorityCharges: { value: value(businessExpenses, "waterAuthorityCharges", "") },
      landConsolidationRent: { value: value(businessExpenses, "landConsolidationRent") }
    };

    return (
      <Form name="additional" onChange={this.onAdditionalChangeHandler} formControls={formControls}>
        {/* Service costs */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="serviceCosts">
              <ResourceText resourceKey="serviceCosts" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="serviceCosts" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perMonth" />
              </div>
            </div>
          </div>
        </div>

        {/* Gas */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="indicationGas">
              <ResourceText resourceKey="indicationGas" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="indicationGas" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perMonth" />
              </div>
            </div>
          </div>
        </div>

        {/* Water */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="indicationWater">
              <ResourceText resourceKey="indicationWater" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="indicationWater" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perMonth" />
              </div>
            </div>
          </div>
        </div>

        {/* Light */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="indicationElectra">
              <ResourceText resourceKey="indicationElectra" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="indicationElectra" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perMonth" />
              </div>
            </div>
          </div>
        </div>

        {/* Heating */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="indicationHeatingCosts">
              <ResourceText resourceKey="indicationHeatingCosts" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="indicationHeatingCosts" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perMonth" />
              </div>
            </div>
          </div>
        </div>

        <div styleName="total">
          <div styleName="label">
            <ResourceText resourceKey="totalPerMonth" />
          </div>
          <div styleName="value">
            <FormattedNumber value={this.state.totalMonthly} style="currency" currency="EUR" />
          </div>
        </div>

        {/* Occupiers */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="oZBOccupiersShare">
              <ResourceText resourceKey="oZBOccupiersShare" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="oZBOccupiersShare" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perYear" />
              </div>
            </div>
          </div>
        </div>

        {/* Business */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="oZBBusinessShare">
              <ResourceText resourceKey="oZBBusinessShare" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="oZBBusinessShare" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perYear" />
              </div>
            </div>
          </div>
        </div>

        {/* Sewerage */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="sewerageCharges">
              <ResourceText resourceKey="sewerageCharges" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="sewerageCharges" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perYear" />
              </div>
            </div>
          </div>
        </div>

        {/* Water authority */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="waterAuthorityCharges">
              <ResourceText resourceKey="waterAuthorityCharges" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="waterAuthorityCharges" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perYear" />
              </div>
            </div>
          </div>
        </div>

        {/* Land consolidation rent */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="landConsolidationRent">
              <ResourceText resourceKey="landConsolidationRent" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="landConsolidationRent" pretty step={10} asPrice />
              <div className="post full">
                <ResourceText resourceKey="perYear" />
              </div>
            </div>
          </div>
        </div>

        <div styleName="total">
          <div styleName="label">
            <ResourceText resourceKey="totalPerYear" />
          </div>
          <div styleName="value">
            <FormattedNumber value={this.state.totalYearly} style="currency" currency="EUR" />
          </div>
        </div>
      </Form>
    );
  }

  private onAdditionalChangeHandler(values: FormReturnValue) {
    let totalMonthly = 0;
    let totalYearly = 0;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const raw = parseFloat(values[key]);
        const value = isNaN(raw) ? 0 : raw;

        switch (key) {
          case "serviceCosts":
          case "indicationGas":
          case "indicationWater":
          case "indicationElectra":
          case "indicationHeatingCosts":
            totalMonthly = totalMonthly + value;
            break;
          case "oZBOccupiersShare":
          case "oZBBusinessShare":
          case "sewerageCharges":
          case "waterAuthorityCharges":
          case "landConsolidationRent":
            totalYearly = totalYearly + value;
            break;
          default:
            break;
        }
      }
    }

    this.setState({ totalYearly, totalMonthly });
    this.props.onChange(values);
  }
}
