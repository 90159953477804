import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useCallback, useRef, useState, useMemo } from "react";
import {
  MatchedSearchAssignmentSnapshot,
  LinkedRelation,
} from "@haywork/api/kolibri";

export enum SearchAssignmentBulkAction {
  Export = "Export",
  CreateEmail = "CreateEmail",
  Deselect = "Deselect",
}

type Props = {
  canEmail: boolean;
  selectedSearchAssignments: MatchedSearchAssignmentSnapshot[];
  onClick: (action: SearchAssignmentBulkAction) => void;
};

export const SearchAssignmentActionsComponent: FC<Props> = memo(
  ({ canEmail, onClick, selectedSearchAssignments }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const emails = useMemo(() => {
      const relations = selectedSearchAssignments.reduce(
        (state, searchAssignment) => {
          (searchAssignment.linkedRelations || []).forEach((relation) =>
            state.push(relation)
          );
          return state;
        },
        [] as LinkedRelation[]
      );

      return relations
        .filter((relation) => !!relation.email)
        .map((relation) => relation.email);
    }, [selectedSearchAssignments]);

    const onClickHandler = useCallback(
      (action: SearchAssignmentBulkAction) => {
        onClick(action);
        setActionsVisible(false);
      },
      [setActionsVisible, onClick]
    );

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="bulkActions.label"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          <Action
            label="searchAssignments.bulkAction.deselect"
            icon={
              <Icon
                light
                name="arrow-from-right"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(SearchAssignmentBulkAction.Deselect)}
          />
          {canEmail && !!emails.length && (
            <Action
              label="searchAssignments.bulkAction.createEmail"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(SearchAssignmentBulkAction.CreateEmail)
              }
            />
          )}
          <Action
            label="searchAssignments.bulkAction.export"
            icon={
              <Icon
                light
                name="file-excel"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(SearchAssignmentBulkAction.Export)}
          />
        </ActionList>
      </>
    );
  }
);
