import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback } from "react";

type Props = {
  visible: boolean;
  onClose: () => void;
  onAction: (keep: boolean) => void;
};

export const KeyNumberModal: FC<Props> = memo(
  ({ visible, onClose, onAction }) => {
    const closeCallback = useCallback(() => {
      onClose();
    }, [onClose]);

    const removeCallback = useCallback(() => {
      onAction(false);
    }, [onAction]);

    const keepCallback = useCallback(() => {
      onAction(true);
    }, [onAction]);

    return (
      <Modal visible={visible} onClose={closeCallback}>
        <ModalHeader title="keyNumberModal.title" close />
        <ModalBody>
          <I18n value="keyNumberModal.body" />
        </ModalBody>
        <ModalFooter>
          <Button
            label="keyNumberModal.action.cancel"
            category="white"
            onClick={closeCallback}
          />
          <Button
            label="keyNumberModal.action.remove"
            category="danger"
            onClick={removeCallback}
          />
          <Button
            label="keyNumberModal.action.keep"
            category="primary"
            onClick={keepCallback}
          />
        </ModalFooter>
      </Modal>
    );
  }
);
