import { MapDispatchToProps, PublicationsThunk } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { PublicationComponent, PublicationComponentProps } from "./publication";

type StateProps = {};
type DispatchProps = {
  activate: (assignmentId: string, mediaPartnerId: string) => Promise<void>;
  deactivate: (assignmentId: string, mediaPartnerId: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  PublicationComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PublicationComponentProps
> = (dispatch) => ({
  activate: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(PublicationsThunk.activate(assignmentId, mediaPartnerId)),
  deactivate: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(PublicationsThunk.deactivate(assignmentId, mediaPartnerId))
});

export type PublicationContainerProps = StateProps & DispatchProps;
export const PublicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationComponent);
