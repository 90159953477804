import { Channel } from "@haywork/api/kolibri";
import * as Ably from "ably";
import * as React from "react";
import { FC, memo, useContext, useEffect } from "react";
import { LiveDataContext } from "../../live-data.context";

type Props = {
  onConnectionChange: (channels: Channel[], realtime: Ably.Realtime) => void;
};

const Connecter: FC<Props> = memo(({ onConnectionChange }) => {
  const { kolibriChannels, kolibriRealtime } = useContext(LiveDataContext);

  useEffect(() => {
    onConnectionChange(kolibriChannels, kolibriRealtime);
  }, [kolibriChannels, kolibriRealtime, onConnectionChange]);

  return null;
});

export default Connecter;
