import {
  MergeFieldCategory,
  MergeTemplate,
  TemplateDefinition,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import {
  EMAILTEMPLATESROUTES,
  EXTERNALROUTES,
  REQUEST,
} from "@haywork/constants";
import { EditableHocProps } from "@haywork/modules/editable";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { Editor, EditorType } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FileUploadObject, RouteUtil, UploadUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { SettingsEmailTemplateEditContainerProps } from "../../containers";
import { PreviewModal } from "../preview";
import { SendPreviewModal } from "../send-preview";
import Actions, { EmailTemplateAction } from "./actions";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsEmailTemplateEditComponentProps {}
interface State {
  previewVisible: boolean;
  mergedTemplate: TemplateDefinition;
  sendPreviewVisible: boolean;
}
type Props = SettingsEmailTemplateEditComponentProps &
  SettingsEmailTemplateEditContainerProps &
  EditableHocProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailTemplateEditComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  private path: string;

  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      mergedTemplate: null,
      sendPreviewVisible: false,
    };

    const mergeTemplates = get(
      this.props.template,
      "mergeTemplates",
      [] as MergeTemplate[]
    );
    const subject = mergeTemplates.find(
      (template) => template.key === "subject"
    );

    this.formControls = {
      name: {
        value: get(this.props.template, "name", ""),
        validators: [Validators.required()],
      },
      linkedTemplateDefinitionCategory: {
        value: get(
          this.props.template,
          "linkedTemplateDefinitionCategory.id",
          ""
        ),
      },
      subject: { value: !!subject ? subject.value : "" },
    };

    this.path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
      id: this.props.template.id,
    });

    this.onEditorChange = this.onEditorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAttachmentChange = this.onAttachmentChange.bind(this);
    this.showTemplatePreview = this.showTemplatePreview.bind(this);
    this.sendTemplatePreview = this.sendTemplatePreview.bind(this);
    this.onPreviewClose = this.onPreviewClose.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveAndCloseTemplate(nextProps.template);
    }
  }

  public componentDidMount() {
    const name = get(this.props.template, "name");
    if (!!name) this.props.setTabTitle(name);
  }

  public render() {
    const { mergeTemplates: templates } = this.props.template;
    const mergeTemplates = templates || [];
    const template = mergeTemplates.find(
      (template) => template.key === "template"
    );
    const initialValue = !!template ? template.value : "";
    const loading = this.props.status === REQUEST.PENDING;

    return (
      <div styleName="template">
        <PageHeader
          title={this.props.template.name || "emailTemplates.defaultTitle"}
          subTitle="emailTemplates"
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={() => this.form.submit()}
                disabled={loading}
              />
              <Actions loading={loading} onClick={this.onActionClick} />
            </>
          }
        />

        <div className="alert alert-info">
          <i className="fal fa-fw fa-info-circle" />
          <I18n
            value="emailTemplateHint"
            values={{ url: EXTERNALROUTES.EMAIL_TEMPLATES_HELPDESK.URI }}
            asHtml
          />
        </div>

        <div styleName="template__body">
          {this.props.status === REQUEST.PENDING && (
            <Ui.Loaders.Fullscreen mask={true} />
          )}

          <div styleName="form-fields">
            <Form
              name="template"
              formControls={this.formControls}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              form={(form) => (this.form = form)}
            >
              <div className="form__row">
                <div className="form__group stretch">
                  <div className="column">
                    <label htmlFor="name">
                      <I18n value="editEmailTemplateLabel.name" />
                    </label>
                    <Input.Text
                      name="name"
                      placeholder="editEmailTemplatePlaceholder.name"
                      data-cy="CY-emailTemplateTitle"
                    />
                  </div>

                  <div className="column__spacer" />

                  <div className="column">
                    <label htmlFor="linkedTemplateDefinitionCategory">
                      <I18n value="editEmailTemplateLabel.linkedTemplateDefinitionCategory" />
                    </label>
                    <Input.NewSelect
                      name="linkedTemplateDefinitionCategory"
                      values={this.props.categories}
                      displayProp="name"
                      valuesProp="id"
                    />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="column">
                  <label htmlFor="subject">
                    <I18n value="editEmailTemplateLabel.subject" />
                  </label>
                  <Input.TextWithMergeField
                    name="subject"
                    placeholder="editEmailTemplatePlaceholder.subject"
                    inEditMode={true}
                    mergeFields={this.props.mergeFields}
                    mergefieldCategoryBlacklist={[
                      MergeFieldCategory.AgendaItem,
                    ]}
                    data-cy="CY-emailTemplateSubject"
                  />
                </div>
              </div>
            </Form>
          </div>
          <div styleName="editor">
            <Editor
              initialValue={initialValue}
              uploadUrl={this.props.uploadUrl}
              uploadShouldBePrivate={true}
              type={EditorType.CreateTemplate}
              onChange={this.onEditorChange}
              onAttachmentChange={this.onAttachmentChange}
              attachments={this.props.template.attachmentBlobs}
              mergefieldCategoryBlacklist={[MergeFieldCategory.AgendaItem]}
            />
          </div>
        </div>

        <PreviewModal
          visible={this.state.previewVisible}
          template={this.state.mergedTemplate}
          onClose={this.onPreviewClose}
        />

        <SendPreviewModal
          visible={this.state.sendPreviewVisible}
          template={this.state.mergedTemplate}
          onClose={this.onPreviewClose}
        />
      </div>
    );
  }

  private onEditorChange(content: string) {
    const { mergeTemplates: templates } = this.props.template;

    let hasTemplate = false;
    const mergeTemplates = templates.map((template) => {
      if (template.key === "template") {
        hasTemplate = true;
        return {
          ...template,
          value: content,
        };
      }
      return template;
    });

    if (!hasTemplate) {
      mergeTemplates.push({
        id: uuid(),
        key: "template",
        value: content,
        culture: this.props.culture,
      });
    }

    const cache: TemplateDefinition = {
      ...this.props.template,
      mergeTemplates,
    };

    this.props.updateCache(cache, this.path);
  }

  private onAttachmentChange(attachments: FileUploadObject[]) {
    const attachmentBlobs = !attachments.length
      ? undefined
      : attachments.map((attachment) =>
          UploadUtil.staticResponseToTemplateDefintionAttachmentBlob(
            attachment.response
          )
        );

    const cache: TemplateDefinition = {
      ...this.props.template,
      attachmentBlobs,
    };

    this.props.updateCache(cache, this.path);
  }

  private async showTemplatePreview() {
    try {
      const mergedTemplate = await this.props.getTemplatePreview(
        this.props.template
      );

      this.setState({
        previewVisible: true,
        mergedTemplate,
      });
    } catch (error) {
      throw error;
    }
  }

  private async sendTemplatePreview() {
    try {
      const mergedTemplate = await this.props.getTemplatePreview(
        this.props.template
      );

      this.setState({
        sendPreviewVisible: true,
        mergedTemplate,
      });
    } catch (error) {
      throw error;
    }
  }

  private onPreviewClose() {
    this.setState({ previewVisible: false, sendPreviewVisible: false });
  }

  private onChange(values: FormReturnValue) {
    const { mergeTemplates: templates } = this.props.template;

    let hasTemplate = false;
    const mergeTemplates = templates.map((template) => {
      if (template.key === "subject") {
        hasTemplate = true;
        return {
          ...template,
          value: values.subject,
        };
      }
      return template;
    });

    if (!hasTemplate) {
      mergeTemplates.push({
        id: uuid(),
        key: "subject",
        value: values.subject,
        culture: this.props.culture,
      });
    }

    const cache: TemplateDefinition = {
      ...this.props.template,
      name: values.name,
      mergeTemplates,
      linkedTemplateDefinitionCategory: {
        id: values.linkedTemplateDefinitionCategory,
      },
    };

    this.props.updateCache(cache, this.path);
  }

  private onSubmit() {
    this.props.saveTemplate(this.props.template);
  }

  private onActionClick(action: EmailTemplateAction) {
    switch (action) {
      case EmailTemplateAction.Preview: {
        this.showTemplatePreview();
        break;
      }
      case EmailTemplateAction.TestEmail: {
        this.sendTemplatePreview();
        break;
      }
      default: {
        break;
      }
    }
  }
}
