export enum NewEntityType {
  Relation = "Relation",
  Assignment = "Assignment",
  Task = "Task",
  Appointment = "Appointment"
}

export enum NewEntityRequester {
  NewEmailTo = "NewEmailTo",
  NewEmailCC = "NewEmailCC",
  NewEmailBCC = "NewEmailBCC"
}
