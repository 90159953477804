import { RootEntityType } from "@haywork/api/event-center";
import { intlContext } from "@haywork/app";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import ExternalChanges from "@haywork/components/external-changes";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import { PROJECTROUTES, REQUEST } from "@haywork/constants";
import Notes from "@haywork/modules/notes-v3";
import {
  ProjectEditContainerProps,
  ProjectEditRouting,
} from "@haywork/modules/project";
import { ConfirmComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { ProjectUtil, RouteUtil } from "@haywork/util";
import { ProjectAssignmentValidatedSubRoutes } from "@haywork/util/project";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { ProjectAction } from "./actions";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ProjectEditComponentProps {}
interface State {
  previousRoute: string;
  nextRoute: string;
  validSubRoutes: ProjectAssignmentValidatedSubRoutes;
  backToDashboardConfirmVisible: boolean;
  confirmSave: boolean;
  confirmSaveAndClose: boolean;
}
type Props = ProjectEditComponentProps & ProjectEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditComponent extends React.Component<Props, State> {
  private routes: string[] = [
    PROJECTROUTES.EDIT_CLIENT.URI,
    PROJECTROUTES.EDIT_ADDRESS.URI,
    PROJECTROUTES.EDIT_MARKETING.URI,
    PROJECTROUTES.PUBLISH.URI,
  ];
  private currentRouteIndex: number = 0;

  constructor(props) {
    super(props);

    this.state = {
      nextRoute: null,
      previousRoute: null,
      validSubRoutes: {
        client: false,
        address: false,
        marketing: false,
      },
      backToDashboardConfirmVisible: false,
      confirmSave: false,
      confirmSaveAndClose: false,
    };

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.renderSubTitle = this.renderSubTitle.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.backToDashboard = this.backToDashboard.bind(this);
    this.cancelBackToDashboard = this.cancelBackToDashboard.bind(this);
  }

  public componentDidMount() {
    const { id } = this.props.project;
    this.routes = this.routes.map((r) => route(r, { id }));
    const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes();
    const validSubRoutes = ProjectUtil.Validation.validateSubRoutes(
      this.props.project
    );
    this.setState({ validSubRoutes, nextRoute, previousRoute });
  }

  public render() {
    const { id, displayName, isNew } = this.props.project;
    const { validSubRoutes } = this.state;

    const clientRoute = classNames("item", {
      valid: validSubRoutes.client,
    });
    const addressRoute = classNames("item", {
      valid: validSubRoutes.address,
    });
    const marketingRoute = classNames("item", {
      disabled: isNew,
      valid: validSubRoutes.marketing,
    });
    const loading = this.props.saveAssignmentState === REQUEST.PENDING;

    return (
      <div styleName="edit">
        <PageHeader
          title={displayName}
          subTitle={this.renderSubTitle()}
          actions={
            <>
              <Presence
                entityId={id}
                entityType={RootEntityType.ProjectAssignment}
              />
              <Notes />
              <Button
                label="save"
                category="success"
                onClick={() => this.setState({ confirmSave: true })}
                disabled={loading}
              />
              <Actions
                loading={loading}
                isFullScreen={this.props.fullscreen}
                showPublish={this.props.canPublish}
                isNew={isNew}
                onClick={this.onActionClick}
              />
            </>
          }
        />

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.ProjectAssignment}
          onReloadEntity={this.props.reloadProject}
          onBackToDashboard={this.props.backToDashboard}
        />

        <div styleName="edit__body">
          {loading && <Ui.Loaders.Fullscreen mask />}
          <ProjectEditRouting id={id} />
        </div>
        <div styleName="edit__navigation">
          <div styleName="button">
            {this.state.previousRoute && (
              <NavLink
                to={this.state.previousRoute}
                className="btn btn-default-alt icon-left"
              >
                <i className="fal fa-fw fa-chevron-left" />
                <I18n value="previousText" />
              </NavLink>
            )}
          </div>

          <div styleName="navigation">
            <div styleName="navigation__list">
              <NavLink
                styleName={clientRoute}
                to={route(PROJECTROUTES.EDIT_CLIENT.URI, { id })}
                data-cy="CY-editClientButton"
              >
                <div id="navigationClient" styleName="index">
                  1
                </div>
                <I18n value="assignmentNavigationClient" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={addressRoute}
                to={route(PROJECTROUTES.EDIT_ADDRESS.URI, { id })}
                data-cy="CY-editProjectButton"
              >
                <div id="navigationAddress" styleName="index">
                  2
                </div>
                <I18n value="project" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={marketingRoute}
                to={route(PROJECTROUTES.EDIT_MARKETING.URI, { id })}
                onClick={this.cancelNavigation}
                data-cy="CY-editMarketingButton"
              >
                <div id="navigationMarketing" styleName="index">
                  3
                </div>
                <I18n value="assignmentNavigationMarketing" />
              </NavLink>
            </div>
          </div>

          <div styleName="button last">
            {this.state.nextRoute &&
              this.currentRouteIndex < this.routes.length - 2 && (
                <NavLink
                  to={this.state.nextRoute}
                  className="btn btn-success icon-right"
                  onClick={this.onNextClickHandler}
                  data-cy="CY-nextButton"
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
            {this.props.canPublish &&
              this.currentRouteIndex === this.routes.length - 2 && (
                <NavLink
                  to={route(PROJECTROUTES.PUBLISH.URI, { id })}
                  className="btn btn-success icon-right"
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.backToDashboardConfirmVisible}
          titleResourceKey="project.backToDashboard.title"
          bodyResourceKey="project.backToDashboard.body"
          onClose={this.cancelBackToDashboard}
          onConfirm={this.backToDashboard}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ProjectAssignment}
          entityId={id}
          saving={this.state.confirmSave}
          onCancel={() => this.setState({ confirmSave: false })}
          onConfirm={() => this.onSaveClickHandler(false)}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ProjectAssignment}
          entityId={id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => this.onSaveClickHandler()}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes(
        nextProps.location.pathname
      );
      const validSubRoutes = ProjectUtil.Validation.validateSubRoutes(
        nextProps.assignment || this.props.project
      );
      this.setState({ validSubRoutes, nextRoute, previousRoute });
    }
  }

  private onNextClickHandler(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.project.isNew && this.currentRouteIndex === 1) {
      event.preventDefault();
      if (!this.props.canSave) return this.props.toggleSaveModal(true);
      this.props.saveInitialAssignment();
    }
  }

  private cancelNavigation(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.project.isNew) event.preventDefault();
  }

  private onSaveClickHandler(close: boolean = true) {
    this.setState({ confirmSave: false, confirmSaveAndClose: false });
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.props.saveProject(close);
  }

  private onPublishClickHandler() {
    if (!this.props.canPublish) return;
    this.props.publish(this.props.project.id);
  }

  private getNextAndPreviousRoutes(pathname?: string): {
    nextRoute: string;
    previousRoute: string;
  } {
    this.currentRouteIndex = this.routes.reduce((state, route, idx) => {
      const path = pathname || this.props.location.pathname;
      if (new RegExp(route, "gi").test(path)) return idx;
      return state;
    }, -1);

    const nextRoute =
      this.currentRouteIndex <= this.routes.length
        ? this.routes[this.currentRouteIndex + 1]
        : null;
    const previousRoute =
      this.currentRouteIndex > 0
        ? this.routes[this.currentRouteIndex - 1]
        : null;

    return {
      nextRoute,
      previousRoute,
    };
  }

  private renderSubTitle() {
    const { project } = this.props;
    const { forSale, forRent } = project;
    const segments = [
      intlContext.formatMessage({ id: "project", defaultMessage: "Project" }),
    ];

    switch (true) {
      case forSale && forRent: {
        segments.push(
          intlContext.formatMessage({
            id: "rentSale",
            defaultMessage: "rent and sale",
          })
        );
        break;
      }
      case forSale: {
        segments.push(
          intlContext.formatMessage({ id: "forSale", defaultMessage: "sale" })
        );
        break;
      }
      case forRent: {
        segments.push(
          intlContext.formatMessage({ id: "forRent", defaultMessage: "rent" })
        );
        break;
      }
      default: {
        break;
      }
    }

    return segments.join(", ");
  }

  private onActionClick(action: ProjectAction) {
    switch (action) {
      case ProjectAction.BackToDashboard: {
        if (this.props.hasChanges) {
          this.setState({ backToDashboardConfirmVisible: true });
        } else {
          this.props.backToDashboard();
        }
        break;
      }
      case ProjectAction.Fullscreen: {
        this.props.toggleFullscreen();
        break;
      }
      case ProjectAction.Publish: {
        this.onPublishClickHandler();
        break;
      }
      case ProjectAction.SaveAndClose: {
        this.setState({ confirmSaveAndClose: true });
        break;
      }
      default: {
        break;
      }
    }
  }

  private backToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
    this.props.backToDashboard();
  }

  private cancelBackToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
  }
}
