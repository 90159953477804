import * as React from "react";
import {
  memo,
  FC,
  useMemo,
  CSSProperties,
  useRef,
  useState,
  useCallback,
  useContext,
  Fragment,
} from "react";
import classNames from "classnames";
import { ColumnConfig, ListContext } from "../..";
import * as CSSModules from "react-css-modules";
import ActionList, { Spacer } from "@haywork/components/ui/action-list";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import { SortOrder } from "@haywork/api/kolibri";
import isString from "lodash-es/isString";

const styles = require("./style.scss");

type Props = {
  listName: string;
  config: ColumnConfig;
};

export const HeaderColumnComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ listName, config }: Props) => {
    const { onSortChangeCallback, sorting } = useContext(ListContext);

    const { width, label, sortConfig, autoFill } = config;
    const [actionsVisible, setActionsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const sortRef = useRef<HTMLDivElement>(null);

    const calculatedStyles = useMemo(() => {
      if (!width) return;

      return {
        width,
        minWidth: width,
      } as CSSProperties;
    }, [width]);

    const sortOptions = useMemo(() => {
      if (!sortConfig) return null;

      return (
        <>
          {sortConfig.map((column, idx) => (
            <Fragment key={idx}>
              <div
                styleName={classNames("sort__label", {
                  active:
                    column === sorting.sortColumn &&
                    sorting.sortOrder === SortOrder.Ascending,
                })}
                key={`${listName}.${column}.ASC`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSortChangeCallback(column, SortOrder.Ascending);
                  setActionsVisible(false);
                }}
              >
                <I18n
                  asHtml
                  value={`${listName}.filter.${column.toString()}.${SortOrder.Ascending.toString()}`}
                />
              </div>
              <div
                styleName={classNames("sort__label", {
                  active:
                    column === sorting.sortColumn &&
                    sorting.sortOrder === SortOrder.Descending,
                })}
                key={`${listName}.${column}.DESC`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSortChangeCallback(column, SortOrder.Descending);
                  setActionsVisible(false);
                }}
              >
                <I18n
                  asHtml
                  value={`${listName}.filter.${column.toString()}.${SortOrder.Descending.toString()}`}
                />
              </div>
            </Fragment>
          ))}
        </>
      );
    }, [
      sortConfig,
      listName,
      onSortChangeCallback,
      setActionsVisible,
      sorting,
    ]);

    const toggleSortList = useCallback(() => {
      if (!sortConfig) return;
      setActionsVisible(!actionsVisible);
    }, [actionsVisible, setActionsVisible, sortConfig]);

    const hasSortingMatch = useMemo(
      () => (sortConfig || []).includes(sorting.sortColumn),
      [sortConfig, sorting]
    );

    const columnLabel = useMemo(() => {
      if (hasSortingMatch) {
        return (
          <I18n
            asHtml
            value={`${listName}.filter.${sorting.sortColumn.toString()}.${sorting.sortOrder.toString()}`}
          />
        );
      }

      if (!label) return null;
      if (isString(label)) {
        return <I18n value={`${listName}.columnName.${label}`} asHtml />;
      } else {
        const { value, values } = label;
        return (
          <I18n
            value={`${listName}.columnName.${value}`}
            values={values}
            asHtml
          />
        );
      }
    }, [label, hasSortingMatch, sorting]);

    const sortTrigger = useMemo(() => {
      if (!sortConfig) return null;
      const active = sortConfig.includes(sorting.sortColumn);

      return (
        <div styleName="sort" ref={sortRef}>
          <Icon
            name={
              active && sorting.sortOrder === SortOrder.Ascending
                ? "chevron-up"
                : "chevron-down"
            }
            light={!active}
            regular={active}
            size={16}
            color={active ? Colors.Primary : Colors.Gray}
          />
        </div>
      );
    }, [sortConfig, sorting]);

    return (
      <div
        styleName={classNames("column", {
          "auto-fill": !!autoFill,
        })}
        style={calculatedStyles}
        onClick={toggleSortList}
        ref={ref}
      >
        <div
          styleName={classNames("label", {
            empty: !columnLabel,
            "has-sorting-match": hasSortingMatch,
          })}
        >
          {columnLabel}
        </div>
        {sortTrigger}

        <ActionList
          parent={ref}
          positionOn={sortRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="listSort" />
          {sortOptions}
        </ActionList>
      </div>
    );
  })
);
