import { Language, ObjectAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingOpenHouseComponent,
  AssignmentEditMarketingOpenHouseComponentProps,
} from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  languages: Language[];
  defaultLanguage: Language;
}

interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingOpenHouseComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingOpenHouseComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AssignmentEditMarketingOpenHouseContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingOpenHouseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingOpenHouseComponent);
