import {
  CertificationOption,
  ComfortQualityOption,
  ConditionOption,
  FurnishingOption,
  ListingType,
  ObjectAssignment,
  ParkingFacilityOption,
  Peculiarity,
  PeculiarityOption,
  PollutionTypeOption,
  RelationSnapShot,
  ResidentialFacilityOption,
  RoofMaterialOption,
  RoofTypeOption,
  TypeOfBusinessLawOption,
  TypeOfFloorOption,
  TypeOfWindowFrameOption,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { NewEntityType } from "@haywork/enum";
import { VVEOption } from "@haywork/modules/assignment";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { Ui } from "@haywork/modules/ui";
import { AssignmentUtil, FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const accessibilityTypes = [
  Peculiarity.AccessibleForTheElderly,
  Peculiarity.AccessibleToDisabledPeople,
];
const occupancyTypes = [
  Peculiarity.DoubleOccupancyAvailable,
  Peculiarity.DoubleOccupancyPossible,
  Peculiarity.PartiallyLeased,
];
const distinguishableTypes = [
  Peculiarity.MonumentalBuilding,
  Peculiarity.NeedsRenovation,
  Peculiarity.DemolitionHouse,
  Peculiarity.Heritage,
];

interface Props {
  objectAssignment: ObjectAssignment;
  realEstateAgencyId: string;
  certificationOptions: CertificationOption[];
  comfortQualityOptions: ComfortQualityOption[];
  typeOfFloorOptions: TypeOfFloorOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  typeOfWindowFrameOptions: TypeOfWindowFrameOption[];
  conditionOptions: ConditionOption[];
  residentialFacilityOptions: ResidentialFacilityOption[];
  furnishingOptions: FurnishingOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  vveOptions: VVEOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  host: string;
  accessibilityOptions: PeculiarityOption[];
  occupancyOptions: PeculiarityOption[];
  distinguishablesOptions: PeculiarityOption[];
  pollutionOptions: PollutionTypeOption[];
  onChange: (values: FormReturnValue) => void;
  navigate: (url: string) => void;
}
interface State {
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditContentParticularsComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);

    const { objectAssignment } = this.props;
    const selectedVveOptions = AssignmentUtil.getSelectedVVEOptions(
      objectAssignment,
      this.props.vveOptions
    );
    const {
      isPermanentlyInhabited,
      isRecreational,
      inPark,
      isBuildingReady,
      approvalMarks,
      comfortQuality,
      typeOfFloorFloors,
      typeOfFloorGroundFloor,
      roof,
      typesOfWindowFrames,
      maintenanceInside,
      maintenanceOutside,
      linkedPropertyManagers,
      totalParkingCapacity,
      indoorParkingCapacity,
      residentialFacilities,
      saleOffer,
      rentOffer,
      parkingFacilities,
      businessRights,
      peculiarities,
      isHouseInNeedOfRefurbishment,
      pollution,
    } = this.props.objectAssignment;
    const offer = this.props.objectAssignment.forSale ? saleOffer : rentOffer;

    const accessibility = (peculiarities || []).filter((peculiarity) =>
      accessibilityTypes.includes(peculiarity)
    );
    const occupancy = (peculiarities || []).filter((peculiarity) =>
      occupancyTypes.includes(peculiarity)
    );
    const distinguishables = (peculiarities || []).filter((peculiarity) =>
      distinguishableTypes.includes(peculiarity)
    );
    if (!!isHouseInNeedOfRefurbishment) {
      distinguishables.push(Peculiarity.NeedsRenovation);
    }

    this.formControls = {
      isPermanentlyInhabited: { value: isPermanentlyInhabited },
      isRecreational: { value: isRecreational },
      inPark: { value: inPark },
      isBuildingReady: { value: isBuildingReady },
      approvalMarks: { value: approvalMarks },
      linkedPropertyManagers: { value: linkedPropertyManagers },
      comfortQuality: { value: comfortQuality },
      typeOfFloorFloors: { value: typeOfFloorFloors },
      typeOfFloorGroundFloor: { value: typeOfFloorGroundFloor },
      roofType: { value: value(roof, "roofType") },
      roofMaterials: { value: value(roof, "roofMaterials") },
      typesOfWindowFrames: { value: typesOfWindowFrames },
      maintenanceInside: {
        value: value(maintenanceInside, "rating"),
      },
      maintenanceOutside: {
        value: value(maintenanceOutside, "rating"),
      },
      totalParkingCapacity: { value: totalParkingCapacity },
      indoorParkingCapacity: { value: indoorParkingCapacity },
      residentialFacilities: { value: residentialFacilities },
      parkingFacilities: { value: parkingFacilities },
      furnishing: { value: value(offer, "furnishing") },
      vve: { value: selectedVveOptions },
      businessLawType: { value: value(businessRights, "businessLawType") },
      accessibility: { value: accessibility },
      occupancy: { value: occupancy },
      distinguishables: { value: distinguishables },
      pollution: { value: pollution || [] },
    };

    this.state = {
      newEntityVisible: false,
      newEntityOptions: null,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const selectedVveOptions = AssignmentUtil.getSelectedVVEOptions(
        objectAssignment,
        this.props.vveOptions
      );
      const {
        isPermanentlyInhabited,
        isRecreational,
        inPark,
        isBuildingReady,
        approvalMarks,
        comfortQuality,
        typeOfFloorFloors,
        typeOfFloorGroundFloor,
        roof,
        typesOfWindowFrames,
        maintenanceInside,
        maintenanceOutside,
        linkedPropertyManagers,
        totalParkingCapacity,
        indoorParkingCapacity,
        residentialFacilities,
        saleOffer,
        rentOffer,
        parkingFacilities,
        businessRights,
        peculiarities,
        isHouseInNeedOfRefurbishment,
        pollution,
      } = this.props.objectAssignment;
      const offer = this.props.objectAssignment.forSale ? saleOffer : rentOffer;

      const accessibility = (peculiarities || []).filter((peculiarity) =>
        accessibilityTypes.includes(peculiarity)
      );
      const occupancy = (peculiarities || []).filter((peculiarity) =>
        occupancyTypes.includes(peculiarity)
      );
      const distinguishables = (peculiarities || []).filter((peculiarity) =>
        distinguishableTypes.includes(peculiarity)
      );
      if (!!isHouseInNeedOfRefurbishment) {
        distinguishables.push(Peculiarity.NeedsRenovation);
      }

      this.formRef.update(
        {
          isPermanentlyInhabited,
          isRecreational,
          inPark,
          isBuildingReady,
          approvalMarks,
          linkedPropertyManagers,
          comfortQuality,
          typeOfFloorFloors,
          typeOfFloorGroundFloor,
          roofType: value(roof, "roofType"),
          roofMaterials: value(roof, "roofMaterials"),
          typesOfWindowFrames,
          maintenanceInside: value(maintenanceInside, "rating"),
          maintenanceOutside: value(maintenanceOutside, "rating"),
          totalParkingCapacity,
          indoorParkingCapacity,
          residentialFacilities,
          parkingFacilities,
          furnishing: value(offer, "furnishing"),
          vve: selectedVveOptions,
          businessLawType: value(businessRights, "businessLawType"),
          accessibility,
          occupancy,
          distinguishables,
          pollution: pollution || [],
        },
        true
      );
    }
  }

  public render() {
    const { forRent, listingType } = this.props.objectAssignment;
    return (
      <Form
        name="particulars"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(ref) => (this.formRef = ref)}
      >
        <div className="form__row">
          <label>
            <I18n value="assignment.label.occupancyOptions" />
          </label>
          <Input.Multi name="occupancy" values={this.props.occupancyOptions} />
        </div>

        <div className="form__row">
          <label>
            <I18n value="assignment.label.distinguishablesOptions" />
          </label>
          <Input.Multi
            name="distinguishables"
            values={this.props.distinguishablesOptions}
          />
        </div>

        <div className="form__row">
          <label>
            <I18n value="accessibilityOptions" />
          </label>
          <Input.Multi
            name="accessibility"
            values={this.props.accessibilityOptions}
          />
        </div>

        <div className="form__row">
          <label>
            <I18n value="inhabitType" />
            &nbsp;
            <Ui.RequiredForPublish />
          </label>
          <div className="form__group">
            <div className="column">
              <Input.Switch
                name="isPermanentlyInhabited"
                label="isPermanentlyInhabited"
                on={true}
                off={false}
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="isRecreational"
                label="isRecreational"
                on={true}
                off={false}
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="inPark"
                label="inPark"
                on={true}
                off={false}
              />
            </div>
            {listingType === ListingType.Plot && (
              <React.Fragment>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Switch
                    name="isBuildingReady"
                    label="isBuildingReady"
                    on={true}
                    off={false}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="form__row">
          <label htmlFor="approvalMarks">
            <I18n value="approvalMarks" />
          </label>
          <Input.Multi
            name="approvalMarks"
            values={this.props.certificationOptions}
          />
        </div>
        {this.props.objectAssignment.listingType === ListingType.Apartment && (
          <div className="form__row">
            <label htmlFor="vve">
              <I18n value="vve" />
            </label>
            <Input.Multi name="vve" values={this.props.vveOptions} />
          </div>
        )}
        {forRent && (
          <div className="form__row">
            <label htmlFor="linkedPropertyManagers">
              <I18n value="linkedPropertyManagers" />
            </label>
            <Input.RelationQuery
              name="linkedPropertyManagers"
              multiple
              onAdd={this.addNewRelation}
              onNavigateToRelation={this.props.navigate}
            />
          </div>
        )}
        <div className="form__row">
          <label htmlFor="comfortQuality">
            <I18n value="comfortQuality" />
          </label>
          <Input.RadioGroup
            name="comfortQuality"
            asButtonList
            canUnselect={true}
          >
            {this.props.comfortQualityOptions.map(
              (comfortQualityOption, idx) => (
                <Input.Radio
                  value={comfortQualityOption.value}
                  label={comfortQualityOption.displayName}
                  key={idx}
                />
              )
            )}
          </Input.RadioGroup>
        </div>
        {/* add VVE for only appartement */}
        <div className="form__row">
          <label htmlFor="furnishing">
            <I18n value="furnishing" />
          </label>
          <Input.RadioGroup name="furnishing" asButtonList canUnselect={true}>
            {this.props.furnishingOptions.map((furnishingOption, idx) => (
              <Input.Radio
                value={furnishingOption.value}
                label={furnishingOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>
        <div className="form__row">
          <label htmlFor="typeOfFloorGroundFloor">
            <I18n value="typeOfFloorGroundFloor" />
          </label>
          <Input.RadioGroup
            name="typeOfFloorGroundFloor"
            asButtonList
            canUnselect={true}
          >
            {this.props.typeOfFloorOptions.map((typeOfFloorOption, idx) => (
              <Input.Radio
                value={typeOfFloorOption.value}
                label={typeOfFloorOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>
        <div className="form__row">
          <label htmlFor="typeOfFloorFloors">
            <I18n value="typeOfFloorFloors" />
          </label>
          <Input.RadioGroup
            name="typeOfFloorFloors"
            asButtonList
            canUnselect={true}
          >
            {this.props.typeOfFloorOptions.map((typeOfFloorOption, idx) => (
              <Input.Radio
                value={typeOfFloorOption.value}
                label={typeOfFloorOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>
        <div className="form__row">
          <div className="form__group">
            <div className="column column__minwidth">
              <label htmlFor="roofType">
                <I18n value="roofType" />
              </label>
              <Input.NewSelect
                name="roofType"
                placeholder="makeAChoice"
                values={this.props.roofTypeOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>
        <div className="form__row">
          <label htmlFor="roofMaterials">
            <I18n value="roofMaterials" />
          </label>
          <Input.Multi
            name="roofMaterials"
            values={this.props.roofMaterialOptions}
          />
        </div>
        <div className="form__row">
          <label htmlFor="typesOfWindowFrames">
            <I18n value="typesOfWindowFrames" />
          </label>
          <Input.Multi
            name="typesOfWindowFrames"
            values={this.props.typeOfWindowFrameOptions}
          />
        </div>
        <div className="form__row">
          <div className="form__group">
            <div className="column column__minwidth">
              <label htmlFor="maintenanceInside">
                <I18n value="maintenanceInside" />
              </label>
              <Input.NewSelect
                name="maintenanceInside"
                placeholder="makeAChoice"
                values={this.props.conditionOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column column__minwidth">
              <label htmlFor="maintenanceOutside">
                <I18n value="maintenanceOutside" />
              </label>
              <Input.NewSelect
                name="maintenanceOutside"
                placeholder="makeAChoice"
                values={this.props.conditionOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>
        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <label htmlFor="totalParkingCapacity">
                <I18n value="totalParkingCapacity" />
              </label>
              <Input.Number name="totalParkingCapacity" round />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="indoorParkingCapacity">
                <I18n value="indoorParkingCapacity" />
              </label>
              <Input.Number name="indoorParkingCapacity" round />
            </div>
          </div>
        </div>
        <div className="form__row">
          <label htmlFor="parkingFacilities">
            <I18n value="parkingFacilities" />
          </label>
          <Input.Multi
            name="parkingFacilities"
            values={this.props.parkingFacilityOptions}
          />
        </div>
        <div className="form__row">
          <label htmlFor="businessLawType">
            <I18n value="businessLawType" />
          </label>
          <Input.Multi
            name="businessLawType"
            values={this.props.typeOfBusinessLawOptions}
          />
        </div>
        <div className="form__row">
          <label htmlFor="residentialFacilities">
            <I18n value="residentialFacilities" />
          </label>
          <Input.Multi
            name="residentialFacilities"
            values={this.props.residentialFacilityOptions}
          />
        </div>

        <div className="form__row">
          <label htmlFor="pollution">
            <I18n value="pollution" />
          </label>
          <Input.Multi name="pollution" values={this.props.pollutionOptions} />
        </div>
        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </Form>
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedPropertyManagers } = this.formRef.getValues();
    linkedPropertyManagers = linkedPropertyManagers || [];

    this.formRef.update({
      linkedPropertyManagers: [...linkedPropertyManagers, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const vveValues = AssignmentUtil.setSelectedVVEOptions(
      values.vve,
      this.props.vveOptions
    );

    let peculiarities: Peculiarity[] = [
      ...values.occupancy,
      ...values.distinguishables,
      ...values.accessibility,
    ];
    const isHouseInNeedOfRefurbishment = peculiarities.includes(
      Peculiarity.NeedsRenovation
    );
    if (!!isHouseInNeedOfRefurbishment) {
      peculiarities = peculiarities.filter(
        (peculiarity) => peculiarity !== Peculiarity.NeedsRenovation
      );
    }

    let fields: Partial<ObjectAssignment> = {
      isPermanentlyInhabited: values.isPermanentlyInhabited,
      isRecreational: values.isRecreational,
      inPark: values.inPark,
      isBuildingReady: values.isBuildingReady,
      approvalMarks: values.approvalMarks,
      linkedPropertyManagers: values.linkedPropertyManagers,
      comfortQuality: values.comfortQuality,
      typeOfFloorFloors: values.typeOfFloorFloors,
      typeOfFloorGroundFloor: values.typeOfFloorGroundFloor,
      roof: {
        ...objectAssignment.roof,
        roofType: values.roofType,
        roofMaterials: values.roofMaterials,
      },
      typesOfWindowFrames: values.typesOfWindowFrames,
      maintenanceInside: {
        ...objectAssignment.maintenanceInside,
        rating: values.maintenanceInside,
      },
      maintenanceOutside: {
        ...objectAssignment.maintenanceOutside,
        rating: values.maintenanceOutside,
      },
      totalParkingCapacity: values.totalParkingCapacity,
      indoorParkingCapacity: values.indoorParkingCapacity,
      residentialFacilities: values.residentialFacilities,
      parkingFacilities: values.parkingFacilities,
      businessRights: {
        ...objectAssignment.businessRights,
        businessLawType: values.businessLawType,
      },
      ...vveValues,
      peculiarities,
      isHouseInNeedOfRefurbishment,
      pollution: values.pollution,
    };

    let offer = objectAssignment.forSale
      ? objectAssignment.saleOffer
      : objectAssignment.rentOffer;
    offer = {
      ...offer,
      furnishing: values.furnishing,
    };

    if (objectAssignment.forSale) {
      fields = {
        ...fields,
        saleOffer: offer,
      };
    } else {
      fields = {
        ...fields,
        rentOffer: offer,
      };
    }

    this.props.onChange(fields);
  }
}
