import { Invoice, InvoiceType } from "@haywork/api/kolibri";
import { Input, SwitchLabelPosition } from "@haywork/modules/form";
import { InvoicePrintContainerProps } from "@haywork/modules/invoice";
import { ResourceText } from "@haywork/modules/shared";
import { Ui, UiOptionListAlignment } from "@haywork/modules/ui";
import { ModalPortal } from "@haywork/portals";
import first from "lodash-es/first";
import * as React from "react";
import { Fragment } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./print.component.scss");

export type InvoicePrintComponentProps = {
  invoice: Invoice;
  visible: boolean;
  onClose: () => void;
};
type State = {
  printReady: boolean;
  pageCount: number;
  template: string;
  useLetterHead: boolean;
  urlLetterheadPaperFirstPage: string;
  urlLetterheadPaperFollowingPages: string;
  statusText: string;
};
type Props = InvoicePrintComponentProps & InvoicePrintContainerProps;
const FixedModalPortal = ModalPortal as any;
@CSSModules(styles, { allowMultiple: true })
export class InvoicePrintComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      printReady: false,
      pageCount: 0,
      template: "",
      useLetterHead: this.props.useLetterHead,
      urlLetterheadPaperFirstPage: !!this.props.useLetterHead
        ? this.props.urlLetterheadPaperFirstPage
        : null,
      urlLetterheadPaperFollowingPages: !!this.props.useLetterHead
        ? this.props.urlLetterheadPaperFollowingPages
        : null,
      statusText: "invoicePrintLoading",
    };

    this.bindReportingInstance = this.bindReportingInstance.bind(this);
    this.setTemplate = this.setTemplate.bind(this);
    this.onTemplateChangeHandler = this.onTemplateChangeHandler.bind(this);
    this.onUseLetterHeadChangeHandler =
      this.onUseLetterHeadChangeHandler.bind(this);
    this.onExportChangeHandler = this.onExportChangeHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.onZoomOutClickHandler = this.onZoomOutClickHandler.bind(this);
    this.onZoomInClickHandler = this.onZoomInClickHandler.bind(this);
    this.onPreviousPageClickHandler =
      this.onPreviousPageClickHandler.bind(this);
    this.onNextPageClickHandler = this.onNextPageClickHandler.bind(this);
    this.onPrintClickHandler = this.onPrintClickHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    this.setTemplate();

    const {
      useLetterHead,
      urlLetterheadPaperFirstPage,
      urlLetterheadPaperFollowingPages,
    } = this.props;

    if (prevProps.useLetterHead !== useLetterHead) {
      this.setState({
        useLetterHead,
      });
    }

    if (
      prevProps.useLetterHead !== useLetterHead &&
      (!!urlLetterheadPaperFirstPage || !!urlLetterheadPaperFollowingPages)
    ) {
      this.setState({
        urlLetterheadPaperFirstPage,
        urlLetterheadPaperFollowingPages,
      });
    }
  }

  public componentDidMount() {
    this.setTemplate();
  }

  public render() {
    if (!this.props.visible) return null;

    const { displayName } = this.props.invoice;
    const { key, values } = !!displayName
      ? { key: "printInvoiceWithName", values: { displayName } }
      : { key: "printInvoice", values: {} };

    return (
      <FixedModalPortal>
        <div styleName="report__wrapper">
          <div styleName="report">
            <div styleName="report__header">
              <div styleName="path">
                <ResourceText resourceKey={key} values={values} asHtml />
              </div>
              <div styleName="close" onClick={this.props.onClose}>
                <i className="fal fa-times" />
              </div>
            </div>

            <div styleName="report__actions">
              <div styleName="left">
                {/* Select template */}
                <div styleName="element">
                  <label htmlFor="template">
                    <ResourceText resourceKey="template" />
                  </label>
                  <Input.NewSelect
                    name="template"
                    values={this.props.printTemplates}
                    value={this.state.template}
                    displayProp="displayName"
                    valuesProp="key"
                    onChange={this.onTemplateChangeHandler}
                  />
                </div>

                {/* Show/hide letterheads */}
                <div styleName="element">
                  <Input.Switch
                    name="useLetterHead"
                    off={false}
                    on={true}
                    value={this.state.useLetterHead}
                    label="useLetterHead"
                    labelPosition={SwitchLabelPosition.Pre}
                    onChange={this.onUseLetterHeadChangeHandler}
                    disabled={!this.state.printReady}
                  />
                </div>
              </div>
              <div styleName="right">
                <Ui.OptionList
                  items={this.props.printExportOptions}
                  displayPath="localizedName"
                  valuePath="name"
                  triggerValue="exportInvoice"
                  icon="fal fa-download"
                  alignment={UiOptionListAlignment.Right}
                  onChange={this.onExportChangeHandler}
                />
              </div>

              {!this.state.printReady && (
                <div styleName="status">
                  <ResourceText resourceKey={this.state.statusText} />
                </div>
              )}
            </div>

            <div styleName="report__body" ref={this.bindRef} />

            <div styleName="report__footer">
              <div styleName="left">
                <button
                  styleName="footer-action"
                  onClick={this.onZoomOutClickHandler}
                  disabled={!this.state.printReady}
                >
                  <i className="fal fa-search-minus" />
                </button>

                <button
                  styleName="footer-action"
                  onClick={this.onZoomInClickHandler}
                  disabled={!this.state.printReady}
                >
                  <i className="fal fa-search-plus" />
                </button>

                {this.state.pageCount > 1 && (
                  <Fragment>
                    <div styleName="divider" />

                    <button
                      styleName="footer-action"
                      onClick={this.onPreviousPageClickHandler}
                      disabled={!this.state.printReady}
                    >
                      <i className="fal fa-chevron-left" />
                    </button>

                    <button
                      styleName="footer-action"
                      onClick={this.onNextPageClickHandler}
                      disabled={!this.state.printReady}
                    >
                      <i className="fal fa-chevron-right" />
                    </button>
                  </Fragment>
                )}
              </div>
              <div styleName="right">
                <button
                  className="btn btn-success"
                  onClick={this.onPrintClickHandler}
                  disabled={!this.state.printReady}
                >
                  <ResourceText resourceKey="print" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </FixedModalPortal>
    );
  }

  private onTemplateChangeHandler(template: string) {
    if (template === this.state.template) return;
    this.setState({ template });

    const { urlLetterheadPaperFirstPage, urlLetterheadPaperFollowingPages } =
      this.state;

    $(this.ref)
      .data("telerik_ReportViewer")
      .reportSource({
        report: template,
        parameters: {
          rootEntityIds: [this.props.invoice.id],
          urlLetterheadPaperFirstPage,
          urlLetterheadPaperFollowingPages,
        },
      })
      .refreshReport();
  }

  private onUseLetterHeadChangeHandler(useLetterHead: boolean) {
    if (useLetterHead === this.state.useLetterHead) return;

    const urlLetterheadPaperFirstPage = !!useLetterHead
      ? this.props.urlLetterheadPaperFirstPage
      : null;
    const urlLetterheadPaperFollowingPages = !!useLetterHead
      ? this.props.urlLetterheadPaperFollowingPages
      : null;

    this.setState({
      useLetterHead,
      urlLetterheadPaperFirstPage,
      urlLetterheadPaperFollowingPages,
    });

    $(this.ref)
      .data("telerik_ReportViewer")
      .reportSource({
        report: this.state.template,
        parameters: {
          rootEntityIds: [this.props.invoice.id],
          urlLetterheadPaperFirstPage,
          urlLetterheadPaperFollowingPages,
        },
      })
      .refreshReport();
  }

  private onExportChangeHandler(value) {
    if (!value || !this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.export.exec(value);
  }

  private onPrintClickHandler() {
    if (!this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.print.exec();
  }

  private onZoomInClickHandler() {
    if (!this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.zoomIn.exec();
  }

  private onZoomOutClickHandler() {
    if (!this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.zoomOut.exec();
  }

  private onPreviousPageClickHandler() {
    if (!this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.goToPrevPage.exec();
  }

  private onNextPageClickHandler() {
    if (!this.state.printReady) return;
    $(this.ref).data("telerik_ReportViewer").commands.goToNextPage.exec();
  }

  private bindRef(ref: HTMLDivElement) {
    if (!this.ref || ref) {
      this.ref = ref;
    }
    this.bindReportingInstance();
  }

  private bindReportingInstance() {
    const { template, useLetterHead } = this.state;
    let { urlLetterheadPaperFirstPage, urlLetterheadPaperFollowingPages } =
      this.state;

    if (!useLetterHead) {
      urlLetterheadPaperFirstPage = null;
      urlLetterheadPaperFollowingPages = null;
    }

    $(this.ref)["telerik_ReportViewer"]({
      serviceUrl: `${this.props.host}/${this.props.apiVersion}/${this.props.realEstateAgencyId}/Reports`,
      templateUrl: "/assets/kendo/templates/ReportViewerKolibri.html",
      authenticationToken: this.props.token,
      reportSource: {
        report: template,
        parameters: {
          rootEntityIds: [this.props.invoice.id],
          urlLetterheadPaperFirstPage,
          urlLetterheadPaperFollowingPages,
        },
      },
      viewMode: "PRINT_PREVIEW",
      printMode: "FORCE_PDF_FILE",
      pageReady: () => {
        const pageCount = !!$(this.ref).data("telerik_ReportViewer")
          ? $(this.ref).data("telerik_ReportViewer").pageCount()
          : 0;
        this.setState({ printReady: true, pageCount });
      },
      renderingEnd: () => {
        const pageCount = !!$(this.ref).data("telerik_ReportViewer")
          ? $(this.ref).data("telerik_ReportViewer").pageCount()
          : 0;
        this.setState({ printReady: true, pageCount });
      },
      printBegin: () => {
        this.setState({
          printReady: false,
          statusText: "invoicePrintPreperation",
        });
      },
      printEnd: () => {
        this.setState({ printReady: true });
      },
      renderingBegin: () => {
        this.setState({ printReady: false, statusText: "invoicePrintLoading" });
      },
    });
  }

  private setTemplate() {
    if (
      !!this.state.template ||
      !this.props.invoice ||
      !this.props.financialAdministration ||
      !this.props.printTemplates ||
      !this.props.printTemplates.length
    )
      return;

    const { invoiceType } = this.props.invoice;
    const { defaultBusinessInvoiceTemplate, defaultConsumerInvoiceTemplate } =
      this.props.financialAdministration;
    const fallbackTemplate = first(this.props.printTemplates).key;
    const template =
      invoiceType === InvoiceType.BusinessToBusinessInvoice
        ? defaultBusinessInvoiceTemplate || fallbackTemplate
        : defaultConsumerInvoiceTemplate || fallbackTemplate;

    this.setState({ template });
  }
}
