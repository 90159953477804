import {
  AcquisitionAssignment,
  AssignmentPhaseOption,
  RealEstateGroupOption,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, EditableActions } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { AcquisitionEditScreenProps } from "../edit/edit";
import { AcquisitionDetailEditScreen } from "./edit-detail";

type StateProps = {
  acquisition: AcquisitionAssignment;
  employees: RelationSnapShot[];
  offices: RelationSnapShot[];
  realEstateGroups: RealEstateGroupOption[];
  assignmentPhases: AssignmentPhaseOption[];
};
type DispatchProps = {
  updateCache: (componentState: AcquisitionAssignment, path: string) => void;
  navigate: (path: string) => RouterAction;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionEditScreenProps,
  AppState
> = (state) => {
  const { employees, offices } = state.account;
  const { realEstateGroups, assignmentPhases } = state.main.mastertable.kolibri;
  const { currentComponentState: acquisition } = state.editable;

  return {
    acquisition,
    employees,
    offices,
    realEstateGroups,
    assignmentPhases,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionEditScreenProps
> = (dispatch) => ({
  updateCache: (componentState: AcquisitionAssignment, path: string) =>
    dispatch(EditableActions.updateComponentState({ componentState, path })),
  navigate: (path: string) => dispatch(push(path)),
});

export type AcquisitionEditDetailScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionEditDetailScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailEditScreen);
