import { createContext } from "react";
import { Channel as MailChannel } from "@haywork/api/mail";
import { Channel as VoipChannel } from "@haywork/api/voip";
import { Channel as KolibriChannel } from "@haywork/api/kolibri";
import * as Ably from "ably";
import noop from "lodash-es/noop";

export type LiveDataContextProps = {
  mailRealtime: Ably.Realtime | null;
  voipRealtime: Ably.Realtime | null;
  kolibriRealtime: Ably.Realtime | null;
  eventCenterRealtime: Ably.Realtime | null;
  mailChannels: MailChannel[];
  voipChannels: VoipChannel[];
  kolibriChannels: KolibriChannel[];
  eventCenterChannels: {
    companyChannel: string | null;
    personalChannel: string | null;
    presenceChannel: string | null;
  };
  refreshMailRealtime: () => void;
};

export const LiveDataContext = createContext<LiveDataContextProps>({
  mailRealtime: null,
  voipRealtime: null,
  kolibriRealtime: null,
  eventCenterRealtime: null,
  mailChannels: [],
  voipChannels: [],
  kolibriChannels: [],
  eventCenterChannels: {
    companyChannel: null,
    personalChannel: null,
    presenceChannel: null
  },
  refreshMailRealtime: noop
});
export const LiveDataContextProvider = LiveDataContext.Provider;
export const LiveDataContextConsumer = LiveDataContext.Consumer;
