import { EntityListStatusTypes } from ".";

export enum ActionType {
  SetListStatus = "EventCenter.EntityList.SetListStatus",
  ClearListStatus = "EventCenter.EntityList.ClearListStatus"
}

type SetListStatus = {
  type: ActionType.SetListStatus;
  key: string;
};
const setListStatus = (key: EntityListStatusTypes) => ({
  type: ActionType.SetListStatus,
  key
});

type ClearListStatus = {
  type: ActionType.ClearListStatus;
  key: string;
};
const clearListStatus = (key: EntityListStatusTypes) => ({
  type: ActionType.ClearListStatus,
  key
});

export type ActionTypes = SetListStatus | ClearListStatus;
export const actions = {
  setListStatus,
  clearListStatus
};
