import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import {
  Form,
  FormControls,
  Input,
  FormReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import {
  ObjectAssignment,
  BusinessToTakeOver,
  BusinessSpecification,
  TakeOverSpecification,
} from "@haywork/api/kolibri";
import { FormattedNumber } from "react-intl";

const styles = require("./form.component.scss");

interface AssignmentEditFinancialOneTimeComponentProps {
  objectAssignment: ObjectAssignment;
  onChange: (objectAssignment: ObjectAssignment) => void;
}
interface AssignmentEditFinancialOneTimeComponentState {
  totalOneTime: number;
}
interface MappedOneTimeCosts {
  personelCosts: number;
  goodwillCosts: number;
  inventoryCosts: number;
  total: number;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialOneTimeComponent extends React.Component<
  AssignmentEditFinancialOneTimeComponentProps,
  AssignmentEditFinancialOneTimeComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    const costs = this.mapBusinessListToValues(
      this.props.objectAssignment.listOfBusiness
    );

    this.state = {
      totalOneTime: costs.total,
    };

    this.formControls = {
      personelCosts: { value: costs.personelCosts },
      goodwillCosts: { value: costs.goodwillCosts },
      inventoryCosts: { value: costs.inventoryCosts },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <Form
        name="one-time"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
      >
        {/* Personel costs */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="personelCosts">
              <ResourceText resourceKey="personelCosts" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="personelCosts" pretty step={1000} asPrice />
            </div>
          </div>
        </div>

        {/* Goodwill costs */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="goodwillCosts">
              <ResourceText resourceKey="goodwillCosts" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="goodwillCosts" pretty step={1000} asPrice />
            </div>
          </div>
        </div>

        {/* Inventory costs */}
        <div className="form__row">
          <div styleName="inline-value">
            <label htmlFor="inventoryCosts">
              <ResourceText resourceKey="inventoryCosts" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="inventoryCosts" pretty step={1000} asPrice />
            </div>
          </div>
        </div>

        {/* Total */}
        <div styleName="total">
          <div styleName="label">
            <ResourceText resourceKey="totalOneTime" />
          </div>
          <div styleName="value">
            <FormattedNumber
              value={this.state.totalOneTime}
              style="currency"
              currency="EUR"
            />
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const listOfBusiness = this.mapValuesToBusinessList(values);
    const costs = this.mapBusinessListToValues(listOfBusiness);

    const updatedObjectAssignment = {
      ...objectAssignment,
      listOfBusiness,
    };

    this.setState({ totalOneTime: costs.total });
    this.props.onChange(updatedObjectAssignment);
  }

  private mapBusinessListToValues(
    values: BusinessToTakeOver[]
  ): MappedOneTimeCosts {
    const rawValues = values || [];
    const costs: MappedOneTimeCosts = {
      personelCosts: 0,
      goodwillCosts: 0,
      inventoryCosts: 0,
      total: 0,
    };

    rawValues.map((value) => {
      switch (value.business) {
        case BusinessSpecification.Goodwill: {
          costs.goodwillCosts =
            costs.goodwillCosts + (value.takeOverAmount || 0);
          break;
        }
        case BusinessSpecification.Inventory: {
          costs.inventoryCosts =
            costs.inventoryCosts + (value.takeOverAmount || 0);
          break;
        }
        case BusinessSpecification.Staff: {
          costs.personelCosts =
            costs.personelCosts + (value.takeOverAmount || 0);
          break;
        }
        default:
          break;
      }
    });

    // Set total
    costs.total =
      costs.goodwillCosts + costs.inventoryCosts + costs.personelCosts;

    return costs;
  }

  private mapValuesToBusinessList(
    values: FormReturnValue
  ): BusinessToTakeOver[] {
    const listOfBusiness: BusinessToTakeOver[] = [];

    for (const key in values) {
      switch (key) {
        case "goodwillCosts": {
          const takeOverAmount = parseFloat(values[key]);
          if (takeOverAmount > 0) {
            listOfBusiness.push({
              business: BusinessSpecification.Goodwill,
              takeOver: null,
              takeOverAmount,
            });
          }
          break;
        }
        case "inventoryCosts": {
          const takeOverAmount = parseFloat(values[key]);
          if (takeOverAmount > 0) {
            listOfBusiness.push({
              business: BusinessSpecification.Inventory,
              takeOver: null,
              takeOverAmount,
            });
          }
          break;
        }
        case "personelCosts": {
          const takeOverAmount = parseFloat(values[key]);
          if (takeOverAmount > 0) {
            listOfBusiness.push({
              business: BusinessSpecification.Staff,
              takeOver: null,
              takeOverAmount,
            });
          }
          break;
        }
        default:
          break;
      }
    }

    return listOfBusiness;
  }
}
