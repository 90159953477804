import { REDUX } from "@haywork/constants";
import * as ActionType from "./statistics.types";

const setCompanyStatistics = (payload: ActionType.Statistics) => ({
  type: REDUX.STATISTICS.SET_COMPANY_STATISTICS,
  ...payload
});

const setEmployeeStatistics = (payload: ActionType.Statistics) => ({
  type: REDUX.STATISTICS.SET_EMPLOYEE_STATISTICS,
  ...payload
});

const setCompanyStatisticsStatus = (statisticsStatus: string) => ({
  type: REDUX.STATISTICS.SET_COMPANY_STATISTICS_STATUS,
  statisticsStatus
});

const setEmployeeStatisticsStatus = (statisticsStatus: string) => ({
  type: REDUX.STATISTICS.SET_EMPLOYEE_STATISTICS_STATUS,
  statisticsStatus
});

export const StatisticsActions = {
  setCompanyStatistics,
  setEmployeeStatistics,
  setCompanyStatisticsStatus,
  setEmployeeStatisticsStatus
};
