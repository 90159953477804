import {
  FinancialAdministration,
  FinancialAdministrationProduct,
  ObjectAssignment,
  ProductOrder,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import { AssignmentEditMarketingBudgetContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { AddFinancialAdministrationProductModalContainer } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";
import isNumber from "lodash-es/isNumber";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import { v4 as uuid } from "uuid";
import { ProductOrderComponent } from "./product-order.component";

const styles = require("./marketing-budget.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Budgets {
  internalBudget: number;
  customerBudget: number;
}
export interface AssignmentEditMarketingBudgetComponentProps {}
interface State {
  internalBudget: number;
  customerBudget: number;
  budgetTotal: number;
  internalSpent: number;
  customerSpent: number;
  spentTotal: number;
  internalSaldo: number;
  customerSaldo: number;
  saldoTotal: number;
  showAddProductModal: boolean;
}
type Props = AssignmentEditMarketingBudgetComponentProps &
  AssignmentEditMarketingBudgetContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingBudgetComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;
  private topFormRef: FormReference;

  constructor(props) {
    super(props);

    const state = this.getCalculatedState(
      this.props.objectAssignment,
      this.props.internalCosts,
      this.props.customerCosts
    );

    this.state = {
      ...state,
      showAddProductModal: false,
    };

    this.formControls = {
      internalBudget: { value: state.internalBudget },
      customerBudget: { value: state.customerBudget },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderProductOrderForm = this.renderProductOrderForm.bind(this);
    this.onProductOrderSubmitHandler =
      this.onProductOrderSubmitHandler.bind(this);
    this.bindMainFormRef = this.bindMainFormRef.bind(this);
    this.onProductOrderChangeHandler = debounce(
      this.onProductOrderChangeHandler.bind(this),
      50
    );
    this.onProductModalCloseHandler =
      this.onProductModalCloseHandler.bind(this);
    this.onProductModalChangeHandler =
      this.onProductModalChangeHandler.bind(this);
    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
    this.onProductModalCloseHandler =
      this.onProductModalCloseHandler.bind(this);
    this.onProductAddedHandler = this.onProductAddedHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.topFormRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const state = this.getCalculatedState(
        this.props.objectAssignment,
        this.props.internalCosts,
        this.props.customerCosts
      );

      this.setState({
        ...state,
      });

      this.topFormRef.update(
        {
          internalBudget: state.internalBudget,
          customerBudget: state.customerBudget,
        },
        true
      );
    }
  }

  public render() {
    const {
      budgetTotal,
      internalSpent,
      customerSpent,
      spentTotal,
      internalSaldo,
      customerSaldo,
      saldoTotal,
    } = this.state;
    const productOrders: ProductOrder[] =
      get(this.props.objectAssignment, "productOrders") || [];
    const listStyle = classNames("list", { empty: productOrders.length === 0 });

    return (
      <div styleName="budget">
        <div className="container-fluid">
          <h1>
            <I18n value="marketingBudget" />
          </h1>
          <Form
            name="budget"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.topFormRef = form)}
          >
            <div styleName="form__columns">
              {/* Budget */}
              <div styleName="form__column">
                <h2>
                  <I18n value="assignmentBudgetTitle" />
                </h2>
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="internalBudget">
                        <I18n value="internalBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Number name="internalBudget" pretty asPrice />
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="customerBudget">
                        <I18n value="customerBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Number name="customerBudget" pretty asPrice />
                    </div>
                  </div>
                </div>
                <div styleName="total">
                  <div styleName="title">
                    <I18n value="total" />
                  </div>
                  <div className="column text-right" styleName="value">
                    <FormattedNumber
                      value={budgetTotal}
                      style="currency"
                      currency="EUR"
                    />{" "}
                    <I18n value="exVat" />
                  </div>
                </div>
              </div>

              {/* Spent */}
              <div styleName="form__column">
                <h2>
                  <I18n value="assignmentSpentTitle" />
                </h2>
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label>
                        <I18n value="internalBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      {this.renderValueColumn(internalSpent)}
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label>
                        <I18n value="customerBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      {this.renderValueColumn(customerSpent)}
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="title">
                    <I18n value="total" />
                  </div>
                  {this.renderTotalValue(spentTotal)}
                </div>
              </div>

              {/* Saldo */}
              <div styleName="form__column">
                <h2>
                  <I18n value="assignmentSaldoTitle" />
                </h2>
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label>
                        <I18n value="internalBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      {this.renderValueColumn(internalSaldo)}
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label>
                        <I18n value="customerBudget" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      {this.renderValueColumn(customerSaldo)}
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="title">
                    <I18n value="total" />
                  </div>
                  {this.renderTotalValue(saldoTotal)}
                </div>
              </div>
            </div>
          </Form>

          <div styleName="add-expense">
            <h1>
              <I18n value="addExpense" />
            </h1>
            {this.renderProductOrderForm()}
          </div>
        </div>

        {/* List */}
        <div styleName={listStyle}>
          <div styleName="list__header">
            <I18n value="expenseOverviewTitle" />
          </div>
          {productOrders.length === 0 && (
            <Ui.EmptyState
              type={UiEmptyStateType.List}
              stickman={UiEmptyStateStickMan.NoRelations}
              title="emptyStateBudgetListTitle"
              subtitle="emptyStateBudgetListSubtitle"
            />
          )}
          {productOrders.map((productOrder, idx) => (
            <ErrorBoundary key={productOrder.id}>
              <ProductOrderComponent
                productOrder={productOrder}
                products={this.props.products}
                zebra={idx % 2 === 0}
                onProductOrderChange={this.onProductOrderChangeHandler}
                onRemove={this.onRemoveClickHandler}
                onNavigate={this.props.navigate}
              />
            </ErrorBoundary>
          ))}
        </div>

        {/* Add new product modal */}
        <AddFinancialAdministrationProductModalContainer
          visible={this.state.showAddProductModal}
          onClose={this.onProductModalCloseHandler}
          onProductAdded={this.onProductAddedHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (!isEqual(nextProps.objectAssignment, this.props.objectAssignment)) {
      const state = this.getCalculatedState(
        nextProps.objectAssignment,
        nextProps.internalCosts,
        nextProps.customerCosts
      );
      this.setState(state);
    }
  }

  private getBudgets(objectAssignment: ObjectAssignment): Budgets {
    const { saleOffer, rentOffer, forSale } = objectAssignment;
    const budget = { internalBudget: 0, customerBudget: 0 };

    if (forSale) {
      budget.internalBudget = parseFloat(value(saleOffer, "internalBudget", 0));
      budget.customerBudget = parseFloat(value(saleOffer, "customerBudget", 0));
    } else {
      budget.internalBudget = parseFloat(value(rentOffer, "internalBudget", 0));
      budget.customerBudget = parseFloat(value(rentOffer, "customerBudget", 0));
    }

    return budget;
  }

  private getCalculatedState(
    objectAssignment: ObjectAssignment,
    internalCosts: number,
    customerCosts: number
  ): any {
    const { internalBudget, customerBudget } =
      this.getBudgets(objectAssignment);
    const internalCostsValue =
      parseFloat((internalCosts || "").toString()) || 0;
    const customerCostsValue =
      parseFloat((customerCosts || "").toString()) || 0;

    return {
      internalBudget,
      customerBudget,
      budgetTotal: internalBudget + customerBudget,
      internalSpent: internalCostsValue,
      customerSpent: customerCostsValue,
      spentTotal: internalCostsValue + customerCostsValue,
      internalSaldo: internalBudget - internalCostsValue,
      customerSaldo: customerBudget - customerCostsValue,
      saldoTotal:
        internalBudget -
        internalCostsValue +
        (customerBudget - customerCostsValue),
    };
  }

  private renderValueColumn(value: number): React.ReactElement<HTMLDivElement> {
    const columnStyle = classNames("column__value", { negative: value < 0 });

    return (
      <div styleName={columnStyle}>
        <FormattedNumber value={value} style="currency" currency="EUR" />
      </div>
    );
  }

  private renderTotalValue(value: number): React.ReactElement<HTMLDivElement> {
    const valueStyle = classNames("value", { negative: value < 0 });

    return (
      <div
        className="column"
        styleName={valueStyle}
        id="totalSaldoMarketingBudget"
      >
        <FormattedNumber value={value} style="currency" currency="EUR" />{" "}
        <I18n value="exVat" />
      </div>
    );
  }

  private renderProductOrderForm(): React.ReactElement<HTMLDivElement> {
    const formControls: FormControls = {
      dateTime: { value: new Date() },
      description: { value: "" },
      unitPriceNet: { value: "" },
      chargeToCustomerBudget: { value: false },
      product: {
        value: "",
        onChange: (ref) => {
          if (ref.value) {
            return {
              description: ref.value.description,
              unitPriceNet: ref.value.unitPriceNet,
            };
          }
        },
      },
    };

    return (
      <div styleName="list__item">
        <div styleName="form">
          <Form
            name="product-order"
            formControls={formControls}
            onSubmit={this.onProductOrderSubmitHandler}
            form={(ref) => this.bindMainFormRef(ref)}
          >
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="select">
                  <label htmlFor="product">
                    <I18n value="marketingBudget.label.product" />
                  </label>
                  <Input.ExtendedSelect
                    name="product"
                    values={this.props.products}
                    displayPath="descriptionShort"
                    onAdd={() => this.setState({ showAddProductModal: true })}
                    addText="addNewProduct"
                    placeholder="marketingBudget.placeholder.product"
                    compareFn={(i, r) => i.id === r}
                    valueCompareFn={(i, r) => i.id === r.id}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="unitPriceNet">
                    <I18n value="marketingBudget.label.unitPriceNet" />
                  </label>
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="unitPriceNet"
                      step={50}
                      pretty
                      asPrice
                    />
                    <div className="post small">
                      <I18n value="exVat" />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="dateTime">
                    <I18n value="marketingBudget.label.dateTime" />
                  </label>
                  <Input.Datepicker name="dateTime" />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="chargeToCustomerBudget">
                    <I18n value="marketingBudget.label.chargeToCustomerBudget" />
                  </label>
                  <Input.RadioGroup name="chargeToCustomerBudget" asButtonList>
                    <Input.Radio
                      value={false}
                      label="marketingBudget.label.internalBudget"
                    />
                    <Input.Radio
                      value={true}
                      label="marketingBudget.label.customerBudget"
                    />
                  </Input.RadioGroup>
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group stretch">
                <div className="column fit">
                  <label htmlFor="description">
                    <I18n value="marketingBudget.label.description" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Textarea name="description" autoSize />
                </div>
                <div className="column__spacer" />
                <div className="column fit">
                  <Button
                    type="submit"
                    category="success"
                    label="marketingBudget.action.add"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  private bindMainFormRef(ref: FormReference) {
    this.formRef = ref;
  }

  private onProductModalCloseHandler() {
    this.setState({ showAddProductModal: false });
  }

  private onProductModalChangeHandler(
    financialAdministration: FinancialAdministration
  ) {
    this.props.updateFinancialAdministration(financialAdministration);
  }

  private onProductAddedHandler(product: FinancialAdministrationProduct) {
    this.formRef.update({
      product,
      description: product.description,
      unitPriceNet: product.unitPriceNet,
    });

    this.setState({
      showAddProductModal: false,
    });
  }

  private onRemoveClickHandler(id: string) {
    const { objectAssignment, currentComponentState } = this.props;
    const productOrders = objectAssignment.productOrders.filter(
      (p) => p.id !== id
    );
    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      productOrders,
    };
    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment, currentComponentState } = this.props;
    let updatedObjectAssignment;

    if (objectAssignment.forSale) {
      updatedObjectAssignment = {
        ...objectAssignment,
        saleOffer: {
          ...objectAssignment.saleOffer,
          customerBudget: parseFloat(values.customerBudget),
          internalBudget: parseFloat(values.internalBudget),
        },
      };
    } else {
      updatedObjectAssignment = {
        ...objectAssignment,
        rentOffer: {
          ...objectAssignment.rentOffer,
          customerBudget: parseFloat(values.customerBudget),
          internalBudget: parseFloat(values.internalBudget),
        },
      };
    }

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
  }

  private onProductOrderSubmitHandler(values: FormReturnValue) {
    const {
      dateTime,
      description,
      unitPriceNet,
      chargeToCustomerBudget,
      product,
    } = values;
    if (!product) return;
    const { objectAssignment, currentComponentState } = this.props;

    const productOrder: ProductOrder = {
      dateTime,
      description,
      unitPriceNet: parseFloat(unitPriceNet),
      chargeToCustomerBudget,
      financialAdministrationProductId: product.id,
      id: uuid(),
    };

    const productOrders = objectAssignment.productOrders || [];

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      productOrders: [productOrder, ...productOrders],
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
    if (this.formRef) {
      this.formRef.update({
        dateTime: new Date(),
        description: "",
        unitPriceNet: 0,
        chargeToCustomerBudget: false,
        product: "",
      });
    }
  }

  private onProductOrderChangeHandler(values: FormReturnValue, id?: string) {
    if (!id) return;
    const { objectAssignment, currentComponentState } = this.props;
    const productOrders = objectAssignment.productOrders.map((productOrder) => {
      if (productOrder.id === id) {
        const {
          dateTime,
          description,
          unitPriceNet,
          chargeToCustomerBudget,
          financialAdministrationProductId,
        } = values;
        return {
          ...productOrder,
          dateTime,
          description,
          unitPriceNet: parseFloat(unitPriceNet),
          chargeToCustomerBudget,
          financialAdministrationProductId: isNumber(
            financialAdministrationProductId
          )
            ? financialAdministrationProductId
            : financialAdministrationProductId.id,
        };
      }
      return productOrder;
    });

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      productOrders,
    };
    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
  }
}
