import { Action } from "redux";
import differenceBy from "lodash-es/differenceBy";

import { REDUX, REQUEST } from "@haywork/constants";
import {
  EventNotificationsFilter,
  ExtendedEventNotification
} from "@haywork/util";

import * as ActionType from "./notifications.types";

export interface NotificationsState {
  notifications: ExtendedEventNotification[];
  notificationsStatus: string;
  personalNotificationTotalCount: number;
  generalNotificationTotalCount: number;
  archivedNotificationTotalCount: number;
  numberOfPersonalUnprocessedNotifications: number;
  numberOfGeneralUnprocessedNotifications: number;
  currentFilter: EventNotificationsFilter;
  forceReload: boolean;
}

const INITIAL_STATE: NotificationsState = {
  notifications: [],
  notificationsStatus: REQUEST.IDLE,
  personalNotificationTotalCount: -1,
  generalNotificationTotalCount: -1,
  archivedNotificationTotalCount: -1,
  numberOfPersonalUnprocessedNotifications: 0,
  numberOfGeneralUnprocessedNotifications: 0,
  currentFilter: EventNotificationsFilter.Personal,
  forceReload: false
};

export const notificationsReducer = (
  state: NotificationsState = INITIAL_STATE,
  action: Action
): NotificationsState => {
  switch (action.type) {
    case REDUX.EVENTCENTER.SET_NOTIFCATIONS: {
      const {
        notifications: appendables,
        totalCount,
        currentFilter,
        forceAppend
      } = <ActionType.Notifications>action;

      const personalNotificationTotalCount =
        currentFilter === EventNotificationsFilter.Personal
          ? totalCount
          : state.personalNotificationTotalCount;
      const generalNotificationTotalCount =
        currentFilter === EventNotificationsFilter.General
          ? totalCount
          : state.generalNotificationTotalCount;
      const archivedNotificationTotalCount =
        currentFilter === EventNotificationsFilter.Processed
          ? totalCount
          : state.archivedNotificationTotalCount;
      let notifications = differenceBy(
        state.notifications,
        appendables,
        (notification) => notification.id
      );

      notifications = !!forceAppend
        ? [...appendables, ...notifications]
        : [...notifications, ...appendables];

      return {
        ...state,
        notifications,
        notificationsStatus: REQUEST.SUCCESS,
        personalNotificationTotalCount,
        generalNotificationTotalCount,
        archivedNotificationTotalCount
      };
    }
    case REDUX.EVENTCENTER.SET_NOTIFCATIONS_STATUS: {
      const { notificationsStatus } = <ActionType.NotificationsStatus>action;

      return {
        ...state,
        notificationsStatus
      };
    }
    case REDUX.EVENTCENTER.SET_NOTIFICATIONS_FILTER: {
      const { currentFilter } = <ActionType.CurrentFilter>action;

      return {
        ...state,
        currentFilter
      };
    }
    case REDUX.EVENTCENTER.SET_UNREAD_COUNT: {
      const {
        numberOfGeneralUnprocessedNotifications,
        numberOfPersonalUnprocessedNotifications
      } = <ActionType.UnreadCount>action;

      return {
        ...state,
        numberOfGeneralUnprocessedNotifications,
        numberOfPersonalUnprocessedNotifications
      };
    }
    case REDUX.EVENTCENTER.UPDATE_NOTIFICATION: {
      const { notification } = <ActionType.SingleNotification>action;

      let numberOfGeneralUnprocessedNotifications =
        state.numberOfGeneralUnprocessedNotifications;
      let numberOfPersonalUnprocessedNotifications =
        state.numberOfPersonalUnprocessedNotifications;

      const notifications = state.notifications.map((original) => {
        if (original.id === notification.id) {
          switch (original.filter) {
            case EventNotificationsFilter.Personal:
              if (notification.filter === EventNotificationsFilter.Processed) {
                numberOfPersonalUnprocessedNotifications =
                  numberOfPersonalUnprocessedNotifications - 1;
              }
              break;
            case EventNotificationsFilter.General:
              if (notification.filter === EventNotificationsFilter.Processed) {
                numberOfGeneralUnprocessedNotifications =
                  numberOfGeneralUnprocessedNotifications - 1;
              }
              break;
            case EventNotificationsFilter.Processed:
              switch (notification.filter) {
                case EventNotificationsFilter.Personal:
                  numberOfPersonalUnprocessedNotifications =
                    numberOfPersonalUnprocessedNotifications + 1;
                  break;
                case EventNotificationsFilter.General:
                  numberOfGeneralUnprocessedNotifications =
                    numberOfGeneralUnprocessedNotifications + 1;
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          return notification;
        }
        return original;
      });

      return {
        ...state,
        notifications,
        numberOfGeneralUnprocessedNotifications,
        numberOfPersonalUnprocessedNotifications
      };
    }
    case REDUX.EVENTCENTER.TOGGLE_ALL_IS_READ: {
      const notifications = state.notifications.map((notification) => {
        return { ...notification, isRead: true };
      });

      return {
        ...state,
        notifications,
        numberOfGeneralUnprocessedNotifications: 0,
        numberOfPersonalUnprocessedNotifications: 0
      };
    }
    case REDUX.EVENTCENTER.PROCESS_ALL: {
      const { archivedBy } = <ActionType.ArchivedBy>action;
      const notifications = state.notifications.map((notification) => {
        const linkedEvent = {
          ...notification.linkedEvent,
          archivedDate: notification.linkedEvent.archivedDate || new Date(),
          archivedBy: notification.linkedEvent.archivedBy || archivedBy
        };
        return {
          ...notification,
          filter: EventNotificationsFilter.Processed,
          isRead: true,
          linkedEvent
        };
      });

      return {
        ...state,
        notifications,
        numberOfGeneralUnprocessedNotifications: 0,
        numberOfPersonalUnprocessedNotifications: 0
      };
    }
    case REDUX.EVENTCENTER.FORCE_RELOAD: {
      const { forceReload } = <ActionType.ForceReload>action;

      return {
        ...state,
        forceReload
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
