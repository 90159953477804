import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import {
  EmployeeRole,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import head from "lodash-es/head";

const accountSelector = (state: AppState) => state.account;

export const linkedEmployee = createSelector(accountSelector, (settings) => {
  const { currentRealestateAgency, employee, employees } = settings;
  if (currentRealestateAgency.role === EmployeeRole.Standard) {
    return {
      id: employee?.id,
      displayName: employee?.displayName,
      typeOfRelation: RelationType.Employee,
    } as LinkedRelation;
  } else {
    const firstEmployee = head(employees);
    return {
      id: firstEmployee?.id,
      displayName: firstEmployee?.displayName,
      typeOfRelation: RelationType.Employee,
    } as LinkedRelation;
  }
});
