import * as React from "react";
import { memo, FC, ReactNode, useState } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  filters?: ReactNode;
  actions?: ReactNode;
  sidebar?: ReactNode;
  sidebarClosed?: boolean;
  sidebarWide?: boolean;
};

export const WrapperComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, filters, actions, sidebar, sidebarClosed, sidebarWide }) => {
      const [sidebarVisible, setSidebarVisible] = useState(!sidebarClosed);

      return (
        <div styleName="wrapper">
          {!!sidebar && (
            <div
              styleName={classNames("sidebar", {
                visible: sidebarVisible,
                wide: sidebarWide,
              })}
            >
              {sidebar}
            </div>
          )}
          <div styleName="body">
            {(!!sidebar || !!filters || !!actions) && (
              <div styleName="body__header">
                {!!sidebar && (
                  <div
                    styleName={classNames("toggle-sidebar", {
                      visible: sidebarVisible,
                    })}
                    onClick={() => setSidebarVisible(!sidebarVisible)}
                  >
                    <Icon
                      name={sidebarVisible ? "arrow-left" : "search"}
                      containIn={32}
                      regular={!sidebarVisible}
                      light={sidebarVisible}
                      size={14}
                      color={sidebarVisible ? Colors.Gray : Colors.White}
                    />
                  </div>
                )}
                {!!filters && <div styleName="filters">{filters}</div>}
                {!filters && <div styleName="push" />}
                {!!actions && <div styleName="actions">{actions}</div>}
              </div>
            )}
            <div styleName="body__list">{children}</div>
          </div>
        </div>
      );
    }
  )
);
