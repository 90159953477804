import { BrochureBlob, BrochureType } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { AssignmentEditMarketingBrochuresContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  ConfirmComponent,
  FileUploadContainer,
  FileUploadIcon,
  Hint,
  HintAlignment,
  ResourceText,
} from "@haywork/modules/shared";
import { Redirect } from "@haywork/services";

import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
const styles = require("./marketing-brochures.component.scss");

type SortableHandleProps = {
  filename: string;
  brochureType: BrochureType;
};

const SortableTrigger = SortableHandle<SortableHandleProps>(
  ({ filename, brochureType }) => (
    <div className="preview portrait pdf">
      <div className="contents">
        <div className="filename">
          {/* {!!brochureType && (
            <>
              <I18n prefix="brochureTypes" value={brochureType.toString()} /> -{" "}
            </>
          )} */}
          {filename}
        </div>
      </div>
    </div>
  )
);

type SortableItemProps = {
  brochure: BrochureBlob;
  downloadHandler: (idx: number) => void;
  editHandler: (idx: number) => void;
  deleteHandler: (idx: number) => void;
  idx: number;
  brochureStatus: string;
  brochureId: number;
};

const SortableItem = SortableElement<SortableItemProps>(
  ({
    brochure,
    downloadHandler,
    editHandler,
    deleteHandler,
    idx,
    brochureStatus,
    brochureId,
  }) => {
    const props = { filename: brochure.fileName, brochureType: brochure.type };
    const editIconStyle = classNames(
      "fal",
      brochureStatus === REQUEST.PENDING && brochureId === brochure.id
        ? "fa-spinner-third fa-spin"
        : "fa-pencil"
    );

    return (
      <div className="file-list__item">
        <div className="meta">
          {!brochure.allowToPublish && (
            <div className="meta__item warn">
              <ResourceText resourceKey="brochureWontBePublished" />
              <Hint
                message="brochureWontBePublishedHint"
                align={HintAlignment.Bottom}
              >
                <i className="fal fa-question-circle" />
              </Hint>
            </div>
          )}
        </div>
        <div className="remove" onClick={() => deleteHandler(idx)}>
          <i className="fal fa-fw fa-times" />
        </div>
        <SortableTrigger {...props} />
        <div className="actions">
          <div
            className="action btn btn-blank"
            onClick={() => downloadHandler(idx)}
          >
            <i className="fal fa-download" />
          </div>
          {!!brochure.isEditable && (
            <div
              className="action btn btn-blank"
              onClick={() => editHandler(idx)}
            >
              <i className={editIconStyle} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

type SortableListProps = {
  brochures: BrochureBlob[];
  downloadHandler: (idx: number) => void;
  editHandler: (idx: number) => void;
  deleteHandler: (idx: number) => void;
  uploadCompleteHandler: (files: any[]) => void;
  onAddClickHandler: () => Promise<void>;
  brochureStatus: string;
  brochureId: number;
};

const SortableList = SortableContainer<SortableListProps>(
  ({
    brochures,
    downloadHandler,
    editHandler,
    deleteHandler,
    uploadCompleteHandler,
    onAddClickHandler,
    brochureStatus,
    brochureId,
  }) => {
    const addIconStyle = classNames(
      "fal",
      brochureStatus === REQUEST.PENDING && !brochureId
        ? "fa-spinner-third fa-spin"
        : "fa-plus"
    );
    const onAddResource =
      brochureStatus === REQUEST.PENDING && !brochureId
        ? "presentationBeingCreated"
        : "createPresentation";

    return (
      <div className="file-list">
        <div className="container-fluid">
          <div className="create-brochure">
            <div
              className="btn btn-primary icon-left"
              onClick={onAddClickHandler}
            >
              <i className={addIconStyle} />
              <ResourceText resourceKey={onAddResource} />
            </div>
            <div className="info">
              <ResourceText resourceKey="createPresentationInfo" asHtml />
            </div>
          </div>
          <div className="upload">
            <FileUploadContainer
              multiple
              icon={FileUploadIcon.Upload}
              title="fileUploadBrochuresTitle"
              subtitle="fileUploadBrochuresSubtitle"
              helperText="fileUploadHelperOnlyPdf"
              onCompleted={uploadCompleteHandler}
              whitelist={["application/pdf", "x-pdf"]}
            />
          </div>
          <div className="files">
            {brochures.map((brochure, idx) => {
              if (!brochure) return null;
              const props = {
                brochure,
                downloadHandler,
                editHandler,
                deleteHandler,
                idx,
                brochureId,
                brochureStatus,
              };

              return (
                <ErrorBoundary key={`item-${idx}`}>
                  <SortableItem index={idx} {...props} />
                </ErrorBoundary>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export type AssignmentEditMarketingBrochuresComponentProps = {};
interface State {
  markedForDelete: number;
}
type Props = AssignmentEditMarketingBrochuresComponentProps &
  AssignmentEditMarketingBrochuresContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingBrochuresComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      markedForDelete: null,
    };

    this.onFileDownloadClickHandler =
      this.onFileDownloadClickHandler.bind(this);
    this.onFileEditClickHandler = this.onFileEditClickHandler.bind(this);
    this.onFileDeleteClickHandler = this.onFileDeleteClickHandler.bind(this);
    this.onSortEndHandler = this.onSortEndHandler.bind(this);
    this.onUploadCompleteHandler = this.onUploadCompleteHandler.bind(this);
    this.onAddClickHandler = this.onAddClickHandler.bind(this);
    this.onConfirmCloseHandler = this.onConfirmCloseHandler.bind(this);
    this.onConfirmConfirmHandler = this.onConfirmConfirmHandler.bind(this);
  }

  public render() {
    let { brochures } = this.props.objectAssignment;
    brochures = brochures || [];

    const props = {
      brochures,
      downloadHandler: this.onFileDownloadClickHandler,
      editHandler: this.onFileEditClickHandler,
      deleteHandler: this.onFileDeleteClickHandler,
      uploadCompleteHandler: this.onUploadCompleteHandler,
      onAddClickHandler: this.onAddClickHandler,
      brochureStatus: this.props.brochureStatus,
      brochureId: this.props.brochureId,
    };

    return (
      <div styleName="brochures">
        <SortableList
          axis="xy"
          useDragHandle
          onSortEnd={this.onSortEndHandler}
          {...props}
        />
        <ConfirmComponent
          visible={this.state.markedForDelete !== null}
          titleResourceKey="deleteBrochureConfirmTitle"
          bodyResourceKey="deleteBrochureConfirmBody"
          onClose={this.onConfirmCloseHandler}
          onConfirm={this.onConfirmConfirmHandler}
        />
      </div>
    );
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.brochureStatus === REQUEST.SUCCESS &&
      prevProps.brochureStatus === REQUEST.PENDING &&
      !!this.props.brochureLink
    ) {
      this.props.openExternalUrl(this.props.brochureLink, "openYisual");
    }
  }

  private onUploadCompleteHandler(files: any[]) {
    const { objectAssignment, currentComponentState } = this.props;
    const brochuresArray = objectAssignment.brochures || [];
    const brochures = files
      .filter((f) => !!f)
      .map((b) => ({ ...b, allowToPublish: true }));

    const updatedObjectAssignment = {
      ...objectAssignment,
      brochures: [...brochuresArray, ...brochures],
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private async onAddClickHandler() {
    const { id } = this.props.objectAssignment;
    const redirect = new Redirect();

    try {
      this.props.saveAssignment();
      const url = await this.props.createNewBrochure(id);
      redirect.navigate(url);
    } catch (error) {
      throw error;
    }
  }

  private onFileDownloadClickHandler(idx: number) {
    const item = this.props.objectAssignment.brochures[idx];
    if (!item.urlOriginal) {
      return;
    }
    window.open(item.urlOriginal, "_blank");
  }

  private async onFileEditClickHandler(idx: number) {
    const item = this.props.objectAssignment.brochures[idx];

    if (item.isEditable && item.id) {
      const { id } = this.props.objectAssignment;
      const redirect = new Redirect();

      try {
        this.props.saveAssignment();
        const url = await this.props.editBrochure(id, item.id);
        redirect.navigate(url);
      } catch (error) {
        throw error;
      }
    }
  }

  private onFileDeleteClickHandler(idx: number) {
    this.setState({ markedForDelete: idx });
  }

  private onConfirmCloseHandler() {
    this.setState({ markedForDelete: null });
  }

  private onConfirmConfirmHandler() {
    const { markedForDelete: idx } = this.state;

    const { objectAssignment, currentComponentState } = this.props;
    const brochures = objectAssignment.brochures.filter((p, k) => k !== idx);
    const updatedObjectAssignment = {
      ...objectAssignment,
      brochures,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
    this.setState({ markedForDelete: null });
  }

  private onSortEndHandler(sort: SortEnd) {
    const { objectAssignment, currentComponentState } = this.props;
    const brochures = arrayMoveImmutable(
      this.props.objectAssignment.brochures,
      sort.oldIndex,
      sort.newIndex
    );
    const updatedObjectAssignment = {
      ...objectAssignment,
      brochures,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }
}
