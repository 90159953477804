import { REDUX } from "@haywork/constants";
import * as ActionType from "./scroller-wrapper.types";

const setDynamicScrollPosition = (
  payload: ActionType.DynamicScrollPosition
) => ({
  type: REDUX.SCROLLPOSITION.SET_DYNAMIC_SCROLL_POSITION,
  ...payload,
});
export const ScrollPositionActions = {
  setDynamicScrollPosition,
};
