import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { ListFiltersContainerProps } from "./list-filters.container";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";
import { FolderCategory } from "@haywork/api/mail";

const styles = require("./style.scss");

export type ListFiltersComponentProps = {};
type Props = ListFiltersComponentProps & ListFiltersContainerProps;

export const ListFiltersComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ filters, setFilters, resetFilters, currentFolder }) => {
      const toggleUnreadFilter = useCallback(() => {
        setFilters({
          ...filters,
          unread: filters.unread === undefined ? true : undefined,
        });
      }, [filters]);

      const toggleBookmarkedFilter = useCallback(() => {
        setFilters({
          ...filters,
          bookmarked: filters.bookmarked === undefined ? true : undefined,
        });
      }, [filters]);

      const visible = useMemo(
        () =>
          !!currentFolder && currentFolder.category !== FolderCategory.Drafts,
        [currentFolder]
      );

      if (!visible) return null;

      return (
        <div styleName="list-filters">
          <span
            styleName={classNames("filter", {
              active:
                filters.unread === undefined &&
                filters.bookmarked === undefined,
            })}
            onClick={resetFilters}
          >
            <I18n value="email.filters.all" />
          </span>
          <div styleName="spacer" />
          <span
            styleName={classNames("filter", {
              active: filters.unread !== undefined,
            })}
            onClick={toggleUnreadFilter}
          >
            <I18n value="email.filters.unread" />
          </span>
          <span
            styleName={classNames("filter", {
              active: filters.bookmarked !== undefined,
            })}
            onClick={toggleBookmarkedFilter}
          >
            <I18n value="email.filters.bookmarked" />
          </span>
        </div>
      );
    }
  )
);
