import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EditGeneralScreen from "../assignments-edit-general";
import EditMediaScreen from "../assignments-edit-media";
import EditObjectScreen from "../assignments-edit-object";

const route = RouteUtil.mapStaticRouteValues;

type Props = { id: string };
export const AcquisitionAssignmentEditRouting: FC<Props> = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.EDIT.URI}
        render={() => (
          <Redirect
            to={route(ACQUISITIONOBJECTROUTES.EDIT_GENERAL.URI, { id })}
          />
        )}
      />
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.EDIT_GENERAL.URI}
        component={EditGeneralScreen}
      />
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.EDIT_OBJECT.URI}
        component={EditObjectScreen}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA.URI}
        component={EditMediaScreen}
      />
    </Switch>
  );
};
