import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  BatchItem,
  BatchItemType,
  BatchOption,
  DossierItemSnapShot,
  FolderTreeFileEntity,
} from "@haywork/api/kolibri";
import { KEYCODE, OFFICE_EXTENSIONS } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Input } from "@haywork/modules/form";
import classNames from "classnames";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { AuthorizationWidgets } from "../../..";
import { ResourceText } from "../../../resource-text/resource-text.component";
import ActionList, { Action } from "../action-list";

const styles = require("./style.scss");

interface Props {
  file: FolderTreeFileEntity;
  rootId: string;
  token: string;
  justAddedFiles: string[];
  bulkSelectedFiles: FolderTreeFileEntity[];
  canSendEmail: boolean;
  onUpdateDossierItem: (snapshot: DossierItemSnapShot) => Promise<void>;
  onRefresh: () => void;
  onDelete: (id: string, parentId: string) => Promise<void>;
  createEditSession: (dossierItemId: string) => Promise<void>;
  onToggleSelectForBulk: (file: FolderTreeFileEntity) => void;
  onEmail: (file: BatchItem) => void;
}
const DossierItemDossierComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      file,
      token,
      onUpdateDossierItem,
      onRefresh,
      onDelete,
      justAddedFiles,
      createEditSession,
      bulkSelectedFiles,
      onToggleSelectForBulk,
      onEmail,
      canSendEmail,
    }: Props) => {
      const { linkedDossierItem, id } = file;
      const [nameInputVisible, setNameInputVisible] = useState(false);
      const [descriptionInputVisible, setDescriptionInputVisible] =
        useState(false);
      const [actionListVisible, setActionListVisible] = useState(false);
      const [name, setName] = useState(
        get(linkedDossierItem, "name") || linkedDossierItem.fileName
      );
      const [orgName, setOrgName] = useState(
        get(linkedDossierItem, "name", "")
      );
      const [description, setDescription] = useState(
        get(linkedDossierItem, "description", "")
      );
      const [orgDescription, setOrgDescription] = useState(
        get(linkedDossierItem, "description", "")
      );
      const nameInputRef = useRef(null);
      const descriptionInputRef = useRef(null);
      const actionRef = useRef(null);

      const justAdded = useMemo(() => {
        if (!linkedDossierItem) return false;
        return justAddedFiles.includes(linkedDossierItem.id);
      }, [linkedDossierItem, justAddedFiles]);

      const icon = useMemo(() => {
        if (!linkedDossierItem) return <i className="fal fa-file" />;

        const { fileExtension } = linkedDossierItem;
        const imagesRegex = /(bmp|jpe?g|gif|png|tiff)/gi;
        const wordRegex = /docx?/gi;
        const excelRegex = /xlsx?/gi;
        const powerpointRegex = /pptx?/gi;
        const pdfRegex = /(pdf|ps)/gi;
        const videoRegex = /(mov|mp4|m4v|3gp|3g2|avi|mpe?g|wmv|asf|ram)/gi;
        const audioRegex = /(mp3|wav|aiff?|mpa|m4a|wma)/gi;

        switch (true) {
          case imagesRegex.test(fileExtension): {
            return <i className="fal fa-file-image" />;
          }
          case wordRegex.test(fileExtension): {
            return <i className="fal fa-file-word" />;
          }
          case excelRegex.test(fileExtension): {
            return <i className="fal fa-file-excel" />;
          }
          case powerpointRegex.test(fileExtension): {
            return <i className="fal fa-file-powerpoint" />;
          }
          case pdfRegex.test(fileExtension): {
            return <i className="fal fa-file-pdf" />;
          }
          case videoRegex.test(fileExtension): {
            return <i className="fal fa-file-video" />;
          }
          case audioRegex.test(fileExtension): {
            return <i className="fal fa-file-audio" />;
          }
          default: {
            return <i className="fal fa-file" />;
          }
        }
      }, [linkedDossierItem]);

      const onNameKeyUpHandler = useCallback(
        (e: KeyboardEvent) => {
          if (!nameInputVisible) return;
          if (e.keyCode === KEYCODE.ENTER && !!nameInputRef.current) {
            nameInputRef.current.blur();
          }
        },
        [nameInputVisible, nameInputRef]
      );

      const onDescriptionKeyUpHandler = useCallback(
        (e: KeyboardEvent) => {
          if (!descriptionInputVisible) return;
          if (e.keyCode === KEYCODE.ENTER && !!descriptionInputRef.current) {
            descriptionInputRef.current.blur();
          }
        },
        [descriptionInputVisible, descriptionInputRef]
      );

      useEffect(() => {
        setDescription(
          !linkedDossierItem ? "" : linkedDossierItem.description || ""
        );
        setOrgDescription(
          !linkedDossierItem ? "" : linkedDossierItem.description || ""
        );
        setOrgName(!linkedDossierItem ? "" : linkedDossierItem.name || "");
        setOrgName(!linkedDossierItem ? "" : linkedDossierItem.name || "");
      }, [linkedDossierItem]);

      useEffect(() => {
        if (!nameInputRef.current) return;
        nameInputRef.current.addEventListener(
          "keyup",
          onNameKeyUpHandler,
          true
        );
        return () => {
          if (!!nameInputRef.current) {
            nameInputRef.current.removeEventListener(
              "keyup",
              onNameKeyUpHandler,
              true
            );
          }
        };
      }, [nameInputRef, onNameKeyUpHandler]);

      useEffect(() => {
        if (!descriptionInputRef.current) return;
        descriptionInputRef.current.addEventListener(
          "keyup",
          onDescriptionKeyUpHandler,
          true
        );
        return () => {
          if (!!descriptionInputRef.current) {
            descriptionInputRef.current.removeEventListener(
              "keyup",
              onDescriptionKeyUpHandler,
              true
            );
          }
        };
      }, [descriptionInputRef, onDescriptionKeyUpHandler]);

      const onActionListClose = useCallback(() => {
        setActionListVisible(false);
      }, [setActionListVisible]);

      const onEditClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedDossierItem) return;
        const { id } = linkedDossierItem;
        createEditSession(id);
      }, [linkedDossierItem, setActionListVisible]);

      const onDownloadClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedDossierItem) return;

        const { url, name, fileExtension } = linkedDossierItem;
        const connector = /\?/g.test(url) ? "&" : "?";

        const urlWithFileName = name
          ? `${url}${connector}access_token=${token}&file_name=${name.replace(
              fileExtension,
              ""
            )}`
          : `${url}${connector}access_token=${token}`;

        window.open(urlWithFileName, "_blank");
      }, [linkedDossierItem, setActionListVisible]);

      const onEmailClickHandler = useCallback(
        (asPDF: boolean = false) => {
          setActionListVisible(false);
          if (!linkedDossierItem) return;
          const { id } = linkedDossierItem;
          let options = [
            BatchOption.TempAccessUrl,
            BatchOption.UseDossierFileName,
          ];
          if (asPDF) {
            options = [...options, BatchOption.ConvertToPdf];
          }
          onEmail({ id, type: BatchItemType.DossierItem, options });
        },
        [linkedDossierItem, onEmail]
      );

      const onNameInputChangeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setName(value);
        },
        [setName]
      );

      const onDescriptionInputChangeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setDescription(value);
        },
        [setDescription]
      );

      const focusOnInput = useCallback(() => {
        setDescriptionInputVisible(true);
        setTimeout(() => {
          if (!!descriptionInputRef.current) {
            descriptionInputRef.current.focus();
          }
        }, 5);
      }, [setDescriptionInputVisible, descriptionInputRef]);

      const onNameInputBlurHandler = useCallback(async () => {
        setNameInputVisible(false);
        if (name === orgName) return;
        const snapshot = {
          ...linkedDossierItem,
          name,
        };
        await onUpdateDossierItem(snapshot);
        onRefresh();
        if (!name) {
          setName(linkedDossierItem.fileName);
        }
      }, [setNameInputVisible, name, orgName, linkedDossierItem, setName]);

      const onDescriptionInputBlurHandler = useCallback(async () => {
        setDescriptionInputVisible(false);
        if (description === orgDescription) return;
        const snapshot = {
          ...linkedDossierItem,
          description,
        };
        await onUpdateDossierItem(snapshot);
        onRefresh();
      }, [
        setDescriptionInputVisible,
        description,
        orgDescription,
        linkedDossierItem,
      ]);

      const onDeleteHandler = useCallback(async () => {
        if (!linkedDossierItem) return;
        const { id, parentId } = linkedDossierItem;
        await onDelete(id, parentId);
        onRefresh();
      }, [linkedDossierItem]);

      const onEditName = useCallback(() => {
        setNameInputVisible(true);
        setTimeout(() => {
          if (!!nameInputRef.current) {
            nameInputRef.current.focus();
          }
        }, 5);
      }, [setNameInputVisible, nameInputRef]);

      const bulkSelected = useMemo(() => {
        return !!bulkSelectedFiles.find((f) => f.id === id);
      }, [id, bulkSelectedFiles]);

      const canEdit = useMemo(() => {
        if (!linkedDossierItem) return false;
        const { fileExtension } = linkedDossierItem;
        return OFFICE_EXTENSIONS.includes(fileExtension);
      }, [linkedDossierItem]);

      if (!linkedDossierItem) return null;
      const { dateTimeCreated, url, fileExtension } = linkedDossierItem;

      return (
        <div styleName={classNames("file", "dossier")}>
          {!!justAdded && <div styleName="just-added" />}
          <div styleName="bulk-select">
            <Input.CheckBox
              name={`bulk-${id}`}
              asSingleInput
              value={bulkSelected}
              onChange={() => onToggleSelectForBulk(file)}
            />
          </div>
          <div styleName="icon">{icon}</div>
          <div styleName="meta">
            <div styleName="meta__name">
              {nameInputVisible ? (
                <input
                  type="text"
                  value={name}
                  onChange={onNameInputChangeHandler}
                  onBlur={onNameInputBlurHandler}
                  ref={nameInputRef}
                  data-lpignore="true"
                />
              ) : (
                <span styleName="faux-link" onClick={onDownloadClickHandler}>
                  {name}
                </span>
              )}
            </div>
            {!nameInputVisible && (
              <div styleName="meta__edit" onClick={onEditName}>
                <i className="fal fa-pencil" />
              </div>
            )}
            <div styleName="meta__creation-date">
              {moment(dateTimeCreated).format("DD-MM-YYYY")}
            </div>
            <div styleName="meta__entities-description for-description">
              {descriptionInputVisible ? (
                <input
                  type="text"
                  value={description}
                  onChange={onDescriptionInputChangeHandler}
                  onBlur={onDescriptionInputBlurHandler}
                  ref={descriptionInputRef}
                  data-lpignore="true"
                />
              ) : (
                <div
                  styleName={classNames("description", {
                    "has-value": !!description,
                  })}
                  onClick={focusOnInput}
                >
                  {!!description ? (
                    description
                  ) : (
                    <ResourceText resourceKey="dossier.file.addDescription" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            styleName="action"
            onClick={() => setActionListVisible(!actionListVisible)}
            ref={actionRef}
          >
            <i className="fal fa-ellipsis-v" />
          </div>

          <ActionList
            visible={actionListVisible}
            onClose={onActionListClose}
            parent={actionRef}
          >
            {canEdit && (
              <FeatureSwitch feature="WEBDAV">
                <Action
                  label="dossier.fileAction.editWithOffice"
                  icon="fal fa-pencil"
                  onClick={onEditClickHandler}
                />
              </FeatureSwitch>
            )}
            {!!url && (
              <Action
                label="dossier.fileAction.download"
                icon="fal fa-download"
                onClick={onDownloadClickHandler}
              />
            )}

            {canSendEmail && (
              <>
                <Action
                  label="dossier.fileAction.email"
                  icon="fal fa-paper-plane"
                  onClick={() => onEmailClickHandler()}
                />

                {/docx?/gi.test(fileExtension) && (
                  <Action
                    label="dossier.fileAction.emailAsPDF"
                    icon="fal fa-envelope-open-text"
                    onClick={() => onEmailClickHandler(true)}
                  />
                )}
              </>
            )}

            <Action
              label="dossier.fileAction.delete"
              icon="fal fa-trash"
              onClick={onDeleteHandler}
            />
            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={WidgetEntityType.DossierItem}
                location={WidgetEntityLocation.Dossier}
                id={id}
                onActionClick={() => setActionListVisible(false)}
              />
            </FeatureSwitch>
          </ActionList>
        </div>
      );
    }
  )
);

export default DossierItemDossierComponent;
