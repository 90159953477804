import { UploadResponse as ApiUploadResponse } from "@haywork/api/kolibri";
import { UploadResponse as MailUploadResponse } from "@haywork/api/mail";
import { FileUploadModalComponent } from "@haywork/modules/shared";
import { FileUploadObject, UploadUtil } from "@haywork/util";
import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import * as React from "react";
import { EditorUploadContainerProps } from "./upload.container";
export interface EditorUploadComponentProps {
  files: FileUploadObject[];
  uploadUrl: string;
  shouldBePrivate?: boolean;
  onClose: () => void;
  onCompleted: (files: FileUploadObject[]) => void;
}
interface State {
  fileSize: number;
  loadedSize: number;
  files: FileUploadObject[];
  modalVisible: boolean;
  uploadPercentage: number;
  errored: FileUploadObject[];
  completed: boolean;
  running: boolean;
  cancelTokenSource: CancelTokenSource;
}
type Props = EditorUploadComponentProps & EditorUploadContainerProps;

export class EditorUploadComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      fileSize: 0,
      loadedSize: 0,
      files: [],
      modalVisible: false,
      uploadPercentage: 0,
      errored: [],
      completed: false,
      running: false,
      cancelTokenSource: null,
    };

    this.onModalCloseHandler = this.onModalCloseHandler.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!this.state.running && nextProps.files.length > 0) {
      this.setState({
        running: true,
      });

      this.uploadFiles(nextProps.files);
    }
  }

  public render() {
    return (
      <FileUploadModalComponent
        visible={this.state.modalVisible}
        uploadedPercentage={this.state.uploadPercentage}
        errored={this.state.errored}
        completed={this.state.completed}
        onClose={this.onModalCloseHandler}
      />
    );
  }

  private onModalCloseHandler() {
    this.setState({
      fileSize: 0,
      loadedSize: 0,
      files: [],
      modalVisible: false,
      uploadPercentage: 0,
      errored: [],
      completed: false,
      running: false,
      cancelTokenSource: null,
    });

    this.props.onClose();
  }

  private async uploadFiles(files: FileUploadObject[]) {
    if (files.length > 0) {
      const fileSize = files.reduce((state, file) => {
        return state + file.file.size;
      }, 0);

      this.setState({
        fileSize,
        uploadPercentage: 0,
        files,
        cancelTokenSource: Axios.CancelToken.source(),
        completed: false,
        modalVisible: true,
        errored: [],
      });

      for (const file of files) {
        await this.uploadSingle(file);
      }
    }
  }

  private uploadSingle(file: FileUploadObject) {
    const formData = new FormData();

    const regex = new RegExp(`${this.props.emailHost}`, "gi");
    const isMailApi = regex.test(this.props.uploadUrl);

    formData.append(
      "isPrivate",
      isMailApi || this.props.shouldBePrivate ? "true" : "false"
    );
    formData.append("upload", file.file);

    const config: AxiosRequestConfig = {
      onUploadProgress: (event: ProgressEvent) => {
        const files = this.state.files.map((f) => {
          if (f.id === file.id) return { ...f, uploaded: event.loaded };
          return f;
        });
        const loadedSize = files.reduce((s, f) => s + f.uploaded, 0);
        let uploadPercentage = Math.round(
          (loadedSize / this.state.fileSize) * 100
        );

        if (uploadPercentage > 100) uploadPercentage = 100;

        this.setState({
          loadedSize,
          uploadPercentage,
          files,
        });
      },
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
    };

    return Axios.post(this.props.uploadUrl, formData, config)
      .then((result) => this.handleUploadSuccess(result, file))
      .catch((error) => this.handleUploadError(error, file));
  }

  private handleUploadSuccess(
    result: AxiosResponse<ApiUploadResponse | MailUploadResponse>,
    file: FileUploadObject
  ) {
    const files = this.state.files.map((f) => {
      if (f.id === file.id) {
        const regex = new RegExp(`${this.props.emailHost}`, "gi");
        const response = regex.test(this.props.uploadUrl)
          ? UploadUtil.mapMailResponseToResponse(
              result.data as MailUploadResponse,
              file.isAttachment
            )
          : UploadUtil.mapApiResponseToResponse(
              result.data as ApiUploadResponse,
              file.isAttachment
            );

        return {
          ...f,
          completed: true,
          response,
        };
      }
      return f;
    });

    this.setState({ files }, () => this.checkCompletedState());
  }

  private handleUploadError(error: any, file: FileUploadObject) {
    const files = this.state.files.map((f) => {
      if (f.id === file.id) return { ...f, error, completed: true };
      return f;
    });

    this.setState({ files }, () => this.checkCompletedState());
  }

  private checkCompletedState() {
    const unCompleted = this.state.files.filter((file) => !file.completed);
    const errored = this.state.files.filter((file) => !!file.error);

    if (unCompleted.length === 0) {
      this.props.onCompleted(this.state.files);
      this.setState({ completed: true, errored });
    }
  }
}
