import {
  FinancialAdministration,
  FinancialAdministrationProduct,
  ObjectAssignment,
  ProductOrder
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { FinancialThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingBudgetComponent,
  AssignmentEditMarketingBudgetComponentProps
} from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  SingleAssignmentState
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";
import { push } from "connected-react-router";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  internalCosts: number;
  customerCosts: number;
  products: FinancialAdministrationProduct[];
  financialAdministrations: FinancialAdministration[];
  path: string;
  financialAdministrationState: string;
}
interface DispatchProps {
  updateObjectAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) => void;
  navigate: (path: string) => void;
}

const reduceInternalCosts = (productOrders: ProductOrder[]): number => {
  return productOrders.reduce((state, productOrder) => {
    if (!productOrder.chargeToCustomerBudget)
      return state + productOrder.unitPriceNet;
    return state;
  }, 0);
};

const reduceCustomerCosts = (productOrders: ProductOrder[]): number => {
  return productOrders.reduce((state, productOrder) => {
    if (!!productOrder.chargeToCustomerBudget)
      return state + productOrder.unitPriceNet;
    return state;
  }, 0);
};

const mapProducts = (
  financialAdministrations: FinancialAdministration[]
): FinancialAdministrationProduct[] => {
  return financialAdministrations.reduce((state, financialAdministration) => {
    if (!financialAdministration.products) return state;
    return [...state, ...financialAdministration.products];
  }, []);
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingBudgetComponentProps,
  AppState
> = (state) => {
  const {
    financialAdministrations,
    financialAdministrationState
  } = state.account;
  const { currentComponentState } = state.editable;
  const { objectAssignment } = currentComponentState;
  const productOrders = objectAssignment.productOrders || [];

  return {
    objectAssignment,
    currentComponentState,
    internalCosts: reduceInternalCosts(productOrders),
    customerCosts: reduceCustomerCosts(productOrders),
    products: mapProducts(financialAdministrations),
    financialAdministrations,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: currentComponentState.objectAssignment.id
    }),
    financialAdministrationState
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingBudgetComponentProps
> = (dispatch) => ({
  updateObjectAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    ),
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) =>
    dispatch(
      FinancialThunks.updateFinancialAdministration(financialAdministration)
    ),
  navigate: (path: string) => dispatch(push(path))
});

export type AssignmentEditMarketingBudgetContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingBudgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingBudgetComponent);
