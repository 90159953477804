import { REDUX } from "@haywork/constants";
import { Status } from "@haywork/stores";

import * as ActionType from "./providers.types";

const setProvidersStatus = (payload: Status) => ({
  type: REDUX.EMAILV2.SET_PROVIDERS_STATUS,
  ...payload
});

const setProviders = (payload: ActionType.ProvidersResponse) => ({
  type: REDUX.EMAILV2.SET_PROVIDERS,
  ...payload
});

export const ProvidersActions = {
  setProvidersStatus,
  setProviders
};
