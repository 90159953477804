export const NOTES = {
  APPEND_NOTES: "APPEND_NOTES",
  SET_NOTES: "SET_NOTES",
  NEW_NOTE: "NEW_NOTE",
  EDIT_NOTE: "EDIT_NOTE",
  DELETE_NOTE: "DELETE_NOTE",
  UNDELETE_NOTE: "UNDELETE_NOTE",
  DELETE_FROM_STATE: "DELETE_FROM_STATE",
  SET_NOTES_STATE: "SET_NOTES_STATE"
};
