import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { EmployeeRole, LinkedEmployee } from "@haywork/api/kolibri";
import head from "lodash-es/head";

const accountSelector = (state: AppState) => state.account;

export const linkedEmployee = createSelector(accountSelector, (account) => {
  const { currentRealestateAgency, employee, employees } = account;
  if (currentRealestateAgency.role === EmployeeRole.Standard) {
    return {
      id: employee?.id,
      displayName: employee?.displayName,
    } as LinkedEmployee;
  } else {
    const firstEmployee = head(employees);
    return {
      id: firstEmployee?.id,
      displayName: firstEmployee?.displayName,
    } as LinkedEmployee;
  }
});
