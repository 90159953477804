import I18n, { Translateable } from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import isString from "lodash-es/isString";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue } from "../../";
import { FilterConfig } from "@haywork/components/ui/list";
import { MlsUtil } from "@haywork/util";

const styles = require("./style.scss");
export const SelectEmptyValue = "no-option-selected";

type Props = {
  values: SingleFilterValue[];
  title?: Translateable;
  filterConfig: FilterConfig;
  onChange?: (filter: string) => void;
};

export const SavedFiltersComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ values, title, filterConfig, onChange }) => {
      const label = useMemo(() => {
        if (!title) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title]);

      const value = useMemo(() => {
        return JSON.stringify(MlsUtil.cleanFilterConfig(filterConfig));
      }, [filterConfig]);

      const handleInputChange = useCallback(
        (value: string) => {
          if (onChange) {
            onChange(value);
          }
        },
        [onChange]
      );

      return (
        <div>
          {label}
          <Input.Select
            name="savedFilters"
            value={value}
            onChange={handleInputChange}
            asSingleInput
            disabled={!values.length}
          >
            <Input.Option value="">
              <I18n value="mls.filters.savedFilters.selectAfilter" />
            </Input.Option>
            {values.map((value, idx) => (
              <Input.Option value={value.value} key={idx} idx={idx}>
                <I18n
                  value={
                    isString(value.label) ? value.label : value.label.value
                  }
                  values={isString(value.label) ? {} : value.label.values}
                />
              </Input.Option>
            ))}
            <Input.Option value="manageFilters">
              <Icon
                size={12}
                name="cog"
                color={Colors.DarkGray}
                fixedWidth
                style={{ marginRight: "5px" }}
              />
              <I18n value="mls.filters.savedFilters.manageFilters" />
            </Input.Option>
          </Input.Select>
        </div>
      );
    }
  )
);
