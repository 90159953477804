import {
  EventNotification,
  EventNotificationsOrderByField,
  SortOrder,
  EventNotificationStatistics
} from "@haywork/api/event-center";
import { FilterConfig } from "@haywork/components/ui/list";
import { Employee } from "@haywork/api/kolibri";

export enum ActionType {
  UpdateList = "Notifications.EventCenter.UpdateList",
  SetOrdering = "Notifications.EventCenter.SetOrdering",
  SetFilters = "Notifications.EventCenter.SetFilters",
  SetScrollOffset = "Notifications.EventCenter.SetScrollOffset",
  RemoveByEventId = "Notifications.EventCenter.RemoveByEventId",
  UpdateListItem = "Notifications.EventCenter.UpdateListItem",
  UpdateStatistics = "Notifications.EventCenter.UpdateStatistics",
  BulkUpdateListItems = "Notifications.EventCenter.BulkUpdateListItems",
  ResetApp = "RESET_APP"
}

type UpdateList = {
  type: ActionType.UpdateList;
  startIndex: number;
  stopIndex: number;
  items: EventNotification[];
  totalCount: number;
};

const updateList = (
  startIndex: number,
  stopIndex: number,
  items: EventNotification[],
  totalCount: number
) => ({
  type: ActionType.UpdateList,
  startIndex,
  stopIndex,
  items,
  totalCount
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: EventNotificationsOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: EventNotificationsOrderByField
) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type RemoveByEventId = {
  type: ActionType.RemoveByEventId;
  eventId: string;
};

const removeByEventId = (eventId: string) => ({
  type: ActionType.RemoveByEventId,
  eventId
});

type UpdateListItem = {
  type: ActionType.UpdateListItem;
  notification: EventNotification;
};

const updateListItem = (notification: EventNotification) => ({
  type: ActionType.UpdateListItem,
  notification
});

type UpdateStatistics = {
  type: ActionType.UpdateStatistics;
  statistics: EventNotificationStatistics;
};

const updateStatistics = (statistics: EventNotificationStatistics) => ({
  type: ActionType.UpdateStatistics,
  statistics
});

type BulkUpdateListItems = {
  type: ActionType.BulkUpdateListItems;
  notifications: EventNotification[];
};

const bulkUpdateListItems = (notifications: EventNotification[]) => ({
  type: ActionType.BulkUpdateListItems,
  notifications
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | RemoveByEventId
  | UpdateListItem
  | UpdateStatistics
  | BulkUpdateListItems
  | ResetApp;
export const actions = {
  updateList,
  setOrdering,
  setFilters,
  setScrollOffset,
  removeByEventId,
  updateListItem,
  updateStatistics,
  bulkUpdateListItems
};
