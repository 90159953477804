import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./style.scss");

interface Props {
  onCreateNewRelation: () => void;
  onBindExistingRelation: () => void;
}

export const VoipActionsNoUserComponent = CSSModules(styles, {
  allowMultiple: true
})(({ onCreateNewRelation, onBindExistingRelation }: Props) => {
  return (
    <div styleName="actions">
      <button type="button" onClick={() => onCreateNewRelation()}>
        <ResourceText resourceKey="voipAction.createNewRelation" />
      </button>
      <button type="button" onClick={() => onBindExistingRelation()}>
        <ResourceText resourceKey="voipAction.bindExistingRelation" />
      </button>
    </div>
  );
});
