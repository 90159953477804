import { LinkedObjectAssignment } from "@haywork/api/event-center";
import {
  AgendaItemCategorySnapShot,
  Employee,
  LinkedAgendaItemCategory,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { ReminderOption } from "@haywork/modules/new-entity";
import { Hint, HintAlignment, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AddressRequest } from "@haywork/request";
import {
  ColorUtil,
  FormControlUtil,
  RelationUtil,
  StringUtil,
} from "@haywork/util";
import escapeRegExp from "lodash-es/escapeRegExp";
import first from "lodash-es/first";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { intlContext } from "@haywork/app";
import classNames from "classnames";

const styles = require("../new-entity.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface AppointmentValues {
  linkedAssignments?: LinkedAssignment[];
  linkedRelations?: LinkedRelation[];
  linkedAgendaItemCategorys?: LinkedAgendaItemCategory[];
}

interface AppointmentProps extends AppointmentValues {
  loading: boolean;
  employees: RelationSnapShot[];
  employee: Employee;
  agendaItemCategories: AgendaItemCategorySnapShot[];
  reminderOptions: ReminderOption[];
  onSubmit: (values: FormReturnValue) => void;
}
interface State {
  showTimes: boolean;
}
type Props = AppointmentProps;

@CSSModules(styles, { allowMultiple: true })
export class AppointmentComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const linkedEmployee = RelationUtil.mapEmployeeToLinkedEmployee(
      this.props.employee
    );

    this.state = {
      showTimes: true,
    };

    const startMoment = moment();
    const endMoment = moment().add(1, "hours");
    const location =
      !!this.props.linkedAssignments && !!this.props.linkedAssignments.length
        ? { description: first(this.props.linkedAssignments).displayName }
        : "";
    const subject = !!location
      ? `${intlContext.formatMessage({ id: "schedulerAt" })} ${
          location.description
        }`
      : "";

    this.formControls = {
      linkedAgendaItemCategory: {
        value: "",
        onChange: () => this.renderSubject(),
      },
      linkedEmployees: { value: linkedEmployee },
      linkedAssignments: {
        value: value(this.props, "linkedAssignments", []),
        onChange: () => this.renderSubject(),
      },
      location: { value: location, onChange: () => this.renderSubject() },
      linkedRelations: { value: value(this.props, "linkedRelations", []) },
      subject: {
        value: subject,
        validators: [Validators.required()],
      },
      isPrivate: { value: false },
      startDateTime: { value: startMoment.toDate() },
      startTime: { value: startMoment.format("HH:mm") },
      allDayEvent: {
        value: false,
        onChange: (ref) => {
          this.setState({ showTimes: !ref.value });
          if (!!ref.value) {
            return { startTime: "00:00", endTime: "23:59" };
          } else {
            return {
              startTime: moment().format("HH:mm"),
              endTime: moment().add(1, "hours").format("HH:mm"),
            };
          }
        },
      },
      endDateTime: { value: endMoment.toDate() },
      endTime: { value: endMoment.format("HH:mm") },
      minutesBeforeReminder: { value: null },
      description: { value: "" },
    };

    this.renderSubject = this.renderSubject.bind(this);
    this.renderCategoryOption = this.renderCategoryOption.bind(this);
  }

  public render() {
    return (
      <React.Fragment>
        <div styleName="body__inner">
          {this.props.loading && <Ui.Loaders.Fullscreen mask />}
          <Form
            name="new-entity-appointment"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.props.onSubmit}
          >
            <div className="form__row">
              <label htmlFor="linkedAgendaItemCategory">
                <ResourceText resourceKey="simpleAppoinmentLinkedAgendaItemCategory" />
              </label>
              <Input.NewSelect
                name="linkedAgendaItemCategory"
                values={this.props.agendaItemCategories}
                placeholder="selectCategory"
                displayFn={this.renderCategoryOption}
                valueProp="id"
                filterProp="displayName"
              />
            </div>
            <div className="form__row">
              <label htmlFor="linkedEmployees">
                <ResourceText resourceKey="simpleAppoinmentLinkedEmployees" />
              </label>
              <Input.Query
                name="linkedEmployees"
                optionValue={this.renderQueryEmployeeOption}
                values={this.props.employees}
                matchOn={this.employeeQueryMatchOn}
                selectedValue={this.renderSelectedEmployeeValue}
                multiple
                placeholder="simpleAppoinmentLinkedEmployeesPlaceholder"
              />
            </div>
            <div className="form__row">
              <label htmlFor="linkedAssignments">
                <ResourceText resourceKey="simpleAppointmentLinkedAssignments" />
              </label>
              <Input.AssigmentQuery name="linkedAssignments" multiple />
            </div>
            <div className="form__row">
              <label htmlFor="location">
                <ResourceText resourceKey="whereAppointment" />
              </label>
              <Input.QueryLegacy
                name="location"
                setQueryAsValue
                useQueryAsOption
                asyncValues={(value) => AddressRequest.addressSearch(value)}
                placeholder="addressPlaceholder"
                displayPath="description"
              />
            </div>
            <div className="form__row">
              <label htmlFor="linkedRelations">
                <ResourceText resourceKey="simpleAppointmentLinkedRelations" />
              </label>
              <Input.RelationQuery name="linkedRelations" multiple />
            </div>
            <div className="form__row">
              <label htmlFor="subject">
                <ResourceText resourceKey="simpleAppointmentSubject" />
              </label>
              <Input.Textarea
                name="subject"
                autoSize
                placeholder="inputSubject"
                maxHeight={256}
                maxLength={250}
              />
            </div>
            <div className="form__row">
              <Hint message="isPrivateTooltip" align={HintAlignment.Right}>
                <Input.Switch
                  name="isPrivate"
                  on={true}
                  off={false}
                  label="simpleAppointmentIsPrivate"
                  labelPosition={SwitchLabelPosition.Post}
                />
              </Hint>
            </div>
            <div className="form__row">
              <label htmlFor="startDateTime">
                <ResourceText resourceKey="from" />
              </label>
              <div className="form__group flex-wrap">
                <div className="column">
                  <Input.Datepicker name="startDateTime" />
                </div>
                {this.state.showTimes && (
                  <React.Fragment>
                    <div className="column__spacer" />

                    <div className="column">
                      <Input.Time name="startTime" minuteInterval={30} />
                    </div>
                  </React.Fragment>
                )}
                <div className="column__spacer" />
                <div className="column">
                  <Input.CheckBox name="allDayEvent" label="allDay" />
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="form__group flex-wrap">
                <div className="column">
                  <label htmlFor="endDateTime">
                    <ResourceText resourceKey="until" />
                  </label>
                  <Input.Datepicker name="endDateTime" />
                </div>
                {this.state.showTimes && (
                  <React.Fragment>
                    <div className="column__spacer" />

                    <div className="column push-label">
                      <Input.Time name="endTime" minuteInterval={30} />
                    </div>
                  </React.Fragment>
                )}
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="minutesBeforeReminder">
                    <ResourceText resourceKey="recieveReminderAppointment" />
                  </label>
                  <Input.NewSelect
                    name="minutesBeforeReminder"
                    values={this.props.reminderOptions}
                    displayProp="type"
                    valueProp="value"
                    translate
                    translatePrefix="reminderType"
                    translateValues={(reminder: ReminderOption) => ({
                      count: reminder.value,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="form__row" styleName="description">
              <label htmlFor="description">
                <ResourceText resourceKey="simpleAppointmentDescription" />
              </label>
              <Input.Textarea
                name="description"
                placeholder="appointmentDetailDescriptionPlaceholder"
                autoSize
                maxLength={8192}
              />
            </div>
          </Form>
        </div>
        <div styleName="body__footer">
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.props.loading}
            onClick={() => this.formRef.submit()}
          >
            <ResourceText resourceKey="save" />
          </button>
        </div>
      </React.Fragment>
    );
  }

  private renderCategoryOption(
    category: AgendaItemCategorySnapShot,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div
        className={classNames("task__category-option", { selected, active })}
      >
        <div
          className="task__category-hint"
          style={{
            backgroundColor: this.categoryColor(category.backColor),
          }}
        />
        <div
          className="task__category-hint-label"
          dangerouslySetInnerHTML={StringUtil.highlight(
            category.displayName,
            query
          )}
        />
      </div>
    );
  }

  private renderQueryEmployeeOption(
    value: any,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private employeeQueryMatchOn(query: string, value: any): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedEmployeeValue(value: any): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private categoryColor(color: string | null): string {
    return !!color ? ColorUtil.hexToRgb(color) : null;
  }

  private getCategoryPlaceholder() {
    return (
      <div className="form__select-option">
        <div>
          <ResourceText resourceKey="selectCategory" />
        </div>
      </div>
    );
  }

  private renderSubject() {
    if (!this.formRef) return;
    const { linkedAgendaItemCategory, location, linkedAssignments } =
      this.formRef.getValues();

    switch (true) {
      case !linkedAgendaItemCategory && !!location: {
        return {
          subject: `${intlContext.formatMessage({ id: "schedulerAt" })} ${
            location.description
          }`,
        };
      }
      case !linkedAgendaItemCategory &&
        !!linkedAssignments &&
        !!linkedAssignments.length: {
        const assignment: LinkedObjectAssignment = first(linkedAssignments);
        return {
          subject: `${intlContext.formatMessage({ id: "schedulerAt" })} ${
            assignment.displayName
          }`,
        };
      }
      case !!linkedAgendaItemCategory &&
        !location &&
        (!linkedAssignments || !linkedAssignments.length): {
        return {
          subject: linkedAgendaItemCategory.displayName,
          allDayEvent: linkedAgendaItemCategory.allDayEvent,
        };
      }
      case !!linkedAgendaItemCategory && !!location: {
        return {
          subject: `${linkedAgendaItemCategory.displayName} ${intlContext
            .formatMessage({
              id: "schedulerAt",
            })
            .toLowerCase()} ${location.description}`,
          allDayEvent: linkedAgendaItemCategory.allDayEvent,
        };
      }
      case !!linkedAgendaItemCategory &&
        !location &&
        !!linkedAssignments &&
        !!linkedAssignments.length: {
        const assignment: LinkedObjectAssignment = first(linkedAssignments);
        return {
          subject: `${linkedAgendaItemCategory.displayName} ${intlContext
            .formatMessage({
              id: "schedulerAt",
            })
            .toLowerCase()} ${assignment.displayName}`,
          allDayEvent: linkedAgendaItemCategory.allDayEvent,
        };
      }
      default:
        return;
    }
  }
}
