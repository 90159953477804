export const TiaraMediaContractsResidential = [
  {
    name: "Tiara alpha",
    hidePropertyName: "isTemporarilyRemovedFromFunda",
    mediaPartnerId: "54dc8a14-4f43-46fe-9532-3b6d29348955",
  },
  {
    name: "Tiara Wonen NVM",
    hidePropertyName: "isTemporarilyRemovedFromFunda",
    mediaPartnerId: "414f789a-6ba2-4823-8f45-77dd89636424",
  },
  {
    name: "Tiara Wonen VBO",
    hidePropertyName: "isTemporarilyRemovedFromFunda",
    mediaPartnerId: "16930cd3-9112-446e-afc7-f87e4eb5aa2d",
  },
  {
    name: "Tiara Wonen VastgoedPRO",
    hidePropertyName: "isTemporarilyRemovedFromFunda",
    mediaPartnerId: "9c25f13d-a855-4abc-a695-b59184c30978",
  },
  {
    name: "Tiara Wonen Onafhankelijk",
    hidePropertyName: "isTemporarilyRemovedFromFunda",
    mediaPartnerId: "8593cbcf-5ac9-47b8-bf18-7cc7cbaaa199",
  },
];
