import {
  LinkedRelation,
  RealEstateGroup,
  RelationSnapShot,
  RentCondition,
  RentOffer,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { DateUtil, FormControlUtil } from "@haywork/util";
import * as equal from "deep-equal";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./status-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface AssignmentStatusModalRentedComponentProps {
  realEstateAgencyId: string;
  assignmentId: string;
  host: string;
  displayName: string;
  linkedApplicants: LinkedRelation[];
  rentOffer: RentOffer;
  statusState: string;
  statusError: string[];
  changeAvailabilityStatus: string;
  realEstateGroup: RealEstateGroup;
  isTransactionInformationConfidential?: boolean;
  onCancel: () => void;
  onCloseAvailabilityModal: () => void;
  rentAssignment: (
    assignmentId: string,
    dateRentedFrom: Date,
    dateRentedUntil: Date,
    rentPrice: number,
    linkedApplicants: LinkedRelation[],
    isVacancyLaw: boolean,
    dateReservation: Date,
    dateSignDeed: Date,
    isTransactionInformationConfidential: boolean
  ) => void;
  navigate: (url: string) => void;
}
interface AssignmentStatusModalRentedComponentState {
  showDateReservation: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentStatusModalRentedComponent extends React.Component<
  AssignmentStatusModalRentedComponentProps,
  AssignmentStatusModalRentedComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;
  private focusRef: HTMLDivElement;

  constructor(props) {
    super(props);

    const {
      dateReservation,
      rentedFrom,
      rentedUntil,
      dateSignDeed,
    } = this.props.rentOffer;
    const timeSignDeed = !!dateSignDeed
      ? moment(dateSignDeed).format("HH:mm")
      : "09:00";

    this.state = {
      showDateReservation: !!dateReservation,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedApplicants: {
        value: this.props.linkedApplicants,
        validators: [
          Validators.arrayMinCount(1, { key: "missingLinkedApplicantsRent" }),
        ],
      },
      rentedUnderReservation: {
        value: !!dateReservation,
        onChange: (ref) => this.setState({ showDateReservation: ref.value }),
      },
      dateReservation: { value: dateReservation || "" },
      dateRentedFrom: {
        value: rentedFrom || "",
        validators: [Validators.required()],
      },
      dateRentedUntil: {
        value: rentedUntil || "",
        validators: [Validators.required()],
      },
      transactionPrice: {
        value: value(this.props, "rentOffer.rentPrice", 0),
      },
      isVacancyLaw: {
        value: false,
      },
      dateSignDeed: {
        value: dateSignDeed,
        validators: [Validators.required()],
      },
      timeSignDeed: {
        value: timeSignDeed,
        validators: [Validators.required()],
      },
      isTransactionInformationConfidential: {
        value:
          this.props.isTransactionInformationConfidential === true
            ? true
            : false,
      },
    };

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onCancelHandler = this.onCancelHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentStatusModalRentedComponentProps
  ) {
    if (nextProps.changeAvailabilityStatus === REQUEST.SUCCESS) {
      this.props.onCloseAvailabilityModal();
    }

    if (
      !!nextProps.linkedApplicants &&
      !equal(nextProps.linkedApplicants, this.props.linkedApplicants)
    ) {
      this.formRef.update({
        linkedApplicants: value(nextProps, "linkedApplicants", []),
      });
    }
  }

  public render() {
    return (
      <div styleName="status-modal" ref={(ref) => (this.focusRef = ref)}>
        <Form
          name="rented"
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
          onSubmit={this.onSubmitHandler}
        >
          <h1>
            <ResourceText
              resourceKey="rentedStatusTitle"
              values={{ displayName: this.props.displayName }}
              asHtml
            />
          </h1>

          {/* Linked applicants */}
          <div className="form__row">
            <label htmlFor="linkedApplicants">
              <ResourceText resourceKey="linkedApplicantsRented" />
            </label>
            <Input.RelationQuery
              name="linkedApplicants"
              multiple
              onAdd={this.addNewRelation}
              onNavigateToRelation={this.props.navigate}
            />
          </div>

          {/* Rented under reservation */}
          <div className="form__row">
            <Input.Switch
              name="rentedUnderReservation"
              on={true}
              off={false}
              label="rentedUnderReservation"
              labelPosition={SwitchLabelPosition.Pre}
            />
          </div>

          {/* Date reservation */}
          {this.state.showDateReservation && (
            <div className="form__row">
              <label htmlFor="">
                <ResourceText resourceKey="dateReservation" />
              </label>
              <Input.Datepicker name="dateReservation" />
            </div>
          )}

          <div className="form__row">
            <div className="form__group stretch">
              {/* Date rented from */}
              <div className="column">
                <label htmlFor="dateRentedFrom">
                  <ResourceText resourceKey="dateRentedFrom" />
                </label>
                <Input.Datepicker name="dateRentedFrom" />
              </div>
              <div className="column__spacer" />
              {/* Date rented untill */}
              <div className="column">
                <label htmlFor="dateRentedUntil">
                  <ResourceText resourceKey="dateRentedUntil" />
                </label>
                <Input.Datepicker name="dateRentedUntil" />
              </div>
            </div>
          </div>

          {/* Transaction price */}
          <div className="form__row">
            <label htmlFor="transactionPrice">
              <ResourceText resourceKey="transactionPriceRented" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="transactionPrice" pretty step={100} asPrice />
              <div className="post full">
                <ResourceText
                  masterKey="rentConditions"
                  resourceKey={value(
                    this.props,
                    "rentOffer.rentCondition",
                    RentCondition.PricePerMonth
                  ).toString()}
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <label htmlFor="dateSignDeed">
              <ResourceText resourceKey="rentTransaction.dateSignDeed" />
            </label>
            <div className="form__group">
              <div className="column">
                <Input.Datepicker name="dateSignDeed" />
              </div>
              <div className="column__textspacer">
                <label htmlFor="timeSignDeed">
                  <ResourceText resourceKey="rentTransaction.timeSignDeed" />
                </label>
              </div>
              <div className="column">
                <Input.Time name="timeSignDeed" />
              </div>
            </div>
          </div>

          {/* Vacancy law */}
          {this.props.realEstateGroup !== RealEstateGroup.Agricultural && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <Input.Switch
                    name="isVacancyLaw"
                    label="isVacancyLaw"
                    on={true}
                    off={false}
                    labelPosition={SwitchLabelPosition.Pre}
                  />
                </div>
              </div>
            </div>
          )}

          {this.props.realEstateGroup === RealEstateGroup.Commercial && (
            <div className="form__row">
              <Input.Switch
                name="isTransactionInformationConfidential"
                on={false}
                off={true}
                label="isTransactionInformationConfidential"
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          )}
        </Form>

        <div styleName="actions">
          <button
            type="button"
            className="btn"
            onClick={this.onCancelHandler}
            disabled={this.props.statusState === REQUEST.PENDING}
          >
            <ResourceText resourceKey="cancel" />
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.onSaveClickHandler}
            disabled={this.props.statusState === REQUEST.PENDING}
          >
            <ButtonLoader
              resourceKey="saveAsRented"
              loading={this.props.changeAvailabilityStatus === REQUEST.PENDING}
            />
          </button>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  private onSaveClickHandler() {
    if (this.formRef && this.props.statusState !== REQUEST.PENDING)
      this.formRef.submit();
  }

  private onCancelHandler() {
    if (this.props.statusState !== REQUEST.PENDING) this.props.onCancel();
  }

  private onSubmitHandler(values: FormReturnValue) {
    if (this.props.statusState === REQUEST.PENDING) return;
    const dateReservation = values.rentedUnderReservation
      ? values.dateReservation
      : undefined;

    this.props.rentAssignment(
      this.props.assignmentId,
      values.dateRentedFrom,
      values.dateRentedUntil,
      values.transactionPrice,
      values.linkedApplicants,
      values.isVacancyLaw,
      dateReservation,
      DateUtil.dateAndTimeToDate(values.dateSignDeed, values.timeSignDeed),
      values.isTransactionInformationConfidential
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedApplicants } = this.formRef.getValues();
    linkedApplicants = linkedApplicants || [];

    this.formRef.update({
      linkedApplicants: [...linkedApplicants, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
