import { intlContext } from "@haywork/app";
import { MAINROUTES, SETTINGSROUTES } from "@haywork/constants";
import { AppState } from "@haywork/stores";
import { createSelector } from "reselect";

const editableSelector = (state: AppState) =>
  state.editable.states.map((state) => ({
    active: state.active,
    title: state.title,
  }));
const pathnameSelector = (state: AppState) => state.router.location.pathname;

const translate = (id: string) =>
  intlContext.formatMessage({ id, defaultMessage: id });

export const appTitle = createSelector(
  editableSelector,
  pathnameSelector,
  (editables, pathname) => {
    const activeTab = editables.find((editable) => editable.active);
    const title = [];

    if (activeTab?.title) {
      title.push(activeTab.title);
    }

    switch (true) {
      case pathname.includes(MAINROUTES.ACQUISITIONS.URI): {
        title.push(translate("OutlookbarAcquisitions"));
        break;
      }
      case pathname.includes(MAINROUTES.APP_XCHANGE.URI):
      case pathname.includes(SETTINGSROUTES.APP_XCHANGE.URI): {
        title.push(translate("OutlookbarAppXchange"));
        break;
      }
      case pathname.includes(MAINROUTES.ASSIGNMENTS.URI): {
        title.push(translate("OutlookbarContracts"));
        break;
      }
      case pathname.includes(MAINROUTES.DASHBOARD.URI): {
        title.push(translate("OutlookbarDashboard"));
        break;
      }
      case pathname.includes(MAINROUTES.DYNAMICDOCUMENTS.URI): {
        title.push(translate("settings.documents.title"));
        break;
      }
      case pathname.includes(MAINROUTES.EMAIL.URI): {
        title.push(translate("OutlookbarMail"));
        break;
      }
      case pathname.includes(MAINROUTES.FINANCE.URI): {
        title.push(translate("OutlookbarInvoices"));
        break;
      }
      case pathname.includes(MAINROUTES.KEYBOARD.URI): {
        title.push(translate("OutlookbarKeyBoard"));
        break;
      }
      case pathname.includes(MAINROUTES.MLS.URI): {
        title.push(translate("OutlookbarMLS"));
        break;
      }
      case pathname.includes(MAINROUTES.NOTIFICATIONS.URI): {
        title.push(translate("OutlookbarNotifications"));
        break;
      }
      case pathname.includes(MAINROUTES.OBJECTTYPES.URI): {
        title.push(translate("pageTitle.project.objectTypes"));
        break;
      }
      case pathname.includes(MAINROUTES.PROJECTS.URI): {
        title.push(translate("browserTitle.projects"));
        break;
      }
      case pathname.includes(MAINROUTES.RELATIONS.URI): {
        title.push(translate("OutlookbarContacts"));
        break;
      }
      case pathname.includes(MAINROUTES.SCHEDULER.URI): {
        title.push(translate("OutlookbarAgenda"));
        break;
      }
      case pathname.includes(MAINROUTES.SEARCHASSIGNMENT.URI): {
        title.push(translate("OutlookbarSearchProfiles"));
        break;
      }
      case pathname.includes(MAINROUTES.SETTINGS.URI): {
        title.push(translate("Instellingen"));
        break;
      }
      case pathname.includes(MAINROUTES.TASKS.URI): {
        title.push(translate("OutlookbarTasks"));
        break;
      }
      default: {
        break;
      }
    }

    title.push("Kolibri");

    return title.join(" - ");
  }
);
