import { ObjectAssignment } from "@haywork/api/kolibri";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../detail-complete.component.scss");

export interface AssignmentDetailCompleteAgriculturalSpecificationComponentProps {
  assignment: ObjectAssignment;
  renderSection: (section: { title: string; data: Array<any> }) => void;
}
type Props = AssignmentDetailCompleteAgriculturalSpecificationComponentProps;

export const AssignmentDetailCompleteAgriculturalSpecificationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignment, renderSection }) => {
      // Render
      return (
        <>
          {renderSection({
            title: "construction",
            data: [
              {
                type: "secondaryListingTypes",
                prefix: "listingTypes",
                values: get(assignment, "secondaryListingTypes", [null]),
              },
              {
                type: "isBiologicalCompanySpec",
                prefix: "isBiologicalCompany",
                values: [get(assignment, "isBiologicalCompany")],
              },
              {
                type: "isSKALCertifiedSpec",
                prefix: "isSKALCertified",
                values: [get(assignment, "isSKALCertified")],
              },
              {
                type: "hasHygieneBarrierSpec",
                prefix: "hasHygieneBarrier",
                values: [get(assignment, "hasHygieneBarrier")],
              },
              {
                type: "numberOfMooringPlacesMilkingCattleSpec",
                prefix: "numberOfMooringPlacesMilkingCattleSpec",
                values: [get(assignment, "numberOfMooringPlacesMilkingCattle")],
              },
              {
                type: "numberOfAnimalPlacesSpec",
                prefix: "numberOfAnimalPlacesSpec",
                values: [get(assignment, "numberOfAnimalPlaces")],
              },
              {
                type: "horseTroughLocationIndoorSpec",
                prefix: "horseTroughLocationIndoorSpec",
                values: [get(assignment.horseTroughLocation, "Indoor")],
              },
              {
                type: "horseTroughLocationOutdoorSpec",
                prefix: "horseTroughLocationOutdoorSpec",
                values: [get(assignment.horseTroughLocation, "Outdoor")],
              },
              {
                type: "stepMillSpec",
                prefix: "stepMill",
                values: [get(assignment, "stepMill")],
              },
            ],
          })}
        </>
      );
    }
  )
);
