import ActionList, { Spacer } from "@haywork/components/ui/action-list";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, MouseEvent, useCallback, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  visible?: boolean;
  onShow?: () => void;
  onHide?: () => void;
};

export const ActionsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, visible, onHide, onShow }) => {
      const ref = useRef<HTMLDivElement>(null);

      const toggleVisibility = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          if (!onHide || !onShow) return;
          !visible ? onShow() : onHide();
        },
        [visible, onHide, onShow]
      );

      return (
        <>
          <div styleName="actions" onClick={toggleVisibility} ref={ref}>
            <Icon name="ellipsis-v" size={24} color={Colors.Gray} light />
          </div>
          <ActionList visible={visible} parent={ref} onHide={onHide}>
            <Spacer label="actions" />
            {children}
          </ActionList>
        </>
      );
    }
  )
);

ActionsComponent.displayName = "ActionsColumn";
