import { CompanyListing } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { WithIntlProps, injectIntl } from "react-intl";

const styles = require("./list-item.component.scss");

interface CompanyListingsItemComponentProps {
  companyListing: CompanyListing;
  idx: number;
  onClick: (uri: string) => void;
}
type Props = CompanyListingsItemComponentProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class CompanyListingsItem extends React.Component<Props> {
  public render() {
    return (
      <div
        styleName="item"
        onClick={() => this.props.onClick(this.props.companyListing.id)}
      >
        <div styleName="item__icon">
          <i className="fal fa-fw fa-home" />
        </div>
        <div styleName="item__meta">
          <h2>
            <ResourceText
              resourceKey="companyListingNumbered"
              values={{ number: this.props.idx + 1 }}
            />
          </h2>
          {this.renderDetailsString()}
        </div>
      </div>
    );
  }

  private renderDetailsString(): React.ReactElement<HTMLDivElement> {
    const details: string[] = [];
    const { companyListing, intl } = this.props;
    const {
      situatedType,
      useSurface,
      roomNumber,
      bedroomNumber,
      content
    } = companyListing;

    if (!!situatedType) {
      const message = intl.formatMessage({
        id: `situatedTypeOptions.${situatedType.toString()}`,
        defaultMessage: `situatedTypeOptions.${situatedType.toString()}`
      });

      details.push(message);
    }

    if (has(useSurface, "area")) {
      const affix = intl.formatHTMLMessage({
        id: "squareMeters",
        defaultMessage: "squareMeters"
      });

      details.push(`${get(useSurface, "area")}${affix}`);
    }

    if (!!roomNumber || !!bedroomNumber) {
      let message: string;

      if (!!roomNumber && !!bedroomNumber) {
        message = intl.formatMessage(
          {
            id: "roomsOfWhichBedrooms",
            defaultMessage: "roomsOfWhichBedrooms"
          },
          {
            rooms: roomNumber,
            bedrooms: bedroomNumber
          }
        );
      } else if (!!roomNumber) {
        message = intl.formatMessage(
          {
            id: "roomCount",
            defaultMessage: "roomCount"
          },
          {
            rooms: roomNumber
          }
        );
      } else {
        message = intl.formatMessage(
          {
            id: "bedroomCount",
            defaultMessage: "bedroomCount"
          },
          {
            bedrooms: bedroomNumber
          }
        );
      }

      details.push(message);
    }

    if (has(content, "volume")) {
      const affix = intl.formatHTMLMessage({
        id: "cubicMeters",
        defaultMessage: "cubicMeters"
      });

      details.push(`${content.volume}${affix}`);
    }

    if (details.length === 0) return null;

    return <div dangerouslySetInnerHTML={{ __html: details.join(", ") }} />;
  }
}

export const CompanyListingsItemComponent = injectIntl(CompanyListingsItem);
