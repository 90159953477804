import {
  EnergyClassOption,
  EnergySourceOption,
  HeatingMethod,
  HeatingMethodOption,
  HeatingWaterMethod,
  HeatingWaterMethodOption,
  IsolationTypeOption,
  ObjectAssignment,
  OwnershipOption,
} from "@haywork/api/kolibri";
import { EXTERNALROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment;
  energyClassOptions: EnergyClassOption[];
  isolationTypeOptions: IsolationTypeOption[];
  heatingMethodOptions: HeatingMethodOption[];
  energySourceOptions: EnergySourceOption[];
  ownershipOptions: OwnershipOption[];
  heatingWaterMethodOptions: HeatingWaterMethodOption[];

  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditContentEnergyAndCostsComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const {
      energyLabel,
      heating,
      insulationTypes,
      boilerYearOfConstruction,
      boilerType,
      hasCombiBoiler,
      boilerFuel,
      boilerProperty,
      warmWaterTypes,
    } = this.props.objectAssignment;

    this.formControls = {
      energyClass: { value: value(energyLabel, "energyClass") },
      energyIndex: { value: value(energyLabel, "energyIndex") },
      energyEndDate: { value: value(energyLabel, "endDate") },
      energyCertificateNumber: {
        value: value(energyLabel, "certificateNumber"),
      },
      insulationTypes: { value: insulationTypes },
      heating: { value: heating },
      warmWaterTypes: { value: warmWaterTypes },
      boilerYearOfConstruction: { value: boilerYearOfConstruction },
      boilerType: { value: boilerType },
      hasCombiBoiler: { value: hasCombiBoiler },
      boilerFuel: { value: boilerFuel },
      boilerProperty: { value: boilerProperty },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const {
        energyLabel,
        heating,
        insulationTypes,
        boilerYearOfConstruction,
        boilerType,
        hasCombiBoiler,
        boilerFuel,
        boilerProperty,
        warmWaterTypes,
      } = this.props.objectAssignment;

      this.formRef.update(
        {
          energyClass: value(energyLabel, "energyClass"),
          energyIndex: value(energyLabel, "energyIndex"),
          energyEndDate: value(energyLabel, "endDate"),
          energyCertificateNumber: value(energyLabel, "certificateNumber"),
          insulationTypes,
          heating,
          warmWaterTypes,
          boilerYearOfConstruction,
          boilerType,
          hasCombiBoiler,
          boilerFuel,
          boilerProperty,
        },
        true
      );
    }
  }

  public render() {
    const heating = value(this.props.objectAssignment, "heating", []);
    const warmWater = value(this.props.objectAssignment, "warmWaterTypes", []);
    const hasBoiler =
      heating.indexOf(HeatingMethod.Boiler) !== -1 ||
      warmWater.indexOf(HeatingWaterMethod.Boiler) !== -1;

    return (
      <Form
        name="energy-costs"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <div className="form__row">
          <label htmlFor="energyClass">
            <ResourceText resourceKey="energyClass" />
            <Ui.InfoLink
              supportLink={EXTERNALROUTES.SUPPORT_ENERGYLABEL.URI}
              message="infoLink.fundaEnergyLabel"
            />
          </label>
          <Input.RadioGroup
            name="energyClass"
            asButtonList
            styleName="energy-class"
          >
            {this.props.energyClassOptions.map((energyClass, idx) => (
              <Input.Radio
                value={energyClass.value}
                label={energyClass.displayName}
                key={idx}
                allowUnCheck={true}
                className={`energyclass-${energyClass.value
                  .toString()
                  .toLowerCase()
                  .slice(0, 1)}`}
              />
            ))}
          </Input.RadioGroup>
        </div>

        <div className="form__row">
          <div className="form__group stretch">
            <div className="column">
              <label htmlFor="energyEndDate">
                <ResourceText resourceKey="energyEndDate" />
              </label>
              <Input.Datepicker name="energyEndDate" />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="energyIndex">
                <ResourceText resourceKey="energyIndex" />
              </label>
              <Input.Number
                name="energyIndex"
                placeholder="energyIndexPlaceholder"
                pretty
                min={0}
                max={9.99}
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="energyCertificateNumber">
                <ResourceText resourceKey="energyCertificateNumber" />
              </label>
              <Input.Text
                name="energyCertificateNumber"
                placeholder="certificateNumberPlaceHolder"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="insulationTypes">
            <ResourceText resourceKey="insulationTypes" />
          </label>
          <Input.Multi
            name="insulationTypes"
            values={this.props.isolationTypeOptions}
          />
        </div>

        <div className="form__row">
          <label htmlFor="heating">
            <ResourceText resourceKey="heating" />
          </label>
          <Input.Multi
            name="heating"
            values={this.props.heatingMethodOptions}
          />
        </div>

        <div className="form__row">
          <label htmlFor="warmWaterTypes">
            <ResourceText resourceKey="warmWaterTypes" />
          </label>
          <Input.Multi
            name="warmWaterTypes"
            values={this.props.heatingWaterMethodOptions}
          />
        </div>

        {hasBoiler && (
          <React.Fragment>
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="boilerYearOfConstruction">
                    <ResourceText resourceKey="boilerYearOfConstruction" />
                  </label>
                  <Input.Number name="boilerYearOfConstruction" />
                </div>
                <div className="column__spacer" />
                <div className="column push-label">
                  <Input.Switch
                    name="hasCombiBoiler"
                    label="hasCombiBoiler"
                    labelPosition={SwitchLabelPosition.Pre}
                    on={true}
                    off={false}
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <label htmlFor="boilerType">
                <ResourceText resourceKey="boilerType" />
              </label>
              <Input.Text name="boilerType" />
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column no-wrap">
                  <label htmlFor="boilerFuel">
                    <ResourceText resourceKey="boilerFuel" />
                  </label>
                  <Input.RadioGroup name="boilerFuel" asButtonList canUnselect>
                    {this.props.energySourceOptions.map(
                      (energySourceOption, idx) => (
                        <Input.Radio
                          value={energySourceOption.value}
                          label={energySourceOption.displayName}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>
                <div className="column__spacer" />
                <div className="column no-wrap">
                  <label htmlFor="boilerProperty">
                    <ResourceText resourceKey="boilerProperty" />
                  </label>
                  <Input.RadioGroup
                    name="boilerProperty"
                    asButtonList
                    canUnselect
                  >
                    {this.props.ownershipOptions.map((ownershipOption, idx) => (
                      <Input.Radio
                        value={ownershipOption.value}
                        label={ownershipOption.displayName}
                        key={idx}
                      />
                    ))}
                  </Input.RadioGroup>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let value = {
      energyLabel: {
        ...objectAssignment.energyLabel,
        energyClass: values.energyClass,
        endDate: values.energyEndDate,
        certificateNumber: values.energyCertificateNumber,
        energyIndex: values.energyIndex,
      },
      insulationTypes: values.insulationTypes,
      heating: values.heating || [],
      boilerYearOfConstruction: values.boilerYearOfConstruction,
      boilerType: values.boilerType,
      hasCombiBoiler: values.hasCombiBoiler,
      boilerProperty: values.boilerProperty,
      boilerFuel: values.boilerFuel,
      warmWaterTypes: values.warmWaterTypes || [],
    };

    const heating = value.heating || [];
    const warmWaterTypes = value.warmWaterTypes || [];

    if (
      heating.indexOf(HeatingMethod.Boiler) === -1 &&
      warmWaterTypes.indexOf(HeatingWaterMethod.Boiler) === -1
    ) {
      value = {
        ...value,
        boilerYearOfConstruction: undefined,
        boilerType: undefined,
        hasCombiBoiler: false,
        boilerProperty: undefined,
        boilerFuel: undefined,
      };
    }

    this.props.onChange(value);
  }
}
