import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";
import { RelationType } from "@haywork/api/kolibri";
import { RelationNewContainerProps } from "@haywork/modules/relation";

const styles = require("./new.component.scss");

export interface RelationNewComponentProps {}
interface RelationNewComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class RelationNewComponent extends React.Component<
  RelationNewComponentProps & RelationNewContainerProps,
  RelationNewComponentState
> {
  public render() {
    return (
      <div styleName="new">
        <div styleName="new__inner">
          <h1>
            <ResourceText resourceKey="createNewRelationTitle" />
          </h1>
          <div styleName="new__types">
            <div
              styleName="type"
              onClick={() => this.createNewRelation(RelationType.ContactPerson)}
              id="addNewContactPerson"
            >
              <i className="fal fa-user" />
              <ResourceText resourceKey="ContactPerson" />
            </div>

            <div
              styleName="type"
              onClick={() =>
                this.createNewRelation(RelationType.ContactCompany)
              }
              id="addNewContactCompany"
            >
              <i className="fal fa-industry" />
              <ResourceText resourceKey="ContactCompany" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private createNewRelation(relationType: RelationType) {
    const { match } = this.props;

    let callerUri;
    if (!!match.params.caller) {
      callerUri = atob(match.params.caller);
    }
    let newName;
    if (!!match.params.newName) {
      newName = match.params.newName;
    }
    let newEmail;
    if (!!match.params.newEmail) {
      newEmail = match.params.newEmail;
    }
    let newPhone;
    if (!!match.params.newPhone) {
      newPhone = match.params.newPhone;
    }

    switch (relationType) {
      case RelationType.ContactPerson:
        return this.props.newContactPerson(
          callerUri,
          newName,
          newEmail,
          newPhone
        );
      case RelationType.ContactCompany:
        return this.props.newContactCompany(
          callerUri,
          newName,
          newEmail,
          newPhone
        );
      default:
        return;
    }
  }
}
