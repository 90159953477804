import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";

import * as ActionType from "./settings.types";
import { NotificationSettings } from "@haywork/api/event-center";

export interface SettingsState {
  notificationSettings: NotificationSettings[];
  notificationSettingsStatus: string;
}

const INITIAL_STATE: SettingsState = {
  notificationSettings: [],
  notificationSettingsStatus: REQUEST.IDLE
};

export const settingsReducer = (state: SettingsState = INITIAL_STATE, action: Action): SettingsState => {
  switch (action.type) {
    case REDUX.EVENTCENTER.SET_NOTIFICATIONS_SETTINGS: {
      const { notificationSettings } = <ActionType.NotificationSettingsResponse> action;

      return {
        ...state,
        notificationSettings,
        notificationSettingsStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.EVENTCENTER.SET_NOTIFICATIONS_SETTINGS_STATUS: {
      const { notificationSettingsStatus } = <ActionType.NotificationSettingsStatus> action;

      return {
        ...state,
        notificationSettingsStatus
      };
    }
    default: {
      return state;
    }
  }
};
