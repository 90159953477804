import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { InputComponentProps } from "../input.component";

interface CheckboxInputComponentProps {
  label?: string;
  subLabel?: string;
  asButton?: boolean;
}
interface CheckboxInputComponentState {}

export class CheckboxComponent extends React.Component<
  CheckboxInputComponentProps & InputComponentProps,
  CheckboxInputComponentState
> {
  private ref: HTMLInputElement;
  private name: string = `${this.props.name}-${uuid()}`;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    const inputCheckBoxStyle = classNames("input__checkbox", {
      disabled: this.props.disabled,
      "as-button": this.props.asButton,
      "no-label": !this.props.label,
    });

    return (
      <div className={inputCheckBoxStyle}>
        <input
          type="checkbox"
          name={this.name}
          id={this.name}
          checked={this.props.value}
          onKeyDown={this.onKeyDownHandler}
          ref={this.bindRef}
          onClick={this.onChangeHandler}
          readOnly
        />
        <label htmlFor={this.name}>
          {!!this.props.label && (
            <ResourceText resourceKey={this.props.label} />
          )}
        </label>
        {this.props.subLabel && (
          <ResourceText resourceKey={this.props.subLabel} asHtml />
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: CheckboxInputComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (this.props.disabled) return;
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onChangeHandler() {
    if (this.props.disabled) return;
    this.props.onChange(!this.props.value);
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
    }
  }
}
