import { LinkedRelation } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";

export interface LinkedPartners {
  partner1: LinkedRelation;
  partner2: LinkedRelation;
}
interface PartnersComponentProps {
  partners: LinkedPartners;
  onClick: (partners: LinkedPartners) => void;
}
interface PartnersComponentState {}

export class PartnersComponent extends React.Component<
  PartnersComponentProps,
  PartnersComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    if (!this.props.partners.partner1 || !this.props.partners.partner2)
      return null;

    const { displayName: partner1Name } = this.props.partners.partner1;
    const { displayName: partner2Name } = this.props.partners.partner2;

    return (
      <div className="partner" onClick={this.onClickHandler}>
        <ResourceText
          resourceKey="linkedPartners"
          values={{ partner1Name, partner2Name }}
          asHtml
        />
      </div>
    );
  }

  private onClickHandler() {
    const partners: LinkedPartners = {
      ...this.props.partners,
      partner1: {
        ...this.props.partners.partner1,
        togetherWithPartner: true,
      },
    };

    this.props.onClick(partners);
  }
}
