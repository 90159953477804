import * as React from "react";
import { FC, memo, useState, useCallback } from "react";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  relations: RelationSnapShot[];
  loading: boolean;
  onCreateRelation: () => void;
  onSelectRelation: (relation: RelationSnapShot) => void;
};

const DuplicatesComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ relations, onCreateRelation, onSelectRelation, loading }) => {
      const [selectedId, setSelectedId] = useState("");

      const toggleSelectedId = useCallback(
        (id: string) => {
          setSelectedId(selectedId === id ? "" : id);
        },
        [selectedId, setSelectedId]
      );

      const selectRelation = useCallback(() => {
        const relation = relations.find(
          (relation) => relation.id === selectedId
        );
        onSelectRelation(relation);
      }, [selectedId, relations]);

      return (
        <>
          <ModalBody>
            <I18n value="duplicateContactModal.duplicates.body" />
            <div styleName="relations">
              {relations.map((relation) => (
                <div
                  key={relation.id}
                  styleName={classNames("relation", {
                    active: relation.id === selectedId,
                  })}
                  onClick={() => toggleSelectedId(relation.id)}
                >
                  <div styleName="relation__dot" />
                  <div
                    styleName="relation__avatar"
                    style={{
                      backgroundImage: !!relation.avatarUrl
                        ? `url(${JSON.stringify(relation.avatarUrl)})`
                        : null,
                    }}
                  >
                    {!relation.avatarUrl && relation.letterAvatar}
                  </div>
                  <div styleName="relation__data">
                    <div styleName="relation__name">{relation.displayName}</div>
                    <div styleName="relation__contact">
                      {!!relation.email && (
                        <div styleName="contact-data">
                          <Icon
                            name="envelope"
                            solid
                            color={Colors.Gray}
                            size={14}
                          />
                          <div styleName="data">{relation.email}</div>
                        </div>
                      )}
                      {!!relation.phoneNumber && (
                        <div styleName="contact-data">
                          <Icon
                            name="phone"
                            solid
                            color={Colors.Gray}
                            size={14}
                          />
                          <div styleName="data">{relation.phoneNumber}</div>
                        </div>
                      )}
                      {!!relation.mobileNumber && (
                        <div styleName="contact-data">
                          <Icon
                            name="mobile-alt"
                            solid
                            color={Colors.Gray}
                            size={14}
                          />
                          <div styleName="data">{relation.mobileNumber}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              category="white"
              label="duplicateContactModal.action.createRelation"
              disabled={loading}
              onClick={onCreateRelation}
            />
            <Button
              category="primary"
              label="duplicateContactModal.action.selectRelation"
              onClick={selectRelation}
              disabled={!selectedId || loading}
              icon={
                !loading ? null : (
                  <Icon
                    name="spinner"
                    spin
                    regular
                    containIn={24}
                    color={Colors.Gray}
                  />
                )
              }
            />
          </ModalFooter>
        </>
      );
    }
  )
);

export default DuplicatesComponent;
