import {
  MediaContractStatus,
  PublicationSnapShot,
  PublicationStatus,
  AssignmentPhase,
  AvailabilityStatus,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ButtonLoader } from "@haywork/modules/shared";
import classNames from "classnames";
import * as moment from "moment";
import * as nl2br from "nl2br";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, FormattedTime } from "react-intl";
import I18n from "@haywork/components/i18n";

const styles = require("./list-item.component.scss");

interface Props {
  publication: PublicationSnapShot;
  mediaPartnerState: string;
  mediaPartnerChangedId: string;
  assignmentPhase: AssignmentPhase;
  availabilityStatus: AvailabilityStatus;
  onDeActivateMediaPartner: (id: string) => void;
  onActivateMediaPartner: (id: string) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class MarketingPartnersListItemComponent extends React.PureComponent<
  Props,
  State
> {
  public constructor(props) {
    super(props);
    this.renderActionButton = this.renderActionButton.bind(this);
  }

  public render() {
    const { publication, assignmentPhase, availabilityStatus } = this.props;
    const favoriteStyle = classNames("favorite", {
      "is-favorite": publication.isFavorite,
    });

    return (
      <div styleName="list__item" data-cy={this.props["data-cy"]}>
        <div styleName={favoriteStyle}>
          <i className="fa fa-star" />
        </div>
        <div styleName="label">
          <h3>{publication.mediaPartnerName}</h3>
          <h4>
            <I18n
              prefix="mediaPartnerCategories"
              value={publication.category.toString()}
            />
          </h4>
        </div>
        <div styleName="info">
          {publication.publicationStatus !== PublicationStatus.Inactive &&
            this.renderPublicationStatusString(publication, assignmentPhase)}
        </div>
        <div styleName="status">
          {this.renderMediaPartnerStatusPill(publication)}
        </div>
        <div styleName="action">
          {this.renderActionButton(
            publication,
            availabilityStatus,
            assignmentPhase
          )}
        </div>
      </div>
    );
  }

  private renderPublicationStatusString(
    publication: PublicationSnapShot,
    assignmentPhase: AssignmentPhase
  ): React.ReactElement<HTMLDivElement> {
    let statusState;
    let icon;
    let statusMessage = publication.statusMessage;

    switch (publication.publicationStatus) {
      case PublicationStatus.Rejected:
      case PublicationStatus.Withdrawn:
        statusState = "error";
        icon = "fa-exclamation-circle";
        break;
      case PublicationStatus.PublishRequestEnqueued:
        statusState = "idle";
        icon = "fa-clock";
        break;
      case PublicationStatus.Offered:
        statusState = "success";
        icon = "fa-envelope-square";
        statusMessage = "publicationNotification";
        break;
      case PublicationStatus.WaitingForExchangeEntityContract:
      case PublicationStatus.WaitingForObligatoryPublications:
        statusState = "warning";
        icon = "fa-exclamation-triangle";
        break;
      default:
        statusState = "success";
        icon = "fa-check-circle";
        break;
    }

    const statusStyle = classNames("pubstatus", statusState);
    const iconStyle = classNames("fal", icon);

    return (
      <div styleName={statusStyle}>
        <div styleName="pubstatus__title">
          <i className={iconStyle} />
          {assignmentPhase.toString() + "xxxx"}
          {assignmentPhase === AssignmentPhase.Concept &&
          publication.publicationStatus ===
            PublicationStatus.PublishRequestEnqueued ? (
            <I18n value="publicationStatus.enqueued.inConcept" />
          ) : (
            <I18n
              prefix="publicationStatuses"
              value={publication.publicationStatus.toString()}
            />
          )}
        </div>
        {publication.dateTimeModified && (
          <div styleName="pubstatus__message">
            {publication.publicationStatus !== PublicationStatus.Rejected &&
              assignmentPhase !== AssignmentPhase.Concept && (
                <span>
                  <I18n value="lastTimeOffered" />{" "}
                  <FormattedDate value={publication.dateTimeModified} />{" "}
                  <FormattedTime value={publication.dateTimeModified} />
                </span>
              )}

            {publication.publicationStatus === PublicationStatus.Rejected && (
              <span
                dangerouslySetInnerHTML={{ __html: nl2br(statusMessage) }}
              />
            )}
          </div>
        )}
        {!!publication.detailsUrl && (
          <div styleName="pubstatus__url">
            <i className="far fa-external-link" />
            <a href={publication.detailsUrl} target="_blank">
              {publication.detailsUrl}
            </a>
          </div>
        )}
      </div>
    );
  }

  private renderMediaPartnerStatusPill(
    publication: PublicationSnapShot
  ): React.ReactElement<HTMLDivElement> {
    if (!!publication.mediaPartnerIsActive) return null;

    const warn = [
      MediaContractStatus.PendingActivation,
      MediaContractStatus.PendingDeactivation,
    ];
    const error = [
      MediaContractStatus.Denied,
      MediaContractStatus.Inactive,
      MediaContractStatus.Suspended,
    ];

    const pillStyle = classNames("pill", {
      warn: warn.indexOf(publication.mediaContractStatus) !== -1,
      error: error.indexOf(publication.mediaContractStatus) !== -1,
    });
    const label = !publication.mediaPartnerIsActive
      ? publication.mediaContractStatus.toString()
      : null;

    if (!label) return null;

    return (
      <div styleName={pillStyle}>
        <I18n prefix="mediaContractStatusOptions" value={label} />
      </div>
    );
  }

  private renderActionButton(
    publication: PublicationSnapShot,
    availabilityStatus: AvailabilityStatus,
    assignmentPhase: AssignmentPhase
  ): React.ReactElement<HTMLDivElement> {
    const disabledStatuses = [
      AvailabilityStatus.Farmed,
      AvailabilityStatus.Leased,
      AvailabilityStatus.Rented,
      AvailabilityStatus.Sold,
      AvailabilityStatus.Withdrawn,
    ];

    if (
      !publication.mediaPartnerIsActive ||
      assignmentPhase === AssignmentPhase.Completed ||
      disabledStatuses.includes(availabilityStatus)
    ) {
      return null;
    }

    switch (publication.publicationStatus) {
      default:
      case PublicationStatus.WaitingForObligatoryPublications:
        return null;
      case PublicationStatus.WaitingForExchangeEntityContract:
      case PublicationStatus.PublishRequestEnqueued: {
        const now = moment();
        if (
          !!publication.dateTimeModified &&
          moment(now).diff(publication.dateTimeModified, "hours", true) < 1
        ) {
          return null;
        }
        return (
          <div
            className="btn btn-success"
            onClick={() =>
              this.props.onActivateMediaPartner(publication.mediaPartnerId)
            }
          >
            <ButtonLoader
              loading={
                this.props.mediaPartnerState === REQUEST.PENDING &&
                publication.mediaPartnerId === this.props.mediaPartnerChangedId
              }
              resourceKey="offerAgain"
            />
          </div>
        );
      }
      case PublicationStatus.Inactive:
        return (
          <div
            className="btn btn-success"
            onClick={() =>
              this.props.onActivateMediaPartner(publication.mediaPartnerId)
            }
            data-cy={
              this.props["data-cy"] &&
              `${this.props["data-cy"]}.PublicationInactiveActionButton`
            }
          >
            <ButtonLoader
              loading={
                this.props.mediaPartnerState === REQUEST.PENDING &&
                publication.mediaPartnerId === this.props.mediaPartnerChangedId
              }
              resourceKey="toOffer"
            />
          </div>
        );
      case PublicationStatus.Rejected:
        return (
          <div
            className="btn btn-success"
            onClick={() =>
              this.props.onActivateMediaPartner(publication.mediaPartnerId)
            }
            data-cy={
              this.props["data-cy"] &&
              `${this.props["data-cy"]}.PublicationRejectedActionButton`
            }
          >
            <ButtonLoader
              loading={
                this.props.mediaPartnerState === REQUEST.PENDING &&
                publication.mediaPartnerId === this.props.mediaPartnerChangedId
              }
              resourceKey="offerAgain"
            />
          </div>
        );
      case PublicationStatus.Withdrawn:
        return (
          <div className="btn btn-blank disabled">
            <I18n value="toOffer" />
          </div>
        );
    }
  }
}
