import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { ConfirmSubscriptionContainerProps } from "./container";
import { ButtonLoader } from "@haywork/modules/shared";
import noop from "lodash-es/noop";

const styles = require("./style.scss");

export type ConfirmSubscriptionProps = {
  visible: boolean;
  handleMlsSubscribe: () => Promise<void>;
  onCloseHandler: () => void;
};

type Props = ConfirmSubscriptionProps & ConfirmSubscriptionContainerProps;

export const ConfirmSubscriptionComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, onCloseHandler, handleMlsSubscribe }) => {
      return (
        <Modal visible={visible} onClose={onCloseHandler}>
          <ModalHeader title="mls.modal.confirmSubscription.title" close />
          <ModalBody>
            <div styleName="text">
              <I18n value="mls.modal.confirmSubscription.text" asHtml />
            </div>
            <div styleName="actions">
              <button styleName="btn" className="btn" onClick={onCloseHandler}>
                <ButtonLoader
                  resourceKey="mls.modal.modalconfirmSubscription.button.cancel"
                  loading={false}
                />
              </button>
              <button
                styleName="btn"
                className="btn btn-primary"
                onClick={handleMlsSubscribe}
              >
                <ButtonLoader
                  resourceKey="mls.modal.modalconfirmSubscription.button.confirm"
                  loading={false}
                />
              </button>
            </div>
          </ModalBody>
        </Modal>
      );
    }
  )
);
