import { RootEntityType } from "@haywork/api/event-center";
import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import {
  DynamicDocumentsThunks,
  EditableThunks,
  MapDispatchToProps,
} from "@haywork/middleware";
import {
  DynamicDocumentsDetailComponent,
  DynamicDocumentsDetailComponentProps,
} from "@haywork/modules/dynamic-documents";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import {
  AppState,
  DynamicDocumentsPreviewActions,
  DynamicDocumentsSingleActions,
  EditableActions,
  LayoutActions,
} from "@haywork/stores";
import { DynamicDocumentsUtil } from "@haywork/util";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  session: DocumentSession;
  template: DocumentTemplate;
  fullscreen: boolean;
  documentStatus: string;
  showSaveModal: boolean;
  canSave: boolean;
}
interface DispatchProps {
  toggleFullscreen: () => void;
  saveAndCloseDynamicDocument: () => void;
  saveInitialDynamicDocument: (path: string) => void;
  toggleSaveModal: (status: boolean) => void;
  stateDisablePreppedForSave: (path: string) => void;
  refreshObjectAssignment: () => void;
  closeDocument: (path: string) => void;
  clearHtmlPreview: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsDetailComponentProps,
  AppState
> = (state) => {
  const { template, documentStatus, showSaveModal } =
    state.dynamicDocuments.single;
  const { fullscreen } = state.layout;
  const canSave = DynamicDocumentsUtil.validForSave(
    get(state.editable.currentComponentState, "session"),
    template.requiresObjectAssignment
  );
  const session = get(state.editable.currentComponentState, "session");

  return {
    session,
    template,
    fullscreen,
    documentStatus,
    canSave,
    showSaveModal,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsDetailComponentProps
> = (dispatch) => ({
  toggleFullscreen: () => dispatch(LayoutActions.toggleFullscreen()),
  saveAndCloseDynamicDocument: () =>
    dispatch(DynamicDocumentsThunks.saveDynamicDocument(true)),
  saveInitialDynamicDocument: (path: string) =>
    dispatch(DynamicDocumentsThunks.saveDynamicDocument(false, path)),
  toggleSaveModal: (status: boolean) =>
    dispatch(DynamicDocumentsSingleActions.toggleSaveModal(status)),
  stateDisablePreppedForSave: (path: string) =>
    dispatch(EditableActions.stateDisablePreppedForSave({ path })),
  refreshObjectAssignment: () =>
    dispatch(DynamicDocumentsThunks.refreshObjectAssignment()),
  closeDocument: (path: string) => {
    dispatch(EditableThunks.remove(path));
    dispatch(LayoutActions.toggleFullscreen(false));
  },
  clearHtmlPreview: () =>
    dispatch(DynamicDocumentsPreviewActions.clearHtmlPreview()),
});

export type DynamicDocumentsDetailContainerProps = StateProps &
  DispatchProps &
  EditableHocProps &
  RouteComponentProps<any>;
export const DynamicDocumentsDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(DynamicDocumentsDetailComponent)
  ),
  {
    entityType: RootEntityType.DocumentSession,
    icon: "folder-open",
    thunk: DynamicDocumentsThunks.getDocument,
    status: "dynamicDocuments.single.documentStatus",
    statePath: "dynamicDocuments.single",
    action: DynamicDocumentsSingleActions.setDocument,
    route: DYNAMICDOCUMENTROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveDynamicDocumentConfirmTitle" },
      body: { key: "saveDynamicDocumentConfirmBody" },
    },
  }
);
