import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SingleFilterValue } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";

const employeesSelector = (state: AppState) => state.employee.employees;
const categorySelector = (state: AppState) =>
  state.main.mastertable.kolibri.taskCategories;
const taskStatusSelector = (state: AppState) =>
  state.main.mastertable.kolibri.taskStatuses;

export const employeeValues = createSelector(employeesSelector, (employees) => {
  return (employees || []).map(
    (employee) =>
      ({
        label: employee.displayName,
        value: employee.id,
      } as SingleFilterValue)
  );
});

export const categoryValues = createSelector(categorySelector, (categories) => {
  return (categories || []).map(
    (category) =>
      ({
        label: category.displayName,
        value: category.value,
      } as SingleFilterValue)
  );
});

export const taskStatusValues = createSelector(
  taskStatusSelector,
  (taskStatuses) => {
    return (taskStatuses || []).map(
      (taskStatus) =>
        ({
          label: taskStatus.displayName,
          value: taskStatus.value,
        } as SingleFilterValue)
    );
  }
);

export const activeFilterGuidValues = createSelector(
  employeesSelector,
  categorySelector,
  (employees, taskCategories) => {
    return {
      employees: (employees || []).map((employee) => ({
        value: employee.displayName,
        id: employee.id,
      })),
      taskCategories: (taskCategories || []).map((category) => ({
        value: category.displayName,
        id: category.value,
      })),
    } as FilterGuidValues;
  }
);
