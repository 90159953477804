import { EmployeeRole } from "@haywork/api/kolibri";
import { StatisticsClient } from "@haywork/api/statistics";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import { AppState, StatisticsActions } from "@haywork/stores";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();

const getCompanyStatistics = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(StatisticsActions.setCompanyStatisticsStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const isBackofficeEmployee =
      state.account.currentRealestateAgency.role === EmployeeRole.BackOffice;
    const isSandboxEnvironment = state.appSettings.environment === "Sandbox";

    if (isBackofficeEmployee || isSandboxEnvironment) {
      dispatch(StatisticsActions.setCompanyStatisticsStatus(REQUEST.SUCCESS));
      return;
    }

    const { statisticsHost } = state.appSettings;
    const client = new StatisticsClient(statisticsHost);

    try {
      const statistics = await parseRequest.response(
        client.read(undefined, realEstateAgencyId),
        undefined,
        { displayError: false }
      );

      dispatch(StatisticsActions.setCompanyStatistics({ statistics }));
    } catch (error) {
      dispatch(StatisticsActions.setCompanyStatisticsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getEmployeeStatistics = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(StatisticsActions.setEmployeeStatisticsStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const isBackofficeEmployee =
      state.account.currentRealestateAgency.role === EmployeeRole.BackOffice;
    const isSandboxEnvironment = state.appSettings.environment === "Sandbox";

    if (isBackofficeEmployee || isSandboxEnvironment) {
      dispatch(StatisticsActions.setCompanyStatisticsStatus(REQUEST.SUCCESS));
      return;
    }

    const { statisticsHost } = state.appSettings;
    const client = new StatisticsClient(statisticsHost);

    try {
      const statistics = await parseRequest.response(
        client.read(state.account.employee.id, realEstateAgencyId),
        undefined,
        { displayError: false }
      );

      dispatch(StatisticsActions.setEmployeeStatistics({ statistics }));
    } catch (error) {
      dispatch(StatisticsActions.setEmployeeStatisticsStatus(REQUEST.PENDING));
      throw error;
    }
  };
};

export const StatisticsThunk = {
  getCompanyStatistics,
  getEmployeeStatistics,
};
