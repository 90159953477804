import { ActionType, ActionTypes } from "./actions";

export enum LogType {
  EmailWebSocket = "EmailWebSocket",
  EmailThunk = "EmailThunk",
}

export type LogMessage = {
  logType: LogType;
  label: string;
  payload: string;
};

export type LoggingState = {
  messages: LogMessage[];
};

const INITIAL: LoggingState = {
  messages: [],
};

export const loggingReducer = (
  state = INITIAL,
  action: ActionTypes
): LoggingState => {
  switch (action.type) {
    case ActionType.AddLogging: {
      const messages = [...state.messages, action.message].slice(-1000);
      return {
        ...state,
        messages,
      };
    }
    default: {
      return state;
    }
  }
};
