import {
  ActiveFilter,
  LinkedRelation,
  RelationSnapShot,
  RelationTermField,
  RelationType,
} from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  LinkedPartners,
  PartnersComponent,
} from "@haywork/modules/form/components/input/query-v3/relation/partners.component";
import { Relation } from "@haywork/request";
import { RelationUtil, RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import last from "lodash-es/last";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { InputComponentProps } from "../../input.component";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import { RelationSelectOptionComponent } from "./components/relation-option.component";
import { PartnerPill } from "./partner-pill.component";
import { PartnerComponent } from "./partner.component";
import { SinglePill } from "./single-pill.component";

import CSSModules = require("react-css-modules");

const route = RouteUtil.mapStaticRouteValues;

interface RelationQueryComponentProps {
  multiple?: boolean;
  placeholder?: string;
  termFields?: RelationTermField[];
  filterByActive?: ActiveFilter;
  filterByRelationTypes?: RelationType[];
  numberOfResults?: number;
  withPartnerControl?: boolean;
  valueAsRelationSnapShot?: boolean;
  inputRef?: any;
  returnAsArray?: boolean;
  onNavigateToRelation?: (path: string) => void;
  onAdd?: (query: string) => void;
}

type Props = RelationQueryComponentProps & InputComponentProps;

export const RelationQueryComponentV2 = React.memo(
  CSSModules(
    {},
    { allowMultiple: true }
  )((props: Props) => {
    const {
      valueAsRelationSnapShot,
      multiple,
      onChange,
      placeholder: pPlaceholder,
      value: pValue,
      termFields,
      filterByActive,
      filterByRelationTypes,
      numberOfResults,
      withPartnerControl,
      returnAsArray,
      onAdd,
      onNavigateToRelation,
    } = props;

    let val = isArray(pValue) ? pValue : [pValue];
    val = val.filter((v) => !!v);

    const [stateValue, setStateValue] = useState(val);
    const [placeholder, setPlaceholder] = useState(
      pPlaceholder || "searchRelationPlaceholder"
    );
    const [partnerMarkedForDelete, setPartnerMarkedForDelete] = useState(null);

    const take = numberOfResults || 25;

    const prepareValues = useCallback((values: any[]): LinkedRelation[] => {
      const linkedRelations: LinkedRelation[] = [];

      values.map((relation: LinkedRelation | RelationSnapShot) => {
        if (RelationUtil.isLinkedRelation(relation)) {
          linkedRelations.push(relation);
        } else {
          const {
            id,
            displayName,
            email,
            linkedPartner,
            locality,
            phoneNumber: phone,
            mobileNumber: phoneMobile,
            streetNameAndNumber,
            typeOfRelation,
            avatarUrl,
            letterAvatar,
          } = relation;

          linkedRelations.push({
            id,
            displayName,
            phone,
            phoneMobile,
            locality,
            streetNameAndNumber,
            linkedPartner,
            togetherWithPartner: false,
            typeOfRelation,
            email,
            avatarUrl,
            letterAvatar,
          });
        }
      });

      return linkedRelations;
    }, []);

    const fireOnChange = useCallback(
      (value: any[]) => {
        const mappedValues = !!valueAsRelationSnapShot
          ? value
          : prepareValues(value);
        const mappedValue =
          !!multiple || returnAsArray ? mappedValues : first(mappedValues);
        onChange(mappedValue);
      },
      [prepareValues, onChange]
    );

    const onChangeHandler = useCallback(
      (value: LinkedRelation[]) => {
        fireOnChange(value);
      },
      [fireOnChange]
    );

    const onBackspaceDeleteHandler = useCallback(() => {
      const lastValue: any = last(pValue);
      if (!withPartnerControl || !lastValue || !lastValue.togetherWithPartner)
        return true;

      if (!partnerMarkedForDelete) {
        setPartnerMarkedForDelete(lastValue);
        return false;
      }

      const value = pValue.map((v) => {
        return lastValue.id === v.id ? { ...v, togetherWithPartner: false } : v;
      });

      setStateValue(value);
      setPartnerMarkedForDelete(null);

      fireOnChange(value);

      return false;
    }, [setStateValue, setPartnerMarkedForDelete, fireOnChange]);

    const onRemoveMarkedForDeleteHandler = useCallback(() => {
      setPartnerMarkedForDelete(null);
    }, [setPartnerMarkedForDelete]);

    const onPartnersClickHandler = useCallback(
      (partners: LinkedPartners) => {
        const filtered = stateValue.filter(
          (v) => v.id !== partners.partner2.id
        );
        const value = filtered.map((v) => {
          return v.id === partners.partner1.id ? partners.partner1 : v;
        });
        setStateValue(value);
        fireOnChange(value);
      },
      [fireOnChange, setStateValue, stateValue]
    );

    const mapDisableOption = useCallback(
      (value: RelationSnapShot, values: LinkedRelation[]): boolean => {
        let ids = values.map((v) => v.id);
        if (withPartnerControl) {
          const partners = values.filter(
            (v) =>
              !!v.togetherWithPartner &&
              !!v.linkedPartner &&
              !!v.linkedPartner.id
          );
          const partnerIds = partners.map((v) => v.linkedPartner.id);
          ids = [...ids, ...partnerIds];
        }
        return ids.indexOf(value.id) !== -1;
      },
      []
    );

    const onPartnerClickHandler = useCallback(
      (relation: LinkedRelation) => {
        const value = stateValue.map((v) => {
          return v.id === relation.id ? relation : v;
        });
        setStateValue(value);
        fireOnChange(value);
      },
      [fireOnChange, setStateValue, stateValue]
    );

    const renderPartnerControls = useCallback(() => {
      let linkedRelations = stateValue.filter(
        (v) => !!v.linkedPartner && !v.togetherWithPartner
      );
      const partnerIds = linkedRelations.map((r) => r.linkedPartner.id);
      const matchingPartners = linkedRelations.filter(
        (r) => partnerIds.indexOf(r.id) !== -1
      );
      const linkedPartners: LinkedPartners[] = matchingPartners.reduce(
        (state, partner) => {
          const match = state.find(
            (s) => s.partner1.id === partner.linkedPartner.id
          );
          if (match) {
            match.partner2 = partner;
          } else {
            state.push({
              partner1: partner,
              partner2: null,
            });
          }
          return state;
        },
        []
      );

      linkedRelations = linkedRelations.filter(
        (r) => partnerIds.indexOf(r.id) === -1
      );

      return (
        <div className="partners">
          {linkedRelations.map((linkedRelation, idx) => (
            <ErrorBoundary key={idx}>
              <PartnerComponent
                relation={linkedRelation}
                onClick={(relation) => onPartnerClickHandler(relation)}
              />
            </ErrorBoundary>
          ))}
          {linkedPartners.map((partners, idx) => (
            <ErrorBoundary key={idx}>
              <PartnersComponent
                partners={partners}
                onClick={(partners) => onPartnersClickHandler(partners)}
              />
            </ErrorBoundary>
          ))}
        </div>
      );
    }, [onPartnerClickHandler, onPartnersClickHandler, stateValue]);

    const onRelationClickHandler = useCallback(
      (relation: LinkedRelation, isPartner: boolean = false) => {
        if (!onNavigateToRelation) return;

        const { id, typeOfRelation } = relation;
        let path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });

        if (!isPartner) {
          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            }
            case RelationType.Employee: {
              path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            }
            case RelationType.Office: {
              path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            }
            default: {
              break;
            }
          }
        }

        onNavigateToRelation(path);
      },
      [onNavigateToRelation]
    );

    const onRemoveRelationClickHandler = useCallback(
      (event: React.MouseEvent<HTMLDivElement>, relation: LinkedRelation) => {
        event.stopPropagation();

        const value = stateValue.filter((v) => v.id !== relation.id);

        setStateValue(value);
        fireOnChange(value);
      },
      [setStateValue, fireOnChange, stateValue]
    );

    const onRemovePartnerClickHandler = useCallback(
      (event: React.MouseEvent<HTMLDivElement>, relation: LinkedRelation) => {
        event.stopPropagation();

        const value = stateValue.map((v) => {
          return v.id === relation.id
            ? { ...relation, togetherWithPartner: false }
            : v;
        });

        setStateValue(value);
        fireOnChange(value);
      },
      [setStateValue, fireOnChange]
    );

    const renderSelectedValuePill = useCallback(
      (relation: LinkedRelation): React.ReactElement<HTMLDivElement> => {
        const { togetherWithPartner } = relation;
        if (!!withPartnerControl && !!togetherWithPartner) {
          return (
            <PartnerPill
              relation={relation}
              partnerMarkedForDelete={partnerMarkedForDelete}
              onRelationClick={(r, isPartner) =>
                onRelationClickHandler(r, isPartner)
              }
              onRemoveRelationClick={(event, relation) =>
                onRemoveRelationClickHandler(event, relation)
              }
              onRemovePartnerClick={(event, relation) =>
                onRemovePartnerClickHandler(event, relation)
              }
              data-cy={`Pill`}
            />
          );
        } else {
          return (
            <SinglePill
              relation={relation}
              onRelationClick={(r, isPartner) =>
                onRelationClickHandler(r, isPartner)
              }
              onRemoveRelationClick={(event, relation) =>
                onRemoveRelationClickHandler(event, relation)
              }
            />
          );
        }
      },
      [
        onRelationClickHandler,
        onRemoveRelationClickHandler,
        onRemovePartnerClickHandler,
        onRelationClickHandler,
        onRemoveRelationClickHandler,
      ]
    );

    const renderSelectedValue = useCallback(
      (value: RelationSnapShot): QueryResultReturnValue<LinkedRelation> => {
        const template = renderSelectedValuePill(value);

        return {
          value,
          template,
        };
      },
      [renderSelectedValuePill]
    );

    useEffect(() => {
      let value = isArray(pValue) ? pValue : [pValue];
      value = value.filter((v) => !!v);
      setStateValue(value);
    }, [pValue]);

    return (
      <div className="relation__query">
        <BaseQueryComponent
          {...props}
          placeholder={placeholder}
          hasCustomPill
          forceAdd={!!onAdd}
          onAddResource="addAsNewRelation"
          onAdd={onAdd}
          onChange={(v) => onChangeHandler(v)}
          asyncValues={(value) =>
            Relation.searchV3(
              value,
              take,
              termFields,
              filterByActive,
              filterByRelationTypes
            )
          }
          optionValue={(r, q) => (
            <RelationSelectOptionComponent relation={r} query={q} />
          )}
          selectedValue={(r) => renderSelectedValue(r as RelationSnapShot)}
          onBackspaceDelete={() => onBackspaceDeleteHandler()}
          onRemoveMarkedForDelete={() => onRemoveMarkedForDeleteHandler()}
          disableOption={mapDisableOption}
        />
        {!!withPartnerControl && renderPartnerControls()}
      </div>
    );
  })
);
