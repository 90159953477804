import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { DynamicDocumentsFormContainer, DynamicDocumentsSignaturesContainer } from "@haywork/modules/dynamic-documents";

const route = RouteUtil.mapStaticRouteValues;

export const DynamicDocumentsDocumentRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ DYNAMICDOCUMENTROUTES.DOCUMENT.URI } render={ () => <Redirect to={ route(DYNAMICDOCUMENTROUTES.DOCUMENT_FORM.URI, { id }) } /> } />
    <Route exact path={ DYNAMICDOCUMENTROUTES.DOCUMENT_FORM.URI } component={ DynamicDocumentsFormContainer } />
    <Route exact path={ DYNAMICDOCUMENTROUTES.DOCUMENT_SIGNATURES.URI } component={ DynamicDocumentsSignaturesContainer } />
  </Switch>;
};
