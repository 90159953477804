import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as deepEqual from "deep-equal";
import noop from "lodash-es/noop";

import {
  Form,
  FormReturnValue,
  FormControls,
  FormReference,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import {
  ObjectAssignment,
  ActiveFilter,
  RentCondition,
  RealEstateGroup,
  UpdateAvailabilityRentedSettings,
  RelationSnapShot,
  LinkedRelation,
  AvailabilityStatus,
  RelationType,
} from "@haywork/api/kolibri";
import { NewEntityOptions, NewEntity } from "@haywork/modules/new-entity";
import { NewEntityType } from "@haywork/enum";
import { DateUtil } from "@haywork/util";
import * as moment from "moment";

const styles = require("../../transaction-modal.component.scss");

interface Props {
  assignment: ObjectAssignment;
  onUpdate: (
    rentSettings: UpdateAvailabilityRentedSettings,
    valid: boolean
  ) => void;
  onInitialValidState: (valid: boolean) => void;
  submitTransactionData: boolean;
}
interface State {
  rentedUnderReservation: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  showDateRentedUntil: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class TransactionRentForm extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { rentOffer, linkedApplicants, availabilityStatus } =
      this.props.assignment;
    const {
      dateReservation,
      rentPrice,
      realizedPerMonth,
      rentedFrom,
      rentedUntil,
      isVacantPropertyAct,
      dateSignDeed,
    } = rentOffer;
    const rentedUnderReservation =
      !!dateReservation || availabilityStatus === AvailabilityStatus.Available;

    const timeSignDeed = !!dateSignDeed
      ? moment(dateSignDeed).format("HH:mm")
      : "09:00";

    this.state = {
      rentedUnderReservation,
      newEntityVisible: false,
      newEntityOptions: null,
      showDateRentedUntil: !!rentedUntil,
    };

    this.formControls = {
      linkedApplicants: {
        value: linkedApplicants || [],
        validators: [
          Validators.arrayMinCount(1, { key: "missingLinkedApplicantsRent" }),
        ],
      },
      rentedUnderReservation: {
        value: rentedUnderReservation,
        onChange: (ref) => {
          this.setState({ rentedUnderReservation: ref.value });
          if (!ref.value) {
            return {
              dateReservation: "",
            };
          }
        },
      },
      dateReservation: {
        value: dateReservation || "",
        validators: [
          Validators.required(
            undefined,
            () => !this.state.rentedUnderReservation
          ),
        ],
      },
      dateRentedFrom: {
        value: rentedFrom || "",
        validators: [Validators.required()],
      },
      dateRentedUntil: {
        value: rentedUntil || "",
        validators: [
          Validators.required(undefined, () => !this.state.showDateRentedUntil),
        ],
      },
      transactionPrice: {
        value: realizedPerMonth || rentPrice || "",
      },
      isVacancyLaw: {
        value: isVacantPropertyAct,
      },
      dateSignDeed: {
        value: dateSignDeed,
        validators: [Validators.required()],
      },
      timeSignDeed: {
        value: timeSignDeed,
        validators: [Validators.required()],
      },
      toggleDateRentedUntil: {
        value: !this.state.showDateRentedUntil,
        onChange: (ref) => {
          this.setState({ showDateRentedUntil: !ref.value });
        },
      },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !this.props.submitTransactionData &&
      !!nextProps.submitTransactionData &&
      !!this.formRef
    ) {
      this.formRef.submit();
    }
  }

  public render() {
    const { rentOffer, realEstateGroup } = this.props.assignment;
    const { rentCondition } = rentOffer;

    return (
      <div styleName="inner">
        <Form
          name="transaction-rent"
          formControls={this.formControls}
          form={this.bindFormRef}
          onChange={this.onChangeHandler}
          onSubmit={noop}
        >
          <div className="form__row">
            <label htmlFor="linkedApplicants">
              <ResourceText resourceKey="rentTransaction.linkedApplicants" />
            </label>
            <Input.RelationQuery
              name="linkedApplicants"
              filterByActive={ActiveFilter.ActiveOnly}
              multiple={true}
              onAdd={this.addNewRelation}
              filterByRelationTypes={[
                RelationType.ContactPerson,
                RelationType.ContactCompany,
              ]}
            />
          </div>

          <div className="form__divider" />

          <div className="form__row">
            <label htmlFor="transactionPrice">
              <ResourceText resourceKey="rentTransaction.transactionPrice" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="transactionPrice" pretty={true} />
              <div className="post full">
                <ResourceText
                  masterKey="rentConditions"
                  resourceKey={(
                    rentCondition || RentCondition.PricePerMonth
                  ).toString()}
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              <div className="column" styleName="rented-date">
                <label htmlFor="dateRentedFrom">
                  <ResourceText resourceKey="rentTransaction.dateRentedFrom" />
                </label>
                <Input.Datepicker name="dateRentedFrom" />
              </div>
              {!!this.state.showDateRentedUntil && (
                <>
                  <div className="column__spacer" />
                  <div className="column" styleName="rented-date">
                    <label htmlFor="dateRentedUntil">
                      <ResourceText resourceKey="rentTransaction.dateRentedUntil" />
                    </label>
                    <Input.Datepicker name="dateRentedUntil" />
                  </div>
                </>
              )}
              <div className="column__spacer" />
              <div className="column push-label">
                <Input.Switch
                  name="toggleDateRentedUntil"
                  on={true}
                  off={false}
                  label="rentTransaction.toggleDateRentedUntil"
                  labelPosition={SwitchLabelPosition.Post}
                />
              </div>
            </div>
          </div>

          <div className="form__divider" />

          <div className="form__row">
            <div className="form__group">
              <div className="column fit">
                <Input.Switch
                  name="rentedUnderReservation"
                  label="rentTransaction.rentedUnderReservation"
                  labelPosition={SwitchLabelPosition.Pre}
                  on={true}
                  off={false}
                />
              </div>
              <div className="column__spacer" />
              {!!this.state.rentedUnderReservation && (
                <React.Fragment>
                  <div className="column__textspacer">
                    <label htmlFor="dateReservation">
                      <ResourceText resourceKey="rentTransaction.dateReservation" />
                    </label>
                  </div>
                  <div className="column">
                    <Input.Datepicker name="dateReservation" />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          {this.state.rentedUnderReservation && (
            <div styleName="info-block">
              <i className="fal fa-info-circle" />
              <ResourceText resourceKey="rentTransaction.dateReservationInfo" />
            </div>
          )}

          <div className="form__divider" />

          <div className="form__row">
            <label htmlFor="dateSignDeed">
              <ResourceText resourceKey="rentTransaction.dateSignDeed" />
            </label>
            <div className="form__group">
              <div className="column">
                <Input.Datepicker name="dateSignDeed" />
              </div>
              <div className="column__textspacer">
                <label htmlFor="timeSignDeed">
                  <ResourceText resourceKey="rentTransaction.timeSignDeed" />
                </label>
              </div>
              <div className="column">
                <Input.Time name="timeSignDeed" />
              </div>
            </div>
          </div>

          {realEstateGroup === RealEstateGroup.Agricultural && (
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <Input.Switch
                    name="isVacancyLaw"
                    label="rentTransaction.isVacancyLaw"
                    on={true}
                    off={false}
                    labelPosition={SwitchLabelPosition.Pre}
                  />
                </div>
              </div>
            </div>
          )}

          <div styleName="info-block">
            <i className="fal fa-info-circle" />
            <ResourceText
              resourceKey={
                this.state.rentedUnderReservation
                  ? "rentTransaction.dateRentedUnderReservationInfo"
                  : "rentTransaction.dateReservationInfo"
              }
            />
          </div>
        </Form>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
      this.props.onInitialValidState(this.formRef.isValid());
      if (!!this.props.submitTransactionData) {
        this.formRef.submit();
      }
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    let { linkedApplicants } = this.props.assignment;
    const { realEstateGroup } = this.props.assignment;
    linkedApplicants = linkedApplicants || [];

    if (!!values.linkedApplicants) {
      linkedApplicants = [
        ...values.linkedApplicants.map(
          (linkedApplicant) =>
            ({
              id: linkedApplicant.id,
              displayName: linkedApplicant.displayName,
              typeOfRelation: linkedApplicant.typeOfRelation,
            } as LinkedRelation)
        ),
      ];
    }

    const dateRentedUntil = !!values.toggleDateRentedUntil
      ? null
      : values.dateRentedUntil || null;

    let settings: UpdateAvailabilityRentedSettings = {
      dateRentedFrom: values.dateRentedFrom || null,
      dateRentedUntil,
      dateReservation: !values.rentedUnderReservation
        ? null
        : values.dateReservation || null,
      dateSignDeed: DateUtil.dateAndTimeToDate(
        values.dateSignDeed,
        values.timeSignDeed
      ),
      linkedApplicants,
      transactionPrice: values.transactionPrice,
    };

    if (realEstateGroup === RealEstateGroup.Agricultural) {
      settings = {
        ...settings,
        isVacancyLaw: values.isVacancyLaw,
      };
    }

    this.props.onUpdate(settings, this.formRef.isValid());
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedApplicants } = this.formRef.getValues();
    linkedApplicants = linkedApplicants || [];

    this.formRef.update({
      linkedApplicants: [...linkedApplicants, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
