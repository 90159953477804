import { REDUX } from "@haywork/constants";
import * as ActionType from "./types.types";

const setType = (payload: ActionType.CurrentType) => ({
  type: REDUX.OBJECTTYPES.SET_TYPE,
  ...payload
});

const setTypeStatus = (currentTypeStatus: string) => ({
  type: REDUX.OBJECTTYPES.SET_TYPE_STATUS,
  currentTypeStatus
});

const setSaveObjectTypeStatus = (saveObjectTypeStatus: string) => ({
  type: REDUX.OBJECTTYPES.SET_SAVE_TYPE_STATUS,
  saveObjectTypeStatus
});

const setBuildNumbers = (payload: ActionType.Buildnumbers) => ({
  type: REDUX.OBJECTTYPES.SET_BUILDNUMBERS,
  ...payload
});

const addBuildNumbers = (payload: ActionType.Buildnumbers) => ({
  type: REDUX.OBJECTTYPES.ADD_BUILDNUMBERS,
  ...payload
});

const setBuildnumbersStatus = (buildnumbersStatus: string) => ({
  type: REDUX.OBJECTTYPES.SET_BUILDNUMBERS_STATUS,
  buildnumbersStatus
});

export const TypesActions = {
  setType,
  setTypeStatus,
  setSaveObjectTypeStatus,
  setBuildNumbers,
  addBuildNumbers,
  setBuildnumbersStatus
};
