import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FolderTreeFileType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import get from "lodash-es/get";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import { ConvertableFile } from "@haywork/util";

const styles = require("./style.scss");

type Props = {
  file: ConvertableFile;
  loading: boolean;
  onToggleConvert: (id: string) => void;
  onDelete: (id: string) => void;
};

export const CheckItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ file: fileRef, onToggleConvert, loading, onDelete }) => {
      const { file, convertToPDF } = fileRef;
      const { type, id } = file;

      const icon = useMemo(() => {
        switch (type) {
          case FolderTreeFileType.DocumentSession: {
            return "file-contract";
          }
          case FolderTreeFileType.Invoice: {
            return "euro-sign";
          }
          case FolderTreeFileType.DossierItem: {
            const { fileExtension } = file.linkedDossierItem;

            const imagesRegex = /(bmp|jpe?g|gif|png|tiff)/gi;
            const wordRegex = /docx?/gi;
            const excelRegex = /xlsx?/gi;
            const powerpointRegex = /pptx?/gi;
            const pdfRegex = /(pdf|ps)/gi;
            const videoRegex = /(mov|mp4|m4v|3gp|3g2|avi|mpe?g|wmv|asf|ram)/gi;
            const audioRegex = /(mp3|wav|aiff?|mpa|m4a|wma)/gi;

            switch (true) {
              case imagesRegex.test(fileExtension): {
                return "file-image";
              }
              case wordRegex.test(fileExtension): {
                return "file-word";
              }
              case excelRegex.test(fileExtension): {
                return "file-excel";
              }
              case powerpointRegex.test(fileExtension): {
                return "file-powerpoint";
              }
              case pdfRegex.test(fileExtension): {
                return "file-pdf";
              }
              case videoRegex.test(fileExtension): {
                return "file-video";
              }
              case audioRegex.test(fileExtension): {
                return "file-audio";
              }
              default: {
                return "file";
              }
            }
          }
          default: {
            return "file";
          }
        }
      }, [type]);

      const displayName = useMemo(() => {
        switch (type) {
          case FolderTreeFileType.DocumentSession: {
            const { name } = file.linkedDocumentSession;
            return <div styleName="item__name">{name}</div>;
          }
          case FolderTreeFileType.DossierItem: {
            const { fileName } = file.linkedDossierItem;
            return <div styleName="item__name">{fileName}</div>;
          }
          case FolderTreeFileType.Invoice: {
            const { displayName } = file.linkedInvoice;
            return (
              <div styleName="item__name">
                {displayName || <I18n value="conceptInvoice" />}
              </div>
            );
          }
          default:
            return <div styleName="item__name" />;
        }
      }, [type]);

      const showConvertToPDF = useMemo(() => {
        switch (true) {
          case type === FolderTreeFileType.DocumentSession:
          case /docx?/gi.test(get(file, "linkedDossierItem.fileExtension")): {
            return (
              <div styleName="item__convert">
                <Input.CheckBox
                  value={convertToPDF}
                  name={`dossierToPDF.${id}`}
                  onChange={() => onToggleConvert(id)}
                  label="dossier.convertToPDF"
                  disabled={loading}
                />
              </div>
            );
          }
          default: {
            return null;
          }
        }
      }, [type, id, convertToPDF, onToggleConvert]);

      if (type === FolderTreeFileType.Unknown) return null;

      return (
        <div styleName="item">
          <div styleName="item__icon">
            <Icon name={icon} light size={18} color={Colors.Black} />
          </div>
          {displayName}
          {showConvertToPDF}
          <div styleName="item__delete" onClick={() => onDelete(id)}>
            <Icon name="minus-circle" light size={18} color={Colors.Primary} />
          </div>
        </div>
      );
    }
  )
);
