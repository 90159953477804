import * as React from "react";
import { FC, memo, useCallback, useContext, useState } from "react";
import { ActionsColumn, ListContext } from "@haywork/components/ui/list";
import { InvoiceSnapShot, InvoiceStatus } from "@haywork/api/kolibri";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";
import { Action } from "@haywork/components/ui/action-list";

export enum InvoiceListAction {
  Edit = "Edit",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Delete = "Delete",
  Download = "Download",
  Email = "Email",
}
type Props = {
  item: InvoiceSnapShot;
  canMail: boolean;
};

export const InvoiceActions: FC<Props> = memo(({ item, canMail }) => {
  const { onActionCallback } = useContext(ListContext);
  const [actionsVisible, setActionsVisible] = useState(false);

  const onAction = useCallback(
    (action: string) => {
      setActionsVisible(false);
      onActionCallback(item, action);
    },
    [item, onActionCallback, setActionsVisible]
  );

  return (
    <ActionsColumn
      visible={actionsVisible}
      onHide={() => setActionsVisible(false)}
      onShow={() => setActionsVisible(true)}
    >
      <Action
        label="invoices.itemAction.edit"
        icon={
          <Icon light name="pencil" color={Colors.ActionListIcon} fixedWidth />
        }
        onClick={() => onAction(InvoiceListAction.Edit)}
      />
      <Action
        label="invoices.itemAction.email"
        disabled={!canMail}
        icon={
          <Icon
            light
            name="envelope"
            color={Colors.ActionListIcon}
            fixedWidth
          />
        }
        onClick={() => {
          if (canMail) onAction(InvoiceListAction.Email);
        }}
      />
      <Action
        label="invoices.itemAction.download"
        icon={
          <Icon
            light
            name="download"
            color={Colors.ActionListIcon}
            fixedWidth
          />
        }
        onClick={() => onAction(InvoiceListAction.Download)}
      />
      {item.isActive && (
        <Action
          label="invoices.itemAction.archive"
          icon={
            <Icon
              light
              name="archive"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(InvoiceListAction.Archive)}
        />
      )}
      {!item.isActive && (
        <Action
          label="invoices.itemAction.unArchive"
          icon={
            <Icon
              light
              name="arrow-from-bottom"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(InvoiceListAction.UnArchive)}
        />
      )}
      {(!item.isActive || item.status === InvoiceStatus.Draft) && (
        <Action
          label="invoices.itemAction.delete"
          icon={
            <Icon
              light
              name="trash-alt"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(InvoiceListAction.Delete)}
        />
      )}
    </ActionsColumn>
  );
});

InvoiceActions.displayName = "ActionsColumn";
