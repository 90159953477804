import * as React from "react";
import * as deepEqual from "deep-equal";

import {
  FormControls,
  FormReference,
  Form,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Space,
  Size,
  UnitOfMeasurement,
  UnitOfVolume,
  SpaceTypeOption,
  KitchenTypeOption,
  KitchenFacilityOption,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { NumberUtil } from "@haywork/util";

const parseFloatExtended = NumberUtil.parseFloatExtended;

interface Props {
  space: Space;
  spaceTypes: SpaceTypeOption[];
  kitchenTypes: KitchenTypeOption[];
  kitchenFacilities: KitchenFacilityOption[];
  onChange: (space: Space) => void;
}
interface State {}

export class KitchenSpaceFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const {
      type,
      size,
      kitchenType,
      kitchenFacilities,
      constructionYear,
    } = this.props.space;
    const { length, width, height, area, volume } = size || ({} as Size);

    this.formControls = {
      type: { value: type },
      kitchenType: { value: kitchenType || [] },
      kitchenFacilities: { value: kitchenFacilities || [] },
      length: { value: length || "", onChange: this.calculateSizeObject },
      width: { value: width || "", onChange: this.calculateSizeObject },
      height: { value: height || "", onChange: this.calculateSizeObject },
      area: { value: area || "" },
      volume: { value: volume || "" },
      constructionYear: { value: constructionYear || "" },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.calculateSizeObject = this.calculateSizeObject.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.space, this.props.space)) {
      const {
        type,
        size,
        kitchenType,
        kitchenFacilities,
        constructionYear,
      } = this.props.space;
      const { length, width, height, area, volume } = size || ({} as Size);

      this.formRef.update({
        type,
        kitchenType: kitchenType || [],
        kitchenFacilities: kitchenFacilities || [],
        length: length || "",
        width: width || "",
        height: height || "",
        area: area || "",
        volume: volume || "",
        constructionYear: constructionYear || "",
      });
    }
  }

  public render() {
    const { spaceTypes, kitchenTypes, kitchenFacilities } = this.props;
    const currentYear = new Date().getFullYear();
    const constructionYearMin = currentYear - 100;
    const constructionYearMax = currentYear + 1;

    return (
      <Form
        name="livingroom-space-details"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChange}
        asSubForm={true}
      >
        <div className="form__row">
          <label htmlFor="type">
            <ResourceText resourceKey="spaceDetails.label.type" />
          </label>
          <Input.NewSelect
            name="type"
            values={spaceTypes}
            displayProp="displayName"
            valuesProp="value"
          />
        </div>

        <div className="form__row">
          <label htmlFor="kitchenType">
            <ResourceText resourceKey="spaceDetails.label.kitchenType" />
          </label>
          <Input.Multi name="kitchenType" values={kitchenTypes} />
        </div>

        <div className="form__row" style={{ maxWidth: 178 }}>
          <label htmlFor="constructionYear">
            <ResourceText resourceKey="spaceDetails.label.constructionYear" />
          </label>
          <Input.Number
            name="constructionYear"
            min={constructionYearMin}
            max={constructionYearMax}
            placeholder="spaceDetails.placeholder.constructionYear"
          />
        </div>

        <div className="form__row">
          <label htmlFor="kitchenFacilities">
            <ResourceText resourceKey="spaceDetails.label.kitchenFacilities" />
          </label>
          <Input.Multi name="kitchenFacilities" values={kitchenFacilities} />
        </div>

        <div className="form__row">
          <div className="form__group stretch">
            <div className="column grow1">
              <div className="form__row">
                <label htmlFor="width">
                  <ResourceText resourceKey="spaceDetails.label.width" />
                </label>
                <div className="input__helper">
                  <Input.Number name="width" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="length">
                  <ResourceText resourceKey="spaceDetails.label.length" />
                </label>
                <div className="input__helper">
                  <Input.Number name="length" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="height">
                  <ResourceText resourceKey="spaceDetails.label.height" />
                </label>
                <div className="input__helper">
                  <Input.Number name="height" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column grow1">
              <div className="form__row">
                <label htmlFor="area">
                  <ResourceText resourceKey="spaceDetails.label.area" />
                </label>
                <div className="input__helper">
                  <Input.Number name="area" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml={true} />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="volume">
                  <ResourceText resourceKey="spaceDetails.label.volume" />
                </label>
                <div className="input__helper">
                  <Input.Number name="volume" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="cubicMeters" asHtml={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private calculateSizeObject(ref, get) {
    const width = get("width").value;
    const length = get("length").value;
    const height = get("height").value;

    switch (true) {
      case !!width && !!length && !!height:
        return {
          area: parseFloatExtended(parseFloat(width) * parseFloat(length)),
          volume: parseFloatExtended(
            parseFloat(width) * parseFloat(length) * parseFloat(height)
          ),
        };
      case !!width && !!length:
        return {
          area: parseFloatExtended(parseFloat(width) * parseFloat(length)),
        };
      default:
        return;
    }
  }

  private onChange(values: FormReturnValue) {
    const space: Space = {
      ...this.props.space,
      type: values.type,
      kitchenType: values.kitchenType,
      kitchenFacilities: values.kitchenFacilities,
      constructionYear: values.constructionYear,
      size: {
        ...(this.props.space.size || {}),
        width: values.width,
        length: values.length,
        height: values.height,
        area: values.area,
        volume: values.volume,
        unitOfMeasurement: UnitOfMeasurement.Meter,
        unitOfVolume: UnitOfVolume.CubicMeter,
      },
    };

    this.props.onChange(space);
  }
}
