import { REDUX } from "@haywork/constants";
import { Status } from "@haywork/stores";

import * as ActionType from "./widgets.types";

const setLastChangedAssignmentsStatus = (payload: Status) => ({
  type: REDUX.ASSIGNMENT.SET_WIDGET_LAST_ASSIGNMENTS_STATUS,
  ...payload
});

const setLastChangedAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.ASSIGNMENT.SET_WIDGET_LAST_ASSIGNMENTS,
  ...payload
});

export const AssigmentWidgetsActions = {
  setLastChangedAssignmentsStatus,
  setLastChangedAssignments
};
