import { AcquisitionAssignment, AssignmentPhase } from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum AcquisitionAssignmentAction {
  CreateObject = "CreateObject",
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAppointment = "CreateAppointment",
  CreateInvoice = "CreateInvoice",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Delete = "Delete",
  SetAssignmentPhaseCompleted = "SetAssignmentPhaseCompleted"
}

type Props = {
  acquisitionAssignment: AcquisitionAssignment;
  canSendEmail: boolean;
  onClick: (action: AcquisitionAssignmentAction) => void;
};

export const AcquisitionAssignmentActionsComponent: FC<Props> = memo(
  ({ acquisitionAssignment, canSendEmail, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);
    const { isActive, assignmentPhase } = acquisitionAssignment;

    const showArchive = !!isActive;
    const showSetAssignmentPhase =
      isActive && assignmentPhase !== AssignmentPhase.Completed;
    const showUnArchive = !isActive;
    const showCreateEmail = canSendEmail;
    const showDelete = assignmentPhase === AssignmentPhase.Concept;

    const onClickHandler = (action: AcquisitionAssignmentAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="actions"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          <Action
            label="acquisitionAssignment.actions.createObject"
            icon={
              <Icon
                light
                name="folder-plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionAssignmentAction.CreateObject)
            }
          />
          {showSetAssignmentPhase && (
            <Action
              label="acquisitionAssignment.actions.setAssignmentPhaseCompleted"
              icon={
                <Icon
                  light
                  name="check"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(
                  AcquisitionAssignmentAction.SetAssignmentPhaseCompleted
                )
              }
            />
          )}
          {showArchive && (
            <Action
              label="acquisitionAssignment.actions.archive"
              icon={
                <Icon
                  light
                  name="archive"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionAssignmentAction.Archive)
              }
            />
          )}

          {showUnArchive && (
            <Action
              label="acquisitionAssignment.actions.unArchive"
              icon={
                <Icon
                  light
                  name="inbox-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionAssignmentAction.UnArchive)
              }
            />
          )}

          {showDelete && (
            <Action
              label="acquisitionAssignment.actions.delete"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AcquisitionAssignmentAction.Delete)}
            />
          )}

          <Spacer label="assignment.actions.label.other" />
          {showCreateEmail && (
            <Action
              label="acquisitionAssignment.actions.createEmail"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionAssignmentAction.CreateEmail)
              }
            />
          )}
          <Action
            label="acquisitionAssignment.actions.createTask"
            icon={
              <Icon
                light
                name="tasks"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionAssignmentAction.CreateTask)
            }
          />
          <Action
            label="acquisitionAssignment.actions.createAppointment"
            icon={
              <Icon
                light
                name="calendar-plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionAssignmentAction.CreateAppointment)
            }
          />
          <Action
            label="acquisitionAssignment.actions.createInvoice"
            icon={
              <Icon
                light
                name="euro-sign"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionAssignmentAction.CreateInvoice)
            }
          />
        </ActionList>
      </>
    );
  }
);
