import { DocumentSession } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  DynamicDocumentsPreviewComponent,
  DynamicDocumentsPreviewComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState, DynamicDocumentsPreviewActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  preview: string;
  anchorName: string;
  session: DocumentSession;
}
interface DispatchProps {
  clearHtmlPreview: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsPreviewComponentProps,
  AppState
> = (state) => {
  const { preview, anchorName } = state.dynamicDocuments.preview;
  const { session } = state.dynamicDocuments.single;

  return {
    preview,
    anchorName,
    session
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsPreviewComponentProps
> = (dispatch) => ({
  clearHtmlPreview: () =>
    dispatch(DynamicDocumentsPreviewActions.clearHtmlPreview())
});

export type DynamicDocumentsPreviewContainerProps = StateProps & DispatchProps;
export const DynamicDocumentsPreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsPreviewComponent);
