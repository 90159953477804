import { Account } from "@haywork/api/mail";
import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { getAccount, folders } from "../../selectors";
import { AddFolderComponent, AddFolderComponentProps } from "./add-folder";

type StateProps = {
  folders: EmailFolder[];
  account: Account | null;
};
type DispatchProps = {
  saveFolder: (
    accountId: string,
    parentId: string,
    displayName: string
  ) => Promise<EmailFolder>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AddFolderComponentProps,
  AppState
> = (state, ownProps) => {
  return {
    folders: folders(state),
    account: getAccount(ownProps.accountId)(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AddFolderComponentProps
> = (dispatch) => ({
  saveFolder: (
    accountId: string,
    parentId: string | null,
    displayName: string
  ) => dispatch(EmailThunk.Folders.saveFolder(accountId, parentId, displayName))
});

export type AddFolderContainerProps = StateProps & DispatchProps;
export const AddFolderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFolderComponent);
