import { Language, LinkedOffice, SocialMediaType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { EMPLOYEEROUTES } from "@haywork/constants";
import { EmployeeGeneralContainerProps } from "@haywork/modules/settings";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface EmployeeGeneralComponentProps {}
interface State {}
type Props = EmployeeGeneralComponentProps & EmployeeGeneralContainerProps;

const styles = require("./employee-general.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class EmployeeGeneralComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.props.getSelectedEmployee(this.props.selectedEmployee.id);
  }

  public render() {
    const employee = this.props.selectedEmployee;
    const id = employee.id;
    const country = employee.address.countryIso2
      ? this.props.countries.find(
          (country) => country.iso2CodeValue === employee.address.countryIso2
        ).displayName
      : "";
    const postalAddressCountry = employee.postalAddress.countryIso2
      ? this.props.countries.find(
          (country) =>
            country.iso2CodeValue === employee.postalAddress.countryIso2
        ).displayName
      : "";

    const facebook = employee.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Facebook
    );
    const linkedIn = employee.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.LinkedIn
    );
    const youtube = employee.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.YouTube
    );
    const twitter = employee.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Twitter
    );
    return (
      <div styleName="general">
        <div styleName="detail-component">
          <div styleName="readMode">
            <PageHeader
              title="pageTitle.employees.general"
              subTitle={employee.displayName}
              actions={
                <Button
                  label="edit"
                  category="success"
                  onClick={() =>
                    this.props.navigate(
                      route(EMPLOYEEROUTES.EMPLOYEE_EDIT.URI, { id })
                    )
                  }
                />
              }
            />

            <div styleName="general__body">
              <div styleName="name">
                <h1 styleName="title">{employee.displayName}</h1>
                {/* Spoken languages */}
                {(() => {
                  if (employee.spokenLanguages.length > 0) {
                    return (
                      <div styleName="spokenLanguages">
                        <label styleName="label">
                          <I18n value="speaksLabel" />
                        </label>
                        {employee.spokenLanguages.map(
                          this.renderLanguagePill.bind(this)
                        )}
                      </div>
                    );
                  }
                })()}
              </div>
              {/* Phone numbers */}
              {employee.phoneNumbers.length > 0 && (
                <div styleName="itemContainer">
                  {employee.phoneNumbers.map((phoneNumber, index) => {
                    const type = phoneNumber.type
                      ? this.props.phoneNumberTypes.find(
                          (phoneNumberType) =>
                            phoneNumber.type === phoneNumberType.value
                        ).displayName
                      : "";
                    return (
                      <div styleName="item" key={index}>
                        <span className={styles.label}>{type}</span>
                        <a href={"tel:" + phoneNumber.number}>
                          {phoneNumber.number}
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Email addresses */}
              {employee.emailAddresses.length > 0 && (
                <div
                  styleName="itemContainer"
                  className={styles.emailAddresses}
                >
                  {employee.emailAddresses.map((emailAddress, index) => {
                    const type = emailAddress.type
                      ? this.props.emailAddressTypes.find(
                          (emailAddressType) =>
                            emailAddress.type === emailAddressType.value
                        ).displayName
                      : "";
                    return (
                      <div styleName="item" key={index}>
                        <span className={styles.label}>{type}</span>
                        <MailTo email={emailAddress.address} />
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Address info */}
              {this.hasAddressOrPostalAddress() && (
                <div styleName={"itemContainer" + " " + "addresses"}>
                  {this.hasAddress() && (
                    <div styleName={"item" + " " + "address"}>
                      <span styleName="label">
                        <I18n value="visitAddress" />
                      </span>
                      <div>
                        {employee.address.street ? (
                          <span styleName="line1">
                            {employee.address.street.name +
                              " " +
                              (employee.address.houseNumber
                                ? employee.address.houseNumber
                                : "") +
                              " " +
                              (employee.address.houseNumberPostfix
                                ? employee.address.houseNumberPostfix
                                : "")}
                          </span>
                        ) : (
                          ""
                        )}
                        {employee.address.locality ? (
                          <span styleName="line2">
                            {(employee.address.postalCode
                              ? employee.address.postalCode
                              : "") +
                              " " +
                              employee.address.locality.name}
                            <span styleName="country">&nbsp;({country})</span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  {(() => {
                    if (
                      employee.postalAddressDiffersVisitAddress &&
                      (employee.postalAddress.street ||
                        employee.postalAddress.locality)
                    ) {
                      return (
                        <div styleName={"item" + " " + "address"}>
                          <span styleName="label">
                            <I18n value="postalAddress" />
                          </span>
                          <div>
                            {employee.postalAddress.street ? (
                              <span styleName="line1">
                                {employee.postalAddress.street.name +
                                  " " +
                                  (employee.postalAddress.houseNumber
                                    ? employee.postalAddress.houseNumber
                                    : "") +
                                  " " +
                                  (employee.postalAddress.houseNumberPostfix
                                    ? employee.postalAddress.houseNumberPostfix
                                    : "")}
                              </span>
                            ) : (
                              ""
                            )}
                            {employee.postalAddress.locality ? (
                              <span styleName="line2">
                                {(employee.postalAddress.postalCode
                                  ? employee.postalAddress.postalCode
                                  : "") +
                                  " " +
                                  employee.postalAddress.locality.name}
                                <span styleName="country">
                                  &nbsp;({postalAddressCountry})
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })()}
                </div>
              )}

              {/* Linked offices */}
              {(() => {
                if (employee.linkedOffices) {
                  return (
                    <div styleName="itemContainer">
                      <span styleName="label">
                        <I18n value="worksAt" />
                      </span>
                      <div>
                        {this.renderOfficeNameList(employee.linkedOffices)}
                      </div>
                    </div>
                  );
                }
              })()}

              {/* Social media */}
              {employee.socialMedia.length > 0 && (
                <div styleName={"itemContainer" + " " + "socialMedia"}>
                  <div styleName="item">
                    <span className={styles.label}>
                      <I18n value="socialMedia" />
                    </span>
                    {facebook && facebook.account && (
                      <a
                        className={styles.facebook}
                        href={facebook.account}
                        target="_blank"
                      >
                        <i className="fab fa-fw fa-facebook-f" />
                        <span className="sr-only">
                          <I18n value="lowercaseFacebook" />
                        </span>
                      </a>
                    )}
                    {linkedIn && linkedIn.account && (
                      <a
                        className={styles.linkedin}
                        href={linkedIn.account}
                        target="_blank"
                      >
                        <i className="fab fa-fw fa-linkedin-in" />
                        <span className="sr-only">
                          <I18n value="lowercaseYoutube" />
                        </span>
                      </a>
                    )}
                    {youtube && youtube.account && (
                      <a
                        className={styles.youtube}
                        href={youtube.account}
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                        <span className="sr-only">
                          <I18n value="lowercaseYoutube" />
                        </span>
                      </a>
                    )}
                    {twitter && twitter.account && (
                      <a
                        className={styles.twitter}
                        href={twitter.account}
                        target="_blank"
                      >
                        <i className="fab fa-twitter" />
                        <span className="sr-only">
                          <I18n value="lowercaseTwitter" />
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderOfficeNameList(offices: LinkedOffice[]): string {
    const officeNames = offices.map((o) => o.displayName);
    return officeNames.join(", ");
  }

  private renderLanguagePill(language: Language, idx: number) {
    let flag = "famfamfam-flag-";

    switch (language) {
      case Language.Croatian:
        flag = flag + "hr";
        break;
      case Language.English:
        flag = flag + "gb";
        break;
      case Language.Estonian:
        flag = flag + "ee";
        break;
      case Language.Finnish:
        flag = flag + "fi";
        break;
      case Language.French:
        flag = flag + "fr";
        break;
      case Language.Georgian:
        flag = flag + "ge";
        break;
      case Language.German:
        flag = flag + "de";
        break;
      case Language.Italian:
        flag = flag + "it";
        break;
      case Language.Polish:
        flag = flag + "pl";
        break;
      case Language.Russian:
        flag = flag + "ru";
        break;
      case Language.Spanish:
        flag = flag + "es";
        break;
      case Language.Swedish:
        flag = flag + "se";
        break;
      case Language.Turkish:
        flag = flag + "tr";
        break;
      default:
        flag = flag + "nl";
        break;
    }

    return (
      <span styleName="language" key={idx}>
        <i className={flag} />
        <I18n prefix="languages" value={language.toString()} />
      </span>
    );
  }

  private hasAddressOrPostalAddress(): boolean {
    if (
      (this.props.selectedEmployee.address.street &&
        this.props.selectedEmployee.address.street.name) ||
      (this.props.selectedEmployee.address.locality &&
        this.props.selectedEmployee.address.locality.name) ||
      (this.props.selectedEmployee.address.postalCode &&
        this.props.selectedEmployee.address.postalCode) ||
      (this.props.selectedEmployee.postalAddress.street &&
        this.props.selectedEmployee.postalAddress.street.name) ||
      (this.props.selectedEmployee.postalAddress.locality &&
        this.props.selectedEmployee.postalAddress.locality.name) ||
      (this.props.selectedEmployee.postalAddress.postalCode &&
        this.props.selectedEmployee.postalAddress.postalCode)
    ) {
      return true;
    }
    return false;
  }

  private hasAddress(): boolean {
    if (
      (this.props.selectedEmployee.address.street &&
        this.props.selectedEmployee.address.street.name) ||
      (this.props.selectedEmployee.address.locality &&
        this.props.selectedEmployee.address.locality.name) ||
      (this.props.selectedEmployee.address.postalCode &&
        this.props.selectedEmployee.address.postalCode)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
