import { combineReducers } from "redux";
import { listReducer, ListState, SearchAssignmentsListActions } from "./list";
import {
  overviewSearchAssignmentReducer,
  OverviewSearchAssignmentState,
  SearchAssignmentsFilters
} from "./overview.reducer";
import { SearchAssignmentActions } from "./search-assignment.actions";
import {
  singleSearchAssignmentReducer,
  SingleSearchAssignmentState
} from "./single.reducer";

export { SearchAssignmentsListActions };
export {
  SearchAssignmentActions,
  searchAssignmentReducer,
  SearchAssignmentState,
  SearchAssignmentsFilters,
  SingleSearchAssignmentState
};

const searchAssignmentReducer = combineReducers({
  single: singleSearchAssignmentReducer,
  overview: overviewSearchAssignmentReducer,
  list: listReducer
});

interface SearchAssignmentState {
  single: SingleSearchAssignmentState;
  overview: OverviewSearchAssignmentState;
  list: ListState;
}
