import { File } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Icon from "@haywork/components/ui/icon";
import {
  EMAIL_ATTACHMENTS_BLACKLIST,
  EXTERNALROUTES,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { FileUtil, MimeCategory } from "@haywork/util";
import last from "lodash-es/last";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import Button from "@haywork/components/ui/button";

const styles = require("./style.scss");

export enum EmailAttachmentAction {
  Download = "Download",
  Save = "Save",
}

type Props = {
  file: File;
  onAction?: (action: EmailAttachmentAction, file: File) => void;
};

const AttachmentComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ file, onAction }) => {
    const { contentType, fileName, size } = file;
    const [actionsVisisble, setActionVisible] = useState(false);
    const [blacklistedModalVisible, setBlacklistedModalVisible] =
      useState(false);
    const actionsRef = useRef<HTMLDivElement>(null);

    const icon = useMemo(() => {
      const detailedMimeType = FileUtil.getDetailedMime(contentType);
      let backgroundColor = Colors.Primary.toString();
      let name = "file";

      switch (detailedMimeType.category) {
        case MimeCategory.Audio: {
          name = "file-audio";
          break;
        }
        case MimeCategory.Image: {
          name = "file-image";
          break;
        }
        case MimeCategory.Video: {
          name = "file-video";
          break;
        }
        case MimeCategory.Word: {
          name = "file-word";
          break;
        }
        case MimeCategory.Excel: {
          backgroundColor = "#207345";
          name = "file-excel";
          break;
        }
        case MimeCategory.PowerPoint: {
          backgroundColor = "#D14424";
          name = "file-powerpoint";
          break;
        }
        case MimeCategory.Code: {
          name = "file-code";
          break;
        }
        case MimeCategory.PDF: {
          name = "file-pdf";
          break;
        }
        default:
          break;
      }

      return (
        <div styleName="attachment__icon" style={{ backgroundColor }}>
          <Icon name={name} color={Colors.White} size={16} />
        </div>
      );
    }, [contentType]);

    const fileSize = useMemo(() => {
      return FileUtil.getFileSize(size);
    }, [size]);

    const extension = useMemo(() => {
      if (!file || !file.fileName) {
        return null;
      }

      const segments = file.fileName.split(".");
      if (!segments.length) {
        return null;
      }

      return last(segments).toUpperCase();
    }, [file]);

    const toggleActionsVisible = useCallback(() => {
      setActionVisible(!actionsVisisble);
    }, [actionsVisisble, setActionVisible]);

    const onActionHandler = useCallback(
      (action: EmailAttachmentAction) => {
        setActionVisible(false);
        if (!onAction) return;

        if (!!file.fileName) {
          const segments = file.fileName.split(".");
          if (segments.length) {
            const isBlackListed =
              EMAIL_ATTACHMENTS_BLACKLIST.includes(extension);
            if (isBlackListed) {
              setBlacklistedModalVisible(true);
              return;
            }
          }
        }

        onAction(action, file);
      },
      [setActionVisible, onAction, file, extension]
    );

    return (
      <>
        <div styleName="attachment">
          {icon}
          <div styleName="attachment__meta">
            <div styleName="name">
              {fileName || <I18n value="email.attachment.missingFileName" />}
            </div>
            <div styleName="size">{fileSize}</div>
          </div>
          <div
            styleName="attachment__actions"
            ref={actionsRef}
            onClick={toggleActionsVisible}
          >
            <Icon name="chevron-down" color={Colors.Gray} size={12} light />
          </div>
        </div>

        <ActionList
          parent={actionsRef}
          visible={actionsVisisble}
          onHide={toggleActionsVisible}
        >
          <Spacer label="actions" />
          <Action
            label="email.attachment.download"
            icon={
              <Icon
                name="arrow-to-bottom"
                color={Colors.ActionListIcon}
                light
                fixedWidth
              />
            }
            onClick={() => onActionHandler(EmailAttachmentAction.Download)}
          />
          <Action
            label="email.attachment.save"
            icon={
              <Icon
                name="save"
                color={Colors.ActionListIcon}
                light
                fixedWidth
              />
            }
            onClick={() => onActionHandler(EmailAttachmentAction.Save)}
          />
        </ActionList>

        <Modal
          visible={blacklistedModalVisible}
          onClose={() => setBlacklistedModalVisible(false)}
          style={{ maxWidth: 360 }}
        >
          <ModalHeader close title="email.attachment.blacklisted.modalTitle" />
          <ModalBody>
            <I18n
              value="email.attachment.blacklisted.modalBody"
              values={{ type: extension }}
            />{" "}
            <a
              href={EXTERNALROUTES.SUPPORT_DOSSIER_UNSUPPORTED_ATTACHMENT.URI}
              target="_blank"
              rel="noopener"
            >
              <I18n value="readWhy" />
            </a>
          </ModalBody>
          <ModalFooter>
            <Button
              label="okay"
              category="primary"
              onClick={() => setBlacklistedModalVisible(false)}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  })
);

export default AttachmentComponent;
