import { RelationSnapShot } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import * as React from "react";
import { CSSProperties, FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../../style.scss");

type Props = {
  relation: RelationSnapShot;
  small?: boolean;
  avatarBorderColor?: string;
};

export const AvatarComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ relation, small, avatarBorderColor }) => {
      const { avatarUrl, letterAvatar } = relation;

      const injectedStyles = useMemo(() => {
        return {
          borderColor: avatarBorderColor,
          backgroundImage: !avatarUrl
            ? null
            : `url(${JSON.stringify(avatarUrl)})`,
        } as CSSProperties;
      }, [avatarBorderColor, avatarUrl]);

      return (
        <div styleName={classNames("avatar", { small })}>
          {!!avatarUrl ? (
            <div style={injectedStyles} styleName="avatar__visual" />
          ) : !!letterAvatar ? (
            <div style={injectedStyles} styleName="avatar__textual">
              {letterAvatar}
            </div>
          ) : (
            <div styleName="avatar__icon">
              <Icon name="user" color={Colors.White} light />
            </div>
          )}
        </div>
      );
    }
  )
);
