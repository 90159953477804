import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { EmailRequestState } from "@haywork/stores/email-v2/main";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  status: EmailRequestState;
  onReloadEmailState: () => void;
};

export const StatusComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ status, onReloadEmailState }) => {
      const label = useMemo(() => {
        switch (status) {
          case EmailRequestState.Error: {
            return (
              <div styleName="label error" onClick={onReloadEmailState}>
                <div styleName="label__icon">
                  <Icon
                    name="exclamation-circle"
                    solid
                    color={Colors.White}
                    size={18}
                  />
                </div>
                <div styleName="label__body">
                  <I18n prefix="email.status" value={status.toString()} />
                </div>
              </div>
            );
          }
          case EmailRequestState.FetchingAccounts:
          case EmailRequestState.FetchingDrafts:
          case EmailRequestState.FetchingFolders:
          case EmailRequestState.FetchingMessages: {
            return (
              <div styleName="label">
                <div styleName="label__icon">
                  <Icon
                    name="spinner"
                    regular
                    color={Colors.White}
                    size={18}
                    spin
                  />
                </div>
                <div styleName="label__body">
                  <I18n prefix="email.status" value={status.toString()} />
                </div>
              </div>
            );
          }
          default: {
            return null;
          }
        }
      }, [status, onReloadEmailState]);

      return label;
    }
  )
);
