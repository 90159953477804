import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentEditClientContainer,
  AssignmentEditAddressContainer,
  AssignmentEditFinancialContainer,
  AssignmentEditContentContainer,
  AssignmentEditMarketingContainer,
  AssignmentPublishContainer
} from "@haywork/modules/assignment";

const route = RouteUtil.mapStaticRouteValues;
export const AssignmentEditRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ ASSIGNMENTROUTES.EDIT.URI } render={ () => <Redirect to={ route(ASSIGNMENTROUTES.EDIT_CLIENT.URI, { id }) } /> } />
    <Route exact path={ ASSIGNMENTROUTES.EDIT_CLIENT.URI } component={ AssignmentEditClientContainer } />
    <Route path={ ASSIGNMENTROUTES.EDIT_ADDRESS.URI } exact component={ AssignmentEditAddressContainer } />
    <Route path={ ASSIGNMENTROUTES.EDIT_ADDRESS_PARAMS.URI } exact component={ AssignmentEditAddressContainer } />
    <Route path={ ASSIGNMENTROUTES.EDIT_FINANCIAL.URI } component={ AssignmentEditFinancialContainer } />
    <Route path={ ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI } component={ AssignmentEditContentContainer } />
    <Route path={ ASSIGNMENTROUTES.EDIT_MARKETING.URI } component={ AssignmentEditMarketingContainer } />
    <Route path={ ASSIGNMENTROUTES.PUBLISH.URI } component={ AssignmentPublishContainer } />
  </Switch>;
};
