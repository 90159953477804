import { Bid, BidOrderByField, BidSnapShot } from "@haywork/api/kolibri";
import { BidThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import * as React from "react";
import { forwardRef, Ref } from "react";
import { connect, MapStateToProps } from "react-redux";
import {
  BidsWidgetComponent,
  BidsWidgetComponentProps,
  BidsWidgetRef
} from "./bids-widget";

type StateProps = {};
type DispatchProps = {
  getBids: (
    assignmentId: string,
    orderBy: BidOrderByField,
    take: number
  ) => Promise<{ results: BidSnapShot[]; total: number }>;
  navigate: (path: string) => RouterAction;
  readBid: (id: string) => Promise<Bid>;
  deleteBid: (id: string, undeleteCallback: () => void) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  BidsWidgetComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  BidsWidgetComponentProps
> = (dispatch) => ({
  getBids: (assignmentId: string, orderBy: BidOrderByField, take: number) =>
    dispatch(BidThunk.getBids(assignmentId, orderBy, take)),
  navigate: (path: string) => dispatch(push(path)),
  readBid: (id: string) => dispatch(BidThunk.readBid(id)),
  deleteBid: (id: string, undeleteCallback: () => void) =>
    dispatch(BidThunk.deleteBid(id, undeleteCallback))
});

export type BidsWidgetContainerProps = StateProps & DispatchProps;
const BidsWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BidsWidgetComponent);

export const BidsWidgetRefComponent = forwardRef(
  (props: BidsWidgetComponentProps, ref: Ref<BidsWidgetRef>) => (
    <BidsWidgetContainer {...props} myForwardedRef={ref} />
  )
);
