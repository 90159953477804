import { PresentEmployee } from "./reducer";

export enum ActionType {
  AddEmployee = "EventCenter.Presence.AddEmployee",
  UpdateEmployee = "EventCenter.Presence.UpdateEmployee",
  RemoveEmployee = "EventCenter.Presence.RemoveEmployee"
}

type UpdateEmployee = {
  type: ActionType.UpdateEmployee;
  employee: PresentEmployee;
};

const updateEmployee = (employee: PresentEmployee) => ({
  type: ActionType.UpdateEmployee,
  employee
});

type AddEmployee = {
  type: ActionType.AddEmployee;
  employee: PresentEmployee;
};

const addEmployee = (employee: PresentEmployee) => ({
  type: ActionType.AddEmployee,
  employee
});

type RemoveEmployee = {
  type: ActionType.RemoveEmployee;
  id: string;
  guid: string;
};

const removeEmployee = (id: string, guid: string) => ({
  type: ActionType.RemoveEmployee,
  id,
  guid
});

export type ActionTypes = UpdateEmployee | AddEmployee | RemoveEmployee;
export const actions = {
  updateEmployee,
  addEmployee,
  removeEmployee
};
