import * as React from "react";
import { memo } from "react";
import { ResourceText } from "../../..";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  label: string;
  values?: { [key: string]: any };
  icon?: string;
  iconUrl?: string;
  onClick: () => void;
}

export const DossierActionListActionComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ label, values, icon, iconUrl, onClick }: Props) => {
      return (
        <div styleName="action" onClick={onClick}>
          <div
            styleName={classNames("icon", { "for-icon": !!icon })}
            style={{
              backgroundImage: !!iconUrl
                ? `url(${JSON.stringify(iconUrl)})`
                : null,
            }}
          >
            {!!icon && <i className={icon} />}
            {!icon && !iconUrl && <i className="fal fa-rocket" />}
          </div>
          <div styleName="label">
            <ResourceText resourceKey={label} values={values} asHtml />
          </div>
        </div>
      );
    }
  )
);
