import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFiltersState } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { SearchComponent, SearchComponentProps } from "./search";

type StateProps = {
  filters: EmailFiltersState;
};
type DispatchProps = {
  setFilters: (filters: EmailFiltersState) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SearchComponentProps,
  AppState
> = (state) => {
  const { filters } = state.emailV2.main;

  return {
    filters,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SearchComponentProps
> = (dispatch) => ({
  setFilters: (filters: EmailFiltersState) =>
    dispatch(EmailThunk.Main.setFilters(filters)),
});

export type SearchContainerProps = StateProps & DispatchProps;
export const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);
