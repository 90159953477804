import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentPhase,
  AssignmentsClient,
  AssignmentSnapShot,
  AssignmentType,
  GeoClient,
  ListingType,
  ProjectAssignment,
  ProjectAssignmentsClient,
  PublicationsClient,
  RealEstateGroup,
  RelationOrderByField,
  RelationsClient,
  SortOrder,
  TimelineActionType,
  TimelineEventsClient,
  TimelineOrderByField,
  UpdateAvailabilityAction,
  WithdrawReason,
  ProjectAssignmentLinkRelationRequest,
} from "@haywork/api/kolibri";
import { MAINROUTES, PROJECTROUTES, REQUEST } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ParseRequest, ApiType } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  ErrorActions,
  FormErrors,
  FormErrorsActions,
  LayoutActions,
  ProjectsActions,
  SingleProjectState,
} from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import {
  AsyncUtil,
  BackOfficeUtil,
  DateUtil,
  ProjectUtil,
  RouteUtil,
} from "@haywork/util";
import { ProjectPublishError } from "@haywork/util/project";
import first from "lodash-es/first";
import get from "lodash-es/get";
import * as moment from "moment";
import { Dispatch } from "../";
import { push } from "connected-react-router";

const route = RouteUtil.mapStaticRouteValues;
const parseRequest = new ParseRequest();

const getProject = (id: string, snapshot: AssignmentSnapShot) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setProjectStatus(REQUEST.PENDING));
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });
    const projectAssignment: ProjectAssignment = ProjectUtil.Mappers.mapSnapshotToProjectAssignment(
      snapshot
    );

    let componentState = {
      projectAssignment,
      publications: [],
      objectTypes: [],
      pastEvents: [],
      futureEvents: [],
      isInitial: true,
      buildnumbers: [],
      buildnumbersTotal: 0,
    };

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.PROJECTS.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveProjectConfirmTitle" },
          body: { key: "saveProjectConfirmBody" },
        },
        entityType: RootEntityType.ProjectAssignment,
        entityId: id,
      })
    );

    if (!!projectAssignment) {
      dispatch(push(path));
      dispatch(ProjectsActions.Single.setProject(componentState));
      dispatch(ProjectsActions.Single.setProjectStatus(REQUEST.SUCCESS));
    }

    const projectAssignmentsClient = new ProjectAssignmentsClient(host);
    const Publications = new PublicationsClient(host);
    const TimelineEvents = new TimelineEventsClient(host);
    const Assignments = new AssignmentsClient(host);

    // Get actual assignment detail
    projectAssignmentsClient
      .read(id, realEstateAgencyId)
      .then((result) => {
        componentState = {
          ...componentState,
          projectAssignment: result.projectAssignment,
          isInitial: false,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState,
            ignoreChanges: true,
          })
        );
        dispatch(ProjectsActions.Single.updateSingleProject(componentState));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(ProjectsActions.Single.setProjectStatus(REQUEST.ERROR));
      });

    // Get assignment publications
    Publications.search(
      {
        assignmentId: id,
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        publications: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateSingleProject(componentState));
    });

    // Events shared request config
    const eventsRequestConfig = {
      orderBy: TimelineOrderByField.Date,
      includeStatistics: false,
      filterByActive: ActiveFilter.ActiveOnly,
      skip: 0,
      take: 5,
      order: SortOrder.Descending,
      assignmentId: id,
    };

    // Get past events
    TimelineEvents.search(
      {
        ...eventsRequestConfig,
        maxDateTime: new Date(),
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        pastEvents: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateSingleProject(componentState));
    });

    // Get future events
    TimelineEvents.search(
      {
        ...eventsRequestConfig,
        minDateTime: new Date(),
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        futureEvents: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateSingleProject(componentState));
    });

    Assignments.search(
      {
        culture: state.main.culture,
        forSale: true,
        forRent: true,
        orderBy: AssignmentOrderByField.ConstructionNumber,
        includeStatistics: true,
        filterByActive: ActiveFilter.ActiveOrInactive,
        skip: 0,
        take: 100,
        order: SortOrder.Ascending,
        filterByAssignmentTypes: [
          AssignmentType.ObjectType,
          AssignmentType.Object,
        ],
        filterByProjectAssignmentIds: [id],
      },
      realEstateAgencyId
    ).then((result) => {
      const { objectTypes, buildnumbers } = result.results.reduce(
        (state, assignment) => {
          if (assignment.typeOfAssignment === AssignmentType.Object) {
            state.buildnumbers.push(assignment);
          } else {
            state.objectTypes.push(assignment);
          }
          return state;
        },
        {
          objectTypes: [],
          buildnumbers: [],
        }
      );

      componentState = {
        ...componentState,
        objectTypes,
        buildnumbers,
        buildnumbersTotal: result.totalResults,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateSingleProject(componentState));
    });
  };
};

const getBuildnumbers = (
  projectId: string,
  objectTypeId: string = "",
  take: number = 25,
  filterByActive: ActiveFilter = ActiveFilter.ActiveOnly,
  skip?: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setBuildnumbersStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { buildnumbers: items } = state.project.single;

    items = items.filter(
      (item) =>
        (item.linkedProjectAssignment && item.linkedProjectAssignment.id) ===
        projectId
    );
    skip = skip === null || skip === undefined ? items.length : skip;

    const Assignments = new AssignmentsClient(host);

    try {
      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ConstructionNumber,
            filterByActive,
            order: SortOrder.Ascending,
            skip,
            take,
            filterByAssignmentTypes: [AssignmentType.Object],
            filterByObjectTypeAssignmentIds: objectTypeId
              ? [objectTypeId]
              : null,
            filterByProjectAssignmentIds: [projectId],
          },
          realEstateAgencyId
        )
      );

      const {
        results: buildnumbers,
        totalResults: buildnumbersTotal,
      } = response;
      const buildnumbersCanLoadMore =
        skip + buildnumbers.length < buildnumbersTotal;

      if (skip === 0) {
        dispatch(
          ProjectsActions.Single.setBuildNumbers({
            buildnumbers,
            buildnumbersCanLoadMore,
            buildnumbersTotal,
          })
        );
      } else {
        dispatch(
          ProjectsActions.Single.addBuildNumbers({
            buildnumbers,
            buildnumbersCanLoadMore,
            buildnumbersTotal,
          })
        );
      }
    } catch (error) {
      dispatch(ProjectsActions.Single.setBuildnumbersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getObjectTypes = (
  projectId: string,
  filterByActive: ActiveFilter,
  reset: boolean = false,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setObjectTypesStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { objectTypes: items } = state.project.single;

    items = items.filter(
      (item) =>
        (item.linkedProjectAssignment && item.linkedProjectAssignment.id) ===
        projectId
    );
    const skip = reset ? 0 : items.length;

    const Assignments = new AssignmentsClient(host);

    try {
      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ModificationDate,
            filterByActive,
            order: SortOrder.Ascending,
            skip,
            take,
            filterByAssignmentTypes: [AssignmentType.ObjectType],
            filterByProjectAssignmentIds: [projectId],
          },
          realEstateAgencyId
        )
      );

      const { results: objectTypes, totalResults: objectTypesTotal } = response;
      const objectTypesCanLoadMore =
        skip + objectTypes.length < objectTypesTotal;

      if (skip === 0) {
        dispatch(
          ProjectsActions.Single.setObjectTypes({
            objectTypes,
            objectTypesCanLoadMore,
            objectTypesTotal,
          })
        );
      } else {
        dispatch(
          ProjectsActions.Single.addObjectTypes({
            objectTypes,
            objectTypesCanLoadMore,
            objectTypesTotal,
          })
        );
      }
    } catch (error) {
      dispatch(ProjectsActions.Single.setObjectTypesStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const searchAddress = (location: string, countryIso2: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Geo = new GeoClient(host);

    try {
      const result = await Geo.addressSearch(
        {
          countryIso2,
          location,
          culture,
        },
        realEstateAgencyId
      );

      return result.results;
    } catch (error) {
      return null;
    }
  };
};

const saveProject = (
  projectAssignment?: ProjectAssignment,
  redirect: boolean = true,
  initial: boolean = false,
  close: boolean = false,
  silent: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account, editable } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;

    // Clients
    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const Publications = new PublicationsClient(host);

    // Local variables
    let reference: SingleProjectState = editable.currentComponentState;
    const assignmentPath = route(PROJECTROUTES.DETAIL.URI, {
      id: reference.projectAssignment.id,
    });

    try {
      // Update reference if ProjectAssignment is passed
      if (!!projectAssignment) {
        reference = {
          ...reference,
          projectAssignment,
        };
      }

      // Set pending state
      if (!silent) {
        dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));
      }

      const saveResult = await parseRequest.response(
        ProjectAssignments.save(
          { projectAssignment: reference.projectAssignment },
          realEstateAgencyId
        )
      );

      reference = {
        ...reference,
        projectAssignment: saveResult.projectAssignment,
      };

      const publications = await parseRequest.response(
        Publications.search(
          { assignmentId: reference.projectAssignment.id },
          realEstateAgencyId
        )
      );

      reference = {
        ...reference,
        publications: publications.results,
      };

      if (close) {
        dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.IDLE));
        return dispatch(EditableThunks.remove(assignmentPath));
      }

      // Fire dispatch actions
      dispatch(
        ProjectsActions.Single.updateProject({
          projectAssignment: reference.projectAssignment,
        })
      );
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState: reference,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );

      // Handle callback?
      const { states } = state.editable;
      const editableItem = states.find((s) => s.path === assignmentPath);
      if (!!editableItem && !!editableItem.caller) {
        dispatch(
          EditableActions.setCalleeId({
            id: reference.projectAssignment.id,
            path: editableItem.caller,
          })
        );
        dispatch(push(editableItem.caller));
        return dispatch(EditableThunks.remove(assignmentPath));
      }

      // Should redirect to general screen?
      if (!!redirect) {
        dispatch(
          push(
            route(PROJECTROUTES.DETAIL.URI, {
              id: reference.projectAssignment.id,
            })
          )
        );
        dispatch(LayoutActions.toggleFullscreen(false));
      }

      // Should redirect after initial save?
      if (!!initial) {
        dispatch(
          push(
            route(PROJECTROUTES.EDIT_MARKETING.URI, {
              id: reference.projectAssignment.id,
            })
          )
        );
      }

      if (!initial && !redirect) {
        const toast: ToastProps = {
          value: "toastProjectSaved",
          icon: "sitemap",
        };

        dispatch(SnackbarActions.addToast(toast));
      }
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
    }
  };
};

const linkRelation = (
  relation: ProjectAssignmentLinkRelationRequest,
  updatedProjectAssignment: ProjectAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const assignmentPath = route(PROJECTROUTES.DETAIL.URI, {
      id: updatedProjectAssignment.id,
    });
    let componentState: SingleProjectState =
      state.editable.currentComponentState;

    // Clients
    const ProjectAssignments = new ProjectAssignmentsClient(host);
    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));

    try {
      await ProjectAssignments.linkRelation(relation, realEstateAgencyId);
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));
      dispatch(
        ProjectsActions.Single.updateProject({
          projectAssignment: updatedProjectAssignment,
        })
      );
      componentState = {
        ...componentState,
        projectAssignment: updatedProjectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unlinkRelation = (
  relation: ProjectAssignmentLinkRelationRequest,
  updatedProjectAssignment: ProjectAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const assignmentPath = route(PROJECTROUTES.DETAIL.URI, {
      id: updatedProjectAssignment.id,
    });
    let componentState: SingleProjectState =
      state.editable.currentComponentState;

    // Clients
    const ProjectAssignments = new ProjectAssignmentsClient(host);
    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));

    try {
      await ProjectAssignments.unlinkRelation(relation, realEstateAgencyId);
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));
      dispatch(
        ProjectsActions.Single.updateProject({
          projectAssignment: updatedProjectAssignment,
        })
      );
      componentState = {
        ...componentState,
        projectAssignment: updatedProjectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const publishProjectAssignment = (id: string, date?: Date) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });

    const ProjectAssignments = new ProjectAssignmentsClient(host);

    dispatch(
      ProjectsActions.Single.setPublishStatus({ publishState: REQUEST.PENDING })
    );

    try {
      await parseRequest.response(
        ProjectAssignments.save(
          {
            projectAssignment:
              state.editable.currentComponentState.projectAssignment,
          },
          realEstateAgencyId
        )
      );
    } catch (error) {
      dispatch(
        ProjectsActions.Single.setPublishStatus({ publishState: REQUEST.ERROR })
      );
      throw error;
    }

    try {
      const projectAssignment = await parseRequest.response(
        ProjectAssignments.updateAvailability(
          {
            id,
            updateAvailabilityAction: UpdateAvailabilityAction.ToAvailable,
          },
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      const componentState: SingleProjectState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(ProjectsActions.Single.updateProject({ projectAssignment }));
      dispatch(
        ProjectsActions.Single.setPublishStatus({
          publishState: REQUEST.SUCCESS,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(push(path));
    } catch (error) {
      dispatch(reloadProjectAssignment(id));
      dispatch(
        ProjectsActions.Single.setPublishStatus({ publishState: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const reloadProjectAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });

    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      const projectAssignment = await parseRequest.response(
        ProjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.projectAssignment
        )
      );

      const componentState: SingleProjectState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(ProjectsActions.Single.updateProject({ projectAssignment }));
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateProjectEditable = (
  componentState: SingleProjectState,
  path: string,
  ignoreChanges: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
        ignoreChanges,
      })
    );
  };
};

const createProject = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { offices } = state.account;
    const office = first(offices);
    const { host } = state.appSettings;
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const assignment = ProjectAssignments.defineNew(
      {
        employeeId,
        forRent: false,
        forSale: true,
        listingType: ListingType.House,
        officeId: office.id,
        realEstateGroup: RealEstateGroup.Residential,
      },
      realEstateAgencyId
    );

    Promise.all([assignment])
      .then((results) => {
        const { projectAssignment } = results[0];

        // Set default value
        projectAssignment.isPermanentlyInhabited = true;

        const route = RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
          id: projectAssignment.id,
        });
        const redirect = RouteUtil.mapStaticRouteValues(
          PROJECTROUTES.EDIT_CLIENT.URI,
          { id: projectAssignment.id }
        );
        const componentState = {
          pastEvents: [],
          futureEvents: [],
          objectTypes: [],
          projectAssignment,
          isInitial: false,
          buildnumbers: [],
          buildnumbersTotal: 0,
          publications: [],
        };

        dispatch(ProjectsActions.Single.setProject(componentState));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
        dispatch(
          EditableActions.addState({
            icon: MAINROUTES.PROJECTS.ICON,
            componentState,
            path: route,
            title: "...",
            confirm: {
              title: { key: "saveProjectConfirmTitle" },
              body: { key: "saveProjectConfirmBody" },
            },
            entityType: RootEntityType.ProjectAssignment,
            entityId: projectAssignment.id,
          })
        );
        dispatch(push(redirect));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
      });
  };
};

const activatePublication = (assignmentId: string, mediaPartnerId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;

    const Publications = new PublicationsClient(host);

    dispatch(
      ProjectsActions.Single.setMediaPartnerState({
        mediaPartnerState: REQUEST.PENDING,
        mediaPartnerChangedId: mediaPartnerId,
      })
    );

    Publications.activate(
      {
        assignmentId,
        mediaPartnerId,
      },
      realEstateAgencyId
    )
      .then((result) =>
        Publications.search({ assignmentId }, realEstateAgencyId)
      )
      .then((result) => {
        const path = route(PROJECTROUTES.DETAIL.URI, { id: assignmentId });
        const componentState: SingleProjectState = {
          ...currentComponentState,
          publications: result.results,
        };

        dispatch(
          ProjectsActions.Single.setMediaPartnerState({
            mediaPartnerState: REQUEST.SUCCESS,
          })
        );
        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(
          ProjectsActions.Single.setMediaPartnerState({
            mediaPartnerState: REQUEST.ERROR,
          })
        );
      });
  };
};

const deActivatePublication = (
  assignmentId: string,
  mediaPartnerId: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;

    const Publications = new PublicationsClient(host);

    Publications.deactivate(
      {
        assignmentId,
        mediaPartnerId,
      },
      realEstateAgencyId
    )
      .then((result) =>
        Publications.search({ assignmentId }, realEstateAgencyId)
      )
      .then((result) => {
        const path = route(PROJECTROUTES.DETAIL.URI, { id: assignmentId });
        const componentState: SingleProjectState = {
          ...currentComponentState,
          publications: result.results,
        };

        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
      });
  };
};

const getTimelineEvents = (
  assignmentId: string,
  timelineActionType: TimelineActionType[] = [],
  init: boolean = true,
  take: number = 25
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setTimelineEventsStatus(REQUEST.PENDING));

    const state = getState();
    const { timelinePage } = state.project.single;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const TimelineEvents = new TimelineEventsClient(host);

    TimelineEvents.search(
      {
        relationId: "",
        assignmentId,
        minDateTime: null,
        maxDateTime: null,
        filterByActionTypes: timelineActionType,
        termFields: null,
        orderBy: TimelineOrderByField.Date,
        includeStatistics: true,
        filterByActive: ActiveFilter.ActiveOrInactive,
        term: null,
        skip: init ? 0 : timelinePage * take,
        take,
        order: SortOrder.Descending,
      },
      realEstateAgencyId
    )
      .then((response) => {
        dispatch(
          init
            ? ProjectsActions.Single.setTimelineEvents({ response })
            : ProjectsActions.Single.appendTimelineEvents({ response })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(ProjectsActions.Single.setTimelineEventsStatus(REQUEST.ERROR));
      });
  };
};

const deleteProject = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const path = route(PROJECTROUTES.DETAIL.URI, { id });

    ProjectAssignments.delete(id, realEstateAgencyId)
      .then(() => {
        dispatch(EditableThunks.remove(path));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
      });
  };
};

const addAroRelation = (relationId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id,
    });

    const Relations = new RelationsClient(host);

    Relations.search(
      {
        relationId,
        includeStatistics: false,
        orderBy: RelationOrderByField.DisplayName,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Ascending,
        skip: 0,
        take: 1,
      },
      realEstateAgencyId
    )
      .then((result) => {
        const { results } = result;

        if (results.length >= 1) {
          const relation = first(results);
          const componentState: SingleProjectState = {
            ...currentComponentState,
            aroCreatedRelation: relation,
          };

          dispatch(EditableActions.removeCallee(path));
          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(EditableActions.removeCallee(path));
      });
  };
};

const addLinkedVendor = (relationId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id,
    });

    const Relations = new RelationsClient(host);

    Relations.search(
      {
        relationId,
        includeStatistics: false,
        orderBy: RelationOrderByField.DisplayName,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Ascending,
        skip: 0,
        take: 1,
      },
      realEstateAgencyId
    )
      .then((result) => {
        const { results } = result;

        if (results.length >= 1) {
          const relation = first(results);
          let linkedVendors = get(
            currentComponentState,
            "projectAssignment.linkedVendors",
            []
          );
          linkedVendors = [...linkedVendors, relation];

          const componentState: SingleProjectState = {
            ...currentComponentState,
            projectAssignment: {
              ...currentComponentState.projectAssignment,
              linkedVendors,
            },
          };

          dispatch(EditableActions.removeCallee(path));
          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
              ignoreChanges: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(EditableActions.removeCallee(path));
      });
  };
};

const addLinkedApplicant = (relationId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id,
    });

    const Relations = new RelationsClient(host);

    Relations.search(
      {
        relationId,
        includeStatistics: false,
        orderBy: RelationOrderByField.DisplayName,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Ascending,
        skip: 0,
        take: 1,
      },
      realEstateAgencyId
    )
      .then((result) => {
        const { results } = result;

        if (results.length >= 1) {
          const relation = first(results);
          let linkedApplicants = get(
            currentComponentState,
            "projectAssignment.linkedApplicants",
            []
          );
          linkedApplicants = [...linkedApplicants, relation];

          const componentState: SingleProjectState = {
            ...currentComponentState,
            projectAssignment: {
              ...currentComponentState.projectAssignment,
              linkedApplicants,
            },
          };

          dispatch(EditableActions.removeCallee(path));
          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
              ignoreChanges: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(EditableActions.removeCallee(path));
      });
  };
};

const setPublishFormErrors = (
  publishErrors: ProjectPublishError[],
  id: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const path = route(PROJECTROUTES.DETAIL.URI, { id });

    const formError: FormErrors = {
      path,
      stack: null,
    };

    dispatch(FormErrorsActions.setErrors({ formError }));
    dispatch(push(first(formError.stack).path));
  };
};

const searchSublocalities = (localityId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    let { currentComponentState: componentState } = state.editable;
    const { culture } = state.main;

    const Geo = new GeoClient(host);

    try {
      const result = await Geo.sublocalitySearch(
        { localityId, culture },
        realEstateAgencyId
      );

      componentState = {
        ...componentState,
        sublocalities: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: route(PROJECTROUTES.DETAIL.URI, {
            id: componentState.projectAssignment.id,
          }),
        })
      );
    } catch (error) {
      dispatch(ErrorActions.setError(error));
    }
  };
};

const clearAvailabilityStatus = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      ProjectsActions.Single.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.IDLE,
      })
    );
  };
};

const withdrawProjectAssignment = (
  projectId: string,
  withdrawReason: WithdrawReason,
  withdrawnDateTime: Date
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });

    dispatch(
      ProjectsActions.Single.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.PENDING,
      })
    );

    await parseRequest.response(
      ProjectAssignments.updateAvailability(
        {
          id: projectId,
          updateAvailabilityAction: UpdateAvailabilityAction.ToWithdrawn,
        },
        realEstateAgencyId
      )
        .then((result) => {
          dispatch(
            ProjectsActions.Single.setChangeAvailabilityStatus({
              changeAvailabilityStatus: REQUEST.SUCCESS,
            })
          );
          const componentState: SingleProjectState = {
            ...currentComponentState,
            ProjectAssignment: result.projectAssignment,
          };
          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
              ignoreChanges: true,
            })
          );
          dispatch(
            ProjectsActions.Single.updateProject({
              projectAssignment: result.projectAssignment,
            })
          );
        })
        .catch((error) => {
          dispatch(ErrorActions.setError(error));
          dispatch(
            ProjectsActions.Single.setChangeAvailabilityStatus({
              changeAvailabilityStatus: REQUEST.ERROR,
            })
          );
          throw error;
        })
    );
  };
};

const sellProjectAssignment = (projectId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });

    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));
    dispatch(ProjectsActions.Single.toggleStatusModal(false));

    try {
      const projectAssignment = await parseRequest.response(
        ProjectAssignments.updateAvailability(
          {
            id: projectId,
            updateAvailabilityAction: UpdateAvailabilityAction.ToSold,
          },
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      const componentState: SingleProjectState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateProject({ projectAssignment }));
      dispatch(ProjectsActions.Single.toggleStatusModal(false));
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const rentProjectAssignment = (projectId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });

    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));
    dispatch(ProjectsActions.Single.toggleStatusModal(false));

    try {
      const projectAssignment = await parseRequest.response(
        ProjectAssignments.updateAvailability(
          {
            id: projectId,
            updateAvailabilityAction: UpdateAvailabilityAction.ToRented,
          },
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      const componentState: SingleProjectState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.updateProject({ projectAssignment }));
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveSingleProjectAssignment = (project?: ProjectAssignment) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let componentState: SingleProjectState =
      state.editable.currentComponentState;
    const assignmentPath = route(PROJECTROUTES.DETAIL.URI, {
      id: componentState.projectAssignment.id,
    });

    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      const projectAssignment = project || componentState.projectAssignment;
      const response = await ProjectAssignments.save(
        { projectAssignment },
        realEstateAgencyId
      );

      dispatch(
        ProjectsActions.Single.updateProject({
          projectAssignment: response.projectAssignment,
        })
      );
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.SUCCESS));

      componentState = {
        ...componentState,
        projectAssignment: response.projectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );

      return response;
    } catch (error) {
      dispatch(ErrorActions.setError({ error }));
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
    }
  };
};

const fetchLocalities = (adminAreaLevel2Id: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;

    const Geo = new GeoClient(host);

    try {
      return await parseRequest.response(
        Geo.localitySearch(
          {
            adminAreaLevel2Id,
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );
    } catch (error) {
      throw error;
    }
  };
};

const archive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });
    const currentComponentState: SingleProjectState =
      state.editable.currentComponentState;

    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      if (
        currentComponentState.projectAssignment.assignmentPhase ===
        AssignmentPhase.Initiated
      ) {
        await parseRequest.response(
          ProjectAssignments.save(
            {
              projectAssignment: {
                ...currentComponentState.projectAssignment,
                assignmentPhase: AssignmentPhase.Completed,
              },
            },
            realEstateAgencyId
          )
        );
      }

      await parseRequest.response(
        ProjectAssignments.archive({ id }, realEstateAgencyId)
      );

      const projectAssignment = await parseRequest.response(
        ProjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.projectAssignment
        )
      );

      const componentState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.setProject(componentState));
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unArchive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });
    const currentComponentState: SingleProjectState =
      state.editable.currentComponentState;

    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      await parseRequest.response(
        ProjectAssignments.unarchive({ id }, realEstateAgencyId)
      );

      const projectAssignment = await parseRequest.response(
        ProjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.projectAssignment
        )
      );

      const componentState = {
        ...currentComponentState,
        projectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(ProjectsActions.Single.setProject(componentState));
    } catch (error) {
      dispatch(ProjectsActions.Single.setSaveProjectStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const backToDashboard = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { single } = state.project;
    const currentComponentState: SingleProjectState =
      state.editable.currentComponentState;

    if (
      get(single.projectAssignment, "id") !==
      get(currentComponentState.projectAssignment, "id")
    )
      return;
    const { id } = single.projectAssignment;
    const path = route(PROJECTROUTES.DETAIL.URI, { id });

    dispatch(
      EditableActions.updateComponentState({
        ignoreChanges: true,
        path,
        componentState: single,
        hasChanges: false,
      })
    );
    dispatch(push(path));
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: SingleProjectState = editable.componentState;
    const { dateTimeModified } = componentState.projectAssignment;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new ProjectAssignmentsClient(host);
      const refProjectAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.projectAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: SingleProjectState = {
          ...componentState,
          projectAssignment: refProjectAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(ProjectsActions.Single.setProject(newComponentState));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refProjectAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refProjectAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadProject = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const projectAssignment = externalChangesData.updatedEntity as ProjectAssignment;
    const componentState: SingleProjectState = {
      ...editable.componentState,
      projectAssignment,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(ProjectsActions.Single.setProject(componentState));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

export const ProjectsThunks = {
  getProject,
  getBuildnumbers,
  searchAddress,
  saveProject,
  publishProjectAssignment,
  updateProjectEditable,
  createProject,
  activatePublication,
  deActivatePublication,
  getTimelineEvents,
  deleteProject,
  addAroRelation,
  addLinkedVendor,
  addLinkedApplicant,
  setPublishFormErrors,
  searchSublocalities,
  clearAvailabilityStatus,
  withdrawProjectAssignment,
  sellProjectAssignment,
  rentProjectAssignment,
  saveSingleProjectAssignment,
  getObjectTypes,
  fetchLocalities,
  archive,
  unArchive,
  backToDashboard,
  reloadProject,
  checkExternalChanges,
  linkRelation,
  unlinkRelation,
};
