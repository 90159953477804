import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { UiPhrasesModalContainerProps } from "@haywork/modules/ui/phrases";
import { ResourceText } from "@haywork/modules/shared";
import { PhraseSnapShot } from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { KEYCODE } from "@haywork/constants";

import { PhraseComponent } from "./phrase.component";

const styles = require("./modal.component.scss");

export interface UiPhrasesModalComponentProps {
  parent: HTMLDivElement;
  onClose: () => void;
  onPhraseAdd: (phrase: PhraseSnapShot) => void;
  onPhraseEdit: (phrase: PhraseSnapShot) => void;
  onAddClick: () => void;
}
interface UiPhrasesModalComponentState {
  currentKey: number;
}

@CSSModules(styles, { allowMultiple: true })
export class UiPhrasesModalComponent extends React.Component<
  UiPhrasesModalComponentProps & UiPhrasesModalContainerProps,
  UiPhrasesModalComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      currentKey: 0,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
    document.addEventListener("keydown", this.onKeyDownHandler, true);
  }

  public render() {
    const footerStyle = classNames("modal__footer", {
      selected: this.state.currentKey === this.props.phrases.length,
    });
    const wrapperStyles = this.getWrapperStyles();

    return (
      <div
        styleName="wrapper"
        ref={(ref) => (this.ref = ref)}
        style={wrapperStyles}
      >
        <div styleName="modal">
          <div styleName="modal__header">
            <ResourceText resourceKey="addPhrases" />
            <div styleName="close" onClick={this.props.onClose}>
              <span className="fal fa-times" />
            </div>
          </div>
          <div styleName="modal__body">
            {this.props.phrases.map((phrase, idx) => (
              <ErrorBoundary key={phrase.id}>
                <PhraseComponent
                  phrase={phrase}
                  onPhraseClick={this.props.onPhraseAdd}
                  onPhraseEdit={this.props.onPhraseEdit}
                  onPhraseDelete={this.props.deletePhraseFromList}
                  onDelete={this.props.onDelete}
                  onUnDelete={this.props.onUnDelete}
                  selected={this.state.currentKey === idx}
                />
              </ErrorBoundary>
            ))}
          </div>
          <div styleName={footerStyle} onClick={this.props.onAddClick}>
            <span className="fal fa-plus-circle" />
            <ResourceText resourceKey="defineNewPhrase" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
    document.removeEventListener("keydown", this.onKeyDownHandler, true);
  }

  private getWrapperStyles(): React.CSSProperties {
    if (!this.props.parent) return null;
    const { left, top } = this.props.parent.getBoundingClientRect();

    return {
      left: left - 8,
      top: top + 32,
    };
  }

  private onKeyDownHandler(event: any) {
    event.preventDefault();

    switch (event.keyCode) {
      case KEYCODE.ESCAPE: {
        return this.props.onClose();
      }
      case KEYCODE.ENTER: {
        const phrase =
          this.props.phrases.length > 0
            ? this.props.phrases[this.state.currentKey]
            : null;
        if (!phrase) return this.props.onAddClick();
        return this.props.onPhraseAdd(phrase);
      }
      case KEYCODE.DOWN_ARROW: {
        let currentKey = this.state.currentKey + 1;
        if (currentKey > this.props.phrases.length) currentKey = 0;
        return this.setState({ currentKey });
      }
      case KEYCODE.UP_ARROW: {
        let currentKey = this.state.currentKey - 1;
        if (currentKey < 0) currentKey = this.props.phrases.length;
        return this.setState({ currentKey });
      }
      default: {
        return;
      }
    }
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target)) this.props.onClose();
  }
}
