import { DocumentTemplateType } from "@haywork/api/kolibri";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import { createSelector } from "reselect";
import { DocumentTemplateCategory } from ".";
import { DossierLinkType } from "../components/download";
import { DynamicDocumentMetaLink } from "./download.container";

const sessionSelector = (state: AppState) =>
  state.dynamicDocuments.single.session;
const documentTemplatesSelector = (state: AppState) =>
  state.dynamicDocuments.list.documentTemplates || [];

export const links = createSelector(sessionSelector, (session) => {
  if (!session) return [] as DynamicDocumentMetaLink[];

  const {
    linkedVendors,
    linkedApplicants,
    linkedNotary,
    linkedAssignment,
    linkedPropertyManagers
  } = session;
  const links: DynamicDocumentMetaLink[] = [];

  if (!!linkedVendors) {
    links.push({
      type: DossierLinkType.Sellers,
      relations: linkedVendors
    });
  }

  if (!!linkedApplicants) {
    links.push({
      type: DossierLinkType.Buyers,
      relations: linkedApplicants
    });
  }

  if (!!linkedNotary) {
    links.push({
      type: DossierLinkType.Notary,
      relations: [linkedNotary]
    });
  }

  if (!!linkedPropertyManagers) {
    links.push({
      type: DossierLinkType.PropertyManagers,
      relations: linkedPropertyManagers
    });
  }

  if (!!linkedAssignment) {
    links.push({
      type: DossierLinkType.Assignment,
      assignment: linkedAssignment
    });
  }

  return links;
});

export const documentHasExternalChanges = createSelector(
  sessionSelector,
  (session) => {
    if (!session) return false;
    const { md5HashGeneratedDocument, blobDetails } = session;
    if (
      !md5HashGeneratedDocument ||
      !blobDetails ||
      !get(blobDetails, "md5Hash")
    )
      return false;
    return md5HashGeneratedDocument !== blobDetails.md5Hash;
  }
);

export const documentCategoryTemplates = createSelector(
  documentTemplatesSelector,
  (templates) => {
    let categories: DocumentTemplateCategory[] = [
      {
        category: DocumentTemplateType.BuyAgreement,
        templates: []
      },
      {
        category: DocumentTemplateType.MediationAssignment,
        templates: []
      },
      {
        category: DocumentTemplateType.RentAgreement,
        templates: []
      },
      {
        category: DocumentTemplateType.Form,
        templates: []
      },
      {
        category: DocumentTemplateType.LeaseAgreement,
        templates: []
      },
      {
        category: DocumentTemplateType.Other,
        templates: []
      },
      {
        category: DocumentTemplateType.Taxation,
        templates: []
      }
    ];

    categories = categories.map((category) => ({
      ...category,
      templates: sortBy(
        templates.filter(
          (template) => template.documentTemplateType === category.category
        ),
        (template) => template.name
      )
    }));

    return categories.filter((c) => c.templates.length > 0);
  }
);
