import {
  RelationGroupSnapShot,
  RelationGroupsStatistics
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./relation-groups.types";

export interface RelationGroupsState {
  relationGroups: RelationGroupSnapShot[];
  latestRelationGroupAdded: RelationGroupSnapShot;
  deletedRelationGroupsIds: string[];
  relationGroupsStatus: string;
  relationAddGroupsStatus: string;
  relationGroupsStatistics: RelationGroupsStatistics;
}

const INITIAL_STATE: RelationGroupsState = {
  relationGroups: [],
  latestRelationGroupAdded: null,
  relationGroupsStatus: "",
  relationAddGroupsStatus: "",
  deletedRelationGroupsIds: [],
  relationGroupsStatistics: null
};

export const relationGroupsReducer = (
  state = INITIAL_STATE,
  action: Action
): RelationGroupsState => {
  switch (action.type) {
    case REDUX.RELATION_GROUPS.SET_RELATION_GROUPS: {
      const { relationGroups, statistics } = <ActionType.RelationGroups>action;
      return {
        ...state,
        relationGroups,
        relationGroupsStatus: REQUEST.SUCCESS,
        relationGroupsStatistics: statistics
      };
    }
    case REDUX.RELATION_GROUPS.SET_RELATION_GROUP: {
      const { singleRelationGroup } = <ActionType.SingleRelationGroup>action;
      const relationGroups = [...state.relationGroups];
      const relationGroupsToSet = relationGroups.map((rg) => {
        if (rg.id === singleRelationGroup.id) {
          return singleRelationGroup;
        }
        return rg;
      });
      return { ...state, relationGroups: relationGroupsToSet };
    }
    case REDUX.RELATION_GROUPS.NEW_RELATION_GROUP: {
      const { relationGroup } = <ActionType.RelationGroup>action;
      return {
        ...state,
        relationGroups: [relationGroup, ...state.relationGroups],
        latestRelationGroupAdded: relationGroup
      };
    }
    case REDUX.RELATION_GROUPS.EDIT_RELATION_GROUP: {
      const { relationGroup } = <ActionType.RelationGroup>action;
      const relationGroups = state.relationGroups.map((relationGroupToEdit) => {
        if (relationGroupToEdit.id === relationGroup.id)
          return { ...relationGroup };
        return { ...relationGroupToEdit };
      });
      return { ...state, relationGroups };
    }
    case REDUX.RELATION_GROUPS.DELETE_RELATION_GROUP: {
      const { id } = <ActionType.Id>action;
      return {
        ...state,
        deletedRelationGroupsIds: [id, ...state.deletedRelationGroupsIds]
      };
    }
    case REDUX.RELATION_GROUPS.UNDELETE_RELATION_GROUP: {
      const { id } = <ActionType.Id>action;
      const deletedRelationGroupsIds = state.deletedRelationGroupsIds.filter(
        (deletedRelationGroupId) => deletedRelationGroupId !== id
      );
      return { ...state, deletedRelationGroupsIds };
    }
    case REDUX.RELATION_GROUPS.DELETE_FROM_STATE: {
      const { id } = <ActionType.Id>action;
      const deletedRelationGroupsIds = state.deletedRelationGroupsIds.filter(
        (deletedNote) => deletedNote !== id
      );
      const relationGroups = state.relationGroups.filter(
        (note) => note.id !== id
      );
      return { ...state, deletedRelationGroupsIds, relationGroups };
    }
    case REDUX.RELATION_GROUPS.SET_RELATION_GROUPS_STATUS: {
      const { relationGroupsStatus } = <ActionType.RelationGroupsStatus>action;
      return { ...state, relationGroupsStatus };
    }
    case REDUX.RELATION_GROUPS.SET_RELATION_ADD_GROUPS_STATUS: {
      const { relationAddGroupsStatus } = <ActionType.RelationAddGroupsStatus>(
        action
      );
      return { ...state, relationAddGroupsStatus };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
