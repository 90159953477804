import { MergeField } from "@haywork/api/kolibri";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  MergeFieldsComponent,
  MergeFieldsComponentProps
} from "./merge-fields.component";
import { mergeFieldCategories, mergeFields } from "./selectors";

interface StateProps {
  mergeFields: MergeField[];
  mergeFieldCategories: MergeField[];
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  MergeFieldsComponentProps,
  AppState
> = (state, ownProps) => {
  return {
    mergeFields: mergeFields(
      state,
      ownProps.categoryWhiteList || [],
      ownProps.categoryBlackList || []
    ),
    mergeFieldCategories: mergeFieldCategories(
      state,
      ownProps.categoryWhiteList || [],
      ownProps.categoryBlackList || []
    )
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  MergeFieldsComponentProps
> = (dispatch) => ({});

export type MergeFieldsContainerProps = StateProps & DispatchProps;
export const MergeFieldsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeFieldsComponent);
