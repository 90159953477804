import { WidgetEntityType } from "@haywork/api/authorization";
import { FolderTreeEntityType } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionDetailDossierScreenContainerProps } from "./dossier.container";
import Sidebar from "../../components/assignment-detail-sidebar";

const styles = require("./style.scss");

export type AcquisitionDetailDossierScreenProps = {};
type Props = AcquisitionDetailDossierScreenProps &
  AcquisitionDetailDossierScreenContainerProps;

export const AcquisitionDetailDossierScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionAssignmentObject }) => {
      return (
        <>
          <div styleName="sidebar">
            <Sidebar />
          </div>
          <div styleName="body">
            <div styleName="dossier">
              <ErrorBoundary>
                <Dossier
                  parentId={acquisitionAssignmentObject.id}
                  id={get(acquisitionAssignmentObject.linkedFolderTree, "id")}
                  subject={acquisitionAssignmentObject.publicReference}
                  entityType={FolderTreeEntityType.AcquisitionObjectAssignment}
                  blocked={false}
                  addNotes={true}
                />
              </ErrorBoundary>
            </div>
          </div>
        </>
      );
    }
  )
);
