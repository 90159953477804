import { Action } from "redux";

import differenceBy from "lodash-es/differenceBy";

import { REDUX, REQUEST } from "@haywork/constants";
import { CommonActionType } from "@haywork/stores/common";
import {
  PublicationSnapShot,
  TimelineEvent,
  TimelineEventsStatistics,
  RelationSnapShot,
  ProjectAssignment,
  AssignmentSnapShot
} from "@haywork/api/kolibri";

import * as ActionType from "./single.types";
import { FolderTreeEntityType } from "@haywork/middleware";

export interface SingleProjectState {
  projectAssignment: ProjectAssignment;
  isInitial: boolean;
  projectAssignmentState: string;
  publications: PublicationSnapShot[];
  pastEvents: TimelineEvent[];
  futureEvents: TimelineEvent[];

  objectTypes: AssignmentSnapShot[];
  objectTypesCanLoadMore: boolean;
  objectTypesStatus: string;
  objectTypesTotal: number;

  events: TimelineEvent[];
  timelinePage: number;
  timelinePageCount: number;
  timelineStatus: string;
  timelineStatistics: TimelineEventsStatistics;

  publishState: string;
  changeAvailabilityStatus: string;
  showStatusModal: boolean;

  saveAssignmentState: string;
  addressSearchState: string;
  showWithdrawModal: boolean;
  withdrawState: string;
  withdrawError: string[];
  showSaveModal: boolean;

  mediaPartnerState: string;
  mediaPartnerChangedId: string;

  aroCreatedRelation: RelationSnapShot;

  buildnumbers: AssignmentSnapShot[];
  buildnumbersCanLoadMore: boolean;
  buildnumbersStatus: string;
  buildnumbersTotal: number;
}

const INITIAL_STATE: SingleProjectState = {
  projectAssignment: null,
  projectAssignmentState: REQUEST.IDLE,
  isInitial: true,
  publications: [],
  pastEvents: [],
  futureEvents: [],

  objectTypes: [],
  objectTypesCanLoadMore: true,
  objectTypesStatus: REQUEST.IDLE,
  objectTypesTotal: 0,

  events: [],
  timelinePage: 0,
  timelinePageCount: 0,
  timelineStatus: REQUEST.IDLE,
  timelineStatistics: null,

  publishState: REQUEST.IDLE,
  changeAvailabilityStatus: REQUEST.IDLE,
  showStatusModal: false,
  saveAssignmentState: REQUEST.IDLE,
  addressSearchState: REQUEST.IDLE,
  showWithdrawModal: false,
  withdrawState: REQUEST.IDLE,
  withdrawError: [],
  showSaveModal: false,

  mediaPartnerState: "",
  mediaPartnerChangedId: "",

  aroCreatedRelation: null,

  buildnumbers: [],
  buildnumbersCanLoadMore: true,
  buildnumbersStatus: REQUEST.IDLE,
  buildnumbersTotal: 0
};

export const singleProjectReducer = (
  state: SingleProjectState = INITIAL_STATE,
  action: Action
): SingleProjectState => {
  switch (action.type) {
    case REDUX.PROJECT.SET_PROJECT: {
      const {
        projectAssignment,
        pastEvents,
        futureEvents,
        isInitial,
        objectTypes,
        buildnumbers,
        buildnumbersTotal,
        publications
      } = <ActionType.SingleProject>action;

      return {
        ...INITIAL_STATE,
        projectAssignment,
        projectAssignmentState: !!projectAssignment
          ? REQUEST.SUCCESS
          : REQUEST.PENDING,
        pastEvents,
        futureEvents,
        isInitial,
        objectTypes,
        buildnumbers,
        buildnumbersTotal,
        publications
      };
    }
    case REDUX.PROJECT.UPDATE_SINGLE_PROJECT: {
      const {
        projectAssignment,
        pastEvents,
        futureEvents,
        isInitial,
        objectTypes,
        buildnumbers,
        publications
      } = <ActionType.SingleProject>action;
      return {
        ...state,
        projectAssignment,
        projectAssignmentState: !!projectAssignment
          ? REQUEST.SUCCESS
          : REQUEST.PENDING,
        pastEvents,
        futureEvents,
        isInitial,
        objectTypes,
        buildnumbers,
        publications
      };
    }
    case REDUX.PROJECT.SET_PROJECT_STATUS: {
      const { projectAssignmentState } = <ActionType.ProjectState>action;

      return { ...state, projectAssignmentState };
    }
    case REDUX.COMMON.SET_STATUS: {
      const { storeSectionName, status } = <CommonActionType.Status>action;

      return { ...state };
    }
    case REDUX.PROJECT.UPDATE_PROJECT: {
      const { projectAssignment } = <ActionType.SingleProjectAssignment>action;

      return { ...state, projectAssignment };
    }
    case REDUX.PROJECT.SET_PUBLISH_STATUS: {
      const { publishState } = <ActionType.PublishState>action;

      return { ...state, publishState };
    }
    case REDUX.PROJECT.SET_CHANGE_AVAILABILITY_STATUS: {
      const { changeAvailabilityStatus } = <
        ActionType.ChangeAvailabilityStatus
      >action;

      return { ...state, changeAvailabilityStatus };
    }
    case REDUX.PROJECT.TOGGLE_STATUS_MODAL: {
      const { showStatusModal } = <ActionType.StatusModal>action;

      return { ...state, showStatusModal };
    }
    case REDUX.PROJECT.SET_SAVE_PROJECTS_STATUS: {
      const { saveAssignmentState } = <ActionType.SaveAssignmentState>action;
      return { ...state, saveAssignmentState };
    }
    case REDUX.PROJECT.TOGGLE_WITHDRAW_MODAL: {
      const { showWithdrawModal } = <ActionType.WithdrawModal>action;
      return { ...state, showWithdrawModal };
    }
    case REDUX.PROJECT.SET_WITHDRAW_STATE: {
      const { withdrawState } = <ActionType.WithdrawState>action;
      return { ...state, withdrawState };
    }
    case REDUX.PROJECT.TOGGLE_SAVE_MODAL: {
      const { showSaveModal } = <ActionType.SaveModal>action;
      return { ...state, showSaveModal };
    }
    case REDUX.PROJECT.SET_TIMELINE_EVENTS: {
      const { response } = <ActionType.Events>action;
      const timelinePageCount = Math.round(
        response.totalResults / response.resultCount
      );

      return {
        ...state,
        events: response.results,
        timelineStatistics: response.statistics,
        timelineStatus: REQUEST.IDLE,
        timelinePageCount,
        timelinePage: 1
      };
    }
    case REDUX.PROJECT.APPEND_TIMELINE_EVENTS: {
      const { response } = <ActionType.Events>action;
      const timelinePageCount = Math.round(
        response.totalResults / response.resultCount
      );

      return {
        ...state,
        events: [...state.events, ...response.results],
        timelineStatistics: response.statistics,
        timelineStatus: REQUEST.IDLE,
        timelinePage: state.timelinePage + 1
      };
    }
    case REDUX.PROJECT.SET_TIMELINE_STATUS: {
      const { timelineStatus } = <ActionType.TimelineStatus>action;
      return { ...state, timelineStatus };
    }
    case REDUX.PROJECT.SET_MEDIAPARTNER_STATE: {
      const { mediaPartnerState, mediaPartnerChangedId } = <
        ActionType.MediaPartnerState
      >action;
      return { ...state, mediaPartnerState, mediaPartnerChangedId };
    }
    case REDUX.PROJECT.SET_BUILDNUMBERS: {
      const { buildnumbers, buildnumbersCanLoadMore, buildnumbersTotal } = <
        ActionType.Buildnumbers
      >action;
      return {
        ...state,
        buildnumbers,
        buildnumbersCanLoadMore,
        buildnumbersStatus: REQUEST.SUCCESS,
        buildnumbersTotal
      };
    }
    case REDUX.PROJECT.ADD_BUILDNUMBERS: {
      const {
        buildnumbers: appendables,
        buildnumbersCanLoadMore,
        buildnumbersTotal
      } = <ActionType.Buildnumbers>action;
      let buildnumbers = differenceBy(
        state.buildnumbers,
        appendables,
        (buildnumber) => buildnumber.id
      );

      buildnumbers = [...buildnumbers, ...appendables];

      return {
        ...state,
        buildnumbers,
        buildnumbersCanLoadMore,
        buildnumbersStatus: REQUEST.SUCCESS,
        buildnumbersTotal
      };
    }
    case REDUX.PROJECT.SET_BUILDNUMBERS_STATUS: {
      const { buildnumbersStatus } = <ActionType.BuildnumbersStatus>action;

      return {
        ...state,
        buildnumbersStatus
      };
    }
    case REDUX.PROJECT.SET_OBJECTTYPES: {
      const { objectTypes, objectTypesCanLoadMore, objectTypesTotal } = <
        ActionType.ObjectTypes
      >action;

      return {
        ...state,
        objectTypes,
        objectTypesCanLoadMore,
        objectTypesStatus: REQUEST.SUCCESS,
        objectTypesTotal
      };
    }
    case REDUX.PROJECT.ADD_OBJECTTYPES: {
      const {
        objectTypes: appendables,
        objectTypesCanLoadMore,
        objectTypesTotal
      } = <ActionType.ObjectTypes>action;
      let objectTypes = differenceBy(
        state.buildnumbers,
        appendables,
        (buildnumber) => buildnumber.id
      );

      objectTypes = [...objectTypes, ...appendables];

      return {
        ...state,
        objectTypes,
        objectTypesCanLoadMore,
        objectTypesStatus: REQUEST.SUCCESS,
        objectTypesTotal
      };
    }
    case REDUX.PROJECT.SET_OBJECTTYPES_STATUS: {
      const { objectTypesStatus } = <ActionType.ObjectTypesStatus>action;

      return {
        ...state,
        objectTypesStatus
      };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityType, entityId } = <any>action;
      if (
        entityType === FolderTreeEntityType.ProjectAssignment &&
        !!state.projectAssignment &&
        state.projectAssignment.id === entityId
      ) {
        return {
          ...state,
          projectAssignment: {
            ...state.projectAssignment,
            linkedFolderTree: {
              id
            }
          }
        };
      } else {
        return state;
      }
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
