import { RemindersSearchResponse } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import * as ActionType from "./reminders.types";

const setRemindersStatus = (remindersStatus: string) => ({
  type: REDUX.REMINDERS.SET_REMINDERS_STATUS,
  remindersStatus
});

const setReminders = (payload: ActionType.Reminders) => ({
  type: REDUX.REMINDERS.SET_REMINDERS,
  ...payload
});

const clearReminders = () => ({
  type: REDUX.REMINDERS.CLEAR_REMINDERS
});

const clearReminder = (id: string) => ({
  type: REDUX.REMINDERS.CLEAR_REMINDER,
  id
});

const appendReminders = (result: RemindersSearchResponse, take: number) => ({
  type: REDUX.REMINDERS.APPEND_REMINDERS,
  ...result,
  take
});

const closeRemindersModal = () => ({
  type: REDUX.REMINDERS.CLOSE_REMINDERS_MODAL
});

export const RemindersActions = {
  setRemindersStatus,
  setReminders,
  appendReminders,
  closeRemindersModal,
  clearReminders,
  clearReminder
};
