import { RootEntityType } from "@haywork/api/event-center";
import {
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  Priority,
  RelationSnapShot,
  RelationType,
  Task,
  TaskStatus,
  TaskCategoryOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import ExternalChanges from "@haywork/components/external-changes";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import { REQUEST, TASKROUTES } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import {
  ConfirmComponent,
  ObjectTimestamps,
  ResourceText,
} from "@haywork/modules/shared";
import { TaskContainerProps } from "@haywork/modules/task";
import { Ui } from "@haywork/modules/ui";
import {
  AssignmentUtil,
  ColorUtil,
  FormControlUtil,
  RouteUtil,
  StringUtil,
} from "@haywork/util";
import differenceBy from "lodash-es/differenceBy";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Actions, { TaskAction } from "./actions";
import classNames from "classnames";

const styles = require("./task.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

export interface TaskComponentProps {}
interface State {
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  deleteConfirmVisible: boolean;
  confirmSaveAndClose: boolean;
  selectedLinkedAssignments: LinkedAssignment[];
  selectedLinkedRelations: LinkedRelation[];
  fetchingRelations: boolean;
  fetchingAssignments: boolean;
}
type Props = TaskComponentProps & TaskContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class TaskComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private ref: HTMLDivElement;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onActionClick = this.onActionClick.bind(this);

    // FormControls
    const {
      subject,
      priority,
      linkedAssignments,
      linkedRelations,
      linkedEmployee,
      endDate,
      description,
      useReminder,
    } = this.props.task;

    const categoryId = !!this.props.task.categoryId
      ? this.props.task.categoryId
      : this.props.categories.length > 0
      ? first(this.props.categories).value
      : "";

    // Bindings
    this.categoryColor = this.categoryColor.bind(this);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.onUnmountHandler = this.onUnmountHandler.bind(this);
    this.onDirtyHandler = this.onDirtyHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.markAsDone = this.markAsDone.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.onDeleteConfirmClose = this.onDeleteConfirmClose.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.handleLinkedRelationsChange =
      this.handleLinkedRelationsChange.bind(this);
    this.handleLinkedAssignmentsChange =
      this.handleLinkedAssignmentsChange.bind(this);
    this.renderCategoryOption = this.renderCategoryOption.bind(this);

    this.formControls = {
      subject: {
        value: subject,
        validators: [Validators.required()],
        onChange: (ref) => {
          this.props.setTabTitle(ref.value);
        },
      },
      priority: { value: priority },
      linkedAssignments: {
        value: linkedAssignments,
        onChange: this.handleLinkedAssignmentsChange,
      },
      linkedRelations: {
        value: linkedRelations,
        onChange: this.handleLinkedRelationsChange,
      },
      linkedEmployee: { value: linkedEmployee },
      endDate: { value: endDate },
      useReminder: { value: useReminder },
      categoryId: { value: categoryId },
      description: { value: description },
    };

    this.state = {
      newEntityVisible: false,
      newEntityOptions: null,
      deleteConfirmVisible: false,
      confirmSaveAndClose: false,
      selectedLinkedAssignments: linkedAssignments || [],
      selectedLinkedRelations: linkedRelations || [],
      fetchingAssignments: false,
      fetchingRelations: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.task, "dateTimeModified") !==
        get(this.props.task, "dateTimeModified")
    ) {
      const {
        subject,
        priority,
        linkedAssignments,
        linkedRelations,
        linkedEmployee,
        endDate,
        description,
        useReminder,
      } = this.props.task;

      const categoryId = !!this.props.task.categoryId
        ? this.props.task.categoryId
        : this.props.categories.length > 0
        ? first(this.props.categories).value
        : "";

      this.formRef.update(
        {
          subject,
          priority,
          linkedAssignments,
          linkedRelations,
          linkedEmployee,
          endDate,
          useReminder,
          categoryId,
          description,
        },
        true
      );
    }
  }

  public render() {
    const { categories, employees, task, taskStatus } = this.props;
    const {
      status,
      completedDate,
      isNew,
      subject,
      isActive,
      id,
      linkedCreatedBy,
      linkedModifiedBy,
      dateTimeCreated,
      dateTimeModified,
    } = task;
    const loading = taskStatus === REQUEST.PENDING;

    return (
      <div
        styleName="task"
        ref={(ref) => (this.ref = ref)}
        data-cy="CY-taskDetailParent"
      >
        <PageHeader
          title={subject || "task.pageHeader.emptyTitle"}
          subTitle={status === TaskStatus.Completed ? "taskCompleted" : null}
          subTitleValues={{
            date: intlContext.formatDate(completedDate || new Date(), {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }),
          }}
          actions={
            <>
              <Presence entityId={id} entityType={RootEntityType.Task} />
              <Button
                label="saveAndClose"
                category="success"
                disabled={loading}
                onClick={() => this.setState({ confirmSaveAndClose: true })}
              />
              {!isNew && (
                <Actions
                  task={task}
                  loading={loading}
                  onClick={this.onActionClick}
                />
              )}
            </>
          }
        >
          {!isActive && (
            <div styleName="archived-pill">
              <I18n value="archived" />
            </div>
          )}
        </PageHeader>

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.Task}
          onReloadEntity={this.props.reloadTask}
        />

        <div styleName="task__body">
          {loading && <Ui.Loaders.Fullscreen mask />}
          <div className="container-fluid">
            <Form
              name="task"
              formControls={this.formControls}
              onSubmit={this.onFormSubmitHandler}
              onUnmount={this.onUnmountHandler}
              onDirty={this.onDirtyHandler}
              form={(ref) => (this.formRef = ref)}
              onChange={this.onChangeHandler}
            >
              <div className="row">
                <div className="form__row col-sm-6">
                  <label htmlFor="subject">
                    <ResourceText resourceKey="taskDetailSubjectLabel" />
                  </label>
                  <div className="input__wrapper">
                    <Input.Text
                      name="subject"
                      placeholder="taskDetailSubjectPlaceholder"
                      data-cy="CY-taskSubjectInput"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form__row col-sm-6">
                  <div className="input__wrapper">
                    <Input.Switch
                      name="priority"
                      on={Priority.High}
                      off={Priority.Medium}
                      label="priorityLabel"
                      labelPosition={SwitchLabelPosition.Post}
                      data-cy="CY-taskPrioritySwitch"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form__row">
                    <label htmlFor="linkedAssignments">
                      <ResourceText resourceKey="taskDetailViewLinkedAssignmentsLabel" />
                    </label>
                    <Input.Suggestion
                      name="linkedAssignments"
                      type="assignment"
                      onNavigate={this.props.navigate}
                      fetchingResults={this.state.fetchingAssignments}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="linkedRelations">
                      <ResourceText resourceKey="taskDetailViewLinkedRelationsLabel" />
                    </label>
                    <Input.Suggestion
                      name="linkedRelations"
                      type="relation"
                      relationTypes={[
                        RelationType.ContactCompany,
                        RelationType.ContactPerson,
                      ]}
                      onNavigate={this.props.navigate}
                      onAdd={this.addNewRelation}
                      fetchingResults={this.state.fetchingRelations}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="linkedEmployee">
                      <ResourceText resourceKey="taskDetailViewTaskFor" />
                    </label>
                    <Input.NewSelect
                      name="linkedEmployee"
                      values={employees}
                      displayProp="displayName"
                    />
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column grow1">
                        <label htmlFor="endDate">
                          <ResourceText resourceKey="taskDetailViewEndDate" />
                        </label>
                        <Input.Datepicker
                          name="endDate"
                          placeholder="taskDetailViewEndDatePlaceholder"
                          data-cy="CY-taskDateTimePicker"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column push-label">
                        <Input.Switch
                          name="useReminder"
                          on={true}
                          off={false}
                          label="receiveReminderTask"
                          labelPosition={SwitchLabelPosition.Pre}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form__row">
                    <label htmlFor="categoryId">
                      <ResourceText resourceKey="taskDetailCategory" />
                    </label>
                    <Input.NewSelect
                      name="categoryId"
                      values={categories}
                      valuesProp="value"
                      filterProp="displayName"
                      displayFn={this.renderCategoryOption}
                    />
                  </div>
                  <div className="form__row" styleName="description">
                    <label htmlFor="description">
                      <ResourceText resourceKey="taskDetailDescriptionLabel" />
                    </label>
                    <Input.Textarea
                      name="description"
                      placeholder="simpleTaskDescriptionPlaceholder"
                      data-cy="CY-taskTextAreaInput"
                    />
                  </div>

                  <div styleName="timestamps">
                    <ObjectTimestamps
                      linkedCreatedBy={linkedCreatedBy}
                      linkedModifiedBy={linkedModifiedBy}
                      dateTimeCreated={dateTimeCreated}
                      dateTimeModified={dateTimeModified}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <ConfirmComponent
          visible={this.state.deleteConfirmVisible}
          onClose={this.onDeleteConfirmClose}
          onConfirm={this.onDeleteConfirm}
          titleResourceKey="task.deleteConfirm.title"
          bodyResourceKey="task.deleteConfirm.body"
        />

        <ConfirmOverwrite
          entityType={RootEntityType.Task}
          entityId={id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => {
            this.formRef.submit();
            this.setState({ confirmSaveAndClose: false });
          }}
        />
      </div>
    );
  }

  public componentDidMount() {
    const title =
      this.props.task.subject ||
      intlContext.formatMessage({ id: "newTask", defaultMessage: "" });
    this.props.setTabTitle(title);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TaskComponentProps & TaskContainerProps
  ) {
    if (!nextProps) return;
    if (!this.props.preppedForSave && !!nextProps.preppedForSave) {
      this.props.saveAndCloseTask();
    }

    const { task: newTask } = nextProps;
    const { task: oldTask } = this.props;

    if (
      has(newTask, "linkedRelations") &&
      value(newTask, "linkedRelations", []).length !==
        value(oldTask, "linkedRelations", []).length &&
      !!this.formRef
    ) {
      const { linkedRelations } = newTask;
      this.formRef.update({ linkedRelations });
    }
  }

  private renderCategoryOption(
    category: TaskCategoryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div
        className={classNames("task__category-option", { active, selected })}
      >
        <div
          className="task__category-hint"
          style={{
            backgroundColor: this.categoryColor(category.categoryBackColor),
          }}
        />
        <div
          className="task__category-hint-label"
          dangerouslySetInnerHTML={StringUtil.highlight(
            category.displayName,
            query
          )}
        />
      </div>
    );
  }

  private onFormSubmitHandler(values: FormReturnValue) {
    if (this.props.taskStatus === REQUEST.PENDING) {
      return;
    }

    const task: Task = {
      ...this.props.task,
      ...values,
      minutesBeforeReminder: 0,
    };
    const path = route(TASKROUTES.TASK.URI, { id: task.id });
    this.props.save(task, path);
  }

  private onUnmountHandler(values: FormReturnValue) {
    const updatedTask: Task = { ...this.props.task, ...values };
    const path = route(TASKROUTES.TASK.URI, { id: updatedTask.id });
    this.props.updateCache(updatedTask, path);
  }

  private onDirtyHandler() {
    this.props.setHasChanges();
  }

  private categoryColor(color: string | null): string {
    return !!color ? ColorUtil.hexToRgb(color) : null;
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedRelations } = this.formRef.getValues();
    linkedRelations = linkedRelations || [];

    this.formRef.update({
      linkedRelations: [...linkedRelations, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const editedTask = this.getTaskFromFormValues(formReturnValue);
    const path = route(TASKROUTES.TASK.URI, { id: editedTask.id });
    this.props.updateTask(editedTask, path);
  }

  private getTaskFromFormValues(formReturnValue: FormReturnValue): Task {
    const task: Task = {
      ...this.props.task,
      categoryId: formReturnValue.categoryId,
      description: formReturnValue.description,
      endDate: formReturnValue.endDate,
      linkedAssignments: formReturnValue.linkedAssignments,
      linkedEmployee: formReturnValue.linkedEmployee,
      linkedRelations: formReturnValue.linkedRelations,
      priority: formReturnValue.priority,
      subject: formReturnValue.subject,
    };

    return task;
  }

  private markAsDone() {
    const editedTask = this.getTaskFromFormValues(this.formRef.getValues());
    editedTask.status = TaskStatus.Completed;
    editedTask.isCompleted = true;
    editedTask.completedPercentage = 100;

    const path = route(TASKROUTES.TASK.URI, { id: editedTask.id });
    this.props.save(editedTask, path);
  }

  private markAsInProgress() {
    const editedTask = this.getTaskFromFormValues(this.formRef.getValues());
    editedTask.status = TaskStatus.NotStarted;
    editedTask.isCompleted = false;
    editedTask.completedPercentage = 0;

    const path = route(TASKROUTES.TASK.URI, { id: editedTask.id });
    this.props.save(editedTask, path);
  }

  private onActionClick(action: TaskAction) {
    const { id } = this.props.task;

    switch (action) {
      case TaskAction.Archive: {
        this.props.archive(id);
        break;
      }
      case TaskAction.Complete: {
        this.markAsDone();
        break;
      }
      case TaskAction.UnComplete: {
        this.props
          .toggleTaskStatus(id, TaskStatus.NotStarted, false)
          .then(() => {
            if (!this.props.task.isActive) {
              this.props.unArchive(id).then(() => {
                this.markAsInProgress();
              });
            } else {
              this.markAsInProgress();
            }
          });

        break;
      }
      case TaskAction.Remove: {
        this.setState({ deleteConfirmVisible: true });
        break;
      }
      case TaskAction.UnArchive: {
        this.props.unArchive(id);
        break;
      }
      default: {
        break;
      }
    }
  }

  private onDeleteConfirmClose() {
    this.setState({ deleteConfirmVisible: false });
  }

  private onDeleteConfirm() {
    const { id } = this.props.task;
    this.setState({ deleteConfirmVisible: false });
    this.props.deleteFromDetail(id);
  }

  private async handleLinkedRelationsChange(control: RawFormControl) {
    try {
      this.setState({ fetchingAssignments: true });

      const { selectedLinkedRelations, selectedLinkedAssignments } = this.state;
      const newLinkedRelations = differenceBy(
        control.value as (LinkedRelation | RelationSnapShot)[],
        selectedLinkedRelations,
        (relation) => relation.id
      ).filter((relation) =>
        [RelationType.ContactCompany, RelationType.ContactPerson].includes(
          relation.typeOfRelation
        )
      );
      let linkedAssignments = selectedLinkedAssignments;

      if (!!newLinkedRelations.length) {
        const relationsIds = newLinkedRelations.map((relation) => relation.id);
        const assignments = await this.props.getAssignmentsLinkedToRelations(
          relationsIds
        );

        linkedAssignments = assignments.map(
          AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment
        );
        linkedAssignments = uniqBy(
          [...selectedLinkedAssignments, ...linkedAssignments],
          (assignment) => assignment.id
        );
      }

      this.setState({
        selectedLinkedRelations: control.value || [],
        selectedLinkedAssignments: linkedAssignments,
      });

      return {
        linkedAssignments,
      };
    } finally {
      this.setState({ fetchingAssignments: false });
    }
  }

  private async handleLinkedAssignmentsChange(
    control: RawFormControl,
    get: (name: string) => RawFormControl
  ) {
    try {
      this.setState({ fetchingRelations: true });

      const { selectedLinkedAssignments, selectedLinkedRelations } = this.state;
      const newLinkedAssignments = differenceBy(
        control.value as LinkedAssignment[],
        selectedLinkedAssignments,
        (assignment) => assignment.id
      ).filter((assignment) =>
        [AssignmentType.Object, AssignmentType.Acquisition].includes(
          assignment.typeOfAssignment
        )
      );
      let linkedRelations = selectedLinkedRelations;

      if (!!newLinkedAssignments.length) {
        const objectPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Object
          )
          .map((assignment) => this.props.getLinkedAssignment(assignment.id));
        const acquisitionPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Acquisition
          )
          .map((assignment) => this.props.getLinkedAcquisition(assignment.id));

        const objects = await Promise.all(objectPromises);
        const acquisitions = await Promise.all(acquisitionPromises);

        const objectLinkedRelations = objects.reduce((state, assignment) => {
          [
            ...(assignment.linkedApplicants || []),
            ...(assignment.linkedVendors || []),
            ...(assignment.linkedClients || []),
          ].map((relation) => state.push(relation));

          return state;
        }, [] as LinkedRelation[]);

        const acquisitionLinkedRelations = acquisitions.reduce(
          (state, assignment) => {
            [
              ...(assignment.linkedApplicants || []),
              ...(assignment.linkedVendors || []),
              ...(assignment.linkedClients || []),
            ].map((relation) => state.push(relation));

            return state;
          },
          [] as LinkedRelation[]
        );

        linkedRelations = uniqBy(
          [
            ...selectedLinkedRelations,
            ...objectLinkedRelations,
            ...acquisitionLinkedRelations,
          ],
          (relation) => relation.id
        );
      }

      this.setState({
        selectedLinkedAssignments: control.value || [],
        selectedLinkedRelations: linkedRelations,
      });

      return {
        linkedRelations,
      };
    } finally {
      this.setState({ fetchingRelations: false });
    }
  }
}
