import {
  AcquisitionObjectAssignment,
  AssignmentPhaseOption,
  CertificationOption,
  ComfortQualityOption,
  ConditionTypeOption,
  ConstructionPeriodOption,
  FurnishingOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  GroundOwnershipTypeOption,
  LocationPlaceOption,
  OrientationOption,
  PeculiarityOption,
  RoofMaterialOption,
  RoofTypeOption,
  TypeOfBusinessLawOption,
  TypeOfFloorOption,
  TypeOfWindowFrameOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, EditableActions } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { accessibilityOptions } from "../../selectors";
import {
  AcquistionAssignmentEditObjectResidentialScreen,
  AcquistionAssignmentEditObjectResidentialScreenProps,
} from "./assignments-edit-object-residential";

export interface VVEOption {
  value: string;
  displayName: string;
}
const ASSIGNMENT = {
  VVE_OPTIONS: [
    { NameOfProperty: "isOwnersAssociationChecklistAvailable" },
    { NameOfProperty: "isOwnersAssociationRegisteredKvK" },
    { NameOfProperty: "hasOwnersAssociationYearlyMeeting" },
    { NameOfProperty: "hasOwnersAssociationMultipleYearsMaintenancePlan" },
    { NameOfProperty: "hasOwnersAssociationMaintenanceExpectations" },
    { NameOfProperty: "hasOwnersAssociationBuildingsInsurance" },
    { NameOfProperty: "hasOwnersAssociationPeriodicContribution" },
    { NameOfProperty: "hasOwnersAssociationReserveFund" },
  ],
};

const getVVEOptions = (): VVEOption[] => {
  return ASSIGNMENT.VVE_OPTIONS.map((option) => ({
    value: option.NameOfProperty,
    displayName: intlContext.formatMessage({
      id: option.NameOfProperty,
      defaultMessage: option.NameOfProperty,
    }),
    isSelected: false,
  }));
};

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  acquisitionStatusOptions: any;
  gardenTypeOptions: GardenTypeOption[];
  gardenQualityOptions: GardenQualityOption[];
  orientations: OrientationOption[];
  garageTypeOptions: GarageTypeOption[];
  constructionPeriods: ConstructionPeriodOption[];
  locationPlaces: LocationPlaceOption[];
  groundOwnershipTypeOptions: GroundOwnershipTypeOption[];
  accessibilityOptions: PeculiarityOption[];
  certificationOptions: CertificationOption[];
  vveOptions: VVEOption[];
  comfortQualityOptions: ComfortQualityOption[];
  furnishingOptions: FurnishingOption[];
  typeOfFloorOptions: TypeOfFloorOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  typeOfWindowFrameOptions: TypeOfWindowFrameOption[];
  conditionTypeOptions: ConditionTypeOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  assignmentPhases: AssignmentPhaseOption[];
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditObjectResidentialScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable.currentComponentState;
  const {
    acquisitionStatusOptions,
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
    garageTypeOptions,
    constructionPeriods,
    locationPlaces,
    groundOwnershipTypeOptions,
    certificationOptions,
    comfortQualityOptions,
    furnishingOptions,
    typeOfFloorOptions,
    roofTypeOptions,
    roofMaterialOptions,
    typeOfWindowFrameOptions,
    conditionTypeOptions,
    typeOfBusinessLawOptions,
    assignmentPhases,
  } = state.main.mastertable.kolibri;

  const vveOptions = getVVEOptions();
  return {
    acquisitionObjectAssignment,
    acquisitionStatusOptions,
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
    garageTypeOptions,
    constructionPeriods,
    locationPlaces,
    groundOwnershipTypeOptions,
    accessibilityOptions: accessibilityOptions(state),
    certificationOptions,
    vveOptions,
    comfortQualityOptions,
    furnishingOptions,
    typeOfFloorOptions,
    roofTypeOptions,
    roofMaterialOptions,
    typeOfWindowFrameOptions,
    conditionTypeOptions,
    typeOfBusinessLawOptions,
    assignmentPhases,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditObjectResidentialScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});
export type AcquistionAssignmentEditObjectResidentialScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditObjectResidentialScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditObjectResidentialScreen);
