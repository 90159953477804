import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { RelationSnapShot } from "@haywork/api/kolibri";

const styles = require("./email-query.component.scss");

interface Props {
  suggestion: RelationSnapShot;
  selected: boolean;
  onClick: () => void;
  onMouseOverState: (state: boolean) => void;
}

export const Suggestion = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const { displayName, email } = props.suggestion;

    return (
      <div
        styleName={classNames("suggestion", { selected: props.selected })}
        data-cy="CY-suggestionItem"
        onClick={props.onClick}
        onMouseDown={() => props.onMouseOverState(true)}
        onMouseUp={() => props.onMouseOverState(false)}
      >
        <div styleName="suggestion__email">{email}</div>
        <div styleName="suggestion__name">{displayName}</div>
      </div>
    );
  }
);
