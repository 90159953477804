import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  Form,
  FormControls,
  Input,
  FormReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import {
  ConditionOption,
  EnergyClassOption,
  LocationPlaceOption,
  BuildingTypeOption,
  SearchAssignment,
  SearchAssignmentFacilityOption,
} from "@haywork/api/kolibri";
import { FormControlUtil } from "@haywork/util";

const styles = require("../edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface SearchAssignmentImportantCommercialComponentProps {
  searchAssignment: SearchAssignment;
  conditionOptions: ConditionOption[];
  energyClassOptions: EnergyClassOption[];
  locationPlaces: LocationPlaceOption[];
  buildingTypeOptions: BuildingTypeOption[];
  searchAssignmentFacilityOptions: SearchAssignmentFacilityOption[];
  onChange: (values: FormReturnValue) => void;
}
interface SearchAssignmentImportantCommercialComponentState {
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentImportantCommercialComponent extends React.Component<
  SearchAssignmentImportantCommercialComponentProps,
  SearchAssignmentImportantCommercialComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);

    this.formControls = {
      constructionType: {
        value: value(this.props.searchAssignment, "constructionType"),
      },
      constructionYearBegin: {
        value: value(this.props.searchAssignment, "constructionYearBegin"),
      },
      constructionYearEnd: {
        value: value(this.props.searchAssignment, "constructionYearEnd"),
      },
      minBuildableArea: {
        value: value(this.props.searchAssignment, "minBuildableArea"),
      },
      maxBuildableArea: {
        value: value(this.props.searchAssignment, "maxBuildableArea"),
      },
      minParkingCapacity: {
        value: value(this.props.searchAssignment, "minParkingCapacity"),
      },
      maxParkingCapacity: {
        value: value(this.props.searchAssignment, "maxParkingCapacity"),
      },
      minEnergyClass: {
        value: value(this.props.searchAssignment, "minEnergyClass"),
      },
      maxEnergyClass: {
        value: value(this.props.searchAssignment, "maxEnergyClass"),
      },
      locationPlaces: {
        value: value(this.props.searchAssignment, "locationPlaces", []),
      },
    };
  }

  public render() {
    return (
      <Form
        name="important-commercial"
        formControls={this.formControls}
        onChange={this.props.onChange}
      >
        <div styleName="important">
          <div styleName="important__inside">
            {/* Building type */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="constructionType">
                <ResourceText resourceKey="constructionType" />
              </label>
              <Input.RadioGroup
                name="constructionType"
                asButtonList
                canUnselect
              >
                {this.props.buildingTypeOptions.map(
                  (buildingTypeOption, idx) => (
                    <Input.Radio
                      value={buildingTypeOption.value}
                      label={buildingTypeOption.displayName}
                      key={idx}
                    />
                  )
                )}
              </Input.RadioGroup>
            </div>

            {/* Construction year */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="constructionYearBegin">
                <ResourceText resourceKey="constructionYear" />
              </label>
              <div styleName="min-max">
                <Input.Number name="constructionYearBegin" />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="constructionYearEnd" />
              </div>
            </div>

            {/* Parcel surface */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minBuildableArea">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div styleName="min-max">
                <div className="input__helper">
                  <Input.Number name="minBuildableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <div className="input__helper">
                  <Input.Number name="maxBuildableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>

            {/* Number of parking spaces */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minParkingCapacity">
                <ResourceText resourceKey="parkingSpaces" />
              </label>
              <div styleName="min-max">
                <Input.Number name="minParkingCapacity" max={100} />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="maxParkingCapacity" max={100} />
              </div>
            </div>

            {/* Energyclass */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minEnergyClass">
                <ResourceText resourceKey="energyClass" />
              </label>
              <div styleName="min-max">
                <Input.NewSelect
                  name="minEnergyClass"
                  addEmptyOption
                  emptyOptionLabel="noChoice"
                  values={this.props.energyClassOptions}
                  displayProp="displayName"
                  valuesProp="value"
                />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.NewSelect
                  name="maxEnergyClass"
                  addEmptyOption
                  emptyOptionLabel="noChoice"
                  values={this.props.energyClassOptions}
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>

            {/* Location places */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="locationPlaces">
                <ResourceText resourceKey="locationPlaces" />
              </label>
              {/* TODO replace asap */}
              <Input.QueryLegacy
                name="locationPlaces"
                values={this.props.locationPlaces}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                asSelect
                multiple
                isLoading={this.state.loading}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
