import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import {
  DynamicDocumentsInfoContainer,
  DynamicDocumentsDownloadContainer,
  DynamicDocumentContainer,
  DynamicDocumentsListOfGoodsContainer,
} from "@haywork/modules/dynamic-documents";

const route = RouteUtil.mapStaticRouteValues;

export const DynamicDocumentsDetailRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={DYNAMICDOCUMENTROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(DYNAMICDOCUMENTROUTES.INFO.URI, { id })} />
        )}
      />
      <Route
        exact
        path={DYNAMICDOCUMENTROUTES.INFO.URI}
        component={DynamicDocumentsInfoContainer}
      />
      <Route
        path={DYNAMICDOCUMENTROUTES.DOCUMENT.URI}
        component={DynamicDocumentContainer}
      />
      <Route
        path={DYNAMICDOCUMENTROUTES.LIST_OF_GOODS.URI}
        component={DynamicDocumentsListOfGoodsContainer}
      />
      <Route
        exact
        path={DYNAMICDOCUMENTROUTES.DOWNLOAD.URI}
        component={DynamicDocumentsDownloadContainer}
      />
    </Switch>
  );
};
