import {
  AssignmentSnapShot,
  CommunicationLog,
  RelationSnapShot,
  RelationType
} from "@haywork/api/kolibri";
import {
  AssignmentThunks,
  CommunicationLogThunk,
  EmailMessageThunks,
  MapDispatchToProps,
  RelationThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { SaveModalComponent, SaveModalComponentProps } from "./save-modal";

type StateProps = {};
type DispatchProps = {
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    subject: string,
    communicationLog?: CommunicationLog
  ) => Promise<CommunicationLog>;
  navigate: (path: string) => RouterAction;
  getRelationsWithMatchingEmailAddress: (
    emailAddresses: string[]
  ) => Promise<RelationSnapShot[]>;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  defineNewCommunicationLog: () => Promise<CommunicationLog>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SaveModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SaveModalComponentProps
> = (dispatch) => ({
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    subject: string,
    communicationLog?: CommunicationLog
  ) =>
    dispatch(
      EmailMessageThunks.saveCommunicationLog(
        linkedRelations,
        linkedAssignments,
        message,
        communicationLog,
        subject,
        true
      )
    ),
  navigate: (path: string) => dispatch(push(path)),
  getRelationsWithMatchingEmailAddress: (emailAddresses: string[]) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(emailAddresses, [
        RelationType.ContactCompany,
        RelationType.ContactPerson
      ])
    ),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  defineNewCommunicationLog: () =>
    dispatch(CommunicationLogThunk.defineNewCommunicationLog())
});

export type SaveModalContainerProps = StateProps & DispatchProps;
export const SaveModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveModalComponent);
