import * as React from "react";
import { FC, memo, useMemo } from "react";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";
import * as CssModules from "react-css-modules";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";

const styles = require("./style.scss");

export enum DecisionAction {
  UseLocalData = "UseLocalData",
  FetchExternalData = "FetchExternalData",
  ReturnToDashboard = "ReturnToDashboard",
}

type Props = {
  visible: boolean;
  dateTimeModified: Date | null;
  modifiedBy: string | null;
  showReturnToDashboard: boolean;
  onAction: (action: DecisionAction) => void;
  onClose: () => void;
};

const DecisionModalComponent: FC<Props> = memo(
  CssModules(styles, { allowMultiple: true })(
    ({
      visible,
      onAction,
      onClose,
      dateTimeModified,
      modifiedBy,
      showReturnToDashboard,
    }) => {
      const body = useMemo(() => {
        if (!dateTimeModified && !modifiedBy) return null;
        const refDate = moment(dateTimeModified);
        const asTime = moment().isSame(refDate, "day");

        switch (true) {
          case !!modifiedBy && asTime: {
            return (
              <I18n
                value="externalChanges.modalBody.full.asTime"
                values={{ time: refDate.format("HH:mm"), modifiedBy }}
                asHtml
              />
            );
          }
          case !modifiedBy && asTime: {
            return (
              <I18n
                value="externalChanges.modalBody.date.asTime"
                values={{ time: refDate.format("HH:mm"), modifiedBy }}
                asHtml
              />
            );
          }
          case !!modifiedBy && !asTime: {
            return (
              <I18n
                value="externalChanges.modalBody.full"
                values={{
                  date: refDate.format("dd DD-MM"),
                  time: refDate.format("HH:mm"),
                  modifiedBy,
                }}
                asHtml
              />
            );
          }
          case !modifiedBy && !asTime: {
            return (
              <I18n
                value="externalChanges.modalBody.date"
                values={{
                  date: refDate.format("dd DD-MM"),
                  time: refDate.format("HH:mm"),
                  modifiedBy,
                }}
                asHtml
              />
            );
          }
          default: {
            return null;
          }
        }
      }, [dateTimeModified, modifiedBy]);

      return (
        <Modal visible={visible} onClose={onClose}>
          <ModalHeader title="externalChanges.modal.header" close />
          <ModalBody>
            <div styleName="modal__body">{body}</div>
            <div styleName="modal__actions">
              <div
                styleName={classNames("modal__action", "external")}
                onClick={() => onAction(DecisionAction.FetchExternalData)}
              >
                <div styleName="action__icon">
                  <Icon
                    name="arrow-to-left"
                    color={Colors.Secondary}
                    size={20}
                    containIn={24}
                    regular
                  />
                </div>
                <I18n
                  value="externalChanges.modal.action.FetchExternalData"
                  values={{ modifiedBy }}
                />
              </div>
              <div
                styleName={classNames("modal__action", "local")}
                onClick={() => onAction(DecisionAction.UseLocalData)}
              >
                <div styleName="action__icon">
                  <Icon
                    name="arrow-from-left"
                    color={Colors.Primary}
                    size={20}
                    containIn={24}
                    regular
                  />
                </div>
                <I18n
                  value="externalChanges.modal.action.UseLocalData"
                  values={{ modifiedBy }}
                />
              </div>
              {showReturnToDashboard && (
                <div
                  styleName={classNames("modal__action", "back")}
                  onClick={() => onAction(DecisionAction.ReturnToDashboard)}
                >
                  <div styleName="action__icon">
                    <Icon
                      name="arrow-from-right"
                      color={Colors.Success}
                      size={20}
                      containIn={24}
                      regular
                    />
                  </div>
                  <I18n value="externalChanges.modal.action.ReturnToDashboard" />
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      );
    }
  )
);

export default DecisionModalComponent;
