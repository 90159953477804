import { LinkedRelation } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { AppState, LinkedAssignment } from "@haywork/stores";
import * as React from "react";
import { FC, memo } from "react";
import { connect } from "react-redux";

interface MailToComponentProps {
  email: string;
  label?: string;
  linkedRelations?: LinkedRelation[];
  linkedAssigmnents?: LinkedAssignment[];
  onClick?: () => void;
}
interface StateProps {
  canSendEmail: boolean;
}
interface DispatchProps {
  createNewEmail: (
    email: string,
    linkedRelations?: LinkedRelation[],
    linkedAssigmnents?: LinkedAssignment[]
  ) => void;
}
type Props = MailToComponentProps & StateProps & DispatchProps;

const mapStateToProps = <StateProps, MailToComponentProps>(state: AppState) => {
  return {
    canSendEmail: canSendEmail(state)
  };
};

const mapDispatchToProps = <DispatchProps, MailToComponentProps>(
  dispatch: Dispatch<any>
) => ({
  createNewEmail: (email: string) =>
    dispatch(EmailThunk.Main.createNewEmail(!email ? [] : [email]))
});

export const MailToComponent: FC<Props> = memo((props) => {
  const label = props.label || props.email;

  if (!!props.canSendEmail) {
    return (
      <span
        className="as-link"
        onClick={(event) => {
          if (props.onClick) {
            props.onClick();
          }
          event.stopPropagation();
          props.createNewEmail(
            props.email,
            props.linkedRelations,
            props.linkedAssigmnents
          );
        }}
        data-cy={props["data-cy"]}
      >
        {label}
      </span>
    );
  }

  return (
    <a
      href={`mailto:${props.email}`}
      onClick={(event) => {
        if (props.onClick) props.onClick();
        event.stopPropagation();
      }}
      data-cy={props["data-cy"]}
    >
      {label}
    </a>
  );
});

export const MailTo = connect<StateProps, DispatchProps, MailToComponentProps>(
  mapStateToProps,
  mapDispatchToProps
)(MailToComponent);
