import { intlContext } from "@haywork/app";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";

interface EmailInputComponentProps {
  icon?: string;
  large?: boolean;
  placeholder?: string;
  translate?: string;
}
interface EmailInputComponentState {
  value: string;
}

export class EmailComponent extends React.Component<
  EmailInputComponentProps & InputComponentProps,
  EmailInputComponentState
> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);

    this.state = {
      value:
        [null, undefined].indexOf(this.props.value) === -1
          ? this.props.value
          : "",
    };
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const textInputStyle = classNames("input__email", {
      large: this.props.large,
      "has-icon": this.props.icon,
    });

    return (
      <div className={textInputStyle}>
        <input
          type="email"
          name={this.props.name}
          id={this.props.name}
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          disabled={this.props.disabled}
          onKeyDown={this.onKeyDownHandler}
          onFocus={this.props.onFocus}
          ref={this.bindRef}
          data-cy={this.props["data-cy"]}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: EmailInputComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }

    const value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    if (this.state.value !== value) {
      this.setState({ value });
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (!!this.props.fireAllChanges) {
      this.props.onChange(value);
    } else {
      this.setState({ value });
    }
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    const { value } = this.state;
    this.props.onChange(value);
    this.props.onBlur(event);
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
    }
  }
}
