export * from "./detail/detail.component";
export * from "./detail-dossier/detail-dossier.component";
export * from "./detail-general/detail-general.component";
export * from "./detail-network/detail-network.component";
export * from "./edit/edit.component";
export * from "./edit-type/edit-type.component";
export * from "./edit-general/edit-general.component";
export * from "./edit-specs/edit-specs.component";
export * from "./edit-marketing/edit-marketing.component";
export * from "./marketing-floorplans/marketing-floorplans.component";
export * from "./marketing-photos/marketing-photos.component";
export * from "./marketing-text/marketing-text.component";
export * from "./marketing-videos/marketing-videos.component";
export * from "./publish/publish.component";
export * from "./detail-buildnumbers/detail-buildnumbers.component";
