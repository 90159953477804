import {
  AppraisalCertificationOption,
  CivilStateOption,
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressTypeOption,
  Employee,
  Gender,
  IdentificationTypeOption,
  Language,
  LanguageSpokenOption,
  PhoneNumberTypeOption,
} from "@haywork/api/kolibri";
import { EMPLOYEEROUTES } from "@haywork/constants";
import { Dispatch, EmployeeThunk } from "@haywork/middleware";
import {
  EmployeeEditComponent,
  EmployeeEditComponentProps,
} from "@haywork/modules/settings/modules/employees/components/edit";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect } from "react-redux";

interface StateProps {
  selectedEmployee: Employee;
  employeeTitleSuggestions: ContactPersonTitleSuggestionOption[];
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
  employeeAddressSearchState: string;
  civilStateOptions: CivilStateOption[];
  identificationTypeOptions: IdentificationTypeOption[];
  languages: LanguageSpokenOption[];
  appraisalCertifications: AppraisalCertificationOption[];
  saveEmployeeState: string;
  path: string;
  enabledLanguages: Language[];
  defaultLanguage: Language;
}

interface DispatchProps {
  removeAddress: (isPostalAddress: boolean, id: string) => void;
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    employeeId: string
  ) => void;
  togglePostalAddress: (id: string) => void;
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) => void;
  saveEmployee: (employee: Employee, path: string) => void;
  updateEmployee: (componentState: Employee, path: string) => void;
}

const mapStateToProps = <StateProps, EmployeeEditComponentProps>(
  state: AppState
) => ({
  selectedEmployee: state.editable.currentComponentState,
  employeeTitleSuggestions: reduceEmployeeTitleSuggestionOptions(
    state.main.mastertable.kolibri.contactPersonTitleSuggestions,
    state.editable.currentComponentState.gender
  ),
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  countries: state.main.mastertable.kolibri.countries,
  employeeAddressSearchState: state.employee.employeeAddressSearchState,
  civilStateOptions: state.main.mastertable.kolibri.civilStates,
  identificationTypeOptions: state.main.mastertable.kolibri.identificationTypes,
  languages: state.main.mastertable.kolibri.languages,
  appraisalCertifications:
    state.main.mastertable.kolibri.appraisalCertifications,
  saveEmployeeState: state.employee.employeeSaveState,
  path: RouteUtil.mapStaticRouteValues(EMPLOYEEROUTES.EMPLOYEE.URI, {
    id: state.employee.selectedEmployee.id,
  }),
  enabledLanguages: state.company.settings.enabledLanguages,
  defaultLanguage: state.company.settings.defaultLanguage,
});

const mapDispatchToProps = <DispatchProps, EmployeeEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  removeAddress: (isPostalAddress: boolean, id: string) =>
    dispatch(EmployeeThunk.removeEmployeeAddress(isPostalAddress, id)),
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    employeeId: string
  ) =>
    dispatch(
      EmployeeThunk.addManualAddress(newAddress, isPostalAddress, employeeId)
    ),
  togglePostalAddress: (id: string) =>
    dispatch(EmployeeThunk.toggleEmployeePostalAddress(id)),
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) =>
    dispatch(EmployeeThunk.searchAddress(value, country, isPostalAddress, id)),
  saveEmployee: (employee: Employee, path: string) =>
    dispatch(EmployeeThunk.saveEmployee(employee, path)),
  updateEmployee: (componentState: Employee, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type EmployeeEditContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const EmployeeEditContainer = injectIntl(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EmployeeEditComponent)
);

const reduceEmployeeTitleSuggestionOptions = (
  titles: ContactPersonTitleSuggestionOption[],
  gender: Gender
): ContactPersonTitleSuggestionOption[] => {
  switch (gender) {
    case Gender.Female:
      return titles.filter((option) => option.usedForFemales === true);
    case Gender.Male:
      return titles.filter((option) => option.usedForMales === true);
    case Gender.Other:
      return titles.filter((option) => option.usedForUnknownGender === true);
    default:
      return titles;
  }
};
