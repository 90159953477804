import {
  AvailabilityStatus,
  ObjectAssignment,
  PriceHistory,
  RealEstateGroup,
  SaleCondition,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { WithIntlProps, injectIntl } from "react-intl";

const styles = require("./widgets.component.scss");

type AssignmentDetailGeneralWidgetsPriceComponentProps = {
  assignment: ObjectAssignment;
};
type Props = AssignmentDetailGeneralWidgetsPriceComponentProps &
  WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class AssignmentDetailGeneralWidgetsPrice extends React.Component<Props> {
  public render() {
    const { forSale, saleOffer, rentOffer, availabilityStatus } =
      this.props.assignment;
    const history = forSale
      ? saleOffer
        ? saleOffer.priceHistory
        : null
      : rentOffer
      ? rentOffer.priceHistory
      : null;

    if (!history) return null;
    if (!this.shouldBeVisible(history, availabilityStatus)) return null;

    const items = history.slice(0, 4);

    return (
      <div styleName="widget price-widget">
        <h2 styleName="title">
          <ResourceText resourceKey="price" />
        </h2>
        {items.map((item, idx) => this.renderPriceSection(item, forSale, idx))}

        <span className="hidden-xs">
          <NavLink
            to={RouteUtil.mapStaticRouteValues(
              ASSIGNMENTROUTES.EDIT_FINANCIAL.URI,
              { id: this.props.assignment.id }
            )}
          >
            <ResourceText resourceKey="changeCurrentPrice" />
          </NavLink>
        </span>
      </div>
    );
  }

  private shouldBeVisible(
    priceHistory: PriceHistory[],
    availabilityStatus: AvailabilityStatus
  ): boolean {
    if (!priceHistory || priceHistory.length === 0) return false;

    switch (availabilityStatus) {
      case AvailabilityStatus.Available:
      case AvailabilityStatus.UnderBid:
      case AvailabilityStatus.UnderOption:
      case undefined:
        return true;
      default:
        return false;
    }
  }

  private renderPriceSection(
    priceHistory: PriceHistory,
    isForSale: boolean,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const isCurrent = idx === 0;
    const priceHistoryStyle = classNames(
      "price-history",
      isCurrent ? "current" : "old",
      {
        up: priceHistory.newPrice > priceHistory.oldPrice,
        down: priceHistory.newPrice <= priceHistory.oldPrice,
      }
    );
    const iconStyle = classNames("fal fa-fw", {
      "fa-euro-sign": isCurrent,
      "fa-arrow-up":
        !isCurrent && priceHistory.newPrice > priceHistory.oldPrice,
      "fa-arrow-down":
        !isCurrent && priceHistory.newPrice <= priceHistory.oldPrice,
    });

    return (
      <div styleName={priceHistoryStyle} key={idx}>
        <div styleName="icon">
          <i className={iconStyle} />
        </div>
        <div styleName="label" data-cy="CY-priceSection">
          {this.renderPriceString(priceHistory, isCurrent, isForSale)}
        </div>
      </div>
    );
  }

  private renderPriceString(
    priceHistory: PriceHistory,
    isCurrent: boolean,
    isForSale: boolean
  ): React.ReactElement<HTMLSpanElement> {
    const { intl, assignment } = this.props;
    const { saleOffer } = assignment;
    const price = intl.formatNumber(priceHistory.newPrice, {
      style: "currency",
      currency: "EUR",
    });
    const date = intl.formatDate(new Date(priceHistory.dateTimeModified), {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    if (!!isForSale) {
      const saleCondition = saleOffer.saleCondition
        ? saleOffer.saleCondition.toString()
        : SaleCondition.CostsBuyer.toString();
      const priceType = intl.formatMessage({
        id: `saleConditions.${saleCondition}`,
        defaultMessage: `saleConditions.${saleCondition}`,
      });
      const resourceKey = isCurrent ? "salePriceIs" : "salePriceWas";
      const resourceValues = isCurrent ? { price, priceType } : { price, date };

      return (
        <ResourceText
          resourceKey={resourceKey}
          values={resourceValues}
          asHtml
        />
      );
    } else {
      if (
        this.props.assignment.realEstateGroup === RealEstateGroup.Agricultural
      ) {
        const resourceKey = isCurrent ? "leasePriceIs" : "leasePriceWas";
        const resourceValues = isCurrent ? { price } : { price, date };

        return (
          <ResourceText
            resourceKey={resourceKey}
            values={resourceValues}
            asHtml
          />
        );
      } else {
        const resourceKey = isCurrent ? "rentPriceIs" : "rentPriceWas";
        const resourceValues = isCurrent ? { price } : { price, date };

        return (
          <ResourceText
            resourceKey={resourceKey}
            values={resourceValues}
            asHtml
          />
        );
      }
    }
  }
}

export const AssignmentDetailGeneralWidgetsPriceComponent = injectIntl(
  AssignmentDetailGeneralWidgetsPrice
);
