import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import first from "lodash-es/first";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import {
  AgendaItemCategoryStatistics,
  AgendaItemCategorySnapShot,
} from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";

const styles = require("./delete-modal.component.scss");

export interface Props {
  visible: boolean;
  statistics: AgendaItemCategoryStatistics;
  category: AgendaItemCategorySnapShot;
  agendaItemCategories: AgendaItemCategorySnapShot[];
  onClose: () => void;
  onDelete: (
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo: AgendaItemCategorySnapShot
  ) => void;
}

interface State {
  hasAgendaItems: boolean;
  category: string;
  agendaItemCategories: AgendaItemCategorySnapShot[];
}

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerCategoryDeleteModalComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      hasAgendaItems: false,
      category: null,
      agendaItemCategories: this.props.agendaItemCategories,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (get(nextProps, "category.id") !== get(this.props, "category.id")) {
      const count = nextProps.statistics.counts.find(
        (cnt) => cnt.agendaItemCategoryId === get(nextProps, "category.id")
      );

      const hasAgendaItems = !!count && get(count, "totalCount", 0) > 0;
      const agendaItemCategories = nextProps.agendaItemCategories.filter(
        (category) => category.id !== get(nextProps, "category.id")
      );
      const category = hasAgendaItems ? first(agendaItemCategories).id : null;

      this.setState({ hasAgendaItems, category, agendaItemCategories });
    }
  }

  public render() {
    const categoryName = get(this.props, "category.displayName", "");
    const categoryResourceKey = this.state.hasAgendaItems
      ? "deleteCategoryWithItems"
      : "areYouSureYouWantToDeleteAgendaCategory";
    const deleteButtonKey = this.state.hasAgendaItems
      ? "deleteAndMoveCategory"
      : "deleteCategory";

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="deleteAgendaCategory" close />
        <ModalBody>
          <div styleName="body">
            <ResourceText
              resourceKey={categoryResourceKey}
              values={{ categoryName }}
              asHtml
            />
          </div>
          {this.state.hasAgendaItems && (
            <Input.NewSelect
              name="category"
              values={this.props.agendaItemCategories}
              value={this.state.category}
              displayProp="displayName"
              valuesProp="id"
              onChange={this.onChangeHandler}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div
            className="btn btn-danger"
            onClick={this.onDelete}
            data-cy="CY-deleteCategoryButton"
          >
            <ResourceText resourceKey={deleteButtonKey} />
          </div>
          <div className="btn btn-default" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private onDelete() {
    const categoryToMoveItemsTo = this.props.agendaItemCategories.find(
      (category) => category.id === this.state.category
    );
    this.props.onDelete(this.props.category, categoryToMoveItemsTo);
  }

  private onChangeHandler(category: string) {
    this.setState({ category });
  }
}
