import { FolderTree } from "@haywork/api/kolibri";
import { File } from "@haywork/api/mail";
import { DossierThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SaveFilesToDossierModalComponent,
  SaveFilesToDossierModalComponentProps
} from "./save-files-to-dossier-modal";

type StateProps = {};
type DispatchProps = {
  saveUrisToDossier: (
    files: File[],
    folderTree: FolderTree,
    parentId: string
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SaveFilesToDossierModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SaveFilesToDossierModalComponentProps
> = (dispatch) => ({
  saveUrisToDossier: (files: File[], folderTree: FolderTree, path: string) =>
    dispatch(DossierThunks.saveUrisToDossier(files, folderTree, path))
});

export type SaveFilesToDossierModalContainerProps = StateProps & DispatchProps;
export const SaveFilesToDossierModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveFilesToDossierModalComponent);
