import { CountryOption, SearchAssignment } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import { SearchAssignmentEditWhereComponent } from "@haywork/modules/search-assignment";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";
import { shouldWarnAboutCollegialListings } from "./selectors";

export interface Radius {
  radius: number;
  displayName: string;
}

interface StateProps {
  searchAssignment: SearchAssignment;
  countries: CountryOption[];
  addressSearchState: string;
  radiusOptions: Radius[];
  amountOfLocations: number;
  addressSearchStateLocationId: number;
  path: string;
  countryIso2: string;
  shouldWarnAboutCollegialListings: boolean;
}
interface DispatchProps {
  searchAddress: (value: string, country: string, locationId: number) => void;
  createNewSearchLocation: (id: string) => void;
  removeSearchLocation: (id: string, searchAssignmentId: string) => void;
  updateSearchAssignment: (
    componentState: SearchAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, AssignmentEditClientComponentProps>(
  state: AppState
) => {
  const searchAssignment: SearchAssignment =
    state.editable.currentComponentState;

  return {
    searchAssignment,
    countries: state.main.mastertable.kolibri.countries,
    addressSearchState: state.searchAssignment.single.addressSearchState,
    radiusOptions: getRadiusOptions(),
    amountOfLocations: (searchAssignment.locations || []).length,
    addressSearchStateLocationId:
      state.searchAssignment.single.addressSearchStateLocationId,
    path: RouteUtil.mapStaticRouteValues(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
      id: searchAssignment.id,
    }),
    countryIso2: "NL",
    shouldWarnAboutCollegialListings: shouldWarnAboutCollegialListings(state),
  };
};

const getRadiusOptions = (): Radius[] => {
  const options: Radius[] = [
    { radius: 0, displayName: "+ 0km" },
    { radius: 1, displayName: "+ 1km" },
    { radius: 2, displayName: "+ 2km" },
    { radius: 5, displayName: "+ 5km" },
    { radius: 10, displayName: "+ 10km" },
    { radius: 15, displayName: "+ 15km" },
  ];

  return options;
};

const mapDispatchToProps = <DispatchProps, AssignmentEditClientComponentProps>(
  dispatch: Dispatch<any>
) => ({
  searchAddress: (value: string, country: string, locationId: number) =>
    dispatch(SearchAssignmentThunks.searchAddress(value, country, locationId)),
  createNewSearchLocation: (id: string) =>
    dispatch(SearchAssignmentThunks.createNewSearchLocation(id)),
  removeSearchLocation: (id: string, searchAssignmentId: string) =>
    dispatch(
      SearchAssignmentThunks.removeSearchLocation(id, searchAssignmentId)
    ),
  updateSearchAssignment: (componentState: SearchAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type SearchAssignmentEditWhereContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentEditWhereContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentEditWhereComponent);
