import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";

const realEstatePropertySelector = (state: AppState) =>
  state.mls.single.realEstateResponse?.realEstateProperty;
const cultureSelector = (state: AppState) => state.main.culture;

export const displayName = createSelector(
  realEstatePropertySelector,
  cultureSelector,
  (realEstateProperty, culture) => {
    const streetnameArray =
      realEstateProperty?.location?.address?.streetname || [];
    const cityNameArray = realEstateProperty?.location?.address?.cityName || [];

    const houseNumber = realEstateProperty?.location?.address?.houseNumber;
    const houseNumberPostfix =
      realEstateProperty?.location?.address?.houseNumberPostfix;
    const postalCode = realEstateProperty?.location?.address?.postalCode;
    const streetname = streetnameArray.find((item) => item.language === culture)
      ?.value;
    const cityName = cityNameArray.find((item) => item.language === culture)
      ?.value;

    const adressLine1 = [streetname, houseNumber, houseNumberPostfix]
      .filter((d) => !!d)
      .join(" ");
    const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

    return [adressLine1, adressLine2].filter((d) => !!d).join(", ");
  }
);
