import { AssignmentType, Message, RelationType } from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES
} from "@haywork/constants";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AsyncUtil, RouteUtil } from "@haywork/util";
import has from "lodash-es/has";
import noop from "lodash-es/noop";
import * as nl2br from "nl2br";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ContactMeModalContainerProps } from "./contact-me-modal.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ContactMeModalComponentProps {
  visible: boolean;
  messageId: string;
  onClose: () => void;
}
interface State {
  loading: boolean;
  message: Message | null;
}
type Props = ContactMeModalComponentProps & ContactMeModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ContactMeModalComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: null
    };

    this.onClose = this.onClose.bind(this);
    this.getMessage = this.getMessage.bind(this);
    this.renderObjectData = this.renderObjectData.bind(this);
    this.renderRelationData = this.renderRelationData.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.props.visible &&
      !!this.props.messageId &&
      !this.state.loading &&
      !this.state.message
    ) {
      this.getMessage();
    }
  }

  public render() {
    return (
      <Modal visible={this.props.visible} onClose={this.onClose}>
        <ModalHeader close={true} title="contactMeModal.title" />
        <ModalBody>
          {!!this.state.loading ? (
            <Ui.Loaders.List />
          ) : !!this.state.message ? (
            <>
              {this.renderObjectData()}
              {this.renderRelationData()}
              {!!this.state.message.content && (
                <div styleName="row">
                  <h2>
                    <ResourceText resourceKey="contactMeModal.messageTitle" />
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nl2br(this.state.message.content)
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <div>
              <ResourceText resourceKey="contactMeModal.emptyState" />
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }

  private async getMessage() {
    if (this.state.loading) return;
    this.setState({ loading: true });

    const { messageId } = this.props;

    try {
      const message = await this.props.getMessage(messageId);
      this.setState({ message });
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }

  private async onClose() {
    this.props.onClose();
    await AsyncUtil.wait(500);
    this.setState({ message: null });
  }

  private renderObjectData() {
    if (!has(this.state.message, "linkedAssignment.displayName")) return null;
    const {
      displayName,
      id,
      typeOfAssignment
    } = this.state.message.linkedAssignment;
    let path = "";

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.AcquisitionObject: {
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.Object: {
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.Project: {
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.ObjectType: {
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      }
      default:
        break;
    }

    return (
      <div styleName="row">
        <h2>
          <ResourceText resourceKey="contactMeModal.objectTitle" />
        </h2>
        <span
          className="as-link"
          onClick={() => (!!path ? this.navigate(path) : noop())}
        >
          {displayName}
        </span>
      </div>
    );
  }

  private renderRelationData() {
    if (!this.state.message.linkedRelation && !this.state.message.userData)
      return null;

    if (!!this.state.message.linkedRelation) {
      const {
        displayName,
        phoneMobile,
        phone,
        phoneWork,
        email,
        typeOfRelation,
        id
      } = this.state.message.linkedRelation;

      let path = "";
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
          break;
        }
        case RelationType.ContactPerson: {
          path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
          break;
        }
        case RelationType.Employee: {
          path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
          break;
        }
        case RelationType.Office: {
          path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
          break;
        }
        default:
          break;
      }

      return (
        <div styleName="row">
          <h2>
            <ResourceText resourceKey="contactMeModal.relationTitle" />
          </h2>
          {!!displayName && (
            <div
              styleName="inner-row name"
              onClick={() => (!!path ? this.navigate(path) : noop())}
            >
              {displayName}
            </div>
          )}
          {!!phoneMobile && (
            <div styleName="inner-row">
              <i className="fal fa-mobile-alt" />
              <a href={`tel:${phoneMobile}`}>{phoneMobile}</a>
            </div>
          )}
          {!!phone && (
            <div styleName="inner-row">
              <i className="fal fa-phone" />
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
          {!!phoneWork && (
            <div styleName="inner-row">
              <i className="fal fa-phone-office" />
              <a href={`tel:${phoneWork}`}>{phoneWork}</a>
            </div>
          )}
          {!!email && (
            <div styleName="inner-row">
              <i className="fal fa-envelope" />
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
        </div>
      );
    }

    if (!!this.state.message.userData) {
      const {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        mobilePhoneNumber
      } = this.state.message.userData;
      const displayName = [firstName, lastName]
        .filter((name) => !!name)
        .join(" ");

      return (
        <div styleName="row">
          <h2>
            <ResourceText resourceKey="contactMeModal.relationTitle" />
          </h2>
          {!!displayName && <div styleName="inner-row name">{displayName}</div>}
          {!!mobilePhoneNumber && (
            <div styleName="inner-row">
              <i className="fal fa-mobile-alt" />
              <a href={`tel:${mobilePhoneNumber}`}>{mobilePhoneNumber}</a>
            </div>
          )}
          {!!phoneNumber && (
            <div styleName="inner-row">
              <i className="fal fa-phone" />
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          )}
          {!!emailAddress && (
            <div styleName="inner-row">
              <i className="fal fa-envelope" />
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </div>
          )}
        </div>
      );
    }
  }

  private navigate(path: string) {
    this.onClose();
    this.props.navigate(path);
  }
}
