import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./create-modal.component.scss");

interface Props {
  onClose: () => void;
  onProceed: () => void;
}

export const Onboarding = CSSModules(styles, { allowMultiple: true })((props: Props) => {
  return (
    <div styleName="onboarding" data-cy="CY-onboardingModal">
      <div styleName="onboarding__title">
        <ResourceText resourceKey="emailAccountOnboardingTitle" />
      </div>
      <div styleName="onboarding__usp">
        <div styleName="list">
          <ResourceText resourceKey="emailAccountOnboardingUSP" asHtml />
        </div>
      </div>
      <div styleName="onboarding__info">
        <ResourceText resourceKey="emailAccountOnboardingBody" asHtml />
      </div>
      <div styleName="footer">
        <div styleName="left">
          <ResourceText resourceKey="emailAccountOnboardingInvoiceInfo" />
        </div>
        <div styleName="right">
          <div className="btn btn-success" data-cy="CY-proceedButton" onClick={props.onProceed}>
            <ResourceText resourceKey="confirmEmailIntegration" />
          </div>
        </div>
      </div>
    </div>
  );
});
