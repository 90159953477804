import { RootEntityType } from "@haywork/api/event-center";
import {
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { EmailAddress, File } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Iframe from "@haywork/components/iframe";
import Icon from "@haywork/components/ui/icon";
import Presence from "@haywork/components/ui/presence";
import PresenceEntity from "@haywork/components/ui/presence/components/entity";
import { Colors } from "@haywork/enum/colors";
import first from "lodash-es/first";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import Attachments from "../attachments";
import CommunicationLogs from "../communication-log";
import { DetailContainerProps } from "./detail.container";

const styles = require("./style.scss");

export type DetailComponentProps = {};
type Props = DetailComponentProps & DetailContainerProps;

export const DetailComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ currentMessage, getMessage, createNewEmail }) => {
      const [loading, setLoading] = useState(false);
      const [reloading, setReloading] = useState(false);
      const [linkedRelations, setLinkedRelations] = useState(
        [] as LinkedRelation[]
      );
      const [linkedAssignments, setLinkedAssignments] = useState(
        [] as LinkedAssignment[]
      );

      const getEmailName = useCallback(
        (contact: EmailAddress, fallback?: string) => {
          if (!contact) return !fallback ? null : <I18n value={fallback} />;
          const { email, name } = contact;

          switch (true) {
            case !!name && !!email: {
              return `${name} <${email}>`;
            }
            case !!name: {
              return name;
            }
            case !!email: {
              return `<${email}>`;
            }
            default: {
              return !fallback ? null : <I18n value={fallback} />;
            }
          }
        },
        []
      );

      const from = useMemo(() => {
        if (!currentMessage) return null;
        const { from } = currentMessage;
        return getEmailName(first(from || []), "email.message.unknownFrom");
      }, [currentMessage, getEmailName]);

      const communicationLogId = useMemo(() => {
        return get(currentMessage, "_metaData.linkedCommunicationLogId") as
          | string
          | undefined;
      }, [currentMessage]);

      const subject = useMemo(() => {
        if (!currentMessage) return null;
        const { subject } = currentMessage;
        return subject || <I18n value="email.message.unknownSubject" />;
      }, [currentMessage]);

      const hasBlockedExternalReferences = useMemo(() => {
        return !!currentMessage && currentMessage.hasBlockedExternalReferences;
      }, [currentMessage]);

      const to = useMemo(() => {
        if (!currentMessage) return null;
        let { to } = currentMessage;
        to = (to || []).filter((to) => !!to.email || !!to.name);
        if (!to.length) return null;

        return (
          <>
            {to.map((email, idx) => (
              <div styleName="target__value" key={idx}>
                {getEmailName(email)}
              </div>
            ))}
          </>
        );
      }, [currentMessage, getEmailName]);

      const cc = useMemo(() => {
        if (!currentMessage) return null;
        let { cc } = currentMessage;
        cc = (cc || []).filter((cc) => !!cc.email || !!cc.name);
        if (!cc.length) return null;

        return (
          <>
            {cc.map((email, idx) => (
              <div styleName="target__value" key={idx}>
                {getEmailName(email)}
              </div>
            ))}
          </>
        );
      }, [currentMessage]);

      const bcc = useMemo(() => {
        if (!currentMessage) return null;
        let { bcc } = currentMessage;
        bcc = (bcc || []).filter((bcc) => !!bcc.email || !!bcc.name);
        if (!bcc.length) return null;

        return (
          <>
            {bcc.map((email, idx) => (
              <div styleName="target__value" key={idx}>
                {getEmailName(email)}
              </div>
            ))}
          </>
        );
      }, [currentMessage]);

      const replyTo = useMemo(() => {
        if (!currentMessage) return null;
        let { replyTo } = currentMessage;
        replyTo = (replyTo || []).filter(
          (replyTo) => !!replyTo.email || !!replyTo.name
        );
        if (!replyTo.length) return null;

        return (
          <>
            {replyTo.map((email, idx) => (
              <div styleName="target__value" key={idx}>
                {getEmailName(email)}
              </div>
            ))}
          </>
        );
      }, [currentMessage]);

      const attachments = useMemo(
        () =>
          !currentMessage
            ? ([] as File[])
            : (currentMessage.files || []).filter((file) => file.isAttachment),
        [currentMessage]
      );

      const messageBody = useMemo(
        () => (!currentMessage ? null : currentMessage.body || null),
        [currentMessage]
      );

      const eventSummary = useMemo(() => {
        if (!currentMessage) return null;
        const { events } = currentMessage;

        if (!isArray(events) || !events.length) return null;
        const event = first(events);
        return event.eventSummary;
      }, [currentMessage]);

      const onClickInEmail = useCallback(
        (event: MouseEvent) => {
          if (event.target["tagName"] === "A") {
            event.preventDefault();
            event.stopPropagation();

            if (/mailto:/gi.test(event.target["href"] || "")) {
              const href = (event.target["href"] || "").replace(
                /mailto:/gi,
                ""
              );
              if (!!href) {
                createNewEmail([href]);
              }
            } else {
              const href = event.target["href"] || "";
              if (!!href) {
                window.open(href, "_blank");
              }
            }
          }
        },
        [createNewEmail]
      );

      const html = useMemo(() => {
        let body = messageBody || "";

        if (!!eventSummary) {
          body = `
          <p>${eventSummary}</p>
          ${body}
        `;
        }

        if (!body) return "";

        body = body.replace(
          /<\s?([a-zA-Z0-9._\-]*)@([a-zA-Z0-9._\-]*)\s?>/gi,
          (match) => {
            return match.replace("<", "&lt;").replace(">", "&gt;");
          }
        );

        return <div dangerouslySetInnerHTML={{ __html: body }} />;
      }, [eventSummary, messageBody]);

      const loadMessage = useCallback(async () => {
        try {
          if (!currentMessage || !!currentMessage.body) return;
          let isCurrentlyLoading = false;
          setLoading((loading) => {
            isCurrentlyLoading = loading;
            return !isCurrentlyLoading;
          });

          if (isCurrentlyLoading) return;

          const {
            id,
            accountId,
            folderId,
            hasBlockedExternalReferences,
            unread,
          } = currentMessage;
          await getMessage(
            id,
            accountId,
            folderId,
            hasBlockedExternalReferences,
            unread ? false : true
          );
        } finally {
          setLoading(false);
        }
      }, [currentMessage, setLoading, getMessage]);

      const unblockReferences = useCallback(async () => {
        if (!currentMessage || reloading) return;

        try {
          setReloading(true);
          const { id, accountId, folderId, unread } = currentMessage;
          await getMessage(
            id,
            accountId,
            folderId,
            false,
            unread ? false : true
          );
        } finally {
          setReloading(false);
        }
      }, [currentMessage, reloading, setReloading, getMessage]);

      const onCommunicationLogUpdate = useCallback(
        (communicationLog: CommunicationLog) => {
          const { linkedRelations, linkedAssignments } = communicationLog;
          setLinkedRelations(linkedRelations || []);
          setLinkedAssignments(linkedAssignments || []);
        },
        [setLinkedRelations, setLinkedAssignments]
      );

      useEffect(() => {
        setLinkedAssignments([]);
        setLinkedRelations([]);
      }, [setLinkedAssignments, setLinkedRelations, currentMessage?.id]);

      const reloadMessageCallback = useCallback(async () => {
        if (!currentMessage) return null;
        const {
          id,
          accountId,
          folderId,
          hasBlockedExternalReferences,
          unread,
        } = currentMessage;
        return await getMessage(
          id,
          accountId,
          folderId,
          !hasBlockedExternalReferences,
          unread ? false : true
        );
      }, [currentMessage]);

      useEffect(() => {
        loadMessage();
      }, [loadMessage]);

      return (
        <div styleName="detail">
          {!currentMessage ? (
            <div styleName="empty-state">
              <Icon name="inbox" color={Colors.MediumGray} size={96} light />
              <div styleName="empty-state__message">
                <I18n value="email.detail.emptyState" />
              </div>
            </div>
          ) : (
            <div styleName="wrapper">
              <div styleName="header">
                <PresenceEntity
                  entityType={RootEntityType.Unknown}
                  entityId={currentMessage.id}
                />
                <div styleName="header__info">
                  <div styleName="from">{from}</div>
                  <div styleName="subject">{subject}</div>
                  {!!to && (
                    <div styleName="target">
                      <div styleName="target__label">
                        <I18n value="email.targetLabel.to" />
                      </div>
                      <div styleName="target__values">{to}</div>
                    </div>
                  )}
                  {!!cc && (
                    <div styleName="target">
                      <div styleName="target__label">
                        <I18n value="email.targetLabel.cc" />
                      </div>
                      <div styleName="target__values">{cc}</div>
                    </div>
                  )}
                  {!!bcc && (
                    <div styleName="target">
                      <div styleName="target__label">
                        <I18n value="email.targetLabel.bcc" />
                      </div>
                      <div styleName="target__values">{bcc}</div>
                    </div>
                  )}
                  {!!replyTo && (
                    <div styleName="target">
                      <div styleName="target__label">
                        <I18n value="email.targetLabel.replyTo" />
                      </div>
                      <div styleName="target__values">{replyTo}</div>
                    </div>
                  )}
                </div>
                <Presence
                  entityType={RootEntityType.Unknown}
                  entityId={currentMessage.id}
                  avatarBorderColor={Colors.White}
                />
              </div>

              <CommunicationLogs
                communicationLogId={communicationLogId}
                onUpdated={onCommunicationLogUpdate}
              />

              <Attachments
                attachments={attachments}
                linkedAssignments={linkedAssignments}
                linkedRelations={linkedRelations}
                reloadMessage={reloadMessageCallback}
              />

              <div styleName="divider" />

              {/* External references */}
              {!!hasBlockedExternalReferences && !reloading && (
                <div styleName="external-references">
                  <I18n value="email.blockedReferences.info" />
                  &nbsp;
                  <span
                    className="as-link"
                    styleName="emphasize"
                    onClick={unblockReferences}
                  >
                    <I18n value="email.blockedReferences.accept" />
                  </span>
                </div>
              )}

              {(!!loading || !!reloading) && (
                <div styleName="loading-state">
                  <Icon
                    name="spinner"
                    size={24}
                    spin
                    color={Colors.MediumGray}
                  />
                </div>
              )}

              {!loading && html && (
                <Iframe linkify autoHeight onLinkClick={onClickInEmail}>
                  {html}
                </Iframe>
              )}
            </div>
          )}
        </div>
      );
    }
  )
);
