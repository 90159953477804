import { RootEntityType } from "@haywork/api/event-center";
import { AcquisitionAssignment } from "@haywork/api/kolibri";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { editable } from "@haywork/modules/editable";
import { AppState } from "@haywork/stores";
import { AcquisitionActions } from "@haywork/stores/acquisition";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AcquisitionDetailScreen,
  AcquisitionDetailScreenProps
} from "./detail";

type StateProps = {
  acquisition: AcquisitionAssignment;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailScreenProps,
  AppState
> = (state) => {
  const { acquisition } = state.acquisition.single;

  return {
    acquisition
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailScreenProps
> = (dispatch) => ({});

export type AcquisitionDetailScreenContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const AcquisitionDetailScreenContainer = editable(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AcquisitionDetailScreen)
  ),
  {
    icon: "handshake-alt",
    thunk: AcquisitionThunks.Acquisition.getAcquisition,
    status: "acquisition.single.acquisitionStatus",
    statePath: "acquisition.single.acquisition",
    action: AcquisitionActions.Single.setAcquisition,
    route: ACQUISITIONROUTES.DETAIL.URI,
    entityType: RootEntityType.AcquisitionAssignment,
    confirm: {
      title: { key: "acquisition.saveConfirm.title" },
      body: { key: "acquisition.saveConfirm.body" }
    }
  }
);
