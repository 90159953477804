import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AcquisitionAssignment,
  AssignmentSnapShot,
  LinkedAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  InvoiceThunk,
  MapDispatchToProps,
  SchedulerThunks,
  TaskThunks,
} from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionDetailGeneralScreen,
  AcquisitionDetailGeneralScreenProps,
} from "./general";

type StateProps = {
  acquisition: AcquisitionAssignment;
  canSendEmail: boolean;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getAcquisitionObjectsInNegotiationOrWon: (
    id: string
  ) => Promise<AssignmentSnapShot[]>;
  createTaskWithLinkedEntities: (
    linkedRelations?: LinkedRelation[],
    linkedAssignment?: LinkedAssignment
  ) => Promise<void>;
  createInvoiceWithLinkedEntities: (
    linkedRelation?: LinkedRelation,
    linkedAssignment?: LinkedAssignment
  ) => Promise<void>;
  createAgendaItemWithLinkedEntities: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createNewEmail: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) => void;
  archiveAcquisition: (id: string) => Promise<void>;
  unArchiveAcquisition: (id: string) => Promise<void>;
  deleteAcquisition: (id: string) => Promise<void>;
  createNew: (acquisition?: AcquisitionAssignment) => Promise<void>;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  setAcquisitionStatus: (
    acquisitionAssignment: AcquisitionAssignment,
    close: boolean
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailGeneralScreenProps,
  AppState
> = (state) => {
  const { acquisition } = state.acquisition.single;

  return {
    acquisition,
    canSendEmail: canSendEmail(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailGeneralScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getAcquisitionObjectsInNegotiationOrWon: (id: string) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.getAcquisitionObjectsInNegotiationOrWon(
        id
      )
    ),
  createTaskWithLinkedEntities: (
    linkedRelations?: LinkedRelation[],
    linkedAssignment?: LinkedAssignment
  ) =>
    dispatch(
      TaskThunks.createTaskWithLinkedEntities(linkedRelations, [
        linkedAssignment,
      ])
    ),
  createInvoiceWithLinkedEntities: (
    linkedRelation?: LinkedRelation,
    linkedAssignment?: LinkedAssignment
  ) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        linkedRelation,
        linkedAssignment,
        undefined,
        undefined,
        "Consumer"
      )
    ),
  createAgendaItemWithLinkedEntities: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment
  ) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities(linkedRelations, [
        linkedAssignment,
      ])
    ),
  createNewEmail: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail(
        [],
        linkedRelations,
        [linkedAssignment],
        displayName
      )
    ),
  archiveAcquisition: (id: string) =>
    dispatch(AcquisitionThunks.Acquisition.archiveAcquisition(id)),
  unArchiveAcquisition: (id: string) =>
    dispatch(AcquisitionThunks.Acquisition.unArchiveAcquisition(id)),
  deleteAcquisition: (id: string) =>
    dispatch(AcquisitionThunks.Acquisition.deleteAcquisition(id)),
  createNew: (acquisition?: AcquisitionAssignment) =>
    dispatch(AcquisitionThunks.AcquisitionObject.createNew(acquisition)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  setAcquisitionStatus: (
    acquisitionAssignment: AcquisitionAssignment,
    close: boolean
  ) =>
    dispatch(
      AcquisitionThunks.Acquisition.saveAcquisition(
        acquisitionAssignment,
        close
      )
    ),
});

export type AcquisitionDetailGeneralScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionDetailGeneralScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailGeneralScreen);
