import { Dispatch, RelationThunks } from "@haywork/middleware";
import {
  RelationNewComponent,
  RelationNewComponentProps,
} from "@haywork/modules/relation";
import { AppState } from "@haywork/stores";
import { NewEntity } from "@haywork/stores/new-entity";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  entities: NewEntity[];
}
interface DispatchProps {
  newContactPerson: (
    caller?: string,
    newName?: string,
    newEmail?: string,
    newPhone?: string
  ) => void;
  newContactCompany: (
    caller?: string,
    newName?: string,
    newEmail?: string,
    newPhone?: string
  ) => void;
}

const mapStateToProps = <StateProps, RelationNewComponentProps>(
  state: AppState
) => {
  const { entities } = state.newEntity;

  return {
    entities,
  };
};

const mapDispatchToProps = <DispatchProps, RelationNewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  newContactPerson: (
    caller?: string,
    newName?: string,
    newEmail?: string,
    newPhone?: string
  ) =>
    dispatch(
      RelationThunks.newContactPerson(caller, newName, newEmail, newPhone)
    ),
  newContactCompany: (
    caller?: string,
    newName?: string,
    newEmail?: string,
    newPhone?: string
  ) =>
    dispatch(
      RelationThunks.newContactCompany(caller, newName, newEmail, newPhone)
    ),
});

export type RelationNewContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const RelationNewContainer = connect<
  StateProps,
  DispatchProps,
  RelationNewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RelationNewComponent));
