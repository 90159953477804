import { Language, ProjectAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { ProjectEditMarketingTextComponent } from "@haywork/modules/project/components/marketing-text/marketing-text.component";
import { AppState, EditableActions, SingleProjectState } from "@haywork/stores";
import { connect } from "react-redux";

export interface ProjectEditMarketingTextContainerProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  languages: Language[];
  defaultLanguage: Language;

  updateProject: (componentState: SingleProjectState, path: string) => void;
}

const mapStateToProps = (state: AppState) => {
  const { currentComponentState } = state.editable;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});
export const ProjectEditMarketingTextContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingTextComponent);
