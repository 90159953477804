import { AgendaItemSnapShot, AgendaItem } from "@haywork/api/kolibri";

export const mapAgendaItemToAgendaItemSnapShot = (
  agendaItem: AgendaItem,
  employeeId: string
): AgendaItemSnapShot & { description?: string } => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    allDayEvent,
    endDateTime,
    exceptionDates,
    isCanceled,
    isConfirmed,
    isPrivate,
    isRecurringEvent,
    linkedAgendaItemCategory,
    linkedAssignments,
    linkedEmployees,
    linkedRelations,
    location,
    parentId,
    priority,
    recurrencePattern,
    startDateTime,
    subject,
    timeMarker,
    description,
    readOnly,
  } = agendaItem;

  const employeeIds = (linkedEmployees || []).map((employee) => employee.id);

  return {
    id,
    accessDeniedForCurrentUser:
      !!isPrivate &&
      employeeId !== linkedCreatedBy?.id &&
      !employeeIds.includes(employeeId),
    allDayEvent,
    dateTimeCreated,
    dateTimeModified,
    endDateTime,
    exceptionDates,
    isCanceled,
    isConfirmed,
    isPrivate,
    isRecurringEvent,
    linkedAgendaItemCategory,
    linkedAssignments,
    linkedCreatedBy,
    linkedEmployees,
    linkedModifiedBy,
    linkedRelations,
    location,
    parentId,
    priority,
    realEstateAgencyId,
    recurrencePattern,
    startDateTime,
    subject,
    timeMarker,
    description,
    readOnly,
  };
};
