import {
  SortOrder,
  SearchAssignmentOrderByField,
  SearchAssignmentSnapShot
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum ActionType {
  UpdateList = "SearchAssignments.List.UpdateList",
  DeleteListItem = "SearchAssignments.List.DeleteListItem",
  SetOrdering = "SearchAssignments.List.SetOrdering",
  SetFilters = "SearchAssignments.List.SetFilters",
  SetScrollOffset = "SearchAssignments.List.SetScrollOffset",
  UpdateItem = "SearchAssignments.List.UpdateItem",
  ResetApp = "RESET_APP"
}

type UpdateList = {
  type: ActionType.UpdateList;
  items: SearchAssignmentSnapShot[];
  totalCount: number;
  startIndex: number;
};
const updateList = (
  startIndex: number,
  items: SearchAssignmentSnapShot[],
  totalCount: number
) => ({
  type: ActionType.UpdateList,
  items,
  totalCount,
  startIndex
});

type DeleteListItem = {
  type: ActionType.DeleteListItem;
  id: string;
};
const deleteListItem = (id: string) => ({
  type: ActionType.DeleteListItem,
  id
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: SearchAssignmentOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: SearchAssignmentOrderByField
) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: SearchAssignmentSnapShot;
};

const updateItem = (snapshot: SearchAssignmentSnapShot) => ({
  type: ActionType.UpdateItem,
  snapshot
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetFilters
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | DeleteListItem
  | UpdateItem
  | ResetApp;
export const actions = {
  updateList,
  setFilters,
  deleteListItem,
  setOrdering,
  setScrollOffset,
  updateItem
};
