import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RELATIONROUTES } from "@haywork/constants";
import {
    RelationAroRelationsContainer,
    RelationAssignmentContainer,
    RelationDossierContainer,
    RelationGeneralContainer,
    RelationTimelineContainer,
    RelationSearchAssignmentsContainer
} from "@haywork/modules/relation";
import { ContactCompanyEditContainer } from "@haywork/modules/relation/containers/contact-company-edit.container";
import { RouteUtil } from "@haywork/util";

const route = RouteUtil.mapStaticRouteValues;
export const ContactCompanyDetailRouting = ( id ) => {
  return (
    <Switch>
    <Route exact path={"/app/relations/company/:id"} render={() => <Redirect to={ route(RELATIONROUTES.CONTACT_COMPANY_GENERAL.URI, id)} />}/>
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_GENERAL.URI } component={ RelationGeneralContainer } />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_TIMELINE.URI } component={ RelationTimelineContainer } />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_ASSIGNMENT.URI } component={ RelationAssignmentContainer } />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_LINKED_RELATIONS.URI } component={ RelationAroRelationsContainer } />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_EDIT.URI } component={ ContactCompanyEditContainer} />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_DOSSIER.URI } component={ RelationDossierContainer} />
    <Route path={ RELATIONROUTES.CONTACT_COMPANY_SEARCH_ASSIGNMENTS.URI } component={ RelationSearchAssignmentsContainer} />
  </Switch>
  );
};
