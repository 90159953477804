import {
  CountryOption,
  EmailAddressTypeOption,
  Language,
  Office,
  PhoneNumberTypeOption,
  RealEstateAgency,
  SocialMediaType,
} from "@haywork/api/kolibri";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

interface OfficeReadonlyComponentProps {
  office: Office;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
  officeName: string;
  realEstateAgency: RealEstateAgency;
}
interface OfficeReadonlyComponentState {}

const styles = require("./office-readonly.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class OfficeReadonlyComponent extends React.Component<
  OfficeReadonlyComponentProps,
  OfficeReadonlyComponentState
> {
  constructor(props) {
    super(props);

    this.hasAddressOrPostalAddress = this.hasAddressOrPostalAddress.bind(this);
    this.hasAddress = this.hasAddress.bind(this);
    this.renderLanguagePill = this.renderLanguagePill.bind(this);
  }

  public render() {
    const { office, realEstateAgency } = this.props;
    const country = office.visitAddress
      ? this.props.countries.find(
          (country) => country.iso2CodeValue === office.visitAddress.countryIso2
        ).displayName
      : "";
    const postalAddressCountry = office.postalAddress.countryIso2
      ? this.props.countries.find(
          (country) =>
            country.iso2CodeValue === office.postalAddress.countryIso2
        ).displayName
      : "";

    const facebook = office.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Facebook
    );
    const linkedIn = office.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.LinkedIn
    );
    const youtube = office.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.YouTube
    );
    const twitter = office.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Twitter
    );

    return (
      <div styleName="readMode">
        {/* Title */}
        <div styleName="name">
          {/* Main Office label */}
          {this.props.office.isMainOffice ? (
            <span className="label label-primary">
              <ResourceText resourceKey="headquarters" />
            </span>
          ) : (
            ""
          )}
          <h1 className={styles.title}>{this.props.officeName}</h1>
          {/* Spoken languages */}
          {(() => {
            if (office.spokenLanguages.length > 0) {
              return (
                <div styleName="spokenLanguages">
                  <label styleName="label">
                    <ResourceText resourceKey="speaksLabel" />
                  </label>
                  {office.spokenLanguages.map(this.renderLanguagePill)}
                </div>
              );
            }
          })()}
        </div>

        {/* description */}
        {office.aboutMe[0] ? (
          <div styleName="itemContainer">
            <div styleName="item">
              <span className={styles.label}>
                <ResourceText resourceKey="description" />
              </span>
              <div className="pre-line">{office.aboutMe[0].text}</div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Association */}
        {office.associationInfo.association ? (
          <div styleName="itemContainer">
            <div styleName="item">
              <span className={styles.label}>
                <ResourceText resourceKey="associationInfo.association" />
              </span>
              <div>
                <span styleName="association">
                  {realEstateAgency?.associationInfo?.realEstateAssociation ? (
                    <ResourceText
                      resourceKey={`realEstateAssociations.${realEstateAgency.associationInfo.realEstateAssociation.toString()}`}
                    />
                  ) : office.associationInfo.association ? (
                    office.associationInfo.association
                  ) : (
                    <ResourceText resourceKey="associationUnknown" />
                  )}
                </span>
                <span styleName="membershipNumber">
                  {office.associationInfo.membershipNumber}
                </span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Phonenumbers */}
        {office.phoneNumbers.length > 0 && (
          <div styleName="itemContainer">
            {office.phoneNumbers.map((phoneNumber, index) => {
              const type = phoneNumber.type
                ? this.props.phoneNumberTypes.find(
                    (phoneNumberType) =>
                      phoneNumber.type === phoneNumberType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <a href={"tel:" + phoneNumber.number}>{phoneNumber.number}</a>
                </div>
              );
            })}
          </div>
        )}

        {/* EmailAddresses */}
        {office.emailAddresses.length > 0 && (
          <div styleName="itemContainer" className={styles.emailAddresses}>
            {office.emailAddresses.map((emailAddress, index) => {
              const type = emailAddress.type
                ? this.props.emailAddressTypes.find(
                    (emailAddressType) =>
                      emailAddress.type === emailAddressType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <MailTo email={emailAddress.address} />
                </div>
              );
            })}
          </div>
        )}

        {/* WebAddress */}
        {office.webAddress ? (
          <div styleName="itemContainer">
            <div styleName={"item"}>
              <span styleName="label">
                <ResourceText resourceKey="website" />
              </span>
              <a href={office.webAddress} target="_blank">
                {office.webAddress}
              </a>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Adresses */}
        {this.hasAddressOrPostalAddress() && (
          <div styleName={"itemContainer" + " " + "addresses"}>
            {this.hasAddress() && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <ResourceText resourceKey="visitAddress" />
                </span>
                <div>
                  {office.visitAddress.street ? (
                    <span styleName="line1">
                      {office.visitAddress.street.name +
                        " " +
                        (office.visitAddress.houseNumber
                          ? office.visitAddress.houseNumber
                          : "") +
                        " " +
                        (office.visitAddress.houseNumberPostfix
                          ? office.visitAddress.houseNumberPostfix
                          : "")}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.props.office.visitAddress.locality ? (
                    <span styleName="line2">
                      {(office.visitAddress.postalCode
                        ? office.visitAddress.postalCode
                        : "") +
                        " " +
                        office.visitAddress.locality.name}
                      <span styleName="country">&nbsp;({country})</span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {(() => {
              if (
                office.postalAddressDiffersVisitAddress &&
                (office.postalAddress.street || office.postalAddress.locality)
              ) {
                return (
                  <div styleName="item address">
                    <span styleName="label">
                      <ResourceText resourceKey="postalAddress" />
                    </span>
                    <div>
                      {office.postalAddress.street ? (
                        <span styleName="line1">
                          {office.postalAddress.street.name +
                            " " +
                            (office.postalAddress.houseNumber
                              ? office.postalAddress.houseNumber
                              : "") +
                            " " +
                            (office.postalAddress.houseNumberPostfix
                              ? office.postalAddress.houseNumberPostfix
                              : "")}
                        </span>
                      ) : (
                        ""
                      )}
                      {office.postalAddress.locality ? (
                        <span styleName="line2">
                          {(office.postalAddress.postalCode
                            ? office.postalAddress.postalCode
                            : "") +
                            " " +
                            this.props.office.postalAddress.locality.name}
                          <span styleName="country">
                            &nbsp;({postalAddressCountry})
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })()}
          </div>
        )}

        {office.socialMedia.length > 0 && (
          <div styleName={"itemContainer" + " " + "socialMedia"}>
            <div className={styles.item}>
              <span className={styles.label}>
                <ResourceText resourceKey="socialMedia" />
              </span>
              {facebook && facebook.account && (
                <a
                  className={styles.facebook}
                  href={facebook.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-facebook-f"></i>
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseFacebook" />
                  </span>
                </a>
              )}
              {linkedIn && linkedIn.account && (
                <a
                  className={styles.linkedin}
                  href={linkedIn.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-linkedin-in"></i>
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {youtube && youtube.account && (
                <a
                  className={styles.youtube}
                  href={youtube.account}
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {twitter && twitter.account && (
                <a
                  className={styles.twitter}
                  href={twitter.account}
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseTwitter" />
                  </span>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private hasAddressOrPostalAddress(): boolean {
    if (
      (this.props.office.visitAddress.street &&
        this.props.office.visitAddress.street.name) ||
      (this.props.office.visitAddress.locality &&
        this.props.office.visitAddress.locality.name) ||
      (this.props.office.visitAddress.postalCode &&
        this.props.office.visitAddress.postalCode) ||
      (this.props.office.postalAddress.street &&
        this.props.office.postalAddress.street.name) ||
      (this.props.office.postalAddress.locality &&
        this.props.office.postalAddress.locality.name) ||
      (this.props.office.postalAddress.postalCode &&
        this.props.office.postalAddress.postalCode)
    ) {
      return true;
    }
    return false;
  }

  private hasAddress(): boolean {
    if (
      (this.props.office.visitAddress.street &&
        this.props.office.visitAddress.street.name) ||
      (this.props.office.visitAddress.locality &&
        this.props.office.visitAddress.locality.name) ||
      (this.props.office.visitAddress.postalCode &&
        this.props.office.visitAddress.postalCode)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private renderLanguagePill(language: Language, idx: number) {
    let flag = "famfamfam-flag-";

    switch (language) {
      case Language.Croatian:
        flag = flag + "hr";
        break;
      case Language.English:
        flag = flag + "gb";
        break;
      case Language.Estonian:
        flag = flag + "ee";
        break;
      case Language.Finnish:
        flag = flag + "fi";
        break;
      case Language.French:
        flag = flag + "fr";
        break;
      case Language.Georgian:
        flag = flag + "ge";
        break;
      case Language.German:
        flag = flag + "de";
        break;
      case Language.Italian:
        flag = flag + "it";
        break;
      case Language.Polish:
        flag = flag + "pl";
        break;
      case Language.Russian:
        flag = flag + "ru";
        break;
      case Language.Spanish:
        flag = flag + "es";
        break;
      case Language.Swedish:
        flag = flag + "se";
        break;
      case Language.Turkish:
        flag = flag + "tr";
        break;
      default:
        flag = flag + "nl";
        break;
    }

    return (
      <span styleName="language" key={idx}>
        <i className={flag} />
        <ResourceText masterKey="languages" resourceKey={language.toString()} />
      </span>
    );
  }
}
