import * as React from "react";
import { FC, memo, useState, useMemo, useEffect, useCallback } from "react";
import { Modal, ModalHeader } from "@haywork/modules/modal";
import {
  LinkedRelation,
  FolderTreeFileEntity,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import CheckView from "./components/check";
import SelectView from "./components/select";
import { SelectDossierItemsModalContainerProps } from "./select-dossier-items-modal.container";
import { ConvertableFile, BlobUtil } from "@haywork/util";

export type SelectDossierItemsModalComponentProps = {
  visible: boolean;
  view: "select" | "check";
  files?: FolderTreeFileEntity[];
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  onClose: () => void;
  onFiles: (results: UploadResponse[]) => void;
};
type Props = SelectDossierItemsModalComponentProps &
  SelectDossierItemsModalContainerProps;

export const SelectDossierItemsModalComponent: FC<Props> = memo(
  ({
    visible,
    view: refView,
    files,
    currentAccount,
    linkedRelations,
    linkedAssignments,
    onClose,
    onFiles,
    createBatchDownload,
    uploadFilesFromUri,
  }) => {
    const [view, setView] = useState(refView);
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(files || []);

    const onSetFiles = useCallback(
      (files: FolderTreeFileEntity[]) => {
        setSelectedFiles(files);
        setView("check");
      },
      [setSelectedFiles, setView]
    );

    const onUseFiles = useCallback(
      async (files: ConvertableFile[]) => {
        try {
          setLoading(true);
          const items = await createBatchDownload(
            files.map(BlobUtil.mapConvertableFileToBatchItem).filter((f) => !!f)
          );
          const attachments = await uploadFilesFromUri(currentAccount, items);
          onFiles(attachments);
          setView(refView);
          setSelectedFiles([]);
        } finally {
          setLoading(false);
        }
      },
      [
        currentAccount,
        createBatchDownload,
        uploadFilesFromUri,
        setView,
        refView,
        setSelectedFiles,
        setLoading,
      ]
    );

    useEffect(() => {
      setSelectedFiles(files);
    }, [files, setSelectedFiles]);

    const renderedView = useMemo(() => {
      switch (view) {
        case "check": {
          return (
            <CheckView
              files={selectedFiles || []}
              loading={loading}
              onUseFiles={onUseFiles}
              canMail={!!currentAccount}
            />
          );
        }
        case "select": {
          return (
            <SelectView
              linkedRelations={linkedRelations}
              linkedAssignments={linkedAssignments}
              onSetFiles={onSetFiles}
            />
          );
        }
        default:
          return null;
      }
    }, [
      view,
      selectedFiles,
      loading,
      linkedRelations,
      linkedAssignments,
      onUseFiles,
      onSetFiles,
      currentAccount,
    ]);

    const onCloseHandler = useCallback(() => {
      if (loading) return;
      onClose();
      setView(refView);
      setSelectedFiles([]);
    }, [loading, onClose, setSelectedFiles]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title={`dossier.selectedFilesModal.title.${view}`} close />
        {renderedView}
      </Modal>
    );
  }
);
