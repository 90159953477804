import { RelationSnapShot } from "@haywork/api/kolibri";
import { ShareType } from "@haywork/api/mail";
import { AccountShareReference } from "@haywork/middleware/thunk/email";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryResultReturnValue,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");

interface Props {
  employees: RelationSnapShot[];
  onSubmit: (snapshots: AccountShareReference[], from: string) => Promise<void>;
}
interface State {
  selectedShareIds: string[];
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class EmailSharesComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      selectedShareIds: [],
      loading: false,
    };

    this.formControls = {
      readShares: { value: [] },
      readWriteShares: { value: [] },
      readWriteSendShares: { value: [] },
      from: { value: "" },
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderDisableOption = this.renderDisableOption.bind(this);
  }

  public render() {
    const disabled = !!this.state.loading;

    return (
      <div styleName="shares">
        <Form
          name="email-shares"
          formControls={this.formControls}
          form={(form) => (this.formRef = form)}
          onSubmit={this.onSubmitHandler}
          onChange={this.onChangeHandler}
        >
          <div styleName="shared">
            <div styleName="shared__form">
              <div className="form__row">
                <label htmlFor="from">
                  <ResourceText resourceKey="emailAccountFrom" />
                </label>
                <Input.Text
                  name="from"
                  disabled={disabled}
                  placeholder="emailAccountFromPlaceholder"
                />
              </div>
            </div>

            <div styleName="shared__header">
              <ResourceText resourceKey="shareEmailAccountWith" />
            </div>

            <div styleName="shared__form">
              <div className="form__row">
                <label htmlFor="readShares">
                  <ResourceText resourceKey="emailReadSharesLabel" />
                </label>
                <Input.Query
                  name="readShares"
                  values={this.props.employees}
                  matchOn={this.matchOnHandler}
                  optionValue={this.renderOptionValue}
                  selectedValue={this.renderSelectedValue}
                  disableOption={this.renderDisableOption}
                  disabled={disabled}
                  multiple
                  placeholder="emailLinkedPersonPlaceholder"
                />
              </div>

              <div className="form__row">
                <label htmlFor="readWriteShares">
                  <ResourceText resourceKey="emailReadWriteSharesLabel" />
                </label>
                <Input.Query
                  name="readWriteShares"
                  values={this.props.employees}
                  matchOn={this.matchOnHandler}
                  optionValue={this.renderOptionValue}
                  selectedValue={this.renderSelectedValue}
                  disableOption={this.renderDisableOption}
                  disabled={disabled}
                  multiple
                  placeholder="emailLinkedPersonPlaceholder"
                />
              </div>

              <div className="form__row">
                <label htmlFor="readWriteSendShares">
                  <ResourceText resourceKey="emailReadWriteSendSharesLabel" />
                </label>
                <Input.Query
                  name="readWriteSendShares"
                  data-cy="CY-readWriteSendShares"
                  values={this.props.employees}
                  matchOn={this.matchOnHandler}
                  optionValue={this.renderOptionValue}
                  selectedValue={this.renderSelectedValue}
                  disableOption={this.renderDisableOption}
                  disabled={disabled}
                  multiple
                  placeholder="emailLinkedPersonPlaceholder"
                />
              </div>
            </div>
          </div>
        </Form>
        <div styleName="footer">
          <div styleName="left" />
          <div styleName="right">
            <button
              type="button"
              data-cy="CY-shareSubmitButton"
              onClick={() => this.formRef.submit()}
              className="btn btn-primary"
            >
              <ButtonLoader resourceKey="save" loading={disabled} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private async onSubmitHandler(values: FormReturnValue) {
    if (!!this.state.loading) return;

    this.setState({
      loading: true,
    });

    const snapshots = this.mapSnapshotGroupsToShares(values);
    await this.props.onSubmit(snapshots, values.from);

    this.setState({
      loading: false,
    });
  }

  private onChangeHandler(values: FormReturnValue) {
    const snapshots = this.mapSnapshotGroupsToShares(values);
    const selectedShareIds = snapshots.map((snapshot) => snapshot.id);

    this.setState({
      selectedShareIds,
    });
  }

  private matchOnHandler(query: string, value: RelationSnapShot): boolean {
    const matchOn = new RegExp(query, "gi");
    return matchOn.test(value.displayName);
  }

  private renderOptionValue(
    value: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private renderSelectedValue(
    value: RelationSnapShot
  ): QueryResultReturnValue<RelationSnapShot> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private renderDisableOption(value: RelationSnapShot) {
    return this.state.selectedShareIds.indexOf(value.id) !== -1;
  }

  private mapSnapshotGroupsToShares(
    values: FormReturnValue
  ): AccountShareReference[] {
    const { readShares, readWriteShares, readWriteSendShares } = values;

    return [
      ...readShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.Read,
      })),
      ...readWriteShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.ReadWrite,
      })),
      ...readWriteSendShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.ReadWriteSend,
      })),
    ];
  }
}
