import * as React from "react";
import { ColorResult, GithubPicker, SketchPicker } from "react-color";
import * as CSSModules from "react-css-modules";

import { InputComponentProps } from "../input.component";

const styles = require("./color.component.scss");
const colorPickerColors = [
  "#808080",
  "#BDC3C7",
  "#A9A9A9",
  "#C0C0C0",
  "#800000",
  "#E74C3C",
  "#BA2700",
  "#D35400",
  "#8E692D",
  "#E9E03F",
  "#808000",
  "#68CC66",
  "#349933",
  "#27AE60",
  "#2ECC71",
  "#16A085",
  "#4FB3B9",
  "#1273DE",
  "#2980B9",
  "#2C3E50",
  "#68A6F6",
  "#000080",
  "#8E44AD",
  "#9B59B6"
];

interface ColorInputComponentProps {
  colorPickerColors?: string[];
  width?: number;
  type?: "github" | "sketch";
}
interface ColorInputComponentState {
  pickerVisible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ColorComponent extends React.Component<
  ColorInputComponentProps & InputComponentProps,
  ColorInputComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      pickerVisible: false
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.togglePickerVisibility = this.togglePickerVisibility.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    return (
      <div styleName="color-picker" ref={(ref) => (this.ref = ref)}>
        <div styleName="trigger" onClick={this.togglePickerVisibility}>
          <div
            styleName="trigger__color"
            style={{ backgroundColor: this.props.value }}
          />
          <div styleName="trigger__arrow">
            <i className="fal fa-chevron-down" />
          </div>
        </div>
        {this.state.pickerVisible && (
          <div styleName="picker">{this.renderPicker()}</div>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private renderPicker() {
    switch (this.props.type) {
      case "sketch": {
        return (
          <SketchPicker
            color={this.props.value}
            disableAlpha={true}
            onChange={this.onChangeHandler}
          />
        );
      }
      default: {
        const width = this.props.width || 212;
        const colors = this.props.colorPickerColors || colorPickerColors;

        return (
          <GithubPicker
            width={`${width}px`}
            colors={colors}
            color={this.props.value}
            onChange={this.onChangeHandler}
          />
        );
      }
    }
  }

  private onChangeHandler(result: ColorResult) {
    this.props.onChange(result.hex);

    switch (this.props.type) {
      case "sketch": {
        break;
      }
      default: {
        this.setState({ pickerVisible: false });
        break;
      }
    }
  }

  private togglePickerVisibility() {
    this.setState((state) => ({ pickerVisible: !state.pickerVisible }));
  }

  private onClickOutsideHandler(event: any) {
    if (!!this.ref && !this.ref.contains(event.target)) {
      this.setState({ pickerVisible: false });
    }
  }
}
