import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import { PhraseSnapShot, Phrase } from "@haywork/api/kolibri";

import * as ActionType from "./phrases.types";

export interface PhrasesState {
  phrases: PhraseSnapShot[];
  phrasesStatus: string;
  phrase: Phrase;
  phraseStatus: string;
}

const INITIAL_STATE: PhrasesState = {
  phrases: [],
  phrasesStatus: REQUEST.IDLE,
  phrase: null,
  phraseStatus: REQUEST.IDLE
};

export const phrasesReducer = (state: PhrasesState = INITIAL_STATE, action: Action): PhrasesState => {
  switch (action.type) {
    case REDUX.PHRASES.SET_PHRASES: {
      const { phrases } = <ActionType.Phrases> action;

      return {
        ...state,
        phrases,
        phrasesStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.PHRASES.UPDATE_PHRASES: {
      const { phrases } = <ActionType.Phrases> action;

      return {
        ...state,
        phrases
      };
    }
    case REDUX.PHRASES.SET_PHRASES_STATUS: {
      const { phrasesStatus } = <ActionType.PhrasesStatus> action;

      return {
        ...state,
        phrasesStatus
      };
    }
    case REDUX.PHRASES.SET_PHRASE: {
      const { phrase } = <ActionType.SinglePhrase> action;

      return {
        ...state,
        phrase,
        phraseStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.PHRASES.SET_PHRASE_STATUS: {
      const { phraseStatus } = <ActionType.PhraseStatus> action;

      return {
        ...state,
        phraseStatus
      };
    }
    case REDUX.PHRASES.UNSET_PHRASE: {
      return {
        ...state,
        phrase: null,
        phraseStatus: REQUEST.IDLE
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
