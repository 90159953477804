import { Dispatch } from ".";

import {
  RelationGroupsClient,
  RelationGroupSnapShot,
  RelationGroupsSearchRequest,
  RelationGroupOrderByField,
  SortOrder,
  ActiveFilter
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { AppState, RelationGroupsActions } from "@haywork/stores";
import { RelationGroupsRequests } from "@haywork/request";
import { ParseRequest } from "@haywork/services";

const parseRequest = new ParseRequest();

const getRelationGroups = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const client = new RelationGroupsClient(host);
      const take = 100;

      dispatch(RelationGroupsActions.setRelationGroupsStatus(REQUEST.PENDING));

      const request: RelationGroupsSearchRequest = {
        includeStatistics: true,
        orderBy: RelationGroupOrderByField.Name,
        order: SortOrder.Ascending,
        skip: 0,
        take,
        filterByActive: ActiveFilter.ActiveOnly
      };

      const response = await parseRequest.response(
        client.search(request, realEstateAgencyId)
      );
      const { results, totalResults, statistics } = response;

      let relationGroups = results;

      if (totalResults > take) {
        const diff = response.totalResults - take;
        const count = Math.ceil(diff / take);
        const promises: Promise<RelationGroupSnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            client
              .search(
                {
                  ...request,
                  skip: i * take,
                  includeStatistics: false
                },
                realEstateAgencyId
              )
              .then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const groups = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <RelationGroupSnapShot[]>[]);

        relationGroups = [...relationGroups, ...groups];
      }

      dispatch(
        RelationGroupsActions.setRelationGroups({ relationGroups, statistics })
      );
    } catch (error) {
      dispatch(RelationGroupsActions.setRelationGroupsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveRelationGroup = (relationGroupSnapShot: RelationGroupSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const RelationGroups = new RelationGroupsClient(host);

    try {
      let relationGroup = await parseRequest.response(
        RelationGroups.read(relationGroupSnapShot.id, realEstateAgencyId).then(
          (response) => response.relationGroup
        )
      );

      relationGroup = {
        ...relationGroup,
        name: relationGroupSnapShot.name,
        backColor: relationGroupSnapShot.backColor
      };

      dispatch(
        RelationGroupsActions.setRelationGroup({
          singleRelationGroup: relationGroup
        })
      );

      parseRequest.response(
        RelationGroups.save({ relationGroup }, realEstateAgencyId)
      );
    } catch (error) {
      dispatch(RelationGroupsActions.setRelationGroupsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const addNewRelationGroup = (backColor: string, name: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationGroupsActions.setRelationAddGroupsStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { culture } = state.main;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const RelationGroups = new RelationGroupsClient(host);

    try {
      let relationGroup = await parseRequest.response(
        RelationGroups.defineNew({ culture }, realEstateAgencyId).then(
          (response) => response.relationGroup
        )
      );

      relationGroup = {
        ...relationGroup,
        name,
        backColor
      };

      await parseRequest.response(
        RelationGroups.save({ relationGroup }, realEstateAgencyId)
      );

      dispatch(
        RelationGroupsActions.setRelationAddGroupsStatus(REQUEST.SUCCESS)
      );
      dispatch(RelationGroupsActions.addRelationGroup({ relationGroup }));

      return relationGroup;
    } catch (error) {
      dispatch(RelationGroupsActions.setRelationAddGroupsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deleteRelationGroup = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const RelationGroups = new RelationGroupsClient(host);

    try {
      await parseRequest.response(
        RelationGroups.delete(id, realEstateAgencyId)
      );

      dispatch(RelationGroupsActions.deleteRelationGroup({ id }));
    } catch (error) {
      dispatch(RelationGroupsActions.setRelationGroupsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unDeleteRelationGroup = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const RelationGroups = new RelationGroupsClient(host);

    try {
      await parseRequest.response(
        RelationGroups.undelete({ id }, realEstateAgencyId)
      );

      dispatch(RelationGroupsActions.unDeleteRelationGroup({ id }));
    } catch (error) {
      dispatch(RelationGroupsActions.setRelationGroupsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export const RelationGroupsThunk = {
  getRelationGroups,
  saveRelationGroup,
  addNewRelationGroup,
  deleteRelationGroup,
  unDeleteRelationGroup
};
