import * as moment from "moment";

import { ErrorMessage } from "@haywork/modules/form";

export const dateShouldBeBefore = (
  date: Date,
  skip?: () => boolean,
  errorMessage?: ErrorMessage
) => (value?: string | Date): null | ErrorMessage => {
  const parsedDate = moment(value, moment.ISO_8601).unix();
  const parsedOriginalDate = moment(date, moment.ISO_8601).unix();
  if ((!!skip && skip() === true) || parsedDate < parsedOriginalDate || !value)
    return null;
  return errorMessage || { key: "validatorDateBefore" };
};

export const dateShouldBeAfter = (
  date: Date,
  skip?: () => boolean,
  errorMessage?: ErrorMessage
) => (value?: string | Date): null | ErrorMessage => {
  const parsedDate = moment(value, moment.ISO_8601).unix();
  const parsedOriginalDate = moment(date, moment.ISO_8601).unix();
  if ((!!skip && skip() === true) || parsedDate > parsedOriginalDate || !value)
    return null;
  return errorMessage || { key: "validatorDateAfter" };
};
