import * as React from "react";
import { FC, memo, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import {
  LinkedRelation,
  MatchedSearchAssignmentSnapshot,
  RelationType,
} from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import noop from "lodash-es/noop";
import { Input } from "@haywork/modules/form";
import I18n from "@haywork/components/i18n";
import { FormattedDate } from "react-intl";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES,
  SEARCHASSIGNMENTROUTES,
} from "@haywork/constants";
import { NavLink } from "react-router-dom";
import head from "lodash-es/head";
import { MailTo } from "@haywork/modules/shared";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  searchAssignment: MatchedSearchAssignmentSnapshot;
  zebra: boolean;
  selectedSearchAssignments: MatchedSearchAssignmentSnapshot[];
  onNavigateToSearchAssignment: (url: string) => void;
  onSelect: (searchAssignment: MatchedSearchAssignmentSnapshot) => void;
  onDeselect: (searchAssignment: MatchedSearchAssignmentSnapshot) => void;
};

export const RowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      searchAssignment,
      selectedSearchAssignments,
      zebra,
      onNavigateToSearchAssignment,
      onSelect,
      onDeselect,
    }) => {
      const {
        id,
        isPaid,
        offerType,
        dateTimeCreated,
        matchMailPeriod,
        linkedRelations,
      } = searchAssignment;

      const selectedIds = useMemo(
        () => selectedSearchAssignments.map((assignment) => assignment.id),
        [selectedSearchAssignments]
      );

      const isSelected = useMemo(() => {
        return selectedIds.includes(id);
      }, [selectedIds, id]);

      const relations = useMemo(() => {
        if (!linkedRelations || !linkedRelations.length)
          return <I18n value="noLinkedRelations" />;

        const render = (relation: LinkedRelation, idx: number) => {
          const { id, typeOfRelation, email, phoneMobile, phone, phoneWork } =
            relation;
          let url = null;

          switch (typeOfRelation) {
            case RelationType.ContactPerson:
              url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
              break;
            case RelationType.ContactCompany:
              url = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            case RelationType.Employee:
              url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            case RelationType.Office:
              url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            default:
              return null;
          }

          const contactData = [];
          const phones = [phoneMobile, phone, phoneWork].filter(
            (phone) => !!phone
          );

          if (!!phones.length) {
            const phone = head(phones);
            contactData.push(
              <a href={`tel:${phone}`} onClick={(e) => e.stopPropagation()}>
                {phone}
              </a>
            );
          }
          if (!!email) {
            contactData.push(<MailTo email={email} />);
          }

          return (
            <div styleName="contact" key={idx}>
              <NavLink to={url} onClick={(e) => e.stopPropagation()}>
                {relation.displayName}
              </NavLink>
              {!!contactData.length && (
                <div styleName="contact__data">
                  {contactData.map((data, idx) => (
                    <span key={idx}>
                      {idx !== 0 && <span>, </span>}
                      <span>{data}</span>
                    </span>
                  ))}
                </div>
              )}
            </div>
          );
        };

        return linkedRelations.map(render);
      }, [linkedRelations]);

      const handleCheckboxChange = useCallback(() => {
        isSelected ? onDeselect(searchAssignment) : onSelect(searchAssignment);
      }, [onSelect, onDeselect, isSelected, searchAssignment]);

      const onClickCallback = useCallback(() => {
        const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });
        onNavigateToSearchAssignment(path);
      }, [onNavigateToSearchAssignment, id]);

      return (
        <div styleName={classNames("row", { zebra })} onClick={onClickCallback}>
          <div styleName="row__checkbox" onClick={(e) => e.stopPropagation()}>
            <Input.CheckBox
              name="select-search-assignment"
              onChange={handleCheckboxChange}
              asSingleInput
              value={isSelected}
            />
          </div>
          <div styleName="row__searchers">{relations}</div>
          <div styleName="row__is-paid">
            {!!isPaid && <i className="fal fa-check" />}
          </div>
          <div styleName="row__offertype">
            <I18n prefix="offerTypeOptions" value={offerType.toString()} />
          </div>
          <div styleName="row__date">
            <FormattedDate
              value={dateTimeCreated || ""}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </div>
          <div styleName="row__frequency">
            <I18n
              prefix="matchMailPeriodOptions"
              value={matchMailPeriod.toString()}
            />
          </div>
        </div>
      );
    }
  )
);

// import {
//   LinkedRelation,
//   MatchedSearchAssignmentSnapshot,
//   RelationType
// } from "@haywork/api/kolibri";
// import {
//   EMPLOYEEROUTES,
//   OFFICESROUTES,
//   RELATIONROUTES,
//   SEARCHASSIGNMENTROUTES
// } from "@haywork/constants";
// import { Input } from "@haywork/modules/form";
// import { ResourceText } from "@haywork/modules/shared";
// import { RouteUtil } from "@haywork/util";
// import classNames from "classnames";
// import head from "lodash-es/head";
// import * as React from "react";
// import * as CSSModules from "react-css-modules";
// import { FormattedDate } from "react-intl";
// import { NavLink } from "react-router-dom";

// const styles = require("./list-item.component.scss");
// const route = RouteUtil.mapStaticRouteValues;

// interface Props {
//   searchAssignment: MatchedSearchAssignmentSnapshot;
//   zebra: boolean;
//   selectedSearchAssignments: MatchedSearchAssignmentSnapshot[];
//   onNavigateToSearchAssignment: (url: string) => void;
//   onSelect: (searchAssignment: MatchedSearchAssignmentSnapshot) => void;
//   onDeselect: (searchAssignment: MatchedSearchAssignmentSnapshot) => void;
// }

// @CSSModules(styles, { allowMultiple: true })
// export class AssignmentDetailSearchAssignmentsItemComponent extends React.PureComponent<
//   Props
// > {
//   constructor(props) {
//     super(props);

//     this.renderLinkedRelation = this.renderLinkedRelation.bind(this);
//     this.onClickHandler = this.onClickHandler.bind(this);
//     this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
//   }

//   public render() {
//     const { searchAssignment, selectedSearchAssignments } = this.props;
//     const itemStyle = classNames("item", { zebra: this.props.zebra });
//     const selectedIds = selectedSearchAssignments.map(
//       (searchAssignment) => searchAssignment.id
//     );

//     return (
//       <div styleName={itemStyle} onClick={this.onClickHandler}>
//         <div styleName="item__checkbox" onClick={(e) => e.stopPropagation()}>
//           <Input.CheckBox
//             name="select-search-assignment"
//             onChange={this.handleCheckboxChange}
//             asSingleInput
//             value={selectedIds.includes(searchAssignment.id)}
//           />
//         </div>
//         <div styleName="item__searchers">{this.renderLinkedRelations()}</div>
//         <div styleName="item__is-paid">
//           {!!searchAssignment.isPaid && <i className="fal fa-check" />}
//         </div>
//         <div styleName="item__offertype">
//           <ResourceText
//             masterKey="offerTypeOptions"
//             resourceKey={searchAssignment.offerType.toString()}
//           />
//         </div>
//         <div styleName="item__date">
//           <FormattedDate
//             value={searchAssignment.dateTimeCreated || ""}
//             day="2-digit"
//             month="2-digit"
//             year="numeric"
//           />
//         </div>
//         <div styleName="item__frequency">
//           <ResourceText
//             masterKey="matchMailPeriodOptions"
//             resourceKey={searchAssignment.matchMailPeriod.toString()}
//           />
//         </div>
//       </div>
//     );
//   }

//   private onClickHandler() {
//     const { id } = this.props.searchAssignment;
//     const url = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

//     this.props.onNavigateToSearchAssignment(url);
//   }

//   private disablePropagation(event: React.MouseEvent<HTMLAnchorElement>) {
//     event.stopPropagation();
//   }

// private renderLinkedRelations(): React.ReactElement<HTMLDivElement> {
//   const linkedRelations = this.props.searchAssignment.linkedRelations || [];

//   if (linkedRelations.length === 0) {
//     return (
//       <>
//         <ResourceText resourceKey="noLinkedRelations" />
//       </>
//     );
//   } else {
//     return <>{linkedRelations.map(this.renderLinkedRelation)}</>;
//   }
// }

// private renderLinkedRelation(
//   relation: LinkedRelation,
//   idx: number
// ): React.ReactElement<HTMLAnchorElement> {
// const {
//   id,
//   typeOfRelation,
//   email,
//   phoneMobile,
//   phone,
//   phoneWork
// } = relation;
// let url = null;

// switch (typeOfRelation) {
//   case RelationType.ContactPerson:
//     url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
//     break;
//   case RelationType.ContactCompany:
//     url = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
//     break;
//   case RelationType.Employee:
//     url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
//     break;
//   case RelationType.Office:
//     url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
//     break;
//   default:
//     return null;
// }

// const contactData = [];
// const phones = [phoneMobile, phone, phoneWork].filter((phone) => !!phone);

// if (!!phones.length) {
//   const phone = head(phones);
//   contactData.push(
//     <a href={`tel:${phone}`} onClick={this.disablePropagation}>
//       {phone}
//     </a>
//   );
// }
// if (!!email) {
//   contactData.push(
//     <a href={`mailto:${email}`} onClick={this.disablePropagation}>
//       {email}
//     </a>
//   );
// }

// return (
//   <div styleName="contact" key={idx}>
//     <NavLink to={url} onClick={this.disablePropagation}>
//       {relation.displayName}
//     </NavLink>
//     {!!contactData.length && (
//       <div styleName="contact__data">
//         {contactData.map((data, idx) => (
//           <span key={idx}>
//             {idx !== 0 && <span>, </span>}
//             <span>{data}</span>
//           </span>
//         ))}
//       </div>
//     )}
//   </div>
// );
// }

//   private handleCheckboxChange(checked: boolean) {
//     if (checked) {
//       this.props.onSelect(this.props.searchAssignment);
//     } else {
//       this.props.onDeselect(this.props.searchAssignment);
//     }
//   }
// }
