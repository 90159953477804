import {
  Account,
  Folder,
  FolderCategory,
  SaveFolderRequest,
} from "@haywork/api/mail";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetCollector,
  DropTargetSpec,
} from "react-dnd";
import { CreateFolderComponent } from "./create-folder.component";
import { FolderComponent } from "./folder.component";
import { LoadMore } from "./load-more.component";

const styles = require("./accounts.component.scss");

interface Props {
  account: Account;
  folders: ExtendedEmailFolder[];
  currentFolder: ExtendedEmailFolder;
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
  rootCanLoadmore: boolean;
  isExpanded: boolean;
  onLoadSubFolders: (
    parentFolderId: string,
    accountId: string,
    init: boolean
  ) => Promise<void>;
  onLoadFolderMessages: (
    account: Account,
    folder: ExtendedEmailFolder,
    category: FolderCategory
  ) => void;
  onMoveMessageToFolder: (
    message: EmailMessage,
    folder: Folder,
    accountId: string
  ) => void;
  onMoveFolderToFolder: (
    folder: Folder,
    targetFolder: Folder,
    accountId: string
  ) => void;
  onSaveFolder: (request: SaveFolderRequest) => Promise<void>;
  checkIfFolderEligableForDelete: (
    folderId: string,
    accountId: string
  ) => Promise<boolean>;
  onRemoveFolder: (folder: ExtendedEmailFolder, accountId: string) => void;
}
interface State {
  expanded: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AccountInstance extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.renderFolderComponent = this.renderFolderComponent.bind(this);

    this.state = {
      expanded: this.props.isExpanded,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!nextProps.isExpanded && !this.props.isExpanded) {
      this.setState({ expanded: true });
    }
  }

  public render() {
    const accountStyle = classNames("account", {
      expanded: this.state.expanded,
      "drag-over": this.props.isOver && this.props.canDrop,
    });
    const unreadCount = this.props.folders.reduce(
      (state, folder) => state + folder.unreadCount,
      0
    );

    return (
      <div styleName={accountStyle}>
        {this.props.connectDropTarget(
          <div
            styleName="account__title"
            data-cy="CY-accountTitle"
            onClick={this.onToggleClickHandler}
          >
            <div styleName="icon">
              <i className="fa fa-folder" />
            </div>
            <div styleName="title">
              {this.props.account.accountName ||
                this.props.account.emailAddress}
            </div>
            {unreadCount > 0 && (
              <div styleName="unread-count">{unreadCount}</div>
            )}
            <div styleName="toggle">
              <i className="fal fa-chevron-down" />
            </div>
          </div>
        )}
        <div styleName="account__folders">
          {this.props.folders.map(this.renderFolderComponent)}
          <CreateFolderComponent
            accountId={this.props.account.id}
            onCreate={this.props.onSaveFolder}
          />
          {this.props.rootCanLoadmore && (
            <LoadMore
              onClick={() =>
                this.props.onLoadSubFolders("", this.props.account.id, false)
              }
            />
          )}
        </div>
      </div>
    );
  }

  private renderFolderComponent(folder: ExtendedEmailFolder, idx: number) {
    if (folder.hasSubFolders) {
      return (
        <ErrorBoundary key={idx}>
          <FolderComponent
            folder={folder}
            account={this.props.account}
            currentFolder={this.props.currentFolder}
            onLoadFolderMessages={(folder, category) =>
              this.props.onLoadFolderMessages(
                this.props.account,
                folder,
                category
              )
            }
            onLoadSubFolders={(parentFolderId, init) =>
              this.props.onLoadSubFolders(
                parentFolderId,
                this.props.account.id,
                !!init
              )
            }
            onMoveMessageToFolder={(message, folder) =>
              this.props.onMoveMessageToFolder(
                message,
                folder,
                this.props.account.id
              )
            }
            onMoveFolderToFolder={(folder, targetFolder) =>
              this.props.onMoveFolderToFolder(
                folder,
                targetFolder,
                this.props.account.id
              )
            }
            onSaveFolder={this.props.onSaveFolder}
          >
            {folder.folders.map(this.renderFolderComponent)}
          </FolderComponent>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary key={idx}>
          <FolderComponent
            folder={folder}
            account={this.props.account}
            currentFolder={this.props.currentFolder}
            onLoadFolderMessages={(folder, category) =>
              this.props.onLoadFolderMessages(
                this.props.account,
                folder,
                category
              )
            }
            onMoveMessageToFolder={(message, folder) =>
              this.props.onMoveMessageToFolder(
                message,
                folder,
                this.props.account.id
              )
            }
            onMoveFolderToFolder={(folderId, folder) =>
              this.props.onMoveFolderToFolder(
                folderId,
                folder,
                this.props.account.id
              )
            }
            onSaveFolder={this.props.onSaveFolder}
            checkIfFolderEligableForDelete={(folderId) =>
              this.props.checkIfFolderEligableForDelete(
                folderId,
                this.props.account.id
              )
            }
            onRemoveFolder={(folder) =>
              this.props.onRemoveFolder(folder, this.props.account.id)
            }
          />
        </ErrorBoundary>
      );
    }
  }

  private onToggleClickHandler() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }
}

const dropSpec: DropTargetSpec<Props> = {
  drop: (props, monitor) => {
    const state: any = monitor.getItem();

    if (!!state.folder) {
      props.onMoveFolderToFolder(state.folder, null, props.account.id);
    }
  },
  canDrop: (props, monitor) => {
    const state: any = monitor.getItem();
    const { folder, message } = state;

    if (!!folder) {
      return (
        !!folder &&
        folder.category === FolderCategory.UserCreated &&
        folder.accountId === props.account.id
      );
    }

    if (!!message) {
      return message.accountId === props.account.id;
    }
  },
};

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

export const AccountComponent = DropTarget(
  "EmailMessage",
  dropSpec,
  dropCollect
)(AccountInstance);
