import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import Notes from "@haywork/modules/notes-v3";
import { ConfirmComponent } from "@haywork/modules/shared";
import { AcquisitionUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { AcquisitionObjectAssignmentEditAction } from "./actions";
import { AcquisitionAssignmentEditRouting } from "./assignment-edit.routing";
import { AcquisitionAssignmentEditScreenContainerProps } from "./assignments-edit.container";
import Presence from "@haywork/components/ui/presence";
import { RootEntityType } from "@haywork/api/event-center";

const styles = require("./assignments-edit.scss");
const route = RouteUtil.mapStaticRouteValues;
const mapRoutes = (routes: string[], id: string) =>
  routes.map((path: string) => route(path, { id }));

export type AcquisitionAssignmentEditScreenProps = {};
type Props = AcquisitionAssignmentEditScreenProps &
  AcquisitionAssignmentEditScreenContainerProps;

export const AcquistionAssignmentEditScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionObjectAssignment,
      save,
      location,
      backToDashboard,
      loading,
      hasChanges,
    }) => {
      const { id, isNew } = acquisitionObjectAssignment;
      const [routes, setRoutes] = useState(
        mapRoutes(
          [
            ACQUISITIONOBJECTROUTES.EDIT_GENERAL.URI,
            ACQUISITIONOBJECTROUTES.EDIT_OBJECT.URI,
            ACQUISITIONOBJECTROUTES.EDIT_MEDIA.URI,
          ],
          id
        )
      );
      const [backToDashboardConfirmVisible, setBackToDashboardConfirmVisible] =
        useState(false);

      const [validSubRoutes, setValidSubRoutes] = useState({
        address: false,
        media: false,
      });

      useEffect(() => {
        const validSubRoutes = AcquisitionUtil.validateSubRoutes(
          acquisitionObjectAssignment
        );
        setValidSubRoutes(validSubRoutes);
      }, [acquisitionObjectAssignment]);

      const addressRoute = classNames("item", {
        valid: validSubRoutes.address,
      });

      const mediaRoute = classNames("item", {
        valid: validSubRoutes.media,
      });

      let currentRouteIndex: number = 0;

      const getNextAndPreviousRoutes = (
        pathname?: string
      ): { nextRoute: string; previousRoute: string } => {
        currentRouteIndex = routes.reduce((state, route, idx) => {
          const path = pathname || location.pathname;
          if (new RegExp(escapeRegExp(route), "gi").test(path)) {
            return idx;
          }
          return state;
        }, -1);

        const nextRoute =
          currentRouteIndex <= routes.length
            ? routes[currentRouteIndex + 1]
            : null;
        const previousRoute =
          currentRouteIndex > 0 ? routes[currentRouteIndex - 1] : null;

        return {
          nextRoute,
          previousRoute,
        };
      };

      useEffect(() => {
        const { id } = acquisitionObjectAssignment;
        setRoutes(routes.map((r) => route(r, { id })));
      }, [location]);

      const { nextRoute, previousRoute } = useMemo(() => {
        return getNextAndPreviousRoutes();
      }, [routes]);

      const onSaveClickHandler = useCallback(
        (close: boolean = false) => {
          save(acquisitionObjectAssignment, close);
        },
        [acquisitionObjectAssignment]
      );

      const onActionClickHandler = useCallback(
        (action: AcquisitionObjectAssignmentEditAction) => {
          switch (action) {
            case AcquisitionObjectAssignmentEditAction.BackToDashboard: {
              if (hasChanges) {
                setBackToDashboardConfirmVisible(true);
                return;
              }
              backToDashboard();
              break;
            }
            case AcquisitionObjectAssignmentEditAction.SaveAndClose: {
              onSaveClickHandler(true);
              break;
            }
            default: {
              return;
            }
          }
        },
        [
          setBackToDashboardConfirmVisible,
          backToDashboard,
          hasChanges,
          acquisitionObjectAssignment,
        ]
      );

      const onBackToDashboard = useCallback(() => {
        setBackToDashboardConfirmVisible(false);
        backToDashboard();
      }, [setBackToDashboardConfirmVisible]);

      return (
        <div styleName="edit">
          <PageHeader
            title={
              acquisitionObjectAssignment.displayName
                ? acquisitionObjectAssignment.displayName
                : "acquisition.assignment.edit.title.new"
            }
            actions={
              <>
                <Presence
                  entityId={acquisitionObjectAssignment.id}
                  entityType={RootEntityType.AcquisitionObjectAssignment}
                />
                {!isNew && <Notes />}
                <Button
                  label="acquisitionObjectAssignment.edit.actions.label.save"
                  category="success"
                  disabled={false}
                  onClick={() => onSaveClickHandler()}
                />
                <Actions
                  loading={loading}
                  isNew={isNew}
                  onClick={onActionClickHandler}
                />
              </>
            }
          />

          <div styleName="edit__body">
            <AcquisitionAssignmentEditRouting
              id={acquisitionObjectAssignment.id}
            />
          </div>

          <div styleName="edit__navigation">
            <div styleName="button">
              {previousRoute && (
                <NavLink
                  to={previousRoute}
                  className="btn btn-default-alt icon-left"
                >
                  <i className="fal fa-fw fa-chevron-left" />
                  <I18n value="previousText" />
                </NavLink>
              )}
            </div>

            <div styleName="navigation">
              <div styleName="navigation__list">
                <NavLink
                  styleName={addressRoute}
                  to={route(ACQUISITIONOBJECTROUTES.EDIT_GENERAL.URI, {
                    id: acquisitionObjectAssignment.id,
                  })}
                >
                  <div styleName="index">1</div>
                  <I18n value="acquisition.assignment.edit.general" />
                </NavLink>
                <div styleName="spacer">
                  <i className="fal fa-fw fa-chevron-right" />
                </div>
                <NavLink
                  styleName={"item"}
                  to={route(ACQUISITIONOBJECTROUTES.EDIT_OBJECT.URI, {
                    id: acquisitionObjectAssignment.id,
                  })}
                >
                  <div styleName="index">2</div>
                  <I18n value="acquisition.assignment.edit.object" />
                </NavLink>
                <div styleName="spacer">
                  <i className="fal fa-fw fa-chevron-right" />
                </div>
                <NavLink
                  styleName={mediaRoute}
                  to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA.URI, {
                    id: acquisitionObjectAssignment.id,
                  })}
                >
                  <div styleName="index">3</div>
                  <I18n value="acquisition.assignment.edit.media" />
                </NavLink>
              </div>
            </div>

            <div styleName="button last">
              {nextRoute && (
                <NavLink to={nextRoute} className="btn btn-success icon-right">
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
            </div>
          </div>

          <ConfirmComponent
            visible={backToDashboardConfirmVisible}
            titleResourceKey="acquisitionObjectAssignment.backToDashboard.title"
            bodyResourceKey="acquisitionObjectAssignment.backToDashboard.body"
            onClose={() => setBackToDashboardConfirmVisible(false)}
            onConfirm={onBackToDashboard}
          />
        </div>
      );
    }
  )
);
