import * as React from "react";
import { FC, memo, useState } from "react";
import * as CSSModules from "react-css-modules";
import {
  PhotosComponent,
  FileUploadContainer,
  FileUploadIcon,
  ConfirmComponent,
} from "@haywork/modules/shared";
import {
  SortEnd,
  SortableHandle,
  SortableElement,
  SortableContainer,
} from "react-sortable-hoc";
import { AcquistionAssignmentEditMediaBrochuresScreenContainerProps } from "./assignments-edit-media-brochures.container";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";
import { REQUEST } from "@haywork/constants";
import { Redirect } from "@haywork/services";
import { noop } from "lodash-es";

import { arrayMoveImmutable } from "array-move";
const styles = require("./assignments-edit-media-brochures.scss");

export type AcquistionAssignmentEditMediaBrochuresScreenProps = {};
type Props = AcquistionAssignmentEditMediaBrochuresScreenProps &
  AcquistionAssignmentEditMediaBrochuresScreenContainerProps;

export const AcquistionAssignmentEditMediaBrochuresScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      currentComponentState,
      updateAcquisitionAssignmentObject,
      path,
      brochureStatus,
      brochureId,
    }) => {
      const [markedForDelete, setMarkedForDelete] = useState(null);
      let { brochures } = currentComponentState;
      brochures = brochures || [];

      const SortableTrigger = SortableHandle(({ filename }) => (
        <div className="preview portrait pdf">
          <div className="contents">
            <div className="filename">{filename}</div>
          </div>
        </div>
      ));

      const SortableItem = SortableElement(
        ({
          brochure,
          downloadHandler,
          editHandler,
          deleteHandler,
          idx,
          brochureStatus,
          brochureId,
        }) => {
          const props = { filename: brochure.fileName };
          const editIconStyle = classNames(
            "fal",
            brochureStatus === REQUEST.PENDING && brochureId === brochure.id
              ? "fa-spinner-third fa-spin"
              : "fa-pencil"
          );

          return (
            <div className="file-list__item">
              <div className="meta">
                {!brochure.allowToPublish && (
                  <div className="meta__item warn">
                    <I18n value="brochureWontBePublished" />
                  </div>
                )}
              </div>
              <div className="remove" onClick={() => deleteHandler(idx)}>
                <i className="fal fa-fw fa-times" />
              </div>
              <SortableTrigger {...props} />
              <div className="actions">
                <div
                  className="action btn btn-blank"
                  onClick={() => downloadHandler(idx)}
                >
                  <i className="fal fa-download" />
                </div>
                {!!brochure.isEditable && (
                  <div
                    className="action btn btn-blank"
                    onClick={() => editHandler(idx)}
                  >
                    <i className={editIconStyle} />
                  </div>
                )}
              </div>
            </div>
          );
        }
      );

      const SortableList = SortableContainer(
        ({
          brochures,
          downloadHandler,
          editHandler,
          deleteHandler,
          uploadCompleteHandler,
          brochureStatus,
          brochureId,
        }) => {
          return (
            <div className="file-list">
              <div className="container-fluid">
                <div className="upload">
                  <FileUploadContainer
                    multiple
                    icon={FileUploadIcon.Upload}
                    title="fileUploadBrochuresTitle"
                    subtitle="fileUploadBrochuresSubtitle"
                    helperText="fileUploadHelperOnlyPdf"
                    onCompleted={uploadCompleteHandler}
                    whitelist={["application/pdf", "x-pdf"]}
                  />
                </div>
                <div className="files">
                  {brochures.map((brochure, idx) => {
                    if (!brochure) return null;
                    const props = {
                      brochure,
                      downloadHandler,
                      editHandler,
                      deleteHandler,
                      idx,
                      brochureId,
                      brochureStatus,
                    };

                    return (
                      <ErrorBoundary key={`item-${idx}`}>
                        <SortableItem index={idx} {...props} />
                      </ErrorBoundary>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      );

      const onUploadCompleteHandler = (files: any[]) => {
        const brochuresArray = currentComponentState.brochures || [];
        const brochures = files
          .filter((f) => !!f)
          .map((b) => ({ ...b, allowToPublish: true }));

        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          brochures: [...brochuresArray, ...brochures],
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const onFileDownloadClickHandler = (idx: number) => {
        const item = currentComponentState.brochures[idx];
        if (!item.urlOriginal) {
          return;
        }
        window.open(item.urlOriginal, "_blank");
      };

      const onSortEndHandler = (sort: SortEnd) => {
        const brochures = arrayMoveImmutable(
          currentComponentState.brochures,
          sort.oldIndex,
          sort.newIndex
        );
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          brochures,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const onFileDeleteClickHandler = (idx: number) => {
        setMarkedForDelete(idx);
      };

      const onConfirmCloseHandler = () => {
        setMarkedForDelete(null);
      };

      const onConfirmConfirmHandler = () => {
        const brochures = currentComponentState.brochures.filter(
          (p, k) => k !== markedForDelete
        );
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          brochures,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
        setMarkedForDelete(null);
      };

      const props = {
        brochures,
        downloadHandler: onFileDownloadClickHandler,
        deleteHandler: onFileDeleteClickHandler,
        uploadCompleteHandler: onUploadCompleteHandler,
        brochureStatus,
        brochureId,
      };

      return (
        <div styleName="brochures">
          <SortableList
            axis="xy"
            useDragHandle
            editHandler={noop}
            onSortEnd={onSortEndHandler}
            {...props}
          />
          {
            <ConfirmComponent
              visible={markedForDelete !== null}
              titleResourceKey="deleteBrochureConfirmTitle"
              bodyResourceKey="deleteBrochureConfirmBody"
              onClose={onConfirmCloseHandler}
              onConfirm={onConfirmConfirmHandler}
            />
          }
        </div>
      );
    }
  )
);
