import {
  ObjectAssignment,
  RidingHall,
  UnitOfMeasurement,
  AcquisitionObjectAssignment
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText, StepComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentAgriculturalRidingHallsComponent extends React.PureComponent<
  Props
> {
  private formControls: FormControls;
  private arrayFormControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const ridingHalls: RidingHall[] = get(
      this.props.objectAssignment,
      "ridingHalls",
      []
    );
    const mappedRidingHalls = ridingHalls.map((hall) => ({
      ...hall,
      surface: get(hall, "surface.area", "")
    }));

    this.formControls = {
      ridingHalls: {
        value: mappedRidingHalls
      }
    };

    this.arrayFormControls = {
      hasCanteen: { value: false },
      buildingYear: {
        value: new Date().getFullYear(),
        onChange: (ref) => {
          if (!ref.value) {
            return { buildingYear: new Date().getFullYear() };
          }
        }
      },
      surface: { value: "" }
    };

    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const ridingHalls: RidingHall[] = get(
        this.props.objectAssignment,
        "ridingHalls",
        []
      );
      const mappedRidingHalls = ridingHalls.map((hall) => ({
        ...hall,
        surface: get(hall, "surface.area", "")
      }));

      this.formRef.update({
        ridingHalls: mappedRidingHalls
      }, true);
    }
  }

  public render() {
    return (
      <StepComponent title="horseCompanySubtype.withRidingHalls">
        <Form
          name="riding-halls"
          formControls={this.formControls}
          onChange={this.onChange}
          form={(form) => (this.formRef = form)}
          asSubForm={this.props.asSubForm}
        >
          <Input.Array name="ridingHalls" formControls={this.arrayFormControls}>
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="surface">
                    <ResourceText resourceKey="ridingHalls.label.surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="surface"
                      placeholder="ridingHalls.label.surface"
                      pretty={true}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml={true} />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="building-year">
                  <label htmlFor="buildingYear">
                    <ResourceText resourceKey="ridingHalls.label.buildingYear" />
                    <Ui.RequiredForPublish hideHint={true} />
                  </label>
                  <Input.Number
                    name="buildingYear"
                    placeholder="ridingHalls.label.buildingYear"
                    max={new Date().getFullYear() + 10}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="hasCanteen">
                    <ResourceText resourceKey="ridingHalls.label.hasCanteen" />
                  </label>
                  <Input.Switch name="hasCanteen" on={true} off={false} />
                </div>
              </div>
            </div>
          </Input.Array>
        </Form>
      </StepComponent>
    );
  }

  private onChange(values: FormReturnValue) {
    const ridingHalls: RidingHall[] = values.ridingHalls.map((hall) => {
      return {
        ...hall,
        surface: {
          area: hall.surface,
          unitOfMeasurement: UnitOfMeasurement.Meter
        }
      };
    });

    const updatedObjectAssignment = {
      ...this.props.objectAssignment,
      ridingHalls
    };

    this.props.onChange(updatedObjectAssignment);
  }
}
