import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";

const notesSelector = (state: AppState) => state.notesV3.notes;
const parentIdSelector = (state: AppState) => state.notesV3.parentId;

export const notes = createSelector(
  parentIdSelector,
  notesSelector,
  (parentId, notes) => notes.filter((note) => note.parentId === parentId)
);

export const pinnedNotes = (count = 3) =>
  createSelector(parentIdSelector, notesSelector, (parentId, notes) =>
    notes
      .filter((note) => note.parentId === parentId && note.isImportant)
      .slice(0, count)
  );
