import { AppClient } from "./app-clients";
import { Reviews, CreateReviewRequest } from "./reviews";
import { Permissions } from "./permissions";
import { Widgets } from "./widgets";

export { CreateReviewRequest };
export const AuthorizationThunks = {
  AppClient,
  Reviews,
  Permissions,
  Widgets
};
