import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";

import { AppState } from "@haywork/stores";

interface ComponentProps extends RouteProps {
  feature: string;
  realEstateAgency?: string;
  featureComponent?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  featureRender?: (props: RouteComponentProps<any>) => React.ReactNode;
}
interface StateProps {
  features: string[];
}

type Props = StateProps & ComponentProps;

const FeatureRouteComponent = (props: Props) => {
  const {
    location,
    component,
    render,
    children,
    path,
    exact,
    strict,
    features,
    featureComponent,
    featureRender,
    feature,
  } = props;

  if (features.indexOf(feature) === -1) {
    return (
      <Route
        location={location}
        component={featureComponent}
        render={featureRender}
        children={children}
        path={path}
        exact={exact}
        strict={strict}
      />
    );
  }

  return (
    <Route
      location={location}
      component={component}
      render={render}
      children={children}
      path={path}
      exact={exact}
      strict={strict}
    />
  );
};

const mapStateToProps = <StateProps, ComponentProps>(state: AppState) => {
  const { features } = state.appSettings;

  return {
    features,
  };
};

export const FeatureRoute = connect<StateProps, {}, ComponentProps>(
  mapStateToProps,
  {}
)(FeatureRouteComponent);
