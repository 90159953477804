import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

import { AssignmentEditMarketingContainerProps } from "@haywork/modules/assignment";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";

import { AssignmentEditMarketingRouting } from "./edit-marketing.routing";
import { RealEstateGroup } from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";

const styles = require("./edit-marketing.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentEditMarketingComponentProps {}
interface State {
  mlsGroups: string[];
}
type Props = AssignmentEditMarketingComponentProps &
  AssignmentEditMarketingContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingComponent extends React.Component<
  Props,
  State
> {
  public render() {
    const { id } = this.props.objectAssignment;

    return (
      <div styleName="marketing" id="scroll-to-top">
        <div styleName="marketing__body">
          <div className="container-fluid">
            <AssignmentEditMarketingRouting id={id} />
          </div>
        </div>
        <div styleName="marketing__sidebar">
          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_PHOTOS.URI,
                id
              )
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_PHOTOS.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-image" />
              <ResourceText resourceKey="assignmentMediaLinkPhotos" />
            </div>
          </NavLink>
          <NavLink
            onClick={() =>
              this.props.navigate(ASSIGNMENTROUTES.EDIT_MARKETING_TEXT.URI, id)
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_TEXT.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section" data-cy="CY-marketingText">
              <i className="fal fa-align-left" />
              <ResourceText resourceKey="assignmentMediaLinkText" />
            </div>
          </NavLink>
          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_PARTNERS.URI,
                id
              )
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_PARTNERS.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-cloud" />
              <ResourceText resourceKey="assignmentMediaLinkPartners" />
            </div>
          </NavLink>

          {!!this.props.mlsGroups?.length && (
            <NavLink
              onClick={() =>
                this.props.navigate(ASSIGNMENTROUTES.EDIT_MARKETING_MLS.URI, id)
              }
              to={route(ASSIGNMENTROUTES.EDIT_MARKETING_MLS.URI, { id })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-exchange" />
                <ResourceText resourceKey="assignmentMediaLinkMls" />
              </div>
            </NavLink>
          )}

          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_WEBSITE.URI,
                id
              )
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_WEBSITE.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <div styleName="housenet" />
              <ResourceText resourceKey="assignmentMediaLinkWebsite" />
            </div>
          </NavLink>
          <NavLink
            onClick={() =>
              this.props.navigate(ASSIGNMENTROUTES.EDIT_MARKETING_MAPS.URI, id)
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_MAPS.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-map" />
              <ResourceText resourceKey="assignmentMediaLinkMaps" />
            </div>
          </NavLink>
          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_VIDEOS.URI,
                id
              )
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_VIDEOS.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-video" />
              <ResourceText resourceKey="assignmentMediaLinkVideos" />
            </div>
          </NavLink>
          {/* TODO sprint 21 <NavLink to={ route(ASSIGNMENTROUTES.EDIT_MARKETING_VIRTUAL_VIDEOS.URI, { id }) } activeClassName="active" >
          <div styleName="section">
            <i className="fal fa-fw fa-street-view" />
            <ResourceText resourceKey="assignmentMediaLink360Videos" />
          </div>
        </NavLink> */}
          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_PRESENTATION.URI,
                id
              )
            }
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_PRESENTATION.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-book" />
              <ResourceText resourceKey="assignmentMediaLinkPresentation" />
            </div>
          </NavLink>
          {this.props.objectAssignment.realEstateGroup !==
            RealEstateGroup.Agricultural && (
            <NavLink
              onClick={() =>
                this.props.navigate(
                  ASSIGNMENTROUTES.EDIT_MARKETING_OPENHOUSE.URI,
                  id
                )
              }
              to={route(ASSIGNMENTROUTES.EDIT_MARKETING_OPENHOUSE.URI, { id })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-home" />
                <ResourceText resourceKey="assignmentMediaLinkOpenhouse" />
              </div>
            </NavLink>
          )}
          <NavLink
            onClick={() =>
              this.props.navigate(
                ASSIGNMENTROUTES.EDIT_MARKETING_BUDGET.URI,
                id
              )
            }
            className="hidden-xs"
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_BUDGET.URI, { id })}
            activeClassName="active"
          >
            <div styleName="section">
              <i className="fal fa-euro-sign" />
              <ResourceText resourceKey="assignmentMediaLinkBudget" />
            </div>
          </NavLink>
        </div>
      </div>
    );
  }
}
