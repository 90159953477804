import { Dispatch } from ".";

import { AppState, AssignmentSingleActions } from "@haywork/stores";
import { BrochuresClient } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";

const parseRequest = new ParseRequest();

// const createNewBrochure = (objectAssignmentId: string) => {
//   return async (dispatch: Dispatch<any>, getState: () => AppState) => {
//     dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.PENDING));

//     const state = getState();
//     const { host } = state.appSettings;
//     const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

//     const Brochures = new BrochuresClient(host);

//     try {
//       const result = await parseRequest.response(
//         Brochures.createNewLink({ objectAssignmentId }, realEstateAgencyId)
//       );

//       dispatch(AssignmentSingleActions.setBrochureLink(result));
//     } catch (error) {
//       dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.ERROR));
//       throw error;
//     }
//   };
// };

const createNewBrochure = (objectAssignmentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Brochures = new BrochuresClient(host);

    try {
      const result = await parseRequest.response(
        Brochures.createNewLink({ objectAssignmentId }, realEstateAgencyId)
      );

      dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.SUCCESS));
      return result;
    } catch (error) {
      dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

// const editBrochure = (objectAssignmentId: string, brochureId: string) => {
//   return async (dispatch: Dispatch<any>, getState: () => AppState) => {
//     dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.PENDING, brochureId));

//     const state = getState();
//     const { host } = state.appSettings;
//     const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

//     const Brochures = new BrochuresClient(host);

//     try {
//       const result = await parseRequest.response(
//         Brochures.createEditLink({ objectAssignmentId, brochureId }, realEstateAgencyId)
//       );

//       dispatch(AssignmentSingleActions.setBrochureLink(result));
//     } catch (error) {
//       dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.ERROR));
//       throw error;
//     }
//   };
// };

const editBrochure = (objectAssignmentId: string, brochureId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AssignmentSingleActions.setBrochureStatus(REQUEST.PENDING, brochureId)
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Brochures = new BrochuresClient(host);

    try {
      const result = await parseRequest.response(
        Brochures.createEditLink(
          { objectAssignmentId, brochureId },
          realEstateAgencyId
        )
      );

      dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.SUCCESS));
      return result;
    } catch (error) {
      dispatch(AssignmentSingleActions.setBrochureStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export const BrochureThunks = {
  createNewBrochure,
  editBrochure
};
