import {
  AddressDetail,
  CountryOption,
  LocationPlaceOption,
  ObjectAssignment,
} from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AppState,
  EditableActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AssignmentEditAddressComponent,
  AssignmentEditAddressComponentProps,
} from "../components/edit-address/edit-address.component";

type StateProps = {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  locationPlaces: LocationPlaceOption[];
  countries: CountryOption[];
  countryIso2: string;
  sublocalities: AddressDetail[];
  enableHideAddressForObjectAssignmentsOption: boolean;
  enableHideHouseNumberForObjectAssignmentsOption: boolean;
  culture: string;
};
type DispatchProps = {
  searchSublocalities: (id: number) => any;
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  setCaller: (caller: string, path: string) => void;
  mapValuesToObjectAssignment: (
    objectAssignment: ObjectAssignment
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditAddressComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;
  const {
    enableHideAddressForObjectAssignmentsOption,
    enableHideHouseNumberForObjectAssignmentsOption,
  } = state.company.settings;
  const { culture } = state.main;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    locationPlaces: state.main.mastertable.kolibri.locationPlaces,
    countries: state.main.mastertable.kolibri.countries,
    countryIso2: "NL", // work around, default country needs to be selectable in settings.
    sublocalities: currentComponentState.sublocalities,
    enableHideAddressForObjectAssignmentsOption,
    enableHideHouseNumberForObjectAssignmentsOption,
    culture,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditAddressComponentProps
> = (dispatch) => ({
  searchSublocalities: (id: number) =>
    dispatch(AssignmentThunks.searchSublocalities(id)),
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
  setCaller: (caller: string, path: string) =>
    dispatch(EditableActions.setCaller({ caller, path })),
  mapValuesToObjectAssignment: (objectAssignment: ObjectAssignment) =>
    dispatch(AssignmentThunks.mapValuesToObjectAssignment(objectAssignment)),
});

export type AssignmentEditAddressContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const AssignmentEditAddressContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(AssignmentEditAddressComponent)
);
