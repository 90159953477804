import {
  ActiveFilter,
  DefaultFolderTree,
  DefaultFolderTreeCategory,
  DefaultFolderTreesClient,
  FolderTree,
  FolderTreesClient,
  SortOrder,
} from "@haywork/api/kolibri";
import { DOSSIERFOLDERROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  DossierActions,
  EditableActions,
  LayoutActions,
  SettingsActions,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { FolderTreeUtil } from "@haywork/util/folder-tree";
import { Dispatch, EditableThunks } from ".";

export enum FolderTreeEntityType {
  ContactPerson = "ContactPerson",
  ContactCompany = "ContactCompany",
  ObjectAssignment = "ObjectAssignment",
  ObjectTypeAssignment = "ObjectTypeAssignment",
  ProjectAssignment = "ProjectAssignment",
  AcquisitionAssignment = "AcquisitionAssignment",
  AcquisitionObjectAssignment = "AcquisitionObjectAssignment",
  RealEstateAgency = "RealEstateAgency",
  Employee = "Employee",
  Office = "Office",
}

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const searchDefaultFolderTrees = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const DefaultFolderTrees = new DefaultFolderTreesClient(host);

    try {
      const folderTrees = await parseRequest
        .response(
          DefaultFolderTrees.search(
            {
              filterByActive: ActiveFilter.ActiveOnly,
              order: SortOrder.Ascending,
              skip: 0,
              take: 100,
            },
            realEstateAgencyId
          )
        )
        .then((response) => response.results);

      dispatch(SettingsActions.FolderTrees.setDefaultFolderTrees(folderTrees));
    } catch (error) {
      throw error;
    }
  };
};

const defineNewDefaultFolderTree = (category: DefaultFolderTreeCategory) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;
    const DefaultFolderTrees = new DefaultFolderTreesClient(host);

    try {
      let defaultFolderTree = await parseRequest
        .response(
          DefaultFolderTrees.defineNew(
            { category, culture },
            realEstateAgencyId
          )
        )
        .then((response) => response.defaultFolderTree);

      defaultFolderTree = {
        ...defaultFolderTree,
        category,
      };

      defaultFolderTree = await parseRequest
        .response(
          DefaultFolderTrees.save({ defaultFolderTree }, realEstateAgencyId)
        )
        .then((response) => response.defaultFolderTree);

      dispatch(searchDefaultFolderTrees());
      return defaultFolderTree;
    } catch (error) {
      throw error;
    }
  };
};

const readDefaultFolderTree = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const DefaultFolderTrees = new DefaultFolderTreesClient(host);

    try {
      dispatch(
        SettingsActions.FolderTrees.setSingleDefaultFolderTreeStatus(
          REQUEST.PENDING
        )
      );

      const path = route(DOSSIERFOLDERROUTES.DETAIL.URI, {
        id,
      });
      let componentState = null;

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.DOSSIERFOLDERS.ICON,
          componentState,
          path,
          title: "...",
          entityType: null,
          entityId: id,
        })
      );

      const defaultFolderTree = await parseRequest.response(
        DefaultFolderTrees.read(id, realEstateAgencyId).then(
          (response) => response.defaultFolderTree
        )
      );

      componentState = defaultFolderTree;

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(
        SettingsActions.FolderTrees.setSingleDefaultFolderTree(
          defaultFolderTree
        )
      );

      return defaultFolderTree;
    } catch (error) {
      dispatch(
        SettingsActions.FolderTrees.setSingleDefaultFolderTreeStatus(
          REQUEST.ERROR
        )
      );
      throw error;
    }
  };
};

const saveDefaultFolderTree = (defaultFolderTree: DefaultFolderTree) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;
    const DefaultFolderTrees = new DefaultFolderTreesClient(host);

    try {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );

      const savedDefaultFolderTree = await parseRequest.response(
        DefaultFolderTrees.save(
          { defaultFolderTree, culture },
          realEstateAgencyId
        ).then((response) => response.defaultFolderTree)
      );
      const path = route(DOSSIERFOLDERROUTES.DETAIL.URI, {
        id: savedDefaultFolderTree.id,
      });

      dispatch(EditableThunks.remove(path));
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const fetchFolderTree = (
  parentId: string,
  entityType: FolderTreeEntityType,
  id?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;
    const FolderTrees = new FolderTreesClient(host);

    try {
      let folderTree: FolderTree;
      if (!id) {
        folderTree = await parseRequest.response(
          FolderTrees.defineNew({ parentId, culture }, realEstateAgencyId).then(
            (response) => response.folderTree
          )
        );
        folderTree = await parseRequest.response(
          FolderTrees.save({ folderTree, culture }, realEstateAgencyId).then(
            (response) => response.folderTree
          )
        );

        dispatch(
          DossierActions.setEntityFolderTreeId(
            folderTree.id,
            entityType,
            parentId
          )
        );
      } else {
        folderTree = await parseRequest.response(
          FolderTrees.read(culture, id, realEstateAgencyId).then(
            (response) => response.folderTree
          )
        );
      }

      folderTree = {
        ...folderTree,
        rootFolderTree: FolderTreeUtil.sortRootFiles(folderTree.rootFolderTree),
      };

      return folderTree;
    } catch (error) {
      throw error;
    }
  };
};

const updateFolderTree = (folderTree: FolderTree) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;
    const FolderTrees = new FolderTreesClient(host);

    try {
      const updatedFolderTree = await parseRequest.response(
        FolderTrees.save({ folderTree, culture }, realEstateAgencyId).then(
          (response) => response.folderTree
        )
      );

      return updatedFolderTree;
    } catch (error) {
      throw error;
    }
  };
};

export const FolderTreesThunk = {
  searchDefaultFolderTrees,
  defineNewDefaultFolderTree,
  readDefaultFolderTree,
  saveDefaultFolderTree,
  fetchFolderTree,
  updateFolderTree,
};
