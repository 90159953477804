import { Action } from "redux";

import * as ActionType from "./app-settings.types";
import { REDUX } from "@haywork/constants";

export interface AppSettingsState {
  host: string;
  emailHost: string;
  eventCenterHost: string;
  version: string;
  environment: string;
  cloudUrl: string;
  usersnapApiKey: string;
  oidcRedirectUri: string;
  oidcUri: string;
  oidcScope: string;
  apiVersion: string;
  emailApiVersion: string;
  features: string[];
  voipHost: string;
  voipApiVersion: string;
  authorizationHost: string;
  blobsHost: string;
  mlsHost: string;
  mlsApiVersion: string;
  reportsHost: string;
  reportsApiVersion: string;
  statisticsHost: string;
  statisticsApiVersion: string;
  ITHitKey: string;
  wootric: {
    ACCOUNT_TOKEN: string;
    SURVEY_IMMEDIATELY: boolean;
    NO_SURVEYED_COOKIE: boolean;
  };
}

const INITIAL_STATE: AppSettingsState = {
  host: undefined,
  emailHost: undefined,
  eventCenterHost: undefined,
  version: undefined,
  environment: undefined,
  cloudUrl: undefined,
  usersnapApiKey: undefined,
  oidcRedirectUri: undefined,
  oidcUri: undefined,
  oidcScope: undefined,
  apiVersion: undefined,
  emailApiVersion: undefined,
  features: [],
  voipHost: undefined,
  voipApiVersion: undefined,
  authorizationHost: undefined,
  blobsHost: undefined,
  mlsHost: undefined,
  mlsApiVersion: undefined,
  reportsHost: undefined,
  reportsApiVersion: undefined,
  statisticsHost: undefined,
  statisticsApiVersion: undefined,
  ITHitKey: undefined,
  wootric: null,
};

export const appSettingsReducer = (
  state: AppSettingsState = INITIAL_STATE,
  action: Action
): AppSettingsState => {
  switch (action.type) {
    case REDUX.APPSETTINGS.SET_SETTINGS: {
      const {
        host,
        emailHost,
        eventCenterHost,
        version,
        environment,
        cloudUrl,
        usersnapApiKey,
        oidcRedirectUri,
        oidcUri,
        oidcScope,
        apiVersion,
        emailApiVersion,
        features,
        voipApiVersion,
        voipHost,
        authorizationHost,
        blobsHost,
        mlsHost,
        mlsApiVersion,
        ITHitKey,
        wootric,
        reportsHost,
        reportsApiVersion,
        statisticsHost,
        statisticsApiVersion,
      } = <ActionType.Settings>action;

      return {
        ...state,
        host,
        emailHost,
        eventCenterHost,
        version,
        environment,
        cloudUrl,
        usersnapApiKey,
        oidcRedirectUri,
        oidcUri,
        oidcScope,
        apiVersion,
        emailApiVersion,
        features,
        voipApiVersion,
        voipHost,
        authorizationHost,
        blobsHost,
        mlsHost,
        mlsApiVersion,
        ITHitKey,
        wootric,
        reportsHost,
        reportsApiVersion,
        statisticsHost,
        statisticsApiVersion,
      };
    }
    default: {
      return state;
    }
  }
};
