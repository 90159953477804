import {
  AcquisitionObjectAssignment,
  AgriculturalSubtypeOther,
  ApartmentCharacteristic,
  ApartmentSort,
  AtticOption,
  BeverageHospitalitySectorType,
  CattleFarmingSubtype,
  ConstructionPeriod,
  FastfoodHospitalitySectorType,
  Floor,
  FloorType,
  Furnishing,
  HorseCompanySubtype,
  HorticulturalCompanyType,
  HotelHospitalitySectorType,
  HouseCharacteristic,
  HouseSort,
  HouseType,
  InvestmentType,
  LandPropertySubType,
  LeisureType,
  ListingType,
  ObjectAssignment,
  OfferType,
  ParkingType,
  PhotoBlob,
  PigCompanyType,
  PoultryFarmingSubtype,
  RealEstateGroup,
  ResidentialSubtypeOther,
  RestaurantHospitalitySectorType,
  SearchAssignment,
  SearchAssignmentFacilityType,
  SearchAssignmentPeculiarityType,
  SocialPropertyType,
  SoilType,
  Space,
  TranslatedText,
  TypePART,
} from "@haywork/api/kolibri";
import {
  Attachment,
  AttachmentType,
  FacilitySearchType,
  FileType,
  LocationPlaceType,
  OtherSearchType,
  PropertyType,
  PropertyTypeSubFilter,
  RealEstateProperty,
  RealEstatePropertyFloorsFloor,
  RealEstatePropertySearchItem,
  SearchRealEstatePropertyRequest,
  StatusType,
} from "@haywork/api/mls";
import { FilterConfig } from "@haywork/components/ui/list";
import {
  COMMON,
  MLS_APARTMENTCHARACTERISTIC,
  MLS_APARTMENTSORT,
  MLS_BALCONY_TYPES,
  MLS_BATHROOM_FACILITY_TYPES,
  MLS_CERTIFICATION_TYPES,
  MLS_CONSTRUCTION_PERIOD,
  MLS_CULTURES,
  MLS_ENERGY_CLASS,
  MLS_FLOOR_TYPE,
  MLS_FURNITURE_TYPES,
  MLS_GARAGE_FACILITY_TYPE,
  MLS_GARAGE_TYPE,
  MLS_GARDEN_QUALITY_TYPE,
  MLS_GARDEN_TYPE,
  MLS_HOUSECHARACTERISTIC,
  MLS_HOUSESORT,
  MLS_HOUSETYPE,
  MLS_ISOLATION_TYPES,
  MLS_KITCHEN_FACILITY_TYPES,
  MLS_KITCHEN_TYPES,
  MLS_LIVING_ROOM_TYPES,
  MLS_LOCATION_PLACES,
  MLS_MAINTENANCE_TYPES,
  MLS_MAP_AGRICULTURALSUBTYPEOTHER,
  MLS_MAP_APARTMENTCHARACTERISTIC,
  MLS_MAP_APARTMENTSORT,
  MLS_MAP_BEVERAGEHOSPITALITYSECTORTYPE,
  MLS_MAP_CATTLEFARMINGSUBTYPE,
  MLS_MAP_FASTFOODHOSPITALITYSECTORTYPE,
  MLS_MAP_HORSECOMPANYSUBTYPE,
  MLS_MAP_HORTICULTURALCOMPANYTYPE,
  MLS_MAP_HOTELHOSPITALITYSECTORTYPE,
  MLS_MAP_HOUSECHARACTERISTIC,
  MLS_MAP_HOUSESORT,
  MLS_MAP_HOUSETYPE,
  MLS_MAP_INVESTMENTTYPE,
  MLS_MAP_LANDPROPERTYSUBTYPE,
  MLS_MAP_LEISURETYPE,
  MLS_MAP_LISTINGTYPES,
  MLS_MAP_LOCATIONPLACES,
  MLS_MAP_ORIENTATION,
  MLS_MAP_PARKINGTYPE,
  MLS_MAP_PIGCOMPANYTYPE,
  MLS_MAP_POULTRYFARMINGSUBTYPE,
  MLS_MAP_RESIDENTIALSUBTYPEOTHER,
  MLS_MAP_RESTAURANTHOSPITALITYSECTORTYPE,
  MLS_MAP_SEARCHASSIGNMENTFACILITYTYPE,
  MLS_MAP_SOCIALPROPERTYTYPE,
  MLS_MAP_SOILTYPE,
  MLS_ORIENTATION_TYPE,
  MLS_PARKINGTYPE,
  MLS_PER_UNIT_SIZE,
  MLS_POINTS_OF_INTEREST,
  MLS_PURCHASE_CONDITION,
  MLS_RENT_PRICE_TYPE,
  MLS_RENT_SPECIFICATION,
  MLS_ROOM_TYPES,
  MLS_TYPEALV,
  MLS_TYPEALVOTHER,
  MLS_TYPEBOG,
  MLS_TYPEPARTOTHER,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { FilterType } from "@haywork/enum/list-filter-types";
import {
  RealEstatePropertyValue,
  realEstatePropertyValueGroups,
} from "@haywork/modules/mls/helpers";
import chunk from "lodash-es/chunk";
import head from "lodash-es/head";
import isArray from "lodash-es/isArray";
import isNumber from "lodash-es/isNumber";
import isString from "lodash-es/isString";
import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { IntlShape } from "react-intl";
import { v4 as uuid } from "uuid";
import { EmailAssignment } from "./email-v2";

type MappedPropertyTypes = {
  agriculturalSubtypeOther?: AgriculturalSubtypeOther;
  apartmentCharacteristic?: ApartmentCharacteristic;
  apartmentSort?: ApartmentSort;
  houseCharacteristic?: HouseCharacteristic;
  houseSort?: HouseSort;
  houseType?: HouseType;
  landPropertySubType?: LandPropertySubType;
  listingType: ListingType;

  parkingType?: ParkingType;
  residentialSubtypeOther?: ResidentialSubtypeOther;
  cattleFarmingSubtype?: CattleFarmingSubtype;
  poultryFarmingSubtype?: PoultryFarmingSubtype;
  horseCompanySubtype?: HorseCompanySubtype;
  horticulturalCompanyType?: HorticulturalCompanyType;
  pigCompanyType?: PigCompanyType;
  soilType?: SoilType;
  beverageHospitalityTypes?: BeverageHospitalitySectorType[];
  restaurantHospitalitySectorTypes?: RestaurantHospitalitySectorType[];
  hotelHospitalitySectorTypes?: HotelHospitalitySectorType[];
  fastfoodHospitalitySectorTypes?: FastfoodHospitalitySectorType[];
  leisureType?: LeisureType;
  socialPropertyType?: SocialPropertyType;
  investmentType?: InvestmentType;
};
export class MlsUtil {
  public static mapPropertySearchItemToEmailAssignment(
    assignment: RealEstatePropertySearchItem,
    intl: IntlShape
  ): EmailAssignment {
    const {
      imageUrls,
      address,
      type,
      areaTotals,
      counts,
      financial,
      facilities,
      bundleId,
    } = assignment;

    const id = bundleId;
    const image = head(imageUrls || []);
    const housenumber = [address?.houseNumber, address?.houseNumberAddendum]
      .filter((d) => !!d)
      .join("-");
    const addressLine1 = [address?.street?.name, housenumber]
      .filter((d) => !!d)
      .join(" ");
    const addressLine2 = [address?.postalCode, address?.locality?.name]
      .filter((d) => !!d)
      .join(" ");
    const salePrice = financial?.purchasePrice || financial?.transactionPrice;
    const price = !!financial?.rentPrice
      ? intl.formatNumber(financial?.rentPrice, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : !!salePrice
      ? intl.formatNumber(salePrice, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : null;
    const priceCondition = !!financial?.rentPrice
      ? intl.formatMessage({ id: "perMonth", defaultMessage: "" })
      : !!financial?.purchaseConditionType
      ? intl.formatMessage({
          id: `purchaseConditionTypeOptions.${financial?.purchaseConditionType.toString()}`,
          defaultMessage: "",
        })
      : null;
    const numberOfRooms = !counts?.numberOfRooms
      ? null
      : intl.formatMessage(
          { id: "roomCount", defaultMessage: "" },
          { rooms: counts?.numberOfRooms }
        );
    const numberOfBedrooms = !counts?.numberOfBedrooms
      ? null
      : intl.formatMessage(
          { id: "bedroomCount", defaultMessage: "" },
          { bedrooms: counts?.numberOfBedrooms }
        );
    const numberOfBathrooms = !counts?.numberOfBathrooms
      ? null
      : intl.formatMessage(
          { id: "bathroomCount", defaultMessage: "" },
          { bathrooms: counts?.numberOfBathrooms }
        );
    const propertyTypes = (type.types || []).map((t) =>
      intl.formatMessage({
        id: `propertyTypeOptions.${t.toString()}`,
        defaultMessage: "",
      })
    );
    const insideArea = !areaTotals?.effectiveArea
      ? undefined
      : intl.formatMessage(
          { id: "squareMetersValue", defaultMessage: "" },
          { value: areaTotals?.effectiveArea || 0 }
        );
    const outsideArea = !areaTotals?.outsideArea
      ? undefined
      : intl.formatMessage(
          { id: "squareMetersValue", defaultMessage: "" },
          { value: areaTotals?.outsideArea || 0 }
        );
    const otherFacilities = (facilities?.otherFacilities || [])
      .filter(
        (d) =>
          !!d &&
          ![
            OtherSearchType.IS_EVEN_HOUSE_NUMBER,
            OtherSearchType.IS_ODD_HOUSE_NUMBER,
          ].includes(d)
      )
      .map((f) =>
        intl.formatMessage({
          id: `otherSearchTypeOptions.${f.toString()}`,
          defaultMessage: "",
        })
      );

    return {
      id,
      image,
      addressLine1,
      addressLine2,
      price,
      priceCondition,
      numberOfRooms,
      numberOfBedrooms,
      numberOfBathrooms,
      facilities: otherFacilities,
      propertyTypes,
      insideArea,
      outsideArea,
    };
  }

  public static createPrintTemplate(
    assignments: RealEstateProperty[],
    realtorName: string,
    intl: IntlShape
  ) {
    const styles: Record<string, React.CSSProperties> = {
      wrapper: {
        fontFamily: `"Open Sans", sans-serif`,
        fontSize: 16,
        color: "#000000",
        pageBreakBefore: "always",
        lineHeight: 1.4,
      },
      heading1: {
        fontFamily: `"Roboto", sans-serif`,
        fontSize: 24,
        marginTop: 0,
        marginBottom: 32,
      },
      heading2: {
        fontFamily: `"Roboto", sans-serif`,
        fontSize: 20,
      },
      heading3: {
        fontFamily: `"Roboto", sans-serif`,
        fontSize: 16,
        marginTop: 0,
        marginBottom: 12,
      },
      group: {
        breakInside: "avoid",
        breakBefore: "auto",
        breakAfter: "auto",
        marginTop: 24,
      },
      row: {
        paddingTop: 8,
        paddingBottom: 8,
        borderTop: "1px solid #e6e6e6",
        display: "flex",
        alignItems: "center",
        pageBreakInside: "avoid",
      },
      row__label: {
        color: "#a6a6a6",
        flex: "none",
        width: 320,
      },
      row__value: {
        flex: 1,
      },
      photos: {
        display: "flex",
        marginBottom: 8,
        breakInside: "avoid",
        breakBefore: "auto",
        breakAfter: "auto",
      },
      photo__wrapper: {
        marginRight: 8,
      },
      photo: {
        display: "block",
        margin: 0,
        height: 120,
        width: "auto",
      },
      meta__withphoto: {
        display: "flex",
      },
      meta__photo: {
        paddingRight: 16,
      },
      metaHeading1: {
        fontSize: 16,
      },
      metaHeading2: {
        fontSize: 18,
        fontWeight: 600,
      },
      metaHeading3: {
        fontSize: 16,
        color: Colors.MediumGray.toString(),
      },
    };

    const GroupRow = (props: {
      item: RealEstatePropertyValue;
      depth?: number;
    }) => {
      if (!props?.item) return null;

      const labelStyle = !props?.depth
        ? null
        : ({ paddingLeft: (props?.depth || 1) * 10 } as React.CSSProperties);

      return isString(props.item.value) ? (
        <div style={styles.row}>
          <div style={{ ...styles.row__label, ...labelStyle }}>
            {props.item.label}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: props.item.value }}
            style={styles.row__value}
          />
        </div>
      ) : (
        <>
          <div style={styles.row}>
            <div
              style={{ ...styles.row__label, ...labelStyle, color: "#000000" }}
            >
              {props.item.label}
            </div>
            <div style={styles.row__value} />
          </div>
          {props.item.value.map((item, idx) => (
            <GroupRow item={item} depth={(props?.depth || 0) + 1} key={idx} />
          ))}
        </>
      );
    };

    const Row = (props: { assignment: RealEstateProperty }) => {
      if (!props.assignment) return null;
      const groups = realEstatePropertyValueGroups(props.assignment);
      const { attachments, location } = props.assignment;

      const streetnameArray = location?.address?.streetname || [];
      const cityNameArray = location?.address?.cityName || [];

      const houseNumber = location?.address?.houseNumber;
      const houseNumberPostfix = location?.address?.houseNumberPostfix;
      const postalCode = location?.address?.postalCode;
      const streetname = streetnameArray.find(
        (item) => item.language === "nl-NL"
      )?.value;
      const cityName = cityNameArray.find(
        (item) => item.language === "nl-NL"
      )?.value;

      const adressLine1 = [streetname, houseNumber, houseNumberPostfix]
        .filter((d) => !!d)
        .join(" ");
      const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

      const displayName = [adressLine1, adressLine2]
        .filter((d) => !!d)
        .join(", ");

      const printableImageTypes = [
        FileType.BMP,
        FileType.GIF,
        FileType.JPG,
        FileType.PNG,
      ];
      const images = (attachments || [])
        .filter(
          (attachment) =>
            attachment.type === AttachmentType.PHOTO &&
            printableImageTypes.includes(attachment.fileType)
        )
        .map(
          (attachment) =>
            attachment.urlNormalizedFile ||
            attachment.urlMediumFile ||
            attachment.urlThumbFile ||
            attachment.urlOriginalFile
        )
        .filter((d) => !!d);

      const adText = (props.assignment?.descriptions?.adText || []).find(
        (text) => text.language === "nl-NL"
      );

      const typeTitle = () => {
        if (!props.assignment?.type || !props.assignment?.offer) return null;
        const segments = ["mlsTypeTitle"];

        switch (true) {
          case props.assignment?.type?.isResidential: {
            segments.push("residential");
            break;
          }
          case props.assignment?.type?.isAgricultural: {
            segments.push("agricultural");
            break;
          }
          case props.assignment?.type?.isCommercial: {
            segments.push("commercial");
            break;
          }
          default: {
            break;
          }
        }

        switch (true) {
          case !!props.assignment?.offer?.isAcquisition: {
            segments.push("acquisition");
          }
          case !!props.assignment?.offer?.isForSale: {
            segments.push("forSale");
            break;
          }
          case !!props.assignment?.offer?.isForRent: {
            segments.push("forRent");
            break;
          }
          default: {
            break;
          }
        }

        const id = segments.join(".");
        if (!id) return null;

        return intl.formatMessage({ id, defaultMessage: id });
      };

      const propertyType = () => {
        const propertyTypes = props.assignment?.type?.propertyTypes || [];
        const secondaryPropertyTypes =
          props.assignment?.type?.secondaryPropertyTypes || [];

        const segments: string[] = [];
        propertyTypes.forEach((propertyType) => {
          segments.push(
            intl.formatMessage({
              id: `propertyTypeOptions.${propertyType.toString()}`,
              defaultMessage: propertyType.toString(),
            })
          );
        });
        secondaryPropertyTypes.forEach((propertyType) => {
          segments.push(
            intl.formatMessage({
              id: `propertyTypeOptions.${propertyType.toString()}`,
              defaultMessage: propertyType.toString(),
            })
          );
        });

        return segments
          .filter((d) => !!d)
          .map((segment, idx) => (idx === 0 ? segment : segment.toLowerCase()))
          .join(", ");
      };

      const metaTypeTitle = typeTitle();
      const propertyTypeTitle = propertyType();

      const metaData = (
        <div>
          {!!metaTypeTitle && (
            <div style={styles.metaHeading1}>{metaTypeTitle}</div>
          )}
          {!!displayName && (
            <div style={styles.metaHeading2}>{displayName}</div>
          )}
          {!!propertyTypeTitle && (
            <div style={styles.metaHeading3}>{propertyTypeTitle}</div>
          )}
        </div>
      );

      return (
        <div style={styles.wrapper}>
          {!!realtorName && <h1 style={styles.heading1}>{realtorName}</h1>}

          {!!images.length ? (
            <div style={styles.meta__withphoto}>
              <div style={styles.meta__photo}>
                <img src={images[0]} style={{ ...styles.photo, height: 140 }} />
              </div>
              <div>{metaData}</div>
            </div>
          ) : (
            metaData
          )}

          {groups.map((group, k1) => (
            <div key={k1} style={styles.group}>
              <h3 style={styles.heading3}>{group.name}</h3>
              {group.values.map((item, k2) => (
                <GroupRow item={item} key={`${k1}.${k2}`} />
              ))}
            </div>
          ))}

          {!!adText?.value && (
            <div style={styles.group}>
              <h3 style={styles.heading3}>
                {intl.formatMessage({
                  id: "mls.propertyGroup.adText",
                  defaultMessage: "Ad text",
                })}
              </h3>
              <div>{adText.value}</div>
            </div>
          )}

          {!!images.length && (
            <div style={styles.group}>
              <h3 style={styles.heading3}>
                {intl.formatMessage({
                  id: "mls.propertyGroup.photos",
                  defaultMessage: "Photos",
                })}
              </h3>

              {chunk(images, 3).map((group, k1) => (
                <div style={styles.photos} key={k1}>
                  {group.map((image, k2) => (
                    <div style={styles.photo__wrapper} key={`${k1}.${k2}`}>
                      <img src={image} style={styles.photo} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    };

    const html = (
      <>
        {assignments.map((assignment, idx) => (
          <Row assignment={assignment} key={idx} />
        ))}
        <style type="text/css">
          {`@page {
            size: A4 portrait;
            margin: 2cm 1cm;
          }`}
        </style>
      </>
    );

    return renderToStaticMarkup(html);
  }

  public static mapToObjectAssignment(
    realEstateProperty?: RealEstateProperty
  ): ObjectAssignment {
    const {
      propertyInfo,
      offer,
      construction,
      evaluations,
      locationDetails,
      gardens,
      garages,
      counts,
      areaTotals,
      surroundings,
      facilities,
      dimensions,
      type,
      floors: mlsFloors,
      climatControl,
      location: mlsLocation,
    } = realEstateProperty || {};

    const propertyTypes = (type?.propertyTypes || []).reduce(
      (state, propertyType) => {
        switch (true) {
          case !!MLS_MAP_AGRICULTURALSUBTYPEOTHER[propertyType]: {
            state.agriculturalSubtypeOther =
              MLS_MAP_AGRICULTURALSUBTYPEOTHER[propertyType];
            break;
          }
          case !!MLS_MAP_APARTMENTCHARACTERISTIC[propertyType]: {
            state.apartmentCharacteristic =
              MLS_MAP_APARTMENTCHARACTERISTIC[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Apartment;
            }
            break;
          }
          case !!MLS_MAP_APARTMENTSORT[propertyType]: {
            state.apartmentSort = MLS_MAP_APARTMENTSORT[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Apartment;
            }
            break;
          }
          case !!MLS_MAP_HOUSECHARACTERISTIC[propertyType]: {
            state.houseCharacteristic =
              MLS_MAP_HOUSECHARACTERISTIC[propertyType];
            break;
          }
          case !!MLS_MAP_HOUSESORT[propertyType]: {
            state.houseSort = MLS_MAP_HOUSESORT[propertyType];
            break;
          }
          case !!MLS_MAP_HOUSETYPE[propertyType]: {
            state.houseType = MLS_MAP_HOUSETYPE[propertyType];
            break;
          }
          case !!MLS_MAP_LANDPROPERTYSUBTYPE[propertyType]: {
            state.landPropertySubType =
              MLS_MAP_LANDPROPERTYSUBTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Plot;
            }
            break;
          }
          case !!MLS_MAP_LISTINGTYPES[propertyType]: {
            state.listingType = MLS_MAP_LISTINGTYPES[propertyType];
            break;
          }

          case !!MLS_MAP_PARKINGTYPE[propertyType]: {
            state.parkingType = MLS_MAP_PARKINGTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Parking;
            }
            break;
          }
          case !!MLS_MAP_RESIDENTIALSUBTYPEOTHER[propertyType]: {
            state.residentialSubtypeOther =
              MLS_MAP_RESIDENTIALSUBTYPEOTHER[propertyType];
            break;
          }
          case !!MLS_MAP_CATTLEFARMINGSUBTYPE[propertyType]: {
            state.cattleFarmingSubtype =
              MLS_MAP_CATTLEFARMINGSUBTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.CattleFarming;
            }
            break;
          }
          case !!MLS_MAP_POULTRYFARMINGSUBTYPE[propertyType]: {
            state.poultryFarmingSubtype =
              MLS_MAP_POULTRYFARMINGSUBTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.PoultryCompany;
            }
            break;
          }
          case !!MLS_MAP_HORSECOMPANYSUBTYPE[propertyType]: {
            state.horseCompanySubtype =
              MLS_MAP_HORSECOMPANYSUBTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.HorsesCompany;
            }
            break;
          }
          case !!MLS_MAP_HORTICULTURALCOMPANYTYPE[propertyType]: {
            state.horticulturalCompanyType =
              MLS_MAP_HORTICULTURALCOMPANYTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Horticultural;
            }
            break;
          }
          case !!MLS_MAP_PIGCOMPANYTYPE[propertyType]: {
            state.pigCompanyType = MLS_MAP_PIGCOMPANYTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.PigHolding;
            }
            break;
          }
          case !!MLS_MAP_SOILTYPE[propertyType]: {
            state.soilType = MLS_MAP_SOILTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.LooseSoil;
            }
            break;
          }
          case !!MLS_MAP_BEVERAGEHOSPITALITYSECTORTYPE[propertyType]: {
            state.beverageHospitalityTypes.push(
              MLS_MAP_BEVERAGEHOSPITALITYSECTORTYPE[propertyType]
            );
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.HotelRestaurantCafe;
            }
            break;
          }
          case !!MLS_MAP_RESTAURANTHOSPITALITYSECTORTYPE[propertyType]: {
            state.restaurantHospitalitySectorTypes.push(
              MLS_MAP_RESTAURANTHOSPITALITYSECTORTYPE[propertyType]
            );
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.HotelRestaurantCafe;
            }
            break;
          }
          case !!MLS_MAP_HOTELHOSPITALITYSECTORTYPE[propertyType]: {
            state.hotelHospitalitySectorTypes.push(
              MLS_MAP_HOTELHOSPITALITYSECTORTYPE[propertyType]
            );
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.HotelRestaurantCafe;
            }
            break;
          }
          case !!MLS_MAP_FASTFOODHOSPITALITYSECTORTYPE[propertyType]: {
            state.fastfoodHospitalitySectorTypes.push(
              MLS_MAP_FASTFOODHOSPITALITYSECTORTYPE[propertyType]
            );
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.HotelRestaurantCafe;
            }
            break;
          }
          case !!MLS_MAP_LEISURETYPE[propertyType]: {
            state.leisureType = MLS_MAP_LEISURETYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Leisure;
            }
            break;
          }
          case !!MLS_MAP_SOCIALPROPERTYTYPE[propertyType]: {
            state.socialPropertyType = MLS_MAP_SOCIALPROPERTYTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.SocialProperty;
            }
            break;
          }
          case !!MLS_MAP_INVESTMENTTYPE[propertyType]: {
            state.investmentType = MLS_MAP_INVESTMENTTYPE[propertyType];
            if (state.listingType === ListingType.House) {
              state.listingType = ListingType.Investment;
            }
            break;
          }
          default: {
            break;
          }
        }

        return state;
      },
      {
        agriculturalSubtypeOther: undefined,
        apartmentCharacteristic: undefined,
        apartmentSort: undefined,
        houseCharacteristic: undefined,
        houseSort: undefined,
        houseType: undefined,
        landPropertySubType: undefined,
        listingType: ListingType.House,
        parkingType: undefined,
        residentialSubtypeOther: undefined,
        cattleFarmingSubtype: undefined,
        poultryFarmingSubtype: undefined,
        horseCompanySubtype: undefined,
        horticulturalCompanyType: undefined,
        pigCompanyType: undefined,
        soilType: undefined,
        beverageHospitalityTypes: [],
        restaurantHospitalitySectorTypes: [],
        hotelHospitalitySectorTypes: [],
        fastfoodHospitalitySectorTypes: [],
        leisureType: undefined,
        socialPropertyType: undefined,
        investmentType: undefined,
      } as MappedPropertyTypes
    );

    const mapRealEstateGroup = (): RealEstateGroup => {
      switch (true) {
        case type?.isResidential: {
          return RealEstateGroup.Residential;
        }
        case type?.isAgricultural: {
          return RealEstateGroup.Agricultural;
          break;
        }
        case type?.isCommercial: {
          return RealEstateGroup.Commercial;
          break;
        }
        default: {
          return RealEstateGroup.Residential;
        }
      }
    };

    const mapFloors = (floors: RealEstatePropertyFloorsFloor[]): Floor[] => {
      if (!floors) return [];
      return floors.map(
        (floor) =>
          ({
            atticOptions: [
              ...(floor.atticFixStair ? [AtticOption.Staircase] : []),
              ...(floor.atticRoomPossible ? [AtticOption.OptionForRoom] : []),
            ],
            description: (floor.description || []).map(
              (description) =>
                ({
                  language: MLS_CULTURES[description.language],
                  text: description.value,
                } as TranslatedText)
            ),
            floorNumber: floor.level,
            floorType: floor.type
              ? MLS_FLOOR_TYPE[floor.type]
              : FloorType.Floor,
            name: head(floor.name.map((text) => text.value)) || "",
            numberOfBedrooms: floor.countOfBedrooms,
            numberOfSpaces: floor.countOfRooms,
            spaces: (floor?.rooms || []).map(
              (room) =>
                ({
                  balconyType: head(
                    room.balconyTypes.map((i) => MLS_BALCONY_TYPES[i])
                  ), // ?? only one on api side
                  bathroomFacilities: room.bathroomFacilities.map(
                    (i) => MLS_BATHROOM_FACILITY_TYPES[i]
                  ),
                  description: (room.description || []).map((description) => ({
                    language: MLS_CULTURES[description.language],
                    text: description.value,
                  })),
                  hasStairway: room.hasStairs,
                  isRenewed: room.isRenovated,
                  kitchenType: (room.kitchenTypes || []).map(
                    (i) => MLS_KITCHEN_TYPES[i]
                  ),
                  livingRoomTypes: room.livingRoomTypes.map(
                    (i) => MLS_LIVING_ROOM_TYPES[i]
                  ),
                  constructionYear: room.renovationYear,
                  kitchenFacilities: (room.kitchenFacilities || []).map(
                    (i) => MLS_KITCHEN_TYPES[i]
                  ),
                  name: head(room.name.map((text) => text.value)) || "",
                  orientation: MLS_ORIENTATION_TYPE[room.orientation],
                  size: {
                    area: room?.dimensions?.area,
                    height: room?.dimensions?.height,
                    width: room?.dimensions?.width,
                    length: room?.dimensions?.length,
                    volume: room?.dimensions?.content,
                  },
                  stove: head(
                    (room.kitchenFacilities || []).map(
                      (i) => MLS_KITCHEN_FACILITY_TYPES[i]
                    )
                  ),
                  type: MLS_ROOM_TYPES[room.type],
                } as Space)
            ),
          } as Floor)
      );
    };

    // const objectTypes = mapObjectTypes(type?.propertyTypes);
    const realEstateGroup = mapRealEstateGroup();
    const location = head(locationDetails);
    const floors = mapFloors(mlsFloors);

    const calculateConstructionPeriod = (year: number) => {
      if (!year || !isNumber(year)) return undefined;

      switch (true) {
        case year < 1906: {
          return ConstructionPeriod.Before1906;
        }
        case year > 1906 && year <= 1930: {
          return ConstructionPeriod.Between1906And1930;
        }
        case year > 1931 && year <= 1944: {
          return ConstructionPeriod.Between1931And1944;
        }
        case year > 1945 && year <= 1959: {
          return ConstructionPeriod.Between1945And1959;
        }
        case year > 1960 && year <= 1970: {
          return ConstructionPeriod.Between1960And1970;
        }
        case year > 1971 && year <= 1980: {
          return ConstructionPeriod.Between1971And1980;
        }
        case year > 1981 && year <= 1990: {
          return ConstructionPeriod.Between1981And1990;
        }
        case year > 1991 && year <= 2000: {
          return ConstructionPeriod.Between1991And2000;
        }
        case year > 2001 && year <= 2010: {
          return ConstructionPeriod.Between2001And2010;
        }
        case year > 2010: {
          return ConstructionPeriod.After2010;
        }
        default: {
          return undefined;
        }
      }
    };

    const assignment: ObjectAssignment = {
      linkedEmployee: { id: COMMON.EMPTY_GUID },
      linkedOffice: { id: COMMON.EMPTY_GUID },
      realEstateGroup,
      dateTimeCreated: new Date(),
      dateTimeModified: new Date(),
      isActive: true,
      isNew: true,
      realEstateAgencyId: COMMON.EMPTY_GUID,
      id: COMMON.EMPTY_GUID,
      forRent: offer?.isForRent,
      forSale: offer?.isForSale,
      gardensText: (gardens || [])
        .map((garden) => {
          return (garden.description || []).map(
            (desc) =>
              ({
                language: MLS_CULTURES[desc.language],
                text: desc.value,
              } as TranslatedText)
          );
        })
        .reduce((state, item) => state.concat(item), []),
      hidePrice: propertyInfo?.hidePrice,
      isConfidential: propertyInfo?.confidential,
      rentOffer: {
        furnishing: MLS_FURNITURE_TYPES[facilities?.furnitureType],
      },
      saleOffer: {
        furnishing: MLS_FURNITURE_TYPES[facilities?.furnitureType],
      },
      address: {
        adminAreaLevel1: {
          id: location?.administrativeAreaLevel1ID,
          name: location?.administrativeAreaLevel1,
        },
        adminAreaLevel2: {
          id: location?.administrativeAreaLevel2ID,
          name: location?.administrativeAreaLevel2,
        },
        adminAreaLevel3: {
          id: location?.administrativeAreaLevel3ID,
          name: location?.administrativeAreaLevel3,
        },
        countryIso2: location?.isO2CountryCode,
        houseNumber: location?.houseNumber,
        houseNumberPostfix: location?.houseNumberAddendum,
        locality: {
          id: location?.localityID,
          name: location?.locality,
        },
        geoLocation: {
          latitude: location?.coordinates.latitude,
          longitude: location?.coordinates.longitude,
        },
        postalCode: location?.postalCode,
        street: {
          id: location?.streetID,
          name: location?.streetName,
        },
        sublocality: {
          id: location?.sublocalityID,
          name: location?.sublocality,
        },
      },
      // agriculturalSubtypeOther: objectTypes?.AlvOtherTypes,
      // apartmentCharacteristic: objectTypes?.apartmentCharacteristic,
      // apartmentSort: objectTypes?.apartmentSort,
      approvalMarks: evaluations?.certifications.map(
        (i) => MLS_CERTIFICATION_TYPES[i]
      ),
      beveragesHospitalityIndustryArea: {
        area: areaTotals?.beveragesHospitalityIndustryArea,
      },
      beveragesNonHospitalityIndustryArea: {
        area: areaTotals?.beveragesNonHospitalityIndustryArea,
      },
      buildingRelatedOutdoorSpace: {
        area: areaTotals?.buildingRelatedOutdoorSpaceArea,
      },
      contents: {
        volume: dimensions?.content,
      },
      countOfHotelrooms: counts?.countOfHotelrooms,
      energyLabel: {
        certificateNumber: climatControl?.energyCertificate?.number,
        endDate: climatControl?.energyCertificate?.dueDate,
        energyClass:
          MLS_ENERGY_CLASS[climatControl?.energyCertificate?.energyClass],
        energyIndex: climatControl?.energyCertificate?.energyIndex,
        isCertificateAvailable:
          climatControl?.energyCertificate?.hasEnergyCertificate,
      },
      externalStorage: {
        area: areaTotals?.storageAreaExternal,
      },
      floorLevel: mlsLocation?.floorNumber,
      floors,
      garages: (garages || []).map((garage) => ({
        description: (garage?.comments || []).map(
          (comment) =>
            ({
              text: comment?.value,
              language: MLS_CULTURES[comment?.language],
            } as TranslatedText)
        ),
        amenities: (garage?.facilities || []).map(
          (a) => MLS_GARAGE_FACILITY_TYPE[a]
        ),
        capacity: garage?.carCapacity,
        garageType: MLS_GARAGE_TYPE[garage.type],
        insulationTypes: garage.isolationTypes.map(
          (type) => MLS_ISOLATION_TYPES[type]
        ),
        size: {
          area: garage?.dimensions?.area,
          height: garage?.dimensions?.height,
          width: garage?.dimensions?.width,
          volume: garage?.dimensions?.content,
        },
      })),
      gardens: (gardens || []).map((garden) => ({
        description: (garden?.description || []).map(
          (description) =>
            ({
              text: description?.value,
              language: MLS_CULTURES[description?.language],
            } as TranslatedText)
        ),
        gardenQuality: MLS_GARDEN_QUALITY_TYPE[garden?.quality],
        gardenType: MLS_GARDEN_TYPE[garden?.type],
        hasBackEntrance: garden?.hasBackyardEntrance,
        isMainGarden: garden?.isMainGarden,
        orientation: MLS_ORIENTATION_TYPE[garden?.orientation],
        size: {
          area: garden?.dimensions?.area,
          height: garden?.dimensions?.height,
          length: garden?.dimensions?.length,
          volume: garden?.dimensions?.content,
          width: garden?.dimensions?.width,
        },
      })),
      hotelHospitalityIndustryArea: {
        area: areaTotals?.hotelHospitalityIndustryArea,
      },
      hotelNonHospitalityIndustryArea: {
        area: areaTotals?.hotelNonHospitalityIndustryArea,
      },
      // houseCharacteristic: objectTypes?.houseCharacteristic,
      // houseSort: objectTypes?.houseSort,
      // houseType: objectTypes?.houseType,
      isNewEstate: construction?.isNewEstate,
      // landPropertySubType: objectTypes?.landTypes,
      // listingType: objectTypes?.listingType,
      ...propertyTypes,
      livingRoomSurface: {
        area: areaTotals?.livingArea,
      },
      locationPlaces: surroundings?.location.map((s) => MLS_LOCATION_PLACES[s]),
      maintenanceInside: {
        conditionType: MLS_MAINTENANCE_TYPES[evaluations?.maintenanceInside],
      },
      maintenanceOutside: {
        conditionType: MLS_MAINTENANCE_TYPES[evaluations?.maintenanceOutside],
      },
      numberOfAnimalPlaces: counts?.countOfAnimalPlaces,
      numberOfAnimals: counts?.countOfAnimals,
      numberOfBathRooms: counts?.countOfBathrooms,
      numberOfBedRooms: counts?.countOfBedrooms,
      numberOfFloors: counts?.countOfFloors,
      numberOfGarages: counts?.countOfGarages,
      numberOfGardens: counts?.countOfGardens,
      numberOfKitchens: counts?.countOfKitchens,
      numberOfMeatPigs: counts?.countOfFinishingPigs,
      numberOfMooringPlacesMilkingCattle: counts?.countOfMooringsDairyCattles,
      numberOfMooringPlacesYoungCattle: counts?.countOfMooringsCattles,
      numberOfRooms: counts?.countOfRooms,
      numberOfShowers: counts?.countOfShowers,
      numberOfSowingSights: counts?.countOfSowPlaces,
      numberOfToilets: counts?.countOfToilettes,
      numberOfWeanedPiglets: counts?.countOfWeanedPigs,
      numberOfBreedingPigs: counts?.countOfBreedingPigs,
      numberOfMeatPigStables: counts?.countOfFinishingPigsties,
      numberOfBreedingPigStables: counts?.countOfBreedingPigsties,
      numberOfPigletStables: counts?.countOfWeanedPigsties,
      otherInsideSpaces: {
        area: areaTotals?.otherIndoorSpaceArea,
      },
      parcelSurface: {
        area: dimensions?.land?.area,
        height: dimensions?.land?.height,
        length: dimensions?.land?.length,
        volume: dimensions?.land?.content,
        width: dimensions?.land?.width,
      },
      pointsOfInterest: (surroundings?.pointsOfInterest || []).map((poi) => ({
        distanceFrom: poi.distanceFrom,
        distanceTo: poi.distanceUntil,
        type: MLS_POINTS_OF_INTEREST[poi.type],
      })),
      usableArea: {
        area: areaTotals?.effectiveArea,
      },
      yearOfConstruction: {
        constructionYear: construction?.constructionYearFrom,
        description: (construction?.constructionComment || []).map((i) => ({
          language: MLS_CULTURES[i.language],
          text: i.value,
        })),
        isUnderConstruction: construction?.isUnderConstruction,
        period:
          MLS_CONSTRUCTION_PERIOD[construction?.constructionPeriod] ||
          calculateConstructionPeriod(construction?.constructionYearFrom),
      },
    };

    const stripUndefined = (object) => {
      if (!object) return;
      // Strip undefined props
      Object.keys(object).forEach((key) => {
        if (!key) return;
        const value = object[key];
        // Strip undefined
        if (value === undefined || value === null) delete object[key];
        // Strip empty arrays
        if (value && value.length === 0) delete object[key];

        const type = typeof value;
        if (type === "object") {
          // Recurse...
          stripUndefined(value);
          if (value && !Object.keys(value).length) {
            delete object[key];
          }
        }
      });
    };

    stripUndefined(assignment);

    return assignment;
  }

  public static mapToAcquisitionAssignment(
    realEstateProperty?: RealEstateProperty
  ): AcquisitionObjectAssignment {
    const base = MlsUtil.mapToObjectAssignment(
      realEstateProperty
    ) as AcquisitionObjectAssignment;
    const {
      descriptions,
      propertyInfo,
      attachments,
      financials,
      offer,
      areaTotals,
    } = realEstateProperty || {};

    const mapAttachments = (attachments: Attachment[]): PhotoBlob[] => {
      if (!attachments) return [];
      const photoFiles = attachments.filter(
        (attachment) => attachment.type === AttachmentType.PHOTO
      );

      return photoFiles.map((file) => {
        const {
          urlMediumFile,
          urlNormalizedFile,
          urlOriginalFile,
          urlThumbFile,
          hash,
          fileType,
          title,
        } = file;

        const fileName = head(title.map((text) => text.value));

        return {
          urlOriginal: urlOriginalFile || urlNormalizedFile || urlMediumFile,
          urlPreview: urlThumbFile || urlMediumFile,
          urlBig: urlNormalizedFile || urlMediumFile,
          urlMedium: urlMediumFile || urlNormalizedFile,
          width: 0,
          height: 0,
          fileSize: 0,
          md5Hash: hash,
          fileExtension: !fileType ? undefined : fileType.toString(),
          fileName,
        } as PhotoBlob;
      });
    };

    const photos = mapAttachments(attachments);

    return {
      ...base,
      advertisementText: (descriptions?.adText || []).map((i) => {
        return {
          language: MLS_CULTURES[i.language],
          text: i.value,
        } as TranslatedText;
      }),
      mandateDateTime: propertyInfo?.mandateDate,
      photos,
      rentOffer: {
        ...(base.rentOffer || {}),
        btwPercentage: financials?.rentVATPercentage,
        buyPricePerParkingLot: financials?.purchasePriceParkingLot,
        commissionContactGross: financials?.commissions?.commissionContactGross,
        commissionContactPercent:
          financials?.commissions?.commissionContactPercent,
        commissionCustomerGross:
          financials?.commissions?.commissionCustomerGross,
        commissionCustomerPercent:
          financials?.commissions?.commissionCustomerPercent,
        commissionOwnerGross: financials?.commissions?.commissionOwnerGross,
        commissionOwnerPercent: financials?.commissions?.commissionOwnerPercent,
        availableFrom: offer?.availableFromDate,
        availableUntil: offer?.availableUntilDate,
        rentCondition: MLS_RENT_PRICE_TYPE[financials?.rentPriceType],
        rentPrice: financials?.rentPrice,
        rentPricePerUnit: financials?.rentPriceInUnits,
        rentPricePerUnitSize: MLS_PER_UNIT_SIZE[financials?.rentPriceUnit],
        rentSpecifications: (financials?.rentSpecification || [])
          .map((spec) => MLS_RENT_SPECIFICATION[spec])
          .filter((d) => !!d),
      },
      saleOffer: {
        ...(base.saleOffer || {}),
        btwPercentage: financials?.purchaseVATPercentage,
        buyPricePerParkingLot: financials?.purchasePriceParkingLotCovered,
        commissionContactGross: financials?.commissions?.commissionContactGross,
        commissionContactPercent:
          financials?.commissions?.commissionContactPercent,
        commissionCustomerGross:
          financials?.commissions?.commissionCustomerGross,
        commissionCustomerPercent:
          financials?.commissions?.commissionCustomerPercent,
        commissionOwnerGross: financials?.commissions?.commissionOwnerGross,
        commissionOwnerPercent: financials?.commissions?.commissionOwnerPercent,
        hasRetailersContribution:
          financials?.hasRetailersAssociationContribution,
        indicationElectra: financials?.indications?.electricityCosts,
        indicationGas: financials?.indications?.gasCosts,
        indicationHeatingCosts: financials?.indications?.heatingCosts,
        indicationWater: financials?.indications?.waterCosts,
        pricePerUnit: financials?.purchasePriceInUnits,
        pricePerUnitSize: MLS_PER_UNIT_SIZE[financials?.purchasePriceUnit],
        salePrice: financials?.purchasePrice,
        saleCondition: MLS_PURCHASE_CONDITION[financials?.purchaseCondition],
      },
      inUnitsFrom: {
        area: areaTotals?.availableInUnitsFrom,
      },
    };
  }

  public static mapToObjectAssignmentForSearchAssignment(
    realEstateProperty?: RealEstateProperty
  ): ObjectAssignment {
    const base = MlsUtil.mapToObjectAssignment(realEstateProperty);
    const { descriptions, propertyInfo, financials, offer, areaTotals } =
      realEstateProperty || {};

    return {
      ...base,
      advertisementText: (descriptions?.adText || []).map((i) => {
        return {
          language: MLS_CULTURES[i.language],
          text: i.value,
        } as TranslatedText;
      }),
      mandateDateTime: propertyInfo?.mandateDate,
      rentOffer: {
        ...(base.rentOffer || {}),
        btwPercentage: financials?.rentVATPercentage,
        buyPricePerParkingLot: financials?.purchasePriceParkingLot,
        commissionContactGross: financials?.commissions?.commissionContactGross,
        commissionContactPercent:
          financials?.commissions?.commissionContactPercent,
        commissionCustomerGross:
          financials?.commissions?.commissionCustomerGross,
        commissionCustomerPercent:
          financials?.commissions?.commissionCustomerPercent,
        commissionOwnerGross: financials?.commissions?.commissionOwnerGross,
        commissionOwnerPercent: financials?.commissions?.commissionOwnerPercent,
        availableFrom: offer?.availableFromDate,
        availableUntil: offer?.availableUntilDate,
        rentCondition: MLS_RENT_PRICE_TYPE[financials?.rentPriceType],
        rentPrice: financials?.rentPrice,
        rentPricePerUnit: financials?.rentPriceInUnits,
        rentPricePerUnitSize: MLS_PER_UNIT_SIZE[financials?.rentPriceUnit],
        rentSpecifications: (financials?.rentSpecification || [])
          .map((spec) => MLS_RENT_SPECIFICATION[spec])
          .filter((d) => !!d),
      },
      saleOffer: {
        ...(base.saleOffer || {}),
        salePrice: financials?.purchasePrice,
        btwPercentage: financials?.purchaseVATPercentage,
        buyPricePerParkingLot: financials?.purchasePriceParkingLotCovered,
        commissionContactGross: financials?.commissions?.commissionContactGross,
        commissionContactPercent:
          financials?.commissions?.commissionContactPercent,
        commissionCustomerGross:
          financials?.commissions?.commissionCustomerGross,
        commissionCustomerPercent:
          financials?.commissions?.commissionCustomerPercent,
        commissionOwnerGross: financials?.commissions?.commissionOwnerGross,
        commissionOwnerPercent: financials?.commissions?.commissionOwnerPercent,
        hasRetailersContribution:
          financials?.hasRetailersAssociationContribution,
        indicationElectra: financials?.indications?.electricityCosts,
        indicationGas: financials?.indications?.gasCosts,
        indicationHeatingCosts: financials?.indications?.heatingCosts,
        indicationWater: financials?.indications?.waterCosts,
        pricePerUnit: financials?.purchasePriceInUnits,
        pricePerUnitSize: MLS_PER_UNIT_SIZE[financials?.purchasePriceUnit],
        saleCondition: MLS_PURCHASE_CONDITION[financials?.purchaseCondition],
      },
      inUnitsFrom: {
        area: areaTotals?.availableInUnitsFrom,
      },
    };
  }

  public static cleanFilterConfig(filterConfig: FilterConfig) {
    let cleanedFilter: Record<string, any> = {};
    Object.keys(filterConfig).forEach((key) => {
      const config = filterConfig[key];
      const { type, value } = config;
      switch (type) {
        case FilterType.Text:
        case FilterType.DatePicker:
        case FilterType.Select:
        case FilterType.Date:
        case FilterType.Number:
        case FilterType.Price:
        case FilterType.Single: {
          if (![null, undefined, NaN, ""].includes(value)) {
            cleanedFilter = {
              ...cleanedFilter,
              [key]: value,
            };
          }
          break;
        }

        case FilterType.SearchLocation:
        case FilterType.Array:
        case FilterType.KeyNumberArray:
        case FilterType.LocationRange:
        case FilterType.Realtor:
        case FilterType.PostalCodeRange: {
          if (isArray(value) && !!value.length) {
            cleanedFilter = {
              ...cleanedFilter,
              [key]: value,
            };
          }
          break;
        }

        case FilterType.TextRange:
        case FilterType.NumberRange:
        case FilterType.PriceRange:
        case FilterType.DateRange: {
          const { min, max } = value;
          if (!!min || !!max) {
            cleanedFilter = {
              ...cleanedFilter,
              [key]: value,
            };
          }
          break;
        }
        case FilterType.MlsPriceRange: {
          const { min, max, type } = value;
          if (!!min || !!max || !!type) {
            cleanedFilter = {
              ...cleanedFilter,
              [key]: value,
            };
          }
        }
        default:
          break;
      }
    });

    return cleanedFilter;
  }

  public static resetFilterToDefaultValues(filterConfig: FilterConfig) {
    let resettedFilters: FilterConfig = {};

    for (const key in filterConfig) {
      const filter = filterConfig[key];

      resettedFilters = {
        ...resettedFilters,
        [key]: {
          ...filter,
          value: filter.emptyValue,
        },
      };
    }

    return resettedFilters;
  }

  public static searchAssignmentsToMlsPropertyQuery(
    searchAssignments: SearchAssignment[]
  ): SearchRealEstatePropertyRequest {
    return (searchAssignments || []).reduce(
      (state, assignment) => {
        const {
          locations,
          realEstateGroup,
          priceStart,
          priceEnd,
          offerType,
          typesPART,
          buildingCharacteristics,
          apartmentTypes,
          apartmentCharacteristics,
          typeParking,
          typesPARTOther,
          typesBOG,
          typesALV,
          typesALVOther,
          constructionYearBegin,
          constructionYearEnd,
          locationPlaces,
          orientationMainGarden,
          minLivableArea,
          maxLivableArea,
          minBuildableArea,
          maxBuildableArea,
          minGardenArea,
          maxGardenArea,
          minNrOfRooms,
          maxNrOfRooms,
          minNrOfBedrooms,
          maxNrOfBedrooms,
          minParkingCapacity,
          maxParkingCapacity,
          mustBeQualifiedForSeniors,
          facilities,
          peculiarities,
          houseSorts,
          houseTypes,
          furnishing,
        } = assignment as SearchAssignment;

        switch (realEstateGroup) {
          case RealEstateGroup.Agricultural: {
            state.isAgricultural = true;
            break;
          }
          case RealEstateGroup.Commercial: {
            state.isCommercial = true;
            break;
          }
          case RealEstateGroup.Residential: {
            state.isResidential = true;
            break;
          }
          default:
            break;
        }

        switch (offerType) {
          case OfferType.Rent: {
            state.offer = {
              ...state.offer,
              isForRent: true,
            };

            if (!!priceStart) {
              state.finance = {
                ...state.finance,
                rentPriceFrom: priceStart,
              };
            }
            if (!!priceEnd) {
              state.finance = {
                ...state.finance,
                rentPriceUntil: priceEnd,
              };
            }
            break;
          }
          case OfferType.Sale: {
            state.offer = {
              ...state.offer,
              isForSale: true,
            };

            if (!!priceStart) {
              state.finance = {
                ...state.finance,
                purchasePriceFrom: priceStart,
              };
            }
            if (!!priceEnd) {
              state.finance = {
                ...state.finance,
                purchasePriceUntil: priceEnd,
              };
            }
            break;
          }
          default:
            break;
        }

        if (!!constructionYearBegin) {
          state.ranges = {
            ...state.ranges,
            buildingYearMin: constructionYearBegin,
          };
        }

        if (!!constructionYearEnd) {
          state.ranges = {
            ...state.ranges,
            buildingYearMax: constructionYearEnd,
          };
        }

        if (!!(locationPlaces || []).length) {
          const locationPlaceTypes: LocationPlaceType[] = [];
          locationPlaces.forEach((locationPlace) => {
            const locationPlaceType = MLS_MAP_LOCATIONPLACES[locationPlace];
            if (!!locationPlaceType) locationPlaceTypes.push(locationPlaceType);
          });

          if (!!locationPlaceTypes.length) {
            state.locationPlaces = [
              ...(state.locationPlaces || []),
              ...locationPlaceTypes,
            ];
          }
        }

        if (!!orientationMainGarden) {
          const orientationType = MLS_MAP_ORIENTATION[orientationMainGarden];

          if (!!orientationType) {
            const gardenOrientations = (
              state.outside?.gardenOrientations || []
            ).filter((orientation) => orientation !== orientationType);

            state.outside = {
              ...state.outside,
              gardenOrientations: [...gardenOrientations, orientationType],
            };
          }
        }

        if (!!minLivableArea) {
          let effectiveAreaMin = state.ranges?.effectiveAreaMin;
          effectiveAreaMin =
            !!effectiveAreaMin && effectiveAreaMin < minLivableArea
              ? effectiveAreaMin
              : minLivableArea;

          state.ranges = {
            ...state.ranges,
            effectiveAreaMin,
          };
        }

        if (!!maxLivableArea) {
          let effectiveAreaMax = state.ranges?.effectiveAreaMax;
          effectiveAreaMax =
            !!effectiveAreaMax && effectiveAreaMax > maxLivableArea
              ? effectiveAreaMax
              : maxLivableArea;

          state.ranges = {
            ...state.ranges,
            effectiveAreaMax,
          };
        }

        if (!!minBuildableArea) {
          let landAreaMin = state.ranges?.landAreaMin;
          landAreaMin =
            !!landAreaMin && landAreaMin < minBuildableArea
              ? landAreaMin
              : minBuildableArea;

          state.ranges = {
            ...state.ranges,
            landAreaMin,
          };
        }

        if (!!maxBuildableArea) {
          let landAreaMax = state.ranges?.landAreaMax;
          landAreaMax =
            !!landAreaMax && landAreaMax > maxBuildableArea
              ? landAreaMax
              : maxBuildableArea;

          state.ranges = {
            ...state.ranges,
            landAreaMax,
          };
        }

        if (!!minGardenArea) {
          let gardenSurfaceMin = state.outside?.gardenSurfaceMin;
          gardenSurfaceMin =
            !!gardenSurfaceMin && gardenSurfaceMin < minGardenArea
              ? gardenSurfaceMin
              : minGardenArea;

          state.outside = {
            ...state.outside,
            gardenSurfaceMin,
          };
        }

        if (!!maxGardenArea) {
          let gardenSurfaceMax = state.outside?.gardenSurfaceMax;
          gardenSurfaceMax =
            !!gardenSurfaceMax && gardenSurfaceMax > maxGardenArea
              ? gardenSurfaceMax
              : maxGardenArea;

          state.outside = {
            ...state.outside,
            gardenSurfaceMax,
          };
        }

        if (!!minNrOfRooms) {
          let numberOfRoomsMin = state.ranges?.numberOfRoomsMin;
          numberOfRoomsMin =
            !!numberOfRoomsMin && numberOfRoomsMin < minNrOfRooms
              ? numberOfRoomsMin
              : minNrOfRooms;

          state.ranges = {
            ...state.ranges,
            numberOfRoomsMin,
          };
        }

        if (!!maxNrOfRooms) {
          let numberOfRoomsMax = state.ranges?.numberOfRoomsMax;
          numberOfRoomsMax =
            !!numberOfRoomsMax && numberOfRoomsMax > maxNrOfRooms
              ? numberOfRoomsMax
              : maxNrOfRooms;

          state.ranges = {
            ...state.ranges,
            numberOfRoomsMax,
          };
        }

        if (!!minNrOfBedrooms) {
          let numberOfBedroomsMin = state.ranges?.numberOfBedroomsMin;
          numberOfBedroomsMin =
            !!numberOfBedroomsMin && numberOfBedroomsMin < minNrOfBedrooms
              ? numberOfBedroomsMin
              : minNrOfBedrooms;

          state.ranges = {
            ...state.ranges,
            numberOfBedroomsMin,
          };
        }

        if (!!maxNrOfBedrooms) {
          let numberOfBedroomsMax = state.ranges?.numberOfBedroomsMax;
          numberOfBedroomsMax =
            !!numberOfBedroomsMax && numberOfBedroomsMax > maxNrOfBedrooms
              ? numberOfBedroomsMax
              : maxNrOfBedrooms;

          state.ranges = {
            ...state.ranges,
            numberOfBedroomsMax,
          };
        }

        if (!!minParkingCapacity) {
          let parkingPlacesMin = state.ranges?.parkingPlacesMin;
          parkingPlacesMin =
            !!parkingPlacesMin && parkingPlacesMin < minParkingCapacity
              ? parkingPlacesMin
              : minParkingCapacity;

          state.ranges = {
            ...state.ranges,
            parkingPlacesMin,
          };
        }

        if (!!maxParkingCapacity) {
          let parkingPlacesMax = state.ranges?.parkingPlacesMax;
          parkingPlacesMax =
            !!parkingPlacesMax && parkingPlacesMax > maxParkingCapacity
              ? parkingPlacesMax
              : maxParkingCapacity;

          state.ranges = {
            ...state.ranges,
            parkingPlacesMax,
          };
        }

        if (!!mustBeQualifiedForSeniors) {
          state.evaluations = {
            ...state.evaluations,
            isAccessibleForSeniors: mustBeQualifiedForSeniors,
          };
        }

        if (!!mustBeQualifiedForSeniors) {
          state.evaluations = {
            ...state.evaluations,
            isAccessibleForSeniors: mustBeQualifiedForSeniors,
          };
        }

        if (!!(peculiarities || []).length) {
          peculiarities.forEach((peculiarity) => {
            switch (peculiarity) {
              case SearchAssignmentPeculiarityType.MonumentalListing: {
                const facilities = (state.facilities || []).filter(
                  (facility) =>
                    facility !== FacilitySearchType.MONUMENTAL_BUILDING
                );
                state.facilities = [
                  ...facilities,
                  FacilitySearchType.MONUMENTAL_BUILDING,
                ];
                break;
              }
              case SearchAssignmentPeculiarityType.RecreationalListing: {
                const others = (state.others || []).filter(
                  (other) => other !== OtherSearchType.IS_RECREATION
                );
                state.others = [...others, OtherSearchType.IS_RECREATION];
                break;
              }
              default:
                break;
            }
          });
        }

        if (!!(facilities || []).length) {
          switch (true) {
            case facilities.includes(SearchAssignmentFacilityType.Balcony): {
              state.outside = {
                ...state.outside,
                hasBalcony: true,
              };
              break;
            }
            case facilities.includes(SearchAssignmentFacilityType.Garden): {
              state.outside = {
                ...state.outside,
                hasGarden: true,
              };
              break;
            }
            case facilities.includes(SearchAssignmentFacilityType.Garage): {
              const others = (state.others || []).filter(
                (other) => other !== OtherSearchType.HAS_GARAGE
              );

              state.others = [...others, OtherSearchType.HAS_GARAGE];
              break;
            }
            default:
              break;
          }

          const facilityTypes = state.facilities || [];
          facilities.forEach((facility) => {
            const facilityType = MLS_MAP_SEARCHASSIGNMENTFACILITYTYPE[facility];
            if (!!facilityType) {
              facilityTypes.push(facilityType);
            }
          });

          if (!!facilityTypes) {
            state.facilities = facilityTypes;
          }
        }

        if (!!furnishing) {
          switch (furnishing) {
            case Furnishing.Furnished: {
              const others = (state.others || []).filter(
                (other) => other !== OtherSearchType.IS_FURNISHED
              );
              state.others = [...others, OtherSearchType.IS_FURNISHED];
              break;
            }
            case Furnishing.Upholstered: {
              const others = (state.others || []).filter(
                (other) => other !== OtherSearchType.IS_UPHOLSTERED
              );
              state.others = [...others, OtherSearchType.IS_UPHOLSTERED];
              break;
            }
            default:
              break;
          }
        }

        // Main property types with sub types
        if ((typesPART || []).includes(TypePART.ResidentialBuilding)) {
          let subFilters: PropertyTypeSubFilter[] = [
            { propertyTypes: [PropertyType.HOUSE] },
          ];

          if (!!(houseTypes || []).length) {
            let propertyTypes: PropertyType[] = [];
            houseTypes.forEach((houseType) => {
              const propertyType = MLS_HOUSETYPE[houseType];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          if (!!(houseSorts || []).length) {
            let propertyTypes: PropertyType[] = [];
            houseSorts.forEach((houseSort) => {
              const propertyType = MLS_HOUSESORT[houseSort];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          if (!!(buildingCharacteristics || []).length) {
            let propertyTypes: PropertyType[] = [];
            buildingCharacteristics.forEach((buildingCharacteristic) => {
              const propertyType =
                MLS_HOUSECHARACTERISTIC[buildingCharacteristic];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        if ((typesPART || []).includes(TypePART.Apartment)) {
          let subFilters: PropertyTypeSubFilter[] = [
            { propertyTypes: [PropertyType.APARTMENT] },
          ];

          if (!!(apartmentTypes || []).length) {
            let propertyTypes: PropertyType[] = [];
            apartmentTypes.forEach((apartmentType) => {
              const propertyType = MLS_APARTMENTSORT[apartmentType];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          if (!!(apartmentCharacteristics || []).length) {
            let propertyTypes: PropertyType[] = [];
            apartmentCharacteristics.forEach((apartmentCharacteristic) => {
              const propertyType =
                MLS_APARTMENTCHARACTERISTIC[apartmentCharacteristic];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        if ((typesPART || []).includes(TypePART.Parking)) {
          let subFilters: PropertyTypeSubFilter[] = [
            { propertyTypes: [PropertyType.PARKING] },
          ];

          if (!!(typeParking || []).length) {
            let propertyTypes: PropertyType[] = [];
            typeParking.forEach((parkingType) => {
              const propertyType = MLS_PARKINGTYPE[parkingType];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        if ((typesPART || []).includes(TypePART.Other)) {
          let subFilters: PropertyTypeSubFilter[] = [
            { propertyTypes: [PropertyType.RESIDENTIAL_OTHERS] },
          ];

          if (!!(typesPARTOther || []).length) {
            let propertyTypes: PropertyType[] = [];
            typesPARTOther.forEach((otherTypePART) => {
              const propertyType = MLS_TYPEPARTOTHER[otherTypePART];
              if (!!propertyType) {
                propertyTypes.push(propertyType);
              }
            });

            propertyTypes = propertyTypes.filter((d) => !!d);
            if (!!propertyTypes.length) {
              subFilters.push({ propertyTypes });
            }
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        if (!!(typesBOG || []).length) {
          const subFilters: PropertyTypeSubFilter[] = typesBOG.map(
            (bogType) => ({ propertyTypes: [MLS_TYPEBOG[bogType]] })
          );

          state.propertyTypeFilters.push({ subFilters });
        }

        if (!!(typesALV || []).length) {
          let subFilters: PropertyTypeSubFilter[] = [];

          let propertyTypes: PropertyType[] = [];
          typesALV.forEach((alvType) => {
            const propertyType = MLS_TYPEALV[alvType];
            if (!!propertyType) {
              propertyTypes.push(propertyType);
            }
          });

          propertyTypes = propertyTypes.filter((d) => !!d);
          if (!!propertyTypes.length) {
            subFilters.push({ propertyTypes });
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        if (!!(typesALVOther || []).length) {
          let subFilters: PropertyTypeSubFilter[] = [];

          let propertyTypes: PropertyType[] = [];
          typesALVOther.forEach((alvOtherType) => {
            const propertyType = MLS_TYPEALVOTHER[alvOtherType];
            if (!!propertyType) {
              propertyTypes.push(propertyType);
            }
          });

          propertyTypes = propertyTypes.filter((d) => !!d);
          if (!!propertyTypes.length) {
            subFilters.push({ propertyTypes });
          }

          subFilters = subFilters.filter((d) => !!d);
          if (!!subFilters.length) {
            state.propertyTypeFilters.push({ subFilters });
          }
        }

        locations.forEach((location) => {
          state.locations.push({
            adminAreaLevel1Id: location?.adminAreaLevel1?.id,
            adminAreaLevel2Id: location?.adminAreaLevel2?.id,
            adminAreaLevel3Id: location?.adminAreaLevel3?.id,
            localityId: location?.locality?.id,
            sublocalityId: location?.sublocality?.id,
            streetId: location?.street?.id,
            postalCode: location?.postalCode,
            range: location?.radius,
          });
        });

        return state;
      },
      {
        skip: 0,
        take: 100,
        locations: [],
        finance: {},
        isResidential: undefined,
        isCommercial: undefined,
        isAgricultural: undefined,
        offer: {},
        ranges: {},
        others: [],
        propertyTypeFilters: [],
        locationPlaces: [],
        outside: {},
        evaluations: {},
        facilities: [],
        availabilityStatuses: [StatusType.AVAILABLE],
      } as SearchRealEstatePropertyRequest
    );
  }
}
