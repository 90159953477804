import { zohoAsapActions, ZohoAsapActions } from "./asap.actions";
import { RequestStatus } from "@haywork/enum";

export interface ZohoAsapState {
  asapStatus: RequestStatus;
  asapVisible: boolean;
}

const INITIAL_STATE: ZohoAsapState = {
  asapStatus: RequestStatus.Idle,
  asapVisible: false
};

export const zohoAsapReducer = (
  state: ZohoAsapState = INITIAL_STATE,
  action: ZohoAsapActions
): ZohoAsapState => {
  switch (action.type) {
    case zohoAsapActions.SetStatus: {
      const { asapStatus } = action;

      return {
        ...state,
        asapStatus
      };
    }
    case zohoAsapActions.ToggleVisibility: {
      const { asapVisible } = action;

      return {
        ...state,
        asapVisible
      };
    }
    default: {
      return state;
    }
  }
};
