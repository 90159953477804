import * as React from "react";
import { FC, memo, useEffect, useState, useCallback, useMemo } from "react";
import * as moment from "moment";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

export const LocaleComponent: FC = memo(
  CSSModules(styles, { allowMultiple: true })(() => {
    const [date, setDate] = useState("");

    const renderDateTime = useCallback(() => {
      const now = moment();
      setDate(now.toString());
    }, [setDate]);

    const locales = useMemo(() => {
      let locales: string[] = [];
      switch (true) {
        case !!navigator.languages: {
          locales = navigator.languages.map((language) => language);
          break;
        }
        case !!navigator.language: {
          locales.push(navigator.language);
          break;
        }
        case !!navigator["userLanguage"]: {
          locales.push(navigator["userLanguage"]);
          break;
        }
        default:
          break;
      }

      return locales;
    }, []);

    useEffect(() => {
      const interval = setInterval(renderDateTime, 1000);
      return () => clearInterval(interval);
    }, [renderDateTime]);

    return (
      <div>
        <div styleName="row">
          <div styleName="label">
            <I18n value="health.locale.label.dateTime" />
          </div>
          <div styleName="value">{date}</div>
        </div>
        <div styleName="row">
          <div styleName="label">
            <I18n value="health.locale.label.locales" />
          </div>
          <div styleName="value">
            {locales.map((locale, idx) => (
              <Pill
                label={locale}
                color={Colors.Primary}
                solid={idx === 0}
                key={idx}
              />
            ))}
          </div>
        </div>
      </div>
    );
  })
);
