import {
  ErrorResponse as KolibriErrorResponse,
  SwaggerException,
} from "@haywork/api/kolibri";
import { ErrorResponse as MailErrorResponse } from "@haywork/api/mail";
import { ErrorActions, isSwaggerException, store } from "@haywork/stores";

export interface ParseResponseOptions {
  displayError?: boolean;
  ignoreUnauthorized?: boolean;
}

export enum ApiType {
  Kolibri = "Kolibri",
  Email = "Email",
}

export class RequestHelper {
  public static isKolibriErrorResponse(
    error: KolibriErrorResponse | MailErrorResponse
  ): error is KolibriErrorResponse {
    return (error as KolibriErrorResponse).brokenBusinessRules !== undefined;
  }
}

export class ParseRequest {
  public response<T>(
    request: Promise<T>,
    api: ApiType = ApiType.Kolibri,
    options: ParseResponseOptions = {
      displayError: true,
      ignoreUnauthorized: false,
    }
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      request.then(resolve).catch((error) => {
        if (
          error?.name === "AbortError" ||
          (typeof error.toString === "function" &&
            error.toString() === "TypeError: Failed to fetch")
        ) {
          return reject(error);
        }

        switch (api) {
          case ApiType.Kolibri: {
            if (isSwaggerException(error)) {
              this.handleSwaggerException(error, options);
            } else {
              this.handleKolibriErrorResponse(
                error as KolibriErrorResponse,
                options
              );
            }
            break;
          }
          case ApiType.Email: {
            if (isSwaggerException(error)) {
              this.handleSwaggerException(error, options);
            } else {
              this.handleEmailErrorResponse(
                error as MailErrorResponse,
                options
              );
            }
            break;
          }
          default:
            break;
        }

        return reject(error);
      });
    });
  }

  private async handleSwaggerException(
    error: SwaggerException,
    options: ParseResponseOptions
  ) {
    if (!!store) {
      if (error.status === 401 && !options.ignoreUnauthorized) {
        store.dispatch(ErrorActions.setUnauthorized(true));
      } else if (options.displayError) {
        store.dispatch(ErrorActions.setError({ error }));
      }
    }

    return error;
  }

  private handleKolibriErrorResponse(
    error: KolibriErrorResponse,
    options: ParseResponseOptions
  ) {
    if (options.displayError && !!store) {
      store.dispatch(ErrorActions.setError({ error }));
    }
  }

  private handleEmailErrorResponse(
    error: MailErrorResponse,
    options: ParseResponseOptions
  ) {
    if (options.displayError && !!store) {
      store.dispatch(ErrorActions.setError({ error }));
    }

    return error;
  }
}
