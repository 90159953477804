import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditMediaScreenContainerProps } from "./assignments-edit-media.container";
import I18n from "@haywork/components/i18n";
import { AcquisitionAssignmentObjectEditMediaRouting } from "./assignments-edit-media.routing";
import { NavLink } from "react-router-dom";
import { RouteUtil } from "@haywork/util";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants/routes";

const styles = require("./assignments-edit-media.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquistionAssignmentEditMediaScreenProps = {};
type Props = AcquistionAssignmentEditMediaScreenProps &
  AcquistionAssignmentEditMediaScreenContainerProps;

export const AcquistionAssignmentEditMediaScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionObjectAssignment }) => {
      const { id } = acquisitionObjectAssignment;
      return (
        <div styleName="media" id="scroll-to-top">
          <div styleName="media__body">
            <div className="container-fluid">
              <AcquisitionAssignmentObjectEditMediaRouting id={id} />
            </div>
          </div>
          <div styleName="media__sidebar">
            <NavLink
              to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_PHOTOS.URI, {
                id
              })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-image" />
                <I18n value="acquisition.assignment.edit.media.photos" />
              </div>
            </NavLink>
            <NavLink
              to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_TEXT.URI, {
                id
              })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-align-left" />
                <I18n value="acquisition.assignment.edit.media.text" />
              </div>
            </NavLink>
            <NavLink
              to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_360.URI, {
                id
              })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-video" />
                <I18n value="acquisition.assignment.edit.media.360" />
              </div>
            </NavLink>
            <NavLink
              to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_FLOORPLANS.URI, {
                id
              })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-map" />
                <I18n value="acquisition.assignment.edit.media.floorplans" />
              </div>
            </NavLink>
            <NavLink
              to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_BROCHURES.URI, {
                id
              })}
              activeClassName="active"
            >
              <div styleName="section">
                <i className="fal fa-book" />
                <I18n value="acquisition.assignment.edit.media.brochures" />
              </div>
            </NavLink>
          </div>
        </div>
      );
    }
  )
);
