import {
  DefaultFolderTreeFolderEntity,
  DefaultFolderTreeRootFolderEntity
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { DOSSIERFOLDERROUTES } from "@haywork/constants";
import { EditableHocProps } from "@haywork/modules/editable";
import AddFolder from "@haywork/modules/shared/components/dossier-add-folder";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import { PureComponent } from "react";
import * as CSSModules from "react-css-modules";
import Folder from "../../components/folder";
import { SettingsDossierFoldersDetailScreenContainerProps } from "./detail.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsDossierFoldersDetailScreenProps {}
interface State {
  categoryName: string;
  addFolderModalVisible: boolean;
}
type Props = SettingsDossierFoldersDetailScreenProps &
  SettingsDossierFoldersDetailScreenContainerProps &
  EditableHocProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsDossierFoldersDetailScreen extends PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      categoryName: null,
      addFolderModalVisible: false
    };

    this.renderCategoryName = this.renderCategoryName.bind(this);
    this.onOpenFolderModal = this.onOpenFolderModal.bind(this);
    this.onCloseFolderModal = this.onCloseFolderModal.bind(this);
    this.onUpdateFolderTree = this.onUpdateFolderTree.bind(this);
    this.saveDefaultFolderTree = this.saveDefaultFolderTree.bind(this);
    this.onTreeUpdate = this.onTreeUpdate.bind(this);
    this.onTreeDelete = this.onTreeDelete.bind(this);
  }

  public componentDidMount() {
    this.renderCategoryName();
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.preppedForSave && !!this.props.preppedForSave) {
      this.props.saveDefaultFolderTree(this.props.folderTree);
    }
  }

  public render() {
    if (!this.props.folderTree) return null;
    const { rootFolderTree } = this.props.folderTree;
    const documentSessions = get(rootFolderTree, "documentSessions");
    const dossierItems = get(rootFolderTree, "dossierItems");
    const invoices = get(rootFolderTree, "invoices");

    return (
      <div styleName="detail">
        <PageHeader
          title="defaultFolderTrees.detail.title"
          titleValues={{ category: this.state.categoryName }}
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={this.saveDefaultFolderTree}
              />
              <Button
                label="defaultFolderTrees.action.add"
                icon={<Icon name="plus" size={18} light />}
                category="primary"
                onClick={this.onOpenFolderModal}
              />
            </>
          }
        />

        <div styleName="list">
          {!!documentSessions && (
            <Folder
              tree={documentSessions}
              depth={1}
              parentName=""
              onTreeUpdate={this.onTreeUpdate}
              onTreeDelete={this.onTreeDelete}
            />
          )}
          {!!dossierItems && (
            <Folder
              tree={dossierItems}
              depth={1}
              parentName=""
              onTreeUpdate={this.onTreeUpdate}
              onTreeDelete={this.onTreeDelete}
            />
          )}
          {!!invoices && (
            <Folder
              tree={invoices}
              depth={1}
              parentName=""
              onTreeUpdate={this.onTreeUpdate}
              onTreeDelete={this.onTreeDelete}
            />
          )}
        </div>

        <AddFolder
          visible={this.state.addFolderModalVisible}
          folderTree={this.props.folderTree.rootFolderTree}
          onClose={this.onCloseFolderModal}
          onUpdateFolderTree={this.onUpdateFolderTree}
        />
      </div>
    );
  }

  private updateTreeRecursivly(
    parentName: string,
    folders: DefaultFolderTreeFolderEntity[],
    path: string,
    tree: DefaultFolderTreeFolderEntity
  ) {
    return folders.map((folder) => {
      const pathname = [parentName, folder.name].filter((d) => !!d).join("/");
      switch (true) {
        case pathname === path: {
          return tree;
        }
        case !!folder.folders && !!folder.folders.length: {
          return {
            ...folder,
            folders: this.updateTreeRecursivly(
              pathname,
              folder.folders,
              path,
              tree
            )
          };
        }
        default: {
          return folder;
        }
      }
    });
  }

  private onTreeUpdate(path: string, tree: DefaultFolderTreeFolderEntity) {
    const { rootFolderTree } = this.props.folderTree;
    const documentSessions = get(rootFolderTree, "documentSessions");
    const dossierItems = get(rootFolderTree, "dossierItems");
    const invoices = get(rootFolderTree, "invoices");
    const dossierItemsFolders = dossierItems.folders || [];
    const documentSessionsFolders = documentSessions.folders || [];
    const invoicesFolders = invoices.folders || [];

    const folderTree: DefaultFolderTreeRootFolderEntity = {
      dossierItems:
        get(dossierItems, "name") === path
          ? tree
          : {
              ...dossierItems,
              folders: this.updateTreeRecursivly(
                get(dossierItems, "name"),
                dossierItemsFolders,
                path,
                tree
              )
            },
      documentSessions:
        get(documentSessions, "name") === path
          ? tree
          : {
              ...documentSessions,
              folders: this.updateTreeRecursivly(
                get(documentSessions, "name"),
                documentSessionsFolders,
                path,
                tree
              )
            },
      invoices:
        get(invoices, "name") === path
          ? tree
          : {
              ...invoices,
              folders: this.updateTreeRecursivly(
                get(invoices, "name"),
                invoicesFolders,
                path,
                tree
              )
            }
    };

    this.onUpdateFolderTree(folderTree);
  }

  private deleteTreeRecursivly(
    parentName: string,
    folders: DefaultFolderTreeFolderEntity[],
    path: string
  ) {
    return folders.reduce(
      (state, folder) => {
        const pathname = [parentName, folder.name].filter((d) => !!d).join("/");

        switch (true) {
          case pathname === path: {
            return state;
          }
          case !!folder.folders && !!folder.folders.length: {
            state.push({
              ...folder,
              folders: this.deleteTreeRecursivly(pathname, folder.folders, path)
            });
            break;
          }
          default: {
            state.push(folder);
            break;
          }
        }

        return state;
      },
      [] as DefaultFolderTreeFolderEntity[]
    );
  }

  private onTreeDelete(path: string) {
    const { rootFolderTree } = this.props.folderTree;
    const documentSessions = get(rootFolderTree, "documentSessions");
    const dossierItems = get(rootFolderTree, "dossierItems");
    const invoices = get(rootFolderTree, "invoices");
    const dossierItemsFolders = dossierItems.folders || [];
    const documentSessionsFolders = documentSessions.folders || [];
    const invoicesFolders = invoices.folders || [];

    const folderTree: DefaultFolderTreeRootFolderEntity = {
      dossierItems: {
        ...dossierItems,
        folders: this.deleteTreeRecursivly(
          get(dossierItems, "name"),
          dossierItemsFolders,
          path
        )
      },
      documentSessions: {
        ...documentSessions,
        folders: this.deleteTreeRecursivly(
          get(documentSessions, "name"),
          documentSessionsFolders,
          path
        )
      },
      invoices: {
        ...invoices,
        folders: this.deleteTreeRecursivly(
          get(invoices, "name"),
          invoicesFolders,
          path
        )
      }
    };

    this.onUpdateFolderTree(folderTree);
  }

  private onUpdateFolderTree(
    rootFolderTree: DefaultFolderTreeRootFolderEntity
  ) {
    this.setState({ addFolderModalVisible: false });
    const folderTree = {
      ...this.props.folderTree,
      rootFolderTree
    };

    const pathname = route(DOSSIERFOLDERROUTES.DETAIL.URI, {
      id: folderTree.id
    });
    this.props.updateCache(folderTree, pathname, false);
  }

  private renderCategoryName() {
    if (!!this.state.categoryName) return;

    const category = get(this.props.folderTree, "category");
    if (!category) return;

    const translation = this.props.defaultFolderCategories.find(
      (cat) => cat.value === category
    );
    if (!translation || !translation.displayName) return;

    const categoryName = translation.displayName;
    this.props.setTabTitle(categoryName);
    this.setState({ categoryName });
  }

  private onOpenFolderModal() {
    this.setState({ addFolderModalVisible: true });
  }

  private onCloseFolderModal() {
    this.setState({ addFolderModalVisible: false });
  }

  private saveDefaultFolderTree() {
    this.props.saveDefaultFolderTree(this.props.folderTree);
  }
}
