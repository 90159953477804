import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import head from "lodash-es/head";

import { ResourceText, FontStyle } from "@haywork/modules/shared";
import { Ui, UiScoreSize } from "@haywork/modules/ui";
import { StringUtil } from "@haywork/util";
import {
  AppClientSnapShot,
  RemunerationCategory,
} from "@haywork/api/authorization";

const styles = require("./partner.component.scss");

interface Props {
  partner: AppClientSnapShot;
  onClick: (partner: AppClientSnapShot) => void;
}

export const AppXchangePartnerWidget = CSSModules(styles, {
  allowMultiple: true,
})((props: Props) => {
  const remunerationStyle = classNames("pill", {
    "is-paid": props.partner.remunerationCategory !== RemunerationCategory.Free,
  });
  const activeStyle = classNames("pill", "action", {
    active: props.partner.isActive,
  });

  const translation = head(props.partner.translationSnapShots);

  const imageStyles: React.CSSProperties = !!translation.logoUrl
    ? { backgroundImage: `url(${translation.logoUrl})` }
    : null;

  return (
    <div styleName="widget" onClick={() => props.onClick(props.partner)}>
      <div styleName="widget__backdrop" style={imageStyles}>
        {!translation.logoUrl && <i className="fal fa-rocket" />}
      </div>
      <div styleName="widget__meta">
        <div styleName="score">
          <h2>
            <ResourceText
              masterKey="appClientCategoryOptions"
              resourceKey={props.partner.category.toString()}
            />
          </h2>
          {!!props.partner.rating && (
            <Ui.Score score={props.partner.rating} size={UiScoreSize.Small} />
          )}
        </div>
        <h1>{translation.title}</h1>
      </div>
      <div styleName="widget__body">
        {StringUtil.trim(translation.introduction, 128)}
      </div>
      <div styleName="widget__footer">
        <div styleName="costs">
          <div styleName={remunerationStyle}>
            <ResourceText
              masterKey="remunerationCategoryOptions"
              resourceKey={props.partner.remunerationCategory.toString()}
            />
          </div>
        </div>
        <div styleName="action">
          <div styleName={activeStyle}>
            {!!props.partner.linkedConsent || props.partner.isFirstParty ? (
              <ResourceText
                resourceKey="active"
                fontStyle={FontStyle.UcFirst}
              />
            ) : (
              <ResourceText
                resourceKey="moreInfo"
                fontStyle={FontStyle.UcFirst}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
