import {
  AppClientSnapShot,
  AppClientCategory,
  RemunerationCategory
} from "@haywork/api/authorization";

export enum appClientActions {
  SetClientsStatus = "Authorization.AppClients.SetClientsStatus",
  SetClients = "Authorization.AppClients.SetClients",
  ToggleFilter = "Authorization.AppClients.ToggleFilter",
  ClearFilters = "Authorization.AppClients.ClearFilters",
  ToggleRemuneration = "Authorization.AppClients.ToggleRemuneration",
  ResetApp = "RESET_APP"
}

interface ResetApp {
  type: appClientActions.ResetApp;
}

interface SetClientsStatus {
  type: appClientActions.SetClientsStatus;
  clientsStatus: string;
}
const setClientsStatus = (clientsStatus: string) => ({
  type: appClientActions.SetClientsStatus,
  clientsStatus
});

interface SetClients {
  type: appClientActions.SetClients;
  clients: AppClientSnapShot[];
  specials: AppClientSnapShot[];
}
const setClients = (
  clients: AppClientSnapShot[],
  specials: AppClientSnapShot[]
) => ({
  type: appClientActions.SetClients,
  clients,
  specials
});

interface ToggleFilter {
  type: appClientActions.ToggleFilter;
  category: AppClientCategory;
}
const toggleFilter = (category: AppClientCategory) => ({
  type: appClientActions.ToggleFilter,
  category
});

interface ClearFilters {
  type: appClientActions.ClearFilters;
}
const clearFilters = () => ({
  type: appClientActions.ClearFilters
});

interface ToggleRemuneration {
  type: appClientActions.ToggleRemuneration;
  category: RemunerationCategory;
}
const toggleRemuneration = (category: RemunerationCategory) => ({
  type: appClientActions.ToggleRemuneration,
  category
});

export type AppClientActions =
  | SetClientsStatus
  | SetClients
  | ToggleFilter
  | ClearFilters
  | ToggleRemuneration
  | ResetApp;
export const Actions = {
  setClientsStatus,
  setClients,
  toggleFilter,
  clearFilters,
  toggleRemuneration
};
