import {
  AcquisitionObjectAssignment,
  ConstructionPeriodOption,
  ListingType,
  ObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { NumberUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  constructionPeriods: ConstructionPeriodOption[];
  onChange: (values: FormReturnValue) => void;
  asSubForm?: boolean;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalSizesAndNumbersComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    const surface = NumberUtil.surfaceToSurfacesObject(
      get(this.props.objectAssignment, "parcelSurface.area")
    );
    const homePlotSurface = NumberUtil.surfaceToSurfacesObject(
      get(this.props.objectAssignment, "homePlotSurface")
    );

    this.formControls = {
      constructionYear: {
        value: get(
          this.props.objectAssignment,
          "yearOfConstruction.constructionYear",
          ""
        ),
        onChange: this.setConstructionPeriod.bind(this),
      },
      constructionPeriod: {
        value: get(
          this.props.objectAssignment,
          "yearOfConstruction.period",
          ""
        ),
      },
      surfaceHa: { value: surface.hectare },
      surfaceA: { value: surface.are },
      surfaceCa: { value: surface.centiare },
      homePlotSurfaceHa: { value: homePlotSurface.hectare },
      homePlotSurfaceA: { value: homePlotSurface.are },
      homePlotSurfaceCa: { value: homePlotSurface.centiare },
      parcelSurface: {
        value: get(this.props.objectAssignment, "parcelSurface.area", ""),
      },
      contents: {
        value: get(this.props.objectAssignment, "contents.volume", ""),
      },
      length: { value: get(this.props.objectAssignment, "length", "") },
      width: { value: get(this.props.objectAssignment, "width", "") },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const surface = NumberUtil.surfaceToSurfacesObject(
        get(this.props.objectAssignment, "parcelSurface.area")
      );
      const homePlotSurface = NumberUtil.surfaceToSurfacesObject(
        get(this.props.objectAssignment, "homePlotSurface")
      );

      this.formRef.update(
        {
          constructionYear: get(
            this.props.objectAssignment,
            "yearOfConstruction.constructionYear",
            ""
          ),
          constructionPeriod: get(
            this.props.objectAssignment,
            "yearOfConstruction.period",
            ""
          ),
          surfaceHa: surface.hectare,
          surfaceA: surface.are,
          surfaceCa: surface.centiare,
          homePlotSurfaceHa: homePlotSurface.hectare,
          homePlotSurfaceA: homePlotSurface.are,
          homePlotSurfaceCa: homePlotSurface.centiare,
          parcelSurface: get(
            this.props.objectAssignment,
            "parcelSurface.area",
            ""
          ),
          contents: get(this.props.objectAssignment, "contents.volume", ""),
          length: get(this.props.objectAssignment, "length", ""),
          width: get(this.props.objectAssignment, "width", ""),
        },
        true
      );
    }
  }

  public render() {
    const { listingType } = this.props.objectAssignment;

    return (
      <Form
        name="features-figures"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        {/* Contruction year & period */}
        {this.props.objectAssignment.listingType !==
          ListingType.ArableCompany && (
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="constructionYear">
                  <ResourceText resourceKey="constructionYear" />
                </label>
                <Input.Number
                  name="constructionYear"
                  max={new Date().getFullYear() + 50}
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="constructionPeriod">
                  <ResourceText resourceKey="constructionPeriod" />
                </label>
                <Input.NewSelect
                  name="constructionPeriod"
                  values={this.props.constructionPeriods}
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>
        )}

        {/* Parcelsurface */}
        {listingType !== ListingType.AgriculturalHouse && (
          <div className="form__row">
            <label htmlFor="surfaceHa">
              <ResourceText resourceKey="parcelSurface" />
            </label>
            <div className="form__group">
              <div className="column">
                <div className="input__helper">
                  <Input.Number
                    name="surfaceHa"
                    round
                    max={9999999999}
                    min={0}
                  />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageHAAffix" />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <Input.Number name="surfaceA" round max={99} min={0} />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageAAffix" />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <Input.Number name="surfaceCa" round max={99} min={0} />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageCAAffix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {listingType !== ListingType.AgriculturalHouse && (
          <div className="form__row">
            <label htmlFor="homePlotSurfaceHa">
              <ResourceText resourceKey="homePlotSurface" />
            </label>
            <div className="form__group">
              <div className="column">
                <div className="input__helper">
                  <Input.Number
                    name="homePlotSurfaceHa"
                    round
                    max={9999999999}
                    min={0}
                  />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageHAAffix" />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <Input.Number
                    name="homePlotSurfaceA"
                    round
                    max={99}
                    min={0}
                  />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageAAffix" />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <Input.Number
                    name="homePlotSurfaceCa"
                    round
                    max={99}
                    min={0}
                  />
                  <div className="post">
                    <ResourceText resourceKey="cadastreAcreageCAAffix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {listingType === ListingType.AgriculturalHouse && (
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="parcelSurface">
                  <ResourceText resourceKey="parcelSurface" />
                </label>
                <div className="input__helper">
                  <Input.Number
                    name="parcelSurface"
                    placeholder="parcelSurface"
                    pretty
                  />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="contents">
                  <ResourceText resourceKey="contents" />
                </label>
                <div className="input__helper">
                  <Input.Number name="contents" placeholder="contents" pretty />
                  <div className="post">
                    <ResourceText resourceKey="cubicMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {listingType === ListingType.LooseSoil && (
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="length">
                  <ResourceText resourceKey="groundLength" />
                </label>
                <div className="input__helper">
                  <Input.Number
                    name="length"
                    placeholder="groundLength"
                    pretty
                  />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="width">
                  <ResourceText resourceKey="groundWidth" />
                </label>
                <div className="input__helper">
                  <Input.Number name="width" placeholder="groundWidth" pretty />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let updatedObjectAssignment:
      | ObjectAssignment
      | AcquisitionObjectAssignment = {
      ...objectAssignment,
      yearOfConstruction: {
        ...objectAssignment.yearOfConstruction,
        constructionYear: values.constructionYear,
        period: values.constructionPeriod,
      },
    };

    if (objectAssignment.listingType !== ListingType.AgriculturalHouse) {
      updatedObjectAssignment = {
        ...updatedObjectAssignment,
        parcelSurface: {
          ...objectAssignment.parcelSurface,
          area: NumberUtil.surfacesToSurface(
            values.surfaceHa,
            values.surfaceA,
            values.surfaceCa
          ),
        },
        homePlotSurface: NumberUtil.surfacesToSurface(
          values.homePlotSurfaceHa,
          values.homePlotSurfaceA,
          values.homePlotSurfaceCa
        ),
      };
    }
    if (objectAssignment.listingType === ListingType.AgriculturalHouse) {
      updatedObjectAssignment = {
        ...updatedObjectAssignment,
        parcelSurface: {
          ...updatedObjectAssignment.parcelSurface,
          area: values.parcelSurface,
        },
        contents: {
          ...updatedObjectAssignment.contents,
          volume: values.contents,
        },
      };
    }
    if (objectAssignment.listingType === ListingType.LooseSoil) {
      updatedObjectAssignment = {
        ...updatedObjectAssignment,
        length: values.length,
        width: values.width,
      };
    }

    this.props.onChange(updatedObjectAssignment);
  }

  private setConstructionPeriod(control: RawFormControl) {
    const { value } = control;
    const year = parseInt(value);

    const period = this.props.constructionPeriods.find(
      (p) => p.minYear <= year && p.maxYear >= year
    );
    if (period) return { constructionPeriod: period.value };
  }
}
