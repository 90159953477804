import {
  AppClient,
  ReviewSnapShot,
  AppClientSnapShot,
  EmployeeSettingsSingleItemResponse,
  CompanySettingsSingleItemResponse
} from "@haywork/api/authorization";
import { REQUEST, REDUX } from "@haywork/constants";

import {
  SingleAppClientActions,
  singleAppClienActions
} from "./single-app-client.actions";

export interface SingleAppClientState {
  clientStatus: string;
  client: AppClient;
  employeeSettings: EmployeeSettingsSingleItemResponse;
  companySettings: CompanySettingsSingleItemResponse;
  settingsStatus: string;
  consentModalVisible: boolean;
  consentStatus: string;
  retractConsentConfirm: boolean;
  reviews: ReviewSnapShot[];
  reviewsStatus: string;
  recommendations: AppClientSnapShot[];
}

const INITIAL_STATE: SingleAppClientState = {
  clientStatus: REQUEST.IDLE,
  client: null,
  employeeSettings: null,
  companySettings: null,
  settingsStatus: REQUEST.IDLE,
  consentModalVisible: false,
  consentStatus: REQUEST.IDLE,
  retractConsentConfirm: false,
  reviews: [],
  reviewsStatus: REQUEST.IDLE,
  recommendations: []
};

export const singleAppClientReducer = (
  state: SingleAppClientState = INITIAL_STATE,
  action: SingleAppClientActions
): SingleAppClientState => {
  switch (action.type) {
    case singleAppClienActions.SetStatus: {
      const { clientStatus } = action;

      return {
        ...state,
        clientStatus
      };
    }
    case singleAppClienActions.SetClient: {
      const { client } = action;

      return {
        ...state,
        client,
        clientStatus: REQUEST.SUCCESS
      };
    }
    case singleAppClienActions.ToggleConsentModal: {
      const { consentModalVisible } = action;

      return {
        ...state,
        consentModalVisible
      };
    }
    case singleAppClienActions.SetReviewsStatus: {
      const { reviewsStatus } = action;

      return {
        ...state,
        reviewsStatus
      };
    }
    case singleAppClienActions.SetReviews: {
      const { reviews } = action;

      return {
        ...state,
        reviews,
        reviewsStatus: REQUEST.SUCCESS
      };
    }
    case singleAppClienActions.SetConsentStatus: {
      const { consentStatus } = action;

      return {
        ...state,
        consentStatus
      };
    }
    case singleAppClienActions.ToggleConsentRetractConfirm: {
      const { retractConsentConfirm } = action;

      return {
        ...state,
        retractConsentConfirm
      };
    }
    case singleAppClienActions.SetRecommendations: {
      const { recommendations } = action;

      return {
        ...state,
        recommendations
      };
    }
    case singleAppClienActions.SetSettings: {
      const { employeeSettings, companySettings } = action;

      return {
        ...state,
        employeeSettings,
        companySettings,
        settingsStatus: REQUEST.SUCCESS
      };
    }
    case singleAppClienActions.UpdateCompanySettings: {
      const { companySettings } = action;

      return {
        ...state,
        companySettings,
        settingsStatus: REQUEST.SUCCESS
      };
    }
    case singleAppClienActions.UpdateEmployeeSettings: {
      const { employeeSettings } = action;

      return {
        ...state,
        employeeSettings,
        settingsStatus: REQUEST.SUCCESS
      };
    }
    case singleAppClienActions.SetSettingsStatus: {
      const { settingsStatus } = action;

      return {
        ...state,
        settingsStatus
      };
    }
    case singleAppClienActions.ClearSettings: {
      return {
        ...state,
        settingsStatus: REQUEST.IDLE,
        employeeSettings: null,
        companySettings: null
      };
    }
    case singleAppClienActions.ResetApp: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
