import { RootEntityType, EntityDetails } from "@haywork/api/event-center";
import {
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentPhase,
  AssignmentsClient,
  AssignmentSnapShot,
  AssignmentType,
  FloorType,
  Language,
  ObjectTypeAssignment,
  ObjectTypeAssignmentsClient,
  ProjectAssignmentsClient,
  RealEstateGroup,
  SortOrder,
  TypePART,
  UpdateAvailabilityAction,
  ObjectTypeAssignmentLinkRelationRequest,
} from "@haywork/api/kolibri";
import {
  MAINROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  REQUEST,
} from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ParseRequest, ApiType } from "@haywork/services";
import {
  AppState,
  EditableActions,
  LayoutActions,
  ProjectsActions,
  EditableItem,
} from "@haywork/stores";
import { ExtendedObjectTypeAssignment } from "@haywork/stores/project/types";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { AsyncUtil, BackOfficeUtil, DateUtil, RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import get from "lodash-es/get";
import { Dispatch } from "../";
import { push } from "connected-react-router";
import * as moment from "moment";
import has from "lodash-es/has";

const route = RouteUtil.mapStaticRouteValues;
const parseRequest = new ParseRequest();

const getType = (id: string, snapshot: AssignmentSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setTypeStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      let currentType: ExtendedObjectTypeAssignment =
        await parseRequest.response(
          ObjectTypeAssignments.read(id, realEstateAgencyId).then(
            (response) => response.objectTypeAssignment
          )
        );

      if (!currentType.floors) {
        currentType = {
          ...currentType,
          floors: [],
        };
      }

      if (!currentType.floors.length) {
        currentType = {
          ...currentType,
          floors: [
            {
              floorType: FloorType.GroundFloor,
              floorNumber: 1,
              spaces: [],
            },
          ],
          numberOfFloors: 1,
        };
      }

      if (has(currentType.linkedProjectAssignment, "id")) {
        const parentProject = await parseRequest.response(
          ProjectAssignments.read(
            currentType.linkedProjectAssignment.id,
            realEstateAgencyId
          ).then((response) => response.projectAssignment)
        );

        currentType = {
          ...currentType,
          parentProject,
        };
      }

      let title = "...";
      const titleText = get(currentType, "titleText");

      if (!!titleText) {
        const text = titleText.find(
          (title) => title.language === Language.Dutch
        );
        if (!!text) {
          title = text.text;
        }
      }

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      const componentState = {
        icon: MAINROUTES.OBJECTTYPES.ICON,
        componentState: currentType,
        path,
        title,
        hasChanges: false,
        confirm: {
          title: { key: "saveObjectTypeConfirmTitle" },
          body: { key: "saveObjectTypeConfirmBody" },
        },
        entityType: RootEntityType.ObjectTypeAssignment,
        entityId: currentType.id,
      };

      dispatch(ProjectsActions.Types.setType({ currentType }));
      dispatch(EditableActions.addState(componentState));
      dispatch(push(path));
    } catch (error) {
      dispatch(ProjectsActions.Types.setTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveType = (
  objectTypeAssignment: ObjectTypeAssignment,
  redirect: boolean = true
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);

    try {
      if (objectTypeAssignment.isNew) {
        const existing = await parseRequest.response(
          Assignments.search(
            {
              orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              assignmentIds: [objectTypeAssignment.id],
              forRent: true,
              forSale: true,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          objectTypeAssignment = {
            ...objectTypeAssignment,
            isNew: false,
          };
        }
      }

      if (!objectTypeAssignment.floors) {
        objectTypeAssignment = {
          ...objectTypeAssignment,
          floors: [],
        };
      }

      if (!objectTypeAssignment.floors.length) {
        objectTypeAssignment = {
          ...objectTypeAssignment,
          floors: [
            {
              floorType: FloorType.GroundFloor,
              floorNumber: 1,
              spaces: [],
            },
          ],
          numberOfFloors: 1,
        };
      }

      if (
        objectTypeAssignment.typePART === TypePART.Apartment &&
        !!objectTypeAssignment.parcelSurface
      ) {
        objectTypeAssignment = {
          ...objectTypeAssignment,
          parcelSurface: null,
        };
      }

      let currentType = await parseRequest.response(
        ObjectTypeAssignments.save(
          {
            objectTypeAssignment,
          },
          realEstateAgencyId
        ).then((response) => response.objectTypeAssignment)
      );

      if (has(currentType.linkedProjectAssignment, "id")) {
        const parentProject = await parseRequest.response(
          ProjectAssignments.read(
            currentType.linkedProjectAssignment.id,
            realEstateAgencyId
          ).then((response) => response.projectAssignment)
        );

        currentType = {
          ...currentType,
          parentProject,
        } as ExtendedObjectTypeAssignment;
      }

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      dispatch(ProjectsActions.Types.setType({ currentType }));

      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: currentType,
        })
      );

      if (!!redirect) {
        dispatch(push(path));
      }

      if (!redirect) {
        const toast: ToastProps = {
          value: "toastObjectTypeSaved",
          icon: "puzzle-piece",
        };

        dispatch(SnackbarActions.addToast(toast));
      }
    } catch (error) {
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const linkRelation = (
  relation: ObjectTypeAssignmentLinkRelationRequest,
  updatedObjectTypeAssignment: ObjectTypeAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
      id: updatedObjectTypeAssignment.id,
    });

    let { currentComponentState: componentState } = state.editable;

    // Clients
    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    try {
      await ObjectTypeAssignments.linkRelation(relation, realEstateAgencyId);
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.SUCCESS));

      componentState = {
        ...componentState,
        ...updatedObjectTypeAssignment,
      };

      dispatch(
        ProjectsActions.Types.setType({
          currentType: updatedObjectTypeAssignment,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unlinkRelation = (
  relation: ObjectTypeAssignmentLinkRelationRequest,
  updatedObjectTypeAssignment: ObjectTypeAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
      id: updatedObjectTypeAssignment.id,
    });
    let componentState = state.editable.currentComponentState;

    // Clients
    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    try {
      await ObjectTypeAssignments.unlinkRelation(relation, realEstateAgencyId);
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.SUCCESS));

      componentState = {
        ...componentState,
        ...updatedObjectTypeAssignment,
      };
      dispatch(
        ProjectsActions.Types.setType({
          currentType: updatedObjectTypeAssignment,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const publishType = (
  objectTypeAssignment: ObjectTypeAssignment,
  createNew: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    let currentType: ExtendedObjectTypeAssignment;
    try {
      currentType = await parseRequest.response(
        ObjectTypeAssignments.save(
          {
            objectTypeAssignment,
          },
          realEstateAgencyId
        ).then((response) => response.objectTypeAssignment)
      );
    } catch (error) {
      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: currentType,
        })
      );
      dispatch(ProjectsActions.Types.setType({ currentType }));
    }

    try {
      currentType = await parseRequest.response(
        ObjectTypeAssignments.updateAvailability(
          {
            id: currentType.id,
            updateAvailabilityAction: UpdateAvailabilityAction.ToAvailable,
          },
          realEstateAgencyId
        ).then((response) => response.objectTypeAssignment)
      );

      const parentProject = await parseRequest.response(
        ProjectAssignments.read(
          currentType.linkedProjectAssignment.id,
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      currentType = {
        ...currentType,
        parentProject,
      };

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: currentType,
        })
      );
      dispatch(ProjectsActions.Types.setType({ currentType }));
      dispatch(push(path));

      if (!!createNew) {
        dispatch(newType(currentType.linkedProjectAssignment.id));
      }
    } catch (error) {
      dispatch(reloadObjectTypeWithParent(objectTypeAssignment.id));
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const reloadObjectTypeWithParent = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      let currentType: ExtendedObjectTypeAssignment =
        await parseRequest.response(
          ObjectTypeAssignments.read(id, realEstateAgencyId).then(
            (response) => response.objectTypeAssignment
          )
        );

      const parentProject = await parseRequest.response(
        ProjectAssignments.read(
          currentType.linkedProjectAssignment.id,
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      currentType = {
        ...currentType,
        parentProject,
      };

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: currentType,
        })
      );
      dispatch(ProjectsActions.Types.setType({ currentType }));
    } catch (error) {
      throw error;
    }
  };
};

const newType = (projectId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { offices } = state.account;
    let officeId = "";
    if (offices.length > 0) {
      officeId = first(offices).id;
    }

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      let currentType: ExtendedObjectTypeAssignment =
        await parseRequest.response(
          ObjectTypeAssignments.defineNew(
            {
              employeeId,
              officeId,
              projectId,
              realEstateGroup: RealEstateGroup.Residential,
              typePART: TypePART.Apartment,
            },
            realEstateAgencyId
          ).then((response) => response.objectTypeAssignment)
        );

      if (!currentType.floors) {
        currentType = {
          ...currentType,
          floors: [],
        };
      }

      if (!currentType.floors.length) {
        currentType = {
          ...currentType,
          floors: [
            {
              floorType: FloorType.GroundFloor,
              floorNumber: 1,
              spaces: [],
            },
          ],
          numberOfFloors: 1,
        };
      }

      const parentProject = await parseRequest.response(
        ProjectAssignments.read(projectId, realEstateAgencyId).then(
          (response) => response.projectAssignment
        )
      );

      currentType = {
        ...currentType,
        amountUnits: 1,
        parentProject,
      };

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      const redirect = route(OBJECTTYPESROUTES.EDIT.URI, {
        id: currentType.id,
      });

      const componentState = {
        icon: MAINROUTES.OBJECTTYPES.ICON,
        componentState: currentType,
        path,
        title: "...",
        hasChanges: false,
        confirm: {
          title: { key: "saveObjectTypeConfirmTitle" },
          body: { key: "saveObjectTypeConfirmBody" },
        },
        entityType: RootEntityType.ObjectTypeAssignment,
        entityId: currentType.id,
      };

      dispatch(ProjectsActions.Types.setType({ currentType }));
      dispatch(EditableActions.addState(componentState));
      dispatch(push(redirect));
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    } catch (error) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      throw error;
    }
  };
};

const saveAndCloseObjectType = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { currentComponentState: objectTypeAssignment } = state.editable;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);

    try {
      if (objectTypeAssignment.isNew) {
        const existing = await parseRequest.response(
          Assignments.search(
            {
              orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              assignmentIds: [objectTypeAssignment.id],
              forRent: true,
              forSale: true,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          objectTypeAssignment = {
            ...objectTypeAssignment,
            isNew: false,
          };
        }
      }

      await parseRequest.response(
        ObjectTypeAssignments.save({ objectTypeAssignment }, realEstateAgencyId)
      );

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
        id: objectTypeAssignment.id,
      });
      dispatch(EditableThunks.remove(path));
    } catch (error) {
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateAvailability = (
  id: string,
  updateAvailabilityAction: UpdateAvailabilityAction
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentComponentState: objectTypeAssignment } = state.editable;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);
    const ProjectAssignments = new ProjectAssignmentsClient(host);

    try {
      let currentType: ExtendedObjectTypeAssignment =
        await parseRequest.response(
          ObjectTypeAssignments.updateAvailability(
            {
              id,
              updateAvailabilityAction,
            },
            realEstateAgencyId
          ).then((response) => response.objectTypeAssignment)
        );

      const parentProject = await parseRequest.response(
        ProjectAssignments.read(
          currentType.linkedProjectAssignment.id,
          realEstateAgencyId
        ).then((response) => response.projectAssignment)
      );

      currentType = {
        ...currentType,
        parentProject,
      };

      const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: currentType.id });
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: currentType,
        })
      );
      dispatch(ProjectsActions.Types.setType({ currentType }));
    } catch (error) {
      dispatch(ProjectsActions.Types.setSaveObjectTypeStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getBuildnumbers = (
  objectTypeId: string,
  filterByActive: ActiveFilter,
  reset: boolean = false,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(ProjectsActions.Types.setBuildnumbersStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { buildnumbers: items } = state.project.types;
    items = items.filter(
      (item) => get(item, "linkedObjectTypeAssignment.id") === objectTypeId
    );
    const skip = reset ? 0 : items.length;

    const Assignments = new AssignmentsClient(host);

    try {
      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ConstructionNumber,
            filterByActive,
            order: SortOrder.Ascending,
            skip,
            take,
            filterByObjectTypeAssignmentIds: [objectTypeId],
            filterByAssignmentTypes: [AssignmentType.Object],
          },
          realEstateAgencyId
        )
      );

      const { results: buildnumbers, totalResults: buildnumbersTotal } =
        response;
      const buildnumbersCanLoadMore =
        skip + buildnumbers.length < buildnumbersTotal;

      if (skip === 0) {
        dispatch(
          ProjectsActions.Types.setBuildNumbers({
            buildnumbers,
            buildnumbersCanLoadMore,
            buildnumbersTotal,
          })
        );
      } else {
        dispatch(
          ProjectsActions.Types.addBuildNumbers({
            buildnumbers,
            buildnumbersCanLoadMore,
            buildnumbersTotal,
          })
        );
      }
    } catch (error) {
      dispatch(ProjectsActions.Types.setBuildnumbersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const removeType = (typeId: string, projectId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: typeId });

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);

    try {
      await parseRequest.response(
        ObjectTypeAssignments.delete(typeId, realEstateAgencyId)
      );

      dispatch(EditableThunks.remove(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const archive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
    const currentComponentState: ObjectTypeAssignment =
      state.editable.currentComponentState;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);

    try {
      if (currentComponentState.assignmentPhase === AssignmentPhase.Initiated) {
        await parseRequest.response(
          ObjectTypeAssignments.save(
            {
              objectTypeAssignment: {
                ...currentComponentState,
                assignmentPhase: AssignmentPhase.Completed,
              },
            },
            realEstateAgencyId
          )
        );
      }

      await parseRequest.response(
        ObjectTypeAssignments.archive({ id }, realEstateAgencyId)
      );

      const objectTypeAssignment = await parseRequest.response(
        ObjectTypeAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectTypeAssignment
        )
      );

      const componentState = {
        ...currentComponentState,
        ...objectTypeAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(
        ProjectsActions.Types.setType({ currentType: objectTypeAssignment })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const unArchive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
    const currentComponentState: ObjectTypeAssignment =
      state.editable.currentComponentState;

    const ObjectTypeAssignments = new ObjectTypeAssignmentsClient(host);

    try {
      await parseRequest.response(
        ObjectTypeAssignments.unarchive({ id }, realEstateAgencyId)
      );

      const objectTypeAssignment = await parseRequest.response(
        ObjectTypeAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectTypeAssignment
        )
      );

      const componentState = {
        ...currentComponentState,
        ...objectTypeAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(
        ProjectsActions.Types.setType({ currentType: objectTypeAssignment })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const backToDashboard = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentType } = state.project.types;
    const currentComponentState: ExtendedObjectTypeAssignment =
      state.editable.currentComponentState;

    if (get(currentType, "id") !== get(currentComponentState, "id")) return;
    const { id } = currentType;
    const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });

    dispatch(
      EditableActions.updateComponentState({
        ignoreChanges: true,
        path,
        componentState: currentType,
        hasChanges: false,
      })
    );
    dispatch(push(path));
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: ExtendedObjectTypeAssignment =
      editable.componentState;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new ObjectTypeAssignmentsClient(host);
      const refObjectTypeAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.objectTypeAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: ExtendedObjectTypeAssignment = {
          ...componentState,
          ...refObjectTypeAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(
            ProjectsActions.Types.setType({ currentType: newComponentState })
          );
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refObjectTypeAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refObjectTypeAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadObjectType = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const objectTypeAssignment =
      externalChangesData.updatedEntity as ExtendedObjectTypeAssignment;
    const componentState: ExtendedObjectTypeAssignment = {
      ...editable.componentState,
      ...objectTypeAssignment,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(ProjectsActions.Types.setType({ currentType: componentState }));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

export const ObjectTypesThunks = {
  getType,
  saveType,
  publishType,
  newType,
  saveAndCloseObjectType,
  updateAvailability,
  getBuildnumbers,
  removeType,
  archive,
  unArchive,
  backToDashboard,
  checkExternalChanges,
  reloadObjectType,
  linkRelation,
  unlinkRelation,
};
