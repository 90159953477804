import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  ObjectAssignment,
  TaskCategoryOption,
  TimelineActionType,
  TimelineEvent,
  TimelineEventsStatistics,
} from "@haywork/api/kolibri";
import {
  AssignmentThunks,
  AuthorizationThunks,
  MapDispatchToProps,
} from "@haywork/middleware";
import {
  AssignmentDetailTimelineComponent,
  AssignmentDetailTimelineComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  events: TimelineEvent[];
  timelineStatus: string;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  canLoadMore: boolean;
  timelineStatistics: TimelineEventsStatistics;
}
interface DispatchProps {
  getTimelineEvents: (
    assignmentId: string,
    actionType?: TimelineActionType[],
    init?: boolean
  ) => void;
  navigate: (route: string) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailTimelineComponentProps,
  AppState
> = (state) => {
  const {
    objectAssignment,
    events,
    timelineStatus,
    timelinePage,
    timelinePageCount,
    timelineStatistics,
  } = state.assignment.single;

  return {
    objectAssignment,
    events,
    timelineStatus,
    taskCategories: state.main.mastertable.kolibri.taskCategories,
    agendaItemCategories: state.scheduler.agendaItemCategories,
    canLoadMore: timelinePage < timelinePageCount,
    timelineStatistics,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailTimelineComponentProps
> = (dispatch) => ({
  getTimelineEvents: (
    assignmentId: string,
    actionType?: TimelineActionType[],
    init?: boolean
  ) =>
    dispatch(
      AssignmentThunks.getTimelineEvents(assignmentId, actionType, init)
    ),
  navigate: (route: string) => dispatch(push(route)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type AssignmentDetailTimelineContainerProps = StateProps & DispatchProps;
export const AssignmentDetailTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailTimelineComponent);
