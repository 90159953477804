import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import * as React from "react";
import { AuthorizationWidgetsContainerProps } from "./authorization-widgets.container";
import Dashboard from "./components/dashboard";
import Dossier from "./components/dossier";
import MenuButton from "./components/menu-button";
import Page from "./components/page";
import Sidebar from "./components/sidebar";
import Timeline from "./components/timeline";
import ContextMenu from "./components/context-menu";
import sortBy from "lodash-es/sortBy";

export interface AuthorizationWidgetsComponentProps {
  location: WidgetEntityLocation;
  type: WidgetEntityType;
  id?: string;
  menuType?: "menu-button" | "page";
  selectedIds?: string[];
  widgetId?: string;
  onActionClick?: () => void;
}
interface State {}
type Props = AuthorizationWidgetsComponentProps &
  AuthorizationWidgetsContainerProps;

export class AuthorizationWidgetsComponent extends React.PureComponent<
  Props,
  State
> {
  public render() {
    const { location, widgets, menuType, id, widgetId, type } = this.props;

    switch (location) {
      case WidgetEntityLocation.Sidebar: {
        return (
          <React.Fragment>
            {widgets.map((widget) => (
              <Sidebar
                widget={widget}
                key={widget.id}
                initializedWidgets={this.props.initializedWidgets}
              />
            ))}
          </React.Fragment>
        );
      }
      case WidgetEntityLocation.Timeline:
      case WidgetEntityLocation.Network: {
        return (
          <Timeline
            widgets={widgets}
            initializedWidgets={this.props.initializedWidgets}
            showModal={this.props.showModal}
          />
        );
      }
      case WidgetEntityLocation.Dossier: {
        return (
          <Dossier
            widgets={widgets}
            showModal={this.props.showModal}
            entityId={this.props.id}
            entityType={this.props.type}
            getInitializedWidgets={this.props.getInitializedWidgets}
            onActionClick={this.props.onActionClick}
          />
        );
      }
      case WidgetEntityLocation.Dashboard: {
        return (
          <React.Fragment>
            {widgets.map((widget) => (
              <Dashboard
                widget={widget}
                key={widget.id}
                getInitializedWidgets={this.props.getInitializedWidgets}
              />
            ))}
          </React.Fragment>
        );
      }
      case WidgetEntityLocation.ListContextMenu: {
        return (
          <ContextMenu
            widgets={widgets}
            showModal={this.props.showModal}
            entityIds={this.props.selectedIds}
            entityType={this.props.type}
            getInitializedWidgets={this.props.getInitializedWidgets}
            onActionClick={this.props.onActionClick}
          />
        );
      }
      case WidgetEntityLocation.Menu: {
        switch (menuType) {
          case "menu-button": {
            const sortedWidgets = sortBy(
              widgets || [],
              (widget) => widget.order
            );

            return (
              <React.Fragment>
                {sortedWidgets.map((widget) => (
                  <MenuButton
                    entityId={id}
                    widget={widget}
                    key={widget.id}
                    type={type}
                  />
                ))}
              </React.Fragment>
            );
          }
          case "page": {
            return (
              <Page
                entityId={id}
                widgets={widgets}
                key={widgetId}
                type={type}
              />
            );
          }
          default: {
            return null;
          }
        }
      }
      default: {
        return null;
      }
    }
  }
}
