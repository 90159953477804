import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { GlobalSearchResponseItem } from "@haywork/request";
import { GlobalSearchType, AssignmentType } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import { StringUtil } from "@haywork/util";
import { Colors } from "@haywork/enum/colors";
import Pill from "@haywork/components/ui/pill";

const styles = require("./style.scss");

type Props = {
  item: GlobalSearchResponseItem;
  query: string;
};

export const OptionComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ item, query }) => {
    const { type, assignment, invoice, relation, task } = item;
    const icon = useMemo(() => {
      switch (type) {
        case GlobalSearchType.Assignments: {
          return (
            <Icon name="folder-open" size={14} color={Colors.Black} solid />
          );
        }
        case GlobalSearchType.Invoices: {
          return <Icon name="euro-sign" size={14} color={Colors.Black} solid />;
        }
        case GlobalSearchType.Relations: {
          return <Icon name="user" size={14} color={Colors.Black} solid />;
        }
        case GlobalSearchType.Tasks: {
          return <Icon name="tasks" size={14} color={Colors.Black} solid />;
        }
        default: {
          return null;
        }
      }
    }, [type]);

    const displayName = useMemo(() => {
      switch (type) {
        case GlobalSearchType.Assignments: {
          if (!assignment) return null;
          const { displayName, publicReference, typeOfAssignment } = assignment;
          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              return publicReference;
            }
            default: {
              return displayName;
            }
          }
        }
        case GlobalSearchType.Invoices: {
          if (!invoice) return null;
          return !!invoice.displayName ? (
            <div
              dangerouslySetInnerHTML={StringUtil.highlight(
                invoice.displayName,
                query
              )}
            />
          ) : (
            <I18n value="conceptInvoice" />
          );
        }
        case GlobalSearchType.Relations: {
          if (!relation) return null;
          return !!relation.displayName ? (
            <div
              dangerouslySetInnerHTML={StringUtil.highlight(
                relation.displayName,
                query
              )}
            />
          ) : null;
        }
        case GlobalSearchType.Tasks: {
          if (!task) return null;
          return !!task.subject ? (
            <div
              dangerouslySetInnerHTML={StringUtil.highlight(
                task.subject,
                query
              )}
            />
          ) : null;
        }
        default: {
          return null;
        }
      }
    }, [type, assignment, invoice, relation, task]);

    const isActive = useMemo(() => {
      switch (type) {
        case GlobalSearchType.Assignments: {
          if (!assignment) return true;
          return assignment.isActive;
        }
        case GlobalSearchType.Invoices: {
          if (!invoice) return true;
          return invoice.isActive;
        }
        case GlobalSearchType.Relations: {
          if (!relation) return true;
          return relation.isActive;
        }
        case GlobalSearchType.Tasks: {
          if (!task) return true;
          return task.isActive;
        }
        default: {
          return true;
        }
      }
    }, [type, assignment, invoice, relation, task]);

    const typeDescription = useMemo(() => {
      switch (type) {
        case GlobalSearchType.Assignments: {
          if (!assignment || !assignment.typeOfAssignment)
            return (
              <Pill
                label="assignment"
                color={Colors.LightGray}
                textColor={Colors.Gray}
                solid
              />
            );
          return (
            <Pill
              labelPrefix="assignmentTypes"
              label={assignment.typeOfAssignment.toString()}
              color={Colors.LightGray}
              textColor={Colors.Gray}
              solid
            />
          );
        }
        case GlobalSearchType.Invoices: {
          return (
            <Pill
              label="invoice"
              color={Colors.LightGray}
              textColor={Colors.Gray}
              solid
            />
          );
        }
        case GlobalSearchType.Relations: {
          if (!relation || !relation.typeOfRelation)
            return (
              <Pill
                label="relation"
                color={Colors.LightGray}
                textColor={Colors.Gray}
                solid
              />
            );
          return (
            <Pill
              labelPrefix="relationTypes"
              label={relation.typeOfRelation.toString()}
              color={Colors.LightGray}
              textColor={Colors.Gray}
              solid
            />
          );
        }
        case GlobalSearchType.Tasks: {
          return (
            <Pill
              label="task"
              color={Colors.LightGray}
              textColor={Colors.Gray}
              solid
            />
          );
        }
        default: {
          return true;
        }
      }
    }, [type, assignment, relation]);

    return (
      <div className="query-component__option">
        {!!icon && <div styleName="section icon">{icon}</div>}
        {!!displayName && <div styleName="section stretch">{displayName}</div>}
        {!!typeDescription && <div styleName="section">{typeDescription}</div>}
        {!isActive && (
          <div styleName="section">
            <Pill
              label="archived"
              color={Colors.LightGray}
              textColor={Colors.Gray}
              solid
            />
          </div>
        )}
      </div>
    );
  })
);
