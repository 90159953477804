import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as bowser from "bowser";
import get from "lodash-es/get";
import classNames from "classnames";
import throttle from "lodash-es/throttle";

import { ResourceText } from "@haywork/modules/shared";

import { Browser } from "./browser.component";
import { EXTERNALROUTES } from "@haywork/constants";

const styles = require("./not-supported.component.scss");
const logo = require("static/Icon_dark.svg");

interface Props {}
interface State {
  size: string;
}

@CSSModules(styles, { allowMultiple: true })
export class NotSupportedComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const windowWidth = window.innerWidth;

    this.state = {
      size:
        windowWidth > 1624 ? "large" : windowWidth > 1132 ? "medium" : "small",
    };

    this.windowResize = throttle(this.windowResize.bind(this), 50);
    window.addEventListener("resize", this.windowResize, true);
  }

  public render() {
    const browser = bowser.getParser(window.navigator.userAgent);
    const osName = (
      (get(browser, "parsedResult.os.name") as string) || ""
    ).toLowerCase();
    const isMac = osName === "macos";
    const isWindows = osName === "windows";

    return (
      <table cellPadding="16" cellSpacing="0" styleName="body-table">
        <tbody>
          <tr>
            <td styleName={classNames("body-cell-left", this.state.size)} />
            <td styleName="body-cell">
              <table cellPadding="0" cellSpacing="0" styleName="content-table">
                <tbody>
                  <tr>
                    <td styleName="align-center">
                      <img src={logo} styleName="logo" />
                    </td>
                  </tr>
                  <tr>
                    <td styleName="align-center">
                      <ResourceText
                        resourceKey="browserNotSupported"
                        asHtml={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div styleName="browsers">
                        {isMac && (
                          <Browser
                            name="Safari"
                            logo={require("static/safari-browser.png")}
                            url={EXTERNALROUTES.DOWNLOAD_SAFARI.URI}
                          />
                        )}
                        <Browser
                          name="Chrome"
                          logo={require("static/chrome-browser.png")}
                          url={EXTERNALROUTES.DOWNLOAD_CHROME.URI}
                        />
                        {isWindows && (
                          <Browser
                            name="Edge"
                            logo={require("static/edge-browser.png")}
                            url={EXTERNALROUTES.DOWNLOAD_EDGE.URI}
                          />
                        )}
                        <Browser
                          name="Firefox"
                          logo={require("static/firefox-browser.png")}
                          url={EXTERNALROUTES.DOWNLOAD_FIREFOX.URI}
                        />
                        <Browser
                          name="Opera"
                          logo={require("static/opera-browser.png")}
                          url={EXTERNALROUTES.DOWNLOAD_OPERA.URI}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td styleName="align-center">
                      <ResourceText
                        resourceKey="browserNotSupportedFooter"
                        values={{
                          url: EXTERNALROUTES.SUPPORT_BROWSER_UPDATE.URI,
                        }}
                        asHtml={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td styleName={classNames("body-cell-right", this.state.size)} />
          </tr>
        </tbody>
      </table>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.windowResize, true);
  }

  private windowResize(event: any) {
    const windowWidth = window.innerWidth;
    const size =
      windowWidth > 1624 ? "large" : windowWidth > 1132 ? "medium" : "small";

    this.setState({ size });
  }
}
