import { ObjectAssignment, PublicationSnapShot } from "@haywork/api/kolibri";
import { AssignmentThunks, Dispatch } from "@haywork/middleware";
import { AssignmentEditMarketingPartnersComponent } from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

export interface AssignmentEditMarketingPartnersContainerProps {
  publications: PublicationSnapShot[];
  objectAssignment: ObjectAssignment;
  mediaPartnerState: string;
  mediaPartnerChangedId: string;

  activatePublication: (assignmentId: string, mediaPartnerId: string) => void;
  deActivatePublication: (assignmentId: string, mediaPartnerId: string) => void;
}

const mapStateToProps = (state: AppState) => ({
  publications: state.editable.currentComponentState.publications,
  objectAssignment: state.editable.currentComponentState.objectAssignment,
  mediaPartnerState: state.assignment.single.mediaPartnerState,
  mediaPartnerChangedId: state.assignment.single.mediaPartnerChangedId
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  activatePublication: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(
      AssignmentThunks.activatePublication(assignmentId, mediaPartnerId)
    ),
  deActivatePublication: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(
      AssignmentThunks.deActivatePublication(assignmentId, mediaPartnerId)
    )
});
export const AssignmentEditMarketingPartnersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingPartnersComponent);
