import { ActiveFilter } from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, RelationListActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  localityValues,
  relationGroupsValues,
  relationTypeValues
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  localityFilterOptions: SingleFilterValue[];
  activeFilterOptions: SingleFilterValue[];
  relationTypeFilterOptions: SingleFilterValue[];
  relationGroupFilterOptions: SingleFilterValue[];
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.relation.list;

  const activeFilterOptions: SingleFilterValue[] = [
    {
      label: ActiveFilter.ActiveOnly.toString(),
      value: ActiveFilter.ActiveOnly
    },
    {
      label: ActiveFilter.InactiveOnly.toString(),
      value: ActiveFilter.InactiveOnly
    }
  ];

  return {
    filters,
    localityFilterOptions: localityValues(state),
    activeFilterOptions,
    relationTypeFilterOptions: relationTypeValues(state),
    relationGroupFilterOptions: relationGroupsValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(RelationListActions.setFilters(filters))
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
