import { RootEntityType } from "@haywork/api/event-center";
import {
  AssignmentSnapShot,
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  MergeTemplate,
  RelationSnapShot,
  RelationType,
  TemplateDefinitionSnapShot,
} from "@haywork/api/kolibri";
import {
  EmailAddress,
  File,
  UserDataElement,
  ShareType,
} from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import ErrorLintComponent from "@haywork/components/ui/error-lint";
import Icon from "@haywork/components/ui/icon";
import PresenceEntity from "@haywork/components/ui/presence/components/entity";
import {
  EMAILROUTES,
  LINKED_ASSIGNMENT_KEY,
  LINKED_RELATION_KEY,
  PATTERN,
  REQUEST,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { EmailCreateContainerProps } from "@haywork/modules/email";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import {
  Alert,
  ConfirmComponent,
  Editor,
  EditorType,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  AssignmentUtil,
  ConvertersUtil,
  EmailUtil,
  FileUploadObject,
  FormControlUtil,
  RouteUtil,
  UploadUtil,
} from "@haywork/util";
import { MappedMergeField, MergeFieldDataType } from "@haywork/util/email";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import debounce from "lodash-es/debounce";
import differenceBy from "lodash-es/differenceBy";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import head from "lodash-es/head";
import uniq from "lodash-es/uniq";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ReactNode } from "react-redux";

const styles = require("./create.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;
const toCCThreshold = 10;

interface ValidationObject {
  isValid: boolean;
  reasons?: ReactNode[];
}

export interface EmailCreateComponentProps {}
interface State {
  body: string;
  updatedBody: string;
  extraReceiversVisible: boolean;
  draftDeleteConfirmVisible: boolean;
  loading: boolean;
  sending: boolean;
  valid: ValidationObject;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  mergeData: MappedMergeField[];
  mergeDataAssignmentId: string;
  mergeDataLinkedAcquisitionId: string;
  mergeDataRelationId: string;
  fetchingLinkedEntities: boolean;
  showUserDataError: boolean;
  showNoSubjectError: boolean;
  selectedLinkedAssignments: LinkedAssignment[];
  selectedLinkedRelations: LinkedRelation[];
  fetchingRelations: boolean;
  fetchingAssignments: boolean;
  accountCanSend: boolean;
}
type Props = EmailCreateComponentProps & EmailCreateContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailCreateComponent extends React.PureComponent<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;
  private maxNoOfRecievers = 50;

  constructor(props) {
    super(props);

    const to = get(this.props.currentDraft, "to", []);
    const cc = get(this.props.currentDraft, "cc", []);
    const bcc = get(this.props.currentDraft, "bcc", []);
    const body = get(this.props.currentDraft, "body", "");
    const valid: ValidationObject = this.getValidState(to, cc, bcc, body);
    const account =
      this.props.currentDraft?.accountId || this.props.currentAccount;

    this.state = {
      body,
      updatedBody: body,
      extraReceiversVisible: cc.length > 0 || bcc.length > 0 ? true : false,
      draftDeleteConfirmVisible: false,
      loading: false,
      sending: false,
      valid,
      newEntityVisible: false,
      newEntityOptions: null,
      mergeData: [],
      mergeDataAssignmentId: null,
      mergeDataLinkedAcquisitionId: null,
      mergeDataRelationId: null,
      fetchingLinkedEntities: false,
      showUserDataError: false,
      showNoSubjectError: false,
      selectedLinkedAssignments: [],
      selectedLinkedRelations: [],
      fetchingAssignments: false,
      fetchingRelations: false,
      accountCanSend: false,
    };

    this.updateBodyCache = debounce(this.updateBodyCache.bind(this), 250);
    this.onEditorFileChange = this.onEditorFileChange.bind(this);
    this.onTemplateSubjectChange = this.onTemplateSubjectChange.bind(this);
    this.onAttachmentChange = this.onAttachmentChange.bind(this);
    this.fetchLinkedEntities = this.fetchLinkedEntities.bind(this);
    this.onRelationAddEmailHandler = this.onRelationAddEmailHandler.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.moveAddressesToBCC = this.moveAddressesToBCC.bind(this);
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
    this.onSetHasChangesAfterTemplateSelect =
      this.onSetHasChangesAfterTemplateSelect.bind(this);
    this.handleLinkedRelationsChange =
      this.handleLinkedRelationsChange.bind(this);
    this.handleLinkedAssignmentsChange =
      this.handleLinkedAssignmentsChange.bind(this);
    this.fetchRelation = this.fetchRelation.bind(this);

    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onSaveAsDraftClickHandler = this.onSaveAsDraftClickHandler.bind(this);
    this.onSendEmailClickHandler = this.onSendEmailClickHandler.bind(this);
    this.toggleExtraReceiversVisibility =
      this.toggleExtraReceiversVisibility.bind(this);
    this.onRelationAddHandler = this.onRelationAddHandler.bind(this);
    this.onEditorChangeHandler = this.onEditorChangeHandler.bind(this);
    this.onConfirmDeleteCloseHandler =
      this.onConfirmDeleteCloseHandler.bind(this);
    this.onConfirmDeleteHandler = this.onConfirmDeleteHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.checkAccountCanSend = this.checkAccountCanSend.bind(this);
    this.handleInfoModalMailClick = this.handleInfoModalMailClick.bind(this);

    this.formControls = {
      from: {
        value: account,
        onChange: (ref) => {
          this.props.setCurrentAccount(ref.value);
          this.checkAccountCanSend(ref.value);
        },
      },
      to: { value: to, onChange: this.fetchRelation },
      cc: { value: cc },
      bcc: { value: bcc },
      subject: { value: value(this.props.currentDraft, "subject", "") },
      linkedRelations: {
        value: [],
        onChange: this.handleLinkedRelationsChange,
      },
      linkedAssignments: {
        value: [],
        onChange: this.handleLinkedAssignmentsChange,
      },
      tracking: { value: false },
    };
  }

  public componentDidMount() {
    this.fetchLinkedEntities();
    this.checkAccountCanSend(
      this.props.currentDraft?.accountId || this.props.currentAccount
    );

    if (this.props.currentDraft && this.props.currentDraft.subject) {
      this.props.setTabTitle(
        this.sanatizeTabTitle(this.props.currentDraft.subject)
      );
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      const { from } = this.formRef.getValues();
      const { temporaryId, id } = this.props.currentDraft;
      this.props.saveAndClose(from, temporaryId || id);
      return;
    }

    if (!deepEqual(nextProps.currentDraft, this.props.currentDraft)) {
      this.formRef.update({
        to: get(nextProps.currentDraft, "to", []),
        cc: get(nextProps.currentDraft, "cc", []),
        bcc: get(nextProps.currentDraft, "bcc", []),
      });
    }

    if (
      nextProps.currentDraftStatus === REQUEST.SUCCESS &&
      this.props.currentDraftStatus === REQUEST.PENDING
    ) {
      this.setState({ updatedBody: nextProps.currentDraft.body || "" });
    }
  }

  public render() {
    const extraReceiversStyle = classNames("extra-receivers", {
      visible: this.state.extraReceiversVisible,
    });
    const files = value(this.props.currentDraft, "files", []);
    const loading =
      this.state.loading ||
      this.state.sending ||
      this.props.currentDraftStatus === REQUEST.PENDING;
    const account =
      this.props.currentDraft?.accountId || this.props.currentAccount;
    const selectedAccount =
      this.props.accounts.find((a) => a.id === account) || null;
    const { to, cc, selectedTemplate, changesAfterTemplateSelect } =
      this.props.currentDraft;
    const selectedToAdresses = to || [];
    const selectedCCAdresses = cc || [];
    const draftIsBeingSent = !!this.props.currentDraft?.sendRequested;

    return (
      <>
        <PresenceEntity
          entityType={RootEntityType.Unknown}
          entityId={this.props.relatedMessageId}
        />

        <div styleName="create">
          <div styleName="create__header">
            <div styleName="left">
              <div styleName="action-group">
                <button
                  type="button"
                  disabled={loading}
                  onClick={this.onDeleteClickHandler}
                >
                  <i className="fal fa-trash-alt" />
                </button>
              </div>
            </div>
            <div styleName="right">
              <button
                type="button"
                className="btn btn-blank icon-left"
                data-cy="CY-draftSaveAsConcept"
                disabled={loading || draftIsBeingSent}
                onClick={this.onSaveAsDraftClickHandler}
              >
                <i className="fal fa-save" />
                <I18n value="emailSaveAsConcept" />
              </button>
              <button
                type="button"
                className="btn btn-primary icon-left"
                data-cy="CY-draftSendEmail"
                disabled={
                  !this.state.accountCanSend ||
                  loading ||
                  !this.state.valid.isValid ||
                  draftIsBeingSent
                }
                onClick={() => this.onSendEmailClickHandler()}
              >
                <i className="fal fa-paper-plane" />
                <I18n value="emailSend" />
              </button>
            </div>
          </div>

          <ErrorLintComponent
            visible={!this.state.valid.isValid}
            messages={this.state.valid.reasons}
          />

          {!!draftIsBeingSent && (
            <div styleName="send__requested">
              <I18n value="emailDraft.sendRequested.info" asHtml />
            </div>
          )}

          <div styleName="create__body">
            {loading && <Ui.Loaders.Fullscreen mask />}

            <div styleName="create__fields">
              <Form
                name="create-email"
                formControls={this.formControls}
                form={(form) => (this.formRef = form)}
                onChange={this.onFormChangeHandler}
              >
                {this.props.accounts.length > 1 && (
                  <div styleName="row">
                    <div styleName="column column__label">
                      <label htmlFor="from">
                        <I18n value="emailFrom" />
                      </label>
                    </div>
                    <div styleName="column column__input">
                      <Input.NewSelect
                        name="from"
                        values={this.props.accounts}
                        displayProp="emailAddress"
                        valuesProp="id"
                      />
                    </div>
                  </div>
                )}

                <div styleName="row">
                  <div styleName="column column__label">
                    <label htmlFor="to">
                      <I18n value="emailTo" />
                    </label>
                  </div>
                  <div styleName="column column__input">
                    <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS" disable>
                      <Input.EmailQuery
                        name="to"
                        data-cy="CY-draftToInput"
                        placeholder="emailAddressPlaceholder"
                        onAddRelation={this.onRelationAddEmailHandler}
                      />
                    </FeatureSwitch>

                    <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS">
                      <Input.EmailWithRelationGroups
                        name="to"
                        onAddRelation={this.onRelationAddEmailHandler}
                        filterByRelationTypes={[
                          RelationType.ContactCompany,
                          RelationType.ContactPerson,
                        ]}
                        onNavigate={this.props.navigate}
                        onAdd={this.onRelationAddEmailHandler}
                      />
                    </FeatureSwitch>
                  </div>
                  <div styleName="column column__action">
                    <button
                      type="button"
                      className={classNames(
                        "btn",
                        !this.state.extraReceiversVisible
                          ? "btn-blank"
                          : "btn-primary"
                      )}
                      onClick={this.toggleExtraReceiversVisibility}
                    >
                      <I18n value="emailExtraReceivers" />
                    </button>
                  </div>
                </div>

                <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS">
                  {selectedToAdresses.length >= toCCThreshold && (
                    <div styleName="row">
                      <div styleName="addresses-count-warning">
                        <Icon
                          name="chevron-right"
                          color={Colors.Warning}
                          size={12}
                          regular
                        />
                        <span
                          styleName="link"
                          onClick={() =>
                            this.moveAddressesToBCC("to", selectedToAdresses)
                          }
                        >
                          <I18n value="email.warning.adressesCount.body" />
                        </span>
                      </div>
                    </div>
                  )}
                </FeatureSwitch>

                <div styleName={extraReceiversStyle}>
                  <div styleName="extra-receivers__fields">
                    <div styleName="row">
                      <div styleName="column column__label">
                        <label htmlFor="cc">
                          <I18n value="emailCC" />
                        </label>
                      </div>
                      <div styleName="column column__input">
                        <FeatureSwitch
                          feature="EMAIL_TO_RELATION_GROUPS"
                          disable
                        >
                          <Input.EmailQuery
                            name="cc"
                            placeholder="emailAddressPlaceholder"
                            onAddRelation={this.onRelationAddEmailHandler}
                            filterByRelationTypes={[
                              RelationType.ContactCompany,
                              RelationType.ContactPerson,
                            ]}
                          />
                        </FeatureSwitch>

                        <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS">
                          <Input.EmailWithRelationGroups
                            name="cc"
                            onAddRelation={this.onRelationAddEmailHandler}
                            filterByRelationTypes={[
                              RelationType.ContactCompany,
                              RelationType.ContactPerson,
                            ]}
                            onNavigate={this.props.navigate}
                            onAdd={this.onRelationAddEmailHandler}
                          />
                        </FeatureSwitch>
                      </div>
                      <div styleName="column column__label">
                        <label htmlFor="bcc">
                          <I18n value="emailBCC" />
                        </label>
                      </div>
                      <div styleName="column column__input">
                        <FeatureSwitch
                          feature="EMAIL_TO_RELATION_GROUPS"
                          disable
                        >
                          <Input.EmailQuery
                            name="bcc"
                            placeholder="emailAddressPlaceholder"
                            onAddRelation={this.onRelationAddEmailHandler}
                            filterByRelationTypes={[
                              RelationType.ContactCompany,
                              RelationType.ContactPerson,
                            ]}
                            onAdd={this.onRelationAddEmailHandler}
                            onNavigate={this.props.navigate}
                          />
                        </FeatureSwitch>

                        <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS">
                          <Input.EmailWithRelationGroups
                            name="bcc"
                            onAddRelation={this.onRelationAddEmailHandler}
                            filterByRelationTypes={[
                              RelationType.ContactCompany,
                              RelationType.ContactPerson,
                            ]}
                            onNavigate={this.props.navigate}
                            onAdd={this.onRelationAddEmailHandler}
                          />
                        </FeatureSwitch>
                      </div>
                    </div>
                  </div>
                </div>

                <FeatureSwitch feature="EMAIL_TO_RELATION_GROUPS">
                  {selectedCCAdresses.length >= toCCThreshold && (
                    <div styleName="row">
                      <div styleName="addresses-count-warning">
                        <Icon
                          name="chevron-right"
                          color={Colors.Warning}
                          size={12}
                          regular
                        />
                        <span
                          styleName="link"
                          onClick={() =>
                            this.moveAddressesToBCC("cc", selectedCCAdresses)
                          }
                        >
                          <I18n value="email.warning.adressesCount.body" />
                        </span>
                      </div>
                    </div>
                  )}
                </FeatureSwitch>

                <div styleName="row">
                  <div styleName="column column__label">
                    <label htmlFor="linkedRelations">
                      <I18n value="saveInTimelineNoColon" />
                    </label>
                  </div>
                  <div styleName="column column__input">
                    <Input.Suggestion
                      name="linkedRelations"
                      type="relation"
                      relationTypes={[
                        RelationType.ContactCompany,
                        RelationType.ContactPerson,
                      ]}
                      placeholder="searchOrChooseRelation"
                      onAdd={this.onRelationAddHandler}
                      onNavigate={this.props.navigate}
                      fetchingResults={this.state.fetchingRelations}
                      infoModalCallback={this.handleInfoModalMailClick}
                    />
                  </div>
                  <div styleName="column column__label joined">
                    <label htmlFor="linkedAssignments">
                      <I18n value="andOr" />
                    </label>
                  </div>
                  <div styleName="column column__input">
                    <Input.Suggestion
                      name="linkedAssignments"
                      type="assignment"
                      placeholder="searchOrChooseAssignment"
                      onNavigate={this.props.navigate}
                      fetchingResults={this.state.fetchingAssignments}
                      infoModalCallback={this.handleInfoModalMailClick}
                    />
                  </div>
                </div>

                <div styleName="row">
                  <div styleName="column column__label">
                    <label htmlFor="subject">
                      <I18n value="emailSubject" />
                    </label>
                  </div>
                  <div styleName="column column__input">
                    <Input.TextWithMergeField
                      name="subject"
                      data-cy="CY-draftSubject"
                      placeholder="emailSubjectPlaceholder"
                      mergeFields={this.props.mergeFields}
                    />
                  </div>
                  <div styleName="column column__action">
                    <Input.Switch
                      name="tracking"
                      on={true}
                      off={false}
                      label="email.tracking.label"
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div styleName="create__editor">
              <Editor
                type={EditorType.Email}
                onChange={this.onEditorChangeHandler}
                initialValue={this.state.updatedBody}
                onFileChange={this.onEditorFileChange}
                uploadUrl={this.props.emailUploadUrl}
                onTemplateSubjectChange={this.onTemplateSubjectChange}
                files={files}
                mergefieldData={this.state.mergeData}
                onAttachmentChange={this.onAttachmentChange}
                selectedAccount={selectedAccount}
                linkedRelations={get(
                  this.props.currentDraft,
                  "linkedRelations"
                )}
                linkedAssignments={get(
                  this.props.currentDraft,
                  "linkedAssignments"
                )}
                onSelectTemplate={this.onSelectTemplate}
                selectedTemplate={selectedTemplate}
                onSetHasChangesAfterTemplateSelect={
                  this.onSetHasChangesAfterTemplateSelect
                }
                changesAfterTemplateSelect={changesAfterTemplateSelect}
              />
            </div>
          </div>

          <ConfirmComponent
            visible={this.state.draftDeleteConfirmVisible}
            titleResourceKey="draftDeleteConfirmTitle"
            bodyResourceKey="draftDeleteConfirmBody"
            bodyValues={this.props.currentDraft || {}}
            onClose={this.onConfirmDeleteCloseHandler}
            onConfirm={this.onConfirmDeleteHandler}
          />

          <NewEntity
            visible={this.state.newEntityVisible}
            options={this.state.newEntityOptions}
            onClose={this.onNewEntityCloseHandler}
            onNewRelation={this.onNewRelationHandler}
          />

          <Alert
            visible={this.state.showUserDataError}
            titleResourceKey="email.saveDraft.userDataErrorTitle"
            bodyResourceKey="email.saveDraft.userDataErrorBody"
            onClose={() => this.setState({ showUserDataError: false })}
          />

          <ConfirmComponent
            visible={this.state.showNoSubjectError}
            titleResourceKey="email.saveDraft.noSubjectErrorTitle"
            bodyResourceKey="email.saveDraft.noSubjectErrorBody"
            onClose={() =>
              this.setState({ showNoSubjectError: false, sending: false })
            }
            onConfirm={() => {
              this.setState({ showNoSubjectError: false, sending: false }, () =>
                this.onSendEmailClickHandler(false)
              );
            }}
          />
        </div>
      </>
    );
  }

  private handleInfoModalMailClick(relation: RelationSnapShot) {
    const { to } = this.formRef.getValues();
    const relationToAdd = {
      email: relation.email || "",
      displayName: relation.displayName || "",
      id: relation.id,
    };

    const uniqTo = uniqBy([...to, relationToAdd], (relation) => relation.email);

    this.formRef.update({
      to: uniqTo,
    });
  }

  private async fetchRelation(
    ref: RawFormControl,
    get: (name: string) => RawFormControl
  ) {
    const to = ref.value || [];

    let linkedRelations: LinkedRelation[] = get("linkedRelations").value;
    let linkedAssignments: LinkedAssignment[] = get("linkedAssignments").value;

    linkedRelations = linkedRelations || [];
    linkedAssignments = linkedAssignments || [];
    if (!to.length) return;

    try {
      const { ids, emails } = to.reduce(
        (state, item) => {
          if (item.id) {
            state.ids.push(item.id);
          } else if (item.email) {
            state.emails.push(item.email);
          }

          return state;
        },
        { emails: [], ids: [] } as { emails: string[]; ids: string[] }
      );

      if (!emails.length && !ids.length) return;

      this.setState({ fetchingLinkedEntities: true });

      const linkedRelationsOnId = !ids.length
        ? []
        : await this.props.getRelationsWithMatchingIds(ids, [
            RelationType.ContactPerson,
            RelationType.ContactCompany,
          ]);

      const linkedRelationsOnEmail = !emails.length
        ? []
        : await this.props.getRelationsWithMatchingEmailAddress(emails, [
            RelationType.ContactPerson,
            RelationType.ContactCompany,
          ]);

      const relations = [...linkedRelationsOnId, ...linkedRelationsOnEmail];

      const mergedLinkedRelations = uniqBy(
        [...linkedRelations, ...relations],
        (relation) => relation.id
      );

      this.formRef.update(
        {
          linkedRelations: mergedLinkedRelations,
        },
        true
      );
      this.setState({ selectedLinkedRelations: mergedLinkedRelations });

      if (!!linkedRelations.length) {
        const linkedAssignmentsFromRelations =
          await this.props.getAssignmentsLinkedToRelations(
            linkedRelations.map((relation) => relation.id)
          );

        const mergedLinkedAssignments = uniqBy(
          [...linkedAssignmentsFromRelations, ...linkedAssignments],
          (assignment) => assignment.id
        );

        this.formRef.update(
          {
            mergedLinkedAssignments,
          },
          true
        );
        this.setState({ selectedLinkedAssignments: mergedLinkedAssignments });
      }
    } finally {
      this.setState({ fetchingLinkedEntities: false });
    }
  }

  private sanatizeTabTitle(title: string) {
    let sanatized = title.replace(/[{}]/g, "");
    sanatized = sanatized.replace(/&nbsp;/g, " ");
    return sanatized;
  }

  private async fetchLinkedEntities() {
    try {
      this.setState({ fetchingLinkedEntities: true });
      const { to, userData } = this.props.currentDraft;

      let linkedRelations = (userData || [])
        .filter((data) => data.key === LINKED_RELATION_KEY && !!data.value)
        .map((data) => JSON.parse(data.value));
      let linkedAssignments = (userData || [])
        .filter((data) => data.key === LINKED_ASSIGNMENT_KEY && !!data.value)
        .map((data) => JSON.parse(data.value));

      if (!!linkedRelations.length || !!linkedAssignments.length) {
        this.formRef.update(
          {
            linkedRelations,
            linkedAssignments,
          },
          true
        );
        this.setState({
          selectedLinkedAssignments: linkedAssignments,
          selectedLinkedRelations: linkedRelations,
        });
        return;
      }

      if (!to.length) {
        return;
      }

      const { ids, emails } = to.reduce(
        (state, item) => {
          if (item.id) {
            state.ids.push(item.id);
          } else if (item.email) {
            state.emails.push(item.email);
          }

          return state;
        },
        { emails: [], ids: [] } as { emails: string[]; ids: string[] }
      );

      const linkedRelationsOnId = !ids.length
        ? []
        : await this.props.getRelationsWithMatchingIds(ids, [
            RelationType.ContactPerson,
            RelationType.ContactCompany,
          ]);

      const linkedRelationsOnEmail = !emails.length
        ? []
        : await this.props.getRelationsWithMatchingEmailAddress(emails, [
            RelationType.ContactPerson,
            RelationType.ContactCompany,
          ]);

      linkedRelations = [...linkedRelationsOnId, ...linkedRelationsOnEmail];

      this.formRef.update(
        {
          linkedRelations,
        },
        true
      );
      this.setState({ selectedLinkedRelations: linkedRelations });

      if (!!linkedRelations.length) {
        linkedAssignments = await this.props.getAssignmentsLinkedToRelations(
          linkedRelations.map((relation) => relation.id)
        );
        this.formRef.update(
          {
            linkedAssignments,
          },
          true
        );
        this.setState({ selectedLinkedAssignments: linkedAssignments });
      }
    } finally {
      this.setState({ fetchingLinkedEntities: false });
    }
  }

  private async onFormChangeHandler(values: FormReturnValue) {
    if (!this.props.currentDraft) return;

    const valid = this.getValidState(
      values.to,
      values.cc,
      values.bcc,
      this.state.body
    );
    const { temporaryId, id } = this.props.currentDraft;
    const path = route(EMAILROUTES.CREATE.URI, { id: temporaryId || id });

    const userDataArray = this.props.currentDraft.userData || [];
    const relatedMessage = userDataArray.find(
      (data) => data.key === "relatedMessage"
    );
    const userData: UserDataElement[] = !!relatedMessage
      ? [relatedMessage]
      : [];
    const linkedRelations =
      (values.linkedRelations as RelationSnapShot[]) || [];
    const linkedAssignments =
      (values.linkedAssignments as AssignmentSnapShot[]) || [];

    linkedRelations.map((linkedRelation: RelationSnapShot) => {
      const relationSnapShot =
        ConvertersUtil.getMinimalRelationSnapShot(linkedRelation);
      userData.push({
        key: LINKED_RELATION_KEY,
        value: JSON.stringify(relationSnapShot),
      });
    });

    linkedAssignments.map((linkedAssignment: AssignmentSnapShot) => {
      const assignmentSnapShot =
        ConvertersUtil.getMinimalAssignmentSnapShot(linkedAssignment);
      userData.push({
        key: LINKED_ASSIGNMENT_KEY,
        value: JSON.stringify(assignmentSnapShot),
      });
    });

    values = { ...values, userData };

    this.setState({ valid });
    this.props.updateCache(
      { ...this.props.currentDraft, ...values, accountId: values.from },
      path
    );

    if (!!values.subject) {
      this.props.setTabTitle(this.sanatizeTabTitle(values.subject));
    }

    this.updateEmailMergeData(
      head(
        linkedAssignments.filter(
          (assignment) =>
            assignment.typeOfAssignment === AssignmentType.Object ||
            assignment.typeOfAssignment === AssignmentType.AcquisitionObject
        )
      ),
      head(linkedRelations)
    );
  }

  private async updateEmailMergeData(
    linkedAssignment?: LinkedAssignment,
    relationSnapShot?: RelationSnapShot
  ) {
    try {
      let {
        mergeData,
        mergeDataAssignmentId,
        mergeDataLinkedAcquisitionId,
        mergeDataRelationId,
      } = this.state;
      let assignmentUpdated = false;
      let relationUpdated = false;

      if (
        !linkedAssignment &&
        (!!mergeDataAssignmentId || !!mergeDataLinkedAcquisitionId)
      ) {
        assignmentUpdated = true;
        mergeDataAssignmentId = null;
        mergeDataLinkedAcquisitionId = null;

        const assignmentDataTypes = [
          MergeFieldDataType.Purchaser,
          MergeFieldDataType.Tenant,
          MergeFieldDataType.Notary,
          MergeFieldDataType.Vendor,
          MergeFieldDataType.Lessor,
          MergeFieldDataType.ObjectAssignment,
          MergeFieldDataType.HouseHunter,
          MergeFieldDataType.AcquisitionObjectAssignment,
        ];
        mergeData = mergeData.filter(
          (data) => assignmentDataTypes.indexOf(data.type) === -1
        );
      }

      if (!relationSnapShot && !!mergeDataRelationId) {
        relationUpdated = true;
        mergeDataRelationId = null;

        mergeData = mergeData.filter(
          (data) => data.type !== MergeFieldDataType.Relation
        );
      }

      if (
        !!linkedAssignment &&
        linkedAssignment.id !== mergeDataAssignmentId &&
        linkedAssignment.typeOfAssignment === AssignmentType.Object
      ) {
        assignmentUpdated = true;
        mergeDataAssignmentId = linkedAssignment.id;
        mergeDataLinkedAcquisitionId = null;

        const assignment = await this.props.getLinkedAssignment(
          linkedAssignment.id
        );

        const assignmentDataTypes = [
          MergeFieldDataType.Purchaser,
          MergeFieldDataType.Tenant,
          MergeFieldDataType.Notary,
          MergeFieldDataType.Vendor,
          MergeFieldDataType.Lessor,
          MergeFieldDataType.ObjectAssignment,
          MergeFieldDataType.HouseHunter,
        ];
        mergeData = mergeData.filter(
          (data) => assignmentDataTypes.indexOf(data.type) === -1
        );

        mergeData = [
          ...mergeData,
          ...EmailUtil.mapAssignmentDataToMergeData(assignment),
        ];
      }

      if (
        !!linkedAssignment &&
        linkedAssignment.id !== mergeDataLinkedAcquisitionId &&
        linkedAssignment.typeOfAssignment === AssignmentType.AcquisitionObject
      ) {
        assignmentUpdated = true;
        mergeDataLinkedAcquisitionId = linkedAssignment.id;
        mergeDataAssignmentId = null;

        const acquisitionObjectAssignment =
          await this.props.getLinkedAcquisitionObjectAssignment(
            linkedAssignment.id
          );

        const assignmentDataTypes = [
          MergeFieldDataType.AcquisitionObjectAssignment,
        ];
        mergeData = mergeData.filter(
          (data) => assignmentDataTypes.indexOf(data.type) === -1
        );

        mergeData = [
          ...mergeData,
          ...EmailUtil.mapAcquisitionAssignmentDataToMergeData(
            acquisitionObjectAssignment
          ),
        ];
      }

      if (!!relationSnapShot && relationSnapShot.id !== mergeDataRelationId) {
        relationUpdated = true;
        mergeDataRelationId = relationSnapShot.id;

        mergeData = mergeData.filter(
          (data) => data.type !== MergeFieldDataType.Relation
        );

        mergeData = [
          ...mergeData,
          {
            id: relationSnapShot.id,
            relationType: relationSnapShot.typeOfRelation,
            type: MergeFieldDataType.Relation,
          },
        ];
      }

      if (!assignmentUpdated && !relationUpdated) return;

      this.setState({
        mergeData,
        mergeDataAssignmentId,
        mergeDataLinkedAcquisitionId,
        mergeDataRelationId,
      });
    } catch (error) {
      throw error;
    }
  }

  private getValidState(
    to: EmailAddress[],
    cc: EmailAddress[],
    bcc: EmailAddress[],
    body: string
  ): ValidationObject {
    const validation: ValidationObject = {
      isValid: true,
      reasons: [],
    };

    if (to.length === 0 && cc.length === 0 && bcc.length === 0) {
      validation.isValid = false;
    }

    if (to.length + cc.length + bcc.length > this.maxNoOfRecievers) {
      validation.isValid = false;
      validation.reasons = [
        ...validation.reasons,
        <I18n
          value="email.error.tooManyRecievers"
          values={{
            aantal: this.maxNoOfRecievers,
          }}
        />,
      ];
    }

    if (validation.isValid) {
      to.map((address) => {
        if (
          !address?.email ||
          address?.email === "" ||
          new RegExp(PATTERN.EMAIL).test(address?.email) === false
        ) {
          validation.isValid = false;
          validation.reasons = [
            ...validation.reasons,
            <I18n value="email.error.noValidTo" />,
          ];
        }
      });
      cc.map((address) => {
        if (
          !address?.email ||
          address?.email === "" ||
          new RegExp(PATTERN.EMAIL).test(address?.email) === false
        ) {
          validation.isValid = false;
        }
      });
      bcc.map((address) => {
        if (
          !address?.email ||
          address?.email === "" ||
          new RegExp(PATTERN.EMAIL).test(address?.email) === false
        ) {
          validation.isValid = false;
        }
      });

      if (!(body || "").trim()) {
        validation.isValid = false;
      }
    }

    return validation;
  }

  private async onSendEmailClickHandler(showSubjectWarning = true) {
    if (this.state.loading || this.state.sending) return;

    this.setState({
      sending: true,
    });

    const values = this.formRef.getValues();
    let body = this.state.body;

    if (!body && this.props.currentDraft.body) {
      body = this.props.currentDraft.body;
    }

    let draft = this.props.currentDraft;
    draft = {
      ...draft,
      ...values,
      body,
    };

    if (draft?.subject === "" && showSubjectWarning) {
      this.setState({ showNoSubjectError: true });
      return;
    }

    try {
      let { linkedRelations, linkedAssignments } = values;
      linkedRelations = linkedRelations || [];
      linkedAssignments = linkedAssignments || [];
      let communicationLogId: string;

      if (linkedRelations.length > 0 || linkedAssignments.length > 0) {
        const communicationLog = await this.props.saveCommunicationLog(
          values.linkedRelations,
          values.linkedAssignments,
          draft
        );

        communicationLogId = communicationLog.id;
      }

      const { userData, ...rest } = draft;
      draft = {
        ...rest,
        userData: (userData || []).filter(
          (data) => data.key === "relatedMessage"
        ),
      };

      await this.props.sendDraft(
        draft.accountId,
        draft,
        communicationLogId,
        values.tracking
      );
    } finally {
      this.setState({
        loading: false,
        sending: false,
      });
    }
  }

  private onEditorChangeHandler(body: string) {
    const valid = this.getValidState(
      value(this.props.currentDraft, "to", []),
      value(this.props.currentDraft, "cc", []),
      value(this.props.currentDraft, "bcc", []),
      body
    );

    this.setState({ body, valid });
    this.updateBodyCache(body);
  }

  private onTemplateSubjectChange(subject: MergeTemplate) {
    if (!!subject && !!subject.value) {
      this.formRef.update({
        subject: subject.value,
      });
    }
  }

  private onAttachmentChange(files: FileUploadObject[]) {
    if (!this.props.currentDraft) return;
    const { temporaryId, id } = this.props.currentDraft;
    const attachments = files
      .filter((file) => !!file && !!file.response)
      .map(UploadUtil.mapFileUploadObjectToFile);

    this.props.setDraftAttachments(attachments, temporaryId || id);
  }

  private onEditorFileChange(files: File[]) {
    if (!this.props.currentDraft) return;
    const { temporaryId, id } = this.props.currentDraft;
    this.props.setDraftFiles(files, temporaryId || id);
  }

  private updateBodyCache(body: string) {
    if (!this.props.currentDraft) return;
    const { temporaryId, id } = this.props.currentDraft;
    const path = route(EMAILROUTES.CREATE.URI, { id: temporaryId || id });
    this.props.updateCache({ ...this.props.currentDraft, body }, path);
  }

  private async onSaveAsDraftClickHandler() {
    if (!!this.state.loading) return;

    const draft = this.props.currentDraft;
    const userDataLength = (draft.userData || []).reduce((state, data) => {
      return state + (data.value || "").length;
    }, 0);

    if (userDataLength > 10000) {
      this.setState({ showUserDataError: true });
      return;
    }

    this.setState({
      loading: true,
    });

    const { subject, from, to, cc, bcc, linkedAssignments, linkedRelations } =
      this.formRef.getValues();
    const { body, updatedBody } = this.state;
    const account = this.props.accounts.find((account) => account.id === from);

    const files = draft.files.filter((file) => file.isAttachment);
    const relatedMessage = has(this.props.currentDraft, "userData")
      ? this.props.currentDraft.userData.find(
          (data) => data.key === "relatedMessage"
        )
      : null;
    const userData: UserDataElement[] = !!relatedMessage
      ? [relatedMessage]
      : [];

    linkedRelations.map((linkedRelation: RelationSnapShot) => {
      const relationSnapShot =
        ConvertersUtil.getMinimalRelationSnapShot(linkedRelation);
      userData.push({
        key: LINKED_RELATION_KEY,
        value: JSON.stringify(relationSnapShot),
      });
    });

    linkedAssignments.map((linkedAssignment: AssignmentSnapShot) => {
      const assignmentSnapShot =
        ConvertersUtil.getMinimalAssignmentSnapShot(linkedAssignment);
      userData.push({
        key: LINKED_ASSIGNMENT_KEY,
        value: JSON.stringify(assignmentSnapShot),
      });
    });

    try {
      const savedDraft = await this.props.saveDraft(
        account.id,
        {
          ...draft,
          userData,
          subject,
          to,
          cc,
          bcc,
          body: body ? body : updatedBody,
          files,
          accountId: account.id,
        },
        true
      );

      const path = route(EMAILROUTES.CREATE.URI, { id: savedDraft.id });

      this.props.updateCache(
        { ...this.props.currentDraft, ...savedDraft, isNew: false },
        path,
        true
      );

      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  private toggleExtraReceiversVisibility() {
    this.setState({
      extraReceiversVisible: !this.state.extraReceiversVisible,
    });
  }

  private onDeleteClickHandler() {
    this.setState({
      draftDeleteConfirmVisible: true,
    });
  }

  private onConfirmDeleteCloseHandler() {
    this.setState({
      draftDeleteConfirmVisible: false,
    });
  }

  private async onConfirmDeleteHandler() {
    this.setState({
      draftDeleteConfirmVisible: false,
      loading: true,
    });

    try {
      await this.props.deleteDraft(
        this.props.currentAccount,
        this.props.currentDraft
      );

      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  private onRelationAddHandler(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onRelationAddEmailHandler(email: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          email,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedRelations } = this.formRef.getValues();
    linkedRelations = linkedRelations || [];

    this.setState({ fetchingLinkedEntities: true });
    const form = this.formRef.getValues();

    this.formRef.update({
      linkedRelations: [...linkedRelations, relation],
      to: [
        ...(form.to as EmailAddress[]),
        { email: relation.email, id: relation.id },
      ],
    });

    this.setState({
      newEntityVisible: false,
      selectedLinkedRelations: [...linkedRelations, relation],
      fetchingLinkedEntities: false,
    });
  }

  private moveAddressesToBCC(from: "to" | "cc", addresses: EmailAddress[]) {
    let { bcc } = this.props.currentDraft;
    bcc = [...(bcc || []), ...addresses];

    if (!this.state.extraReceiversVisible) {
      this.setState({ extraReceiversVisible: true });
    }

    if (!!this.formRef) {
      switch (from) {
        case "to": {
          this.formRef.update({
            to: [],
            bcc,
          });
          return;
        }
        case "cc": {
          this.formRef.update({
            cc: [],
            bcc,
          });
          return;
        }
        default:
          return;
      }
    }
  }

  private onSelectTemplate(selectedTemplate: TemplateDefinitionSnapShot) {
    const { temporaryId, id } = this.props.currentDraft;
    const path = route(EMAILROUTES.CREATE.URI, { id: temporaryId || id });

    this.props.updateCache(
      { ...this.props.currentDraft, selectedTemplate },
      path
    );
  }

  private onSetHasChangesAfterTemplateSelect(
    changesAfterTemplateSelect: boolean
  ) {
    const { temporaryId, id } = this.props.currentDraft;
    const path = route(EMAILROUTES.CREATE.URI, { id: temporaryId || id });

    this.props.updateCache(
      { ...this.props.currentDraft, changesAfterTemplateSelect },
      path
    );
  }

  private async handleLinkedRelationsChange(control: RawFormControl) {
    if (!!this.state.fetchingLinkedEntities) return;

    try {
      this.setState({ fetchingAssignments: true });

      const { selectedLinkedRelations, selectedLinkedAssignments } = this.state;
      const newLinkedRelations = differenceBy(
        control.value as (LinkedRelation | RelationSnapShot)[],
        selectedLinkedRelations,
        (relation) => relation.id
      ).filter((relation) =>
        [RelationType.ContactCompany, RelationType.ContactPerson].includes(
          relation.typeOfRelation
        )
      );
      let linkedAssignments = selectedLinkedAssignments;

      if (!!newLinkedRelations.length) {
        const relationsIds = newLinkedRelations.map((relation) => relation.id);
        const assignments = await this.props.getAssignmentsLinkedToRelations(
          relationsIds
        );

        linkedAssignments = assignments.map(
          AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment
        );
        linkedAssignments = uniqBy(
          [...selectedLinkedAssignments, ...linkedAssignments],
          (assignment) => assignment.id
        );
      }

      this.setState({
        selectedLinkedRelations: control.value || [],
        selectedLinkedAssignments: linkedAssignments,
      });

      return {
        linkedAssignments,
      };
    } finally {
      this.setState({ fetchingAssignments: false });
    }
  }

  private async handleLinkedAssignmentsChange(control: RawFormControl) {
    if (!!this.state.fetchingLinkedEntities) return;

    try {
      this.setState({ fetchingRelations: true });

      const { selectedLinkedAssignments, selectedLinkedRelations } = this.state;

      const newLinkedAssignments = differenceBy(
        control.value as LinkedAssignment[],
        selectedLinkedAssignments,
        (assignment) => assignment.id
      ).filter((assignment) =>
        [
          AssignmentType.Object,
          AssignmentType.Acquisition,
          AssignmentType.AcquisitionObject,
        ].includes(assignment.typeOfAssignment)
      );
      let linkedRelations = selectedLinkedRelations;

      if (!!newLinkedAssignments.length) {
        const objectPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Object
          )
          .map((assignment) => this.props.getLinkedAssignment(assignment.id));

        const acquisitionPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Acquisition
          )
          .map((assignment) => this.props.getLinkedAcquisition(assignment.id));

        const acquisitionObjectPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.AcquisitionObject
          )
          .map((assignment) =>
            this.props.getLinkedAcquisitionObjectAssignment(assignment.id)
          );

        const objects = await Promise.all(objectPromises);
        const acquisitions = await Promise.all(acquisitionPromises);

        const acquisitionObjects = await Promise.all(acquisitionObjectPromises);

        const linkedAcquisitionsPromises = acquisitionObjects.map(
          (assignment) =>
            this.props.getLinkedAcquisition(
              assignment?.linkedAcquisitionAssignment?.id
            )
        );
        const linkedAcquisitions = await Promise.all(
          linkedAcquisitionsPromises
        );

        const objectLinkedRelations = objects.reduce((state, assignment) => {
          [
            ...(assignment.linkedApplicants || []),
            ...(assignment.linkedVendors || []),
            ...(assignment.linkedClients || []),
          ].map((relation) => state.push(relation));

          return state;
        }, [] as LinkedRelation[]);

        const acquisitionLinkedRelations = acquisitions.reduce(
          (state, assignment) => {
            [
              ...(assignment.linkedApplicants || []),
              ...(assignment.linkedVendors || []),
              ...(assignment.linkedClients || []),
            ].map((relation) => state.push(relation));

            return state;
          },
          [] as LinkedRelation[]
        );

        const linkedAcquisitionsRelations = linkedAcquisitions.reduce(
          (state, assignment) => {
            [
              ...(assignment.linkedApplicants || []),
              ...(assignment.linkedVendors || []),
              ...(assignment.linkedClients || []),
            ].map((relation) => state.push(relation));

            return state;
          },
          [] as LinkedRelation[]
        );

        linkedRelations = uniqBy(
          [
            ...selectedLinkedRelations,
            ...objectLinkedRelations,
            ...acquisitionLinkedRelations,
            ...linkedAcquisitionsRelations,
          ],
          (relation) => relation.id
        );
      }

      this.setState({
        selectedLinkedAssignments: control.value || [],
        selectedLinkedRelations: linkedRelations,
      });

      return {
        linkedRelations,
      };
    } finally {
      this.setState({ fetchingRelations: false });
    }
  }

  private checkAccountCanSend(accountId: string) {
    const restrictions = this.props.shares.find(
      (share) => share.accountId === accountId
    );

    this.setState({
      accountCanSend: ![ShareType.Read, ShareType.ReadWrite].includes(
        restrictions?.shareType
      ),
    });
  }
}
