import { LinkedEmployee } from "@haywork/api/kolibri";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

interface OfficeEmployeeListItemComponentProps {
  employee?: LinkedEmployee;
  zebra: boolean;
  onEmployeeClickHandler: (employee: LinkedEmployee) => void;
  onOfficeEmployeeArchive: (employeeId: string) => void;
  removeEmployeeFromList: (employeeId: string) => void;
  onOfficeEmployeeUnarchive: (employeeId: string) => void;
}
interface OfficeEmployeeListItemComponentState {
  showActions: boolean;
  preppedForRemove: boolean;
  deleteCountdown: number;
}

const styles = require("./office-employee-list-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class OfficeEmployeeListItemComponent extends React.Component<
  OfficeEmployeeListItemComponentProps,
  OfficeEmployeeListItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.toggleShowActions = this.toggleShowActions.bind(this);
    this.onUnRemoveClickHandler = this.onUnRemoveClickHandler.bind(this);
    this.onArchiveClickHandler = this.onArchiveClickHandler.bind(this);

    this.state = {
      showActions: false,
      preppedForRemove: false,
      deleteCountdown: 5,
    };
  }

  public render() {
    const combined = classNames("item ", {
      zebra: this.props.zebra,
      "show-actions": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
    });
    const archiveAction = classNames("action", { actions__archive: true });
    const editAction = classNames("action", { actions__edit: true });

    return (
      <div
        id={this.props.employee.id}
        styleName={combined}
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="item__data">
          <div styleName="relationAvatar">
            {this.props.employee.avatarUrl ? (
              <span
                styleName="image"
                style={{
                  backgroundImage: `url(${JSON.stringify(
                    this.props.employee.avatarUrl
                  )})`,
                }}
              />
            ) : (
              <span styleName="noImage">
                {this.props.employee.letterAvatar}
              </span>
            )}
          </div>
          <div styleName="item__meta__container">
            <div styleName="item__meta" onClick={this.onClickHandler}>
              <div styleName="meta">
                <span styleName="displayName">
                  {this.props.employee.displayName}{" "}
                </span>
                <span styleName="typeOfRelation">
                  <ResourceText resourceKey="ContactEmployee" />{" "}
                </span>
              </div>
              <div styleName="meta contact">
                {this.props.employee.email ? (
                  <span styleName="email">
                    <MailTo email={this.props.employee.email} />
                  </span>
                ) : (
                  ""
                )}
                {this.props.employee.phoneMobile ? (
                  <span styleName="phoneNumber">
                    {this.props.employee.phoneMobile}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div styleName="toggle" onClick={this.toggleShowActions}>
            <i id="toggleOfficeEmployee" className="fa fa-fw fa-ellipsis-v" />
          </div>
          <div styleName="undo-delete" onClick={this.onUnRemoveClickHandler}>
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="actions">
          <div styleName="action actions__edit" onClick={this.onClickHandler}>
            <i className="fal fa-fw fa-pencil" />
          </div>
          <div
            styleName="action actions__delete"
            onClick={this.onArchiveClickHandler}
          >
            <i className="fal fa-fw fa-archive" />
          </div>
        </div>
      </div>
    );
  }
  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickHandler() {
    this.props.onEmployeeClickHandler(this.props.employee);
  }
  private onArchiveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ preppedForRemove: true, showActions: false });
    this.props.onOfficeEmployeeArchive(this.props.employee.id);

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.removeEmployeeFromList(this.props.employee.id);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private onUnRemoveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    this.props.onOfficeEmployeeUnarchive(this.props.employee.id);
  }

  private toggleShowActions() {
    this.setState({ showActions: !this.state.showActions });
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.showActions
    )
      this.setState({ showActions: false });
  }
}
