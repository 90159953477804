import {
  Priority,
  TimelineActionSubType,
  TimelineActionType,
  TimelineEvent,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import {
  INVOICEROUTES,
  SCHEDULERROUTES,
  SEARCHASSIGNMENTROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./linked-event-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  onClick: (path: string) => void;
  event: TimelineEvent;
}

@CSSModules(styles, { allowMultiple: true })
export class LinkedEventComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const { event } = this.props;
    const eventStyle = classNames("event", {
      "is-clickable":
        [
          TimelineActionType.AgendaItem,
          TimelineActionType.Invoice,
          TimelineActionType.Task,
          TimelineActionType.SearchProfile,
        ].indexOf(event.actionType) !== -1 &&
        !this.props.event.accessDeniedForCurrentUser,
    });

    return (
      <div
        styleName={eventStyle}
        onClick={this.onClickHandler}
        data-cy={this.props["data-cy"]}
      >
        <div styleName="icon">
          <i
            className={
              "fal fa-fw " + this.renderTimelineActionTypeIcon(event.actionType)
            }
          />
          {event.actionType === TimelineActionType.Task &&
            event.priority === Priority.High && (
              <i
                styleName="item__important"
                className="fa fa-fw fa-exclamation"
                aria-hidden="true"
              />
            )}
        </div>
        <div styleName="info">
          <h3>{this.renderTimelineActionTypeIconHeader(event)}</h3>
          <FormattedDate
            value={new Date(event.date)}
            day="2-digit"
            month="long"
            year="numeric"
          />
        </div>
      </div>
    );
  }

  private renderTimelineActionTypeIcon(actionType: TimelineActionType): string {
    switch (actionType) {
      case TimelineActionType.AgendaItem:
        return "fa-calendar-alt";
      case TimelineActionType.Invoice:
        return "fa-euro-sign";
      case TimelineActionType.Task:
        return "fa-tasks";
      case TimelineActionType.Unknown:
        return "fa-file-o";
      case TimelineActionType.Email:
        return "fa-envelope";
      case TimelineActionType.ContactMe:
        return "fa-comment-alt";
      case TimelineActionType.Website:
        return "fa-globe";
      case TimelineActionType.SearchProfile:
        return "fa-search";
      case TimelineActionType.Transaction:
        return "fa-handshake";
      case TimelineActionType.CommunicationLog:
        return "fa-envelope";
      default:
        return "fa-question-circle";
    }
  }

  private renderTimelineActionTypeIconHeader(
    event: TimelineEvent
  ): string | React.ReactElement<HTMLSpanElement> {
    switch (event.actionType) {
      case TimelineActionType.Transaction:
        return this.renderTimelineSubActionTypeIconHeader(event);
      case TimelineActionType.Invoice:
        return !!event.invoiceNumber ? (
          <ResourceText
            resourceKey="invoiceNumber"
            values={{ invoiceNumber: event.invoiceNumber }}
          />
        ) : (
          <ResourceText resourceKey="concept" />
        );
      default:
        return (
          event.subject || <ResourceText resourceKey="timelineNoSubject" />
        );
    }
  }

  private renderTimelineSubActionTypeIconHeader(
    event: TimelineEvent
  ): string | React.ReactElement<HTMLSpanElement> {
    switch (event.actionSubType) {
      case TimelineActionSubType.PriceChange:
        const oldPrice = intlContext.formatNumber(event.oldPrice | 0, {
          style: "currency",
          currency: "EUR",
        });
        const newPrice = intlContext.formatNumber(event.price | 0, {
          style: "currency",
          currency: "EUR",
        });
        return (
          <ResourceText
            resourceKey="askingPriceUpdatedTo"
            values={{ oldPrice, newPrice }}
          />
        );
      default:
        return (
          <ResourceText
            masterKey="timelineActionSubOptions"
            resourceKey={event.actionSubType.toString()}
          />
        );
    }
  }

  private onClickHandler() {
    if (this.props.event.accessDeniedForCurrentUser) return;

    switch (this.props.event.actionType) {
      case TimelineActionType.AgendaItem:
        this.props.onClick(
          route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
            id: this.props.event.id,
          })
        );
        break;
      case TimelineActionType.Task:
        this.props.onClick(
          route(TASKROUTES.TASK.URI, { id: this.props.event.id })
        );
        break;
      case TimelineActionType.Invoice:
        this.props.onClick(
          route(INVOICEROUTES.DETAIL.URI, { id: this.props.event.id })
        );
        break;
      case TimelineActionType.SearchProfile:
        this.props.onClick(
          route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id: this.props.event.id })
        );
        break;
      default:
        break;
    }
  }
}
