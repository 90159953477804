import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, ConfirmState, LayoutActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { ConfirmComponent, ConfirmComponentProps } from "./confirm.component";

interface StateProps {
  confirm: ConfirmState;
}
interface DispatchProps {
  close: () => any;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ConfirmComponentProps,
  AppState
> = (state) => ({
  confirm: state.layout.confirm
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ConfirmComponentProps
> = (dispatch) => ({
  close: () => dispatch(LayoutActions.hideConfirm())
});

export type ConfirmContainerProps = StateProps & DispatchProps;
export const ConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmComponent);
