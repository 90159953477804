import { RequestStatus } from "@haywork/enum";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { LayoutHelpComponent, LayoutHelpComponentProps } from "./help";

interface StatepProps {
  asapStatus: RequestStatus;
  asapVisible: boolean;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StatepProps,
  LayoutHelpComponentProps,
  AppState
> = (state) => {
  const { asapStatus, asapVisible } = state.zoho.asap;

  return {
    asapStatus,
    asapVisible
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LayoutHelpComponentProps
> = (dispatch) => ({});

export type LayoutHelpContainerProps = StatepProps & DispatchProps;
export const LayoutHelpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutHelpComponent);
