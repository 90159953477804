import { AgendaItemSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps, SchedulerThunks } from "@haywork/middleware";
import {
  DashboardSchedulerWidgetComponent,
  DashboardSchedulerWidgetComponentProps
} from "@haywork/modules/dashboard/components/widgets/scheduler-widget.component";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  agendaItems: AgendaItemSnapShot[];
  schedulerWidgetState: string;
}
interface DispatchProps {
  getAgendaItemsForToday: (
    returnOccurencesOfRecurringAgendaItems: boolean
  ) => void;
  navigate: (route: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DashboardSchedulerWidgetComponentProps,
  AppState
> = (state) => ({
  agendaItems: state.scheduler.agendaItemsForToday,
  schedulerWidgetState: state.scheduler.schedulerWidgetState
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DashboardSchedulerWidgetComponentProps
> = (dispatch) => ({
  getAgendaItemsForToday: () =>
    dispatch(SchedulerThunks.getAgendaItemsForToday(true)),
  navigate: (route: string) => dispatch(push(route))
});

export type SchedulerWidgetContainerProps = StateProps & DispatchProps;
export const SchedulerWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSchedulerWidgetComponent);
