import { ObjectAssignment, RealEstateGroup } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentEditFinancialContainerProps,
  AssignmentEditFinancialRentAlvContainer,
  AssignmentEditFinancialRentBogContainer,
  AssignmentEditFinancialRentContainer,
  AssignmentEditFinancialSaleContainer
} from "@haywork/modules/assignment";
import { FormReturnValue } from "@haywork/modules/form";
import { RouteUtil } from "@haywork/util";
import * as React from "react";

export type AssignmentEditFinancialComponentProps = {};
type Props = AssignmentEditFinancialComponentProps &
  AssignmentEditFinancialContainerProps;

export class AssignmentEditFinancialComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    const { objectAssignment } = this.props.currentComponentState;
    const { forSale, realEstateGroup }: ObjectAssignment = objectAssignment;

    switch (forSale) {
      case true: {
        return (
          <AssignmentEditFinancialSaleContainer
            onChange={this.onChangeHandler}
          />
        );
      }
      default: {
        switch (realEstateGroup) {
          case RealEstateGroup.Residential: {
            return (
              <AssignmentEditFinancialRentContainer
                onChange={this.onChangeHandler}
              />
            );
          }
          case RealEstateGroup.Commercial: {
            return (
              <AssignmentEditFinancialRentBogContainer
                onChange={this.onChangeHandler}
              />
            );
          }
          case RealEstateGroup.Agricultural: {
            return (
              <AssignmentEditFinancialRentAlvContainer
                onChange={this.onChangeHandler}
              />
            );
          }
          default: {
            return null;
          }
        }
      }
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const { id } = this.props.currentComponentState.objectAssignment;
    const path = RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id
    });
    const objectAssignment = {
      ...this.props.currentComponentState.objectAssignment,
      ...values
    };
    const newState = {
      ...this.props.currentComponentState,
      objectAssignment
    };

    this.props.updateAssignment(newState, path);
  }
}
