import { AssignmentSnapShot, ActiveFilter } from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { KeyBoardComponent, KeyBoardComponentProps } from "../components";

interface StateProps {
  assignments: AssignmentSnapShot[];
  status: string;
  showAllKeyboardAssignments?: ActiveFilter;
}
interface DispatchProps {
  getAssignmentsWithKey: (filterByActive?: ActiveFilter) => Promise<void>;
  navigate: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  KeyBoardComponentProps,
  AppState
> = (state) => {
  const { assignments, assignmentsStatus: status } = state.keyBoard;
  const showAllKeyboardAssignments = state.account.showAllKeyboardAssignments;

  return {
    assignments,
    status,
    showAllKeyboardAssignments,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  KeyBoardComponentProps
> = (dispatch) => ({
  getAssignmentsWithKey: (filterByActive?: ActiveFilter) =>
    dispatch(AssignmentThunks.getAssignmentsWithKey(filterByActive)),
  navigate: (path: string) => dispatch(push(path)),
});

export type KeyboardContainerProps = StateProps & DispatchProps;
export const KeyBoardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyBoardComponent);
