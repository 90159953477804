import {
  RelationGroupSnapShot,
  RelationGroupsStatistics,
  TableResponse,
} from "@haywork/api/kolibri";
import { Dispatch, RelationGroupsThunk } from "@haywork/middleware";
import { RelationGroupsComponentProps } from "@haywork/modules/settings";
import RelationGroupsComponent from "@haywork/modules/settings/modules/relation-groups/components/relation-groups.component";
import { AppState, RelationGroupsActions } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  masterTable: TableResponse;
  relationGroups: RelationGroupSnapShot[];
  deletedRelationGroupsIds: string[];
  relationGroupsStatus: string;
  relationAddGroupsStatus: string;
  relationGroupsStatistics: RelationGroupsStatistics;
}

interface DispatchProps {
  updateRelationGroup: (relationGroupSnapShot: RelationGroupSnapShot) => void;
  addNewRelationGroup: (color: string, name: string) => void;
  deleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) => void;
  unDeleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) => void;
  removeFromList: (relationGroup: RelationGroupSnapShot) => void;
}

const mapStateToProps = <StateProps, RelationGroupComponentProps>(
  state: AppState
) => {
  const masterTable = state.main.mastertable.kolibri;
  const {
    relationGroups,
    deletedRelationGroupsIds,
    relationGroupsStatus,
    relationAddGroupsStatus,
    relationGroupsStatistics,
  } = state.relationGroups;

  return {
    masterTable,
    relationGroups,
    deletedRelationGroupsIds,
    relationGroupsStatus,
    relationAddGroupsStatus,
    relationGroupsStatistics,
  };
};

const mapDispatchToProps = <DispatchProps, TaskOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateRelationGroup: (relationGroupSnapShot: RelationGroupSnapShot) =>
    dispatch(RelationGroupsThunk.saveRelationGroup(relationGroupSnapShot)),
  addNewRelationGroup: (color: string, name: string) =>
    dispatch(RelationGroupsThunk.addNewRelationGroup(color, name)),

  deleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) =>
    dispatch(RelationGroupsThunk.deleteRelationGroup(relationGroup.id)),
  unDeleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) =>
    dispatch(RelationGroupsThunk.unDeleteRelationGroup(relationGroup.id)),
  removeFromList: (relationGroup: RelationGroupSnapShot) =>
    dispatch(RelationGroupsActions.deleteFromState({ id: relationGroup.id })),
});

export type RelationGroupsContainerProps = StateProps & DispatchProps;
export const RelationGroupsContainer = connect<
  StateProps,
  DispatchProps,
  RelationGroupsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationGroupsComponent);
