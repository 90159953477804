/// <reference path="./../../../definitions/index.d.ts" />

import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import { accessReducer, AccessState } from "./access";
import { accountReducer, AccountState } from "./account";
import { acquisitionReducer, AcquisitionState } from "./acquisition";
import { appSettingsReducer, AppSettingsState } from "./app-settings";
import { assignmentReducer, AssignmentState } from "./assignment";
import { authorizationReducer, AuthorizationState } from "./authorization";
import { companyReducer, CompanyState } from "./company";
import {
  dynamicDocumentsReducer,
  DynamicDocumentsState,
} from "./dynamic-documents";
import { editableReducer, EditableState } from "./editable";
import { emailReducer, EmailState } from "./email";
import { emailReducerV2, EmailStateV2 } from "./email-v2";
import { EmployeeFilterState, employeeReducer } from "./employee";
import { errorReducer, ErrorState } from "./error";
import { eventCenterReducer, EventCenterState } from "./event-center";
import { formReducer, FormState } from "./form";
import { globalSearchReducer, GlobalSearchState } from "./global-search";
import { invoiceReducer, InvoiceState } from "./invoice";
import { keyBoardReducer, KeyBoardState } from "./keyboard";
import { layoutReducer, LayoutState } from "./layout";
import { liveDataReducer, LiveDataState } from "./live-data";
import { mainReducer, MainState } from "./main";
import { mediapartnerReducer, MediapartnersState } from "./mediapartners";
import { mlsReducer, MLSState } from "./mls";
import { newEntityReducer, NewEntityState } from "./new-entity";
import { notesV3Reducer, NotesV3State } from "./notes-v3";
import { notificationsReducer, NotificationsState } from "./notifications";
import { officesReducer, OfficeState } from "./offices";
import { phrasesReducer, PhrasesState } from "./phrases";
import { projectReducer, ProjectState } from "./project";
import {
  realEstateAgencyReducer,
  RealEstateAgencyState,
} from "./real-estate-agency";
import { relationReducer, RelationState } from "./relation";
import { relationGroupsReducer, RelationGroupsState } from "./relation-groups";
import { remindersReducer, RemindersState } from "./reminders";
import { schedulerReducer, SchedulerState } from "./scheduler";
import {
  schedulerCategoryReducer,
  SchedulerCategoryState,
} from "./scheduler-category";
import {
  searchAssignmentReducer,
  SearchAssignmentState,
} from "./search-assignment";
import { settingsReducer, SettingsState } from "./settings";
import { snackbarReducer, SnackbarState } from "./snackbar-v2";
import { statisticsReducer, StatisticsState } from "./statistics";
import { taskReducer, taskReducerV2, TaskState, TaskStateV2 } from "./task";
import { voipReducer, VoipState } from "./voip";
import { zohoReducer, ZohoState } from "./zoho";
import { loggingReducer, LoggingState } from "./logging";
import { scrollPositionReducer, ScrollPositionState } from "./scroller-wrapper";

declare let window: ExtendedWindow;
interface AppState {
  access: AccessState;
  account: AccountState;
  acquisition: AcquisitionState;
  appSettings: AppSettingsState;
  assignment: AssignmentState;
  authorization: AuthorizationState;
  company: CompanyState;
  dynamicDocuments: DynamicDocumentsState;
  editable: EditableState;
  email: EmailState;
  emailV2: EmailStateV2;
  employee: EmployeeFilterState;
  error: ErrorState;
  eventCenter: EventCenterState;
  form: FormState;
  globalSearch: GlobalSearchState;
  invoice: InvoiceState;
  keyBoard: KeyBoardState;
  layout: LayoutState;
  liveData: LiveDataState;
  main: MainState;
  mediapartners: MediapartnersState;
  mls: MLSState;
  newEntity: NewEntityState;
  notesV3: NotesV3State;
  notifications: NotificationsState;
  offices: OfficeState;
  phrases: PhrasesState;
  project: ProjectState;
  realEstateAgency: RealEstateAgencyState;
  relation: RelationState;
  relationGroups: RelationGroupsState;
  reminders: RemindersState;
  router: RouterState;
  scheduler: SchedulerState;
  schedulerCategory: SchedulerCategoryState;
  searchAssignment: SearchAssignmentState;
  settings: SettingsState;
  snackbar: SnackbarState;
  statistics: StatisticsState;
  task: TaskState;
  taskV2: TaskStateV2;
  voip: VoipState;
  zoho: ZohoState;
  logging: LoggingState;
  scrollPosition: ScrollPositionState;
}

let enhancers;
const history = createBrowserHistory();

if (
  process.env.NODE_ENV === "development" &&
  !!window.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers = compose(
    applyMiddleware(routerMiddleware(history), thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  enhancers = compose(applyMiddleware(routerMiddleware(history), thunk));
}

const persistAccountConfig: PersistConfig<AccountState> = {
  key: "haywork:account",
  storage: sessionStorage,
  whitelist: ["currentRealestateAgency"],
};

const persistAccessConfig: PersistConfig<AccessState> = {
  key: "haywork:access",
  storage: sessionStorage,
};

const persistEditableConfig: PersistConfig<EditableState> = {
  key: "haywork:editable",
  storage: sessionStorage,
};

const persistSchedulerConfig: PersistConfig<SchedulerState> = {
  key: "haywork:scheduler",
  storage: sessionStorage,
  whitelist: ["schedulerFilters", "agendaItemCategories"],
};

const persistErrorConfig: PersistConfig<ErrorState> = {
  key: "haywork:error",
  storage: sessionStorage,
};

const persistLoggingConfig: PersistConfig<LoggingState> = {
  key: "haywork:logging",
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  access: persistReducer(persistAccessConfig, accessReducer),
  account: persistReducer(persistAccountConfig, accountReducer),
  acquisition: acquisitionReducer,
  appSettings: appSettingsReducer,
  assignment: assignmentReducer,
  authorization: authorizationReducer,
  company: companyReducer,
  dynamicDocuments: dynamicDocumentsReducer,
  editable: persistReducer(persistEditableConfig, editableReducer),
  email: emailReducer,
  emailV2: emailReducerV2,
  employee: employeeReducer,
  error: persistReducer(persistErrorConfig, errorReducer),
  eventCenter: eventCenterReducer,
  notifications: notificationsReducer,
  form: formReducer,
  globalSearch: globalSearchReducer,
  invoice: invoiceReducer,
  keyBoard: keyBoardReducer,
  layout: layoutReducer,
  liveData: liveDataReducer,
  main: mainReducer,
  mediapartners: mediapartnerReducer,
  mls: mlsReducer,
  newEntity: newEntityReducer,
  notesV3: notesV3Reducer,
  offices: officesReducer,
  phrases: phrasesReducer,
  project: projectReducer,
  realEstateAgency: realEstateAgencyReducer,
  relation: relationReducer,
  relationGroups: relationGroupsReducer,
  reminders: remindersReducer,
  scheduler: persistReducer(persistSchedulerConfig, schedulerReducer),
  schedulerCategory: schedulerCategoryReducer,
  searchAssignment: searchAssignmentReducer,
  settings: settingsReducer,
  snackbar: snackbarReducer,
  statistics: statisticsReducer,
  task: taskReducer,
  taskV2: taskReducerV2,
  voip: voipReducer,
  zoho: zohoReducer,
  logging: persistReducer(persistLoggingConfig, loggingReducer),
  scrollPosition: scrollPositionReducer,
});

const initialState = <AppState>{};
const store = createStore<AppState, any, any, any>(
  rootReducer,
  initialState,
  enhancers
);
const persistor = persistStore(store);

export { store, AppState, history, persistor };
