import * as React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@haywork/modules/modal";
import { AddCommunicationLogContainerProps } from "./add-communication-log.container";
import { ResourceText } from "../resource-text/resource-text.component";
import {
  Form,
  FormControls,
  FormReference,
  Validators,
  Input
} from "@haywork/modules/form";
import { AsyncUtil, RegexUtil } from "@haywork/util";
import { AddManualCommunicationLogRequest } from "@haywork/middleware/thunk/voip/calls.thunk";
import {
  CommunicationLogDirection,
  RelationType,
  ActiveFilter,
  LinkedRelation,
  LinkedAssignment,
  RelationSnapShot
} from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import { NewEntityOptions, NewEntity } from "@haywork/modules/new-entity";
import { NewEntityType } from "@haywork/enum";

const styles = require("./style.scss");

export interface AddCommunicationLogComponentProps {
  visible: boolean;
  linkedRelation?: LinkedRelation;
  linkedAssignment?: LinkedAssignment;
  onClose: (refresh: boolean) => void;
}
interface State {
  saving: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = AddCommunicationLogComponentProps &
  AddCommunicationLogContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AddCommunicationLogComponent extends React.PureComponent<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      newEntityVisible: false,
      newEntityOptions: null
    };

    this.formControls = {
      relation: {
        value: this.props.linkedRelation || "",
        validators: [Validators.required()]
      },
      assignment: {
        value: this.props.linkedAssignment || ""
      },
      direction: {
        value: CommunicationLogDirection.Outgoing,
        validators: [Validators.required()]
      },
      subject: { value: "", validators: [Validators.required()] },
      description: { value: "" },
      employee: {
        value: this.props.employee,
        validators: [Validators.required()]
      }
    };

    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public render() {
    return (
      <>
        <Modal visible={this.props.visible}>
          <ModalHeader title="addCommunicationLog.title" />
          <ModalBody>
            <Form
              name="add-communication-log"
              formControls={this.formControls}
              form={(form) => (this.formRef = form)}
              onSubmit={this.onSubmit}
            >
              <div className="form__row">
                <label htmlFor="direction">
                  <ResourceText resourceKey="addCommunicationLog.label.direction" />
                </label>
                <Input.RadioGroup name="direction" asButtonList={true}>
                  <Input.Radio
                    value={CommunicationLogDirection.Outgoing}
                    label="OutgoingCall"
                  />
                  <Input.Radio
                    value={CommunicationLogDirection.Incoming}
                    label="IncomingCall"
                  />
                </Input.RadioGroup>
              </div>

              {!this.props.linkedRelation && (
                <div className="form__row">
                  <label htmlFor="relation">
                    <ResourceText resourceKey="addCommunicationLog.label.relation" />
                  </label>
                  <Input.RelationQuery
                    name="relation"
                    filterByRelationTypes={[
                      RelationType.ContactCompany,
                      RelationType.ContactPerson
                    ]}
                    filterByActive={ActiveFilter.ActiveOrInactive}
                    onAdd={this.addNewRelation}
                  />
                </div>
              )}

              <div className="form__row">
                <label htmlFor="employee">
                  <ResourceText resourceKey="addCommunicationLog.label.employee" />
                </label>
                <Input.RelationQuery
                  name="employee"
                  filterByRelationTypes={[RelationType.Employee]}
                  filterByActive={ActiveFilter.ActiveOrInactive}
                />
              </div>

              <div className="form__row">
                <label htmlFor="subject">
                  <ResourceText resourceKey="addCommunicationLog.label.subject" />
                </label>
                <Input.Text
                  name="subject"
                  placeholder="addCommunicationLog.label.subject"
                />
              </div>

              <div className="form__row">
                <label htmlFor="description">
                  <ResourceText resourceKey="addCommunicationLog.label.description" />
                </label>
                <Input.Textarea
                  autoSize={true}
                  name="description"
                  minHeight={72}
                  placeholder="addCommunicationLog.label.description"
                />
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-blank"
              disabled={this.state.saving}
              onClick={() => this.onClose()}
            >
              <ResourceText resourceKey="addCommunicationLog.cancel" />
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.state.saving}
              onClick={() => this.formRef.submit()}
            >
              <ResourceText resourceKey="addCommunicationLog.save" />
            </button>
          </ModalFooter>
          <NewEntity
            visible={this.state.newEntityVisible}
            options={this.state.newEntityOptions}
            onClose={this.onNewEntityCloseHandler}
            onNewRelation={this.onNewRelationHandler}
          />
        </Modal>
      </>
    );
  }

  private async onClose(refresh: boolean = false) {
    this.props.onClose(refresh);
    await AsyncUtil.wait(500);
    if (!!this.formRef) {
      this.formRef.update({
        relation: this.props.linkedRelation || "",
        direction: CommunicationLogDirection.Outgoing,
        subject: "",
        description: "",
        employee: this.props.employee
      });
      this.formRef.clearErrors();
      this.setState({
        saving: false
      });
    }
  }

  private async onSubmit(values: AddManualCommunicationLogRequest) {
    if (this.state.saving) return;
    this.setState({ saving: true });

    try {
      await this.props.addManualCommunicationLog(values);
      this.onClose(true);
    } catch (error) {
      throw error;
    } finally {
      this.setState({ saving: true });
    }
  }

  private addNewRelation(name: string) {
    let typeOfInfo: string;
    if (RegexUtil.isNumber(name)) typeOfInfo = "telephone";
    if (RegexUtil.isValidEmail(name)) typeOfInfo = "email";
    if (!RegexUtil.isNumber(name) && !RegexUtil.isValidEmail(name))
      typeOfInfo = "name";

    if (this.state.newEntityVisible) return;

    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          [`${typeOfInfo}`]: name
        }
      }
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false
    });
  }

  private onNewRelationHandler(newRelation: RelationSnapShot) {
    this.formRef.update({
      relation: newRelation
    });

    this.setState({
      newEntityVisible: false
    });
  }
}
