import { RealEstateProperty } from "@haywork/api/mls";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import {
  DetailMediaComponent,
  DetailMediaComponentProps,
} from "./detail-media";
import { attachments, GroupedAttachments } from "./selectors";
import { PhotoBlob } from "@haywork/api/kolibri";

type StateProps = {
  attachments: GroupedAttachments[];
};
type DispatchProps = {
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DetailMediaComponentProps,
  AppState
> = (state) => {
  return {
    attachments: attachments(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DetailMediaComponentProps
> = (dispatch) => ({
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
});

export type DetailMediaContainerProps = StateProps & DispatchProps;
export const DetailMediaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailMediaComponent);
