import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import {
  TabBarContainerProps,
  TabComponent as Tab,
} from "@haywork/modules/tab";
import { EditableItem } from "@haywork/stores";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ExtraTabComponent } from "./extra-tab.component";

const styles = require("./tab-bar.component.scss");

export interface TabBarComponentProps {}
interface TabBarComponentState {
  showExtraMenu: boolean;
  extraMenuExpanded: boolean;
}
type TabBarComponentConnectProps = TabBarComponentProps & TabBarContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class TabBarComponent extends React.Component<
  TabBarComponentConnectProps,
  TabBarComponentState
> {
  private ref: HTMLDivElement;
  private tabsRef: HTMLDivElement;
  private listRef: HTMLDivElement;
  private newItemAdded: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      showExtraMenu: false,
      extraMenuExpanded: false,
    };

    this.calculateTabs = this.calculateTabs.bind(this);
    this.onTabClickHandler = this.onTabClickHandler.bind(this);
    this.onWindowResizeHandler = debounce(
      this.onWindowResizeHandler.bind(this),
      50,
      { leading: true }
    );
    this.scrollToTab = this.scrollToTab.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onTabCloseHandler = this.onTabCloseHandler.bind(this);
    this.onTabListClickHandler = this.onTabListClickHandler.bind(this);

    window.addEventListener(
      "resize",
      this.onWindowResizeHandler.bind(this),
      true
    );
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TabBarComponentConnectProps
  ) {
    if (!nextProps) return;
    this.newItemAdded = nextProps.tabs.length > this.props.tabs.length;
  }

  public render() {
    return (
      <div styleName="tab-bar" ref={(ref) => (this.ref = ref)}>
        <div styleName="tab-bar__tabs" ref={(ref) => (this.tabsRef = ref)}>
          <div styleName="tabs">
            {this.props.tabs.map((tab, idx) => (
              <ErrorBoundary key={idx}>
                <Tab
                  tab={tab}
                  onTabClose={this.onTabCloseHandler}
                  onTabClick={(path) => this.onTabClickHandler(path, idx)}
                />
              </ErrorBoundary>
            ))}
          </div>
        </div>
        {this.state.showExtraMenu && (
          <div
            styleName={classNames("tab-bar__list", {
              expanded: this.state.extraMenuExpanded,
            })}
            ref={(ref) => (this.listRef = ref)}
          >
            <div styleName="trigger" onClick={this.onTabListClickHandler}>
              <ResourceText resourceKey="allTabs" />
              <i className="fal fa-chevron-down" />
            </div>
            <div styleName="list">
              {this.props.tabs.map((tab, idx) => (
                <ErrorBoundary key={idx}>
                  <ExtraTabComponent
                    tab={tab}
                    onTabClick={(path) => this.onTabClickHandler(path, idx)}
                  />
                </ErrorBoundary>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  public componentDidUpdate() {
    this.calculateTabs();
  }

  public componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.onWindowResizeHandler.bind(this),
      true
    );
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onWindowResizeHandler() {
    this.calculateTabs();
    this.setState({
      extraMenuExpanded: false,
    });
  }

  private onClickOutsideHandler(event: any) {
    if (!this.listRef) return;
    const clickedInside = this.listRef.contains(event.target);
    if (!clickedInside && !!this.state.extraMenuExpanded)
      this.setState({ extraMenuExpanded: false });
  }

  private onTabClickHandler(path: string, key: number) {
    this.scrollToTab(key);
    this.setState({ extraMenuExpanded: false });

    if (path !== this.props.currentPath) {
      this.props.updateReferrer(path, this.props.currentPath);
      this.props.navigate(path);
    }
  }

  private onTabCloseHandler(tab: EditableItem) {
    if (tab.hasChanges && tab.confirm) {
      const { title, body } = tab.confirm;

      if (tab.currentPath !== this.props.currentPath) {
        this.props.updateReferrer(tab.currentPath, this.props.currentPath);
        this.props.navigate(tab.currentPath);
      }

      this.props.showConfirm(
        title,
        body,
        () => this.props.stateSetPreppedForSave(tab.path),
        () => this.props.removeState(tab.path),
        { key: "save" },
        { key: "dontSave" }
      );
    } else {
      this.props.removeState(tab.path);
    }
  }

  private onTabListClickHandler() {
    this.setState({
      extraMenuExpanded: !this.state.extraMenuExpanded,
    });
  }

  private calculateTabs() {
    if (!this.ref || !this.tabsRef) return;
    const { width: refWidth } = this.tabsRef.getBoundingClientRect();
    const tabs = this.ref.querySelectorAll(`[class^="tab-component_tab_"]`);

    let showExtraMenu = false;
    let breakpoint = 0;
    for (let i = 0; i < tabs.length; i++) {
      const { width: tabwidth } = tabs.item(i).getBoundingClientRect();
      breakpoint += tabwidth;
      if (breakpoint > refWidth) {
        showExtraMenu = true;
        break;
      }
    }

    if (showExtraMenu !== this.state.showExtraMenu) {
      this.setState({
        showExtraMenu,
        extraMenuExpanded: false,
      });
    }

    if (!!this.newItemAdded) {
      this.newItemAdded = false;
      setTimeout(this.scrollToTab, 100);
    }
  }

  private scrollToTab(key?: number) {
    if (!this.ref || !this.tabsRef) return;
    const tabs = this.ref.querySelectorAll(`[class^="tab-component_tab_"]`);
    const tab = tabs.item(key !== undefined ? key : tabs.length - 1);
    const { left: containerLeft, width: containerWidth } =
      this.tabsRef.getBoundingClientRect();
    const { left: tabLeft, width: tabWidth } = tab.getBoundingClientRect();
    const tabMargin = 2;

    this.tabsRef.scrollLeft =
      tabLeft -
      containerLeft -
      tabMargin -
      containerWidth / 2 +
      this.tabsRef.scrollLeft +
      tabWidth / 2;
  }
}
