import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  DossierLinkedEntitiesComponent,
  DossierLinkedEntitiesComponentProps
} from "./linked-entities";

interface StateProps {}
interface DispatchProps {
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DossierLinkedEntitiesComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DossierLinkedEntitiesComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path))
});

export type DossierLinkedEntitiesContainerProps = StateProps & DispatchProps;
export const DossierLinkedEntitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DossierLinkedEntitiesComponent);
