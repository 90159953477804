import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { String } from "@haywork/util/string";
import I18n from "@haywork/components/i18n";

const styles = require("./keyboard.component.scss");

interface Props {
  assignment: AssignmentSnapShot;
  onClick: (assignment: AssignmentSnapShot) => void;
}

export const KeyDetail = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const {
      displayName,
      keyNr,
      keyNote,
      photo1Url,
      realEstateGroup,
      forSale
    } = props.assignment;

    return (
      <div
        styleName="key-detail"
        onClick={() => props.onClick(props.assignment)}
      >
        <div styleName="key-detail__photo">
          {!!photo1Url ? (
            <div
              styleName="photo"
              style={{ backgroundImage: `url(${JSON.stringify(photo1Url)})` }}
            />
          ) : (
            <div styleName="icon">
              <i className="fal fa-camera-alt" />
            </div>
          )}
          <div styleName="key-number">{keyNr}</div>
        </div>
        <div styleName="key-detail__info">
          <I18n value={realEstateGroup.toString()} prefix="realEstateGroups" />,{" "}
          <I18n value={!!forSale ? "forSale" : "forRent"} />
        </div>
        <div styleName="key-detail__body">
          <h1>{displayName}</h1>
          {!!keyNote && (
            <div styleName="excerpt">{String.trim(keyNote, 36)}</div>
          )}
        </div>
      </div>
    );
  }
);
