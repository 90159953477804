import { ObjectAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES, EXTERNALROUTES } from "@haywork/constants";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { AssignmentSaveError, AssignmentUtil, RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./save-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentSaveModalComponentProps {
  visible: boolean;
  objectAssignment: ObjectAssignment;
  onClose: () => void;
}
interface AssignmentSaveModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentSaveModalComponent extends React.Component<
  AssignmentSaveModalComponentProps,
  AssignmentSaveModalComponentState
> {
  constructor(props) {
    super(props);

    this.mapError = this.mapError.bind(this);
  }

  public render() {
    const errors = AssignmentUtil.getErrors(this.props.objectAssignment);

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="saveAssignmentMissingFields" close />
        <ModalBody>
          <ResourceText
            resourceKey="saveAssignmentMissingFieldsBody"
            values={{ count: errors.length }}
          />
          {errors.map(this.mapError)}
        </ModalBody>
        <ModalFooter>
          <div id="cancelError" className="btn" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private mapError(
    error: AssignmentSaveError,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { id } = this.props.objectAssignment;
    let link, info;

    switch (error) {
      case AssignmentSaveError.IncompleteAddress:
        link = route(ASSIGNMENTROUTES.EDIT_ADDRESS.URI, { id });
        break;
      case AssignmentSaveError.NoLinkedEmployee:
      case AssignmentSaveError.NoListingType:
      case AssignmentSaveError.NoRealEstateGroup:
      case AssignmentSaveError.NoTypeOf:
      case AssignmentSaveError.NoLeisureType:
        link = route(ASSIGNMENTROUTES.EDIT_CLIENT.URI, { id });
        break;
      case AssignmentSaveError.IncompleteFloors:
        link = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
        info = EXTERNALROUTES.SUPPORT_INCOMPLETE_FLOORS.URI;
        break;
      default:
        route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return (
      <div styleName="error__wrapper" key={idx}>
        <div styleName="error" onClick={this.props.onClose}>
          <NavLink to={link}>
            <ResourceText resourceKey={error.toString()} />
          </NavLink>
        </div>
        {!!info && (
          <NavLink to={info} styleName="info" target="_blank">
            <i className="fa fa-info" />
          </NavLink>
        )}
      </div>
    );
  }
}
