import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";
import { DocumentTemplateCategory } from "@haywork/modules/dynamic-documents";

const styles = require("./category.component.scss");

interface DynamicDocumentsCategoryComponentProps {
  category: DocumentTemplateCategory;
  onClick: (id) => void;
}
interface DynamicDocumentsCategoryComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsCategoryComponent extends React.Component<
  DynamicDocumentsCategoryComponentProps,
  DynamicDocumentsCategoryComponentState
> {
  public render() {
    const { category } = this.props;
    const templates = category.templates || [];

    return (
      <div styleName="category">
        <span className="linedTitle">
          <ResourceText
            masterKey="documentTemplateTypeOptions"
            resourceKey={category.category.toString()}
          />
        </span>
        <div>
          {templates.map((template, idx) => (
            <span
              styleName="document"
              onClick={() => this.props.onClick(template.id)}
              key={idx}
            >
              <i className="fal fa-fw fa-file-alt" />
              <span>{template.name}</span>
            </span>
          ))}
        </div>
      </div>
    );
  }
}
