import { DynamicDocumentsPreviewContainerProps } from "@haywork/modules/dynamic-documents/containers/preview.container";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ToolTipComponent } from "./tooltip.component";

const styles = require("./preview.component.scss");

export type DynamicDocumentsPreviewComponentProps = {
  scrollable: HTMLDivElement;
  onMergefieldClick: (name: string) => void;
};
type Props = DynamicDocumentsPreviewComponentProps &
  DynamicDocumentsPreviewContainerProps;
interface State {
  tooltipVisible: boolean;
  tooltipCallerClientRect: ClientRect;
}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsPreviewComponent extends React.Component<
  Props,
  State
> {
  private ref: HTMLDivElement;
  private mergeFields: NodeListOf<Element>;

  constructor(props) {
    super(props);

    this.state = {
      tooltipVisible: true,
      tooltipCallerClientRect: null,
    };

    this.onMergeFieldClick = this.onMergeFieldClick.bind(this);
    this.onMergeFieldMouseEnter = this.onMergeFieldMouseEnter.bind(this);
    this.onMergeFieldMouseLeave = this.onMergeFieldMouseLeave.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (nextProps.anchorName !== this.props.anchorName) {
      this.jumpToAnchor(nextProps.anchorName);
    }
    if (!nextProps.session || nextProps.session.id !== this.props.session.id) {
      this.props.clearHtmlPreview();
    }
  }

  public render() {
    return (
      <div styleName="preview">
        <ToolTipComponent
          visible={this.state.tooltipVisible}
          position={this.state.tooltipCallerClientRect}
        />
        <div
          ref={this.bindRef}
          dangerouslySetInnerHTML={{ __html: this.props.preview }}
        />
      </div>
    );
  }

  public componentDidUpdate() {
    if (!this.ref) return;
    this.removeEventListeners();

    this.mergeFields = this.ref.querySelectorAll("[class^=mergefield_]");

    for (let i = 0; i < this.mergeFields.length; i++) {
      const element = this.mergeFields.item(i);
      const fieldName = element.classList[0].replace(/mergefield_/g, "");
      if (fieldName === this.props.anchorName) {
        element.classList.add("preview_highlight");
      }
      element.addEventListener("click", this.onMergeFieldClick, true);
      if (element.classList.contains("filled_from_entity")) {
        element.addEventListener(
          "mouseenter",
          this.onMergeFieldMouseEnter,
          true
        );
        element.addEventListener(
          "mouseleave",
          this.onMergeFieldMouseLeave,
          true
        );
      }
    }
  }

  public componentWillUnmount() {
    this.removeEventListeners();
  }

  private onMergeFieldClick(event: MouseEvent) {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    const classList: DOMTokenList = event.currentTarget["classList"];
    const matchOn = /mergefield_/gi;

    if (!!classList && classList.length > 0) {
      for (let i = 0; i < classList.length; i++) {
        const className = classList.item(i);
        if (className.match(matchOn)) {
          this.props.onMergefieldClick(className.replace(matchOn, ""));
          break;
        }
      }
    }
  }

  private onMergeFieldMouseEnter(event: MouseEvent) {
    this.setState({
      tooltipVisible: true,
      tooltipCallerClientRect: event.currentTarget["getBoundingClientRect"](),
    });
  }

  private onMergeFieldMouseLeave() {
    this.setState({
      tooltipVisible: false,
      tooltipCallerClientRect: null,
    });
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) this.ref = ref;
  }

  private removeEventListeners() {
    if (!!this.mergeFields) {
      for (let i = 0; i < this.mergeFields.length; i++) {
        const element = this.mergeFields.item(i);
        element.removeEventListener("click", this.onMergeFieldClick, true);
        if (element.classList.contains("filled_from_entity")) {
          element.removeEventListener(
            "mouseenter",
            this.onMergeFieldMouseEnter,
            true
          );
          element.removeEventListener(
            "mouseleave",
            this.onMergeFieldMouseLeave,
            true
          );
        }
      }
    }
  }

  private jumpToAnchor(name: string) {
    if (!this.ref) return;
    const anchorName = `#usercontrol_${name}`;
    const highlighted = `.preview_highlight`;
    const shouldBeHighlighted = `.mergefield_${name}`;

    const anchor: HTMLAnchorElement = this.ref.querySelector(anchorName);
    const highlights = this.ref.querySelectorAll(highlighted);
    const newHighlights = this.ref.querySelectorAll(shouldBeHighlighted);

    if (!!anchor) {
      const { top: anchorTop } = anchor.getBoundingClientRect();
      const { top: parentTop } = this.props.scrollable.getBoundingClientRect();
      const offsetTop = anchorTop - parentTop + this.props.scrollable.scrollTop;

      this.props.scrollable.scrollTop = offsetTop - 56;
    }
    for (let i = 0; i < highlights.length; i++) {
      highlights.item(i).classList.remove("preview_highlight");
    }
    for (let i = 0; i < newHighlights.length; i++) {
      newHighlights.item(i).classList.add("preview_highlight");
    }
  }
}
