import { ErrorMessage } from "@haywork/modules/form";
import isString from "lodash-es/isString";
import isBoolean from "lodash-es/isBoolean";

export const required = (errorMessage?: ErrorMessage, skip?: () => boolean) => (value?: any): null | ErrorMessage => {
  if ((!!skip && skip() === true) || isBoolean(value)) return null;
  const refValue = (value && !isString(value)) ? value.toString() : value || "";
  if (refValue && refValue.trim().length > 0) return null;
  return errorMessage || { key: "validatorRequired" };
};
