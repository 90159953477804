import { RelationGroupSnapShot, RelationGroupType } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { REQUEST } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { ColorUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { ColorResult, GithubPicker } from "react-color";
import * as CSSModules from "react-css-modules";

const styles = require("./relation-group-item.component.scss");

interface RelationGroupItemComponentProps {
  relationGroup: RelationGroupSnapShot;
  zebra: boolean;
  relationCount: number;
  relationGroupsStatus: string;
  relationGroupChanged: (relationGroup: RelationGroupSnapShot) => void;
  deleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) => void;
  unDeleteRelationGroupItem: (relationGroup: RelationGroupSnapShot) => void;
  removeFromList: (relationGroup: RelationGroupSnapShot) => void;
}
interface RelationGroupItemComponentState {
  showActions: boolean;
  preppedForRemove: boolean;
  deleteCountdown: number;
  displayColorPicker: boolean;
  groupName: string;
  selectedColor: string;
}

@CSSModules(styles, { allowMultiple: true })
export class RelationGroupItemComponent extends React.Component<
  RelationGroupItemComponentProps,
  RelationGroupItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;
  private input: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      showActions: false,
      preppedForRemove: false,
      deleteCountdown: 5,
      displayColorPicker: false,
      groupName: this.props.relationGroup.name,
      selectedColor: this.props.relationGroup.backColor
        ? ColorUtil.hexToRgb(this.props.relationGroup.backColor)
        : null,
    };

    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onUnDeleteClickHandler = this.onUnDeleteClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.colorChanged = this.colorChanged.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const colorPickerColors = [
      "#EC407A",
      "#673AB7",
      "#2196F3",
      "#009688",
      "#4CAF50",
      "#FFC107",
      "#795548",
      "#E53935",
    ];
    const innerStyle = classNames("inner", {
      zebra: this.props.zebra,
      "actions-visible": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
      selected: this.state.displayColorPicker,
    });

    return (
      <div styleName="item" ref={(ref) => (this.ref = ref)}>
        <div styleName={innerStyle}>
          <div styleName="colorPicker">
            {/* Color picker start */}
            <div
              styleName="swatch"
              onClick={() => {
                this.setState({
                  displayColorPicker: !this.state.displayColorPicker,
                });
              }}
            >
              <div styleName="arrow">
                <i className="fal fa-chevron-down" />
              </div>
              <div
                styleName="color"
                style={{ backgroundColor: this.state.selectedColor }}
              />
            </div>
            {this.state.displayColorPicker ? (
              <div styleName="popover">
                <div
                  styleName="cover"
                  onClick={() => {
                    this.setState({ displayColorPicker: false });
                  }}
                />
                <GithubPicker
                  width="212px"
                  colors={colorPickerColors}
                  color={this.props.relationGroup.backColor}
                  onChange={this.colorChanged}
                />
              </div>
            ) : null}
            {/* Color picker end */}
          </div>
          <div styleName="item__description">
            {/* TODO: Placeholder Resource */}
            <input
              type="text"
              onClick={(e) => {
                e.stopPropagation();
              }}
              value={this.state.groupName}
              placeholder={intlContext.formatMessage({
                id: "clickHereToAddDescription",
              })}
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDownHandler}
              ref={(ref) => (this.input = ref)}
              onBlur={this.onBlurHandler}
              data-lpignore="true"
            />
          </div>
          <div styleName="item__statistics">
            <ResourceText
              resourceKey="relationsWithRole"
              values={{ amountOfRelations: this.props.relationCount }}
            />
          </div>
          {this.props.relationGroup.relationGroupType ===
          RelationGroupType.UserDefined ? (
            <div styleName="item__toggle" onClick={this.onToggleClickHandler}>
              <span className="fal fa-fw fa-ellipsis-v" />
            </div>
          ) : (
            <div styleName="item__toggle noClick">
              <span className="fal fa-fw fa-lock" />
            </div>
          )}
          <div styleName="undo-delete" onClick={this.onUnDeleteClickHandler}>
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="item__actions">
          <div className="btn btn-danger" onClick={this.onDeleteClickHandler}>
            <span className="fal fa-times" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RelationGroupItemComponentProps
  ) {
    if (
      this.props.relationGroupsStatus !== REQUEST.ERROR &&
      nextProps.relationGroupsStatus === REQUEST.ERROR
    ) {
      clearInterval(this.removeCountdown);
      this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    }
  }

  private colorChanged(color: ColorResult) {
    const newColor = ColorUtil.rgbToHex(color.hex);
    const relationGroupSnapShot: RelationGroupSnapShot = {
      ...this.props.relationGroup,
      backColor: newColor,
    };
    this.setState({ selectedColor: color.hex, displayColorPicker: false });
    this.props.relationGroupChanged(relationGroupSnapShot);
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ groupName: value || "" });
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    const relationGroupSnapShot: RelationGroupSnapShot = {
      ...this.props.relationGroup,
      name: this.state.groupName,
    };
    const t = this.state.groupName.trim();

    if (t) {
      this.props.relationGroupChanged(relationGroupSnapShot);
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      this.input.blur();
    }
  }

  private onToggleClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ showActions: !this.state.showActions });
  }

  private onUnDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    this.props.unDeleteRelationGroupItem(this.props.relationGroup);
  }

  private onDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ preppedForRemove: true, showActions: false });
    this.props.deleteRelationGroupItem(this.props.relationGroup);

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.removeFromList(this.props.relationGroup);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.showActions
    )
      this.setState({ showActions: false });
  }
}
