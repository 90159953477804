import {
  SortOrder,
  TaskCategoryOption,
  TaskOrderByField,
  TaskSnapShot,
  TaskStatus,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { MapDispatchToProps, TaskThunks } from "@haywork/middleware";
import { AppState, taskActionsV2 } from "@haywork/stores";
import { TaskOrder } from "@haywork/stores/task/list";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { TaskListComponent, TaskListComponentProps } from "./list";
import { activeFilterGuidValues } from "./selectors";
import { strict } from "assert";

type StateProps = {
  tasks: TaskSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  activeFilterGuidValues: FilterGuidValues;
  order: TaskOrder;
  scrollOffset: number;
  taskCategories: TaskCategoryOption[];
  employeeId: string;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  create: () => Promise<void>;
  open: (id: string, snapshot: TaskSnapShot) => void;
  deleteTask: (
    id: string,
    subject: string,
    undeleteCallback?: () => void,
    showToast?: boolean
  ) => void;
  setFilters: (filters: FilterConfig) => void;
  setOrdering: (sortOrder: SortOrder, sortColumn: TaskOrderByField) => void;
  setScrollOffset: (offset: number) => void;
  updateListItemStatus: (
    snapshot: TaskSnapShot,
    status: TaskStatus
  ) => Promise<void>;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  TaskListComponentProps,
  AppState
> = (state) => {
  const { tasks, totalCount, filters, order, scrollOffset } = state.taskV2.list;
  const { id: employeeId } = state.account.employee;

  return {
    tasks,
    totalCount,
    filters,
    order,
    scrollOffset,
    taskCategories: state.main.mastertable.kolibri.taskCategories,
    activeFilterGuidValues: activeFilterGuidValues(state),
    employeeId,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  TaskListComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(TaskThunks.getListItems(startIndex, stopIndex)),
  create: () => dispatch(TaskThunks.createTask()),
  open: (id: string) => dispatch(TaskThunks.getTask(id)),
  deleteTask: (
    id: string,
    subject: string,
    undeleteCallback?: () => void,
    showToast?: boolean
  ) =>
    dispatch(TaskThunks.deleteTask(id, subject, undeleteCallback, showToast)),
  setOrdering: (sortOrder: SortOrder, sortColumn: TaskOrderByField) =>
    dispatch(taskActionsV2.List.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(taskActionsV2.List.setFilters(filters)),
  setScrollOffset: (offset: number) => {
    dispatch(taskActionsV2.List.setScrollOffset(offset));
  },
  updateListItemStatus: (snapshot: TaskSnapShot, status: TaskStatus) =>
    dispatch(TaskThunks.updateListItemStatus(snapshot, status, true)),
  archive: (id: string) => dispatch(TaskThunks.archive(id)),
  unArchive: (id: string) => dispatch(TaskThunks.unArchive(id)),
});

export type TaskListContainerProps = StateProps & DispatchProps;
export const TaskListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskListComponent);
