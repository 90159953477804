import { SaveFolderRequest } from "@haywork/api/mail";
import { KEYCODE } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-folder.component.scss");

interface Props {
  accountId: string;
  parentId?: string;
  depth?: number;
  onCreate: (request: SaveFolderRequest, isNew: boolean) => Promise<void>;
}
interface State {
  formVisible: boolean;
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class CreateFolderComponent extends React.Component<Props, State> {
  private formRef: FormReference;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      accountId: { value: this.props.accountId },
      parentId: { value: this.props.parentId || "" },
      displayName: {
        value: "",
        validators: [Validators.required()],
      },
    };

    this.state = {
      formVisible: false,
      loading: false,
    };

    this.onDocumentKeyDownHandler = this.onDocumentKeyDownHandler.bind(this);
    this.onShowFormHandler = this.onShowFormHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    document.addEventListener("keydown", this.onDocumentKeyDownHandler, true);
  }

  public render() {
    const createFolderStyle = classNames(
      "create-folder",
      this.props.depth !== undefined ? `depth${this.props.depth}` : ""
    );

    return (
      <div styleName={createFolderStyle}>
        {!this.state.formVisible && (
          <div
            styleName="create-folder__trigger"
            data-cy="CY-createFolderButton"
            onClick={this.onShowFormHandler}
          >
            <i className="fa fa-plus-circle" />
            <ResourceText resourceKey="emailCreateNewFolder" />
          </div>
        )}
        {!!this.state.formVisible && (
          <Form
            name="create-sub-folder"
            formControls={this.formControls}
            onSubmit={this.onSubmitHandler}
          >
            <div styleName="create-folder__form">
              <Input.Text
                name="displayName"
                data-cy="CY-createFolderInput"
                placeholder="emailFolderDisplaynamePlaceholder"
                disabled={this.state.loading}
              />
              <div styleName="divider" />
              <button
                type="submit"
                data-cy="CY-createFolderSubmitButton"
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                <i className="fal fa-check" />
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.onDocumentKeyDownHandler,
      true
    );
  }

  private onDocumentKeyDownHandler(event: KeyboardEvent) {
    if (!this.state.formVisible) return;
    if (event.keyCode === KEYCODE.ESCAPE) {
      this.setState({
        formVisible: false,
      });
    }
  }

  private onShowFormHandler() {
    this.setState({
      formVisible: true,
    });
  }

  private async onSubmitHandler(values: FormReturnValue) {
    if (this.state.loading) return;

    this.setState({
      loading: true,
    });

    try {
      await this.props.onCreate(
        {
          accountId: values.accountId,
          displayName: values.displayName,
          parentId: values.parentId || undefined,
        },
        true
      );

      this.setState({
        formVisible: false,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }
}
