import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  APPXCHANGEROUTES,
  ASSIGNMENTROUTES,
  BASEROUTES,
  DOSSIERFOLDERROUTES,
  DYNAMICDOCUMENTROUTES,
  EMAILROUTES,
  EMAILSIGNATURESROUTES,
  EMAILTEMPLATESROUTES,
  EMPLOYEEROUTES,
  INVOICEROUTES,
  MAINROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  REALESTATEAGENCYROUTES,
  RELATIONROUTES,
  SCHEDULERCATEGORIESROUTES,
  SCHEDULERROUTES,
  SEARCHASSIGNMENTROUTES,
  TASKROUTES,
} from "@haywork/constants";
import {
  AquisitionAssignmentDetail,
  AquisitionDetail,
  AquisitionList,
} from "@haywork/modules/acquisition";
import AcquisitionListV2 from "@haywork/modules/acquisition/components/listV2";
import { AppXchangeDetailContainer } from "@haywork/modules/app-xchange";
import {
  AssignmentDetailContainer,
  AssignmentEditContainer,
  AssignmentOverviewContainer,
} from "@haywork/modules/assignment";
import AssignmentList from "@haywork/modules/assignment/components/list";
import { DashboardContainer } from "@haywork/modules/dashboard";
import {
  DynamicDocumentsDetailContainer,
  NewDocumentContainer,
} from "@haywork/modules/dynamic-documents";
import { EmailCreateContainer } from "@haywork/modules/email";
import Email from "@haywork/modules/email-v2";
import EventCenter from "@haywork/modules/event-center-v2";
import { FeatureRoute } from "@haywork/modules/feature-switch";
import HealthModule from "@haywork/modules/health";
import {
  InvoiceDetailContainer,
  InvoiceOverviewContainer,
} from "@haywork/modules/invoice";
import InvoiceList from "@haywork/modules/invoice/components/list";
import { KeyBoard } from "@haywork/modules/keyboard";
import MLSModule from "@haywork/modules/mls";
import { ObjectTypesDetailContainer } from "@haywork/modules/object-types";
import Playground from "@haywork/modules/playground";
import { ProjectDetailContainer } from "@haywork/modules/project";
import { ProjectEditContainer } from "@haywork/modules/project/containers/edit.container";
import { RealEstateAgencyDetailContainer } from "@haywork/modules/real-estate-agency";
import {
  ContactCompanyDetailContainer,
  ContactPersonDetailContainer,
  RelationNewContainer,
} from "@haywork/modules/relation";
import RelationList from "@haywork/modules/relation/components/list";
import {
  SchedulerDetailContainer,
  SchedulerOverviewContainer,
} from "@haywork/modules/scheduler";
import {
  NewSearchAssignmentContainer,
  SearchAssignmentDetailContainer,
  SearchAssignmentEditContainer,
  SearchAssignmentOverviewContainer,
} from "@haywork/modules/search-assignment";
import SearchAssignmentList from "@haywork/modules/search-assignment/components/list";
import { SettingsDetailContainer } from "@haywork/modules/settings";
import { SettingsDossierFoldersDetail } from "@haywork/modules/settings/modules/dossier-folders";
import { SettingsEmailSignatureEdit } from "@haywork/modules/settings/modules/email-signatures";
import { SettingsEmailTemplateEdit } from "@haywork/modules/settings/modules/email-templates";
import { EmployeeDetailContainer } from "@haywork/modules/settings/modules/employees";
import { OfficeDetailContainer } from "@haywork/modules/settings/modules/offices/containers/office-detail.container";
import { SettingsSchedulerEditContainer } from "@haywork/modules/settings/modules/scheduler";
import { FeatureNotReadyComponent } from "@haywork/modules/shared";
import { TaskContainer, TaskOverviewContainer } from "@haywork/modules/task";
import TaskList from "@haywork/modules/task/components/list";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const DefaultLayoutRouting = ({ realEstateAgencyId }) => {
  return (
    <Switch>
      <Route
        exact
        path={BASEROUTES.APP}
        render={() => <Redirect to={MAINROUTES.DASHBOARD.URI} />}
      />
      <Route
        exact
        path={MAINROUTES.DASHBOARD.URI}
        component={DashboardContainer}
      />
      <Route
        path={MAINROUTES.SETTINGS.URI}
        component={SettingsDetailContainer}
      />
      <Route
        path={MAINROUTES.PROFILE.URI}
        component={FeatureNotReadyComponent}
      />
      <Route
        path={MAINROUTES.EXCHANGE.URI}
        component={FeatureNotReadyComponent}
      />
      <Route
        path={MAINROUTES.HOUSENET.URI}
        component={FeatureNotReadyComponent}
      />
      <Route
        path={MAINROUTES.FEATURE.URI}
        component={FeatureNotReadyComponent}
      />

      {/* Invoices */}
      <FeatureRoute
        exact
        path={MAINROUTES.FINANCE.URI}
        component={InvoiceList}
        feature="VIRTUALIZED_LISTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={InvoiceOverviewContainer}
      />
      <Route
        exact
        path={INVOICEROUTES.DETAIL.URI}
        component={InvoiceDetailContainer}
      />

      {/** Tasks */}
      <FeatureRoute
        exact
        path={MAINROUTES.TASKS.URI}
        component={TaskList}
        feature="VIRTUALIZED_LISTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={TaskOverviewContainer}
      />
      <Route exact path={TASKROUTES.TASK.URI} component={TaskContainer} />

      {/** Scheduler */}
      <Route
        exact
        path={MAINROUTES.SCHEDULER.URI}
        component={SchedulerOverviewContainer}
      />
      <Route
        path={SCHEDULERROUTES.SCHEDULER_DETAIL.URI}
        component={SchedulerDetailContainer}
      />

      {/** Assignments */}
      <FeatureRoute
        exact
        path={MAINROUTES.ASSIGNMENTS.URI}
        component={AssignmentList}
        feature="VIRTUALIZED_LISTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={AssignmentOverviewContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.DETAIL.URI}
        component={AssignmentDetailContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT.URI}
        component={AssignmentEditContainer}
      />

      {/** Acquisitions */}
      <FeatureRoute
        exact
        path={MAINROUTES.ACQUISITIONS.URI}
        component={AcquisitionListV2}
        realEstateAgency={realEstateAgencyId}
        feature="VIRTUALIZED_LISTS"
        featureComponent={AquisitionList}
      />
      <FeatureRoute
        path={ACQUISITIONROUTES.DETAIL.URI}
        component={AquisitionDetail}
        feature="ACQUISITIONS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        path={ACQUISITIONOBJECTROUTES.DETAIL.URI}
        component={AquisitionAssignmentDetail}
        feature="ACQUISITIONS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      {/** Projects */}
      <Route
        path={PROJECTROUTES.DETAIL.URI}
        component={ProjectDetailContainer}
      />
      <Route path={PROJECTROUTES.EDIT.URI} component={ProjectEditContainer} />

      {/* Search assignments */}
      <FeatureRoute
        exact
        path={MAINROUTES.SEARCHASSIGNMENT.URI}
        feature="VIRTUALIZED_LISTS"
        component={SearchAssignmentList}
        featureComponent={SearchAssignmentOverviewContainer}
        realEstateAgency={realEstateAgencyId}
      />
      <Route
        exact
        path={SEARCHASSIGNMENTROUTES.NEW.URI}
        component={NewSearchAssignmentContainer}
      />
      <Route
        exact
        path={SEARCHASSIGNMENTROUTES.NEW_WITH_RELATION.URI}
        component={NewSearchAssignmentContainer}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.DETAIL.URI}
        component={SearchAssignmentDetailContainer}
      />
      <Route
        path={SEARCHASSIGNMENTROUTES.EDIT.URI}
        component={SearchAssignmentEditContainer}
      />

      {/** Real Estate Agency */}
      <Route
        path={REALESTATEAGENCYROUTES.DETAIL.URI}
        component={RealEstateAgencyDetailContainer}
      />

      {/** Relation */}
      <Route exact path={MAINROUTES.RELATIONS.URI} component={RelationList} />
      <Route
        exact
        path={RELATIONROUTES.NEW.URI}
        component={RelationNewContainer}
      />
      <Route
        exact
        path={RELATIONROUTES.NEW_ARO.URI}
        component={RelationNewContainer}
      />
      <Route
        exact
        path={RELATIONROUTES.NEW_PHONE_ARO.URI}
        component={RelationNewContainer}
      />
      <Route
        exact
        path={RELATIONROUTES.NEW_EMAIL_ARO.URI}
        component={RelationNewContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI}
        component={ContactCompanyDetailContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_DETAIL.URI}
        component={ContactPersonDetailContainer}
      />

      {/** Employee */}
      <Route
        path={EMPLOYEEROUTES.EMPLOYEE.URI}
        component={EmployeeDetailContainer}
      />

      {/* Offices */}
      <Route
        path={OFFICESROUTES.OFFICE_DETAIL.URI}
        component={OfficeDetailContainer}
      />

      {/* Dynamic documents */}
      <FeatureRoute
        path={DYNAMICDOCUMENTROUTES.NEW.URI}
        component={NewDocumentContainer}
        feature="DYNAMIC_DOCUMENTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        path={DYNAMICDOCUMENTROUTES.DETAIL.URI}
        component={DynamicDocumentsDetailContainer}
        feature="DYNAMIC_DOCUMENTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      {/* E-mail */}
      <FeatureRoute
        exact
        path={EMAILROUTES.CREATE.URI}
        component={EmailCreateContainer}
        feature="EMAIL"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        path={MAINROUTES.EMAIL.URI}
        component={Email}
        feature="EMAIL"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      {/* App Xchange */}
      <FeatureRoute
        path={APPXCHANGEROUTES.DETAIL.URI}
        component={AppXchangeDetailContainer}
        feature="APP_XCHANGE"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      {/* Project types */}
      <FeatureRoute
        path={OBJECTTYPESROUTES.DETAIL.URI}
        component={ObjectTypesDetailContainer}
        feature="PROJECTS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <FeatureRoute
        path={SCHEDULERCATEGORIESROUTES.DETAIL.URI}
        component={SettingsSchedulerEditContainer}
        feature="EDIT_SCHEDULER_CATEGORY"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <FeatureRoute
        path={MAINROUTES.KEYBOARD.URI}
        component={KeyBoard}
        feature="KEYBOARD"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <FeatureRoute
        path={EMAILSIGNATURESROUTES.DETAIL.URI}
        component={SettingsEmailSignatureEdit}
        feature="EMAIL_SIGNATURES"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <FeatureRoute
        path={EMAILTEMPLATESROUTES.DETAIL.URI}
        component={SettingsEmailTemplateEdit}
        feature="EMAIL_TEMPLATES"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <FeatureRoute
        path={DOSSIERFOLDERROUTES.DETAIL.URI}
        component={SettingsDossierFoldersDetail}
        feature="FOLDERS_V2"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <Route path={MAINROUTES.HEALTH.URI} component={HealthModule} />

      {/* Notifications */}
      <FeatureRoute
        path={MAINROUTES.NOTIFICATIONS.URI}
        component={EventCenter}
        feature="EVENTCENTER_V2"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      {/* Playground */}
      <Route path={MAINROUTES.PLAYGROUND.URI} component={Playground} />

      {/** MLS */}
      <FeatureRoute
        path={MAINROUTES.MLS.URI}
        component={MLSModule}
        feature="MLS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />

      <Route render={() => <Redirect to={BASEROUTES.NOT_FOUND} />} />
    </Switch>
  );
};
