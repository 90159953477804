import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import sortBy from "lodash-es/sortBy";
import get from "lodash-es/get";
import { LinkedRealEstateAgency, AssignmentType } from "@haywork/api/kolibri";
import decode from "jwt-decode";

const linkedRealEstateAgenciesSelector = (state: AppState) =>
  state.account.accountSettings.linkedRealEstateAgencies;
const enabledAssignmentTypesSelector = (state: AppState) =>
  state.company.settings.enabledAssignmentTypes || [];
const customCompanySettingsSelector = (state: AppState) =>
  state.company.customCompanySettings;
const eventHubStatusSelector = (state: AppState) => state.liveData;
const emailTotalAccountsSelector = (state: AppState) =>
  state.emailV2.accounts.accounts.length;
const tokenSelector = (state: AppState) => state.access.token || "";

export const linkedRealEstateAgencies = createSelector(
  linkedRealEstateAgenciesSelector,
  (agencies) => {
    return sortBy(agencies, (agency: LinkedRealEstateAgency) => agency.name);
  }
);

export const linkedRealEstateAgenciesWithoutCurrent = (id: string) =>
  createSelector(linkedRealEstateAgenciesSelector, (agencies) => {
    return sortBy(
      agencies.filter((agency) => agency.id !== id),
      (agency: LinkedRealEstateAgency) => agency.name
    );
  });

export const canCreateObjectAssignment = createSelector(
  enabledAssignmentTypesSelector,
  (enabledAssignmentTypes) =>
    enabledAssignmentTypes.includes(AssignmentType.Object)
);

export const canCreateProjectAssignment = createSelector(
  enabledAssignmentTypesSelector,
  (enabledAssignmentTypes) =>
    enabledAssignmentTypes.includes(AssignmentType.Project)
);

export const customLogo = createSelector(
  customCompanySettingsSelector,
  (customCompanySettings) => {
    return get(customCompanySettings, "companyLogo", null) as string | null;
  }
);

export const eventHubStatus = createSelector(
  eventHubStatusSelector,
  emailTotalAccountsSelector,
  (state, totalAccounts) => {
    const { mailStatus, voipStatus, kolibriStatus, eventCenterStatus } = state;
    const statuses = [voipStatus, kolibriStatus, eventCenterStatus];
    if (totalAccounts > 0) {
      statuses.push(mailStatus);
    }

    switch (true) {
      case statuses.includes("failed"): {
        return "failed";
      }
      case statuses.includes("disconnected"):
      case statuses.includes("closed"): {
        return "disconnected";
      }
      case statuses.includes("suspended"): {
        return "suspended";
      }
      case statuses.includes("connecting"):
      case statuses.includes("closing"): {
        return "connecting";
      }
      case statuses.includes("initialized"): {
        return "initialized";
      }
      default: {
        return "connected";
      }
    }
  }
);

export const hasOriginalPersonId = createSelector(tokenSelector, (token) => {
  if (!token) {
    return false;
  }
  const data: any = decode(token);
  return !!data.orgpid;
});
