import { Dispatch } from ".";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import {
  TimelineEventsClient,
  TimelineOrderByField,
  ActiveFilter,
  SortOrder,
  TimelineActionType,
} from "@haywork/api/kolibri";

const parseRequest = new ParseRequest();

const getTimelineEvents = (
  startIndex: number,
  stopIndex: number,
  assignmentId?: string,
  relationId?: string,
  filterByActionTypes: TimelineActionType[] = [],
  includeTimelineEventsOfChildItems: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const TimelineEvents = new TimelineEventsClient(host);

    try {
      const response = await parseRequest.response(
        TimelineEvents.search(
          {
            includeStatistics: false,
            orderBy: TimelineOrderByField.Date,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: startIndex,
            take: stopIndex - startIndex,
            assignmentId,
            relationId,
            filterByActionTypes,
            includeTimelineEventsOfChildItems,
          },
          realEstateAgencyId
        )
      );

      const { totalResults, results } = response;

      return {
        events: results || [],
        total: totalResults | 0,
      };
    } catch (error) {
      throw error;
    }
  };
};

export const TimelineEventThunk = {
  getTimelineEvents,
};
