import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { FormattedDate } from "react-intl";

import { ResourceText } from "@haywork/modules/shared";
import { Link } from "react-router-dom";
import {
  LinkedRelation,
  RelationType,
  MailHistorySnapShot,
  SearchAssignment,
} from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentMatchMailItemComponentProps {
  searchAssignment: SearchAssignment;
  matchMail: MailHistorySnapShot;
  zebra: boolean;
  onMatchingPropertiesClick: (properties: string[], dateTimeSent: Date) => void;
}
interface SearchAssignmentMatchMailItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentMatchMailItemComponent extends React.Component<
  SearchAssignmentMatchMailItemComponentProps,
  SearchAssignmentMatchMailItemComponentState
> {
  public render() {
    const { dateTimeSent, matchedPropertyCount, matchedProperties } =
      this.props.matchMail;
    const linkedRelations = this.props.searchAssignment.linkedRelations || [];
    const itemStyle = classNames("item", { zebra: this.props.zebra });

    return (
      <div styleName={itemStyle}>
        <div styleName="item__icon">
          <i className="fa fa-fw fa-envelope" />
        </div>
        <div styleName="item__meta">
          {linkedRelations.length > 0 && (
            <div>
              <h2>
                <ResourceText resourceKey="to" />:
              </h2>
              {this.renderLinkedRelations()}
            </div>
          )}
          {linkedRelations.length === 0 && (
            <ResourceText resourceKey="noMatchMailLinkedRelations" />
          )}
        </div>
        <div styleName="item__matches">
          <span
            className="as-link"
            onClick={() =>
              this.props.onMatchingPropertiesClick(
                matchedProperties,
                dateTimeSent
              )
            }
          >
            <ResourceText
              resourceKey="matchMailPropertyMatches"
              values={{ count: matchedPropertyCount }}
            />
          </span>
        </div>
        <div styleName="item__sent">
          <FormattedDate
            value={dateTimeSent}
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        </div>
      </div>
    );
  }

  private renderLinkedRelations(): React.ReactElement<HTMLDivElement> {
    const linkedRelations = this.props.searchAssignment.linkedRelations || [];

    return (
      <div>
        {linkedRelations.map((linkedRelation, idx) => (
          <span key={idx}>
            {idx !== 0 && <span>, </span>}
            <Link to={this.getRelationUrl(linkedRelation)}>
              {linkedRelation.displayName}
            </Link>
          </span>
        ))}
      </div>
    );
  }

  private getRelationUrl(relation: LinkedRelation): string {
    const { id, typeOfRelation } = relation;

    switch (typeOfRelation) {
      case RelationType.ContactCompany:
        return route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
      case RelationType.Employee:
        return route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
      case RelationType.Office:
        return route(OFFICESROUTES.BASE.URI, { id });
      default:
        return route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
    }
  }
}
