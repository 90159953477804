import { connect, MapStateToProps } from "react-redux";
import {
  VoipInfoModalComponent,
  VoipInfoModalComponentProps
} from "./voip-info-modal";
import { AppState } from "@haywork/stores";
import { VoipThunk, MapDispatchToProps } from "@haywork/middleware";
import { CommunicationLog } from "@haywork/api/kolibri";
import { push, RouterAction } from "connected-react-router";

interface StateProps {}
interface DispatchProps {
  getCommunicationLog: (
    communicationLogId: string
  ) => Promise<CommunicationLog>;
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  VoipInfoModalComponentProps,
  AppState
> = (state) => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  VoipInfoModalComponentProps
> = (dispatch) => ({
  getCommunicationLog: (communicationLogId: string) =>
    dispatch(VoipThunk.Calls.getCommunicationLog(communicationLogId)),
  navigate: (path: string) => dispatch(push(path))
});

export type VoipInfoModalContainerProps = StateProps & DispatchProps;
export const VoipInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoipInfoModalComponent);
