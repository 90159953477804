export const PROJECT = {
  SET_PROJECTS: "SET_PROJECTS",
  APPEND_PROJECTS: "APPEND_PROJECTS",
  SET_PROJECTS_STATUS: "SET_PROJECTS_STATUS",
  SET_PROJECTS_FILTERS: "SET_PROJECTS_FILTERS",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_PROJECTS_FILTERS",
  CLEAR_FILTER: "CLEAR_PROJECTS_FILTER",
  SET_PROJECT: "SET_PROJECT",
  UPDATE_SINGLE_PROJECT: "UPDATE_SINGLE_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  SET_PROJECT_STATUS: "SET_PROJECT_STATUS",
  SET_PROJECT_EDIT_STATUS: "SET_PROJECT_EDIT_STATUS",
  SET_PUBLISH_STATUS: "SET_PUBLISH_STATUS",
  SET_CHANGE_AVAILABILITY_STATUS: "SET_CHANGE_AVAILABILITY_STATUS",
  TOGGLE_STATUS_MODAL: "PROJECT_TOGGLE_STATUS_MODAL",
  SET_STATUS_STATE: "SET_STATUS_STATE",
  SET_SAVE_PROJECTS_STATUS: "SET_SAVE_PROJECTS_STATUS",
  TOGGLE_WITHDRAW_MODAL: "PROJECT_TOGGLE_WITHDRAW_MODAL",
  SET_WITHDRAW_STATE: "SET_WITHDRAW_STATE",
  TOGGLE_SAVE_MODAL: "TOGGLE_PROJECT_SAVE_MODAL",
  SET_TIMELINE_EVENTS: "SET_TIMELINE_EVENTS",
  APPEND_TIMELINE_EVENTS: "APPEND_TIMELINE_EVENTS",
  SET_TIMELINE_STATUS: "SET_PROJECT_TIMELINE_STATUS",
  SET_MEDIAPARTNER_STATE: "SET_MEDIAPARTNER_STATE",
  SET_CADASTRE_STATE: "SET_CADASTRE_STATE",
  SET_WIDGET_LAST_PROJECTS: "SET_WIDGET_LAST_PROJECTS",
  SET_WIDGET_LAST_PROJECTS_STATUS: "SET_WIDGET_LAST_PROJECTS_STATUS",
  SET_BUILDNUMBERS: "PROJECT_SET_BUILDNUMBERS",
  ADD_BUILDNUMBERS: "PROJECT_ADD_BUILDNUMBERS",
  SET_BUILDNUMBERS_STATUS: "PROJECT_SET_BUILDNUMBERS_STATUS",
  SET_OBJECTTYPES: "PROJECT_SET_OBJECTTYPES",
  ADD_OBJECTTYPES: "PROJECT_ADD_OBJECTTYPES",
  SET_OBJECTTYPES_STATUS: "PROJECT_SET_OBJECTTYPES_STATUS"
};
