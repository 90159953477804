import { MIME } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import {
  FileUploadContainer,
  FileUploadIcon,
  ResourceText,
} from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";

const styles = require("./photos.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface PhotosComponentProps {
  photos: any[];
  path: string;
  showMainPhotoLabel: boolean;

  onUploadCompleteHandler: (photos: any[]) => void;
  fileDeleted: (idx: number) => void;
  sorted: (sort: SortEnd) => void;
  fileEdited: (photos: any) => void;
}

interface PhotosComponentState {
  showFileEditModal: boolean;
  fileToEdit: any;
  currentIdx: number;
}

const SortableTrigger = SortableHandle(({ thumbStyles, fileName }) => (
  <div className="preview" style={thumbStyles}>
    <div className="file-name">{fileName}</div>
  </div>
));

const SortableItem = SortableElement(
  ({
    thumbStyles,
    downloadHandler,
    editHandler,
    deleteHandler,
    idx,
    showMainPhotoLabel,
    fileName,
  }) => {
    const props = { thumbStyles, fileName };

    return (
      <div className="file-list__item" data-cy="CY-ImagePreview">
        <div className="meta">
          {showMainPhotoLabel && idx === 0 && (
            <div className="meta__item">
              <ResourceText resourceKey="isMainPhoto" />
            </div>
          )}
        </div>
        <div
          className="remove"
          onClick={() => deleteHandler(idx)}
          data-cy={props["data-cy"] && `${props["data-cy"]}.RemoveButton`}
        >
          <i className="fal fa-fw fa-times" />
        </div>
        <SortableTrigger {...props} />
        <div className="actions">
          <div
            className="action btn btn-blank"
            onClick={() => downloadHandler(idx)}
            data-cy={props["data-cy"] && `${props["data-cy"]}.DownloadButton`}
          >
            <i className="fal fa-download" />
          </div>
          <div
            className="action btn btn-blank"
            onClick={() => editHandler(idx)}
            data-cy={props["data-cy"] && `${props["data-cy"]}.EditButton`}
          >
            <i className="fal fa-pencil" />
          </div>
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    photos,
    downloadHandler,
    editHandler,
    deleteHandler,
    uploadCompleteHandler,
    showMainPhotoLabel,
  }) => (
    <div className="file-list">
      <div className="container-fluid">
        <div className="upload">
          <FileUploadContainer
            multiple
            icon={FileUploadIcon.Upload}
            title="fileUploadImagesTitle"
            subtitle="fileUploadImagesSubtitle"
            helperText="fileUploadHelperOnlyImages"
            onCompleted={uploadCompleteHandler}
            whitelist={MIME.IMAGE}
          />
        </div>
        <div className="files">
          {photos.map((photo, idx) => {
            if (!photo) return null;
            const image = photo.urlPreview || photo.thumbUrl;
            const thumbStyles = {
              backgroundImage: `url(${JSON.stringify(image)})`,
            };
            const fileName = [photo.fileName || photo.name, photo.fileExtension]
              .filter((d) => !!d)
              .join("");

            const props = {
              thumbStyles,
              downloadHandler,
              editHandler,
              deleteHandler,
              idx,
              showMainPhotoLabel,
              fileName,
            };

            return (
              <ErrorBoundary key={`item-${idx}`}>
                <SortableItem index={idx} {...props} />
              </ErrorBoundary>
            );
          })}
        </div>
      </div>
    </div>
  )
);

@CSSModules(styles, { allowMultiple: true })
export class PhotosComponent extends React.Component<
  PhotosComponentProps,
  PhotosComponentState
> {
  private formRef: FormReference;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      showFileEditModal: false,
      fileToEdit: null,
      currentIdx: 0,
    };

    this.onFileDownloadClickHandler = this.onFileDownloadClickHandler.bind(
      this
    );
    this.onFileEditClickHandler = this.onFileEditClickHandler.bind(this);
    this.onFileDeleteClickHandler = this.onFileDeleteClickHandler.bind(this);
    this.onSortEndHandler = this.onSortEndHandler.bind(this);
    this.onEditModalCloseHandler = this.onEditModalCloseHandler.bind(this);
    this.onFileEditSubmitHandler = this.onFileEditSubmitHandler.bind(this);
    this.onUploadCompleteHandler = this.onUploadCompleteHandler.bind(this);
  }
  public render() {
    const photos = this.props.photos ? [...this.props.photos] : [];

    const props = {
      photos,
      downloadHandler: this.onFileDownloadClickHandler,
      editHandler: this.onFileEditClickHandler,
      deleteHandler: this.onFileDeleteClickHandler,
      uploadCompleteHandler: this.onUploadCompleteHandler,
      showMainPhotoLabel: this.props.showMainPhotoLabel,
    };

    const formControls: FormControls = {
      name: { value: value(this.state, "fileToEdit.name", "") },
      description: { value: value(this.state, "fileToEdit.description", "") },
    };

    return (
      <div styleName="photos">
        <SortableList
          axis="xy"
          useDragHandle
          onSortEnd={this.onSortEndHandler}
          {...props}
        />
        <Modal
          visible={this.state.showFileEditModal}
          onClose={this.onEditModalCloseHandler}
        >
          <ModalHeader title="editPhoto" close />
          <Form
            name="update-photo-meta"
            formControls={formControls}
            onSubmit={this.onFileEditSubmitHandler}
            form={(ref) => (this.formRef = ref)}
          >
            <ModalBody>
              <div styleName="previewPhoto">
                {this.state.fileToEdit && (
                  <img
                    src={
                      this.state.fileToEdit.urlMedium ||
                      this.state.fileToEdit.thumbUrl
                    }
                  />
                )}
              </div>
              <div className="form__row">
                <Input.Text name="name" placeholder="imageFilename" />
              </div>
              <div className="form__row">
                <Input.Textarea
                  name="description"
                  placeholder="fileDescription"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-success">
                <ResourceText resourceKey="save" />
              </button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }

  private onUploadCompleteHandler(files: any[]) {
    this.props.onUploadCompleteHandler(files);
  }

  private onFileDownloadClickHandler(idx: number) {
    const item: any = this.props.photos[idx];
    window.open(item.urlOriginal || item.url, "_blank");
  }

  private onFileEditClickHandler(currentIdx: number) {
    const fileToEdit = this.props.photos[currentIdx];
    this.setState({ showFileEditModal: true, fileToEdit, currentIdx });
    if (this.formRef)
      this.formRef.update({
        name: fileToEdit.name,
        description: fileToEdit.description,
      });
  }

  private onFileDeleteClickHandler(idx: number) {
    this.props.fileDeleted(idx);
  }

  private onSortEndHandler(sort: SortEnd) {
    this.props.sorted(sort);
  }

  private onEditModalCloseHandler() {
    this.setState({ showFileEditModal: false });
  }

  private onFileEditSubmitHandler(values: FormReturnValue) {
    const photos = this.props.photos.map((photo, idx) => {
      if (idx === this.state.currentIdx) {
        return {
          ...photo,
          name: values.name,
          description: values.description,
        };
      }
      return photo;
    });

    this.props.fileEdited(photos);
    this.setState({ showFileEditModal: false });
  }
}
