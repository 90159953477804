import { RootEntityType } from "@haywork/api/event-center";
import { ContactPerson, PhotoBlob, RelationType } from "@haywork/api/kolibri";
import { RELATIONROUTES, REQUEST } from "@haywork/constants";
import { Dispatch, RelationThunks } from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { ContactPersonDetailComponent } from "@haywork/modules/relation/";
import { AppState, RelationActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  selectedRelationId: string;
  selectedRelationType: RelationType;
  selectedRelationDisplayName: string;
  relationContactInfoState: string;
  selectedRelationAvatar: PhotoBlob;
  selectedRelationIsNew: boolean;
  selectedContactPerson: ContactPerson;
  isLoading: boolean;
  contactPerson: ContactPerson;
  path: string;
}

interface DispatchProps {
  getContactInfo: (relationId: string, relationType: RelationType) => void;
  onRelationOptionClickHandler: (routePath: string) => void;
  updateContactPersonPassportPhoto: (
    newAvatar: PhotoBlob,
    contactPersonId: string
  ) => void;
  saveAndCloseContactPerson: (
    contactPerson: ContactPerson,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, ContactPersonDetailComponentProps>(
  state: AppState
) => ({
  selectedRelationId: state.relation.detail.selectedRelationId,
  selectedRelationType: state.relation.detail.selectedRelationType,
  selectedRelationDisplayName:
    state.relation.detail.selectedRelationDisplayName,
  relationContactInfoState: state.relation.detail.relationContactInfoState,
  selectedRelationAvatar: state.relation.detail.selectedRelationAvatar,
  selectedRelationIsNew: state.relation.detail.selectedRelationIsNew,
  selectedContactPerson: state.relation.detail.selectedContactPerson,
  isLoading: state.relation.detail.relationContactInfoState !== REQUEST.SUCCESS,
  contactPerson: state.editable.currentComponentState,
  path: RouteUtil.mapStaticRouteValues(
    RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
    { id: state.relation.detail.selectedContactPerson.id }
  )
});

const mapDispatchToProps = <DispatchProps, ContactPersonDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  onRelationOptionClickHandler: (routePath: string) =>
    dispatch(push(routePath)),
  getContactInfo: (relationId: string, relationType: RelationType) =>
    dispatch(RelationThunks.getContactInfo(relationId, relationType)),
  updateContactPersonPassportPhoto: (
    newAvatar: PhotoBlob,
    contactPersonId: string
  ) =>
    dispatch(
      RelationThunks.updateContactPersonPassportPhoto(
        newAvatar,
        contactPersonId
      )
    ),
  saveAndCloseContactPerson: (contactPerson: ContactPerson, path: string) =>
    dispatch(RelationThunks.saveContactPerson(contactPerson, path, true))
});

export type ContactPersonDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  EditableHocProps;
export const ContactPersonDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(ContactPersonDetailComponent)
  ),
  {
    entityType: RootEntityType.ContactPerson,
    icon: "address-book",
    thunk: RelationThunks.getContactPersonInfo,
    status: "relation.detail.relationContactInfoState",
    statePath: "relation.detail.selectedContactPerson",
    action: RelationActions.searchContactPerson2,
    route: RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
    confirm: {
      title: { key: "savePersonConfirmTitle" },
      body: { key: "savePersonConfirmBody" }
    }
  }
);
