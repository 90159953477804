import {
  SortOrder,
  TaskOrderByField,
  TaskSnapShot,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import List, {
  ActiveFilters,
  FilterConfig,
  ListColumnConfig,
  ListRefProps,
  ListWrapper,
} from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import Filters from "./components/filters";
import Row from "./components/row";
import { TaskListContainerProps } from "./list.container";
import { TaskListAction } from "./components/row/row-actions/row-actions";

const styles = require("./style.scss");

export type TaskListComponentProps = {};
type Props = TaskListComponentProps & TaskListContainerProps;

export const TaskListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      navigate,
      totalCount,
      getListItems,
      filters,
      activeFilterGuidValues,
      setFilters,
      setOrdering,
      order,
      scrollOffset,
      setScrollOffset,
      tasks,
      taskCategories,
      updateListItemStatus,
      create,
      deleteTask,
      archive,
      unArchive,
      employeeId,
    }) => {
      const listRef = useRef<ListRefProps>();

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: "",
              width: 40,
            },
            {
              label: {
                value: "subject",
              },
              width: 500,
              autoFill: true,
            },
            {
              label: {
                value: "endDate",
              },
              width: 200,
              sortConfig: [TaskOrderByField.DefaultOrder],
            },
            {
              label: "",
              width: 36,
            },
          ],
          withActions: true,
        } as ListColumnConfig;
      }, []);

      const onNavigatePath = useCallback((path: string) => {
        navigate(path);
      }, []);

      const onSortChange = useCallback(
        (sortColumn: TaskOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const onAction = useCallback(
        (item: TaskSnapShot, action: TaskListAction) => {
          const { id, subject } = item;
          switch (action) {
            case TaskListAction.Delete: {
              deleteTask(id, subject, null, true);
              break;
            }
            case TaskListAction.Archive: {
              archive(id);
              break;
            }
            case TaskListAction.UnArchive: {
              unArchive(id);
              break;
            }
            default: {
              break;
            }
          }
        },
        [navigate]
      );

      useEffect(() => {
        getListItems(0, 100);
      }, []);

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="tasks"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
          >
            <List
              name="tasks"
              rowHeight={100}
              columnConfig={columnConfig}
              data={tasks}
              totalCount={totalCount}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              onAction={onAction}
              ref={listRef}
              emptyState={
                <EmptyState
                  icon="folder-open"
                  title="tasks.list.emptyStateTitle"
                  subTitle="tasks.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="tasks.list.emptyState.action"
                      category="primary"
                      onClick={() => create()}
                    />
                  }
                />
              }
            >
              {(data: TaskSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigatePath={onNavigatePath}
                  taskCategories={taskCategories}
                  onToggleTaskStatus={updateListItemStatus}
                  employeeId={employeeId}
                />
              )}
            </List>
          </ListWrapper>
        </div>
      );
    }
  )
);
