import * as React from "react";
import { FC, memo, useEffect, useCallback, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { KeysModalContainerProps } from "./keys-modal.container";
import { ExtendedKey } from "@haywork/middleware";
import { Ui } from "@haywork/modules/ui";
import Key from "../key";
import * as CSSModules from "react-css-modules";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");
const KEYS_PER_PAGE = 25;

export type KeysModalComponentProps = {
  visible: boolean;
  selectedKeyNr: number | null | undefined;
  onClose: () => void;
  onKeySelect: (selectedKey: ExtendedKey) => void;
};
type Props = KeysModalComponentProps & KeysModalContainerProps;

export const KeysModalComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, onClose, getKeys, selectedKeyNr, onKeySelect }) => {
      const [loading, setLoading] = useState(false);
      const [pageCount, setPageCount] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [keys, setKeys] = useState<ExtendedKey[]>([]);
      const [visibleKeys, setVisibleKeys] = useState<ExtendedKey[]>([]);

      const updateVisibleKeys = useCallback(
        (currentPage: number) => {
          const start = currentPage * KEYS_PER_PAGE;
          const end = start + KEYS_PER_PAGE;

          const visibleKeys = keys.slice(start, end);
          setVisibleKeys(visibleKeys);
        },
        [keys, setVisibleKeys]
      );

      const gotoFirstPage = useCallback(() => {
        if (currentPage === 0) return;
        setCurrentPage(0);
        updateVisibleKeys(0);
      }, [currentPage, setCurrentPage, updateVisibleKeys]);

      const gotoPreviousPage = useCallback(() => {
        if (currentPage === 0) return;
        const page = currentPage - 1;
        setCurrentPage(page);
        updateVisibleKeys(page);
      }, [currentPage, setCurrentPage, updateVisibleKeys]);

      const gotoNextPage = useCallback(() => {
        if (currentPage === pageCount) return;
        const page = currentPage + 1;
        setCurrentPage(page);
        updateVisibleKeys(page);
      }, [currentPage, pageCount, setCurrentPage, updateVisibleKeys]);

      const gotoLastPage = useCallback(() => {
        if (currentPage === pageCount) return;
        setCurrentPage(pageCount);
        updateVisibleKeys(pageCount);
      }, [currentPage, pageCount, setCurrentPage, updateVisibleKeys]);

      const fetchKeys = useCallback(async () => {
        if (!visible) return;

        try {
          setLoading(true);
          const keys = await getKeys();
          const currentPage = !selectedKeyNr
            ? 0
            : Math.floor((selectedKeyNr - 1) / KEYS_PER_PAGE);
          const pageCount = Math.floor(keys.length / KEYS_PER_PAGE);
          const start = currentPage * KEYS_PER_PAGE;
          const end = start + KEYS_PER_PAGE;

          const visibleKeys = keys.slice(start, end);
          setVisibleKeys(visibleKeys);
          setCurrentPage(currentPage);
          setPageCount(pageCount);
          setKeys(keys);
        } finally {
          setLoading(false);
        }
      }, [
        visible,
        setKeys,
        setVisibleKeys,
        selectedKeyNr,
        setCurrentPage,
        setLoading,
        setPageCount
      ]);

      useEffect(() => {
        fetchKeys();
      }, [fetchKeys]);

      return (
        <Modal visible={visible} onClose={onClose}>
          <ModalHeader title="keysModal.title" close />
          <ModalBody noPadding>
            <div styleName="body">
              {loading && !visibleKeys.length && (
                <div styleName="loader">
                  <Ui.Loaders.List />
                </div>
              )}
              {visibleKeys.map((key) => (
                <Key
                  linkedKey={key}
                  key={key.id}
                  activeKey={selectedKeyNr}
                  onClick={onKeySelect}
                />
              ))}
            </div>
            <div styleName="navigation">
              <button
                styleName="action"
                disabled={currentPage === 0}
                onClick={gotoFirstPage}
              >
                <Icon
                  name="chevron-double-left"
                  color={Colors.DarkGray}
                  size={16}
                  regular
                />
              </button>
              <button
                styleName="action"
                disabled={currentPage === 0}
                onClick={gotoPreviousPage}
              >
                <Icon
                  name="chevron-left"
                  color={Colors.DarkGray}
                  size={16}
                  regular
                />
              </button>
              <div styleName="counter">
                {currentPage + 1}/{pageCount + 1}
              </div>
              <button
                styleName="action"
                disabled={currentPage === pageCount}
                onClick={gotoNextPage}
              >
                <Icon
                  name="chevron-right"
                  color={Colors.DarkGray}
                  size={16}
                  regular
                />
              </button>
              <button
                styleName="action"
                disabled={currentPage === pageCount}
                onClick={gotoLastPage}
              >
                <Icon
                  name="chevron-double-right"
                  color={Colors.DarkGray}
                  size={16}
                  regular
                />
              </button>
            </div>
          </ModalBody>
        </Modal>
      );
    }
  )
);
