import { LogMessage } from "./reducer";

export enum ActionType {
  AddLogging = "Logging.AddLogging",
}

type AddLogging = {
  type: ActionType.AddLogging;
  message: LogMessage;
};
const addLogging = (message: LogMessage) => ({
  type: ActionType.AddLogging,
  message,
});

export type ActionTypes = AddLogging;
export const actions = {
  addLogging,
};
