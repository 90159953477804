import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum ProjectAction {
  Fullscreen = "Fullscreen",
  SaveAndClose = "Save",
  BackToDashboard = "BackToDashboard",
  Publish = "Publish"
}

type Props = {
  showPublish: boolean;
  loading: boolean;
  isFullScreen: boolean;
  isNew: boolean;
  onClick: (action: ProjectAction) => void;
};

export const ProjectEditActionsComponent: FC<Props> = memo(
  ({ showPublish, loading, isFullScreen, isNew, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const onClickHandler = (action: ProjectAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />

          <Action
            label="saveAndClose"
            icon={
              <Icon
                light
                name="save"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ProjectAction.SaveAndClose)}
          />

          {!isNew && (
            <Action
              label="assignment.edit.actions.label.backToDashboard"
              icon={
                <Icon
                  light
                  name="tachometer-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ProjectAction.BackToDashboard)}
            />
          )}

          <Action
            label={isFullScreen ? "normalScreen" : "fullScreen"}
            icon={
              <Icon
                light
                name={isFullScreen ? "compress-alt" : "expand-alt"}
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(ProjectAction.Fullscreen)}
          />

          {showPublish && (
            <Action
              label="publish"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(ProjectAction.Publish)}
            />
          )}
        </ActionList>
      </>
    );
  }
);

export default ProjectEditActionsComponent;
