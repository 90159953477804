import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./relation.types";
import {
  TimelineEvent, TimelineEventsStatistics
} from "@haywork/api/kolibri";

export interface RelationDetailTimelineState {
  relationTimelineStatus: string;
  timelineEvents: TimelineEvent[];
  timeLineStatistics: TimelineEventsStatistics;
  timelinePage: number;
  timelinePageCount: number;
}

const INITIAL_STATE: RelationDetailTimelineState = {
  relationTimelineStatus: REQUEST.IDLE,
  timelineEvents: null,
  timeLineStatistics: null,
  timelinePage: 0,
  timelinePageCount: 0
};

export const relationDetailTimelineReducer = (state: RelationDetailTimelineState = INITIAL_STATE, action: Action): RelationDetailTimelineState => {
  switch (action.type) {
    case REDUX.RELATION.SET_FETCH_RELATION_TIMELINE_STATE: {
      const { relationTimelineStatus } = <ActionType.RelationTimelineStatus> action;
      return { ...state, relationTimelineStatus };
    }
    case REDUX.RELATION.SEARCH_TIMELINE_EVENTS: {
      const {results, totalResults, resultCount, statistics, take} = <ActionType.TimelineEventsResponse> action;
      const timelinePageCount = Math.ceil(totalResults / take);

      return {
        ...state,
        timelineEvents: results,
        timeLineStatistics: statistics,
        timelinePage: 1,
        timelinePageCount
      };
    }
    case REDUX.RELATION.APPEND_TIMELINE_EVENTS: {
      const {results, totalResults, resultCount, statistics, take} = <ActionType.TimelineEventsResponse> action;
      const timelinePageCount = Math.ceil(totalResults / take);
      const timelinePage = state.timelinePage + 1;

      return {
        ...state,
        timelineEvents: [...state.timelineEvents, ...results],
        timeLineStatistics: statistics,
        timelinePageCount,
        timelinePage
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
