import {
  Language,
  SocialMediaTranslation,
  SocialMediaType,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { addHttp } from "@haywork/util/url";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { InputComponentProps } from "../input.component";

interface Props {
  platforms: SocialMediaType[];
}
interface State {}

@CSSModules({}, { allowMultiple: true })
export class SocialComponent extends React.Component<
  Props & InputComponentProps,
  State
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderSocialInput = this.renderSocialInput.bind(this);
  }

  public render() {
    return (
      <div className="input__social">
        {this.props.platforms.map(this.renderSocialInput)}
      </div>
    );
  }

  private renderSocialInput(
    type: SocialMediaType,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const ref = uuid();
    const platform = type.toString().toLowerCase();
    const inputStyle = classNames("social-input", platform);
    const iconStyle = classNames("fab", "fa-fw", `fa-${platform}`);

    const item = (this.props.value || []).find((v) => v.type === type);
    const accountValue = !!item ? item.account : "";
    const placeholder = `pasteA${type.toString()}Link`;

    return (
      <div key={idx} className={inputStyle}>
        <label htmlFor={ref}>
          <i className={iconStyle} />
        </label>
        <input
          type="text"
          name={ref}
          id={ref}
          value={accountValue}
          placeholder={intlContext.formatMessage({
            id: placeholder,
            defaultMessage: placeholder,
          })}
          onChange={(event) => this.onChangeHandler(event, type)}
          onBlur={(event) => this.onBlurHandler(event, type)}
          onKeyDown={this.onKeyDownHandler}
          data-cy={this.props["data-cy"]}
          data-lpignore="true"
        />
      </div>
    );
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onChangeHandler(
    event: React.ChangeEvent<HTMLInputElement>,
    type: SocialMediaType
  ) {
    const platforms: SocialMediaTranslation[] = this.props.platforms.map(
      (platform) => {
        const item = this.props.value.find((v) => v.type === platform);

        return (
          item || {
            account: "",
            language: Language.Dutch,
            type: platform,
          }
        );
      }
    );
    const { value } = event.target;
    const values = platforms.map((platform) => {
      if (platform.type === type) {
        return { ...platform, account: value };
      }
      return platform;
    });

    this.props.onChange(values);
  }

  private onBlurHandler(
    event: React.FocusEvent<HTMLInputElement>,
    type: SocialMediaType
  ) {
    const platforms: SocialMediaTranslation[] = this.props.platforms.map(
      (platform) => {
        const item = this.props.value.find((v) => v.type === platform);

        return (
          item || {
            account: "",
            language: Language.Dutch,
            type: platform,
          }
        );
      }
    );
    let { value } = event.currentTarget;
    const values = platforms.map((platform) => {
      if (platform.type === type) {
        value = addHttp(value);
        return { ...platform, account: value };
      }
      return platform;
    });

    this.props.onChange(values);
  }
}
