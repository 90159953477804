import {
  AcquisitionAssignment,
  AssignmentSnapShot,
  CommunicationLog,
  ObjectAssignment,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import {
  AcquisitionThunk,
  AssignmentThunks,
  CommunicationLogThunk,
  MapDispatchToProps,
  RelationThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { SaveModalComponent, SaveModalComponentProps } from "./save-modal";

type StateProps = {
  autoRemoveMessageAfterPersist: boolean;
};
type DispatchProps = {
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    subject: string,
    autoRemoveMessageAfterPersist: boolean,
    communicationLog?: CommunicationLog
  ) => Promise<CommunicationLog>;
  navigate: (path: string) => RouterAction;
  getRelationsWithMatchingEmailAddress: (
    emailAddresses: string[]
  ) => Promise<RelationSnapShot[]>;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  defineNewCommunicationLog: () => Promise<CommunicationLog>;
  getLinkedAssignment: (id: string) => Promise<ObjectAssignment>;
  getLinkedAcquisition: (id: string) => Promise<AcquisitionAssignment>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SaveModalComponentProps,
  AppState
> = (state) => {
  const autoRemoveMessageAfterPersist =
    state.emailV2.main.autoRemoveAfterMessagePersists;

  return { autoRemoveMessageAfterPersist };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SaveModalComponentProps
> = (dispatch) => ({
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    subject: string,
    autoRemoveMessageAfterPersist: boolean,
    communicationLog?: CommunicationLog
  ) =>
    dispatch(
      EmailThunk.CommunicationLogs.saveCommunicationLog(
        linkedRelations,
        linkedAssignments,
        message,
        autoRemoveMessageAfterPersist,
        communicationLog,
        subject,
        true
      )
    ),
  navigate: (path: string) => dispatch(push(path)),
  getRelationsWithMatchingEmailAddress: (emailAddresses: string[]) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(emailAddresses, [
        RelationType.ContactCompany,
        RelationType.ContactPerson,
      ])
    ),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  defineNewCommunicationLog: () =>
    dispatch(CommunicationLogThunk.defineNewCommunicationLog()),
  getLinkedAssignment: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
  getLinkedAcquisition: (id: string) =>
    dispatch(AcquisitionThunk.getLinkedAcquisition(id)),
});

export type SaveModalContainerProps = StateProps & DispatchProps;
export const SaveModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveModalComponent);
