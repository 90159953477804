import { CompanyListing, RealEstateGroup } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentEditContentContainerProps } from "@haywork/modules/assignment";
import { EditContentAgriculturalComponent } from "@haywork/modules/assignment/components/edit-content/agricultural.component";
import { FormReturnValue } from "@haywork/modules/form";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EditContentCommercialComponent } from "./commercial.component";
import { EditContentResidentialComponent } from "./residential.component";

const styles = require("./edit-content.component.scss");

export type AssignmentEditContentComponentProps = {};
interface State {}
type Props = AssignmentEditContentComponentProps &
  AssignmentEditContentContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditContentComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onCompanyListingsChangeHandler = this.onCompanyListingsChangeHandler.bind(
      this
    );
  }

  public render() {
    const {
      objectAssignment,
      constructionPeriods,
      energyClassOptions,
      isolationTypeOptions,
      heatingMethodOptions,
      certificationOptions,
      realEstateAgencyId,
      comfortQualityOptions,
      typeOfFloorOptions,
      roofTypeOptions,
      roofMaterialOptions,
      typeOfWindowFrameOptions,
      conditionOptions,
      host,
      residentialFacilityOptions,
      welfareStateOptions,
      airTreatmentOptions,
      industrialBuildingFacilityOptions,
      facilityOptions,
      constructionOptionOptions,
      leisureFacilityTypeOptions,
      residentialBuildingTypeOptions,
      socialPropertyFacilityTypeOptions,
      parkingFacilityOptions,
      typesOfCommunalArea,
      pavementTypeOptions,
      furnishingOptions,
      groundUsageOptions,
      soilTypeOptions,
      drainageOptions,
      horseTroughLocationOptions,
      houseTypes,
      situatedTypeOptions,
      orientations,
      gardenQualityOptions,
      garageTypeOptions,
      amenitiesOptions,
      gardenTypeOptions,
      companyListings,
      energySourceOptions,
      ownershipOptions,
      heatingWaterMethodOptions,
      typeOfBusinessLawOptions,
      accessibilityOptions,
      bogCombinationObjectSituationOptions,
      bogCombinationObjectStatusOptions,
      bogCombinationObjectTypeOptions,
      poultryFarmingSubtypes,
      poultryHousingTypeOptions,
      occupancyOptions,
      distinguishablesOptions,
      pollutionOptions,
    } = this.props;

    return (
      <div styleName="content" id="scroll-to-top">
        <div className="container-fluid">
          {objectAssignment.realEstateGroup === RealEstateGroup.Residential && (
            <EditContentResidentialComponent
              objectAssignment={objectAssignment}
              constructionPeriods={constructionPeriods}
              energyClassOptions={energyClassOptions}
              isolationTypeOptions={isolationTypeOptions}
              heatingMethodOptions={heatingMethodOptions}
              realEstateAgencyId={realEstateAgencyId}
              certificationOptions={certificationOptions}
              comfortQualityOptions={comfortQualityOptions}
              typeOfFloorOptions={typeOfFloorOptions}
              roofTypeOptions={roofTypeOptions}
              roofMaterialOptions={roofMaterialOptions}
              typeOfWindowFrameOptions={typeOfWindowFrameOptions}
              conditionOptions={conditionOptions}
              residentialFacilityOptions={residentialFacilityOptions}
              furnishingOptions={furnishingOptions}
              energySourceOptions={energySourceOptions}
              ownershipOptions={ownershipOptions}
              host={host}
              heatingWaterMethodOptions={heatingWaterMethodOptions}
              parkingFacilityOptions={parkingFacilityOptions}
              vveOptions={this.props.vveOptions}
              typeOfBusinessLawOptions={typeOfBusinessLawOptions}
              onChange={this.onChangeHandler}
              navigate={this.props.navigate}
              accessibilityOptions={accessibilityOptions}
              bogCombinationObjectSituationOptions={
                bogCombinationObjectSituationOptions
              }
              bogCombinationObjectStatusOptions={
                bogCombinationObjectStatusOptions
              }
              bogCombinationObjectTypeOptions={bogCombinationObjectTypeOptions}
              occupancyOptions={occupancyOptions}
              distinguishablesOptions={distinguishablesOptions}
              pollutionOptions={pollutionOptions}
            />
          )}

          {objectAssignment.realEstateGroup === RealEstateGroup.Commercial && (
            <EditContentCommercialComponent
              objectAssignment={objectAssignment}
              welfareStateOptions={welfareStateOptions}
              typesOfCommunalArea={typesOfCommunalArea}
              airTreatmentOptions={airTreatmentOptions}
              industrialBuildingFacilityOptions={
                industrialBuildingFacilityOptions
              }
              facilityOptions={facilityOptions}
              constructionOptionOptions={constructionOptionOptions}
              leisureFacilityTypeOptions={leisureFacilityTypeOptions}
              pavementTypeOptions={pavementTypeOptions}
              residentialBuildingTypeOptions={residentialBuildingTypeOptions}
              socialPropertyFacilityTypeOptions={
                socialPropertyFacilityTypeOptions
              }
              constructionPeriods={constructionPeriods}
              energyClassOptions={energyClassOptions}
              conditionOptions={conditionOptions}
              certificationOptions={certificationOptions}
              parkingFacilityOptions={parkingFacilityOptions}
              typeOfBusinessLawOptions={typeOfBusinessLawOptions}
              furnishingOptions={furnishingOptions}
              bogCombinationObjectSituationOptions={
                bogCombinationObjectSituationOptions
              }
              bogCombinationObjectStatusOptions={
                bogCombinationObjectStatusOptions
              }
              onChange={this.onChangeHandler}
            />
          )}

          {objectAssignment.realEstateGroup ===
            RealEstateGroup.Agricultural && (
            <EditContentAgriculturalComponent
              objectAssignment={objectAssignment}
              companyListings={companyListings}
              groundUsageOptions={groundUsageOptions}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              horseTroughLocationOptions={horseTroughLocationOptions}
              constructionPeriods={constructionPeriods}
              houseTypes={houseTypes}
              situatedTypeOptions={situatedTypeOptions}
              orientations={orientations}
              gardenQualityOptions={gardenQualityOptions}
              garageTypeOptions={garageTypeOptions}
              amenitiesOptions={amenitiesOptions}
              isolationTypeOptions={isolationTypeOptions}
              gardenTypeOptions={gardenTypeOptions}
              onChange={this.onChangeHandler}
              onCompanyListingsChange={this.onCompanyListingsChangeHandler}
              onRemoveCompanyListing={this.props.removeCompanyListing}
              poultryFarmingSubtypes={poultryFarmingSubtypes}
              poultryHousingTypeOptions={poultryHousingTypeOptions}
              conditionOptions={conditionOptions}
            />
          )}
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { id } = this.props.currentComponentState.objectAssignment;
    const path = RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id,
    });
    const objectAssignment = {
      ...this.props.currentComponentState.objectAssignment,
      ...values,
    };
    const newState = {
      ...this.props.currentComponentState,
      objectAssignment,
    };

    this.props.updateAssignment(newState, path);
  }

  private onCompanyListingsChangeHandler(values: CompanyListing[]) {
    const { id } = this.props.currentComponentState.objectAssignment;
    const path = RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id,
    });
    const listings = this.props.companyListings || [];

    const companyListings = values.map((listing) => {
      const reference = listings.find((c) => c.id === listing.id);
      if (!reference) return listing;
      return {
        ...reference,
        ...listing,
      };
    });

    const newState = {
      ...this.props.currentComponentState,
      companyListings,
    };

    this.props.updateAssignment(newState, path);
  }
}
