import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionDetailTimelineScreen,
  AcquisitionDetailTimelineScreenProps
} from "./timeline";

type StateProps = {
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailTimelineScreenProps,
  AppState
> = (state) => {
  const { acquisitionAssignmentObject } = state.acquisition.assignment;

  return {
    acquisitionAssignmentObject
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailTimelineScreenProps
> = (dispatch) => ({});

export type AcquisitionDetailTimelineScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionDetailTimelineScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailTimelineScreen);
