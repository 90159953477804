import { combineReducers } from "redux";
import { settingsReducer, SettingsState, SettingsActions } from "./settings";
import { messagesReducer, MessagesState, MessagesActions } from "./messages";
import { foldersReducer, FoldersState, FoldersActions } from "./folders";
import { accountsReducer, AccountsState, AccountsActions } from "./accounts";
import { draftsReducer, DraftsState, DraftsActions } from "./drafts";
import { sharesReducer, SharesState, SharesActions } from "./shares";
import {
  providersReducer,
  ProvidersState,
  ProvidersActions
} from "./providers";
import {
  emailTemplatesReducer,
  EmailTemplatesState,
  EmailSignatureActions
} from "./templates";

const emailReducer = combineReducers({
  settings: settingsReducer,
  messages: messagesReducer,
  folders: foldersReducer,
  accounts: accountsReducer,
  drafts: draftsReducer,
  shares: sharesReducer,
  providers: providersReducer,
  templates: emailTemplatesReducer
});

interface EmailState {
  settings: SettingsState;
  messages: MessagesState;
  folders: FoldersState;
  accounts: AccountsState;
  drafts: DraftsState;
  shares: SharesState;
  providers: ProvidersState;
  templates: EmailTemplatesState;
}

const EmailActions = {
  Messages: MessagesActions,
  Folders: FoldersActions,
  Accounts: AccountsActions,
  Drafts: DraftsActions,
  Shares: SharesActions,
  Providers: ProvidersActions,
  Settings: SettingsActions,
  Templates: EmailSignatureActions
};

export { emailReducer, EmailState, EmailActions };
