import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { SearchAssignment, RealEstateGroup } from "@haywork/api/kolibri";
import FormattedText from "@haywork/components/ui/formatted-text";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";
import { AddressUtil } from "@haywork/util";
import Icon from "@haywork/components/ui/icon";

const styles = require("./style.scss");

type Props = {
  searchAssignment: SearchAssignment;
  searchAssignmentIds: string[];
  onToggle: (id: string) => void;
};
const SearchAssignmentSuggestionComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ searchAssignment, onToggle, searchAssignmentIds }) => {
      const {
        realEstateGroup,
        offerType,
        id,
        locations,
        typesPART,
        typesPARTOther,
        typesBOG,
        typesALV,
        typesALVOther,
        typeParking,
      } = searchAssignment;

      const intl = useIntl();

      const title = useMemo(() => {
        const translations: string[] = [];

        if (!!realEstateGroup) {
          translations.push(
            intl.formatMessage({
              id: `realEstateGroups.${realEstateGroup.toString()}`,
              defaultMessage: realEstateGroup.toString(),
            })
          );
        }

        if (!!offerType) {
          translations.push(
            intl.formatMessage({
              id: `offerTypeOptions.${offerType.toString()}`,
              defaultMessage: offerType.toString(),
            })
          );
        }

        return translations.filter((v) => !!v).join(", ");
      }, [realEstateGroup, offerType, intl]);

      const active = useMemo(() => {
        return searchAssignmentIds.includes(id);
      }, [searchAssignmentIds, id]);

      const updatedLocations = useMemo(() => {
        return (locations || [])
          .map((location) =>
            AddressUtil.createTranslatedSearchAssignmentLocation(location)
          )
          .filter((location) => !!location.displayName);
      }, [locations, intl]);

      const locationsRow = useMemo(() => {
        if (!updatedLocations.length) return null;
        const locations = updatedLocations.slice(0, 3);

        return (
          <div styleName="suggestion__locations">
            {locations.map((location, idx) => (
              <div key={idx} styleName="location">
                <div styleName="icon">
                  <Icon
                    name="location-circle"
                    color={Colors.Primary}
                    size={14}
                  />
                </div>
                <div styleName="name">{location.displayName}</div>
              </div>
            ))}
          </div>
        );
      }, [updatedLocations, active]);

      const oveTypesRow = useMemo(() => {
        let oveTypesString: string;
        let parts: string[] = [];
        let partsOther: string[] = [];

        switch (realEstateGroup) {
          case RealEstateGroup.Residential: {
            parts = (typesPART || [])
              .filter((v) => !!v)
              .map((type) =>
                intl.formatMessage({
                  id: `typePARTOptions.${type.toString()}`,
                  defaultMessage: type.toString(),
                })
              );
            partsOther = (typesPARTOther || [])
              .filter((v) => !!v)
              .map((type) =>
                intl.formatMessage({
                  id: `typePARTOtherOptions.${type.toString()}`,
                  defaultMessage: type.toString(),
                })
              );
            break;
          }
          case RealEstateGroup.Commercial: {
            parts = (typesBOG || [])
              .filter((v) => !!v)
              .map((type) =>
                intl.formatMessage({
                  id: `typeBOGOptions.${type.toString()}`,
                  defaultMessage: type.toString(),
                })
              );
            break;
          }
          case RealEstateGroup.Agricultural: {
            parts = (typesALV || [])
              .filter((v) => !!v)
              .map((type) =>
                intl.formatMessage({
                  id: `typeALVOptions.${type.toString()}`,
                  defaultMessage: type.toString(),
                })
              );
            partsOther = (typesALVOther || [])
              .filter((v) => !!v)
              .map((type) =>
                intl.formatMessage({
                  id: `typeALVOtherOptions.${type.toString()}`,
                  defaultMessage: type.toString(),
                })
              );
            break;
          }
          default:
            break;
        }

        const partsParking = (typeParking || [])
          .filter((v) => !!v)
          .map((type) =>
            intl.formatMessage({
              id: `parkingTypeOptions.${type.toString()}`,
              defaultMessage: type.toString(),
            })
          );

        oveTypesString = [...parts, ...partsOther, ...partsParking]
          .filter((v) => !!v)
          .join(", ");

        if (!oveTypesString) return null;
        return <div styleName="suggestion__types">{oveTypesString}</div>;
      }, [realEstateGroup, intl]);

      return (
        <div
          styleName={classNames("suggestion", { active })}
          onClick={() => onToggle(id)}
        >
          <div styleName="suggestion__body">
            <div styleName="suggestion__title">
              <div styleName="header">
                <FormattedText
                  value={title}
                  textStyle="ucfirst"
                  skipTranslation
                  color={Colors.TextBlack}
                />
              </div>
              <div styleName="check">
                <Icon
                  name={active ? "check" : "plus"}
                  color={active ? Colors.Primary : Colors.Gray}
                  size={16}
                  regular
                />
              </div>
            </div>
            <div>{oveTypesRow}</div>
          </div>
          {locationsRow}
        </div>
      );
    }
  )
);

export default SearchAssignmentSuggestionComponent;
