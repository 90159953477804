import * as React from "react";

import {
  FloorTypeOption,
  AtticOptionOption,
  LivingRoomTypeOption,
  BathroomFacilityOption,
  KitchenTypeOption,
  OrientationOption,
  SpaceTypeOption,
  SpaceType,
  KitchenFacilityOption,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";

import {
  FloorFormComponent,
  SimpleSpaceFormComponent,
  LivingroomSpaceFormComponent,
  BathroomSpaceFormComponent,
  KitchenSpaceFormComponent,
  BalconyTerraceSpaceFormComponent,
} from "./forms";
import { ExtendedFloor, ExtendedSpace } from "..";
import { AssignmentUtil } from "@haywork/util";

interface Props {
  selectedFloorOrSpace: ExtendedFloor | ExtendedSpace;
  floorTypeOptions: FloorTypeOption[];
  atticOptions: AtticOptionOption[];
  livingRoomTypes: LivingRoomTypeOption[];
  bathroomFacilities: BathroomFacilityOption[];
  kitchenTypes: KitchenTypeOption[];
  orientations: OrientationOption[];
  spaceTypes: SpaceTypeOption[];
  floors: ExtendedFloor[];
  kitchenFacilities: KitchenFacilityOption[];
  canEditMainFloors: boolean;
  onChange: (floorOrSpace: ExtendedFloor | ExtendedSpace) => void;
}

export const DetailComponent = ({
  selectedFloorOrSpace,
  floorTypeOptions,
  atticOptions,
  livingRoomTypes,
  bathroomFacilities,
  kitchenTypes,
  orientations,
  spaceTypes,
  floors,
  kitchenFacilities,
  canEditMainFloors,
  onChange,
}: Props) => {
  const isFloor = AssignmentUtil.isFloor;

  switch (true) {
    case !!selectedFloorOrSpace && !isFloor(selectedFloorOrSpace): {
      const space = selectedFloorOrSpace as ExtendedSpace;
      switch (space.type) {
        case SpaceType.LivingRoom:
          return (
            <LivingroomSpaceFormComponent
              space={space}
              spaceTypes={spaceTypes}
              livingRoomTypes={livingRoomTypes}
              onChange={onChange}
            />
          );
        case SpaceType.Bathroom:
          return (
            <BathroomSpaceFormComponent
              space={space}
              spaceTypes={spaceTypes}
              bathroomFacilities={bathroomFacilities}
              onChange={onChange}
            />
          );
        case SpaceType.Kitchen:
          return (
            <KitchenSpaceFormComponent
              space={space}
              spaceTypes={spaceTypes}
              kitchenTypes={kitchenTypes}
              kitchenFacilities={kitchenFacilities}
              onChange={onChange}
            />
          );
        case SpaceType.Balcony:
        case SpaceType.RoofTerrace:
          return (
            <BalconyTerraceSpaceFormComponent
              space={space}
              spaceTypes={spaceTypes}
              orientations={orientations}
              onChange={onChange}
            />
          );
        default:
          return (
            <SimpleSpaceFormComponent
              space={space}
              spaceTypes={spaceTypes}
              onChange={onChange}
            />
          );
      }
    }
    case !!selectedFloorOrSpace && isFloor(selectedFloorOrSpace): {
      const floor = selectedFloorOrSpace as ExtendedFloor;
      switch (floor.floorType) {
        default:
          return (
            <FloorFormComponent
              floor={floor}
              floors={floors}
              floorTypeOptions={floorTypeOptions}
              atticOptions={atticOptions}
              canEditMainFloors={canEditMainFloors}
              onChange={onChange}
            />
          );
      }
    }
    default:
      return <ResourceText resourceKey="floorsAndSpaces.NothingSelected" />;
  }
};
