import { AppClientSnapShot } from "@haywork/api/authorization";
import PageHeader from "@haywork/components/ui/page-header";
import { APPXCHANGEROUTES } from "@haywork/constants";
import { AppXchangeAboutContainerProps } from "@haywork/modules/app-xchange";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { ToggleConsent } from "../toggle-consent";
import { Info } from "./info.component";
import { Integrations } from "./integrations.component";
import { MoreInfo } from "./more-info.component";
import { Related } from "./related.component";
import { Screenshots } from "./screenshots.component";

const styles = require("./about.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AppXchangeAboutComponentProps = {
  consentGiven: boolean;
  hideConsentGivenInfo: () => void;
};
type Props = AppXchangeAboutComponentProps & AppXchangeAboutContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeAboutComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onOpenPartner = this.onOpenPartner.bind(this);
  }

  public componentDidMount() {
    if (!this.props.partners.length) {
      this.props.getRecommendations();
    }
  }

  public render() {
    const { displayName } = this.props.client;

    return (
      <div styleName="about">
        <PageHeader title="appXchangeAboutTitle" actions={<ToggleConsent />} />

        <div styleName="about__body">
          {!!this.props.consentGiven && (
            <div styleName="consent-given">
              <div
                styleName="consent-given__close"
                onClick={this.props.hideConsentGivenInfo}
              >
                <i className="fal fa-times" />
              </div>
              <ResourceText
                resourceKey="Authorization.ConsentGiven.Info"
                values={{ displayName }}
                asHtml={true}
              />
              <NavLink
                to={route(APPXCHANGEROUTES.SUPPORT.URI, {
                  id: this.props.client.id,
                })}
                className="btn btn-primary"
              >
                <ResourceText resourceKey="Authorization.Cta.Support" />
              </NavLink>
            </div>
          )}

          <Info
            client={this.props.client}
            translation={this.props.translation}
          />
          <Screenshots
            translation={this.props.translation}
            showLightbox={this.props.showLightbox}
          />
          <MoreInfo translation={this.props.translation} />
          <Integrations client={this.props.client} />
          <Related
            partners={this.props.partners}
            onOpenPartner={this.onOpenPartner}
          />
        </div>
      </div>
    );
  }

  private onOpenPartner(client: AppClientSnapShot) {
    const { id } = client;
    const path = route(APPXCHANGEROUTES.DETAIL.URI, { id });

    this.props.navigate(path);
  }
}
