import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SingleFilterValue } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import {
  PERSONAL_EVENT_TYPES,
  PERSONAL_EVENT_TYPES_IMMUTABLE,
  GENERAL_EVENT_TYPES,
  GENERAL_EVENT_TYPES_IMMUTABLE,
  MLS_EVENT_TYPES,
} from "@haywork/constants";

const personalEventTypes = [
  ...PERSONAL_EVENT_TYPES,
  ...PERSONAL_EVENT_TYPES_IMMUTABLE,
  ...MLS_EVENT_TYPES,
];
const generalEventTypes = [
  ...GENERAL_EVENT_TYPES,
  ...GENERAL_EVENT_TYPES_IMMUTABLE,
];

const masterTableSelector = (state: AppState) => state.main.mastertable;
const employeesSelector = (state: AppState) => state.employee.employees;
const filtersSelector = (state: AppState) =>
  state.notifications.eventCenter.filters;
const statisticsSelector = (state: AppState) =>
  state.notifications.eventCenter.statistics;

export const listingTypeValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { listingTypes } = mastertable.kolibri;
    return (listingTypes || []).map(
      (listingType) =>
        ({
          label: listingType.displayName,
          value: listingType.value,
        } as SingleFilterValue)
    );
  }
);

export const employeeValues = createSelector(employeesSelector, (employees) => {
  return (employees || []).map(
    (employee) =>
      ({
        label: employee.displayName,
        value: employee.id,
      } as SingleFilterValue)
  );
});

export const activeFilterGuidValues = createSelector(
  employeesSelector,
  (employees) => {
    return {
      employeeId: (employees || []).map((employee) => ({
        value: employee.displayName,
        id: employee.id,
      })),
    } as FilterGuidValues;
  }
);

export const eventNotificationDateTimeValue = createSelector(
  filtersSelector,
  (filters) => {
    return {
      value: filters.eventNotificationDateTime.value,
    } as SingleFilterValue;
  }
);

export const typeFilterValues = createSelector(
  statisticsSelector,
  (statistics) => {
    const personalCount = (
      statistics?.numberOfNotArchivedEventsPerType || []
    ).reduce((state, cnt) => {
      return personalEventTypes.includes(cnt.eventType)
        ? state + cnt.count
        : state;
    }, 0);

    const generalCount = (
      statistics?.numberOfNotArchivedEventsPerType || []
    ).reduce((state, cnt) => {
      return generalEventTypes.includes(cnt.eventType)
        ? state + cnt.count
        : state;
    }, 0);

    return [
      {
        label: "typeFilters.personal",
        value: "personal",
        count: personalCount,
      },
      { label: "typeFilters.general", value: "general", count: generalCount },
      { label: "typeFilters.processedOnly", value: "processed" },
    ] as SingleFilterValue[];
  }
);

export const personalNotificationValues = createSelector(
  statisticsSelector,
  (statistics) => {
    return personalEventTypes.map((value) => {
      const count =
        (statistics?.numberOfNotArchivedEventsPerType || []).find(
          (cnt) => cnt.eventType === value
        )?.count || 0;
      return {
        label: `eventTypeFilter.${value.toString()}`,
        value,
        count,
      } as SingleFilterValue;
    });
  }
);

export const generalNotificationValues = createSelector(
  statisticsSelector,
  (statistics) => {
    return generalEventTypes.map((value) => {
      const count =
        (statistics?.numberOfNotArchivedEventsPerType || []).find(
          (cnt) => cnt.eventType === value
        )?.count || 0;
      return {
        label: `eventTypeFilter.${value.toString()}`,
        value,
        count,
      } as SingleFilterValue;
    });
  }
);
