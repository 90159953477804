import * as React from "react";
import { FC, memo, useState, useMemo, useEffect, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionListScreenContainerProps } from "./list.container";
import classNames from "classnames";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import List from "../../components/list";
import Filters from "../../components/list-filters";
import Pill from "@haywork/components/ui/pill";
import debounce from "lodash-es/debounce";
import noop from "lodash-es/noop";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";

const styles = require("./style.scss");

export type AcquisitionListScreenProps = {};
type Props = AcquisitionListScreenProps & AcquisitionListScreenContainerProps;

export const AcquisitionListScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      selectedFilters,
      clearFilter,
      clearAllFilters,
      getAcquisitions,
      filters,
    }) => {
      const [collapsed, setCollapsed] = useState(false);
      const initAcquisitions = useCallback(
        debounce(getAcquisitions || noop, 100),
        [getAcquisitions]
      );

      useEffect(() => {
        initAcquisitions();
      }, [filters]);

      const toggleCollapsed = () => {
        setCollapsed(!collapsed);
      };

      const toggleCollapsedStyle = useMemo(
        () =>
          collapsed
            ? { name: "filter", color: Colors.White, solid: true }
            : { name: "arrow-left", color: Colors.Black, light: true },
        [collapsed]
      );

      return (
        <div styleName="list__screen">
          <div styleName={classNames("sidebar", { collapsed })}>
            <div styleName="toggle-collapsed" onClick={toggleCollapsed}>
              <Icon {...toggleCollapsedStyle} size={16} />
            </div>
            <div styleName="sidebar__inner">
              <div styleName="clear">
                {!!selectedFilters.length ? (
                  <span className="as-link" onClick={clearAllFilters}>
                    <I18n value="acquisitions.list.clear" />
                  </span>
                ) : (
                  <span>
                    <I18n value="acquisitions.list.noFilters" />
                  </span>
                )}
              </div>
              <div styleName="filters">
                <Filters />
              </div>
            </div>
          </div>
          <div styleName="body">
            <div styleName="header">
              {selectedFilters.map((filter, idx) => (
                <Pill
                  key={idx}
                  label={filter.label}
                  labelValues={{ value: filter.value }}
                  color={Colors.Primary}
                  solid
                  deletable
                  onClick={() => clearFilter(filter)}
                />
              ))}
            </div>
            <div styleName="list">
              <EntityListStatus
                list="acquisitions"
                onClick={initAcquisitions}
                style={{ top: 35 }}
              />
              <List />
            </div>
          </div>
        </div>
      );
    }
  )
);
