import { Action } from "redux";

import {
  FinancialAdministration,
  FinancialAdministrationSnapShot,
  Invoice,
  ReportTemplate,
  ExtensionInfo,
  ObjectAssignment,
  AssignmentSnapShot
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";

import * as ActionType from "./invoice.types";

export interface SingleInvoiceState {
  invoice: Invoice;
  invoiceStatus: string;
  saveInvoiceStatus: string;
  addressSearchStatus: string;
  formalName: string;
  financialAdministrations: FinancialAdministrationSnapShot[];
  financialAdministration: FinancialAdministration;
  printTemplates: ReportTemplate[];
  printExportOptions: ExtensionInfo[];
  linkedAssignment: ObjectAssignment;
  linkedAssignmentSnapShot: AssignmentSnapShot;
}

const INITIAL_STATE: SingleInvoiceState = {
  invoice: null,
  invoiceStatus: REQUEST.IDLE,
  saveInvoiceStatus: REQUEST.IDLE,
  addressSearchStatus: REQUEST.IDLE,
  formalName: null,
  financialAdministrations: [],
  financialAdministration: null,
  printTemplates: [],
  printExportOptions: [],
  linkedAssignment: null,
  linkedAssignmentSnapShot: null
};

export const singleInvoiceReducer = (state: SingleInvoiceState = INITIAL_STATE, action: Action): SingleInvoiceState => {
  switch (action.type) {
    case REDUX.INVOICES.SET_SINGLE_INVOICE: {
      const { invoice } = <ActionType.SingleInvoice> action;
      return {
        ...state,
        invoice,
        invoiceStatus: REQUEST.SUCCESS,
        addressSearchStatus: REQUEST.IDLE,
        formalName: null,
        financialAdministrations: [],
        financialAdministration: null,
        printTemplates: [],
        printExportOptions: [],
        linkedAssignment: null,
        linkedAssignmentSnapShot: null
      };
    }
    case REDUX.INVOICES.SET_SINGLE_INVOICE_STATUS: {
      const { invoiceStatus } = <ActionType.InvoiceSingleStatus> action;
      return { ...state, invoiceStatus };
    }
    case REDUX.INVOICES.SET_INVOICE_ADDRESS_SEARCH_STATUS: {
      const { addressSearchStatus } = <ActionType.AddressSearchStatus> action;
      return { ...state, addressSearchStatus };
    }
    case REDUX.INVOICES.SET_INVOICE_FORMAL_NAME: {
      const { formalName } = <ActionType.FormalName> action;
      return { ...state, formalName };
    }
    case REDUX.INVOICES.SET_FINANCIAL_ADMINISTRATIONS_INVOICE: {
      const { financialAdministrations } = <ActionType.InvoiceFinancialAdministrations> action;
      return { ...state, financialAdministrations };
    }
    case REDUX.INVOICES.SET_FINANCIAL_ADMINISTRATION_INVOICE: {
      const { financialAdministration } = <ActionType.InvoiceFinancialAdministration> action;
      return { ...state, financialAdministration };
    }
    case REDUX.INVOICES.SET_LINKED_ASSIGMENT: {
      const { linkedAssignment, linkedAssignmentSnapShot } = <ActionType.LinkedAssignment> action;

      return {
        ...state,
        linkedAssignment,
        linkedAssignmentSnapShot
      };
    }
    case REDUX.INVOICES.SET_PRINT_TEMPLATES: {
      const { printTemplates } = <ActionType.PrintTemplates> action;

      return {
        ...state,
        printTemplates
      };
    }
    case REDUX.INVOICES.SET_PRINT_EXPORT_OPTIONS: {
      const { printExportOptions } = <ActionType.PrintExportOptions> action;

      return {
        ...state,
        printExportOptions
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: return state;
  }
};
