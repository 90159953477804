import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  SearchAssignmentDetailMatchmailContainerProps,
  SearchAssignmentMatchingPropertiesModalContainer,
} from "@haywork/modules/search-assignment";
import {
  InfiniteScroll,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SearchAssignmentMatchMailItemComponent } from "./list-item.component";

const styles = require("./detail-matchmail.component.scss");

export type SearchAssignmentDetailMatchmailComponentProps = {};
interface State {
  matchingPropertiesModalVisible: boolean;
  objectAssignmentIds: string[];
  dateTimeSent: Date;
}
type Props = SearchAssignmentDetailMatchmailComponentProps &
  SearchAssignmentDetailMatchmailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentDetailMatchmailComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onMatchingPropertiesClickHandler = this.onMatchingPropertiesClickHandler.bind(
      this
    );
    this.onModalCloseHandler = this.onModalCloseHandler.bind(this);

    this.state = {
      matchingPropertiesModalVisible: false,
      objectAssignmentIds: [],
      dateTimeSent: new Date(),
    };
  }

  public componentDidMount() {
    const { id } = this.props.searchAssignment;
    this.props.getSearchAssignmentMatchMails(id, true);
  }

  public render() {
    const matchMails = this.props.matchMails || [];

    return (
      <div styleName="matchmail">
        <div styleName="matchmail__header">
          <ResourceText
            resourceKey="matchMailCount"
            values={{ total: this.props.matchMailsTotal }}
          />
        </div>
        <div styleName="matchmail__list">
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {matchMails.map((matchMail, idx) => (
              <ErrorBoundary key={idx}>
                <SearchAssignmentMatchMailItemComponent
                  zebra={idx % 2 === 0}
                  matchMail={matchMail}
                  searchAssignment={this.props.searchAssignment}
                  onMatchingPropertiesClick={
                    this.onMatchingPropertiesClickHandler
                  }
                />
              </ErrorBoundary>
            ))}
            {this.props.matchMailsState === REQUEST.PENDING && (
              <PageLoader loading fullscreen={matchMails.length === 0} />
            )}
            {this.props.matchMailsState === REQUEST.SUCCESS &&
              matchMails.length === 0 && (
                <div styleName="empty-state">
                  <div styleName="content">
                    <div styleName="icon" />
                    <div styleName="text">
                      <h2>
                        <ResourceText resourceKey="matchMailsEmptyStateTitle" />
                      </h2>
                      <p>
                        <ResourceText resourceKey="matchMailsEmptyStateBody" />
                      </p>
                    </div>
                  </div>
                </div>
              )}
          </InfiniteScroll>
        </div>

        {/* Matching properties modal */}
        <SearchAssignmentMatchingPropertiesModalContainer
          visible={this.state.matchingPropertiesModalVisible}
          onClose={this.onModalCloseHandler}
          onNavigateToProperty={this.props.navigate}
          objectAssignmentIds={this.state.objectAssignmentIds}
          searchAssignment={this.props.searchAssignment}
          dateTimeSent={this.state.dateTimeSent}
        />
      </div>
    );
  }

  private onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.matchMailsState === REQUEST.PENDING
    )
      return;
    const { id } = this.props.searchAssignment;
    this.props.getSearchAssignmentMatchMails(id);
  }

  private onMatchingPropertiesClickHandler(
    objectAssignmentIds: string[],
    dateTimeSent: Date
  ) {
    this.props.getMatchMailProperties(
      objectAssignmentIds,
      this.props.searchAssignment.id
    );
    this.setState({
      matchingPropertiesModalVisible: true,
      objectAssignmentIds,
      dateTimeSent,
    });
  }

  private onModalCloseHandler() {
    this.setState({ matchingPropertiesModalVisible: false });
  }
}
