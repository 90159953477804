import {
  AssignmentSnapShot,
  CommunicationLog,
  CommunicationLogsClient,
  EmployeeRole,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import {
  Account,
  FolderCategory,
  Message,
  MessagesClient,
  RelatedMessageLink,
  UserDataElement,
  ErrorCode,
} from "@haywork/api/mail";
import {
  COUNTS,
  EMAILROUTES,
  LINKED_ASSIGNMENT_KEY,
  LINKED_RELATION_KEY,
  MAINROUTES,
  REQUEST,
} from "@haywork/constants";
import { DraftType } from "@haywork/enum";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EditableActions, EmailActions } from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { RouteUtil, EmailUtil as OldEmailUtil } from "@haywork/util";
import { ExtendedEmailDraft, ExtendedEmailFolder } from "@haywork/util/email";
import differenceBy from "lodash-es/differenceBy";
import findIndex from "lodash-es/findIndex";
import first from "lodash-es/first";
import get from "lodash-es/get";
import { Dispatch } from "../";
import { push } from "connected-react-router";
import { v4 as uuid } from "uuid";
import has from "lodash-es/has";
import {
  EmailChangeFolderType,
  EmailChangeFolder,
} from "@haywork/api/event-center";
import { EmailMessage } from "@haywork/stores/email-v2";
import { EmailUtil } from "@haywork/util/email-v2";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getMessages = (init: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    if (role === EmployeeRole.BackOffice) return;

    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { currentFolder } = state.email.folders;

    if (!currentFolder || !currentAccount) return;

    const { messages, searchFilters } = state.email.messages;
    const { term: searchQuery, unread, bookmarked } = searchFilters;
    const skip = init
      ? 0
      : messages.filter(
          (message) =>
            message.folderId === currentFolder.id &&
            message.accountId === currentAccount.id
        ).length;
    const take = COUNTS.EMAIL_MESSAGES;
    const firstMessage = first(
      messages.filter(
        (message) =>
          message.accountId === currentAccount.id &&
          message.folderId === currentFolder.id
      )
    );

    dispatch(
      EmailActions.Folders.setFolderMessagesStatus({
        canLoadMoreMessagesStatus: REQUEST.PENDING,
        id: currentFolder.id,
      })
    );

    if (!!init && !!firstMessage) {
      dispatch(readMessage(currentAccount, currentFolder, firstMessage));
    }

    const Messages = new MessagesClient(emailHost);

    try {
      const messages = await parseRequest.response(
        Messages.search(
          {
            folderId: currentFolder.id,
            accountId: currentAccount.id,
            skip,
            take,
            searchQuery,
            unread,
            bookmarked,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const { messages: currentMessages } = state.emailV2.messages;
      const extendedMessages = messages.results.map((message) =>
        EmailUtil.mapMessageToEmailMessage(
          message,
          currentAccount.id,
          currentFolder.id,
          currentMessages
        )
      );
      dispatch(
        EmailActions.Messages.appendMessages({ messages: extendedMessages })
      );

      if (messages.resultCount === 0) {
        dispatch(
          EmailActions.Folders.setFolderCanLoadMore({
            canLoadMoreMessages: false,
            id: currentFolder.id,
          })
        );
      }

      const { accounts, folders } = getState().email;
      if (
        !!init &&
        !firstMessage &&
        get(currentAccount, "id") === get(accounts, "currentAccount.id") &&
        get(currentFolder, "id") === get(folders, "currentFolder.id")
      ) {
        const firstMessage = first(extendedMessages);

        dispatch(readMessage(currentAccount, currentFolder, firstMessage));
      }
      dispatch(
        EmailActions.Folders.setFolderMessagesStatus({
          canLoadMoreMessagesStatus: REQUEST.SUCCESS,
          id: currentFolder.id,
        })
      );
    } catch (error) {
      dispatch(
        EmailActions.Folders.setFolderMessagesStatus({
          canLoadMoreMessagesStatus: REQUEST.ERROR,
          id: currentFolder.id,
        })
      );
      throw error;
    }
  };
};

const readMessage = (
  account: Account,
  folder: ExtendedEmailFolder,
  message: EmailMessage,
  setRead: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!message) return;

    const state = getState();
    const { currentMessage: oldMessage } = state.email.messages;

    if (!oldMessage || oldMessage.id !== message.id) {
      dispatch(EmailActions.Messages.setMessage({ message }));

      if (setRead) {
        dispatch(toggleUnread(account.id, message));
      }

      if (!message.body) {
        dispatch(getMessage(account, folder, message.id, undefined));
      }
    }
  };
};

const getMessage = (
  account: Account,
  folder: ExtendedEmailFolder,
  id: string,
  blockExternalReferences?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Messages.setMessageStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    if (role === EmployeeRole.BackOffice) return;

    const { emailHost } = state.appSettings;

    const Messages = new MessagesClient(emailHost);

    try {
      const result = await parseRequest.response(
        Messages.read(
          account.id,
          id,
          blockExternalReferences,
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const { message } = result;
      const { messages: currentMessages } = state.emailV2.messages;
      const extendedMessage = EmailUtil.mapMessageToEmailMessage(
        message,
        account.id,
        folder.id,
        currentMessages,
        true
      );

      dispatch(
        EmailActions.Messages.updateMessage({ message: extendedMessage })
      );
    } catch (error) {
      dispatch(EmailActions.Messages.setMessageStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const toggleUnread = (accountId: string, message: EmailMessage) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EmailActions.Messages.setMessageUnreadStatus({
        id: message.id,
        unread: !message.unread,
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Messages = new MessagesClient(emailHost);

    try {
      await parseRequest.response(
        Messages.toggleIsRead(
          {
            accountId,
            id: message.id,
            isRead: message.unread,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      dispatch(
        EmailActions.Folders.updateUnreadCount({
          unread: message.unread,
          folderId: message.folderId,
        })
      );
    } catch (error) {
      dispatch(
        EmailActions.Messages.setMessageUnreadStatus({
          id: message.id,
          unread: message.unread,
        })
      );
      throw error;
    }
  };
};

const toggleBookmarked = (
  accountId: string,
  id: string,
  bookmarked: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EmailActions.Messages.setMessageBookmarkStatus({
        id,
        bookmarked: !bookmarked,
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Messages = new MessagesClient(emailHost);

    try {
      await parseRequest.response(
        Messages.toggleBookmark(
          {
            accountId,
            id,
            isBookmarked: !bookmarked,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );
    } catch (error) {
      dispatch(
        EmailActions.Messages.setMessageBookmarkStatus({ id, bookmarked })
      );
      throw error;
    }
  };
};

const deleteMessage = (accountId: string, message: EmailMessage) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { messages } = state.email.messages;
    const { currentFolder } = state.email.folders;

    const filteredMessages = messages.filter(
      (message) =>
        message.folderId === currentFolder.id &&
        message.accountId === currentAccount.id
    );
    const index = findIndex(filteredMessages, (msg) => msg.id === message.id);
    const previousMessage = filteredMessages[index - 1];
    const nextMessage = filteredMessages[index + 1];

    const Messages = new MessagesClient(emailHost);

    try {
      switch (true) {
        case !!nextMessage:
          dispatch(readMessage(currentAccount, currentFolder, nextMessage));
          break;
        case !!previousMessage:
          dispatch(readMessage(currentAccount, currentFolder, previousMessage));
          break;
        default:
          break;
      }

      dispatch(EmailActions.Messages.deleteMessage(message.id));

      await parseRequest.response(
        Messages.delete(accountId, message.id, realEstateAgencyId),
        ApiType.Email,
        { displayError: false }
      );

      const toast: ToastProps = {
        value: "messageMovedToTrash",
        icon: "trash-alt",
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      throw error;
    }
  };
};

const moveToFolder = (
  accountId: string,
  message: EmailMessage,
  folder: ExtendedEmailFolder
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { messages } = state.email.messages;
    const { currentFolder } = state.email.folders;

    const filteredMessages = messages.filter(
      (message) =>
        message.folderId === currentFolder.id &&
        message.accountId === currentAccount.id
    );
    const index = findIndex(filteredMessages, (msg) => msg.id === message.id);
    const previousMessage = filteredMessages[index + 1];
    const nextMessage = filteredMessages[index - 1];

    const Messages = new MessagesClient(emailHost);

    try {
      const updatedMessage = {
        ...message,
        folderId: folder.id,
      };

      dispatch(
        EmailActions.Messages.updateMessage({ message: updatedMessage })
      );

      switch (true) {
        case !!previousMessage:
          dispatch(readMessage(currentAccount, currentFolder, previousMessage));
          break;
        case !!nextMessage:
          dispatch(readMessage(currentAccount, currentFolder, nextMessage));
          break;
        default:
          break;
      }

      await parseRequest.response(
        Messages.move(
          {
            accountId,
            id: message.id,
            newFolderId: folder.id,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      dispatch(
        SnackbarActions.addToast({
          value: "emailMessageMovedToFolder",
          values: { folder: folder.displayName },
          icon: "folder-open",
        })
      );
    } catch (error) {
      dispatch(EmailActions.Messages.updateMessage({ message }));
      throw error;
    }
  };
};

const getMessagesForAccountAndFolder = (
  account: Account,
  folder: ExtendedEmailFolder
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Accounts.setCurrentAccount({ account }));
    dispatch(EmailActions.Folders.setCurrentFolder({ folder }));
    dispatch(EmailActions.Messages.clearCurrentMessage());
    dispatch(getMessages(true));
  };
};

const messageToDraft = (
  message: Message,
  type: DraftType,
  accountId: string,
  folderId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { accounts } = state.email.accounts;
    const { cultureMessages } = state.main;
    const account = accounts.results.find(
      (account) => account.id === accountId
    );

    let {
      body,
      subject,
      from,
      cc,
      bcc,
      files,
      to: additionalTo,
      date,
    } = message;
    cc = cc || [];
    bcc = bcc || [];
    from = from || [];
    files = files || [];
    date = date || new Date();
    let to = [];
    const originalTo = additionalTo || [];
    const userData: UserDataElement[] = [];

    const linkedCommunicationLogId = get(
      message,
      "_metaData.linkedCommunicationLogId"
    );
    if (!!linkedCommunicationLogId) {
      const communicationLog = await dispatch(
        getCommunicationLog(linkedCommunicationLogId)
      );
      const { linkedRelations, linkedAssignments } = communicationLog;

      (linkedRelations || []).map((relation) => {
        userData.push({
          key: LINKED_RELATION_KEY,
          value: JSON.stringify(relation),
        });
      });
      (linkedAssignments || []).map((assignment) => {
        userData.push({
          key: LINKED_ASSIGNMENT_KEY,
          value: JSON.stringify(assignment),
        });
      });
    }

    switch (type) {
      case DraftType.ReplyAll:
        additionalTo = additionalTo.filter(
          (to) => to.email !== account.emailAddress
        );
        to = [...from];
        to = [...to, ...differenceBy(additionalTo, to, (email) => email.email)];
        // to = [...to, ...differenceBy(cc, to, (email) => email.email)];
        // to = [...to, ...differenceBy(bcc, to, (email) => email.email)];
        body = OldEmailUtil.prepareEmailBody(
          from,
          originalTo,
          cc,
          date,
          subject,
          body,
          cultureMessages
        );
        subject = `RE: ${subject}`;
        files = files.filter((file) => !file.isAttachment);
        userData.push({
          key: "relatedMessage",
          value: JSON.stringify({
            link: RelatedMessageLink.ReplyTo,
            accountId,
            messageId: message.id,
          }),
        });
        break;
      case DraftType.Reply: {
        to = from;
        cc = [];
        bcc = [];
        body = OldEmailUtil.prepareEmailBody(
          from,
          originalTo,
          cc,
          date,
          subject,
          body,
          cultureMessages
        );
        subject = `RE: ${subject}`;
        files = files.filter((file) => !file.isAttachment);
        userData.push({
          key: "relatedMessage",
          value: JSON.stringify({
            link: RelatedMessageLink.ReplyTo,
            accountId,
            messageId: message.id,
          }),
        });
        break;
      }
      case DraftType.Forward: {
        cc = [];
        bcc = [];
        body = OldEmailUtil.prepareEmailBody(
          from,
          originalTo,
          [],
          date,
          subject,
          body,
          cultureMessages
        );
        subject = `FW: ${subject}`;
        userData.push({
          key: "relatedMessage",
          value: JSON.stringify({
            link: RelatedMessageLink.ForwardOf,
            accountId,
            messageId: message.id,
          }),
        });
        break;
      }
      default:
        break;
    }

    const id = uuid();
    const path = route(EMAILROUTES.CREATE.URI, { id });
    const componentState: ExtendedEmailDraft = {
      body,
      subject,
      to,
      cc,
      files,
      isNew: true,
      temporaryId: id,
      accountId,
      folderId,
      hasChanges: false,
      type,
      userData,
      sendRequested: false,
    };

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: componentState.subject || "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
      })
    );
    dispatch(push(path));
  };
};

const checkIfFolderEligableForDelete = (
  folderId: string,
  accountId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Messages = new MessagesClient(emailHost);

    try {
      return await parseRequest.response(
        Messages.search(
          {
            accountId,
            skip: 0,
            take: 1,
            folderId,
          },
          realEstateAgencyId
        ).then((result) => result.resultCount === 0),
        ApiType.Email
      );
    } catch (error) {
      throw error;
    }
  };
};

const archiveMessage = (accountId: string, id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;
    const { messages } = state.email.messages;
    const { currentFolder } = state.email.folders;

    const filteredMessages = messages.filter(
      (message) =>
        message.folderId === currentFolder.id &&
        message.accountId === currentAccount.id
    );
    const index = findIndex(filteredMessages, (message) => message.id === id);
    const previousMessage = filteredMessages[index + 1];
    const nextMessage = filteredMessages[index - 1];

    const Messages = new MessagesClient(emailHost);

    try {
      await parseRequest.response(
        Messages.archive({ accountId, id }, realEstateAgencyId),
        ApiType.Email
      );

      const toast: ToastProps = {
        value: "messageMovedToArchive",
        icon: "archive",
      };

      switch (true) {
        case !!previousMessage:
          dispatch(readMessage(currentAccount, currentFolder, previousMessage));
          break;
        case !!nextMessage:
          dispatch(readMessage(currentAccount, currentFolder, nextMessage));
          break;
        default:
          break;
      }

      dispatch(EmailActions.Messages.deleteMessage(id));
      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      throw error;
    }
  };
};

const searchMessages = (term: string, folder: ExtendedEmailFolder) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Messages.clearAll());
    dispatch(EmailActions.Messages.setTerm(term));
    dispatch(
      EmailActions.Folders.setCurrentFolder({
        folder: { ...folder, canLoadMoreMessagesStatus: REQUEST.PENDING },
      })
    );
    dispatch(getMessages(true));
  };
};

const filterMessages = (unread: boolean | true) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(EmailActions.Messages.clearAll());
    dispatch(EmailActions.Messages.filter({ unread }));
    dispatch(getMessages(true));
  };
};

const checkAndUpdateMessage = (
  messageId: string,
  accountId: string,
  personId?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: employeeId } = state.account.employee;

    if (personId === employeeId) return;

    const { messages } = state.email.messages;
    const match = messages.find((message) => message.id === messageId);

    if (!match) return;

    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const client = new MessagesClient(emailHost);

    try {
      const message = await parseRequest.response(
        client
          .read(accountId, messageId, true, realEstateAgencyId)
          .then((r) => r.message),
        ApiType.Email,
        { displayError: false }
      );

      const { messages: currentMessages } = state.emailV2.messages;
      const extendedMessage = EmailUtil.mapMessageToEmailMessage(
        message,
        accountId,
        get(message, "folder.id"),
        currentMessages,
        true
      );
      dispatch(
        EmailActions.Messages.updateMessage({ message: extendedMessage })
      );
    } catch (error) {
      if (
        (has(error, "status") && get(error, "status") === 404) ||
        (has(error, "errorCode") &&
          get(error, "errorCode") === ErrorCode.NotFound)
      ) {
        dispatch(EmailActions.Messages.deleteMessage(messageId));
      }
      throw error;
    }
  };
};

const appendNewMessage = (
  messageId: string,
  accountId: string,
  folder: EmailChangeFolder,
  silent: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    // const { currentAccount } = state.email.accounts;
    // const { currentFolder } = state.email.folders;

    const Messages = new MessagesClient(emailHost);

    // if (currentAccount.id !== accountId) return;
    // if (!currentFolder || currentFolder.category !== FolderCategory.Inbox)
    //   return;

    try {
      const message = await parseRequest.response(
        Messages.read(accountId, messageId, true, realEstateAgencyId).then(
          (r) => r.message
        ),
        ApiType.Email,
        { displayError: false }
      );

      const { messages: currentMessages } = state.emailV2.messages;
      const extendedMessage = EmailUtil.mapMessageToEmailMessage(
        message,
        accountId,
        folder.id,
        currentMessages,
        true
      );
      dispatch(
        EmailActions.Messages.appendMessage({ message: extendedMessage })
      );

      if (
        ![EmailChangeFolderType.Trash, EmailChangeFolderType.Archive].includes(
          folder.type
        )
      ) {
        if (!silent) {
          const toast: ToastProps = {
            value: "emailToastMessageReceived",
            values: { folder: folder.displayName },
            icon: "envelope",
          };
          dispatch(SnackbarActions.addToast(toast));
        }
      }
    } catch (error) {
      throw error;
    }
  };
};

const getCommunicationLog = (communicationLogId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CommunicationLog = new CommunicationLogsClient(host);

    try {
      const log = await parseRequest.response(
        CommunicationLog.read(communicationLogId, realEstateAgencyId),
        ApiType.Email
      );
      return log.communicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const saveCommunicationLog = (
  linkedRelations: RelationSnapShot[],
  linkedAssignments: AssignmentSnapShot[],
  message: EmailMessage,
  communicationLog?: CommunicationLog,
  subject?: string,
  showToast?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, emailHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentAccount } = state.email.accounts;

    const CommunicationLog = new CommunicationLogsClient(host);
    const Messages = new MessagesClient(emailHost);
    let isNew = get(communicationLog, "isNew", false);

    try {
      // Is New
      if (!communicationLog) {
        isNew = true;
        communicationLog = await parseRequest.response(
          CommunicationLog.defineNew({}, realEstateAgencyId).then(
            (response) => response.communicationLog
          ),
          ApiType.Email
        );
      }

      communicationLog = {
        ...communicationLog,
        subject: subject || message.subject,
        date: message.date,
        linkedRelations,
        linkedAssignments,
      };

      const saveResponse = await parseRequest.response(
        CommunicationLog.save({ communicationLog }, realEstateAgencyId),
        ApiType.Email
      );

      if (isNew) {
        const accountId = currentAccount.id;
        const communicationLogId = saveResponse.communicationLog.id;
        try {
          await parseRequest.response(
            Messages.persist(
              {
                accountId,
                communicationLogId,
                id: message.id,
                removeMessageAfterPersisted: false,
              },
              realEstateAgencyId
            ),
            ApiType.Email
          );

          const messageToAppend = {
            ...message,
            _metaData: {
              ...message._metaData,
              linkedCommunicationLogId: communicationLogId,
            },
          };

          dispatch(
            EmailActions.Messages.updateMessage({ message: messageToAppend })
          );
        } catch (error) {
          deleteCommunicationLog(communicationLogId);
          throw error;
        }
      }

      if (showToast) {
        const toast: ToastProps = {
          value: "emailToastLinkedItemsSaved",
          icon: "envelope",
        };
        dispatch(SnackbarActions.addToast(toast));
      }

      return saveResponse.communicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const deleteCommunicationLog = (communicationLogId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, emailHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentMessage } = state.email.messages;

    const CommunicationLog = new CommunicationLogsClient(host);
    const Messages = new MessagesClient(emailHost);

    try {
      await parseRequest.response(
        CommunicationLog.delete(communicationLogId, realEstateAgencyId),
        ApiType.Email
      );
      await parseRequest.response(
        Messages.unlinkCommunicationLog(
          { communicationLogId },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const messageToAppend: EmailMessage = {
        ...currentMessage,
        _metaData: {
          ...currentMessage._metaData,
          linkedCommunicationLogId: null,
        },
      };

      dispatch(
        EmailActions.Messages.updateMessage({ message: messageToAppend })
      );
    } catch (error) {
      throw error;
    }
  };
};

const loadExternalReferences = (message: EmailMessage) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Messages = new MessagesClient(emailHost);

    try {
      const result = await parseRequest.response(
        Messages.read(
          message.accountId,
          message.id,
          false,
          realEstateAgencyId
        ).then((response) => response.message),
        ApiType.Email
      );

      const extendedMessage = {
        ...message,
        ...result,
      };
      dispatch(
        EmailActions.Messages.updateMessage({ message: extendedMessage })
      );
    } catch (error) {
      throw error;
    }
  };
};

export const EmailMessageThunks = {
  getMessages,
  getMessage,
  readMessage,
  toggleUnread,
  toggleBookmarked,
  deleteMessage,
  moveToFolder,
  getMessagesForAccountAndFolder,
  messageToDraft,
  checkIfFolderEligableForDelete,
  archiveMessage,
  searchMessages,
  filterMessages,
  checkAndUpdateMessage,
  appendNewMessage,
  getCommunicationLog,
  saveCommunicationLog,
  deleteCommunicationLog,
  loadExternalReferences,
};
