import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  LinkedAssignment,
  LinkedRelation,
  ObjectAssignmentsClient,
  SortOrder,
  Task,
  TaskDelegateFilter,
  TaskOrderByField,
  TasksClient,
  TaskSnapShot,
  TaskStatus,
  AgendaItem,
} from "@haywork/api/kolibri";
import { MAINROUTES, REQUEST, TASKROUTES } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  LayoutActions,
  TaskActions,
  taskActionsV2,
} from "@haywork/stores";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import {
  AsyncUtil,
  BackOfficeUtil,
  DateUtil,
  RouteUtil,
  StringUtil,
} from "@haywork/util";
import { push } from "connected-react-router";
import head from "lodash-es/head";
import * as moment from "moment";
import { Dispatch } from ".";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { mapTaskToTaskSnapShot } from "@haywork/mappers/task";
import { TaskListActions } from "@haywork/stores/task/list";
import findIndex from "lodash-es/findIndex";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getTasks = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(TaskActions.setTaskOverviewStatus(REQUEST.PENDING));

    const state = getState();
    const { employeeId } = state.access;
    const { taskOverviewPage, tasksFilters } = state.task;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      statusFilter,
      employeeFilter,
      categoryFilter,
      delegateFilter,
    } = tasksFilters;

    const Tasks = new TasksClient(host);

    try {
      const result = await parseRequest.response(
        Tasks.search(
          {
            orderBy: TaskOrderByField.DefaultOrder,
            includeStatistics: true,
            filterByActive: ActiveFilter.ActiveOrInactive,
            skip: init ? 0 : taskOverviewPage * take,
            take,
            order: SortOrder.Ascending,
            statusFilter,
            employeeId:
              delegateFilter === TaskDelegateFilter.Delegated
                ? employeeId
                : employeeFilter,
            categoryIds: categoryFilter,
            delegateFilter,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(TaskActions.setTasks(result, take))
        : dispatch(TaskActions.appendTasks(result, take));
    } catch (error) {
      dispatch(TaskActions.setTaskOverviewStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getUpcomingTasks = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(TaskActions.setTaskWidgetState(REQUEST.PENDING));

    const state = getState();
    const { employeeId } = state.access;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      const tasks = await parseRequest.response(
        Tasks.search(
          {
            orderBy: TaskOrderByField.EndDate,
            statusFilter: [
              TaskStatus.NotStarted,
              TaskStatus.InProgress,
              TaskStatus.Waiting,
            ],
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 3,
            order: SortOrder.Ascending,
            employeeId,
            delegateFilter: TaskDelegateFilter.AssignedToMe,
          },
          realEstateAgencyId
        )
      );

      dispatch(TaskActions.setUpcomingTasks(tasks));
    } catch (error) {
      dispatch(TaskActions.setTaskWidgetState(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateWidgetItemStatus = (taskId: string, status: TaskStatus) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(
        Tasks.updateStatus({ taskId, status }, realEstateAgencyId)
      );

      dispatch(TaskActions.setTaskWidgetItemStatus({ taskId, status }));
    } catch (error) {
      throw error;
    }
  };
};

const createTask = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    try {
      let task = await parseRequest.response(
        Tasks.defineNew({ employeeId }, realEstateAgencyId).then(
          (response) => response.task
        )
      );

      const path = route(TASKROUTES.TASK.URI, { id: task.id });
      task = {
        ...task,
        useReminder: true,
      };

      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.TASKS.ICON,
          componentState: task,
          path,
          title: "...",
          confirm: {
            title: { key: "saveTaskConfirmTitle" },
            body: { key: "saveTaskConfirmBody" },
          },
          entityType: RootEntityType.Task,
          entityId: task.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      throw error;
    }
  };
};

const deleteTask = (
  id: string,
  subject: string,
  undeleteCallback?: () => void,
  showToast?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { tasks, totalCount } = state.taskV2.list;

    const Tasks = new TasksClient(host);

    try {
      parseRequest.response(Tasks.delete(id, realEstateAgencyId));

      const index = findIndex(tasks, (task) => task.id === id);
      if (index === -1) return;
      const snapshot = tasks[index];
      const searchAssignmentsClone = [...tasks];
      searchAssignmentsClone.splice(index, 1);

      dispatch(
        TaskListActions.updateList(0, searchAssignmentsClone, totalCount - 1)
      );

      if (!!undeleteCallback || showToast) {
        dispatch(
          SnackbarActions.addToast({
            value: "task.toast.deleted",
            values: { subject: StringUtil.trim(subject, 48) },
            callback: async () => {
              await dispatch(unDeleteTask(id, snapshot, index));
              if (typeof undeleteCallback === "function") undeleteCallback();
            },
            callbackLabel: "task.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteTask = (
  id: string,
  snapshot?: TaskSnapShot,
  prevIndex?: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { tasks, totalCount } = state.taskV2.list;
    const tasksClone = [...tasks];

    const Tasks = new TasksClient(host);

    try {
      parseRequest.response(Tasks.undelete({ id }, realEstateAgencyId));
      if ((snapshot && prevIndex) || (snapshot && prevIndex === 0)) {
        tasksClone.splice(prevIndex, 0, snapshot);

        dispatch(TaskListActions.updateList(0, tasksClone, totalCount + 1));
      }
    } catch (error) {
      throw error;
    }
  };
};

const updateStatus = (
  taskId: string,
  status: TaskStatus,
  refresh = true,
  showUndo = false,
  task?: TaskSnapShot
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;
    const { tasks, tasksFilters } = state.task;
    const {
      statusFilter,
      employeeFilter,
      categoryFilter,
      delegateFilter,
    } = tasksFilters;
    const take = tasks.length + 1;

    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(
        Tasks.updateStatus({ taskId, status }, realEstateAgencyId)
      );

      if (refresh) {
        const tasks = await parseRequest.response(
          Tasks.search(
            {
              orderBy: TaskOrderByField.DefaultOrder,
              includeStatistics: true,
              filterByActive: ActiveFilter.ActiveOrInactive,
              skip: 0,
              take,
              order: SortOrder.Ascending,
              statusFilter,
              employeeId:
                delegateFilter === TaskDelegateFilter.Delegated
                  ? employeeId
                  : employeeFilter,
              categoryIds: categoryFilter,
              delegateFilter,
            },
            realEstateAgencyId
          )
        );

        dispatch(TaskActions.setTasks(tasks, take));

        if (showUndo) {
          dispatch(
            SnackbarActions.addToast({
              value: "task.toast.edited",
              values: { subject: StringUtil.trim(task.subject, 48) },
              callback: async () => {
                await dispatch(
                  updateStatus(taskId, task.status, true, false, task)
                );
              },
              callbackLabel: "task.toast.action.undelete",
              icon: "undo",
            })
          );
        }
      }
    } catch (error) {
      throw error;
    }
  };
};

const save = (task: Task, path: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(TaskActions.setTaskStatus({ status: REQUEST.PENDING }));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(Tasks.save({ task }, realEstateAgencyId));

      dispatch(EditableThunks.remove(path));
      dispatch(TaskActions.setTaskStatus({ status: REQUEST.IDLE }));
    } catch (error) {
      dispatch(TaskActions.setTaskStatus({ status: REQUEST.ERROR }));
      throw error;
    }
  };
};

const getTask = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(TaskActions.setCurrentTaskStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      const task = await parseRequest.response(
        Tasks.read(id, realEstateAgencyId).then((response) => response.task)
      );

      dispatch(TaskActions.setCurrentTask({ task }));
    } catch (error) {
      dispatch(TaskActions.setCurrentTaskStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveAndCloseTask = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(TaskActions.setTaskStatus({ status: REQUEST.PENDING }));

    const state = getState();
    const { host } = state.appSettings;
    const task: Task = state.editable.currentComponentState;
    const path = route(TASKROUTES.TASK.URI, { id: task.id });
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(Tasks.save({ task }, realEstateAgencyId));

      dispatch(EditableThunks.remove(path));
      dispatch(TaskActions.setTaskStatus({ status: REQUEST.IDLE }));
    } catch (error) {
      dispatch(TaskActions.setTaskStatus({ status: REQUEST.ERROR }));
      throw error;
    }
  };
};

const getLinkedAssignmentRelations = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    try {
      return await parseRequest.response(
        ObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const createTaskFromEvent = (assignment: LinkedAssignment, endDate?: Date) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    try {
      let task = await Tasks.defineNew({ employeeId }, realEstateAgencyId).then(
        (response) => response.task
      );
      task = {
        ...task,
        linkedAssignments: [assignment],
        endDate,
        useReminder: true,
      };

      const path = route(TASKROUTES.TASK.URI, { id: task.id });
      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.TASKS.ICON,
          componentState: task,
          path,
          title: "...",
          confirm: {
            title: { key: "saveTaskConfirmTitle" },
            body: { key: "saveTaskConfirmBody" },
          },
          entityType: RootEntityType.Task,
          entityId: task.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const createTaskFromAppointment = (appointment: AgendaItem, intl) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const taskCategories = state.main.mastertable.kolibri.taskCategories;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    const translations = {
      from: intl.formatMessage({
        id: `scheduler.from`,
        defaultMessage: `from`,
      }),
      to: intl.formatMessage({
        id: `scheduler.to`,
        defaultMessage: `to`,
      }),
      description: intl.formatMessage({
        id: `scheduler.description`,
        defaultMessage: `description`,
      }),
      location: intl.formatMessage({
        id: `scheduler.location`,
        defaultMessage: `location`,
      }),
      none: intl.formatMessage({
        id: `scheduler.none`,
        defaultMessage: `location`,
      }),
    };

    const employee =
      appointment?.linkedEmployees && appointment?.linkedEmployees.length
        ? appointment.linkedEmployees[0]
        : undefined;

    const employeeId =
      employee.id || BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    try {
      let task = await Tasks.defineNew({ employeeId }, realEstateAgencyId).then(
        (response) => response.task
      );

      const location = appointment?.location as any;

      const description =
        translations.from +
        ":" +
        " " +
        moment(appointment?.startDateTime).format("DD-MM-YYYY HH:mm") +
        "\r\n" +
        translations.to +
        ":" +
        " " +
        moment(appointment?.endDateTime).format("DD-MM-YYYY HH:mm") +
        "\r\n" +
        translations.location +
        ":" +
        " " +
        (location?.description ? location?.description : translations.none) +
        "\r\n" +
        "--------------- \r\n" +
        translations.description +
        ":" +
        " " +
        "\r\n" +
        (appointment?.description
          ? appointment?.description
          : translations.none);

      task = {
        ...task,
        useReminder: true,
        linkedAssignments: appointment?.linkedAssignments,
        linkedRelations: appointment?.linkedRelations,
        subject: appointment?.subject,
        categoryId:
          taskCategories.find(
            (cat) =>
              cat.displayName ===
              appointment?.linkedAgendaItemCategory?.displayName
          )?.value || task?.categoryId,
        description,
      };

      const path = route(TASKROUTES.TASK.URI, { id: task.id });
      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.TASKS.ICON,
          componentState: task,
          path,
          hasChanges: true,
          title: "...",
          confirm: {
            title: { key: "saveTaskConfirmTitle" },
            body: { key: "saveTaskConfirmBody" },
          },
          entityType: RootEntityType.Task,
          entityId: task.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const createTaskWithLinkedEntities = (
  linkedRelations?: LinkedRelation[],
  linkedAssignments?: LinkedAssignment[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    try {
      let task = await parseRequest.response(
        Tasks.defineNew({ employeeId }, realEstateAgencyId).then(
          (response) => response.task
        )
      );

      const path = route(TASKROUTES.TASK.URI, { id: task.id });
      task = {
        ...task,
        useReminder: true,
        linkedAssignments,
        linkedRelations,
      };

      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.TASKS.ICON,
          componentState: task,
          path,
          title: "...",
          confirm: {
            title: { key: "saveTaskConfirmTitle" },
            body: { key: "saveTaskConfirmBody" },
          },
          entityType: RootEntityType.Task,
          entityId: task.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      throw error;
    }
  };
};

const archive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(Tasks.archive({ id }, realEstateAgencyId));

      const task = await parseRequest.response(
        Tasks.read(id, realEstateAgencyId).then((response) => response.task)
      );

      const path = route(TASKROUTES.TASK.URI, { id: task.id });

      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          hasChanges: false,
          path,
          componentState: task,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const unArchive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(Tasks.unarchive({ id }, realEstateAgencyId));

      const task = await parseRequest.response(
        Tasks.read(id, realEstateAgencyId).then((response) => response.task)
      );

      const path = route(TASKROUTES.TASK.URI, { id: task.id });

      dispatch(TaskActions.setCurrentTask({ task }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          hasChanges: false,
          path,
          componentState: task,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const deleteFromDetail = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Tasks = new TasksClient(host);

    try {
      await parseRequest.response(Tasks.delete(id, realEstateAgencyId));
      const path = route(TASKROUTES.TASK.URI, { id });
      dispatch(EditableThunks.remove(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: Task = editable.componentState;
    if (!componentState) return;

    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new TasksClient(host);
      const refTask = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.task),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: Task = {
          ...componentState,
          ...refTask,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(TaskActions.setCurrentTask({ task: newComponentState }));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refTask;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refTask,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadTask = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const task = externalChangesData.updatedEntity as Task;
    const componentState: Task = {
      ...editable.componentState,
      ...task,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(TaskActions.setCurrentTask({ task: componentState }));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateTaskListItem = (taskId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { tasks } = state.task;
    const { features } = state.appSettings;

    let ref: TaskSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.taskV2.list.tasks || []).find(
        (task) => !!task && task.id === taskId
      );
    } else {
      ref = (tasks || []).find((task) => task.id === taskId);
    }

    if (!ref) return;

    try {
      const client = new TasksClient(host);
      const task = await parseRequest.response(
        client
          .read(taskId, realEstateAgencyId)
          .then((response) => response?.task)
      );
      if (!task) return;

      const snapshot = mapTaskToTaskSnapShot(task);

      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(taskActionsV2.List.updateListItem(snapshot));
      } else {
        dispatch(TaskActions.updateListItem({ snapshot }));
      }
    } catch (error) {
      throw error;
    }
  };
};

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { filters, order } = state.taskV2.list;
      const {
        filterByActive: refFilterByActive,
        taskStatuses,
        taskCategories,
        employees,
        delegated,
      } = filters;
      const client = new TasksClient(host);

      const filterByActive: ActiveFilter =
        refFilterByActive.value.length === 1
          ? head(refFilterByActive.value)
          : ActiveFilter.ActiveOrInactive;

      const response = await parseRequest.response(
        client.search(
          {
            includeStatistics: false,
            orderBy: order.sortColumn,
            filterByActive,
            order: order.sortOrder,
            skip: startIndex,
            take: stopIndex - startIndex,
            categoryIds: taskCategories.value,
            employeeIds: employees.value,
            statusFilter: taskStatuses.value,
            delegateFilter:
              delegated.value && delegated.value.length
                ? delegated.value[0]
                : undefined,
          },
          realEstateAgencyId
        )
      );

      if (!response) {
        return;
      }

      const { results, totalResults } = response;
      dispatch(
        taskActionsV2.List.updateList(startIndex, results || [], totalResults)
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateListItemStatus = (
  snapshot: TaskSnapShot,
  status: TaskStatus,
  showUndo = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new TasksClient(host);

      let updatedSnapshot: TaskSnapShot = {
        ...snapshot,
        status,
      };

      dispatch(taskActionsV2.List.updateListItem(updatedSnapshot));

      await parseRequest.response(
        client.updateStatus({ taskId: snapshot.id, status }, realEstateAgencyId)
      );

      updatedSnapshot = await parseRequest.response(
        client
          .search(
            {
              includeStatistics: false,
              orderBy: TaskOrderByField.DefaultOrder,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              taskId: snapshot.id,
            },
            realEstateAgencyId
          )
          .then((response) => head(response.results))
      );

      if (showUndo) {
        dispatch(
          SnackbarActions.addToast({
            value: "task.toast.edited",
            values: { subject: StringUtil.trim(snapshot.subject, 48) },
            callback: async () => {
              await dispatch(
                updateListItemStatus(snapshot, snapshot.status, false)
              );
            },
            callbackLabel: "task.toast.action.undelete",
            icon: "undo",
          })
        );
      }

      if (!updatedSnapshot) return;
      dispatch(taskActionsV2.List.updateListItem(updatedSnapshot));
    } catch (error) {
      throw error;
    }
  };
};

export const TaskThunks = {
  getListItems,
  getTasks,
  updateStatus,
  updateWidgetItemStatus,
  createTask,
  save,
  getTask,
  deleteTask,
  unDeleteTask,
  getUpcomingTasks,
  saveAndCloseTask,
  getLinkedAssignmentRelations,
  createTaskFromEvent,
  createTaskWithLinkedEntities,
  archive,
  unArchive,
  deleteFromDetail,
  checkExternalChanges,
  reloadTask,
  updateTaskListItem,
  updateListItemStatus,
  createTaskFromAppointment,
};
