import { connect, MapStateToProps } from "react-redux";
import {
  UserFoldersComponent,
  UserFoldersComponentProps
} from "./user-folders";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { Account } from "@haywork/api/mail";
import { folders } from "../../selectors";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {
  accounts: Account[];
  folders: EmailFolder[];
  currentFolder: string;
};
type DispatchProps = {
  moveFolder: (id: string, newParentId: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  UserFoldersComponentProps,
  AppState
> = (state) => {
  const { accounts } = state.emailV2.accounts;
  const { currentFolder } = state.emailV2.main;

  return {
    accounts,
    folders: folders(state),
    currentFolder
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  UserFoldersComponentProps
> = (dispatch) => ({
  moveFolder: (id: string, newParentId: string) =>
    dispatch(EmailThunk.Folders.moveFolder(id, newParentId))
});

export type UserFoldersContainerProps = StateProps & DispatchProps;
export const UserFoldersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFoldersComponent);
