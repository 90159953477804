import { ObjectAssignment, OpenHouse } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentEditMarketingOpenHouseContainerProps } from "@haywork/modules/assignment";
import { OpenHouseComponent } from "@haywork/modules/shared";
import { SingleAssignmentState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-open-house.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentEditMarketingOpenHouseComponentProps {}
type Props = AssignmentEditMarketingOpenHouseComponentProps &
  AssignmentEditMarketingOpenHouseContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingOpenHouseComponent extends React.Component<
  Props
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div>
        <OpenHouseComponent
          openHouse={this.props.objectAssignment.openHouse}
          languages={this.props.languages}
          openHouseChanged={this.onChangeHandler}
          defaultLanguage={this.props.defaultLanguage}
          dateTimeModified={this.props.objectAssignment.dateTimeModified}
        />
      </div>
    );
  }

  private onChangeHandler(openHouse: OpenHouse) {
    const { objectAssignment, currentComponentState } = this.props;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id
    });

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      openHouse
    };

    const newState: SingleAssignmentState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment
    };

    this.props.updateAssignment(newState, path);
  }
}
