import * as React from "react";
import { FC, memo, useState, useMemo, useRef } from "react";
import * as CSSModules from "react-css-modules";
import { DuplicateModalContainerProps } from "./duplicate-modal.container";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import { ButtonLoader } from "@haywork/modules/shared";
import I18n from "@haywork/components/i18n";
import { AssignmentAction } from "../detail-general/actions";
import {
  Form,
  FormControls,
  Input,
  FormReference,
} from "@haywork/modules/form";
import classNames from "classnames";

const styles = require("./style.scss");

enum KeyAction {
  Keep = "Keep",
  Remove = "Remove",
  Migrate = "Migrate",
  KeepAndMigrate = "KeepAndMigrate",
}

export type DuplicateModalComponentProps = {
  assignmentId: string;
  isActive: boolean;
  visible: boolean;
  hasKeyNr: boolean;
  duplicateAction: AssignmentAction | null;
  forSale: boolean;
  onClose: () => void;
};
type Props = DuplicateModalComponentProps & DuplicateModalContainerProps;

export const DuplicateModalComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      assignmentId,
      isActive,
      visible,
      hasKeyNr,
      duplicateAction,
      forSale,
      onClose,
      duplicateAssignment,
    }) => {
      const [loading, setLoading] = useState(false);
      const form = useRef<FormReference>(null);

      const onCloseHandler = () => {
        if (!!form.current) {
          form.current.update({ keyAction: KeyAction.Migrate });
        }
        onClose();
      };

      const onDuplicate = async () => {
        try {
          setLoading(true);

          const shouldArchive =
            isActive &&
            ((forSale &&
              duplicateAction === AssignmentAction.DuplicateAsSale) ||
              (!forSale &&
                duplicateAction === AssignmentAction.DuplicateAsRent));
          const duplicateAsForSale =
            duplicateAction === AssignmentAction.DuplicateAsSale;
          let shouldCopyKeyNumber = false;
          let shouldClearKeyNumber = false;

          if (!!form.current) {
            const { keyAction } = form.current.getValues();

            shouldCopyKeyNumber = [
              KeyAction.Migrate,
              KeyAction.KeepAndMigrate,
            ].includes(keyAction);

            shouldClearKeyNumber = [
              KeyAction.Migrate,
              KeyAction.Remove,
            ].includes(keyAction);
          }

          await duplicateAssignment(
            assignmentId,
            shouldArchive,
            duplicateAsForSale,
            shouldCopyKeyNumber,
            shouldClearKeyNumber
          );

          onCloseHandler();
        } catch (error) {
          throw error;
        } finally {
          setLoading(false);
        }
      };

      const formControls = useMemo(() => {
        return {
          keyAction: { value: KeyAction.Migrate },
        } as FormControls;
      }, []);

      let body: string;

      switch (true) {
        case isActive &&
          forSale &&
          duplicateAction === AssignmentAction.DuplicateAsSale: {
          body = "assignment.duplicateModal.saleBody";
          break;
        }
        case isActive &&
          !forSale &&
          duplicateAction === AssignmentAction.DuplicateAsRent: {
          body = "assignment.duplicateModal.rentBody";
          break;
        }
        default: {
          break;
        }
      }

      return (
        <Modal visible={visible}>
          <ModalHeader title="assignment.duplicateModal.title" />
          <ModalBody>
            {!!body && (
              <div className="form__row">
                <I18n value={body} asHtml />
              </div>
            )}

            {hasKeyNr && (
              <div styleName={classNames("key", { single: !body })}>
                <Form
                  name="assignment.duplicateModal"
                  formControls={formControls}
                  form={(ref) => (form.current = ref)}
                >
                  <div className="form__row">
                    <I18n value="assignment.duplicateModal.hasKey" asHtml />
                  </div>

                  <div className="form__row">
                    <Input.RadioGroup
                      name="keyAction"
                      asButtonList
                      disabled={loading}
                    >
                      <Input.Radio
                        label={`keyAction.${KeyAction.Remove.toString()}`}
                        value={KeyAction.Remove}
                      />
                      <Input.Radio
                        label={`keyAction.${KeyAction.Keep.toString()}`}
                        value={KeyAction.Keep}
                      />
                      <Input.Radio
                        label={`keyAction.${KeyAction.KeepAndMigrate.toString()}`}
                        value={KeyAction.KeepAndMigrate}
                      />
                      <Input.Radio
                        label={`keyAction.${KeyAction.Migrate.toString()}`}
                        value={KeyAction.Migrate}
                      />
                    </Input.RadioGroup>
                  </div>
                </Form>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-blank"
              onClick={onCloseHandler}
            >
              <I18n value="assignment.duplicateModal.cancel" />
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onDuplicate}
              disabled={loading}
            >
              <ButtonLoader
                loading={loading}
                resourceKey="assignment.duplicateModal.action"
              />
            </button>
          </ModalFooter>
        </Modal>
      );
    }
  )
);
