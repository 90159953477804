import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum AcquisitionAssignmentEditAction {
  SaveAndClose = "SaveAndClose",
  BackToDashboard = "BackToDashboard"
}

type Props = {
  loading: boolean;
  isNew: boolean;
  onClick: (action: AcquisitionAssignmentEditAction) => void;
};

export const AcquisitionAssignmentEditActionsComponent: FC<Props> = memo(
  ({ loading, isNew, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const onClickHandler = (action: AcquisitionAssignmentEditAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />

          <Action
            label="acquisitionAssignment.edit.actions.label.saveAndClose"
            icon={
              <Icon
                light
                name="save"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionAssignmentEditAction.SaveAndClose)
            }
          />

          {!isNew && (
            <Action
              label="acquisitionAssignment.edit.actions.label.backToDashboard"
              icon={
                <Icon
                  light
                  name="tachometer-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionAssignmentEditAction.BackToDashboard)
              }
            />
          )}
        </ActionList>
      </>
    );
  }
);

export default AcquisitionAssignmentEditActionsComponent;
