import {
  ApartmentCharacteristic,
  ApartmentSort,
  HouseCharacteristic,
  HouseSort,
  HouseType,
  ListingType,
  ParkingType,
  RealEstateGroup,
  ResidentialSubtypeOther,
} from "@haywork/api/kolibri";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useReducer } from "react";
import * as CSSModules from "react-css-modules";
import { ListingTypeContainerProps } from "./listing-type.container";

const styles = require("./style.scss");

type ListingTypeState = {
  listingType: ListingType | null;
  houseSort: HouseSort | null;
  houseType: HouseType | null;
  houseCharacteristic: HouseCharacteristic | null;
  apartmentSort: ApartmentSort | null;
  apartmentCharacteristic: ApartmentCharacteristic | null;
  parkingType: ParkingType | null;
  residentialSubtypeOther: ResidentialSubtypeOther | null;
};
type ListingTypeAction = {
  type: string;
  payload: { [key: string]: any };
};
const listingTypeReducer = (
  state: ListingTypeState,
  action: ListingTypeAction
): ListingTypeState => {
  switch (action.type) {
    case "update": {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

type ListingTypeListState = {
  houseSortVisible: boolean;
  houseTypeVisible: boolean;
  houseCharacteristicVisible: boolean;
  apartmentSortVisible: boolean;
  apartmentCharacteristicVisible: boolean;
  parkingTypeVisible: boolean;
  residentialSubtypeOtherVisible: boolean;
};
const listingTypeListReducer = (
  state: ListingTypeListState,
  action: ListingTypeAction
): ListingTypeListState => {
  switch (action.type) {
    case "update": {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

export type ListingTypeResponse = {
  listingType?: ListingType;
  houseSort?: HouseSort;
  houseType?: HouseType;
  houseCharacteristic?: HouseCharacteristic;
  apartmentSort?: ApartmentSort;
  apartmentCharacteristic?: ApartmentCharacteristic;
  parkingType?: ParkingType;
  residentialSubtypeOther?: ResidentialSubtypeOther;
};
export type ListingTypeComponentProps = {
  realEstateGroup: RealEstateGroup;
  listingType?: ListingType;
  houseSort?: HouseSort;
  houseType?: HouseType;
  houseCharacteristic?: HouseCharacteristic;
  apartmentSort?: ApartmentSort;
  apartmentCharacteristic?: ApartmentCharacteristic;
  parkingType?: ParkingType;
  residentialSubtypeOther?: ResidentialSubtypeOther;
  isNew: boolean;
  onChange?: (response: ListingTypeResponse) => void;
};
type Props = ListingTypeComponentProps & ListingTypeContainerProps;

export const ListingTypeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      realEstateGroup,
      listingType,
      houseSort,
      houseType,
      houseCharacteristic,
      apartmentSort,
      apartmentCharacteristic,
      parkingType,
      residentialSubtypeOther,
      listingTypes,
      houseSorts,
      houseTypes,
      houseCharacteristics,
      apartmentSorts,
      apartmentCharacteristics,
      parkingTypeOptions,
      residentialSubtypeOthers,
      onChange,
      isNew,
    }) => {
      const filteredListingTypes = useMemo(() => {
        return (listingTypes || []).filter((listingType) =>
          (listingType.realEstateGroups || []).includes(realEstateGroup)
        );
      }, [realEstateGroup, listingTypes]);
      const [state, setState] = useReducer(listingTypeReducer, {
        listingType: listingType || null,
        houseSort: houseSort || null,
        houseType: houseType || null,
        houseCharacteristic: houseCharacteristic || null,
        apartmentSort: apartmentSort || null,
        apartmentCharacteristic: apartmentCharacteristic || null,
        parkingType: parkingType || null,
        residentialSubtypeOther: residentialSubtypeOther || null,
      });
      const [list, setList] = useReducer(listingTypeListReducer, {
        houseSortVisible:
          realEstateGroup === RealEstateGroup.Residential &&
          listingType === ListingType.House,
        houseTypeVisible:
          realEstateGroup === RealEstateGroup.Residential && !!houseSort,
        houseCharacteristicVisible:
          realEstateGroup === RealEstateGroup.Residential && !!houseType,
        apartmentSortVisible:
          realEstateGroup === RealEstateGroup.Residential &&
          listingType === ListingType.Apartment,
        apartmentCharacteristicVisible:
          realEstateGroup === RealEstateGroup.Residential && !!apartmentSort,
        parkingTypeVisible:
          realEstateGroup === RealEstateGroup.Residential &&
          listingType === ListingType.Parking,
        residentialSubtypeOtherVisible:
          realEstateGroup === RealEstateGroup.Residential &&
          listingType === ListingType.Other,
      });

      const setListingType = useCallback(
        (listingType: ListingType) => {
          if (!isNew || listingType === state.listingType) return;
          let payload: { [key: string]: any } = {
            listingType,
          };

          if (realEstateGroup === RealEstateGroup.Residential) {
            payload = {
              ...payload,
              houseSort: null,
              houseType: null,
              houseCharacteristic: null,
              apartmentSort: null,
              apartmentCharacteristic: null,
              parkingType: null,
              residentialSubtypeOther: null,
            };
          }

          setState({ type: "update", payload });
        },
        [state.listingType, realEstateGroup]
      );

      const setHouseSort = useCallback(
        (houseSort: HouseSort) => {
          if (houseSort === state.houseSort) return;
          const payload: { [key: string]: any } = {
            houseSort,
            houseType: null,
            houseCharacteristic: null,
          };

          setState({ type: "update", payload });
        },
        [state.houseSort]
      );

      const setHouseType = useCallback(
        (houseType: HouseType) => {
          if (houseType === state.houseType) return;
          const payload: { [key: string]: any } = {
            houseType,
            houseCharacteristic: null,
          };

          setState({ type: "update", payload });
        },
        [state.houseType]
      );

      const setHouseCharacteristic = useCallback(
        (houseCharacteristic: HouseCharacteristic) => {
          if (houseCharacteristic === state.houseCharacteristic) return;
          const payload: { [key: string]: any } = {
            houseCharacteristic,
          };

          setState({ type: "update", payload });
        },
        [state.houseCharacteristic]
      );

      const setApartmentSort = useCallback(
        (apartmentSort: ApartmentSort) => {
          if (apartmentSort === state.apartmentSort) return;
          const payload: { [key: string]: any } = {
            apartmentSort,
            apartmentCharacteristic: null,
          };

          setState({ type: "update", payload });
        },
        [state.apartmentSort]
      );

      const setApartmentCharacteristic = useCallback(
        (apartmentCharacteristic: ApartmentCharacteristic) => {
          if (apartmentCharacteristic === state.apartmentCharacteristic) return;
          const payload: { [key: string]: any } = {
            apartmentCharacteristic,
          };

          setState({ type: "update", payload });
        },
        [state.apartmentCharacteristic]
      );

      const setParkingType = useCallback(
        (parkingType: ParkingType) => {
          if (parkingType === state.parkingType) return;
          const payload: { [key: string]: any } = {
            parkingType,
          };

          setState({ type: "update", payload });
        },
        [state.parkingType]
      );

      const setResidentialSubtypeOther = useCallback(
        (residentialSubtypeOther: ResidentialSubtypeOther) => {
          if (residentialSubtypeOther === state.residentialSubtypeOther) return;
          const payload: { [key: string]: any } = {
            residentialSubtypeOther,
          };

          setState({ type: "update", payload });
        },
        [state.residentialSubtypeOther]
      );

      useEffect(() => {
        const isResidential = realEstateGroup === RealEstateGroup.Residential;

        setList({
          type: "update",
          payload: {
            houseSortVisible:
              isResidential && state.listingType === ListingType.House,
            houseTypeVisible: isResidential && !!state.houseSort,
            houseCharacteristicVisible: isResidential && !!state.houseType,
            apartmentSortVisible:
              isResidential && state.listingType === ListingType.Apartment,
            apartmentCharacteristicVisible:
              isResidential && !!state.apartmentSort,
            parkingTypeVisible:
              isResidential && state.listingType === ListingType.Parking,
            residentialSubtypeOtherVisible:
              isResidential && state.listingType === ListingType.Other,
          },
        });
      }, [state, realEstateGroup]);

      useEffect(() => {
        if (!onChange) return;
        onChange(state);
      }, [state]);

      return (
        <div
          styleName={classNames("listing-type", {
            spread: realEstateGroup !== RealEstateGroup.Residential,
          })}
        >
          {/* Listing type */}
          <div
            styleName={classNames("column", {
              spread: realEstateGroup !== RealEstateGroup.Residential,
              disabled: !isNew,
            })}
          >
            {filteredListingTypes.map((listingType) => (
              <div
                key={listingType.id}
                styleName={classNames("item", {
                  active: listingType.value === state.listingType,
                })}
                onClick={() => setListingType(listingType.value)}
              >
                {listingType.displayName}
              </div>
            ))}
          </div>

          {/* House sort */}
          {list.houseSortVisible && (
            <div styleName="column">
              {houseSorts.map((houseSort) => (
                <div
                  key={houseSort.id}
                  styleName={classNames("item", {
                    active: houseSort.value === state.houseSort,
                  })}
                  onClick={() => setHouseSort(houseSort.value)}
                >
                  {houseSort.displayName}
                </div>
              ))}
            </div>
          )}

          {/* House type */}
          {list.houseTypeVisible && (
            <div styleName="column">
              {houseTypes.map((houseType) => (
                <div
                  key={houseType.id}
                  styleName={classNames("item", {
                    active: houseType.value === state.houseType,
                  })}
                  onClick={() => setHouseType(houseType.value)}
                >
                  {houseType.displayName}
                </div>
              ))}
            </div>
          )}

          {/* House characteristic */}
          {list.houseCharacteristicVisible && (
            <div styleName="column">
              {houseCharacteristics.map((houseCharacteristic) => (
                <div
                  key={houseCharacteristic.id}
                  styleName={classNames("item", {
                    active:
                      houseCharacteristic.value === state.houseCharacteristic,
                  })}
                  onClick={() =>
                    setHouseCharacteristic(houseCharacteristic.value)
                  }
                >
                  {houseCharacteristic.displayName}
                </div>
              ))}
            </div>
          )}

          {/* Apartment sort */}
          {list.apartmentSortVisible && (
            <div styleName="column">
              {apartmentSorts.map((apartmentSort) => (
                <div
                  key={apartmentSort.id}
                  styleName={classNames("item", {
                    active: apartmentSort.value === state.apartmentSort,
                  })}
                  onClick={() => setApartmentSort(apartmentSort.value)}
                >
                  {apartmentSort.displayName}
                </div>
              ))}
            </div>
          )}

          {/* Apartment characteristic */}
          {list.apartmentCharacteristicVisible && (
            <div styleName="column">
              {apartmentCharacteristics.map((apartmentCharacteristic) => (
                <div
                  key={apartmentCharacteristic.id}
                  styleName={classNames("item", {
                    active:
                      apartmentCharacteristic.value ===
                      state.apartmentCharacteristic,
                  })}
                  onClick={() =>
                    setApartmentCharacteristic(apartmentCharacteristic.value)
                  }
                >
                  {apartmentCharacteristic.displayName}
                </div>
              ))}
            </div>
          )}

          {/* Parking type */}
          {list.parkingTypeVisible && (
            <div styleName="column">
              {parkingTypeOptions.map((parkingType) => (
                <div
                  key={parkingType.id}
                  styleName={classNames("item", {
                    active: parkingType.value === state.parkingType,
                  })}
                  onClick={() => setParkingType(parkingType.value)}
                >
                  {parkingType.displayName}
                </div>
              ))}
            </div>
          )}

          {/* Residential subtype */}
          {list.residentialSubtypeOtherVisible && (
            <div styleName="column">
              {residentialSubtypeOthers.map((residentialSubtype) => (
                <div
                  key={residentialSubtype.id}
                  styleName={classNames("item", {
                    active:
                      residentialSubtype.value ===
                      state.residentialSubtypeOther,
                  })}
                  onClick={() =>
                    setResidentialSubtypeOther(residentialSubtype.value)
                  }
                >
                  {residentialSubtype.displayName}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
