import { FolderCategory } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { DragDropType } from "@haywork/enum/drag-drop";
import { EmailFolder } from "@haywork/stores/email-v2";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import {
  FC,
  memo,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { useDrag, useDrop } from "react-dnd";
import Folder from ".";
import { EmailContext } from "../../email.context";
import { FolderContainerProps } from "./folder.container";

const styles = require("./style.scss");
const FolderCategoryDropBlacklist = [
  FolderCategory.Drafts,
  FolderCategory.Sent,
];
const recursive = (folders: EmailFolder[], folderId: string) => {
  if (!folders.length) return [];

  let subFolders = folders.filter((folder) => folder.parentId === folderId);

  if (!subFolders.length) return [];
  subFolders.map((folder) => {
    subFolders = [...subFolders, ...recursive(folders, folder.id)];
  });

  return subFolders;
};

export type FolderComponentProps = {
  folder: EmailFolder;
  groupedFolder?: boolean;
  depth?: number;
  parentExpanded: boolean;
  onDelete?: (id: string, displayName: string) => void;
};
type Props = FolderComponentProps & FolderContainerProps;

export const FolderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      folder,
      accounts,
      currentFolder,
      groupedFolder,
      setCurrentFolder,
      folders,
      depth,
      moveToFolder,
      parentExpanded,
      moveFolder,
      onDelete,
    }) => {
      const { category, unreadCount, id, accountId, internallyCreatedOn } =
        folder;
      const [droppable, setDroppable] = useState(false);
      const [subFoldersVisible, setSubFoldersVisible] = useState(false);
      const ref = useRef<HTMLDivElement>();
      const { onAddFolder, onUpdateFolder } = useContext(EmailContext);
      const forceVisibleTimeout = useRef<any>(null);
      const [hovering, setHovering] = useState(false);

      const subFolderIds = useMemo(() => {
        let subFolders = folders.filter((folder) => folder.parentId === id);
        if (!subFolders.length) return [];
        subFolders = [...subFolders, ...recursive(folders, id)];

        return subFolders.map((folder) => folder.id);
      }, [folders, id]);

      const isBlockedFromActions = useCallback(() => {
        if (!internallyCreatedOn) {
          return false;
        }

        const now = new Date();
        const diffInSeconds = moment(now).diff(internallyCreatedOn, "seconds");

        return diffInSeconds < 30;
      }, [internallyCreatedOn]);

      const [dragProps, drag] = useDrag({
        item: {
          type: DragDropType.EmailFolder,
          id: folder.id,
          accountId: folder.accountId,
          subFolderIds,
        },
        canDrag: () => {
          if (isBlockedFromActions()) {
            return false;
          }

          return category === FolderCategory.UserCreated;
        },
      });

      const [dropProps, drop] = useDrop({
        accept: [DragDropType.Email, DragDropType.EmailFolder],
        canDrop: (item) => {
          if (isBlockedFromActions()) {
            return false;
          }

          switch (item.type) {
            case DragDropType.Email: {
              const { accountId: itemAccountId } = item as any;
              return (
                !FolderCategoryDropBlacklist.includes(category) &&
                itemAccountId === accountId
              );
            }
            case DragDropType.EmailFolder: {
              const {
                id: refId,
                accountId: refAccountId,
                subFolderIds: refSubFolderIds,
              } = item as any;
              return (
                category === FolderCategory.UserCreated &&
                !!refId &&
                refId !== id &&
                !!refAccountId &&
                refAccountId === accountId &&
                !refSubFolderIds.includes(id)
              );
            }
            default: {
              return false;
            }
          }
        },
        drop: (item) => {
          switch (item.type) {
            case DragDropType.Email: {
              const { id: refId } = item as any;
              moveToFolder(refId, id);
              return;
            }
            case DragDropType.EmailFolder: {
              const { id: refId } = item as any;
              moveFolder(refId, id);
              return;
            }
            default: {
              return;
            }
          }
        },
        collect: (monitor) => {
          if (monitor.canDrop() && monitor.isOver()) {
            setDroppable(true);
            forceVisibleTimeout.current = setTimeout(() => {
              setSubFoldersVisible(true);
            }, 500);
          } else {
            setDroppable(false);
            if (!!forceVisibleTimeout.current) {
              clearTimeout(forceVisibleTimeout.current);
              forceVisibleTimeout.current = null;
            }
          }
        },
      });

      const count = useMemo(() => {
        return category === FolderCategory.Inbox && // Unread count is only for items in the inbox as per decision
          !!unreadCount &&
          unreadCount > 0
          ? unreadCount
          : 0;
      }, [category, unreadCount]);

      const icon = useMemo(() => {
        const color = droppable ? Colors.White : Colors.Gray;

        switch (category) {
          case FolderCategory.Inbox: {
            return (
              <Icon
                name="inbox"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          case FolderCategory.Sent: {
            return (
              <Icon
                name="paper-plane"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          case FolderCategory.Trash: {
            return (
              <Icon
                name="trash-alt"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          case FolderCategory.Archive: {
            return (
              <Icon
                name="archive"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          case FolderCategory.Spam: {
            return (
              <Icon name="ban" size={16} containIn={20} color={color} regular />
            );
          }
          case FolderCategory.Important: {
            return (
              <Icon
                name="exclamation-circle"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          case FolderCategory.Drafts: {
            return (
              <Icon
                name="edit"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
          default: {
            return (
              <Icon
                name="folder"
                size={16}
                containIn={20}
                color={color}
                regular
              />
            );
          }
        }
      }, [category, droppable]);

      const subFolders = useMemo(() => {
        return folders.filter((subFolder) => subFolder.parentId === id);
      }, [folders, id]);

      const folderStyleName = useMemo(() => {
        return classNames("folder", !depth ? undefined : `depth${depth}`, {
          active: id === currentFolder,
          grouped: !!groupedFolder,
          droppable,
          expandable: !!subFolders.length,
          hover: hovering,
        });
      }, [
        id,
        currentFolder,
        groupedFolder,
        droppable,
        depth,
        subFolders,
        hovering,
      ]);

      const addFolderCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          onAddFolder(id, accountId);
        },
        [id, accountId, onAddFolder]
      );

      const updateFolderCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          onUpdateFolder(id);
        },
        [id, onUpdateFolder]
      );

      const displayName = useMemo(() => {
        const folderName = folder.displayName || "email.unknownFolder";
        if (!groupedFolder) return folderName;

        const account = accounts.find(
          (account) => account.id === folder.accountId
        );
        return !account
          ? folderName
          : account.accountName || account.displayName || folderName;
      }, [accounts, folder, groupedFolder]);

      const removeFolderCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          if (typeof onDelete === "function") onDelete(id, displayName);
        },
        [id, displayName, onDelete]
      );

      const tools = useMemo(() => {
        if (hovering && isBlockedFromActions()) {
          return null;
        }

        if (category !== FolderCategory.UserCreated) {
          return null;
        }

        const color = droppable ? Colors.White : Colors.Gray;

        return (
          <div styleName="tools">
            <div styleName="tool" onClick={updateFolderCallback}>
              <Icon name="pencil" size={14} containIn={16} color={color} />
            </div>

            {(!depth || depth < 4) && (
              <div styleName="tool" onClick={addFolderCallback}>
                <Icon
                  name="plus-circle"
                  size={14}
                  containIn={16}
                  color={color}
                />
              </div>
            )}

            {(!subFolders || !subFolders.length) && (
              <div styleName="tool" onClick={removeFolderCallback}>
                <Icon name="times" size={14} containIn={16} color={color} />
              </div>
            )}
          </div>
        );
      }, [
        category,
        depth,
        subFolders,
        addFolderCallback,
        updateFolderCallback,
        removeFolderCallback,
        droppable,
        hovering,
        isBlockedFromActions,
      ]);

      const toggleCurrentFolder = useCallback(() => {
        if (folder.id === currentFolder) {
          return;
        }
        setCurrentFolder(folder);
      }, [folder, currentFolder, setCurrentFolder]);

      const toggleSubFolders = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          if (subFoldersVisible) toggleCurrentFolder();
          setSubFoldersVisible(!subFoldersVisible);
        },
        [subFoldersVisible, setSubFoldersVisible, toggleCurrentFolder]
      );

      useEffect(() => {
        if (!parentExpanded) {
          setSubFoldersVisible(false);
        }
      }, [setSubFoldersVisible, parentExpanded]);

      useEffect(() => {
        const onMouseEnter = () => {
          setHovering(true);
        };
        const onMouseLeave = () => {
          setHovering(false);
        };

        ref.current?.addEventListener("mouseenter", onMouseEnter, true);
        ref.current?.addEventListener("mouseleave", onMouseLeave, true);

        return () => {
          ref.current?.removeEventListener("mouseenter", onMouseEnter, true);
          ref.current?.removeEventListener("mouseleave", onMouseLeave, true);
        };
      }, []);

      drag(drop(ref));

      return (
        <div>
          <div
            styleName={folderStyleName}
            onClick={toggleCurrentFolder}
            ref={ref}
          >
            {!!subFolders.length && (
              <div onClick={toggleSubFolders}>
                <Icon
                  name={subFoldersVisible ? "caret-down" : "caret-right"}
                  size={14}
                  containIn={16}
                  color={droppable ? Colors.White : Colors.Gray}
                />
              </div>
            )}
            <div styleName="icon">{icon}</div>
            <div styleName="label">
              <I18n value={displayName} />
            </div>
            {tools}
            {!!count && <div styleName="count">{count}</div>}
          </div>
          {!!subFoldersVisible && (
            <div styleName="subfolders">
              {subFolders.map((subFolder) => (
                <Folder
                  folder={subFolder}
                  key={subFolder.id}
                  depth={!depth ? 1 : depth + 1}
                  parentExpanded={subFoldersVisible}
                  onDelete={onDelete}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
