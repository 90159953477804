export default `#var uid = target.attr("data-uid");#
    #var element = target.is(".k-task") ? target : target.parent();#
    #var uid = element.attr("data-uid");#
    #var scheduler = target.closest("[data-role=scheduler]").data("kendoScheduler");#
    #var intl = sender.element.data('intl');#
    #var getAgendaItem = sender.element.data('getAgendaItem');#
    #var model = scheduler.occurrenceByUid(uid);#
    #var categoryResource = scheduler.resources.filter(function(item){return item.name === 'category_resource';});#
    #var catRes = categoryResource[0];#
    #var category = catRes.dataSource.data().filter(function(item){#
        #return item.id === model.categoryId;#
    #})[0]#
    #var categoryTitle = ''#
    #if(category){#
        #categoryTitle = category.displayName;#
    #}#

    #var relations = model.linkedRelations#
    #var assignments = model.linkedAssignments#
    #var employees = model.linkedEmployees#

    #var assignmentsKeyData = [];#

    #if(assignments && assignments.length > 0){#
        #for(var i = 0; i < assignments.length; i++){#
            #var assignment = assignments[i];#
            #if(assignments[i] && assignments[i].keyNr){#
                #assignmentsKeyData.push({
                    displayName: assignment.displayName || "",
                    keyNr: assignment.keyNr,
                    keyNote: assignment.keyNote || ""
                })#
            #}#
        #}#
    #}#

    #if(model) {#
        <table class="schedulerTooltip">
            <tr>
                <td>#:intl.formatMessage({id: "schedulerTooltipWhat"})#</td>
                <td>#:model.title#</td>
            </tr>

            #if(!model.isAllDay){#
              <tr>
                <td>#:intl.formatMessage({id: "schedulerTooltipWhen"})#</td>
                <td>#:kendo.toString(model.start, 't')# - #:kendo.toString(model.end, 't')#</td>
              </tr>
            #}#

            #if(employees && employees.length){#
              <tr>
                <td>#:intl.formatMessage({id: "schedulerTooltipResponsible"})#</td>
                  <td>
                    #for(var i = 0; i < employees.length; i++){#
                      #var url = "/app/employees/" + employees[i].id#
                      <a href="javascript:;" id="employeeLink" class="contactsLink schedulerTooltipLink" data-contact-id="#:employees[i].id#" data-url="#:url#">#:employees[i].displayName#</a>#if(employees.length !== (i + 1)){#, #}#
                    #}#
                </td>
              </tr>
            #}#

            #if(relations && relations.length){#
            <tr>
                <td>#:intl.formatMessage({id: "schedulerTooltipWithWho"})#</td>
                <td>
                    #for(var i = 0; i < relations.length; i++){#
                        #var urlPrefix = "";#
                        #switch(relations[i].typeOfRelation){
                            case "ContactCompany":#
                                #urlPrefix = "/app/relations/company/"#
                                #break;#
                            #case "ContactPerson":#
                                #urlPrefix = "/app/relations/person/"#
                                #break;#
                            #default:#
                                #urlPrefix = ""#
                        #}#
                        #var url = urlPrefix + relations[i].id#
                        <a href="javascript:;" id="relationLink" class="contactsLink schedulerTooltipLink" data-contact-id="#:relations[i].id#" data-url="#:url#">#:relations[i].displayName#</a>#if(relations.length !== (i + 1)){#, #}#
                    #}#
                </td>
            </tr>
            #}#
            #if(!!model.location){#
            <tr>
                <td>#:intl.formatMessage({id: "schedulerTooltipWhere"})#</td>
                <td>
                    #:model.location#
                </td>
            </tr>
           #}#

            #if(assignmentsKeyData && assignmentsKeyData.length > 1){#
                <tr><td>&nbsp;</td></tr>
                <tr><td colspan="2" class="fullWidth bold">#:intl.formatMessage({id: "schedulerTooltipKeynumbers"})#</td></tr>
                #for(var i = 0; i < assignmentsKeyData.length; i++){#
                    <tr>
                        <td class="fullWidth" colspan="2">
                            <table>
                            <tr>
                                <td class="fullWidth" colspan="2">
                                    #:assignmentsKeyData[i].keyNr# - #:assignmentsKeyData[i].displayName#
                                </td>
                            <tr>
                            #if(assignmentsKeyData[i].keyNote){#
                            <tr>
                                <td class="fullWidth" colspan="2">
                                    <span class="note">
                                        <span>#:intl.formatMessage({id: "schedulerTooltipNote"})#</span>
                                        #:assignmentsKeyData[i].keyNote#
                                    </span>
                                </td>
                            </tr>
                            #}#
                            </table>
                        </td>
                    </tr>
                #}#
            #}#
            #if(assignmentsKeyData && assignmentsKeyData.length === 1 && assignmentsKeyData[0].keyNr){#
                <tr>
                    <td>#:intl.formatMessage({id: "schedulerTooltipKeynumber"})#</td>
                    <td>
                        #:assignments[0].keyNr#
                        #if(assignments[0].keyNote){#
                        <span class="note">
                            <span>#:intl.formatMessage({id: "schedulerTooltipNote"})#</span>
                                #:assignments[0].keyNote#
                            </span>
                        #}else{#
                        #}#
                    </td>
                </tr>
            #}#
            <tr id="agendaItemDescriptionContainer" style="display:none">
                <td colspan="2">
                    <table>
                        <tr>
                            <td>#:intl.formatMessage({id: "schedulerTooltipDescription"})#</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2" id="agendaItemDescription"></td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    #} else {#
        <strong>#:intl.formatMessage({id: "schedulerTooltipNoData"})#</strong>
    #}#`;
