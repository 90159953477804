import { OpenHouse, ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { ProjectEditMarketingOpenHouseContainerProps } from "@haywork/modules/project";
import { OpenHouseComponent } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-open-house.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ProjectEditMarketingOpenHouseComponentProps {}
type Props = ProjectEditMarketingOpenHouseComponentProps &
  ProjectEditMarketingOpenHouseContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditMarketingOpenHouseComponent extends React.Component<
  Props
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div>
        <OpenHouseComponent
          openHouse={this.props.projectAssignment.openHouse}
          languages={this.props.languages}
          openHouseChanged={this.onChangeHandler}
          defaultLanguage={this.props.defaultLanguage}
          dateTimeModified={this.props.projectAssignment.dateTimeModified}
        />
      </div>
    );
  }

  private onChangeHandler(openHouse: OpenHouse) {
    const { projectAssignment, currentComponentState } = this.props;
    const path = route(PROJECTROUTES.DETAIL.URI, { id: projectAssignment.id });

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      openHouse
    };

    const newState: SingleProjectState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, path);
  }
}
