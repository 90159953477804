import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryStatistics,
  AgendaItemCategoryType,
  AgendaItemCategoryTypeOption,
  AgendaStandardDuration,
  AgendaStandardDurationOption,
} from "@haywork/api/kolibri";
import {
  MapDispatchToProps,
  SchedulerCategoriesThunk,
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsSchedulerOverviewComponent,
  SettingsSchedulerOverviewComponentProps,
} from "../components";
import {
  mappedAgendaItemCategories,
  MappedAgendaItemCategory,
  standardDurations,
} from "./selectors";

interface StateProps {
  agendaItemCategories: AgendaItemCategorySnapShot[];
  mappedAgendaItemCategories: MappedAgendaItemCategory[];
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  agendaItemCategoryStatistics: AgendaItemCategoryStatistics;
  schedulerCategoryStatus: string;
  features: string[];
}
interface DispatchProps {
  getCategories: (init: boolean) => Promise<void>;
  newCategory: (
    backColor: string,
    displayName: string,
    standardDuration: AgendaStandardDuration,
    needsToBeConfirmed: boolean,
    categoryType: AgendaItemCategoryType
  ) => Promise<void>;
  saveCategory: (category: AgendaItemCategorySnapShot) => Promise<void>;
  deleteCategory: (
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo?: AgendaItemCategorySnapShot
  ) => Promise<void>;
  navigate: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsSchedulerOverviewComponentProps,
  AppState
> = (state) => {
  const { agendaItemCategoryTypes } = state.main.mastertable.kolibri;
  const {
    agendaItemCategoryStatistics,
    agendaItemCategories,
    schedulerCategoryStatus,
  } = state.schedulerCategory;
  const { features } = state.appSettings;

  return {
    agendaItemCategories,
    mappedAgendaItemCategories: mappedAgendaItemCategories(state),
    agendaItemCategoryTypes,
    agendaStandardDurationOptions: standardDurations(state),
    agendaItemCategoryStatistics,
    schedulerCategoryStatus,
    features,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsSchedulerOverviewComponentProps
> = (dispatch) => ({
  getCategories: (init: boolean) =>
    dispatch(SchedulerCategoriesThunk.getCategories(init)),
  newCategory: (
    backColor: string,
    displayName: string,
    standardDuration: AgendaStandardDuration,
    needsToBeConfirmed: boolean,
    categoryType: AgendaItemCategoryType
  ) =>
    dispatch(
      SchedulerCategoriesThunk.newCategory(
        backColor,
        displayName,
        standardDuration,
        needsToBeConfirmed,
        categoryType
      )
    ),
  saveCategory: (category: AgendaItemCategorySnapShot) =>
    dispatch(SchedulerCategoriesThunk.saveCategoryFromSnapshot(category)),
  deleteCategory: (
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo?: AgendaItemCategorySnapShot
  ) =>
    dispatch(
      SchedulerCategoriesThunk.deleteCategory(
        categoryToDelete,
        categoryToMoveItemsTo
      )
    ),
  navigate: (path: string) => dispatch(push(path)),
});

export type SettingsSchedulerOverviewContainerProps = StateProps &
  DispatchProps;
export const SettingsSchedulerOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSchedulerOverviewComponent);
