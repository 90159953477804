import { AgendaItem } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import * as ActionType from "./scheduler.types";

const setAgendaItems = (
  payload: ActionType.AgendaItemsResponse,
  take: number
) => ({
  type: REDUX.SCHEDULER.SET_AGENDA_ITEMS,
  ...payload,
  take
});

const appendAgendaItems = (
  payload: ActionType.AgendaItemsResponse,
  take: number
) => ({
  type: REDUX.SCHEDULER.APPEND_AGENDA_ITEMS,
  ...payload,
  take
});

const setAgendaItemsForToday = (payload: ActionType.AgendaItemsResponse) => ({
  type: REDUX.SCHEDULER.SET_AGENDA_ITEMS_FOR_TODAY,
  ...payload
});

const setAgendaItem = (payload: ActionType.SingleAgendaItemResponse) => ({
  type: REDUX.SCHEDULER.SET_AGENDA_ITEM,
  ...payload
});

const setAgendaItemDirectly = (agendaItem: AgendaItem) => ({
  type: REDUX.SCHEDULER.SET_AGENDA_ITEM,
  agendaItem
});

const setAgendaItemCategories = (
  payload: ActionType.AgendaItemCategoriesResponse,
  take: number
) => ({
  type: REDUX.SCHEDULER.SET_AGENDA_ITEM_CATEGORIES,
  ...payload,
  take
});

const setSchedulerState = (schedulerState: string) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_STATE,
  schedulerState
});

const setSchedulerWidgetState = (schedulerState: string) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_WIDGET_STATE,
  schedulerState
});

const setSchedulerDetailState = (schedulerState: string) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_DETAIL_STATE,
  schedulerState
});

const setSchedulerConfirmationState = (schedulerState: string) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_CONFIRMATION_STATE,
  schedulerState
});

const setSchedulerRemoveState = (schedulerState: string) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_REMOVE_STATE,
  schedulerState
});

const setSchedulerFilters = (payload: ActionType.Filters) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_FILTERS,
  ...payload
});

const setRecurrency = (payload: ActionType.Recurrency) => ({
  type: REDUX.SCHEDULER.SET_SCHEDULER_RECURRENCY,
  ...payload
});

const updateOverviewItem = (payload: ActionType.SnapShot) => ({
  type: REDUX.SCHEDULER.UPDATE_OVERVIEW_ITEM,
  ...payload
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.SCHEDULER.DELETE_LIST_ITEM,
  ...payload
});

export const SchedulerActions = {
  setAgendaItems,
  appendAgendaItems,
  setAgendaItemsForToday,
  setAgendaItemCategories,
  setSchedulerState,
  setSchedulerConfirmationState,
  setSchedulerRemoveState,
  setSchedulerWidgetState,
  setSchedulerFilters,
  setSchedulerDetailState,
  setAgendaItem,
  setRecurrency,
  setAgendaItemDirectly,
  updateOverviewItem,
  deleteListItem
};
