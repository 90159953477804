import {
  AcquisitionObjectAssignment,
  AssignmentType,
  RelationRole,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes from "@haywork/modules/notes-v3";
import { ResourceText } from "@haywork/modules/shared";
import { AssignmentUtil, RelationUtil } from "@haywork/util";
import { LinkedRelationWithRole } from "@haywork/util/assignment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Sidebar from "../../components/assignment-detail-sidebar";
import { AssignmentDetailNetworkContainerProps } from "./detail-network.container";
import { AssignmentDetailNetworkItemComponent } from "./list-item.component";

const styles = require("./detail-network.component.scss");

export interface AssignmentDetailNetworkComponentProps {}
interface State {
  addingContact: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = AssignmentDetailNetworkComponentProps &
  AssignmentDetailNetworkContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailNetworkComponent extends React.Component<
  Props,
  State
> {
  private inputRef: HTMLInputElement;
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      addingContact: false,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedContact: { value: "", validators: [Validators.required()] },
      relationRole: { value: RelationRole.Client },
    };

    this.toggleAddingContact = this.toggleAddingContact.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.contactLinkTypeChangeHandler =
      this.contactLinkTypeChangeHandler.bind(this);
    this.contactLinkRemoveFromListHandler =
      this.contactLinkRemoveFromListHandler.bind(this);
    this.renderAddContact = this.renderAddContact.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
  }

  public render() {
    const { forSale, isNew } = this.props.currentComponentState;
    const contactLinks = AssignmentUtil.getAssignmentAroRelations(
      this.props.currentComponentState
    );

    return (
      <>
        <div styleName="sidebar">
          <Sidebar />
        </div>
        <div styleName="body">
          <div styleName="network">
            <PageHeader
              title="pageTitle.assignment.network"
              actions={
                <>
                  {!isNew && <Notes />}
                  <Button
                    label="addContact"
                    category="primary"
                    icon={<Icon name="plus" light size={18} />}
                    iconPosition="start"
                    onClick={this.toggleAddingContact}
                  />
                </>
              }
            />

            <div styleName="summary">
              <ResourceText resourceKey="aroNetworkHeader" />
            </div>

            {this.props.acquisitionAssignmentObjectStatus === REQUEST.ERROR &&
              this.renderContactAddError()}

            {this.state.addingContact && (
              <div styleName="add-contact">
                <div styleName="add-contact__avatar">
                  <div styleName="avatar" />
                </div>
                {this.renderAddContact()}
              </div>
            )}

            {contactLinks.length > 0 && (
              <div styleName="list">
                {contactLinks.map((contactLink, idx) => (
                  <ErrorBoundary
                    key={contactLink.id + contactLink.relationRole.toString()}
                  >
                    <AssignmentDetailNetworkItemComponent
                      contact={contactLink}
                      zebra={idx % 2 === 0}
                      contactLinkRemoveFromList={
                        this.contactLinkRemoveFromListHandler
                      }
                      contactLinkTypeChange={this.contactLinkTypeChangeHandler}
                      relationRoles={this.props.relationRoles}
                      navigate={this.props.navigate}
                      forSale={forSale}
                      assignmentId={this.props.currentComponentState.id}
                    />
                  </ErrorBoundary>
                ))}
              </div>
            )}
            {contactLinks.length === 0 && (
              <div styleName="emptyState">
                <div styleName="content">
                  <div styleName="icon" />
                  <div styleName="text">
                    <h2>
                      <ResourceText resourceKey="emptyStateNoNetworkTitle" />
                    </h2>
                    <p>
                      <ResourceText resourceKey="emptyStateNoNetworkText" />
                    </p>
                  </div>
                </div>
              </div>
            )}

            <NewEntity
              visible={this.state.newEntityVisible}
              options={this.state.newEntityOptions}
              onClose={this.onNewEntityCloseHandler}
              onNewRelation={this.onNewRelationHandler}
            />
          </div>
        </div>
      </>
    );
  }

  private contactLinkRemoveFromListHandler(contact: LinkedRelationWithRole) {
    const updatedAcquisitionAssignment = AssignmentUtil.removeLinkedRelation(
      this.props.currentComponentState,
      contact
    ) as AcquisitionObjectAssignment;
    this.props.saveAcquisition(updatedAcquisitionAssignment);
  }

  private contactLinkTypeChangeHandler(
    type: RelationRole,
    contact: LinkedRelationWithRole
  ) {
    const updatedAcquisitionAssignment = AssignmentUtil.updateLinkedRelation(
      this.props.currentComponentState,
      type,
      contact
    ) as AcquisitionObjectAssignment;
    this.props.saveAcquisition(updatedAcquisitionAssignment);
  }

  private toggleAddingContact() {
    this.setState({ addingContact: !this.state.addingContact }, () => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    });
  }

  private renderAddContact(): React.ReactElement<HTMLDivElement> {
    const relationRoles = RelationUtil.mapRelationRoles(
      this.props.relationRoles,
      AssignmentType.Acquisition,
      this.props.currentComponentState.forSale
    );

    return (
      <div styleName="add-contact__body">
        <Form
          formControls={this.formControls}
          name="contactLink"
          onSubmit={this.onSubmitHandler}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.RelationQuery
                  name="linkedContact"
                  filterByRelationTypes={[
                    RelationType.ContactPerson,
                    RelationType.ContactCompany,
                  ]}
                  onAdd={this.addNewRelation}
                />
              </div>
              <div styleName="function">
                <Input.NewSelect
                  name="relationRole"
                  values={relationRoles}
                  displayProp="label"
                  valuesProp="value"
                />
              </div>
            </div>
            <div styleName="action">
              <button type="submit" className="btn btn-success">
                <ResourceText resourceKey="add" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    const updatedAcquisitionAssignment = AssignmentUtil.addLinkedRelation(
      this.props.currentComponentState,
      values.relationRole,
      values.linkedContact
    ) as AcquisitionObjectAssignment;
    this.props.saveAcquisition(updatedAcquisitionAssignment);
    this.setState({ addingContact: false });
  }

  private renderContactAddError() {
    return (
      <div styleName="error" className="alert alert-danger">
        <ResourceText resourceKey="contactAlreadyExistsError" />
      </div>
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedContact: RelationSnapShot) {
    this.formRef.update({
      linkedContact,
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
