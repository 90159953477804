import { Account, ShareType } from "@haywork/api/mail";
import { AppState } from "@haywork/stores";
import head from "lodash-es/head";
import { createSelector } from "reselect";

const accountsSelector = (state: AppState) =>
  state.emailV2.accounts.accounts || [];
const mailSettingsSelector = (state: AppState) =>
  state.emailV2.main.personSettings;
const sharesSelector = (state: AppState) => state.emailV2.shares.shares || [];

export const accounts = createSelector(
  accountsSelector,
  mailSettingsSelector,
  (accounts, settings) => {
    const compareFn = (a: Account, b: Account) =>
      a.id === settings?.defaultAccountId ? -1 : 0;
    return accounts.sort(compareFn);
  }
);

const sendAccounts = createSelector(
  accounts,
  sharesSelector,
  (accounts, shares) => {
    return accounts.filter((account) => {
      const restrictions = shares.find(
        (share) => share.accountId === account.id
      );
      return ![ShareType.Read, ShareType.ReadWrite].includes(
        restrictions?.shareType
      );
    });
  }
);

export const defaultSendAccountId = createSelector(
  sendAccounts,
  mailSettingsSelector,
  (accounts, settings) => {
    if (accounts && accounts.length === 1) {
      return head(accounts).id;
    }

    const account = (accounts || []).find(
      (account) => account.id === settings?.defaultAccountId
    );

    return account?.id || head(accounts)?.id || "";
  }
);
