import {
  AppClient,
  AppClientTranslation,
  CompanySettingsSingleItemResponse,
  EmployeeSettingsSingleItemResponse,
  PermissionSnapShot
} from "@haywork/api/authorization";
import { APPXCHANGEROUTES, MAINROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AuthorizationThunks } from "@haywork/middleware/thunk/authorization";
import {
  AppXchangeDetailComponent,
  AppXchangeDetailComponentProps
} from "@haywork/modules/app-xchange";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { AppState } from "@haywork/stores";
import { AuthorizationActions } from "@haywork/stores/authorization";
import { connect, MapStateToProps } from "react-redux";
import { translation } from "./selectors";

interface StateProps {
  client: AppClient;
  translation: AppClientTranslation;
  consentModalVisible: boolean;
  consentStatus: string;
  retractConsentConfirm: boolean;
  culture: string;
  employeeSettings: EmployeeSettingsSingleItemResponse;
  companySettings: CompanySettingsSingleItemResponse;
}
interface DispatchProps {
  toggleConsentModal: (status: boolean) => void;
  toggleConsent: (appClientId: string, hasConsent: boolean) => Promise<void>;
  toggleConsentRetractConfirm: (status: boolean) => void;
  getPermissionsForClient: (id: string) => Promise<PermissionSnapShot[]>;
  getSettings: (id: string) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AppXchangeDetailComponentProps,
  AppState
> = (state) => {
  const {
    consentModalVisible,
    client,
    consentStatus,
    retractConsentConfirm,
    employeeSettings,
    companySettings
  } = state.authorization.singleAppClient;
  const { culture } = state.main;

  return {
    client,
    translation: translation(state),
    consentModalVisible,
    consentStatus,
    retractConsentConfirm,
    culture,
    employeeSettings,
    companySettings,
    currentPath: state.editable.currentPath
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AppXchangeDetailComponentProps
> = (dispatch) => ({
  toggleConsentModal: (status: boolean) =>
    dispatch(AuthorizationActions.SingleAppClient.toggleConsentModal(status)),
  toggleConsent: (appClientId: string, hasConsent: boolean) =>
    dispatch(
      AuthorizationThunks.AppClient.toggleConsent(appClientId, hasConsent)
    ),
  toggleConsentRetractConfirm: (status: boolean) =>
    dispatch(
      AuthorizationActions.SingleAppClient.toggleConsentRetractConfirm(status)
    ),
  getPermissionsForClient: (id: string) =>
    dispatch(AuthorizationThunks.Permissions.getPermissionsForClient(id)),
  getSettings: (id: string) =>
    dispatch(AuthorizationThunks.AppClient.getSettings(id))
});

export type AppXchangeDetailContainerProps = StateProps &
  DispatchProps &
  EditableHocProps;
export const AppXchangeDetailContainer = editable(
  connect(mapStateToProps, mapDispatchToProps)(AppXchangeDetailComponent),
  {
    entityType: null,
    icon: MAINROUTES.APP_XCHANGE.ICON,
    thunk: AuthorizationThunks.AppClient.getClient,
    status: "authorization.singleAppClient.clientStatus",
    statePath: "authorization.singleAppClient.client",
    action: AuthorizationActions.SingleAppClient.setClient,
    route: APPXCHANGEROUTES.DETAIL.URI
  }
);
