import * as React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@haywork/modules/modal";
import {
  Form,
  FormControls,
  FormReference,
  Input,
  FormReturnValue
} from "@haywork/modules/form";
import { TemplateDefinition } from "@haywork/api/kolibri";

import { SettingsEmailTemplateSendPreviewContainerProps } from "./send-preview.container";
import { ResourceText, ButtonLoader } from "@haywork/modules/shared";
import { AsyncUtil } from "@haywork/util";
import { PATTERN } from "@haywork/constants";

export interface SettingsEmailTemplateSendPreviewProps {
  visible: boolean;
  template: TemplateDefinition;
  onClose: () => void;
}
interface State {
  loading: boolean;
}
type Props = SettingsEmailTemplateSendPreviewProps &
  SettingsEmailTemplateSendPreviewContainerProps;

export class SettingsEmailTemplateSendPreview extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.formControls = {
      emails: { value: "" }
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    if (!this.props.template) return null;

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <Form
          name="send-preview-email"
          formControls={this.formControls}
          onSubmit={this.onSubmit}
          form={(form) => (this.form = form)}
        >
          <ModalHeader
            title="emailTemplateSendPreview.ModalTitle"
            close={true}
          />
          <ModalBody>
            <div className="form__row">
              <label htmlFor="emails">
                <ResourceText resourceKey="emailTemplateSendPreview.emailsLabel" />
              </label>
              <Input.Text
                name="emails"
                placeholder="emailTemplateSendPreview.emailsPlaceholder"
                disabled={this.state.loading}
              />
              <div className="form__hint">
                <ResourceText resourceKey="emailTemplateSendPreview.emailsHint" />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.state.loading}
            >
              <ButtonLoader
                resourceKey="emailTemplateSendPreview.sendTrigger"
                loading={this.state.loading}
              />
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }

  private async onSubmit(values: FormReturnValue) {
    if (this.state.loading) return;

    let emails = (values.emails as string).replace(/\s/g, "").split(",");
    emails = emails.filter((email) => PATTERN.EMAIL.test(email));

    if (!emails.length) return;
    const to = emails.map((email) => ({ email }));

    this.setState({ loading: true });

    try {
      const template = this.props.template.mergeTemplates.find(
        (template) => template.key === "template"
      );
      const subjectTemplate = this.props.template.mergeTemplates.find(
        (template) => template.key === "subject"
      );

      const body = !!template ? template.value : "";
      const subject = this.sanatizeSubject(
        !!subjectTemplate ? subjectTemplate.value : ""
      );

      await this.props.sendPreviewEmail(body, to, subject);

      this.props.onClose();

      await AsyncUtil.wait(500);
      this.form.update({ emails: "" });
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }

  private sanatizeSubject(title: string) {
    return title.replace(/&nbsp;/g, " ");
  }
}
