import { EmployeeRole } from "@haywork/api/kolibri";
import { LiveDataConnection } from "@haywork/stores/live-data";
import * as Ably from "ably";
import * as React from "react";
import { PureComponent } from "react";
import { LiveDataContainerProps } from "./live-data.container";
import {
  LiveDataContextProps,
  LiveDataContextProvider,
} from "./live-data.context";

export type LiveDataComponentProps = {};
type Props = LiveDataComponentProps & LiveDataContainerProps;

let mounted = false;

export class LiveDataComponent extends PureComponent<
  Props,
  LiveDataContextProps
> {
  private mailConnection: Ably.Types.ConnectionCallbacks | null = null;
  private voipConnection: Ably.Types.ConnectionCallbacks | null = null;
  private kolibriConnection: Ably.Types.ConnectionCallbacks | null = null;
  private eventCenterConnection: Ably.Types.ConnectionCallbacks | null = null;

  constructor(props) {
    super(props);

    this.initMailRealtime = this.initMailRealtime.bind(this);
    this.initVoipRealtime = this.initVoipRealtime.bind(this);
    this.initKolibriRealtime = this.initKolibriRealtime.bind(this);
    this.initEventCenterRealtime = this.initEventCenterRealtime.bind(this);
    this.bindMailConnection = this.bindMailConnection.bind(this);
    this.bindVoipConnection = this.bindVoipConnection.bind(this);
    this.bindKolibriConnection = this.bindKolibriConnection.bind(this);
    this.bindEventCenterConnection = this.bindEventCenterConnection.bind(this);
    this.refreshMailRealtime = this.refreshMailRealtime.bind(this);

    this.state = {
      mailRealtime: null,
      voipRealtime: null,
      kolibriRealtime: null,
      eventCenterRealtime: null,
      mailChannels: [],
      voipChannels: [],
      kolibriChannels: [],
      eventCenterChannels: {
        companyChannel: null,
        personalChannel: null,
        presenceChannel: null,
      },
      refreshMailRealtime: this.refreshMailRealtime,
    };
  }

  public render() {
    return (
      <LiveDataContextProvider value={this.state}>
        {this.props.children}
      </LiveDataContextProvider>
    );
  }

  public componentDidMount() {
    this.initMailRealtime();
    this.initVoipRealtime();
    this.initKolibriRealtime();
    this.initEventCenterRealtime();
    mounted = true;
  }

  public componentWillUnmount() {
    mounted = false;

    if (!!this.state.mailRealtime) {
      this.state.mailRealtime.close();
    }
    if (!!this.mailConnection) {
      this.mailConnection.off();
      this.mailConnection = null;
    }
    if (!!this.state.voipRealtime) {
      this.state.voipRealtime.close();
    }
    if (!!this.voipConnection) {
      this.voipConnection.off();
      this.voipConnection = null;
    }
    if (!!this.state.kolibriRealtime) {
      this.state.kolibriRealtime.close();
    }
    if (!!this.kolibriConnection) {
      this.kolibriConnection.off();
      this.kolibriConnection = null;
    }
    if (!!this.state.eventCenterRealtime) {
      this.state.eventCenterRealtime.close();
    }
    if (!!this.eventCenterConnection) {
      this.eventCenterConnection.off();
      this.eventCenterConnection = null;
    }
    this.setState({
      mailRealtime: null,
      voipRealtime: null,
      kolibriRealtime: null,
      eventCenterRealtime: null,
      mailChannels: [],
      voipChannels: [],
      kolibriChannels: [],
      eventCenterChannels: {
        companyChannel: null,
        personalChannel: null,
        presenceChannel: null,
      },
    });
  }

  private async initMailRealtime() {
    if (
      this.props.role === EmployeeRole.BackOffice ||
      !this.props.features.includes("EMAIL")
    )
      return;

    try {
      const {
        channels: mailChannels,
        accessToken,
      } = await this.props.authenticateMail();

      const mailRealtime = new Ably.Realtime({
        token: accessToken,
        authCallback: async (_, authCallback) => {
          let authenticationError: any = null;
          let updatedAccessToken: string | null = null;
          let updatedMailChannels = this.state.mailChannels;

          try {
            const {
              channels: mailChannels,
              accessToken,
            } = await this.props.authenticateMail();

            updatedAccessToken = accessToken;
            updatedMailChannels = mailChannels;
          } catch (error) {
            authenticationError = error;
            updatedMailChannels = [];
          } finally {
            authCallback(authenticationError, updatedAccessToken);
          }

          this.setState({ mailChannels: updatedMailChannels });
        },
      });

      this.setState({ mailRealtime, mailChannels });
      this.bindMailConnection();
    } catch (error) {
      throw error;
    }
  }

  private async initVoipRealtime() {
    try {
      const {
        channels: voipChannels,
        accessToken,
      } = await this.props.authenticateVoip();

      const voipRealtime = new Ably.Realtime({
        token: accessToken,
        authCallback: async (_, authCallback) => {
          let authenticationError: any = null;
          let updatedAccessToken: string | null = null;
          let updatedVoipChannels = this.state.voipChannels;

          try {
            const {
              channels: voipChannels,
              accessToken,
            } = await this.props.authenticateVoip();

            updatedAccessToken = accessToken;
            updatedVoipChannels = voipChannels;
          } catch (error) {
            authenticationError = error;
            updatedVoipChannels = [];
          } finally {
            authCallback(authenticationError, updatedAccessToken);
          }

          this.setState({ voipChannels: updatedVoipChannels });
        },
      });

      this.setState({ voipChannels, voipRealtime });
      this.bindVoipConnection();
    } catch (error) {
      throw error;
    }
  }

  private async initKolibriRealtime() {
    try {
      const {
        channels: kolibriChannels,
        accessToken,
      } = await this.props.authenticateKolibri();

      const kolibriRealtime = new Ably.Realtime({
        token: accessToken,
        authCallback: async (_, authCallback) => {
          let authenticationError: any = null;
          let updatedAccessToken: string | null = null;
          let updatedKolibriChannels = this.state.kolibriChannels;

          try {
            const {
              channels: kolibriChannels,
              accessToken,
            } = await this.props.authenticateKolibri();

            updatedAccessToken = accessToken;
            updatedKolibriChannels = kolibriChannels;
          } catch (error) {
            authenticationError = error;
            updatedKolibriChannels = [];
          } finally {
            authCallback(authenticationError, updatedAccessToken);
          }

          this.setState({ kolibriChannels: updatedKolibriChannels });
        },
      });

      this.setState({ kolibriChannels, kolibriRealtime });
      this.bindKolibriConnection();
    } catch (error) {
      throw error;
    }
  }

  private async initEventCenterRealtime() {
    try {
      const {
        companyChannel,
        personalChannel,
        presenceChannel,
        accessToken,
      } = await this.props.authenticateEventCenter();

      const eventCenterRealtime = new Ably.Realtime({
        token: accessToken,
        authCallback: async (_, authCallback) => {
          let authenticationError: any = null;
          let updatedAccessToken: string | null = null;
          let updatedEventCenterChannels = this.state.eventCenterChannels;

          try {
            const {
              companyChannel,
              personalChannel,
              presenceChannel,
              accessToken,
            } = await this.props.authenticateEventCenter();

            updatedAccessToken = accessToken;
            updatedEventCenterChannels = {
              ...updatedEventCenterChannels,
              companyChannel: companyChannel || null,
              personalChannel: personalChannel || null,
              presenceChannel: presenceChannel || null,
            };
          } catch (error) {
            authenticationError = error;
            updatedEventCenterChannels = {
              companyChannel: null,
              personalChannel: null,
              presenceChannel: null,
            };
          } finally {
            authCallback(authenticationError, updatedAccessToken);
          }

          this.setState({ eventCenterChannels: updatedEventCenterChannels });
        },
      });

      this.setState({
        eventCenterRealtime,
        eventCenterChannels: {
          companyChannel: companyChannel || null,
          personalChannel: personalChannel || null,
          presenceChannel: presenceChannel || null,
        },
      });
      this.bindEventCenterConnection();
    } catch (error) {
      throw error;
    }
  }

  private bindMailConnection() {
    if (!!this.mailConnection) {
      this.mailConnection.off();
      this.mailConnection = null;
    }

    if (!this.state.mailRealtime) return;
    const { connection } = this.state.mailRealtime;
    this.mailConnection = connection;

    this.mailConnection.on((status) => {
      if (status.current === "closed" && mounted) {
        this.initMailRealtime();
      }

      this.props.handleConnectionChange(
        status.current,
        LiveDataConnection.Mail
      );
    });
  }

  private bindVoipConnection() {
    if (!!this.voipConnection) {
      this.voipConnection.off();
      this.voipConnection = null;
    }

    if (!this.state.voipRealtime) return;
    const { connection } = this.state.voipRealtime;
    this.voipConnection = connection;

    this.voipConnection.on((status) => {
      if (status.current === "closed" && mounted) {
        this.initVoipRealtime();
      }

      this.props.handleConnectionChange(
        status.current,
        LiveDataConnection.Voip
      );
    });
  }

  private bindKolibriConnection() {
    if (!!this.kolibriConnection) {
      this.kolibriConnection.off();
      this.kolibriConnection = null;
    }

    if (!this.state.kolibriRealtime) return;
    const { connection } = this.state.kolibriRealtime;
    this.kolibriConnection = connection;

    this.kolibriConnection.on((status) => {
      if (status.current === "closed" && mounted) {
        this.initKolibriRealtime();
      }

      this.props.handleConnectionChange(
        status.current,
        LiveDataConnection.Kolibri
      );
    });
  }

  private bindEventCenterConnection() {
    if (!!this.eventCenterConnection) {
      this.eventCenterConnection.off();
      this.eventCenterConnection = null;
    }

    if (!this.state.eventCenterRealtime) return;
    const { connection } = this.state.eventCenterRealtime;
    this.eventCenterConnection = connection;

    this.eventCenterConnection.on((status) => {
      if (status.current === "closed" && mounted) {
        this.initEventCenterRealtime();
      }

      this.props.handleConnectionChange(
        status.current,
        LiveDataConnection.EventCenter
      );
    });
  }

  private refreshMailRealtime() {
    if (!!this.state.mailRealtime) {
      this.state.mailRealtime.close();
    }
    if (!!this.mailConnection) {
      this.mailConnection.off();
      this.mailConnection = null;
    }

    this.initMailRealtime();
  }
}
