import {
  ActiveFilter,
  AssignmentType,
  GlobalSearchType,
  RealEstateGroup,
  RelationType,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  INVOICEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { InputComponentProps } from "@haywork/modules/form";
import {
  GlobalSearchRequest,
  GlobalSearchResponseItem,
} from "@haywork/request";
import { RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import Option from "./components/option";
import Pill from "./components/pill";

const route = RouteUtil.mapStaticRouteValues;

type GlobalSearchQueryComponentProps = {
  filterByActive?: ActiveFilter;
  filterByAssignmentTypes?: AssignmentType[];
  filterByRealEstateGroups?: RealEstateGroup[];
  filterByRelationTypes?: RelationType[];
  filterByTypes?: GlobalSearchType[];
  takePerType?: number;
  placeholder?: string;
  multiple?: boolean;
  onNavigate?: (path: string) => void;
  onPillClick?: (item: GlobalSearchResponseItem) => void;
};
type State = {
  value: GlobalSearchResponseItem[];
  placeholder: string;
};
type Props = GlobalSearchQueryComponentProps & InputComponentProps;

export class GlobalSearchQueryComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let value = isArray(this.props.value)
      ? this.props.value
      : [this.props.value];
    value = value.filter((v) => !!v);

    this.state = {
      value,
      placeholder:
        this.props.placeholder || "globalSearchQueryInput.placeholder",
    };

    this.renderSelectedValuePill = this.renderSelectedValuePill.bind(this);
    this.onPillClick = this.onPillClick.bind(this);
    this.onPillRemoveClick = this.onPillRemoveClick.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedValue = this.renderSelectedValue.bind(this);
    this.renderDisabledOption = this.renderDisabledOption.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div className="assignment__query">
        <BaseQueryComponent
          {...this.props}
          {...this.state}
          hasCustomPill
          asyncValues={(term) =>
            GlobalSearchRequest.search({
              term,
              filterByActive: this.props.filterByActive,
              filterByAssignmentTypes: this.props.filterByAssignmentTypes,
              filterByRealEstateGroups: this.props.filterByRealEstateGroups,
              filterByRelationTypes: this.props.filterByRelationTypes,
              filterByTypes: this.props.filterByTypes,
              takePerType: this.props.takePerType,
            })
          }
          optionValue={this.renderOptionValue}
          selectedValue={this.renderSelectedValue}
          disableOption={this.renderDisabledOption}
          onChange={this.onChangeHandler}
          data-cy={this.props["data-cy"]}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    let value = isArray(nextProps.value) ? nextProps.value : [nextProps.value];
    value = value.filter((v) => !!v);

    this.setState({ value });
  }

  private onChangeHandler(value: GlobalSearchResponseItem[]) {
    this.fireOnChange(value);
  }

  private fireOnChange(values: GlobalSearchResponseItem[]) {
    const value = !!this.props.multiple ? values : first(values);
    this.props.onChange(value);
  }

  private renderOptionValue(
    item: GlobalSearchResponseItem,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return <Option item={item} query={query} />;
  }

  private renderSelectedValue(
    value: GlobalSearchResponseItem
  ): QueryResultReturnValue<GlobalSearchResponseItem> {
    const template = this.renderSelectedValuePill(value);

    return {
      value,
      template,
    };
  }

  private renderDisabledOption(
    value: GlobalSearchResponseItem,
    values: GlobalSearchResponseItem[]
  ) {
    const ids = values.map((v) => v.entityId);
    return ids.includes(value.entityId);
  }

  private renderSelectedValuePill(
    item: GlobalSearchResponseItem
  ): React.ReactElement<HTMLDivElement> {
    return (
      <Pill
        item={item}
        onClick={this.onPillClick}
        onRemoveClick={this.onPillRemoveClick}
      />
    );
  }

  private onPillClick(item: GlobalSearchResponseItem) {
    if (!!this.props.onPillClick) return this.props.onPillClick(item);
    if (!this.props.onNavigate) return;
    const { type, assignment, relation, entityId: id } = item;

    let path = "";
    switch (type) {
      case GlobalSearchType.Assignments: {
        if (!assignment || !assignment.typeOfAssignment) break;
        const { typeOfAssignment } = assignment;
        switch (typeOfAssignment) {
          case AssignmentType.Acquisition: {
            path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
            break;
          }
          case AssignmentType.AcquisitionObject: {
            path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
            break;
          }
          case AssignmentType.Object: {
            path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
            break;
          }
          case AssignmentType.ObjectType: {
            path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
            break;
          }
          case AssignmentType.Project: {
            path = route(PROJECTROUTES.DETAIL.URI, { id });
            break;
          }
          default:
            break;
        }
      }
      case GlobalSearchType.Invoices: {
        path = route(INVOICEROUTES.DETAIL.URI, { id });
        break;
      }
      case GlobalSearchType.Relations: {
        if (!relation || !relation.typeOfRelation) break;
        const { typeOfRelation } = relation;
        switch (typeOfRelation) {
          case RelationType.ContactCompany: {
            path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
            break;
          }
          case RelationType.ContactPerson: {
            path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
            break;
          }
          case RelationType.Employee: {
            path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
            break;
          }
          case RelationType.Office: {
            path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
            break;
          }
          default:
            break;
        }
      }
      case GlobalSearchType.Tasks: {
        path = route(TASKROUTES.TASK.URI, { id });
        break;
      }
      default:
        break;
    }

    if (!!path) this.props.onNavigate(path);
  }

  private onPillRemoveClick(
    event: React.MouseEvent<HTMLDivElement>,
    item: GlobalSearchResponseItem
  ) {
    event.stopPropagation();
    const value = this.state.value.filter((v) => v.entityId !== item.entityId);

    this.setState({ value });
    this.fireOnChange(value);
  }
}
