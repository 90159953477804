import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import {
  ObjectTypesPublishComponent,
  ObjectTypesPublishComponentProps
} from "@haywork/modules/object-types";
import { AppState } from "@haywork/stores";
import { ExtendedObjectTypeAssignment } from "@haywork/stores/project/types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  editableDisplayName,
  editableObjectTypeInfo,
  path,
  projectName,
  savingType
} from "./selectors";

interface StateProps {
  currentType: ExtendedObjectTypeAssignment;
  displayName: string;
  objectTypeInfo: string;
  publishing: boolean;
  path: string;
  projectName: string;
}
interface DispatchProps {
  navigate: (path: string) => void;
  publishType: (
    currentType: ExtendedObjectTypeAssignment,
    createNew?: boolean
  ) => void;
}

const mapStateToProps = <StateProps, ObjectTypesPublishComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;

  return {
    currentType,
    displayName: editableDisplayName(state),
    objectTypeInfo: editableObjectTypeInfo(state),
    publishing: savingType(state),
    path: path(state),
    projectName: projectName(state)
  };
};

const mapDispatchToProps = <DispatchProps, ObjectTypesPublishComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (path: string) => dispatch(push(path)),
  publishType: (
    currentType: ExtendedObjectTypeAssignment,
    createNew?: boolean
  ) => dispatch(ProjectsThunks.Types.publishType(currentType, createNew))
});

export type ObjectTypesPublishContainerProps = StateProps & DispatchProps;
export const ObjectTypesPublishContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesPublishComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesPublishComponent);
