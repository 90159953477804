import {
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  RelationType,
  TaskCategoryOption,
  TaskSnapShot,
  TaskStatus,
  Priority,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { Column, Row } from "@haywork/components/ui/list";
import Pill from "@haywork/components/ui/pill";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import { DateUtil, DiffStatus, RouteUtil, ColorUtil } from "@haywork/util";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import LoadingState from "../loading-state";
import Actions from "./row-actions";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  data: TaskSnapShot | null;
  zebra: boolean;
  taskCategories: TaskCategoryOption[];
  employeeId: string;
  onNavigatePath: (path: string) => void;
  onToggleTaskStatus: (
    snapshot: TaskSnapShot,
    status: TaskStatus
  ) => Promise<void>;
};

export const RowComponent: FC<Props> = memo(
  ({
    data,
    zebra,
    onNavigatePath,
    taskCategories,
    onToggleTaskStatus,
    employeeId,
  }) => {
    return !data ? (
      <LoadingState zebra={zebra} />
    ) : (
      <DataRowComponent
        data={data}
        zebra={zebra}
        onNavigatePath={onNavigatePath}
        taskCategories={taskCategories}
        onToggleTaskStatus={onToggleTaskStatus}
        employeeId={employeeId}
      />
    );
  }
);

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      data,
      zebra,
      onNavigatePath,
      taskCategories,
      onToggleTaskStatus,
      employeeId,
    }) => {
      const {
        id,
        isActive,
        subject,
        priority,
        status,
        endDate,
        categoryId,
        linkedRelations,
        linkedAssignments,
        linkedEmployee,
      } = data;

      const category = useMemo(() => {
        return taskCategories.find((category) => category.value === categoryId);
      }, [categoryId, taskCategories]);

      const categoryFlag = useMemo(() => {
        const styles: CSSProperties = {
          backgroundColor: !isActive
            ? Colors.Gray
            : !category
            ? undefined
            : ColorUtil.hexToRgb(category.categoryBackColor),
        };

        return <div styleName="category-flag" style={styles} />;
      }, [category, isActive]);

      const subtitle = useMemo(() => {
        if (!linkedEmployee || linkedEmployee.id === employeeId) {
          if (!category || !category.displayName) return null;
          return category.displayName;
        } else {
          return !category || !category.displayName ? (
            <I18n
              value="tasks.listItem.subjectWithoutCategory"
              values={{ name: linkedEmployee.displayName }}
            />
          ) : (
            <I18n
              value="tasks.listItem.subjectWithCategory"
              values={{
                name: linkedEmployee.displayName,
                category: category.displayName,
              }}
            />
          );
        }
      }, [linkedEmployee, employeeId, category]);

      const pillsRow = useMemo(() => {
        const relations = linkedRelations || [];
        const assignments = linkedAssignments || [];

        return !relations.length && !assignments.length ? null : (
          <div styleName="linked-entities">
            {relations.map((relation) => (
              <Pill
                label={relation.displayName}
                color={!isActive ? Colors.Gray : Colors.Primary}
                onClick={() => onRelationClickHandler(relation)}
                icon="user"
                key={relation.id}
              />
            ))}
            {assignments.map((assignment) => (
              <Pill
                label={assignment.displayName}
                color={!isActive ? Colors.Gray : Colors.Primary}
                onClick={() => onAssignmentClickHandler(assignment)}
                icon="folder-open"
                key={assignment.id}
              />
            ))}
          </div>
        );
      }, [linkedRelations, linkedAssignments]);

      const daysLeft = useMemo(() => {
        return DateUtil.diffInDays(new Date(), endDate);
      }, [endDate]);

      const dateTarget = useMemo(() => {
        const { status: dateStatus, value: days } = daysLeft;

        switch (true) {
          case !isActive:
          case [TaskStatus.Canceled, TaskStatus.Completed].includes(status):
          default: {
            return null;
          }
          case dateStatus === DiffStatus.Negative: {
            return (
              <div styleName={classNames("days-overdue", "oops")}>
                <I18n value="amountOfDaysPastDueDate" values={{ days }} />
              </div>
            );
          }
          case dateStatus === DiffStatus.Today: {
            return (
              <div styleName={classNames("days-overdue", "today")}>
                <I18n value="amountOfDays" values={{ days }} />
              </div>
            );
          }
          case dateStatus === DiffStatus.Positive: {
            return (
              <div styleName={classNames("days-overdue", "safe")}>
                <I18n value="amountOfDays" values={{ days }} />
              </div>
            );
          }
        }
      }, [daysLeft, status, isActive]);

      const onListClickHandler = useCallback(() => {
        const path = route(TASKROUTES.TASK.URI, { id });
        onNavigatePath(path);
      }, [id, onNavigatePath]);

      const onRelationClickHandler = useCallback(
        (relation: LinkedRelation) => {
          const { id, typeOfRelation } = relation;
          let path = "";

          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            }
            case RelationType.Employee: {
              path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            }
            case RelationType.Office: {
              path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            }
            default: {
              path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
              break;
            }
          }

          onNavigatePath(path);
        },
        [onNavigatePath]
      );

      const onAssignmentClickHandler = useCallback(
        (assignment: LinkedAssignment) => {
          const { id } = assignment;
          let path = "";
          switch (assignment.typeOfAssignment) {
            case AssignmentType.Acquisition:
              path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
              break;
            case AssignmentType.AcquisitionObject:
              path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
              break;
            case AssignmentType.ObjectType:
              path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
              break;
            case AssignmentType.Project:
              path = route(PROJECTROUTES.DETAIL.URI, { id });
              break;
            default:
              path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
              break;
          }

          onNavigatePath(path);
        },
        [onNavigatePath]
      );

      const onToggleCompleted = useCallback(
        (checked: boolean) => {
          onToggleTaskStatus(
            data,
            checked ? TaskStatus.Completed : TaskStatus.NotStarted
          );
        },
        [data, onToggleTaskStatus]
      );

      return (
        <Row zebra={zebra} onClick={onListClickHandler}>
          <Column index={0}>
            <div
              styleName={classNames("column", "centered")}
              onClick={(e) => e.stopPropagation()}
            >
              {categoryFlag}
              <Input.CheckBox
                name={`taskStatus.${id}`}
                value={status === TaskStatus.Completed}
                onChange={onToggleCompleted}
                asSingleInput
              />
            </div>
          </Column>
          <Column index={1}>
            <div styleName="column">
              <div
                styleName={classNames("subject", {
                  completed: status === TaskStatus.Completed,
                  archived: !isActive,
                })}
              >
                {subject}
              </div>
              {subtitle && (
                <div
                  styleName={classNames("subtitle", {
                    completed: status === TaskStatus.Completed,
                  })}
                >
                  {subtitle}
                </div>
              )}
              {pillsRow}
            </div>
          </Column>
          <Column index={2}>
            <div styleName="column">
              <div styleName="end-date">
                {!endDate ? (
                  <I18n value="task.listItem.noEndDate" />
                ) : (
                  moment(endDate).format("DD MMMM YYYY")
                )}
              </div>
              {dateTarget}
            </div>
          </Column>
          <Column index={3}>
            <div styleName={classNames("column", "centered")}>
              {priority === Priority.High && <div styleName="priority">!</div>}
            </div>
          </Column>
          <Actions item={data} />
        </Row>
      );
    }
  )
);
