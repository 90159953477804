import {
  EventTimeSpan,
  MomentInTimeOption,
  NotificationSettings,
  UnitOfTimeOption,
} from "@haywork/api/event-center";
import { RelationSnapShot } from "@haywork/api/kolibri";
import {
  DELEGATEFORMS,
  MULTITIMESPANSFORMS,
  TIMESPANSFORMS,
} from "@haywork/constants";
import { Input } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DelegatesForm, MultiTimeSpansForm, TimeSpansForm } from "./forms";

const styles = require("./list-item.component.scss");

interface Props {
  setting: NotificationSettings;
  employees: RelationSnapShot[];
  unitsOfTime: UnitOfTimeOption[];
  momentsInTime: MomentInTimeOption[];
  onChange: (setting: NotificationSettings) => void;
}
interface State {
  enabled: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ListItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onEnabledChangeHandler = this.onEnabledChangeHandler.bind(this);
    this.onTimeSpansChangeHandler = this.onTimeSpansChangeHandler.bind(this);
    this.onDelegatesChangeHandler = this.onDelegatesChangeHandler.bind(this);

    this.state = {
      enabled: this.props.setting.enabled,
    };
  }

  public render() {
    const showTimeSpans =
      TIMESPANSFORMS.indexOf(this.props.setting.eventType) !== -1;
    const showDelegates =
      DELEGATEFORMS.indexOf(this.props.setting.eventType) !== -1;
    const showMultiTimeSpans =
      MULTITIMESPANSFORMS.indexOf(this.props.setting.eventType) !== -1;

    return (
      <div styleName={classNames("item", { expanded: this.state.enabled })}>
        <div styleName="item__header">
          <div styleName="input">
            <Input.Switch
              name={`enabled.${this.props.setting.eventType.toString()}`}
              on={true}
              off={false}
              value={this.state.enabled}
              onChange={this.onEnabledChangeHandler}
              data-cy={"CY-listItem" + this.props.setting.eventType}
            />
          </div>
          <h2>
            <ResourceText
              masterKey="eventTypes"
              resourceKey={this.props.setting.eventType.toString()}
            />
          </h2>
        </div>

        <div styleName="item__body">
          {!!showTimeSpans && (
            <TimeSpansForm
              setting={this.props.setting}
              unitsOfTime={this.props.unitsOfTime}
              momentsInTime={this.props.momentsInTime}
              onChange={this.onTimeSpansChangeHandler}
            />
          )}

          {!!showMultiTimeSpans && (
            <MultiTimeSpansForm
              setting={this.props.setting}
              onChange={this.onTimeSpansChangeHandler}
            />
          )}

          {!!showDelegates && (
            <DelegatesForm
              setting={this.props.setting}
              employees={this.props.employees}
              onChange={this.onDelegatesChangeHandler}
            />
          )}
        </div>
      </div>
    );
  }

  private onEnabledChangeHandler(enabled: boolean) {
    this.setState({ enabled });

    const setting = {
      ...this.props.setting,
      enabled,
    };

    this.props.onChange(setting);
  }

  private onDelegatesChangeHandler(delegates: RelationSnapShot[]) {
    const setting = {
      ...this.props.setting,
      delegates: delegates.map((delegate) => ({
        id: delegate.id,
        displayName: delegate.displayName,
      })),
    };

    this.props.onChange(setting);
  }

  private onTimeSpansChangeHandler(timeSpans: EventTimeSpan[]) {
    const setting = {
      ...this.props.setting,
      timeSpans,
    };

    this.props.onChange(setting);
  }
}
