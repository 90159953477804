import {
  Language,
  ProjectAssignment,
  TranslatedText
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

const styles = require("./dates-form.component.scss");

export interface Props {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;

  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
}

@CSSModules(styles, { allowMultiple: true })
export class DatesFormComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { projectAssignment } = this.props;
    const { buildingStartText, deliveryStartText, saleStartText } = this.props;

    const {
      buildingStart,
      deliveryStart,
      saleStart,
      hideBuildingStartDate,
      hideDeliveryStartDate,
      hideSaleStartDate
    } = projectAssignment;

    this.formControls = {
      buildingStart: { value: buildingStart },
      hideBuildingStartDate: { value: hideBuildingStartDate },
      buildingStartText: { value: buildingStartText },
      deliveryStart: { value: deliveryStart },
      hideDeliveryStartDate: { value: hideDeliveryStartDate },
      deliveryStartText: { value: deliveryStartText },
      saleStart: { value: saleStart },
      hideSaleStartDate: { value: hideSaleStartDate },
      saleStartText: { value: saleStartText },
      rentStart: { value: saleStart },
      hideRentStartDate: { value: hideSaleStartDate },
      rentStartText: { value: saleStartText }
    };

    this.onDatesChangeHandler = this.onDatesChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;
      const {
        buildingStartText,
        deliveryStartText,
        saleStartText
      } = this.props;

      const {
        buildingStart,
        deliveryStart,
        saleStart,
        hideBuildingStartDate,
        hideDeliveryStartDate,
        hideSaleStartDate
      } = projectAssignment;

      this.formRef.update({
        buildingStart,
        hideBuildingStartDate,
        buildingStartText,
        deliveryStart,
        hideDeliveryStartDate,
        deliveryStartText,
        saleStart,
        hideSaleStartDate,
        saleStartText,
        rentStart: saleStart,
        hideRentStartDate: hideSaleStartDate,
        rentStartText: saleStartText
      }, true);
    }
  }

  public render() {
    return (
      <div styleName="dates-form">
        <Form
          name="dates-form"
          onChange={this.onDatesChangeHandler}
          formControls={this.formControls}
          form={(form) => (this.formRef = form)}
        >
          {this.renderDateFormRow(
            "buildingStart",
            "hideBuildingStartDate",
            "buildingStartText"
          )}
          {this.props.projectAssignment.forSale
            ? this.renderDateFormRow(
                "saleStart",
                "hideSaleStartDate",
                "saleStartText",
                !this.props.projectAssignment.forSale
              )
            : this.renderDateFormRow(
                "rentStart",
                "hideRentStartDate",
                "rentStartText",
                !this.props.projectAssignment.forSale
              )}
          {this.renderDateFormRow(
            "deliveryStart",
            "hideDeliveryStartDate",
            "deliveryStartText"
          )}
        </Form>
      </div>
    );
  }

  private renderDateFormRow(
    dateName: string,
    partsName: string,
    dateTextName: string,
    last: boolean = false
  ) {
    return (
      <React.Fragment>
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor={dateName} className="pre">
                <ResourceText resourceKey={`projectLabel.${dateName}`} />
              </label>
            </div>
            <div className="column" styleName="date-input">
              <Input.Datepicker name={dateName} data-cy={"CY-" + dateName} />
              <div styleName="switch">
                <Input.Switch
                  name={partsName}
                  label="hide"
                  labelPosition={SwitchLabelPosition.Post}
                  on={true}
                  off={false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor={dateTextName} className="pre">
                <ResourceText resourceKey={`projectLabel.${dateTextName}`} />
              </label>
            </div>
            <div className="column" styleName="date-input">
              <Input.Textarea
                name={dateTextName}
                placeholder="projectTypesPlaceholderStartText"
                placeholderValues={{ year: new Date().getFullYear() }}
                autoSize
                maxCount={256}
                data-cy={"CY-" + dateTextName}
              />
            </div>
          </div>
        </div>
        {!!last && <div styleName="date-divider" />}
      </React.Fragment>
    );
  }

  private onDatesChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id
    });

    let buildingStartText: TranslatedText[];
    if (values.buildingStartText) {
      let ref = false;
      buildingStartText = get(projectAssignment, "buildingStartText", []);
      buildingStartText = buildingStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.buildingStartText
          };
        }
        return text;
      });

      if (!ref) {
        buildingStartText.push({
          language: Language.Dutch,
          text: values.buildingStartText
        });
      }
    }

    let deliveryStartText: TranslatedText[];
    if (values.deliveryStartText) {
      let ref = false;
      deliveryStartText = get(projectAssignment, "deliveryStartText", []);
      deliveryStartText = deliveryStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.deliveryStartText
          };
        }
        return text;
      });

      if (!ref) {
        deliveryStartText.push({
          language: Language.Dutch,
          text: values.deliveryStartText
        });
      }
    }

    let saleStartText: TranslatedText[];
    if (values.saleStartText || values.rentStartText) {
      let ref = false;
      saleStartText = get(projectAssignment, "saleStartText", []);
      saleStartText = saleStartText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: projectAssignment.forSale
              ? values.saleStartText
              : values.rentStartText
          };
        }
        return text;
      });

      if (!ref) {
        saleStartText.push({
          language: Language.Dutch,
          text: projectAssignment.forSale
            ? values.saleStartText
            : values.rentStartText
        });
      }
    }

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      buildingStart: values.buildingStart,
      buildingStartText,
      hideBuildingStartDate: values.hideBuildingStartDate,
      buildingStartDateParts: undefined,
      deliveryStart: values.deliveryStart,
      deliveryStartText,
      hideDeliveryStartDate: values.hideDeliveryStartDate,
      deliveryStartDateParts: undefined,
      saleStart: projectAssignment.forSale
        ? values.saleStart
        : values.rentStart,
      saleStartText,
      hideSaleStartDate: projectAssignment.forSale
        ? values.hideSaleStartDate
        : values.hideRentStartDate,
      saleStartDateParts: undefined
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, pathname);
  }
}
