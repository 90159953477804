export enum DistanceFromTo {
  DistanceNone = "DistanceNone",
  LessThanFiveHundred = "LessThanFiveHundred",
  FiveHundredUntillOneThousand = "FiveHundredUntillOneThousand",
  OneThousandUntillFifteenHundred = "OneThousandUntillFifteenHundred",
  FifteenHundredUntillTwoThousand = "FifteenHundredUntillTwoThousand",
  TwoThousandUntillThreeThousand = "TwoThousandUntillThreeThousand",
  ThreeThousandUntillFourThousand = "ThreeThousandUntillFourThousand",
  FourThousandUntillFiveThousand = "FourThousandUntillFiveThousand",
  MoreThanFiveThousand = "MoreThanFiveThousand"
}
