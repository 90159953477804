import {
  BusinessPartnerSnapShot,
  Language,
  TranslatedText,
  TypePART,
} from "@haywork/api/kolibri";
import { EXTERNALROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  RawFormControl,
  Validators,
} from "@haywork/modules/form";
import { ObjectTypesEditTypeContainerProps } from "@haywork/modules/object-types";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { Relation } from "@haywork/request";
import { StringUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./edit-type.component.scss");

export interface ObjectTypesEditTypeComponentProps {}
interface State {
  showHouseSort: boolean;
  showHouseType: boolean;
  showHouseCharacteristic: boolean;
  showApartmentSort: boolean;
  showApartmentCharacteristic: boolean;
}
type Props = ObjectTypesEditTypeComponentProps &
  ObjectTypesEditTypeContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditTypeComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    const {
      typePART,
      houseType,
      houseSort,
      houseCharacteristic,
      apartmentCharacteristic,
      apartmentSort,
      businessPartners,
      linkedEmployee,
    } = this.props.currentType;

    this.state = {
      showHouseSort: typePART === TypePART.ResidentialBuilding,
      showHouseType: !!houseType || !!houseSort,
      showHouseCharacteristic: !!houseCharacteristic || !!houseType,
      showApartmentSort: typePART === TypePART.Apartment,
      showApartmentCharacteristic: !!apartmentCharacteristic || !!apartmentSort,
    };

    this.formControls = {
      displayName: {
        value: this.props.displayName,
        validators: [Validators.required()],
      },
      linkedEmployee: {
        value: linkedEmployee || "",
        validators: [Validators.required()],
      },
      businessPartners: {
        value: businessPartners || [],
      },
      typePART: {
        value: get(this.props.currentType, "typePART"),
        onChange: this.typePartChange.bind(this),
      },
      houseSort: {
        value: get(this.props.currentType, "houseSort"),
        onChange: (ref) => {
          if (!!ref.value) {
            this.setState({ showHouseType: true });
          } else {
            this.setState({
              showHouseType: false,
              showHouseCharacteristic: false,
            });
          }
          return !ref.value
            ? { houseType: undefined, houseCharacteristic: undefined }
            : null;
        },
      },
      houseType: {
        value: get(this.props.currentType, "houseType"),
        onChange: (ref) => {
          this.setState({ showHouseCharacteristic: !!ref.value });
          return !ref.value ? { houseCharacteristic: undefined } : null;
        },
      },
      houseCharacteristic: {
        value: get(this.props.currentType, "houseCharacteristic"),
      },
      apartmentSort: {
        value: get(this.props.currentType, "apartmentSort"),
        onChange: (ref) => {
          this.setState({ showApartmentCharacteristic: !!ref.value });
          return !ref.value ? { apartmentCharacteristic: undefined } : null;
        },
      },
      apartmentCharacteristic: {
        value: get(this.props.currentType, "apartmentCharacteristic"),
      },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.currentType, "dateTimeModified") !==
        get(this.props.currentType, "dateTimeModified")
    ) {
      const {
        typePART,
        houseType,
        houseSort,
        houseCharacteristic,
        apartmentCharacteristic,
        apartmentSort,
        businessPartners,
        linkedEmployee,
      } = this.props.currentType;

      this.setState({
        showHouseSort: typePART === TypePART.ResidentialBuilding,
        showHouseType: !!houseType || !!houseSort,
        showHouseCharacteristic: !!houseCharacteristic || !!houseType,
        showApartmentSort: typePART === TypePART.Apartment,
        showApartmentCharacteristic:
          !!apartmentCharacteristic || !!apartmentSort,
      });

      this.formRef.update(
        {
          displayName: this.props.displayName,
          linkedEmployee: linkedEmployee || "",
          businessPartners: businessPartners || [],
          typePART: get(this.props.currentType, "typePART"),
          houseSort: get(this.props.currentType, "houseSort"),
          houseType: get(this.props.currentType, "houseType"),
          houseCharacteristic: get(
            this.props.currentType,
            "houseCharacteristic"
          ),
          apartmentSort: get(this.props.currentType, "apartmentSort"),
          apartmentCharacteristic: get(
            this.props.currentType,
            "apartmentCharacteristic"
          ),
        },
        true
      );
    }
  }

  public render() {
    const { isNew } = this.props.currentType;

    return (
      <div styleName="type">
        <div className="container-fluid" data-cy="CY-objectTypeClientContainer">
          <Form
            name="project-type-type"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.formRef = form)}
          >
            <div className="form__row">
              <label htmlFor="displayName">
                <ResourceText resourceKey="projectTypeDisplayName" />
              </label>
              <Input.Text
                name="displayName"
                data-cy="CY-objectTypeDisplayname"
              />
            </div>

            {/* Employee */}
            <div className="form__row">
              <label htmlFor="linkedEmployee">
                <ResourceText resourceKey="whosAssignment" />
              </label>
              <Input.NewSelect
                name="linkedEmployee"
                placeholder="chooseEmployee"
                values={this.props.employees}
                displayProp="displayName"
              />
            </div>

            {/* Co-signers */}
            <div className="form__row">
              <label htmlFor="">
                <ResourceText resourceKey="businessPartners" />
              </label>
              <div className="input__wrapper">
                <Input.Query
                  name="businessPartners"
                  asyncValues={(term) => Relation.searchBusinessPartners(term)}
                  multiple
                  optionValue={this.renderBusinessPartnerOption}
                  selectedValue={this.renderSelectedBusinessPartner}
                />
              </div>
            </div>

            <div className="form__row">
              <label>
                <ResourceText resourceKey="projectTypeListingType" />{" "}
                {!!isNew && (
                  <Ui.ImportantHint
                    message="onlyAvailableOnIsNew"
                    url={EXTERNALROUTES.SUPPORT_INFO_LISTINGTYPE.URI}
                  />
                )}
              </label>
              <div className="form__group">
                <div className="column">
                  <Input.List
                    name="typePART"
                    values={this.props.listingTypes}
                    displayPath="displayName"
                    comparePath="value"
                    resultPath="value"
                    isRoot
                    disabled={!this.props.currentType.isNew}
                    data-cy="CY-ListTypePart"
                  />
                </div>

                {/* House */}
                {this.state.showHouseSort && (
                  <div className="column__join">
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.List
                        name="houseSort"
                        values={this.props.houseSorts}
                        displayPath="displayName"
                        comparePath="value"
                        resultPath="value"
                        data-cy="CY-ListHouseSort"
                      />
                    </div>
                  </div>
                )}
                {this.state.showHouseType && (
                  <div className="column__join">
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.List
                        name="houseType"
                        values={this.props.houseTypes}
                        displayPath="displayName"
                        comparePath="value"
                        resultPath="value"
                        data-cy="CY-ListHouseType"
                      />
                    </div>
                  </div>
                )}
                {this.state.showHouseCharacteristic && (
                  <div className="column__join">
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.List
                        name="houseCharacteristic"
                        values={this.props.houseCharacteristics}
                        displayPath="displayName"
                        comparePath="value"
                        resultPath="value"
                        data-cy="CY-ListHouseCharacteristic"
                      />
                    </div>
                  </div>
                )}

                {/* Apartment */}
                {this.state.showApartmentSort && (
                  <div className="column__join">
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.List
                        name="apartmentSort"
                        values={this.props.apartmentSorts}
                        displayPath="displayName"
                        comparePath="value"
                        resultPath="value"
                        data-cy="CY-ListAppartmentSort"
                      />
                    </div>
                  </div>
                )}
                {this.state.showApartmentCharacteristic && (
                  <div className="column__join">
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.List
                        name="apartmentCharacteristic"
                        values={this.props.apartmentCharacteristics}
                        displayPath="displayName"
                        comparePath="value"
                        resultPath="value"
                        data-cy="CY-ListAppartmentCharacteristics"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    let { currentType } = this.props;

    let titleText: TranslatedText[];
    if (values.displayName) {
      let ref = false;
      titleText = get(currentType, "titleText", []);
      titleText = titleText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.displayName,
          };
        }
        return text;
      });

      if (!ref) {
        titleText.push({
          language: Language.Dutch,
          text: values.displayName,
        });
      }
    }

    currentType = {
      ...currentType,
      apartmentCharacteristic: values.apartmentCharacteristic,
      apartmentSort: values.apartmentSort,
      houseCharacteristic: values.houseCharacteristic,
      houseSort: values.houseSort,
      houseType: values.houseType,
      typePART: values.typePART,
      titleText,
      businessPartners: values.businessPartners || [],
      linkedEmployee: values.linkedEmployee,
    };

    this.props.updateCurrentType(currentType, this.props.path);
  }

  private typePartChange(ref: RawFormControl) {
    switch (ref.value) {
      case TypePART.ResidentialBuilding: {
        this.setState({
          showHouseSort: true,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
        });
        return { apartmentSort: undefined, apartmentCharacteristic: undefined };
      }
      case TypePART.Apartment: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: true,
          showApartmentCharacteristic: false,
        });
        return {
          houseSort: undefined,
          houseType: undefined,
          houseCharacteristic: undefined,
        };
      }
      default: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
        });
        return {
          apartmentSort: undefined,
          apartmentCharacteristic: undefined,
          houseSort: undefined,
          houseType: undefined,
          houseCharacteristic: undefined,
        };
      }
    }
  }

  private renderBusinessPartnerOption(
    value: BusinessPartnerSnapShot,
    query: string
  ): QueryOptionReturnValue {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return (
      <div dangerouslySetInnerHTML={StringUtil.highlight(displayName, query)} />
    );
  }

  private renderSelectedBusinessPartner(
    value: BusinessPartnerSnapShot
  ): QueryResultReturnValue<any> {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return {
      value,
      template: <div>{displayName}</div>,
    };
  }
}
