import { EmployeeRole } from "@haywork/api/kolibri";
import { EMPLOYEEROUTES, EXTERNALROUTES, MAINROUTES } from "@haywork/constants";
import { UserMenuContainerProps } from "@haywork/modules/layout";
import { ResourceText } from "@haywork/modules/shared";
import { Redirect } from "@haywork/services";
import { AppState, store } from "@haywork/stores";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RealEstateAgenciesComponent } from "./realestate-agencies.component";

const styles = require("./user-menu.component.scss");

export interface UserMenuComponentProps {}
interface UserMenuComponentState {
  expanded: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class UserMenuComponent extends React.Component<
  UserMenuComponentProps & UserMenuContainerProps,
  UserMenuComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onLogoutClickHandler = this.onLogoutClickHandler.bind(this);
    this.onNavigateClickHandler = this.onNavigateClickHandler.bind(this);
    this.onHousenetClickHandler = this.onHousenetClickHandler.bind(this);
    this.onExchangeClickHandler = this.onExchangeClickHandler.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const userMenuStyle = classNames("user-menu", {
      expanded: this.state.expanded,
    });
    const avatarStyles = this.props.avatar
      ? { backgroundImage: `url(${JSON.stringify(this.props.avatar)})` }
      : null;
    const eventHubStatusStyle = classNames(
      "event-hub__status",
      this.props.eventHubStatus
    );
    const state: AppState = store.getState();
    const employeeId = state.account.employee.id;
    const exchangeIcon = require("static/logo_icon-exchange.svg");
    const housenetIcon = require("static/logo_icon-housenet.svg");

    return (
      <div
        styleName={userMenuStyle}
        ref={(ref) => (this.ref = ref)}
        data-cy="CY-userMenu"
      >
        <div styleName="trigger" onClick={this.onClickHandler}>
          {this.props.avatar ? (
            <div styleName="avatar" style={avatarStyles}>
              <div styleName={eventHubStatusStyle} />
            </div>
          ) : (
            <div styleName="avatar">
              <div styleName={eventHubStatusStyle} />
              <div styleName="letterAvatar">{this.props.letterAvatar}</div>
            </div>
          )}
          <div styleName="meta">
            <div styleName="employee">{this.props.displayName}</div>
            <div styleName="company">{this.props.officeDisplayName}</div>
          </div>
          <div styleName="icon">
            <i className="fal fa-fw fa-chevron-down" />
          </div>
        </div>
        <div styleName="dropdown">
          {this.props.role === EmployeeRole.Standard && (
            <div
              styleName="dropdown__item"
              onClick={() =>
                this.onNavigateClickHandler(
                  EMPLOYEEROUTES.EMPLOYEES.URI + employeeId
                )
              }
            >
              <i className="fal fa-user-alt" />
              <ResourceText resourceKey="profile" />
            </div>
          )}
          <div
            data-cy="CY-settingMenuItem"
            styleName="dropdown__item"
            onClick={() => this.onNavigateClickHandler(MAINROUTES.SETTINGS.URI)}
          >
            <i className="fal fa-sliders-h" />
            <ResourceText resourceKey="settings" />
          </div>
          <div
            styleName="dropdown__item"
            onClick={() => this.onNavigateClickHandler(MAINROUTES.HEALTH.URI)}
          >
            <i className="fal fa-stethoscope" />
            <ResourceText resourceKey="userMenu.action.health" />
          </div>

          <div
            styleName="dropdown__item"
            onClick={() =>
              window.open(
                "https://kolibri.software/nieuws/release-notes/",
                "_blank"
              )
            }
          >
            <i className="fal fa-newspaper" />
            <ResourceText resourceKey="userMenu.action.releases" />
          </div>

          <div
            styleName="dropdown__item"
            className="visible-xs"
            onClick={() =>
              this.onNavigateClickHandler(EXTERNALROUTES.SUPPORT.URI, true)
            }
          >
            <i className="fal fa-life-ring" />
            <ResourceText resourceKey="help" />
          </div>
          <div
            styleName="dropdown__item"
            className="visible-xs"
            onClick={this.onHousenetClickHandler}
          >
            <span styleName="img__icon housenet">
              <img src={housenetIcon} />
            </span>
            <ResourceText resourceKey="OutlookbarHousenet" />
          </div>
          <div
            styleName="dropdown__item"
            className="visible-xs"
            onClick={this.onExchangeClickHandler}
          >
            <span styleName="img__icon exchange">
              <img src={exchangeIcon} />
            </span>
            <ResourceText resourceKey="OutlookbarExchange" />
          </div>
          <div styleName="dropdown__item" onClick={this.onLogoutClickHandler}>
            <i className="fal fa-sign-out" />
            <ResourceText resourceKey="logOut" />
          </div>
          {this.props.linkedRealEstateAgencies.length >= 1 && (
            <RealEstateAgenciesComponent
              linkedRealEstateAgencies={this.props.linkedRealEstateAgencies}
              onRealestateAgencyClick={this.props.switchRealEstateAgency}
            />
          )}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickHandler() {
    this.setState({ expanded: true });
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref === undefined) return;
    if (!this.ref.contains(event.target) && !!this.state.expanded)
      this.setState({ expanded: false });
  }

  private onLogoutClickHandler() {
    this.props.logout();
    this.setState({ expanded: false });
  }

  private onNavigateClickHandler(route: string, external?: boolean) {
    this.setState({ expanded: false });
    if (external) return window.open(route, "_blank");
    this.props.navigate(route);
  }

  private async onExchangeClickHandler() {
    this.setState({ expanded: false });

    if (!this.props.exchangeEnabled) {
      return window.open(EXTERNALROUTES.EXCHANGE.URI, "_blank");
    }

    const redirect = new Redirect();

    try {
      const url = await this.props.getExchangeSingleSignOnUrl();
      redirect.navigate(url);
    } catch (error) {
      redirect.navigate(EXTERNALROUTES.EXCHANGE.URI);
    }
  }

  private async onHousenetClickHandler() {
    this.setState({ expanded: false });

    if (!this.props.housenetEnabled) {
      return window.open(EXTERNALROUTES.HOUSENET_INFO.URI, "_blank");
    }

    const redirect = new Redirect();

    try {
      const url = await this.props.getHousenetSingleSignOnUrl();
      redirect.navigate(url);
    } catch (error) {
      redirect.navigate(EXTERNALROUTES.HOUSENET_INFO.URI);
    }
  }
}
