import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { connect, MapStateToProps } from "react-redux";
import { AppState, EditableActions } from "@haywork/stores";
import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import {
  AcquistionAssignmentEditMediaFloorplansScreenProps,
  AcquistionAssignmentEditMediaFloorplansScreen,
} from "./assignments-edit-media-floorplans";
import { RouterAction, push } from "connected-react-router";
import { RouteUtil } from "@haywork/util";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {
  acquisitionStatusOptions: any;
  currentComponentState: AcquisitionObjectAssignment;
  path: string;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditMediaFloorplansScreenProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;
  const { acquisitionStatusOptions } = state.main.mastertable.kolibri;
  return {
    acquisitionStatusOptions,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
      id: currentComponentState.id,
    }),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditMediaFloorplansScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AcquistionAssignmentEditMediaFloorplansScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditMediaFloorplansScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditMediaFloorplansScreen);
