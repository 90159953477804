export const TASK = {
  SET_TASKS: "SET_TASKS",
  SET_UPCOMING_TASKS: "SET_UPCOMING_TASKS",
  APPEND_TASKS: "APPEND_TASKS",
  SET_TASK_OVERVIEW_STATUS: "SET_TASK_OVERVIEW_STATUS",
  SET_TASK_INITIAL_OVERVIEW_STATUS: "SET_TASK_INITIAL_OVERVIEW_STATUS",
  SET_TASK_WIDGET_STATE: "SET_TASK_WIDGET_STATE",
  SET_TASK_STATUS: "SET_TASK_STATUS",
  SET_OVERVIEW_TASK_STATUS: "SET_OVERVIEW_TASK_STATUS",
  SET_TASK_WIDGET_ITEM_STATUS: "SET_TASK_WIDGET_ITEM_STATUS",
  SET_CURRENT_TASK: "SET_CURRENT_TASK",
  SET_CURRENT_TASK_STATUS: "SET_CURRENT_TASK_STATUS",
  UPDATE_TASKS_FILTERS: "UPDATE_TASKS_FILTERS",
  REMOVE_TASK_FROM_OVERVIEW: "REMOVE_TASK_FROM_OVERVIEW",
  SET_TASKS_STATUS: "SET_TASKS_STATUS",
  UPDATE_LIST_ITEM: "TASKS_UPDATE_LIST_ITEM",
  DELETE_LIST_ITEM: "TASKS_DELETE_LIST_ITEM"
};
