import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { ListFilterContextProvider } from "./list-filter.context";
import { FilterConfig } from "./";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");
const equals = require("react-fast-compare");

type Props = {
  filters: FilterConfig;
  disableClear?: boolean;
  clearLabel?: string;
  onChange: (filters: FilterConfig) => void;
  onClearFilters?: () => void;
};

export const ListFilterComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      children,
      filters,
      onChange,
      disableClear,
      clearLabel,
      onClearFilters,
    }) => {
      const onFilterChange = useCallback(
        (value: FilterConfig) => {
          if (!equals(value, filters)) {
            onChange(value);
          }
        },
        [filters, onChange]
      );

      const canClear = useMemo(() => {
        if (!!disableClear) return false;
        let canClear = false;

        for (const key in filters) {
          const filter = filters[key];
          if (!equals(filter.value, filter.emptyValue)) {
            canClear = true;
            break;
          }
        }

        return canClear;
      }, [filters, disableClear]);

      const clearFilters = useCallback(() => {
        if (!canClear) return;

        const updatedFilters: FilterConfig = {};
        for (const key in filters) {
          const filter = filters[key];

          updatedFilters[key] = {
            ...filter,
            value: filter.emptyValue,
          };
        }

        if (!equals(updatedFilters, filters)) {
          onChange(updatedFilters);
        }

        if (!!onClearFilters) {
          onClearFilters();
        }
      }, [canClear, filters, onChange, onClearFilters]);

      return (
        <div styleName="list-filter">
          {!disableClear && (
            <div styleName="clear">
              {canClear ? (
                <span className="as-link" onClick={clearFilters}>
                  <I18n value={clearLabel || "list.clearFilters"} />
                </span>
              ) : (
                <span>
                  <I18n value="list.noActiveFilters" />
                </span>
              )}
            </div>
          )}
          <ListFilterContextProvider value={{ filters, onFilterChange }}>
            <div styleName="filters">
              <div styleName="filters__inner">{children}</div>
            </div>
          </ListFilterContextProvider>
        </div>
      );
    }
  )
);
