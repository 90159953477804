import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  ProjectAssignment,
  ProjectAssignmentLinkRelationRequest,
  RelationRoleOption,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks,
} from "@haywork/middleware";
import {
  ProjectDetailNetworkComponent,
  ProjectDetailNetworkComponentProps,
} from "@haywork/modules/project/components/detail-network/detail-network.component";
import { AppState, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  relationRoles: RelationRoleOption[];
  realEstateAgencyId: string;
  host: string;
  path: string;
  saveContactState: string;
}

interface DispatchProps {
  navigate: (url: string) => void;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  saveProject: (projectAssignment: ProjectAssignment) => void;
  saveSingleProject: (projectAssignment: ProjectAssignment) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  linkRelation: (
    relation: ProjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ProjectAssignment
  ) => Promise<void>;
  unlinkRelation: (
    relation: ProjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ProjectAssignment
  ) => Promise<void>;
}

const mapStateToProps = <StateProps, AssignmentDetailNetworkComponentProps>(
  state: AppState
) => {
  const { currentComponentState } = state.editable;
  const { relationRoles } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const path = RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
    id: currentComponentState.projectAssignment.id,
  });

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    relationRoles,
    realEstateAgencyId,
    host,
    path,
    saveContactState: state.project.single.saveAssignmentState,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AssignmentDetailNetworkComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      ProjectsThunks.Projects.updateProjectEditable(componentState, path, true)
    ),
  saveProject: (projectAssignment: ProjectAssignment) =>
    dispatch(ProjectsThunks.Projects.saveProject(projectAssignment, false)),
  saveSingleProject: (projectAssignment: ProjectAssignment) =>
    dispatch(
      ProjectsThunks.Projects.saveSingleProjectAssignment(projectAssignment)
    ),
  linkRelation: (
    relation: ProjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ProjectAssignment
  ) =>
    dispatch(
      ProjectsThunks.Projects.linkRelation(relation, updatedObjectAssignment)
    ),
  unlinkRelation: (
    relation: ProjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ProjectAssignment
  ) =>
    dispatch(
      ProjectsThunks.Projects.unlinkRelation(relation, updatedObjectAssignment)
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type ProjectDetailNetworkContainerProps = StateProps & DispatchProps;
export const ProjectDetailNetworkContainer = connect<
  StateProps,
  DispatchProps,
  ProjectDetailNetworkComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailNetworkComponent);
