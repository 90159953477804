import {
  CountryOption,
  EmailAddressTypeOption,
  Office,
  PhoneNumberTypeOption,
  RealEstateAgency,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  OfficeGeneralComponent,
  OfficeGeneralComponentProps,
} from "@haywork/modules/settings/modules/offices/components/general";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgency: RealEstateAgency;
  selectedOffice?: Office;
  selectedOfficeId: string;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
}

interface DispatchProps {
  navigate: (path: string) => RouterAction;
}

const mapStateToProps = <StateProps, OfficeGeneralComponentProps>(
  state: AppState
) => ({
  realEstateAgency: state.realEstateAgency.realEstateAgency,
  selectedOffice: state.offices.selectedOffice,
  selectedOfficeId: state.offices.selectedOffice.id,
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  countries: state.main.mastertable.kolibri.countries,
});

const mapDispatchToProps = <DispatchProps, OfficeGeneralComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (path: string) => dispatch(push(path)),
});

export type OfficeGeneralContainerProps = StateProps & DispatchProps;
export const OfficeGeneralContainer = connect<
  StateProps,
  DispatchProps,
  OfficeGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OfficeGeneralComponent);
