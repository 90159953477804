import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ObjectAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import { FormattedNumber } from "react-intl";

const styles = require("./widgets.component.scss");

type Props = {
  assignment: ObjectAssignment;
};

@CSSModules(styles, { allowMultiple: true })
export class BogSpecifications extends React.Component<Props> {
  public render() {
    const rentPricePerUnit = get(
      this.props.assignment,
      "rentOffer.rentPricePerUnit"
    );
    if (!rentPricePerUnit) return null;

    return (
      <div styleName="bog-specs">
        <div styleName="bog-spec">
          <div styleName="alv-spec">
            <div styleName="icon">
              <i className="fal fa-fw fa-euro-sign" />
            </div>
            <div styleName="label">
              <FormattedNumber
                value={rentPricePerUnit}
                style="decimal"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />{" "}
              <ResourceText resourceKey="perSquareMetersPerYear" asHtml />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
