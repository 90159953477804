import {
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  Priority,
  RelationType,
  TaskCategoryOption,
  TaskSnapShot,
  TaskStatus,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  TASKROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { ColorUtil, DateUtil, DiffStatus, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import { NavLink } from "react-router-dom";

const styles = require("./task-list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface TaskListItemComponentProps {
  task: TaskSnapShot;
  isCurrentUser: boolean;
  categories: TaskCategoryOption[];
  zebra: boolean;
  toggleTaskStatus: (task: TaskSnapShot, status: TaskStatus) => void;
  removeTask: (id: string) => void;
  onClick: (id: string) => void;
}
interface TaskListItemComponentState {
  category: TaskCategoryOption;
  showActions: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class TaskListItemComponent extends React.Component<
  TaskListItemComponentProps,
  TaskListItemComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    // Set initial state
    const category = this.props.categories.filter(
      (category) => category.value === this.props.task.categoryId
    );
    this.state = {
      category: category[0],
      showActions: false,
    };

    this.toggleShowActions = this.toggleShowActions.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onToggleCompleted = this.onToggleCompleted.bind(this);
    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
    this.onTaskClickHandler = this.onTaskClickHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const task = this.props.task;
    const daysLeft = DateUtil.diffInDays(new Date(), task.endDate);

    const taskStyle = classNames("task", {
      zebra: this.props.zebra,
      "show-actions": this.state.showActions,
      completed: task.status === TaskStatus.Completed,
    });
    const color = this.state.category
      ? ColorUtil.hexToRgb(this.state.category.categoryBackColor)
      : null;
    const label = this.state.category ? this.state.category.displayName : null;
    const completedIcon =
      task.status === TaskStatus.Completed ? "fa-check-square" : "fa-square";
    const toggleTaskStyle = classNames("fal fa-fw ", completedIcon);
    return (
      <div
        styleName={taskStyle}
        ref={(ref) => (this.ref = ref)}
        onClick={this.onTaskClickHandler}
        data-cy="CY-taskListItem"
      >
        <div styleName="task__data">
          <div
            styleName="task__category-hint"
            style={{ backgroundColor: color }}
          />
          <div
            styleName="task__toggle"
            onClick={this.onToggleCompleted}
            data-cy="CY-toggleTask"
          >
            <i className={toggleTaskStyle} />
          </div>
          {task.priority === Priority.High && (
            <div styleName="important">
              <i className="fa fa-fw fa-exclamation" />
            </div>
          )}
          <div styleName="task__meta__container">
            <div styleName="task__meta">
              <div styleName="title" data-cy="CY-taskListItemTitle">
                <strong>{task.subject}</strong>
                &nbsp;
                {!this.props.isCurrentUser &&
                  has(task, "linkedEmployee.displayName") && (
                    <ResourceText
                      resourceKey="taskTaskFor"
                      values={{ employee: task.linkedEmployee.displayName }}
                      asHtml
                    />
                  )}
              </div>
              <div styleName="meta">
                {label && (
                  <span styleName="category">
                    <span
                      styleName="category__hint"
                      style={{ backgroundColor: color }}
                    />
                    <span styleName="category__label">{label}</span>
                  </span>
                )}

                {task.linkedRelations &&
                  task.linkedRelations.map((relation, idx) => (
                    <NavLink
                      key={idx}
                      to={this.getRelationRoute(relation)}
                      styleName="linkedRelation"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <i
                        className={
                          relation.typeOfRelation === RelationType.ContactPerson
                            ? "fal fa-fw fa-user-alt"
                            : "fal fa-fw fa-industry"
                        }
                      />
                      <div styleName="label">{relation.displayName}</div>
                    </NavLink>
                  ))}

                {task.linkedAssignments &&
                  task.linkedAssignments.map((assignment, idx) => (
                    <NavLink
                      key={idx}
                      to={this.getAssignmentPath(assignment)}
                      styleName="linkedAssignment"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <i className="fal fa-fw fa-folder-open" />
                      <div styleName="label">{assignment.displayName}</div>
                    </NavLink>
                  ))}
              </div>
            </div>
            <div styleName="dates">
              <div styleName="dates__createdon">
                {task.endDate && (
                  <FormattedDate
                    value={task.endDate}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                )}
                {!task.endDate && (
                  <ResourceText resourceKey="taskListItemNoDate" />
                )}
              </div>
              {daysLeft && this.getDateTarget(daysLeft.status, daysLeft.value)}
            </div>
          </div>
          <div
            styleName="toggle"
            onClick={this.toggleShowActions}
            data-cy="CY-toggleShowActions"
          >
            <i id="toggleTask" className="fal fa-fw fa-ellipsis-v" />
          </div>
        </div>
        <div styleName="actions">
          <div
            styleName="action actions__delete"
            onClick={this.onRemoveClickHandler}
            data-cy="CY-removeTask"
          >
            <i id="deleteTask" className="fal fa-fw fa-times" />
          </div>
          <NavLink to={route(TASKROUTES.TASK.URI, { id: this.props.task.id })}>
            <div styleName="action actions__edit">
              <i id="editTask" className="fal fa-fw fa-pencil" />
            </div>
          </NavLink>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TaskListItemComponentProps
  ) {
    if (
      nextProps.task &&
      nextProps.task.categoryId !== this.props.task.categoryId
    ) {
      const category = this.props.categories.filter(
        (category) => category.value === nextProps.task.categoryId
      );
      this.setState({ category: category[0] });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onTaskClickHandler() {
    this.props.onClick(this.props.task.id);
  }

  private onToggleCompleted(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    const status =
      this.props.task.status === TaskStatus.Completed
        ? TaskStatus.NotStarted
        : TaskStatus.Completed;
    this.props.toggleTaskStatus(this.props.task, status);
  }

  private toggleShowActions(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    this.setState({ showActions: !this.state.showActions });
  }

  private onRemoveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.removeTask(this.props.task.id);
  }

  private onClickOutsideHandler(event: any) {
    if (!this.ref.contains(event.target) && !!this.state.showActions)
      this.setState({ showActions: false });
  }

  private getRelationRoute(relation: LinkedRelation): string {
    switch (relation.typeOfRelation) {
      case RelationType.ContactPerson:
        return route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
          id: relation.id,
        });
      case RelationType.ContactCompany:
        return route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
          id: relation.id,
        });
      default:
        return null;
    }
  }

  private getDateTarget(status: DiffStatus, days) {
    switch (status) {
      case DiffStatus.Negative: {
        return (
          <div styleName="dates__target overdue">
            <ResourceText
              resourceKey="amountOfDaysPastDueDate"
              values={{ days }}
            />
          </div>
        );
      }
      case DiffStatus.Today: {
        return (
          <div styleName="dates__target today">
            <ResourceText resourceKey="amountOfDays" values={{ days }} />
          </div>
        );
      }
      case DiffStatus.Positive: {
        return (
          <div id="dates__target" styleName="dates__target">
            <ResourceText resourceKey="amountOfDays" values={{ days }} />
          </div>
        );
      }
      default:
        return;
    }
  }

  private getAssignmentPath({
    typeOfAssignment,
    id,
  }: LinkedAssignment): string {
    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return path;
  }
}
