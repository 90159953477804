import * as React from "react";
import { memo, FC, useRef, useMemo } from "react";
import Hint from "@haywork/components/ui/hint";
import { useIntl } from "react-intl";
import * as moment from "moment";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

export type ColumnRowProps = {
  label: string;
  value: string | number | Date | string[];
  type: "string" | "number" | "date" | "array" | "squaremeters" | "cubicmeters";
  prefix?: string;
  values?: Record<string, any>;
};

type Props = {
  label: string;
  labelPrefix?: string;
  labelValues?: Record<string, any>;
  item: ColumnRowProps;
};

export const KeyValueRowComponent: FC<Props> = memo(
  CSSModules(styles)(({ label, labelPrefix, labelValues, item }) => {
    const ref = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const translation = useMemo(() => {
      let id = label;
      if (!!labelPrefix) {
        id = `${labelPrefix}.${id}`;
      }

      return intl.formatMessage({ id }, labelValues);
    }, [label, labelPrefix, labelValues, intl]);

    const valueString = useMemo(() => {
      const { type, value, values, prefix } = item;
      switch (type) {
        case "date": {
          return moment(value as Date).format("DD-MM-YYYY");
        }
        case "string": {
          let id = value as string;
          if (!!prefix) {
            id = `${prefix}.${id}`;
          }

          return intl.formatHTMLMessage({ id }, values) as string;
        }
        case "array": {
          return (value as string[])
            .map((defaultMessage, idx) => {
              let translation = intl.formatMessage({
                id: [prefix, defaultMessage].filter((d) => !!d).join("."),
                defaultMessage,
              });

              if (idx !== 0) {
                translation = translation.toLowerCase();
              }

              return translation;
            })
            .filter((d) => !!d)
            .join(", ");
        }
        case "number": {
          return intl.formatNumber(value as number, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        }
        case "squaremeters": {
          return (
            intl.formatNumber(value as number, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) + "m<sup>2</sup>"
          );
        }
        case "cubicmeters": {
          return (
            intl.formatNumber(value as number, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) + "m<sup>3</sup>"
          );
        }
        default: {
          return value.toString();
        }
      }
    }, [item, intl]);

    return (
      <div styleName="column__row">
        <div styleName="label">{translation}</div>
        <div styleName="value" ref={ref}>
          <span dangerouslySetInnerHTML={{ __html: valueString }} />
          <Hint parentRef={ref} label={valueString} onlyOnParentOverflow />
        </div>
      </div>
    );
  })
);
