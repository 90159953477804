import { AcquisitionAssignment } from "@haywork/api/kolibri";
import FormattedText from "@haywork/components/ui/formatted-text";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { Colors } from "@haywork/enum/colors";

type Props = {
  acquisition: AcquisitionAssignment;
};
export const AcquisitionInfoComponent: FC<Props> = memo(({ acquisition }) => {
  const {
    forSale,
    forRent,
    realEstateGroup,
    commissionGross,
    commissionPercent,
    commissionGrossRent,
    commissionPercentRent
  } = acquisition;

  const intl = useIntl();

  const title = useMemo(() => {
    let title = "";

    switch (true) {
      case !!forSale && !!forRent: {
        title = intl.formatMessage({
          id: "acquisition.info.forSaleAndForRent",
          defaultMessage: "For sale and rent"
        });
        break;
      }
      case !!forSale: {
        title = intl.formatMessage({
          id: "acquisition.info.forSale",
          defaultMessage: "For sale"
        });
        break;
      }
      case !!forRent: {
        title = intl.formatMessage({
          id: "acquisition.info.forRent",
          defaultMessage: "For rent"
        });
        break;
      }
      default:
        break;
    }

    if (!!realEstateGroup) {
      title = `${title}, ${intl.formatMessage({
        id: `realEstateGroups.${realEstateGroup.toString()}`,
        defaultMessage: realEstateGroup.toString()
      })}`;
    }

    return title;
  }, [forSale, forRent, realEstateGroup]);

  const commissionSale = useMemo(() => {
    switch (true) {
      case !forSale:
      default: {
        return null;
      }
      case !!commissionGross && !!commissionPercent: {
        return intl.formatMessage(
          {
            id: "acquisition.info.commissionGrossAndPercent",
            defaultMessage: ""
          },
          {
            gross: intl.formatNumber(commissionGross, {
              style: "currency",
              currency: "EUR"
            }),
            percent: commissionPercent
          }
        );
      }
      case !!commissionGross: {
        return intl.formatMessage(
          { id: "acquisition.info.commissionGross", defaultMessage: "" },
          {
            gross: intl.formatNumber(commissionGross, {
              style: "currency",
              currency: "EUR"
            })
          }
        );
      }
      case !!commissionPercent: {
        return intl.formatMessage(
          { id: "acquisition.info.commissionPercent", defaultMessage: "" },
          {
            percent: commissionPercent
          }
        );
      }
    }
  }, [forSale, commissionGross, commissionPercent]);

  const commissionRent = useMemo(() => {
    switch (true) {
      case !forRent:
      default: {
        return null;
      }
      case !!commissionGrossRent && !!commissionPercentRent: {
        return intl.formatMessage(
          {
            id: "acquisition.info.commissionGrossAndPercentRent",
            defaultMessage: ""
          },
          {
            gross: intl.formatNumber(commissionGrossRent, {
              style: "currency",
              currency: "EUR"
            }),
            percent: commissionPercentRent
          }
        );
      }
      case !!commissionGrossRent: {
        return intl.formatMessage(
          { id: "acquisition.info.commissionGrossRent", defaultMessage: "" },
          {
            gross: intl.formatNumber(commissionGrossRent, {
              style: "currency",
              currency: "EUR"
            })
          }
        );
      }
      case !!commissionPercentRent: {
        return intl.formatMessage(
          {
            id: "acquisition.info.commissionPercentRent",
            defaultMessage: ""
          },
          {
            percent: commissionPercentRent
          }
        );
      }
    }
  }, [forRent, commissionGrossRent, commissionPercentRent]);

  if (!acquisition) return null;

  return (
    <>
      <FormattedText
        value={title}
        type="header"
        bold
        chain
        textStyle="ucfirst"
        skipTranslation
      />
      {!!commissionSale && (
        <FormattedText
          value={commissionSale}
          type="paragraph"
          color={Colors.Gray}
          skipTranslation
          chain
        />
      )}
      {!!commissionRent && (
        <FormattedText
          value={commissionRent}
          type="paragraph"
          color={Colors.Gray}
          skipTranslation
        />
      )}
    </>
  );
});
