import { CompanySettings } from "@haywork/api/kolibri";
import { CompanySettingsThunks, Dispatch } from "@haywork/middleware";
import {
  SettingsAcquisitionAssignmentsComponent,
  SettingsAcquisitionAssignmentsComponentProps
} from "@haywork/modules/settings/modules/acquisition-assignments";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  settings: CompanySettings;
  settingsStatus: string;
}
interface DispatchProps {
  saveCompanySettings: (companySettings: CompanySettings) => void;
}

const mapStateToProps = <
  StateProps,
  SettingsAcquisitionAssignmentsComponentProps
>(
  state: AppState
) => {
  const { settings, settingsStatus } = state.company;

  return {
    settings,
    settingsStatus
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SettingsAcquisitionAssignmentsComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  saveCompanySettings: (companyListing: CompanySettings) =>
    dispatch(CompanySettingsThunks.saveCompanySettings(companyListing))
});

export type SettingsAcquisitionAssignmentsContainerProps = StateProps &
  DispatchProps;
export const SettingsAcquisitionAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  SettingsAcquisitionAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsAcquisitionAssignmentsComponent);
