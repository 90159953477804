import * as React from "react";
import { memo, useState, useRef, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { DefaultFolderTreeFolderEntity } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./style.scss");

interface Props {
  tree: DefaultFolderTreeFolderEntity;
  depth: number;
  parentName: string;
  onTreeUpdate: (path: string, tree: DefaultFolderTreeFolderEntity) => void;
  onTreeDelete: (path: string) => void;
}
export const SettingsDossierFoldersFolderComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ tree, depth, parentName, onTreeUpdate, onTreeDelete }: Props) => {
      const [collapsed, setCollapsed] = useState(true);
      const [folderName, setFolderName] = useState(tree.name);
      const [inputVisible, setInputVisible] = useState(false);
      const inputElement = useRef(null);

      const folders = tree.folders || [];
      const expandable = depth <= 3 && !!folders.length;
      const root = depth === 1;
      const name = [parentName, tree.name].filter((d) => !!d).join("/");

      useEffect(() => {
        if (!folders.length) {
          setCollapsed(true);
        }
      }, [folders.length]);

      const toggleCollapsed = () => {
        if (!expandable) return;
        setCollapsed(!collapsed);
      };
      const folderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const folderName = event.target.value;
        setFolderName(folderName);
      };
      const setNewFolderName = () => {
        setInputVisible(false);
        if (!folderName) {
          setFolderName(tree.name);
          return;
        }

        const updatedTree: DefaultFolderTreeFolderEntity = {
          ...tree,
          name: folderName,
        };
        onTreeUpdate(name, updatedTree);
      };
      const focusOnElement = () => {
        if (depth === 1) return;
        setInputVisible(true);
        setTimeout(() => inputElement.current.focus(), 5);
      };
      const deleteTree = () => {
        if (root) return;
        onTreeDelete(name);
      };

      return (
        <div styleName={classNames("folder", { collapsed })}>
          <div styleName={classNames("folder__header", `depth-${depth}`)}>
            {!!expandable && (
              <div styleName="toggle" onClick={toggleCollapsed}>
                <i className="fal fa-fw fa-chevron-right" />
              </div>
            )}
            <i
              className={classNames(
                "fa",
                "fa-fw",
                collapsed ? "fa-folder" : "fa-folder-open"
              )}
            />
            <div styleName="name">
              {!inputVisible ? (
                <div
                  styleName={classNames("toggle-trigger", {
                    root,
                  })}
                  onClick={focusOnElement}
                >
                  <ResourceText
                    masterKey="rootDossierFolder"
                    resourceKey={tree.name}
                    fallback={tree.name}
                  />
                </div>
              ) : (
                <input
                  ref={inputElement}
                  type="text"
                  value={folderName}
                  onChange={folderNameChange}
                  onBlur={setNewFolderName}
                  styleName="input"
                  data-lpignore="true"
                />
              )}
            </div>
            <div
              styleName={classNames("action", { root })}
              onClick={deleteTree}
            >
              <i
                className={classNames(
                  "fal",
                  depth === 1 ? "fa-lock" : "fa-times"
                )}
              />
            </div>
          </div>
          {!!expandable && (
            <div styleName="sub-folders">
              {folders.map((folder, idx) => (
                <SettingsDossierFoldersFolderComponent
                  tree={folder}
                  depth={depth + 1}
                  parentName={name}
                  key={name + idx}
                  onTreeUpdate={onTreeUpdate}
                  onTreeDelete={onTreeDelete}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
