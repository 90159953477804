import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  EmployeeRole,
  MonthStats,
  StatisticsSingleItemResponse,
  WeekStats,
} from "@haywork/api/kolibri";
import { MapDispatchToProps, StatisticsThunk } from "@haywork/middleware";
import { AuthorizationThunks } from "@haywork/middleware/thunk/authorization";
import {
  DashboardComponent,
  DashboardComponentProps,
} from "@haywork/modules/dashboard";
import { AppState } from "@haywork/stores";
import * as moment from "moment";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  statisticsThisWeek: WeekStats;
  statisticsLastWeek: WeekStats;
  statisticsThisMonth: MonthStats;
  statisticsLastMonth: MonthStats;
  companyStatisticsStatus: string;
  isBackofficeEmployee: boolean;
}

interface DispatchProps {
  getCompanyStatistics: () => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DashboardComponentProps,
  AppState
> = (state) => ({
  statisticsThisWeek: getStatisticsForWeek(
    state.statistics.companyStatistics,
    moment().week()
  ),
  statisticsLastWeek: getStatisticsForWeek(
    state.statistics.companyStatistics,
    moment().week() - 1
  ),
  statisticsThisMonth: getStatisticsForMonth(
    state.statistics.companyStatistics,
    moment().month() + 1
  ),
  statisticsLastMonth: getStatisticsForMonth(
    state.statistics.companyStatistics,
    moment().month()
  ),
  companyStatisticsStatus: state.statistics.companyStatisticsStatus,
  isBackofficeEmployee:
    state.account.currentRealestateAgency.role === EmployeeRole.BackOffice,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DashboardComponentProps
> = (dispatch) => ({
  getCompanyStatistics: () => dispatch(StatisticsThunk.getCompanyStatistics()),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

const getStatisticsForWeek = (
  statistics: StatisticsSingleItemResponse,
  weekNumber: number
): WeekStats => {
  if (!statistics) return;
  return statistics.weekStats.find(
    (statistics) => statistics.weekNr === weekNumber
  );
};

const getStatisticsForMonth = (
  statistics: StatisticsSingleItemResponse,
  monthNumber: number
): MonthStats => {
  if (!statistics) return;

  const stats = statistics.monthStats.find(
    (statistics) => statistics.month === monthNumber
  );
  return stats;
};

export type DashboardContainerProps = StateProps & DispatchProps;
export const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
