import { TableResponse, TaskSnapShot, TaskStatus } from "@haywork/api/kolibri";
import { TaskThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  DashboardTasksWidgetComponent,
  DashboardTasksWidgetComponentProps,
} from "../../components/widgets/tasks-widget.component";

interface StateProps {
  tasks: TaskSnapShot[];
  taskWidgetState: string;
  totalResults: number;
  mastertable: TableResponse;
}
interface DispatchProps {
  navigate: (route: string) => void;
  getUpcomingTasks: () => void;
  toggleTaskStatus: (id: string, status: TaskStatus) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DashboardTasksWidgetComponentProps,
  AppState
> = (state) => ({
  totalResults: state.task.totalResultsWidget,
  tasks: state.task.upcomingTasks,
  taskWidgetState: state.task.taskWidgetState,
  mastertable: state.main.mastertable.kolibri,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DashboardTasksWidgetComponentProps
> = (dispatch) => ({
  navigate: (route: string) => dispatch(push(route)),
  getUpcomingTasks: () => dispatch(TaskThunks.getUpcomingTasks()),
  toggleTaskStatus: (taskId: string, status: TaskStatus) =>
    dispatch(TaskThunks.updateWidgetItemStatus(taskId, status)),
});

export type TaskWidgetContainerProps = StateProps & DispatchProps;
export const TaskWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTasksWidgetComponent);
