import * as React from "react";
import { FC, memo, useMemo, useCallback } from "react";
import * as CSSmodules from "react-css-modules";
import {
  AssignmentSnapShot,
  AssignmentType,
  AvailabilityStatus,
} from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import head from "lodash-es/head";
import Pill from "@haywork/components/ui/pill";
import Button from "@haywork/components/ui/button";
import { ASSIGNMENTROUTES, PROJECTROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import I18n from "@haywork/components/i18n";

const styles = require("../../style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: AssignmentSnapShot;
  loading: boolean;
  onAction: (assignment: AssignmentSnapShot) => void;
  onNavigate: (path: string) => void;
};

export const ObjectRowComponent: FC<Props> = memo(
  CSSmodules(styles, { allowMultiple: true })(
    ({ assignment, loading, onAction, onNavigate }) => {
      const {
        photo1Url,
        photo2Url,
        photo3Url,
        id,
        typeOfAssignment,
        availabilityStatus,
        forRent,
        forSale,
        rentPrice,
        salePrice,
        rentCondition,
        saleCondition,
      } = assignment;

      const thumb = useMemo(() => {
        const filtered = [photo1Url, photo2Url, photo3Url].filter((d) => !!d);
        return head(filtered);
      }, [photo1Url, photo2Url, photo3Url]);

      const status = useMemo(() => {
        return !availabilityStatus ? null : (
          <I18n
            prefix="availabilityStatuses"
            value={availabilityStatus.toString()}
          />
        );
      }, [availabilityStatus]);

      const price = useMemo(() => {
        if (!!forRent && !!rentPrice) {
          const condition = !rentCondition ? null : (
            <I18n prefix="rentConditions" value={rentCondition.toString()} />
          );
          return (
            <>
              <I18n
                value="address.matchingProperties.rentPrice"
                values={{ price: rentPrice }}
                asHtml
              />
              {!condition ? null : <> {condition}</>}
            </>
          );
        }

        if (!!forSale && !!salePrice) {
          const condition = !saleCondition ? null : (
            <I18n prefix="saleConditions" value={saleCondition.toString()} />
          );
          return (
            <>
              <I18n
                value={
                  availabilityStatus === AvailabilityStatus.Available
                    ? "address.matchingProperties.purchasePrice"
                    : "address.matchingProperties.transactionPrice"
                }
                values={{ price: salePrice }}
                asHtml
              />
              {!condition ? null : <> {condition}</>}
            </>
          );
        }

        return null;
      }, [
        forRent,
        forSale,
        rentPrice,
        salePrice,
        rentCondition,
        saleCondition,
        availabilityStatus,
      ]);

      const onNavigateCallback = useCallback(() => {
        if (!id || !typeOfAssignment) return;
        let path = "";

        switch (typeOfAssignment) {
          case AssignmentType.Object: {
            path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
            break;
          }
          case AssignmentType.Project: {
            path = route(PROJECTROUTES.DETAIL.URI, { id });
            break;
          }
          default: {
            return;
          }
        }

        onNavigate(path);
      }, [id, typeOfAssignment, onNavigate]);

      const onActionCallback = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onAction(assignment);
        },
        [assignment, onAction]
      );

      return (
        <div styleName="row">
          <div styleName="row__column preview">
            <div
              styleName="thumb"
              style={{
                backgroundImage: !thumb
                  ? null
                  : `url(${JSON.stringify(thumb)})`,
              }}
            >
              {!thumb && (
                <div styleName="thumb__inner">
                  <Icon name="camera-alt" size={24} color={Colors.Gray} light />
                </div>
              )}
            </div>
          </div>
          <div styleName="row__column meta">
            <div styleName="meta">
              <Pill
                label="address.matchingProperties.sourceOwn"
                color={Colors.Warning}
                tiny
                fullWidth
                solid
              />
            </div>
            {!!status && (
              <div styleName="meta">
                <strong>{status}</strong>
              </div>
            )}
            {!!price && <div styleName="meta">{price}</div>}
          </div>
          <div styleName="row__column action">
            <Button
              category="primary"
              label="address.matchingProperties.action"
              onClick={onActionCallback}
              disabled={loading}
            />
          </div>
        </div>
      );
    }
  )
);
