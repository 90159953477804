import { AssignmentPhase, SearchAssignment } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import { SearchAssignmentEditComponent } from "@haywork/modules/search-assignment";
import { AppState, EditableItem } from "@haywork/stores";
import { SearchAssignmentActions } from "@haywork/stores/search-assignment";
import { AddressUtil, RouteUtil, SearchAssignmentUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  searchAssignment: SearchAssignment;
  canSave: boolean;
  canPublish: boolean;
  saveSearchAssignmentStatus: string;
}
interface DispatchProps {
  saveSearchAssignment: (searchAssignment: SearchAssignment) => void;
  saveInitialSearchAssignment: (searchAssignment: SearchAssignment) => void;
  publish: (id: string) => void;
  toggleSaveModal: (status: boolean) => void;
  reloadSearchAssignment: (editable: EditableItem) => Promise<void>;
}

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps = <StateProps, AssignmentEditComponentProps>(
  state: AppState
) => {
  const searchAssignment: SearchAssignment =
    state.editable.currentComponentState;
  const { isNew, assignmentPhase } = searchAssignment;
  const { saveSearchAssignmentStatus } = state.searchAssignment.single;

  const searchLocations = searchAssignment.locations || [];
  const locations = searchLocations.filter((location) =>
    AddressUtil.doesLocationHaveAddress(location)
  );

  return {
    searchAssignment,
    canSave: SearchAssignmentUtil.validForSave(searchAssignment),
    canPublish:
      !isNew &&
      assignmentPhase === AssignmentPhase.Concept &&
      !!locations.length,
    saveSearchAssignmentStatus,
  };
};

const mapDispatchToProps = <DispatchProps, AssignmentEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(SearchAssignmentThunks.saveSearchAssignment(searchAssignment)),
  publish: (id: string) =>
    dispatch(push(route(SEARCHASSIGNMENTROUTES.PUBLISH.URI, { id }))),
  toggleSaveModal: (status: boolean) =>
    dispatch(SearchAssignmentActions.toggleSaveModal(status)),
  saveInitialSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(
      SearchAssignmentThunks.saveSearchAssignment(
        searchAssignment,
        false,
        false,
        true
      )
    ),
  reloadSearchAssignment: (editable: EditableItem) =>
    dispatch(SearchAssignmentThunks.reloadSearchAssignment(editable)),
});

export type SearchAssignmentEditContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const SearchAssignmentEditContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchAssignmentEditComponent)
);
