import { SortOrder } from "@haywork/api/kolibri";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import isString from "lodash-es/isString";
import * as React from "react";
import {
  CSSProperties,
  FC,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import * as CSSModules from "react-css-modules";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ListColumnConfig } from ".";
import Header from "./components/header";
import { ListContext, ListContextProvider } from "./list.context";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import { Colors } from "@haywork/enum/colors";
import Button from "@haywork/components/ui/button";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";

const styles = require("./style.scss");
const HEADER_HEIGHT = 36;

const innerElementType = forwardRef<HTMLDivElement, any>(
  CSSModules(styles, { allowMultiple: true })(
    ({ style, children, ...rest }, ref) => {
      const { name, emptyState, hasError, refresh } = useContext(ListContext);

      const injectedStyle = useMemo(() => {
        return {
          ...style,
          height: `${parseFloat(style.height) + HEADER_HEIGHT}px`,
        } as CSSProperties;
      }, [style]);

      return (
        <div
          ref={ref}
          styleName="inner-element"
          style={injectedStyle}
          {...rest}
        >
          <Header listName={name} />

          <EntityListStatus list={name} onClick={refresh} style={{ top: 48 }} />

          {hasError && (
            <div styleName="error">
              <Icon
                name="exclamation-circle"
                size={96}
                light
                color={Colors.Danger}
              />
              <div styleName="error__inner">
                <h1>
                  <I18n value="list.fetch.error.title" />
                </h1>
                <h2>
                  <I18n value="list.fetch.error.body" />
                </h2>
              </div>
              <Button
                label="list.fetch.error.action"
                category="primary"
                onClick={refresh}
              />
            </div>
          )}
          {!hasError && children}
          {!hasError && !!emptyState && <div>{emptyState}</div>}
        </div>
      );
    }
  )
);

const outerElementType = forwardRef<HTMLDivElement, any>(
  ({ onScroll, ...rest }, ref) => {
    const { onScrollCallback } = useContext(ListContext);

    const handleOnScroll = useCallback(
      debounce((target: HTMLDivElement) => {
        if (!target || !onScrollCallback) return;
        onScrollCallback(target.scrollTop);
      }, 250),
      [onScrollCallback]
    );

    return (
      <div
        ref={ref}
        onScroll={(e) => {
          handleOnScroll(e.target as HTMLDivElement);
          onScroll(e);
        }}
        {...rest}
      />
    );
  }
);

const Row: FC<ListChildComponentProps> = memo(
  CSSModules(styles, { allowMultiple: true })(({ style, index, data }) => {
    const { items, renderer } = data;
    const item = items[index];

    const injectedStyle = useMemo(() => {
      let top = !style.top
        ? 0
        : isString(style.top)
        ? parseFloat(style.top)
        : style.top;
      top = top + HEADER_HEIGHT;

      return {
        ...style,
        top,
      };
    }, [style, index]);

    return (
      <div style={injectedStyle} styleName="row">
        {renderer(item, index)}
      </div>
    );
  })
);

type Props = {
  name: string;
  rowHeight: number;
  columnConfig: ListColumnConfig;
  data: any[];
  totalCount: number;
  sortColumn: any;
  sortOrder: SortOrder;
  minimumBatchSize?: number;
  emptyState?: ReactNode;
  initialScrollOffset?: number;
  allowCancel?: boolean;
  children: (data: any, idx: number) => ReactNode;
  loadMore: (startIndex: number, stopIndex: number) => Promise<any>;
  onSortChange: (orderBy: any, order: SortOrder) => void;
  onAction?: (item: any, action: any) => void;
  onScroll?: (scrollTop: number) => void;
};

export type ListRefProps = {
  refresh: () => void;
};

export const ListComponent = memo(
  forwardRef<ListRefProps, Props>(
    CSSModules(styles, { allowMultiple: true })(
      (
        {
          rowHeight,
          name,
          columnConfig,
          data,
          totalCount,
          children,
          loadMore,
          sortColumn,
          sortOrder,
          onSortChange,
          minimumBatchSize,
          onAction,
          onScroll,
          emptyState: outerEmptyState,
          initialScrollOffset,
          allowCancel,
        },
        forwardedRef
      ) => {
        const [loading, setLoading] = useState(false);
        const [abortedRequest, setAbortedRequest] = useState(false);
        const [sorting, setSorting] = useState({ sortColumn, sortOrder });
        const [innerRef, setInnerRef] = useState<InfiniteLoader | null>(null);
        const [hasError, setHasError] = useState(false);
        const initial = useRef(true);

        useEffect(() => {
          if (process.env.NODE_ENV === "development") {
            if (!columnConfig || !columnConfig.columns)
              throw Error("List missing column configuration.");

            if (!columnConfig.columns.length)
              throw Error(
                "Column configuration needs atleast one defined column."
              );

            const numberOfAutofillColumns = columnConfig.columns.filter(
              (column) => !!column.autoFill
            ).length;

            if (numberOfAutofillColumns === 0)
              throw Error("No auto-fill column found in column configuration.");

            if (numberOfAutofillColumns > 1)
              throw Error(
                "Column configuration should only have one auto-fill column."
              );
          }
        }, [columnConfig]);

        useEffect(() => {
          if (process.env.NODE_ENV === "development") {
            if (typeof children !== "function")
              throw Error(
                "Type of children should be a function returning a row component."
              );
          }
        }, [children]);

        const emptyState = useMemo(() => {
          if (!outerEmptyState || loading || totalCount > 0) return null;
          return outerEmptyState;
        }, [outerEmptyState, loading, totalCount]);

        const calculatedTotalCount = useMemo(() => {
          if (hasError) return 0;
          if (loading && !totalCount) return 100;
          return totalCount;
        }, [totalCount, loading, hasError]);

        const isItemLoaded = useCallback(
          (index: number) => !!data[index],
          [data]
        );

        const loadMoreItems = useCallback(
          async (startIndex: number, stopIndex: number, isInitial = false) => {
            let aborted = false;

            if (
              stopIndex === 0 ||
              (loading && !allowCancel) ||
              (!isInitial && startIndex === 0)
            )
              return;

            try {
              setLoading(true);
              await loadMore(startIndex, stopIndex);
            } catch (error) {
              if (error?.code === DOMException.ABORT_ERR && allowCancel) {
                aborted = true;
              } else {
                setHasError(true);
              }
            } finally {
              if (!aborted) {
                setLoading(false);
              }
            }
          },
          [setLoading, loadMore, setHasError, setAbortedRequest]
        );

        const onSortChangeCallback = useCallback(
          async (sortColumn: any, sortOrder: SortOrder) => {
            if (
              sortColumn === sorting.sortColumn &&
              sortOrder === sorting.sortOrder
            )
              return;

            onSortChange(sortColumn, sortOrder);
            setSorting({ sortColumn, sortOrder });
          },
          [onSortChange, sorting, setSorting]
        );

        const onActionCallback = useCallback(
          (item: any, action: any) => {
            if (!onAction) return;
            onAction(item, action);
          },
          [onAction]
        );

        const onScrollCallback = useCallback(
          (scrollTop: number) => {
            if (!onScroll) return;
            onScroll(scrollTop);
          },
          [onScroll]
        );

        const refresh = useCallback(() => {
          if (!innerRef) return;
          const list: List = get(innerRef, "_listRef");
          if (!list) return;

          setHasError(false);
          list.scrollTo(0);
          loadMoreItems(0, minimumBatchSize, true);
        }, [innerRef, loadMoreItems, minimumBatchSize, setHasError]);

        useEffect(() => {
          if (!forwardedRef) return;
          forwardedRef["current"] = { refresh };
        }, [forwardedRef, refresh]);

        useEffect(() => {
          if (!initial.current) return;
          initial.current = false;

          if (!data.length) {
            loadMoreItems(0, minimumBatchSize, true);
          }
        }, [initial, loadMoreItems, data.length]);

        return (
          <ListContextProvider
            value={{
              name,
              columnConfig,
              rowHeight,
              emptyState,
              onSortChangeCallback,
              onActionCallback,
              onScrollCallback,
              sorting,
              hasError,
              refresh,
            }}
          >
            <div styleName="wrapper">
              <div styleName="list">
                <AutoSizer>
                  {({ width, height }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={calculatedTotalCount}
                      loadMoreItems={loadMoreItems}
                      minimumBatchSize={minimumBatchSize || 25}
                      ref={setInnerRef}
                    >
                      {({ onItemsRendered, ref }) => (
                        <List
                          width={width}
                          height={height}
                          itemCount={calculatedTotalCount}
                          itemSize={rowHeight}
                          innerElementType={innerElementType}
                          outerElementType={outerElementType}
                          itemData={{
                            items: data,
                            renderer: children,
                          }}
                          onItemsRendered={onItemsRendered}
                          initialScrollOffset={initialScrollOffset}
                          ref={ref}
                        >
                          {Row}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </div>
            </div>
          </ListContextProvider>
        );
      }
    )
  )
);
