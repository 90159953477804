import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";

import {
  Form,
  FormControls,
  Input,
  FormReturnValue,
} from "@haywork/modules/form";
import { ObjectTypeAssignment, TypePART } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("../edit-general.component.scss");

interface Props {
  currentType: ObjectTypeAssignment;
  onChange: (type: ObjectTypeAssignment) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesUnitAndSizesDefaultComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    const {
      usableArea,
      parcelSurface,
      livingRoomSurface,
      contents,
      amountUnits,
      amountFreeUnits,
    } = this.props.currentType;

    this.formControls = {
      usableAreaMin: { value: get(usableArea, "minArea") },
      usableAreaMax: { value: get(usableArea, "maxArea") },
      parcelSurfaceMin: { value: get(parcelSurface, "minArea") },
      parcelSurfaceMax: { value: get(parcelSurface, "maxArea") },
      livingRoomSurfaceMin: { value: get(livingRoomSurface, "minArea") },
      livingRoomSurfaceMax: { value: get(livingRoomSurface, "maxArea") },
      contentsMin: { value: get(contents, "minVolume") },
      contentsMax: { value: get(contents, "maxVolume") },
      amountUnits: { value: amountUnits },
      amountFreeUnits: { value: amountFreeUnits },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderNumberRangeFormRow = this.renderNumberRangeFormRow.bind(this);
  }

  public render() {
    const { forSale, typePART } = this.props.currentType;

    return (
      <Form
        name="objecttype-units-sizes-default"
        formControls={this.formControls}
        asSubForm={true}
        onChange={this.onChangeHandler}
      >
        {this.renderNumberRangeFormRow("usableArea", "squareMeters")}
        {typePART !== TypePART.Apartment &&
          this.renderNumberRangeFormRow("parcelSurface", "squareMeters")}
        {this.renderNumberRangeFormRow("livingRoomSurface", "squareMeters")}
        {this.renderNumberRangeFormRow("contents", "cubicMeters")}

        {/* Amount of units */}
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor="amountUnits" className="pre">
                <ResourceText resourceKey="projectTypesLabel.amountUnits" />
              </label>
            </div>
            <div className="column" styleName="range-input">
              <Input.Number
                name="amountUnits"
                data-cy="CY-amountOfUnitsInput"
              />
            </div>
          </div>
        </div>

        {/* Amount of free units */}
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="range-label">
              <label htmlFor="amountFreeUnits" className="pre">
                <ResourceText resourceKey="projectTypesLabel.amountFreeUnits" />
              </label>
            </div>
            <div className="column" styleName="range-input">
              <Input.Number
                name="amountFreeUnits"
                data-cy="CY-amountOfFreeUnitsInput"
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const type = {
      ...this.props.currentType,
      amountUnits: values.amountUnits,
      amountFreeUnits: values.amountFreeUnits,
      contents: {
        ...this.props.currentType.contents,
        minVolume: values.contentsMin,
        maxVolume: values.contentsMax,
      },
      livingRoomSurface: {
        ...this.props.currentType.livingRoomSurface,
        minArea: values.livingRoomSurfaceMin,
        maxArea: values.livingRoomSurfaceMax,
      },
      parcelSurface: {
        ...this.props.currentType.parcelSurface,
        minArea: values.parcelSurfaceMin,
        maxArea: values.parcelSurfaceMax,
      },
      usableArea: {
        ...this.props.currentType.usableArea,
        minArea: values.usableAreaMin,
        maxArea: values.usableAreaMax,
      },
    };

    this.props.onChange(type);
  }

  private renderNumberRangeFormRow(name: string, postfix: string) {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column" styleName="range-label">
            <label htmlFor={`${name}Min`} className="pre">
              <ResourceText resourceKey={`projectTypesLabel.${name}`} />
            </label>
          </div>
          <div className="column" styleName="range-input">
            <div className="input__helper">
              <Input.Number
                name={`${name}Min`}
                min={0}
                max={999999999999}
                data-cy={"CY-" + name + "Min"}
              />
              <div className="post">
                <ResourceText resourceKey={postfix} asHtml />
              </div>
            </div>
          </div>
          <div className="column__textspacer">
            <ResourceText resourceKey="untilAnd" />
          </div>
          <div className="column" styleName="range-input">
            <div className="input__helper">
              <Input.Number
                name={`${name}Max`}
                min={0}
                max={999999999999}
                data-cy={"CY-" + name + "Max"}
              />
              <div className="post">
                <ResourceText resourceKey={postfix} asHtml />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
