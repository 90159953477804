import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FolderTreeFileEntity, FolderTreeFileType } from "@haywork/api/kolibri";
import classNames from "classnames";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  file: FolderTreeFileEntity;
  depth: number;
  selectedFiles: FolderTreeFileEntity[];
  onToggleSelect: (file: FolderTreeFileEntity) => void;
};

export const FileComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ file, depth, selectedFiles, onToggleSelect }) => {
      const {
        type,
        linkedDocumentSession,
        linkedDossierItem,
        linkedInvoice,
        id,
      } = file;

      const selected = useMemo(() => {
        return selectedFiles.map((f) => f.id).includes(id);
      }, [selectedFiles, id]);

      const icon = useMemo(() => {
        let name = "file";
        switch (type) {
          case FolderTreeFileType.DocumentSession: {
            name = "file-contract";
            break;
          }
          case FolderTreeFileType.Invoice: {
            name = "euro-sign";
            break;
          }
          case FolderTreeFileType.DossierItem: {
            if (!linkedDossierItem || !linkedDossierItem.fileExtension) break;
            const { fileExtension } = linkedDossierItem;
            const imagesRegex = /(bmp|jpe?g|gif|png|tiff)/gi;
            const wordRegex = /docx?/gi;
            const excelRegex = /xlsx?/gi;
            const powerpointRegex = /pptx?/gi;
            const pdfRegex = /(pdf|ps)/gi;
            const videoRegex = /(mov|mp4|m4v|3gp|3g2|avi|mpe?g|wmv|asf|ram)/gi;
            const audioRegex = /(mp3|wav|aiff?|mpa|m4a|wma)/gi;

            switch (true) {
              case imagesRegex.test(fileExtension): {
                name = "file-image";
                break;
              }
              case wordRegex.test(fileExtension): {
                name = "file-word";
                break;
              }
              case excelRegex.test(fileExtension): {
                name = "file-excel";
                break;
              }
              case powerpointRegex.test(fileExtension): {
                name = "file-powerpoint";
                break;
              }
              case pdfRegex.test(fileExtension): {
                name = "file-pdf";
                break;
              }
              case videoRegex.test(fileExtension): {
                name = "file-video";
                break;
              }
              case audioRegex.test(fileExtension): {
                name = "file-audio";
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }

        return <Icon name={name} size={18} light color={Colors.Black} />;
      }, [type, linkedDossierItem]);

      const info = useMemo(() => {
        switch (type) {
          case FolderTreeFileType.DocumentSession: {
            if (!linkedDocumentSession) return "";
            const { name } = linkedDocumentSession;
            return name;
          }
          case FolderTreeFileType.DossierItem: {
            if (!linkedDossierItem) return "";
            const { name, fileName, fileExtension } = linkedDossierItem;
            return (
              name || [fileName, fileExtension].filter((d) => !!d).join("")
            );
          }
          case FolderTreeFileType.Invoice: {
            if (!linkedInvoice) return "";
            const { displayName } = linkedInvoice;
            return (
              displayName || <I18n value="dossier.invoice.conceptInvoice" />
            );
          }
          default: {
            return "";
          }
        }
      }, [type, linkedDocumentSession, linkedDossierItem, linkedInvoice]);

      return (
        <div styleName={classNames("file", `depth-${depth}`)}>
          <div styleName="file__icon">{icon}</div>
          <div styleName="file__select">
            <Input.CheckBox
              name={`select-${id}`}
              asSingleInput
              value={selected}
              onChange={() => onToggleSelect(file)}
            />
          </div>
          <div styleName="file__info">{info}</div>
        </div>
      );
    }
  )
);
