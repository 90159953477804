import { RootEntityType } from "@haywork/api/event-center";
import {
  AssignmentSnapShot,
  AvailabilityStatus,
  ContactCompany,
  ContactPerson,
  CountryOption,
  Employee,
  FinancialAdministration,
  Invoice,
  InvoiceDueTerm,
  InvoiceDueTermOption,
  InvoiceLine,
  InvoiceStatus,
  InvoiceStatusOption,
  InvoiceTypeOption,
  LinkedAssignment,
  ObjectAssignment,
  ProductOrder,
  RealEstateGroup,
  RelationType,
  VatCondition,
  BatchResponseItem,
  BatchItem,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import { INVOICEROUTES, MAINROUTES } from "@haywork/constants";
import {
  Dispatch,
  FinancialThunks,
  InvoiceThunk,
  BlobThunk,
} from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import {
  InvoiceDetailComponent,
  InvoiceDetailComponentProps,
} from "@haywork/modules/invoice";
import {
  AppState,
  EditableActions,
  EditableItem,
  InvoiceActions,
} from "@haywork/stores";
import { push } from "connected-react-router";
import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect } from "react-redux";
import {
  deposit,
  invoiceTotalGross,
  invoiceTotalNet,
  invoiceTotalTax,
  rentPrice,
  vatCondition,
} from "./selectors";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";

interface StateProps {
  currentAccount: string;
  isActive: boolean;
  invoice: Invoice;
  invoiceStatus: string;
  invoiceStatuses: InvoiceStatusOption[];
  invoiceDueTerms: InvoiceDueTermOption[];
  invoiceTypes: InvoiceTypeOption[];
  realEstateAgencyId: string;
  host: string;
  countries: CountryOption[];
  countryIso2: string;
  addressSearchStatus: string;
  formalName: string;
  financialAdministrations: FinancialAdministration[];
  financialAdministrationState: string;
  financialAdministration: FinancialAdministration;
  productOrders: ProductOrder[];
  shouldShowMarketingBudgetButton: boolean;
  shouldShowCourtageButton: boolean;
  canAddCourtage: boolean;
  realEstateGroup: RealEstateGroup;
  courtage: number;
  culture: string;
  linkedAssignmentSnapShot: AssignmentSnapShot;
  deposit: number;
  rentPrice: number;
  vatCondition: VatCondition;
  invoiceTotalGross: number;
  invoiceTotalNet: number;
  invoiceTotalTax: number;
}
interface DispatchProps {
  saveInvoice: (invoice: Invoice, close: boolean) => void;
  updateInvoice: (componentState: any, path: string) => void;
  setFinancialAdministration: (
    id: string,
    ignoreChanges: boolean,
    path?: string
  ) => void;
  getDataFromAssignment: (id: string, newAssignment?: boolean) => void;
  updatePrices: (invoiceLines: InvoiceLine[]) => void;
  saveAndCloseInvoice: () => void;
  navigate: (url: string) => void;
  updateDueDateWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => void;
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) => void;
  archive: (id: string) => void;
  unarchive: (id: string) => void;
  getPrintTemplates: (invoiceId: string) => void;
  getPrintFormats: () => void;
  archiveInvoice: (invoice: Invoice, update: boolean) => void;
  unArchiveInvoice: (id: string, update: boolean) => void;
  deleteInvoice: (id: string, redirect: boolean) => void;
  getDataFromRelation: (
    id: string,
    typeOfRelation: RelationType
  ) => Promise<ContactPerson | Employee | ContactCompany>;
  setFormalName: (name: string) => void;
  reloadInvoice: (editable: EditableItem) => Promise<void>;
  createBatchDownload: (items: BatchItem[]) => Promise<BatchResponseItem[]>;
  uploadFilesFromUri: (
    accountId: string,
    files: BatchResponseItem[]
  ) => Promise<UploadResponse[]>;
  createNewEmailWithAttachments: (
    attachments: UploadResponse[],
    to?: LinkedRelation[] | undefined,
    subject?: string
  ) => void;
}

const shouldShowMarketingBudgetButton = (
  productOrders: ProductOrder[],
  invoiceLines: InvoiceLine[]
): boolean => {
  if (!productOrders || productOrders.length === 0) return false;
  const invoiceLineIds = invoiceLines.map((line) => line.productToPassOnID);
  const orders = productOrders.filter(
    (p) => invoiceLineIds.indexOf(p.id) === -1
  );

  return orders.length > 0;
};

const shouldShowCourtageButton = (
  snapshot: AssignmentSnapShot,
  invoice: Invoice
): boolean => {
  if (!snapshot || !invoice) return false;

  const lines = invoice.lines || [];
  const line = lines.find((line) => line.productID === -1);
  return !line;
};

const canAddCourtage = (snapshot: AssignmentSnapShot): boolean => {
  if (!snapshot) return false;
  return !!snapshot.forSale
    ? !!snapshot.saleCommissionTotal
    : !!snapshot.rentCommissionTotal;
};

const mapStateToProps = <StateProps, InvoiceDetailComponentProps>(
  state: AppState
) => {
  const { currentComponentState: invoice } = state.editable;
  const { currentAccount } = state.emailV2.main;
  const {
    invoiceStatus,
    addressSearchStatus,
    invoice: initialInvoice,
    linkedAssignment,
    linkedAssignmentSnapShot,
    formalName,
  } = state.invoice.single;
  const { financialAdministrations, financialAdministrationState } =
    state.account;
  const financialAdministration = !!get(invoice, "financialAdministrationID")
    ? financialAdministrations.find(
        (a) => a.id === invoice.financialAdministrationID
      )
    : null;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const { invoiceStatuses, invoiceDueTerms, countries, invoiceTypes } =
    state.main.mastertable.kolibri;
  const productOrders = mapProductOrders(
    linkedAssignment,
    financialAdministration
  );
  const { culture } = state.main;

  return {
    currentAccount,
    isActive: initialInvoice.isActive,
    invoice,
    invoiceStatus,
    invoiceStatuses: filterInvoiceStatuses(invoiceStatuses, initialInvoice),
    invoiceDueTerms: filterInvoiceDueTerms(invoiceDueTerms, invoice),
    realEstateAgencyId,
    host,
    countries,
    countryIso2: "NL", // work around, default country needs to be selectable in settings.
    addressSearchStatus,
    formalName,
    financialAdministrations,
    financialAdministrationState,
    financialAdministration,
    productOrders,
    invoiceTypes,
    shouldShowMarketingBudgetButton: shouldShowMarketingBudgetButton(
      productOrders,
      invoice ? invoice.lines : []
    ),
    shouldShowCourtageButton: shouldShowCourtageButton(
      linkedAssignmentSnapShot,
      invoice
    ),
    canAddCourtage: canAddCourtage(linkedAssignmentSnapShot),
    realEstateGroup: !!linkedAssignment
      ? linkedAssignment.realEstateGroup
      : null,
    courtage: !!linkedAssignmentSnapShot
      ? !!linkedAssignmentSnapShot.forSale
        ? linkedAssignmentSnapShot.saleCommissionTotal
        : linkedAssignmentSnapShot.rentCommissionTotal
      : 0,
    culture,
    linkedAssignmentSnapShot,
    deposit: deposit(state),
    rentPrice: rentPrice(state),
    vatCondition: vatCondition(state),
    invoiceTotalGross: invoiceTotalGross(state),
    invoiceTotalNet: invoiceTotalNet(state),
    invoiceTotalTax: invoiceTotalTax(state),
  };
};

const filterInvoiceDueTerms = (
  terms: InvoiceDueTermOption[],
  invoice: Invoice
) => {
  let filteredTerms = terms;

  if (!!get(invoice, "linkedAssignment")) {
    switch (invoice.linkedAssignment.availabilityStatus) {
      case AvailabilityStatus.Sold:
      case AvailabilityStatus.Rented:
      case AvailabilityStatus.Leased:
      case AvailabilityStatus.Farmed: {
        filteredTerms = terms;
        break;
      }
      default: {
        filteredTerms = terms.filter(
          (t) => t.value !== InvoiceDueTerm.TransportDate
        );
        break;
      }
    }
  } else {
    filteredTerms = terms.filter(
      (t) => t.value !== InvoiceDueTerm.TransportDate
    );
  }

  return sortBy(filteredTerms, "id");
};

const filterInvoiceStatuses = (
  statuses: InvoiceStatusOption[],
  invoice: Invoice
) => {
  if (invoice.status !== InvoiceStatus.Draft) {
    return statuses.filter((status) => status.value !== InvoiceStatus.Draft);
  }
  return statuses;
};

const mapProductOrders = (
  linkedAssignment: ObjectAssignment,
  administration: FinancialAdministration
): ProductOrder[] => {
  if (!linkedAssignment || !linkedAssignment.productOrders || !administration) {
    return [];
  }

  return linkedAssignment.productOrders.filter((product) => {
    return (
      product.financialAdministrationId === administration.id &&
      !!product.chargeToCustomerBudget &&
      !product.linkedInvoice
    );
  });
};

const mapDispatchToProps = <DispatchProps, InvoiceDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveInvoice: (invoice: Invoice, close: boolean) =>
    dispatch(InvoiceThunk.saveInvoice(invoice, close)),
  updateInvoice: (componentState: any, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  setFinancialAdministration: (
    id: string,
    ignoreChanges: boolean,
    path?: string
  ) =>
    dispatch(InvoiceThunk.setFinancialAdministration(id, ignoreChanges, path)),
  getDataFromAssignment: (id: string, newAssignment: boolean) =>
    dispatch(InvoiceThunk.getDataFromAssignment(id, newAssignment)),
  updatePrices: (invoiceLines: InvoiceLine[]) =>
    dispatch(InvoiceThunk.udpateTotalPrices(invoiceLines)),
  saveAndCloseInvoice: () => dispatch(InvoiceThunk.saveAndCloseInvoice()),
  navigate: (url: string) => dispatch(push(url)),
  updateDueDateWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(InvoiceThunk.updateDueDateWithLinkedAssignment(linkedAssignment)),
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) =>
    dispatch(
      FinancialThunks.updateFinancialAdministration(financialAdministration)
    ),
  archive: (id: string) => dispatch(InvoiceThunk.archiveInvoice(id)),
  unarchive: (id: string) => dispatch(InvoiceThunk.unArchiveInvoice(id)),
  getPrintTemplates: (invoiceId: string) =>
    dispatch(InvoiceThunk.getPrintTemplates(invoiceId)),
  getPrintFormats: () => dispatch(InvoiceThunk.getPrintFormats()),
  archiveInvoice: async (invoice: Invoice, update: boolean) => {
    await dispatch(InvoiceThunk.saveInvoice(invoice, false)),
      dispatch(InvoiceThunk.archiveInvoice(invoice.id, update, true));
  },

  unArchiveInvoice: (id: string, update: boolean) =>
    dispatch(InvoiceThunk.unArchiveInvoice(id, update)),
  deleteInvoice: (id: string, redirect: boolean) =>
    dispatch(InvoiceThunk.deleteInvoice(id, undefined, redirect)),
  getDataFromRelation: (id: string, typeOfRelation: RelationType) =>
    dispatch(InvoiceThunk.getDataFromRelation(id, typeOfRelation)),
  setFormalName: (name: string) => dispatch(InvoiceActions.setFormalName(name)),
  reloadInvoice: (editable: EditableItem) =>
    dispatch(InvoiceThunk.reloadInvoice(editable)),
  createBatchDownload: (items: BatchItem[]) =>
    dispatch(BlobThunk.createBatchDownload(items)),
  uploadFilesFromUri: (accountId: string, files: BatchResponseItem[]) =>
    dispatch(BlobThunk.uploadFilesFromUri(accountId, files)),
  createNewEmailWithAttachments: (
    attachments: UploadResponse[],
    to?: LinkedRelation[] | undefined,
    subject?: string
  ) =>
    dispatch(
      EmailThunk.Drafts.createNewEmailWithAttachments(attachments, to, subject)
    ),
});

export type InvoiceDetailContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any> &
  EditableHocProps;
export const InvoiceDetailContainer = editable(
  injectIntl(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(InvoiceDetailComponent)
  ),
  {
    entityType: RootEntityType.Invoice,
    icon: MAINROUTES.FINANCE.ICON,
    thunk: InvoiceThunk.getInvoice,
    status: "invoice.single.invoiceStatus",
    statePath: "invoice.single.invoice",
    action: InvoiceActions.setSingleInvoiceDirectly,
    route: INVOICEROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveInvoiceConfirmTitle" },
      body: { key: "saveInvoiceConfirmBody" },
    },
  }
);
