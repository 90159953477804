import * as React from "react";
import * as CSSModules from "react-css-modules";

import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { uiTableRow, Ui, UiTableRowProps } from "@haywork/modules/ui";

const styles = require("./list-item.component.scss");

interface TemplateListItemComponentProps {
  template: TemplateDefinitionSnapShot;
  zebra: boolean;
  onOpen: (id: string) => void;
  onDelete: (id: string) => void;
  onUndelete: (id: string) => void;
  onDeleteDone: (id: string) => void;
}
interface State {
  showActionOverlay: boolean;
}
type Props = TemplateListItemComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class TemplateListItemComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showActionOverlay: false
    };

    this.onActionDoneHandler = this.onActionDoneHandler.bind(this);
    this.onActionCancelHandler = this.onActionCancelHandler.bind(this);
  }

  public render() {
    const { template } = this.props;

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
        actionTitle="cancelEmailTemplateDelete"
        actionVisible={this.state.showActionOverlay}
        onActionDone={this.onActionDoneHandler}
        onActionCancel={this.onActionCancelHandler}
      >
        <div styleName="list-item">
          <div
            styleName="column list-item__name"
            data-cy="emailTemplateListItem"
          >
            {template.name}
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private renderListActions() {
    const deleteButton = () => (
      <button
        className="btn btn-danger"
        data-cy="CY-deleteTemplateButton"
        onClick={() => {
          this.props.onDelete(this.props.template.id);
          this.setState({ showActionOverlay: true });
        }}
      >
        <i className="fal fa-times" />
      </button>
    );

    const editButton = () => (
      <button
        className="btn btn-success"
        data-cy="CY-editTemplateButton"
        onClick={() => this.props.onOpen(this.props.template.id)}
      >
        <i className="fal fa-pencil" />
      </button>
    );

    return [deleteButton, editButton];
  }

  private onActionDoneHandler() {
    this.setState({
      showActionOverlay: false
    });
    this.props.onDeleteDone(this.props.template.id);
  }

  private onActionCancelHandler() {
    this.setState({
      showActionOverlay: false
    });
    this.props.onUndelete(this.props.template.id);
  }
}
