import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFiltersState, EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { currentFolder, filters } from "../../selectors";
import {
  ListFiltersComponent,
  ListFiltersComponentProps
} from "./list-filters";

type StateProps = {
  filters: EmailFiltersState;
  currentFolder: EmailFolder;
};
type DispatchProps = {
  setFilters: (filters: EmailFiltersState) => void;
  resetFilters: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListFiltersComponentProps,
  AppState
> = (state) => {
  return {
    filters: filters(state),
    currentFolder: currentFolder(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListFiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: EmailFiltersState) =>
    dispatch(EmailThunk.Main.setFilters(filters)),
  resetFilters: () => dispatch(EmailThunk.Main.resetFilters())
});

export type ListFiltersContainerProps = StateProps & DispatchProps;
export const ListFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListFiltersComponent);
