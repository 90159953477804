import {
  AirTreatmentOption,
  FacilityOption,
  ObjectAssignment,
  TypeOfCommunalAreaOption,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  airTreatmentOptions: AirTreatmentOption[];
  facilityOptions: FacilityOption[];
  typesOfCommunalArea: TypeOfCommunalAreaOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogOfficeComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      vVO: { value: value(objectAssignment, "vVO.area") },
      inUnitsFrom: { value: value(objectAssignment, "inUnitsFrom.area") },
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) },
      typesOfCommunalArea: {
        value: value(objectAssignment, "typesOfCommunalArea", []),
      },
      officeFacilities: {
        value: value(objectAssignment, "officeFacilities", []),
      },
      airTreatments: { value: value(objectAssignment, "airTreatments", []) },
      isTurnkey: { value: value(objectAssignment, "isTurnkey", false) },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update(
        {
          vVO: value(objectAssignment, "vVO.area"),
          inUnitsFrom: value(objectAssignment, "inUnitsFrom.area"),
          numberOfFloors: value(objectAssignment, "numberOfFloors", 0),
          typesOfCommunalArea: value(
            objectAssignment,
            "typesOfCommunalArea",
            []
          ),
          officeFacilities: value(objectAssignment, "officeFacilities", []),
          airTreatments: value(objectAssignment, "airTreatments", []),
          isTurnkey: value(objectAssignment, "isTurnkey", false),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="office"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="officeSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="inUnitsFrom">
                <ResourceText resourceKey="inUnitsFrom" />
              </label>
              <div className="input__helper">
                <Input.Number name="inUnitsFrom" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="numberOfFloors">
                <ResourceText resourceKey="numberOfFloorsBog" />
              </label>
              <Input.Number name="numberOfFloors" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="typesOfCommunalArea">
            <ResourceText resourceKey="typesOfCommunalArea" />
          </label>
          <Input.Multi
            name="typesOfCommunalArea"
            values={this.props.typesOfCommunalArea}
          />
        </div>

        <div className="form__row">
          <label htmlFor="officeFacilities">
            <ResourceText resourceKey="officeFacilities" />
          </label>
          <Input.Multi
            name="officeFacilities"
            values={this.props.facilityOptions}
          />
        </div>

        <div className="form__row">
          <label htmlFor="airTreatments">
            <ResourceText resourceKey="airTreatments" />
          </label>
          <Input.Multi
            name="airTreatments"
            values={this.props.airTreatmentOptions}
          />
        </div>

        <div className="form__row">
          <Input.Switch
            name="isTurnkey"
            label="isTurnkey"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const fields: any = {
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      inUnitsFrom: {
        ...objectAssignment.inUnitsFrom,
        area: values.inUnitsFrom,
      },
      numberOfFloors: values.numberOfFloors,
      typesOfCommunalArea: values.typesOfCommunalArea,
      officeFacilities: values.officeFacilities,
      airTreatments: values.airTreatments,
      isTurnkey: values.isTurnkey,
    };

    this.props.onChange(fields);
  }
}
