import { ProjectAssignment } from "@haywork/api/kolibri";
import { FailedBuildNumberRequest } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { Hint, ResourceText } from "@haywork/modules/shared";
import { ButtonLoader } from "@haywork/modules/shared/components/button-loader/button-loader.component";
import { ArrayUtil, AsyncUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import sortBy from "lodash-es/sortBy";
import uniq from "lodash-es/uniq";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AddBuildnumbersContainerProps } from "./add-buildnumber.container";

const styles = require("./add-buildnumbers.component.scss");

export interface AddBuildnumbersComponentProps {
  project: ProjectAssignment;
  price: number;
  objectTypeId?: string;
  onClose: () => void;
  onSuccess: (objectTypeId: string) => void;
}
interface State {
  buildnumbers: string;
  buildnumberCount: number;
  submitting: boolean;
  objectTypeId: string;
  showWarnings: boolean;
  failed: FailedBuildNumberRequest[];
}
type Props = AddBuildnumbersComponentProps & AddBuildnumbersContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AddBuildnumbersComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.state = {
      buildnumbers: "",
      buildnumberCount: 0,
      submitting: false,
      objectTypeId: this.props.objectTypeId || "",
      showWarnings: false,
      failed: [],
    };

    this.formControls = {
      objectType: {
        value: this.props.objectTypeId || "",
        onChange: (ref) => {
          this.setState({ objectTypeId: ref.value });
        },
      },
      price: { value: this.props.price || "" },
      buildnumberText: {
        value: "",
        validators: [Validators.buildnumbers()],
        onChange: (ref) => {
          if (this.state.showWarnings) {
            this.setState({ showWarnings: false });
          }
        },
      },
      buildnumberStep: { value: 1 },
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !this.props.addBuildnumbersVisible &&
      !!nextProps.addBuildnumbersVisible &&
      has(nextProps.project, "id")
    ) {
      this.props.getObjectTypes(nextProps.project.id);
    }

    if (nextProps.price !== this.props.price && !!this.formRef) {
      this.formRef.update({
        price: nextProps.price || "",
      });
    }

    if (
      !!nextProps.objectTypes &&
      nextProps.objectTypes.length > 0 &&
      !this.state.objectTypeId
    ) {
      const objectTypeId = nextProps.objectTypes[0].id;
      this.setState({ objectTypeId });
      this.formRef.update({ objectType: objectTypeId });
    }
  }

  public render() {
    const cannotSubmit =
      this.state.buildnumberCount === 0 ||
      this.state.submitting ||
      !this.state.objectTypeId;

    return (
      <Modal
        visible={this.props.addBuildnumbersVisible}
        onClose={this.onCloseHandler}
      >
        <ModalHeader
          title="addBuildnumbersModalTitle"
          titleValues={{
            projectName: get(this.props.project, "displayName", ""),
          }}
          close
        />
        <ModalBody noPadding>
          <div styleName="buildnumber__form">
            <Form
              name="buildnumbers"
              formControls={this.formControls}
              onChange={this.onChangeHandler}
              form={(form) => (this.formRef = form)}
            >
              <div className="form__row">
                <label htmlFor="objectType">
                  <ResourceText resourceKey="builnumberLabel.objectType" />
                </label>
                <Input.NewSelect
                  name="objectType"
                  placeholder="makeAChoiceExtended"
                  values={this.props.objectTypes}
                  displayProp="displayName"
                  valuesProp="id"
                  disabled={
                    this.state.submitting || this.props.objectTypes.length === 0
                  }
                />
              </div>

              <div className="form__row">
                <label htmlFor="price">
                  <ResourceText resourceKey="builnumberLabel.price" />
                  <div styleName="hint">
                    <Hint message="builnumberHint.price" forceInline>
                      <i className="fa fa-info-circle" />
                    </Hint>
                  </div>
                </label>
                <div className="input__helper">
                  <div className="pre">&euro;</div>
                  <Input.Number
                    name="price"
                    pretty
                    min={0}
                    max={99999999999999}
                    disabled={this.state.submitting}
                    data-cy="CY-priceField"
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="buildnumberText">
                  <ResourceText resourceKey="builnumberLabel.buildnumberText" />
                  <div styleName="hint">
                    <Hint message="builnumberHint.buildnumberText" forceInline>
                      <i className="fa fa-info-circle" />
                    </Hint>
                  </div>
                </label>
                <Input.Text
                  name="buildnumberText"
                  data-cy="CY-buildnumberText"
                  disabled={this.state.submitting}
                  fireAllChanges
                />
              </div>

              <div className="form__row">
                <label htmlFor="buildnumberStep">
                  <ResourceText resourceKey="builnumberLabel.buildnumberStep" />
                </label>
                <Input.Number
                  name="buildnumberStep"
                  data-cy="CY-buildnumberStep"
                  showActions
                  min={1}
                  max={999}
                  disabled={this.state.submitting}
                />
              </div>
            </Form>
          </div>
          <div styleName="buildnumber__text">
            {this.state.showWarnings ? (
              this.renderWarnings()
            ) : (
              <ResourceText
                resourceKey="buildnumbersBeingCreated"
                values={{
                  numbers: this.state.buildnumbers,
                  count: this.state.buildnumberCount,
                }}
                asHtml
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {!!this.state.showWarnings ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => this.reset(true)}
              data-cy="CY-errorButton"
            >
              <ResourceText resourceKey="okay" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              disabled={cannotSubmit}
              onClick={this.onSubmitHandler}
              data-cy="CY-createBuildNumbers"
            >
              <ButtonLoader
                resourceKey="addBuildNumbers"
                loading={this.state.submitting}
              />
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    let buildnumbers = "";
    let buildnumberCount = 0;

    if (!!values.buildnumberText) {
      const generatedBuildnumbers = this.calculateBuildNumbers(
        values.buildnumberText,
        values.buildnumberStep
      );
      buildnumbers = generatedBuildnumbers.join(", ");
      buildnumberCount = generatedBuildnumbers.length;
    }

    this.setState({
      buildnumbers,
      buildnumberCount,
    });
  }

  private calculateBuildNumbers(text: string, step: number = 2): number[] {
    const blocks = text.split(",").map((block) => block.replace(/\s/g, ""));
    const { numbers, ranges } = blocks.reduce(
      (state, block) => {
        if (block.match(/-/g)) {
          state.ranges.push(block);
        } else {
          state.numbers.push(parseInt(block));
        }
        return state;
      },
      { numbers: [], ranges: [] }
    );

    const final = ranges.reduce((state, range) => {
      const [min, max] = range.split("-");
      const seperatedRange = ArrayUtil.createRange(min, max, step);

      return [...state, ...seperatedRange];
    }, numbers);

    return sortBy(uniq(final));
  }

  private onCloseHandler() {
    if (this.state.submitting) return;
    this.props.onClose();
    this.reset();
  }

  private async reset(fireSuccess: boolean = false) {
    if (fireSuccess) {
      const values = this.formRef.getValues();
      this.props.onSuccess(values.objectType);
    }

    await AsyncUtil.wait(500);

    this.formRef.update({
      objectType: this.props.objectTypeId || "",
      price: this.props.price || "",
      buildnumberText: "",
      buildnumberStep: 2,
    });
    this.setState({
      buildnumbers: "",
      buildnumberCount: 0,
      objectTypeId: this.props.objectTypeId || "",
      showWarnings: false,
      failed: [],
    });
  }

  private async onSubmitHandler() {
    if (this.state.submitting || !this.props.project) return;
    const values = this.formRef.getValues();
    const buildnumbers = this.calculateBuildNumbers(
      values.buildnumberText,
      values.buildnumberStep
    );

    this.setState({
      submitting: true,
    });

    try {
      const failed = await this.props.defineAndSaveNewFromBatch(
        buildnumbers,
        values.objectType,
        values.price,
        this.props.project.id
      );

      if (failed.length > 0) {
        return this.setState({
          submitting: false,
          showWarnings: true,
          failed,
        });
      }

      this.reset(true);
      this.setState({
        submitting: false,
      });
    } catch (error) {
      this.setState({
        submitting: false,
      });
      throw error;
    }
  }

  private renderWarnings() {
    return (
      <div styleName="warnings" data-cy={"CY-AddingBuildNumbersFailed"}>
        <ResourceText resourceKey="buildnumbersSuccesfullyCreated" />
        {this.state.failed.map((fail, idx) => (
          <ErrorBoundary key={idx}>
            <div styleName="warning">
              <i className="fal fa-exclamation-triangle" />
              <ResourceText
                resourceKey="failedDefineNewBuildnumberExists"
                values={{ buildnumber: fail.buildNumber }}
              />
            </div>
          </ErrorBoundary>
        ))}
      </div>
    );
  }
}
