import { Event, PublicationStatusType } from "@haywork/api/event-center";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import Pill from "@haywork/components/ui/pill";
import {
  ACQUISITIONOBJECTROUTES,
  ASSIGNMENTROUTES,
  MLSROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  SCHEDULERROUTES,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { MailTo } from "@haywork/modules/shared";
import { EmailDraft } from "@haywork/stores/email-v2";
import { RouteUtil } from "@haywork/util";
import * as linkify from "linkifyjs/html";
import first from "lodash-es/first";
import uniqBy from "lodash-es/uniqBy";
import * as nl2br from "nl2br";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { RelationProps, RelationType } from "../../list";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  event: Event | null | undefined;
  emailEnabled: boolean;
  onCreateNewEmail: (emails: string) => void;
  onNavigate: (path: string) => void;
  onUpdateRelation: (relation: RelationProps) => void;
  onCreateNewTask: () => void;
  onCreateNewAppointment: () => void;
  onCreateNewRelation: () => void;
  onOpenUrl: () => void;
  onOpenDraft: (draft: EmailDraft) => void;
};

export const EventBodyComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      event,
      emailEnabled,
      onCreateNewEmail,
      onNavigate,
      onUpdateRelation,
      onCreateNewTask,
      onCreateNewAppointment,
      onCreateNewRelation,
      onOpenUrl,
      onOpenDraft,
    }) => {
      const onClickInBodyCallback = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (emailEnabled && event.target["tagName"] === "A") {
            event.preventDefault();
            event.stopPropagation();

            if (/mailto:/gi.test(event.target["href"] || "")) {
              const href = (event.target["href"] || "").replace(
                /mailto:/gi,
                ""
              );
              if (!!href) {
                onCreateNewEmail(href);
              }
            } else {
              const href = event.target["href"] || "";
              if (!!href) {
                window.open(href, "_blank");
              }
            }
          }
        },
        [emailEnabled, onCreateNewEmail]
      );

      const messages = useMemo(() => {
        if (!event?.linkedMessages) return null;
        let { linkedMessages } = event;
        linkedMessages = uniqBy(
          linkedMessages || [],
          (message) => message.id
        ).filter((message) => !!message.body);

        if (!linkedMessages.length) return null;

        return (
          <>
            {linkedMessages.map((message) => (
              <div key={message.id} styleName="row">
                <div styleName="row__icon">
                  <Icon
                    name="comment-alt-lines"
                    size={14}
                    color={Colors.Gray}
                    solid
                    containIn={18}
                  />
                </div>
                <div styleName="row__body">
                  <div
                    dangerouslySetInnerHTML={{ __html: nl2br(message.body) }}
                  />
                </div>
              </div>
            ))}
          </>
        );
      }, [event]);

      const mailErrors = useMemo(() => {
        if (!event?.linkedDrafts) return null;
        let { linkedDrafts } = event;
        linkedDrafts = uniqBy(
          linkedDrafts || [],
          (message) => message.id
        ).filter((message) => !!message.systemMessage);

        if (!linkedDrafts.length) return null;

        return (
          <>
            {linkedDrafts.map((message) => (
              <div key={message.id} styleName="row">
                <div styleName="row__icon">
                  <Icon
                    name="comment-alt-lines"
                    size={14}
                    color={Colors.Gray}
                    solid
                    containIn={18}
                  />
                </div>
                <div styleName="row__body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nl2br(message.systemMessage),
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        );
      }, [event]);

      const errors = useMemo(() => {
        if (!event?.linkedPublications) return null;
        let { linkedPublications } = event;
        linkedPublications = uniqBy(
          linkedPublications || [],
          (publication) => publication.id
        ).filter(
          (publication) =>
            publication.publicationStatus === PublicationStatusType.Rejected &&
            !!publication.displayName
        );

        if (!linkedPublications.length) return null;

        return (
          <>
            {linkedPublications.map((publication) => {
              const body = linkify(publication.displayName, {
                defaultProtocol: "https",
                target: "_blank",
              });

              return (
                <div key={publication.id} styleName="row">
                  <div styleName="row__icon">
                    <Icon
                      name="exclamation-triangle"
                      size={14}
                      color={Colors.Gray}
                      solid
                      containIn={18}
                    />
                  </div>
                  <div styleName="row__body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: nl2br(body),
                      }}
                      onClick={onClickInBodyCallback}
                    />
                  </div>
                </div>
              );
            })}
          </>
        );
      }, [event, onClickInBodyCallback]);

      const updateRelationCallback = useCallback(
        (relation: RelationProps) => {
          if (!(event?.linkedMessages || []).length) return;
          onUpdateRelation(relation);
        },
        [event, onUpdateRelation]
      );

      const relations = useMemo(() => {
        if (
          !event?.linkedMessages ||
          !event?.linkedContactPersons ||
          !event?.linkedContactCompanies
        )
          return null;
        let relations: RelationProps[] = [];

        const linkedMessageRelations = (event.linkedMessages || [])
          .map((message) => {
            const {
              id,
              firstName,
              lastName,
              emailAddress,
              phoneNumber,
              mobilePhoneNumber,
              gender,
              streetName,
              houseNumber,
              postalCode,
              locality,
            } = message;
            const displayName = [firstName, lastName]
              .filter((d) => !!d)
              .join(" ");

            if (
              !!displayName ||
              !!emailAddress ||
              !!phoneNumber ||
              !!mobilePhoneNumber ||
              !!streetName ||
              !!houseNumber ||
              !!postalCode ||
              !!locality
            ) {
              return {
                id,
                displayName,
                emailAddress,
                phoneNumber,
                mobilePhoneNumber,
                streetName,
                houseNumber,
                postalCode,
                locality,
                gender,
                typeOfRelation: RelationType.Unknown,
              } as RelationProps;
            } else {
              return null;
            }
          })
          .filter((d) => !!d);

        (event.linkedContactPersons || []).forEach((contactPerson) => {
          const { id, displayName, email: emailAddress } = contactPerson;
          const ref = linkedMessageRelations.find(
            (message) => message.emailAddress === emailAddress
          );

          if (!!ref) {
            relations.push({
              ...ref,
              id,
              displayName,
              emailAddress,
              typeOfRelation: RelationType.ContactPerson,
            });
          } else {
            relations.push({
              id,
              displayName,
              emailAddress,
              typeOfRelation: RelationType.ContactPerson,
            });
          }
        });

        (event.linkedContactCompanies || []).forEach((contactCompany) => {
          const { id, displayName } = contactCompany;

          relations.push({
            id,
            displayName,
            typeOfRelation: RelationType.ContactCompany,
          });
        });

        relations = !relations.length ? linkedMessageRelations : relations;
        relations = uniqBy(relations, (relation) => relation.id);
        if (!relations) return null;
        relations = relations.slice(0, 1);

        return (
          <>
            {relations.map((relation) => {
              const {
                displayName,
                emailAddress,
                phoneNumber,
                mobilePhoneNumber,
                typeOfRelation,
                streetName,
                houseNumber,
                postalCode,
                locality,
              } = relation;
              const icon =
                typeOfRelation === RelationType.ContactCompany
                  ? "building"
                  : "user";
              const showUpdateRelation =
                typeOfRelation !== RelationType.Unknown &&
                !!(event?.linkedMessages || []).length;

              return (
                <div key={relation.id} styleName="row">
                  <div styleName="row__icon">
                    <Icon
                      name={icon}
                      size={14}
                      color={Colors.Gray}
                      solid
                      containIn={18}
                    />
                  </div>
                  <div styleName="row__body">
                    <div styleName="emphasize">
                      {displayName}
                      {showUpdateRelation && (
                        <span
                          styleName="update-relation"
                          onClick={() => updateRelationCallback(relation)}
                        >
                          (<I18n value="notification.action.updateRelation" />)
                        </span>
                      )}
                    </div>

                    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                      {!!streetName && (
                        <div>{`${streetName} ${
                          houseNumber ? houseNumber : ""
                        }`}</div>
                      )}
                      {!!postalCode && <div>{postalCode}</div>}
                      {!!locality && <div>{locality}</div>}
                    </div>

                    {!!emailAddress && (
                      <div>
                        <MailTo email={emailAddress} />
                      </div>
                    )}
                    {!!phoneNumber && (
                      <div>
                        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                      </div>
                    )}
                    {!!mobilePhoneNumber && (
                      <div>
                        <a href={`tel:${mobilePhoneNumber}`}>
                          {mobilePhoneNumber}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        );
      }, [event, updateRelationCallback]);

      const objectAssignments = useMemo(() => {
        if (!event?.linkedObjectAssignments) return null;
        let { linkedObjectAssignments } = event;
        linkedObjectAssignments = uniqBy(
          linkedObjectAssignments || [],
          (assignment) => assignment.id
        ).filter((assignment) => !!assignment.displayName);

        if (!linkedObjectAssignments.length) return null;

        return (
          <>
            {linkedObjectAssignments.map((assignment) => (
              <div key={assignment.id} styleName="row">
                <div styleName="row__icon">
                  <Icon
                    name="folder-open"
                    size={14}
                    color={Colors.Gray}
                    solid
                    containIn={18}
                  />
                </div>
                <div styleName="row__body">{assignment?.displayName}</div>
              </div>
            ))}
          </>
        );
      }, [event]);

      const projectAssignments = useMemo(() => {
        if (!event?.linkedProjectAssignments) return null;
        let { linkedProjectAssignments } = event;
        linkedProjectAssignments = uniqBy(
          linkedProjectAssignments || [],
          (assignment) => assignment.id
        ).filter((assignment) => !!assignment.displayName);

        if (!linkedProjectAssignments.length) return null;

        return (
          <>
            {linkedProjectAssignments.map((assignment) => (
              <div key={assignment.id} styleName="row">
                <div styleName="row__icon">
                  <Icon
                    name="folder-open"
                    size={14}
                    color={Colors.Gray}
                    solid
                    containIn={18}
                  />
                </div>
                <div styleName="row__body">{assignment?.displayName}</div>
              </div>
            ))}
          </>
        );
      }, [event]);

      const acquisitionObjectAssignments = useMemo(() => {
        if (!event?.linkedAcquisitionObjectAssignments) return null;
        let { linkedAcquisitionObjectAssignments } = event;
        linkedAcquisitionObjectAssignments = uniqBy(
          linkedAcquisitionObjectAssignments || [],
          (assignment) => assignment.id
        ).filter((assignment) => !!assignment.displayName);

        if (!linkedAcquisitionObjectAssignments.length) return null;

        return (
          <>
            {linkedAcquisitionObjectAssignments.map((assignment) => (
              <div key={assignment.id} styleName="row">
                <div styleName="row__icon">
                  <Icon
                    name="folder-open"
                    size={14}
                    color={Colors.Gray}
                    solid
                    containIn={18}
                  />
                </div>
                <div styleName="row__body">{assignment?.displayName}</div>
              </div>
            ))}
          </>
        );
      }, [event]);

      const canOpenLink = useMemo(() => {
        if (!event.linkedMessages) return false;
        const linkedUrls = (event.linkedMessages || []).filter(
          (message) => !!message.infoUrl
        );

        return !!linkedUrls.length;
      }, [event]);

      const canCreateRelation = useMemo(() => {
        if (!event.linkedMessages) return false;
        const linkedMessages = (event.linkedMessages || []).filter(
          (message) =>
            !!message.firstName ||
            !!message.lastName ||
            !!message.emailAddress ||
            !!message.phoneNumber ||
            !!message.mobilePhoneNumber
        );
        const linkedContactPersons = event?.linkedContactPersons || [];
        const linkedContactCompanies = event?.linkedContactCompanies || [];

        return (
          !linkedContactPersons.length &&
          !linkedContactCompanies.length &&
          !!linkedMessages.length
        );
      }, [event]);

      const canOpenObjectAssignment = useMemo(() => {
        if (!event?.linkedObjectAssignments) return false;

        const linkedObjectAssignments = (
          event.linkedObjectAssignments || []
        ).filter((assignment) => !!assignment.id);

        return !!linkedObjectAssignments.length;
      }, [event]);

      const canOpenProjectAssignment = useMemo(() => {
        if (!event?.linkedProjectAssignments) return false;

        const linkedProjectAssignments = (
          event.linkedProjectAssignments || []
        ).filter((assignment) => !!assignment.id);

        return !!linkedProjectAssignments.length;
      }, [event]);

      const canOpenAcquisitionObjectAssignment = useMemo(() => {
        if (!event?.linkedAcquisitionObjectAssignments) return false;
        const linkedAcquisitionObjectAssignments = (
          event.linkedAcquisitionObjectAssignments || []
        ).filter((assignment) => !!assignment.id);

        return !!linkedAcquisitionObjectAssignments.length;
      }, [event]);

      const canOpenContactPerson = useMemo(() => {
        if (!event?.linkedContactPersons) return false;
        const linkedContactPersons = (event.linkedContactPersons || []).filter(
          (contact) => !!contact.id
        );

        return !!linkedContactPersons.length;
      }, [event]);

      const canOpenContactCompany = useMemo(() => {
        if (!event?.linkedContactCompanies) return false;
        const linkedContactCompanies = (
          event.linkedContactCompanies || []
        ).filter((contact) => !!contact.id);

        return !!linkedContactCompanies.length;
      }, [event]);

      const canOpenAppointment = useMemo(() => {
        if (!event?.linkedAgendaItems) return false;
        const linkedAgendaItems = (event.linkedAgendaItems || []).filter(
          (agendaItem) => !!agendaItem.id
        );

        return !!linkedAgendaItems.length;
      }, [event]);

      const canOpenBundle = useMemo(() => {
        if (!event?.linkedBundles) return false;

        const linkedBundles = (event.linkedBundles || []).filter(
          (assignment) => !!assignment.id
        );

        return !!linkedBundles.length;
      }, [event]);

      const canOpenDraft = useMemo(() => {
        if (!event?.linkedDrafts) return false;

        const linkedDrafts = (event.linkedDrafts || []).filter(
          (assignment) => !!assignment.id
        );

        return !!linkedDrafts.length;
      }, [event]);

      const hasActions = useMemo(() => {
        return (
          canOpenObjectAssignment ||
          canOpenProjectAssignment ||
          canOpenAcquisitionObjectAssignment ||
          canOpenContactPerson ||
          canOpenContactCompany ||
          canOpenAppointment ||
          canCreateRelation ||
          canOpenBundle ||
          canOpenDraft ||
          canOpenLink
        );
      }, [
        canOpenObjectAssignment,
        canOpenProjectAssignment,
        canOpenAcquisitionObjectAssignment,
        canOpenContactPerson,
        canOpenContactCompany,
        canOpenAppointment,
        canCreateRelation,
        canOpenBundle,
        canOpenDraft,
        canOpenLink,
      ]);

      const hasBody = useMemo(
        () =>
          !!messages ||
          !!errors ||
          !!relations ||
          !!objectAssignments ||
          !!projectAssignments ||
          !!acquisitionObjectAssignments,
        [
          messages,
          errors,
          relations,
          objectAssignments,
          projectAssignments,
          acquisitionObjectAssignments,
        ]
      );

      const openObjectAssignmentCallback = useCallback(() => {
        const assignment = first(
          (event?.linkedObjectAssignments || []).filter(
            (assignment) => !!assignment.id
          )
        );
        if (!assignment) return;

        const { id } = assignment;
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openProjectAssignmentCallback = useCallback(() => {
        const assignment = first(
          (event?.linkedProjectAssignments || []).filter(
            (assignment) => !!assignment.id
          )
        );
        if (!assignment) return;

        const { id } = assignment;
        const path = route(PROJECTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openAcquisitionObjectAssignmentCallback = useCallback(() => {
        const assignment = first(
          (event?.linkedAcquisitionObjectAssignments || []).filter(
            (assignment) => !!assignment.id
          )
        );
        if (!assignment) return;

        const { id } = assignment;
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openContactPersonCallback = useCallback(() => {
        const relation = first(
          (event?.linkedContactPersons || []).filter(
            (relation) => !!relation.id
          )
        );
        if (!relation) return;

        const { id } = relation;
        const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openContactCompanyCallback = useCallback(() => {
        const relation = first(
          (event?.linkedContactCompanies || []).filter(
            (relation) => !!relation.id
          )
        );
        if (!relation) return;

        const { id } = relation;
        const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openAppointmentCallback = useCallback(() => {
        const agendaItem = first(
          (event?.linkedAgendaItems || []).filter(
            (agendaItem) => !!agendaItem.id
          )
        );
        if (!agendaItem) return;

        const { id } = agendaItem;
        const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id });
        onNavigate(path);
      }, [event, onNavigate]);

      const openBundleCallback = useCallback(() => {
        const assignment = first(
          (event?.linkedBundles || []).filter((assignment) => !!assignment.id)
        );
        if (!assignment) return;

        const { id: id, appClientKey: source } = assignment;
        const path = route(MLSROUTES.DETAIL.URI, { id, source });
        onNavigate(path);
      }, [event, onNavigate]);

      const openDraftCallback = useCallback(() => {
        const draft = first(
          (event?.linkedDrafts || []).filter((draft) => !!draft.id)
        );
        if (!draft) return;
        const { id, subject, accountId } = draft;

        const extendedDraft: EmailDraft = {
          id,
          subject,
          accountId,
          folderId: "",
          searchString: "",
          sendRequested: false,
        };

        onOpenDraft(extendedDraft);
      }, [event, onOpenDraft]);

      if (!event || !hasBody) return null;
      return (
        <div styleName="body">
          {messages}
          {errors}
          {mailErrors}
          {relations}
          {objectAssignments}
          {projectAssignments}
          {acquisitionObjectAssignments}
          {!!hasActions && (
            <div styleName="row">
              {!!canCreateRelation && (
                <Pill
                  label="notification.action.createRelation"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Success}
                  onClick={onCreateNewRelation}
                />
              )}
              {!!canOpenObjectAssignment && (
                <Pill
                  label="notification.action.openObjectAssignment"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openObjectAssignmentCallback}
                />
              )}
              {!!canOpenProjectAssignment && (
                <Pill
                  label="notification.action.openProjectAssignment"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openProjectAssignmentCallback}
                />
              )}
              {!!canOpenAcquisitionObjectAssignment && (
                <Pill
                  label="notification.action.openAcquisitionObjectAssignment"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openAcquisitionObjectAssignmentCallback}
                />
              )}
              {!!canOpenBundle && (
                <Pill
                  label="notification.action.openBundle"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openBundleCallback}
                />
              )}
              {!!canOpenDraft && (
                <Pill
                  label="notification.action.openDraft"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openDraftCallback}
                />
              )}
              {!!canOpenContactPerson && (
                <Pill
                  label="notification.action.openContactPerson"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openContactPersonCallback}
                />
              )}
              {!!canOpenContactCompany && (
                <Pill
                  label="notification.action.openContactCompany"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openContactCompanyCallback}
                />
              )}
              {!!canOpenAppointment && (
                <Pill
                  label="notification.action.openAppointment"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={openAppointmentCallback}
                />
              )}
              {!canOpenBundle && !canOpenDraft && (
                <Pill
                  label="notification.action.createAppointment"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={onCreateNewAppointment}
                />
              )}
              {!canOpenBundle && !canOpenDraft && (
                <Pill
                  label="notification.action.createTask"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Primary}
                  onClick={onCreateNewTask}
                />
              )}
              {!!canOpenLink && (
                <Pill
                  label="notification.action.openUrl"
                  disableHint
                  fullWidth
                  hoverColor={Colors.Success}
                  onClick={onOpenUrl}
                />
              )}
            </div>
          )}
        </div>
      );
    }
  )
);
