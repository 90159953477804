import { SingleFilterConfig } from "@haywork/components/ui/list";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";
import last from "lodash-es/last";
import * as React from "react";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { ListFilterContext } from "../..";
import { PostalCodeRangeComponent as PostalCodeRange } from "./postal-code-range";

type PostalCodeRangeValue = {
  min: number;
  max: number;
};

type InnerPostalCodeRangeValue = PostalCodeRangeValue & {
  id: string;
  description: string;
};

type Props = {
  configKey: string;
};

export const PostalCodeRangesComponent: FC<Props> = memo(({ configKey }) => {
  const { filters, onFilterChange } = useContext(ListFilterContext);

  const filter = useMemo(() => {
    return !filters ? undefined : filters[configKey];
  }, [filters, configKey]);

  const ranges = useMemo(() => {
    if (!filter || !filter.value.length)
      return [
        {
          min: null,
          max: null,
          description: "",
          id: uuid(),
        } as InnerPostalCodeRangeValue,
      ];

    const ranges = filter.value as PostalCodeRangeValue[];
    const enrichedRanges = ranges.map(
      (range) => ({ ...range, id: uuid() } as InnerPostalCodeRangeValue)
    );

    if (!!last(enrichedRanges).min && !!last(enrichedRanges).max) {
      enrichedRanges.push({
        min: null,
        max: null,
        description: "",
        id: uuid(),
      });
    }

    return enrichedRanges;
  }, [filter]);

  const onChangeHandler = useCallback(
    (min: number, max: number, id: any) => {
      if (
        !min ||
        min.toString().length < 4 ||
        !max ||
        max.toString().length < 4 ||
        min > max ||
        !filter
      ) {
        return;
      }

      const value = ranges
        .map((ref) => {
          if (id === ref.id) {
            return { min, max };
          } else {
            return { min: ref.min, max: ref.max };
          }
        })
        .filter((ref) => !!ref.min && !!ref.max);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [ranges, onFilterChange, configKey, filter, filters]
  );

  const onClearCallback = useCallback(
    (id: string) => {
      const filteredRanges = ranges.filter((range) => range.id !== id);
      const value = filteredRanges
        .map((ref) => ({ min: ref.min, max: ref.max }))
        .filter((ref) => !!ref.min || !!ref.max);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [ranges, onFilterChange, filter, filters]
  );

  return (
    <div>
      {ranges.map(({ min, max, id }) => (
        <PostalCodeRange
          min={min}
          max={max}
          index={id}
          onChange={onChangeHandler}
          onClear={onClearCallback}
          key={id}
        />
      ))}
    </div>
  );
});
