import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./score.component.scss");

export enum UiScoreSize {
  Small = "Small",
  Normal = "Normal",
  Large = "Large",
}

interface Props {
  score: number;
  size?: UiScoreSize;
}

export const UiScoreComponent = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const size = props.size || UiScoreSize.Normal;
    const scoreStyle = classNames("score", size.toString().toLowerCase());
    const score = Math.round(props.score * 2) / 2;
    const minScore = Math.floor(score);

    const getStars = () => {
      const stars = [];
      let missingScore = minScore;

      for (let i = 0; i < minScore; i++) {
        stars.push(<i className="fa fa-star" key={i} />);
      }

      if (minScore !== score) {
        stars.push(<i className="fa fa-star-half-alt" key={minScore} />);
        missingScore++;
      }

      for (let i = missingScore; i < 5; i++) {
        stars.push(<i className="far fa-star" key={i} />);
      }

      return stars;
    };

    return <div styleName={scoreStyle}>{getStars()}</div>;
  }
);
