import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./buildnumbers.types";
import { AssignmentSnapShot } from "@haywork/api/kolibri";

export interface BuildnumbersState {
  addBuildnumbersVisible: boolean;
  objectTypes: AssignmentSnapShot[];
  objectTypesStatus: string;
}

const INITIAL_STATE: BuildnumbersState = {
  addBuildnumbersVisible: false,
  objectTypes: [],
  objectTypesStatus: REQUEST.IDLE
};

export const buildnumbersReducer = (state: BuildnumbersState = INITIAL_STATE, action: Action): BuildnumbersState => {
  switch (action.type) {
    case REDUX.BUILDNUMBERS.TOGGLE_ADD_BUILDNUMBERS: {
      const { addBuildnumbersVisible } = <ActionType.ToggleVisibility> action;

      return {
        ...state,
        addBuildnumbersVisible
      };
    }
    case REDUX.BUILDNUMBERS.SET_OBJECT_TYPES: {
      const { objectTypes } = <ActionType.ObjectTypes> action;

      return {
        ...state,
        objectTypes,
        objectTypesStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.BUILDNUMBERS.SET_OBJECT_TYPES_STATUS: {
      const { objectTypesStatus } = <ActionType.ObjectTypesStatus> action;

      return {
        ...state,
        objectTypesStatus
      };
    }
    default: {
      return state;
    }
  }
};
