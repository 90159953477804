import { isNull } from "lodash-es";
import pick from "lodash-es/pick";
import { EmailMessage } from ".";
import { ActionType, ActionTypes } from "./actions";

export type State = {
  messages: EmailMessage[];
};

const INITIAL: State = {
  messages: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.AppendMessages: {
      const { messages } = action;

      const newMessageIds = messages.map((message) => message.id);

      const cleanedMessageState = state.messages.filter((message) => {
        return !newMessageIds.includes(message.id);
      });

      return {
        ...state,
        messages: [...cleanedMessageState, ...messages],
      };
    }
    case ActionType.UpdateMessage: {
      const { message } = action;

      const messages = state.messages.map((m) => {
        if (m.id !== message.id) return m;
        const newProps = pick(message, [
          "threadId",
          "unread",
          "bookmarked",
          "events",
          "hasBlockedExternalReferences",
          "_metaData",
          "folder",
          "body",
          "searchString",
          "folderId",
          "files",
        ]);

        return {
          ...m,
          ...newProps,
        };
      });

      return {
        ...state,
        messages,
      };
    }
    case ActionType.DeleteMessage: {
      const { id } = action;
      const messages = state.messages.filter((message) => message.id !== id);

      return {
        ...state,
        messages,
      };
    }
    case ActionType.Clear:
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
