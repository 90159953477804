import * as React from "react";
import {
  FC,
  memo,
  MutableRefObject,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  useMemo,
} from "react";
import * as CSSModules from "react-css-modules";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import { useIntl } from "react-intl";
import { FolderContextContainerProps } from "./folder-context.container";
import { FolderCategory } from "@haywork/api/mail";
import sortBy from "lodash-es/sortBy";
import Folder from "./folder";

const styles = require("./style.scss");

export type FolderContextComponentProps = {
  parent: MutableRefObject<HTMLButtonElement>;
  visible: boolean;
  accountId: string | null;
  onClose: () => void;
  onFolderSelect: (folderId: string) => void;
};
type Props = FolderContextComponentProps & FolderContextContainerProps;

export const FolderContextComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ parent, visible, onClose, folders, onFolderSelect }) => {
      const [filterValue, setFilterValue] = useState("");
      const intl = useIntl();

      const onFilterChangeCallback = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          setFilterValue(event.target.value);
        },
        [setFilterValue]
      );

      const placeholder = useMemo(() => {
        return intl.formatMessage({
          id: "email.folderContext.filterPlaceholder",
          defaultMessage: "Filter folders",
        });
      }, []);

      const mappedFolders = useMemo(() => {
        let mappedFolders = folders.filter(
          (folder) =>
            ![FolderCategory.Sent, FolderCategory.Drafts].includes(
              folder.category
            )
        );

        mappedFolders = mappedFolders.map((folder) => {
          if (folder.category === FolderCategory.UserCreated) return folder;

          const displayName = intl.formatMessage({
            id: `folderCategory.${folder.category.toString()}`,
            defaultMessage: folder.displayName || folder.category.toString(),
          });

          return {
            ...folder,
            displayName,
          };
        });

        return sortBy(mappedFolders, [
          (folder) => folder.category === FolderCategory.UserCreated,
          (folder) => folder.displayName,
        ]);
      }, [folders]);

      const filteredFolders = useMemo(() => {
        if (!filterValue) return mappedFolders;
        const regex = new RegExp(filterValue, "gi");
        return mappedFolders.filter((folder) =>
          regex.test(folder.displayName || "")
        );
      }, [mappedFolders, filterValue]);

      useEffect(() => {
        if (!visible) {
          setFilterValue("");
        }
      }, [visible, setFilterValue]);

      return (
        <ContextMenu
          visible={visible}
          parent={parent}
          position={ContextMenuPosition.BottomCenter}
          bindOn={ContextMenuPosition.TopCenter}
          push={{ top: 2 }}
          onClickOutside={onClose}
        >
          <div styleName="folders">
            <div styleName="filter">
              <input
                type="text"
                value={filterValue}
                onChange={onFilterChangeCallback}
                placeholder={placeholder}
                data-lpignore="true"
              />
            </div>
            <div styleName="list">
              {filteredFolders.map((folder) => (
                <Folder
                  folder={folder}
                  key={folder.id}
                  onClick={onFolderSelect}
                />
              ))}
            </div>
          </div>
        </ContextMenu>
      );
    }
  )
);
