import { FormContainerProps } from "@haywork/modules/form";
import { FormError } from "@haywork/stores";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import first from "lodash-es/first";
import isObject from "lodash-es/isObject";
import * as PropTypes from "prop-types";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { ErrorMessage, ValidatorFn } from "../errors";
import isEqual from "lodash-es/isEqual";

export type FormControlChangeFn = (
  control: RawFormControl,
  get?: (name: string) => RawFormControl
) => FormReturnValue | void;
export type FormValuesFn = (
  values: FormReturnValue,
  valid: boolean,
  get?: (name: string) => RawFormControl
) => void;

export interface FormControlInstance {
  value: any;
  validators?: ValidatorFn[];
  onChange?: FormControlChangeFn;
}
export interface FormControls {
  [propName: string]: FormControlInstance;
}

export interface RawFormControl {
  name: string;
  value: any;
  valid: boolean;
  validForSubmit: boolean;
  errors: ErrorMessage[];
}

export interface MappedFormControl extends RawFormControl {
  onUpdate: (control: RawFormControl) => void;
  onUnsetInitial: () => void;
  onFocusOnError: () => void;
  triggerFormErrorFocus: (error: FormError) => void;
  validators?: ValidatorFn[];
  onChange?: FormControlChangeFn;
  mounted: boolean;
}

export interface FormReturnValue {
  [name: string]: any;
}

export interface FormReference {
  submit: () => boolean;
  clear: () => void;
  update: (values: { [key: string]: any }, silent?: boolean) => void;
  getValues: () => FormReturnValue;
  isValid: () => boolean;
  clearErrors: () => void;
}

export interface FormComponentProps {
  name: string;
  formControls: FormControls;
  onSubmit?: (values: FormReturnValue) => void;
  onChange?: FormValuesFn;
  onUnmount?: (values: FormReturnValue) => void;
  onDirty?: () => void;
  blocked?: boolean;
  model?: any;
  map?: (values: any) => any;
  validate?: boolean;
  autoComplete?: string;
  asSubForm?: boolean;
  form?: (ref: FormReference) => void;
  className?: string;
}
interface FormComponentState {}

export class FormComponent extends React.Component<
  FormComponentProps & FormContainerProps,
  FormComponentState
> {
  public static childContextTypes = {
    registerControl: PropTypes.func.isRequired,
    unRegisterControl: PropTypes.func.isRequired,
    updateControl: PropTypes.func.isRequired,
    validateControl: PropTypes.func.isRequired,
    setSubformValidity: PropTypes.func.isRequired,
  };

  private controls: MappedFormControl[] = [];
  private formRef: HTMLFormElement;
  private isDirty: boolean = false;
  private id: string = uuid();

  constructor(props: FormComponentProps & FormContainerProps) {
    super(props);

    this.controls = this.mapFormControls();

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onClearHandler = this.onClearHandler.bind(this);
    this.onUpdateHandler = this.onUpdateHandler.bind(this);
    this.onGetValuesHandler = this.onGetValuesHandler.bind(this);
    this.onGetValid = this.onGetValid.bind(this);
    this.renderConditionalChanges = this.renderConditionalChanges.bind(this);
    this.fireOnChange = debounce(this.fireOnChange.bind(this), 5);
    this.handleFormErrors = debounce(this.handleFormErrors.bind(this), 5);
    this.onClearErrors = this.onClearErrors.bind(this);
    this.registerControl = this.registerControl.bind(this);
    this.unRegisterControl = this.unRegisterControl.bind(this);
    this.updateControl = this.updateControl.bind(this);
    this.validateControl = this.validateControl.bind(this);
    this.setSubformValidity = this.setSubformValidity.bind(this);
    this.getFormControl = this.getFormControl.bind(this);

    if (this.props.errors.length > 0) {
      this.handleFormErrors(this.props.errors);
    }
  }

  public getChildContext() {
    return {
      registerControl: this.registerControl,
      unRegisterControl: this.unRegisterControl,
      updateControl: this.updateControl,
      validateControl: this.validateControl,
      setSubformValidity: this.setSubformValidity,
    };
  }

  public render() {
    const formStyle = classNames("form", this.props.className);

    return (
      <fieldset>
        {this.props.asSubForm ? (
          <div className={formStyle} key={this.id}>
            {this.props.children}
          </div>
        ) : (
          <form
            className={formStyle}
            name={this.props.name}
            onSubmit={this.onSubmitHandler}
            noValidate={this.props.validate || true}
            autoComplete={this.props.autoComplete || "off"}
            ref={(ref) => (this.formRef = ref)}
            key={this.id}
            data-cy={this.props["data-cy"]}
          >
            {this.props.children}
          </form>
        )}
      </fieldset>
    );
  }

  public componentDidMount() {
    if (!!this.props.form) {
      this.props.form({
        submit: this.onSubmitHandler,
        clear: this.onClearHandler,
        update: this.onUpdateHandler,
        getValues: this.onGetValuesHandler,
        isValid: this.onGetValid,
        clearErrors: this.onClearErrors,
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: FormComponentProps & FormContainerProps
  ) {
    if (!nextProps) return;
    if (
      nextProps.errors.length > 0 &&
      nextProps.errors.length !== this.props.errors.length
    ) {
      this.handleFormErrors(nextProps.errors);
    }
  }

  public componentWillUnmount() {
    if (this.props.onUnmount) this.props.onUnmount(this.mapFormValues());
  }

  public registerControl(
    name: string,
    onUpdate: (control: RawFormControl) => void,
    onUnsetInitial: () => void,
    onFocusOnError: () => void,
    triggerFormErrorFocus: (error: FormError) => void
  ) {
    this.checkFormFieldForExistance(name);

    let reference: MappedFormControl;
    this.controls = this.controls.map((control) => {
      if (control.name === name) {
        const { valid, errors } = this.checkValidity(control.value, control);
        reference = {
          ...control,
          onUpdate,
          onUnsetInitial,
          onFocusOnError,
          triggerFormErrorFocus,
          valid,
          errors,
          mounted: true,
        };
        return reference;
      }
      return control;
    });

    return reference;
  }

  public unRegisterControl(name: string) {
    /* TODO toggle between fields with same name */
  }

  public updateControl(name: string, value: any, surpress: boolean = false) {
    if (!!value && !!value.then) {
      return value.then((result) => this.updateControl(name, result));
    }

    const control = this.controls.find((ctrl) => ctrl.name === name);
    if (!control.mounted) return;

    let reference: MappedFormControl;
    let updated: boolean = false;

    if (this.props.onDirty && !this.isDirty) {
      this.isDirty = !isEqual(control.value, value);
      if (!!this.isDirty) this.props.onDirty();
    }

    this.controls = this.controls.map((control) => {
      if (control.name === name) {
        const { valid, errors } = this.checkValidity(value, control);

        updated = !isEqual(control.value, value);
        reference = { ...control, value, valid, errors };

        return reference;
      }
      return control;
    });

    if (!!reference && !!reference.onUpdate) {
      const { name, value, valid, errors } = reference;
      reference.onUpdate({ name, value, valid, errors, validForSubmit: true });
    }

    if (!!reference && !!reference.onChange && !surpress && updated)
      this.renderConditionalChanges(reference, true);
    if (this.props.onChange && updated && !surpress) {
      this.fireOnChange();
    }
  }

  public validateControl(name: string) {
    let reference: MappedFormControl;

    this.controls = this.controls.map((control) => {
      if (control.name === name) {
        const { valid, errors } = this.checkValidity(control.value, control);
        reference = { ...control, valid, errors };
        return reference;
      }
      return control;
    });

    if (!!reference && !!reference.onUpdate) {
      const { name, value, valid, errors } = reference;
      reference.onUpdate({ name, value, valid, errors, validForSubmit: true });
    }
  }

  public setSubformValidity(name: string, valid: boolean) {
    this.controls = this.controls.map((control) => {
      if (control.name === name) {
        return { ...control, valid };
      }
      return control;
    });
  }

  public onSubmitHandler(event?: React.FormEvent<HTMLFormElement>): boolean {
    if (!!event) event.preventDefault();
    if (!this.props.onSubmit)
      throw new Error(
        `Form: "${this.props.name}" has no onSubmit property registered`
      );
    if (!this.isFormValid(false) || this.props.blocked) return false;
    this.props.onSubmit(this.mapFormValues());
    return true;
  }

  public onClearHandler() {
    this.controls.map((control) => {
      if (isObject(control.value)) {
        const emptyValues = {};
        for (const key in control.value) {
          emptyValues[key] = "";
        }
        this.updateControl(control.name, emptyValues, true);
      } else {
        this.updateControl(control.name, "", true);
      }
    });
  }

  public onUpdateHandler(values: { [key: string]: any }, silent?: boolean) {
    for (const name in values) {
      this.checkFormFieldForExistance(name);
      this.updateControl(name, values[name], silent);
    }
  }

  public onGetValuesHandler(): FormReturnValue {
    return this.mapFormValues();
  }

  public onGetValid(): boolean {
    return this.isFormValid(true, true);
  }

  public onClearErrors() {
    this.controls = this.controls.map((control) => {
      const { value, name } = control;
      if (!!control.onUpdate && !this.props.asSubForm) {
        control.onUpdate({
          name,
          value,
          valid: true,
          errors: [],
          validForSubmit: true,
        });
      }

      return control;
    });
  }

  private fireOnChange() {
    this.props.onChange(
      this.mapFormValues(),
      this.isFormValid(true, true),
      this.getFormControl
    );
  }

  private async renderConditionalChanges(
    control: MappedFormControl,
    surpress: boolean = false
  ) {
    const { name, value, valid, errors } = control;
    let values =
      control.onChange(
        { name, value, valid, errors, validForSubmit: true },
        this.getFormControl
      ) || {};

    if (!!values && !!values.then) {
      values = await values;
    }

    if (!values) return;

    for (const key in values) {
      this.checkFormFieldForExistance(key);
      this.updateControl(key, values[key], surpress);
    }
  }

  private mapFormControls(formControls?: FormControls): MappedFormControl[] {
    const controls: MappedFormControl[] = [];
    const receivedFormControls = formControls || this.props.formControls;

    for (const name in receivedFormControls) {
      if (receivedFormControls.hasOwnProperty(name)) {
        const control = receivedFormControls[name];
        const { value, validators, onChange } = control;
        const { valid, errors } = this.checkInstanceValidity(value, control);

        controls.push({
          name,
          value,
          valid,
          validForSubmit: true,
          errors,
          onUpdate: null,
          onUnsetInitial: null,
          onFocusOnError: null,
          triggerFormErrorFocus: null,
          validators,
          onChange,
          mounted: true,
        });
      }
    }

    return controls;
  }

  private checkValidity(
    value: any,
    control: MappedFormControl
  ): { valid: boolean; errors: ErrorMessage[] } {
    const { validators, valid, errors } = control;
    if (!validators || validators.length === 0) return { valid, errors };

    const errorArray = validators
      .map((validator) => validator(value))
      .filter((error) => !!error);

    return { valid: errorArray.length === 0, errors: errorArray };
  }

  private checkInstanceValidity(
    value: any,
    control: FormControlInstance
  ): { valid: boolean; errors: ErrorMessage[] } {
    const { validators } = control;
    if (!validators || validators.length === 0)
      return { valid: true, errors: [] };

    const errorArray = validators
      .map((validator) => validator(value))
      .filter((error) => !!error);

    return { valid: errorArray.length === 0, errors: errorArray };
  }

  private isFormValid(
    skipFocus: boolean = true,
    internal: boolean = false
  ): boolean {
    let formErrors = 0;
    let foundError = false;

    this.controls.map((control) => {
      const { value, name, onUnsetInitial } = control;
      const { valid, errors } = this.checkValidity(value, control);

      if (!!control.onUpdate && !this.props.asSubForm) {
        control.onUpdate({ name, value, valid, errors, validForSubmit: true });
      }

      if (!valid && !skipFocus && !foundError) {
        foundError = true;
        if (control.onFocusOnError) {
          control.onFocusOnError();
        }
      }

      if ((!!internal || !!onUnsetInitial) && !valid) {
        if (!internal) onUnsetInitial();
        formErrors++;
      }
    });

    return formErrors === 0;
  }

  private mapFormValues(): FormReturnValue {
    const values = {};

    this.controls.forEach((field) => {
      values[field.name] = field.value;
    });

    if (this.props.model && !this.props.map) {
      return { ...this.props.model, ...values };
    }

    if (this.props.map) {
      return this.props.map(values);
    }

    return values;
  }

  private checkFormFieldForExistance(name: string) {
    const control = this.controls.find((control) => control.name === name);
    if (!control)
      throw new Error(
        `A FormControl with the name "${name}" is not registered with the Form: "${this.props.name}"`
      );
  }

  private getFormControl(name: string): RawFormControl {
    this.checkFormFieldForExistance(name);
    const { value, valid, errors } = this.controls.find(
      (control) => name === control.name
    );
    return { name, value, valid, errors, validForSubmit: true };
  }

  private handleFormErrors(errors: FormError[]) {
    const candidateError = first(errors);
    const control = this.controls.find(
      (ctrl) => ctrl.name === candidateError.fieldName
    );

    if (!!control) {
      control.triggerFormErrorFocus(candidateError);
    }
  }
}
