import * as React from "react";
import classNames from "classnames";
import has from "lodash-es/has";
import get from "lodash-es/get";

import { InputComponentProps } from "../input.component";

interface ListInputComponentProps {
  values: any[];
  displayPath?: string;
  valuePath?: string;
  comparePath?: string;
  resultPath?: string;
  isRoot?: boolean;
}
interface ListInputComponentState {}

export class ListComponent extends React.Component<
  ListInputComponentProps & InputComponentProps,
  ListInputComponentState
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
  }

  public render() {
    const listInputStyle = classNames("input__list", {
      disabled: this.props.disabled,
    });

    return (
      <div className={listInputStyle} data-cy={this.props["data-cy"]}>
        {this.props.values.map(this.renderListItem)}
      </div>
    );
  }

  private renderListItem(
    item: any,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const label =
      this.props.displayPath && has(item, this.props.displayPath)
        ? get(item, this.props.displayPath)
        : item;
    const value =
      this.props.valuePath && has(this.props.value, this.props.valuePath)
        ? get(this.props.value, this.props.valuePath)
        : this.props.value;
    const match =
      this.props.comparePath && has(item, this.props.comparePath)
        ? get(item, this.props.comparePath)
        : item;
    const active = value === match;
    const listItemStyle = classNames("list-item", { active });

    return (
      <div
        className={listItemStyle}
        key={idx}
        onClick={() => this.onChangeHandler(item, active)}
        data-cy="CY-listItem"
      >
        {label}
      </div>
    );
  }

  private onChangeHandler(item: any, active: boolean) {
    if (this.props.disabled) return;
    const value =
      active && !this.props.isRoot
        ? ""
        : this.props.resultPath && has(item, this.props.resultPath)
        ? get(item, this.props.resultPath)
        : item;
    this.props.onChange(value);
  }
}
