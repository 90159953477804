import {
  EventNotification,
  EventType,
  GenderType,
} from "@haywork/api/event-center";
import {
  Address,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType as ApiRelationType,
} from "@haywork/api/kolibri";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  duplicateModalreducer,
  initialDuplicateModalState,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import Icon from "@haywork/components/ui/icon";
import {
  ActiveFilters,
  FilterConfig,
  ListWrapper,
} from "@haywork/components/ui/list";
import {
  COUNTS,
  MAINROUTES,
  RELATIONROUTES,
  SETTINGSROUTES,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { Ui } from "@haywork/modules/ui";
import { EventCenterUtil, RelationUtil, RouteUtil } from "@haywork/util";
import debounce from "lodash-es/debounce";
import first from "lodash-es/first";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
  ListRowProps,
  ScrollParams,
} from "react-virtualized";
import Filters from "./components/filters";
import LoadingState from "./components/loading-state";
import Row from "./components/row";
import { ListContainerProps } from "./list.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;
const cache = new CellMeasurerCache({
  defaultHeight: 74,
  fixedWidth: true,
});

export type ListComponentProps = {};
type Props = ListComponentProps & ListContainerProps;

export enum RelationType {
  Unknown = "Unknown",
  ContactPerson = "ContactPerson",
  ContactCompany = "ContactCompany",
}
export type RelationProps = {
  id?: string;
  displayName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  gender?: GenderType;
  typeOfRelation: RelationType;
  streetName?: string;
  houseNumber?: string;
  postalCode?: string;
  locality?: string;
};

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      getListItems,
      notifications,
      totalCount,
      filters,
      setFilters,
      scrollOffset,
      setScrollOffset,
      activeFilterGuidValues,
      navigate,
      processAllVisible,
      toggleAllIsArchived,
      toggleIsArchived,
      toggleIsRead,
      canSendEmail,
      createNewEmail,
      getRelationsWithMatchingEmailAddress,
      updateMessageWithLinkedRelation,
      openDraft,
    }) => {
      const listRef = useRef<List>();
      const inited = useRef(false);
      const [loading, setLoading] = useState(false);
      const [processingAll, setProcessingAll] = useState(false);
      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] =
        useState<NewEntityOptions | null>(null);
      const intl = useIntl();
      const [duplicateModalState, setDuplicateModalState] = useReducer(
        duplicateModalreducer,
        initialDuplicateModalState
      );

      const isRowLoaded = useCallback(
        ({ index }: Index) => !!notifications[index],
        [notifications]
      );

      const loadMoreRows = useCallback(
        async ({ startIndex, stopIndex }: IndexRange) => {
          try {
            setLoading(true);
            await getListItems(startIndex, stopIndex);
          } finally {
            setLoading(false);
          }
        },
        [getListItems, setLoading]
      );

      const getInitialState = useCallback(() => {
        loadMoreRows({ startIndex: 0, stopIndex: COUNTS.EVENT_NOTIFICATIONS });
      }, [loadMoreRows]);

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          setFilters(filters);
          if (!listRef || !listRef.current) return;
          listRef.current.forceUpdate();
        },
        [listRef, setFilters]
      );

      const onToggleStatusCallback = useCallback(
        async (notificationId: string, eventId: string, isActive: boolean) => {
          if (isActive) {
            await toggleIsRead(notificationId, true);
            await toggleIsArchived(eventId, true);
          } else {
            await toggleIsArchived(eventId, false);
          }
        },
        [toggleIsArchived, toggleIsRead]
      );

      const onCreateNewAppointmentCallback = useCallback(
        async (data: EventNotification) => {
          if (newEntityVisible) return;

          const {
            linkedObjectAssignments,
            linkedProjectAssignments,
            linkedAcquisitionObjectAssignments,
            linkedContactPersons,
            linkedContactCompanies,
          } = data?.linkedEvent;
          const linkedRelations = [
            ...(linkedContactPersons || []).map(
              (relation) =>
                ({
                  ...relation,
                  typeOfRelation: ApiRelationType.ContactPerson,
                } as LinkedRelation)
            ),
            ...(linkedContactCompanies || []).map(
              (relation) =>
                ({
                  ...relation,
                  typeOfRelation: ApiRelationType.ContactCompany,
                } as LinkedRelation)
            ),
          ];
          const linkedAssignments = [
            ...(linkedObjectAssignments || []),
            ...(linkedProjectAssignments || []),
            ...(linkedAcquisitionObjectAssignments || []),
          ];

          setNewEntityOptions({
            type: NewEntityType.Appointment,
            newAppointment: {
              linkedRelations,
              linkedAssignments,
            },
          });
          setNewEntityVisible(true);
        },
        [newEntityVisible, setNewEntityVisible, setNewEntityOptions]
      );

      const onCloseDuplicatesModalCallback = useCallback(() => {
        setDuplicateModalState({ type: "reset" });
      }, [setDuplicateModalState]);

      const createNewRelationCallback = useCallback(
        (
          name?: string,
          email?: string,
          telephone?: string,
          mobilePhone?: string,
          gender?: GenderType,
          address?: Address,
          meta?: { [key: string]: any }
        ) => {
          setNewEntityOptions({
            type: NewEntityType.Relation,
            newRelation: {
              name,
              email,
              telephone,
              mobilePhone,
              gender,
              address,
            },
            meta,
          });
          setNewEntityVisible(true);
          onCloseDuplicatesModalCallback();
        },
        [
          setNewEntityVisible,
          setNewEntityOptions,
          onCloseDuplicatesModalCallback,
        ]
      );

      const onCreateNewRelationCallback = useCallback(
        async (notification: EventNotification) => {
          if (newEntityVisible) return;
          const message = first(
            notification?.linkedEvent?.linkedMessages || []
          );
          const meta = !message ? undefined : { messageId: message.id };

          let name: string;
          let email: string;
          let telephone: string;
          let mobilePhone: string;
          let gender: GenderType;
          let address: Address;
          if (!!message) {
            const {
              firstName,
              lastName,
              emailAddress,
              phoneNumber,
              mobilePhoneNumber,
              gender: genderType,
              streetName,
              houseNumber,
              locality,
              postalCode,
            } = message;
            name = [firstName, lastName].filter((d) => !!d).join(" ");
            email = emailAddress;
            telephone = phoneNumber;
            mobilePhone = mobilePhoneNumber;
            gender = genderType;

            if (streetName && locality) {
              address = {
                street: {
                  name: streetName,
                },
                houseNumber: (houseNumber && parseInt(houseNumber)) || null,
                locality: {
                  name: locality,
                },
                postalCode,
              };
            }
          }

          if (!!email) {
            const relations = await getRelationsWithMatchingEmailAddress(email);
            if (!!relations.length) {
              setDuplicateModalState({
                type: "set",
                payload: {
                  visible: true,
                  view: "duplicates",
                  relations,
                  relationData: {
                    name,
                    email,
                    telephone,
                    mobilePhone,
                    gender,
                  },
                  notification,
                },
              });
              return;
            }
          }

          createNewRelationCallback(
            name,
            email,
            telephone,
            mobilePhone,
            gender,
            address,
            meta
          );
        },
        [newEntityVisible, createNewRelationCallback, setDuplicateModalState]
      );

      const onCreateNewTaskCallback = useCallback(
        (data: EventNotification) => {
          if (newEntityVisible) return;

          const {
            eventType,
            linkedObjectAssignments,
            linkedProjectAssignments,
            linkedAcquisitionObjectAssignments,
            eventDateTime,
            linkedContactPersons,
            linkedContactCompanies,
          } = data?.linkedEvent;
          const linkedRelations = [
            ...(linkedContactPersons || []).map(
              (relation) =>
                ({
                  ...relation,
                  typeOfRelation: ApiRelationType.ContactPerson,
                } as LinkedRelation)
            ),
            ...(linkedContactCompanies || []).map(
              (relation) =>
                ({
                  ...relation,
                  typeOfRelation: ApiRelationType.ContactCompany,
                } as LinkedRelation)
            ),
          ];
          const linkedAssignments = [
            ...((linkedObjectAssignments || []) as LinkedAssignment[]),
            ...((linkedProjectAssignments || []) as LinkedAssignment[]),
            ...((linkedAcquisitionObjectAssignments ||
              []) as LinkedAssignment[]),
          ];
          let subject = null;

          switch (true) {
            case eventType === EventType.AccountCreated &&
              EventCenterUtil.hasName(data?.linkedEvent): {
              subject = intl.formatMessage(
                { id: "quickTaskSubject.AccountCreated" },
                {
                  displayName: EventCenterUtil.getName(data?.linkedEvent),
                }
              );
              break;
            }
            case eventType === EventType.BankwarrantyExpired &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName } = first(linkedObjectAssignments);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.BankwarrantyExpired" },
                {
                  displayName,
                  date: intl.formatDate(eventDateTime, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                }
              );
              break;
            }
            case eventType === EventType.Birthday &&
              EventCenterUtil.hasName(data?.linkedEvent): {
              subject = intl.formatMessage(
                { id: "quickTaskSubject.Birthday" },
                {
                  displayName: EventCenterUtil.getName(data?.linkedEvent),
                  date: intl.formatDate(eventDateTime, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                }
              );
              break;
            }
            case eventType === EventType.ContactMeRequest &&
              EventCenterUtil.hasName(data?.linkedEvent) &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName: assignmentDisplayName } = first(
                linkedObjectAssignments
              );
              subject = intl.formatMessage(
                { id: "quickTaskSubject.ContactMeRequest" },
                {
                  assignmentDisplayName,
                  displayName: EventCenterUtil.getName(data?.linkedEvent),
                }
              );
              break;
            }
            case eventType === EventType.PublicationFailed &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName } = first(linkedObjectAssignments);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.PublicationFailed" },
                { displayName }
              );
              break;
            }
            case eventType === EventType.PublicationSucceeded &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName } = first(linkedObjectAssignments);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.PublicationSucceeded" },
                { displayName }
              );
              break;
            }
            case eventType === EventType.OfferListing &&
              EventCenterUtil.hasName(data?.linkedEvent): {
              subject = intl.formatMessage(
                { id: "quickTaskSubject.OfferListing" },
                {
                  displayName: EventCenterUtil.getName(data?.linkedEvent),
                }
              );
              break;
            }
            case eventType === EventType.RentedUntilExpired &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName } = first(linkedObjectAssignments);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.RentedUntilExpired" },
                {
                  displayName,
                  date: intl.formatDate(eventDateTime, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                }
              );
              break;
            }
            case eventType === EventType.ReservationExpired &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length: {
              const { displayName } = first(linkedObjectAssignments);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.ReservationExpired" },
                {
                  displayName,
                  date: intl.formatDate(eventDateTime, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                }
              );
              break;
            }
            case eventType === EventType.Valuation &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length &&
              !!linkedRelations &&
              !!linkedRelations.length: {
              const { displayName: assignmentDisplayName } = first(
                linkedObjectAssignments
              );
              const { displayName } = first(linkedRelations);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.Valuation" },
                {
                  displayName,
                  assignmentDisplayName,
                }
              );
              break;
            }
            case eventType === EventType.Viewing &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length &&
              !!linkedRelations &&
              !!linkedRelations.length: {
              const { displayName: assignmentDisplayName } = first(
                linkedObjectAssignments
              );
              const { displayName } = first(linkedRelations);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.Viewing" },
                {
                  displayName,
                  assignmentDisplayName,
                }
              );
              break;
            }
            case eventType === EventType.ViewingRequest &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length &&
              !!linkedRelations &&
              !!linkedRelations.length: {
              const { displayName: assignmentDisplayName } = first(
                linkedObjectAssignments
              );
              const { displayName } = first(linkedRelations);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.ViewingRequest" },
                {
                  displayName,
                  assignmentDisplayName,
                }
              );
              break;
            }
            case eventType === EventType.ContactForm &&
              !!linkedObjectAssignments &&
              !!linkedObjectAssignments.length &&
              !!linkedRelations &&
              !!linkedRelations.length: {
              const { displayName: assignmentDisplayName } = first(
                linkedObjectAssignments
              );
              const { displayName } = first(linkedRelations);
              subject = intl.formatMessage(
                { id: "quickTaskSubject.ViewingRequest" },
                {
                  displayName,
                  assignmentDisplayName,
                }
              );
            }
            default:
              break;
          }

          setNewEntityOptions({
            type: NewEntityType.Task,
            newTask: {
              subject,
              linkedRelations,
              linkedAssignments,
            },
          });
          setNewEntityVisible(true);
        },
        [newEntityVisible, setNewEntityVisible, setNewEntityOptions]
      );

      const onCloseNewEntityCallback = useCallback(() => {
        setNewEntityVisible(false);
        setNewEntityOptions(null);
      }, [setNewEntityVisible, setNewEntityOptions]);

      const onUpdateRelationCallback = useCallback(
        (notification: EventNotification, relation: RelationProps) => {
          const messages = !notification?.linkedEvent?.linkedMessages || [];
          if (!messages) return;

          const {
            displayName: name,
            phoneNumber: telephone,
            mobilePhoneNumber: mobilePhone,
            emailAddress: email,
          } = relation;

          setDuplicateModalState({
            type: "set",
            payload: {
              visible: true,
              view: "other",
              relations: [],
              relationData: {
                name,
                email,
                telephone,
                mobilePhone,
              },
              notification,
            },
          });
        },
        [setDuplicateModalState]
      );

      const onNewRelationCallback = useCallback(
        (relation: RelationSnapShot, _, meta?: { [key: string]: any }) => {
          if (!!meta?.messageId) {
            updateMessageWithLinkedRelation(
              meta.messageId,
              RelationUtil.mapSnapshotToLinkedRelation(relation)
            );
          }

          const { id } = relation;
          navigate(route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id }));
        },
        [navigate]
      );

      const onNewTaskCallback = useCallback(() => {
        navigate(MAINROUTES.TASKS.URI);
      }, [navigate]);

      const onNewAppointmentCallback = useCallback(() => {
        navigate(MAINROUTES.SCHEDULER.URI);
      }, []);

      const rowRenderer = ({ style, parent, index, key }: ListRowProps) => {
        const notification = notifications[index];

        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
            style={style}
          >
            {({ registerChild, measure }) => (
              <div style={style}>
                {!notification ? (
                  <LoadingState
                    first={index === 0}
                    last={index === totalCount - 1}
                  />
                ) : (
                  <Row
                    data={notification}
                    first={index === 0}
                    last={index === totalCount - 1}
                    ref={registerChild}
                    onNavigate={navigate}
                    onToggleStatus={onToggleStatusCallback}
                    onMeasure={measure}
                    emailEnabled={canSendEmail}
                    onCreateNewEmail={createNewEmail}
                    onCreateNewAppointment={onCreateNewAppointmentCallback}
                    onCreateNewRelation={onCreateNewRelationCallback}
                    onCreateNewTask={onCreateNewTaskCallback}
                    onUpdateRelation={onUpdateRelationCallback}
                    onOpenDraft={openDraft}
                  />
                )}
              </div>
            )}
          </CellMeasurer>
        );
      };

      const noRowsRenderer = useCallback(
        CSSModules(styles, { allowMultiple: true })(() => {
          if (loading) {
            return (
              <div>
                <Ui.Loaders.List />
              </div>
            );
          } else {
            return (
              <div styleName="empty-state">
                <EmptyState
                  icon="bell-slash"
                  title="notifications.emptyStateList.title"
                  subTitle="notifications.emptyStateList.subTitle"
                />
              </div>
            );
          }
        }),
        [loading]
      );

      const onSettingsClickCallback = useCallback(() => {
        navigate(SETTINGSROUTES.EVENTCENTER.URI);
      }, [navigate]);

      const onScrollCallback = useCallback(
        debounce((params: ScrollParams) => {
          if (!!params.scrollTop) {
            setScrollOffset(params.scrollTop);
          }
        }, 250),
        [setScrollOffset]
      );

      const processAllCallback = useCallback(async () => {
        try {
          setProcessingAll(true);
          await toggleAllIsArchived();
          await getInitialState();
        } finally {
          setProcessingAll(false);
        }
      }, [toggleAllIsArchived, setProcessingAll, getInitialState]);

      const updateNotifications = useCallback(() => {
        const updatedFilters: FilterConfig = {};
        for (const key in filters) {
          const filter = filters[key];

          updatedFilters[key] = {
            ...filter,
            value: filter.emptyValue,
          };
        }
        setFilters(updatedFilters);
      }, [setFilters]);

      useEffect(() => {
        getInitialState();
      }, [getInitialState, filters]);

      useEffect(() => {
        if (!inited.current) {
          inited.current = true;
          listRef.current.scrollToPosition(scrollOffset);
        }
      }, [scrollOffset]);

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="notifications"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
            sidebarWide
            actions={
              <>
                <Button
                  label="notifications.list.settings"
                  category="primary"
                  icon={<Icon name="cog" light color={Colors.White} />}
                  onClick={onSettingsClickCallback}
                />
                {!!processAllVisible && (
                  <Button
                    label="notifications.list.processAll"
                    category="primary"
                    icon={
                      processingAll ? (
                        <Icon name="spinner" light color={Colors.Gray} spin />
                      ) : (
                        <Icon
                          name="clipboard-check"
                          light
                          color={Colors.White}
                        />
                      )
                    }
                    onClick={processAllCallback}
                    disabled={processingAll}
                  />
                )}
              </>
            }
          >
            <div styleName="body">
              <EntityListStatus
                list={"eventCenterV2"}
                onClick={updateNotifications}
                style={{ top: 48 }}
              />
              <AutoSizer>
                {({ width, height }) => (
                  <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    rowCount={totalCount}
                    minimumBatchSize={COUNTS.EVENT_NOTIFICATIONS}
                  >
                    {({ onRowsRendered }) => (
                      <List
                        name="eventCenterV2"
                        ref={listRef}
                        width={width}
                        height={height}
                        rowRenderer={rowRenderer}
                        rowHeight={cache.rowHeight}
                        rowCount={totalCount}
                        deferredMeasurementCache={cache}
                        onRowsRendered={onRowsRendered}
                        noRowsRenderer={noRowsRenderer}
                        onScroll={onScrollCallback}
                      />
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </div>
          </ListWrapper>

          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={onCloseNewEntityCallback}
            onNewRelation={onNewRelationCallback}
            onNewTask={onNewTaskCallback}
            onNewAppoinment={onNewAppointmentCallback}
          />

          <DuplicatesModal
            visible={duplicateModalState.visible}
            view={duplicateModalState.view}
            relations={duplicateModalState.relations}
            relationData={duplicateModalState.relationData}
            linkedNotification={duplicateModalState.notification}
            onClose={onCloseDuplicatesModalCallback}
            onCreateNewRelation={createNewRelationCallback}
            mode={"events"}
          />
        </div>
      );
    }
  )
);
