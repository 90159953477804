import {
  Account,
  Folder,
  FolderCategory,
  SaveFolderRequest,
} from "@haywork/api/mail";
import { EmailMessage } from "@haywork/stores/email-v2";
import { FormControlUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceCollector,
  DragSourceSpec,
  DropTarget,
  DropTargetCollector,
  DropTargetSpec,
} from "react-dnd";
import { EditableTitle } from "./editable-title.component";
import { LoadMore } from "./load-more.component";
import { StaticTitle } from "./static-title.component";

const styles = require("./accounts.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  folder: ExtendedEmailFolder;
  account: Account;
  currentFolder: ExtendedEmailFolder;
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
  connectDragSource?: ConnectDragSource;
  onLoadSubFolders?: (folderId: string, init?: boolean) => Promise<void>;
  onLoadFolderMessages: (
    folder: ExtendedEmailFolder,
    category: FolderCategory
  ) => void;
  onMoveMessageToFolder: (message: EmailMessage, folder: Folder) => void;
  onMoveFolderToFolder: (folder: Folder, targetFolder: Folder) => void;
  onSaveFolder: (request: SaveFolderRequest) => Promise<void>;
  checkIfFolderEligableForDelete?: (folderId: string) => Promise<boolean>;
  onRemoveFolder?: (folder: ExtendedEmailFolder) => void;
}
interface State {
  activeName: string;
  expanded: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class FolderInstance extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onExpandClickHandler = this.onExpandClickHandler.bind(this);

    this.state = {
      activeName: null,
      expanded: false,
    };
  }

  public render() {
    const { displayName, hasSubFolders, category, unreadCount, depth, id } =
      this.props.folder;
    const selected = id === value(this.props.currentFolder, "id");
    const depthStyle = depth < 4 ? `depth${depth}` : "depth-deep";
    const folderStyle = classNames("folder", depthStyle, {
      expanded: this.state.expanded,
      active: selected,
      "drag-over": this.props.isOver && this.props.canDrop,
    });

    return (
      <div styleName={folderStyle}>
        {this.props.connectDropTarget(
          this.props.connectDragSource(
            !hasSubFolders && category === FolderCategory.UserCreated ? (
              <div>
                <EditableTitle
                  depth={depth}
                  folder={this.props.folder}
                  accountId={this.props.account.id}
                  selected={selected}
                  onClick={this.onClickHandler}
                  onUpdate={this.props.onSaveFolder}
                  checkIfFolderEligableForDelete={
                    this.props.checkIfFolderEligableForDelete
                  }
                  onRemoveFolder={this.props.onRemoveFolder}
                />
              </div>
            ) : (
              <div>
                <StaticTitle
                  depth={depth}
                  displayName={displayName}
                  hasSubFolders={hasSubFolders}
                  category={category}
                  selected={selected}
                  count={unreadCount}
                  expanded={this.state.expanded}
                  onClick={this.onClickHandler}
                  onExpandClick={this.onExpandClickHandler}
                />
              </div>
            )
          )
        )}
        {!!hasSubFolders && (
          <div styleName="folder__list">
            {this.props.children}
            {this.props.folder.canLoadMoreFolders && (
              <LoadMore
                onClick={() =>
                  this.props.onLoadSubFolders(this.props.folder.id, false)
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }

  private onClickHandler() {
    if (
      !!this.props.currentFolder &&
      this.props.currentFolder.id === this.props.folder.id
    )
      return;
    this.props.onLoadFolderMessages(
      this.props.folder,
      this.props.folder.category
    );
  }

  private async onExpandClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    const { id, folders } = this.props.folder;
    const { expanded } = this.state;

    if (!expanded && folders.length === 0) {
      await this.props.onLoadSubFolders(id);
    }

    this.setState({
      expanded: !expanded,
    });
  }
}

const dropSpec: DropTargetSpec<Props> = {
  drop: (props, monitor) => {
    const state: any = monitor.getItem();

    if (!!state.folder) {
      props.onMoveFolderToFolder(state.folder, props.folder);
    } else {
      props.onMoveMessageToFolder(state.message, props.folder);
    }
  },
  canDrop: (props, monitor) => {
    const state: any = monitor.getItem();
    const { message, folder } = state;

    if (!!folder) {
      let canDrop = props.folder.category !== FolderCategory.Drafts;

      if (!!folder) {
        canDrop =
          props.folder.category === FolderCategory.UserCreated &&
          folder.id !== props.folder.id &&
          folder.accountId === props.account.id;
      }

      return canDrop;
    }

    if (!!message) {
      return (
        message.accountId === props.account.id &&
        props.folder.category === FolderCategory.UserCreated
      );
    }
  },
};

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const dragSpec: DragSourceSpec<Props, any> = {
  beginDrag(props) {
    const { folder } = props;
    return { folder };
  },
  canDrag(props) {
    return props.folder.category === FolderCategory.UserCreated;
  },
};

const dragCollect: DragSourceCollector<any, any> = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const DropFolderComponent = DropTarget(
  "EmailMessage",
  dropSpec,
  dropCollect
)(FolderInstance);
export const FolderComponent = DragSource(
  "EmailMessage",
  dragSpec,
  dragCollect
)(DropFolderComponent);
