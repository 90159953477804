import { SortOrder, AssignmentOrderByField } from "@haywork/api/kolibri";
import { Actions as AssignmentListActions } from "./actions";

export { Actions } from "./actions";
export type AssignmentOrder = {
  sortOrder: SortOrder;
  sortColumn: AssignmentOrderByField;
};

export { listReducer, ListState } from "./reducer";
export { AssignmentListActions };
