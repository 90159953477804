import {
  ObjectAssignment,
  PublicationSnapShot,
  ProjectAssignment
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import * as React from "react";
import { FC, memo, useState, useEffect, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { HideFromMediaPartnerToggleContainerProps } from "./hide-toggle.container";
import { Input, SwitchLabelPosition } from "@haywork/modules/form";
import { ASSIGNMENTROUTES, PROJECTROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";

const styles = require("./style.scss");

export interface ShowForMediaPartners {
  name?: string;
  hidePropertyName: string;
  mediaPartnerId: string;
}

export type HideFromMediaPartnerToggleProps = {
  assignment: ObjectAssignment | ProjectAssignment;
  publication: PublicationSnapShot;
  showForMediaPartners: ShowForMediaPartners[];
  type?: string;
};
type Props = HideFromMediaPartnerToggleProps &
  HideFromMediaPartnerToggleContainerProps;

export const HideFromMediaPartnerToggleComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      assignment,
      isTemporarilyRemoved,
      updateAssignment,
      updateProject,
      currentComponentState,
      publication,
      showForMediaPartners,
      type
    }) => {
      const [hideFromMediaPartner, setHideFromMediaPartner] = useState(
        isTemporarilyRemoved
      );

      useEffect(() => {
        switch (type) {
          case "projectAssignment": {
            const path = RouteUtil.mapStaticRouteValues(
              PROJECTROUTES.DETAIL.URI,
              {
                id: assignment.id
              }
            );
            // TODO: get isTemporarilyRemovedFromFunda from props to further generalize component
            const updatedProjectAssignment:
              | ObjectAssignment
              | ProjectAssignment = {
              ...assignment,
              ...{ isTemporarilyRemovedFromFunda: hideFromMediaPartner }
            };

            const newState = {
              ...currentComponentState,
              projectAssignment: updatedProjectAssignment
            };

            updateProject(newState as any, path);
            break;
          }
          case "objectAssignment": {
            const path = RouteUtil.mapStaticRouteValues(
              ASSIGNMENTROUTES.DETAIL.URI,
              {
                id: assignment.id
              }
            );

            // TODO: get isTemporarilyRemovedFromFunda from props to further generalize component
            const updatedObjectAssignment:
              | ObjectAssignment
              | ProjectAssignment = {
              ...assignment,
              ...{ isTemporarilyRemovedFromFunda: hideFromMediaPartner }
            };

            const newState = {
              ...currentComponentState,
              objectAssignment: updatedObjectAssignment
            };

            updateAssignment(newState as any, path);
            break;
          }
          default:
            return undefined;
        }
      }, [hideFromMediaPartner]);

      if (
        showForMediaPartners.some((mediaPartner) => {
          return mediaPartner.mediaPartnerId === publication.mediaPartnerId;
        }) &&
        publication.isActive &&
        !assignment.isConfidential &&
        publication.mediaPartnerIsActive
      ) {
        return (
          <Input.Switch
            name="hideFromMediaPartner"
            value={hideFromMediaPartner}
            on={true}
            off={false}
            label="publications.extra.isTemporarilyRemoved"
            labelPosition={SwitchLabelPosition.Post}
            onChange={(value) => {
              setHideFromMediaPartner(value);
            }}
          />
        );
      }
      return <></>;
    }
  )
);
