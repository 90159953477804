import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import has from "lodash-es/has";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { StepperType } from "./stepper.component";

const styles = require("./step.component.scss");

interface Props {
  title: string;
  completed?: boolean;
  error?: boolean;
}

interface State {
  idx: number;
  active: boolean;
  type: StepperType;
  height: number | string;
  overflow: any;
  takeHeight: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class StepComponent extends React.Component<Props, State> {
  public static contextTypes = {
    registerStep: PropTypes.func,
    focusStep: PropTypes.func,
    unRegisterStep: PropTypes.func,
  };

  private id: string = uuid();
  private bodyRef: HTMLDivElement;

  constructor(props, context) {
    super(props, context);

    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);

    this.state = {
      idx: 0,
      active: false,
      type: StepperType.Numbered,
      height: 0,
      overflow: null,
      takeHeight: false,
    };
  }

  public componentWillUnmount() {
    this.context.unRegisterStep(this.id);
  }

  public componentDidMount() {
    const { idx, type, active } = this.context.registerStep(
      this.id,
      this.onToggleHandler.bind(this)
    );

    this.setState({
      idx,
      active,
      type,
      height: active ? "auto" : 0,
      overflow: active ? "visible" : null,
      takeHeight: false,
    });
  }

  public render() {
    const triggerIconStyle = classNames("trigger", {
      active: this.state.active,
    });
    const stepStyles =
      this.state.active || this.state.takeHeight
        ? {
            height: this.state.height,
            overflow: this.state.overflow,
          }
        : null;
    const itemStyle = classNames(
      "item",
      {
        active: this.state.active,
        completed: this.props.completed,
        error: this.props.error,
      },
      this.getTypeClassname()
    );

    return (
      <div
        styleName={itemStyle}
        style={stepStyles}
        onFocus={this.onFocusHandler}
      >
        <div styleName="item__header" onClick={this.onClickHandler}>
          {this.state.type === StepperType.Numbered && (
            <div styleName="idx">
              {this.props.completed ? (
                <i className="fal fa-fw fa-check faIcon" />
              ) : this.props.error ? (
                <i className="fal fa-fw fa-exclamation-triangle faIcon" />
              ) : (
                this.state.idx + 1
              )}
            </div>
          )}
          <h3>
            <ResourceText resourceKey={this.props.title} />
          </h3>
          {this.state.type === StepperType.Accordion && (
            <div styleName={triggerIconStyle}>
              <i className="fal fa-fw fa-chevron-down" />
            </div>
          )}
        </div>
        <div styleName="item__body" ref={this.bindRef}>
          <div styleName="step">{this.props.children}</div>
        </div>
      </div>
    );
  }

  private onToggleHandler(active: boolean) {
    if (active === this.state.active) return;

    if (!!active) {
      this.setState({ active: true, height: this.bodyRef.clientHeight + 25 });
      setTimeout(
        () =>
          this.setState({
            takeHeight: false,
            height: "auto",
            overflow: "visible",
          }),
        700
      );
    } else {
      this.setState({
        height: this.bodyRef.clientHeight + 25,
        takeHeight: true,
        active: false,
      });
      setTimeout(() => this.setState({ height: 0, overflow: null }), 50);
    }
  }

  private onFocusHandler() {
    if (!this.state.active && has(this.context, "focusStep"))
      this.context.focusStep(this.id);
  }

  private onClickHandler() {
    if (has(this.context, "focusStep")) this.context.focusStep(this.id);
  }

  private bindRef(element: HTMLDivElement) {
    if (!element || !!this.bodyRef) return;
    this.bodyRef = element;
  }

  private getTypeClassname(): string {
    switch (this.state.type) {
      case StepperType.Accordion:
        return "accordion";
      default:
        return "numbered";
    }
  }
}
