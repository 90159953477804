import { RootEntityType } from "@haywork/api/event-center";
import {
  AgendaItem,
  AgendaItemCategorySnapShot,
  AgendaItemSnapShot,
  AssignmentSnapShot,
  AssignmentType,
  LinkedAgendaItemCategory,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import ExternalChanges from "@haywork/components/external-changes";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import { EMPLOYEEROUTES, REQUEST, SCHEDULERROUTES } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  RawFormControl,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import {
  OverlapInterface,
  SchedulerDetailContainerProps,
  SchedulerRecurrenceModalContainer,
} from "@haywork/modules/scheduler";
import {
  ConfirmComponent,
  Hint,
  HintAlignment,
  ObjectTimestamps,
  ResourceText,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { Scheduler } from "@haywork/request";
import { AddressRequest } from "@haywork/request/address";
import { Print } from "@haywork/services";
import {
  AssignmentUtil,
  ColorUtil,
  ConvertersUtil,
  FormControlUtil,
  RouteUtil,
  StringUtil,
} from "@haywork/util";
import * as md5 from "blueimp-md5";
import differenceBy from "lodash-es/differenceBy";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import has from "lodash-es/has";
import head from "lodash-es/head";
import isArray from "lodash-es/isArray";
import last from "lodash-es/last";
import uniqBy from "lodash-es/uniqBy";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Actions, { SchedulerAction } from "./actions";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

interface Location {
  description: string;
}

export interface SchedulerDetailComponentProps {}
interface State {
  latitude: number;
  longitude: number;
  showRecurrence: boolean;
  location: Location;
  showConfirmDelete: boolean;
  showConfirmRemoveRecurrence: boolean;
  showTimes: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  confirmSaveAndClose: boolean;
  overlapInfo: {
    hasOverlap: boolean;
    overlappingAgendaItems: AgendaItemSnapShot[];
    overlappingAgendaItemsString: string;
    isExisting: boolean;
  };
  selectedLinkedAssignments: LinkedAssignment[];
  selectedLinkedRelations: LinkedRelation[];
  fetchingRelations: boolean;
  fetchingAssignments: boolean;
}
type Props = SchedulerDetailComponentProps & SchedulerDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SchedulerDetailComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;
  private previousObjectAddress: string;
  private originalStartTime: string;
  private originalEndTime: string;
  private employeeOptions: RelationSnapShot[];

  constructor(props) {
    super(props);

    const { agendaItem, reminderOptions, realEstateAgencyId } = this.props;
    const {
      minutesBeforeReminder,
      location,
      allDayEvent,
      id,
      linkedAgendaItemCategory,
      subject,
      isPrivate,
      linkedEmployees,
      linkedAssignments,
      linkedRelations,
      startDateTime,
      endDateTime,
      description,
      readOnly,
    } = agendaItem;

    this.employeeOptions = this.props.employees.filter(
      (employee) => !linkedEmployees.find((e) => e.id === employee.id)
    );

    const reminderOption =
      reminderOptions.find(
        (option) => option.value === minutesBeforeReminder
      ) || head(reminderOptions);

    let geoLocation = { latitude: null, longitude: null };
    if (
      !!agendaItem?.geoLocation?.latitude &&
      !!agendaItem?.geoLocation?.longitude
    ) {
      geoLocation = {
        latitude: agendaItem.geoLocation.latitude,
        longitude: agendaItem.geoLocation.longitude,
      };
    }

    this.state = {
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude,
      showRecurrence: false,
      location: { description: location },
      showConfirmDelete: false,
      showConfirmRemoveRecurrence: false,
      showTimes: !allDayEvent,
      newEntityVisible: false,
      newEntityOptions: null,
      confirmSaveAndClose: false,
      overlapInfo: {
        hasOverlap: false,
        overlappingAgendaItems: [],
        overlappingAgendaItemsString: "",
        isExisting: false,
      },
      selectedLinkedAssignments: linkedAssignments || [],
      selectedLinkedRelations: linkedRelations || [],
      fetchingAssignments: false,
      fetchingRelations: false,
    };

    this.onActionClick = this.onActionClick.bind(this);
    this.onPrintClickHandler = this.onPrintClickHandler.bind(this);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.getCoords = this.getCoords.bind(this);
    this.getSubject = this.getSubject.bind(this);
    this.clearCoords = this.clearCoords.bind(this);
    this.getCategoryPlaceholder = this.getCategoryPlaceholder.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onDirtyHandler = this.onDirtyHandler.bind(this);
    this.removeRecurrency = this.removeRecurrency.bind(this);
    this.openEmployeeDetail = this.openEmployeeDetail.bind(this);
    this.handleLinkedRelationsChange =
      this.handleLinkedRelationsChange.bind(this);
    this.handleLinkedAssignmentsChange =
      this.handleLinkedAssignmentsChange.bind(this);
    this.renderSelectedEmployeeValue =
      this.renderSelectedEmployeeValue.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);

    this.formControls = {
      id: { value: id },
      linkedAgendaItemCategory: {
        value: linkedAgendaItemCategory || "",
        onChange: (ref, get) => {
          if (
            this.props.agendaItem?.linkedAgendaItemCategory?.id === ref.value.id
          )
            return;

          const newCategory: LinkedAgendaItemCategory = ref.value;
          const newCategoryFromList = this.props.agendaItemCategories.find(
            (agendaItemCategory) => agendaItemCategory.id === newCategory.id
          );
          const originalDate = new Date(
            this.props.agendaItem.startDateTime.toString()
          );
          let endDateTime = new Date(
            this.props.agendaItem.startDateTime.toString()
          );

          if (
            newCategoryFromList &&
            newCategoryFromList.standardDurationInMinutes > 0
          ) {
            endDateTime = moment(originalDate)
              .add(newCategoryFromList.standardDurationInMinutes, "m")
              .toDate();
          } else {
            endDateTime = new Date(
              this.props.agendaItem.endDateTime.toString()
            );
          }

          if (newCategory && !agendaItem.readOnly) {
            const location = get("location").value;
            const subject = this.getSubject(
              newCategory.displayName,
              location ? location.description : ""
            );
            this.props.setTabTitle(subject);
            const endTime = moment(endDateTime).format("HH:mm");

            if (
              this.shouldGetStandardDescription(
                get("description").value,
                newCategory
              )
            ) {
              const descriptionPromise = new Promise((resolve, reject) => {
                Scheduler.getAgendaItemCategory(
                  this.props.realEstateAgencyId,
                  this.props.culture,
                  newCategory.id,
                  this.props.host
                )
                  .then((result) =>
                    resolve(result.agendaItemCategory.standardDescription)
                  )
                  .catch((err) => reject(err));
              });
              return {
                subject,
                endDateTime,
                endTime,
                description: descriptionPromise,
              };
            }
            return { subject, endDateTime, endTime };
          }
        },
      },
      subject: {
        value: subject,
        onChange: (ref) => {
          this.props.setTabTitle(ref.value);
        },
        validators: [Validators.required()],
      },
      isPrivate: { value: isPrivate },
      readOnly: { value: readOnly },
      linkedEmployees: {
        value: linkedEmployees,
        onChange: (ref) => {
          if (!ref && !ref.value && !ref.value.length) return;
          const selectedEmployees = ref.value as RelationSnapShot[];

          this.employeeOptions = this.props.employees.filter((employee) => {
            return !selectedEmployees.find((e) => e.id === employee.id);
          });
        },
      },
      linkedAssignments: {
        value: ConvertersUtil.convertToAssignmentSnapshots(
          linkedAssignments,
          realEstateAgencyId
        ),
        onChange: this.handleLinkedAssignmentsChange,
      },
      location: {
        value: { description: location },

        onChange: (ref, get) => {
          if (ref.value && !agendaItem.readOnly) {
            if (!ref.value.manualOption) {
              this.getCoords(ref.value.description);
            } else {
              this.clearCoords();
            }

            const location = ref.value;
            const category = get("linkedAgendaItemCategory").value;
            const subject = this.getSubject(
              category ? category.displayName : "",
              location.description
            );
            this.props.setTabTitle(subject);

            this.setState({ location });
            return { subject };
          } else {
            this.clearCoords();
            this.setState({ location: { description: "" } });
          }
        },
      },
      linkedRelations: {
        value: linkedRelations,
        onChange: this.handleLinkedRelationsChange,
      },
      allDayEvent: {
        value: allDayEvent,
        onChange: (ref, get) => {
          if (ref.value) {
            this.setState({ showTimes: false });

            this.originalStartTime = get("startTime").value;
            this.originalEndTime = get("endTime").value;

            return { startTime: "09:00", endTime: "10:00" };
          } else {
            this.setState({ showTimes: true });

            return {
              startTime: this.originalStartTime
                ? this.originalStartTime
                : "09:00",
              endTime: this.originalEndTime ? this.originalEndTime : "10:00",
            };
          }
        },
      },
      startDateTime: {
        value: startDateTime,
        onChange: (ref, get) => {
          const endDate = new Date(get("endDateTime").value);
          if (ref.value && ref.value > endDate) {
            return { endDateTime: new Date(ref.value) };
          }
        },
        validators: [Validators.required()],
      },
      endDateTime: {
        value: endDateTime,
        validators: [Validators.required()],
      },
      startTime: {
        value: moment(startDateTime).format("HH:mm"),
        onChange: (ref) => {
          // Get current form values
          const values = this.formRef.getValues();
          const { startDateTime } = values;

          // Get current ref values
          const newStartTimes = ref.value.split(":");
          if (newStartTimes.length < 2) return;

          const currentStartDateTime = moment(startDateTime);
          const orignalStartDateTime = moment(
            this.props.agendaItem.startDateTime
          );
          const originalEndDateTime = moment(this.props.agendaItem.endDateTime);

          // Because fields are split get the times and create new date/time
          const newStartDateTime = moment(currentStartDateTime)
            .set("hour", parseInt(newStartTimes[0], 10))
            .set("minute", newStartTimes[1]);

          // Compare original start date and end date to get the diff
          const diff = originalEndDateTime.diff(
            orignalStartDateTime,
            "hours",
            true
          );

          // Set new end date by adding or subtracting the diff
          let newEndDate = newStartDateTime;
          if (diff > 0) {
            newEndDate = newEndDate.add(diff, "hours");
          } else {
            newEndDate = newEndDate.subtract(diff, "hours");
          }

          const newEndTime = newEndDate.format("HH:mm");
          return { endDateTime: newEndDate.toDate(), endTime: newEndTime };
        },
        validators: [
          Validators.required(undefined, () => !this.state.showTimes),
        ],
      },
      endTime: {
        value: moment(endDateTime).format("HH:mm"),
        validators: [
          Validators.required(undefined, () => !this.state.showTimes),
        ],
      },
      description: { value: description },
      minutesBeforeReminder: { value: reminderOption },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.agendaItem, "dateTimeModified") !==
        get(this.props.agendaItem, "dateTimeModified") &&
      this.props.agendaItem
    ) {
      let geoLocation = { latitude: null, longitude: null };
      if (
        this.props.agendaItem.geoLocation &&
        this.props.agendaItem.geoLocation.latitude &&
        this.props.agendaItem.geoLocation.longitude
      ) {
        geoLocation = {
          latitude: this.props.agendaItem.geoLocation.latitude,
          longitude: this.props.agendaItem.geoLocation.longitude,
        };
      }

      this.setState({
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
        location: { description: this.props.agendaItem.location },
        showTimes: !this.props.agendaItem.allDayEvent,
      });

      const { agendaItem } = this.props;

      const reminderOption = agendaItem.minutesBeforeReminder
        ? this.props.reminderOptions.find(
            (reminderOption) =>
              reminderOption.value === agendaItem.minutesBeforeReminder
          )
        : this.props.reminderOptions[0];

      this.formRef.update(
        {
          id: get(this.props.agendaItem, "id"),
          linkedAgendaItemCategory: this.props.agendaItem
            .linkedAgendaItemCategory
            ? this.props.agendaItem.linkedAgendaItemCategory
            : "",
          subject: this.props.agendaItem.subject,
          isPrivate: agendaItem.isPrivate,
          linkedEmployees: agendaItem.linkedEmployees,
          linkedAssignments: ConvertersUtil.convertToAssignmentSnapshots(
            this.props.agendaItem.linkedAssignments,
            this.props.realEstateAgencyId
          ),
          location: { description: this.props.agendaItem.location },
          linkedRelations: agendaItem.linkedRelations
            ? agendaItem.linkedRelations
            : [],
          allDayEvent: agendaItem.allDayEvent,
          startDateTime: agendaItem.startDateTime,
          endDateTime: agendaItem.endDateTime,
          startTime: moment(agendaItem.startDateTime).format("HH:mm"),
          endTime: moment(agendaItem.endDateTime).format("HH:mm"),
          description: agendaItem.description,
          minutesBeforeReminder: reminderOption,
        },
        true
      );
    }
  }

  public componentDidMount() {
    if (this.props.agendaItem.isNew && !this.props.agendaItem.subject) {
      this.props.setTabTitle(
        intlContext.formatMessage({
          id: "newAgendaItem",
          defaultMessage: "newAgendaItem",
        })
      );
    } else {
      this.props.setTabTitle(this.props.agendaItem.subject);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      has(nextProps.agendaItem, "subject") &&
      value(nextProps.agendaItem, "subject") !==
        value(this.props.agendaItem, "subject")
    ) {
      this.props.setTabTitle(nextProps.agendaItem.subject);
    }

    if (!this.props.preppedForSave && !!nextProps.preppedForSave) {
      const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
        id: nextProps.agendaItem.id,
      });
      this.props.save(nextProps.agendaItem, path);
    }

    const { agendaItem: newAgendaItem } = nextProps;
    const { agendaItem: oldAgendaItem } = this.props;

    if (
      has(newAgendaItem, "linkedRelations") &&
      value(newAgendaItem, "linkedRelations", []).length !==
        value(oldAgendaItem, "linkedRelations", []).length &&
      !!this.formRef
    ) {
      const { linkedRelations } = newAgendaItem;
      this.formRef.update({ linkedRelations });
    }

    if (
      !!nextProps.agendaItem &&
      nextProps.agendaItem.id !== get(this.props.agendaItem, "id") &&
      !!this.formRef
    ) {
      const {
        id,
        minutesBeforeReminder,
        linkedAgendaItemCategory,
        subject,
        isPrivate,
        linkedEmployees,
        linkedAssignments,
        location,
        linkedRelations,
        allDayEvent,
        startDateTime,
        endDateTime,
        description,
        geoLocation,
      } = nextProps.agendaItem;
      const reminderOption = minutesBeforeReminder
        ? nextProps.reminderOptions.find(
            (reminderOption) => reminderOption.value === minutesBeforeReminder
          )
        : this.props.reminderOptions[0];

      const mappedLinkedRelations = !!linkedRelations ? linkedRelations : [];

      this.formRef.update({
        id,
        linkedAgendaItemCategory: linkedAgendaItemCategory || "",
        subject: subject || "",
        isPrivate,
        linkedEmployees: linkedEmployees || [],
        linkedAssignments: linkedAssignments || [],
        location: location || "",
        linkedRelations: mappedLinkedRelations,
        allDayEvent,
        startDateTime,
        endDateTime,
        startTime: moment(startDateTime).format("HH:mm"),
        endTime: moment(endDateTime).format("HH:mm"),
        description: description || "",
        minutesBeforeReminder: reminderOption,
      });

      this.setState({
        latitude: get(geoLocation, "latitude", null),
        longitude: get(geoLocation, "longitude", null),
        location: { description: location },
        showTimes: !allDayEvent,
      });
    }
  }

  public render() {
    const {
      agendaItem,
      schedulerDetailState,
      schedulerConfirmationState,
      schedulerCancelState,
    } = this.props;
    if (!agendaItem) return;
    const {
      linkedCreatedBy,
      linkedModifiedBy,
      dateTimeCreated,
      dateTimeModified,
      id,
    } = this.props.agendaItem;

    const { isCanceled, isConfirmed } = agendaItem;

    let isRecurrenceDisabled: boolean;
    if (
      isArray(get(this.props.agendaItem, "parentId")) &&
      this.props.agendaItem.parentId.length > 0
    ) {
      isRecurrenceDisabled = true;
    }
    let recurrenceTooltip: string = "recurrenceTooltip";
    if (isRecurrenceDisabled) {
      recurrenceTooltip = "recurrenceDisabledTooltip";
    }
    const loading =
      schedulerDetailState === REQUEST.PENDING ||
      schedulerConfirmationState === REQUEST.PENDING ||
      schedulerCancelState === REQUEST.PENDING;

    return (
      <div styleName="schedulerDetail">
        <PageHeader
          title="appointment"
          subTitle={
            isCanceled
              ? "agendaItem.status.canceled"
              : isConfirmed
              ? "agendaItem.status.confirmed"
              : null
          }
          actions={
            <>
              <Presence entityId={id} entityType={RootEntityType.Agendaitem} />
              <Button
                label="saveAndClose"
                category="success"
                disabled={loading}
                onClick={() => this.setState({ confirmSaveAndClose: true })}
              />
              <Actions
                agendaItem={agendaItem}
                loading={loading}
                onClick={this.onActionClick}
              />
            </>
          }
        />

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.Agendaitem}
          onReloadEntity={this.props.reloadAgendaItem}
        />

        <div styleName="content">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <Form
            name="agendaItem"
            formControls={this.formControls}
            onSubmit={this.onFormSubmitHandler}
            onChange={this.onChangeHandler}
            onDirty={this.onDirtyHandler}
            form={(ref) => (this.formRef = ref)}
          >
            <div styleName="fields">
              <div className="container-fluid">
                {agendaItem.readOnly ? (
                  <div styleName="read-only-warning">
                    <i
                      className="fal fa-fw fa-info-circle"
                      styleName="warningIcon"
                    />
                    <ResourceText resourceKey="readOnlyWarning" />
                  </div>
                ) : null}
                <div className="form__row">
                  <label htmlFor="linkedAgendaItemCategory">
                    <ResourceText resourceKey="kindOfAppointment" />
                  </label>
                  <Input.Query
                    name="linkedAgendaItemCategory"
                    placeholder="selectCategory"
                    values={this.props.agendaItemCategories}
                    matchOn={(q, v: AgendaItemCategorySnapShot) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.displayName)
                    }
                    selectedValue={(c: AgendaItemCategorySnapShot) => ({
                      value: c,
                      template: (
                        <div className="category-option">
                          <div
                            className="category-hint"
                            style={{
                              backgroundColor: this.categoryColor(c.backColor),
                            }}
                          />
                          <div className="category-label">{c.displayName}</div>
                        </div>
                      ),
                    })}
                    optionValue={(v: AgendaItemCategorySnapShot, q) => (
                      <div className="category-option">
                        <div
                          className="category-hint"
                          style={{
                            backgroundColor: this.categoryColor(v.backColor),
                          }}
                        />
                        <div className="category-label">{v.displayName}</div>
                      </div>
                    )}
                    multiple={false}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="linkedEmployees">
                    <ResourceText resourceKey="schedulingIntoAgenda" />
                  </label>
                  <Input.Query
                    name="linkedEmployees"
                    multiple
                    optionValue={this.renderQueryEmployeeOption}
                    selectedValue={this.renderSelectedEmployeeValue}
                    values={this.employeeOptions}
                    matchOn={this.employeeQueryMatchOn}
                  />
                </div>
                <div className="form__row">
                  <div className="form__group halves">
                    <div className="column">
                      <label htmlFor="linkedRelations">
                        <ResourceText resourceKey="whoAppointment" />
                      </label>
                      <Input.Suggestion
                        name="linkedRelations"
                        type="relation"
                        relationTypes={[
                          RelationType.ContactCompany,
                          RelationType.ContactPerson,
                        ]}
                        onNavigate={this.props.navigate}
                        onAdd={this.addNewRelation}
                        fetchingResults={this.state.fetchingRelations}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="linkedAssignments">
                        <ResourceText resourceKey="assignmentAppointment" />
                      </label>
                      <Input.Suggestion
                        name="linkedAssignments"
                        type="assignment"
                        onNavigate={this.props.navigate}
                        fetchingResults={this.state.fetchingAssignments}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div>
                    <label htmlFor="location">
                      <ResourceText resourceKey="whereAppointment" />
                    </label>
                    <Input.QueryLegacy
                      name="location"
                      data-cy="CY-agendaItemLocation"
                      setQueryAsValue
                      useQueryAsOption
                      asyncValues={(value) =>
                        AddressRequest.addressSearch(value)
                      }
                      placeholder="addressPlaceholder"
                      displayPath="description"
                      disabled={this.props.agendaItem.readOnly}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <label htmlFor="subject">
                    <ResourceText resourceKey="subject" />
                  </label>
                  <Input.Textarea
                    name="subject"
                    autoSize
                    data-cy="CY-agendaItemSubject"
                    placeholder="inputSubject"
                    maxHeight={256}
                    maxLength={250}
                    disabled={this.props.agendaItem.readOnly}
                  />
                  <Hint message="isPrivateTooltip" align={HintAlignment.Right}>
                    <Input.CheckBox
                      name="isPrivate"
                      label="privateAppointment"
                      inline
                      disabled={this.props.agendaItem.readOnly}
                    />
                  </Hint>
                </div>

                <div className="form__row">
                  <label htmlFor="startDateTime">
                    <ResourceText resourceKey="from" />
                  </label>
                  <div className="form__group flex-wrap">
                    <div className="column">
                      <Input.Datepicker
                        name="startDateTime"
                        data-cy="CY-startDateTime"
                        disabled={this.props.agendaItem.readOnly}
                      />
                    </div>
                    <div className="column__spacer" />
                    {this.state.showTimes && (
                      <div className="column">
                        <Input.Time
                          name="startTime"
                          minuteInterval={30}
                          data-cy="CY-startTime"
                          disabled={this.props.agendaItem.readOnly}
                        />
                      </div>
                    )}
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.CheckBox
                        name="allDayEvent"
                        label="allDay"
                        disabled={this.props.agendaItem.readOnly}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group flex-wrap">
                    <div className="column">
                      <label htmlFor="endDateTime">
                        <ResourceText resourceKey="until" />
                      </label>
                      <Input.Datepicker
                        name="endDateTime"
                        data-cy="CY-endDateTime"
                        disabled={this.props.agendaItem.readOnly}
                      />
                    </div>
                    <div className="column__spacer" />
                    {this.state.showTimes && (
                      <div className="column push-label">
                        <Input.Time
                          name="endTime"
                          minuteInterval={30}
                          data-cy="CY-endTime"
                          disabled={this.props.agendaItem.readOnly}
                        />
                      </div>
                    )}
                    <div className="column__spacer" />
                    {!this.props.agendaItem.recurrencePattern && (
                      <div className="column">
                        <label htmlFor="minutesBeforeReminder">
                          <ResourceText resourceKey="recieveReminderAppointment" />
                        </label>
                        <Input.NewSelect
                          name="minutesBeforeReminder"
                          values={this.props.reminderOptions}
                          displayProp="text"
                          disabled={this.props.agendaItem.readOnly}
                        />
                      </div>
                    )}
                    <div className="column__spacer" />
                    <div className="column">
                      {!agendaItem.readOnly ? (
                        <label>
                          <ResourceText resourceKey="recurring" />
                        </label>
                      ) : null}

                      <div className="form__control">
                        <div className="btn-group">
                          {!agendaItem.readOnly ? (
                            <div className="btn-group" role="group">
                              <Hint
                                message={recurrenceTooltip}
                                align={HintAlignment.Bottom}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={isRecurrenceDisabled}
                                  onClick={() =>
                                    this.setState({ showRecurrence: true })
                                  }
                                  data-cy="CY-buttonRecurrency"
                                >
                                  <i className="fal fa-fw fa-pencil" />
                                  <ResourceText
                                    resourceKey={
                                      this.props.agendaItem.recurrencePattern
                                        ? "edit"
                                        : "add"
                                    }
                                  />
                                </button>
                              </Hint>
                            </div>
                          ) : null}
                          {this.props.agendaItem.recurrencePattern && (
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  this.setState({
                                    showConfirmRemoveRecurrence: true,
                                  });
                                }}
                                data-cy="CY-buttonRemoveRecurrency"
                              >
                                <i className="fal fa-fw fa-trash-alt" />
                                <ResourceText resourceKey="delete" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form__row" styleName="description">
                  <label htmlFor="description">
                    <ResourceText resourceKey="description" />
                  </label>
                  <Input.Textarea
                    name="description"
                    placeholder="appointmentDetailDescriptionPlaceholder"
                    autoSize
                    maxLength={8192}
                    disabled={this.props.agendaItem.readOnly}
                  />
                </div>
                <div styleName="footer">
                  {!this.props.agendaItem.isNew && (
                    <div styleName="meta-wrapper">
                      <ObjectTimestamps
                        linkedCreatedBy={linkedCreatedBy}
                        linkedModifiedBy={linkedModifiedBy}
                        dateTimeCreated={dateTimeCreated}
                        dateTimeModified={dateTimeModified}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>

        <SchedulerRecurrenceModalContainer
          showModal={this.state.showRecurrence}
          onClose={() => this.setState({ showRecurrence: false })}
        />

        <ConfirmComponent
          visible={this.state.overlapInfo.hasOverlap}
          titleResourceKey="scheduler.overlapModalTitle"
          bodyResourceKey="scheduler.overlapModalMessage"
          bodyValues={{
            agendaItems: this.state.overlapInfo.overlappingAgendaItemsString,
            action: this.state.overlapInfo.isExisting
              ? "bewerken"
              : "toevoegen",
            afspraken:
              this.state.overlapInfo.isExisting &&
              this.state.overlapInfo.overlappingAgendaItems.length > 1
                ? this.state.overlapInfo.overlappingAgendaItems.length - 1
                : this.state.overlapInfo.overlappingAgendaItems.length,
          }}
          onClose={() => {
            this.setState({
              overlapInfo: {
                hasOverlap: false,
                overlappingAgendaItems: [],
                overlappingAgendaItemsString: "",
                isExisting: false,
              },
            });
          }}
          onConfirm={() => {
            const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
              id: agendaItem.id,
            });
            this.props.save(agendaItem, path);
          }}
        />

        <ConfirmComponent
          visible={this.state.showConfirmDelete}
          titleResourceKey="appointmentDeleteModalTitle"
          bodyResourceKey="appointmentDeleteModalMessage"
          onClose={() => {
            this.setState({ showConfirmDelete: false });
          }}
          onConfirm={() => {
            const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
              id: agendaItem.id,
            });
            this.props.deleteAgendaItem(agendaItem.id, path);
          }}
        />

        <ConfirmComponent
          visible={this.state.showConfirmRemoveRecurrence}
          titleResourceKey="appointmentDeleteRecurrenceModalTitle"
          bodyResourceKey="appointmentDeleteRecurrenceModalMessage"
          onClose={() => {
            this.setState({ showConfirmRemoveRecurrence: false });
          }}
          onConfirm={() => {
            this.removeRecurrency();
            this.setState({ showConfirmRemoveRecurrence: false });
          }}
        />

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.Agendaitem}
          entityId={id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => {
            this.formRef.submit();
            this.setState({ confirmSaveAndClose: false });
          }}
        />
      </div>
    );
  }

  private renderQueryEmployeeOption(
    value: any,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private renderSelectedEmployeeValue(value: any): QueryResultReturnValue<any> {
    const template = this.renderEmployeePill(value);

    return {
      value,
      template,
    };
  }

  private renderEmployeePill(employee: LinkedRelation) {
    return (
      <div
        onClick={() => {
          this.openEmployeeDetail(employee.id);
        }}
      >
        {employee.displayName}
      </div>
    );
  }

  private openEmployeeDetail(id: string) {
    const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
    this.props.navigate(path);
  }

  private employeeQueryMatchOn(query: string, value: any): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private getCategoryPlaceholder() {
    return (
      <div className="form__select-option">
        <div>
          <ResourceText resourceKey={"selectCategory"} />
        </div>
      </div>
    );
  }

  private shouldGetStandardDescription(
    description: string,
    newCategory: LinkedAgendaItemCategory
  ): boolean {
    const categorySnapShot = this.props.agendaItemCategories.find(
      (category) => category.id === newCategory.id
    );
    if (!categorySnapShot.descriptionHash) return false;
    if (!description) return true;

    const descriptionHash = md5(description); // password is `password`
    const base64Description = this.hexToBase64(descriptionHash);

    for (let i = 0; i < this.props.agendaItemCategories.length; i++) {
      const category = this.props.agendaItemCategories[i];
      if (
        category.descriptionHash &&
        base64Description === category.descriptionHash
      ) {
        return true;
      }
    }
    return false;
  }

  private hexToBase64(str) {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, "")
          .replace(/([\da-fA-F]{ 2 }) ?/g, "0x$1 ")
          .replace(/ +$/, "")
          .split(" ")
      )
    );
  }

  private getSubject(categoryName: string, location: string): string {
    let newSubject: string = "";
    if (categoryName) {
      newSubject = categoryName;
    }

    if (location) {
      if (categoryName) {
        newSubject += " bij ";
      } else {
        newSubject += "Bij ";
      }
      newSubject += location;
    }
    return newSubject;
  }

  private getCoords(location: string) {
    AddressRequest.getGeocodes(location, this.props.country.displayName).then(
      (results: google.maps.GeocoderResult[]) => {
        if (results.length > 0) {
          this.setState({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        }
      }
    );
  }

  private clearCoords() {
    this.setState({
      latitude: null,
      longitude: null,
    });
  }

  private categoryColor(color: string | null): string {
    return !!color ? ColorUtil.hexToRgb(color) : null;
  }

  private getAgendaItemFromFormValues(
    formReturnValue: FormReturnValue
  ): AgendaItem {
    let agendaItem = {
      ...this.props.agendaItem,
      id: formReturnValue.id || this.props.agendaItem.id || "",
      linkedAgendaItemCategory: formReturnValue.linkedAgendaItemCategory,
      subject: formReturnValue.subject,
      isPrivate: formReturnValue.isPrivate,
      linkedEmployees: formReturnValue.linkedEmployees,
      linkedAssignments: formReturnValue.linkedAssignments,
      linkedRelations: formReturnValue.linkedRelations,
      allDayEvent: formReturnValue.allDayEvent,
      startDateTime: new Date(formReturnValue.startDateTime),
      endDateTime: new Date(formReturnValue.endDateTime),
      description: formReturnValue.description,
      minutesBeforeReminder: formReturnValue.minutesBeforeReminder.value,
      useReminder:
        !!formReturnValue.minutesBeforeReminder.value &&
        formReturnValue.minutesBeforeReminder.value > 0,
      location: !!formReturnValue.location
        ? formReturnValue.location.description
        : "",
    };
    if (this.state.latitude && this.state.longitude) {
      agendaItem = {
        ...agendaItem,
        geoLocation: {
          ...agendaItem.geoLocation,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
        },
      };
    }

    if (formReturnValue.startTime) {
      const startTimeSplit = formReturnValue.startTime.split(":");
      if (startTimeSplit.length === 2) {
        const startDateTime = agendaItem.startDateTime;

        startDateTime.setHours(startTimeSplit[0]);
        startDateTime.setMinutes(startTimeSplit[1]);

        agendaItem = {
          ...agendaItem,
          startDateTime,
        };
      }
    }

    if (formReturnValue.endTime) {
      const endTimeSplit = formReturnValue.endTime.split(":");
      if (endTimeSplit.length === 2) {
        const endDateTime = agendaItem.endDateTime;

        endDateTime.setHours(endTimeSplit[0]);
        endDateTime.setMinutes(endTimeSplit[1]);

        agendaItem = {
          ...agendaItem,
          endDateTime,
        };
      }
    }

    return agendaItem;
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedRelations } = this.formRef.getValues();
    linkedRelations = linkedRelations || [];

    this.formRef.update({
      linkedRelations: [...linkedRelations, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private onFormSubmitHandler(formReturnValue: FormReturnValue) {
    const agendaItem = this.getAgendaItemFromFormValues(formReturnValue);
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    this.props.checkForOverlappingAppointments(agendaItem).then((overlap) => {
      if (overlap && overlap.hasOverlap) {
        this.processOverlap(agendaItem, overlap);
        return;
      }
      this.props.save(agendaItem, path);
    });
  }

  private processOverlap(agendaItem: AgendaItem, overlap: OverlapInterface) {
    let isSelf: boolean;
    const overlappingAgendaItemsString = overlap.agendaItems
      .map((item: AgendaItemSnapShot) => {
        if (agendaItem.id === item.id) {
          isSelf = true;
          return;
        }
        const employeesResponsible = item.linkedEmployees
          .map((employee) => employee?.displayName || "")
          .join(", ");
        const start = moment(item.startDateTime).format("DD-MM-YYYY HH:mm");
        const end = moment(item.endDateTime).format("DD-MM-YYYY HH:mm");
        return `<pre>Onderwerp: ${
          item.subject
        }<br/>Start: ${start} <br/>Eind: ${end} <br/>Gemaakt door: ${
          item.linkedCreatedBy?.displayName || ""
        }<br />Verantwoordelijke(n): ${employeesResponsible}</pre>`;
      })
      .join("");
    this.saveOrShowOverlap(
      isSelf,
      overlap,
      overlappingAgendaItemsString,
      agendaItem
    );
  }

  private saveOrShowOverlap(
    isSelf: boolean,
    overlap: OverlapInterface,
    overlappingAgendaItemsString: string,
    agendaItem: AgendaItem
  ) {
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    // Check if the current agenda item is itself and overlaps only with itself or all day event then skip overlap check
    if (
      (isSelf && overlap.agendaItems.length === 1) ||
      agendaItem.allDayEvent
    ) {
      this.props.save(agendaItem, path);
    } else {
      this.setState({
        overlapInfo: {
          hasOverlap: true,
          overlappingAgendaItems: overlap.agendaItems,
          overlappingAgendaItemsString,
          isExisting: isSelf,
        },
      });
    }
  }

  private removeRecurrency() {
    const agendaItem = {
      ...this.props.agendaItem,
      isRecurringEvent: false,
      recurrencePattern: "",
    };
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    this.props.updateCache(agendaItem, path);
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const agendaItem = this.getAgendaItemFromFormValues(formReturnValue);
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    this.props.updateCache(agendaItem, path);
  }

  private onDirtyHandler() {
    this.props.setHasChanges();
  }

  private async onPrintClickHandler(values: AgendaItem) {
    const { subject } = values;
    const print = new Print();
    const html = print.createAppointmentTemplate(values);
    print.print(html, subject);
  }

  private onActionClick(action: SchedulerAction) {
    if (!this.formRef) return;
    const values = this.formRef.getValues();
    const agendaItem = this.getAgendaItemFromFormValues(values);

    switch (action) {
      case SchedulerAction.Cancel: {
        this.props.cancelAgendaItem(agendaItem);
        break;
      }
      case SchedulerAction.Confirm: {
        this.props.confirmAgendaItem(agendaItem);
        break;
      }
      case SchedulerAction.CreateAppointment: {
        this.props.navigateToNewScheduler(
          agendaItem.startDateTime,
          agendaItem.endDateTime,
          (agendaItem.linkedEmployees || []).map((employee) => employee.id),
          agendaItem
        );
        break;
      }
      case SchedulerAction.Print: {
        this.onPrintClickHandler(values as AgendaItem);
        break;
      }
      case SchedulerAction.Remove: {
        this.setState({ showConfirmDelete: true });
        break;
      }
      case SchedulerAction.CreateTask: {
        this.props.createTaskFromAppointment(values as AgendaItem, intlContext);
        break;
      }
      default: {
        break;
      }
    }
  }

  private async handleLinkedRelationsChange(control: RawFormControl) {
    try {
      this.setState({ fetchingAssignments: true });

      const { selectedLinkedRelations, selectedLinkedAssignments } = this.state;
      const newLinkedRelations = differenceBy(
        control.value as (LinkedRelation | RelationSnapShot)[],
        selectedLinkedRelations,
        (relation) => relation.id
      ).filter((relation) =>
        [RelationType.ContactCompany, RelationType.ContactPerson].includes(
          relation.typeOfRelation
        )
      );
      let linkedAssignments = selectedLinkedAssignments;

      if (!!newLinkedRelations.length) {
        const relationsIds = (newLinkedRelations || []).map(
          (relation) => relation.id
        );
        const assignments = await this.props.getAssignmentsLinkedToRelations(
          relationsIds
        );

        linkedAssignments = assignments.map(
          AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment
        );
        linkedAssignments = uniqBy(
          [...selectedLinkedAssignments, ...linkedAssignments],
          (assignment) => assignment.id
        );
      }

      this.setState({
        selectedLinkedRelations: control.value || [],
        selectedLinkedAssignments: linkedAssignments,
      });

      if (this.formRef) this.formRef.update({ linkedAssignments });

      return {
        linkedRelations: control.value,
        linkedAssignments,
      };
    } finally {
      this.setState({ fetchingAssignments: false });
    }
  }

  private async handleLinkedAssignmentsChange(
    control: RawFormControl,
    get: (name: string) => RawFormControl
  ) {
    try {
      this.setState({ fetchingRelations: true });

      const { selectedLinkedAssignments, selectedLinkedRelations } = this.state;
      const newLinkedAssignments = differenceBy(
        control.value as LinkedAssignment[],
        selectedLinkedAssignments,
        (assignment) => assignment.id
      ).filter((assignment) =>
        [AssignmentType.Object, AssignmentType.Acquisition].includes(
          assignment.typeOfAssignment
        )
      );
      let linkedRelations = selectedLinkedRelations;

      if (!!newLinkedAssignments.length) {
        const objectPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Object
          )
          .map((assignment) => this.props.getLinkedAssignment(assignment.id));
        const acquisitionPromises = newLinkedAssignments
          .filter(
            (assignment) =>
              assignment.typeOfAssignment === AssignmentType.Acquisition
          )
          .map((assignment) => this.props.getLinkedAcquisition(assignment.id));

        const objects = await Promise.all(objectPromises);
        const acquisitions = await Promise.all(acquisitionPromises);

        const objectLinkedRelations = objects.reduce((state, assignment) => {
          [
            ...(assignment.linkedApplicants || []),
            ...(assignment.linkedVendors || []),
            ...(assignment.linkedClients || []),
          ].map((relation) => state.push(relation));

          return state;
        }, [] as LinkedRelation[]);

        const acquisitionLinkedRelations = acquisitions.reduce(
          (state, assignment) => {
            [
              ...(assignment.linkedApplicants || []),
              ...(assignment.linkedVendors || []),
              ...(assignment.linkedClients || []),
            ].map((relation) => state.push(relation));

            return state;
          },
          [] as LinkedRelation[]
        );

        linkedRelations = uniqBy(
          [
            ...selectedLinkedRelations,
            ...objectLinkedRelations,
            ...acquisitionLinkedRelations,
          ],
          (relation) => relation.id
        );
      }

      this.setState({
        selectedLinkedAssignments: control.value || [],
        selectedLinkedRelations: linkedRelations,
      });

      let subject = get("subject")?.value || "";
      let location = get("location").value || { description: "" };

      if (!!(control.value || []).length) {
        const assignment: AssignmentSnapShot | LinkedAssignment = last(
          control.value
        );
        const linkedAgendaItemCategory = get("linkedAgendaItemCategory");
        // SETTING THE NEW SUBJECT
        if (!this.props.agendaItem.readOnly && !location.description) {
          subject = this.getSubject(
            linkedAgendaItemCategory?.value?.displayName,
            assignment.displayName
          );
        }
        this.props.setTabTitle(subject);

        // let hasStandardLocation = false;
        // // WE ARE CHECKING OF THERE IS A LOCATION DESCRIPTION
        // if (!!location.description) {
        //   // ITERATING OVER THE ASSIGNMENTS LINKED TO RELATION PERSON(s)
        //   control.value.forEach(
        //     (assignment: AssignmentSnapShot | LinkedAssignment) => {
        //       // IF THE CURRENT LOCATION DESCRIPTION IS SAME AS ONE OF THE ASSIGNMENT DISPLAY NAMES
        //       // WE MARK HAS STANDARD LOCATION AS TRUE
        //       hasStandardLocation =
        //         location.description === assignment.displayName;
        //     }
        //   );
        //
        //   if (
        //     !hasStandardLocation &&
        //     this.previousObjectAddress === location.description
        //   ) {
        //     // WE MARK HAS STANDARD LOCATION AS TRUE
        //     hasStandardLocation = true;
        //   }

        //   if (!hasStandardLocation) {
        //     location = { description: assignment.displayName || "" };
        //   }
        // } else {
        //   location = { description: assignment.displayName || "" };
        // }
        if (!location.description) {
          location = { description: assignment.displayName || "" };
        }

        this.previousObjectAddress = assignment.displayName || "";
        this.getCoords(location.description);
        this.setState({ location });
      }

      if (this.formRef) this.formRef.update({ linkedRelations });

      return {
        subject,
        location,
      };
    } finally {
      this.setState({ fetchingRelations: false });
    }
  }
}
