import * as React from "react";
import { injectIntl, WithIntlProps } from "react-intl";

export enum FontStyle {
  UpperCase = "UpperCase",
  UcFirst = "UcFirst",
  LowerCase = "LowerCase",
  None = "None",
}

interface ResourceTextProps {
  resourceKey: string;
  masterKey?: string;
  values?: { [key: string]: React.ReactText | any };
  asHtml?: boolean;
  fontStyle?: FontStyle;
  fallback?: string;
}
type Props = ResourceTextProps & WithIntlProps<any>;

export class ResourceTextComponent extends React.Component<Props> {
  public render() {
    if (!this.props.resourceKey) return null;
    const resource = !!this.props.masterKey
      ? `${this.props.masterKey}.${this.props.resourceKey}`
      : this.props.resourceKey;

    let value = this.props.intl.formatMessage(
      {
        id: resource,
        defaultMessage: this.props.fallback || resource,
      },
      this.props.values
    );

    if (this.props.fontStyle) {
      switch (this.props.fontStyle) {
        case FontStyle.UpperCase: {
          value = value.toUpperCase();
          break;
        }
        case FontStyle.LowerCase: {
          value = value.toLowerCase();
          break;
        }
        case FontStyle.UcFirst: {
          const lower = value.toLowerCase();
          value = `${lower.charAt(0).toUpperCase()}${lower.slice(1)}`;
          break;
        }
        default:
          break;
      }
    }

    return this.props.asHtml ? (
      <span
        dangerouslySetInnerHTML={{ __html: value }}
        data-cy={this.props["data-cy"]}
      />
    ) : (
      <span>{value}</span>
    );
  }
}

export const ResourceText = injectIntl(ResourceTextComponent);
