import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { signatures } from "./selectors";
import {
  EditorSignaturesComponent,
  EditorSignaturesComponentProps
} from "./signatures.component";

interface StateProps {
  signatures: TemplateDefinitionSnapShot[];
}
interface DispatchProps {
  navigate: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EditorSignaturesComponentProps,
  AppState
> = (state) => {
  return {
    signatures: signatures(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EditorSignaturesComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path))
});

export type EditorSignaturesContainerProps = StateProps & DispatchProps;
export const EditorSignaturesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorSignaturesComponent);
