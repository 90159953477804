import { Group } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import { Input } from "@haywork/modules/form";
import * as moment from "moment";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-mls.component.scss");

type Props = {
  group: Group;
  objectGroupName: string | null | undefined;
  objectReleaseDate: Date | null | undefined;
  handleGroupChange: (group: Group, value: boolean) => void;
  handleReleaseDateChange: (group: Group, value: Date) => void;
};

const GroupComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      group,
      objectGroupName,
      objectReleaseDate,
      handleGroupChange,
      handleReleaseDateChange,
    }) => {
      const autoPublishReleaseDate =
        !objectGroupName && !objectReleaseDate && group.autoPublish
          ? moment()
              .endOf("day")
              .add(group.defaultReleaseDaysInFuture || 0, "days")
              .toDate()
          : undefined;

      return (
        <div styleName="mlsPublicationDate">
          <div styleName="mlsPublicationDate__label">
            <I18n
              value="mls.dateOfPublication.group"
              values={{ group: group.name }}
            />
          </div>
          <div styleName="mlsPublicationDate__switch">
            <Input.Switch
              name="switch"
              value={!!objectGroupName || !!autoPublishReleaseDate}
              on={true}
              off={false}
              onChange={(value) => handleGroupChange(group, value)}
              asSingleInput
            />
          </div>
          {(!!objectGroupName || !!autoPublishReleaseDate) && (
            <>
              <div styleName="mlsPublicationDate__untill">
                <I18n value="untill" />
              </div>
              <div styleName="mlsPublicationDate__date">
                <Input.Datepicker
                  name="date"
                  value={objectReleaseDate || autoPublishReleaseDate}
                  onChange={(value) => handleReleaseDateChange(group, value)}
                  placeholder="date"
                  asSingleInput
                />
              </div>
            </>
          )}
        </div>
      );
    }
  )
);

export default GroupComponent;
