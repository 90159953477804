import { intlContext } from "@haywork/app";
import I18n from "@haywork/components/i18n";
import { KEYCODE } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { AsyncUtil } from "@haywork/util";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import debounce from "lodash-es/debounce";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { OptionComponent, OptionValue } from "./option.component";
import { PillComponent } from "./pill.component";

export type QueryOptionReturnValue = React.ReactElement<HTMLDivElement>;
export type QueryResultReturnValue<G> = {
  value: G;
  template: React.ReactElement<HTMLDivElement>;
};
export type QueryResultStringReturnValue<G> = {
  value: G;
  resultString: string;
};

interface Props<G> {
  value?: any;
  values?: G[];
  multiple?: boolean;
  placeholder?: string;
  hasCustomPill?: boolean;
  name: string;
  forceAdd?: boolean;
  onAddResource?: string;
  emptyResource?: string;
  disabled?: boolean;
  loading?: boolean;
  blockExternalValues?: boolean;
  disabledValues?: G[];
  matchOn?: (query: string, value: G) => boolean;
  asyncValues?: (value: string) => Promise<G[]>;
  onChange: (value: any[]) => void;
  optionValue: (value: G, query: string) => QueryOptionReturnValue;
  selectedStringValue?: (value: G) => QueryResultStringReturnValue<any>;
  selectedValue?: (value: G) => QueryResultReturnValue<any>;
  disableOption?: (value: G, values: any[]) => boolean;
  onBackspaceDelete?: () => boolean;
  onRemoveMarkedForDelete?: () => void;
  onBlur?: () => void;
  onAdd?: (query: string) => void;
  inputRef?: (ref: HTMLInputElement) => void;
  clear?: (callback: () => void) => void;
  autoFocus?: boolean;
  openOnDeleteOption?: boolean;
  forceClearQueryOnFocus?: boolean;
  filterPlaceholder?: string;
}
interface State<G> {
  query: string;
  options: OptionValue<G>[];
  focussed: boolean;
  expanded: boolean;
  selectedValues: any[];
  displayValues: React.ReactElement<HTMLDivElement>[];
  loading: boolean;
  selectedOptionKey: number;
  markedForDelete: number;
  mouseDownOnOption: boolean;
}

export class BaseQueryComponent<G> extends React.Component<Props<G>, State<G>> {
  private _isMounted = false;
  private ref: HTMLDivElement;
  private inputRef: HTMLInputElement;
  private listRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.checkProperties();

    this.state = {
      query: "",
      options: [],
      focussed: false,
      expanded: false,
      selectedValues: [],
      displayValues: [],
      loading: this.props.loading || false,
      selectedOptionKey: -1,
      markedForDelete: null,
      mouseDownOnOption: false,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.filterAsyncValues = debounce(this.filterAsyncValues.bind(this), 500);
    this.renderOptions = debounce(this.renderOptions.bind(this), 50);
    this.renderAddOrEmptyOption = this.renderAddOrEmptyOption.bind(this);
    this.clear = this.clear.bind(this);
    this.setOnMouseOverState = this.setOnMouseOverState.bind(this);
    this.bindRefElement = this.bindRefElement.bind(this);
    this.onPillRemoveHandler = this.onPillRemoveHandler.bind(this);
    this.onQueryChangeHandler = this.onQueryChangeHandler.bind(this);
    this.onQueryBlurHandler = this.onQueryBlurHandler.bind(this);
    this.onQueryFocusHandler = this.onQueryFocusHandler.bind(this);
    this.onQueryKeyDownHandler = this.onQueryKeyDownHandler.bind(this);
    this.bindInputRefElement = this.bindInputRefElement.bind(this);
    this.bindListRefElement = this.bindListRefElement.bind(this);
    this.onOptionClickHandler = this.onOptionClickHandler.bind(this);

    if (!!this.props.clear) {
      this.props.clear(this.clear);
    }

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentDidMount() {
    const { displayValues, selectedValues, query } = this.mapReceivedValues(
      this.props.value
    );
    this.renderOptions(this.props.values, selectedValues);
    this.setState({ displayValues, selectedValues, query });

    this._isMounted = true;
  }

  public render() {
    const placeholder =
      !!this.props.filterPlaceholder && this.state.expanded
        ? intlContext.formatMessage({
            id: this.props.filterPlaceholder,
            defaultMessage: this.props.filterPlaceholder,
          })
        : this.props.placeholder
        ? intlContext.formatMessage({
            id: this.props.placeholder,
            defaultMessage: this.props.placeholder,
          })
        : null;
    const formInputClass = classNames("input__query", {
      focussed: this.state.focussed,
      expanded: this.state.expanded,
      disabled: this.props.disabled,
    });

    return (
      <div className={formInputClass} ref={this.bindRefElement}>
        <div className="input">
          {this.props.children}
          {this.state.displayValues.map((value, idx) => (
            <ErrorBoundary key={idx}>
              <PillComponent
                value={value}
                idx={idx}
                markedForDelete={this.state.markedForDelete}
                onRemove={this.onPillRemoveHandler}
                isCustomPill={this.props.hasCustomPill}
                disabled={this.props.disabled}
                data-cy={
                  this.props["data-cy"] && `${this.props["data-cy"]}.Pill`
                }
              />
            </ErrorBoundary>
          ))}
          <input
            type="text"
            name={this.props.name}
            id={this.props.name}
            value={this.state.query}
            onChange={this.onQueryChangeHandler}
            onBlur={this.onQueryBlurHandler}
            onFocus={this.onQueryFocusHandler}
            onKeyDown={this.onQueryKeyDownHandler}
            placeholder={placeholder}
            ref={this.bindInputRefElement}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            data-cy={this.props["data-cy"]}
            autoComplete="off"
            data-lpignore="true"
          />
        </div>
        <div className="option__list" ref={this.bindListRefElement}>
          {this.state.options.map((option, idx) => (
            <ErrorBoundary key={idx}>
              <OptionComponent
                option={option}
                query={this.state.query}
                idx={idx}
                selectedOptionKey={this.state.selectedOptionKey}
                optionValue={this.props.optionValue}
                onClick={this.onOptionClickHandler}
                onMouseUp={() => this.setOnMouseOverState(false)}
                onMouseDown={() => this.setOnMouseOverState(true)}
                data-cy={
                  this.props["data-cy"] && `${this.props["data-cy"]}.Option`
                }
              />
            </ErrorBoundary>
          ))}
          {this.renderAddOrEmptyOption()}
        </div>
        {this.state.loading && (
          <div className="loader">
            <div className="indeterminate" />
          </div>
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props<G>) {
    if (!nextProps) return;

    const loading = nextProps.loading || false;
    const focussed = !nextProps.loading && this.props.loading;
    if (this._isMounted) this.setState({ loading, focussed });

    if (!this.props.blockExternalValues) {
      const { displayValues, selectedValues, query } = this.mapReceivedValues(
        nextProps.value
      );

      if (this._isMounted) this.setState({ displayValues, selectedValues });

      if (
        !!this.props.values &&
        (!this.props.values ||
          this.state.query.length === 0 ||
          !deepEqual(nextProps.values, this.props.values))
      ) {
        this.renderOptions(nextProps.values, selectedValues);
      }

      if (!!this.props.selectedStringValue) {
        if (this._isMounted) this.setState({ query });
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onQueryChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: query } = event.target;
    let expanded = false;
    let loading = false;

    if (!query.trim()) {
      const options = this.checkOptions(this.state.options);
      if (this._isMounted)
        return this.setState({
          query,
          options,
          expanded: options.length >= 1,
          loading: false,
        });
    }

    if (!!this.props.values) {
      this.filterStaticValues(query);
      expanded = true;
    }
    if (!!this.props.asyncValues && query.length >= 2) {
      this.filterAsyncValues(query);
      loading = true;
      expanded = true;
    }
    if (!!this.props.asyncValues && query.length < 2) {
      if (this._isMounted)
        return this.setState({
          query,
          options: [],
          expanded: false,
          loading: false,
        });
    }

    if (this._isMounted) this.setState({ query, expanded, loading });
  }

  private onQueryFocusHandler() {
    const expanded =
      this.state.options.length >= 1 ||
      (!!this.props.onAdd && this.state.query.trim().length >= 2);
    if (this._isMounted) this.setState({ focussed: true, expanded });
    if (this.props.forceClearQueryOnFocus) this.setState({ query: "" });
  }

  private async onQueryBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    event.persist();
    await AsyncUtil.wait(10);
    if (this.state.mouseDownOnOption) return;

    let { query } = this.state;
    const { selectedValues } = this.state;

    if (!!this.props.selectedStringValue) {
      const values = selectedValues
        .map((s) => this.props.selectedStringValue(s))
        .filter((f) => !!f);
      if (values.length > 0) {
        query = first(values).resultString || "";
      }
    } else {
      query = "";
    }

    if (this._isMounted)
      this.setState({
        focussed: false,
        markedForDelete: null,
        selectedValues,
        expanded: this.inputRef === document.activeElement,
        query,
        selectedOptionKey: -1,
      });

    if (!!this.props.onBlur) this.props.onBlur();
    if (!!this.props.onRemoveMarkedForDelete)
      this.props.onRemoveMarkedForDelete();
  }

  private onQueryKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    const { forceAdd } = this.props;
    const { options, query } = this.state;
    const length = !!forceAdd ? options.length : options.length - 1;

    switch (keyCode) {
      case KEYCODE.UP_ARROW: {
        event.preventDefault();
        let selectedOptionKey = this.state.selectedOptionKey - 1;
        if (selectedOptionKey < 0) selectedOptionKey = length;
        if (this._isMounted) this.setState({ selectedOptionKey });
        break;
      }
      case KEYCODE.DOWN_ARROW: {
        event.preventDefault();
        let selectedOptionKey = this.state.selectedOptionKey + 1;
        if (selectedOptionKey > length) selectedOptionKey = 0;
        if (this._isMounted) this.setState({ selectedOptionKey });
        break;
      }
      case KEYCODE.ENTER: {
        event.preventDefault();
        if (this.state.expanded) {
          if (
            !!this.props.onAdd &&
            this.state.selectedOptionKey === options.length
          ) {
            if (this._isMounted)
              this.setState({
                expanded: false,
                query: "",
                options: [],
                selectedOptionKey: -1,
              });
            this.props.onAdd(query);
          } else {
            const option = options[this.state.selectedOptionKey];
            this.selectOption(option);
          }
        }
        break;
      }
      case KEYCODE.TAB: {
        if (
          !this.state.expanded ||
          (!!this.props.onAdd &&
            this.state.selectedOptionKey === options.length)
        )
          break;
        const option = options[this.state.selectedOptionKey];
        this.selectOption(option);
      }
      case KEYCODE.BACKSPACE: {
        if (query === "" && !!this.props.onBackspaceDelete) {
          if (!this.props.onBackspaceDelete()) return;
        }
        if (
          query === "" &&
          this.state.selectedValues.length >= 1 &&
          this.state.markedForDelete === null
        ) {
          const markedForDelete = this.state.selectedValues.length - 1;
          if (this._isMounted) this.setState({ markedForDelete });
        }
        if (query === "" && this.state.markedForDelete !== null) {
          this.onPillRemoveHandler(this.state.markedForDelete);
        }
        break;
      }
      case KEYCODE.ESCAPE: {
        if (this._isMounted)
          this.setState({
            query: "",
            options: [],
            expanded: false,
            selectedOptionKey: -1,
            markedForDelete: null,
          });
        if (!!this.props.onRemoveMarkedForDelete)
          this.props.onRemoveMarkedForDelete();
        break;
      }
      default:
        if (this._isMounted) this.setState({ markedForDelete: null });
        if (!!this.props.onRemoveMarkedForDelete)
          this.props.onRemoveMarkedForDelete();
        break;
    }
  }

  private onOptionClickHandler(option: OptionValue<G>) {
    this.selectOption(option);
  }

  private onPillRemoveHandler(key: number) {
    // Check for false because undefined is falsey and undefined should in this case be true
    const openOnDelete = this.props.openOnDeleteOption === false ? false : true;
    const selectedValues = this.state.selectedValues.filter(
      (v, idx) => idx !== key
    );
    const displayValues = this.state.displayValues.filter(
      (v, idx) => idx !== key
    );

    if (this.inputRef && openOnDelete) {
      this.inputRef.focus();
    }

    if (this._isMounted)
      this.setState({ selectedValues, displayValues, markedForDelete: null });
    this.props.onChange(selectedValues);
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.expanded) {
      if (this._isMounted) this.setState({ loading: false, expanded: false });
    }
  }

  private onAddHandler(query: string) {
    if (this._isMounted)
      this.setState({
        query: "",
        options: [],
        expanded: false,
        selectedOptionKey: -1,
        markedForDelete: null,
      });
    this.props.onAdd(query);
  }

  private checkProperties() {
    const {
      values,
      matchOn,
      asyncValues,
      forceAdd,
      onAdd,
      onAddResource,
      name,
      selectedValue,
      selectedStringValue,
      multiple,
    } = this.props;

    if (!values && !asyncValues) {
      throw new Error(
        `Query component: ${name} needs a static or async option list.`
      );
    }

    if (!!values && !!asyncValues) {
      throw new Error(
        `Query component: ${name} can only implement a static or async option list, not both.`
      );
    }

    if (!!values && !matchOn) {
      throw new Error(
        `Query component: ${name} mising "matchOn" property when using a static option list.`
      );
    }

    if (!!asyncValues && !!matchOn) {
      throw new Error(
        `Query component: ${name} "matchOn" property cannot be used with a async option list.`
      );
    }

    if (!!forceAdd && onAdd === undefined) {
      throw new Error(
        `Query component: ${name} "forceAdd" property cannot be defined without "onAdd" property.`
      );
    }

    if (!!onAdd && onAddResource === undefined) {
      throw new Error(
        `Query component: ${name} "onAdd" property cannot be defined without "onAddResource" property.`
      );
    }

    if (!!multiple && !selectedValue) {
      throw new Error(
        `Query component: ${name} is set to have multiple values, "selectedValue" property need to be set.`
      );
    }

    if (!selectedValue && !selectedStringValue) {
      throw new Error(
        `Query component: ${name} needs at least "selectedStringValue" or "selectedValue" property set.`
      );
    }

    if (!!selectedValue && !!selectedStringValue) {
      throw new Error(
        `Query component: ${name} can't have both "selectedStringValue" and "selectedValue" properties.`
      );
    }
  }

  private renderOptions(values: G[], selectedValues?: any[]) {
    if (!values) return [];
    let checkAgainst = selectedValues || this.state.selectedValues;
    if (!!this.props.disabledValues) {
      checkAgainst = [...checkAgainst, ...this.props.disabledValues];
    }

    const options = values.map((value) => {
      return {
        disabled: !!this.props.disableOption
          ? this.props.disableOption(value, checkAgainst)
          : false,
        value,
      };
    });

    if (this._isMounted) this.setState({ options });
  }

  private setOnMouseOverState(mouseDownOnOption: boolean) {
    if (this._isMounted) this.setState({ mouseDownOnOption });
  }

  private checkOptions(
    values: OptionValue<G>[],
    selectedValues?: any[]
  ): OptionValue<G>[] {
    if (!values) return [];
    let checkAgainst = selectedValues || this.state.selectedValues;
    if (!!this.props.disabledValues) {
      checkAgainst = [...checkAgainst, ...this.props.disabledValues];
    }

    return values.map((value) => {
      return {
        ...value,
        disabled: !!this.props.disableOption
          ? this.props.disableOption(value.value, checkAgainst)
          : false,
      };
    });
  }

  private renderAddOrEmptyOption(): React.ReactElement<HTMLDivElement> {
    const { forceAdd, onAdd, onAddResource, emptyResource } = this.props;
    const { options, query, selectedOptionKey } = this.state;
    const selected = selectedOptionKey === options.length;

    const emptyState = emptyResource || "noMatchingOption";
    const emptyStyle = classNames("option__empty", {
      "has-action": !!onAdd,
      selected,
    });

    if (selected && this.listRefElement) {
      this.listRefElement.scrollTop = this.listRefElement.clientHeight;
    }

    if (!!forceAdd && query.length >= 2) {
      return (
        <div
          data-cy="CY-queryOnAddResource"
          className={emptyStyle}
          onClick={() => this.onAddHandler(query)}
          onMouseDown={() => this.setOnMouseOverState(true)}
          onMouseUp={() => this.setOnMouseOverState(false)}
        >
          <strong>
            <I18n value={onAddResource} values={{ query }} asHtml />
          </strong>
        </div>
      );
    }

    if (!!onAdd && this.state.options.length === 0 && !this.state.loading) {
      return (
        <div
          className={emptyStyle}
          onClick={() => this.onAddHandler(query)}
          onMouseDown={() => this.setOnMouseOverState(true)}
          onMouseUp={() => this.setOnMouseOverState(false)}
          data-cy={`${this.props["data-cy"]}.AddResource`}
        >
          <I18n value={onAddResource} values={{ query }} asHtml />
        </div>
      );
    }

    if (this.state.options.length === 0 && !this.state.loading) {
      return (
        <div className={emptyStyle}>
          <I18n value={emptyState} />
        </div>
      );
    }

    return null;
  }

  private mapReceivedValues(receivedValues: any[]): {
    displayValues: React.ReactElement<HTMLDivElement>[];
    selectedValues: any[];
    query: string;
  } {
    const blacklist = [undefined, NaN, Infinity] as any[];
    let rawValues = !receivedValues
      ? []
      : isArray(receivedValues)
      ? receivedValues
      : [receivedValues];
    rawValues = rawValues.map((v) => (!blacklist.includes(v) ? v : ""));
    let query = "",
      selectedValues = [],
      displayValues = [];

    if (!!this.props.selectedValue) {
      const mappedValues = rawValues
        .filter((r) => !!r)
        .map((v) => this.props.selectedValue(v));
      selectedValues = mappedValues.map((r) => r.value).filter((m) => !!m);
      displayValues = mappedValues.map((r) => r.template).filter((m) => !!m);
    }

    if (!!this.props.selectedStringValue) {
      const mappedValues = rawValues
        .filter((r) => !blacklist.includes(r))
        .map((v) => this.props.selectedStringValue(v));

      selectedValues = mappedValues
        .map((r) => r.value)
        .filter((m) => !blacklist.includes(m));
      if (selectedValues.length > 0) {
        query = first(mappedValues).resultString;
      }
    }

    return {
      displayValues,
      selectedValues,
      query: !blacklist.includes(query) ? query : "",
    };
  }

  private selectOption(option: OptionValue<G>) {
    if (!!this.props.values && !!this.props.multiple) {
      this.inputRef.focus();
    }

    if (!option || !!option.disabled) return;
    if (!!this.props.selectedValue) {
      const { value, template } = this.props.selectedValue(option.value);
      const selectedValues = !this.props.multiple
        ? [value]
        : [...this.state.selectedValues, value];
      const displayValues = !this.props.multiple
        ? [template]
        : [...this.state.displayValues, template];

      if (this._isMounted)
        this.setState({
          selectedValues,
          displayValues,
          expanded: !!this.props.values && !!this.props.multiple,
          query: "",
          options: this.props.values ? this.state.options : [],
          selectedOptionKey:
            !!this.props.values && !!this.props.multiple
              ? this.state.selectedOptionKey
              : -1,
        });

      if (this.props.values) {
        this.renderOptions(this.props.values, selectedValues);
      }

      this.props.onChange(selectedValues);
    }

    if (!!this.props.selectedStringValue) {
      const { value, resultString: query } = this.props.selectedStringValue(
        option.value
      );
      const selectedValues = [value];

      this.setState({
        selectedValues,
        expanded: !!this.props.values && !!this.props.multiple,
        query,
        options: this.props.value
          ? this.state.options
          : this.checkOptions(this.state.options, selectedValues),
        selectedOptionKey: 0,
      });

      if (this.props.value) {
        this.renderOptions(this.props.values, selectedValues);
      }

      this.props.onChange(selectedValues);
    }
  }

  private bindRefElement(ref: HTMLDivElement) {
    if (!this.ref && !!ref) this.ref = ref;
  }

  private bindListRefElement(ref: HTMLDivElement) {
    if (!this.listRefElement && !!ref) this.listRefElement = ref;
  }

  private bindInputRefElement(ref: HTMLInputElement) {
    if (!this.inputRef && !!ref) this.inputRef = ref;
    if (this.props.inputRef) this.props.inputRef(ref);
  }

  private filterStaticValues(query: string) {
    const rawOptions = !query.trim()
      ? this.props.values
      : this.props.values.filter((value) => this.props.matchOn(query, value));
    this.renderOptions(rawOptions);
  }

  private filterAsyncValues(query: string) {
    this.props
      .asyncValues(query)
      .then((result) => {
        this.renderOptions(result);
        this.setState({ loading: false });
      })
      .catch(() => {
        if (this._isMounted) this.setState({ options: [], loading: false });
      });
  }

  private async clear() {
    await AsyncUtil.wait(150);
    if (this._isMounted)
      this.setState({
        query: "",
        options: [],
        focussed: false,
        expanded: false,
        selectedValues: [],
        displayValues: [],
        loading: this.props.loading || false,
        selectedOptionKey: -1,
        markedForDelete: null,
      });
  }
}
