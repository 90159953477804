import { RealtorSuggestionItem } from "@haywork/api/mls";
import { SingleFilterConfig } from "@haywork/components/ui/list";
import last from "lodash-es/last";
import * as React from "react";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { ListFilterContext } from "../..";
import Realtor from "./realtor.container";

type RealtorValue = {
  realtor: RealtorSuggestionItem;
};

type InnerRealtorValue = RealtorValue & {
  id: string;
  description: string;
};

type Props = {
  configKey: string;
};

export const RealtorsComponent: FC<Props> = memo(({ configKey }) => {
  const { filters, onFilterChange } = useContext(ListFilterContext);

  const filter = useMemo(() => {
    return !filters ? undefined : filters[configKey];
  }, [filters, configKey]);

  const realtors = useMemo(() => {
    if (!filter || !filter.value.length)
      return [
        {
          realtor: null,
          description: "",
          id: uuid(),
        } as InnerRealtorValue,
      ];

    const realtors = filter.value as RealtorValue[];
    const enrichedRealtors = realtors.map(
      (location) => ({ ...location, id: uuid() } as InnerRealtorValue)
    );

    if (!!last(enrichedRealtors).realtor) {
      enrichedRealtors.push({
        realtor: null,
        description: "",
        id: uuid(),
      });
    }

    return enrichedRealtors;
  }, [filter]);

  const onClearCallback = useCallback(
    (id: string) => {
      const filteredRealtors = realtors.filter((realtor) => realtor.id !== id);
      const value = filteredRealtors
        .map((ref) => ({ realtor: ref.realtor }))
        .filter((ref) => !!ref.realtor);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [realtors, onFilterChange, filter, filters]
  );

  const onChangeHandler = useCallback(
    (realtor: RealtorSuggestionItem, id: any) => {
      if (!realtor) return;
      const value = realtors
        .map((ref) => {
          if (id === ref.id) {
            return { realtor };
          } else {
            return { realtor: ref.realtor };
          }
        })
        .filter((ref) => !!ref.realtor);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [realtors, onFilterChange, configKey, filter]
  );

  return (
    <div>
      {realtors.map(({ realtor, id }) => (
        <Realtor
          realtor={realtor}
          index={id}
          onChange={onChangeHandler}
          onClear={onClearCallback}
          key={id}
        />
      ))}
    </div>
  );
});
