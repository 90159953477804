import { Dispatch } from "@haywork/middleware";
import {
  SettingsGeneralComponent,
  SettingsGeneralComponentProps
} from "@haywork/modules/settings/modules/general";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  idManagerUri: string;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, SettingsGeneralComponentProps>(
  state: AppState
) => ({
  idManagerUri: `${state.appSettings.oidcUri}?hideLayout=true`
});

const mapDispatchToProps = <DispatchProps, SettingsGeneralComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type SettingsGeneralContainerProps = StateProps & DispatchProps;
export const SettingsGeneralContainer = connect<
  StateProps,
  DispatchProps,
  SettingsGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsGeneralComponent);
