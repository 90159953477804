import {
  AgendaItemCategory,
  AgendaItemCategorySnapShot,
  AgendaItemSnapShot,
  Employee,
  EmployeeRole,
  RelationSnapShot,
  RelationType,
  FileResponse,
  AgendaItem,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { AccessThunks, Dispatch, SchedulerThunks } from "@haywork/middleware";
import { MappedDropdownValue } from "@haywork/modules/filter";
import { SchedulerOverviewComponent } from "@haywork/modules/scheduler";
import {
  AccountState,
  AppState,
  SchedulerActions,
  SchedulerFilters,
} from "@haywork/stores";
import { SimpleLabelValue } from "@haywork/util";
import { push } from "connected-react-router";
import first from "lodash-es/first";
import { connect, MapStateToProps } from "react-redux";
import {
  agendaItemsSelected,
  agendaItemCategories,
  sideBarToggled,
} from "./selectors";

interface StateProps {
  agendaItems?: AgendaItemSnapShot[];
  agendaItemCategoriesFilterValues?: SimpleLabelValue[];
  agendaItemCategories?: AgendaItemCategory[];
  employeesFilterValues?: SimpleLabelValue[];
  employees?: RelationSnapShot[];
  loggedInEmployee?: RelationSnapShot | Employee;
  schedulerFilters: SchedulerFilters;
  realEstateAgencyId?: string;
  idToken?: string;
  culture?: string;
  schedulerOverviewState: string;
  host: string;
  apiVersion: string;
  isBackOfficeEmployee: boolean;
  majorTickFilters: MappedDropdownValue[];
  sideBarToggled?: boolean;
}

interface DispatchProps {
  getAgendaItems?: () => void;
  getAgendaItem: (id: string) => Promise<AgendaItem>;
  setSchedulerFilters?: (filters: SchedulerFilters) => void;
  persistSchedulerFilters?: (filters: SchedulerFilters) => void;
  addRecurrencyException?: (
    parentId: string,
    originalStartDateTime: Date,
    newEndDateTime?: Date,
    newStartDateTime?: Date
  ) => void;
  addRecurrencyExceptionAndOpenDetail?: (
    parentId: string,
    originalStartDateTime: Date,
    newEndDateTime?: Date,
    newStartDateTime?: Date,
    allDay?: boolean
  ) => void;
  deleteAgendaItem?: (id: string) => void;
  moveAgendaItem?: (
    id: string,
    newStartDateTime: Date,
    newEndDateTime: Date,
    linkedEmployeeIds: string[],
    agendaItem: AgendaItemSnapShot
  ) => void;
  goToDetail: (id: string) => void;
  navigateToDetail: (detailUrl: string) => void;
  navigateToNewScheduler: (
    startDateTime?: Date,
    endDateTime?: Date,
    linkedEmployeeIds?: string[]
  ) => void;
  exportAgenda: (
    start: Date,
    end: Date,
    culture: string,
    employeeIds: string[],
    categoryIds: string[]
  ) => Promise<FileResponse>;
}

const mapCategories = (
  categories: AgendaItemCategorySnapShot[]
): SimpleLabelValue[] => {
  return categories.map((category) => ({
    label: category.displayName,
    value: category.id,
  }));
};

const mapEmployees = (
  employees: RelationSnapShot[],
  ownEmployee: Employee
): SimpleLabelValue[] => {
  const employeesFilterValues: SimpleLabelValue[] = employees.reduce(
    (state, e) => {
      if (e.id === ownEmployee.id) {
        return [
          {
            label: intlContext.formatMessage({
              id: "myAgenda",
              defaultMessage: "myAgenda",
            }),
            value: e.id,
          },
          ...state,
        ];
      }
      state.push({ label: e.displayName, value: e.id });
      return state;
    },
    []
  );

  return employeesFilterValues;
};

const mapCurrentEmployee = (state: AccountState): RelationSnapShot => {
  if (state.currentRealestateAgency.role === EmployeeRole.Standard) {
    return {
      dateTimeCreated: state.employee.dateTimeCreated,
      dateTimeModified: state.employee.dateTimeModified,
      isActive: true,
      typeOfRelation: RelationType.Employee,
      id: state.employee.id,
      displayName: state.employee.displayName,
    };
  } else {
    return first(state.employees);
  }
};

const getMajorTickFilters = (): MappedDropdownValue[] => {
  return [
    {
      label: intlContext.formatMessage({
        id: "fifteenMinutes",
        defaultMessage: "fifteenMinutes",
      }),
      value: 15,
    },
    {
      label: intlContext.formatMessage({
        id: "thirtyMinutes",
        defaultMessage: "thirtyMinutes",
      }),
      value: 30,
    },
    {
      label: intlContext.formatMessage({
        id: "sixtyMinutes",
        defaultMessage: "sixtyMinutes",
      }),
      value: 60,
    },
  ];
};

const mapStateToProps: MapStateToProps<any, any, AppState> = <
  StateProps,
  SchedulerOverviewComponentProps
>(
  state: AppState
) => {
  const { schedulerOverviewState, schedulerFilters } = state.scheduler;
  const { employees, currentRealestateAgency } = state.account;
  const { token } = state.access;
  const { culture } = state.main;
  const { host, apiVersion } = state.appSettings;

  const agendaItemCategoriesFilterValues = mapCategories(
    state.scheduler.agendaItemCategories
  );
  const employeesFilterValues = mapEmployees(
    state.account.employees,
    state.account.employee
  );
  const loggedInEmployee = mapCurrentEmployee(state.account);
  const isBackOfficeEmployee =
    currentRealestateAgency.role === EmployeeRole.BackOffice;
  const majorTickFilters = getMajorTickFilters();

  return {
    agendaItems: agendaItemsSelected(state),
    sideBarToggled: sideBarToggled(state),
    agendaItemCategoriesFilterValues,
    agendaItemCategories: agendaItemCategories(state),
    employeesFilterValues,
    employees,
    loggedInEmployee,
    schedulerFilters,
    realEstateAgencyId: currentRealestateAgency.id,
    idToken: token,
    culture,
    schedulerOverviewState,
    host,
    apiVersion,
    isBackOfficeEmployee,
    majorTickFilters,
  };
};

const mapDispatchToProps = <DispatchProps, SchedulerOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getAgendaItems: () => dispatch(SchedulerThunks.getAgendaItems()),
  getAgendaItem: (id: string) => dispatch(SchedulerThunks.getAgendaItem(id)),
  setSchedulerFilters: (filters: SchedulerFilters) =>
    dispatch(
      SchedulerActions.setSchedulerFilters({ schedulerFilters: filters })
    ),
  persistSchedulerFilters: (filters: SchedulerFilters) =>
    dispatch(AccessThunks.setSchedulerFilters(filters)),
  addRecurrencyException: (
    parentId: string,
    originalStartDateTime: Date,
    newEndDateTime?: Date,
    newStartDateTime?: Date
  ) =>
    dispatch(
      SchedulerThunks.addRecurrencyException(
        parentId,
        originalStartDateTime,
        newStartDateTime,
        newEndDateTime
      )
    ),
  addRecurrencyExceptionAndOpenDetail: (
    parentId: string,
    originalStartDateTime: Date,
    newEndDateTime?: Date,
    newStartDateTime?: Date,
    allDay?: boolean
  ) =>
    dispatch(
      SchedulerThunks.addRecurrencyException(
        parentId,
        originalStartDateTime,
        newStartDateTime,
        newEndDateTime,
        true,
        allDay
      )
    ),
  deleteAgendaItem: (id: string) =>
    dispatch(SchedulerThunks.deleteAgendaItem(id)),
  moveAgendaItem: (
    id: string,
    newStartDateTime: Date,
    newEndDateTime: Date,
    linkedEmployeeIds: string[],
    currentAgendaItem: AgendaItemSnapShot
  ) =>
    dispatch(
      SchedulerThunks.moveAgendaItem(
        id,
        newStartDateTime,
        newEndDateTime,
        linkedEmployeeIds,
        currentAgendaItem
      )
    ),
  goToDetail: (id: string) => dispatch(push(`/app/scheduler/${id}`)),
  navigateToNewScheduler: (
    startDateTime?: Date,
    endDateTime?: Date,
    linkedEmployeeIds?: string[]
  ) =>
    dispatch(
      SchedulerThunks.createSchedulerItem(
        startDateTime,
        endDateTime,
        linkedEmployeeIds
      )
    ),
  navigateToDetail: (detailUrl: string) => dispatch(push(detailUrl)),
  exportAgenda: (
    start: Date,
    end: Date,
    culture: string,
    employeeIds: string[],
    categoryIds: string[]
  ) =>
    dispatch(
      SchedulerThunks.exportAgenda(
        start,
        end,
        culture,
        employeeIds,
        categoryIds
      )
    ),
});

export type SchedulerOverviewContainerProps = StateProps & DispatchProps;
export const SchedulerOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerOverviewComponent);
