import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from "reselect";
import { AppState } from "@haywork/stores";
import { RootEntityType } from "@haywork/api/event-center";

const equals = require("react-fast-compare");
const presentEmployeesSelector = (state: AppState) =>
  state.eventCenter.presence.presentEmployees || [];
const employeeSelector = (state: AppState) => state.account.employee.id;

export const presentEmployee = createSelector(
  employeeSelector,
  presentEmployeesSelector,
  (employeeId, presentEmployees) => {
    return presentEmployees.find(
      (presentEmployee) => presentEmployee.employeeId === employeeId
    );
  }
);

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, equals);

const memoizedEmployeeIds = createSelector(
  presentEmployeesSelector,
  employeeSelector,
  (_, entityType: RootEntityType, entityId: string) => ({
    entityType,
    entityId,
  }),
  (presentEmployees, employeeId, { entityId, entityType }) => {
    const filteredEmployees = presentEmployees.filter(
      (employee) => employee.employeeId !== employeeId
    );

    return filteredEmployees.reduce((state, employee) => {
      const matches = employee.entities.filter(
        (entity) => entity.id === entityId && entity.type === entityType
      );
      if (!!matches.length) {
        return [...state, employee.employeeId];
      }
      return state;
    }, [] as string[]);
  }
);

export const employeeIds = createDeepEqualSelector(
  memoizedEmployeeIds,
  (ids) => ids
);
