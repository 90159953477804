import {
  CommunicationLogDirection,
  RelationSnapShot,
  RelationType
} from "@haywork/api/kolibri";
import {
  RelationThunks,
  SchedulerThunks,
  VoipThunk,
  MapDispatchToProps
} from "@haywork/middleware";
import { AppState, VoipActions } from "@haywork/stores";
import { Call } from "@haywork/stores/voip/calls";
import { MapStateToProps, connect } from "react-redux";
import { push } from "connected-react-router";
import { VoipModule, VoipModuleProps } from "./voip";

interface StateProps {
  calls: Call[];
  employeeName: string;
}
interface DispatchProps {
  toggleCollapsed: (call: Call) => void;
  removeCall: (call: Call) => void;
  searchRelation: (
    phoneNumber: string,
    conversationId: string
  ) => Promise<void>;
  searchEmployee: (employeeId: string, conversationId: string) => Promise<void>;
  setLinkedRelations: (
    linkedRelations: RelationSnapShot[],
    conversationId: string
  ) => void;
  updateRelation: (
    id: string,
    typeOfRelation: RelationType,
    phoneNumber: string
  ) => Promise<void>;
  navigate: (path: string) => void;
  createAgendaItemForRelation: (relation: RelationSnapShot) => Promise<void>;
  addCommunicationLog: (
    relation: RelationSnapShot,
    conversationId: string,
    direction: CommunicationLogDirection,
    subject: string
  ) => Promise<void>;
  updateCommunicationLog: (
    communicationLogId: string,
    subject: string,
    note: string
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  VoipModuleProps,
  AppState
> = (state) => {
  const { calls } = state.voip.calls;
  const { displayName: employeeName } = state.account.employee;

  return {
    calls,
    employeeName
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, VoipModuleProps> = (
  dispatch
) => ({
  toggleCollapsed: (call: Call) =>
    dispatch(VoipActions.Calls.toggleCollapsed(call)),
  removeCall: (call: Call) => dispatch(VoipActions.Calls.removeCall(call)),
  searchRelation: (phoneNumber: string, conversationId: string) =>
    dispatch(VoipThunk.Calls.searchRelation(phoneNumber, conversationId)),
  searchEmployee: (employeeId: string, conversationId: string) =>
    dispatch(VoipThunk.Calls.searchEmployee(employeeId, conversationId)),
  setLinkedRelations: (
    linkedRelations: RelationSnapShot[],
    conversationId: string
  ) =>
    dispatch(
      VoipActions.Calls.setLinkedRelations(linkedRelations, conversationId)
    ),
  updateRelation: (
    id: string,
    typeOfRelation: RelationType,
    phoneNumber: string
  ) => dispatch(RelationThunks.addPhoneNumber(id, typeOfRelation, phoneNumber)),
  navigate: (path: string) => dispatch(push(path)),
  createAgendaItemForRelation: (relation: RelationSnapShot) =>
    dispatch(SchedulerThunks.createAgendaItemForRelation(relation)),
  addCommunicationLog: (
    relation: RelationSnapShot,
    conversationId: string,
    direction: CommunicationLogDirection,
    subject: string
  ) =>
    dispatch(
      VoipThunk.Calls.addCommunicationLog(
        relation,
        conversationId,
        direction,
        subject
      )
    ),
  updateCommunicationLog: (
    communicationLogId: string,
    subject: string,
    note: string
  ) =>
    dispatch(
      VoipThunk.Calls.updateCommunicationLog(communicationLogId, subject, note)
    )
});

export type VoipModuleContainerProps = StateProps & DispatchProps;
export const VoipModuleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoipModule);
