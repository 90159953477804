import {
  AssignmentPhase,
  MatchMailPeriod,
  MatchMailTrigger,
  SearchAssignment
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import { injectIntl, WithIntlProps } from "react-intl";

const styles = require("./matchmail.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

interface SearchAssignmentWidgetMatchMailProps {
  searchAssignment: SearchAssignment;
}
type Props = SearchAssignmentWidgetMatchMailProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetMatchMail extends React.Component<Props> {
  public render() {
    const { id, assignmentPhase } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, { id });

    return (
      <div>
        <h2>
          <ResourceText resourceKey="matchmail" />
        </h2>
        {assignmentPhase === AssignmentPhase.Concept && (
          <ResourceText resourceKey="conceptMatchMailPeriod" asHtml />
        )}
        {assignmentPhase !== AssignmentPhase.Concept && (
          <div>
            {this.renderMatchmailString()}
            {!this.props.searchAssignment.foreignId && (
              <Link to={url}>
                <ResourceText resourceKey="change" />
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }

  private getQueryValues() {
    const { searchAssignment } = this.props;
    const timeframe = `matchMailPeriodOptions.${value(
      searchAssignment,
      "matchMailPeriod",
      MatchMailPeriod.Never
    )}`;
    const start = value(searchAssignment, "sendEmailStartDate");
    const end = value(searchAssignment, "sendEmailEndDate");
    const trigger = `matchMailTriggerOptions.${value(
      searchAssignment,
      "matchMailTrigger",
      MatchMailTrigger.AllChanges
    )}`;
    const { formatMessage, formatDate } = this.props.intl;

    const queryValues = {
      timeframe: formatMessage({
        id: timeframe,
        defaultMessage: timeframe
      }).toLowerCase(),
      start: start
        ? formatDate(start, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          })
        : formatMessage({
            id: "notSet",
            defaultMessage: "not set"
          }).toLowerCase(),
      end: end
        ? formatDate(end, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          })
        : formatMessage({
            id: "notSet",
            defaultMessage: "not set"
          }).toLowerCase(),
      trigger: formatMessage({
        id: trigger,
        defaultMessage: trigger
      }).toLowerCase()
    };

    return queryValues;
  }

  private renderMatchmailString(): React.ReactElement<HTMLSpanElement> {
    const { matchMailPeriod } = this.props.searchAssignment;
    if (!matchMailPeriod || matchMailPeriod === MatchMailPeriod.Never) {
      return <ResourceText resourceKey="noMatchMailPeriod" asHtml />;
    }

    const queryValues = this.getQueryValues();
    return (
      <ResourceText resourceKey="matchMailQuery" values={queryValues} asHtml />
    );
  }
}

export const SearchAssignmentWidgetMatchMailComponent = injectIntl(
  SearchAssignmentWidgetMatchMail
);
