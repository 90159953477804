import {
  EventTimeSpan,
  MomentInTimeOption,
  MomentInTimeType,
  NotificationSettings,
  UnitOfTime,
  UnitOfTimeOption,
} from "@haywork/api/event-center";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import first from "lodash-es/first";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./forms.component.scss");

interface Props {
  setting: NotificationSettings;
  unitsOfTime: UnitOfTimeOption[];
  momentsInTime: MomentInTimeOption[];
  onChange: (timeSpans: EventTimeSpan[]) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class TimeSpansFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    let amountOfTime = 1;
    let momentInTime = MomentInTimeType.AfterEvent;
    let unitOfTime = UnitOfTime.Day;

    const timeSpans = first(this.props.setting.timeSpans);
    if (!!timeSpans) {
      amountOfTime = get(timeSpans, "amountOfTime", 1);
      momentInTime = get(
        timeSpans,
        "momentInTime",
        MomentInTimeType.AfterEvent
      );
      unitOfTime = get(timeSpans, "unitOfTime", UnitOfTime.Day);
    }

    this.formControls = {
      unitOfTime: { value: unitOfTime },
      amountOfTime: { value: amountOfTime },
      momentInTime: { value: momentInTime },
    };
  }

  public render() {
    return (
      <Form
        name={`timeSpans.${this.props.setting.eventType.toString()}`}
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <div className="form__row" styleName="row">
          <div className="form__group">
            <div className="column" styleName="amount-of-time">
              <Input.Number
                name="amountOfTime"
                min={1}
                max={999}
                data-cy={"CY-amountOftime" + this.props.setting.eventType}
              />
            </div>

            <div className="column__spacer" />

            <div className="column">
              <Input.NewSelect
                name="unitOfTime"
                values={this.props.unitsOfTime}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>

            <div className="column__spacer" />

            <div className="column">
              <Input.NewSelect
                name="momentInTime"
                values={this.props.momentsInTime}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>

            <div className="column__spacer" />

            <div className="column">
              <label className="post">
                <ResourceText
                  resourceKey={`notificationSettingsLabelTimeSpans.${this.props.setting.eventType.toString()}`}
                />
              </label>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const timeSpans: EventTimeSpan = {
      unitOfTime: values.unitOfTime,
      amountOfTime: values.amountOfTime,
      momentInTime: values.momentInTime,
    };
    this.props.onChange([timeSpans]);
  }
}
