import { RangeUnit } from "@haywork/api/mls";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";
import { Input } from "@haywork/modules/form";
import LocationInput from "@haywork/modules/mls/components/location-input";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { LocationRangeContainerProps } from "./location-range.container";

const styles = require("./style.scss");
const LOCATION_RANGES = [
  {
    label: "+0km",
    range: 0,
    rangeUnit: RangeUnit.Kilometer,
  },
  {
    label: "+50m",
    range: 5,
    rangeUnit: RangeUnit.Decameter,
  },
  {
    label: "+100m",
    range: 1,
    rangeUnit: RangeUnit.Hectometer,
  },
  {
    label: "+200m",
    range: 2,
    rangeUnit: RangeUnit.Hectometer,
  },
  {
    label: "+500m",
    range: 5,
    rangeUnit: RangeUnit.Hectometer,
  },
  {
    label: "+1km",
    range: 1,
    rangeUnit: RangeUnit.Kilometer,
  },
  {
    label: "+2km",
    range: 2,
    rangeUnit: RangeUnit.Kilometer,
  },
  {
    label: "+5km",
    range: 5,
    rangeUnit: RangeUnit.Kilometer,
  },
  {
    label: "+10km",
    range: 10,
    rangeUnit: RangeUnit.Kilometer,
  },
  {
    label: "+15km",
    range: 15,
    rangeUnit: RangeUnit.Kilometer,
  },
];

export type LocationRangeComponentProps = {
  location: ExtendedLocationSuggestionItem | null;
  range: number | null;
  rangeUnit: RangeUnit;
  index: string;
  onChange: (
    location: ExtendedLocationSuggestionItem | null,
    range: number | null,
    rangeUnit: RangeUnit,
    id: string
  ) => void;
  onClear: (id: string) => void;
};
type Props = LocationRangeComponentProps & LocationRangeContainerProps;

export const LocationRangeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ location, range, rangeUnit, index, onChange, onClear }) => {
      const [locationValue, setLocationValue] = useState(location);
      const [value, setValue] = useState(
        LOCATION_RANGES.find(
          (locationRange) =>
            locationRange.range === range &&
            locationRange.rangeUnit === rangeUnit
        )
      );
      const inited = useRef<boolean>(false);
      const [rangeDisabled, setRangeDisabled] = useState(
        !location?.supportsRadius
      );

      const updateLocation = useCallback(
        (location: ExtendedLocationSuggestionItem) => {
          setLocationValue(location);
          const disableRadius = !location.supportsRadius;
          setRangeDisabled(disableRadius);
          if (disableRadius) {
            setValue(LOCATION_RANGES[0]);
          }
        },
        []
      );

      const updateRange = useCallback(
        (value: { label: string; range: number; rangeUnit: RangeUnit }) => {
          setValue(value);
        },
        []
      );

      const onClearCallback = useCallback(() => {
        onClear(index);
      }, [onClear, index]);

      useEffect(() => {
        if (!inited.current) {
          inited.current = true;
          return;
        }

        onChange(locationValue, value.range, value.rangeUnit, index);
      }, [locationValue, value, index, onChange]);

      return (
        <div styleName="row">
          <div styleName="location">
            <LocationInput
              name={`location-range__location-filter.${index}`}
              value={locationValue}
              onChange={updateLocation}
            />
          </div>
          <div styleName="range">
            <Input.NewSelect
              name={`location-range__range-filter.${index}`}
              values={LOCATION_RANGES}
              value={value}
              displayProp="label"
              onChange={updateRange}
              disabled={rangeDisabled}
            />
          </div>
          {!!location && (
            <div styleName="clear">
              <div styleName="clear__trigger" onClick={onClearCallback}>
                <Icon name="times" light color={Colors.Gray} />
              </div>
            </div>
          )}
        </div>
      );
    }
  )
);
