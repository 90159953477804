import * as React from "react";
import { FC } from "react";
import ErrorLintComponent from "@haywork/components/ui/error-lint";
import I18n from "@haywork/components/i18n";

type Props = {
  isRecreational: boolean;
  isResidential: boolean;
  constructionNumber: string;
  hideHouseNumberWarningVisible: boolean;
};

export const HouseNumberWarningComponent: FC<Props> = ({
  isRecreational,
  constructionNumber,
  hideHouseNumberWarningVisible,
  isResidential,
}) => {
  if (
    !isRecreational &&
    !constructionNumber &&
    hideHouseNumberWarningVisible &&
    isResidential
  ) {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column">
            <ErrorLintComponent
              mode="box"
              visible={hideHouseNumberWarningVisible}
              messages={[
                <I18n value="assignment.address.hideHouseNumberWarning" />,
              ]}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
