import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { OnboardingComponent, OnboardingProps } from "./onboarding";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { MlsAccessData } from "@haywork/stores/mls/main/actions";
import { mlsAccessData } from "../../screens/list/selectors";

type StateProps = {
  mlsAccessData: MlsAccessData;
};
type DispatchProps = {
  toggleMlsSubscription: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  OnboardingProps,
  AppState
> = (state) => {
  return {
    mlsAccessData: mlsAccessData(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OnboardingProps> = (
  dispatch
) => ({
  toggleMlsSubscription: () =>
    dispatch(MLSThunk.RealEstateAgencies.toggleMlsSubscription()),
});

export type OnboardingContainerProps = StateProps & DispatchProps;
export const OnboardingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingComponent);
