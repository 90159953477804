import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import isString from "lodash-es/isString";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ListItemComponent } from "./list-item.component";
import isEqual from "lodash-es/isEqual";

const styles = require("./option-list.component.scss");

export enum UiOptionListAlignment {
  Left = "Left",
  Right = "Right",
}

interface Props {
  items: any[];
  triggerValue: string | { key: string; values: object };
  displayPath?: string;
  valuePath?: string;
  icon?: string;
  alignment?: UiOptionListAlignment;
  onChange: (value: any) => void;
}
interface State {
  selectedValue: any;
  alignment: UiOptionListAlignment;
  expanded: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class UiOptionListComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.onTriggerClickHandler = this.onTriggerClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.state = {
      selectedValue: null,
      alignment: this.props.alignment || UiOptionListAlignment.Left,
      expanded: false,
    };

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const { key, values } = isString(this.props.triggerValue)
      ? { key: this.props.triggerValue, values: {} }
      : this.props.triggerValue;
    const listStyle = classNames("list", {
      right: this.state.alignment === UiOptionListAlignment.Right,
      expanded: this.state.expanded,
    });

    return (
      <div styleName="option-list" ref={(ref) => (this.ref = ref)}>
        <div styleName="trigger" onClick={this.onTriggerClickHandler}>
          {!!this.props.icon && <i className={this.props.icon} />}
          <div styleName="text">
            <ResourceText resourceKey={key} values={values} />
          </div>
          <i className="fal fa-chevron-down" />
        </div>
        <div styleName={listStyle}>
          {this.props.items.map((item, idx) => (
            <ErrorBoundary key={idx}>
              <ListItemComponent
                item={item}
                displayPath={this.props.displayPath}
                valuePath={this.props.valuePath}
                onChange={this.onChangeHandler}
              />
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.expanded) {
      this.setState({ expanded: false });
    }
  }

  private onTriggerClickHandler() {
    this.setState({ expanded: !this.state.expanded });
  }

  private onChangeHandler(value: any) {
    if (isEqual(value, this.state.selectedValue)) {
      this.setState({ expanded: false });
      return;
    }
    this.setState({ selectedValue: value, expanded: false });
    this.props.onChange(value);
  }
}
