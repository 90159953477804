import {
  ContactCompany,
  RelationSnapShot,
  PhoneNumberType,
  RelationType,
} from "@haywork/api/kolibri";

export const mapContactCompanyToRelationSnapShot = (
  relation: ContactCompany
): RelationSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    emailAddresses,
    logo,
    name,
    phoneNumbers,
    address,
    displayName,
    linkedRelationGroups,
    futureAddress,
  } = relation;

  const letterAvatar = (name || "")
    .split(" ")
    .slice(0, 2)
    .map((slice) => slice[0])
    .join("");

  const mobileNumber = (phoneNumbers || []).find(
    (phone) => phone.type === PhoneNumberType.Mobile
  )?.number;
  const phoneNumber =
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Work)
      ?.number ||
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Home)
      ?.number ||
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Other)
      ?.number;

  const houseNumber = [address?.houseNumber, address?.houseNumberPostfix]
    .filter((d) => !!d)
    .join("-");
  const streetNameAndNumber = [address?.street?.name, houseNumber]
    .filter((d) => !!d)
    .join(" ");

  const futureHouseNumber = [
    futureAddress?.houseNumber,
    futureAddress?.houseNumberPostfix,
  ]
    .filter((d) => !!d)
    .join("-");
  const futureStreetNameAndNumber = [
    futureAddress?.street?.name,
    futureHouseNumber,
  ]
    .filter((d) => !!d)
    .join(" ");

  return {
    id,
    avatarUrl: logo?.urlPreview,
    dateTimeCreated,
    dateTimeModified,
    displayName,
    email: (emailAddresses || [])[0]?.address,
    secondEmailAddress: (emailAddresses || [])[1]?.address,
    thirdEmailAddress: (emailAddresses || [])[2]?.address,
    isActive,
    letterAvatar,
    linkedCreatedBy,
    linkedModifiedBy,
    linkedRelationGroups,
    locality: address?.locality?.name,
    mobileNumber,
    phoneNumber,
    streetNameAndNumber,
    postalCode: address?.postalCode,
    typeOfRelation: RelationType.ContactCompany,
    futureStreetNameAndNumber,
    futurePostalCode: futureAddress?.postalCode,
    futureLocality: futureAddress?.locality?.name,
  };
};
