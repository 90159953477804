import {
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { EmailMessage } from "@haywork/stores/email-v2";
import uniq from "lodash-es/uniq";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SaveModalContainerProps } from "./save-modal.container";

export type SaveModalComponentProps = {
  visible: boolean;
  message: EmailMessage;
  communicationLog: CommunicationLog | null;
  onClose: (refresh?: boolean) => void;
  onAddRelation: (query: string, communicationLog: CommunicationLog) => void;
};
type Props = SaveModalComponentProps & SaveModalContainerProps;

export const SaveModalComponent: FC<Props> = memo(
  ({
    visible,
    message,
    communicationLog,
    onClose,
    onAddRelation,
    saveCommunicationLog,
    navigate,
    getRelationsWithMatchingEmailAddress,
    getAssignmentsLinkedToRelations,
    defineNewCommunicationLog,
  }) => {
    const { subject } = message;

    const [loading, setLoading] = useState(false);
    const form = useRef<FormReference>(null);
    const [valid, setValid] = useState(false);

    const formControls = useMemo(() => {
      return {
        subject: { value: "", validators: [Validators.required()] },
        linkedRelations: { value: [] },
        linkedAssignments: { value: [] },
      } as FormControls;
    }, []);

    const onCloseHandler = useCallback(() => {
      if (loading) return;
      onClose();
    }, [loading, onClose]);

    const onSaveHandler = useCallback(async () => {
      if (loading || !form.current) return;
      try {
        setLoading(true);
        const { getValues } = form.current;
        const { subject, linkedAssignments, linkedRelations } = getValues();

        await saveCommunicationLog(
          linkedRelations,
          linkedAssignments,
          message,
          subject,
          communicationLog
        );
        onClose(true);
      } finally {
        setLoading(false);
      }
    }, [
      loading,
      setLoading,
      form,
      communicationLog,
      saveCommunicationLog,
      message,
      onClose,
    ]);

    const fetchSuggestions = useCallback(async () => {
      if (!!communicationLog || !form.current) return;
      const { from, replyTo } = message;
      const emailsFrom = (from || [])
        .map((email) => email.email)
        .filter((d) => !!d);

      const emailsReplyTo = (replyTo || [])
        .map((email) => email.email)
        .filter((d) => !!d);

      const emails = uniq([...emailsFrom, ...emailsReplyTo]);

      if (!emails.length) return;
      const linkedRelations = await getRelationsWithMatchingEmailAddress(
        emails
      );
      const { update } = form.current;

      update({
        linkedRelations,
      });

      if (!linkedRelations.length) return;
      const relationIds = linkedRelations.map((relation) => relation.id);
      const linkedAssignments = await getAssignmentsLinkedToRelations(
        relationIds
      );

      update({
        linkedAssignments,
      });
    }, [
      communicationLog,
      message,
      getRelationsWithMatchingEmailAddress,
      getAssignmentsLinkedToRelations,
      form,
    ]);

    useEffect(() => {
      if (visible) fetchSuggestions();
    }, [fetchSuggestions, visible]);

    const updateForm = useCallback(() => {
      if (!form.current) return;
      const { update } = form.current;
      let linkedRelations = [] as LinkedRelation[];
      let linkedAssignments = [] as LinkedAssignment[];
      let newSubject = subject || "";

      if (!!communicationLog) {
        const {
          linkedRelations: relations,
          linkedAssignments: assignments,
          subject,
        } = communicationLog;
        linkedRelations = relations || [];
        linkedAssignments = assignments || [];
        newSubject = subject || newSubject;
      }

      update({
        subject: newSubject,
        linkedRelations,
        linkedAssignments,
      });

      if (
        !!newSubject &&
        (!!linkedRelations.length || !!linkedAssignments.length)
      ) {
        setValid(true);
      }
    }, [form, subject, communicationLog, setValid]);

    useEffect(() => {
      if (visible) updateForm();
    }, [updateForm, visible]);

    const onAddRelationHandler = useCallback(
      async (query: string) => {
        let log = communicationLog;
        if (!log) {
          log = await defineNewCommunicationLog();
        }

        if (!!form.current) {
          const { getValues } = form.current;
          const { linkedRelations, linkedAssignments } = getValues();

          log = {
            ...log,
            linkedRelations,
            linkedAssignments,
          };
        }

        onAddRelation(query, log);
      },
      [form, onAddRelation, communicationLog]
    );

    const formOnChangeHandler = useCallback(
      (values: FormReturnValue) => {
        const { subject, linkedRelations, linkedAssignments } = values;
        const valid =
          !!subject && (!!linkedRelations.length || !!linkedAssignments.length);
        setValid(valid);
      },
      [setValid]
    );

    const onNavigateHandler = useCallback(
      (path: string) => {
        navigate(path);
        onClose();
      },
      [navigate, onClose]
    );

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title="emailSaveModal.header.title" close />
        <ModalBody>
          <Form
            name="emailSaveModal"
            formControls={formControls}
            form={(ref) => (form.current = ref)}
            onChange={formOnChangeHandler}
          >
            <div className="form__row">
              <label htmlFor="subject">
                <I18n value="emailSaveModal.label.subject" />
              </label>
              <Input.Text name="subject" disabled={loading} />
            </div>

            <div className="form__row">
              <label htmlFor="linkedRelations">
                <I18n value="emailSaveModal.label.linkedEntities" />
              </label>
              <Input.Suggestion
                name="linkedRelations"
                type="relation"
                relationTypes={[
                  RelationType.ContactCompany,
                  RelationType.ContactPerson,
                ]}
                onAdd={onAddRelationHandler}
                disabled={loading}
                onNavigate={onNavigateHandler}
              />
            </div>

            <div className="form__row">
              <Input.Suggestion
                name="linkedAssignments"
                type="assignment"
                disabled={loading}
                onNavigate={onNavigateHandler}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            category="primary"
            label="emailSaveModal.action.save"
            disabled={loading || !valid}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
            onClick={onSaveHandler}
          />
        </ModalFooter>
      </Modal>
    );
  }
);
