import { SearchAssignment } from "@haywork/api/kolibri";
import {
  MapDispatchToProps,
  SearchAssignmentThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SearchAssignmentSuggestionsComponent,
  SearchAssignmentSuggestionsComponentProps
} from "./search-assignment-suggestions";

type StateProps = {};
type DispatchProps = {
  getSearchAssignmentsByRelationIds: (
    relationIds: string[]
  ) => Promise<SearchAssignment[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SearchAssignmentSuggestionsComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SearchAssignmentSuggestionsComponentProps
> = (dispatch) => ({
  getSearchAssignmentsByRelationIds: (relationIds: string[]) =>
    dispatch(
      SearchAssignmentThunks.getSearchAssignmentsByRelationIds(relationIds)
    )
});

export type SearchAssignmentSuggestionsContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentSuggestionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentSuggestionsComponent);
