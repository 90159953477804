import {
  FinancialAdministration,
  FinancialAdministrationProduct,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FormReturnValue } from "@haywork/modules/form";
import { SettingsFinancialAdministrationProductsContainerProps } from "@haywork/modules/settings/modules/financial-administrations";
import { ConfirmComponent, Hint } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ProductComponent } from "./product.component";

const styles = require("./products.component.scss");

export interface SettingsFinancialAdministrationProductsComponentProps {}
interface State {
  financialAdministration: FinancialAdministration;
  saveConfirmVisible: boolean;
  numberOfEmptyProducts: number;
}
type Props = SettingsFinancialAdministrationProductsComponentProps &
  SettingsFinancialAdministrationProductsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsFinancialAdministrationProductsComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onAddProductClickHandler = this.onAddProductClickHandler.bind(this);
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onProductRemoveHandler = this.onProductRemoveHandler.bind(this);
    this.onProductChangeHandler = this.onProductChangeHandler.bind(this);
    this.onSaveCloseHandler = this.onSaveCloseHandler.bind(this);
    this.onSaveConfirmHandler = this.onSaveConfirmHandler.bind(this);

    const financialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    this.state = {
      financialAdministration,
      saveConfirmVisible: false,
      numberOfEmptyProducts: 0,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    const nextFinancialAdministration = nextProps.financialAdministrations.find(
      (f) => f.id === nextProps.match.params.id
    );
    const prevFinancialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    if (
      nextProps.match.params.id !== this.props.match.params.id ||
      nextFinancialAdministration.products.length !==
        prevFinancialAdministration.products.length
    ) {
      this.setState({
        financialAdministration: nextFinancialAdministration,
      });
    }
  }

  public render() {
    if (!this.state.financialAdministration) {
      return null;
    }
    const loading = this.props.financialAdministrationState === REQUEST.PENDING;

    return (
      <div styleName="products">
        <PageHeader
          title="settingsMenuFinancialAdministrationProductsServices"
          subTitle={this.state.financialAdministration.name}
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={this.onSaveClickHandler}
                disabled={loading}
              />
              <Button
                label="addNewProduct"
                icon={
                  <Icon
                    name="plus"
                    size={18}
                    light
                    color={loading ? Colors.Gray : Colors.White}
                  />
                }
                category="primary"
                onClick={this.onAddProductClickHandler}
                disabled={loading}
              />
            </>
          }
        />

        <div styleName="products__body">
          <div styleName="inner">
            <div styleName="products__list-header">
              <div styleName="column">
                <I18n value="group" />
              </div>
              <div styleName="column">
                <I18n value="productOrService" />
              </div>
              <div styleName="column">
                <I18n value="description" />
              </div>
              <div styleName="column">
                <I18n value="priceExVat" />
              </div>
              <div styleName="column">
                <I18n value="taxRate" />
              </div>
              <div styleName="column centered">
                <I18n value="activeUcFirst" />
              </div>
              <div styleName="column centered">
                <Hint message="productsMarketingHint" forceInline>
                  <I18n value="marketing" />
                  &nbsp;
                  <i className="fal fa-question-circle" />
                </Hint>
              </div>
              <div styleName="column">
                <I18n value="ledger" />
              </div>
              <div styleName="column" />
            </div>

            {loading && <Ui.Loaders.Fullscreen mask />}

            {this.state.financialAdministration.products.map((product, idx) => (
              <ErrorBoundary key={product.id}>
                <ProductComponent
                  product={product}
                  financialAdministration={this.state.financialAdministration}
                  onRemove={this.onProductRemoveHandler}
                  onChange={this.onProductChangeHandler}
                />
              </ErrorBoundary>
            ))}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.saveConfirmVisible}
          titleResourceKey="financialAdminEmptyProductsConfirmTitle"
          bodyResourceKey="financialAdminEmptyProductsConfirmBody"
          bodyValues={{ count: this.state.numberOfEmptyProducts }}
          onClose={this.onSaveCloseHandler}
          onConfirm={this.onSaveConfirmHandler}
        />
      </div>
    );
  }

  private onAddProductClickHandler() {
    const newProduct: FinancialAdministrationProduct = {
      id: null,
      isActive: false,
      isMarketingProduct: false,
      unitPriceNet: 0,
    };

    const products = [
      newProduct,
      ...this.state.financialAdministration.products,
    ];

    const financialAdministration = {
      ...this.state.financialAdministration,
      products,
    };

    this.props.updateFinancialAdministration(financialAdministration);
  }

  private onSaveClickHandler() {
    const count = this.state.financialAdministration.products.length;
    const filteredProducts = this.state.financialAdministration.products.filter(
      (p) => {
        return (
          !!p.descriptionShort ||
          !!p.description ||
          p.unitPriceNet !== 0 ||
          !!p.exportLedgerAccountCode
        );
      }
    );

    if (count !== filteredProducts.length) {
      this.setState({
        saveConfirmVisible: true,
        numberOfEmptyProducts: count - filteredProducts.length,
      });
    } else {
      this.props.updateFinancialAdministration(
        this.state.financialAdministration
      );
    }
  }

  private onProductRemoveHandler(id: number) {
    const products = this.state.financialAdministration.products.filter(
      (p) => p.id !== id
    );

    const financialAdministration = {
      ...this.state.financialAdministration,
      products,
    };

    this.setState({
      financialAdministration,
    });
  }

  private onProductChangeHandler(values: FormReturnValue) {
    const { id } = values;

    const products = this.state.financialAdministration.products.map((p) => {
      if (p.id === id) {
        return {
          ...p,
          ...values,
        };
      }
      return p;
    });

    const financialAdministration = {
      ...this.state.financialAdministration,
      products,
    };

    this.setState({
      financialAdministration,
    });
  }

  private onSaveCloseHandler() {
    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyProducts: 0,
    });
  }

  private onSaveConfirmHandler() {
    const products = this.state.financialAdministration.products.filter((p) => {
      return (
        !!p.descriptionShort ||
        !!p.description ||
        p.unitPriceNet !== 0 ||
        !!p.exportLedgerAccountCode
      );
    });
    const financialAdministration = {
      ...this.state.financialAdministration,
      products,
    };

    this.props.updateFinancialAdministration(financialAdministration);

    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyProducts: 0,
    });
  }
}
