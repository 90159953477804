import { RootEntityType } from "@haywork/api/event-center";
import {
  EmailAddress,
  EmailAddressType,
  LinkedRelationGroup,
  PhoneNumber,
  PhoneNumberType,
  RelationGroupSnapShot,
  SocialMediaType,
  ContactCompany,
} from "@haywork/api/kolibri";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import ExternalChanges from "@haywork/components/external-changes";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import { RELATIONROUTES, REQUEST } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import head from "lodash-es/head";
import Notes from "@haywork/modules/notes-v3";
import { ContactCompanyEditContainerProps } from "@haywork/modules/relation/containers/contact-company-edit.container";
import {
  ResourceText,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  initialDuplicateModalState,
  DuplicateModalState,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./edit.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface ContactCompanyEditComponentProps {}
interface State {
  isRelationGroupLoading: boolean;
  relationGroupsThatCantBeDeleted: LinkedRelationGroup[];
  showFutureAddress: boolean;
  confirmSaveAndClose: boolean;
  duplicateModalState: DuplicateModalState;
  newRelation: {
    relation: ContactCompany;
    path: string;
  };
}
type Props = ContactCompanyEditComponentProps &
  ContactCompanyEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ContactCompanyEditComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private form: FormReference;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;

  constructor(props) {
    super(props);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.countrySelectedDisplay = this.countrySelectedDisplay.bind(this);
    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.getRelationGroupsThatCantGetDeleted = this.getRelationGroupsThatCantGetDeleted.bind(
      this
    );
    this.addManualRelationGroup = this.addManualRelationGroup.bind(this);
    this.mapRelationGroups = this.mapRelationGroups.bind(this);
    this.toggleFutureAddress = this.toggleFutureAddress.bind(this);

    const company = this.props.contactCompany;
    const { futureAddress, futureAddressActivationDateTime } = company;

    this.state = {
      isRelationGroupLoading: false,
      relationGroupsThatCantBeDeleted: this.getRelationGroupsThatCantGetDeleted(),
      showFutureAddress:
        !!futureAddress &&
        (!futureAddressActivationDateTime ||
          moment().isBefore(moment(futureAddressActivationDateTime))),
      confirmSaveAndClose: false,
      duplicateModalState: initialDuplicateModalState,
      newRelation: null,
    };

    this.formControls = {
      displayName: {
        value: value(company, "displayName"),
        validators: [Validators.required()],
      },
      webAddress: {
        value: value(company, "webAddress"),
        validators: [Validators.url()],
      },
      legalRegistrationNumber: {
        value: value(company, "legalRegistration.number"),
      },
      registrationLocality: {
        value: value(company, "legalRegistration.locality"),
      },
      legalRegistrationDate: {
        value: value(company, "legalRegistration.date"),
      },
      taxNumber: { value: value(company, "taxNumber") },
      bankAccountNumber: { value: value(company, "bankAccount.number") },
      socialMedia: { value: value(company, "socialMedia") },
      phoneNumbers: { value: value(company, "phoneNumbers") },
      emailAddresses: { value: value(company, "emailAddresses") },
      linkedRelationGroups: {
        value: company.linkedRelationGroups ? company.linkedRelationGroups : [],
      },
      visitAddress: { value: value(company, "address") },
      postalAddress: {
        value: company.postalAddressDiffersVisitAddress
          ? value(company, "postalAddress")
          : "",
      },
      futureAddress: {
        value: value(company, "futureAddress"),
      },
      futureAddressActivationDateTime: {
        value: value(company, "futureAddressActivationDateTime"),
      },
    };

    this.phoneNumbersFormControls = {
      number: { value: "" },
      type: { value: PhoneNumberType.Mobile },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Work },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.form &&
      get(prevProps.contactCompany, "dateTimeModified") !==
        get(this.props.contactCompany, "dateTimeModified")
    ) {
      const company = this.props.contactCompany;
      const { futureAddress, futureAddressActivationDateTime } = company;

      this.setState({
        relationGroupsThatCantBeDeleted: this.getRelationGroupsThatCantGetDeleted(),
        showFutureAddress:
          !!futureAddress &&
          (!futureAddressActivationDateTime ||
            moment().isBefore(moment(futureAddressActivationDateTime))),
      });

      this.form.update(
        {
          displayName: value(company, "displayName"),
          webAddress: value(company, "webAddress"),
          legalRegistrationNumber: value(company, "legalRegistration.number"),
          registrationLocality: value(company, "legalRegistration.locality"),
          legalRegistrationDate: value(company, "legalRegistration.date"),
          taxNumber: value(company, "taxNumber"),
          bankAccountNumber: value(company, "bankAccount.number"),
          socialMedia: value(company, "socialMedia"),
          phoneNumbers: value(company, "phoneNumbers"),
          emailAddresses: value(company, "emailAddresses"),
          linkedRelationGroups: company.linkedRelationGroups
            ? company.linkedRelationGroups
            : [],
          visitAddress: value(company, "address"),
          postalAddress: company.postalAddressDiffersVisitAddress
            ? value(company, "postalAddress")
            : "",
          futureAddress: value(company, "futureAddress"),
          futureAddressActivationDateTime: value(
            company,
            "futureAddressActivationDateTime"
          ),
        },
        true
      );
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.relationAddGroupsStatus === REQUEST.PENDING &&
      nextProps.relationAddGroupsStatus === REQUEST.SUCCESS
    ) {
      let linkedRelationGroups = [];
      if (nextProps.contactCompany.linkedRelationGroups) {
        linkedRelationGroups = [
          ...nextProps.contactCompany.linkedRelationGroups,
          nextProps.latestRelationGroupAdded,
        ];
      } else {
        linkedRelationGroups = [nextProps.latestRelationGroupAdded];
      }

      this.form.update({
        linkedRelationGroups,
      });
      this.setState({ isRelationGroupLoading: false });
    }
  }

  public shouldComponentUpdate() {
    if (this.props.saveRelationContactInfoState === REQUEST.ERROR) {
      return false;
    } else {
      return true;
    }
  }

  public render() {
    const platforms = [
      SocialMediaType.Facebook,
      SocialMediaType.LinkedIn,
      SocialMediaType.YouTube,
      SocialMediaType.Twitter,
    ];
    const { contactCompany, saveRelationContactInfoState } = this.props;
    let { displayName } = contactCompany;
    displayName = displayName || "contactCompany.pageHeader.emptyTitle";
    const saving = saveRelationContactInfoState === REQUEST.PENDING;

    return (
      <div styleName="edit">
        <PageHeader
          title={displayName}
          subTitle="contactCompany.pageHeader.subtitle"
          actions={
            <>
              <Presence
                entityId={contactCompany.id}
                entityType={RootEntityType.ContactCompany}
              />
              <Notes />
              <Button
                label="save"
                category="success"
                disabled={saving}
                onClick={() => this.setState({ confirmSaveAndClose: true })}
              />
            </>
          }
        />

        <ExternalChanges
          entityId={contactCompany.id}
          entityType={RootEntityType.ContactCompany}
          onReloadEntity={this.props.reloadContactCompany}
        />

        <div styleName="edit__body" id="scroll-to-top">
          {saving && <Ui.Loaders.Fullscreen mask />}
          <Form
            formControls={this.formControls}
            name="relation"
            onSubmit={this.onFormSubmitHandler}
            form={(formRef) => (this.form = formRef)}
            model={this.props.contactCompany}
            onChange={this.onChangeHandler}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              {/* Basic tab */}
              <StepComponent title="contactBasis">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="displayName">
                      <ResourceText resourceKey="companyName" />
                    </label>
                    <Input.Text name="displayName" placeholder="companyName" />
                  </div>

                  <div className="form__row">
                    <label htmlFor="linkedRelationGroups">
                      <ResourceText resourceKey="toWhichRelationGroupDoesThisRelationBelong" />
                    </label>
                    <Input.RelationGroupQueryV2
                      name="linkedRelationGroups"
                      placeholder="relationAroAddNewRelationGroup"
                      relationGroups={this.props.relationGroups}
                    />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Phone numbers */}
                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="phoneNumbers" />
                    </label>
                    <Input.Array
                      name="phoneNumbers"
                      formControls={this.phoneNumbersFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column" styleName="phone__field">
                        <Input.Text name="number" placeholder="phoneNumber" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="type__field">
                        <Input.NewSelect
                          name="type"
                          values={this.props.phoneNumberTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Email addresses */}
                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="emailAddresses" />
                    </label>
                    <Input.Array
                      name="emailAddresses"
                      formControls={this.emailAddressesFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column">
                        <Input.Text name="address" placeholder="address" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.NewSelect
                          name="type"
                          values={this.props.emailAddressTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  <div className="form__row">
                    <label>
                      <ResourceText resourceKey="visitAddress" />
                    </label>
                    <Input.LocationQueryV2
                      name="visitAddress"
                      countries={this.props.countries}
                      countryIso2={this.props.countryIso2}
                      culture={this.props.culture}
                      showManualAddressOnAddress={true}
                    />
                    {!this.props.contactCompany
                      .postalAddressDiffersVisitAddress && (
                      <div styleName="addLink">
                        <a
                          onClick={() =>
                            this.props.togglePostalAddress(
                              this.props.contactCompany.id
                            )
                          }
                        >
                          <ResourceText resourceKey="addPostalAddressQuestion" />
                        </a>
                      </div>
                    )}
                  </div>

                  {this.props.contactCompany
                    .postalAddressDiffersVisitAddress && (
                    <div className="form__row">
                      <label>
                        <ResourceText resourceKey="postalAddress" />
                        &nbsp;
                        <span
                          className="as-link"
                          onClick={() =>
                            this.props.togglePostalAddress(
                              this.props.contactCompany.id
                            )
                          }
                        >
                          <ResourceText resourceKey="removePostalAddress" />
                        </span>
                      </label>
                      <Input.LocationQueryV2
                        name="postalAddress"
                        countries={this.props.countries}
                        countryIso2={this.props.countryIso2}
                        culture={this.props.culture}
                        showManualAddressOnAddress={true}
                      />
                    </div>
                  )}

                  <FeatureSwitch feature="FUTURE_ADDRESS">
                    {!this.state.showFutureAddress ? (
                      <div className="form__row">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            this.setState({ showFutureAddress: true })
                          }
                        >
                          <ResourceText resourceKey="relation.futureAddress.toggle" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="form__row">
                          <label htmlFor="futureAddress">
                            <ResourceText resourceKey="relation.futureAddress.futureAddress" />
                            &nbsp;
                            <span
                              className="as-link"
                              onClick={this.toggleFutureAddress}
                            >
                              <ResourceText resourceKey="relation.futureAddress.remove" />
                            </span>
                          </label>
                          <Input.LocationQueryV2
                            name="futureAddress"
                            countries={this.props.countries}
                            countryIso2={this.props.countryIso2}
                            culture={this.props.culture}
                            showManualAddressOnAddress={true}
                          />
                        </div>

                        <div className="form__row">
                          <label htmlFor="futureAddressActivationDateTime">
                            <ResourceText resourceKey="relation.futureAddress.futureAddressActivationDateTime" />
                          </label>
                          <div className="form__group">
                            <div
                              className="column"
                              styleName="future-address__date"
                            >
                              <Input.Datepicker name="futureAddressActivationDateTime" />
                            </div>
                            <div className="column__spacer" />
                            <div
                              className="column"
                              styleName="future-address__info"
                            >
                              <i className="fa fa-info-circle" />
                              <ResourceText resourceKey="relation.futureAddress.info" />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </FeatureSwitch>

                  {/* Webaddress */}
                  <div className="form__row">
                    <label htmlFor="webAddress">
                      <ResourceText resourceKey="website" />
                    </label>
                    <Input.Url name="webAddress" placeholder="webAddress" />
                  </div>
                </div>
              </StepComponent>

              {/* Details tab */}
              <StepComponent title="contactDetails">
                <div styleName="relation-form">
                  <div className="form__row">
                    <div className="form__group stretch">
                      <div className="column">
                        <label htmlFor="companyRegisteredNumber">
                          <ResourceText resourceKey="companyRegisteredNumber" />
                        </label>
                        <Input.Text
                          name="legalRegistrationNumber"
                          placeholder="companyRegisteredNumber"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="registrationDate">
                          <ResourceText resourceKey="registrationDate" />
                        </label>
                        <Input.Datepicker
                          name="legalRegistrationDate"
                          placeholder="registrationDate"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="registrationCity">
                          <ResourceText resourceKey="registrationCity" />
                        </label>
                        <Input.Text
                          name="registrationLocality"
                          placeholder="registrationCity"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <label htmlFor="BTWNumber">
                      <ResourceText resourceKey="BTWNumber" />
                    </label>
                    <Input.Text name="taxNumber" placeholder="BTWNumber" />
                  </div>

                  <div className="form__row">
                    <label htmlFor="IBANNumber">
                      <ResourceText resourceKey="IBANNumber" />
                    </label>
                    <Input.Text
                      name="bankAccountNumber"
                      placeholder="IBANNumber"
                    />
                  </div>
                </div>
              </StepComponent>

              {/* Social tab */}
              <StepComponent title="contactSocial">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="socialMedia" />
                    <Input.Social name="socialMedia" platforms={platforms} />
                  </div>
                </div>
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>

        <ConfirmOverwrite
          entityType={RootEntityType.ContactCompany}
          entityId={contactCompany.id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => {
            this.form.submit();
            this.setState({ confirmSaveAndClose: false });
          }}
        />
        <DuplicatesModal
          visible={this.state.duplicateModalState.visible}
          view={this.state.duplicateModalState.view}
          relations={this.state.duplicateModalState.relations}
          relationData={this.state.duplicateModalState.relationData}
          onClose={() => this.clearState()}
          onNavigate={() => {
            this.clearState();
          }}
          onCreateNewRelation={() => {
            this.props.saveContactCompany(
              this.state.newRelation.relation,
              this.state.newRelation.path
            );
            this.clearState();
          }}
          mode={"create"}
        />
      </div>
    );
  }

  public async onFormSubmitHandler(values: FormReturnValue) {
    const company = this.props.contactCompany;
    const editedCompany = this.getContactCompanyFromFormValues(values);

    const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
      id: company.id,
    });

    const { emailAddresses: email, phoneNumbers: phone, isNew } = editedCompany;

    if (isNew) {
      const emailAddresses = (email || []).map((e) => e.address as string);
      const phoneNumbers = head<string>(
        (phone || []).map((p) => p.number as string)
      );

      const relations = await this.props.getRelationsWithMatchingEmailAddressOrPhoneNumber(
        emailAddresses,
        phoneNumbers
      );

      if (!!relations.length) {
        this.setState({
          duplicateModalState: {
            visible: true,
            view: "duplicates",
            relations,
            relationData: {
              email: head(emailAddresses),
              telephone: phoneNumbers,
            },
          },
          newRelation: {
            relation: editedCompany,
            path,
          },
        });
        return;
      }
    }

    this.props.saveContactCompany(editedCompany, path);
  }

  private clearState() {
    this.setState({
      duplicateModalState: initialDuplicateModalState,
      newRelation: { relation: null, path: null },
    });
  }

  private getContactCompanyFromFormValues(values: FormReturnValue) {
    const company = this.props.contactCompany;

    return {
      ...company,
      displayName: values.displayName,
      emailAddresses: (values.emailAddresses || [])
        .map((address: EmailAddress) =>
          !address.type ? { ...address, type: EmailAddressType.Work } : address
        )
        .filter((address: EmailAddress) => !!address.address),
      phoneNumbers: (values.phoneNumbers || [])
        .map((phone: PhoneNumber) =>
          !phone.type ? { ...phone, type: PhoneNumberType.Mobile } : phone
        )
        .filter((phone: PhoneNumber) => !!phone.number),
      webAddress: values.webAddress,
      legalRegistration: {
        ...company.legalRegistration,
        number: values.legalRegistrationNumber,
        locality: values.registrationLocality,
        date: values.legalRegistrationDate,
      },
      taxNumber: values.taxNumber,
      bankAccount: {
        ...company.bankAccount,
        number: values.bankAccountNumber,
      },
      socialMedia: values.socialMedia,
      address: values.visitAddress,
      postalAddress: values.postalAddress,
      futureAddress: values.futureAddress,
      futureAddressActivationDateTime: values.futureAddressActivationDateTime,
      linkedRelationGroups: this.mapRelationGroups(values.linkedRelationGroups),
    };
  }

  private mapRelationGroups(
    newRelations: RelationGroupSnapShot[]
  ): LinkedRelationGroup[] {
    let result = this.props.contactCompany.linkedRelationGroups
      ? [...this.props.contactCompany.linkedRelationGroups]
      : [];

    for (let i = 0; i < newRelations.length; i++) {
      const newRelation = newRelations[i];
      const foundRelation = result.find(
        (relation) => relation.id === newRelation.id
      );

      if (!foundRelation) {
        result.push({
          backColor: newRelation.backColor,
          displayName: newRelation.name,
          frontColor: newRelation.frontColor,
          id: newRelation.id,
          isActive: true,
          isReadOnly: false,
        });
      }
    }

    result = result.filter((item) => {
      const foundRelation = newRelations.find(
        (relation) => relation.id === item.id
      );
      return foundRelation;
    });

    return result;
  }

  private countrySelectedDisplay(value: any) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(value)} />
      </div>
    );
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const editedContactCompany = this.getContactCompanyFromFormValues(
      formReturnValue
    );
    this.props.updateContactCompany(editedContactCompany, this.props.path);
  }

  private addManualRelationGroup(value: string) {
    this.props.addNewRelationGroup(value);
    this.setState({ isRelationGroupLoading: true });
  }

  private getRelationGroupsThatCantGetDeleted(): LinkedRelationGroup[] {
    if (!this.props.contactCompany.linkedRelationGroups) return [];
    const result = this.props.contactCompany.linkedRelationGroups.filter(
      (relationGroup) => relationGroup.isReadOnly
    );
    return result;
  }

  private toggleFutureAddress() {
    if (!this.form) return;
    this.form.update({
      futureAddress: "",
      futureAddressActivationDateTime: "",
    });
    this.setState({ showFutureAddress: false });
  }
}
