import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import { ObjectTypesEditContainerProps } from "@haywork/modules/object-types";
import { Ui } from "@haywork/modules/ui";
import { ObjectTypesUtil, RouteUtil } from "@haywork/util";
import { ValidatedObjectTypesSubRoutes } from "@haywork/util/object-types";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { ObjectTypeAction } from "./actions";
import { ObjectTypesEditRouting } from "./edit.routing";
import Notes from "@haywork/modules/notes-v3";
import { ConfirmComponent } from "@haywork/modules/shared";
import ExternalChanges from "@haywork/components/external-changes";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import { RootEntityType } from "@haywork/api/event-center";
import Presence from "@haywork/components/ui/presence";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ObjectTypesEditComponentProps {}
interface State {
  previousRoute: string;
  nextRoute: string;
  validSubRoutes: ValidatedObjectTypesSubRoutes;
  backToDashboardConfirmVisible: boolean;
  confirmSave: boolean;
  confirmSaveAndClose: boolean;
}
type Props = ObjectTypesEditComponentProps & ObjectTypesEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditComponent extends React.Component<Props, State> {
  private routes: string[] = [
    OBJECTTYPESROUTES.EDIT_TYPE.URI,
    OBJECTTYPESROUTES.EDIT_GENERAL.URI,
    OBJECTTYPESROUTES.EDIT_SPECIFICATIONS.URI,
    OBJECTTYPESROUTES.EDIT_MARKETING.URI,
    OBJECTTYPESROUTES.PUBLISH.URI,
  ];
  private currentRouteIndex: number = 0;

  constructor(props) {
    super(props);

    this.onActionClick = this.onActionClick.bind(this);
    this.renderHouseInfo = this.renderHouseInfo.bind(this);
    this.backToDashboard = this.backToDashboard.bind(this);
    this.cancelBackToDashboard = this.cancelBackToDashboard.bind(this);

    this.state = {
      nextRoute: null,
      previousRoute: null,
      validSubRoutes: null,
      backToDashboardConfirmVisible: false,
      confirmSave: false,
      confirmSaveAndClose: false,
    };
  }

  public UNSAFE_componentWillMount() {
    const { id } = this.props.currentType;
    this.routes = this.routes.map((r) => route(r, { id }));
    const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes();
    const validSubRoutes = ObjectTypesUtil.validateSubRoutes(
      this.props.currentType
    );
    this.setState({ validSubRoutes, nextRoute, previousRoute });
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes(
        nextProps.location.pathname
      );
      const validSubRoutes = ObjectTypesUtil.validateSubRoutes(
        nextProps.currentType || this.props.currentType
      );
      this.setState({ validSubRoutes, nextRoute, previousRoute });
    }
  }

  public render() {
    if (!this.props.currentType) return null;

    const { id, isNew } = this.props.currentType;
    const { displayName } = this.props;

    const typeRoute = classNames("item", {
      valid: this.state.validSubRoutes.type,
    });
    const generalRoute = classNames("item", {
      valid: this.state.validSubRoutes.general,
    });
    const specificationsRoute = classNames("item", {
      valid: this.state.validSubRoutes.specifications,
    });
    const marketingRoute = classNames("item", {
      valid: this.state.validSubRoutes.marketing,
    });
    const loading = this.props.saving;

    return (
      <div styleName="edit">
        <PageHeader
          title={displayName || "projectTypeNewTitle"}
          subTitle={this.renderHouseInfo()}
          actions={
            <>
              <Presence
                entityId={id}
                entityType={RootEntityType.ObjectTypeAssignment}
              />
              <Notes />
              <Button
                label="save"
                category="success"
                onClick={() => this.setState({ confirmSave: true })}
                disabled={loading}
              />
              <Actions
                loading={loading}
                isFullScreen={this.props.fullscreen}
                showPublish={this.props.canPublish}
                isNew={isNew}
                onClick={this.onActionClick}
              />
            </>
          }
        />

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.ObjectTypeAssignment}
          onReloadEntity={this.props.reloadObjectType}
          onBackToDashboard={this.props.backToDashboard}
        />

        <div styleName="edit__body">
          {loading && <Ui.Loaders.Fullscreen mask />}
          <ObjectTypesEditRouting id={id} />
        </div>
        <div styleName="edit__navigation">
          <div styleName="button">
            {this.state.previousRoute && (
              <NavLink
                to={this.state.previousRoute}
                className="btn btn-default-alt icon-left"
              >
                <i className="fal fa-fw fa-chevron-left" />
                <I18n value="previousText" />
              </NavLink>
            )}
          </div>

          <div styleName="navigation">
            <div styleName="navigation__list">
              <NavLink
                styleName={typeRoute}
                to={route(OBJECTTYPESROUTES.EDIT_TYPE.URI, { id })}
                data-cy="CY-editTypeButton"
              >
                <div styleName="index">1</div>
                <I18n value="projectTypeNavigationType" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={generalRoute}
                to={route(OBJECTTYPESROUTES.EDIT_GENERAL.URI, { id })}
                data-cy="CY-editGeneralButton"
              >
                <div styleName="index">2</div>
                <I18n value="projectTypeNavigationGeneral" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={specificationsRoute}
                to={route(OBJECTTYPESROUTES.EDIT_SPECIFICATIONS.URI, { id })}
                data-cy="CY-editObjectTypesButton"
              >
                <div styleName="index">3</div>
                <I18n value="projectTypeNavigationSpecifications" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={marketingRoute}
                to={route(OBJECTTYPESROUTES.EDIT_MARKETING.URI, { id })}
                data-cy="CY-editMarketingButton"
              >
                <div styleName="index">4</div>
                <I18n value="projectTypeNavigationMarketing" />
              </NavLink>
            </div>
          </div>

          <div styleName="button last">
            {this.state.nextRoute &&
              this.currentRouteIndex < this.routes.length - 2 && (
                <NavLink
                  to={this.state.nextRoute}
                  className="btn btn-success icon-right"
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
            {this.props.canPublish &&
              this.currentRouteIndex === this.routes.length - 2 && (
                <NavLink
                  to={route(OBJECTTYPESROUTES.PUBLISH.URI, { id })}
                  className="btn btn-success icon-right"
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.backToDashboardConfirmVisible}
          titleResourceKey="objectType.backToDashboard.title"
          bodyResourceKey="objectType.backToDashboard.body"
          onClose={this.cancelBackToDashboard}
          onConfirm={this.backToDashboard}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ObjectTypeAssignment}
          entityId={id}
          saving={this.state.confirmSave}
          onCancel={() => this.setState({ confirmSave: false })}
          onConfirm={() => {
            this.props.saveType(this.props.currentType, false);
            this.setState({ confirmSave: false });
          }}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ObjectTypeAssignment}
          entityId={id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => {
            this.props.saveType(this.props.currentType);
            this.setState({ confirmSaveAndClose: false });
          }}
        />
      </div>
    );
  }

  private getNextAndPreviousRoutes(pathname?: string): {
    nextRoute: string;
    previousRoute: string;
  } {
    this.currentRouteIndex = this.routes.reduce((state, route, idx) => {
      const path = pathname || this.props.location.pathname;
      if (new RegExp(escapeRegExp(route), "gi").test(path)) return idx;
      return state;
    }, -1);

    const nextRoute =
      this.currentRouteIndex <= this.routes.length
        ? this.routes[this.currentRouteIndex + 1]
        : null;
    const previousRoute =
      this.currentRouteIndex > 0
        ? this.routes[this.currentRouteIndex - 1]
        : null;

    return {
      nextRoute,
      previousRoute,
    };
  }

  private renderHouseInfo(): string {
    const {
      typePART,
      houseSort,
      houseType,
      houseCharacteristic,
      apartmentSort,
      apartmentCharacteristic,
      forSale,
      forRent,
    } = this.props.currentType;
    const typePARTValue = !!typePART
      ? this.props.intl.formatMessage({
          id: `typePARTOptions.${typePART.toString()}`,
          defaultMessage: `typePARTOptions.${typePART.toString()}`,
        })
      : null;
    const houseSortValue = !!houseSort
      ? this.props.intl.formatMessage({
          id: `houseSorts.${houseSort.toString()}`,
          defaultMessage: `houseSorts.${houseSort.toString()}`,
        })
      : null;
    const houseTypeValue = !!houseType
      ? this.props.intl.formatMessage({
          id: `houseTypes.${houseType.toString()}`,
          defaultMessage: `houseTypes.${houseType.toString()}`,
        })
      : null;
    const houseCharacteristicValue = !!houseCharacteristic
      ? this.props.intl.formatMessage({
          id: `houseCharacteristics.${houseCharacteristic.toString()}`,
          defaultMessage: `houseCharacteristics.${houseCharacteristic.toString()}`,
        })
      : null;
    const apartmentSortValue = !!apartmentSort
      ? this.props.intl.formatMessage({
          id: `apartmentSorts.${apartmentSort.toString()}`,
          defaultMessage: `apartmentSorts.${apartmentSort.toString()}`,
        })
      : null;
    const apartmentCharacteristicValue = !!apartmentCharacteristic
      ? this.props.intl.formatMessage({
          id: `apartmentCharacteristics.${apartmentCharacteristic.toString()}`,
          defaultMessage: `apartmentCharacteristics.${apartmentCharacteristic.toString()}`,
        })
      : null;

    let forSaleText;
    if (forSale) {
      forSaleText = this.props.intl.formatMessage({
        id: "forSale",
        defaultMessage: "sale",
      });
    }

    let forRentText;
    if (forRent) {
      forRentText = this.props.intl.formatMessage({
        id: "forRent",
        defaultMessage: "rent",
      });
    }

    const houseInfo = [
      typePARTValue,
      houseSortValue,
      houseTypeValue,
      houseCharacteristicValue,
      apartmentSortValue,
      apartmentCharacteristicValue,
      forSaleText,
      forRentText,
    ];

    const houseInfoString = houseInfo
      .filter((value) => !!value)
      .join(", ")
      .toLowerCase();

    return houseInfoString.charAt(0).toUpperCase() + houseInfoString.slice(1);
  }

  private onPublishClickHandler() {
    this.props.navigate(
      route(OBJECTTYPESROUTES.PUBLISH.URI, { id: this.props.currentType.id })
    );
  }

  private onActionClick(action: ObjectTypeAction) {
    switch (action) {
      case ObjectTypeAction.BackToDashboard: {
        if (this.props.hasChanges) {
          this.setState({ backToDashboardConfirmVisible: true });
        } else {
          this.props.backToDashboard();
        }
        break;
      }
      case ObjectTypeAction.Fullscreen: {
        this.props.toggleFullscreen();
        break;
      }
      case ObjectTypeAction.Publish: {
        this.onPublishClickHandler();
        break;
      }
      case ObjectTypeAction.SaveAndClose: {
        this.setState({ confirmSaveAndClose: true });
        break;
      }
      default: {
        break;
      }
    }
  }

  private backToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
    this.props.backToDashboard();
  }

  private cancelBackToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
  }
}
