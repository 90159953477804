import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./page-loader.component.scss");

interface PageLoaderComponentProps {
  loading: boolean;
  fullscreen?: boolean;
  strokeWidth?: number;
  size?: number;
}
interface PageLoaderComponentState {
  strokeWidth: number;
  size: number;
}

@CSSModules(styles, { allowMultiple: true })
export class PageLoader extends React.Component<
  PageLoaderComponentProps,
  PageLoaderComponentState
> {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      size: 56,
      strokeWidth: this.props.strokeWidth || 3,
    };
  }

  public render() {
    const pageLoader = classNames("page-loader", {
      loading: this.props.loading,
      fullscreen: this.props.fullscreen,
    });

    return (
      <div styleName={pageLoader}>
        <div styleName="loader" style={this.getLoaderSize()}>
          <svg styleName="circular" viewBox="25 25 50 50">
            <circle
              styleName="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeMiterlimit="10"
              style={this.getStrokeWidth()}
            />
          </svg>
        </div>
      </div>
    );
  }

  private getLoaderSize() {
    return { width: `${this.state.size}px` };
  }

  private getStrokeWidth() {
    return { strokeWidth: `${this.state.strokeWidth}px` };
  }
}
