import { EmployeeFilter } from "@haywork/middleware/thunk/employee.thunk";
import * as ActionType from "./employee.types";
import { RelationSnapShot, Employee } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";

const updateEmployeeFilter = (employeeFilter: EmployeeFilter) => ({
    type: REDUX.EMPLOYEE.UPDATE_EMPLOYEE_FILTER,
    employeeFilter
});

const setFetchEmployeeOverviewState = (employeeOverviewState: string) => ({
    type: REDUX.EMPLOYEE.SET_FETCH_EMPLOYEE_OVERVIEW_STATE,
    employeeOverviewState
});

const setEmployees = (payload: ActionType.EmployeeResponse) => ({
    type: REDUX.EMPLOYEE.SEARCH_EMPLOYEES,
    ...payload
});

const setEmployeeDirectly = (employee: Employee) => ({
  type: REDUX.EMPLOYEE.SET_EMPLOYEE,
  employee
});

const setEmployee = (payload: ActionType.SingleEmployeeResponse) => ({
  type: REDUX.EMPLOYEE.SET_EMPLOYEE,
  ...payload
});

const appendEmployee = (payload: ActionType.EmployeeResponse) => ({
    type: REDUX.EMPLOYEE.APPEND_EMPLOYEES,
    ...payload
});

const setInitialFetchEmployeeOverviewState = (employeeOverviewState: string) => ({
    type: REDUX.EMPLOYEE.SET_INITIAL_FETCH_EMPLOYEE_OVERVIEW_STATE,
    employeeOverviewState
});

const setEmployeeUpdateState = (employeeUpdateState: string) => ({
    type: REDUX.EMPLOYEE.SET_EMPLOYEE_UPDATE_STATE,
    employeeUpdateState
});

const archiveEmployee = (employee: RelationSnapShot) => ({
    type: REDUX.EMPLOYEE.ARCHIVE_EMPLOYEE,
    employee
});

const toggleEmployeModal = (showEmployeeModal: boolean) => ({
    type: REDUX.EMPLOYEE.TOGGLE_EMPLOYEE_MODAL,
    showEmployeeModal
});

const setEmployeeState = (employeeState?: string) => ({
    type: REDUX.EMPLOYEE.SET_EMPLOYEE_STATE,
    employeeState
});

const setEmployeeAssignmentState = (employeeAssignmentState?: string) => ({
    type: REDUX.EMPLOYEE.SET_EMPLOYEE_ASSIGNMENT_STATE,
    employeeAssignmentState
});

const appendAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.EMPLOYEE.APPEND_EMPLOYEE_ASSIGNMENTS,
  ...payload
});

const searchAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.EMPLOYEE.SEARCH_EMPLOYEE_ASSIGNMENTS,
  ...payload
});

const setAddressSearchState = (employeeAddressSearchState: string) => ({
  type: REDUX.EMPLOYEE.SET_EMPLOYEE_ADDRESS_SEARCH_STATE,
  employeeAddressSearchState
});

const setEmployeeSaveState = (employeeSaveState: string) => ({
  type: REDUX.EMPLOYEE.SET_SAVE_EMPLOYEE_STATE,
  employeeSaveState
});

const clearEmployees = () => ({
  type: REDUX.EMPLOYEE.CLEAR_EMPLOYEES
});

const setTotalAmountOfEmployees = (amountOfEmployees: number) => ({
  type: REDUX.EMPLOYEE.SET_TOTAL_AMOUNT_OF_EMPLOYEES,
  amountOfEmployees
});

export const EmployeeActions = {
    updateEmployeeFilter,
    setFetchEmployeeOverviewState,
    setInitialFetchEmployeeOverviewState,
    setEmployees,
    appendEmployee,
    setEmployeeUpdateState,
    archiveEmployee,
    toggleEmployeModal,
    setEmployeeState,
    setEmployee,
    setEmployeeDirectly,
    setEmployeeAssignmentState,
    appendAssignments,
    searchAssignments,
    setAddressSearchState,
    setEmployeeSaveState,
    clearEmployees,
    setTotalAmountOfEmployees
};
