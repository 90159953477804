import { ErrorMessage } from "@haywork/modules/form";

export const maxLength = (maxLength: number, errorMessage?: ErrorMessage) => (
  value?: string
): null | ErrorMessage => {
  if (!value || value.trim().length <= maxLength) return null;
  return (
    errorMessage || { key: "validatorMaxLength", values: { count: maxLength } }
  );
};
