import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEmailSignaturesOverviewComponent,
  SettingsEmailSignaturesOverviewComponentProps
} from "../components";
import { signatures } from "./selectors";

interface StateProps {
  signatures: TemplateDefinitionSnapShot[];
  status: string;
  defaultSignatureTemplateId: string;
}
interface DispatchProps {
  setDefaultSignatureTemplateId: (id: string, active: boolean) => Promise<void>;
  defineNew: () => Promise<void>;
  navigate: (path: string) => void;
  deleteTemplate: (id: string) => Promise<void>;
  unDeleteTemplate: (id: string) => Promise<void>;
  deleteTemplateFromState: (id: string, isDefaultTemplate: boolean) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEmailSignaturesOverviewComponentProps,
  AppState
> = (state) => {
  const { templatesStatus: status } = state.email.templates;
  const defaultSignatureTemplateId = !!state.emailV2.main.companySettings
    ? state.emailV2.main.companySettings.defaultSignatureTemplateId
    : null;

  return {
    signatures: signatures(state),
    status,
    defaultSignatureTemplateId
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEmailSignaturesOverviewComponentProps
> = (dispatch) => ({
  setDefaultSignatureTemplateId: (id: string, active: boolean) =>
    dispatch(EmailTemplatesThunks.setDefaultSignatureTemplateId(id, active)),
  defineNew: () => dispatch(EmailTemplatesThunks.defineNewSignature()),
  navigate: (path: string) => dispatch(push(path)),
  deleteTemplate: (id: string) =>
    dispatch(EmailTemplatesThunks.deleteTemplate(id)),
  unDeleteTemplate: (id: string) =>
    dispatch(EmailTemplatesThunks.unDeleteTemplate(id)),
  deleteTemplateFromState: (id: string, isDefaultTemplate: boolean) =>
    dispatch(
      EmailTemplatesThunks.deleteTemplateFromState(id, isDefaultTemplate)
    )
});

export type SettingsEmailSignaturesOverviewContainerProps = StateProps &
  DispatchProps;
export const SettingsEmailSignaturesOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEmailSignaturesOverviewComponent);
