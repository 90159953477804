import * as Ably from "ably";
import * as React from "react";
import { FC, memo, useContext, useEffect } from "react";
import { LiveDataContext } from "../../live-data.context";

type Props = {
  onConnectionChange: (
    channels: {
      companyChannel: string;
      personalChannel: string;
      presenceChannel: string;
    },
    realtime: Ably.Realtime,
    refreshMailRealtime: () => void
  ) => void;
};

const Connecter: FC<Props> = memo(({ onConnectionChange }) => {
  const {
    eventCenterChannels,
    eventCenterRealtime,
    refreshMailRealtime
  } = useContext(LiveDataContext);

  useEffect(() => {
    onConnectionChange(
      eventCenterChannels,
      eventCenterRealtime,
      refreshMailRealtime
    );
  }, [
    eventCenterChannels,
    eventCenterRealtime,
    onConnectionChange,
    refreshMailRealtime
  ]);

  return null;
});

export default Connecter;
