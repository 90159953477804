import {
  CountryOption,
  Language,
  RealEstateAgency,
  RealEstateAssociationOption,
} from "@haywork/api/kolibri";
import { REALESTATEAGENCYROUTES } from "@haywork/constants";
import { Dispatch, RealEstateAgencyThunks } from "@haywork/middleware";
import {
  RealEstateAgencyEditComponent,
  RealEstateAgencyEditComponentProps,
} from "@haywork/modules/real-estate-agency";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  realEstateAgency: RealEstateAgency;
  realEstateAgencyStatus: string;
  languages: Language[];
  defaultLanguage: Language;
  countries: CountryOption[];
  path: string;
  realEstateAssociations: RealEstateAssociationOption[];
}
interface DispatchProps {
  updateRealEstateAgencyCache: (
    componentState: RealEstateAgency,
    path: string
  ) => void;
  save: (componentState: RealEstateAgency, path: string) => void;
}

const mapStateToProps = <StateProps, RealEstateAgencyEditComponentProps>(
  state: AppState
) => {
  const { realEstateAgencyStatus } = state.realEstateAgency;
  const realEstateAgency = state.editable.currentComponentState;
  const { enabledLanguages, defaultLanguage } = state.company.settings;
  const { countries, realEstateAssociations } = state.main.mastertable.kolibri;
  const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
    id: realEstateAgency.id,
  });

  return {
    realEstateAgency,
    realEstateAgencyStatus,
    languages: enabledLanguages,
    defaultLanguage,
    countries,
    path,
    realEstateAssociations,
  };
};

const mapDispatchToProps = <DispatchProps, RealEstateAgencyEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateRealEstateAgencyCache: (
    componentState: RealEstateAgency,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        path,
        componentState,
      })
    ),
  save: (componentState: RealEstateAgency, path: string) =>
    dispatch(RealEstateAgencyThunks.saveRealEstateAgency(componentState, path)),
});

export type RealEstateAgencyEditContainerProps = StateProps & DispatchProps;
export const RealEstateAgencyEditContainer = connect<
  StateProps,
  DispatchProps,
  RealEstateAgencyEditComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RealEstateAgencyEditComponent);
