import { AssignmentSnapShot, AssignmentType } from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { WithIntlProps, injectIntl } from "react-intl";

const styles = require("./linked-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssigmentWidgetLinkedItemComponentProps {
  assignmentWidgetItem: AssignmentSnapShot;
  onNavigate: (url: string) => void;
}
type Props = AssigmentWidgetLinkedItemComponentProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class AssigmentWidgetLinkedItem extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.navigate = this.navigate.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
  }

  public render() {
    const { assignmentWidgetItem } = this.props;

    return (
      <div styleName="item" onClick={this.navigate}>
        <div styleName="image">
          {assignmentWidgetItem.photo1Url ? (
            <img
              src={assignmentWidgetItem.photo1Url}
              className="img-responsive"
            />
          ) : (
            <div styleName="noImage">
              <i className="fal fa-fw fa-camera-retro" />
            </div>
          )}
        </div>
        <div styleName="text">
          {(!!assignmentWidgetItem.displayName ||
            !!assignmentWidgetItem.streetNameAndNumber) && (
            <div styleName="streetName">
              {!!assignmentWidgetItem.displayName
                ? assignmentWidgetItem.displayName
                : assignmentWidgetItem.streetNameAndNumber}
            </div>
          )}
          {!!assignmentWidgetItem.locality && (
            <div styleName="locality">{assignmentWidgetItem.locality}</div>
          )}
        </div>
        {this.renderPrice()}
      </div>
    );
  }

  private navigate() {
    const { id, typeOfAssignment } = this.props.assignmentWidgetItem;
    let assignmentRoute;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        assignmentRoute = ACQUISITIONROUTES.DETAIL.URI;
        break;
      case AssignmentType.AcquisitionObject:
        assignmentRoute = ACQUISITIONOBJECTROUTES.DETAIL.URI;
        break;
      case AssignmentType.Object:
      default:
        assignmentRoute = ASSIGNMENTROUTES.DETAIL.URI;
        break;
      case AssignmentType.ObjectType:
        assignmentRoute = OBJECTTYPESROUTES.DETAIL.URI;
        break;
      case AssignmentType.Project:
        assignmentRoute = PROJECTROUTES.DETAIL.URI;
        break;
    }

    const path = route(assignmentRoute, { id });
    this.props.onNavigate(path);
  }

  private renderPrice() {
    const {
      typeOfAssignment,
      salePrice,
      salePriceMax,
      rentPrice,
      rentPriceMax,
      forSale
    } = this.props.assignmentWidgetItem;
    const prices = [];

    switch (typeOfAssignment) {
      case AssignmentType.Object:
      default: {
        const price = !!forSale ? salePrice : rentPrice;
        prices.push(
          this.props.intl.formatNumber(price, {
            style: "currency",
            currency: "EUR"
          })
        );
        break;
      }
      case AssignmentType.ObjectType:
      case AssignmentType.Project: {
        const minPrice = !!forSale ? salePrice : rentPrice;
        const maxPrice = !!forSale
          ? !!salePriceMax
            ? salePriceMax
            : 0
          : !!rentPriceMax
          ? rentPriceMax
          : 0;

        prices.push(
          this.props.intl.formatNumber(minPrice, {
            style: "currency",
            currency: "EUR"
          })
        );
        if (!!maxPrice) {
          prices.push(
            this.props.intl.formatNumber(maxPrice, {
              style: "currency",
              currency: "EUR"
            })
          );
        }
        break;
      }
    }

    return (
      <div styleName="price">
        <span styleName="label">
          <ResourceText resourceKey="askingPrice" />
        </span>
        <span styleName="value">
          {prices.join(` ${this.props.intl.formatMessage({ id: "untill" })} `)}
        </span>
      </div>
    );
  }
}

export const AssigmentWidgetLinkedItemComponent = injectIntl(
  AssigmentWidgetLinkedItem
);
