export default class AbortableRequest {
  private controller: AbortController;
  private signal: AbortSignal;
  private _window = window;
  private _windowFetch = window.fetch;

  constructor() {
    this.process = this.process.bind(this);
    this.fetch = this.fetch.bind(this);
    this._fetch = this._fetch.bind(this);
  }

  public async process() {
    if (!!this.controller && !this.signal.aborted) {
      await this.controller.abort();
    }

    this.controller = new AbortController();
    this.signal = this.controller.signal;
  }

  public abort() {
    if (!this.controller || !this.signal || this.signal.aborted) return;
    this.controller.abort();
  }

  public fetch() {
    return {
      fetch: this._fetch,
    };
  }

  private _fetch(info: RequestInfo, init?: RequestInit) {
    const requestInit = !init
      ? { signal: this.signal }
      : { ...init, signal: this.signal };
    return this._windowFetch.apply(this._window, [info, requestInit]);
  }
}
