import { AccountState, NewSchedulerItemValues } from "@haywork/stores";
import { BackOffice } from "./backoffice";

export class Scheduler {
  public static getDefaultNewSchedulerItemValues(
    account: AccountState
  ): NewSchedulerItemValues {
    const startDateTime: Date = new Date();
    startDateTime.setSeconds(0, 0);

    const endDateTime: Date = new Date();
    endDateTime.setMinutes(new Date().getMinutes() + 30);
    endDateTime.setSeconds(0, 0);

    const employeeId = BackOffice.getEmployeeId(account);
    const linkedEmployeeIds = [];

    if (!!employeeId) {
      linkedEmployeeIds.push(employeeId);
    }

    const result = {
      startDateTime,
      endDateTime,
      linkedEmployeeIds,
    };
    return result;
  }

  public static getSubjectAndLocation(
    categoryName?: string,
    location?: string
  ): { subject: string; location: string } {
    let newSubject: string = categoryName || "";
    if (categoryName) {
      newSubject = categoryName;
    }

    if (location) {
      if (categoryName) {
        newSubject += " bij ";
      } else {
        newSubject += "Bij ";
      }
      newSubject += location;
    }

    return {
      subject: newSubject,
      location: location || "",
    };
  }
}
