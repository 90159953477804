import { AgendaItem } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { SCHEDULERROUTES } from "@haywork/constants";
import { Dispatch } from "@haywork/middleware";
import { SchedulerRecurrenceModalComponent } from "@haywork/modules/scheduler";
import { AppState, EditableActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";
import { RRule } from "rrule";

export interface RecurrenceOption {
  text: string;
  value: any;
}

interface StateProps {
  recurrenceRules?: RRule;
  recurrenceOptions?: RecurrenceOption[];
  monthOptions?: RecurrenceOption[];
  weekNumberOptions?: RecurrenceOption[];
  weekdayOptions?: RecurrenceOption[];
  startDateTime?: Date;
  agendaItem: AgendaItem;
  path: string;
}
interface DispatchProps {
  updateAgendaItem: (componentState: AgendaItem, path: string) => void;
}

const mapStateToProps: MapStateToProps<any, any, AppState> = <
  StateProps,
  SchedulerRecurrenceModalComponentProps
>(
  state: AppState
) => ({
  recurrenceOptions: getRecurrenceOptions(),
  monthOptions: getMonthOptions(),
  weekNumberOptions: getWeekNumberOptions(),
  weekdayOptions: getWeekdayOptions(),
  recurrenceRules: generateRecurrenceDataObject(
    state.scheduler.agendaItem.recurrencePattern,
    state.scheduler.agendaItem.startDateTime
  ),
  startDateTime: state.scheduler.agendaItem.startDateTime,
  agendaItem: state.editable.currentComponentState,
  path: RouteUtil.mapStaticRouteValues(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
    id: state.scheduler.agendaItem.id
  })
});

const mapDispatchToProps = <
  DispatchProps,
  SchedulerRecurrenceModalComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateAgendaItem: (componentState: AgendaItem, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

const generateRecurrenceDataObject = (
  recurrencePattern: string,
  startDateTime: Date
) => {
  if (!recurrencePattern) {
    return new RRule({ freq: 3 });
  }
  const reccurenceOptions = RRule.parseString(recurrencePattern);
  reccurenceOptions.wkst = !reccurenceOptions.wkst
    ? RRule.MO
    : reccurenceOptions.wkst;
  return new RRule(reccurenceOptions);
};

const getRecurrenceOptions = (): RecurrenceOption[] => {
  return [
    {
      text: intlContext.formatMessage({ id: "daily", defaultMessage: "daily" }),
      value: RRule.DAILY
    },
    {
      text: intlContext.formatMessage({
        id: "weekly",
        defaultMessage: "weekly"
      }),
      value: RRule.WEEKLY
    },
    {
      text: intlContext.formatMessage({
        id: "monthly",
        defaultMessage: "monthly"
      }),
      value: RRule.MONTHLY
    },
    {
      text: intlContext.formatMessage({
        id: "yearly",
        defaultMessage: "yearly"
      }),
      value: RRule.YEARLY
    }
  ];
};

const getMonthOptions = (): RecurrenceOption[] => {
  return [
    {
      text: intlContext.formatMessage({
        id: "januari",
        defaultMessage: "januari"
      }),
      value: 1
    },
    {
      text: intlContext.formatMessage({
        id: "febuari",
        defaultMessage: "febuari"
      }),
      value: 2
    },
    {
      text: intlContext.formatMessage({ id: "march", defaultMessage: "march" }),
      value: 3
    },
    {
      text: intlContext.formatMessage({ id: "april", defaultMessage: "april" }),
      value: 4
    },
    {
      text: intlContext.formatMessage({ id: "may", defaultMessage: "may" }),
      value: 5
    },
    {
      text: intlContext.formatMessage({ id: "june", defaultMessage: "june" }),
      value: 6
    },
    {
      text: intlContext.formatMessage({ id: "july", defaultMessage: "july" }),
      value: 7
    },
    {
      text: intlContext.formatMessage({
        id: "august",
        defaultMessage: "august"
      }),
      value: 8
    },
    {
      text: intlContext.formatMessage({
        id: "september",
        defaultMessage: "september"
      }),
      value: 9
    },
    {
      text: intlContext.formatMessage({
        id: "october",
        defaultMessage: "october"
      }),
      value: 10
    },
    {
      text: intlContext.formatMessage({
        id: "november",
        defaultMessage: "november"
      }),
      value: 11
    },
    {
      text: intlContext.formatMessage({
        id: "december",
        defaultMessage: "december"
      }),
      value: 12
    }
  ];
};

const getWeekNumberOptions = (): RecurrenceOption[] => {
  return [
    {
      text: intlContext.formatMessage({ id: "first", defaultMessage: "first" }),
      value: 1
    },
    {
      text: intlContext.formatMessage({
        id: "second",
        defaultMessage: "second"
      }),
      value: 2
    },
    {
      text: intlContext.formatMessage({ id: "third", defaultMessage: "third" }),
      value: 3
    },
    {
      text: intlContext.formatMessage({
        id: "fourth",
        defaultMessage: "fourth"
      }),
      value: 4
    },
    {
      text: intlContext.formatMessage({ id: "last", defaultMessage: "last" }),
      value: -1
    }
  ];
};

const getWeekdayOptions = (): RecurrenceOption[] => {
  // bug in RRULE, weekday options get values based on a non-zero based index starting with 1. However, when passed into RRule
  // in order to parse the recurrence string it assumes zero based index. Bug only appears when starting day is Monday.
  // This is why we use a 0 based index for days instead of .getJsWeekday(). - TKL
  return [
    { text: "Ma.", value: 0 },
    { text: "Di.", value: 1 },
    { text: "Wo.", value: 2 },
    { text: "Do.", value: 3 },
    { text: "Vr.", value: 4 },
    { text: "Za.", value: 5 },
    { text: "Zo.", value: 6 }
  ];
};

export type SchedulerRecurrenceModalContainerProps = StateProps & DispatchProps;
export const SchedulerRecurrenceModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerRecurrenceModalComponent);
