import { Task } from "@haywork/api/kolibri";

export enum singleActions {
  SetTask = "Task.Single.SetTask",
  SetStatus = "Task.Single.SetStatus"
}

type SetTask = {
  type: singleActions.SetTask;
  task: Task;
};
const setTask = (task: Task) => ({
  type: singleActions.SetTask,
  task
});

type SetStatus = {
  type: singleActions.SetStatus;
  taskRequestStatus: string;
};
const setStatus = (taskRequestStatus: string) => ({
  type: singleActions.SetStatus,
  taskRequestStatus
});

export type SingleActions = SetTask | SetStatus;
export const Actions = {
  setTask,
  setStatus
};
