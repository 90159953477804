const FONT_FAMILIES = [
  { family: "Arial", fallback: "sans-serif" },
  { family: "Courier", fallback: "sans-serif" },
  { family: "Impact", fallback: "sans-serif" },
  { family: "Verdana", fallback: "sans-serif" },
  { family: "Helvetica", fallback: "sans-serif" },
  { family: "Tahoma", fallback: "sans-serif" },
  { family: "Times New Roman", fallback: "serif" }
];
const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

const STYLES = {};

FONT_FAMILIES.map((f) => {
  STYLES[`FONT_FAMILY_${ f.family.toUpperCase().replace(/\s/gi, "_") }`] = { fontFamily: `${ f.family }, ${ f.fallback }` };
});

FONT_SIZES.map((size) => {
  STYLES[`FONT_SIZE_${ size }`] = { fontSize: `${ size }px` };
});

export const EDITOR = {
  STYLES,
  FONT_FAMILIES,
  FONT_SIZES
};
