import * as React from "react";

import { ResourceText } from "@haywork/modules/shared";
import { ErrorMessage } from "./";

export const Errors = ({ errors }: { errors: ErrorMessage[] }) => {
  return (errors) ?
    <div className="form__errors">
      { errors.map((err, key) => <div className="error" key={ key }><ResourceText resourceKey={ err.key } values={ err.values } /></div>) }
    </div> :
    null;
};
