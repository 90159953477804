import { RangeUnit, RealtorSuggestionItem } from "@haywork/api/mls";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import { FilterType } from "@haywork/enum/list-filter-types";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterConfig } from "../..";
import { FilterGuidValues } from "../list-filter";
import isString from "lodash-es/isString";

type PillProps = {
  key: string;
  prefix: string;
  value: string;
  values?: { [key: string]: any };
  label?: string;
  type: FilterType;
  deletable: boolean;
};

type Props = {
  prefix: string;
  filters: FilterConfig;
  guidValues?: FilterGuidValues;
  fixedWhitelist?: string[];
  prefixBlacklist?: string[];
  onChange: (filters: FilterConfig) => void;
};

export const ActiveFiltersComponent: FC<Props> = memo(
  ({
    filters,
    prefix,
    guidValues,
    fixedWhitelist,
    prefixBlacklist,
    onChange,
  }) => {
    const intl = useIntl();

    const pills = useMemo(() => {
      const pills: PillProps[] = [];
      const guidValueKeys = Object.keys(guidValues || {});
      const nonDeletables = fixedWhitelist || [];
      const nonPrefixed = prefixBlacklist || [];

      for (const key in filters) {
        const filter = filters[key];
        const deletable = !nonDeletables.includes(key);
        const prefixed = !nonPrefixed.includes(key);

        switch (filter.type) {
          case FilterType.Array: {
            if (!filter.value.length) continue;

            filter.value.forEach((value) => {
              let label: string;
              if (guidValueKeys.includes(filter.prefix)) {
                const ref = (guidValues[filter.prefix] || []).find(
                  (d) => d.id === value
                );
                if (!!ref) label = ref.value;
              }
              pills.push({
                key,
                prefix: !prefixed ? undefined : filter.prefix || prefix,
                value,
                type: filter.type,
                label,
                deletable,
              });
            });
            continue;
          }

          case FilterType.SearchLocation: {
            if (!filter.value.length) continue;

            filter.value.forEach((value) => {
              pills.push({
                key,
                prefix: !prefixed ? undefined : filter.prefix || prefix,
                ...(value.label ? { value: value.label } : { value }),
                type: filter.type,
                deletable,
              });
            });
            continue;
          }

          case FilterType.PostalCodeRange: {
            if (!filter.value.length) continue;

            filter.value.forEach((value) => {
              pills.push({
                key,
                prefix: !prefixed ? undefined : filter.prefix || prefix,
                value: "mlsFilter.postalCodeRange",
                values: { min: value.min, max: value.max },
                type: filter.type,
                deletable,
              });
            });
            continue;
          }

          case FilterType.KeyNumberArray: {
            if (!filter.value.length) continue;

            const value = filter.value.join(", ");

            pills.push({
              key,
              prefix: !prefixed ? undefined : filter.prefix || prefix,
              value,
              type: filter.type,
              deletable,
            });
            continue;
          }
          case FilterType.DateRange: {
            if (!filter.value.min && !filter.value.max) continue;
            let value = key;

            switch (true) {
              case filter.value.min !== undefined &&
                filter.value.max !== undefined: {
                value = `${key}MinMax`;
                break;
              }
              case filter.value.min !== undefined: {
                value = `${key}Min`;
                break;
              }
              case filter.value.max !== undefined: {
                value = `${key}Max`;
                break;
              }
              default: {
                break;
              }
            }

            const min = isString(filter.value.min)
              ? new Date(filter.value.min)
              : filter.value.min;
            const max = isString(filter.value.max)
              ? new Date(filter.value.max)
              : filter.value.max;

            pills.push({
              key,
              prefix: !prefixed ? undefined : filter.prefix || prefix,
              value,
              values: { min, max },
              type: filter.type,
              deletable,
            });
            continue;
          }
          case FilterType.NumberRange:
          case FilterType.PriceRange:
          case FilterType.TextRange: {
            if (!filter.value.min && !filter.value.max) continue;
            let value = key;

            switch (true) {
              case filter.value.min !== undefined &&
                filter.value.max !== undefined: {
                value = `${key}MinMax`;
                break;
              }
              case filter.value.min !== undefined: {
                value = `${key}Min`;
                break;
              }
              case filter.value.max !== undefined: {
                value = `${key}Max`;
                break;
              }
              default: {
                break;
              }
            }

            pills.push({
              key,
              prefix: !prefixed ? undefined : filter.prefix || prefix,
              value,
              values: { min: filter.value.min, max: filter.value.max },
              type: filter.type,
              deletable,
            });
            continue;
          }
          case FilterType.MlsPriceRange: {
            if (!filter.value.type || (!filter.value.min && !filter.value.max))
              continue;
            let value = key;

            switch (true) {
              case filter.value.min !== undefined &&
                filter.value.max !== undefined: {
                value = `${key}MinMax`;
                break;
              }
              case filter.value.min !== undefined: {
                value = `${key}Min`;
                break;
              }
              case filter.value.max !== undefined: {
                value = `${key}Max`;
                break;
              }
              default: {
                break;
              }
            }

            const typeValue = intl.formatMessage({
              id: `priceRange.priceType.${filter.value.type}`,
              defaultMessage:
                process.env.NODE_ENV === "development"
                  ? `priceRange.priceType.${filter.value.type}`
                  : filter.value.type,
            });

            pills.push({
              key,
              prefix: !prefixed ? undefined : filter.prefix || prefix,
              value,
              values: {
                type: typeValue,
                min: filter.value.min,
                max: filter.value.max,
              },
              type: filter.type,
              deletable,
            });
            continue;
          }
          case FilterType.Select:
          case FilterType.Single: {
            if (!filter.value) continue;

            let label: string;
            if (guidValueKeys.includes(filter.prefix)) {
              const ref = (guidValues[filter.prefix] || []).find(
                (d) => d.id === filter.value
              );
              if (!!ref) label = ref.value;
            }

            pills.push({
              key,
              prefix: !prefixed ? undefined : filter.prefix || prefix,
              value: filter.value,
              type: filter.type,
              label,
              deletable,
            });
            continue;
          }
          case FilterType.LocationRange: {
            if (!filter.value) continue;

            filter.value.map(
              (
                value: {
                  location: ExtendedLocationSuggestionItem;
                  range: number;
                  rangeUnit: RangeUnit;
                },
                idx
              ) => {
                if (!value.location) return;
                const { type, label } = value.location;

                let typeValue = intl.formatMessage({
                  id: `locationSuggestionTypeOptions.${type}`,
                  defaultMessage: "",
                });
                typeValue = !typeValue ? typeValue : `(${typeValue})`;
                const displayName = [label, typeValue]
                  .filter((d) => !!d)
                  .join(" ");

                let range = "0km";

                switch (value.rangeUnit) {
                  case RangeUnit.Meter: {
                    range = `${value.range || 0}m`;
                    break;
                  }
                  case RangeUnit.Decameter: {
                    range = `${(value.range || 0) * 10}m`;
                    break;
                  }
                  case RangeUnit.Hectometer: {
                    range = `${(value.range || 0) * 100}m`;
                    break;
                  }
                  default: {
                    range = `${value.range || 0}km`;
                    break;
                  }
                }

                pills.push({
                  key,
                  prefix: !prefixed ? undefined : filter.prefix || prefix,
                  value: idx,
                  values: { displayName, range },
                  type: filter.type,
                  label: "pill.location",
                  deletable,
                });
              }
            );
            continue;
          }
          case FilterType.Realtor: {
            if (!filter.value) continue;

            filter.value.map(
              (
                value: {
                  realtor: RealtorSuggestionItem;
                },
                idx
              ) => {
                if (!value.realtor) return;
                const { label } = value.realtor;

                pills.push({
                  key,
                  prefix: !prefixed ? undefined : filter.prefix || prefix,
                  value: idx,
                  type: filter.type,
                  label,
                  deletable,
                });
              }
            );
            continue;
          }
          default: {
            continue;
          }
        }
      }

      return pills;
    }, [filters, prefix, guidValues, fixedWhitelist, prefixBlacklist, intl]);

    const handleDelete = useCallback(
      (pill: PillProps) => {
        let filter = { ...filters[pill.key] };
        if (!filter) return;

        switch (pill.type) {
          case FilterType.Array: {
            filter = {
              ...filter,
              value: filter.value.filter((v) => v !== pill.value),
            };
            break;
          }
          case FilterType.DateRange:
          case FilterType.NumberRange:
          case FilterType.PriceRange:
          case FilterType.MlsPriceRange:
          case FilterType.TextRange:
          case FilterType.KeyNumberArray:
          case FilterType.Select:
          case FilterType.Single: {
            filter = {
              ...filter,
              value: filter.emptyValue,
            };
            break;
          }
          case FilterType.LocationRange: {
            filter = {
              ...filter,
              value: filter.value.filter((_, idx) => idx !== pill.value),
            };
            break;
          }
          case FilterType.Realtor: {
            filter = {
              ...filter,
              value: filter.value.filter((_, idx) => idx !== pill.value),
            };
            break;
          }
          case FilterType.SearchLocation: {
            filter = {
              ...filter,
              value: filter.value.filter((_, idx) => {
                return _.label !== pill.value;
              }),
            };
            break;
          }
          case FilterType.PostalCodeRange: {
            filter = {
              ...filter,
              value: filter.value.filter((_, idx) => {
                return !(
                  _.min === pill.values.min && _.max === pill.values.max
                );
              }),
            };
            break;
          }
          default:
            return;
        }

        onChange({
          ...filters,
          [pill.key]: filter,
        });
      },
      [onChange, filters]
    );

    return (
      <div>
        {pills.map((pill, idx) => (
          <Pill
            label={pill.label || pill.value}
            labelPrefix={pill.prefix}
            labelValues={pill.values}
            key={idx}
            deletable={pill.deletable}
            color={Colors.Primary}
            solid
            onClick={() => handleDelete(pill)}
          />
        ))}
      </div>
    );
  }
);
