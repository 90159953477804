import {
  AssignmentSnapShot,
  AssignmentType,
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { EmailLinkedEntitiesContainerProps } from "@haywork/modules/email";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { EmailMessage } from "@haywork/stores/email-v2";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import uniq from "lodash-es/uniq";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./linked-entities.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface EmailLinkedEntitiesComponentProps {
  message: EmailMessage;
  onLinkedRelationsChange?: (relations: LinkedRelation[]) => void;
  onLinkedAssignmentsChange?: (assignments: LinkedAssignment[]) => void;
}
interface State {
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  loading: boolean;
  communicationLog: CommunicationLog;
  formVisible: boolean;
  linkedRelationCount: number;
  fetchingAssignments: boolean;
}
type Props = EmailLinkedEntitiesComponentProps &
  EmailLinkedEntitiesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export default class EmailLinkedEntitiesComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      newEntityVisible: false,
      newEntityOptions: null,
      loading: false,
      communicationLog: null,
      formVisible: false,
      linkedRelationCount: 0,
      fetchingAssignments: false,
    };

    this.formControls = {
      linkedRelations: {
        value: [],
        onChange: this.searchAssignments.bind(this),
      },
      linkedAssignments: {
        value: [],
        onChange: this.onLinkedAssignmentsChange.bind(this),
      },
    };

    this.addNewRelation = this.addNewRelation.bind(this);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.fetchCommunicationLogOrRelation =
      this.fetchCommunicationLogOrRelation.bind(this);
    this.renderRelationPill = this.renderRelationPill.bind(this);
    this.renderAssignmentPill = this.renderAssignmentPill.bind(this);
    this.openRelation = this.openRelation.bind(this);
    this.openAssignment = this.openAssignment.bind(this);
    this.fetchRelationSuggestions = this.fetchRelationSuggestions.bind(this);
  }

  public componentDidMount() {
    this.fetchCommunicationLogOrRelation();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.message.id !== prevProps.message.id) {
      this.formRef.update({ linkedRelations: [], linkedAssignments: [] });
      this.setState({
        communicationLog: null,
        formVisible: false,
        linkedRelationCount: 0,
      });
      this.fetchCommunicationLogOrRelation();
    }
  }

  public render() {
    const { loading, communicationLog, formVisible } = this.state;

    return (
      <>
        <Form
          name="emailLinkedEntities"
          formControls={this.formControls}
          onSubmit={this.onFormSubmitHandler}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="subTitle">
            <ResourceText
              resourceKey={
                !communicationLog ? "saveInTimeline" : "savedInTimeline"
              }
            />
          </div>

          {!formVisible && (
            <div styleName="linked-entities">
              <div styleName="pills">
                {get(
                  communicationLog,
                  "linkedRelations",
                  [] as LinkedRelation[]
                ).map(this.renderRelationPill)}
                {get(
                  communicationLog,
                  "linkedAssignments",
                  [] as LinkedAssignment[]
                ).map(this.renderAssignmentPill)}
              </div>
              <div
                styleName="edit"
                onClick={() => this.setState({ formVisible: true })}
              >
                <i className="fal fa-pencil" />
              </div>
            </div>
          )}

          <div
            styleName={classNames("entities", {
              visible: formVisible,
            })}
          >
            <div styleName="entities__inputs">
              <div styleName="relations">
                <Input.Suggestion
                  name="linkedRelations"
                  type="relation"
                  relationTypes={[
                    RelationType.ContactCompany,
                    RelationType.ContactPerson,
                  ]}
                  placeholder="searchOrChooseRelation"
                  onNavigate={this.props.navigate}
                  onAdd={this.addNewRelation}
                />
              </div>
              <div styleName="assignments">
                <Input.Suggestion
                  name="linkedAssignments"
                  type="assignment"
                  placeholder="searchOrChooseAssignment"
                  onNavigate={this.props.navigate}
                  fetchingResults={this.state.fetchingAssignments}
                />
              </div>
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              <ButtonLoader resourceKey="save" loading={loading} />
            </button>
          </div>
        </Form>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </>
    );
  }

  private onLinkedAssignmentsChange(
    ref: RawFormControl,
    get: (name: string) => RawFormControl
  ) {
    if (this.state.loading || !ref || !ref.value) return;

    const linkedAssignments: AssignmentSnapShot[] = ref.value || [];
    let linkedRelations: LinkedRelation[] = get("linkedRelations").value || [];
    const linkedRelationsIds = linkedRelations.map((relation) => relation.id);

    linkedAssignments.map((linkedAssignment) => {
      let linkedVendors = linkedAssignment.linkedVendors || [];
      linkedVendors = linkedVendors.filter(
        (linkedVendor) => !linkedRelationsIds.includes(linkedVendor.id)
      );

      if (!!linkedVendors.length) {
        linkedRelations = [...linkedRelations, ...linkedVendors];
      }
    });

    const linkedRelationCount = linkedRelations.length;
    this.setState({ linkedRelationCount });
    if (!linkedRelations.length) return;

    return {
      linkedRelations,
    };
  }

  private async searchAssignments(ref: RawFormControl) {
    if (!this.state.formVisible) return;

    try {
      this.setState({ fetchingAssignments: true });

      const relations = ref.value || [];
      if (relations.length < this.state.linkedRelationCount) return;

      const relationIds = relations
        .map((relation) => relation.id)
        .filter((d) => !!d);
      const linkedRelationCount = relationIds.length;

      this.setState({ linkedRelationCount });
      if (!linkedRelationCount) return;

      const linkedAssignments =
        await this.props.getAssignmentsLinkedToRelations(relationIds);

      this.formRef.update({
        linkedAssignments,
      });
    } finally {
      this.setState({ fetchingAssignments: false });
    }
  }

  private async fetchCommunicationLogOrRelation() {
    const id = get(this.props.message, "_metaData.linkedCommunicationLogId");
    if (!id) {
      this.fetchRelationSuggestions();
      this.setState({ formVisible: true });
      return;
    }
    this.setState({ loading: true });

    try {
      const communicationLog = await this.props.getCommunicationLog(id);
      let { linkedRelations, linkedAssignments } = communicationLog;
      linkedRelations = linkedRelations || [];
      linkedAssignments = linkedAssignments || [];

      this.formRef.update({
        linkedRelations,
        linkedAssignments,
      });

      this.setState({
        communicationLog,
        linkedRelationCount: linkedRelations.length,
      });

      if (!!this.props.onLinkedRelationsChange) {
        this.props.onLinkedRelationsChange(linkedRelations);
      }
      if (!!this.props.onLinkedAssignmentsChange) {
        this.props.onLinkedAssignmentsChange(linkedAssignments);
      }
    } catch {
      this.setState({ formVisible: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  private async fetchRelationSuggestions() {
    const { from, replyTo } = this.props.message;
    const emailsFrom = (from || [])
      .map((email) => email.email)
      .filter((d) => !!d);

    const emailsReplyTo = (replyTo || [])
      .map((email) => email.email)
      .filter((d) => !!d);

    const emailAdresses = uniq([...emailsFrom, ...emailsReplyTo]);

    if (!emailAdresses.length) return;
    this.setState({ loading: true });
    let linkedRelationCount = this.state.linkedRelationCount;

    try {
      const linkedRelations =
        await this.props.getRelationsWithMatchingEmailAddress(emailAdresses, [
          RelationType.ContactCompany,
          RelationType.ContactPerson,
        ]);
      this.formRef.update({
        linkedRelations,
      });
      linkedRelationCount = linkedRelations.length;

      if (!!this.props.onLinkedRelationsChange) {
        this.props.onLinkedRelationsChange(linkedRelations);
      }

      if (!!linkedRelations.length) {
        const linkedAssignments =
          await this.props.getAssignmentsLinkedToRelations(
            linkedRelations.map((relation) => relation.id)
          );
        this.formRef.update({
          linkedAssignments,
        });

        if (!!this.props.onLinkedAssignmentsChange) {
          this.props.onLinkedAssignmentsChange(linkedAssignments);
        }
      }
    } finally {
      this.setState({ loading: false, linkedRelationCount });
    }
  }

  private renderRelationPill(item: LinkedRelation) {
    return (
      <div
        key={item.id}
        styleName="pill"
        onClick={() => this.openRelation(item)}
      >
        {item.displayName}
      </div>
    );
  }

  private renderAssignmentPill(item: LinkedAssignment) {
    return (
      <div
        key={item.id}
        styleName="pill"
        onClick={() => this.openAssignment(item)}
      >
        {item.displayName}
      </div>
    );
  }

  private openRelation(item: LinkedRelation) {
    const { id, typeOfRelation } = item;

    switch (typeOfRelation) {
      case RelationType.ContactCompany: {
        const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      case RelationType.ContactPerson: {
        const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      default: {
        return;
      }
    }
  }

  private openAssignment(item: LinkedAssignment) {
    const { id, typeOfAssignment } = item;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      case AssignmentType.AcquisitionObject: {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      case AssignmentType.Object: {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      case AssignmentType.ObjectType: {
        const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      case AssignmentType.Project: {
        const path = route(PROJECTROUTES.DETAIL.URI, { id });
        this.props.navigate(path);
        return;
      }
      default: {
        return;
      }
    }
  }

  private async onFormSubmitHandler(values: FormReturnValue) {
    const { loading, communicationLog } = this.state;

    if (loading) return;
    this.setState({ loading: true });

    const relations = values.linkedRelations || [];
    const assignments = values.linkedAssignments || [];

    try {
      if (!!communicationLog && !relations.length && !assignments.length) {
        await this.props.removeCommunicationLog(communicationLog.id);
        this.setState({ communicationLog: null, formVisible: true });
        return;
      }

      const newCommunicationLog = await this.props.saveCommunicationLog(
        relations,
        assignments,
        this.props.message,
        communicationLog
      );

      this.setState({
        communicationLog: newCommunicationLog,
        formVisible: false,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedRelations } = this.formRef.getValues();
    linkedRelations = linkedRelations || [];
    linkedRelations = [...linkedRelations, relation];

    this.formRef.update({
      linkedRelations,
    });

    this.setState({
      newEntityVisible: false,
      linkedRelationCount: linkedRelations.length,
    });
  }
}
