import {
  Filter,
  FilterConfig,
  FilterSection,
  ListFilter,
  ListRefProps
} from "@haywork/components/ui/list";
import * as React from "react";
import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from "react";
import { FiltersContainerProps } from "./filters.container";

export type FiltersComponentProps = {
  list: MutableRefObject<ListRefProps>;
};
type Props = FiltersComponentProps & FiltersContainerProps;

export const FiltersComponent: FC<Props> = memo(
  ({
    filters,
    list,
    setFilters,
    realEstateGroupValues,
    saleOrRentValues,
    assignmentPhaseValues,
    officeValues,
    employeeValues,
    getOffices,
    getEmployees,
    archivedValues
  }) => {
    const [inited, setInited] = useState(false);

    const updateFilters = useCallback(
      (filters: FilterConfig) => {
        if (!list || !list.current) return;
        setFilters(filters);
        list.current.refresh();
      },
      [list, setFilters]
    );

    useEffect(() => {
      if (inited) return;
      if (!officeValues.length) getOffices();
    }, [officeValues.length, getOffices, inited]);

    useEffect(() => {
      if (inited) return;
      if (!employeeValues.length) getEmployees();
    }, [employeeValues.length, getEmployees, inited]);

    useEffect(() => {
      setInited(true);
    }, [setInited]);

    return (
      <ListFilter filters={filters} onChange={updateFilters}>
        <FilterSection title="assignment.filter.title.realEstateGroups">
          <Filter.CheckboxArray
            configKey="realEstateGroups"
            values={realEstateGroupValues}
          />
        </FilterSection>

        <FilterSection title="assignment.filter.title.saleOrRent">
          <Filter.CheckboxArray
            configKey="saleOrRent"
            values={saleOrRentValues}
          />
        </FilterSection>

        <FilterSection title="assignment.filter.title.assignmentPhases">
          <Filter.CheckboxArray
            configKey="assignmentPhases"
            values={assignmentPhaseValues}
          />
          <Filter.CheckboxArray configKey="archived" values={archivedValues} />
        </FilterSection>

        <FilterSection
          title="assignment.filter.title.offices"
          collapsable
          collapsed
        >
          <Filter.CheckboxArray configKey="offices" values={officeValues} />
        </FilterSection>

        <FilterSection
          title="assignment.filter.title.employees"
          collapsable
          collapsed
        >
          <Filter.CheckboxArray configKey="employees" values={employeeValues} />
        </FilterSection>
      </ListFilter>
    );
  }
);
