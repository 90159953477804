import {
  AssignmentType,
  FolderTree,
  GlobalSearchType,
  RelationType
} from "@haywork/api/kolibri";
import { DossierThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { SelectComponent, SelectComponentProps } from "./select";

type StateProps = {};
type DispatchProps = {
  readFolderTree: (
    parentId: string,
    type: GlobalSearchType,
    typeOfRelation?: RelationType,
    typeOfAssignment?: AssignmentType
  ) => Promise<FolderTree>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SelectComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SelectComponentProps
> = (dispatch) => ({
  readFolderTree: (
    parentId: string,
    type: GlobalSearchType,
    typeOfRelation?: RelationType,
    typeOfAssignment?: AssignmentType
  ) =>
    dispatch(
      DossierThunks.readFolderTree(
        parentId,
        type,
        typeOfRelation,
        typeOfAssignment,
        true
      )
    )
});

export type SelectContainerProps = StateProps & DispatchProps;
export const SelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectComponent);
