import {
  AppraisalCertification,
  CivilState,
  CountryOption,
  EmailAddressType,
  Employee,
  Gender,
  PhoneNumberType,
  SocialMediaType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { EMPLOYEEROUTES, REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { EmployeeEditContainerProps } from "@haywork/modules/settings";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as AddressRequest from "@haywork/request/address";
import { FormControlUtil, RouteUtil, StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./edit-employee.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

export interface EmployeeEditComponentProps {}
interface State {
  country: string;
  countryString: string;
  addressResult: google.maps.places.AutocompletePrediction;
  postalCountry: string;
  postalAddressResult: google.maps.places.AutocompletePrediction;
  postalCountryString: string;
  countryCode: string;
  postalCountryCode: string;
  allowPartnerForCivilState: boolean;
  gender: Gender;
}
type Props = EmployeeEditComponentProps & EmployeeEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmployeeEditComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private form: FormReference;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;
  private ref: HTMLDivElement;
  private isSaving: boolean;
  private isActiveAppraiser: boolean =
    this.props.selectedEmployee.certification.isActiveAppraiser;

  private civilStatesWithoutParter: CivilState[] = [
    CivilState.Divorced,
    CivilState.Single,
    CivilState.Unknown,
    CivilState.Unmarried,
    CivilState.Widow,
    CivilState.Widower,
  ];

  constructor(props) {
    super(props);
    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.renderCountryString = this.renderCountryString.bind(this);
    this.onAddressSearchChangeHandler =
      this.onAddressSearchChangeHandler.bind(this);
    this.onAddManualAddress = this.onAddManualAddress.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.getEmployeeFromFormValues = this.getEmployeeFromFormValues.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);

    this.state = {
      country: this.props.selectedEmployee.address.countryIso2,
      countryString: this.renderCountryString(
        this.props.selectedEmployee.address.countryIso2
      ),
      addressResult: null,
      postalCountry: this.props.selectedEmployee.postalAddress
        ? this.props.selectedEmployee.postalAddress.countryIso2
        : this.props.selectedEmployee.address.countryIso2,
      postalAddressResult: null,
      postalCountryString: this.renderCountryString(
        this.props.selectedEmployee.postalAddress.countryIso2
      ),
      countryCode: this.props.selectedEmployee.address.countryIso2,
      postalCountryCode: this.props.selectedEmployee.postalAddress.countryIso2,
      allowPartnerForCivilState:
        this.civilStatesWithoutParter.indexOf(
          this.props.selectedEmployee.civilState
        ) === -1,
      gender: this.props.selectedEmployee.gender,
    };

    const employee = this.props.selectedEmployee;

    // we've been using firstname for this field for a long time now. This way, if the nickname is empty, the users won't see an empty name for every employee.
    const nickname = value(employee, "nickname")
      ? value(employee, "nickname")
      : value(employee, "firstName");

    this.formControls = {
      gender: { value: this.props.selectedEmployee.gender },
      title: { value: value(employee, "title") },
      nickname: { value: nickname, validators: [Validators.required()] },
      lastName: {
        value: value(employee, "lastName"),
        validators: [Validators.required()],
      },
      middleName: { value: value(employee, "middleName") },
      firstName: {
        value: value(employee, "firstName"),
        onChange: (ref, get) => {
          return { nameLetters: this.formatNameLetters(ref.value) };
        },
      },
      nameLetters: { value: value(employee, "nameLetters") },
      phoneNumbers: { value: value(employee, "phoneNumbers") },
      emailAddresses: { value: value(employee, "emailAddresses") },

      spokenLanguages: { value: value(employee, "spokenLanguages") },
      dateOfBirth: { value: value(employee, "dateOfBirth") },
      cityOfBirth: { value: value(employee, "cityOfBirth") },
      countryIso2OfBirth: {
        value: employee.countryIso2OfBirth ? employee.countryIso2OfBirth : "NL",
      },

      identificationCardType: {
        value: value(employee, "identificationCard.type"),
      },
      identificationCardNumber: {
        value: value(employee, "identificationCard.number"),
      },
      socialSecurityNumber: { value: value(employee, "socialSecurityNumber") },
      identificationCardValidUntilDate: {
        value: value(employee, "identificationCard.validUntilDate"),
      },
      bankAccountNumber: {
        value: value(employee, "bankAccount.number"),
        validators: [Validators.iban()],
      },

      employeeDescription: { value: value(employee, "aboutMe") },
      socialMedia: { value: value(employee, "socialMedia") },

      certified: { value: this.isActiveAppraiser },
      certification: { value: value(employee, "certification.certification") },
      certificateCity: { value: value(employee, "certification.locality") },
      certificateDate: { value: value(employee, "certification.date") },
      certificateNumber: { value: value(employee, "certification.number") },

      streetname: { value: value(employee, "address.street.name") },
      houseNumber: { value: value(employee, "address.houseNumber") },
      houseNumberPostfix: {
        value: value(employee, "address.houseNumberPostfix"),
      },
      postalCode: { value: value(employee, "address.postalCode") },
      localityName: { value: value(employee, "address.locality.name") },

      postalStreetname: { value: value(employee, "postalAddress.street.name") },
      postalHouseNumber: {
        value: value(employee, "postalAddress.houseNumber"),
      },
      postalHouseNumberPostfix: {
        value: value(employee, "postalAddress.houseNumberPostfix"),
      },
      postalPostalCode: { value: value(employee, "postalAddress.postalCode") },
      postalLocalityName: {
        value: value(employee, "postalAddress.locality.name"),
      },

      country: { value: value(employee, "address.countryIso2") },
      postalCountry: { value: value(employee, "postalAddress.countryIso2") },
      addressSearch: {
        value: "",
        onChange: (ref) => {
          if (ref.value && ref.value.length > 0) {
            this.props.searchAddress(
              ref.value[0].description,
              this.state.countryCode,
              false,
              this.props.selectedEmployee.id
            );
          }
        },
      },
      postalAddressSearch: {
        value: "",
        onChange: (ref) => {
          if (ref.value && ref.value.length > 0) {
            this.props.searchAddress(
              ref.value[0].description,
              this.state.postalCountryCode,
              true,
              this.props.selectedEmployee.id
            );
          }
        },
      },
      civilState: {
        value: value(employee, "civilState"),
        onChange: (ref) => {
          this.setState({
            allowPartnerForCivilState:
              this.civilStatesWithoutParter.indexOf(ref.value) === -1,
          });
        },
      },
    };

    this.phoneNumbersFormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Mobile },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Work },
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: EmployeeEditComponentProps & EmployeeEditContainerProps
  ) {
    if (nextProps.selectedEmployee.address && !this.isSaving) {
      const address = nextProps.selectedEmployee.address;
      this.form.update({
        streetname: address.street ? address.street.name : "",
        houseNumber: address.houseNumber ? address.houseNumber : "",
        houseNumberPostfix: address.houseNumberPostfix
          ? address.houseNumberPostfix
          : "",
        postalCode: address.postalCode ? address.postalCode : "",
        localityName: address.locality ? address.locality.name : "",
        addressSearch: "",
      });
    }
    if (nextProps.selectedEmployee.postalAddress && !this.isSaving) {
      const postalAddress = nextProps.selectedEmployee.postalAddress;
      this.form.update({
        postalStreetname: postalAddress.street ? postalAddress.street.name : "",
        postalHouseNumber: postalAddress.houseNumber
          ? postalAddress.houseNumber
          : "",
        postalHouseNumberPostfix: postalAddress.houseNumberPostfix
          ? postalAddress.houseNumberPostfix
          : "",
        postalPostalCode: postalAddress.postalCode
          ? postalAddress.postalCode
          : "",
        postalLocalityName: postalAddress.locality
          ? postalAddress.locality.name
          : "",
        postalAddressSearch: "",
      });
    }

    if (
      nextProps.selectedEmployee.gender !==
        this.props.selectedEmployee.gender &&
      !!this.form
    ) {
      this.form.update({
        title: nextProps.employeeTitleSuggestions[0].displayName,
      });
    }
  }

  public render() {
    if (!this.props.selectedEmployee) return null;

    const platforms = [
      SocialMediaType.Facebook,
      SocialMediaType.LinkedIn,
      SocialMediaType.YouTube,
      SocialMediaType.Twitter,
    ];
    const loading = this.props.saveEmployeeState === REQUEST.PENDING;

    return (
      <div styleName="edit" ref={(ref) => (this.ref = ref)} id="scroll-to-top">
        <PageHeader
          title="pageTitle.employees.general"
          subTitle={this.props.selectedEmployee.displayName}
          actions={
            <Button
              label="save"
              category="success"
              onClick={() => this.form.submit()}
              disabled={loading}
            />
          }
        />
        <div styleName="body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <Form
            formControls={this.formControls}
            name="employee"
            onSubmit={this.onFormSubmitHandler}
            onChange={this.onChangeHandler}
            form={(formRef) => (this.form = formRef)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              <StepComponent title="contactBasis">
                <div styleName="relation-form">
                  {/* Gender */}
                  <div className="form__row">
                    <Input.RadioGroup name="gender" asButtonList>
                      <Input.Radio label="genderMale" value={Gender.Male} />
                      <Input.Radio label="genderFemale" value={Gender.Female} />
                      <Input.Radio label="genderOther" value={Gender.Other} />
                    </Input.RadioGroup>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="title">
                          <I18n value="title" />
                        </label>
                        <Input.NewSelect
                          name="title"
                          values={this.props.employeeTitleSuggestions}
                          displayProp="displayName"
                          valuesProp="displayName"
                        />
                      </div>
                      <div className="column__spacer" />
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="singleFirstName">
                          <I18n value="singleFirstName" />
                        </label>
                        <Input.Text
                          name="nickname"
                          placeholder="singleFirstName"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="middleName">
                          <I18n value="middleName" />
                        </label>
                        <Input.Text
                          name="middleName"
                          placeholder="middleName"
                          maxLength={45}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="lastName">
                          <I18n value="lastName" />
                        </label>
                        <Input.Text name="lastName" placeholder="lastName" />
                      </div>
                    </div>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Phone numbers */}
                  <div className="form__row">
                    <label>
                      <I18n value="phoneNumbers" />
                    </label>
                    <Input.Array
                      name="phoneNumbers"
                      formControls={this.phoneNumbersFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column">
                        <Input.Text name="number" placeholder="phoneNumber" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.NewSelect
                          name="type"
                          values={this.props.phoneNumberTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Email addresses */}
                  <div className="form__row">
                    <label>
                      <I18n value="emailAddresses" />
                    </label>
                    <Input.Array
                      name="emailAddresses"
                      formControls={this.emailAddressesFormControls}
                      max={4}
                      className="form__group stretch"
                    >
                      <div className="column">
                        <Input.Text name="address" placeholder="address" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.NewSelect
                          name="type"
                          values={this.props.emailAddressTypes}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </Input.Array>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Address info*/}
                  <div className="form__row">
                    {(this.props.selectedEmployee.address.street &&
                      this.props.selectedEmployee.address.street.name) ||
                    this.props.selectedEmployee.address.postalCode ||
                    (this.props.selectedEmployee.address.locality &&
                      this.props.selectedEmployee.address.locality.name) ? (
                      <div>{this.renderAddress(false)}</div>
                    ) : (
                      <div>{this.renderSearchAddressBar(false)}</div>
                    )}
                    {!this.props.selectedEmployee
                      .postalAddressDiffersVisitAddress && (
                      <div styleName="addLink">
                        <a
                          onClick={() =>
                            this.props.togglePostalAddress(
                              this.props.selectedEmployee.id
                            )
                          }
                        >
                          <I18n value="addPostalAddressQuestion" />
                        </a>
                      </div>
                    )}
                  </div>

                  {this.props.selectedEmployee
                    .postalAddressDiffersVisitAddress && (
                    <div className="form__row">
                      {(this.props.selectedEmployee.postalAddress.street &&
                        this.props.selectedEmployee.postalAddress.street
                          .name) ||
                      this.props.selectedEmployee.postalAddress.postalCode ||
                      (this.props.selectedEmployee.postalAddress.locality &&
                        this.props.selectedEmployee.postalAddress.locality
                          .name) ? (
                        <div>{this.renderAddress(true)}</div>
                      ) : (
                        <div styleName="addressBarRemoveOption">
                          {this.renderSearchAddressBar(true)}
                          <button
                            styleName="deleteItem"
                            type="button"
                            onClick={() => {
                              this.props.togglePostalAddress(
                                this.props.selectedEmployee.id
                              );
                            }}
                          >
                            <i className="fal fa-minus-circle" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </StepComponent>
              <StepComponent title="contactDetails">
                <div styleName="relation-form">
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="firstName">
                          <I18n value="fullFirstName" />
                        </label>
                        <Input.Text name="firstName" placeholder="firstName" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="nameLetters">
                          <I18n value="nameLetters" />
                        </label>
                        <Input.Text
                          name="nameLetters"
                          placeholder="nameLetters"
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Birth info */}
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="dateOfBirth">
                          <I18n value="dateOfBirth" />
                        </label>
                        <Input.Datepicker
                          name="dateOfBirth"
                          placeholder="dateOfBirth"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="cityOfBirth">
                          <I18n value="cityOfBirth" />
                        </label>
                        <Input.Text
                          name="cityOfBirth"
                          placeholder="cityOfBirth"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="countryIso2OfBirth">
                          <I18n value="countryIso2OfBirth" />
                        </label>
                        <Input.NewSelect
                          name="countryIso2OfBirth"
                          values={this.props.countries}
                          valuesProp="iso2CodeValue"
                          filterProp="displayName"
                          displayValueFn={this.renderCountryValue}
                          displayFn={this.renderCountryOption}
                        />
                      </div>
                    </div>
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Spoken languages */}
                  <div className="form__row">
                    <label htmlFor="spokenLanguages">
                      <I18n value="spokenLanguages" />
                    </label>
                    <div className="input__wrapper">
                      {/* TODO replace asap */}
                      <Input.QueryLegacy
                        name="spokenLanguages"
                        values={this.props.languages}
                        multiple
                        placeholder="locationPlacesPlaceholder"
                        asSelect
                        displayPath="displayName"
                        valuePath="value"
                        matchPath="displayName"
                      />
                    </div>
                  </div>

                  {/* Civil state */}
                  <div className="form__row">
                    <label htmlFor="civilState">
                      <I18n value="civilState" />
                    </label>
                    <Input.NewSelect
                      name="civilState"
                      values={this.props.civilStateOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                  <div styleName="relation-form__divider" />

                  {/* Identification info */}
                  <div className="form__row">
                    <label htmlFor="identificationCard.type">
                      <I18n value="identificationCard.type" />
                    </label>
                    <Input.NewSelect
                      name="identificationCardType"
                      values={this.props.identificationTypeOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="identificationCard.number">
                          <I18n value="identificationCard.number" />
                        </label>
                        <Input.Text
                          name="identificationCardNumber"
                          placeholder="identificationCard.number"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="socialSecurityNumber">
                          <I18n value="socialSecurityNumber" />
                        </label>
                        <Input.Text
                          name="socialSecurityNumber"
                          placeholder="socialSecurityNumber"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="identificationCard.validUntilDate">
                          <I18n value="identificationCard.validUntilDate" />
                        </label>
                        <Input.Datepicker
                          name="identificationCardValidUntilDate"
                          placeholder="date"
                        />
                      </div>
                    </div>
                  </div>

                  <div styleName="relation-form__divider" />
                  {/* Bank account */}
                  <div className="form__row">
                    <label htmlFor="bankAccount.number">
                      <I18n value="bankAccount.number" />
                    </label>
                    <Input.Text
                      name="bankAccountNumber"
                      placeholder="IBANNumberExample"
                    />
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="contactSocial">
                <div styleName="relation-form">
                  <div className="form__row">
                    <label htmlFor="employeeDescription">
                      <I18n
                        value="employeeDescription"
                        asHtml
                        values={{
                          firstName: this.props.selectedEmployee.firstName,
                        }}
                      />
                    </label>
                    <Input.TranslateText
                      name="employeeDescription"
                      languages={this.props.enabledLanguages}
                      defaultLanguage={this.props.defaultLanguage}
                    />
                  </div>

                  <div styleName="relation-form__divider" />

                  {/* Social media*/}
                  <div styleName="relation-form">
                    <div className="form__row">
                      <label htmlFor="socialMedia" />
                      <Input.Social name="socialMedia" platforms={platforms} />
                    </div>
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="contactValuation">
                <div styleName="relation-form">
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="certified"
                          on={true}
                          off={false}
                          label="certification.certified"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <label htmlFor="certification.certification">
                      <I18n value="certification.certification" />
                    </label>
                    <Input.RadioGroup name="certification" asButtonList>
                      <Input.Radio
                        label="SCVM"
                        value={AppraisalCertification.Scvm}
                      />
                      <Input.Radio
                        label="VastGoedCert"
                        value={AppraisalCertification.VastGoedCert}
                      />
                    </Input.RadioGroup>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="city">
                          <I18n value="in" />
                        </label>
                        <Input.Text name="certificateCity" placeholder="city" />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="certificateDate">
                          <I18n value="on" />
                        </label>
                        <Input.Datepicker
                          name="certificateDate"
                          placeholder="date"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="certificateNumber">
                          <I18n value="certificateNumber" />
                        </label>
                        <Input.Text
                          name="certificateNumber"
                          placeholder="certificateNumberPlaceHolder"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>
      </div>
    );
  }

  public onFormSubmitHandler(formValues: FormReturnValue) {
    this.isSaving = true;
    const editedEmployee = this.getEmployeeFromFormValues(formValues);

    const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id: editedEmployee.id });
    this.props.saveEmployee(editedEmployee, path);
  }

  private getEmployeeFromFormValues(result: FormReturnValue): Employee {
    const employee: Employee = {
      ...this.props.selectedEmployee,
      gender: result.gender,
      title: result.title,
      nameLetters: result.nameLetters,
      middleName: result.middleName,
      phoneNumbers: result.phoneNumbers,
      emailAddresses: result.emailAddresses,
      address: {
        ...this.props.selectedEmployee.address,
        houseNumber: result.houseNumber,
        houseNumberPostfix: result.houseNumberPostfix,
        postalCode: result.postalCode,
        street: {
          ...this.props.selectedEmployee.address.street,
          name: result.streetname,
        },
        locality: {
          ...this.props.selectedEmployee.address.locality,
          name: result.localityName,
        },
      },
      postalAddress: {
        ...this.props.selectedEmployee.postalAddress,
        houseNumber: result.postalHouseNumber,
        houseNumberPostfix: result.postalHouseNumberPostfix,
        postalCode: result.postalPostalCode,
        street: {
          ...this.props.selectedEmployee.postalAddress.street,
          name: result.postalStreetname,
        },
        locality: {
          ...this.props.selectedEmployee.postalAddress.locality,
          name: result.postalLocalityName,
        },
      },
      firstName: result.firstName,
      nickname: result.nickname,
      lastName: result.lastName,
      dateOfBirth: result.dateOfBirth,
      cityOfBirth: result.cityOfBirth,
      countryIso2OfBirth: result.countryIso2OfBirth,
      civilState: result.civilState,
      identificationCard: {
        ...this.props.selectedEmployee.identificationCard,
        number: result.identificationCardNumber,
        type: result.identificationCardType,
        validUntilDate: result.identificationCardValidUntilDate,
      },
      spokenLanguages: result.spokenLanguages,
      socialSecurityNumber: result.socialSecurityNumber,
      bankAccount: {
        ...this.props.selectedEmployee.bankAccount,
        number: result.bankAccountNumber,
      },
      aboutMe: result.employeeDescription,
      socialMedia: result.socialMedia,
      certification: {
        ...this.props.selectedEmployee.certification,
        isActiveAppraiser: result.certified,
        certification: result.certification,
        locality: result.certificateCity,
        date: result.certificateDate,
        number: result.certificateNumber,
      },
    };

    employee.emailAddresses = employee.emailAddresses.map((emailAddress) => {
      if (!emailAddress.type) {
        emailAddress.type = EmailAddressType.Work;
      }
      return emailAddress;
    });

    employee.phoneNumbers = employee.phoneNumbers.map((phoneNumber) => {
      if (!phoneNumber.type) {
        phoneNumber.type = PhoneNumberType.Work;
      }
      return phoneNumber;
    });

    return employee;
  }

  private onChangeHandler(formReturnValue: FormReturnValue) {
    const editedEmployee = this.getEmployeeFromFormValues(formReturnValue);
    this.props.updateEmployee(editedEmployee, this.props.path);
  }

  private renderAddress(isPostalAddress: boolean) {
    return (
      <div styleName="address-block-container">
        <span styleName="title">
          <I18n value={isPostalAddress ? "postalAddress" : "visitAddress"} />
        </span>
        <div styleName="address-block">
          <div styleName="fields">
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="street">
                  <label htmlFor="streetname">
                    <I18n value="streetname" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalStreetname" : "streetname"}
                    placeholder="streetname"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="houseNumber">
                  <label htmlFor="houseNumber">
                    <I18n value="houseNumber" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalHouseNumber" : "houseNumber"}
                    placeholder="houseNumber"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="houseNumberPostfix">
                  <label htmlFor="houseNumberPostfix">
                    <I18n value="houseNumberPostfix" />
                  </label>
                  <Input.Text
                    name={
                      isPostalAddress
                        ? "postalHouseNumberPostfix"
                        : "houseNumberPostfix"
                    }
                    placeholder="houseNumberPostfix"
                  />
                </div>
              </div>
            </div>
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="postalcode">
                  <label htmlFor="postalCode">
                    <I18n value="postalCode" />
                  </label>
                  <Input.Text
                    name={isPostalAddress ? "postalPostalCode" : "postalCode"}
                    placeholder="postalCode"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="localityName">
                    <I18n value="locality" />
                  </label>
                  <Input.Text
                    name={
                      isPostalAddress ? "postalLocalityName" : "localityName"
                    }
                    placeholder="locality"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            styleName="deleteItem"
            type="button"
            onClick={() =>
              this.props.removeAddress(
                isPostalAddress,
                this.props.selectedEmployee.id
              )
            }
          >
            <i className="fal fa-minus-circle" />
          </button>
        </div>
      </div>
    );
  }

  private renderSearchAddressBar(isPostalAddress: boolean) {
    return (
      <div styleName="addressBar">
        <label styleName="title">
          <I18n value={isPostalAddress ? "postalAddress" : "visitAddress"} />
        </label>
        <div className="form__group">
          <div className="column" styleName="address-search-country">
            <Input.NewSelect
              name="country"
              values={this.props.countries}
              valuesProp="iso2CodeValue"
              filterProp="displayName"
              displayValueFn={this.renderCountryValue}
              displayFn={this.renderCountryOption}
              onChange={(value) => {
                if (isPostalAddress) {
                  this.setState({
                    postalCountryString: this.renderCountryString(value),
                    postalCountryCode: value,
                  });
                } else {
                  this.setState({
                    countryString: this.renderCountryString(value),
                    countryCode: value,
                  });
                }
              }}
            />
          </div>
          <div className="column__spacer" />
          <div className="column" styleName="address-search-query">
            <Input.QueryLegacy
              name={isPostalAddress ? "postalAddressSearch" : "addressSearch"}
              asyncValues={(value) =>
                AddressRequest.AddressRequest.addressSearch(
                  value,
                  isPostalAddress
                    ? this.state.postalCountry
                    : this.state.country
                )
              }
              placeholder="addressPlaceholder"
              displayPath="description"
              isLoading={
                this.props.employeeAddressSearchState === REQUEST.PENDING
              }
              noItemsFoundText="addressNotFoundAddManually"
              onAddManualOption={(value: string) => {
                this.onAddManualAddress(value, isPostalAddress);
              }}
              onChange={(value) => this.onAddressSearchChangeHandler(value)}
            />
          </div>
        </div>
      </div>
    );
  }

  private onAddressSearchChangeHandler(result: FormReturnValue) {
    if (result.country !== this.state.country) {
      this.setState({ country: result.country });
    }
    if (
      result.addressSearch &&
      (!this.state.addressResult ||
        this.state.addressResult.place_id !== result.addressSearch[0].place_id)
    ) {
      this.setState({
        country: result.country,
        addressResult: result.addressSearch,
      });
    }
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private renderCountryString(countryCode: string): string {
    return this.props.intl.formatMessage({
      id: `countryCode${countryCode}`,
      defaultMessage: countryCode,
    });
  }

  private onAddManualAddress(value: string, isPostalAddress: boolean) {
    this.props.addManualAddress(
      value,
      isPostalAddress,
      this.props.selectedEmployee.id
    );
  }

  private formatNameLetters(names: string) {
    let nameLetters = "";
    const splitNames = names.split(" ");
    for (let i = 0; i < splitNames.length; i++) {
      const name = splitNames[i];
      if (name) {
        nameLetters = nameLetters + name[0] + ".";
      }
    }
    return nameLetters;
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}
