import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import { TaskSnapShot, TaskStatus } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import { Fragment } from "react";

const styles = require("./task-item.component.scss");

interface GlobalSearchTaskItemComponentProps {
  onTaskClick: (id: string) => void;
  task: TaskSnapShot;
  query: string;
  active: boolean;
  list: HTMLDivElement;
}

interface GlobalSearchTaskItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class GlobalSearchTaskItemComponent extends React.Component<
  GlobalSearchTaskItemComponentProps,
  GlobalSearchTaskItemComponentState
> {
  private ref: HTMLDivElement;

  public render() {
    const { subject } = this.props.task;
    const taskStyle = classNames("task", { finished: this.props.active });
    const taskSectionStyle = classNames("section", {
      completed: this.props.task.status === TaskStatus.Completed,
    });

    if (this.props.active && this.props.list) this.setListPosition();

    return (
      <div
        styleName={taskStyle}
        onClick={() => this.props.onTaskClick(this.props.task.id)}
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="task__avatar">
          <i className="fal fa-fw fa-tasks" />
        </div>
        <div styleName="task__subject">
          <div
            styleName={taskSectionStyle}
            dangerouslySetInnerHTML={StringUtil.highlight(
              subject,
              this.props.query
            )}
          />
          {this.renderMetaData()}
        </div>
      </div>
    );
  }

  private setListPosition() {
    if (!this.ref) return;
    const { clientHeight, scrollTop } = this.props.list;
    const top = this.ref.offsetTop;
    const bottom = top + this.ref.clientHeight;

    if (bottom > clientHeight + scrollTop || top < scrollTop) {
      this.props.list.scrollTop =
        top + this.ref.clientHeight / 2 - clientHeight / 2;
    }
  }

  private renderMetaData(): React.ReactElement<HTMLDivElement> {
    const { linkedEmployee } = this.props.task;
    if (!linkedEmployee) return null;

    return (
      <Fragment>
        <div styleName="section extra">
          <span className="fal fa-user" />
          <span>{linkedEmployee.displayName}</span>
        </div>
      </Fragment>
    );
  }
}
