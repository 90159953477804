import {
  RealEstatePropertySearchItem,
  StatusType,
  RealEstateAssociationType,
  PriceUnitType,
  PriceCodeType,
  Group,
} from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import Pill from "@haywork/components/ui/pill";
import { Column, Row } from "@haywork/components/ui/list";
import { MLSROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import head from "lodash-es/head";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber, useIntl } from "react-intl";
import LoadingState from "../loading-state";
import Preview from "../preview";
import KeyValueRow, { ColumnRowProps } from "../key-value-row";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

const nvmLogo = require("static/logo-nvm_primary.png");
const vboLogo = require("static/logo-vbo_primary.png");
const vgpLogo = require("static/logo-vgp_primary.png");

type Props = {
  data: any | null;
  zebra: boolean;
  selectedIds: string[];
  agencyGroups: Group[];
  setRealtorInfoModal: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      data: any;
    }>
  >;
  setPriceHistoryModal: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      data: any;
    }>
  >;
  setPublicationsHistoryModal: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      data: any;
    }>
  >;
  onOpenDetail: (path: string) => void;
  onToggleSelected: (id: string) => void;
};

export const RowComponent: FC<Props> = memo(
  ({
    data,
    zebra,
    selectedIds,
    agencyGroups,
    setRealtorInfoModal,
    setPriceHistoryModal,
    setPublicationsHistoryModal,
    onOpenDetail,
    onToggleSelected,
  }) => {
    return !data ? (
      <LoadingState zebra={zebra} />
    ) : (
      <DataRowComponent
        data={data}
        zebra={zebra}
        selectedIds={selectedIds}
        agencyGroups={agencyGroups}
        setRealtorInfoModal={setRealtorInfoModal}
        setPriceHistoryModal={setPriceHistoryModal}
        setPublicationsHistoryModal={setPublicationsHistoryModal}
        onOpenDetail={onOpenDetail}
        onToggleSelected={onToggleSelected}
      />
    );
  }
);

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      data,
      zebra,
      selectedIds,
      agencyGroups,
      setRealtorInfoModal,
      setPriceHistoryModal,
      setPublicationsHistoryModal,
      onOpenDetail,
      onToggleSelected,
    }) => {
      const {
        id,
        imageUrls,
        appClientKeys,
        address,
        mutations,
        counts,
        agency,
        financial,
        propertyInfo,
        differentRegistrations,
        type: propertyType,
        construction,
        climateControl,
        areaTotals,
        facilities,
        surroundings,
        outside,
        garage,
        evaluation,
        transaction,
        metaData,
      } = data as RealEstatePropertySearchItem;
      const intl = useIntl();

      const addressLine1 = useMemo(() => {
        const houseNumber = address?.houseNumber;
        const houseNumberPostfix = address?.houseNumberAddendum;
        const streetname = address?.street?.name;
        const fullHouseNumber = [houseNumber, houseNumberPostfix]
          .filter((d) => ![null, undefined].includes(d))
          .join("-");

        return [streetname, fullHouseNumber]
          .filter((d) => ![null, undefined].includes(d))
          .join(" ");
      }, [address]);

      const addressLine2 = useMemo(() => {
        const postalCode = address?.postalCode;
        const cityName = address?.locality?.name;

        return [postalCode, cityName].filter((d) => !!d).join(" ");
      }, [address]);

      const mainImage = useMemo(() => {
        return (imageUrls || [])[0];
      }, [imageUrls]);

      const secondaryImage = useMemo(() => {
        return (imageUrls || [])[1];
      }, [imageUrls]);

      const tertiaryImage = useMemo(() => {
        return (imageUrls || [])[2];
      }, [imageUrls]);

      const bulkSelected = useMemo(() => {
        return selectedIds.includes(id);
      }, [selectedIds, id]);

      const daysOnMarket = useMemo(() => {
        let diffInDays: number;

        switch (propertyInfo.availability) {
          case StatusType.AVAILABLE: {
            const ref = moment(propertyInfo?.mandateDate);
            if (!ref || !ref.isValid()) {
              diffInDays = 0;
              break;
            }
            diffInDays = moment()
              .startOf("day")
              .diff(ref.startOf("day"), "days");
            break;
          }
          case StatusType.SOLD:
          case StatusType.SOLD_UNDER_CONDITIONS:
          case StatusType.RENTED_UNDER_CONDITIONS:
          case StatusType.RENTED:
          case StatusType.WITHDRAWN: {
            const ref = moment(transaction?.transactionDateTime);
            const mandateDate = moment(propertyInfo?.mandateDate);
            if (
              !ref ||
              !ref.isValid() ||
              !mandateDate ||
              !mandateDate.isValid()
            ) {
              diffInDays = 0;
              break;
            }
            diffInDays = ref
              .startOf("day")
              .diff(mandateDate.startOf("day"), "days");
            break;
          }
          default:
            break;
        }

        return diffInDays;
      }, [propertyInfo, transaction]);

      const columnGarageProps = useMemo(() => {
        const props = [] as ColumnRowProps[];
        if (!!(garage?.types || []).length) {
          props.push({
            label: "mls.list.garageTypes",
            value: (garage.types || []).map((gType) => gType.toString()),
            type: "array",
            prefix: "garageTypeOptions",
          });
        }
        if (garage?.capacity) {
          props.push({
            label: "mls.list.garageCapacity",
            value: garage.capacity,
            type: "number",
          });
        }
        return props;
      }, [garage]);

      const columnOutsideProps = useMemo(() => {
        const props = [] as ColumnRowProps[];
        if (!!(surroundings?.locations || []).length) {
          props.push({
            label: "mls.list.locations",
            value: (surroundings?.locations || []).map((location) =>
              location.toString()
            ),
            type: "array",
            prefix: "locationPlaceTypeOptions",
          });
        }
        if (!!(outside?.gardenTypes || []).length) {
          props.push({
            label: "mls.list.gardenTypes",
            value: (outside?.gardenTypes || []).map((gardenType) =>
              gardenType.toString()
            ),
            type: "array",
            prefix: "gardenTypeOptions",
          });
        }
        if (!!(outside?.gardenOrientations || []).length) {
          props.push({
            label: "mls.list.gardenOrientations",
            value: (outside?.gardenOrientations || []).map(
              (gardenOrientation) => gardenOrientation.toString()
            ),
            type: "array",
            prefix: "orientationTypeOptions",
          });
        }
        if (outside?.totalBackyardGardenArea) {
          props.push({
            label: "mls.list.totalBackyardGardenArea",
            value: outside?.totalBackyardGardenArea,
            type: "squaremeters",
          });
        }
        if (outside?.totalGardenArea) {
          props.push({
            label: "mls.list.totalGardenArea",
            value: outside?.totalGardenArea,
            type: "squaremeters",
          });
        }
        if (!!outside?.hasBackyardEntrance) {
          props.push({
            label: "mls.list.hasBackyardEntrance",
            value: !outside?.hasBackyardEntrance ? "no" : "yes",
            type: "string",
          });
        }
        return props;
      }, [surroundings, outside]);

      const columnEnergyProps = useMemo(() => {
        const props = [] as ColumnRowProps[];

        if (climateControl?.energyClass) {
          props.push({
            label: "mls.list.energyClass",
            value: climateControl?.energyClass.toString(),
            type: "string",
            prefix: "energyClassTypeOptions",
          });
        }
        if (!!(construction?.isolationTypes || []).length) {
          props.push({
            label: "mls.list.isolationTypes",
            value: (construction?.isolationTypes || []).map((isolationType) =>
              isolationType.toString()
            ),
            type: "array",
            prefix: "isolationTypeOptions",
          });
        }
        if (!!(climateControl?.heatingMethods || []).length) {
          props.push({
            label: "mls.list.heatingMethods",
            value: (climateControl?.heatingMethods || []).map((heatingMethod) =>
              heatingMethod.toString()
            ),
            type: "array",
            prefix: "heatingMethodTypeOptions",
          });
        }
        if (!!(climateControl?.heatingWaterMethods || []).length) {
          props.push({
            label: "mls.list.heatingWaterMethods",
            value: (climateControl?.heatingWaterMethods || []).map(
              (heatingWaterMethod) => heatingWaterMethod.toString()
            ),
            type: "array",
            prefix: "heatingMethodWaterTypeOptions",
          });
        }
        if (climateControl?.typeOfBoiler)
          props.push({
            label: "mls.list.typeOfBoiler",
            value: climateControl?.typeOfBoiler,
            type: "string",
          });

        return props;
      }, [climateControl, construction]);

      const columnLayersProps = useMemo(() => {
        const props = [] as ColumnRowProps[];
        if (counts?.numberOfRooms) {
          props.push({
            label: "mls.list.numberOfRooms",
            value: counts?.numberOfRooms,
            type: "number",
          });
        }
        if (counts?.numberOfBedrooms) {
          props.push({
            label: "mls.list.numberOfBedrooms",
            value: counts?.numberOfBedrooms,
            type: "number",
          });
        }
        if (counts?.numberOfBathrooms) {
          props.push({
            label: "mls.list.numberOfBathrooms",
            value: counts?.numberOfBathrooms,
            type: "number",
          });
        }
        if (!!(facilities?.bathroomFacilities || []).length) {
          props.push({
            label: "mls.list.bathroomFacilities",
            value: (facilities?.bathroomFacilities || []).map((facility) =>
              facility.toString()
            ),
            type: "array",
            prefix: "bathroomFacilityTypeOptions",
          });
        }
        if (counts?.numberOfFloors) {
          props.push({
            label: "mls.list.numberOfFloors",
            value: counts?.numberOfFloors,
            type: "number",
          });
        }
        if (!!(facilities?.remainingFacilities || []).length) {
          props.push({
            label: "mls.list.remainingFacilities",
            value: (facilities?.remainingFacilities || []).map((facility) =>
              facility.toString()
            ),
            type: "array",
            prefix: "remainingFacilityTypeOptions",
          });
        }
        return props;
      }, [facilities, counts]);

      const columnConstructionProps = useMemo(() => {
        const props = [] as ColumnRowProps[];
        if (!!(propertyType?.types || []).length) {
          props.push({
            label: "mls.list.propertyTypes",
            value: (propertyType?.types || []).map((pType) => pType.toString()),
            type: "array",
            prefix: "propertyTypeOptions",
          });
        }
        if (
          construction?.constructionYearTo ||
          construction?.constructionYearFrom
        ) {
          props.push({
            label: "mls.list.constructionPeriod",
            value: !construction?.constructionYearTo
              ? "mls.list.constructionPeriodFrom"
              : !construction?.constructionYearFrom
              ? "mls.list.constructionPeriodTo"
              : construction?.constructionYearFrom ===
                construction?.constructionYearTo
              ? "mls.list.constructionPeriodFrom"
              : "mls.list.constructionPeriodFromTo",
            values: {
              to: construction?.constructionYearTo,
              from: construction?.constructionYearFrom,
            },
            type: "string",
          });
        }
        if (evaluation?.isAccessibleForSeniors) {
          props.push({
            label: "mls.list.other",
            value: "mls.list.isAccessibleForSeniors",
            type: "string",
          });
        }
        return props;
      }, [construction, propertyType, evaluation]);

      const columnMeasurementsProps = useMemo(() => {
        const props = [] as ColumnRowProps[];
        if (!!areaTotals?.effectiveArea)
          props.push({
            label: "mls.list.effectiveArea",
            value: areaTotals?.effectiveArea,
            type: "squaremeters",
          });
        if (!!areaTotals?.landArea)
          props.push({
            label: "mls.list.landArea",
            value: areaTotals?.landArea,
            type: "squaremeters",
          });
        if (!!areaTotals?.storageAreaExternal)
          props.push({
            label: "mls.list.outsideArea",
            value: areaTotals?.storageAreaExternal,
            type: "squaremeters",
          });
        if (!!areaTotals?.content)
          props.push({
            label: "mls.list.content",
            value: areaTotals?.content,
            type: "cubicmeters",
          });
        if (!!areaTotals?.livingRoom)
          props.push({
            label: "mls.list.livingRoom",
            value: areaTotals?.livingRoom,
            type: "squaremeters",
          });
        if (
          !!areaTotals?.availableInUnitsFrom &&
          areaTotals?.availableInUnitsFrom !== areaTotals?.floorArea
        )
          props.push({
            label: "mls.list.availableInUnitsFrom",
            value: areaTotals?.availableInUnitsFrom,
            type: "squaremeters",
          });
        if (!!areaTotals?.floorArea)
          props.push({
            label: "mls.list.floorArea",
            value: areaTotals?.floorArea,
            type: "squaremeters",
          });

        return props;
      }, [areaTotals]);

      const columnObjectDataProps = useMemo(() => {
        const props = [] as ColumnRowProps[];

        if (
          [StatusType.RENTED, StatusType.SOLD].includes(
            propertyInfo?.availability
          ) &&
          transaction?.contractDateTime
        ) {
          props.push({
            label: "mls.list.contractDateTime",
            value: transaction?.contractDateTime,
            type: "date",
          });
        }
        if (propertyInfo?.mandateDate) {
          props.push({
            label: "mls.list.mandateDate",
            value: propertyInfo?.mandateDate,
            type: "date",
          });
        }
        if (transaction?.transactionDateTime) {
          props.push({
            label:
              propertyInfo?.availability === StatusType.WITHDRAWN
                ? "mls.list.withdrawelDateTime"
                : "mls.list.transactionDateTime",
            value: transaction?.transactionDateTime,
            type: "date",
          });
        }
        if (
          [
            StatusType.RENTED_UNDER_CONDITIONS,
            StatusType.SOLD_UNDER_CONDITIONS,
          ].includes(propertyInfo?.availability) &&
          transaction?.waivingOfTerminationTo
        ) {
          props.push({
            label: "mls.list.waivingOfTerminationTo",
            value: transaction?.waivingOfTerminationTo,
            type: "date",
          });
        }
        if (propertyInfo?.modificationDateTime) {
          props.push({
            label: "mls.list.modificationDateTime",
            value: propertyInfo.modificationDateTime,
            type: "date",
          });
        }
        if (
          ![undefined, null, NaN].includes(daysOnMarket) &&
          daysOnMarket > 1
        ) {
          props.push({
            label: "mls.list.daysOnMarket",
            value: daysOnMarket,
            type: "number",
          });
        }
        return props;
      }, [propertyInfo, transaction, daysOnMarket, differentRegistrations]);

      const filteredMutations = useMemo(() => {
        return (mutations || [])
          .map((mutation) => {
            if (mutation.oldPrice === 0 || !mutation.oldPrice) return;
            return mutation;
          })
          .filter((mutation) => !!mutation);
      }, [mutations]);

      const onToggleSelectForBulk = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();
          event.stopPropagation();
          onToggleSelected(id);
        },
        [onToggleSelected, id]
      );

      const onClickCallback = useCallback(() => {
        const source = head(appClientKeys);
        const path = route(MLSROUTES.DETAIL.URI, { id, source });
        onOpenDetail(path);
      }, [id, onOpenDetail, appClientKeys]);

      const onModalClick = useCallback(
        (
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          type: string,
          data: any
        ) => {
          e.preventDefault();
          e.stopPropagation();
          switch (type) {
            case "realtorInfo":
              setRealtorInfoModal({ isVisible: true, data });
              break;
            case "priceHistory":
              setPriceHistoryModal({ isVisible: true, data });
              break;
            case "publicationsHistory":
              setPublicationsHistoryModal({ isVisible: true, data });
              break;
            default:
              break;
          }
        },
        [setRealtorInfoModal, setPriceHistoryModal, setPublicationsHistoryModal]
      );

      const status = useMemo(() => {
        if (!propertyInfo?.availability) return null;

        let color = Colors.Warning;
        switch (propertyInfo?.availability) {
          case StatusType.AVAILABLE: {
            color = Colors.Success;
            break;
          }
          case StatusType.WITHDRAWN: {
            color = Colors.Danger;
            break;
          }
          default: {
            color = Colors.Warning;
            break;
          }
        }

        return (
          <div styleName="column__row">
            <div style={{ color, fontWeight: 600 }}>
              <I18n
                value={propertyInfo?.availability.toString()}
                prefix="statusTypeOptions"
              />
            </div>
          </div>
        );
      }, [propertyInfo?.availability]);

      const agencyBlock = useMemo(() => {
        if (!agency?.name) return null;
        const { realEstateAssociation, name } = agency;

        let image: string;
        switch (realEstateAssociation) {
          case RealEstateAssociationType.NL_NVM: {
            image = nvmLogo;
            break;
          }
          case RealEstateAssociationType.NL_VBO: {
            image = vboLogo;
            break;
          }
          case RealEstateAssociationType.NL_VASTGOED_PRO: {
            image = vgpLogo;
            break;
          }
          default:
            break;
        }

        return (
          <div styleName="column__row">
            <div styleName="realtor">
              {!!image && (
                <div styleName="realtor__image">
                  <img src={image} />
                </div>
              )}
              <span
                className="as-link"
                onClick={(e) => onModalClick(e, "realtorInfo", agency)}
              >
                <span>{name}</span>
              </span>
            </div>
          </div>
        );
      }, [agency]);

      const perUnitType = useCallback(
        (priceUnitType: PriceUnitType) => {
          const id = !priceUnitType
            ? `priceUnitTypeOptions.${PriceUnitType.PER_M2.toString()}`
            : `priceUnitTypeOptions.${priceUnitType.toString()}`;
          const defaultMessage = "m<sup>2</sup>";

          return intl.formatMessage({ id, defaultMessage });
        },
        [intl]
      );

      const rentPricePerUnit = useMemo(() => {
        if (!financial?.rentPricePerUnit) return null;

        const label = !financial?.rentPricePerUnitType ? (
          <I18n value="mls.list.rentPricePerUnit" />
        ) : (
          <I18n
            value="mls.list.rentPricePerUnitType"
            values={{
              unit: perUnitType(financial?.rentPricePerUnitType),
            }}
          />
        );

        return (
          <div styleName="column__row">
            <div styleName="label">{label}</div>
            <div styleName="value">
              <FormattedNumber
                value={financial?.rentPricePerUnit || 0}
                style="currency"
                currency="EUR"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </div>
          </div>
        );
      }, [financial]);

      const purchasePricePerUnit = useMemo(() => {
        if (!financial?.purchasePricePerUnit) return null;

        const label = !financial?.purchasePricePerUnitType ? (
          <I18n value="mls.list.purchasePricePerUnit" />
        ) : (
          <I18n
            value="mls.list.purchasePricePerUnitType"
            values={{
              unit: perUnitType(financial?.purchasePricePerUnitType),
            }}
          />
        );

        return (
          <div styleName="column__row">
            <div styleName="label">{label}</div>
            <div styleName="value">
              <FormattedNumber
                value={financial?.purchasePricePerUnit || 0}
                style="currency"
                currency="EUR"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </div>
          </div>
        );
      }, [financial]);

      const purchaseConditionType = useMemo(() => {
        if (!financial?.purchaseConditionType) return null;
        const defaultMessage = financial?.purchaseConditionType.toString();
        const id = `purchaseConditionTypeOptions.${defaultMessage}`;
        return intl.formatMessage({ id, defaultMessage }).toLowerCase();
      }, [financial, intl]);

      const pricePerSquareMeter = useMemo(() => {
        if (
          !financial?.pricePerSquareMeter ||
          !!rentPricePerUnit ||
          !!purchasePricePerUnit
        )
          return null;

        return (
          <div styleName="column__row">
            <div styleName="label">
              <I18n value="mls.list.pricePerSquareMeter" asHtml />
            </div>
            <div styleName="value">
              <FormattedNumber
                value={financial?.pricePerSquareMeter || 0}
                style="currency"
                currency="EUR"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </div>
          </div>
        );
      }, [financial, rentPricePerUnit, purchasePricePerUnit]);

      const country = useMemo(() => address?.country?.name, [address]);

      const mlsGroupLabel = useMemo(() => {
        if (!propertyInfo || !metaData) {
          return;
        }

        const groups = (metaData?.groupNames || []).map(
          (name) =>
            agencyGroups.find(
              (group) =>
                group.name && group.name.toLowerCase() === name.toLowerCase()
            )?.name || name
        );

        // const groups = metaData?.groupNames || [];

        return moment(metaData?.releaseDate).isValid() &&
          moment(metaData.releaseDate).utc().isAfter(moment().utc()) &&
          groups.length > 0 ? (
          <Pill
            label="mls.label.exclusive"
            labelValues={{ name: head(groups) }}
            color="#c91e24"
            textColor={Colors.White}
            tiny
            solid
            disableHint
            fullWidth
          />
        ) : null;
      }, [propertyInfo, metaData, agencyGroups]);

      return (
        <Row zebra={zebra} onClick={onClickCallback}>
          <Column index={0} divide>
            <div styleName="bulk-select">
              <div styleName="trigger" onClick={onToggleSelectForBulk}>
                <Icon
                  name={bulkSelected ? "check-square" : "square"}
                  color={bulkSelected ? Colors.Primary : Colors.MediumGray}
                  light={!bulkSelected}
                  solid={bulkSelected}
                  size={16}
                />
              </div>
            </div>
          </Column>
          <Column index={1} divide>
            <div styleName="column">
              <Preview image={mainImage} />
              <div styleName="secondary-previews">
                <Preview image={secondaryImage} small />
                <div styleName="spacer" />
                <Preview image={tertiaryImage} small />
              </div>
            </div>
          </Column>
          <Column index={2} divide>
            <div styleName="column">
              <div styleName="column__row location">
                <div>
                  {mlsGroupLabel}
                  {propertyInfo?.isConfidential && (
                    <Pill
                      label="mls.label.confidential"
                      color={Colors.Primary}
                      textColor={Colors.White}
                      tiny
                      solid
                      disableHint
                      fullWidth
                    />
                  )}
                </div>

                {!!addressLine1 && <div>{addressLine1}</div>}
                {!!addressLine2 && <div>{addressLine2}</div>}
                {!!country && <div styleName="country">{country}</div>}
              </div>
              {agencyBlock}
            </div>
          </Column>
          <Column index={3} divide>
            <div styleName="column">
              {!!financial?.rentPrice && (
                <div styleName="column__row">
                  <div styleName="label">
                    <I18n value="mls.list.rentPrice" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={financial?.rentPrice || 0}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </div>
                </div>
              )}

              {!!financial?.purchasePrice && (
                <div styleName="column__row">
                  <div styleName="label">
                    <I18n value="mls.list.askingPrice" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={financial?.purchasePrice || 0}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                    {!!purchaseConditionType && <> {purchaseConditionType}</>}
                  </div>
                </div>
              )}

              {!!financial?.transactionPrice && (
                <div styleName="column__row">
                  <div styleName="label">
                    <I18n value="mls.list.transactionPrice" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={financial?.transactionPrice || 0}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </div>
                </div>
              )}

              {pricePerSquareMeter}
              {rentPricePerUnit}
              {purchasePricePerUnit}
              {status}

              {!!propertyInfo?.substatus && (
                <div styleName="column__row">
                  <I18n
                    value={propertyInfo?.substatus.toString()}
                    prefix="subStatusTypeOptions"
                  />
                </div>
              )}

              {!!financial?.priceCodeType &&
                financial?.priceCodeType !== PriceCodeType.ASKING_PRICE && (
                  <div styleName="column__row">
                    <I18n
                      value={financial?.priceCodeType.toString()}
                      prefix="priceCodeTypeOptions"
                    />
                  </div>
                )}

              {!!(filteredMutations || []).length && (
                <div styleName="column__row">
                  <div styleName="inline-icon">
                    <Icon
                      name="chart-line"
                      size={14}
                      color={Colors.Primary}
                      regular
                    />
                  </div>
                  <span
                    className="as-link"
                    onClick={(e) =>
                      onModalClick(e, "priceHistory", filteredMutations)
                    }
                  >
                    <I18n
                      value="mls.prices.changes"
                      values={{
                        count: (filteredMutations || []).length,
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
          </Column>
          <Column index={4} divide>
            <div styleName="column">
              {!columnObjectDataProps.length &&
              !(differentRegistrations || []).length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnObjectDataProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
              {!!(differentRegistrations || []).length && (
                <div styleName="column__row">
                  <div styleName="inline-icon">
                    <Icon
                      name="code-branch"
                      size={14}
                      color={Colors.Primary}
                      regular
                    />
                  </div>
                  <span
                    className="as-link"
                    onClick={(e) =>
                      onModalClick(
                        e,
                        "publicationsHistory",
                        differentRegistrations
                      )
                    }
                  >
                    <I18n
                      value="mls.dates.publications"
                      values={{
                        count: (differentRegistrations || []).length,
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
          </Column>
          <Column index={5} divide>
            <div styleName="column">
              {!columnMeasurementsProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnMeasurementsProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
          <Column index={6} divide>
            <div styleName="column">
              {!columnConstructionProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnConstructionProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
          <Column index={7} divide>
            <div styleName="column">
              {!columnLayersProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnLayersProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
          <Column index={8} divide>
            <div styleName="column">
              {!columnEnergyProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnEnergyProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
          <Column index={9} divide>
            <div styleName="column">
              {!columnOutsideProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnOutsideProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
          <Column index={10} divide>
            <div styleName="column">
              {!columnGarageProps.length ? (
                <div styleName="column__row">
                  <I18n value="mls.list.column.empty" />
                </div>
              ) : (
                columnGarageProps.map((item, idx) => (
                  <KeyValueRow key={idx} label={item.label} item={item} />
                ))
              )}
            </div>
          </Column>
        </Row>
      );
    }
  )
);
