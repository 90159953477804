import { actions as FoldersActions } from "./actions";
import { reducer as foldersReducer, State } from "./reducer";
import { Folder } from "@haywork/api/mail";

export type EmailFolder = Folder & {
  accountId: string;
  unreadCount: number;
  position: number;
  totalResults?: number;
  selectedMessage?: string;
  internallyCreatedOn?: Date;
};
export type FoldersState = State;
export { foldersReducer, FoldersActions };
