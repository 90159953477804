import { intlContext } from "@haywork/app";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { NumberUtil, StringUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import { TimeListItemComponent } from "./time-item.component";

const leading = NumberUtil.addLeadingZeros;

interface TimeInputComponentProps {
  placeholder?: string;
  minuteInterval?: number;
  persistValue?: boolean;
}
interface TimeInputComponentState {
  expanded: boolean;
  values: string[];
  value: string;
}

export class TimeComponent extends React.Component<
  TimeInputComponentProps & InputComponentProps,
  TimeInputComponentState
> {
  private ref: HTMLDivElement;
  private list: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      values: this.mapValues(),
      value: this.props.value || "",
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onItemClickHandler = this.onItemClickHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const textInputStyle = classNames("input__time", {
      expanded: this.state.expanded,
    });

    return (
      <div
        className={textInputStyle}
        ref={(ref) => (this.ref = ref)}
        id={this.props.name}
      >
        <input
          name={this.props.name}
          id={this.props.name}
          type="text"
          onFocus={this.onFocusHandler}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          disabled={this.props.disabled}
          value={this.state.value}
          data-cy={this.props["data-cy"]}
          placeholder={placeholder}
          data-lpignore="true"
        />
        <div className="select__options" ref={(ref) => (this.list = ref)}>
          {this.state.values.map((value, idx) => (
            <ErrorBoundary key={idx}>
              <TimeListItemComponent
                value={value}
                selectedValue={this.props.value}
                list={this.list}
                onItemClick={this.onItemClickHandler}
              />
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TimeInputComponentProps & InputComponentProps
  ) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  private onFocusHandler() {
    if (!!this.props.onFocus) this.props.onFocus();
    this.setState({ expanded: true });
  }

  private onBlurHandler() {
    if (!this.state.value) {
      this.props.onChange(this.props.persistValue ? this.props.value : "");
      return;
    }

    const valid = /^(\d?\d?)([:.]\d\d)?(\d?\d?)?$/g.test(this.state.value);
    if (!valid) {
      this.setState({ value: this.props.value });
      return;
    }

    if (this.state.value === this.props.value) {
      return;
    }

    let value = this.props.value;
    const hasColonOrDot = /[:.]/g.test(this.state.value);
    const hasColon = /:/g.test(this.state.value);
    if (hasColonOrDot) {
      const digits = this.state.value.split(hasColon ? ":" : ".");
      const hours = digits[0];
      const minutes = digits[1];

      if (parseInt(hours) < 24 && parseInt(minutes) < 60) {
        value = `${leading(hours)}:${leading(minutes)}`;
      }
    } else {
      const length = this.state.value.length;
      switch (length) {
        case 1:
          value = `0${this.state.value}:00`;
          break;
        case 2:
          if (parseInt(this.state.value) < 24) {
            value = `${this.state.value}:00`;
          }
          break;
        case 3: {
          const hours = this.state.value.slice(0, 1);
          const minutes = this.state.value.slice(1);
          if (parseInt(minutes) < 60) {
            value = `0${hours}:${minutes}`;
          }
          break;
        }
        case 4: {
          const hours = this.state.value.slice(0, 2);
          const minutes = this.state.value.slice(2);
          if (parseInt(hours) < 24 && parseInt(minutes) < 60) {
            value = `${hours}:${minutes}`;
          }
          break;
        }
        default:
          break;
      }
    }

    this.setState({
      value,
    });

    this.props.onChange(value);
  }

  private onItemClickHandler(value: string) {
    this.props.onChange(value);
    this.setState({ expanded: false, value });
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.setState({ value });
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref === undefined) return;
    if (!this.ref.contains(event.target) && !!this.state.expanded)
      this.setState({ expanded: false });
  }

  private mapValues(): string[] {
    const values = [];

    for (let i = 0; i <= 23; i++) {
      if (this.props.minuteInterval) {
        const sequences = 60 / this.props.minuteInterval;
        let minute = 0;

        for (let j = 0; j < sequences; j++) {
          values.push(
            `${StringUtil.prependZero(i)}:${StringUtil.prependZero(minute)}`
          );
          minute = minute + this.props.minuteInterval;
        }
      } else {
        values.push(`${StringUtil.prependZero(i)}:00`);
        values.push(`${StringUtil.prependZero(i)}:30`);
      }
    }

    return values;
  }
}
