import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./feature-not-ready.component.scss");

interface FeatureNotReadyComponentProps {
  content?: string;
  contentValues?: { [key: string]: any };
  type?: string;
}
interface FeatureNotReadyComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class FeatureNotReadyComponent extends React.Component<
  FeatureNotReadyComponentProps,
  FeatureNotReadyComponentState
> {
  public render() {
    const key = this.props.content || "featureNotAvailable";
    const values = this.props.contentValues || {};
    const featureStyle = classNames("feature-not-ready", this.props.type);

    return (
      <div styleName={featureStyle}>
        <div styleName="inner">
          <div styleName="image" />
          <div styleName="content">
            <h1 styleName="empty-state__title">
              <ResourceText resourceKey={key} values={values} />
            </h1>
          </div>
        </div>
      </div>
    );
  }
}
