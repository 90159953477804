import { Input } from "@haywork/modules/form";
import first from "lodash-es/first";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { BaseFilter } from "../base/base-filter.component";

const styles = require("./select.component.scss");

export interface MappedSelectValue {
  label: string;
  value: any;
}

interface SelectFilterComponentProps {
  values: MappedSelectValue[];
  value?: MappedSelectValue[];
}
interface SelectFilterComponentState {
  current: any;
}

@CSSModules(styles, { allowMultiple: true })
export class SelectFilter extends BaseFilter<
  SelectFilterComponentProps,
  SelectFilterComponentState
> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onSelectChangeHandler = this.onSelectChangeHandler.bind(this);

    const current = this.props.value || first(this.props.values);

    this.state = {
      values: this.props.values,
      visible: false,
      current,
    };
  }

  public render() {
    return (
      <div styleName="select-filter">
        <Input.NewSelect
          name="select"
          values={this.props.values}
          value={this.state.current}
          displayProp="label"
          valuesProp="value"
          onChange={this.onSelectChangeHandler}
        />
      </div>
    );
  }

  public onSelectChangeHandler(value: any) {
    if (this.state.current === value) return;
    this.setState({ visible: false, current: value });
    this.context.updateField(this.props.name, value);
  }
}
