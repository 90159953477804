import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  HouseSortOption,
  HouseTypeOption,
  HouseCharacteristicOption,
  ApartmentSortOption,
  ApartmentCharacteristicOption,
  ParkingTypeOption,
  SearchAssignment,
  TypePARTOption,
  TypePART,
  SearchAssignmentPeculiarityOption,
  TypePARTOtherOption
} from "@haywork/api/kolibri";
import { FormControls, Input, Form, FormReturnValue } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("../edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface SearchAssignmentWhatResidentialComponentProps {
  searchAssignment: SearchAssignment;
  typePARTOptions: TypePARTOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  parkingTypeOptions: ParkingTypeOption[];
  typePARTOtherOptions: TypePARTOtherOption[];
  searchAssignmentPeculiarityOptions: SearchAssignmentPeculiarityOption[];
  onChange: (values: FormReturnValue) => void;
}
interface SearchAssignmentWhatResidentialComponentState {
  showHouse: boolean;
  showApartment: boolean;
  showParking: boolean;
  showResidentialSubType: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWhatResidentialComponent extends React.Component<
  SearchAssignmentWhatResidentialComponentProps,
  SearchAssignmentWhatResidentialComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.state = this.getState(value(this.props.searchAssignment, "typesPART", []));

    this.formControls = {
      typesPART: {
        value: value(this.props.searchAssignment, "typesPART", []),
        onChange: (ref) => {
          const state = this.getState(ref.value);
          this.setState(state);
        }
      },
      houseSorts: { value: value(this.props.searchAssignment, "houseSorts", []) },
      houseTypes: { value: value(this.props.searchAssignment, "houseTypes", []) },
      buildingCharacteristics: { value: value(this.props.searchAssignment, "buildingCharacteristics", []) },
      apartmentTypes: { value: value(this.props.searchAssignment, "apartmentTypes", []) },
      apartmentCharacteristics: { value: value(this.props.searchAssignment, "apartmentCharacteristics", []) },
      typeParking: { value: value(this.props.searchAssignment, "typeParking", []) },
      typesPARTOther: { value: value(this.props.searchAssignment, "typesPARTOther", []) },
      peculiarities: { value: value(this.props.searchAssignment, "peculiarities", []) }
    };
  }

  public render() {
    return (
      <Form name="what-residential" formControls={this.formControls} onChange={this.props.onChange}>
        <div className="form__row" styleName="inline-value">
          <label htmlFor="typesPART" />
          <Input.Multi name="typesPART" values={this.props.typePARTOptions} />
        </div>

        {/* Type headers
      <div styleName="type-headers">
        <div styleName="header">
          <h2>
            <ResourceText resourceKey="sort" />
          </h2>
        </div>
        <div styleName="header">
          {(this.state.showHouse || this.state.showApartment) &&
            <h2>
              <ResourceText resourceKey="type" />
            </h2>
          }
        </div>
        <div styleName="header">
          {this.state.showHouse &&
            <h2>
              <ResourceText resourceKey="characteristic" />
            </h2>
          }
        </div>
      </div>
      */}

        {/* House types row */}
        {this.state.showHouse && (
          <div styleName="type-row" className="flex-start">
            <div styleName="label" className="pre">
              <label htmlFor="houseSorts">
                <ResourceText resourceKey="house" />
              </label>
            </div>
            <div styleName="column">
              <Input.QueryLegacy
                name="houseSorts"
                values={this.props.houseSorts}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="houseSorts"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column">
              <Input.QueryLegacy
                name="houseTypes"
                values={this.props.houseTypes}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="houseTypes"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column">
              <Input.QueryLegacy
                name="buildingCharacteristics"
                values={this.props.houseCharacteristics}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="buildingCharacteristics"
                asSelect
                multiple
              />
            </div>
          </div>
        )}

        {/* Apartment types row */}
        {this.state.showApartment && (
          <div styleName="type-row" className="flex-start">
            <div styleName="label" className="pre">
              <label htmlFor="apartmentTypes">
                <ResourceText resourceKey="apartment" />
              </label>
            </div>
            <div styleName="column">
              <Input.QueryLegacy
                name="apartmentTypes"
                values={this.props.apartmentSorts}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="apartmentTypes"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column">
              <Input.QueryLegacy
                name="apartmentCharacteristics"
                values={this.props.apartmentCharacteristics}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="apartmentCharacteristics"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column" />
          </div>
        )}

        {/* Parking types row */}
        {this.state.showParking && (
          <div styleName="type-row" className="flex-start">
            <div styleName="label" className="pre">
              <label htmlFor="typeParking">
                <ResourceText resourceKey="parking" />
              </label>
            </div>
            <div styleName="column">
              <Input.QueryLegacy
                name="typeParking"
                values={this.props.parkingTypeOptions}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="typeParking"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column" />
            <div styleName="spacer" />
            <div styleName="column" />
          </div>
        )}

        {/* Residential subtypes row */}
        {this.state.showResidentialSubType && (
          <div styleName="type-row" className="flex-start">
            <div styleName="label" className="pre">
              <label htmlFor="typesPARTOther">
                <ResourceText resourceKey="typesPARTOther" />
              </label>
            </div>
            <div styleName="column">
              <Input.QueryLegacy
                name="typesPARTOther"
                values={this.props.typePARTOtherOptions}
                displayPath="displayName"
                valuePath="value"
                matchPath="displayName"
                placeholder="typeOrSelect"
                asSelect
                multiple
              />
            </div>
            <div styleName="spacer" />
            <div styleName="column" />
            <div styleName="spacer" />
            <div styleName="column" />
          </div>
        )}

        {/* Peculiarity types row */}
        <div styleName="type-row" className="flex-start">
          <div styleName="label" className="pre">
            <label htmlFor="peculiarities">
              <ResourceText resourceKey="peculiarities" />
            </label>
          </div>
          <div styleName="column">
            <Input.QueryLegacy
              name="peculiarities"
              values={this.props.searchAssignmentPeculiarityOptions}
              displayPath="displayName"
              valuePath="value"
              matchPath="displayName"
              placeholder="typeOrSelect"
              asSelect
              multiple
            />
          </div>
          <div styleName="spacer" />
          <div styleName="column" />
          <div styleName="spacer" />
          <div styleName="column" />
        </div>
      </Form>
    );
  }

  private getState(types: TypePART[]): SearchAssignmentWhatResidentialComponentState {
    return {
      showHouse: types.indexOf(TypePART.ResidentialBuilding) !== -1,
      showApartment: types.indexOf(TypePART.Apartment) !== -1,
      showParking: types.indexOf(TypePART.Parking) !== -1,
      showResidentialSubType: types.indexOf(TypePART.Other) !== -1
    };
  }
}
