import * as React from "react";
import { memo, ReactNode, FC } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  title: string;
  titleValues?: { [key: string]: any };
  titlePrefix?: string;
  subTitle?: string;
  subTitleValues?: { [key: string]: any };
  subTitlePrefix?: string;
  actions?: ReactNode;
  children?: ReactNode;
}
export const UiPageHeaderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      title,
      titleValues,
      titlePrefix,
      subTitle,
      subTitleValues,
      subTitlePrefix,
      actions,
      children,
    }) => {
      return (
        <div styleName="page-header">
          <div styleName={classNames("title", { "has-children": !!children })}>
            <div styleName="title__main">
              <I18n value={title} values={titleValues} prefix={titlePrefix} />
            </div>
            {!!subTitle && (
              <div styleName="title__sub">
                <I18n
                  value={subTitle}
                  values={subTitleValues}
                  prefix={subTitlePrefix}
                />
              </div>
            )}
          </div>
          <div styleName="content">{children}</div>
          <div styleName="actions">{actions}</div>
        </div>
      );
    }
  )
);
