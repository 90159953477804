import {
  EmailAddressTypeOption,
  Employee,
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberTypeOption,
} from "@haywork/api/kolibri";
import { AccessThunks, Dispatch } from "@haywork/middleware";
import {
  OnboardingModalComponent,
  OnboardingModalComponentProps,
} from "@haywork/modules/onboarding";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  employee: Employee;
  relationEmployeeState: string;
  showOnboarding: boolean;
  homePhoneNumber: string;
  mobilePhoneNumber: string;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
}

interface DispatchProps {
  saveEmployee: (employee: Employee) => void;
  setSeenContactForm: () => void;
}

const getPhoneNumber = (
  phoneNumbers: PhoneNumber[],
  type: PhoneNumberType
): string => {
  const foundPhoneNumbers = phoneNumbers.find(
    (phoneNumber) => phoneNumber.type === type
  );
  return foundPhoneNumbers ? foundPhoneNumbers.number : "";
};

const mapStateToProps = <StateProps, OnboardingModalComponentProps>(
  state: AppState
) => ({
  employee: state.account.employee,
  relationEmployeeState: state.account.relationEmployeeState,
  showOnboarding: state.account.showOnboarding,
  homePhoneNumber: getPhoneNumber(
    state.account.employee.phoneNumbers,
    PhoneNumberType.Home
  ),
  mobilePhoneNumber: getPhoneNumber(
    state.account.employee.phoneNumbers,
    PhoneNumberType.Mobile
  ),
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
});

const mapDispatchToProps = <DispatchProps, OnboardingModalComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveEmployee: (employee: Employee) =>
    dispatch(AccessThunks.saveEmployee(employee)),
  setSeenContactForm: () => dispatch(AccessThunks.setSeenContactForm()),
});

export type OnboardingModalContainerProps = StateProps & DispatchProps;
export const OnboardingModalContainer = connect<
  StateProps,
  DispatchProps,
  OnboardingModalComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingModalComponent);
