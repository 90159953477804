import * as React from "react";
import { FC, memo, useEffect } from "react";
import { DetailContainerProps } from "./detail.container";
import { Switch, Redirect, Route } from "react-router-dom";
import { MLSROUTES } from "@haywork/constants";
import * as CSSModules from "react-css-modules";
import Sidebar from "./components/sidebar";
import DetailGeneral from "../detail-general";
import DetailTimeline from "../detail-timeline";
import DetailSearchAssignments from "../detail-search-assignments";
import { EditableHocProps } from "@haywork/modules/editable";
import DetailMedia from "../detail-media";

const styles = require("./style.scss");

export type DetailComponentProps = {};
type Props = DetailComponentProps & DetailContainerProps & EditableHocProps;

export const DetailComponent: FC<Props> = memo(
  CSSModules(styles)(({ setTabTitle, displayName }) => {
    useEffect(() => {
      setTabTitle(displayName || "...");
    }, [setTabTitle, displayName]);

    return (
      <div styleName="detail">
        <div styleName="sidebar">
          <Sidebar />
        </div>
        <div styleName="body">
          <Switch>
            <Redirect
              path={MLSROUTES.DETAIL.URI}
              to={MLSROUTES.GENERAL.URI}
              exact
            />
            <Route path={MLSROUTES.GENERAL.URI} exact>
              <DetailGeneral />
            </Route>

            <Route path={MLSROUTES.MEDIA.URI} exact>
              <DetailMedia />
            </Route>

            <Route path={MLSROUTES.TIMELINE.URI} exact>
              <DetailTimeline />
            </Route>

            <Route path={MLSROUTES.SEARCH_ASSIGNMENTS.URI} exact>
              <DetailSearchAssignments />
            </Route>
          </Switch>
        </div>
      </div>
    );
  })
);
