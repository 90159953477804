import {
  AssignmentType,
  RelationRole,
  RelationRoleOption,
} from "@haywork/api/kolibri";
import { DropdownFilter, Filter } from "@haywork/modules/filter";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import { RelationUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { LinkedRelationWithRole } from "@haywork/util/assignment";

type Props = {
  contact: LinkedRelationWithRole;
  preppedForRemove: boolean;
  relationRoles: RelationRoleOption[];
  forSale: boolean;
  assignmentId: string;
  onLinkTypeChange: (relationRole: RelationRole) => void;
};

const styles = require("./link-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class ListLinkItemComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onFilterChangedHandler = this.onFilterChangedHandler.bind(this);
    this.renderAddressLine = this.renderAddressLine.bind(this);
    this.renderContactLine = this.renderContactLine.bind(this);
  }

  public render() {
    const { avatarUrl, letterAvatar, displayName, relationRole } =
      this.props.contact;
    const avatarStyles = !!avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(avatarUrl)})` }
      : null;
    const contactLinkStyle = classNames("contact-link", {
      "prepped-for-remove": this.props.preppedForRemove,
    });

    return (
      <div styleName={contactLinkStyle}>
        <div styleName="item__avatar">
          <div styleName="avatar" style={avatarStyles}>
            {!avatarStyles && <div styleName="letters">{letterAvatar}</div>}
          </div>
        </div>
        <div styleName="item__body">
          <Filter changeFilter={this.onFilterChangedHandler}>
            <strong>{displayName}</strong>
            &nbsp;
            <ResourceText resourceKey="is" />
            <span
              className="dropdown-filter-wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <DropdownFilter
                name="linkTypeFilter"
                inline
                values={RelationUtil.mapRelationRoles(
                  this.props.relationRoles,
                  AssignmentType.Acquisition,
                  this.props.forSale
                )}
                value={relationRole}
              />
            </span>
          </Filter>
        </div>

        {this.renderContactLine()}
        {this.renderAddressLine()}
      </div>
    );
  }

  private onFilterChangedHandler(values: any) {
    if (values.linkTypeFilter !== this.props.contact.relationRole) {
      this.props.onLinkTypeChange(values.linkTypeFilter);
    }
  }

  private renderAddressLine(): React.ReactElement<HTMLDivElement> {
    /*const { streetNameAndNumber, locality } = this.props.contact;
    if (!streetNameAndNumber && !locality) return null;

    return <div styleName="item__address">
      {streetNameAndNumber && <div styleName="streetname">{streetNameAndNumber}</div>}
      {locality && <div styleName="locality">{locality}</div>}
    </div>;*/
    return null;
  }

  private renderContactLine(): React.ReactElement<HTMLDivElement> {
    const { phone, phoneMobile, phoneWork, email } = this.props.contact;
    if (!phone && !phoneMobile && !phoneWork && !email) return null;
    const phones = [phone, phoneMobile, phoneWork];
    const cleanedPhones = phones.filter((phone) => !!phone);

    return (
      <div styleName="item__contact">
        {cleanedPhones.length > 0 && (
          <div styleName="phone">{cleanedPhones[0]}</div>
        )}
        {email && (
          <div styleName="email">
            <MailTo email={email} />
          </div>
        )}
      </div>
    );
  }
}
