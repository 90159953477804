import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ConfirmContainerProps } from "./confirm.container";

const styles = require("./confirm.component.scss");

export interface ConfirmComponentProps {}

@CSSModules(styles, { allowMultiple: true })
export class ConfirmComponent extends React.Component<
  ConfirmComponentProps & ConfirmContainerProps
> {
  constructor(props) {
    super(props);

    this.onPositiveClickHandler = this.onPositiveClickHandler.bind(this);
    this.onNegativeClickHandler = this.onNegativeClickHandler.bind(this);
  }

  public render() {
    const { visible, title, body, negativeLabel, positiveLabel } =
      this.props.confirm;
    const confirmStyle = classNames("confirm", { visible });

    return (
      <div styleName={confirmStyle}>
        <div styleName="confirm__backdrop" />
        <div styleName="confirm__object">
          <header>
            <ResourceText resourceKey={title.key} values={title.values} />
            <div styleName="confirm__close" onClick={this.props.close}>
              <div className="fal fa-times" />
            </div>
          </header>
          <section>
            <ResourceText resourceKey={body.key} values={body.values} />
          </section>
          <footer>
            {this.props.confirm.positiveFn && (
              <div
                id="positiveConfirm"
                className="btn btn-success"
                onClick={this.onPositiveClickHandler}
              >
                <ResourceText
                  resourceKey={positiveLabel.key}
                  values={positiveLabel.values}
                />
              </div>
            )}
            {this.props.confirm.negativeFn && (
              <div
                id="negativeConfirm"
                className="btn btn-danger"
                onClick={this.onNegativeClickHandler}
              >
                <ResourceText
                  resourceKey={negativeLabel.key}
                  values={negativeLabel.values}
                />
              </div>
            )}
            <div
              id="cancelConfirm"
              className="btn btn-blank"
              onClick={this.props.close}
            >
              <ResourceText resourceKey="cancel" />
            </div>
          </footer>
        </div>
      </div>
    );
  }

  private onPositiveClickHandler() {
    this.props.close();
    this.props.confirm.positiveFn();
  }

  private onNegativeClickHandler() {
    this.props.close();
    this.props.confirm.negativeFn();
  }
}
