import {
  AgriculturalSubtypeOtherOption,
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  BeverageHospitalityTypeOption,
  CattleFarmingSubtypeOption,
  FastfoodHospitalitySectorTypeOption,
  HorecaSectorTypeOption,
  HorseCompanySubtypeOption,
  HorticulturalCompanyTypeOption,
  HotelHospitalitySectorTypeOption,
  HouseCharacteristicOption,
  HouseSortOption,
  HouseTypeOption,
  InvestmentTypeOption,
  LeisureTypeOption,
  ListingTypeOption,
  OfferType,
  ParkingTypeOption,
  PigCompanyTypeOption,
  ProjectAssignment,
  RealEstateGroup,
  RealEstateGroupOption,
  RelationSnapShot,
  ResidentialSubtypeOtherOption,
  RestaurantHospitalitySectorTypeOption,
  SocialPropertyTypeOption,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import {
  ProjectEditClientComponent,
  ProjectEditClientComponentProps,
} from "@haywork/modules/project/components/edit-client/edit-client.component";
import { AppState, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  employees: RelationSnapShot[];
  offices: RelationSnapShot[];
  realEstateGroups: RealEstateGroupOption[];
  listingTypes: ListingTypeOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  parkingTypeOptions: ParkingTypeOption[];
  residentialSubtypeOthers: ResidentialSubtypeOtherOption[];
  realEstateAgencyId: string;
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  host: string;
  path: string;
  investmentTypeOptions: InvestmentTypeOption[];
  horecaSectorTypeOptions: HorecaSectorTypeOption[];
  leisureTypeOptions: LeisureTypeOption[];
  socialPropertyTypeOptions: SocialPropertyTypeOption[];
  beverageHospitalitySectorTypes: BeverageHospitalityTypeOption[];
  restaurantHospitalitySectorTypes: RestaurantHospitalitySectorTypeOption[];
  fastfoodHospitalitySectorTypes: FastfoodHospitalitySectorTypeOption[];
  hotelHospitalitySectorTypes: HotelHospitalitySectorTypeOption[];
  horseCompanySubtypes: HorseCompanySubtypeOption[];
  cattleFarmingSubtypes: CattleFarmingSubtypeOption[];
  horticulturalCompanyTypes: HorticulturalCompanyTypeOption[];
  pigCompanyTypes: PigCompanyTypeOption[];
  agriculturalSubtypeOthers: AgriculturalSubtypeOtherOption[];
  offerType: OfferType;
}

interface DispatchProps {
  updateProject: (componentState: SingleProjectState, path: string) => void;
  navigate: (url: string) => void;
}

const mapRealEstateGroups = (
  realEstateGroups: RealEstateGroupOption[]
): RealEstateGroupOption[] => {
  return realEstateGroups.filter((realEstateGroup) => {
    switch (realEstateGroup.value) {
      case RealEstateGroup.Commercial:
      case RealEstateGroup.Residential:
      case RealEstateGroup.Agricultural:
        return true;
      default:
        return false;
    }
  });
};

const mapStateToProps = <StateProps, ProjectEditClientComponentProps>(
  state: AppState
) => {
  const {
    realEstateGroups,
    listingTypes,
    investmentTypeOptions,
    horecaSectorTypeOptions,
    leisureTypeOptions,
    socialPropertyTypeOptions,
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
    beverageHospitalitySectorTypes,
    restaurantHospitalitySectorTypes,
    fastfoodHospitalitySectorTypes,
    hotelHospitalitySectorTypes,
    horseCompanySubtypes,
    cattleFarmingSubtypes,
    horticulturalCompanyTypes,
    pigCompanyTypes,
    agriculturalSubtypeOthers,
  } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { currentComponentState } = state.editable;
  const projectAssignment: ProjectAssignment =
    currentComponentState.projectAssignment;

  return {
    employees: state.account.employees,
    offices: state.account.offices,
    realEstateGroups: mapRealEstateGroups(realEstateGroups),
    houseSorts,
    houseTypes,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
    realEstateAgencyId,
    projectAssignment,
    currentComponentState,
    host: state.appSettings.host,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id,
    }),
    investmentTypeOptions,
    horecaSectorTypeOptions,
    leisureTypeOptions,
    socialPropertyTypeOptions,
    beverageHospitalitySectorTypes,
    restaurantHospitalitySectorTypes,
    fastfoodHospitalitySectorTypes,
    hotelHospitalitySectorTypes,
    horseCompanySubtypes,
    cattleFarmingSubtypes,
    horticulturalCompanyTypes,
    pigCompanyTypes,
    agriculturalSubtypeOthers,
    listingTypes,
    houseCharacteristics,
    offerType: OfferType.MixingForm,
  };
};

const mapDispatchToProps = <DispatchProps, ProjectEditClientComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      ProjectsThunks.Projects.updateProjectEditable(componentState, path)
    ),
  navigate: (url: string) => dispatch(push(url)),
});

export type ProjectEditClientContainerProps = StateProps & DispatchProps;
export const ProjectEditClientContainer = connect<
  StateProps,
  DispatchProps,
  ProjectEditClientComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditClientComponent);
