import { FolderCategory, SyncStatus } from "@haywork/api/mail";
import * as React from "react";
import { FC, memo, useCallback, useState, useEffect, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import FolderGroup from "./components/folder-group";
import List from "./components/list";
import UserFolders from "./components/user-folders";
import { EmailContainerProps } from "./email.container";
import { EmailContextProvider } from "./email.context";
import AddFolderModal from "./components/add-folder";
import UpdateFolderModal from "./components/update-folder";
import ListFilters from "./components/list-filters";
import Detail from "./components/detail";
import Header from "./components/header";
import first from "lodash-es/first";
import classNames from "classnames";
import { EmailReAuthenticateModalComponent } from "../email";
import I18n from "@haywork/components/i18n";
import { Account } from "@haywork/api/mail";
import ErrorLintComponent from "@haywork/components/ui/error-lint";
import head from "lodash-es/head";
import { Modal, ModalHeader, ModalBody } from "../modal";
import { RelationSnapShot } from "@haywork/api/kolibri";
import EmailStatus from "./components/status";

const styles = require("./style.scss");

export type EmailComponentProps = {};
type Props = EmailComponentProps & EmailContainerProps;

export const EmailComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      getMessages,
      getDrafts,
      currentMessageId,
      setCurrentMessage,
      accountsAndFoldersVisible,
      accounts,
      reAuthenticate,
      loggedInEmployeeId,
      employees,
      requestState,
      clearAndReloadEmailState,
    }) => {
      const [addFolderModalState, setAddFolderModalState] = useState({
        visible: false,
        accountId: "",
        folderId: null,
      });
      const [updateFolderModalState, setUpdateFolderModalState] = useState({
        visible: false,
        folderId: "",
      });

      const [reAuthModalVisible, setReAuthModalVisible] = useState(false);
      const [reAuthNotOwnerModalVisible, setReAuthNotOwnerModalVisible] =
        useState(false);

      const [reAuthAccount, setReAuthAccount] = useState<Account>(null);
      const [accountOwner, setAccountOwner] = useState<RelationSnapShot>(null);

      const [loading, setLoading] = useState(false);

      const onAddFolder = useCallback(
        (parentFolder: string | null, accountId: string) => {
          setAddFolderModalState({
            visible: true,
            accountId,
            folderId: parentFolder,
          });
        },
        [setAddFolderModalState]
      );

      const onUpdateFolder = useCallback(
        (folderId: string) => {
          setUpdateFolderModalState({
            visible: true,
            folderId,
          });
        },
        [setUpdateFolderModalState]
      );

      const onCloseAddFolderModal = useCallback(() => {
        setAddFolderModalState({
          visible: false,
          accountId: "",
          folderId: null,
        });
      }, [setAddFolderModalState]);

      const onCloseUpdateFolderModal = useCallback(() => {
        setUpdateFolderModalState({ visible: false, folderId: null });
      }, [setUpdateFolderModalState]);

      const getMessagesCallback = useCallback(
        async (skip: number, accountId: string, folderId: string) => {
          setLoading(true);

          try {
            const messages = await getMessages(skip, accountId, folderId);
            if (!currentMessageId) {
              const message = first(messages || []);
              if (!message) return;
              setCurrentMessage(message);
            }
          } finally {
            setLoading(false);
          }
        },
        [loading, setLoading, getMessages, currentMessageId, setCurrentMessage]
      );

      const getDraftsCallback = useCallback(
        async (skip: number, accountId: string, folderId: string) => {
          if (loading) return;
          setLoading(true);

          try {
            await getDrafts(skip, accountId, folderId);
          } finally {
            setLoading(false);
          }
        },
        [loading, setLoading, getDrafts]
      );

      const findEmployee = useCallback(
        (ownerId: string) => {
          return employees.find((employee) => employee.id === ownerId);
        },
        [employees]
      );

      const showSyncErrorModal = useCallback(
        (loggedInEmployeeId: string, ownerId: string) => {
          if (loggedInEmployeeId === ownerId) setReAuthModalVisible(true);
          if (loggedInEmployeeId !== ownerId) {
            setAccountOwner(findEmployee(ownerId));
            setReAuthNotOwnerModalVisible(true);
          }
        },
        [
          setReAuthModalVisible,
          setReAuthNotOwnerModalVisible,
          setAccountOwner,
          findEmployee,
        ]
      );

      useEffect(() => {
        const reAuthAccount = head(
          accounts.filter(
            (account) => account.syncStatus === SyncStatus.InvalidCredentials
          )
        );
        setReAuthAccount(reAuthAccount);
      }, [head, accounts, setReAuthAccount]);

      return (
        <div styleName="email">
          <ErrorLintComponent
            messages={[
              <I18n
                value="emailReAuthenticateWarning"
                values={{ email: reAuthAccount?.emailAddress }}
                asHtml
              />,
            ]}
            visible={!!reAuthAccount}
            onClick={() =>
              showSyncErrorModal(loggedInEmployeeId, reAuthAccount.ownerId)
            }
          ></ErrorLintComponent>

          <EmailContextProvider
            value={{
              onAddFolder,
              onUpdateFolder,
              loading,
              getMessages: getMessagesCallback,
              getDrafts: getDraftsCallback,
            }}
          >
            <div styleName="header">
              <Header />
            </div>
            <div
              styleName={classNames("body", {
                "accounts-and-folders-hidden": !accountsAndFoldersVisible,
              })}
            >
              <div styleName="accounts-and-folders">
                <div styleName="accounts-and-folders__list">
                  <FolderGroup category={FolderCategory.Inbox} />
                  <FolderGroup category={FolderCategory.Important} />
                  <FolderGroup category={FolderCategory.Drafts} />
                  <FolderGroup category={FolderCategory.Sent} />
                  <FolderGroup category={FolderCategory.Spam} />
                  <FolderGroup category={FolderCategory.Archive} />
                  <FolderGroup category={FolderCategory.Trash} />
                  <FolderGroup category={FolderCategory.SynchronizingMessage} />
                  <UserFolders />
                </div>

                <div styleName="accounts-and-folders__status">
                  <EmailStatus
                    status={requestState}
                    onReloadEmailState={clearAndReloadEmailState}
                  />
                </div>
              </div>
              <div styleName="messages">
                <ListFilters />
                <List />
              </div>
              <div styleName="message">
                <Detail />
              </div>
            </div>

            <AddFolderModal
              visible={addFolderModalState.visible}
              accountId={addFolderModalState.accountId}
              folderId={addFolderModalState.folderId}
              onClose={onCloseAddFolderModal}
            />

            <UpdateFolderModal
              visible={updateFolderModalState.visible}
              folderId={updateFolderModalState.folderId}
              onClose={onCloseUpdateFolderModal}
            />

            <EmailReAuthenticateModalComponent
              visible={reAuthModalVisible}
              account={reAuthAccount}
              onClose={() => setReAuthModalVisible(false)}
              onReAuthenticate={reAuthenticate}
            />

            <Modal
              visible={reAuthNotOwnerModalVisible}
              onClose={() => {
                setReAuthNotOwnerModalVisible(false);
                setAccountOwner(null);
              }}
            >
              <ModalHeader
                title={"emailV2.reAuthAccount.notAccountOwner.modal.title"}
                close
              />
              <ModalBody>
                <I18n
                  value="emailV2.reAuthAccount.notAccountOwner.modal.message"
                  values={{
                    employeeName: accountOwner?.displayName,
                  }}
                ></I18n>
              </ModalBody>
            </Modal>
          </EmailContextProvider>
        </div>
      );
    }
  )
);
