import { Action } from "redux";
import differenceBy from "lodash-es/differenceBy";

import { REDUX } from "@haywork/constants";
import { ExtendedEmailFolder } from "@haywork/util/email";

import * as ActionType from "./folders.types";
import { IdentifierPayload } from "@haywork/stores/helpers";
import { FolderCategory } from "@haywork/api/mail";

interface RootCanLoadMore {
  accountId: string;
  canLoadMore: boolean;
}
export interface FoldersState {
  folders: ExtendedEmailFolder[];
  currentFolder: ExtendedEmailFolder;
  rootCanLoadMore: RootCanLoadMore[];
}

const INITIAL_STATE: FoldersState = {
  folders: [],
  currentFolder: null,
  rootCanLoadMore: [],
};

export const foldersReducer = (
  state: FoldersState = INITIAL_STATE,
  action: Action
): FoldersState => {
  switch (action.type) {
    case REDUX.EMAILV2.APPEND_FOLDERS: {
      const { folders: appendables } = <ActionType.FoldersPayload>action;
      let folders = differenceBy(
        state.folders,
        appendables,
        (folder) => folder.id
      );

      folders = [...folders, ...appendables];

      return {
        ...state,
        folders,
      };
    }
    case REDUX.EMAILV2.SET_CURRENT_FOLDER: {
      const { folder: currentFolder } = <ActionType.FolderPayload>action;
      const folders = state.folders.map((folder) => {
        if (folder.id !== currentFolder.id) {
          return {
            ...folder,
            canLoadMoreMessages: true,
          };
        }
        return folder;
      });

      return {
        ...state,
        currentFolder,
        folders,
      };
    }
    case REDUX.EMAILV2.UPDATE_FOLDER: {
      const { folder: updateable } = <ActionType.FolderPayload>action;
      let folders = state.folders.map((folder) => {
        if (folder.id === updateable.id) {
          return {
            ...folder,
            ...updateable,
          };
        }
        return folder;
      });

      let currentFolder = state.currentFolder;
      if (!!currentFolder && currentFolder.id === updateable.id) {
        currentFolder = {
          ...currentFolder,
          ...updateable,
        };
      }

      if (!!updateable.parentId) {
        folders = folders.map((folder) => {
          if (folder.id === updateable.parentId) {
            return {
              ...folder,
              hasSubFolders: true,
            };
          }
          return folder;
        });
      }

      return {
        ...state,
        folders,
        currentFolder,
      };
    }
    case REDUX.EMAILV2.REMOVE_FOLDER: {
      const { folder: deletable } = <ActionType.FolderPayload>action;
      let folders = state.folders.filter(
        (folder) => folder.id !== deletable.id
      );

      if (!!deletable.parentId) {
        const subFolderCount = folders.filter(
          (folder) => folder.parentId === deletable.parentId
        ).length;
        if (subFolderCount === 0) {
          folders = folders.map((folder) => {
            if (folder.id === deletable.parentId) {
              return {
                ...folder,
                hasSubFolders: false,
                canLoadMoreFolders: false,
              };
            }
            return folder;
          });
        }
      }

      return {
        ...state,
        folders,
      };
    }
    case REDUX.EMAILV2.SET_UNREAD_COUNT: {
      const { counts } = <ActionType.UnreadCount>action;
      const unreadCountIds = counts.map((count) => count.folderId);

      const folders = state.folders.map((folder) => {
        const idx = unreadCountIds.indexOf(folder.id);
        if (idx !== -1) {
          return {
            ...folder,
            unreadCount: counts[idx].unreadCount,
          };
        }
        return folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case REDUX.EMAILV2.REMOVE_ACCOUNT: {
      const { id } = <IdentifierPayload>action;
      const folders = state.folders.filter((folder) => folder.accountId !== id);
      const rootCanLoadMore = state.rootCanLoadMore.filter(
        (root) => root.accountId !== id
      );
      let currentFolder = null;

      if (!!state.currentFolder && state.currentFolder.accountId !== id) {
        currentFolder = state.currentFolder;
      }

      return {
        ...state,
        folders,
        rootCanLoadMore,
        currentFolder,
      };
    }
    case REDUX.EMAILV2.SET_FOLDER_UNREAD_COUNT: {
      const { id, unreadCount } = <ActionType.FolderUnreadCount>action;
      const folders = state.folders.map((folder) => {
        if (folder.id === id) {
          return {
            ...folder,
            unreadCount,
          };
        }
        return folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case REDUX.EMAILV2.SET_FOLDER_MESSAGES_STATUS: {
      const { canLoadMoreMessagesStatus, id } = <ActionType.MessagesStatus>(
        action
      );
      let currentFolder = state.currentFolder;
      const folders = state.folders.map((folder) => {
        if (folder.id === id) {
          return {
            ...folder,
            canLoadMoreMessagesStatus,
          };
        }
        return folder;
      });

      if (!!currentFolder && currentFolder.id === id) {
        currentFolder = {
          ...currentFolder,
          canLoadMoreMessagesStatus,
        };
      }

      return {
        ...state,
        currentFolder,
        folders,
      };
    }
    case REDUX.EMAILV2.SET_FOLDER_CAN_LOAD_MORE: {
      const { canLoadMoreMessages, id } = <ActionType.CanLoadMore>action;
      let currentFolder = state.currentFolder;
      const folders = state.folders.map((folder) => {
        if (folder.id === id) {
          return {
            ...folder,
            canLoadMoreMessages,
          };
        }
        return folder;
      });

      if (!!currentFolder && currentFolder.id === id) {
        currentFolder = {
          ...currentFolder,
          canLoadMoreMessages,
        };
      }

      return {
        ...state,
        currentFolder,
        folders,
      };
    }
    case REDUX.EMAILV2.SET_ROOT_CAN_LOAD_MORE: {
      const { accountId, canLoadMore } = <ActionType.RootCanLoadMore>action;
      const accountIds = state.rootCanLoadMore.map((root) => root.accountId);

      let rootCanLoadMore = state.rootCanLoadMore;
      if (accountIds.indexOf(accountId) === -1) {
        rootCanLoadMore = [
          ...rootCanLoadMore,
          {
            accountId,
            canLoadMore,
          },
        ];
      } else {
        rootCanLoadMore = rootCanLoadMore.map((root) => {
          if (root.accountId === accountId) {
            return {
              accountId,
              canLoadMore,
            };
          }
          return root;
        });
      }

      return {
        ...state,
        rootCanLoadMore,
      };
    }
    case REDUX.EMAILV2.UPDATE_UNREAD_COUNT: {
      const { unread, folderId } = <ActionType.UpdateUnreadCount>action;
      const folders = state.folders.map((folder) => {
        if (
          folder.id === folderId &&
          folder.category === FolderCategory.Inbox
        ) {
          return {
            ...folder,
            unreadCount: unread
              ? folder.unreadCount - 1
              : folder.unreadCount + 1,
          };
        }
        return folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
