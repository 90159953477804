import * as React from "react";
import * as CSSModules from "react-css-modules";
import noop from "lodash-es/noop";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";

import { REQUEST, SETTINGSROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";

import { EmailDetailHeaderComponent } from "./header.component";

const styles = require("./detail.component.scss");

export interface Props {
  name: string;
  hasAccount: boolean;
  accountsStatus: string;
  hasAccounts: boolean;
  onCreateNewEmail: () => void;
}

export const DetailEmptyState = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    if (props.accountsStatus !== REQUEST.SUCCESS) {
      return (
        <div styleName="detail">
          <Ui.Loaders.Fullscreen />
        </div>
      );
    }

    if (props.accountsStatus === REQUEST.SUCCESS && !!props.hasAccounts) {
      return (
        <div styleName="empty-state">
          <ResourceText resourceKey="emptyStateEmailDetail" />
        </div>
      );
    }

    return (
      <div styleName="detail">
        <div styleName="detail__header">
          <EmailDetailHeaderComponent
            message={null}
            messageStatus={REQUEST.PENDING}
            currentAccount={null}
            currentFolder={null}
            currentFolderIsArchive={false}
            currentFolderIsTrash={false}
            toggleBookmarked={noop}
            toggleUnread={noop}
            moveToFolder={noop}
            onDeleteMessage={noop}
            onMessageToDraft={noop}
            onArchiveMessage={noop}
            onCreateNewEmail={props.onCreateNewEmail}
            onDownloadExternalReferences={noop}
          />
        </div>
        <div styleName="detail__body">
          <div styleName="detail__paper">
            <div styleName="paper__header">
              {/* Info */}
              <div styleName="info">
                <div styleName="date">
                  <FormattedDate
                    value={new Date()}
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                  &nbsp;-&nbsp;
                  <FormattedDate
                    value={new Date()}
                    hour="2-digit"
                    minute="2-digit"
                  />
                </div>
                <div styleName="name">Kolibri</div>
                <div styleName="description">
                  <ResourceText resourceKey="emailEmptyStateMessageDescription" />
                </div>
              </div>
            </div>
            <div styleName="paper__body">
              <div styleName="empty-state__body">
                <ResourceText
                  resourceKey={
                    !props.hasAccount
                      ? "emailEmptyStateMessageBody"
                      : "emailEmptyStateMessageBodySubscribed"
                  }
                  values={{ name: props.name }}
                  asHtml
                />
              </div>
              <Link to={SETTINGSROUTES.EMAIL.URI} styleName="empty-state__cta">
                <ResourceText
                  resourceKey={
                    !props.hasAccount
                      ? "emailEmptyStateMessageLink"
                      : "emailEmptyStateMessageLinkSubscribed"
                  }
                  asHtml
                />
              </Link>
              {!props.hasAccount && (
                <div styleName="empty-state__financial">
                  <ResourceText resourceKey="emailEmptyStateMessageFinancialFooter" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
