import { ListFilterComponent } from "./list-filter";
import {
  ListFilterContext,
  ListFilterContextConsumer,
  ListFilterContextProvider,
} from "./list-filter.context";
import FilterSection from "./components/section";
import CheckboxArray from "./components/checkbox-array";
import TextRange from "./components/text-range";
import NumberRange from "./components/number-range";
import PriceRange from "./components/price-range";
import KeyNumberArray from "./components/keynumber-array";
import DatePicker from "./components/date-picker";
import DateRange from "./components/date-range";
import Select, {
  SelectEmptyValue as SelectFilterEmptyValue,
} from "./components/select";
import { Translateable } from "@haywork/components/i18n";
import FilterSectionColumn from "./components/section-column";
import LocationRange from "./components/location-range";
import RadioGroup from "./components/radio-group";
import FilterContextMenu from "./components/filter-context-menu";
import { FilterType } from "@haywork/enum/list-filter-types";
import Realtor from "./components/realtors";
import SearchLocation from "./components/search-location";
import SavedFilters from "./components/saved-filters";
import MlsPriceRange from "./components/mls-price-range";
import PostalCodeRange from "./components/postal-code-range";

type FilterGuidValues = {
  [key: string]: { value: string; id: string }[];
};

type SingleFilterValue = {
  label?: Translateable;
  translatedLabel?: string;
  value: any;
  count?: number;
};

type SubFilter = {
  [key: string]: string;
};

type SingleFilterConfig = {
  type: FilterType;
  value: any | any[];
  emptyValue: any;
  prefix?: string;
  subFilter?: SubFilter;
};

type FilterConfig = {
  [key: string]: SingleFilterConfig;
};

const Filter = {
  CheckboxArray,
  TextRange,
  NumberRange,
  PriceRange,
  MlsPriceRange,
  KeyNumberArray,
  DatePicker,
  DateRange,
  Select,
  LocationRange,
  Realtor,
  RadioGroup,
  SearchLocation,
  SavedFilters,
  PostalCodeRange,
};

export {
  ListFilterComponent as default,
  ListFilterContext,
  ListFilterContextConsumer,
  ListFilterContextProvider,
  FilterSection,
  FilterSectionColumn,
  SingleFilterConfig,
  FilterConfig,
  SingleFilterValue,
  Filter,
  FilterGuidValues,
  SelectFilterEmptyValue,
  FilterContextMenu,
};
