import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { TimelineComponent, TimelineComponentProps } from "./timeline";
import { MutationTypeOption, Mutation } from "@haywork/api/mls";
import { mutations } from "./selectors";

type StateProps = {
  mutationTypeOptions: MutationTypeOption[];
  mutations: Mutation[];
  forRentOrSale: "sale" | "rent";
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  TimelineComponentProps,
  AppState
> = (state) => {
  const { mutationTypeOptions } = state.main.mastertable.mls;
  const isForSale =
    state.mls.single.realEstateResponse?.realEstateProperty?.offer?.isForSale;

  return {
    mutationTypeOptions,
    mutations: mutations(state),
    forRentOrSale: isForSale ? "sale" : "rent",
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  TimelineComponentProps
> = (dispatch) => ({});

export type TimelineContainerProps = StateProps & DispatchProps;
export const TimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineComponent);
