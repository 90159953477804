import { EmployeeRole } from "@haywork/api/kolibri";
import {
  FolderOrderBy,
  FoldersClient,
  SaveFolderRequest,
  SortOrder
} from "@haywork/api/mail";
import { COUNTS } from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EmailActions } from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { EmailUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();

const searchFolders = (
  parentFolderId: string,
  accountId: string,
  init: boolean = false,
  flushCache: boolean = true
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const {
      id: realEstateAgencyId,
      role
    } = state.account.currentRealestateAgency;
    if (role === EmployeeRole.BackOffice) return;

    const { emailHost } = state.appSettings;
    const { folders, rootCanLoadMore } = state.email.folders;
    const take = COUNTS.EMAIL_FOLDERS;
    const root = rootCanLoadMore.find((root) => root.accountId === accountId);
    const subFolderCount = !parentFolderId
      ? !!root
        ? folders.filter((folder) => folder.accountId === accountId).length
        : 0
      : folders.filter((folder) => folder.parentId === parentFolderId).length;

    const Folders = new FoldersClient(emailHost);

    try {
      const folderResponse = await parseRequest.response(
        Folders.search(
          {
            accountId,
            parentFolderId,
            skip: !!init ? 0 : subFolderCount,
            take,
            orderBy: FolderOrderBy.Default,
            order: SortOrder.Ascending,
            flushCache: true,
            includeStatistics: false
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const extendedFolders = folderResponse.results.map((folder) =>
        EmailUtil.mapFolderToExtendedFolder(
          folder,
          accountId,
          folder.hasSubFolders &&
            folderResponse.resultCount < folderResponse.totalResults
        )
      );
      dispatch(
        EmailActions.Folders.appendFolders({ folders: extendedFolders })
      );

      if (parentFolderId === "") {
        const totalResults =
          folders.filter((folder) => folder.accountId === accountId).length +
          folderResponse.resultCount;
        dispatch(
          EmailActions.Folders.setRootCanLoadMore({
            accountId,
            canLoadMore: folderResponse.totalResults > totalResults
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const getRootFolders = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const {
      id: realEstateAgencyId,
      role
    } = state.account.currentRealestateAgency;
    if (role === EmployeeRole.BackOffice) return;

    const { emailHost } = state.appSettings;
    const { accounts } = state.email.accounts;
    const take = COUNTS.EMAIL_FOLDERS;

    const Folders = new FoldersClient(emailHost);

    try {
      const promises = accounts.results.map((account) =>
        parseRequest.response(
          Folders.search(
            {
              accountId: account.id,
              skip: 0,
              take,
              orderBy: FolderOrderBy.Default,
              order: SortOrder.Ascending,
              flushCache: true,
              parentFolderId: "",
              includeStatistics: false
            },
            realEstateAgencyId
          ),
          ApiType.Email
        )
      );

      const folders = await Promise.all(promises);

      accounts.results.map((account, idx) => {
        const folderResponse = folders[idx];
        const extendedFolders = folderResponse.results.map((folder) =>
          EmailUtil.mapFolderToExtendedFolder(
            folder,
            account.id,
            folder.hasSubFolders &&
              folderResponse.resultCount < folderResponse.totalResults
          )
        );
        dispatch(
          EmailActions.Folders.appendFolders({ folders: extendedFolders })
        );
        dispatch(
          EmailActions.Folders.setRootCanLoadMore({
            accountId: account.id,
            canLoadMore:
              folderResponse.totalResults > folderResponse.resultCount
          })
        );
      });
    } catch (error) {
      throw error;
    }
  };
};

const saveFolder = (request: SaveFolderRequest, isNew: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Folders = new FoldersClient(emailHost);

    try {
      const folder = await parseRequest.response(
        Folders.saveFolder(request, realEstateAgencyId).then((r) => r.folder),
        ApiType.Email
      );

      if (isNew) {
        await dispatch(searchFolders("", request.accountId, true, true));

        const toast: ToastProps = {
          value: "newEmailFolderCreated",
          values: folder,
          icon: "older"
        };

        dispatch(SnackbarActions.addToast(toast));
      } else {
        const extendedFolder = EmailUtil.mapFolderToExtendedFolder(
          folder,
          request.accountId
        );
        const toast: ToastProps = {
          value: "emailFolderUpdated",
          values: folder,
          icon: "folder"
        };

        dispatch(EmailActions.Folders.updateFolder({ folder: extendedFolder }));
        dispatch(SnackbarActions.addToast(toast));
      }
    } catch (error) {
      throw error;
    }
  };
};

const moveFolder = (
  folder: ExtendedEmailFolder,
  targetFolder: ExtendedEmailFolder,
  accountId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Folders = new FoldersClient(emailHost);

    const parentId = targetFolder ? targetFolder.id : "";
    const updatedFolder: ExtendedEmailFolder = {
      ...folder,
      parentId
    };
    dispatch(EmailActions.Folders.updateFolder({ folder: updatedFolder }));

    try {
      await parseRequest.response(
        Folders.saveFolder(
          {
            id: folder.id,
            accountId,
            displayName: folder.displayName,
            parentId
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const toast: ToastProps = {
        value: "emailToastMessageFolderMoved",
        icon: "folder"
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      dispatch(EmailActions.Folders.updateFolder({ folder }));
      throw error;
    }
  };
};

const removeFolder = (folder: ExtendedEmailFolder, accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Folders = new FoldersClient(emailHost);

    dispatch(EmailActions.Folders.removeFolder({ folder }));

    try {
      await parseRequest.response(
        Folders.deleteFolder(accountId, folder.id, realEstateAgencyId),
        ApiType.Email
      );

      const toast: ToastProps = {
        value: "emailToastMessageFolderDeleted",
        icon: "trash-alt"
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      throw error;
    }
  };
};

const getFolder = (accountId: string, id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Folders = new FoldersClient(emailHost);

    try {
      return await parseRequest.response(
        Folders.readFolder(accountId, id, realEstateAgencyId).then(
          (res) => res.folder
        ),
        ApiType.Email
      );
    } catch (error) {
      throw error;
    }
  };
};

const checkAndUpdateFolder = (folderId: string, accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Folders = new FoldersClient(emailHost);

    try {
      const folder = await parseRequest.response(
        Folders.readFolder(accountId, folderId, realEstateAgencyId).then(
          (r) => r.folder
        ),
        ApiType.Email,
        { displayError: false }
      );

      const extendedFolder = EmailUtil.mapFolderToExtendedFolder(
        folder,
        accountId
      );
      dispatch(EmailActions.Folders.updateFolder({ folder: extendedFolder }));
    } catch (error) {
      throw error;
    }
  };
};

export const EmailFolderThunks = {
  searchFolders,
  getRootFolders,
  saveFolder,
  moveFolder,
  removeFolder,
  getFolder,
  checkAndUpdateFolder
};
