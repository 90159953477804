import {
  InitializedWidget,
  WidgetEntityType,
  WidgetSnapShot,
} from "@haywork/api/authorization";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Action } from "@haywork/components/ui/action-list";

const styles = require("./style.scss");

interface Props {
  widgets: WidgetSnapShot[];
  entityType: WidgetEntityType;
  entityIds: string[];
  showModal: (
    widgetModalWidget: WidgetSnapShot,
    widgetModalInitializedWidget: InitializedWidget
  ) => void;
  getInitializedWidgets: (
    ids: string[],
    type: WidgetEntityType,
    cancelStoreUpdate?: boolean
  ) => Promise<InitializedWidget[]>;
  onActionClick?: () => void;
}
interface State {
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsContextMenuComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handlePartnerClick = this.handlePartnerClick.bind(this);
  }

  public render() {
    const { widgets } = this.props;
    if (!widgets.length) return null;

    return (
      <>
        {widgets.map((widget) => (
          <Action
            label={widget.displayName}
            onClick={() => {
              this.handlePartnerClick(widget);
              if (this.props.onActionClick) {
                this.props.onActionClick();
              }
            }}
            key={widget.id}
            icon={
              !!widget?.linkedAppClient?.iconUrl ? (
                <img
                  width={22.5}
                  height={22.5}
                  src={widget.linkedAppClient.iconUrl}
                />
              ) : undefined
            }
          />
        ))}
      </>
    );
  }

  private async handlePartnerClick(widget: WidgetSnapShot) {
    if (this.state.loading) return;
    this.setState({ loading: true });

    try {
      const { entityType, entityIds, getInitializedWidgets } = this.props;
      const initializedWidgets = await getInitializedWidgets(
        entityIds,
        entityType
      );

      const initializedWidget = initializedWidgets.find(
        (initializedWidget) => initializedWidget.id === widget.id
      );

      if (!!initializedWidget?.signedCallbackUri) {
        this.props.showModal(widget, initializedWidget);
      }
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }
}
