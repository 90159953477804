import { Dispatch } from ".";

import { AppState, MediapartnersActions } from "@haywork/stores";
import {
  ActiveFilter,
  MediaContractClient,
  MediaContractOrderByField,
  MediaContractPublicationMethod,
  MediaPartnerCategory,
  SortOrder
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";

const parseRequest = new ParseRequest();

const getMediaContracts = (
  init: boolean = false,
  refresh: boolean | null = null,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      MediapartnersActions.setMediaContractsStatus({
        status: REQUEST.PENDING,
        refresh
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite
    } = state.mediapartners;

    const MediaContract = new MediaContractClient(host);

    try {
      const result = await parseRequest.response(
        MediaContract.search(
          {
            orderBy: MediaContractOrderByField.Default,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: init ? 0 : mediaContracts.page * take,
            take,
            categories,
            statuses,
            isFavorite
          },
          realEstateAgencyId
        )
      );

      init
        ? dispatch(MediapartnersActions.setMediaContracts({ ...result, take }))
        : dispatch(
            MediapartnersActions.appendMediaContracts({ ...result, take })
          );
    } catch (error) {
      dispatch(
        MediapartnersActions.setMediaContractsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const resetFilters = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MediapartnersActions.resetFilters());
    dispatch(getMediaContracts(true, true));
  };
};

const toggleIsActive = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MediapartnersActions.toggleIsActive());
    dispatch(getMediaContracts(true, true));
  };
};

const toggleIsFavorite = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MediapartnersActions.toggleIsFavorite());
    dispatch(getMediaContracts(true, true));
  };
};

const setCategories = (categories: MediaPartnerCategory[]) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MediapartnersActions.setCategories({ categories }));
    dispatch(getMediaContracts(true, true));
  };
};

const activateMediaContract = (mediaContractId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      MediapartnersActions.setMediaContractsStatus({
        status: REQUEST.PENDING,
        refresh: true
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite
    } = state.mediapartners;
    const take = mediaContracts.results.length;

    const MediaContract = new MediaContractClient(host);

    try {
      await parseRequest.response(
        MediaContract.activate({ mediaContractId }, realEstateAgencyId)
      );

      dispatch(getMediaContracts(true, true));
    } catch (error) {
      dispatch(
        MediapartnersActions.setMediaContractsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const deActivateMediaContract = (mediaContractId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      MediapartnersActions.setMediaContractsStatus({
        status: REQUEST.PENDING,
        refresh: true
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite
    } = state.mediapartners;
    const take = mediaContracts.results.length;

    const MediaContract = new MediaContractClient(host);

    try {
      await parseRequest.response(
        MediaContract.deactivate({ mediaContractId }, realEstateAgencyId)
      );

      dispatch(getMediaContracts(true, true));
    } catch (error) {
      dispatch(
        MediapartnersActions.setMediaContractsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const setFavorite = (mediaContractId: string, setIsFavorite: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite
    } = state.mediapartners;
    const take = mediaContracts.results.length;

    const MediaContract = new MediaContractClient(host);

    try {
      const contract = await parseRequest.response(
        MediaContract.read(mediaContractId, realEstateAgencyId).then(
          (response) => response.mediaContract
        )
      );

      const mediaContract = {
        ...contract,
        isFavorite: setIsFavorite
      };

      await parseRequest.response(
        MediaContract.save({ mediaContract }, realEstateAgencyId)
      );

      dispatch(getMediaContracts(true, true));
    } catch (error) {
      dispatch(
        MediapartnersActions.setMediaContractsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const setPublishType = (mediaContractId: string, isAutoPublish: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      MediapartnersActions.setMediaContractsStatus({
        status: REQUEST.PENDING,
        refresh: true
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite
    } = state.mediapartners;
    const take = mediaContracts.results.length;

    const MediaContract = new MediaContractClient(host);

    const publicationMethod = !!isAutoPublish
      ? MediaContractPublicationMethod.Auto
      : MediaContractPublicationMethod.Manual;

    try {
      await parseRequest.response(
        MediaContract.updatePublicationMethod(
          { mediaContractId, publicationMethod },
          realEstateAgencyId
        )
      );

      dispatch(getMediaContracts(true, true));
    } catch (error) {
      dispatch(
        MediapartnersActions.setMediaContractsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

export const MediapartnersThunks = {
  getMediaContracts,
  resetFilters,
  toggleIsActive,
  toggleIsFavorite,
  setCategories,
  activateMediaContract,
  deActivateMediaContract,
  setFavorite,
  setPublishType
};
