import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";

const editableStatesSelector = (state: AppState) => state.editable.states || [];

export const hasChanges = createSelector(
  editableStatesSelector,
  (states) => {
    let hasChanges = false;

    states.map((state) => {
      if (state.active) {
        hasChanges = state.hasChanges;
      }
    });

    return hasChanges;
  }
);
