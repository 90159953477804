import { MasterTableClient as AuthorizationMasterTableClient } from "@haywork/api/authorization";
import { MasterTableClient as EventCenterMasterTableClient } from "@haywork/api/event-center";
import {
  AccountSettingsClient,
  AccountSettingsSingleItemResponse,
  ActiveFilter,
  AgendaItemCategoriesClient,
  AgendaItemCategoryOrderByField,
  CompanySettingsClient,
  EmployeeRole,
  EmployeesClient,
  FinancialAdministration,
  FinancialAdministrationOrderByField,
  FinancialAdministrationsClient,
  MasterTableClient,
  RealEstateAgenciesClient,
  RelationOrderByField,
  RelationsClient,
  RelationType,
  SortOrder,
} from "@haywork/api/kolibri";
import { UserDataElement } from "@haywork/api/mail";
import { MasterTableClient as MlsMasterTableClient } from "@haywork/api/mls";
import { OIDC_CONFIG, REQUEST, SCHEDULER_OTHER } from "@haywork/constants";
import {
  AccessThunks,
  AccountThunks,
  EditableThunks,
  EventCenterThunk,
} from "@haywork/middleware";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { AnalyticsService, OidcService, ParseRequest } from "@haywork/services";
import {
  AccessActions,
  AccountActions,
  AppSettingsActions,
  AppState,
  CompanyActions,
  MainActions,
  RealEstateAgencyActions,
  SchedulerActions,
  SchedulerFilters,
  taskActionsV2,
} from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { AsyncUtil, MastertableUtil } from "@haywork/util";
import get from "lodash-es/get";
import { Dispatch } from ".";
import { AssignmentThunks } from "./assignment.thunk";
import { AuthorizationThunks } from "./authorization";
import { EmailThunk } from "./emailV2";
import { MLSThunk } from "./mls";
import { NotificationsThunk } from "./notifications";
import { RelationGroupsThunk } from "./relation-groups.thunk";
import { ReminderThunks } from "./reminder.thunk";

declare let window: ExtendedWindow;

const parseRequest = new ParseRequest();
const analytics = new AnalyticsService();

const getApplicationValues = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { role } = state.account.currentRealestateAgency;
    const { features } = state.appSettings;

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      if (role === EmployeeRole.Standard) {
        if (FeatureHelper.executeBlock(features, "EMAIL")) {
          dispatch(EmailThunk.Main.getInitialEmailState());
        }

        dispatch(ReminderThunks.getReminders());
        dispatch(EventCenterThunk.getNotificationSettings());

        if (FeatureHelper.executeBlock(features, "EVENTCENTER_V2")) {
          dispatch(NotificationsThunk.EventCenter.getStatistics());
        } else {
          dispatch(EventCenterThunk.getInitialNotifications());
        }
      }

      if (FeatureHelper.executeBlock(features, "EMAIL")) {
        dispatch(EmailThunk.Main.getSecondaryEmailState());
      }

      const agendaItemCategories = await parseRequest.response(
        AgendaItemCategories.search(
          {
            skip: 0,
            take: 100,
            order: SortOrder.Ascending,
            filterByActive: ActiveFilter.ActiveOrInactive,
            orderBy: AgendaItemCategoryOrderByField.DisplayName,
            includeStatistics: false,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        SchedulerActions.setAgendaItemCategories(agendaItemCategories, 99)
      );
      dispatch(RelationGroupsThunk.getRelationGroups());
    } catch (error) {
      throw error;
    }
  };
};

const reloadAppForRealEstateAgency = (
  realEstateAgencyId: string,
  personId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const { pathname, search } = window.location;
      const params = new URLSearchParams(search);
      const state = getState();
      const { host } = state.appSettings;
      const AccountSettings = new AccountSettingsClient(host);

      const accountSettingsResult = await parseRequest.response(
        AccountSettings.readByEmployeeId({ personId }).then(
          (response) => response.accountSettings
        )
      );

      dispatch(AccountActions.setAccountSettings(accountSettingsResult));
      dispatch(AccountActions.switchRealEstateAgency(realEstateAgencyId));

      params.delete("forRealEstateAgencyId");
      const newSearchString = params.toString();

      window.open(
        `${pathname}${newSearchString && `?${newSearchString}`}`,
        "_self"
      );
    } catch (error) {
      throw error;
    }
  };
};

const getInitialValues = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MainActions.setAppStatus(REQUEST.PENDING));

    try {
      const settings = await fetch(`${location.origin}/app.settings.json`, {
        cache: "no-cache",
      })
        .then((response) => response.json())
        .then((response) => response?.appSettings);

      const {
        API_HOST,
        VERSION,
        ENVIRONMENT,
        CLOUD_URL,
        USERSNAP_API_KEY,
        OIDC_REDIRECT_URI,
        OIDC_URI,
        OIDC_SCOPE,
        ANALYTICS_KEY,
        API_VERSION,
        EMAIL_API_VERSION,
        FEATURES,
        EMAIL_HOST,
        EVENTCENTER_HOST,
        GOOGLE_KEY,
        VOIP_HOST,
        VOIP_API_VERSION,
        AUTHORIZATION_HOST,
        BLOBS_HOST,
        MLS_HOST,
        MLS_API_VERSION,
        ITHIT_KEY,
        WOOTRIC,
        REPORTS_HOST,
        REPORTS_API_VERSION,
        STATISTICS_HOST,
        STATISTICS_API_VERSION,
      } = settings;

      dispatch(
        AppSettingsActions.setSettings({
          host: API_HOST,
          emailHost: EMAIL_HOST,
          eventCenterHost: EVENTCENTER_HOST,
          version: VERSION,
          environment: ENVIRONMENT,
          cloudUrl: CLOUD_URL,
          usersnapApiKey: USERSNAP_API_KEY,
          oidcRedirectUri: OIDC_REDIRECT_URI,
          oidcUri: OIDC_URI,
          oidcScope: OIDC_SCOPE,
          apiVersion: API_VERSION,
          emailApiVersion: EMAIL_API_VERSION,
          features: FEATURES,
          voipHost: VOIP_HOST,
          voipApiVersion: VOIP_API_VERSION,
          authorizationHost: AUTHORIZATION_HOST,
          blobsHost: BLOBS_HOST,
          mlsHost: MLS_HOST,
          mlsApiVersion: MLS_API_VERSION,
          ITHitKey: ITHIT_KEY,
          wootric: WOOTRIC,
          reportsHost: REPORTS_HOST,
          reportsApiVersion: REPORTS_API_VERSION,
          statisticsHost: STATISTICS_HOST,
          statisticsApiVersion: STATISTICS_API_VERSION,
        })
      );

      const mngr = new OidcService();
      mngr.setConfig({
        ...OIDC_CONFIG,
        authority: OIDC_URI,
        post_logout_redirect_uri: OIDC_REDIRECT_URI,
        redirect_uri: OIDC_REDIRECT_URI + "/callback.html",
        silent_redirect_uri: OIDC_REDIRECT_URI + "/renew.html",
        scope: OIDC_SCOPE,
      });

      const user = await mngr.OidcUserManager.getUser();

      if (!user || user.expired) {
        const { pathname, search } = location;
        if (!!pathname) {
          const storage = window.sessionStorage || window.localStorage;
          storage.setItem("kolibri:deeplink", pathname + search);
        }
        mngr.OidcUserManager.signinRedirect();
      } else {
        // Check if we need to redirect to backoffice account
        const params = new URLSearchParams(window.location.search);
        if (params.has("forRealEstateAgencyId")) {
          dispatch(
            reloadAppForRealEstateAgency(
              params.get("forRealEstateAgencyId"),
              user.profile.pid
            )
          );
          return;
        }

        // Add google maps to body
        if (!!GOOGLE_KEY && !get(window, "google.maps")) {
          const scriptTag = document.createElement("script");
          const lastScript = document.getElementsByTagName("script")[0];

          scriptTag.type = "text/javascript";
          scriptTag.async = true;
          scriptTag.src = `//maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_KEY}&language=nl`;
          lastScript.parentNode.insertBefore(scriptTag, lastScript);
        }

        // Set ITHIt Webdav client key
        if (!!ITHIT_KEY && !!window.ITHit) {
          const { WebDAV } = window.ITHit;
          WebDAV.Client.LicenseId = ITHIT_KEY;
        }

        dispatch(
          AccessActions.setInitial({
            employeeId: user.profile.pid,
            token: user.access_token,
          })
        );

        const state = getState();
        const { culture } = state.main;

        analytics.setUserAndAnalyticsApp(user.profile.pid, ANALYTICS_KEY);
        analytics.event("app", "init");

        const result = await dispatch(
          getInitialAppState(culture, user.profile.pid, FEATURES)
        );

        const kolibriMessages = MastertableUtil.mapValuesToTranslatables(
          result.mastertableKolibri
        );
        const eventCenterMessages = MastertableUtil.mapValuesToTranslatables(
          result.mastertableEventCenter
        );
        const authorizationMessages = MastertableUtil.mapValuesToTranslatables(
          result.mastertableAuthorization
        );
        const mlsMessages = MastertableUtil.mapValuesToTranslatables(
          result.mastertableMls
        );

        const cultureMessages = {
          ...kolibriMessages,
          ...eventCenterMessages,
          ...authorizationMessages,
          ...mlsMessages,
        };
        dispatch(MainActions.setTranslations(cultureMessages));

        dispatch(
          MainActions.setMasterTableValueKolibri(result.mastertableKolibri)
        );
        dispatch(
          MainActions.setMasterTableValueEventCenter(
            result.mastertableEventCenter
          )
        );
        dispatch(
          MainActions.setMasterTableValueAuthorization(
            result.mastertableAuthorization
          )
        );
        dispatch(MainActions.setMasterTableValueMls(result.mastertableMls));

        dispatch(
          CompanyActions.setCompanySettings(
            result.companySettings.companySettings
          )
        );
        dispatch(
          AccountActions.setAccountSettings(
            result.accountSettings.accountSettings
          )
        );
        dispatch(
          AccountActions.setEmployeesAndOffices({
            relations: result.relations.results,
          })
        );
        dispatch(AccountThunks.setEmployee(result.employee.employee));
        dispatch(taskActionsV2.List.setEmployee(result.employee.employee));
        dispatch(
          AccountActions.setShowOnboarding(
            result.accountSettings.accountSettings,
            result.employee.employee
          )
        );
        dispatch(
          AccountActions.setFinancialAdministrations({
            financialAdministrations: result.financialAdministrations,
          })
        );
        dispatch(
          RealEstateAgencyActions.setRealEstateAgency(
            result.realEstateAgency.realEstateAgency
          )
        );
        dispatch(
          setSchedulerFilters(
            result.accountSettings.accountSettings.customUserSettings
          )
        );

        if (FeatureHelper.executeBlock(FEATURES, "APP_XCHANGE")) {
          dispatch(AuthorizationThunks.Widgets.getConsentedWidgets());
        }
        dispatch(AssignmentThunks.getAssignmentTemplates());

        if (FeatureHelper.executeBlock(FEATURES, "MLS")) {
          dispatch(MLSThunk.RealEstateAgencies.getRealEstateAgency());
          const locations = await dispatch(
            MLSThunk.SearchLocations.getSearchLocations()
          );
          const defaultLocations = (locations || []).filter(
            (location) => !!location.isDefault
          );
          if (!!defaultLocations.length) {
            dispatch(
              MLSActions.List.setDefaultSearchLocations(defaultLocations)
            );
          }
        }

        dispatch(MainActions.setAppStatus(REQUEST.SUCCESS));
      }
    } catch (error) {
      dispatch(MainActions.setAppStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const setSchedulerFilters = (customUserSettings: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!customUserSettings) return;

    const state = getState();
    const { schedulerFilters, agendaItemCategories } = state.scheduler;

    const userData: UserDataElement[] = JSON.parse(customUserSettings);
    const schedulerFiltersJSON =
      userData.find((data) => data.key === SCHEDULER_OTHER.FILTER_SETTINGS_KEY)
        ?.value || "{}";
    const parsedFilters = JSON.parse(schedulerFiltersJSON);

    const filteredIds = (parsedFilters.categoryIds || []).filter((id) =>
      agendaItemCategories.some((category) => (category.id === id ? id : null))
    );

    const filtersToUse: SchedulerFilters = {
      categoryIds: filteredIds || schedulerFilters.categoryIds,
      employeeIds: parsedFilters.employeeIds || schedulerFilters.employeeIds,
      showFilters: parsedFilters.showFilters || schedulerFilters.showFilters,
      showWorkingHours:
        parsedFilters.showWorkingHours || schedulerFilters.showWorkingHours,
      startDateTimeMax: schedulerFilters.startDateTimeMax,
      startDateTimeMin: schedulerFilters.startDateTimeMin,
      view: parsedFilters.view || schedulerFilters.view,
      majorTick: parsedFilters.majorTick || schedulerFilters.majorTick,
      isCanceled: parsedFilters.isCanceled,
      isConfirmed: parsedFilters.isConfirmed,
    };

    if (filteredIds.length !== (parsedFilters.categoryIds || []).length) {
      dispatch(AccessThunks.setSchedulerFilters(filtersToUse));
    }

    dispatch(
      SchedulerActions.setSchedulerFilters({ schedulerFilters: filtersToUse })
    );
  };
};

const switchLanguage = (culture: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(MainActions.setLanguageStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const MasterTable = new MasterTableClient(host);

    try {
      const mastertable = await parseRequest.response(
        MasterTable.index(culture, realEstateAgencyId)
      );

      dispatch(MainActions.switchLanguage(culture));
      // dispatch(MainActions.switchLanguage({ culture, mastertable }));
    } catch (error) {
      dispatch(MainActions.setLanguageStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const switchRealEstateAgency = (id: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(MainActions.setAppStatus(REQUEST.PENDING));
    await dispatch(EditableThunks.clearAllTabs());
    dispatch(AccountActions.switchRealEstateAgency(id));
    await AsyncUtil.wait(500);
    window.open("/", "_self");
  };
};

const getInitialAppState = (
  culture: string,
  personId: string,
  features: string[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, eventCenterHost, authorizationHost, mlsHost } =
      state.appSettings;

    const MasterTable = new MasterTableClient(host);
    const Employees = new EmployeesClient(host);
    const CompanySettings = new CompanySettingsClient(host);
    const RealEstateAgencies = new RealEstateAgenciesClient(host);
    const Relations = new RelationsClient(host);
    const AccountSettings = new AccountSettingsClient(host);
    const FinancialAdministrations = new FinancialAdministrationsClient(host);
    const EventCenterMasterTable = new EventCenterMasterTableClient(
      eventCenterHost
    );
    const AuthorizationMasterTable = new AuthorizationMasterTableClient(
      authorizationHost
    );
    const MlsMasterTable = new MlsMasterTableClient(mlsHost);

    try {
      let accountSettingsResult: AccountSettingsSingleItemResponse;

      if (
        state.account.currentRealestateAgency &&
        state.account.currentRealestateAgency.role === EmployeeRole.BackOffice
      ) {
        accountSettingsResult = await parseRequest.response(
          AccountSettings.readByEmployeeId2(
            { personId },
            state.account.currentRealestateAgency.id
          )
        );
      } else {
        accountSettingsResult = await parseRequest.response(
          AccountSettings.readByEmployeeId({ personId })
        );
      }

      const realEstateAgencyId = !!get(
        state.account.currentRealestateAgency,
        "id"
      )
        ? state.account.currentRealestateAgency.id
        : accountSettingsResult.accountSettings.realEstateAgencyId;

      const mastertable = parseRequest.response(
        MasterTable.index(culture, realEstateAgencyId)
      );

      const eventCenterMastertable = parseRequest.response(
        EventCenterMasterTable.index(culture, realEstateAgencyId)
      );

      let authorizationMastertable;
      if (FeatureHelper.executeBlock(features, "APP_XCHANGE")) {
        authorizationMastertable = parseRequest.response(
          AuthorizationMasterTable.index(culture, realEstateAgencyId)
        );
      } else {
        authorizationMastertable = new Promise((resolve) => resolve({}));
      }

      let mlsMastertable;
      if (FeatureHelper.executeBlock(features, "MLS")) {
        mlsMastertable = parseRequest.response(
          MlsMasterTable.index(culture, realEstateAgencyId)
        );
      } else {
        mlsMastertable = new Promise((resolve) => resolve({}));
      }

      const companySettings = parseRequest.response(
        CompanySettings.read(realEstateAgencyId)
      );
      const realEstateAgency = parseRequest.response(
        RealEstateAgencies.read(realEstateAgencyId)
      );
      const employee = parseRequest.response(
        Employees.read(
          personId,
          accountSettingsResult.accountSettings.realEstateAgencyId
        )
      );
      const relations = parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office],
          },
          realEstateAgencyId
        )
      );

      const financialAdministration: Promise<FinancialAdministration[]> =
        new Promise(async (resolve, reject) => {
          const financialAdministrations = await parseRequest.response(
            FinancialAdministrations.search(
              {
                orderBy: FinancialAdministrationOrderByField.Name,
                filterByActive: ActiveFilter.ActiveOnly,
                skip: 0,
                take: 100,
                order: SortOrder.Ascending,
              },
              realEstateAgencyId
            ).then((response) => response.results)
          );

          const promises = financialAdministrations.map((res) => {
            return parseRequest.response(
              FinancialAdministrations.read(res.id, realEstateAgencyId)
            );
          });

          Promise.all(promises)
            .then((result) => {
              resolve(result.map((res) => res.financialAdministration));
            })
            .catch((err) => reject(err));
        });

      const results = await Promise.all([
        mastertable,
        eventCenterMastertable,
        authorizationMastertable,
        mlsMastertable,
        companySettings,
        employee,
        relations,
        financialAdministration,
        realEstateAgency,
      ]);

      return {
        mastertableKolibri: results[0],
        mastertableEventCenter: results[1],
        mastertableAuthorization: results[2],
        mastertableMls: results[3],
        companySettings: results[4],
        employee: results[5],
        relations: results[6],
        financialAdministrations: results[7],
        realEstateAgency: results[8],
        mastertable: { ...results[0], ...results[1], ...results[2] },
        accountSettings: accountSettingsResult,
      };
    } catch (error) {
      throw error;
    }
  };
};

export const MainThunks = {
  getInitialValues,
  switchLanguage,
  getApplicationValues,
  switchRealEstateAgency,
};
