import {
  AcquisitionAssignment,
  RelationRoleOption,
} from "@haywork/api/kolibri";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { AppState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { push } from "connected-react-router";
import {
  AssignmentDetailNetworkComponent,
  AssignmentDetailNetworkComponentProps,
} from "./detail-network.component";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";

interface StateProps {
  currentComponentState: AcquisitionAssignment;
  relationRoles: RelationRoleOption[];
  realEstateAgencyId: string;
  host: string;
  path: string;
  acquisitionStatus: string;
}
interface DispatchProps {
  navigate: (url: string) => void;
  saveAcquisition: (acquisition: AcquisitionAssignment) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailNetworkComponentProps,
  AppState
> = (state) => {
  const { acquisitionStatus } = state.acquisition.single;
  const currentComponentState: AcquisitionAssignment =
    state.editable.currentComponentState;
  const { relationRoles } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const path = RouteUtil.mapStaticRouteValues(ACQUISITIONROUTES.DETAIL.URI, {
    id: currentComponentState.id,
  });

  return {
    currentComponentState,
    relationRoles,
    realEstateAgencyId,
    host,
    path,
    acquisitionStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailNetworkComponentProps
> = (dispatch) => ({
  navigate: (url: string) => dispatch(push(url)),
  saveAcquisition: (acquisition: AcquisitionAssignment) =>
    dispatch(AcquisitionThunks.Acquisition.saveAcquisition(acquisition, false)),
});

export type AssignmentDetailNetworkContainerProps = StateProps & DispatchProps;
export const AssignmentDetailNetworkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailNetworkComponent);
