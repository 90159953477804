import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  DuplicateModalComponent,
  DuplicateModalComponentProps
} from "./duplicate-modal";

type StateProps = {};
type DispatchProps = {
  duplicateAssignment: (
    id: string,
    shouldArchive: boolean,
    duplicateAsForSale: boolean,
    shouldCopyKeyNumber: boolean,
    shouldClearKeyNumber: boolean
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DuplicateModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DuplicateModalComponentProps
> = (dispatch) => ({
  duplicateAssignment: (
    id: string,
    shouldArchive: boolean,
    duplicateAsForSale: boolean,
    shouldCopyKeyNumber: boolean,
    shouldClearKeyNumber: boolean
  ) =>
    dispatch(
      AssignmentThunks.duplicateAssignment(
        id,
        shouldArchive,
        duplicateAsForSale,
        shouldCopyKeyNumber,
        shouldClearKeyNumber
      )
    )
});

export type DuplicateModalContainerProps = StateProps & DispatchProps;
export const DuplicateModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateModalComponent);
