import * as React from "react";
import * as CSSModules from "react-css-modules";

import { FormControlUtil } from "@haywork/util";

const styles = require("./option-list.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

const ListItem = ({ item, displayPath, valuePath, onChange }) => {
  const val = !!valuePath ? value(item, valuePath) : item;
  const display = !!displayPath ? value(item, displayPath) : item;

  return (
    <div styleName="list-item" onClick={() => onChange(val)}>
      {display}
    </div>
  );
};

export const ListItemComponent = CSSModules(ListItem, styles, {
  allowMultiple: true
});
