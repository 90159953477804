import {
  TemplateDefinitionCategoriesClient,
  TemplateDefinitionsClient,
  TemplateDefinitionOrderByField,
  TemplateDefinitionCategoryOrderByField,
  SortOrder,
  ActiveFilter,
  TemplateDefinitionCategoryType,
  TemplateDefinitionSnapShot,
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { AppState, EditableActions } from "@haywork/stores";
import { Dispatch } from "../";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { EMAILTEMPLATESROUTES, MAINROUTES, COUNTS } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getInitialTemplatesState = () => {
  return (dispatch: Dispatch<any>) => {
    try {
      dispatch(getTemplateCategories());
      dispatch(getTemplateMergeFields());
      dispatch(getTemplates());
    } catch (error) {
      throw error;
    }
  };
};

const getTemplateCategories = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new TemplateDefinitionCategoriesClient(host);

      const categories = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 100,
              orderBy: TemplateDefinitionCategoryOrderByField.Name,
              order: SortOrder.Ascending,
              filterByActive: ActiveFilter.ActiveOnly,
            },
            realEstateAgencyId
          )
          .then((response) => response.results || [])
      );

      dispatch(EmailActionsV2.Templates.setTemplateCategories(categories));

      return categories;
    } catch (error) {
      throw error;
    }
  };
};

const getTemplateMergeFields = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { culture } = state.main;
      const client = new TemplateDefinitionsClient(host);

      const mergeFields = await parseRequest.response(
        client
          .searchMergeFields({ culture }, realEstateAgencyId)
          .then((response) => response.mergeFields || [])
      );

      dispatch(EmailActionsV2.Templates.setTemplateMergeFields(mergeFields));

      return mergeFields;
    } catch (error) {
      throw error;
    }
  };
};

const getTemplates = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new TemplateDefinitionsClient(host);
      const take = 100;

      const request = {
        skip: 0,
        take,
        orderBy: TemplateDefinitionOrderByField.Name,
        order: SortOrder.Ascending,
        filterByActive: ActiveFilter.ActiveOnly,
      };

      const response = await parseRequest.response(
        client.search(request, realEstateAgencyId)
      );

      if (!response) throw new Error("E-mail templates request failed");
      const { totalResults, resultCount, results } = response;
      let templates = results || [];

      if (totalResults > resultCount) {
        const diff = totalResults - resultCount;
        const count = Math.ceil(diff / take);
        const promises: Promise<TemplateDefinitionSnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            client
              .search(
                {
                  ...request,
                  skip: i * COUNTS.EMAIL_ACCOUNTS,
                },
                realEstateAgencyId
              )
              .then((response) => response.results)
          );
        }

        const response = await Promise.all(promises);
        const results = response.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <TemplateDefinitionSnapShot[]>[]);

        templates = [...templates, ...results];
      }

      dispatch(EmailActionsV2.Templates.setTemplates(templates));

      return templates;
    } catch (error) {
      throw error;
    }
  };
};

const defineNewTemplate = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { categories } = state.emailV2.templates;
      const client = new TemplateDefinitionsClient(host);

      const category = categories.find(
        (category) =>
          category.categoryType === TemplateDefinitionCategoryType.EmailTemplate
      );

      const templateDefinition = await parseRequest.response(
        client
          .defineNew(
            {
              templateDefinitionCategoryId: category.id,
            },
            realEstateAgencyId
          )
          .then((response) => response.templateDefinition || null)
      );
      if (!templateDefinition)
        throw new Error("Template definition could not be created");

      const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
        id: templateDefinition.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAILTEMPLATE.ICON,
          componentState: templateDefinition,
          path,
          title: "...",
          confirm: {
            title: { key: "emailTemplateConfirmTitle" },
            body: { key: "emailTemplateConfirmBody" },
          },
          entityType: null,
          entityId: templateDefinition.id,
        })
      );
      dispatch(push(path));

      return templateDefinition;
    } catch (error) {
      throw error;
    }
  };
};

const getTemplate = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new TemplateDefinitionsClient(host);

      const templateDefinition = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.templateDefinition || null)
      );
      if (!templateDefinition)
        throw new Error("Template definition could not be found");

      return templateDefinition;
    } catch (error) {
      throw error;
    }
  };
};

const getEditableTemplate = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
        id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAILTEMPLATE.ICON,
          componentState: null,
          path,
          title: "...",
          entityType: null,
          entityId: id,
        })
      );

      const templateDefinition = await dispatch(getTemplate(id));

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: templateDefinition,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getInitialTemplatesState,
  getTemplateCategories,
  getTemplateMergeFields,
  getTemplates,
  defineNewTemplate,
  getTemplate,
  getEditableTemplate,
};
