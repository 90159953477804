import { ActionType, ActionTypes, MlsAccessData } from "./actions";
import { REQUEST } from "@haywork/constants";

export type State = {
  mlsAccessData: MlsAccessData;
  requestStatus: string;
};

const INITIAL: State = {
  mlsAccessData: null,
  requestStatus: REQUEST.IDLE,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetAccessData: {
      const { mlsAccessData } = action;

      return {
        ...state,
        mlsAccessData,
        requestStatus: REQUEST.SUCCESS,
      };
    }
    case ActionType.SetRequestStatus: {
      const { requestStatus } = action;

      return {
        ...state,
        requestStatus,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
