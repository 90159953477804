import { EventNotification, EventType } from "@haywork/api/event-center";
import { SCHEDULERROUTES, TASKROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Form, FormControls, Input } from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import {
  ReminderModalContainerProps,
  ReminderModalListItemComponent,
} from "@haywork/modules/reminder";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./reminder-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ReminderModalComponentProps {}
interface State {
  selectedItem: EventNotification;
  selectedReminderMinutes: number;
}
type Props = ReminderModalComponentProps & ReminderModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ReminderModalComponent extends React.Component<Props, State> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.itemSelected = this.itemSelected.bind(this);
    this.onSubItemClick = this.onSubItemClick.bind(this);
    this.openItem = this.openItem.bind(this);
    this.deleteReminder = this.deleteReminder.bind(this);
    this.suspendAllReminders = this.suspendAllReminders.bind(this);

    this.state = {
      selectedItem: null,
      selectedReminderMinutes: 15,
    };

    this.formControls = {
      reminderMinute: {
        value: this.state.selectedReminderMinutes,
        onChange: (ref) => {
          this.setState({ selectedReminderMinutes: ref.value });
        },
      },
    };
  }

  public render() {
    return (
      <Modal
        visible={this.props.showReminders}
        className="reminderModal"
        onClose={this.props.clearAllReminders}
      >
        <ModalHeader close={true} title="reminders" />
        <ModalBody noPadding={true}>
          <div styleName="body">
            {this.props.reminders.map((reminder, idx) => {
              return (
                <ErrorBoundary key={idx}>
                  <ReminderModalListItemComponent
                    zebra={idx % 2 === 0}
                    reminder={reminder}
                    onSelect={this.itemSelected}
                    isSelected={
                      this.state.selectedItem &&
                      reminder.id === this.state.selectedItem.id
                    }
                    culture={this.props.culture}
                    agendaItemCategories={this.props.agendaItemCategories}
                    taskCategories={this.props.taskCategories}
                    onSubItemClick={this.onSubItemClick}
                  />
                </ErrorBoundary>
              );
            })}
            <div styleName="buttons">
              <button
                className="btn btn-link"
                onClick={this.props.deleteAllReminders}
              >
                <ResourceText resourceKey="deleteReminders" />
              </button>
              <div>
                <button
                  className={
                    !this.state.selectedItem
                      ? "btn btn-disabled"
                      : "btn btn-primary"
                  }
                  disabled={!this.state.selectedItem}
                  onClick={this.openItem}
                >
                  {this.state.selectedItem &&
                  this.state.selectedItem.linkedEvent.eventType ===
                    EventType.TaskReminder ? (
                    <ResourceText resourceKey="openTaskItem" />
                  ) : (
                    <ResourceText resourceKey="openAgendaItem" />
                  )}
                </button>
                <button
                  className={
                    !this.state.selectedItem
                      ? "btn btn-disabled"
                      : "btn btn-danger"
                  }
                  disabled={!this.state.selectedItem}
                  onClick={this.deleteReminder}
                >
                  <ResourceText resourceKey="deleteReminder" />
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style="flex">
          <div styleName="footer-options">
            <div styleName="suspend">
              <span className="hidden-xs">
                <ResourceText resourceKey="clickToSuspend" />
              </span>
              <Form name="reminderForm" formControls={this.formControls}>
                <Input.NewSelect
                  name="reminderMinute"
                  values={this.props.reminderMinutesOptions}
                  displayProp="displayName"
                  valuesProp="nrOfMinutes"
                />
              </Form>
            </div>
            <div styleName="actions">
              <button
                className="btn btn-primary"
                onClick={this.suspendAllReminders}
              >
                <ResourceText resourceKey="suspendReminder" />
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private itemSelected(selectedItem: EventNotification) {
    this.setState({ selectedItem });
  }

  private openItem() {
    const { linkedEvent } = this.state.selectedItem;
    let routePath, id;

    switch (linkedEvent.eventType) {
      case EventType.AgendaItemReminder: {
        const reminders = linkedEvent.linkedAgendaItems || [];
        const reminder = first(reminders);

        id = reminder.id;
        routePath = SCHEDULERROUTES.SCHEDULER_DETAIL.URI;
        break;
      }
      case EventType.TaskReminder: {
        const reminders = linkedEvent.linkedTasks || [];
        const reminder = first(reminders);

        id = reminder.id;
        routePath = TASKROUTES.TASK.URI;
        break;
      }
      default:
        return;
    }

    this.props.deleteReminder(id);
    this.props.navigate(route(routePath, { id }));
    this.props.clearAllReminders();
  }

  private deleteReminder() {
    const { linkedEvent } = this.state.selectedItem;
    let id;

    switch (linkedEvent.eventType) {
      case EventType.AgendaItemReminder: {
        const reminders = linkedEvent.linkedAgendaItems || [];
        const reminder = first(reminders);

        id = reminder.id;
        break;
      }
      case EventType.TaskReminder: {
        const reminders = linkedEvent.linkedTasks || [];
        const reminder = first(reminders);

        id = reminder.id;
        break;
      }
      default:
        return;
    }

    this.props.deleteReminder(id);
    this.setState({ selectedItem: null });
  }

  private suspendAllReminders() {
    if (this.state.selectedReminderMinutes === -1) {
      this.props.deleteAllReminders();
    } else {
      this.props.rescheduleAllReminders(this.state.selectedReminderMinutes);
    }
  }

  private onSubItemClick() {
    this.props.clearAllReminders();
  }
}
