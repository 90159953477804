import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";

import * as ActionType from "./scroller-wrapper.types";

export interface ScrollPositionState {
  [key: string]: number;
}

const INITIAL_STATE: ScrollPositionState = {};

export const scrollPositionReducer = (
  state: ScrollPositionState = INITIAL_STATE,
  action: Action
): ScrollPositionState => {
  switch (action.type) {
    case REDUX.SCROLLPOSITION.SET_DYNAMIC_SCROLL_POSITION: {
      const { key, value } = <ActionType.DynamicScrollPosition>action;
      return {
        ...state,
        [key]: value,
      };
    }

    default: {
      return state;
    }
  }
};
