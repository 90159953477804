import {
  AgendaItemsClient,
  AgendaItemOrderByField,
  SortOrder,
  ActiveFilter,
  AgendaItemCategoriesClient,
  AgendaItemsSearchResponse,
} from "@haywork/api/kolibri";
import { AppState } from "@haywork/stores";
import { BackOfficeUtil } from "@haywork/util";
import { ParseRequest, ApiType } from "@haywork/services";

const parseRequest = new ParseRequest();

const search = async (state: AppState, skip: number = 0) => {
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const AgendaItems = new AgendaItemsClient(host);

  try {
    let { employeeIds } = state.scheduler.schedulerFilters;
    if (!employeeIds || employeeIds.length === 0) {
      employeeIds = [state.access.employeeId];
    }

    return await parseRequest.response(
      AgendaItems.search(
        {
          employeeIds,
          categoryIds: state.scheduler.schedulerFilters.categoryIds,
          periodDateTimeMin: state.scheduler.schedulerFilters.startDateTimeMin,
          periodDateTimeMax: state.scheduler.schedulerFilters.startDateTimeMax,
          orderBy: AgendaItemOrderByField.StartDateTime,
          skip,
          take: 99,
          order: SortOrder.Ascending,
          filterByActive: ActiveFilter.ActiveOrInactive,
          isCanceled: state.scheduler.schedulerFilters.isCanceled,
          isConfirmed: state.scheduler.schedulerFilters.isConfirmed,
        },
        realEstateAgencyId
      )
    );
  } catch (error) {
    throw error;
  }
};

const getAgendaItemCategory = async (
  realEstateAgencyId: string,
  culture: string,
  categoryId: string,
  host: string
) => {
  const AgendaItemCategories = new AgendaItemCategoriesClient(host);

  try {
    return await parseRequest.response(
      AgendaItemCategories.read(categoryId, realEstateAgencyId)
    );
  } catch (error) {
    throw error;
  }
};

const searchForAgendaItemsToday = async (
  state: AppState,
  returnOccurencesOfRecurringAgendaItems?: boolean
) => {
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const AgendaItems = new AgendaItemsClient(host);

  const employeeId = BackOfficeUtil.getEmployeeId(state.account);
  if (!employeeId) {
    return {
      resultCount: 0,
      results: [],
      totalResults: 0,
    } as AgendaItemsSearchResponse;
  }

  try {
    const employeeIds: string[] = [employeeId];
    const endOfToday = new Date();

    endOfToday.setHours(23, 59, 59, 999);

    return await parseRequest.response(
      AgendaItems.search(
        {
          employeeIds,
          order: SortOrder.Ascending,
          orderBy: AgendaItemOrderByField.StartDateTime,
          periodDateTimeMin: new Date(),
          periodDateTimeMax: endOfToday,
          skip: 0,
          take: 3,
          isCanceled: false,
          filterByActive: ActiveFilter.ActiveOnly,
          returnOccurencesOfRecurringAgendaItems,
        },
        realEstateAgencyId
      )
    );
  } catch (error) {
    throw error;
  }
};

export const Scheduler = {
  search,
  getAgendaItemCategory,
  searchForAgendaItemsToday,
};
