import BidWidgetForObjectAssignment from "./components/object-widget";

export enum BidAction {
  Edit = "Edit",
  Reject = "Reject",
  CounterOffer = "CounterOffer",
  Accept = "Accept",
  ShowAllBids = "ShowAllBids",
  Delete = "Delete"
}

export { BidWidgetForObjectAssignment };
