import {
  FolderTree,
  LinkedAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { File } from "@haywork/api/mail";
import { EMAIL_ATTACHMENTS_BLACKLIST } from "@haywork/constants";
import { Modal, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import CheckView from "./components/check";
import SelectView from "./components/select";
import { SaveFilesToDossierModalContainerProps } from "./save-files-to-dossier-modal.container";
import last from "lodash-es/last";

export type SaveFilesToDossierModalComponentProps = {
  visible: boolean;
  files: File[];
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  onClose: () => void;
};
type Props = SaveFilesToDossierModalComponentProps &
  SaveFilesToDossierModalContainerProps;

export const SaveFilesToDossierModalComponent: FC<Props> = memo(
  ({
    visible,
    files,
    linkedRelations,
    linkedAssignments,
    onClose,
    saveUrisToDossier,
  }) => {
    const [view, setView] = useState<"check" | "select">("check");
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(files || []);
    const [selectedFolderName, setSelectedFolderName] = useState("");

    const resetStateAndClose = useCallback(() => {
      onClose();
      setView("check");
      setSelectedFiles([]);
      setSelectedFolderName("");
    }, [onClose, setView, setSelectedFiles, setSelectedFolderName]);

    const onCloseHandler = useCallback(() => {
      if (loading) return;
      resetStateAndClose();
    }, [loading, resetStateAndClose]);

    const onUseFiles = useCallback(() => {
      setView("select");
    }, [setView]);

    const onSaveToFolder = useCallback(
      async (folderTree: FolderTree) => {
        try {
          setLoading(true);
          const files = selectedFiles
            .filter((f) => !!f)
            .filter((file) => {
              if (!file.fileName) {
                return true;
              }
              const segments = file.fileName.split(".");
              if (!segments.length) {
                return true;
              }

              return !EMAIL_ATTACHMENTS_BLACKLIST.includes(
                last(segments).toUpperCase()
              );
            });
          await saveUrisToDossier(files, folderTree, selectedFolderName);
          resetStateAndClose();
        } finally {
          setLoading(false);
        }
      },
      [setLoading, resetStateAndClose, files, selectedFolderName]
    );

    useEffect(() => {
      setSelectedFiles(files);
    }, [files, setSelectedFiles]);

    useEffect(() => {
      if (view === "check" && selectedFiles.length === 1) {
        setView("select");
      }
    }, [view, selectedFiles, setView]);

    const renderedView = useMemo(() => {
      switch (view) {
        case "check": {
          return (
            <CheckView
              files={selectedFiles || []}
              onSetFiles={setSelectedFiles}
              onUseFiles={onUseFiles}
            />
          );
        }
        case "select": {
          return (
            <SelectView
              linkedRelations={linkedRelations}
              linkedAssignments={linkedAssignments}
              selectedFolderName={selectedFolderName}
              loading={loading}
              onSelectFolderName={setSelectedFolderName}
              onSaveToFolder={onSaveToFolder}
            />
          );
        }
        default:
          return null;
      }
    }, [
      view,
      selectedFiles,
      loading,
      linkedRelations,
      linkedAssignments,
      onUseFiles,
      selectedFolderName,
      setSelectedFolderName,
    ]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title={`dossier.saveFilesModal.title.${view}`} close />
        {renderedView}
      </Modal>
    );
  }
);
