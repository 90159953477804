import {
  AtticOptionOption,
  BathroomFacilityOption,
  FloorTypeOption,
  KitchenFacilityOption,
  KitchenTypeOption,
  LivingRoomTypeOption,
  OrientationOption,
  SpaceTypeOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { ExtendedFloor } from "./";
import {
  FloorsAndSpacesComponent,
  FloorsAndSpacesComponentProps,
} from "./floors-and-spaces.component";
import { canEditMainFloors, floors } from "./selectors";

interface StateProps {
  floorTypeOptions: FloorTypeOption[];
  atticOptions: AtticOptionOption[];
  livingRoomTypes: LivingRoomTypeOption[];
  bathroomFacilities: BathroomFacilityOption[];
  kitchenTypes: KitchenTypeOption[];
  orientations: OrientationOption[];
  spaceTypes: SpaceTypeOption[];
  floors: ExtendedFloor[];
  kitchenFacilityOptions: KitchenFacilityOption[];
  canEditMainFloors: boolean;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  FloorsAndSpacesComponentProps,
  AppState
> = (state, ownProps) => {
  const {
    floorTypeOptions,
    atticOptions,
    livingRoomTypes,
    bathroomFacilities,
    kitchenTypes,
    orientations,
    spaceTypes,
    kitchenFacilityOptions,
  } = state.main.mastertable.kolibri;

  return {
    floorTypeOptions,
    atticOptions,
    livingRoomTypes,
    bathroomFacilities,
    kitchenTypes,
    orientations,
    spaceTypes,
    kitchenFacilityOptions,
    floors: floors(ownProps.objectAssignment),
    canEditMainFloors: canEditMainFloors(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FloorsAndSpacesComponentProps
> = (dispatch) => ({});

export type FloorsAndSpacesContainerProps = StateProps & DispatchProps;
export const FloorsAndSpacesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FloorsAndSpacesComponent);
