import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";

const acquisitionStatusOptionsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.acquisitionStatusOptions;

export const acquisitionStatusOptions = (
  assignment: AcquisitionObjectAssignment
) =>
  createSelector(acquisitionStatusOptionsSelector, (options) => {
    return options.filter((option) =>
      !!assignment && assignment.forRent ? option.isForRent : option.isForSale
    );
  });
