import { AssignmentType, LinkedAssignment } from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
} from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

interface Props {
  linkedAssignment: LinkedAssignment;
  onClick?: () => void;
}
interface State {}

const styles = require("../list-item/style.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class LinkedAssignmentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onStopPropagation = this.onStopPropagation.bind(this);
  }

  public render() {
    const { displayName, id, typeOfAssignment } = this.props.linkedAssignment;
    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return (
      <Link to={path} onClick={this.onStopPropagation}>
        <span styleName="linkedAssignment">
          <i className="fal fa-fw fa-folder" />
          {displayName}
        </span>
      </Link>
    );
  }

  private onStopPropagation(event: React.MouseEvent<any>) {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
