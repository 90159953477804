import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import { MapDispatchToProps, VoipThunk } from "@haywork/middleware";
import { AddManualCommunicationLogRequest } from "@haywork/middleware/thunk/voip/calls.thunk";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AddCommunicationLogComponent,
  AddCommunicationLogComponentProps
} from "./add-communication-log";

interface StateProps {
  employee: LinkedRelation;
}
interface DispatchProps {
  addManualCommunicationLog: (
    request: AddManualCommunicationLogRequest
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AddCommunicationLogComponentProps,
  AppState
> = (state) => {
  const { id, displayName } = state.account.employee;

  return {
    employee: {
      id,
      displayName,
      typeOfRelation: RelationType.Employee
    }
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AddCommunicationLogComponentProps
> = (dispatch) => ({
  addManualCommunicationLog: (request: AddManualCommunicationLogRequest) =>
    dispatch(VoipThunk.Calls.addManualCommunicationLog(request))
});

export type AddCommunicationLogContainerProps = StateProps & DispatchProps;
export const AddCommunicationLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCommunicationLogComponent);
