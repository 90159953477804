import {
  AgendaItemCategorySnapShot,
  TaskCategoryOption,
  TimelineActionType,
  TimelineEvent,
  CommunicationLog,
} from "@haywork/api/kolibri";
import { CommunicationLogThunk, MapDispatchToProps } from "@haywork/middleware";
import { TimelineEventThunk } from "@haywork/middleware/thunk/timeline-events";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { TimelineComponent, TimelineComponentProps } from "./timeline";

type StateProps = {
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
};
type DispatchProps = {
  getTimelineEvents: (
    startIndex: number,
    stopIndex: number,
    assignmentId?: string,
    relationId?: string,
    filterByActionTypes?: TimelineActionType[],
    includeTimelineEventsOfChildItems?: boolean
  ) => Promise<{
    events: TimelineEvent[];
    total: number;
  }>;
  navigate: (path: string) => RouterAction;
  deleteCommunicationLog: (
    id: string,
    undeleteCallback: () => void
  ) => Promise<CommunicationLog>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  TimelineComponentProps,
  AppState
> = (state) => {
  const { taskCategories } = state.main.mastertable.kolibri;
  const { agendaItemCategories } = state.scheduler;

  return {
    taskCategories,
    agendaItemCategories,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  TimelineComponentProps
> = (dispatch) => ({
  getTimelineEvents: (
    startIndex: number,
    stopIndex: number,
    assignmentId?: string,
    relationId?: string,
    filterByActionTypes?: TimelineActionType[],
    includeTimelineEventsOfChildItems?: boolean
  ) =>
    dispatch(
      TimelineEventThunk.getTimelineEvents(
        startIndex,
        stopIndex,
        assignmentId,
        relationId,
        filterByActionTypes,
        includeTimelineEventsOfChildItems
      )
    ),
  navigate: (path: string) => dispatch(push(path)),
  deleteCommunicationLog: (id: string, undeleteCallback: () => void) =>
    dispatch(
      CommunicationLogThunk.deleteCommunicationLog(
        id,
        undefined,
        undeleteCallback
      )
    ),
});

export type TimelineContainerProps = StateProps & DispatchProps;
export const TimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineComponent);
