import {
  ActiveFilter,
  AgendaItemCategoriesClient,
  AgendaItemCategory,
  AgendaItemCategoryOrderByField,
  AgendaItemCategorySnapShot,
  AgendaItemCategoryType,
  AgendaStandardDuration,
  SortOrder,
} from "@haywork/api/kolibri";
import {
  MAINROUTES,
  REQUEST,
  SCHEDULERCATEGORIESROUTES,
} from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  SchedulerCategoryActions,
} from "@haywork/stores";
import { RouteUtil, SchedulerCategoryUtil } from "@haywork/util";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getCategories = (init: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SchedulerCategoryActions.setSchedulerCategoriesStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      const {
        results: agendaItemCategories,
        statistics: agendaItemCategoryStatistics,
      } = await parseRequest.response(
        AgendaItemCategories.search(
          {
            skip: 0,
            take: 99,
            order: SortOrder.Ascending,
            filterByActive: ActiveFilter.ActiveOnly,
            orderBy: AgendaItemCategoryOrderByField.ParentType,
            includeStatistics: true,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        SchedulerCategoryActions.setSchedulerCategories({
          agendaItemCategories,
          agendaItemCategoryStatistics,
        })
      );
    } catch (error) {
      dispatch(
        SchedulerCategoryActions.setSchedulerCategoriesStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const readCategory = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SchedulerCategoryActions.setAgendaItemCategoryStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(SCHEDULERCATEGORIESROUTES.DETAIL.URI, {
      id,
    });

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    let componentState = null;

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.SCHEDULERCATEGORY.ICON,
        componentState,
        path,
        title: "...",
        entityType: null,
        entityId: id,
      })
    );

    try {
      const agendaItemCategory = await parseRequest.response(
        AgendaItemCategories.read(id, realEstateAgencyId).then(
          (response) => response.agendaItemCategory
        )
      );

      componentState = agendaItemCategory;

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );

      dispatch(
        SchedulerCategoryActions.setAgendaItemCategory({ agendaItemCategory })
      );
    } catch (error) {
      throw error;
    }
  };
};

const saveCategoryFromSnapshot = (category: AgendaItemCategorySnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { mastertable } = state.main;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const standardDuration = mastertable.kolibri.agendaStandardDurationOptions.find(
      (standardDuration) =>
        standardDuration.durationInMinutes ===
        category.standardDurationInMinutes
    );

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      let agendaItemCategory = await parseRequest.response(
        AgendaItemCategories.read(category.id, realEstateAgencyId).then(
          (response) => response.agendaItemCategory
        )
      );

      agendaItemCategory = {
        ...agendaItemCategory,
        displayName: category.displayName,
        backColor: category.backColor,
        standardDuration: standardDuration
          ? standardDuration.value
          : AgendaStandardDuration.OneHour,
        categoryType: category.categoryType,
        useReminder: category.useReminder,
        needsToBeConfirmed: category.needsToBeConfirmed,
      };

      await parseRequest.response(
        AgendaItemCategories.save({ agendaItemCategory }, realEstateAgencyId)
      );

      dispatch(getCategories());
    } catch (error) {
      throw error;
    }
  };
};

const saveCategory = (agendaItemCategory: AgendaItemCategory) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(SCHEDULERCATEGORIESROUTES.DETAIL.URI, {
      id: agendaItemCategory.id,
    });

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      await parseRequest.response(
        AgendaItemCategories.save({ agendaItemCategory }, realEstateAgencyId)
      );

      await dispatch(getCategories());
      dispatch(EditableThunks.remove(path));
    } catch (error) {
      throw error;
    }
  };
};

const newCategory = (
  backColor: string,
  displayName: string,
  standardDuration: AgendaStandardDuration,
  needsToBeConfirmed: boolean,
  categoryType: AgendaItemCategoryType
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { mastertable } = state.main;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      let agendaItemCategorySnapShot;
      let agendaItemCategory = await parseRequest.response(
        AgendaItemCategories.defineNew({}, realEstateAgencyId).then(
          (response) => response.agendaItemCategory
        )
      );

      agendaItemCategory = {
        ...agendaItemCategory,
        displayName,
        backColor,
        standardDuration,
        needsToBeConfirmed,
        categoryType,
      };

      agendaItemCategorySnapShot = SchedulerCategoryUtil.convertToSnapShot(
        agendaItemCategory,
        mastertable.kolibri.agendaStandardDurationOptions
      );

      await parseRequest.response(
        AgendaItemCategories.save({ agendaItemCategory }, realEstateAgencyId)
      );

      await dispatch(getCategories());
      dispatch(
        SchedulerCategoryActions.addSchedulerCategory({
          agendaItemCategory: agendaItemCategorySnapShot,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const deleteCategory = (
  categoryToDelete: AgendaItemCategorySnapShot,
  categoryToMoveItemsTo?: AgendaItemCategorySnapShot
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const AgedaItemCategories = new AgendaItemCategoriesClient(host);

    try {
      if (categoryToMoveItemsTo) {
        await parseRequest.response(
          AgedaItemCategories.moveLinkedAgendaItems(
            {
              oldCategoryId: categoryToDelete.id,
              newCategoryId: categoryToMoveItemsTo.id,
            },
            realEstateAgencyId
          )
        );
      }

      await parseRequest.response(
        AgedaItemCategories.delete(categoryToDelete.id, realEstateAgencyId)
      );

      dispatch(
        SchedulerCategoryActions.deleteSchedulerCategory({
          id: categoryToDelete.id,
        })
      );
    } catch (error) {
      dispatch(
        SchedulerCategoryActions.setSchedulerCategoriesStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

export const SchedulerCategoriesThunk = {
  getCategories,
  readCategory,
  saveCategoryFromSnapshot,
  saveCategory,
  newCategory,
  deleteCategory,
};
