import {
  UiTableComponent,
  uiTableRow,
  UiTableRowProps,
  UiTableColumnConfig
} from "./table";
import { UiOnboardingContainer } from "./onboarding";
import {
  UiEmptyStateComponent,
  UiEmptyStateStickMan,
  UiEmptyStateType
} from "./empty-state";
import {
  UiPhrasesContainer,
  EditModalComponent as UiPhrasesEditModal,
  UiPhrasesModalContainer as UiPhrasesModal
} from "./phrases";
import { UiWindowFrameContainer } from "./window-frame";
import { UiOptionListComponent, UiOptionListAlignment } from "./option-list";
import { UiActionListItemComponent } from "./action-list-item";
import { Loaders } from "./loaders";
import { UiRequiredForPublish } from "./required-for-publish/required-for-publish.component";
import { UiScoreComponent, UiScoreSize } from "./score/score.component";
import { UiImportantHint } from "./important-hint/important-hint.component";
import HighlightFeature from "./highlight-feature";
import InfoLink from "./info-link";

export const Ui = {
  Onboarding: UiOnboardingContainer,
  EmptyState: UiEmptyStateComponent,
  Phrases: UiPhrasesContainer,
  WindowFrame: UiWindowFrameContainer,
  OptionList: UiOptionListComponent,
  ActionListItem: UiActionListItemComponent,
  Table: UiTableComponent,
  Loaders,
  RequiredForPublish: UiRequiredForPublish,
  Score: UiScoreComponent,
  ImportantHint: UiImportantHint,
  HighlightFeature,
  InfoLink
};

export {
  UiEmptyStateStickMan,
  UiEmptyStateType,
  UiOptionListAlignment,
  uiTableRow,
  UiTableRowProps,
  UiTableColumnConfig,
  UiPhrasesEditModal,
  UiPhrasesModal,
  UiScoreSize
};
