import {
  ActiveFilter,
  AssignmentType,
  PaidFilter,
  RealEstateGroup
} from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { SearchAssignmentsListActions } from "@haywork/stores/search-assignment";
import { connect, MapStateToProps } from "react-redux";
import {
  assignmentPhaseValues,
  availabilityStatusValues,
  listingTypeValues
} from "../../selectors";
import {
  alvTypesValues,
  bogTypesValues,
  matchmailPeriodsValues,
  partTypesValues
} from "./../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  realEstateGroupValues: SingleFilterValue[];
  assignmentTypeValues: SingleFilterValue[];
  saleOrRentValues: SingleFilterValue[];
  listingTypeValues: SingleFilterValue[];
  archivedValues: SingleFilterValue[];
  assignmentPhaseValues: SingleFilterValue[];
  isPaidValues: SingleFilterValue[];
  matchmailPeriodsValues: SingleFilterValue[];
  partTypesValues: SingleFilterValue[];
  bogTypesValues: SingleFilterValue[];
  alvTypesValues: SingleFilterValue[];
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.searchAssignment.list;

  const activeFilterOptions: SingleFilterValue[] = [
    {
      label: ActiveFilter.ActiveOnly.toString(),
      value: ActiveFilter.ActiveOnly
    },
    {
      label: ActiveFilter.InactiveOnly.toString(),
      value: ActiveFilter.InactiveOnly
    }
  ];

  const realEstateGroupValues: SingleFilterValue[] = [
    {
      label: `realEstateGroups.${RealEstateGroup.Residential.toString()}`,
      value: RealEstateGroup.Residential
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Commercial.toString()}`,
      value: RealEstateGroup.Commercial
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Agricultural.toString()}`,
      value: RealEstateGroup.Agricultural
    }
  ];
  const assignmentTypeValues: SingleFilterValue[] = [
    {
      label: `assignmentTypes.${AssignmentType.Object.toString()}`,
      value: AssignmentType.Object
    },
    {
      label: `assignmentTypes.${AssignmentType.Project.toString()}`,
      value: AssignmentType.Project
    }
  ];
  const saleOrRentValues: SingleFilterValue[] = [
    { label: "assignment.filter.forSale", value: "Sale" },
    { label: "assignment.filter.forRent", value: "Rent" }
  ];
  const archivedValues: SingleFilterValue[] = [
    { label: "assignment.filter.archived", value: "archived" }
  ];
  const isPaidValues: SingleFilterValue[] = [
    { label: "searchAssignment.filter.isPaid", value: PaidFilter.PaidOnly },
    {
      label: "searchAssignment.filter.isNotPaid",
      value: PaidFilter.NotPaidOnly
    },
    {
      label: "searchAssignment.filter.PaidOrNotPaid",
      value: PaidFilter.PaidOrNotPaid
    }
  ];

  return {
    filters,
    realEstateGroupValues,
    assignmentTypeValues,
    saleOrRentValues,
    archivedValues,
    isPaidValues,
    listingTypeValues: listingTypeValues(state),
    assignmentPhaseValues: assignmentPhaseValues(state),
    availabilityStatusValues: availabilityStatusValues(state),
    matchmailPeriodsValues: matchmailPeriodsValues(state),
    partTypesValues: partTypesValues(state),
    bogTypesValues: bogTypesValues(state),
    alvTypesValues: alvTypesValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(SearchAssignmentsListActions.setFilters(filters))
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
