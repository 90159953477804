import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./dropdown-navigation.component.scss");

export enum DropdownTriggerEvent {
  Click = "Click",
  Hover = "Hover",
  ClickOrHover = "ClickOrHover",
}

export enum DropdownAlignment {
  TopRight = "TopRight",
  TopLeft = "TopLeft",
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
}

interface DropdownNavigationComponentProps {
  buttonLabel: string;
  buttonClass?: string;
  buttonIcon?: string;
  triggerHandler?: DropdownTriggerEvent;
  alignment?: DropdownAlignment;
  disabled?: boolean;
}
interface DropdownNavigationComponentState {
  triggerHandler: DropdownTriggerEvent;
  visible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class DropdownNavigation extends React.Component<
  DropdownNavigationComponentProps,
  DropdownNavigationComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      triggerHandler: this.props.triggerHandler || DropdownTriggerEvent.Click,
      visible: false,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const dropdownStyle = classNames("dropdown", {
      "can-hover": this.state.triggerHandler !== DropdownTriggerEvent.Click,
      visible: this.state.visible,
    });
    const dropdownListStyle = classNames(
      "dropdown__list",
      this.getAlignmentStyle()
    );
    const buttonStyle = classNames(
      "btn",
      this.props.buttonClass || "btn-primary icon-right",
      {
        "icon-left": !!this.props.buttonIcon,
      }
    );

    return (
      <div
        styleName={dropdownStyle}
        ref={(ref) => (this.ref = ref)}
        data-cy={this.props["data-cy"]}
      >
        <div
          className={buttonStyle}
          onClick={this.onClickHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.Clickable`
          }
        >
          {this.props.buttonIcon && <i className={this.props.buttonIcon} />}
          <ResourceText resourceKey={this.props.buttonLabel} />
          <i className="chevron fal fa-fw fa-chevron-down" />
        </div>
        <div styleName={dropdownListStyle}>{this.props.children}</div>
      </div>
    );
  }

  private onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (
      this.props.disabled ||
      [DropdownTriggerEvent.Click, DropdownTriggerEvent.ClickOrHover].indexOf(
        this.state.triggerHandler
      ) === -1
    )
      return;
    this.setState({ visible: true });
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.visible)
      this.setState({ visible: false });
  }

  private getAlignmentStyle() {
    switch (this.props.alignment) {
      case DropdownAlignment.BottomRight:
        return "br";
      case DropdownAlignment.TopLeft:
        return "tl";
      case DropdownAlignment.TopRight:
        return "tr";
      case DropdownAlignment.BottomLeft:
      default:
        return "bl";
    }
  }
}
