import { MAINROUTES } from "@haywork/constants";
import { AppState } from "@haywork/stores";
import { createSelector } from "reselect";
import escapeRegExp from "lodash-es/escapeRegExp";

const sharesSelector = (state: AppState) => state.emailV2.shares.shares;
const editablesSelector = (state: AppState) => state.editable.states || [];
const currentFolderSelector = (state: AppState) =>
  state.emailV2.main.currentFolder;
const foldersSelector = (state: AppState) =>
  state.emailV2.folders.folders || [];

export const hiddenShareIds = createSelector(sharesSelector, (shares) =>
  shares.filter((share) => share.isHidden).map((share) => share.accountId)
);

export const editables = createSelector(editablesSelector, (editables) => {
  return editables
    .filter((editable) => !!editable.entityType && !!editable.entityId)
    .map((editable) => ({
      type: editable.entityType,
      id: editable.entityId,
      openedOn: editable.openedOn,
      inEditMode: /\/edit/gi.test(editable.currentPath),
    }));
});

const currentFolder = createSelector(
  currentFolderSelector,
  foldersSelector,
  (currentFolder, folders) => {
    if (!currentFolder || !folders.length) return undefined;
    return folders.find((folder) => folder.id === currentFolder);
  }
);

export const currentEmailId = createSelector(currentFolder, (currentFolder) => {
  const path = location.pathname;
  const regex = new RegExp(escapeRegExp(MAINROUTES.EMAIL.URI), "gi");

  return regex.test(path) ? currentFolder?.selectedMessage || null : null;
});
