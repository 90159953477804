import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import {
  SearchAssignmentLocation,
  SearchForLocationType
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import has from "lodash-es/has";

const locationsSelector = (state: AppState) =>
  (state.editable.currentComponentState.locations ||
    []) as SearchAssignmentLocation[];
const collegialListingsStatusSelector = (state: AppState) =>
  state.company.collegialListingsStatus;
const matchMailSettingsSelector = (state: AppState) =>
  state.company.settings.matchMailSettings;

export const shouldWarnAboutCollegialListings = createSelector(
  collegialListingsStatusSelector,
  matchMailSettingsSelector,
  locationsSelector,
  (collegialListingsStatus, settings, locations) => {
    if (
      collegialListingsStatus !== REQUEST.SUCCESS ||
      !settings ||
      !settings.collegialListingsEnabled ||
      !locations.length
    )
      return false;

    const count = locations.filter(
      (location) =>
        location.searchForLocationType === SearchForLocationType.Place &&
        has(location.adminAreaLevel2, "id") &&
        !has(location.locality, "id")
    );

    return !!count.length;
  }
);
