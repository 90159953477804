import { RootEntityType } from "@haywork/api/event-center";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { EditableItem } from "@haywork/stores";
import { DateUtil } from "@haywork/util";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CssModules from "react-css-modules";
import Decision, { DecisionAction } from "./decision-modal";
import { ExternalChangesContainerProps } from "./external-changes.container";

const styles = require("./style.scss");

export type ExternalChangesComponentProps = {
  entityId: string;
  entityType: RootEntityType;
  onReloadEntity: (editable: EditableItem) => void;
  onBackToDashboard?: () => void;
};
type Props = ExternalChangesComponentProps & ExternalChangesContainerProps;

export const ExternalChangesComponent: FC<Props> = memo(
  CssModules(styles, { allowMultiple: true })(
    ({
      editable,
      employeeId,
      removeHasExternalChanges,
      onReloadEntity,
      onBackToDashboard,
    }) => {
      const [modalVisible, setModalVisible] = useState(false);
      const [dateTimeModified, setDateTimeModified] = useState<Date | null>(
        null
      );
      const [modifiedBy, setModifiedBy] = useState<string | null>(null);

      const body = useMemo(() => {
        if (!dateTimeModified && !modifiedBy) return null;
        const refDate = moment(dateTimeModified);
        const asTime = moment().isSame(refDate, "day");

        switch (true) {
          case modifiedBy && modifiedBy === employeeId: {
            return null;
          }
          case !!modifiedBy && asTime: {
            return (
              <I18n
                value="externalChanges.body.full.asTime"
                values={{ time: refDate.format("HH:mm"), modifiedBy }}
                asHtml
              />
            );
          }
          case !modifiedBy && asTime: {
            return (
              <I18n
                value="externalChanges.body.date.asTime"
                values={{ time: refDate.format("HH:mm"), modifiedBy }}
                asHtml
              />
            );
          }
          case !!modifiedBy && !asTime: {
            return (
              <I18n
                value="externalChanges.body.full"
                values={{
                  date: refDate.format("dd DD-MM"),
                  time: refDate.format("HH:mm"),
                  modifiedBy,
                }}
                asHtml
              />
            );
          }
          case !modifiedBy && !asTime: {
            return (
              <I18n
                value="externalChanges.body.date"
                values={{
                  date: refDate.format("dd DD-MM"),
                  time: refDate.format("HH:mm"),
                  modifiedBy,
                }}
                asHtml
              />
            );
          }
          default: {
            return null;
          }
        }
      }, [dateTimeModified, modifiedBy]);

      const onActionHandler = useCallback(
        (action: DecisionAction) => {
          switch (action) {
            case DecisionAction.UseLocalData: {
              removeHasExternalChanges();
              break;
            }
            case DecisionAction.FetchExternalData: {
              if (!editable) return;
              onReloadEntity(editable);
              break;
            }
            case DecisionAction.ReturnToDashboard: {
              if (!!onBackToDashboard) onBackToDashboard();
              break;
            }
            default: {
              break;
            }
          }

          setModalVisible(false);
        },
        [
          setModalVisible,
          removeHasExternalChanges,
          onReloadEntity,
          editable,
          onBackToDashboard,
        ]
      );

      useEffect(() => {
        if (!editable || !editable.externalChangesData) return;
        const { dateTimeModified, modifiedBy } = editable.externalChangesData;
        setDateTimeModified(dateTimeModified || null);
        setModifiedBy(modifiedBy || null);
      }, [editable, setDateTimeModified, setModifiedBy]);

      if (!editable || !editable.externalChangesData || !body) return null;

      return (
        <>
          <div
            styleName="external-changes"
            onClick={() => setModalVisible(true)}
          >
            <div styleName="icon">
              <Icon
                name="comment-edit"
                color={Colors.Secondary}
                size={20}
                solid
              />
            </div>
            <div styleName="body">{body}</div>
            <div styleName="action">
              <I18n value="externalChanges.action" />
              <Icon
                name="chevron-down"
                color={Colors.Secondary}
                size={14}
                regular
                containIn={24}
              />
            </div>
          </div>
          <Decision
            visible={modalVisible}
            dateTimeModified={dateTimeModified}
            modifiedBy={modifiedBy}
            showReturnToDashboard={!!onBackToDashboard}
            onAction={onActionHandler}
            onClose={() => setModalVisible(false)}
          />
        </>
      );
    }
  )
);
