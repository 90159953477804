import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";

import { UnauthorizedModalContainerProps } from "../../containers";
import { OidcService } from "@haywork/services";

const styles = require("./unauthorized-modal.component.scss");

export interface UnauthorizedModalComponentProps {}
interface State {}
type Props = UnauthorizedModalComponentProps & UnauthorizedModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class UnauthorizedModalComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onAuthorizeHandler = this.onAuthorizeHandler.bind(this);
  }

  public render() {
    return (
      <Modal visible={this.props.visible}>
        <ModalHeader title="unauthorizedModalHeader" />
        <ModalBody>
          <ResourceText resourceKey="unauthorizedModalBody" asHtml />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary icon-left"
            onClick={this.onAuthorizeHandler}
          >
            <i className="far fa-lock-open-alt" />
            <ResourceText resourceKey="unauthorizedModalAuthorize" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private onAuthorizeHandler() {
    this.props.hideModal();
    const oidc = new OidcService();
    oidc.OidcUserManager.signinRedirect();
  }
}
