import {
  Account,
  AccountOrderBy,
  AccountsClient,
  AccountShare,
  AccountShareOrderBy,
  AccountSharesClient,
  CompaniesClient,
  MessagesClient,
  CreateAccountRequest,
  FolderCategory,
  PersonSettingsClient,
  ProviderOrderBy,
  ProvidersClient,
  ReauthenticateAccountRequest,
  SortOrder,
} from "@haywork/api/mail";
import { COUNTS, MAINROUTES, REQUEST } from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EmailActions } from "@haywork/stores";
import { AsyncUtil } from "@haywork/util";
import first from "lodash-es/first";
import { Dispatch } from "../";
import { push } from "connected-react-router";
import {
  AccountShareReference,
  EmailFolderThunks,
  EmailMessageThunks,
} from "../";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { EmailThunk } from "../emailV2";

const parseRequest = new ParseRequest();

const createAccount = (values: CreateAccountRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Settings.setRedirectUri(null));
    dispatch(EmailActions.Settings.setCreateStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: personId } = state.account.employee;
    const { emailHost } = state.appSettings;

    const Accounts = new AccountsClient(emailHost);

    const request = {
      ...values,
      personId,
    };

    try {
      const account = await parseRequest.response(
        Accounts.create(request, realEstateAgencyId),
        ApiType.Email
      );

      if (!!account.redirectUri && !account.account) {
        dispatch(EmailActions.Settings.setRedirectUri(account.redirectUri));
        return account.redirectUri;
      }

      if (!!account.account) {
        dispatch(EmailActionsV2.Accounts.appendAccount(account.account));
      }

      dispatch(getPersonSettings());
      dispatch(EmailActions.Settings.setCreateStatus(REQUEST.SUCCESS));
      dispatch(
        EmailActions.Accounts.setCurrentAccount({ account: account.account })
      );

      dispatch(EmailThunk.Main.getInitialEmailState());
      dispatch(EmailMessageThunks.getMessages());

      return account.account;
    } catch (error) {
      dispatch(EmailActions.Settings.setCreateStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateAccountAndShares = (
  account: Account,
  shares: AccountShareReference[],
  defaultAccountId: string | null
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();

    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { id: personId } = state.account.employee;
    const { personSettings } = state.emailV2.main;
    const take = COUNTS.EMAIL_ACCOUNTS;

    const Accounts = new AccountsClient(emailHost);
    const Shares = new AccountSharesClient(emailHost);
    const PersonSettings = new PersonSettingsClient(emailHost);

    try {
      if (defaultAccountId !== null) {
        const updatedPersonSettings = await parseRequest.response(
          PersonSettings.save(
            {
              personSettings: {
                ...personSettings,
                defaultAccountId,
              },
            },
            realEstateAgencyId
          ),
          ApiType.Email
        );

        dispatch(
          EmailActionsV2.Main.setPersonSettings(
            updatedPersonSettings.personSettings
          )
        );
        dispatch(
          EmailActions.Settings.setSettings({
            personSettings: updatedPersonSettings.personSettings,
          })
        );
      }

      if (personId === account.ownerId) {
        await parseRequest.response(
          Accounts.save(
            {
              accountId: account.id,
              accountName: account.accountName,
              archiveFolderId: account.archiveFolderId,
              defaultSignatureTemplateId: account.defaultSignatureTemplateId,
              sentFromName: account.sentFromName,
              autoRemoveMessageAfterPersist:
                account.autoRemoveMessageAfterPersist,
            },
            realEstateAgencyId
          ),
          ApiType.Email
        );

        const accountShares = await parseRequest.response(
          Shares.search(
            {
              accountId: account.id,
              skip: 0,
              take: 100,
              orderBy: AccountShareOrderBy.CreationDate,
              order: SortOrder.Ascending,
            },
            realEstateAgencyId
          ).then((response) => response?.results || [])
        );
        const accountSharesPersonIds = accountShares.map(
          (share) => share.personId
        );
        const accountShareReferencesIds = shares.map((share) => share.id);

        const newShares = shares.filter(
          (share) => accountSharesPersonIds.indexOf(share.id) === -1
        );
        const updatedShares = accountShares.filter(
          (share) => accountShareReferencesIds.indexOf(share.personId) !== -1
        );
        const deletedShares = accountShares.filter(
          (share) => accountShareReferencesIds.indexOf(share.personId) === -1
        );

        if (newShares.length > 0) {
          const newAccountShares = await Promise.all(
            newShares.map(() =>
              parseRequest
                .response(
                  Shares.defineNew(
                    { accountId: account.id },
                    realEstateAgencyId
                  ),
                  ApiType.Email
                )
                .then((result) => result.accountShare)
            )
          );

          await Promise.all(
            newAccountShares.map((share, idx) =>
              parseRequest.response(
                Shares.saveAccountShare(
                  {
                    accountShare: {
                      ...share,
                      personId: newShares[idx].id,
                      shareType: newShares[idx].shareType,
                    },
                  },
                  realEstateAgencyId
                ),
                ApiType.Email
              )
            )
          );
        }

        if (updatedShares.length > 0) {
          await Promise.all(
            updatedShares.map((share) => {
              const reference = shares.find((s) => s.id === share.personId);
              return parseRequest.response(
                Shares.saveAccountShare(
                  {
                    accountShare: {
                      ...share,
                      shareType: reference.shareType,
                    },
                  },
                  realEstateAgencyId
                ),
                ApiType.Email
              );
            })
          );
        }

        if (deletedShares.length > 0) {
          await Promise.all(
            deletedShares.map((share) =>
              parseRequest.response(
                Shares.deleteAccountShare(share.id, true, realEstateAgencyId),
                ApiType.Email
              )
            )
          );
        }
      }

      const accounts = await parseRequest.response(
        Accounts.search(
          {
            skip: 0,
            take,
            orderBy: AccountOrderBy.DefaultAccount,
            order: SortOrder.Descending,
            personId,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      dispatch(EmailActions.Accounts.setAccounts({ ...accounts, take }));
      dispatch(EmailThunk.Main.getInitialEmailState());
    } catch (error) {
      throw error;
    }
  };
};

const deleteAccount = (accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Accounts.removeAccount(accountId));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Accounts = new AccountsClient(emailHost);

    try {
      await parseRequest.response(
        Accounts.delete(accountId, true, realEstateAgencyId),
        ApiType.Email
      );
      dispatch(getPersonSettings());
      dispatch(EmailThunk.Main.getInitialEmailState());
    } catch (error) {
      throw error;
    }
  };
};

const getAccounts = (isNewAccountCall: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!isNewAccountCall) {
      dispatch(
        EmailActions.Accounts.setAccountsStatus({ status: REQUEST.PENDING })
      );
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: personId } = state.account.employee;
    const { emailHost } = state.appSettings;
    const take = COUNTS.EMAIL_ACCOUNTS;

    const Accounts = new AccountsClient(emailHost);
    const AccountShares = new AccountSharesClient(emailHost);

    try {
      const result = await parseRequest.response(
        Accounts.search(
          {
            skip: 0,
            take,
            orderBy: AccountOrderBy.DefaultAccount,
            order: SortOrder.Descending,
            personId,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const shares = await parseRequest.response(
        AccountShares.search(
          {
            skip: 0,
            take,
            orderBy: AccountShareOrderBy.CreationDate,
            order: SortOrder.Ascending,
            personId,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      const { results: accounts } = result;

      dispatch(EmailActionsV2.Accounts.setAccounts(accounts || []));
      dispatch(EmailActionsV2.Shares.setShares(shares.results || []));
      await dispatch(EmailThunk.Main.getInitialEmailState());

      if (!!isNewAccountCall) {
        const currentAccount = first(
          getState().email.accounts.accounts.results
        );
        const { folders } = getState().email.folders;
        const inbox = folders.find(
          (folder) =>
            folder.category === FolderCategory.Inbox &&
            folder.accountId === currentAccount.id
        );
        const currentFolder = inbox || first(folders);

        dispatch(
          EmailActions.Accounts.setCurrentAccount({ account: currentAccount })
        );
        dispatch(
          EmailActions.Folders.setCurrentFolder({ folder: currentFolder })
        );
        dispatch(EmailMessageThunks.getMessages());
      }

      return accounts;
    } catch (error) {
      dispatch(
        EmailActions.Accounts.setAccountsStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const getProviders = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EmailActions.Providers.setProvidersStatus({ status: REQUEST.PENDING })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const take = COUNTS.EMAIL_ACCOUNTS;

    const Providers = new ProvidersClient(emailHost);

    try {
      const result = await parseRequest.response(
        Providers.search(
          {
            skip: 0,
            take,
            orderBy: ProviderOrderBy.DisplayName,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );
      dispatch(EmailActions.Providers.setProviders({ ...result, take }));
    } catch (error) {
      dispatch(
        EmailActions.Providers.setProvidersStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const getAccountShares = (accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.PENDING }));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const take = COUNTS.EMAIL_ACCOUNTS;

    const Shares = new AccountSharesClient(emailHost);

    try {
      const result = await parseRequest.response(
        Shares.search(
          {
            accountId,
            skip: 0,
            take,
            orderBy: AccountShareOrderBy.CreationDate,
            order: SortOrder.Descending,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      dispatch(EmailActions.Shares.setShares({ ...result, take }));
      dispatch(EmailActionsV2.Shares.setShares(result.results || []));
    } catch (error) {
      dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.ERROR }));
      throw error;
    }
  };
};

const defineNewAccountShare = (accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.PENDING }));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Shares = new AccountSharesClient(emailHost);

    try {
      const result = await parseRequest.response(
        Shares.defineNew(
          {
            accountId,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );

      dispatch(
        EmailActions.Shares.appendSingleShare({ share: result.accountShare })
      );
    } catch (error) {
      dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.ERROR }));
      throw error;
    }
  };
};

const deleteShare = (share: AccountShare) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!share.personId) return;
    if (share.isNew) {
      dispatch(EmailActions.Shares.removeSingleShare({ share }));
      return;
    }

    dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.PENDING }));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Shares = new AccountSharesClient(emailHost);

    try {
      await parseRequest.response(
        Shares.deleteAccountShare(share.id, true, realEstateAgencyId),
        ApiType.Email
      );
      dispatch(EmailActions.Shares.removeSingleShare({ share }));
      dispatch(EmailThunk.Shares.getAccountShares());
    } catch (error) {
      dispatch(EmailActions.Shares.setSharesStatus({ status: REQUEST.ERROR }));
      throw error;
    }
  };
};

const getPersonSettings = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const PersonSettings = new PersonSettingsClient(emailHost);
    const Companies = new CompaniesClient(emailHost);

    try {
      const personSettings = await parseRequest.response(
        PersonSettings.read(realEstateAgencyId).then((r) => r.personSettings),
        ApiType.Email
      );

      const company = await parseRequest.response(
        Companies.read(realEstateAgencyId, realEstateAgencyId).then(
          (r) => r.company
        ),
        ApiType.Email
      );

      dispatch(EmailActionsV2.Main.setPersonSettings(personSettings));
      dispatch(EmailActions.Settings.setSettings({ personSettings, company }));
    } catch (error) {
      throw error;
    }
  };
};

const reAuthenticate = (request: ReauthenticateAccountRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Accounts = new AccountsClient(emailHost);

    try {
      const authenticated = await parseRequest.response(
        Accounts.reauthenticate(request, realEstateAgencyId),
        ApiType.Email
      );

      if (!!authenticated.redirectUri) {
        window.open(authenticated.redirectUri, "_blank");
      }
    } catch (error) {
      throw error;
    }
  };
};

const toggleShareVisibility = (share: AccountShare) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const AccountShares = new AccountSharesClient(emailHost);

    dispatch(
      EmailActions.Shares.setShareVisibility({
        id: share.id,
        visible: share.isHidden,
      })
    );

    try {
      await parseRequest.response(
        AccountShares.toggleIsHidden(
          {
            id: share.id,
            hide: !share.isHidden,
          },
          realEstateAgencyId
        ),
        ApiType.Email
      );
    } catch (error) {
      throw error;
    }
  };
};

const addInitialShares = (
  account: Account,
  shares: AccountShareReference[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Shares = new AccountSharesClient(emailHost);
    const Accounts = new AccountsClient(emailHost);

    try {
      if (shares.length > 0) {
        const newAccountShares = await Promise.all(
          shares.map(() =>
            parseRequest
              .response(
                Shares.defineNew({ accountId: account.id }, realEstateAgencyId),
                ApiType.Email
              )
              .then((result) => result.accountShare)
          )
        );

        await Promise.all(
          newAccountShares.map((share, idx) =>
            parseRequest.response(
              Shares.saveAccountShare(
                {
                  accountShare: {
                    ...share,
                    personId: shares[idx].id,
                    shareType: shares[idx].shareType,
                  },
                },
                realEstateAgencyId
              ),
              ApiType.Email
            )
          )
        );
      }

      if (!!account.sentFromName) {
        await parseRequest.response(
          Accounts.save(
            {
              accountId: account.id,
              accountName: account.accountName,
              archiveFolderId: account.archiveFolderId,
              defaultSignatureTemplateId: account.defaultSignatureTemplateId,
              sentFromName: account.sentFromName,
            },
            realEstateAgencyId
          ),
          ApiType.Email
        );

        const { id: personId } = state.account.employee;
        const take = COUNTS.EMAIL_ACCOUNTS;
        const accounts = await parseRequest.response(
          Accounts.search(
            {
              skip: 0,
              take,
              orderBy: AccountOrderBy.DefaultAccount,
              order: SortOrder.Descending,
              personId,
            },
            realEstateAgencyId
          ),
          ApiType.Email
        );
        dispatch(EmailActions.Accounts.setAccounts({ ...accounts, take }));
      }

      dispatch(EmailThunk.Shares.getAccountShares());
      await AsyncUtil.wait(500);
      dispatch(push(MAINROUTES.EMAIL.URI));
      dispatch(EmailActions.Settings.setAccountAddedVisibility(true));
    } catch (error) {
      throw error;
    }
  };
};

export const EmailAccountThunks = {
  createAccount,
  updateAccountAndShares,
  deleteAccount,
  getAccounts,
  getProviders,
  getAccountShares,
  defineNewAccountShare,
  deleteShare,
  getPersonSettings,
  reAuthenticate,
  toggleShareVisibility,
  addInitialShares,
};
