import {
  LinkedRelation,
  RelationType,
  SearchAssignmentSnapShot,
} from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, injectIntl, WithIntlProps } from "react-intl";
import { Link } from "react-router-dom";
import { intlContext } from "@haywork/app";
import I18n from "@haywork/components/i18n";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentListItemProps {
  searchAssignment: SearchAssignmentSnapShot;
  zebra: boolean;
  onClick: (id: string) => void;
}
type Props = SearchAssignmentListItemProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class SearchAssignmentListItem extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.itemOnClickHandler = this.itemOnClickHandler.bind(this);
  }

  public render() {
    const searchAssignment = this.props.searchAssignment;
    const listItemStyle = classNames("list-item-grid", {
      zebra: this.props.zebra,
    });
    const { key: resourceKey, values: resourceValues } = this.renderPrice();

    return (
      <div styleName={listItemStyle} onClick={this.itemOnClickHandler}>
        <div styleName="list-item__info searchers">
          <div styleName="meta">
            {this.renderLinkedRelations(searchAssignment.linkedRelations)}
          </div>
        </div>
        <div styleName="list-item__info rentSale">
          <div styleName="meta">
            <I18n
              prefix="offerTypeOptions"
              value={this.props.searchAssignment.offerType.toString()}
            />
          </div>
        </div>

        <div styleName="list-item__info type">
          <div styleName="meta">
            <I18n value={this.renderType()} />
          </div>
        </div>
        <div styleName="list-item__info price">
          <div styleName="meta">
            {!!resourceKey && (
              <I18n value={resourceKey} values={resourceValues} />
            )}
          </div>
        </div>

        <div styleName="list-item__info is-paid">
          <div styleName="meta">
            {!!this.props.searchAssignment.isPaid && (
              <i className="fal fa-check" />
            )}
          </div>
        </div>

        <div styleName="list-item__info date">
          <div styleName="meta">
            {this.props.searchAssignment.dateAssignment && (
              <FormattedDate
                value={this.props.searchAssignment.dateAssignment}
              />
            )}
          </div>
        </div>
        <div styleName="list-item__info matchmail">
          <div styleName="meta">
            <I18n
              prefix="matchMailPeriodOptions"
              value={this.props.searchAssignment.matchMailPeriod.toString()}
            />
          </div>
        </div>
      </div>
    );
  }

  private renderLinkedRelations(linkedRelations: LinkedRelation[]) {
    if (!linkedRelations) return <I18n value="noSearchLinkedRelations" />;

    return linkedRelations.map((searcher, idx) => {
      return <span key={idx}>{this.renderLinkedRelation(searcher)}</span>;
    });
  }

  private renderLinkedRelation(linkedRelation: LinkedRelation): JSX.Element {
    const relationRoute =
      linkedRelation.typeOfRelation === RelationType.ContactPerson
        ? route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
            id: linkedRelation.id,
          })
        : route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
            id: linkedRelation.id,
          });

    const linkedRelationStyle = classNames(
      linkedRelation.typeOfRelation === RelationType.ContactPerson
        ? "fal fa-fw fa-user-alt"
        : "fal fa-fw fa-industry"
    );
    return (
      <Link
        styleName="linkedRelation"
        to={relationRoute}
        key={linkedRelation.id}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <i className={linkedRelationStyle} />
        {linkedRelation.displayName}
      </Link>
    );
  }

  private itemOnClickHandler() {
    const { id } = this.props.searchAssignment;
    this.props.onClick(id);
  }

  private renderType(): string {
    const { alvOveType, bogOveType, partOveType } = this.props.searchAssignment;
    const { formatMessage } = this.props.intl;

    if (!!partOveType) {
      return partOveType
        .filter((t) => !!t)
        .map((t) =>
          formatMessage({
            id: `typePARTOptions.${t.toString()}`,
            defaultMessage: `typePARTOptions.${t.toString()}`,
          })
        )
        .join(", ");
    }
    if (!!bogOveType) {
      return bogOveType
        .filter((t) => !!t)
        .map((t) =>
          formatMessage({
            id: `typeBOGOptions.${t.toString()}`,
            defaultMessage: `typeBOGOptions.${t.toString()}`,
          })
        )
        .join(", ");
    }
    if (!!alvOveType) {
      return alvOveType
        .filter((t) => !!t)
        .map((t) =>
          formatMessage({
            id: `typeALVOptions.${t.toString()}`,
            defaultMessage: `typeALVOptions.${t.toString()}`,
          })
        )
        .join(", ");
    }
  }

  private renderPrice(): { key: string; values: { [key: string]: any } } {
    const { minPrice, maxPrice } = this.props.searchAssignment;
    let key, min, max;

    if (minPrice !== undefined && maxPrice !== undefined) {
      key = "searchAssignmentPriceMinMax";
      min = intlContext.formatNumber(minPrice, {
        style: "currency",
        currency: "EUR",
      });
      max = intlContext.formatNumber(maxPrice, {
        style: "currency",
        currency: "EUR",
      });
    } else if (minPrice !== undefined) {
      key = "searchAssignmentPriceMin";
      min = intlContext.formatNumber(minPrice, {
        style: "currency",
        currency: "EUR",
      });
    } else if (maxPrice !== undefined) {
      key = "searchAssignmentPriceMax";
      max = intlContext.formatNumber(maxPrice, {
        style: "currency",
        currency: "EUR",
      });
    }

    return { key, values: { min, max } };
  }
}

export const SearchAssignmentListItemComponent = injectIntl(
  SearchAssignmentListItem
);
