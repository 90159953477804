import {
  ObjectAssignment,
  SocialPropertyFacilityOption,
  SocialPropertyType,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogSocialPropertyComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) },
      socialPropertyFacilityTypes: {
        value: value(objectAssignment, "socialPropertyFacilityTypes", []),
      },
      redesignationPossible: {
        value: value(objectAssignment, "redesignationPossible", false),
      },
      returnOfServices: {
        value: value(objectAssignment, "returnOfServices", false),
      },
      practicArea: { value: value(objectAssignment, "practicArea", false) },
      practicAreaCapacity: {
        value: value(objectAssignment, "practicAreaCapacity"),
      },
      withAccommodations: {
        value: value(objectAssignment, "withAccommodations", false),
      },
      countOfHousingUnitsInAccommodations: {
        value: value(objectAssignment, "countOfHousingUnitsInAccommodations"),
      },
      sharedAccommodations: {
        value: value(objectAssignment, "sharedAccommodations", false),
      },
      sharedSanitaryFacilities: {
        value: value(objectAssignment, "sharedSanitaryFacilities", false),
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
      vVO: { value: value(objectAssignment, "vVO.area") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update(
        {
          numberOfFloors: value(objectAssignment, "numberOfFloors", 0),
          socialPropertyFacilityTypes: value(
            objectAssignment,
            "socialPropertyFacilityTypes",
            []
          ),
          redesignationPossible: value(
            objectAssignment,
            "redesignationPossible",
            false
          ),
          returnOfServices: value(objectAssignment, "returnOfServices", false),
          practicArea: value(objectAssignment, "practicArea", false),
          practicAreaCapacity: value(objectAssignment, "practicAreaCapacity"),
          withAccommodations: value(
            objectAssignment,
            "withAccommodations",
            false
          ),
          countOfHousingUnitsInAccommodations: value(
            objectAssignment,
            "countOfHousingUnitsInAccommodations"
          ),
          sharedAccommodations: value(
            objectAssignment,
            "sharedAccommodations",
            false
          ),
          sharedSanitaryFacilities: value(
            objectAssignment,
            "sharedSanitaryFacilities",
            false
          ),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
          vVO: value(objectAssignment, "vVO.area"),
        },
        true
      );
    }
  }

  public render() {
    const { socialPropertyType } = this.props.objectAssignment;

    return (
      <Form
        name="social-property"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        {this.props.socialPropertyFacilityTypeOptions &&
          this.props.socialPropertyFacilityTypeOptions.length > 0 && (
            <div className="form__row">
              <label htmlFor="socialPropertyFacilityTypes">
                <ResourceText resourceKey="socialPropertyFacilityTypes" />
              </label>
              <Input.Multi
                name="socialPropertyFacilityTypes"
                values={this.props.socialPropertyFacilityTypeOptions}
              />
            </div>
          )}

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="redesignationPossible"
            label="redesignationPossible"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        {(socialPropertyType === SocialPropertyType.Religious ||
          socialPropertyType === SocialPropertyType.Sports) && (
          <div className="form__row" styleName="retail-switch">
            <Input.Switch
              name="returnOfServices"
              label="returnOfServices"
              on={true}
              off={false}
              labelPosition={SwitchLabelPosition.Pre}
            />
          </div>
        )}

        {socialPropertyType === SocialPropertyType.Healthcare && (
          <div>
            <div className="form__row" styleName="retail-switch">
              <Input.Switch
                name="practicArea"
                label="practicArea"
                on={true}
                off={false}
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="practicAreaCapacity">
                    <ResourceText resourceKey="practicAreaCapacity" />
                  </label>
                  <Input.Number name="practicAreaCapacity" />
                </div>
              </div>
            </div>

            <div className="form__row" styleName="retail-switch">
              <Input.Switch
                name="withAccommodations"
                label="withAccommodations"
                on={true}
                off={false}
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="countOfHousingUnitsInAccommodations">
                    <ResourceText resourceKey="countOfHousingUnitsInAccommodations" />
                  </label>
                  <Input.Number name="countOfHousingUnitsInAccommodations" />
                </div>
              </div>
            </div>

            <div className="form__row" styleName="retail-switch">
              <Input.Switch
                name="sharedAccommodations"
                label="sharedAccommodations"
                on={true}
                off={false}
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>

            <div className="form__row" styleName="retail-switch">
              <Input.Switch
                name="sharedSanitaryFacilities"
                label="sharedSanitaryFacilities"
                on={true}
                off={false}
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          </div>
        )}

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="numberOfFloors">
                <ResourceText resourceKey="numberOfFloorsBog" />
              </label>
              <Input.Number name="numberOfFloors" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="surface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let fields: any = {
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      vVO: {
        ...(objectAssignment.vVO || {}),
        area: values.vVO,
      },
      socialPropertyFacilityTypes: values.socialPropertyFacilityTypes,
      redesignationPossible: values.redesignationPossible,
    };

    if (
      objectAssignment.socialPropertyType === SocialPropertyType.Religious ||
      objectAssignment.socialPropertyType === SocialPropertyType.Sports
    ) {
      fields = {
        ...fields,
        returnOfServices: values.returnOfServices,
      };
    }

    if (objectAssignment.socialPropertyType === SocialPropertyType.Healthcare) {
      fields = {
        ...fields,
        practicArea: values.practicArea,
        practicAreaCapacity: values.practicAreaCapacity,
        withAccommodations: values.withAccommodations,
        countOfHousingUnitsInAccommodations:
          values.countOfHousingUnitsInAccommodations,
        sharedAccommodations: values.sharedAccommodations,
        sharedSanitaryFacilities: values.sharedSanitaryFacilities,
      };
    }

    this.props.onChange(fields);
  }
}
