import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import * as Ably from "ably";
import { connect, MapStateToProps } from "react-redux";
import { LiveDataComponent, LiveDataComponentProps } from "./live-data";

type StateProps = {
  eventCenterStatus: Ably.Types.ConnectionState;
  kolibriStatus: Ably.Types.ConnectionState;
  mailStatus: Ably.Types.ConnectionState;
  voipStatus: Ably.Types.ConnectionState;
  showEmailStatus: boolean;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  LiveDataComponentProps,
  AppState
> = (state) => {
  const {
    eventCenterStatus,
    kolibriStatus,
    mailStatus,
    voipStatus
  } = state.liveData;
  const { accounts } = state.emailV2.accounts;

  return {
    eventCenterStatus,
    kolibriStatus,
    mailStatus,
    voipStatus,
    showEmailStatus: accounts.length > 0
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LiveDataComponentProps
> = (dispatch) => ({});

export type LiveDataContainerProps = StateProps & DispatchProps;
export const LiveDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveDataComponent);
