import { Floor, Garage, Garden, GardenType } from "@haywork/api/kolibri";
import { FormReturnValue } from "@haywork/modules/form";
import { RoomLayoutContainerProps } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { RoomLayoutSectionComponent, StorageComponent } from "./components";

const styles = require("./room-layout.component.scss");

export enum RoomType {
  Garden = "Garden",
  Garage = "Garage",
  Storage = "Storage",
}

export interface FloorSpace<G> {
  floorNumber?: number;
  id: string;
  space: G;
}

export interface FloorsData {
  floors: Floor[];
  gardens: Garden[];
  garages: Garage[];
  numberOfGarages?: number;
  numberOfGardens?: number;
}

export interface RoomLayoutComponentProps {
  floors: number[];
  data: FloorsData;
  onChange: (data: FloorsData) => void;
  onStorageChange: (values: FormReturnValue) => void;
}

interface State {
  gardenCount: number;
  garageCount: number;
  gardens: FloorSpace<Garden>[];
  garages: FloorSpace<Garage>[];
  expandedType: RoomType;
}

type Props = RoomLayoutComponentProps & RoomLayoutContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class RoomLayoutComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const spacesAndCounts = this.mapSpacesAndCounts();
    this.state = {
      ...spacesAndCounts,
      expandedType: null,
    };

    this.mapValuesToObjectData = this.mapValuesToObjectData.bind(this);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.onExpandedClickHandler = this.onExpandedClickHandler.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onCountChangeHandler = this.onCountChangeHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="room-layout">
        {/* Gardens */}
        <RoomLayoutSectionComponent
          roomType={RoomType.Garden}
          count={this.state.gardenCount}
          onAdd={this.onAddHandler}
          spaces={this.state.gardens}
          floors={this.props.floors}
          expanded={this.state.expandedType === RoomType.Garden}
          onExpandedClick={this.onExpandedClickHandler}
          title={{
            key: "roomLayoutGardensTitle",
            values: { count: this.state.gardenCount },
          }}
          onDelete={this.onDeleteHandler}
          onSubmit={this.onSubmitHandler}
          onCountChange={this.onCountChangeHandler}
          data-cy="CY-GardensLayout"
        />

        {/* Garages */}
        <RoomLayoutSectionComponent
          roomType={RoomType.Garage}
          count={this.state.garageCount}
          onAdd={this.onAddHandler}
          spaces={this.state.garages}
          floors={this.props.floors}
          expanded={this.state.expandedType === RoomType.Garage}
          onExpandedClick={this.onExpandedClickHandler}
          title={{
            key: "roomLayoutGaragesTitle",
            values: { count: this.state.garageCount },
          }}
          onDelete={this.onDeleteHandler}
          onSubmit={this.onSubmitHandler}
          onCountChange={this.onCountChangeHandler}
          data-cy="CY-GaragesLayout"
        />

        {/* Storage */}
        {!!this.props.objectAssignment && (
          <StorageComponent
            objectAssignment={this.props.objectAssignment}
            storageRoomTypeOptions={this.props.storageRoomTypeOptions}
            amenitiesOptions={this.props.amenitiesOptions}
            isolationTypeOptions={this.props.isolationTypeOptions}
            onSubmit={this.props.onStorageChange}
          />
        )}
      </div>
    );
  }

  private onExpandedClickHandler(type: RoomType) {
    const expandedType = this.state.expandedType === type ? null : type;
    this.setState({ expandedType });
  }

  private onAddHandler(roomType: RoomType) {
    let state = {};

    switch (roomType) {
      case RoomType.Garden: {
        const gardenCount = this.state.gardenCount + 1;
        const gardens = [...this.state.gardens, this.generateEmptyGarden()];
        if (gardens.length > 20) return;
        state = { gardens, gardenCount };
        break;
      }
      case RoomType.Garage: {
        const garageCount = this.state.garageCount + 1;
        const garages = [...this.state.garages, this.generateEmptyType()];
        if (garages.length > 20) return;
        state = { garages, garageCount };
        break;
      }
      default:
        break;
    }

    this.setState({ ...state, expandedType: roomType }, () =>
      this.mapValuesToObjectData()
    );
  }

  private onDeleteHandler(id: string, roomType: RoomType) {
    let state = {};

    switch (roomType) {
      case RoomType.Garden: {
        const gardens = this.state.gardens.filter((i) => i.id !== id);
        state = { gardens, gardenCount: gardens.length };
        break;
      }
      case RoomType.Garage: {
        const garages = this.state.garages.filter((i) => i.id !== id);
        state = { garages, garageCount: garages.length };
        break;
      }
      default:
        break;
    }

    this.setState(state, () => this.mapValuesToObjectData());
  }

  private onSubmitHandler<G>(
    space: FloorSpace<G>,
    id: string,
    roomType: RoomType
  ) {
    let state = {};

    switch (roomType) {
      case RoomType.Garden: {
        const gardens = this.state.gardens.map((i) =>
          i.id === id ? space : i
        );
        state = { gardens };
        break;
      }
      case RoomType.Garage: {
        const garages = this.state.garages.map((i) =>
          i.id === id ? space : i
        );
        state = { garages };
        break;
      }
      default:
        break;
    }

    this.setState(state, () => this.mapValuesToObjectData());
  }

  private onCountChangeHandler(count: number, roomType: RoomType) {
    let state = {};

    switch (roomType) {
      case RoomType.Garden: {
        const gardens = this.generateBulkSpaces(
          this.state.gardens,
          count,
          this.generateEmptyType.bind(this)
        );
        state = { gardens, gardenCount: gardens.length };
        break;
      }
      case RoomType.Garage: {
        const garages = this.generateBulkSpaces(
          this.state.garages,
          count,
          this.generateEmptyType.bind(this)
        );
        state = { garages, garageCount: garages.length };
        break;
      }
      default:
        break;
    }

    this.setState({ ...state, expandedType: roomType }, () =>
      this.mapValuesToObjectData()
    );
  }

  private mapSpacesAndCounts() {
    let { gardens, garages } = this.props.data;
    gardens = gardens || [];
    garages = garages || [];

    const count = {
      gardenCount: gardens.length,
      garageCount: garages.length,
    };

    const spaces = {
      gardens: [],
      garages: [],
    };

    gardens.map((garden) => {
      spaces.gardens.push({
        id: uuid(),
        space: garden,
      });
    });

    garages.map((garage) => {
      spaces.garages.push({
        id: uuid(),
        space: garage,
      });
    });

    return {
      ...count,
      ...spaces,
    };
  }

  private mapValuesToObjectData() {
    const { data } = this.props;
    const { gardens: rawGardens, garages: rawGarages } = this.state;

    const gardens = rawGardens.map((g) => g.space);
    const garages = rawGarages.map((g) => g.space);

    const updatedData: FloorsData = {
      ...data,
      gardens,
      garages,
      numberOfGarages: garages.length,
      numberOfGardens: gardens.length,
    };

    this.props.onChange(updatedData);
  }

  private generateEmptyType(): FloorSpace<Garden | Garage> {
    return {
      id: uuid(),
      space: {},
    };
  }

  private generateEmptyGarden(): FloorSpace<Garden> {
    return {
      id: uuid(),
      space: {
        gardenType: GardenType.BackGarden,
      },
    };
  }

  private generateBulkSpaces<G>(
    spaces: FloorSpace<G>[],
    count: number,
    multiplier: () => FloorSpace<G>
  ): FloorSpace<G>[] {
    if (count === 0) return [];
    const repeat = count - spaces.length;
    if (repeat === 0) return spaces;
    if (repeat > 0) {
      const extraSpaces: FloorSpace<G>[] = [];
      for (let i = 0; i < repeat; i++) {
        extraSpaces.push(multiplier());
      }
      return [...spaces, ...extraSpaces];
    } else {
      return spaces.slice(0, count);
    }
  }
}
