import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  GeoClient,
  MatchClient,
  MatchedSearchAssignmentSnapshot,
  MatchMailPeriod,
  ObjectAssignment,
  OfferType,
  PaidFilter,
  RealEstateGroup,
  RelationOrderByField,
  RelationsClient,
  SearchAssignment,
  SearchAssignmentLocation,
  SearchAssignmentOrderByField,
  SearchAssignmentsClient,
  SearchAssignmentSnapShot,
  SearchForLocationType,
  SearchSearchAssignmentsRequest,
  SortOrder,
} from "@haywork/api/kolibri";
import {
  COMMON,
  MAINROUTES,
  REQUEST,
  SEARCHASSIGNMENTROUTES,
} from "@haywork/constants";
import { mapSearchAssignmentToSearchAssignmentSnapShot } from "@haywork/mappers/search-assignment";
import { EditableThunks } from "@haywork/middleware";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  RelationActions,
} from "@haywork/stores";
import {
  SearchAssignmentActions,
  SearchAssignmentsListActions,
} from "@haywork/stores/search-assignment";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { AddressUtil, DateUtil, RouteUtil } from "@haywork/util";
import { SearchAssignmentResultLevel } from "@haywork/util/adress";
import { push } from "connected-react-router";
import findIndex from "lodash-es/findIndex";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import * as moment from "moment";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getSearchAssignments = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SearchAssignmentActions.setSearchAssignmentsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const overview = state.searchAssignment.overview;
    const { account } = state;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { searchAssignmentsFilters } = state.searchAssignment.overview;

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      const result = await parseRequest.response(
        SearchAssignments.search(
          {
            orderBy: SearchAssignmentOrderByField.CreationDate,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: init ? 0 : overview.searchAssignmentsPage * take,
            take,
            realEstateGroups: searchAssignmentsFilters.filterByRealEstateGroups,
            offerTypes: searchAssignmentsFilters.offerTypes,
            matchMailPeriods: searchAssignmentsFilters.matchmailPeriods,
            assignmentPhases: searchAssignmentsFilters.assignmentPhases,
            partTypes: searchAssignmentsFilters.partTypes,
            bogTypes: searchAssignmentsFilters.bogTypes,
            alvTypes: searchAssignmentsFilters.alvTypes,
            filterByPaid: PaidFilter.PaidOrNotPaid,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(SearchAssignmentActions.setSearchAssignments(result, take))
        : dispatch(
            SearchAssignmentActions.appendSearchAssignments(result, take)
          );
    } catch (error) {
      throw error;
    }
  };
};

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { filters, order } = state.searchAssignment.list;
      const {
        realEstateGroups,
        assignmentPhases,
        saleOrRent,
        isPaid,
        matchMailPeriods,
        archived,
        typePARTOptions,
        typeBOGOptions,
        typeALVOptions,
      } = filters;

      const client = new SearchAssignmentsClient(host);

      const forRent = saleOrRent.value.includes("forRent");
      const forSale = saleOrRent.value.includes("forSale");
      const filterByActive = !archived.value.length
        ? ActiveFilter.ActiveOnly
        : ActiveFilter.InactiveOnly;

      const response = await parseRequest.response(
        client.search(
          {
            orderBy: order.sortColumn,
            filterByActive,
            order: order.sortOrder,
            skip: startIndex,
            take: stopIndex - startIndex,
            realEstateGroups: realEstateGroups.value,
            offerTypes: saleOrRent.value,
            assignmentPhases: assignmentPhases.value,
            filterByPaid: isPaid.value,
            matchMailPeriods: matchMailPeriods.value,
            partTypes: typePARTOptions.value,
            bogTypes: typeBOGOptions.value,
            alvTypes: typeALVOptions.value,
          },
          realEstateAgencyId
        )
      );

      if (!response) {
        return;
      }

      const { results, totalResults } = response;
      dispatch(
        SearchAssignmentsListActions.updateList(
          startIndex,
          results,
          totalResults
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getSearchAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SearchAssignmentActions.setDetailStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      let searchAssignment = await parseRequest.response(
        SearchAssignments.read(id, realEstateAgencyId).then(
          (response) => response.searchAssignment
        )
      );

      const locations = (searchAssignment.locations || []).map((location) =>
        AddressUtil.createTranslatedSearchAssignmentLocation(location)
      );
      searchAssignment = {
        ...searchAssignment,
        locations,
      };

      const route = RouteUtil.mapStaticRouteValues(
        SEARCHASSIGNMENTROUTES.DETAIL.URI,
        { id: searchAssignment.id }
      );

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.SEARCHASSIGNMENT.ICON,
          componentState: searchAssignment,
          path: route,
          title: "...",
          entityType: RootEntityType.SearchAssignment,
          entityId: searchAssignment.id,
        })
      );

      if (
        !searchAssignment.locations ||
        searchAssignment.locations.length === 0
      ) {
        searchAssignment = {
          ...searchAssignment,
          locations: [
            { id: 0, searchForLocationType: SearchForLocationType.Place },
          ],
        };
      }

      dispatch(
        SearchAssignmentActions.setSearchAssignment({ searchAssignment })
      );
    } catch (error) {
      dispatch(SearchAssignmentActions.setDetailStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const createNewSearchLocation = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const currentComponentState: SearchAssignment =
      state.editable.currentComponentState;
    const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

    const highestId = currentComponentState.locations.reduce((prev, current) =>
      prev.id > current.id ? prev : current
    ).id;

    const componentState: SearchAssignment = {
      ...currentComponentState,
      locations: [
        ...currentComponentState.locations,
        {
          id: highestId + 1,
          searchForLocationType: SearchForLocationType.Place,
        },
      ],
    };

    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    );
  };
};

const removeSearchLocation = (id: string, searchAssignmentId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const currentComponentState: SearchAssignment =
      state.editable.currentComponentState;
    const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
      id: searchAssignmentId,
    });

    const componentState: SearchAssignment = {
      ...currentComponentState,
      locations: currentComponentState.locations
        .filter((location) => location.id.toString() !== id)
        .map((location) => ({ ...location })),
    };

    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    );
  };
};

const createSearchAssignment = (type: RealEstateGroup, relationId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id } = state.account.employee;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const SearchAssignments = new SearchAssignmentsClient(host);
    const Relations = new RelationsClient(host);

    try {
      let searchAssignment = await parseRequest.response(
        SearchAssignments.defineNew(
          { employeeId: id },
          realEstateAgencyId
        ).then((r) => r.searchAssignment)
      );

      const locations = (searchAssignment.locations || []).map((location) =>
        AddressUtil.createTranslatedSearchAssignmentLocation(location)
      );
      searchAssignment = {
        ...searchAssignment,
        locations,
      };

      const route = RouteUtil.mapStaticRouteValues(
        SEARCHASSIGNMENTROUTES.DETAIL.URI,
        { id: searchAssignment.id }
      );
      const redirect = RouteUtil.mapStaticRouteValues(
        SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI,
        {
          id: searchAssignment.id,
        }
      );

      searchAssignment = {
        ...searchAssignment,
        offerType: OfferType.Sale,
      };

      let componentState: SearchAssignment = {
        ...searchAssignment,
        matchMailPeriod: MatchMailPeriod.Weekly,
        realEstateGroup: type,
      };

      if (!!relationId) {
        const linkedRelations = await parseRequest.response(
          Relations.search(
            {
              orderBy: RelationOrderByField.DisplayName,
              includeStatistics: false,
              filterByActive: ActiveFilter.ActiveOnly,
              skip: 0,
              take: 100,
              order: 0,
              relationId,
            },
            realEstateAgencyId
          ).then((r) => r.results)
        );

        componentState = {
          ...componentState,
          linkedRelations,
        };
      }

      dispatch(
        SearchAssignmentActions.setSearchAssignment({
          searchAssignment: componentState,
        })
      );
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.SEARCHASSIGNMENT.ICON,
          componentState,
          path: route,
          title: "...",
          confirm: {
            title: { key: "saveSearchAssignmentConfirmTitle" },
            body: { key: "saveSearchAssignmentConfirmBody" },
          },
          entityType: RootEntityType.SearchAssignment,
          entityId: componentState.id,
        })
      );

      dispatch(push(redirect));
    } catch (error) {
      dispatch(SearchAssignmentActions.setDetailStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const searchAddress = (
  location: string,
  countryIso2: string,
  locationId: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SearchAssignmentActions.setAddressSearchState({
        addressSearchState: REQUEST.PENDING,
        locationId,
      })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { culture } = state.main;
    const currentComponentState: SearchAssignment =
      state.editable.currentComponentState;
    const { account } = state;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;

    const Geo = new GeoClient(host);

    try {
      const address = await parseRequest.response(
        Geo.addressSearch(
          {
            countryIso2,
            location,
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (address === null) {
        return dispatch(
          SearchAssignmentActions.setAddressSearchState({
            addressSearchState: REQUEST.ERROR,
            locationId,
          })
        );
      }

      const placeIds: number[] = [];
      const addresses: SearchAssignmentLocation[] = [];

      const streetId = get(address.street, "id");
      const postalCode = address.postalCode;
      const localityId = get(address.locality, "id");
      const sublocalityId = get(address, "sublocality.id");
      const adminAreaLevel2Id = get(address.adminAreaLevel2, "id");
      const adminAreaLevel1Id = get(address.adminAreaLevel1, "id");

      switch (true) {
        case !!streetId: {
          placeIds.push(streetId);
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              0,
              SearchAssignmentResultLevel.Street
            )
          );
          break;
        }
        case !!postalCode && location.startsWith(postalCode): {
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              0,
              SearchAssignmentResultLevel.PostalCode
            )
          );
          break;
        }
        case !!sublocalityId: {
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              0,
              SearchAssignmentResultLevel.SubLocality
            )
          );
          break;
        }
        case !!localityId: {
          placeIds.push(localityId);
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              0,
              SearchAssignmentResultLevel.Locality
            )
          );
          if (
            get(address.locality, "name") ===
            get(address.adminAreaLevel2, "name")
          ) {
            placeIds.push(adminAreaLevel2Id);
            addresses.push(
              AddressUtil.createSearchLocality(
                address,
                1,
                SearchAssignmentResultLevel.AdminAreaLevel2
              )
            );
          }
          if (
            get(address.locality, "name") ===
            get(address.adminAreaLevel1, "name")
          ) {
            placeIds.push(adminAreaLevel1Id);
            addresses.push(
              AddressUtil.createSearchLocality(
                address,
                2,
                SearchAssignmentResultLevel.AdminAreaLevel1
              )
            );
          }
          break;
        }
        case !!postalCode: {
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              0,
              SearchAssignmentResultLevel.PostalCode
            )
          );
          break;
        }
        case !!adminAreaLevel2Id: {
          placeIds.push(adminAreaLevel2Id);
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              1,
              SearchAssignmentResultLevel.AdminAreaLevel2
            )
          );
          if (
            get(address.locality, "name") ===
            get(address.adminAreaLevel1, "name")
          ) {
            placeIds.push(adminAreaLevel1Id);
            addresses.push(
              AddressUtil.createSearchLocality(
                address,
                2,
                SearchAssignmentResultLevel.AdminAreaLevel1
              )
            );
          }
          break;
        }
        case !!adminAreaLevel1Id: {
          placeIds.push(adminAreaLevel1Id);
          addresses.push(
            AddressUtil.createSearchLocality(
              address,
              2,
              SearchAssignmentResultLevel.AdminAreaLevel1
            )
          );
          break;
        }
        default:
          break;
      }

      let locations: SearchAssignmentLocation[] = (
        currentComponentState.locations || []
      ).filter((location) => location.displayName !== null);
      const start = locations.slice(0, locationId);
      const end = locations.slice(locationId + 1);

      locations = [...start, ...addresses, ...end].map((location, id) => ({
        ...location,
        id,
      }));

      const componentState = { ...currentComponentState, locations };

      dispatch(
        SearchAssignmentActions.setAddressSearchState({
          addressSearchState: REQUEST.IDLE,
        })
      );

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
            id: currentComponentState.id,
          }),
        })
      );
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setAddressSearchState({
          addressSearchState: REQUEST.ERROR,
          locationId,
        })
      );
      throw error;
    }
  };
};

const saveSearchAssignment = (
  searchAssignment: SearchAssignment,
  redirect: boolean = true,
  close: boolean = false,
  initial: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    if (searchAssignment.locations && searchAssignment.locations.length > 1) {
      const locations = searchAssignment.locations.filter((location) =>
        AddressUtil.doesLocationHaveAddress(location)
      );
      searchAssignment = {
        ...searchAssignment,
        locations,
      };
    }

    searchAssignment = {
      ...searchAssignment,
      constructionType: !!searchAssignment.constructionType
        ? searchAssignment.constructionType
        : null,
    };

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      let updatedSearchAssignment = await parseRequest.response(
        SearchAssignments.save({ searchAssignment }, realEstateAgencyId).then(
          (response) => response.searchAssignment
        )
      );

      const locations = (updatedSearchAssignment.locations || []).map(
        (location) =>
          AddressUtil.createTranslatedSearchAssignmentLocation(location)
      );
      updatedSearchAssignment = {
        ...updatedSearchAssignment,
        locations,
      };

      const searchAssignmentPath = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
        id: searchAssignment.id,
      });

      if (close) {
        dispatch(
          SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.IDLE)
        );
        return dispatch(EditableThunks.remove(searchAssignmentPath));
      }

      dispatch(
        SearchAssignmentActions.updateSearchAssignment({
          searchAssignment: updatedSearchAssignment,
        })
      );
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: updatedSearchAssignment,
          path: searchAssignmentPath,
          ignoreChanges: true,
        })
      );

      if (!!redirect) {
        dispatch(
          push(
            route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
              id: updatedSearchAssignment.id,
            })
          )
        );
      }

      if (!!initial) {
        dispatch(
          push(
            route(SEARCHASSIGNMENTROUTES.EDIT_LINKED_ASSIGNMENTS.URI, {
              id: updatedSearchAssignment.id,
            })
          )
        );
      }
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getSearchAssignmentMatchingProperties = (
  searchAssignmentId: string,
  startIndex: number,
  stopIndex: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Match = new MatchClient(host);

    try {
      const result = await parseRequest.response(
        Match.searchMatchingProperties(
          {
            searchAssignmentId,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
      );

      return {
        items: result?.results || [],
        total: result?.totalResults || 0,
      };
    } catch (error) {
      throw error;
    }
  };
};

const getSearchAssignmentMatchMails = (
  searchAssignmentId: string,
  init: boolean = false,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SearchAssignmentActions.setMatchMailsState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { matchMailsPage } = state.searchAssignment.single;
    const { host } = state.appSettings;

    const Match = new MatchClient(host);

    try {
      const result = await parseRequest.response(
        Match.searchMailHistory(
          {
            searchAssignmentId,
            skip: init ? 0 : matchMailsPage * take,
            take,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(SearchAssignmentActions.setMatchMails({ result, take }))
        : dispatch(SearchAssignmentActions.appendMatchMails({ result, take }));
    } catch (error) {
      dispatch(SearchAssignmentActions.setMatchMailsState(REQUEST.ERROR));
      throw error;
    }
  };
};

const getMatchMailProperties = (
  objectAssignmentIds: string[],
  searchAssignmentId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      SearchAssignmentActions.setModalMatchingPropertiesState(REQUEST.PENDING)
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Match = new MatchClient(host);

    try {
      const result = await parseRequest.response(
        Match.getMatchedProperties(
          {
            objectAssignmentIds,
            searchAssignmentId,
          },
          realEstateAgencyId
        )
      );

      dispatch(SearchAssignmentActions.setModalMatchingProperties({ result }));
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setModalMatchingPropertiesState(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getObjectAssignmentSearchAssignments = (
  objectAssignmentId: string,
  isConceptAssignment: boolean,
  matchMailPeriod: MatchMailPeriod[] | null,
  skip: number = 0,
  take: number = 50
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const Match = new MatchClient(host);

    try {
      return await parseRequest.response(
        Match.searchSearchAssignments(
          {
            objectAssignmentId,
            isConceptAssignment,
            skip,
            take,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            matchMailPeriods: isArray(matchMailPeriod)
              ? matchMailPeriod
              : undefined,
          },
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getMatchingSearchAssignments = (contactId: string, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      RelationActions.setFetchRelationSearchAssignmentsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      const searchAssignments = await parseRequest.response(
        SearchAssignments.search(
          {
            skip: 0,
            take,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            orderBy: SearchAssignmentOrderByField.CreationDate,
            relationIds: [contactId],
            filterByPaid: PaidFilter.PaidOrNotPaid,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(
        RelationActions.setRelationSearchAssignments({ searchAssignments })
      );
    } catch (error) {
      dispatch(
        RelationActions.setFetchRelationSearchAssignmentsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: SearchAssignment = editable.componentState;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new SearchAssignmentsClient(host);

      let searchAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.searchAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );

      const locations = (searchAssignment.locations || []).map((location) =>
        AddressUtil.createTranslatedSearchAssignmentLocation(location)
      );
      searchAssignment = {
        ...searchAssignment,
        locations,
      };

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: SearchAssignment = {
          ...componentState,
          ...searchAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
          })
        );

        if (active) {
          dispatch(
            SearchAssignmentActions.setSearchAssignment({ searchAssignment })
          );
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = searchAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: searchAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadSearchAssignment = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    let searchAssignment =
      externalChangesData.updatedEntity as SearchAssignment;

    const locations = (searchAssignment.locations || []).map((location) =>
      AddressUtil.createTranslatedSearchAssignmentLocation(location)
    );
    searchAssignment = {
      ...searchAssignment,
      locations,
    };

    const componentState: SearchAssignment = {
      ...editable.componentState,
      ...searchAssignment,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
      })
    );

    if (active) {
      dispatch(
        SearchAssignmentActions.setSearchAssignment({ searchAssignment })
      );
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const getSearchAssignmentsByIds = (ids: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      const promises = ids.map((id) =>
        parseRequest.response(
          SearchAssignments.read(id, realEstateAgencyId).then(
            (response) => response.searchAssignment
          )
        )
      );

      const searchAssignments = await Promise.all(promises);

      return searchAssignments.filter((assignment) => !!assignment);
    } catch (error) {
      throw error;
    }
  };
};

const getSearchAssignmentsByRelationIds = (relationIds: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const SearchAssignments = new SearchAssignmentsClient(host);

    try {
      const searchAssignmentIds = await parseRequest.response(
        SearchAssignments.search(
          {
            orderBy: SearchAssignmentOrderByField.CreationDate,
            filterByPaid: PaidFilter.PaidOrNotPaid,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: 0,
            take: 100,
            relationIds,
          },
          realEstateAgencyId
        ).then((response) =>
          (response.results || []).map((assignment) => assignment.id)
        )
      );

      const promises = searchAssignmentIds.map((id) =>
        parseRequest.response(
          SearchAssignments.read(id, realEstateAgencyId).then(
            (response) => response.searchAssignment
          )
        )
      );

      const searchAssignments = await Promise.all(promises);

      return searchAssignments.filter((assignment) => !!assignment);
    } catch (error) {
      throw error;
    }
  };
};

const updateSearchAssignmentListItem = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { searchAssignments } = state.searchAssignment.list;
    const ref = (searchAssignments || []).find(
      (searchAssignment) => searchAssignment.id === id
    );

    if (!ref) return;

    // Change to get
    try {
      const client = new SearchAssignmentsClient(host);
      const searchAssignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response?.searchAssignment)
      );
      if (!searchAssignment) return;

      const snapshot =
        mapSearchAssignmentToSearchAssignmentSnapShot(searchAssignment);

      dispatch(SearchAssignmentsListActions.updateItem(snapshot));
    } catch (error) {
      throw error;
    }
  };
};

const archive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.PENDING)
      );

      const client = new SearchAssignmentsClient(host);

      await parseRequest.response(client.archive({ id }, realEstateAgencyId));
      await dispatch(refreshSearchAssignment(id));

      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.SUCCESS)
      );
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const unArchive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.PENDING)
      );

      const client = new SearchAssignmentsClient(host);

      await parseRequest.response(client.unarchive({ id }, realEstateAgencyId));
      await dispatch(refreshSearchAssignment(id));

      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.SUCCESS)
      );
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const refreshSearchAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new SearchAssignmentsClient(host);
      const searchAssignmentPath = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
        id,
      });

      let searchAssignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.searchAssignment)
      );

      const locations = (searchAssignment.locations || []).map((location) =>
        AddressUtil.createTranslatedSearchAssignmentLocation(location)
      );

      searchAssignment = {
        ...searchAssignment,
        locations,
      };

      dispatch(
        SearchAssignmentActions.updateSearchAssignment({
          searchAssignment,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: searchAssignment,
          path: searchAssignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const remove = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.PENDING)
      );

      const client = new SearchAssignmentsClient(host);
      const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

      await parseRequest.response(client.delete(id, realEstateAgencyId));

      dispatch(EditableThunks.remove(path));
      dispatch(
        SnackbarActions.addToast({
          value: "searchAssignment.toast.deleted",
          callback: () => {
            dispatch(unRemove(id));
          },
          callbackLabel: "searchAssignment.toast.action.undelete",
          icon: "trash-alt",
        })
      );
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.SUCCESS)
      );
    } catch (error) {
      dispatch(
        SearchAssignmentActions.setSaveSearchAssignmentStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const unRemove = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new SearchAssignmentsClient(host);
      const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

      await parseRequest.response(client.undelete({ id }, realEstateAgencyId));
      dispatch(push(path));
    } catch (error) {
      throw error;
    }
  };
};

const removeListSearchAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new SearchAssignmentsClient(host);
    const { searchAssignments, totalCount } = state.searchAssignment.list;

    try {
      const index = findIndex(
        searchAssignments,
        (searchAssignment) => searchAssignment.id === id
      );
      if (index === -1) return;
      const snapshot = searchAssignments[index];
      const searchAssignmentsClone = [...searchAssignments];
      searchAssignmentsClone.splice(index, 1);

      dispatch(
        SearchAssignmentsListActions.updateList(
          0,
          searchAssignmentsClone,
          totalCount - 1
        )
      );

      await parseRequest.response(client.delete(id, realEstateAgencyId));

      dispatch(
        SnackbarActions.addToast({
          value: "searchAssignment.toast.deleted",
          callback: async () => {
            await dispatch(unRemoveListSearchAssignment(id, snapshot, index));
          },
          callbackLabel: "searchAssignment.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unRemoveListSearchAssignment = (
  id: string,
  snapshot: SearchAssignmentSnapShot,
  prevIndex: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new SearchAssignmentsClient(host);
    const { searchAssignments, totalCount } = state.searchAssignment.list;
    const searchAssignmentsClone = [...searchAssignments];

    try {
      searchAssignmentsClone.splice(prevIndex, 0, snapshot);

      dispatch(
        SearchAssignmentsListActions.updateList(
          0,
          searchAssignmentsClone,
          totalCount + 1
        )
      );

      await parseRequest.response(client.undelete({ id }, realEstateAgencyId));
    } catch (error) {
      throw error;
    }
  };
};

const findSearchAssignmentByObjectAssignment = (
  objectAssignment: ObjectAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new MatchClient(host);
    const take = 100;

    try {
      const request: SearchSearchAssignmentsRequest = {
        objectAssignmentId: COMMON.EMPTY_GUID,
        filterByActive: ActiveFilter.ActiveOnly,
        order: SortOrder.Ascending,
        skip: 0,
        take: 10,
        objectAssignment,
      };

      const response = await parseRequest.response(
        client.searchSearchAssignments(request, realEstateAgencyId)
      );

      let assignments = response.results || [];

      if (response.totalResults > take) {
        const diff = response.totalResults - take;
        const count = Math.ceil(diff / take);
        const promises: Promise<MatchedSearchAssignmentSnapshot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            client
              .searchSearchAssignments(
                {
                  ...request,
                  skip: i * take,
                },
                realEstateAgencyId
              )
              .then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const snapshots = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <MatchedSearchAssignmentSnapshot[]>[]);

        assignments = [...assignments, ...snapshots];
      }

      return assignments;
    } catch (error) {
      throw error;
    }
  };
};

export const SearchAssignmentThunks = {
  getSearchAssignments,
  getListItems,
  createSearchAssignment,
  getSearchAssignment,
  searchAddress,
  saveSearchAssignment,
  createNewSearchLocation,
  removeSearchLocation,
  getSearchAssignmentMatchingProperties,
  getSearchAssignmentMatchMails,
  getMatchMailProperties,
  getObjectAssignmentSearchAssignments,
  getMatchingSearchAssignments,
  checkExternalChanges,
  reloadSearchAssignment,
  getSearchAssignmentsByIds,
  getSearchAssignmentsByRelationIds,
  updateSearchAssignmentListItem,
  archive,
  unArchive,
  remove,
  removeListSearchAssignment,
  findSearchAssignmentByObjectAssignment,
};
