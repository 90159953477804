import { FolderCategory, ShareType, Account } from "@haywork/api/mail";
import { AppState } from "@haywork/stores";
import { EmailDraft, EmailMessage } from "@haywork/stores/email-v2";
import first from "lodash-es/first";
import isBoolean from "lodash-es/isBoolean";
import sortBy from "lodash-es/sortBy";
import { createSelector } from "reselect";
import head from "lodash-es/head";

const accountsSelector = (state: AppState) =>
  state.emailV2.accounts.accounts || [];
const foldersSelector = (state: AppState) =>
  state.emailV2.folders.folders || [];
const currentFolderSelector = (state: AppState) =>
  state.emailV2.main.currentFolder;
const messagesSelector = (state: AppState) =>
  state.emailV2.messages.messages || [];
const draftsSelector = (state: AppState) => state.emailV2.drafts.drafts || [];
const filterSelector = (state: AppState) => state.emailV2.main.filters;
const idsMatchingSearchQuerySelector = (state: AppState) =>
  state.emailV2.main.idsMatchingSearchQuery || [];
const sharesSelector = (state: AppState) => state.emailV2.shares.shares || [];
const externalAccountSelector = (state: { accountId: string }) =>
  state.accountId;
const settingsSelector = (state: AppState) => state.emailV2.main.personSettings;
const searchSelectedMessageSelector = (state: AppState) =>
  state.emailV2.main.searchSelectedMessage;

export const accounts = createSelector(
  accountsSelector,
  settingsSelector,
  (accounts, settings) => {
    const compareFn = (a: Account, b: Account) =>
      a.id === settings?.defaultAccountId ? -1 : 0;
    return accounts.sort(compareFn);
  }
);

export const folders = createSelector(foldersSelector, (folders) => {
  return sortBy(folders, (folder) => folder.displayName);
});

export const accountFolders = createSelector(
  folders,
  externalAccountSelector,
  (folders, accountId) => {
    if (!accountId) return [];
    return folders.filter((folder) => folder.accountId === accountId);
  }
);

export const currentFolder = createSelector(
  currentFolderSelector,
  folders,
  (currentFolder, folders) => {
    if (!currentFolder || !folders.length) return undefined;
    return folders.find((folder) => folder.id === currentFolder);
  }
);

export const canPerformEditActions = createSelector(
  currentFolder,
  sharesSelector,
  (folder, shares) => {
    if (!folder || !folder.accountId) return false;
    if (!shares.length) return true;

    const { accountId } = folder;
    const restrictions = shares.filter(
      (share) => share.accountId === accountId
    );
    if (!restrictions.length) return true;

    const validShareTypes = [ShareType.ReadWrite, ShareType.ReadWriteSend];
    let canPerformEditActions = false;
    for (let i = 0; i < restrictions.length; i++) {
      const restriction = restrictions[i];
      if (validShareTypes.includes(restriction.shareType)) {
        canPerformEditActions = true;
        break;
      }
    }

    return canPerformEditActions;
  }
);

export const filters = createSelector(
  filterSelector,
  (filters) => filters || {}
);

export const items = createSelector(
  currentFolder,
  messagesSelector,
  draftsSelector,
  filters,
  idsMatchingSearchQuerySelector,
  (folder, messages, drafts, filters, idsMatchingSearchQuery) => {
    if (!folder) return [];

    const { unread, bookmarked, searchQuery } = filters;
    const searchQueryRegex = !searchQuery
      ? null
      : new RegExp(searchQuery, "gi");
    let items: EmailMessage[] | EmailDraft[] = [];

    if (folder.category === FolderCategory.Drafts) {
      const folderDrafts = drafts.filter((draft) => {
        const matchesAccount = draft.accountId === folder.accountId;
        const matchesFolder = draft.folderId === folder.id;
        let queryMatches = true;

        if (!!searchQueryRegex) {
          const { searchString } = draft;
          queryMatches =
            idsMatchingSearchQuery.includes(draft.id) ||
            (!!searchString && searchQueryRegex.test(searchString));
        }

        return matchesAccount && matchesFolder && queryMatches;
      });

      items = sortBy(folderDrafts, (draft) => draft.date).reverse();
    } else {
      const folderMessages = messages.filter((message) => {
        const matchesAccount = message.accountId === folder.accountId;
        const matchesFolder = message.folderId === folder.id;
        const matchesUnread = !isBoolean(unread) || message.unread === unread;
        const matchesBookmarked =
          !isBoolean(bookmarked) || message.bookmarked === bookmarked;

        if (!!searchQueryRegex) {
          const { searchString } = message;
          return (
            idsMatchingSearchQuery.includes(message.id) ||
            (!!searchString && searchQueryRegex.test(searchString))
          );
        }

        return (
          matchesAccount && matchesUnread && matchesBookmarked && matchesFolder
        );
      });

      items = sortBy(folderMessages, (message) => message.date).reverse();
    }

    return items;
  }
);

export const getAccount = (accountId: string) =>
  createSelector(accountsSelector, (accounts) => {
    return accounts.find((account) => account.id === accountId) || null;
  });

export const currentMessage = createSelector(
  currentFolder,
  messagesSelector,
  searchSelectedMessageSelector,
  (folder, messages, searchSelectedMessage) => {
    let selectedMessage = folder?.selectedMessage || null;

    if (!!searchSelectedMessage) {
      selectedMessage = searchSelectedMessage;
    }

    return messages.find((message) => message.id === selectedMessage) || null;
  }
);

export const currentMessageId = createSelector(
  currentMessage,
  (currentMessage) => (!currentMessage ? null : currentMessage.id || null)
);

export const archiveFolder = createSelector(
  currentFolder,
  folders,
  accountsSelector,
  (folder, folders, accounts) => {
    if (!folder) return null;

    const { accountId } = folder;
    const account = accounts.find((account) => account.id === accountId);
    if (!account) return null;

    const archiveFolders = folders
      .filter((folder) => folder.accountId === accountId)
      .filter((folder) =>
        !!account.archiveFolderId
          ? folder.id === account.archiveFolderId
          : folder.category === FolderCategory.Archive
      );
    if (!archiveFolders.length) return null;

    return first(archiveFolders);
  }
);

export const spamFolder = createSelector(
  currentFolder,
  folders,
  accountsSelector,
  (folder, folders, accounts) => {
    if (!folder) return null;

    const { accountId } = folder;
    const account = accounts.find((account) => account.id === accountId);
    if (!account) return null;

    const spamFolders = folders
      .filter((folder) => folder.accountId === accountId)
      .filter((folder) => folder.category === FolderCategory.Spam);
    if (!spamFolders.length) return null;

    return first(spamFolders);
  }
);

export const trashFolder = createSelector(
  currentFolder,
  folders,
  accountsSelector,
  (folder, folders, accounts) => {
    if (!folder) return null;

    const { accountId } = folder;
    const account = accounts.find((account) => account.id === accountId);
    if (!account) return null;

    const trashFolders = folders
      .filter((folder) => folder.accountId === accountId)
      .filter((folder) => folder.category === FolderCategory.Trash);
    if (!trashFolders.length) return null;

    return first(trashFolders);
  }
);

export const hasArchiveFolder = createSelector(
  archiveFolder,
  (archiveFolder) => {
    return !!archiveFolder;
  }
);

export const hasSpamFolder = createSelector(spamFolder, (spamFolder) => {
  return !!spamFolder;
});

export const hasTrashFolder = createSelector(trashFolder, (trashFolder) => {
  return !!trashFolder;
});

const sendAccounts = createSelector(
  accounts,
  sharesSelector,
  (accounts, shares) => {
    return accounts.filter((account) => {
      const restrictions = shares.find(
        (share) => share.accountId === account.id
      );
      return ![ShareType.Read, ShareType.ReadWrite].includes(
        restrictions?.shareType
      );
    });
  }
);

export const defaultSendAccountId = createSelector(sendAccounts, (accounts) => {
  return head(accounts)?.id || "";
});
