import { Action } from "redux";
import { REDUX, REQUEST, SCHEDULER_OTHER } from "@haywork/constants";
import {
  AgendaItemSnapShot,
  AgendaItemCategorySnapShot,
  AgendaItem,
} from "@haywork/api/kolibri";
import * as ActionType from "./scheduler.types";
import sortBy from "lodash-es/sortBy";

export interface SchedulerFilters {
  employeeIds: string[];
  categoryIds: string[];
  startDateTimeMin: Date;
  startDateTimeMax: Date;
  showFilters: boolean;
  view: string;
  showWorkingHours: boolean;
  majorTick: number;
  isCanceled?: boolean;
  isConfirmed?: boolean;
}

export interface NewSchedulerItemValues {
  startDateTime: Date;
  endDateTime: Date;
  linkedEmployeeIds: string[];
  agendaItem?: AgendaItem;
}

export interface SchedulerState {
  agendaItems?: AgendaItemSnapShot[];
  agendaItemsForToday?: AgendaItemSnapShot[];
  agendaItem?: AgendaItem;
  agendaItemCategories?: AgendaItemCategorySnapShot[];
  schedulerOverviewState?: string;
  schedulerDetailState?: string;
  schedulerCancelState?: string;
  schedulerWidgetState?: string;
  schedulerConfirmationState?: string;
  schedulerRemoveState?: string;
  schedulerFilters?: SchedulerFilters;
}

const INITIAL_STATE: SchedulerState = {
  agendaItems: [],
  agendaItemsForToday: [],
  agendaItem: null,
  agendaItemCategories: [],
  schedulerOverviewState: REQUEST.IDLE,
  schedulerDetailState: REQUEST.IDLE,
  schedulerConfirmationState: REQUEST.IDLE,
  schedulerCancelState: REQUEST.IDLE,
  schedulerWidgetState: REQUEST.IDLE,
  schedulerRemoveState: REQUEST.IDLE,
  schedulerFilters: {
    employeeIds: [],
    categoryIds: [],
    startDateTimeMin: null,
    startDateTimeMax: null,
    showFilters: false,
    view: SCHEDULER_OTHER.WEEK_VIEW,
    showWorkingHours: false,
    majorTick: 60,
  },
};

export const schedulerReducer = (
  state: SchedulerState = INITIAL_STATE,
  action: Action
): SchedulerState => {
  switch (action.type) {
    case REDUX.SCHEDULER.SET_SCHEDULER_STATE: {
      const { schedulerState } = <ActionType.SchedulerState>action;
      return { ...state, schedulerOverviewState: schedulerState };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_DETAIL_STATE: {
      const { schedulerState } = <ActionType.SchedulerState>action;
      return { ...state, schedulerDetailState: schedulerState };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_CONFIRMATION_STATE: {
      const { schedulerState } = <ActionType.SchedulerState>action;
      return { ...state, schedulerConfirmationState: schedulerState };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_REMOVE_STATE: {
      const { schedulerState } = <ActionType.SchedulerState>action;
      return { ...state, schedulerRemoveState: schedulerState };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_WIDGET_STATE: {
      const { schedulerState } = <ActionType.SchedulerState>action;
      return { ...state, schedulerWidgetState: schedulerState };
    }
    case REDUX.SCHEDULER.SET_AGENDA_ITEMS: {
      const { results, resultCount, totalResults, take } = <
        ActionType.AgendaItemsResponse
      >action;
      return {
        ...state,
        agendaItems: results,
        schedulerOverviewState: REQUEST.SUCCESS,
        schedulerRemoveState: REQUEST.SUCCESS,
      };
    }
    case REDUX.SCHEDULER.APPEND_AGENDA_ITEMS: {
      const { results, resultCount, totalResults, take } = <
        ActionType.AgendaItemsResponse
      >action;
      return {
        ...state,
        agendaItems: [...state.agendaItems, ...results],
        schedulerOverviewState: REQUEST.SUCCESS,
        schedulerRemoveState: REQUEST.SUCCESS,
      };
    }
    case REDUX.SCHEDULER.SET_AGENDA_ITEMS_FOR_TODAY: {
      const { results, resultCount, totalResults } = <
        ActionType.AgendaItemsResponse
      >action;
      return {
        ...state,
        agendaItemsForToday: sortBy(
          results,
          (item: AgendaItemSnapShot) => item.startDateTime
        ),
        schedulerWidgetState: REQUEST.SUCCESS,
      };
    }
    case REDUX.SCHEDULER.SET_AGENDA_ITEM: {
      const { agendaItem } = <ActionType.SingleAgendaItemResponse>action;
      return {
        ...state,
        agendaItem,
        schedulerDetailState: REQUEST.SUCCESS,
      };
    }
    case REDUX.SCHEDULER.SET_AGENDA_ITEM_CATEGORIES: {
      const { results } = <ActionType.AgendaItemCategoriesResponse>action;
      return {
        ...state,
        agendaItemCategories: results,
      };
    }
    case REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORIES: {
      const { agendaItemCategories } = <ActionType.SchedulerCategories>action;

      return {
        ...state,
        agendaItemCategories,
      };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_FILTERS: {
      const { schedulerFilters } = <ActionType.Filters>action;
      return { ...state, schedulerFilters };
    }
    case REDUX.SCHEDULER.SET_SCHEDULER_RECURRENCY: {
      const { recurrency } = <ActionType.Recurrency>action;
      let isRecurringEvent = false;
      if (recurrency !== null && recurrency !== "") {
        isRecurringEvent = true;
      }
      const agendaItem = {
        ...state.agendaItem,
        recurrencePattern: recurrency,
        isRecurringEvent,
      };
      return { ...state, agendaItem };
    }
    case REDUX.SCHEDULER.UPDATE_OVERVIEW_ITEM: {
      const { snapshot } = <ActionType.SnapShot>action;
      const agendaItems = (state.agendaItems || []).map((agendaItem) => {
        if (agendaItem.id === snapshot.id) {
          return snapshot;
        }
        return agendaItem;
      });
      const agendaItemsForToday = (state.agendaItemsForToday || []).map(
        (agendaItem) => {
          if (agendaItem.id === snapshot.id) {
            return snapshot;
          }
          return agendaItem;
        }
      );

      return {
        ...state,
        agendaItems,
        agendaItemsForToday,
      };
    }
    case REDUX.SCHEDULER.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const agendaItems = (state.agendaItems || []).filter(
        (agendaItem) => agendaItem.id !== id
      );
      const agendaItemsForToday = (state.agendaItemsForToday || []).filter(
        (agendaItem) => agendaItem.id !== id
      );

      return {
        ...state,
        agendaItems,
        agendaItemsForToday,
      };
    }
    case REDUX.ACCESS.LOGOUT: {
      return INITIAL_STATE;
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
