import * as React from "react";
import { FC, memo, MouseEvent, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { ListChildComponentProps } from "react-window";
import classNames from "classnames";
import {
  LinkedRelation,
  RelationType,
  AssignmentSnapShot,
} from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES,
  ACQUISITIONROUTES,
} from "@haywork/constants";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type AcquisitionListItemComponentProps = {
  acquisition: AssignmentSnapShot;
  onNavigate: (id: string) => void;
};
type Props = AcquisitionListItemComponentProps & ListChildComponentProps;

export const AcquisitionListItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ style, index, acquisition, onNavigate }) => {
      const {
        realEstateGroup,
        id,
        forSale,
        forRent,
        assignmentPhase,
        linkedClients,
      } = acquisition;

      const kindLabel = useMemo(() => {
        switch (true) {
          case forRent && forSale: {
            return "acquisition.rentAndSale";
          }
          case forRent: {
            return "acquisition.onlyRent";
          }
          case forSale:
          default: {
            return "acquisition.onlySale";
          }
        }
      }, [forSale, forRent]);

      const onListClickHandler = useCallback(() => {
        const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [id, onNavigate]);

      const onRelationClickHandler = useCallback(
        (e: MouseEvent<HTMLSpanElement>, relation: LinkedRelation) => {
          e.stopPropagation();
          const { id, typeOfRelation } = relation;
          let path = "";

          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            }
            case RelationType.Employee: {
              path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            }
            case RelationType.Office: {
              path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            }
            default: {
              path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
              break;
            }
          }

          onNavigate(path);
        },
        [onNavigate]
      );

      const clients = useMemo(() => {
        const clients = linkedClients || [];
        const amount = clients.length;
        const sliceLength = 3;
        const slice = clients.slice(0, sliceLength);

        return slice.map((client, idx) => {
          const { id, typeOfRelation } = client;
          return (
            <span key={id}>
              <span
                className="as-link"
                onClick={(event) =>
                  onRelationClickHandler(event, {
                    id,
                    typeOfRelation,
                  })
                }
              >
                {client.displayName}
              </span>
              {(idx < slice.length - 2 ||
                (idx < slice.length - 1 && amount > sliceLength)) && (
                <span>,&nbsp;</span>
              )}
              {idx === slice.length - 2 && amount <= sliceLength && (
                <span>
                  &nbsp;
                  <I18n value="and" />
                  &nbsp;
                </span>
              )}
              {idx === slice.length - 1 && amount > sliceLength && (
                <>
                  &nbsp;
                  <span className="as-link" onClick={onListClickHandler}>
                    <I18n
                      value="andTimesMore"
                      values={{ times: amount - sliceLength }}
                    />
                  </span>
                </>
              )}
            </span>
          );
        });
      }, [linkedClients, onListClickHandler, onRelationClickHandler]);

      return (
        <div
          style={{ ...style, top: parseFloat(style.top.toString()) + 30 }}
          styleName={classNames("item", "columnized", {
            zebra: index % 2 === 0,
          })}
          onClick={onListClickHandler}
        >
          <div styleName="column">{acquisition.publicReference}</div>
          <div styleName="column clients">{clients}</div>
          <div styleName="column">
            <I18n
              prefix="realEstateGroups"
              value={realEstateGroup.toString()}
            />
          </div>
          <div styleName="column">
            <I18n value={kindLabel} />
          </div>
          <div styleName="column">
            <I18n
              prefix="assignmentPhases"
              value={assignmentPhase.toString()}
            />
          </div>
        </div>
      );
    }
  )
);
