import {
  AssignmentPhase,
  RealEstateGroup,
  SearchAssignment,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST, SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  SearchAssignmentEditContainerProps,
  SearchAssignmentEditRouting,
} from "@haywork/modules/search-assignment";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { AddressUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import ExternalChanges from "@haywork/components/external-changes";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import { RootEntityType } from "@haywork/api/event-center";
import Presence from "@haywork/components/ui/presence";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SearchAssignmentEditComponentProps {}
interface State {
  previousRoute: string;
  nextRoute: string;
  confirmSave: boolean;
}
type Props = SearchAssignmentEditComponentProps &
  SearchAssignmentEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentEditComponent extends React.Component<
  Props,
  State
> {
  private routes: string[] = [
    SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI,
    SEARCHASSIGNMENTROUTES.EDIT_WHAT.URI,
    SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI,
    SEARCHASSIGNMENTROUTES.EDIT_LINKED_ASSIGNMENTS.URI,
    SEARCHASSIGNMENTROUTES.PUBLISH.URI,
  ];
  private currentRouteIndex: number = 0;

  constructor(props) {
    super(props);

    this.state = {
      nextRoute: null,
      previousRoute: null,
      confirmSave: false,
    };

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
  }

  public componentDidMount() {
    const { id } = this.props.searchAssignment;
    this.routes = this.routes.map((r) => route(r, { id }));
    this.setNextAndPreviousRoutes();
  }

  public render() {
    const { id, isNew } = this.props.searchAssignment;

    const valid = this.validateRoutes(this.props.searchAssignment);

    const whereRoute = classNames("item", {
      valid: valid.where,
    });
    const whatRoute = classNames("item", {
      valid: valid.what,
    });
    const contactsRoute = classNames("item", {
      valid: valid.contacts,
    });
    const linkedAssignmentsRoute = classNames("item", {
      disabled: isNew,
      valid: valid.linkedAssignments,
    });

    return (
      <div styleName="edit">
        <PageHeader
          title={this.getHeaderTitle()}
          actions={
            <>
              <Presence
                entityId={id}
                entityType={RootEntityType.SearchAssignment}
              />
              <Button
                label="save"
                category="primary"
                icon={<Icon name="save" size={18} light />}
                iconPosition="start"
                onClick={() => this.setState({ confirmSave: true })}
              />
              {this.props.canPublish && (
                <Button
                  label="activate"
                  category="primary"
                  icon={<Icon name="paper-plane" size={18} light />}
                  iconPosition="start"
                  onClick={this.onPublishClickHandler}
                />
              )}
            </>
          }
        />

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.SearchAssignment}
          onReloadEntity={this.props.reloadSearchAssignment}
        />

        <div styleName="edit__body">
          <SearchAssignmentEditRouting id={id} />
        </div>
        <div styleName="edit__navigation">
          <div styleName="button">
            {this.state.previousRoute && (
              <NavLink
                to={this.state.previousRoute}
                className="btn btn-default icon-left"
              >
                <i className="fal fa-fw fa-chevron-left" />
                <ResourceText resourceKey="previousText" />
              </NavLink>
            )}
          </div>

          <div styleName="navigation">
            <div styleName="navigation__list">
              <NavLink
                styleName={whereRoute}
                to={route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, { id })}
              >
                <div styleName="index">1</div>
                <ResourceText resourceKey="searchAssignmentWhere" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={whatRoute}
                to={route(SEARCHASSIGNMENTROUTES.EDIT_WHAT.URI, { id })}
              >
                <div styleName="index">2</div>
                <ResourceText resourceKey="searchAssignmentWhat" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={contactsRoute}
                to={route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, { id })}
              >
                <div styleName="index">3</div>
                <ResourceText resourceKey="searchAssignmentNavigationContacts" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={linkedAssignmentsRoute}
                to={route(SEARCHASSIGNMENTROUTES.EDIT_LINKED_ASSIGNMENTS.URI, {
                  id,
                })}
                onClick={this.cancelNavigation}
              >
                <div styleName="index">4</div>
                <ResourceText resourceKey="searchAssignmentNavigationLinkedAssignments" />
              </NavLink>
            </div>
          </div>
          <div styleName="button last">
            {this.state.nextRoute &&
              this.currentRouteIndex < this.routes.length - 2 && (
                <NavLink
                  to={this.state.nextRoute}
                  className="btn btn-success icon-right"
                  onClick={this.onNextClickHandler}
                >
                  <ResourceText resourceKey="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
            {this.props.canPublish &&
              this.currentRouteIndex === this.routes.length - 2 && (
                <NavLink
                  to={route(SEARCHASSIGNMENTROUTES.PUBLISH.URI, { id })}
                  className="btn btn-success icon-right"
                >
                  <ResourceText resourceKey="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
          </div>
        </div>

        <ConfirmOverwrite
          entityType={RootEntityType.SearchAssignment}
          entityId={id}
          saving={this.state.confirmSave}
          onCancel={() => this.setState({ confirmSave: false })}
          onConfirm={this.onSaveClickHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setNextAndPreviousRoutes(nextProps.location.pathname);
    }
  }

  private getHeaderTitle() {
    const { id, realEstateGroup } = this.props.searchAssignment;

    switch (this.props.location.pathname) {
      case route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, { id }): {
        return "whereAreYouLookingFor";
      }
      case route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, { id }):
        return "howShallWeInformYou";
      case route(SEARCHASSIGNMENTROUTES.EDIT_LINKED_ASSIGNMENTS.URI, { id }):
        return "foundAssignments";
      case route(SEARCHASSIGNMENTROUTES.PUBLISH.URI, { id }):
        return "publishSearchAssignment";
      default:
        return realEstateGroup === RealEstateGroup.Residential
          ? "whatAreYouLookingFor"
          : "whatAreYouLookingForAlt";
    }
  }

  private onNextClickHandler(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.searchAssignment.isNew && this.currentRouteIndex === 2) {
      event.preventDefault();
      if (!this.props.canSave) return this.props.toggleSaveModal(true);
      this.props.saveInitialSearchAssignment(this.props.searchAssignment);
    }
  }

  private cancelNavigation(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.searchAssignment.isNew) event.preventDefault();
  }

  private onSaveClickHandler() {
    this.setState({ confirmSave: false });
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.props.saveSearchAssignment(this.props.searchAssignment);
  }

  private onPublishClickHandler() {
    if (!this.props.canPublish) return;

    switch (true) {
      case /edit\/publish/gi.test(this.props.location.pathname): {
        const searchAssignment: SearchAssignment = {
          ...this.props.searchAssignment,
          assignmentPhase: AssignmentPhase.Initiated,
        };

        this.props.saveSearchAssignment(searchAssignment);
        return;
      }
      default: {
        this.props.publish(this.props.searchAssignment.id);
        return;
      }
    }
  }

  private setNextAndPreviousRoutes(pathname?: string) {
    this.currentRouteIndex = this.routes.reduce((state, route, idx) => {
      const path = pathname || this.props.location.pathname;
      if (new RegExp(escapeRegExp(route), "gi").test(path)) return idx;
      return state;
    }, -1);

    const nextRoute =
      this.currentRouteIndex <= this.routes.length &&
      this.currentRouteIndex !== -1
        ? this.routes[this.currentRouteIndex + 1]
        : null;
    const previousRoute =
      this.currentRouteIndex > 0
        ? this.routes[this.currentRouteIndex - 1]
        : null;

    this.setState({ nextRoute, previousRoute });
  }

  private validateRoutes(searchAssignment: SearchAssignment): {
    where: boolean;
    what: boolean;
    contacts: boolean;
    linkedAssignments: boolean;
  } {
    const locations = searchAssignment.locations || [];
    const valid = {
      where: false,
      what: false,
      contacts: false,
      linkedAssignments: false,
    };

    const filledLocation = locations.find((location) =>
      AddressUtil.doesLocationHaveAddress(location)
    );
    if (filledLocation) valid.where = true;
    if (
      searchAssignment.offerType &&
      (searchAssignment.typesPART ||
        searchAssignment.typesBOG ||
        searchAssignment.typesALV)
    )
      valid.what = true;
    if (
      searchAssignment.linkedRelations &&
      searchAssignment.linkedRelations.length > 0
    )
      valid.contacts = true;
    if (valid.where && valid.what && valid.contacts)
      valid.linkedAssignments = true;

    return valid;
  }
}
