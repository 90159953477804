import {
  SearchAssignment,
  SearchAssignmentLocation,
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const styles = require("./locations.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  searchAssignment: SearchAssignment;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetLocationsComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.renderLocation = this.renderLocation.bind(this);
  }

  public render() {
    const { id, locations, foreignId } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, { id });

    return (
      <div>
        {locations.map(this.renderLocation)}
        {!foreignId && (
          <p>
            <Link to={url}>
              <ResourceText resourceKey="changeSearchLocations" />
            </Link>
          </p>
        )}
      </div>
    );
  }

  private renderLocation(
    location: SearchAssignmentLocation,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { displayName } = location;
    if (!displayName) return null;

    return (
      <div styleName="location" key={idx}>
        <div styleName="location__icon">
          <i className="fa fa-map-marker" />
          <div styleName="no">{idx + 1}</div>
        </div>
        <div styleName="location__label">
          {displayName} {this.renderRadius(location.radius)}
        </div>
      </div>
    );
  }

  private renderRadius(radius: number): JSX.Element {
    return !!radius ? (
      <span>
        + {radius}
        km
      </span>
    ) : null;
  }
}
