import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";

const currentComponentStateSelector = (state: AppState) =>
  state.editable.currentComponentState;

export const currentComponentStateEntityId = createSelector(
  currentComponentStateSelector,
  (state) => {
    return (get(state, "id") ||
      get(state, "bundleId") ||
      get(state, "temporaryId") ||
      get(state, "objectAssignment.id") ||
      get(state, "projectAssignment.id") ||
      get(state, "acquisition.id") ||
      get(state, "session.id", null)) as string | null;
  }
);
