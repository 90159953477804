import { RootEntityType } from "@haywork/api/event-center";
import I18n from "@haywork/components/i18n";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";

const styles = require("./style.scss");

type Props = {
  entityType: RootEntityType | null;
  onClose: () => void;
};

export const BlockedViewComponent: FC<Props> = memo(
  CSSModules(styles)(({ children, entityType, onClose }) => {
    const intl = useIntl();

    const prefix = useMemo(
      () =>
        !entityType
          ? null
          : intl.formatMessage({
              id: `presenceRootEntityType.prefix.${entityType.toString()}`,
              defaultMessage: entityType.toString(),
            }),
      [entityType]
    );
    const type = useMemo(
      () =>
        !entityType
          ? null
          : intl.formatMessage({
              id: `presenceRootEntityType.${entityType.toString()}`,
              defaultMessage: entityType.toString(),
            }),
      [entityType]
    );

    return (
      <div styleName="wrapper">
        <div styleName="preview">{children}</div>
        <div styleName="overlay">
          <div styleName="info">
            <div styleName="section">
              <I18n value="blockedView.body" values={{ prefix, type }} asHtml />
            </div>
            {/* <div styleName="section">
              <Button
                label="blockedView.action"
                style={{ width: "100%" }}
                onClick={onClose}
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  })
);
