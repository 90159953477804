import * as React from "react";

import { EmailAddressType } from "@haywork/api/kolibri";
import { MappedDropdownValue } from "@haywork/modules/filter";
import {
  Form,
  FormControls,
  FormReference,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

interface EmployeeModalComponentProps {
  officeFilterOptions?: MappedDropdownValue[];
}

interface EmployeeModalComponentState {}

export class EmployeeModalComponent extends React.Component<
  EmployeeModalComponentProps,
  EmployeeModalComponentState
> {
  private formControls: FormControls;
  private emailAddressesFormControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      emailAddresses: {
        value: FormControlUtil.returnObjectPathOrNull(
          "a name",
          "emailAddresses"
        ),
      },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Work },
      offices: { value: "" },
    };
  }

  public render() {
    return (
      <div className="container-modal">
        <Form
          formControls={this.formControls}
          name="relation"
          form={(formRef) => (this.form = formRef)}
        >
          <div className="modal-header">
            <p>
              Vul hier het e-mailadres van de gebruiker die je wilt toevoegen en
              het kantoor waaraan je de medewerker wilt toevoegen in.
            </p>
          </div>
          <div className="modal-body">
            <div className="form__row">
              <label>
                <ResourceText resourceKey="emailAddresses" />
              </label>
              <Input.Array
                name="emailAddresses"
                formControls={this.emailAddressesFormControls}
                className="form__group stretch"
              >
                <div className="column">
                  <Input.Text name="address" placeholder="E-mailaddress" />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.NewSelect
                    name="offices"
                    values={this.props.officeFilterOptions}
                    displayProp="label"
                    valuesProp="value"
                  />
                </div>
              </Input.Array>
            </div>
          </div>
          <div className="modal-footer">
            <p className="text-info">
              Een extra Kolibri gebruiker kost €10,- per maand en wordt
              automatisch in rekening gebracht op je eerstvolgende factuur.
            </p>
          </div>
        </Form>
      </div>
    );
  }
}
