import { Garage, GarageType } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FloorSpace } from "../room-layout.component";
import { GarageContainerProps } from "./containers";

const styles = require("./forms.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface RoomLayoutGarageFormComponentProps {
  space: FloorSpace<Garage>;
  onDelete: () => void;
  onSubmit: (space: FloorSpace<Garage>, closeExpand: boolean) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class RoomLayoutGarageFormComponent extends React.Component<
  RoomLayoutGarageFormComponentProps & GarageContainerProps
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeSubmitHandler = this.onChangeSubmitHandler.bind(this);
    this.onOkayClickHandler = this.onOkayClickHandler.bind(this);

    this.formControls = {
      garageType: {
        value: value(this.props.space, "space.garageType", GarageType.Garage),
      },
      amenities: { value: value(this.props.space, "space.amenities") },
      insulationTypes: {
        value: value(this.props.space, "space.insulationTypes"),
      },
      length: {
        value: value(this.props.space, "space.size.length"),
        onChange: this.onChangeHandler.bind(this),
      },
      width: {
        value: value(this.props.space, "space.size.width"),
        onChange: this.onChangeHandler.bind(this),
      },
      height: {
        value: value(this.props.space, "space.size.height"),
        onChange: this.onChangeHandler.bind(this),
      },
      area: { value: value(this.props.space, "space.size.area") },
      volume: { value: value(this.props.space, "space.size.volume") },
      capacity: { value: value(this.props.space, "space.capacity") },
    };
  }

  public render() {
    return (
      <Form
        name="room-layout-garage"
        formControls={this.formControls}
        asSubForm
        form={(ref) => (this.formRef = ref)}
        onSubmit={this.onSubmitHandler}
        onChange={this.onChangeSubmitHandler}
      >
        {/* Garage type */}
        <div className="form__row">
          <label htmlFor="garageType">
            <ResourceText resourceKey="garageType" /> <Ui.RequiredForPublish />
          </label>
          <Input.RadioGroup name="garageType" asButtonList multiline>
            {this.props.garageTypeOptions.map((garageTypeOption, idx) => (
              <Input.Radio
                value={garageTypeOption.value}
                label={garageTypeOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Size */}
        <div className="form__row">
          <label htmlFor="size">
            <ResourceText resourceKey="garageSize" />
          </label>
          <div styleName="size-controls">
            <div styleName="column">
              {/* Garage length */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label className="pre">
                      <ResourceText resourceKey="roomLengthLabel" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="length" pretty />
                      <div className="post">
                        <ResourceText resourceKey="meterShort" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Garage width */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label className="pre">
                      <ResourceText resourceKey="roomWidthLabel" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="width" pretty />
                      <div className="post">
                        <ResourceText resourceKey="meterShort" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Garage height */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label className="pre">
                      <ResourceText resourceKey="roomHeightLabel" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="height" pretty />
                      <div className="post">
                        <ResourceText resourceKey="meterShort" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div styleName="column__spacer" />

            <div styleName="column">
              {/* Garage area */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label className="pre">
                      <ResourceText resourceKey="roomAreaLabel" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="area" pretty round />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Garage volume */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label className="pre">
                      <ResourceText resourceKey="roomVolumeLabel" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <div className="input__helper">
                      <Input.Number name="volume" pretty />
                      <div className="post">
                        <ResourceText resourceKey="cubicMeters" asHtml />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Capacity */}
        <div className="form__row" styleName="capacity">
          <div className="form__group">
            <div className="column">
              <label className="pre">
                <ResourceText resourceKey="garageCapacity" />
              </label>
            </div>
            <div className="column__spacer" />
            <div className="column">
              <div className="input__helper">
                <Input.Number name="capacity" min={0} max={100} />
                <div className="post full">
                  <ResourceText resourceKey="garageCapacityCars" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Amenities */}
        <div className="form__row">
          <label htmlFor="amenities">
            <ResourceText resourceKey="amenities" />
          </label>
          <Input.Multi name="amenities" values={this.props.amenitiesOptions} />
        </div>

        {/* Isolation type */}
        <div className="form__row">
          <label htmlFor="insulationTypes">
            <ResourceText resourceKey="insulationTypes" />
          </label>
          <Input.Multi
            name="insulationTypes"
            values={this.props.isolationTypeOptions}
          />
        </div>

        <div styleName="divider" />

        <div styleName="button-footer">
          <div className="btn btn-success" onClick={this.onOkayClickHandler}>
            <ResourceText resourceKey="okay" />
          </div>
          <div className="btn btn-danger" onClick={this.props.onDelete}>
            <ResourceText resourceKey="remove" />
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(
    ref: RawFormControl,
    get: (name: string) => RawFormControl
  ) {
    const length = get("length");
    const width = get("width");
    const height = get("height");

    const values = {};

    if (length.value && width.value) {
      const area = parseFloat(length.value) * parseFloat(width.value);
      values["area"] = area;
    }

    if (length.value && width.value && height.value) {
      const volume =
        parseFloat(length.value) *
        parseFloat(width.value) *
        parseFloat(height.value);
      values["volume"] = volume;
    }

    return Object.keys(values).length > 0 ? values : null;
  }

  private onOkayClickHandler() {
    if (!this.formRef) return;
    this.formRef.submit();
  }

  private onChangeSubmitHandler(values: FormReturnValue) {
    this.props.onSubmit(this.getUpdatedSpace(values), false);
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.props.onSubmit(this.getUpdatedSpace(values), true);
  }

  private getUpdatedSpace(values: FormReturnValue): FloorSpace<Garage> {
    const { space } = this.props;
    return {
      ...space,
      space: {
        ...space.space,
        garageType: values.garageType,
        insulationTypes: values.insulationTypes,
        amenities: values.amenities,
        capacity: values.capacity,
        size: {
          ...space.space.size,
          length: values.length,
          width: values.width,
          height: values.height,
          area: values.area,
          volume: values.volume,
        },
      },
    };
  }
}
