import * as Ably from "ably";
import { LiveDataConnection } from "./";

export enum ActionType {
  SetStatus = "LiveData.SetStatus"
}

type SetStatus = {
  type: ActionType.SetStatus;
  status: Ably.Types.ConnectionState;
  connection: LiveDataConnection;
};
const setStatus = (
  status: Ably.Types.ConnectionState,
  connection: LiveDataConnection
) => ({
  type: ActionType.SetStatus,
  status,
  connection
});

export type ActionTypes = SetStatus;
export const actions = {
  setStatus
};
