import { ObjectAssignment } from "@haywork/api/kolibri";
import { Group } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import head from "lodash-es/head";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentEditMarketingMlsContainerProps } from "../../containers/marketing-mls.container";
import MlsGroup from "./group";

const styles = require("./marketing-mls.component.scss");

export type AssignmentEditMarketingMlsComponentProps = {};
type Props = AssignmentEditMarketingMlsComponentProps &
  AssignmentEditMarketingMlsContainerProps;

export const AssignmentEditMarketingMlsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      agencyGroups,
      objectAssignment,
      currentComponentState,
      path,
      updateAssignment,
    }) => {
      const handleGroupChange = useCallback(
        (group: Group, value: boolean) => {
          let assignment: ObjectAssignment = { ...objectAssignment };

          if (value) {
            const date = moment()
              .endOf("day")
              .add(group.defaultReleaseDaysInFuture || 1, "days");
            const mlsReleaseDate = moment(date).toDate();

            assignment = {
              ...assignment,
              mlsGroup: group.name,
              mlsReleaseDate,
            };
          } else {
            assignment = {
              ...assignment,
              mlsGroup: undefined,
              mlsReleaseDate:
                assignment.mlsReleaseDate ||
                moment()
                  .endOf("day")
                  .add(group.defaultReleaseDaysInFuture || 0, "days")
                  .toDate(),
            };
          }

          const componentState = {
            ...currentComponentState,
            objectAssignment: assignment,
          };

          updateAssignment(componentState, path);
        },
        [
          agencyGroups,
          objectAssignment,
          currentComponentState,
          path,
          updateAssignment,
        ]
      );

      const handleReleaseDateChange = useCallback(
        (group: Group, date: Date) => {
          const mlsReleaseDate = moment(date).endOf("day").toDate();

          const assignment = {
            ...objectAssignment,
            mlsGroup: group.name,
            mlsReleaseDate,
          };

          const componentState = {
            ...currentComponentState,
            objectAssignment: assignment,
          };

          updateAssignment(componentState, path);
        },
        [
          agencyGroups,
          objectAssignment,
          currentComponentState,
          path,
          updateAssignment,
        ]
      );

      return (
        <>
          <div className="alert alert-info">
            <I18n
              value="assignment.marketing.mls.infoMessage"
              values={{
                count: agencyGroups.length || 0,
                group: head(agencyGroups)?.name,
              }}
            />
          </div>
          <div>
            {agencyGroups.map((group, idx) => (
              <MlsGroup
                group={group}
                objectGroupName={objectAssignment.mlsGroup}
                objectReleaseDate={objectAssignment.mlsReleaseDate}
                handleGroupChange={handleGroupChange}
                handleReleaseDateChange={handleReleaseDateChange}
                key={idx}
              />
            ))}
          </div>
        </>
      );
    }
  )
);
