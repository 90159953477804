import { FolderCategory, MessageEventType } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { DragDropType } from "@haywork/enum/drag-drop";
import { EmailDraft, EmailMessage } from "@haywork/stores/email-v2";
import classNames from "classnames";
import first from "lodash-es/first";
import * as moment from "moment";
import * as React from "react";
import {
  FC,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { DragPreviewImage, useDrag } from "react-dnd";
import { ListChildComponentProps } from "react-window";
import shave from "shave";
import { TextPlaceholder } from "@haywork/components/ui/empty-state";
import { ConfirmComponent } from "@haywork/modules/shared";

const styles = require("./style.scss");
const dragImage = require("static/envelope.png");

type Props = ListChildComponentProps & {
  rowData: EmailMessage | EmailDraft;
  folderCategory: FolderCategory;
  currentMessageId: string | null;
  canPerformEditActions: boolean;
  currentFolderIsTrash: boolean;
  hasTrashFolder: boolean;
  showFolderName: boolean;
  onClick: (
    item: EmailMessage | EmailDraft,
    folderCategory: FolderCategory
  ) => void;
  onToggleUnRead: (id: string, isRead: boolean, accountId: string) => void;
  onToggleBookmarked: (
    id: string,
    isBookmarked: boolean,
    accountId: string
  ) => void;
  onDelete: (item: EmailMessage) => void;
  onDraftDelete: (item: EmailDraft) => void;
};

export const RowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      style,
      rowData,
      currentFolderIsTrash,
      hasTrashFolder,
      currentMessageId,
      folderCategory,
      canPerformEditActions,
      showFolderName,
      onToggleBookmarked,
      onToggleUnRead,
      onClick,
      onDelete,
      onDraftDelete,
    }) => {
      const onClickCallback = useCallback(
        (item: EmailMessage | EmailDraft) => {
          onClick(item, folderCategory);
        },
        [folderCategory, onClick]
      );

      return (
        <div styleName="wrapper" style={style}>
          {!rowData ? (
            <EmptyStateComponent />
          ) : folderCategory === FolderCategory.Drafts ? (
            <DraftComponent
              rowData={rowData as EmailDraft}
              onClick={onClickCallback}
              onDelete={onDraftDelete}
            />
          ) : (
            <MessageComponent
              rowData={rowData as EmailMessage}
              onToggleUnRead={onToggleUnRead}
              onClick={onClickCallback}
              currentMessageId={currentMessageId}
              onToggleBookmarked={onToggleBookmarked}
              canPerformEditActions={canPerformEditActions}
              onDelete={onDelete}
              currentFolderIsTrash={currentFolderIsTrash}
              hasTrashFolder={hasTrashFolder}
              showFolderName={showFolderName}
              folderCategory={folderCategory}
            />
          )}
        </div>
      );
    }
  )
);

const EmptyStateComponent: FC = memo(
  CSSModules(styles, { allowMultiple: true })(() => {
    return (
      <div styleName="empty-state">
        <TextPlaceholder numberOfLines={3} style={{ width: "100%" }} />
      </div>
    );
  })
);

type DraftComponentProps = {
  rowData: EmailDraft;
  onClick: (item: EmailDraft) => void;
  onDelete: (item: EmailDraft) => void;
};
const DraftComponent: FC<DraftComponentProps> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ rowData, onClick, onDelete }) => {
      if (!rowData) return;
      const [showDeleteConfirm, setShowConfirmDelete] = useState(false);
      const { subject, snippet, date, to } = rowData;
      const snippetRef = useRef<HTMLDivElement>();

      const title = useMemo(() => {
        if (!to || !to.length) return <I18n value="email.message.missingTo" />;
        const { email, name } = first(to);
        return name || email || <I18n value="email.message.missingTo" />;
      }, [to]);

      const saved = useMemo(() => {
        if (!date) return null;
        const messageDate = moment(date);
        const now = moment();

        switch (true) {
          case messageDate.isSame(now, "day"): {
            return messageDate.format("HH:mm");
          }
          case messageDate.isSame(now.subtract(1, "day"), "day"): {
            return (
              <I18n
                value="email.messageDate.yesterday"
                values={{ time: messageDate.format("HH:mm") }}
              />
            );
          }
          case messageDate.isSame(now.subtract(2, "days"), "day"): {
            return (
              <I18n
                value="email.messageDate.dayBeforeYesterday"
                values={{ time: messageDate.format("HH:mm") }}
              />
            );
          }
          case messageDate.isSame(now, "month"): {
            return messageDate.format("dd D MMM YYYY");
          }
          default: {
            return messageDate.format("DD-MM-YYYY");
          }
        }
      }, [date]);

      const onClickCallback = useCallback(() => {
        onClick(rowData);
      }, [rowData, onClick]);

      const onDeleteCallback = useCallback(
        (event?: MouseEvent<HTMLDivElement>) => {
          if (!!event) {
            event.stopPropagation();
          }

          if (!showDeleteConfirm) {
            setShowConfirmDelete(true);
            return;
          }

          setShowConfirmDelete(false);
          onDelete(rowData);
        },
        [rowData, onDelete, showDeleteConfirm, setShowConfirmDelete]
      );

      useEffect(() => {
        if (!!snippetRef.current) {
          shave(snippetRef.current, 36);
        }
      }, []);

      return (
        <>
          <div styleName="message" onClick={onClickCallback}>
            <div styleName="from">
              <div styleName="title">{title}</div>

              <div styleName="date">{saved}</div>
              <div styleName="flags">
                <div
                  styleName="flag clickable show-on-hover"
                  onClick={onDeleteCallback}
                >
                  <Icon
                    name="trash-alt"
                    size={14}
                    color={Colors.Danger}
                    regular
                  />
                </div>
              </div>
            </div>
            <div styleName="subject">
              <div styleName="title">
                {subject || <I18n value="email.message.missingSubject" />}
              </div>
            </div>
            <div styleName="snippet" ref={snippetRef}>
              {snippet || <I18n value="email.message.missingSnippet" />}
            </div>
          </div>

          <ConfirmComponent
            visible={showDeleteConfirm}
            titleResourceKey="email.draft.deleteConfirmTitle"
            bodyResourceKey="email.draft.deleteConfirmBody"
            onClose={() => setShowConfirmDelete(false)}
            onConfirm={onDeleteCallback}
          />
        </>
      );
    }
  )
);

type MessageComponentProps = {
  rowData: EmailMessage;
  currentMessageId: string | null;
  canPerformEditActions: boolean;
  currentFolderIsTrash: boolean;
  hasTrashFolder: boolean;
  showFolderName: boolean;
  folderCategory: FolderCategory;
  onClick: (item: EmailMessage) => void;
  onToggleUnRead: (id: string, isRead: boolean, accountId: string) => void;
  onToggleBookmarked: (
    id: string,
    isBookmarked: boolean,
    accountId: string
  ) => void;
  onDelete: (item: EmailMessage) => void;
};
const MessageComponent: FC<MessageComponentProps> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      rowData,
      onToggleUnRead,
      onClick,
      currentMessageId,
      onToggleBookmarked,
      canPerformEditActions,
      onDelete,
      currentFolderIsTrash,
      hasTrashFolder,
      showFolderName,
    }) => {
      if (!rowData) return;
      const {
        to,
        from,
        subject,
        snippet,
        date,
        id,
        accountId,
        folderId,
        files,
        _metaData,
        bookmarked,
        unread,
        folder,
      } = rowData;
      const snippetRef = useRef<HTMLDivElement>();
      const [_, drag, preview] = useDrag({
        item: {
          type: DragDropType.Email,
          id,
          folderId,
          accountId,
        },
        canDrag: () =>
          canPerformEditActions &&
          folderCategory !== FolderCategory.SynchronizingMessage,
      });

      const folderCategory = useMemo(
        () =>
          !folder
            ? FolderCategory.UserCreated
            : folder.category || FolderCategory.UserCreated,
        [folder]
      );

      const title = useMemo(() => {
        const refs =
          folderCategory === FolderCategory.Sent ? to || [] : from || [];

        if (!refs.length)
          return (
            <I18n
              value={
                folderCategory === FolderCategory.Sent
                  ? "email.message.missingTo"
                  : "email.message.missingFrom"
              }
            />
          );
        const { email, name } = first(refs);
        return (
          name ||
          email || (
            <I18n
              value={
                folderCategory === FolderCategory.Sent
                  ? "email.message.missingTo"
                  : "email.message.missingFrom"
              }
            />
          )
        );
      }, [from, to, folderCategory]);

      const received = useMemo(() => {
        if (!date) return null;
        const messageDate = moment(date);
        const now = moment();

        switch (true) {
          case messageDate.isSame(now, "day"): {
            return (
              <I18n
                value="email.messageDate.today"
                values={{ time: messageDate.format("HH:mm") }}
              />
            );
          }
          case messageDate.isSame(now.subtract(1, "day"), "day"): {
            return (
              <I18n
                value="email.messageDate.yesterday"
                values={{ time: messageDate.format("HH:mm") }}
              />
            );
          }
          case messageDate.isSame(now.subtract(1, "days"), "day"): {
            return (
              <I18n
                value="email.messageDate.dayBeforeYesterday"
                values={{ time: messageDate.format("HH:mm") }}
              />
            );
          }
          case messageDate.isSame(now, "month"): {
            return messageDate.format("dd D MMM HH:mm");
          }
          case messageDate.isSame(now, "year"): {
            return messageDate.format("D MMM HH:mm");
          }
          default: {
            return messageDate.format("DD-MM-YYYY HH:mm");
          }
        }
      }, [date]);

      const toggleBookmarkedCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();

          if (folderCategory === FolderCategory.SynchronizingMessage) {
            return;
          }

          onToggleBookmarked(id, !bookmarked, accountId);
        },
        [bookmarked, id, accountId, folderCategory, onToggleBookmarked]
      );

      const onDeleteCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();

          if (folderCategory === FolderCategory.SynchronizingMessage) {
            return;
          }

          onDelete(rowData);
        },
        [rowData, folderCategory, onDelete]
      );

      const flags = useMemo(() => {
        const hasAttachments = !!(files || []).filter(
          (file) => file.isAttachment
        ).length;
        let isForwarded = false;
        let isReplied = false;
        let isOpened = false;

        if (!!_metaData) {
          const { messageEvents } = _metaData;
          (messageEvents || []).forEach((event) => {
            if (!isForwarded && event.type === MessageEventType.Forwarded)
              isForwarded = true;
            if (!isReplied && event.type === MessageEventType.Replied)
              isReplied = true;
            if (!isOpened && event.type === MessageEventType.Opened)
              isOpened = true;
          });
        }

        return (
          <>
            {!!isReplied && (
              <div styleName="flag">
                <Icon name="reply" size={14} color={Colors.MediumGray} />
              </div>
            )}
            {!!isForwarded && (
              <div styleName="flag">
                <Icon name="share" size={14} color={Colors.MediumGray} />
              </div>
            )}
            {!!isOpened && (
              <div styleName="flag">
                <Icon name="eye" size={14} color={Colors.MediumGray} />
              </div>
            )}
            <div styleName="flag clickable" onClick={toggleBookmarkedCallback}>
              <Icon
                name="pennant"
                size={14}
                color={bookmarked ? Colors.Danger : Colors.MediumGray}
                regular={!bookmarked}
                solid={bookmarked}
              />
            </div>
            {!!hasAttachments && (
              <div styleName="flag">
                <Icon
                  name="paperclip"
                  size={14}
                  color={Colors.MediumGray}
                  regular
                />
              </div>
            )}
            {!!hasTrashFolder &&
              !currentFolderIsTrash &&
              folderCategory !== FolderCategory.SynchronizingMessage && (
                <div
                  styleName="flag clickable show-on-hover"
                  onClick={onDeleteCallback}
                >
                  <Icon
                    name="trash-alt"
                    size={14}
                    color={Colors.Danger}
                    regular
                  />
                </div>
              )}
          </>
        );
      }, [
        files,
        _metaData,
        bookmarked,
        folderCategory,
        toggleBookmarkedCallback,
        onDeleteCallback,
      ]);

      const toggleUnreadCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();

          if (folderCategory === FolderCategory.SynchronizingMessage) {
            return;
          }

          onToggleUnRead(id, unread, accountId);
        },
        [unread, id, accountId, folderCategory, onToggleUnRead]
      );

      const onClickCallback = useCallback(() => {
        onClick(rowData);
        const { unread, id, accountId } = rowData;
        if (
          rowData.unread &&
          folderCategory !== FolderCategory.SynchronizingMessage
        ) {
          onToggleUnRead(id, unread, accountId);
        }
      }, [rowData, folderCategory, onClick, onToggleUnRead]);

      useEffect(() => {
        if (!!snippetRef.current) {
          shave(snippetRef.current, 36);
        }
      }, []);

      const folderName = useMemo(() => {
        if (!rowData?.folder || !showFolderName) return null;
        const { category, displayName } = rowData.folder;

        if (category === FolderCategory.UserCreated && !!displayName) {
          return <>{displayName}</>;
        }

        if (!!category && category !== FolderCategory.UserCreated) {
          return <I18n value={`folderCategory.${category.toString()}`} />;
        }

        return null;
      }, [rowData, showFolderName]);

      return (
        <>
          <DragPreviewImage connect={preview} src={dragImage} />
          <div
            styleName={classNames("message", {
              active: currentMessageId === id,
            })}
            ref={drag}
            onClick={onClickCallback}
          >
            <div
              styleName={classNames("unread-status", { unread })}
              onClick={toggleUnreadCallback}
            />
            <div styleName="from">
              <div styleName="title">{title}</div>
              <div styleName="flags">{flags}</div>
            </div>
            <div styleName={classNames("subject", { unread })}>
              <div styleName="title">
                {subject || <I18n value="email.message.missingSubject" />}
              </div>
              {!!folderName && <div styleName="folder">{folderName}</div>}
              <div styleName="date">{received}</div>
            </div>
            <div styleName="snippet" ref={snippetRef}>
              {snippet || <I18n value="email.message.missingSnippet" />}
            </div>
          </div>
        </>
      );
    }
  )
);
