import {
  AcquisitionAssignment,
  RelationType,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { ACQUISITIONROUTES } from "@haywork/constants";
import {
  Form,
  FormReference,
  FormReturnValue,
  Input,
  FormControls,
  Validators,
} from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import {
  FC,
  useMemo,
  useRef,
  memo,
  useState,
  useEffect,
  useCallback,
} from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionEditDetailScreenContainerProps } from "./edit-detail.container";
import SearchAssignments from "../../components/search-assignment-suggestions";
import FormattedText from "@haywork/components/ui/formatted-text";
import { Ui } from "@haywork/modules/ui";
import { NewEntityType } from "@haywork/enum";
import { NewEntity } from "@haywork/modules/new-entity";

export type AcquisitionDetailEditScreenProps = {};
type Props = AcquisitionDetailEditScreenProps &
  AcquisitionEditDetailScreenContainerProps;

const styles = require("./edit-detail.scss");
const route = RouteUtil.mapStaticRouteValues;

const enum ForSaleAndForRentStatuses {
  ForSale = "ForSale",
  ForRent = "ForRent",
  ForSaleAndForRent = "ForSaleAndForRent",
}

export const AcquisitionDetailEditScreen: FC<Props> = memo(
  CSSModules(styles, {
    allowMultiple: true,
  })(
    ({
      employees,
      offices,
      realEstateGroups,
      assignmentPhases,
      acquisition,
      updateCache,
      navigate,
    }) => {
      const form = useRef<FormReference>(null);
      const {
        forSale,
        forRent,
        linkedClients,
        id,
        linkedSearchAssignment,
        dateTimeModified,
      } = acquisition;
      const [relationIds, setRelationIds] = useState(
        (linkedClients || []).map((client) => client.id)
      );
      const [searchAssignmentIds, setSearchAssignmentIds] = useState(
        (linkedSearchAssignment || []).map((assignment) => assignment.id)
      );
      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] = useState(null);

      const detailPath = useMemo(() => {
        return route(ACQUISITIONROUTES.DETAIL.URI, {
          id,
        });
      }, []);

      const formControls = useMemo(() => {
        const {
          forSale,
          forRent,
          assignmentPhase,
          linkedClients,
          linkedEmployee,
          linkedOffice,
          realEstateGroup,
          commissionGross,
          commissionPercent,
          commissionGrossRent,
          commissionPercentRent,
        } = acquisition;

        let forSaleValue: ForSaleAndForRentStatuses;

        switch (true) {
          case !!forSale && !!forRent: {
            forSaleValue = ForSaleAndForRentStatuses.ForSaleAndForRent;
            break;
          }

          case !!forRent: {
            forSaleValue = ForSaleAndForRentStatuses.ForRent;
            break;
          }
          case !!forSale:
          default: {
            forSaleValue = ForSaleAndForRentStatuses.ForSale;
            break;
          }
        }

        return {
          assignmentPhase: { value: assignmentPhase },
          linkedClients: {
            value: linkedClients,
            onChange: (ref) => {
              const relationIds = (ref.value || []).map((client) => client.id);
              setRelationIds(relationIds);
            },
            validators: [Validators.arrayMinCount(1)],
          },
          linkedEmployee: { value: linkedEmployee },
          linkedOffice: { value: linkedOffice },
          realEstateGroup: { value: realEstateGroup },
          forSale: { value: forSaleValue },
          commissionGross: { value: commissionGross },
          commissionPercent: { value: commissionPercent },
          commissionGrossRent: { value: commissionGrossRent },
          commissionPercentRent: { value: commissionPercentRent },
        } as FormControls;
      }, []);

      const onNewEntityCloseHandler = () => {
        setNewEntityVisible(false);
      };

      const onNewRelationHandler = (relation: RelationSnapShot) => {
        let { linkedClients } = form.current.getValues();
        linkedClients = linkedClients || [];

        form.current.update({
          linkedClients: [...linkedClients, relation],
        });

        setNewEntityVisible(false);
      };

      const onChangeHandler = (values: FormReturnValue) => {
        let updatedComponentState: AcquisitionAssignment = {
          ...acquisition,
          ...values,
        };
        switch (values.forSale) {
          case ForSaleAndForRentStatuses.ForSaleAndForRent: {
            updatedComponentState = {
              ...updatedComponentState,
              forSale: true,
              forRent: true,
            };
            break;
          }
          case ForSaleAndForRentStatuses.ForSale: {
            updatedComponentState = {
              ...updatedComponentState,
              forSale: true,
              forRent: false,
            };
            break;
          }
          case ForSaleAndForRentStatuses.ForRent: {
            updatedComponentState = {
              ...updatedComponentState,
              forSale: false,
              forRent: true,
            };
            break;
          }
          default:
            break;
        }

        updateCache(updatedComponentState, detailPath);
      };

      const updateLinkedSearchAssignments = (id?: string) => {
        if (id) {
          const updatedSearchAssignmentIds = searchAssignmentIds.includes(id)
            ? searchAssignmentIds.filter(
                (searchAssignmentId) => searchAssignmentId !== id
              )
            : [...searchAssignmentIds, id];

          const updatedComponentState: AcquisitionAssignment = {
            ...acquisition,
            linkedSearchAssignment: updatedSearchAssignmentIds.map((id) => ({
              id,
            })),
          };

          setSearchAssignmentIds(updatedSearchAssignmentIds);
          updateCache(updatedComponentState, detailPath);
        } else {
          const updatedComponentState: AcquisitionAssignment = {
            ...acquisition,
            linkedSearchAssignment: [],
          };

          setSearchAssignmentIds([]);
          updateCache(updatedComponentState, detailPath);
        }
      };

      const addNewRelation = (name: string) => {
        if (newEntityVisible) return;
        setNewEntityVisible(true);
        setNewEntityOptions({
          type: NewEntityType.Relation,
          newRelation: {
            name,
          },
        });
      };

      const handleExternalChanges = useCallback(
        (dateTimeModified: Date) => {
          if (!form || !form.current || !dateTimeModified) return;
          const {
            assignmentPhase,
            linkedClients,
            linkedEmployee,
            linkedOffice,
            realEstateGroup,
            commissionGross,
            commissionPercent,
            commissionGrossRent,
            commissionPercentRent,
            forSale,
            forRent,
          } = acquisition;

          let forSaleValue: ForSaleAndForRentStatuses;

          switch (true) {
            case !!forSale && !!forRent: {
              forSaleValue = ForSaleAndForRentStatuses.ForSaleAndForRent;
              break;
            }

            case !!forRent: {
              forSaleValue = ForSaleAndForRentStatuses.ForRent;
              break;
            }
            case !!forSale:
            default: {
              forSaleValue = ForSaleAndForRentStatuses.ForSale;
              break;
            }
          }

          form.current.update(
            {
              assignmentPhase,
              linkedClients,
              linkedEmployee,
              linkedOffice,
              realEstateGroup,
              forSale: forSaleValue,
              commissionGross,
              commissionPercent,
              commissionGrossRent,
              commissionPercentRent,
            },
            true
          );
        },
        [form, acquisition]
      );

      useEffect(() => {
        handleExternalChanges(dateTimeModified);
      }, [handleExternalChanges, dateTimeModified]);

      return (
        <div styleName="edit-detail" id="scroll-to-top">
          <div className="container-fluid">
            <Form
              name="acquisitionForm"
              formControls={formControls}
              form={(ref) => (form.current = ref)}
              onChange={onChangeHandler}
            >
              <StepperComponent
                initial={0}
                scrollToElementId="scroll-to-top"
                openAll
              >
                <StepComponent title="general">
                  <div className="form__row">
                    <label htmlFor="subject">
                      <I18n value="status" />
                    </label>
                    <div className="input__wrapper">
                      <Input.NewSelect
                        name="assignmentPhase"
                        values={assignmentPhases}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="subject">
                      <span>
                        <I18n value="acquisitions.edit.whoarethevendors" />
                      </span>
                      <Ui.RequiredForPublish hideHint />
                    </label>
                    <div className="input__wrapper">
                      <Input.RelationQuery
                        name="linkedClients"
                        multiple
                        filterByRelationTypes={[
                          RelationType.ContactCompany,
                          RelationType.ContactPerson,
                        ]}
                        onNavigateToRelation={navigate}
                        onAdd={addNewRelation}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="linkedEmployee">
                      <I18n value="whosAssignment" />
                    </label>
                    <Input.NewSelect
                      name="linkedEmployee"
                      values={employees}
                      displayProp="displayName"
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="linkedOffice">
                      <I18n value="whichAgency" />
                    </label>
                    <Input.NewSelect
                      name="linkedOffice"
                      values={offices}
                      displayProp="displayName"
                    />
                  </div>
                </StepComponent>
                <StepComponent title="theassignment">
                  <div styleName="editRealEstateGroup" className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.RadioGroup name="realEstateGroup" asButtonList>
                          {realEstateGroups.map((realEstateGroup, idx) => (
                            <Input.Radio
                              label={realEstateGroup.displayName}
                              value={realEstateGroup.value}
                              key={idx}
                            />
                          ))}
                        </Input.RadioGroup>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.RadioGroup name="forSale" asButtonList>
                          <Input.Radio
                            label="sale"
                            value={ForSaleAndForRentStatuses.ForSale}
                          />
                          <Input.Radio
                            label="rent"
                            value={ForSaleAndForRentStatuses.ForRent}
                          />
                          <Input.Radio
                            label="forSaleAndForRent"
                            value={ForSaleAndForRentStatuses.ForSaleAndForRent}
                          />
                        </Input.RadioGroup>
                      </div>
                    </div>
                  </div>

                  <SearchAssignments
                    relationIds={relationIds}
                    searchAssignmentIds={searchAssignmentIds}
                    toggleSearchAssignment={updateLinkedSearchAssignments}
                  />
                </StepComponent>
                <StepComponent title="financial">
                  {!!forSale && (
                    <div className="form__row">
                      <div className="form__group">
                        <div styleName="column financial">
                          <label htmlFor="commissionGross">
                            <I18n value="acquisition.courtage.sale" />
                          </label>
                          <div styleName="row__input" className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number
                              name="commissionGross"
                              pretty={true}
                            />
                          </div>
                          <I18n value="andor" />
                          <div styleName="row__input" className="input__helper">
                            <Input.Number
                              name="commissionPercent"
                              max={100}
                              pretty={true}
                            />
                            <div className="post">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!!forRent && (
                    <div className="form__row">
                      <div className="form__group">
                        <div styleName="column financial">
                          <label htmlFor="commissionGrossRent">
                            <I18n value="acquisition.courtage.rent" />
                          </label>
                          <div styleName="row__input" className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number
                              name="commissionGrossRent"
                              pretty={true}
                            />
                          </div>
                          <I18n value="andor" />
                          <div styleName="row__input" className="input__helper">
                            <Input.Number
                              name="commissionPercentRent"
                              max={100}
                              pretty={true}
                            />
                            <div className="post">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </StepComponent>
              </StepperComponent>
            </Form>
          </div>
          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={onNewEntityCloseHandler}
            onNewRelation={onNewRelationHandler}
          />
        </div>
      );
    }
  )
);
