import {
  ActiveFilter,
  TaskDelegateFilter,
  TaskStatus,
} from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { EmployeeThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState, taskActionsV2 } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { categoryValues, employeeValues } from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  employeeValues: SingleFilterValue[];
  taskStatusValuesOld: SingleFilterValue[];
  categoryValues: SingleFilterValue[];
  filterByActiveValues: SingleFilterValue[];
  delegatedValues: SingleFilterValue[];
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
  getEmployees: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.taskV2.list;
  const filterByActiveValues = [
    {
      label: `tasks.activeFilter.${ActiveFilter.ActiveOnly.toString()}`,
      value: ActiveFilter.ActiveOnly,
    },
    {
      label: `tasks.activeFilter.${ActiveFilter.InactiveOnly.toString()}`,
      value: ActiveFilter.InactiveOnly,
    },
  ];
  const delegatedValues = [
    {
      label: `tasks.delegateFilter.${TaskDelegateFilter.Delegated.toString()}`,
      value: TaskDelegateFilter.Delegated,
    },
    {
      label: `tasks.delegateFilter.${TaskDelegateFilter.AssignedToMe.toString()}`,
      value: TaskDelegateFilter.AssignedToMe,
    },
  ];
  const taskStatusValuesOld = [
    {
      label: `tasks.statusFilter.notStarted`,
      value: TaskStatus.NotStarted,
    },
    {
      label: `tasks.statusFilter.completed`,
      value: TaskStatus.Completed,
    },
    // {
    //   label: `tasks.statusFilter.deferred`,
    //   value: TaskStatus.Deferred
    // }
  ];

  return {
    filters,
    employeeValues: employeeValues(state),
    taskStatusValuesOld,
    categoryValues: categoryValues(state),
    filterByActiveValues,
    delegatedValues,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(taskActionsV2.List.setFilters(filters)),
  getEmployees: () => dispatch(EmployeeThunk.getEmployees(true, 100)),
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
