import {
  TemplateDefinitionSnapShot,
  TemplateDefinitionCategorySnapShot,
  MergeField
} from "@haywork/api/kolibri";

export enum ActionType {
  SetTemplates = "EmailV2.Templates.SetTemplates",
  SetTemplateCategories = "EmailV2.Templates.SetTemplateCategories",
  SetTemplateMergeFields = "EmailV2.Templates.SetTemplateMergeFields",
  ResetApp = "RESET_APP"
}

type SetTemplates = {
  type: ActionType.SetTemplates;
  templates: TemplateDefinitionSnapShot[];
};

const setTemplates = (templates: TemplateDefinitionSnapShot[]) => ({
  type: ActionType.SetTemplates,
  templates
});

type SetTemplateCategories = {
  type: ActionType.SetTemplateCategories;
  categories: TemplateDefinitionCategorySnapShot[];
};

const setTemplateCategories = (
  categories: TemplateDefinitionCategorySnapShot[]
) => ({
  type: ActionType.SetTemplateCategories,
  categories
});

type SetTemplateMergeFields = {
  type: ActionType.SetTemplateMergeFields;
  mergeFields: MergeField[];
};

const setTemplateMergeFields = (mergeFields: MergeField[]) => ({
  type: ActionType.SetTemplateMergeFields,
  mergeFields
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | SetTemplates
  | SetTemplateCategories
  | SetTemplateMergeFields
  | ResetApp;
export const actions = {
  setTemplates,
  setTemplateCategories,
  setTemplateMergeFields
};
