import * as React from "react";
import {
  memo,
  CSSProperties,
  useMemo,
  forwardRef,
  Ref,
  useCallback,
} from "react";
import classNames from "classnames";

interface Props {
  name: string;
  color?: string;
  size?: number;
  style?: CSSProperties;
  light?: boolean;
  regular?: boolean;
  solid?: boolean;
  brands?: boolean;
  rotate?: "90" | "180" | "270";
  flip?: "flip-horizontal" | "flip-vertical";
  spin?: boolean;
  fixedWidth?: boolean;
  containIn?: number;
  onClick?: () => void;
}
export const UiIconComponent = memo(
  forwardRef(
    (
      {
        name,
        color,
        size,
        style,
        light,
        regular,
        solid,
        brands,
        rotate,
        flip,
        spin,
        fixedWidth,
        containIn,
        onClick,
      }: Props,
      ref: Ref<HTMLElement>
    ) => {
      const typeStyle = useMemo(() => {
        switch (true) {
          case brands: {
            return "fab";
          }
          case light: {
            return "fal";
          }
          case regular: {
            return "far";
          }
          case solid: {
            return "fas";
          }
          default: {
            return "fa";
          }
        }
      }, [light, regular, solid, brands]);

      const rotationStyle = useMemo(() => {
        switch (rotate) {
          case "90":
            return "fa-rotate-90";
          case "180":
            return "fa-rotate-180";
          case "270":
            return "fa-rotate-270";
          default:
            return "";
        }
      }, [rotate]);

      const flipStyle = useMemo(() => {
        switch (flip) {
          case "flip-horizontal":
            return "fa-flip-horizontal";
          case "flip-vertical":
            return "fa-flip-vertical";
          default:
            return "";
        }
      }, [flip]);

      const spinStyle = useMemo(() => {
        return !!spin ? "fa-spin" : "";
      }, [spin]);

      const elementStyle = useMemo(() => {
        let styleObj: CSSProperties = {
          color: color || "#ffffff",
          fontSize: size || 18,
          width: fixedWidth ? "1.25em" : null,
          textAlign: fixedWidth ? "center" : null,
        };

        if (!!containIn) {
          styleObj = {
            ...styleObj,
            width: containIn,
            height: containIn,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          };
        }

        if (!!style) {
          styleObj = {
            ...styleObj,
            ...style,
          };
        }

        if (!!onClick) {
          styleObj = {
            ...styleObj,
            cursor: "pointer",
          };
        }

        return styleObj;
      }, [color, size, fixedWidth, containIn, style, onClick]);

      const onClickCallback = useCallback(() => {
        if (!!onClick) onClick();
      }, [onClick]);

      return (
        <i
          className={classNames(
            typeStyle,
            `fa-${name}`,
            rotationStyle,
            flipStyle,
            spinStyle
          )}
          style={elementStyle}
          ref={ref}
          onClick={onClickCallback}
        />
      );
    }
  )
);
