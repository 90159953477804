import { MessageEventType } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import { EmailMessage } from "@haywork/stores/email-v2";

const styles = require("./detail.component.scss");

interface Props {
  message: EmailMessage;
}

export const MessageEvents: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })((props) => {
    const intl = useIntl();

    if (!has(props.message._metaData, "messageEvents")) return null;
    let events = get(props.message._metaData, "messageEvents");
    events = sortBy(events, (event) => moment(event.dateTime).unix());
    const event = first(events);

    if (!event) return null;

    let key, values;

    switch (event.type) {
      case MessageEventType.Forwarded:
        key = "emailForwarded";
        values = {
          dateTime: intl.formatDate(event.dateTime, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
          })
        };
        break;
      case MessageEventType.Replied:
        key = "emailReplied";
        values = {
          dateTime: intl.formatDate(event.dateTime, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
          })
        };
        break;
      default:
        break;
    }

    if (!key || !values) return null;

    return (
      <div styleName="events">
        <i className="fa fa-info-circle" />
        <ResourceText resourceKey={key} values={values} />
      </div>
    );
  })
);
