import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, AuthorizationActions } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import { ToggleConsentComponent } from "./toggle-consent.component";

interface StateProps {
  hasConsent: boolean;
  appClientId: string;
  isFirstParty: boolean;
}
interface DispatchProps {
  toggleConsentModal: (visible: boolean) => void;
  toggleConsentRetractConfirm: (visible: boolean) => void;
}

const mapStateToProps: MapStateToProps<StateProps, void, AppState> = (
  state
) => {
  const { client } = state.authorization.singleAppClient;
  const hasConsent = !!get(client, "linkedConsent");
  const appClientId = get(client, "id", "");
  const isFirstParty = get(client, "isFirstParty", false);

  return {
    hasConsent,
    appClientId,
    isFirstParty
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = (
  dispatch
) => ({
  toggleConsentModal: (visible: boolean) =>
    dispatch(AuthorizationActions.SingleAppClient.toggleConsentModal(visible)),
  toggleConsentRetractConfirm: (visible: boolean) =>
    dispatch(
      AuthorizationActions.SingleAppClient.toggleConsentRetractConfirm(visible)
    )
});

export type ToggleConsentContainerProps = StateProps & DispatchProps;
export const ToggleConsentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleConsentComponent);
