import * as PropTypes from "prop-types";
import * as React from "react";
import { v4 as uuid } from "uuid";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";

interface RadioInputComponentProps {
  value: any;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  allowUnCheck?: boolean;
  className?: string;
}
interface RadioInputComponentState {
  checked: boolean;
}

export class RadioComponent extends React.Component<
  RadioInputComponentProps,
  RadioInputComponentState
> {
  public static contextTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.any,
    updateValue: PropTypes.func.isRequired,
  };
  private id: string;

  constructor(props) {
    super(props);

    // Initial state
    this.state = { checked: false };
    this.id = uuid();

    // Event bindings
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    return (
      <div
        className={classNames("radio", this.props.className, {
          disabled: this.props.disabled,
        })}
      >
        <input
          type="radio"
          id={this.id}
          checked={this.state.checked}
          value={this.props.value}
          onChange={this.onChangeHandler}
          onClick={this.onClickHandler}
          onKeyDown={this.onKeyDownHandler}
          disabled={this.props.disabled}
          data-cy={this.props["data-cy"]}
        />
        <label htmlFor={this.id}>
          <ResourceText resourceKey={this.props.label} />
        </label>
      </div>
    );
  }

  public componentDidMount() {
    if (this.props.checked) this.context.updateValue(this.props.value);
    const checked = !!this.context
      ? this.props.value === this.context.value
      : false;
    this.setState({ checked });
  }

  public UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext) this.parseValue(nextContext.value);
  }

  public onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.context.disabled) {
      event.preventDefault();
      return;
    }
    this.context.updateValue(this.props.value);
  }

  private onClickHandler(event: React.MouseEvent<HTMLInputElement>) {
    if (
      this.context.disabled ||
      (!this.props.allowUnCheck && !event.currentTarget.checked)
    ) {
      event.preventDefault();
      return;
    }

    if (this.props.allowUnCheck && event.currentTarget.checked) {
      this.context.updateValue(null);
      return;
    }

    this.context.updateValue(this.props.value);
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private parseValue(value: any) {
    return this.setState({ checked: value === this.props.value });
  }
}
