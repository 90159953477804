import { FolderCategory } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import { EmailDraft, EmailMessage } from "@haywork/stores/email-v2";
import debounce from "lodash-es/debounce";
import * as React from "react";
import { FC, forwardRef, memo, useCallback, useContext, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { v4 as uuid } from "uuid";
import { EmailContext } from "../../email.context";
import Item from "../message";
import { ListContainerProps } from "./list.container";
import { ListContext, ListContextProvider } from "./list.context";

const styles = require("./style.scss");

const innerElementType = forwardRef<HTMLDivElement, any>(
  CSSModules(styles, { allowMultiple: true })(({ children, ...rest }, ref) => {
    const { loading, itemCount } = useContext(ListContext);

    return (
      <div ref={ref} {...rest}>
        {!loading && !itemCount && (
          <div styleName="empty-state">
            <I18n value="email.list.emptyState" />
          </div>
        )}
        {children}
      </div>
    );
  })
);

const outerElementType = forwardRef<HTMLDivElement, any>(
  ({ onScroll, ...rest }, ref) => {
    const { onScrollCallback } = useContext(ListContext);

    const handleOnScroll = useCallback(
      debounce((target: HTMLDivElement) => {
        if (!target || !onScrollCallback) return;
        onScrollCallback(target.scrollTop);
      }, 250),
      [onScrollCallback]
    );

    return (
      <div
        ref={ref}
        onScroll={(e) => {
          handleOnScroll(e.target as HTMLDivElement);
          onScroll(e);
        }}
        {...rest}
      />
    );
  }
);

export type ListComponentProps = {};
type Props = ListComponentProps & ListContainerProps;

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      currentFolder,
      items,
      setFolderPosition,
      onToggleUnRead,
      setCurrentMessage,
      currentMessageId,
      onToggleBookmarked,
      filters,
      canPerformEditActions,
      openDraft,
      moveToFolder,
      hasTrashFolder,
      trashFolder,
      deleteDraft,
    }) => {
      const { loading, getDrafts, getMessages } = useContext(EmailContext);

      const folderId = useMemo(
        () => (!currentFolder ? null : currentFolder.id),
        [currentFolder]
      );

      const folderCategory = useMemo(
        () => (!currentFolder ? null : currentFolder.category),
        [currentFolder]
      );

      const accountId = useMemo(
        () => (!currentFolder ? null : currentFolder.accountId),
        [currentFolder]
      );

      const totalResults = useMemo(
        () => (!currentFolder ? null : currentFolder.totalResults),
        [currentFolder]
      );

      const resultsCount = useMemo(() => items.length, [items]);

      const initialScrollOffset = useMemo(
        () => (!currentFolder ? 0 : currentFolder.position || 0),
        [currentFolder]
      );

      const canLoadMore = useMemo(
        () =>
          [null, undefined].includes(totalResults) ||
          totalResults > resultsCount,
        [totalResults, resultsCount]
      );

      const itemCount = useMemo(
        () => (loading || canLoadMore ? resultsCount + 1 : resultsCount),
        [loading, canLoadMore, resultsCount]
      );

      const isItemLoaded = useCallback(
        (index: number) => !!items[index] || !canLoadMore,
        [items, canLoadMore]
      );

      const fetch = useCallback(async () => {
        if ((loading && !!resultsCount) || !canLoadMore) return;
        if (folderCategory === FolderCategory.Drafts) {
          await getDrafts(resultsCount || 0, accountId, folderId);
        } else {
          await getMessages(resultsCount || 0, accountId, folderId);
        }
      }, [
        folderId,
        accountId,
        getMessages,
        loading,
        resultsCount,
        canLoadMore,
        getDrafts,
        folderCategory,
      ]);

      const onScrollCallback = useCallback(
        (position: number) => {
          if (!!filters.searchQuery) {
            return;
          }

          setFolderPosition(folderId, position);
        },
        [folderId, setFolderPosition, filters.searchQuery]
      );

      const onItemClickCallback = useCallback(
        (item: EmailMessage | EmailDraft, folderCategory: FolderCategory) => {
          if (folderCategory === FolderCategory.Drafts) {
            openDraft(item as EmailDraft);
          } else {
            setCurrentMessage(item as EmailMessage);
          }
        },
        [setCurrentMessage, openDraft]
      );

      const onMessageDeleteCallback = useCallback(
        (message: EmailMessage) => {
          if (!hasTrashFolder) return;
          const { id: messageId } = message;
          const { id: folderId } = trashFolder;
          moveToFolder(messageId, folderId);
        },
        [moveToFolder, hasTrashFolder, trashFolder]
      );

      const infiniteLoaderKey = useMemo(() => {
        return uuid();
      }, [folderId, filters]);

      const currentFolderIsTrash = useMemo(
        () => currentFolder?.id === trashFolder?.id,
        [currentFolder, trashFolder]
      );

      return (
        <div styleName="list">
          <ListContextProvider value={{ onScrollCallback, loading, itemCount }}>
            <AutoSizer>
              {({ width, height }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  loadMoreItems={fetch}
                  itemCount={itemCount}
                  key={infiniteLoaderKey}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      itemSize={88}
                      innerElementType={innerElementType}
                      outerElementType={outerElementType}
                      initialScrollOffset={initialScrollOffset}
                      ref={ref}
                    >
                      {(props) => (
                        <Item
                          {...props}
                          rowData={items[props.index]}
                          onToggleUnRead={onToggleUnRead}
                          onToggleBookmarked={onToggleBookmarked}
                          onClick={onItemClickCallback}
                          currentMessageId={currentMessageId}
                          folderCategory={folderCategory}
                          canPerformEditActions={canPerformEditActions}
                          onDelete={onMessageDeleteCallback}
                          currentFolderIsTrash={currentFolderIsTrash}
                          hasTrashFolder={hasTrashFolder}
                          onDraftDelete={deleteDraft}
                          showFolderName={!!filters.searchQuery}
                        />
                      )}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </ListContextProvider>
        </div>
      );
    }
  )
);
