import {
  eventCenterReducer,
  EventCenterActions,
  EventCenterState
} from "./event-center";
import { combineReducers } from "redux";

export const notificationsReducer = combineReducers({
  eventCenter: eventCenterReducer
});

export type NotificationsState = {
  eventCenter: EventCenterState;
};

export const NotificationsActions = {
  EventCenter: EventCenterActions
};
