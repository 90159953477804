import * as React from "react";
import { memo, useMemo } from "react";
import { FolderTreeFileEntity, FolderTreeFileType } from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "../../../resource-text/resource-text.component";

const styles = require("./style.scss");

interface Props {
  file: FolderTreeFileEntity;
  selectedFilesCount: number;
}
const DossierDragPreviewPreviewComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ file, selectedFilesCount }: Props) => {
      const icon = useMemo(() => {
        if (!!selectedFilesCount) {
          return <i className="far fa-copy" />;
        }

        switch (file.type) {
          case FolderTreeFileType.DocumentSession: {
            return <i className="far fa-file-contract" />;
          }
          case FolderTreeFileType.Invoice: {
            return <i className="far fa-euro-sign" />;
          }
          case FolderTreeFileType.DossierItem: {
            const imagesRegex = /(bmp|jpe?g|gif|png|tiff)/gi;
            const wordRegex = /docx?/gi;
            const excelRegex = /xlsx?/gi;
            const powerpointRegex = /pptx?/gi;
            const pdfRegex = /(pdf|ps)/gi;
            const videoRegex = /(mov|mp4|m4v|3gp|3g2|avi|mpe?g|wmv|asf|ram)/gi;
            const audioRegex = /(mp3|wav|aiff?|mpa|m4a|wma)/gi;

            switch (true) {
              case imagesRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-image" />;
              }
              case wordRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-word" />;
              }
              case excelRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-excel" />;
              }
              case powerpointRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-powerpoint" />;
              }
              case pdfRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-pdf" />;
              }
              case videoRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-video" />;
              }
              case audioRegex.test(file.linkedDossierItem.fileExtension): {
                return <i className="far fa-file-audio" />;
              }
              default: {
                return <i className="far fa-file" />;
              }
            }
          }
          default: {
            return <i className="far fa-file" />;
          }
        }
      }, [file.id]);
      const displayName = useMemo(() => {
        if (!!selectedFilesCount) {
          return (
            <ResourceText
              resourceKey="numberOfFiles"
              values={{ count: selectedFilesCount }}
            />
          );
        }

        switch (file.type) {
          case FolderTreeFileType.DocumentSession: {
            return <span>{file.linkedDocumentSession.name || ""}</span>;
          }
          case FolderTreeFileType.Invoice: {
            return (
              <span>
                {file.linkedInvoice.displayName || (
                  <ResourceText resourceKey="dossier.invoice.conceptInvoice" />
                )}
              </span>
            );
          }
          case FolderTreeFileType.DossierItem: {
            const name = [
              file.linkedDossierItem.fileName,
              file.linkedDossierItem.fileExtension
            ]
              .filter((d) => !!d)
              .join("");
            return <span>{name}</span>;
          }
          default: {
            return null;
          }
        }
      }, [file.id]);

      return (
        <div styleName="preview">
          <div styleName="icon">{icon}</div>
          <div styleName="name">{displayName}</div>
        </div>
      );
    }
  )
);

export default DossierDragPreviewPreviewComponent;
