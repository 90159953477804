import {
  ActiveFilter,
  LinkedRelation,
  RelationSnapShot,
  RelationTermField,
  RelationType,
} from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  LinkedPartners,
  PartnersComponent,
} from "@haywork/modules/form/components/input/query-v3/relation/partners.component";
import { ResourceText } from "@haywork/modules/shared";
import { Relation } from "@haywork/request";
import { RelationUtil, RouteUtil, StringUtil } from "@haywork/util";
import capitalize from "lodash-es/capitalize";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import last from "lodash-es/last";
import * as React from "react";
import { InputComponentProps } from "../../input.component";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import { PartnerPill } from "./partner-pill.component";
import { PartnerComponent } from "./partner.component";
import { SinglePill } from "./single-pill.component";

const route = RouteUtil.mapStaticRouteValues;

interface RelationQueryComponentProps<G> {
  multiple?: boolean;
  placeholder?: string;
  termFields?: RelationTermField[];
  filterByActive?: ActiveFilter;
  filterByRelationTypes?: RelationType[];
  numberOfResults?: number;
  withPartnerControl?: boolean;
  valueAsRelationSnapShot?: boolean;
  onNavigateToRelation?: (path: string) => void;
  onAdd?: (query: string) => void;
}
interface RelationQueryComponentState {
  value: LinkedRelation[];
  placeholder: string;
  partnerMarkedForDelete: LinkedRelation;
}

export class RelationQueryComponent<G> extends React.Component<
  RelationQueryComponentProps<G> & InputComponentProps,
  RelationQueryComponentState
> {
  constructor(props) {
    super(props);
    let value = isArray(this.props.value)
      ? this.props.value
      : [this.props.value];
    value = value.filter((v) => !!v);

    this.state = {
      value,
      placeholder: this.props.placeholder || "searchRelationPlaceholder",
      partnerMarkedForDelete: null,
    };

    this.onRemoveRelationClickHandler =
      this.onRemoveRelationClickHandler.bind(this);
    this.renderPartnerControls = this.renderPartnerControls.bind(this);
    this.renderSelectedValuePill = this.renderSelectedValuePill.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedValue = this.renderSelectedValue.bind(this);
    this.onBackspaceDeleteHandler = this.onBackspaceDeleteHandler.bind(this);
    this.onRemoveMarkedForDeleteHandler =
      this.onRemoveMarkedForDeleteHandler.bind(this);
    this.mapDisableOption = this.mapDisableOption.bind(this);
    this.onPartnerClickHandler = this.onPartnerClickHandler.bind(this);
    this.onPartnersClickHandler = this.onPartnersClickHandler.bind(this);
    this.onRelationClickHandler = this.onRelationClickHandler.bind(this);
    this.onRemoveRelationClickHandler =
      this.onRemoveRelationClickHandler.bind(this);
    this.onRemovePartnerClickHandler =
      this.onRemovePartnerClickHandler.bind(this);
  }

  public render() {
    const {
      termFields,
      filterByActive,
      filterByRelationTypes,
      numberOfResults,
      withPartnerControl,
    } = this.props;
    const take = numberOfResults || 25;

    return (
      <div className="relation__query">
        <BaseQueryComponent
          {...this.props}
          {...this.state}
          hasCustomPill
          forceAdd={!!this.props.onAdd}
          onAddResource="addAsNewRelation"
          onAdd={this.props.onAdd}
          onChange={this.onChangeHandler}
          asyncValues={(value) =>
            Relation.searchV3(
              value,
              take,
              termFields,
              filterByActive,
              filterByRelationTypes
            )
          }
          optionValue={this.renderOptionValue}
          selectedValue={this.renderSelectedValue}
          onBackspaceDelete={this.onBackspaceDeleteHandler}
          onRemoveMarkedForDelete={this.onRemoveMarkedForDeleteHandler}
          disableOption={this.mapDisableOption}
          data-cy={this.props["data-cy"]}
        />
        {!!withPartnerControl && this.renderPartnerControls()}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RelationQueryComponentProps<G> & InputComponentProps
  ) {
    if (!nextProps) return;
    let value = isArray(nextProps.value) ? nextProps.value : [nextProps.value];
    value = value.filter((v) => !!v);

    this.setState({ value });
  }

  private onChangeHandler(value: LinkedRelation[]) {
    this.fireOnChange(value);
  }

  private fireOnChange(value: any[]) {
    const mappedValues = !!this.props.valueAsRelationSnapShot
      ? value
      : this.prepareValues(value);
    const mappedValue = !!this.props.multiple
      ? mappedValues
      : first(mappedValues);

    this.props.onChange(mappedValue);
  }

  private onBackspaceDeleteHandler(): boolean {
    const lastValue = last(this.state.value);
    if (
      !this.props.withPartnerControl ||
      !lastValue ||
      !lastValue.togetherWithPartner
    )
      return true;

    if (!this.state.partnerMarkedForDelete) {
      this.setState({ partnerMarkedForDelete: lastValue });
      return false;
    }

    const value = this.state.value.map((v) => {
      return lastValue.id === v.id ? { ...v, togetherWithPartner: false } : v;
    });

    this.setState({ value, partnerMarkedForDelete: null });
    this.fireOnChange(value);

    return false;
  }

  private onRemoveMarkedForDeleteHandler() {
    this.setState({ partnerMarkedForDelete: null });
  }

  private onPartnerClickHandler(relation: LinkedRelation) {
    const value = this.state.value.map((v) => {
      return v.id === relation.id ? relation : v;
    });

    this.setState({ value });
    this.fireOnChange(value);
  }

  private onPartnersClickHandler(partners: LinkedPartners) {
    const filtered = this.state.value.filter(
      (v) => v.id !== partners.partner2.id
    );
    const value = filtered.map((v) => {
      return v.id === partners.partner1.id ? partners.partner1 : v;
    });

    this.setState({ value });
    this.fireOnChange(value);
  }

  private prepareValues(values: any[]): LinkedRelation[] {
    const linkedRelations: LinkedRelation[] = [];

    values.map((relation: LinkedRelation | RelationSnapShot) => {
      if (RelationUtil.isLinkedRelation(relation)) {
        linkedRelations.push(relation);
      } else {
        const {
          id,
          displayName,
          email,
          linkedPartner,
          locality,
          phoneNumber: phone,
          mobileNumber: phoneMobile,
          streetNameAndNumber,
          typeOfRelation,
          avatarUrl,
          letterAvatar,
        } = relation;

        linkedRelations.push({
          id,
          displayName,
          phone,
          phoneMobile,
          locality,
          streetNameAndNumber,
          linkedPartner,
          togetherWithPartner: false,
          typeOfRelation,
          email,
          avatarUrl,
          letterAvatar,
        });
      }
    });

    return linkedRelations;
  }

  private renderPartnerControls(): React.ReactElement<HTMLDivElement> {
    let linkedRelations = this.state.value.filter(
      (v) => !!v.linkedPartner && !v.togetherWithPartner
    );
    const partnerIds = linkedRelations.map((r) => r.linkedPartner.id);
    const matchingPartners = linkedRelations.filter(
      (r) => partnerIds.indexOf(r.id) !== -1
    );
    const linkedPartners: LinkedPartners[] = matchingPartners.reduce(
      (state, partner) => {
        const match = state.find(
          (s) => s.partner1.id === partner.linkedPartner.id
        );
        if (match) {
          match.partner2 = partner;
        } else {
          state.push({
            partner1: partner,
            partner2: null,
          });
        }
        return state;
      },
      []
    );

    linkedRelations = linkedRelations.filter(
      (r) => partnerIds.indexOf(r.id) === -1
    );

    return (
      <div className="partners">
        {linkedRelations.map((linkedRelation, idx) => (
          <ErrorBoundary key={idx}>
            <PartnerComponent
              relation={linkedRelation}
              onClick={this.onPartnerClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.Partner`
              }
            />
          </ErrorBoundary>
        ))}
        {linkedPartners.map((partners, idx) => (
          <ErrorBoundary key={idx}>
            <PartnersComponent
              partners={partners}
              onClick={this.onPartnersClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.Partner`
              }
            />
          </ErrorBoundary>
        ))}
      </div>
    );
  }

  private renderOptionValue(
    relation: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const { displayName } = relation;

    return (
      <div className="relation__name">
        <div
          className="section"
          dangerouslySetInnerHTML={StringUtil.highlight(
            displayName || "",
            query
          )}
        />
        {this.renderAddress(relation, query)}
        {this.renderTelephone(relation, query)}
        {this.renderEmailAddress(relation, query)}
        {!relation.isActive && (
          <div className="query-component__archived-label">
            <ResourceText resourceKey="archived" />
          </div>
        )}
      </div>
    );
  }

  private renderSelectedValue(
    value: RelationSnapShot
  ): QueryResultReturnValue<LinkedRelation> {
    const template = this.renderSelectedValuePill(value);

    return {
      value,
      template,
    };
  }

  private mapDisableOption(
    value: RelationSnapShot,
    values: LinkedRelation[]
  ): boolean {
    let ids = values.map((v) => v.id);
    if (this.props.withPartnerControl) {
      const partners = values.filter(
        (v) =>
          !!v.togetherWithPartner && !!v.linkedPartner && !!v.linkedPartner.id
      );
      const partnerIds = partners.map((v) => v.linkedPartner.id);
      ids = [...ids, ...partnerIds];
    }
    return ids.indexOf(value.id) !== -1;
  }

  private renderSelectedValuePill(
    relation: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    const { togetherWithPartner } = relation;

    if (!!this.props.withPartnerControl && !!togetherWithPartner) {
      return (
        <PartnerPill
          relation={relation}
          partnerMarkedForDelete={this.state.partnerMarkedForDelete}
          onRelationClick={this.onRelationClickHandler}
          onRemoveRelationClick={this.onRemoveRelationClickHandler}
          onRemovePartnerClick={this.onRemovePartnerClickHandler}
          data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Pill`}
        />
      );
    } else {
      return (
        <SinglePill
          relation={relation}
          onRelationClick={this.onRelationClickHandler}
          onRemoveRelationClick={this.onRemoveRelationClickHandler}
          data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Pill`}
        />
      );
    }
  }

  private onRelationClickHandler(
    relation: LinkedRelation,
    isPartner: boolean = false
  ) {
    if (!this.props.onNavigateToRelation) return;

    const { id, typeOfRelation } = relation;
    let path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });

    if (!isPartner) {
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
          break;
        }
        case RelationType.Employee: {
          path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
          break;
        }
        case RelationType.Office: {
          path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
          break;
        }
        default: {
          break;
        }
      }
    }

    this.props.onNavigateToRelation(path);
  }

  private onRemoveRelationClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    relation: LinkedRelation
  ) {
    event.stopPropagation();

    const value = this.state.value.filter((v) => v.id !== relation.id);

    this.setState({ value });
    this.fireOnChange(value);
  }

  private onRemovePartnerClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    relation: LinkedRelation
  ) {
    event.stopPropagation();

    const value = this.state.value.map((v) => {
      return v.id === relation.id
        ? { ...relation, togetherWithPartner: false }
        : v;
    });

    this.setState({ value });
    this.fireOnChange(value);
  }

  private renderAddress(
    relation: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const { locality, streetNameAndNumber } = relation;
    if (!locality && !streetNameAndNumber) return null;

    const values = [streetNameAndNumber, locality];
    const value = values
      .reduce((state, value) => {
        if (!!value) {
          state.push(capitalize(value));
        }
        return state;
      }, [])
      .join(", ");

    return (
      <div className="section extra">
        <span className="fal fa-folder-open" />
        <span dangerouslySetInnerHTML={StringUtil.highlight(value, query)} />
      </div>
    );
  }

  private renderTelephone(
    relation: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const { mobileNumber, phoneNumber } = relation;
    if (!mobileNumber && !phoneNumber) return null;

    const matchPhone = StringUtil.matches(phoneNumber, query);
    const matchMobile = StringUtil.matches(mobileNumber, query);

    if (matchPhone === -1 && matchMobile === -1) return null;

    return (
      <React.Fragment>
        {matchPhone >= 0 && (
          <div className="section extra">
            <span className="fal fa-phone" />
            <span
              dangerouslySetInnerHTML={StringUtil.highlight(phoneNumber, query)}
            />
          </div>
        )}
        {matchMobile >= 0 && (
          <div className="section extra">
            <span className="fal fa-mobile" />
            <span
              dangerouslySetInnerHTML={StringUtil.highlight(
                mobileNumber,
                query
              )}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  private renderEmailAddress(
    relation: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const { email, secondEmailAddress, thirdEmailAddress } = relation;
    if (!email) return null;

    const matchEmail = StringUtil.matches(email, query);
    const matchSecondEmailAddress = StringUtil.matches(
      secondEmailAddress || "",
      query
    );
    const matchThirdEmailAddress = StringUtil.matches(
      thirdEmailAddress || "",
      query
    );

    if (
      matchEmail === -1 &&
      matchSecondEmailAddress === -1 &&
      matchThirdEmailAddress === -1
    )
      return null;

    return (
      <React.Fragment>
        {matchEmail >= 0 && (
          <div className="section extra">
            <span className="fal fa-envelope" />
            <span
              dangerouslySetInnerHTML={StringUtil.highlight(email, query)}
            />
          </div>
        )}
        {matchSecondEmailAddress >= 0 && (
          <div className="section extra">
            <span className="fal fa-envelope" />
            <span
              dangerouslySetInnerHTML={StringUtil.highlight(
                secondEmailAddress,
                query
              )}
            />
          </div>
        )}
        {matchThirdEmailAddress >= 0 && (
          <div className="section extra">
            <span className="fal fa-envelope" />
            <span
              dangerouslySetInnerHTML={StringUtil.highlight(
                thirdEmailAddress,
                query
              )}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
