export const SCHEDULER = {
  SET_AGENDA_ITEMS: "SET_AGENDA_ITEMS",
  APPEND_AGENDA_ITEMS: "APPEND_AGENDA_ITEMS",
  SET_AGENDA_ITEMS_FOR_TODAY: "SET_AGENDA_ITEMS_FOR_TODAY",
  SET_AGENDA_ITEM: "SET_AGENDA_ITEM",
  SET_AGENDA_ITEM_DIRECTLY: "SET_AGENDA_ITEM_DIRECTLY",
  SET_AGENDA_ITEM_CATEGORIES: "SET_AGENDA_ITEM_CATEGORIES",
  SET_RECURRENCY_EXCEPTION: "SET_RECURRENCY_EXCEPTION",
  SET_SCHEDULER_STATE: "SET_SCHEDULER_STATE",
  SET_SCHEDULER_WIDGET_STATE: "SET_SCHEDULER_WIDGET_STATE",
  SET_SCHEDULER_DETAIL_STATE: "SET_SCHEDULER_DETAIL_STATE",
  SET_SCHEDULER_CONFIRMATION_STATE: "SET_SCHEDULER_CONFIRMATION_STATE",
  SET_SCHEDULER_REMOVE_STATE: "SET_SCHEDULER_REMOVE_STATE",
  SET_SCHEDULER_FILTERS: "SET_SCHEDULER_FILTERS",
  SET_SCHEDULER_RECURRENCY: "SET_SCHEDULER_RECURRENCY",
  UPDATE_OVERVIEW_ITEM: "SCHEDULER_UPDATE_OVERVIEW_ITEM",
  DELETE_LIST_ITEM: "SCHEDULER_DELETE_LIST_ITEM"
};
