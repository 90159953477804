import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import noop from "lodash-es/noop";
import isString from "lodash-es/isString";

import { UiTableColumnConfig } from "./table.component";
import { SortOrder } from "@haywork/api/kolibri";
import { ResourceText, FontStyle } from "@haywork/modules/shared";

const styles = require("./table.component.scss");

interface HeaderColumnProps {
  column: UiTableColumnConfig;
  orderBy: any;
  order: SortOrder;
  isIE11: boolean;
  idx: number;
  onOrder: (sortOrder: any) => void;
}

export const HeaderColumn = CSSModules(styles, { allowMultiple: true })(
  ({ column, orderBy, order, onOrder, idx, isIE11 }: HeaderColumnProps) => {
    const canOrder = !!column.orderBy;
    const hasOrder = !!canOrder && column.orderBy === orderBy;
    const headerColumnStyle = classNames("header-column", {
      "can-order": canOrder,
    });
    const headerColumnStyles = isIE11 ? { msGridColumns: idx + 1 } : null;
    const orderStyle = classNames("order", {
      asc: hasOrder && order === SortOrder.Ascending,
      desc: hasOrder && order === SortOrder.Descending,
    });
    const { key, values } = isString(column.title)
      ? { key: column.title, values: {} }
      : column.title;

    return (
      <div
        styleName={headerColumnStyle}
        style={headerColumnStyles}
        onClick={() => (!!canOrder ? onOrder(column.orderBy) : noop())}
      >
        <div styleName="title">
          <ResourceText
            resourceKey={key}
            values={values}
            fontStyle={FontStyle.UcFirst}
          />
        </div>
        {!!canOrder && (
          <div styleName={orderStyle}>
            <i className="fa fa-caret-up" />
            <i className="fa fa-caret-down" />
          </div>
        )}
      </div>
    );
  }
);
