import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { VoipConversationStatus } from "@haywork/api/voip";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  phoneNumber: string | null;
  linkedRelations: RelationSnapShot[];
  conversationStatus: VoipConversationStatus;
  onSelectRelation: (relation: RelationSnapShot) => void;
}

export const VoipWidgetBodyRelationsComponent = CSSModules(styles, {
  allowMultiple: true,
})(
  ({
    phoneNumber,
    linkedRelations,
    onSelectRelation,
    conversationStatus,
  }: Props) => {
    const blocked = [
      VoipConversationStatus.Ringing,
      VoipConversationStatus.Unknown,
    ].includes(conversationStatus);

    const selectRelation = (relation: RelationSnapShot) => {
      if (blocked) return;
      onSelectRelation(relation);
    };

    return (
      <div>
        {linkedRelations.map((relation) => (
          <div
            styleName={classNames("body", { blocked })}
            key={relation.id}
            onClick={() => selectRelation(relation)}
          >
            <div
              styleName="body__avatar"
              style={{
                backgroundImage: !!relation.avatarUrl
                  ? `url(${JSON.stringify(relation.avatarUrl)})`
                  : null,
              }}
            >
              {!relation.avatarUrl && <i className="fal fa-user" />}
            </div>
            <div styleName="body__meta">
              <div styleName="display-name">{relation.displayName}</div>
              <div styleName="phone-number">{phoneNumber}</div>
            </div>
            {!blocked && (
              <div styleName="body__pickup">
                <i className="fal fa-phone-plus" />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
);
