import {
  Employee,
  RelationSnapShot,
  TemplateDefinitionSnapShot,
} from "@haywork/api/kolibri";
import {
  Account,
  AccountShare,
  CreateAccountRequest,
  Folder,
  PersonSettings,
  Provider,
  ReauthenticateAccountRequest,
} from "@haywork/api/mail";
import {
  AccountShareReference,
  Dispatch,
  EmailAccountThunks,
  EmailFolderThunks,
} from "@haywork/middleware";
import {
  SettingsEmailOverviewComponent,
  SettingsEmailOverviewComponentProps,
} from "@haywork/modules/settings/modules/email";
import { AppState, EmailActions } from "@haywork/stores";
import { connect } from "react-redux";
import { signatures, defaultAccount } from "./selectors";

interface StateProps {
  employee: Employee;
  employees: RelationSnapShot[];
  accounts: Account[];
  providers: Provider[];
  shares: AccountShare[];
  redirectUri: string | null;
  createStatus: string;
  personSettings: PersonSettings;
  defaultAccountId: string;
  signatures: TemplateDefinitionSnapShot[];
}
interface DispatchProps {
  createAccount: (request: CreateAccountRequest) => Promise<string | Account>;
  getAccounts: () => void;
  updateAccountAndShares: (
    account: Account,
    shares: AccountShareReference[],
    defaultAccountId: string | null
  ) => Promise<void>;
  deleteAccount: (accountId: string) => void;
  getAccountShares: (accountId: string) => void;
  clearAccountShares: () => void;
  defineNewShare: (accountId: string) => void;
  deleteShare: (share: AccountShare) => void;
  toggleShareVisibility: (share: AccountShare) => void;
  reAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
  addInitialShares: (
    account: Account,
    shares: AccountShareReference[]
  ) => Promise<void>;
  getFolder: (accountId: string, folderId: string) => Promise<Folder>;
}

const mapStateToProps = <StateProps, SettingsEmailOverviewComponentProps>(
  state: AppState
) => {
  const { employees, employee } = state.account;
  const { redirectUri, createStatus } = state.email.settings;
  const { accounts } = state.emailV2.accounts;
  const { shares } = state.emailV2.shares;
  const { providers } = state.emailV2.providers;
  const { personSettings } = state.emailV2.main;
  const filteredEmployees = employees.filter((e) => e.id !== employee.id);

  return {
    employee,
    employees: filteredEmployees,
    accounts,
    providers,
    redirectUri,
    createStatus,
    shares,
    personSettings,
    defaultAccountId: defaultAccount(state)?.id,
    signatures: signatures(state),
  };
};

const mapDispatchToProps = <DispatchProps, SettingsEmailOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  createAccount: (request: CreateAccountRequest) =>
    dispatch(EmailAccountThunks.createAccount(request)),
  getAccounts: () => dispatch(EmailAccountThunks.getAccounts()),
  updateAccountAndShares: (
    account: Account,
    shares: AccountShareReference[],
    defaultAccountId: string | null
  ) =>
    dispatch(
      EmailAccountThunks.updateAccountAndShares(
        account,
        shares,
        defaultAccountId
      )
    ),
  deleteAccount: (accountId: string) =>
    dispatch(EmailAccountThunks.deleteAccount(accountId)),
  getAccountShares: (accountId: string) =>
    dispatch(EmailAccountThunks.getAccountShares(accountId)),
  clearAccountShares: () => dispatch(EmailActions.Shares.clearShares()),
  defineNewShare: (accountId: string) =>
    dispatch(EmailAccountThunks.defineNewAccountShare(accountId)),
  deleteShare: (share: AccountShare) =>
    dispatch(EmailAccountThunks.deleteShare(share)),
  toggleShareVisibility: (share: AccountShare) =>
    dispatch(EmailAccountThunks.toggleShareVisibility(share)),
  reAuthenticate: (request: ReauthenticateAccountRequest) =>
    dispatch(EmailAccountThunks.reAuthenticate(request)),
  addInitialShares: (account: Account, shares: AccountShareReference[]) =>
    dispatch(EmailAccountThunks.addInitialShares(account, shares)),
  getFolder: (accountId: string, folderId: string) =>
    dispatch(EmailFolderThunks.getFolder(accountId, folderId)),
});

export type SettingsEmailOverviewContainerProps = StateProps & DispatchProps;
export const SettingsEmailOverviewContainer = connect<
  StateProps,
  DispatchProps,
  SettingsEmailOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEmailOverviewComponent);
