import { CompanySettings, WebsiteProviderOption } from "@haywork/api/kolibri";
import { CompanySettingsThunks, Dispatch } from "@haywork/middleware";
import {
  SettingsMatchmailComponent,
  SettingsMatchmailComponentProps,
} from "@haywork/modules/settings/modules/matchmail";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  settings: CompanySettings;
  settingsStatus: string;
  websiteProviderOptions: WebsiteProviderOption[];
  collegialListingsStatus: string;
}
interface DispatchProps {
  saveCompanySettings: (companyListing: CompanySettings) => void;
  requestCollegialListingAuthorization: () => void;
  deActivateCollegialListings: () => void;
}

const mapStateToProps = <StateProps, SettingsMatchmailComponentProps>(
  state: AppState
) => {
  const { settings, settingsStatus, collegialListingsStatus } = state.company;
  const { websiteProviderOptions } = state.main.mastertable.kolibri;

  return {
    settings,
    settingsStatus,
    websiteProviderOptions,
    collegialListingsStatus,
  };
};

const mapDispatchToProps = <DispatchProps, SettingsMatchmailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveCompanySettings: (companyListing: CompanySettings) =>
    dispatch(CompanySettingsThunks.saveCompanySettings(companyListing)),
  requestCollegialListingAuthorization: () =>
    dispatch(CompanySettingsThunks.requestCollegialListingAuthorization()),
  deActivateCollegialListings: () =>
    dispatch(CompanySettingsThunks.deActivateCollegialListings()),
});

export type SettingsMatchmailContainerProps = StateProps & DispatchProps;
export const SettingsMatchmailContainer = connect<
  StateProps,
  DispatchProps,
  SettingsMatchmailComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsMatchmailComponent);
