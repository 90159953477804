import {
  Form,
  FormControls,
  FormReference,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import noop from "lodash-es/noop";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./add-financial-product.component.scss");

interface ProductGroupFormComponentProps {
  productGroupName: string;
  financialAdministrationState: string;
  onClose: () => void;
  onFormRef: (form: FormReference) => void;
}
interface ProductGroupFormComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ProductGroupFormComponent extends React.Component<
  ProductGroupFormComponentProps,
  ProductGroupFormComponentState
> {
  private ref: FormReference;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.bindFormRef = this.bindFormRef.bind(this);

    this.formControls = {
      name: {
        value: this.props.productGroupName,
        validators: [Validators.required()],
      },
      budgetedAmount: {
        value: "",
        validators: [Validators.required()],
      },
      budgetedNumberOfProducts: {
        value: "",
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    return (
      <div styleName="add-product-group">
        <div
          styleName="close"
          onClick={this.props.onClose}
          data-cy={this.props["data-cy"]}
        >
          <i className="fal fa-times" />
        </div>
        <Form
          name="add-product-group"
          formControls={this.formControls}
          form={this.bindFormRef}
          onSubmit={noop}
        >
          <div className="form__row" styleName="name__spacing">
            <label htmlFor="name">
              <ResourceText resourceKey="name" />
            </label>
            <Input.Text
              name="name"
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.NameInput`
              }
            />
          </div>

          <div className="form__row">
            <label htmlFor="budgetedNumberOfProducts">
              <ResourceText resourceKey="productExpectedRevenue" />
            </label>
            <div className="form__group">
              <div className="column">
                <Input.Number
                  name="budgetedNumberOfProducts"
                  max={999999}
                  round
                  data-cy={
                    this.props["data-cy"] &&
                    `${this.props["data-cy"]}.BudgetNumberOfProductsInput`
                  }
                />
              </div>
              <div className="column__textspacer">
                <ResourceText resourceKey="timesFor" />
              </div>
              <div className="column">
                <div className="input__helper">
                  <div className="pre">&euro;</div>
                  <Input.Number
                    name="budgetedAmount"
                    max={999999}
                    pretty
                    data-cy={
                      this.props["data-cy"] &&
                      `${this.props["data-cy"]}.BudgetedAmoutInput`
                    }
                  />
                </div>
              </div>
              <div className="column__textspacer">
                <ResourceText resourceKey="perTime" />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!this.ref && !!ref) {
      this.ref = ref;
      this.props.onFormRef(ref);
    }
  }
}
