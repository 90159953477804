import { MasterTableResponse as TableResponseAuthorization } from "@haywork/api/authorization";
import { TableResponse as TableResponseEventCenter } from "@haywork/api/event-center";
import {
  AgendaStandardDurationOption,
  TableResponse,
} from "@haywork/api/kolibri";
import { ReadMasterTableResponse as TableResponseMls } from "@haywork/api/mls";
import sortBy from "lodash-es/sortBy";

export interface SimpleLabelValue {
  label: string;
  value: any;
}

export interface SimpleLabelOriginal {
  label: string;
  value: any;
  filter: string;
}

export class Mastertable {
  public static mapValuesToTranslatables(
    table:
      | TableResponse
      | TableResponseEventCenter
      | TableResponseAuthorization
      | TableResponseMls
  ): Record<string, string> {
    const translationTable = {};

    for (const key in table) {
      if (table.hasOwnProperty(key)) {
        const reference = table[key];
        for (const ref in reference) {
          if (reference.hasOwnProperty(ref)) {
            const translatable = reference[ref];
            let translateKey = null;
            if (key === "countries") {
              translateKey = `countryCode${translatable.iso2CodeValue}`;
            }
            if (
              !translateKey &&
              !!translatable.value &&
              !!translatable.displayName
            ) {
              translateKey = translatable.value;
            }
            if (!!translateKey) {
              translationTable[`${key}.${translateKey}`] =
                translatable.displayName;
            }
          }
        }
      }
    }

    return translationTable;
  }

  public static sortDurationInMinutes(
    options: AgendaStandardDurationOption[]
  ): AgendaStandardDurationOption[] {
    return sortBy(options, "durationInMinutes");
  }

  public static sortEnumList<G>(list: G[], sort: any[]): G[] {
    return list.sort((left, right) => {
      const leftIndex = sort.indexOf(left["value"]);
      const rightindex = sort.indexOf(right["value"]);
      if (leftIndex > rightindex) {
        return 1;
      } else if (leftIndex < rightindex) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
