import {
  ActiveFilter,
  InvoiceOrderByField,
  InvoiceSnapShot,
  InvoiceStatus,
  SortOrder
} from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import { PagedResults, Status, StoreHelper } from "@haywork/stores";
import { Action } from "redux";
import * as ActionType from "./overview.types";

export interface InvoiceFiltering {
  filterByActive: ActiveFilter[];
  statusFilter: InvoiceStatus[];
  overdueFrom: Date;
  overdueUntil: Date;
  invoiceDateFrom: Date;
  invoiceDateUntil: Date;
}

export interface InvoiceOverviewState {
  invoices: PagedResults<InvoiceSnapShot>;
  order: SortOrder;
  orderBy: InvoiceOrderByField;
  filters: InvoiceFiltering;
}

const INITIAL_STATE: InvoiceOverviewState = {
  invoices: StoreHelper.createInitialPagedResult(),
  order: SortOrder.Ascending,
  orderBy: InvoiceOrderByField.InvoiceOverdue,
  filters: {
    filterByActive: [ActiveFilter.ActiveOnly],
    statusFilter: [],
    overdueFrom: null,
    overdueUntil: null,
    invoiceDateFrom: null,
    invoiceDateUntil: null
  }
};

export const invoiceOverviewReducer = (
  state: InvoiceOverviewState = INITIAL_STATE,
  action: Action
): InvoiceOverviewState => {
  switch (action.type) {
    case REDUX.INVOICES.SET_INVOICES_ORDERING: {
      const { order, orderBy } = <ActionType.Ordering>action;

      return {
        ...state,
        order,
        orderBy
      };
    }
    case REDUX.INVOICES.SET_INVOICES_STATUS: {
      const invoices = {
        ...state.invoices,
        ...StoreHelper.setObjectStatus(<Status>action)
      };

      return {
        ...state,
        invoices
      };
    }
    case REDUX.INVOICES.SET_INVOICES: {
      const invoices = StoreHelper.setPagedResults(<
        ActionType.InvoicesResponse
      >action);

      return {
        ...state,
        invoices
      };
    }
    case REDUX.INVOICES.APPEND_INVOICES: {
      const invoices = StoreHelper.updatePagedResults(
        <ActionType.InvoicesResponse>action,
        state.invoices
      );

      return {
        ...state,
        invoices
      };
    }
    case REDUX.INVOICES.DELETE_INVOICE_FROM_LIST: {
      const { id } = <ActionType.Identifier>action;
      const results = state.invoices.results.filter(
        (invoice) => invoice.id !== id
      );

      return {
        ...state,
        invoices: {
          ...state.invoices,
          results
        }
      };
    }
    case REDUX.INVOICES.ARCHIVE_INVOICE_FROM_LIST: {
      const { id } = <ActionType.Identifier>action;
      const results = state.invoices.results.reduce((s, invoice) => {
        if (invoice.id === id) {
          if (
            state.filters.filterByActive.indexOf(ActiveFilter.ActiveOnly) === -1
          ) {
            const updatedInvoice: InvoiceSnapShot = {
              ...invoice,
              isActive: false
            };
            s.push(updatedInvoice);
          }
        } else {
          s.push(invoice);
        }
        return s;
      }, []);

      return {
        ...state,
        invoices: {
          ...state.invoices,
          results
        }
      };
    }
    case REDUX.INVOICES.UNARCHIVE_INVOICE_FROM_LIST: {
      const { id } = <ActionType.Identifier>action;
      const results = state.invoices.results.reduce((s, invoice) => {
        if (invoice.id === id) {
          if (
            state.filters.filterByActive.indexOf(ActiveFilter.InactiveOnly) !==
            -1
          ) {
            const updatedInvoice: InvoiceSnapShot = {
              ...invoice,
              isActive: true
            };
            s.push(updatedInvoice);
          }
        } else {
          s.push(invoice);
        }
        return s;
      }, []);

      return {
        ...state,
        invoices: {
          ...state.invoices,
          results
        }
      };
    }
    case REDUX.INVOICES.SET_INVOICE_FILTERING: {
      const { filters } = <ActionType.InvoiceFilteringResponse>action;

      return {
        ...state,
        filters
      };
    }
    case REDUX.INVOICES.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        filters: {
          filterByActive: [],
          statusFilter: [],
          overdueFrom: null,
          overdueUntil: null,
          invoiceDateFrom: null,
          invoiceDateUntil: null
        }
      };
    }
    case REDUX.INVOICES.UPDATE_LIST_ITEM: {
      const { snapshot } = <ActionType.SnapShot>action;
      const invoices = StoreHelper.updateSingleResultInResults(
        snapshot,
        "id",
        state.invoices
      );

      return {
        ...state,
        invoices
      };
    }
    case REDUX.INVOICES.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const invoices = StoreHelper.deleteSingleResultInResults(
        id,
        "id",
        state.invoices
      );

      return {
        ...state,
        invoices
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
