import { Dispatch, MainThunks } from "@haywork/middleware";
import {
  SettingsLanguageComponent,
  SettingsLanguageComponentProps
} from "@haywork/modules/settings/modules/language";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  culture: string;
  languageStatus: string;
}
interface DispatchProps {
  switchLanguage: (culture: string) => void;
}

const mapStateToProps = <StateProps, SettingsLanguageComponentProps>(
  state: AppState
) => ({
  culture: state.main.culture,
  languageStatus: state.main.languageStatus
});

const mapDispatchToProps = <DispatchProps, SettingsLanguageComponentProps>(
  dispatch: Dispatch<any>
) => ({
  switchLanguage: (culture: string) =>
    dispatch(MainThunks.switchLanguage(culture))
});

export type SettingsLanguageContainerProps = StateProps & DispatchProps;
export const SettingsLanguageContainer = connect<
  StateProps,
  DispatchProps,
  SettingsLanguageComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsLanguageComponent);
