import { FinancialAdministration, ReportTemplate } from "@haywork/api/kolibri";
import { Dispatch, FinancialThunks, InvoiceThunk } from "@haywork/middleware";
import { SettingsFinancialAdministrationsGeneralComponent } from "@haywork/modules/settings/modules";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  financialAdministrations: FinancialAdministration[];
  financialAdministrationState: string;
}
interface DispatchProps {
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) => void;
  getPrintTemplates: (b2b: boolean) => Promise<ReportTemplate[]>;
}

const mapStateToProps = <
  StateProps,
  SettingsFinancialAdministrationsGeneralComponentProps
>(
  state: AppState
) => {
  const { financialAdministrations, financialAdministrationState } =
    state.account;

  return {
    financialAdministrations,
    financialAdministrationState,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SettingsFinancialAdministrationsGeneralComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) =>
    dispatch(
      FinancialThunks.updateFinancialAdministration(financialAdministration)
    ),
  getPrintTemplates: (b2b: boolean) =>
    dispatch(InvoiceThunk.getGlobalPrintTemplates(b2b)),
});

export type SettingsFinancialAdministrationsGeneralContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const SettingsFinancialAdministrationsGeneralContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsFinancialAdministrationsGeneralComponent)
);
