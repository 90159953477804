import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

const styles = require("./numbers-form.component.scss");

interface Props {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
}

@CSSModules(styles, { allowMultiple: true })
export class NumbersFormComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { projectAssignment } = this.props;

    this.formControls = {
      usageAreaMin: { value: value(projectAssignment, "usageArea.minArea") },
      usageAreaMax: { value: value(projectAssignment, "usageArea.maxArea") },
      parcelSurfaceMin: {
        value: value(projectAssignment, "parcelSurface.minArea")
      },
      parcelSurfaceMax: {
        value: value(projectAssignment, "parcelSurface.maxArea")
      },
      contentMin: { value: value(projectAssignment, "content.minVolume") },
      contentMax: { value: value(projectAssignment, "content.maxVolume") },
      amountOfUnits: { value: value(projectAssignment, "amountOfUnits") },
      amountOfFreeUnits: {
        value: value(projectAssignment, "amountOfFreeUnits")
      }
    };

    this.onNumbersChangeHandler = this.onNumbersChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;

      this.formRef.update({
        usageAreaMin: value(projectAssignment, "usageArea.minArea"),
        usageAreaMax: value(projectAssignment, "usageArea.maxArea"),
        parcelSurfaceMin: value(projectAssignment, "parcelSurface.minArea"),
        parcelSurfaceMax: value(projectAssignment, "parcelSurface.maxArea"),
        contentMin: value(projectAssignment, "content.minVolume"),
        contentMax: value(projectAssignment, "content.maxVolume"),
        amountOfUnits: value(projectAssignment, "amountOfUnits"),
        amountOfFreeUnits: value(projectAssignment, "amountOfFreeUnits")
      }, true);
    }
  }

  public render() {
    return (
      <div styleName="numbers-form">
        <Form
          name="numbers-form"
          onChange={this.onNumbersChangeHandler}
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
        >
          {this.renderNumberRangeFormRow("usageArea", "squareMeters")}
          {this.renderNumberRangeFormRow("parcelSurface", "squareMeters")}
          {this.renderNumberRangeFormRow("content", "cubicMeters")}

          <div className="form__row">
            <div className="form__group">
              <div className="column" styleName="range-label">
                <label htmlFor="amountOfUnits" className="pre">
                  <ResourceText resourceKey="amountOfUnitsInProject" />
                </label>
              </div>
              <div className="column" styleName="range-input">
                <Input.Number
                  name={"amountOfUnits"}
                  min={0}
                  max={999}
                  data-cy="CY-amountOfUnitsInput"
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group">
              <div className="column" styleName="range-label">
                <label htmlFor="amountOfFreeUnits" className="pre">
                  <ResourceText resourceKey="amountOfFreeUnitsInProject" />
                </label>
              </div>
              <div className="column" styleName="range-input">
                <Input.Number
                  name={"amountOfFreeUnits"}
                  min={0}
                  max={999}
                  data-cy="CY-amountOfFreeUnitsInput"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private renderNumberRangeFormRow(name: string, postfix: string) {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column" styleName="range-label">
            <label htmlFor={`${name}Min`} className="pre">
              <ResourceText resourceKey={`projectLabel.${name}`} />
            </label>
          </div>
          <div className="column" styleName="range-input">
            <div className="input__helper">
              <Input.Number
                name={`${name}Min`}
                data-cy={"CY-" + name + "Min"}
              />
              <div className="post">
                <ResourceText resourceKey={postfix} asHtml />
              </div>
            </div>
          </div>
          <div className="column__textspacer">
            <ResourceText resourceKey="untilAnd" />
          </div>
          <div className="column" styleName="range-input">
            <div className="input__helper">
              <Input.Number
                name={`${name}Max`}
                data-cy={"CY-" + name + "Max"}
              />
              <div className="post">
                <ResourceText resourceKey={postfix} asHtml />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onNumbersChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id
    });

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      usageArea: { minArea: values.usageAreaMin, maxArea: values.usageAreaMax },
      parcelSurface: {
        minArea: values.parcelSurfaceMin,
        maxArea: values.parcelSurfaceMax
      },
      content: { minVolume: values.contentMin, maxVolume: values.contentMax },
      amountOfUnits: parseInt(values.amountOfUnits || "0"),
      amountOfFreeUnits: parseInt(values.amountOfFreeUnits || "0")
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, pathname);
  }
}
