import * as React from "react";
import { FormattedNumber } from "react-intl";
import * as CSSModules from "react-css-modules";

import {
  Form,
  Input,
  FormControls,
  FormReturnValue
} from "@haywork/modules/form";
import {
  CommissionPaidByOption,
  SaleOffer,
  CommissionType,
  CommissionPaidBy,
  CommissionTypeOption,
  RentOffer,
  RentCondition,
  ObjectAssignment,
  RealEstateGroup,
  AvailabilityStatus
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("./form.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface CourtageTotal {
  percentage: number;
  percentageTotal: number;
  fixed: number;
  months: number;
  total: number;
}

interface AssignmentEditFinancialCourtageComponentProps {
  commissionPaidByOptions?: CommissionPaidByOption[];
  commissionPaidBy?: CommissionPaidBy;
  commissionTypeOptions: CommissionTypeOption[];
  objectAssignment: ObjectAssignment;
  offer: SaleOffer | RentOffer;
  onChange: (values: FormReturnValue) => void;
}
interface AssignmentEditFinancialCourtageComponentState {
  courtage: CourtageTotal;
  commissionPaidBy: CommissionPaidBy;
  commissionType: CommissionType;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialCourtageComponent extends React.Component<
  AssignmentEditFinancialCourtageComponentProps,
  AssignmentEditFinancialCourtageComponentState
> {
  constructor(props) {
    super(props);

    const { offer } = this.props;

    this.state = {
      courtage: this.props.objectAssignment.forSale
        ? this.calculateSaleCourtage(offer)
        : this.calculateRentCourtage(offer),
      commissionPaidBy: offer.commissionPaidBy || CommissionPaidBy.Owner,
      commissionType: offer.commissionType || CommissionType.FixedAndPercentage
    };

    this.onCourtageChangeHandler = this.onCourtageChangeHandler.bind(this);
  }

  public render() {
    const {
      offer,
      commissionPaidByOptions,
      commissionTypeOptions
    } = this.props;

    const formControls: FormControls = {
      commissionPaidBy: {
        value:
          offer.commissionPaidBy || this.props.commissionPaidBy
            ? this.props.commissionPaidBy
            : CommissionPaidBy.Owner,
        onChange: (ref) => this.setState({ commissionPaidBy: ref.value })
      },
      commissionType: {
        value: offer.commissionType || CommissionType.FixedAndPercentage,
        onChange: (ref) => this.setState({ commissionType: ref.value })
      },
      commissionCustomerGross: { value: offer.commissionCustomerGross },
      commissionOwnerGross: { value: offer.commissionOwnerGross },
      commissionCustomerPercent: { value: offer.commissionCustomerPercent },
      commissionOwnerPercent: { value: offer.commissionOwnerPercent },
      commissionOwnerMonthsRent: {
        value: value(offer, "commissionOwnerMonthsRent")
      },
      commissionCustomerMonthsRent: {
        value: value(offer, "commissionCustomerMonthsRent")
      }
    };

    let showCommissionCustomerMonthsRent = false;
    let showCommissionOwnerMonthsRent = false;

    const forSale = this.props.objectAssignment.forSale;

    if (!forSale) {
      const rentOffer: RentOffer = offer;
      showCommissionCustomerMonthsRent =
        rentOffer.rentCondition !== RentCondition.PricePerContract &&
        this.state.commissionPaidBy !== CommissionPaidBy.Owner;
      showCommissionOwnerMonthsRent =
        rentOffer.rentCondition !== RentCondition.PricePerContract &&
        this.state.commissionPaidBy !== CommissionPaidBy.Customer;
    }

    const commissionCustomerGross = forSale
      ? "commissionCustomerGross"
      : "commissionCustomerGrossRent";
    const commissionCustomerGrossLease = forSale
      ? "commissionCustomerGross"
      : "commissionCustomerGrossLease";

    const commissionOwnerGross = forSale
      ? "commissionOwnerGross"
      : "commissionOwnerGrossRent";
    const commissionOwnerGrossLease = forSale
      ? "commissionOwnerGross"
      : "commissionOwnerGrossLease";

    const commissionCustomerPercent = forSale
      ? "commissionCustomerPercent"
      : "commissionCustomerPercentRent";
    const commissionCustomerPercentLease = forSale
      ? "commissionCustomerPercent"
      : "commissionCustomerPercentLease";

    const commissionOwnerPercent = forSale
      ? "commissionOwnerPercent"
      : "commissionOwnerPercentRent";
    const commissionOwnerPercentLease = forSale
      ? "commissionOwnerPercent"
      : "commissionOwnerPercentLease";

    const commissionOwnerMonthsResourceKey =
      this.props.objectAssignment.realEstateGroup ===
      RealEstateGroup.Agricultural
        ? "commissionOwnerMonthsLease"
        : "commissionOwnerMonthsRent";
    const commissionCustomerMonthsResourceKey =
      this.props.objectAssignment.realEstateGroup ===
      RealEstateGroup.Agricultural
        ? "commissionCustomerMonthsLease"
        : "commissionCustomerMonthsRent";

    return (
      <Form
        name="courtage"
        onChange={this.onCourtageChangeHandler}
        formControls={formControls}
        asSubForm
      >
        {/* Commission paid by */}
        {!!commissionPaidByOptions && !!commissionPaidByOptions.length && (
          <div className="form__row">
            <Input.RadioGroup name="commissionPaidBy" asButtonList>
              {commissionPaidByOptions.map((commissionPaidByOption, idx) => (
                <Input.Radio
                  label={this.returnDisplayName(commissionPaidByOption)}
                  value={commissionPaidByOption.value}
                  key={idx}
                />
              ))}
            </Input.RadioGroup>
          </div>
        )}

        {/* Commission type */}
        <div className="form__row">
          <Input.RadioGroup name="commissionType" asButtonList>
            {commissionTypeOptions.map((commissionTypeOption, idx) => (
              <Input.Radio
                label={commissionTypeOption.displayName}
                value={commissionTypeOption.value}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Courtage and total */}
        <div className="form__row">
          <div className="form__group top">
            <div className="column">
              {showCommissionOwnerMonthsRent && (
                <div styleName="inline-value">
                  <label htmlFor="commissionOwnerMonthsRent">
                    <ResourceText
                      resourceKey={commissionOwnerMonthsResourceKey}
                    />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="commissionOwnerMonthsRent" max={3} />
                    <div className="post full">
                      <ResourceText resourceKey="months" />
                    </div>
                  </div>
                </div>
              )}

              {showCommissionCustomerMonthsRent && (
                <div styleName="inline-value">
                  <label htmlFor="commissionCustomerMonthsRent">
                    <ResourceText
                      resourceKey={commissionCustomerMonthsResourceKey}
                    />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="commissionCustomerMonthsRent" max={3} />
                    <div className="post full">
                      <ResourceText resourceKey="months" />
                    </div>
                  </div>
                </div>
              )}

              {this.state.commissionType !== CommissionType.Percentage &&
                this.state.commissionPaidBy !== CommissionPaidBy.Owner && (
                  <div styleName="inline-value">
                    <label htmlFor="commissionCustomerGross">
                      <ResourceText
                        resourceKey={
                          this.props.objectAssignment.realEstateGroup ===
                          RealEstateGroup.Agricultural
                            ? commissionCustomerGrossLease
                            : commissionCustomerGross
                        }
                      />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="commissionCustomerGross"
                        pretty
                        asPrice
                      />
                    </div>
                  </div>
                )}

              {this.state.commissionType !== CommissionType.Percentage &&
                this.state.commissionPaidBy !== CommissionPaidBy.Customer && (
                  <div styleName="inline-value">
                    <label htmlFor="commissionOwnerGross">
                      <ResourceText
                        resourceKey={
                          this.props.objectAssignment.realEstateGroup ===
                          RealEstateGroup.Agricultural
                            ? commissionOwnerGrossLease
                            : commissionOwnerGross
                        }
                      />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="commissionOwnerGross"
                        pretty
                        asPrice
                      />
                    </div>
                  </div>
                )}

              {this.state.commissionType !== CommissionType.Fixed &&
                this.state.commissionPaidBy !== CommissionPaidBy.Owner && (
                  <div styleName="inline-value">
                    <label htmlFor="commissionCustomerPercent">
                      <ResourceText
                        resourceKey={
                          this.props.objectAssignment.realEstateGroup ===
                          RealEstateGroup.Agricultural
                            ? commissionCustomerPercentLease
                            : commissionCustomerPercent
                        }
                      />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="commissionCustomerPercent"
                        pretty
                        max={100}
                      />
                      <div className="post">%</div>
                    </div>
                  </div>
                )}

              {this.state.commissionType !== CommissionType.Fixed &&
                this.state.commissionPaidBy !== CommissionPaidBy.Customer && (
                  <div styleName="inline-value">
                    <label htmlFor="commissionOwnerPercent">
                      <ResourceText
                        resourceKey={
                          this.props.objectAssignment.realEstateGroup ===
                          RealEstateGroup.Agricultural
                            ? commissionOwnerPercentLease
                            : commissionOwnerPercent
                        }
                      />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="commissionOwnerPercent"
                        pretty
                        max={100}
                      />
                      <div className="post">%</div>
                    </div>
                  </div>
                )}
            </div>
            <div className="column__spacer wide" />
            <div className="column column__right">
              <div styleName="section__total">
                <h2>
                  <ResourceText resourceKey="courtageTotal" />
                </h2>
                {(showCommissionOwnerMonthsRent ||
                  showCommissionCustomerMonthsRent) && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText resourceKey="monthsTotal" />
                    </div>
                    <div styleName="value">
                      <FormattedNumber
                        value={this.state.courtage.months}
                        style="currency"
                        currency="EUR"
                      />
                    </div>
                  </div>
                )}

                {this.state.commissionType !== CommissionType.Percentage && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText resourceKey="fixed" />
                    </div>
                    <div styleName="value">
                      <FormattedNumber
                        value={this.state.courtage.fixed}
                        style="currency"
                        currency="EUR"
                      />
                    </div>
                  </div>
                )}
                {this.state.commissionType !== CommissionType.Fixed && (
                  <div styleName="item">
                    <div styleName="label">
                      <ResourceText
                        resourceKey={
                          forSale ? "yearPercentage" : "yearPercentageRent"
                        }
                        values={{ percentage: this.state.courtage.percentage }}
                      />
                    </div>
                    <div styleName="value">
                      <FormattedNumber
                        value={this.state.courtage.percentageTotal}
                        style="currency"
                        currency="EUR"
                      />
                    </div>
                  </div>
                )}

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="total" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.courtage.total}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentEditFinancialCourtageComponentProps
  ) {
    switch (this.props.objectAssignment.forSale) {
      case true: {
        const nextOffer: SaleOffer = nextProps.offer;
        const offer: SaleOffer = this.props.offer;
        if (nextOffer.salePrice !== offer.salePrice) {
          this.setState({ courtage: this.calculateSaleCourtage(nextOffer) });
        }
        break;
      }
      case false: {
        const nextOffer: RentOffer = nextProps.offer;
        const offer: RentOffer = this.props.offer;
        if (
          nextOffer.rentPrice !== offer.rentPrice ||
          nextOffer.rentCondition !== offer.rentCondition
        ) {
          this.setState({ courtage: this.calculateRentCourtage(nextOffer) });
        }
      }
      default:
        return;
    }
  }

  private returnDisplayName(option: CommissionPaidByOption): string {
    let displayName: string;

    if (this.props.objectAssignment.forSale) {
      displayName = option.displayNameForSale;
      return displayName;
    } else {
      if (
        this.props.objectAssignment.realEstateGroup ===
        RealEstateGroup.Agricultural
      ) {
        displayName = option.displayNameForPacht;
        return displayName;
      } else {
        displayName = option.displayNameForRent;
        return displayName;
      }
    }
  }

  private onCourtageChangeHandler(values: FormReturnValue) {
    let { offer } = this.props;
    offer = {
      ...offer,
      commissionCustomerGross: values.commissionCustomerGross,
      commissionOwnerGross: values.commissionOwnerGross,
      commissionCustomerPercent: values.commissionCustomerPercent,
      commissionOwnerPercent: values.commissionOwnerPercent,
      commissionCustomerMonthsRent: values.commissionCustomerMonthsRent,
      commissionOwnerMonthsRent: values.commissionOwnerMonthsRent,
      commissionType: values.commissionType,
      commissionPaidBy: values.commissionPaidBy || CommissionPaidBy.Owner
    };

    const courtage = this.props.objectAssignment.forSale
      ? this.calculateSaleCourtage(offer)
      : this.calculateRentCourtage(offer);

    this.setState({ courtage });
    this.props.onChange(values);
  }

  private calculateSaleCourtage(offer: SaleOffer): CourtageTotal {
    let courtage: CourtageTotal = {
      percentage: 0,
      percentageTotal: 0,
      fixed: 0,
      months: 0,
      total: 0
    };
    const commissionCustomerGross = parseFloat(
      value(offer, "commissionCustomerGross", 0)
    );
    const commissionOwnerGross = parseFloat(
      value(offer, "commissionOwnerGross", 0)
    );
    const commissionCustomerPercent = parseFloat(
      value(offer, "commissionCustomerPercent", 0)
    );
    const commissionOwnerPercent = parseFloat(
      value(offer, "commissionOwnerPercent", 0)
    );
    const { availabilityStatus } = this.props.objectAssignment;
    const price =
      [AvailabilityStatus.Sold, AvailabilityStatus.SoldUnderCondition].indexOf(
        availabilityStatus
      ) === -1
        ? parseFloat(value(offer, "salePrice", 0))
        : parseFloat(value(offer, "purchasePrice", 0));

    const fixedCustomer = commissionCustomerGross;
    const fixedOwner = commissionOwnerGross;
    const percentageCustomer = (commissionCustomerPercent / 100) * price;
    const percentageOwner = (commissionOwnerPercent / 100) * price;

    switch (offer.commissionPaidBy) {
      case CommissionPaidBy.Customer: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              fixed: fixedCustomer,
              total: fixedCustomer
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              percentage: commissionCustomerPercent,
              percentageTotal: percentageCustomer,
              fixed: fixedCustomer,
              total: fixedCustomer + percentageCustomer
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              percentage: commissionCustomerPercent,
              percentageTotal: percentageCustomer,
              total: percentageCustomer
            };
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.Owner: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              fixed: fixedOwner,
              total: fixedOwner
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              percentage: commissionOwnerPercent,
              percentageTotal: percentageOwner,
              fixed: fixedOwner,
              total: fixedOwner + percentageOwner
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              percentage: offer.commissionOwnerPercent,
              percentageTotal: percentageOwner,
              total: percentageOwner
            };
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.OwnerAndCustomer:
      default: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              fixed: fixedOwner + fixedCustomer,
              total: fixedOwner + fixedCustomer
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              percentage: commissionOwnerPercent + commissionCustomerPercent,
              percentageTotal: percentageOwner + percentageCustomer,
              fixed: fixedOwner + fixedCustomer,
              total:
                fixedOwner +
                percentageOwner +
                fixedCustomer +
                percentageCustomer
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              percentage: commissionOwnerPercent + commissionCustomerPercent,
              percentageTotal: percentageOwner + percentageCustomer,
              total: percentageOwner + percentageCustomer
            };
            break;
          }
        }
        break;
      }
    }

    return courtage;
  }

  private calculateRentCourtage(offer: RentOffer): CourtageTotal {
    let courtage: CourtageTotal = {
      percentage: 0,
      percentageTotal: 0,
      fixed: 0,
      months: 0,
      total: 0
    };

    const commissionCustomerGross = parseFloat(
      value(offer, "commissionCustomerGross", 0)
    );
    const commissionOwnerGross = parseFloat(
      value(offer, "commissionOwnerGross", 0)
    );
    const commissionCustomerMonthsRent = parseFloat(
      value(offer, "commissionCustomerMonthsRent", 0)
    );
    const commissionCustomerPercent = parseFloat(
      value(offer, "commissionCustomerPercent", 0)
    );
    const commissionOwnerPercent = parseFloat(
      value(offer, "commissionOwnerPercent", 0)
    );
    const commissionOwnerMonthsRent = parseFloat(
      value(offer, "commissionOwnerMonthsRent", 0)
    );
    const { availabilityStatus } = this.props.objectAssignment;
    const price =
      [
        AvailabilityStatus.Rented,
        AvailabilityStatus.RentedUnderCondition,
        AvailabilityStatus.Leased,
        AvailabilityStatus.LeasedUnderCondition,
        AvailabilityStatus.Farmed,
        AvailabilityStatus.FarmedUnderCondition
      ].indexOf(availabilityStatus) === -1
        ? parseFloat(value(offer, "rentPrice", 0))
        : parseFloat(value(offer, "realizedPerMonth", 0));

    let percentagePrice: number;
    switch (offer.rentCondition) {
      case RentCondition.PricePerDay: {
        percentagePrice = price * 365;
        break;
      }
      case RentCondition.PricePerWeek: {
        percentagePrice = price * 52;
        break;
      }
      case RentCondition.PricePerMonth: {
        percentagePrice = price * 12;
        break;
      }
      case RentCondition.PricePerQuarter: {
        percentagePrice = price * 4;
        break;
      }
      case RentCondition.PricePerHalfYear: {
        percentagePrice = price * 2;
        break;
      }
      default:
        percentagePrice = price;
        break;
    }

    const fixedCustomer = commissionCustomerGross;
    const fixedOwner = commissionOwnerGross;
    const percentageCustomer =
      (commissionCustomerPercent / 100) * percentagePrice;
    const percentageOwner = (commissionOwnerPercent / 100) * percentagePrice;
    const monthsCustomer =
      commissionCustomerMonthsRent * this.priceToMonth(price, offer);
    const monthsOwner =
      commissionOwnerMonthsRent * this.priceToMonth(price, offer);

    switch (offer.commissionPaidBy) {
      case CommissionPaidBy.Customer: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              months: monthsCustomer,
              fixed: fixedCustomer,
              total: fixedCustomer + monthsCustomer
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              months: monthsCustomer,
              percentage: commissionCustomerPercent,
              percentageTotal: percentageCustomer,
              fixed: fixedCustomer,
              total: fixedCustomer + percentageCustomer + monthsCustomer
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              months: monthsCustomer,
              percentage: commissionCustomerPercent,
              percentageTotal: percentageCustomer,
              total: percentageCustomer + monthsCustomer
            };
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.Owner: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              months: monthsOwner,
              fixed: fixedOwner,
              total: fixedOwner + monthsOwner
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              months: monthsOwner,
              percentage: commissionOwnerPercent,
              percentageTotal: percentageOwner,
              fixed: fixedOwner,
              total: fixedOwner + percentageOwner + monthsOwner
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              months: monthsOwner,
              percentage: offer.commissionOwnerPercent,
              percentageTotal: percentageOwner,
              total: percentageOwner + monthsOwner
            };
            break;
          }
        }
        break;
      }

      case CommissionPaidBy.OwnerAndCustomer:
      default: {
        switch (offer.commissionType) {
          case CommissionType.Fixed: {
            courtage = {
              ...courtage,
              months: monthsOwner + monthsCustomer,
              fixed: fixedOwner + fixedCustomer,
              total: fixedOwner + fixedCustomer + monthsOwner + monthsCustomer
            };
            break;
          }
          case CommissionType.FixedAndPercentage:
          default: {
            courtage = {
              ...courtage,
              months: monthsOwner + monthsCustomer,
              percentage: commissionOwnerPercent + commissionCustomerPercent,
              percentageTotal: percentageOwner + percentageCustomer,
              fixed: fixedOwner + fixedCustomer,
              total:
                fixedOwner +
                percentageOwner +
                fixedCustomer +
                percentageCustomer +
                monthsOwner +
                monthsCustomer
            };
            break;
          }
          case CommissionType.Percentage: {
            courtage = {
              ...courtage,
              months: monthsOwner + monthsCustomer,
              percentage: commissionOwnerPercent + commissionCustomerPercent,
              percentageTotal: percentageOwner + percentageCustomer,
              total:
                percentageOwner +
                percentageCustomer +
                monthsOwner +
                monthsCustomer
            };
            break;
          }
        }
        break;
      }
    }

    return courtage;
  }

  private priceToMonth(price: number, offer: RentOffer): number {
    if (this.props.objectAssignment.forSale) return 0;

    switch (offer.rentCondition) {
      case RentCondition.PricePerDay:
        return price * 31;
      case RentCondition.PricePerWeek:
        return price * 4;
      case RentCondition.PricePerMonth:
        return price;
      case RentCondition.PricePerQuarter:
        return price / 3;
      case RentCondition.PricePerHalfYear:
        return price / 6;
      case RentCondition.PricePerYear:
        return price / 12;
      default:
        return 0;
    }
  }
}
