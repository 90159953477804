export class RegexUtil {
  public static escapeRegexString(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  public static isNumber(value: string): boolean {
    if (value !== "") {
      const validNumbers = /^[/+0-9-]*$/;
      const result: RegExpMatchArray = value.match(validNumbers);
      return !!result;
    }
  }

  public static isValidEmail(value: string): boolean {
    if (value !== "") {
      const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailResult: RegExpMatchArray = value.match(validEmail);
      return !!emailResult;
    }
  }
}
