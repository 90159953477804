import * as React from "react";
import {
  FC,
  memo,
  useMemo,
  CSSProperties,
  MouseEvent,
  useRef,
  useCallback,
  useState,
  forwardRef,
} from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";
import Icon from "../icon";
import Hint from "../hint";
import { useIntl } from "react-intl";
import { useCombinedRefs } from "@haywork/hooks/combined-refs";

const styles = require("./style.scss");

type Props = {
  label: string;
  labelPrefix?: string;
  labelValues?: { [key: string]: any };
  color?: string;
  textColor?: string;
  solid?: boolean;
  icon?: string;
  deletable?: boolean;
  hintLabel?: string;
  tiny?: boolean;
  disableHint?: boolean;
  fullWidth?: boolean;
  hoverColor?: string;
  onClick?: () => void;
};

export const PillComponent = memo(
  forwardRef<HTMLDivElement, Props>(
    CSSModules(styles, { allowMultiple: true })(
      (
        {
          label,
          labelPrefix,
          labelValues,
          color,
          textColor,
          solid,
          icon,
          deletable,
          onClick,
          hintLabel,
          tiny,
          disableHint,
          fullWidth,
          hoverColor,
        },
        outerRef
      ) => {
        const innerRef = useRef<HTMLDivElement>(null);
        const ref = useCombinedRefs<HTMLDivElement>(outerRef, innerRef);
        const intl = useIntl();
        const [focus, setFocus] = useState(false);

        const style = useMemo(() => {
          let styles: CSSProperties = {};

          switch (true) {
            case !!color && !!solid: {
              styles = {
                ...styles,
                backgroundColor: color,
                borderColor: color,
                color: textColor || Colors.White,
              };
              break;
            }
            case !!color: {
              styles = {
                ...styles,
                borderColor: color,
                color: !focus ? textColor || color : Colors.White,
                backgroundColor: !focus ? Colors.White : color,
              };
              break;
            }
            case !!hoverColor && !!focus: {
              styles = {
                ...styles,
                borderColor: hoverColor,
                color: Colors.White,
                backgroundColor: hoverColor,
              };
              break;
            }
            default: {
              break;
            }
          }

          return styles;
        }, [color, textColor, solid, focus, hoverColor]);

        const iconColor = useMemo(() => {
          switch (true) {
            case !!textColor: {
              return textColor;
            }
            case !!color && !!solid: {
              return Colors.White;
            }
            case !!color: {
              return !focus ? color : Colors.White;
            }
            default: {
              return Colors.Gray;
            }
          }
        }, [color, solid, textColor, focus]);

        const onClickHandler = useCallback(
          (event: MouseEvent<HTMLDivElement>) => {
            if (!!onClick) {
              event.stopPropagation();
              onClick();
            }
          },
          [onClick]
        );

        const hint = useMemo(() => {
          switch (true) {
            case !!disableHint: {
              return null;
            }
            case !!hintLabel: {
              return hintLabel;
            }
            case !!labelPrefix && !!label: {
              const id = `${labelPrefix}.${label}`;

              return intl.formatMessage(
                {
                  id,
                  defaultMessage:
                    process.env.NODE_ENV === "development" ? id : label,
                },
                labelValues
              );
            }
            case !!label: {
              return intl.formatMessage(
                { id: label, defaultMessage: label },
                labelValues
              );
            }
            default: {
              return null;
            }
          }
        }, [disableHint, hintLabel, intl, labelPrefix, label, labelValues]);

        const onMouseEnter = useCallback(() => {
          if (!onClick) return;
          setFocus(true);
        }, [onClick, setFocus]);

        const onMouseLeave = useCallback(() => {
          if (!onClick) return;
          setFocus(false);
        }, [onClick, setFocus]);

        return (
          <div
            style={style}
            styleName={classNames("pill", {
              deletable,
              clickable: !!onClick,
              tiny,
            })}
            onClick={onClickHandler}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            aria-label={hint}
          >
            {!!icon && !deletable && (
              <div styleName="icon">
                <Icon name={icon} size={14} color={iconColor} />
              </div>
            )}
            <div styleName={classNames("label", { "full-width": fullWidth })}>
              <I18n
                prefix={labelPrefix}
                value={label}
                values={labelValues}
                asHtml
              />
            </div>
            {!!deletable && (
              <div styleName="delete">
                <Icon name="times" size={14} color={Colors.White} regular />
              </div>
            )}
            {!!hint && <Hint parentRef={ref} label={hint} />}
          </div>
        );
      }
    )
  )
);
