import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { PinnedNotes } from "@haywork/modules/notes-v3";
import { SearchAssignmentDetailGeneralContainerProps } from "@haywork/modules/search-assignment";
import { AuthorizationWidgets, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  SearchAssignmentWidgetDetailsComponent,
  SearchAssignmentWidgetLocationsComponent,
  SearchAssignmentWidgetMatchMailComponent,
  SearchAssignmentWidgetMatchMailsComponent,
  SearchAssignmentWidgetRelationsComponent,
  SearchAssignmentWidgetWishesComponent,
} from "./widgets";
import Status from "./widgets/status";
import I18n from "@haywork/components/i18n";
import { Link } from "react-router-dom";
import { APPXCHANGEROUTES, SETTINGSROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";

const styles = require("./detail-general.component.scss");

export type SearchAssignmentDetailGeneralComponentProps = {};
type Props = SearchAssignmentDetailGeneralComponentProps &
  SearchAssignmentDetailGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentDetailGeneralComponent extends React.PureComponent<Props> {
  public render() {
    const { searchAssignment, hasMatchMail } = this.props;
    const { assignmentPhase, id, isActive } = searchAssignment;

    return (
      <div styleName="general">
        <div styleName="body">
          {!hasMatchMail && (
            <div styleName="warning">
              <div styleName="warning__icon">
                <i className="fal fa-exclamation-triangle" />
              </div>
              <div styleName="warning__body">
                <div styleName="warning__text">
                  <I18n
                    value="search-assignment.warning.noMatchMailEnabled"
                    asHtml
                  />
                </div>
                <div styleName="warning__actions">
                  <Link to={SETTINGSROUTES.MATCHMAIL.URI}>
                    <I18n value="search-assignment.warning.noMatchMailEnabled.settingsAction" />
                  </Link>
                  <Link
                    to={RouteUtil.mapStaticRouteValues(
                      APPXCHANGEROUTES.DETAIL.URI,
                      { id: "518122e2-2c77-4da0-b96d-38d45cc93e8c" }
                    )}
                  >
                    <I18n value="search-assignment.warning.noMatchMailEnabled.appXchangeAction" />
                  </Link>
                </div>
              </div>
            </div>
          )}

          <ErrorBoundary>
            <div styleName="body__section">
              <Status assignmentPhase={assignmentPhase} isActive={isActive} />
            </div>
          </ErrorBoundary>

          {/* Locations */}
          <ErrorBoundary>
            <div styleName="body__section">
              <h2>
                <ResourceText resourceKey="searchAssignment.widgetTitle.locations" />
              </h2>
              <div styleName="widgetLocations">
                <SearchAssignmentWidgetLocationsComponent
                  searchAssignment={this.props.searchAssignment}
                />
              </div>

              <PinnedNotes />
            </div>
          </ErrorBoundary>

          {/* Details */}
          <ErrorBoundary>
            <div styleName="body__section">
              <SearchAssignmentWidgetDetailsComponent
                searchAssignment={this.props.searchAssignment}
              />
            </div>
          </ErrorBoundary>

          {/* Wishes */}
          <ErrorBoundary>
            <div styleName="body__section">
              <SearchAssignmentWidgetWishesComponent
                searchAssignment={this.props.searchAssignment}
              />
            </div>
          </ErrorBoundary>

          {/* Matchmail */}
          <ErrorBoundary>
            <div styleName="body__section">
              <SearchAssignmentWidgetMatchMailComponent
                searchAssignment={this.props.searchAssignment}
              />
            </div>
          </ErrorBoundary>
        </div>

        <div styleName="sidebar">
          <ErrorBoundary>
            <SearchAssignmentWidgetRelationsComponent
              searchAssignment={this.props.searchAssignment}
              onNavigateToRelation={this.props.navigate}
            />
          </ErrorBoundary>

          {/* Authorization widgets */}
          <FeatureSwitch feature="APP_XCHANGE">
            <AuthorizationWidgets
              type={WidgetEntityType.SearchAssignment}
              location={WidgetEntityLocation.Sidebar}
              id={id}
            />
          </FeatureSwitch>

          <ErrorBoundary>
            <SearchAssignmentWidgetMatchMailsComponent
              matchMails={this.props.matchMails}
              searchAssignment={this.props.searchAssignment}
              getMatchMailProperties={this.props.getMatchMailProperties}
              navigate={this.props.navigate}
              getWidgetSearchAssignmentMatchMails={
                this.props.getWidgetSearchAssignmentMatchMails
              }
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}
