import * as React from "react";
import get from "lodash-es/get";
import {
  Children,
  FC,
  memo,
  useContext,
  useEffect,
  useCallback,
  MouseEvent,
} from "react";
import { ListContext } from "../../list.context";
import classNames from "classnames";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  zebra?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  className?: string;
};

export const RowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, zebra, onClick, className }) => {
      const { rowHeight: height } = useContext(ListContext);
      const onClickHandler = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          if (!onClick) return;
          event.stopPropagation();
          onClick(event);
        },
        [onClick]
      );

      useEffect(() => {
        if (process.env.NODE_ENV === "development") {
          const filteredChildren = Children.toArray(children).filter(
            (child: any) => {
              return !["ActionsColumn", "Column"].includes(
                get(child, "type.displayName")
              );
            }
          );

          if (!!filteredChildren.length) {
            throw Error(
              "Rows can only have <Column /> or <ActionsColumn /> components as childs."
            );
          }
        }
      }, [children]);

      return (
        <div
          styleName={classNames("row", className, { zebra })}
          style={{ height }}
          onClick={onClickHandler}
        >
          {children}
        </div>
      );
    }
  )
);
