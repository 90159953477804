import { WidgetEntityType } from "@haywork/api/authorization";
import { RelationType } from "@haywork/api/kolibri";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Notes from "@haywork/modules/notes-v3";
import {
  RelationAssignmentContainerProps,
  RelationAssignmentListItemComponent,
} from "@haywork/modules/relation";
import { InfiniteScroll, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface RelationAssignmentComponentProps {}
interface State {}
type Props = RelationAssignmentComponentProps &
  RelationAssignmentContainerProps;

const styles = require("./relation-assignment.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class RelationAssignmentComponent extends React.Component<Props, State> {
  public componentDidMount() {
    this.props.getRelationAssignments(this.props.selectedRelationId, true);

    const widgetEntityType =
      this.props.selectedRelationType === RelationType.ContactPerson
        ? WidgetEntityType.ContactPerson
        : WidgetEntityType.ContactCompany;

    this.props.getInitializedWidgets(
      this.props.selectedRelationId,
      widgetEntityType
    );
  }

  public render() {
    const assignments = this.props.relationAssignments;
    const relationName = this.props.selectedRelationDisplayName;
    return (
      <div styleName="assignments">
        <PageHeader
          title="pageTitle.relation.assignments"
          subTitle="pageSubTitle.relation.assignments"
          subTitleValues={{ name: relationName }}
          actions={<Notes />}
        />

        <div styleName="body">
          {assignments && assignments.length > 0 ? (
            this.renderAssignments()
          ) : (
            <div styleName="emptyState">
              <div styleName="content">
                <div styleName="icon" />
                <div styleName="text">
                  <h2>
                    <ResourceText resourceKey="emptyStateNoAssignmentsTitle" />
                  </h2>
                  <p>
                    <ResourceText resourceKey="emptyStateNoAssignmentsText" />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  public renderAssignments() {
    return (
      <InfiniteScroll scrollEnd={() => this.onScrollEndHandler()}>
        {this.props.relationAssignments.map((assignment, idx) => (
          <ErrorBoundary key={idx}>
            <RelationAssignmentListItemComponent
              assignment={assignment}
              relationId={this.props.selectedRelationId}
              zebra={idx % 2 !== 0}
              onAssignmentClick={(assignment) =>
                this.props.goToAssignmentDetail(assignment)
              }
            />
          </ErrorBoundary>
        ))}
      </InfiniteScroll>
    );
  }

  private onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.relationAssignmentState === REQUEST.PENDING
    )
      return;
    this.props.getRelationAssignments(this.props.selectedRelationId, false);
  }
}
