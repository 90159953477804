import { Account } from "@haywork/api/mail";
import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import {
  FolderGroupComponent,
  FolderGroupComponentProps,
} from "./folder-group";
import { accounts } from "../../selectors";

type StateProps = {
  accounts: Account[];
  folders: EmailFolder[];
  currentAccount: string;
  currentFolder: string;
};
type DispatchProps = {
  setCurrentFolder: (folder: EmailFolder) => void;
  removeFolder: (id: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FolderGroupComponentProps,
  AppState
> = (state) => {
  const { folders } = state.emailV2.folders;
  const { currentAccount, currentFolder } = state.emailV2.main;

  return {
    accounts: accounts(state),
    folders,
    currentAccount,
    currentFolder,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FolderGroupComponentProps
> = (dispatch) => ({
  setCurrentFolder: (folder: EmailFolder) =>
    dispatch(EmailThunk.Main.setCurrentFolder(folder)),
  removeFolder: (id: string) => dispatch(EmailThunk.Folders.removeFolder(id)),
});

export type FolderGroupContainerProps = StateProps & DispatchProps;
export const FolderGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderGroupComponent);
