import { REDUX } from "@haywork/constants";
import { FolderTreeEntityType } from "@haywork/middleware";

const setEntityFolderTreeId = (
  id: string,
  entityType: FolderTreeEntityType,
  entityId: string
) => ({
  type: REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID,
  id,
  entityType,
  entityId
});

export const DossierActions = {
  setEntityFolderTreeId
};
