import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { FormattedNumber } from "react-intl";
import { AssignmentSnapShot, AssignmentType } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import FormattedText from "@haywork/components/ui/formatted-text";
import I18n from "@haywork/components/i18n";

interface Props {
  assignment: AssignmentSnapShot;
  relationId: string;
  zebra: boolean;
  onAssignmentClick?: (assignment: AssignmentSnapShot) => void;
}

const styles = require("./relation-assignment-list-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class RelationAssignmentListItemComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.renderMetaData = this.renderMetaData.bind(this);
    this.renderDisplayName = this.renderDisplayName.bind(this);
  }

  public render() {
    const assignment = this.props.assignment;
    const listItemStyle = classNames("list-item", { zebra: this.props.zebra });
    const hasPreview = !!assignment.photo1Url && assignment.photo1Url !== "";
    const previewStyle = hasPreview
      ? { backgroundImage: `url(${JSON.stringify(assignment.photo1Url)})` }
      : null;
    const enumToString = StringUtil.enumToString;
    const association =
      this.renderAssociationBetweenAssignmentAndSelectedRelation();

    return (
      <div
        styleName={listItemStyle}
        onClick={() => this.props.onAssignmentClick(this.props.assignment)}
      >
        <div styleName="list-item__preview">
          <div styleName="preview" style={previewStyle}>
            {!hasPreview && <i className="fal fa-fw fa-camera-retro" />}
          </div>
        </div>
        <div styleName="list-item__info">
          <div styleName="meta">{this.renderMetaData()}</div>
          <div styleName="title">{this.renderDisplayName()}</div>
          <div styleName="type">
            <I18n
              prefix="listingTypes"
              value={enumToString(assignment.listingType)}
            />
          </div>
        </div>
        {assignment.typeOfAssignment !== AssignmentType.Acquisition && (
          <div styleName="list-item__meta">
            {assignment.forSale && (
              <div styleName="meta">
                <span styleName="label">
                  <I18n value="askingPrice" />
                </span>
                {this.renderPrice(assignment.salePrice)}
              </div>
            )}
            {assignment.forRent && (
              <div styleName="meta">
                <span styleName="label">
                  <I18n value="rentalPrice" />
                </span>
                {this.renderPrice(assignment.rentPrice)}
              </div>
            )}
            {assignment.typeOfAssignment === AssignmentType.Object && (
              <div styleName="meta">
                <span styleName="label">
                  <I18n value="courtage" />
                </span>
                {assignment.forSale &&
                  this.renderCommision(assignment.saleCommissionTotal)}
                {assignment.forRent &&
                  this.renderCommision(assignment.rentCommissionTotal)}
              </div>
            )}
          </div>
        )}
        <div styleName="list-item__status">
          <div styleName="type">
            <I18n
              prefix="assignmentPhases"
              value={enumToString(assignment.assignmentPhase)}
            />
          </div>
          <div styleName="status">
            <I18n
              prefix="availabilityStatuses"
              value={enumToString(assignment.availabilityStatus)}
            />
          </div>
        </div>
        <div styleName="list-item__association">
          {!!association && (
            <>
              <div styleName="type">
                <I18n value="role" />
              </div>
              <div styleName="status">{association}</div>
            </>
          )}
        </div>
      </div>
    );
  }

  private renderPrice(price: number) {
    if (!!price && price > 0) {
      return (
        <div styleName="value">
          <FormattedNumber value={price} style="currency" currency="EUR" />
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <FormattedText value="none" textStyle="lowercase" />
        </div>
      );
    }
  }

  private renderCommision(total: number) {
    if (!!total && total > 0) {
      return (
        <div styleName="value positive">
          <FormattedNumber value={total} style="currency" currency="EUR" />
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <FormattedText value="none" textStyle="lowercase" />
        </div>
      );
    }
  }

  private renderAssociationBetweenAssignmentAndSelectedRelation() {
    const { assignment, relationId } = this.props;
    const {
      linkedVendors,
      linkedApplicants,
      linkedNotaries,
      linkedPropertyManagers,
      forSale,
    } = assignment;

    switch (true) {
      case !!linkedVendors &&
        !!linkedVendors.find((relation) => relation.id === relationId): {
        const vendorType = forSale ? "VendorSale" : "VendorRent";
        return (
          <I18n prefix="assignmentRelationAssocition" value={vendorType} />
        );
      }
      case !!linkedApplicants &&
        !!linkedApplicants.find((relation) => relation.id === relationId): {
        const applicantType = forSale ? "ApplicantSale" : "ApplicantRent";
        return (
          <I18n prefix="assignmentRelationAssocition" value={applicantType} />
        );
      }
      case !!linkedNotaries &&
        !!linkedNotaries.find((relation) => relation.id === relationId): {
        return <I18n prefix="assignmentRelationAssocition" value="Notary" />;
      }
      case !!linkedPropertyManagers &&
        !!linkedPropertyManagers.find(
          (relation) => relation.id === relationId
        ): {
        return (
          <I18n prefix="assignmentRelationAssocition" value="PropertyManager" />
        );
      }
      default: {
        return "";
      }
    }
  }

  private renderMetaData() {
    const { realEstateGroup, forSale, forRent, typeOfAssignment } =
      this.props.assignment;

    switch (true) {
      case typeOfAssignment === AssignmentType.Acquisition: {
        let translation = "";
        switch (true) {
          case !!forSale && !!forRent:
          default: {
            translation = "acquisition.info.forSaleAndForRent";
            break;
          }
          case !!forRent: {
            translation = "acquisition.info.forRent";
            break;
          }
          case !!forSale: {
            translation = "acquisition.info.forSale";
            break;
          }
        }

        return (
          <span>
            <I18n
              prefix="realEstateGroups"
              value={realEstateGroup.toString()}
            />
            , <I18n value={translation} />
          </span>
        );
      }
      default: {
        return (
          <span>
            <I18n
              prefix="realEstateGroups"
              value={realEstateGroup.toString()}
            />
            , <I18n value={forSale ? "forSale" : "forRent"} />
          </span>
        );
      }
    }
  }

  private renderDisplayName() {
    const { displayName, publicReference, forSale, forRent, typeOfAssignment } =
      this.props.assignment;

    switch (true) {
      case typeOfAssignment === AssignmentType.Acquisition: {
        switch (true) {
          case !!publicReference: {
            return <span>{publicReference}</span>;
          }
          case !!forRent && !!forSale:
          default: {
            return (
              <span>
                <I18n value="acquisition.type.forSaleAndRent" />
              </span>
            );
          }
          case !!forRent: {
            return (
              <span>
                <I18n value="acquisition.type.forRent" />
              </span>
            );
          }
          case !!forSale: {
            return (
              <span>
                <I18n value="acquisition.type.forSale" />
              </span>
            );
          }
        }
      }
      default: {
        return <span>{displayName}</span>;
      }
    }
  }
}
