import * as React from "react";
import { FC, memo, useState, useCallback, useMemo } from "react";
import { SearchLocation } from "@haywork/api/mls";
import * as CSSModules from "react-css-modules";
import { Input } from "@haywork/modules/form";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { useIntl } from "react-intl";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  searchLocation: SearchLocation;
  onChange: (searchLocation: SearchLocation) => void;
  onDelete: (id: string) => void;
};

export const SearchLocationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ searchLocation, onChange, onDelete }) => {
      const { id } = searchLocation;
      const [name, setName] = useState(searchLocation.name);
      const [postalCodeMin, setPostalCodeMin] = useState(
        searchLocation.postalCodeMin
      );
      const [postalCodeMax, setPostalCodeMax] = useState(
        searchLocation.postalCodeMax
      );
      const [isDefault, setIsDefault] = useState(searchLocation.isDefault);
      const intl = useIntl();

      const deleteCallback = useCallback(() => {
        onDelete(id);
      }, [id, onDelete]);

      const changeName = useCallback(
        (name: string) => {
          setName(name);
          onChange({ ...searchLocation, name });
        },
        [setName, searchLocation, onChange]
      );

      const changePostalCodeMin = useCallback(
        (postalCodeMin: number) => {
          setPostalCodeMin(postalCodeMin);
          onChange({ ...searchLocation, postalCodeMin });
        },
        [setPostalCodeMin, searchLocation, onChange]
      );

      const changePostalCodeMax = useCallback(
        (postalCodeMax: number) => {
          setPostalCodeMax(postalCodeMax);
          onChange({ ...searchLocation, postalCodeMax });
        },
        [setPostalCodeMax, searchLocation, onChange]
      );

      const changeIsDefault = useCallback(
        (isDefault: boolean) => {
          setIsDefault(isDefault);
          onChange({ ...searchLocation, isDefault });
        },
        [setIsDefault, searchLocation, onChange]
      );

      const header = useMemo(() => {
        return (
          name ||
          intl.formatMessage({
            id: "mls.searchLocation.emptyHeader",
            defaultMessage: "Add area",
          })
        );
      }, [name, intl]);

      return (
        <div styleName="location">
          <div styleName="header">
            <div styleName="header__title">{header}</div>
            <div styleName="header__trigger" onClick={deleteCallback}>
              <Icon name="times" light color={Colors.Danger} />
            </div>
          </div>
          <div styleName="row">
            <div styleName="row__inputs">
              <div styleName="column full">
                <Input.Text
                  name="name"
                  value={name}
                  placeholder="mls.searchLocation.label.name"
                  onChange={changeName}
                  asSingleInput
                />
              </div>
              <div styleName="column">
                <I18n value="from" />
              </div>
              <div styleName="column medium">
                <Input.Number
                  name="postalCodeMin"
                  value={postalCodeMin}
                  placeholder="mls.searchLocation.label.postalCode"
                  onChange={changePostalCodeMin}
                  asSingleInput
                  min={1000}
                  max={9999}
                  maxLength={4}
                />
              </div>
              <div styleName="column">
                <I18n value="untill" />
              </div>
              <div styleName="column medium">
                <Input.Number
                  name="postalCodeMax"
                  value={postalCodeMax}
                  placeholder="mls.searchLocation.label.postalCode"
                  onChange={changePostalCodeMax}
                  asSingleInput
                  min={1000}
                  max={9999}
                  maxLength={4}
                />
              </div>
              <div styleName="column">
                <Input.Switch
                  name="isDefault"
                  label="mls.searchLocation.label.isDefault"
                  value={isDefault}
                  on={true}
                  off={false}
                  onChange={changeIsDefault}
                  asSingleInput
                />
              </div>
            </div>
            {(!name || !postalCodeMin || !postalCodeMax) && (
              <div styleName="row__info">
                <i className="fal fa-fw fa-exclamation-triangle" />
                <span>
                  <I18n value="mls.searchLocation.warning.missingFields" />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
