import {
  Channel,
  ChannelType,
  EventEntity,
  EventHubMessage
} from "@haywork/api/kolibri";
import * as Ably from "ably";
import isString from "lodash-es/isString";
import * as React from "react";
import { PureComponent } from "react";
import Connecter from "./context-connector";
import { KolibriContainerProps } from "./kolibri.container";

export type KolibriComponentProps = {};
type Props = KolibriComponentProps & KolibriContainerProps;
type State = {
  channels: Ably.Types.RealtimeChannelCallbacks[];
  realtime: Ably.Realtime | null;
};

export class KolibriComponent extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);

    this.state = {
      channels: [],
      realtime: null
    };
  }

  public render() {
    return <Connecter onConnectionChange={this.onConnectionChange} />;
  }

  public componentWillUnmount() {
    this.unsubscribe();
    this.setState({ channels: [] });
  }

  private onConnectionChange(
    kolibriChannels: Channel[],
    realtime: Ably.Realtime | null
  ) {
    this.unsubscribe();

    if (!realtime) {
      this.setState({ channels: [], realtime: null });
      return;
    }

    const channels = kolibriChannels
      .filter((channel) => channel.type === ChannelType.CompanyEntity)
      .map((channel) => {
        const realtimeChannel = realtime.channels.get(channel.name);

        realtimeChannel.subscribe((response) => {
          const data: EventHubMessage = isString(response.data)
            ? JSON.parse(response.data)
            : response.data;

          this.handleEvent(data);
        });

        return realtimeChannel;
      });

    this.setState({ channels, realtime });
  }

  private handleEvent(data: EventHubMessage) {
    const { entity } = data;
    if (entity.entityType !== EventEntity.ObjectAssignment) return;
    this.props.addReceivedBrochures(entity.id);
  }

  private unsubscribe() {
    const { channels } = this.state;
    channels.map((channel) => channel.unsubscribe());
  }
}
