export class Redirect {
  private windowOrigin = window.location.origin;
  private windowRef: Window;

  constructor() {
    this.windowRef = window.open(
      `${this.windowOrigin}/redirect.html`,
      "_blank"
    );
  }

  public navigate(url: string) {
    if (!this.windowRef) return;
    try {
      this.windowRef.location.replace(url);
    } catch (error) {
      this.windowRef.location.href = url;
    }
  }

  public onClose(callback: Function) {
    if (!this.windowRef) return;
    const interval = setInterval(async () => {
      if (!!this.windowRef && !!this.windowRef.closed) {
        clearInterval(interval);
        callback();
      }
    }, 500);
  }
}
