import { MapDispatchToProps } from "@haywork/middleware/thunk";
import { AppState, AuthorizationActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  WindowMessagesComponent,
  WindowMessagesComponentProps,
} from "./window-messages";
import { InitializedWidget } from "@haywork/api/authorization";
import { push } from "connected-react-router";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { EmailAddress } from "@haywork/api/mail";

type StateProps = {
  initializedWidgets: InitializedWidget[];
};
type DispatchProps = {
  navigate: (route: string) => void;
  createNewDraft: (
    to?: EmailAddress[],
    subject?: string,
    body?: string
  ) => void;
  hideModal: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  WindowMessagesComponentProps,
  AppState
> = (state) => {
  const initializedWidgets =
    state.authorization.widgets.initializedWidgets || [];

  return {
    initializedWidgets,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  WindowMessagesComponentProps
> = (dispatch) => ({
  navigate: (route: string) => dispatch(push(route)),
  createNewDraft: (to?: EmailAddress[], subject?: string, body?: string) =>
    dispatch(EmailThunk.Drafts.createNewDraft(to, subject, body)),
  hideModal: () => dispatch(AuthorizationActions.Widgets.hideModal()),
});

export type WindowMessagesContainerProps = StateProps & DispatchProps;
export const WindowMessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WindowMessagesComponent);
