import { Action } from "redux";
import differenceBy from "lodash-es/differenceBy";

import { REDUX, REQUEST } from "@haywork/constants";

import { ExtendedObjectTypeAssignment } from "./";
import * as ActionType from "./types.types";
import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { FolderTreeEntityType } from "@haywork/middleware";

export interface TypesState {
  currentType: ExtendedObjectTypeAssignment;
  currentTypeStatus: string;
  saveObjectTypeStatus: string;
  buildnumbers: AssignmentSnapShot[];
  buildnumbersCanLoadMore: boolean;
  buildnumbersStatus: string;
  buildnumbersTotal: number;
}

const INITIAL_STATE: TypesState = {
  currentType: null,
  currentTypeStatus: REQUEST.IDLE,
  saveObjectTypeStatus: REQUEST.IDLE,
  buildnumbers: [],
  buildnumbersCanLoadMore: true,
  buildnumbersStatus: REQUEST.IDLE,
  buildnumbersTotal: 0
};

export const typesReducer = (
  state: TypesState = INITIAL_STATE,
  action: Action
): TypesState => {
  switch (action.type) {
    case REDUX.OBJECTTYPES.SET_TYPE: {
      const { currentType } = <ActionType.CurrentType>action;

      return {
        ...state,
        currentType,
        currentTypeStatus: REQUEST.SUCCESS,
        saveObjectTypeStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.OBJECTTYPES.SET_TYPE_STATUS: {
      const { currentTypeStatus } = <ActionType.TypeStatus>action;

      return {
        ...state,
        currentTypeStatus
      };
    }
    case REDUX.OBJECTTYPES.SET_SAVE_TYPE_STATUS: {
      const { saveObjectTypeStatus } = <ActionType.SaveObjectTypeStatus>action;

      return {
        ...state,
        saveObjectTypeStatus
      };
    }
    case REDUX.OBJECTTYPES.SET_BUILDNUMBERS: {
      const { buildnumbers, buildnumbersCanLoadMore, buildnumbersTotal } = <
        ActionType.Buildnumbers
      >action;

      return {
        ...state,
        buildnumbers,
        buildnumbersCanLoadMore,
        buildnumbersStatus: REQUEST.SUCCESS,
        buildnumbersTotal
      };
    }
    case REDUX.OBJECTTYPES.ADD_BUILDNUMBERS: {
      const {
        buildnumbers: appendables,
        buildnumbersCanLoadMore,
        buildnumbersTotal
      } = <ActionType.Buildnumbers>action;
      let buildnumbers = differenceBy(
        state.buildnumbers,
        appendables,
        (buildnumber) => buildnumber.id
      );

      buildnumbers = [...buildnumbers, ...appendables];

      return {
        ...state,
        buildnumbers,
        buildnumbersCanLoadMore,
        buildnumbersStatus: REQUEST.SUCCESS,
        buildnumbersTotal
      };
    }
    case REDUX.OBJECTTYPES.SET_BUILDNUMBERS_STATUS: {
      const { buildnumbersStatus } = <ActionType.BuildnumbersStatus>action;

      return {
        ...state,
        buildnumbersStatus
      };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityType, entityId } = <any>action;
      if (
        entityType === FolderTreeEntityType.ObjectTypeAssignment &&
        !!state.currentType &&
        state.currentType.id === entityId
      ) {
        return {
          ...state,
          currentType: {
            ...state.currentType,
            linkedFolderTree: {
              id
            }
          }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
