import { createSelector } from "reselect";
import get from "lodash-es/get";

import { AppState } from "@haywork/stores";
import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";

const templatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;
const categorySelector = (state: AppState) =>
  state.emailV2.templates.categories;

export const signatures = createSelector(
  [templatesSelector, categorySelector],
  (templates, categories) => {
    const signaturesCategoryIds = categories
      .filter(
        (category) =>
          category.categoryType ===
          TemplateDefinitionCategoryType.EmailSignature
      )
      .map((category) => category.id);

    return templates.filter((template) => {
      return (
        signaturesCategoryIds.indexOf(
          get(template, "linkedTemplateDefinitionCategory.id")
        ) !== -1
      );
    });
  }
);
