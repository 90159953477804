import {
  RelationSnapShot,
  LinkedRelation,
  Gender,
  ContactPerson,
  Employee,
  CountryOption,
  Address,
  LinkedEmployee,
  RelationRoleOption,
  AssignmentType,
} from "@haywork/api/kolibri";
import isArray from "lodash-es/isArray";
import get from "lodash-es/get";
import { String } from "./string";
import { intlContext } from "@haywork/app";

export interface MappedLinkedRelation extends LinkedRelation {
  isMapped?: boolean;
}

export class Relation {
  public static isLinkedRelation(
    relation: RelationSnapShot | LinkedRelation
  ): relation is LinkedRelation {
    return (<LinkedRelation>relation).togetherWithPartner !== undefined;
  }

  public static getFormalAppelation(
    useFormalAppelation: boolean,
    gender: Gender,
    firstName: string,
    middleName: string,
    lastName: string
  ): string {
    let genderText;
    let combinedLastName = [middleName, String.capitalize(lastName)]
      .filter((n) => !!n)
      .join(" ");
    if (!combinedLastName) {
      combinedLastName = "";
    }

    switch (gender) {
      case Gender.Male:
        genderText = intlContext.formatMessage({
          id: "maleStandardGenderText",
        });
        break;
      case Gender.Female:
        genderText = intlContext.formatMessage({
          id: "femaleStandardGenderText",
        });
        break;
      case Gender.Unknown:
      default:
        genderText = intlContext.formatMessage({
          id: "unknownStandardGenderText",
        });
        break;
    }

    if (useFormalAppelation) {
      return combinedLastName
        ? intlContext.formatMessage(
            { id: "formalSalutation" },
            { gender: genderText, lastName: combinedLastName }
          )
        : intlContext.formatMessage(
            { id: "formalSalutationNoName" },
            { gender: genderText }
          );
    } else {
      return intlContext.formatMessage(
        { id: "informalSalutation" },
        { firstName }
      );
    }
  }

  public static getFormalSalutation(
    relation: ContactPerson | Employee
  ): string {
    let genderText;

    switch (relation.gender) {
      case Gender.Male:
        genderText = intlContext.formatMessage({ id: "maleSalutation" });
        break;
      case Gender.Female:
        genderText = intlContext.formatMessage({ id: "femaleSalutation" });
        break;
      default:
        break;
    }

    if (!!relation.nameLetters) {
      return [
        genderText,
        relation.nameLetters,
        relation.middleName,
        String.capitalize(relation.lastName),
      ]
        .filter((n) => !!n)
        .join(" ");
    }

    return [
      genderText,
      relation.nickname,
      relation.middleName,
      relation.lastName,
    ].join(" ");
  }

  public static getAddressCountryDisplayName(
    countries: CountryOption[],
    address: Address
  ): string {
    if (!countries || !address || !isArray(countries) || !address.countryIso2)
      return null;
    const country = countries.find(
      (country) => get(country, "iso2CodeValue") === address.countryIso2
    );

    return get(country, "displayName") || null;
  }

  public static getBirthCountryDisplayName(
    countries: CountryOption[],
    contactPerson: ContactPerson
  ): string {
    if (
      !countries ||
      !contactPerson ||
      !isArray(countries) ||
      !contactPerson.countryIso2OfBirth
    )
      return null;
    const country = countries.find(
      (country) =>
        get(country, "iso2CodeValue") === contactPerson.countryIso2OfBirth
    );

    return get(country, "displayName") || null;
  }

  public static mapEmployeeToLinkedEmployee(
    employee: Employee
  ): LinkedEmployee {
    return {
      id: employee.id,
      displayName: employee.displayName,
    };
  }

  public static mapRelationRoles(
    relationRoles: RelationRoleOption[],
    assignmentType: AssignmentType,
    forSale: boolean
  ) {
    const relationRolesToUse = relationRoles.filter(
      (role) =>
        role.assignmentTypes &&
        role.assignmentTypes.indexOf(assignmentType) > -1
    );

    return relationRolesToUse.map((val) => {
      return forSale
        ? { label: val.displayNameForSale, value: val.value }
        : {
            label: val.displayNameForRent,
            value: val.value,
          };
    });
  }

  public static mapSnapshotToLinkedRelation(
    relation: RelationSnapShot | MappedLinkedRelation
  ): MappedLinkedRelation {
    if ("isMapped" in relation || relation.hasOwnProperty("phoneMobile")) {
      return relation;
    } else {
      const {
        id,
        isActive,
        dateTimeModified,
        dateTimeCreated,
        avatarUrl,
        dateOfBirth,
        displayName,
        email,
        linkedPartner,
        letterAvatar,
        locality,
        phoneNumber,
        mobileNumber,
        typeOfRelation,
      } = relation as RelationSnapShot;

      return {
        id,
        isActive,
        dateTimeCreated,
        dateTimeModified,
        avatarUrl,
        dateOfBirth,
        displayName,
        email,
        linkedPartner,
        letterAvatar,
        locality,
        phone: phoneNumber,
        phoneMobile: mobileNumber,
        typeOfRelation,
        isMapped: true,
      };
    }
  }

  public static mapLinkedRelationToSnapshot(
    relation: LinkedRelation
  ): RelationSnapShot {
    if ("isMapped" in relation) {
      return relation as RelationSnapShot;
    } else {
      const {
        id,
        isActive,
        dateTimeModified,
        dateTimeCreated,
        avatarUrl,
        dateOfBirth,
        displayName,
        email,
        linkedPartner,
        letterAvatar,
        locality,
        typeOfRelation,
        phone,
        phoneMobile,
      } = relation as LinkedRelation;

      return {
        id,
        isActive,
        dateTimeCreated,
        dateTimeModified,
        avatarUrl,
        dateOfBirth,
        displayName,
        email,
        linkedPartner,
        letterAvatar,
        locality,
        phoneNumber: phone,
        mobileNumber: phoneMobile,
        typeOfRelation,
      };
    }
  }
}
