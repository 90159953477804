import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface HeadingComponentProps {
  formElement: FlattenedFormElement;
}
interface HeadingComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class HeadingComponent extends React.Component<
  HeadingComponentProps,
  HeadingComponentState
> {
  public render() {
    const { heading, name, depth } = this.props.formElement;
    const headingStyle = classNames("heading", `heading${depth}`);

    return <div styleName={headingStyle}>{heading || name}</div>;
  }
}
