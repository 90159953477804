import {
  SearchAssignmentOrderByField,
  SearchAssignmentSnapShot,
  SortOrder
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import List, {
  ActiveFilters,
  FilterConfig,
  ListColumnConfig,
  ListRefProps,
  ListWrapper
} from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef } from "react";
import * as CSSModules from "react-css-modules";
import Filters from "./components/filters";
import Row from "./components/row";
import { SearchAssignmentListAction } from "./components/row-actions";
import { SearchAssignmentsListContainerProps } from "./list.container";
import { RouteUtil } from "@haywork/util";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type SearchAssignmentsListComponentProps = {};
type Props = SearchAssignmentsListComponentProps &
  SearchAssignmentsListContainerProps;

export const SearchAssignmentsListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      navigate,
      totalCount,
      getListItems,
      filters,
      activeFilterGuidValues,
      setFilters,
      setOrdering,
      order,
      scrollOffset,
      setScrollOffset,
      searchAssignments,
      create,
      updateListItem,
      archive,
      unArchive,
      remove
    }) => {
      const listRef = useRef<ListRefProps>();

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: "linkedRelations",
              width: 250,
              autoFill: true
            },
            {
              label: {
                value: "saleOrRent"
              },
              width: 150
            },
            {
              label: {
                value: "type"
              },
              width: 200
            },
            {
              label: {
                value: "price"
              },
              width: 250
            },
            {
              label: {
                value: "isPaid"
              },
              width: 80
            },
            {
              label: {
                value: "createdOn"
              },
              width: 150
            },
            {
              label: {
                value: "matchmail"
              },
              width: 150
            }
          ],
          withActions: true
        } as ListColumnConfig;
      }, []);

      const onNavigate = useCallback((path: string) => {
        navigate(path);
      }, []);

      const onSortChange = useCallback(
        (sortColumn: SearchAssignmentOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const onAction = useCallback(
        (
          item: SearchAssignmentSnapShot,
          action: SearchAssignmentListAction
        ) => {
          const { id } = item;

          switch (action) {
            case SearchAssignmentListAction.Edit: {
              navigate(route(SEARCHASSIGNMENTROUTES.EDIT.URI, { id }));
              break;
            }
            case SearchAssignmentListAction.Archive: {
              updateListItem({ ...item, isActive: false });
              archive(id);
              break;
            }
            case SearchAssignmentListAction.UnArchive: {
              updateListItem({ ...item, isActive: true });
              unArchive(id);
              break;
            }
            case SearchAssignmentListAction.Delete: {
              remove(id);
              break;
            }
            default: {
              break;
            }
          }
        },
        [navigate, unArchive, archive, updateListItem, remove]
      );

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="searchAssignments"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
          >
            <List
              name="searchAssignments"
              rowHeight={100}
              columnConfig={columnConfig}
              data={searchAssignments}
              totalCount={totalCount}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onAction={onAction}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              ref={listRef}
              emptyState={
                <EmptyState
                  icon="user"
                  title="searchAssignments.list.emptyStateTitle"
                  subTitle="searchAssignments.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="searchAssignments.list.emptyState.action"
                      category="primary"
                      onClick={() => create()}
                    />
                  }
                />
              }
            >
              {(data: SearchAssignmentSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigate={onNavigate}
                />
              )}
            </List>
          </ListWrapper>
        </div>
      );
    }
  )
);
