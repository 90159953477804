import {
  PublicationSnapShot,
  PublicationStatus,
  AssignmentPhase,
  AvailabilityStatus
} from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  SwitchLabelPosition
} from "@haywork/modules/form";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { MarketingPartnersListItemComponent } from "./list-item.component";
import I18n from "@haywork/components/i18n";

const styles = require("./marketing-partners.component.scss");

interface Props {
  mediaPartnerState: string;
  mediaPartnerChangedId: string;
  publications: PublicationSnapShot[];
  assignmentPhase: AssignmentPhase;
  availabilityStatus: AvailabilityStatus;
  activatePublication: (mediaPartnerId: string) => void;
  deActivatePublication: (mediaPartnerId: string) => void;
}
interface State {
  pendingPublications: PublicationSnapShot[];
  publications: PublicationSnapShot[];
  activeMediaPartners: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class MarketingPartnersComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      pendingPublications: this.filterPendingPublications(),
      publications: this.filterPublications(),
      activeMediaPartners: false
    };

    this.onActiveMediaPartnersHandler = this.onActiveMediaPartnersHandler.bind(
      this
    );
    this.activateMediaPartner = this.activateMediaPartner.bind(this);
    this.deActivateMediaPartner = this.deActivateMediaPartner.bind(this);
    this.filterPendingPublications = this.filterPendingPublications.bind(this);
    this.filterPublications = this.filterPublications.bind(this);
  }

  public render() {
    const formControls: FormControls = {
      activeMediaPartners: { value: this.state.activeMediaPartners }
    };
    const { assignmentPhase, availabilityStatus } = this.props;

    return (
      <div styleName="partners" data-cy={this.props["data-cy"]}>
        <div styleName="partners__header">
          <h2>
            <I18n value="publicationsBeingProcessed" />
          </h2>
        </div>
        <div styleName="list">
          {this.state.pendingPublications.map((publication, idx) => (
            <ErrorBoundary key={idx}>
              <MarketingPartnersListItemComponent
                publication={publication}
                mediaPartnerState={this.props.mediaPartnerState}
                mediaPartnerChangedId={this.props.mediaPartnerChangedId}
                assignmentPhase={assignmentPhase}
                availabilityStatus={availabilityStatus}
                onDeActivateMediaPartner={this.deActivateMediaPartner}
                onActivateMediaPartner={this.activateMediaPartner}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.MarketingPartners`
                }
              />
            </ErrorBoundary>
          ))}
        </div>
        <div styleName="partners__header">
          <h2>
            <I18n value="selectMediaPartners" />
          </h2>
          <Form
            name="toggle-media-partners"
            formControls={formControls}
            onChange={this.onActiveMediaPartnersHandler}
          >
            <Input.Switch
              name="activeMediaPartners"
              label="showOnlyActiveMediaPartners"
              labelPosition={SwitchLabelPosition.Pre}
              on={true}
              off={false}
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.MediaPartnersInput`
              }
            />
          </Form>
        </div>
        <div styleName="list">
          {this.state.publications.map((publication, idx) => (
            <ErrorBoundary key={idx}>
              <MarketingPartnersListItemComponent
                publication={publication}
                mediaPartnerState={this.props.mediaPartnerState}
                mediaPartnerChangedId={this.props.mediaPartnerChangedId}
                assignmentPhase={assignmentPhase}
                availabilityStatus={availabilityStatus}
                onDeActivateMediaPartner={this.deActivateMediaPartner}
                onActivateMediaPartner={this.activateMediaPartner}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.MarketingPartners`
                }
              />
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps && nextProps.publications) {
      const pendingPublications = this.filterPendingPublications(
        nextProps.publications
      );
      const publications = this.filterPublications(
        this.state.activeMediaPartners,
        nextProps.publications
      );

      this.setState({ pendingPublications, publications });
    }
  }

  private onActiveMediaPartnersHandler(values: FormReturnValue) {
    const { activeMediaPartners } = values;

    const publications = this.filterPublications(activeMediaPartners);

    this.setState({ activeMediaPartners, publications });
  }

  private filterPendingPublications(
    publications?: PublicationSnapShot[]
  ): PublicationSnapShot[] {
    const publicationsToMap = publications || this.props.publications;
    return publicationsToMap.filter(
      (publication) =>
        publication.publicationStatus !== PublicationStatus.Inactive
    );
  }

  private filterPublications(
    onlyActive: boolean = false,
    publications?: PublicationSnapShot[]
  ): PublicationSnapShot[] {
    const publicationsToMap = publications || this.props.publications;
    return publicationsToMap.filter((publication) => {
      return onlyActive
        ? publication.mediaPartnerIsActive &&
            publication.publicationStatus === PublicationStatus.Inactive
        : publication.publicationStatus === PublicationStatus.Inactive;
    });
  }

  private activateMediaPartner(mediaPartnerId: string) {
    this.props.activatePublication(mediaPartnerId);
  }

  private deActivateMediaPartner(mediaPartnerId: string) {
    this.props.deActivatePublication(mediaPartnerId);
  }
}
