import {
  MatchedSearchAssignmentSnapshot,
  LinkedRelation,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import PageHeader from "@haywork/components/ui/page-header";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import Row from "./components/row";
import { SearchAssignmentsContainerProps } from "./search-assignments.container";
import { Ui } from "@haywork/modules/ui";
import Actions, { SearchAssignmentBulkAction } from "./components/actions";
import uniqBy from "lodash-es/uniqBy";
import { EmailAddress } from "@haywork/api/mail";
import { useIntl } from "react-intl";
import { MlsUtil } from "@haywork/util";
import { EmailUtil } from "@haywork/util/email-v2";

const styles = require("./style.scss");

export type SearchAssignmentComponentProps = {};
type Props = SearchAssignmentComponentProps & SearchAssignmentsContainerProps;

export const SearchAssignmentComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      findSearchAssignmentByObjectAssignment,
      objectAssignment,
      navigate,
      canSendEmail,
      exportSearchAssignmentRelations,
      createNewEmail,
      getRealEstatePropertySearchResponse,
      realestateProperty,
    }) => {
      const intl = useIntl();
      const [bulkSelected, setBulkSelected] = useState(false);
      const [searchAssignments, setSearchAssignments] = useState<
        MatchedSearchAssignmentSnapshot[]
      >([]);
      const [selectedSearchAssignments, setSelectedSearchAssignments] =
        useState([]);
      const [loading, setLoading] = useState(false);

      const count = useMemo(
        () => searchAssignments.length,
        [searchAssignments]
      );

      const toggleBulkSelected = useCallback(() => {
        setBulkSelected((bulkSelected) => {
          setSelectedSearchAssignments(!bulkSelected ? searchAssignments : []);
          return !bulkSelected;
        });
      }, [setBulkSelected, setSelectedSearchAssignments, searchAssignments]);

      const fetch = useCallback(async () => {
        try {
          setLoading(true);
          const searchAssignments =
            await findSearchAssignmentByObjectAssignment(objectAssignment);
          setSearchAssignments(searchAssignments);
        } finally {
          setLoading(false);
        }
      }, [
        findSearchAssignmentByObjectAssignment,
        objectAssignment,
        setSearchAssignments,
        setLoading,
      ]);

      const onSelectCallback = useCallback(
        (searchAssignment: MatchedSearchAssignmentSnapshot) => {
          setSelectedSearchAssignments((searchAssignments) => {
            if (count === searchAssignments.length + 1) {
              setBulkSelected(true);
            }
            return [...searchAssignments, searchAssignment];
          });
        },
        [setSelectedSearchAssignments, setBulkSelected, count]
      );

      const onDeselectCallback = useCallback(
        (searchAssignment: MatchedSearchAssignmentSnapshot) => {
          setSelectedSearchAssignments((searchAssignments) =>
            searchAssignments.filter(
              (assignment) => assignment.id !== searchAssignment.id
            )
          );
          setBulkSelected(false);
        },
        [setSelectedSearchAssignments, setBulkSelected]
      );

      const onActionCallback = useCallback(
        async (action: SearchAssignmentBulkAction) => {
          switch (action) {
            case SearchAssignmentBulkAction.CreateEmail: {
              const { bundleId, appClientKey } = realestateProperty;
              const snapshot = await getRealEstatePropertySearchResponse(
                bundleId,
                appClientKey
              );
              if (!snapshot) return;

              let relationsWithEmail: LinkedRelation[] =
                selectedSearchAssignments.reduce((state, searchAssignment) => {
                  (searchAssignment.linkedRelations || [])
                    .filter((relation) => !!relation.email)
                    .forEach((relation) => state.push(relation));
                  return state;
                }, []);

              relationsWithEmail = uniqBy(
                relationsWithEmail,
                (relation) => relation.id
              );

              if (!relationsWithEmail.length) return;
              const emailAddresses = relationsWithEmail.map(
                (relation) =>
                  ({
                    email: relation.email,
                    name: relation.displayName,
                  } as EmailAddress)
              );

              const subject = intl.formatMessage(
                { id: "searchAssignment.email.subject", defaultMessage: "" },
                { address: objectAssignment.displayName }
              );

              const assignment = MlsUtil.mapPropertySearchItemToEmailAssignment(
                snapshot,
                intl
              );
              const body = EmailUtil.createAssignmentsEmailTemplate([
                assignment,
              ]);

              createNewEmail(
                emailAddresses,
                relationsWithEmail,
                subject,
                body,
                true
              );
              setSelectedSearchAssignments([]);
              setBulkSelected(false);
              break;
            }
            case SearchAssignmentBulkAction.Export: {
              const relationIds = selectedSearchAssignments.reduce(
                (state, searchAssignment) => {
                  (searchAssignment.linkedRelations || []).forEach((relation) =>
                    state.push(relation.id)
                  );
                  return state;
                },
                [] as string[]
              );

              if (!!relationIds.length) {
                exportSearchAssignmentRelations(relationIds);
              }
              setSelectedSearchAssignments([]);
              setBulkSelected(false);
              break;
            }
            case SearchAssignmentBulkAction.Deselect: {
              setSelectedSearchAssignments([]);
              setBulkSelected(false);
              break;
            }
            default: {
              break;
            }
          }
        },
        [
          selectedSearchAssignments,
          setSelectedSearchAssignments,
          exportSearchAssignmentRelations,
          createNewEmail,
          intl,
          objectAssignment,
          realestateProperty,
          setBulkSelected,
        ]
      );

      useEffect(() => {
        fetch();
      }, [fetch]);

      return (
        <div styleName="search-assignments">
          <PageHeader
            title="mls.pageHeader.searchAssignments"
            subTitle="mls.pageHeader.general"
            actions={
              <>
                {!!selectedSearchAssignments.length && (
                  <Actions
                    canEmail={canSendEmail}
                    selectedSearchAssignments={selectedSearchAssignments}
                    onClick={onActionCallback}
                  />
                )}
              </>
            }
          />

          <div styleName="searchers__header">
            <div styleName="column checkbox">
              <Input.CheckBox
                name="bulk-select-search-assignments"
                asSingleInput
                value={bulkSelected}
                onChange={toggleBulkSelected}
              />
            </div>
            <div styleName="column search">
              <I18n value="searchers" />
            </div>
            <div styleName="column is-paid">
              <I18n value="searchAssignment.overviewHeader.isPaid" />
            </div>
            <div styleName="column offertype">
              <I18n value="rentSale" />
            </div>
            <div styleName="column date">
              <I18n value="createdDate" />
            </div>
            <div styleName="column frequency">
              <I18n value="matchmail" />
            </div>
          </div>

          <div styleName="searchers__list">
            {!!loading && <Ui.Loaders.List />}

            {!loading &&
              searchAssignments.map((searchAssignment, idx) => (
                <Row
                  searchAssignment={searchAssignment}
                  zebra={idx % 2 === 0}
                  onNavigateToSearchAssignment={navigate}
                  selectedSearchAssignments={selectedSearchAssignments}
                  onSelect={onSelectCallback}
                  onDeselect={onDeselectCallback}
                  key={searchAssignment.id}
                />
              ))}

            {!loading && searchAssignments.length === 0 && (
              <div styleName="empty-state">
                <div styleName="content">
                  <div styleName="icon" />
                  <div styleName="text">
                    <h2>
                      <I18n value="assignmentSearchAssignmentsEmptyStateTitleAlt" />
                    </h2>
                    <p>
                      <I18n value="assignmentSearchAssignmentsEmptyStateBody" />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
