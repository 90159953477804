export type MlsAccessData = {
  demoUntilDateTime?: Date;
  dateTimeModified?: Date;
  isActive: boolean;
  dateTimeActivated?: Date;
  activatedByEmployeeId?: string;
};

export enum ActionType {
  SetAccessData = "MLS.Main.SetAccessData",
  SetRequestStatus = "MLS.Main.SetRequestStatus",
  ResetApp = "RESET_APP",
}

type SetAccessData = {
  type: ActionType.SetAccessData;
  mlsAccessData: MlsAccessData;
};

const setAccessData = (mlsAccessData: MlsAccessData) => ({
  type: ActionType.SetAccessData,
  mlsAccessData,
});

type SetRequestStatus = {
  type: ActionType.SetRequestStatus;
  requestStatus: string;
};

const setRequestStatus = (requestStatus: string) => ({
  type: ActionType.SetRequestStatus,
  requestStatus,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes = SetAccessData | SetRequestStatus | ResetApp;
export const actions = {
  setAccessData,
  setRequestStatus,
};
