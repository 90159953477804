import { RealEstateGroup, SortOrder } from "@haywork/api/kolibri";
import {
  Group,
  MutationType,
  PropertyType,
  RealEstatePropertySearchItem,
} from "@haywork/api/mls";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterType } from "@haywork/enum/list-filter-types";
import { MLSOrder } from ".";
import { ActionType, ActionTypes } from "./actions";
import * as moment from "moment";

const params = new URLSearchParams(window.location.search);
const groupsValue =
  params.has("group") && params.get("group") === "VVA" ? ["VVA"] : [];

export type State = {
  assignments: RealEstatePropertySearchItem[];
  totalCount: number;
  order: MLSOrder;
  filters: FilterConfig;
  scrollOffset: number;
  selectedIds: string[];
  agencyGroups: Group[];
  appClients: string[];
};

export enum ParkingPlaceCount {
  LessThanFive = "LessThanFive",
  MoreThanFive = "MoreThanFive",
  MoreThanTen = "MoreThanTen",
  MoreThanTwenty = "MoreThanTwenty",
  MoreThanFifty = "MoreThanFifty",
}

const INITIAL: State = {
  assignments: [],
  totalCount: 0,
  order: {
    sortOrder: SortOrder.Descending,
    sortColumn: "",
  },
  agencyGroups: [],
  appClients: [],
  filters: {
    realEstateGroups: {
      type: FilterType.Single,
      value: RealEstateGroup.Residential,
      emptyValue: null,
      prefix: "realEstateGroups",
    },
    forSaleAndOrRent: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "forSaleAndOrRent",
    },
    following: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "following",
    },
    subFiltering: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "realEstateGroups",
      subFilter: {
        [RealEstateGroup.Residential.toString()]: "subFilter",
      },
    },
    assignmentTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "assignmentTypes",
    },
    locations: {
      type: FilterType.LocationRange,
      value: [],
      emptyValue: [],
    },
    searchLocations: {
      type: FilterType.SearchLocation,
      value: [],
      emptyValue: [],
      prefix: "searchLocations",
    },
    mutationTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "mutationTypeOptions",
    },
    mutationsDates: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "mutationsDates",
    },
    priceRangeValues: {
      type: FilterType.MlsPriceRange,
      value: { type: undefined, min: undefined, max: undefined },
      emptyValue: { type: undefined, min: undefined, max: undefined },
      prefix: "priceRange",
    },
    periodType: {
      type: FilterType.Select,
      value: null,
      emptyValue: null,
    },
    availabilityStatuses: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "statusTypeOptions",
    },
    propertyTypeOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
      subFilter: {
        [PropertyType.HOUSE.toString()]: "listingTypes",
        [PropertyType.APARTMENT.toString()]: "apartmentTypes",
        [PropertyType.GARAGE.toString()]: "garageTypes",
        [PropertyType.RESIDENTIAL_OTHERS.toString()]: "otherTypes",
        [PropertyType.PARKING.toString()]: "parkingTypeOptions",
        [PropertyType.HORSES_COMPANY.toString()]: "horseCompanySubtypes",
        [PropertyType.HORTICULTURAL_COMPANY.toString()]:
          "horticulturalCompanyTypes",
        [PropertyType.CATTLE_HUSBANDRY.toString()]: "cattleFarmingSubtypes",
        [PropertyType.PIG_HOLDING.toString()]: "pigCompanyTypes",
        [PropertyType.HOTEL_RESTAURANT_CAFE.toString()]:
          "beverageHospitalitySectorTypes",
      },
    },
    houseTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    houseSorts: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    houseCharacteristics: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    apartmentSorts: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    apartmentCharacteristics: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    garageTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    otherTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    horseCompanySubtypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    parkingTypeOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    pigCompanyTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    cattleFarmingSubtypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    horticulturalCompanyTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    beverageHospitalitySectorTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    locationPlaces: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "locationPlaceTypeOptions",
    },
    livingAreaValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "livingArea",
    },
    noOfRoomsValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "noOfRooms",
    },
    noOfBedroomsValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "noOfBedrooms",
    },
    contentValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "content",
    },
    floorsValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "noOfFloors",
    },
    outside: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      subFilter: {
        ["hasGarden"]: "hasGardenSubFilter",
      },
    },
    orientationTypeValues: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "orientationTypeOptions",
    },
    gardenPlotSizeValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "gardenPlotSize",
    },
    garageTypeValues: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "garageTypeOptions",
    },
    garageTypeOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "garageTypeOptions",
    },
    garageCapacityValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "garageCapacity",
    },
    plotSizeValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "plotSize",
    },
    yearOfConstructionValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "yearOfConstruction",
    },
    energyClasses: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "energyClassTypeOptions",
    },
    conditionOptionsInside: {
      type: FilterType.Single,
      value: undefined,
      emptyValue: undefined,
      prefix: "conditionTypeOptions",
    },
    conditionOptionsOutside: {
      type: FilterType.Single,
      value: undefined,
      emptyValue: undefined,
      prefix: "conditionTypeOptions",
    },
    facilitySearchTypeOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "facilitySearchTypeOptions",
    },
    otherSearchTypeOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "otherSearchTypeOptions",
    },
    realtors: {
      type: FilterType.Realtor,
      value: [],
      emptyValue: [],
    },
    groups: {
      type: FilterType.Array,
      value: groupsValue,
      emptyValue: [],
      prefix: "groups",
    },
    appClients: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "appClients",
    },
    parkingPlaceCounts: {
      type: FilterType.Single,
      value: undefined,
      emptyValue: undefined,
      prefix: "parkingPlaceCount",
    },
    floorAreaValues: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
      prefix: "floorArea",
    },
    groundType: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "propertyTypeOptions",
    },
    postalCodeRanges: {
      type: FilterType.PostalCodeRange,
      value: [],
      emptyValue: [],
      prefix: "postalCode",
    },
    relativeDate: {
      type: FilterType.Select,
      value: null,
      emptyValue: null,
    },
  },
  scrollOffset: 0,
  selectedIds: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, startIndex } = action;

      let assignments = [];

      if (startIndex > 0) {
        assignments = [...state.assignments];
      }

      if (!assignments.length) {
        assignments = new Array(totalCount).fill(null);
      }

      for (let i = 0; i < items.length; i++) {
        assignments[i + startIndex] = items[i];
      }

      return {
        ...state,
        assignments,
        totalCount,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        assignments: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      let { filters } = action;

      // Set default realEstateGroup
      if (!filters?.realEstateGroups?.value) {
        filters = {
          ...filters,
          realEstateGroups: {
            ...state.filters.realEstateGroups,
            value: RealEstateGroup.Residential,
          },
        };
      }

      // Clear dates when no mutationTypes are set
      if (!filters?.mutationTypes?.value?.length) {
        filters = {
          ...filters,
          mutationsDates: {
            ...state.filters.mutationsDates,
            value: state.filters.mutationsDates.emptyValue,
          },
          relativeDate: {
            ...state.filters.relativeDate,
            value: state.filters.relativeDate.emptyValue,
          },
        };
      }

      // Set default mutationsDates.min when mutationTypes are set but no mutationsDates provided
      if (
        !!filters?.mutationTypes?.value?.length &&
        !filters?.mutationsDates?.value?.min &&
        !filters?.relativeDate?.value
      ) {
        const now = moment();
        let min: string;

        if (
          filters?.mutationTypes?.value?.length === 1 &&
          filters?.mutationTypes?.value?.[0] === MutationType.New
        ) {
          min = now.clone().subtract(1, "day").startOf("day").toJSON();
        } else {
          min = now.clone().subtract(1, "week").startOf("day").toJSON();
        }

        filters = {
          ...filters,
          mutationsDates: {
            ...filters.mutationsDates,
            value: {
              min,
              max: undefined,
            },
          },
        };
      }

      // Reset mutationDates when relativeDate is initially set
      if (!state.filters.relativeDate.value && !!filters.relativeDate.value) {
        filters = {
          ...filters,
          mutationsDates: {
            ...filters.mutationsDates,
            value: filters.mutationsDates.emptyValue,
          },
        };
      }

      // Reset mutationDates when mutationsDates are initially set
      if (
        (!state.filters.mutationsDates.value.min &&
          !!filters.mutationsDates.value.min) ||
        (!state.filters.mutationsDates.value.max &&
          !!filters.mutationsDates.value.max)
      ) {
        filters = {
          ...filters,
          relativeDate: {
            ...filters.relativeDate,
            value: filters.relativeDate.emptyValue,
          },
        };
      }

      return {
        ...state,
        assignments: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.SetSelectedIds: {
      const { selectedIds } = action;

      return {
        ...state,
        selectedIds,
      };
    }
    case ActionType.SetRealEstateAgencyGroups: {
      const { agencyGroups } = action;

      return {
        ...state,
        agencyGroups,
      };
    }
    case ActionType.SetRealEstateAgencyAppClients: {
      const { appClients } = action;

      return {
        ...state,
        appClients,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;
      const assignments = state.assignments.map((assignment) => {
        if (assignment.bundleId === snapshot.bundleId) {
          return snapshot;
        }
        return assignment;
      });

      return {
        ...state,
        assignments,
      };
    }
    case ActionType.SetDefaultSearchLocations: {
      const { locations } = action;
      const value = locations.map((location) => ({
        label: location?.name,
        min: location?.postalCodeMin,
        max: location?.postalCodeMax,
      }));

      return {
        ...state,
        filters: {
          ...state.filters,
          searchLocations: {
            ...state.filters.searchLocations,
            value,
          },
          forSaleAndOrRent: {
            ...state.filters.forSaleAndOrRent,
            value: [],
          },
        },
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
