import { WidgetEntityType } from "@haywork/api/authorization";
import {
  AssignmentType,
  LinkedRelation,
  ObjectAssignment,
  ObjectAssignmentLinkRelationRequest,
  RelationRole,
  RelationSnapShot,
  RelationType,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { AssignmentDetailNetworkContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes from "@haywork/modules/notes-v3";
import { PageLoader } from "@haywork/modules/shared";
import { AssignmentUtil, RegexUtil, RelationUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentDetailNetworkItemComponent } from "./list-item.component";
import { LinkedRelationWithRole } from "@haywork/util/assignment";
import Actions, { NetworkBulkAction } from "./actions";
import uniqBy from "lodash-es/uniqBy";
import { EmailAddress } from "@haywork/api/mail";
import * as deepEqual from "deep-equal";

const styles = require("./detail-network.component.scss");
const selectedRelationTypes = [
  RelationRole.Applicant,
  RelationRole.Appraiser,
  RelationRole.Client,
  RelationRole.FinancialAdvisor,
  RelationRole.Inspector,
  RelationRole.Notary,
  RelationRole.Occupant,
  RelationRole.OfferingAgency,
  RelationRole.SomeoneWhoOpted,
  RelationRole.Photographer,
  RelationRole.Potential,
  RelationRole.ProjectDeveloper,
  RelationRole.PropertyManager,
  RelationRole.PurchasingBroker,
  RelationRole.RentalAgent,
  RelationRole.SalesBroker,
  RelationRole.ContactPerson,
  RelationRole.Stylist,
  RelationRole.TenantRepresentationBroker,
  RelationRole.Vendor,
];

export interface ExtendedLinkedRelation extends LinkedRelation {
  relationRole: RelationRole;
}

export type AssignmentDetailNetworkComponentProps = {};
type State = {
  addingContact: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  relationRole: RelationRole;
  contactLinks: LinkedRelationWithRole[];
  selectedContactLinks: LinkedRelationWithRole[];
};
type Props = AssignmentDetailNetworkComponentProps &
  AssignmentDetailNetworkContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailNetworkComponent extends React.Component<
  Props,
  State
> {
  private inputRef: HTMLInputElement;
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      addingContact: false,
      newEntityVisible: false,
      newEntityOptions: null,
      relationRole: null,
      contactLinks: AssignmentUtil.getAssignmentAroRelations(
        this.props.objectAssignment
      ),
      selectedContactLinks: [],
    };

    this.formControls = {
      linkedContact: { value: "", validators: [Validators.required()] },
      relationRole: { value: RelationRole.Applicant },
    };

    this.toggleAddingContact = this.toggleAddingContact.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.contactLinkTypeChangeHandler = this.contactLinkTypeChangeHandler.bind(
      this
    );
    this.contactLinkRemoveFromListHandler = this.contactLinkRemoveFromListHandler.bind(
      this
    );
    this.renderAddContact = this.renderAddContact.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onBulkChangeHandler = this.onBulkChangeHandler.bind(this);
    this.addSelectedId = this.addSelectedId.bind(this);
    this.removeSelectedId = this.removeSelectedId.bind(this);
    this.onActionClickHandler = this.onActionClickHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.objectAssignment, this.props.objectAssignment)) {
      this.setState({
        contactLinks: AssignmentUtil.getAssignmentAroRelations(
          this.props.objectAssignment
        ),
      });
    }
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      get(this.props.objectAssignment, "id"),
      WidgetEntityType.ObjectAssignment
    );
  }

  public render() {
    const { objectAssignment, relationRoles } = this.props;
    const { displayName, forSale } = objectAssignment;
    const relationTypes = selectedRelationTypes.map((value) => {
      const translation = relationRoles.find(
        (relationRole) => relationRole.value === value
      );
      const label = !translation
        ? value.toString()
        : !forSale
        ? translation.displayNameForRent ||
          translation.displayNameForRent ||
          value.toString()
        : translation.displayNameForSale ||
          translation.displayName ||
          value.toString();

      return { label, value };
    });
    const bulkSelected =
      !!this.state.contactLinks.length &&
      this.state.contactLinks.length === this.state.selectedContactLinks.length;
    const showBulkActions = !!this.state.selectedContactLinks.length;

    return (
      <div styleName="body">
        <PageHeader
          title="pageTitle.assignment.network"
          subTitle={displayName}
          actions={
            <>
              <Notes />
              <Button
                label="addContact"
                category="success"
                icon={<Icon name="plus" light size={18} />}
                iconPosition="start"
                onClick={this.toggleAddingContact}
              />
              <Actions
                visible={showBulkActions}
                canEmail={this.props.canEmail}
                selectedContactLinks={this.state.selectedContactLinks}
                onClick={this.onActionClickHandler}
              />
            </>
          }
        />

        <FeatureSwitch feature="FILTER_NETWORK">
          <div styleName="network__filter">
            <I18n value="network.filter.prefix" />
            <div styleName="filter__input">
              <Input.NewSelect
                name="selectedRelationTypes"
                values={relationTypes}
                valuesProp="value"
                displayProp="label"
                addEmptyOption
                emptyOptionLabel="selectedRelationType.all"
                value={this.state.relationRole}
                onChange={this.onChangeFilter}
              />
            </div>
          </div>
        </FeatureSwitch>

        <div styleName="summary">
          <FeatureSwitch feature="FILTER_NETWORK">
            <div styleName="column checkbox">
              <Input.CheckBox
                name="bulk-select-search-assignments"
                asSingleInput
                value={bulkSelected}
                onChange={this.onBulkChangeHandler}
              />
            </div>
          </FeatureSwitch>
          <div styleName="column title">
            <I18n value="aroNetworkHeader" />
          </div>
        </div>

        {this.props.saveContactState === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}

        {this.props.saveContactState === REQUEST.ERROR &&
          this.renderContactAddError()}

        {this.state.addingContact && (
          <div styleName="add-contact">
            <div styleName="add-contact__avatar">
              <div styleName="avatar" />
            </div>
            {this.renderAddContact()}
          </div>
        )}

        {this.state.contactLinks.length > 0 && (
          <div styleName="list">
            {this.state.contactLinks.map((contactLink, idx) => (
              <ErrorBoundary key={`${contactLink.id}.${idx}`}>
                <AssignmentDetailNetworkItemComponent
                  contact={contactLink}
                  zebra={idx % 2 === 0}
                  contactLinkRemoveFromList={
                    this.contactLinkRemoveFromListHandler
                  }
                  contactLinkTypeChange={this.contactLinkTypeChangeHandler}
                  relationRoles={this.props.relationRoles}
                  navigate={this.props.navigate}
                  forSale={forSale}
                  assignmentId={this.props.objectAssignment.id}
                  onSelect={this.addSelectedId}
                  onDeselect={this.removeSelectedId}
                  selectedContactLinks={this.state.selectedContactLinks}
                />
              </ErrorBoundary>
            ))}
          </div>
        )}
        {this.state.contactLinks.length === 0 && (
          <div styleName="emptyState">
            <div styleName="content">
              <div styleName="icon" />
              <div styleName="text">
                <h2>
                  <I18n value="emptyStateNoNetworkTitle" />
                </h2>
                <p>
                  <I18n value="emptyStateNoNetworkText" />
                </p>
              </div>
            </div>
          </div>
        )}

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  private contactLinkRemoveFromListHandler(contact: ExtendedLinkedRelation) {
    if (
      !get(contact, "id") ||
      !get(contact, "typeOfRelation") ||
      !get(contact, "relationRole")
    )
      return;

    const relation: ObjectAssignmentLinkRelationRequest = {
      relationId: get(contact, "id"),
      relationType: get(contact, "typeOfRelation"),
      objectAssignmentId: this.props.objectAssignment.id,
      relationRole: get(contact, "relationRole"),
    };

    const updatedObjectAssignment = AssignmentUtil.removeLinkedRelation(
      this.props.objectAssignment,
      contact
    ) as ObjectAssignment;
    this.props.unlinkRelation(relation, updatedObjectAssignment);
    this.setState({ addingContact: false });
  }

  private contactLinkTypeChangeHandler(
    type: RelationRole,
    contact: ExtendedLinkedRelation
  ) {
    const updatedObjectAssignment = AssignmentUtil.updateLinkedRelation(
      this.props.objectAssignment,
      type,
      contact
    ) as ObjectAssignment;
    const newState = {
      ...this.props.currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
    this.props.saveAssignment(updatedObjectAssignment);
  }

  private toggleAddingContact() {
    this.setState({ addingContact: !this.state.addingContact }, () => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    });
  }

  private renderAddContact(): React.ReactElement<HTMLDivElement> {
    const relationRoles = RelationUtil.mapRelationRoles(
      this.props.relationRoles,
      AssignmentType.Object,
      this.props.objectAssignment.forSale
    );

    return (
      <div styleName="add-contact__body">
        <Form
          formControls={this.formControls}
          name="contactLink"
          onSubmit={this.onSubmitHandler}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.RelationQuery
                  name="linkedContact"
                  filterByRelationTypes={[
                    RelationType.ContactPerson,
                    RelationType.ContactCompany,
                  ]}
                  onAdd={this.addNewRelation}
                />
              </div>
              <div styleName="function">
                <Input.NewSelect
                  name="relationRole"
                  values={relationRoles}
                  displayProp="label"
                  valuesProp="value"
                />
              </div>
            </div>
            <div styleName="action">
              <button type="submit" className="btn btn-success">
                <I18n value="add" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    if (
      !get(values.linkedContact, "id") ||
      !get(values.linkedContact, "typeOfRelation") ||
      !get(values, "relationRole")
    )
      return;

    const relation: ObjectAssignmentLinkRelationRequest = {
      relationId: get(values.linkedContact, "id"),
      relationType: get(values.linkedContact, "typeOfRelation"),
      objectAssignmentId: this.props.objectAssignment.id,
      relationRole: get(values, "relationRole"),
    };

    const updatedObjectAssignment = AssignmentUtil.addLinkedRelation(
      this.props.objectAssignment,
      values.relationRole,
      values.linkedContact
    ) as ObjectAssignment;

    this.props.linkRelation(relation, updatedObjectAssignment);
    const contactLinks = AssignmentUtil.getAssignmentAroRelations(
      updatedObjectAssignment
    );

    this.setState({ addingContact: false, contactLinks });
  }

  private renderContactAddError() {
    return (
      <div styleName="error" className="alert alert-danger">
        <I18n value="contactAlreadyExistsError" />
      </div>
    );
  }

  private addNewRelation(name: string) {
    let typeOfInfo: string;
    if (RegexUtil.isNumber(name)) typeOfInfo = "telephone";
    if (RegexUtil.isValidEmail(name)) typeOfInfo = "email";
    if (!RegexUtil.isNumber(name) && !RegexUtil.isValidEmail(name))
      typeOfInfo = "name";

    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          [`${typeOfInfo}`]: name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedContact: RelationSnapShot) {
    this.formRef.update({
      linkedContact,
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private addSelectedId(contact: LinkedRelationWithRole) {
    const selectedContactLinks = [...this.state.selectedContactLinks, contact];
    this.setState({ selectedContactLinks });
  }

  private removeSelectedId(contact: LinkedRelationWithRole) {
    const selectedContactLinks = this.state.selectedContactLinks.filter(
      (selectedSearchAssignment) => selectedSearchAssignment.id !== contact.id
    );
    this.setState({ selectedContactLinks });
  }

  private onChangeFilter(relationRole: RelationRole) {
    let contactLinks = AssignmentUtil.getAssignmentAroRelations(
      this.props.objectAssignment
    );
    contactLinks = contactLinks.filter(
      (contactLink) =>
        !relationRole || contactLink.relationRole === relationRole
    );

    this.setState({ relationRole, contactLinks });
  }

  private onBulkChangeHandler(checked: boolean) {
    if (checked) {
      this.setState({
        selectedContactLinks: this.state.contactLinks,
      });
    } else {
      this.setState({ selectedContactLinks: [] });
    }
  }

  private onActionClickHandler(action: NetworkBulkAction) {
    switch (action) {
      case NetworkBulkAction.CreateEmail: {
        const { selectedContactLinks } = this.state;
        let relationsWithEmail = selectedContactLinks.filter(
          (contactLink) => !!contactLink.email
        );

        relationsWithEmail = uniqBy(
          relationsWithEmail,
          (relation) => relation.id
        );

        if (!relationsWithEmail.length) return;
        const emailAddresses = relationsWithEmail.map(
          (relation) =>
            ({
              email: relation.email,
              name: relation.displayName,
            } as EmailAddress)
        );
        const linkedAssignment: LinkedAssignment = {
          id: this.props.objectAssignment.id,
          displayName: this.props.objectAssignment.displayName,
        };

        this.props.createNewEmail(
          emailAddresses,
          relationsWithEmail,
          linkedAssignment,
          "",
          true
        );
        this.setState({ selectedContactLinks: [] });
        return;
      }
      case NetworkBulkAction.Deselect: {
        this.setState({ selectedContactLinks: [] });
        return;
      }
      default: {
        return;
      }
    }
  }
}
