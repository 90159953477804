import {
  ContactPerson,
  RelationSnapShot,
  RelationType,
  PhoneNumberType,
} from "@haywork/api/kolibri";

export const mapContactPersonToRelationSnapShot = (
  relation: ContactPerson
): RelationSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    address,
    dateOfBirth,
    displayName,
    emailAddresses,
    nameLetters,
    passportPhotoBlob,
    phoneNumbers,
    futureAddress,
    linkedPartner,
    linkedRelationGroups,
  } = relation;

  const mobileNumber = (phoneNumbers || []).find(
    (phone) => phone.type === PhoneNumberType.Mobile
  )?.number;
  const phoneNumber =
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Work)
      ?.number ||
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Home)
      ?.number ||
    (phoneNumbers || []).find((phone) => phone.type === PhoneNumberType.Other)
      ?.number;

  const houseNumber = [address?.houseNumber, address?.houseNumberPostfix]
    .filter((d) => !!d)
    .join("-");
  const streetNameAndNumber = [address?.street?.name, houseNumber]
    .filter((d) => !!d)
    .join(" ");

  const futureHouseNumber = [
    futureAddress?.houseNumber,
    futureAddress?.houseNumberPostfix,
  ]
    .filter((d) => !!d)
    .join("-");
  const futureStreetNameAndNumber = [
    futureAddress?.street?.name,
    futureHouseNumber,
  ]
    .filter((d) => !!d)
    .join(" ");

  return {
    id,
    avatarUrl: passportPhotoBlob?.urlPreview,
    dateOfBirth,
    dateTimeCreated,
    dateTimeModified,
    displayName,
    email: (emailAddresses || [])[0]?.address,
    secondEmailAddress: (emailAddresses || [])[1]?.address,
    thirdEmailAddress: (emailAddresses || [])[2]?.address,
    isActive,
    letterAvatar: nameLetters,
    linkedCreatedBy,
    linkedModifiedBy,
    linkedRelationGroups,
    linkedPartner,
    locality: address?.locality?.name,
    mobileNumber,
    phoneNumber,
    streetNameAndNumber,
    postalCode: address?.postalCode,
    typeOfRelation: RelationType.ContactPerson,
    futureStreetNameAndNumber,
    futurePostalCode: futureAddress?.postalCode,
    futureLocality: futureAddress?.locality?.name,
  };
};
