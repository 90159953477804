import { UserDataElement } from "@haywork/api/mail";
import {
  PropertyType,
  PropertyTypeOption,
  OtherSearchType,
  ConditionType,
  FacilitySearchType,
  RangeUnit,
} from "@haywork/api/mls";
import { SingleFilterValue } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import {
  MLS,
  MLS_MAINTYPES_PART,
  MLS_HOUSE_TYPES,
  MLS_HOUSE_SORTS,
  MLS_HOUSE_CHARACTERISTICS,
  MLS_APARTMENT_SORTS,
  MLS_APARTMENT_CHARACTERISTICS,
  MLS_HORSE_COMPANY_TYPES,
  MLS_GARAGE_TYPES,
  MLS_CULTIVATION_TYPES,
  MLS_HORECA_TYPES,
  MLS_MAINTYPES_BOG,
  MLS_MAINTYPES_ALV,
  MLS_OTHER_TYPES_PART,
  MLS_PIG_COMPANY_TYPES,
  MLS_BEEF_TYPES,
  MLS_GROUNDTYPES,
} from "@haywork/constants";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import { AppState } from "@haywork/stores";
import { createSelector } from "reselect";
import isString from "lodash-es/isString";
import { RealEstateGroup } from "@haywork/api/kolibri";

const masterTableSelector = (state: AppState) => state.main.mastertable;
const accountSettingsSelector = (state: AppState) =>
  state.account.accountSettings;
const officesSelector = (state: AppState) => state.offices.offices;
const employeesSelector = (state: AppState) => state.employee.employees;
const filtersSelector = (state: AppState) => state.mls.list.filters;
const searchLocationsSelector = (state: AppState) => state.mls.searchLocations;
const agencyGroupsSelector = (state: AppState) => state.mls.list.agencyGroups;
const appClientSelector = (state: AppState) => state.mls.list.appClients;
const typeSelector = (state: AppState, type: string) => {
  return { mastertable: state.main.mastertable, type };
};
const selectedIdsSelector = (state: AppState) => state.mls.list.selectedIds;
const mlsAccessDataSelector = (state: AppState) => state.mls.main.mlsAccessData;

const mapObjectTypes = (
  allTypes: PropertyTypeOption[],
  toMatch: PropertyType[]
): SingleFilterValue[] => {
  return allTypes.reduce((results: SingleFilterValue[], item) => {
    if (toMatch.includes(item.value))
      results.push({
        label: item.displayName,
        value: item.value,
      });
    return results;
  }, []);
};

export const listingTypes = createSelector(
  typeSelector,
  ({ mastertable, type }) => {
    const { propertyTypeOptions } = mastertable.mls;

    switch (type) {
      case "listingTypesPart":
        return mapObjectTypes(propertyTypeOptions, MLS_MAINTYPES_PART);
      case "listingTypesBog":
        return mapObjectTypes(propertyTypeOptions, MLS_MAINTYPES_BOG);
      case "groundTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_GROUNDTYPES);
      case "listingTypesAlv":
        return mapObjectTypes(propertyTypeOptions, MLS_MAINTYPES_ALV);
      case "houseTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_HOUSE_TYPES);
      case "houseSorts":
        return mapObjectTypes(propertyTypeOptions, MLS_HOUSE_SORTS);
      case "houseCharacteristics":
        return mapObjectTypes(propertyTypeOptions, MLS_HOUSE_CHARACTERISTICS);
      case "apartmentSorts":
        return mapObjectTypes(propertyTypeOptions, MLS_APARTMENT_SORTS);
      case "apartmentCharacteristics":
        return mapObjectTypes(
          propertyTypeOptions,
          MLS_APARTMENT_CHARACTERISTICS
        );
      case "garageTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_GARAGE_TYPES);
      case "otherPartTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_OTHER_TYPES_PART);
      case "horseCompanyTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_HORSE_COMPANY_TYPES);
      case "parkingTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_GARAGE_TYPES);
      case "pigCompanyTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_PIG_COMPANY_TYPES);
      case "beefTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_BEEF_TYPES);
      case "horticulturalTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_CULTIVATION_TYPES);
      case "horecaTypes":
        return mapObjectTypes(propertyTypeOptions, MLS_HORECA_TYPES);

      default:
        break;
    }
  }
);

export const locationPlaces = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { locationPlaceTypeOptions } = mastertable.mls;
    return (locationPlaceTypeOptions || []).map(
      (type) =>
        ({
          label: type.displayName,
          value: type.value,
        } as SingleFilterValue)
    );
  }
);

export const savedFilters = createSelector(
  accountSettingsSelector,
  (accountSettings) => {
    const { customUserSettings } = accountSettings;

    const userData = !!customUserSettings
      ? (JSON.parse(customUserSettings) as UserDataElement[])
      : [];
    const itemsMatchingMlsKey = userData.filter(
      (data) =>
        data.key === MLS.FILTER_SETTINGS_KEY &&
        !!data.value &&
        isString(data.value)
    );

    return itemsMatchingMlsKey
      .map((item) => JSON.parse(item.value) as MlsFilter)
      .map((item) => {
        if (!!item?.filters?.locations?.length) {
          return {
            ...item,
            filters: {
              ...item.filters,
              locations: (item.filters.locations || []).map((location) => {
                if (!location.rangeUnit) {
                  return {
                    ...location,
                    rangeUnit: RangeUnit.Kilometer,
                  };
                }

                return location;
              }),
            },
          };
        }
        return item;
      })
      .filter((item) => !!item.filters);
  }
);

export const orientationTypeValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { orientationTypeOptions } = mastertable.mls;
    return (orientationTypeOptions || []).map(
      (type) =>
        ({
          label: type.displayName,
          value: type.value,
        } as SingleFilterValue)
    );
  }
);

export const garageTypeOptions = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { garageTypeOptions } = mastertable.mls;
    return (garageTypeOptions || []).map(
      (type) =>
        ({
          label: type.displayName,
          value: type.value,
        } as SingleFilterValue)
    );
  }
);

export const assignmentPhaseValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { assignmentPhases } = mastertable.kolibri;
    return (assignmentPhases || []).map(
      (assignmentPhase) =>
        ({
          label: assignmentPhase.displayName,
          value: assignmentPhase.value,
        } as SingleFilterValue)
    );
  }
);

export const energyClassOptions = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { energyClassTypeOptions } = mastertable.mls;
    return (energyClassTypeOptions || []).map(
      (type) =>
        ({
          label: type.displayName,
          value: type.value,
        } as SingleFilterValue)
    );
  }
);

export const conditionOptionsInside = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { conditionTypeOptions } = mastertable.mls;
    return (conditionTypeOptions || [])
      .map(
        (type) =>
          ({
            label: type.displayName,
            value: type.value,
          } as SingleFilterValue)
      )
      .filter(
        (item) =>
          [
            ConditionType.VERY_GOOD,
            ConditionType.GOOD,
            ConditionType.MEDIOCRE,
            ConditionType.MODERATE,
            ConditionType.BAD,
          ].indexOf(item.value) > -1
      );
  }
);

export const conditionOptionsOutside = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { conditionTypeOptions } = mastertable.mls;
    return (conditionTypeOptions || [])
      .map(
        (type) =>
          ({
            label: type.displayName,
            value: type.value,
          } as SingleFilterValue)
      )
      .filter(
        (item) =>
          [
            ConditionType.VERY_GOOD,
            ConditionType.GOOD,
            ConditionType.MEDIOCRE,
            ConditionType.MODERATE,
            ConditionType.BAD,
          ].indexOf(item.value) > -1
      );
  }
);

export const availabilityStatusValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { statusTypeOptions } = mastertable.mls;
    return (statusTypeOptions || []).map(
      (availabilityStatus) =>
        ({
          label: availabilityStatus.displayName,
          value: availabilityStatus.value,
        } as SingleFilterValue)
    );
  }
);

export const mutationTypeOptions = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { mutationTypeOptions } = mastertable.mls;
    return (mutationTypeOptions || []).map(
      (type) =>
        ({
          label: type.displayName,
          value: type.value,
        } as SingleFilterValue)
    );
  }
);

export const officeValues = createSelector(officesSelector, (offices) => {
  return (offices || []).map(
    (office) =>
      ({
        label: office.displayName,
        value: office.id,
      } as SingleFilterValue)
  );
});

export const employeeValues = createSelector(employeesSelector, (employees) => {
  return (employees || []).map(
    (employee) =>
      ({
        label: employee.displayName,
        value: employee.id,
      } as SingleFilterValue)
  );
});

export const activeFilterGuidValues = createSelector(
  employeesSelector,
  officesSelector,
  (employees, offices) => {
    return {
      employees: (employees || []).map((employee) => ({
        value: employee.displayName,
        id: employee.id,
      })),
      offices: (offices || []).map((office) => ({
        value: office.displayName,
        id: office.id,
      })),
    } as FilterGuidValues;
  }
);

export const facilitiesValues = createSelector(
  masterTableSelector,
  filtersSelector,
  (mastertable, filters) => {
    const { facilitySearchTypeOptions } = mastertable.mls;
    const realEstateGroup =
      filters?.realEstateGroups?.value || RealEstateGroup.Residential;

    return (facilitySearchTypeOptions || [])
      .filter((facilitySearchTypeOption) => {
        switch (realEstateGroup) {
          case RealEstateGroup.Agricultural: {
            return [FacilitySearchType.BUSINESS_HOUSE].includes(
              facilitySearchTypeOption.value
            );
          }
          case RealEstateGroup.Commercial: {
            return [
              FacilitySearchType.BOILER,
              FacilitySearchType.ELEVATOR,
              FacilitySearchType.RENEWABLE_ENERGY,
            ].includes(facilitySearchTypeOption.value);
          }
          case RealEstateGroup.Residential: {
            return ![
              FacilitySearchType.BUSINESS_HOUSE,
              FacilitySearchType.MONUMENTAL_BUILDING,
              FacilitySearchType.NEEDS_RENOVATION,
            ].includes(facilitySearchTypeOption.value);
          }
          default: {
            return false;
          }
        }
      })
      .map(
        (facilitySearchTypeOption) =>
          ({
            label: facilitySearchTypeOption.displayName,
            value: facilitySearchTypeOption.value,
          } as SingleFilterValue)
      );
  }
);

export const otherSearchTypeOptions = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { otherSearchTypeOptions } = mastertable.mls;

    const others = [
      {
        displayName: `otherSearchTypeOptions.isAccessibleForSeniors`,
        value: "isAccessibleForSeniors",
      },
      {
        displayName: `otherSearchTypeOptions.isAccessibleWithDisabilities`,
        value: "isAccessibleWithDisabilities",
      },
      {
        displayName: `otherSearchTypeOptions.isAdaptedHome`,
        value: "isAdaptedHome",
      },
      {
        displayName: `otherSearchTypeOptions.hasOpenHouse`,
        value: "hasOpenHouse",
      },
      {
        displayName: `otherSearchTypeOptions.isAuction`,
        value: "isAuction",
      },
    ];

    const mergedOthers = [...otherSearchTypeOptions, ...others];

    return (mergedOthers || [])
      .map(
        (otherSearchTypeOption) =>
          ({
            label: otherSearchTypeOption.displayName,
            value: otherSearchTypeOption.value,
          } as SingleFilterValue)
      )
      .filter((item) => item.value !== OtherSearchType.IS_CONFIDENTIAL);
  }
);

export const furnishingValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { furnishingOptions } = mastertable.kolibri;
    return (furnishingOptions || []).map(
      (furnishingOption) =>
        ({
          label: furnishingOption.displayName,
          value: furnishingOption.value,
        } as SingleFilterValue)
    );
  }
);

export const searchLocationValues = createSelector(
  searchLocationsSelector,
  (searchLocationsState) => {
    const { searchLocations } = searchLocationsState;
    return (searchLocations || []).map((item) => {
      return {
        label: item.name,
        value: {
          label: item.name,
          min: item.postalCodeMin,
          max: item.postalCodeMax,
        },
      };
    });
  }
);

export const realEstateAgencyGroupValues = createSelector(
  agencyGroupsSelector,
  (agencyGroups) => {
    return (agencyGroups || []).map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });
  }
);

export const realEstateAgencyAppClientValues = createSelector(
  appClientSelector,
  (appClients) => {
    return (appClients || []).map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }
);

export const mutationsDates = createSelector(filtersSelector, (filters) => {
  return { value: filters.mutationsDates.value } as SingleFilterValue;
});

export const priceRangeValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.priceRangeValues.value } as SingleFilterValue;
});

export const livingAreaValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.livingAreaValues.value } as SingleFilterValue;
});

export const noOfRoomsValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.noOfRoomsValues.value } as SingleFilterValue;
});

export const noOfBedroomsValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.noOfBedroomsValues.value } as SingleFilterValue;
});

export const contentValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.contentValues.value } as SingleFilterValue;
});

export const floorsValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.floorsValues.value } as SingleFilterValue;
});

export const yearOfConstructionValues = createSelector(
  filtersSelector,
  (filters) => {
    return {
      value: filters.yearOfConstructionValues.value,
    } as SingleFilterValue;
  }
);

export const gardenPlotSizeValues = createSelector(
  filtersSelector,
  (filters) => {
    return { value: filters.gardenPlotSizeValues.value } as SingleFilterValue;
  }
);

export const plotSizeValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.plotSizeValues.value } as SingleFilterValue;
});

export const garageCapacityValues = createSelector(
  filtersSelector,
  (filters) => {
    return { value: filters.garageCapacityValues.value } as SingleFilterValue;
  }
);

export const hasSelectedIds = createSelector(
  selectedIdsSelector,
  (selectedIds) => !!selectedIds.length
);

export const mlsAccessData = createSelector(
  mlsAccessDataSelector,
  (mlsAccessData) => {
    return mlsAccessData;
  }
);

export const floorAreaValues = createSelector(filtersSelector, (filters) => {
  return { value: filters.floorAreaValues.value } as SingleFilterValue;
});

export const filters = createSelector(filtersSelector, (filters) => {
  return filters;
});
