import {
  ObjectAssignment,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { Group, RealEstateProperty } from "@haywork/api/mls";
import { RelationThunks } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { AppState } from "@haywork/stores";
import { IntlShape } from "react-intl";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import {
  DetailGeneralComponent,
  DetailGeneralComponentProps,
} from "./detail-general";
import { adText, displayName } from "./selectors";

type StateProps = {
  realEstateProperty: RealEstateProperty;
  displayName: string;
  adText: string;
  following: boolean;
  appClientKey: string;
  bundleId: string;
  realtorName: string;
  groupNames: string[];
  groupReleaseDateTime: Date | null | undefined;
  agencyGroups: Group[];
};
type DispatchProps = {
  toggleFollow: ({
    bundleId,
    appClientKey,
    following,
  }: {
    bundleId: string;
    appClientKey: string;
    following: boolean;
  }) => Promise<void>;
  copyToObjectAssignment: (objectAssignment: ObjectAssignment) => void;
  getRelationsWithMatchingEmailAddress: (
    email: string
  ) => Promise<RelationSnapShot[]>;
  printRealEstateProperty: (
    realEstateProperty: RealEstateProperty,
    realtorName: string,
    intl: IntlShape
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DetailGeneralComponentProps,
  AppState
> = (state) => {
  const {
    realEstateProperty,
    following,
    appClientKey,
    bundleId,
    groupNames,
    groupReleaseDateTime,
  } = state.mls.single.realEstateResponse;
  const { name: realtorName } = state.account.currentRealestateAgency;
  const agencyGroups = state.mls.list.agencyGroups || [];

  return {
    realEstateProperty,
    displayName: displayName(state),
    adText: adText(state),
    following,
    appClientKey,
    bundleId,
    realtorName,
    groupNames: groupNames || [],
    groupReleaseDateTime,
    agencyGroups,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DetailGeneralComponentProps
> = (dispatch) => ({
  toggleFollow: ({ bundleId, appClientKey, following }) =>
    dispatch(
      MLSThunk.Single.toggleFollow({ bundleId, appClientKey, following })
    ),
  copyToObjectAssignment: (realEstateProperty) =>
    dispatch(MLSThunk.Single.copyToAssignment(realEstateProperty)),
  printRealEstateProperty: (
    realEstateProperty: RealEstateProperty,
    realtorName: string,
    intl: IntlShape
  ) =>
    dispatch(
      MLSThunk.Single.printRealEstateProperty(
        realEstateProperty,
        realtorName,
        intl
      )
    ),
  getRelationsWithMatchingEmailAddress: (email: string) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        [email],
        [RelationType.ContactCompany]
      )
    ),
});

export type DetailGeneralContainerProps = StateProps & DispatchProps;
export const DetailGeneralContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailGeneralComponent);
