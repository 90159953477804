import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { PriceHistoryComponent, PriceHistoryProps } from "./price-history";

type StateProps = {};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  PriceHistoryProps,
  AppState
> = (state) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PriceHistoryProps
> = (dispatch) => ({});

export type PriceHistoryContainerProps = StateProps & DispatchProps;
export const PriceHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceHistoryComponent);
