export const EMPLOYEE = {
    UPDATE_EMPLOYEE_FILTER: "UPDATE_EMPLOYEE_FILTER",
    SET_FETCH_EMPLOYEE_OVERVIEW_STATE: "SET_FETCH_EMPLOYEE_OVERVIEW_STATE",
    SET_INITIAL_FETCH_EMPLOYEE_OVERVIEW_STATE: "SET_INITIAL_FETCH_EMPLOYEE_OVERVIEW_STATE",
    SEARCH_EMPLOYEES: "SEARCH_EMPLOYEES",
    APPEND_EMPLOYEES: "APPEND_EMPLOYEES",
    UPDATE_OFFICE_TO_EMPLOYEE: "SAVE_OFFICE_TO_EMPLOYEE",
    SET_EMPLOYEE_UPDATE_STATE: "SET_EMPLOYEE_UPDATE_STATE",
    ARCHIVE_EMPLOYEE: "ARCHIVE_EMPLOYEE",
    REMOVE_FROM_LIST: "RELATION_REMOVE_CL_FROM_LIST",
    TOGGLE_EMPLOYEE_MODAL: "TOGGLE_EMPLOYEE_MODAL",
    GET_EMPLOYEE: "GET_EMPLOYEE",
    SET_EMPLOYEE_STATE: "SET_EMPLOYEE_STATE",
    SET_EMPLOYEE: "SET_EMPLOYEE",
    SET_EMPLOYEE_ASSIGNMENT_STATE: "SET_EMPLOYEE_ASSIGNMENT_STATE",
    APPEND_EMPLOYEE_ASSIGNMENTS: "APPEND_EMPLOYEE_ASSIGNMENTS",
    SEARCH_EMPLOYEE_ASSIGNMENTS: "SEARCH_EMPLOYEE_ASSIGNMENTS",
    SET_EMPLOYEE_ADDRESS_SEARCH_STATE: "SET_EMPLOYEE_ADDRESS_SEARCH_STATE",
    SET_SAVE_EMPLOYEE_STATE: "SET_SAVE_EMPLOYEE_STATE",
    CLEAR_EMPLOYEES: "CLEAR_EMPLOYEES",
    SET_TOTAL_AMOUNT_OF_EMPLOYEES: "SET_TOTAL_AMOUNT_OF_EMPLOYEES"
  };
