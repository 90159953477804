import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  zebra: boolean;
  style: CSSProperties;
};

export const TimelineEmptyItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ zebra, style }) => {
    return (
      <div styleName={classNames("empty-state", { zebra })} style={style}>
        <div styleName="empty-state__icon">
          <Icon name="ellipsis-h" light size={24} color={Colors.Gray} />
        </div>
        <div styleName="empty-state__spacer" />
        <div styleName="empty-state__bars">
          <div styleName="bar1" />
          <div styleName="bar2" />
        </div>
      </div>
    );
  })
);
