import { CheckboxFilter, Filter } from "@haywork/modules/filter";
import { AcquisitionFilters } from "@haywork/stores/acquisition";
import * as React from "react";
import { FC, memo, useMemo, useCallback, MouseEvent } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionListFiltersContainerProps } from "./list-filters.container";

const styles = require("./style.scss");

export type AcquisitionListFiltersComponentProps = {};
type Props = AcquisitionListFiltersComponentProps &
  AcquisitionListFiltersContainerProps;

export const AcquisitionListFiltersComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      filters,
      realEstateGroupsValues,
      kindOfAssignmentValues,
      assignmentPhasesValues,
      officesValues,
      employeesValues,
      setFilters,
    }) => {
      const onChangeFilterHandler = (value: AcquisitionFilters) => {
        setFilters(value);
      };

      const {
        filterByRealEstateGroups,
        kindOfAssignment,
        assignmentPhases,
        employeeIds,
        officeIds,
      } = filters;

      const officesSubtitle = useMemo(() => {
        const values = officesValues.slice(0, 10);
        return values.map((value) => value.label).join(", ");
      }, [officeIds, officesValues]);

      const removeOfficeFromFilter = useCallback(
        (event: MouseEvent<HTMLDivElement>, id: string) => {
          event.stopPropagation();
          const updatedfilters = {
            ...filters,
            officeIds: filters.officeIds.filter((officeId) => officeId !== id),
          };

          setFilters(updatedfilters);
        },
        [filters, setFilters]
      );

      const renderSelectedOffices = useMemo(() => {
        const values = officesValues.filter((value) =>
          officeIds.includes(value.value)
        );
        return (
          <div styleName="checked-filters" className="subtitle__selectedItems">
            {values.map((value) => (
              <div
                key={value.value}
                onClick={(event) => removeOfficeFromFilter(event, value.value)}
                styleName="checked-filter"
              >
                <div styleName="icon">
                  <i className="fal fa-check" />
                </div>
                <span>{value.label}</span>
              </div>
            ))}
          </div>
        );
      }, [officeIds, officesValues]);

      const employeesSubtitle = useMemo(() => {
        const values = employeesValues.slice(0, 10);
        return values.map((value) => value.label).join(", ");
      }, [employeeIds, employeesValues]);

      const removeEmployeeFromFilter = useCallback(
        (event: MouseEvent<HTMLDivElement>, id: string) => {
          event.stopPropagation();
          const updatedfilters = {
            ...filters,
            employeeIds: filters.employeeIds.filter(
              (employeeId) => employeeId !== id
            ),
          };

          setFilters(updatedfilters);
        },
        [filters, setFilters]
      );

      const renderSelectedEmployees = useMemo(() => {
        const values = employeesValues.filter((value) =>
          employeeIds.includes(value.value)
        );
        return (
          <div styleName="checked-filters" className="subtitle__selectedItems">
            {values.map((value) => (
              <div
                key={value.value}
                onClick={(event) =>
                  removeEmployeeFromFilter(event, value.value)
                }
                styleName="checked-filter"
              >
                <div styleName="icon">
                  <i className="fal fa-check" />
                </div>
                <span>{value.label}</span>
              </div>
            ))}
          </div>
        );
      }, [employeeIds, employeesValues]);

      return (
        <Filter changeFilter={onChangeFilterHandler}>
          <CheckboxFilter
            name="filterByRealEstateGroups"
            value={filterByRealEstateGroups}
            values={realEstateGroupsValues}
            title="acquisition.filters.filterByRealEstateGroups"
          />

          <CheckboxFilter
            name="kindOfAssignment"
            value={kindOfAssignment}
            values={kindOfAssignmentValues}
            title="acquisition.filters.kindOfAssignment"
          />

          <CheckboxFilter
            name="assignmentPhases"
            value={assignmentPhases}
            values={assignmentPhasesValues}
            title="acquisition.filters.assignmentPhases"
          />

          <CheckboxFilter
            name="officeIds"
            value={officeIds}
            values={officesValues}
            title="acquisition.filters.officeIds"
            canToggle
            visible={false}
            subtitle={
              officeIds.length ? renderSelectedOffices : officesSubtitle
            }
            hideCheckedItems
          />

          <CheckboxFilter
            name="employeeIds"
            value={employeeIds}
            values={employeesValues}
            title="acquisition.filters.employeeIds"
            canToggle
            visible={false}
            subtitle={
              employeeIds.length ? renderSelectedEmployees : employeesSubtitle
            }
            hideCheckedItems
          />
        </Filter>
      );
    }
  )
);
