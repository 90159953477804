import {
  SearchAssignment,
  SearchAssignmentSnapShot,
  RealEstateGroup,
  MatchedPropertySnapShot,
} from "@haywork/api/kolibri";
import { AddressUtil } from "./adress";
import * as moment from "moment";
import { EmailAssignment } from "./email-v2";
import { IntlShape } from "react-intl";
import { priceRangeValues } from "@haywork/modules/mls/screens/list/selectors";

export enum SearchAssignmentSaveError {
  NoLinkedRelations = "NoLinkedRelations",
  NoSearchLocations = "NoSearchLocations",
}

export class SearchAssignmentUtil {
  public static mapMatchedPropertySnapShotToEmailAssignment(
    assignment: MatchedPropertySnapShot,
    intl: IntlShape
  ): EmailAssignment {
    const {
      id,
      rentPrice,
      salePrice,
      forSale,
      forRent,
      listingType,
      photo1Url,
      photo2Url,
      photo3Url,
      saleCondition,
      displayName,
    } = assignment;

    const image = photo1Url || photo2Url || photo3Url;
    const addressLine1 = displayName;
    const priceToRender = forSale ? salePrice : rentPrice;
    const price = !priceToRender
      ? undefined
      : intl.formatNumber(priceToRender, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
    const priceCondition =
      !forRent || !saleCondition
        ? undefined
        : intl.formatMessage({
            id: `saleConditions.${saleCondition.toString()}`,
            defaultMessage: "",
          });
    const propertyTypes = !listingType
      ? undefined
      : [
          intl.formatMessage({
            id: `listingTypes.${listingType.toString()}`,
            defaultMessage: "",
          }),
        ].filter((d) => !!d);

    return {
      id,
      image,
      addressLine1,
      price,
      priceCondition,
      propertyTypes,
    };
  }

  public static validForSave(searchAssignment: SearchAssignment) {
    if (!searchAssignment) return true;

    const linkedRelations = searchAssignment.linkedRelations || [];
    const locations = searchAssignment.locations || [];

    if (!linkedRelations || linkedRelations.length === 0) return false;

    if (locations.length > 1) {
      const filledLocation = locations.find((location) =>
        AddressUtil.doesLocationHaveAddress(location)
      );
      if (!filledLocation) return false;
    }

    return true;
  }

  public static getErrors(
    searchAssignment: SearchAssignment
  ): SearchAssignmentSaveError[] {
    if (!searchAssignment) return [];

    const errors: SearchAssignmentSaveError[] = [];
    const linkedRelations = searchAssignment.linkedRelations || [];
    const locations = searchAssignment.locations || [];

    const filledLocation = locations.find((location) =>
      AddressUtil.doesLocationHaveAddress(location)
    );
    if (!filledLocation)
      errors.push(SearchAssignmentSaveError.NoSearchLocations);

    if (!linkedRelations || linkedRelations.length === 0)
      errors.push(SearchAssignmentSaveError.NoLinkedRelations);

    return errors;
  }

  public static extendName(
    snapshot: SearchAssignmentSnapShot
  ): SearchAssignmentSnapShot {
    const {
      offerType,
      alvOveType,
      partOveType,
      bogOveType,
      realEstateGroup,
      dateTimeCreated,
      linkedRelations,
    } = snapshot;

    let segments = [`${realEstateGroup.toString()} ${offerType.toString()}`];
    switch (realEstateGroup) {
      case RealEstateGroup.Residential: {
        const types = (partOveType || [])
          .filter((v) => !!v)
          .map((type) => type.toString());
        segments = [...segments, ...types];
        break;
      }
      case RealEstateGroup.Agricultural: {
        const types = (alvOveType || [])
          .filter((v) => !!v)
          .map((type) => type.toString());
        segments = [...segments, ...types];
        break;
      }
      case RealEstateGroup.Commercial: {
        const types = (bogOveType || [])
          .filter((v) => !!v)
          .map((type) => type.toString());
        segments = [...segments, ...types];
        break;
      }
      default:
        break;
    }

    const relationNames = (linkedRelations || [])
      .map((relation) => relation.displayName)
      .filter((v) => !!v);

    segments = [
      ...segments,
      ...relationNames,
      moment(dateTimeCreated).format("DD MMMM YYYY"),
    ];

    return {
      ...snapshot,
      name: segments.join(", "),
    };
  }
}
