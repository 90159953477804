import { combineReducers } from "redux";

import { singleProjectReducer, SingleProjectState, ProjectSingleActions as Single } from "./single";
import { typesReducer, TypesState, TypesActions as Types } from "./types";
import { buildnumbersReducer, BuildnumbersState, BuildnumbersActions as Buildnumbers } from "./buildnumbers";

const projectReducer = combineReducers({
  single: singleProjectReducer,
  types: typesReducer,
  buildnumbers: buildnumbersReducer
});

interface ProjectState {
  single: SingleProjectState;
  types: TypesState;
  buildnumbers: BuildnumbersState;
}

const ProjectsActions = {
  Types,
  Buildnumbers,
  Single
};

export {
  projectReducer,
  ProjectState,
  ProjectsActions,
  SingleProjectState
};
