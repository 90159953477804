import * as React from "react";
import { InitializedWidget, WidgetSnapShot } from "@haywork/api/authorization";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  widget: WidgetSnapShot;
  initializedWidgets: InitializedWidget[];
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsSidebarComponent extends React.PureComponent<
  Props,
  State
> {
  public render() {
    const { widget, initializedWidgets } = this.props;
    const { displayName, height, id } = widget;
    const initializedWidget = initializedWidgets.find(
      (widget) => widget.id === id
    );

    return (
      <div styleName="widget">
        <h2 styleName="widget__title">{displayName}</h2>
        <div style={{ height }} styleName="widget__content">
          {!!initializedWidget && (
            <iframe
              style={{ height }}
              frameBorder={0}
              src={initializedWidget.signedCallbackUri}
              // sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin"
            />
          )}
        </div>
      </div>
    );
  }
}
