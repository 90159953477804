import { Address, AssignmentPhase } from "@haywork/api/kolibri";
import { PROJECTROUTES, REQUEST } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ProjectPublishContainerProps } from "@haywork/modules/project";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import Publications from "@haywork/modules/shared/components/publish-publications";
import { SingleProjectState } from "@haywork/stores";
import { ProjectUtil, RouteUtil } from "@haywork/util";
import { ProjectPublishError } from "@haywork/util/project";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./publish.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const preflight = ProjectUtil.Validation.preflightPublishProjectAssignment;

interface ProjectPublishComponentProps {}
interface ProjectPublishComponentState {
  showFundaDate: boolean;
  hideOnFundaUntil: Date;
  showConfirmModal: boolean;
  fromSubmit: boolean;
  publishErrors: ProjectPublishError[];
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectPublishComponent extends React.Component<
  ProjectPublishComponentProps & ProjectPublishContainerProps,
  ProjectPublishComponentState
> {
  private formControls: FormControls;
  private ref: HTMLDivElement;
  private newProjectTypeAfterPublish: boolean;

  constructor(props) {
    super(props);

    this.state = {
      showFundaDate: this.props.projectAssignment.hideOnFundaUntil
        ? true
        : false,
      hideOnFundaUntil: this.props.projectAssignment.hideOnFundaUntil,
      showConfirmModal: false,
      fromSubmit: false,
      publishErrors: preflight(
        this.props.projectAssignment,
        this.props.constructionPeriods
      ),
    };

    this.formControls = {
      toggleFunda: {
        value: this.props.projectAssignment.hideOnFundaUntil ? true : false,
        onChange: (ref) => {
          if (!ref.value) {
            this.setState({
              showFundaDate: false,
              hideOnFundaUntil: undefined,
            });
            return { hideOnFundaUntil: "" };
          } else {
            this.setState({ showFundaDate: true });
          }
        },
      },
      hideOnFundaUntil: {
        value: this.props.projectAssignment.hideOnFundaUntil,
        onChange: (ref) => {
          this.setState({ hideOnFundaUntil: ref.value });
        },
        validators: [
          Validators.required(undefined, () => !this.state.showFundaDate),
          Validators.dateShouldBeAfter(new Date()),
        ],
      },
    };

    this.onNavigateClickHandler = this.onNavigateClickHandler.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onConformModalCloseHandler =
      this.onConformModalCloseHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.createObjectTypeAfterPublish =
      this.createObjectTypeAfterPublish.bind(this);
  }

  public render() {
    const { id, photos, videos, displayName, address } =
      this.props.projectAssignment;
    const photoCount = !!photos ? photos.length : 0;
    const videoCount = !!videos ? videos.length : 0;
    const firstPhoto: any = photoCount ? photos[0] : null;
    const previewStyles = photoCount
      ? {
          backgroundImage: `url(${JSON.stringify(
            firstPhoto.urlPreview || firstPhoto.thumbUrl
          )})`,
        }
      : null;

    return (
      <div styleName="publish" ref={(ref) => (this.ref = ref)}>
        <div className="container-fluid">
          <h1>
            <ResourceText resourceKey="publishStartingProject" />
          </h1>

          <div styleName="publish__info">
            <div styleName="preview" style={previewStyles}>
              {!photoCount && <i className="fal fa-camera-retro" />}
            </div>
            <div styleName="address">
              <div>{displayName}</div>
              <div>{this.getProjectAddress(address)}</div>
            </div>
          </div>

          <div styleName="publish__list">
            <FeatureSwitch feature="PUBLICATIONS_V2" disable>
              {this.renderListItem(
                "publishMediaPartnersProject",
                this.props.publicationCount,
                route(PROJECTROUTES.EDIT_MARKETING_PARTNERS.URI, { id })
              )}
            </FeatureSwitch>

            <FeatureSwitch feature="PUBLICATIONS_V2">
              <Publications
                assignmentId={this.props.projectAssignment.id}
                isAssignmentConcept={
                  this.props.projectAssignment.assignmentPhase ===
                  AssignmentPhase.Concept
                }
              />
            </FeatureSwitch>

            {this.renderListItem(
              "publishPhotos",
              photoCount,
              route(PROJECTROUTES.EDIT_MARKETING_PHOTOS.URI, { id })
            )}
            {this.renderListItem(
              "publishVideos",
              videoCount,
              route(PROJECTROUTES.EDIT_MARKETING_VIDEOS.URI, { id })
            )}
          </div>

          <div styleName="publish__form">
            <Form
              name="publish"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              onChange={this.onChangeHandler}
            >
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <Input.Switch
                      name="toggleFunda"
                      on={true}
                      off={false}
                      label="hideOnFunda"
                      labelPosition={SwitchLabelPosition.Pre}
                    />
                  </div>
                  {this.state.showFundaDate && (
                    <div className="column__join">
                      <div className="column__textspacer">
                        <ResourceText resourceKey="untill" />
                      </div>
                      <div className="column">
                        <Input.Datepicker
                          name="hideOnFundaUntil"
                          hour={0}
                          minutes={0}
                          seconds={0}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <button type="submit" className="btn btn-primary">
                      <ResourceText resourceKey="publishProject" />
                    </button>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={this.createObjectTypeAfterPublish}
                    >
                      <ResourceText resourceKey="publishAndAddObjectTypes" />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          visible={this.state.showConfirmModal}
          onClose={this.onConformModalCloseHandler}
        >
          <ModalHeader close title="publishProject" />
          <ModalBody>
            <ResourceText resourceKey="startPublishProject" />
          </ModalBody>
          <ModalFooter style="no-padding">
            <button
              className="btn btn-success text-center full emphasize"
              disabled={this.props.publishState === REQUEST.PENDING}
              onClick={this.onPublishClickHandler}
            >
              <ButtonLoader
                resourceKey="publish"
                loading={this.props.publishState === REQUEST.PENDING}
              />
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ProjectPublishComponentProps & ProjectPublishContainerProps
  ) {
    if (
      nextProps &&
      nextProps.publishState === REQUEST.ERROR &&
      this.state.showConfirmModal === true &&
      !this.state.fromSubmit
    ) {
      this.setState({ showConfirmModal: false });
    }
  }

  private createObjectTypeAfterPublish() {
    this.newProjectTypeAfterPublish = true;
  }

  private getProjectAddress(address: Address): string {
    if (!address) return "";
    let str = "";
    if (address.postalCode) {
      str += address.postalCode;
    }
    if (address.postalCode && address.locality && address.locality.name) {
      str += ", ";
    }
    if (address.locality && address.locality.name) {
      str += address.locality.name;
    }
    return str;
  }

  private renderListItem(
    label: string,
    count: number,
    editRoute: string
  ): React.ReactElement<HTMLDivElement> {
    const iconType = classNames(
      "fal",
      "fa-fw",
      count === 0 ? "fa-exclamation-triangle" : "fa-check-circle"
    );
    const iconStyle = classNames("listitem__icon", { warn: count === 0 });
    const btnStyle = classNames(
      "btn",
      count === 0 ? "btn-warning" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          <ResourceText resourceKey={label} values={{ count }} />
        </div>
        <div styleName="listitem__action">
          <div
            className={btnStyle}
            onClick={() => this.onNavigateClickHandler(editRoute)}
          >
            <ResourceText resourceKey="adjust" />
          </div>
        </div>
      </div>
    );
  }

  private onNavigateClickHandler(route: string) {
    this.props.navigate(route);
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.setState({ showConfirmModal: true, fromSubmit: true }, () => {
      this.setState({ fromSubmit: false });
    });
  }

  private onChangeHandler(values: FormReturnValue) {
    const { currentComponentState } = this.props;

    const newState: SingleProjectState = {
      ...currentComponentState,
      projectAssignment: {
        ...currentComponentState.projectAssignment,
        hideOnFundaUntil: values.hideOnFundaUntil,
      },
    };

    this.props.updateProject(newState, this.props.path);
  }

  private onConformModalCloseHandler() {
    this.setState({ showConfirmModal: false });
    this.newProjectTypeAfterPublish = false;
  }

  private async onPublishClickHandler() {
    const { id } = this.props.projectAssignment;
    await this.props.publishProject(id, this.state.hideOnFundaUntil);

    if (this.newProjectTypeAfterPublish === true) {
      this.props.onAddNewType(this.props.projectAssignment.id);
    }
  }
}
