import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-loader.component.scss");

interface ListLoaderComponentProps {}
interface ListLoaderComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ListLoader extends React.Component<ListLoaderComponentProps, ListLoaderComponentState> {

  public render() {
    return <div
      styleName="list-loader"
      data-cy={ this.props["data-cy"] }
    >
      <div styleName="loader">
        <svg styleName="circular" viewBox="25 25 50 50">
          <circle styleName="path" cx="50" cy="50" r="20" fill="none" strokeMiterlimit="10" />
        </svg>
      </div>
    </div>;
  }
}
