import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { EmailAddress } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./list.component.scss");

interface Props {
  names: EmailAddress[];
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class NamesComponent extends React.Component<Props, State> {
  private breakOn = 5;

  public render() {
    let { names } = this.props;
    const count = names.length - this.breakOn;
    names = names.slice(0, this.breakOn);

    return (
      <div styleName="name">
        {names.map((name) => name.name || name.email).join(", ")}
        {count > 0 && (
          <ResourceText
            resourceKey="emailMoreContacts"
            values={{ count }}
            asHtml
          />
        )}
      </div>
    );
  }
}
