import { ObjectTypeAssignment, TypePART } from "@haywork/api/kolibri";
import { ObjectTypesEditSpecsContainerProps } from "@haywork/modules/object-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EditSpecsDefault, EditSpecsPlot } from "./types";

const styles = require("./edit-specs.component.scss");

export interface ObjectTypesEditSpecsComponentProps {}
type Props = ObjectTypesEditSpecsComponentProps &
  ObjectTypesEditSpecsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditSpecsComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const {
      currentType,
      isolationTypeOptions,
      heatingMethodOptions,
      heatingWaterMethodOptions,
      energySourceOptions,
      ownershipOptions,
      comfortQualityOptions,
      roofTypeOptions,
      roofMaterialOptions,
      conditionOptions,
      parkingFacilityOptions,
      residentialFacilityOptions
    } = this.props;
    const { typePART } = currentType;

    return (
      <React.Fragment>
        {typePART !== TypePART.BuildingGround && (
          <EditSpecsDefault
            currentType={currentType}
            isolationTypeOptions={isolationTypeOptions}
            heatingMethodOptions={heatingMethodOptions}
            heatingWaterMethodOptions={heatingWaterMethodOptions}
            energySourceOptions={energySourceOptions}
            ownershipOptions={ownershipOptions}
            comfortQualityOptions={comfortQualityOptions}
            roofTypeOptions={roofTypeOptions}
            roofMaterialOptions={roofMaterialOptions}
            conditionOptions={conditionOptions}
            parkingFacilityOptions={parkingFacilityOptions}
            residentialFacilityOptions={residentialFacilityOptions}
            onChange={this.onChange}
          />
        )}
        {typePART === TypePART.BuildingGround && (
          <EditSpecsPlot
            currentType={currentType}
            currentDestinationText={this.props.currentDestinationText}
            currentUsageText={this.props.currentUsageText}
            onChange={this.onChange}
          />
        )}
      </React.Fragment>
    );
  }

  private onChange(type: ObjectTypeAssignment) {
    this.props.updateCurrentType(type, this.props.path);
  }
}
