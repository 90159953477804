import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { FormattedDate } from "react-intl";

import { EmailBucket } from "@haywork/enum";
import { ExtendedEmailDraft } from "@haywork/util/email";

const styles = require("./list.component.scss");

interface Props {
  draft: ExtendedEmailDraft;
  currentMessageId: string;
  bucket: EmailBucket;
  onClick: (draft: ExtendedEmailDraft) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class DraftItem extends React.Component<Props, State> {
  public render() {
    const { subject, snippet, files, id } = this.props.draft;
    const attachments = files || [];
    const itemStyle = classNames("item", {
      selected: this.props.currentMessageId === id,
    });

    return (
      <div
        styleName={itemStyle}
        data-cy="CY-draftListItem"
        onClick={() => this.props.onClick(this.props.draft)}
      >
        <div styleName="item__info">
          <div styleName="name">{this.renderToNames()}</div>
          <div styleName="description">{subject}</div>
          <div styleName="excerpt">{snippet || "..."}</div>
        </div>
        <div styleName="item__meta">
          <div styleName="flags">
            {attachments.length > 0 && (
              <div styleName="flag">
                <i className="fal fa-paperclip" />
              </div>
            )}
          </div>
          <div styleName="date">{this.renderDate()}</div>
        </div>
      </div>
    );
  }

  private renderDate() {
    const draft = this.props.draft;

    switch (this.props.bucket) {
      case EmailBucket.Today:
        return (
          <FormattedDate
            value={draft.date || ""}
            hour="2-digit"
            minute="2-digit"
          />
        );
      case EmailBucket.Yesterday:
        return (
          <FormattedDate
            value={draft.date || ""}
            weekday="short"
            hour="2-digit"
            minute="2-digit"
          />
        );
      case EmailBucket.LastWeek:
        return (
          <FormattedDate
            value={draft.date || ""}
            weekday="short"
            day="2-digit"
            month="short"
          />
        );
      default:
        return (
          <FormattedDate
            value={draft.date || ""}
            weekday="short"
            day="2-digit"
            month="short"
            year="2-digit"
          />
        );
    }
  }

  private renderToNames(): string {
    if (!this.props.draft.to) return null;
    return this.props.draft.to.map((p) => p.name || p.email).join(", ");
  }
}
