import * as React from "react";
import * as PropTypes from "prop-types";

interface ArrayInputShouldShowComponentProps {
  shouldShow: (values: { [key: string]: any }) => boolean;
}
interface ArrayInputShouldShowComponentState {
  shouldShow: boolean;
}

export class ArrayInputShouldShowComponent extends React.Component<ArrayInputShouldShowComponentProps, ArrayInputShouldShowComponentState> {
  public static contextTypes = {
    values: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      shouldShow: (this.context.values) ? this.props.shouldShow(this.context.values) : false
    };
  }

  public render() {
    return (this.state.shouldShow) ?
      <div>{ this.props.children }</div> :
      null;
  }

  public UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext && nextContext.values) this.setState({ shouldShow: this.props.shouldShow(nextContext.values) });
  }
}
