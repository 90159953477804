import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { NoteSnapShot, LinkedEmployee } from "@haywork/api/kolibri";
import classNames from "classnames";
import * as moment from "moment";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import * as nl2br from "nl2br";
import * as linkify from "linkifyjs/html";

const styles = require("./style.scss");

type Props = {
  note: NoteSnapShot | null;
  isSelf: boolean;
  isEditing: boolean;
  onEdit: (note: NoteSnapShot) => void;
  onMarkImportant: (note: NoteSnapShot) => void;
  onDelete: (note: NoteSnapShot) => void;
};
const NotesRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ note, isSelf, isEditing, onMarkImportant, onEdit, onDelete }) => {
      if (!note) return null;

      const {
        linkedModifiedBy,
        linkedCreatedBy,
        isImportant,
        dateTimeCreated,
        dateTimeModified,
        description,
      } = note;

      let { displayName, avatarUrl, letterAvatar } =
        linkedCreatedBy || ({} as LinkedEmployee);
      displayName = displayName || "";
      avatarUrl = avatarUrl || null;
      letterAvatar = letterAvatar || "";

      const isInitialNote = moment(dateTimeCreated).isSame(
        moment(dateTimeModified)
      );
      const dateString = isInitialNote ? "note.date.self" : "note.date.other";
      const date = moment(
        isInitialNote ? dateTimeCreated : dateTimeModified
      ).format("DD MMMM YYYY");
      const time = moment(
        isInitialNote ? dateTimeCreated : dateTimeModified
      ).format("HH:mm");
      const { displayName: modifiedByDisplayName } =
        linkedModifiedBy || ({} as LinkedEmployee);

      const body = useMemo(() => {
        if (!description) return "";
        let body = nl2br(description);
        body = linkify(body, {
          defaultProtocol: "https",
          target: {
            url: "_blank",
          },
        });

        return body;
      }, [description]);

      return (
        <div styleName={classNames("row-wrapper", isSelf ? "self" : "other")}>
          <div
            styleName="row__avatar"
            style={{
              backgroundImage: !avatarUrl
                ? null
                : `url(${JSON.stringify(avatarUrl)})`,
            }}
          >
            {!avatarUrl && letterAvatar}
          </div>
          <div
            styleName={classNames("row", isSelf ? "self" : "other", {
              important: isImportant,
              "in-edit-mode": isEditing,
            })}
          >
            <div styleName="row__title">
              <span>{displayName}</span>
              <div styleName="row__actions">
                <div styleName="action" onClick={() => onDelete(note)}>
                  <Icon
                    name="times"
                    light
                    size={14}
                    color="rgba(0, 0, 0, 0.3)"
                  />
                </div>
                <div styleName="action" onClick={() => onEdit(note)}>
                  <Icon
                    name="pencil"
                    light
                    size={12}
                    color="rgba(0, 0, 0, 0.3)"
                  />
                </div>
                <div
                  styleName={classNames("action", { important: isImportant })}
                  onClick={() => onMarkImportant(note)}
                >
                  <Icon
                    name="thumbtack"
                    light={!isImportant}
                    solid={isImportant}
                    size={12}
                    color="rgba(0, 0, 0, 0.3)"
                  />
                </div>
              </div>
            </div>
            <div
              styleName="row__body"
              dangerouslySetInnerHTML={{ __html: body }}
            />
            <div styleName="row__footer">
              <I18n
                value={dateString}
                values={{ modifiedByDisplayName, date, time }}
              />
            </div>
          </div>
        </div>
      );
    }
  )
);

export default NotesRowComponent;
