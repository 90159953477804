import { ActionType, ActionTypes } from "./actions";
import { EmailFolder } from ".";
import { FolderCategory } from "@haywork/api/mail";

export type State = {
  folders: EmailFolder[];
};

const INITIAL: State = {
  folders: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetFolders: {
      const { folders } = action;

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetFoldersCount: {
      const { statistics } = action;
      const counts = statistics.counts || [];

      const folders = state.folders.map((folder) => {
        const ref = counts.find((c) => c.folderId === folder.id);
        return !ref ? folder : { ...folder, unreadCount: ref.unreadCount || 0 };
      });

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetAccountInboxUnreadCount: {
      const { accountId, unreadCount } = action;

      const folders = state.folders.map((folder) => {
        if (
          folder.accountId === accountId &&
          folder.category === FolderCategory.Inbox
        ) {
          return {
            ...folder,
            unreadCount,
          };
        }
        return folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case ActionType.UpdateFolder: {
      const { folder, blockInternalCreatedOn } = action;
      const folders = state.folders.map((interalFolder) => {
        return interalFolder.id === folder.id
          ? {
              ...folder,
              internallyCreatedOn: blockInternalCreatedOn
                ? undefined
                : new Date(),
            }
          : interalFolder;
      });

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetFolderTotalResults: {
      const { id, totalResults } = action;
      const folders = state.folders.map((folder) => {
        return folder.id === id ? { ...folder, totalResults } : folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetFolderPosition: {
      const { id, position } = action;
      const folders = state.folders.map((folder) => {
        return folder.id === id ? { ...folder, position } : folder;
      });

      return {
        ...state,
        folders,
      };
    }
    case ActionType.AddFolder: {
      const { folder } = action;
      const folders = [
        ...state.folders,
        { ...folder, internallyCreatedOn: new Date() },
      ];

      return {
        ...state,
        folders,
      };
    }
    case ActionType.DeleteFolder: {
      const { id } = action;
      const folders = state.folders.filter((folder) => folder.id !== id);

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetCurrentMessage: {
      const { selectedMessage, id } = action;
      const folders = state.folders.map((folder) =>
        folder.id === id ? { ...folder, selectedMessage } : folder
      );

      return {
        ...state,
        folders,
      };
    }
    case ActionType.SetAccountFolders: {
      const { accountId, folders } = action;
      const filtered = state.folders.filter(
        (folder) =>
          folder.accountId !== accountId ||
          folder.category === FolderCategory.SynchronizingMessage
      );

      return {
        ...state,
        folders: [...filtered, ...folders],
      };
    }
    case ActionType.Clear:
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
