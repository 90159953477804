import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./global-message-widget.component.scss");

interface DashboardGlobalMessageWidgetComponentProps {}
interface DashboardGlobalMessageWidgetComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class  DashboardGlobalMessageWidgetComponent extends React.Component< DashboardGlobalMessageWidgetComponentProps,  DashboardGlobalMessageWidgetComponentState> {
  public render() {
    return <article className="widget alert alert-danger" styleName="widgetGlobalMessage">
      <i className="fal fa-fw fa-exclamation-triangle"></i> Let op, er is op dit moment een storing bij Tiara waardoor nieuwe foto's niet direct op Funda.nl komen. In de loop van de ochtend is dit verholpen.
    </article>;
  }
}
