import { PhraseSnapShot } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { PhrasesThunks } from "@haywork/middleware/thunk/phrases.thunk";
import {
  UiPhrasesModalComponent,
  UiPhrasesModalComponentProps
} from "@haywork/modules/ui/phrases";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  phrases: PhraseSnapShot[];
  phrasesStatus: string;
}
interface DispatchProps {
  onDelete: (id: string) => void;
  onUnDelete: (id: string) => void;
  deletePhraseFromList: (id: string) => void;
}

const mapStateToProps = <StateProps, UiPhrasesModalComponentProps>(
  state: AppState
) => {
  const { phrases, phrasesStatus } = state.phrases;

  return {
    phrases,
    phrasesStatus
  };
};

const mapDispatchToProps = <DispatchProps, UiPhrasesModalComponentProps>(
  dispatch: Dispatch<any>
) => ({
  onDelete: (id: string) => dispatch(PhrasesThunks.deletePhrase(id)),
  onUnDelete: (id: string) => dispatch(PhrasesThunks.unDeletePhrase(id)),
  deletePhraseFromList: (id: string) =>
    dispatch(PhrasesThunks.deletePhraseFromList(id))
});

export type UiPhrasesModalContainerProps = StateProps & DispatchProps;
export const UiPhrasesModalContainer = connect<
  StateProps,
  DispatchProps,
  UiPhrasesModalComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UiPhrasesModalComponent);
