import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionDetailTimelineScreenContainerProps } from "./timeline.container";
import Timeline from "@haywork/modules/shared/components/timeline-v2";
import Sidebar from "../../components/assignment-detail-sidebar";

const styles = require("./style.scss");

export type AcquisitionDetailTimelineScreenProps = {};
type Props = AcquisitionDetailTimelineScreenProps &
  AcquisitionDetailTimelineScreenContainerProps;

export const AcquisitionDetailTimelineScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionAssignmentObject }) => {
      const { id } = acquisitionAssignmentObject;

      return (
        <>
          <div styleName="sidebar">
            <Sidebar />
          </div>
          <div styleName="body">
            <div styleName="timeline">
              <Timeline
                entityId={id}
                entityType="assignment"
                addNotes={true}
                includeTimelineEventsOfChildItems={true}
              />
            </div>
          </div>
        </>
      );
    }
  )
);
