import { AcquisitionAssignment } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionDetailSidebarComponent,
  AcquisitionDetailSidebarComponentProps
} from "./detail-sidebar";

type StateProps = {
  acquisition: AcquisitionAssignment;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailSidebarComponentProps,
  AppState
> = (state) => {
  const { acquisition } = state.acquisition.single;

  return {
    acquisition
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailSidebarComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path))
});

export type AcquisitionDetailSidebarContainerProps = StateProps & DispatchProps;
export const AcquisitionDetailSidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailSidebarComponent);
