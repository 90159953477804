import { RootEntityType } from "@haywork/api/event-center";
import { RelationSnapShot } from "@haywork/api/kolibri";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import Avatar from "./components/avatar";
import List from "./components/list";
import { PresenceContainerProps } from "./presence.container";

const styles = require("./style.scss");

export type PresenceComponentProps = {
  entityType: RootEntityType;
  entityId: string;
  avatarBorderColor?: string;
};
type Props = PresenceComponentProps & PresenceContainerProps;

export const PresenceComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ employees, employeeIds, avatarBorderColor, entityType }) => {
      const [currentEmployees, setCurrentEmployees] = useState(
        [] as RelationSnapShot[]
      );
      const [notify, setNotify] = useState(false);

      const visual = useMemo(() => {
        const count = currentEmployees.length;

        switch (count) {
          case 0: {
            return null;
          }
          case 1:
          case 2:
          case 3: {
            return (
              <div styleName="avatar__wrapper">
                {currentEmployees.map((relation) => (
                  <Avatar
                    relation={relation}
                    key={relation.id}
                    avatarBorderColor={avatarBorderColor}
                  />
                ))}
              </div>
            );
          }
          default: {
            const employees = currentEmployees.slice(0, 2);
            return (
              <div styleName="avatar__wrapper">
                {employees.map((relation) => (
                  <Avatar
                    relation={relation}
                    key={relation.id}
                    avatarBorderColor={avatarBorderColor}
                  />
                ))}
                <div styleName="avatar">
                  <div
                    styleName="avatar__count"
                    style={{ borderColor: avatarBorderColor }}
                  >
                    +{count - 2}
                  </div>
                </div>
              </div>
            );
          }
        }
      }, [currentEmployees, avatarBorderColor]);

      const notifier = useMemo(() => {
        return (
          <div styleName="notify" style={{ borderColor: avatarBorderColor }} />
        );
      }, [avatarBorderColor]);

      const filterEmployees = useCallback(
        (employeeIds: string[]) => {
          const currentEmployees = employees.filter((employee) =>
            employeeIds.includes(employee.id)
          );
          setCurrentEmployees(currentEmployees);
        },
        [employees, setCurrentEmployees]
      );

      useEffect(() => {
        filterEmployees(employeeIds);
      }, [employeeIds, filterEmployees]);

      useEffect(() => {
        if (!employeeIds.length) {
          setNotify(false);
          return;
        }

        setNotify(true);
        setTimeout(() => setNotify(false), 6000);
      }, [employeeIds, setNotify]);

      return (
        <div styleName="wrapper">
          {visual}
          <List relations={currentEmployees} entityType={entityType} />
          {notify && notifier}
        </div>
      );
    }
  )
);
