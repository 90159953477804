import { RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentsClient,
  DocumentSession,
  DocumentSessionOrderByField,
  DocumentSessionsClient,
  DocumentSessionSnapShot,
  DocumentTemplateOrderByField,
  DocumentTemplatesClient,
  DocumentTemplatesSearchRequest,
  SortOrder,
} from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  DynamicDocumentsListActions,
  DynamicDocumentsSingleActions,
  EditableActions,
  LayoutActions,
} from "@haywork/stores";
import { DynamicDocumentsPreviewActions } from "@haywork/stores/dynamic-documents/preview";
import {
  DynamicDocumentsSingleState,
  ListOfGoodsValueExtended,
} from "@haywork/stores/dynamic-documents/single";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { DynamicDocumentsUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import first from "lodash-es/first";
import has from "lodash-es/has";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

export enum TypeOfLinkedRelation {
  linkedApplicant = "linkedApplicant",
  linkedVendor = "linkedVendor",
  linkedPropertyManager = "linkedPropertyManager",
  linkedNotary = "linkedNotary",
}

const getDocumentTemplates = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      DynamicDocumentsListActions.setDocumentTemplatesStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new DocumentTemplatesClient(host);
    const take = 100;

    const request: DocumentTemplatesSearchRequest = {
      order: SortOrder.Descending,
      skip: 0,
      take,
      orderBy: DocumentTemplateOrderByField.RecentlyUsed,
      forSale: false,
      forRent: false,
      isActive: ActiveFilter.ActiveOnly,
    };

    try {
      const response = await parseRequest.response(
        client.search(request, realEstateAgencyId)
      );

      if (!response.results) return;
      let documentTemplates = response.results;

      if (response.totalResults > take) {
        const diff = response.totalResults - take;
        const count = Math.ceil(diff / take);

        for (let i = 1; i <= count; i++) {
          const response = await parseRequest.response(
            client.search(
              { ...request, skip: i * take, take },
              realEstateAgencyId
            )
          );

          documentTemplates = [...documentTemplates, ...response.results];
        }
      }

      dispatch(
        DynamicDocumentsListActions.setDocumentTemplates({ documentTemplates })
      );
    } catch (error) {
      dispatch(
        DynamicDocumentsListActions.setDocumentTemplatesStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getDocument = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);
    const DocumentTemplates = new DocumentTemplatesClient(host);

    try {
      const session = await parseRequest.response(
        DocumentSessions.read(id, realEstateAgencyId).then(
          (response) => response.documentSession
        )
      );

      const template = await parseRequest.response(
        DocumentTemplates.read(
          session.linkedDocumentTemplate.id,
          realEstateAgencyId
        ).then((response) => response.documentTemplate)
      );

      let listOfGoods: ListOfGoodsValueExtended = null;

      if (session.listOfGoods) {
        listOfGoods = DynamicDocumentsUtil.getExtendedListOfGoods(
          session.listOfGoods
        );
        listOfGoods = DynamicDocumentsUtil.filterEmptyValues(listOfGoods);
      }

      dispatch(
        DynamicDocumentsSingleActions.setDocument({
          session,
          template,
          listOfGoods,
        })
      );
    } catch (error) {
      dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const downloadDocument = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const props = {
      id,
      access_token: state.access.token,
    };

    const url = `${host}/${apiVersion}/${realEstateAgencyId}/DocumentSessions/Download?${RouteUtil.mapObjectToGetParams(
      props
    )}`;
    window.open(url, "_blank");
  };
};

const archiveDocument = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      await parseRequest.response(
        DocumentSessions.archive({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveDocument = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      await parseRequest.response(
        DocumentSessions.unarchive({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const createDynamicDocument = (templateId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const DocumentSessions = new DocumentSessionsClient(host);
    const DocumentTemplates = new DocumentTemplatesClient(host);

    // let session: DocumentSession, template: DocumentTemplate;

    try {
      let session = await parseRequest.response(
        DocumentSessions.defineNew(
          { documentTemplateId: templateId },
          realEstateAgencyId
        ).then((response) => response.documentSession)
      );

      const template = await parseRequest.response(
        DocumentTemplates.read(
          session.linkedDocumentTemplate.id,
          realEstateAgencyId
        ).then((response) => response.documentTemplate)
      );

      let listOfGoods = DynamicDocumentsUtil.getExtendedListOfGoods(
        session.listOfGoods
      );
      listOfGoods = DynamicDocumentsUtil.filterEmptyValues(listOfGoods);

      session = {
        ...session,
        formElementValues: DynamicDocumentsUtil.mapTemplateToFormReturnValues(
          template,
          session
        ),
      };

      const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id: session.id });
      const componentState = {
        session,
        template,
        listOfGoods,
      };

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.DYNAMICDOCUMENTS.ICON,
          componentState,
          path,
          title: template.name,
          confirm: {
            title: { key: "saveDynamicDocumentConfirmTitle" },
            body: { key: "saveDynamicDocumentConfirmBody" },
          },
          entityType: RootEntityType.DocumentSession,
          entityId: session.id,
        })
      );

      dispatch(push(route(DYNAMICDOCUMENTROUTES.INFO.URI, { id: session.id })));
      dispatch(
        DynamicDocumentsSingleActions.setDocument({
          session,
          template,
          listOfGoods,
        })
      );
    } catch (error) {
      dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveDynamicDocument = (close: boolean = false, redirectTo?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.PENDING));

    const state = getState();
    const { account, editable } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;

    const currentComponentState: DynamicDocumentsSingleState =
      editable.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: currentComponentState.session.id,
    });
    let documentSession = DynamicDocumentsUtil.prepareSessionForSave(
      currentComponentState.session
    );

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      if (documentSession.isNew) {
        const existing = await parseRequest.response(
          DocumentSessions.search(
            {
              orderBy: DocumentSessionOrderByField.CreationDate,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              documentSessionId: documentSession.id,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          documentSession = {
            ...documentSession,
            isNew: false,
          };
        }
      }

      let session = await parseRequest.response(
        DocumentSessions.save({ documentSession }, realEstateAgencyId).then(
          (response) => response.documentSession
        )
      );

      session = {
        ...session,
        formElementValues: DynamicDocumentsUtil.mapTemplateToFormReturnValues(
          currentComponentState.template,
          session
        ),
      };

      dispatch(DynamicDocumentsSingleActions.setDocumentSession({ session }));

      if (close) {
        dispatch(EditableThunks.remove(path));
        dispatch(LayoutActions.toggleFullscreen(false));
        return;
      }

      if (!!redirectTo) {
        dispatch(push(redirectTo));
      }

      const componentState = { ...currentComponentState, session };
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(DynamicDocumentsSingleActions.setDocumentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateDocumentSession = (
  session: DocumentSession,
  refreshPreview: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;

    if (!has(currentComponentState, "session.id")) return;

    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: currentComponentState.session.id,
    });

    const componentState: DynamicDocumentsSingleState = {
      ...currentComponentState,
      session,
    };

    dispatch(EditableActions.updateComponentState({ componentState, path }));
    if (refreshPreview) {
      dispatch(getHtmlPreview());
    }
  };
};

const getHtmlPreview = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      DynamicDocumentsPreviewActions.setHtmlPreviewRefreshStatus(
        REQUEST.PENDING
      )
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { session }: DynamicDocumentsSingleState =
      state.editable.currentComponentState;
    const documentSession = {
      ...session,
    };

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      const { htmlRender: preview, visibleFormElements } =
        await parseRequest.response(
          DocumentSessions.htmlLivePreview(
            { documentSession },
            realEstateAgencyId
          )
        );

      dispatch(
        DynamicDocumentsPreviewActions.refreshHtmlPreview({
          preview,
          visibleFormElements,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateSessionListOfGoods = (
  listOfGoodsValue: ListOfGoodsValueExtended
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const previousComponentState: DynamicDocumentsSingleState =
      state.editable.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: previousComponentState.session.id,
    });
    const listOfGoods = state.dynamicDocuments.single.listOfGoods;

    const newListOfGoods = DynamicDocumentsUtil.updateListOfGoods(
      listOfGoodsValue,
      listOfGoods
    );

    const convertedListOfGoods =
      DynamicDocumentsUtil.convertToNormalListOfGoods(newListOfGoods);

    const componentState = {
      ...previousComponentState,
      session: {
        ...previousComponentState.session,
        listOfGoods: convertedListOfGoods,
      },
      listOfGoods: newListOfGoods,
    };

    dispatch(EditableActions.updateComponentState({ componentState, path }));
  };
};

const addEmptyValueToListOfGoods = (
  listOfGoodsValue: ListOfGoodsValueExtended
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const previousComponentState: DynamicDocumentsSingleState =
      state.editable.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: previousComponentState.session.id,
    });
    const listOfGoods = state.dynamicDocuments.single.listOfGoods;

    const newListOfGoods = DynamicDocumentsUtil.addEmptyValue(
      listOfGoodsValue,
      listOfGoods
    );

    const convertedListOfGoods =
      DynamicDocumentsUtil.convertToNormalListOfGoods(newListOfGoods);

    const componentState = {
      ...previousComponentState,
      session: {
        ...previousComponentState.session,
        listOfGoods: convertedListOfGoods,
      },
      listOfGoods: newListOfGoods,
    };

    dispatch(EditableActions.updateComponentState({ componentState, path }));
  };
};

const deleteFromListOfGoods = (listOfGoodsValue: ListOfGoodsValueExtended) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const previousComponentState: DynamicDocumentsSingleState =
      state.editable.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: previousComponentState.session.id,
    });
    const listOfGoods = { ...state.editable.currentComponentState };

    const newListOfGoods = DynamicDocumentsUtil.removeFromListOfGoods(
      listOfGoodsValue,
      listOfGoods.listOfGoods
    );

    const convertedListOfGoods =
      DynamicDocumentsUtil.convertToNormalListOfGoods(newListOfGoods);

    const componentState = {
      ...previousComponentState,
      session: {
        ...previousComponentState.session,
        listOfGoods: convertedListOfGoods,
      },
      listOfGoods: newListOfGoods,
    };

    dispatch(EditableActions.updateComponentState({ componentState, path }));
  };
};

const refreshObjectAssignment = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    let componentState: DynamicDocumentsSingleState =
      state.editable.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
      id: componentState.session.id,
    });

    const Assignments = new AssignmentsClient(host);

    if (!componentState.session.linkedAssignment) return;

    dispatch(
      EditableActions.setCalleeState({ path, calleeState: REQUEST.PENDING })
    );

    try {
      const assignments = await parseRequest.response(
        Assignments.search(
          {
            assignmentIds: [componentState.session.linkedAssignment.id],
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take: 0,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (assignments.length > 0) {
        const linkedAssignment = first(assignments);
        componentState = {
          ...componentState,
          session: {
            ...componentState.session,
            linkedAssignment,
          },
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState,
            path,
            ignoreChanges: true,
          })
        );
      }

      dispatch(EditableActions.removeCallee(path));
    } catch (error) {
      dispatch(EditableActions.removeCallee(path));
      throw error;
    }
  };
};

const deleteDocument = (id: string, undeleteCallback?: () => void) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      await parseRequest.response(
        DocumentSessions.delete(id, realEstateAgencyId)
      );

      if (!!undeleteCallback) {
        dispatch(
          SnackbarActions.addToast({
            value: "document.toast.deleted",
            callback: async () => {
              await dispatch(unDeleteDocument(id));
              undeleteCallback();
            },
            callbackLabel: "document.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteDocument = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      await parseRequest.response(
        DocumentSessions.undelete({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateDocumentSessionMeta = (snapshot: DocumentSessionSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const DocumentSessions = new DocumentSessionsClient(host);

    try {
      const item = await parseRequest.response(
        DocumentSessions.read(snapshot.id, realEstateAgencyId).then(
          (response) => response.documentSession
        )
      );

      if (!!item) {
        await parseRequest.response(
          DocumentSessions.patch(
            item.id,
            {
              documentSession: {
                name: snapshot.name,
              },
            },
            realEstateAgencyId
          )
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

export const DynamicDocumentsThunks = {
  getDocumentTemplates,
  getDocument,
  downloadDocument,
  archiveDocument,
  unArchiveDocument,
  createDynamicDocument,
  updateDocumentSession,
  getHtmlPreview,
  saveDynamicDocument,
  refreshObjectAssignment,
  updateSessionListOfGoods,
  deleteFromListOfGoods,
  addEmptyValueToListOfGoods,
  deleteDocument,
  updateDocumentSessionMeta,
};
