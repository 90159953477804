import { File } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import {
  EMAIL_ATTACHMENTS_BLACKLIST,
  EXTERNALROUTES,
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { FileUtil, MimeCategory } from "@haywork/util";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import last from "lodash-es/last";

const styles = require("./style.scss");

type Props = {
  file: File;
  onDelete: (id: string) => void;
};

export const CheckItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ file, onDelete }) => {
    const { id, contentType, fileName, size } = file;

    const icon = useMemo(() => {
      const detailedMimeType = FileUtil.getDetailedMime(contentType);
      let name = "file";

      switch (detailedMimeType.category) {
        case MimeCategory.Audio: {
          name = "file-audio";
          break;
        }
        case MimeCategory.Image: {
          name = "file-image";
          break;
        }
        case MimeCategory.Video: {
          name = "file-video";
          break;
        }
        case MimeCategory.Word: {
          name = "file-word";
          break;
        }
        case MimeCategory.Excel: {
          name = "file-excel";
          break;
        }
        case MimeCategory.PowerPoint: {
          name = "file-powerpoint";
          break;
        }
        case MimeCategory.Code: {
          name = "file-code";
          break;
        }
        case MimeCategory.PDF: {
          name = "file-pdf";
          break;
        }
        default:
          break;
      }

      return <Icon name={name} size={18} light color={Colors.Black} />;
    }, [contentType]);

    const fileSize = useMemo(() => {
      return FileUtil.getFileSize(size);
    }, [size]);

    const extension = useMemo(() => {
      if (!file || !file.fileName) {
        return null;
      }

      const segments = file.fileName.split(".");
      if (!segments.length) {
        return null;
      }

      return last(segments).toUpperCase();
    }, [file]);

    const isBlackListed = useMemo(() => {
      if (!extension) {
        return false;
      }

      return EMAIL_ATTACHMENTS_BLACKLIST.includes(extension);
    }, [extension]);

    return (
      <div styleName="item">
        <div styleName="item__icon">{icon}</div>
        <div styleName="item__name">
          {fileName || <I18n value="email.attachment.missingFileName" />}
          {isBlackListed && (
            <div styleName="blacklisted">
              <I18n
                value="email.attachment.blacklisted"
                values={{ type: extension }}
              />{" "}
              <a
                href={EXTERNALROUTES.SUPPORT_DOSSIER_UNSUPPORTED_ATTACHMENT.URI}
                target="_blank"
                rel="noopener"
              >
                <I18n value="readWhy" />
              </a>
            </div>
          )}
        </div>
        <div styleName="item__size">{fileSize}</div>
        {!isBlackListed ? (
          <div styleName="item__delete" onClick={() => onDelete(id)}>
            <Icon name="minus-circle" light size={18} color={Colors.Primary} />
          </div>
        ) : (
          <div styleName="item__warn">
            <Icon
              name="exclamation-triangle"
              light
              size={18}
              color={Colors.Warning}
            />
          </div>
        )}
      </div>
    );
  })
);
