import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { Event, EventType } from "@haywork/api/event-center";
import Icon from "@haywork/components/ui/icon";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  event: Event | null | undefined;
};

export const EventTypeIconComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ event }) => {
    const icon = useMemo(() => {
      if (!event) return <Icon name="bell" regular />;
      const { eventType } = event;

      switch (eventType) {
        case EventType.AccountCreated: {
          return <Icon name="user-plus" regular />;
        }
        case EventType.AccountDeleted: {
          return <Icon name="user-minus" regular />;
        }
        case EventType.AgendaItemReminder: {
          return <Icon name="bell-on" regular />;
        }
        case EventType.BankwarrantyExpired: {
          return <Icon name="file-contract" regular />;
        }
        case EventType.Birthday: {
          return <Icon name="birthday-cake" regular />;
        }
        case EventType.BrochureDownloaded: {
          return <Icon name="file-download" regular />;
        }
        case EventType.BundleMutationNew: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationWithdrawn: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationPriceChange: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationTransferred: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationUnderBid: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationUnderOption: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationUnderCondition: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationDeedSigned: {
          return <Icon name="exchange" regular />;
        }
        case EventType.BundleMutationAvailable: {
          return <Icon name="exchange" regular />;
        }
        case EventType.CompanyMessage: {
          return <Icon name="comment" regular />;
        }
        case EventType.ContactMeRequest: {
          return <Icon name="comment-alt-lines" regular />;
        }
        case EventType.IncomingBidExpired: {
          return <Icon name="comments-dollar" regular />;
        }
        case EventType.NvmOauthTokenExpired: {
          return <Icon name="key" regular />;
        }
        case EventType.OfferListing: {
          return <Icon name="sign" regular />;
        }
        case EventType.Other: {
          return <Icon name="comment-dots" regular />;
        }
        case EventType.OutgoingBidExpired: {
          return <Icon name="comments-dollar" regular />;
        }
        case EventType.PersonalMessage: {
          return <Icon name="comment" regular />;
        }
        case EventType.PublicationFailed: {
          return <Icon name="exclamation" solid />;
        }
        case EventType.PublicationSucceeded: {
          return <Icon name="check" regular />;
        }
        case EventType.Relocation: {
          return <Icon name="person-carry" regular />;
        }
        case EventType.RentReservationExpired: {
          return <Icon name="file-contract" regular />;
        }
        case EventType.RentedExpired: {
          return <Icon name="thumbs-up" regular />;
        }
        case EventType.RentedFromExpired: {
          return <Icon name="file-contract" regular />;
        }
        case EventType.RentedUntilExpired: {
          return <Icon name="sync-alt" regular />;
        }
        case EventType.ReservationExpired: {
          return <Icon name="file-contract" regular />;
        }
        case EventType.Searchprofile: {
          return <Icon name="search-plus" regular />;
        }
        case EventType.SendMailFailed: {
          return <Icon name="envelope" regular />;
        }
        case EventType.SendMailSuccess: {
          return <Icon name="envelope" regular />;
        }
        case EventType.SentEmailOpened: {
          return <Icon name="envelope-open" regular />;
        }
        case EventType.SoldExpired: {
          return <Icon name="thumbs-up" regular />;
        }
        case EventType.SystemMessage: {
          return <Icon name="comment" regular />;
        }
        case EventType.TaskReminder: {
          return <Icon name="bell-on" regular />;
        }
        case EventType.TransferExpired: {
          return <Icon name="handshake-alt" regular />;
        }
        case EventType.Valuation: {
          return <Icon name="money-check-alt" regular />;
        }
        case EventType.Viewing: {
          return <Icon name="laptop-house" regular />;
        }
        case EventType.ViewingRequest: {
          return <Icon name="home-heart" regular />;
        }
        case EventType.DocumentSigned: {
          return <Icon name="signature" regular></Icon>;
        }
        case EventType.DocumentSignDeclined: {
          return <Icon name="signature" regular></Icon>;
        }
        case EventType.DocumentSignExpired: {
          return <Icon name="signature" regular></Icon>;
        }
        default: {
          return <Icon name="bell" regular />;
        }
      }
    }, [event]);

    const category = useMemo(() => {
      if (!event) return null;
      const { eventType } = event;

      switch (eventType) {
        case EventType.SendMailSuccess:
        case EventType.AccountCreated:
        case EventType.PublicationSucceeded:
        case EventType.SoldExpired:
        case EventType.RentedExpired:
        case EventType.DocumentSigned: {
          return "success";
        }
        case EventType.Birthday:
        case EventType.AgendaItemReminder:
        case EventType.TaskReminder: {
          return "info";
        }
        case EventType.BankwarrantyExpired:

        case EventType.IncomingBidExpired:
        case EventType.NvmOauthTokenExpired:
        case EventType.OutgoingBidExpired:
        case EventType.RentReservationExpired:

        case EventType.RentedFromExpired:
        case EventType.RentedUntilExpired:
        case EventType.ReservationExpired:
        case EventType.TransferExpired:
        case EventType.DocumentSignDeclined:
        case EventType.DocumentSignExpired: {
          return "warning";
        }
        case EventType.SendMailFailed:
        case EventType.PublicationFailed: {
          return "danger";
        }
        default: {
          return "primary";
        }
      }
    }, [event]);

    return <div styleName={classNames("icon", category)}>{icon}</div>;
  })
);
