import * as PropTypes from "prop-types";
import * as React from "react";

interface BaseFilterComponentProps {
  name: string;
  value?: any;
}
interface BaseFilterComponentState {
  value: any;
}

export abstract class BaseFilter<P, S> extends React.Component<
  P & BaseFilterComponentProps,
  BaseFilterComponentState & any
> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired
  };

  constructor(props: P & BaseFilterComponentProps) {
    super(props);
    this.state = { value: this.props.value || null };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidMount() {
    this.context.registerField(this.props.name, this.state.value);
  }

  public componentWillUnmount() {
    this.context.unRegisterField(this.props.name, this.state.value);
  }

  public onChangeHandler(event: any) {
    const value = event.target.value;
    this.setState({ value });
  }

  public onBlurHandler(event: any) {
    this.setValue(event.target.value);
  }

  public setValue(value: string) {
    this.context.updateField(this.props.name, value);
  }
}
