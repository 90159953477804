import {
  ActiveFilter,
  AssignmentPhase,
  AvailabilityStatus,
  LinkedRelation,
  ObjectAssignment,
  RealEstateGroup,
  RelationSnapShot,
  SaleConditionOption,
  UpdateAvailabilitySoldSettings,
  RelationType,
} from "@haywork/api/kolibri";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { DateUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import noop from "lodash-es/noop";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../../transaction-modal.component.scss");

interface Props {
  saleConditions: SaleConditionOption[];
  assignment: ObjectAssignment;
  onUpdate: (
    soldSettings: UpdateAvailabilitySoldSettings,
    valid: boolean
  ) => void;
  onInitialValidState: (valid: boolean) => void;
  submitTransactionData: boolean;
}
interface State {
  soldUnderReservation: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

@CSSModules(styles, { allowMultiple: true })
export class TransactionSaleForm extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { saleOffer, linkedApplicants, availabilityStatus } =
      this.props.assignment;

    const {
      salePrice,
      purchasePrice,
      dateTransfer,
      endDateBankWarranty,
      dateReservation,
      dateSold,
      agreementDate,
    } = saleOffer;

    const priceFromAssignment = purchasePrice || null;
    const timeSold = !!dateSold ? moment(dateSold).format("HH:mm") : "09:00";
    const soldUnderReservation =
      !!dateReservation ||
      availabilityStatus === AvailabilityStatus.Available ||
      availabilityStatus === AvailabilityStatus.UnderBid;

    this.state = {
      soldUnderReservation,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedApplicants: {
        value: linkedApplicants || [],
        validators: [
          Validators.arrayMinCount(1, { key: "missingLinkedApplicants" }),
        ],
      },
      salePrice: {
        value: priceFromAssignment,
        validators: [
          Validators.required(),
          Validators.priceDifference(salePrice, {
            key: "priceDifferenceToLargeSale",
          }),
        ],
      },
      // saleCondition: { value: saleCondition },
      dateTransfer: {
        value: dateTransfer,
        validators: [Validators.required()],
      },
      endDateBankWarranty: { value: endDateBankWarranty },
      soldUnderReservation: {
        value: soldUnderReservation,
        onChange: (ref) => {
          this.setState({ soldUnderReservation: ref.value });
          if (!ref.value) {
            return {
              dateReservation: "",
            };
          }
        },
      },
      dateReservation: {
        value: dateReservation || "",
        validators: [
          Validators.required(
            undefined,
            () => !this.state.soldUnderReservation
          ),
        ],
      },
      dateSold: { value: dateSold, validators: [Validators.required()] },
      timeSold: { value: timeSold, validators: [Validators.required()] },
      agreementDate: { value: agreementDate },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !this.props.submitTransactionData &&
      !!nextProps.submitTransactionData &&
      !!this.formRef
    ) {
      this.formRef.submit();
    }
  }

  public render() {
    const { assignmentPhase, realEstateGroup } = this.props.assignment;

    return (
      <React.Fragment>
        <Form
          name="transaction-sale"
          formControls={this.formControls}
          form={this.bindFormRef}
          onChange={this.onChangeHandler}
          onSubmit={noop}
        >
          {assignmentPhase === AssignmentPhase.Concept && (
            <div styleName="info-block full">
              <i className="fal fa-info-circle" />
              <ResourceText resourceKey="soldModalInfo" asHtml={true} />
            </div>
          )}

          <div styleName="inner">
            <div className="form__row">
              <label htmlFor="linkedApplicants">
                <ResourceText resourceKey="saleTransaction.linkedApplicants" />
              </label>
              <Input.RelationQuery
                name="linkedApplicants"
                filterByActive={ActiveFilter.ActiveOnly}
                multiple={true}
                onAdd={this.addNewRelation}
                filterByRelationTypes={[
                  RelationType.ContactPerson,
                  RelationType.ContactCompany,
                ]}
              />
            </div>

            <div className="form__divider" />

            <div className="form__row">
              <div className="form__group stretch">
                <div className="column">
                  <label htmlFor="salePrice">
                    <ResourceText resourceKey="saleTransaction.salePrice" />{" "}
                    <Ui.RequiredForPublish message="saleTransaction.salePrice.info" />
                  </label>
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number name="salePrice" pretty={true} />
                  </div>
                </div>
                {/* <div className="column__spacer" />
                <div className="column fit push-label">
                  <Input.RadioGroup name="saleCondition" asButtonList>
                    {this.props.saleConditions.map((saleCondition, idx) => (
                      <Input.Radio
                        label={saleCondition.displayName}
                        value={saleCondition.value}
                        key={idx}
                      />
                    ))}
                  </Input.RadioGroup>
                </div> */}
              </div>
            </div>

            <div className="form__row">
              <div className="form__group stretch">
                <div className="column">
                  <label htmlFor="dateTransfer">
                    <ResourceText resourceKey="saleTransaction.dateTransfer" />
                  </label>
                  <Input.Datepicker name="dateTransfer" />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <label htmlFor="endDateBankWarranty">
                    <ResourceText resourceKey="saleTransaction.endDateBankWarranty" />
                  </label>
                  <Input.Datepicker name="endDateBankWarranty" />
                </div>
              </div>
            </div>

            <div className="form__divider" />

            <div className="form__row">
              <div className="form__group">
                <div className="column fit">
                  <Input.Switch
                    name="soldUnderReservation"
                    label="saleTransaction.soldUnderReservation"
                    labelPosition={SwitchLabelPosition.Pre}
                    on={true}
                    off={false}
                  />
                </div>
                <div className="column__spacer" />
                {!!this.state.soldUnderReservation && (
                  <React.Fragment>
                    <div className="column__textspacer">
                      <label htmlFor="dateReservation">
                        <ResourceText resourceKey="saleTransaction.dateReservation" />
                      </label>
                    </div>
                    <div className="column">
                      <Input.Datepicker name="dateReservation" />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

            {this.state.soldUnderReservation && (
              <div styleName="info-block">
                <i className="fal fa-info-circle" />
                <ResourceText resourceKey="saleTransaction.dateReservationInfo" />
              </div>
            )}

            {realEstateGroup === RealEstateGroup.Agricultural && (
              <>
                <div className="form__divider" />

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label htmlFor="agreementDate">
                        <ResourceText resourceKey="saleTransaction.agreementDate" />
                      </label>
                      <Input.Datepicker
                        name="agreementDate"
                        hour={0}
                        minutes={0}
                        seconds={0}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="form__divider" />

            <div className="form__row">
              <label htmlFor="dateSold">
                <ResourceText resourceKey="saleTransaction.dateSold" />
              </label>
              <div className="form__group">
                <div className="column">
                  <Input.Datepicker name="dateSold" />
                </div>
                <div className="column__textspacer">
                  <label htmlFor="timeSold">
                    <ResourceText resourceKey="saleTransaction.timeSold" />
                  </label>
                </div>
                <div className="column">
                  <Input.Time name="timeSold" />
                </div>
              </div>
            </div>

            <div styleName="info-block">
              <i className="fal fa-info-circle" />
              <ResourceText
                resourceKey={
                  this.state.soldUnderReservation
                    ? "saleTransaction.dateSoldUnderReservationInfo"
                    : "saleTransaction.dateReservationInfo"
                }
              />
            </div>
          </div>
        </Form>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </React.Fragment>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
      this.props.onInitialValidState(this.formRef.isValid());
      if (!!this.props.submitTransactionData) {
        this.formRef.submit();
      }
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    let { linkedApplicants } = this.props.assignment;
    const { realEstateGroup } = this.props.assignment;
    linkedApplicants = linkedApplicants || [];

    if (!!values.linkedApplicants) {
      linkedApplicants = [
        ...values.linkedApplicants.map(
          (linkedApplicant) =>
            ({
              id: linkedApplicant.id,
              displayName: linkedApplicant.displayName,
              typeOfRelation: linkedApplicant.typeOfRelation,
            } as LinkedRelation)
        ),
      ];
    }

    let settings: UpdateAvailabilitySoldSettings = {
      dateOfTransfer: values.dateTransfer || null,
      dateReservation: !values.soldUnderReservation
        ? null
        : values.dateReservation || null,
      dateAgreement: null,
      dateSold: DateUtil.dateAndTimeToDate(values.dateSold, values.timeSold),
      endDateBankWarranty: values.endDateBankWarranty || null,
      linkedApplicants,
      transactionPrice: values.salePrice,
      isSaleAndLeaseBack: null,
    };

    if (realEstateGroup === RealEstateGroup.Agricultural) {
      settings = {
        ...settings,
        dateAgreement: values.agreementDate,
      };
    }

    this.props.onUpdate(settings, this.formRef.isValid());
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedApplicants } = this.formRef.getValues();
    linkedApplicants = linkedApplicants || [];

    this.formRef.update({
      linkedApplicants: [...linkedApplicants, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
