import {
  CivilStateOption,
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressTypeOption,
  IdentificationTypeOption,
  Language,
  LanguageSpokenOption,
  Office,
  PhoneNumberTypeOption,
  RealEstateAssociation,
} from "@haywork/api/kolibri";
import { OFFICESROUTES } from "@haywork/constants";
import { Dispatch, OfficeThunk } from "@haywork/middleware";
import {
  OfficeEditComponent,
  OfficeEditComponentProps,
} from "@haywork/modules/settings/modules/offices/components/edit";
import { AppState, EditableActions, OfficeActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgencyId: string;
  selectedOfficeId: string;
  office: Office;
  realEstateAssociation: string;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  contactPersonTitleSuggestions: ContactPersonTitleSuggestionOption[];
  civilStateOptions: CivilStateOption[];
  identificationTypeOptions: IdentificationTypeOption[];
  saveOfficeState: string;
  addressSearchState: string;
  countries: CountryOption[];
  path: string;
  languages: Language[];
  spokenLanguages: LanguageSpokenOption[];
  latestSpokenLanguageAdded: LanguageSpokenOption;
  defaultLanguage: Language;
}

interface DispatchProps {
  saveOffice: (office: Office, path: string) => void;
  navigate: (routePath: string) => void;
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) => void;
  removeAddress: (isPostalAddress: boolean, id: string) => void;
  togglePostalAddress: (id: string) => void;
  changeTabTitle: (title: string, path: string) => void;
  updateOffice: (componentState: Office, path: string) => void;
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    relationId: string
  ) => void;
  addManualLanguage: (newLanguage: string, relationId: string) => void;
  addSpokenLanguage: (newSpokenLanguage) => void;
  resetSearchState: () => void;
}

const mapStateToProps = <StateProps, OfficeEditComponentProps>(
  state: AppState
) => {
  return {
    office: state.editable.currentComponentState,
    realEstateAssociation: getRealEstateAssociation(state),
    selectedOfficeId: state.offices.selectedOffice.id,
    countries: state.main.mastertable.kolibri.countries,
    phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
    emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
    contactPersonTitleSuggestions:
      state.main.mastertable.kolibri.contactPersonTitleSuggestions,
    civilStateOptions: state.main.mastertable.kolibri.civilStates,
    realEstateAgencyId: state.account.currentRealestateAgency.id,
    identificationTypeOptions:
      state.main.mastertable.kolibri.identificationTypes,
    saveOfficeState: state.offices.saveOfficeInfoState,
    addressSearchState: state.offices.saveOfficeAddressInfoState,
    path: RouteUtil.mapStaticRouteValues(OFFICESROUTES.OFFICE_DETAIL.URI, {
      id: state.offices.selectedOffice.id,
    }),
    languages: state.company.settings.enabledLanguages,
    spokenLanguages: state.main.mastertable.kolibri.languages,
    latestSpokenLanguageAdded: state.offices.latestSpokenLanguageAdded,
    defaultLanguage: state.company.settings.defaultLanguage,
  };
};

const mapDispatchToProps = <DispatchProps, OfficeEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveOffice: (office: Office, path: string) =>
    dispatch(OfficeThunk.saveOffice(office, path)),
  navigate: (routePath) => dispatch(push(routePath)),
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) => dispatch(OfficeThunk.searchAddress(value, country, isPostalAddress, id)),
  removeAddress: (isPostalAddress: boolean, id: string) =>
    dispatch(OfficeThunk.removeOfficeAddress(isPostalAddress, id)),
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    relationId: string
  ) =>
    dispatch(
      OfficeThunk.addManualAddress(newAddress, isPostalAddress, relationId)
    ),
  addManualLanguage: (newLanguage, relationId) =>
    dispatch(OfficeThunk.addManualLanguage(newLanguage, relationId)),
  togglePostalAddress: (id: string) =>
    dispatch(OfficeThunk.toggleRelationPostalAddress(id)),
  changeTabTitle: (title: string, path: string) =>
    dispatch(EditableActions.updateTabTitle({ title, path })),
  updateOffice: (componentState: Office, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  addSpokenLanguage: (addSpokenLanguage) =>
    dispatch(OfficeActions.addSpokenLanguage(addSpokenLanguage)),
  resetSearchState: () => dispatch(OfficeActions.resetSearchState()),
});

const getRealEstateAssociation = (state: AppState): string => {
  let realEstateAssociation =
    state.main.mastertable.kolibri.realEstateAssociations.find(
      (a) =>
        a.value ===
        state.realEstateAgency.realEstateAgency.associationInfo
          .realEstateAssociation
    );

  if (!realEstateAssociation) {
    realEstateAssociation =
      state.main.mastertable.kolibri.realEstateAssociations.find(
        (a) => a.value === RealEstateAssociation.Unknown
      );
  }
  return realEstateAssociation.displayName;
};

export type OfficeContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const OfficeEditContainer = injectIntl(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeEditComponent)
);
