import * as React from "react";
import { FC, memo, useCallback } from "react";
import { DetailMediaContainerProps } from "./detail-media.container";
import PageHeader from "@haywork/components/ui/page-header";
import * as CSSModules from "react-css-modules";
import EmptyState from "@haywork/components/ui/empty-state";
import I18n from "@haywork/components/i18n";
import AttachmentCmp from "./components/attachment";
import { Attachment, FileType } from "@haywork/api/mls";
import { urlToPhotoBlob } from "@haywork/util/url";

const styles = require("./style.scss");
const validTypes = [
  FileType.BMP,
  FileType.GIF,
  FileType.JPG,
  FileType.PNG,
  FileType.YOUTUBE,
];

export type DetailMediaComponentProps = {};
type Props = DetailMediaComponentProps & DetailMediaContainerProps;

export const DetailMediaComponent: FC<Props> = memo(
  CSSModules(styles)(({ attachments, openLightbox }) => {
    const onClickCallback = useCallback(
      (attachment: Attachment) => {
        const {
          fileType,
          fileTypeNormalizedFile,
          urlOriginalFile,
          urlNormalizedFile,
          urlMediumFile,
        } = attachment;
        const type = fileTypeNormalizedFile || fileType;
        const image = urlNormalizedFile || urlMediumFile || urlOriginalFile;
        const imageMatch = /\.(jpe?g|gif|png|bmp)/i;

        switch (true) {
          case validTypes.includes(type) || imageMatch.test(image): {
            const slides = attachments.reduce<string[]>((state, group) => {
              const valid = (group.attachments || [])
                .filter((a) => {
                  const {
                    fileTypeNormalizedFile,
                    fileType,
                    urlOriginalFile,
                    urlNormalizedFile,
                    urlMediumFile,
                  } = a;
                  const type = fileTypeNormalizedFile || fileType;
                  const image =
                    urlNormalizedFile || urlMediumFile || urlOriginalFile;

                  return (
                    !!image &&
                    (imageMatch.test(image) || validTypes.includes(type))
                  );
                })
                .map(
                  (a) =>
                    a.urlNormalizedFile || a.urlMediumFile || a.urlOriginalFile
                );

              if (!!valid.length) {
                state = [...state, ...valid];
              }

              return state;
            }, []);

            const index = slides.indexOf(image) || 0;
            openLightbox(slides.map(urlToPhotoBlob), index);

            break;
          }
          default: {
            if (!urlOriginalFile) return;
            window.open(urlOriginalFile, "_blank");
            break;
          }
        }
      },
      [openLightbox]
    );

    return (
      <div styleName="media">
        <PageHeader
          title="mls.pageHeader.media"
          subTitle="mls.pageHeader.general"
        />
        <div styleName="media__body">
          {!attachments.length && (
            <EmptyState
              icon="photo-video"
              title="mls.emptyStateMlsMedia.title"
            />
          )}

          {attachments.map((group) => (
            <div key={group.type.toString()} styleName="group">
              <div styleName="group__name">
                <I18n
                  prefix="attachmentTypeOptions"
                  value={group.type.toString()}
                />
              </div>
              <div styleName="group__items">
                {(group.attachments || []).map((attachment, idx) => (
                  <AttachmentCmp
                    key={
                      attachment.foreignID || `${group.type.toString()}-${idx}`
                    }
                    attachment={attachment}
                    onClick={onClickCallback}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  })
);
