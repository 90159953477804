import { AppState } from "@haywork/stores";
import * as moment from "moment";
import { createSelector } from "reselect";

const agendaItemsSelector = (state: AppState) => state.scheduler.agendaItems;
const layoutSelector = (state: AppState) => state.layout;

const agendaItemCategorySelector = (state: AppState) =>
  state.scheduler.agendaItemCategories;

export const sideBarToggled = createSelector(
  layoutSelector,
  (layout) => layout.sidebarCollapsed
);

export const agendaItemsSelected = createSelector(
  agendaItemsSelector,
  (agendaItems) => {
    if (!agendaItems) return null;

    const items = agendaItems.map((agendaItem) => {
      agendaItem.startDateTime = new Date(
        moment(agendaItem.startDateTime)
          .seconds(0)
          .milliseconds(0)
          .toISOString()
      );
      return agendaItem;
    });

    return items;
  }
);

export const agendaItemCategories = createSelector(
  agendaItemCategorySelector,
  (categories) => (categories && categories.length > 0 ? categories : [])
);
