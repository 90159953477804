import { Status } from "@haywork/stores";
import * as ActionType from "./list.types";
import { REDUX } from "@haywork/constants";

const setDocumentTemplatesStatus = (documentTemplatesStatus: string) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_TEMPLATES_STATUS,
  documentTemplatesStatus
});

const setDocumentTemplates = (payload: ActionType.DocumentTemplatesResponse) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_TEMPLATES,
  ...payload
});

export const DynamicDocumentsListActions = {
  setDocumentTemplatesStatus,
  setDocumentTemplates
};
