import {
  Filter,
  FilterConfig,
  FilterSection,
  ListFilter
} from "@haywork/components/ui/list";
import * as React from "react";
import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from "react";
import { List } from "react-virtualized";
import { FiltersContainerProps } from "./filters.container";

export type FiltersComponentProps = {
  list: MutableRefObject<List>;
};
type Props = FiltersComponentProps & FiltersContainerProps;

export const FiltersComponent: FC<Props> = memo(
  ({
    filters,
    list,
    setFilters,
    personalNotificationValues,
    generalNotificationValues,
    typeFilterValues,
    eventNotificationDateTimeValue
  }) => {
    const updateFilters = useCallback(
      (newFilters: FilterConfig) => {
        if (
          (filters.typeFilters.value.includes("processed") &&
            newFilters.typeFilters.value.includes("general")) ||
          (filters.typeFilters.value.includes("processed") &&
            newFilters.typeFilters.value.includes("personal"))
        ) {
          newFilters.typeFilters.value = newFilters.typeFilters.value.filter(
            (i) => i !== "processed"
          );
          newFilters = {
            ...newFilters,
            typeFilters: {
              ...newFilters.typeFilters,
              value: [...newFilters.typeFilters.value]
            }
          };
        }

        if (newFilters.typeFilters.value.includes("processed")) {
          newFilters.typeFilters.value = newFilters.typeFilters.value.filter(
            (i) => i === "processed"
          );
          newFilters = {
            ...newFilters,
            typeFilters: {
              ...newFilters.typeFilters,
              value: [...newFilters.typeFilters.value]
            }
          };
        }

        setFilters(newFilters);
        if (!list || !list.current) return;
        list.current.forceUpdate();
      },
      [list, setFilters, filters]
    );

    return (
      <ListFilter filters={filters} onChange={updateFilters}>
        <FilterSection title="notifications.filter.title.typeFilters">
          <Filter.CheckboxArray
            configKey="typeFilters"
            values={typeFilterValues}
          />
        </FilterSection>

        <FilterSection title="notifications.filter.title.personalNotifications">
          <Filter.CheckboxArray
            configKey="personalNotifications"
            values={personalNotificationValues}
            sortByAlphabet
          />
        </FilterSection>

        <FilterSection title="notifications.filter.title.generalNotifications">
          <Filter.CheckboxArray
            configKey="generalNotifications"
            values={generalNotificationValues}
            sortByAlphabet
          />
        </FilterSection>

        <FilterSection title="notifications.filter.title.eventNotificationDateTime">
          <Filter.DateRange
            configKey="eventNotificationDateTime"
            value={eventNotificationDateTimeValue}
          />
        </FilterSection>
      </ListFilter>
    );
  }
);
