import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { accountFolders } from "../../selectors";
import {
  FolderContextComponent,
  FolderContextComponentProps
} from "./folder-context";

type StateProps = {
  folders: EmailFolder[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  FolderContextComponentProps,
  AppState
> = (state, ownProps) => {
  return {
    folders: accountFolders({ ...state, accountId: ownProps.accountId })
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FolderContextComponentProps
> = (dispatch) => ({});

export type FolderContextContainerProps = StateProps & DispatchProps;
export const FolderContextContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderContextComponent);
