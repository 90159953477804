import { PhotoBlob } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { EMPLOYEEROUTES } from "@haywork/constants";
import {
  AvatarUploadTriggerComponent,
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  EmployeeDetailContainerProps,
  EmployeeDetailRouting,
} from "@haywork/modules/settings";
import { ObjectTimestamps, ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./employee-detail.component.scss");

export interface EmployeeDetailComponentProps {}
type Props = EmployeeDetailComponentProps & EmployeeDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmployeeDetailComponent extends React.Component<Props> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      avatar: {
        value: FormControlUtil.returnObjectPathOrNull(
          this.props.selectedEmployee,
          "passportPhotoBlob"
        ),
      },
    };

    this.avatarChange = this.avatarChange.bind(this);
    this.renderMenuLink = this.renderMenuLink.bind(this);
  }

  public componentDidMount() {
    if (!this.props.selectedEmployee) return;
    this.props.setTabTitle(
      this.props.selectedEmployee.displayName
        ? this.props.selectedEmployee.displayName
        : intlContext.formatMessage({ id: "newEmployee", defaultMessage: "" })
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: EmployeeDetailComponentProps & EmployeeDetailContainerProps
  ) {
    if (!nextProps) return;
    if (
      this.props.selectedEmployee &&
      nextProps.selectedEmployee &&
      this.props.selectedEmployee.displayName !==
        nextProps.selectedEmployee.displayName &&
      this.props.selectedEmployeeId === nextProps.selectedEmployeeId
    ) {
      this.props.setTabTitle(
        nextProps.selectedEmployee.displayName
          ? nextProps.selectedEmployee.displayName
          : intlContext.formatMessage({
              id: "newRelation",
              defaultMessage: "newRelation",
            })
      );
    }

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      const { selectedEmployee } = nextProps;
      const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, {
        id: selectedEmployee.id,
      });
      this.props.saveEmployee(selectedEmployee, path);
    }
  }

  public render() {
    const inEditMode = this.props.location.pathname.match(/edit/);
    const id = this.props.selectedEmployeeId;

    return (
      <div styleName="detailContainer">
        {/*<NotesContainer parentId={id} />*/}
        <div styleName="sideBar">
          <div styleName="avatar" className="hidden-xs hidden-sm">
            {
              <Form
                name="employeeDetailAvatar"
                formControls={this.formControls}
                onChange={this.avatarChange}
              >
                <Input.Avatar name="avatar">
                  <AvatarUploadTriggerComponent />
                </Input.Avatar>
              </Form>
            }
          </div>
          <div styleName="menu">
            <div styleName="menu">
              {!inEditMode &&
                this.renderMenuLink(
                  route(EMPLOYEEROUTES.EMPLOYEE_GENERAL.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral"
                )}
              {!!inEditMode &&
                this.renderMenuLink(
                  route(EMPLOYEEROUTES.EMPLOYEE_EDIT.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral",
                  true
                )}
              {this.renderMenuLink(
                route(EMPLOYEEROUTES.EMPLOYEE_ASSIGNMENT.URI, { id }),
                "fa-align-left",
                "tabAssignments"
              )}
              {this.renderMenuLink(
                route(EMPLOYEEROUTES.EMPLOYEE_DOSSIER.URI, { id }),
                "fa-users",
                "tabDossier"
              )}
            </div>
          </div>
          <ObjectTimestamps
            linkedCreatedBy={this.props.selectedEmployee.linkedCreatedBy}
            linkedModifiedBy={this.props.selectedEmployee.linkedModifiedBy}
            dateTimeCreated={this.props.selectedEmployee.dateTimeCreated}
            dateTimeModified={this.props.selectedEmployee.dateTimeModified}
          />
        </div>
        <div styleName="detailContent">
          <EmployeeDetailRouting id={id} />
        </div>
      </div>
    );
  }

  private renderMenuLink(
    path: string,
    icon: string,
    label: string,
    overrideDisabled: boolean = false
  ) {
    const isNewOrEdit =
      !overrideDisabled &&
      (this.props.selectedEmployee.isNew ||
        this.props.location.pathname.match(/edit/));
    const itemStyle = classNames({ disabled: isNewOrEdit });
    const iconStyle = classNames("fal fa-fw", icon);

    return !isNewOrEdit ? (
      <NavLink
        styleName="item"
        className={itemStyle}
        activeClassName="active"
        to={path}
      >
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </NavLink>
    ) : (
      <div styleName="item" className={itemStyle}>
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </div>
    );
  }

  private onDisabledClick(event: React.MouseEvent<HTMLElement>) {
    if (this.props.selectedEmployee.isNew) {
      event.preventDefault();
    }
  }

  private avatarChange(values: FormReturnValue) {
    const avatar: PhotoBlob = values.avatar;
    this.props.updateEmployeePassportPhoto(
      avatar ? avatar : null,
      this.props.selectedEmployee.id
    );
  }
}
