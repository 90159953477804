import { REDUX } from "@haywork/constants";
import * as ActionType from "./access.types";

const setInitial = (payload: ActionType.Initial) => ({
  type: REDUX.ACCESS.SET_INITIAL_ACCESS,
  ...payload
});

const setToken = (payload: ActionType.AccessToken) => ({
  type: REDUX.ACCESS.UPDATE_TOKEN,
  ...payload
});

const logout = () => ({
  type: REDUX.ACCESS.LOGOUT
});

export const AccessActions = {
  setInitial,
  setToken,
  logout
};
