import {
  AcceptanceDetails,
  AcceptanceOption,
  CommissionPaidByOption,
  CommissionTypeOption,
  Language,
  LeaseTermUnitOption,
  ObjectAssignment,
  PriceCode,
  PriceCodeOption,
  RentCondition,
  RentConditionOption,
  RentOffer,
  RentSpecificationOption,
  VatConditionOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditFinancialRentAlvComponent,
  AssignmentEditFinancialRentAlvComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  rentOffer: RentOffer;
  vatConditions: VatConditionOption[];
  acceptanceDetails: AcceptanceDetails;
  acceptances: AcceptanceOption[];
  leaseTermUnitOptions: LeaseTermUnitOption[];
  rentConditions: RentConditionOption[];
  hidePrice: boolean;
  priceCodes: PriceCodeOption[];
  commissionPaidByOptions: CommissionPaidByOption[];
  commissionTypeOptions: CommissionTypeOption[];
  languages: Language[];
  defaultLanguage: Language;
  rentSpecifications: RentSpecificationOption[];
  objectAssignment: ObjectAssignment;
  enableHidePriceForObjectAssignmentsOption: boolean;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditFinancialRentAlvComponentProps,
  AppState
> = (state) => {
  const { objectAssignment } = state.editable.currentComponentState;
  const {
    rentOffer,
    acceptanceDetails,
    hidePrice,
  }: ObjectAssignment = objectAssignment;
  const {
    acceptances,
    leaseTermUnitOptions,
    vatConditions,
    commissionPaidByOptions,
    commissionTypeOptions,
    rentSpecifications,
  } = state.main.mastertable.kolibri;
  const { enableHidePriceForObjectAssignmentsOption } = state.company.settings;

  const priceCodes = filterRentPriceCodes(
    state.main.mastertable.kolibri.priceCodes
  );
  const rentConditions = filterRentConditions(
    state.main.mastertable.kolibri.rentConditions
  );

  return {
    acceptanceDetails,
    rentOffer,
    acceptances,
    leaseTermUnitOptions,
    rentConditions,
    hidePrice,
    vatConditions,
    priceCodes,
    commissionPaidByOptions,
    commissionTypeOptions,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage,
    rentSpecifications,
    objectAssignment,
    enableHidePriceForObjectAssignmentsOption,
  };
};

const filterRentPriceCodes = (
  priceCodes: PriceCodeOption[]
): PriceCodeOption[] => {
  const filteredPriceCodes = priceCodes.filter(
    (priceCode) =>
      priceCode.value === PriceCode.ToBeNegotiated ||
      priceCode.value === PriceCode.OnRequest
  );

  const askingPriceOption = {
    ...priceCodes.find(
      (priceCode) => priceCode.value === PriceCode.AskingPrice
    ),
  };
  askingPriceOption.displayName = intlContext.formatMessage({
    id: "leasePrice",
  });
  filteredPriceCodes.unshift(askingPriceOption);

  return filteredPriceCodes;
};

const filterRentConditions = (
  rentConditions: RentConditionOption[]
): RentConditionOption[] => {
  const filteredRentConditions = rentConditions.filter(
    (rentCondition) =>
      rentCondition.value === RentCondition.PricePerYear ||
      rentCondition.value === RentCondition.PricePerMonth
  );

  return filteredRentConditions;
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditFinancialRentAlvComponentProps
> = (dispatch) => ({});

export type AssignmentEditFinancialRentAlvContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditFinancialRentAlvContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditFinancialRentAlvComponent);
