import * as React from "react";
import { FC, memo, useMemo } from "react";
import I18n from "@haywork/components/i18n";
import { Input, Validators } from "@haywork/modules/form";
import get from "lodash-es/get";
import {
  Bid,
  RelationType,
  AssignmentType,
  BidType,
  LinkedRelation
} from "@haywork/api/kolibri";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import RelationsToNotify from "../relations-to-notify";

type Props = {
  bid: Bid;
  originalBid?: Bid;
  loading: boolean;
  relationsToNotify?: LinkedRelation[];
  onUpdate: (bid: Bid) => void;
  onSave: () => void;
};

export const OfferComponent: FC<Props> = memo(
  ({ loading, bid, originalBid, relationsToNotify, onUpdate, onSave }) => {
    if (!bid) return null;

    const { type } = bid;

    const onUpdateBid = (field: string, value: any) => {
      onUpdate({
        ...bid,
        [field]: value
      });
    };

    const orignalBidValue = useMemo(() => {
      return get(originalBid, "amount", null);
    }, []);

    const showLinkedRelations = useMemo(() => {
      return type === BidType.Incoming;
    }, [type]);

    const showLinkedEmployee = useMemo(() => {
      return type === BidType.Outgoing;
    }, [type]);

    const bidValue = useMemo(() => {
      return get(bid, "amount", null) === 0 ? null : get(bid, "amount", null);
    }, [bid]);

    const validUntilError = useMemo(() => {
      const { bidDateTime, validUntil } = bid;
      if (!bidDateTime || !validUntil) return null;
      const message = Validators.dateShouldBeAfter(bidDateTime, null, {
        key: "bid.error.dateShouldBeInFuture"
      })(validUntil);
      return message;
    }, [bid]);

    return (
      <>
        <ModalBody>
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="bidDateTime">
                  <I18n value="bid.modal.label.bidDateTime" />
                </label>
                <Input.Datepicker
                  value={get(bid, "bidDateTime", "")}
                  name="bidDateTime"
                  asSingleInput
                  onChange={(value) => onUpdateBid("bidDateTime", value)}
                  disabled={loading}
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="amount">
                  <I18n value="bid.modal.label.amount" />
                </label>
                <div className="input__helper">
                  <div className="pre">&euro;</div>
                  <Input.Number
                    value={bidValue || orignalBidValue}
                    name="amount"
                    asSingleInput
                    onChange={(value) => onUpdateBid("amount", value)}
                    disabled={loading}
                    pretty
                    min={1}
                  />
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="validUntil">
                  <I18n value="bid.modal.label.validUntil" />
                </label>
                <Input.Datepicker
                  value={get(bid, "validUntil", "")}
                  name="validUntil"
                  asSingleInput
                  onChange={(value) => onUpdateBid("validUntil", value)}
                  disabled={loading}
                />
                {!!validUntilError && (
                  <div className="form__errors">
                    <div className="error">
                      <I18n
                        value={validUntilError.key}
                        values={validUntilError.values}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {showLinkedRelations && (
            <div className="form__row">
              <label htmlFor="linkedRelations">
                <I18n value="bid.modal.label.linkedRelations" />
              </label>
              <Input.RelationQuery
                value={get(bid, "linkedRelations", [])}
                name="linkedRelations"
                filterByRelationTypes={[
                  RelationType.ContactPerson,
                  RelationType.ContactCompany
                ]}
                asSingleInput
                onChange={(value) => onUpdateBid("linkedRelations", value)}
                disabled={loading}
                multiple
              />
            </div>
          )}

          {showLinkedEmployee && (
            <div className="form__row">
              <label htmlFor="linkedEmployee">
                <I18n value="bid.modal.label.linkedEmployee" />
              </label>
              <Input.RelationQuery
                value={get(bid, "linkedEmployee", "")}
                name="linkedEmployee"
                filterByRelationTypes={[RelationType.Employee]}
                asSingleInput
                onChange={(value) => onUpdateBid("linkedEmployee", value)}
                disabled={loading}
              />
            </div>
          )}

          <div className="form__row">
            <label htmlFor="description">
              <I18n value="bid.modal.label.description" />
            </label>
            <Input.Textarea
              value={get(bid, "description", "")}
              name="description"
              asSingleInput
              autoSize
              minHeight={64}
              onChange={(value) => onUpdateBid("description", value)}
              disabled={loading}
            />
          </div>

          {!!relationsToNotify && !!relationsToNotify.length && (
            <RelationsToNotify relations={relationsToNotify} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label="bid.modal.save"
            disabled={loading || !bidValue}
            onClick={onSave}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
          />
        </ModalFooter>
      </>
    );
  }
);
