import {
  ActiveFilter,
  SearchAssignmentOrderByField,
  SearchAssignmentSnapShot,
  SortOrder,
  PaidFilter,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { SearchAssignmentOrder } from ".";
import { ActionType, ActionTypes } from "./actions";
import { FilterType } from "@haywork/enum/list-filter-types";

export type ListState = {
  searchAssignments: SearchAssignmentSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  order: SearchAssignmentOrder;
  scrollOffset: number;
};

const INITIAL: ListState = {
  searchAssignments: [],
  totalCount: 0,
  order: {
    sortOrder: SortOrder.Ascending,
    sortColumn: SearchAssignmentOrderByField.CreationDate,
  },
  filters: {
    realEstateGroups: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "realEstateGroups",
    },
    assignmentPhases: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "assignmentPhases",
    },
    saleOrRent: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    archived: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    isPaid: {
      type: FilterType.Select,
      value: PaidFilter.PaidOrNotPaid,
      emptyValue: undefined,
    },
    matchMailPeriods: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "matchMailPeriodOptions",
    },
    typePARTOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "typePARTOptions",
    },
    typeBOGOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "typeBOGOptions",
    },
    typeALVOptions: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "typeALVOptions",
    },
  },
  scrollOffset: 0,
};

export const listReducer = (state = INITIAL, action: ActionTypes) => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, startIndex } = action;

      let searchAssignments = [...state.searchAssignments];
      if (!searchAssignments.length) {
        searchAssignments = new Array(totalCount).fill(null);
      }

      for (let i = 0; i < items.length; i++) {
        searchAssignments[i + startIndex] = items[i];
      }

      return {
        ...state,
        searchAssignments,
        totalCount,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        searchAssignments: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        searchAssignments: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;

      const searchAssignments = state.searchAssignments.map(
        (searchAssignment) =>
          !!searchAssignment && searchAssignment.id === snapshot.id
            ? snapshot
            : searchAssignment
      );

      return {
        ...state,
        searchAssignments,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
