import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionListComponent,
  AcquisitionListComponentProps
} from "./list";

type StateProps = {
  assignments: AssignmentSnapShot[];
  assignmentsStatus: string;
  total: number;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getAcquisitions: (startIndex: number, stopIndex: number) => Promise<void>;
  createAcquisition: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionListComponentProps,
  AppState
> = (state) => {
  const { assignments, assignmentsStatus, total } = state.acquisition.list;

  return {
    assignments,
    assignmentsStatus,
    total
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionListComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getAcquisitions: (startIndex: number, stopIndex: number) =>
    dispatch(
      AcquisitionThunks.Acquisition.getAcquisitions(startIndex, stopIndex)
    ),
  createAcquisition: () =>
    dispatch(AcquisitionThunks.Acquisition.createAcquisition())
});

export type AcquisitionListContainerProps = StateProps & DispatchProps;
export const AcquisitionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionListComponent);
