import has from "lodash-es/has";
import first from "lodash-es/first";

import { EventNotification, EventType, Event } from "@haywork/api/event-center";
import {
  PERSONAL_EVENT_TYPES,
  PERSONAL_EVENT_TYPES_IMMUTABLE,
} from "@haywork/constants";
import { EventNotificationActionType } from "@haywork/modules/event-center/components/modal-message/footer.component";

export enum EventNotificationsFilter {
  Personal = "Personal",
  General = "General",
  Processed = "Processed",
}

export interface ExtendedEventNotification extends EventNotification {
  filter: EventNotificationsFilter;
  icon: string;
  actionTypes: EventNotificationActionType[];
}

export class EventCenter {
  public static enrichEventNotification(
    notification: EventNotification
  ): ExtendedEventNotification {
    let filter, icon, actionTypes;

    if (!!notification.linkedEvent.archivedDate) {
      filter = EventNotificationsFilter.Processed;
    } else {
      filter =
        [...PERSONAL_EVENT_TYPES, ...PERSONAL_EVENT_TYPES_IMMUTABLE].indexOf(
          notification.linkedEvent.eventType
        ) !== -1
          ? EventNotificationsFilter.Personal
          : EventNotificationsFilter.General;
    }

    switch (notification.linkedEvent.eventType) {
      case EventType.AccountCreated:
        actionTypes = [
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.CreateRelation,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
        ];
        icon = "fa fa-user-plus";
        break;
      case EventType.BankwarrantyExpired:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-file-contract";
        break;
      case EventType.Birthday:
        actionTypes = [
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
        ];
        icon = "fa fa-birthday-cake";
        break;
      case EventType.BrochureDownloaded:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateRelation,
          EventNotificationActionType.OpenRelation,
        ];
        icon = "fa fa-file-download";
        break;
      case EventType.ContactMeRequest:
        actionTypes = [
          EventNotificationActionType.CreateRelation,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
          EventNotificationActionType.OpenRelation,
        ];
        icon = "fa fa-envelope";
        break;
      case EventType.NvmOauthTokenExpired:
        actionTypes = [
          EventNotificationActionType.RefreshCollegialListingToken,
        ];
        icon = "fa fa-ticket-alt";
        break;
      case EventType.OfferListing:
        actionTypes = [
          EventNotificationActionType.CreateRelation,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
        ];
        icon = "fa fa-sign";
        break;
      case EventType.OutgoingBidExpired:
        actionTypes = [EventNotificationActionType.OpenAcquisitionAssignment];
        icon = "fa fa-comments-alt-dollar";
        break;
      case EventType.IncomingBidExpired:
        actionTypes = [EventNotificationActionType.OpenAcquisitionAssignment];
        icon = "fa fa-comments-alt-dollar";
        break;
      case EventType.Other:
        actionTypes = [];
      default:
        actionTypes = [];
        icon = "fa fa-ellipsis-h";
        break;
      case EventType.PublicationFailed:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-exclamation";
        break;
      case EventType.PersonalMessage:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.OpenSearchAssignment,
          EventNotificationActionType.OpenAppointment,
        ];
        icon = "fa fa-comment";
        break;
      case EventType.PublicationSucceeded:
        actionTypes = [EventNotificationActionType.OpenAssignment];
        icon = "fa fa-check";
        break;
      case EventType.RentedFromExpired:
        actionTypes = [];
        icon = "fa fa-grin-beam";
        break;
      case EventType.RentedUntilExpired:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
        ];
        icon = "far fa-sync-alt";
        break;
      case EventType.ReservationExpired:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-file-contract";
        break;
      case EventType.RentReservationExpired:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-file-contract";
        break;
      case EventType.Searchprofile:
        actionTypes = [
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
          EventNotificationActionType.OpenSearchAssignment,
        ];
        icon = "far fa-search";
        break;
      case EventType.SoldExpired:
        actionTypes = [];
        icon = "fa fa-grin-beam";
        break;
      // case EventType.SystemMessage:
      case EventType.CompanyMessage:
        actionTypes = [EventNotificationActionType.OpenLink];
        icon = "fa fa-megaphone";
        break;
      case EventType.Valuation:
        actionTypes = [
          EventNotificationActionType.OpenAppointment,
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-home";
        break;
      case EventType.Viewing:
        actionTypes = [
          EventNotificationActionType.OpenAppointment,
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.CreateTask,
        ];
        icon = "fa fa-home";
        break;
      case EventType.ViewingRequest:
        actionTypes = [
          EventNotificationActionType.OpenRelation,
          EventNotificationActionType.CreateRelation,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
          EventNotificationActionType.CreateEmail,
        ];
        icon = "fa fa-home";
        break;
      case EventType.AccountDeleted:
        actionTypes = [];
        icon = "fa fa-user-times";
        break;
      case EventType.TransferExpired:
        actionTypes = [
          EventNotificationActionType.OpenAssignment,
          EventNotificationActionType.CreateTask,
          EventNotificationActionType.CreateAppointment,
        ];
        icon = "fa fa-box-open";
        break;
      case EventType.SendMailFailed:
        actionTypes = [EventNotificationActionType.OpenDraft];
        icon = "far fa-envelope";
        break;
      case EventType.SendMailSuccess:
        actionTypes = [];
        icon = "far fa-envelope";
        break;
      case EventType.SentEmailOpened:
        actionTypes = [];
        icon = "far fa-envelope-open-text";
        break;
      case EventType.BundleMutationAvailable:
      case EventType.BundleMutationDeedSigned:
      case EventType.BundleMutationNew:
      case EventType.BundleMutationPriceChange:
      case EventType.BundleMutationTransferred:
      case EventType.BundleMutationUnderBid:
      case EventType.BundleMutationUnderCondition:
      case EventType.BundleMutationUnderOption:
      case EventType.BundleMutationWithdrawn:
        actionTypes = [];
        icon = "far fa-exchange";
        break;
    }

    return {
      ...notification,
      filter,
      icon,
      actionTypes,
    };
  }

  public static hasName(event: Event): boolean {
    const { linkedMessages } = event;
    return (
      !!linkedMessages &&
      !!linkedMessages.length &&
      (has(first(linkedMessages), "firstName") ||
        has(first(linkedMessages), "lastName"))
    );
  }

  public static getName(event: Event): string {
    const { linkedMessages } = event;
    const linkedMessage = first(linkedMessages);

    return [linkedMessage.firstName, linkedMessage.lastName]
      .filter((n) => !!n)
      .join(" ");
  }
}
