import {
  EntityDetails,
  EventCenterChangeDetails,
  NotificationDetails,
  RootEntityType,
} from "@haywork/api/event-center";
import { Account } from "@haywork/api/mail";
import {
  EmailAccountThunks,
  EventCenterThunk,
  MapDispatchToProps,
  ReminderThunks,
} from "@haywork/middleware/thunk";
import { AppState, EventCenterActions, PresentEmployee } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { editables, currentEmailId } from "../../selectors";
import {
  EventCenterComponent,
  EventCenterComponentProps,
} from "./event-center";
import { NotificationsThunk } from "@haywork/middleware/thunk/notifications";

type StateProps = {
  employeeId: string;
  editables: {
    type: RootEntityType;
    id: string;
    openedOn: Date;
    inEditMode: boolean;
  }[];
  version: string;
  features: string[];
  guid: string;
  currentEmailId: string;
};
type DispatchProps = {
  handleEventCenterChangeDetails: (
    eventCenterChangeDetails?: EventCenterChangeDetails
  ) => Promise<void>;
  getEmailAccounts: () => Promise<Account[]>;
  getReminders: () => Promise<void>;
  handleRootEntityChange: (entityDetails?: EntityDetails) => Promise<void>;
  getUnreadCount: () => Promise<void>;
  showNotificationToast: (details: NotificationDetails) => void;
  enterPresence: (employee: PresentEmployee) => void;
  updatePresence: (employee: PresentEmployee) => void;
  removePresence: (id: string, guid: string) => void;
  getStatistics: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  EventCenterComponentProps,
  AppState
> = (state) => {
  const { id: employeeId } = state.account.employee;
  const { version } = state.appSettings;
  const { features } = state.appSettings;
  const { guid } = state.eventCenter.presence;

  return {
    employeeId,
    editables: editables(state),
    version,
    features,
    guid,
    currentEmailId: currentEmailId(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EventCenterComponentProps
> = (dispatch) => ({
  handleEventCenterChangeDetails: (
    eventCenterChangeDetails?: EventCenterChangeDetails
  ) =>
    dispatch(
      EventCenterThunk.handleEventCenterChangeDetails(eventCenterChangeDetails)
    ),
  getEmailAccounts: () => dispatch(EmailAccountThunks.getAccounts(true)),
  getReminders: () => dispatch(ReminderThunks.getReminders()),
  handleRootEntityChange: (entityDetails?: EntityDetails) =>
    dispatch(EventCenterThunk.handleRootEntityChange(entityDetails)),
  getUnreadCount: () => dispatch(EventCenterThunk.getUnreadCount()),
  showNotificationToast: (details: NotificationDetails) =>
    dispatch(EventCenterThunk.showNotificationToast(details)),
  enterPresence: (employee: PresentEmployee) =>
    dispatch(EventCenterActions.Presence.addEmployee(employee)),
  updatePresence: (employee: PresentEmployee) =>
    dispatch(EventCenterActions.Presence.updateEmployee(employee)),
  removePresence: (id: string, guid: string) =>
    dispatch(EventCenterActions.Presence.removeEmployee(id, guid)),
  getStatistics: () => dispatch(NotificationsThunk.EventCenter.getStatistics()),
});

export type EventCenterContainerProps = StateProps & DispatchProps;
export const EventCenterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCenterComponent);
