import * as React from "react";
import * as CSSModules from "react-css-modules";

import { Ui } from "@haywork/modules/ui";
import { AgendaItemCategorySnapShot, AgendaItemCategoryTypeOption, AgendaStandardDurationOption } from "@haywork/api/kolibri";
import { FeatureHelper } from "@haywork/modules/feature-switch";

import { ItemForm } from "../form";

const styles = require("./item.component.scss");

interface Props {
  features: string[];
  zebra: boolean;
  category: AgendaItemCategorySnapShot;
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  onChange: (category: AgendaItemCategorySnapShot) => Promise<void>;
  onDelete: (category: AgendaItemCategorySnapShot) => void;
  onEdit: (category: AgendaItemCategorySnapShot) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerItemComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
  }

  public render() {
    return (
      <Ui.ActionListItem actions={this.renderListActions()} zebra={this.props.zebra}>
        <div styleName="item">
          <ItemForm
            category={this.props.category}
            agendaItemCategoryTypes={this.props.agendaItemCategoryTypes}
            agendaStandardDurationOptions={this.props.agendaStandardDurationOptions}
            onChange={this.props.onChange}
          />
        </div>
      </Ui.ActionListItem>
    );
  }

  private renderListActions() {
    const deleteButton = () => (
      <button className="btn btn-danger" data-cy="CY-deleteCategory" onClick={this.onDeleteClickHandler}>
        <i className="fal fa-times" />
      </button>
    );

    const editButton = () => (
      <button className="btn btn-success" data-cy="CY-editCategory" onClick={this.onEditClickHandler}>
        <i className="fal fa-pencil" />
      </button>
    );

    return FeatureHelper.executeBlock(this.props.features, "EDIT_SCHEDULER_CATEGORY") ? [deleteButton, editButton] : [deleteButton];
  }

  private onDeleteClickHandler() {
    this.props.onDelete(this.props.category);
  }

  private onEditClickHandler() {
    this.props.onEdit(this.props.category);
  }
}
