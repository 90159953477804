import { Action } from "redux";
import first from "lodash-es/first";

import { REDUX } from "@haywork/constants";
import { Account } from "@haywork/api/mail";
import {
  PagedResults,
  StoreHelper,
  Status,
  IdentifierPayload
} from "@haywork/stores/helpers";

import * as ActionType from "./accounts.types";

export interface AccountsState {
  accounts: PagedResults<Account>;
  currentAccount: Account;
}

const INITIAL_STATE: AccountsState = {
  accounts: StoreHelper.createInitialPagedResult(),
  currentAccount: null
};

export const accountsReducer = (
  state: AccountsState = INITIAL_STATE,
  action: Action
): AccountsState => {
  switch (action.type) {
    case REDUX.EMAILV2.SET_ACCOUNTS_STATUS: {
      const accounts = {
        ...state.accounts,
        ...StoreHelper.setObjectStatus(<Status>action)
      };

      return {
        ...state,
        accounts
      };
    }
    case REDUX.EMAILV2.SET_ACCOUNTS: {
      const accounts = StoreHelper.setPagedResults(<ActionType.AccountsPayload>(
        action
      ));

      let currentAccount = accounts.results.find(
        (account) =>
          account.id ===
          (!!state.currentAccount ? state.currentAccount.id : null)
      );
      currentAccount = currentAccount || state.currentAccount;

      return {
        ...state,
        accounts,
        currentAccount
      };
    }
    case REDUX.EMAILV2.SET_CURRENT_ACCOUNT: {
      const { account: currentAccount } = <ActionType.AccountPayload>action;

      return {
        ...state,
        currentAccount
      };
    }
    case REDUX.EMAILV2.REMOVE_ACCOUNT: {
      const { id } = <IdentifierPayload>action;
      const results = state.accounts.results.filter(
        (account) => account.id !== id
      );

      let currentAccount = null;

      if (!!state.currentAccount && state.currentAccount.id !== id) {
        currentAccount = state.currentAccount;
      }

      if (!currentAccount && results.length > 0) {
        currentAccount = first(results);
      }

      return {
        ...state,
        accounts: {
          ...state.accounts,
          results
        },
        currentAccount
      };
    }
    case REDUX.EMAILV2.SET_ACCOUNT_SYNC_STATUS: {
      const { accountId, syncStatus } = <ActionType.SyncStatusPayload>action;
      const results = state.accounts.results.map((account) => {
        if (account.id === accountId) {
          return {
            ...account,
            syncStatus
          };
        }
        return account;
      });

      return {
        ...state,
        accounts: {
          ...state.accounts,
          results
        }
      };
    }
    case REDUX.EMAILV2.SET_CURRENT_BY_ID: {
      const { accountId } = <ActionType.AccountIdPayload>action;
      const currentAccount = state.accounts.results.find(
        (account) => account.id === accountId
      );

      if (!currentAccount) {
        return state;
      } else {
        return {
          ...state,
          currentAccount
        };
      }
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
