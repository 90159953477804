import * as React from "react";
import { CSSProperties, FC, memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  width: number;
  height: number;
  style?: CSSProperties;
};

export const ImageComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ width, height, style }) => {
    return (
      <div styleName="wrapper" style={style}>
        <div styleName="image" style={{ width, height }} />
      </div>
    );
  })
);
