import {
  AssignmentPhase,
  SearchAssignmentSnapShot,
  MatchMailPeriod,
  OfferType,
  RealEstateGroup,
  TypeAlv,
  TypeBOG,
  TypePART
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./search-assignment.types";

export interface SearchAssignmentsFilters {
  filterByRealEstateGroups: RealEstateGroup[] | null;
  partTypes: TypePART[] | null;
  bogTypes: TypeBOG[] | null;
  alvTypes: TypeAlv[] | null;
  assignmentPhases: AssignmentPhase[] | null;
  offerTypes: OfferType[] | null;
  matchmailPeriods: MatchMailPeriod[] | null;
}

export interface SelectedSearchAssignmentFilter {}

export enum SearchAssignmentSortedHeaderType {
  SearchersAsc = "SearchersAsc",
  SearchersDesc = "SearchersDesc",
  RentSaleAsc = "RentSaleAsc",
  RentSaleDesc = "RentSaleDesc",
  DateAsc = "DateAsc",
  DateDesc = "dateDesc",
  MatchmailAsc = "MatchmailAsc",
  MatchmailDesc = "MatchmailDesc"
}

export interface OverviewSearchAssignmentState {
  searchAssignments: SearchAssignmentSnapShot[];
  searchAssignmentsStatus: string;
  searchAssignmentsPage: number;
  searchAssignmentsPageCount: number;
  searchAssignmentsTotal: number;
  searchAssignmentsFilters: SearchAssignmentsFilters;
  selectedHeaderSort: SearchAssignmentSortedHeaderType;
}

const INITIAL_STATE: OverviewSearchAssignmentState = {
  searchAssignments: [],
  searchAssignmentsStatus: REQUEST.IDLE,
  searchAssignmentsFilters: {
    filterByRealEstateGroups: null,
    partTypes: null,
    bogTypes: null,
    alvTypes: null,
    assignmentPhases: [AssignmentPhase.Initiated],
    offerTypes: null,
    matchmailPeriods: null
  },
  searchAssignmentsPage: 0,
  searchAssignmentsPageCount: 0,
  searchAssignmentsTotal: 0,
  selectedHeaderSort: null
};

export const overviewSearchAssignmentReducer = (
  state: OverviewSearchAssignmentState = INITIAL_STATE,
  action: Action
): OverviewSearchAssignmentState => {
  switch (action.type) {
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS_STATUS: {
      const { searchAssignmentsStatus } = <ActionType.SearchAssignmentsStatus>(
        action
      );
      return { ...state, searchAssignmentsStatus };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS: {
      const { results, resultCount, totalResults, take, statistics } = <
        ActionType.SearchAssignmentsResponse
      >action;
      const searchAssignmentsPageCount = Math.ceil(totalResults / take);

      return {
        ...state,
        searchAssignments: results,
        searchAssignmentsStatus: REQUEST.SUCCESS,
        searchAssignmentsPage: 1,
        searchAssignmentsPageCount,
        searchAssignmentsTotal: totalResults
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.APPEND_SEARCH_ASSIGNMENTS: {
      const { results, totalResults, resultCount, take, statistics } = <
        ActionType.SearchAssignmentsResponse
      >action;
      const searchAssignmentsPageCount = Math.ceil(totalResults / take);
      const searchAssignmentsPage = state.searchAssignmentsPage + 1;

      return {
        ...state,
        searchAssignments: [...state.searchAssignments, ...results],
        searchAssignmentsStatus: REQUEST.SUCCESS,
        searchAssignmentsPage,
        searchAssignmentsPageCount,
        searchAssignmentsTotal: totalResults
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENTS_FILTERS: {
      const { searchAssignmentsFilters } = <ActionType.Filters>action;

      return { ...state, searchAssignmentsFilters };
    }
    case REDUX.SEARCH_ASSIGNMENT.CLEAR_ALL_SEARCHASSIGNMENTS_FILTERS: {
      return {
        ...state,
        searchAssignmentsFilters: {
          filterByRealEstateGroups: null,
          partTypes: null,
          bogTypes: null,
          alvTypes: null,
          assignmentPhases: null,
          offerTypes: null,
          matchmailPeriods: null
        }
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.CLEAR_SEARCH_ASSIGNMENTS_FILTER: {
      const { filter } = <ActionType.Filter>action;
      const reference = state.searchAssignmentsFilters[filter.filter];
      const values = reference.filter((item) => item !== filter.value);

      return {
        ...state,
        searchAssignmentsFilters: {
          ...state.searchAssignmentsFilters,
          [filter.filter]: values
        }
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SELECTED_ROW_HEADER: {
      const { selectedHeaderSort } = <ActionType.HeaderSort>action;
      return { ...state, selectedHeaderSort };
    }
    case REDUX.SEARCH_ASSIGNMENT.UPDATE_LIST_ITEM: {
      const { snapshot } = <ActionType.SnapShot>action;
      const searchAssignments = (state.searchAssignments || []).map(
        (searchAssignment) => {
          if (searchAssignment.id === snapshot.id) {
            return snapshot;
          }
          return searchAssignment;
        }
      );

      return {
        ...state,
        searchAssignments
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const searchAssignments = (state.searchAssignments || []).filter(
        (searchAssignment) => searchAssignment.id !== id
      );

      return {
        ...state,
        searchAssignments
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
