import {
  ApplicantTypeOption,
  ObjectAssignment,
  VendorTypeOption,
} from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { EditableCalleeType } from "@haywork/enum";
import {
  AssignmentThunks,
  EditableThunks,
  MapDispatchToProps,
} from "@haywork/middleware";
import {
  DynamicDocumentsInfoComponent,
  DynamicDocumentsInfoComponentProps,
} from "@haywork/modules/dynamic-documents";
import { AppState, EditableActions } from "@haywork/stores";
import { DynamicDocumentsSingleState } from "@haywork/stores/dynamic-documents/single";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { documentHasExternalChanges } from "./selectors";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  currentComponentState: DynamicDocumentsSingleState;
  realEstateAgencyId?: string;
  vendorTypeOptions: VendorTypeOption[];
  applicantTypeOptions: ApplicantTypeOption[];
  host: string;
  path: string;
  documentHasExternalChanges: boolean;
}
interface DispatchProps {
  navigateToCadastreWithCallback: (
    calleeType: EditableCalleeType,
    path: string,
    returnPath: string,
    id: string
  ) => void;
  updateDocumentSessionCache: (componentState, path: string) => void;
  navigate: (route: string) => void;
  getLinkedAssignmentRelations: (id: string) => Promise<ObjectAssignment>;
  closeDocumentSession: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsInfoComponentProps,
  AppState
> = (state) => {
  const currentComponentState = state.editable.currentComponentState;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
    id: currentComponentState.session.id,
  });

  return {
    currentComponentState,
    realEstateAgencyId,
    vendorTypeOptions: state.main.mastertable.kolibri.vendorTypeOptions,
    applicantTypeOptions: state.main.mastertable.kolibri.applicantTypeOptions,
    host,
    path,
    documentHasExternalChanges: documentHasExternalChanges(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsInfoComponentProps
> = (dispatch) => ({
  navigateToCadastreWithCallback: (
    calleeType: EditableCalleeType,
    path: string,
    returnPath: string,
    id: string
  ) =>
    dispatch(
      AssignmentThunks.navigateToCadastreWithCallback(
        calleeType,
        path,
        returnPath,
        id
      )
    ),
  updateDocumentSessionCache: (componentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        path,
        componentState,
      })
    ),
  navigate: (route: string) => dispatch(push(route)),
  getLinkedAssignmentRelations: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
  closeDocumentSession: (path: string) => dispatch(EditableThunks.remove(path)),
});

export type DynamicDocumentsInfoContainerProps = StateProps & DispatchProps;
export const DynamicDocumentsInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsInfoComponent);
