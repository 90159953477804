import * as React from "react";
import { FC, memo, useEffect } from "react";
import { SearchAssignmentDetailLinkedAssignmentsContainerProps } from "@haywork/modules/search-assignment";
import * as CSSModules from "react-css-modules";
import MultiList from "@haywork/components/ui/multi-list";
import EmptyRow from "./components/empty-row";
import EmptyState from "./components/empty-state";
import MlsRow from "./components/mls-row";
import MatchedPropertyRow from "./components/matched-property-row";
import { EmailAssignment } from "@haywork/util/email-v2";

const styles = require("./style.scss");

export type SearchAssignmentDetailLinkedAssignmentsComponentProps = {
  selectedAssignmentsIds: string[];
  inEditMode: boolean;
  clearSelectedAssignments: () => void;
  onToggleSelected: (assignment: EmailAssignment) => void;
};
type Props = SearchAssignmentDetailLinkedAssignmentsComponentProps &
  SearchAssignmentDetailLinkedAssignmentsContainerProps;

export const SearchAssignmentDetailLinkedAssignmentsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      searchAssignment,
      selectedAssignmentsIds,
      inEditMode,
      getMlsObjectsMatchingSearchAssignments,
      getSearchAssignmentMatchingProperties,
      navigate,
      clearSelectedAssignments,
      onToggleSelected,
    }) => {
      const { id } = searchAssignment;

      useEffect(() => {
        return () => {
          clearSelectedAssignments();
        };
      }, [clearSelectedAssignments]);

      return (
        <div styleName="matching-properties">
          <MultiList
            apis={[
              {
                name: "searchAssignmentMatchingProperties",
                endpoint: (startIndex, stopIndex) =>
                  getSearchAssignmentMatchingProperties(
                    id,
                    startIndex,
                    stopIndex
                  ),
                row: (props) => (
                  <MatchedPropertyRow
                    {...props}
                    selectedAssignmentsIds={selectedAssignmentsIds}
                    onNavigate={navigate}
                    onToggleSelected={onToggleSelected}
                    inEditMode={inEditMode}
                  />
                ),
              },
              {
                name: "searchAssignmentMls",
                endpoint: (startIndex, stopIndex) =>
                  getMlsObjectsMatchingSearchAssignments(
                    [{ id }],
                    startIndex,
                    stopIndex
                  ),
                row: (props) => (
                  <MlsRow
                    {...props}
                    selectedAssignmentsIds={selectedAssignmentsIds}
                    onNavigate={navigate}
                    onToggleSelected={onToggleSelected}
                    inEditMode={inEditMode}
                  />
                ),
              },
            ]}
            emptyRow={EmptyRow}
            emptyState={<EmptyState />}
            itemSize={150}
            initiallySelectedApi={
              inEditMode ? "searchAssignmentMatchingProperties" : undefined
            }
          />
        </div>
      );
    }
  )
);
