import * as React from "react";
import { FC, memo, useState, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import Icon from "../icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  intiallyCollapsed?: boolean;
  label: string;
  canCollaps?: boolean;
};

export const CollapsableRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, intiallyCollapsed, label, canCollaps }) => {
      const [collapsed, setCollapsed] = useState(intiallyCollapsed || false);

      const onClickCallback = useCallback(() => {
        if (!canCollaps) return;
        setCollapsed((collapsed) => !collapsed);
      }, [setCollapsed, canCollaps]);

      return (
        <div>
          <div
            onClick={onClickCallback}
            styleName={classNames("title", { collapsable: !!canCollaps })}
          >
            <div styleName="label">{label}</div>
            {!!canCollaps && (
              <div styleName="icon">
                <Icon
                  name={!collapsed ? "chevron-down" : "chevron-up"}
                  color={Colors.Gray}
                  light
                />
              </div>
            )}
          </div>
          {(!canCollaps || !collapsed) && <div>{children}</div>}
        </div>
      );
    }
  )
);
