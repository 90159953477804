import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  SearchAssignmentEditContactContainer,
  SearchAssignmentEditLinkedAssignmentsContainer,
  SearchAssignmentEditWhatContainer,
  SearchAssignmentEditWhereContainer,
  SearchAssignmentPublishContainer
} from "@haywork/modules/search-assignment";
import { RouteUtil } from "@haywork/util";

const route = RouteUtil.mapStaticRouteValues;
export const SearchAssignmentEditRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ SEARCHASSIGNMENTROUTES.EDIT.URI } render={ () => <Redirect to={ route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, { id }) } /> } />
    <Route exact path={ SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI } component={ SearchAssignmentEditWhereContainer } />
    <Route exact path={ SEARCHASSIGNMENTROUTES.EDIT_WHAT.URI } component={ SearchAssignmentEditWhatContainer } />
    <Route exact path={ SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI } component={ SearchAssignmentEditContactContainer } />
    <Route exact path={ SEARCHASSIGNMENTROUTES.EDIT_LINKED_ASSIGNMENTS.URI } component={ SearchAssignmentEditLinkedAssignmentsContainer } />
    <Route path={ SEARCHASSIGNMENTROUTES.PUBLISH.URI } component={ SearchAssignmentPublishContainer } />
  </Switch>;
};
