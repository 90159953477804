import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { AssignmentSnapShot, AssignmentType } from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import {
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  ACQUISITIONOBJECTROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./last-changed-assignments.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentProps {
  assignment: AssignmentSnapShot;
  onNavigate: (path: string) => void;
}

export const Assignment = CSSModules(styles, { allowMultiple: true })(
  ({ assignment, onNavigate }: AssignmentProps) => {
    const hasImage = !!assignment.photo1Url && assignment.photo1Url !== "";
    const photoStyles = !!hasImage
      ? { backgroundImage: `url(${JSON.stringify(assignment.photo1Url)})` }
      : null;
    let path;

    switch (assignment.typeOfAssignment) {
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: assignment.id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id: assignment.id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id: assignment.id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignment.id });
        break;
    }

    return (
      <div
        styleName="assignment"
        onClick={() => onNavigate(path)}
        data-cy="CY-widget-assignmentLink"
      >
        <div styleName="assignment__photo" style={photoStyles}>
          {!hasImage && (
            <div styleName="icon">
              <i className="fal fa-camera-retro" />
            </div>
          )}
        </div>
        <div styleName="assignment__label">
          {assignment.displayName ? (
            assignment.displayName
          ) : (
            <ResourceText resourceKey="noCadastralAddressFound" />
          )}
        </div>
      </div>
    );
  }
);
