import {
  GardenQualityOption,
  GardenTypeOption,
  OrientationOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import {
  RoomLayoutGardenFormComponent,
  RoomLayoutGardenFormComponentProps,
} from "../garden.component";

interface StateProps {
  gardenTypeOptions: GardenTypeOption[];
  gardenQualityOptions: GardenQualityOption[];
  orientations: OrientationOption[];
}
interface DispatchProps {}

const mapStateToProps = <StateProps, GardenComponentProps>(state: AppState) => {
  const {
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
  } = state.main.mastertable.kolibri;

  return {
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
  };
};

const mapDispatchToProps = <DispatchProps, GardenComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type GardenContainerProps = StateProps & DispatchProps;
export const GardenContainer = connect<
  StateProps,
  DispatchProps,
  RoomLayoutGardenFormComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RoomLayoutGardenFormComponent);
