import { HOUSENET_OFFER } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import head from "lodash-es/head";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DashboardWidgetHousenetAdContainerProps } from "./housenet-ad.container";

const styles = require("./style.scss");

export interface DashboardWidgetHousenetAdComponentProps {}
type Props = DashboardWidgetHousenetAdComponentProps &
  DashboardWidgetHousenetAdContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DashboardWidgetHousenetAdComponent extends React.PureComponent<
  Props
> {
  public render() {
    const { settings } = this.props;
    if (!settings || !!settings.housenetEnabled) return null;
    const { displayName, emailAddresses, phoneNumbers } = this.props.employee;
    const { name } = this.props.currentRealestateAgency;
    const email = head(
      (emailAddresses || []).map((email) => email.address).filter((d) => !!d)
    );
    const phone = head(
      (phoneNumbers || []).map((phone) => phone.number).filter((d) => !!d)
    );
    const uri = `https://forms.zohopublic.eu/wazzup/form/HousenetLightleadform/formperma/vJ4XSczHgW4yAzohusIrsNANWrvgdD0mUDKFq_RWKi0/?WZNAME=${displayName ||
      ""}&WZCOMPANY=${name || ""}&WZPHONE=${phone || ""}&WZEMAIL=${email ||
      ""}`;

    return (
      <div className="widget-wrapper">
        <article className="widget">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-tags" />
            </span>
            <span className="text">
              <ResourceText
                resourceKey="housenetAd.widgetTitle"
                values={{ offer: HOUSENET_OFFER }}
                asHtml={true}
              />
            </span>
          </header>
          <div className="content" styleName="content">
            <ResourceText
              resourceKey="housenetAd.widgetContent"
              values={{ offer: HOUSENET_OFFER }}
              asHtml={true}
            />
          </div>
          <footer>
            <a
              href={uri}
              target="_blank"
              className="btn btn-link btn-link-primary"
            >
              <ResourceText resourceKey="housenetAd.cta" />
            </a>
          </footer>
        </article>
      </div>
    );
  }
}
