import { BidOrderByField, BidSnapShot } from "@haywork/api/kolibri";
import { BidThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { BidsComponent, BidsComponentProps } from "./bids";

type StateProps = {};
type DispatchProps = {
  getBids: (
    assignmentId: string,
    orderBy: BidOrderByField
  ) => Promise<BidSnapShot[]>;
  deleteBid: (id: string, undeleteCallback: () => void) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  BidsComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  BidsComponentProps
> = (dispatch) => ({
  getBids: (assignmentId: string, orderBy: BidOrderByField) =>
    dispatch(BidThunk.getAllBids(assignmentId, orderBy)),
  deleteBid: (id: string, undeleteCallback: () => void) =>
    dispatch(BidThunk.deleteBid(id, undeleteCallback))
});

export type BidsContainerProps = StateProps & DispatchProps;
export const BidsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BidsComponent);
