import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { ResourceText, PageLoader } from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  LinkedRelation,
  RelationType,
  SearchAssignmentSnapShot,
  MatchedSearchAssignmentSnapshot,
} from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";

import { RelationWidgetsSearchAssignmentsItemComponent } from "./list-item.component";
import { REQUEST, RELATIONROUTES } from "@haywork/constants";

const styles = require("./search-assignments.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface RelationWidgetSearchAssignmentsComponentProps {
  relationId: string;
  relationType: RelationType;
  searchAssignments: SearchAssignmentSnapShot[];
  searchAssignmentsStatus: string;
  onNavigateToSearchAssignment: (url: string) => void;
  getMatchingSearchAssignments: (relationId: string) => void;
}
interface RelationWidgetSearchAssignmentsComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class RelationWidgetSearchAssignmentsComponent extends React.Component<
  RelationWidgetSearchAssignmentsComponentProps,
  RelationWidgetSearchAssignmentsComponentState
> {
  public componentDidMount() {
    const { relationId } = this.props;
    this.props.getMatchingSearchAssignments(relationId);
  }

  public render() {
    const { searchAssignments } = this.props;
    const slicedSearchAssignments = searchAssignments.slice(0, 3);

    return (
      <div styleName="search-assignments">
        <h3 styleName="title">
          <ResourceText resourceKey="searchAssignments" />
        </h3>
        {slicedSearchAssignments.length > 0 && (
          <div styleName="search-assignments__result">
            {slicedSearchAssignments.map((searchAssignment, idx) => (
              <ErrorBoundary key={idx}>
                <RelationWidgetsSearchAssignmentsItemComponent
                  searchAssignment={searchAssignment}
                  onNavigateToSearchAssignment={
                    this.props.onNavigateToSearchAssignment
                  }
                />
              </ErrorBoundary>
            ))}
            <div styleName="actions">
              <NavLink to={this.getSearchAssignmentsRoute()}>
                <ResourceText resourceKey="goToSearchAssignments" />
              </NavLink>
            </div>
          </div>
        )}
        {this.props.searchAssignmentsStatus === REQUEST.PENDING && (
          <div styleName="search-assignments__loader">
            <PageLoader loading />
          </div>
        )}
        {this.props.searchAssignmentsStatus === REQUEST.SUCCESS &&
          slicedSearchAssignments.length === 0 && (
            <div styleName="emptyState">
              <div styleName="icon" />
              <div styleName="text">
                <h2>
                  <ResourceText resourceKey="assignmentSearchAssignmentsEmptyStateTitle" />
                </h2>
              </div>
            </div>
          )}
      </div>
    );
  }

  private getSearchAssignmentsRoute(): string {
    switch (this.props.relationType) {
      case RelationType.ContactCompany:
        return route(RELATIONROUTES.CONTACT_COMPANY_SEARCH_ASSIGNMENTS.URI, {
          id: this.props.relationId,
        });
      case RelationType.ContactPerson:
        return route(RELATIONROUTES.CONTACT_PERSON_SEARCH_ASSIGNMENTS.URI, {
          id: this.props.relationId,
        });
      default:
        return "";
    }
  }
}
