import { AppState } from "@haywork/stores";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { DetailComponent, DetailComponentProps } from "./detail";
import { editable } from "@haywork/modules/editable";
import { MLSROUTES } from "@haywork/constants";
import { RootEntityType } from "@haywork/api/event-center";
import { MLSActions } from "@haywork/stores/mls";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { displayName } from "./selectors";

type StateProps = { displayName: string };
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  DetailComponentProps,
  AppState
> = (state) => {
  return {
    displayName: displayName(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DetailComponentProps
> = (dispatch) => ({});

export type DetailContainerProps = StateProps & DispatchProps;
export const DetailContainer = editable(
  connect(mapStateToProps, mapDispatchToProps)(DetailComponent),
  {
    icon: "fal fa-exchange",
    thunk: MLSThunk.Single.getRealEstateResponse,
    status: "mls.single.status",
    statePath: "mls.single.realEstateResponse",
    action: MLSActions.Single.setSingle,
    route: MLSROUTES.DETAIL.URI,
    entityType: RootEntityType.Unknown,
  }
);
