import { WidgetEntityType } from "@haywork/api/authorization";
import { AssignmentType, TimelineActionType } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { AssignmentDetailTimelineContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import TimelineComponent from "@haywork/modules/shared/components/timeline";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./detail-timeline.component.scss");

export interface AssignmentDetailTimelineComponentProps {}
interface State {}
type Props = AssignmentDetailTimelineComponentProps &
  AssignmentDetailTimelineContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailTimelineComponent extends React.Component<
  Props,
  State
> {
  public componentDidMount() {
    this.props.getTimelineEvents(this.props.objectAssignment.id);
    this.props.getInitializedWidgets(
      get(this.props.objectAssignment, "id"),
      WidgetEntityType.ObjectAssignment
    );
  }

  public render() {
    const { displayName, id } = this.props.objectAssignment;

    return (
      <React.Fragment>
        <ErrorBoundary>
          <TimelineComponent
            name={displayName}
            timelineEvents={this.props.events}
            timeLineStatistics={this.props.timelineStatistics}
            relationTimelineStatus={this.props.timelineStatus}
            onTimelineActionTypeChange={(actionType) =>
              this.props.getTimelineEvents(
                this.props.objectAssignment.id,
                actionType
              )
            }
            onScrollEndOfTimeline={(actionType) =>
              this.appendTimelineEvents(actionType)
            }
            emptyStateTitle="emptyStateNoTimelineTitle"
            emptyStateText="emptyStateNoTimelineTextAssignment"
            mainEmptyStateTitle="emptyStateNoTimelineTitle"
            mainEmptyStateText="emptyStateNoTimelineTextAssignment"
            taskCategories={this.props.taskCategories}
            agendaItemCategories={this.props.agendaItemCategories}
            parentId={this.props.objectAssignment.id}
            canLoadMore={this.props.canLoadMore}
            authorizationWidgetEntityType={WidgetEntityType.ObjectAssignment}
            linkedAssignment={{
              id,
              displayName,
              typeOfAssignment: AssignmentType.Object
            }}
            onRefresh={() =>
              this.props.getTimelineEvents(this.props.objectAssignment.id)
            }
            addNotes={true}
          />
        </ErrorBoundary>
      </React.Fragment>
    );
  }

  private appendTimelineEvents(timelineActionTypes: TimelineActionType[]) {
    if (
      !this.props.canLoadMore ||
      this.props.timelineStatus === REQUEST.PENDING
    )
      return;
    this.props.getTimelineEvents(
      this.props.objectAssignment.id,
      timelineActionTypes,
      false
    );
  }
}
