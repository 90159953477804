import {
  ErrorMessage,
  Errors,
  MappedFormControl,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormError } from "@haywork/stores/form/errors";
import classNames from "classnames";
import isEqual from "lodash-es/isEqual";
import isString from "lodash-es/isString";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Subtract } from "utility-types";
import { v4 as uuid } from "uuid";

interface InputComponentProps {
  autoFocus?: boolean;
  block?: boolean;
  className?: string;
  clearFormField?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  disableTrim?: boolean;
  fireAllChanges?: boolean;
  focus?: boolean;
  hint?: string | { key: string; values: any };
  initial?: boolean;
  inline?: boolean;
  name: string;
  noWrapper?: boolean;
  shouldFocusOnError?: boolean;
  styleName?: string;
  valid?: boolean;
  validForSubmit?: boolean;
  value?: any;
  asSingleInput?: boolean;
  onBlur?: (event?: React.FocusEvent<any>) => void;
  onChange?: (value: any, valid?: boolean) => void;
  onFocus?: (event?: React.FocusEvent<any>) => void;
  submitOnEnter?: () => void;
}
interface FormControlProps extends InputComponentProps {}
interface State {
  errors: ErrorMessage[];
  formError: FormError;
  initial: boolean;
  isDirty: boolean;
  shouldFocusOnError: boolean;
  valid: boolean;
  value: any;
}
type Props = FormControlProps;

const formControl = <P extends InputComponentProps>(
  Component: React.ComponentType<P>,
  higherLevelComponent: boolean = false,
  noWrapper: boolean = false
) => {
  class FormControl extends React.Component<
    Props & P & InputComponentProps,
    State
  > {
    public static contextTypes = {
      registerControl: PropTypes.func,
      setSubformValidity: PropTypes.func,
      unRegisterControl: PropTypes.func,
      updateControl: PropTypes.func,
      validateControl: PropTypes.func,
    };
    private id: string = uuid();

    constructor(props, context) {
      super(props, context);

      // Bindings
      this.onUpdateHandler = this.onUpdateHandler.bind(this);
      this.unsetInitialHandler = this.unsetInitialHandler.bind(this);
      this.onFocusOnErrorHandler = this.onFocusOnErrorHandler.bind(this);
      this.onTriggerFormErrorFocus = this.onTriggerFormErrorFocus.bind(this);
      this.onChangeHandler = this.onChangeHandler.bind(this);
      this.onBlurHandler = this.onBlurHandler.bind(this);
      this.onKeyDownHandler = this.onKeyDownHandler.bind(this);

      // Set initial state
      if (
        !!this.props.asSingleInput ||
        !this.context ||
        !this.context.registerControl
      ) {
        this.state = {
          errors: [],
          formError: null,
          initial: true,
          isDirty: false,
          shouldFocusOnError: false,
          valid: true,
          value: [NaN, undefined, null].includes(this.props.value)
            ? ""
            : this.props.value,
        };
      } else {
        const control: MappedFormControl = this.context.registerControl(
          this.props.name,
          this.onUpdateHandler,
          this.unsetInitialHandler,
          this.onFocusOnErrorHandler,
          this.onTriggerFormErrorFocus
        );
        const { value, valid, errors } = control;

        this.state = {
          errors,
          formError: null,
          initial: true,
          isDirty: false,
          shouldFocusOnError: false,
          valid,
          value,
        };
      }
    }

    public render() {
      if (this.props.noWrapper || !!noWrapper) {
        return (
          <Component
            {...this.props}
            {...this.state}
            onChange={this.onChangeHandler}
            onBlur={this.onBlurHandler}
            key={this.id}
          />
        );
      }

      const formControlStyle = classNames(
        "form__control",
        this.props.className,
        {
          "has-errors": !this.state.initial && !this.state.valid,
          disabled: this.props.disabled,
          "has-preflight-error": this.state.formError,
        }
      );
      const formFieldStyle = classNames({
        form__field: !this.props.clearFormField,
        inline: this.props.inline,
      });

      return (
        <div className={formFieldStyle}>
          <div
            className={formControlStyle}
            styleName={this.props.styleName}
            onKeyDown={this.onKeyDownHandler}
          >
            <Component
              {...this.props}
              {...this.state}
              onChange={this.onChangeHandler}
              onBlur={this.onBlurHandler}
              key={this.id}
            />
          </div>
          {this.renderHint()}
          {!this.state.initial && !this.state.valid && (
            <Errors errors={this.state.errors} />
          )}
        </div>
      );
    }

    public componentDidUpdate(
      prevProps: Readonly<Props & Subtract<P, InputComponentProps>>
    ) {
      if (
        [null, undefined].indexOf(this.props.value) === -1 &&
        this.props.value !== prevProps.value
      ) {
        this.setState({ value: this.props.value || "" });
      }
    }

    public componentWillUnmount() {
      if (this.context && this.context.unRegisterControl)
        this.context.unRegisterControl(this.props.name);
    }

    public shouldComponentUpdate(
      nextProps: Readonly<Props & Subtract<P, InputComponentProps>>
    ) {
      const propsChanges = !isEqual(nextProps, this.props);
      return (
        !!this.props.asSingleInput ||
        higherLevelComponent ||
        !this.context ||
        !this.context.updateControl ||
        propsChanges
      );
    }

    private onUpdateHandler(control: RawFormControl) {
      const { value, valid, errors } = control;
      this.setState({ value, valid, errors }, () => {
        this.forceUpdate();
      });
    }

    private onChangeHandler(value: any, valid?: boolean) {
      if (this.props.block) return;
      if (this.props.onChange) this.props.onChange(value);
      if (
        !this.context ||
        !this.context.updateControl ||
        !!this.props.asSingleInput
      )
        return;

      if (!this.state.isDirty) {
        this.setState({
          isDirty: true,
          initial: false,
          shouldFocusOnError: false,
        });
      }

      if (valid !== undefined) {
        this.context.setSubformValidity(this.props.name, valid);
      }

      this.context.updateControl(this.props.name, value);
    }

    private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
      if (this.props.submitOnEnter && event.keyCode === 13 && !event.shiftKey)
        this.props.submitOnEnter();
    }

    private onBlurHandler(e) {
      if (
        !!e &&
        e.nativeEvent &&
        e.nativeEvent.explicitOriginalTarget &&
        e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
      ) {
        return;
      }

      if (this.state.isDirty) {
        this.setState({ initial: false, shouldFocusOnError: false });
      }
      if (this.props.onBlur) this.props.onBlur();
      if (this.context.validateControl) {
        this.context.validateControl(this.props.name);
      }
    }

    private renderHint(): React.ReactElement<HTMLDivElement> {
      if (!this.props.hint) return null;
      const hint: any = isString(this.props.hint)
        ? { key: this.props.hint, values: {} }
        : this.props.hint;

      return (
        <div className="form__hint">
          <ResourceText resourceKey={hint.key} values={hint.values} />
        </div>
      );
    }

    private unsetInitialHandler() {
      if (!!this.state.initial) {
        this.setState({ initial: false });
      }
    }

    private onFocusOnErrorHandler() {
      this.setState({ shouldFocusOnError: true }, () => this.forceUpdate());
    }

    private onTriggerFormErrorFocus(formError: FormError) {
      this.setState({ shouldFocusOnError: true, formError }, () =>
        this.forceUpdate()
      );
    }
  }

  return CSSModules({}, { allowMultiple: true })(FormControl);
};

export { formControl, InputComponentProps };
