import { CompanySettings, Employee } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, CurrentRealestateAgency } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  DashboardWidgetHousenetAdComponent,
  DashboardWidgetHousenetAdComponentProps
} from "./housenet-ad";

interface StateProps {
  settings: CompanySettings | null;
  employee: Employee;
  currentRealestateAgency: CurrentRealestateAgency;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  DashboardWidgetHousenetAdComponentProps,
  AppState
> = (state) => {
  const { settings } = state.company;
  const { employee, currentRealestateAgency } = state.account;

  return {
    settings,
    employee,
    currentRealestateAgency
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DashboardWidgetHousenetAdComponentProps
> = (dispatch) => ({});

export type DashboardWidgetHousenetAdContainerProps = StateProps &
  DispatchProps;
export const DashboardWidgetHousenetAdContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardWidgetHousenetAdComponent);
