import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { Input, Form, FormReturnValue } from "@haywork/modules/form";
import { AcquistionAssignmentEditMediaTextScreenContainerProps } from "./assignments-edit-media-text.container";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import FormattedTextComponent from "@haywork/components/ui/formatted-text";

const styles = require("./assignments-edit-media-text.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

export type AcquistionAssignmentEditMediaTextScreenProps = {};
type Props = AcquistionAssignmentEditMediaTextScreenProps &
  AcquistionAssignmentEditMediaTextScreenContainerProps;

export const AcquistionAssignmentEditMediaTextScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      currentComponentState,
      updateAcquisitionAssignmentObject,
      path,
      languages,
      defaultLanguage,
    }) => {
      const formControls = {
        advertisementText: {
          value: value(currentComponentState, "advertisementText", ""),
        },
      };

      const onChangeHandler = (values: FormReturnValue) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: currentComponentState.id,
        });

        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...currentComponentState,
          ...values,
        };

        const newState: AcquisitionObjectAssignment = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      return (
        <Form
          name="marketing-text"
          formControls={formControls}
          onChange={onChangeHandler}
        >
          <FormattedTextComponent asSection value="advertisementText" />
          <Input.TranslateText
            name="advertisementText"
            languages={languages}
            defaultLanguage={defaultLanguage}
            data-cy="CY-advertisementText"
          />
        </Form>
      );
    }
  )
);
