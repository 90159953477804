import { SEARCH_ASSIGNMENT } from "./search-assignment";
import { ACCESS } from "./access";
import { ACCOUNT } from "./account";
import { APPSETTINGS } from "./app-settings";
import { ASSIGNMENT } from "./assignment";
import { COMMON } from "./common";
import { COMPANY } from "./company";
import { CREDENTIALS } from "./credentials";
import { DOSSIER } from "./dossier";
import { EDITABLE } from "./editable";
import { GLOBALSEARCH } from "./global-search";
import { LAYOUT } from "./layout";
import { MAIN } from "./main";
import { NOTES } from "./notes";
import { RELATION } from "./relation";
import { SCHEDULER } from "./scheduler";
import { STATISTICS } from "./statistics";
import { TASK } from "./task";
import { RELATION_GROUPS } from "./relation-groups";
import { EMPLOYEE } from "./employee";
import { OFFICES } from "./offices";
import { MEDIAPARTNERS } from "./mediapartners";
import { ERRORS } from "./errors";
import { FORM } from "./form";
import { INVOICES } from "./invoice";
import { DYNAMIC_DOCUMENTS } from "./dynamic-documents";
import { PHRASES } from "./phrases";
import { REALESTATE_AGENCY } from "./realestate-agency";
import { SCHEDULER_CATEGORIES } from "./scheduler-categories";
import { EMAILV2 } from "./email";
import { SNACKBAR } from "./snackbar";
import { PROJECT } from "@haywork/constants/redux/project";
import { NEW_ENTITY } from "./new-entity";
import { APP_XCHANGE } from "./app-xchange";
import { OBJECTTYPES } from "./object-types";
import { BUILDNUMBERS } from "./buildnumbers";
import { EVENTCENTER } from "./event-center";
import { REMINDERS } from "./reminders";
import { SCROLLPOSITION } from "./scroll-position";

export const REDUX = {
  ACCESS,
  ACCOUNT,
  ASSIGNMENT,
  SEARCH_ASSIGNMENT,
  COMMON,
  COMPANY,
  CREDENTIALS,
  DOSSIER,
  EDITABLE,
  LAYOUT,
  MAIN,
  NOTES,
  RELATION,
  SCHEDULER,
  TASK,
  STATISTICS,
  GLOBALSEARCH,
  APPSETTINGS,
  RELATION_GROUPS,
  EMPLOYEE,
  OFFICES,
  MEDIAPARTNERS,
  ERRORS,
  FORM,
  INVOICES,
  DYNAMIC_DOCUMENTS,
  PHRASES,
  REALESTATE_AGENCY,
  SCHEDULER_CATEGORIES,
  SNACKBAR,
  NEW_ENTITY,
  EMAILV2,
  PROJECT,
  APP_XCHANGE,
  OBJECTTYPES,
  BUILDNUMBERS,
  EVENTCENTER,
  REMINDERS,
  SCROLLPOSITION,
};
