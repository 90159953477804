import {
  AssignmentOrderByField,
  AssignmentSnapShot,
  SortOrder
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import {
  AssignmentThunks,
  MapDispatchToProps,
  ProjectsThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import {
  AssignmentListActions,
  AssignmentOrder
} from "@haywork/stores/assignment/list";
import { connect, MapStateToProps } from "react-redux";
import { ListComponent, ListComponentProps } from "./list";
import { activeFilterGuidValues } from "./selectors";

type StateProps = {
  assignments: AssignmentSnapShot[];
  totalCount: number;
  commissionTotal: number;
  order: AssignmentOrder;
  filters: FilterConfig;
  scrollOffset: number;
  activeFilterGuidValues: FilterGuidValues;
};
type DispatchProps = {
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: AssignmentOrderByField
  ) => void;
  createNewAssignment: () => void;
  setFilters: (filters: FilterConfig) => void;
  setScrollOffset: (offset: number) => void;
  openAssignment: (id: string, snapshot: AssignmentSnapShot) => void;
  openProject: (id: string, snapshot: AssignmentSnapShot) => void;
  openObjectType: (id: string, snapshot: AssignmentSnapShot) => void;
  exportListToExcel: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListComponentProps,
  AppState
> = (state) => {
  const {
    assignments,
    totalCount,
    statistics,
    order,
    filters,
    scrollOffset
  } = state.assignment.list;
  const { commissionTotal } = statistics;

  return {
    assignments,
    totalCount,
    commissionTotal: commissionTotal || 0,
    order,
    filters,
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListComponentProps
> = (dispatch) => ({
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(AssignmentThunks.getListItems(startIndex, stopIndex)),
  setOrdering: (sortOrder: SortOrder, sortColumn: AssignmentOrderByField) =>
    dispatch(AssignmentListActions.setOrdering(sortOrder, sortColumn)),
  createNewAssignment: () => dispatch(AssignmentThunks.createAssignment()),
  setFilters: (filters: FilterConfig) =>
    dispatch(AssignmentListActions.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(AssignmentListActions.setScrollOffset(offset)),
  openAssignment: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(AssignmentThunks.getAssignment(id, snapshot)),
  openProject: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(ProjectsThunks.Projects.getProject(id, snapshot)),
  openObjectType: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(ProjectsThunks.Types.getType(id, snapshot)),
  exportListToExcel: () => dispatch(AssignmentThunks.exportListToExcel())
});

export type ListContainerProps = StateProps & DispatchProps;
export const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
