import { ActionType, ActionTypes } from "./actions";
import { LiveDataConnection } from "./";
import * as Ably from "ably";

export type LiveDataState = {
  mailStatus: Ably.Types.ConnectionState;
  voipStatus: Ably.Types.ConnectionState;
  kolibriStatus: Ably.Types.ConnectionState;
  eventCenterStatus: Ably.Types.ConnectionState;
};

const INITIAL: LiveDataState = {
  mailStatus: "disconnected",
  voipStatus: "disconnected",
  kolibriStatus: "disconnected",
  eventCenterStatus: "disconnected"
};

export const liveDataReducer = (
  state = INITIAL,
  action: ActionTypes
): LiveDataState => {
  switch (action.type) {
    case ActionType.SetStatus: {
      const { connection, status } = action;
      switch (connection) {
        case LiveDataConnection.Mail: {
          return {
            ...state,
            mailStatus: status
          };
        }
        case LiveDataConnection.Voip: {
          return {
            ...state,
            voipStatus: status
          };
        }
        case LiveDataConnection.Kolibri: {
          return {
            ...state,
            kolibriStatus: status
          };
        }
        case LiveDataConnection.EventCenter: {
          return {
            ...state,
            eventCenterStatus: status
          };
        }
        default: {
          return state;
        }
      }
    }
    default: {
      return state;
    }
  }
};
