import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import {
  Employee,
  RelationSnapShot,
  RelationType,
  AccountSettings,
  FinancialAdministration,
  EmployeeRole,
  ActiveFilter,
} from "@haywork/api/kolibri";
import * as ActionType from "./account.types";

export interface CurrentRealestateAgency {
  id: string;
  name: string;
  role: EmployeeRole;
}

export interface AccountState {
  employee?: Employee;
  employees?: RelationSnapShot[];
  relationEmployeeState?: string;
  accountSettings?: AccountSettings;
  offices?: RelationSnapShot[];
  error?: any;
  showOnboarding?: boolean;
  showAllAssignments?: boolean;
  showAllKeyboardAssignments?: ActiveFilter;
  financialAdministrations?: FinancialAdministration[];
  financialAdministrationState?: string;
  currentRealestateAgency?: CurrentRealestateAgency;
}

const INITIAL_STATE: AccountState = {
  employee: null,
  employees: [],
  relationEmployeeState: REQUEST.IDLE,
  accountSettings: null,
  offices: [],
  error: null,
  showOnboarding: false,
  showAllAssignments: false,
  showAllKeyboardAssignments: ActiveFilter.ActiveOnly,
  financialAdministrations: [],
  financialAdministrationState: REQUEST.IDLE,
  currentRealestateAgency: null,
};

export const accountReducer = (
  state: AccountState = INITIAL_STATE,
  action: Action
): AccountState => {
  switch (action.type) {
    case REDUX.ACCOUNT.SET_SHOW_ALL_KEYBOARD_ASSIGNMENTS: {
      const { showAllKeyboardAssignments } = <
        ActionType.ShowAllKeyBoardAssignments
      >action;
      return { ...state, showAllKeyboardAssignments };
    }

    case REDUX.ACCOUNT.SET_SHOW_ALL_ASSIGNMENTS: {
      const { showAllAssignments } = <ActionType.ShowAllAssignments>action;
      return { ...state, showAllAssignments };
    }

    case REDUX.ACCOUNT.SET_EMPLOYEE: {
      const { employee } = <ActionType.Employee>action;
      return { ...state, employee, relationEmployeeState: REQUEST.SUCCESS };
    }
    case REDUX.ACCOUNT.SET_ACCOUNT_SETTINGS: {
      const { accountSettings } = <ActionType.AccountSettings>action;

      if (!state.currentRealestateAgency) {
        const matchedRealEstateAgency =
          accountSettings.linkedRealEstateAgencies.find(
            (a) => a.id === accountSettings.realEstateAgencyId
          );
        const matchedRealEstateAgencyRole =
          accountSettings.worksAtRealEstateAgencies.find(
            (a) => a.realEstateAgencyId === accountSettings.realEstateAgencyId
          );

        const currentRealestateAgency = {
          id: accountSettings.realEstateAgencyId,
          name: matchedRealEstateAgency.name,
          role: matchedRealEstateAgencyRole.role,
        };

        return {
          ...state,
          accountSettings,
          currentRealestateAgency,
        };
      }

      return { ...state, accountSettings };
    }
    case REDUX.ACCOUNT.SET_EMPLOYEES_AND_OFFICES: {
      const { relations } = <ActionType.Relations>action;
      const employees = relations.filter(
        (employee) => employee.typeOfRelation === RelationType.Employee
      );
      const offices = relations.filter(
        (employee) => employee.typeOfRelation === RelationType.Office
      );

      return { ...state, employees, offices };
    }
    case REDUX.ACCOUNT.EMPLOYEE_FAILURE: {
      const { error } = <ActionType.EmployeeError>action;
      return { ...state, error };
    }
    case REDUX.ACCOUNT.SET_EMPLOYEE_STATE: {
      const { relationEmployeeState } = <ActionType.EmployeeState>action;
      return { ...state, relationEmployeeState };
    }
    case REDUX.ACCOUNT.SET_ONBOARDING: {
      const { showOnboarding } = <ActionType.ShowOnboarding>action;
      return { ...state, showOnboarding };
    }
    case REDUX.ACCOUNT.SET_FINANCIAL_ADMINISTRATIONS: {
      const { financialAdministrations } = <
        ActionType.FinancialAdministrations
      >action;
      return { ...state, financialAdministrations };
    }
    case REDUX.ACCOUNT.SET_FINANCIAL_ADMINISTRATION_STATE: {
      const { financialAdministrationState } = <
        ActionType.FinancialAdministrationState
      >action;
      return { ...state, financialAdministrationState };
    }
    case REDUX.ACCOUNT.UPDATE_FINANCIAL_ADMINISTRATION: {
      const { financialAdministration } = <ActionType.FinancialAdministration>(
        action
      );
      const financialAdministrations = state.financialAdministrations.map(
        (fa) => {
          if (fa.id === financialAdministration.financialAdministration.id) {
            return financialAdministration.financialAdministration;
          }
          return fa;
        }
      );

      return {
        ...state,
        financialAdministrations,
        financialAdministrationState: REQUEST.IDLE,
      };
    }
    case REDUX.ACCOUNT.SWITCH_REALESTATE_AGENCY: {
      const { id } = <ActionType.Id>action;
      const matchedRealEstateAgency = (
        state.accountSettings?.linkedRealEstateAgencies || []
      ).find((a) => a.id === id);
      const matchedRealEstateAgencyRole = (
        state.accountSettings?.worksAtRealEstateAgencies || []
      ).find((a) => a.realEstateAgencyId === id);

      const currentRealestateAgency = {
        id,
        name: matchedRealEstateAgency?.name || "",
        role: matchedRealEstateAgencyRole?.role || EmployeeRole.BackOffice,
      };

      return {
        ...INITIAL_STATE,
        currentRealestateAgency,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return {
        ...state,
        employee: null,
        employees: [],
        relationEmployeeState: REQUEST.IDLE,
        accountSettings: null,
        offices: [],
        error: null,
        showOnboarding: false,
        financialAdministrations: [],
        financialAdministrationState: REQUEST.IDLE,
      };
    }
    case REDUX.ACCESS.LOGOUT: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
