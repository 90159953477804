import * as React from "react";
import { FC, memo, useCallback, useContext, useState } from "react";
import { ActionsColumn, ListContext } from "@haywork/components/ui/list";
import { SearchAssignmentSnapShot } from "@haywork/api/kolibri";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";
import { Action } from "@haywork/components/ui/action-list";

export enum SearchAssignmentListAction {
  Edit = "Edit",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Delete = "Delete"
}
type Props = {
  item: SearchAssignmentSnapShot;
};

export const SearchAssignmentActions: FC<Props> = memo(({ item }) => {
  const { onActionCallback } = useContext(ListContext);
  const [actionsVisible, setActionsVisible] = useState(false);

  const onAction = useCallback(
    (action: string) => {
      setActionsVisible(false);
      onActionCallback(item, action);
    },
    [item, onActionCallback, setActionsVisible]
  );

  return (
    <ActionsColumn
      visible={actionsVisible}
      onHide={() => setActionsVisible(false)}
      onShow={() => setActionsVisible(true)}
    >
      <Action
        label="searchAssignment.itemAction.edit"
        icon={
          <Icon light name="pencil" color={Colors.ActionListIcon} fixedWidth />
        }
        onClick={() => onAction(SearchAssignmentListAction.Edit)}
      />
      {item.isActive && (
        <Action
          label="searchAssignment.itemAction.archive"
          icon={
            <Icon
              light
              name="archive"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(SearchAssignmentListAction.Archive)}
        />
      )}
      {!item.isActive && (
        <Action
          label="searchAssignment.itemAction.unArchive"
          icon={
            <Icon
              light
              name="arrow-from-bottom"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(SearchAssignmentListAction.UnArchive)}
        />
      )}
      {!item.isActive && (
        <Action
          label="searchAssignment.itemAction.delete"
          icon={
            <Icon
              light
              name="trash-alt"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onAction(SearchAssignmentListAction.Delete)}
        />
      )}
    </ActionsColumn>
  );
});

SearchAssignmentActions.displayName = "ActionsColumn";
