import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AppState,
  EditableActions,
  SingleAssignmentState,
  SingleProjectState
} from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  HideFromMediaPartnerToggleComponent,
  HideFromMediaPartnerToggleProps
} from "./hide-toggle";

type StateProps = {
  isTemporarilyRemoved: boolean | null;
  currentComponentState: SingleAssignmentState;
};
type DispatchProps = {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  updateProject: (componentState: SingleProjectState, path: string) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  HideFromMediaPartnerToggleProps,
  AppState
> = (state, props) => {
  const { type } = props;

  // TODO: get isTemporarilyRemovedFromFunda from props to further generalize component
  const getIsTemporarilyRemoved = () => {
    switch (type) {
      case "projectAssignment": {
        return state.project.single.projectAssignment
          .isTemporarilyRemovedFromFunda;
      }
      case "objectAssignment": {
        return state.assignment.single.objectAssignment
          .isTemporarilyRemovedFromFunda;
      }
      default:
        return null;
    }
  };

  const { currentComponentState } = state.editable;

  return {
    isTemporarilyRemoved: getIsTemporarilyRemoved(),
    currentComponentState
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  HideFromMediaPartnerToggleProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type HideFromMediaPartnerToggleContainerProps = StateProps &
  DispatchProps;
export const HideFromMediaPartnerToggleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HideFromMediaPartnerToggleComponent);
