import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import has from "lodash-es/has";
import get from "lodash-es/get";
import first from "lodash-es/first";

import { StickyHeadersComponent } from "../sticky-headers/sticky-headers.component";

const styles = require("./infinite-scroll.component.scss");

type StickyConditionFn<G> = (prop: G) => {
  match: any;
  hideSubs?: boolean;
  display?: (prop: G) => any;
};
export type InfiniteScrollScrollToTopFn = (position?: number) => void;

export interface StickyConditions<G> {
  main: { path: string; matchFn: StickyConditionFn<G> };
  sub?: { path: string; matchFn: StickyConditionFn<G> };
}
interface InfiniteScrollComponentProps<G> {
  offset?: number;
  scrollEnd: () => void;
  sticky?: StickyConditions<G>;
  relative?: boolean;
  scrollTop?: (scrollTop: InfiniteScrollScrollToTopFn) => void;
}
interface InfiniteScrollComponentState {
  scrollTop: number;
  top: number;
}

@CSSModules(styles, { allowMultiple: true })
export class InfiniteScroll extends React.Component<
  InfiniteScrollComponentProps<any>,
  InfiniteScrollComponentState
> {
  private ref: HTMLDivElement;
  private offset: number = 250;

  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
      top: 0,
    };

    // Bind this
    this.onScrollHandler = this.onScrollHandler.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public componentDidMount() {
    this.offset = this.props.offset || this.offset;
    if (this.props.scrollTop)
      this.props.scrollTop(this.triggerScrollTop.bind(this));
  }

  public render() {
    const wrapperStyle = classNames("infinite-scroll__wrapper", {
      relative: this.props.relative,
    });

    return (
      <div styleName={wrapperStyle} data-cy={this.props["data-cy"]}>
        <div
          styleName="infinite-scroll"
          onScroll={this.onScrollHandler}
          ref={this.bindRef}
        >
          {this.renderChildren()}
        </div>
      </div>
    );
  }

  private onScrollHandler(event: any) {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    const diff = scrollHeight - offsetHeight - scrollTop;

    if (scrollTop >= this.state.top && diff <= this.offset)
      this.props.scrollEnd();
    this.setState({ top: scrollTop });
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
    }
  }

  private renderChildren(): React.ReactNode {
    if (!this.props.sticky) return this.props.children;
    const children = React.Children.toArray(this.props.children);
    const mainSections: any[] = [];
    const { main, sub } = this.props.sticky;
    let subSections: any[] = [];
    let hideSubs = false;
    let idx = 0;

    const rendered = children.reduce((state: React.ReactNode[], child) => {
      let childCmp = child;

      if (
        !has(childCmp, `props.${main.path}`) &&
        has(childCmp, "props.children")
      ) {
        const children = React.Children.toArray(
          get(childCmp, "props.children")
        );
        childCmp = first(children);
      }

      if (has(childCmp, `props.${main.path}`)) {
        const obj = get(childCmp, `props.${main.path}`);
        const match = main.matchFn(obj);
        if (mainSections.indexOf(match.match) === -1) {
          mainSections.push(match.match);
          state.push(
            <StickyHeadersComponent
              key={idx}
              display={match.display || match.match}
              obj={obj}
              isMain
            />
          );
          subSections = [];
          hideSubs = match.hideSubs || false;
          idx++;
        }
      }

      if (!hideSubs && sub && has(childCmp, `props.${sub.path}`)) {
        const obj = get(childCmp, `props.${sub.path}`);
        const match = sub.matchFn(obj);
        if (subSections.indexOf(match.match) === -1) {
          subSections.push(match.match);
          state.push(
            <StickyHeadersComponent
              key={idx}
              display={match.display || match.match}
              obj={obj}
              isSub
            />
          );
          idx++;
        }
      }

      state.push(child);
      return state;
    }, []);

    return rendered;
  }

  private triggerScrollTop(position?: number) {
    if (!this.ref) return;
    this.ref.scrollTop = position || 0;
  }
}
