import I18n from "@haywork/components/i18n";
import { ModalPortal } from "@haywork/portals";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./hint.component.scss");

enum HintAlignment {
  Left = "Left",
  Right = "Right",
  Bottom = "Bottom",
  Top = "Top",
}

interface Props {
  align?: HintAlignment;
  message: string;
  values?: any;
  shouldRender?: boolean;
  forceInline?: boolean;
  fill?: boolean;
  disableCursor?: boolean;
  actionCursor?: boolean;
}
interface State {
  align: string;
  hover: boolean;
  style: any;
  shouldRender: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class Hint extends React.PureComponent<Props, State> {
  private ref: HTMLSpanElement;
  private tipRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      align: this.props.align || HintAlignment.Right,
      hover: false,
      style: {
        top: 0,
        left: 0,
      },
      shouldRender:
        this.props.shouldRender === undefined ? true : this.props.shouldRender,
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.bindTipRef = this.bindTipRef.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (nextProps.shouldRender !== this.props.shouldRender) {
      const shouldRender =
        nextProps.shouldRender === undefined ? true : nextProps.shouldRender;
      this.setState({ shouldRender });
    }
  }

  public render() {
    if (!this.state.shouldRender) return this.props.children;
    const hintStyle = classNames("hint", {
      "force-inline": this.props.forceInline,
      fill: this.props.fill,
      "default-cursor": this.props.disableCursor,
      "action-cursor": this.props.actionCursor,
    });
    const messageStyle = classNames(
      "hint__message",
      this.state.align.toString().toLowerCase(),
      {
        visible: this.state.hover,
      }
    );

    return (
      <span
        styleName={hintStyle}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        ref={this.bindRef}
        data-cy={this.props["data-cy"]}
      >
        <ModalPortal>
          <div
            styleName={messageStyle}
            style={this.state.style}
            ref={this.bindTipRef}
          >
            <I18n
              value={this.props.message}
              values={this.props.values}
              asHtml
            />
          </div>
        </ModalPortal>
        {this.props.children}
      </span>
    );
  }

  private onMouseEnterHandler() {
    if (!this.ref) return;

    const { bottom, left, right, top, width, height } =
      this.ref.getBoundingClientRect();
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;
    const { width: tWidth, height: tHeight } =
      this.tipRef.getBoundingClientRect();

    const alignBottom = bottom + tHeight + 26 < wHeight;
    let sTop = alignBottom ? bottom + 10 : top - tHeight - 10;
    let sLeft = left + width / 2 - tWidth / 2;

    switch (this.props.align) {
      case HintAlignment.Left: {
        sLeft = left - tWidth - 10;
        sTop = top + height / 2 - tHeight / 2;
        break;
      }
      default:
        break;
    }

    const style = {
      top: sTop,
      left: sLeft,
    };

    this.setState({ hover: true, style });
  }

  private onMouseLeaveHandler() {
    this.setState({ hover: false });
  }

  private bindRef(ref: HTMLSpanElement) {
    this.ref = ref;
  }

  private bindTipRef(ref: HTMLDivElement) {
    this.tipRef = ref;
  }
}

export { Hint, HintAlignment };
