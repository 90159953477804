import {
  AcquisitionObjectAssignment,
  AcquisitionStatus,
  Address,
  AssignmentPhase,
  AssignmentType,
  RelationSnapShot,
  RelationType,
  RentOffer,
  SaleOffer,
} from "@haywork/api/kolibri";
import { RealEstateProperty } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum/entity";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { NewEntity } from "@haywork/modules/new-entity";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import AdressExtended, {
  AddressExtendedResponse,
} from "@haywork/modules/shared/components/address-extended";
import { MlsUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import MatchingProperties from "@haywork/modules/mls/components/matching-properties-on-address";
import { AcquistionAssignmentEditGeneralScreenContainerProps } from "./assignments-edit-general.container";

const styles = require("./assignments-edit-general.scss");
const route = RouteUtil.mapStaticRouteValues;
const equals = require("react-fast-compare");
const nonValues = [null, undefined, NaN];

export type AcquistionAssignmentEditGeneralScreenProps = {};
type Props = AcquistionAssignmentEditGeneralScreenProps &
  AcquistionAssignmentEditGeneralScreenContainerProps;

export const AcquistionAssignmentEditGeneralScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionObjectAssignment,
      acquisitionStatusOptions,
      employees,
      navigate,
      updateAcquisitionAssignmentObject,
      uploadMlsPhotosForAquisitionObject,
    }) => {
      const {
        id,
        address,
        status,
        linkedVendors,
        linkedClients,
        forRent,
        saleOffer,
        rentOffer,
        isNew,
      } = acquisitionObjectAssignment;
      const form = useRef<FormReference>(null);

      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] = useState(null);
      const [addressChanged, setAddressChanged] = useState(false);

      const isForSale = useMemo(() => {
        return forRent === true ? false : true;
      }, [forRent]);

      const path = useMemo(() => {
        return route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id,
        });
      }, [id]);

      const formControls = useMemo(() => {
        return {
          forSale: { value: isForSale },
          status: { value: status },
          linkedVendors: { value: linkedVendors },
          linkedClients: { value: linkedClients },
          linkedEmployee: { value: "" },
          salePrice: { value: isForSale ? get(saleOffer, "salePrice") : null },
          targetPurchasePrice: {
            value: isForSale ? get(saleOffer, "targetPurchasePrice") : null,
          },
          purchasePrice: {
            value: isForSale ? get(saleOffer, "purchasePrice") : null,
          },
          transportDate: {
            value: isForSale
              ? get(saleOffer, "dateTransfer")
                ? get(saleOffer, "dateTransfer")
                : null
              : null,
          },
          endDateBankWarranty: {
            value: isForSale ? get(saleOffer, "endDateBankWarranty") : null,
          },
          dateReservation: {
            value: isForSale ? get(saleOffer, "dateReservation") : null,
          },
          agreementDate: {
            value: isForSale ? get(saleOffer, "agreementDate") : null,
          },
          rentPrice: { value: !isForSale ? get(rentOffer, "rentPrice") : null },
          targetRentPrice: {
            value: !isForSale ? get(rentOffer, "targetRentPrice") : null,
          },
          realizedPerMonth: {
            value: !isForSale ? get(rentOffer, "realizedPerMonth") : null,
          },
          rentedFrom: {
            value: !isForSale ? get(rentOffer, "rentedFrom") : null,
          },
          rentedUntil: {
            value: !isForSale ? get(rentOffer, "rentedUntil") : null,
          },
          dateSignDeed: {
            value: !isForSale ? get(rentOffer, "dateSignDeed") : null,
          },
        } as FormControls;
      }, []);

      const onNewEntityCloseHandler = () => {
        setNewEntityVisible(false);
      };

      const onNewRelationHandler = (relation: RelationSnapShot) => {
        let { linkedVendors } = form.current.getValues();
        linkedVendors = linkedVendors || [];

        form.current.update({
          linkedVendors: [...linkedVendors, relation],
        });

        setNewEntityVisible(false);
      };

      const onChangeHandler = (values: FormReturnValue) => {
        const isForSale = values.forSale;
        let assignment: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
        };
        let saleOffer: SaleOffer | undefined;
        let rentOffer: RentOffer | undefined;

        assignment = {
          ...assignment,
          forSale: isForSale,
          forRent: !isForSale,
        };

        if (!!values.linkedVendors) {
          assignment = {
            ...assignment,
            linkedVendors: values.linkedVendors,
          };
        }

        if (!!values.linkedClients) {
          assignment = {
            ...assignment,
            linkedClients: values.linkedClients,
          };
        }

        if (!values.status) {
          assignment = {
            ...assignment,
            status: AcquisitionStatus.New,
          };
        }

        if (values.status) {
          assignment = {
            ...assignment,
            status: values.status,
          };
        }

        // Update sale offer
        if (isForSale) {
          saleOffer = {
            ...(assignment.saleOffer || {}),
            salePrice: values.salePrice,
            targetPurchasePrice: values.targetPurchasePrice,
            purchasePrice: values.purchasePrice,
          };

          if (!nonValues.includes(values.transportDate)) {
            saleOffer = { ...saleOffer, dateTransfer: values.transportDate };
          }

          if (!nonValues.includes(values.endDateBankWarranty)) {
            saleOffer = {
              ...saleOffer,
              endDateBankWarranty: values.endDateBankWarranty,
            };
          }

          if (!nonValues.includes(values.dateReservation)) {
            saleOffer = {
              ...saleOffer,
              dateReservation: values.dateReservation,
            };
          }

          if (!nonValues.includes(values.agreementDate)) {
            saleOffer = { ...saleOffer, agreementDate: values.agreementDate };
          }

          assignment = {
            ...assignment,
            saleOffer,
          };
        }

        // Update rent offer
        if (!isForSale) {
          rentOffer = {
            ...(assignment.rentOffer || {}),
            rentPrice: values.rentPrice,
            targetRentPrice: values.targetRentPrice,
            realizedPerMonth: values.realizedPerMonth,
          };

          if (!nonValues.includes(values.rentedFrom)) {
            rentOffer = { ...rentOffer, rentedFrom: values.rentedFrom };
          }

          if (!nonValues.includes(values.rentedUntil)) {
            rentOffer = { ...rentOffer, rentedUntil: values.rentedUntil };
          }

          if (!nonValues.includes(values.dateSignDeed)) {
            rentOffer = { ...rentOffer, dateSignDeed: values.dateSignDeed };
          }

          assignment = {
            ...assignment,
            rentOffer,
          };
        }

        updateAcquisitionAssignmentObject(assignment, path);
      };

      const onAdressChange = (response: AddressExtendedResponse) => {
        if (equals(response?.address, acquisitionObjectAssignment?.address)) {
          return;
        }

        const { address } = response;

        const newState: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          address,
        };

        updateAcquisitionAssignmentObject(newState, path);
        setAddressChanged(true);
      };

      const statusChangeHandler = (status: AcquisitionStatus) => {
        if (status !== AcquisitionStatus.New) {
          const newState: AcquisitionObjectAssignment = {
            ...acquisitionObjectAssignment,
            assignmentPhase: AssignmentPhase.Initiated,
          };
          updateAcquisitionAssignmentObject(newState, path);
        }
      };

      const addNewRelation = (name: string) => {
        if (newEntityVisible) return;
        setNewEntityVisible(true);
        setNewEntityOptions({
          type: NewEntityType.Relation,
          newRelation: {
            name,
          },
        });
      };

      const handleAssignmentSelect = useCallback(
        async (assignment) => {
          const mlsAssignment = MlsUtil.mapToAcquisitionAssignment(
            assignment as RealEstateProperty
          );

          const photos = await uploadMlsPhotosForAquisitionObject(
            mlsAssignment?.photos
          );

          const {
            listingType,
            realEstateGroup,
            ...acquistionObjectToMerge
          } = acquisitionObjectAssignment;

          const newState: AcquisitionObjectAssignment = {
            ...mlsAssignment,
            ...acquistionObjectToMerge,
            ...{ photos },
          };

          updateAcquisitionAssignmentObject(newState, path);
        },
        [updateAcquisitionAssignmentObject, uploadMlsPhotosForAquisitionObject]
      );

      return (
        <div styleName="edit-detail" id="scroll-to-top">
          <div className="container-fluid">
            <Form
              name="acquisition-object-general"
              formControls={formControls}
              onChange={onChangeHandler}
              form={(ref) => (form.current = ref)}
            >
              <StepperComponent initial={0} scrollToElementId="scroll-to-top">
                <StepComponent title="acquisition.assignment.edit.general">
                  <div className="form__row">
                    <div className="form__group stretch">
                      <div className="column">
                        <label htmlFor="status">
                          <I18n value="status" />
                        </label>

                        <Input.NewSelect
                          name="status"
                          values={acquisitionStatusOptions}
                          displayProp="displayName"
                          valuesProp="value"
                          onChange={statusChangeHandler}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <label htmlFor="linkedVendors">
                          <I18n value="acquisition.assignment.edit.offeredBy" />
                        </label>
                        <Input.RelationQuery
                          name="linkedVendors"
                          multiple
                          filterByRelationTypes={[
                            RelationType.ContactCompany,
                            RelationType.ContactPerson,
                          ]}
                          onAdd={addNewRelation}
                          onNavigateToRelation={navigate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="subject">
                      <span>
                        <I18n value="acquisitions.edit.object.whoarethevendors" />
                      </span>
                    </label>
                    <div className="input__wrapper">
                      <Input.RelationQuery
                        name="linkedClients"
                        multiple
                        filterByRelationTypes={[
                          RelationType.ContactCompany,
                          RelationType.ContactPerson,
                        ]}
                        onNavigateToRelation={navigate}
                        onAdd={addNewRelation}
                      />
                    </div>
                  </div>
                  {/* <div className="form__row">
                    <label htmlFor="linkedEmployee">
                      <I18n value="whosAssignment" />
                    </label>
                    <Input.NewSelect
                      name="linkedEmployee"
                      values={employees}
                      displayProp="displayName"
                    />
                  </div> */}

                  <div className="form__row">
                    <label htmlFor="forSale">
                      <I18n value="type" />
                    </label>
                    <Input.RadioGroup name="forSale" asButtonList>
                      <Input.Radio label="sale" value={true} />
                      <Input.Radio label="rent" value={false} />
                    </Input.RadioGroup>
                  </div>
                </StepComponent>

                <StepComponent title="acquisition.assignment.edit.adress">
                  <AdressExtended
                    address={address}
                    typeOfAssignment={AssignmentType.AcquisitionObject}
                    onChange={onAdressChange}
                  >
                    {(adress: Address) => (
                      <FeatureSwitch feature="MLS">
                        {(isNew || addressChanged) && (
                          <MatchingProperties
                            address={address}
                            onSelectAssignment={handleAssignmentSelect}
                          />
                        )}
                      </FeatureSwitch>
                    )}
                  </AdressExtended>
                </StepComponent>

                <StepComponent title="acquisition.assignment.edit.financial">
                  {isForSale && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="salePrice">
                            <I18n value="acquisition.assignment.edit.financial.askingPrice" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number name="salePrice" pretty={true} />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="targetPurchasePrice">
                            <I18n value="acquisition.assignment.edit.financial.goalBuyPrice" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number
                              name="targetPurchasePrice"
                              pretty={true}
                            />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="purchasePrice">
                            <I18n value="acquisition.assignment.edit.financial.transactionPrice" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number name="purchasePrice" pretty={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isForSale && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="rentPrice">
                            <I18n value="acquisition.assignment.edit.financial.askingPriceRent" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number name="rentPrice" pretty={true} />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="targetRentPrice">
                            <I18n value="acquisition.assignment.edit.financial.targetRentPrice" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number
                              name="targetRentPrice"
                              pretty={true}
                            />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="realizedPerMonth">
                            <I18n value="acquisition.assignment.edit.financial.realizedPerMonth" />
                          </label>
                          <div className="input__helper">
                            <div className="pre">&euro;</div>
                            <Input.Number
                              name="realizedPerMonth"
                              pretty={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isForSale && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="transportDate">
                            <I18n value="acquisition.assignment.edit.transfer.transportDate" />
                          </label>
                          <div>
                            <Input.Datepicker name="transportDate" />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="bankWarrantyDate">
                            <I18n value="acquisition.assignment.edit.transfer.bankWarrantyDate" />
                          </label>
                          <div>
                            <Input.Datepicker name="endDateBankWarranty" />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="conditionDate">
                            <I18n value="acquisition.assignment.edit.transfer.conditionDate" />
                          </label>
                          <div>
                            <Input.Datepicker name="dateReservation" />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-out-column">
                          <label htmlFor="agreementDate">
                            <I18n value="acquisition.assignment.edit.transfer.agreementDate" />
                          </label>
                          <div>
                            <Input.Datepicker name="agreementDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isForSale && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="rentedFrom">
                            <I18n value="acquisition.assignment.edit.transfer.rentedFrom" />
                          </label>
                          <div>
                            <Input.Datepicker name="rentedFrom" />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="rentedUntil">
                            <I18n value="acquisition.assignment.edit.transfer.rentedUntil" />
                          </label>
                          <div>
                            <Input.Datepicker name="rentedUntil" />
                          </div>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="maxed-column">
                          <label htmlFor="dateSignDeed">
                            <I18n value="acquisition.assignment.edit.transfer.dateSignDeed" />
                          </label>
                          <div>
                            <Input.Datepicker name="dateSignDeed" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </StepComponent>
              </StepperComponent>
            </Form>
          </div>
          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={onNewEntityCloseHandler}
            onNewRelation={onNewRelationHandler}
          />
        </div>
      );
    }
  )
);
