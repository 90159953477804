import * as React from "react";
import { memo } from "react";
import * as CSSModules from "react-css-modules";
import List from "./components/list";

const styles = require("./style.scss");

export const EventCenterModule = memo(
  CSSModules(styles, { allowMultiple: true })(() => {
    return (
      <div styleName="event-center">
        <List />
      </div>
    );
  })
);
