import { Account, AccountShare, Provider, SyncStatus } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";
import { Ui, uiTableRow, UiTableRowProps } from "@haywork/modules/ui";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");

interface ListItemComponentProps {
  zebra: boolean;
  account: Account;
  share: AccountShare;
  providers: Provider[];
  isDefaultAccount: boolean;
  onToggle: (share: AccountShare) => void;
  onEdit: (account: Account) => void;
}

@CSSModules(styles, { allowMultiple: true })
class ListItemComponent extends React.Component<
  ListItemComponentProps & UiTableRowProps
> {
  constructor(props) {
    super(props);

    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
  }

  public render() {
    const { displayName, emailAddress, type } = this.props.account;
    const avatar = this.props.providers.find((p) => p.type === type);
    const avatarStyles: React.CSSProperties =
      !!avatar && !!avatar.logoUrl
        ? { backgroundImage: `url("${JSON.stringify(avatar.logoUrl)}")` }
        : null;
    const isHidden = !!this.props.share ? this.props.share.isHidden : false;
    const shareType = !!this.props.share
      ? this.props.share.shareType.toString()
      : null;

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
      >
        <div styleName="list-item" style={this.props.gridTemplateColumns}>
          <div styleName="column">
            <div styleName="email">
              <div styleName="avatar" style={avatarStyles} />
              <div styleName="address">
                <span styleName="emailaddress">{emailAddress}</span>
                {this.renderAccountStatus()}
              </div>
            </div>
          </div>
          <div styleName="column">
            <div styleName="account-name">
              {displayName}
              {this.props.isDefaultAccount && (
                <strong>
                  <ResourceText resourceKey="isDefaultEmailAccount" />
                </strong>
              )}
            </div>
          </div>
          <div styleName="column">
            <div styleName="share-shared-with">
              <div styleName="share-shared-with__title">
                <ResourceText resourceKey="you" />
                {!!isHidden && <i className="far fa-eye-slash" />}
              </div>
              {!!shareType && (
                <div styleName="share-shared-with__info">
                  <ResourceText resourceKey={`shareType.${shareType}`} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private onToggleClickHandler() {
    this.props.onToggle(this.props.share);
  }

  private renderListActions(): React.StatelessComponent[] {
    const isHidden = !!this.props.share ? this.props.share.isHidden : false;
    const toggleStyle = classNames(
      "far",
      !isHidden ? "fa-eye" : "fa-eye-slash"
    );
    const toggleButtonStyle = classNames(
      "btn",
      !isHidden ? "btn-primary" : "btn-gray"
    );

    const toggleButton = () => (
      <button className={toggleButtonStyle} onClick={this.onToggleClickHandler}>
        <i className={toggleStyle} />
      </button>
    );

    const editButton = () => (
      <button className="btn btn-success" onClick={this.onEditClickHandler}>
        <i className="fal fa-pencil" />
      </button>
    );

    return [toggleButton, editButton];
  }

  private renderAccountStatus() {
    const { syncStatus } = this.props.account;
    let status = "";
    let statusIcon = "";

    switch (syncStatus) {
      default:
        status = "active";
        statusIcon = "far fa-sync";
        break;
      case SyncStatus.Unknown:
        status = "unknown";
        statusIcon = "fas fa-question-circle";
        break;
      case SyncStatus.Running:
        status = "okay";
        statusIcon = "far fa-check";
        break;
      case SyncStatus.InvalidCredentials:
      case SyncStatus.Stopped:
        status = "warning";
        statusIcon = "far fa-exclamation-triangle";
        break;
      case SyncStatus.Error:
        status = "danger";
        statusIcon = "far fa-times";
        break;
    }

    const statusStyle = classNames("status", status);

    return (
      <div styleName={statusStyle}>
        <i className={statusIcon} />
        <ResourceText resourceKey={`syncStatus.${syncStatus.toString()}`} />
      </div>
    );
  }

  private onEditClickHandler() {
    this.props.onEdit(this.props.account);
  }
}

export const SharedListItem = uiTableRow(ListItemComponent);
