import { REDUX } from "@haywork/constants";
import * as ActionType from "./single.types";

const setDocument = (payload: ActionType.DynamicDocument) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT,
  ...payload
});

const setDocumentSession = (payload: ActionType.DocumentSession) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_SESSION,
  ...payload
});

const setDocumentStatus = (documentStatus: string) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_STATUS,
  documentStatus
});

const toggleSaveModal = (showSaveModal: boolean) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.TOGGLE_DYNAMIC_DOCUMENTS_SAVE_MODAL,
  showSaveModal
});

export const DynamicDocumentsSingleActions = {
  setDocument,
  setDocumentStatus,
  toggleSaveModal,
  setDocumentSession
};
