import {
  AssignmentSnapShot,
  AssignmentType,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AssigmentWidgetLinkedItemComponent } from "./linked-item.component";

const styles = require("./widget.component.scss");

export interface AssignmentWidgetItem {
  photoUrl: string;
  streetNameAndNumber: string;
  locality: string;
  salePrice: number;
  id: string;
}

interface Props {
  assignmentWidgetItems: AssignmentSnapShot[];
  headerResourceKey: string;
  emptyStateTitleResourceKey: string;
  emptyStateDescriptionResourceKey: string;
  hideEmptyStateImage: boolean;
  onNavigate: (url: string) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentWidgetComponent extends React.Component<Props> {
  public render() {
    const assignments = (this.props.assignmentWidgetItems || [])
      .filter(
        (assignment) =>
          assignment.typeOfAssignment !== AssignmentType.Acquisition
      )
      .slice(0, 3);

    return (
      <div styleName="assignmentWidget">
        <h3 styleName="title">
          <ResourceText resourceKey={this.props.headerResourceKey} />
        </h3>
        {/* No assignments, show empty state */}
        {!assignments.length && (
          <div styleName="emptyState">
            {!this.props.hideEmptyStateImage && <div styleName="icon" />}
            <div styleName="text">
              <h2>
                <ResourceText
                  resourceKey={this.props.emptyStateTitleResourceKey}
                />
              </h2>
              <p>
                <ResourceText
                  resourceKey={this.props.emptyStateDescriptionResourceKey}
                />
              </p>
            </div>
          </div>
        )}

        {/* show assignments */}
        {assignments.map((assignmentWidgetItem, idx) => (
          <ErrorBoundary key={idx}>
            <AssigmentWidgetLinkedItemComponent
              assignmentWidgetItem={assignmentWidgetItem}
              onNavigate={this.props.onNavigate}
            />
          </ErrorBoundary>
        ))}
      </div>
    );
  }
}

export const convertToAssignmentWidgetItemFromSnapShot = (
  assignmentSnapShot: AssignmentSnapShot
): AssignmentWidgetItem => {
  if (!assignmentSnapShot) {
    return;
  }

  return {
    locality: assignmentSnapShot.locality,
    photoUrl: assignmentSnapShot.photo1Url,
    salePrice: assignmentSnapShot.forSale
      ? assignmentSnapShot.salePrice
      : assignmentSnapShot.rentPrice,
    streetNameAndNumber: assignmentSnapShot.streetNameAndNumber,
    id: assignmentSnapShot.id,
  };
};

export const convertToAssignmentWidgetItemFromLinkedItem = (
  linkedAssignment: LinkedAssignment
): AssignmentWidgetItem => {
  if (!linkedAssignment) {
    return;
  }

  return {
    locality: linkedAssignment.locality,
    photoUrl: linkedAssignment.photo1Url,
    salePrice: linkedAssignment.forSale
      ? linkedAssignment.salePrice
      : linkedAssignment.rentPrice,
    streetNameAndNumber: linkedAssignment.streetNameAndNumber,
    id: linkedAssignment.id,
  };
};
