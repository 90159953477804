import * as React from "react";
import { FC, memo } from "react";
import { Switch, Route } from "react-router-dom";
import { MAINROUTES, MLSROUTES } from "@haywork/constants";
import List from "./screens/list";
import Detail from "./screens/detail";

type Props = {};

export const MLSModule: FC<Props> = memo(() => {
  return (
    <Switch>
      <Route path={MAINROUTES.MLS.URI} exact>
        <List />
      </Route>
      <Route path={MLSROUTES.DETAIL.URI}>
        <Detail />
      </Route>
    </Switch>
  );
});
