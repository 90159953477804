import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentDetailCompleteContainer,
  AssignmentDetailDossierContainer,
  AssignmentDetailGeneralContainer,
  AssignmentDetailNetworkContainer,
  AssignmentDetailSearchAssignmentsContainer,
  AssignmentDetailTimelineContainer,
  AssignmentDetailVersionsContainer,
  AssignmentEditContainer,
} from "@haywork/modules/assignment";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
export const AssignmentDetailRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ASSIGNMENTROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(ASSIGNMENTROUTES.GENERAL.URI, { id })} />
        )}
      />
      <Route
        exact
        path={ASSIGNMENTROUTES.GENERAL.URI}
        component={AssignmentDetailGeneralContainer}
      />
      <Route
        exact
        path={ASSIGNMENTROUTES.COMPLETE.URI}
        component={AssignmentDetailCompleteContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.TIMELINE.URI}
        component={AssignmentDetailTimelineContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.NETWORK.URI}
        component={AssignmentDetailNetworkContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.DOSSIER.URI}
        component={AssignmentDetailDossierContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.SEARCH_ASSIGNMENTS.URI}
        component={AssignmentDetailSearchAssignmentsContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.VERSIONS.URI}
        component={AssignmentDetailVersionsContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT.URI}
        component={AssignmentEditContainer}
      />
      <Route
        exact
        path={ASSIGNMENTROUTES.WIDGET_PAGE.URI}
        render={({ match }) => (
          <AuthorizationWidgets
            location={WidgetEntityLocation.Menu}
            type={WidgetEntityType.ObjectAssignment}
            menuType="page"
            id={id}
            widgetId={match.params.widgetId}
          />
        )}
      />
    </Switch>
  );
};
