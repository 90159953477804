import { Dispatch } from ".";
import { AppState } from "@haywork/stores";
import { ParseRequest, ApiType } from "@haywork/services";
import { EventHubClient as MailEventHubClient } from "@haywork/api/mail";
import { EventHubClient as VoipEventHubClient } from "@haywork/api/voip";
import {
  EventHubClient as KolibriEventHubClient,
  ChannelType
} from "@haywork/api/kolibri";
import { EventHubClient as EventCenterEventHubClient } from "@haywork/api/event-center";

const parseRequest = new ParseRequest();

const authenticateMail = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { emailHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employee } = state.account;
    if (!employee) return;

    try {
      const client = new MailEventHubClient(emailHost);
      const { id: personId } = employee;
      return await parseRequest.response(
        client.authenticate({ personId }, realEstateAgencyId),
        ApiType.Email,
        { displayError: false, ignoreUnauthorized: true }
      );
    } catch (error) {
      throw error;
    }
  };
};

const authenticateVoip = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { voipHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employee } = state.account;
    if (!employee) return;

    try {
      const client = new VoipEventHubClient(voipHost);
      const { id: employeeId } = employee;
      return await parseRequest.response(
        client.authenticate({ employeeId }, realEstateAgencyId),
        undefined,
        { displayError: false, ignoreUnauthorized: true }
      );
    } catch (error) {
      throw error;
    }
  };
};

const authenticateKolibri = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    try {
      const client = new KolibriEventHubClient(host);
      return await parseRequest.response(
        client.authenticate(
          {
            channels: [ChannelType.CompanyEntity, ChannelType.PersonalReminders]
          },
          realEstateAgencyId
        ),
        undefined,
        { displayError: false, ignoreUnauthorized: true }
      );
    } catch (error) {
      throw error;
    }
  };
};

const authenticateEventCenter = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employee } = state.account;
    if (!employee) return;

    try {
      const client = new EventCenterEventHubClient(eventCenterHost);
      const { id: employeeId } = employee;
      return await parseRequest.response(
        client.authenticate(
          {
            employeeId
          },
          realEstateAgencyId
        ),
        undefined,
        { displayError: false, ignoreUnauthorized: true }
      );
    } catch (error) {
      throw error;
    }
  };
};

export const LiveDataThunk = {
  authenticateMail,
  authenticateVoip,
  authenticateKolibri,
  authenticateEventCenter
};
