import {
  LinkedRelationGroup,
  RelationGroupSnapShot,
} from "@haywork/api/kolibri";
import { RelationGroupsRequests } from "@haywork/request";
import { ColorUtil, StringUtil } from "@haywork/util";
import escapeRegExp from "lodash-es/escapeRegExp";
import last from "lodash-es/last";
import noop from "lodash-es/noop";
import * as React from "react";
import { InputComponentProps } from "../../input.component";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import { ExtendedRelationGroupSnapShot, PillComponent } from "./pill.component";

const color = ColorUtil.hexToRgb;

interface RelationGroupQueryComponentProps {
  relationGroups: RelationGroupSnapShot[];
}
interface RelationGroupQueryComponentState {
  value: ExtendedRelationGroupSnapShot[];
  readOnly: ExtendedRelationGroupSnapShot[];
  loading: boolean;
  relationGroups: RelationGroupSnapShot[];
}

export class RelationGroupQueryComponentV2 extends React.Component<
  RelationGroupQueryComponentProps & InputComponentProps,
  RelationGroupQueryComponentState
> {
  constructor(props) {
    super(props);

    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedValue = this.renderSelectedValue.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBackspaceDeleteHandler = this.onBackspaceDeleteHandler.bind(this);
    this.onRemoveMarkedForDeleteHandler = this.onRemoveMarkedForDeleteHandler.bind(
      this
    );
    this.onAddHandler = this.onAddHandler.bind(this);
    this.onPillRemoveHandler = this.onPillRemoveHandler.bind(this);

    const { value, readOnly } = this.renderLinkedRelationGroups(
      this.props.value
    );

    this.state = {
      value,
      readOnly,
      loading: false,
      relationGroups: this.props.relationGroups || [],
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RelationGroupQueryComponentProps & InputComponentProps
  ) {
    if (!nextProps) return;

    const { value, readOnly } = this.renderLinkedRelationGroups(
      nextProps.value
    );
    this.setState({
      value,
      readOnly,
      relationGroups: nextProps.relationGroups,
    });
  }

  public render() {
    return (
      <div className="relation-group__query">
        <BaseQueryComponent
          {...this.props}
          {...this.state}
          values={this.state.relationGroups}
          matchOn={this.matchOnHandler}
          optionValue={this.renderOptionValue}
          selectedValue={this.renderSelectedValue}
          onChange={this.onChangeHandler}
          onBackspaceDelete={this.onBackspaceDeleteHandler}
          onRemoveMarkedForDelete={this.onRemoveMarkedForDeleteHandler}
          onAdd={this.onAddHandler}
          disableOption={this.mapDisableOption}
          onAddResource="addRelationGroup"
          forceAdd
          multiple
          hasCustomPill
          disabledValues={this.state.readOnly}
          disabled={this.state.loading}
        >
          {this.state.readOnly.map((group, idx) => (
            <PillComponent group={group} key={idx} onRemove={noop} />
          ))}
        </BaseQueryComponent>
      </div>
    );
  }

  private onChangeHandler(value: any[]) {
    this.fireOnChange(value);
  }

  private onPillRemoveHandler(id: string) {
    const value = this.state.value.filter((v) => v.id !== id);
    this.setState({ value });
    this.fireOnChange(value);
  }

  private onBackspaceDeleteHandler() {
    if (!this.state.value || this.state.value.length === 0) return;
    const lastValue = last(this.state.value);

    if (!lastValue.markedForDelete) {
      const value = this.state.value.map((v) => {
        if (v.id === lastValue.id) {
          return { ...v, markedForDelete: true };
        }
        return v;
      });
      this.setState({ value });
      return false;
    }

    const value = this.state.value.filter((v) => v.id !== lastValue.id);
    this.setState({ value });
    this.fireOnChange(value);
    return false;
  }

  private onRemoveMarkedForDeleteHandler() {
    const value = this.state.value.map((v) => {
      delete v.markedForDelete;
      return v;
    });
    this.setState({ value });
  }

  private onAddHandler(name: string) {
    this.setState({ loading: true });

    RelationGroupsRequests.addRelationGroup(name)
      .then((result) => {
        const value = [
          ...this.state.value,
          {
            ...result,
            markedForDelete: false,
            isReadOnly: false,
          },
        ];

        const relationGroups = [result, ...this.state.relationGroups];

        this.setState({ loading: false, value, relationGroups });
        this.fireOnChange(value);
      })
      .catch(() => this.setState({ loading: false }));
  }

  private fireOnChange(value: ExtendedRelationGroupSnapShot[]) {
    const values = this.prepareValues([...this.state.readOnly, ...value]);
    this.props.onChange(values);
  }

  private matchOnHandler(query: string, value: RelationGroupSnapShot): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.name);
  }

  private renderOptionValue(
    group: RelationGroupSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const styles = { backgroundColor: color(group.backColor) };

    return (
      <div className="option">
        <div className="dot" style={styles} />
        <div
          dangerouslySetInnerHTML={StringUtil.highlight(group.name, query)}
        />
      </div>
    );
  }

  private renderSelectedValue(
    value: ExtendedRelationGroupSnapShot
  ): QueryResultReturnValue<any> {
    return {
      value,
      template: (
        <React.Fragment>
          <PillComponent group={value} onRemove={this.onPillRemoveHandler} />
        </React.Fragment>
      ),
    };
  }

  private mapDisableOption(
    value: RelationGroupSnapShot,
    values: LinkedRelationGroup[]
  ): boolean {
    const ids = values.map((v) => v.id);
    return ids.indexOf(value.id) !== -1;
  }

  private renderLinkedRelationGroups(
    groups: LinkedRelationGroup[]
  ): {
    value: ExtendedRelationGroupSnapShot[];
    readOnly: ExtendedRelationGroupSnapShot[];
  } {
    if (!groups) return { value: [], readOnly: [] };

    const mappedGroups = groups.reduce(
      (state, group) => {
        const reference: any = this.props.relationGroups.find(
          (g) => g.id === group.id
        );
        const name = !!reference ? reference.displayName || reference.name : "";

        if (group.isReadOnly) {
          state.readOnly.push({ ...group, name });
        } else {
          state.value.push({ ...group, name });
        }
        return state;
      },
      { value: [], readOnly: [] }
    );

    return mappedGroups;
  }

  private prepareValues(
    values: ExtendedRelationGroupSnapShot[]
  ): LinkedRelationGroup[] {
    return values.map((v) => {
      const { name, displayName, id, backColor, isReadOnly } = v;
      return {
        id,
        backColor,
        displayName: displayName || name,
        isReadOnly,
      };
    });
  }
}
