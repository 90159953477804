import {
  AcceptanceDetails,
  AcceptanceOption,
  CommissionPaidByOption,
  CommissionTypeOption,
  Language,
  LeaseTermUnitOption,
  ObjectAssignment,
  PriceCode,
  PriceCodeOption,
  RentConditionOption,
  RentOffer,
  VatConditionOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditFinancialRentBogComponent,
  AssignmentEditFinancialRentBogComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  rentOffer: RentOffer;
  vatConditions: VatConditionOption[];
  acceptanceDetails: AcceptanceDetails;
  acceptances: AcceptanceOption[];
  leaseTermUnitOptions: LeaseTermUnitOption[];
  rentConditions: RentConditionOption[];
  hidePrice: boolean;
  priceCodes: PriceCodeOption[];
  commissionPaidByOptions: CommissionPaidByOption[];
  commissionTypeOptions: CommissionTypeOption[];
  languages: Language[];
  defaultLanguage: Language;
  objectAssignment: ObjectAssignment;
  enableHidePriceForObjectAssignmentsOption: boolean;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditFinancialRentBogComponentProps,
  AppState
> = (state) => {
  const { objectAssignment } = state.editable.currentComponentState;
  const {
    rentOffer,
    acceptanceDetails,
    hidePrice,
  }: ObjectAssignment = objectAssignment;
  const {
    acceptances,
    leaseTermUnitOptions,
    rentConditions,
    vatConditions,
    commissionPaidByOptions,
    commissionTypeOptions,
  } = state.main.mastertable.kolibri;
  const { enableHidePriceForObjectAssignmentsOption } = state.company.settings;

  const priceCodes = filterRentPriceCodes(
    state.main.mastertable.kolibri.priceCodes
  );

  return {
    acceptanceDetails,
    rentOffer,
    acceptances,
    leaseTermUnitOptions,
    rentConditions,
    hidePrice,
    vatConditions,
    priceCodes,
    commissionPaidByOptions,
    commissionTypeOptions,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage,
    objectAssignment,
    enableHidePriceForObjectAssignmentsOption,
  };
};

const filterRentPriceCodes = (
  priceCodes: PriceCodeOption[]
): PriceCodeOption[] => {
  const filteredPriceCodes = priceCodes.filter(
    (priceCode) =>
      priceCode.value === PriceCode.ToBeNegotiated ||
      priceCode.value === PriceCode.OnRequest
  );

  const askingPriceOption = {
    ...priceCodes.find(
      (priceCode) => priceCode.value === PriceCode.AskingPrice
    ),
  };
  askingPriceOption.displayName = intlContext.formatMessage({
    id: "rentalPrice",
  });
  filteredPriceCodes.unshift(askingPriceOption);

  return filteredPriceCodes;
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditFinancialRentBogComponentProps
> = (dispatch) => ({});

export type AssignmentEditFinancialRentBogContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditFinancialRentBogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditFinancialRentBogComponent);
