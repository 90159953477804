import { AssignmentType, RealEstateGroup } from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import {
  EmployeeThunk,
  MapDispatchToProps,
  OfficeThunk
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { AssignmentListActions } from "@haywork/stores/acquisition/listV2";
import { connect, MapStateToProps } from "react-redux";
import {
  assignmentPhaseValues,
  availabilityStatusValues,
  employeeValues,
  listingTypeValues,
  officeValues
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  realEstateGroupValues: SingleFilterValue[];
  assignmentTypeValues: SingleFilterValue[];
  saleOrRentValues: SingleFilterValue[];
  listingTypeValues: SingleFilterValue[];
  assignmentPhaseValues: SingleFilterValue[];
  availabilityStatusValues: SingleFilterValue[];
  officeValues: SingleFilterValue[];
  employeeValues: SingleFilterValue[];
  archivedValues: SingleFilterValue[];
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
  getOffices: () => Promise<void>;
  getEmployees: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.acquisition.listV2;
  const realEstateGroupValues: SingleFilterValue[] = [
    {
      label: `realEstateGroups.${RealEstateGroup.Residential.toString()}`,
      value: RealEstateGroup.Residential
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Commercial.toString()}`,
      value: RealEstateGroup.Commercial
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Agricultural.toString()}`,
      value: RealEstateGroup.Agricultural
    }
  ];
  const assignmentTypeValues: SingleFilterValue[] = [
    {
      label: `assignmentTypes.${AssignmentType.Object.toString()}`,
      value: AssignmentType.Object
    },
    {
      label: `assignmentTypes.${AssignmentType.Project.toString()}`,
      value: AssignmentType.Project
    }
  ];
  const saleOrRentValues: SingleFilterValue[] = [
    { label: "assignment.filter.forSale", value: "forSale" },
    { label: "assignment.filter.forRent", value: "forRent" }
  ];
  const archivedValues: SingleFilterValue[] = [
    { label: "assignment.filter.archived", value: "archived" }
  ];

  return {
    filters,
    realEstateGroupValues,
    assignmentTypeValues,
    saleOrRentValues,
    listingTypeValues: listingTypeValues(state),
    assignmentPhaseValues: assignmentPhaseValues(state),
    availabilityStatusValues: availabilityStatusValues(state),
    officeValues: officeValues(state),
    employeeValues: employeeValues(state),
    archivedValues
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(AssignmentListActions.setFilters(filters)),
  getOffices: () => dispatch(OfficeThunk.getOffices(true, 100)),
  getEmployees: () => dispatch(EmployeeThunk.getEmployees(true, 100))
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
