import { AgendaItem } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import {
  RecurrenceOption,
  SchedulerRecurrenceModalContainerProps,
} from "@haywork/modules/scheduler";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RRule } from "rrule";

const styles = require("./recurrence-modal.component.scss");

enum EndingOption {
  Never = "Never",
  After = "After",
  On = "On",
}

enum MonthlyOption {
  DaySpecific = "DaySpecific",
  CountOfDay = "CountOfDay",
}

enum YearlyOption {
  OnDateOfMonth = "OnDateOfMonth",
  onSpecificDayOfMonth = "onSpecificDayOfMonth",
}

export interface SchedulerRecurrenceModalComponentProps {
  showModal: boolean;
  onClose: () => void;
}
interface State {
  selectedRecurrenceOption?: RecurrenceOption;
  selectedMonthOption?: RecurrenceOption;
  selectedWeekNumberOption?: RecurrenceOption;
  selectedWeekdayOption?: RecurrenceOption;
  selectedEndingOption?: EndingOption;
  selectedMonthlyOption?: MonthlyOption;
  selectedYearlyOption?: YearlyOption;
}
type Props = SchedulerRecurrenceModalComponentProps &
  SchedulerRecurrenceModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SchedulerRecurrenceModalComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.setInitialState = this.setInitialState.bind(this);
    this.setFormControls = this.setFormControls.bind(this);

    this.setInitialState();
    this.setFormControls();
  }

  public render() {
    return (
      <div>
        <Modal
          visible={this.props.showModal}
          onClose={() => this.props.onClose()}
        >
          <ModalHeader title="recurring" close />
          <ModalBody>
            <Form
              name="recurrence"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              form={(formRef) => (this.form = formRef)}
            >
              <div className="form__row">
                <div className="form__group">
                  <div className="column" styleName="label-pre">
                    <label htmlFor="recurrence" className="pre">
                      <ResourceText resourceKey="recurringAppointment" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.NewSelect
                      name="recurrence"
                      values={this.props.recurrenceOptions}
                      displayProp="text"
                    />
                  </div>
                </div>
              </div>
              {this.state.selectedRecurrenceOption &&
                this.state.selectedRecurrenceOption.value === RRule.DAILY && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="label-pre">
                        <label className="pre">
                          <ResourceText resourceKey="every" />
                        </label>
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="inputNumberShort">
                        <Input.Number name="interval" min={1} showActions />
                      </div>
                      <div className="column__spacer" />
                      <div className="column pre">
                        <ResourceText resourceKey="everyDayAffix" />
                      </div>
                    </div>
                  </div>
                )}
              {this.state.selectedRecurrenceOption &&
                this.state.selectedRecurrenceOption.value === RRule.WEEKLY && (
                  <div>
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="label-pre">
                          <label className="pre">
                            <ResourceText resourceKey="every" />
                          </label>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="inputNumberShort">
                          <Input.Number name="interval" min={1} showActions />
                        </div>
                        <div className="column__spacer" />
                        <div className="column pre">
                          <ResourceText resourceKey="everyWeekAffix" />
                        </div>
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__group stretch">
                        {this.props.weekdayOptions.map((weekdayOption, idx) => {
                          return (
                            <div className="column" key={idx}>
                              <Input.CheckBox
                                name={weekdayOption.text}
                                label={weekdayOption.text}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.selectedRecurrenceOption &&
                this.state.selectedRecurrenceOption.value === RRule.MONTHLY && (
                  <div className="form__row">
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="label-pre">
                          <label className="pre">
                            <ResourceText resourceKey="every" />
                          </label>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="inputNumberShort">
                          <Input.Number name="interval" min={1} showActions />
                        </div>
                        <div className="column__spacer" />
                        <div className="column pre">
                          <ResourceText resourceKey="everyMonthAffix" />
                        </div>
                      </div>
                    </div>
                    <Input.RadioGroup name="selectedMonthlyOption">
                      <div className="form__row">
                        <div className="form__group">
                          <div className="column" styleName="label-pre">
                            <Input.Radio
                              label="onThe"
                              value={MonthlyOption.DaySpecific}
                            />
                          </div>
                          <div className="column__spacer" />
                          <div className="column" styleName="inputNumberShort">
                            <Input.Number
                              name="onTheDayOfMonth"
                              max={31}
                              min={1}
                              showActions
                              disabled={
                                this.state.selectedMonthlyOption !==
                                MonthlyOption.DaySpecific
                              }
                            />
                          </div>
                          <div className="column__spacer" />
                          <div className="column pre">
                            <ResourceText resourceKey="numthOfTheMonth" />
                          </div>
                        </div>
                      </div>
                      <div className="form__row">
                        <div className="form__group">
                          <div className="column" styleName="label-pre">
                            <Input.Radio
                              label="onThe"
                              value={MonthlyOption.CountOfDay}
                            />
                          </div>
                          <div className="column__spacer" />
                          <div className="column">
                            <Input.NewSelect
                              name="weekNumberOption"
                              values={this.props.weekNumberOptions}
                              displayProp="text"
                              disabled={
                                this.state.selectedMonthlyOption !==
                                MonthlyOption.CountOfDay
                              }
                            />
                          </div>
                          <div className="column__spacer" />
                          <div className="column">
                            <Input.NewSelect
                              name="weekdayOption"
                              values={this.props.weekdayOptions}
                              displayProp="text"
                              disabled={
                                this.state.selectedMonthlyOption !==
                                MonthlyOption.CountOfDay
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Input.RadioGroup>
                  </div>
                )}
              {this.state.selectedRecurrenceOption &&
                this.state.selectedRecurrenceOption.value === RRule.YEARLY && (
                  <div className="form__row">
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column" styleName="label-pre">
                          <label className="pre">
                            <ResourceText resourceKey="every" />
                          </label>
                        </div>
                        <div className="column__spacer" />
                        <div className="column" styleName="inputNumberShort">
                          <Input.Number name="interval" min={1} showActions />
                        </div>
                        <div className="column__spacer" />
                        <div className="column pre">
                          <ResourceText resourceKey="everyYearAffix" />
                        </div>
                      </div>
                    </div>
                    <div className="form__row">
                      <Input.RadioGroup name="selectedYearlyOption">
                        <div className="form__row">
                          <div className="form__group">
                            <div className="column" styleName="label-pre">
                              <Input.Radio
                                label="on"
                                value={YearlyOption.OnDateOfMonth}
                              />
                            </div>
                            <div className="column__spacer" />
                            <div
                              className="column"
                              styleName="inputNumberShort"
                            >
                              <Input.Number
                                name="onTheDayOfMonth"
                                max={31}
                                min={1}
                                disabled={
                                  this.state.selectedYearlyOption !==
                                  YearlyOption.OnDateOfMonth
                                }
                                showActions
                              />
                            </div>
                            <div className="column__spacer" />
                            <div className="column">
                              <Input.NewSelect
                                name="monthOption"
                                values={this.props.monthOptions}
                                displayProp="text"
                                disabled={
                                  this.state.selectedYearlyOption !==
                                  YearlyOption.OnDateOfMonth
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form__row">
                          <div className="form__group">
                            <div className="column" styleName="label-pre">
                              <Input.Radio
                                label="onThe"
                                value={YearlyOption.onSpecificDayOfMonth}
                              />
                            </div>
                            <div className="column__spacer" />
                            <div className="column">
                              <Input.NewSelect
                                name="weekNumberOption"
                                values={this.props.weekNumberOptions}
                                displayProp="text"
                                disabled={
                                  this.state.selectedYearlyOption !==
                                  YearlyOption.onSpecificDayOfMonth
                                }
                              />
                            </div>
                            <div className="column__spacer" />
                            <div className="column">
                              <Input.NewSelect
                                name="weekdayOption"
                                values={this.props.weekdayOptions}
                                displayProp="text"
                                disabled={
                                  this.state.selectedYearlyOption !==
                                  YearlyOption.onSpecificDayOfMonth
                                }
                              />
                            </div>
                            <div className="column__spacer" />
                            <div className="column">
                              <Input.NewSelect
                                name="monthOption2"
                                values={this.props.monthOptions}
                                displayProp="text"
                                disabled={
                                  this.state.selectedYearlyOption !==
                                  YearlyOption.onSpecificDayOfMonth
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Input.RadioGroup>
                    </div>
                  </div>
                )}
              <div className="form__row">
                <div className="form__group">
                  <div className="column" styleName="label-pre">
                    <label htmlFor="startingDate" className="pre">
                      <ResourceText resourceKey="startingOn" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.Datepicker name="startingDate" />
                  </div>
                </div>
              </div>
              <div className="form__row">
                <label htmlFor="ending">
                  <ResourceText resourceKey="ending" />
                </label>
                <Input.RadioGroup name="ending">
                  <Input.Radio label="never" value={EndingOption.Never} />
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="label-pre">
                        <Input.Radio
                          label="after"
                          value={EndingOption.After}
                          data-cy="CY-recurrencyAfter"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="inputNumberShort">
                        <Input.Number
                          name="afterCount"
                          min={1}
                          disabled={
                            this.state.selectedEndingOption !==
                            EndingOption.After
                          }
                          showActions
                          data-cy="CY-recurrencyAfterCount"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column pre">
                        <ResourceText resourceKey="afterAffix" />
                      </div>
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="label-pre">
                        <Input.Radio label="on" value={EndingOption.On} />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.Datepicker
                          name="endingDate"
                          disabled={
                            this.state.selectedEndingOption !== EndingOption.On
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Input.RadioGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              onClick={(e) => {
                this.props.onClose();
              }}
            >
              <ResourceText resourceKey="cancel" />
            </button>
            <button
              className="btn btn-success"
              type="button"
              onClick={() => this.form.submit()}
              data-cy="CY-recurrencySave"
            >
              <ResourceText resourceKey="done" />
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  public onSubmitHandler(formReturnValue: FormReturnValue) {
    const recurrencyRuleOptions = { ...this.props.recurrenceRules.options };

    if (formReturnValue.recurrence.value !== null) {
      recurrencyRuleOptions.freq = formReturnValue.recurrence.value;
    }

    if (formReturnValue.interval) {
      recurrencyRuleOptions.interval = formReturnValue.interval;
    }

    switch (formReturnValue.ending.toString()) {
      case EndingOption.Never:
        recurrencyRuleOptions.until = null;
        recurrencyRuleOptions.count = null;
        break;
      case EndingOption.On:
        recurrencyRuleOptions.until = formReturnValue.endingDate;
        break;
      case EndingOption.After:
        recurrencyRuleOptions.count = formReturnValue.afterCount;
        break;
      default:
        break;
    }

    switch (this.state.selectedRecurrenceOption.value) {
      case RRule.DAILY:
        recurrencyRuleOptions.bymonth = null;
        recurrencyRuleOptions.bymonthday = null;
        recurrencyRuleOptions.byweekday = null;
        recurrencyRuleOptions.bysetpos = null;
        recurrencyRuleOptions.byyearday = null;
        break;
      case RRule.WEEKLY:
        if (!recurrencyRuleOptions.byweekday) {
          recurrencyRuleOptions.byweekday = [];
        }
        const weekdays: number[] = [];
        for (let i = 0; i < this.props.weekdayOptions.length; i++) {
          const weekdayOption = this.props.weekdayOptions[i];
          const formValue = formReturnValue[weekdayOption.text];
          if (formValue) {
            weekdays.push(weekdayOption.value);
          }
        }
        recurrencyRuleOptions.byweekday = weekdays;
        break;
      case RRule.MONTHLY:
        if (
          formReturnValue.selectedMonthlyOption &&
          formReturnValue.selectedMonthlyOption === MonthlyOption.DaySpecific
        ) {
          recurrencyRuleOptions.bymonthday = formReturnValue.onTheDayOfMonth;
          recurrencyRuleOptions.bysetpos = null;
          recurrencyRuleOptions.byweekday = null;
        } else if (
          formReturnValue.selectedMonthlyOption &&
          formReturnValue.selectedMonthlyOption === MonthlyOption.CountOfDay
        ) {
          recurrencyRuleOptions.bysetpos =
            formReturnValue.weekNumberOption.value;
          recurrencyRuleOptions.byweekday = formReturnValue.weekdayOption.value;
          recurrencyRuleOptions.bymonthday = null;
        }
        break;
      case RRule.YEARLY:
        if (
          formReturnValue.selectedYearlyOption &&
          formReturnValue.selectedYearlyOption === YearlyOption.OnDateOfMonth
        ) {
          recurrencyRuleOptions.bymonth = formReturnValue.monthOption.value;
          recurrencyRuleOptions.bymonthday = formReturnValue.onTheDayOfMonth;
          recurrencyRuleOptions.byweekday = null;
          recurrencyRuleOptions.bysetpos = null;
        } else if (
          formReturnValue.selectedYearlyOption &&
          formReturnValue.selectedYearlyOption ===
            YearlyOption.onSpecificDayOfMonth
        ) {
          recurrencyRuleOptions.bysetpos =
            formReturnValue.weekNumberOption.value;
          recurrencyRuleOptions.byweekday = formReturnValue.weekdayOption.value;
          recurrencyRuleOptions.bymonth = formReturnValue.monthOption2.value;
          recurrencyRuleOptions.bymonthday = null;
        }
      default:
        break;
    }
    // Remove dtstart before updating pattern since it's not supported by kendo.ui.Scheduler.
    // Remove autocreated byhour, byminute and bysecond. Set wkst to correct constant
    const optionsString = RRule.optionsToString({
      ...recurrencyRuleOptions,
      wkst: RRule.MO,
      dtstart: null,
      byhour: [],
      byminute: [],
      bysecond: [],
    });

    const agendaItemToUpdate: AgendaItem = {
      ...this.props.agendaItem,
      recurrencePattern: optionsString,
      isRecurringEvent: true,
    };
    this.props.updateAgendaItem(agendaItemToUpdate, this.props.path);
    this.props.onClose();
  }

  private setInitialState() {
    const { recurrenceOptions } = this.props;
    const recurrenceRulesOptions = this.props.recurrenceRules.options;
    const selectedRecurrenceOption = recurrenceOptions.find(
      (recurrenceOption) =>
        recurrenceOption.value === recurrenceRulesOptions.freq
    );
    const selectedMonthOption = this.props.monthOptions.find(
      (monthOption) => monthOption.value === recurrenceRulesOptions.bymonth
    );

    let selectedWeekdayOption: RecurrenceOption;
    if (recurrenceRulesOptions.byweekday) {
      selectedWeekdayOption = this.props.weekdayOptions.find(
        (weekdayOption) =>
          weekdayOption.value === recurrenceRulesOptions.byweekday
      );
    }

    let selectedWeekNumberOption: RecurrenceOption;
    if (recurrenceRulesOptions.bysetpos && recurrenceRulesOptions.bysetpos) {
      selectedWeekNumberOption = this.props.weekNumberOptions.find(
        (weekNumberOption) =>
          weekNumberOption.value === recurrenceRulesOptions.bysetpos[0]
      );
    }

    let selectedEndingOption: EndingOption;
    if (
      recurrenceRulesOptions.count === null &&
      recurrenceRulesOptions.until === null
    ) {
      selectedEndingOption = EndingOption.Never;
    } else if (recurrenceRulesOptions.count) {
      selectedEndingOption = EndingOption.After;
    } else if (recurrenceRulesOptions.until) {
      selectedEndingOption = EndingOption.On;
    }

    let selectedMonthlyOption: MonthlyOption;
    if (
      recurrenceRulesOptions.bymonthday &&
      recurrenceRulesOptions.bymonthday.length > 0
    ) {
      selectedMonthlyOption = MonthlyOption.DaySpecific;
    }

    // monthly = 1
    if (recurrenceRulesOptions.freq === 1) {
      if (
        recurrenceRulesOptions.bysetpos &&
        recurrenceRulesOptions.bymonthday
      ) {
        selectedMonthlyOption = MonthlyOption.CountOfDay;
      } else {
        selectedMonthlyOption = MonthlyOption.DaySpecific;
      }
    }

    if (!selectedMonthlyOption) {
      selectedMonthlyOption = MonthlyOption.DaySpecific;
    }

    // yearly = 0
    let selectedYearlyOption: YearlyOption = YearlyOption.OnDateOfMonth;
    if (recurrenceRulesOptions.freq === 0) {
      if (!recurrenceRulesOptions.byweekday) {
        selectedYearlyOption = YearlyOption.OnDateOfMonth;
      } else {
        selectedYearlyOption = YearlyOption.onSpecificDayOfMonth;
      }
    }

    this.state = {
      selectedRecurrenceOption,
      selectedMonthOption,
      selectedWeekNumberOption,
      selectedWeekdayOption,
      selectedEndingOption,
      selectedMonthlyOption,
      selectedYearlyOption,
    };
  }

  private setFormControls() {
    const recurrenceRulesOptions = this.props.recurrenceRules.options;
    let byMonthDay: number;
    const currentMonthNumber = new Date().getMonth();

    if (
      recurrenceRulesOptions.bymonthday &&
      recurrenceRulesOptions.bymonthday.length > 0
    ) {
      byMonthDay = recurrenceRulesOptions.bymonthday[0] as number;
    }
    this.formControls = {
      recurrence: {
        value: this.state.selectedRecurrenceOption,
        onChange: (ref, get) => {
          this.setState({ selectedRecurrenceOption: ref.value });
        },
      },
      interval: { value: recurrenceRulesOptions.interval },
      startingDate: { value: this.props.startDateTime },
      ending: {
        value: this.state.selectedEndingOption
          ? this.state.selectedEndingOption
          : "",
        onChange: (ref) => {
          switch (ref.value) {
            case EndingOption.After:
              this.setState({ selectedEndingOption: EndingOption.After });
              return { afterCount: "1", endingDate: "" };
            case EndingOption.Never:
              this.setState({ selectedEndingOption: EndingOption.Never });
              return { afterCount: "", endingDate: "" };
            case EndingOption.On:
              this.setState({ selectedEndingOption: EndingOption.On });
              return { afterCount: "", endingDate: new Date() };
            default:
              break;
          }
        },
      },
      afterCount: {
        value: recurrenceRulesOptions.count ? recurrenceRulesOptions.count : "",
      },
      endingDate: {
        value: recurrenceRulesOptions.until ? recurrenceRulesOptions.until : "",
      },
      selectedMonthlyOption: {
        value: this.state.selectedMonthlyOption
          ? this.state.selectedMonthlyOption
          : "'",
        onChange: (ref) => {
          this.setState({ selectedMonthlyOption: ref.value });
        },
      },
      selectedYearlyOption: {
        value: this.state.selectedYearlyOption
          ? this.state.selectedYearlyOption
          : "'",
        onChange: (ref) => {
          this.setState({ selectedYearlyOption: ref.value });
        },
      },
      onTheDayOfMonth: { value: byMonthDay ? byMonthDay : 1 },
      weekNumberOption: {
        value: recurrenceRulesOptions.bysetpos
          ? { value: recurrenceRulesOptions.bysetpos[0] }
          : { value: 1 },
      },
      weekdayOption: {
        value: recurrenceRulesOptions.byweekday
          ? { value: recurrenceRulesOptions.byweekday[0] }
          : { value: 1 },
      },
      monthOption: {
        value: recurrenceRulesOptions.bymonth
          ? { value: recurrenceRulesOptions.bymonth[1] }
          : { value: currentMonthNumber },
      },
      monthOption2: {
        value: recurrenceRulesOptions.bymonth
          ? { value: recurrenceRulesOptions.bymonth[1] }
          : { value: currentMonthNumber },
      },
    };

    const weekDays = recurrenceRulesOptions.byweekday
      ? [...(recurrenceRulesOptions.byweekday as number[])]
      : [];
    this.props.weekdayOptions.forEach(
      (weekdayOption) =>
        (this.formControls[weekdayOption.text] = {
          value: weekDays.indexOf(weekdayOption.value) > -1,
        })
    );
  }
}
