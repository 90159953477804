import {
  InvoiceOrderByField,
  InvoiceSnapShot,
  SortOrder,
  BatchItem,
  BatchItemType,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import EmptyState from "@haywork/components/ui/empty-state";
import List, {
  ActiveFilters,
  FilterConfig,
  ListColumnConfig,
  ListRefProps,
  ListWrapper,
} from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef } from "react";
import * as CSSModules from "react-css-modules";
import Filters from "./components/filters";
import Row from "./components/row";
import { ListContainerProps } from "./list.container";
import { InvoiceListAction } from "./components/row-actions/row-actions";
import { RouteUtil } from "@haywork/util";
import { INVOICEROUTES } from "@haywork/constants";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import PrintLoader from "@haywork/components/ui/print-loader";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type ListComponentProps = {};
type Props = ListComponentProps & ListContainerProps;

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      invoices,
      totalCount,
      order,
      filters,
      scrollOffset,
      currentAccount,
      setOrdering,
      setFilters,
      setScrollOffset,
      getListItems,
      navigate,
      createNewInvoice,
      unArchive,
      archive,
      updateListItem,
      deleteListInvoice,
      exportListToExcel,
      downloadInvoice,
      createBatchDownload,
      uploadFilesFromUri,
      createNewEmailWithAttachments,
    }) => {
      const listRef = useRef<ListRefProps>();

      const [loading, setLoading] = React.useState(false);

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: "number",
              width: 172,
              sortConfig: [InvoiceOrderByField.InvoiceNumber],
            },
            {
              label: "amount",
              width: 128,
              sortConfig: [InvoiceOrderByField.InvoiceAmount],
            },
            {
              label: "date",
              width: 128,
              sortConfig: [InvoiceOrderByField.InvoiceDate],
            },
            {
              label: "dueDate",
              width: 144,
              sortConfig: [
                InvoiceOrderByField.InvoiceDueDate,
                InvoiceOrderByField.InvoiceOverdue,
              ],
            },
            {
              label: "status",
              width: 128,
              sortConfig: [InvoiceOrderByField.InvoiceStatus],
            },
            {
              label: "for",
              width: 300,
              autoFill: true,
            },
            {
              label: "assignment",
              width: 220,
            },
          ],
          withActions: true,
        } as ListColumnConfig;
      }, []);

      const onSortChange = useCallback(
        (sortColumn: InvoiceOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const prepareInvoiceMail = useCallback(
        async (item: InvoiceSnapShot) => {
          setLoading(true);
          try {
            const file: BatchItem = {
              id: item.id,
              type: BatchItemType.Invoice,
            };
            const items = await createBatchDownload([file]);
            if (!items) return;
            const attachments = await uploadFilesFromUri(currentAccount, items);
            if (!attachments) return;
            createNewEmailWithAttachments(
              attachments,
              item.linkedRelations ? item.linkedRelations : undefined
            );
          } finally {
            setLoading(false);
          }
        },
        [
          setLoading,
          createBatchDownload,
          uploadFilesFromUri,
          createNewEmailWithAttachments,
          currentAccount,
        ]
      );

      const onAction = useCallback(
        (item: InvoiceSnapShot, action: InvoiceListAction) => {
          const { id } = item;

          switch (action) {
            case InvoiceListAction.Edit: {
              navigate(route(INVOICEROUTES.DETAIL.URI, { id }));
              break;
            }
            case InvoiceListAction.Archive: {
              updateListItem({ ...item, isActive: false });
              archive(id);
              break;
            }
            case InvoiceListAction.UnArchive: {
              updateListItem({ ...item, isActive: true });
              unArchive(id);
              break;
            }
            case InvoiceListAction.Delete: {
              deleteListInvoice(id);
              break;
            }
            case InvoiceListAction.Download: {
              downloadInvoice(id);
              break;
            }
            case InvoiceListAction.Email: {
              prepareInvoiceMail(item);
              break;
            }
            default: {
              break;
            }
          }
        },
        [
          navigate,
          unArchive,
          archive,
          updateListItem,
          deleteListInvoice,
          currentAccount,
        ]
      );

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="invoices"
                filters={filters}
                onChange={onActiveFiltersChange}
              />
            }
            actions={
              <Button
                label="invoices.list.export"
                category="export"
                icon={<Icon name="file-excel" light color={Colors.White} />}
                onClick={exportListToExcel}
              />
            }
          >
            <List
              name="invoices"
              rowHeight={48}
              columnConfig={columnConfig}
              data={invoices}
              totalCount={totalCount}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              onAction={onAction}
              ref={listRef}
              emptyState={
                <EmptyState
                  icon="euro-sign"
                  title="invoices.list.emptyStateTitle"
                  subTitle="invoices.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="invoices.list.emptyState.action"
                      category="primary"
                      onClick={createNewInvoice}
                    />
                  }
                />
              }
            >
              {(data: InvoiceSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigate={navigate}
                  canMail={!!currentAccount}
                />
              )}
            </List>
          </ListWrapper>
          <PrintLoader visible={loading} type="invoice" />
        </div>
      );
    }
  )
);
