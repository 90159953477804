import {
  LinkedAssignment,
  LinkedRelation,
  MergeField,
  MergeFieldCategory,
  MergeFieldType,
  MergeTemplate,
  TemplateDefinitionSnapShot,
  TemplateDefintionAttachmentBlob,
} from "@haywork/api/kolibri";
import { Account, File as EmailFile, UploadResponse } from "@haywork/api/mail";
import { intlContext } from "@haywork/app";
import { MAIL_LIMIT } from "@haywork/constants";
import { SelectDossierItems } from "@haywork/modules/shared/components/dossier-v2";
import { Ui } from "@haywork/modules/ui";
import {
  EmailUtil,
  FileUploadObject,
  FileUtil,
  UploadUtil,
} from "@haywork/util";
import { MappedMergeField, MergeFieldDataType } from "@haywork/util/email";
import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import differenceBy from "lodash-es/differenceBy";
import first from "lodash-es/first";
import get from "lodash-es/get";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EmailTemplates } from "../email-templates";
import { MergeFields } from "../merge-fields";
import { ResourceText } from "../resource-text/resource-text.component";
import { Attachments, Phrases, Signatures, Upload } from "./components";
import { EditorContainerProps } from "./editor.container";
import { TemplateMergeData } from "@haywork/middleware";
import { ConfirmComponent } from "@haywork/modules/shared";

const styles = require("./editor.component.scss");

const fontFamilies =
  "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Century Gothic=Century Gothic, CenturyGothic, AppleGothic, sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;";

const placeholderImageSrc = (name: string) => {
  try {
    const canvas = document.createElement("canvas");
    canvas.height = 300;
    canvas.width = 240;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#EEEEEE";
    ctx.fillRect(0, 0, 240, 300);
    ctx.fillStyle = "#777777";
    ctx.font = "15px Arial";
    ctx.textAlign = "center";
    ctx.fillText(name, 120, 80);
    ctx.font = "12px Arial";
    ctx.fillText("Klik op de afbeelding en sleep de", 120, 110);
    ctx.fillText("hoeken om het formaat aan te passen.", 120, 126);
    ctx.fillText("Houd CTRL (Windows) of CMD (Mac)", 120, 150);
    ctx.fillText("ingedrukt tijdens het slepen om", 120, 166);
    ctx.fillText("het formaat vrij aan te passen.", 120, 182);
    return canvas.toDataURL();
  } catch (error) {
    return `https://via.placeholder.com/240x300?text=${name}`;
  }
};

export enum EditorType {
  Email = "Email",
  Full = "Full",
  CreateTemplate = "CreateTemplate",
}
enum FileExplorerTarget {
  Inline = "Inline",
  Attachment = "Attachment",
}

export interface EditorComponentProps {
  initialValue: string;
  free?: boolean;
  type?: EditorType;
  uploadUrl: string;
  attachments?: TemplateDefintionAttachmentBlob[];
  files?: EmailFile[];
  uploadShouldBePrivate?: boolean;
  mergefieldCategoryWhitelist?: MergeFieldCategory[];
  mergefieldCategoryBlacklist?: MergeFieldCategory[];
  mergefieldData?: MappedMergeField[];
  selectedAccount?: Account;
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  selectedTemplate?: TemplateDefinitionSnapShot;
  changesAfterTemplateSelect?: boolean;
  onChange: (content: string) => void;
  onFileChange?: (files: EmailFile[]) => void;
  onAttachmentChange?: (attachments: FileUploadObject[]) => void;
  onTemplateSubjectChange?: (subject: MergeTemplate) => void;
  onSelectTemplate?: (selectedTemplate: TemplateDefinitionSnapShot) => void;
  onSetHasChangesAfterTemplateSelect?: (hasChanges: boolean) => void;
}
interface State {
  phrasesModalVisible: boolean;
  fileExplorerTarget: FileExplorerTarget;
  phrasesRef: HTMLDivElement;
  uploadFiles: FileUploadObject[];
  files: EmailFile[];
  attachments: FileUploadObject[];
  fileUploadAccepts: string;
  mergeFieldControlVisible: boolean;
  mergeFieldParent: HTMLElement;
  signatureParent: HTMLElement;
  signatureControlVisible: boolean;
  hasSetSignature: boolean;
  editorInited: boolean;
  templateParent: HTMLElement;
  templateControlVisible: boolean;
  selectedTemplate: TemplateDefinitionSnapShot;
  dossierFilesModalVisible: boolean;
  templateOverwriteWarningVisible: boolean;
  changesAfterTemplateSelect: boolean;
}
type Props = EditorComponentProps & EditorContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EditorComponent extends React.Component<Props, State> {
  private toolbarConfig: string;
  private toolbarPlugins: string[];
  private contentCSS: string;
  private editorInstance: any;
  private fileUploadRef: HTMLInputElement;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFileUploadChange = this.onFileUploadChange.bind(this);
    this.onPhraseAdd = this.onPhraseAdd.bind(this);
    this.onPhrasesClose = this.onPhrasesClose.bind(this);
    this.onEditorInit = this.onEditorInit.bind(this);
    this.onUploadClose = this.onUploadClose.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.onUploadCompleted = this.onUploadCompleted.bind(this);
    this.onAttachmentsChange = this.onAttachmentsChange.bind(this);
    this.onMergeFieldsHide = this.onMergeFieldsHide.bind(this);
    this.onMergeFieldAdd = this.onMergeFieldAdd.bind(this);
    this.setupEditor = this.setupEditor.bind(this);
    this.onSelectSignatureTemplate = this.onSelectSignatureTemplate.bind(this);
    this.onSignaturesHide = this.onSignaturesHide.bind(this);
    this.setDefaultSignature = this.setDefaultSignature.bind(this);
    this.onTemplateHide = this.onTemplateHide.bind(this);
    this.onAddTemplate = this.onAddTemplate.bind(this);
    this.setEditorContent = this.setEditorContent.bind(this);
    this.mapEmailFilesAndAttachments =
      this.mapEmailFilesAndAttachments.bind(this);
    this.setDossierItemFiles = this.setDossierItemFiles.bind(this);
    this.mapMergeTemplateData = this.mapMergeTemplateData.bind(this);
    this.cancelTemplateOverwrite = this.cancelTemplateOverwrite.bind(this);
    this.confirmTemplateOverwrite = this.confirmTemplateOverwrite.bind(this);
    this.triggerFileUploadClick = this.triggerFileUploadClick.bind(this);

    const { attachments, files } = this.mapEmailFilesAndAttachments();
    const signatureMatch =
      /<hayworksignature>([\w\W]+?)?<\/hayworksignature>/g.exec(
        this.props.initialValue
      );
    const hasSetSignature = !!signatureMatch && !!signatureMatch[1];

    this.state = {
      phrasesRef: null,
      phrasesModalVisible: false,
      fileExplorerTarget: FileExplorerTarget.Inline,
      uploadFiles: [],
      files,
      attachments,
      fileUploadAccepts: "*",
      mergeFieldControlVisible: false,
      mergeFieldParent: null,
      signatureControlVisible: false,
      signatureParent: null,
      hasSetSignature,
      editorInited: false,
      templateParent: null,
      templateControlVisible: false,
      selectedTemplate: this.props.selectedTemplate || null,
      dossierFilesModalVisible: false,
      templateOverwriteWarningVisible: false,
      changesAfterTemplateSelect:
        this.props.changesAfterTemplateSelect || false,
    };

    let toolbarConfig = [
      "undo redo",
      "fontselect fontsizeselect formatselect",
      "alignleft aligncenter alignright alignjustify",
      "forecolor backcolor",
      "bold italic underline",
      "bullist numlist outdent indent",
    ];
    const plugins = [
      "textcolor",
      "lists",
      "link",
      "code",
      "image",
      "media",
      "advlist",
      "noneditable",
      "colorpicker",
      "nonbreaking",
    ];
    const contentCSS = ["/assets/css/editor.css"];

    switch (this.props.type) {
      case EditorType.Email: {
        toolbarConfig = [
          ...["addemailtemplate"],
          ...toolbarConfig,
          ...[
            "link phrases",
            "addimage addattachment adddossierattachment",
            "addemailsignature",
          ],
        ];
        plugins.push("fullpage");
        contentCSS.push("/assets/css/editor-preview.css");
        break;
      }
      case EditorType.CreateTemplate: {
        toolbarConfig = [
          ...toolbarConfig,
          ...[
            "link phrases",
            "addimage addattachment",
            "addmergefield",
            "code",
            "table",
          ],
        ];
        plugins.push("table");
        break;
      }
      default:
        toolbarConfig = [...toolbarConfig, ...["link"]];
        break;
    }

    this.toolbarConfig = toolbarConfig.join(" | ");
    this.toolbarPlugins = plugins;
    this.contentCSS = contentCSS.join(",");
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !deepEqual(prevProps.mergefieldData, this.props.mergefieldData) &&
      !!this.state.selectedTemplate &&
      this.state.editorInited
    ) {
      this.onAddTemplate(this.state.selectedTemplate, true);
    }

    if (
      !deepEqual(prevProps.files, this.props.files) ||
      !deepEqual(prevProps.attachments, this.props.attachments)
    ) {
      const { attachments, files } = this.mapEmailFilesAndAttachments();
      this.setState({ attachments, files });
    }

    if (
      get(prevProps.selectedAccount, "id") !==
      get(this.props.selectedAccount, "id")
    ) {
      this.setDefaultSignature();
    }
  }

  public render() {
    const totalFileSize = this.state.attachments.reduce(
      (state, attachment) =>
        state +
        (!!attachment.response
          ? attachment.response.size || attachment.file.size
          : attachment.file.size),
      0
    );

    return (
      <div styleName="editor__wrapper">
        <div styleName={classNames("editor", { free: this.props.free })}>
          {!this.state.editorInited && (
            <div styleName="editor__loader">
              <Ui.Loaders.Fullscreen />
            </div>
          )}
          <div styleName="editor__instance">
            <Editor
              initialValue={this.props.initialValue}
              onEditorChange={this.onChangeHandler}
              init={{
                font_formats: fontFamilies,
                menubar: false,
                browser_spellcheck: true,
                toolbar: this.toolbarConfig,
                plugins: this.toolbarPlugins,
                resize: false,
                branding: false,
                fontsize_formats:
                  "8px 10px 12px 14px 16px 18px 24px 36px 72px 96px",
                table_default_attributes: {},
                table_default_styles: {},
                language: "nl",
                statusbar: false,
                content_css: this.contentCSS,
                valid_children: "+body[style]",
                nonbreaking_force_tab: true,
                extended_valid_elements:
                  "hayworksignature,hayworktemplate,hayworkbody,contentbody",
                custom_elements:
                  "hayworksignature,hayworktemplate,hayworkbody,contentbody",
                forced_root_block: false,
                setup: this.setupEditor,
                init_instance_callback: this.onEditorInit,
              }}
              data-cy="CY-emailEditor"
            />
          </div>
        </div>

        {totalFileSize >= MAIL_LIMIT && (
          <div styleName="file-size-error">
            <i className="fal fa-exclamation-triangle" />
            <ResourceText resourceKey="email.create.fileSizeError" />
          </div>
        )}

        <Attachments
          files={this.state.attachments}
          free={this.props.free}
          onChange={this.onAttachmentsChange}
        />

        <div styleName="file-upload">
          <input
            type="file"
            multiple
            name="file-upload"
            id="file-upload"
            ref={(ref) => (this.fileUploadRef = ref)}
            onChange={this.onFileUploadChange}
            accept={this.state.fileUploadAccepts}
          />
        </div>

        <Phrases
          phrasesModalVisible={this.state.phrasesModalVisible}
          phrasesRef={this.state.phrasesRef}
          onPhraseAdd={this.onPhraseAdd}
          onClose={this.onPhrasesClose}
        />

        <Upload
          files={this.state.uploadFiles}
          onClose={this.onUploadClose}
          uploadUrl={this.props.uploadUrl}
          onCompleted={this.onUploadCompleted}
          shouldBePrivate={this.props.uploadShouldBePrivate}
        />

        {this.props.type === EditorType.CreateTemplate && (
          <MergeFields
            visible={this.state.mergeFieldControlVisible}
            parent={this.state.mergeFieldParent}
            categoryWhiteList={this.props.mergefieldCategoryWhitelist}
            categoryBlackList={this.props.mergefieldCategoryBlacklist}
            onHide={this.onMergeFieldsHide}
            onAdd={this.onMergeFieldAdd}
          />
        )}

        {this.props.type === EditorType.Email && (
          <React.Fragment>
            <Signatures
              onSelectTemplate={this.onSelectSignatureTemplate}
              visible={this.state.signatureControlVisible}
              parent={this.state.signatureParent}
              onHide={this.onSignaturesHide}
            />
            <EmailTemplates
              visible={this.state.templateControlVisible}
              parent={this.state.templateParent}
              onHide={this.onTemplateHide}
              onAdd={this.onAddTemplate}
            />
            <SelectDossierItems
              view="select"
              visible={this.state.dossierFilesModalVisible}
              linkedAssignments={this.props.linkedAssignments}
              linkedRelations={this.props.linkedRelations}
              onClose={() => this.setState({ dossierFilesModalVisible: false })}
              onFiles={this.setDossierItemFiles}
            />
          </React.Fragment>
        )}

        <ConfirmComponent
          visible={this.state.templateOverwriteWarningVisible}
          titleResourceKey="email.templateOverwriteConfirm.title"
          bodyResourceKey="email.templateOverwriteConfirm.body"
          onClose={this.cancelTemplateOverwrite}
          onConfirm={this.confirmTemplateOverwrite}
        />
      </div>
    );
  }

  private cancelTemplateOverwrite() {
    this.setState({ templateOverwriteWarningVisible: false });
  }

  private confirmTemplateOverwrite() {
    this.onAddTemplate(this.state.selectedTemplate);
  }

  private onEditorInit(editor: any) {
    const iframe: HTMLIFrameElement = editor.iframeElement;
    const frameDocument = iframe.contentDocument;
    this.setState({ editorInited: true });
    this.setDefaultSignature();

    frameDocument.addEventListener(
      "dragenter",
      (e) => e.preventDefault(),
      false
    );
    frameDocument.addEventListener(
      "dragover",
      (e) => e.preventDefault(),
      false
    );

    frameDocument.addEventListener(
      "drop",
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        const files: File[] = [];

        switch (true) {
          case !!event.dataTransfer.items: {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
              const item = event.dataTransfer.items[i];
              if (item.kind === "file") {
                files.push(item.getAsFile());
              }
            }
            break;
          }
          default: {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
              files.push(event.dataTransfer.files[i]);
            }
            break;
          }
        }

        this.handleFiles(files);
      },
      false
    );
  }

  private handleFiles(selectedFiles: File[]) {
    if (!selectedFiles || selectedFiles.length === 0) return;

    const { attachments } = this.state;

    const uploadFiles = selectedFiles.map((selectedFile) => {
      let file: FileUploadObject;

      if (
        this.state.fileExplorerTarget === FileExplorerTarget.Inline &&
        /image\//gi.test(selectedFile.type)
      ) {
        file = UploadUtil.createFileUploadObject(selectedFile);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const image = `<img src="${reader.result}" data-preview="${file.id}" />`;
          this.editorInstance.insertContent(image);
        });
        reader.readAsDataURL(selectedFile);
      } else {
        file = UploadUtil.createFileUploadObject(selectedFile, true);
        attachments.push(file);
      }

      return file;
    });

    this.setState({ uploadFiles, attachments });
  }

  private onUploadCompleted(files: FileUploadObject[]) {
    let uploadedFiles: EmailFile[] = [];
    const erroredFileIds: string[] = [];

    files.map((file) => {
      if (!file || !file.response) {
        erroredFileIds.push(get(file, "id", ""));
        return;
      }
      if (
        this.state.fileExplorerTarget === FileExplorerTarget.Inline &&
        /image\//gi.test(file.file.type)
      ) {
        const image = new Image();
        image.addEventListener("load", () => {
          // Only match if positive lookahead includes <img src="data && data-preview= to prevent greedy text replacing
          const match = `\(?=<img src="data)<img([\\w\\W]+?)data-preview="${file.id}"([\\w\\W]+?)(\\/)?>`;

          const replace = `<img src="${file.response.uri}" />`;
          const regexp = new RegExp(match, "gi");
          let content: string = this.editorInstance.getContent();
          content = content.replace(regexp, replace);
          this.setEditorContent(content);
        });
        image.src = file.response.uri;
      }

      uploadedFiles.push(UploadUtil.mapResponseToEmailFile(file.response));
    });

    let { attachments } = this.state;

    attachments = attachments
      .filter((attachment) => !erroredFileIds.includes(attachment.id))
      .map((attachment) => {
        const ref = files.find((file) => file.id === attachment.id);
        return !!ref ? ref : attachment;
      });

    attachments = uniqBy(attachments, (attachment) => attachment.id);

    if (!!this.props.onAttachmentChange) {
      this.props.onAttachmentChange(attachments);
    }

    uploadedFiles = [...this.state.files, ...uploadedFiles];

    if (!!this.props.onFileChange) {
      const files = uploadedFiles.filter((file) => !file.isAttachment);
      this.props.onFileChange(files);
    }

    this.setState({ uploadFiles: [], attachments, files: uploadedFiles });
  }

  private setDefaultSignature() {
    if (this.props.type !== EditorType.Email || !!this.state.hasSetSignature)
      return;

    this.onSelectSignatureTemplate(null, false);

    if (
      (!get(this.props.company, "defaultSignatureTemplateId") &&
        !get(this.props.selectedAccount, "defaultSignatureTemplateId")) ||
      !this.props.signatures.length
    )
      return;

    const id =
      get(this.props.selectedAccount, "defaultSignatureTemplateId") ||
      get(this.props.company, "defaultSignatureTemplateId");

    const signature = this.props.signatures.find(
      (signature) => signature.id === id
    );

    if (signature) {
      this.onSelectSignatureTemplate(signature, false);
    }
  }

  private onAttachmentsChange(attachments: FileUploadObject[]) {
    if (!!this.props.onAttachmentChange) {
      attachments = uniqBy(attachments, (attachment) => attachment.id);
      this.props.onAttachmentChange(attachments);
      this.setState({
        attachments,
      });
    }
  }

  private onMergeFieldsHide() {
    this.setState({ mergeFieldControlVisible: false });
  }

  private onMergeFieldAdd(field: MergeField) {
    this.setState({ mergeFieldControlVisible: false });
    this.editorInstance.insertContent(
      field.mergeFieldValue + "<span>&nbsp;</span>"
    );
  }

  private onChangeHandler(value: string) {
    const { changesAfterTemplateSelect, selectedTemplate } = this.state;

    if (!!selectedTemplate && !changesAfterTemplateSelect) {
      this.setState({ changesAfterTemplateSelect: true });
      if (this.props.onSetHasChangesAfterTemplateSelect) {
        this.props.onSetHasChangesAfterTemplateSelect(true);
      }
    }

    const body = this.cleanValue(value);
    this.props.onChange(body);
  }

  private cleanValue(value: string): string {
    const mergefieldMatcher =
      /<span([\w\W][^>]+?)data-mergefield-value([\w\W]+?)>([\w\W]+?)<\/span>/gi;
    const matches = value.match(mergefieldMatcher);

    let returnValue = value;

    if (matches) {
      matches.map((matched) => {
        const value = `\{\{${
          /data-mergefield-value="(.[^"]+)"/g.exec(matched)[1]
        }\}\}`;
        returnValue = returnValue.replace(matched, value);
      });
    }

    const imageMergefieldMatcher =
      /<img([\w\W][^>]+?)data-mergefield-value([\w\W]+?)(\/)?>/gi;
    const imageMatches = value.match(imageMergefieldMatcher);

    if (imageMatches) {
      imageMatches.map((matched) => {
        const value = `\{\{${
          /data-mergefield-value="(.[^"]+)"/g.exec(matched)[1]
        }\}\}`;

        let replacementImage = matched.replace(
          /data-mergefield-value\s*=\s*"(.+?)"/g,
          ""
        );
        replacementImage = replacementImage.replace(
          /src\s*=\s*"(.+?)"/g,
          `src="${value}"`
        );

        returnValue = returnValue.replace(matched, replacementImage);
      });
    }

    const anchorMergefieldMatcher =
      /<a([\w\W][^>]+?)data-mergefield-value([\w\W]+?)>([\w\W]+?)<\/a>/gi;
    const anchorMatches = value.match(anchorMergefieldMatcher);

    if (anchorMatches) {
      anchorMatches.map((matched) => {
        const value = `\{\{${
          /data-mergefield-value="(.[^"]+)"/g.exec(matched)[1]
        }\}\}`;

        const mergeField = this.props.mergeFields.find(
          (field) => field.mergeFieldValue === value
        );

        if (!mergeField) return;
        const isEmailAddress = mergeField.type === MergeFieldType.EmailAddress;
        const replacement = `<a href="${
          isEmailAddress ? `mailto:${value}` : value
        }"${isEmailAddress ? "" : ` target="_blank"`}>${value}</a>`;
        returnValue = returnValue.replace(matched, replacement);
      });
    }

    return returnValue;
  }

  private onUploadClose() {
    this.setState({ uploadFiles: [] });
  }

  private onPhrasesClose() {
    this.setState({ phrasesModalVisible: false });
  }

  private onPhraseAdd(content: string) {
    this.editorInstance.insertContent(content);
    this.editorInstance.focus();
    this.onPhrasesClose();
  }

  private onSignaturesHide() {
    this.setState({ signatureControlVisible: false });
  }

  private onTemplateHide() {
    this.setState({ templateControlVisible: false });
  }

  private mapMergeTemplateData() {
    const { employeeId, currentRealestateAgencyId, officeId, mergefieldData } =
      this.props;
    const data = mergefieldData || [];

    return {
      employeeIds: [employeeId],
      companyIds: [currentRealestateAgencyId],
      officeIds: [officeId],
      tenantIds: data
        .filter((data) => data.type === MergeFieldDataType.Tenant)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      purchaserIds: data
        .filter((data) => data.type === MergeFieldDataType.Purchaser)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      notaryIds: data
        .filter((data) => data.type === MergeFieldDataType.Notary)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      lessorIds: data
        .filter((data) => data.type === MergeFieldDataType.Lessor)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      vendorIds: data
        .filter((data) => data.type === MergeFieldDataType.Vendor)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      objectAssignmentIds: data
        .filter((data) => data.type === MergeFieldDataType.ObjectAssignment)
        .map((data) => data.id),
      acquisitionObjectAssignmentIds: data
        .filter(
          (data) => data.type === MergeFieldDataType.AcquisitionObjectAssignment
        )
        .map((data) => data.id),
      relationIds: data
        .filter((data) => data.type === MergeFieldDataType.Relation)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
      houseHunterIds: data
        .filter((data) => data.type === MergeFieldDataType.HouseHunter)
        .map((data) => ({
          relationId: data.id,
          relationType: data.relationType,
        })),
    } as TemplateMergeData;
  }

  private async onAddTemplate(
    template: TemplateDefinitionSnapShot,
    checkPossibleOverwrite = false
  ) {
    const { changesAfterTemplateSelect } = this.state;
    const templateOverwriteWarningVisible =
      checkPossibleOverwrite && changesAfterTemplateSelect;

    this.setState({
      templateControlVisible: false,
      selectedTemplate: template,
      templateOverwriteWarningVisible,
    });
    if (!!this.props.onSelectTemplate) {
      this.props.onSelectTemplate(template);
    }

    if (templateOverwriteWarningVisible) {
      return;
    }

    const data = this.mapMergeTemplateData();

    try {
      const response = await this.props.mergeTemplate(template, data);
      const mergeTemplates = get(response, "mergeTemplates") || [];
      const bodyTemplate = mergeTemplates.find(
        (template) => template.key === "template"
      );
      const subjectTemplate = mergeTemplates.find(
        (template) => template.key === "subject"
      );

      if (!!this.props.onTemplateSubjectChange) {
        this.props.onTemplateSubjectChange(subjectTemplate || null);
      }

      const body = !!bodyTemplate ? bodyTemplate.value : "";
      let content: string = this.editorInstance.getContent();

      const matchPlaceholder =
        /<hayworktemplate>([\w\W]+?)?<\/hayworktemplate>/g;
      const matchSignaturePlaceholder =
        /<hayworksignature>([\w\W]+?)?<\/hayworksignature>/g;
      const matchBodyEnd = /<\/body>/g;

      switch (true) {
        case matchPlaceholder.test(content): {
          const matches = content.match(matchPlaceholder);
          if (!matches.length) break;

          const match = first(matches);
          const startIdx = content.indexOf(match);
          const endIdx = startIdx + match.length;

          content = [
            content.substring(0, startIdx),
            "<hayworktemplate>",
            body,
            "<br />",
            "</hayworktemplate>",
            content.substring(endIdx, content.length),
          ].join("");

          break;
        }
        case matchSignaturePlaceholder.test(content): {
          const matches = content.match(matchSignaturePlaceholder);
          if (!matches.length) break;

          const match = first(matches);
          const startIdx = content.indexOf(match);

          content = [
            content.substring(0, startIdx),
            "<hayworktemplate>",
            body,
            "<br />",
            "</hayworktemplate>",
            content.substring(startIdx, content.length),
          ].join("");

          break;
        }
        case matchBodyEnd.test(content): {
          const matches = content.match(matchBodyEnd);
          if (!matches.length) break;

          const match = first(matches);
          const idx = content.indexOf(match);

          content = [
            content.substring(0, idx),
            "<hayworktemplate>",
            body,
            "<br />",
            "</hayworktemplate>",
            content.substring(idx, content.length),
          ].join("");

          break;
        }
        default: {
          content += body;
          break;
        }
      }

      this.setEditorContent(content);

      // Await upload images and attachments
      let templateAttachments = (response.attachmentBlobs || []).map(
        UploadUtil.createFileUploadObjectFromAttachmentBlob
      );

      templateAttachments = differenceBy(
        templateAttachments,
        this.state.attachments,
        (attachment) => attachment.id
      );
      templateAttachments = uniqBy(
        templateAttachments,
        (attachment) => attachment.id
      );

      if (!!templateAttachments.length) {
        // this.setState(({ attachments }) => ({
        //   attachments: [...attachments, ...templateAttachments]
        // }));

        await this.parseTemplateMediaToMailMedia(
          bodyTemplate.value,
          templateAttachments
        );
      }

      this.setState({
        changesAfterTemplateSelect: false,
      });
      if (this.props.onSetHasChangesAfterTemplateSelect) {
        this.props.onSetHasChangesAfterTemplateSelect(false);
      }
    } catch (error) {
      throw error;
    }
  }

  private async onSelectSignatureTemplate(
    template: TemplateDefinitionSnapShot = null,
    manual: boolean = true
  ) {
    this.setState({
      signatureControlVisible: false,
    });

    try {
      let signatureTemplate: MergeTemplate = { id: null, key: null, value: "" };
      if (!!template) {
        const response = await this.props.mergeTemplate(template, {
          employeeIds: [this.props.employeeId],
          companyIds: [this.props.currentRealestateAgencyId],
          officeIds: [this.props.officeId],
        });

        const mergeTemplates = get(response, "mergeTemplates") || [];
        const values = mergeTemplates.filter(
          (mergeTemplate) => mergeTemplate.key === "signature"
        );

        if (!values.length) return;
        signatureTemplate = first(values);
        let attachments = (response.attachmentBlobs || []).map(
          UploadUtil.createFileUploadObjectFromAttachmentBlob
        );

        attachments = uniqBy(
          [...this.state.attachments, ...attachments],
          (attachment) => attachment.id
        );

        this.setState({
          attachments,
        });
      }

      let content: string = this.editorInstance.getContent();

      const matchPlaceholder =
        /<hayworksignature>([\w\W]+?)?<\/hayworksignature>/g;
      const matchTemplatePlaceholder =
        /<hayworktemplate>([\w\W]+?)?<\/hayworktemplate>/g;
      const matchBodyEnd = /<\/body>/g;

      switch (true) {
        case matchPlaceholder.test(content): {
          const matches = content.match(matchPlaceholder);
          if (!matches.length) break;

          const match = first(matches);
          const startIdx = content.indexOf(match);
          const endIdx = startIdx + match.length;

          content = [
            content.substring(0, startIdx),
            matchTemplatePlaceholder.test(content)
              ? ""
              : "<hayworktemplate><p>&nbsp;</p></hayworktemplate>",
            "<hayworksignature>",
            signatureTemplate.value,
            "</hayworksignature>",
            content.substring(endIdx, content.length),
          ].join("");

          break;
        }
        case matchBodyEnd.test(content): {
          const matches = content.match(matchBodyEnd);
          if (!matches.length) break;

          const match = first(matches);
          const idx = content.indexOf(match);

          content = [
            content.substring(0, idx),
            matchTemplatePlaceholder.test(content)
              ? ""
              : "<hayworktemplate><p>&nbsp;</p></hayworktemplate>",
            "<hayworksignature>",
            signatureTemplate.value,
            "</hayworksignature>",
            content.substring(idx, content.length),
          ].join("");

          break;
        }
        default: {
          content += signatureTemplate.value;
          break;
        }
      }

      this.setEditorContent(content);
      if (manual) {
        this.setState({ hasSetSignature: true });
      }
    } catch (error) {
      throw error;
    }
  }

  private async parseTemplateMediaToMailMedia(
    body: string,
    attachments: FileUploadObject[]
  ) {
    if (!this.props.selectedAccount) return;

    const imageRegex = /<img([\w\W]+?)(\/)?>/gi;
    const images = body.match(imageRegex) || [];

    const imageUrls = images.reduce((state, image) => {
      const src = /src\s*=\s*"(.+?)"/g.exec(image)[1];
      if (src) state.push(src);
      return state;
    }, [] as string[]);

    const files: EmailFile[] = [];

    for (const image of imageUrls) {
      const fileNameMatches = image.match(/(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/gi);
      const fileName = first(fileNameMatches);
      if (!/http(s?):\/\//gi.test(image)) continue;

      const imageResponse = await this.props.uploadFromUri(
        this.props.selectedAccount.id,
        image,
        FileUtil.sanatizeFilename(fileName)
      );

      files.push(EmailUtil.mapFileUploadResponseToMailFile(imageResponse));

      await new Promise<void>((resolve) => {
        const img = new Image();
        img.addEventListener("load", () => {
          let content: string = this.editorInstance.getContent();
          content = content.replace(image, imageResponse.uri);
          this.setEditorContent(content);
          resolve();
        });

        img.src = imageResponse.uri;
      });
    }

    for (const attachment of attachments) {
      let attachmentResponse = await this.props.uploadFromUri(
        this.props.selectedAccount.id,
        attachment.response.uri,
        FileUtil.sanatizeFilename(attachment.response.name)
      );
      attachmentResponse = {
        ...attachmentResponse,
        size: attachmentResponse.size || attachment.response.size,
      };

      files.push(
        EmailUtil.mapFileUploadResponseToMailFile(attachmentResponse, true)
      );

      let attachments: FileUploadObject[] = this.state.attachments.map(
        (ref) => {
          if (attachment.id === ref.id) {
            return {
              ...ref,
              response: UploadUtil.mapMailResponseToResponse(
                attachmentResponse,
                true
              ),
            };
          }
          return ref;
        }
      );
      attachments = uniqBy(attachments, (attachment) => attachment.id);

      this.setState({ attachments });

      if (!!this.props.onAttachmentChange) {
        this.props.onAttachmentChange(attachments);
      }
    }

    this.setState({ files });
    if (!!this.props.onFileChange) {
      this.props.onFileChange(files);
    }
  }

  private onFileUploadChange(
    event: Event | React.ChangeEvent<HTMLInputElement>,
    fileExplorerTarget?: FileExplorerTarget
  ) {
    if (
      !(event.target as any).files ||
      (event.target as any).files.length === 0
    )
      return;

    if (!!fileExplorerTarget) {
      this.setState({
        fileExplorerTarget,
      });
    }

    const files: File[] = [];

    for (let i = 0; i < (event.target as any).files.length; i++) {
      files.push((event.target as any).files[i]);
    }

    this.handleFiles(files);
  }

  private triggerFileUploadClick() {
    this.fileUploadRef.click();
  }

  private setupEditor(editor: any) {
    this.editorInstance = editor;

    const isIos =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    editor.addButton("phrases", {
      icon: "fal fa-comment-alt",
      id: "editor-phrases",
      title: intlContext.formatMessage({ id: "tinyMCEPhrasesButton" }),
      onclick: () => {
        this.setState({ phrasesModalVisible: true });
      },
      onpostrender: () => {
        const phrasesRef = document.getElementById(
          "editor-phrases-button"
        ) as HTMLDivElement;

        this.setState({ phrasesRef });
      },
    });

    editor.addButton("addimage", {
      icon: "fal fa-image",
      id: "editor-add-image",
      title: intlContext.formatMessage({ id: "tinyMCEAddImageButton" }),
      onclick: () => {
        if (!isIos) {
          this.setState({
            fileExplorerTarget: FileExplorerTarget.Inline,
            fileUploadAccepts: "image/*",
          });
          this.triggerFileUploadClick();
        }
      },
      onpostrender: (event) => {
        if (isIos) {
          const element: HTMLDivElement | undefined = event?.target?.$el?.[0];
          if (element) {
            const label = document.createElement("label");
            const input = document.createElement("input");

            input.type = "file";
            input.multiple = true;
            input.id = "file-upload-image";
            input.name = "file-upload-image";
            input.accept = "image/*";
            input.addEventListener("change", (event) =>
              this.onFileUploadChange(event, FileExplorerTarget.Inline)
            );
            input.classList.add("visually-hidden");
            label.classList.add("editor-faux-label");

            label.appendChild(input);
            element.parentElement.insertBefore(label, element.nextSibling);
            element.style.position = "relative";
          }
        }
      },
    });

    editor.addButton("addattachment", {
      icon: "fal fa-paperclip",
      id: "editor-add-attachment",
      title: intlContext.formatMessage({
        id: "tinyMCEAddAttachmentButton",
      }),
      onclick: () => {
        if (!isIos) {
          this.setState({
            fileExplorerTarget: FileExplorerTarget.Attachment,
            fileUploadAccepts: "*",
          });
          this.triggerFileUploadClick();
        }
      },
      onpostrender: (event) => {
        if (isIos) {
          const element: HTMLDivElement | undefined = event?.target?.$el?.[0];
          if (element) {
            const label = document.createElement("label");
            const input = document.createElement("input");

            input.type = "file";
            input.multiple = true;
            input.id = "file-upload-attachment";
            input.name = "file-upload-attachment";
            input.accept = "*";
            input.addEventListener("change", (event) =>
              this.onFileUploadChange(event, FileExplorerTarget.Attachment)
            );
            input.classList.add("visually-hidden");
            label.classList.add("editor-faux-label");

            label.appendChild(input);
            element.parentElement.insertBefore(label, element.nextSibling);
            element.style.position = "relative";
          }
        }
      },
    });

    editor.addButton("adddossierattachment", {
      icon: "fal fa-folder-plus",
      id: "editor-add-dossier-attachment",
      title: intlContext.formatMessage({
        id: "tinyMCEAddDossierAttachmentButton",
      }),
      onclick: () => {
        this.setState({ dossierFilesModalVisible: true });
      },
    });

    editor.addButton("addmergefield", {
      icon: "fal fa-file-alt",
      id: "editor-add-mergefield",
      text: intlContext.formatMessage({
        id: "tinyMCEAddMergeFieldButton",
      }),
      onclick: () => {
        this.setState(({ mergeFieldControlVisible }) => ({
          mergeFieldControlVisible: !mergeFieldControlVisible,
        }));
      },
      onpostrender: () => {
        const mergeFieldParent = document.getElementById(
          "editor-add-mergefield"
        ) as HTMLElement;

        this.setState({ mergeFieldParent });
      },
    });

    editor.addButton("addemailsignature", {
      icon: "fal fa-file-signature",
      id: "editor-add-email-signature",
      title: intlContext.formatMessage({
        id: "tinyMCEAddEmailSignatureButton",
      }),
      onclick: () => {
        this.setState(({ signatureControlVisible }) => ({
          signatureControlVisible: !signatureControlVisible,
        }));
      },
      onpostrender: () => {
        const signatureParent = document.getElementById(
          "editor-add-email-signature"
        ) as HTMLElement;

        this.setState({ signatureParent });
      },
    });

    editor.addButton("addemailtemplate", {
      icon: "fal fa-file",
      id: "editor-add-email-template",
      text: intlContext.formatMessage({
        id: "tinyMCEAddEmailTemplateButton",
      }),
      onclick: () => {
        this.setState(({ templateControlVisible }) => ({
          templateControlVisible: !templateControlVisible,
        }));
      },
      onpostrender: () => {
        const templateParent = document.getElementById(
          "editor-add-email-template"
        ) as HTMLElement;

        this.setState({ templateParent });
      },
    });

    editor.on("focus", () => {
      this.setState({
        mergeFieldControlVisible: false,
        phrasesModalVisible: false,
        signatureControlVisible: false,
        templateControlVisible: false,
      });
    });

    editor.on("BeforeSetContent", (e) => {
      let content: string = e.content;

      // Update images with pattern first
      const imageMatch = /<img([\w\W]+?)src="{{([\w\W]+?)}}"([\w\W]+?)(\/)?>/gi;
      const imageMatches = content.match(imageMatch);

      if (!!imageMatches && !!this.props.mergeFields.length) {
        imageMatches.map((matched) => {
          const value = /\{\{([\w\.]+)\}\}/g.exec(matched)[1];
          const mergeField = this.props.mergeFields.find(
            (field) => field.mergeFieldValue === `{{${value}}}`
          );

          if (mergeField.type === MergeFieldType.ImageUrl) {
            let replacementImage = matched.replace(
              /src\s*=\s*"(.+?)"/g,
              `src="${placeholderImageSrc(mergeField.displayName)}"`
            );

            replacementImage = replacementImage.replace(
              "/>",
              ` data-mergefield-value="${value}" />`
            );

            content = content.replace(matched, replacementImage);
          }
        });
      }

      // Then update anchors
      const anchorMatch =
        /<a([\w\W]+?)href="(mailto:)?{{([\w\W]+?)}}"((?:.(?!<\/a>))*.)?>((?:.(?!<\/a>))*.)<\/a>/gi;
      const anchorMatches = content.match(anchorMatch);

      if (!!anchorMatches && !!this.props.mergeFields.length) {
        anchorMatches.map((matched) => {
          const value = /{{([\w\W]+?)}}/gi.exec(matched)[0];
          content = content.replace(matched, value);
        });
      }

      // Finally update remaining placeholders
      const match = /\{\{([\w\.]+)\}\}/gi;
      const matches = content.match(match);

      if (!!matches && !!this.props.mergeFields.length) {
        matches.map((matched) => {
          const mergeField = this.props.mergeFields.find(
            (field) => field.mergeFieldValue === matched
          );
          const value = matched.replace(/[{}]/g, "");

          if (!mergeField) return;

          switch (mergeField.type) {
            case MergeFieldType.Text:
            default: {
              const replacement = `<span class="highlight-mergefield ${
                this.props.type === EditorType.CreateTemplate ? "" : "missing"
              }" data-mergefield-value="${value}"><strong>${
                mergeField.categoryDisplayName
              }</strong>${mergeField.displayName}</span>`;
              content = content.replace(matched, replacement);
              break;
            }
            case MergeFieldType.ImageUrl: {
              const replacement = `<img data-mergefield-value="${value}" width="240" height="300" src="${placeholderImageSrc(
                mergeField.displayName
              )}" />`;
              content = content.replace(matched, replacement);
              break;
            }
            case MergeFieldType.EmailAddress:
            case MergeFieldType.Url: {
              const replacement = `<a class="highlight-mergefield ${
                this.props.type === EditorType.CreateTemplate ? "" : "missing"
              }" data-mergefield-value="${value}"><strong>${
                mergeField.categoryDisplayName
              }</strong>${mergeField.displayName}</a>`;
              content = content.replace(matched, replacement);
              break;
            }
          }
        });
      }

      e.content = content;
    });
  }

  private setEditorContent(content: string) {
    if (!this.editorInstance || !this.editorInstance.selection) return;
    const bookmark = this.editorInstance.selection.getBookmark(2, true);
    this.editorInstance.setContent(content);
    this.editorInstance.selection.moveToBookmark(bookmark);
  }

  private mapEmailFilesAndAttachments() {
    let attachments = (this.props.attachments || []).map(
      UploadUtil.createFileUploadObjectFromAttachmentBlob
    );

    const { attachments: attachmentsFromFiles, files } = (
      this.props.files || []
    ).reduce(
      (state, file) => {
        state.files.push(file);

        if (file.isAttachment) {
          state.attachments.push(
            UploadUtil.createFileUploadObjectFromEmailFile(file)
          );
        }

        return state;
      },
      { attachments: [], files: [] } as {
        attachments: FileUploadObject[];
        files: EmailFile[];
      }
    );

    attachments = [...attachments, ...attachmentsFromFiles];
    return { attachments, files };
  }

  private setDossierItemFiles(results: UploadResponse[]) {
    let files = results.map((result) =>
      UploadUtil.mapMailUploadResponseToEmailFile(result, true)
    );
    let attachments = files.map((file) =>
      UploadUtil.createFileUploadObjectFromEmailFile(file, true)
    );

    attachments = [...this.state.attachments, ...attachments];
    attachments = uniqBy(attachments, (attachment) => attachment.id);

    if (!!this.props.onAttachmentChange) {
      this.props.onAttachmentChange(attachments);
    }

    files = [...this.state.files, ...files];

    if (!!this.props.onFileChange) {
      files = files.filter((file) => !file.isAttachment);
      this.props.onFileChange(files);
    }

    this.setState({ files, attachments, dossierFilesModalVisible: false });
  }
}
