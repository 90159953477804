import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  EmailAddress,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { RELATIONROUTES, SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentWidgetComponent } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import Notes from "@haywork/modules/notes-v3";
import {
  ContactCompanyReadonlyComponent,
  ContactPersonReadonlyComponent,
  RelationGeneralContainerProps,
} from "@haywork/modules/relation";
import {
  AuthorizationWidgets,
  ConfirmComponent,
  PageLoader,
} from "@haywork/modules/shared";
import SelectEmailModal from "@haywork/modules/shared/components/choose-email";
import TimelineWidgetComponent from "@haywork/modules/shared/components/timeline/components/widget";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { RelationAction } from "./actions";
import { RelationWidgetSearchAssignmentsComponent } from "./widgets";

export interface RelationGeneralComponentProps {}
interface State {
  showConfirmDelete: boolean;
  showChooseEmailModal: boolean;
  emailAddresses: EmailAddress[];
}
type Props = RelationGeneralComponentProps & RelationGeneralContainerProps;

const styles = require("./relation-general.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class RelationGeneralComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmDelete: false,
      showChooseEmailModal: false,
      emailAddresses: [],
    };

    this.editRelation = this.editRelation.bind(this);
    this.renderTimeLineNavLink = this.renderTimeLineNavLink.bind(this);
    this.renderAssignmentsNavLink = this.renderAssignmentsNavLink.bind(this);
    this.handleRelationAction = this.handleRelationAction.bind(this);
    this.deleteRelation = this.deleteRelation.bind(this);
    this.closeChooseEmailModal = this.closeChooseEmailModal.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.onConfirmDeleteCloseHandler = this.onConfirmDeleteCloseHandler.bind(
      this
    );
    this.onConfirmDeleteHandler = this.onConfirmDeleteHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getLinkedAssignments(this.props.selectedRelationId);
    this.props.getTimelineEvents(this.props.selectedRelationId);

    const widgetEntityType =
      this.props.selectedRelationType === RelationType.ContactPerson
        ? WidgetEntityType.ContactPerson
        : WidgetEntityType.ContactCompany;

    this.props.getInitializedWidgets(
      this.props.selectedRelationId,
      widgetEntityType
    );
  }

  public render() {
    const {
      selectedRelationType,
      selectedContactPerson,
      selectedContactCompany,
      canSendEmail,
    } = this.props;
    const typeOfRelation = selectedRelationType;
    const { isActive } =
      typeOfRelation === RelationType.ContactCompany
        ? selectedContactCompany
        : selectedContactPerson;

    return (
      <div styleName="general">
        <PageHeader
          title="pageTitle.relation.general"
          subTitle={typeOfRelation.toString()}
          subTitlePrefix="relationTypes"
          actions={
            <>
              <Notes />
              <Button
                label="editRelation"
                category="success"
                onClick={this.editRelation}
              />
              <Actions
                relation={
                  typeOfRelation === RelationType.ContactCompany
                    ? selectedContactCompany
                    : selectedContactPerson
                }
                canSendEmail={canSendEmail}
                onClick={this.handleRelationAction}
              />
            </>
          }
        >
          {!isActive && (
            <div styleName="archived-pill">
              <I18n value="archived" />
            </div>
          )}
        </PageHeader>

        <div styleName="body">
          <div styleName="detail-component">
            {typeOfRelation === RelationType.ContactCompany ? (
              <ContactCompanyReadonlyComponent
                phoneNumberTypes={this.props.phoneNumberTypes}
                emailAddressTypes={this.props.emailAddressTypes}
                countries={this.props.countries}
                company={this.props.selectedContactCompany}
              />
            ) : (
              <ContactPersonReadonlyComponent
                phoneNumberTypes={this.props.phoneNumberTypes}
                emailAddressTypes={this.props.emailAddressTypes}
                countries={this.props.countries}
                contactPerson={this.props.selectedContactPerson}
                civilStates={this.props.civilStates}
                navigate={this.props.navigate}
                identificationTypes={this.props.identificationTypes}
              />
            )}
          </div>
          {this.renderWidgetsOrPageLoader()}

          <SelectEmailModal
            visible={this.state.showChooseEmailModal}
            emailAddresses={this.state.emailAddresses}
            onClose={this.closeChooseEmailModal}
            onSelect={this.sendEmail}
          />
        </div>
      </div>
    );
  }

  private handleRelationAction(action: RelationAction) {
    const {
      selectedContactPerson,
      selectedContactCompany,
      selectedRelationType,
    } = this.props;
    const { id, displayName, address, emailAddresses } =
      selectedRelationType === RelationType.ContactCompany
        ? selectedContactCompany
        : selectedContactPerson;
    const linkedRelation: LinkedRelation = {
      id,
      displayName,
      typeOfRelation: selectedRelationType,
    };
    const emails = (emailAddresses || []).filter((email) => !!email.address);

    switch (action) {
      case RelationAction.Archive: {
        if (selectedRelationType === RelationType.ContactCompany) {
          this.props.archiveContactCompany(id);
        } else {
          this.props.archiveContactPerson(id);
        }
        break;
      }
      case RelationAction.UnArchive: {
        if (selectedRelationType === RelationType.ContactCompany) {
          this.props.unArchiveContactCompany(id);
        } else {
          this.props.unArchiveContactPerson(id);
        }
        break;
      }
      case RelationAction.Remove: {
        this.props.deleteRelationV2(id, displayName, selectedRelationType);
        // this.deleteRelation();
        break;
      }
      case RelationAction.CreateEmail: {
        if (!emails.length) return;
        if (emails.length === 1) {
          const { address } = emails[0];
          this.props.createNewEmail(address);
        } else {
          this.setState({ showChooseEmailModal: true, emailAddresses: emails });
        }
        break;
      }
      case RelationAction.CreateTask: {
        this.props.createTaskWithLinkedRelation(linkedRelation);
        break;
      }
      case RelationAction.CreateInvoice: {
        this.props.createInvoiceWithLinkedRelation(
          linkedRelation,
          selectedRelationType,
          address
        );
        break;
      }
      case RelationAction.CreateAgendaItem: {
        this.props.createAgendaItemWithLinkedRelation(linkedRelation);
        break;
      }
      case RelationAction.CreateSearchAssignment: {
        const path = route(SEARCHASSIGNMENTROUTES.NEW_WITH_RELATION.URI, {
          id,
        });
        this.props.navigate(path);
        break;
      }
      default:
        break;
    }
  }

  private editRelation() {
    const id = this.props.selectedRelationId;
    switch (this.props.selectedRelationType) {
      case RelationType.ContactCompany: {
        return this.props.navigate(
          route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, { id })
        );
      }
      case RelationType.ContactPerson: {
        return this.props.navigate(
          route(RELATIONROUTES.CONTACT_PERSON_EDIT.URI, { id })
        );
      }
      default: {
        return;
      }
    }
  }

  private renderWidgetsOrPageLoader() {
    if (this.props.isLoading) {
      return <PageLoader loading={true} />;
    }

    const { selectedRelationId, selectedRelationType } = this.props;

    return (
      <div styleName="widgets">
        <div styleName="linked-assignments" className="clearfix">
          <ErrorBoundary>
            <AssignmentWidgetComponent
              assignmentWidgetItems={this.props.relationAssignments}
              headerResourceKey="linkedAssignments"
              emptyStateTitleResourceKey="aroAssignmentsWidgetEmptystateTitle"
              emptyStateDescriptionResourceKey="aroAssignmentsWidgetEmptystateText"
              hideEmptyStateImage={false}
              onNavigate={this.props.navigate}
            />
          </ErrorBoundary>
          {this.renderAssignmentsNavLink()}
        </div>

        {/* Authorization widgets */}
        <FeatureSwitch feature="APP_XCHANGE">
          {[RelationType.ContactCompany, RelationType.ContactPerson].indexOf(
            selectedRelationType
          ) !== -1 && (
            <div styleName="authorization-widgets">
              <AuthorizationWidgets
                type={
                  selectedRelationType === RelationType.ContactCompany
                    ? WidgetEntityType.ContactCompany
                    : WidgetEntityType.ContactPerson
                }
                location={WidgetEntityLocation.Sidebar}
                id={selectedRelationId}
              />
            </div>
          )}
        </FeatureSwitch>

        {[RelationType.ContactCompany, RelationType.ContactPerson].indexOf(
          this.props.selectedRelationType
        ) !== -1 && (
          <ErrorBoundary>
            <RelationWidgetSearchAssignmentsComponent
              relationId={this.props.selectedRelationId}
              searchAssignments={this.props.searchAssignments}
              searchAssignmentsStatus={this.props.searchAssignmentsStatus}
              onNavigateToSearchAssignment={this.props.navigate}
              getMatchingSearchAssignments={
                this.props.getMatchingSearchAssignments
              }
              relationType={this.props.selectedRelationType}
            />
          </ErrorBoundary>
        )}

        <div styleName="timeline-events" className="clearfix">
          <ErrorBoundary>
            <TimelineWidgetComponent
              timelineEvents={this.props.timelineEvents}
              headerResourceKey="aroTimeLineWidgetTitle"
              emptyStateTitleResourceKey="emptyStateNoTimelineTitle"
              emptyStateDescriptionResourceKey="emptyStateNoTimelineText"
              hideEmptyStateImage={false}
              onNavigate={this.props.navigate}
            />
          </ErrorBoundary>
          {this.renderTimeLineNavLink()}
        </div>

        <ConfirmComponent
          visible={this.state.showConfirmDelete}
          titleResourceKey="relationDeleteModalTitle"
          bodyResourceKey="relationDeleteModalMessage"
          onClose={this.onConfirmDeleteCloseHandler}
          onConfirm={this.onConfirmDeleteHandler}
        />
      </div>
    );
  }

  private renderTimeLineNavLink() {
    const id = this.props.selectedRelationId;
    let url: string;
    switch (this.props.selectedRelationType) {
      case RelationType.ContactCompany: {
        url = route(RELATIONROUTES.CONTACT_COMPANY_TIMELINE.URI, { id });
        break;
      }
      case RelationType.ContactPerson: {
        url = route(RELATIONROUTES.CONTACT_PERSON_TIMELINE.URI, { id });
        break;
      }
      default: {
        url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
      }
    }
    return (
      <NavLink className="widgetLink" activeClassName="active" to={url}>
        <I18n value="goToTimeline" />
      </NavLink>
    );
  }

  private renderAssignmentsNavLink() {
    const id = this.props.selectedRelationId;
    let url: string;
    switch (this.props.selectedRelationType) {
      case RelationType.ContactCompany: {
        url = route(RELATIONROUTES.CONTACT_COMPANY_ASSIGNMENT.URI, { id });
        break;
      }
      case RelationType.ContactPerson: {
        url = route(RELATIONROUTES.CONTACT_PERSON_ASSIGNMENT.URI, { id });
        break;
      }
      default: {
        url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
      }
    }
    return (
      <NavLink className="widgetLink" activeClassName="active" to={url}>
        <I18n value="goToAssingments" />
      </NavLink>
    );
  }

  private deleteRelation() {
    this.setState({ showConfirmDelete: true });
  }

  private onConfirmDeleteCloseHandler() {
    this.setState({ showConfirmDelete: false });
  }

  private onConfirmDeleteHandler() {
    const {
      selectedRelationType,
      selectedRelationId,
      selectedContactCompany,
      selectedContactPerson,
    } = this.props;
    const { displayName } =
      selectedRelationType === RelationType.ContactCompany
        ? selectedContactCompany
        : selectedContactPerson;

    this.setState({ showConfirmDelete: false });
    this.props.deleteRelation(
      selectedRelationId,
      displayName,
      selectedRelationType
    );
  }

  private closeChooseEmailModal() {
    this.setState({ showChooseEmailModal: false });
  }

  private sendEmail(email: string | null) {
    this.setState({ showChooseEmailModal: false });
    if (!email) return;
    this.props.createNewEmail(email);
  }
}
