import {
  ObjectAssignment,
  WelfareStateOption,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  welfareStateOptions: WelfareStateOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}
interface State {
  hasResidentialBuilding: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogHotelRestaurantCafeComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.state = {
      hasResidentialBuilding: value(
        objectAssignment,
        "residentialBuilding.hasResidentialBuilding",
        false
      ),
    };

    this.formControls = {
      buildingLayer: { value: value(objectAssignment, "buildingLayer", 0) },
      inDenseHospitalityArea: {
        value: value(objectAssignment, "inDenseHospitalityArea", false),
      },
      vVO: { value: value(objectAssignment, "vVO.area") },
      wvo: { value: value(objectAssignment, "wvo.area") },
      terrace: { value: value(objectAssignment, "terrace", false) },
      revenuePerYear: { value: value(objectAssignment, "revenuePerYear") },
      welfareState: { value: value(objectAssignment, "welfareState") },
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) },

      residentialBuildingHasResidentialBuilding: {
        value: value(
          objectAssignment,
          "residentialBuilding.hasResidentialBuilding",
          false
        ),
        onChange: (ref) => this.setState({ hasResidentialBuilding: ref.value }),
      },
      residentialBuildingEffectiveArea: {
        value: value(
          objectAssignment,
          "residentialBuilding.effectiveArea.area"
        ),
      },
      residentialBuildingRevenuePerYear: {
        value: value(objectAssignment, "residentialBuilding.revenuePerYear"),
      },

      /* Fastfood */
      fastfoodHospitalityIndustryArea: {
        value: value(objectAssignment, "fastfoodHospitalityIndustryArea.area"),
      },
      fastfoodCountOfSeats: {
        value: value(objectAssignment, "fastfoodCountOfSeats"),
      },

      /* Beverages */
      beveragesHospitalityIndustryArea: {
        value: value(objectAssignment, "beveragesHospitalityIndustryArea.area"),
      },
      beveragesNonHospitalityIndustryArea: {
        value: value(
          objectAssignment,
          "beveragesNonHospitalityIndustryArea.area"
        ),
      },
      beveragesCountOfSeats: {
        value: value(objectAssignment, "beveragesCountOfSeats"),
      },

      /* Restaurant */
      restaurantHospitalityIndustryArea: {
        value: value(
          objectAssignment,
          "restaurantHospitalityIndustryArea.area"
        ),
      },
      restaurantNonHospitalityIndustryArea: {
        value: value(
          objectAssignment,
          "restaurantNonHospitalityIndustryArea.area"
        ),
      },
      restaurantCountOfSeats: {
        value: value(objectAssignment, "restaurantCountOfSeats"),
      },

      /* Hotel */
      hotelHospitalityIndustryArea: {
        value: value(objectAssignment, "hotelHospitalityIndustryArea.area"),
      },
      hotelNonHospitalityIndustryArea: {
        value: value(objectAssignment, "hotelNonHospitalityIndustryArea.area"),
      },
      countOfHotelrooms: {
        value: value(objectAssignment, "countOfHotelrooms"),
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.setState({
        hasResidentialBuilding: value(
          objectAssignment,
          "residentialBuilding.hasResidentialBuilding",
          false
        ),
      });

      this.formRef.update(
        {
          buildingLayer: value(objectAssignment, "buildingLayer", 0),
          inDenseHospitalityArea: value(
            objectAssignment,
            "inDenseHospitalityArea",
            false
          ),
          vVO: value(objectAssignment, "vVO.area"),
          wvo: value(objectAssignment, "wvo.area"),
          terrace: value(objectAssignment, "terrace", false),
          revenuePerYear: value(objectAssignment, "revenuePerYear"),
          welfareState: value(objectAssignment, "welfareState"),
          numberOfFloors: value(objectAssignment, "numberOfFloors", 0),

          residentialBuildingHasResidentialBuilding: value(
            objectAssignment,
            "residentialBuilding.hasResidentialBuilding",
            false
          ),
          residentialBuildingEffectiveArea: value(
            objectAssignment,
            "residentialBuilding.effectiveArea.area"
          ),
          residentialBuildingRevenuePerYear: value(
            objectAssignment,
            "residentialBuilding.revenuePerYear"
          ),
          fastfoodHospitalityIndustryArea: value(
            objectAssignment,
            "fastfoodHospitalityIndustryArea.area"
          ),
          fastfoodCountOfSeats: value(objectAssignment, "fastfoodCountOfSeats"),
          beveragesHospitalityIndustryArea: value(
            objectAssignment,
            "beveragesHospitalityIndustryArea.area"
          ),
          beveragesNonHospitalityIndustryArea: value(
            objectAssignment,
            "beveragesNonHospitalityIndustryArea.area"
          ),
          beveragesCountOfSeats: value(
            objectAssignment,
            "beveragesCountOfSeats"
          ),
          restaurantHospitalityIndustryArea: value(
            objectAssignment,
            "restaurantHospitalityIndustryArea.area"
          ),
          restaurantNonHospitalityIndustryArea: value(
            objectAssignment,
            "restaurantNonHospitalityIndustryArea.area"
          ),
          restaurantCountOfSeats: value(
            objectAssignment,
            "restaurantCountOfSeats"
          ),
          hotelHospitalityIndustryArea: value(
            objectAssignment,
            "hotelHospitalityIndustryArea.area"
          ),
          hotelNonHospitalityIndustryArea: value(
            objectAssignment,
            "hotelNonHospitalityIndustryArea.area"
          ),
          countOfHotelrooms: value(objectAssignment, "countOfHotelrooms"),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
        },
        true
      );
    }
  }

  public render() {
    const {
      fastfoodHospitalitySectorTypes,
      beverageHospitalityTypes,
      restaurantHospitalitySectorTypes,
      hotelHospitalitySectorTypes,
    } = this.props.objectAssignment;

    return (
      <Form
        name="hotel-restaurant-cafe"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row" styleName="size-field">
          <div className="form__group">
            <div className="column">
              <label htmlFor="buildingLayer">
                <ResourceText resourceKey="buildingLayerHoreca" />
              </label>
              <Input.Number name="buildingLayer" />
            </div>
          </div>
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="inDenseHospitalityArea"
            label="inDenseHospitalityArea"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="surface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="wvo">
                <ResourceText resourceKey="wvo" />
              </label>
              <div className="input__helper">
                <Input.Number name="wvo" step={50} pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="numberOfFloors">
                <ResourceText resourceKey="numberOfFloorsBog" />
              </label>
              <Input.Number name="numberOfFloors" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="terrace"
            label="bogTerrace"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="residentialBuildingHasResidentialBuilding"
            label="hasResidentialBuilding"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        {this.state.hasResidentialBuilding && (
          <div>
            <div className="form__row" styleName="size-field">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="residentialBuildingEffectiveArea">
                    <ResourceText resourceKey="effectiveAreaResidentialBuilding" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="residentialBuildingEffectiveArea"
                      step={50}
                      pretty
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form__row" styleName="size-field">
              <label htmlFor="residentialBuildingRevenuePerYear">
                <ResourceText resourceKey="revenuePerYearResidentialBuilding" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number
                  name="residentialBuildingRevenuePerYear"
                  step={1000}
                  pretty
                  asPrice
                />
                <div className="post full">
                  <ResourceText resourceKey="perYear" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form__row" styleName="size-field">
          <div className="form__group">
            <div className="column">
              <label htmlFor="revenuePerYear">
                <ResourceText resourceKey="revenuePerYear" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number
                  name="revenuePerYear"
                  step={1000}
                  asPrice
                  pretty
                />
                <div className="post full">
                  <ResourceText resourceKey="perYear" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="welfareState">
            <ResourceText resourceKey="welfareState" />
          </label>
          <Input.RadioGroup name="welfareState" asButtonList>
            {this.props.welfareStateOptions.map((welfareStateOption, idx) => (
              <Input.Radio
                value={welfareStateOption.value}
                key={idx}
                label={welfareStateOption.displayName}
              />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Fastfood */}
        {!!fastfoodHospitalitySectorTypes &&
          fastfoodHospitalitySectorTypes.length > 0 && (
            <div>
              <h2 styleName="section-title">
                <ResourceText resourceKey="fastFood" />
              </h2>

              <div className="form__row" styleName="size-field">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="fastfoodHospitalityIndustryArea">
                      <ResourceText resourceKey="surface" />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="fastfoodHospitalityIndustryArea"
                        pretty
                        step={50}
                      />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__row" styleName="size-field">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="fastfoodCountOfSeats">
                      <ResourceText resourceKey="countOfSeats" />
                    </label>
                    <Input.Number name="fastfoodCountOfSeats" step={50} />
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Beverages */}
        {!!beverageHospitalityTypes && beverageHospitalityTypes.length > 0 && (
          <div>
            <h2 styleName="section-title">
              <ResourceText resourceKey="beverages" />
            </h2>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="beveragesHospitalityIndustryArea">
                    <ResourceText resourceKey="surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="beveragesHospitalityIndustryArea"
                      pretty
                      step={50}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="size-field">
                  <label htmlFor="beveragesNonHospitalityIndustryArea">
                    <ResourceText resourceKey="nonHorecaSurface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="beveragesNonHospitalityIndustryArea"
                      pretty
                      step={50}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form__row" styleName="size-field">
              <div className="form__group">
                <div className="column">
                  <label htmlFor="beveragesCountOfSeats">
                    <ResourceText resourceKey="countOfSeats" />
                  </label>
                  <Input.Number name="beveragesCountOfSeats" step={50} />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Restaurant */}
        {!!restaurantHospitalitySectorTypes &&
          restaurantHospitalitySectorTypes.length > 0 && (
            <div>
              <h2 styleName="section-title">
                <ResourceText resourceKey="restaurantTitle" />
              </h2>

              <div className="form__row">
                <div className="form__group">
                  <div className="column" styleName="size-field">
                    <label htmlFor="restaurantHospitalityIndustryArea">
                      <ResourceText resourceKey="surface" />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="restaurantHospitalityIndustryArea"
                        pretty
                        step={50}
                      />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column" styleName="size-field">
                    <label htmlFor="restaurantNonHospitalityIndustryArea">
                      <ResourceText resourceKey="nonHorecaSurface" />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="restaurantNonHospitalityIndustryArea"
                        pretty
                        step={50}
                      />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__row" styleName="size-field">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="restaurantCountOfSeats">
                      <ResourceText resourceKey="countOfSeats" />
                    </label>
                    <Input.Number name="restaurantCountOfSeats" step={50} />
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Hotel */}
        {!!hotelHospitalitySectorTypes &&
          hotelHospitalitySectorTypes.length > 0 && (
            <div>
              <h2 styleName="section-title">
                <ResourceText resourceKey="hotel" />
              </h2>

              <div className="form__row">
                <div className="form__group">
                  <div className="column" styleName="size-field">
                    <label htmlFor="hotelHospitalityIndustryArea">
                      <ResourceText resourceKey="surface" />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="hotelHospitalityIndustryArea"
                        pretty
                        step={50}
                      />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column" styleName="size-field">
                    <label htmlFor="hotelNonHospitalityIndustryArea">
                      <ResourceText resourceKey="nonHorecaSurface" />
                    </label>
                    <div className="input__helper">
                      <Input.Number
                        name="hotelNonHospitalityIndustryArea"
                        pretty
                        step={50}
                      />
                      <div className="post">
                        <ResourceText resourceKey="squareMeters" asHtml />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__row" styleName="size-field">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="countOfHotelrooms">
                      <ResourceText resourceKey="countOfHotelrooms" />
                    </label>
                    <Input.Number name="countOfHotelrooms" step={50} />
                  </div>
                </div>
              </div>
            </div>
          )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const {
      fastfoodHospitalitySectorTypes,
      beverageHospitalityTypes,
      restaurantHospitalitySectorTypes,
      hotelHospitalitySectorTypes,
    } = objectAssignment;

    let fields: ObjectAssignment | AcquisitionObjectAssignment = {
      ...objectAssignment,
      buildingLayer: values.buildingLayer,
      inDenseHospitalityArea: values.inDenseHospitalityArea,
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      wvo: {
        ...objectAssignment.wvo,
        area: values.wvo,
      },
      terrace: values.terrace,
      residentialBuilding: {
        ...objectAssignment.residentialBuilding,
        hasResidentialBuilding:
          values.residentialBuildingHasResidentialBuilding,
        effectiveArea: {
          ...objectAssignment.residentialBuilding.effectiveArea,
          area: values.residentialBuildingEffectiveArea,
        },
        revenuePerYear: values.residentialBuildingRevenuePerYear,
      },
      revenuePerYear: values.revenuePerYear,
      welfareState: values.welfareState,
    };

    if (
      !!fastfoodHospitalitySectorTypes &&
      fastfoodHospitalitySectorTypes.length > 0
    ) {
      fields = {
        ...fields,
        fastfoodHospitalityIndustryArea: {
          ...objectAssignment.fastfoodHospitalityIndustryArea,
          area: values.fastfoodHospitalityIndustryArea,
        },
        fastfoodCountOfSeats: values.fastfoodCountOfSeats,
      };
    }

    if (!!beverageHospitalityTypes && beverageHospitalityTypes.length > 0) {
      fields = {
        ...fields,
        beveragesHospitalityIndustryArea: {
          ...objectAssignment.beveragesHospitalityIndustryArea,
          area: values.beveragesHospitalityIndustryArea,
        },
        beveragesNonHospitalityIndustryArea: {
          ...objectAssignment.beveragesNonHospitalityIndustryArea,
          area: values.beveragesNonHospitalityIndustryArea,
        },
        beveragesCountOfSeats: values.beveragesCountOfSeats,
      };
    }

    if (
      !!restaurantHospitalitySectorTypes &&
      restaurantHospitalitySectorTypes.length > 0
    ) {
      fields = {
        ...fields,
        restaurantHospitalityIndustryArea: {
          ...objectAssignment.restaurantHospitalityIndustryArea,
          area: values.restaurantHospitalityIndustryArea,
        },
        restaurantNonHospitalityIndustryArea: {
          ...objectAssignment.restaurantNonHospitalityIndustryArea,
          area: values.restaurantNonHospitalityIndustryArea,
        },
        restaurantCountOfSeats: values.restaurantCountOfSeats,
      };
    }

    if (
      !!hotelHospitalitySectorTypes &&
      hotelHospitalitySectorTypes.length > 0
    ) {
      fields = {
        ...fields,
        hotelHospitalityIndustryArea: {
          ...objectAssignment.hotelHospitalityIndustryArea,
          area: values.hotelHospitalityIndustryArea,
        },
        hotelNonHospitalityIndustryArea: {
          ...objectAssignment.hotelNonHospitalityIndustryArea,
          area: values.hotelNonHospitalityIndustryArea,
        },
        countOfHotelrooms: values.countOfHotelrooms,
      };
    }

    this.props.onChange(fields);
  }
}
