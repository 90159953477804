import { SettingField } from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AppXchangeSettingsComponent,
  AppXchangeSettingsComponentProps
} from "../components/settings";
import { settingsFields } from "./selectors";

interface StateProps {
  fields: SettingField[];
  settingsStatus: string;
}
interface DispatchProps {
  saveCompanySettings: (
    appClientId: string,
    fields: SettingField[]
  ) => Promise<void>;
  saveEmployeeSettings: (
    appClientId: string,
    fields: SettingField[]
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AppXchangeSettingsComponentProps,
  AppState
> = (state, ownProps) => {
  const { settingsStatus } = state.authorization.singleAppClient;

  return {
    fields: settingsFields(ownProps.type)(state),
    settingsStatus
  };
};

const mapDispacthToProps: MapDispatchToProps<
  DispatchProps,
  AppXchangeSettingsComponentProps
> = (dispatch) => ({
  saveCompanySettings: (appClientId: string, fields: SettingField[]) =>
    dispatch(
      AuthorizationThunks.AppClient.saveCompanySettings(appClientId, fields)
    ),
  saveEmployeeSettings: (appClientId: string, fields: SettingField[]) =>
    dispatch(
      AuthorizationThunks.AppClient.saveEmployeeSettings(appClientId, fields)
    )
});

export type AppXchangeSettingsContainerProps = StateProps & DispatchProps;
export const AppXchangeSettingsContainer = connect(
  mapStateToProps,
  mapDispacthToProps
)(AppXchangeSettingsComponent);
