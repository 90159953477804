import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import {
  DynamicDocumentsThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  DynamicDocumentsListOfGoodsComponent,
  DynamicDocumentsListOfGoodsComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState } from "@haywork/stores";
import { ListOfGoodsValueExtended } from "@haywork/stores/dynamic-documents/single";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  session: DocumentSession;
  template: DocumentTemplate;
  listOfGoods: ListOfGoodsValueExtended;
}
interface DispatchProps {
  updateListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) => void;
  deleteFromListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) => void;
  addEmptyValueToListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsListOfGoodsComponentProps,
  AppState
> = (state) => {
  const { session, template } = state.dynamicDocuments.single;
  const { listOfGoods } = state.editable.currentComponentState;

  return {
    session,
    template,
    listOfGoods
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsListOfGoodsComponentProps
> = (dispatch) => ({
  updateListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) =>
    dispatch(DynamicDocumentsThunks.updateSessionListOfGoods(valueToUpdate)),
  deleteFromListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) =>
    dispatch(DynamicDocumentsThunks.deleteFromListOfGoods(valueToUpdate)),
  addEmptyValueToListOfGoods: (valueToUpdate: ListOfGoodsValueExtended) =>
    dispatch(DynamicDocumentsThunks.addEmptyValueToListOfGoods(valueToUpdate))
});

export type DynamicDocumentsListOfGoodsContainerProps = StateProps &
  DispatchProps;
export const DynamicDocumentsListOfGoodsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsListOfGoodsComponent);
