import { EventType, NotificationEvent } from "@haywork/api/event-center";

export const PERSONAL_EVENT_TYPES = [
  EventType.BankwarrantyExpired,
  EventType.PublicationFailed,
  EventType.PublicationSucceeded,
  EventType.RentedUntilExpired,
  EventType.ReservationExpired,
  EventType.RentReservationExpired,
  EventType.Valuation,
  EventType.Viewing,
  EventType.TransferExpired,
  EventType.BrochureDownloaded,
  EventType.ContactMeRequest,
  EventType.ViewingRequest,
  EventType.PersonalMessage,
  EventType.OutgoingBidExpired,
  EventType.IncomingBidExpired,
  EventType.SentEmailOpened,
  EventType.DocumentSigned,
  EventType.DocumentSignDeclined,
  EventType.DocumentSignExpired,
];

export const MLS_EVENT_TYPES = [
  EventType.BundleMutationNew,
  EventType.BundleMutationWithdrawn,
  EventType.BundleMutationPriceChange,
  EventType.BundleMutationTransferred,
  EventType.BundleMutationUnderBid,
  EventType.BundleMutationUnderOption,
  EventType.BundleMutationUnderCondition,
  EventType.BundleMutationDeedSigned,
  EventType.BundleMutationAvailable,
];

export const PERSONAL_EVENT_TYPES_IMMUTABLE = [
  EventType.SendMailFailed,
  EventType.SendMailSuccess,
];

export const GENERAL_EVENT_TYPES = [
  EventType.AccountCreated,
  EventType.Birthday,
  EventType.ContactForm,
  EventType.OfferListing,
  EventType.Other,
  EventType.RentedFromExpired,
  EventType.Searchprofile,
  EventType.SoldExpired,
  EventType.SystemMessage,
  EventType.NvmOauthTokenExpired,
  EventType.AccountDeleted,
  EventType.CompanyMessage,
];

export const GENERAL_EVENT_TYPES_IMMUTABLE = [];

export const TIMESPANSFORMS = [
  EventType.Valuation,
  EventType.RentedUntilExpired,
  EventType.NvmOauthTokenExpired,
  EventType.Viewing,
  EventType.BankwarrantyExpired,
  EventType.ReservationExpired,
  EventType.RentReservationExpired,
  EventType.TransferExpired,
  EventType.OutgoingBidExpired,
  EventType.IncomingBidExpired,
  EventType.DocumentSigned,
  EventType.DocumentSignDeclined,
  EventType.DocumentSignExpired,
];

export const DELEGATEFORMS = [
  EventType.Valuation,
  EventType.RentedFromExpired,
  EventType.PublicationFailed,
  EventType.PublicationSucceeded,
  EventType.ReservationExpired,
  EventType.RentReservationExpired,
  EventType.RentedUntilExpired,
  EventType.NvmOauthTokenExpired,
  EventType.Viewing,
  EventType.BankwarrantyExpired,
  EventType.TransferExpired,
  EventType.BrochureDownloaded,
  EventType.ContactMeRequest,
  EventType.ViewingRequest,
  EventType.PersonalMessage,
  EventType.OutgoingBidExpired,
  EventType.IncomingBidExpired,
  EventType.SentEmailOpened,
  EventType.BundleMutationNew,
  EventType.BundleMutationWithdrawn,
  EventType.BundleMutationPriceChange,
  EventType.BundleMutationTransferred,
  EventType.BundleMutationUnderBid,
  EventType.BundleMutationUnderOption,
  EventType.BundleMutationUnderCondition,
  EventType.BundleMutationDeedSigned,
  EventType.BundleMutationAvailable,
  EventType.DocumentSigned,
  EventType.DocumentSignDeclined,
  EventType.DocumentSignExpired,
];

export const MULTITIMESPANSFORMS = [];

export const EVENTS_WITH_TOAST = [
  NotificationEvent.Unknown,
  NotificationEvent.AccountCreated,
  NotificationEvent.AccountDeleted,
  NotificationEvent.BankwarrantyExpired,
  NotificationEvent.Birthday,
  NotificationEvent.BrochureDownloaded,
  NotificationEvent.ContactMeRequest,
  NotificationEvent.NvmOauthTokenExpired,
  NotificationEvent.OfferListing,
  NotificationEvent.PublicationFailed,
  NotificationEvent.PublicationSucceeded,
  NotificationEvent.RentedFromExpired,
  NotificationEvent.RentedUntilExpired,
  NotificationEvent.ReservationExpired,
  NotificationEvent.Searchprofile,
  NotificationEvent.SoldExpired,
  NotificationEvent.SystemMessage,
  NotificationEvent.TransferExpired,
  NotificationEvent.Valuation,
  NotificationEvent.Viewing,
  NotificationEvent.ViewingRequest,
  NotificationEvent.SendMailFailed,
  NotificationEvent.SendMailSuccess,
  NotificationEvent.PersonalMessage,
  NotificationEvent.IncomingBidExpired,
  NotificationEvent.OutgoingBidExpired,
  NotificationEvent.ContactForm,
  NotificationEvent.BundleMutationNew,
  NotificationEvent.BundleMutationWithdrawn,
  NotificationEvent.BundleMutationPriceChange,
  NotificationEvent.BundleMutationTransferred,
  NotificationEvent.BundleMutationUnderBid,
  NotificationEvent.BundleMutationUnderOption,
  NotificationEvent.BundleMutationUnderCondition,
  NotificationEvent.BundleMutationDeedSigned,
  NotificationEvent.BundleMutationAvailable,
  NotificationEvent.DocumentSigned,
  NotificationEvent.DocumentSignDeclined,
  NotificationEvent.DocumentSignExpired,
];
