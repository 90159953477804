import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  image: string | null | undefined;
  small?: boolean;
};

export const PreviewComponent: FC<Props> = memo(
  CSSModules(styles)(({ image, small }) => {
    return (
      <div
        styleName="preview"
        style={{
          backgroundImage: !!image ? `url(${JSON.stringify(image)})` : null,
        }}
      >
        {!image && (
          <div styleName="preview__icon">
            <Icon
              name="camera-alt"
              size={!small ? 24 : 16}
              light
              color={Colors.MediumGray}
            />
          </div>
        )}
      </div>
    );
  })
);
