import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ResourceText } from "@haywork/modules/shared";
import {
  ProductOrder,
  Invoice,
  FinancialAdministration,
} from "@haywork/api/kolibri";
import {
  ProductOrderComponent,
  ExtendedProductOrder,
} from "@haywork/modules/invoice";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

const styles = require("./marketing-budget-modal.component.scss");

interface MarketingBudgetModalComponentProps {
  productOrders: ProductOrder[];
  onClose: () => void;
  onChange: (productOrders: ProductOrder[]) => void;
  financialAdministration: FinancialAdministration;
  invoice: Invoice;
}

interface MarketingBudgetModalComponentState {
  extendedProductOrders: ExtendedProductOrder[];
  checked: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class MarketingBudgetModalComponent extends React.Component<
  MarketingBudgetModalComponentProps,
  MarketingBudgetModalComponentState
> {
  constructor(props) {
    super(props);

    this.setCheckedProductOrder = this.setCheckedProductOrder.bind(this);
    this.addMarketingBudgetToInvoice =
      this.addMarketingBudgetToInvoice.bind(this);
    this.checkAllProducts = this.checkAllProducts.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      extendedProductOrders: [],
      checked: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: MarketingBudgetModalComponentProps
  ) {
    if (!nextProps) return;

    const products = this.props.financialAdministration
      ? this.props.financialAdministration.products
      : [];

    const mappedProductOrders: ExtendedProductOrder[] =
      nextProps.productOrders.map((productOrder) => {
        const financialAdministrationProduct = products.find(
          (p) => p.id === productOrder.financialAdministrationProductId
        );
        return {
          checked: false,
          displayName: financialAdministrationProduct
            ? financialAdministrationProduct.descriptionShort || ""
            : "",
          productOrder,
        };
      });

    this.setState({
      extendedProductOrders: mappedProductOrders,
    });
  }

  public render() {
    const checkedStyle = this.state.checked
      ? classNames("fal fa-check-square")
      : classNames("fal fa-square");

    return (
      <div styleName="marketing-budget-modal">
        <div styleName="headerModal">
          <div styleName="title">
            <ResourceText resourceKey="marketingBudgetProducts" />
          </div>
          <div styleName="close" onClick={this.onClose}>
            <i className="fal fa-fw fa-times" />
          </div>
        </div>
        <div styleName="item header">
          <div styleName="option check">
            <i className={checkedStyle} onClick={this.checkAllProducts} />
          </div>
          <div styleName="option date">
            <ResourceText resourceKey="date" />
          </div>
          <div styleName="option product">
            <ResourceText resourceKey="product" />
          </div>
          <div styleName="option description">
            <ResourceText resourceKey="description" />
          </div>
          <div styleName="option amount">
            <ResourceText resourceKey="InvoiceAmountExclusive" />
          </div>
        </div>
        {this.state.extendedProductOrders &&
          this.state.extendedProductOrders.map((productOrder, idx) => (
            <ErrorBoundary key={productOrder.productOrder.id}>
              <ProductOrderComponent
                productOrder={productOrder}
                isChecked={this.setCheckedProductOrder}
                currency={this.props.invoice.currency}
              />
            </ErrorBoundary>
          ))}
        <div styleName="footer">
          <button
            className="btn btn-primary"
            onClick={this.addMarketingBudgetToInvoice}
          >
            <ResourceText resourceKey="add" />
          </button>
        </div>
      </div>
    );
  }

  private setCheckedProductOrder(checked: boolean, id: string) {
    const newExtendedProductOrders: ExtendedProductOrder[] =
      this.state.extendedProductOrders.map((productOrder) => {
        if (productOrder.productOrder.id === id) {
          productOrder.checked = checked;
        }

        return productOrder;
      });

    const filtered = this.state.extendedProductOrders.filter(
      (e) => !!e.checked
    );

    this.setState({
      checked: filtered.length === this.state.extendedProductOrders.length,
      extendedProductOrders: newExtendedProductOrders,
    });
  }

  private checkAllProducts() {
    const newExtendedProductOrders: ExtendedProductOrder[] =
      this.state.extendedProductOrders.map((productOrder) => {
        productOrder.checked = !this.state.checked;
        return productOrder;
      });

    this.setState({
      checked: !this.state.checked,
      extendedProductOrders: newExtendedProductOrders,
    });
  }

  private addMarketingBudgetToInvoice() {
    const productOrders = this.state.extendedProductOrders
      .filter((e) => !!e.checked)
      .map((p) => p.productOrder);
    this.props.onChange(productOrders);
    this.setState({
      checked: false,
    });
  }

  private onClose() {
    this.setState({
      checked: false,
    });
    this.props.onClose();
  }
}
