import { actions as AssignmentListActions } from "./actions";
import {
  reducer as assignmentListReducer,
  State as AssignmentListState
} from "./reducer";
import { SortOrder, AssignmentOrderByField } from "@haywork/api/kolibri";

export type AssignmentOrder = {
  sortOrder: SortOrder;
  sortColumn: AssignmentOrderByField;
};

export { assignmentListReducer, AssignmentListActions, AssignmentListState };
