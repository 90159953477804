import {
  AssignmentSnapShot,
  AssignmentType,
  AssignmentTypeOption,
  FurnishingOption,
  ListingTypeOption,
  RealEstateGroupOption,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { ASSIGNMENTROUTES, MAINROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  MapDispatchToProps,
  ProjectsThunks,
} from "@haywork/middleware";
import {
  AssignmentOverviewComponent,
  AssignmentOverviewComponentProps,
} from "@haywork/modules/assignment";
import {
  AppState,
  AssignmentOverviewActions,
  AssignmentsFilters,
  PagedResults,
} from "@haywork/stores";
import {
  RouteUtil,
  SimpleLabelOriginal,
  SimpleLabelValue,
} from "@haywork/util";
import { push } from "connected-react-router";
import sortBy from "lodash-es/sortBy";
import { connect, MapStateToProps } from "react-redux";

type StateProps = {
  assignments: PagedResults<AssignmentSnapShot>;
  commissionTotal: number;
  localities: SimpleLabelValue[];
  employees: SimpleLabelValue[];
  offices: SimpleLabelValue[];
  listingTypes: SimpleLabelValue[];
  assignmentsFilters: AssignmentsFilters;
  assignmentPhases: SimpleLabelValue[];
  availabilityStatuses: SimpleLabelValue[];
  realEstateGroups: SimpleLabelValue[];
  assignmentTypes: SimpleLabelValue[];
  kindOfAssignment: SimpleLabelValue[];
  selectedFilters: SimpleLabelOriginal[];
  realEstateAgencyId: string;
  keyNumbers: SimpleLabelValue[];
  furnishingOptions: FurnishingOption[];
};

type DispatchProps = {
  getAssignments: (init?: boolean, take?: number) => void;
  setAssignmentsFilters: (filters: AssignmentsFilters) => void;
  clearAllFilters: () => void;
  clearFilter: (filter: SimpleLabelOriginal) => void;
  exportAssignments: () => void;
  goToAssignment: (id: string) => void;
  goToFeature: () => void;
  openAssignment: (id: string, snapshot: AssignmentSnapShot) => void;
  openProject: (id: string, snapshot: AssignmentSnapShot) => void;
  openObjectType: (id: string, snapshot: AssignmentSnapShot) => void;
};

const mapEmployeesAndOffices = (
  relations: RelationSnapShot[]
): SimpleLabelValue[] => {
  return relations.map((relation) => ({
    label: relation.displayName,
    value: relation.id,
  }));
};

const mapSimple = (array: string[]): SimpleLabelValue[] => {
  return array.map((item) => ({ label: item, value: item }));
};

const mapSimpleNumber = (array: number[]): SimpleLabelValue[] => {
  return array.map((item) => ({
    label: item.toString(),
    value: item.toString(),
  }));
};

const mapListingTypeOption = (
  array: ListingTypeOption[]
): SimpleLabelValue[] => {
  return array.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapMastertableLists = (list: any): SimpleLabelValue[] => {
  return list.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapAssignmentPhases = (list: any[]): SimpleLabelValue[] => {
  let values = list.map((item) => ({
    label: item.displayName,
    value: item.value,
  }));
  values = [...values, { label: "archived", value: "archived" }];

  return values;
};

const mapSelectedFilters = (
  filters: AssignmentsFilters,
  offices: RelationSnapShot[],
  employees: RelationSnapShot[]
) => {
  let selectedFilters = [];

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const values = filters[key];
      if (values && values.length > 0) {
        let mappedValues;
        switch (key) {
          case "officeIds":
            mappedValues = values.map((item) => {
              const ref = offices.find((office) => office.id === item);
              return { label: ref.displayName, value: ref.id, filter: key };
            });
            break;
          case "employeeIds":
            mappedValues = values.map((item) => {
              const ref = employees.find((employee) => employee.id === item);
              return { label: ref.displayName, value: ref.id, filter: key };
            });
            break;
          case "listingTypes":
          case "availabilityStatuses":
          case "assignmentPhases":
            mappedValues = values.map((item) => ({
              label: `${key}.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "filterByRealEstateGroups":
            mappedValues = values.map((item) => ({
              label: `realEstateGroups.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "filterByAssignmentTypes":
            mappedValues = values.map((item) => ({
              label: `assignmentTypes.${item}`,
              value: item,
              filter: key,
            }));
            break;
          case "filterByKeyNumbers":
            mappedValues = [
              {
                label: sortBy(values, parseInt).join(", "),
                value: null,
                filter: key,
              },
            ];
            break;
          case "filterByFurnishings": {
            mappedValues = values.map((item) => ({
              label: `furnishingOptions.${item}`,
              value: item,
              filter: key,
            }));
            break;
          }
          case "filterByMinPrice": {
            mappedValues = !!filters.filterByMinPrice
              ? [
                  {
                    label: "assignmentFilter.filterByMinPrice",
                    value: filters.filterByMinPrice,
                    filter: key,
                  },
                ]
              : [];
            break;
          }
          case "filterByMaxPrice": {
            mappedValues = !!filters.filterByMaxPrice
              ? [
                  {
                    label: "assignmentFilter.filterByMaxPrice",
                    value: filters.filterByMaxPrice,
                    filter: key,
                  },
                ]
              : [];
            break;
          }
          case "filterByNumberOfBedroomsMin": {
            mappedValues = !!filters.filterByNumberOfBedroomsMin
              ? [
                  {
                    label: "assignmentFilter.filterByNumberOfBedroomsMin",
                    value: filters.filterByNumberOfBedroomsMin,
                    filter: key,
                  },
                ]
              : [];
            break;
          }
          case "filterByNumberOfBedroomsMax": {
            mappedValues = !!filters.filterByNumberOfBedroomsMax
              ? [
                  {
                    label: "assignmentFilter.filterByNumberOfBedroomsMax",
                    value: filters.filterByNumberOfBedroomsMax,
                    filter: key,
                  },
                ]
              : [];
            break;
          }
          default:
            mappedValues = values.map((item) => ({
              label: item,
              value: item,
              filter: key,
            }));
            break;
        }

        selectedFilters = [...selectedFilters, ...mappedValues];
      }
    }
  }
  return selectedFilters;
};

const mapRealestateGroups = (
  list: RealEstateGroupOption[]
): SimpleLabelValue[] => {
  return list.map((item) => ({ label: item.displayName, value: item.value }));
};

const mapAssignmentTypes = (
  list: AssignmentTypeOption[]
): SimpleLabelValue[] => {
  return list.map((item) => ({ label: item.displayName, value: item.value }));
};

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentOverviewComponentProps,
  AppState
> = (state, ownProps) => {
  const {
    assignments,
    assignmentsCommissionTotal,
    assignmentsLocalities,
    assignmentsFilters,
    keyNumbers,
  } = state.assignment.overview;
  const { listingTypes, furnishingOptions } = state.main.mastertable.kolibri;
  const { employees, offices, currentRealestateAgency } = state.account;
  const assignmentTypes = [];

  for (const val of mapAssignmentTypes(
    state.main.mastertable.kolibri.assignmentTypes
  )) {
    switch (val.value) {
      case AssignmentType.Object:
        val.label = intlContext.formatMessage({ id: "objects" });
        assignmentTypes.push(val);
        break;
      case AssignmentType.Project:
        val.label = intlContext.formatMessage({ id: "projects" });
        assignmentTypes.push(val);
        break;
      case AssignmentType.ObjectType:
        break;
      default:
        break;
    }
  }

  return {
    assignments,
    commissionTotal: assignmentsCommissionTotal,
    localities: mapSimple(assignmentsLocalities),
    employees: mapEmployeesAndOffices(state.account.employees),
    offices: mapEmployeesAndOffices(state.account.offices),
    listingTypes: mapListingTypeOption(listingTypes),
    assignmentsFilters,
    assignmentPhases: mapAssignmentPhases(
      state.main.mastertable.kolibri.assignmentPhases
    ),
    availabilityStatuses: mapMastertableLists(
      state.main.mastertable.kolibri.availabilityStatuses
    ),
    realEstateGroups: mapRealestateGroups(
      state.main.mastertable.kolibri.realEstateGroups
    ),
    assignmentTypes,
    kindOfAssignment: [
      { label: "sale", value: "sale" },
      { label: "rentLease", value: "rentLease" },
    ],
    selectedFilters: mapSelectedFilters(assignmentsFilters, offices, employees),
    realEstateAgencyId: currentRealestateAgency.id,
    keyNumbers: mapSimpleNumber(keyNumbers),
    furnishingOptions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentOverviewComponentProps
> = (dispatch) => ({
  getAssignments: (init?: boolean, take?: number) =>
    dispatch(AssignmentThunks.getAssignments(init, take)),
  setAssignmentsFilters: (filters: AssignmentsFilters) =>
    dispatch(
      AssignmentOverviewActions.setAssignmentsFilters({
        assignmentsFilters: filters,
      })
    ),
  clearAllFilters: () => dispatch(AssignmentOverviewActions.clearAllFilters()),
  clearFilter: (filter: SimpleLabelOriginal) =>
    dispatch(AssignmentOverviewActions.clearFilter({ filter })),
  exportAssignments: () => dispatch(AssignmentThunks.exportAssignments()),
  goToAssignment: (id: string) =>
    dispatch(push(route(ASSIGNMENTROUTES.DETAIL.URI, { id }))),
  goToFeature: () => dispatch(push(MAINROUTES.FEATURE.URI)),
  openAssignment: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(AssignmentThunks.getAssignment(id, snapshot)),
  openProject: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(ProjectsThunks.Projects.getProject(id, snapshot)),
  openObjectType: (id: string, snapshot: AssignmentSnapShot) =>
    dispatch(ProjectsThunks.Types.getType(id, snapshot)),
});

export type AssignmentOverviewContainerProps = StateProps & DispatchProps;
export const AssignmentOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentOverviewComponent);
