import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch } from "@haywork/middleware";
import {
  ProjectEditMarketingVideoComponent,
  ProjectEditMarketingVideoComponentProps
} from "@haywork/modules/project/components/marketing-videos/marketing-videos.component";
import { AppState, EditableActions, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";

interface StateProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  path: string;
}

interface DispatchProps {
  updateProjectAssignment: (
    componentState: SingleProjectState,
    path: string
  ) => any;
}

const mapStateToProps = <StateProps, ProjectEditMarketingVideoComponentProps>(
  state: AppState
) => {
  const { currentComponentState } = state.editable;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id
    })
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ProjectEditMarketingVideoComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateProjectAssignment: (componentState: SingleProjectState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type ProjectEditMarketingVideoContainerProps = StateProps &
  DispatchProps;
export const ProjectEditMarketingVideoContainer = connect<
  StateProps,
  DispatchProps,
  ProjectEditMarketingVideoComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingVideoComponent);
