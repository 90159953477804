import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { LinkedAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { FormattedDate } from "react-intl";
import { RouteUtil } from "@haywork/util";
import { ASSIGNMENTROUTES } from "@haywork/constants";

const styles = require("./detail-versions.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  assignment: LinkedAssignment;
  zebra: boolean;
  isLatest?: boolean;
  onClick: (path: string) => void;
}

export const ListItem = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const {
      displayName,
      photo1Url,
      assignmentPhase,
      availabilityStatus,
      publicReference,
      id,
      dateTimeModified,
    } = props.assignment;
    const previewStyles: React.CSSProperties = {
      backgroundImage: photo1Url ? `url(${photo1Url})` : null,
    };
    const itemStyle = classNames("item", { zebra: props.zebra });

    return (
      <div
        styleName={itemStyle}
        onClick={() =>
          props.onClick(route(ASSIGNMENTROUTES.DETAIL.URI, { id }))
        }
      >
        <div styleName="item__preview">
          <div styleName="preview" style={previewStyles}>
            {!photo1Url && <i className="fal fa-fw fa-camera-retro" />}
          </div>
        </div>

        <div styleName="item__meta">
          {(!!publicReference || !!props.isLatest) && (
            <div styleName="meta">
              {!!publicReference && (
                <div styleName="meta__pill pubref">{publicReference}</div>
              )}
              {!!props.isLatest && (
                <div styleName="meta__pill">
                  <ResourceText resourceKey="assignmentVersionLatest" />
                </div>
              )}
            </div>
          )}
          <h1>{displayName}</h1>
        </div>
        <div styleName="item__date">
          <ResourceText resourceKey="assignmentLastUpdated" />
          <br />
          <strong>
            <FormattedDate
              value={dateTimeModified}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </strong>
        </div>
        <div styleName="item__status">
          {!!assignmentPhase && (
            <h2>
              <ResourceText
                masterKey="assignmentPhases"
                resourceKey={assignmentPhase.toString()}
              />
            </h2>
          )}
          {!!availabilityStatus && (
            <h3>
              <ResourceText
                masterKey="availabilityStatuses"
                resourceKey={availabilityStatus.toString()}
              />
            </h3>
          )}
        </div>
      </div>
    );
  }
);
