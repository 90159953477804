import { PhraseSnapShot } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./phrase.component.scss");

interface Props {
  phrase: PhraseSnapShot;
  selected: boolean;
  onPhraseClick: (phrase: PhraseSnapShot) => void;
  onPhraseEdit: (phrase: PhraseSnapShot) => void;
  onPhraseDelete: (id: string) => void;
  onDelete: (id: string) => void;
  onUnDelete: (id: string) => void;
}
interface State {
  showActions: boolean;
  markedForDelete: boolean;
  countdown: number;
}

@CSSModules(styles, { allowMultiple: true })
export class PhraseComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.onDeleteCancelHandler = this.onDeleteCancelHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);

    this.state = {
      showActions: false,
      markedForDelete: false,
      countdown: 5,
    };

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const phraseStyle = classNames("phrase", { selected: this.props.selected });
    const actionsStyle = classNames("phrase__actions", {
      visible: this.state.showActions,
    });
    const deleteStyle = classNames("phrase__delete", {
      "marked-for-delete": this.state.markedForDelete,
    });

    return (
      <div styleName={phraseStyle} ref={(ref) => (this.ref = ref)}>
        <div styleName={deleteStyle} onClick={this.onDeleteCancelHandler}>
          <ResourceText resourceKey="undoDelete" /> ({this.state.countdown})
        </div>
        <div
          styleName="phrase__title"
          onClick={() => this.props.onPhraseClick(this.props.phrase)}
        >
          {this.props.phrase.title}
        </div>
        <div styleName="toggle-actions" onClick={this.onToggleClickHandler}>
          <span className="fal fa-ellipsis-v" />
        </div>
        <div styleName={actionsStyle}>
          <div
            className="btn btn-success"
            styleName="action"
            onClick={() => this.props.onPhraseEdit(this.props.phrase)}
          >
            <span className="fal fa-pencil" />
          </div>
          <div
            className="btn btn-danger"
            styleName="action"
            onClick={this.onDeleteClickHandler}
          >
            <span className="fal fa-times" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.showActions
    )
      this.setState({ showActions: false });
  }

  private onToggleClickHandler() {
    this.setState({ showActions: !this.state.showActions });
  }

  private onDeleteClickHandler() {
    const { id } = this.props.phrase;

    this.setState({ markedForDelete: true, showActions: false, countdown: 5 });
    this.props.onDelete(id);

    this.removeCountdown = setInterval(() => {
      const countdown = this.state.countdown - 1;
      this.setState({ countdown });
      if (countdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.onPhraseDelete(id);
      }
    }, 1000);
  }

  private onDeleteCancelHandler() {
    const { id } = this.props.phrase;
    clearInterval(this.removeCountdown);
    this.setState({ markedForDelete: false });
    this.props.onUnDelete(id);
  }
}
