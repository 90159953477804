import { EmployeeRole, LinkedRealEstateAgency } from "@haywork/api/kolibri";
import {
  AccessThunks,
  CompanySettingsThunks,
  MainThunks,
  Dispatch
} from "@haywork/middleware";
import {
  UserMenuComponent,
  UserMenuComponentProps
} from "@haywork/modules/layout";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { linkedRealEstateAgenciesWithoutCurrent } from "./selectors";
import { eventHubStatus } from "../containers/selectors";

interface StateProps {
  displayName: string;
  avatar: string;
  letterAvatar: string;
  officeDisplayName: string;
  eventHubStatus: string;
  linkedRealEstateAgencies: LinkedRealEstateAgency[];
  exchangeEnabled: boolean;
  housenetEnabled: boolean;
  realEstateAgencyId: string;
  features: string[];
  role: EmployeeRole;
}

interface DispatchProps {
  logout: () => void;
  navigate: (url: string) => void;
  switchRealEstateAgency: (id: string) => void;
  getHousenetSingleSignOnUrl: () => Promise<string>;
  getExchangeSingleSignOnUrl: () => Promise<string>;
}

const mapStateToProps = <StateProps, UserMenuComponentProps>(
  state: AppState
) => {
  const {
    displayName,
    passportPhotoBlob,
    letterAvatar
  } = state.account.employee;
  const { currentRealestateAgency } = state.account;
  const { exchangeEnabled, housenetEnabled } = state.company.settings;
  const { id: realEstateAgencyId, role } = currentRealestateAgency;
  const { features } = state.appSettings;

  return {
    displayName,
    letterAvatar,
    avatar: !!passportPhotoBlob ? passportPhotoBlob.urlPreview : null,
    officeDisplayName: currentRealestateAgency.name,
    eventHubStatus: eventHubStatus(state),
    linkedRealEstateAgencies: linkedRealEstateAgenciesWithoutCurrent(
      currentRealestateAgency.id
    )(state),
    exchangeEnabled,
    housenetEnabled,
    realEstateAgencyId,
    features,
    role
  };
};

const mapDispatchToProps = <DispatchProps, UserMenuComponentProps>(
  dispatch: Dispatch<any>
) => ({
  logout: () => dispatch(AccessThunks.logout()),
  navigate: (url: string) => dispatch(push(url)),
  switchRealEstateAgency: (id: string) =>
    dispatch(MainThunks.switchRealEstateAgency(id)),
  getHousenetSingleSignOnUrl: () =>
    dispatch(CompanySettingsThunks.getHousenetSingleSignOnUrl()),
  getExchangeSingleSignOnUrl: () =>
    dispatch(CompanySettingsThunks.getExchangeSingleSignOnUrl())
});

export type UserMenuContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const UserMenuContainer = withRouter<any, any>(
  connect<StateProps, DispatchProps, UserMenuComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(UserMenuComponent)
);
