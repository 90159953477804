import {
  AgendaItem,
  AgendaItemCategorySnapShot,
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressTypeOption,
  Employee,
  PhoneNumberTypeOption,
  RelationGroupSnapShot,
  RelationSnapShot,
  RelationType,
  Task,
  TaskCategoryOption,
} from "@haywork/api/kolibri";
import {
  Dispatch,
  NewAppointmentRequest,
  NewContactCompanyRequest,
  NewContactPersonRequest,
  NewEntityThunks,
  NewTaskRequest,
  RelationThunks,
} from "@haywork/middleware";
import {
  NewEntityComponent,
  NewEntityComponentProps,
} from "@haywork/modules/new-entity";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

export enum ReminderTimeType {
  Minute = "Minute",
  Hour = "Hour",
  None = "None",
}

export interface ReminderOption {
  id: number;
  value: number;
  type: ReminderTimeType;
}
interface StateProps {
  titleSuggestions: ContactPersonTitleSuggestionOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  phoneNumberTypes: PhoneNumberTypeOption[];
  employees: RelationSnapShot[];
  employee: Employee;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  reminderOptions: ReminderOption[];
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
  relationGroups: RelationGroupSnapShot[];
}
interface DispatchProps {
  newContactPerson: (
    request: NewContactPersonRequest
  ) => Promise<RelationSnapShot>;
  newContactCompany: (
    request: NewContactCompanyRequest
  ) => Promise<RelationSnapShot>;
  newTask: (request: NewTaskRequest) => Promise<Task>;
  newAppointment: (request: NewAppointmentRequest) => Promise<AgendaItem>;
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    email: string[],
    phoneNumber?: string
  ) => Promise<RelationSnapShot[]>;
}

const reminderOptions: ReminderOption[] = [
  { id: 1, value: null, type: ReminderTimeType.None },
  { id: 2, value: 5, type: ReminderTimeType.Minute },
  { id: 3, value: 10, type: ReminderTimeType.Minute },
  { id: 4, value: 15, type: ReminderTimeType.Minute },
  { id: 5, value: 30, type: ReminderTimeType.Minute },
  { id: 6, value: 1, type: ReminderTimeType.Hour },
  { id: 7, value: 2, type: ReminderTimeType.Hour },
  { id: 8, value: 4, type: ReminderTimeType.Hour },
  { id: 9, value: 8, type: ReminderTimeType.Hour },
];

const mapStateToProps = <StateProps, NewEntityComponentProps>(
  state: AppState
) => {
  const {
    contactPersonTitleSuggestions: titleSuggestions,
    emailAddressTypes,
    phoneNumberTypes,
  } = state.main.mastertable.kolibri;
  const { employees, employee } = state.account;
  const { taskCategories } = state.main.mastertable.kolibri;
  const { agendaItemCategories } = state.scheduler;
  const { countries } = state.main.mastertable.kolibri;
  const { countryIso2 } =
    state.realEstateAgency.realEstateAgency.legalRegistration;
  const { culture } = state.main;
  const { relationGroups } = state.relationGroups;

  return {
    titleSuggestions,
    emailAddressTypes,
    phoneNumberTypes,
    employees,
    employee,
    taskCategories,
    agendaItemCategories,
    reminderOptions,
    countries,
    countryIso2,
    culture,
    relationGroups,
  };
};

const mapDispatchToProps = <DispatchProps, NewEntityComponentProps>(
  dispatch: Dispatch<any>
) => ({
  newContactPerson: (request: NewContactPersonRequest) =>
    dispatch(NewEntityThunks.newContactPerson(request)),
  newContactCompany: (request: NewContactCompanyRequest) =>
    dispatch(NewEntityThunks.newContactCompany(request)),
  newAppointment: (request: NewAppointmentRequest) =>
    dispatch(NewEntityThunks.newAppointment(request)),
  newTask: (request: NewTaskRequest) =>
    dispatch(NewEntityThunks.newTask(request)),
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    email: string[],
    phoneNumber?: string
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddressOrPhoneNumber(
        email,
        [RelationType.ContactCompany, RelationType.ContactPerson],
        phoneNumber
      )
    ),
});

export type NewEntityContainerProps = StateProps & DispatchProps;
export const NewEntity = connect<
  StateProps,
  DispatchProps,
  NewEntityComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(NewEntityComponent);
