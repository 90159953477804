import * as React from "react";
import { FC, memo, useState, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import {
  FolderTreeFolderEntity,
  FolderTreeFileEntity,
} from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import File from "../file";

const styles = require("./style.scss");

type Props = {
  folder: FolderTreeFolderEntity;
  depth: number;
  selectedFiles: FolderTreeFileEntity[];
  onToggleSelect: (file: FolderTreeFileEntity) => void;
};

export const FolderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ folder, depth, selectedFiles, onToggleSelect }) => {
      const { folders, files, name } = folder;

      const canCollapse = useMemo(() => {
        return (!!folders && !!folders.length) || (!!files && !!files.length);
      }, [folders, files]);

      const [collapsed, setCollapsed] = useState(depth > 0 || !canCollapse);

      const toggleCollapsed = useCallback(() => {
        if (!canCollapse) return;
        setCollapsed(!collapsed);
      }, [collapsed, setCollapsed, canCollapse]);

      return (
        <div styleName="folder">
          <div
            onClick={toggleCollapsed}
            styleName={classNames("header", `depth-${depth}`, {
              "can-collapse": canCollapse,
            })}
          >
            {canCollapse && (
              <div styleName="header__icon">
                <Icon
                  name={collapsed ? "chevron-right" : "chevron-down"}
                  color={Colors.Primary}
                  regular
                  size={14}
                />
              </div>
            )}
            <div styleName="header__icon">
              <Icon
                name={collapsed ? "folder" : "folder-open"}
                color={Colors.Primary}
                solid
                size={16}
              />
            </div>
            <div styleName="header__label">{name}</div>
          </div>
          {!collapsed && (
            <div>
              {(folders || []).map((folder, idx) => (
                <FolderComponent
                  folder={folder}
                  depth={depth + 1}
                  key={folder.name + idx}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                />
              ))}
              {(files || []).map((file) => (
                <File
                  file={file}
                  key={file.id}
                  depth={depth}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
