import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";

import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { uiTableRow, Ui, UiTableRowProps } from "@haywork/modules/ui";
import { Input } from "@haywork/modules/form";

const styles = require("./list-item.component.scss");

interface SignatureListItemComponentProps {
  signature: TemplateDefinitionSnapShot;
  zebra: boolean;
  defaultSignatureGuid: string;
  onDefaultSignatureChange: (id: string, active: boolean) => void;
  onOpen: (id: string) => void;
  onDelete: (id: string) => void;
  onUndelete: (id: string) => void;
  onDeleteDone: (id: string) => void;
}
interface State {
  showActionOverlay: boolean;
}
type Props = SignatureListItemComponentProps & UiTableRowProps;

@CSSModules(styles, { allowMultiple: true })
export class SignatureListItemComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showActionOverlay: false
    };

    this.onActionDoneHandler = this.onActionDoneHandler.bind(this);
    this.onActionCancelHandler = this.onActionCancelHandler.bind(this);
    this.onDefaultSignatureChange = this.onDefaultSignatureChange.bind(this);
  }

  public render() {
    const { signature, defaultSignatureGuid } = this.props;
    const isDefault = get(signature, "id") === defaultSignatureGuid;

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
        actionTitle="cancelEmailSignatureDelete"
        actionVisible={this.state.showActionOverlay}
        onActionDone={this.onActionDoneHandler}
        onActionCancel={this.onActionCancelHandler}
      >
        <div styleName="list-item" style={this.props.gridTemplateColumns}>
          <div styleName="column list-item__name">
            {get(this.props.signature, "name", "")}
          </div>
          <div styleName="column list-item__is-default">
            <Input.Switch
              name="is-default"
              on={true}
              off={false}
              value={isDefault}
              onChange={this.onDefaultSignatureChange}
            />
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private renderListActions() {
    const deleteButton = () => (
      <button
        className="btn btn-danger"
        data-cy="CY-deleteSignatureButton"
        onClick={() => {
          this.props.onDelete(this.props.signature.id);
          this.setState({ showActionOverlay: true });
        }}
      >
        <i className="fal fa-times" />
      </button>
    );

    const editButton = () => (
      <button
        className="btn btn-success"
        onClick={() => this.props.onOpen(this.props.signature.id)}
      >
        <i className="fal fa-pencil" />
      </button>
    );

    return [deleteButton, editButton];
  }

  private onActionDoneHandler() {
    this.setState({
      showActionOverlay: false
    });
    this.props.onDeleteDone(this.props.signature.id);
  }

  private onActionCancelHandler() {
    this.setState({
      showActionOverlay: false
    });
    this.props.onUndelete(this.props.signature.id);
  }

  private onDefaultSignatureChange(active: boolean) {
    this.props.onDefaultSignatureChange(this.props.signature.id, active);
  }
}

export const SignatureListItem = uiTableRow(SignatureListItemComponent);
