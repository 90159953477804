import {
  Task,
  TaskDelegateFilter,
  TaskSnapShot,
  TasksStatistics,
  TaskStatus
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import orderBy from "lodash-es/orderBy";
import { Action } from "redux";
import * as ActionType from "./task.types";

export enum TaskFilterStatus {
  InProgress = "InProgress",
  Executed = "Executed",
  All = "All",
  Delegated = "Delegated"
}

export interface TaskState {
  task: Task;
  currentTaskStatus: string;
  taskStatus: string;
  tasks: TaskSnapShot[];
  tasksStatistics: TasksStatistics;
  upcomingTasks: TaskSnapShot[];
  tasksFilters: {
    statusFilterMap: TaskFilterStatus;
    statusFilter: TaskStatus[];
    employeeFilter: string;
    categoryFilter: string[];
    delegateFilter: TaskDelegateFilter;
  };
  taskOverviewStatus: string;
  taskInitialOverviewStatus: string;
  taskWidgetState: string;
  taskOverviewPage: number;
  taskOverviewPageCount: number;
  totalResultsWidget: number;
}

const INITIAL_STATE: TaskState = {
  task: null,
  upcomingTasks: null,
  taskWidgetState: REQUEST.IDLE,
  currentTaskStatus: REQUEST.IDLE,
  taskStatus: REQUEST.IDLE,
  tasks: [],
  tasksStatistics: null,
  tasksFilters: {
    statusFilterMap: TaskFilterStatus.InProgress,
    statusFilter: [
      TaskStatus.NotStarted,
      TaskStatus.InProgress,
      TaskStatus.Waiting,
      TaskStatus.Deferred
    ],
    employeeFilter: null,
    categoryFilter: null,
    delegateFilter: TaskDelegateFilter.AssignedToMe
  },
  taskOverviewStatus: REQUEST.IDLE,
  taskInitialOverviewStatus: REQUEST.IDLE,
  taskOverviewPage: 0,
  taskOverviewPageCount: 0,
  totalResultsWidget: 0
};

export const taskReducer = (
  state: TaskState = INITIAL_STATE,
  action: Action
): TaskState => {
  switch (action.type) {
    case REDUX.TASK.SET_TASK_OVERVIEW_STATUS: {
      const { taskOverviewStatus } = <ActionType.TaskOverviewState>action;
      return { ...state, taskOverviewStatus };
    }
    case REDUX.TASK.SET_TASK_INITIAL_OVERVIEW_STATUS: {
      const { taskOverviewStatus } = <ActionType.TaskOverviewState>action;
      return { ...state, taskInitialOverviewStatus: taskOverviewStatus };
    }
    case REDUX.TASK.SET_TASK_WIDGET_STATE: {
      const { taskWidgetState } = <ActionType.TaskWidgetState>action;
      return { ...state, taskWidgetState };
    }
    case REDUX.TASK.SET_TASKS: {
      const { results, resultCount, totalResults, take, statistics } = <
        ActionType.TasksResponse
      >action;
      const taskOverviewPageCount = Math.round(totalResults / take);

      return {
        ...state,
        tasks: results,
        taskOverviewStatus: REQUEST.SUCCESS,
        taskInitialOverviewStatus: REQUEST.SUCCESS,
        taskOverviewPage: 1,
        taskOverviewPageCount,
        tasksStatistics: statistics
      };
    }
    case REDUX.TASK.SET_UPCOMING_TASKS: {
      const { results, resultCount, totalResults, take } = <
        ActionType.TasksResponse
      >action;
      const taskOverviewPageCount = Math.round(totalResults / take);

      return {
        ...state,
        upcomingTasks: results,
        taskWidgetState: REQUEST.SUCCESS,
        totalResultsWidget: totalResults
      };
    }
    case REDUX.TASK.APPEND_TASKS: {
      const { results, totalResults, resultCount, take } = <
        ActionType.TasksResponse
      >action;
      const taskOverviewPageCount = Math.round(totalResults / take);
      const taskOverviewPage = state.taskOverviewPage + 1;

      return {
        ...state,
        tasks: [...state.tasks, ...results],
        taskOverviewStatus: REQUEST.SUCCESS,
        taskOverviewPage,
        taskOverviewPageCount
      };
    }
    case REDUX.TASK.SET_TASK_STATUS: {
      const { status } = <ActionType.Status>action;

      return { ...state, taskStatus: status };
    }
    case REDUX.TASK.SET_TASK_WIDGET_ITEM_STATUS: {
      const { taskId, status } = <ActionType.TaskStatus>action;
      const upcomingTasks = state.upcomingTasks.map((task) => {
        if (task.id === taskId) return { ...task, status };
        return task;
      });

      return { ...state, upcomingTasks };
    }
    case REDUX.TASK.SET_CURRENT_TASK: {
      const { task } = <ActionType.Task>action;
      return {
        ...state,
        task,
        taskStatus: REQUEST.SUCCESS,
        currentTaskStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.ACCOUNT.SET_EMPLOYEE: {
      const { employee } = <ActionType.Employee>action;
      return {
        ...state,
        tasksFilters: { ...state.tasksFilters, employeeFilter: employee.id }
      };
    }
    case REDUX.TASK.UPDATE_TASKS_FILTERS: {
      const { statusFilterMap, employeeFilter, categoryFilter } = <
        ActionType.TasksFilters
      >action;
      let status = null;
      let delegateFilter = TaskDelegateFilter.AssignedToMe;

      switch (statusFilterMap) {
        case TaskFilterStatus.InProgress:
          status = [
            TaskStatus.NotStarted,
            TaskStatus.InProgress,
            TaskStatus.Waiting,
            TaskStatus.Deferred
          ];
          break;
        case TaskFilterStatus.Executed:
          status = [TaskStatus.Completed, TaskStatus.Canceled];
          break;
        case TaskFilterStatus.Delegated:
          delegateFilter = TaskDelegateFilter.Delegated;
          break;
        default:
          status = null;
          break;
      }

      return {
        ...state,
        tasksFilters: {
          ...state.tasksFilters,
          statusFilter: status,
          employeeFilter,
          categoryFilter,
          delegateFilter,
          statusFilterMap
        }
      };
    }
    case REDUX.TASK.SET_CURRENT_TASK_STATUS: {
      const { status } = <ActionType.Status>action;
      return { ...state, currentTaskStatus: status };
    }
    case REDUX.TASK.REMOVE_TASK_FROM_OVERVIEW: {
      const { id } = <ActionType.Identifier>action;
      const tasks = state.tasks.filter((task) => task.id !== id);

      return { ...state, tasks };
    }
    case REDUX.TASK.SET_OVERVIEW_TASK_STATUS: {
      const { taskId, status } = <ActionType.TaskStatus>action;
      const mappedTasks = state.tasks.map((task) => {
        if (task.id === taskId) return { ...task, status };
        return task;
      });
      const tasks = orderBy(
        mappedTasks,
        ["status", "endDate"],
        ["desc", "asc"]
      );

      return { ...state, tasks };
    }
    case REDUX.TASK.UPDATE_LIST_ITEM: {
      const { snapshot } = <ActionType.SnapShot>action;
      const tasks = (state.tasks || []).map((task) => {
        if (task.id === snapshot.id) {
          return snapshot;
        }
        return task;
      });

      return {
        ...state,
        tasks
      };
    }
    case REDUX.TASK.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const tasks = (state.tasks || []).filter((task) => task.id !== id);

      return {
        ...state,
        tasks
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
