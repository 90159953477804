import * as React from "react";
import { CSSProperties, useContext, MouseEvent, useCallback } from "react";
import { FC, memo, useMemo } from "react";
import classNames from "classnames";
import { ListContext } from "../../list.context";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  index: number;
  divide?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const ColumnComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, index, divide, onClick }) => {
      const { columnConfig } = useContext(ListContext);
      const { width, autoFill } = columnConfig.columns[index];

      const onClickHandler = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          if (!onClick) return;
          event.stopPropagation();
          onClick(event);
        },
        [onClick]
      );

      const calculatedStyles = useMemo(() => {
        if (!width) return;

        return {
          width,
          minWidth: width,
        } as CSSProperties;
      }, [width]);

      return (
        <div
          styleName={classNames("column", {
            "auto-fill": autoFill,
            divide,
          })}
          style={calculatedStyles}
          onClick={onClickHandler}
        >
          {children}
        </div>
      );
    }
  )
);

ColumnComponent.displayName = "Column";
