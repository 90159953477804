import { RootEntityType } from "@haywork/api/event-center";
import {
  AssignmentType,
  FinancialAdministration,
  FinancialAdministrationProduct,
  Invoice,
  InvoiceDueTerm,
  InvoiceLine,
  InvoiceStatus,
  InvoiceType,
  ProductOrder,
  RealEstateGroup,
  RelationSnapShot,
  RelationType,
  VatCondition,
  BatchItem,
  BatchItemType,
} from "@haywork/api/kolibri";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import ExternalChanges from "@haywork/components/external-changes";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import Presence from "@haywork/components/ui/presence";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  CONFIG,
  INVOICEROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  REQUEST,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import {
  InvoiceDetailContainerProps,
  InvoiceLineComponent,
  InvoiceLineHeaderComponent,
  InvoicePrintContainer,
  MarketingBudgetModalComponent,
} from "@haywork/modules/invoice";
import { Modal, ModalBody } from "@haywork/modules/modal";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes, { NotesList } from "@haywork/modules/notes-v3";
import {
  AddFinancialAdministrationProductModalContainer,
  ConfirmComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  FormControlUtil,
  RouteUtil,
  ConvertableFile,
  BlobUtil,
} from "@haywork/util";
import * as deepEqual from "deep-equal";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import Actions, { InvoiceAction } from "./actions";
import PrintLoader from "@haywork/components/ui/print-loader";
import isEqual from "lodash-es/isEqual";

export interface InvoiceDetailComponentProps {
  zebra: boolean;
}
interface State {
  addressResult?: google.maps.places.AutocompletePrediction;
  invoiceType: InvoiceType;
  relationType: RelationType;
  invoiceLines: InvoiceLine[];
  productOrders: ProductOrder[];
  showAddProductModal: boolean;
  showMarketingBudgetModal: boolean;
  productRequester: number;
  addedProduct: FinancialAdministrationProduct;
  printVisible: boolean;
  deleteConfirmVivible: boolean;
  archiveConfirmVisible: boolean;
  noCourtageConfirmVisible: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  printConfirmVisible: boolean;
  confirmSave: boolean;
  loadingInvoiceMail: boolean;
  invoiceConfirmVisible: boolean;
}
type Props = InvoiceDetailComponentProps & InvoiceDetailContainerProps;

const styles = require("./invoice-detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

@CSSModules(styles, { allowMultiple: true })
export class InvoiceDetailComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
    this.onOpenMarketingModal = this.onOpenMarketingModal.bind(this);
    this.prepareInvoice = this.prepareInvoice.bind(this);
    this.onFormUpdateHandler = this.onFormUpdateHandler.bind(this);
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.renderVatAndTAO = this.renderVatAndTAO.bind(this);
    this.getTotalPrice = this.getTotalPrice.bind(this);
    this.getTaxRate = this.getTaxRate.bind(this);
    this.addInvoiceLine = this.addInvoiceLine.bind(this);
    this.updateInvoiceLinesWithMarketingLines =
      this.updateInvoiceLinesWithMarketingLines.bind(this);
    this.onRemoveInvoiceLineClickHandler =
      this.onRemoveInvoiceLineClickHandler.bind(this);
    this.updateInvoiceLine = this.updateInvoiceLine.bind(this);
    this.onPhraseAddHandler = this.onPhraseAddHandler.bind(this);
    this.toggleAddProductModal = this.toggleAddProductModal.bind(this);
    this.onProductModalChangeHandler =
      this.onProductModalChangeHandler.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.onArchiveClickHandler = this.onArchiveClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.showInvoiceEmailOptions = this.showInvoiceEmailOptions.bind(this);

    const { invoice, financialAdministration } = this.props;
    const invoiceType = get(
      invoice,
      "invoiceType",
      InvoiceType.BusinessToConsumerInvoice
    );

    this.state = {
      invoiceType,
      relationType:
        invoiceType === InvoiceType.BusinessToBusinessInvoice
          ? RelationType.ContactCompany
          : RelationType.ContactPerson,
      invoiceLines: value(invoice, "lines", []),
      productOrders: this.props.productOrders || [],
      showAddProductModal: false,
      showMarketingBudgetModal: false,
      productRequester: null,
      addedProduct: null,
      printVisible: false,
      deleteConfirmVivible: false,
      archiveConfirmVisible: false,
      noCourtageConfirmVisible: false,
      newEntityVisible: false,
      newEntityOptions: null,
      printConfirmVisible: false,
      confirmSave: false,
      loadingInvoiceMail: false,
      invoiceConfirmVisible: false,
    };

    // Set invoice and due date
    let invoiceDate = has(invoice, "invoiceDate")
      ? moment(invoice.invoiceDate).toDate()
      : new Date();
    let dueDate = has(invoice, "dueDate")
      ? moment(invoice.invoiceDate).toDate()
      : new Date();
    let termOfPayment = InvoiceDueTerm.After14Days;

    if (has(invoice, "invoiceDate") && has(invoice, "dueDate")) {
      const mInvoiceDate = moment(invoice.invoiceDate);
      const mDueDate = moment(invoice.dueDate);
      const diffInDays = mDueDate.diff(mInvoiceDate, "days");
      const term = this.props.invoiceDueTerms.find(
        (term) => term.numberOfDays === diffInDays
      );

      termOfPayment = !!term ? term.value : null;
      dueDate = mDueDate.toDate();
      invoiceDate = mInvoiceDate.toDate();
    }

    // Set form controls
    this.formControls = {
      status: {
        value: value(invoice, "status"),
      },
      invoiceType: {
        value: value(invoice, "invoiceType"),
        onChange: (ref) => {
          const relationType =
            ref.value === InvoiceType.BusinessToConsumerInvoice
              ? RelationType.ContactPerson
              : RelationType.ContactCompany;
          this.setState({ invoiceType: ref.value, relationType });
          return {
            linkedRelation: null,
            customerName: "",
            customerAddress: "",
          };
        },
      },
      invoiceDate: {
        value: invoiceDate,
        onChange: (ref, get) => {
          const termOfPayment = get("termOfPayment");
          const term = this.props.invoiceDueTerms.find(
            (v) => v.value === termOfPayment.value
          );

          if (
            !!term &&
            (term.numberOfDays !== null || term.numberOfMonths !== null) &&
            term.value !== InvoiceDueTerm.TransportDate
          ) {
            const newDueDate = moment(new Date(ref.value));
            newDueDate.add(term.numberOfMonths, "months");
            newDueDate.add(term.numberOfDays, "days");
            const dueDate = newDueDate.toDate();
            return { dueDate };
          }
        },
      },
      dueDate: {
        value: dueDate,
        onChange: (ref, get) => {
          if (!ref.value) return;
          const dueDate = moment(ref.value);
          const invoiceDate = moment(get("invoiceDate").value);
          const diffInDays = dueDate.diff(invoiceDate, "days");

          const term = this.props.invoiceDueTerms.find(
            (v) => v.numberOfDays === diffInDays
          );

          return {
            termOfPayment: term || "",
          };
        },
      },
      termOfPayment: {
        value: termOfPayment || "",
        onChange: (ref, get) => {
          const invoiceDate = get("invoiceDate");
          const term = this.props.invoiceDueTerms.find(
            (v) => v.value === ref.value
          );
          let returnValues = {};

          // Set due date to transport date, if any
          if (!!term && term.value === InvoiceDueTerm.TransportDate) {
            const linkedAssignment = get("linkedAssignment");
            if (!!linkedAssignment.value) {
              return this.props.updateDueDateWithLinkedAssignment(
                linkedAssignment.value
              );
            }
          }

          // Calculate new due date
          if (
            !!term &&
            (term.numberOfDays !== null || term.numberOfMonths !== null)
          ) {
            const newDueDate = moment(new Date(invoiceDate.value));
            newDueDate.add(term.numberOfMonths, "months");
            newDueDate.add(term.numberOfDays, "days");
            const dueDate = newDueDate.toDate();
            returnValues = {
              ...returnValues,
              dueDate,
            };
          }

          if (!!ref.value && ref.value !== InvoiceDueTerm.TransportDate) {
            const dueDateText = this.updateDueDateText(ref.value);
            returnValues = {
              ...returnValues,
              dueDateText,
            };
          }

          if (Object.keys(returnValues).length > 0) {
            return returnValues;
          }
        },
      },
      purpose: { value: value(invoice, "purpose") },
      reference: { value: value(invoice, "reference") },
      linkedAssignment: { value: value(invoice, "linkedAssignment") },
      customerAddress: {
        value: value(invoice, "customerAddress"),
      },
      linkedRelation: {
        value: value(invoice, "linkedRelation"),
        validators: [
          Validators.required(
            undefined,
            () => get(invoice, "status") === InvoiceStatus.Draft
          ),
        ],
        onChange: (ref) => {
          if (!ref.value || ref.value.length === 0) {
            this.props.setFormalName("");
            return {
              customerName: "",
              customerAddress: "",
            };
          }
        },
      },
      tavCustomer: { value: value(invoice, "tavCustomer") },
      taxNumberCustomer: {
        value: value(invoice, "taxNumberCustomer"),
      },
      customerName: { value: value(invoice, "customerName") },
      administration: {
        value: value(financialAdministration, "id"),
        onChange: (ref) => {
          const path = route(INVOICEROUTES.DETAIL.URI, {
            id: get(invoice, "id"),
          });
          this.props.setFinancialAdministration(ref.value, false, path);
        },
      },
      dueDateText: {
        value:
          !!get(invoice, "isNew") && !invoice.dueDateText
            ? this.updateDueDateText(InvoiceDueTerm.After14Days)
            : value(invoice, "dueDateText"),
      },
    };

    this.showPrintOptions = this.showPrintOptions.bind(this);
    this.prepareInvoiceMail = this.prepareInvoiceMail.bind(this);
    this.confirmShowPrintOptions = this.confirmShowPrintOptions.bind(this);
    this.cancelConfirmShowPrintOptions =
      this.cancelConfirmShowPrintOptions.bind(this);
    this.cancelConfirmShowEmailInvoiceOptions =
      this.cancelConfirmShowEmailInvoiceOptions.bind(this);
    this.onPrintCloseHandler = this.onPrintCloseHandler.bind(this);
    this.onCancelDeleteHandler = this.onCancelDeleteHandler.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onCancelArchiveHandler = this.onCancelArchiveHandler.bind(this);
    this.onArchiveHandler = this.onArchiveHandler.bind(this);
    this.onCancelNoCourtageHandler = this.onCancelNoCourtageHandler.bind(this);
    this.onNoCourtageHandler = this.onNoCourtageHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.addCourtageLine = this.addCourtageLine.bind(this);
    this.addDepositLine = this.addDepositLine.bind(this);
    this.addRentPriceLine = this.addRentPriceLine.bind(this);
    this.onCloseMarketingModalHandler =
      this.onCloseMarketingModalHandler.bind(this);
    this.onProductAddedHandler = this.onProductAddedHandler.bind(this);
    this.hideMarketingBudgetModal = this.hideMarketingBudgetModal.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!!this.form && !isEqual(prevProps.invoice, this.props.invoice)) {
      const { invoice, financialAdministration } = this.props;
      const invoiceType = get(
        invoice,
        "invoiceType",
        InvoiceType.BusinessToConsumerInvoice
      );

      this.setState({
        invoiceType,
        relationType:
          invoiceType === InvoiceType.BusinessToBusinessInvoice
            ? RelationType.ContactCompany
            : RelationType.ContactPerson,
        invoiceLines: value(invoice, "lines", []),
        productOrders: this.props.productOrders || [],
      });

      // Set invoice and due date
      let invoiceDate = has(invoice, "invoiceDate")
        ? moment(invoice.invoiceDate).toDate()
        : new Date();
      let dueDate = has(invoice, "dueDate")
        ? moment(invoice.invoiceDate).toDate()
        : new Date();
      let termOfPayment = InvoiceDueTerm.After14Days;

      if (has(invoice, "invoiceDate") && has(invoice, "dueDate")) {
        const mInvoiceDate = moment(invoice.invoiceDate);
        const mDueDate = moment(invoice.dueDate);
        const diffInDays = mDueDate.diff(mInvoiceDate, "days");
        const diffInMonths = mDueDate.diff(mInvoiceDate, "months", true);
        const term = this.props.invoiceDueTerms.find(
          (term) =>
            term.numberOfDays === diffInDays ||
            (!!diffInMonths && term.numberOfMonths === diffInMonths)
        );

        termOfPayment = !!term ? term.value : null;
        dueDate = mDueDate.toDate();
        invoiceDate = mInvoiceDate.toDate();
      }
      // Set form controls

      this.form.update(
        {
          status: value(invoice, "status"),
          invoiceType: value(invoice, "invoiceType"),
          invoiceDate,
          dueDate,
          termOfPayment: termOfPayment || "",
          purpose: value(invoice, "purpose"),
          reference: value(invoice, "reference"),
          linkedAssignment: value(invoice, "linkedAssignment"),
          customerAddress: value(invoice, "customerAddress"),
          linkedRelation: value(invoice, "linkedRelation"),
          tavCustomer: value(invoice, "tavCustomer"),
          taxNumberCustomer: value(invoice, "taxNumberCustomer"),
          customerName: value(invoice, "customerName") || this.props.formalName,
          administration: value(financialAdministration, "id"),
          dueDateText: value(invoice, "dueDateText"),
        },
        true
      );
    }
  }

  public componentDidMount() {
    if (!!this.props.invoice && this.props.invoice.linkedAssignment) {
      this.props.getDataFromAssignment(this.props.invoice.linkedAssignment.id);
    }
    if (!!this.props.invoice && !this.props.invoice.isNew) {
      this.props.getPrintFormats();
      this.props.getPrintTemplates(this.props.invoice.id);
    }

    if (this.props.invoice.financialAdministrationID) {
      this.props.setFinancialAdministration(
        this.props.invoice.financialAdministrationID,
        true
      );
    }
    const fallBackTitle = this.props.invoice.isNew
      ? "newInvoice"
      : "conceptInvoice";
    const title =
      get(this.props.invoice, "displayName") ||
      this.props.intl.formatMessage({
        id: fallBackTitle,
        defaultMessage: fallBackTitle,
      });
    this.props.setTabTitle(title);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: InvoiceDetailContainerProps
  ) {
    if (!nextProps || !nextProps?.invoice) return;

    if (
      !!nextProps.invoice &&
      !!this.props.invoice &&
      nextProps.invoice.id !== this.props.invoice.id &&
      nextProps.invoice.isNew
    ) {
      return;
    }

    if (!this.props.preppedForSave && !!nextProps.preppedForSave) {
      this.props.saveAndCloseInvoice();
    }

    if (
      has(nextProps.invoice, "linkedAssignment.id") &&
      value(nextProps.invoice, "linkedAssignment.id") !==
        value(this.props.invoice, "linkedAssignment.id")
    ) {
      this.props.getDataFromAssignment(
        nextProps.invoice.linkedAssignment.id,
        true
      );
    }

    if (
      has(nextProps.invoice, "linkedRelation.id") &&
      value(nextProps.invoice, "linkedRelation.id") !==
        value(this.props.invoice, "linkedRelation.id")
    ) {
      const { id, typeOfRelation } = nextProps.invoice.linkedRelation;
      this.props.getDataFromRelation(id, typeOfRelation);
    }

    if (nextProps.formalName !== this.props.formalName) {
      this.form.update({
        customerName: nextProps.formalName,
      });
    }

    if (
      get(nextProps.invoice, "dueDate") !== get(this.props.invoice, "dueDate")
    ) {
      this.form.update({
        dueDate: nextProps.invoice.dueDate,
      });
    }

    const { invoice: newInvoice } = nextProps;
    const { invoice: oldInvoice } = this.props;

    if (
      !!newInvoice.linkedRelation &&
      value(newInvoice, "linkedRelation.id") !==
        value(oldInvoice, "linkedRelation.id") &&
      !!this.form
    ) {
      const { linkedRelation } = newInvoice;
      this.form.update({ linkedRelation });
    }

    if (!deepEqual(newInvoice.customerAddress, oldInvoice.customerAddress)) {
      this.form.update({ customerAddress: newInvoice.customerAddress || "" });
    }

    if (
      !deepEqual(nextProps.productOrders, this.props.productOrders) ||
      !deepEqual(nextProps.invoice.lines, this.props.invoice.lines)
    ) {
      const { lines } = nextProps.invoice;

      const productOrders = nextProps.productOrders.filter((product) => {
        const line = lines.find(
          (line) => line.productToPassOnID === product.id
        );
        return !line;
      });
      this.setState({ productOrders });
    }

    if (
      !!nextProps.invoice.lines &&
      nextProps.invoice.lines.length > 0 &&
      !deepEqual(nextProps.invoice.lines, this.props.invoice.lines)
    ) {
      this.props.updatePrices(nextProps.invoice.lines);
    }
  }

  public render() {
    if (!this.props.invoice) return null;
    const { invoice, invoiceStatus } = this.props;
    const loading = invoiceStatus === REQUEST.PENDING;

    return (
      <div styleName="general">
        {/* Notes */}
        <NotesList parentId={invoice.id} />

        {/* Print preview */}
        <InvoicePrintContainer
          invoice={this.props.invoice}
          visible={this.state.printVisible}
          onClose={this.onPrintCloseHandler}
        />

        {/* Delete confirm */}
        <ConfirmComponent
          visible={this.state.deleteConfirmVivible}
          titleResourceKey="invoiceDeleteConfirmTitle"
          bodyResourceKey="invoiceDeleteConfirmBody"
          bodyValues={{ displayName: this.props.invoice.displayName }}
          onClose={this.onCancelDeleteHandler}
          onConfirm={this.onDeleteHandler}
        />

        {/* Archive confirm */}
        <ConfirmComponent
          visible={this.state.archiveConfirmVisible}
          titleResourceKey="invoiceArchiveConfirmTitle"
          bodyResourceKey="invoiceArchiveConfirmBody"
          bodyValues={{ displayName: this.props.invoice.displayName }}
          onClose={this.onCancelArchiveHandler}
          onConfirm={this.onArchiveHandler}
        />

        {/* Missing courtage confirm */}
        <ConfirmComponent
          visible={this.state.noCourtageConfirmVisible}
          titleResourceKey="invoiceNoCourtageConfirmTitle"
          bodyResourceKey={
            !get(this.props.linkedAssignmentSnapShot, "salePrice")
              ? "invoiceNoCourtageConfirmBodyPrice"
              : "invoiceNoCourtageConfirmBodyCourtage"
          }
          bodyValues={{
            displayName: value(
              this.props.invoice,
              "linkedAssignment.displayName"
            ),
          }}
          onClose={this.onCancelNoCourtageHandler}
          onConfirm={this.onNoCourtageHandler}
        />

        {/* Print confirm */}
        <ConfirmComponent
          visible={this.state.printConfirmVisible}
          titleResourceKey="invoicePrintConfirmTitle"
          bodyResourceKey="invoicePrintConfirmBody"
          onClose={this.cancelConfirmShowPrintOptions}
          onConfirm={this.showPrintOptions}
        />

        {/* Invoice Email confirm */}
        <ConfirmComponent
          visible={this.state.invoiceConfirmVisible}
          titleResourceKey="invoiceEmailConfirmTitle"
          bodyResourceKey="invoiceEmailConfirmBody"
          onClose={this.cancelConfirmShowEmailInvoiceOptions}
          onConfirm={this.showInvoiceEmailOptions}
        />

        <PrintLoader visible={this.state.loadingInvoiceMail} type="invoice" />

        <PageHeader
          title="invoice"
          actions={
            <>
              <Presence
                entityId={invoice.id}
                entityType={RootEntityType.Invoice}
              />
              <Notes />
              <Button
                label="saveAndClose"
                category="success"
                disabled={loading}
                onClick={() => this.setState({ confirmSave: true })}
              />
              <Actions
                invoice={invoice}
                loading={loading}
                canMail={!!this.props.currentAccount}
                onClick={this.onActionClick}
              />
            </>
          }
        >
          {!this.props.invoice.isActive && (
            <div styleName="archived-label">
              <I18n value="archived" />
            </div>
          )}
        </PageHeader>

        <ExternalChanges
          entityId={invoice.id}
          entityType={RootEntityType.Invoice}
          onReloadEntity={this.props.reloadInvoice}
        />

        <div styleName="body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <div className="container-fluid">
            <Form
              formControls={this.formControls}
              name={`invoice${this.props.invoice.id}`}
              onSubmit={this.onFormSubmitHandler}
              model={this.props.invoice}
              form={(formRef) => (this.form = formRef)}
              onChange={this.onFormUpdateHandler}
            >
              <div styleName="invoiceSection">
                {/*invoice status*/}
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column column__minwidth">
                      <label htmlFor="status">
                        <I18n value="invoiceStatuses" />
                      </label>
                      <Input.NewSelect
                        name="status"
                        values={this.props.invoiceStatuses}
                        displayProp="displayName"
                        valuesProp="value"
                        disabled={!this.props.invoice.isActive}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div
                      className="column column__minwidth"
                      styleName="invoiceType"
                    >
                      <label htmlFor="invoiceType">
                        <I18n value="type" />
                      </label>
                      <Input.RadioGroup name="invoiceType" asButtonList>
                        {this.props.invoiceTypes.map((type, idx) => (
                          <Input.Radio
                            label={type.displayName}
                            value={type.value}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                    <div className="column__spacer" />
                    <div className="column column__minwidth">
                      <label htmlFor="nummer">
                        <I18n value="nummer" />
                      </label>
                      <div className="form__control" styleName="readOnly">
                        {this.props.invoice.displayName ? (
                          this.props.invoice.displayName
                        ) : (
                          <I18n value="automatic" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/*invoice date, term of payment, expire date*/}
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column column__minwidth">
                      <label htmlFor="invoiceDate">
                        <I18n value="invoiceDate" />
                      </label>
                      <Input.Datepicker
                        name="invoiceDate"
                        disabled={!this.props.invoice.isActive}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column column__minwidth">
                      <label htmlFor="termOfPayment">
                        <I18n value="termOfPayment" />
                      </label>
                      <Input.NewSelect
                        name="termOfPayment"
                        addEmptyOption
                        emptyOptionLabel="other"
                        values={this.props.invoiceDueTerms}
                        displayProp="displayName"
                        valuesProp="value"
                        disabled={!this.props.invoice.isActive}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column column__minwidth">
                      <label htmlFor="dueDate">
                        <I18n value="dueDate" />
                      </label>
                      <Input.Datepicker
                        name="dueDate"
                        disabled={!this.props.invoice.isActive}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    {this.props.financialAdministrations && (
                      <div className="column">
                        <label htmlFor="administration">
                          <I18n value="financialAdministration" />
                        </label>
                        <Input.NewSelect
                          name="administration"
                          values={this.props.financialAdministrations}
                          displayProp="name"
                          valuesProp="id"
                        />
                      </div>
                    )}
                    {this.state.invoiceType ===
                      InvoiceType.BusinessToBusinessInvoice && (
                      <div className="column__spacer" />
                    )}
                    {this.state.invoiceType ===
                      InvoiceType.BusinessToBusinessInvoice && (
                      <div className="column">
                        <label htmlFor="debtorNumber">
                          <I18n value="debtorNumber" />
                        </label>
                        <div
                          id="debtorNumber"
                          className="form__control"
                          styleName="readOnly"
                        >
                          {this.props.invoice.debtorNumber ? (
                            this.props.invoice.debtorNumber
                          ) : (
                            <I18n value="automatic" />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/*Concerns and reference*/}
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="purpose">
                        <I18n value="concerns" />
                      </label>
                      <div className="flex flex-start">
                        <Input.Text
                          name="purpose"
                          placeholder="concernsPlaceholder"
                          hasPhrases
                        />
                        {!!this.props.invoice.isActive && (
                          <Ui.Phrases
                            phraseCategoryId={CONFIG.PURPOSE_PHRASE_ID_INVOICE}
                            onPhraseAdd={(value) =>
                              this.onPhraseAddHandler(value, "purpose")
                            }
                          />
                        )}
                      </div>
                    </div>
                    {this.state.invoiceType ===
                      InvoiceType.BusinessToBusinessInvoice && (
                      <div className="column__spacer" />
                    )}

                    {this.state.invoiceType ===
                      InvoiceType.BusinessToBusinessInvoice && (
                      <div className="column">
                        <label htmlFor="reference">
                          <I18n value="reference" />
                        </label>
                        <Input.Text
                          name="reference"
                          placeholder="referencePlaceholder"
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Linked assignment*/}
                <div className="form__row">
                  <label htmlFor="linkedAssignment">
                    <I18n value="taskDetailViewLinkedAssignmentsLabel" />
                  </label>
                  <Input.AssigmentQuery
                    name="linkedAssignment"
                    placeholder="taskDetailViewLinkedAssignmentsPlaceholder"
                    onNavigateToAssignment={this.props.navigate}
                  />
                </div>
              </div>
              {/* Debtor */}
              <div styleName="invoiceSection">
                <div styleName="subTitle">
                  <I18n value="debtor" />
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column column__minwidth">
                      <label htmlFor="linkedRelation">
                        {this.state.relationType ===
                        RelationType.ContactPerson ? (
                          <I18n value="ContactPerson" />
                        ) : (
                          <I18n value="ContactCompany" />
                        )}
                      </label>
                      <Input.RelationQuery
                        name="linkedRelation"
                        filterByRelationTypes={
                          this.state.relationType === RelationType.ContactPerson
                            ? [RelationType.ContactPerson]
                            : [RelationType.ContactCompany]
                        }
                        multiple={false}
                        onAdd={this.addNewRelation}
                        onNavigateToRelation={this.props.navigate}
                        disabled={!this.props.invoice.isActive}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column column__minwidth">
                      <label htmlFor="customerName">
                        <I18n value="name" />
                      </label>
                      <Input.Text
                        name="customerName"
                        placeholder="invoiceNamePlaceholder"
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <label htmlFor="customerAddress">
                    <I18n value="customerAddress" />
                  </label>
                  <Input.LocationQueryV2
                    name="customerAddress"
                    countries={this.props.countries}
                    countryIso2={this.props.countryIso2}
                    culture={this.props.culture}
                    showManualAddressOnAddress={true}
                  />
                </div>

                {this.state.invoiceType ===
                  InvoiceType.BusinessToBusinessInvoice &&
                  this.renderVatAndTAO()}
              </div>
              <div styleName="invoiceSection invoiceLines">
                <div styleName="subTitle">
                  <I18n value="invoiceLines" />
                </div>
                <div styleName="invoiceLines">
                  <InvoiceLineHeaderComponent />
                  {this.props.financialAdministration &&
                    this.props.invoice.lines.map((line, idx) => (
                      <ErrorBoundary key={`${line.amount}.${idx}`}>
                        <InvoiceLineComponent
                          invoiceLine={line}
                          financialAdministration={
                            this.props.financialAdministration
                          }
                          invoice={this.props.invoice}
                          updateInvoiceLine={this.updateInvoiceLine}
                          removeInvoiceLine={
                            this.onRemoveInvoiceLineClickHandler
                          }
                          idx={idx}
                          addedProduct={
                            idx === this.state.productRequester
                              ? this.state.addedProduct
                              : null
                          }
                          showAddProductModal={() =>
                            this.toggleAddProductModal(idx)
                          }
                        />
                      </ErrorBoundary>
                    ))}
                </div>
                <div styleName="invoiceLinesActions">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.addInvoiceLine}
                  >
                    <I18n value="addInvoiceLine" />
                  </button>
                  {this.props.shouldShowMarketingBudgetButton && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.onOpenMarketingModal}
                    >
                      <I18n value="marketingBudgetInvoice" />
                    </button>
                  )}
                  {this.props.shouldShowCourtageButton && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.addCourtageLine}
                    >
                      <I18n value="invoiceAddCourtageLine" />
                    </button>
                  )}

                  {!!this.props.deposit && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.addDepositLine}
                    >
                      <I18n value="invoiceAddDepositLine" />
                    </button>
                  )}

                  {!!this.props.rentPrice && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.addRentPriceLine}
                    >
                      <I18n value="invoiceAddRentPriceLine" />
                    </button>
                  )}
                </div>
              </div>
              <div styleName="invoiceSection invoiceFooter">
                <div styleName="notes">
                  <div className="form__row">
                    <label htmlFor="dueDateText">
                      <I18n value="dueDateText" />
                    </label>
                    <div className="flex flex-start">
                      <Input.Textarea
                        name="dueDateText"
                        placeholder="invoiceDueDateTextPlaceholder"
                        minHeight={78}
                        autoSize
                        disabled={!this.props.invoice.isActive}
                        maxLength={1024}
                        hasPhrases
                      />
                      {!!this.props.invoice.isActive && (
                        <Ui.Phrases
                          phraseCategoryId={CONFIG.REMARKS_PHRASE_ID_INVOICE}
                          onPhraseAdd={(value) =>
                            this.onPhraseAddHandler(value, "dueDateText")
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div styleName="invoiceTotals">
                  <div>
                    <span styleName="title">
                      <I18n value="invoiceTotalAmountEx" />
                    </span>
                    <span styleName="value">
                      <span styleName="currency">
                        {this.props.invoice.currency
                          ? this.props.invoice.currency
                          : "€"}
                      </span>
                      <span styleName="number">
                        <FormattedNumber
                          value={this.props.invoiceTotalNet}
                          style="decimal"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </span>
                  </div>
                  <div>
                    <span styleName="title">
                      <I18n value="invoiceTax" />
                    </span>
                    <span styleName="value">
                      <span styleName="currency">
                        {this.props.invoice.currency
                          ? this.props.invoice.currency
                          : "€"}
                      </span>
                      <span styleName="number">
                        <FormattedNumber
                          value={this.props.invoiceTotalTax}
                          style="decimal"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </span>
                  </div>
                  <div>
                    <span styleName="title">
                      <I18n value="invoiceTotalAmountInc" />
                    </span>
                    <span styleName="value">
                      <span styleName="currency">
                        {this.props.invoice.currency
                          ? this.props.invoice.currency
                          : "€"}
                      </span>
                      <span styleName="number">
                        <FormattedNumber
                          value={this.props.invoiceTotalGross}
                          style="decimal"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              {/* Add new product modal */}
              <AddFinancialAdministrationProductModalContainer
                visible={this.state.showAddProductModal}
                onClose={this.onCloseMarketingModalHandler}
                onProductAdded={this.onProductAddedHandler}
              />

              <Modal
                visible={this.state.showMarketingBudgetModal}
                onClose={this.hideMarketingBudgetModal}
              >
                <ModalBody noPadding>
                  <MarketingBudgetModalComponent
                    productOrders={this.state.productOrders}
                    onClose={this.hideMarketingBudgetModal}
                    onChange={this.updateInvoiceLinesWithMarketingLines}
                    financialAdministration={this.props.financialAdministration}
                    invoice={this.props.invoice}
                  />
                </ModalBody>
              </Modal>
            </Form>
          </div>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.Invoice}
          entityId={invoice.id}
          saving={this.state.confirmSave}
          onCancel={() => this.setState({ confirmSave: false })}
          onConfirm={this.onSaveClickHandler}
        />
      </div>
    );
  }

  private onDeleteClickHandler() {
    this.setState({
      deleteConfirmVivible: true,
    });
  }

  private onArchiveClickHandler() {
    if (!this.props.invoice.isActive) {
      return this.props.unArchiveInvoice(this.props.invoice.id, true);
    }

    this.setState({
      archiveConfirmVisible: true,
    });
  }

  private onCancelDeleteHandler() {
    this.setState({
      deleteConfirmVivible: false,
    });
  }

  private onDeleteHandler() {
    this.setState({
      deleteConfirmVivible: false,
    });

    this.props.deleteInvoice(this.props.invoice.id, true);
  }

  private onCancelArchiveHandler() {
    this.setState({
      archiveConfirmVisible: false,
    });
  }

  private onArchiveHandler() {
    this.setState({
      archiveConfirmVisible: false,
    });

    this.props.archiveInvoice(this.props.invoice, true);
  }

  private onCancelNoCourtageHandler() {
    this.setState({
      noCourtageConfirmVisible: false,
    });
  }

  private onNoCourtageHandler() {
    this.setState({
      noCourtageConfirmVisible: false,
    });

    const { id, typeOfAssignment, salePrice } =
      this.props.linkedAssignmentSnapShot;

    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.AcquisitionObject: {
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.ObjectType: {
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.Project: {
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      }
      default: {
        const uri = !salePrice
          ? ASSIGNMENTROUTES.DETAIL.URI
          : ASSIGNMENTROUTES.EDIT_FINANCIAL.URI;
        path = route(uri, { id });
        break;
      }
    }

    this.props.navigate(path);
  }

  private onPhraseAddHandler(phrase: string, fieldName: string) {
    if (!this.form) return;
    const { [fieldName]: oldValue } = this.form.getValues();
    const value = `${oldValue} ${phrase}`.trim();
    this.form.update({ [fieldName]: value });
  }

  private renderVatAndTAO() {
    return (
      <div className="form__row">
        <div className="form__group stretch">
          <div className="column column_minwidth">
            <label htmlFor="tavCustomer">
              <I18n value="toTheAttentionOf" />
            </label>
            <Input.Text
              name="tavCustomer"
              placeholder="toTheAttentionOfPlaceholder"
            />
          </div>

          <div className="column__spacer" />

          <div className="column column__minwidth">
            <label htmlFor="taxNumberCustomer">
              <I18n value="taxNumberCustomer" />
            </label>
            <Input.Text
              name="taxNumberCustomer"
              placeholder="taxNumberCustomerPlaceholder"
              maxLength={24}
            />
          </div>
        </div>
      </div>
    );
  }

  private onFormSubmitHandler(values: FormReturnValue) {
    const updatedInvoice = this.prepareInvoice(values);
    this.props.saveInvoice(updatedInvoice, true);
  }

  private onFormUpdateHandler(values: FormReturnValue) {
    const updatedInvoice = this.prepareInvoice(values);
    const path = route(INVOICEROUTES.DETAIL.URI, { id: this.props.invoice.id });
    this.props.updateInvoice(updatedInvoice, path);
  }

  private prepareInvoice(values: FormReturnValue): Invoice {
    const { invoice } = this.props;

    let linkedRelation = values.linkedRelation
      ? values.linkedRelation[0] || values.linkedRelation
      : null;
    if (linkedRelation && linkedRelation.length === 0) {
      linkedRelation = null;
    }

    const {
      status,
      invoiceType,
      invoiceDate,
      dueDate,
      purpose,
      customerName,
      reference,
      dueDateText,
      tavCustomer,
      taxNumberCustomer,
      customerAddress,
      linkedAssignment,
    } = values;

    const updatedInvoice: Invoice = {
      ...invoice,
      status,
      invoiceType,
      invoiceDate,
      dueDate,
      purpose,
      dueDateText,
      linkedAssignment,
      customerName,
      reference,
      linkedRelation,
      tavCustomer,
      taxNumberCustomer,
      customerAddress,
    };

    return updatedInvoice;
  }

  private addInvoiceLine() {
    const { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });

    const newInvoiceLine: InvoiceLine = {
      amount: 0,
      taxPercentage: 0,
      totalPriceGross: 0,
      totalPriceNet: 0,
      totalTax: 0,
      unitPriceGross: 0,
      unitPriceNet: 0,
      unitTax: 0,
    };

    const newState = {
      ...invoice,
      lines: [...invoice.lines, newInvoiceLine],
    };

    this.props.updateInvoice(newState, path);
  }

  private updateInvoiceLine(invoiceLine: InvoiceLine, index: number) {
    const { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });
    const lines = invoice.lines.map((line, idx) => {
      if (idx === index) {
        return invoiceLine;
      }
      return line;
    });

    const { totalPriceGross, totalPriceNet, totalTax } = lines.reduce(
      (state, line) => {
        state.totalPriceGross += line.totalPriceGross;
        state.totalPriceNet += line.unitPriceNet * line.amount;
        state.totalTax +=
          line.totalPriceGross - line.unitPriceNet * line.amount;

        return state;
      },
      { totalPriceGross: 0, totalPriceNet: 0, totalTax: 0 }
    );

    const updatedInvoice: Invoice = {
      ...invoice,
      totalPriceGross,
      totalPriceNet,
      totalTax,
      lines,
    };

    this.props.updateInvoice(updatedInvoice, path);
  }

  private getTotalPrice(price: number, taxRate: number, amount: number) {
    return price * amount + price * amount * (taxRate / 100);
  }

  private getTaxRate(id: number): number {
    const { taxRates } = this.props.financialAdministration;
    const taxRate = taxRates.find((r) => r.id === id);
    return taxRate.taxPercentage;
  }

  private toggleAddProductModal(idx: number) {
    this.setState({
      showAddProductModal: !this.state.showAddProductModal,
      productRequester: idx,
    });
  }

  private hideMarketingBudgetModal() {
    this.setState({ showMarketingBudgetModal: false });
  }

  private onProductModalChangeHandler(
    financialAdministration: FinancialAdministration
  ) {
    this.props.updateFinancialAdministration(financialAdministration);
  }

  private updateInvoiceLinesWithMarketingLines(newProducts: ProductOrder[]) {
    const { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });

    const newInvoiceLines: InvoiceLine[] = newProducts.map((productOrder) => {
      const invoiceLine: InvoiceLine = {
        amount: 1,
        description: productOrder.description,
        exportLedgerAccountCodeProduct: null,
        exportLedgerAccountCodeTax: null,
        exportProductCode: null,
        exportTexCode: null,
        productID: productOrder.financialAdministrationProductId,
        taxPercentage: null,
        totalPriceGross: productOrder.unitPriceNet || 0,
        taxRateID: null,
        totalPriceNet: productOrder.unitPriceNet || 0,
        totalTax: null,
        unitPriceGross: null,
        unitPriceNet: productOrder.unitPriceNet || 0,
        unitTax: 0,
        productToPassOnID: productOrder.id,
      };
      return invoiceLine;
    });

    const mappednewInvoiceLines: InvoiceLine[] = newInvoiceLines.map((line) => {
      const financialAdministrationProduct =
        this.props.financialAdministration.products.find(
          (p) => p.id === line.productID
        );
      const taxPercentage = financialAdministrationProduct.taxRateId
        ? this.getTaxRate(financialAdministrationProduct.taxRateId)
        : this.getTaxRate(this.props.financialAdministration.taxRates[0].id);
      const totalPriceGross = this.getTotalPrice(
        line.unitPriceNet,
        taxPercentage,
        1
      );

      return {
        ...line,
        taxPercentage,
        totalPriceGross,
      };
    });

    const newState = {
      ...invoice,
      lines: [...invoice.lines, ...mappednewInvoiceLines],
    };

    this.props.updateInvoice(newState, path);
    this.setState({ showMarketingBudgetModal: false });
  }

  private onSaveClickHandler(close: boolean = true) {
    this.props.saveInvoice(this.props.invoice, close);
    this.setState({ confirmSave: false });
  }

  private onCloseMarketingModalHandler() {
    this.setState({ showAddProductModal: false });
  }

  private confirmShowPrintOptions() {
    const { isNew } = this.props.invoice;

    if (isNew) {
      this.setState({
        printConfirmVisible: true,
      });
    } else {
      this.showPrintOptions();
    }
  }

  private cancelConfirmShowPrintOptions() {
    this.setState({
      printConfirmVisible: false,
    });
  }

  private async showPrintOptions() {
    this.setState({ printConfirmVisible: false });
    try {
      await this.props.saveInvoice(this.props.invoice, false);
      this.setState({ printVisible: true });
    } catch (error) {
      throw error;
    }
  }

  private onPrintCloseHandler() {
    this.setState({ printVisible: false });
  }

  private confirmShowEmailInvoiceOptions() {
    const { isNew } = this.props.invoice;

    if (isNew) {
      this.setState({
        invoiceConfirmVisible: true,
      });
    } else {
      this.showInvoiceEmailOptions();
    }
  }

  private cancelConfirmShowEmailInvoiceOptions() {
    this.setState({
      invoiceConfirmVisible: false,
    });
  }

  private async showInvoiceEmailOptions() {
    this.setState({ invoiceConfirmVisible: false });
    try {
      await this.props.saveInvoice(this.props.invoice, false);
      this.prepareInvoiceMail(this.props.invoice);
    } catch (error) {
      throw error;
    }
  }

  private onProductAddedHandler(product: FinancialAdministrationProduct) {
    this.setState({
      showAddProductModal: false,
      addedProduct: product,
    });
  }

  private onOpenMarketingModal() {
    this.setState({ showMarketingBudgetModal: true });
  }

  private addCourtageLine() {
    if (!this.props.canAddCourtage) {
      this.setState({
        noCourtageConfirmVisible: true,
      });
      return;
    }

    let { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });
    const exPrice =
      this.props.realEstateGroup === RealEstateGroup.Residential
        ? this.props.courtage / 1.21
        : this.props.courtage;

    const incPrice = exPrice * 1.21;
    const tax = incPrice - exPrice;

    const line: InvoiceLine = {
      productID: -1,
      description: this.props.intl.formatMessage({
        id: "commission",
        defaultMessage: "commission",
      }),
      amount: 1,
      unitPriceNet: exPrice,
      totalPriceNet: exPrice,
      unitPriceGross: incPrice,
      totalPriceGross: incPrice,
      taxPercentage: 21,
      totalTax: tax,
      unitTax: tax,
    };

    invoice = {
      ...invoice,
      lines: [...invoice.lines, line],
    };

    this.props.updateInvoice(invoice, path);
  }

  private addDepositLine() {
    let { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });

    const line: InvoiceLine = {
      productID: (this.props.invoice.lines || []).length + 1,
      description: this.props.intl.formatMessage({
        id: "deposit",
        defaultMessage: "deposit",
      }),
      amount: 1,
      unitPriceNet: this.props.deposit,
      totalPriceNet: this.props.deposit,
      unitPriceGross: this.props.deposit,
      totalPriceGross: this.props.deposit,
      taxPercentage: 0,
      totalTax: 0,
      unitTax: 0,
    };

    invoice = {
      ...invoice,
      lines: [...invoice.lines, line],
    };

    this.props.updateInvoice(invoice, path);
  }

  private addRentPriceLine() {
    let { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });
    const exPrice =
      this.props.realEstateGroup === RealEstateGroup.Residential
        ? this.props.rentPrice
        : this.props.vatCondition === VatCondition.Included
        ? this.props.rentPrice / 1.21
        : this.props.rentPrice * 1.21;

    const incPrice =
      this.props.realEstateGroup === RealEstateGroup.Residential
        ? this.props.rentPrice
        : exPrice * 1.21;
    const tax = incPrice - exPrice;

    const line: InvoiceLine = {
      productID: (this.props.invoice.lines || []).length + 1,
      description: this.props.intl.formatMessage({
        id: "rentPrice",
        defaultMessage: "rentPrice",
      }),
      amount: 1,
      unitPriceNet: exPrice,
      totalPriceNet: exPrice,
      unitPriceGross: incPrice,
      totalPriceGross: incPrice,
      taxPercentage:
        this.props.realEstateGroup === RealEstateGroup.Residential ? 0 : 21,
      totalTax: tax,
      unitTax: tax,
    };

    invoice = {
      ...invoice,
      lines: [...invoice.lines, line],
    };

    this.props.updateInvoice(invoice, path);
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    const { invoiceType } = this.form.getValues();
    let singleFormType = RelationType.ContactPerson;

    switch (invoiceType) {
      case InvoiceType.BusinessToBusinessInvoice:
        singleFormType = RelationType.ContactCompany;
        break;
      default:
        break;
    }

    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
          singleFormType,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedRelation: RelationSnapShot) {
    this.form.update({
      linkedRelation,
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private onRemoveInvoiceLineClickHandler(id: number) {
    const { invoice } = this.props;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });
    const lines = invoice.lines.filter((line, idx) => idx !== id);

    const updatedInvoice: Invoice = {
      ...invoice,
      lines,
    };

    this.props.updateInvoice(updatedInvoice, path);
  }

  private updateDueDateText(term: InvoiceDueTerm): string {
    const dueDate = this.props.intl.formatMessage({
      id: `invoiceDueTerms.${term}`,
      defaultMessage: `invoiceDueTerms.${term}`,
    });
    const dueDateText = this.props.intl.formatMessage(
      {
        id: "dueDateTextInvoice",
        defaultMessage: "dueDateTextInvoice",
      },
      { dueDate }
    );

    return dueDateText;
  }

  private async prepareInvoiceMail(invoice: Invoice) {
    this.setState({ loadingInvoiceMail: true });

    try {
      const file: BatchItem = {
        id: invoice.id,
        type: BatchItemType.Invoice,
      };
      const items = await this.props.createBatchDownload([file]);
      if (!items) return;
      const attachments = await this.props.uploadFilesFromUri(
        this.props.currentAccount,
        items
      );
      if (!attachments) return;
      this.props.createNewEmailWithAttachments(
        attachments,
        this.props.invoice.linkedRelation
          ? [this.props.invoice.linkedRelation]
          : undefined,
        this.props.invoice.displayName
      );
    } finally {
      this.setState({ loadingInvoiceMail: false });
    }
  }

  private onActionClick(action: InvoiceAction) {
    switch (action) {
      case InvoiceAction.Archive:
      case InvoiceAction.UnArchive: {
        this.onArchiveClickHandler();
        break;
      }
      case InvoiceAction.Remove: {
        this.onDeleteClickHandler();
        break;
      }
      case InvoiceAction.Print: {
        this.confirmShowPrintOptions();
        break;
      }
      case InvoiceAction.Email: {
        this.confirmShowEmailInvoiceOptions();
        break;
      }
      default: {
        break;
      }
    }
  }
}
