import { Task, TaskSnapShot, ExpirationMoment } from "@haywork/api/kolibri";
import * as moment from "moment";

export const mapTaskToTaskSnapShot = (task: Task): TaskSnapShot => {
  const {
    id,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    categoryId,
    completedPercentage,
    endDate,
    linkedAssignments,
    linkedEmployee,
    linkedRelations,
    priority,
    startDate,
    status,
    subject,
  } = task;

  let expirationMoment = ExpirationMoment.NoEndDate;
  if (!!endDate && moment(endDate).isValid()) {
    const now = moment().startOf("day");
    const end = moment(endDate).startOf("day");
    const nextWeek = now.clone().add(1, "week");

    switch (true) {
      case now.isBefore(end): {
        expirationMoment = ExpirationMoment.Expired;
        break;
      }
      case now.isSame(end, "week"): {
        expirationMoment = ExpirationMoment.ThisWeek;
        break;
      }
      case nextWeek.isSame(end, "week"): {
        expirationMoment = ExpirationMoment.NextWeek;
        break;
      }
      case end.isAfter(nextWeek, "week"): {
        expirationMoment = ExpirationMoment.AfterNextWeek;
        break;
      }
      default: {
        break;
      }
    }
  }

  return {
    id,
    categoryId,
    completedPercentage,
    endDate,
    expirationMoment,
    isActive,
    linkedAssignments,
    linkedCreatedBy,
    linkedEmployee,
    linkedModifiedBy,
    linkedRelations,
    priority,
    realEstateAgencyId,
    startDate,
    status,
    subject,
  };
};
