import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import {
  AssignmentSnapShot,
  RealEstateGroup,
  AssignmentType,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";
import { FontStyle } from "@haywork/modules/shared/components/resource-text/resource-text.component";
import { FormattedNumber } from "react-intl";

const styles = require("./buildnumber-overview.component.scss");
const enumToString = StringUtil.enumToString;

interface Props {
  buildnumber: AssignmentSnapShot;
  zebra: boolean;
  onClick: (buildnumber: AssignmentSnapShot) => void;
}

export const BuildnumberItem = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const {
      constructionNumberString,
      assignmentPhase,
      realEstateGroup,
      forSale,
      listingType,
      salePrice,
      rentPrice,
      saleCondition,
      saleCommissionTotal,
      rentCommissionTotal,
      typeOfAssignment,
      isActive,
      availabilityStatus,
      displayName,
      linkedObjectTypeAssignment,
    } = props.buildnumber;
    const rentResourceKey =
      realEstateGroup === RealEstateGroup.Agricultural ? "forLease" : "forRent";
    const price = forSale ? salePrice : rentPrice;
    const commission = forSale ? saleCommissionTotal : rentCommissionTotal;

    return (
      <div
        onClick={() => props.onClick(props.buildnumber)}
        styleName={classNames("item", { zebra: props.zebra })}
        data-cy={props["data-cy"]}
      >
        <div styleName="item__header">
          <div styleName={classNames("build-no", { archived: !isActive })}>
            <i className="fa fa-home" />
            {constructionNumberString}
          </div>
          {/* TODO: disabled for now assignmentPhase === AssignmentPhase.Concept &&
          <div styleName="pub-ready">
            <ResourceText resourceKey="readyForPublication" />
          </div>
        */}
        </div>
        <div styleName="item__body">
          <div styleName="column">
            {!!linkedObjectTypeAssignment && (
              <div styleName="linked-object-type">
                <ResourceText
                  resourceKey="linkedObjectType"
                  values={{
                    displayName: linkedObjectTypeAssignment.displayName,
                  }}
                />
              </div>
            )}
            <div styleName="realestate-group">
              <ResourceText
                masterKey="realEstateGroups"
                resourceKey={enumToString(realEstateGroup)}
              />
              ,{" "}
              {forSale ? (
                <ResourceText
                  resourceKey="forSale"
                  fontStyle={FontStyle.UcFirst}
                />
              ) : (
                <ResourceText
                  resourceKey={rentResourceKey}
                  fontStyle={FontStyle.UcFirst}
                />
              )}
            </div>
            <div styleName="name">{displayName}</div>
            <div styleName="listing-type">
              <ResourceText
                masterKey="listingTypes"
                resourceKey={enumToString(
                  typeOfAssignment === AssignmentType.ObjectType
                    ? typeOfAssignment
                    : listingType
                )}
              />
            </div>
          </div>
          <div styleName="column">
            <div styleName="price">
              <div styleName="price__label">
                <ResourceText resourceKey="askingPrice" />
              </div>
              <div styleName="price__value">
                {!!price && price !== 0 ? (
                  <FormattedNumber
                    value={price}
                    style="currency"
                    currency="EUR"
                  />
                ) : (
                  <ResourceText
                    resourceKey="none"
                    fontStyle={FontStyle.LowerCase}
                  />
                )}
                &nbsp;
                {!!forSale && !!saleCondition && (
                  <ResourceText
                    masterKey="saleConditions"
                    resourceKey={enumToString(saleCondition)}
                  />
                )}
              </div>
            </div>
            <div styleName="price">
              <div styleName="price__label">
                <ResourceText resourceKey="courtage" />
              </div>
              <div styleName="price__value">
                {!!commission && commission !== 0 ? (
                  <FormattedNumber
                    value={commission}
                    style="currency"
                    currency="EUR"
                  />
                ) : (
                  <ResourceText
                    resourceKey="none"
                    fontStyle={FontStyle.LowerCase}
                  />
                )}
              </div>
            </div>
          </div>
          <div styleName="column last">
            {!!isActive ? (
              <div styleName="status">
                <div styleName="status__phase">
                  <ResourceText
                    masterKey="assignmentPhases"
                    resourceKey={enumToString(assignmentPhase)}
                  />
                </div>
                <div styleName="status__availability">
                  <ResourceText
                    masterKey="availabilityStatuses"
                    resourceKey={enumToString(availabilityStatus)}
                  />
                </div>
              </div>
            ) : (
              <div styleName="status">
                <div styleName="status__phase">
                  <ResourceText resourceKey="archived" />
                </div>
                <div styleName="status__availability">
                  <ResourceText
                    masterKey="availabilityStatuses"
                    resourceKey={enumToString(availabilityStatus)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
