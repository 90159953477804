import isArray from "lodash-es/isArray";
import escapeRegExp from "lodash-es/escapeRegExp";

export class Route {

  public static mapStaticRouteValues(route: string, values: any, hash?: string): string {
    let returnValue = route;
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const match = new RegExp(`:${escapeRegExp(key)}`, "g");
        returnValue = returnValue.replace(match, values[key]);
      }
    }
    if (hash) returnValue = `${ returnValue }#${ hash }`;
    return returnValue;
  }

  public static mapObjectToGetParams(props: { [key: string]: any }): string {
    const propArray = [];

    for (const key in props) {
      const value = props[key];
      if (["", null, undefined].indexOf(value) === -1) {
        if (isArray(value)) {
          value.map((v) => {
            propArray.push(`${ key }=${ JSON.stringify(v) }`);
          });
        } else {
          propArray.push(`${ key }=${ JSON.stringify(props[key]) }`);
        }
      }
    }

    const rawParamString = propArray.join("&");
    const exp = new RegExp(/"/, "g");

    return rawParamString.replace(exp, "");
  }
}
