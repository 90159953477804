import { WidgetEntityType } from "@haywork/api/authorization";
import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { PinnedNotes } from "@haywork/modules/notes-v3";
import { ObjectTypesDetailGeneralContainerProps } from "@haywork/modules/object-types";
import { FontStyle, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, FormattedNumber } from "react-intl";
import { ObjectTypesDetailGeneralWidgetsStatusComponent } from "./widgets/status.component";

const styles = require("./detail-general.component.scss");

export interface ObjectTypesDetailGeneralComponentProps {}
interface State {}
type Props = ObjectTypesDetailGeneralComponentProps &
  ObjectTypesDetailGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesDetailGeneralComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.hideOnFundaChanged = this.hideOnFundaChanged.bind(this);
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.currentType.id,
      WidgetEntityType.ObjectTypeAssignment
    );
  }

  public render() {
    const {
      currentType,
      buildingStartText,
      deliveryStartText,
      saleStartText,
      priceRange,
      livingRoomRange,
      usableAreaRange,
      parcelRange,
      contentsRange,
      displayName,
    } = this.props;
    const {
      buildingTypeStart,
      deliveryStart,
      saleStart,
      forSale,
      amountUnits,
      amountFreeUnits,
    } = currentType;

    return (
      <div styleName="general">
        <h2>
          {forSale ? (
            <ResourceText resourceKey="forSale" fontStyle={FontStyle.UcFirst} />
          ) : (
            <ResourceText resourceKey="forRent" fontStyle={FontStyle.UcFirst} />
          )}
        </h2>
        {!!this.props.projectName && (
          <h2 styleName="project-name">{this.props.projectName}</h2>
        )}
        <h1>
          {displayName}{" "}
          {!this.props.currentType.isActive && (
            <span styleName="archived">
              <ResourceText resourceKey="archived" />
            </span>
          )}
        </h1>
        <h3>{this.renderHouseInfo()}</h3>

        <div styleName="build-numbers">
          <div styleName="row">
            <div styleName="section">
              <i className="fa fa-home" />
              <ResourceText
                resourceKey="projectTypesDetailUnitsAmount"
                values={{ count: amountUnits || 0 }}
              />
            </div>
            <div styleName="section">
              <i className="fa fa-sign" />
              <ResourceText
                resourceKey="projectTypesDetailFreeUnitsAmount"
                values={{ count: amountFreeUnits || 0 }}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.props.toggleAddBuildnumbers(true)}
            >
              <ResourceText resourceKey="projectTypesDetailAddBuildNumbers" />
            </button>
          </div>
          <PinnedNotes />
        </div>

        <ErrorBoundary>
          <ObjectTypesDetailGeneralWidgetsStatusComponent
            objectType={this.props.currentType}
            onHideOnFundaChange={this.hideOnFundaChanged}
          />
        </ErrorBoundary>
        {(!!priceRange ||
          !!livingRoomRange ||
          !!parcelRange ||
          !!contentsRange) && (
          <div styleName="widget">
            <div styleName="title">
              <ResourceText resourceKey="projectTypesDetailNumbersTitle" />
            </div>
            {!!priceRange && (
              <div styleName="row">
                <div styleName="column">
                  <h3>
                    <ResourceText
                      resourceKey={
                        !!forSale
                          ? "projectTypesDetailSalePrice"
                          : "projectTypesDetailRentPrice"
                      }
                    />
                  </h3>
                  <div styleName="range" data-cy="CY-salePriceRange">
                    {this.renderPriceRange(priceRange)}
                  </div>
                </div>
              </div>
            )}

            {(!!livingRoomRange ||
              !!parcelRange ||
              !!contentsRange ||
              !!usableAreaRange) && (
              <div styleName="row">
                {!!usableAreaRange && (
                  <div styleName="column">
                    <h3>
                      <ResourceText resourceKey="projectTypesDetailUseableAreaRange" />
                    </h3>
                    <div styleName="range" data-cy="usableAreaRange">
                      {this.renderSizeRange(usableAreaRange, "squareMeters")}
                    </div>
                  </div>
                )}
                {!!livingRoomRange && (
                  <div styleName="column">
                    <h3>
                      <ResourceText resourceKey="projectTypesDetailLivingRoomRange" />
                    </h3>
                    <div styleName="range" data-cy="livingRoomRange">
                      {this.renderSizeRange(livingRoomRange, "squareMeters")}
                    </div>
                  </div>
                )}
                {!!parcelRange && (
                  <div styleName="column">
                    <h3>
                      <ResourceText resourceKey="projectTypesDetailParcelRange" />
                    </h3>
                    <div styleName="range" data-cy="parcelRange">
                      {this.renderSizeRange(parcelRange, "squareMeters")}
                    </div>
                  </div>
                )}
                {!!contentsRange && (
                  <div styleName="column">
                    <h3>
                      <ResourceText resourceKey="projectTypesDetailContentsRange" />
                    </h3>
                    <div styleName="range" data-cy="contentsRange">
                      {this.renderSizeRange(contentsRange, "cubicMeters")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div styleName="widget">
          <div styleName="title">
            <ResourceText resourceKey="projectTypesDetailDatesTitle" />
          </div>
          {(!!buildingStartText ||
            !!buildingTypeStart ||
            !!deliveryStartText ||
            !!deliveryStart) && (
            <div styleName="row">
              {(!!buildingStartText || !!buildingTypeStart) && (
                <div styleName="column">
                  <h3>
                    <ResourceText resourceKey="projectTypesDetailStartBuild" />
                  </h3>
                  <div styleName="range">
                    {this.renderDateText(buildingStartText, buildingTypeStart)}
                  </div>
                </div>
              )}
              {(!!deliveryStartText || !!deliveryStart) && (
                <div styleName="column">
                  <h3>
                    <ResourceText resourceKey="projectTypesDetailStartDelivery" />
                  </h3>
                  <div styleName="range">
                    {this.renderDateText(deliveryStartText, deliveryStart)}
                  </div>
                </div>
              )}
            </div>
          )}
          {(!!saleStart || !!saleStartText) && (
            <div styleName="row">
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailStartSale" />
                </h3>
                <div styleName="range">
                  {this.renderDateText(saleStartText, saleStart)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderHouseInfo(): string {
    const {
      typePART,
      houseSort,
      houseType,
      houseCharacteristic,
    } = this.props.currentType;
    const typePARTValue = !!typePART
      ? this.props.intl.formatMessage({
          id: `typePARTOptions.${typePART.toString()}`,
          defaultMessage: `typePARTOptions.${typePART.toString()}`,
        })
      : null;
    const houseSortValue = !!houseSort
      ? this.props.intl.formatMessage({
          id: `houseSorts.${houseSort.toString()}`,
          defaultMessage: `houseSorts.${houseSort.toString()}`,
        })
      : null;
    const houseTypeValue = !!houseType
      ? this.props.intl.formatMessage({
          id: `houseTypes.${houseType.toString()}`,
          defaultMessage: `houseTypes.${houseType.toString()}`,
        })
      : null;
    const houseCharacteristicValue = !!houseCharacteristic
      ? this.props.intl.formatMessage({
          id: `houseCharacteristics.${houseCharacteristic.toString()}`,
          defaultMessage: `houseCharacteristics.${houseCharacteristic.toString()}`,
        })
      : null;
    const houseInfo = [
      typePARTValue,
      houseSortValue,
      houseTypeValue,
      houseCharacteristicValue,
    ];

    return houseInfo.filter((value) => !!value).join(", ");
  }

  private hideOnFundaChanged(hideOnFundaUntil: Date) {
    const { currentType } = this.props;

    const updatedObjectTypeAssignment: ObjectTypeAssignment = {
      ...currentType,
      hideOnFundaUntil,
    };

    this.props.saveObjectType(updatedObjectTypeAssignment);
  }

  private renderPriceRange(range: NumberRange) {
    const fragments = [];
    let idx = 0;

    if (!!range.min) {
      fragments.push(
        <FormattedNumber
          value={range.min}
          style="currency"
          currency="EUR"
          key={idx}
        />
      );
      idx++;
    }

    if (!!range.max) {
      if (!range.min) {
        fragments.push(<ResourceText resourceKey="rangeMaximum" key={idx} />);
      } else {
        fragments.push(<ResourceText resourceKey="rangeUntill" key={idx} />);
      }
      idx++;

      fragments.push(
        <FormattedNumber
          value={range.max}
          style="currency"
          currency="EUR"
          key={idx}
        />
      );
    }

    return <React.Fragment children={fragments} />;
  }

  private renderDateText(text: string, date: Date) {
    const fragments = [];
    let idx = 0;

    if (!!text) {
      fragments.push(<span key={idx}>{text}</span>);
      idx++;
    }

    if (!!date) {
      if (!!text) {
        fragments.push(<span key={idx}>|</span>);
        idx++;
      }

      fragments.push(
        <FormattedDate
          value={date}
          day="2-digit"
          month="long"
          year="numeric"
          key={idx}
        />
      );
    }

    return <React.Fragment children={fragments} />;
  }

  private renderSizeRange(range: NumberRange, postfix: string) {
    const fragments = [];
    let idx = 0;

    if (!!range.min) {
      fragments.push(
        <React.Fragment key={idx}>
          {range.min}
          <ResourceText resourceKey={postfix} asHtml />
        </React.Fragment>
      );
      idx++;
    }

    if (!!range.max) {
      if (!range.min) {
        fragments.push(<ResourceText resourceKey="rangeMaximum" key={idx} />);
      } else {
        fragments.push(<ResourceText resourceKey="rangeUntill" key={idx} />);
      }
      idx++;

      fragments.push(
        <React.Fragment key={idx}>
          {range.max}
          <ResourceText resourceKey={postfix} asHtml />
        </React.Fragment>
      );
    }

    return <React.Fragment children={fragments} />;
  }
}
