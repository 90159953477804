import { ObjectTypeAssignment, WaitingTimeOption } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditGeneralComponent,
  ObjectTypesEditGeneralComponentProps,
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { connect } from "react-redux";
import {
  editableBuildingStartText,
  editableDeliveryStartText,
  editableSaleStartText,
  path,
} from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;
  path: string;
  waitingTimeOptions: WaitingTimeOption[];
}
interface DispatchProps {
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, ObjectTypesEditGeneralComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;
  const { waitingTimeOptions } = state.main.mastertable.kolibri;

  return {
    currentType,
    buildingStartText: editableBuildingStartText(state),
    deliveryStartText: editableDeliveryStartText(state),
    saleStartText: editableSaleStartText(state),
    path: path(state),
    waitingTimeOptions,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesEditGeneralComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type ObjectTypesEditGeneralContainerProps = StateProps & DispatchProps;
export const ObjectTypesEditGeneralContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditGeneralComponent);
