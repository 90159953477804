import { RootEntityType } from "@haywork/api/event-center";
import { AppState, EditableActions, EditableItem } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { createSelector } from "reselect";
import {
  ExternalChangesComponent,
  ExternalChangesComponentProps,
} from "./external-changes";
import { MapDispatchToProps } from "@haywork/middleware";

const editablesSelector = (state: AppState) => state.editable.states || [];
const editable = createSelector(
  editablesSelector,
  (_: any, id: string, type: RootEntityType) => ({ id, type }),
  (editables, { id: entityId, type: entityType }) => {
    if (!entityId || !entityType || !editables || !editables.length)
      return null;
    const ref = editables.find(
      (editable) =>
        editable.entityId === entityId &&
        editable.entityType === entityType &&
        !!editable.hasNewerExternalChanges
    );
    return ref || null;
  }
);

type StateProps = {
  editable: EditableItem | null;
  employeeId: string;
};
type DispatchProps = {
  removeHasExternalChanges: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ExternalChangesComponentProps,
  AppState
> = (state, ownProps) => ({
  editable: editable(state, ownProps.entityId, ownProps.entityType),
  employeeId: state.account?.employee?.id,
});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ExternalChangesComponentProps
> = (dispatch, ownProps) => ({
  removeHasExternalChanges: () =>
    dispatch(
      EditableActions.removeHasExternalChanges({
        entityId: ownProps.entityId,
        entityType: ownProps.entityType,
      })
    ),
});

export type ExternalChangesContainerProps = StateProps & DispatchProps;
export const ExternalChangesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalChangesComponent);
