import { SortOrder } from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { AppState } from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { MLSOrder } from "@haywork/stores/mls/list";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { ListComponent, ListComponentProps } from "./list";
import {
  activeFilterGuidValues,
  hasSelectedIds,
  mlsAccessData,
  filters,
} from "./selectors";
import { push } from "connected-react-router";
import { Group, RealEstatePropertySearchItem } from "@haywork/api/mls";
import { IntlShape } from "react-intl";
import { MlsAccessData } from "@haywork/stores/mls/main/actions";
import { canSendEmail } from "@haywork/selector";

type StateProps = {
  requestStatus: string;
  assignments: RealEstatePropertySearchItem[];
  totalCount: number;
  order: MLSOrder;
  filters: FilterConfig;
  scrollOffset: number;
  activeFilterGuidValues: FilterGuidValues;
  hasSelectedIds: boolean;
  mlsAccessData: MlsAccessData;
  agencyGroups: Group[];
  canSendEmail: boolean;
};
type DispatchProps = {
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  setOrdering: (sortOrder: SortOrder, sortColumn: any) => void;
  setFilters: (filters: FilterConfig) => void;
  setScrollOffset: (offset: number) => void;
  navigate: (path: string) => void;
  setSelectedIdsFilter: (selectedIds: string[]) => void;
  mailSelection: (selectedIds: string[], intl: IntlShape) => Promise<void>;
  bulkFollow: (selectedIds: string[], intl: IntlShape) => Promise<void>;
  printRealEstateProperties: (
    properties: RealEstatePropertySearchItem[],
    intl: IntlShape
  ) => Promise<void>;
  getPropertiesById: (
    bundleIds: string[]
  ) => Promise<RealEstatePropertySearchItem[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListComponentProps,
  AppState
> = (state) => {
  const { order, scrollOffset, assignments, totalCount } = state.mls.list;
  const { requestStatus } = state.mls.main;
  const agencyGroups = state.mls.list.agencyGroups || [];
  const { accounts } = state.emailV2.accounts;

  return {
    requestStatus,
    mlsAccessData: mlsAccessData(state),
    assignments,
    totalCount,
    order,
    filters: filters(state),
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state),
    hasSelectedIds: hasSelectedIds(state),
    agencyGroups,
    canSendEmail: canSendEmail(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListComponentProps
> = (dispatch) => ({
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(MLSThunk.List.getListItems(startIndex, stopIndex)),
  setOrdering: (sortOrder: SortOrder, sortColumn: any) =>
    dispatch(MLSActions.List.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(MLSActions.List.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(MLSActions.List.setScrollOffset(offset)),
  navigate: (path: string) => dispatch(push(path)),
  setSelectedIdsFilter: (selectedIds: string[]) =>
    dispatch(MLSActions.List.setSelectedIds(selectedIds)),
  mailSelection: (selectedIds: string[], intl: IntlShape) =>
    dispatch(MLSThunk.List.mailSelection(selectedIds, intl)),
  bulkFollow: (selectedIds: string[], intl: IntlShape) =>
    dispatch(MLSThunk.List.bulkFollow(selectedIds, intl)),
  printRealEstateProperties: (
    properties: RealEstatePropertySearchItem[],
    intl: IntlShape
  ) => dispatch(MLSThunk.List.printRealEstateProperties(properties, intl)),
  getPropertiesById: (bundleIds: string[]) =>
    dispatch(MLSThunk.List.getPropertiesById(bundleIds)),
});

export type ListContainerProps = StateProps & DispatchProps;
export const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
