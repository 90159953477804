import { intlContext } from "@haywork/app";
import { addHttp } from "@haywork/util/url";
import classNames from "classnames";
import * as React from "react";
import { InputComponentProps } from "../input.component";

interface UrlInputComponentProps {
  icon?: string;
  large?: boolean;
  placeholder?: string;
  translate?: string;
  maxLength?: number;
}
interface UrlInputComponentState {}

export class UrlComponent extends React.Component<
  UrlInputComponentProps & InputComponentProps,
  UrlInputComponentState
> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const textInputStyle = classNames("input__text", {
      large: this.props.large,
      "has-icon": this.props.icon,
    });

    return (
      <div className={textInputStyle}>
        {this.props.icon && <i className={"icon " + this.props.icon} />}
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          maxLength={this.props.maxLength ? this.props.maxLength : 128}
          placeholder={placeholder}
          value={this.props.value}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          onFocus={this.props.onFocus}
          disabled={this.props.disabled}
          onKeyDown={this.onKeyDownHandler}
          ref={this.bindRef}
          data-cy={this.props["data-cy"]}
          data-lpignore="true"
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: UrlInputComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.props.onChange(value);
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    let { value } = event.currentTarget;
    if (!!value && !this.props.disableTrim) value = value.trim();
    value = addHttp(value);
    this.props.onChange(value);
    this.props.onBlur(event);
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
    }
  }
}
