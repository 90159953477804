import {
  BogCombinationObjectSituationOption,
  BogCombinationObjectStatusOption,
  BogCombinationObjectTypeOption,
  ObjectAssignment,
  UnitOfMeasurement,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import { Ui } from "@haywork/modules/ui";

interface Props {
  objectAssignment: ObjectAssignment;
  bogCombinationObjectSituationOptions: BogCombinationObjectSituationOption[];
  bogCombinationObjectStatusOptions: BogCombinationObjectStatusOption[];
  bogCombinationObjectTypeOptions?: BogCombinationObjectTypeOption[];
  onChange: (objectAssignment: ObjectAssignment) => void;
}

export class AssignmentEditContentCombinationObject extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const {
      bogCombinationObjectType,
      bogCombinationObjectSituation,
      bogCombinationObjectStatus,
      bogCombinationObjectArea,
    } = this.props.objectAssignment;

    this.formControls = {
      bogCombinationObjectType: { value: bogCombinationObjectType || "" },
      bogCombinationObjectSituation: {
        value: bogCombinationObjectSituation || "",
      },
      bogCombinationObjectStatus: { value: bogCombinationObjectStatus || "" },
      bogCombinationObjectArea: {
        value: get(bogCombinationObjectArea, "area", ""),
      },
    };

    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const {
        bogCombinationObjectType,
        bogCombinationObjectSituation,
        bogCombinationObjectStatus,
        bogCombinationObjectArea,
      } = this.props.objectAssignment;

      this.formRef.update(
        {
          bogCombinationObjectType: bogCombinationObjectType || "",
          bogCombinationObjectSituation: bogCombinationObjectSituation || "",
          bogCombinationObjectStatus: bogCombinationObjectStatus || "",
          bogCombinationObjectArea: get(bogCombinationObjectArea, "area", ""),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="assignment-combination-object"
        formControls={this.formControls}
        onChange={this.onChange}
        asSubForm={true}
        form={(form) => (this.formRef = form)}
      >
        {this.props.bogCombinationObjectTypeOptions && (
          <div className="form__row">
            <label htmlFor="bogCombinationObjectType">
              <ResourceText resourceKey="combinationObjectLabel.bogCombinationObjectType" />
            </label>
            <Input.RadioGroup
              name="bogCombinationObjectType"
              asButtonList
              canUnselect={true}
            >
              {this.props.bogCombinationObjectTypeOptions.map(
                (bogCombinationObjectType, idx) => (
                  <Input.Radio
                    value={bogCombinationObjectType.value}
                    label={bogCombinationObjectType.displayName}
                    key={idx}
                  />
                )
              )}
            </Input.RadioGroup>
          </div>
        )}

        <div className="form__row">
          <label htmlFor="bogCombinationObjectSituation">
            <ResourceText resourceKey="combinationObjectLabel.bogCombinationObjectSituation" />
          </label>
          <Input.RadioGroup
            name="bogCombinationObjectSituation"
            asButtonList
            canUnselect={true}
          >
            {this.props.bogCombinationObjectSituationOptions.map(
              (bogCombinationObjectSituation, idx) => (
                <Input.Radio
                  value={bogCombinationObjectSituation.value}
                  label={bogCombinationObjectSituation.displayName}
                  key={idx}
                />
              )
            )}
          </Input.RadioGroup>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <label htmlFor="bogCombinationObjectArea">
                <ResourceText resourceKey="combinationObjectLabel.bogCombinationObjectArea" />
                <Ui.RequiredForPublish />
              </label>
              <div className="input__helper">
                <Input.Number name="bogCombinationObjectArea" />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml={true} />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="bogCombinationObjectStatus">
                <ResourceText resourceKey="combinationObjectLabel.bogCombinationObjectStatus" />
              </label>
              <Input.RadioGroup
                name="bogCombinationObjectStatus"
                asButtonList
                canUnselect={true}
              >
                {this.props.bogCombinationObjectStatusOptions.map(
                  (bogCombinationObjectStatus, idx) => (
                    <Input.Radio
                      value={bogCombinationObjectStatus.value}
                      label={bogCombinationObjectStatus.displayName}
                      key={idx}
                    />
                  )
                )}
              </Input.RadioGroup>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChange(values: FormReturnValue) {
    const {
      bogCombinationObjectType,
      bogCombinationObjectSituation,
      bogCombinationObjectStatus,
      bogCombinationObjectArea,
    } = values;

    const objectAssignment: ObjectAssignment = {
      ...this.props.objectAssignment,
      bogCombinationObjectType,
      bogCombinationObjectSituation,
      bogCombinationObjectStatus,
      bogCombinationObjectArea: {
        ...(this.props.objectAssignment.bogCombinationObjectArea || {}),
        area: bogCombinationObjectArea,
        unitOfMeasurement: UnitOfMeasurement.Meter,
      },
    };
    this.props.onChange(objectAssignment);
  }
}
