import {
  ArchiveFilter,
  EntityDetails,
  EventCenterChangeDetails,
  EventHubClient,
  EventNotificationsClient,
  EventNotificationsOrderByField,
  EventsClient,
  EventsOrderByField,
  EventType,
  NotificationDetails,
  NotificationEvent,
  NotificationSettings,
  NotificationSettingsClient,
  RootEntityType,
  SortOrder,
  RootEntityEvent,
} from "@haywork/api/event-center";
import {
  COUNTS,
  GENERAL_EVENT_TYPES,
  GENERAL_EVENT_TYPES_IMMUTABLE,
  PERSONAL_EVENT_TYPES,
  PERSONAL_EVENT_TYPES_IMMUTABLE,
  REQUEST,
  MAINROUTES,
} from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  EventCenterActions,
  AssignmentOverviewActions,
  SchedulerActions,
  TaskActions,
  RelationActions,
  InvoiceOverviewActions,
} from "@haywork/stores";
import { DashboardActions } from "@haywork/stores/event-center/dashboard";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import {
  AsyncUtil,
  BackOfficeUtil,
  EventCenterUtil,
  EventNotificationsFilter,
  ExtendedEventNotification,
} from "@haywork/util";
import * as moment from "moment";
import { Dispatch } from ".";
import { AccountActions } from "./../../stores/account/account.actions";
import { AssignmentThunks } from "./assignment.thunk";
import { InvoiceThunk } from "./invoice.thunk";
import { ProjectsThunks } from "./projects";
import { RelationThunks } from "./relation.thunk";
import { SchedulerThunks } from "./scheduler.thunk";
import { SearchAssignmentThunks } from "./search-assignment.thunk";
import { TaskThunks } from "./task.thunk";
import { AcquisitionThunks } from "./acquisitions";
import head from "lodash-es/head";
import { AcquisitionActions } from "@haywork/stores/acquisition";
import { SearchAssignmentActions } from "@haywork/stores/search-assignment";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { NotificationsActions } from "@haywork/stores/notifications";
import { NotificationsThunk } from "./notifications";
import { MLSThunk } from "./mls";

const parseRequest = new ParseRequest();

const getInitialNotifications = (
  minPersonalTake: number = 0,
  minGeneralTake: number = 0
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EventCenterActions.Notifications.setNotificationsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;
    const { culture } = state.main;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      const personalNotificationPromise = EventNotifications.search(
        {
          orderBy: EventNotificationsOrderByField.DefaultOrder,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Descending,
          skip: 0,
          take:
            minPersonalTake > COUNTS.EVENT_NOTIFICATIONS
              ? minPersonalTake
              : COUNTS.EVENT_NOTIFICATIONS,
          employeeId,
          includeStatistics: false,
          culture,
          filterByEventTypes: [
            ...PERSONAL_EVENT_TYPES,
            ...PERSONAL_EVENT_TYPES_IMMUTABLE,
          ],
        },
        realEstateAgencyId
      );

      const generalNotificationPromise = EventNotifications.search(
        {
          orderBy: EventNotificationsOrderByField.DefaultOrder,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Descending,
          skip: 0,
          take:
            minGeneralTake > COUNTS.EVENT_NOTIFICATIONS
              ? minGeneralTake
              : COUNTS.EVENT_NOTIFICATIONS,
          employeeId,
          includeStatistics: false,
          culture,
          filterByEventTypes: [
            ...GENERAL_EVENT_TYPES,
            ...GENERAL_EVENT_TYPES_IMMUTABLE,
          ],
        },
        realEstateAgencyId
      );

      const notificationsResponse = await parseRequest.response(
        Promise.all([personalNotificationPromise, generalNotificationPromise])
      );
      const [
        personalNotificationResponse,
        generalNotificationResponse,
      ] = notificationsResponse;

      dispatch(
        EventCenterActions.Notifications.setUnreadCount(
          personalNotificationResponse.totalResults,
          generalNotificationResponse.totalResults
        )
      );
      dispatch(
        EventCenterActions.Notifications.setNotifications({
          notifications: personalNotificationResponse.results.map(
            EventCenterUtil.enrichEventNotification
          ),
          totalCount: personalNotificationResponse.totalResults,
          currentFilter: EventNotificationsFilter.Personal,
        })
      );
      dispatch(
        EventCenterActions.Notifications.setNotifications({
          notifications: generalNotificationResponse.results.map(
            EventCenterUtil.enrichEventNotification
          ),
          totalCount: generalNotificationResponse.totalResults,
          currentFilter: EventNotificationsFilter.General,
        })
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Notifications.setNotificationsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getNotifications = (
  filter?: EventNotificationsFilter,
  forceSkip?: number,
  forceTake?: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EventCenterActions.Notifications.setNotificationsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;
    const { notifications } = state.eventCenter.notifications;
    const { culture } = state.main;

    const forceAppend = forceSkip !== undefined;
    const currentFilter =
      filter || state.eventCenter.notifications.currentFilter;
    const filterByArchived =
      currentFilter === EventNotificationsFilter.Processed
        ? ArchiveFilter.ArchivedOnly
        : ArchiveFilter.NotArchivedOnly;
    const filterByEventTypes =
      currentFilter === EventNotificationsFilter.Processed
        ? undefined
        : currentFilter === EventNotificationsFilter.Personal
        ? [...PERSONAL_EVENT_TYPES, ...PERSONAL_EVENT_TYPES_IMMUTABLE]
        : [...GENERAL_EVENT_TYPES, ...GENERAL_EVENT_TYPES_IMMUTABLE];
    const skip = forceAppend
      ? forceSkip
      : notifications.filter(
          (notification) => notification.filter === currentFilter
        ).length;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      const notificationsResponse = await parseRequest.response(
        EventNotifications.search(
          {
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            filterByArchived,
            order: SortOrder.Descending,
            skip,
            take: forceTake || COUNTS.EVENT_NOTIFICATIONS,
            employeeId,
            includeStatistics: false,
            culture,
            filterByEventTypes,
          },
          realEstateAgencyId
        )
      );

      const notifications = notificationsResponse.results.map(
        EventCenterUtil.enrichEventNotification
      );
      const totalCount = notificationsResponse.totalResults;

      dispatch(
        EventCenterActions.Notifications.setNotifications({
          notifications,
          totalCount,
          currentFilter,
          forceAppend,
        })
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Notifications.setNotificationsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const getSystemMessages = (forceSkip?: number, forceTake?: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const skip = forceSkip || 0;
    const client = new EventsClient(eventCenterHost);

    try {
      const linkedMessages = await parseRequest.response(
        client
          .search(
            {
              orderBy: EventsOrderByField.DefaultOrder,
              includeStatistics: false,
              filterByArchived: ArchiveFilter.NotArchivedOnly,
              order: SortOrder.Descending,
              take: forceTake || COUNTS.EVENT_NOTIFICATIONS,
              skip,
              eventTypes: [EventType.CompanyMessage],
            },
            realEstateAgencyId
          )
          .then((response) => response.results || [])
          .then((events) =>
            events.map((event) => head(event.linkedMessages || []))
          )
          .then((messages) => messages.filter((message) => !!message))
      );

      return linkedMessages;
    } catch (error) {
      throw error;
    }
  };
};

const getUnreadCount = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;
    const { culture } = state.main;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      const personalNotificationPromise = EventNotifications.search(
        {
          orderBy: EventNotificationsOrderByField.DefaultOrder,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Descending,
          skip: 0,
          take: 0,
          employeeId,
          includeStatistics: false,
          culture,
          filterByEventTypes: PERSONAL_EVENT_TYPES,
        },
        realEstateAgencyId
      ).then((response) => response.totalResults);

      const generalNotificationPromise = EventNotifications.search(
        {
          orderBy: EventNotificationsOrderByField.DefaultOrder,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Descending,
          skip: 0,
          take: 0,
          employeeId,
          includeStatistics: false,
          culture,
          filterByEventTypes: GENERAL_EVENT_TYPES,
        },
        realEstateAgencyId
      ).then((response) => response.totalResults);

      const [
        personalTotalResults,
        generalTotalResults,
      ] = await parseRequest.response(
        Promise.all([personalNotificationPromise, generalNotificationPromise])
      );

      dispatch(
        EventCenterActions.Notifications.setUnreadCount(
          personalTotalResults,
          generalTotalResults
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const OBSOLETE__getUnreadCount = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;
    const {
      numberOfGeneralUnprocessedNotifications,
      numberOfPersonalUnprocessedNotifications,
    } = state.eventCenter.notifications;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      const personalUnreadCount = await parseRequest.response(
        EventNotifications.search(
          {
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 0,
            employeeId,
            includeStatistics: true,
            filterByEventTypes: PERSONAL_EVENT_TYPES,
          },
          realEstateAgencyId
        ).then((response) => response.statistics.numberOfNotArchivedEvents)
      );

      const generalUnreadCount = await parseRequest.response(
        EventNotifications.search(
          {
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 0,
            employeeId,
            includeStatistics: true,
            filterByEventTypes: GENERAL_EVENT_TYPES,
          },
          realEstateAgencyId
        ).then((response) => response.statistics.numberOfNotArchivedEvents)
      );

      dispatch(
        EventCenterActions.Notifications.setUnreadCount(
          personalUnreadCount,
          generalUnreadCount
        )
      );

      if (
        generalUnreadCount !== numberOfGeneralUnprocessedNotifications ||
        personalUnreadCount !== numberOfPersonalUnprocessedNotifications
      ) {
        dispatch(
          getInitialNotifications(
            numberOfPersonalUnprocessedNotifications - personalUnreadCount,
            numberOfGeneralUnprocessedNotifications - generalUnreadCount
          )
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const getNotificationSettings = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      EventCenterActions.Settings.setNotificationSettingsStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;

    const NotificationSettings = new NotificationSettingsClient(
      eventCenterHost
    );

    try {
      const notificationSettings = await parseRequest.response(
        NotificationSettings.read(employeeId, realEstateAgencyId).then(
          (response) => response.notificationSettings
        )
      );

      dispatch(
        EventCenterActions.Settings.setNotificationSettings({
          notificationSettings,
        })
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Settings.setNotificationSettingsStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const updateNotificationSettings = (
  notificationSettings: NotificationSettings[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;

    const NotificationSettings = new NotificationSettingsClient(
      eventCenterHost
    );

    try {
      const savedNotificationSettings = await parseRequest.response(
        NotificationSettings.save(
          {
            employeeId,
            notificationSettings,
          },
          realEstateAgencyId
        ).then((response) => response.notificationSettings)
      );

      dispatch(
        EventCenterActions.Settings.setNotificationSettings({
          notificationSettings: savedNotificationSettings,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const archive = (notification: ExtendedEventNotification) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;

    const updatedNotification: ExtendedEventNotification = {
      ...notification,
      filter: EventNotificationsFilter.Processed,
      isRead: true,
      linkedEvent: {
        ...notification.linkedEvent,
        archivedBy: {
          id: employeeId,
        },
        archivedDate: new Date(),
      },
    };

    const Events = new EventsClient(eventCenterHost);
    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      await parseRequest.response(
        Events.toggleIsArchived(
          {
            eventId: notification.linkedEvent.id,
            isArchived: true,
          },
          realEstateAgencyId
        )
      );

      if (!notification.isRead) {
        await parseRequest.response(
          EventNotifications.toggleIsRead(
            { eventNotificationId: notification.id, isRead: true, employeeId },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EventCenterActions.Notifications.updateNotification({
          notification: updatedNotification,
        })
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Notifications.updateNotification({ notification })
      );
      throw error;
    }
  };
};

const unArchive = (notification: ExtendedEventNotification) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const filter =
      PERSONAL_EVENT_TYPES.indexOf(notification.linkedEvent.eventType) !== -1
        ? EventNotificationsFilter.Personal
        : EventNotificationsFilter.General;
    const updatedNotification: ExtendedEventNotification = {
      ...notification,
      filter,
      linkedEvent: {
        ...notification.linkedEvent,
        archivedBy: undefined,
        archivedDate: undefined,
      },
    };

    const Events = new EventsClient(eventCenterHost);

    try {
      await parseRequest.response(
        Events.toggleIsArchived(
          {
            eventId: notification.linkedEvent.id,
            isArchived: false,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        EventCenterActions.Notifications.updateNotification({
          notification: updatedNotification,
        })
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Notifications.updateNotification({ notification })
      );
      throw error;
    }
  };
};

const toggleAllIsRead = (isRead: boolean = true) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employeeId } = state.access;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      await parseRequest.response(
        EventNotifications.toggleAllIsRead(
          { employeeId, isRead },
          realEstateAgencyId
        )
      );
      dispatch(EventCenterActions.Notifications.toggleAllIsRead());
    } catch (error) {
      throw error;
    }
  };
};

const showNotificationToast = (details: NotificationDetails) => {
  return (dispatch: Dispatch<any>) => {
    if (!details || !details.message) return;

    let icon;
    switch (details.event) {
      case NotificationEvent.AccountCreated:
        icon = "user-plus";
        break;
      case NotificationEvent.BankwarrantyExpired:
        icon = "file-contract";
        break;
      case NotificationEvent.Birthday:
        icon = "birthday-cake";
        break;
      case NotificationEvent.BrochureDownloaded:
        icon = "file-download";
        break;
      case NotificationEvent.ContactMeRequest:
        icon = "envelope";
        break;
      case NotificationEvent.NvmOauthTokenExpired:
        icon = "ticket-alt";
        break;
      case NotificationEvent.OfferListing:
        icon = "sign";
        break;
      case NotificationEvent.PublicationFailed:
        icon = "exclamation";
        break;
      case NotificationEvent.PublicationSucceeded:
        icon = "check";
        break;
      case NotificationEvent.RentedFromExpired:
        icon = "grin-beam";
        break;
      case NotificationEvent.RentedUntilExpired:
        icon = "sync-alt";
        break;
      case NotificationEvent.ReservationExpired:
        icon = "file-contract";
        break;
      case NotificationEvent.Searchprofile:
        icon = "search";
        break;
      case NotificationEvent.SoldExpired:
        icon = "grin-beam";
        break;
      // case NotificationEvent.SystemMessage:
      //   icon = "comment";
      //   break;
      case NotificationEvent.Valuation:
        icon = "home";
        break;
      case NotificationEvent.Viewing:
        icon = "home";
        break;
      case NotificationEvent.ViewingRequest:
        icon = "home";
        break;
      case NotificationEvent.AccountDeleted:
        icon = "user-times";
        break;
      case NotificationEvent.TransferExpired:
        icon = "box-open";
        break;
      case NotificationEvent.IncomingBidExpired:
      case NotificationEvent.OutgoingBidExpired:
        icon = "comments-alt-dollar";
        break;
      case NotificationEvent.SentEmailOpened:
        icon = "envelope-open-text";
        break;
      case NotificationEvent.ContactForm:
        icon = "envelope";
        break;
      case NotificationEvent.Unknown:
      default:
        icon = "ellipsis-h";
        break;
    }

    const toast: ToastProps = {
      value: details.message,
      icon,
    };

    dispatch(SnackbarActions.addToast(toast));
  };
};

const setNotificationsFilter = (filter: EventNotificationsFilter) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EventCenterActions.Notifications.setFilter(filter));
    const state = getState();
    const {
      personalNotificationTotalCount,
      generalNotificationTotalCount,
      archivedNotificationTotalCount,
    } = state.eventCenter.notifications;

    let count = 0;
    switch (filter) {
      case EventNotificationsFilter.Personal: {
        count = personalNotificationTotalCount;
        break;
      }
      case EventNotificationsFilter.General: {
        count = generalNotificationTotalCount;
        break;
      }
      case EventNotificationsFilter.Processed: {
        count = archivedNotificationTotalCount;
        break;
      }
      default:
        break;
    }

    if (count === -1) {
      dispatch(getNotifications(filter));
    }
  };
};

const processAll = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employee } = state.account;

    const Events = new EventsClient(eventCenterHost);

    try {
      await parseRequest.response(
        Events.toggleAllIsArchived({ isArchived: true }, realEstateAgencyId)
      );
      dispatch(
        EventCenterActions.Notifications.processAll({
          id: employee.id,
          displayName: employee.displayName,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const getAssignmentsNeedAttentionEvents = (showAllAssignments = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      return;
    }

    dispatch(
      EventCenterActions.Dashboard.setAssignmentsNeedAttentionStatus(
        REQUEST.PENDING
      )
    );

    const filterByRootEntityId = showAllAssignments ? null : employeeId;

    const Events = new EventsClient(eventCenterHost);

    try {
      const events = await parseRequest.response(
        Events.search(
          {
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            orderBy: EventsOrderByField.DefaultOrder,
            filterByRootEntityId,
            includeStatistics: false,
            eventTypes: [
              EventType.BankwarrantyExpired,
              EventType.PublicationFailed,
              EventType.RentedUntilExpired,
              EventType.ReservationExpired,
              EventType.TransferExpired,
              EventType.OutgoingBidExpired,
              EventType.IncomingBidExpired,
              EventType.DocumentSigned,
              EventType.DocumentSignDeclined,
              EventType.DocumentSignExpired,
            ],
            minEventDateTime: moment().subtract(1, "month").toDate(),
            maxEventDateTime: moment().add(1, "year").toDate(),
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );
      dispatch(AccountActions.setShowAllAssignments(showAllAssignments));
      dispatch(
        EventCenterActions.Dashboard.setAssignmentsNeedAttentionResults(events)
      );
    } catch (error) {
      dispatch(
        EventCenterActions.Dashboard.setAssignmentsNeedAttentionStatus(
          REQUEST.ERROR
        )
      );
      throw error;
    }
  };
};

const getLeadsEvents = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EventCenterActions.Dashboard.setLeadsStatus(REQUEST.PENDING));

    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Events = new EventsClient(eventCenterHost);

    try {
      const events = await parseRequest.response(
        Events.search(
          {
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 3,
            orderBy: EventsOrderByField.DefaultOrder,
            includeStatistics: false,
            eventTypes: [
              EventType.AccountCreated,
              EventType.BrochureDownloaded,
              EventType.ContactMeRequest,
              EventType.ViewingRequest,
              EventType.OfferListing,
            ],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(EventCenterActions.Dashboard.setLeadsResults(events));
    } catch (error) {
      dispatch(EventCenterActions.Dashboard.setLeadsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const fetchNotificationsIfNeeded = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const {
      currentFilter,
      numberOfGeneralUnprocessedNotifications: generalCount,
      numberOfPersonalUnprocessedNotifications: personalCount,
      notifications,
      forceReload,
    } = state.eventCenter.notifications;

    const count = (notifications || []).filter(
      (notification) => notification.filter === currentFilter
    ).length;

    switch (true) {
      case currentFilter === EventNotificationsFilter.General &&
        (count !== generalCount || forceReload): {
        dispatch(
          EventCenterActions.Notifications.setForceReload({
            forceReload: false,
          })
        );
        return dispatch(
          getNotifications(currentFilter, 0, Math.abs(count - generalCount))
        );
      }
      case currentFilter === EventNotificationsFilter.Personal &&
        (count !== personalCount || forceReload): {
        dispatch(
          EventCenterActions.Notifications.setForceReload({
            forceReload: false,
          })
        );
        return dispatch(
          getNotifications(currentFilter, 0, Math.abs(count - personalCount))
        );
      }
      default:
        return;
    }
  };
};

const handleEventCenterChangeDetails = (
  eventCenterChangeDetails?: EventCenterChangeDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!eventCenterChangeDetails) return;
    const { entityIds } = eventCenterChangeDetails;
    if (!entityIds || !entityIds.length) return;

    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { employee } = state.account;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { features } = state.appSettings;

    const EventNotifications = new EventNotificationsClient(eventCenterHost);

    try {
      const notifications = await parseRequest.response(
        EventNotifications.search(
          {
            employeeId: employee.id,
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            includeStatistics: false,
            filterByArchived: ArchiveFilter.ArchivedAndNot,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            eventIds: entityIds,
          },
          realEstateAgencyId
        ).then((response) => response.results || [])
      );

      if (FeatureHelper.executeBlock(features, "EVENTCENTER_V2")) {
        dispatch(
          NotificationsActions.EventCenter.bulkUpdateListItems(notifications)
        );
        dispatch(NotificationsThunk.EventCenter.getStatistics());
      } else {
        notifications
          .map((notification) =>
            EventCenterUtil.enrichEventNotification(notification)
          )
          .map((notification) =>
            dispatch(
              EventCenterActions.Notifications.updateNotification({
                notification,
              })
            )
          );
      }
    } catch (error) {
      throw error;
    }
  };
};

const eventArchive = (eventId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Events = new EventsClient(eventCenterHost);

    try {
      await parseRequest.response(
        Events.toggleIsArchived(
          {
            eventId,
            isArchived: true,
          },
          realEstateAgencyId
        )
      );

      dispatch(EventCenterActions.Dashboard.archive(eventId));
    } catch (error) {
      throw error;
    }
  };
};

const handleRootEntityChangeListUpdates = (
  event: RootEntityEvent,
  entityType: RootEntityType,
  entityId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { location } = state.router;
    const { features } = state.appSettings;

    switch (event) {
      case RootEntityEvent.Add: {
        switch (entityType) {
          case RootEntityType.ObjectAssignment:
          case RootEntityType.ProjectAssignment: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.assignment.list;
              if (!totalCount) return;
            } else {
              const { total } = state.assignment.overview.assignments;
              if (!total || location.pathname !== MAINROUTES.ASSIGNMENTS.URI)
                return;
            }

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("assignments")
            );
            return;
          }
          case RootEntityType.AcquisitionAssignment: {
            const { total } = state.acquisition.list;
            if (!total || location.pathname !== MAINROUTES.ACQUISITIONS.URI)
              return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("acquisitions")
            );
            return;
          }
          case RootEntityType.Task: {
            const { tasks } = state.task;
            if (!tasks.length || location.pathname !== MAINROUTES.TASKS.URI)
              return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("tasks")
            );
            return;
          }
          case RootEntityType.Message: {
            if (location.pathname !== MAINROUTES.NOTIFICATIONS.URI) return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("eventCenterV2")
            );
            return;
          }
          case RootEntityType.ContactCompany:
          case RootEntityType.ContactPerson: {
            const { relations } = state.relation.list;
            if (
              !relations.length ||
              location.pathname !== MAINROUTES.RELATIONS.URI
            )
              return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("relations")
            );
            return;
          }
          case RootEntityType.SearchAssignment: {
            const { searchAssignmentsTotal } = state.searchAssignment.overview;
            if (
              !searchAssignmentsTotal ||
              location.pathname !== MAINROUTES.SEARCHASSIGNMENT.URI
            )
              return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus(
                "searchAssignments"
              )
            );
            return;
          }
          case RootEntityType.Invoice: {
            const { total } = state.invoice.overview.invoices;
            if (!total || location.pathname !== MAINROUTES.FINANCE.URI) return;

            dispatch(
              EventCenterActions.EntityListStatus.setListStatus("invoices")
            );
            return;
          }
          case RootEntityType.Agendaitem: {
            if (location.pathname !== MAINROUTES.SCHEDULER.URI) return;
            dispatch(SchedulerThunks.updateAfterAdd(entityId));
            return;
          }
          default: {
            return;
          }
        }
      }
      case RootEntityEvent.Delete: {
        switch (entityType) {
          case RootEntityType.ObjectAssignment:
          case RootEntityType.ProjectAssignment: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.assignment.list;
              if (!totalCount) return;

              dispatch(
                EventCenterActions.EntityListStatus.setListStatus("assignments")
              );
            } else {
              dispatch(
                AssignmentOverviewActions.deleteListItem({ id: entityId })
              );
            }
            return;
          }
          case RootEntityType.AcquisitionAssignment: {
            dispatch(AcquisitionActions.List.deleteListItem(entityId));
            return;
          }
          case RootEntityType.Agendaitem: {
            dispatch(SchedulerActions.deleteListItem({ id: entityId }));
            return;
          }
          case RootEntityType.Task: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.taskV2.list;
              if (!totalCount) return;

              dispatch(
                EventCenterActions.EntityListStatus.setListStatus("tasks")
              );
            } else {
              dispatch(TaskActions.deleteListItem({ id: entityId }));
            }
            return;
          }
          case RootEntityType.ContactCompany:
          case RootEntityType.ContactPerson: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.relation.list;
              if (!totalCount) return;

              dispatch(
                EventCenterActions.EntityListStatus.setListStatus("relations")
              );
            } else {
              dispatch(RelationActions.deleteListItem({ id: entityId }));
            }
            return;
          }
          case RootEntityType.SearchAssignment: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.searchAssignment.list;
              if (!totalCount) return;

              dispatch(
                EventCenterActions.EntityListStatus.setListStatus(
                  "searchAssignments"
                )
              );
            } else {
              dispatch(
                SearchAssignmentActions.deleteListItem({ id: entityId })
              );
            }
            return;
          }
          case RootEntityType.Invoice: {
            if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
              const { totalCount } = state.invoice.list;
              if (!totalCount) return;

              dispatch(
                EventCenterActions.EntityListStatus.setListStatus("invoices")
              );
            } else {
              dispatch(InvoiceOverviewActions.deleteListItem({ id: entityId }));
            }
            return;
          }
          default: {
            return;
          }
        }
      }
      case RootEntityEvent.Update: {
        switch (entityType) {
          case RootEntityType.ObjectAssignment: {
            dispatch(AssignmentThunks.updateObjectAssignmentListItem(entityId));
            return;
          }
          case RootEntityType.ProjectAssignment: {
            dispatch(
              AssignmentThunks.updateProjectAssignmentListItem(entityId)
            );
            return;
          }
          case RootEntityType.AcquisitionAssignment: {
            dispatch(
              AcquisitionThunks.Acquisition.updateAcquisitionListItem(entityId)
            );
            return;
          }
          case RootEntityType.Agendaitem: {
            dispatch(SchedulerThunks.updateAgendaItem(entityId));
            return;
          }
          case RootEntityType.Task: {
            dispatch(TaskThunks.updateTaskListItem(entityId));
            return;
          }
          case RootEntityType.ContactCompany: {
            dispatch(RelationThunks.updateContactCompanyListItem(entityId));
            return;
          }
          case RootEntityType.ContactPerson: {
            dispatch(RelationThunks.updateContactPersonListItem(entityId));
            return;
          }
          case RootEntityType.SearchAssignment: {
            dispatch(
              SearchAssignmentThunks.updateSearchAssignmentListItem(entityId)
            );
            return;
          }
          case RootEntityType.Invoice: {
            dispatch(InvoiceThunk.updateInvoiceListItem(entityId));
            return;
          }
          case RootEntityType.Message: {
            dispatch(
              NotificationsThunk.EventCenter.updateEventNotificationByMessageId(
                entityId
              )
            );
            return;
          }
          default: {
            return;
          }
        }
      }
      default: {
        return;
      }
    }
  };
};

const handleRootEntityChange = (entityDetails?: EntityDetails) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!entityDetails || !entityDetails.entityId) return;
    const { entityType, entityId, event } = entityDetails;
    dispatch(handleRootEntityChangeListUpdates(event, entityType, entityId));

    await AsyncUtil.wait(1000);

    const state = getState();
    const { states } = state.editable;
    const editable = (states || []).find(
      (s) => s.entityType === entityType && s.entityId === entityId
    );

    if (!editable || event !== RootEntityEvent.Update) return;

    switch (entityType) {
      case RootEntityType.ObjectAssignment: {
        dispatch(
          AssignmentThunks.checkExternalChanges(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.ProjectAssignment: {
        dispatch(
          ProjectsThunks.Projects.checkExternalChanges(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.ObjectTypeAssignment: {
        dispatch(
          ProjectsThunks.Types.checkExternalChanges(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.AcquisitionAssignment: {
        dispatch(
          AcquisitionThunks.Acquisition.checkExternalChanges(
            editable,
            entityDetails
          )
        );
        return;
      }
      case RootEntityType.AcquisitionObjectAssignment: {
        dispatch(
          AcquisitionThunks.AcquisitionObject.checkExternalChanges(
            editable,
            entityDetails
          )
        );
        return;
      }
      case RootEntityType.Agendaitem: {
        dispatch(SchedulerThunks.checkExternalChanges(editable, entityDetails));
        return;
      }
      case RootEntityType.Task: {
        dispatch(TaskThunks.checkExternalChanges(editable, entityDetails));
        return;
      }
      case RootEntityType.ContactCompany: {
        dispatch(
          RelationThunks.checkExternalChangesCompany(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.ContactPerson: {
        dispatch(
          RelationThunks.checkExternalChangesPerson(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.SearchAssignment: {
        dispatch(
          SearchAssignmentThunks.checkExternalChanges(editable, entityDetails)
        );
        return;
      }
      case RootEntityType.Invoice: {
        dispatch(InvoiceThunk.checkExternalChanges(editable, entityDetails));
        return;
      }
      default: {
        return;
      }
    }
  };
};

export const EventCenterThunk = {
  getInitialNotifications,
  getNotifications,
  getSystemMessages,
  getUnreadCount,
  getNotificationSettings,
  updateNotificationSettings,
  archive,
  unArchive,
  toggleAllIsRead,
  showNotificationToast,
  setNotificationsFilter,
  processAll,
  getAssignmentsNeedAttentionEvents,
  getLeadsEvents,
  fetchNotificationsIfNeeded,
  handleEventCenterChangeDetails,
  eventArchive,
  handleRootEntityChange,
};
