import { ProjectAssignment } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { PROJECTROUTES, REQUEST } from "@haywork/constants";
import { FormReturnValue } from "@haywork/modules/form";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { NotesList } from "@haywork/modules/notes-v3";
import {
  ProjectDetailContainerProps,
  ProjectDetailRouting,
  ProjectSaveModalComponent,
  ProjectWithdrawModalComponent,
} from "@haywork/modules/project";
import {
  ConfirmComponent,
  ObjectTimestamps,
  ResourceText,
  SlideShow,
} from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ProjectDetailComponentProps {}
interface State {}
type Props = ProjectDetailComponentProps & ProjectDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    // Bindings
    this.onUnmountHandler = this.onUnmountHandler.bind(this);
    this.onDirtyHandler = this.onDirtyHandler.bind(this);
    this.onSlideClickHandler = this.onSlideClickHandler.bind(this);
    this.onCloseAvailabilityModal = this.onCloseAvailabilityModal.bind(this);
    this.onCloseWithdrawModal = this.onCloseWithdrawModal.bind(this);
    this.onCloseAvailabilityHander = this.onCloseAvailabilityHander.bind(this);
    this.onConfirmSaleHandler = this.onConfirmSaleHandler.bind(this);
    this.onCloseAvailabilityHander = this.onCloseAvailabilityHander.bind(this);
    this.onConfirmRentHandler = this.onConfirmRentHandler.bind(this);
  }

  public render() {
    if (!this.props.project) return null;

    const {
      project,
      withdrawReasonOptions,
      withdrawState,
      showWithdrawModal,
      showStatusModal,
      statusState,
    } = this.props;

    const {
      id,
      linkedCreatedBy,
      linkedModifiedBy,
      dateTimeCreated,
      dateTimeModified,
      isNew,
      displayName,
      forRent,
      forSale,
    } = this.props.project;

    const photos = project.photos ? project.photos.filter((p) => !!p) : [];
    const showSidebar = !this.props.location.pathname.match(/edit/);

    const statusModalVisible =
      showStatusModal || statusState === REQUEST.PENDING;

    return (
      <div
        styleName="detail"
        ref={(ref) => (this.ref = ref)}
        data-cy="CY-projectParentDiv"
      >
        {!isNew && <NotesList parentId={id} />}
        {showSidebar && (
          <div styleName="sideBar">
            <div styleName="preview-slider" className="hidden-xs hidden-sm">
              {photos && photos.length > 0 && (
                <SlideShow
                  slides={photos}
                  onSlideClick={this.onSlideClickHandler}
                />
              )}
              {(!photos || photos.length === 0) && (
                <i className="fal fa-fw fa-camera-retro" />
              )}
            </div>
            <div styleName="menu">
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.GENERAL.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-folder"
                  data-cy="CY-projectDetailMenuGeneral"
                />
                <span styleName="text">
                  <ResourceText resourceKey="tabProject" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.TIMELINE.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-align-left"
                  data-cy="CY-projectDetailMenuTimeline"
                />
                <span styleName="text">
                  <ResourceText resourceKey="tabTimeline" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.NETWORK.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-users"
                  data-cy="CY-projectDetailMenuNetwork"
                />
                <span styleName="text">
                  <ResourceText resourceKey="tabNetwork" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.DOSSIER.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-archive"
                  data-cy="CY-projectDetailMenuDossier"
                />
                <span styleName="text">
                  <ResourceText resourceKey="tabDossier" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.TYPES.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-puzzle-piece"
                  data-cy="CY-projectDetailMenuTypes"
                />
                <span styleName="text">
                  <ResourceText resourceKey="typesMenuItem" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(PROJECTROUTES.BUILD_NUMBERS.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-map-signs"
                  data-cy="CY-projectDetailMenuBuildnumbers"
                />
                <span styleName="text">
                  <ResourceText resourceKey="projectDetailBuildNumbers" />
                </span>
              </NavLink>
            </div>
            <ObjectTimestamps
              linkedCreatedBy={linkedCreatedBy}
              linkedModifiedBy={linkedModifiedBy}
              dateTimeCreated={dateTimeCreated}
              dateTimeModified={dateTimeModified}
            />
          </div>
        )}
        <div styleName="body">
          <ProjectDetailRouting id={id} />
        </div>

        <Modal visible={showWithdrawModal} onClose={this.onCloseWithdrawModal}>
          <ModalHeader title="withDrawAssignment" close />
          <ModalBody noPadding>
            <ProjectWithdrawModalComponent
              displayName={displayName}
              projectId={id}
              withdrawReasonOptions={withdrawReasonOptions}
              withdrawState={withdrawState}
              onCancel={this.onCloseWithdrawModal}
              withdrawProject={this.props.withdrawProjectAssignment}
              changeAvailabilityStatus={this.props.changeAvailabilityStatus}
              onCloseAvailabilityModal={this.onCloseWithdrawModal}
            />
          </ModalBody>
        </Modal>

        <ConfirmComponent
          visible={statusModalVisible && forSale}
          titleResourceKey="projectSaleModalTitle"
          bodyResourceKey="projectSaleModalMessage"
          onClose={this.onCloseAvailabilityHander}
          onConfirm={this.onConfirmSaleHandler}
        />

        <ConfirmComponent
          visible={statusModalVisible && forRent}
          titleResourceKey="projectRentModalTitle"
          bodyResourceKey="projectRentModalMessage"
          onClose={this.onCloseAvailabilityHander}
          onConfirm={this.onConfirmRentHandler}
        />

        <ProjectSaveModalComponent
          visible={this.props.showSaveModal}
          projectAssignment={this.props.project}
          onClose={() => this.props.toggleSaveModal(false)}
        />
      </div>
    );
  }

  public componentDidMount() {
    this.props.setTabTitle(
      this.props.project.displayName ||
        intlContext.formatMessage({
          id: "newProject",
          defaultMessage: "newProject",
        })
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveAndCloseProject();
    }

    if (
      nextProps.project &&
      !!nextProps.project.displayName &&
      nextProps.project.displayName !== this.props.project.displayName
    ) {
      this.props.setTabTitle(
        nextProps.project.displayName
          ? nextProps.project.displayName
          : intlContext.formatMessage({ id: "newProject" })
      );
    }
  }

  private onSlideClickHandler(idx: number) {
    this.props.openLightbox(this.props.project.photos, idx);
  }

  private onConfirmSaleHandler() {
    this.props.sellProjectAssignment(this.props.project.id);
  }

  private onConfirmRentHandler() {
    this.props.rentProjectAssignment(this.props.project.id);
  }

  private onCloseAvailabilityHander() {
    this.props.toggleStatusModal(false);
  }

  private onCloseAvailabilityModal() {
    this.props.toggleStatusModal(false);
    this.props.clearAvailabilityStatus();
  }

  private onCloseWithdrawModal() {
    this.props.toggleWithdrawModal(false);
    this.props.clearAvailabilityStatus();
  }

  private onUnmountHandler(values: FormReturnValue) {
    const updatedProject: ProjectAssignment = {
      ...this.props.project,
      ...values,
    };
    const path = route(PROJECTROUTES.DETAIL.URI, { id: updatedProject.id });
    this.props.updateCache(updatedProject, path);
  }

  private onDirtyHandler() {
    this.props.setHasChanges();
  }
}
