import * as React from "react";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import { KEYCODE } from "@haywork/constants";
import { MergeFields, ResourceText } from "@haywork/modules/shared/";

import { InputComponentProps } from "../input.component";
import { MergeField, MergeFieldCategory } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";

interface TextWithMergeFieldComponentProps {
  inEditMode: boolean;
  mergeFields: MergeField[];
  mergefieldCategoryWhitelist?: MergeFieldCategory[];
  mergefieldCategoryBlacklist?: MergeFieldCategory[];
}
interface State {
  value: string;
  focussed: boolean;
  mergeFieldsVisible: boolean;
}
type Props = TextWithMergeFieldComponentProps & InputComponentProps;

export class TextWithMergeFieldComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;
  private triggerRef: HTMLButtonElement;
  private range: Range;

  constructor(props) {
    super(props);

    const value =
      [null, undefined].indexOf(this.props.value) === -1
        ? this.props.value
        : "";

    this.state = {
      value,
      focussed: false,
      mergeFieldsVisible: false,
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onButtonFocus = this.onButtonFocus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.toggleMergeFieldVisibility =
      this.toggleMergeFieldVisibility.bind(this);
    this.onMergeFieldsHide = this.onMergeFieldsHide.bind(this);
    this.onMergeFieldsAdd = this.onMergeFieldsAdd.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.bindTriggerRef = this.bindTriggerRef.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    let value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    value = this.cleanValue(value);

    if (this.state.value !== value) {
      this.setState({ value });
      this.ref.innerHTML = this.setContent(value);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.mergeFields.length && !!this.props.mergeFields.length) {
      this.ref.innerHTML = this.setContent(this.state.value);
    }
  }

  public render() {
    const inputStyle = classNames("input__text-with-mergefield", {
      focussed: this.state.focussed,
    });

    return (
      <div className={inputStyle}>
        <button
          className="focus-ref"
          type="button"
          id={this.props.name}
          name={this.props.name}
          onFocus={this.onButtonFocus}
        />
        <div
          className="contenteditable"
          contentEditable={true}
          onKeyUp={this.onKeyUp}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onSelect={this.setSelection}
          ref={this.bindRef}
          spellCheck={false}
          data-cy={this.props["data-cy"]}
        />

        {!!this.props.inEditMode && (
          <React.Fragment>
            <button
              className="mergefield-trigger"
              ref={this.bindTriggerRef}
              type="button"
              onClick={this.toggleMergeFieldVisibility}
            >
              <i className="fal fa-file-alt" />
              <ResourceText resourceKey="tinyMCEAddMergeFieldButton" />
            </button>

            <MergeFields
              visible={this.state.mergeFieldsVisible}
              parent={this.triggerRef}
              onHide={this.onMergeFieldsHide}
              onAdd={this.onMergeFieldsAdd}
              categoryWhiteList={this.props.mergefieldCategoryWhitelist}
              categoryBlackList={this.props.mergefieldCategoryBlacklist}
            />
          </React.Fragment>
        )}
      </div>
    );
  }

  public componentDidMount() {
    if (!!this.ref) {
      this.ref.innerHTML = this.setContent(this.state.value);
    }
  }

  private bindRef(ref: HTMLDivElement) {
    if (!ref || !!this.ref) return;
    this.ref = ref;
  }

  private bindTriggerRef(ref: HTMLButtonElement) {
    if (!ref || !!this.triggerRef) return;
    this.triggerRef = ref;
  }

  private onButtonFocus() {
    setTimeout(() => this.ref.focus(), 0);
  }

  private onFocus() {
    this.setState({
      focussed: true,
    });
  }

  private setSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (!sel) return;
      this.range = sel.getRangeAt(0);
    }
  }

  private toggleMergeFieldVisibility() {
    this.setState(({ mergeFieldsVisible }) => ({
      mergeFieldsVisible: !mergeFieldsVisible,
    }));
  }

  private onMergeFieldsHide() {
    this.setState({ mergeFieldsVisible: false });
  }

  private onMergeFieldsAdd(field: MergeField) {
    const id = uuid();
    const span = document.createElement("span");
    span.id = id;
    span.classList.add("inline-highlight-mergefield");
    span.setAttribute("contenteditable", "false");
    span.setAttribute(
      "data-mergefield-value",
      field.mergeFieldValue.replace(/[{}]/g, "")
    );
    const categoryDisplayName = document.createElement("strong");
    categoryDisplayName.innerText = field.categoryDisplayName;

    const displayName = document.createTextNode(field.displayName);

    span.appendChild(categoryDisplayName);
    span.appendChild(displayName);

    if (!this.range) {
      this.range = window.getSelection().getRangeAt(0);
    }

    const range = this.range.cloneRange();
    const sel = window.getSelection();

    range.deleteContents();
    range.insertNode(span);

    range.setStartAfter(span);
    range.setEndAfter(span);
    sel.removeAllRanges();
    sel.addRange(range);

    this.setState({
      value: this.cleanValue(this.ref.innerHTML),
      mergeFieldsVisible: false,
    });
  }

  private onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.keyCode === KEYCODE.ENTER) {
      event.preventDefault();
      return false;
    }
  }

  private onKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    const value = this.cleanValue(event.currentTarget.innerHTML);
    this.setState({ value });
  }

  private onBlur() {
    const value = StringUtil.stripHTML(this.state.value);
    this.props.onChange(value);

    this.setState({
      focussed: false,
    });
  }

  private cleanValue(value: string): string {
    const mergefieldMatcher =
      /<span([\w\W]+?)data-mergefield-value([\w\W]+?)>([\w\W]+?)<\/span>/gi;
    const matches = value.match(mergefieldMatcher);

    let returnValue = value;

    if (matches) {
      matches.map((matched) => {
        const value = `\{\{${
          /data-mergefield-value="([^)]+)"/g.exec(matched)[1]
        }\}\}`;
        returnValue = returnValue.replace(matched, value);
      });
    }

    returnValue = returnValue.replace(/&nbsp;/gi, " ");

    return returnValue;
  }

  private setContent(value: string) {
    let content = value;

    const match = /\{\{([\w\.]+)\}\}/gi;
    const matches = content.match(match);

    if (
      !!matches &&
      !!this.props.mergeFields &&
      !!this.props.mergeFields.length
    ) {
      matches.map((matched) => {
        const mergeField = this.props.mergeFields.find(
          (field) => field.mergeFieldValue === matched
        );

        if (!mergeField) return;

        const value = matched.replace(/[{}]/g, "");
        const replacement = `<span contenteditable="false" class="inline-highlight-mergefield ${
          this.props.inEditMode ? "" : "missing"
        }" data-mergefield-value="${value}"><strong>${
          mergeField.categoryDisplayName
        }</strong>${mergeField.displayName}</span>`;

        content = content.replace(matched, replacement);
      });
    }

    content = content.replace(/&nbsp;/gi, " ");

    return content;
  }
}
