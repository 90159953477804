import { REQUEST } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { store } from "@haywork/stores";
import { FileUtil, MimeCategory } from "@haywork/util";
import Axios, { AxiosRequestConfig } from "axios";
import classNames from "classnames";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { InputComponentProps } from "../input.component";
interface AvatarComponentProps {
  shouldBePrivate?: boolean;
  onUploadComplete?: (success: boolean) => void;
  onUploadStarted?: () => void;
  largePreview?: boolean;
}
interface AvatarComponentState {
  status: string;
  hover: boolean;
  avatarPreview: { [key: string]: string } | null;
  uploadProgress: number;
}

export class AvatarComponent extends React.Component<
  AvatarComponentProps & InputComponentProps,
  AvatarComponentState
> {
  private bearer: string;
  private uploadUrl: string;

  constructor(props) {
    super(props);

    this.state = {
      status: REQUEST.IDLE,
      hover: false,
      avatarPreview: !!this.props.value
        ? {
            backgroundImage: `url(${JSON.stringify(
              this.props.largePreview
                ? this.props.value.urlOriginal
                : this.props.value.urlPreview
            )})`,
          }
        : null,
      uploadProgress: 0,
    };

    const state = store.getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    this.bearer = state.access.token;
    this.uploadUrl = `${host}/${apiVersion}/${realEstateAgencyId}/blobs/upload`;

    this.onDragOverHandler = this.onDragOverHandler.bind(this);
    this.onDragEnterHandler = this.onDragEnterHandler.bind(this);
    this.onDragLeaveHandler = this.onDragLeaveHandler.bind(this);
    this.onDropHandler = this.onDropHandler.bind(this);
    this.onRemoveAvatarClickHandler =
      this.onRemoveAvatarClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  public render() {
    const inputAvatarStyle = classNames("input__avatar", {
      "drag-over": this.state.hover,
    });

    return (
      <div
        className={inputAvatarStyle}
        onDragOver={this.onDragOverHandler}
        onDragEnter={this.onDragEnterHandler}
        onDragLeave={this.onDragLeaveHandler}
        onDrop={this.onDropHandler}
      >
        <div className="inputavatar__trigger" />
        {this.state.avatarPreview
          ? this.renderAvatarPreview()
          : this.renderUploadControls()}
        {this.state.status === REQUEST.PENDING && (
          <div className="inputavatar__loader">
            <div className="text">
              <ResourceText resourceKey="uploading" />
            </div>
            <div className="bar">
              <div
                className="progress-bar"
                style={{ width: `${this.state.uploadProgress}%` }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AvatarComponentProps & InputComponentProps
  ) {
    if (
      !nextProps ||
      (nextProps.value &&
        this.props.value &&
        nextProps.value.urlPreview === this.props.value.urlPreview)
    )
      return;
    this.setState({
      avatarPreview: nextProps.value
        ? {
            backgroundImage: `url(${JSON.stringify(
              this.props.largePreview
                ? nextProps.value.urlOriginal
                : nextProps.value.urlPreview
            )})`,
          }
        : null,
    });
  }

  private onDragOverHandler(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  private onDragEnterHandler(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (this.state.status === REQUEST.PENDING) return;
    this.setState({ hover: true });
  }

  private onDragLeaveHandler(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    this.setState({ hover: false });
  }

  private onDropHandler(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (this.state.status === REQUEST.PENDING) return;
    this.uploadFile(event.dataTransfer.files);
  }

  private onRemoveAvatarClickHandler() {
    this.setState({ avatarPreview: null });
    this.props.onChange("");
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.status === REQUEST.PENDING) return;
    this.uploadFile(event.target.files);
  }

  private renderAvatarPreview(): React.ReactElement<HTMLDivElement> {
    const uploadRefName = uuid();

    return (
      <div className="avatarinput__preview" style={this.state.avatarPreview}>
        {this.state.status === REQUEST.ERROR && (
          <div className="avatarinput__error">
            <i className="fa fa-exclamation" />
          </div>
        )}
        {!this.state.hover && this.state.status === REQUEST.IDLE && (
          <div>
            <label htmlFor={uploadRefName} className="inputavatar__input">
              <input
                type="file"
                id={uploadRefName}
                name={uploadRefName}
                onChange={this.onChangeHandler}
                accept="image/*"
              />
              <div className="btn btn-success upload-icon">
                <i className="fal fa-fw fa-pencil" />
              </div>
            </label>
            <div
              className="btn btn-danger avatarinput__remove"
              onClick={this.onRemoveAvatarClickHandler}
            >
              <ResourceText resourceKey="removeAvatar" />
            </div>
          </div>
        )}
        <div className="avatarinput__replace">
          <ResourceText resourceKey="replaceAvatar" />
        </div>
      </div>
    );
  }

  private renderUploadControls(): React.ReactElement<HTMLDivElement> {
    const uploadRefName = uuid();

    return (
      <div className="avatarinput__controls">
        <label htmlFor={uploadRefName} className="inputavatar__input">
          <input
            type="file"
            id={uploadRefName}
            name={uploadRefName}
            onChange={this.onChangeHandler}
            accept="image/*"
          />
        </label>
        {this.props.children}
      </div>
    );
  }

  private uploadFile(files: FileList) {
    if (!files || files.length === 0) return this.setState({ hover: false });
    const file = files.item(0);

    const mime = FileUtil.getDetailedMime(file.type);
    if (mime.category !== MimeCategory.Image) {
      return this.setState({ hover: false });
    }

    const preview = URL.createObjectURL(file);
    const formData = new FormData();

    formData.append("isPrivate", this.props.shouldBePrivate ? "true" : "false");
    formData.append("upload", file);

    this.setState({
      status: REQUEST.PENDING,
      hover: false,
      avatarPreview: { backgroundImage: `url(${JSON.stringify(preview)})` },
      uploadProgress: 0,
    });

    if (this.props.onUploadStarted) this.props.onUploadStarted();

    const config: AxiosRequestConfig = {
      onUploadProgress: (event: ProgressEvent) => {
        const uploadProgress = (event.loaded / event.total) * 100;
        this.setState({ uploadProgress });
      },
      headers: {
        Authorization: `Bearer ${this.bearer}`,
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
    };

    Axios.post(this.uploadUrl, formData, config)
      .then((result) => {
        const { data } = result;
        this.setState({ uploadProgress: 0, status: REQUEST.IDLE });

        const photoBlob = FileUtil.convertUploadResponseToPhotoBlob(data);
        this.props.onChange(photoBlob);
        if (this.props.onUploadComplete) this.props.onUploadComplete(true);
      })
      .catch((err) => {
        this.setState({ uploadProgress: 0, status: REQUEST.ERROR });
        if (this.props.onUploadComplete) this.props.onUploadComplete(false);
        setTimeout(() => {
          this.setState({ status: REQUEST.IDLE, avatarPreview: null });
        }, 3500);
      });
  }
}
