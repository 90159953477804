import {
  BidSnapShot,
  BidStatus,
  BidType,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import ActionList, { Action } from "@haywork/components/ui/action-list";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import LinkedRelation from "../linked-relation";

const styles = require("./style.scss");

export enum BidAction {
  Edit = "Edit",
  Reject = "Reject",
  CounterOffer = "CounterOffer",
  Accept = "Accept",
  ShowAllBids = "ShowAllBids",
  Delete = "Delete",
}
type Props = {
  bid: BidSnapShot;
  onNavigate: (path: string) => void;
  onAction: (id: string, action: BidAction) => void;
};

export const BidComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ bid, onNavigate, onAction }) => {
      const {
        type,
        linkedRelations,
        linkedEmployee,
        bidDateTime,
        amount,
        validUntil,
        id,
        status,
        linkedBid,
      } = bid;

      const intl = useIntl();
      const actions = useRef<HTMLDivElement>(null);
      const [actionsVisible, setActionsVisible] = useState(false);

      const counterOfferLabel = useMemo(() => {
        return type === BidType.Outgoing
          ? "bid.widget.action.counterOfferExternal"
          : "bid.widget.action.counterOfferInternal";
      }, [type]);

      const relations = useMemo(() => {
        if (type === BidType.Outgoing) {
          return !!linkedEmployee
            ? [
                {
                  id: linkedEmployee.id,
                  displayName: linkedEmployee.displayName,
                  typeOfRelation: RelationType.Employee,
                },
              ]
            : [];
        } else {
          return linkedRelations || [];
        }
      }, [linkedRelations, linkedEmployee, type]);

      const direction = useMemo(() => {
        const incoming = type === BidType.Incoming;

        let arrow = "";
        let color = "";
        switch (true) {
          case status === BidStatus.Accepted: {
            arrow = "check";
            color = Colors.Success;
            break;
          }
          case status === BidStatus.Denied: {
            arrow = "times";
            color = Colors.Danger;
            break;
          }
          case incoming: {
            arrow = "arrow-left";
            color = Colors.Success;
            break;
          }
          default: {
            arrow = "arrow-right";
            color = Colors.Primary;
            break;
          }
        }

        return <Icon name={arrow} color={color} regular />;
      }, [type, status]);

      const amountLine = useMemo(() => {
        const price = intl.formatNumber(amount, {
          style: "currency",
          currency: "EUR",
        });

        if (
          !validUntil &&
          ![BidStatus.Accepted, BidStatus.Denied].includes(status)
        ) {
          const label = !!linkedBid
            ? "bid.widget.label.amount.counterOffer"
            : "bid.widget.label.amount.offer";

          return (
            <div
              styleName={classNames("amount", {
                denied: status === BidStatus.Denied,
              })}
            >
              <I18n value={label} values={{ price }} asHtml />
            </div>
          );
        }

        const date = moment(validUntil).format("DD MMMM YYYY");
        let label = "";
        switch (true) {
          case status === BidStatus.Accepted: {
            label = "bid.widget.label.amount.accepted";
            break;
          }
          case status === BidStatus.Denied: {
            label = "bid.widget.label.amount.denied";
            break;
          }
          default: {
            label = !!linkedBid
              ? "bid.widget.label.amount.counterOffer"
              : "bid.widget.label.amount.offer";
            break;
          }
        }

        return (
          <div
            styleName={classNames("amount", {
              denied: status === BidStatus.Denied,
            })}
          >
            <I18n value={label} values={{ date, price }} asHtml />
          </div>
        );
      }, [amount, validUntil, status, linkedBid]);

      const toggleActions = useCallback(() => {
        setActionsVisible(!actionsVisible);
      }, [actionsVisible]);

      const onActionClick = useCallback(
        (action: BidAction) => {
          onAction(id, action);
          setActionsVisible(false);
        },
        [id]
      );

      return (
        <div styleName="bid">
          <div styleName="bid__type">{direction}</div>
          <div styleName="bid__info">
            <div styleName="relations">
              {relations.map((relation) => (
                <LinkedRelation
                  key={relation.id}
                  relation={relation}
                  onNavigate={onNavigate}
                />
              ))}

              <span>
                <I18n
                  value="bid.widget.label.onBidDateTime"
                  values={{
                    date: moment(bidDateTime).format("DD MMMM YYYY"),
                  }}
                />
              </span>
            </div>
            {amountLine}
            <div styleName="actions">
              <div
                styleName="actions__trigger"
                ref={actions}
                onClick={toggleActions}
              >
                <span>
                  <I18n value="bid.widget.label.actions" />
                </span>
                <Icon
                  name="chevron-down"
                  regular
                  color={Colors.Primary}
                  size={14}
                />
              </div>
            </div>
          </div>

          <ActionList
            parent={actions}
            visible={actionsVisible}
            onHide={toggleActions}
          >
            <Action
              label="bid.widget.action.edit"
              icon={
                <Icon
                  light
                  name="pencil"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onActionClick(BidAction.Edit)}
            />
            {![BidStatus.Accepted, BidStatus.Denied].includes(status) && (
              <>
                <Action
                  label={counterOfferLabel}
                  icon={
                    <Icon
                      light
                      name="sign-out"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onActionClick(BidAction.CounterOffer)}
                />
                <Action
                  label={
                    type === BidType.Outgoing
                      ? "bid.widget.action.reject.outgoing"
                      : "bid.widget.action.reject"
                  }
                  icon={
                    <Icon
                      light
                      name="times"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onActionClick(BidAction.Reject)}
                />
                <Action
                  label="bid.widget.action.accept.acquisition"
                  icon={
                    <Icon
                      light
                      name="sign-in"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onActionClick(BidAction.Accept)}
                />
              </>
            )}
            <Action
              label="bid.widget.action.delete"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onActionClick(BidAction.Delete)}
            />
          </ActionList>
        </div>
      );
    }
  )
);
