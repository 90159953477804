import {
  CompanyListing,
  DimensionsType,
  EnergyCertificateType,
  FascilityType,
  Garage,
  Garden,
  MultilanguageStringType,
  OpenHouseEventType,
  Pavement,
  PointOfInterest,
  PriceUnitType,
  RealEstateProperty,
  RealEstatePropertyAreaTotals,
  RealEstatePropertyClimatControl,
  RealEstatePropertyClimatControlHeating,
  RealEstatePropertyConstruction,
  RealEstatePropertyConstructionGlazing,
  RealEstatePropertyConstructionRoof,
  RealEstatePropertyConstructionWindows,
  RealEstatePropertyCounts,
  RealEstatePropertyCurrent,
  RealEstatePropertyCurrentVacancy,
  RealEstatePropertyDimensions,
  RealEstatePropertyDimensionsSurfacesSurface,
  RealEstatePropertyEvaluations,
  RealEstatePropertyFacilities,
  RealEstatePropertyFacilitiesAccommodations,
  RealEstatePropertyFacilitiesHouse,
  RealEstatePropertyFacilitiesProfessionArea,
  RealEstatePropertyFacilitiesStorageRoom,
  RealEstatePropertyFinancials,
  RealEstatePropertyFloorsFloor,
  RealEstatePropertyFloorsFloorRoomsRoom,
  RealEstatePropertyLegal,
  RealEstatePropertyLocation,
  RealEstatePropertyOffer,
  RealEstatePropertyOfferLinkedObject,
  RealEstatePropertyProjectInfo,
  RealEstatePropertyPropertyInfo,
  RealEstatePropertySurroundings,
  RealEstatePropertyType,
  StatusType,
  Structure,
  TakeOverType,
} from "@haywork/api/mls";
import isBoolean from "lodash-es/isBoolean";
import isNumber from "lodash-es/isNumber";
import * as moment from "moment";
import { IntlShape } from "react-intl";
import { createSelector } from "reselect";

enum MlsTranslationType {
  String = "String",
  StringArray = "StringArray",
  Number = "Number",
  SquareMeters = "SquareMeters",
  CubicMeters = "CubicMeters",
  DateFull = "DateFull",
  DateSimple = "DateSimple",
  DateTime = "DateTime",
  Currency = "Currency",
  Boolean = "Boolean",
  MultilanguageString = "MultilanguageString",
  Meters = "Meters",
  Kilograms = "Kilograms",
  Percentage = "Percentage",
  Months = "Months",
  Time = "Time",
  KilogramsPerSquareMeter = "KilogramsPerSquareMeter",
}

type CreateTranlationProps = {
  type: MlsTranslationType;
  value:
    | string
    | string[]
    | number
    | Date
    | boolean
    | MultilanguageStringType[];
  prefix?: string;
  values?: Record<string, any>;
};

let intl: IntlShape;
const createTranslation = (props: CreateTranlationProps): string => {
  if (!intl) return "";
  const { type, value, prefix, values } = props;

  switch (type) {
    case MlsTranslationType.String: {
      let id = value as string;
      if (!!prefix) {
        id = `${prefix}.${value}`;
      }

      return intl.formatMessage(
        { id, defaultMessage: id }, // { id, defaultMessage: value as string },
        values
      );
    }
    case MlsTranslationType.StringArray: {
      const translations = ((value as string[]) || [])
        .filter((d) => !!d)
        .map((singleValue) => {
          let id = singleValue;
          if (!!prefix) {
            id = `${prefix}.${singleValue}`;
          }

          return intl.formatMessage(
            { id, defaultMessage: id }, // { id, defaultMessage: singleValue },
            values
          );
        });

      return translations
        .map((translation, idx) =>
          idx === 0 ? translation : translation.toLowerCase()
        )
        .join(", ");
    }
    case MlsTranslationType.Number: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return amount;
    }
    case MlsTranslationType.SquareMeters: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      return amount + "m<sup>2</sup>";
    }
    case MlsTranslationType.CubicMeters: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      return amount + "m<sup>3</sup>";
    }
    case MlsTranslationType.Boolean: {
      const id = !!value ? "yes" : "no";
      return intl.formatMessage({
        id,
        defaultMessage: id,
      });
    }
    case MlsTranslationType.MultilanguageString: {
      const ref = ((value as MultilanguageStringType[]) || []).find(
        (v) => v.language === "nl-NL"
      );
      return ref?.value;
    }
    case MlsTranslationType.Meters: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return amount + "m";
    }
    case MlsTranslationType.Kilograms: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return amount + "kg";
    }
    case MlsTranslationType.KilogramsPerSquareMeter: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return amount + "kg/m<sup>2</sup>";
    }
    case MlsTranslationType.Percentage: {
      const amount = intl.formatNumber(value as number, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return amount + "%";
    }
    case MlsTranslationType.Months: {
      const months = value as number;
      return intl.formatMessage(
        { id: "monthsPlural", defaultMessage: `${months} months` },
        { months }
      );
    }
    case MlsTranslationType.Currency: {
      const price = value as number;
      return intl.formatNumber(price, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "EUR",
      });
    }
    case MlsTranslationType.DateFull: {
      const date = value as Date;
      return moment(date).format("DD-MM-YYYY");
    }
    case MlsTranslationType.DateSimple: {
      const date = value as Date;
      return moment(date).format("DD-MM-YYYY");
    }
    case MlsTranslationType.DateTime: {
      const date = value as Date;
      return moment(date).format("DD-MM-YYYY HH:mm");
    }
    case MlsTranslationType.Time: {
      const date = value as Date;
      return moment(date).format("HH:mm");
    }
    default: {
      return (value || "").toString();
    }
  }
};

export type RealEstatePropertyValue = {
  label: string;
  value: string | RealEstatePropertyValue[];
};

export type RealEstatePropertyValueGroups = {
  name: string;
  values: RealEstatePropertyValue[];
};

const areaTotalsValues = createSelector(
  (areaTotals: RealEstatePropertyAreaTotals) => areaTotals,
  (areaTotals: RealEstatePropertyAreaTotals) => {
    if (!areaTotals) return [];
    const returnValue = [] as RealEstatePropertyValue[];

    for (const key in areaTotals) {
      const item = areaTotals[key];
      if (!!item) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: key,
            prefix: "mls.prop.areaTotals",
          }),
          value: createTranslation({
            type: [
              "expansionAreaPercentage",
              "expansionAreaTerrainPercentage",
            ].includes(key)
              ? MlsTranslationType.Percentage
              : MlsTranslationType.SquareMeters,
            value: item,
          }),
        });
      }
    }

    return returnValue;
  }
);

const climatControlValues = createSelector(
  (climatControl: RealEstatePropertyClimatControl) => climatControl,
  (climatControl: RealEstatePropertyClimatControl) => {
    if (!climatControl) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      energyCertificate,
      energyConsumptionKWH,
      energyConsumptionKWHCubicUnit,
      energyConsumptionKWHSquareUnit,
      heating,
      ventilation,
      epc,
    } = climatControl;

    addEnergyCertificate(energyCertificate, returnValue);

    if (!!energyConsumptionKWH) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "energyConsumptionKWH",
          prefix: "mls.prop.climatControl",
        }),
        value: energyConsumptionKWH.toString(),
      });
    }

    if (!!energyConsumptionKWHCubicUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "energyConsumptionKWHCubicUnit",
          prefix: "mls.prop.climatControl",
        }),
        value: createTranslation({
          type: MlsTranslationType.CubicMeters,
          value: energyConsumptionKWHCubicUnit,
        }),
      });
    }

    if (!!energyConsumptionKWHSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "energyConsumptionKWHSquareUnit",
          prefix: "mls.prop.climatControl",
        }),
        value: createTranslation({
          type: MlsTranslationType.SquareMeters,
          value: energyConsumptionKWHSquareUnit,
        }),
      });
    }

    addHeating(heating, returnValue);

    if (!!(ventilation?.ventilationMethod || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "ventilationMethod",
          prefix: "mls.prop.climatControl.ventilation",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (ventilation?.ventilationMethod || []).map((v) =>
            v.toString()
          ),
          prefix: "ventilationMethodTypeOptions",
        }),
      });
    }

    if (!!ventilation?.energySource) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "energySource",
          prefix: "mls.prop.climatControl.ventilation",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: ventilation?.energySource.toString(),
          prefix: "ventilationEnergySourceTypeOptions",
        }),
      });
    }

    if (!!epc) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "epc",
          prefix: "mls.prop.climatControl",
        }),
        value: epc.toString(),
      });
    }

    return returnValue;
  }
);

const addEnergyCertificate = (
  energyCertificate: EnergyCertificateType,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!energyCertificate) return;

  const innerValues = [] as RealEstatePropertyValue[];
  const {
    hasEnergyCertificate,
    dueDate,
    dateOfIssue,
    energyClass,
    energyIndex,
    heatingDemandClass,
    heatingDemand,
    energyPerformanceFactor,
    energyPerformanceFactorClass,
  } = energyCertificate;

  if (isBoolean(hasEnergyCertificate)) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasEnergyCertificate",
        prefix: "mls.prop.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: hasEnergyCertificate,
      }),
    });
  }

  if (moment(dateOfIssue).isValid()) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "dateOfIssue",
        prefix: "mls.prop.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.DateSimple,
        value: dateOfIssue,
      }),
    });
  }

  if (moment(dueDate).isValid()) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "dueDate",
        prefix: "mls.prop.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.DateSimple,
        value: dueDate,
      }),
    });
  }

  if (!!energyCertificate?.number) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "number",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: energyCertificate?.number,
    });
  }

  if (!!energyClass) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energyClass",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: energyClass.toString(),
        prefix: "energyClassTypeOptions",
      }),
    });
  }

  if (!!energyIndex) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energyIndex",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: energyIndex.toString(),
    });
  }

  if (!!heatingDemandClass) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingDemandClass",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: heatingDemandClass.toString(),
        prefix: "energyClassTypeOptions",
      }),
    });
  }

  if (!!heatingDemand) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingDemand",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: heatingDemand.toString(),
    });
  }

  if (!!energyPerformanceFactor) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energyPerformanceFactor",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: energyPerformanceFactor.toString(),
    });
  }

  if (!!energyPerformanceFactorClass) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energyPerformanceFactorClass",
        prefix: "mls.prop.climatControl.energyCertificate",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: energyPerformanceFactorClass.toString(),
        prefix: "energyClassTypeOptions",
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energyCertificateLabel",
        prefix: "mls.prop.climatControl",
      }),
      value: innerValues,
    });
  }
};

const addHeating = (
  heating: RealEstatePropertyClimatControlHeating,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!heating) return;

  const innerValues = [] as RealEstatePropertyValue[];
  const {
    heatingAllocation,
    energySource,
    typeOfBoiler,
    isCombiBoiler,
    yearOfManufacture,
    ownership,
    heatingMethods,
    heatingMethodsWater,
  } = heating;

  if (!!heatingAllocation) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingAllocation",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: heatingAllocation.toString(),
        prefix: "heatingAllocationTypeOptions",
      }),
    });
  }

  if (!!energySource) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "energySource",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: energySource.toString(),
        prefix: "typeOfFuelTypeOptions",
      }),
    });
  }

  if (!!typeOfBoiler) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: typeOfBoiler,
    });

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "typeOfBoiler",
          prefix: "mls.prop.climatControl.heating",
        }),
        value,
      });
    }
  }

  if (!!isCombiBoiler) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "isCombiBoiler",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: isCombiBoiler,
      }),
    });
  }

  if (!!yearOfManufacture) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "yearOfManufacture",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: yearOfManufacture.toString(),
    });
  }

  if (!!ownership) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "ownership",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: ownership.toString(),
        prefix: "ownershipTypeOptions",
      }),
    });
  }

  if (!!(heatingMethods || []).length) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingMethods",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (heatingMethods || []).map((v) => v.toString()),
        prefix: "heatingMethodTypeOptions",
      }),
    });
  }

  if (!!(heatingMethodsWater || []).length) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingMethodsWater",
        prefix: "mls.prop.climatControl.heating",
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (heatingMethodsWater || []).map((v) => v.toString()),
        prefix: "heatingMethodWaterTypeOptions",
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "heatingLabel",
        prefix: "mls.prop.climatControl",
      }),
      value: innerValues,
    });
  }
};

const constructionValues = createSelector(
  (construction: RealEstatePropertyConstruction) => construction,
  (construction: RealEstatePropertyConstruction) => {
    if (!construction) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      boarding,
      buildingMaterial,
      buildingRefurbishedInYear,
      buildStyle,
      cellarType,
      clearHeight,
      clearSpan,
      constructionComment, // *
      constructionHeight,
      constructionHeightTerrain,
      constructionPeriod, // *
      constructionOptions,
      constructionWidth,
      constructionYearFrom, // *
      constructionYearTo, // *
      doors,
      flatCategory,
      floorLoadCapacity,
      frontageSegmentation,
      glazing,
      hasAsbestus,
      hasIsolation,
      isAtticRough,
      isNewEstate,
      isolationComment,
      isolationEvaluation,
      isolationTypes,
      isPrefabricatedHouse,
      isReadyForConstruction,
      isUnderConstruction,
      orientationMainRooms,
      propertyCondition,
      reconstruction,
      renovationYear,
      roof,
      roomHeight,
      wayOfConstructionPrefabricatedHouse,
      windows,
    } = construction;

    if (!!boarding) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "boarding",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: boarding.toString(),
          prefix: "boardingTypeOptions",
        }),
      });
    }

    if (!!buildingMaterial) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingMaterial",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: buildingMaterial.toString(),
          prefix: "buildingMaterialTypeOptions",
        }),
      });
    }

    if (!!buildingRefurbishedInYear) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingRefurbishedInYear",
          prefix: "mls.prop.construction",
        }),
        value: buildingRefurbishedInYear.toString(),
      });
    }

    if (!!buildStyle) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildStyle",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: buildStyle.toString(),
          prefix: "buildStyleTypeOptions",
        }),
      });
    }

    if (!!cellarType) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "cellarType",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: cellarType.toString(),
          prefix: "cellarTypeOptions",
        }),
      });
    }

    if (!!clearHeight) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "clearHeight",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: clearHeight,
        }),
      });
    }

    if (!!clearSpan) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "clearSpan",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: clearSpan,
        }),
      });
    }

    if (!!constructionHeight) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionHeight",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: constructionHeight,
        }),
      });
    }

    if (!!constructionHeightTerrain) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionHeightTerrain",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: constructionHeightTerrain,
        }),
      });
    }

    if (!!constructionPeriod) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionPeriod",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: constructionPeriod.toString(),
          prefix: "constructionPeriodTypeOptions",
        }),
      });
    }

    if (!!(constructionComment || []).length) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: constructionComment,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "constructionComment",
            prefix: "mls.prop.construction",
          }),
          value,
        });
      }
    }

    if (!!constructionYearFrom && !!constructionYearTo) {
      if (constructionYearFrom === constructionYearTo) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "constructionYear",
            prefix: "mls.prop.construction",
          }),
          value: constructionYearFrom.toString(),
        });
      } else {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "constructionYear",
            prefix: "mls.prop.construction",
          }),
          value: createTranslation({
            type: MlsTranslationType.String,
            value: "constructionYearFromUntill",
            prefix: "mls.prop.construction",
            values: {
              from: constructionYearFrom,
              untill: constructionYearTo,
            },
          }),
        });
      }
    } else if (!!constructionYearFrom || !!constructionYearTo) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionYear",
          prefix: "mls.prop.construction",
        }),
        value: !constructionYearFrom
          ? constructionYearTo.toString()
          : constructionYearFrom.toString(),
      });
    }

    if (!!(constructionOptions || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionOptions",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (constructionOptions || []).map((v) => v.toString()),
          prefix: "propertyTypeOptions",
        }),
      });
    }

    if (!!constructionWidth) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionWidth",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: constructionWidth,
        }),
      });
    }

    if (!!doors) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "doors",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: doors.toString(),
          prefix: "doorsTypeOptions",
        }),
      });
    }

    if (!!flatCategory) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "flatCategory",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: flatCategory.toString(),
          prefix: "flatCategoryTypeOptions",
        }),
      });
    }

    if (!!floorLoadCapacity) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "floorLoadCapacity",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.KilogramsPerSquareMeter,
          value: floorLoadCapacity,
        }),
      });
    }

    if (!!frontageSegmentation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "frontageSegmentation",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: frontageSegmentation.toString(),
          prefix: "frontageSegmentationTypeOptions",
        }),
      });
    }

    addGlazing(glazing, returnValue);

    if (isBoolean(hasAsbestus)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasAsbestus",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasAsbestus,
        }),
      });
    }

    if (isBoolean(hasIsolation)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasIsolation",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasIsolation,
        }),
      });
    }

    if (isBoolean(isAtticRough)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isAtticRough",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isAtticRough,
        }),
      });
    }

    if (isBoolean(isNewEstate)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isNewEstate",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isNewEstate,
        }),
      });
    }

    if (!!isolationComment) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: isolationComment,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "isolationComment",
            prefix: "mls.prop.construction",
          }),
          value,
        });
      }
    }

    if (!!isolationEvaluation) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: isolationEvaluation,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "isolationEvaluation",
            prefix: "mls.prop.construction",
          }),
          value,
        });
      }
    }

    if (!!(isolationTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isolationTypes",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (isolationTypes || []).map((v) => v.toString()),
          prefix: "isolationTypeOptions",
        }),
      });
    }

    if (isBoolean(isPrefabricatedHouse)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isPrefabricatedHouse",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isPrefabricatedHouse,
        }),
      });
    }

    if (isBoolean(isReadyForConstruction)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isReadyForConstruction",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isReadyForConstruction,
        }),
      });
    }

    if (isBoolean(isUnderConstruction)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isUnderConstruction",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isUnderConstruction,
        }),
      });
    }

    if (!!orientationMainRooms) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "orientationMainRooms",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: orientationMainRooms.toString(),
          prefix: "orientationTypeOptions",
        }),
      });
    }

    if (!!propertyCondition) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "propertyCondition",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: propertyCondition.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!reconstruction) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "reconstruction",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: reconstruction.toString(),
          prefix: "reconstructionTypeOptions",
        }),
      });
    }

    if (!!renovationYear) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "renovationYear",
          prefix: "mls.prop.construction",
        }),
        value: renovationYear.toString(),
      });
    }

    if (!!roomHeight) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "roomHeight",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: roomHeight,
        }),
      });
    }

    addRoof(roof, returnValue);

    if (!!wayOfConstructionPrefabricatedHouse) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "wayOfConstructionPrefabricatedHouse",
          prefix: "mls.prop.construction",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: wayOfConstructionPrefabricatedHouse.toString(),
          prefix: "wayOfConstructionPrefabricatedHouseTypeOptions",
        }),
      });
    }

    addWindows(windows, returnValue);

    return returnValue;
  }
);

const addWindows = (
  windows: RealEstatePropertyConstructionWindows,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!windows) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { type, windowComments, windowEvaluation } = windows;

  if (!!(windowEvaluation || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: windowEvaluation,
    });

    if (!!type) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "type",
          prefix: "mls.prop.construction.windows",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: type.toString(),
          prefix: "windowTypeOptions",
        }),
      });
    }

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "windowEvaluation",
          prefix: "mls.prop.construction.windows",
        }),
        value,
      });
    }
  }

  if (!!(windowComments || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: windowComments,
    });

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "windowComments",
          prefix: "mls.prop.construction.windows",
        }),
        value,
      });
    }
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "windowsLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const addRoof = (
  roof: RealEstatePropertyConstructionRoof,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!roof) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { roofComments, roofEvaluation, roofMaterials, roofType } = roof;

  if (!!roofType) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "roofType",
        prefix: "mls.prop.construction.roof",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: roofType.toString(),
        prefix: "roofTypeOptions",
      }),
    });
  }

  if (!!(roofMaterials || []).length) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "roofMaterials",
        prefix: "mls.prop.construction.roof",
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (roofMaterials || []).map((v) => v.toString()),
        prefix: "roofMaterialTypeOptions",
      }),
    });
  }

  if (!!(roofComments || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: roofComments,
    });

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "roofComments",
          prefix: "mls.prop.construction.roof",
        }),
        value,
      });
    }
  }

  if (!!(roofEvaluation || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: roofEvaluation,
    });

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "roofEvaluation",
          prefix: "mls.prop.construction.roof",
        }),
        value,
      });
    }
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "roofLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const addGlazing = (
  glazing: RealEstatePropertyConstructionGlazing,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!glazing) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { glazingComments, glazingEvaluation, glazingType } = glazing;

  if (!!(glazingComments || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: glazingComments,
    });

    if (!!glazingType) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "glazingType",
          prefix: "mls.prop.construction.glazing",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: glazingType.toString(),
          prefix: "glazingTypeOptions",
        }),
      });
    }

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "glazingComments",
          prefix: "mls.prop.construction.glazing",
        }),
        value,
      });
    }
  }

  if (!!(glazingEvaluation || []).length) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: glazingEvaluation,
    });

    if (!!value) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "glazingEvaluation",
          prefix: "mls.prop.construction.glazing",
        }),
        value,
      });
    }
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "glazingLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const countsValues = createSelector(
  (counts: RealEstatePropertyCounts) => counts,
  (counts: RealEstatePropertyCounts) => {
    if (!counts) return [];
    const returnValue = [] as RealEstatePropertyValue[];

    for (const key in counts) {
      const item = counts[key];
      if (!!item) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: key,
            prefix: "mls.prop.counts",
          }),
          value: item.toString(),
        });
      }
    }

    return returnValue;
  }
);

const currentValues = createSelector(
  (current: RealEstatePropertyCurrent) => current,
  (current: RealEstatePropertyCurrent) => {
    if (!current) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      animalProductionRights,
      brancheLimitation,
      cateringAllowed,
      currentUsageDescription,
      currentDestinationDescription,
      forTakeOverItems,
      isPartiallyRented,
      hasAnnualPitches,
      isTurnkey,
      isVacated,
      limitations,
      partialMutationsParkingplaces,
      partialMutationsParkingplacesCovered,
      partialMutationsArea,
      pavement,
      percentageRented,
      propensityInPercentage,
      publicOrientedServices,
      remainingTermOfAContract,
      retail,
      returnOfServices,
      revenuePerYear,
      sectorTypes,
      vacancy,
    } = current;

    if (!!animalProductionRights) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "animalProductionRights",
          prefix: "mls.prop.current.current",
        }),
        value: animalProductionRights.toString(),
      });
    }

    addAssociationOfOwners(current, returnValue);

    if (!!brancheLimitation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "brancheLimitation",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: brancheLimitation,
        }),
      });
    }

    if (!!cateringAllowed) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "cateringAllowed",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: cateringAllowed,
        }),
      });
    }

    if (!!currentUsageDescription) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: currentUsageDescription,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "currentUsageDescription",
            prefix: "mls.prop.current",
          }),
          value,
        });
      }
    }

    if (!!currentDestinationDescription) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: currentDestinationDescription,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "currentDestinationDescription",
            prefix: "mls.prop.current",
          }),
          value,
        });
      }
    }

    addForTakeOverItems(forTakeOverItems, returnValue);

    if (!!isPartiallyRented) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isPartiallyRented",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isPartiallyRented,
        }),
      });
    }

    if (!!hasAnnualPitches) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasAnnualPitches",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasAnnualPitches,
        }),
      });
    }

    if (!!isTurnkey) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isTurnkey",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isTurnkey,
        }),
      });
    }

    if (!!isVacated) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isVacated",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isVacated,
        }),
      });
    }

    if (!!(limitations || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "limitations",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (limitations || []).map((v) => v.toString()),
          prefix: "limitationTypeOptions",
        }),
      });
    }

    // partialMutationsParkingplaces?: PartialMutations[] | null; *
    // partialMutationsParkingplacesCovered?: PartialMutations[] | null; *
    // partialMutationsArea?: PartialMutations[] | null; *
    // pavement?: Pavement | null; *

    if (!!percentageRented) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "percentageRented",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Percentage,
          value: percentageRented,
        }),
      });
    }

    if (!!propensityInPercentage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "propensityInPercentage",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Percentage,
          value: propensityInPercentage,
        }),
      });
    }

    if (!!publicOrientedServices) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "publicOrientedServices",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: publicOrientedServices,
        }),
      });
    }

    if (!!remainingTermOfAContract) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "remainingTermOfAContract",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Months,
          value: remainingTermOfAContract,
        }),
      });
    }

    if (!!retail) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "retail",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: retail,
        }),
      });
    }

    if (!!returnOfServices) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "returnOfServices",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: returnOfServices,
        }),
      });
    }

    if (!!revenuePerYear) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "revenuePerYear",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: revenuePerYear,
        }),
      });
    }

    if (!!(sectorTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "sectorTypes",
          prefix: "mls.prop.current",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (sectorTypes || []).map((v) => v.toString()),
          prefix: "sectorTypeOptions",
        }),
      });
    }

    addPavement(pavement, returnValue);
    addVacancy(vacancy, returnValue);

    return returnValue;
  }
);

const addVacancy = (
  vacancy: RealEstatePropertyCurrentVacancy,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!vacancy) return;
  const innerValues = [] as RealEstatePropertyValue[];

  displayDimensionsType(vacancy, innerValues, "dimensions");

  if (!!vacancy.rentalValue) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "rentalValue",
        prefix: "mls.prop.current.vacancy",
      }),
      value: createTranslation({
        type: MlsTranslationType.Currency,
        value: vacancy.rentalValue,
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "label",
        prefix: "mls.prop.construction.vacancy",
      }),
      value: innerValues,
    });
  }
};

const addPavement = (
  pavement: Pavement,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!pavement) return;
  const innerValues = [] as RealEstatePropertyValue[];

  if (!!pavement.percentage) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "percentage",
        prefix: "mls.prop.construction.pavement",
      }),
      value: createTranslation({
        type: MlsTranslationType.Percentage,
        value: pavement.percentage,
      }),
    });
  }

  if (!!pavement.type) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "type",
        prefix: "mls.prop.construction.pavement",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: pavement.type,
        prefix: "pavementTypeOptions",
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "label",
        prefix: "mls.prop.construction.pavement",
      }),
      value: innerValues,
    });
  }
};

const addForTakeOverItems = (
  takeOverTypes: TakeOverType[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!takeOverTypes.length) return;

  (takeOverTypes || []).map((takeOverType) => {
    const innerValues = [] as RealEstatePropertyValue[];

    const label = !!takeOverType.item
      ? createTranslation({
          type: MlsTranslationType.String,
          value: takeOverType.item.toString(),
          prefix: "takeOverItemTypeOptions",
        })
      : createTranslation({
          type: MlsTranslationType.String,
          value: "itemLabel",
          prefix: "mls.prop.construction.takeOver",
        });

    if (!!takeOverType.price) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "price",
          prefix: "mls.prop.construction.takeOver",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: takeOverType.price,
        }),
      });
    }

    if (!!takeOverType.specification) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "specification",
          prefix: "mls.prop.construction.takeOver",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: takeOverType.specification,
          prefix: "takeOverSpecificationTypeOptions",
        }),
      });
    }

    if (!!innerValues.length) {
      returnValue.push({
        label,
        value: innerValues,
      });
    }
  });
};

const addAssociationOfOwners = (
  current: RealEstatePropertyCurrent,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!current) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const {
    associationOfOwnersHasAnnualMeeting,
    associationOfOwnersHasHomeInsurance,
    associationOfOwnersHasLongTermMaintenancePlan,
    associationOfOwnersHasMaintenanceForecast,
    associationOfOwnersHasPeriodicContribution,
    associationOfOwnersHasReserveFund,
    associationOfOwnersIsRegisteredAtChamberOfCommerce,
    checkListAssociationOfOwnersAvailable,
  } = current;

  if (!!associationOfOwnersHasAnnualMeeting) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasAnnualMeeting",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasAnnualMeeting,
      }),
    });
  }

  if (!!associationOfOwnersHasPeriodicContribution) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasPeriodicContribution",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasPeriodicContribution,
      }),
    });
  }

  if (!!associationOfOwnersHasReserveFund) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasReserveFund",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasReserveFund,
      }),
    });
  }

  if (!!associationOfOwnersHasMaintenanceForecast) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasMaintenanceForecast",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasMaintenanceForecast,
      }),
    });
  }

  if (!!associationOfOwnersHasLongTermMaintenancePlan) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasLongTermMaintenancePlan",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasLongTermMaintenancePlan,
      }),
    });
  }

  if (!!associationOfOwnersHasHomeInsurance) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasHomeInsurance",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersHasHomeInsurance,
      }),
    });
  }

  if (!!associationOfOwnersIsRegisteredAtChamberOfCommerce) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersIsRegisteredAtChamberOfCommerce",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: associationOfOwnersIsRegisteredAtChamberOfCommerce,
      }),
    });
  }

  if (!!checkListAssociationOfOwnersAvailable) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "checkListAssociationOfOwnersAvailable",
        prefix: "mls.prop.current",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: checkListAssociationOfOwnersAvailable,
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "associationOfOwnersHasAnnualMeetingLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const evaluationsValues = createSelector(
  (evaluations: RealEstatePropertyEvaluations) => evaluations,
  (evaluations: RealEstatePropertyEvaluations) => {
    if (!evaluations) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      buildingCondition,
      certifications,
      communalAreas,
      generalState,
      groundUsage,
      hasGreenLabel,
      hasGroundPollution,
      hasLandUsePlan,
      hasLogisticFunction,
      hasMonumentalProtection,
      isAccesible,
      isChildFriendly,
      isProtected,
      isQualifiedForPeopleWithDisabilities,
      isQualifiedForFamilies,
      isQualifiedForSeniors,
      isQualifiedForStudents,
      isReadyForOccupancy,
      maintenanceInside,
      maintenanceOutside,
      needsRenovation,
      partialOfficeUsePossible,
      partialOrdinationPossible,
      petsAllowed,
      redesignationPossible,
      securityMeasures,
      suitableForBranches,
      suitableForCountOfPeople,
      forSpecialTargetAudience,
      comfortQuality,
      polutionTypes,
      isSKALCertified,
    } = evaluations;

    if (!!buildingCondition) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingCondition",
          prefix: "mls.prop.evaluations.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: buildingCondition.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!(certifications || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "certifications",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (certifications || []).map((v) => v.toString()),
          prefix: "certificateTypeOptions",
        }),
      });
    }

    if (!!(communalAreas || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "communalAreas",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (communalAreas || []).map((v) => v.toString()),
          prefix: "communalAreaTypeOptions",
        }),
      });
    }

    if (!!generalState) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "generalState",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: generalState.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!groundUsage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "groundUsage",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: groundUsage.toString(),
          prefix: "groundUsageTypeOptions",
        }),
      });
    }

    if (!!hasGreenLabel) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasGreenLabel",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasGreenLabel,
        }),
      });
    }

    if (!!hasGroundPollution) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasGroundPollution",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasGroundPollution,
        }),
      });
    }

    if (!!hasLandUsePlan) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasLandUsePlan",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasLandUsePlan,
        }),
      });
    }

    if (!!hasLogisticFunction) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasLogisticFunction",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasLogisticFunction,
        }),
      });
    }

    if (!!hasMonumentalProtection) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasMonumentalProtection",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasMonumentalProtection,
        }),
      });
    }

    if (!!isAccesible) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isAccesible",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isAccesible,
        }),
      });
    }

    if (!!isChildFriendly) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isChildFriendly",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isChildFriendly,
        }),
      });
    }

    if (!!isProtected) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isProtected",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isProtected,
        }),
      });
    }

    if (!!isQualifiedForPeopleWithDisabilities) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isQualifiedForPeopleWithDisabilities",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isQualifiedForPeopleWithDisabilities,
        }),
      });
    }

    if (!!isQualifiedForFamilies) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isQualifiedForFamilies",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isQualifiedForFamilies,
        }),
      });
    }

    if (!!isQualifiedForStudents) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isQualifiedForStudents",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isQualifiedForStudents,
        }),
      });
    }

    if (!!isReadyForOccupancy) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isReadyForOccupancy",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isReadyForOccupancy,
        }),
      });
    }

    if (!!isQualifiedForSeniors) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isQualifiedForSeniors",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isQualifiedForSeniors,
        }),
      });
    }

    if (!!maintenanceInside) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "maintenanceInside",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: maintenanceInside.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!maintenanceOutside) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "maintenanceOutside",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: maintenanceOutside.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!needsRenovation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "needsRenovation",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: needsRenovation,
        }),
      });
    }

    if (!!partialOfficeUsePossible) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "partialOfficeUsePossible",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: partialOfficeUsePossible,
        }),
      });
    }

    if (!!partialOrdinationPossible) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "partialOrdinationPossible",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: partialOrdinationPossible,
        }),
      });
    }

    if (!!petsAllowed) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "petsAllowed",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: petsAllowed,
        }),
      });
    }

    if (!!redesignationPossible) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "redesignationPossible",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: redesignationPossible,
        }),
      });
    }

    if (!!(securityMeasures || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "securityMeasures",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (securityMeasures || []).map((v) => v.toString()),
          prefix: "securityMeasureTypeOptions",
        }),
      });
    }

    if (!!suitableForBranches) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: suitableForBranches,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "suitableForBranches",
            prefix: "mls.prop.evaluations",
          }),
          value,
        });
      }
    }

    if (!!suitableForCountOfPeople) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "suitableForCountOfPeople",
          prefix: "mls.prop.evaluations",
        }),
        value: suitableForCountOfPeople.toString(),
      });
    }

    if (!!forSpecialTargetAudience) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "forSpecialTargetAudience",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: forSpecialTargetAudience,
        }),
      });
    }

    if (!!comfortQuality) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "comfortQuality",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: comfortQuality.toString(),
          prefix: "comfortQualityTypeOptions",
        }),
      });
    }

    if (!!(polutionTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "polutionTypes",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (polutionTypes || []).map((v) => v.toString()),
          prefix: "pollutionTypeOptions",
        }),
      });
    }

    if (!!isSKALCertified) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isSKALCertified",
          prefix: "mls.prop.evaluations",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isSKALCertified,
        }),
      });
    }

    return returnValue;
  }
);

const facilitiesValues = createSelector(
  (facilities: RealEstatePropertyFacilities) => facilities,
  (facilities: RealEstatePropertyFacilities) => {
    if (!facilities) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      accommodations,
      airco,
      airTreatments,
      airTreatmentsOffice,
      alarm,
      attick,
      backYard,
      balcony,
      bathroom,
      bedroom,
      boiler,
      buildingHasElevator,
      builtInLight,
      cable_TV,
      cctv,
      cellar,
      companyListings,
      digi_TV,
      diningRoom,
      drainage,
      electricity,
      electricityConnection,
      elevator,
      entry,
      fencing,
      firePlace,
      firePlaceType,
      flueTube,
      footbridge,
      frenchBalcony,
      fibreOpticCable,
      furniture,
      furnitureType,
      garage,
      garden,
      gasConnection,
      gastronomy,
      generalConnectionComments,
      greenHouse,
      hasRoomSeparateEntrance,
      heating,
      homeAutomation,
      horseTroughIndoor,
      horseTroughOutdoor,
      horseTroughDrainage,
      horseWalker,
      house,
      industrialFacilities,
      installations,
      intercom,
      internetConnection,
      jacuzzi,
      kitchen,
      kitchenType,
      leisureFacilities,
      livingRoom,
      localSewer,
      localWasteWaterSystem,
      manureStorage,
      milkingSystemTypes,
      office,
      officeFacilities,
      officeFacilitiesOffice,
      openPorch,
      outdoorAreaFacilities,
      outdoorAwnings,
      parkingPlace,
      parkingPlaceOwnership,
      parkingTypes,
      phoneLine,
      poultryHousing,
      professionArea,
      professionRoom,
      rollerBlinds,
      retail,
      sanitary,
      sanitationLock,
      satelliteDish,
      sauna,
      sewerConnection,
      showroom,
      skyLight,
      slidingDoors,
      socialPropertyFacilities,
      solarBlinds,
      solarium,
      solarPanels,
      steamCabin,
      stock,
      storageRoom,
      structures,
      sunCollectors,
      swimmingPool,
      swimmingPoolAttributes,
      tank,
      terrace,
      terrain,
      toilet,
      toiletSeparated,
      upholstered,
      upholsteredType,
      utilityRoom,
      ventilation,
      washingMachine,
      washPlace,
      waterConnection,
      welnessArea,
      windmill,
    } = facilities;

    addAccommodations(accommodations, returnValue);

    if (!!(airTreatments || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "airTreatments",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (airTreatments || []).map((v) => v.toString()),
          prefix: "airTreatmentTypeOptions",
        }),
      });
    }

    if (!!(airTreatmentsOffice || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "airTreatmentsOffice",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (airTreatmentsOffice || []).map((v) => v.toString()),
          prefix: "airTreatmentTypeOptions",
        }),
      });
    }

    displayBasicFacilities(airco, returnValue, "facilities.airco");
    displayBasicFacilities(alarm, returnValue, "facilities.alarm");
    displayBasicFacilities(attick, returnValue, "facilities.attick");
    displayBasicFacilities(backYard, returnValue, "facilities.backYard");
    displayBasicFacilities(balcony, returnValue, "facilities.balcony");
    displayBasicFacilities(bathroom, returnValue, "facilities.bathroom");
    displayBasicFacilities(bedroom, returnValue, "facilities.bedroom");
    displayBasicFacilities(boiler, returnValue, "facilities.boiler");

    if (!!buildingHasElevator) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingHasElevator",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: buildingHasElevator,
        }),
      });
    }

    displayBasicFacilities(
      builtInLight,
      returnValue,
      "facilities.builtInLight"
    );
    displayBasicFacilities(cable_TV, returnValue, "facilities.cable_TV");
    displayBasicFacilities(cctv, returnValue, "facilities.cctv");
    displayBasicFacilities(cellar, returnValue, "facilities.cellar");
    displayCompanyListings(companyListings, returnValue);
    displayBasicFacilities(digi_TV, returnValue, "facilities.digi_TV");
    displayBasicFacilities(diningRoom, returnValue, "facilities.diningRoom");
    displayBasicFacilities(drainage, returnValue, "facilities.drainage");
    displayBasicFacilities(electricity, returnValue, "facilities.electricity");
    displayBasicFacilities(
      electricityConnection,
      returnValue,
      "facilities.electricityConnection"
    );
    displayBasicFacilities(elevator, returnValue, "facilities.elevator");
    displayBasicFacilities(entry, returnValue, "facilities.entry");

    if (!!(fencing || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "fencing",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (fencing || []).map((v) => v.toString()),
          prefix: "fencingTypeOptions",
        }),
      });
    }

    displayBasicFacilities(firePlace, returnValue, "facilities.firePlace");

    if (!!firePlaceType) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "firePlaceType",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: firePlaceType.toString(),
          prefix: "firePlaceTypeOptions",
        }),
      });
    }

    displayBasicFacilities(flueTube, returnValue, "facilities.flueTube");
    displayBasicFacilities(footbridge, returnValue, "facilities.footbridge");
    displayBasicFacilities(
      frenchBalcony,
      returnValue,
      "facilities.frenchBalcony"
    );
    displayBasicFacilities(
      fibreOpticCable,
      returnValue,
      "facilities.fibreOpticCable"
    );
    displayBasicFacilities(furniture, returnValue, "facilities.furniture");

    if (!!furnitureType) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "furnitureType",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: furnitureType.toString(),
          prefix: "furnitureTypeOptions",
        }),
      });
    }

    displayBasicFacilities(garage, returnValue, "facilities.garage");
    displayBasicFacilities(garden, returnValue, "facilities.garden");
    displayBasicFacilities(
      gasConnection,
      returnValue,
      "facilities.gasConnection"
    );
    displayBasicFacilities(gastronomy, returnValue, "facilities.gastronomy");

    if (!!generalConnectionComments) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: generalConnectionComments,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "generalConnectionComments",
            prefix: "mls.prop.facilities",
          }),
          value,
        });
      }
    }

    displayBasicFacilities(greenHouse, returnValue, "facilities.greenHouse");

    if (!!hasRoomSeparateEntrance) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasRoomSeparateEntrance",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasRoomSeparateEntrance,
        }),
      });
    }

    displayBasicFacilities(heating, returnValue, "facilities.heating");
    displayBasicFacilities(
      homeAutomation,
      returnValue,
      "facilities.homeAutomation"
    );
    displayBasicFacilities(
      horseTroughIndoor,
      returnValue,
      "facilities.horseTroughIndoor"
    );
    displayBasicFacilities(
      horseTroughOutdoor,
      returnValue,
      "facilities.horseTroughOutdoor"
    );
    displayBasicFacilities(
      horseTroughDrainage,
      returnValue,
      "facilities.horseTroughDrainage"
    );
    displayBasicFacilities(horseWalker, returnValue, "facilities.horseWalker");

    addHouse(house, returnValue);

    if (!!(industrialFacilities || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "industrialFacilities",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (industrialFacilities || []).map((v) => v.toString()),
          prefix: "industrialFacilityTypeOptions",
        }),
      });
    }

    if (!!installations) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: installations,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "installations",
            prefix: "mls.prop.facilities",
          }),
          value,
        });
      }
    }

    displayBasicFacilities(intercom, returnValue, "facilities.intercom");
    displayBasicFacilities(
      internetConnection,
      returnValue,
      "facilities.internetConnection"
    );
    displayBasicFacilities(jacuzzi, returnValue, "facilities.jacuzzi");
    displayBasicFacilities(kitchen, returnValue, "facilities.kitchen");

    if (!!kitchenType) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "kitchenType",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: kitchenType.toString(),
          prefix: "kitchenTypeOptions",
        }),
      });
    }

    if (!!(leisureFacilities || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "leisureFacilities",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (leisureFacilities || []).map((v) => v.toString()),
          prefix: "leisureFacilityTypeOptions",
        }),
      });
    }

    displayBasicFacilities(livingRoom, returnValue, "facilities.livingRoom");
    displayBasicFacilities(localSewer, returnValue, "facilities.localSewer");
    displayBasicFacilities(
      localWasteWaterSystem,
      returnValue,
      "facilities.localWasteWaterSystem"
    );

    (manureStorage || []).map((storage) => {
      if (!!storage?.volume) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "volume",
            prefix: "mls.prop.facilities.manureStorage",
          }),
          value: storage?.volume.toString(),
        });
      }
    });

    if (!!(milkingSystemTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "milkingSystemTypes",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (milkingSystemTypes || []).map((v) => v.toString()),
          prefix: "milkingSystemTypeOptions",
        }),
      });
    }

    displayBasicFacilities(office, returnValue, "facilities.office");

    if (!!(officeFacilities || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "officeFacilities",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (officeFacilities || []).map((v) => v.toString()),
          prefix: "officeFacilityTypeOptions",
        }),
      });
    }

    if (!!(officeFacilitiesOffice || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "officeFacilitiesOffice",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (officeFacilitiesOffice || []).map((v) => v.toString()),
          prefix: "officeFacilityTypeOptions",
        }),
      });
    }

    displayBasicFacilities(openPorch, returnValue, "facilities.openPorch");

    if (!!(outdoorAreaFacilities || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "outdoorAreaFacilities",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (outdoorAreaFacilities || []).map((v) => v.toString()),
          prefix: "outdoorAreaFacilityTypeOptions",
        }),
      });
    }

    displayBasicFacilities(
      outdoorAwnings,
      returnValue,
      "facilities.outdoorAwnings"
    );
    displayBasicFacilities(
      parkingPlace,
      returnValue,
      "facilities.parkingPlace"
    );

    if (!!parkingPlaceOwnership) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "parkingPlaceOwnership",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: parkingPlaceOwnership.toString(),
          prefix: "ownershipOptionTypeOptions",
        }),
      });
    }

    if (!!(parkingTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "parkingTypes",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (parkingTypes || []).map((v) => v.toString()),
          prefix: "parkingTypeOptions",
        }),
      });
    }

    displayBasicFacilities(phoneLine, returnValue, "facilities.phoneLine");

    if (!!(poultryHousing || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "poultryHousing",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (poultryHousing || []).map((v) => v.toString()),
          prefix: "poultryHousingTypeOptions",
        }),
      });
    }

    addProffessionArea(professionArea, returnValue);

    displayBasicFacilities(
      professionRoom,
      returnValue,
      "facilities.professionRoom"
    );
    displayBasicFacilities(
      rollerBlinds,
      returnValue,
      "facilities.rollerBlinds"
    );
    displayBasicFacilities(retail, returnValue, "facilities.retail");
    displayBasicFacilities(sanitary, returnValue, "facilities.sanitary");
    displayBasicFacilities(
      sanitationLock,
      returnValue,
      "facilities.sanitationLock"
    );
    displayBasicFacilities(
      satelliteDish,
      returnValue,
      "facilities.satelliteDish"
    );
    displayBasicFacilities(sauna, returnValue, "facilities.sauna");
    displayBasicFacilities(
      sewerConnection,
      returnValue,
      "facilities.sewerConnection"
    );
    displayBasicFacilities(showroom, returnValue, "facilities.showroom");
    displayBasicFacilities(skyLight, returnValue, "facilities.skyLight");
    displayBasicFacilities(
      slidingDoors,
      returnValue,
      "facilities.slidingDoors"
    );

    if (!!(socialPropertyFacilities || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "socialPropertyFacilities",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (socialPropertyFacilities || []).map((v) => v.toString()),
          prefix: "socialPropertyFacilityTypeOptions",
        }),
      });
    }

    displayBasicFacilities(solarBlinds, returnValue, "facilities.solarBlinds");
    displayBasicFacilities(solarium, returnValue, "facilities.solarium");
    displayBasicFacilities(solarPanels, returnValue, "facilities.solarPanels");
    displayBasicFacilities(steamCabin, returnValue, "facilities.steamCabin");
    displayBasicFacilities(stock, returnValue, "facilities.stock");

    addStorageRoom(storageRoom, returnValue);
    addStructures(structures, returnValue);

    displayBasicFacilities(
      sunCollectors,
      returnValue,
      "facilities.sunCollectors"
    );
    displayBasicFacilities(
      swimmingPool,
      returnValue,
      "facilities.swimmingPool"
    );

    if (!!(swimmingPoolAttributes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "swimmingPoolAttributes",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (swimmingPoolAttributes || []).map((v) => v.toString()),
          prefix: "swimmingPoolAttributeTypeOptions",
        }),
      });
    }

    displayBasicFacilities(tank, returnValue, "facilities.tank");
    displayBasicFacilities(terrace, returnValue, "facilities.terrace");
    displayBasicFacilities(terrain, returnValue, "facilities.terrain");
    displayBasicFacilities(toilet, returnValue, "facilities.toilet");
    displayBasicFacilities(
      toiletSeparated,
      returnValue,
      "facilities.toiletSeparated"
    );
    displayBasicFacilities(upholstered, returnValue, "facilities.upholstered");

    if (!!upholsteredType) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "upholsteredType",
          prefix: "mls.prop.facilities",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: upholsteredType.toString(),
          prefix: "upholsteredTypeOptions",
        }),
      });
    }

    displayBasicFacilities(utilityRoom, returnValue, "facilities.utilityRoom");
    displayBasicFacilities(ventilation, returnValue, "facilities.ventilation");
    displayBasicFacilities(
      washingMachine,
      returnValue,
      "facilities.washingMachine"
    );
    displayBasicFacilities(washPlace, returnValue, "facilities.washPlace");
    displayBasicFacilities(
      waterConnection,
      returnValue,
      "facilities.waterConnection"
    );
    displayBasicFacilities(welnessArea, returnValue, "facilities.welnessArea");
    displayBasicFacilities(windmill, returnValue, "facilities.windmill");

    return returnValue;
  }
);

const addProffessionArea = (
  professionArea: RealEstatePropertyFacilitiesProfessionArea,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!professionArea) return;
  const innerValues = [] as RealEstatePropertyValue[];

  displayBasicFacilities(
    professionArea,
    innerValues,
    "facilities.professionArea"
  );
  if (!!professionArea?.capacity) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "capacity",
        prefix: "mls.prop.facilities.professionArea",
      }),
      value: professionArea?.capacity.toString(),
    });
  }

  returnValue.push({
    label: createTranslation({
      type: MlsTranslationType.String,
      value: "label",
      prefix: "mls.prop.facilities.professionArea",
    }),
    value: innerValues,
  });
};

const addStructures = (
  structures: Structure[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!structures.length) return;

  (structures || []).map((structure) => {
    const innerValues = [] as RealEstatePropertyValue[];
    const { area, constructionYear, description, hasCanteen, structureType } =
      structure;
    const label = !!structureType
      ? createTranslation({
          type: MlsTranslationType.String,
          value: structureType.toString(),
          prefix: "structureTypeOptions",
        })
      : createTranslation({
          type: MlsTranslationType.String,
          value: "label",
          prefix: "mls.prop.facilities.structure",
        });

    if (!!area) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "area",
          prefix: "mls.prop.facilities.structure",
        }),
        value: createTranslation({
          type: MlsTranslationType.SquareMeters,
          value: area,
        }),
      });
    }

    if (!!constructionYear) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionYear",
          prefix: "mls.prop.facilities.structure",
        }),
        value: constructionYear.toString(),
      });
    }

    if (!!description) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "description",
          prefix: "mls.prop.facilities.structure",
        }),
        value: description,
      });
    }

    if (!!hasCanteen) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasCanteen",
          prefix: "mls.prop.facilities.structure",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasCanteen,
        }),
      });
    }

    if (!!innerValues.length) {
      returnValue.push({
        label,
        value: innerValues,
      });
    }
  });
};

const addStorageRoom = (
  storageRoom: RealEstatePropertyFacilitiesStorageRoom,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!storageRoom) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { attributes, available, comments, evaluation, isolationTypes, type } =
    storageRoom;

  displayBasicFacilities(storageRoom, innerValues, "facilities.storageRoom");

  if (!!attributes?.hasAttic) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasAttic",
        prefix: "mls.prop.facilities.storageRoom.attributes",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: attributes?.hasAttic,
      }),
    });
  }

  if (!!attributes?.hasElectricity) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasElectricity",
        prefix: "mls.prop.facilities.storageRoom.attributes",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: attributes?.hasElectricity,
      }),
    });
  }

  if (!!attributes?.hasHeating) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasHeating",
        prefix: "mls.prop.facilities.storageRoom.attributes",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: attributes?.hasHeating,
      }),
    });
  }

  if (!!attributes?.hasWater) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasWater",
        prefix: "mls.prop.facilities.storageRoom.attributes",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: attributes?.hasWater,
      }),
    });
  }

  if (!!(isolationTypes || []).length) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "isolationTypes",
        prefix: "mls.prop.facilities.storageRoom",
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (storageRoom.isolationTypes || []).map((v) => v.toString()),
        prefix: "isolationTypeOptions",
      }),
    });
  }

  if (!!type) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "type",
        prefix: "mls.prop.facilities.storageRoom",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: type.toString(),
        prefix: "storageRoomTypeOptions",
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "storageRoomLabel",
        prefix: "mls.prop.facilities",
      }),
      value: innerValues,
    });
  }
};

const addHouse = (
  house: RealEstatePropertyFacilitiesHouse,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!house) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { countOfVacantUnits, effectiveArea, revenuePerYear, typeBuilding } =
    house;

  displayBasicFacilities(house, innerValues, "facilities.house");

  if (!!countOfVacantUnits) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "countOfVacantUnits",
        prefix: "mls.prop.facilities.house",
      }),
      value: countOfVacantUnits.toString(),
    });
  }

  if (!!effectiveArea) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "effectiveArea",
        prefix: "mls.prop.facilities.house",
      }),
      value: createTranslation({
        type: MlsTranslationType.SquareMeters,
        value: effectiveArea,
      }),
    });
  }

  if (!!revenuePerYear) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "revenuePerYear",
        prefix: "mls.prop.facilities.house",
      }),
      value: createTranslation({
        type: MlsTranslationType.Currency,
        value: revenuePerYear,
      }),
    });
  }

  if (!!typeBuilding) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "typeBuilding",
        prefix: "mls.prop.facilities.house",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: typeBuilding.toString(),
        prefix: "buildingTypeOptions",
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "houseLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const addAccommodations = (
  accommodations: RealEstatePropertyFacilitiesAccommodations,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!accommodations) return;
  const innerValues = [] as RealEstatePropertyValue[];
  const { countOfHousingUnits, isShared, sharedSanitaryFacilities } =
    accommodations;

  displayBasicFacilities(
    accommodations,
    innerValues,
    "facilities.accommodations"
  );

  if (!!countOfHousingUnits) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "countOfHousingUnits",
        prefix: "mls.prop.facilities.accommodations",
      }),
      value: countOfHousingUnits.toString(),
    });
  }

  if (!!isShared) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "isShared",
        prefix: "mls.prop.facilities.accommodations",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: isShared,
      }),
    });
  }

  if (!!sharedSanitaryFacilities) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "sharedSanitaryFacilities",
        prefix: "mls.prop.facilities.accommodations",
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: sharedSanitaryFacilities,
      }),
    });
  }

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "accommodationsLabel",
        prefix: "mls.prop.facilities",
      }),
      value: innerValues,
    });
  }
};

const displayBasicFacilities = (
  facilityType: FascilityType,
  returnValue: RealEstatePropertyValue[],
  facilityName: string
) => {
  if (!facilityType) return;

  if (!!facilityType?.available) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: `mls.prop.${facilityName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: facilityType?.available,
      }),
    });

    if (!!facilityType?.comments) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: facilityType?.comments,
      });

      if (!!value) {
        returnValue.push({
          label: "",
          value,
        });
      }
    }

    if (!!facilityType?.evaluation) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: facilityType?.evaluation,
      });

      if (!!value) {
        returnValue.push({
          label: "",
          value,
        });
      }
    }
  }
};

const displayCompanyListings = (
  companyListings: CompanyListing[],
  returnValue: RealEstatePropertyValue[]
) => {
  (companyListings || []).forEach((companyListing, idx) => {
    const innerReturnValue: RealEstatePropertyValue[] = [];

    const label = createTranslation({
      type: MlsTranslationType.String,
      value: "label",
      prefix: "mls.prop.facilities.companyListing",
      values: { number: idx + 1 },
    });

    if (!!companyListing?.constructionYear) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionYear",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: companyListing?.constructionYear.toString(),
      });
    }

    displayDimensionsType(
      companyListing.content,
      innerReturnValue,
      "facilities.companyListing.content"
    );

    if (!!companyListing?.countOfBedrooms) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "countOfBedrooms",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: companyListing?.countOfBedrooms.toString(),
      });
    }

    if (!!companyListing?.countOfFloors) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "countOfFloors",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: companyListing?.countOfFloors.toString(),
      });
    }

    if (!!companyListing?.countOfRooms) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "countOfRooms",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: companyListing?.countOfRooms.toString(),
      });
    }

    displayDimensionsType(
      companyListing?.effectiveArea,
      innerReturnValue,
      "facilities.companyListing.effectiveArea"
    );

    addEnergyCertificate(companyListing?.energyCertificate, innerReturnValue);

    if (!!companyListing?.isMainListing) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isMainListing",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: companyListing?.isMainListing,
        }),
      });
    }

    displayDimensionsType(
      companyListing?.landArea,
      innerReturnValue,
      "facilities.companyListing.landArea"
    );

    if (!!companyListing?.maintenanceInside) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "maintenanceInside",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: companyListing?.maintenanceInside.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!companyListing?.maintenanceOutside) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "maintenanceOutside",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: companyListing?.maintenanceOutside.toString(),
          prefix: "conditionTypeOptions",
        }),
      });
    }

    if (!!(companyListing?.propertyTypes || []).length) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "propertyTypes",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: companyListing?.propertyTypes.map((v) => v.toString()),
          prefix: "propertyTypeOptions",
        }),
      });
    }

    if (!!companyListing?.situated) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "situated",
          prefix: "mls.prop.facilities.companyListing",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: companyListing?.situated.toString(),
          prefix: "situatedTypeOptions",
        }),
      });
    }

    displayBasicGarden(
      companyListing.garden,
      innerReturnValue,
      "facilities.companyListing.garden"
    );

    displayBasicGarage(
      companyListing.garage,
      innerReturnValue,
      "facilities.companyListing.garage"
    );

    returnValue.push({
      label,
      value: innerReturnValue,
    });
  });
};

const displayBasicGarage = (
  garage: Garage,
  returnValue: RealEstatePropertyValue[],
  prefix: string,
  index?: number
) => {
  if (!garage) return;

  const innerReturnValue: RealEstatePropertyValue[] = [];
  const name = !!(garage?.name || []).length
    ? createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: garage?.name,
      })
    : null;

  const label = !!name
    ? name
    : isNumber(index)
    ? createTranslation({
        type: MlsTranslationType.String,
        value: "label",
        prefix: `mls.prop.${prefix}`,
        values: { number: index + 1 },
      })
    : createTranslation({
        type: MlsTranslationType.String,
        value: "defaultLabel",
        prefix: `mls.prop.${prefix}`,
      });

  if (!!garage?.name) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: garage?.name,
    });

    if (!!value) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "name",
          prefix: `mls.prop.${prefix}`,
        }),
        value,
      });
    }
  }

  if (!!garage?.carCapacity) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "carCapacity",
        prefix: `mls.prop.${prefix}`,
      }),
      value: garage?.carCapacity.toString(),
    });
  }

  if (!!garage?.comments) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: garage?.comments,
    });

    if (!!value) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "comments",
          prefix: `mls.prop.${prefix}`,
        }),
        value,
      });
    }
  }

  displayDimensionsType(
    garage?.dimensions,
    innerReturnValue,
    `${prefix}.dimensions`
  );

  if (!!garage?.evaluation) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: garage?.evaluation,
    });

    if (!!value) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "evaluation",
          prefix: `mls.prop.${prefix}`,
        }),
        value,
      });
    }
  }

  if (!!(garage?.facilities || []).length) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "facilities",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (garage?.facilities || []).map((v) => v.toString()),
        prefix: "garageFacilityTypeOptions",
      }),
    });
  }

  if (!!garage?.type) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "type",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: garage?.type.toString(),
        prefix: "garageTypeOptions",
      }),
    });
  }

  if (!!(garage?.isolationTypes || []).length) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "isolationTypes",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.StringArray,
        value: (garage?.isolationTypes || []).map((v) => v.toString()),
        prefix: "isolationTypeOptions",
      }),
    });
  }

  if (!!innerReturnValue.length) {
    returnValue.push({
      label,
      value: innerReturnValue,
    });
  }
};

const displayBasicGarden = (
  garden: Garden,
  returnValue: RealEstatePropertyValue[],
  prefix: string,
  index?: number
) => {
  if (!garden) return;
  const innerReturnValue: RealEstatePropertyValue[] = [];
  const name = !!(garden?.name || []).length
    ? createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: garden?.name,
      })
    : null;

  const label = !!name
    ? name
    : isNumber(index)
    ? createTranslation({
        type: MlsTranslationType.String,
        value: "label",
        prefix: `mls.prop.${prefix}`,
        values: { number: index + 1 },
      })
    : createTranslation({
        type: MlsTranslationType.String,
        value: "defaultLabel",
        prefix: `mls.prop.${prefix}`,
      });

  if (!!garden?.name) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: garden?.name,
    });

    if (!!value) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "name",
          prefix: `mls.prop.${prefix}`,
        }),
        value,
      });
    }
  }

  if (!!garden?.quality) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "quality",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: garden?.quality.toString(),
        prefix: "gardenQualityTypeOptions",
      }),
    });
  }

  displayDimensionsType(
    garden?.dimensions,
    innerReturnValue,
    `${prefix}.dimensions`
  );

  if (!!garden?.isMainGarden) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "isMainGarden",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: garden?.isMainGarden,
      }),
    });
  }

  if (!!garden?.orientation) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "orientation",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: garden?.orientation.toString(),
        prefix: "orientationTypeOptions",
      }),
    });
  }

  if (!!garden?.description) {
    const value = createTranslation({
      type: MlsTranslationType.MultilanguageString,
      value: garden?.description,
    });

    if (!!value) {
      innerReturnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "description",
          prefix: `mls.prop.${prefix}`,
        }),
        value,
      });
    }
  }

  if (!!garden?.hasBackyardEntrance) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "hasBackyardEntrance",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Boolean,
        value: garden?.hasBackyardEntrance,
      }),
    });
  }

  if (!!garden?.type) {
    innerReturnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "type",
        prefix: `mls.prop.${prefix}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: garden?.type.toString(),
        prefix: "gardenTypeOptions",
      }),
    });
  }

  if (!!innerReturnValue.length) {
    returnValue.push({
      label,
      value: innerReturnValue,
    });
  }
};

const displayDimensionsType = (
  dimensionsType: DimensionsType,
  returnValue: RealEstatePropertyValue[],
  dimensionName: string
) => {
  if (!dimensionsType) return;
  if (!!dimensionsType?.area) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "area",
        prefix: `mls.prop.${dimensionName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.SquareMeters,
        value: dimensionsType?.area,
      }),
    });
  }

  if (!!dimensionsType?.content) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "content",
        prefix: `mls.prop.${dimensionName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.CubicMeters,
        value: dimensionsType?.content,
      }),
    });
  }

  if (!!dimensionsType?.height) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "height",
        prefix: `mls.prop.${dimensionName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Meters,
        value: dimensionsType?.height,
      }),
    });
  }

  if (!!dimensionsType?.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "length",
        prefix: `mls.prop.${dimensionName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Meters,
        value: dimensionsType?.length,
      }),
    });
  }

  if (!!dimensionsType?.width) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "width",
        prefix: `mls.prop.${dimensionName}`,
      }),
      value: createTranslation({
        type: MlsTranslationType.Meters,
        value: dimensionsType?.width,
      }),
    });
  }
};

const legalValues = createSelector(
  (legal: RealEstatePropertyLegal) => legal,
  (legal: RealEstatePropertyLegal) => {
    if (!legal) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      businessRights,
      legalStatus,
      environmentalPermits,
      productionRights,
    } = legal;

    if (!!(businessRights || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "businessRights",
          prefix: "mls.prop.legal",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (businessRights || []).map((v) => v.toString()),
          prefix: "businessRightOptions",
        }),
      });
    }

    if (!!legalStatus) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "legalStatus",
          prefix: "mls.prop.legal",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: legalStatus.toString(),
          prefix: "legalStatusTypeOptions",
        }),
      });
    }

    if (!!environmentalPermits) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "environmentalPermits",
          prefix: "mls.prop.legal",
        }),
        value: environmentalPermits,
      });
    }

    if (!!productionRights) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: productionRights,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "productionRights",
            prefix: "mls.prop.legal",
          }),
          value,
        });
      }
    }

    return returnValue;
  }
);

const surroundingsValues = createSelector(
  (surroundings: RealEstatePropertySurroundings) => surroundings,
  (surroundings: RealEstatePropertySurroundings) => {
    if (!surroundings) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      countOfApartments,
      countOfFreeApartments,
      hillsideLocation,
      isInHighConcentrationArea,
      isInIndustrialEstate,
      isInOfficeBlock,
      isInPark,
      isLakeLocation,
      location,
      noisePollution,
      northFacingSlope,
      pedestrianArea,
      pointsOfInterest,
      protectionZone,
      qualityOfLocation,
      shopLocation,
      southFacingSlope,
      welfareState,
      withGreenView,
      withView,
    } = surroundings;

    if (!!countOfApartments) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "countOfApartments",
          prefix: "mls.prop.surroundings",
        }),
        value: countOfApartments.toString(),
      });
    }

    if (!!countOfFreeApartments) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "countOfFreeApartments",
          prefix: "mls.prop.surroundings",
        }),
        value: countOfFreeApartments.toString(),
      });
    }

    if (!!hillsideLocation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hillsideLocation",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hillsideLocation,
        }),
      });
    }

    if (!!isInHighConcentrationArea) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isInHighConcentrationArea",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isInHighConcentrationArea,
        }),
      });
    }

    if (!!isInIndustrialEstate) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isInIndustrialEstate",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isInIndustrialEstate,
        }),
      });
    }

    if (!!isInOfficeBlock) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isInOfficeBlock",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isInOfficeBlock,
        }),
      });
    }

    if (!!isInPark) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isInPark",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isInPark,
        }),
      });
    }

    if (!!isLakeLocation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isLakeLocation",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isLakeLocation,
        }),
      });
    }

    if (!!(location || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "location",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (location || []).map((v) => v.toString()),
          prefix: "locationPlaceTypeOptions",
        }),
      });
    }

    if (!!noisePollution) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "noisePollution",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: noisePollution.toString(),
          prefix: "noisePollutionTypeOptions",
        }),
      });
    }

    if (!!northFacingSlope) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "northFacingSlope",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: northFacingSlope,
        }),
      });
    }

    if (!!pedestrianArea) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "pedestrianArea",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: pedestrianArea,
        }),
      });
    }

    addPointsOfInterest(pointsOfInterest, returnValue);

    if (!!protectionZone) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "protectionZone",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: protectionZone,
        }),
      });
    }

    if (!!qualityOfLocation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "qualityOfLocation",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: qualityOfLocation.toString(),
          prefix: "qualityTypeOptions",
        }),
      });
    }

    if (!!shopLocation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "shopLocation",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: shopLocation.toString(),
          prefix: "shopLocationTypeOptions",
        }),
      });
    }

    if (!!southFacingSlope) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "southFacingSlope",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: southFacingSlope,
        }),
      });
    }

    if (!!welfareState) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "welfareState",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: welfareState.toString(),
          prefix: "welfareStateTypeOptions",
        }),
      });
    }

    if (!!withGreenView) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "withGreenView",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: withGreenView,
        }),
      });
    }

    if (!!withView) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "withView",
          prefix: "mls.prop.surroundings",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: withView,
        }),
      });
    }

    return returnValue;
  }
);

const addPointsOfInterest = (
  pointsOfInterest: PointOfInterest[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!pointsOfInterest) return;
  const innerValues = [] as RealEstatePropertyValue[];

  (pointsOfInterest || []).map((pointOfInterest) => {
    const distanceValues = [] as RealEstatePropertyValue[];

    if (!!pointOfInterest?.distanceFrom) {
      distanceValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "distanceFrom",
          prefix: "mls.prop.surroundings.pointOfInterest",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: pointOfInterest?.distanceFrom,
        }),
      });
    }

    if (!!pointOfInterest?.distanceUntil) {
      distanceValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "distanceUntil",
          prefix: "mls.prop.surroundings.pointOfInterest",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: pointOfInterest?.distanceUntil,
        }),
      });
    }

    if (!!pointOfInterest?.type) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: pointOfInterest?.type.toString(),
          prefix: "pointOfInterestTypeOptions",
        }),
        value: distanceValues,
      });
    }
  });

  if (!!innerValues.length) {
    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "pointsOfInterestLabel",
        prefix: "mls.prop.construction",
      }),
      value: innerValues,
    });
  }
};

const dimensionsValues = createSelector(
  (dimensions: RealEstatePropertyDimensions) => dimensions,
  (dimensions: RealEstatePropertyDimensions) => {
    if (!dimensions) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      lot,
      frontage,
      content,
      land,
      officeDimensions,
      terrainDimensions,
      surfaces,
    } = dimensions;

    displayDimensionsType(lot, returnValue, "dimensions.lot");

    if (!!frontage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "frontage",
          prefix: "mls.prop.dimensions",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: frontage,
        }),
      });
    }

    if (!!content) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "content",
          prefix: "mls.prop.dimensions",
        }),
        value: createTranslation({
          type: MlsTranslationType.CubicMeters,
          value: content,
        }),
      });
    }

    displayDimensionsType(land, returnValue, "dimensions.land");
    displayDimensionsType(
      officeDimensions,
      returnValue,
      "dimensions.officeDimensions"
    );
    displayDimensionsType(
      terrainDimensions,
      returnValue,
      "dimensions.terrainDimensions"
    );

    addSurfaces(surfaces, returnValue);

    return returnValue;
  }
);

const addSurfaces = (
  surfaces: RealEstatePropertyDimensionsSurfacesSurface[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!surfaces) return;

  (surfaces || []).map((surface) => {
    const innerValues = [] as RealEstatePropertyValue[];
    const label = !!surface.surfaceType
      ? createTranslation({
          type: MlsTranslationType.String,
          value: surface?.surfaceType.toString(),
          prefix: "surfaceTypeOptions",
        })
      : createTranslation({
          type: MlsTranslationType.String,
          value: "label",
          prefix: "mls.prop.dimensions.surface",
        });

    if (!!surface?.area) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "area",
          prefix: "mls.prop.dimensions.surface",
        }),
        value: createTranslation({
          type: MlsTranslationType.SquareMeters,
          value: surface?.area,
        }),
      });
    }

    if (!!surface?.content) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "content",
          prefix: "mls.prop.dimensions.surface",
        }),
        value: createTranslation({
          type: MlsTranslationType.CubicMeters,
          value: surface?.content,
        }),
      });
    }

    if (!!surface?.height) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "height",
          prefix: "mls.prop.dimensions.surface",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: surface?.height,
        }),
      });
    }

    if (!!surface?.length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "length",
          prefix: "mls.prop.dimensions.surface",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: surface?.length,
        }),
      });
    }

    if (!!surface?.width) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "width",
          prefix: "mls.prop.dimensions.surface",
        }),
        value: createTranslation({
          type: MlsTranslationType.Meters,
          value: surface?.width,
        }),
      });
    }

    returnValue.push({
      label,
      value: innerValues,
    });
  });
};

const financialsValues = createSelector(
  (financials: RealEstatePropertyFinancials) => financials,
  (financials: RealEstatePropertyFinancials) => {
    if (!financials) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      advancedPaymentAmount,
      buildingLeaseFee,
      buildingLeaseFeeSquareUnit,
      calculationTypeMainRent,
      commentsMonthlyCosts,
      communalCostsIncluded,
      deposit,
      freeParking,
      furnitureCosts,
      generalSalesTax,
      grossRentalIncome,
      hasPaidLandDevelopmentCosts,
      hasRetailersAssociationContribution,
      heatingCostsNet,
      indexLinkedInPercent,
      indications,
      isPartOfInvestmentPortfolio,
      landDevelopmentCosts,
      leaseInYears,
      leaseNet,
      leaseSquareUnitNet,
      mainRentNet,
      mainRentNetSquareUnit,
      maintenanceFund,
      monthlyCosts,
      monthlyCostsSquareUnit,
      obtainableRent,
      obtainableRentSquareUnit,
      operationalCostsNet,
      ownershipOfGarage,
      paymentRequestGros,
      purchaseCondition,
      purchaseMixform,
      purchasePrice,
      purchasePriceInUnits,
      purchasePriceUnit,
      purchasePriceType,
      purchaseVATApplies,
      purchaseVATPercentage,
      purchasePriceSquareUnit,
      purchasePriceParkingLot,
      purchasePriceParkingLotCovered,
      purchaseSpecificationParkingLot,
      purchaseSpecification,
      purchasePriceIncludedTerrain,
      purchasePriceIncludedOffice,
      realEstateTaxDate,
      realEstateTaxValue,
      redemptionAmount,
      rentPrice,
      rentPriceUnit,
      rentPriceInUnits,
      rentPriceParkingLot,
      rentPriceParkingLotCovered,
      rentPriceCodeParkingLot,
      rentPriceType,
      rentSpecification,
      rentVATPercentage,
      rentVATApplies,
      rentPriceIncludedTerrain,
      rentPriceIncludedOffice,
      retailersAssociationContribution,
      revenue,
      serviceCosts,
      specialCostsNet,
      specialCostsOnetimeNet,
      subsidizedHousing,
      summOneTimeCosts,
      taxReleaseInMonth,
      yearlyCosts,
      yearlyCostsSquareUnit,
      priceCode,
      constructionInterest,
      yieldReturn,
      priceOfLand,
    } = financials;

    if (!!advancedPaymentAmount) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "advancedPaymentAmount",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: advancedPaymentAmount,
        }),
      });
    }

    if (!!buildingLeaseFee) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingLeaseFee",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: buildingLeaseFee,
        }),
      });
    }

    if (!!buildingLeaseFeeSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "buildingLeaseFeeSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: buildingLeaseFeeSquareUnit,
        }),
      });
    }

    if (!!calculationTypeMainRent) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "calculationTypeMainRent",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: calculationTypeMainRent,
        }),
      });
    }

    if (!!commentsMonthlyCosts) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: commentsMonthlyCosts,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "commentsMonthlyCosts",
            prefix: "mls.prop.financials",
          }),
          value,
        });
      }
    }

    if (!!communalCostsIncluded) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "communalCostsIncluded",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: communalCostsIncluded,
        }),
      });
    }

    if (!!deposit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "deposit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: deposit,
        }),
      });
    }

    if (!!freeParking) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "freeParking",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: freeParking,
        }),
      });
    }

    if (!!furnitureCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "furnitureCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: furnitureCosts,
        }),
      });
    }

    if (!!generalSalesTax) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "generalSalesTax",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: generalSalesTax,
        }),
      });
    }

    if (!!grossRentalIncome) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "grossRentalIncome",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: grossRentalIncome,
        }),
      });
    }

    if (!!hasPaidLandDevelopmentCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasPaidLandDevelopmentCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasPaidLandDevelopmentCosts,
        }),
      });
    }

    if (!!hasRetailersAssociationContribution) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasRetailersAssociationContribution",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasRetailersAssociationContribution,
        }),
      });
    }

    if (!!heatingCostsNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "heatingCostsNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: heatingCostsNet,
        }),
      });
    }

    if (!!indexLinkedInPercent) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "indexLinkedInPercent",
          prefix: "mls.prop.financials",
        }),
        value: indexLinkedInPercent.toString(),
      });
    }

    if (!!indications?.electricityCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "electricityCosts",
          prefix: "mls.prop.financials.indications",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: indications?.electricityCosts,
        }),
      });
    }

    if (!!indications?.gasCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "gasCosts",
          prefix: "mls.prop.financials.indications",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: indications?.gasCosts,
        }),
      });
    }

    if (!!indications?.heatingCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "heatingCosts",
          prefix: "mls.prop.financials.indications",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: indications?.heatingCosts,
        }),
      });
    }

    if (!!indications?.waterCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "waterCosts",
          prefix: "mls.prop.financials.indications",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: indications?.waterCosts,
        }),
      });
    }

    if (!!isPartOfInvestmentPortfolio) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isPartOfInvestmentPortfolio",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isPartOfInvestmentPortfolio,
        }),
      });
    }

    if (!!landDevelopmentCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "landDevelopmentCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: landDevelopmentCosts,
        }),
      });
    }

    if (!!leaseInYears) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "leaseInYears",
          prefix: "mls.prop.financials",
        }),
        value: leaseInYears.toString(),
      });
    }

    if (!!leaseNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "leaseNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: leaseNet,
        }),
      });
    }

    if (!!leaseSquareUnitNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "leaseSquareUnitNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: leaseSquareUnitNet,
        }),
      });
    }

    if (!!mainRentNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "mainRentNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: mainRentNet,
        }),
      });
    }

    if (!!mainRentNetSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "mainRentNetSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: mainRentNetSquareUnit,
        }),
      });
    }

    if (!!maintenanceFund) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "maintenanceFund",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: maintenanceFund,
        }),
      });
    }

    if (!!monthlyCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "monthlyCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: monthlyCosts,
        }),
      });
    }

    if (!!monthlyCostsSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "monthlyCostsSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: monthlyCostsSquareUnit,
        }),
      });
    }

    if (!!obtainableRent) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "obtainableRent",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: obtainableRent,
        }),
      });
    }

    if (!!obtainableRentSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "obtainableRentSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: obtainableRentSquareUnit,
        }),
      });
    }

    if (!!operationalCostsNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "operationalCostsNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: operationalCostsNet,
        }),
      });
    }

    if (!!ownershipOfGarage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "ownershipOfGarage",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: ownershipOfGarage.toString(),
          prefix: "ownershipOptionTypeOptions",
        }),
      });
    }

    if (!!paymentRequestGros) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "paymentRequestGros",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: paymentRequestGros,
        }),
      });
    }

    if (!!purchaseMixform) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchaseMixform",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: purchaseMixform.toString(),
          prefix: "purchaseMixFormTypeOptions",
        }),
      });
    }

    if (!!purchasePrice) {
      let label = createTranslation({
        type: MlsTranslationType.String,
        value: "purchasePrice",
        prefix: "mls.prop.financials",
      });

      let value = createTranslation({
        type: MlsTranslationType.Currency,
        value: purchasePrice,
      });

      if (!!purchaseCondition) {
        value = `${value} ${createTranslation({
          type: MlsTranslationType.String,
          value: purchaseCondition.toString(),
          prefix: "purchaseConditionTypeOptions",
        }).toLowerCase()}`;
      }

      if (!!purchasePriceType) {
        label = `${label} ${createTranslation({
          type: MlsTranslationType.String,
          value: purchasePriceType.toString(),
          prefix: "purchasePriceTypeOptions",
        }).toLowerCase()}`;
      }

      returnValue.push({
        label,
        value,
      });
    }

    if (!!purchasePriceInUnits) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value:
            purchasePriceUnit === PriceUnitType.PER_M2
              ? "purchasePriceInUnitsM2"
              : "purchasePriceInUnitsHa",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceInUnits,
        }),
      });
    }

    if (!!purchaseVATApplies) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchaseVATApplies",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: purchaseVATApplies,
        }),
      });
    }

    if (!!purchaseVATPercentage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchaseVATPercentage",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Percentage,
          value: purchaseVATPercentage,
        }),
      });
    }

    if (!!purchasePriceSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchasePriceSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceSquareUnit,
        }),
      });
    }
    if (!!purchasePriceParkingLot) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchasePriceParkingLot",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceParkingLot,
        }),
      });
    }

    if (!!purchasePriceParkingLotCovered) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchasePriceParkingLotCovered",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceParkingLotCovered,
        }),
      });
    }

    if (!!purchaseSpecificationParkingLot) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchaseSpecificationParkingLot",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: purchaseSpecificationParkingLot.toString(),
          prefix: "purchaseSpecificationTypeOptions",
        }),
      });
    }

    if (!!purchaseSpecification) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchaseSpecification",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: purchaseSpecification.toString(),
          prefix: "purchaseSpecificationTypeOptions",
        }),
      });
    }

    if (!!purchasePriceIncludedTerrain) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchasePriceIncludedTerrain",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceIncludedTerrain,
        }),
      });
    }

    if (!!purchasePriceIncludedOffice) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "purchasePriceIncludedOffice",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: purchasePriceIncludedOffice,
        }),
      });
    }

    if (moment(realEstateTaxDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "realEstateTaxDate",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateSimple,
          value: realEstateTaxDate,
        }),
      });
    }

    if (!!realEstateTaxValue) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "realEstateTaxValue",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: realEstateTaxValue,
        }),
      });
    }

    if (!!redemptionAmount) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "redemptionAmount",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: redemptionAmount,
        }),
      });
    }

    if (!!rentPrice) {
      let label = createTranslation({
        type: MlsTranslationType.String,
        value: "rentPrice",
        prefix: "mls.prop.financials",
      });

      if (!!rentPriceType) {
        label = `${label} ${createTranslation({
          type: MlsTranslationType.String,
          value: rentPriceType.toString(),
          prefix: "rentPriceTypeOptions",
        }).toLowerCase()}`;
      }

      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPrice",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPrice,
        }),
      });
    }

    if (!!rentPriceInUnits) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value:
            rentPriceUnit === PriceUnitType.PER_M2
              ? "rentPriceInUnitsM2"
              : "rentPriceInUnitsHa",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPriceInUnits,
        }),
      });
    }

    if (!!rentPriceParkingLot) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPriceParkingLot",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPriceParkingLot,
        }),
      });
    }

    if (!!rentPriceParkingLotCovered) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPriceParkingLotCovered",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPriceParkingLotCovered,
        }),
      });
    }

    if (!!rentPriceCodeParkingLot) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPriceCodeParkingLot",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: rentPriceCodeParkingLot.toString(),
          prefix: "priceCodeTypeOptions",
        }),
      });
    }

    if (!!(rentSpecification || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentSpecification",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (rentSpecification || []).map((v) => v.toString()),
          prefix: "rentSpecificationTypeOptions",
        }),
      });
    }

    if (!!rentVATPercentage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentVATPercentage",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Percentage,
          value: rentVATPercentage,
        }),
      });
    }

    if (!!rentVATApplies) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentVATApplies",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: rentVATApplies,
        }),
      });
    }

    if (!!rentPriceIncludedTerrain) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPriceIncludedTerrain",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPriceIncludedTerrain,
        }),
      });
    }

    if (!!rentPriceIncludedOffice) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "rentPriceIncludedOffice",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: rentPriceIncludedOffice,
        }),
      });
    }

    if (!!retailersAssociationContribution) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "retailersAssociationContribution",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: retailersAssociationContribution,
        }),
      });
    }

    if (!!revenue) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "revenue",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: revenue,
        }),
      });
    }

    if (!!serviceCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "serviceCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: serviceCosts,
        }),
      });
    }

    if (!!specialCostsNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "specialCostsNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: specialCostsNet,
        }),
      });
    }

    if (!!specialCostsOnetimeNet) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "specialCostsOnetimeNet",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: specialCostsOnetimeNet,
        }),
      });
    }

    if (!!subsidizedHousing) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "subsidizedHousing",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: subsidizedHousing,
        }),
      });
    }

    if (!!summOneTimeCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "summOneTimeCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: summOneTimeCosts,
        }),
      });
    }

    if (!!taxReleaseInMonth) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "taxReleaseInMonth",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: taxReleaseInMonth,
        }),
      });
    }

    if (!!yearlyCosts) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "yearlyCosts",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: yearlyCosts,
        }),
      });
    }

    if (!!yearlyCostsSquareUnit) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "yearlyCostsSquareUnit",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: yearlyCostsSquareUnit,
        }),
      });
    }

    if (!!priceCode) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "priceCode",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: priceCode.toString(),
          prefix: "priceCodeTypeOptions",
        }),
      });
    }

    if (!!constructionInterest) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionInterest",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: constructionInterest,
        }),
      });
    }

    if (!!yieldReturn) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "yieldReturn",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Percentage,
          value: yieldReturn,
        }),
      });
    }

    if (!!priceOfLand) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "priceOfLand",
          prefix: "mls.prop.financials",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: priceOfLand,
        }),
      });
    }

    return returnValue;
  }
);

const floorsValues = createSelector(
  (floors: RealEstatePropertyFloorsFloor[]) => floors,
  (floors: RealEstatePropertyFloorsFloor[]) => {
    if (!floors) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    (floors || []).map((floorValue, idx) => {
      const {
        atticFixStair,
        atticRoomPossible,
        countOfBedrooms,
        countOfRooms,
        description,
        dimensions,
        level,
        name: orgName,
        orientation,
        rooms,
        type,
      } = floorValue;
      const innerValues = [] as RealEstatePropertyValue[];

      const labelName = !!(orgName || []).length
        ? createTranslation({
            type: MlsTranslationType.MultilanguageString,
            value: orgName,
          })
        : null;

      const label = !!labelName
        ? labelName
        : !!type
        ? createTranslation({
            type: MlsTranslationType.String,
            value: type.toString(),
            prefix: "floorTypeOptions",
          })
        : createTranslation({
            type: MlsTranslationType.String,
            value: "label",
            prefix: "mls.props.floors",
            values: { number: idx + 1 },
          });

      if (!!atticFixStair) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "atticFixStair",
            prefix: "mls.prop.floors",
          }),
          value: createTranslation({
            type: MlsTranslationType.Boolean,
            value: atticFixStair,
          }),
        });
      }

      if (!!atticRoomPossible) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "atticRoomPossible",
            prefix: "mls.prop.floors",
          }),
          value: createTranslation({
            type: MlsTranslationType.Boolean,
            value: atticRoomPossible,
          }),
        });
      }

      if (!!countOfBedrooms) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "countOfBedrooms",
            prefix: "mls.prop.floors",
          }),
          value: countOfBedrooms.toString(),
        });
      }

      if (!!countOfRooms) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "countOfRooms",
            prefix: "mls.prop.floors",
          }),
          value: countOfRooms.toString(),
        });
      }

      if (!!description) {
        const value = createTranslation({
          type: MlsTranslationType.MultilanguageString,
          value: description,
        });

        if (!!value) {
          innerValues.push({
            label: createTranslation({
              type: MlsTranslationType.String,
              value: "description",
              prefix: "mls.prop.floors",
            }),
            value,
          });
        }
      }

      displayDimensionsType(dimensions, innerValues, "floors.dimensions");

      if (!!level) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "level",
            prefix: "mls.prop.floors",
          }),
          value: level.toString(),
        });
      }

      if (!!orgName) {
        const value = createTranslation({
          type: MlsTranslationType.MultilanguageString,
          value: orgName,
        });

        if (!!value) {
          innerValues.push({
            label: createTranslation({
              type: MlsTranslationType.String,
              value: "name",
              prefix: "mls.prop.floors",
            }),
            value,
          });
        }
      }

      if (!!orientation) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "orientation",
            prefix: "mls.prop.floors",
          }),
          value: createTranslation({
            type: MlsTranslationType.String,
            value: orientation.toString(),
            prefix: "orientationTypeOptions",
          }),
        });
      }

      addRooms(rooms, innerValues);

      returnValue.push({
        label,
        value: innerValues,
      });
    });

    return returnValue;
  }
);

const addRooms = (
  rooms: RealEstatePropertyFloorsFloorRoomsRoom[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!(rooms || []).length) return;
  const roomValues: RealEstatePropertyValue[] = [];

  (rooms || []).forEach((room) => {
    const {
      balconyTypes,
      bathroomFacilities,
      description,
      dimensions,
      hasBuiltInAppliances,
      hasStairs,
      isRenovated,
      kitchenFacilities,
      kitchenTypes,
      name: orgName,
      orientation,
      type,
      livingRoomTypes,
      renovationYear,
    } = room;
    const innerValues = [] as RealEstatePropertyValue[];
    const name = !!(orgName || []).length
      ? createTranslation({
          type: MlsTranslationType.MultilanguageString,
          value: orgName,
        })
      : null;

    const label = !!name
      ? name
      : !!type
      ? createTranslation({
          type: MlsTranslationType.String,
          value: type.toString(),
          prefix: "roomTypeOptions",
        })
      : createTranslation({
          type: MlsTranslationType.String,
          value: "label",
          prefix: "mls.props.floors.rooms",
        });

    if (!!(balconyTypes || []).length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "balconyTypes",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (balconyTypes || []).map((v) => v.toString()),
          prefix: "balconyTypeOptions",
        }),
      });
    }

    if (!!(bathroomFacilities || []).length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "bathroomFacilities",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (bathroomFacilities || []).map((v) => v.toString()),
          prefix: "bathroomFacilityTypeOptions",
        }),
      });
    }

    if (!!description) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: description,
      });

      if (!!value) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "description",
            prefix: "mls.prop.floors.rooms",
          }),
          value,
        });
      }
    }

    displayDimensionsType(dimensions, innerValues, "floors.rooms.dimensions");

    if (!!hasBuiltInAppliances) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasBuiltInAppliances",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasBuiltInAppliances,
        }),
      });
    }

    if (!!hasStairs) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "hasStairs",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: hasStairs,
        }),
      });
    }

    if (!!isRenovated) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isRenovated",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isRenovated,
        }),
      });
    }

    if (!!(kitchenFacilities || []).length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "kitchenFacilities",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (kitchenFacilities || []).map((v) => v.toString()),
          prefix: "kitchenFacilityTypeOptions",
        }),
      });
    }

    if (!!(kitchenTypes || []).length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "kitchenTypes",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (kitchenTypes || []).map((v) => v.toString()),
          prefix: "kitchenTypeOptions",
        }),
      });
    }

    if (!!orientation) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "orientation",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: orientation.toString(),
          prefix: "orientationTypeOptions",
        }),
      });
    }

    if (!!(livingRoomTypes || []).length) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "livingRoomTypes",
          prefix: "mls.prop.floors.rooms",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (livingRoomTypes || []).map((v) => v.toString()),
          prefix: "livingRoomTypeOptions",
        }),
      });
    }

    if (!!renovationYear) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "renovationYear",
          prefix: "mls.prop.floors.rooms",
        }),
        value: renovationYear.toString(),
      });
    }

    roomValues.push({
      label,
      value: innerValues,
    });
  });

  returnValue.push({
    label: createTranslation({
      type: MlsTranslationType.String,
      value: "mls.prop.floors.rooms",
    }),
    value: roomValues,
  });
};

const garagesValues = createSelector(
  (garages: Garage[]) => garages,
  (garages: Garage[]) => {
    if (!garages) return [];
    const returnValue = [] as RealEstatePropertyValue[];

    (garages || []).map((garage, idx) => {
      displayBasicGarage(garage, returnValue, "garages.garage", idx);
    });

    return returnValue;
  }
);

const gardensValues = createSelector(
  (gardens: Garden[]) => gardens,
  (gardens: Garden[]) => {
    if (!gardens) return [];
    const returnValue = [] as RealEstatePropertyValue[];

    (gardens || []).map((garden, idx) => {
      displayBasicGarden(garden, returnValue, "gardens.garden", idx);
    });

    return returnValue;
  }
);

const offerValues = createSelector(
  (offer: RealEstatePropertyOffer) => offer,
  (offer: RealEstatePropertyOffer) => {
    if (!offer) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      acceptance,
      acceptanceDate,
      acceptanceDescription,
      isAcquisition,
      isConventioned,
      isForPacht,
      isForRent,
      isForSale,
      isIncentive,
      isSaleAndLeaseBack,
      isSpecial,
      isTopper,
      openHouse,
      selfIntrest,
      specialText,
      tenancyInYears,
      availableFromDate,
      availableUntilDate,
      auctionDate,
      natureRegistration,
      registerReason,
      linkedObject,
    } = offer;

    if (!!acceptance) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "acceptance",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: acceptance.toString(),
          prefix: "acceptanceTypeOptions",
        }),
      });
    }

    if (moment(acceptanceDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "acceptanceDate",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateSimple,
          value: acceptanceDate,
        }),
      });
    }

    if (!!acceptanceDescription) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: acceptanceDescription,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "acceptanceDescription",
            prefix: "mls.prop.offer",
          }),
          value,
        });
      }
    }

    if (!!isAcquisition) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isAcquisition",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isAcquisition,
        }),
      });
    }

    if (!!isConventioned) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isConventioned",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isConventioned,
        }),
      });
    }

    if (!!isForPacht) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isForPacht",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isForPacht,
        }),
      });
    }

    if (!!isForRent) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isForRent",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isForRent,
        }),
      });
    }

    if (!!isForSale) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isForSale",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isForSale,
        }),
      });
    }

    if (!!isIncentive) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isIncentive",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isIncentive,
        }),
      });
    }

    if (!!isSaleAndLeaseBack) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isSaleAndLeaseBack",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isSaleAndLeaseBack,
        }),
      });
    }

    if (!!isSpecial) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isSpecial",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isSpecial,
        }),
      });
    }

    if (!!isTopper) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isTopper",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isTopper,
        }),
      });
    }

    addOpenHouses(openHouse, returnValue);

    if (!!selfIntrest) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "selfIntrest",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: selfIntrest,
        }),
      });
    }

    if (!!specialText) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: specialText,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "specialText",
            prefix: "mls.prop.offer",
          }),
          value,
        });
      }
    }

    if (!!tenancyInYears) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "tenancyInYears",
          prefix: "mls.prop.offer",
        }),
        value: tenancyInYears.toString(),
      });
    }

    if (moment(availableFromDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "availableFromDate",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateSimple,
          value: availableFromDate,
        }),
      });
    }

    if (moment(availableUntilDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "availableUntilDate",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateSimple,
          value: availableUntilDate,
        }),
      });
    }

    if (moment(auctionDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "auctionDate",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateSimple,
          value: auctionDate,
        }),
      });
    }

    if (!!natureRegistration) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "natureRegistration",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: natureRegistration.toString(),
          prefix: "natureRegistrationTypeOptions",
        }),
      });
    }

    if (!!registerReason) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "registerReason",
          prefix: "mls.prop.offer",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: registerReason.toString(),
          prefix: "registerReasonTypeOptions",
        }),
      });
    }

    addLinkedObject(linkedObject, returnValue);

    return returnValue;
  }
);

const addLinkedObject = (
  linkedObject: RealEstatePropertyOfferLinkedObject,
  returnValue: RealEstatePropertyValue[]
) => {
  if (!linkedObject) return;
  const innerValues = [] as RealEstatePropertyValue[];

  if (!!linkedObject?.area) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "area",
        prefix: "mls.prop.offer.linkedObject",
      }),
      value: createTranslation({
        type: MlsTranslationType.SquareMeters,
        value: linkedObject?.area,
      }),
    });
  }

  if (!!linkedObject?.situated) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "situated",
        prefix: "mls.prop.offer.linkedObject",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: linkedObject?.situated.toString(),
        prefix: "situatedTypeOptions",
      }),
    });
  }

  if (!!linkedObject?.status) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "status",
        prefix: "mls.prop.offer.linkedObject",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: linkedObject?.status.toString(),
        prefix: "linkedObjectStatusTypeOptions",
      }),
    });
  }

  if (!!linkedObject?.type) {
    innerValues.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "type",
        prefix: "mls.prop.offer.linkedObject",
      }),
      value: createTranslation({
        type: MlsTranslationType.String,
        value: linkedObject?.type.toString(),
        prefix: "propertyTypeOptions",
      }),
    });
  }

  returnValue.push({
    label: createTranslation({
      type: MlsTranslationType.String,
      value: "linkedObjectLabel",
      prefix: "mls.prop.offer",
    }),
    value: innerValues,
  });
};

const addOpenHouses = (
  openHouses: OpenHouseEventType[],
  returnValue: RealEstatePropertyValue[]
) => {
  if (!openHouses) return;

  (openHouses || []).map((openHouse) => {
    const innerValues = [] as RealEstatePropertyValue[];

    if (moment(openHouse?.date || null).isValid()) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "date",
          prefix: "mls.prop.offer.openHouse",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: openHouse?.date,
        }),
      });
    }

    if (moment(openHouse?.from || null).isValid()) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "from",
          prefix: "mls.prop.offer.openHouse",
        }),
        value: createTranslation({
          type: MlsTranslationType.Time,
          value: openHouse?.from,
        }),
      });
    }

    if (moment(openHouse?.until || null).isValid()) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "until",
          prefix: "mls.prop.offer.openHouse",
        }),
        value: createTranslation({
          type: MlsTranslationType.Time,
          value: openHouse?.until,
        }),
      });
    }

    if (!!openHouse?.option) {
      innerValues.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "option",
          prefix: "mls.prop.offer.openHouse",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: openHouse?.option.toString(),
          prefix: "openHouseOptionOptions",
        }),
      });
    }

    if (!!openHouse?.title) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: openHouse?.title,
      });

      if (!!value) {
        innerValues.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "title",
            prefix: "mls.prop.offer.openHouse",
          }),
          value,
        });
      }
    }

    returnValue.push({
      label: createTranslation({
        type: MlsTranslationType.String,
        value: "label",
        prefix: "mls.prop.construction.openHouses",
      }),
      value: innerValues,
    });
  });
};

const projectInfoValues = createSelector(
  (projectInfo: RealEstatePropertyProjectInfo) => projectInfo,
  (projectInfo: RealEstatePropertyProjectInfo) => {
    if (!projectInfo) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const { constructionNumber, objectTypeTitle, projectTitle } = projectInfo;

    if (!!constructionNumber) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "constructionNumber",
          prefix: "mls.prop.projectInfo",
        }),
        value: constructionNumber.toString(),
      });
    }

    if (!!objectTypeTitle) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: objectTypeTitle,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "objectTypeTitle",
            prefix: "mls.prop.projectInfo",
          }),
          value,
        });
      }
    }

    if (!!projectTitle) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: projectTitle,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "projectTitle",
            prefix: "mls.prop.projectInfo",
          }),
          value,
        });
      }
    }

    return returnValue;
  }
);

const propertyInfoValues = createSelector(
  (propertyInfo: RealEstatePropertyPropertyInfo) => propertyInfo,
  (propertyInfo: RealEstatePropertyPropertyInfo) => {
    if (!propertyInfo) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      creationDateTime,
      mandateDate,
      modificationDateTime,
      nameOfBuilding,
      propertyCompanyName,
      status,
      substatus,
      exclusiveStatus,
      withdrawReason,
      cadastrallCode,
      url,
    } = propertyInfo;

    if (moment(creationDateTime).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "creationDateTime",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: creationDateTime,
        }),
      });
    }

    if (moment(mandateDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "mandateDate",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: mandateDate,
        }),
      });
    }

    if (moment(modificationDateTime).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "modificationDateTime",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: modificationDateTime,
        }),
      });
    }

    if (!!nameOfBuilding) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "nameOfBuilding",
          prefix: "mls.prop.propertyInfo",
        }),
        value: nameOfBuilding.toString(),
      });
    }

    if (!!propertyCompanyName) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "propertyCompanyName",
          prefix: "mls.prop.propertyInfo",
        }),
        value: propertyCompanyName.toString(),
      });
    }

    if (!!status) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "status",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: status.toString(),
          prefix: "statusTypeOptions",
        }),
      });
    }

    if (!!substatus) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "substatus",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: substatus.toString(),
          prefix: "subStatusTypeOptions",
        }),
      });
    }

    if (!!exclusiveStatus) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "exclusiveStatus",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: exclusiveStatus.toString(),
          prefix: "exclusiveStatusTypeOptions",
        }),
      });
    }

    if (!!withdrawReason) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "withdrawReason",
          prefix: "mls.prop.propertyInfo",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: withdrawReason.toString(),
          prefix: "withdrawReasonTypeOptions",
        }),
      });
    }

    if (!!cadastrallCode) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "cadastrallCode",
          prefix: "mls.prop.propertyInfo",
        }),
        value: cadastrallCode.toString(),
      });
    }

    if (!!url) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "url",
          prefix: "mls.prop.propertyInfo",
        }),
        value: url.toString(),
      });
    }

    return returnValue;
  }
);

const transactionValues = createSelector(
  (property: RealEstateProperty) => property?.transaction,
  (property: RealEstateProperty) => property?.propertyInfo,
  (transaction, propertyInfo) => {
    if (!transaction) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      date,
      dateUntil,
      waivingOfTerminationTo,
      contractDate,
      contractFreePeriodInMonths,
      isPublic,
      realisedPrice,
      isEconomicTransfer,
    } = transaction;

    if (moment(date).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value:
            propertyInfo?.status === StatusType.WITHDRAWN
              ? "withdrawDate"
              : "date",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: date,
        }),
      });
    }

    if (moment(dateUntil).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "dateUntil",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: dateUntil,
        }),
      });
    }

    if (moment(waivingOfTerminationTo).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "waivingOfTerminationTo",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: waivingOfTerminationTo,
        }),
      });
    }

    if (moment(contractDate).isValid()) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "contractDate",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.DateFull,
          value: contractDate,
        }),
      });
    }

    if (!!contractFreePeriodInMonths) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "contractFreePeriodInMonths",
          prefix: "mls.prop.transaction",
        }),
        value: contractFreePeriodInMonths.toString(),
      });
    }

    if (!!isPublic) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isPublic",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isPublic,
        }),
      });
    }

    if (!!realisedPrice) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "realisedPrice",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Currency,
          value: realisedPrice,
        }),
      });
    }

    if (!!isEconomicTransfer) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isEconomicTransfer",
          prefix: "mls.prop.transaction",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isEconomicTransfer,
        }),
      });
    }

    return returnValue;
  }
);

const typeValues = createSelector(
  (type: RealEstatePropertyType) => type,
  (type: RealEstatePropertyType) => {
    if (!type) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      isResidential,
      isCommercial,
      isAgricultural,
      forRecreation,
      forPermanentResidence,
      isInvestment,
      isApartmentSharingCommunity,
      isOrganicCompany,
      isResidentialLot,
      propertyTypes,
      secondaryPropertyTypes,
    } = type;

    if (!!isResidential) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isResidential",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isResidential,
        }),
      });
    }

    if (!!isCommercial) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isCommercial",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isCommercial,
        }),
      });
    }

    if (!!isAgricultural) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isAgricultural",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isAgricultural,
        }),
      });
    }

    if (!!forRecreation) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "forRecreation",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: forRecreation,
        }),
      });
    }

    if (!!forPermanentResidence) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "forPermanentResidence",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: forPermanentResidence,
        }),
      });
    }

    if (!!isInvestment) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isInvestment",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isInvestment,
        }),
      });
    }

    if (!!isApartmentSharingCommunity) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isApartmentSharingCommunity",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isApartmentSharingCommunity,
        }),
      });
    }

    if (!!isOrganicCompany) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isOrganicCompany",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isOrganicCompany,
        }),
      });
    }

    if (!!isResidentialLot) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "isResidentialLot",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: isResidentialLot,
        }),
      });
    }

    if (!!(propertyTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "propertyTypes",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (propertyTypes || []).map((v) => v.toString()),
          prefix: "propertyTypeOptions",
        }),
      });
    }

    if (!!(secondaryPropertyTypes || []).length) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "secondaryPropertyTypes",
          prefix: "mls.prop.type",
        }),
        value: createTranslation({
          type: MlsTranslationType.StringArray,
          value: (secondaryPropertyTypes || []).map((v) => v.toString()),
          prefix: "propertyTypeOptions",
        }),
      });
    }

    return returnValue;
  }
);

const locationValues = createSelector(
  (location: RealEstatePropertyLocation) => location,
  (location: RealEstatePropertyLocation) => {
    if (!location) return [];
    const returnValue = [] as RealEstatePropertyValue[];
    const {
      floor,
      floorNumber,
      frequencyRange,
      frontage,
      level,
      locationDescription,
      residence,
      topNo,
      vicinity,
    } = location;

    if (!!floor) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "floor",
          prefix: "mls.prop.location",
        }),
        value: createTranslation({
          type: MlsTranslationType.String,
          value: floor.toString(),
          prefix: "floorTypeOptions",
        }),
      });
    }

    if (!!floorNumber) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "floorNumber",
          prefix: "mls.prop.location",
        }),
        value: floorNumber.toString(),
      });
    }

    if (isBoolean(frequencyRange)) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "frequencyRange",
          prefix: "mls.prop.location",
        }),
        value: createTranslation({
          type: MlsTranslationType.Boolean,
          value: frequencyRange,
        }),
      });
    }

    if (!!frontage) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "frontage",
          prefix: "mls.prop.location",
        }),
        value: frontage.toString(),
      });
    }

    if (!!level) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: level,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "level",
            prefix: "mls.prop.location",
          }),
          value,
        });
      }
    }

    if (!!locationDescription) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: locationDescription,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "locationDescription",
            prefix: "mls.prop.location",
          }),
          value,
        });
      }
    }

    if (!!residence) {
      returnValue.push({
        label: createTranslation({
          type: MlsTranslationType.String,
          value: "residence",
          prefix: "mls.prop.location",
        }),
        value: residence,
      });
    }

    if (!!topNo) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: topNo,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "topNo",
            prefix: "mls.prop.location",
          }),
          value,
        });
      }
    }

    if (!!vicinity) {
      const value = createTranslation({
        type: MlsTranslationType.MultilanguageString,
        value: vicinity,
      });

      if (!!value) {
        returnValue.push({
          label: createTranslation({
            type: MlsTranslationType.String,
            value: "vicinity",
            prefix: "mls.prop.location",
          }),
          value,
        });
      }
    }

    return returnValue;
  }
);

export const realEstatePropertyValueGroups = createSelector(
  (realEstateProperty: RealEstateProperty) => realEstateProperty,
  (realEstateProperty: RealEstateProperty) => {
    const groups = [] as RealEstatePropertyValueGroups[];

    const transaction = transactionValues(realEstateProperty);
    if (transaction.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "transaction",
          prefix: "mls.title",
        }),
        values: transaction,
      });
    }

    const financials = financialsValues(realEstateProperty?.financials);
    if (financials.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "financials",
          prefix: "mls.title",
        }),
        values: financials,
      });
    }

    const offer = offerValues(realEstateProperty?.offer);
    if (offer.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "offer",
          prefix: "mls.title",
        }),
        values: offer,
      });
    }

    const type = typeValues(realEstateProperty?.type);
    if (type.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "type",
          prefix: "mls.title",
        }),
        values: type,
      });
    }

    const location = locationValues(realEstateProperty?.location);
    if (location.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "location",
          prefix: "mls.title",
        }),
        values: location,
      });
    }

    const construction = constructionValues(realEstateProperty?.construction);
    if (construction.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "construction",
          prefix: "mls.title",
        }),
        values: construction,
      });
    }

    const propertyInfo = propertyInfoValues(realEstateProperty?.propertyInfo);
    if (propertyInfo.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "propertyInfo",
          prefix: "mls.title",
        }),
        values: propertyInfo,
      });
    }

    const projectInfo = projectInfoValues(realEstateProperty?.projectInfo);
    if (projectInfo.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "projectInfo",
          prefix: "mls.title",
        }),
        values: projectInfo,
      });
    }

    const areaTotals = areaTotalsValues(realEstateProperty?.areaTotals);
    if (areaTotals.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "areaTotals",
          prefix: "mls.title",
        }),
        values: areaTotals,
      });
    }

    const dimensions = dimensionsValues(realEstateProperty?.dimensions);
    if (dimensions.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "dimensions",
          prefix: "mls.title",
        }),
        values: dimensions,
      });
    }

    const floors = floorsValues(realEstateProperty?.floors);
    if (floors.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "floors",
          prefix: "mls.title",
        }),
        values: floors,
      });
    }

    const counts = countsValues(realEstateProperty.counts);
    if (counts.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "counts",
          prefix: "mls.title",
        }),
        values: counts,
      });
    }

    const climatControl = climatControlValues(
      realEstateProperty?.climatControl
    );
    if (climatControl.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "climatControl",
          prefix: "mls.title",
        }),
        values: climatControl,
      });
    }

    const surroundings = surroundingsValues(realEstateProperty?.surroundings);
    if (surroundings.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "surroundings",
          prefix: "mls.title",
        }),
        values: surroundings,
      });
    }

    const gardens = gardensValues(realEstateProperty?.gardens);
    if (gardens.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "gardens",
          prefix: "mls.title",
        }),
        values: gardens,
      });
    }

    const garages = garagesValues(realEstateProperty?.garages);
    if (garages.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "garages",
          prefix: "mls.title",
        }),
        values: garages,
      });
    }

    const current = currentValues(realEstateProperty?.current);
    if (current.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "current",
          prefix: "mls.title",
        }),
        values: current,
      });
    }

    const facilities = facilitiesValues(realEstateProperty?.facilities);
    if (facilities.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "facilities",
          prefix: "mls.title",
        }),
        values: facilities,
      });
    }

    const legal = legalValues(realEstateProperty?.legal);
    if (legal.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "legal",
          prefix: "mls.title",
        }),
        values: legal,
      });
    }

    const evaluations = evaluationsValues(realEstateProperty?.evaluations);
    if (evaluations.length) {
      groups.push({
        name: createTranslation({
          type: MlsTranslationType.String,
          value: "evaluations",
          prefix: "mls.title",
        }),
        values: evaluations,
      });
    }

    return groups;
  }
);

export const useRealEstatePropertyValueGroups = (
  realEstateProperty?: RealEstateProperty,
  extIntl?: IntlShape
) => {
  intl = extIntl;
  if (!realEstateProperty) return;
  return realEstatePropertyValueGroups(realEstateProperty);
};
