import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  PublicationHistoryComponent,
  PublicationHistoryProps,
} from "./publication-history";
import { push } from "connected-react-router";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { RealEstatePropertySearchItem } from "@haywork/api/mls";

type StateProps = {};
type DispatchProps = {
  navigate: (path: string) => void;
  getPropertiesById: (
    bundleIds: string[]
  ) => Promise<RealEstatePropertySearchItem[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  PublicationHistoryProps,
  AppState
> = (state) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PublicationHistoryProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getPropertiesById: (bundleIds: string[]) =>
    dispatch(MLSThunk.List.getPropertiesById(bundleIds)),
});

export type PublicationHistoryContainerProps = StateProps & DispatchProps;
export const PublicationHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationHistoryComponent);
