import {
  ImagePlaceholder,
  TextPlaceholder
} from "@haywork/components/ui/empty-state";
import { Column, Row, ActionsColumn } from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo } from "react";

type Props = {
  zebra: boolean;
};

export const LoadingStateComponent: FC<Props> = memo(({ zebra }) => {
  return (
    <Row zebra={zebra}>
      <Column index={0}>
        <TextPlaceholder
          numberOfLines={1}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%"
          }}
        />
      </Column>
      <Column index={1}>
        <TextPlaceholder
          numberOfLines={1}
          style={{
            padding: 8,
            maxWidth: 300,
            height: "100%",
            width: "100%"
          }}
        />
      </Column>
      <Column index={2}>
        <TextPlaceholder
          numberOfLines={1}
          style={{
            padding: 8,
            height: "100%",
            width: "100%"
          }}
        />
      </Column>
      <Column index={3}>
        <TextPlaceholder
          numberOfLines={1}
          style={{
            padding: 8,
            height: "100%",
            width: "100%"
          }}
        />
      </Column>
      <Column index={4}>
        <TextPlaceholder
          numberOfLines={2}
          style={{
            padding: 8,
            height: "100%",
            width: "100%"
          }}
        />
      </Column>
      <Column index={5} />
      <Column index={6} />
      <ActionsColumn />
    </Row>
  );
});
