import { RealEstatePropertySearchItem } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { MultiListRowProps } from "@haywork/components/ui/multi-list";
import Pill from "@haywork/components/ui/pill";
import { MLSROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil, MlsUtil } from "@haywork/util";
import classNames from "classnames";
import head from "lodash-es/head";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber, useIntl } from "react-intl";
import { EmailAssignment } from "@haywork/util/email-v2";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = MultiListRowProps<RealEstatePropertySearchItem> & {
  selectedAssignmentsIds: string[];
  inEditMode: boolean;
  onNavigate: (path: string) => void;
  onToggleSelected: (assignment: EmailAssignment) => void;
};

export const MlsRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      style,
      zebra,
      data,
      selectedAssignmentsIds,
      inEditMode,
      onNavigate,
      onToggleSelected,
    }) => {
      const { imageUrls, address, bundleId, appClientKeys, financial, agency } =
        data as RealEstatePropertySearchItem;
      const intl = useIntl();

      const photo = useMemo(() => head(imageUrls), [imageUrls]);

      const displayName = useMemo(() => {
        const houseNumber = address?.houseNumber;
        const houseNumberPostfix = address?.houseNumberAddendum;
        const postalCode = address?.postalCode;
        const streetname = address?.street?.name;
        const cityName = address?.locality?.name;

        const adressLine1 = [streetname, houseNumber, houseNumberPostfix]
          .filter((d) => !!d)
          .join(" ");
        const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

        return [adressLine1, adressLine2].filter((d) => !!d).join(", ");
      }, [address]);

      const realtor = useMemo(() => {
        return agency?.name;
      }, [agency]);

      const emailAssignment = useMemo(
        () =>
          MlsUtil.mapPropertySearchItemToEmailAssignment(
            data as RealEstatePropertySearchItem,
            intl
          ),
        [data, intl]
      );

      const isSelected = useMemo(() => {
        return selectedAssignmentsIds.includes(bundleId);
      }, [bundleId, selectedAssignmentsIds]);

      const onClickHandler = useCallback(() => {
        const id = bundleId;
        const source = head(appClientKeys);
        const path = route(MLSROUTES.DETAIL.URI, { id, source });
        onNavigate(path);
      }, [bundleId, appClientKeys, onNavigate]);

      const toggleSelectedCallback = useCallback(
        (event: React.MouseEvent) => {
          event.stopPropagation();
          onToggleSelected(emailAssignment);
        },
        [emailAssignment, onToggleSelected]
      );

      return (
        <div
          style={style}
          styleName={classNames("row", { zebra })}
          onClick={onClickHandler}
        >
          {!inEditMode && (
            <div styleName="selected">
              <div
                styleName="selected__trigger"
                onClick={toggleSelectedCallback}
              >
                <Icon
                  name={isSelected ? "check-square" : "square"}
                  size={16}
                  light={!isSelected}
                  solid={isSelected}
                  color={isSelected ? Colors.Primary : Colors.MediumGray}
                />
              </div>
            </div>
          )}
          <div styleName={classNames("column", "thumb", { push: inEditMode })}>
            <div
              styleName="preview"
              style={{
                backgroundImage: !photo
                  ? null
                  : `url(${JSON.stringify(photo)})`,
              }}
            >
              {!photo && (
                <div styleName="preview__empty-state">
                  <Icon
                    name="camera-alt"
                    size={20}
                    color={Colors.MediumGray}
                    light
                  />
                </div>
              )}
            </div>
          </div>
          <div styleName="column meta">
            <div>
              <Pill
                label="searchAssignment.action.objectSuggestion"
                color={Colors.Secondary}
                tiny
                solid
                fullWidth
              />
            </div>

            {!!displayName && <div styleName="title">{displayName}</div>}

            {(!!financial?.rentPrice || !!financial?.purchasePrice) && (
              <div styleName="column-row">
                <div styleName="column-row__label">
                  {!!financial?.rentPrice ? (
                    <I18n value="mls.list.rentPrice" />
                  ) : (
                    <I18n value="mls.list.askingPrice" />
                  )}
                </div>
                <div styleName="column-row__value">
                  {!!financial?.rentPrice ? (
                    <FormattedNumber
                      value={financial?.rentPrice}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={0}
                      maximumFractionDigits={2}
                    />
                  ) : (
                    <FormattedNumber
                      value={financial?.purchasePrice}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={0}
                      maximumFractionDigits={2}
                    />
                  )}
                </div>
              </div>
            )}

            {!!realtor && (
              <div styleName="column-row">
                <div styleName="column-row__label">
                  <I18n
                    value={
                      !!financial?.rentPrice
                        ? "acquisitionAssignment.label.vendorRent"
                        : "acquisitionAssignment.label.vendorSale"
                    }
                  />
                </div>
                <div styleName="column-row__value">
                  <div styleName="pills">
                    <Pill
                      label={realtor}
                      icon="building"
                      color={Colors.Secondary}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
