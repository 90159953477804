import { intlContext } from "@haywork/app";
import PageHeader from "@haywork/components/ui/page-header";
import { COLOR_PICKER, REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  RelationGroupItemComponent,
  RelationGroupsContainerProps,
} from "@haywork/modules/settings";
import {
  ButtonLoader,
  InfiniteScroll,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import { ColorUtil } from "@haywork/util";
import * as React from "react";
import { ColorResult, GithubPicker } from "react-color";
import * as CSSModules from "react-css-modules";

const styles = require("./relation-groups.component.scss");
const colorPickerColors = [
  "#EC407A",
  "#673AB7",
  "#2196F3",
  "#009688",
  "#4CAF50",
  "#FFC107",
  "#795548",
  "#E53935",
  "#808080",
  "#BDC3C7",
  "#A9A9A9",
  "#C0C0C0",
  "#800000",
  "#E74C3C",
  "#BA2700",
  "#D35400",
  "#8E692D",
  "#E9E03F",
  "#808000",
  "#68CC66",
  "#349933",
  "#27AE60",
  "#2ECC71",
  "#16A085",
  "#4FB3B9",
  "#1273DE",
  "#2980B9",
  "#2C3E50",
  "#68A6F6",
  "#000080",
  "#8E44AD",
  "#9B59B6",
];

export interface RelationGroupsComponentProps {}
interface State {
  displayColorPicker: boolean;
  selectedColor: string;
  newGroupName: string;
}
type Props = RelationGroupsComponentProps & RelationGroupsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export default class RelationGroupsComponent extends React.Component<
  Props,
  State
> {
  private focusRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      selectedColor: COLOR_PICKER.DEFAULT_COLOR,
      newGroupName: "",
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.colorChanged = this.colorChanged.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onAddRelationGroup = this.onAddRelationGroup.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: RelationGroupsComponentProps & RelationGroupsContainerProps
  ) {
    if (
      this.props.relationAddGroupsStatus === REQUEST.PENDING &&
      nextProps.relationAddGroupsStatus === REQUEST.SUCCESS
    ) {
      this.setState({
        selectedColor: COLOR_PICKER.DEFAULT_COLOR,
        newGroupName: "",
      });
    }
  }

  public render() {
    return (
      <div styleName="relationGroupBody" ref={(ref) => (this.focusRef = ref)}>
        <PageHeader title="manageRelationGroups" subTitle="settingsMenuUsage" />

        <div styleName="addARelationGroup" className="section__container">
          <h2 className="section__title">
            <ResourceText resourceKey="addARelationGroup" />
          </h2>
          <div className="section__option">
            <div className="section__option__meta">
              {/* Color picker start */}
              <div styleName="colorPicker">
                <label htmlFor="colorPicker">
                  <ResourceText resourceKey="color" />
                </label>

                <div
                  styleName="swatch"
                  onClick={() => {
                    this.setState({
                      displayColorPicker: !this.state.displayColorPicker,
                    });
                  }}
                >
                  <div styleName="arrow">
                    <i className="fal fa-chevron-down" />
                  </div>
                  <div
                    styleName="color"
                    style={{ backgroundColor: this.state.selectedColor }}
                  />
                </div>

                {this.state.displayColorPicker && (
                  <div styleName="popover">
                    <div
                      styleName="cover"
                      onClick={() => {
                        this.setState({ displayColorPicker: false });
                      }}
                    />
                    <GithubPicker
                      colors={colorPickerColors}
                      width="212px"
                      onChange={this.colorChanged}
                    />
                  </div>
                )}
                {/* Color picker end */}
              </div>

              <div className="spacer" />

              {/* new group input field */}
              <div styleName="groupName">
                <label htmlFor="newGroupName">
                  <ResourceText resourceKey="whatIsTheRoleOfTheGroup" />
                </label>
                <input
                  id="newGroupName"
                  type="text"
                  name="newGroupName"
                  disabled={
                    this.props.relationAddGroupsStatus === REQUEST.PENDING
                  }
                  value={this.state.newGroupName}
                  placeholder={intlContext.formatMessage({
                    id: "newGroupName",
                  })}
                  onChange={this.onChangeHandler}
                  onKeyDown={this.onKeyDownHandler}
                  data-lpignore="true"
                />
              </div>
            </div>
            <div className="section__actions actions__noLabel">
              <button
                type="submit"
                className="btn btn-success"
                disabled={!this.state.newGroupName}
                onClick={this.onAddRelationGroup}
              >
                <ButtonLoader
                  resourceKey="addARelationGroupButton"
                  loading={
                    this.props.relationAddGroupsStatus === REQUEST.PENDING
                  }
                />
              </button>
            </div>
          </div>
        </div>

        <div styleName="listHeader">
          <ResourceText resourceKey="AllRelationGroups" />
        </div>

        <div styleName="list-body">
          {this.props.relationGroupsStatus === REQUEST.PENDING && (
            <PageLoader loading />
          )}
          {this.props.relationGroups.length > 0 && (
            <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
              {this.props.relationGroups.map((relationGroup, idx) => {
                let count = 0;
                if (
                  this.props.relationGroupsStatistics &&
                  this.props.relationGroupsStatistics.counts
                ) {
                  const statistics = this.props.relationGroupsStatistics.counts.find(
                    (count) => count.relationGroupId === relationGroup.id
                  );
                  if (statistics) {
                    count = statistics.totalCount;
                  }
                }

                return (
                  <ErrorBoundary key={relationGroup.id}>
                    <RelationGroupItemComponent
                      relationGroup={relationGroup}
                      relationGroupChanged={this.props.updateRelationGroup}
                      relationCount={count}
                      deleteRelationGroupItem={
                        this.props.deleteRelationGroupItem
                      }
                      unDeleteRelationGroupItem={
                        this.props.unDeleteRelationGroupItem
                      }
                      removeFromList={this.props.removeFromList}
                      relationGroupsStatus={this.props.relationGroupsStatus}
                      zebra={idx % 2 === 0}
                    />
                  </ErrorBoundary>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    // Do nothing
  }

  private onAddRelationGroup() {
    if (this.state.newGroupName && this.state.selectedColor) {
      this.props.addNewRelationGroup(
        ColorUtil.rgbToHex(this.state.selectedColor),
        this.state.newGroupName
      );
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      this.onAddRelationGroup();
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    value.trim().length > 0
      ? this.setState({ newGroupName: value })
      : this.setState({ newGroupName: "" });
  }

  private colorChanged(color: ColorResult) {
    this.setState({ selectedColor: color.hex, displayColorPicker: false });
  }
}
