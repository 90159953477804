import { AgendaItem } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { EmailMessage } from "@haywork/stores/email-v2";
import first from "lodash-es/first";
import * as PrintJS from "print-js";
import { FormatDateOptions } from "react-intl";

const url =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
const data = /data:image\/\w+\;base64\,[\w\W]+/i;

export class Print {
  public createMailTemplate(message: EmailMessage): string {
    const stripPageStyle = (messageBody: string) => {
      let newBody = messageBody
        .replace(/\@page[^}]*\}*/g, "")
        .replace(/\page:[^;]*\}*/g, "");

      newBody = newBody
        .replace(/<img([\w\W]+?)\/?>/gi, (match) => {
          return match.replace(/src=\"([^"']+)\"/gi, (m, $1) => {
            return url.test($1) || data.test($1) ? m : "";
          });
        })
        .replace(/src=\"\s*\"/gi, "");
      return newBody;
    };

    const to = (message.to || []).map((to) => {
      switch (true) {
        case !!to.name && !!to.email:
          return `${to.name} &lt;${to.email}&gt;`;
        default:
          return to.email;
      }
    });

    const from = (message.from || [])
      .map((from) => {
        switch (true) {
          case !!from.name && !!from.email:
            return `${from.name} &lt;${from.email}&gt;`;
          default:
            return from.email;
        }
      })
      .join(", ");

    const cc = (message.cc || [])
      .map((cc) => {
        switch (true) {
          case !!cc.name && !!cc.email:
            return `${cc.name} &lt;${cc.email}&gt;`;
          default:
            return cc.email;
        }
      })
      .join(", ");

    const date = intlContext.formatDate(message.date, {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const attachments = (message.files || [])
      .filter((file) => file.isAttachment)
      .map((file, idx) => {
        return `<tr>
        <td align="left" valign="top" class="email__header-label">
        ${
          idx === 0
            ? intlContext.formatMessage({
                id: "emailPrintTemplateAttachments",
                defaultMessage: "Attachments:",
              })
            : ""
        }
        </td>
        <td align="left" valign="top">${file.fileName}</td>`;
      })
      .join("");

    const ccString = !!cc
      ? `<tr>
    <td align="left" valign="top" class="email__header-label">${intlContext.formatMessage(
      {
        id: "emailPrintTemplateCC",
        defaultMessage: "CC:",
      }
    )}</td>
    <td align="left" valign="top">${cc}</td>
  </tr>`
      : "";

    const body = `
      <style type="text/css">
        .email {
          max-width: 670px;
          position: relative;
          margin: 0 auto;
        }
        .email__header {
          margin-bottom: 24px;
        }
        .email__header-title {
          font-size: 18px;
          font-weight: 700;
          border-bottom: 3px solid #000000;
          padding: 12px 0;
          margin-bottom: 12px;
        }
        .email__header-title table {
          width: 100%;
          border: none;
        }
        .email__header-title table td {
          padding: 5px 0;
        }
        .email__header-label {
          max-width: 172px;
          min-width: 96px;
          padding-right: 24px;
          font-weight: 700;
        }
      </style>
      <div class="email">
        <div class="email__header">
          <div class="email__header-title">${first(to)}</div>
          <div class="email__header-row">
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td align="left" valign="top" class="email__header-label">${intlContext.formatMessage(
                  {
                    id: "emailPrintTemplateFrom",
                    defaultMessage: "From:",
                  }
                )}</td>
                <td align="left" valign="top">${from}</td>
              </tr>
              ${ccString}
              <tr>
                <td align="left" valign="top" class="email__header-label">${intlContext.formatMessage(
                  {
                    id: "emailPrintTemplateDate",
                    defaultMessage: "Sent:",
                  }
                )}</td>
                <td align="left" valign="top">${date}</td>
              </tr>
              <tr>
                <td align="left" valign="top" class="email__header-label">${intlContext.formatMessage(
                  {
                    id: "emailPrintTemplateTo",
                    defaultMessage: "To:",
                  }
                )}</td>
                <td align="left" valign="top">${to.join(", ")}</td>
              </tr>
              <tr>
                <td align="left" valign="top" class="email__header-label">${intlContext.formatMessage(
                  {
                    id: "emailPrintTemplateSubject",
                    defaultMessage: "Subject:",
                  }
                )}</td>
                <td align="left" valign="top">${message.subject}</td>
              </tr>
              ${attachments}
            </table>
          </div>
        </div>
        <div class="email__body">
          ${stripPageStyle(message.body)}
        </div>
      </div>
    `;

    return body;
  }

  public createAppointmentTemplate(appointment: AgendaItem): string {
    const headerRows = [];
    let options: FormatDateOptions = {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    if (!appointment.allDayEvent) {
      options = {
        ...options,
        hour: "2-digit",
        minute: "2-digit",
      };
    }

    if (!!appointment.location) {
      const loc = appointment.location as any;
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateLocation",
              defaultMessage: "Location:",
            }
          )}</td>
          <td align="left" valign="top">${loc?.description}</td>
        </tr>
      `);
    }

    if (!!appointment.startDateTime) {
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateStart",
              defaultMessage: "Start:",
            }
          )}</td>
          <td align="left" valign="top">${intlContext.formatDate(
            appointment.startDateTime,
            options
          )}</td>
        </tr>
      `);
    }

    if (!!appointment.endDateTime) {
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateEnd",
              defaultMessage: "End:",
            }
          )}</td>
          <td align="left" valign="top">${intlContext.formatDate(
            appointment.endDateTime,
            options
          )}</td>
        </tr>
      `);
    }

    if (!!appointment.linkedEmployees && !!appointment.linkedEmployees.length) {
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateEmployees",
              defaultMessage: "Linked employees:",
            },
            { count: appointment.linkedEmployees.length }
          )}</td>
          <td align="left" valign="top">${appointment.linkedEmployees
            .map((employee) => employee.displayName)
            .join(", ")}</td>
        </tr>
      `);
    }

    if (
      !!appointment.linkedAssignments &&
      !!appointment.linkedAssignments.length
    ) {
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateAssignments",
              defaultMessage: "Linked assignments:",
            },
            { count: appointment.linkedAssignments.length }
          )}</td>
          <td align="left" valign="top">${appointment.linkedAssignments
            .map((assignment) => assignment.displayName)
            .join(", ")}</td>
        </tr>
      `);
    }

    if (!!appointment.linkedRelations && !!appointment.linkedRelations.length) {
      headerRows.push(`
        <tr>
          <td align="left" valign="top" class="appointment__row-label">${intlContext.formatMessage(
            {
              id: "appointmentPrintTemplateRelations",
              defaultMessage: "Linked relations:",
            },
            { count: appointment.linkedRelations.length }
          )}</td>
          <td align="left" valign="top">${appointment.linkedRelations
            .map((relation) => {
              const meansOfContact = [
                relation.phone,
                relation.phoneMobile,
                relation.phoneWork,
                relation.email,
              ].filter((contact) => !!contact);

              switch (true) {
                case meansOfContact.length > 0:
                  return `${relation.displayName} &lt;${meansOfContact.join(
                    ", "
                  )}&gt;`;
                default:
                  return relation.displayName;
              }
            })
            .join(", ")}</td>
        </tr>
      `);
    }

    return `
      <style type="text/css">
        .appointment {
          max-width: 670px;
          position: relative;
          margin: 0 auto;
        }
        .appointment__header {
          margin-bottom: 24px;
        }
        .appointment__header-title {
          font-size: 18px;
          font-weight: 700;
          border-bottom: 3px solid #000000;
          padding: 12px 0;
          margin-bottom: 12px;
        }
        .appointment__header-row table {
          width: 100%;
          border: none;
        }
        .appointment__header-row table td {
          padding: 5px 0;
        }
        .appointment__row-label {
          max-width: 172px;
          min-width: 96px;
          padding-right: 24px;
          font-weight: 700;
        }
      </style>
      <div class="appointment">
        <div class="appointment__header">
          <div class="appointment__header-title">${appointment.subject}</div>
          <div class="appointment__header-row">
            <table cellspacing="0" cellpadding="0">
              ${headerRows.join("")}
            </table>
          </div>
        </div>
        <div class="appointment__body" style="white-space:pre-line">
          ${appointment.description}
        </div>
      </div>`;
  }

  public print(printable: string, documentTitle: string) {
    try {
      PrintJS({
        printable,
        documentTitle,
        type: "raw-html",
      });
    } catch (error) {
      throw error;
    }
  }
}
