import { RealEstateGroup } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware/thunk";
import { NewSearchAssignmentComponent } from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {}
export interface DispatchProps {
  createSearchAssignment: (type: RealEstateGroup, relationId: string) => void;
}

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => ({});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  createSearchAssignment: (type: RealEstateGroup, relationId: string) =>
    dispatch(SearchAssignmentThunks.createSearchAssignment(type, relationId))
});

export type NewSearchAssignmentContainerProps = StateProps & DispatchProps;
export const NewSearchAssignmentContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(NewSearchAssignmentComponent);
