import { REDUX } from "@haywork/constants";
import { Status } from "@haywork/stores";

import * as ActionType from "./overview.types";

const setOrdering = (payload: ActionType.Ordering) => ({
  type: REDUX.INVOICES.SET_INVOICES_ORDERING,
  ...payload
});

const setInvoicesStatus = (payload: Status) => ({
  type: REDUX.INVOICES.SET_INVOICES_STATUS,
  ...payload
});

const setInvoices = (payload: ActionType.InvoicesResponse) => ({
  type: REDUX.INVOICES.SET_INVOICES,
  ...payload
});

const appendInvoices = (payload: ActionType.InvoicesResponse) => ({
  type: REDUX.INVOICES.APPEND_INVOICES,
  ...payload
});

const deleteInvoiceFromList = (payload: ActionType.Identifier) => ({
  type: REDUX.INVOICES.DELETE_INVOICE_FROM_LIST,
  ...payload
});

const archiveInvoiceFromList = (payload: ActionType.Identifier) => ({
  type: REDUX.INVOICES.ARCHIVE_INVOICE_FROM_LIST,
  ...payload
});

const unArchiveInvoiceFromList = (payload: ActionType.Identifier) => ({
  type: REDUX.INVOICES.UNARCHIVE_INVOICE_FROM_LIST,
  ...payload
});

const setInvoiceFiltering = (payload: ActionType.InvoiceFilteringResponse) => ({
  type: REDUX.INVOICES.SET_INVOICE_FILTERING,
  ...payload
});

const clearAllFilters = () => ({
  type: REDUX.INVOICES.CLEAR_ALL_FILTERS
});

const updateListItem = (payload: ActionType.SnapShot) => ({
  type: REDUX.INVOICES.UPDATE_LIST_ITEM,
  ...payload
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.INVOICES.DELETE_LIST_ITEM,
  ...payload
});

export const InvoiceOverviewActions = {
  setOrdering,
  setInvoicesStatus,
  setInvoices,
  appendInvoices,
  deleteInvoiceFromList,
  archiveInvoiceFromList,
  unArchiveInvoiceFromList,
  setInvoiceFiltering,
  clearAllFilters,
  updateListItem,
  deleteListItem
};
