import {
  AcquisitionObjectAssignment,
  ListingType as lType
} from "@haywork/api/kolibri";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import ListingType, {
  ListingTypeResponse
} from "@haywork/modules/acquisition/components/listing-type";
import {
  AssignmentBogFeaturesAndFiguresComponent,
  AssignmentBogParticularsComponent,
  AssignmentEditBogHotelRestaurantCafeComponent,
  AssignmentEditBogInvestmentComponent,
  AssignmentEditBogLeisureComponent,
  AssignmentEditBogLotComponent,
  AssignmentEditBogOfficeComponent,
  AssignmentEditBogOtherComponent,
  AssignmentEditBogOutdoorAreaComponent,
  AssignmentEditBogProductionHallComponent,
  AssignmentEditBogRetailSpaceComponent,
  AssignmentEditBogSocialPropertyComponent
} from "@haywork/modules/assignment/components/edit-content/bog";
import { Form, FormControls, FormReturnValue } from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditObjectCommercialScreenContainerProps } from "./assignments-edit-object-commercial.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquistionAssignmentEditObjectCommercialScreenProps = {};
type Props = AcquistionAssignmentEditObjectCommercialScreenProps &
  AcquistionAssignmentEditObjectCommercialScreenContainerProps;

export const AcquistionAssignmentEditObjectCommercialScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionObjectAssignment,
      updateAcquisitionAssignmentObject,
      constructionPeriods,
      welfareStateOptions,
      airTreatmentOptions,
      industrialBuildingFacilityOptions,
      facilityOptions,
      constructionOptionOptions,
      leisureFacilityTypeOptions,
      residentialBuildingTypeOptions,
      socialPropertyFacilityTypeOptions,
      typesOfCommunalArea,
      pavementTypeOptions,
      conditionOptions,
      certificationOptions,
      parkingFacilityOptions,
      typeOfBusinessLawOptions,
      furnishingOptions
    }) => {
      const {
        realEstateGroup,
        listingType,
        houseSort,
        houseType,
        houseCharacteristic,
        apartmentSort,
        apartmentCharacteristic,
        parkingType,
        residentialSubtypeOther,
        forRent,
        isNew
      } = acquisitionObjectAssignment;

      const isForSale = useMemo(() => {
        return forRent === true ? false : true;
      }, [forRent]);

      const formControls = useMemo(() => {
        return { forSale: { value: isForSale } } as FormControls;
      }, []);

      const handleListingChange = (listingTypes: ListingTypeResponse) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });

        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          ...listingTypes
        };

        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const onChangeHandler = (values: FormReturnValue) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });

        let updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment
        };

        updatedAcquisitionAssignmentObject = {
          ...updatedAcquisitionAssignmentObject
        };
        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const subFormChangeHandler = (fields) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });
        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          ...fields
        };
        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      return (
        <div styleName="edit-detail" id="scroll-to-top">
          <div className="container-fluid">
            <Form
              name="acquisition-object-object-agricultural"
              formControls={formControls}
              onChange={onChangeHandler}
            >
              <StepperComponent initial={0} scrollToElementId="scroll-to-top">
                <StepComponent title="acquisition.assignment.edit.typeOfBuild">
                  <div className="form__row">
                    <ListingType
                      realEstateGroup={realEstateGroup}
                      listingType={listingType}
                      houseSort={houseSort}
                      houseType={houseType}
                      houseCharacteristic={houseCharacteristic}
                      apartmentSort={apartmentSort}
                      apartmentCharacteristic={apartmentCharacteristic}
                      parkingType={parkingType}
                      residentialSubtypeOther={residentialSubtypeOther}
                      isNew={isNew}
                      onChange={handleListingChange}
                    />
                  </div>
                </StepComponent>
                <StepComponent title="buildingPeriod">
                  <AssignmentBogFeaturesAndFiguresComponent
                    objectAssignment={acquisitionObjectAssignment}
                    constructionPeriods={constructionPeriods}
                    asSubForm={true}
                    onChange={subFormChangeHandler}
                  />
                </StepComponent>
                <StepComponent title="particulars">
                  <AssignmentBogParticularsComponent
                    objectAssignment={acquisitionObjectAssignment}
                    conditionOptions={conditionOptions}
                    certificationOptions={certificationOptions}
                    parkingFacilityOptions={parkingFacilityOptions}
                    typeOfBusinessLawOptions={typeOfBusinessLawOptions}
                    furnishingOptions={furnishingOptions}
                    asSubForm={true}
                    onChange={subFormChangeHandler}
                  />
                </StepComponent>
                {acquisitionObjectAssignment.listingType ===
                  lType.RetailSpace && (
                  <StepComponent title="retailSpace">
                    <AssignmentEditBogRetailSpaceComponent
                      objectAssignment={acquisitionObjectAssignment}
                      welfareStateOptions={welfareStateOptions}
                      typesOfCommunalArea={typesOfCommunalArea}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.ProductionHall && (
                  <StepComponent title="productionHall">
                    <AssignmentEditBogProductionHallComponent
                      objectAssignment={acquisitionObjectAssignment}
                      airTreatmentOptions={airTreatmentOptions}
                      industrialBuildingFacilityOptions={
                        industrialBuildingFacilityOptions
                      }
                      asSubForm={true}
                      facilityOptions={facilityOptions}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType === lType.Office && (
                  <StepComponent title="bogOffice">
                    <AssignmentEditBogOfficeComponent
                      objectAssignment={acquisitionObjectAssignment}
                      airTreatmentOptions={airTreatmentOptions}
                      facilityOptions={facilityOptions}
                      typesOfCommunalArea={typesOfCommunalArea}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.HotelRestaurantCafe && (
                  <StepComponent title="hotelRestaurantCafe">
                    <AssignmentEditBogHotelRestaurantCafeComponent
                      objectAssignment={acquisitionObjectAssignment}
                      welfareStateOptions={welfareStateOptions}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType === lType.Lot && (
                  <StepComponent title="lot">
                    <AssignmentEditBogLotComponent
                      objectAssignment={acquisitionObjectAssignment}
                      constructionOptionOptions={constructionOptionOptions}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType === lType.Leisure && (
                  <StepComponent title="leisure">
                    <AssignmentEditBogLeisureComponent
                      objectAssignment={acquisitionObjectAssignment}
                      leisureFacilityTypeOptions={leisureFacilityTypeOptions}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.OutdoorArea && (
                  <StepComponent title="outdoorArea">
                    <AssignmentEditBogOutdoorAreaComponent
                      objectAssignment={acquisitionObjectAssignment}
                      pavementTypeOptions={pavementTypeOptions}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {(acquisitionObjectAssignment.listingType === lType.Other ||
                  acquisitionObjectAssignment.listingType === lType.Garage ||
                  acquisitionObjectAssignment.listingType ===
                    lType.PracticeSpace ||
                  acquisitionObjectAssignment.listingType ===
                    lType.WarehouseProduction) && (
                  <StepComponent title="otherTitle">
                    <AssignmentEditBogOtherComponent
                      objectAssignment={acquisitionObjectAssignment}
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.Investment && (
                  <StepComponent title="investment">
                    <AssignmentEditBogInvestmentComponent
                      objectAssignment={acquisitionObjectAssignment}
                      residentialBuildingTypeOptions={
                        residentialBuildingTypeOptions
                      }
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.SocialProperty && (
                  <StepComponent title="socialProperty">
                    <AssignmentEditBogSocialPropertyComponent
                      objectAssignment={acquisitionObjectAssignment}
                      socialPropertyFacilityTypeOptions={
                        socialPropertyFacilityTypeOptions
                      }
                      asSubForm={true}
                      onChange={subFormChangeHandler}
                    />
                  </StepComponent>
                )}
              </StepperComponent>
            </Form>
          </div>
        </div>
      );
    }
  )
);
