import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import {
  DefaultFolderTreeCategory,
  DefaultFolderTreeSnapShot,
} from "@haywork/api/kolibri";
import { COMMON } from "@haywork/constants";

const defaultFolderTreesSelector = (state: AppState) =>
  state.settings.folderTrees.defaultFolderTrees;

const masterTableSelector = (state: AppState) => state.main.mastertable;

export interface DefaultFolderTreesGroup {
  type: "relation" | "sale" | "rent" | "other";
  categories: DefaultFolderTreeSnapShot[];
}
export const defaultFolderTrees = createSelector(
  defaultFolderTreesSelector,
  (defaultFolderTrees) => {
    const groups: DefaultFolderTreesGroup[] = [
      {
        type: "relation",
        categories: [
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.ConctactPersons,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.ContactCompanies,
            id: COMMON.EMPTY_GUID,
          },
        ],
      },
      {
        type: "rent",
        categories: [
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentResidentialForRent,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentCommercialForRent,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentAgriculturalForRent,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.AcquisitionAssignmentForRent,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.AcquisitionObjectAssignmentForRent,
            id: COMMON.EMPTY_GUID,
          },
        ],
      },
      {
        type: "sale",
        categories: [
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentResidentialForSale,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentCommercialForSale,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.ObjectAssignmentAgriculturalForSale,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.AcquisitionAssignmentForSale,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category:
              DefaultFolderTreeCategory.AcquisitionObjectAssignmentForSale,
            id: COMMON.EMPTY_GUID,
          },
        ],
      },
      {
        type: "other",
        categories: [
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.ObjectTypeAssignmentResidential,
            id: COMMON.EMPTY_GUID,
          },
          {
            realEstateAgencyId: COMMON.EMPTY_GUID,
            category: DefaultFolderTreeCategory.ProjectAssignmentResidential,
            id: COMMON.EMPTY_GUID,
          },
        ],
      },
    ];

    return defaultFolderTrees.reduce((state, folderTree) => {
      const { category } = folderTree;

      switch (category) {
        case DefaultFolderTreeCategory.ConctactPersons:
        case DefaultFolderTreeCategory.ContactCompanies: {
          const group = state.find((group) => group.type === "relation");
          group.categories = group.categories.map((category) => {
            if (category.category === folderTree.category) {
              return folderTree;
            }
            return category;
          });
          break;
        }
        case DefaultFolderTreeCategory.ObjectAssignmentResidentialForRent:
        case DefaultFolderTreeCategory.ObjectAssignmentCommercialForRent:
        case DefaultFolderTreeCategory.ObjectAssignmentAgriculturalForRent:
        case DefaultFolderTreeCategory.AcquisitionAssignmentForRent:
        case DefaultFolderTreeCategory.AcquisitionObjectAssignmentForRent: {
          const group = state.find((group) => group.type === "rent");
          group.categories = group.categories.map((category) => {
            if (category.category === folderTree.category) {
              return folderTree;
            }
            return category;
          });
          break;
        }
        case DefaultFolderTreeCategory.ObjectAssignmentResidentialForSale:
        case DefaultFolderTreeCategory.ObjectAssignmentCommercialForSale:
        case DefaultFolderTreeCategory.ObjectAssignmentAgriculturalForSale:
        case DefaultFolderTreeCategory.AcquisitionAssignmentForSale:
        case DefaultFolderTreeCategory.AcquisitionObjectAssignmentForSale: {
          const group = state.find((group) => group.type === "sale");
          group.categories = group.categories.map((category) => {
            if (category.category === folderTree.category) {
              return folderTree;
            }
            return category;
          });
          break;
        }
        case DefaultFolderTreeCategory.ObjectTypeAssignmentResidential:
        case DefaultFolderTreeCategory.ProjectAssignmentResidential: {
          const group = state.find((group) => group.type === "other");
          group.categories = group.categories.map((category) => {
            if (category.category === folderTree.category) {
              return folderTree;
            }
            return category;
          });
          break;
        }
        default: {
          break;
        }
      }

      return state;
    }, groups);
  }
);

export const defaultFolderCategories = createSelector(
  masterTableSelector,
  (mastertable) => {
    return mastertable.kolibri.defaultFolderTreeCategoryOptions || [];
  }
);
