import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  Address,
  AssignmentOrderByField,
  AssignmentsClient,
  BatchItem,
  BatchItemType,
  BatchOption,
  BlobsClient,
  ContactCompaniesClient,
  ContactPersonsClient,
  EmployeesClient,
  FinancialAdministrationsClient,
  Invoice,
  InvoiceDueTerm,
  InvoiceLine,
  InvoiceOrderByField,
  InvoicesClient,
  InvoiceSnapShot,
  InvoiceType,
  LinkedAssignment,
  LinkedRelation,
  ObjectAssignmentsClient,
  RelationType,
  ReportCategory,
  SortOrder,
} from "@haywork/api/kolibri";
import { INVOICEROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  ErrorActions,
  InvoiceActions,
  InvoiceFiltering,
  InvoiceListActions,
  InvoiceOverviewActions,
  LayoutActions,
} from "@haywork/stores";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { AsyncUtil, DateUtil, RelationUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import * as deepEqual from "deep-equal";
import findIndex from "lodash-es/findIndex";
import first from "lodash-es/first";
import head from "lodash-es/head";
import * as moment from "moment";
import { Dispatch } from ".";
import { saveAs } from "file-saver";
import { ReportsClient } from "@haywork/api/reports";
import { mapInvoiceToInvoiceSnapShot } from "@haywork/mappers/invoice";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const setInvoicesOrdering = (
  order: SortOrder,
  orderBy: InvoiceOrderByField,
  reload: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceOverviewActions.setOrdering({ order, orderBy }));
    if (!!reload) {
      dispatch(getInvoices(true));
    }
  };
};

const getInvoices = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      InvoiceOverviewActions.setInvoicesStatus({ status: REQUEST.PENDING })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      invoices,
      order,
      orderBy,
      filters: unMappedFilters,
    } = state.invoice.overview;

    const filters = {
      ...unMappedFilters,
      filterByActive:
        unMappedFilters.filterByActive.length === 1
          ? unMappedFilters.filterByActive[0]
          : ActiveFilter.ActiveOrInactive,
    };

    const InvoiceClient = new InvoicesClient(host);

    try {
      const result = await parseRequest.response(
        InvoiceClient.search(
          {
            skip: init ? 0 : invoices.page * take,
            take,
            order,
            skipSystemInvoicesAreMissingCheck: false,
            orderBy,
            ...filters,
          },
          realEstateAgencyId
        )
      );

      dispatch(
        init
          ? InvoiceOverviewActions.setInvoices({ ...result, take })
          : InvoiceOverviewActions.appendInvoices({ ...result, take })
      );
    } catch (error) {
      dispatch(
        InvoiceOverviewActions.setInvoicesStatus({ status: REQUEST.ERROR })
      );
      throw error;
    }
  };
};

const deleteInvoice = (
  id: string,
  undeleteCallback?: () => void,
  redirectToOverview?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(INVOICEROUTES.DETAIL.URI, { id });

    const Invoices = new InvoicesClient(host);

    try {
      await parseRequest.response(Invoices.delete(id, realEstateAgencyId));

      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.SUCCESS));

      if (!!undeleteCallback) {
        dispatch(
          SnackbarActions.addToast({
            value: "invoice.toast.deleted",
            callback: async () => {
              await dispatch(unDeleteInvoice(id));
              undeleteCallback();
            },
            callbackLabel: "invoice.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }

      if (!!redirectToOverview) {
        dispatch(EditableThunks.remove(path));
      }
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unDeleteInvoice = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Invoices = new InvoicesClient(host);

    try {
      await parseRequest.response(
        Invoices.undelete({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const archiveInvoice = (
  id: string,
  updateComponentState?: boolean,
  close = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(INVOICEROUTES.DETAIL.URI, { id });

    const Invoices = new InvoicesClient(host);

    try {
      await parseRequest.response(Invoices.archive({ id }, realEstateAgencyId));

      dispatch(InvoiceOverviewActions.archiveInvoiceFromList({ id }));
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.SUCCESS));

      if (!!updateComponentState) {
        const { currentComponentState } = state.editable;
        const componentState = {
          ...currentComponentState,
          isActive: false,
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState,
            ignoreChanges: true,
            path,
          })
        );
      }
      if (close) {
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.IDLE));
        dispatch(EditableThunks.remove(path));
      }
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unArchiveInvoice = (id: string, updateComponentState?: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(INVOICEROUTES.DETAIL.URI, { id });

    const Invoices = new InvoicesClient(host);

    try {
      await parseRequest.response(
        Invoices.unarchive({ id }, realEstateAgencyId)
      );

      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.SUCCESS));
      if (!!updateComponentState) {
        const { currentComponentState } = state.editable;
        const componentState = {
          ...currentComponentState,
          isActive: true,
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState,
            ignoreChanges: true,
            path,
          })
        );
      }
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const invoiceFilterChange = (filters: InvoiceFiltering) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { filters: oldFilters } = state.invoice.overview;

    const newFilters: InvoiceFiltering = {
      ...filters,
      invoiceDateFrom: filters.invoiceDateFrom || null,
      invoiceDateUntil: filters.invoiceDateUntil || null,
      overdueFrom: filters.overdueFrom || null,
      overdueUntil: filters.overdueUntil || null,
    };

    if (!deepEqual(newFilters, oldFilters)) {
      dispatch(
        InvoiceOverviewActions.setInvoiceFiltering({ filters: newFilters })
      );
      dispatch(getInvoices(true));
    }
  };
};

const clearAllFilters = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceOverviewActions.clearAllFilters());
    dispatch(getInvoices(true));
  };
};

const createInvoice = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id } = state.account.financialAdministrations[0];
    const invoiceType = InvoiceType.BusinessToBusinessInvoice;

    const InvoiceClient = new InvoicesClient(host);

    try {
      let result = await parseRequest.response(
        InvoiceClient.defineNew(
          { financialAdministrationId: id, invoiceType },
          realEstateAgencyId
        )
      );

      const invoiceDate = moment(result.invoice.invoiceDate)
        .set("hours", 0)
        .set("minutes", 0)
        .set("seconds", 0)
        .set("milliseconds", 0);

      result = {
        ...result,
        invoice: {
          ...result.invoice,
          invoiceDate: invoiceDate.toDate(),
          dueDate: invoiceDate.add(14, "days").toDate(),
        },
      };

      const path = route(INVOICEROUTES.DETAIL.URI, { id: result.invoice.id });
      const invoice: Invoice = {
        ...result.invoice,
        lines: [
          {
            amount: 0,
            taxPercentage: 0,
            totalPriceGross: 0,
            totalPriceNet: 0,
            totalTax: 0,
            unitPriceGross: 0,
            unitPriceNet: 0,
            unitTax: 0,
          },
        ],
        invoiceType: InvoiceType.BusinessToConsumerInvoice,
      };

      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.FINANCE.ICON,
          componentState: invoice,
          path,
          title: "...",
          confirm: {
            title: { key: "saveInvoiceConfirmTitle" },
            body: { key: "saveInvoiceConfirmBody" },
          },
          entityType: RootEntityType.Invoice,
          entityId: invoice.id,
        })
      );
      dispatch(InvoiceActions.setSingleInvoice({ invoice }));
      dispatch(push(path));
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      throw error;
    }
  };
};

const getInvoice = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Invoice = new InvoicesClient(host);

    try {
      const invoice = await parseRequest.response(
        Invoice.read(id, realEstateAgencyId).then(
          (response) => response.invoice
        )
      );

      const path = route(INVOICEROUTES.DETAIL.URI, {
        id: invoice.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.FINANCE.ICON,
          componentState: invoice,
          path,
          title: invoice.displayName,
          entityType: RootEntityType.Invoice,
          entityId: invoice.id,
        })
      );

      if (invoice.linkedAssignment) {
        dispatch(getDataFromAssignment(invoice.linkedAssignment.id));
      }

      dispatch(InvoiceActions.setSingleInvoice({ invoice }));
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveInvoice = (invoice: Invoice, close: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));
    const state = getState();
    const { host, reportsHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { defaultCulture } = state.account.accountSettings;

    const path = route(INVOICEROUTES.DETAIL.URI, { id: invoice.id });
    const Invoices = new InvoicesClient(host);
    const Reports = new ReportsClient(reportsHost);

    try {
      const updatedInvoice = await parseRequest.response(
        Invoices.save({ invoice }, realEstateAgencyId)
      );

      if (close) {
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.IDLE));
        dispatch(EditableThunks.remove(path));
      } else {
        const { invoice: componentState } = updatedInvoice;
        dispatch(
          EditableActions.updateComponentState({
            componentState,
            path,
            ignoreChanges: true,
          })
        );

        const printTemplates = await parseRequest.response(
          Reports.searchTemplates(
            {
              invoiceId: updatedInvoice.invoice.id,
              culture: defaultCulture,
              category: ReportCategory.Invoices,
            },
            realEstateAgencyId
          ).then((response) => response.result)
        );
        dispatch(InvoiceActions.setPrintTemplates({ printTemplates }));

        const printExportOptions = await parseRequest.response(
          Reports.getDocumentFormats(realEstateAgencyId)
        );

        dispatch(InvoiceActions.setPrintExportOptions({ printExportOptions }));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.IDLE));
      }
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const setFinancialAdministration = (
  id: string,
  ignoreChanges: boolean,
  path?: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const FinancialAdministrations = new FinancialAdministrationsClient(host);

    FinancialAdministrations.read(id, realEstateAgencyId)
      .then((result) => {
        dispatch(
          InvoiceActions.setFinancialAdministration({
            financialAdministration: result.financialAdministration,
          })
        );
        if (!!path) {
          const updatedInvoiceLines: InvoiceLine[] = [];
          const { currentComponentState } = state.editable;

          state.editable.currentComponentState.lines.map((line) => {
            result.financialAdministration.products.map((product) => {
              if (product.id === line.productID) {
                updatedInvoiceLines.push(line);
              }
            });
          });

          const updatedInvoice: Invoice = {
            ...currentComponentState,
            financialAdministrationID: result.financialAdministration.id,
            lines: updatedInvoiceLines,
          };

          dispatch(
            EditableActions.updateComponentState({
              componentState: updatedInvoice,
              path,
              ignoreChanges,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      });
  };
};

const getDataFromAssignment = (id: string, newAssignment: boolean = false) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);

    let linkedAssignmentSnapShot;

    Assignments.search(
      {
        forRent: true,
        forSale: true,
        includeStatistics: false,
        orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Ascending,
        skip: 0,
        take: 1,
        assignmentIds: [id],
      },
      realEstateAgencyId
    )
      .then((result) => {
        linkedAssignmentSnapShot = result.results[0];
        return ObjectAssignments.read(id, realEstateAgencyId);
      })
      .then((result) => {
        dispatch(
          InvoiceActions.setInvoiceLinkedAssignment({
            linkedAssignment: result.objectAssignment,
            linkedAssignmentSnapShot,
          })
        );

        const { currentComponentState } = state.editable;
        if (
          currentComponentState &&
          newAssignment &&
          !currentComponentState.linkedRelation &&
          result.objectAssignment.linkedVendors &&
          result.objectAssignment.linkedVendors.length > 0
        ) {
          const path = route(INVOICEROUTES.DETAIL.URI, {
            id: currentComponentState.id,
          });
          const linkedRelation = result.objectAssignment.linkedVendors[0];
          const componentState: Invoice = {
            ...currentComponentState,
            linkedRelation,
          };

          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      });
  };
};

const udpateTotalPrices = (invoiceLines: InvoiceLine[]) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const path = route(INVOICEROUTES.DETAIL.URI, {
      id: currentComponentState.id,
    });

    let totalPriceNet: number = 0;
    let totalTax: number = 0;

    invoiceLines.map((line: any) => {
      if (!!line.unitPriceNet && !!line.amount && !!line.taxPercentage) {
        const lineAmount = parseFloat(line.amount);
        const lineUnitPriceNet = parseFloat(line.unitPriceNet);
        const lineTotalPriceNet = lineUnitPriceNet * lineAmount;
        const lineTaxPercentage = parseFloat(line.taxPercentage) / 100;
        const lineUnitTaxPrice = lineTaxPercentage * lineUnitPriceNet;
        const lineTotalTaxPrice = lineUnitTaxPrice * lineAmount;

        totalPriceNet += lineTotalPriceNet;
        totalTax += lineTotalTaxPrice;
      }
    });

    const totalPriceGross: number = totalPriceNet + totalTax;
    const componentState: Invoice = {
      ...currentComponentState,
      totalPriceGross,
      totalPriceNet,
      totalTax,
    };

    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    );
  };
};

const saveAndCloseInvoice = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const unpreparedInvoice: Invoice = state.editable.currentComponentState;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: unpreparedInvoice.id });
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Invoices = new InvoicesClient(host);

    const invoice = {
      ...unpreparedInvoice,
      lines: unpreparedInvoice.lines.filter((l) => !!l.productID),
    };

    Invoices.save({ invoice }, realEstateAgencyId)
      .then((result) => {
        dispatch(EditableThunks.remove(path));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.IDLE));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      });
  };
};

const updateDueDateWithLinkedAssignment = (
  linkedAssignment: LinkedAssignment
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentComponentState } = state.editable;

    const Assignment = new ObjectAssignmentsClient(host);
    const route = RouteUtil.mapStaticRouteValues(INVOICEROUTES.DETAIL.URI, {
      id: currentComponentState.id,
    });

    Assignment.read(linkedAssignment.id, realEstateAgencyId)
      .then((result) => {
        if (
          !!result.objectAssignment.saleOffer &&
          !!result.objectAssignment.saleOffer.dateTransfer
        ) {
          const date = new Date(result.objectAssignment.saleOffer.dateTransfer);
          const { currentComponentState } = state.editable;
          const componentState = {
            ...currentComponentState,
            dueDate: date,
            dueDateText: InvoiceDueTerm.TransportDate,
          };

          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path: route,
            })
          );
        } else if (
          !!result.objectAssignment.rentOffer &&
          !!result.objectAssignment.rentOffer.rentedFrom
        ) {
          const date = new Date(result.objectAssignment.rentOffer.rentedFrom);
          const { currentComponentState } = state.editable;
          const componentState = {
            ...currentComponentState,
            dueDate: date,
            dueDateText: InvoiceDueTerm.TransportDate,
          };

          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path: route,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
        dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      });
  };
};

const exportInvoices = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host, apiVersion } = state.appSettings;
    const { filters } = state.invoice.overview;

    const props = {
      take: 500,
      access_token: state.access.token,
      ...filters,
      order: SortOrder.Ascending,
      orderBy: InvoiceOrderByField.CreationDate,
    };

    const url = `${host}/${apiVersion}/${realEstateAgencyId}/Invoices/SearchAndExport?${RouteUtil.mapObjectToGetParams(
      props
    )}`;

    window.open(url, "_blank");
  };
};

const getPrintTemplates = (invoiceId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { reportsHost } = state.appSettings;
    const { defaultCulture } = state.account.accountSettings;

    const Reports = new ReportsClient(reportsHost);

    Reports.searchTemplates(
      { invoiceId, culture: defaultCulture, category: ReportCategory.Invoices },
      realEstateAgencyId
    )
      .then((result) => {
        const { result: printTemplates } = result;
        dispatch(InvoiceActions.setPrintTemplates({ printTemplates }));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
      });
  };
};

const getPrintFormats = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { reportsHost } = state.appSettings;

    const Reports = new ReportsClient(reportsHost);

    Reports.getDocumentFormats(realEstateAgencyId)
      .then((printExportOptions) => {
        dispatch(InvoiceActions.setPrintExportOptions({ printExportOptions }));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError(error));
      });
  };
};

const getDataFromRelation = (id: string, typeOfRelation: RelationType) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    let componentState: Invoice = state.editable.currentComponentState;
    const path = route(INVOICEROUTES.DETAIL.URI, { id: componentState.id });

    let formalName: string;
    let customerAddress: Address;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);
    const Employees = new EmployeesClient(host);

    try {
      switch (typeOfRelation) {
        case RelationType.ContactPerson: {
          const person = await ContactPersons.read(id, realEstateAgencyId).then(
            (result) => result.contactPerson
          );
          const { address, postalAddress, addressLine } = person;
          customerAddress = !!postalAddress ? postalAddress : address;
          formalName = addressLine || RelationUtil.getFormalSalutation(person);
          break;
        }
        case RelationType.Employee: {
          const employee = await Employees.read(id, realEstateAgencyId).then(
            (result) => result.employee
          );
          const { address, postalAddress, addressLine } = employee;
          customerAddress = !!postalAddress ? postalAddress : address;
          formalName =
            addressLine || RelationUtil.getFormalSalutation(employee);
          break;
        }
        case RelationType.ContactCompany: {
          const company = await ContactCompanies.read(
            id,
            realEstateAgencyId
          ).then((result) => result.contactCompany);
          const { address, postalAddress, displayName, taxNumber } = company;
          customerAddress = !!postalAddress ? postalAddress : address;
          formalName = displayName;
          if (!!taxNumber) {
            componentState = {
              ...componentState,
              taxNumberCustomer: taxNumber,
            };
          }
          break;
        }
        default:
          return;
      }

      componentState = {
        ...componentState,
        customerAddress,
      };

      dispatch(EditableActions.updateComponentState({ componentState, path }));
      dispatch(InvoiceActions.setFormalName(formalName));
    } catch (error) {
      dispatch(ErrorActions.setError(error));
    }
  };
};

const createInvoiceWithLinkedEntities = (
  linkedRelation?: LinkedRelation,
  linkedAssignment?: LinkedAssignment,
  relationType?: RelationType,
  customerAddress?: Address,
  type?: "Buisiness" | "Consumer"
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id } = state.account.financialAdministrations[0];
    const invoiceType = !type
      ? relationType === RelationType.ContactPerson
        ? InvoiceType.BusinessToConsumerInvoice
        : InvoiceType.BusinessToBusinessInvoice
      : type === "Consumer"
      ? InvoiceType.BusinessToConsumerInvoice
      : InvoiceType.BusinessToBusinessInvoice;

    const InvoiceClient = new InvoicesClient(host);
    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);
    const Employees = new EmployeesClient(host);

    try {
      let formalName: string;

      let result = await parseRequest.response(
        InvoiceClient.defineNew(
          { financialAdministrationId: id, invoiceType },
          realEstateAgencyId
        )
      );

      const invoiceDate = moment(result.invoice.invoiceDate)
        .set("hours", 0)
        .set("minutes", 0)
        .set("seconds", 0)
        .set("milliseconds", 0);

      result = {
        ...result,
        invoice: {
          ...result.invoice,
          invoiceDate: invoiceDate.toDate(),
          dueDate: invoiceDate.add(14, "days").toDate(),
          linkedAssignment,
          linkedRelation,
          customerAddress,
        },
      };

      if (!!linkedRelation?.id) {
        switch (relationType) {
          case RelationType.ContactPerson: {
            const person = await ContactPersons.read(
              linkedRelation.id,
              realEstateAgencyId
            ).then((result) => result.contactPerson);
            const { displayName } = person;
            formalName =
              displayName || RelationUtil.getFormalSalutation(person);
            break;
          }
          case RelationType.Employee: {
            const employee = await Employees.read(
              linkedRelation.id,
              realEstateAgencyId
            ).then((result) => result.employee);
            const { displayName } = employee;
            formalName =
              displayName || RelationUtil.getFormalSalutation(employee);
            break;
          }
          case RelationType.ContactCompany: {
            const company = await ContactCompanies.read(
              linkedRelation.id,
              realEstateAgencyId
            ).then((result) => result.contactCompany);
            const { displayName, taxNumber } = company;
            formalName = displayName;
            if (!!taxNumber) {
              result = {
                ...result,
                invoice: {
                  ...result.invoice,
                  taxNumberCustomer: taxNumber,
                },
              };
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      const path = route(INVOICEROUTES.DETAIL.URI, { id: result.invoice.id });
      const invoice: Invoice = {
        ...result.invoice,
        lines: [
          {
            amount: 0,
            taxPercentage: 0,
            totalPriceGross: 0,
            totalPriceNet: 0,
            totalTax: 0,
            unitPriceGross: 0,
            unitPriceNet: 0,
            unitTax: 0,
          },
        ],
      };

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.FINANCE.ICON,
          componentState: invoice,
          path,
          title: "...",
          confirm: {
            title: { key: "saveInvoiceConfirmTitle" },
            body: { key: "saveInvoiceConfirmBody" },
          },
          entityType: RootEntityType.Invoice,
          entityId: invoice.id,
        })
      );
      dispatch(InvoiceActions.setSingleInvoice({ invoice }));
      dispatch(InvoiceActions.setFormalName(formalName));
      dispatch(push(path));
    } catch (error) {
      dispatch(InvoiceActions.setSingleInvoiceStatus(REQUEST.ERROR));
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const getGlobalPrintTemplates = (businessToBusinessInvoice: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { reportsHost } = state.appSettings;
    const { defaultCulture } = state.account.accountSettings;
    const client = new ReportsClient(reportsHost);

    try {
      return await parseRequest.response(
        client
          .searchTemplates(
            {
              culture: defaultCulture,
              category: ReportCategory.Invoices,
              businessToBusinessInvoice,
            },
            realEstateAgencyId
          )
          .then((response) => response.result || [])
      );
    } catch (error) {
      throw error;
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: Invoice = editable.componentState;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new InvoicesClient(host);
      const invoice = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.invoice),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: Invoice = {
          ...componentState,
          ...invoice,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(InvoiceActions.setSingleInvoice({ invoice }));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = invoice;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: invoice,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadInvoice = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const invoice = externalChangesData.updatedEntity as Invoice;
    const componentState: Invoice = {
      ...editable.componentState,
      ...invoice,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(InvoiceActions.setSingleInvoice({ invoice }));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateInvoiceListItem = (invoiceId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { invoices } = state.invoice.overview;
    const { features } = state.appSettings;

    let ref: InvoiceSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.invoice.list.invoices || []).find(
        (invoice) => !!invoice && invoice.id === invoiceId
      );
    } else {
      ref = (invoices.results || []).find(
        (invoice) => invoice.id === invoiceId
      );
    }

    if (!ref) return;

    try {
      const client = new InvoicesClient(host);
      const invoice = await parseRequest.response(
        client
          .read(invoiceId, realEstateAgencyId)
          .then((response) => response?.invoice)
      );
      if (!invoice) return;

      const snapshot = mapInvoiceToInvoiceSnapShot(invoice);

      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(InvoiceListActions.updateItem(snapshot));
      } else {
        dispatch(InvoiceOverviewActions.updateListItem({ snapshot }));
      }
    } catch (error) {
      throw error;
    }
  };
};

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { order, filters } = state.invoice.list;
      const {
        filterByActive: refFilterByActive,
        statusFilter,
        overdue,
        invoiceDate,
      } = filters;
      const client = new InvoicesClient(host);

      const filterByActive: ActiveFilter =
        refFilterByActive.value.length === 1
          ? head(refFilterByActive.value)
          : ActiveFilter.ActiveOrInactive;

      const response = await parseRequest.response(
        client.search(
          {
            orderBy: order.sortColumn,
            order: order.sortOrder,
            skip: startIndex,
            take: stopIndex - startIndex + 1,
            skipSystemInvoicesAreMissingCheck: false,
            filterByActive,
            statusFilter: statusFilter.value,
            overdueFrom: overdue.value.min,
            overdueUntil: overdue.value.max,
            invoiceDateFrom: invoiceDate.value.min,
            invoiceDateUntil: invoiceDate.value.max,
          },
          realEstateAgencyId
        )
      );

      if (!response) {
        return;
      }

      const { results, totalResults, statistics } = response;
      dispatch(
        InvoiceListActions.updateList(
          startIndex,
          results,
          totalResults,
          statistics
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const deleteListInvoice = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new InvoicesClient(host);
    const { invoices, totalCount, statistics } = state.invoice.list;

    try {
      const index = findIndex(invoices, (invoice) => invoice.id === id);
      if (index === -1) return;
      const snapshot = invoices[index];
      const invoicesClone = [...invoices];
      invoicesClone.splice(index, 1);

      dispatch(
        InvoiceListActions.updateList(
          0,
          invoicesClone,
          totalCount - 1,
          statistics
        )
      );

      await parseRequest.response(client.delete(id, realEstateAgencyId));

      dispatch(
        SnackbarActions.addToast({
          value: "invoice.toast.deleted",
          callback: async () => {
            await dispatch(unDeleteListInvoice(id, snapshot, index));
          },
          callbackLabel: "invoice.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteListInvoice = (
  id: string,
  snapshot: InvoiceSnapShot,
  prevIndex: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new InvoicesClient(host);
    const { invoices, totalCount, statistics } = state.invoice.list;
    const invoicesClone = [...invoices];

    try {
      invoicesClone.splice(prevIndex, 0, snapshot);

      dispatch(
        InvoiceListActions.updateList(
          0,
          invoicesClone,
          totalCount + 1,
          statistics
        )
      );

      await parseRequest.response(client.undelete({ id }, realEstateAgencyId));
    } catch (error) {
      throw error;
    }
  };
};

const exportListToExcel = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;
    const { token: access_token } = state.access;
    const { filters } = state.invoice.list;
    const {
      filterByActive: refFilterByActive,
      statusFilter,
      overdue,
      invoiceDate,
    } = filters;

    const filterByActive: ActiveFilter =
      refFilterByActive.value.length === 1
        ? head(refFilterByActive.value)
        : ActiveFilter.ActiveOrInactive;

    const props = {
      filterByActive,
      statusFilter: statusFilter.value,
      overdueFrom: overdue.value.min,
      overdueUntil: overdue.value.max,
      invoiceDateFrom: invoiceDate.value.min,
      invoiceDateUntil: invoiceDate.value.max,
      culture,
      access_token,
    };

    const url = `${host}/${apiVersion}/${realEstateAgencyId}/Invoices/SearchAndExport?${RouteUtil.mapObjectToGetParams(
      props
    )}`;

    window.open(url, "_blank");
  };
};

const downloadInvoice = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new BlobsClient(host);

      const item: BatchItem = {
        id,
        type: BatchItemType.Invoice,
        options: [BatchOption.TempAccessUrl],
      };

      const blobs = await parseRequest.response(
        client
          .createBatchDownload({ batchItems: [item] }, realEstateAgencyId)
          .then((response) => response.batchResponseItems || [])
      );
      const blob = first(blobs);
      if (!blob || !blob.url || !blob.fileName) return;
      saveAs(blob.url, blob.fileName);
    } catch (error) {
      throw error;
    }
  };
};

export const InvoiceThunk = {
  setInvoicesOrdering,
  getInvoices,
  deleteInvoice,
  unDeleteInvoice,
  archiveInvoice,
  unArchiveInvoice,
  invoiceFilterChange,
  clearAllFilters,
  createInvoice,
  getInvoice,
  saveInvoice,
  setFinancialAdministration,
  getDataFromAssignment,
  udpateTotalPrices,
  saveAndCloseInvoice,
  updateDueDateWithLinkedAssignment,
  exportInvoices,
  getPrintTemplates,
  getPrintFormats,
  getDataFromRelation,
  checkExternalChanges,
  reloadInvoice,
  createInvoiceWithLinkedEntities,
  getGlobalPrintTemplates,
  updateInvoiceListItem,
  getListItems,
  deleteListInvoice,
  exportListToExcel,
  downloadInvoice,
};
