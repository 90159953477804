import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { Space } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { ExtendedFloor, ExtendedSpace } from "../";

const styles = require("../floors-and-spaces.component.scss");

interface Props {
  space: ExtendedSpace;
  floor: ExtendedFloor;
  selectedFloorOrSpace: ExtendedFloor | ExtendedSpace;
  onSpaceSelect: (space: Space) => void;
  onDuplicateSpace: (space: Space) => void;
  onDeleteSpace: (space: Space) => void;
}
interface State {
  actionsVisible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SpaceComponent extends React.PureComponent<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      actionsVisible: false,
    };

    this.bindRef = this.bindRef.bind(this);
    this.toggleActionsVisible = this.toggleActionsVisible.bind(this);
    this.onSpaceSelect = this.onSpaceSelect.bind(this);
    this.onDuplicateSpace = this.onDuplicateSpace.bind(this);
    this.onDeleteSpace = this.onDeleteSpace.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const { selectedFloorOrSpace, space } = this.props;
    const { type, id } = space;

    return (
      <div styleName="space" ref={this.bindRef}>
        <div
          styleName={classNames("space__title", {
            "actions-visible": this.state.actionsVisible,
            active: !!selectedFloorOrSpace && selectedFloorOrSpace.id === id,
          })}
        >
          <div styleName="title" onClick={this.onSpaceSelect}>
            <ResourceText
              masterKey="spaceTypes"
              resourceKey={type.toString()}
            />

            {space.livingRoomTypes && space.livingRoomTypes.length > 0 && (
              <span styleName="subTypes">
                (
                {space.livingRoomTypes.map((livingRoomType) => (
                  <ResourceText
                    key={livingRoomType}
                    masterKey="livingRoomTypes"
                    resourceKey={livingRoomType.toString()}
                  />
                ))}
                )
              </span>
            )}
          </div>
          <div styleName="trigger" onClick={this.toggleActionsVisible}>
            <i className="fal fa-ellipsis-v" />
          </div>
        </div>
        <div styleName="space__actions">
          <button
            type="button"
            styleName="duplicate"
            onClick={this.onDuplicateSpace}
          >
            <i className="fal fa-copy" />
          </button>
          <button type="button" styleName="delete" onClick={this.onDeleteSpace}>
            <i className="fal fa-trash-alt" />
          </button>
        </div>
      </div>
    );
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
    }
  }

  private toggleActionsVisible() {
    this.setState(({ actionsVisible }) => ({
      actionsVisible: !actionsVisible,
    }));
  }

  private onClickOutsideHandler(event: any) {
    if (!this.ref) return;
    const clickedInside = this.ref.contains(event.target);
    if (!clickedInside && !!this.state.actionsVisible)
      this.setState({ actionsVisible: false });
  }

  private onSpaceSelect() {
    const { onSpaceSelect, space } = this.props;
    onSpaceSelect(space);
    this.setState({ actionsVisible: false });
  }

  private onDuplicateSpace() {
    const { onDuplicateSpace, space } = this.props;
    onDuplicateSpace(space);
    this.setState({ actionsVisible: false });
  }

  private onDeleteSpace() {
    const { onDeleteSpace, space } = this.props;
    onDeleteSpace(space);
    this.setState({ actionsVisible: false });
  }
}
