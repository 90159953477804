import * as React from "react";
import classNames from "classnames";
import { LinkedRelation } from "@haywork/api/kolibri";
import { useState, FC, useCallback, useRef, useMemo } from "react";
import CSSModules = require("react-css-modules");
import { FeatureSwitch } from "@haywork/modules/feature-switch/components/feature-switch.component";
import RelationInfo from "@haywork/components/ui/relation-info";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import { RelationUtil } from "@haywork/util";

interface Props {
  relation: LinkedRelation;
  partnerMarkedForDelete: LinkedRelation;
  onRelationClick: (relation: LinkedRelation, isPartner?: boolean) => void;
  onRemoveRelationClick: (
    event: React.MouseEvent<HTMLDivElement>,
    relation: LinkedRelation
  ) => void;
  onRemovePartnerClick: (
    event: React.MouseEvent<HTMLDivElement>,
    relation: LinkedRelation
  ) => void;
}

const styles = require("./style.scss");

export const PartnerPill: FC<Props> = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      relation,
      partnerMarkedForDelete,
      onRemoveRelationClick,
      onRemovePartnerClick,
      onRelationClick,
    }: Props) => {
      const [touched, setTouched] = useState(false);
      const [pillRelation, setPillRelation] = useState(relation);
      const [firstTouch, setFirstTouch] = useState(true);
      const [partnerTouched, setPartnerTouched] = useState(false);
      const [partnerFirstTouch, setPartnerFirstTouch] = useState(true);
      const [hover, toggleHover] = useState(false);

      const contextMenuParentRef = useRef<HTMLDivElement>();
      const timeoutRef = useRef<any>(null);

      const partnerStyle = classNames("partner", {
        "marked-for-delete":
          !!partnerMarkedForDelete && relation.id === partnerMarkedForDelete.id,
        hover: partnerTouched,
      });

      const onRelationClickHandler = React.useCallback(
        (relation: LinkedRelation, isPartner: boolean = false) => {
          if (!isPartner && touched && firstTouch) {
            setFirstTouch(false);
          }

          if (isPartner && partnerTouched && partnerFirstTouch) {
            setPartnerFirstTouch(false);
          }

          onRelationClick(relation, isPartner);

          setTouched(false);
          setFirstTouch(true);
          setPartnerTouched(false);
          setPartnerFirstTouch(true);
        },
        [
          onRelationClick,
          setTouched,
          setFirstTouch,
          setPartnerTouched,
          setPartnerFirstTouch,
        ]
      );

      const mouseEnterCallback = useCallback(
        (relationItem) => {
          if (relationItem.id) setPillRelation(relationItem);
          if (!!timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
          toggleHover(true);
        },
        [toggleHover, setPillRelation, pillRelation]
      );

      const mouseLeaveCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          toggleHover(false);
        }, 50);
      }, [toggleHover]);

      const item = useMemo(
        () => RelationUtil.mapLinkedRelationToSnapshot(pillRelation),
        [pillRelation]
      );

      return (
        <>
          <div className="partner-pill" data-cy="CY-parnterPill">
            <div
              className={classNames("relation", { hover: touched })}
              onTouchStart={() => setTouched(true)}
              onClick={() => onRelationClickHandler(relation)}
              onMouseEnter={() => {
                mouseEnterCallback(relation);
              }}
              onMouseLeave={mouseLeaveCallback}
              ref={contextMenuParentRef}
            >
              <div className="value">{relation.displayName}</div>
              <div
                className="remove"
                onClick={(event) => onRemoveRelationClick(event, relation)}
              >
                <i className="fal fa-times" />
              </div>
            </div>
            <div className="divider" />
            <div
              className={partnerStyle}
              onTouchStart={() => setPartnerTouched(true)}
              onClick={() =>
                onRelationClickHandler(relation.linkedPartner, true)
              }
              onMouseEnter={() => {
                mouseEnterCallback(relation.linkedPartner);
              }}
              onMouseLeave={mouseLeaveCallback}
              ref={contextMenuParentRef}
            >
              <div className="value">{relation.linkedPartner.displayName}</div>
              <div
                className="remove"
                onClick={(event) => onRemovePartnerClick(event, relation)}
              >
                <i className="fal fa-times" />
              </div>
            </div>
          </div>
          <FeatureSwitch feature="RELATION_INFO">
            <ContextMenu
              visible={hover}
              parent={contextMenuParentRef}
              width={500}
              position={ContextMenuPosition.TopRight}
            >
              <div
                styleName="context-menu"
                onMouseEnter={mouseEnterCallback}
                onMouseLeave={mouseLeaveCallback}
              >
                <RelationInfo item={item} visible={hover} />
              </div>
            </ContextMenu>
          </FeatureSwitch>
        </>
      );
    }
  )
);
