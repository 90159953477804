import { REDUX } from "@haywork/constants";
import * as ActionType from "./preview.types";

const setHtmlPreview = (payload: ActionType.Preview) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW,
  ...payload
});

const setHtmlPreviewStatus = (previewStatus: string) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW_STATUS,
  previewStatus
});

const focusOnAnchor = (payload: ActionType.Name) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_ANCHOR_NAME,
  ...payload
});

const setHtmlPreviewRefreshStatus = (previewRefreshStatus: string) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_HTML_PREVIEW_REFRESH_STATUS,
  previewRefreshStatus
});

const refreshHtmlPreview = (payload: ActionType.Preview) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.REFRESH_HTML_PREVIEW,
  ...payload
});

const focusOnUserField = (payload: ActionType.Name) => ({
  type: REDUX.DYNAMIC_DOCUMENTS.SET_USERFIELD_NAME,
  ...payload
});

const clearHtmlPreview = () => ({
  type: REDUX.DYNAMIC_DOCUMENTS.CLEAR_HTML_PREVIEW
});

const resetFocus = () => ({
  type: REDUX.DYNAMIC_DOCUMENTS.RESET_FOCUS
});

export const DynamicDocumentsPreviewActions = {
  setHtmlPreview,
  setHtmlPreviewStatus,
  focusOnAnchor,
  setHtmlPreviewRefreshStatus,
  refreshHtmlPreview,
  focusOnUserField,
  clearHtmlPreview,
  resetFocus
};
