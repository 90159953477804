export const EVENTCENTER = {
  SET_NOTIFICATIONS_FILTER: "EVC_SET_NOTIFICATIONS_FILTER",
  SET_UNREAD_COUNT: "EVC_SET_UNREAD_COUNT",
  SET_NOTIFICATIONS_SETTINGS: "EVC_SET_NOTIFICATIONS_SETTINGS",
  SET_NOTIFICATIONS_SETTINGS_STATUS: "EVC_SET_NOTIFICATIONS_SETTINGS_STATUS",
  SET_NOTIFCATIONS: "EVC_SET_NOTIFCATIONS",
  SET_NOTIFCATIONS_STATUS: "EVC_SET_NOTIFCATIONS_STATUS",
  UPDATE_NOTIFICATION: "EVC_UPDATE_NOTIFICATION",
  TOGGLE_ALL_IS_READ: "EVC_TOGGLE_ALL_IS_READ",
  PROCESS_ALL: "EVC_PROCESS_ALL",
  FORCE_RELOAD: "EVC_FORCE_RELOAD"
};
