import I18n, { Translateable } from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import isString from "lodash-es/isString";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import ContextMenu from "../filter-context-menu";

const styles = require("./style.scss");

type Props = {
  title: Translateable;
  subtitle?: Translateable;
  collapsable?: boolean;
  collapsed?: boolean;
  expand?: "vertical" | "horizontal";
  hidden?: boolean;
  info?: string;
};

export const SectionComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      children,
      title,
      subtitle,
      collapsed: outerCollapsed,
      collapsable,
      expand,
      hidden,
      info,
    }) => {
      const [collapsed, setCollapsed] = useState(
        !!collapsable && (!!outerCollapsed || expand === "horizontal")
      );
      const [showInfo, setShowInfo] = useState(false);
      const ref = useRef<HTMLDivElement>();

      const translatedTitle = useMemo(() => {
        return (
          <I18n
            value={isString(title) ? title : title.value}
            values={isString(title) ? undefined : title.values}
          />
        );
      }, [title]);

      const translatedSubtitle = useMemo(() => {
        if (!subtitle) return null;

        return (
          <I18n
            value={isString(subtitle) ? subtitle : subtitle.value}
            values={isString(subtitle) ? undefined : subtitle.values}
          />
        );
      }, [subtitle]);

      const icon = useMemo(() => {
        return expand === "horizontal"
          ? "chevron-right"
          : collapsed
          ? "chevron-down"
          : "chevron-up";
      }, [collapsed, expand]);

      const toggleCollapse = useCallback(() => {
        if (!collapsable) return;
        setCollapsed(!collapsed);
      }, [collapsed, setCollapsed, collapsable]);

      useEffect(() => {
        if (!collapsable) {
          setCollapsed(false);
        }
      }, [setCollapsed, collapsable]);

      if (hidden) return null;

      return (
        <div
          styleName={classNames("section", {
            expanded: expand === "horizontal" && !collapsed,
          })}
        >
          <div
            styleName={classNames("header", { collapsable })}
            onClick={toggleCollapse}
            ref={ref}
          >
            <div styleName="header__titles">
              <div styleName="title-wrapper">
                <div styleName="title">{translatedTitle}</div>
                {info && (
                  <div styleName="info">
                    {showInfo && <I18n value={info} asHtml />}
                    <div
                      onMouseEnter={() => setShowInfo(true)}
                      onMouseLeave={() => setShowInfo(false)}
                    >
                      <Icon
                        name="info-circle"
                        regular={true}
                        color={Colors.Primary}
                        size={14}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!!translatedSubtitle && (
                <div styleName="subtitle">{translatedSubtitle}</div>
              )}
            </div>

            {collapsable && (
              <div styleName="header__toggle">
                <Icon
                  name={icon}
                  light
                  color={Colors.Primary}
                  size={14}
                  containIn={20}
                />
              </div>
            )}
          </div>
          {!collapsed && expand !== "horizontal" && (
            <div styleName="inputs">{children}</div>
          )}

          {expand === "horizontal" && (
            <ContextMenu
              visible={!collapsed}
              parent={ref}
              onClose={toggleCollapse}
            >
              {children}
            </ContextMenu>
          )}
        </div>
      );
    }
  )
);
