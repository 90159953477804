import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CCSModules from "react-css-modules";
import { LinkedRelation } from "@haywork/api/kolibri";
import Relation from "./relation";
import FormattedText from "@haywork/components/ui/formatted-text";

const styles = require("./style.scss");

type Props = {
  relations: LinkedRelation[];
};
export const RelationsToNotifyComponent: FC<Props> = memo(
  CCSModules(styles, { allowMultiple: true })(({ relations }) => {
    return (
      <div styleName="relations-to-notify">
        <FormattedText value="bidsModal.header.relationsToNotify" asSection />
        <FormattedText value="bidsModal.body.relationsToNotify" />

        {relations.map((relation) => (
          <Relation key={relation.id} relation={relation} />
        ))}
      </div>
    );
  })
);
