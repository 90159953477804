import { combineReducers } from "redux";
import { AccountsActions, accountsReducer, AccountsState } from "./accounts";
import {
  MessagesActions,
  messagesReducer,
  MessagesState,
  EmailMessage
} from "./messages";
import {
  DraftsActions,
  draftsReducer,
  DraftsState,
  EmailDraft
} from "./drafts";
import {
  FoldersActions,
  foldersReducer,
  FoldersState,
  EmailFolder
} from "./folders";
import { MainActions, mainReducer, MainState, EmailFiltersState } from "./main";
import {
  TemplatesActions,
  templatesReducer,
  TemplatesState
} from "./templates";
import {
  ProvidersActions,
  ProvidersState,
  providersReducer
} from "./providers";
import { SharesActions, SharesState, sharesReducer } from "./shares";

export type EmailStateV2 = {
  accounts: AccountsState;
  drafts: DraftsState;
  folders: FoldersState;
  main: MainState;
  messages: MessagesState;
  templates: TemplatesState;
  providers: ProvidersState;
  shares: SharesState;
};

export const emailReducerV2 = combineReducers({
  accounts: accountsReducer,
  drafts: draftsReducer,
  folders: foldersReducer,
  main: mainReducer,
  messages: messagesReducer,
  templates: templatesReducer,
  providers: providersReducer,
  shares: sharesReducer
});

export const EmailActionsV2 = {
  Accounts: AccountsActions,
  Drafts: DraftsActions,
  Folders: FoldersActions,
  Main: MainActions,
  Messages: MessagesActions,
  Templates: TemplatesActions,
  Providers: ProvidersActions,
  Shares: SharesActions
};

export { EmailFolder, EmailMessage, EmailFiltersState, EmailDraft };
