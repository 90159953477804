export const BASEROUTES = {
  ROOT: "/",
  APP: "/app",
  NOT_FOUND: "/not-found",
};

export const MAINROUTES = {
  DASHBOARD: { URI: BASEROUTES.APP + "/dashboard", ICON: "tachometer" },
  ASSIGNMENTS: { URI: BASEROUTES.APP + "/assignments", ICON: "folder-open" },
  ACQUISITIONS: {
    URI: BASEROUTES.APP + "/acquisitions",
    ICON: "handshake-alt",
  },
  ACQUISITIONOBJECT: {
    URI: BASEROUTES.APP + "/acquisitionobject",
    ICON: "folder-open",
  },
  PROJECTS: { URI: BASEROUTES.APP + "/projects", ICON: "sitemap" },
  SCHEDULER: { URI: BASEROUTES.APP + "/scheduler", ICON: "calendar" },
  SCHEDULERCATEGORY: {
    URI: BASEROUTES.APP + "/scheduler-category",
    ICON: "calendar-alt",
  },
  EMAILSIGNATURE: {
    URI: BASEROUTES.APP + "/email-signatures",
    ICON: "file-signature",
  },
  EMAILTEMPLATE: {
    URI: BASEROUTES.APP + "/email-templates",
    ICON: "file",
  },
  DOSSIERFOLDERS: {
    URI: BASEROUTES.APP + "/dossier-folder",
    ICON: "archive",
  },
  EMAIL: { URI: BASEROUTES.APP + "/email", ICON: "envelope" },
  TASKS: { URI: BASEROUTES.APP + "/tasks", ICON: "tasks" },
  RELATIONS: { URI: BASEROUTES.APP + "/relations", ICON: "address-book" },
  SEARCHASSIGNMENT: { URI: BASEROUTES.APP + "/searchprofiles", ICON: "search" },
  FINANCE: { URI: BASEROUTES.APP + "/finance", ICON: "euro-sign" },
  SETTINGS: { URI: BASEROUTES.APP + "/settings", ICON: "sliders-h" },
  PROFILE: { URI: BASEROUTES.APP + "/profile", ICON: "user-circle" },
  FEATURE: { URI: BASEROUTES.APP + "/feature", ICON: "code" },
  DYNAMICDOCUMENTS: { URI: BASEROUTES.APP + "/documents", ICON: "file-alt" },
  PLAYGROUND: { URI: BASEROUTES.APP + "/playground", ICON: "child" },
  EXCHANGE: { URI: BASEROUTES.APP + "/exchange" },
  HOUSENET: { URI: BASEROUTES.APP + "/housenet" },
  REALESTATEAGENCY: {
    URI: BASEROUTES.APP + "/real-estate-agency",
    ICON: "user-circle",
  },
  APP_XCHANGE: { URI: BASEROUTES.APP + "/app-xchange", ICON: "rocket" },
  OBJECTTYPES: { URI: BASEROUTES.APP + "/object-types", ICON: "puzzle-piece" },
  KEYBOARD: { URI: BASEROUTES.APP + "/keyboard", ICON: "key" },
  HEALTH: { URI: BASEROUTES.APP + "/health", ICON: "stethoscope" },
  MLS: { URI: BASEROUTES.APP + "/mls", ICON: "exchange" },
  NOTIFICATIONS: { URI: BASEROUTES.APP + "/notifications", ICON: "bell" },
};

export const TASKROUTES = {
  TASK: { URI: BASEROUTES.APP + "/tasks/:id" },
};

export const EMPLOYEEROUTES = {
  EMPLOYEES: { URI: BASEROUTES.APP + "/employees/" },
  EMPLOYEE: { URI: BASEROUTES.APP + "/employees/:id" },
  EMPLOYEE_GENERAL: { URI: BASEROUTES.APP + "/employees/:id/general" },
  EMPLOYEE_ASSIGNMENT: { URI: BASEROUTES.APP + "/employees/:id/assignment" },
  EMPLOYEE_DOSSIER: { URI: BASEROUTES.APP + "/employees/:id/dossier" },
  EMPLOYEE_EDIT: { URI: BASEROUTES.APP + "/employees/:id/edit" },
};

export const SCHEDULERROUTES = {
  SCHEDULER_DETAIL: { URI: MAINROUTES.SCHEDULER.URI + "/:id" },
};

export const SCHEDULERCATEGORIESROUTES = {
  DETAIL: { URI: MAINROUTES.SCHEDULERCATEGORY.URI + "/:id" },
};

export const EMAILSIGNATURESROUTES = {
  DETAIL: { URI: MAINROUTES.EMAILSIGNATURE.URI + "/:id" },
};

export const EMAILTEMPLATESROUTES = {
  DETAIL: { URI: MAINROUTES.EMAILTEMPLATE.URI + "/:id" },
};

export const DOSSIERFOLDERROUTES = {
  DETAIL: { URI: MAINROUTES.DOSSIERFOLDERS.URI + "/:id" },
};

export const SETTINGSROUTES = {
  OVERVIEW: { URI: MAINROUTES.SETTINGS.URI + "/overview" },
  BRANCHES: { URI: MAINROUTES.SETTINGS.URI + "/branches" },
  USERS: { URI: MAINROUTES.SETTINGS.URI + "/users" },
  GENERAL: { URI: MAINROUTES.SETTINGS.URI + "/general" },
  REAL_ESTATE_AGENCY: { URI: MAINROUTES.SETTINGS.URI + "/real-estate-agency" },
  ADMINISTRATION: { URI: MAINROUTES.SETTINGS.URI + "/administration/:id" },
  GENERAL_ADMINISTRATION: {
    URI: MAINROUTES.SETTINGS.URI + "/administration/:id/general",
  },
  TAXRATES: { URI: MAINROUTES.SETTINGS.URI + "/administration/:id/taxrates" },
  PRODUCTS: { URI: MAINROUTES.SETTINGS.URI + "/administration/:id/products" },
  GROUPS: { URI: MAINROUTES.SETTINGS.URI + "/administration/:id/groups" },
  SCHEDULER_CATEGORIES: { URI: MAINROUTES.SETTINGS.URI + "/scheduler" },
  RELATIONGROUPS: { URI: MAINROUTES.SETTINGS.URI + "/relationgroups" },
  STANDARDLETTERS: { URI: MAINROUTES.SETTINGS.URI + "/standardletters" },
  ASSIGNMENTS: { URI: MAINROUTES.SETTINGS.URI + "/assignments" },
  EMAIL: { URI: MAINROUTES.SETTINGS.URI + "/email/accounts" },
  EMAIL_TEMPLATES: { URI: MAINROUTES.SETTINGS.URI + "/email/templates" },
  EMAIL_SIGNATURES: { URI: MAINROUTES.SETTINGS.URI + "/email/signatures" },
  APP_XCHANGE: { URI: MAINROUTES.SETTINGS.URI + "/app-xchange" },
  LANGUAGE: { URI: MAINROUTES.SETTINGS.URI + "/language" },
  MEDIAPARTNERS: { URI: MAINROUTES.SETTINGS.URI + "/mediapartners" },
  MATCHMAIL: { URI: MAINROUTES.SETTINGS.URI + "/matchmail" },
  EVENTCENTER: { URI: MAINROUTES.SETTINGS.URI + "/event-center" },
  DOSSIER_FOLDERS: { URI: MAINROUTES.SETTINGS.URI + "/dossier-folders" },
  COMPANY_SETTINGS: { URI: MAINROUTES.SETTINGS.URI + "/company-settings" },
  ACQUISITION_ASSIGNMENTS: {
    URI: MAINROUTES.SETTINGS.URI + "/acquisition-assignments",
  },
  MLS: { URI: MAINROUTES.SETTINGS.URI + "/mls" },
  DOCUMENTS: { URI: MAINROUTES.SETTINGS.URI + "/documents" },
};

export const ASSIGNMENTROUTES = {
  DETAIL: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/general" },
  COMPLETE: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/complete" },
  TIMELINE: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/timeline" },
  NETWORK: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/network" },
  SEARCH_ASSIGNMENTS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/search-assignments",
  },
  DOSSIER: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/dossier" },
  VERSIONS: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/versions" },
  EDIT: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit" },
  EDIT_CLIENT: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/client" },
  EDIT_ADDRESS: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/address" },
  EDIT_ADDRESS_PARAMS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/address/:caller",
  },
  EDIT_FINANCIAL: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/financial" },
  EDIT_ASSIGNMENT: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/assignment" },
  EDIT_MARKETING: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing" },
  EDIT_MARKETING_PHOTOS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/photos",
  },
  EDIT_MARKETING_TEXT: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/text",
  },
  EDIT_MARKETING_PARTNERS: {
    URI:
      MAINROUTES.ASSIGNMENTS.URI +
      "/:id/edit/marketing/partners-and-publications",
  },
  EDIT_MARKETING_MLS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/uitwisseling",
  },
  EDIT_MARKETING_WEBSITE: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/website",
  },
  EDIT_MARKETING_MAPS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/maps",
  },
  EDIT_MARKETING_VIDEOS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/videos",
  },
  EDIT_MARKETING_VIRTUAL_VIDEOS: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/virtual",
  },
  EDIT_MARKETING_PRESENTATION: {
    URI:
      MAINROUTES.ASSIGNMENTS.URI +
      "/:id/edit/marketing/presentation-and-brochures",
  },
  EDIT_MARKETING_OPENHOUSE: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/open-house",
  },
  EDIT_MARKETING_BUDGET: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/marketing/budget",
  },
  PUBLISH: { URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/edit/publish" },
  WIDGET_PAGE: {
    URI: MAINROUTES.ASSIGNMENTS.URI + "/:id/widget/:widgetId",
  },
};

export const PROJECTROUTES = {
  DETAIL: { URI: MAINROUTES.PROJECTS.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.PROJECTS.URI + "/:id/general" },
  TIMELINE: { URI: MAINROUTES.PROJECTS.URI + "/:id/timeline" },
  NETWORK: { URI: MAINROUTES.PROJECTS.URI + "/:id/network" },
  DOSSIER: { URI: MAINROUTES.PROJECTS.URI + "/:id/dossier" },
  TYPES: { URI: MAINROUTES.PROJECTS.URI + "/:id/types" },
  BUILD_NUMBERS: { URI: MAINROUTES.PROJECTS.URI + "/:id/build-numbers" },
  EDIT: { URI: MAINROUTES.PROJECTS.URI + "/:id/edit" },
  EDIT_CLIENT: { URI: MAINROUTES.PROJECTS.URI + "/:id/edit/client" },
  EDIT_ADDRESS: { URI: MAINROUTES.PROJECTS.URI + "/:id/edit/address" },
  EDIT_ADDRESS_PARAMS: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/address/:caller",
  },
  EDIT_MARKETING: { URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing" },
  EDIT_MARKETING_PHOTOS: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/photos",
  },
  EDIT_MARKETING_TEXT: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/text",
  },
  EDIT_MARKETING_PARTNERS: {
    URI:
      MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/partners-and-publications",
  },
  EDIT_MARKETING_WEBSITE: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/website",
  },
  EDIT_MARKETING_MAPS: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/maps",
  },
  EDIT_MARKETING_VIDEOS: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/videos",
  },
  EDIT_MARKETING_VIRTUAL_VIDEOS: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/virtual",
  },
  EDIT_MARKETING_PRESENTATION: {
    URI:
      MAINROUTES.PROJECTS.URI +
      "/:id/edit/marketing/presentation-and-brochures",
  },
  EDIT_MARKETING_OPENHOUSE: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/open-house",
  },
  EDIT_MARKETING_BUDGET: {
    URI: MAINROUTES.PROJECTS.URI + "/:id/edit/marketing/budget",
  },
  PUBLISH: { URI: MAINROUTES.PROJECTS.URI + "/:id/edit/publish" },
};

export const SEARCHASSIGNMENTROUTES = {
  NEW: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/new" },
  NEW_WITH_RELATION: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/new/:id" },
  DETAIL: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/general" },
  SEARCHERS: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/searchers" },
  LINKED_ASSIGNMENTS: {
    URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/linked-assignments",
  },
  MATCHMAIL: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/matchmail" },
  EDIT: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit" },
  EDIT_WHERE: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit/where" },
  EDIT_WHAT: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit/what" },
  EDIT_CONTACTS: {
    URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit/contacts",
  },
  EDIT_LINKED_ASSIGNMENTS: {
    URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit/linked-assignments",
  },
  PUBLISH: { URI: MAINROUTES.SEARCHASSIGNMENT.URI + "/:id/edit/publish" },
};

export const REALESTATEAGENCYROUTES = {
  NEW: { URI: MAINROUTES.REALESTATEAGENCY.URI + "/new" },
  DETAIL: { URI: MAINROUTES.REALESTATEAGENCY.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.REALESTATEAGENCY.URI + "/:id/general" },
  DOSSIER: { URI: MAINROUTES.REALESTATEAGENCY.URI + "/:id/dossier" },
  EDIT: { URI: MAINROUTES.REALESTATEAGENCY.URI + "/:id/edit" },
};

export const RELATIONROUTES = {
  NEW: { URI: MAINROUTES.RELATIONS.URI + "/new" },
  NEW_ARO: { URI: MAINROUTES.RELATIONS.URI + "/new_aro/:caller/:newName" },
  NEW_PHONE_ARO: {
    URI: MAINROUTES.RELATIONS.URI + "/new_phone_aro/:caller/:newPhone",
  },
  NEW_EMAIL_ARO: {
    URI: MAINROUTES.RELATIONS.URI + "/new_email_aro/:caller/:newEmail",
  },

  CONTACT_PERSON_DETAIL: { URI: MAINROUTES.RELATIONS.URI + "/person/:id" },
  CONTACT_PERSON_GENERAL: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/general",
  },
  CONTACT_PERSON_TIMELINE: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/timeline",
  },
  CONTACT_PERSON_ASSIGNMENT: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/assignments",
  },
  CONTACT_PERSON_LINKED_RELATIONS: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/contacts",
  },
  CONTACT_PERSON_EDIT: { URI: MAINROUTES.RELATIONS.URI + "/person/:id/edit" },
  CONTACT_PERSON_DOSSIER: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/dossier",
  },
  CONTACT_PERSON_SEARCH_ASSIGNMENTS: {
    URI: MAINROUTES.RELATIONS.URI + "/person/:id/search-assignments",
  },

  CONTACT_COMPANY_DETAIL: { URI: MAINROUTES.RELATIONS.URI + "/company/:id" },
  CONTACT_COMPANY_GENERAL: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/general",
  },
  CONTACT_COMPANY_TIMELINE: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/timeline",
  },
  CONTACT_COMPANY_ASSIGNMENT: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/assignments",
  },
  CONTACT_COMPANY_LINKED_RELATIONS: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/contacts",
  },
  CONTACT_COMPANY_EDIT: { URI: MAINROUTES.RELATIONS.URI + "/company/:id/edit" },
  CONTACT_COMPANY_DOSSIER: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/dossier",
  },
  CONTACT_COMPANY_SEARCH_ASSIGNMENTS: {
    URI: MAINROUTES.RELATIONS.URI + "/company/:id/search-assignments",
  },
};

export const OFFICESROUTES = {
  BASE: { URI: BASEROUTES.APP + "/offices" },
  OFFICE_DETAIL: { URI: BASEROUTES.APP + "/offices/office/:id" },
  OFFICE_GENERAL: { URI: BASEROUTES.APP + "/offices/office/:id/general" },
  OFFICE_LINKED_EMPLOYEES: {
    URI: BASEROUTES.APP + "/offices/office/:id/employees",
  },
  OFFICE_ASSIGNMENT: {
    URI: BASEROUTES.APP + "/offices/office/:id/assignments",
  },
  OFFICE_DOSSIER: {
    URI: BASEROUTES.APP + "/offices/office/:id/dossier",
  },
  EDIT_OFFICE: { URI: BASEROUTES.APP + "/offices/office/:id/edit" },
};

export const APPXCHANGEROUTES = {
  DETAIL: { URI: MAINROUTES.APP_XCHANGE.URI + "/:id" },
  ABOUT: { URI: MAINROUTES.APP_XCHANGE.URI + "/:id/about" },
  REVIEWS: { URI: MAINROUTES.APP_XCHANGE.URI + "/:id/reviews" },
  COSTS: { URI: MAINROUTES.APP_XCHANGE.URI + "/:id/costs" },
  SUPPORT: { URI: MAINROUTES.APP_XCHANGE.URI + "/:id/support" },
  EMPLOYEE_SETTINGS: {
    URI: MAINROUTES.APP_XCHANGE.URI + "/:id/employee-settings",
  },
  COMPANY_SETTINGS: {
    URI: MAINROUTES.APP_XCHANGE.URI + "/:id/company-settings",
  },
  WIDGET_PAGE: {
    URI: MAINROUTES.APP_XCHANGE.URI + "/:id/widget/:widgetId",
  },
};

export const OBJECTTYPESROUTES = {
  DETAIL: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/general" },
  TIMELINE: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/timeline" },
  NETWORK: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/network" },
  DOSSIER: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/dossier" },
  BUILD_NUMBERS: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/build-numbers" },
  EDIT: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit" },
  EDIT_TYPE: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/type" },
  EDIT_GENERAL: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/general" },
  EDIT_SPECIFICATIONS: {
    URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/specifications",
  },
  EDIT_MARKETING: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/marketing" },
  EDIT_MARKETING_PHOTOS: {
    URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/marketing/photos",
  },
  EDIT_MARKETING_TEXT: {
    URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/marketing/text",
  },
  EDIT_MARKETING_MAPS: {
    URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/marketing/maps",
  },
  EDIT_MARKETING_VIDEOS: {
    URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/marketing/videos",
  },
  PUBLISH: { URI: MAINROUTES.OBJECTTYPES.URI + "/:id/edit/publish" },
};

export const DYNAMICDOCUMENTROUTES = {
  NEW: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/new" },
  DETAIL: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id" },
  INFO: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/info" },
  DOCUMENT: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/document" },
  LIST_OF_GOODS: {
    URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/list-of-goods",
  },
  DOCUMENT_FORM: {
    URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/document/form",
  },
  SECTION: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/document/:section" },
  DOCUMENT_SIGNATURES: {
    URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/document/signatures",
  },
  DOWNLOAD: { URI: MAINROUTES.DYNAMICDOCUMENTS.URI + "/:id/download" },
};

export const EMAILROUTES = {
  CREATE: { URI: MAINROUTES.EMAIL.URI + "/create/:id" },
  MESSAGE: { URI: MAINROUTES.EMAIL.URI + "/message/:id" },
  DRAFT: { URI: MAINROUTES.EMAIL.URI + "/create/:id" },
};

export const ACQUISITIONROUTES = {
  DETAIL: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id" },
  GENERAL: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id/general" },
  NETWORK: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id/network" },
  ACQUISITION_ASSIGNMENTS: {
    URI: MAINROUTES.ACQUISITIONS.URI + "/:id/assignments",
  },
  DOSSIER: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id/dossier" },
  TIMELINE: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id/timeline" },
  EDIT: { URI: MAINROUTES.ACQUISITIONS.URI + "/:id/edit" },
  EDIT_DETAILS: {
    URI: MAINROUTES.ACQUISITIONS.URI + "/:id/edit/acquisitiondetails",
  },
};

export const ACQUISITIONOBJECTROUTES = {
  DETAIL: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id",
  },
  GENERAL: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/general",
  },
  TIMELINE: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/timeline",
  },
  NETWORK: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/network",
  },
  DOSSIER: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/dossier",
  },
  EDIT: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit",
  },
  EDIT_GENERAL: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/general",
  },
  EDIT_OBJECT: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/object",
  },
  EDIT_MEDIA: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media",
  },
  EDIT_MEDIA_PHOTOS: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/photos",
  },
  EDIT_MEDIA_TEXT: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/text",
  },
  EDIT_MEDIA_VIDEO: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/video",
  },
  EDIT_MEDIA_360: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/360",
  },
  EDIT_MEDIA_FLOORPLANS: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/floorplans",
  },
  EDIT_MEDIA_BROCHURES: {
    URI: MAINROUTES.ACQUISITIONOBJECT.URI + "/:id/edit/media/brochures",
  },
};

export const MLSROUTES = {
  DETAIL: { URI: MAINROUTES.MLS.URI + "/:id/:source" },
  GENERAL: { URI: MAINROUTES.MLS.URI + "/:id/:source/general" },
  TIMELINE: { URI: MAINROUTES.MLS.URI + "/:id/:source/timeline" },
  SEARCH_ASSIGNMENTS: {
    URI: MAINROUTES.MLS.URI + "/:id/:source/search-assignments",
  },
  MEDIA: {
    URI: MAINROUTES.MLS.URI + "/:id/:source/media",
  },
};

export const EXTERNALROUTES = {
  EXCHANGE: { URI: "https://kolibri.software/appxchange/" },
  DISCLAIMER: { URI: "https://kolibri.software/voorwaarden/disclaimer/" },
  PRIVACY_POLICY: {
    URI: "https://kolibri.software/voorwaarden/privacy-beleid/",
  },
  SUPPORT: {
    URI: "https://support.kolibri.software/",
  },
  CADASTRE: {
    URI: "https://support.kolibri.software/portal/nl/kb/articles/kadaster-kosten-voor-opvragen-kadastrale-gegevens",
  },
  MAXRENT: {
    URI: "https://www.huurcommissie.nl/onderwerpen/huurprijs-en-punten/huurprijscheck-en-puntentelling/",
  },
  MATCHMAIL_INFO: {
    URI: "https://support.kolibri.software/portal/kb/articles/zoekopdrachten-in-kolibri-matchmail",
  },
  HOUSENET_INFO: {
    URI: "https://kolibri.software/websites-voor-makelaars-housenet/",
  },
  KOLIBRI_FREQUENT_QUESTIONS: {
    URI: "https://support.kolibri.software/portal/kb/kolibri-en-housenet/kolibri/veelgestelde-vragen/",
  },
  SUPPORT_INCOMPLETE_FLOORS: {
    URI: "https://support.kolibri.software/portal/kb/articles/opdracht-waarom-toont-funda-niet-het-juiste-aantal-kamers",
  },
  KOLIBRI_WEBINARS: {
    URI: "https://kolibri.software/academy/gratis-kolibri-webinar-voor-makelaars/basis-webinar-kolibri/",
  },
  KOLIBRI_ADDRESS_NOT_FOUND: {
    URI: "https://support.kolibri.software/portal/kb/articles/adres-kan-niet-worden-gevonden",
  },
  KOLIBRI_BORDER_ADDRESS: {
    URI: "https://support.kolibri.software/portal/kb/articles/hoe-meld-ik-een-woning-in-een-grensgebied-van-nederland-aan-op-funda-nl",
  },
  HOME: { URI: "https://kolibri.software/" },
  ORDER_EMAIL: { URI: "https://app.pipefy.com/public/form/nGFryiBc" },
  CONDITIONS: { URI: "https://kolibri.software/voorwaarden/" },
  EMAIL_PROVIDER_YAHOO_HELP: {
    URI: "https://support.kolibri.software/portal/kb/articles/yahoo-e-mail-koppelen-aan-kolibri",
  },
  EMAIL_PROVIDER_ICLOUD_HELP: {
    URI: "https://support.kolibri.software/portal/kb/articles/icloud-e-mail-koppelen-aan-kolibri",
  },
  EMAIL_PROVIDER_VIDEO: {
    URI: "https://support.kolibri.software/portal/kb/articles/e-mail-in-kolibri",
  },
  DOWNLOAD_SAFARI: { URI: "https://support.apple.com/downloads/safari" },
  DOWNLOAD_CHROME: { URI: "https://www.google.com/chrome" },
  DOWNLOAD_EDGE: { URI: "https://www.microsoft.com/microsoft-edge" },
  DOWNLOAD_FIREFOX: { URI: "https://www.mozilla.org/firefox/new" },
  DOWNLOAD_OPERA: { URI: "https://www.opera.com" },
  SUPPORT_BROWSER_UPDATE: {
    URI: "https://support.kolibri.software/portal/kb/articles/ik-krijg-een-melding-om-mijn-browser-te-upgraden-wat-nu",
  },
  APP_STORE_LINK: {
    URI: "https://itunes.apple.com/us/app/kolibri-app-voor-makelaars/id1438637671?mt=8&ign-mpt=uo%3D2",
  },
  PLAY_STORE_LINK: {
    URI: "https://play.google.com/store/apps/details?id=com.kolibri24&hl=en_US",
  },
  EMAIL_SIGNATURES_HELPDESK: {
    URI: "https://support.kolibri.software/portal/kb/articles/e-mail-handtekeningen-instellen-en-gebruiken",
  },
  EMAIL_TEMPLATES_HELPDESK: {
    URI: "https://support.kolibri.software/portal/kb/articles/e-mail-templates-instellen-en-gebruiken",
  },
  SUPPORT_INFO_LISTINGTYPE: {
    URI: "https://support.kolibri.software/portal/kb/articles/kolibri-wijzigen-van-het-woningtype",
  },
  SUPPORT_ENERGYLABEL: {
    URI: "https://support.kolibri.software/portal/kb/articles/opdracht-waarom-toont-funda-het-verkeerde-energielabel",
  },
  SUPPORT_FLOORSANDSPACES: {
    URI: "https://support.kolibri.software/portal/kb/articles/opdrachten-woonlagen-en-ruimtes",
  },
  SUPPORT_DOSSIER: {
    URI: "https://support.kolibri.software/portal/kb/articles/standaardmappen-beheren",
  },
  SUPPORT_COBROWSE: {
    BLOCKED: {
      URI: "https://support.kolibri.software/portal/kb/articles/browserplugins-uitschakelen",
    },
    URI: "https://support.kolibri.software/portal/kb/articles/hulp-op-afstand",
  },
  SUPPORT_LOOM: {
    URI: "https://support.kolibri.software/portal/kb/articles/use-loom",
  },
  SUPPORT_DOSSIER_UNSUPPORTED_ATTACHMENT: {
    URI: "https://support.kolibri.software/portal/kb/articles/dossier-niet-ondersteund-bestandstype",
  },
};

export const INVOICEROUTES = {
  DETAIL: { URI: MAINROUTES.FINANCE.URI + "/:id" },
};

export const MAINROUTES_URIS: string[] = [];
for (const key in MAINROUTES) {
  MAINROUTES_URIS.push(MAINROUTES[key].URI);
}
for (const key in SETTINGSROUTES) {
  MAINROUTES_URIS.push(SETTINGSROUTES[key].URI);
}
