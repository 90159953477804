import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailFolder } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { accounts, folders } from "../../selectors";
import { FolderComponent, FolderComponentProps } from "./folder";
import { Account } from "@haywork/api/mail";

type StateProps = {
  accounts: Account[];
  currentFolder: string;
  folders: EmailFolder[];
};
type DispatchProps = {
  setCurrentFolder: (folder: EmailFolder) => void;
  moveToFolder: (messageId: string, folderId: string) => Promise<void>;
  moveFolder: (id: string, newParentId: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FolderComponentProps,
  AppState
> = (state) => {
  const { currentFolder } = state.emailV2.main;

  return {
    accounts: accounts(state),
    currentFolder,
    folders: folders(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FolderComponentProps
> = (dispatch) => ({
  setCurrentFolder: (folder: EmailFolder) =>
    dispatch(EmailThunk.Main.setCurrentFolder(folder)),
  moveToFolder: (messageId: string, folderId: string) =>
    dispatch(EmailThunk.Messages.moveToFolder(messageId, folderId)),
  moveFolder: (id: string, newParentId: string) =>
    dispatch(EmailThunk.Folders.moveFolder(id, newParentId))
});

export type FolderContainerProps = StateProps & DispatchProps;
export const FolderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderComponent);
