import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import {
  ContactLinkSnapShot,
  ContactLinkTypeSuggestion,
  LinkedRelation,
} from "@haywork/api/kolibri";
import {
  DropdownFilter,
  Filter,
  MappedDropdownValue,
} from "@haywork/modules/filter";
import {
  ResourceText,
  MailTo,
  AuthorizationWidgets,
} from "@haywork/modules/shared";
import {
  WidgetEntityType,
  WidgetEntityLocation,
} from "@haywork/api/authorization";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

interface PersonLinkItemComponentProps {
  contact: ContactLinkSnapShot;
  linkTypes: ContactLinkTypeSuggestion[];
  relationId: string;
  preppedForRemove: boolean;
  onLinkTypeChange: (
    sourceRelationId: string,
    targetRelationId: string,
    linkType: string
  ) => void;
}
interface PersonLinkItemComponentState {}

const styles = require("./link-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class PersonLinkItemComponent extends React.Component<
  PersonLinkItemComponentProps,
  PersonLinkItemComponentState
> {
  constructor(props) {
    super(props);

    this.onFilterChangedHandler = this.onFilterChangedHandler.bind(this);
    this.isItMe = this.isItMe.bind(this);
    this.renderAddressLine = this.renderAddressLine.bind(this);
    this.renderContactLine = this.renderContactLine.bind(this);
  }

  public render() {
    const { sourceRelation, targetRelation } = this.props.contact;
    const person =
      sourceRelation.id === this.props.relationId
        ? targetRelation
        : sourceRelation;
    const avatarStyles = !!person.avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(person.avatarUrl)})` }
      : null;
    const contactLinkStyle = classNames("contact-link", {
      "prepped-for-remove": this.props.preppedForRemove,
    });

    return (
      <div styleName={contactLinkStyle}>
        <div styleName="item__avatar">
          <div styleName="avatar" style={avatarStyles}>
            {!avatarStyles && (
              <div styleName="letters">{person.letterAvatar}</div>
            )}
          </div>
        </div>
        <div styleName="item__body">
          <Filter changeFilter={this.onFilterChangedHandler}>
            <ResourceText
              resourceKey="aroIs"
              values={{
                name: sourceRelation.displayName,
                isSelf: this.isItMe(sourceRelation),
              }}
              asHtml
            />
            <span
              className="dropdown-filter-wrapper"
              onClick={this.onClickHander}
            >
              {this.props.contact.isPartnerLink && (
                <React.Fragment>
                  &nbsp;
                  <ResourceText resourceKey="aroPartner" />
                  &nbsp;
                </React.Fragment>
              )}
              {!this.props.contact.isPartnerLink && (
                <DropdownFilter
                  name="linkTypeFilter"
                  inline
                  values={this.mapLinkTypes(this.props.linkTypes)}
                  value={this.mapInitialLinkTypeValue(
                    this.props.contact.linkType
                  )}
                />
              )}
            </span>
            <ResourceText
              resourceKey="aroOf"
              values={{
                name: targetRelation.displayName,
                isSelf: this.isItMe(targetRelation),
              }}
              asHtml
            />
          </Filter>
        </div>

        {/* Authorization widgets */}
        <FeatureSwitch feature="APP_XCHANGE">
          <AuthorizationWidgets
            type={WidgetEntityType.ContactPerson}
            location={WidgetEntityLocation.Network}
            id={this.props.relationId}
          />
        </FeatureSwitch>

        {this.renderContactLine(person)}
        {this.renderAddressLine(person)}
      </div>
    );
  }

  private onFilterChangedHandler(values: any) {
    if (values.linkTypeFilter !== this.props.contact.linkType) {
      this.props.onLinkTypeChange(
        this.props.contact.sourceRelation.id,
        this.props.contact.targetRelation.id,
        values.linkTypeFilter
      );
    }
  }

  private onClickHander(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  private isItMe(relation: LinkedRelation): string {
    return relation.id === this.props.relationId ? "yes" : "no";
  }

  private mapLinkTypes(linkTypes: ContactLinkTypeSuggestion[]) {
    const mappedLinkTypes: MappedDropdownValue[] = linkTypes.map((val) => ({
      label: val.displayName,
      value: val.displayName,
    }));
    return mappedLinkTypes;
  }

  private mapInitialLinkTypeValue(linkType: string) {
    const key =
      linkType !== null && linkType.length > 0 ? linkType : "Kies een optie";
    return { label: key, value: linkType };
  }

  private renderAddressLine(
    person: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    /*if (!person.streetNameAndNumber && !person.locality) return null;

    return <div styleName="item__address">
      {person.streetNameAndNumber && <div styleName="streetname">{person.streetNameAndNumber}</div>}
      {person.locality && <div styleName="locality">{person.locality}</div>}
    </div>;*/
    return null;
  }

  private renderContactLine(
    person: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    if (
      !person.phone &&
      !person.phoneMobile &&
      !person.phoneWork &&
      !person.email
    )
      return null;
    const phones = [person.phone, person.phoneMobile, person.phoneWork];
    const cleanedPhones = phones.filter((phone) => !!phone);

    return (
      <div styleName="item__contact">
        {cleanedPhones.length > 0 && (
          <div styleName="phone">{cleanedPhones[0]}</div>
        )}
        {person.email && (
          <div styleName="email">
            <MailTo email={person.email} />
          </div>
        )}
      </div>
    );
  }
}
