import {
  ActiveFilter,
  AssignmentPhase,
  AvailabilityStatus,
  LinkedRelation,
  MixedFormOption,
  RealEstateGroup,
  RelationSnapShot,
  RelationType,
  SaleOffer,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { DateUtil, FormControlUtil } from "@haywork/util";
import isEqual from "lodash-es/isEqual";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./status-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface AssignmentStatusModalSoldComponentProps {
  availabilityStatus: AvailabilityStatus;
  assignmentPhase: AssignmentPhase;
  realEstateAgencyId: string;
  assignmentId: string;
  host: string;
  displayName: string;
  linkedApplicants: LinkedRelation[];
  mixedForms: MixedFormOption[];
  saleOffer: SaleOffer;
  changeAvailabilityStatus: string;
  realEstateGroup: RealEstateGroup;
  isTransactionInformationConfidential?: boolean;
  onCloseAvailabilityModal: () => void;
  sellAssignment: (
    assignmentId: string,
    dateTransfer: Date,
    dateSold: Date,
    salePrice: number,
    endDateBankWarranty: Date,
    linkedApplicants: LinkedRelation[],
    isSaleAndLeaseBack: boolean,
    DateAgreement: Date,
    dateReservation: Date,
    isTransactionInformationConfidential: boolean
  ) => void;
  navigate: (url: string) => void;
}
interface AssignmentStatusModalSoldComponentState {
  showDateReservation: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentStatusModalSoldComponent extends React.Component<
  AssignmentStatusModalSoldComponentProps,
  AssignmentStatusModalSoldComponentState
> {
  private formRef: FormReference;
  private formControls: FormControls;
  private focusRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      showDateReservation: true,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedApplicants: {
        value: value(this.props, "linkedApplicants", []),
        validators: [
          Validators.arrayMinCount(1, { key: "missingLinkedApplicants" }),
        ],
      },
      soldUnderReservation: {
        value: this.state.showDateReservation,
        onChange: (ref) => {
          this.setState({ showDateReservation: ref.value });
        },
      },
      dateReservation: {
        value: value(this.props, "saleOffer.dateReservation"),
        validators: [
          Validators.required(undefined, () => !this.state.showDateReservation),
        ],
      },
      dateSold: {
        value: value(this.props, "saleOffer.dateSold"),
        validators: [Validators.required()],
      },
      timeSold: { value: "09:00", validators: [Validators.required()] },
      endDateBankWarranty: {
        value: value(this.props, "saleOffer.endDateBankWarranty"),
      },
      dateTransfer: {
        value: value(this.props, "saleOffer.dateTransfer"),
        validators: [Validators.required()],
      },
      salePrice: {
        value:
          value(this.props, "saleOffer.purchasePrice") ||
          value(this.props, "saleOffer.salePrice"),
        validators: [
          Validators.priceDifference(value(this.props, "saleOffer.salePrice"), {
            key: "priceDifferenceToLargeSale",
          }),
        ],
      },
      isSaleAndLeaseBack: {
        value: value(this.props, "saleOffer.isSaleAndLeaseBack", false),
      },
      dateAgreement: { value: value(this.props, "saleOffer.agreementDate") },
      isTransactionInformationConfidential: {
        value:
          this.props.isTransactionInformationConfidential === true
            ? true
            : false,
      },
    };

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onCancelHandler = this.onCancelHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.showUnderCondition = this.showUnderCondition.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentStatusModalSoldComponentProps
  ) {
    if (nextProps.changeAvailabilityStatus === REQUEST.SUCCESS) {
      this.props.onCloseAvailabilityModal();
    }

    if (
      !!nextProps.linkedApplicants &&
      !isEqual(nextProps.linkedApplicants, this.props.linkedApplicants)
    ) {
      this.formRef.update({
        linkedApplicants: value(nextProps, "linkedApplicants", []),
      });
    }

    if (
      !!nextProps.saleOffer &&
      !isEqual(nextProps.saleOffer, this.props.saleOffer)
    ) {
      this.formRef.update({
        soldUnderReservation: this.state.showDateReservation,
        dateReservation: value(nextProps, "saleOffer.dateReservation"),
        dateSold: value(nextProps, "saleOffer.dateSold"),
        timeSold: "09:00",
        endDateBankWarranty: value(nextProps, "saleOffer.endDateBankWarranty"),
        dateTransfer: value(nextProps, "saleOffer.dateTransfer"),
        salePrice:
          value(nextProps, "saleOffer.purchasePrice") ||
          value(nextProps, "saleOffer.salePrice"),
        isSaleAndLeaseBack: value(
          nextProps,
          "saleOffer.isSaleAndLeaseBack",
          false
        ),
        dateAgreement: value(nextProps, "saleOffer.agreementDate"),
      });
    }
  }

  public render() {
    const { realEstateGroup } = this.props;
    const dateAgreement =
      realEstateGroup === RealEstateGroup.Agricultural
        ? "dateAgreementALV"
        : "dateAgreement";
    const dateSold =
      realEstateGroup === RealEstateGroup.Agricultural
        ? "dateSoldALV"
        : "dateSold";
    const endDateBankWarranty =
      realEstateGroup === RealEstateGroup.Agricultural
        ? "endDateBankWarrantyALV"
        : "endDateBankWarranty";
    const dateOfTransfer =
      realEstateGroup === RealEstateGroup.Agricultural
        ? "dateOfTransferALV"
        : "dateOfTransfer";

    return (
      <div styleName="status-modal" ref={(ref) => (this.focusRef = ref)}>
        <Form
          name="sold"
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
          onSubmit={this.onSubmitHandler}
        >
          {/* Linked applicants */}
          <div className="form__row">
            <label htmlFor="linkedApplicants">
              <ResourceText resourceKey="linkedApplicantsSold" />
            </label>
            <Input.RelationQuery
              name="linkedApplicants"
              multiple
              filterByRelationTypes={[
                RelationType.ContactPerson,
                RelationType.ContactCompany,
              ]}
              filterByActive={ActiveFilter.ActiveOnly}
              onAdd={this.addNewRelation}
              onNavigateToRelation={this.props.navigate}
            />
          </div>

          {/* Sold under reservation */}
          {this.showUnderCondition() && (
            <div className="form__row">
              <Input.Switch
                name="soldUnderReservation"
                on={true}
                off={false}
                label="soldUnderReservation"
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          )}

          {/* Date reservation */}
          {this.state.showDateReservation && this.showUnderCondition() && (
            <div className="form__row" styleName="date-field">
              <label htmlFor="">
                <ResourceText resourceKey="dateReservation" />
              </label>
              <Input.Datepicker
                name="dateReservation"
                hour={0}
                minutes={0}
                seconds={0}
              />
            </div>
          )}

          {/* Date Agreement */}
          {this.props.realEstateGroup === RealEstateGroup.Agricultural && (
            <div className="form__row" styleName="date-field">
              <label htmlFor="dateAgreement">
                <ResourceText resourceKey={dateAgreement} />
              </label>
              <Input.Datepicker
                name="dateAgreement"
                hour={0}
                minutes={0}
                seconds={0}
              />
            </div>
          )}

          <div className="form__row">
            <div className="form__group">
              {/* Date sold */}
              <div className="column" styleName="date-field">
                <label htmlFor="dateSold">
                  <ResourceText resourceKey={dateSold} />
                </label>
                <Input.Datepicker name="dateSold" />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="dateSold">
                  <ResourceText resourceKey="timeSold" />
                </label>
                <Input.Time name="timeSold" />
              </div>
            </div>
          </div>

          {/* Enddate bank warranty */}
          <div className="form__row" styleName="date-field">
            <label htmlFor="endDateBankWarranty">
              <ResourceText resourceKey={endDateBankWarranty} />
            </label>
            <Input.Datepicker
              name="endDateBankWarranty"
              hour={0}
              minutes={0}
              seconds={0}
            />
          </div>

          {/* Sale and lease back */}
          {this.props.realEstateGroup === RealEstateGroup.Commercial && (
            <div className="form__row">
              <Input.Switch
                name="isSaleAndLeaseBack"
                on={true}
                off={false}
                label="isSaleAndLeaseBack"
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          )}

          {/* Date of transfer */}
          <div className="form__row" styleName="date-field">
            <label htmlFor="dateTransfer">
              <ResourceText resourceKey={dateOfTransfer} />
            </label>
            <Input.Datepicker
              name="dateTransfer"
              hour={0}
              minutes={0}
              seconds={0}
            />
          </div>

          {/* Transaction price */}
          <div className="form__row">
            <label htmlFor="salePrice">
              <ResourceText resourceKey="transactionPriceSold" />
            </label>
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number name="salePrice" pretty step={1000} asPrice />
              {value(this.props, "saleOffer.saleCondition") && (
                <div className="post full">
                  <ResourceText
                    masterKey="saleConditions"
                    resourceKey={this.props.saleOffer.saleCondition.toString()}
                  />
                </div>
              )}
            </div>
          </div>

          {realEstateGroup === RealEstateGroup.Commercial && (
            <div className="form__row">
              <Input.Switch
                name="isTransactionInformationConfidential"
                on={false}
                off={true}
                label="isTransactionInformationConfidential"
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          )}
        </Form>

        {this.props.assignmentPhase === AssignmentPhase.Concept && (
          <div className="alert alert-info">
            <i className="fal fa-fw fa-info-circle" />
            <ResourceText resourceKey="soldModalInfo" asHtml={true} />
          </div>
        )}

        <div styleName="actions">
          <button
            type="button"
            className="btn"
            onClick={this.onCancelHandler}
            disabled={this.props.changeAvailabilityStatus === REQUEST.PENDING}
          >
            <ResourceText resourceKey="cancel" />
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.onSaveClickHandler}
            disabled={this.props.changeAvailabilityStatus === REQUEST.PENDING}
          >
            <ButtonLoader
              resourceKey={
                this.props.availabilityStatus === AvailabilityStatus.Available
                  ? "saveAsSold"
                  : "saveAsSoldAndPublish"
              }
              loading={this.props.changeAvailabilityStatus === REQUEST.PENDING}
            />
          </button>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  private onSaveClickHandler() {
    if (this.formRef && this.props.changeAvailabilityStatus !== REQUEST.PENDING)
      this.formRef.submit();
  }

  private onCancelHandler() {
    if (this.props.changeAvailabilityStatus !== REQUEST.PENDING)
      this.props.onCloseAvailabilityModal();
  }

  private showUnderCondition(): boolean {
    switch (this.props.availabilityStatus) {
      case AvailabilityStatus.Available:
      case AvailabilityStatus.Rented:
      case AvailabilityStatus.RentedUnderCondition:
      case AvailabilityStatus.Sold:
      case AvailabilityStatus.SoldUnderCondition:
      case AvailabilityStatus.UnderBid:
      case AvailabilityStatus.UnderOption:
        return true;
      default:
        switch (this.props.assignmentPhase) {
          case AssignmentPhase.Concept:
            return true;
          default:
            return false;
        }
    }
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedApplicants } = this.formRef.getValues();
    linkedApplicants = linkedApplicants || [];

    this.formRef.update({
      linkedApplicants: [...linkedApplicants, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private onSubmitHandler(values: FormReturnValue) {
    if (this.props.changeAvailabilityStatus === REQUEST.PENDING) return;

    const dateSold = DateUtil.dateAndTimeToDate(
      values.dateSold,
      values.timeSold
    );
    const dateTransfer = values.dateTransfer ? values.dateTransfer : undefined;
    const endDateBankWarranty = values.endDateBankWarranty
      ? values.endDateBankWarranty
      : undefined;
    const dateReservation = values.soldUnderReservation
      ? values.dateReservation
      : undefined;
    const dateAgreement = values.dateAgreement
      ? values.dateAgreement
      : undefined;

    this.props.sellAssignment(
      this.props.assignmentId,
      dateTransfer,
      dateSold,
      values.salePrice,
      endDateBankWarranty,
      values.linkedApplicants,
      values.isSaleAndLeaseBack,
      dateAgreement,
      dateReservation,
      values.isTransactionInformationConfidential
    );
  }
}
