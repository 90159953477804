import * as React from "react";
import { FC, memo, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import Icon from "@haywork/components/ui/icon";
import { NotesContainerProps } from "./notes.container";

const styles = require("./style.scss");

export interface NotesComponentProps {}
type Props = NotesComponentProps & NotesContainerProps;

export const NotesComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ notesVisible, toggleListVisibility, totalCount }) => {
      const count = !totalCount
        ? ""
        : totalCount >= 100
        ? "99+"
        : totalCount.toString();

      const toggleVisiblility = () => {
        toggleListVisibility(!notesVisible);
      };

      return (
        <div styleName="notes" onClick={toggleVisiblility}>
          <Icon name="comment-alt" size={24} light />
          <div styleName="notes__count">{count}</div>
        </div>
      );
    }
  )
);
