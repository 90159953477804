import { ActionType, ActionTypes } from "./actions";
import {
  InvoicesStatistics,
  InvoiceSnapShot,
  SortOrder,
  InvoiceOrderByField,
  ActiveFilter,
} from "@haywork/api/kolibri";
import { InvoiceOrder } from ".";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterType } from "@haywork/enum/list-filter-types";

export type State = {
  invoices: InvoiceSnapShot[];
  totalCount: number;
  statistics: InvoicesStatistics;
  order: InvoiceOrder;
  filters: FilterConfig;
  scrollOffset: number;
};

const INITIAL: State = {
  invoices: [],
  totalCount: 0,
  statistics: {
    noEndDateCount: 0,
    expiredCount: 0,
    expiresTodayCount: 0,
    expiresTomorrowCount: 0,
    expiresNextWeekCount: 0,
    expiresAfterTwoWeeksCount: 0,
    completedCount: 0,
  },
  order: {
    sortOrder: SortOrder.Ascending,
    sortColumn: InvoiceOrderByField.CreationDate,
  },
  filters: {
    filterByActive: {
      type: FilterType.Array,
      value: [ActiveFilter.ActiveOnly],
      emptyValue: [],
      prefix: "invoices.activeFilter",
    },
    statusFilter: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "invoiceStatuses",
    },
    overdue: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
    invoiceDate: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
  },
  scrollOffset: 0,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.ResetList: {
      return {
        ...state,
        invoices: [],
        totalCount: 0,
      };
    }
    case ActionType.UpdateList: {
      const { items, totalCount, statistics, startIndex } = action;

      let invoices = [...state.invoices];
      if (!invoices.length) {
        invoices = new Array(totalCount).fill(null);
      }

      invoices = items.reduce((state, assignment, idx) => {
        state[startIndex + idx] = assignment;
        return state;
      }, invoices);

      return {
        ...state,
        invoices,
        totalCount,
        statistics: !statistics ? state.statistics : statistics,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        invoices: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        invoices: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;

      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          !!invoice && invoice.id === snapshot.id ? snapshot : invoice
        ),
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
