import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import { LinkedEntity } from "@haywork/components/ui/linked-entities";
import LinkedRelation from "./linked-relation";
import LinkedAssignment from "./linked-assignment";

type Props = {
  visible: boolean;
  linkedEntities: LinkedEntity[];
  onClose: () => void;
  onNavigate: (path: string) => void;
};

export const LinkedEntitiesModal: FC<Props> = memo(
  ({ visible, linkedEntities, onClose, onNavigate }) => {
    const onNavigateHandler = useCallback((path: string) => {
      onClose();
      onNavigate(path);
    }, []);

    return (
      <Modal visible={visible} onClose={onClose}>
        <ModalHeader title="linkedEntitiesModal.header.title" close />
        <ModalBody>
          {linkedEntities.map((linkedEntity) =>
            linkedEntity.type === "relation" ? (
              <LinkedRelation
                key={linkedEntity.entity.id}
                linkedEntity={linkedEntity.entity}
                onNavigate={onNavigateHandler}
              />
            ) : (
              <LinkedAssignment
                key={linkedEntity.entity.id}
                linkedEntity={linkedEntity.entity}
                onNavigate={onNavigateHandler}
              />
            )
          )}
        </ModalBody>
      </Modal>
    );
  }
);
