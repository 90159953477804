import { Actions as Single, singleReducer, SingleState } from "./single";
import {
  Actions as List,
  listReducer,
  ListState,
  AcquisitionFilters
} from "./list";

import {
  Actions as ListV2,
  listReducer as listReducerV2,
  ListState as ListStateV2
} from "./listV2";

import {
  Actions as Assignment,
  assignmentReducer,
  AssignmentState
} from "./assignment";
import { combineReducers } from "redux";

type AcquisitionState = {
  single: SingleState;
  list: ListState;
  listV2: ListStateV2;
  assignment: AssignmentState;
};

const acquisitionReducer = combineReducers({
  single: singleReducer,
  list: listReducer,
  listV2: listReducerV2,
  assignment: assignmentReducer
});

const AcquisitionActions = {
  Single,
  List,
  ListV2,
  Assignment
};

export {
  acquisitionReducer,
  AcquisitionState,
  AcquisitionFilters,
  AcquisitionActions
};
