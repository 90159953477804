import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./global-search.types";
import { AssignmentSnapShot, RelationSnapShot, InvoiceSnapShot, TaskSnapShot } from "@haywork/api/kolibri";

export interface GlobalSearchState {
  searchStatus: string;
  assignments: AssignmentSnapShot[];
  relations: RelationSnapShot[];
  invoices: InvoiceSnapShot[];
  tasks: TaskSnapShot[];
  assignmentCount: number;
  relationCount: number;
  invoiceCount: number;
  taskCount: number;
}

const INITIAL_STATE: GlobalSearchState = {
  searchStatus: REQUEST.IDLE,
  assignments: [],
  relations: [],
  invoices: [],
  tasks: [],
  assignmentCount: 0,
  relationCount: 0,
  invoiceCount: 0,
  taskCount: 0
};

export const globalSearchReducer = (state: GlobalSearchState = INITIAL_STATE, action: Action): GlobalSearchState => {
  switch (action.type) {
    case REDUX.GLOBALSEARCH.SET_RESULTS_STATUS: {
      const { searchStatus } = <ActionType.SearchStatus> action;

      return {
        ...state,
        searchStatus
      };
    }
    case REDUX.GLOBALSEARCH.SET_INITIAL_RESULTS: {
      const { assignments, relations, invoices, tasks } = <ActionType.InitialResults> action;

      return {
        ...state,
        searchStatus: REQUEST.IDLE,
        assignments: assignments.results,
        relations: relations.results,
        invoices: invoices.results,
        tasks: tasks.results,
        assignmentCount: assignments.totalResults,
        relationCount: relations.totalResults,
        invoiceCount: invoices.totalResults,
        taskCount: tasks.totalResults
      };
    }
    case REDUX.GLOBALSEARCH.SET_ASSIGNMENT_RESULTS: {
      const { assignments } = <ActionType.InitialResults> action;

      return {
        ...state,
        searchStatus: REQUEST.IDLE,
        assignments: assignments.results,
        assignmentCount: assignments.totalResults
      };
    }
    case REDUX.GLOBALSEARCH.SET_RELATION_RESULTS: {
      const { relations } = <ActionType.InitialResults> action;

      return {
        ...state,
        searchStatus: REQUEST.IDLE,
        relations: relations.results,
        relationCount: relations.totalResults
      };
    }
    case REDUX.GLOBALSEARCH.SET_INVOICE_RESULTS: {
      const { invoices } = <ActionType.InitialResults> action;

      return {
        ...state,
        searchStatus: REQUEST.IDLE,
        invoices: invoices.results,
        invoiceCount: invoices.totalResults
      };
    }
    case REDUX.GLOBALSEARCH.SET_TASK_RESULTS: {
      const { tasks } = <ActionType.InitialResults> action;

      return {
        ...state,
        searchStatus: REQUEST.IDLE,
        tasks: tasks.results,
        invoiceCount: tasks.totalResults
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
