import { FolderCategory } from "@haywork/api/mail";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import AccountAndFolders, { AccountWithFolders } from "../account";
import { UserFoldersContainerProps } from "./user-folders.container";

const styles = require("./style.scss");

export type UserFoldersComponentProps = {};
type Props = UserFoldersComponentProps & UserFoldersContainerProps;

export const UserFoldersComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ accounts, folders, moveFolder }) => {
      const showUserFolders = useMemo(() => {
        return !!accounts.length && !!folders.length;
      }, [accounts, folders]);

      const accountAndFolders = useMemo(() => {
        if (!showUserFolders) return [] as AccountWithFolders[];
        return accounts.reduce((state, account) => {
          const accountFolders = folders.filter(
            (folder) =>
              !folder.parentId &&
              folder.category === FolderCategory.UserCreated &&
              folder.accountId === account.id
          );

          state.push({
            account,
            folders: accountFolders
          } as AccountWithFolders);

          return state;
        }, [] as AccountWithFolders[]);
      }, [showUserFolders, accounts, folders]);

      if (!showUserFolders) return null;

      return (
        <div styleName="wrapper">
          {accountAndFolders.map((item) => (
            <AccountAndFolders
              item={item}
              key={item.account.id}
              moveFolder={moveFolder}
            />
          ))}
        </div>
      );
    }
  )
);
