import PageHeader from "@haywork/components/ui/page-header";
import { SettingsGeneralContainerProps } from "@haywork/modules/settings/modules/general";
import * as React from "react";
import { memo, FC } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./general.component.scss");

export interface SettingsGeneralComponentProps {}
type Props = SettingsGeneralComponentProps & SettingsGeneralContainerProps;

export const SettingsGeneralComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ idManagerUri }) => {
    return (
      <div styleName="general">
        <PageHeader title="settingsMenuGeneral" />
        <iframe src={idManagerUri} />
      </div>
    );
  })
);
