import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { KeysContainerProps } from "./keys.container";
import * as CSSModules from "react-css-modules";
import KeysModal from "./components/keys-modal";
import { ExtendedKey } from "@haywork/middleware";

const styles = require("./style.scss");

export type KeysComponentProps = {
  keyNr: number | null | undefined;
  keyNote: string | null | undefined;
  onChange: (
    keyNumber: number | undefined,
    keyNote: string | undefined
  ) => void;
};
type Props = KeysComponentProps & KeysContainerProps;

export const KeysComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      keyNr: passedKeyNumber,
      keyNote: passedKeyNote,
      onChange,
      getFirstAvailableKey
    }) => {
      const [keyNr, setKeyNr] = useState(passedKeyNumber);
      const [keyNote, setKeyNote] = useState(passedKeyNote);
      const [loading, setLoading] = useState(false);
      const [keysModalVisible, setKeysModalVisible] = useState(false);

      const setKeyValues = useCallback(
        (
          passedKeyNumber: number | null | undefined,
          passedKeyNote: string | null | undefined
        ) => {
          setKeyNr(passedKeyNumber);
          setKeyNote(passedKeyNote);
        },
        [setKeyNr, setKeyNote]
      );

      const onUpdateKeyNote = useCallback(
        (keyNote: string) => {
          setKeyNote(keyNote);
          onChange(keyNr, keyNote);
        },
        [setKeyNote, onChange, keyNr]
      );

      const removeKeyNumber = useCallback(() => {
        setKeyNr(undefined);
        onChange(undefined, keyNote || undefined);
      }, [setKeyNr, keyNote, onChange]);

      const addKeyNumber = useCallback(async () => {
        try {
          setLoading(true);
          const keyNr = await getFirstAvailableKey();
          setKeyNr(keyNr);
          onChange(keyNr, keyNote || undefined);
        } finally {
          setLoading(false);
        }
      }, [getFirstAvailableKey, setKeyNr, keyNote, onChange, setLoading]);

      const closeKeysModal = useCallback(() => {
        setKeysModalVisible(false);
      }, [setKeysModalVisible]);

      const openKeysModal = useCallback(() => {
        setKeysModalVisible(true);
      }, [setKeysModalVisible]);

      const setSelectedKeyNr = useCallback(
        (selectedKey: ExtendedKey) => {
          setKeyNr(selectedKey.id);
          onChange(selectedKey.id, keyNote || undefined);
          closeKeysModal();
        },
        [setKeyNr, keyNote, onChange, closeKeysModal]
      );

      useEffect(() => {
        setKeyValues(passedKeyNumber, passedKeyNote);
      }, [passedKeyNumber, passedKeyNote, setKeyValues]);

      return (
        <div>
          {!keyNr ? (
            <Button
              label="keysWidget.action.add"
              category="primary"
              onClick={addKeyNumber}
              disabled={loading}
              icon={
                loading ? (
                  <Icon
                    name="spinner"
                    spin
                    regular
                    containIn={24}
                    color={Colors.Gray}
                  />
                ) : null
              }
            />
          ) : (
            <>
              <div className="form__row">
                <div className="form__group">
                  <div className="column" styleName="key-number__column">
                    <label htmlFor="keysWidget.keyNr">
                      <I18n value="keysWidget.label.keyNr" />
                    </label>
                    <div styleName="key-number" onClick={openKeysModal}>
                      <I18n
                        value="keysWidget.body.keyNr"
                        values={{ keyNr }}
                        asHtml
                      />
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column" styleName="key-note__column">
                    <label htmlFor="keysWidget.keyNote">
                      <I18n value="keysWidget.label.keyNote" />
                    </label>
                    <Input.Textarea
                      name="keysWidget.keyNote"
                      placeholder="keysWidget.placeholder.keyNote"
                      autoSize
                      minHeight={128}
                      asSingleInput
                      value={keyNote}
                      onChange={onUpdateKeyNote}
                    />
                  </div>
                </div>
              </div>
              <div styleName="footer">
                <div styleName="footer__info">
                  <I18n value="keysWidget.body.info" />
                </div>
                <div styleName="footer__action">
                  <span onClick={removeKeyNumber}>
                    <I18n value="keysWidget.action.remove" />
                  </span>
                </div>
              </div>
            </>
          )}

          <KeysModal
            visible={keysModalVisible}
            selectedKeyNr={keyNr}
            onClose={closeKeysModal}
            onKeySelect={setSelectedKeyNr}
          />
        </div>
      );
    }
  )
);
