import { RootEntityType } from "@haywork/api/event-center";
import {
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import {
  File,
  FolderCategory,
  MessageEvent,
  MessageEventType,
} from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Presence from "@haywork/components/ui/presence";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import Attachment, {
  EmailAttachmentAction,
} from "@haywork/modules/email/components/attachment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FontStyle, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { ExtendedEmailFolder } from "@haywork/util/email";
import get from "lodash-es/get";
import head from "lodash-es/head";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import CommunicationLogWidget from "../communication-log";
import { EmailBody } from "./body.component";
import { MessageEvents } from "./events.component";
import { NamesComponent } from "./names.component";
import { EmailMessage } from "@haywork/stores/email-v2";

const styles = require("./detail.component.scss");

export interface Props {
  message: EmailMessage;
  messageStatus: string;
  folder?: ExtendedEmailFolder;
  isMessageBlob?: boolean;
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  onDownloadExternalReferences: (message: EmailMessage) => void;
  onDownloadAttachment: (file: File) => void;
  onSaveAttachmentsToDossier: (
    file: File[],
    linkedRelations: LinkedRelation[],
    linkedAssignments: LinkedAssignment[]
  ) => void;
}

export const EmailPaper: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      message,
      messageStatus,
      folder,
      onDownloadExternalReferences,
      isMessageBlob,
      onDownloadAttachment,
      onSaveAttachmentsToDossier,
      linkedRelations: refLinkedRelations,
      linkedAssignments: refLinkedAssignments,
    }) => {
      const {
        date,
        from,
        subject,
        body,
        to,
        cc,
        bcc,
        events,
        files,
        _metaData,
      } = message;

      const linkedCommunicationLogId = useMemo(() => {
        return get(message._metaData, "linkedCommunicationLogId");
      }, [message._metaData]);

      const [linkedRelations, setLinkedRelations] = useState(
        refLinkedRelations || []
      );
      const [linkedAssignments, setLinkedAssignments] = useState(
        refLinkedAssignments || []
      );

      const attachments = useMemo(() => {
        return (files || []).filter((message) => message.isAttachment);
      }, [files]);

      const header = useMemo(() => {
        return isMessageBlob ? (
          <>
            {!!from && !!from.length && (
              <div styleName="from">
                <div styleName="label">
                  <ResourceText
                    resourceKey="from"
                    fontStyle={FontStyle.UpperCase}
                  />
                </div>
                <NamesComponent emails={from} />
              </div>
            )}
            {!!to && !!to.length && (
              <div styleName="to">
                <div styleName="label">
                  <ResourceText
                    resourceKey="to"
                    fontStyle={FontStyle.UpperCase}
                  />
                </div>
                <NamesComponent emails={to} />
              </div>
            )}
          </>
        ) : (
          <div styleName="name">
            <NamesComponent
              emails={
                !!folder && folder.category === FolderCategory.Sent ? to : from
              }
              large
            />
          </div>
        );
      }, [isMessageBlob, from, to, folder]);

      const meta = useMemo(() => {
        return (
          <>
            {!!folder &&
              folder.category !== FolderCategory.Sent &&
              !!to &&
              to.length > 0 && (
                <div styleName="to">
                  <div styleName="label">
                    <ResourceText
                      resourceKey="to"
                      fontStyle={FontStyle.UpperCase}
                    />
                  </div>
                  <NamesComponent emails={to} />
                </div>
              )}
            {!!cc && cc.length > 0 && (
              <div styleName="cc">
                <div styleName="label">
                  <ResourceText
                    resourceKey="cc"
                    fontStyle={FontStyle.UpperCase}
                  />
                </div>
                <NamesComponent emails={cc} />
              </div>
            )}
            {!!bcc && bcc.length > 0 && (
              <div styleName="bcc">
                <div styleName="label">
                  <ResourceText
                    resourceKey="bcc"
                    fontStyle={FontStyle.UpperCase}
                  />
                </div>
                <NamesComponent emails={bcc} />
              </div>
            )}
          </>
        );
      }, [from, to, folder]);

      const dates = useMemo(() => {
        return (
          <div styleName="date">
            <FormattedDate
              value={date || ""}
              day="2-digit"
              month="long"
              year="numeric"
            />
            &nbsp;-&nbsp;
            <FormattedDate value={date || ""} hour="2-digit" minute="2-digit" />
          </div>
        );
      }, [date]);

      const tracking = useMemo(() => {
        if (
          !_metaData ||
          !_metaData.messageEvents ||
          !_metaData.messageEvents.length
        )
          return null;
        const opens = _metaData.messageEvents.filter(
          (event) => event.type === MessageEventType.Opened
        );
        const messageEvent = head(opens);

        return !messageEvent ? null : (
          <div styleName="tracking">
            <i className="fal fa-envelope-open-text" />
            <I18n
              value="email.message.tracking"
              values={{
                date: moment((messageEvent as MessageEvent).dateTime).format(
                  "dddd DD MMMM YYYY"
                ),
                time: moment((messageEvent as MessageEvent).dateTime).format(
                  "HH:mm"
                ),
              }}
            />
          </div>
        );
      }, [_metaData]);

      const onAttachmentActionHandler = useCallback(
        (action: EmailAttachmentAction, file: File) => {
          if (!action || !file) return;

          switch (action) {
            case EmailAttachmentAction.Download: {
              onDownloadAttachment(file);
              return;
            }
            case EmailAttachmentAction.Save: {
              onSaveAttachmentsToDossier(
                [file],
                linkedRelations,
                linkedAssignments
              );
              return;
            }
            default:
              return;
          }
        },
        [
          onDownloadAttachment,
          onSaveAttachmentsToDossier,
          linkedRelations,
          linkedAssignments,
        ]
      );

      const saveAllAttachments = useCallback(() => {
        onSaveAttachmentsToDossier(
          attachments,
          linkedRelations,
          linkedAssignments
        );
      }, [
        attachments,
        onSaveAttachmentsToDossier,
        linkedRelations,
        linkedAssignments,
      ]);

      const onCommunicationLogUpdate = useCallback(
        (communicationLog: CommunicationLog) => {
          const { linkedRelations, linkedAssignments } = communicationLog;
          setLinkedRelations(linkedRelations || []);
          setLinkedAssignments(linkedAssignments || []);
        },
        [setLinkedRelations, setLinkedAssignments]
      );

      return (
        <>
          <div styleName="paper__header">
            {/* Info */}
            <div styleName="info">
              <div styleName="info__row">
                {dates}
                {header}
                {meta}

                <div styleName="description">{subject}</div>
                <MessageEvents message={message} />
                {tracking}

                {!isMessageBlob && (
                  <CommunicationLogWidget
                    message={message}
                    linkedCommunicationLogId={linkedCommunicationLogId}
                    onUpdated={onCommunicationLogUpdate}
                    key={message.id}
                  />
                )}
              </div>

              {!isMessageBlob && (
                <Presence
                  entityType={RootEntityType.Unknown}
                  entityId={message.id}
                  avatarBorderColor={Colors.White}
                />
              )}
            </div>

            {/* Attachments */}
            {!!attachments.length && (
              <div styleName="attachments">
                {attachments.map((file) => (
                  <ErrorBoundary key={file.id}>
                    <Attachment
                      file={file}
                      onAction={onAttachmentActionHandler}
                    />
                  </ErrorBoundary>
                ))}

                {attachments.length > 1 && (
                  <div styleName="attachments__saveall">
                    <span className="as-link" onClick={saveAllAttachments}>
                      <ResourceText resourceKey="email.attachment.saveAll" />
                    </span>
                  </div>
                )}
              </div>
            )}

            {/* External references */}
            {!!message.hasBlockedExternalReferences && (
              <div styleName="external-references">
                <i className="fa fa-info-circle" />
                <div styleName="body">
                  <ResourceText resourceKey="emailExternalReferencesInfo" />
                </div>
                <div
                  styleName="trigger"
                  onClick={() => onDownloadExternalReferences(message)}
                >
                  <ResourceText resourceKey="emailDownloadExternalReferences" />
                </div>
              </div>
            )}
          </div>

          <div styleName="paper__body">
            {messageStatus === REQUEST.PENDING && <Ui.Loaders.List />}
            {messageStatus === REQUEST.SUCCESS && (
              <EmailBody body={body} events={events} />
            )}
          </div>
        </>
      );
    }
  )
);
