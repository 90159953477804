import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  Acceptance,
  ActiveFilter,
  AgeRange,
  AssignmentOrderByField,
  AssignmentPhase,
  AssignmentsClient,
  AssignmentSnapShot,
  AssignmentsSearchRequest,
  AssignmentType,
  AvailabilityStatus,
  BuyerType,
  Cadastre,
  CadastreOrderByField,
  CadastresClient,
  ChildAgeRange,
  CompanyListingsClient,
  ContractReason,
  EmployeeRole,
  FamilyIncome,
  GeoClient,
  HouseholdComposition,
  LeftBehindObject,
  LinkedCadastre,
  LinkedRelation,
  ListingType,
  MatchMailPeriod,
  MovingReason,
  ObjectAssignment,
  ObjectAssignmentLinkRelationRequest,
  ObjectAssignmentsClient,
  ObjectAssignmentsUpdateAvailabilityRequest,
  ProjectAssignmentsClient,
  PublicationsClient,
  PublicationSnapShot,
  RealEstateGroup,
  RentCondition,
  RentOffer,
  ReportCategory,
  SearchSearchAssignmentsRequest,
  SortOrder,
  TimelineActionType,
  TimelineEventsClient,
  TimelineOrderByField,
  TransactionMetaData,
  TransactionMetaDataClient,
  UpdateAvailabilityAction,
  UpdateAvailabilityRentedSettings,
  UpdateAvailabilitySoldSettings,
  WithdrawReason,
} from "@haywork/api/kolibri";
import { ReportsClient } from "@haywork/api/reports";
import { ASSIGNMENTROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { EditableCalleeType } from "@haywork/enum";
import { mapObjectAssignmentToAssignmentSnapshot } from "@haywork/mappers/object-assignment";
import { mapProjectAssignmentToAssignmentSnapshot } from "@haywork/mappers/project-assignment";
import { EditableThunks } from "@haywork/middleware";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AccountActions,
  AppState,
  AssigmentWidgetsActions,
  AssignmentListActions,
  AssignmentOverviewActions,
  AssignmentSingleActions,
  AssignmentTemplatesActions,
  EditableActions,
  EditableItem,
  ErrorActions,
  FormError,
  FormErrors,
  FormErrorsActions,
  KeyBoardActions,
  LayoutActions,
  OfficeActions,
  RelationActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import {
  AssignmentUtil,
  BackOfficeUtil,
  DateUtil,
  RouteUtil,
} from "@haywork/util";
import { AssignmentPublishError } from "@haywork/util/assignment";
import Axios, { AxiosRequestConfig } from "axios";
import { push } from "connected-react-router";
import { saveAs } from "file-saver";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import differenceBy from "lodash-es/differenceBy";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import head from "lodash-es/head";
import * as moment from "moment";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const exportAssignments = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      localities,
      employeeIds,
      officeIds,
      availabilityStatuses,
      listingTypes,
      filterByRealEstateGroups,
      filterByAssignmentTypes,
      kindOfAssignment,
      filterByKeyNumbers,
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    } = state.assignment.overview.assignmentsFilters;
    let { assignmentPhases } = state.assignment.overview.assignmentsFilters;
    const { host, apiVersion } = state.appSettings;

    let forSale = true;
    let forRent = true;
    let isArchived = false;

    assignmentPhases = assignmentPhases.reduce((state, phase) => {
      if (phase.toString() !== "archived") {
        state.push(phase);
      } else {
        isArchived = true;
      }
      return state;
    }, []);

    const filterByActive = isArchived
      ? ActiveFilter.InactiveOnly
      : ActiveFilter.ActiveOnly;

    if (kindOfAssignment && kindOfAssignment.length === 1) {
      switch (first(kindOfAssignment)) {
        case "sale":
          forRent = false;
          break;
        case "rentLease":
          forSale = false;
          break;
        default:
          break;
      }
    }

    const props = {
      assignmentPhases,
      listingTypes,
      availabiltyStatuses: availabilityStatuses,
      officeIds,
      employeeIds,
      filterByRealEstateGroups,
      filterByActive,
      filterByAssignmentTypes,
      localities,
      forSale,
      forRent,
      culture: state.main.culture,
      access_token: state.access.token,
      filterByKeyNumbers,
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    };

    const url = `${host}/${apiVersion}/${realEstateAgencyId}/Assignments/SearchAndExport?${RouteUtil.mapObjectToGetParams(
      props
    )}`;

    window.open(url, "_blank");
  };
};

const getAssignment = (id: string, snapshot: AssignmentSnapShot) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.PENDING));
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
    const objectAssignment =
      AssignmentUtil.mapSnapshotToObjectAssignment(snapshot);

    let componentState = {
      objectAssignment,
      yisualLinks: null,
      cadastres: [],
      companyListings: [],
      publications: [],
      pastEvents: [],
      futureEvents: [],
      isInitial: true,
      lastMarketingRoute: null,
      transactionMetaData: null,
      receivedDataFromMLS: false,
    };

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.ASSIGNMENTS.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveAssignmentConfirmTitle" },
          body: { key: "saveAssignmentConfirmBody" },
        },
        entityType: RootEntityType.ObjectAssignment,
        entityId: id,
      })
    );

    if (!!objectAssignment) {
      dispatch(push(path));
      dispatch(AssignmentSingleActions.setAssignment(componentState));
      dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.SUCCESS));
    }

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const CompanyListings = new CompanyListingsClient(host);
    const Cadastres = new CadastresClient(host);
    const Publications = new PublicationsClient(host);
    const TimelineEvents = new TimelineEventsClient(host);
    const TransactionMetaData = new TransactionMetaDataClient(host);

    // Get actual assignment detail
    ObjectAssignments.read(id, realEstateAgencyId)
      .then((result) => {
        let { objectAssignment } = result;

        if (
          !objectAssignment.acceptanceDetails ||
          !objectAssignment.acceptanceDetails.acceptance
        ) {
          objectAssignment = {
            ...objectAssignment,
            acceptanceDetails: {
              ...(objectAssignment.acceptanceDetails || {}),
              acceptance: Acceptance.InConsultation,
            },
          };
        }

        objectAssignment =
          AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

        componentState = {
          ...componentState,
          objectAssignment,
          isInitial: false,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState,
            ignoreChanges: true,
          })
        );
        dispatch(
          AssignmentSingleActions.updateSingleAssignment(componentState)
        );

        const {
          linkedCadastres,
          linkedCompanyListings,
          linkedTransactionMetaData,
        } = result.objectAssignment;

        if (!!linkedTransactionMetaData) {
          TransactionMetaData.read(
            linkedTransactionMetaData.id,
            realEstateAgencyId
          ).then((response) => {
            componentState = {
              ...componentState,
              transactionMetaData: response.transactionMetaData,
            };

            dispatch(
              EditableActions.updateComponentState({
                path,
                componentState,
                ignoreChanges: true,
              })
            );
            dispatch(
              AssignmentSingleActions.updateSingleAssignment(componentState)
            );
          });
        }

        if (linkedCadastres && linkedCadastres.length > 0) {
          const cadastres = linkedCadastres.map((c) =>
            Cadastres.read(c.id, realEstateAgencyId)
          );

          if (cadastres.length > 0) {
            Promise.all(cadastres).then((result) => {
              componentState = {
                ...componentState,
                cadastres: result.map((response) => response.cadastre),
              };

              dispatch(
                EditableActions.updateComponentState({
                  path,
                  componentState,
                  ignoreChanges: true,
                })
              );
              dispatch(
                AssignmentSingleActions.updateSingleAssignment(componentState)
              );
            });
          }
        }
        if (linkedCompanyListings && linkedCompanyListings.length > 0) {
          const companyListings = linkedCompanyListings
            .filter((l) => !!l.isActive)
            .map((l) => CompanyListings.read(l.id, realEstateAgencyId));

          if (companyListings.length > 0) {
            Promise.all(companyListings).then((result) => {
              componentState = {
                ...componentState,
                companyListings: result.map(
                  (response) => response.companyListing
                ),
              };

              dispatch(
                EditableActions.updateComponentState({
                  path,
                  componentState,
                  ignoreChanges: true,
                })
              );
              dispatch(
                AssignmentSingleActions.updateSingleAssignment(componentState)
              );
            });
          }
        }
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.ERROR));
      });

    // Get assignment publications
    Publications.search(
      {
        assignmentId: id,
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        publications: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateSingleAssignment(componentState));
    });

    dispatch(
      EditableActions.updateComponentState({
        path,
        componentState,
        ignoreChanges: true,
      })
    );
    dispatch(AssignmentSingleActions.updateSingleAssignment(componentState));

    // Events shared request config
    const eventsRequestConfig = {
      orderBy: TimelineOrderByField.Date,
      includeStatistics: false,
      filterByActive: ActiveFilter.ActiveOnly,
      skip: 0,
      take: 5,
      order: SortOrder.Descending,
      assignmentId: id,
    };

    // Get past events
    TimelineEvents.search(
      {
        ...eventsRequestConfig,
        maxDateTime: new Date(),
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        pastEvents: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateSingleAssignment(componentState));
    });

    // Get future events
    TimelineEvents.search(
      {
        ...eventsRequestConfig,
        minDateTime: new Date(),
      },
      realEstateAgencyId
    ).then((result) => {
      componentState = {
        ...componentState,
        futureEvents: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateSingleAssignment(componentState));
    });
  };
};

const getAssignmentsByRelation = (relationIds: string[], take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Assignments = new AssignmentsClient(host);

    try {
      const result = await Assignments.search(
        {
          culture: state.main.culture,
          forSale: true,
          forRent: true,
          orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
          includeStatistics: false,
          filterByActive: ActiveFilter.ActiveOnly,
          skip: 0,
          take,
          order: SortOrder.Ascending,
          filterByAssignmentTypes: [
            AssignmentType.Object,
            AssignmentType.Acquisition,
            AssignmentType.AcquisitionObject,
          ],
          relationIds: [...relationIds],
        },
        realEstateAgencyId
      );

      const statuses = [
        AvailabilityStatus.Available,
        AvailabilityStatus.FarmedUnderCondition,
        AvailabilityStatus.LeasedUnderCondition,
        AvailabilityStatus.RentedUnderCondition,
        AvailabilityStatus.SoldUnderCondition,
        AvailabilityStatus.UnderBid,
        AvailabilityStatus.UnderOption,
      ];

      const filteredAssignments = (result.results || []).filter(
        (assignment) =>
          assignment.assignmentPhase === AssignmentPhase.Concept ||
          statuses.includes(assignment.availabilityStatus) ||
          assignment.typeOfAssignment === AssignmentType.Acquisition
      );

      return filteredAssignments;
    } catch (error) {
      dispatch(ErrorActions.setError({ error }));
      throw error;
    }
  };
};

const getAssignments = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AssignmentOverviewActions.setAssignmentsStatus({
        status: REQUEST.PENDING,
      })
    );
    const state = getState();
    const { overview } = state.assignment;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      localities,
      employeeIds,
      officeIds,
      availabilityStatuses,
      assignmentPhases,
      listingTypes,
      filterByRealEstateGroups,
      filterByAssignmentTypes,
      kindOfAssignment,
      filterByFurnishings,
      filterByMinPrice,
      filterByMaxPrice,
      filterByNumberOfBedroomsMin,
      filterByNumberOfBedroomsMax,
    } = overview.assignmentsFilters;
    const { host } = state.appSettings;
    const { features } = state.appSettings;

    const Assignments = new AssignmentsClient(host);

    let forSale = true;
    let forRent = true;

    if (kindOfAssignment && kindOfAssignment.length === 1) {
      switch (first(kindOfAssignment)) {
        case "sale":
          forRent = false;
          break;
        case "rentLease":
          forSale = false;
          break;
        default:
          break;
      }
    }

    let filterByActive = ActiveFilter.ActiveOnly;
    const filterByKeyNumbers = !overview.assignmentsFilters.filterByKeyNumbers
      ? []
      : overview.assignmentsFilters.filterByKeyNumbers.map((val) =>
          parseInt(val)
        );

    if (
      !employeeIds &&
      !filterByRealEstateGroups &&
      !listingTypes &&
      !localities &&
      !officeIds &&
      !filterByKeyNumbers &&
      assignmentPhases.length === 0
    ) {
      filterByActive = ActiveFilter.ActiveOrInactive;
    }

    if (assignmentPhases.map((p) => p.toString()).indexOf("archived") !== -1) {
      filterByActive = ActiveFilter.InactiveOnly;
    }

    const filteredAssignmentPhases = assignmentPhases.filter(
      (p) => p.toString() !== "archived"
    );

    let filterByAssignmentTypesFilter = [AssignmentType.Object];

    if (FeatureHelper.executeBlock(features, "PROJECTS")) {
      filterByAssignmentTypesFilter =
        !filterByAssignmentTypes || filterByAssignmentTypes.length === 0
          ? [AssignmentType.Object, AssignmentType.Project]
          : filterByAssignmentTypes;
    }

    try {
      const assignment = await parseRequest.response(
        Assignments.search(
          {
            culture: state.main.culture,
            forSale,
            forRent,
            orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
            includeStatistics: true,
            filterByActive,
            skip: init ? 0 : overview.assignments.page * take,
            take,
            order: SortOrder.Ascending,
            localities,
            employeeIds,
            officeIds,
            availabiltyStatuses: availabilityStatuses,
            assignmentPhases: filteredAssignmentPhases,
            listingTypes,
            filterByRealEstateGroups,
            filterByAssignmentTypes: filterByAssignmentTypesFilter,
            filterByKeyNumbers,
            filterByFurnishings,
            filterByMinPrice,
            filterByMaxPrice,
            filterByNumberOfBedroomsMin,
            filterByNumberOfBedroomsMax,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(
            AssignmentOverviewActions.setAssignments({ ...assignment, take })
          )
        : dispatch(
            AssignmentOverviewActions.appendAssignments({ ...assignment, take })
          );
    } catch (error) {
      throw error;
    }
  };
};

const getAssignmentsForOfficeDetail = (
  officeID: string,
  init: boolean = true,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    init
      ? dispatch(OfficeActions.setOfficeAssignmentInitialState(REQUEST.PENDING))
      : dispatch(OfficeActions.setOfficeAssignmentState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { officeAssignmentPage } = state.offices;
    const { host } = state.appSettings;

    const Assignments = new AssignmentsClient(host);

    try {
      const assignments = await parseRequest.response(
        Assignments.search(
          {
            officeIds: [officeID],
            filterByAssignmentTypes: [
              AssignmentType.Object,
              AssignmentType.Project,
              AssignmentType.ObjectType,
              AssignmentType.AcquisitionObject,
            ],
            forSale: true,
            forRent: true,
            orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: init ? 0 : officeAssignmentPage * take,
            take,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
      );

      if (!!init) {
        dispatch(OfficeActions.searchAssignments(assignments));
        dispatch(
          OfficeActions.setOfficeAssignmentInitialState(REQUEST.SUCCESS)
        );
      } else {
        dispatch(OfficeActions.appendAssignments(assignments));
        dispatch(OfficeActions.setOfficeAssignmentState(REQUEST.SUCCESS));
      }
    } catch (error) {
      throw error;
    }
  };
};

const getAssignmentsForRelationDetail = (
  relationId: string,
  init: boolean = true,
  take: number = 25
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setRelationAssignmentState(REQUEST.PENDING));

    const state = getState();
    const { relationAssignmentPage } = state.relation.detailAssignment;
    const { host } = state.appSettings;
    const { account } = state;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;

    const Assignments = new AssignmentsClient(host);

    try {
      const assignments = await parseRequest.response(
        Assignments.search(
          {
            assignmentIds: null,
            assignmentPhases: null,
            listingTypes: null,
            availabiltyStatuses: null,
            officeIds: null,
            employeeIds: null,
            relationIds: [relationId],
            filterByAssignmentTypes: null,
            filterByRealEstateGroups: null,
            countryCode: null,
            localities: null,
            forSale: true,
            forRent: true,
            termFields: null,
            orderBy: AssignmentOrderByField.LocalityStreetNameAndNumber,
            includeStatistics: true,
            filterByActive: ActiveFilter.ActiveOrInactive,
            term: null,
            skip: init ? 0 : relationAssignmentPage * take,
            take,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(RelationActions.searchAssignments(assignments))
        : dispatch(RelationActions.appendAssignments(assignments));

      dispatch(RelationActions.setRelationAssignmentState(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(RelationActions.setRelationAssignmentState(REQUEST.ERROR));
      throw error;
    }
  };
};

const searchAddress = (location: string, countryIso2: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const Geo = new GeoClient(host);
    let newState: SingleAssignmentState;

    dispatch(AssignmentSingleActions.setAddressSearchState(REQUEST.PENDING));

    try {
      const address = await parseRequest.response(
        Geo.addressSearch(
          {
            countryIso2,
            location,
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      const { objectAssignment } = currentComponentState;
      const updatedObjectAssignment = {
        ...objectAssignment,
        address,
      };
      newState = {
        ...currentComponentState,
        objectAssignment: updatedObjectAssignment,
      };

      const sublocalities = await parseRequest.response(
        Geo.sublocalitySearch(
          {
            culture,
            localityId: address.locality.id,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      newState = {
        ...newState,
        sublocalities,
      };

      dispatch(AssignmentSingleActions.setAddressSearchState(REQUEST.IDLE));
      dispatch(
        EditableActions.updateComponentState({
          componentState: newState,
          path: route(ASSIGNMENTROUTES.DETAIL.URI, {
            id: currentComponentState.objectAssignment.id,
          }),
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setAddressSearchState(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveSingleObjectAssignment = (assignment?: ObjectAssignment) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let componentState: SingleAssignmentState =
      state.editable.currentComponentState;
    const assignmentPath = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: componentState.objectAssignment.id,
    });

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);

    try {
      let objectAssignment = AssignmentUtil.prepareObjectAssignmentFloors(
        assignment || componentState.objectAssignment
      );

      if (objectAssignment.isNew) {
        const existing = await parseRequest.response(
          Assignments.search(
            {
              orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              assignmentIds: [objectAssignment.id],
              forRent: true,
              forSale: true,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          objectAssignment = {
            ...objectAssignment,
            isNew: false,
          };
        }
      }

      objectAssignment = await parseRequest.response(
        ObjectAssignments.save({ objectAssignment }, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );

      dispatch(AssignmentSingleActions.updateAssignment({ objectAssignment }));
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );

      componentState = {
        ...componentState,
        objectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const linkRelation = (
  relation: ObjectAssignmentLinkRelationRequest,
  updatedObjectAssignment: ObjectAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const assignmentPath = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: updatedObjectAssignment.id,
    });
    let componentState: SingleAssignmentState =
      state.editable.currentComponentState;

    // Clients
    const ObjectAssignments = new ObjectAssignmentsClient(host);
    dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING));

    try {
      await ObjectAssignments.linkRelation(relation, realEstateAgencyId);
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment: updatedObjectAssignment,
        })
      );
      componentState = {
        ...componentState,
        objectAssignment: updatedObjectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unlinkRelation = (
  relation: ObjectAssignmentLinkRelationRequest,
  updatedObjectAssignment: ObjectAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;
    const assignmentPath = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: updatedObjectAssignment.id,
    });
    let componentState: SingleAssignmentState =
      state.editable.currentComponentState;

    // Clients
    const ObjectAssignments = new ObjectAssignmentsClient(host);
    dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING));

    try {
      await ObjectAssignments.unlinkRelation(relation, realEstateAgencyId);
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment: updatedObjectAssignment,
        })
      );
      componentState = {
        ...componentState,
        objectAssignment: updatedObjectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: assignmentPath,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveAssignment = (
  objectAssignment?: ObjectAssignment,
  redirect: boolean = true,
  initial: boolean = false,
  close: boolean = false,
  silent: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    // State
    const state = getState();
    const { account, editable } = state;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = account.currentRealestateAgency;

    // Clients
    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Cadastres = new CadastresClient(host);
    const Publications = new PublicationsClient(host);
    const CompanyListings = new CompanyListingsClient(host);

    // Local variables
    let reference: SingleAssignmentState = editable.currentComponentState;
    const assignmentPath = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: reference.objectAssignment.id,
    });

    // Update reference if objectAssignment is passed
    if (!!objectAssignment) {
      const updatedObjectAssignment: ObjectAssignment = { ...objectAssignment };

      reference = {
        ...reference,
        objectAssignment: updatedObjectAssignment,
      };
    }

    // Add first photo to testimonial if objectassignment has photos.
    if (
      reference.objectAssignment.photos &&
      reference.objectAssignment.photos.length > 0 &&
      reference.objectAssignment.testimonial &&
      reference.objectAssignment.testimonial.showFrom
    ) {
      reference = {
        ...reference,
        objectAssignment: {
          ...reference.objectAssignment,
          testimonial: {
            ...reference.objectAssignment.testimonial,
            listingPhoto: { ...reference.objectAssignment.photos[0] },
          },
        },
      };
    }

    // Set pending state
    if (!silent) {
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING)
      );
    }

    // Check for cadastres and create cadastre promise
    const cadastres = reference.cadastres || [];
    const savedCadastres = [];
    const cadastrePromise = new Promise<void>(async (resolve, reject) => {
      if (cadastres.length === 0) {
        reference = {
          ...reference,
          objectAssignment: {
            ...reference.objectAssignment,
            linkedCadastres: [],
          },
          cadastres,
        };
        return resolve();
      }

      try {
        for (let cadastre of cadastres) {
          if (cadastre.isNew) {
            const existing = await parseRequest.response(
              Cadastres.search(
                {
                  orderBy: CadastreOrderByField.CreationDateTime,
                  filterByActive: ActiveFilter.ActiveOrInactive,
                  order: SortOrder.Ascending,
                  skip: 0,
                  take: 1,
                  cadastreId: cadastre.id,
                },
                realEstateAgencyId
              )
            );

            if (!!existing && !!existing.results && !!existing.results.length) {
              cadastre = {
                ...cadastre,
                isNew: false,
              };
            }
          }

          const savedCadastre = await Cadastres.save(
            { cadastre },
            realEstateAgencyId
          );
          savedCadastres.push(savedCadastre);
        }

        const linkedCadastres: any[] = savedCadastres.map((c) => ({
          id: c.cadastre.id,
        }));
        const newCadastres = savedCadastres.map((r) => r.cadastre);

        reference = {
          ...reference,
          objectAssignment: {
            ...reference.objectAssignment,
            linkedCadastres,
          },
          cadastres: newCadastres,
        };

        resolve();
      } catch (error) {
        reject(error);
      }
    });

    // Check for company listings
    const companyListings = reference.companyListings || [];
    const companyListingsPromise = new Promise<void>((resolve, reject) => {
      if (companyListings.length > 0) {
        const promises = companyListings.map((companyListing) =>
          CompanyListings.save({ companyListing }, realEstateAgencyId)
        );
        Promise.all(promises)
          .then((results) => {
            const linkedCompanyListings: any[] = results.map((r) => ({
              id: r.companyListing.id,
            }));
            reference = {
              ...reference,
              companyListings: results.map((r) => r.companyListing),
              objectAssignment: {
                ...reference.objectAssignment,
                linkedCompanyListings,
              },
            };
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve();
      }
    });

    // Resolve cadastre promise and save assignment
    cadastrePromise
      .then(() => companyListingsPromise)
      .then(() => {
        const objectAssignment = AssignmentUtil.prepareObjectAssignmentFloors(
          reference.objectAssignment
        );

        ObjectAssignments.save({ objectAssignment }, realEstateAgencyId)
          .then((result) => {
            reference = {
              ...reference,
              objectAssignment: result.objectAssignment,
            };

            return Publications.search(
              { assignmentId: reference.objectAssignment.id },
              realEstateAgencyId
            );
          })
          .then((result) => {
            reference = {
              ...reference,
              publications: result.results,
            };

            if (close) {
              dispatch(
                AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.IDLE)
              );
              return dispatch(EditableThunks.remove(assignmentPath));
            }

            // Fire dispatch actions
            dispatch(
              AssignmentSingleActions.updateAssignment({
                objectAssignment: reference.objectAssignment,
              })
            );
            dispatch(
              AssignmentSingleActions.updateCompanyListings(
                reference.companyListings || []
              )
            );
            dispatch(
              AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
            );
            dispatch(
              EditableActions.updateComponentState({
                componentState: reference,
                path: assignmentPath,
                ignoreChanges: true,
                resetExternalChanges: true,
              })
            );
            dispatch(
              EditableActions.removeHasExternalChanges({
                entityId: reference.objectAssignment.id,
                entityType: RootEntityType.ObjectAssignment,
              })
            );

            // Handle callback?
            const { states } = state.editable;
            const editableItem = states.find((s) => s.path === assignmentPath);
            if (!!editableItem && !!editableItem.caller) {
              dispatch(
                EditableActions.setCalleeId({
                  id: reference.objectAssignment.id,
                  path: editableItem.caller,
                })
              );
              dispatch(push(editableItem.caller));
              return dispatch(EditableThunks.remove(assignmentPath));
            }

            // Should redirect to general screen?
            if (!!redirect) {
              dispatch(
                push(
                  route(ASSIGNMENTROUTES.DETAIL.URI, {
                    id: reference.objectAssignment.id,
                  })
                )
              );
              dispatch(LayoutActions.toggleFullscreen(false));
            }

            // Should redirect after initial save?
            if (!!initial) {
              dispatch(
                push(
                  route(ASSIGNMENTROUTES.EDIT_FINANCIAL.URI, {
                    id: reference.objectAssignment.id,
                  })
                )
              );
            }

            if (!initial && !redirect) {
              const toast: ToastProps = {
                value: "toastAssignmentSaved",
                icon: "folder-open",
              };

              dispatch(SnackbarActions.addToast(toast));
            }
          })
          .catch((error) => {
            dispatch(ErrorActions.setError({ error }));
            dispatch(
              AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR)
            );
            dispatch(
              EditableActions.updateComponentState({
                componentState: reference,
                path: assignmentPath,
                ignoreChanges: true,
              })
            );
          });
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR)
        );
        dispatch(
          EditableActions.updateComponentState({
            componentState: reference,
            path: assignmentPath,
            ignoreChanges: true,
          })
        );
      });
  };
};

const clearAvailabilityStatus = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AssignmentSingleActions.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.IDLE,
      })
    );
  };
};

export interface TransactionMetaDataResponse {
  movingReasons?: MovingReason[];
  isResident?: boolean;
  numberOfHouseholdMembers?: number;
  buyerType?: BuyerType;
  householdComposition?: HouseholdComposition;
  ageMainMoneyMaker?: AgeRange;
  ageOldestChild?: ChildAgeRange;
  familyIncome?: FamilyIncome;
  leftBehindObject?: LeftBehindObject;
}
const transitionObjectAssignment = (
  assignment: ObjectAssignment,
  data: TransactionMetaDataResponse,
  soldSettings?: UpdateAvailabilitySoldSettings,
  rentedSettings?: UpdateAvailabilityRentedSettings
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AssignmentSingleActions.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.PENDING,
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignment.id });

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const TransactionMetaData = new TransactionMetaDataClient(host);

    try {
      let updatedTransactionMetaData: TransactionMetaData;
      if (!assignment.linkedTransactionMetaData) {
        const metaData = await parseRequest.response(
          TransactionMetaData.defineNew(
            { assignmentId: assignment.id },
            realEstateAgencyId
          ).then((response) => response.transactionMetaData)
        );

        updatedTransactionMetaData = {
          ...metaData,
          ...data,
        };
      } else {
        const metaData = await parseRequest.response(
          TransactionMetaData.read(
            assignment.linkedTransactionMetaData.id,
            realEstateAgencyId
          ).then((response) => response.transactionMetaData)
        );

        updatedTransactionMetaData = {
          ...metaData,
          ...data,
        };
      }

      const transactionMetaData = await parseRequest.response(
        TransactionMetaData.save(
          { transactionMetaData: updatedTransactionMetaData },
          realEstateAgencyId
        ).then((response) => response.transactionMetaData)
      );

      const updateAvailabilityAction = assignment.forSale
        ? UpdateAvailabilityAction.ToSold
        : UpdateAvailabilityAction.ToRented;

      let request: ObjectAssignmentsUpdateAvailabilityRequest = {
        id: assignment.id,
        updateAvailabilityAction,
      };

      if (assignment.forSale) {
        request = {
          ...request,
          soldSettings,
        };
      } else {
        request = {
          ...request,
          rentedSettings,
        };
      }

      const objectAssignment = await parseRequest.response(
        ObjectAssignments.updateAvailability(request, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
        transactionMetaData,
      };

      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.SUCCESS,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment,
          transactionMetaData,
        })
      );
    } catch (error) {
      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.ERROR,
        })
      );
      throw error;
    }
  };
};

const sellObjectAssignment = (
  assignmentId: string,
  dateTransfer: Date,
  dateSold: Date,
  salePrice: number,
  endDateBankWarranty: Date,
  linkedApplicants: LinkedRelation[],
  isSaleAndLeaseBack: boolean,
  dateAgreement: Date,
  dateReservation: Date,
  isTransactionInformationConfidential: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignmentId });

    dispatch(
      AssignmentSingleActions.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.PENDING,
      })
    );

    try {
      let objectAssignment = await parseRequest.response(
        ObjectAssignments.updateAvailability(
          {
            id: assignmentId,
            updateAvailabilityAction: UpdateAvailabilityAction.ToSold,
            soldSettings: {
              dateAgreement,
              dateOfTransfer: dateTransfer,
              dateReservation,
              dateSold,
              endDateBankWarranty,
              linkedApplicants,
              linkedNotaries:
                state.editable.currentComponentState.linkedNotaries,
              transactionPrice: salePrice,
              isSaleAndLeaseBack,
            },
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      objectAssignment = {
        ...objectAssignment,
        isTransactionInformationConfidential,
      };

      objectAssignment = await parseRequest.response(
        ObjectAssignments.save({ objectAssignment }, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
      };

      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.SUCCESS,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateAssignment({ objectAssignment }));
    } catch (error) {
      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.ERROR,
        })
      );
      throw error;
    }
  };
};

const rentObjectAssignment = (
  assignmentId: string,
  dateRentedFrom: Date,
  dateRentedUntil: Date,
  rentPrice: number,
  linkedApplicants: LinkedRelation[],
  isVacancyLaw: boolean,
  dateReservation: Date,
  dateSignDeed: Date,
  isTransactionInformationConfidential: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignmentId });

    dispatch(
      AssignmentSingleActions.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.PENDING,
      })
    );

    try {
      let objectAssignment = await parseRequest.response(
        ObjectAssignments.updateAvailability(
          {
            id: assignmentId,
            updateAvailabilityAction: UpdateAvailabilityAction.ToRented,
            rentedSettings: {
              dateRentedFrom,
              dateRentedUntil,
              dateReservation,
              isVacancyLaw,
              linkedApplicants,
              transactionPrice: rentPrice,
              dateSignDeed,
            },
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      objectAssignment = {
        ...objectAssignment,
        isTransactionInformationConfidential,
      };

      objectAssignment = await parseRequest.response(
        ObjectAssignments.save({ objectAssignment }, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
      };

      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.SUCCESS,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateAssignment({ objectAssignment }));
    } catch (error) {
      dispatch(
        AssignmentSingleActions.setChangeAvailabilityStatus({
          changeAvailabilityStatus: REQUEST.ERROR,
        })
      );
      throw error;
    }
  };
};

const withdrawObjectAssignment = (
  assignmentId: string,
  withdrawReason: WithdrawReason
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignmentId });
    const withdrawnDateTime = moment().subtract(5, "minutes").toDate();

    dispatch(
      AssignmentSingleActions.setChangeAvailabilityStatus({
        changeAvailabilityStatus: REQUEST.PENDING,
      })
    );

    await parseRequest.response(
      ObjectAssignments.updateAvailability(
        {
          id: assignmentId,
          updateAvailabilityAction: UpdateAvailabilityAction.ToWithdrawn,
          withdrawnSettings: {
            withdrawnDateTime,
            withdrawReason,
          },
        },
        realEstateAgencyId
      )
        .then((result) => {
          dispatch(
            AssignmentSingleActions.setChangeAvailabilityStatus({
              changeAvailabilityStatus: REQUEST.SUCCESS,
            })
          );
          const componentState: SingleAssignmentState = {
            ...currentComponentState,
            objectAssignment: result.objectAssignment,
          };
          dispatch(
            EditableActions.updateComponentState({
              componentState,
              path,
              ignoreChanges: true,
            })
          );
          dispatch(
            AssignmentSingleActions.updateAssignment({
              objectAssignment: result.objectAssignment,
            })
          );
        })
        .catch((error) => {
          dispatch(ErrorActions.setError({ error }));
          dispatch(
            AssignmentSingleActions.setChangeAvailabilityStatus({
              changeAvailabilityStatus: REQUEST.ERROR,
            })
          );
        })
    );
  };
};

const publishObjectAssignment = (id: string, date?: Date) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        AssignmentSingleActions.setPublishStatus({
          publishState: REQUEST.PENDING,
        })
      );

      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const { currentComponentState } = state.editable;
      const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

      const ObjectAssignments = new ObjectAssignmentsClient(host);
      const Assignments = new AssignmentsClient(host);

      try {
        let objectAssignment = AssignmentUtil.prepareObjectAssignmentFloors(
          state.editable.currentComponentState.objectAssignment
        );

        if (objectAssignment.isNew) {
          const existing = await parseRequest.response(
            Assignments.search(
              {
                orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
                filterByActive: ActiveFilter.ActiveOrInactive,
                order: SortOrder.Ascending,
                skip: 0,
                take: 1,
                assignmentIds: [objectAssignment.id],
                forRent: true,
                forSale: true,
                includeStatistics: false,
              },
              realEstateAgencyId
            )
          );

          if (!!existing && !!existing.results && !!existing.results.length) {
            objectAssignment = {
              ...objectAssignment,
              isNew: false,
            };
          }
        }

        if (!!date) {
          objectAssignment = {
            ...objectAssignment,
            hideOnFundaUntil: date,
          };
        }

        /**
         * Check if realEstateAgency is assigned to MLS groups with autoPublish and then check if
         * assignment is missing mlsGroup and mlsReleaseDate props. If so customer has not updated
         * props manually and the group must be added.
         */
        const mlsAutoPublishGroups = (state.mls.list.agencyGroups || []).filter(
          (group) => !!group.autoPublish
        );
        const hasMlsAutoPublishGroups = mlsAutoPublishGroups.length > 0;

        if (
          hasMlsAutoPublishGroups &&
          !objectAssignment.mlsGroup &&
          !objectAssignment.mlsReleaseDate
        ) {
          const group = head(mlsAutoPublishGroups);
          const mlsGroup = group?.name;
          const mlsReleaseDate = moment()
            .endOf("day")
            .add(group.defaultReleaseDaysInFuture || 0, "days")
            .toDate();

          objectAssignment = {
            ...objectAssignment,
            mlsGroup,
            mlsReleaseDate,
          };
        }

        await parseRequest.response(
          ObjectAssignments.save(
            {
              objectAssignment,
            },
            realEstateAgencyId
          )
        );
      } catch (error) {
        dispatch(
          AssignmentSingleActions.setPublishStatus({
            publishState: REQUEST.ERROR,
          })
        );
        throw error;
      }

      try {
        const objectAssignment = await parseRequest.response(
          ObjectAssignments.updateAvailability(
            {
              id,
              updateAvailabilityAction: UpdateAvailabilityAction.ToAvailable,
            },
            realEstateAgencyId
          ).then((response) => response.objectAssignment)
        );

        const componentState: SingleAssignmentState = {
          ...currentComponentState,
          objectAssignment,
        };

        dispatch(
          AssignmentSingleActions.updateAssignment({
            objectAssignment,
          })
        );
        dispatch(
          AssignmentSingleActions.setPublishStatus({
            publishState: REQUEST.SUCCESS,
          })
        );
        dispatch(
          EditableActions.updateComponentState({
            componentState,
            path,
            ignoreChanges: true,
          })
        );
        dispatch(push(path));
        dispatch(LayoutActions.toggleFullscreen(false));
      } catch (error) {
        dispatch(reloadObjectAssignment(id));
        dispatch(
          AssignmentSingleActions.setPublishStatus({
            publishState: REQUEST.ERROR,
          })
        );
        throw error;
      }
    } catch (error) {
      dispatch(
        AssignmentSingleActions.setPublishStatus({
          publishState: REQUEST.ERROR,
        })
      );
      throw error;
    }
  };
};

const reloadObjectAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    try {
      let objectAssignment = await parseRequest.response(
        ObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );
      objectAssignment =
        AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
      };

      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateAssignmentEditable = (
  componentState: SingleAssignmentState,
  path: string,
  ignoreChanges: boolean = false
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
        ignoreChanges,
      })
    );
  };
};

const createAssignment = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    const { offices, employee } = state.account;
    const { enabledRealEstateGroups } = state.company.settings;

    let linkedOffice;
    if (!!employee.linkedOffices && role === EmployeeRole.Standard) {
      linkedOffice = employee.linkedOffices.find(
        (office) => office.isMainOffice
      );
      if (!linkedOffice) {
        linkedOffice = first(employee.linkedOffices);
      }
    } else {
      linkedOffice = first(offices);
    }

    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    const typesInfo = [
      {
        realEstateGroup: RealEstateGroup.Residential,
        listingType: ListingType.House,
      },
      {
        realEstateGroup: RealEstateGroup.Commercial,
        listingType: ListingType.Other,
      },
      {
        realEstateGroup: RealEstateGroup.Agricultural,
        listingType: ListingType.ArableCompany,
      },
    ].filter((type) =>
      (enabledRealEstateGroups || []).includes(type.realEstateGroup)
    );
    const typeInfo = first(typesInfo) || {
      realEstateGroup: RealEstateGroup.Residential,
      listingType: ListingType.House,
    };

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({
          createLoaderVisible: false,
        })
      );
      return;
    }

    ObjectAssignments.defineNew(
      {
        employeeId,
        forRent: false,
        forSale: true,
        officeId: linkedOffice.id,
        ...typeInfo,
      },
      realEstateAgencyId
    )
      .then((response) => {
        let { objectAssignment } = response;

        // Set default value
        objectAssignment = {
          ...objectAssignment,
          isPermanentlyInhabited: true,
          acceptanceDetails: {
            ...(objectAssignment.acceptanceDetails || {}),
            acceptance: Acceptance.InConsultation,
          },
        };

        const route = RouteUtil.mapStaticRouteValues(
          ASSIGNMENTROUTES.DETAIL.URI,
          {
            id: objectAssignment.id,
          }
        );
        const redirect = RouteUtil.mapStaticRouteValues(
          ASSIGNMENTROUTES.EDIT_CLIENT.URI,
          { id: objectAssignment.id }
        );
        const componentState = {
          yisualLinks: null,
          cadastres: [],
          companyListings: [],
          publications: [],
          pastEvents: [],
          futureEvents: [],
          objectAssignment,
          isInitial: false,
          lastMarketingRoute: null,
          transactionMetaData: null,
          receivedDataFromMLS: false,
        };

        dispatch(AssignmentSingleActions.setAssignment(componentState));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
        dispatch(
          EditableActions.addState({
            icon: MAINROUTES.ASSIGNMENTS.ICON,
            componentState,
            path: route,
            title: "...",
            confirm: {
              title: { key: "saveAssignmentConfirmTitle" },
              body: { key: "saveAssignmentConfirmBody" },
            },
            entityType: RootEntityType.ObjectAssignment,
            entityId: objectAssignment.id,
          })
        );
        dispatch(push(redirect));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
      });
  };
};

const getCadastre = (
  countryIso2: string,
  houseNumber: number,
  houseNumberPostfix: string,
  postalCode: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const Cadastres = new CadastresClient(host);

    dispatch(
      AssignmentSingleActions.setCadastreState({
        cadastreState: REQUEST.PENDING,
      })
    );

    Cadastres.buyAndSave(
      {
        countryIso2,
        houseNumber,
        houseNumberPostfix,
        postalCode,
      },
      realEstateAgencyId
    )
      .then((result) => {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
          id: currentComponentState.objectAssignment.id,
        });
        const area = result.cadastres.reduce((state, cadastre) => {
          if (!!cadastre.cadastralDetails.surface)
            return state + cadastre.cadastralDetails.surface;
          return state;
        }, 0);

        const componentState: SingleAssignmentState = {
          ...currentComponentState,
          cadastres: result.cadastres,
          objectAssignment: {
            ...currentComponentState.objectAssignment,
            parcelSurface: {
              ...currentComponentState.objectAssignment.parcelSurface,
              area: area || "",
            },
          },
        };

        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
        dispatch(
          AssignmentSingleActions.setCadastreState({
            cadastreState: REQUEST.IDLE,
          })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssignmentSingleActions.setCadastreState({
            cadastreState: REQUEST.ERROR,
          })
        );
      });
  };
};

const getExtraCadastre = (
  countryIso2: string,
  municipalityId: string,
  municipalityName: string,
  parcelNumber: string,
  sectionNumber: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const Cadastres = new CadastresClient(host);

    dispatch(
      AssignmentSingleActions.setCadastreState({
        cadastreState: REQUEST.PENDING,
      })
    );

    Cadastres.buyAndSave(
      {
        countryIso2,
        houseNumber: null,
        municipalityId,
        municipalityName,
        sectionNumber,
        parcelNumber,
      },
      realEstateAgencyId
    )
      .then((result) => {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
          id: currentComponentState.objectAssignment.id,
        });
        const componentState: SingleAssignmentState = {
          ...currentComponentState,
          cadastres: [...currentComponentState.cadastres, ...result.cadastres],
        };

        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
        dispatch(
          AssignmentSingleActions.setCadastreState({
            cadastreState: REQUEST.IDLE,
          })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssignmentSingleActions.setCadastreState({
            cadastreState: REQUEST.ERROR,
          })
        );
      });
  };
};

const activatePublication = (assignmentId: string, mediaPartnerId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;

    const Publications = new PublicationsClient(host);

    dispatch(
      AssignmentSingleActions.setMediaPartnerState({
        mediaPartnerState: REQUEST.PENDING,
        mediaPartnerChangedId: mediaPartnerId,
      })
    );

    Publications.activate(
      {
        assignmentId,
        mediaPartnerId,
      },
      realEstateAgencyId
    )
      .then(() => Publications.search({ assignmentId }, realEstateAgencyId))
      .then((result) => {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignmentId });
        const componentState: SingleAssignmentState = {
          ...currentComponentState,
          publications: result.results,
        };

        dispatch(
          AssignmentSingleActions.setMediaPartnerState({
            mediaPartnerState: REQUEST.SUCCESS,
          })
        );
        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssignmentSingleActions.setMediaPartnerState({
            mediaPartnerState: REQUEST.ERROR,
          })
        );
      });
  };
};

const deActivatePublication = (
  assignmentId: string,
  mediaPartnerId: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;

    const Publications = new PublicationsClient(host);

    Publications.deactivate(
      {
        assignmentId,
        mediaPartnerId,
      },
      realEstateAgencyId
    )
      .then(() => Publications.search({ assignmentId }, realEstateAgencyId))
      .then((result) => {
        const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: assignmentId });
        const componentState: SingleAssignmentState = {
          ...currentComponentState,
          publications: result.results,
        };

        dispatch(
          EditableActions.updateComponentState({ path, componentState })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
      });
  };
};

const getTimelineEvents = (
  assignmentId: string,
  timelineActionType: TimelineActionType[] = [],
  init: boolean = true,
  take: number = 25,
  includeTimelineEventsOfChildItems: boolean = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setTimelineEventsStatus(REQUEST.PENDING));

    const state = getState();
    const { timelinePage } = state.assignment.single;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const TimelineEvents = new TimelineEventsClient(host);

    TimelineEvents.search(
      {
        relationId: "",
        assignmentId,
        minDateTime: null,
        maxDateTime: null,
        filterByActionTypes: timelineActionType,
        termFields: null,
        orderBy: TimelineOrderByField.Date,
        includeStatistics: true,
        filterByActive: ActiveFilter.ActiveOrInactive,
        term: null,
        skip: init ? 0 : timelinePage * take,
        take,
        order: SortOrder.Descending,
        includeTimelineEventsOfChildItems,
      },
      realEstateAgencyId
    )
      .then((response) => {
        dispatch(
          init
            ? AssignmentSingleActions.setTimelineEvents({ response })
            : AssignmentSingleActions.appendTimelineEvents({ response })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssignmentSingleActions.setTimelineEventsStatus(REQUEST.ERROR)
        );
      });
  };
};

const deleteAssignment = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    ObjectAssignments.delete(id, realEstateAgencyId)
      .then(() => {
        dispatch(push(MAINROUTES.ASSIGNMENTS.URI));
        dispatch(EditableThunks.remove(path));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
      });
  };
};

const createCadastre = (objectAssignmentId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const currentComponentState: SingleAssignmentState =
        state.editable.currentComponentState;
      const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: objectAssignmentId,
      });

      const ObjectAssignments = new ObjectAssignmentsClient(host);
      const Cadastres = new CadastresClient(host);
      const Publications = new PublicationsClient(host);
      const Assignments = new AssignmentsClient(host);

      dispatch(
        AssignmentSingleActions.setCadastreState({
          cadastreState: REQUEST.PENDING,
        })
      );

      let objectAssignment: ObjectAssignment;
      let reference: SingleAssignmentState = currentComponentState;
      let publications: PublicationSnapShot[] = [];

      objectAssignment = AssignmentUtil.prepareObjectAssignmentFloors(
        reference.objectAssignment
      );

      if (objectAssignment.isNew) {
        const existing = await parseRequest.response(
          Assignments.search(
            {
              orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              assignmentIds: [objectAssignment.id],
              forRent: true,
              forSale: true,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          objectAssignment = {
            ...objectAssignment,
            isNew: false,
          };
        }
      }

      objectAssignment = await ObjectAssignments.save(
        { objectAssignment },
        realEstateAgencyId
      ).then((response) => response.objectAssignment);

      if (currentComponentState?.objectAssignment?.isNew) {
        publications = await Publications.search(
          { assignmentId: reference.objectAssignment.id },
          realEstateAgencyId
        ).then((response) => response.results);
      }

      if (!objectAssignment?.address?.locality) {
        throw new Error(
          "ObjectAssignment is missing valid address to create new Cadastre"
        );
      }

      const cadastre = await Cadastres.defineNew(
        {
          objectAssignmentId,
        },
        realEstateAgencyId
      ).then((response) => response.cadastre);

      const cadastres = [...reference.cadastres, cadastre];

      reference = {
        ...reference,
        objectAssignment,
        publications,
        cadastres,
      };

      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment: reference.objectAssignment,
        })
      );
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        AssignmentSingleActions.setCadastreState({
          cadastreState: REQUEST.IDLE,
        })
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: reference,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ErrorActions.setError({ error }));
      dispatch(
        AssignmentSingleActions.setCadastreState({
          cadastreState: REQUEST.ERROR,
        })
      );
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
    }
  };
};

const updateAvailability = (
  updateAvailabilityAction: UpdateAvailabilityAction,
  id: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING)
      );

      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const { currentComponentState } = state.editable;
      const ObjectAssignments = new ObjectAssignmentsClient(host);

      const result = await parseRequest.response(
        ObjectAssignments.updateAvailability(
          {
            updateAvailabilityAction,
            id,
          },
          realEstateAgencyId
        )
      );

      if (!result) return;

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: result.objectAssignment.id,
      });

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment: result.objectAssignment,
      };

      dispatch(
        AssignmentSingleActions.updateAssignment({
          objectAssignment: result.objectAssignment,
        })
      );
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(ErrorActions.setError({ error }));
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
    }
  };
};

const removeCompanyListing = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const CompanyListings = new CompanyListingsClient(host);

    CompanyListings.delete(id, realEstateAgencyId)
      .then(() => dispatch(AssignmentSingleActions.removeCompanyListing(id)))
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
      });
  };
};

const setPublishFormErrors = (
  publishErrors: AssignmentPublishError[],
  id: string
) => {
  return (dispatch: Dispatch<any>) => {
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    const stack: FormError[] = publishErrors.map((error) => {
      let path, fieldName;
      switch (error) {
        case AssignmentPublishError.ConstructionYearMismatch:
        case AssignmentPublishError.NoBuildPeriodOrYear: {
          path = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
          fieldName = "constructionYear";
          break;
        }
        case AssignmentPublishError.NoAcceptance: {
          path = route(ASSIGNMENTROUTES.EDIT_FINANCIAL.URI, { id });
          fieldName = "acceptance";
          break;
        }
        case AssignmentPublishError.NoContents: {
          path = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
          fieldName = "contents";
          break;
        }
        case AssignmentPublishError.NoLivingSurface: {
          path = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
          fieldName = "usableArea";
          break;
        }
        case AssignmentPublishError.NoParcelSurface: {
          path = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
          fieldName = "parcelSurface";
          break;
        }
        case AssignmentPublishError.NoRentCondition: {
          path = route(ASSIGNMENTROUTES.EDIT_FINANCIAL.URI, { id });
          fieldName = "rentCondition";
          break;
        }
        case AssignmentPublishError.NoRoomsCount: {
          path = route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id });
          fieldName = "Room";
          break;
        }
        case AssignmentPublishError.NoSaleCondition: {
          path = route(ASSIGNMENTROUTES.EDIT_FINANCIAL.URI, { id });
          fieldName = "saleCondition";
          break;
        }
        default:
          return;
      }
      return {
        fieldName,
        path,
        error,
      };
    });

    const formError: FormErrors = {
      path,
      stack,
    };

    dispatch(FormErrorsActions.setErrors({ formError }));
    dispatch(push(first(formError.stack).path));
  };
};

const navigateToCadastreWithCallback = (
  calleeType: EditableCalleeType,
  path: string,
  returnPath: string,
  id?: string
) => {
  return (dispatch: Dispatch<any>) => {
    const uri = route(ASSIGNMENTROUTES.EDIT_ADDRESS_PARAMS.URI, {
      id,
      caller: btoa(returnPath),
    });

    dispatch(EditableActions.setCallee({ calleeType, calleeId: null, path }));
    dispatch(push(uri));
  };
};

const addReceivedBrochures = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    const isCurrentComponentState =
      has(currentComponentState, "objectAssignment.id") &&
      currentComponentState.objectAssignment.id === id;
    const editable = state.editable.states.find((s) => s.path === path);

    if (!!editable || isCurrentComponentState) {
      const ObjectAssignments = new ObjectAssignmentsClient(host);

      ObjectAssignments.read(id, realEstateAgencyId)
        .then((result) => {
          const { brochures, dateTimeModified, linkedModifiedBy } =
            result.objectAssignment;

          if (isCurrentComponentState) {
            const componentState = {
              ...currentComponentState,
              objectAssignment: {
                ...currentComponentState.objectAssignment,
                brochures,
                dateTimeModified,
                linkedModifiedBy,
              },
            };

            dispatch(
              EditableActions.updateComponentState({ componentState, path })
            );
          } else {
            const componentState = {
              ...editable.componentState,
              objectAssignment: {
                ...editable.componentState.objectAssignment,
                brochures,
                dateTimeModified,
                linkedModifiedBy,
              },
            };

            dispatch(
              EditableActions.updateSingleComponentState({
                componentState,
                path,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(ErrorActions.setError({ error }));
        });
    }
  };
};

const getLastChangedAssignments = (take: number = 5) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AssigmentWidgetsActions.setLastChangedAssignmentsStatus({
        status: REQUEST.PENDING,
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Assignments = new AssignmentsClient(host);

    Assignments.search(
      {
        forRent: true,
        forSale: true,
        includeStatistics: false,
        orderBy: AssignmentOrderByField.ModificationDate,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Descending,
        filterByAssignmentTypes: [
          AssignmentType.Object,
          AssignmentType.AcquisitionObject,
        ],
        skip: 0,
        take,
      },
      realEstateAgencyId
    )
      .then((result) => {
        dispatch(
          AssigmentWidgetsActions.setLastChangedAssignments({ ...result, take })
        );
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          AssigmentWidgetsActions.setLastChangedAssignmentsStatus({
            status: REQUEST.ERROR,
          })
        );
      });
  };
};

const searchSublocalities = (localityId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    let { currentComponentState: componentState } = state.editable;
    const { culture } = state.main;

    const Geo = new GeoClient(host);

    try {
      const result = await parseRequest.response(
        Geo.sublocalitySearch({ localityId, culture }, realEstateAgencyId)
      );

      componentState = {
        ...componentState,
        sublocalities: result.results,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path: route(ASSIGNMENTROUTES.DETAIL.URI, {
            id: componentState.objectAssignment.id,
          }),
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const navigateToMarketingRoute = (lastMarketingRoute: string, id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    let { currentComponentState: componentState } = state.editable;

    componentState = {
      ...componentState,
      lastMarketingRoute,
    };

    dispatch(
      AssignmentSingleActions.setLastMarketingRoute({ lastMarketingRoute })
    );
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path: route(ASSIGNMENTROUTES.DETAIL.URI, { id }),
        ignoreChanges: true,
      })
    );
  };
};

const archive = (objectAssignment: ObjectAssignment, keepKeyNumber = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const currentComponentState: SingleAssignmentState =
      state.editable.currentComponentState;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);

    try {
      let shouldUpdate = false;

      if (objectAssignment.assignmentPhase === AssignmentPhase.Initiated) {
        shouldUpdate = true;

        objectAssignment =
          AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

        objectAssignment = {
          ...objectAssignment,
          assignmentPhase: AssignmentPhase.Completed,
        };
      }

      if (!keepKeyNumber && !!objectAssignment.keyNr) {
        shouldUpdate = true;

        objectAssignment = {
          ...objectAssignment,
          keyNr: null,
        };
      }

      if (shouldUpdate) {
        if (objectAssignment.isNew) {
          const existing = await parseRequest.response(
            Assignments.search(
              {
                orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
                filterByActive: ActiveFilter.ActiveOrInactive,
                order: SortOrder.Ascending,
                skip: 0,
                take: 1,
                assignmentIds: [objectAssignment.id],
                forRent: true,
                forSale: true,
                includeStatistics: false,
              },
              realEstateAgencyId
            )
          );

          if (!!existing && !!existing.results && !!existing.results.length) {
            objectAssignment = {
              ...objectAssignment,
              isNew: false,
            };
          }
        }

        await parseRequest.response(
          ObjectAssignments.save(
            {
              objectAssignment,
            },
            realEstateAgencyId
          )
        );
      }

      await parseRequest.response(
        ObjectAssignments.archive(
          {
            id: objectAssignment.id,
          },
          realEstateAgencyId
        )
      );

      objectAssignment = await parseRequest.response(
        ObjectAssignments.read(objectAssignment.id, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );
      objectAssignment =
        AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: objectAssignment.id,
      });

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
      };

      dispatch(AssignmentSingleActions.updateAssignment({ objectAssignment }));
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
      dispatch(ErrorActions.setError({ error }));
      throw error;
    }
  };
};

const duplicate = (
  objectAssignment: ObjectAssignment,
  duplicateAsForSale: boolean,
  keyNr: number = null,
  keyNote: string = ""
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { employee, offices } = state.account;

    let linkedOffice;
    if (!!employee.linkedOffices && role === EmployeeRole.Standard) {
      linkedOffice = employee.linkedOffices.find(
        (office) => office.isMainOffice
      );
      if (!linkedOffice) {
        linkedOffice = first(employee.linkedOffices);
      }
    } else {
      linkedOffice = first(offices);
    }

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Cadastres = new CadastresClient(host);

    try {
      objectAssignment =
        AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

      let linkedCadastres: LinkedCadastre[] = [];
      let cadastres: Cadastre[];
      if (
        !!objectAssignment.linkedCadastres &&
        !!objectAssignment.linkedCadastres.length
      ) {
        const clonedCadastrePromises = objectAssignment.linkedCadastres.map(
          (cadastre) =>
            Cadastres.clone({ id: cadastre.id }, realEstateAgencyId).then(
              (response) => response.cadastre
            )
        );

        const clonedCadastres = await Promise.all(clonedCadastrePromises);
        const cadastrePromises = clonedCadastres.map((cadastre) => {
          return new Promise<Cadastre>(async (resolve, reject) => {
            try {
              if (cadastre.isNew) {
                const existing = await parseRequest.response(
                  Cadastres.search(
                    {
                      orderBy: CadastreOrderByField.CreationDateTime,
                      filterByActive: ActiveFilter.ActiveOrInactive,
                      order: SortOrder.Ascending,
                      skip: 0,
                      take: 1,
                      cadastreId: cadastre.id,
                    },
                    realEstateAgencyId
                  )
                );

                if (
                  !!existing &&
                  !!existing.results &&
                  !!existing.results.length
                ) {
                  cadastre = {
                    ...cadastre,
                    isNew: false,
                  };
                }
              }

              const response = Cadastres.save(
                { cadastre },
                realEstateAgencyId
              ).then((response) => response.cadastre);

              resolve(response);
            } catch (error) {
              reject(error);
            }
          });
        });

        cadastres = await Promise.all(cadastrePromises);
        linkedCadastres = cadastres.map(({ id }) => ({ id }));
      }

      objectAssignment = await parseRequest.response(
        ObjectAssignments.clone(
          {
            id: objectAssignment.id,
            duplicateAsForSale,
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      const id = BackOfficeUtil.getEmployeeId(state.account);
      if (!id) {
        return;
      }

      objectAssignment = {
        ...objectAssignment,
        linkedEmployee: {
          id,
        },
        linkedOffice: {
          id: linkedOffice.id,
        },
        linkedCadastres,
        keyNr,
        keyNote,
      };

      const route = RouteUtil.mapStaticRouteValues(
        ASSIGNMENTROUTES.DETAIL.URI,
        {
          id: objectAssignment.id,
        }
      );
      const redirect = RouteUtil.mapStaticRouteValues(
        ASSIGNMENTROUTES.EDIT_CLIENT.URI,
        { id: objectAssignment.id }
      );
      const componentState = {
        yisualLinks: null,
        cadastres,
        companyListings: [],
        publications: [],
        pastEvents: [],
        futureEvents: [],
        objectAssignment,
        isInitial: false,
        lastMarketingRoute: null,
      };

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.ASSIGNMENTS.ICON,
          componentState,
          path: route,
          title: "...",
          confirm: {
            title: { key: "saveAssignmentConfirmTitle" },
            body: { key: "saveAssignmentConfirmBody" },
          },
          hasChanges: true,
          entityType: RootEntityType.ObjectAssignment,
          entityId: objectAssignment.id,
        })
      );
      dispatch(push(redirect));
    } catch (error) {
      dispatch(ErrorActions.setError({ error }));
      throw error;
    }
  };
};

const duplicateAssignment = (
  id: string,
  shouldArchive: boolean,
  duplicateAsForSale: boolean,
  shouldCopyKeyNumber: boolean,
  shouldClearKeyNumber: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    try {
      let keyNumber: number | null = null;

      let objectAssignment = await parseRequest.response(
        ObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );

      keyNumber =
        shouldCopyKeyNumber && !!objectAssignment
          ? objectAssignment.keyNr || null
          : null;
      const keyNote =
        shouldCopyKeyNumber && !!objectAssignment
          ? objectAssignment.keyNote || null
          : null;
      if (shouldArchive) {
        await dispatch(archive(objectAssignment, !shouldClearKeyNumber));
      }

      await dispatch(
        duplicate(objectAssignment, duplicateAsForSale, keyNumber, keyNote)
      );

      if (!shouldArchive && shouldClearKeyNumber) {
        objectAssignment = {
          ...objectAssignment,
          keyNr: undefined,
          keyNote: undefined,
        };
        await parseRequest.response(
          ObjectAssignments.save({ objectAssignment }, realEstateAgencyId)
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const renewRentOffer = (
  assignment: ObjectAssignment,
  availableFrom: Date,
  availableUntil: Date,
  rentPrice: number,
  rentCondition: RentCondition,
  availabilityIsTemporary: boolean,
  copyKeyNr = false,
  keepKeyNumber = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { employee } = state.account;
    let { currentComponentState: sourceComponentState } = state.editable;

    const linkedOffice = assignment.linkedOffice;
    const linkedEmployee =
      role === EmployeeRole.Standard ? employee : assignment.linkedEmployee;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);
    const savedKeyNr: number | null = !!assignment.keyNr
      ? assignment.keyNr
      : null;

    try {
      /**
       * Update source assignment if needed
       */
      let sourceObjectAssignment = assignment;
      const sourcePath = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: assignment.id,
      });

      if (assignment.assignmentPhase !== AssignmentPhase.Completed) {
        if (assignment.isNew) {
          const existing = await parseRequest.response(
            Assignments.search(
              {
                orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
                filterByActive: ActiveFilter.ActiveOrInactive,
                order: SortOrder.Ascending,
                skip: 0,
                take: 1,
                assignmentIds: [assignment.id],
                forRent: true,
                forSale: true,
                includeStatistics: false,
              },
              realEstateAgencyId
            )
          );

          if (!!existing && !!existing.results && !!existing.results.length) {
            assignment = {
              ...assignment,
              isNew: false,
            };
          }
        }

        sourceObjectAssignment = await parseRequest.response(
          ObjectAssignments.save(
            {
              objectAssignment: {
                ...assignment,
                assignmentPhase: AssignmentPhase.Completed,
              },
            },
            realEstateAgencyId
          ).then((response) => response.objectAssignment)
        );
      }

      if (assignment.isActive) {
        await dispatch(archive(assignment, keepKeyNumber));
      }

      sourceComponentState = {
        ...sourceComponentState,
        objectAssignment: {
          ...sourceObjectAssignment,
          isActive: false,
        },
      };

      dispatch(
        EditableActions.updateComponentState({
          path: sourcePath,
          componentState: sourceComponentState,
          ignoreChanges: true,
        })
      );

      /**
       * Clone and save new assignment
       */
      let objectAssignment = await parseRequest.response(
        ObjectAssignments.clone(
          {
            id: assignment.id,
            duplicateAsForSale: false,
            includeLinkedContacts: true,
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      let rentOffer: RentOffer = {
        ...objectAssignment.rentOffer,
        rentPrice,
        rentCondition,
        availabilityIsTemporary,
      };

      if (availabilityIsTemporary) {
        rentOffer = {
          ...rentOffer,
          availableFrom,
          availableUntil,
        };
      } else {
        rentOffer = {
          ...rentOffer,
          availableFrom: undefined,
          availableUntil: undefined,
        };
      }

      if (!!copyKeyNr) {
        objectAssignment = {
          ...objectAssignment,
          keyNr: savedKeyNr,
        };
      }

      objectAssignment = await parseRequest.response(
        ObjectAssignments.save(
          {
            objectAssignment: {
              ...objectAssignment,
              rentOffer,
              linkedEmployee,
              linkedOffice,
            },
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      const targetAssignmentId = objectAssignment.id;
      await parseRequest.response(
        ObjectAssignments.makeVersionOf(
          {
            sourceAssignmentId: assignment.id,
            targetAssignmentId,
          },
          realEstateAgencyId
        )
      );

      objectAssignment = await parseRequest.response(
        ObjectAssignments.read(targetAssignmentId, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );
      objectAssignment =
        AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: objectAssignment.id,
      });
      const componentState = {
        objectAssignment,
        cadastres: [],
        companyListings: [],
        publications: [],
        pastEvents: [],
        futureEvents: [],
        isInitial: true,
        lastMarketingRoute: null,
        transactionMetaData: null,
        receivedDataFromMLS: false,
      };

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.ASSIGNMENTS.ICON,
          componentState,
          path,
          title: "...",
          confirm: {
            title: { key: "saveAssignmentConfirmTitle" },
            body: { key: "saveAssignmentConfirmBody" },
          },
          entityType: RootEntityType.ObjectAssignment,
          entityId: objectAssignment.id,
        })
      );

      dispatch(push(path));
      dispatch(AssignmentSingleActions.setAssignment(componentState));
      dispatch(getSecondaryAssignmentData(objectAssignment));
    } catch (error) {
      dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getSecondaryAssignmentData = (objectAssignment: ObjectAssignment) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    });

    const Cadastres = new CadastresClient(host);
    const CompanyListings = new CompanyListingsClient(host);
    const Publications = new PublicationsClient(host);
    const TimelineEvents = new TimelineEventsClient(host);

    try {
      const linkedCadastres = get(objectAssignment, "linkedCadastres") || [];
      const linkedCompanyListings =
        get(objectAssignment, "linkedCompanyListings") || [];

      const linkedCadastresPromises = linkedCadastres.map((cadastre) =>
        Cadastres.read(cadastre.id, realEstateAgencyId).then(
          (response) => response.cadastre
        )
      );
      const linkedCompanyListingsPromises = linkedCompanyListings.map(
        (companyListing) =>
          CompanyListings.read(companyListing.id, realEstateAgencyId).then(
            (response) => response.companyListing
          )
      );

      const [cadastres, companyListings] = await parseRequest.response(
        Promise.all([
          Promise.all(linkedCadastresPromises),
          Promise.all(linkedCompanyListingsPromises),
        ])
      );

      const publications = await parseRequest.response(
        Publications.search(
          { assignmentId: objectAssignment.id },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      const eventsRequestConfig = {
        orderBy: TimelineOrderByField.Date,
        includeStatistics: false,
        filterByActive: ActiveFilter.ActiveOnly,
        skip: 0,
        take: 5,
        order: SortOrder.Descending,
        assignmentId: objectAssignment.id,
      };

      const pastEvents = await parseRequest.response(
        TimelineEvents.search(
          { ...eventsRequestConfig, maxDateTime: new Date() },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      const futureEvents = await parseRequest.response(
        TimelineEvents.search(
          { ...eventsRequestConfig, minDateTime: new Date() },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      const componentState = {
        objectAssignment,
        cadastres,
        companyListings,
        publications,
        pastEvents,
        futureEvents,
        lastMarketingRoute: null,
        isInitial: false,
        transactionMetaData: null,
        receivedDataFromMLS: false,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.updateSingleAssignment(componentState));
    } catch (error) {
      throw error;
    }
  };
};

const prolongRentOffer = (
  id: string,
  rentedUntil: Date,
  realizedPerMonth: number,
  rentCondition: RentCondition
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { currentComponentState: componentState } = state.editable;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const Assignments = new AssignmentsClient(host);
    let objectAssignment = componentState.objectAssignment;

    try {
      if (objectAssignment.isNew) {
        const existing = await parseRequest.response(
          Assignments.search(
            {
              orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              assignmentIds: [objectAssignment.id],
              forRent: true,
              forSale: true,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          objectAssignment = {
            ...objectAssignment,
            isNew: false,
          };
        }
      }

      objectAssignment = await parseRequest.response(
        ObjectAssignments.save(
          {
            objectAssignment: {
              ...componentState.objectAssignment,
              rentOffer: {
                ...componentState.objectAssignment.rentOffer,
                rentCondition,
                rentedUntil,
                realizedPerMonth,
              },
            },
          },
          realEstateAgencyId
        ).then((response) => response.objectAssignment)
      );

      componentState = {
        ...componentState,
        objectAssignment,
      };

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.setAssignment(componentState));
    } catch (error) {
      dispatch(AssignmentSingleActions.setAssignmentStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getLinkedAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new ObjectAssignmentsClient(host);

    try {
      return await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.objectAssignment)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getAssignmentsWithKey = (
  filterByActive: ActiveFilter = ActiveFilter.ActiveOnly
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(KeyBoardActions.setStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const take = 100;

    const Assignments = new AssignmentsClient(host);

    try {
      const request = {
        forRent: true,
        forSale: true,
        includeStatistics: false,
        orderBy: AssignmentOrderByField.KeyNumber,
        take,
        skip: 0,
        filterByActive,
        order: SortOrder.Ascending,
        hasKey: true,
      };
      const response = await parseRequest.response(
        Assignments.search(request, realEstateAgencyId)
      );

      let assignments = response.results;

      if (response.totalResults > take) {
        const diff = response.totalResults - take;
        const count = Math.ceil(diff / take);
        const promises: Promise<AssignmentSnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            Assignments.search(
              {
                ...request,
                skip: i * take,
              },
              realEstateAgencyId
            ).then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const snapshots = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <AssignmentSnapShot[]>[]);

        assignments = [...assignments, ...snapshots];
      }
      dispatch(AccountActions.setShowAllKeyBoardAssignments(filterByActive));
      dispatch(KeyBoardActions.addKeys(assignments));
    } catch (error) {
      dispatch(KeyBoardActions.setStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getAssignmentTemplates = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { reportsHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;

    const Reports = new ReportsClient(reportsHost);

    Reports.searchTemplates(
      { culture, category: ReportCategory.WindowPresentations },
      realEstateAgencyId
    )
      .then((response) => response.result)
      .then((printTemplates) =>
        dispatch(
          AssignmentTemplatesActions.setPrintTemplates({ printTemplates })
        )
      );

    Reports.getDocumentFormats(realEstateAgencyId).then((printExportOptions) =>
      dispatch(
        AssignmentTemplatesActions.setPrintExportOptions({ printExportOptions })
      )
    );
  };
};

const updateLocalState = (id: string, navigateToDashboard = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { single } = state.assignment;
    const client = new ObjectAssignmentsClient(host);

    try {
      const objectAssignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.objectAssignment)
      );

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: objectAssignment.id,
      });
      const componentState = {
        ...single,
        objectAssignment,
      };

      if (navigateToDashboard) {
        dispatch(push(path));
      }

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
          resetExternalChanges: true,
        })
      );
      dispatch(AssignmentSingleActions.setAssignment(componentState));
    } catch (error) {
      throw error;
    }
  };
};

const backToDashboard = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { single } = state.assignment;
    const currentComponentState: SingleAssignmentState =
      state.editable.currentComponentState;

    if (
      get(single.objectAssignment, "id") !==
      get(currentComponentState.objectAssignment, "id")
    )
      return;

    try {
      const { id } = single.objectAssignment;
      dispatch(updateLocalState(id, true));
    } catch (error) {
      throw error;
    }
  };
};

const unArchive = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const currentComponentState: SingleAssignmentState =
      state.editable.currentComponentState;

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    try {
      await parseRequest.response(
        ObjectAssignments.unarchive(
          {
            id,
          },
          realEstateAgencyId
        )
      );

      let objectAssignment = await parseRequest.response(
        ObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.objectAssignment
        )
      );
      objectAssignment =
        AssignmentUtil.prepareObjectAssignmentFloors(objectAssignment);

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

      const componentState: SingleAssignmentState = {
        ...currentComponentState,
        objectAssignment,
      };

      dispatch(AssignmentSingleActions.updateAssignment({ objectAssignment }));
      dispatch(
        AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.SUCCESS)
      );
      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      dispatch(AssignmentSingleActions.setSaveAssignmentStatus(REQUEST.ERROR));
      dispatch(ErrorActions.setError({ error }));
      throw error;
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: SingleAssignmentState = editable.componentState;
    if (!componentState || !componentState.objectAssignment) return;
    const { dateTimeModified } = componentState.objectAssignment;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new ObjectAssignmentsClient(host);

    try {
      const refObjectAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.objectAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );
      const { componentState, hasChanges, active, path } = editable;
      const { objectAssignment } = componentState as SingleAssignmentState;

      const refBrochures = refObjectAssignment.brochures || [];
      const brochures = objectAssignment.brochures || [];
      const diff = differenceBy(
        refBrochures,
        brochures,
        (brochure) => brochure.id
      );
      const newYisualBrochures = diff.filter((brochure) => brochure.isEditable);

      if (!!newYisualBrochures.length) {
        const newComponentState: SingleAssignmentState = {
          ...componentState,
          objectAssignment: {
            ...componentState.objectAssignment,
            brochures: [...brochures, ...newYisualBrochures],
          },
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            hasChanges: true,
          })
        );
        return;
      }

      if (!hasChanges) {
        const newComponentState: SingleAssignmentState = {
          ...componentState,
          objectAssignment: refObjectAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(AssignmentSingleActions.setAssignment(newComponentState));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refObjectAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refObjectAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadAssignment = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const objectAssignment =
      externalChangesData.updatedEntity as ObjectAssignment;
    const componentState: SingleAssignmentState = {
      ...editable.componentState,
      objectAssignment,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(AssignmentSingleActions.setAssignment(componentState));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateObjectAssignmentListItem = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { assignments } = state.assignment.overview;
    const { features } = state.appSettings;

    let ref: AssignmentSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.assignment.list.assignments || []).find(
        (assignment) => !!assignment && assignment.id === id
      );
    } else {
      ref = (assignments.results || []).find(
        (assignment) => assignment.id === id
      );
    }

    if (!ref) return;

    try {
      const client = new ObjectAssignmentsClient(host);
      const assignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response?.objectAssignment)
      );
      if (!assignment) return;

      const snapshot = mapObjectAssignmentToAssignmentSnapshot(assignment);
      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(AssignmentListActions.updateItem(snapshot));
      } else {
        dispatch(
          AssignmentOverviewActions.updateSingleAssignment({ snapshot })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const updateProjectAssignmentListItem = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { assignments } = state.assignment.overview;
    const { features } = state.appSettings;

    let ref: AssignmentSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.assignment.list.assignments || []).find(
        (assignment) => !!assignment && assignment.id === id
      );
    } else {
      ref = (assignments.results || []).find(
        (assignment) => assignment.id === id
      );
    }

    if (!ref) return;

    // Change to get
    try {
      const client = new ProjectAssignmentsClient(host);
      const assignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response?.projectAssignment)
      );
      if (!assignment) return;

      const snapshot = mapProjectAssignmentToAssignmentSnapshot(assignment);
      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(AssignmentListActions.updateItem(snapshot));
      } else {
        dispatch(
          AssignmentOverviewActions.updateSingleAssignment({ snapshot })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { order, filters } = state.assignment.list;
      const {
        saleOrRent,
        realEstateGroups,
        assignmentTypes,
        keyNumbers,
        listingTypes,
        assignmentPhases,
        availabilityStatuses,
        offices,
        employees,
        localities,
        priceRange,
        numberOfBedrooms,
        furnishings,
        archived,
        availableFrom,
        availableUntill,
      } = filters;
      const client = new AssignmentsClient(host);

      const filterByAssignmentTypes = !assignmentTypes.value.length
        ? [AssignmentType.Object, AssignmentType.Project]
        : assignmentTypes.value;

      const filterByActive = !archived.value.length
        ? ActiveFilter.ActiveOnly
        : ActiveFilter.InactiveOnly;

      const response = await parseRequest.response(
        client.search(
          {
            includeStatistics: startIndex === 0,
            orderBy: order.sortColumn,
            order: order.sortOrder,
            skip: startIndex,
            take: stopIndex - startIndex + 1,
            forRent: saleOrRent.value.includes("forRent"),
            forSale: saleOrRent.value.includes("forSale"),
            filterByActive,
            filterByRealEstateGroups: realEstateGroups.value,
            filterByAssignmentTypes,
            filterByKeyNumbers: keyNumbers.value,
            listingTypes: listingTypes.value,
            assignmentPhases: assignmentPhases.value,
            availabiltyStatuses: availabilityStatuses.value,
            officeIds: offices.value,
            employeeIds: employees.value,
            localities: localities.value,
            filterByMinPrice: priceRange.value.min,
            filterByMaxPrice: priceRange.value.max,
            filterByNumberOfBedroomsMin: numberOfBedrooms.value.min,
            filterByNumberOfBedroomsMax: numberOfBedrooms.value.max,
            filterByFurnishings: furnishings.value,
            availableFromMin: availableFrom.value.min,
            availableFromMax: availableFrom.value.max,
            availableUntilMin: availableUntill.value.min,
            availableUntilMax: availableUntill.value.max,
          },
          realEstateAgencyId
        )
      );

      if (!response) {
        return;
      }

      const { results, totalResults, statistics } = response;
      dispatch(
        AssignmentListActions.updateList(
          startIndex,
          results,
          totalResults,
          statistics
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const exportListToExcel = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;
    const { filters, order } = state.assignment.list;
    const {
      saleOrRent,
      realEstateGroups,
      assignmentTypes,
      keyNumbers,
      listingTypes,
      assignmentPhases,
      availabilityStatuses,
      offices,
      employees,
      localities,
      priceRange,
      numberOfBedrooms,
      furnishings,
      archived,
      availableFrom,
      availableUntill,
    } = filters;
    const { sortOrder, sortColumn } = order;

    const filterByAssignmentTypes = !assignmentTypes.value.length
      ? [AssignmentType.Object, AssignmentType.Project]
      : assignmentTypes.value;

    const filterByActive = !archived.value.length
      ? ActiveFilter.ActiveOnly
      : ActiveFilter.InactiveOnly;

    const baseRequest: AssignmentsSearchRequest = {
      order: sortOrder,
      orderBy: sortColumn,
      filterByActive: ActiveFilter.ActiveOrInactive,
      forRent: saleOrRent.value.includes("forRent"),
      forSale: saleOrRent.value.includes("forSale"),
      includeStatistics: false,
      skip: 0,
      take: 999,
    };

    const request = {
      assignmentPhases: assignmentPhases.value,
      listingTypes: listingTypes.value,
      availabiltyStatuses: availabilityStatuses.value,
      officeIds: offices.value,
      employeeIds: employees.value,
      filterByRealEstateGroups: realEstateGroups.value,
      filterByActive,
      filterByAssignmentTypes,
      localities: localities.value,
      filterByKeyNumbers: keyNumbers.value,
      filterByFurnishings: furnishings.value,
      filterByMinPrice: priceRange.value.min,
      filterByMaxPrice: priceRange.value.max,
      filterByNumberOfBedroomsMin: numberOfBedrooms.value.min,
      filterByNumberOfBedroomsMax: numberOfBedrooms.value.max,
      availableFromMin: availableFrom.value.min,
      availableFromMax: availableFrom.value.max,
      availableUntilMin: availableUntill.value.min,
      availableUntilMax: availableUntill.value.max,
      culture,
    };

    const updatedRequest = { ...baseRequest, ...request };

    const { token } = state.access;

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
      responseType: "arraybuffer",
    };

    const response = await Axios.post(
      `${host}/${apiVersion}/${realEstateAgencyId}/Assignments/Export`,
      updatedRequest,
      config
    ).then((response) => {
      return new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    });

    saveAs(
      response,
      `opdrachten-export-${moment().format("DDMMYYYYHHmm")}.xlsx`
    );

    // const url = `${host}/${apiVersion}/${realEstateAgencyId}/Assignments/SearchAndExport?${RouteUtil.mapObjectToGetParams(
    //   props
    // )}`;

    // window.open(url, "_blank");
  };
};

const mapValuesToObjectAssignment = (objectAssignment: ObjectAssignment) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      let { currentComponentState } = state.editable;

      if (!currentComponentState.objectAssignment) return;
      const {
        id,
        linkedEmployee,
        linkedOffice,
        realEstateAgencyId,
        isNew,
        dateTimeCreated,
        dateTimeModified,
      } = currentComponentState.objectAssignment as ObjectAssignment;

      const contractReason = !objectAssignment.forSale
        ? ContractReason.ForRent
        : ContractReason.ForSale;

      currentComponentState = {
        ...currentComponentState,
        objectAssignment: {
          ...currentComponentState.objectAssignment,
          ...objectAssignment,
          id,
          linkedEmployee,
          linkedOffice,
          realEstateAgencyId,
          isNew,
          dateTimeCreated,
          dateTimeModified,
          contractReason,
        },
        receivedDataFromMLS: true,
      };
      if (objectAssignment.forRent) {
        currentComponentState.objectAssignment.forSale = false;
      }
      if (objectAssignment.forSale) {
        currentComponentState.objectAssignment.forRent = false;
      }

      const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
      const toast: ToastProps = {
        value: "assignment.toast.addedSuggestionData",
        icon: "clipboard-check",
      };

      dispatch(SnackbarActions.addToast(toast));

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: currentComponentState,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const exportAssignmentSearchAssignments = (
  objectAssignmentId: string,
  take: number,
  isConceptAssignment: boolean,
  matchMailPeriods?: MatchMailPeriod[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host, apiVersion } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { token } = state.access;

      const request: SearchSearchAssignmentsRequest = {
        objectAssignmentId,
        isConceptAssignment,
        filterByActive: ActiveFilter.ActiveOnly,
        order: SortOrder.Ascending,
        skip: 0,
        take,
        matchMailPeriods,
      };

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        httpAgent: new HttpAgent({ keepAlive: true }),
        httpsAgent: new HttpsAgent({ keepAlive: true }),
        responseType: "arraybuffer",
      };

      const response = await Axios.post(
        `${host}/${apiVersion}/${realEstateAgencyId}/Matches/SearchAndExportSearchAssignments`,
        request,
        config
      ).then((response) => {
        return new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      });

      saveAs(
        response,
        `zoekopdrachten-export-${moment().format("DDMMYYYYHHmm")}.xlsx`
      );
    } catch (error) {
      throw error;
    }
  };
};

export const AssignmentThunks = {
  activatePublication,
  clearAvailabilityStatus,
  createAssignment,
  exportAssignments,
  getAssignments,
  getAssignmentsForRelationDetail,
  getCadastre,
  getTimelineEvents,
  deleteAssignment,
  deActivatePublication,
  createCadastre,
  publishObjectAssignment,
  saveAssignment,
  searchAddress,
  sellObjectAssignment,
  rentObjectAssignment,
  withdrawObjectAssignment,
  updateAssignmentEditable,
  getExtraCadastre,
  updateAvailability,
  getAssignmentsForOfficeDetail,
  removeCompanyListing,
  getAssignment,
  setPublishFormErrors,
  navigateToCadastreWithCallback,
  addReceivedBrochures,
  getLastChangedAssignments,
  searchSublocalities,
  getAssignmentsByRelation,
  saveSingleObjectAssignment,
  navigateToMarketingRoute,
  archive,
  renewRentOffer,
  prolongRentOffer,
  getLinkedAssignment,
  getAssignmentsWithKey,
  transitionObjectAssignment,
  getAssignmentTemplates,
  duplicateAssignment,
  backToDashboard,
  unArchive,
  checkExternalChanges,
  reloadAssignment,
  updateObjectAssignmentListItem,
  linkRelation,
  unlinkRelation,
  getListItems,
  exportListToExcel,
  mapValuesToObjectAssignment,
  updateProjectAssignmentListItem,
  exportAssignmentSearchAssignments,
};
