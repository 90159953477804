import {
  RealEstateGroup,
  SearchAssignmentSnapShot,
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface RelationWidgetsSearchAssignmentsItemComponentProps {
  searchAssignment: SearchAssignmentSnapShot;
  onNavigateToSearchAssignment: (url: string) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class RelationWidgetsSearchAssignmentsItemComponent extends React.Component<
  RelationWidgetsSearchAssignmentsItemComponentProps
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="item" onClick={this.onClickHandler}>
        <div styleName="item__icon">
          <i className="fal fa-fw fa-search" />
        </div>
        <div styleName="item__meta">{this.renderSearchAssignmentText()}</div>
      </div>
    );
  }

  private onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    const { id } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onNavigateToSearchAssignment(url);
  }

  private renderSearchAssignmentText(): React.ReactElement<HTMLSpanElement> {
    const { realEstateGroup, offerType } = this.props.searchAssignment;

    return (
      <span>
        {this.renderRealEstateGroup(realEstateGroup)}
        {!!offerType && (
          <strong>
            &nbsp;(
            <ResourceText
              masterKey="offerTypeOptions"
              resourceKey={offerType.toString()}
            />
            )
          </strong>
        )}
      </span>
    );
  }

  private renderRealEstateGroup(realEstateGroup: RealEstateGroup) {
    switch (realEstateGroup) {
      case RealEstateGroup.Residential:
        return <ResourceText resourceKey="forResidential" />;
      case RealEstateGroup.Commercial:
        return <ResourceText resourceKey="forCommercial" />;
      case RealEstateGroup.Agricultural:
        return <ResourceText resourceKey="forAgricultural" />;
      default:
        break;
    }
  }
}
