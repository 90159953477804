import { RealEstatePropertySearchItem, StatusType } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import head from "lodash-es/head";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  property: RealEstatePropertySearchItem;
  onHandleRegistrationClick: (property: RealEstatePropertySearchItem) => void;
};

const PublicationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ property, onHandleRegistrationClick }) => {
      const availability = property?.propertyInfo?.availability;
      const mandateDate = property?.propertyInfo?.mandateDate;
      const transactionDateTime = property?.transaction?.transactionDateTime;
      const images = (property?.imageUrls || []).filter((d) => !!d);
      const intl = useIntl();

      const daysOnMarket = useMemo(() => {
        if (!availability || !mandateDate || !transactionDateTime) return 0;
        switch (availability) {
          case StatusType.AVAILABLE:
            return moment().diff(mandateDate, "days");
          case StatusType.SOLD:
          case StatusType.SOLD_UNDER_CONDITIONS:
          case StatusType.RENTED_UNDER_CONDITIONS:
          case StatusType.RENTED:
          case StatusType.WITHDRAWN:
            return moment(transactionDateTime).diff(mandateDate, "days");
          default:
            break;
        }
      }, [availability, mandateDate, transactionDateTime]);

      const thumbStyle = useMemo(() => {
        if (!images.length) return null;
        const image = head(images);
        return {
          backgroundImage: `url(${JSON.stringify(image)})`,
        } as React.CSSProperties;
      }, [images]);

      const price = useMemo(() => {
        const price =
          property?.financial?.transactionPrice ||
          property?.financial?.rentPrice ||
          property?.financial?.purchasePrice;

        if (!price) return null;

        return intl.formatNumber(price, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }, [property]);

      const status = useMemo(() => {
        switch (availability) {
          case StatusType.AVAILABLE: {
            return "success";
          }
          case StatusType.WITHDRAWN: {
            return "danger";
          }
          default:
            return "warning";
        }
      }, [availability]);

      const date = useCallback((date: Date) => {
        if (!date) return null;
        return moment(date).format("DD-MM-YYYY");
      }, []);

      const handleRegistrationClick = useCallback(() => {
        onHandleRegistrationClick(property);
      }, [property, onHandleRegistrationClick]);

      return (
        <div styleName="property" onClick={handleRegistrationClick}>
          <div styleName="thumb" style={thumbStyle}>
            {!thumbStyle && (
              <div styleName="placeholder">
                <Icon name="camera-alt" size={20} color={Colors.MediumGray} />
              </div>
            )}
          </div>
          <div styleName="meta">
            {!!property?.propertyInfo?.availability && (
              <div styleName="meta__row">
                <span styleName={classNames("status", status)}>
                  <I18n
                    value={property?.propertyInfo?.availability.toString()}
                    prefix="statusTypeOptions"
                  />
                </span>
              </div>
            )}

            {!!price && (
              <div styleName="meta__row">
                <div styleName="label">
                  <I18n value="mls.publication.price" />
                </div>
                <div styleName="value">{price}</div>
              </div>
            )}

            {!!daysOnMarket && (
              <div styleName="meta__row">
                <div styleName="label">
                  <I18n value="mls.publication.daysOnMarket" />
                </div>
                <div styleName="value">{daysOnMarket}</div>
              </div>
            )}

            {!!property?.propertyInfo?.mandateDate && (
              <div styleName="meta__row">
                <div styleName="label">
                  <I18n value="mls.publication.from" />
                </div>
                <div styleName="value">
                  {date(property?.propertyInfo?.mandateDate)}
                </div>
              </div>
            )}

            {!!property?.transaction?.transactionDateTime && (
              <div styleName="meta__row">
                <div styleName="label">
                  <I18n value="mls.publication.untill" />
                </div>
                <div styleName="value">
                  {date(property?.transaction?.transactionDateTime)}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);

export default PublicationComponent;
