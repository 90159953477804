import * as React from "react";
import * as PropTypes from "prop-types";
import * as deepEqual from "deep-equal";

type ComponentConstructor<P> = React.ComponentClass<P> | React.StatelessComponent<P>;

export interface UiTableRowProps {
  gridTemplateColumns: React.CSSProperties;
}

export const uiTableRow = <P extends {}> (Component: ComponentConstructor<P>) => {
  const result = class UiTableRow extends React.Component<P & {}, UiTableRowProps> {
    public static contextTypes = {
      gridTemplateColumns: PropTypes.object
    };

    constructor(props, context) {
      super(props, context);

      this.state = {
        gridTemplateColumns: this.context.gridTemplateColumns || null
      };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: P & {}, nextContext: UiTableRowProps) {
      if (!nextContext || deepEqual(this.context, nextContext)) return;
      this.setState({
        gridTemplateColumns: nextContext.gridTemplateColumns || null
      });
    }

    public render() {
      return <Component
        { ...this.state }
        { ...this.props }
      />;
    }
  };

  return result;
};
