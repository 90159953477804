import * as React from "react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import * as linkifyElement from "linkifyjs/element";
import { useMeasure } from "react-use";
import { v4 as uuid } from "uuid";

type Props = {
  width?: number;
  height?: number;
  children: React.ReactElement;
  linkify?: boolean;
  autoHeight?: boolean;
  onLinkClick?: (event: MouseEvent) => void;
};

export const IframeComponent: FC<Props> = ({
  children,
  width,
  height,
  linkify,
  autoHeight,
  onLinkClick,
}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const [style, setStyle] = useState<React.CSSProperties>({
    height,
    border: "none",
    width: width || "100%",
    overflow: autoHeight ? "hidden" : "initial",
  });
  const [wrapperRef, { height: wrapperHeight }] = useMeasure<HTMLDivElement>();

  const onLoadCallback = useCallback(() => {
    setIframeLoaded(true);
  }, [setIframeLoaded]);

  const anchorClickCallback = useCallback(
    (event: MouseEvent) => {
      if (!onLinkClick) return;
      onLinkClick(event);
    },
    [onLinkClick]
  );

  const wrapperRefCallback = useCallback(
    async (element: HTMLDivElement) => {
      if (element) {
        wrapperRef(element);

        if (linkify) {
          linkifyElement(element, {
            defaultProtocol: "https",
            target: "_blank",
          });
        }

        const anchors = element.getElementsByTagName("a");
        for (let i = 0; i < anchors.length; i++) {
          const anchor = anchors.item(i);

          anchor?.addEventListener("click", anchorClickCallback);
          if (
            !!anchor?.hasAttribute("href") &&
            !anchor.getAttribute("href")?.includes("mailto:")
          ) {
            anchor.setAttribute("target", "_blank");
          }
        }
      }
    },
    [linkify, anchorClickCallback, wrapperRef]
  );

  const iframeRefCallback = useCallback(
    (element: HTMLIFrameElement) => {
      setIframeRef(element);
    },
    [setIframeRef]
  );

  const node = useMemo(() => {
    if (!iframeLoaded) return;
    const node = iframeRef?.contentWindow?.document.body;

    node?.setAttribute(
      "style",
      "margin: 0; padding: 0; font-family: Arial, sans-serif; font-size: 14px; overflow: hidden;"
    );

    return node;
  }, [iframeLoaded, iframeRef]);

  const wrapper = useMemo(() => {
    return (
      <div
        style={{ overflowY: "hidden" }}
        ref={wrapperRefCallback}
        key={uuid()}
      >
        {children}
      </div>
    );
  }, [children, wrapperRefCallback]);

  useEffect(() => {
    if (!!autoHeight) {
      setStyle((style) => ({
        ...style,
        height: wrapperHeight,
      }));
    }
  }, [wrapperHeight, autoHeight]);

  return (
    <iframe
      style={style}
      ref={iframeRefCallback}
      frameBorder={0}
      onLoad={onLoadCallback}
      src={"/empty.html"}
    >
      {node && createPortal(wrapper, node)}
    </iframe>
  );
};
