import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouteUtil } from "@haywork/util";
import { ACQUISITIONROUTES } from "@haywork/constants";
import GeneralScreen from "../general";
import AssignmentsScreen from "../assignments";
import NetworkScreen from "../network";
import DossierScreen from "../dossier";
import TimelineScreen from "../timeline";
import EditScreen from "../edit";

const route = RouteUtil.mapStaticRouteValues;
export const AcquisitionsDetailRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ACQUISITIONROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(ACQUISITIONROUTES.GENERAL.URI, { id })} />
        )}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.GENERAL.URI}
        component={GeneralScreen}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.ACQUISITION_ASSIGNMENTS.URI}
        component={AssignmentsScreen}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.NETWORK.URI}
        component={NetworkScreen}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.DOSSIER.URI}
        component={DossierScreen}
      />
      <Route
        exact
        path={ACQUISITIONROUTES.TIMELINE.URI}
        component={TimelineScreen}
      />
      <Route path={ACQUISITIONROUTES.EDIT.URI} component={EditScreen} />
    </Switch>
  );
};
