export const EMAIL = {
  IMAP_PORTS: [143, 993],
  SMTP_PORTS: [25, 465, 587],
};

export const EMAIL_ATTACHMENTS_BLACKLIST = [
  "ADE",
  "ADP",
  "APP",
  "ASA",
  "ASP",
  "BAS",
  "BAT",
  "CER",
  "CHM",
  "CMD",
  "COM",
  "CPL",
  "CRT",
  "CSH",
  "DLL",
  "EXE",
  "FXP",
  "HLP",
  "HTA",
  "HTM",
  "HTML",
  "HTR",
  "INF",
  "INS",
  "ISP",
  "ITS",
  "JS",
  "JSE",
  "KSH",
  "LNK",
  "MAD",
  "MAF",
  "MAG",
  "MAM",
  "MAQ",
  "MAR",
  "MAS",
  "MAT",
  "MAU",
  "MAV",
  "MAW",
  "MDA",
  "MDB",
  "MDE",
  "MDT",
  "MDW",
  "MDZ",
  "MHT",
  "MHTM",
  "MHTML",
  "MSC",
  "MSI",
  "MSP",
  "MST",
  "OCX",
  "OPS",
  "PCD",
  "PIF",
  "PRF",
  "PRG",
  "REG",
  "SCF",
  "SCR",
  "SCT",
  "SHB",
  "SHS",
  "TMP",
  "URL",
  "VB",
  "VBE",
  "VBS",
  "VBX",
  "VSMACROS",
  "VSS",
  "VST",
  "VSW",
  "WS",
  "WSC",
  "WSF",
  "WSH",
  "XSL",
];
