import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { HealthModule, HealthModuleProps } from "./health";
import { LogMessage } from "@haywork/stores/logging/reducer";

type StateProps = {
  userVersion: string;
  errors: string[];
  messages: LogMessage[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  HealthModuleProps,
  AppState
> = (state) => {
  const { version: userVersion } = state.appSettings;
  const { errors } = state.error;
  const { messages } = state.logging;

  return { userVersion, errors, messages };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  HealthModuleProps
> = (dispatch) => ({});

export type HealthContainerProps = StateProps & DispatchProps;
export const HealthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthModule);
