export enum FilterType {
  Text = "Text",
  Array = "Array",
  TextRange = "TextRange",
  NumberRange = "NumberRange",
  PriceRange = "PriceRange",
  DatePicker = "DatePicker",
  DateRange = "DateRange",
  KeyNumberArray = "KeyNumberArray",
  Select = "Select",
  LocationRange = "LocationRange",
  Date = "Date",
  Number = "Number",
  Price = "Price",
  Single = "Single",
  Realtor = "Realtor",
  SearchLocation = "SearchLocation",
  MlsPriceRange = "MlsPriceRange",
  PostalCodeRange = "PostalCodeRange",
}
