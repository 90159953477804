import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { MlsUtil } from "@haywork/util";

const realestatePropertySelector = (state: AppState) =>
  state.mls.single?.realEstateResponse;

export const realestateProperty = createSelector(
  realestatePropertySelector,
  (property) => property
);

export const objectAssignment = createSelector(
  realestatePropertySelector,
  (property) => {
    return !property?.realEstateProperty
      ? null
      : MlsUtil.mapToObjectAssignmentForSearchAssignment(
          property.realEstateProperty
        );
  }
);
