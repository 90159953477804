import {
  AssignmentPhase,
  AvailabilityStatus,
  ProjectAssignment
} from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useMemo, useRef, useState } from "react";

const SOLD_STATUSES = [
  AvailabilityStatus.Farmed,
  AvailabilityStatus.Leased,
  AvailabilityStatus.Rented,
  AvailabilityStatus.Sold,
  AvailabilityStatus.Withdrawn
];

export enum ProjectAction {
  Complete = "Complete",
  WithDraw = "WithDraw",
  Publish = "Publish",
  Archive = "Archive",
  UnArchive = "UnArchive",
  AddBuildNumbers = "AddBuildNumbers",
  AddObjectType = "AddObjectType",
  Remove = "Remove",
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAppointment = "CreateAppointment",
  CreateInvoice = "CreateInvoice"
}

type Props = {
  project: ProjectAssignment;
  loading: boolean;
  canSendEmail: boolean;
  canCreateObjectTypeAssignment: boolean;
  canCreateObjectAssignment: boolean;
  onClick: (action: ProjectAction) => void;
};

export const ProjectGeneralActionsComponent: FC<Props> = memo(
  ({
    project,
    loading,
    canSendEmail,
    canCreateObjectTypeAssignment,
    canCreateObjectAssignment,
    onClick
  }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const { assignmentPhase, availabilityStatus, forSale, isActive } = project;

    const showComplete = useMemo(() => {
      let value;
      switch (assignmentPhase) {
        case AssignmentPhase.Initiated:
          value = availabilityStatus === AvailabilityStatus.Available;
          break;
        case AssignmentPhase.Concept:
          value = !!forSale;
          break;
        default:
          value = false;
      }

      return value;
    }, [assignmentPhase, availabilityStatus, forSale]);
    const showWithdraw =
      availabilityStatus === AvailabilityStatus.Available &&
      assignmentPhase !== AssignmentPhase.Concept;
    const showPublish = assignmentPhase === AssignmentPhase.Concept;
    const showArchive =
      !!isActive &&
      ([AssignmentPhase.Concept, AssignmentPhase.Completed].indexOf(
        assignmentPhase
      ) !== -1 ||
        SOLD_STATUSES.indexOf(availabilityStatus) !== -1) &&
      !!isActive;
    const showUnArchive = !isActive;
    const showRemove = assignmentPhase === AssignmentPhase.Concept;
    const showCreateEmail = canSendEmail;
    const showAddObjectType = canCreateObjectTypeAssignment;
    const showAddObject = canCreateObjectAssignment;

    const onClickHandler = (action: ProjectAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="project.actions.label.actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="project.actions.label.actions" />
          {showComplete && (
            <Action
              label="completeAssignment"
              icon={<Icon light name="fast-forward" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.Complete)}
            />
          )}
          {showWithdraw && (
            <Action
              label="withDrawAssignment"
              icon={<Icon light name="arrow-left" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.WithDraw)}
            />
          )}
          {showPublish && (
            <Action
              label="assignmentMenuPublish"
              icon={<Icon light name="globe" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.Publish)}
            />
          )}
          {showAddObjectType && (
            <Action
              label="projectMenuAddType"
              icon={<Icon light name="plus" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.AddObjectType)}
            />
          )}
          {showAddObject && (
            <Action
              label="assignmentMenuAddBuildNumbers"
              icon={<Icon light name="plus" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.AddBuildNumbers)}
            />
          )}

          <Spacer label="assignment.actions.label.other" />
          {showCreateEmail && (
            <Action
              label="assignment.actions.label.email"
              icon={<Icon light name="paper-plane" color={Colors.ActionListIcon} fixedWidth />}
              onClick={() => onClickHandler(ProjectAction.CreateEmail)}
            />
          )}
          <Action
            label="assignment.actions.label.task"
            icon={<Icon light name="tasks" color={Colors.ActionListIcon} fixedWidth />}
            onClick={() => onClickHandler(ProjectAction.CreateTask)}
          />
          <Action
            label="assignment.actions.label.appointment"
            icon={<Icon light name="calendar-plus" color={Colors.ActionListIcon} fixedWidth />}
            onClick={() => onClickHandler(ProjectAction.CreateAppointment)}
          />
          <Action
            label="assignment.actions.label.invoice"
            icon={<Icon light name="euro-sign" color={Colors.ActionListIcon} fixedWidth />}
            onClick={() => onClickHandler(ProjectAction.CreateInvoice)}
          />

          {(showArchive || showUnArchive || showRemove) && (
            <>
              <Spacer />
              {showArchive && (
                <Action
                  label="assignmentMenuArchive"
                  icon={<Icon light name="archive" color={Colors.ActionListIcon} fixedWidth />}
                  onClick={() => onClickHandler(ProjectAction.Archive)}
                />
              )}
              {showUnArchive && (
                <Action
                  label="assignmentMenuUnArchive"
                  icon={<Icon light name="inbox-out" color={Colors.ActionListIcon} fixedWidth />}
                  onClick={() => onClickHandler(ProjectAction.UnArchive)}
                />
              )}
              {showRemove && (
                <Action
                  label="assignmentMenuRemove"
                  icon={<Icon light name="trash-alt" color={Colors.ActionListIcon} fixedWidth />}
                  onClick={() => onClickHandler(ProjectAction.Remove)}
                />
              )}
            </>
          )}
        </ActionList>
      </>
    );
  }
);

export default ProjectGeneralActionsComponent;
