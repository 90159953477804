import { intlContext } from "@haywork/app";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import * as CSSModules from "react-css-modules";
import DropArea from "./drop";
import classNames from "classnames";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./styles.scss");
const maxWidth = 512;
const maxHeight = 512;

type Props = {
  placeholder?: string;
  maxWidth?: number;
  maxHeight?: number;
} & InputComponentProps;

type State = {
  style: React.CSSProperties | undefined;
};

@CSSModules(styles, { allowMultiple: true })
export class ImageBase64Component extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      style: undefined,
    };

    this.onDropHandler = this.onDropHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    const { value } = this.props;
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;

    return (
      <div>
        <label
          htmlFor={`${this.props.name}.file`}
          styleName={classNames("wrapper", { "has-image": !!value })}
          style={this.state.style}
          onClick={(e) => {
            if (!!value) {
              e.preventDefault();
            }
          }}
        >
          {!!value ? (
            <>
              <div styleName="remove" onClick={this.onRemoveHandler}>
                <Icon name="times" color={Colors.White} size={16} light />
              </div>
              <div styleName="image">
                <img src={`data:image/png;base64,${value}`} />
              </div>
            </>
          ) : (
            <div styleName="inner">{placeholder}</div>
          )}
          <input
            type="file"
            name={`${this.props.name}.file`}
            id={`${this.props.name}.file`}
            styleName="hidden"
            onChange={this.onChangeHandler}
          />
          <DropArea onDrop={this.onDropHandler} />
        </label>
      </div>
    );
  }

  private onDropHandler(file: File) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = new Image();

      img.onload = () => {
        const { width, height } = img;
        const childRatio = height / width;
        let newHeight = this.props.maxHeight || maxHeight,
          newWidth = this.props.maxWidth || maxWidth;
        const ratio = newHeight / newWidth;

        if (width < newWidth && height < newHeight) {
          newWidth = width;
          newHeight = height;
        } else if (childRatio > ratio) {
          newWidth = Math.floor(newHeight / childRatio);
        } else {
          newHeight = Math.floor(newWidth * childRatio);
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        let logo = canvas.toDataURL() || "";
        logo = logo.replace("data:image/png;base64,", "");

        this.setState({ style: { width: newWidth, height: newHeight } });
        this.props.onChange(logo);
      };

      img.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  }

  private onRemoveHandler() {
    this.setState({ style: undefined });
    this.props.onChange("");
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event?.target?.files;
    if (!!files && !!files.length) {
      const first = files.item(0);
      this.onDropHandler(first);
    }
  }
}
