import { SearchLocation } from "@haywork/api/mls";

export enum ActionType {
  SetSearchLocations = "MLS.SearchLocations.SetSearchLocations",
  ResetApp = "RESET_APP",
}

type SetSearchLocations = {
  type: ActionType.SetSearchLocations;
  searchLocations: SearchLocation[];
};

const setSearchLocations = (searchLocations: SearchLocation[]) => ({
  type: ActionType.SetSearchLocations,
  searchLocations,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes = SetSearchLocations | ResetApp;
export const actions = {
  setSearchLocations,
};
