import {
  AmenitiesOption,
  CompanyListing,
  ConditionOption,
  ConstructionPeriodOption,
  DrainageOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  GroundUsageOption,
  HorseCompanySubtype,
  HorseTroughLocationOption,
  HouseTypeOption,
  IsolationTypeOption,
  ListingType,
  ObjectAssignment,
  OrientationOption,
  PoultryFarmingSubtypeOption,
  PoultryHousingTypeOption,
  SituatedTypeOption,
  SoilTypeOption,
} from "@haywork/api/kolibri";
import { FormReturnValue } from "@haywork/modules/form";
import {
  FloorsAndSpaces,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AgriculturalAgriculturalHouseComponent,
  AgriculturalArableCompanyComponent,
  AgriculturalBusinessSpaceComponent,
  AgriculturalCattleFarmingComponent,
  AgriculturalCompanyListingsComponent,
  AgriculturalDairyFarmingComponent,
  AgriculturalHorsesCompanyComponent,
  AgriculturalHorticulturalComponent,
  AgriculturalLooseSoilComponent,
  AgriculturalOtherComponent,
  AgriculturalPigHoldingComponent,
  AgriculturalPoultryCompanyComponent,
  AgriculturalSizesAndNumbersComponent,
  AgriculturalVealCalvesFarmingComponent,
} from "./agricultural";
import GlassStructures from "./agricultural/components/glass-structures";
import RidingHalls from "./agricultural/components/riding-halls";
import {
  AssignmentEditContentSpaceGardensComponent,
  FloorLevel,
} from "./shared";

interface EditContentAgriculturalComponentProps {
  objectAssignment: ObjectAssignment;
  companyListings: CompanyListing[];
  groundUsageOptions: GroundUsageOption[];
  soilTypeOptions: SoilTypeOption[];
  drainageOptions: DrainageOption[];
  horseTroughLocationOptions: HorseTroughLocationOption[];
  constructionPeriods: ConstructionPeriodOption[];
  houseTypes: HouseTypeOption[];
  situatedTypeOptions: SituatedTypeOption[];
  orientations: OrientationOption[];
  gardenQualityOptions: GardenQualityOption[];
  garageTypeOptions: GarageTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  isolationTypeOptions: IsolationTypeOption[];
  gardenTypeOptions: GardenTypeOption[];
  poultryFarmingSubtypes: PoultryFarmingSubtypeOption[];
  poultryHousingTypeOptions: PoultryHousingTypeOption[];
  conditionOptions: ConditionOption[];
  onChange: (values: FormReturnValue) => void;
  onCompanyListingsChange: (companyListings: CompanyListing[]) => void;
  onRemoveCompanyListing: (id: string) => void;
}
interface State {}
type Props = EditContentAgriculturalComponentProps & RouteComponentProps<any>;

export class EditContentAgriculturalInstance extends React.Component<
  Props,
  State
> {
  public render() {
    const {
      objectAssignment,
      groundUsageOptions,
      soilTypeOptions,
      drainageOptions,
      horseTroughLocationOptions,
      constructionPeriods,
      houseTypes,
      situatedTypeOptions,
      orientations,
      gardenQualityOptions,
      garageTypeOptions,
      amenitiesOptions,
      isolationTypeOptions,
      gardenTypeOptions,
      companyListings,
      poultryFarmingSubtypes,
      poultryHousingTypeOptions,
      conditionOptions,
    } = this.props;

    let initial = 0;
    if (
      this.props.location.hash &&
      this.props.location.hash === "#company-listings"
    ) {
      initial = [
        ListingType.AgriculturalHouse,
        ListingType.HorsesCompany,
      ].includes(objectAssignment.listingType)
        ? 3
        : 2;
    }

    return (
      <StepperComponent initial={initial} scrollToElementId="scroll-to-top">
        {objectAssignment.listingType === ListingType.ArableCompany && (
          <StepComponent title="listingTypes.ArableCompany">
            <AgriculturalArableCompanyComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.BusinessSpace && (
          <StepComponent title="listingTypes.BusinessSpace">
            <AgriculturalBusinessSpaceComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.LooseSoil && (
          <StepComponent title="listingTypes.LooseSoil">
            <AgriculturalLooseSoilComponent
              objectAssignment={objectAssignment}
              groundUsageOptions={groundUsageOptions}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.DairyFarmingCompany && (
          <StepComponent title="listingTypes.DairyFarmingCompany">
            <AgriculturalDairyFarmingComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {/* ProductionRights, VealCalvesFarming */}
        {objectAssignment.listingType === ListingType.Other && (
          <StepComponent title="listingTypes.Other">
            <AgriculturalOtherComponent
              objectAssignment={objectAssignment}
              groundUsageOptions={groundUsageOptions}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.HorsesCompany && (
          <>
            <StepComponent title="listingTypes.HorsesCompany">
              <AgriculturalHorsesCompanyComponent
                objectAssignment={objectAssignment}
                horseTroughLocationOptions={horseTroughLocationOptions}
                soilTypeOptions={soilTypeOptions}
                drainageOptions={drainageOptions}
                onChange={this.props.onChange}
              />
            </StepComponent>
            {[
              HorseCompanySubtype.Pension,
              HorseCompanySubtype.RidingSchool,
            ].includes(objectAssignment.horseCompanySubtype) && (
              <RidingHalls
                objectAssignment={objectAssignment}
                onChange={this.props.onChange}
              />
            )}
          </>
        )}
        {objectAssignment.listingType === ListingType.PoultryCompany && (
          <StepComponent title="listingTypes.PoultryCompany">
            <AgriculturalPoultryCompanyComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              poultryFarmingSubtypes={poultryFarmingSubtypes}
              poultryHousingTypeOptions={poultryHousingTypeOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.CattleFarming && (
          <StepComponent title="listingTypes.CattleFarming">
            <AgriculturalCattleFarmingComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.VealCalvesFarming && (
          <StepComponent title="listingTypes.VealCalvesFarming">
            <AgriculturalVealCalvesFarmingComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.Horticultural && (
          <>
            <StepComponent title="listingTypes.Horticultural">
              <AgriculturalHorticulturalComponent
                objectAssignment={objectAssignment}
                soilTypeOptions={soilTypeOptions}
                drainageOptions={drainageOptions}
                onChange={this.props.onChange}
              />
            </StepComponent>
            <GlassStructures
              objectAssignment={objectAssignment}
              onChange={this.props.onChange}
            />
          </>
        )}
        {objectAssignment.listingType === ListingType.PigHolding && (
          <StepComponent title="listingTypes.PigHolding">
            <AgriculturalPigHoldingComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              drainageOptions={drainageOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.AgriculturalHouse && (
          <StepComponent title="listingTypes.AgriculturalHouse">
            <AgriculturalAgriculturalHouseComponent
              objectAssignment={objectAssignment}
              soilTypeOptions={soilTypeOptions}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        {objectAssignment.listingType === ListingType.AgriculturalHouse && (
          <React.Fragment>
            <StepComponent title="assignment.FloorsAndSpaces">
              <FloorLevel
                objectAssignment={objectAssignment}
                onChange={this.props.onChange}
              />
              <FloorsAndSpaces
                objectAssignment={objectAssignment}
                onChange={this.props.onChange}
              />
            </StepComponent>

            <StepComponent title="assignmentOtherSpacesTab">
              <AssignmentEditContentSpaceGardensComponent
                objectAssignment={objectAssignment}
                onChange={this.props.onChange}
              />
            </StepComponent>
          </React.Fragment>
        )}
        <StepComponent title="sizesAndNumbers">
          <AgriculturalSizesAndNumbersComponent
            objectAssignment={objectAssignment}
            constructionPeriods={constructionPeriods}
            onChange={this.props.onChange}
          />
        </StepComponent>
        <StepComponent title="businessHomes">
          <AgriculturalCompanyListingsComponent
            objectAssignment={objectAssignment}
            companyListings={companyListings}
            houseTypes={houseTypes}
            situatedTypeOptions={situatedTypeOptions}
            orientations={orientations}
            gardenQualityOptions={gardenQualityOptions}
            garageTypeOptions={garageTypeOptions}
            amenitiesOptions={amenitiesOptions}
            isolationTypeOptions={isolationTypeOptions}
            gardenTypeOptions={gardenTypeOptions}
            conditionOptions={conditionOptions}
            onChange={this.props.onCompanyListingsChange}
            onRemoveCompanyListing={this.props.onRemoveCompanyListing}
          />
        </StepComponent>
      </StepperComponent>
    );
  }
}

export const EditContentAgriculturalComponent = withRouter<
  EditContentAgriculturalComponentProps & RouteComponentProps<any>,
  any
>(EditContentAgriculturalInstance);
