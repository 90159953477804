import { createSelector } from "reselect";
import { AppState } from "./stores";
import { ShareType, SyncStatus } from "./api/mail";
import { EmployeeRole, EnergyClassOption, EnergyClass } from "./api/kolibri";

const accountsSelector = (state: AppState) => state.emailV2.accounts.accounts;
const sharesSelector = (state: AppState) => state.emailV2.shares.shares;
const employeeIdSelector = (state: AppState) => state.account.employee.id;
const realEstateAgencySelector = (state: AppState) =>
  state.account.currentRealestateAgency;
const mastertableSelector = (state: AppState) => state.main.mastertable.kolibri;

export const canSendEmail = createSelector(
  realEstateAgencySelector,
  accountsSelector,
  sharesSelector,
  employeeIdSelector,
  (realEstateAgency, accounts, shares, personId) => {
    const { role } = realEstateAgency;
    if (role === EmployeeRole.BackOffice) return false;

    let canSendEmail = false;

    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const restrictions = shares.find(
        (share) => share.accountId === account.id && share.personId === personId
      );
      if (
        account.syncStatus !== SyncStatus.InvalidCredentials &&
        (!restrictions || restrictions.shareType === ShareType.ReadWriteSend)
      ) {
        canSendEmail = true;
        break;
      }
    }

    return canSendEmail;
  }
);

export const energyClassOptions = createSelector(
  mastertableSelector,
  (mastertable) => {
    if (!mastertable) return [] as EnergyClassOption[];
    const validOptions = [
      EnergyClass.A,
      EnergyClass.Aplus,
      EnergyClass.Aplusplus,
      EnergyClass.B,
      EnergyClass.C,
      EnergyClass.D,
      EnergyClass.E,
      EnergyClass.F,
      EnergyClass.G,
    ];
    return (mastertable.energyClassOptions || []).filter((energyClass) =>
      validOptions.includes(energyClass.value)
    );
  }
);
