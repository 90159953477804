import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { ObjectTypesEditMarketingContainerProps } from "@haywork/modules/object-types";

import { ObjectTypesEditMarketingRouting } from "./edit-marketing.routing";

const styles = require("./edit-marketing.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ObjectTypesEditMarketingComponentProps {}
interface State {}
type Props = ObjectTypesEditMarketingComponentProps & ObjectTypesEditMarketingContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditMarketingComponent extends React.Component<Props, State> {
  public render() {
    const { id } = this.props.currentType;

    return <div styleName="marketing" id="scroll-to-top">
      <div styleName="marketing__body">
        <div className="container-fluid">
          <ObjectTypesEditMarketingRouting id={ id } />
        </div>
      </div>
      <div styleName="marketing__sidebar">
        <NavLink to={ route(OBJECTTYPESROUTES.EDIT_MARKETING_PHOTOS.URI, { id }) } activeClassName="active" data-cy="CY-marketingPhotos">
          <div styleName="section">
            <i className="fal fa-image" />
            <ResourceText resourceKey="assignmentMediaLinkPhotos" />
          </div>
        </NavLink>
        <NavLink to={ route(OBJECTTYPESROUTES.EDIT_MARKETING_TEXT.URI, { id }) } activeClassName="active" data-cy="CY-marketingText">
          <div styleName="section">
            <i className="fal fa-align-left" />
            <ResourceText resourceKey="assignmentMediaLinkText" />
          </div>
        </NavLink>
        <NavLink to={ route(OBJECTTYPESROUTES.EDIT_MARKETING_MAPS.URI, { id }) } activeClassName="active" data-cy="CY-marketingMaps">
          <div styleName="section">
            <i className="fal fa-map" />
            <ResourceText resourceKey="assignmentMediaLinkMaps" />
          </div>
        </NavLink>
        <NavLink to={ route(OBJECTTYPESROUTES.EDIT_MARKETING_VIDEOS.URI, { id }) } activeClassName="active" data-cy="CY-marketingVideos">
          <div styleName="section">
            <i className="fal fa-video" />
            <ResourceText resourceKey="projectMediaLinkVideos" />
          </div>
        </NavLink>
      </div>
    </div>;
  }
}
