import * as React from "react";
import { FC, memo, useCallback } from "react";
import Pill from "@haywork/components/ui/pill";
import { LinkedAssignment, AssignmentType } from "@haywork/api/kolibri";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import {
  ASSIGNMENTROUTES,
  PROJECTROUTES,
  OBJECTTYPESROUTES,
  ACQUISITIONROUTES,
  ACQUISITIONOBJECTROUTES
} from "@haywork/constants";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: LinkedAssignment;
  isActive: boolean;
  onNavigate: (path: string) => void;
};

const AssignmentPillComponent: FC<Props> = memo(
  ({ assignment, onNavigate, isActive }) => {
    const { typeOfAssignment, id } = assignment;
    const onClick = useCallback(() => {
      switch (typeOfAssignment) {
        case AssignmentType.Object: {
          const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case AssignmentType.Project: {
          const path = route(PROJECTROUTES.DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case AssignmentType.ObjectType: {
          const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case AssignmentType.Acquisition: {
          const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case AssignmentType.AcquisitionObject: {
          const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        default: {
          break;
        }
      }
    }, [typeOfAssignment, id, onNavigate]);

    return (
      <Pill
        label={assignment.displayName || assignment.publicReference}
        color={!isActive ? Colors.Gray : Colors.Primary}
        onClick={onClick}
      />
    );
  }
);

export default AssignmentPillComponent;
