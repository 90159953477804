import {
  AcquisitionObjectAssignment,
  AmenitiesOption,
  ConditionOption,
  ConstructionPeriodOption,
  DrainageOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  GroundUsageOption,
  HorseTroughLocationOption,
  HouseTypeOption,
  IsolationTypeOption,
  OrientationOption,
  PoultryFarmingSubtypeOption,
  PoultryHousingTypeOption,
  SituatedTypeOption,
  SoilTypeOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, EditableActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquistionAssignmentEditObjectAgriculturalScreen,
  AcquistionAssignmentEditObjectAgriculturalScreenProps,
} from "./assignments-edit-object-agricultural";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  groundUsageOptions: GroundUsageOption[];
  soilTypeOptions: SoilTypeOption[];
  drainageOptions: DrainageOption[];
  horseTroughLocationOptions: HorseTroughLocationOption[];
  constructionPeriods: ConstructionPeriodOption[];
  houseTypes: HouseTypeOption[];
  situatedTypeOptions: SituatedTypeOption[];
  orientations: OrientationOption[];
  gardenQualityOptions: GardenQualityOption[];
  garageTypeOptions: GarageTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  isolationTypeOptions: IsolationTypeOption[];
  gardenTypeOptions: GardenTypeOption[];
  poultryFarmingSubtypes: PoultryFarmingSubtypeOption[];
  poultryHousingTypeOptions: PoultryHousingTypeOption[];
  conditionOptions: ConditionOption[];
};
type DispatchProps = {
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditObjectAgriculturalScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable.currentComponentState;
  const {
    groundUsageOptions,
    soilTypeOptions,
    drainageOptions,
    horseTroughLocationOptions,
    constructionPeriods,
    houseTypes,
    situatedTypeOptions,
    orientations,
    gardenQualityOptions,
    garageTypeOptions,
    amenitiesOptions,
    isolationTypeOptions,
    gardenTypeOptions,
    poultryFarmingSubtypes,
    poultryHousingTypeOptions,
    conditionOptions,
  } = state.main.mastertable.kolibri;
  return {
    acquisitionObjectAssignment,
    constructionPeriods,
    isolationTypeOptions,
    conditionOptions,
    groundUsageOptions,
    soilTypeOptions,
    drainageOptions,
    horseTroughLocationOptions,
    houseTypes,
    situatedTypeOptions,
    orientations,
    gardenQualityOptions,
    garageTypeOptions,
    amenitiesOptions,
    gardenTypeOptions,
    poultryFarmingSubtypes,
    poultryHousingTypeOptions,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditObjectAgriculturalScreenProps
> = (dispatch) => ({
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AcquistionAssignmentEditObjectAgriculturalScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditObjectAgriculturalScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditObjectAgriculturalScreen);
