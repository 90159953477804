import {
  FinancialAdministrationProduct,
  ProductOrder,
  InvoiceStatus,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { RouteUtil } from "@haywork/util";
import { INVOICEROUTES } from "@haywork/constants";

const styles = require("./marketing-budget.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  productOrder: ProductOrder;
  products: FinancialAdministrationProduct[];
  zebra: boolean;
  onProductOrderChange: (values: FormReturnValue, id: string) => void;
  onRemove: (id: string) => void;
  onNavigate: (path: string) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class ProductOrderComponent extends React.Component<Props> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      id: { value: this.props.productOrder.id },
      dateTime: { value: this.props.productOrder.dateTime },
      description: { value: this.props.productOrder.description },
      unitPriceNet: { value: this.props.productOrder.unitPriceNet },
      chargeToCustomerBudget: {
        value: this.props.productOrder.chargeToCustomerBudget,
      },
      financialAdministrationProductId: {
        value: this.props.productOrder.financialAdministrationProductId,
        onChange: (ref) => {
          if (ref.value) {
            return {
              description: ref.value.description,
              unitPriceNet: ref.value.unitPriceNet,
            };
          }
        },
      },
    };

    this.renderLinkedInvoice = this.renderLinkedInvoice.bind(this);
  }

  public render() {
    const itemStyle = classNames("list__item", { zebra: this.props.zebra });

    return (
      <div styleName={itemStyle}>
        <div styleName="form">
          <Form
            name={`product-order-${this.props.productOrder.id}`}
            formControls={this.formControls}
            onChange={(values) =>
              this.props.onProductOrderChange(
                values,
                this.props.productOrder.id
              )
            }
          >
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column" styleName="select">
                  <Input.ExtendedSelect
                    name="financialAdministrationProductId"
                    values={this.props.products}
                    displayPath="descriptionShort"
                    compareFn={(i, r) => i.id === r}
                    valueCompareFn={(i, r) => i.id === r.id}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="unitPriceNet"
                      step={50}
                      pretty
                      asPrice
                    />
                    <div className="post small">
                      <ResourceText resourceKey="exVat" />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Datepicker name="dateTime" />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.RadioGroup name="chargeToCustomerBudget" asButtonList>
                    <Input.Radio
                      value={false}
                      label="marketingBudget.label.internalBudget"
                    />
                    <Input.Radio
                      value={true}
                      label="marketingBudget.label.customerBudget"
                    />
                  </Input.RadioGroup>
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group stretch">
                <div className="column">
                  <Input.Textarea
                    name="description"
                    autoSize
                    placeholder="description"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column fit">
                  <div styleName="actions">
                    <div
                      styleName="action"
                      onClick={() =>
                        this.props.onRemove(this.props.productOrder.id)
                      }
                    >
                      <I18n value="marketingBudget.action.remove" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.renderLinkedInvoice()}
          </Form>
        </div>
      </div>
    );
  }

  private renderLinkedInvoice() {
    const { linkedInvoice } = this.props.productOrder;
    if (!linkedInvoice) return null;

    const { number: invoiceNumber, status, id } = linkedInvoice;
    if (!id || (status !== InvoiceStatus.Draft && !invoiceNumber)) return null;
    const path = route(INVOICEROUTES.DETAIL.URI, { id });

    return (
      <div className="form__row">
        <span className="as-link" onClick={() => this.props.onNavigate(path)}>
          {status === InvoiceStatus.Draft ? (
            <I18n value="marketingBudget.linkedConceptInvoice" />
          ) : (
            <I18n
              value="marketingBudget.linkedInvoice"
              values={{ invoiceNumber }}
            />
          )}
        </span>
      </div>
    );
  }
}
