import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { Toast } from "@haywork/stores/snackbar-v2";
import * as React from "react";
import { FC, memo, useEffect } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");
const DEFAULT_PERSIST_FOR = 5000;

type Props = {
  toast: Toast;
  removeToast: (id: string) => void;
};

const ToastComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ toast, removeToast }) => {
    if (!toast) return null;

    useEffect(() => {
      const timeout = setTimeout(() => {
        removeToast(toast.id);
        if (typeof toast.done === "function") {
          toast.done();
        }
      }, toast.persistFor || DEFAULT_PERSIST_FOR);

      return () => {
        clearTimeout(timeout);
      };
    }, [toast]);

    const triggerCallback = () => {
      if (typeof toast.callback !== "function") return;
      removeToast(toast.id);
      toast.callback();
    };

    return (
      <div styleName="toast">
        {!!toast.icon && (
          <div styleName="toast__icon">
            <Icon
              name={toast.icon}
              color={Colors.Gray}
              light
              size={24}
              spin={toast.spin}
            />
          </div>
        )}
        <div styleName="toast__body">
          <I18n value={toast.value} values={toast.values} asHtml />
        </div>
        {!!toast.callback && !!toast.callbackLabel && (
          <div styleName="toast__action">
            <div styleName="hit-area" onClick={triggerCallback}>
              <I18n
                value={toast.callbackLabel}
                values={toast.callbackLabelValues}
                asHtml
              />
            </div>
          </div>
        )}
      </div>
    );
  })
);

export default ToastComponent;
