import {
  Amenities,
  AmenitiesOption,
  IsolationTypeOption,
  ObjectAssignment,
  StorageRoomTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, StringUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./section.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment;
  storageRoomTypeOptions: StorageRoomTypeOption[];
  isolationTypeOptions: IsolationTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  onSubmit: (values: FormReturnValue) => void;
}
interface State {
  active: boolean;
  expanded: boolean;
  bodyStyles: React.CSSProperties;
}

@CSSModules(styles, { allowMultiple: true })
export class StorageComponent extends React.Component<Props, State> {
  private bodyRef: HTMLDivElement;
  private formControls: FormControls;
  private form: FormReference;
  private amenitiesOptions: Amenities[] = [
    Amenities.Attic,
    Amenities.CentralHeating,
    Amenities.Electricity,
    Amenities.Water,
  ];

  constructor(props) {
    super(props);

    this.state = {
      active: value(
        this.props.objectAssignment,
        "isBarnStorageAvailable",
        false
      ),
      expanded: false,
      bodyStyles: null,
    };

    const amenities = [];

    if (!!this.props.objectAssignment.storageHasAttic) {
      amenities.push(Amenities.Attic);
    }
    if (!!this.props.objectAssignment.storageHasElectra) {
      amenities.push(Amenities.Electricity);
    }
    if (!!this.props.objectAssignment.storageHasHeating) {
      amenities.push(Amenities.CentralHeating);
    }
    if (!!this.props.objectAssignment.storageHasWater) {
      amenities.push(Amenities.Water);
    }

    this.formControls = {
      storageRoomType: {
        value: value(this.props.objectAssignment, "storageRoomType"),
      },
      amenities: { value: amenities },
      storageInsulationTypes: {
        value: value(this.props.objectAssignment, "storageInsulationTypes", []),
      },
    };

    this.onToggleExpandedClickHandler =
      this.onToggleExpandedClickHandler.bind(this);
    this.onToggleActiveClickHandler =
      this.onToggleActiveClickHandler.bind(this);
    this.bindBodyRef = this.bindBodyRef.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  public render() {
    const toggleStyle = classNames("storage-toggle", {
      active: this.state.active,
    });
    const collapseTriggerStyle = classNames("collapse-trigger", {
      expanded: this.state.expanded,
    });
    const collapseLabel = !this.state.expanded ? "expand" : "collapse";
    const amenitiesOptions = this.props.amenitiesOptions.filter(
      (option) => this.amenitiesOptions.indexOf(option.value) !== -1
    );

    return (
      <div styleName="section">
        <div styleName="section__header">
          <div
            styleName={toggleStyle}
            onClick={this.onToggleActiveClickHandler}
          >
            <i className="far fa-check" />
          </div>
          <div styleName="title">
            <ResourceText
              resourceKey="roomLayoutStorageTitle"
              values={{ count: !!this.state.active ? 1 : 0 }}
            />
          </div>
          {!!this.state.active && (
            <div
              styleName={collapseTriggerStyle}
              onClick={this.onToggleExpandedClickHandler}
            >
              <div styleName="icon">
                <span className="fal fa-fw fa-chevron-down" />
              </div>
              <ResourceText resourceKey={collapseLabel} />
            </div>
          )}
        </div>

        <div styleName="section__body" style={this.state.bodyStyles}>
          <div styleName="inner" ref={this.bindBodyRef}>
            <div styleName="storage-form">
              <Form
                name=""
                formControls={this.formControls}
                asSubForm={true}
                form={(form) => (this.form = form)}
                onChange={this.onFormChange}
              >
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="selectStorageType" />
                  </label>
                  <Input.RadioGroup
                    name="storageRoomType"
                    asButtonList
                    multiline
                  >
                    {this.props.storageRoomTypeOptions.map(
                      (storageRoomType, idx) => (
                        <Input.Radio
                          value={storageRoomType.value}
                          label={StringUtil.capitalize(
                            storageRoomType.displayName
                          )}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>

                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="selectAmenities" />
                  </label>
                  <Input.Multi name="amenities" values={amenitiesOptions} />
                </div>

                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="selectIsolationType" />
                  </label>
                  <Input.Multi
                    name="storageInsulationTypes"
                    values={this.props.isolationTypeOptions}
                  />
                </div>

                <div styleName="divider" />

                <div styleName="button-footer">
                  <div
                    className="btn btn-success"
                    onClick={(event) =>
                      this.onToggleExpandedClickHandler(event, false)
                    }
                  >
                    <ResourceText resourceKey="okay" />
                  </div>
                  <div
                    className="btn btn-danger"
                    onClick={(event) =>
                      this.onToggleActiveClickHandler(event, false)
                    }
                  >
                    <ResourceText resourceKey="remove" />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private bindBodyRef(ref: HTMLDivElement) {
    if (!!ref && !this.bodyRef) this.bodyRef = ref;
  }

  private onToggleActiveClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    forceActive?: boolean
  ) {
    const active = forceActive !== undefined ? forceActive : !this.state.active;
    this.setState({ active });
    this.onToggleExpandedClickHandler(undefined, active);

    if (!active) {
      this.props.onSubmit({
        isBarnStorageAvailable: false,
        storageRoomType: null,
        storageHasAttic: false,
        storageHasElectra: false,
        storageHasHeating: false,
        storageHasWater: false,
        storageInsulationTypes: [],
      });

      this.form.update({
        storageRoomType: "",
        amenities: [],
        storageInsulationTypes: [],
      });
    }
  }

  private onToggleExpandedClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    active?: boolean
  ) {
    const expanded = active !== undefined ? active : !this.state.expanded;
    this.setState({ expanded });

    if (!this.bodyRef) return;

    if (!expanded) {
      this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
      setTimeout(() => {
        this.setState({ bodyStyles: { height: 0, overflow: "hidden" } });
      }, 1);
      setTimeout(() => {
        this.setState({ bodyStyles: null });
      }, 451);
    } else {
      this.setState({ bodyStyles: { height: this.bodyRef.clientHeight } });
      setTimeout(() => {
        this.setState({ bodyStyles: { height: "auto" } });
      }, 450);
    }
  }

  private onFormChange(values: FormReturnValue) {
    const amenities = values.amenities || [];

    this.props.onSubmit({
      isBarnStorageAvailable: this.state.active,
      storageRoomType: values.storageRoomType,
      storageHasAttic: amenities.indexOf(Amenities.Attic) !== -1,
      storageHasElectra: amenities.indexOf(Amenities.Electricity) !== -1,
      storageHasHeating: amenities.indexOf(Amenities.CentralHeating) !== -1,
      storageHasWater: amenities.indexOf(Amenities.Water) !== -1,
      storageInsulationTypes: values.storageInsulationTypes,
    });
  }
}
