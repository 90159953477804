import {
  AssignmentSnapShot,
  CommunicationLog,
  ObjectAssignment,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { Account } from "@haywork/api/mail";
import {
  AssignmentThunks,
  Dispatch,
  EmailMessageThunks,
  RelationThunks,
} from "@haywork/middleware";
import { EmailLinkedEntitiesComponentProps } from "@haywork/modules/email";
import EmailLinkedEntitiesComponent from "@haywork/modules/email/components/linked-entities/linked-entities.component";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  currentAccount: Account;
  currentFolder: ExtendedEmailFolder;
}

interface DispatchProps {
  getCommunicationLog: (
    communicationLogId: string
  ) => Promise<CommunicationLog>;
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    communicationLog?: CommunicationLog
  ) => Promise<CommunicationLog>;
  getRelationsWithMatchingEmailAddress: (
    emailAddresses: string[],
    relationTypes: RelationType[]
  ) => Promise<RelationSnapShot[]>;
  navigate: (url: string) => void;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  removeCommunicationLog: (id: string) => void;
  getLinkedAssignmentRelations: (id: string) => Promise<ObjectAssignment>;
}

const mapStateToProps = <StateProps, EmailLinkedEntitiesComponentProps>(
  state: AppState
) => {
  const { currentAccount } = state.email.accounts;
  const { currentFolder } = state.email.folders;

  return {
    currentAccount,
    currentFolder,
  };
};

const mapDispatchToProps = <DispatchProps, EmailLinkedEntitiesComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getCommunicationLog: (communicationLogId: string) =>
    dispatch(EmailMessageThunks.getCommunicationLog(communicationLogId)),
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    message: EmailMessage,
    communicationLog?: CommunicationLog
  ) =>
    dispatch(
      EmailMessageThunks.saveCommunicationLog(
        linkedRelations,
        linkedAssignments,
        message,
        communicationLog,
        null,
        true
      )
    ),
  getRelationsWithMatchingEmailAddress: (
    emailAddresses: string[],
    relationTypes: RelationType[]
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        emailAddresses,
        relationTypes
      )
    ),
  navigate: (route: string) => dispatch(push(route)),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  removeCommunicationLog: (id: string) =>
    dispatch(EmailMessageThunks.deleteCommunicationLog(id)),
  getLinkedAssignmentRelations: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
});

export type EmailLinkedEntitiesContainerProps = StateProps & DispatchProps;
export const EmailLinkedEntitiesContainer = connect<
  StateProps,
  DispatchProps,
  EmailLinkedEntitiesComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailLinkedEntitiesComponent);
