import { Account, ReauthenticateAccountRequest } from "@haywork/api/mail";
import {
  Form,
  FormControls,
  FormReference,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./re-authenticate-modal.component.scss");

interface Props {
  account: Account;
  onClose: () => void;
  onReAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
}
interface State {
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ReAuthOtherComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.state = {
      loading: false,
    };

    this.formControls = {
      accountId: { value: this.props.account.id },
      username: { value: this.props.account.emailAddress },
      password: {
        value: "",
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    return (
      <div styleName="other">
        <div styleName="other__body">
          <ResourceText
            resourceKey="emailReAuthOtherInfo"
            values={{ email: this.props.account.emailAddress }}
            asHtml
          />
        </div>
        <div styleName="other__form">
          <Form
            name="email-re-auth-other"
            formControls={this.formControls}
            form={(ref) => (this.form = ref)}
            onSubmit={this.onSubmitHandler}
          >
            <div className="form__row">
              <label htmlFor="password">
                <ResourceText resourceKey="emailAccountPassword" />
              </label>
              <Input.Password
                name="password"
                canToggle
                disabled={this.state.loading}
              />
            </div>
          </Form>
        </div>
        <div styleName="footer" className="flex">
          <div className="flex justify-start flex-grow-1">
            <button
              type="button"
              className="btn btn-blank"
              onClick={this.props.onClose}
              disabled={this.state.loading}
            >
              <ResourceText resourceKey="IllDoThisLater" />
            </button>
          </div>
          <div className="flex justify-end flex-grow-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.form.submit()}
            >
              <ButtonLoader
                resourceKey="connect"
                loading={this.state.loading}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private async onSubmitHandler(values: ReauthenticateAccountRequest) {
    if (this.state.loading) return;

    this.setState({
      loading: true,
    });

    try {
      const authenticated = await this.props.onReAuthenticate(values);

      this.props.onClose();
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }
}
