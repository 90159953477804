import { LinkedEmployee, Office } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { Dispatch, OfficeThunk } from "@haywork/middleware";
import { OfficeEmployeeComponent } from "@haywork/modules/settings/modules/offices/components/employees/office-employee.component";
import { AppState } from "@haywork/stores";
import { OfficeActions } from "@haywork/stores/offices/office.action";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  office: Office;
  officeEmployeeInitialOverviewState: string;
  linkedEmployees: LinkedEmployee[];
  host: string;
  realEstateAgencyId: string;
  totalEmployeeCount: number;
  selectedOfficeDisplayName?: string;
  contactAddError: boolean;
  employeeSaveState: string;
}

interface DispatchProps {
  saveContactLink: (employeeId: string, office: Office) => void;
  getOfficeInfo: (officeID: string) => void;
  clearEmployeeList: (office: Office) => void;
  goToEmployeeDetail: (routePathString) => void;
  onOfficeEmployeeArchive: (office: Office, employeeId: string) => void;
  onOfficeEmployeeUnarchive: (office: Office, employeeId: string) => void;
  removeEmployeeFromList: (office: Office, employeeId: string) => void;
  resetSaveStatus: () => void;
  navigate: (route: string) => void;
}

const mapStateToProps = <StateProps, OfficeEmployeeComponentProps>(
  state: AppState
) => ({
  linkedEmployees: state.offices.selectedOffice.linkedEmployees,
  totalEmployeeCount: state.offices.selectedOffice.linkedEmployees.length,
  office: state.offices.selectedOffice,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  host: state.appSettings.host,
  officeEmployeeInitialOverviewState:
    state.offices.officeEmployeeInitialOverviewState,
  selectedOfficeDisplayName: state.offices.selectedOffice.name,
  contactAddError: state.offices.contactAddError,
  employeeSaveState: state.offices.officeEmployeeSaveState
});

const mapDispatchToProps = <DispatchProps, OfficeEmployeeComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveContactLink: (employeeId: string, office: Office) =>
    dispatch(OfficeThunk.addOfficeToEmployee(employeeId, office)),
  clearEmployeeList: (office: Office) =>
    dispatch(OfficeActions.clearEmployeeList(office)),
  getOfficeInfo: (officeId: string) =>
    dispatch(OfficeThunk.getOfficeInfo(officeId)),
  goToEmployeeDetail: (routePath: string) => dispatch(push(routePath)),
  onOfficeEmployeeArchive: (office: Office, employeeId: string) =>
    dispatch(OfficeThunk.archiveOfficeEmployee(office, employeeId)),
  onOfficeEmployeeUnarchive: (office: Office, employeeId: string) =>
    dispatch(OfficeThunk.unarchiveOfficeEmployee(office, employeeId)),
  removeEmployeeFromList: (office: Office, employeeId: string) =>
    dispatch(OfficeActions.archiveOfficeEmployee({ office, employeeId })),
  resetSaveStatus: () =>
    dispatch(OfficeActions.setOfficeEmployeeSaveState(REQUEST.IDLE)),
  navigate: (route: string) => dispatch(push(route))
});

export type OfficeEmployeeContainerProps = StateProps & DispatchProps;
export const OfficeEmployeeContainer = connect<
  StateProps,
  DispatchProps,
  OfficeEmployeeContainerProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OfficeEmployeeComponent);
