import { COUNTS, REDUX, REQUEST } from "@haywork/constants";
import { EmailMessage } from "@haywork/stores/email-v2";
import { IdentifierPayload } from "@haywork/stores/helpers";
import differenceBy from "lodash-es/differenceBy";
import { Action } from "redux";
import * as ActionType from "./messages.types";

export interface MessagesState {
  messages: EmailMessage[];
  currentMessage: EmailMessage;
  currentMessageStatus: string;
  searchFilters: {
    term: string;
    unread: boolean;
    bookmarked: boolean;
    hasAttachment: boolean;
  };
}

const INITIAL_STATE: MessagesState = {
  messages: [],
  currentMessage: null,
  currentMessageStatus: REQUEST.IDLE,
  searchFilters: {
    term: undefined,
    unread: undefined,
    bookmarked: undefined,
    hasAttachment: undefined
  }
};

export const messagesReducer = (
  state: MessagesState = INITIAL_STATE,
  action: Action
): MessagesState => {
  switch (action.type) {
    case REDUX.EMAILV2.APPEND_MESSAGES: {
      const { messages: appendables } = <ActionType.MessagesPayload>action;
      let messages = differenceBy(
        state.messages,
        appendables,
        (message) => message.id
      );

      messages = [...messages, ...appendables];

      return {
        ...state,
        messages
      };
    }
    case REDUX.EMAILV2.SET_MESSAGE: {
      const { message: currentMessage } = <ActionType.MessagePayload>action;
      const messages = state.messages.map((message) => {
        if (message.id === currentMessage.id) {
          return currentMessage;
        }
        return message;
      });

      return {
        ...state,
        messages,
        currentMessage,
        currentMessageStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.EMAILV2.SET_MESSAGE_STATUS: {
      const { currentMessageStatus } = <ActionType.CurrentMessageStatus>action;

      return {
        ...state,
        currentMessageStatus
      };
    }
    case REDUX.EMAILV2.APPEND_MESSAGE: {
      const { message: appendable } = <ActionType.MessagePayload>action;
      const messages = state.messages.reduce(
        (state, message) => {
          if (message.id !== appendable.id) {
            state.push(message);
          }
          return state;
        },
        [appendable]
      );

      return {
        ...state,
        messages
      };
    }
    case REDUX.EMAILV2.UPDATE_MESSAGE: {
      const { message: extendedMessage } = <ActionType.MessagePayload>action;
      const messages = state.messages.map((message) => {
        if (message.id === extendedMessage.id) {
          return extendedMessage;
        }
        return message;
      });

      let currentMessage = state.currentMessage;
      if (!!currentMessage && extendedMessage.id === currentMessage.id) {
        currentMessage = extendedMessage;
      }

      return {
        ...state,
        currentMessage,
        currentMessageStatus: REQUEST.SUCCESS,
        messages
      };
    }
    case REDUX.EMAILV2.DELETE_MESSAGE: {
      const { id } = <IdentifierPayload>action;
      const messages = state.messages.filter((message) => message.id !== id);

      return {
        ...state,
        messages
      };
    }
    case REDUX.EMAILV2.SET_MESSAGE_BOOKMARK_STATUS: {
      const { id, bookmarked } = <ActionType.Bookmarked>action;
      const messages = state.messages.map((message) => {
        if (message.id === id) {
          return {
            ...message,
            bookmarked
          };
        }

        return message;
      });

      let currentMessage = state.currentMessage;
      if (!!currentMessage && currentMessage.id === id) {
        currentMessage = {
          ...currentMessage,
          bookmarked
        };
      }

      return {
        ...state,
        currentMessage,
        messages
      };
    }
    case REDUX.EMAILV2.SET_MESSAGE_UNREAD_STATUS: {
      const { id, unread } = <ActionType.Unread>action;
      const messages = state.messages.map((message) => {
        if (message.id === id) {
          return {
            ...message,
            unread
          };
        }

        return message;
      });

      let currentMessage = state.currentMessage;
      if (!!currentMessage && currentMessage.id === id) {
        currentMessage = {
          ...currentMessage,
          unread
        };
      }

      return {
        ...state,
        currentMessage,
        messages
      };
    }
    case REDUX.EMAILV2.SET_FILTER_TERM: {
      const { term } = <ActionType.Term>action;

      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          term
        }
      };
    }
    case REDUX.EMAILV2.FILTER_MESSAGES: {
      const { unread, bookmarked, hasAttachment } = <ActionType.Filters>action;

      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          unread,
          bookmarked,
          hasAttachment
        }
      };
    }
    case REDUX.EMAILV2.REMOVE_ACCOUNT: {
      const { id } = <IdentifierPayload>action;
      const messages = state.messages.filter(
        (message) => message.accountId !== id
      );

      let currentMessage = null;
      let currentMessageStatus = REQUEST.IDLE;

      if (!!state.currentMessage && state.currentMessage.accountId !== id) {
        currentMessage = state.currentMessage;
        currentMessageStatus = state.currentMessageStatus;
      }

      return {
        ...state,
        messages,
        currentMessage,
        currentMessageStatus
      };
    }
    case REDUX.EMAILV2.SET_CURRENT_FOLDER: {
      const { folder: currentFolder } = <ActionType.FolderPayload>action;

      const folderIds: string[] = [];
      const folders: {
        folderId: string;
        messages: EmailMessage[];
      }[] = state.messages.reduce((state, message) => {
        const idx = folderIds.indexOf(message.folderId);
        if (idx === -1) {
          folderIds.push(message.folderId);
          state.push({
            folderId: message.folderId,
            messages: [message]
          });
        } else {
          state[idx].messages.push(message);
        }

        return state;
      }, []);

      const messages: EmailMessage[] = folders.reduce((state, folder) => {
        const messages =
          folder.folderId === currentFolder.id
            ? folder.messages
            : folder.messages.slice(0, COUNTS.EMAIL_MESSAGES);

        return [...state, ...messages];
      }, []);

      return {
        ...state,
        messages
      };
    }
    case REDUX.EMAILV2.CLEAR_CURRENT_MESSAGE: {
      return {
        ...state,
        currentMessage: null,
        currentMessageStatus: REQUEST.IDLE
      };
    }
    case REDUX.EMAILV2.CLEAR_ALL_MESSAGES:
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
