import { RootEntityType } from "@haywork/api/event-center";
import {
  AgendaItemCategory,
  AgendaItemCategoryTypeOption,
  AgendaItemReminderMergeFieldOption,
  AgendaStandardDurationOption,
} from "@haywork/api/kolibri";
import { SCHEDULERCATEGORIESROUTES } from "@haywork/constants";
import {
  MapDispatchToProps,
  SchedulerCategoriesThunk,
} from "@haywork/middleware";
import { editable } from "@haywork/modules/editable";
import { AppState, SchedulerCategoryActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsSchedulerEditComponent,
  SettingsSchedulerEditComponentProps,
} from "../components/edit/edit.component";
import { standardDurations } from "./selectors";

interface StateProps {
  agendaItemCategory: AgendaItemCategory;
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  agendaItemReminderMergeFieldOptions: AgendaItemReminderMergeFieldOption[];
}
interface DispatchProps {
  saveCategory: (agendaItemCategory: AgendaItemCategory) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsSchedulerEditComponentProps,
  AppState
> = (state) => {
  const { currentComponentState: agendaItemCategory } = state.editable;
  const {
    agendaItemCategoryTypes,
    agendaItemReminderMergeFieldOptions,
  } = state.main.mastertable.kolibri;

  return {
    agendaItemCategory,
    agendaItemCategoryTypes,
    agendaStandardDurationOptions: standardDurations(state),
    agendaItemReminderMergeFieldOptions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsSchedulerEditComponentProps
> = (dispatch) => ({
  saveCategory: (agendaItemCategory: AgendaItemCategory) =>
    dispatch(SchedulerCategoriesThunk.saveCategory(agendaItemCategory)),
});

export type SettingsSchedulerEditContainerProps = StateProps & DispatchProps;
export const SettingsSchedulerEditContainer = editable(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsSchedulerEditComponent),
  {
    entityType: RootEntityType.Agendaitem,
    icon: "calendar-alt",
    thunk: SchedulerCategoriesThunk.readCategory,
    status: "schedulerCategory.schedulerCategoryDetailStatus",
    statePath: "schedulerCategory.agendaItemCategory",
    action: SchedulerCategoryActions.updateAgendaItemCategory,
    route: SCHEDULERCATEGORIESROUTES.DETAIL.URI,
    confirm: {
      title: { key: "agendaCategoryConfirmTitle" },
      body: { key: "agendaCategoryConfirmBody" },
    },
  }
);
