import * as React from "react";
import * as CSSModules from "react-css-modules";
import maxBy from "lodash-es/maxBy";
import pick from "lodash-es/pick";

import { Ui } from "@haywork/modules/ui";
import { ResourceText } from "@haywork/modules/shared";
import { AppClientStatistics } from "@haywork/api/authorization";

const styles = require("./reviews.component.scss");

interface Props {
  statistics: AppClientStatistics;
}

export const ReviewVisual = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const {
      amountOfFiveStarRatings,
      amountOfFourStarRatings,
      amountOfOneStarRatings,
      amountOfThreeStarRatings,
      amountOfTwoStarRatings,
      averageRating,
      amountOfReviews
    } = props.statistics;
    const scores = [
      { key: 5, value: amountOfFiveStarRatings },
      { key: 4, value: amountOfFourStarRatings },
      { key: 3, value: amountOfThreeStarRatings },
      { key: 2, value: amountOfTwoStarRatings },
      { key: 1, value: amountOfOneStarRatings }
    ];

    const max = maxBy(scores, (score) => score.value);
    const mappedScores = scores.map((score) => ({
      ...score,
      percentage: Math.ceil((score.value / (max.value || 1)) * 100)
    }));

    return (
      <div styleName="visual">
        <div styleName="visual__general">
          <div styleName="widget">
            <div styleName="inner">
              <Ui.Score score={averageRating} />
            </div>
            <div styleName="label">
              <ResourceText
                resourceKey="appXchangeReviews"
                values={{ reviews: amountOfReviews }}
              />
            </div>
          </div>
        </div>

        <div styleName="visual__list">
          {mappedScores.map((score, idx) => (
            <div styleName="score" key={idx}>
              <div styleName="score__label">
                <i className="fa fa-star" /> {score.key}
              </div>
              <div styleName="score__bar">
                <div
                  styleName="score__bar-inner"
                  style={{ minWidth: `${score.percentage}%` }}
                >
                  {score.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);
