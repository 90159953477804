import { SettingField, SettingsFieldType } from "@haywork/api/authorization";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  field: SettingField;
}

export const AppXChangeSettingsInputComponent = CSSModules(styles, {
  allowMultiple: true,
})(({ field }: Props) => {
  if (!field || !field.linkedSettingFieldDefinition) return null;
  const {
    type,
    label,
    description,
    minValue,
    maxValue,
    maxLength,
    listOptions,
    readOnly,
  } = field.linkedSettingFieldDefinition;

  switch (type) {
    case SettingsFieldType.Decimal:
    case SettingsFieldType.Number: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Number
            name={field.fieldName}
            placeholder={description}
            pretty={type === SettingsFieldType.Price}
            min={minValue}
            max={maxValue}
            readOnly={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.Price: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <div className="input__helper">
            <div className="pre">&euro;</div>
            <Input.Number
              name={field.fieldName}
              placeholder={description}
              pretty={type === SettingsFieldType.Price}
              min={minValue}
              max={maxValue}
              readOnly={readOnly}
            />
          </div>
        </div>
      );
    }
    case SettingsFieldType.SingleLineText: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Text
            name={field.fieldName}
            placeholder={description}
            maxLength={maxLength}
            readOnly={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.MultiLineText: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Textarea
            name={field.fieldName}
            placeholder={description}
            autoSize={true}
            maxCount={maxLength}
            readOnly={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.Date: {
      return (
        <div styleName="input date">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Datepicker
            name={field.fieldName}
            placeholder={description}
            disabled={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.DateTime: {
      return (
        <div styleName="input datetime">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <div className="form__group">
            <div className="column" styleName="date">
              <Input.Datepicker
                name={field.fieldName}
                placeholder={description}
                disabled={readOnly}
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Time
                name={`combinedTime.${field.fieldName}`}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      );
    }
    case SettingsFieldType.SingleSelect: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.NewSelect
            name={field.fieldName}
            values={listOptions}
            addEmptyOption
            displayProp="displayName"
            valuesProp="value"
            disabled={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.MultiSelect: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Multi
            name={field.fieldName}
            values={listOptions || []}
            disabled={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.Boolean: {
      return (
        <div styleName="input">
          <Input.Switch
            name={field.fieldName}
            label={label}
            on={true}
            off={false}
            disabled={readOnly}
          />
        </div>
      );
    }
    case SettingsFieldType.RgbValue: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.Color name={field.fieldName} type="sketch" />
        </div>
      );
    }
    case SettingsFieldType.Base64Image: {
      return (
        <div styleName="input">
          {!!label && <label htmlFor={field.fieldName}>{label}</label>}
          <Input.ImageBase64
            name={field.fieldName}
            placeholder={description}
            disabled={readOnly}
            maxWidth={field.linkedSettingFieldDefinition?.maxWidth}
            maxHeight={field.linkedSettingFieldDefinition?.maxHeight}
          />
        </div>
      );
    }
    default: {
      return null;
    }
  }
});
