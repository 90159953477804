export enum EditableCalleeType {
  SearchAssignmentLinkedRelation = "SearchAssignmentLinkedRelation",
  ObjectAssignmentLinkedVendor = "ObjectAssignmentLinkedVendor",
  ObjectAssignmentLinkedApplicant = "ObjectAssignmentLinkedApplicant",
  ObjectAssignmentAro = "ObjectAssignmentAro",
  ProjectAssignmentLinkedVendor = "ProjectAssignmentLinkedVendor",
  ProjectAssignmentLinkedApplicant = "ProjectAssignmentLinkedApplicant",
  ProjectAssignmentAro = "ProjectAssignmentAro",
  SchedulerLinkedRelation = "SchedulerLinkedRelation",
  TaskLinkedRelation = "TaskLinkedRelation",
  RelationLinkedRelation = "RelationLinkedRelation",
  InvoiceLinkedRelation = "InvoiceLinkedRelation",

  DynamicDocumentLinkedVendor = "DynamicDocumentLinkedVendor",
  DynamicDocumentLinkedApplicant = "DynamicDocumentLinkedApplicant",
  DynamicDocumentLinkedPropertyManager = "DynamicDocumentLinkedPropertyManager",
  DynamicDocumentLinkedNotary = "DynamicDocumentLinkedNotary",

  DynamicDocumentCadastre = "DynamicDocumentCadastre"
}
