import {
  ComfortQualityOption,
  ConditionOption,
  EnergySourceOption,
  HeatingMethodOption,
  HeatingWaterMethodOption,
  IsolationTypeOption,
  ObjectTypeAssignment,
  OwnershipOption,
  ParkingFacilityOption,
  ResidentialFacilityOption,
  RoofMaterialOption,
  RoofTypeOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ObjectTypesEditSpecsComponent,
  ObjectTypesEditSpecsComponentProps,
} from "@haywork/modules/object-types";
import { AppState, EditableActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  editableCurrentDestinationText,
  editableCurrentUsageText,
  path,
} from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  isolationTypeOptions: IsolationTypeOption[];
  heatingMethodOptions: HeatingMethodOption[];
  heatingWaterMethodOptions: HeatingWaterMethodOption[];
  energySourceOptions: EnergySourceOption[];
  ownershipOptions: OwnershipOption[];
  comfortQualityOptions: ComfortQualityOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  conditionOptions: ConditionOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  residentialFacilityOptions: ResidentialFacilityOption[];
  path: string;
  currentDestinationText: string;
  currentUsageText: string;
}
interface DispatchProps {
  navigate: (path: string) => void;
  updateCurrentType: (
    componentState: ObjectTypeAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, ObjectTypesEditSpecsComponentProps>(
  state: AppState
) => {
  const { currentComponentState: currentType } = state.editable;
  const {
    isolationTypeOptions,
    heatingMethodOptions,
    heatingWaterMethodOptions,
    energySourceOptions,
    ownershipOptions,
    comfortQualityOptions,
    roofTypeOptions,
    roofMaterialOptions,
    conditionOptions,
    parkingFacilityOptions,
    residentialFacilityOptions,
  } = state.main.mastertable.kolibri;

  return {
    currentType,
    isolationTypeOptions,
    heatingMethodOptions,
    heatingWaterMethodOptions,
    energySourceOptions,
    ownershipOptions,
    comfortQualityOptions,
    roofTypeOptions,
    roofMaterialOptions,
    conditionOptions,
    parkingFacilityOptions,
    residentialFacilityOptions,
    path: path(state),
    currentDestinationText: editableCurrentDestinationText(state),
    currentUsageText: editableCurrentUsageText(state),
  };
};

const mapDispatchToProps = <DispatchProps, ObjectTypesEditSpecsComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (path: string) => dispatch(push(path)),
  updateCurrentType: (componentState: ObjectTypeAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type ObjectTypesEditSpecsContainerProps = StateProps & DispatchProps;
export const ObjectTypesEditSpecsContainer = connect<
  StateProps,
  DispatchProps,
  ObjectTypesEditSpecsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesEditSpecsComponent);
