import {
  CivilStateOption,
  ContactPerson,
  CountryOption,
  EmailAddressTypeOption,
  IdentificationTypeOption,
  PhoneNumberTypeOption,
  SocialMediaType
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { RELATIONROUTES } from "@haywork/constants";
import { PinnedNotes } from "@haywork/modules/notes-v3";
import { MailTo } from "@haywork/modules/shared";
import { ColorUtil, RelationUtil, RouteUtil } from "@haywork/util";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

interface ContactPersonReadOnlyComponentProps {
  contactPerson: ContactPerson;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
  civilStates: CivilStateOption[];
  identificationTypes: IdentificationTypeOption[];
  navigate: (routePath: string) => void;
}
interface ContactPersonReadonlyComponentState {}

const styles = require("./relation-readonly.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class ContactPersonReadonlyComponent extends React.Component<
  ContactPersonReadOnlyComponentProps,
  ContactPersonReadonlyComponentState
> {
  constructor(props) {
    super(props);

    this.hasAddressOrPostalOrFutureAddress = this.hasAddressOrPostalOrFutureAddress.bind(
      this
    );
    this.hasAddress = this.hasAddress.bind(this);
    this.hasPostalAddress = this.hasPostalAddress.bind(this);
    this.hasFutureAddress = this.hasFutureAddress.bind(this);
  }

  public render() {
    const country = RelationUtil.getAddressCountryDisplayName(
      this.props.countries,
      this.props.contactPerson.address
    );
    const countryOfBirth = RelationUtil.getBirthCountryDisplayName(
      this.props.countries,
      this.props.contactPerson
    );

    const facebook = this.props.contactPerson.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Facebook
    );
    const linkedIn = this.props.contactPerson.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.LinkedIn
    );
    const youtube = this.props.contactPerson.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.YouTube
    );
    const twitter = this.props.contactPerson.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Twitter
    );

    const {
      displayName,
      gender,
      linkedRelationGroups,
      phoneNumbers,
      emailAddresses,
      address,
      postalAddress,
      postalAddressDiffersVisitAddress,
      futureAddress,
      futureAddressActivationDateTime,
      webAddress,
      socialMedia,
      socialSecurityNumber,
      dateOfBirth,
      civilState,
      cityOfBirth,
      linkedPartner,
      identificationCard,
      bankAccount
    } = this.props.contactPerson;

    return (
      <div styleName="readMode">
        <div styleName="name">
          <h1 className={styles.title}>{displayName}</h1>
          <div styleName="relationPillz">
            {this.props.contactPerson &&
              linkedRelationGroups &&
              linkedRelationGroups.map((linkedRelationGroup, idx) => {
                const color = ColorUtil.hexToRgb(linkedRelationGroup.backColor);
                const style = { borderColor: color, color };
                return (
                  <div styleName="relationPill" key={idx} style={style}>
                    <div styleName="relationPill__label">
                      {linkedRelationGroup.displayName}
                    </div>
                  </div>
                );
              })}
          </div>

          <PinnedNotes />
        </div>

        {!!gender && (
          <div styleName="itemContainer">
            <div styleName="item">
              <span className={styles.label}>
                <I18n value="gender" />
              </span>
              <I18n value={gender.toString()} prefix="gender" />
            </div>
          </div>
        )}

        {phoneNumbers.length > 0 && (
          <div styleName="itemContainer">
            {phoneNumbers.map((phoneNumber, index) => {
              const type = phoneNumber.type
                ? this.props.phoneNumberTypes.find(
                    (phoneNumberType) =>
                      phoneNumber.type === phoneNumberType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <a href={"tel:" + phoneNumber.number}>{phoneNumber.number}</a>
                </div>
              );
            })}
          </div>
        )}
        {emailAddresses.length > 0 && (
          <div styleName="itemContainer" className={styles.emailAddresses}>
            {emailAddresses.map((emailAddress, index) => {
              const type = emailAddress.type
                ? this.props.emailAddressTypes.find(
                    (emailAddressType) =>
                      emailAddress.type === emailAddressType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <MailTo email={emailAddress.address} />
                </div>
              );
            })}
          </div>
        )}

        {this.hasAddressOrPostalOrFutureAddress() && (
          <div styleName={"itemContainer" + " " + "addresses"}>
            {this.hasAddress() && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <I18n value="visitAddress" />
                </span>
                <div>
                  {!!address.street && (
                    <span styleName="line1">
                      {address.street.name} {address.houseNumber}{" "}
                      {address.houseNumberPostfix}
                    </span>
                  )}
                  {!!address.locality && (
                    <span styleName="line2">
                      {address.postalCode} {address.locality.name}
                      {country && (
                        <span styleName="country">
                          &nbsp;(
                          {country})
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}

            {this.hasPostalAddress() && !!postalAddressDiffersVisitAddress && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <I18n value="postalAddress" />
                </span>
                <div>
                  {!!postalAddress.street && (
                    <span styleName="line1">
                      {postalAddress.street.name} {postalAddress.houseNumber}{" "}
                      {postalAddress.houseNumberPostfix}
                    </span>
                  )}
                  {!!postalAddress.locality && (
                    <span styleName="line2">
                      {postalAddress.postalCode} {postalAddress.locality.name}
                      {country && (
                        <span styleName="country">
                          &nbsp;(
                          {country})
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}

            {this.hasFutureAddress() && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <I18n
                    value="futureAddress.title"
                    values={{
                      date: moment(futureAddressActivationDateTime).format(
                        "DD MMMM YYYY"
                      )
                    }}
                  />
                </span>
                <div>
                  {!!futureAddress.street && (
                    <span styleName="line1">
                      {futureAddress.street.name} {futureAddress.houseNumber}{" "}
                      {futureAddress.houseNumberPostfix}
                    </span>
                  )}
                  {!!futureAddress.locality && (
                    <span styleName="line2">
                      {futureAddress.postalCode} {futureAddress.locality.name}
                      {country && (
                        <span styleName="country">
                          &nbsp;(
                          {country})
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* WebAddress */}
        {webAddress ? (
          <div styleName="itemContainer">
            <div styleName={"item" + " " + "link"}>
              <span styleName="label">
                <I18n value="website" />
              </span>
              <a href={webAddress} target="_blank">
                {webAddress}
              </a>
            </div>
          </div>
        ) : (
          ""
        )}

        {dateOfBirth || civilState ? (
          <div styleName={"itemContainer" + " " + "dateOfBirth"}>
            {dateOfBirth ? (
              <div styleName="item">
                <span styleName="label">
                  <I18n value="bornOnCapital" />
                </span>
                <div>
                  <span styleName="date">
                    {moment(dateOfBirth).format("DD MMMM YYYY")}
                  </span>
                  {cityOfBirth ? (
                    <div>
                      <span>
                        <I18n value="in" />
                      </span>
                      <span className={styles.cityOfBirth}>{cityOfBirth}</span>
                      {countryOfBirth && (
                        <span className={styles.countryOfBirth}>
                          ({countryOfBirth})
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {civilState ? (
              <div className={styles.item}>
                <span className={styles.label}>
                  <I18n value="civilState" />
                </span>
                <div>
                  {
                    this.props.civilStates.find(
                      (state) => state.value === civilState
                    ).displayName
                  }
                </div>
              </div>
            ) : (
              ""
            )}

            {linkedPartner && linkedPartner.id ? (
              <div className={styles.item}>
                <span className={styles.label}>
                  <I18n value="partner" />
                </span>
                <div>
                  <a
                    onClick={() => {
                      this.props.navigate(
                        route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
                          id: linkedPartner.id
                        })
                      );
                    }}
                  >
                    {linkedPartner.displayName}
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {identificationCard || socialSecurityNumber ? (
          <div styleName="itemContainer">
            {identificationCard.type ? (
              <div styleName="item">
                <span styleName="label">
                  <I18n value="identityProof" />
                </span>
                <div>
                  {
                    this.props.identificationTypes.find(
                      (identificationType) =>
                        identificationType.value === identificationCard.type
                    ).displayName
                  }
                </div>
              </div>
            ) : (
              ""
            )}
            {identificationCard.number ? (
              <div styleName="item">
                <span styleName="label">
                  <I18n value="documentNumber" />
                </span>
                <div>{identificationCard.number}</div>
              </div>
            ) : (
              ""
            )}
            {socialSecurityNumber ? (
              <div styleName="item">
                <span styleName="label">
                  <I18n value="personNumber" />
                </span>
                <div>{socialSecurityNumber}</div>
              </div>
            ) : (
              ""
            )}
            {identificationCard.validUntilDate ? (
              <div styleName="item">
                <span styleName="label">
                  <I18n value="validUntil" />
                </span>
                <div>
                  <FormattedDate
                    value={identificationCard.validUntilDate}
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {bankAccount && bankAccount.number ? (
          <div styleName="itemContainer">
            <div styleName="item">
              <span styleName="label">
                <I18n value="IBANNumber" />
              </span>
              <div>{bankAccount.number}</div>
            </div>
          </div>
        ) : (
          ""
        )}

        {socialMedia.length > 0 && (
          <div styleName={"itemContainer" + " " + "socialMedia"}>
            <div className={styles.item}>
              <span className={styles.label}>
                <I18n value="socialMedia" />
              </span>
              {facebook && facebook.account && (
                <a
                  className={styles.facebook}
                  href={facebook.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-facebook-f" />
                  <span className="sr-only">
                    <I18n value="lowercaseFacebook" />
                  </span>
                </a>
              )}
              {linkedIn && linkedIn.account && (
                <a
                  className={styles.linkedin}
                  href={linkedIn.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-linkedin-in" />
                  <span className="sr-only">
                    <I18n value="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {youtube && youtube.account && (
                <a
                  className={styles.youtube}
                  href={youtube.account}
                  target="_blank"
                >
                  <i className="fab fa-youtube" />
                  <span className="sr-only">
                    <I18n value="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {twitter && twitter.account && (
                <a
                  className={styles.twitter}
                  href={twitter.account}
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                  <span className="sr-only">
                    <I18n value="lowercaseTwitter" />
                  </span>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private hasAddressOrPostalOrFutureAddress(): boolean {
    return (
      this.hasAddress() || this.hasPostalAddress() || this.hasFutureAddress()
    );
  }

  private hasAddress(): boolean {
    const { address } = this.props.contactPerson;
    if (!address) return false;
    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }

  private hasPostalAddress(): boolean {
    const { postalAddress: address } = this.props.contactPerson;
    if (!address) return false;
    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }

  private hasFutureAddress(): boolean {
    const {
      futureAddress: address,
      futureAddressActivationDateTime
    } = this.props.contactPerson;
    if (
      !address ||
      !futureAddressActivationDateTime ||
      moment().isAfter(moment(futureAddressActivationDateTime))
    )
      return false;

    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }
}
