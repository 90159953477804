import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { createSelector } from "reselect";

const templatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;
const categoriesSelector = (state: AppState) =>
  state.emailV2.templates.categories;

export const signatures = createSelector(
  [templatesSelector, categoriesSelector],
  (templates, categories) => {
    const signatureCategory = categories.find(
      (category) =>
        category.categoryType === TemplateDefinitionCategoryType.EmailSignature
    );
    if (!signatureCategory) return [];
    return templates.filter(
      (template) =>
        get(template.linkedTemplateDefinitionCategory, "id") ===
        signatureCategory.id
    );
  }
);
