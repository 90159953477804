import { FormElement } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import { Hint } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface TextareaComponentProps {
  formElement: FlattenedFormElement;
  value?: string;
  focus: boolean;
  onChange: (formElement: FormElement) => void;
  onFocus: (name: string) => void;
}
interface TextareaComponentState {
  value: string;
}

@CSSModules(styles, { allowMultiple: true })
export class TextareaComponent extends React.Component<
  TextareaComponentProps,
  TextareaComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
    };

    this.fireOnChange = debounce(this.fireOnChange.bind(this), 500);
    this.onChangehandler = this.onChangehandler.bind(this);
    this.onPhraseAddHandler = this.onPhraseAddHandler.bind(this);
  }

  public render() {
    const {
      name,
      preText,
      postText,
      maximumLength,
      phraseCategoryId,
      disabled,
    } = this.props.formElement;
    const inputClass = classNames("element__input block", {
      "has-phrases": !!phraseCategoryId,
    });

    return (
      <div styleName="element">
        {!!preText && <div styleName="element__pre block">{preText}</div>}
        <div styleName={inputClass}>
          <Hint message="fieldNotNeeded" shouldRender={disabled}>
            <Input.Textarea
              name={name}
              value={this.state.value}
              onChange={this.onChangehandler}
              maxLength={maximumLength}
              autoSize
              onFocus={() => this.props.onFocus(name)}
              fireAllChanges
              focus={this.props.focus}
              disabled={disabled}
              disableTrim
            />
          </Hint>
          {!!phraseCategoryId && !disabled && (
            <Ui.Phrases
              phraseCategoryId={phraseCategoryId}
              onPhraseAdd={this.onPhraseAddHandler}
              onPhraseClick={() => this.props.onFocus(name)}
            />
          )}
        </div>
        {!!postText && <div styleName="element__pre block">{postText}</div>}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: TextareaComponentProps) {
    if (!nextProps) return false;
    return (
      nextProps.focus !== this.props.focus ||
      nextProps.formElement.disabled !== this.props.formElement.disabled
    );
  }

  private onPhraseAddHandler(phrase: string) {
    const value = `${this.state.value}${phrase}`;
    this.onChangehandler(value);
  }

  private onChangehandler(value: string) {
    this.setState({ value });
    this.forceUpdate();

    const { name, type } = this.props.formElement;
    const formElement: FormElement = {
      name,
      type,
      textInfo: {
        value,
      },
    };

    this.fireOnChange(formElement);
  }

  private fireOnChange(formElement: FormElement) {
    this.props.onChange(formElement);
  }
}
