import { LinkedMessage } from "@haywork/api/event-center";
import { EventCenterThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SystemMessages,
  SystemMessagesComponentProps
} from "./system-messages";

interface StateProps {}
interface DispatchProps {
  getSystemMessages: () => Promise<LinkedMessage[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SystemMessagesComponentProps,
  AppState
> = (state) => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SystemMessagesComponentProps
> = (dispatch) => ({
  getSystemMessages: () => dispatch(EventCenterThunk.getSystemMessages(0, 5))
});

export type SystemMessagesContainerProps = StateProps & DispatchProps;
export const SystemMessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemMessages);
