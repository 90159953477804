import {
  AssignmentType,
  LinkedAssignment,
  RelationType,
  RelationSnapShot,
  ObjectAssignment,
  ProjectAssignment,
  AssignmentSnapShot,
  LinkedRelation,
  ActiveFilter,
  RealEstateGroup,
  AssignmentPhase,
} from "@haywork/api/kolibri";
import FormattedText from "@haywork/components/ui/formatted-text";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { useCallback, useEffect, useState, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentInfoContainerProps } from "./assignment-info.container";
import { Colors } from "@haywork/enum/colors";
import Pill from "@haywork/components/ui/pill";
import head from "lodash-es/head";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";
import { useIntl } from "react-intl";
import { Assignment, ExtendedAssignmentSnapshot } from "@haywork/request";
import { AssignmentStatusModalRentedComponent } from "@haywork/modules/assignment/components/status-modal";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentInfoComponentProps {
  item: AssignmentSnapShot & Partial<ExtendedAssignmentSnapshot>;
  visible: boolean;
  emailCallback?: (relation: RelationSnapShot | LinkedRelation) => void;
}
type Props = AssignmentInfoComponentProps & AssignmentInfoContainerProps;

export const AssignmentInfoComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, navigate, emailCallback, visible }: Props) => {
      const [stateItem, setStateItem] = useState(item);
      const intl = useIntl();

      const mappedRelation = (relation: LinkedRelation, type: string) => {
        return (
          <div key={relation.id}>
            <FormattedText
              value={
                stateItem.forSale
                  ? `infoModal.${type}.sale`
                  : `infoModal.${type}.rent`
              }
              addStyle={{ display: "inline" }}
            />
            <span styleName="linkedItem">
              <a
                onClick={() =>
                  navigate(
                    route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
                      id: relation.id,
                    })
                  )
                }
              >
                {relation.displayName}
              </a>
              {relation.email && !emailCallback && (
                <span styleName="linkedItemEmail">
                  ({<MailTo email={relation.email} />})
                </span>
              )}
              {relation.email && emailCallback && (
                <span
                  styleName="linkedItemEmail"
                  onClick={() => emailCallback(relation)}
                >
                  ({relation.email})
                </span>
              )}
            </span>
          </div>
        );
      };

      const extendAssignmentInfoCallback = useCallback(
        async (id) => {
          try {
            if (!stateItem.id || stateItem.extended) return;
            const results = await Assignment.search(
              "",
              10,
              [],
              true,
              true,
              ActiveFilter.ActiveOrInactive,
              undefined,
              [id]
            );
            const result = head(results);
            if (!result) {
              setStateItem((item) => ({ ...item, extended: true }));
            } else {
              setStateItem((item) => ({ ...item, ...result, extended: true }));
            }
          } catch (error) {
            throw error;
          }
        },
        [setStateItem, stateItem]
      );

      useEffect(() => {
        if (!visible) return;
        extendAssignmentInfoCallback(item.id);
      }, [extendAssignmentInfoCallback, visible, item]);

      const realEstateGroupInfo = useMemo(() => {
        switch (stateItem.realEstateGroup) {
          case RealEstateGroup.Agricultural: {
            return <I18n value={"infoModal.linkedAcquisition.agricultural"} />;
          }
          case RealEstateGroup.Commercial: {
            return <I18n value={"infoModal.linkedAcquisition.commercial"} />;
          }
          case RealEstateGroup.Residential: {
            return <I18n value={"infoModal.linkedAcquisition.residential"} />;
          }
          default: {
            return <I18n value={"infoModal.linkedAcquisition.residential"} />;
          }
        }
      }, []);

      const assignmentPhaseInfo = useMemo(() => {
        switch (stateItem.assignmentPhase) {
          case AssignmentPhase.Initiated: {
            return <I18n value={"infoModal.linkedAcquisition.initiated"} />;
          }
          case AssignmentPhase.Concept: {
            return <I18n value={"infoModal.linkedAcquisition.concept"} />;
          }
          case AssignmentPhase.Completed: {
            return <I18n value={"infoModal.linkedAcquisition.completed"} />;
          }
          default: {
            return <I18n value={"infoModal.linkedAcquisition.initiated"} />;
          }
        }
      }, [stateItem]);

      return (
        <div styleName="info">
          <div styleName="header">
            <div styleName="name">
              <FormattedText
                value={stateItem.displayName}
                bold
                addStyle={{ fontSize: 14 }}
                skipTranslation
              />
            </div>
            {stateItem.publicReference && (
              <Pill
                label={stateItem.publicReference}
                color={stateItem.isActive ? Colors.Warning : Colors.Gray}
                tiny
              />
            )}
            {!stateItem.isActive && (
              <Pill label={"infoModal.archived"} color={Colors.Gray} tiny />
            )}
            <div styleName="data">
              {[
                AssignmentType.Acquisition,
                AssignmentType.AcquisitionObject,
              ].includes(stateItem.typeOfAssignment) ? (
                <>
                  {stateItem.linkedClients?.length > 0 && (
                    <div className={styles.acquisitionBody}>
                      <div className={styles.acquisitionLabel}>
                        <I18n value={"infoModal.acquisitionFor.label"} />
                      </div>
                      <div className={styles.acquisionFor}>
                        {stateItem?.linkedClients
                          ?.map((client) => client.displayName)
                          .join(", ")}
                      </div>
                    </div>
                  )}
                  <div className={styles.acquisitionBody}>
                    <div className={styles.acquisitionLabel}>
                      <I18n value={"infoModal.acquisitionType.label"} />
                    </div>

                    <div className={styles.acquisitionBody}>
                      {stateItem.forSale && (
                        <I18n value={"infoModal.type.sale"} />
                      )}
                      {stateItem.forRent && (
                        <I18n value={"infoModal.type.rent"} />
                      )}
                    </div>
                    <div>{realEstateGroupInfo}</div>
                  </div>

                  <div className={styles.acquisitionBody}>
                    <div className={styles.acquisitionLabel}>
                      <I18n value={"infoModal.acquisitionPhase.label"} />
                    </div>
                    <div>{assignmentPhaseInfo}</div>
                  </div>
                </>
              ) : null}

              {((!!stateItem.forSale && !!stateItem.salePrice) ||
                (!!stateItem.forRent && !!stateItem.rentPrice)) && (
                <span>
                  <I18n
                    value={
                      stateItem.forSale
                        ? "infoModal.price.sale"
                        : "infoModal.price.rent"
                    }
                    values={{
                      price: intl.formatNumber(
                        stateItem.forSale
                          ? stateItem.salePrice || 0
                          : stateItem.rentPrice || 0,
                        { style: "currency", currency: "EUR" }
                      ),
                    }}
                  />
                </span>
              )}
              {(stateItem.keyNote || stateItem.keyNr) && (
                <span>
                  <I18n value={"infoModal.keyInfo"}></I18n>
                  {`${stateItem.keyNr} ${
                    stateItem.keyNote ? `- ${stateItem.keyNote}` : ""
                  }`}
                </span>
              )}
              {stateItem.dateSold && (
                <span>
                  <I18n
                    value={"infoModal.dateSold"}
                    values={{
                      date: moment(stateItem.dateSold).format("DD-MM-YYYY"),
                    }}
                  ></I18n>
                </span>
              )}
              {stateItem.rentedFrom && (
                <span>
                  <I18n
                    value={"infoModal.rentedFrom"}
                    values={{
                      date: moment(stateItem.rentedFrom).format("DD-MM-YYYY"),
                    }}
                  ></I18n>
                </span>
              )}
            </div>
          </div>
          <div styleName="body">
            {stateItem.linkedVendors && stateItem.linkedVendors.length > 0 && (
              <div styleName="linkedItems">
                {stateItem.linkedVendors.map((relation) =>
                  mappedRelation(relation, "linkedVendors")
                )}
              </div>
            )}
            {stateItem.linkedApplicants &&
              stateItem.linkedApplicants.length > 0 && (
                <div styleName="linkedItems">
                  {stateItem.linkedApplicants.map((relation) =>
                    mappedRelation(relation, "linkedApplicants")
                  )}
                </div>
              )}
          </div>
        </div>
      );
    }
  )
);
