import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AssignmentType,
  LinkedAssignment,
  ObjectAssignment,
  RealEstateGroup,
  UpdateAvailabilityAction,
  AssignmentPhase,
  AvailabilityStatus,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import {
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  REQUEST,
} from "@haywork/constants";
import {
  AssignmentDetailGeneralContainerProps,
  ProlongRentOfferModal,
  RenewRentOfferModal,
  WindowPresentationPrintContainer,
} from "@haywork/modules/assignment";
import { BidWidgetForObjectAssignment } from "@haywork/modules/bids";
import { BidsObjectWidgetRef } from "@haywork/modules/bids/components/object-widget/object-widget";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import Notes, { PinnedNotes } from "@haywork/modules/notes-v3";
import {
  AuthorizationWidgets,
  ConfirmComponent,
  LinkedEmployeeComponent,
  LinkedEventComponent,
  LinkedRelationComponent,
  PartnersWidgetComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { ArrayUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import DuplicateModal from "../duplicate-modal";
import Actions, { AssignmentAction } from "./actions";
import {
  AgriculturalSpecifications,
  AssignmentDetailGeneralWidgetsPriceComponent,
  AssignmentDetailGeneralWidgetsStatusComponent,
  AssignmentWidgetCompanyListingsComponent,
  AssignmentWidgetSearchAssignmentsComponent,
  BogSpecifications,
  ResidentialSpecifications,
} from "./widgets";
import HideOnFunda from "./widgets/hide-on-funda";
import { RetractRentData } from "./widgets/retract-rent-data.component";
import { RetractSaleData } from "./widgets/retract-sales-data.component";
import KeyNumberModal from "../key-number-modal";

const styles = require("./detail-general.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const CONDITIONAL_STATUSES = [
  AvailabilityStatus.FarmedUnderCondition,
  AvailabilityStatus.LeasedUnderCondition,
  AvailabilityStatus.RentedUnderCondition,
  AvailabilityStatus.SoldUnderCondition,
];

export interface AssignmentDetailGeneralComponentProps {}
interface State {
  showConfirmDelete: boolean;
  showConfirmRetract: boolean;
  showConfirmRetractSale: boolean;
  showConfirmRetractRent: boolean;
  prolongRentOfferModalVisible: boolean;
  renewRentOfferModalVisible: boolean;
  printVisible: boolean;
  mediaPartnerAlertVisible: boolean;
  archiveAction: AssignmentAction;
  duplicateModalVisible: boolean;
  duplicateAction: AssignmentAction | null;
  withdrawFromConditionalStatusModalVisible: boolean;
  archiveWithKeyNumberVisible: boolean;
}
type Props = AssignmentDetailGeneralComponentProps &
  AssignmentDetailGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailGeneralComponent extends React.Component<
  Props,
  State
> {
  private bidWidget: BidsObjectWidgetRef;

  constructor(props) {
    super(props);

    this.state = {
      showConfirmDelete: false,
      showConfirmRetract: false,
      showConfirmRetractSale: false,
      showConfirmRetractRent: false,
      prolongRentOfferModalVisible: false,
      renewRentOfferModalVisible: false,
      printVisible: false,
      mediaPartnerAlertVisible: false,
      archiveAction: null,
      duplicateModalVisible: false,
      duplicateAction: null,
      withdrawFromConditionalStatusModalVisible: false,
      archiveWithKeyNumberVisible: false,
    };

    this.onConfirmDeleteCloseHandler =
      this.onConfirmDeleteCloseHandler.bind(this);
    this.onConfirmDeleteHandler = this.onConfirmDeleteHandler.bind(this);
    this.onShowDeleteConfirmHandler =
      this.onShowDeleteConfirmHandler.bind(this);
    this.onRetractClickHandler = this.onRetractClickHandler.bind(this);
    this.onConfirmRetractCloseHandler =
      this.onConfirmRetractCloseHandler.bind(this);
    this.onConfirmRetractHandler = this.onConfirmRetractHandler.bind(this);
    this.onChangeAvailabilityStatus =
      this.onChangeAvailabilityStatus.bind(this);
    this.onToggleProlongRentTerm = this.onToggleProlongRentTerm.bind(this);
    this.onToggleRenewRentOffer = this.onToggleRenewRentOffer.bind(this);
    this.onRetractSaleClickHandler = this.onRetractSaleClickHandler.bind(this);
    this.onPrintCloseHandler = this.onPrintCloseHandler.bind(this);
    this.onRetractRentClickHandler = this.onRetractRentClickHandler.bind(this);
    this.hideOnFundaChanged = this.hideOnFundaChanged.bind(this);
    this.checkIfAssignmentCanBeArchived =
      this.checkIfAssignmentCanBeArchived.bind(this);
    this.setMediaPartnerAlertVisible =
      this.setMediaPartnerAlertVisible.bind(this);
    this.onActionHandler = this.onActionHandler.bind(this);
    this.forceArchive = this.forceArchive.bind(this);
    this.cancelArchive = this.cancelArchive.bind(this);
    this.onCloseDuplicateModal = this.onCloseDuplicateModal.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.closeWithdrawFromConditionalStatus =
      this.closeWithdrawFromConditionalStatus.bind(this);
    this.withdrawFromConditionalStatus =
      this.withdrawFromConditionalStatus.bind(this);
    this.onRetractBidClickHandler = this.onRetractBidClickHandler.bind(this);
    this.archiveWithKeyNumber = this.archiveWithKeyNumber.bind(this);
    this.closeArchiveWithKeyNumber = this.closeArchiveWithKeyNumber.bind(this);
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      get(this.props.assignment, "id"),
      WidgetEntityType.ObjectAssignment
    );
  }

  public render() {
    const { assignment, companyListings, isInitial, printTemplates } =
      this.props;
    const {
      assignmentPhase,
      displayName,
      keyNr,
      keyNote,
      linkedApplicants,
      linkedVendors,
      linkedNotaries,
      linkedPropertyManagers,
      isActive,
      id,
      realEstateGroup,
      forSale,
      linkedEmployee,
      publicReference,
      isImported,
      linkedAppraisers,
      linkedFinancialAdvisors,
      linkedInspectors,
      linkedPurchasingBrokers,
      linkedContactPersons,
      saleOffer,
      availabilityStatus,
      rentOffer,
      isConfidential,
      address,
    } = assignment;

    const rentResourceKey =
      realEstateGroup === RealEstateGroup.Agricultural ? "forLease" : "forRent";
    const subTitle = !!assignmentPhase
      ? assignmentPhase.toString()
      : displayName || "";

    const sublocality = address?.sublocality?.name;

    return (
      <div styleName="general">
        <PageHeader
          title="pageTitle.assignment.general"
          subTitle={isActive ? subTitle : "archived"}
          subTitlePrefix={
            isActive && !!assignmentPhase ? "assignmentPhases" : null
          }
          actions={
            <>
              <Notes />
              <Button
                label="showEdit"
                category="success"
                onClick={() => this.props.edit(id)}
                disabled={isInitial}
              />
              <Actions
                assignment={assignment}
                printTemplates={printTemplates}
                isInitial={isInitial}
                canSendEmail={this.props.canSendEmail}
                enabledRealEstateGroups={this.props.enabledRealEstateGroups}
                canDuplicate={this.props.canDuplicate}
                onClick={this.onActionHandler}
              />
            </>
          }
        />

        <div styleName="general__body">
          {this.props.saveAssignmentState === REQUEST.PENDING && (
            <Ui.Loaders.Fullscreen mask />
          )}

          <div styleName="section detail" className="clearfix">
            <div styleName="assignment__info">
              {!!publicReference && (
                <div styleName="pubref">{publicReference}</div>
              )}
              {isImported && (
                <div styleName="imported">
                  <I18n value="imported" />
                </div>
              )}
              {!!isConfidential && (
                <div styleName="confidential">
                  <I18n value="confidential" />
                </div>
              )}
              <h2>
                <I18n
                  prefix="realEstateGroups"
                  value={realEstateGroup.toString()}
                />
                ,{" "}
                {forSale ? (
                  <I18n value="forSale" />
                ) : (
                  <I18n value={rentResourceKey} />
                )}
              </h2>
              <h1 data-cy="CY-ObjectAssignmentDetailTitle">
                {displayName}{" "}
                {sublocality && (
                  <span styleName="subscript">{sublocality}</span>
                )}
              </h1>
              <h3 data-cy="CY-ObjectAssignmentDetailHouseInfo">
                {this.renderHouseInfo()}
              </h3>
              {!!keyNr && (
                <div styleName="key">
                  <span styleName="number">
                    <i className="fal fa-key" /> {keyNr}
                  </span>
                  {!!keyNote && <span styleName="note">{keyNote}</span>}
                </div>
              )}
              <PinnedNotes />

              {this.props.assignment.realEstateGroup ===
                RealEstateGroup.Agricultural && (
                <ErrorBoundary>
                  <AgriculturalSpecifications
                    assignment={this.props.assignment}
                  />
                </ErrorBoundary>
              )}
              {this.props.assignment.realEstateGroup ===
                RealEstateGroup.Commercial && (
                <ErrorBoundary>
                  <BogSpecifications assignment={this.props.assignment} />
                </ErrorBoundary>
              )}
              {this.props.assignment.realEstateGroup ===
                RealEstateGroup.Residential && (
                <ErrorBoundary>
                  <ResidentialSpecifications
                    assignment={this.props.assignment}
                  />
                </ErrorBoundary>
              )}
            </div>

            <ErrorBoundary>
              <AssignmentDetailGeneralWidgetsStatusComponent
                assignment={this.props.assignment}
                isInitial={isInitial}
                notificationSettings={this.props.notificationSettings}
                onRetractClick={this.onRetractClickHandler}
                onRetractBidClick={this.onRetractBidClickHandler}
                onModifyClick={() => this.props.toggleStatusModal(true)}
                prolongRentTerm={this.onToggleProlongRentTerm}
                renewRentOffer={this.onToggleRenewRentOffer}
              />
            </ErrorBoundary>

            <FeatureSwitch feature="BIDS">
              <ErrorBoundary>
                <BidWidgetForObjectAssignment
                  assignmentId={id}
                  widgetRef={(ref) => (this.bidWidget = ref)}
                />
              </ErrorBoundary>
            </FeatureSwitch>

            <ErrorBoundary>
              <HideOnFunda
                objectAssignment={this.props.assignment}
                onChange={this.hideOnFundaChanged}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              {!!forSale ? (
                <RetractSaleData
                  saleOffer={saleOffer}
                  availability={availabilityStatus}
                  onRetractSaleOffer={this.onRetractSaleClickHandler}
                />
              ) : (
                <RetractRentData
                  rentOffer={rentOffer}
                  availability={availabilityStatus}
                  onRetractRentOffer={this.onRetractRentClickHandler}
                />
              )}
            </ErrorBoundary>

            <ErrorBoundary>
              <AssignmentDetailGeneralWidgetsPriceComponent
                assignment={this.props.assignment}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <AssignmentWidgetCompanyListingsComponent
                companyListings={companyListings}
                assignmentId={assignment.id}
                onClick={this.props.navigate}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <PartnersWidgetComponent
                assignmentPhase={this.props.assignment.assignmentPhase}
                id={this.props.assignment.id}
                isTemporarilyRemovedFromFunda={
                  this.props.assignment.isTemporarilyRemovedFromFunda
                }
                webAddress={this.props.assignment.webAddress}
                publications={this.props.mediaPartners}
                publicationsRoute={route(
                  ASSIGNMENTROUTES.EDIT_MARKETING_PARTNERS.URI,
                  { id }
                )}
              />
            </ErrorBoundary>
          </div>

          <div styleName="section meta" className="clearfix">
            {/* Linked employee */}
            {!!linkedEmployee && (
              <div styleName="meta__network">
                <div styleName="relations">
                  <h3 styleName="meta__title">
                    <I18n value="assignmentLinkedEmployee" />
                  </h3>
                  <LinkedEmployeeComponent
                    employee={linkedEmployee}
                    onClick={this.props.navigate}
                  />
                </div>
              </div>
            )}

            {/* Relations */}
            {ArrayUtil.arraysNotEmpty([
              linkedVendors,
              linkedApplicants,
              linkedNotaries,
              linkedPropertyManagers,
              linkedAppraisers,
              linkedFinancialAdvisors,
              linkedInspectors,
              linkedPurchasingBrokers,
              linkedContactPersons,
            ]) && (
              <div styleName="meta__network">
                {ArrayUtil.existsAndNotEmpty(linkedVendors) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value={forSale ? "sellers" : "rentees"} />
                    </h3>
                    {linkedVendors.map((linkedVendor, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedVendor}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedApplicants) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value={forSale ? "buyers" : "renters"} />
                    </h3>
                    {linkedApplicants.map((linkedApplicant, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedApplicant}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedNotaries) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="notaries" />
                    </h3>
                    {linkedNotaries.map((linkedNotary, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedNotary}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedPropertyManagers) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="propertyManagers" />
                    </h3>
                    {linkedPropertyManagers.map(
                      (linkedPropertyManager, idx) => (
                        <ErrorBoundary key={idx}>
                          <LinkedRelationComponent
                            relation={linkedPropertyManager}
                            onClick={this.props.navigate}
                          />
                        </ErrorBoundary>
                      )
                    )}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedAppraisers) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="linkedAppraisers" />
                    </h3>
                    {linkedAppraisers.map((linkedAppraiser, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedAppraiser}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedFinancialAdvisors) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="linkedFinancialAdvisors" />
                    </h3>
                    {linkedFinancialAdvisors.map(
                      (linkedFinancialAdvisor, idx) => (
                        <ErrorBoundary key={idx}>
                          <LinkedRelationComponent
                            relation={linkedFinancialAdvisor}
                            onClick={this.props.navigate}
                          />
                        </ErrorBoundary>
                      )
                    )}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedInspectors) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="linkedInspectors" />
                    </h3>
                    {linkedInspectors.map((linkedInspector, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedInspector}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedContactPersons) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="linkedContactPersons" />
                    </h3>
                    {linkedContactPersons.map((linkedContactPerson, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedRelationComponent
                          relation={linkedContactPerson}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}
                {ArrayUtil.existsAndNotEmpty(linkedPurchasingBrokers) && (
                  <div styleName="relations">
                    <h3 styleName="meta__title">
                      <I18n value="linkedPurchasingBrokers" />
                    </h3>
                    {linkedPurchasingBrokers.map(
                      (linkedPurchasingBroker, idx) => (
                        <ErrorBoundary key={idx}>
                          <LinkedRelationComponent
                            relation={linkedPurchasingBroker}
                            onClick={this.props.navigate}
                          />
                        </ErrorBoundary>
                      )
                    )}
                  </div>
                )}
                <div styleName="actions">
                  <NavLink to={route(ASSIGNMENTROUTES.NETWORK.URI, { id })}>
                    <I18n value="goToNetwork" />
                  </NavLink>
                </div>
              </div>
            )}

            {/* Relations empty state */}
            {!ArrayUtil.arraysNotEmpty([
              linkedVendors,
              linkedApplicants,
              linkedNotaries,
            ]) && (
              <div styleName="meta__network">
                <div styleName="relations">
                  <h3 styleName="meta__title">
                    <I18n value="linkedRelations" />
                  </h3>
                  <div styleName="empty-state">
                    <div styleName="body">
                      <I18n value="linkedRelationsEmptyState" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Authorization widgets */}
            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={WidgetEntityType.ObjectAssignment}
                location={WidgetEntityLocation.Sidebar}
                id={assignment.id}
              />
            </FeatureSwitch>

            {/* Searchers */}
            <AssignmentWidgetSearchAssignmentsComponent
              onNavigateToSearchAssignment={this.props.navigate}
              assignmentId={id}
              getObjectAssignmentSearchAssignments={(assignmentId) =>
                this.props.getObjectAssignmentSearchAssignments(
                  assignmentId,
                  assignmentPhase === AssignmentPhase.Concept
                )
              }
            />

            {/* Events */}
            {ArrayUtil.arraysNotEmpty([
              this.props.pastEvents,
              this.props.futureEvents,
            ]) && (
              <div styleName="meta__events">
                {ArrayUtil.existsAndNotEmpty(this.props.futureEvents) && (
                  <div styleName="list">
                    <h3 styleName="meta__title">
                      <I18n value="upcomingEvents" />
                    </h3>
                    {this.props.futureEvents.map((futureEvent, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedEventComponent
                          event={futureEvent}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}

                {ArrayUtil.existsAndNotEmpty(this.props.pastEvents) && (
                  <div styleName="list">
                    <h3 styleName="meta__title">
                      <I18n value="pastEvents" />
                    </h3>
                    {this.props.pastEvents.map((pastEvent, idx) => (
                      <ErrorBoundary key={idx}>
                        <LinkedEventComponent
                          event={pastEvent}
                          onClick={this.props.navigate}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )}

                <div styleName="actions">
                  <NavLink to={route(ASSIGNMENTROUTES.TIMELINE.URI, { id })}>
                    <I18n value="goToTimeline" />
                  </NavLink>
                </div>
              </div>
            )}

            {/* Events empty state */}
            {!ArrayUtil.arraysNotEmpty([
              this.props.pastEvents,
              this.props.futureEvents,
            ]) && (
              <div styleName="meta__events">
                <div styleName="list">
                  <h3 styleName="meta__title">
                    <I18n value="linkedEvents" />
                  </h3>
                  <div styleName="empty-state">
                    <div styleName="body">
                      <I18n value="linkedEventsEmptyState" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!!this.props.assignment.forRent && (
          <React.Fragment>
            <ProlongRentOfferModal
              visible={this.state.prolongRentOfferModalVisible}
              displayName={this.props.assignment.displayName}
              assignment={this.props.assignment}
              onClose={() => this.onToggleProlongRentTerm(false)}
            />

            <RenewRentOfferModal
              visible={this.state.renewRentOfferModalVisible}
              displayName={this.props.assignment.displayName}
              assignment={this.props.assignment}
              onClose={() => this.onToggleRenewRentOffer(false)}
            />
          </React.Fragment>
        )}

        <WindowPresentationPrintContainer
          assignment={this.props.assignment}
          visible={this.state.printVisible}
          onClose={this.onPrintCloseHandler}
        />

        <ConfirmComponent
          visible={this.state.showConfirmDelete}
          titleResourceKey="assignmentDeleteModalTitle"
          bodyResourceKey="assignmentDeleteModalMessage"
          onClose={this.onConfirmDeleteCloseHandler}
          onConfirm={this.onConfirmDeleteHandler}
        />

        <ConfirmComponent
          visible={this.state.showConfirmRetract}
          titleResourceKey="assignmentRetractConfirmTitle"
          bodyResourceKey="assignmentRetractConfirmMessage"
          onClose={this.onConfirmRetractCloseHandler}
          onConfirm={this.onConfirmRetractHandler}
        />

        <ConfirmComponent
          visible={this.state.showConfirmRetractSale}
          titleResourceKey="assignmentRetractSaleConfirmTitle"
          bodyResourceKey="assignmentRetractSaleConfirmMessage"
          onClose={this.onConfirmRetractCloseHandler}
          onConfirm={this.onConfirmRetractHandler}
        />

        <ConfirmComponent
          visible={this.state.showConfirmRetractRent}
          titleResourceKey="assignmentRetractRentConfirmTitle"
          bodyResourceKey="assignmentRetractRentConfirmMessage"
          onClose={this.onConfirmRetractCloseHandler}
          onConfirm={this.onConfirmRetractHandler}
        />

        <ConfirmComponent
          visible={this.state.mediaPartnerAlertVisible}
          titleResourceKey="assignment.error.mediaPartnerErrorOnArchiveTitle"
          bodyResourceKey="assignment.error.mediaPartnerErrorOnArchiveBody"
          onClose={this.cancelArchive}
          onConfirm={() => this.forceArchive(this.state.archiveAction)}
        />

        <ConfirmComponent
          visible={this.state.withdrawFromConditionalStatusModalVisible}
          titleResourceKey="assignment.confirm.withdrawFromConditionalStatus.title"
          bodyResourceKey="assignment.confirm.withdrawFromConditionalStatus.body"
          onClose={this.closeWithdrawFromConditionalStatus}
          onConfirm={this.withdrawFromConditionalStatus}
        />

        <DuplicateModal
          visible={this.state.duplicateModalVisible}
          hasKeyNr={!!keyNr}
          duplicateAction={this.state.duplicateAction}
          forSale={!!forSale}
          isActive={isActive}
          assignmentId={id}
          onClose={this.onCloseDuplicateModal}
        />

        <KeyNumberModal
          visible={this.state.archiveWithKeyNumberVisible}
          onClose={this.closeArchiveWithKeyNumber}
          onAction={this.archiveWithKeyNumber}
        />
      </div>
    );
  }

  private renderHouseInfo(): string {
    const { listingType, houseSort, houseType } = this.props.assignment;
    const listingTypeValue = !!listingType
      ? this.props.intl.formatMessage({
          id: `listingTypes.${listingType.toString()}`,
          defaultMessage: `listingTypes.${listingType.toString()}`,
        })
      : null;
    const houseSortValue = !!houseSort
      ? this.props.intl.formatMessage({
          id: `houseSorts.${houseSort.toString()}`,
          defaultMessage: `houseSorts.${houseSort.toString()}`,
        })
      : null;
    const houseTypeValue = !!houseType
      ? this.props.intl.formatMessage({
          id: `houseTypes.${houseType.toString()}`,
          defaultMessage: `houseTypes.${houseType.toString()}`,
        })
      : null;
    const houseInfo = [listingTypeValue, houseSortValue, houseTypeValue];

    return houseInfo.filter((value) => !!value).join(", ");
  }

  private onRetractClickHandler() {
    this.setState({ showConfirmRetract: true });
  }

  private onRetractBidClickHandler() {
    const { id } = this.props.assignment;

    this.props.updateAvailability(UpdateAvailabilityAction.ToAvailable, id);
  }

  private onRetractSaleClickHandler() {
    this.setState({ showConfirmRetractSale: true });
  }

  private onRetractRentClickHandler() {
    this.setState({ showConfirmRetractRent: true });
  }

  private hideOnFundaChanged(hideOnFundaUntil: Date | null) {
    const { assignment, currentComponentState } = this.props;

    const updatedObjectAssignment: ObjectAssignment = {
      ...assignment,
      hideOnFundaUntil,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
    this.props.saveAssignment();
  }

  private onShowDeleteConfirmHandler() {
    this.setState({ showConfirmDelete: true });
  }

  private onConfirmDeleteCloseHandler() {
    this.setState({ showConfirmDelete: false });
  }

  private onConfirmRetractCloseHandler() {
    this.setState({
      showConfirmRetract: false,
      showConfirmRetractSale: false,
      showConfirmRetractRent: false,
    });
  }

  private onConfirmDeleteHandler() {
    this.setState({ showConfirmDelete: false });
    this.props.deleteAssignment(this.props.assignment.id);
  }

  private onConfirmRetractHandler() {
    const { assignment } = this.props;
    this.setState({
      showConfirmRetract: false,
      showConfirmRetractSale: false,
      showConfirmRetractRent: false,
    });

    this.props.updateAvailability(
      UpdateAvailabilityAction.ToAvailable,
      assignment.id
    );
  }

  private onChangeAvailabilityStatus(status: UpdateAvailabilityAction) {
    this.props.updateAvailability(status, this.props.assignment.id);
  }

  private async onActionHandler(action: AssignmentAction) {
    const {
      id,
      displayName,
      linkedProjectAssignment,
      linkedObjectTypeAssignment,
      linkedEmployee,
      forRent,
      forSale,
      linkedVendors,
    } = this.props.assignment;
    const linkedAssignment: LinkedAssignment = {
      id,
      displayName,
      typeOfAssignment: AssignmentType.Object,
    };
    const projectId = get(linkedProjectAssignment, "id");
    const objectTypeId = get(linkedObjectTypeAssignment, "id");

    const linkedRelations = [...(linkedVendors || [])];

    switch (action) {
      case AssignmentAction.FinalizeRent:
      case AssignmentAction.FinalizeSale:
      case AssignmentAction.FinalizeSaleDirectly:
      case AssignmentAction.FinalizeRentDirectly:
      case AssignmentAction.ChangeTransactionData:
        return this.props.toggleStatusModal(true);
      case AssignmentAction.WithDraw:
        return this.withdraw();
      case AssignmentAction.Publish:
        return this.props.navigate(route(ASSIGNMENTROUTES.PUBLISH.URI, { id }));
      case AssignmentAction.Remove:
        return this.onShowDeleteConfirmHandler();
      case AssignmentAction.PlaceUnderOffer:
        return this.onChangeAvailabilityStatus(
          UpdateAvailabilityAction.ToUnderOffer
        );
      case AssignmentAction.PlaceUnderOption:
        return this.onChangeAvailabilityStatus(
          UpdateAvailabilityAction.ToUnderOption
        );
      case AssignmentAction.ToAvailable:
        return this.onChangeAvailabilityStatus(
          UpdateAvailabilityAction.ToAvailable
        );
      case AssignmentAction.AddBid:
        if (!!this.bidWidget) {
          this.bidWidget.update();
        }
        return;
      case AssignmentAction.Archive:
      case AssignmentAction.DuplicateAsRent: {
        if (!!forSale) {
          return this.forceArchive(action);
        } else {
          return this.checkIfAssignmentCanBeArchived(action);
        }
      }
      case AssignmentAction.DuplicateAsSale: {
        if (!!forRent) {
          return this.forceArchive(action);
        } else {
          return this.checkIfAssignmentCanBeArchived(action);
        }
      }
      case AssignmentAction.RenewRentOffer:
        return this.checkIfAssignmentCanBeArchived(action);
      case AssignmentAction.UnArchive: {
        return this.props.unArchive(id);
      }
      case AssignmentAction.ProlongRentOffer:
        return this.onToggleProlongRentTerm();
      case AssignmentAction.PrintWindowPresentation:
        return this.setState({ printVisible: true });
      case AssignmentAction.CreateTask: {
        return this.props.createTaskWithLinkedAssignment(linkedAssignment);
      }
      case AssignmentAction.CreateInvoice: {
        return this.props.createInvoiceWithLinkedAssignment(
          linkedRelations[0] || undefined,
          linkedAssignment
        );
      }
      case AssignmentAction.CreateAppointment: {
        return this.props.createAgendaItemWithLinkedAssignment(
          linkedRelations,
          linkedAssignment,
          linkedEmployee
        );
      }
      case AssignmentAction.CreateEmail: {
        return this.props.createNewEmail(linkedAssignment, displayName);
      }
      case AssignmentAction.OpenParentObjectType: {
        if (!objectTypeId) return;
        const path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: objectTypeId });
        return this.props.navigate(path);
      }
      case AssignmentAction.OpenParentProject: {
        if (!projectId) return;
        const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });
        return this.props.navigate(path);
      }
      default:
        return;
    }
  }

  private checkIfAssignmentCanBeArchived(action: AssignmentAction) {
    const { mediaPartners } = this.props;
    let { rejected } = mediaPartners;
    rejected = rejected.filter((partner) =>
      /tiara|atrium/gi.test(partner.mediaPartnerName || "")
    );

    if (!!rejected.length) {
      this.setMediaPartnerAlertVisible(true, action);
      return;
    }

    this.forceArchive(action);
  }

  private setMediaPartnerAlertVisible(
    mediaPartnerAlertVisible: boolean,
    archiveAction: AssignmentAction
  ) {
    this.setState({ mediaPartnerAlertVisible, archiveAction });
  }

  private onToggleProlongRentTerm(
    prolongRentOfferModalVisible: boolean = true
  ) {
    this.setState({ prolongRentOfferModalVisible });
  }

  private onToggleRenewRentOffer(renewRentOfferModalVisible: boolean = true) {
    this.setState({ renewRentOfferModalVisible });
  }

  private onPrintCloseHandler() {
    this.setState({ printVisible: false });
  }

  private archiveWithKeyNumber(keep: boolean) {
    this.closeArchiveWithKeyNumber();
    this.props.archive(this.props.assignment, keep);
  }

  private closeArchiveWithKeyNumber() {
    this.setState({ archiveWithKeyNumberVisible: false });
  }

  private forceArchive(archiveAction?: AssignmentAction) {
    const { id, keyNr, forSale, forRent, isActive } = this.props.assignment;
    const action = archiveAction || this.state.archiveAction;

    switch (action) {
      case AssignmentAction.Archive:
        if (!!keyNr) {
          this.setState({ archiveWithKeyNumberVisible: true });
        } else {
          this.props.archive(this.props.assignment);
        }
        break;
      case AssignmentAction.DuplicateAsRent:
        if (!!keyNr || (isActive && !!forRent)) {
          this.setState({
            duplicateModalVisible: true,
            duplicateAction: archiveAction,
          });
        } else {
          this.props.duplicateAssignment(id, false, false, false, false);
        }
        break;
      case AssignmentAction.DuplicateAsSale:
        if (!!keyNr || (isActive && !!forSale)) {
          this.setState({
            duplicateModalVisible: true,
            duplicateAction: archiveAction,
          });
        } else {
          this.props.duplicateAssignment(id, false, true, false, false);
        }
        break;
      case AssignmentAction.RenewRentOffer:
        this.onToggleRenewRentOffer();
        break;
      default:
        break;
    }

    this.cancelArchive();
  }

  private cancelArchive() {
    this.setState({ mediaPartnerAlertVisible: false, archiveAction: null });
  }

  private onCloseDuplicateModal() {
    this.setState({ duplicateModalVisible: false });
  }

  private withdraw() {
    const { availabilityStatus } = this.props.assignment;

    if (CONDITIONAL_STATUSES.includes(availabilityStatus)) {
      this.setState({ withdrawFromConditionalStatusModalVisible: true });
    } else {
      this.props.toggleWithdrawModal(true);
    }
  }

  private closeWithdrawFromConditionalStatus() {
    this.setState({ withdrawFromConditionalStatusModalVisible: false });
  }

  private async withdrawFromConditionalStatus() {
    this.closeWithdrawFromConditionalStatus();

    const { id } = this.props.assignment;
    await this.props.updateAvailability(
      UpdateAvailabilityAction.ToAvailable,
      id
    );

    this.props.toggleWithdrawModal(true);
  }
}
