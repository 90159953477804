import { REQUEST } from "@haywork/constants";
import {
  SearchAssignmentDetailLinkedAssignmentsContainer,
  SearchAssignmentEditLinkedAssignmentsContainerProps,
} from "@haywork/modules/search-assignment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import noop from "lodash-es/noop";

const styles = require("./edit-linked-assignments.component.scss");

export interface SearchAssignmentEditLinkedAssignmentsComponentProps {}
type Props = SearchAssignmentEditLinkedAssignmentsComponentProps &
  SearchAssignmentEditLinkedAssignmentsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentEditLinkedAssignmentsComponent extends React.Component<
  Props
> {
  constructor(props) {
    super(props);

    if (this.props.hasChanges) {
      this.props.saveSearchAssignment(this.props.searchAssignment);
    }
  }

  public render() {
    return (
      <div styleName="client">
        <div className="container-fluid">
          {this.props.saveSearchAssignmentStatus !== REQUEST.PENDING && (
            <SearchAssignmentDetailLinkedAssignmentsContainer
              selectedAssignmentsIds={[]}
              clearSelectedAssignments={noop}
              onToggleSelected={noop}
              inEditMode={true}
            />
          )}
        </div>
      </div>
    );
  }
}
