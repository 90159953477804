import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SimpleLabelOriginal } from "@haywork/util";

const filtersSelector = (state: AppState) => state.acquisition.list.filters;
const officesSelector = (state: AppState) => state.account.offices || [];
const employeesSelector = (state: AppState) => state.account.employees || [];

export const selectedFilters = createSelector(
  filtersSelector,
  officesSelector,
  employeesSelector,
  (filters, offices, employees) => {
    let selectedFilters: SimpleLabelOriginal[] = [];

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const values = filters[key] || [];
        let mappedValues: SimpleLabelOriginal[] = [];

        switch (key) {
          case "filterByRealEstateGroups": {
            mappedValues = values.map((item) => ({
              label: `realEstateGroups.${item}`,
              value: item,
              filter: key
            }));
            break;
          }
          case "assignmentPhases": {
            mappedValues = values.map((item) => ({
              label: `${key}.${item}`,
              value: item,
              filter: key
            }));
            break;
          }
          case "officeIds": {
            mappedValues = values.map((item) => {
              const ref = offices.find((office) => office.id === item);
              return { label: ref.displayName, value: ref.id, filter: key };
            });
            break;
          }
          case "employeeIds": {
            mappedValues = values.map((item) => {
              const ref = employees.find((employee) => employee.id === item);
              return { label: ref.displayName, value: ref.id, filter: key };
            });
            break;
          }
          default: {
            mappedValues = values.map((item) => ({
              label: item,
              value: item,
              filter: key
            }));
            break;
          }
        }

        selectedFilters = [...selectedFilters, ...mappedValues];
      }
    }

    return selectedFilters;
  }
);
