export class ZohoFormsService {

  private static baseUrl: string = "https://forms.zohopublic.eu/wazzup/form/";

  /**
   * Opens the zoho form in a new window
   * @param formName
   * @param formIdentifier
   * @param params
   * @param target
   */
  public static openFormInNewWindow(formName: string, formIdentifier: string, params: any, target: string = "_blank") {
    window.open(this.createUrl(formName, formIdentifier) + this.formatParams(params), target);
  }

  /**
   * Returns the zoho form url
   * @param formName
   * @param formIdentifier
   */
  private static createUrl(formName: string, formIdentifier: string) {
    return this.baseUrl + `${formName}/formperma/${formIdentifier}`;
  }

  /**
   * Returns the url encoded params as a string
   * @param params
   */
  private static formatParams(params) {
    let paramsString:string = "";
    for (const property in params) {
      if (params.hasOwnProperty(property)) {
        const firstParam = !!paramsString.length ? "&" : "?";
        paramsString = paramsString + `${firstParam}${property}=${encodeURIComponent(params[property])}`;
      }
    }
    return paramsString;
  }
}
