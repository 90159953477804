import { CommunicationLog } from "@haywork/api/kolibri";
import { EmailMessageThunks, MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  CommunicationLogComponent,
  CommunicationLogComponentProps,
} from "./communication-log";

type StateProps = {};
type DispatchProps = {
  getCommunicationLog: (
    communicationLogId: string
  ) => Promise<CommunicationLog>;
  navigate: (path: string) => RouterAction;
  readMessage: (
    messageId: string,
    accountId: string,
    folderId: string
  ) => Promise<EmailMessage>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  CommunicationLogComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  CommunicationLogComponentProps
> = (dispatch) => ({
  readMessage: (messageId: string, accountId: string, folderId: string) =>
    dispatch(
      EmailThunk.Messages.getMessage(messageId, accountId, folderId, true)
    ),
  getCommunicationLog: (communicationLogId: string) =>
    dispatch(EmailMessageThunks.getCommunicationLog(communicationLogId)),
  navigate: (path: string) => dispatch(push(path)),
});

export type CommunicationLogContainerProps = StateProps & DispatchProps;
export const CommunicationLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationLogComponent);
