import {
  Amenities,
  ApartmentCharacteristic,
  ApartmentSort,
  BalconyType,
  BathroomFacility,
  BeverageHospitalitySectorType,
  CattleFarmingSubtype,
  Certification,
  ConditionType,
  ConstructionPeriod,
  EnergyClass,
  FastfoodHospitalitySectorType,
  FloorType,
  Furnishing,
  GarageType,
  GardenQuality,
  GardenType,
  HorseCompanySubtype,
  HorticulturalCompanyType,
  HotelHospitalitySectorType,
  HouseCharacteristic,
  HouseSort,
  HouseType,
  InsulationType,
  InvestmentType,
  KitchenType,
  LandPropertySubType,
  Language,
  LeisureType,
  ListingType,
  LivingRoomType,
  LocationPlace,
  Orientation,
  ParkingType,
  PigCompanyType,
  PointOfInterestType,
  PoultryFarmingSubtype,
  RentCondition,
  RentSpecification,
  ResidentialSubtypeOther,
  RestaurantHospitalitySectorType,
  SaleCondition,
  SocialPropertyType,
  SoilType,
  SpaceType,
  Stove,
  UnitSize,
  AgriculturalSubtypeOther,
  TypePART,
  SearchAssignmentFacilityType,
  Condition,
  TypePARTOther,
  TypeBOG,
  TypeAlvOther,
  TypeAlv,
} from "@haywork/api/kolibri";
import {
  BalconyType as MlsBalconyType,
  BathroomFacilityType,
  CertificateType,
  ConditionType as MlsConditionType,
  ConstructionPeriodType,
  EnergyClassType,
  FloorType as MlsFloorType,
  FurnitureType,
  GarageFacilityType,
  GarageType as MlsGarageType,
  GardenQualityType,
  GardenType as MlsGardenType,
  IsolationType,
  KitchenFacilityType,
  LivingRoomType as MlsLivingRoomType,
  LocationPlaceType,
  OrientationType,
  PointOfInterestType as MlsPointOfInterestType,
  PriceUnitType,
  PropertyType,
  PurchaseConditionType,
  RentPriceType,
  RentSpecificationType,
  RoomType,
  FacilitySearchType,
} from "@haywork/api/mls";

export const MLS = {
  FILTER_SETTINGS_KEY: "mlsFilter",
};

export const MLS_MAINTYPES_PART = [
  PropertyType.HOUSE,
  PropertyType.APARTMENT,
  PropertyType.PARKING,
  PropertyType.PLOT,
  PropertyType.RESIDENTIAL_OTHERS,
];

export const MLS_MAINTYPES_BOG = [
  PropertyType.COMMERCIAL_OTHERS,
  PropertyType.PRODUCTION_HALL,
  PropertyType.OUTDOOR_AREA,
  PropertyType.HOTEL_RESTAURANT_CAFE,
  PropertyType.OFFICE,
  PropertyType.LOT,
  PropertyType.PRACTICE_SPACE,
  PropertyType.RETAIL_SPACE,
  PropertyType.WAREHOUSE,
  PropertyType.WAREHOUSE_PRODUCTION,
  PropertyType.SERVICES,
  PropertyType.GARAGE,
  PropertyType.LEISURE,
  PropertyType.INVESTMENT,
  PropertyType.SOCIAL_PROPERTY,
];

export const MLS_GROUNDTYPES = [
  PropertyType.CLAY,
  PropertyType.LEEM,
  PropertyType.PEAT,
  PropertyType.SAND,
  PropertyType.SABLON,
];

export const MLS_MAINTYPES_ALV = [
  PropertyType.ARABLE_COMPANY,
  PropertyType.LOOSE_SOIL,
  PropertyType.DAIRY_FARMING_COMPANY,
  PropertyType.POULTRY_COMPANY,
  PropertyType.HORSES_COMPANY,
  PropertyType.HORTICULTURAL_COMPANY,
  PropertyType.PIG_HOLDING,
  PropertyType.VEAL_CALVES,
  PropertyType.AGRICULTURAL_HOUSE,
  PropertyType.CATTLE_HUSBANDRY,
  PropertyType.AGRICULTURAL_OTHERS,
  PropertyType.PREMISES,
];

export const MLS_HOUSE_SORTS = [
  PropertyType.SINGLE_FAMILY_HOUSE,
  PropertyType.MANSION,
  PropertyType.VILLA,
  PropertyType.COUNTRY_HOUSE,
  PropertyType.BUNGALOW,
  PropertyType.RESIDENTIAL_FARM,
  PropertyType.CANAL_HOUSE,
  PropertyType.HOUSEBOAT,
  PropertyType.MOBILE_HOME,
  PropertyType.SHARED_HOUSE,
  PropertyType.COTTAGE,
  PropertyType.RESIDENTIAL_FARM,
  PropertyType.GIPSY_CART,
];

export const MLS_HOUSE_TYPES = [
  PropertyType.DETACHED_HOUSE,
  PropertyType.LINKED_HOUSE,
  PropertyType.SEMI_DETACHED_HOUSE_ONE_ROOF,
  PropertyType.ROW_HOUSE_MIDDLE,
  PropertyType.ROW_HOUSE_CORNER,
  PropertyType.ROW_HOUSE_END,
  PropertyType.SEMI_DETACHED_HOUSE,
  PropertyType.SEMI_DETACHED_LINKED_HOUSE_ONE_ROOF,
  PropertyType.STAGGERED,
];

export const MLS_APARTMENT_SORTS = [
  PropertyType.UPSTAIRS_APARTMENT,
  PropertyType.GROUND_FLOOR_APARTMENT,
  PropertyType.MAISONETTE,
  PropertyType.GALLERY_FLAT,
  PropertyType.PORTICO_FLAT,
  PropertyType.UPSTAIRS_GROUND_FLOOR_APARTMENT,
  PropertyType.PENTHOUSE,
  PropertyType.PORCH_APARTMENT,
  PropertyType.STUDENT_ROOM,
  PropertyType.DOUBLE_GROUND_FLOOR_APARTMENT,
  PropertyType.MEZZANINE,
  PropertyType.MODERN,
  PropertyType.SOVIETSTYLE,
  PropertyType.ITALIANYARDS,
];

export const MLS_GARAGE_TYPES = [
  PropertyType.INDOOR_GARAGE,
  PropertyType.PARKING_CELLAR,
  PropertyType.PARKING_PLACE,
  PropertyType.GARAGE,
];

export const MLS_OTHER_TYPES_PART = [
  PropertyType.STOREROOM,
  PropertyType.BERTH,
  PropertyType.MOBILE_HOME_PITCH,
  PropertyType.GIPSY_CART_PITCH,
  PropertyType.RESIDENTIAL_SHOP_PREMISES,
  PropertyType.BOTTOM_SECTION,
  PropertyType.STORAGE_SPACE,
];

export const MLS_APARTMENT_CHARACTERISTICS = [
  PropertyType.SERVICEFLAT,
  PropertyType.COMMON_APARTMENT,
  PropertyType.CORRIDOR_FLAT,
  PropertyType.BELETAGE,
  PropertyType.BASEMENT_FLAT,
  PropertyType.DOUBLE_UPPER_HOUSE,
  PropertyType.NURSERY_FLAT,
  PropertyType.CHEKHURI,
  PropertyType.DUPLEX,
  PropertyType.NONSTANDARD,
  PropertyType.RESIDENTIAL_OTHERS,
  PropertyType.PENTHOUSE,
  PropertyType.TRIPLEX,
];

export const MLS_HOUSE_CHARACTERISTICS = [
  PropertyType.DIKE_HOUSE,
  PropertyType.SPLIT_LEVEL_HOUSE,
  PropertyType.DRIVE_IN_HOUSE,
  PropertyType.SEMI_BUNGALOW,
  PropertyType.PATIO_HOUSE,
  PropertyType.PILE_HOUSE,
  PropertyType.COURTYARD_HOUSE,
  PropertyType.BUSINESS_SERVICE_HOUSE,
  PropertyType.WATER_HOUSE,
  PropertyType.WIND_WATER_MILL,
  PropertyType.QUADRANT_HOUSE,
];

export const MLS_HORECA_TYPES = [
  PropertyType.EMPTY_PREMISES,
  PropertyType.CAFE_BAR,
  PropertyType.EATERY,
  PropertyType.DISCO_DANCING,
  PropertyType.RESTAURANT,
  PropertyType.ROADHOUSE,
  PropertyType.DAYTIME_OPERATION,
  PropertyType.HOTEL,
  PropertyType.HALLS_PARTY_VENUE,
  PropertyType.ADDITIONAL_HOSPITALITY,
  PropertyType.CAFETERIA,
  PropertyType.SNACKBAR,
  PropertyType.ICE_CREAM_PARLOR,
  PropertyType.CAFE_RESTAURANT,
  PropertyType.LUXURY_RESTAURANT,
  PropertyType.CHINESE_INDIAN_RESTAURANT,
  PropertyType.PANCAKE_RESTAURANT,
  PropertyType.RESTAURANT_PARTY_VENUE,
  PropertyType.CONGRESS_HALLS_VENUE,
  PropertyType.LUXURY_HOTEL,
  PropertyType.CONGRESS_HOTEL,
  PropertyType.HOTEL_GARNI,
];

export const MLS_HORSE_COMPANY_TYPES = [
  PropertyType.HORSE_RIDING_SCHOOL,
  PropertyType.HORSE_PENSION,
  PropertyType.HORSE_TRAINING_FARM,
  PropertyType.HORSE_FARM_OTHERS,
  PropertyType.STUD,
];

export const MLS_PIG_COMPANY_TYPES = [
  PropertyType.SOWS,
  PropertyType.FATTENING_PIGS,
  PropertyType.SOWS_AND_FATTENING_PIGS,
  PropertyType.REARING_COMPANY,
];

export const MLS_CULTIVATION_TYPES = [
  PropertyType.FLOWERS_COMPANY,
  PropertyType.FLOWER_BULB_COMPANY,
  PropertyType.TREE_NURSERY_COMPANY,
  PropertyType.FRUIT_GROWING_COMPANY,
  PropertyType.VEGETABLES_COMPANY,
  PropertyType.PLANTS_COMPANY,
];

export const MLS_BEEF_TYPES = [
  PropertyType.DAIRY_CATTLE,
  PropertyType.SUCKLER_COW,
  PropertyType.MEAT_BULLS,
  PropertyType.BREEDING_YOUNG_CATTLE,
  PropertyType.VEAL_CALVES,
  PropertyType.ROSE_CALVES,
];

export const MLS_CULTURES = {
  "nl-NL": Language.Dutch,
  Nl_NL: Language.Dutch,
  En_GB: Language.English,
  Ka_GE: Language.Georgian,
  De_DE: Language.German,
  Fr_FR: Language.French,
  Es_ES: Language.Spanish,
  It_IT: Language.Italian,
  Hr_HR: Language.Croatian,
  Ru_RU: Language.Russian,
  Et_EE: Language.Estonian,
  Fi_FI: Language.Finnish,
  Pl_PL: Language.Polish,
  Tr_TR: Language.Turkish,
  Sk_SK: Language.Swedish,
};

export const MLS_FURNITURE_TYPES = {
  [FurnitureType.NOT_FURNISHED]: Furnishing.AsShell,
  [FurnitureType.PARTIALLY_FURNISHED]: Furnishing.Upholstered,
  [FurnitureType.FURNISHED]: Furnishing.Furnished,
  [FurnitureType.FURNITURE_OPTIONAL]: Furnishing.OptionForFurnished,
};

export const MLS_RENT_PRICE_TYPE = {
  [RentPriceType.PRICE_PER_DAY]: RentCondition.PricePerDay,
  [RentPriceType.PRICE_PER_WEEK]: RentCondition.PricePerWeek,
  [RentPriceType.PRICE_PER_MONTH]: RentCondition.PricePerMonth,
  [RentPriceType.PRICE_PER_YEAR]: RentCondition.PricePerYear,
  [RentPriceType.PRICE_PER_QUARTER]: RentCondition.PricePerQuarter,
  [RentPriceType.PRICE_PER_HALF_YEAR]: RentCondition.PricePerHalfYear,
  [RentPriceType.PRICE_PER_CONTRACT]: RentCondition.PricePerContract,
  [RentPriceType.M2_PER_MONTH]: RentCondition.PricePerMonth,
  [RentPriceType.M2_PER_YEAR]: RentCondition.PricePerYear,
};

export const MLS_RENT_SPECIFICATION = {
  [RentSpecificationType.INCL_SERVICE_COSTS]: RentSpecification.ServiceCharges,
  [RentSpecificationType.INCL_GAS]: RentSpecification.Gas,
  [RentSpecificationType.INCL_WATER]: RentSpecification.Water,
  [RentSpecificationType.INCL_ELECTRICITY]: RentSpecification.Electricity,
  [RentSpecificationType.INCL_FURNITURE]: RentSpecification.Furnished,
  [RentSpecificationType.INCL_UPHOLSTERED]: RentSpecification.Upholstered,
};

export const MLS_PER_UNIT_SIZE = {
  [PriceUnitType.PER_M2]: UnitSize.M2,
  [PriceUnitType.PER_ACRE]: UnitSize.Hectare,
};

export const MLS_PURCHASE_CONDITION = {
  [PurchaseConditionType.COSTS_BUYER]: SaleCondition.CostsBuyer,
  [PurchaseConditionType.FREE_ON_NAME]: SaleCondition.FreeOnName,
  [PurchaseConditionType.COSTS_SPLIT]: SaleCondition.CostsSplit,
};

export const MLS_GARAGE_FACILITY_TYPE = {
  [GarageFacilityType.HEATING]: Amenities.CentralHeating,
  [GarageFacilityType.WATER]: Amenities.Water,
  [GarageFacilityType.ELECTRICITY]: Amenities.Electricity,
  [GarageFacilityType.ELECTRIC_DOORS]: Amenities.ElectricDoor,
  // [GarageFacilityType."LOFT"]: Amenities.
  [GarageFacilityType.ATTIC]: Amenities.Attic,
};

export const MLS_GARAGE_TYPE = {
  [MlsGarageType.ATTACHED_WOOD]: GarageType.AttachedWood,
  [MlsGarageType.ATTACHED_STONE]: GarageType.AttachedStone,
  [MlsGarageType.DETACHED_WOOD]: GarageType.AttachedWood,
  [MlsGarageType.DETACHED_STONE]: GarageType.DetachedStone,
  [MlsGarageType.INDOOR]: GarageType.Indoor,
  [MlsGarageType.GARAGEBOX]: GarageType.GarageBox,
  [MlsGarageType.BASEMENT_CAR_PARK]: GarageType.UndergroundParking,
  [MlsGarageType.OPTION]: GarageType.OptionForGarage,
  [MlsGarageType.CARPORT]: GarageType.CarPort,
  [MlsGarageType.PARKING_PLACE]: GarageType.ParkingSpace,
  [MlsGarageType.GARAGE_WITH_CARPORT]: GarageType.GarageWithCarPort,
  [MlsGarageType.GARAGE]: GarageType.Garage,
  // "DESIGNATED_PARKING": GarageType.,
  // "LOT_IN_CARPORT": GarageType.,
  // "IN_COURTYARD": GarageType.c,
  // "IN_FRONT_OF_HOUSE": GarageType.f,
  // "OTHER" : GarageType.,
};

export const MLS_ISOLATION_TYPES = {
  [IsolationType.ROOF]: InsulationType.RoofInsulation,
  [IsolationType.WALL]: InsulationType.WallInsulation,
  [IsolationType.FLOOR]: InsulationType.FloorInsulation,
  [IsolationType.FULL]: InsulationType.FullyInsulated,
  [IsolationType.CAVITY_WALL]: InsulationType.CavityWall,
  [IsolationType.ANCHOR_FREE_CAVITY_WALL]: InsulationType.AnchorlessCavityWall,
  [IsolationType.ECO_CONSTRUCTION]: InsulationType.EcoBuilding,
  [IsolationType.INSULATED_GLAZING]: InsulationType.Sash,
  [IsolationType.PARTLY_INSULATED_GLAZING]: InsulationType.PartialDoubleGlazing,
  [IsolationType.SECONDARY_GLAZING]: InsulationType.DoubleGlazing,
  [IsolationType.LARGELY_DOUBLE_GLAZING]: InsulationType.LargelyDoubleGlazing,
  [IsolationType.TRIPLE_GLAZING]: InsulationType.TripleGlazing,
  [IsolationType.CRACKING_SEAL]: InsulationType.CrackingSeal,
  [IsolationType.HR_GLAZING]: InsulationType.HrGlass,
};

export const MLS_CONSTRUCTION_PERIOD = {
  [ConstructionPeriodType.BEFORE_1906]: ConstructionPeriod.Before1906,
  [ConstructionPeriodType.Item1906_1930]: ConstructionPeriod.Between1906And1930,
  [ConstructionPeriodType.Item1931_1944]: ConstructionPeriod.Between1931And1944,
  [ConstructionPeriodType.Item1945_1959]: ConstructionPeriod.Between1945And1959,
  [ConstructionPeriodType.Item1960_1970]: ConstructionPeriod.Between1960And1970,
  [ConstructionPeriodType.Item1971_1980]: ConstructionPeriod.Between1971And1980,
  [ConstructionPeriodType.Item1981_1990]: ConstructionPeriod.Between1981And1990,
  [ConstructionPeriodType.Item1991_2000]: ConstructionPeriod.Between1991And2000,
  [ConstructionPeriodType.Item2001_2010]: ConstructionPeriod.Between2001And2010,
  [ConstructionPeriodType.FROM_2011]: ConstructionPeriod.After2010,
};

export const MLS_GARDEN_QUALITY_TYPE = {
  [GardenQualityType.TO_BE_CREATED]: GardenQuality.ToConstruct,
  [GardenQualityType.NEGLECTED]: GardenQuality.Derelict,
  [GardenQualityType.NORMAL]: GardenQuality.Normal,
  [GardenQualityType.BEAUTIFULLY_LANDSCAPED]:
    GardenQuality.BeautifullyLandscaped,
  [GardenQualityType.WELL_MAINTAINED]: GardenQuality.Groomed,
  [GardenQualityType.CONSTRUCTED_UNDER_ARCHITECTURE]:
    GardenQuality.ConstructedByArchitect,
};

export const MLS_GARDEN_TYPE = {
  [MlsGardenType.BACK_YARD]: GardenType.BackGarden,
  [MlsGardenType.FRONT_YARD]: GardenType.FrontGarden,
  [MlsGardenType.SIDE_YARD]: GardenType.SideGarden,
  [MlsGardenType.GARDEN_AROUND]: GardenType.SurroundingGarden,
  [MlsGardenType.PATIO_ATRIUM]: GardenType.PatioAtrium,
  [MlsGardenType.OPEN_AREA]: GardenType.Place,
  [MlsGardenType.SUN_TERRACE]: GardenType.SunTerrace,
  [MlsGardenType.ENCLOSED_YARD]: GardenType.EnclosedYard,
};

export const MLS_ORIENTATION_TYPE = {
  [OrientationType.NORTH]: Orientation.North,
  [OrientationType.NORTH_EAST]: Orientation.NorthEast,
  [OrientationType.EAST]: Orientation.East,
  [OrientationType.SOUTH_EAST]: Orientation.SouthEast,
  [OrientationType.SOUTH]: Orientation.South,
  [OrientationType.SOUTH_WEST]: Orientation.SouthWest,
  [OrientationType.WEST]: Orientation.West,
  [OrientationType.NORTH_WEST]: Orientation.NorthWest,
};

export const MLS_BALCONY_TYPES = {
  [MlsBalconyType.BALCONY]: BalconyType.Balcony,
  [MlsBalconyType.CLOSED_BALCONY]: BalconyType.ClosedBalcony,
  [MlsBalconyType.FRENCH_BALCONY]: BalconyType.FrenchBalcony,
  [MlsBalconyType.GLAZED_BALCONY]: BalconyType.GlazedBalcony,
  [MlsBalconyType.TERRACE]: BalconyType.Terrace,
};

export const MLS_BATHROOM_FACILITY_TYPES = {
  [BathroomFacilityType.BATH]: BathroomFacility.Bath,
  [BathroomFacilityType.HALF_BATH]: BathroomFacility.HalfBath,
  [BathroomFacilityType.BIDET]: BathroomFacility.Bidet,
  [BathroomFacilityType.URINOIR]: BathroomFacility.Urinal,
  [BathroomFacilityType.SHOWER]: BathroomFacility.Shower,
  [BathroomFacilityType.TOILET]: BathroomFacility.Toilet,
  [BathroomFacilityType.JACUZZI]: BathroomFacility.Jacuzzi,
  [BathroomFacilityType.STEAMCABIN]: BathroomFacility.SteamCabin,
  [BathroomFacilityType.DOUBLE_SINK]: BathroomFacility.DoubleSink,
  [BathroomFacilityType.SINK]: BathroomFacility.Sink,
  [BathroomFacilityType.WASHBASIN_FURNITURE]:
    BathroomFacility.WashbasinFurniture,
  [BathroomFacilityType.WALKIN_SHOWER]: BathroomFacility.WalkInShower,
  [BathroomFacilityType.UNDERFLOORHEATING]: BathroomFacility.UnderfloorHeating,
  [BathroomFacilityType.WHIRLPOOL]: BathroomFacility.Whirlpool,
  [BathroomFacilityType.WASHINGMACHINE_CONNECTION]:
    BathroomFacility.WashingMachineConnection,
  [BathroomFacilityType.SAUNA]: BathroomFacility.Sauna,
};

export const MLS_KITCHEN_TYPES = {
  OPEN: KitchenType.Open,
  SEMI_OPEN: KitchenType.SemiOpen,
  CLOSED: KitchenType.Closed,
  DINING: KitchenType.Dining,
  // "LARGE_KITCHEN": ,
  // "KITCHENETTE": ,
  // "AMERICAN_KITCHEN" : ,
};

export const MLS_LIVING_ROOM_TYPES = {
  [MlsLivingRoomType.CONSERVATORY]: LivingRoomType.Conservatory,
  [MlsLivingRoomType.FORMER_ROOM_EN_SUITE]: LivingRoomType.RoomEnSuite,
  [MlsLivingRoomType.GARDENROOM]: LivingRoomType.GardenRoom,
  [MlsLivingRoomType.L_ROOM]: LivingRoomType.LRoom,
  [MlsLivingRoomType.LIVING_ROOM]: LivingRoomType.LivingRoom,
  [MlsLivingRoomType.ROOM_EN_SUITE]: LivingRoomType.RoomEnSuite,
  [MlsLivingRoomType.STUDY]: LivingRoomType.Study,
  [MlsLivingRoomType.SUNRAYS_ROOM]: LivingRoomType.SunRoom,
  [MlsLivingRoomType.T_ROOM]: LivingRoomType.TRoom,
  [MlsLivingRoomType.U_ROOM]: LivingRoomType.URoom,
  [MlsLivingRoomType.OFFICE]: LivingRoomType.Office,
  [MlsLivingRoomType.Z_ROOM]: LivingRoomType.ZRoom,
};

export const MLS_KITCHEN_FACILITY_TYPES = {
  [KitchenFacilityType.ELECTRIC_STOVE]: Stove.Electric,
  [KitchenFacilityType.GAS_STOVE]: Stove.Gas,
  [KitchenFacilityType.WOOD_STOVE]: Stove.Wood,
  // "BUILT_IN_EQUIPMENT": ,
  // "COOKING_ISLAND": ,
  // "RINSE_ISLAND" : ,
};

export const MLS_ROOM_TYPES = {
  [RoomType.BALCONY]: SpaceType.Balcony,
  [RoomType.BATHROOM]: SpaceType.Bathroom,
  [RoomType.BEDROOM]: SpaceType.Bedroom,
  [RoomType.BOILER_ROOM]: SpaceType.BoilerRoom,
  [RoomType.CONSERVATORY]: SpaceType.Conservatory,
  [RoomType.CORRIDOR]: SpaceType.Corridor,
  [RoomType.DORMER]: SpaceType.Dormer,
  [RoomType.ENCLOSED_PORCH]: SpaceType.EnclosedPorch,
  [RoomType.HALL]: SpaceType.Hall,
  [RoomType.KITCHEN]: SpaceType.Kitchen,
  [RoomType.LAUNDRY_ROOM]: SpaceType.LaundryRoom,
  [RoomType.LIVINGROOM]: SpaceType.LivingRoom,
  [RoomType.LOGGIA]: SpaceType.Loggia,
  [RoomType.ROOF_TERRACE]: SpaceType.RoofTerrace,
  [RoomType.SCULLERY]: SpaceType.UtilityRoom,
  [RoomType.SHOWER]: SpaceType.Shower,
  [RoomType.STORAGE]: SpaceType.Storage,
  [RoomType.STOREROOM]: SpaceType.StoreRoom,
  [RoomType.TOILET]: SpaceType.Toilet,
  [RoomType.VESTIBULE]: SpaceType.Vestibule,
};

export const MLS_FLOOR_TYPE = {
  [MlsFloorType.GROUND_FLOOR]: FloorType.GroundFloor,
  [MlsFloorType.FLOOR_1]: FloorType.Floor,
  [MlsFloorType.FLOOR_2]: FloorType.Floor,
  [MlsFloorType.FLOOR_3]: FloorType.Floor,
  [MlsFloorType.FLOOR_4]: FloorType.Floor,
  [MlsFloorType.FLOOR_5]: FloorType.Floor,
  [MlsFloorType.FLOOR_6]: FloorType.Floor,
  [MlsFloorType.FLOOR_7]: FloorType.Floor,
  [MlsFloorType.FLOOR_8]: FloorType.Floor,
  [MlsFloorType.FLOOR_9]: FloorType.Floor,
  [MlsFloorType.FLOOR_10]: FloorType.Floor,
  [MlsFloorType.FLOOR_11_15]: FloorType.Floor,
  [MlsFloorType.FLOOR_16_30]: FloorType.Floor,
  [MlsFloorType.BEYOND30]: FloorType.Floor,
  [MlsFloorType.TOP_FLOOR]: FloorType.TopFloor,
  // [MlsFloorType.MEZZANINE_ENTRESOL]: ,
  [MlsFloorType.BASEMENT]: FloorType.Basement,
  // [MlsFloorType.RAISED_GROUND_FLOOR]: ,
  // [MlsFloorType.SEVERAL]: ,
  [MlsFloorType.ATTIC]: FloorType.Attic,
};

export const MLS_MAINTENANCE_TYPES = {
  // "VERY_GOOD": ,
  // "GOOD": ,
  // "MEDIOCRE": ,
  // "MODERATE": ,
  // "BAD": ,
  // "TO_REFURBISH": ,
  [MlsConditionType.IN_NEED_OF_RENOVATION]: ConditionType.NeedsRenovation,
  [MlsConditionType.FIRST_TIME_USE_AFTER_NEW_CONSTRUCTION]:
    ConditionType.NewBuilding,
  [MlsConditionType.FIRST_TIME_USE_AFTER_RENOVATION]: ConditionType.Renovated,
  // "TO_BE_DEMOLISHED": ,
  [MlsConditionType.FOUNDATION_ONLY]: ConditionType.Foundation,
  [MlsConditionType.FRAMEWORK_ONLY]: ConditionType.Framework,
  [MlsConditionType.UNDER_CONSTRUCTION]: ConditionType.UnderConstruction,
  [MlsConditionType.NEEDS_DECORATION]: ConditionType.NeedsDecoration,
  [MlsConditionType.NEWLY_DECORATED]: ConditionType.NewlyDecorated,
  [MlsConditionType.READY]: ConditionType.Ready,
  [MlsConditionType.ESSENTIAL_REPAIR_IS_DONE]:
    ConditionType.EssentialRepairsDone,
  // [MlsConditionType.PLANNED]: ConditionType,
  // "PRE_MARKETING": ,
  // "STARTED": ,
  // "NEARLY_FINISHED": ,
  // "MOVE_IN_READY": ,
};

export const MLS_ENERGY_CLASS = {
  // "APLUSPLUSPLUSPLUSPLUSPLUS": ,
  // "APLUSPLUSPLUSPLUSPLUS": ,
  // "APLUSPLUSPLUSPLUS": ,
  // "APLUSPLUSPLUS": ,
  [EnergyClassType.APLUSPLUS]: EnergyClass.Aplusplus,
  [EnergyClassType.APLUS]: EnergyClass.Aplus,
  [EnergyClassType.A]: EnergyClass.A,
  [EnergyClassType.B]: EnergyClass.B,
  [EnergyClassType.C]: EnergyClass.C,
  [EnergyClassType.D]: EnergyClass.D,
  [EnergyClassType.E]: EnergyClass.E,
  [EnergyClassType.F]: EnergyClass.F,
  [EnergyClassType.G]: EnergyClass.G,
};

export const MLS_POINTS_OF_INTEREST = {
  [MlsPointOfInterestType.BANK]: PointOfInterestType.BankBranch,
  [MlsPointOfInterestType.BUSJUNCTION]: PointOfInterestType.BusHub,
  [MlsPointOfInterestType.BUSSTOP]: PointOfInterestType.BusStop,
  [MlsPointOfInterestType.CASHMACHINE]: PointOfInterestType.ATM,
  [MlsPointOfInterestType.HIGHWAY_EXIT]: PointOfInterestType.HighwayExit,
  [MlsPointOfInterestType.METROJUNCTION]: PointOfInterestType.MetroHub,
  [MlsPointOfInterestType.METROSTOP]: PointOfInterestType.MetroStop,
  [MlsPointOfInterestType.RAILWAY_STATION]: PointOfInterestType.RailwayStation,
  [MlsPointOfInterestType.RECREATION]: PointOfInterestType.Recreation,
  [MlsPointOfInterestType.RESTAURANT]: PointOfInterestType.Restaurant,
  [MlsPointOfInterestType.SHOP]: PointOfInterestType.Shop,
  [MlsPointOfInterestType.SUPERMARKET]: PointOfInterestType.Supermarket,
  [MlsPointOfInterestType.TRAINSTOP]: PointOfInterestType.NsContent,
  [MlsPointOfInterestType.TRAMJUNCTION]: PointOfInterestType.TramHub,
  [MlsPointOfInterestType.TRAMSTOP]: PointOfInterestType.TramStop,
};

export const MLS_CERTIFICATION_TYPES = {
  [CertificateType.AANGEPASTE_WONING]: Certification.AdaptedProperty,
  [CertificateType.POLITIEKEURMERK]: Certification.Police,
  [CertificateType.BRANDVEILIGHEID]: Certification.FireSafety,
  [CertificateType.ALGEMENE_WONING_KEUR]: Certification.GeneralHomeInspection,
  [CertificateType.ENERGIE_PRESTATIE_ADVIES]:
    Certification.EnergyPerformanceCertificate,
  [CertificateType.BOUWKUNDIGE_KEURING]: Certification.BuildingInspection,
  [CertificateType.HUISGARANTIE]: Certification.HomeWarranty,
  [CertificateType.GIW_WAARBORGCERTIFICAAT]:
    Certification.HomeWarrantyCertificate,
  [CertificateType.SECURITY]: Certification.Security,
  [CertificateType.ENERGYLABEL]: Certification.EnergyLabel,
  [CertificateType.MEASUREMENTCERTIFICATE]:
    Certification.MeasurementCertificate,
  [CertificateType.CERTIFICATE_OF_OCCUPANCY]:
    Certification.CertificateOfOccupancy,
};

export const MLS_LOCATION_PLACES = {
  [LocationPlaceType.CENTRAL_CITY_CENTER]: LocationPlace.CentralCityCenter,
  // [LocationPlaceType.LOCAL_CENTER]: LocationPlace.ce,
  // [LocationPlaceType.PERIPHERAL]: LocationPlace.per,
  [LocationPlaceType.STANDALONE]: LocationPlace.Standalone,
  [LocationPlaceType.RESIDENTIAL_AREA]: LocationPlace.ResidentialArea,
  [LocationPlaceType.SECLUDED_LOCATION]: LocationPlace.SecludedLocation,
  [LocationPlaceType.ON_A_BUSY_STREET]: LocationPlace.OnBusyStreet,
  [LocationPlaceType.ON_A_QUIET_STREET]: LocationPlace.OnQuietStreet,
  [LocationPlaceType.OPEN_POSITION]: LocationPlace.OpenArea,
  [LocationPlaceType.ON_WATERWAY]: LocationPlace.OnWaterway,
  [LocationPlaceType.OUTSIDE_VILLAGE]: LocationPlace.OutsideVillage,
  [LocationPlaceType.NEAR_RAILWAY_STATION]: LocationPlace.NearRailwayStation,
  [LocationPlaceType.UNOBSTRUCTED_VIEW]: LocationPlace.UnobstructedView,
  [LocationPlaceType.BUSINESS_PARK]: LocationPlace.BusinessPark,
  // [LocationPlaceType.GDV]: LocationPlace.d,
  // [LocationPlaceType.PDV]: ,
  [LocationPlaceType.HARBOUR_AREA]: LocationPlace.HarborArea,
  [LocationPlaceType.INDUSTRIAL_AREA]: LocationPlace.IndustrialArea,
  [LocationPlaceType.OFFICE_PARK]: LocationPlace.OfficePark,
  [LocationPlaceType.SHOPPING_CENTER]: LocationPlace.ShoppingCenter,
  // [LocationPlaceType.CATERING]: LocationPlace.c,
  // [LocationPlaceType.CITY]: LocationPlace.,
  [LocationPlaceType.COUNTRY_SIDE]: LocationPlace.Countryside,
  [LocationPlaceType.DISTRICT]: LocationPlace.SubDivision,
  [LocationPlaceType.FOREST_BORDER]: LocationPlace.ForestEdge,
  [LocationPlaceType.FORESTED_AREA]: LocationPlace.ForestedArea,
  [LocationPlaceType.NEAR_HIGHWAY]: LocationPlace.NearHighway,
  [LocationPlaceType.NEAR_PUBLIC_TRANSPORT]: LocationPlace.NearPublicTransport,
  [LocationPlaceType.NEAR_SCHOOL]: LocationPlace.NearSchool,
  [LocationPlaceType.PARK]: LocationPlace.Park,
  // [LocationPlaceType.PLACE_CENTRAL]: LocationPlace.cen,
  [LocationPlaceType.SEA_BORDER]: LocationPlace.Seaside,
  [LocationPlaceType.SHELTERED_LOCATION]: LocationPlace.ShelteredLocation,
  [LocationPlaceType.SUB_DIVISION]: LocationPlace.SubDivision,
  [LocationPlaceType.SUBURB]: LocationPlace.Suburb,
  // [LocationPlaceType.TOWN]: LocationPlace.ci,
  [LocationPlaceType.WATERSIDE]: LocationPlace.Waterside,
  [LocationPlaceType.FURNITUREBOULEVARD]: LocationPlace.FurnitureBoulevard,
  [LocationPlaceType.SHOPPING_CENTER_COVERED]: LocationPlace.IndoorShopping,
  [LocationPlaceType.SEA_VIEW]: LocationPlace.SeaView,
};

export const MLS_MAP_LISTINGTYPES = {
  [PropertyType.HOUSE]: ListingType.House,
  [PropertyType.APARTMENT]: ListingType.Apartment,
  [PropertyType.PARKING]: ListingType.Parking,
  [PropertyType.PLOT]: ListingType.Plot,
  [PropertyType.RESIDENTIAL_OTHERS]: ListingType.Other,
  [PropertyType.COMMERCIAL_OTHERS]: ListingType.Other,
  [PropertyType.PRODUCTION_HALL]: ListingType.ProductionHall,
  [PropertyType.OUTDOOR_AREA]: ListingType.OutdoorArea,
  [PropertyType.HOTEL_RESTAURANT_CAFE]: ListingType.HotelRestaurantCafe,
  [PropertyType.OFFICE]: ListingType.Office,
  [PropertyType.LOT]: ListingType.Lot,
  [PropertyType.PRACTICE_SPACE]: ListingType.PracticeSpace,
  [PropertyType.RETAIL_SPACE]: ListingType.RetailSpace,
  [PropertyType.ARABLE_COMPANY]: ListingType.ArableCompany,
  [PropertyType.LOOSE_SOIL]: ListingType.LooseSoil,
  [PropertyType.DAIRY_FARMING_COMPANY]: ListingType.DairyFarmingCompany,
  [PropertyType.POULTRY_COMPANY]: ListingType.PoultryCompany,
  [PropertyType.HORSES_COMPANY]: ListingType.HorsesCompany,
  [PropertyType.HORTICULTURAL_COMPANY]: ListingType.Horticultural,
  [PropertyType.PIG_HOLDING]: ListingType.PigHolding,
  [PropertyType.VEAL_CALVES]: ListingType.VealCalvesFarming,
  [PropertyType.AGRICULTURAL_HOUSE]: ListingType.AgriculturalHouse,
  [PropertyType.CATTLE_HUSBANDRY]: ListingType.CattleFarming,
  [PropertyType.GARAGE]: ListingType.Garage,
  [PropertyType.WAREHOUSE]: ListingType.Warehouse,
  [PropertyType.WAREHOUSE_PRODUCTION]: ListingType.WarehouseProduction,
  [PropertyType.SERVICES]: ListingType.Services,
  [PropertyType.SUMMERCOTTAGE]: ListingType.SummerCottage,
  [PropertyType.LEISURE]: ListingType.Leisure,
  [PropertyType.INVESTMENT]: ListingType.Investment,
  [PropertyType.SOCIAL_PROPERTY]: ListingType.SocialProperty,
  [PropertyType.COTTAGE]: ListingType.SummerCottage,
};

export const MLS_MAP_HOUSESORT = {
  [PropertyType.SINGLE_FAMILY_HOUSE]: HouseSort.SingleFamilyHouse,
  [PropertyType.MANSION]: HouseSort.Mansion,
  [PropertyType.VILLA]: HouseSort.Villa,
  [PropertyType.COUNTRY_HOUSE]: HouseSort.CountryHouse,
  [PropertyType.BUNGALOW]: HouseSort.Bungalow,
  [PropertyType.RESIDENTIAL_FARM]: HouseSort.ResidentialFarm,
  [PropertyType.CANAL_HOUSE]: HouseSort.CanalHouse,
  [PropertyType.HOUSEBOAT]: HouseSort.HouseBoat,
  [PropertyType.MOBILE_HOME]: HouseSort.MobileHome,
  [PropertyType.GIPSY_CART]: HouseSort.GipsyCart,
};

export const MLS_MAP_APARTMENTSORT = {
  [PropertyType.UPSTAIRS_APARTMENT]: ApartmentSort.UpstairsApartment,
  [PropertyType.GROUND_FLOOR_APARTMENT]: ApartmentSort.GroundFloorApartment,
  [PropertyType.MAISONETTE]: ApartmentSort.Maisonette,
  [PropertyType.GALLERY_FLAT]: ApartmentSort.GalleryFlat,
  [PropertyType.PORTICO_FLAT]: ApartmentSort.PorticoFlat,
  [PropertyType.UPSTAIRS_GROUND_FLOOR_APARTMENT]:
    ApartmentSort.UpstairsGroundFloorApartment,
  [PropertyType.PENTHOUSE]: ApartmentSort.Penthouse,
  [PropertyType.PORCH_APARTMENT]: ApartmentSort.PorchApartment,
  [PropertyType.DOUBLE_GROUND_FLOOR_APARTMENT]:
    ApartmentSort.DoubleGroundFloorApartment,
  [PropertyType.MEZZANINE]: ApartmentSort.Mezzanine,
  [PropertyType.STUDENT_ROOM]: ApartmentSort.StudentRoom,
  [PropertyType.MODERN]: ApartmentSort.ModernApartment,
  [PropertyType.SOVIETSTYLE]: ApartmentSort.SovietStyleApartment,
  [PropertyType.ITALIANYARDS]: ApartmentSort.ItalianYardApartment,
};

export const MLS_MAP_PARKINGTYPE = {
  [PropertyType.INDOOR_GARAGE]: ParkingType.IndoorGarage,
  [PropertyType.PARKING_CELLAR]: ParkingType.ParkingCellar,
  [PropertyType.PARKING_PLACE]: ParkingType.ParkingSpace,
};

export const MLS_MAP_RESIDENTIALSUBTYPEOTHER = {
  [PropertyType.STOREROOM]: ResidentialSubtypeOther.StoreRoom,
  [PropertyType.BERTH]: ResidentialSubtypeOther.Berth,
  [PropertyType.MOBILE_HOME_PITCH]: ResidentialSubtypeOther.MobileHomePitch,
  [PropertyType.GIPSY_CART_PITCH]: ResidentialSubtypeOther.GypsyCartPitch,
  [PropertyType.RESIDENTIAL_SHOP_PREMISES]:
    ResidentialSubtypeOther.ResidentialShopPremises,
  [PropertyType.BOTTOM_SECTION]: ResidentialSubtypeOther.LowerSection,
  [PropertyType.STORAGE_SPACE]: ResidentialSubtypeOther.StorageSpace,
};

export const MLS_MAP_HOUSETYPE = {
  [PropertyType.DETACHED_HOUSE]: HouseType.DetachedHouse,
  [PropertyType.LINKED_HOUSE]: HouseType.LinkedHouse,
  [PropertyType.SEMI_DETACHED_HOUSE_ONE_ROOF]:
    HouseType.SemiDetachedHouseOneRoof,
  [PropertyType.ROW_HOUSE_MIDDLE]: HouseType.RowHouseMiddle,
  [PropertyType.ROW_HOUSE_CORNER]: HouseType.RowHouseCorner,
  [PropertyType.ROW_HOUSE_END]: HouseType.RowHouseEnd,
  [PropertyType.SEMI_DETACHED_HOUSE]: HouseType.SemiDetachedHouse,
  [PropertyType.SEMI_DETACHED_LINKED_HOUSE_ONE_ROOF]:
    HouseType.SemiDetachedLinkedHouseOneRoof,
};

export const MLS_MAP_APARTMENTCHARACTERISTIC = {
  [PropertyType.SERVICEFLAT]: ApartmentCharacteristic.ServiceFlat,
  [PropertyType.COMMON_APARTMENT]: ApartmentCharacteristic.CommonApartment,
  [PropertyType.CORRIDOR_FLAT]: ApartmentCharacteristic.CorridorFlat,
  [PropertyType.BELETAGE]: ApartmentCharacteristic.Beletage,
  [PropertyType.BASEMENT_FLAT]: ApartmentCharacteristic.Basement,
  [PropertyType.DOUBLE_UPPER_HOUSE]: ApartmentCharacteristic.DoubleUpperHouse,
  [PropertyType.NURSERY_FLAT]: ApartmentCharacteristic.NurseryFlat,
  [PropertyType.CHEKHURI]: ApartmentCharacteristic.Chekhuri,
  [PropertyType.DUPLEX]: ApartmentCharacteristic.Duplex,
  [PropertyType.KALAKURI]: ApartmentCharacteristic.Kalakuri,
  [PropertyType.KAVLASHVILI]: ApartmentCharacteristic.Kavlashvili,
  [PropertyType.KHRUSHOVI]: ApartmentCharacteristic.Khrushovi,
  [PropertyType.LVOVI]: ApartmentCharacteristic.Lvovi,
  [PropertyType.MOSCOW]: ApartmentCharacteristic.Moscow,
  [PropertyType.NONSTANDARD]: ApartmentCharacteristic.Nonstandard,
  [PropertyType.TRIPLEX]: ApartmentCharacteristic.Triplex,
  [PropertyType.TUKHARELI]: ApartmentCharacteristic.Tukhareli,
};

export const MLS_MAP_HOUSECHARACTERISTIC = {
  [PropertyType.DIKE_HOUSE]: HouseCharacteristic.DikeHouse,
  [PropertyType.SPLIT_LEVEL_HOUSE]: HouseCharacteristic.SplitLevelHouse,
  [PropertyType.DRIVE_IN_HOUSE]: HouseCharacteristic.DriveInHouse,
  [PropertyType.SEMI_BUNGALOW]: HouseCharacteristic.SemiBungalow,
  [PropertyType.PATIO_HOUSE]: HouseCharacteristic.PatioHouse,
  [PropertyType.PILE_HOUSE]: HouseCharacteristic.PileHouse,
  [PropertyType.COURTYARD_HOUSE]: HouseCharacteristic.CourtyardHouse,
  [PropertyType.BUSINESS_SERVICE_HOUSE]:
    HouseCharacteristic.BusinessServiceHouse,
  [PropertyType.WATER_HOUSE]: HouseCharacteristic.WaterHouse,
  [PropertyType.WIND_WATER_MILL]: HouseCharacteristic.WindWaterMill,
  [PropertyType.QUADRANT_HOUSE]: HouseCharacteristic.QuadrantHouse,
};

export const MLS_AGRICULTURAL_SUBTYPE_OTHER = [
  PropertyType.SHEEP,
  PropertyType.GOAT,
  PropertyType.WORMS,
  PropertyType.EEL,
  PropertyType.AGRICULTURAL_OTHER_OTHER,
  PropertyType.FISH,
];

export const MLS_MAP_AGRICULTURALSUBTYPEOTHER = {
  [PropertyType.SHEEP]: AgriculturalSubtypeOther.SheepFarm,
  [PropertyType.GOAT]: AgriculturalSubtypeOther.GoatFarm,
  [PropertyType.WORMS]: AgriculturalSubtypeOther.WormsFarm,
  [PropertyType.EEL]: AgriculturalSubtypeOther.EelFarm,
  [PropertyType.AGRICULTURAL_OTHER_OTHER]: AgriculturalSubtypeOther.Other,
  [PropertyType.FISH]: AgriculturalSubtypeOther.Fishery,
};

export const MLS_MAP_CATTLEFARMINGSUBTYPE = {
  [PropertyType.SUCKLER_COW]: CattleFarmingSubtype.SucklerCows,
  [PropertyType.MEAT_BULLS]: CattleFarmingSubtype.VealBulls,
  [PropertyType.BREEDING_YOUNG_CATTLE]:
    CattleFarmingSubtype.BreedingYoungCattle,
  [PropertyType.DAIRY_CATTLE]: CattleFarmingSubtype.DairyCattle,
  [PropertyType.ROSE_CALVES]: CattleFarmingSubtype.RoseCalves,
};

export const MLS_MAP_POULTRYFARMINGSUBTYPE = {
  [PropertyType.HENS]: PoultryFarmingSubtype.Hens,
  [PropertyType.BROILERS]: PoultryFarmingSubtype.Broilers,
  [PropertyType.BREEDING_HENS]: PoultryFarmingSubtype.BreedingHens,
  [PropertyType.BREEDING_BROILER]: PoultryFarmingSubtype.BreedingBroiler,
  [PropertyType.TURKEYS_GEESE_DUCKS_OSTRICHES]:
    PoultryFarmingSubtype.TurkeysGeeseDucksOstriches,
};

export const MLS_MAP_HORSECOMPANYSUBTYPE = {
  [PropertyType.HORSE_RIDING_SCHOOL]: HorseCompanySubtype.RidingSchool,
  [PropertyType.HORSE_PENSION]: HorseCompanySubtype.Pension,
  [PropertyType.HORSE_TRAINING_FARM]: HorseCompanySubtype.TrainingFarm,
  [PropertyType.HORSE_FARM_OTHERS]: HorseCompanySubtype.Other,
  [PropertyType.STUD]: HorseCompanySubtype.StudFarm,
};

export const MLS_MAP_HORTICULTURALCOMPANYTYPE = {
  [PropertyType.GREENHOUSE_HORTICULTURE_COMPANY]:
    HorticulturalCompanyType.GreenhouseHorticulture,
  [PropertyType.GROUND_HORTICULTURE_COMPANY]: HorticulturalCompanyType.Ground,
  [PropertyType.FRUIT_GROWING_COMPANY]: HorticulturalCompanyType.FruitGrowing,
  [PropertyType.MUSHROOM_CULTIVATION_COMPANY]:
    HorticulturalCompanyType.MushroomCultivation,
  [PropertyType.TREE_NURSERY_COMPANY]: HorticulturalCompanyType.TreeNursery,
  [PropertyType.FLOWER_BULB_COMPANY]: HorticulturalCompanyType.FlowerBulbs,
};

export const MLS_MAP_PIGCOMPANYTYPE = {
  [PropertyType.SOWS]: PigCompanyType.Sows,
  [PropertyType.FATTENING_PIGS]: PigCompanyType.Meat,
  [PropertyType.SOWS_AND_FATTENING_PIGS]: PigCompanyType.SowsAndMeat,
};

export const MLS_MAP_SOILTYPE = {
  [PropertyType.CLAY]: SoilType.Clay,
  [PropertyType.LEEM]: SoilType.Loam,
  [PropertyType.PEAT]: SoilType.Peat,
  [PropertyType.SAND]: SoilType.Sand,
  [PropertyType.SABLON]: SoilType.SabulousClay,
};

export const MLS_MAP_BEVERAGEHOSPITALITYSECTORTYPE = {
  [PropertyType.CAFE_BAR]: BeverageHospitalitySectorType.Cafe,
  [PropertyType.EATERY]: BeverageHospitalitySectorType.EatCafe,
  [PropertyType.DISCO_DANCING]: BeverageHospitalitySectorType.Disco,
};

export const MLS_MAP_RESTAURANTHOSPITALITYSECTORTYPE = {
  [PropertyType.RESTAURANT]: RestaurantHospitalitySectorType.Restaurant,
  [PropertyType.ROADHOUSE]: RestaurantHospitalitySectorType.Roadhouse,
  [PropertyType.CAFE_RESTAURANT]:
    RestaurantHospitalitySectorType.CafeRestaurant,
  [PropertyType.LUXURY_RESTAURANT]:
    RestaurantHospitalitySectorType.LuxuryRestaurant,
  [PropertyType.CHINESE_INDIAN_RESTAURANT]:
    RestaurantHospitalitySectorType.ChineseIndianRestaurant,
  [PropertyType.PANCAKE_RESTAURANT]:
    RestaurantHospitalitySectorType.PancakeHouse,
  [PropertyType.RESTAURANT_PARTY_VENUE]:
    RestaurantHospitalitySectorType.RestaurantPartyCenter,
  [PropertyType.CONGRESS_HALLS_VENUE]:
    RestaurantHospitalitySectorType.CongressHall,
};

export const MLS_MAP_HOTELHOSPITALITYSECTORTYPE = {
  [PropertyType.HOTEL]: HotelHospitalitySectorType.Hotel,
  [PropertyType.LUXURY_HOTEL]: HotelHospitalitySectorType.LuxuryHotel,
  [PropertyType.CONGRESS_HOTEL]: HotelHospitalitySectorType.CongressHotel,
  [PropertyType.HOTEL_GARNI]: HotelHospitalitySectorType.HotelGarni,
};

export const MLS_MAP_FASTFOODHOSPITALITYSECTORTYPE = {
  [PropertyType.CAFETERIA]: FastfoodHospitalitySectorType.CafeteriaRestaria,
  [PropertyType.SNACKBAR]: FastfoodHospitalitySectorType.Snackbar,
  [PropertyType.ICE_CREAM_PARLOR]: FastfoodHospitalitySectorType.IceCreamParlor,
};

export const MLS_MAP_LANDPROPERTYSUBTYPE = {
  [PropertyType.COMMERCIAL_LAND]: LandPropertySubType.Commercial,
  [PropertyType.FOREST_LAND]: LandPropertySubType.Forestland,
  [PropertyType.INDUSTRIAL_LAND]: LandPropertySubType.Industrial,
  [PropertyType.LAND_BY_WATERS]: LandPropertySubType.LandByWater,
  [PropertyType.LAND_WITHOUT_PURPOSE]: LandPropertySubType.LandWithoutPurpose,
  [PropertyType.PROFIT_YIELDING_LAND]: LandPropertySubType.ProfitYieldingLand,
  [PropertyType.RESIDENTIAL_LAND]: LandPropertySubType.ResidentialLand,
  [PropertyType.SOCIAL_LAND]: LandPropertySubType.SocialLand,
  [PropertyType.TRANSPORTATIONS_LAND]: LandPropertySubType.TransportationLand,
};

export const MLS_MAP_LEISURETYPE = {
  [PropertyType.CAMPING]: LeisureType.Camping,
  [PropertyType.BUNGALOWPARK]: LeisureType.BungalowPark,
  [PropertyType.DAY_RECREATION]: LeisureType.DayRecriation,
  [PropertyType.WELLNESS]: LeisureType.Wellness,
  [PropertyType.LEISURE_OTHERS]: LeisureType.Other,
};

export const MLS_MAP_SOCIALPROPERTYTYPE = {
  [PropertyType.HEALTHCARE_INSTITUTION]: SocialPropertyType.Healthcare,
  [PropertyType.SPORTS_INSTITUTION]: SocialPropertyType.Sports,
  [PropertyType.CULTURAL_INSTITUTION]: SocialPropertyType.Cultural,
  [PropertyType.RELIGIOUS_INSTITUTION]: SocialPropertyType.Religious,
  [PropertyType.EDUCATIONAL_INSTITUTION]: SocialPropertyType.Educational,
};

export const MLS_MAP_INVESTMENTTYPE = {
  [PropertyType.INVESTMENT_RETAIL_SPACE]: InvestmentType.Retail,
  [PropertyType.INVESTMENT_OFFICE_SPACE]: InvestmentType.Office,
  [PropertyType.INVESTMENT_BUSINESS_SPACE]: InvestmentType.Business,
  [PropertyType.INVESTMENT_HOTEL_RESTAURANT_CAFE]:
    InvestmentType.HotelRestaurantCafe,
  [PropertyType.INVESTMENT_LEISURE]: InvestmentType.Leisure,
  [PropertyType.INVESTMENT_SOCIAL_PROPERTY]: InvestmentType.SocialProperty,
  [PropertyType.INVESTMENT_RESIDENTIAL]: InvestmentType.Residential,
  [PropertyType.INVESTMENT_LOT]: InvestmentType.Lot,
  [PropertyType.INVESTMENT_LOOSE_SOIL]: InvestmentType.LooseSoil,
  [PropertyType.INVESTMENT_OUTDOOR_AREA]: InvestmentType.OutdoorArea,
  [PropertyType.INVESTMENT_AGRICULTURAL]: InvestmentType.Agricultural,
  [PropertyType.INVESTMENT_OTHERS]: InvestmentType.Other,
};

/**
 * Unmapped MLS property types

export const MLS_PROPERTY_TYPE = {
  [PropertyType."SEE_CLAY"]: ,
  [PropertyType."FLUVIAL"]: ,
  [PropertyType."LOSS"]: ,
  [PropertyType."STAGGERED"]: ,
  [PropertyType."EMPTY_PREMISES"]: ,
  [PropertyType."DAYTIME_OPERATION"]: ,
  [PropertyType."ADDITIONAL_HOSPITALITY"]: ,
  [PropertyType."SHARED_HOUSE"]: ,
  [PropertyType."REARING_COMPANY"]: ,
  [PropertyType."UNSPECIFIED"]: ,
  [PropertyType."FLOWERS_COMPANY"]: ,
  [PropertyType."VEGETABLES_COMPANY"]: ,
  [PropertyType."PLANTS_COMPANY" ]: ,
  [PropertyType."SHOWROOM"]: ,
  [PropertyType.HALLS_PARTY_VENUE]: ,
  [PropertyType.PREMISES]: ,
};

 */

export const MLS_MAP_TYPEPART = {
  [TypePART.Apartment]: PropertyType.APARTMENT,
  // [TypePART.BuildingGround]: PropertyType,
  [TypePART.Other]: PropertyType.RESIDENTIAL_OTHERS,
  [TypePART.Parking]: PropertyType.PARKING,
  [TypePART.ResidentialBuilding]: PropertyType.HOUSE,
  [TypePART.SummerCottage]: PropertyType.SUMMERCOTTAGE,
};

export const MLS_MAP_LOCATIONPLACES = {
  [LocationPlace.CentralCityCenter]: LocationPlaceType.CENTRAL_CITY_CENTER,
  [LocationPlace.Standalone]: LocationPlaceType.STANDALONE,
  [LocationPlace.ResidentialArea]: LocationPlaceType.RESIDENTIAL_AREA,
  [LocationPlace.SecludedLocation]: LocationPlaceType.SECLUDED_LOCATION,
  [LocationPlace.OnBusyStreet]: LocationPlaceType.ON_A_BUSY_STREET,
  [LocationPlace.OnQuietStreet]: LocationPlaceType.ON_A_QUIET_STREET,
  [LocationPlace.OpenArea]: LocationPlaceType.OPEN_POSITION,
  [LocationPlace.OnWaterway]: LocationPlaceType.ON_WATERWAY,
  [LocationPlace.OutsideVillage]: LocationPlaceType.OUTSIDE_VILLAGE,
  [LocationPlace.NearRailwayStation]: LocationPlaceType.NEAR_RAILWAY_STATION,
  [LocationPlace.UnobstructedView]: LocationPlaceType.UNOBSTRUCTED_VIEW,
  [LocationPlace.BusinessPark]: LocationPlaceType.BUSINESS_PARK,
  [LocationPlace.HarborArea]: LocationPlaceType.HARBOUR_AREA,
  [LocationPlace.IndustrialArea]: LocationPlaceType.INDUSTRIAL_AREA,
  [LocationPlace.OfficePark]: LocationPlaceType.OFFICE_PARK,
  [LocationPlace.ShoppingCenter]: LocationPlaceType.SHOPPING_CENTER,
  [LocationPlace.Countryside]: LocationPlaceType.COUNTRY_SIDE,
  [LocationPlace.SubDivision]: LocationPlaceType.DISTRICT,
  [LocationPlace.ForestEdge]: LocationPlaceType.FOREST_BORDER,
  [LocationPlace.ForestedArea]: LocationPlaceType.FORESTED_AREA,
  [LocationPlace.NearHighway]: LocationPlaceType.NEAR_HIGHWAY,
  [LocationPlace.NearPublicTransport]: LocationPlaceType.NEAR_PUBLIC_TRANSPORT,
  [LocationPlace.NearSchool]: LocationPlaceType.NEAR_SCHOOL,
  [LocationPlace.Park]: LocationPlaceType.PARK,
  [LocationPlace.Seaside]: LocationPlaceType.SEA_BORDER,
  [LocationPlace.ShelteredLocation]: LocationPlaceType.SHELTERED_LOCATION,
  [LocationPlace.SubDivision]: LocationPlaceType.SUB_DIVISION,
  [LocationPlace.Suburb]: LocationPlaceType.SUBURB,
  [LocationPlace.Waterside]: LocationPlaceType.WATERSIDE,
  [LocationPlace.FurnitureBoulevard]: LocationPlaceType.FURNITUREBOULEVARD,
  [LocationPlace.IndoorShopping]: LocationPlaceType.SHOPPING_CENTER_COVERED,
  [LocationPlace.SeaView]: LocationPlaceType.SEA_VIEW,
};

export const MLS_MAP_ORIENTATION = {
  [Orientation.East]: OrientationType.EAST,
  [Orientation.North]: OrientationType.NORTH,
  [Orientation.NorthEast]: OrientationType.NORTH_EAST,
  [Orientation.NorthWest]: OrientationType.NORTH_WEST,
  [Orientation.South]: OrientationType.SOUTH,
  [Orientation.SouthEast]: OrientationType.SOUTH_EAST,
  [Orientation.SouthWest]: OrientationType.SOUTH_WEST,
  [Orientation.West]: OrientationType.WEST,
};

export const MLS_MAP_SEARCHASSIGNMENTFACILITYTYPE = {
  [SearchAssignmentFacilityType.ElevatorInBuilding]:
    FacilitySearchType.ELEVATOR,
  [SearchAssignmentFacilityType.Elevator]: FacilitySearchType.ELEVATOR,
  [SearchAssignmentFacilityType.Fireplace]: FacilitySearchType.FIREPLACE,
  [SearchAssignmentFacilityType.Sauna]: FacilitySearchType.SAUNA,
  [SearchAssignmentFacilityType.Bath]: FacilitySearchType.BATHTUB,
  [SearchAssignmentFacilityType.SwimmingPool]: FacilitySearchType.POOL,
  [SearchAssignmentFacilityType.Jacuzzi]: FacilitySearchType.JACUZZI,
  [SearchAssignmentFacilityType.SteamCabin]: FacilitySearchType.STEAM_CABIN,
};

export const MLS_MAP_CONDITION = {
  [Condition.Excellent]: MlsConditionType.VERY_GOOD,
  [Condition.Good]: MlsConditionType.GOOD,
  [Condition.GoodToExcellent]: MlsConditionType.GOOD,
  [Condition.Habitable]: MlsConditionType.MEDIOCRE,
  [Condition.HabitableToGood]: MlsConditionType.MEDIOCRE,
  [Condition.Moderate]: MlsConditionType.MODERATE,
  [Condition.ModerateToHabitable]: MlsConditionType.MODERATE,
  [Condition.Poor]: MlsConditionType.BAD,
  [Condition.PoorToModerate]: MlsConditionType.BAD,
};

export const MLS_HOUSECHARACTERISTIC = {
  [HouseCharacteristic.DikeHouse]: PropertyType.DIKE_HOUSE,
  [HouseCharacteristic.SplitLevelHouse]: PropertyType.SPLIT_LEVEL_HOUSE,
  [HouseCharacteristic.DriveInHouse]: PropertyType.DRIVE_IN_HOUSE,
  [HouseCharacteristic.SemiBungalow]: PropertyType.SEMI_BUNGALOW,
  [HouseCharacteristic.PatioHouse]: PropertyType.PATIO_HOUSE,
  [HouseCharacteristic.PileHouse]: PropertyType.PILE_HOUSE,
  [HouseCharacteristic.CourtyardHouse]: PropertyType.COURTYARD_HOUSE,
  [HouseCharacteristic.BusinessServiceHouse]:
    PropertyType.BUSINESS_SERVICE_HOUSE,
  [HouseCharacteristic.WaterHouse]: PropertyType.WATER_HOUSE,
  [HouseCharacteristic.WindWaterMill]: PropertyType.WIND_WATER_MILL,
  [HouseCharacteristic.QuadrantHouse]: PropertyType.QUADRANT_HOUSE,
};

export const MLS_APARTMENTSORT = {
  [ApartmentSort.UpstairsApartment]: PropertyType.UPSTAIRS_APARTMENT,
  [ApartmentSort.GroundFloorApartment]: PropertyType.GROUND_FLOOR_APARTMENT,
  [ApartmentSort.Maisonette]: PropertyType.MAISONETTE,
  [ApartmentSort.GalleryFlat]: PropertyType.GALLERY_FLAT,
  [ApartmentSort.PorticoFlat]: PropertyType.PORTICO_FLAT,
  [ApartmentSort.UpstairsGroundFloorApartment]:
    PropertyType.UPSTAIRS_GROUND_FLOOR_APARTMENT,
  [ApartmentSort.Penthouse]: PropertyType.PENTHOUSE,
  [ApartmentSort.PorchApartment]: PropertyType.PORCH_APARTMENT,
  [ApartmentSort.DoubleGroundFloorApartment]:
    PropertyType.DOUBLE_GROUND_FLOOR_APARTMENT,
  [ApartmentSort.Mezzanine]: PropertyType.MEZZANINE,
  [ApartmentSort.StudentRoom]: PropertyType.STUDENT_ROOM,
  [ApartmentSort.ModernApartment]: PropertyType.MODERN,
  [ApartmentSort.SovietStyleApartment]: PropertyType.SOVIETSTYLE,
  [ApartmentSort.ItalianYardApartment]: PropertyType.ITALIANYARDS,
};

export const MLS_APARTMENTCHARACTERISTIC = {
  [ApartmentCharacteristic.ServiceFlat]: PropertyType.SERVICEFLAT,
  [ApartmentCharacteristic.CommonApartment]: PropertyType.COMMON_APARTMENT,
  [ApartmentCharacteristic.CorridorFlat]: PropertyType.CORRIDOR_FLAT,
  [ApartmentCharacteristic.Beletage]: PropertyType.BELETAGE,
  [ApartmentCharacteristic.Basement]: PropertyType.BASEMENT_FLAT,
  [ApartmentCharacteristic.DoubleUpperHouse]: PropertyType.DOUBLE_UPPER_HOUSE,
  [ApartmentCharacteristic.NurseryFlat]: PropertyType.NURSERY_FLAT,
  [ApartmentCharacteristic.Chekhuri]: PropertyType.CHEKHURI,
  [ApartmentCharacteristic.Duplex]: PropertyType.DUPLEX,
  [ApartmentCharacteristic.Kalakuri]: PropertyType.KALAKURI,
  [ApartmentCharacteristic.Kavlashvili]: PropertyType.KAVLASHVILI,
  [ApartmentCharacteristic.Khrushovi]: PropertyType.KHRUSHOVI,
  [ApartmentCharacteristic.Lvovi]: PropertyType.LVOVI,
  [ApartmentCharacteristic.Moscow]: PropertyType.MOSCOW,
  [ApartmentCharacteristic.Nonstandard]: PropertyType.NONSTANDARD,
  [ApartmentCharacteristic.Triplex]: PropertyType.TRIPLEX,
  [ApartmentCharacteristic.Tukhareli]: PropertyType.TUKHARELI,
};

export const MLS_HOUSESORT = {
  [HouseSort.SingleFamilyHouse]: PropertyType.SINGLE_FAMILY_HOUSE,
  [HouseSort.Mansion]: PropertyType.MANSION,
  [HouseSort.Villa]: PropertyType.VILLA,
  [HouseSort.CountryHouse]: PropertyType.COUNTRY_HOUSE,
  [HouseSort.Bungalow]: PropertyType.BUNGALOW,
  [HouseSort.ResidentialFarm]: PropertyType.RESIDENTIAL_FARM,
  [HouseSort.CanalHouse]: PropertyType.CANAL_HOUSE,
  [HouseSort.HouseBoat]: PropertyType.HOUSEBOAT,
  [HouseSort.MobileHome]: PropertyType.MOBILE_HOME,
  [HouseSort.GipsyCart]: PropertyType.GIPSY_CART,
};

export const MLS_HOUSETYPE = {
  [HouseType.DetachedHouse]: PropertyType.DETACHED_HOUSE,
  [HouseType.LinkedHouse]: PropertyType.LINKED_HOUSE,
  [HouseType.SemiDetachedHouseOneRoof]:
    PropertyType.SEMI_DETACHED_HOUSE_ONE_ROOF,
  [HouseType.RowHouseMiddle]: PropertyType.ROW_HOUSE_MIDDLE,
  [HouseType.RowHouseCorner]: PropertyType.ROW_HOUSE_CORNER,
  [HouseType.RowHouseEnd]: PropertyType.ROW_HOUSE_END,
  [HouseType.SemiDetachedHouse]: PropertyType.SEMI_DETACHED_HOUSE,
  [HouseType.SemiDetachedLinkedHouseOneRoof]:
    PropertyType.SEMI_DETACHED_LINKED_HOUSE_ONE_ROOF,
};

export const MLS_PARKINGTYPE = {
  [ParkingType.IndoorGarage]: PropertyType.INDOOR_GARAGE,
  [ParkingType.ParkingCellar]: PropertyType.PARKING_CELLAR,
  [ParkingType.ParkingSpace]: PropertyType.PARKING_PLACE,
};

export const MLS_TYPEPARTOTHER = {
  [TypePARTOther.Berth]: PropertyType.BERTH,
  [TypePARTOther.Bottom]: PropertyType.BOTTOM_SECTION,
  [TypePARTOther.CaravanPlace]: PropertyType.GIPSY_CART_PITCH,
  [TypePARTOther.Garage]: PropertyType.GARAGE,
  // [TypePARTOther.HomeStore]: PropertyType.STORE,
  [TypePARTOther.MobileHousePlace]: PropertyType.MOBILE_HOME_PITCH,
  [TypePARTOther.ParkingSpace]: PropertyType.PARKING_PLACE,
  [TypePARTOther.Storage]: PropertyType.STOREROOM,
  [TypePARTOther.StorageSpace]: PropertyType.STORAGE_SPACE,
};

export const MLS_TYPEBOG = {
  [TypeBOG.BusinessHall]: PropertyType.PRODUCTION_HALL,
  [TypeBOG.Garagebox]: PropertyType.INDOOR_GARAGE,
  [TypeBOG.HorecaListing]: PropertyType.HOTEL_RESTAURANT_CAFE,
  [TypeBOG.Investment]: PropertyType.INVESTMENT,
  [TypeBOG.Leisure]: PropertyType.LEISURE,
  [TypeBOG.Lot]: PropertyType.LOT,
  [TypeBOG.Office]: PropertyType.OFFICE,
  [TypeBOG.Other]: PropertyType.COMMERCIAL_OTHERS,
  [TypeBOG.OutdoorArea]: PropertyType.OUTDOOR_AREA,
  [TypeBOG.PracticArea]: PropertyType.PRACTICE_SPACE,
  [TypeBOG.RetailArea]: PropertyType.RETAIL_SPACE,
  [TypeBOG.Services]: PropertyType.SERVICES,
  [TypeBOG.SocialProperty]: PropertyType.SOCIAL_PROPERTY,
  [TypeBOG.Warehouse]: PropertyType.WAREHOUSE,
  [TypeBOG.WarehouseProduction]: PropertyType.WAREHOUSE_PRODUCTION,
};

export const MLS_TYPEALV = {
  [TypeAlv.CattleFarming]: PropertyType.CATTLE_HUSBANDRY,
  [TypeAlv.DairyFarming]: PropertyType.DAIRY_FARMING_COMPANY,
  [TypeAlv.HorseFarm]: PropertyType.HORSES_COMPANY,
  [TypeAlv.HorticulturalCompany]: PropertyType.HORTICULTURAL_COMPANY,
  [TypeAlv.Listing]: PropertyType.AGRICULTURAL_HOUSE,
  [TypeAlv.LooseSoil]: PropertyType.LOOSE_SOIL,
  [TypeAlv.MeatCalves]: PropertyType.MEAT_BULLS,
  [TypeAlv.Other]: PropertyType.AGRICULTURAL_OTHERS,
  [TypeAlv.PigFarm]: PropertyType.PIG_HOLDING,
  [TypeAlv.PoultryFarm]: PropertyType.POULTRY_COMPANY,
  [TypeAlv.Premises]: PropertyType.PREMISES,
  // [TypeAlv.TillageFarm]: PropertyType.GROUND,
};

export const MLS_TYPEALVOTHER = {
  // [TypeAlvOther.BuildingPlot]: PropertyType.AGRI,
  [TypeAlvOther.Eel]: PropertyType.EEL,
  [TypeAlvOther.Fish]: PropertyType.FISH,
  [TypeAlvOther.Goats]: PropertyType.GOAT,
  [TypeAlvOther.Other]: PropertyType.AGRICULTURAL_OTHER_OTHER,
  [TypeAlvOther.Sheep]: PropertyType.SHEEP,
  [TypeAlvOther.Worms]: PropertyType.WORMS,
};
