import {
  AssignmentPhase,
  BusinessPartnerSnapShot,
  ContractReason,
  InvestmentType,
  ListingType,
  ObjectAssignment,
  RealEstateGroup,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { EXTERNALROUTES } from "@haywork/constants";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import { NewEntityType } from "@haywork/enum";
import {
  AssignmentAlvTypesComponent,
  AssignmentEditClientContainerProps,
} from "@haywork/modules/assignment";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  RawFormControl,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { Relation } from "@haywork/request";
import { FormControlUtil, StringUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./edit-client.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface AssignmentEditClientComponentProps {}
interface AssignmentEditClientComponentState {
  showHouseSort: boolean;
  showHouseType: boolean;
  showHouseCharacteristic: boolean;
  showApartmentSort: boolean;
  showApartmentCharacteristic: boolean;
  showParkingType: boolean;
  showResidentialSubtypeOthers: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditClientComponent extends React.Component<
  AssignmentEditClientComponentProps & AssignmentEditClientContainerProps,
  AssignmentEditClientComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      showHouseSort:
        this.props.objectAssignment.listingType === ListingType.House,
      showHouseType:
        !!this.props.objectAssignment.houseType ||
        !!this.props.objectAssignment.houseSort,
      showHouseCharacteristic:
        !!this.props.objectAssignment.houseCharacteristic ||
        !!this.props.objectAssignment.houseType,
      showApartmentSort:
        this.props.objectAssignment.listingType === ListingType.Apartment,
      showApartmentCharacteristic:
        !!this.props.objectAssignment.apartmentCharacteristic ||
        !!this.props.objectAssignment.apartmentSort,
      showParkingType:
        this.props.objectAssignment.listingType === ListingType.Parking,
      showResidentialSubtypeOthers:
        this.props.objectAssignment.listingType === ListingType.Other,
      newEntityVisible: false,
      newEntityOptions: null,
    };

    const { objectAssignment } = this.props;
    const { forRent } = objectAssignment;
    const forSaleValue = forRent === true ? false : true;

    this.formControls = {
      linkedVendors: { value: value(objectAssignment, "linkedVendors", []) },
      linkedEmployee: {
        value: value(objectAssignment, "linkedEmployee"),
        validators: [Validators.required()],
      },
      linkedOffice: { value: value(objectAssignment, "linkedOffice") },
      businessPartners: {
        value: value(objectAssignment, "businessPartners", []),
      },
      realEstateGroup: {
        value: value(
          objectAssignment,
          "realEstateGroup",
          RealEstateGroup.Residential
        ),
        onChange: (ref) => {
          if (ref.value === RealEstateGroup.Residential) {
            return { listingType: ListingType.House };
          } else if (ref.value === RealEstateGroup.Commercial) {
            return { listingType: ListingType.Other };
          } else if (ref.value === RealEstateGroup.Agricultural) {
            return { listingType: ListingType.ArableCompany };
          }
        },
      },
      isConfidential: { value: value(objectAssignment, "isConfidential") },
      forSale: {
        value: forSaleValue,
        validators: [Validators.required()],
      },
      listingType: {
        value: value(objectAssignment, "listingType", ListingType.House),
        onChange: this.listingTypeChange.bind(this),
      },
      houseSort: {
        value: value(objectAssignment, "houseSort"),
        onChange: (ref) => {
          if (!!ref.value) {
            this.setState({ showHouseType: true });
          } else {
            this.setState({
              showHouseType: false,
              showHouseCharacteristic: false,
            });
          }
          return { houseType: "", houseCharacteristic: "" };
        },
      },
      houseType: {
        value: value(objectAssignment, "houseType"),
        onChange: (ref) => {
          this.setState({ showHouseCharacteristic: !!ref.value });
          return { houseCharacteristic: "" };
        },
      },
      houseCharacteristic: {
        value: value(objectAssignment, "houseCharacteristic"),
      },
      apartmentSort: {
        value: value(objectAssignment, "apartmentSort"),
        onChange: (ref) => {
          this.setState({ showApartmentCharacteristic: !!ref.value });
          return { apartmentCharacteristic: "" };
        },
      },
      apartmentCharacteristic: {
        value: value(objectAssignment, "apartmentCharacteristic"),
      },
      parkingType: { value: value(objectAssignment, "parkingType") },
      residentialSubtypeOther: {
        value: value(objectAssignment, "residentialSubtypeOther"),
      },
      isNewEstate: { value: value(objectAssignment, "isNewEstate") },
      isUnderConstruction: {
        value: value(
          objectAssignment,
          "yearOfConstruction.isUnderConstruction",
          false
        ),
      },

      /* BOG specific */
      investmentType: { value: value(objectAssignment, "investmentType") },
      horecaSectorTypes: {
        value: value(objectAssignment, "horecaSectorTypes", []),
        validators: [Validators.arrayMinCount(1)],
      },

      beverageHospitalityTypes: {
        value: value(objectAssignment, "beverageHospitalityTypes", []),
      },
      fastfoodHospitalitySectorTypes: {
        value: value(objectAssignment, "fastfoodHospitalitySectorTypes", []),
      },
      restaurantHospitalitySectorTypes: {
        value: value(objectAssignment, "restaurantHospitalitySectorTypes", []),
      },
      hotelHospitalitySectorTypes: {
        value: value(objectAssignment, "hotelHospitalitySectorTypes", []),
      },

      leisureType: { value: value(objectAssignment, "leisureType") },
      socialPropertyType: {
        value: value(objectAssignment, "socialPropertyType"),
      },
      hasOfficeSpace: {
        value: value(objectAssignment, "hasOfficeSpace", false),
      },
      hasTerrain: { value: value(objectAssignment, "hasTerrain", false) },

      /* ALV specific */
      registerReason: { value: value(objectAssignment, "registerReason") },
      natureRegistration: {
        value: value(objectAssignment, "natureRegistration"),
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.onAgricultureTypeChange = this.onAgricultureTypeChange.bind(this);
  }

  public render() {
    const { employees, offices, realEstateGroups, objectAssignment } =
      this.props;
    const {
      realEstateGroup,
      listingType,
      investmentType,
      isNew,
      isConfidential,
      assignmentPhase,
    } = objectAssignment;

    const realEstateLabel =
      realEstateGroup === RealEstateGroup.Agricultural ? "lease" : "rent";
    const confidentialDisabled =
      !isConfidential && assignmentPhase === AssignmentPhase.Initiated;

    return (
      <div styleName="client">
        <div className="container-fluid">
          <Form
            name="client"
            onChange={this.onChangeHandler}
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
          >
            {/* Client */}
            <div className="form__row">
              <label htmlFor="subject">
                <ResourceText resourceKey="assignmentWhoIsTheClient" />
              </label>
              <div className="input__wrapper">
                <Input.RelationQuery
                  name="linkedVendors"
                  filterByRelationTypes={[
                    RelationType.ContactPerson,
                    RelationType.ContactCompany,
                  ]}
                  multiple
                  onAdd={this.addNewRelation}
                  onNavigateToRelation={this.props.navigate}
                  data-cy={"CY-linkedVendors"}
                />
              </div>
            </div>

            {/* Employee */}
            <div className="form__row">
              <label htmlFor="linkedEmployee">
                <ResourceText resourceKey="whosAssignment" />
              </label>
              <Input.NewSelect
                name="linkedEmployee"
                placeholder="chooseEmployee"
                values={employees}
                displayProp="displayName"
              />
            </div>

            {/* Agency */}
            <div className="form__row">
              <label htmlFor="linkedOffice">
                <ResourceText resourceKey="whichAgency" />
              </label>
              <Input.NewSelect
                name="linkedOffice"
                values={offices}
                displayProp="displayName"
              />
            </div>

            {/* Co-signers */}
            <div className="form__row">
              <label htmlFor="">
                <ResourceText resourceKey="businessPartners" />
              </label>
              <div className="input__wrapper">
                <Input.Query
                  name="businessPartners"
                  asyncValues={(term) => Relation.searchBusinessPartners(term)}
                  multiple
                  data-cy="CY-businessPartners"
                  optionValue={this.renderBusinessPartnerOption}
                  selectedValue={this.renderSelectedBusinessPartner}
                />
              </div>
            </div>

            {/* Type */}
            <div styleName="editRealEstateGroup" className="form__row">
              <label htmlFor="realEstateGroup">
                <ResourceText resourceKey="whatKindOfAssignment" />
                {!!isNew && (
                  <>
                    {" "}
                    <Ui.ImportantHint
                      message="onlyAvailableOnIsNew"
                      url={EXTERNALROUTES.SUPPORT_INFO_LISTINGTYPE.URI}
                    />
                  </>
                )}
              </label>
              <div className="form__group">
                <div className="column">
                  <Input.RadioGroup
                    name="realEstateGroup"
                    asButtonList
                    disabled={!this.props.objectAssignment.isNew}
                  >
                    {realEstateGroups.map((realEstateGroup, idx) => (
                      <Input.Radio
                        label={realEstateGroup.displayName}
                        value={realEstateGroup.value}
                        key={idx}
                      />
                    ))}
                  </Input.RadioGroup>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.RadioGroup
                    name="forSale"
                    asButtonList
                    disabled={!this.props.objectAssignment.isNew}
                  >
                    <Input.Radio
                      label="sale"
                      value={true}
                      data-cy={"CY-ObjectAssignmentSaleRadiobutton"}
                    />
                    <Input.Radio label={realEstateLabel} value={false} />
                  </Input.RadioGroup>
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Switch
                    name="isConfidential"
                    label="confidential"
                    on={true}
                    off={false}
                    disabled={confidentialDisabled}
                  />
                </div>
                {this.props.objectAssignment.assignmentPhase ===
                  AssignmentPhase.Concept && (
                  <>
                    <div className="column__spacer" />
                    <div className="column as-input">
                      <Ui.InfoLink
                        supportLink={SUPPORTURI.CONFIDENTIALASSIGNMENT}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* New state & Construction */}
            {realEstateGroup !== RealEstateGroup.Agricultural && (
              <div className="form__row">
                <label className="with-line">
                  <ResourceText resourceKey="whatKindOfBuildType" />
                </label>
                <div className="form__group">
                  <div className="column">
                    <Input.Switch
                      name="isNewEstate"
                      label="assignmentIsItAProject"
                      on={true}
                      off={false}
                    />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.Switch
                      name="isUnderConstruction"
                      label="assignmentIsItInDevelopment"
                      on={true}
                      off={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* BOG listing type */}
            {realEstateGroup === RealEstateGroup.Commercial && (
              <div className="form__row">
                <label htmlFor="listingType">
                  <ResourceText resourceKey="bogListingType" />
                  {this.props.objectAssignment.listingType ===
                    ListingType.Other && (
                    <Ui.ImportantHint
                      message="listingTypeOtherBogHint"
                      asHint
                    />
                  )}
                </label>
                <Input.RadioGroup
                  name="listingType"
                  asButtonList
                  multiline
                  disabled={!this.props.objectAssignment.isNew}
                >
                  {this.props.listingTypes.map((listingType, idx) => (
                    <Input.Radio
                      value={listingType.value}
                      label={listingType.displayName}
                      key={idx}
                      data-cy="CY-listItem"
                    />
                  ))}
                </Input.RadioGroup>
              </div>
            )}

            {/* BOG Business space types */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              listingType === ListingType.ProductionHall && (
                <div className="form__row">
                  <label htmlFor="bogBusinessSpace">
                    <ResourceText resourceKey="productionHallFunctions" />
                  </label>
                  <div className="form__group as-button-list">
                    <div className="column">
                      <Input.CheckBox
                        name="hasOfficeSpace"
                        label="hasOfficeSpace"
                        asButton
                      />
                    </div>
                    <div className="column">
                      <Input.CheckBox
                        name="hasTerrain"
                        label="hasTerrain"
                        asButton
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* BOG investment types */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              listingType === ListingType.Investment && (
                <div className="form__row">
                  <label htmlFor="investmentType">
                    <ResourceText resourceKey="investmentType" />
                  </label>
                  <Input.RadioGroup
                    name="investmentType"
                    asButtonList
                    multiline
                  >
                    {this.props.investmentTypeOptions.map(
                      (investmentType, idx) => (
                        <Input.Radio
                          value={investmentType.value}
                          label={investmentType.displayName}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>
              )}

            {/* BOG horeca types */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              listingType === ListingType.HotelRestaurantCafe && (
                <div className="form__row" styleName="horeca-types">
                  <span styleName="title">
                    <ResourceText resourceKey="kindOfHorecaType" />
                  </span>
                  <div styleName="row">
                    <div styleName="label">
                      <label htmlFor="beverageHospitalityTypes">
                        <ResourceText resourceKey="beveragesSector" />
                      </label>
                    </div>
                    <div styleName="fields">
                      <Input.Multi
                        name="beverageHospitalityTypes"
                        values={this.props.beverageHospitalitySectorTypes}
                      />
                    </div>
                  </div>

                  <div styleName="row">
                    <div styleName="label">
                      <label htmlFor="fastfoodHospitalitySectorTypes">
                        <ResourceText resourceKey="fastfoodSector" />
                      </label>
                    </div>
                    <div styleName="fields">
                      <Input.Multi
                        name="fastfoodHospitalitySectorTypes"
                        values={this.props.fastfoodHospitalitySectorTypes}
                      />
                    </div>
                  </div>

                  <div styleName="row">
                    <div styleName="label">
                      <label htmlFor="restaurantHospitalitySectorTypes">
                        <ResourceText resourceKey="restaurantSector" />
                      </label>
                    </div>
                    <div styleName="fields">
                      <Input.Multi
                        name="restaurantHospitalitySectorTypes"
                        values={this.props.restaurantHospitalitySectorTypes}
                      />
                    </div>
                  </div>

                  <div styleName="row">
                    <div styleName="label">
                      <label htmlFor="hotelHospitalitySectorTypes">
                        <ResourceText resourceKey="hotelSector" />
                      </label>
                    </div>
                    <div styleName="fields">
                      <Input.Multi
                        name="hotelHospitalitySectorTypes"
                        values={this.props.hotelHospitalitySectorTypes}
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* BOG horeca types when investment type HotelRestaurantCafe */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              investmentType === InvestmentType.HotelRestaurantCafe && (
                <div className="form__row">
                  <label htmlFor="horecaSectorTypes">
                    <ResourceText resourceKey="horecaSectorTypes" />
                  </label>
                  <Input.Multi
                    name="horecaSectorTypes"
                    values={this.props.horecaSectorTypeOptions}
                  />
                </div>
              )}

            {/* BOG leisure types */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              listingType === ListingType.Leisure && (
                <div className="form__row">
                  <label htmlFor="leisureType">
                    <ResourceText resourceKey="leisureType" />
                  </label>
                  <Input.RadioGroup name="leisureType" asButtonList multiline>
                    {this.props.leisureTypeOptions.map((leisureType, idx) => (
                      <Input.Radio
                        value={leisureType.value}
                        label={leisureType.displayName}
                        key={idx}
                        data-cy="CY-leisureTypeOption"
                      />
                    ))}
                  </Input.RadioGroup>
                </div>
              )}

            {/* BOG social property types */}
            {realEstateGroup === RealEstateGroup.Commercial &&
              listingType === ListingType.SocialProperty && (
                <div className="form__row">
                  <label htmlFor="socialPropertyType">
                    <ResourceText resourceKey="socialPropertyType" />
                  </label>
                  <Input.RadioGroup
                    name="socialPropertyType"
                    asButtonList
                    multiline
                  >
                    {this.props.socialPropertyTypeOptions.map(
                      (socialPropertyType, idx) => (
                        <Input.Radio
                          value={socialPropertyType.value}
                          label={socialPropertyType.displayName}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>
              )}

            {/* ALV listing types */}
            {realEstateGroup === RealEstateGroup.Agricultural && (
              <AssignmentAlvTypesComponent
                listingTypes={this.props.listingTypes}
                objectAssignment={this.props.objectAssignment}
                objectAssignmentUpdated={this.onAgricultureTypeChange}
                horseCompanySubtypes={this.props.horseCompanySubtypes}
                horticulturalCompanyTypes={this.props.horticulturalCompanyTypes}
                pigCompanyTypes={this.props.pigCompanyTypes}
                agriculturalSubtypeOthers={this.props.agriculturalSubtypeOthers}
                cattleFarmingSubtypes={this.props.cattleFarmingSubtypes}
                houseSorts={this.props.houseSorts}
                houseCharacteristics={this.props.houseCharacteristics}
                houseTypes={this.props.houseTypes}
                alvBuildingPlotDestinationOptions={
                  this.props.alvBuildingPlotDestinationOptions
                }
              />
            )}

            {/* Object characteristics */}
            {realEstateGroup === RealEstateGroup.Residential && (
              <div className="form__row">
                <div className="form__group">
                  <div
                    className={classNames("column", {
                      "push-label":
                        !isNew &&
                        (this.state.showApartmentSort ||
                          this.state.showResidentialSubtypeOthers ||
                          this.state.showHouseSort),
                    })}
                  >
                    {!!isNew && (
                      <label>
                        <Ui.ImportantHint
                          message="onlyAvailableOnIsNew"
                          url={EXTERNALROUTES.SUPPORT_INFO_LISTINGTYPE.URI}
                        />
                      </label>
                    )}
                    <Input.List
                      name="listingType"
                      values={this.props.listingTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                      isRoot
                      disabled={!this.props.objectAssignment.isNew}
                      data-cy="CY-residentialListingType"
                    />
                  </div>

                  {/* House */}
                  {this.state.showHouseSort && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column">
                        <label>
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.List
                          name="houseSort"
                          values={this.props.houseSorts}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-houseSortListingType"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.showHouseType && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column">
                        <label>
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.List
                          name="houseType"
                          values={this.props.houseTypes}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-houseTypeListingType"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.showHouseCharacteristic && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column push-label">
                        <Input.List
                          name="houseCharacteristic"
                          values={this.props.houseCharacteristics}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-houseCharacteristicsListingType"
                        />
                      </div>
                    </div>
                  )}

                  {/* Apartment */}
                  {this.state.showApartmentSort && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column">
                        <label>
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.List
                          name="apartmentSort"
                          values={this.props.apartmentSorts}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-apartmentSortsListingType"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.showApartmentCharacteristic && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column">
                        <label>
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.List
                          name="apartmentCharacteristic"
                          values={this.props.apartmentCharacteristics}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-apartmentCharacteristicsListingType"
                        />
                      </div>
                    </div>
                  )}

                  {/* Parking */}
                  {this.state.showParkingType && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column push-label">
                        <Input.List
                          name="parkingType"
                          values={this.props.parkingTypeOptions}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-parkingTypeListingType"
                        />
                      </div>
                    </div>
                  )}

                  {/* Other */}
                  {this.state.showResidentialSubtypeOthers && (
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column">
                        <label>
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.List
                          name="residentialSubtypeOther"
                          values={this.props.residentialSubtypeOthers}
                          displayPath="displayName"
                          comparePath="value"
                          resultPath="value"
                          data-cy="CY-residentialSubtypeListingType"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {realEstateGroup === RealEstateGroup.Agricultural && (
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label htmlFor="registerReason">
                      <ResourceText resourceKey="registerReasonLabel" />
                      <Ui.RequiredForPublish />
                    </label>
                    <Input.NewSelect
                      name="registerReason"
                      placeholder="makeAChoice"
                      values={this.props.registerReasonOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="natureRegistration">
                      <ResourceText resourceKey="natureRegistrationLabel" />
                      <Ui.RequiredForPublish />
                    </label>
                    <Input.NewSelect
                      name="natureRegistration"
                      placeholder="makeAChoice"
                      values={this.props.natureRegistrationOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                </div>
              </div>
            )}
          </Form>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentEditClientComponentProps &
      AssignmentEditClientContainerProps
  ) {
    const { objectAssignment: newObjectAssignment } = nextProps;
    const { objectAssignment: oldObjectAssignment } = this.props;

    if (
      has(newObjectAssignment, "linkedVendors") &&
      get(newObjectAssignment, "linkedVendors", []).length !==
        get(oldObjectAssignment, "linkedVendors", []).length &&
      !!this.formRef
    ) {
      const { linkedVendors } = newObjectAssignment;
      this.formRef.update({ linkedVendors });
    }

    if (
      !!this.formRef &&
      get(this.props.objectAssignment, "dateTimeModified") !==
        get(nextProps.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = nextProps;
      const { forRent } = objectAssignment;
      const forSaleValue = forRent === true ? false : true;

      this.formRef.update(
        {
          linkedVendors: value(objectAssignment, "linkedVendors", []),
          linkedEmployee: value(objectAssignment, "linkedEmployee"),
          linkedOffice: value(objectAssignment, "linkedOffice"),
          businessPartners: value(objectAssignment, "businessPartners", []),
          realEstateGroup: value(
            objectAssignment,
            "realEstateGroup",
            RealEstateGroup.Residential
          ),
          isConfidential: value(objectAssignment, "isConfidential"),
          forSale: forSaleValue,
          listingType: value(
            objectAssignment,
            "listingType",
            ListingType.House
          ),
          houseSort: value(objectAssignment, "houseSort"),
          houseType: value(objectAssignment, "houseType"),
          houseCharacteristic: value(objectAssignment, "houseCharacteristic"),
          apartmentSort: value(objectAssignment, "apartmentSort"),
          apartmentCharacteristic: value(
            objectAssignment,
            "apartmentCharacteristic"
          ),
          parkingType: value(objectAssignment, "parkingType"),
          residentialSubtypeOther: value(
            objectAssignment,
            "residentialSubtypeOther"
          ),
          isNewEstate: value(objectAssignment, "isNewEstate"),
          isUnderConstruction: value(
            objectAssignment,
            "yearOfConstruction.isUnderConstruction",
            false
          ),
          investmentType: value(objectAssignment, "investmentType"),
          horecaSectorTypes: value(objectAssignment, "horecaSectorTypes", []),
          beverageHospitalityTypes: value(
            objectAssignment,
            "beverageHospitalityTypes",
            []
          ),
          fastfoodHospitalitySectorTypes: value(
            objectAssignment,
            "fastfoodHospitalitySectorTypes",
            []
          ),
          restaurantHospitalitySectorTypes: value(
            objectAssignment,
            "restaurantHospitalitySectorTypes",
            []
          ),
          hotelHospitalitySectorTypes: value(
            objectAssignment,
            "hotelHospitalitySectorTypes",
            []
          ),
          leisureType: value(objectAssignment, "leisureType"),
          socialPropertyType: value(objectAssignment, "socialPropertyType"),
          hasOfficeSpace: value(objectAssignment, "hasOfficeSpace", false),
          hasTerrain: value(objectAssignment, "hasTerrain", false),
          registerReason: value(objectAssignment, "registerReason"),
          natureRegistration: value(objectAssignment, "natureRegistration"),
        },
        true
      );
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const { currentComponentState } = this.props;
    let objectAssignment: ObjectAssignment;

    switch (values.realEstateGroup) {
      case RealEstateGroup.Residential: {
        objectAssignment = this.mapValuesToResidentialObjectAssignment(values);
        break;
      }
      case RealEstateGroup.Commercial: {
        objectAssignment = this.mapValuesToCommercialObjectAssignment(values);
        break;
      }
      case RealEstateGroup.Agricultural: {
        objectAssignment = this.mapValuesToAgriculturalObjectAssignment(values);
        break;
      }
      default:
        return;
    }

    const newState = {
      ...currentComponentState,
      objectAssignment,
    };

    this.props.updateAssignment(newState, this.props.path);
  }

  private onAgricultureTypeChange(objectAssignment: ObjectAssignment) {
    const { currentComponentState } = this.props;
    const newState = {
      ...currentComponentState,
      objectAssignment,
    };

    this.formRef.update({ listingType: objectAssignment.listingType });
    this.props.updateAssignment(newState, this.props.path);
  }

  private mapValuesToResidentialObjectAssignment(
    values: FormReturnValue
  ): ObjectAssignment {
    const characteristics = this.getObjectCharacteristics(values);

    return {
      ...this.props.objectAssignment,
      ...characteristics,
      listingType: values.listingType,
      forSale: values.forSale,
      forRent: !values.forSale,
      isConfidential: values.isConfidential,
      yearOfConstruction: {
        ...this.props.objectAssignment.yearOfConstruction,
        isUnderConstruction: values.isUnderConstruction,
      },
      isNewEstate: values.isNewEstate,
      businessPartners: values.businessPartners,
      linkedEmployee: values.linkedEmployee,
      linkedOffice: values.linkedOffice,
      linkedVendors: values.linkedVendors,
      realEstateGroup: values.realEstateGroup,
      contractReason: !values.forSale
        ? ContractReason.ForRent
        : ContractReason.ForSale,
    };
  }

  private mapValuesToAgriculturalObjectAssignment(
    values: FormReturnValue
  ): ObjectAssignment {
    return {
      ...this.props.objectAssignment,
      listingType: values.listingType,
      forSale: values.forSale,
      forRent: !values.forSale,
      isConfidential: values.isConfidential,
      yearOfConstruction: {
        ...this.props.objectAssignment.yearOfConstruction,
        isUnderConstruction: values.isUnderConstruction,
      },
      isNewEstate: values.isNewEstate,
      businessPartners: values.businessPartners,
      linkedEmployee: values.linkedEmployee,
      linkedOffice: values.linkedOffice,
      linkedVendors: values.linkedVendors,
      realEstateGroup: values.realEstateGroup,
      contractReason: !values.forSale
        ? ContractReason.ForRent
        : ContractReason.ForSale,
      natureRegistration: values.natureRegistration,
      registerReason: values.registerReason,
    };
  }

  private mapValuesToCommercialObjectAssignment(
    values: FormReturnValue
  ): ObjectAssignment {
    let fields = {};

    switch (values.listingType) {
      case ListingType.ProductionHall: {
        fields = {
          hasOfficeSpace: values.hasOfficeSpace,
          hasTerrain: values.hasTerrain,
          leisureType: null,
          investmentType: null,
          socialPropertyType: null,
          horecaSectorTypes: [],
          beverageHospitalityTypes: [],
          fastfoodHospitalitySectorTypes: [],
          restaurantHospitalitySectorTypes: [],
          hotelHospitalitySectorTypes: [],
        };
        break;
      }
      case ListingType.HotelRestaurantCafe: {
        fields = {
          hasOfficeSpace: null,
          hasTerrain: null,
          leisureType: null,
          investmentType: null,
          socialPropertyType: null,
          horecaSectorTypes: [],
          beverageHospitalityTypes: values.beverageHospitalityTypes,
          fastfoodHospitalitySectorTypes: values.fastfoodHospitalitySectorTypes,
          restaurantHospitalitySectorTypes:
            values.restaurantHospitalitySectorTypes,
          hotelHospitalitySectorTypes: values.hotelHospitalitySectorTypes,
        };
        break;
      }
      case ListingType.Leisure: {
        fields = {
          leisureType: values.leisureType,
          hasOfficeSpace: null,
          hasTerrain: null,
          investmentType: null,
          socialPropertyType: null,
          horecaSectorTypes: [],
          beverageHospitalityTypes: [],
          fastfoodHospitalitySectorTypes: [],
          restaurantHospitalitySectorTypes: [],
          hotelHospitalitySectorTypes: [],
        };
        break;
      }
      case ListingType.Investment: {
        fields = {
          investmentType: values.investmentType,
          hasOfficeSpace: null,
          hasTerrain: null,
          leisureType: null,
          socialPropertyType: null,
          horecaSectorTypes: values.horecaSectorTypes,
          beverageHospitalityTypes: [],
          fastfoodHospitalitySectorTypes: [],
          restaurantHospitalitySectorTypes: [],
          hotelHospitalitySectorTypes: [],
        };
        break;
      }
      case ListingType.SocialProperty: {
        fields = {
          socialPropertyType: values.socialPropertyType,
          hasOfficeSpace: null,
          hasTerrain: null,
          leisureType: null,
          investmentType: null,
          horecaSectorTypes: [],
          beverageHospitalityTypes: [],
          fastfoodHospitalitySectorTypes: [],
          restaurantHospitalitySectorTypes: [],
          hotelHospitalitySectorTypes: [],
        };
        break;
      }
      default:
        break;
    }

    return {
      ...this.props.objectAssignment,
      ...fields,
      forSale: values.forSale,
      forRent: !values.forSale,
      isConfidential: values.isConfidential,
      yearOfConstruction: {
        ...this.props.objectAssignment.yearOfConstruction,
        isUnderConstruction: values.isUnderConstruction,
      },
      isNewEstate: values.isNewEstate,
      businessPartners: values.businessPartners,
      linkedEmployee: values.linkedEmployee,
      linkedOffice: values.linkedOffice,
      linkedVendors: values.linkedVendors,
      realEstateGroup: values.realEstateGroup,
      listingType: values.listingType,
      contractReason: !values.forSale
        ? ContractReason.ForRent
        : ContractReason.ForSale,
    };
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedVendors } = this.formRef.getValues();
    linkedVendors = linkedVendors || [];

    this.formRef.update({
      linkedVendors: [...linkedVendors, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private listingTypeChange(ref: RawFormControl) {
    switch (ref.value) {
      case ListingType.House: {
        this.setState({
          showHouseSort: true,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
          showParkingType: false,
          showResidentialSubtypeOthers: false,
        });
        return {
          apartmentSort: "",
          apartmentCharacteristic: "",
          parkingType: "",
          residentialSubtypeOther: "",
        };
      }
      case ListingType.Apartment: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: true,
          showApartmentCharacteristic: false,
          showParkingType: false,
          showResidentialSubtypeOthers: false,
        });
        return {
          parkingType: "",
          residentialSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
        };
      }
      case ListingType.Parking: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
          showParkingType: true,
          showResidentialSubtypeOthers: false,
        });
        return {
          apartmentSort: "",
          apartmentCharacteristic: "",
          residentialSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
        };
      }
      case ListingType.Other: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
          showParkingType: false,
          showResidentialSubtypeOthers: true,
        });
        return {
          apartmentSort: "",
          apartmentCharacteristic: "",
          parkingType: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
        };
      }
      default: {
        this.setState({
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
          showApartmentSort: false,
          showApartmentCharacteristic: false,
          showParkingType: false,
          showResidentialSubtypeOthers: false,
        });
        return {
          apartmentSort: "",
          apartmentCharacteristic: "",
          parkingType: "",
          residentialSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
        };
      }
    }
  }

  private getObjectCharacteristics(values: FormReturnValue): FormReturnValue {
    switch (values.listingType) {
      case ListingType.House: {
        return {
          houseSort: values.houseSort,
          houseType: values.houseType,
          houseCharacteristic: values.houseCharacteristic,
          apartmentSort: null,
          apartmentCharacteristic: null,
          parkingType: null,
          residentialSubtypeOther: null,
        };
      }
      case ListingType.Apartment: {
        return {
          houseSort: null,
          houseType: null,
          houseCharacteristic: null,
          apartmentSort: values.apartmentSort,
          apartmentCharacteristic: values.apartmentCharacteristic,
          parkingType: null,
          residentialSubtypeOther: null,
        };
      }
      case ListingType.Parking: {
        return {
          houseSort: null,
          houseType: null,
          houseCharacteristic: null,
          apartmentSort: null,
          apartmentCharacteristic: null,
          parkingType: values.parkingType,
          residentialSubtypeOther: null,
        };
      }
      case ListingType.Other: {
        return {
          houseSort: null,
          houseType: null,
          houseCharacteristic: null,
          apartmentSort: null,
          apartmentCharacteristic: null,
          parkingType: null,
          residentialSubtypeOther: values.residentialSubtypeOther,
        };
      }
      default: {
        return {
          houseSort: null,
          houseType: null,
          houseCharacteristic: null,
          apartmentSort: null,
          apartmentCharacteristic: null,
          parkingType: null,
          residentialSubtypeOther: null,
        };
      }
    }
  }

  private renderBusinessPartnerOption(
    value: BusinessPartnerSnapShot,
    query: string
  ): QueryOptionReturnValue {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return (
      <div dangerouslySetInnerHTML={StringUtil.highlight(displayName, query)} />
    );
  }

  private renderSelectedBusinessPartner(
    value: BusinessPartnerSnapShot
  ): QueryResultReturnValue<any> {
    let displayName = [value.displayName, value.locality]
      .filter((v) => !!v)
      .join(", ");
    if (has(value, "associationInfo.membershipNumber")) {
      displayName = `${displayName} (${get(
        value,
        "associationInfo.membershipNumber"
      )})`;
    }

    return {
      value,
      template: <div>{displayName}</div>,
    };
  }
}
