import { Invoice } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import * as ActionType from "./invoice.types";

const updateInvoiceFilter = (payload: ActionType.InvoiceFilter) => ({
  type: REDUX.INVOICES.UPDATE_INVOICE_FILTER,
  ...payload
});

const setSingleInvoice = (payload: ActionType.SingleInvoice) => ({
  type: REDUX.INVOICES.SET_SINGLE_INVOICE,
  ...payload
});

const setSingleInvoiceDirectly = (invoice: Invoice) => ({
  type: REDUX.INVOICES.SET_SINGLE_INVOICE,
  invoice
});

const setSingleInvoiceStatus = (invoiceStatus: string) => ({
  type: REDUX.INVOICES.SET_SINGLE_INVOICE_STATUS,
  invoiceStatus
});

const setAddressSearchStatus = (addressSearchStatus: string) => ({
  type: REDUX.INVOICES.SET_INVOICE_ADDRESS_SEARCH_STATUS,
  addressSearchStatus
});

const setFormalName = (formalName: string) => ({
  type: REDUX.INVOICES.SET_INVOICE_FORMAL_NAME,
  formalName
});

const setFinancialAdministrations = (
  payload: ActionType.InvoiceFinancialAdministrations
) => ({
  type: REDUX.INVOICES.SET_FINANCIAL_ADMINISTRATIONS_INVOICE,
  ...payload
});

const setFinancialAdministration = (
  payload: ActionType.InvoiceFinancialAdministration
) => ({
  type: REDUX.INVOICES.SET_FINANCIAL_ADMINISTRATION_INVOICE,
  ...payload
});

const setInvoiceLinkedAssignment = (payload: ActionType.LinkedAssignment) => ({
  type: REDUX.INVOICES.SET_LINKED_ASSIGMENT,
  ...payload
});

const setPrintTemplates = (payload: ActionType.PrintTemplates) => ({
  type: REDUX.INVOICES.SET_PRINT_TEMPLATES,
  ...payload
});

const setPrintExportOptions = (payload: ActionType.PrintExportOptions) => ({
  type: REDUX.INVOICES.SET_PRINT_EXPORT_OPTIONS,
  ...payload
});

const removeInvoiceFromList = (id: string) => ({
  type: REDUX.INVOICES.REMOVE_INVOICE_FROM_LIST,
  id
});

export const InvoiceActions = {
  updateInvoiceFilter,
  setSingleInvoice,
  setSingleInvoiceDirectly,
  setSingleInvoiceStatus,
  setAddressSearchStatus,
  setFormalName,
  setFinancialAdministrations,
  setFinancialAdministration,
  setInvoiceLinkedAssignment,
  setPrintTemplates,
  setPrintExportOptions,
  removeInvoiceFromList
};
