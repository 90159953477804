import { actions as ListActions } from "./actions";
import { reducer as listReducer, State } from "./reducer";
import { SortOrder } from "@haywork/api/kolibri";

export type MLSOrder = {
  sortOrder: SortOrder;
  sortColumn: any;
};

export enum RelativeDateValue {
  Today = "Today",
  Yesterday = "Yesterday",
  DayBeforeYesterday = "DayBeforeYesterday",
  OneWeekAgo = "OneWeekAgo",
  TwoWeeksAgo = "TwoWeeksAgo",
  AMonthAgo = "AMonthAgo",
}

export type ListState = State;
export { ListActions, listReducer };
