import { Floor, Space } from "@haywork/api/kolibri";

export interface ExtendedFloor extends Floor {
  id?: string;
  spaces: ExtendedSpace[];
}
export interface ExtendedSpace extends Space {
  id?: string;
}

export {
  FloorsAndSpacesContainer as FloorsAndSpaces
} from "./floors-and-spaces.container";
