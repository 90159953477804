import * as React from "react";
import {
  FC,
  memo,
  useRef,
  MutableRefObject,
  useMemo,
  CSSProperties,
  useEffect,
  useCallback,
  useState
} from "react";
import { ModalPortal } from "@haywork/portals";
import isNumber from "lodash-es/isNumber";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

export enum ContextMenuPosition {
  TopLeft = "TopLeft",
  TopCenter = "TopCenter",
  TopRight = "TopRight",
  CenterLeft = "Center",
  CenterCenter = "CenterCenter",
  CenterRight = "CenterRight",
  BottomLeft = "BottomLeft",
  BottomCenter = "BottomCenter",
  BottomRight = "BottomRight"
}

type Props = {
  visible: boolean;
  parent: MutableRefObject<Element>;
  position?: ContextMenuPosition | "mouse";
  bindOn?: ContextMenuPosition;
  width?: number | "parent";
  maxWidth?: number | "parent";
  lockXAxis?: boolean;
  lockYAxis?: boolean;
  gutter?: number;
  push?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  onClickOutside?: () => void;
  onScroll?: () => void;
};

export const ContextMenuComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      visible,
      parent,
      position: pPosition,
      bindOn: pBindOn,
      width: pWidth,
      maxWidth: pMaxWidth,
      lockXAxis,
      lockYAxis,
      gutter: pGutter,
      children,
      onClickOutside,
      onScroll,
      push
    }) => {
      const element = useRef<HTMLDivElement>();
      const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
      const gutter = useMemo(() => pGutter || 16, [pGutter]);
      const position = useMemo(() => pPosition || "mouse", [pPosition]);
      const bindOn = useMemo(() => pBindOn || ContextMenuPosition.TopLeft, [
        pBindOn
      ]);

      const calculatePosition = useCallback(() => {
        if (!parent || !parent.current) return { newLeft: 0, newTop: 0 };
        const {
          left,
          width,
          top,
          height
        } = parent.current.getBoundingClientRect();
        let newLeft: number;
        let newTop: number;

        switch (position) {
          case ContextMenuPosition.TopLeft:
          case ContextMenuPosition.CenterLeft:
          case ContextMenuPosition.BottomLeft: {
            newLeft = left;
            break;
          }
          case ContextMenuPosition.TopCenter:
          case ContextMenuPosition.CenterCenter:
          case ContextMenuPosition.BottomCenter: {
            newLeft = left + width / 2;
            break;
          }
          default: {
            newLeft = left + width;
            break;
          }
        }

        switch (position) {
          case ContextMenuPosition.TopLeft:
          case ContextMenuPosition.TopCenter:
          case ContextMenuPosition.TopRight: {
            newTop = top;
            break;
          }
          case ContextMenuPosition.CenterLeft:
          case ContextMenuPosition.CenterCenter:
          case ContextMenuPosition.CenterRight: {
            newTop = top + height / 2;
            break;
          }
          default: {
            newTop = top + height;
            break;
          }
        }

        return { newLeft, newTop };
      }, [position]);

      const calculateBindOn = useCallback(
        (left: number, top: number) => {
          if (!element.current) return { newLeft: left, newTop: top };
          const { width, height } = element.current.getBoundingClientRect();
          let newLeft = left;
          let newTop = top;

          switch (bindOn) {
            case ContextMenuPosition.TopCenter:
            case ContextMenuPosition.CenterCenter:
            case ContextMenuPosition.BottomCenter: {
              newLeft = left - width / 2;
              break;
            }
            case ContextMenuPosition.TopRight:
            case ContextMenuPosition.BottomRight:
            case ContextMenuPosition.CenterRight: {
              newLeft = left - width;
              break;
            }
            default: {
              newLeft = left;
              break;
            }
          }

          switch (bindOn) {
            case ContextMenuPosition.CenterLeft:
            case ContextMenuPosition.CenterCenter:
            case ContextMenuPosition.CenterRight: {
              newTop = top - height / 2;
              break;
            }
            case ContextMenuPosition.BottomLeft:
            case ContextMenuPosition.BottomCenter:
            case ContextMenuPosition.BottomRight: {
              newTop = top - height;
              break;
            }
            default: {
              newTop = top;
              break;
            }
          }

          return { newLeft, newTop };
        },
        [bindOn]
      );

      const calculatePush = useCallback(
        (left: number, top: number) => {
          if (!push) return { pushLeft: left, pushTop: top };
          let pushLeft = left;
          let pushTop = top;

          switch (true) {
            case !!push.top: {
              pushTop += push.top;
              break;
            }
            case !!push.right: {
              pushLeft -= push.right;
              break;
            }
            case !!push.bottom: {
              pushTop -= push.bottom;
              break;
            }
            case !!push.left: {
              pushLeft += push.left;
              break;
            }
            default:
              break;
          }

          return { pushLeft, pushTop };
        },
        [push]
      );

      const resetFromGutter = useCallback(
        (left: number, top: number) => {
          if (!element.current)
            return {
              newLeft: left,
              newTop: top,
              newMaxWidth: "auto" as number | "auto",
              newMaxHeight: "auto" as number | "auto"
            };
          const { width, height } = element.current.getBoundingClientRect();
          const { innerWidth, innerHeight } = window;
          let newLeft = left;
          let newTop = top;
          let newMaxWidth: number | "auto" = "auto";
          let newMaxHeight: number | "auto" = "auto";

          switch (true) {
            case width > innerWidth + gutter * 2: {
              newLeft = gutter;
              newMaxWidth = innerWidth - gutter * 2;
              break;
            }
            case left < gutter && !lockXAxis: {
              newLeft = gutter;
              break;
            }
            case left + width > innerWidth: {
              newLeft = innerWidth - gutter - width;
              break;
            }
            default:
              break;
          }

          switch (true) {
            case height > innerHeight + gutter * 2: {
              newTop = gutter;
              newMaxHeight = innerHeight - gutter * 2;
              break;
            }
            case top < gutter && !lockYAxis: {
              newTop = gutter;
            }
            case top + height > innerHeight: {
              newTop = innerHeight - gutter - height;
              break;
            }
            default:
              break;
          }

          return { newLeft, newTop, newMaxWidth, newMaxHeight };
        },
        [lockXAxis, lockYAxis, gutter]
      );

      const style = useMemo(() => {
        let visibility = "hidden";
        let top = 0;
        let left = 0;
        let maxWidth = pMaxWidth || "auto";
        let maxHeight: number | "auto" = "auto";
        let width = isNumber(pWidth) ? pWidth : "auto";

        if (!!parent && !!parent.current && !!element.current) {
          visibility = visible ? "visible" : "hidden";

          if (pWidth === "parent") {
            const {
              width: parentWidth
            } = parent.current.getBoundingClientRect();
            width = parentWidth;
          }

          if (!!push) {
            switch (true) {
              case !!push.top: {
                top += push.top;
                break;
              }
              case !!push.right: {
                left -= push.right;
                break;
              }
              case !!push.bottom: {
                top -= push.bottom;
                break;
              }
              case !!push.left: {
                left += push.left;
                break;
              }
              default:
                break;
            }
          }

          if (position === "mouse") {
            const { pushLeft, pushTop } = calculatePush(
              mousePosition.x,
              mousePosition.y
            );
            const { newLeft, newTop } = calculateBindOn(pushLeft, pushTop);
            top = newTop;
            left = newLeft;
          } else {
            const { newLeft: calcLeft, newTop: calcTop } = calculatePosition();
            const { pushLeft, pushTop } = calculatePush(calcLeft, calcTop);
            const { newLeft, newTop } = calculateBindOn(pushLeft, pushTop);

            top = newTop;
            left = newLeft;
          }

          const {
            newLeft,
            newTop,
            newMaxWidth,
            newMaxHeight
          } = resetFromGutter(left, top);

          top = newTop;
          left = newLeft;
          maxWidth = newMaxWidth;
          maxHeight = newMaxHeight;
        }

        return {
          visibility,
          top,
          left,
          width,
          maxWidth,
          maxHeight
        } as CSSProperties;
      }, [
        visible,
        pWidth,
        pMaxWidth,
        position,
        mousePosition,
        bindOn,
        resetFromGutter,
        calculateBindOn,
        calculatePosition,
        calculatePush
      ]);

      const handleClickOutside = useCallback(
        (event: MouseEvent) => {
          if (
            !onClickOutside ||
            !visible ||
            !element.current ||
            !parent ||
            !parent.current
          )
            return;
          if (
            element.current.contains(event.target as Node) ||
            parent.current.contains(event.target as Node)
          )
            return;
          onClickOutside();
        },
        [visible, onClickOutside]
      );

      const handleScrollEvent = useCallback(
        (event: MouseEvent) => {
          if (
            !onScroll ||
            !visible ||
            (!!element.current &&
              element.current.contains(event.target as Node))
          ) {
            return;
          }
          onScroll();
        },
        [visible, onScroll]
      );

      const handleMousePositionOnClick = useCallback(
        (event: MouseEvent) => {
          if (visible || position !== "mouse") return;

          setMousePosition({
            x: event.clientX,
            y: event.clientY
          });
        },
        [visible, position, setMousePosition]
      );

      useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [handleClickOutside]);

      useEffect(() => {
        document.addEventListener("scroll", handleScrollEvent, true);
        return () => {
          document.removeEventListener("scroll", handleScrollEvent, true);
        };
      }, [handleScrollEvent]);

      useEffect(() => {
        document.addEventListener("click", handleMousePositionOnClick);
        return () => {
          document.removeEventListener("click", handleMousePositionOnClick);
        };
      }, [handleMousePositionOnClick]);

      return (
        <ModalPortal>
          <div ref={element} style={style} styleName="context-menu">
            {children}
          </div>
        </ModalPortal>
      );
    }
  )
);
