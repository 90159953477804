import { Language, ProjectAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  ProjectEditMarketingOpenHouseComponent,
  ProjectEditMarketingOpenHouseComponentProps
} from "@haywork/modules/project";
import { AppState, EditableActions, SingleProjectState } from "@haywork/stores";
import { connect } from "react-redux";

export interface StateProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  languages: Language[];
  defaultLanguage: Language;
}

interface DispatchProps {
  updateProject: (componentState: SingleProjectState, path: string) => void;
}

const mapStateToProps = (state: AppState) => {
  const { currentComponentState } = state.editable;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type ProjectEditMarketingOpenHouseContainerProps = StateProps &
  DispatchProps;
export const ProjectEditMarketingOpenHouseContainer = connect<
  StateProps,
  DispatchProps,
  ProjectEditMarketingOpenHouseComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingOpenHouseComponent);
