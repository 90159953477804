import { MAINROUTES } from "@haywork/constants";
import { AppState, EditableActions } from "@haywork/stores";
import { push } from "connected-react-router";
import escapeRegExp from "lodash-es/escapeRegExp";
import { Dispatch } from ".";

const remove = (path: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { states, mainReturnPath } = state.editable;

    const editable = states.find((editable) =>
      new RegExp(escapeRegExp(editable.path), "gi").test(path)
    );

    if (!editable) return;
    dispatch(EditableActions.removeState({ path }));
    if (!!editable.active) {
      dispatch(push(editable?.referrer || mainReturnPath));
    }
  };
};

const clearAllTabs = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(push(MAINROUTES.DASHBOARD.URI));
    dispatch(EditableActions.clearAll());
  };
};

export const EditableThunks = {
  remove,
  clearAllTabs,
};
