import I18n from "@haywork/components/i18n";
import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import AppData from "./components/app-data";
import BrowserData from "./components/browser-data";
import LiveData from "./components/live-data";
import Locale from "./components/locale";
import Presence from "./components/presence";
import { HealthContainerProps } from "./health.container";
import Button from "@haywork/components/ui/button";
import * as bowser from "bowser";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import * as moment from "moment";

const styles = require("./style.scss");

export type HealthModuleProps = {};
type Props = HealthModuleProps & HealthContainerProps;

export const HealthModule: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ userVersion, errors, messages }) => {
      const [generatingReport, setGeneratingReport] = useState(false);
      const [generatingEmailRaport, setGeneratingEmailRaport] = useState(false);

      const createHealthReport = useCallback(async () => {
        if (generatingReport) return;
        try {
          setGeneratingReport(true);

          const data = bowser.getParser(window.navigator.userAgent);
          const {
            type: platformType,
            vendor: platformVendor,
            model: platformModel,
          } = data.getPlatform();
          const { name: engineName, version: engineVersion } = data.getEngine();
          const { width: screenWidth, height: screenHeight } = screen;

          let report: { [key: string]: any } = {
            platformType,
            platformVendor,
            platformModel,
            engineName,
            engineVersion,
            browserName: data.getBrowserName(),
            browserVersion: data.getBrowserVersion(),
            osName: data.getOSName(),
            osVersion: data.getOSVersion(),
            screenWidth,
            screenHeight,
          };

          report = {
            ...report,
            errors: errors.map((error) => JSON.parse(error)),
          };

          const zip = new JSZip();
          zip.file("report.json", JSON.stringify(report, null, 2));

          const content = await zip.generateAsync({ type: "blob" });
          saveAs(
            content,
            `kolibri-health-${moment().format("DDMMYYYYHHmm")}.zip`
          );
        } finally {
          setGeneratingReport(false);
        }
      }, [errors, generatingReport, setGeneratingReport]);

      const createEmailRaport = useCallback(async () => {
        try {
          setGeneratingEmailRaport(true);
          const report = {
            messages: messages.map((message) => ({
              ...message,
              payload: JSON.parse(message.payload),
            })),
          };

          const zip = new JSZip();
          zip.file("report.json", JSON.stringify(report, null, 2));

          const content = await zip.generateAsync({ type: "blob" });
          saveAs(
            content,
            `kolibri-mailsocketlog-${moment().format("DDMMYYYYHHmm")}.zip`
          );
        } finally {
          setGeneratingEmailRaport(false);
        }
      }, [messages, setGeneratingEmailRaport]);

      return (
        <div styleName="health">
          <div styleName="inner">
            <div styleName="section">
              <h2>
                <I18n value="health.title.appData" />
              </h2>
              <AppData userVersion={userVersion} />
            </div>

            <div styleName="section">
              <h2>
                <I18n value="health.title.dateTimeAndLocale" />
              </h2>
              <Locale />
            </div>

            <div styleName="section">
              <h2>
                <I18n value="health.title.browserData" />
              </h2>
              <BrowserData />
            </div>

            <div styleName="section">
              <h2>
                <I18n value="health.title.liveData" />
              </h2>
              <LiveData />
            </div>

            <div styleName="section">
              <h2>
                <I18n value="health.title.presence" />
              </h2>
              <Presence />
            </div>

            <div styleName="section">
              <Button
                label="health.action.createReport"
                onClick={createHealthReport}
                disabled={generatingReport}
              />

              {!!messages.length && (
                <Button
                  label="health.action.createEmailLoggingReport"
                  onClick={createEmailRaport}
                  disabled={generatingEmailRaport}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  )
);
