import { WidgetEntityType } from "@haywork/api/authorization";
import { RelationType, TimelineActionType } from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { RelationTimelineContainerProps } from "@haywork/modules/relation";
import TimelineComponent from "@haywork/modules/shared/components/timeline";
import * as React from "react";

export interface RelationTimelineComponentProps {}
interface State {}
type Props = RelationTimelineComponentProps & RelationTimelineContainerProps;

export class RelationTimelineComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.appendTimelineEvents = this.appendTimelineEvents.bind(this);
  }

  public componentDidMount() {
    this.props.getTimelineEvents(this.props.selectedRelationId);

    const widgetEntityType =
      this.props.relationType === RelationType.ContactPerson
        ? WidgetEntityType.ContactPerson
        : WidgetEntityType.ContactCompany;

    this.props.getInitializedWidgets(
      this.props.selectedRelationId,
      widgetEntityType
    );
  }

  public render() {
    const timelineEvents = this.props.timelineEvents;
    return (
      <React.Fragment>
        <ErrorBoundary>
          <TimelineComponent
            name={this.props.relationName}
            title={this.props.relationTitle}
            timelineEvents={timelineEvents}
            timeLineStatistics={this.props.timeLineStatistics}
            relationTimelineStatus={this.props.relationTimelineStatus}
            onTimelineActionTypeChange={(timelineActionTypes) =>
              this.changeTimelineFilters(timelineActionTypes)
            }
            onScrollEndOfTimeline={() => this.appendTimelineEvents()}
            emptyStateTitle="emptyStateNoTimelineTitle"
            emptyStateText="emptyStateNoTimelineText"
            agendaItemCategories={this.props.agendaItemCategories}
            taskCategories={this.props.taskCategories}
            parentId={this.props.selectedRelationId}
            canLoadMore={this.props.canLoadMore}
            authorizationWidgetEntityType={
              this.props.relationType === RelationType.ContactPerson
                ? WidgetEntityType.ContactPerson
                : WidgetEntityType.ContactCompany
            }
            linkedRelation={{
              id: this.props.selectedRelationId,
              displayName: this.props.relationTitle,
              typeOfRelation: this.props.relationType,
            }}
            onRefresh={() =>
              this.props.getTimelineEvents(this.props.selectedRelationId)
            }
            addNotes={true}
          />
        </ErrorBoundary>
      </React.Fragment>
    );
  }

  private appendTimelineEvents() {
    this.props.getTimelineEvents(this.props.selectedRelationId, false);
  }

  private changeTimelineFilters(timelineActionTypes: TimelineActionType[]) {
    this.props.getTimelineEvents(
      this.props.selectedRelationId,
      true,
      timelineActionTypes
    );
  }
}
