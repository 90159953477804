import * as React from "react";
import * as CSSModules from "react-css-modules";

import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { TemplateDefinition } from "@haywork/api/kolibri";

const styles = require("./preview.component.scss");

interface Props {
  visible: boolean;
  template: TemplateDefinition;
  onClose: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailTemplatePreview extends React.Component<
  Props,
  State
> {
  public render() {
    if (!this.props.template) return null;

    const template = this.props.template.mergeTemplates.find(
      (template) => template.key === "template"
    );

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="emailTemplatePreview.ModalTitle" close={true} />
        <ModalBody noPadding={true}>
          <div styleName="preview__wrapper">
            <div
              styleName="preview"
              dangerouslySetInnerHTML={{ __html: template.value }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
