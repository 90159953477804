import {
  BatchItem,
  FolderTreeFileEntity,
  FolderTreeFileType,
} from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import { KEYCODE } from "@haywork/constants";
import classNames from "classnames";
import * as React from "react";
import { memo, useCallback, useEffect, useState } from "react";
import * as CSSModules from "react-css-modules";
import { getEmptyImage } from "react-dnd-html5-backend";
import DocumentSession from "./item-document-session";
import Dossier from "./item-dossier";
import Invoice from "./item-invoice";
import { DossierItemContainerProps } from "./item.container";

const styles = require("./style.scss");

export interface DossierItemComponentProps {
  file: FolderTreeFileEntity;
  depth: number;
  pathname: string;
  rootId: string;
  justAddedFiles: string[];
  selectedFiles: string[];
  bulkSelectedFiles: FolderTreeFileEntity[];
  onRefresh: () => void;
  onToggleSelect: (file: FolderTreeFileEntity, pathname: string) => void;
  onToggleSelectForBulk: (file: FolderTreeFileEntity) => void;
  onCreateMailWithAttachments: (attachments: UploadResponse[]) => void;
}
type Props = DossierItemComponentProps & DossierItemContainerProps;

export const DossierItemComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      file,
      depth,
      rootId,
      pathname,
      token,
      onRefresh,
      connectDragSource,
      navigate,
      downloadDocument,
      updateDossierItem,
      archiveDocumentSession,
      unArchiveDocumentSession,
      deleteDocumentSession,
      archiveInvoice,
      unArchiveInvoice,
      deleteInvoice,
      deleteDossier,
      justAddedFiles,
      onToggleSelect,
      selectedFiles,
      connectDragPreview,
      createDocumentSessionEditSession,
      createDossierItemEditSession,
      onToggleSelectForBulk,
      bulkSelectedFiles,
      canSendEmail,
      createBatchDownload,
      uploadFilesFromUri,
      currentAccount,
      onCreateMailWithAttachments,
      downloadInvoice,
      updateDocumentSession,
    }: Props) => {
      const [canSelect, setCanSelect] = useState(false);
      const handleKeyDown = (event: KeyboardEvent) => {
        const isCtrl = [KEYCODE.CTRL, KEYCODE.CMD].includes(event.keyCode);
        if (isCtrl) {
          event.preventDefault();
        }
        setCanSelect(isCtrl);
      };
      const handleKeyUp = () => {
        setCanSelect(false);
      };
      const handleClick = () => {
        if (!canSelect) return;
        onToggleSelect(file, pathname);
      };
      useEffect(() => {
        window.addEventListener("keydown", handleKeyDown, true);
        window.addEventListener("keyup", handleKeyUp, true);

        return () => {
          window.removeEventListener("keydown", handleKeyDown, true);
          window.removeEventListener("keyup", handleKeyUp, true);
        };
      }, []);
      useEffect(() => {
        if (!!connectDragPreview) {
          connectDragPreview(getEmptyImage(), { captureDraggingState: true });
        }
      }, [!!connectDragPreview]);

      const onDeleteHandler = useCallback(
        async (id: string, type: FolderTreeFileType, parentId?: string) => {
          switch (type) {
            case FolderTreeFileType.DocumentSession: {
              await deleteDocumentSession(id, onRefresh);
              onRefresh();
              return;
            }
            case FolderTreeFileType.DossierItem: {
              if (!parentId) return;
              await deleteDossier(id, parentId, onRefresh);
              onRefresh();
              return;
            }
            case FolderTreeFileType.Invoice: {
              await deleteInvoice(id, onRefresh);
              onRefresh();
              return;
            }
            default:
              return;
          }
        },
        [deleteDocumentSession, deleteDossier, deleteInvoice, onRefresh]
      );

      const onEmailHandler = useCallback(
        async (file: BatchItem) => {
          if (!file || !currentAccount) return;

          const items = await createBatchDownload(file);
          const attachments = await uploadFilesFromUri(currentAccount, items);

          onCreateMailWithAttachments(attachments);
        },
        [
          currentAccount,
          createBatchDownload,
          uploadFilesFromUri,
          onCreateMailWithAttachments,
        ]
      );

      if (!file || !file.type || file.type === FolderTreeFileType.Unknown)
        return null;

      const renderType = () => {
        switch (file.type) {
          case FolderTreeFileType.DocumentSession: {
            return (
              <DocumentSession
                file={file}
                rootId={rootId}
                bulkSelectedFiles={bulkSelectedFiles}
                canSendEmail={canSendEmail}
                onToggleSelectForBulk={onToggleSelectForBulk}
                onNavigate={navigate}
                onDownloadDocument={downloadDocument}
                onRefresh={onRefresh}
                onArchive={archiveDocumentSession}
                onUnArchive={unArchiveDocumentSession}
                onDelete={(id) =>
                  onDeleteHandler(id, FolderTreeFileType.DocumentSession)
                }
                createEditSession={createDocumentSessionEditSession}
                onEmail={onEmailHandler}
                onUpdateDocumentSession={updateDocumentSession}
              />
            );
          }
          case FolderTreeFileType.DossierItem: {
            return (
              <Dossier
                file={file}
                rootId={rootId}
                token={token}
                canSendEmail={canSendEmail}
                bulkSelectedFiles={bulkSelectedFiles}
                onToggleSelectForBulk={onToggleSelectForBulk}
                onUpdateDossierItem={updateDossierItem}
                onRefresh={onRefresh}
                onDelete={(id, parentId) =>
                  onDeleteHandler(id, FolderTreeFileType.DossierItem, parentId)
                }
                justAddedFiles={justAddedFiles || []}
                createEditSession={createDossierItemEditSession}
                onEmail={onEmailHandler}
              />
            );
          }
          case FolderTreeFileType.Invoice: {
            return (
              <Invoice
                file={file}
                rootId={rootId}
                bulkSelectedFiles={bulkSelectedFiles}
                canSendEmail={canSendEmail}
                onToggleSelectForBulk={onToggleSelectForBulk}
                onNavigate={navigate}
                onArchive={archiveInvoice}
                onUnArchive={unArchiveInvoice}
                onDelete={(id) =>
                  onDeleteHandler(id, FolderTreeFileType.Invoice)
                }
                onRefresh={onRefresh}
                onEmail={onEmailHandler}
                onDownload={downloadInvoice}
              />
            );
          }
          default: {
            return null;
          }
        }
      };

      return connectDragSource(
        <div
          styleName={classNames("item", `depth-${depth}`, {
            selected: selectedFiles.includes(file.id),
          })}
          onClick={handleClick}
        >
          {renderType()}
        </div>
      );
    }
  )
);
