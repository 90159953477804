import {
  AcquisitionObjectAssignment,
  AirTreatmentOption,
  CertificationOption,
  ConditionOption,
  ConstructionOptionOption,
  ConstructionPeriodOption,
  FacilityOption,
  FurnishingOption,
  IndustrialBuildingFacilityOption,
  LeisureFacilityOption,
  LeisureType,
  ParkingFacilityOption,
  PavementTypeOption,
  RealEstateGroup,
  ResidentialBuildingTypeOption,
  SocialPropertyFacilityOption,
  SocialPropertyType,
  TypeOfBusinessLawOption,
  TypeOfCommunalAreaOption,
  WelfareStateOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, EditableActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquistionAssignmentEditObjectCommercialScreen,
  AcquistionAssignmentEditObjectCommercialScreenProps,
} from "./assignments-edit-object-commercial";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  constructionPeriods: ConstructionPeriodOption[];
  welfareStateOptions: WelfareStateOption[];
  airTreatmentOptions: AirTreatmentOption[];
  industrialBuildingFacilityOptions: IndustrialBuildingFacilityOption[];
  facilityOptions: FacilityOption[];
  constructionOptionOptions: ConstructionOptionOption[];
  leisureFacilityTypeOptions: LeisureFacilityOption[];
  residentialBuildingTypeOptions: ResidentialBuildingTypeOption[];
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[];
  typesOfCommunalArea: TypeOfCommunalAreaOption[];
  pavementTypeOptions: PavementTypeOption[];
  conditionOptions: ConditionOption[];
  certificationOptions: CertificationOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  furnishingOptions: FurnishingOption[];
};
type DispatchProps = {
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) => void;
};

const mapCertificationOptions = (
  certificationOptions: CertificationOption[],
  realEstateGroup: RealEstateGroup
): CertificationOption[] => {
  return certificationOptions.filter((option) => {
    return option.realEstateGroups.indexOf(realEstateGroup) !== -1;
  });
};

const mapSocialPropertyFacilityTypeOptions = (
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[],
  socialPropertyType: SocialPropertyType
): SocialPropertyFacilityOption[] => {
  return socialPropertyFacilityTypeOptions.filter((option) => {
    return option.socialPropertyTypes.indexOf(socialPropertyType) !== -1;
  });
};

const mapLeisureFacilityTypeOptions = (
  leisureFacilityTypeOptions: LeisureFacilityOption[],
  leisureType: LeisureType
): LeisureFacilityOption[] => {
  return leisureFacilityTypeOptions.filter((option) => {
    return option.leisureTypes.indexOf(leisureType) !== -1;
  });
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditObjectCommercialScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable.currentComponentState;
  const {
    constructionPeriods,
    welfareStateOptions,
    airTreatmentOptions,
    industrialBuildingFacilityOptions,
    facilityOptions,
    constructionOptionOptions,
    residentialBuildingTypeOptions,
    typesOfCommunalArea,
    pavementTypeOptions,
    certificationOptions,
    parkingFacilityOptions,
    typeOfBusinessLawOptions,
    furnishingOptions,
    leisureFacilityTypeOptions,
    socialPropertyFacilityTypeOptions,
    conditionOptions,
  } = state.main.mastertable.kolibri;
  return {
    acquisitionObjectAssignment,
    constructionPeriods,
    conditionOptions,
    welfareStateOptions,
    airTreatmentOptions,
    industrialBuildingFacilityOptions,
    constructionOptionOptions,
    residentialBuildingTypeOptions,
    typesOfCommunalArea,
    pavementTypeOptions,
    certificationOptions: mapCertificationOptions(
      certificationOptions,
      acquisitionObjectAssignment.realEstateGroup
    ),
    facilityOptions,
    leisureFacilityTypeOptions: mapLeisureFacilityTypeOptions(
      leisureFacilityTypeOptions,
      acquisitionObjectAssignment.leisureType
    ),
    socialPropertyFacilityTypeOptions: mapSocialPropertyFacilityTypeOptions(
      socialPropertyFacilityTypeOptions,
      acquisitionObjectAssignment.socialPropertyType
    ),
    parkingFacilityOptions,
    furnishingOptions,
    typeOfBusinessLawOptions,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditObjectCommercialScreenProps
> = (dispatch) => ({
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AcquistionAssignmentEditObjectCommercialScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditObjectCommercialScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditObjectCommercialScreen);
