import { AssignmentSnapShot } from "@haywork/api/kolibri";
import {
  Dispatch,
  FailedBuildNumberRequest,
  ProjectsThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import {
  AddBuildnumbersComponent,
  AddBuildnumbersComponentProps
} from "./add-buildnumbers.component";

interface StateProps {
  addBuildnumbersVisible: boolean;
  objectTypes: AssignmentSnapShot[];
  objectTypesStatus: string;
}
interface DispatchProps {
  getObjectTypes: (projectId: string) => void;
  defineAndSaveNewFromBatch: (
    buildnumbers: number[],
    objectTypeId: string,
    price: number,
    projectId: string
  ) => Promise<FailedBuildNumberRequest[]>;
}

const mapStateToProps = <StateProps, AddBuildnumbersComponentProps>(
  state: AppState
) => {
  const {
    addBuildnumbersVisible,
    objectTypes,
    objectTypesStatus
  } = state.project.buildnumbers;

  return {
    addBuildnumbersVisible,
    objectTypes,
    objectTypesStatus
  };
};

const mapDispatchToProps = <DispatchProps, AddBuildnumbersComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getObjectTypes: (projectId: string) =>
    dispatch(ProjectsThunks.Buildnumbers.getObjectTypes(projectId)),
  defineAndSaveNewFromBatch: (
    buildnumbers: number[],
    objectTypeId: string,
    price: number,
    projectId: string
  ) =>
    dispatch(
      ProjectsThunks.Buildnumbers.defineAndSaveNewFromBatch(
        buildnumbers,
        objectTypeId,
        price,
        projectId
      )
    )
});

export type AddBuildnumbersContainerProps = StateProps & DispatchProps;
export const AddBuildnumbersContainer = connect<
  StateProps,
  DispatchProps,
  AddBuildnumbersComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(AddBuildnumbersComponent);
