import { ProductOrder } from "@haywork/api/kolibri";

export * from "./product-order.component";
export * from "./marketing-budget-modal.component";

export interface ExtendedProductOrder {
  checked: boolean;
  displayName: string;
  productOrder: ProductOrder;
}
