import { REDUX } from "@haywork/constants";
import * as ActionType from "./relation-groups.types";

const setRelationGroups = (payload: ActionType.RelationGroups) => ({
  type: REDUX.RELATION_GROUPS.SET_RELATION_GROUPS,
  ...payload
});

const setRelationGroup = (payload: ActionType.SingleRelationGroup) => ({
  type: REDUX.RELATION_GROUPS.SET_RELATION_GROUP,
  ...payload
});

const addRelationGroup = (payload: ActionType.RelationGroup) => ({
  type: REDUX.RELATION_GROUPS.NEW_RELATION_GROUP,
  ...payload
});

const editRelationGroup = (payload: ActionType.RelationGroup) => ({
  type: REDUX.RELATION_GROUPS.EDIT_RELATION_GROUP,
  ...payload
});

const deleteRelationGroup = (payload: ActionType.Id) => ({
  type: REDUX.RELATION_GROUPS.DELETE_RELATION_GROUP,
  ...payload
});

const unDeleteRelationGroup = (payload: ActionType.Id) => ({
  type: REDUX.RELATION_GROUPS.UNDELETE_RELATION_GROUP,
  ...payload
});

const deleteFromState = (payload: ActionType.Id) => ({
  type: REDUX.RELATION_GROUPS.DELETE_FROM_STATE,
  ...payload
});

const setRelationGroupsStatus = (relationGroupsStatus: string) => ({
  type: REDUX.RELATION_GROUPS.SET_RELATION_GROUPS_STATUS,
  relationGroupsStatus
});

const setRelationAddGroupsStatus = (relationAddGroupsStatus: string) => ({
  type: REDUX.RELATION_GROUPS.SET_RELATION_ADD_GROUPS_STATUS,
  relationAddGroupsStatus
});

export const RelationGroupsActions = {
  setRelationGroups,
  addRelationGroup,
  editRelationGroup,
  deleteRelationGroup,
  unDeleteRelationGroup,
  deleteFromState,
  setRelationGroupsStatus,
  setRelationAddGroupsStatus,
  setRelationGroup
};
