import { AccountShare } from "@haywork/api/mail";

export enum ActionType {
  SetShares = "EmailV2.Shares.SetShares",
  ResetApp = "RESET_APP",
  Clear = "EmailV2.Main.Clear",
}

type SetShares = {
  type: ActionType.SetShares;
  shares: AccountShare[];
};

const setShares = (shares: AccountShare[]) => ({
  type: ActionType.SetShares,
  shares,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

type Clear = {
  type: ActionType.Clear;
};

export type ActionTypes = SetShares | ResetApp | Clear;
export const actions = {
  setShares,
};
