import { RelationSnapShot } from "@haywork/api/kolibri";
import { Dispatch, OfficeThunk } from "@haywork/middleware";
import { OfficeOverviewComponent } from "@haywork/modules/settings/modules/offices/components/overview/office-overview.component";
import { AppState, OfficeActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  officeInitialOverviewState: string;
  officeOverviewState: string;
  offices: RelationSnapShot[];
  canLoadMore: boolean;
  totalOffices: number;
}

interface DispatchProps {
  goToOfficeDetail: (url: string) => void;
  setOfficeID: (id: string) => void;
  getOffices: (init?: boolean, take?: number) => void;
  clearOffices: () => void;
  archiveOffice: (office: RelationSnapShot) => void;
  unArchiveOffice: (office: RelationSnapShot) => void;
  removeOfficeFromList: (office: RelationSnapShot) => void;
  createOffice: () => void;
}

const mapStateToProps = <StateProps, OfficeOverviewComponentProps>(
  state: AppState
) => ({
  officeInitialOverviewState: state.offices.officeInitialOverviewState,
  officeOverviewState: state.offices.officeOverviewState,
  offices: state.offices.offices,
  canLoadMore:
    state.offices.officeOverviewPage < state.offices.officeOverviewCount,
  totalOffices: state.offices.totalOfficesCount
});

const mapDispatchToProps = <DispatchProps, OfficeOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  goToOfficeDetail: (url: string) => dispatch(push(url)),
  setOfficeID: (id: string) => dispatch(OfficeActions.setOfficeID(id)),
  getOffices: (init?, take?) => dispatch(OfficeThunk.getOffices(init, take)),
  clearOffices: () => dispatch(OfficeActions.clearOffices()),
  archiveOffice: (office: RelationSnapShot) =>
    dispatch(OfficeThunk.archiveOffice(office)),
  unArchiveOffice: (office: RelationSnapShot) =>
    dispatch(OfficeThunk.unArchiveOffice(office)),
  removeOfficeFromList: (office: RelationSnapShot) =>
    dispatch(OfficeActions.archiveOffice(office)),
  createOffice: () => dispatch(OfficeThunk.defineNewOffice())
});

export type OfficeOverviewContainerProps = StateProps & DispatchProps;
export const OfficeOverviewContainer = connect<
  StateProps,
  DispatchProps,
  OfficeOverviewContainerProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OfficeOverviewComponent);
