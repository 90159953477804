import { Garden, GardenType } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FloorSpace } from "../room-layout.component";
import { GardenContainerProps } from "./containers";

const styles = require("./forms.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface RoomLayoutGardenFormComponentProps {
  space: FloorSpace<Garden>;
  onDelete: () => void;
  onSubmit: (space: FloorSpace<Garden>, closeExpand: boolean) => void;
}
interface State {
  showRequiredForPublish: boolean;
}
type Props = RoomLayoutGardenFormComponentProps & GardenContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class RoomLayoutGardenFormComponent extends React.PureComponent<
  Props,
  State
> {
  private formControls: FormControls;
  private sizeFormControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeSubmitHandler = this.onChangeSubmitHandler.bind(this);
    this.onOkayClickHandler = this.onOkayClickHandler.bind(this);

    this.state = {
      showRequiredForPublish:
        get(this.props.space, "space.gardenType") !==
        GardenType.SurroundingGarden,
    };

    this.formControls = {
      gardenType: {
        value: value(
          this.props.space,
          "space.gardenType",
          GardenType.BackGarden
        ),
        onChange: (ref) => {
          this.setState({
            showRequiredForPublish: ref.value !== GardenType.SurroundingGarden,
          });
        },
      },
      orientation: { value: value(this.props.space, "space.orientation") },
      gardenQuality: { value: value(this.props.space, "space.gardenQuality") },
      isMainGarden: {
        value: value(this.props.space, "space.isMainGarden", false),
      },
      hasBackEntrance: {
        value: value(this.props.space, "space.hasBackEntrance", false),
      },
      size: { value: value(this.props.space, "space.size") },
    };

    this.sizeFormControls = {
      length: {
        value: "",
        onChange: this.onChangeHandler,
      },
      width: {
        value: "",
        onChange: this.onChangeHandler,
      },
      area: { value: "" },
    };
  }

  public render() {
    return (
      <Form
        name="room-layout-garden"
        formControls={this.formControls}
        asSubForm
        form={(ref) => (this.formRef = ref)}
        onSubmit={this.onSubmitHandler}
        onChange={this.onChangeSubmitHandler}
      >
        {/* Garden type */}
        <div className="form__row">
          <label htmlFor="gardenType">
            <ResourceText resourceKey="gardenTypes" />
          </label>
          <Input.RadioGroup name="gardenType" asButtonList>
            {this.props.gardenTypeOptions.map((gardenTypeOption, idx) => (
              <Input.Radio
                value={gardenTypeOption.value}
                label={gardenTypeOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Size */}
        <Input.Object name="size" formControls={this.sizeFormControls}>
          <div className="form__row">
            <label htmlFor="size">
              <ResourceText resourceKey="gardenSize" />
            </label>
            <div styleName="size-controls">
              <div styleName="column">
                {/* Garden length */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label className="pre">
                        <ResourceText resourceKey="roomLengthLabel" />
                        {!!this.state.showRequiredForPublish && (
                          <Ui.RequiredForPublish />
                        )}
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <Input.Number name="length" pretty />
                        <div className="post">
                          <ResourceText resourceKey="meterShort" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Garden width */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label className="pre">
                        <ResourceText resourceKey="roomWidthLabel" />
                        {!!this.state.showRequiredForPublish && (
                          <Ui.RequiredForPublish />
                        )}
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <Input.Number name="width" pretty />
                        <div className="post">
                          <ResourceText resourceKey="meterShort" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Garden area */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label className="pre">
                        <ResourceText resourceKey="roomAreaLabel" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <Input.Number name="area" pretty round />
                        <div className="post">
                          <ResourceText resourceKey="squareMeters" asHtml />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Input.Object>

        {/* Garden info */}
        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <Input.CheckBox name="isMainGarden" label="isMainGarden" />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.CheckBox name="hasBackEntrance" label="hasBackEntrance" />
            </div>
          </div>
        </div>

        {/* Garden orientation */}
        <div className="form__row">
          <label htmlFor="orientation">
            <ResourceText resourceKey="gardenOrientation" />
          </label>
          <Input.RadioGroup name="orientation" asButtonList>
            {this.props.orientations.map((orientation, idx) => (
              <Input.Radio
                value={orientation.value}
                label={orientation.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>

        {/* Garden quality */}
        <div className="form__row">
          <label htmlFor="gardenQuality">
            <ResourceText resourceKey="gardenQuality" />
          </label>
          <Input.RadioGroup name="gardenQuality" asButtonList>
            {this.props.gardenQualityOptions.map((gardenQualityOption, idx) => (
              <Input.Radio
                value={gardenQualityOption.value}
                label={gardenQualityOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>

        <div styleName="divider" />

        <div styleName="button-footer">
          <div className="btn btn-success" onClick={this.onOkayClickHandler}>
            <ResourceText resourceKey="okay" />
          </div>
          <div className="btn btn-danger" onClick={this.props.onDelete}>
            <ResourceText resourceKey="remove" />
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(
    ref: RawFormControl,
    get: (name: string) => RawFormControl
  ): FormReturnValue {
    const length = get("length");
    const width = get("width");

    const values = {};

    if (length.value && width.value) {
      const area = parseFloat(length.value) * parseFloat(width.value);
      values["area"] = area;
    }

    return Object.keys(values).length > 0 ? values : null;
  }

  private onOkayClickHandler() {
    if (!this.formRef) return;
    this.formRef.submit();
  }

  private onChangeSubmitHandler(values: FormReturnValue) {
    this.props.onSubmit(this.getUpdatedSpace(values), false);
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.props.onSubmit(this.getUpdatedSpace(values), true);
  }

  private getUpdatedSpace(values: FormReturnValue): FloorSpace<Garden> {
    const { space } = this.props;
    return {
      ...space,
      space: {
        ...space.space,
        gardenType: values.gardenType,
        orientation: values.orientation,
        gardenQuality: values.gardenQuality,
        isMainGarden: values.isMainGarden,
        hasBackEntrance: values.hasBackEntrance,
        size: {
          ...space.space.size,
          ...values.size,
        },
      },
    };
  }
}
