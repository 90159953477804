import {
  TemplateDefinitionSnapShot,
  TemplateDefinition,
  TemplateDefinitionCategorySnapShot,
  MergeField
} from "@haywork/api/kolibri";

export enum emailTemplatesActions {
  SetTemplates = "EmailTemplates.SetTemplates",
  SetTemplatesStatus = "EmailTemplates.SetTemplatesStatus",
  SetSingle = "EmailTemplates.SetSingle",
  SetSingleStatus = "EmailTemplates.SetSingleStatus",
  SetCategories = "EmailTemplates.SetCategories",
  SetMergeFields = "EmailTemplates.SetMergeFields",
  DeleteTemplate = "EmailTemplates.DeleteTemplate"
}

interface SetTemplates {
  type: emailTemplatesActions.SetTemplates;
  templates: TemplateDefinitionSnapShot[];
}
const setTemplates = (templates: TemplateDefinitionSnapShot[]) => ({
  type: emailTemplatesActions.SetTemplates,
  templates
});

interface SetTemplatesStatus {
  type: emailTemplatesActions.SetTemplatesStatus;
  templatesStatus: string;
}
const setTemplatesStatus = (templatesStatus: string) => ({
  type: emailTemplatesActions.SetTemplatesStatus,
  templatesStatus
});

interface SetSingle {
  type: emailTemplatesActions.SetSingle;
  single: TemplateDefinition;
}
const setSingle = (single: TemplateDefinition) => ({
  type: emailTemplatesActions.SetSingle,
  single
});

interface SetSingleStatus {
  type: emailTemplatesActions.SetSingleStatus;
  singleStatus: string;
}
const setSingleStatus = (singleStatus: string) => ({
  type: emailTemplatesActions.SetSingleStatus,
  singleStatus
});

interface SetCategories {
  type: emailTemplatesActions.SetCategories;
  categories: TemplateDefinitionCategorySnapShot[];
}
const setCategories = (categories: TemplateDefinitionCategorySnapShot[]) => ({
  type: emailTemplatesActions.SetCategories,
  categories
});

interface SetMergeFields {
  type: emailTemplatesActions.SetMergeFields;
  mergeFields: MergeField[];
}
const setMergeFields = (mergeFields: MergeField[]) => ({
  type: emailTemplatesActions.SetMergeFields,
  mergeFields
});

interface DeleteTemplate {
  type: emailTemplatesActions.DeleteTemplate;
  id: string;
}
const deleteTemplate = (id: string) => ({
  type: emailTemplatesActions.DeleteTemplate,
  id
});

export type EmailTemplatesActions =
  | SetTemplates
  | SetTemplatesStatus
  | SetSingle
  | SetSingleStatus
  | SetCategories
  | SetMergeFields
  | DeleteTemplate;

export const Actions = {
  setTemplates,
  setTemplatesStatus,
  setSingle,
  setSingleStatus,
  setCategories,
  setMergeFields,
  deleteTemplate
};
