import { InvoiceSnapShot } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Column, Row } from "@haywork/components/ui/list";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, FormattedNumber } from "react-intl";
import LoadingState from "../loading-state";
import Actions from "../row-actions";
import { RouteUtil } from "@haywork/util";
import { INVOICEROUTES } from "@haywork/constants";
import RelationPill from "./relation-pill";
import AssignmentPill from "./assignment-pill";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  data: InvoiceSnapShot | null;
  zebra: boolean;
  canMail: boolean;
  onNavigate: (path: string) => void;
};

export const RowComponent: FC<Props> = memo(
  ({ data, zebra, canMail, onNavigate }) => {
    return !data ? (
      <LoadingState zebra={zebra} />
    ) : (
      <DataRowComponent
        data={data}
        zebra={zebra}
        onNavigate={onNavigate}
        canMail={canMail}
      />
    );
  }
);

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ data, zebra, canMail, onNavigate }) => {
      const {
        id,
        isActive,
        displayName,
        invoiceTotalAmount,
        invoiceDate,
        dueDate,
        isOverdue,
        status,
        linkedAssignment,
      } = data;

      const linkedRelations = (data.linkedRelations || []).filter((d) => !!d);

      const navigate = useCallback(() => {
        onNavigate(route(INVOICEROUTES.DETAIL.URI, { id }));
      }, [id, onNavigate]);

      return (
        <Row zebra={zebra} onClick={navigate}>
          <Column index={0}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              {displayName}
            </div>
          </Column>
          <Column index={1}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              <FormattedNumber
                value={invoiceTotalAmount}
                style="currency"
                currency="EUR"
              />
            </div>
          </Column>
          <Column index={2}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              <FormattedDate
                value={invoiceDate}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          </Column>
          <Column index={3}>
            <div
              styleName={classNames("column", {
                overdue: isOverdue,
                inactive: !isActive,
              })}
            >
              <div styleName="label">
                <FormattedDate
                  value={dueDate}
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                />
              </div>
              {isOverdue && isActive && (
                <div styleName="icon">
                  <Icon name="exclamation-triangle" color={Colors.Warning} />
                </div>
              )}
            </div>
          </Column>
          <Column index={4}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              <I18n prefix="invoiceStatuses" value={status.toString()} />
              {!isActive && (
                <>
                  , <I18n value="archived" />
                </>
              )}
            </div>
          </Column>
          <Column index={5}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              {(linkedRelations || []).map((relation) => (
                <RelationPill
                  relation={relation}
                  key={relation.id}
                  onNavigate={onNavigate}
                  isActive={isActive}
                />
              ))}
            </div>
          </Column>
          <Column index={6}>
            <div styleName={classNames("column", { inactive: !isActive })}>
              {!!linkedAssignment && (
                <AssignmentPill
                  assignment={linkedAssignment}
                  onNavigate={onNavigate}
                  isActive={isActive}
                />
              )}
            </div>
          </Column>
          <Actions item={data} canMail={canMail} />
        </Row>
      );
    }
  )
);
