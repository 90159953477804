import {
  DocumentSession,
  DocumentTemplate,
  Form,
  FormElement,
} from "@haywork/api/kolibri";
import {
  DynamicDocumentsThunks,
  MapDispatchToProps,
} from "@haywork/middleware";
import {
  DynamicDocumentsFormComponent,
  DynamicDocumentsFormComponentProps,
} from "@haywork/modules/dynamic-documents";
import { AppState, DynamicDocumentsPreviewActions } from "@haywork/stores";
import { DynamicDocumentsSingleState } from "@haywork/stores/dynamic-documents/single";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  componentState: DynamicDocumentsSingleState;
  session: DocumentSession;
  template: DocumentTemplate;
  formElements: FormElement[];
  userFieldName: string;
  visibleFormElements: string[];
}
interface DispatchProps {
  updateDocumentSession: (session: DocumentSession) => void;
  focusOnAnchor: (name: string) => void;
}

const mapFormElements = (form: Form): FormElement[] => {
  return form.sections.reduce((state, section) => {
    const elements = section.elements || [];
    elements.map((element) => {
      if (!!element.formElements && element.formElements.length > 0) {
        state.push(element);
      }
    });
    return state;
  }, []);
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsFormComponentProps,
  AppState
> = (state) => {
  const componentState: DynamicDocumentsSingleState =
    state.editable.currentComponentState;
  const { session, template } = componentState;
  const { userFieldName, visibleFormElements } = state.dynamicDocuments.preview;
  const { leftSideScrollPosition } = state.scrollPosition;
  return {
    componentState,
    session,
    template,
    formElements: mapFormElements(template.form),
    userFieldName,
    visibleFormElements,
    leftSideScrollPosition,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsFormComponentProps
> = (dispatch) => ({
  updateDocumentSession: (session: DocumentSession) =>
    dispatch(DynamicDocumentsThunks.updateDocumentSession(session, true)),
  focusOnAnchor: (name: string) => {
    dispatch(DynamicDocumentsPreviewActions.focusOnAnchor({ name }));
    setTimeout(() => dispatch(DynamicDocumentsPreviewActions.resetFocus()), 5);
  },
});

export type DynamicDocumentsFormContainerProps = StateProps & DispatchProps;
export const DynamicDocumentsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsFormComponent);
