import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { SnackbarActions, Toast } from "@haywork/stores/snackbar-v2";
import { connect, MapStateToProps } from "react-redux";
import {
  SnackbarComponent,
  SnackbarComponentProps
} from "./snackbar.component";

type StateProps = {
  toasts: Toast[];
};
type DispatchProps = {
  removeToast: (id: string) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SnackbarComponentProps,
  AppState
> = (state) => ({
  toasts: state.snackbar.toasts
});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SnackbarComponentProps
> = (dispatch) => ({
  removeToast: (id: string) => dispatch(SnackbarActions.removeToast(id))
});

export type SnackbarContainerProps = StateProps & DispatchProps;
export const SnackbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarComponent);
