import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { PROJECTROUTES } from "@haywork/constants";
import {
  ProjectEditMarketingOpenHouseContainer, ProjectEditMarketingPhotosContainer, ProjectEditMarketingTextContainer, ProjectEditMarketingPartnersContainer,
  ProjectEditMarketingFloorplansContainer, ProjectEditMarketingVideoContainer
} from "@haywork/modules/project";

const route = RouteUtil.mapStaticRouteValues;
export const ProjectEditMarketingRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ PROJECTROUTES.EDIT_MARKETING.URI }
      render={ () => <Redirect to={ route(PROJECTROUTES.EDIT_MARKETING_PHOTOS.URI, { id }) } /> } />
    <Route exact path={ PROJECTROUTES.EDIT_MARKETING_PHOTOS.URI } component={ ProjectEditMarketingPhotosContainer } />
    <Route path={ PROJECTROUTES.EDIT_MARKETING_TEXT.URI } component={ ProjectEditMarketingTextContainer } />
    <Route path={ PROJECTROUTES.EDIT_MARKETING_PARTNERS.URI } component={ ProjectEditMarketingPartnersContainer } />
    <Route path={ PROJECTROUTES.EDIT_MARKETING_MAPS.URI } component={ ProjectEditMarketingFloorplansContainer } />
    <Route path={ PROJECTROUTES.EDIT_MARKETING_VIDEOS.URI } component={ ProjectEditMarketingVideoContainer } />
    <Route path={ PROJECTROUTES.EDIT_MARKETING_OPENHOUSE.URI } component={ ProjectEditMarketingOpenHouseContainer } />
  </Switch>;
};
