import { SearchAssignmentSnapShot } from "@haywork/api/kolibri";
import * as React from "react";

interface Props {
  searchAssignment: SearchAssignmentSnapShot;
  onSearchAssignmentClick: (assignment: SearchAssignmentSnapShot) => void;
  onRemoveSearchAssignmentClick: (
    event: React.MouseEvent<HTMLDivElement>,
    assignment: SearchAssignmentSnapShot
  ) => void;
}
interface State {
  touched: boolean;
  firstTouch: boolean;
}

export class Pill extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      firstTouch: true
    };
  }

  public render() {
    const { searchAssignment } = this.props;

    return (
      <div
        className="pill"
        onTouchStart={() => this.setState({ touched: true })}
        onClick={() => this.onSearchAssignmentClickHandler(searchAssignment)}
        data-cy={this.props["data-cy"]}
      >
        <div className="value">{searchAssignment.name}</div>
        <div
          className="remove"
          onClick={(event) =>
            this.props.onRemoveSearchAssignmentClick(event, searchAssignment)
          }
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.RemoveButton`
          }
        >
          <i className="fal fa-times" />
        </div>
      </div>
    );
  }

  private onSearchAssignmentClickHandler(assignment: SearchAssignmentSnapShot) {
    if (this.state.touched && this.state.firstTouch) {
      return this.setState({
        firstTouch: false
      });
    }

    this.props.onSearchAssignmentClick(assignment);

    this.setState({
      touched: false,
      firstTouch: true
    });
  }
}
