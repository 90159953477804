import {
  AgendaItemCategory,
  AgendaItemCategorySnapShot,
  AgendaStandardDurationOption,
  AgendaStandardDuration
} from "@haywork/api/kolibri";

export class SchedulerCategoryUtil {
  public static convertToSnapShot(
    agendaItemCategory: AgendaItemCategory,
    standardDurationOptions: AgendaStandardDurationOption[]
  ): AgendaItemCategorySnapShot {
    const standardDurationOption = standardDurationOptions.find(
      (standardDuration) =>
        standardDuration.value === agendaItemCategory.standardDuration
    );

    const snapshot: AgendaItemCategorySnapShot = {
      allDayEvent: null,
      displayName: agendaItemCategory.displayName,
      id: agendaItemCategory.id,
      backColor: agendaItemCategory.backColor,
      frontColor: agendaItemCategory.frontColor,
      categoryType: agendaItemCategory.categoryType,
      descriptionHash: null,
      isPrivate: agendaItemCategory.isPrivate,
      minutesBeforeReminder: agendaItemCategory.minutesBeforeReminder,
      needsToBeConfirmed: agendaItemCategory.needsToBeConfirmed,
      priority: agendaItemCategory.priority,
      standardDurationInMinutes: standardDurationOption
        ? standardDurationOption.durationInMinutes
        : null,
      timeMarker: agendaItemCategory.timeMarker,
      useReminder: agendaItemCategory.useReminder,
      dateTimeCreated: agendaItemCategory.dateTimeCreated,
      dateTimeModified: agendaItemCategory.dateTimeModified
    };

    return snapshot;
  }

  public static standardDurationToMinutes(
    duration: AgendaStandardDuration,
    durationOptions: AgendaStandardDurationOption[]
  ): number {
    const result = durationOptions.find((option) => option.value === duration);
    return !!result ? result.durationInMinutes : 30;
  }

  public static minutesToStandardDuration(
    minutes: number,
    durationOptions: AgendaStandardDurationOption[]
  ): AgendaStandardDuration {
    const result = durationOptions.find(
      (option) => option.durationInMinutes === minutes
    );
    return !!result ? result.value : AgendaStandardDuration.ThirtyMinutes;
  }
}
