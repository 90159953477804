import * as React from "react";
import { FC, memo, useContext, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { ListFilterContext } from "../../list-filter.context";
import { SingleFilterValue, SingleFilterConfig } from "../../";
import I18n, { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import classNames from "classnames";

const styles = require("./style.scss");

type KeyProps = {
  activeKeys: number[];
  item: SingleFilterValue;
  handleInputChange: (value: number) => void;
};

const Key: FC<KeyProps> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ activeKeys, item, handleInputChange }) => {
      return (
        <div
          styleName={classNames("key", {
            active: activeKeys.includes(item.value),
          })}
          onClick={() => handleInputChange(item.value)}
        >
          {item.value}
        </div>
      );
    }
  )
);

type Props = {
  configKey: string;
  values: SingleFilterValue[];
  title?: Translateable;
};

export const KeyNumberArrayComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ configKey, values, title }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const activeKeys = useMemo(() => {
        if (!filter) return [];
        return (filter.value || []) as number[];
      }, [filter]);

      const handleInputChange = useCallback(
        (value: number) => {
          if (!filter) return;

          let updatedFilter = filter;
          if (!activeKeys.includes(value)) {
            updatedFilter = {
              ...updatedFilter,
              value: [...updatedFilter.value, value],
            };
          } else {
            updatedFilter = {
              ...updatedFilter,
              value: updatedFilter.value.filter((v) => v !== value),
            };
          }

          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, onFilterChange, activeKeys]
      );

      if (!filter) return null;

      return (
        <div styleName="wrapper">
          {!!label && <h2>{label}</h2>}
          <div styleName="keys">
            {values.map((keyNumber) => (
              <Key
                item={keyNumber}
                activeKeys={activeKeys}
                key={keyNumber.value}
                handleInputChange={handleInputChange}
              />
            ))}
          </div>
        </div>
      );
    }
  )
);
