import { connect, MapStateToProps } from "react-redux";
import { AppState } from "@haywork/stores";
import { ZohoComponent, ZohoComponentProps } from "./zoho";
import { MapDispatchToProps } from "@haywork/middleware";

interface StatepProps {}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StatepProps,
  ZohoComponentProps,
  AppState
> = (state) => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ZohoComponentProps
> = (dispatch) => ({});

export type ZohoContainerProps = StatepProps & DispatchProps;
export const ZohoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ZohoComponent);
