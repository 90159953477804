import { REDUX } from "@haywork/constants";
import * as ActionType from "./folders.types";

const appendFolders = (payload: ActionType.FoldersPayload) => ({
  type: REDUX.EMAILV2.APPEND_FOLDERS,
  ...payload
});

const setFolderMessagesStatus = (payload: ActionType.MessagesStatus) => ({
  type: REDUX.EMAILV2.SET_FOLDER_MESSAGES_STATUS,
  ...payload
});

const setCurrentFolder = (payload: ActionType.FolderPayload) => ({
  type: REDUX.EMAILV2.SET_CURRENT_FOLDER,
  ...payload
});

const updateFolder = (payload: ActionType.FolderPayload) => ({
  type: REDUX.EMAILV2.UPDATE_FOLDER,
  ...payload
});

const removeFolder = (payload: ActionType.FolderPayload) => ({
  type: REDUX.EMAILV2.REMOVE_FOLDER,
  ...payload
});

const setUnreadCount = (payload: ActionType.UnreadCount) => ({
  type: REDUX.EMAILV2.SET_UNREAD_COUNT,
  ...payload
});

const setFolderCount = (payload: ActionType.FolderUnreadCount) => ({
  type: REDUX.EMAILV2.SET_FOLDER_UNREAD_COUNT,
  ...payload
});

const setFolderCanLoadMore = (payload: ActionType.CanLoadMore) => ({
  type: REDUX.EMAILV2.SET_FOLDER_CAN_LOAD_MORE,
  ...payload
});

const setRootCanLoadMore = (payload: ActionType.RootCanLoadMore) => ({
  type: REDUX.EMAILV2.SET_ROOT_CAN_LOAD_MORE,
  ...payload
});

const updateUnreadCount = (payload: ActionType.UpdateUnreadCount) => ({
  type: REDUX.EMAILV2.UPDATE_UNREAD_COUNT,
  ...payload
});

export const FoldersActions = {
  appendFolders,
  setFolderMessagesStatus,
  setCurrentFolder,
  updateFolder,
  removeFolder,
  setUnreadCount,
  setFolderCount,
  setFolderCanLoadMore,
  setRootCanLoadMore,
  updateUnreadCount
};
