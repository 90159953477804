import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Hint } from "@haywork/modules/shared";

const styles = require("./style.scss");

interface Props {
  supportLink: string;
  message?: string;
  hideHint?: boolean;
}

export const UiInfoLinkComponent = CSSModules(styles, { allowMultiple: true })(
  ({ message, hideHint, supportLink }: Props) => {
    const onClickHandler = () => {
      window.open(supportLink, "_blank");
    };

    return (
      <div styleName="info-link" onClick={onClickHandler}>
        <Hint
          message={message || "default.infoLink"}
          shouldRender={!hideHint}
          actionCursor
        >
          <i className="far fa-info-circle" />
        </Hint>
      </div>
    );
  }
);
