import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { EMAILTEMPLATESROUTES, REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SettingsEmailTemplatesOverviewContainerProps } from "../../containers";
import { TemplateListItem } from "../list-item";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./overview.component.scss");

export interface SettingsEmailTemplatesOverviewComponentProps {}
interface State {}
type Props = SettingsEmailTemplatesOverviewComponentProps &
  SettingsEmailTemplatesOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailTemplatesOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onTemplateOpen = this.onTemplateOpen.bind(this);
  }

  public render() {
    const { templates, categories, status } = this.props;

    const results = uniqBy(categories, (category) => category.id);
    const categorizedResults = results
      .reduce((state, category) => {
        state.push({
          name: category.name,
          id: category.id,
          fields: templates.filter(
            (template) =>
              template.linkedTemplateDefinitionCategory.id === category.id
          ),
        });

        return state;
      }, [])
      .filter((category) => !!category.fields.length);

    return (
      <div styleName="templates">
        <PageHeader
          title="emailTemplates"
          subTitle="settingsMenuEmail"
          actions={
            <Button
              label="newEmailTemplate"
              icon={<Icon name="plus" size={18} light />}
              category="primary"
              onClick={this.props.defineNew}
            />
          }
        />

        <div styleName="templates__body">
          <div styleName="header">
            <I18n value="settingsEmailTemplate.Template" />
          </div>

          {categorizedResults.map((category) => (
            <div key={category.id}>
              <div styleName="subheader">{category.name}</div>
              {category.fields.map((template, idx) => (
                <ErrorBoundary key={template.id}>
                  <TemplateListItem
                    template={template}
                    zebra={idx % 2 !== 0}
                    onOpen={this.onTemplateOpen}
                    onDelete={this.props.deleteTemplate}
                    onUndelete={this.props.unDeleteTemplate}
                    onDeleteDone={this.props.getInitialTemplatesState}
                  />
                </ErrorBoundary>
              ))}
            </div>
          ))}

          {templates.length === 0 && status !== REQUEST.PENDING && (
            <Ui.EmptyState
              stickman={UiEmptyStateStickMan.NoRelations}
              type={UiEmptyStateType.List}
              title="emptyStateNoEmailTemplatesOverviewTitle"
              subtitle="emptyStateNoEmailTemplatesOverviewBody"
            >
              <div
                className="btn btn-primary"
                styleName="empty-state-button"
                onClick={this.props.defineNew}
              >
                <I18n value="newEmailTemplate" />
              </div>
            </Ui.EmptyState>
          )}

          {status === REQUEST.PENDING && <Ui.Loaders.List />}
        </div>
      </div>
    );
  }

  private onTemplateOpen(id: string) {
    const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, { id });
    this.props.navigate(path);
  }
}
