import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as moment from "moment";

import { ResourceText } from "@haywork/modules/shared";
import { ObjectAssignment } from "@haywork/api/kolibri";

const styles = require("./widgets.component.scss");

interface Props {
  assignment: ObjectAssignment;
  showProlongRentOffer: boolean;
  showRenewRentOffer: boolean;
  prolongRentTerm: () => void;
  renewRentOffer: () => void;
}

export const RentTermExpiresComponent = CSSModules(styles, {
  allowMultiple: true
})((props: Props) => {
  if (!props.assignment) return null;
  const { rentOffer } = props.assignment;

  let days = -1;
  if (!!rentOffer.rentedUntil) {
    const date = moment(rentOffer.rentedUntil);
    days = Math.ceil(date.diff(moment(), "days", true));
  }

  const resourceKey = days < 0 ? "rentTermExpiredBody" : "rentTermExpiresBody";

  return (
    <div styleName="rent-term-expires">
      <div styleName="body">
        <i className="fa fa-lightbulb" />
        <ResourceText resourceKey={resourceKey} values={{ days }} asHtml />
      </div>
      <div styleName="actions">
        {props.showProlongRentOffer && (
          <button className="btn btn-success" onClick={props.prolongRentTerm}>
            <ResourceText resourceKey="prolongRentTerm" />
          </button>
        )}
        {props.showRenewRentOffer && (
          <button className="btn btn-primary" onClick={props.renewRentOffer}>
            <ResourceText resourceKey="renewRentOffer" />
          </button>
        )}
      </div>
    </div>
  );
});
