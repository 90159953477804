import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type AppDataComponentProps = {
  userVersion: string;
};
type Props = AppDataComponentProps;
type VersionResponse = {
  environment: string;
  appversion: string;
  website: string;
  server: string;
  deployed_at: string;
};

export const AppDataComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ userVersion }) => {
    const [latestVersion, setLatestVersion] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchLatestAppVersion = useCallback(async () => {
      try {
        setLoading(true);

        const data: VersionResponse = await fetch(
          `${location.origin}/release.json`,
          {
            cache: "no-cache",
          }
        ).then((response) => response.json());

        setLatestVersion(data.appversion);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }, [setLoading, setLatestVersion]);

    const versionMismatch = useMemo(() => {
      return !latestVersion || !userVersion
        ? false
        : userVersion !== latestVersion;
    }, [userVersion, latestVersion]);

    useEffect(() => {
      fetchLatestAppVersion();
    }, [fetchLatestAppVersion]);

    return (
      <div styleName="app-data">
        <div styleName="row">
          <div styleName="label">
            <I18n value="health.appData.version.user" />
          </div>
          <div styleName="value">
            <Pill
              label={userVersion}
              color={versionMismatch ? Colors.Warning : Colors.Primary}
              solid={versionMismatch}
            />
            {versionMismatch && (
              <Pill
                label="health.appData.action.refresh"
                color={Colors.Primary}
                onClick={() => location.reload()}
              />
            )}
          </div>
        </div>
        <div styleName="row">
          <div styleName="label">
            <I18n value="health.appData.version.server" />
          </div>
          <div styleName="value">
            {loading ? (
              <Icon name="spinner" containIn={26} color={Colors.Gray} spin />
            ) : (
              <Pill label={latestVersion} color={Colors.Primary} solid />
            )}
          </div>
        </div>
      </div>
    );
  })
);
