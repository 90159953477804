import {
  EmailAddress,
  LinkedRelation,
  PhoneNumber,
} from "@haywork/api/kolibri";
import { MapDispatchToProps, RelationThunks } from "@haywork/middleware";
import { NotificationsThunk } from "@haywork/middleware/thunk/notifications";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  DuplicateContactsModalComponent,
  DuplicateContactsModalComponentProps,
} from "./duplicate-contacts-modal";
import { RouterAction, push } from "connected-react-router";

type StateProps = {};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  updateMessageWithLinkedRelation: (
    id: string,
    linkedRelation: LinkedRelation
  ) => Promise<void>;
  enrichContactPerson: (
    id: string,
    phoneNumbers: PhoneNumber[],
    emailAddresses: EmailAddress[]
  ) => Promise<void>;
  enrichContactCompany: (
    id: string,
    phoneNumbers: PhoneNumber[],
    emailAddresses: EmailAddress[]
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DuplicateContactsModalComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DuplicateContactsModalComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  updateMessageWithLinkedRelation: (
    id: string,
    linkedRelation: LinkedRelation
  ) =>
    dispatch(
      NotificationsThunk.Messages.updateMessageWithLinkedRelation(
        id,
        linkedRelation
      )
    ),
  enrichContactPerson: (
    id: string,
    phoneNumbers: PhoneNumber[],
    emailAddresses: EmailAddress[]
  ) =>
    dispatch(
      RelationThunks.enrichContactPerson(id, phoneNumbers, emailAddresses)
    ),
  enrichContactCompany: (
    id: string,
    phoneNumbers: PhoneNumber[],
    emailAddresses: EmailAddress[]
  ) =>
    dispatch(
      RelationThunks.enrichContactCompany(id, phoneNumbers, emailAddresses)
    ),
});

export type DuplicateContactsModalContainerProps = StateProps & DispatchProps;
export const DuplicateContactsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateContactsModalComponent);
