import { ProjectAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";

const styles = require("./widgets.component.scss");

interface ProjectDetailGeneralWidgetsPriceComponentProps {
  project: ProjectAssignment;
  priceRange: NumberRange;
  livingRoomRange: NumberRange;
  parcelRange: NumberRange;
  contentsRange: NumberRange;
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailGeneralWidgetsNumbersComponent extends React.Component<
  ProjectDetailGeneralWidgetsPriceComponentProps
> {
  public render() {
    const {
      project,
      priceRange,
      livingRoomRange,
      parcelRange,
      contentsRange,
    } = this.props;
    const { forSale } = project;

    if (
      !project ||
      (!priceRange && !livingRoomRange && !parcelRange && !contentsRange)
    )
      return null;

    return (
      <div styleName="widget">
        <div styleName="title">
          <ResourceText resourceKey="projectTypesDetailNumbersTitle" />
        </div>
        {!!priceRange && (
          <div styleName="row">
            <div styleName="column">
              <h3>
                <ResourceText
                  resourceKey={
                    !!forSale
                      ? "projectTypesDetailSalePrice"
                      : "projectTypesDetailRentPrice"
                  }
                />
              </h3>
              <div styleName="range" data-cy="CY-salePriceRange">
                {this.renderPriceRange(priceRange)}
              </div>
            </div>
          </div>
        )}
        {(!!livingRoomRange || !!parcelRange || !!contentsRange) && (
          <div styleName="row">
            {!!livingRoomRange && (
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailLivingRoomRange" />
                </h3>
                <div styleName="range" data-cy="CY-livingRoomRange">
                  {this.renderSizeRange(livingRoomRange, "squareMeters")}
                </div>
              </div>
            )}
            {!!parcelRange && (
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailParcelRange" />
                </h3>
                <div styleName="range" data-cy="CY-parcelRange">
                  {this.renderSizeRange(parcelRange, "squareMeters")}
                </div>
              </div>
            )}
            {!!contentsRange && (
              <div styleName="column">
                <h3>
                  <ResourceText resourceKey="projectTypesDetailContentsRange" />
                </h3>
                <div styleName="range" data-cy="CY-contentsRange">
                  {this.renderSizeRange(contentsRange, "cubicMeters")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  private renderPriceRange(range: NumberRange) {
    const fragments = [];
    let idx = 0;

    if (!!range.min) {
      fragments.push(
        <FormattedNumber
          value={range.min}
          style="currency"
          currency="EUR"
          key={idx}
        />
      );
      idx++;
    }

    if (!!range.max) {
      if (!range.min) {
        fragments.push(<ResourceText resourceKey="rangeMaximum" key={idx} />);
      } else {
        fragments.push(<ResourceText resourceKey="rangeUntill" key={idx} />);
      }
      idx++;

      fragments.push(
        <FormattedNumber
          value={range.max}
          style="currency"
          currency="EUR"
          key={idx}
        />
      );
    }

    return <React.Fragment children={fragments} />;
  }

  private renderSizeRange(range: NumberRange, postfix: string) {
    const fragments = [];
    let idx = 0;

    if (!!range.min) {
      fragments.push(
        <React.Fragment key={idx}>
          {range.min}
          <ResourceText resourceKey={postfix} asHtml />
        </React.Fragment>
      );
      idx++;
    }

    if (!!range.max) {
      if (!range.min) {
        fragments.push(<ResourceText resourceKey="rangeMaximum" key={idx} />);
      } else {
        fragments.push(<ResourceText resourceKey="rangeUntill" key={idx} />);
      }
      idx++;

      fragments.push(
        <React.Fragment key={idx}>
          {range.max}
          <ResourceText resourceKey={postfix} asHtml />
        </React.Fragment>
      );
    }

    return <React.Fragment children={fragments} />;
  }
}
