import * as React from "react";
import { FC, memo, useCallback, ReactNode, useRef, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { LocationsContainerProps } from "./locations.container";
import { RealEstatePropertySearchItem } from "@haywork/api/mls";

const styles = require("./style.scss");
const zoom = 8;

export type LocationsComponentProps = {
  visible: boolean;
  assignments: RealEstatePropertySearchItem[];
  onClose: () => void;
};
type Props = LocationsComponentProps & LocationsContainerProps;

export const LocationsComponent: FC<Props> = memo(
  CSSModules(styles)(({ visible, assignments, onClose }) => {
    const map = useRef<google.maps.Map | null>(null);
    const markers = useRef<google.maps.Marker[]>([]);

    const refCallback = useCallback((node: ReactNode) => {
      if (!!map.current || !google) return;
      map.current = new google.maps.Map(node as HTMLDivElement, {
        zoom,
        clickableIcons: false,
        center: new google.maps.LatLng(52.090736, 5.12142),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
    }, []);

    const clearMarkers = useCallback(() => {
      if (!markers.current.length) return;
      markers.current.forEach((marker) => marker.setMap(null));
      markers.current = [];
    }, []);

    const setMarkersCallback = useCallback(
      (items: RealEstatePropertySearchItem[]) => {
        clearMarkers();

        items
          .filter(
            (item) =>
              !!item?.address?.coordinates?.latitude &&
              !!item?.address?.coordinates?.longitude
          )
          .forEach((item) => {
            const street = item?.address.street?.name;
            const houseNumber = item?.address?.houseNumber;
            const houseNumberAddendum = item?.address?.houseNumberAddendum;
            const locality = item?.address?.locality?.name;
            const address = [street, houseNumber, houseNumberAddendum]
              .filter((d) => !!d)
              .join(" ");
            const displayName = [address, locality]
              .filter((d) => !!d)
              .join(", ");

            const { latitude, longitude } = item.address.coordinates;
            const position = new google.maps.LatLng(latitude, longitude);

            const marker = new google.maps.Marker({
              position,
              map: map.current,
            });

            const infoWindow = new google.maps.InfoWindow({
              content: `<span>${displayName}</span>`,
              position,
            });
            infoWindow.open(map.current, marker);

            marker.addListener("click", () => {
              infoWindow.open(map.current, marker);
              map.current.panTo(position);
            });

            markers.current.push(marker);
          });

        const bounds = new google.maps.LatLngBounds();
        markers.current.forEach((marker) =>
          bounds.extend(marker.getPosition())
        );

        map.current.fitBounds(bounds);
        if (map.current.getZoom() > 16) {
          map.current.setZoom(16);
        }
      },
      [clearMarkers]
    );

    const onCloseCallback = useCallback(() => {
      if (!!map.current) {
        map.current.setZoom(zoom);
        map.current.setCenter(new google.maps.LatLng(52.090736, 5.12142));
        clearMarkers();
      }
      onClose();
    }, [onClose, clearMarkers]);

    useEffect(() => {
      if (!map.current) return;
      setMarkersCallback(assignments);
    }, [assignments, setMarkersCallback]);

    return (
      <Modal visible={visible} onClose={onCloseCallback}>
        <ModalHeader title="mls.locationsModal.title" close />
        <ModalBody noPadding>
          <div styleName="map" ref={refCallback} />
        </ModalBody>
      </Modal>
    );
  })
);
