import {
  AnnouncementCategory,
  AnnouncementMessage,
  Channel,
  ChannelType,
  EmailChangeActionType,
  EmailChangeCategory,
  EmailChangeFolderType,
  SyncStatus,
} from "@haywork/api/mail";
import { LogType } from "@haywork/stores";
import * as Ably from "ably";
import isString from "lodash-es/isString";
import * as React from "react";
import { PureComponent } from "react";
import Connecter from "./context-connector";
import { EmailContainerProps } from "./email.container";

export type EmailComponentProps = {};
type Props = EmailComponentProps & EmailContainerProps;
type State = {
  channels: Ably.Types.RealtimeChannelCallbacks[];
  realtime: Ably.Realtime | null;
};

export class EmailComponent extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);

    this.state = {
      channels: [],
      realtime: null,
    };
  }

  public render() {
    return <Connecter onConnectionChange={this.onConnectionChange} />;
  }

  public componentWillUnmount() {
    this.unsubscribe();
    this.setState({ channels: [] });
  }

  private onConnectionChange(
    mailChannels: Channel[],
    realtime: Ably.Realtime | null
  ) {
    this.unsubscribe();

    if (!realtime) {
      this.setState({ channels: [], realtime: null });
      return;
    }

    const accountChannels = mailChannels
      .filter(
        (channel) =>
          channel.channelType === ChannelType.AccountChannel &&
          !this.props.hiddenShareIds.includes(channel.accountId)
      )
      .map((channel) => ({
        realtime: realtime.channels.get(channel.name),
        accountId: channel.accountId,
      }));

    const channels = accountChannels.map((accountChannel) => {
      const { realtime } = accountChannel;

      realtime.subscribe((response) => {
        const data: AnnouncementMessage = isString(response.data)
          ? JSON.parse(response.data)
          : response.data;

        if (data.category !== AnnouncementCategory.EmailAccountChange) return;
        this.handleEvent(data);
      });

      return realtime;
    });

    this.setState({ channels, realtime });
  }

  private handleEvent(data: AnnouncementMessage) {
    const { emailChangeDetails, mailAccountId } = data;
    this.props.addLogging({
      logType: LogType.EmailWebSocket,
      label: "Socket message received",
      payload: JSON.stringify(data),
    });

    switch (emailChangeDetails.category) {
      case EmailChangeCategory.MessageOpened: {
        const message = data?.emailChangeDetails?.emailMessage;
        this.props.checkAndUpdateMessage(message?.id);
        return;
      }
      case EmailChangeCategory.FolderUnreadCount: {
        if (
          !!mailAccountId &&
          emailChangeDetails?.emailFolder?.unreadCount !== undefined
        ) {
          this.props.setAccountInboxUnreadCount(
            mailAccountId,
            emailChangeDetails.emailFolder.unreadCount
          );
        }
        return;
      }
      case EmailChangeCategory.Message:
      case EmailChangeCategory.MessageReplied:
      case EmailChangeCategory.MessageForwarded: {
        const actionType = data?.emailChangeDetails?.actionType;
        const message = data?.emailChangeDetails?.emailMessage;
        const folder = message?.folder;

        if (!actionType) {
          this.props.addLogging({
            logType: LogType.EmailWebSocket,
            label: "Action type missing in received data",
            payload: JSON.stringify({
              actionType,
              message,
              folder,
            }),
          });

          return;
        }

        switch (actionType) {
          case EmailChangeActionType.Create: {
            if (!!folder && !!folder.id) {
              const silent = folder.type === EmailChangeFolderType.Sent;
              this.props.appendMessage(
                message.id,
                mailAccountId,
                folder.id,
                silent
              );
            }
            return;
          }
          case EmailChangeActionType.Modify: {
            this.props.checkAndUpdateMessage(message.id);
            return;
          }
          case EmailChangeActionType.Delete: {
            this.props.deleteMessage(message.id);
            return;
          }
          default: {
            return;
          }
        }
      }
      case EmailChangeCategory.Draft: {
        const actionType = data?.emailChangeDetails?.actionType;
        const { id } = data?.emailChangeDetails?.emailDraft;

        switch (actionType) {
          case EmailChangeActionType.Delete: {
            this.props.removeDraft(id);
            break;
          }
          case EmailChangeActionType.Modify: {
            this.props.refreshDraft(mailAccountId, id);
          }
          default: {
            this.props.refreshDraftFolder(mailAccountId);
            break;
          }
        }

        return;
      }
      case EmailChangeCategory.Folder: {
        const actionType = data?.emailChangeDetails?.actionType;
        const folderDetails = data?.emailChangeDetails?.emailFolder;

        if (!actionType || !folderDetails) return;

        switch (actionType) {
          case EmailChangeActionType.Create:
          case EmailChangeActionType.Modify: {
            this.props.refreshAccountFolders(mailAccountId);
            return;
          }
          case EmailChangeActionType.Delete: {
            this.props.deleteFolder(folderDetails.id);
            return;
          }
          default: {
            return;
          }
        }
      }
      case EmailChangeCategory.AccountConnected: {
        this.props.addLogging({
          logType: LogType.EmailWebSocket,
          label: "Account status changed",
          payload: JSON.stringify({
            mailAccountId,
            status: SyncStatus.Connected,
          }),
        });
        this.props.setAccountSyncStatus(mailAccountId, SyncStatus.Connected);
        return;
      }
      case EmailChangeCategory.AccountError: {
        this.props.addLogging({
          logType: LogType.EmailWebSocket,
          label: "Account status changed",
          payload: JSON.stringify({
            mailAccountId,
            status: SyncStatus.Error,
          }),
        });
        this.props.setAccountSyncStatus(mailAccountId, SyncStatus.Error);
        return;
      }
      case EmailChangeCategory.AccountInvalidCredentials: {
        this.props.addLogging({
          logType: LogType.EmailWebSocket,
          label: "Account status changed",
          payload: JSON.stringify({
            mailAccountId,
            status: SyncStatus.InvalidCredentials,
          }),
        });
        this.props.setAccountSyncStatus(
          mailAccountId,
          SyncStatus.InvalidCredentials
        );
        return;
      }
      case EmailChangeCategory.AccountRunning: {
        this.props.addLogging({
          logType: LogType.EmailWebSocket,
          label: "Account status changed",
          payload: JSON.stringify({
            mailAccountId,
            status: SyncStatus.Running,
          }),
        });
        this.props.setAccountSyncStatus(mailAccountId, SyncStatus.Running);
        return;
      }
      case EmailChangeCategory.AccountStopped: {
        this.props.addLogging({
          logType: LogType.EmailWebSocket,
          label: "Account status changed",
          payload: JSON.stringify({
            mailAccountId,
            status: SyncStatus.Stopped,
          }),
        });
        this.props.setAccountSyncStatus(mailAccountId, SyncStatus.Stopped);
        return;
      }
      default: {
        return;
      }
    }
  }

  private unsubscribe() {
    const { channels } = this.state;
    channels.map((channel) => channel.unsubscribe());
  }
}
