import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AgendaItemCategorySnapShot,
  BidStatus,
  LinkedAssignment,
  LinkedRelation,
  Priority,
  TaskCategoryOption,
  TimelineActionSubType,
  TimelineActionType,
  TimelineEvent,
  CommunicationLog,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { ColorUtil, DateUtil, DiffStatus, StringUtil } from "@haywork/util";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { Fragment } from "react";
import * as CSSModules from "react-css-modules";
import {
  FormattedDate,
  FormattedTime,
  injectIntl,
  WithIntlProps,
} from "react-intl";
import Assignment from "../linked-assignment";
import Relation from "../linked-relation";

interface ListItemComponentProps {
  timelineEvent: TimelineEvent;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  parentId: string;
  zebra: boolean;
  authorizationWidgetEntityType: WidgetEntityType;
  onTimelineClick: (timelineEvent: TimelineEvent) => void;
  deleteCommunicationLog: (timelineEvent: TimelineEvent) => void;
  unDeleteCommunicationLog: (timelineEvent: TimelineEvent) => void;
  onRefresh: () => void;
}
interface State {
  clickedInside: boolean;
  showActionOverlay: boolean;
}
type Props = ListItemComponentProps & WithIntlProps<any>;

const styles = require("./style.scss");

@CSSModules(styles, { allowMultiple: true })
class ListItem extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      clickedInside: false,
      showActionOverlay: false,
    };

    this.onTimelineClickHandler = this.onTimelineClickHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.deleteCommunicationLog = this.deleteCommunicationLog.bind(this);
    this.unDeleteCommunicationLog = this.unDeleteCommunicationLog.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    if (!this.props.timelineEvent) return null;
    const { parentId, authorizationWidgetEntityType } = this.props;

    const timelineEvent = this.props.timelineEvent;
    const linkedRelationComponents =
      this.createMultipleLinkedRelationComponents(
        timelineEvent.linkedRelations
      );
    const linkedAssignmentComponents =
      this.createMultipleLinkedAssignmentComponents(
        timelineEvent.linkedAssignments
      );
    const listItemStyle = classNames("item", {
      active: this.state.clickedInside,
    });

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
        actionTitle="communicationLog.delete"
        actionVisible={this.state.showActionOverlay}
        onActionDone={this.props.onRefresh}
        onActionCancel={this.unDeleteCommunicationLog}
      >
        <div styleName={listItemStyle} ref={this.bindRef}>
          {this.renderTimelineIcon()}
          <div styleName="meta" onClick={() => this.onTimelineClickHandler()}>
            <div styleName="meta__inner">
              {this.renderStatus()}
              {this.renderSubject()}
              {this.renderCategory()}
              {linkedRelationComponents}
              {linkedAssignmentComponents}
            </div>

            {this.renderPriorityTask()}

            {/* Authorization widgets */}
            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={authorizationWidgetEntityType}
                location={WidgetEntityLocation.Timeline}
                id={parentId}
              />
            </FeatureSwitch>

            {this.renderDate()}
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private renderListActions(): React.StatelessComponent[] {
    const deleteButton = () => (
      <button className="btn btn-danger" onClick={this.deleteCommunicationLog}>
        <i className="fal fa-times" />
      </button>
    );

    const viewButton = () => (
      <button className="btn btn-primary" onClick={this.onTimelineClickHandler}>
        <i className="fal fa-search" />
      </button>
    );

    const { actionType } = this.props.timelineEvent;

    switch (actionType) {
      case TimelineActionType.ContactMe:
      case TimelineActionType.SearchProfile:
      case TimelineActionType.Invoice:
      case TimelineActionType.Task:
      case TimelineActionType.AgendaItem: {
        return [viewButton];
      }
      case TimelineActionType.CommunicationLog: {
        return [viewButton, deleteButton];
      }
      default: {
        return [];
      }
    }
  }

  private deleteCommunicationLog() {
    if (this.props.timelineEvent.accessDeniedForCurrentUser === true) return;
    this.setState({ showActionOverlay: true });
    this.props.deleteCommunicationLog(this.props.timelineEvent);
  }

  private unDeleteCommunicationLog() {
    this.props.unDeleteCommunicationLog(this.props.timelineEvent);
  }

  private bindRef(ref: HTMLDivElement) {
    if (!this.ref && !!ref) {
      this.ref = ref;
    }
  }

  private onClickOutsideHandler(event) {
    if (!this.ref) return;
    const clickedInside = this.ref.contains(event.target);
    if (this.state.clickedInside !== clickedInside) {
      this.setState({ clickedInside });
    }
  }

  private onTimelineClickHandler() {
    if (this.props.timelineEvent.accessDeniedForCurrentUser === true) return;
    this.props.onTimelineClick(this.props.timelineEvent);
  }

  private createMultipleLinkedRelationComponents(
    linkedRelations: LinkedRelation[]
  ) {
    if (!linkedRelations) return null;
    const relations = linkedRelations.filter(
      (relation) => relation.id !== this.props.parentId
    );

    return (
      <Fragment>
        {relations.map((relation, idx) => (
          <ErrorBoundary key={idx}>
            <Relation linkedRelation={relation} />
          </ErrorBoundary>
        ))}
      </Fragment>
    );
  }

  private createMultipleLinkedAssignmentComponents(
    linkedAssignments: LinkedAssignment[]
  ) {
    if (!linkedAssignments) return null;
    const assignments = linkedAssignments.filter(
      (assignment) => assignment.id !== this.props.parentId
    );

    return (
      <Fragment>
        {assignments.map((assignment, idx) => (
          <ErrorBoundary key={idx}>
            <Assignment linkedAssignment={assignment} />
          </ErrorBoundary>
        ))}
      </Fragment>
    );
  }

  private renderTimelineIcon(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    let icon = "";
    let borderStyle;

    switch (timelineEvent.actionType) {
      case TimelineActionType.AgendaItem:
        icon = timelineEvent.isCanceled
          ? "calendar-times"
          : timelineEvent.isConfirmed
          ? "calendar-check"
          : "calendar-alt";
        const agendaItemCategory = this.props.agendaItemCategories.find(
          (category) => category.id === timelineEvent.categoryId
        );
        if (agendaItemCategory) {
          borderStyle = {
            borderColor: ColorUtil.hexToRgb(agendaItemCategory.backColor),
          };
        }
        break;
      case TimelineActionType.Invoice:
        icon = "euro-sign";
        break;
      case TimelineActionType.Task:
        icon = "tasks";
        const taskCategory = this.props.taskCategories.find(
          (category) => category.value === timelineEvent.categoryId
        );
        if (taskCategory) {
          borderStyle = {
            borderColor: ColorUtil.hexToRgb(taskCategory.categoryBackColor),
          };
        }
        break;
      case TimelineActionType.Unknown:
        icon = "file-o";
        break;
      case TimelineActionType.Email:
        icon = "envelope";
        break;
      case TimelineActionType.SearchProfile:
        icon = "search";
        break;
      case TimelineActionType.ContactMe:
        icon = "comment-alt";
        break;
      case TimelineActionType.Website:
        icon = "globe";
        break;
      case TimelineActionType.Transaction:
        icon = "handshake";
        break;
      case TimelineActionType.CommunicationLog:
        icon =
          timelineEvent.actionSubType === TimelineActionSubType.Email
            ? "envelope"
            : "phone";
        break;
      case TimelineActionType.Relocation: {
        icon = "box-alt";
        break;
      }
      case TimelineActionType.Bid: {
        switch (timelineEvent.bidStatus) {
          case BidStatus.Active:
            icon = "comments-dollar";
            break;
          case BidStatus.Accepted:
            icon = "thumbs-up";
            break;
          case BidStatus.Denied:
            icon = "thumbs-down";
            break;
          default:
            icon = "comments-dollar";
            break;
        }
        break;
      }
      case TimelineActionType.KeyNrChange: {
        icon = "key";
        break;
      }
      default:
        icon = "archive";
        break;
    }

    const iconStyle = classNames("fal", "fa-fw", `fa-${icon}`);

    return (
      <div styleName="item__icon" style={borderStyle}>
        <i className={iconStyle} />
      </div>
    );
  }
  private renderPriorityTask(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    switch (timelineEvent.actionType) {
      case TimelineActionType.Task:
        return (
          <div>
            {/* high priority span */}
            {timelineEvent.priority === Priority.High && (
              <span styleName="item__important">
                <i className=" fa fa-exclamation" aria-hidden="true" />
              </span>
            )}
          </div>
        );
      default:
        break;
    }
    return <div />;
  }

  private renderStatus(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    if (timelineEvent.actionType !== TimelineActionType.AgendaItem) return;
    let status;
    switch (timelineEvent.isCanceled) {
      case true:
        status = "timeline.canceled";
        break;
      default:
        switch (timelineEvent.isConfirmed) {
          case true:
            status = "timeline.confirmed";
            break;
          default:
            status = "timeline.open";
            break;
        }
        break;
    }
    return <I18n value={status} />;
  }

  private renderSubject(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    switch (timelineEvent.actionType) {
      case TimelineActionType.AgendaItem:
        return timelineEvent.isCanceled ? (
          <h2 style={{ textDecoration: "line-through" }}>
            {timelineEvent.subject}
          </h2>
        ) : (
          <h2>{timelineEvent.subject}</h2>
        );
      case TimelineActionType.Task:
        return (
          <h2>
            <div
              styleName={classNames(
                timelineEvent.completedPercentage === 100
                  ? "completed-subject"
                  : ""
              )}
            >
              {timelineEvent.subject}
            </div>
          </h2>
        );
      case TimelineActionType.Invoice:
        return (
          <h2>
            {timelineEvent.invoiceNumber ? (
              <I18n
                value="invoiceNumber"
                values={{ invoiceNumber: timelineEvent.invoiceNumber }}
              />
            ) : (
              <I18n value="concept" />
            )}
            {timelineEvent.subject ? (
              <I18n
                value="dashInvoiceSubject"
                values={{ invoiceSubject: timelineEvent.subject }}
              />
            ) : (
              ""
            )}
          </h2>
        );
      case TimelineActionType.Email:
        return <h2>{StringUtil.stripHTML(timelineEvent.subject)}</h2>;
      case TimelineActionType.Transaction:
        return (
          <h2>{this.renderTimelineSubActionTypeIconHeader(timelineEvent)}</h2>
        );
      case TimelineActionType.Invoice:
        return (
          <h2>
            {!!timelineEvent.invoiceNumber ? (
              <I18n
                value="invoiceNumber"
                values={{ invoiceNumber: timelineEvent.invoiceNumber }}
              />
            ) : (
              <I18n value="concept" />
            )}
          </h2>
        );
      case TimelineActionType.CommunicationLog:
        return <h2>{StringUtil.stripHTML(timelineEvent.subject)}</h2>;
      case TimelineActionType.Relocation: {
        return (
          <>
            <h2>
              <I18n value="timeline.relocation.subject" />
            </h2>
            <h3>{timelineEvent.subject}</h3>
          </>
        );
      }
      case TimelineActionType.Bid: {
        return (
          <>
            <h2>
              <I18n
                value="timeline.bid.subject"
                values={{
                  price: this.props.intl.formatNumber(
                    timelineEvent.price || 0,
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  ),
                  status: this.props.intl.formatMessage({
                    id: `bidStatus.${timelineEvent.bidStatus.toString()}`,
                    defaultMessage: timelineEvent.bidStatus.toString(),
                  }),
                  date: moment(
                    this.renderBidDate(timelineEvent.bidStatus)
                  ).format("DD-MM-YYYY"),
                }}
              />
            </h2>
            <h3>{timelineEvent.subject}</h3>
          </>
        );
      }
      default:
        return (
          <h2>{timelineEvent.subject || <I18n value="timelineNoSubject" />}</h2>
        );
    }
  }

  private renderTimelineSubActionTypeIconHeader(
    event: TimelineEvent
  ): string | React.ReactElement<HTMLSpanElement> {
    switch (event.actionSubType) {
      case TimelineActionSubType.PriceChange:
        const oldPrice = this.props.intl.formatNumber(event.oldPrice || 0, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });
        const newPrice = this.props.intl.formatNumber(event.price || 0, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });
        return (
          <I18n value="askingPriceUpdatedTo" values={{ oldPrice, newPrice }} />
        );
      default:
        return (
          <I18n
            prefix="timelineActionSubOptions"
            value={event.actionSubType.toString()}
          />
        );
    }
  }

  private renderCategory(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    switch (timelineEvent.actionType) {
      case TimelineActionType.AgendaItem:
        const agendaItemCategory = this.props.agendaItemCategories.find(
          (category) => category.id === timelineEvent.categoryId
        );
        if (agendaItemCategory) {
          return (
            <span styleName="item__category">
              <span
                styleName="color"
                style={{
                  backgroundColor: agendaItemCategory
                    ? ColorUtil.hexToRgb(agendaItemCategory.backColor)
                    : "",
                }}
              />
              {agendaItemCategory.displayName}
            </span>
          );
        }
        break;
      case TimelineActionType.Task:
        const taskCategory = this.props.taskCategories.find(
          (category) => category.value === timelineEvent.categoryId
        );
        if (taskCategory) {
          return (
            <span styleName="item__category">
              <span
                styleName="color"
                style={{
                  backgroundColor: taskCategory
                    ? ColorUtil.hexToRgb(taskCategory.categoryBackColor)
                    : "",
                }}
              />
              {taskCategory.displayName}
            </span>
          );
        }
        break;
      default:
        break;
    }

    return <div />;
  }

  private renderBidDate(bidStatus) {
    const { timelineEvent } = this.props;
    switch (bidStatus) {
      case BidStatus.Accepted: {
        return timelineEvent.acceptedDateTime || timelineEvent.dateTimeModified;
      }
      case BidStatus.Active: {
        return timelineEvent.bidDateTime || timelineEvent.dateTimeModified;
      }
      case BidStatus.Denied: {
        return timelineEvent.deniedDateTime || timelineEvent.dateTimeModified;
      }
      case BidStatus.Expired: {
        return timelineEvent.bidValidUntil || timelineEvent.dateTimeModified;
      }
      default:
        break;
    }
  }

  private renderDate(): React.ReactElement<HTMLDivElement> {
    const { timelineEvent } = this.props;
    let pre = null;
    let post = null;
    let createdBy = null;
    let date = (
      <FormattedDate
        value={new Date(timelineEvent.date)}
        day="2-digit"
        month="long"
        year="numeric"
      />
    );

    switch (timelineEvent.actionType) {
      case TimelineActionType.Email:
        pre = <I18n value="receivedOn" />;
        post = (
          <div>
            <I18n value="receivedAt" />
            <FormattedDate
              value={new Date(timelineEvent.date)}
              hour="2-digit"
              minute="2-digit"
            />
          </div>
        );
        break;
      case TimelineActionType.SearchProfile:
        pre = <I18n value="sentOn" />;
        break;
      case TimelineActionType.AgendaItem:
        const diff = DateUtil.diffInDays(
          timelineEvent.date,
          timelineEvent.endDate
        );

        if (timelineEvent.allDayEvent) {
          post = <I18n value="isAllDay" />;
        }

        if (!timelineEvent.allDayEvent && diff.status === DiffStatus.Positive) {
          date = (
            <div styleName="multi">
              <div styleName="multi__date">
                <I18n value="from" />
                <FormattedDate
                  value={new Date(timelineEvent.date)}
                  day="2-digit"
                  month="long"
                  year="numeric"
                  hour="2-digit"
                  minute="2-digit"
                />
              </div>
              <div styleName="multi__date">
                <I18n value="untill" />
                <FormattedDate
                  value={new Date(timelineEvent.endDate)}
                  day="2-digit"
                  month="long"
                  year="numeric"
                  hour="2-digit"
                  minute="2-digit"
                />
              </div>
            </div>
          );
        }

        if (!timelineEvent.allDayEvent && diff.status === DiffStatus.Today) {
          post = (
            <div styleName="multi-values">
              <I18n value="from" />
              <FormattedDate
                value={new Date(timelineEvent.date)}
                hour="2-digit"
                minute="2-digit"
              />
              <I18n value="untill" />
              <FormattedDate
                value={new Date(timelineEvent.endDate)}
                hour="2-digit"
                minute="2-digit"
              />
            </div>
          );
        }

        createdBy = (
          <div styleName="multi-values highlight">
            <I18n value="madeBy" /> {timelineEvent.linkedCreatedBy.displayName}
          </div>
        );

        break;
      case TimelineActionType.Task:
        if (timelineEvent.completedPercentage === 100) {
          post = (
            <div styleName="dates__target">
              <I18n value="Completed" />
            </div>
          );
        } else {
          date = (
            <FormattedDate
              value={new Date(timelineEvent.endDate)}
              day="2-digit"
              month="long"
              year="numeric"
            />
          );
          const daysLeft = DateUtil.diffInDays(
            new Date(),
            timelineEvent.endDate
          );
          const days = daysLeft.value;

          switch (daysLeft.status) {
            case DiffStatus.Negative: {
              post = (
                <div styleName="dates__target overdue">
                  <I18n value="amountOfDaysPastDueDate" values={{ days }} />
                </div>
              );
              break;
            }
            case DiffStatus.Today: {
              post = (
                <div styleName="dates__target today">
                  <I18n value="amountOfDays" values={{ days }} />
                </div>
              );
              break;
            }
            case DiffStatus.Positive: {
              post = (
                <div styleName="dates__target">
                  <I18n value="amountOfDays" values={{ days }} />
                </div>
              );
              break;
            }
            default:
              break;
          }
        }
        break;
      case TimelineActionType.Transaction:
        date = (
          <FormattedDate
            value={new Date(timelineEvent.date)}
            day="2-digit"
            month="long"
            year="numeric"
          />
        );
        post = (
          <FormattedTime
            value={timelineEvent.date}
            hour="2-digit"
            minute="2-digit"
          />
        );
        break;
      case TimelineActionType.Invoice:
        date = (
          <FormattedDate
            value={timelineEvent.dateTimeCreated}
            day="numeric"
            month="long"
            year="numeric"
          />
        );
        post = <FormattedTime value={timelineEvent.dateTimeCreated} />;
        break;
      case TimelineActionType.Website:
        date = (
          <FormattedDate
            value={new Date(timelineEvent.date)}
            day="2-digit"
            month="long"
            year="numeric"
          />
        );
        post = (
          <FormattedTime
            value={timelineEvent.date}
            hour="2-digit"
            minute="2-digit"
          />
        );
        break;
      case TimelineActionType.CommunicationLog:
        date = (
          <FormattedDate
            value={new Date(timelineEvent.date)}
            day="2-digit"
            month="long"
            year="numeric"
          />
        );
        post = (
          <FormattedTime
            value={timelineEvent.date}
            hour="2-digit"
            minute="2-digit"
          />
        );
        break;
      default:
        break;
    }

    return (
      <div styleName="item__date">
        {pre && <div styleName="pre">{pre}</div>}
        {date}
        {post && <div styleName="post">{post}</div>}
        {createdBy && <div styleName="post">{createdBy}</div>}
      </div>
    );
  }
}

export const ListItemComponent = injectIntl(ListItem);
