import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import * as PropTypes from "prop-types";

import { ModalContainerProps } from "@haywork/modules/modal";
import { ModalPortal } from "@haywork/portals";

const styles = require("./modal.component.scss");

type ModalComponentProps = {
  visible: boolean;
  className?: string;
  closeOnBackDropClick?: boolean;
  large?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
};
type Props = ModalComponentProps & ModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ModalComponent extends React.Component<Props> {
  public static childContextTypes = {
    close: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.backDropClickHandler = this.backDropClickHandler.bind(this);
  }

  public getChildContext() {
    return {
      close: this.close.bind(this),
    };
  }

  public render() {
    const visible = classNames("modal__wrapper", {
      visible: this.props.visible,
      "can-close": !!this.props.onClose && !!this.props.closeOnBackDropClick,
    });
    const modalStyle = classNames(this.props.className);

    return (
      <ModalPortal>
        <div styleName={visible} onClick={this.backDropClickHandler}>
          <div styleName="modal__inner">
            <div
              styleName={classNames("modal", { large: !!this.props.large })}
              className={modalStyle}
              style={this.props.style}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  }

  public close() {
    if (!!this.props.onClose) this.props.onClose();
  }

  private backDropClickHandler() {
    if (!!this.props.onClose && !!this.props.closeOnBackDropClick) {
      this.props.onClose();
    }
  }
}
