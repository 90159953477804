import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { InputComponentProps } from "../input.component";
import { AsyncUtil } from "@haywork/util";
import { KEYCODE } from "@haywork/constants";

const styles = require("./postalcode.component.scss");

interface PostalcodeComponentProps {
  backgroundColor?: string;
}
interface State {
  postalCodeDigits: string;
  postalCodeLetters: string;
  postalCodeHasFocus: boolean;
}
type Props = PostalcodeComponentProps & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class PostalcodeComponent extends React.Component<Props, State> {
  private postalCodeDigitsRef: HTMLInputElement;
  private postalCodeLettersRef: HTMLInputElement;

  constructor(props) {
    super(props);

    let value =
      [null, undefined].indexOf(this.props.value) === -1
        ? this.props.value
        : "";

    value = (value || "").replace(/\s/gi, "");
    const filteredDigits = value.replace(/\D/gi, "");
    const filteredLetters = value.replace(/\d/gi, "");
    const postalCodeDigits = filteredDigits.slice(0, 4);
    const postalCodeLetters = filteredLetters.slice(0, 2);

    this.state = {
      postalCodeDigits,
      postalCodeLetters,
      postalCodeHasFocus: false,
    };

    this.onPostalCodeDigitsChange = this.onPostalCodeDigitsChange.bind(this);
    this.onPostalCodeLettersChange = this.onPostalCodeLettersChange.bind(this);
    this.onPostalCodeFocus = this.onPostalCodeFocus.bind(this);
    this.onPostalCodeBlur = this.onPostalCodeBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    let value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    value = (value || "").replace(/\s/gi, "");
    const filteredDigits = (value || "").replace(/\D/gi, "");
    const filteredLetters = (value || "").replace(/\d/gi, "");
    const postalCodeDigits = filteredDigits.slice(0, 4);
    const postalCodeLetters = filteredLetters.slice(0, 2);

    if (
      postalCodeDigits !== this.state.postalCodeDigits ||
      postalCodeLetters !== this.state.postalCodeLetters
    ) {
      this.setState({
        postalCodeDigits,
        postalCodeLetters,
      });
    }
  }

  public render() {
    const postalCodeBackgroundStyle = {
      backgroundColor: this.props.backgroundColor || "#ffffff",
    };

    return (
      <div
        styleName={classNames("postalcode-search", {
          "has-focus": this.state.postalCodeHasFocus,
        })}
      >
        <div styleName="digits" style={postalCodeBackgroundStyle}>
          <input
            type="text"
            name={`${this.props.name}PostalCodeDigits`}
            value={this.state.postalCodeDigits}
            onChange={this.onPostalCodeDigitsChange}
            onFocus={this.onPostalCodeFocus}
            onBlur={this.onPostalCodeBlur}
            onKeyDown={this.onKeyDown}
            ref={(ref) => (this.postalCodeDigitsRef = ref)}
            placeholder="6811"
            data-cy="CY-postalCodeDigits"
            data-lpignore="true"
          />
        </div>
        <div styleName="letters">
          <input
            type="text"
            name={`${this.props.name}PostalCodeLetters`}
            value={this.state.postalCodeLetters}
            onChange={this.onPostalCodeLettersChange}
            onFocus={this.onPostalCodeFocus}
            onBlur={this.onPostalCodeBlur}
            onKeyDown={this.onKeyDown}
            ref={(ref) => (this.postalCodeLettersRef = ref)}
            placeholder="AA"
            data-cy="CY-postalCodeLetters"
            data-lpignore="true"
          />
        </div>
      </div>
    );
  }

  private async onPostalCodeDigitsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    const filteredValue = (value || "").replace(/\D/gi, "");
    const postalCodeDigits = filteredValue.slice(0, 4);

    this.setState({ postalCodeDigits });

    if (filteredValue.length >= 4) {
      await AsyncUtil.wait(5);
      this.postalCodeLettersRef.focus();
    }
  }

  private async onPostalCodeLettersChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    const filteredValue = (value || "").replace(/\d/gi, "");
    const postalCodeLetters = filteredValue.slice(0, 2).toUpperCase();

    this.setState({ postalCodeLetters });
  }

  private onPostalCodeFocus() {
    this.setState({ postalCodeHasFocus: true });
  }

  private onPostalCodeBlur() {
    this.setState({ postalCodeHasFocus: false });
    this.props.onChange(
      this.state.postalCodeDigits + this.state.postalCodeLetters
    );
  }

  private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.keyCode) {
      case KEYCODE.ENTER:
        //  this.onPostalCodeSubmit();
        break;
      default:
        break;
    }
  }
}
