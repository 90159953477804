import { REDUX } from "@haywork/constants";
import { CompanyListing } from "@haywork/api/kolibri";
import { Status } from "@haywork/stores";

import * as ActionType from "./overview.types";

const setAssignmentsStatus = (payload: Status) => ({
  type: REDUX.ASSIGNMENT.SET_ASSIGNMENTS_STATUS,
  ...payload
});

const setAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.ASSIGNMENT.SET_ASSIGNMENTS,
  ...payload
});

const appendAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.ASSIGNMENT.APPEND_ASSIGNMENTS,
  ...payload
});

const setAssignmentsFilters = (payload: ActionType.Filters) => ({
  type: REDUX.ASSIGNMENT.SET_ASSIGNMENTS_FILTERS,
  ...payload
});

const clearAllFilters = () => ({
  type: REDUX.ASSIGNMENT.CLEAR_ALL_FILTERS
});

const clearFilter = (payload: ActionType.Filter) => ({
  type: REDUX.ASSIGNMENT.CLEAR_FILTER,
  ...payload
});

const updateSingleAssignment = (payload: ActionType.Snapshot) => ({
  type: REDUX.ASSIGNMENT.UPDATE_SINGLE_LIST_ASSIGNMENT,
  ...payload
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.ASSIGNMENT.DELETE_LIST_ITEM,
  ...payload
});

export const AssignmentOverviewActions = {
  setAssignmentsStatus,
  setAssignments,
  appendAssignments,
  setAssignmentsFilters,
  clearAllFilters,
  clearFilter,
  updateSingleAssignment,
  deleteListItem
};
