import * as React from "react";
import { FC, memo, useContext, useMemo, useCallback, ChangeEvent } from "react";
import * as CSSModules from "react-css-modules";
import { ListFilterContext } from "../../list-filter.context";
import { SingleFilterValue, SingleFilterConfig } from "../../";
import I18n, { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type RadioButtonProps = {
  filter: SingleFilterConfig;
  item: SingleFilterValue;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => void;
};

const RadioButton: FC<RadioButtonProps> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ filter, item, handleInputChange }) => {
      const checked = filter.value === item.value;
      const { value, values } = isString(item.label)
        ? { value: item.label, values: undefined }
        : item.label;

      return (
        <label styleName="radio">
          <div styleName="radio__icon">
            <Icon
              name={checked ? "check-circle" : "circle"}
              light={!checked}
              solid={checked}
              size={16}
              color={checked ? Colors.Primary : Colors.MediumGray}
            />
          </div>
          <input
            type="radio"
            checked={checked}
            onChange={(event) => handleInputChange(event, item.value)}
          />
          <div styleName="radio__label">
            <I18n value={value} values={values} />
          </div>
        </label>
      );
    }
  )
);

type Props = {
  configKey: string;
  values: SingleFilterValue[];
  title?: Translateable;
  blockUpdate?: boolean;
  onChange?: (updatedFilter: SingleFilterConfig) => void;
};

export const RadioGroupComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ configKey, values, title, blockUpdate, onChange }) => {
      const { filters, onFilterChange } = useContext(ListFilterContext);

      const filter = useMemo(() => {
        return !filters ? undefined : filters[configKey];
      }, [filters, configKey]);

      const label = useMemo(() => {
        if (!title || !filter) return null;
        const { value, values } = isString(title)
          ? { value: title, values: undefined }
          : title;
        return (
          <div styleName="label">
            <I18n value={value} values={values} />
          </div>
        );
      }, [title, filter]);

      const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, value: any) => {
          if (!filter) return;

          let updatedFilter = filter;
          if (event.target.checked) {
            updatedFilter = {
              ...updatedFilter,
              value,
            };
          }
          if (!!onChange) {
            onChange(updatedFilter);
            if (!!blockUpdate) return;
          }
          onFilterChange({
            ...filters,
            [configKey]: updatedFilter,
          });
        },
        [filter, filters, configKey, blockUpdate, onFilterChange, onChange]
      );

      if (!filter) return null;

      return (
        <div styleName="radio-group">
          {!!label && <h2>{label}</h2>}
          {values.map((item, idx) => (
            <RadioButton
              item={item}
              filter={filter}
              handleInputChange={handleInputChange}
              key={idx}
            />
          ))}
        </div>
      );
    }
  )
);
