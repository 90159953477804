import { ActiveFilter } from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, InvoiceListActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  invoiceDateValue,
  overdueValue,
  statusFilterValues
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  filterByActiveValues: SingleFilterValue[];
  statusFilterValues: SingleFilterValue[];
  overdueValue: SingleFilterValue;
  invoiceDateValue: SingleFilterValue;
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.invoice.list;
  const filterByActiveValues = [
    {
      label: `invoices.activeFilter.${ActiveFilter.ActiveOnly.toString()}`,
      value: ActiveFilter.ActiveOnly
    },
    {
      label: `invoices.activeFilter.${ActiveFilter.InactiveOnly.toString()}`,
      value: ActiveFilter.InactiveOnly
    }
  ];

  return {
    filters,
    filterByActiveValues,
    statusFilterValues: statusFilterValues(state),
    overdueValue: overdueValue(state),
    invoiceDateValue: invoiceDateValue(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(InvoiceListActions.setFilters(filters))
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
