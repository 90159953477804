import { SearchAssignmentSnapShot } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Column, Row } from "@haywork/components/ui/list";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import LoadingState from "../loading-state";
import Actions from "../row-actions";
import RelationPill from "./relation-pill";
import * as moment from "moment";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  data: SearchAssignmentSnapShot | null;
  zebra: boolean;
  onNavigate: (path: string) => void;
};

export const RowComponent: FC<Props> = memo(({ data, zebra, onNavigate }) => {
  return !data ? (
    <LoadingState zebra={zebra} />
  ) : (
    <DataRowComponent data={data} zebra={zebra} onNavigate={onNavigate} />
  );
});

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ data, zebra, onNavigate }) => {
    const {
      id,
      isActive,
      linkedRelations,
      offerType,
      isPaid,
      dateAssignment,
      matchMailPeriod,
    } = data;
    const intl = useIntl();
    const renderType = useMemo(() => {
      const { alvOveType, bogOveType, partOveType } = data;

      if (!!partOveType) {
        return partOveType
          .filter((t) => !!t)
          .map((t) =>
            intl.formatMessage({
              id: `typePARTOptions.${t.toString()}`,
              defaultMessage: `typePARTOptions.${t.toString()}`,
            })
          )
          .join(", ");
      }
      if (!!bogOveType) {
        return bogOveType
          .filter((t) => !!t)
          .map((t) =>
            intl.formatMessage({
              id: `typeBOGOptions.${t.toString()}`,
              defaultMessage: `typeBOGOptions.${t.toString()}`,
            })
          )
          .join(", ");
      }
      if (!!alvOveType) {
        return alvOveType
          .filter((t) => !!t)
          .map((t) =>
            intl.formatMessage({
              id: `typeALVOptions.${t.toString()}`,
              defaultMessage: `typeALVOptions.${t.toString()}`,
            })
          )
          .join(", ");
      }
    }, [data]);

    const renderPrice = useMemo(() => {
      const { minPrice, maxPrice } = data;
      let key, min, max;

      if (minPrice !== undefined && maxPrice !== undefined) {
        key = "searchAssignmentPriceMinMax";
        min = intlContext.formatNumber(minPrice, {
          style: "currency",
          currency: "EUR",
        });
        max = intlContext.formatNumber(maxPrice, {
          style: "currency",
          currency: "EUR",
        });
      } else if (minPrice !== undefined) {
        key = "searchAssignmentPriceMin";
        min = intlContext.formatNumber(minPrice, {
          style: "currency",
          currency: "EUR",
        });
      } else if (maxPrice !== undefined) {
        key = "searchAssignmentPriceMax";
        max = intlContext.formatNumber(maxPrice, {
          style: "currency",
          currency: "EUR",
        });
      }
      return <I18n value={key} values={{ min, max }} />;
    }, [data]);

    const navigate = useCallback(() => {
      onNavigate(route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id }));
    }, [id, onNavigate]);

    return (
      <Row
        zebra={zebra}
        onClick={navigate}
        className={!isActive ? "archived" : ""}
      >
        <Column index={0}>
          <div styleName={classNames("column")}>
            {(linkedRelations || []).map((relation) => (
              <RelationPill
                relation={relation}
                key={relation.id}
                onNavigate={onNavigate}
                isActive={isActive}
              />
            ))}
          </div>
        </Column>
        <Column index={1}>
          <div styleName="column">
            <I18n prefix="offerTypeOptions" value={offerType.toString()} />
          </div>
        </Column>
        <Column index={2}>
          <div styleName="column">{renderType}</div>
        </Column>
        <Column index={3}>
          <div styleName="column">{renderPrice}</div>
        </Column>
        <Column index={4}>
          <div styleName="column">
            {!!isPaid && (
              <Icon name="check" size={24} color={Colors.ActionListIcon} />
            )}
          </div>
        </Column>
        <Column index={5}>
          <div styleName="column">
            {dateAssignment && moment(dateAssignment).format("DD-MM-YYYY")}
          </div>
        </Column>
        <Column index={6}>
          <div styleName="column">
            <I18n
              prefix="matchMailPeriodOptions"
              value={matchMailPeriod.toString()}
            />
          </div>
        </Column>
        <Actions item={data} />
      </Row>
    );
  })
);
