import { RealtorSuggestionItem } from "@haywork/api/mls";
import { MapDispatchToProps } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RealtorComponent, RealtorComponentProps } from "./realtor";

type StateProps = {};
type DispatchProps = {
  querySuggestions: (term: string) => Promise<RealtorSuggestionItem[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  RealtorComponentProps,
  AppState
> = (state, ownProps) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  RealtorComponentProps
> = (dispatch) => ({
  querySuggestions: (term: string) =>
    dispatch(MLSThunk.List.queryRealtorSuggestions(term)),
});

export type RealtorContainerProps = StateProps & DispatchProps;
const RealtorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RealtorComponent);

export default RealtorContainer;
