import { FinancialAdministrationGroup } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./groups.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface GroupComponentProps {
  group: FinancialAdministrationGroup;
  onRemove: (id: number) => void;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class GroupComponent extends React.Component<GroupComponentProps> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);

    this.formControls = {
      id: { value: value(this.props.group, "id") },
      name: { value: value(this.props.group, "name") },
      budgetedNumberOfProducts: {
        value: value(this.props.group, "budgetedNumberOfProducts"),
      },
      budgetedAmount: { value: value(this.props.group, "budgetedAmount") },
    };
  }

  public render() {
    return (
      <Form
        name={`group-${this.props.group.id}`}
        formControls={this.formControls}
        onChange={this.props.onChange}
      >
        <div styleName="group">
          <div styleName="column">
            <Input.Text
              name="name"
              maxLength={128}
              placeholder="groupNamePlaceholder"
            />
          </div>
          <div styleName="column">
            <Input.Number name="budgetedNumberOfProducts" maxLength={999} />
          </div>
          <div styleName="column">
            <Input.Number name="budgetedAmount" maxLength={99999} pretty />
          </div>
          <div styleName="column remove">
            <div styleName="trigger" onClick={this.onRemoveClickHandler}>
              <i className="fal fa-minus-circle" />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onRemoveClickHandler() {
    const { id } = this.props.group;
    this.props.onRemove(id);
  }
}
