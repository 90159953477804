import Accounts from "./accounts";
import Drafts from "./drafts";
import Folders from "./folders";
import Main from "./main";
import Messages from "./messages";
import CommunicationLogs from "./communication-logs";
import Templates from "./templates";
import Providers from "./providers";
import Shares from "./shares";

export const EmailThunk = {
  Accounts,
  Drafts,
  Folders,
  Main,
  Messages,
  CommunicationLogs,
  Templates,
  Providers,
  Shares
};
