import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { PermissionGroup } from "./";
import {
  PermissionResourceCategory,
  LinkedPermissionResource,
} from "@haywork/api/authorization";
import { Hint } from "@haywork/modules/shared";

const styles = require("./consent-modal.component.scss");

interface Props {
  group: PermissionGroup;
  numberOfPermissions: number;
}
interface State {
  collapsed: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class PermissionGroupComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.numberOfPermissions >= 10,
    };

    this.renderIcon = this.renderIcon.bind(this);
  }

  public render() {
    const { displayName, permissionResource, permissions } = this.props.group;
    const permissionsStyle = classNames("permissions", {
      collapsed: this.state.collapsed,
    });

    return (
      <div styleName={permissionsStyle}>
        <div
          styleName="permissions__header"
          onClick={() =>
            this.setState(({ collapsed }) => ({ collapsed: !collapsed }))
          }
        >
          {this.renderIcon(permissionResource)}
          <div styleName="name">{displayName}</div>
          <div styleName="toggle-expanded">
            <i className="fal fa-chevron-up" />
          </div>
        </div>
        <div styleName="permissions__list">
          {permissions.map((permission, idx) => (
            <div styleName="permission" key={idx}>
              <div styleName="title">{permission.title}</div>
              <div styleName="description">
                <Hint message={permission.description} forceInline={true}>
                  <i className="fal fa-info-circle" />
                </Hint>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  private renderIcon(category: LinkedPermissionResource) {
    let icon;
    switch (category.category) {
      case PermissionResourceCategory.AgendaItem:
      case PermissionResourceCategory.AgendaItemCategory: {
        icon = "fal fa-calendar";
        break;
      }
      case PermissionResourceCategory.Assignment:
      case PermissionResourceCategory.Cadastre:
      case PermissionResourceCategory.ObjectAssignment:
      case PermissionResourceCategory.ProjectAssignment:
      case PermissionResourceCategory.TransactionMetaData:
      case PermissionResourceCategory.AssignmentRequest:
      case PermissionResourceCategory.ObjectTypeAssignment:
      case PermissionResourceCategory.Publication: {
        icon = "fal fa-folder-open";
        break;
      }
      case PermissionResourceCategory.Blob:
      case PermissionResourceCategory.Brochure:
      case PermissionResourceCategory.DocumentSession:
      case PermissionResourceCategory.DossierItem:
      case PermissionResourceCategory.DocumentTemplates:
      case PermissionResourceCategory.TemplateDefinition:
      case PermissionResourceCategory.TemplateDefinitionCategory:
      case PermissionResourceCategory.CommunicationLog:
      case PermissionResourceCategory.CommunicationLogBlob: {
        icon = "fal fa-file";
        break;
      }
      case PermissionResourceCategory.BusinessPartner:
      case PermissionResourceCategory.ContactCompany:
      case PermissionResourceCategory.ContactPerson:
      case PermissionResourceCategory.Employee:
      case PermissionResourceCategory.Relation:
      case PermissionResourceCategory.RelationGroup: {
        icon = "fal fa-user-alt";
        break;
      }
      case PermissionResourceCategory.FinancialAdministration:
      case PermissionResourceCategory.Invoice: {
        icon = "fal fa-euro-sign";
        break;
      }
      case PermissionResourceCategory.CompanySettings: {
        icon = "fal fa-cog";
        break;
      }
      case PermissionResourceCategory.ContactLink: {
        icon = "fal fa-link";
        break;
      }
      case PermissionResourceCategory.EventHub: {
        icon = "fal fa-traffic-light-go";
        break;
      }
      case PermissionResourceCategory.GlobalSearch:
      case PermissionResourceCategory.Matches:
      case PermissionResourceCategory.SearchAssignment:
      case PermissionResourceCategory.Geo: {
        icon = "fal fa-search";
        break;
      }
      case PermissionResourceCategory.Mastertable:
      case PermissionResourceCategory.Phrase: {
        icon = "fal fa-file-alt";
        break;
      }
      case PermissionResourceCategory.MediaContract: {
        icon = "fal fa-rocket";
        break;
      }
      case PermissionResourceCategory.Message:
      case PermissionResourceCategory.AccountSettings: {
        icon = "fal fa-envelope";
        break;
      }
      case PermissionResourceCategory.Note: {
        icon = "fal fa-sticky-note";
        break;
      }
      case PermissionResourceCategory.Office:
      case PermissionResourceCategory.RealEstateAgency: {
        icon = "fal fa-building";
        break;
      }
      case PermissionResourceCategory.Reminder: {
        icon = "fal fa-bell";
        break;
      }
      case PermissionResourceCategory.Statistics: {
        icon = "fal fa-chart-bar";
        break;
      }
      case PermissionResourceCategory.Task: {
        icon = "fal fa-tasks";
        break;
      }
      case PermissionResourceCategory.TimelineEvent: {
        icon = "fal folder-open";
        break;
      }
      case PermissionResourceCategory.Other:
      case PermissionResourceCategory.Key:
      default: {
        icon = "fal fa-key";
        break;
      }
    }

    return (
      <div styleName="category-icon">
        <i className={icon} />
      </div>
    );
  }
}
