import I18n from "@haywork/components/i18n";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { CreateMenuContainerProps } from "@haywork/modules/layout";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-menu.component.scss");

export interface CreateMenuComponentProps {}
interface State {
  visible: boolean;
}
type Props = CreateMenuComponentProps & CreateMenuContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class CreateMenuComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.onCreateAgendaItemClickHandler =
      this.onCreateAgendaItemClickHandler.bind(this);
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const dropdownStyle = classNames("dropdown", {
      visible: this.state.visible,
    });

    return (
      <div
        styleName="create-menu"
        id="create-menu"
        data-cy="CY-createMenuButton"
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onClick={this.onClickHandler}
      >
        <div id="createNewObject" styleName="trigger">
          <i className="fal fa-fw fa-plus" />
          <I18n value="newButton" />
        </div>
        <div styleName={dropdownStyle}>
          <ul styleName="menu">
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewAssignment}
                data-cy="CY-createMenuAssignmentButton"
              >
                <div id="newContract" styleName="label">
                  <i className="fal fa-fw fa-folder" />
                  <span>
                    <I18n value="newButtonContract" />
                  </span>
                </div>
              </div>
            </li>
            <FeatureSwitch feature="ACQUISITIONS">
              <li>
                <div
                  styleName="link"
                  onClick={this.props.createNewAcquisition}
                  data-cy="CY-createMenuAcquisitionButton"
                >
                  <div id="newAcquisition" styleName="label">
                    <i className="fal fa-fw fa-handshake-alt" />
                    <span>
                      <I18n value="newButtonAcquisition" />
                    </span>
                  </div>
                </div>
              </li>
            </FeatureSwitch>
            <li>
              <div
                styleName="link"
                onClick={this.onCreateAgendaItemClickHandler}
                data-cy="CY-createMenuAppointmentButton"
              >
                <div id="newAppointment" styleName="label">
                  <i className="fal fa-fw fa-calendar" />
                  <span>
                    <I18n value="newButtonAppointment" />
                  </span>
                </div>
              </div>
            </li>
            {!this.props.isBackofficeEmployee && !!this.props.canSendEmail && (
              <li>
                <div
                  styleName="link"
                  onClick={this.props.createNewEmail}
                  data-cy="CY-createMenuEmailButton"
                >
                  <div id="newEmail" styleName="label">
                    <i className="fal fa-fw fa-envelope" />
                    <span>
                      <I18n value="newButtonEmail" />
                    </span>
                  </div>
                </div>
              </li>
            )}
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewTask}
                data-cy="CY-createMenuTaskButton"
              >
                <div id="newTask" styleName="label">
                  <i className="fal fa-fw fa-tasks" />
                  <span>
                    <I18n value="newButtonTask" />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewRelation}
                data-cy="CY-createMenuRelationButton"
              >
                <div id="newRelation" styleName="label">
                  <i className="fal fa-fw fa-address-book" />
                  <span>
                    <I18n value="newButtonRelation" />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewSearchAssignment}
                data-cy="CY-createMenuSearchAssignmentButton"
              >
                <div styleName="label">
                  <i className="fal fa-fw fa-search" />
                  <span>
                    <I18n value="newButtonSearchAssignment" />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewInvoice}
                data-cy="CY-createMenuInvoiceButton"
              >
                <div id="newInvoice" styleName="label">
                  <i className="fal fa-fw fa-euro-sign" />
                  <span>
                    <I18n value="invoice" />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewProject}
                data-cy="CY-createMenuProjectButton"
              >
                <div id="newProject" styleName="label">
                  <i className="fal fa-fw fa-sitemap" />
                  <span>
                    <I18n value="newButtonProject" />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div
                styleName="link"
                onClick={this.props.createNewDocument}
                data-cy="CY-createMenuDocumentButton"
              >
                <div id="newDocument" styleName="label">
                  <i className="fal fa-fw fa-file-alt" />
                  <span>
                    <I18n value="newButtonDocument" />
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  public onCreateAgendaItemClickHandler() {
    const start = new Date();
    start.setSeconds(0, 0);
    const end = new Date();
    end.setSeconds(0, 0);
    end.setMinutes(new Date().getMinutes() + 30);

    this.props.createNewAgendaItem(start, end, this.props.employeeId);
  }

  private onMouseEnterHandler() {
    this.setState({
      visible: true,
    });
  }

  private onMouseLeaveHandler() {
    this.setState({
      visible: false,
    });
  }

  private onClickHandler() {
    this.setState({
      visible: !this.state.visible,
    });
  }
}
