import { Dispatch } from ".";
import { UserManager } from "oidc-client";
import { push } from "connected-react-router";
import {
  AccountSettingsClient,
  Employee,
  EmployeesClient,
} from "@haywork/api/kolibri";
import { MAINROUTES, OIDC_CONFIG, REQUEST } from "@haywork/constants";
import {
  AccessActions,
  AccountActions,
  AppState,
  ErrorActions,
  LayoutActions,
  MainActions,
  SchedulerFilters,
  SchedulerActions,
  EditableActions,
} from "@haywork/stores";
import { Dates } from "@haywork/util/date";
import { ParseRequest } from "@haywork/services";
import { SCHEDULER_OTHER } from "@haywork/constants/scheduler-other";
import { UserDataElement } from "@haywork/api/mail";
import { AsyncUtil } from "@haywork/util";

const parseRequest = new ParseRequest();

const saveEmployee = (employee: Employee) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(AccountActions.setRelationEmployeeState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Employees = new EmployeesClient(host);

    // remove the displayName because if we don't, the API doesn't refresh it for some reason.
    if (employee.displayName) {
      employee.displayName = null;
    }
    // set the nickname, because the nickname is used for avatar letters. You can't change that in onboarding
    // this is a temporary fix.
    employee.nickname = employee.firstName;

    try {
      const updatedEmployee = await parseRequest.response(
        Employees.save({ employee }, realEstateAgencyId).then(
          (response) => response.employee
        )
      );

      dispatch(AccountActions.setEmployee(updatedEmployee));
    } catch (error) {
      dispatch(AccountActions.setRelationEmployeeState(REQUEST.ERROR));
      throw error;
    }
  };
};

const setSeenContactForm = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AccountSettings = new AccountSettingsClient(host);

    try {
      await parseRequest.response(
        AccountSettings.save(
          {
            accountSettings: {
              ...state.account.accountSettings,
              seenContactFormOn: Dates.getUTCNow(),
            },
          },
          realEstateAgencyId
        )
      );

      dispatch(AccountActions.hideOnboarding());
      dispatch(LayoutActions.toggleOnboarding(true));
    } catch (error) {
      dispatch(AccountActions.hideOnboarding());
      throw error;
    }
  };
};

const setSchedulerFilters = (filters: SchedulerFilters) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { accountSettings } = state.account;

    const AccountSettings = new AccountSettingsClient(host);
    dispatch(
      SchedulerActions.setSchedulerFilters({ schedulerFilters: filters })
    );

    let userData: UserDataElement[] = accountSettings.customUserSettings
      ? JSON.parse(accountSettings.customUserSettings)
      : [];
    userData = userData.filter(
      (data) => data.key !== SCHEDULER_OTHER.FILTER_SETTINGS_KEY
    );

    userData.push({
      key: SCHEDULER_OTHER.FILTER_SETTINGS_KEY,
      value: JSON.stringify(filters),
    });

    try {
      const result = await AccountSettings.save(
        {
          accountSettings: {
            ...state.account.accountSettings,
            customUserSettings: JSON.stringify(userData),
          },
        },
        realEstateAgencyId
      );

      dispatch(AccountActions.setAccountSettings(result.accountSettings));
    } catch (error) {
      dispatch(ErrorActions.setError(error));
    }
  };
};

const logout = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { oidcUri, oidcRedirectUri } = state.appSettings;

      const oidcUserManager = new UserManager({
        ...OIDC_CONFIG,
        authority: oidcUri,
        post_logout_redirect_uri: oidcRedirectUri,
        redirect_uri: oidcRedirectUri + "/callback.html",
        silent_redirect_uri: oidcRedirectUri + "/renew.html",
      });

      dispatch(MainActions.setAppStatus(REQUEST.PENDING));
      dispatch(EditableActions.clearAll());
      await AsyncUtil.wait(1000);

      await oidcUserManager.signoutRedirect();

      dispatch(push(MAINROUTES.DASHBOARD.URI));
      dispatch(MainActions.resetApp());
      dispatch(AccessActions.logout());
    } catch (error) {
      throw error;
    }
  };
};

export const AccessThunks = {
  logout,
  saveEmployee,
  setSeenContactForm,
  setSchedulerFilters,
};
