import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SingleFilterValue } from "@haywork/components/ui/list";
import {
  FilterGuidValues,
  SelectFilterEmptyValue,
} from "@haywork/components/ui/list/components/list-filter";
import { RelationType } from "@haywork/api/kolibri";

const officesSelector = (state: AppState) => state.offices.offices;
const employeesSelector = (state: AppState) => state.employee.employees;
const localitiesSelector = (state: AppState) =>
  state.relation.list.statistics.localities;
const relationTypesSelector = (state: AppState) =>
  state.main.mastertable.kolibri.relationTypes;
const relationGroupsSelector = (state: AppState) =>
  state.relationGroups.relationGroups;

export const officeValues = createSelector(officesSelector, (offices) => {
  return (offices || []).map(
    (office) =>
      ({
        label: office.displayName,
        value: office.id,
      } as SingleFilterValue)
  );
});

export const employeeValues = createSelector(employeesSelector, (employees) => {
  return (employees || []).map(
    (employee) =>
      ({
        label: employee.displayName,
        value: employee.id,
      } as SingleFilterValue)
  );
});

export const localityValues = createSelector(
  localitiesSelector,
  (localities) => {
    return [
      {
        label: "relations.filter.emptyValue.localities",
        value: SelectFilterEmptyValue,
      } as SingleFilterValue,
      ...(localities || []).map(
        (locality) =>
          ({
            label: locality,
            value: locality,
          } as SingleFilterValue)
      ),
    ];
  }
);

export const relationTypeValues = createSelector(
  relationTypesSelector,
  (relationTypes) => {
    return (relationTypes || [])
      .map(
        (relationType) =>
          ({
            label: relationType.displayName,
            value: relationType.value,
          } as SingleFilterValue)
      )
      .filter(
        (relationType) =>
          [RelationType.Office, RelationType.Employee].indexOf(
            relationType.value
          ) === -1
      );
  }
);

export const relationGroupsValues = createSelector(
  relationGroupsSelector,
  (relationGroups) => {
    return (relationGroups || []).map(
      (relationGroup) =>
        ({
          label: relationGroup.name,
          value: relationGroup.id,
        } as SingleFilterValue)
    );
  }
);

export const activeFilterGuidValues = createSelector(
  relationGroupsSelector,
  (relationGroups) => {
    return {
      relationGroupIds: (relationGroups || []).map((relationGroup) => ({
        value: relationGroup.name,
        id: relationGroup.id,
      })),
    } as FilterGuidValues;
  }
);
