import * as React from "react";
import classNames from "classnames";
import { ResourceText } from "@haywork/modules/shared";
import { REQUEST, ASSIGNMENTROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import { AssignmentsNeedAttentionContainerProps } from "./assignments-need-attention.container";
import { Assignment } from "./assignment.component";
import * as CSSModules from "react-css-modules";
import { Input, SwitchLabelPosition } from "@haywork/modules/form/components";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./assignments-need-attention.component.scss");

export interface AssignmentsNeedAttentionComponentProps {}
interface State {}
type Props = AssignmentsNeedAttentionComponentProps &
  AssignmentsNeedAttentionContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentsNeedAttentionComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.showAllAssignments = this.showAllAssignments.bind(this);
  }

  public componentDidMount() {
    this.props.getAssignmentsNeedAttentionEvents(
      this.props.showAllAssignmentsBool
    );
  }

  public render() {
    const showEmptyState =
      this.props.status === REQUEST.SUCCESS && this.props.events.length === 0;
    const showAllAssignmentsBool = this.props.showAllAssignmentsBool;

    return (
      <div className="widget-wrapper">
        <article className="widget">
          <header>
            <div className="intro">
              <span className="icon">
                <i className="fal fa-fw fa-heart" />
              </span>
              <span className="text">
                <ResourceText resourceKey="dashboardWidget.title.assignmentsNeedAttention" />
              </span>
            </div>
            <div className="actions">
              {/* <span className="text">
                <ResourceText resourceKey="dashboardWidget.assignmentsNeedAttention.myAssingmentsOnly" />
              </span> */}
              <Input.Switch
                name={"toggleMyAssignmentsOnly"}
                label="dashboardWidget.assignmentsNeedAttention.allObjects"
                labelPosition={SwitchLabelPosition.Pre}
                value={showAllAssignmentsBool}
                on={true}
                off={false}
                onChange={this.showAllAssignments}
              />
            </div>
          </header>

          <div
            className={classNames("content", {
              emptyState: showEmptyState,
            })}
            styleName="scroll-area"
          >
            {showEmptyState && (
              <ResourceText resourceKey="dashboardWidget.emptyState.assignmentsNeedAttention" />
            )}
            {this.props.events.map((event) => (
              <ErrorBoundary key={event.id}>
                <Assignment
                  assignments={event.linkedObjectAssignments}
                  event={event}
                  onClick={(id) =>
                    this.props.navigate(
                      route(ASSIGNMENTROUTES.DETAIL.URI, { id })
                    )
                  }
                  onArchive={this.props.eventArchive}
                  onCreateTask={this.props.createTask}
                />
              </ErrorBoundary>
            ))}
            {this.props.status === REQUEST.PENDING && <Ui.Loaders.List />}
          </div>

          {/*<footer>
          <Link
            to={MAINROUTES.ASSIGNMENTS.URI}
            className="btn btn-link btn-link-primary"
          >
            <ResourceText resourceKey="dashboardWidget.action.assignmentsNeedAttention" />
          </Link>
        </footer>*/}
        </article>
      </div>
    );
  }
  private showAllAssignments(showAllAssignments: boolean) {
    this.props.showAllAssignments(showAllAssignments);
  }
}
