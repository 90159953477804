import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { RootEntityType } from "@haywork/api/event-center";
import * as moment from "moment";

const presentEmployeesSelector = (state: AppState) =>
  state.eventCenter.presence.presentEmployees || [];
const employeeIdSelector = (state: AppState) => state.account.employee.id;
const guidSelector = (state: AppState) => state.eventCenter.presence.guid;
const nonEditTypes = [RootEntityType.Agendaitem, RootEntityType.Task];

type MatchingEmployeesProps = {
  entityId: string;
  entityType: RootEntityType | null;
  inEditMode: boolean;
};
export const blockDoubleView = (props: MatchingEmployeesProps) =>
  createSelector(
    employeeIdSelector,
    presentEmployeesSelector,
    guidSelector,
    (employeeId, employees, guid) => {
      const matchingEmployees = employees.filter(
        (employee) => employee.employeeId === employeeId
      );
      if (matchingEmployees.length <= 1) return false;

      const self = matchingEmployees.find((employee) => employee.guid === guid);
      const others = matchingEmployees.filter(
        (employee) => employee.guid !== guid
      );
      if (!self || !others.length) return false;

      const match = (self.entities || []).find(
        (entity) =>
          entity.id === props.entityId &&
          entity.type === props.entityType &&
          (nonEditTypes.includes(entity.type) ||
            (!!props.inEditMode && !!entity.inEditMode))
      );
      if (!match?.openedOn) return false;

      return others.reduce((state, matchingState) => {
        const matches = (matchingState.entities || []).filter(
          (entity) =>
            entity.id === props.entityId &&
            entity.type === props.entityType &&
            (nonEditTypes.includes(entity.type) ||
              (!!props.inEditMode && !!entity.inEditMode))
        );
        matches.forEach((matched) => {
          if (
            !!matched?.openedOn &&
            moment(matched.openedOn).isBefore(moment(match.openedOn))
          ) {
            state = true;
          }
        });

        return state;
      }, false);
    }
  );
