import {
  ContactCompany,
  ContactPerson,
  RelationSnapShot
} from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";
import { RelationFilter } from "@haywork/middleware/thunk/relation.thunk";
import * as ActionType from "./relation.types";

const searchRelation = (payload: ActionType.RelationResponse) => ({
  type: REDUX.RELATION.SEARCH_RELATION,
  ...payload
});

const searchContactPerson = (payload: ActionType.ContactPersonResponse) => ({
  type: REDUX.RELATION.SEARCH_CONTACT_PERSON,
  ...payload
});

const searchContactPerson2 = (contactPerson: ContactPerson) => ({
  type: REDUX.RELATION.SEARCH_CONTACT_PERSON,
  contactPerson
});

const searchContactCompany = (payload: ActionType.ContactCompanyResponse) => ({
  type: REDUX.RELATION.SEARCH_CONTACT_COMPANY,
  ...payload
});

const searchContactCompany2 = (contactCompany: ContactCompany) => ({
  type: REDUX.RELATION.SEARCH_CONTACT_COMPANY,
  contactCompany
});

const searchTimelineItems = (
  payload: ActionType.TimelineEventsResponse,
  take: number
) => ({
  type: REDUX.RELATION.SEARCH_TIMELINE_EVENTS,
  ...payload,
  take
});

const appendTimelineItems = (
  payload: ActionType.TimelineEventsResponse,
  take: number
) => ({
  type: REDUX.RELATION.APPEND_TIMELINE_EVENTS,
  ...payload,
  take
});

const searchAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.RELATION.SEARCH_ASSIGNMENTS,
  ...payload
});

const searchContactLinks = (payload: ActionType.ContactLinksResponse) => ({
  type: REDUX.RELATION.SEARCH_CONTACT_LINKS,
  ...payload
});

const searchRelationGroups = (payload: ActionType.RelationGroupsResponse) => ({
  type: REDUX.RELATION.SEARCH_RELATIONGROUPS,
  ...payload
});

const appendRelation = (payload: ActionType.RelationResponse) => ({
  type: REDUX.RELATION.APPEND_RELATION,
  ...payload
});

const appendSingleContactLink = (
  payload: ActionType.SingleContactLinkResponse
) => ({
  type: REDUX.RELATION.APPEND_SINGLE_CONTACT_LINK,
  ...payload
});

const appendAssignments = (payload: ActionType.AssignmentsResponse) => ({
  type: REDUX.RELATION.APPEND_ASSIGNMENTS,
  ...payload
});

const appendContactLinks = (payload: ActionType.ContactLinksResponse) => ({
  type: REDUX.RELATION.APPEND_CONTACT_LINKS,
  ...payload
});

const updateRelationFilter = (relationFilter?: RelationFilter) => ({
  type: REDUX.RELATION.UPDATE_RELATION_FILTER,
  relationFilter
});

const updateContactLink = (payload: ActionType.SingleContactLinkResponse) => ({
  type: REDUX.RELATION.UPDATE_CONTACTLINK,
  ...payload
});

const selectRelation = (relation: RelationSnapShot) => ({
  type: REDUX.RELATION.SELECT_RELATION,
  relation
});

const setFetchRelationOverviewState = (relationOverviewState: string) => ({
  type: REDUX.RELATION.SET_FETCH_RELATION_OVERVIEW_STATE,
  relationOverviewState
});

const setInitialFetchRelationOverviewState = (
  relationOverviewState: string
) => ({
  type: REDUX.RELATION.SET_INITIAL_FETCH_RELATION_OVERVIEW_STATE,
  relationOverviewState
});

const setFetchRelationContactInfoState = (
  relationContactInfoState?: string
) => ({
  type: REDUX.RELATION.SET_FETCH_RELATION_GENERAL_CONTACT_INFO_STATE,
  relationContactInfoState
});

const setPathForNewRelation = (
  pathForNewRelation: ActionType.PathForNewRelation
) => ({
  type: REDUX.RELATION.SET_PATH_FOR_NEW_RELATION,
  ...pathForNewRelation
});

const setFetchRelationContactLinkState = (
  relationContactLinkState?: string
) => ({
  type: REDUX.RELATION.SET_FETCH_RELATION_CONTACT_LINK_STATE,
  relationContactLinkState
});

const setFetchRelationTimelineStatus = (relationTimelineStatus: string) => ({
  type: REDUX.RELATION.SET_FETCH_RELATION_TIMELINE_STATE,
  relationTimelineStatus
});

const setSaveRelationContactInfoState = (
  saveRelationContactInfoState: string
) => ({
  type: REDUX.RELATION.SET_SAVE_CONTACT_INFO_STATE,
  saveRelationContactInfoState
});

const setRelationAssignmentState = (relationAssignmentState: string) => ({
  type: REDUX.RELATION.SET_RELATION_ASSIGNMENT_STATE,
  relationAssignmentState
});

const setContactCompanyUpdateState = (contactCompanyUpdateState: string) => ({
  type: REDUX.RELATION.SET_CONTACT_COMPANY_UPDATE_STATE,
  contactCompanyUpdateState
});

const setSaveContactLinkState = (relationSaveContactLinkState: string) => ({
  type: REDUX.RELATION.SET_RELATION_SAVE_CONTACT_LINK_STATE,
  relationSaveContactLinkState
});

const setAddressSearchState = (relationAddressSearchState: string) => ({
  type: REDUX.RELATION.SET_RELATION_ADDRESS_SEARCH_STATE,
  relationAddressSearchState
});

const archiveRelation = (relation: RelationSnapShot) => ({
  type: REDUX.RELATION.ARCHIVE_RELATION,
  relation
});

const removeRelationFromList = (payload: ActionType.ContactLink) => ({
  type: REDUX.RELATION.REMOVE_FROM_LIST,
  ...payload
});

const setExportUrl = (relationExportUrl: string) => ({
  type: REDUX.RELATION.SET_RELATION_EXPORT_URL,
  relationExportUrl
});

const setFetchRelationSearchAssignmentsStatus = (
  relationSearchAssignmentsStatus: string
) => ({
  type: REDUX.RELATION.SET_FETCH_RELATION_SEARCH_ASSIGNMENTS_STATUS,
  relationSearchAssignmentsStatus
});

const setRelationSearchAssignments = (
  payload: ActionType.SearchAssignments
) => ({
  type: REDUX.RELATION.SET_RELATION_SEARCH_ASSIGNMENTS,
  ...payload
});

const setContactPerson = (payload: ActionType.ContactPersonResponse) => ({
  type: REDUX.RELATION.SET_CONTACT_PERSON,
  ...payload
});

const setContactCompany = (payload: ActionType.ContactCompanyResponse) => ({
  type: REDUX.RELATION.SET_CONTACT_COMPANY,
  ...payload
});

const updateListItem = (payload: ActionType.SnapShot) => ({
  type: REDUX.RELATION.UPDATE_LIST_ITEM,
  ...payload
});

const deleteListItem = (payload: ActionType.Guid) => ({
  type: REDUX.RELATION.DELETE_LIST_ITEM,
  ...payload
});

export const RelationActions = {
  appendAssignments,
  appendContactLinks,
  appendRelation,
  appendSingleContactLink,
  appendTimelineItems,
  archiveRelation,
  removeRelationFromList,
  searchAssignments,
  searchContactCompany,
  searchContactCompany2,
  searchContactLinks,
  searchContactPerson,
  searchContactPerson2,
  searchRelation,
  searchRelationGroups,
  searchTimelineItems,
  selectRelation,
  setAddressSearchState,
  setContactCompanyUpdateState,
  setExportUrl,
  setFetchRelationContactInfoState,
  setFetchRelationContactLinkState,
  setFetchRelationOverviewState,
  setFetchRelationTimelineStatus,
  setInitialFetchRelationOverviewState,
  setPathForNewRelation,
  setRelationAssignmentState,
  setSaveContactLinkState,
  setSaveRelationContactInfoState,
  updateContactLink,
  updateRelationFilter,
  setFetchRelationSearchAssignmentsStatus,
  setRelationSearchAssignments,
  setContactPerson,
  setContactCompany,
  updateListItem,
  deleteListItem
};
