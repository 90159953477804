import { Action } from "redux";
import differenceBy from "lodash-es/differenceBy";

import { REDUX, REQUEST, COUNTS } from "@haywork/constants";
import { ExtendedEmailDraft } from "@haywork/util/email";

import * as ActionType from "./drafts.types";
import { IdentifierPayload } from "@haywork/stores/helpers";

export interface DraftsState {
  drafts: ExtendedEmailDraft[];
  currentDraft: ExtendedEmailDraft;
  currentDraftStatus: string;
}

const INITIAL_STATE: DraftsState = {
  drafts: [],
  currentDraft: null,
  currentDraftStatus: REQUEST.IDLE
};

export const draftsReducer = (state: DraftsState = INITIAL_STATE, action: Action): DraftsState => {
  switch (action.type) {
    case REDUX.EMAILV2.APPEND_DRAFTS: {
      const { drafts: appendables } = <ActionType.DraftsPayload>action;
      let drafts = differenceBy(state.drafts, appendables, (message) => message.id);

      drafts = [...drafts, ...appendables];

      return {
        ...state,
        drafts
      };
    }
    case REDUX.EMAILV2.DELETE_DRAFT: {
      const { id } = <IdentifierPayload>action;
      const drafts = state.drafts.filter((draft) => draft.id !== id);

      return {
        ...state,
        drafts
      };
    }
    case REDUX.EMAILV2.SET_DRAFT: {
      const { draft: currentDraft } = <ActionType.DraftPayload>action;

      return {
        ...state,
        currentDraft,
        currentDraftStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.EMAILV2.SET_DRAFT_STATUS: {
      const { currentDraftStatus } = <ActionType.DraftStatus>action;

      return {
        ...state,
        currentDraftStatus
      };
    }
    case REDUX.EMAILV2.REMOVE_ACCOUNT: {
      const { id } = <IdentifierPayload>action;
      const drafts = state.drafts.filter((draft) => draft.accountId !== id);

      let currentDraft = null;
      let currentDraftStatus = REQUEST.IDLE;

      if (!!state.currentDraft && state.currentDraft.accountId !== id) {
        currentDraft = state.currentDraft;
        currentDraftStatus = state.currentDraftStatus;
      }

      return {
        ...state,
        drafts,
        currentDraft,
        currentDraftStatus
      };
    }
    case REDUX.EMAILV2.SET_CURRENT_FOLDER: {
      const { folder: currentFolder } = <ActionType.FolderPayload>action;

      const folderIds: string[] = [];
      const folders: { folderId: string; drafts: ExtendedEmailDraft[] }[] = state.drafts.reduce((state, draft) => {
        const idx = folderIds.indexOf(draft.folderId);
        if (idx === -1) {
          folderIds.push(draft.folderId);
          state.push({
            folderId: draft.folderId,
            drafts: [draft]
          });
        } else {
          state[idx].drafts.push(draft);
        }

        return state;
      }, []);

      const drafts: ExtendedEmailDraft[] = folders.reduce((state, folder) => {
        const drafts =
          folder.folderId === currentFolder.id ? folder.drafts : folder.drafts.slice(0, COUNTS.EMAIL_MESSAGES);

        return [...state, ...drafts];
      }, []);

      return {
        ...state,
        drafts
      };
    }
    case REDUX.EMAILV2.UPDATE_TEMPORARY_ID: {
      const { temporaryId } = <ActionType.TemporaryId>action;

      let currentDraft = state.currentDraft;
      if (!!currentDraft) {
        currentDraft = {
          ...currentDraft,
          temporaryId
        };
      }

      return {
        ...state,
        currentDraft
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
