import { ObjectAssignment } from "@haywork/api/kolibri";
import { WindowPresentationPrintContainerProps } from "@haywork/modules/assignment";
import { Input } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui, UiOptionListAlignment } from "@haywork/modules/ui";
import { ModalPortal } from "@haywork/portals";
import first from "lodash-es/first";
import * as React from "react";
import { Fragment } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./window-presentation-print.component.scss");

export interface WindowPresentationPrintComponentProps {
  assignment: ObjectAssignment;
  visible: boolean;
  onClose: () => void;
}
interface State {
  printReady: boolean;
  pageCount: number;
  template: string;
  statusText: string;
}
type Props = WindowPresentationPrintComponentProps &
  WindowPresentationPrintContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class WindowPresentationPrintComponent extends React.Component<
  Props,
  State
> {
  private reportViewer: any;
  private reportViewerData: any;

  constructor(props) {
    super(props);

    this.state = {
      printReady: false,
      pageCount: 0,
      template:
        !!this.props.printTemplates && this.props.printTemplates.length > 0
          ? first(this.props.printTemplates).key
          : "",
      statusText: "windowPresentationPrintLoading",
    };

    this.bindReportingInstance = this.bindReportingInstance.bind(this);
    this.onTemplateChangeHandler = this.onTemplateChangeHandler.bind(this);
    this.onExportChangeHandler = this.onExportChangeHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.onZoomOutClickHandler = this.onZoomOutClickHandler.bind(this);
    this.onZoomInClickHandler = this.onZoomInClickHandler.bind(this);
    this.onPreviousPageClickHandler = this.onPreviousPageClickHandler.bind(
      this
    );
    this.onNextPageClickHandler = this.onNextPageClickHandler.bind(this);
    this.onPrintClickHandler = this.onPrintClickHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    let state: State = {
      ...this.state,
    };

    if (!!nextProps.printTemplates && nextProps.printTemplates.length > 0) {
      state = {
        ...state,
        template: first(nextProps.printTemplates).key,
      };
    }

    this.setState(state);
  }

  public render() {
    if (!this.props.visible) return null;
    const { displayName } = this.props.assignment;
    const { key, values } = !!displayName
      ? { key: "printWindowPresentationWithName", values: { displayName } }
      : { key: "printWindowPresentation", values: {} };

    return (
      <ModalPortal>
        <div styleName="report__wrapper">
          <div styleName="report">
            <div styleName="report__header">
              <div styleName="path">
                <ResourceText resourceKey={key} values={values} asHtml />
              </div>
              <div styleName="close" onClick={this.props.onClose}>
                <i className="fal fa-times" />
              </div>
            </div>

            <div styleName="report__actions">
              <div styleName="left">
                {/* Select template */}
                <div styleName="element">
                  <label htmlFor="template">
                    <ResourceText resourceKey="template" />
                  </label>
                  <Input.NewSelect
                    name="template"
                    values={this.props.printTemplates}
                    displayProp="displayName"
                    valuesProp="key"
                    value={this.state.template}
                    disabled={!this.state.printReady}
                    onChange={this.onTemplateChangeHandler}
                  />
                </div>
              </div>
              <div styleName="right">
                <Ui.OptionList
                  items={this.props.printExportOptions}
                  displayPath="localizedName"
                  valuePath="name"
                  triggerValue="exportWindowPresentation"
                  icon="fal fa-download"
                  alignment={UiOptionListAlignment.Right}
                  onChange={this.onExportChangeHandler}
                />
              </div>

              {!this.state.printReady && (
                <div styleName="status">
                  <ResourceText resourceKey={this.state.statusText} />
                </div>
              )}
            </div>

            <div styleName="report__body" ref={this.bindRef} />

            <div styleName="report__footer">
              <div styleName="left">
                <button
                  styleName="footer-action"
                  onClick={this.onZoomOutClickHandler}
                  disabled={!this.state.printReady}
                >
                  <i className="fal fa-search-minus" />
                </button>

                <button
                  styleName="footer-action"
                  onClick={this.onZoomInClickHandler}
                  disabled={!this.state.printReady}
                >
                  <i className="fal fa-search-plus" />
                </button>

                {this.state.pageCount > 1 && (
                  <Fragment>
                    <div styleName="divider" />

                    <button
                      styleName="footer-action"
                      onClick={this.onPreviousPageClickHandler}
                      disabled={!this.state.printReady}
                    >
                      <i className="fal fa-chevron-left" />
                    </button>

                    <button
                      styleName="footer-action"
                      onClick={this.onNextPageClickHandler}
                      disabled={!this.state.printReady}
                    >
                      <i className="fal fa-chevron-right" />
                    </button>
                  </Fragment>
                )}
              </div>
              <div styleName="right">
                <button
                  className="btn btn-success"
                  onClick={this.onPrintClickHandler}
                  disabled={!this.state.printReady}
                >
                  <ResourceText resourceKey="print" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  }

  private onTemplateChangeHandler(template: string) {
    if (template === this.state.template) return;
    this.setState({ template });

    this.reportViewerData
      .reportSource({
        report: template,
        parameters: {
          rootEntityIds: [this.props.assignment.id],
        },
      })
      .refreshReport();
  }

  private onExportChangeHandler(value) {
    if (!value) return;
    this.reportViewerData.commands.export.exec(value);
  }

  private onPrintClickHandler() {
    if (!this.state.printReady) return;
    this.reportViewerData.commands.print.exec();
  }

  private onZoomInClickHandler() {
    if (!this.state.printReady) return;
    this.reportViewerData.commands.zoomIn.exec();
  }

  private onZoomOutClickHandler() {
    if (!this.state.printReady) return;
    this.reportViewerData.commands.zoomOut.exec();
  }

  private onPreviousPageClickHandler() {
    if (!this.state.printReady || this.reportViewerData.currentPage() === 1)
      return;
    this.reportViewerData.commands.goToPrevPage.exec();
  }

  private onNextPageClickHandler() {
    if (
      !this.state.printReady ||
      this.reportViewerData.currentPage() === this.reportViewerData.pageCount()
    )
      return;
    this.reportViewerData.commands.goToNextPage.exec();
  }

  private bindRef(ref: HTMLDivElement) {
    this.reportViewer = $(ref);
    this.bindReportingInstance();
  }

  private bindReportingInstance() {
    const { template } = this.state;

    this.reportViewer.telerik_ReportViewer({
      serviceUrl: `${this.props.host}/${this.props.apiVersion}/${this.props.realEstateAgencyId}/Reports`,
      templateUrl: "/assets/kendo/templates/ReportViewerKolibri.html",
      authenticationToken: this.props.token,
      reportSource: {
        report: template,
        parameters: {
          rootEntityIds: [this.props.assignment.id],
        },
      },
      viewMode: "PRINT_PREVIEW",
      printMode: "FORCE_PDF_FILE",
      pageReady: () => {
        const pageCount = !!this.reportViewerData
          ? this.reportViewerData.pageCount()
          : 0;
        this.setState({ printReady: true, pageCount });
      },
      printBegin: () => {
        this.setState({
          printReady: false,
          statusText: "windowPresentationPrintPreperation",
        });
      },
      printEnd: () => {
        this.setState({ printReady: true });
      },
      renderingBegin: () => {
        this.setState({
          printReady: false,
          statusText: "windowPresentationPrintLoading",
        });
      },
    });

    this.reportViewerData = this.reportViewer.data("telerik_ReportViewer");
  }
}
