import {
  DefaultFolderTree,
  DefaultFolderTreeCategory
} from "@haywork/api/kolibri";
import { FolderTreesThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { defaultFolderTrees, DefaultFolderTreesGroup } from "../../selectors";
import {
  SettingsDossierFoldersOverview,
  SettingsDossierFoldersOverviewProps
} from "./overview";

interface StateProps {
  defaultFolderTrees: DefaultFolderTreesGroup[];
}
interface DispatchProps {
  searchDefaultFolderTrees: () => Promise<void>;
  defineNewDefaultFolderTree: (
    category: DefaultFolderTreeCategory
  ) => Promise<DefaultFolderTree>;
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsDossierFoldersOverviewProps,
  AppState
> = (state) => {
  return {
    defaultFolderTrees: defaultFolderTrees(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsDossierFoldersOverviewProps
> = (dispatch) => ({
  searchDefaultFolderTrees: () =>
    dispatch(FolderTreesThunk.searchDefaultFolderTrees()),
  defineNewDefaultFolderTree: (category: DefaultFolderTreeCategory) =>
    dispatch(FolderTreesThunk.defineNewDefaultFolderTree(category)),
  navigate: (path: string) => dispatch(push(path))
});

export type SettingsDossierFoldersContainerProps = StateProps & DispatchProps;
export const SettingsDossierFoldersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsDossierFoldersOverview);
