import {
  AssignmentSnapShot,
  ObjectAssignment,
  ProjectAssignment,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import I18n from "@haywork/components/i18n";
import AssignmentInfo from "@haywork/components/ui/assignment-info";
import Icon from "@haywork/components/ui/icon";
import RelationInfo from "@haywork/components/ui/relation-info";
import { Colors } from "@haywork/enum/colors";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { RelationUtil } from "@haywork/util";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  type: "relation" | "assignment";
  item: ObjectAssignment | ProjectAssignment | RelationSnapShot;
  onRemove: (id: string) => void;
  onClick: (id: string) => void;
  emailCallback?: (relation: RelationSnapShot) => void;
  onMouseDown: () => void;
}
export const FormSuggestionItemComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, type, onClick, onMouseDown, onRemove, emailCallback }: Props) => {
      const [hover, toggleHover] = useState(false);
      const contextMenuParentRef = useRef<HTMLDivElement>();
      const contextMenuParentRef1 = useRef<HTMLDivElement>();
      const timeoutRef = useRef<any>(null);

      const mouseEnterCallback = useCallback(() => {
        if (!!timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        toggleHover(true);
      }, [toggleHover]);

      const mouseLeaveCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          toggleHover(false);
        }, 50);
      }, [toggleHover]);

      const relation = useMemo(
        () =>
          type === "relation"
            ? RelationUtil.mapLinkedRelationToSnapshot(item as RelationSnapShot)
            : undefined,
        [item, type]
      );

      const assignment = useMemo(
        () =>
          type === "assignment"
            ? ((item as unknown) as AssignmentSnapShot)
            : undefined,
        [item, type]
      );

      return (
        <div styleName="item">
          <div
            styleName="item__toggle"
            onMouseDown={onMouseDown}
            onMouseUp={() => onRemove(item.id)}
          >
            <i className="fa fa-check-square" />
          </div>
          <div styleName="item__name">
            <div
              styleName="name"
              onMouseDown={onMouseDown}
              onMouseUp={() => onClick(item.id)}
            >
              {"publicReference" in item && item.displayName === ""
                ? item.publicReference
                : item.displayName}
            </div>
            {!item.isActive && (
              <div styleName="archived">
                <I18n value="archived" />
              </div>
            )}

            <FeatureSwitch feature="RELATION_INFO">
              <div
                styleName="context-menu-trigger"
                onMouseEnter={mouseEnterCallback}
                onMouseLeave={mouseLeaveCallback}
                ref={contextMenuParentRef}
              >
                <Icon name="info-circle" color={Colors.Primary} size={14} />
              </div>
              <ContextMenu
                visible={hover}
                parent={contextMenuParentRef}
                width={500}
                position={ContextMenuPosition.TopRight}
              >
                <div
                  styleName="context-menu"
                  onMouseEnter={mouseEnterCallback}
                  onMouseLeave={mouseLeaveCallback}
                >
                  {type === "relation" && (
                    <RelationInfo
                      item={relation}
                      visible={hover}
                      emailCallback={emailCallback ? emailCallback : undefined}
                    />
                  )}
                  {type === "assignment" && (
                    <AssignmentInfo
                      item={assignment}
                      visible={hover}
                      emailCallback={emailCallback ? emailCallback : undefined}
                    />
                  )}
                </div>
              </ContextMenu>
            </FeatureSwitch>
          </div>
        </div>
      );
    }
  )
);
