import { BASEROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { MainThunks, Dispatch } from "@haywork/middleware/thunk";
import { AppError } from "@haywork/modules/error";
import LiveData, {
  EmailEventHub,
  EventCenterEventHub,
  KolibriEventHub,
  VoipEventHub,
} from "@haywork/modules/live-data";
import { NotSupportedView } from "@haywork/modules/not-supported";
import { OidcService } from "@haywork/services";
import { AccessActions, AppSettingsState, AppState } from "@haywork/stores";
import * as bowser from "bowser";
import { push } from "connected-react-router";
import * as React from "react";
import { connect, MapStateToProps } from "react-redux";
import SmartBanner from "react-smartbanner";
import AppTitle from "@haywork/components/app-title";
import LoadScreen from "@haywork/modules/layout/components/load-screen";

interface Props {
  appStatus: string;
  settings: AppSettingsState;
  token: string;
  features: string[];
  getInitialValues: () => void;
  goToDashboard: () => void;
  updateToken: (token: string) => void;
  getApplicationValues: () => void;
}

class MainComponent extends React.PureComponent<Props> {
  private oidc: OidcService = new OidcService();
  private browserNotSupported = false;

  public componentDidMount() {
    this.props.getInitialValues();
    this.oidc.onUserLoaded((user) => this.props.updateToken(user.access_token));

    const browser = bowser.getParser(window.navigator.userAgent);
    this.browserNotSupported = browser.satisfies({
      "internet explorer": "<=11",
      safari: "<10.1",
      chrome: "<57",
      firefox: "<52",
      opera: "<44",
      mobile: {
        safari: "<10.3",
      },
    });
  }

  public render() {
    if (!!this.browserNotSupported) {
      return <NotSupportedView />;
    }

    if (this.props.appStatus === REQUEST.ERROR) {
      return <AppError />;
    }

    if (this.props.appStatus !== REQUEST.SUCCESS) {
      return <LoadScreen />;
    }

    return (
      <LiveData>
        <AppTitle />
        <SmartBanner title="Kolibri24" position="top" />
        <EmailEventHub />
        <VoipEventHub />
        <KolibriEventHub />
        <EventCenterEventHub>{this.props.children}</EventCenterEventHub>
      </LiveData>
    );
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.appStatus === this.props.appStatus) return;

    switch (this.props.appStatus) {
      case REQUEST.SUCCESS: {
        this.props.getApplicationValues();
        if (location.pathname !== BASEROUTES.ROOT) return;
        return this.props.goToDashboard();
      }
      default:
        return;
    }
  }
}

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => {
  return {
    appStatus: state.main.appStatus,
    settings: state.appSettings,
    token: state.access.token,
    features: state.appSettings.features,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getInitialValues: () => dispatch(MainThunks.getInitialValues()),
  goToDashboard: () => dispatch(push(MAINROUTES.DASHBOARD.URI)),
  updateToken: (token: string) => dispatch(AccessActions.setToken({ token })),
  getApplicationValues: () => dispatch(MainThunks.getApplicationValues()),
});

export const MainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);
