import {
  FinancialAdministration,
  FinancialAdministrationGroup,
  FinancialAdministrationProduct,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { FormReference } from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import {
  AddFinancialAdministrationProductModalContainerProps,
  ButtonLoader,
} from "@haywork/modules/shared";
import last from "lodash-es/last";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ProductFormComponent } from "./product-form.component";
import { ProductGroupFormComponent } from "./product-group-form.component";

const styles = require("./add-financial-product.component.scss");

export interface AddFinancialAdministrationProductModalComponentProps {
  visible: boolean;
  onClose: () => void;
  onProductAdded: (product: FinancialAdministrationProduct) => void;
}
interface AddFinancialAdministrationProductModalComponentState {
  showProductGroupForm: boolean;
  productGroupName: string;
  productRef: FormReference;
  productGroupRef: FormReference;
  administrationId: string;
}

@CSSModules(styles, { allowMultiple: true })
export class AddFinancialAdministrationProductModalComponent extends React.Component<
  AddFinancialAdministrationProductModalComponentProps &
    AddFinancialAdministrationProductModalContainerProps,
  AddFinancialAdministrationProductModalComponentState
> {
  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onAddProductGroupHandler = this.onAddProductGroupHandler.bind(this);
    this.bindProductRef = this.bindProductRef.bind(this);
    this.onProductGroupCloseHandler = this.onProductGroupCloseHandler.bind(
      this
    );
    this.bindProductGroupRef = this.bindProductGroupRef.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.state = {
      showProductGroupForm: false,
      productGroupName: "",
      productRef: null,
      productGroupRef: null,
      administrationId: null,
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AddFinancialAdministrationProductModalComponentProps &
      AddFinancialAdministrationProductModalContainerProps
  ) {
    if (!nextProps) return;

    const {
      financialAdministrationState: nextState,
      financialAdministrations,
    } = nextProps;
    const { financialAdministrationState: prevState } = this.props;
    const { administrationId } = this.state;

    if (
      nextState === REQUEST.IDLE &&
      prevState === REQUEST.PENDING &&
      !!administrationId
    ) {
      const administration = financialAdministrations.find(
        (a) => a.id === administrationId
      );
      if (!!administration) {
        const product = last(administration.products);
        this.props.onProductAdded(product);
        this.clearFormData();
        this.setState({ administrationId: null });
      }
    }
  }

  public render() {
    return (
      <Modal
        visible={this.props.visible}
        onClose={this.onCloseHandler}
        data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Modal`}
      >
        <ModalHeader
          title="addNewProduct"
          close
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.ModalHeader`
          }
        />
        <ModalBody>
          <ProductFormComponent
            showProductGroupForm={this.state.showProductGroupForm}
            financialAdministrationState={
              this.props.financialAdministrationState
            }
            financialAdministrations={this.props.financialAdministrations}
            onAddProductGroup={this.onAddProductGroupHandler}
            onFormRef={this.bindProductRef}
            data-cy={
              this.props["data-cy"] && `${this.props["data-cy"]}.Product`
            }
          />
          {this.state.showProductGroupForm && (
            <ProductGroupFormComponent
              productGroupName={this.state.productGroupName}
              financialAdministrationState={
                this.props.financialAdministrationState
              }
              onClose={this.onProductGroupCloseHandler}
              onFormRef={this.bindProductGroupRef}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.ProductGroup`
              }
            />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            disabled={
              this.props.financialAdministrationState === REQUEST.PENDING
            }
            onClick={this.onSubmitHandler}
            data-cy={
              this.props["data-cy"] && `${this.props["data-cy"]}.SaveButton`
            }
          >
            <ButtonLoader
              resourceKey="save"
              loading={
                this.props.financialAdministrationState === REQUEST.PENDING
              }
            />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private onAddProductGroupHandler(productGroupName: string) {
    this.setState({
      productGroupName,
      showProductGroupForm: true,
    });
  }

  private onProductGroupCloseHandler() {
    this.setState({
      showProductGroupForm: false,
      productGroupRef: null,
    });
  }

  private bindProductRef(productRef: FormReference) {
    this.setState({ productRef });
  }

  private bindProductGroupRef(productGroupRef: FormReference) {
    this.setState({ productGroupRef });
  }

  private onSubmitHandler() {
    const { productRef, productGroupRef } = this.state;

    if (!productRef) return;

    let product: FinancialAdministrationProduct,
      financialAdministration: FinancialAdministration;

    // Check validity
    const productValid = productRef.submit();
    const productGroupValid = !!productGroupRef
      ? productGroupRef.submit()
      : true;
    if (!productValid || !productGroupValid) return;

    if (!!productRef) {
      const values = productRef.getValues();

      financialAdministration = this.props.financialAdministrations.find(
        (a) => a.id === values.financialAdministration
      );

      product = {
        id: null,
        isActive: true,
        isMarketingProduct: values.isMarketingProduct,
        unitPriceNet: parseFloat(values.unitPriceNet),
        description: values.description,
        descriptionShort: values.descriptionShort,
        groupId: values.groupId,
        exportLedgerAccountCode: values.exportLedgerAccountCode,
        taxRateId: values.taxRateId,
      };
    }

    const products = financialAdministration.products || [];
    let groups = financialAdministration.groups || [];

    if (!!productGroupRef) {
      const values = productGroupRef.getValues();

      const productGroup: FinancialAdministrationGroup = {
        budgetedAmount: parseFloat(values.budgetedAmount),
        budgetedNumberOfProducts: parseInt(values.budgetedNumberOfProducts),
        id: -1,
        name: values.name,
      };

      product = {
        ...product,
        groupId: -1,
      };

      groups = [...groups, productGroup];
    }

    financialAdministration = {
      ...financialAdministration,
      products: [...products, product],
      groups,
    };

    this.setState({ administrationId: financialAdministration.id });
    this.props.updateFinancialAdministration(financialAdministration);
  }

  private onCloseHandler() {
    this.clearFormData();
    this.props.onClose();
  }

  private clearFormData() {
    const { productRef, productGroupRef } = this.state;

    if (!!productRef) {
      productRef.update({
        descriptionShort: "",
        description: "",
        unitPriceNet: "",
        groupId: "",
      });
    }
    if (!!productGroupRef) {
      productGroupRef.update({
        name: "",
        budgetedAmount: "",
        budgetedNumberOfProducts: "",
      });
    }

    this.setState({
      showProductGroupForm: false,
      productGroupName: "",
    });
  }
}
