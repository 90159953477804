import { FolderTreeEntityType } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { OfficeDetailDossierContainerProps } from "./detail-dossier.container";

const styles = require("./style.scss");

export interface OfficeDetailDossierComponentProps {}
interface State {}
type Props = OfficeDetailDossierComponentProps &
  OfficeDetailDossierContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class OfficeDetailDossierComponent extends React.Component<
  Props,
  State
> {
  public render() {
    return (
      <ErrorBoundary>
        <Dossier
          parentId={this.props.officeId}
          id={this.props.linkedFolderTreeId}
          subject={this.props.name}
          entityType={FolderTreeEntityType.Office}
          addNotes={false}
        />
      </ErrorBoundary>
    );
  }
}
