import PageHeader from "@haywork/components/ui/page-header";
import { AppXchangeSupportContainerProps } from "@haywork/modules/app-xchange";
import {
  MarkDown,
  ResourceText,
  StepComponent,
  StepperComponent,
  StepperType,
} from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ToggleConsent } from "../toggle-consent";

const styles = require("./support.component.scss");

export type AppXchangeSupportComponentProps = {};
type Props = AppXchangeSupportComponentProps & AppXchangeSupportContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeSupportComponent extends React.Component<Props> {
  public render() {
    const { emailAddressSupport, phoneNumber } = this.props.client;
    const { website, faqs, supportDescription } = this.props.translation;

    return (
      <div styleName="support">
        <PageHeader
          title="appXchangeSupportTitle"
          actions={<ToggleConsent />}
        />

        <div styleName="support__body">
          <div styleName="support__content">
            <div styleName="scrollable">
              <MarkDown
                markdown={supportDescription}
                onImageClick={this.props.showLightbox}
              />

              {!!faqs && faqs.length && (
                <div styleName="faq">
                  <h2>
                    <ResourceText resourceKey="appXchangeSupportFaq" />
                  </h2>
                  <StepperComponent type={StepperType.Accordion}>
                    {this.props.translation.faqs.map((faq) => (
                      <StepComponent title={faq.question} key={faq.index}>
                        <MarkDown markdown={faq.answer} />
                      </StepComponent>
                    ))}
                  </StepperComponent>
                </div>
              )}
            </div>
          </div>

          <div styleName="support__contact">
            <div styleName="scrollable">
              <h2>
                <ResourceText resourceKey="appXchangeSupportContact" />
              </h2>
              {!!emailAddressSupport && (
                <div styleName="contact__link">
                  <i className="fal fa-envelope" />
                  <a href={`mailto:${emailAddressSupport}`} target="_blank">
                    {emailAddressSupport}
                  </a>
                </div>
              )}
              {!!phoneNumber && (
                <div styleName="contact__link">
                  <i className="fal fa-phone" />
                  <a href={`tel:${phoneNumber}`} target="_blank">
                    {phoneNumber}
                  </a>
                </div>
              )}
              {!!website && (
                <div styleName="contact__link">
                  <i className="fal fa-globe" />
                  <a href={website} target="_blank">
                    {website}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
