import { AppClient, ReviewSnapShot } from "@haywork/api/authorization";
import { Employee } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators
} from "@haywork/modules/form";
import { ButtonLoader } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  employee: Employee;
  client: AppClient;
  review: ReviewSnapShot;
  submitting: boolean;
  onFormCloseHandler: () => void;
  onSubmitReview: (values: FormReturnValue) => void;
};

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeReviewFormComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      id: { value: get(this.props.review, "id", "") },
      rate: { value: get(this.props.review, "rate", 1) },
      reviewText: {
        value: get(this.props.review, "reviewText", ""),
        validators: [Validators.required()]
      },
      linkedEmployee: { value: { id: this.props.employee.id } },
      linkedAppClient: { value: { id: this.props.client.id } }
    };
  }

  public render() {
    const avatar = this.props.employee.passportPhotoBlob
      ? {
          backgroundImage: `url(${JSON.stringify(
            this.props.employee.passportPhotoBlob.urlPreview
          )})`
        }
      : null;

    return (
      <div styleName="review__form">
        <div styleName="review__avatar" style={avatar}>
          {!avatar && (
            <div styleName="letter">{this.props.employee.nameLetters}</div>
          )}
        </div>

        <div styleName="form">
          <div styleName="form__close" onClick={this.props.onFormCloseHandler}>
            <i className="fal fa-times" />
          </div>
          <Form
            name="review"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.props.onSubmitReview}
          >
            <div styleName="form__row">
              <Input.Review name="rate" disabled={this.props.submitting} />
            </div>
            <div styleName="form__row">
              <Input.Textarea
                name="reviewText"
                minHeight={128}
                maxCount={1024}
                placeholder="appXchangeReviewDescriptionPlaceholder"
                disabled={this.props.submitting}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              <ButtonLoader
                resourceKey="appXchangeSubmitReview"
                loading={this.props.submitting}
              />
            </button>
          </Form>
        </div>
      </div>
    );
  }
}
