export const SUPPORTURI = {
  MEDIAPARTNERS:
    "https://support.kolibri.software/portal/kb/articles/fw-probleem-media-partners-8-2-2019",
  UNDERCONDITION:
    "https://support.kolibri.software/portal/kb/articles/voorbehoud-intrekken-of-wijzigen",
  ADDRESSNOTFOUND:
    "https://support.kolibri.software/portal/kb/articles/adres-kan-niet-worden-gevonden",
  HIDEHOUSENUMBER:
    "https://support.kolibri.software/portal/kb/articles/huisnummer-verbergen-op-funda-en-pararius",
  YISUAL:
    "https://support.kolibri.software/portal/kb/articles/opdrachten-hoe-voeg-ik-een-presentatie-of-brochure-toe",
  TESTIMONIAL:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-plaats-ik-een-testimonial-op-mijn-website",
  VIDEOAND360:
    "https://support.kolibri.software/portal/kb/articles/opdrachten-hoe-voeg-ik-video-s-en-360-foto-s-toe",
  DOSSIERDOCUMENTS:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-voeg-ik-overeenkomsten-toe-aan-het-dossier",
  DOSSIERADDFILE:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-voeg-ik-een-bestand-toe-aan-het-dossier",
  KEYNUMBER:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-voeg-ik-een-sleutelnummer-toe",
  ADDRESSDRAGMARKER:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-verplaats-ik-de-marker-in-het-kaartje-naar-de-juiste-positie",
  CADASTREFILES:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-voeg-ik-kadastrale-kaarten-en-berichten-toe",
  CONFIDENTIALASSIGNMENT:
    "https://support.kolibri.software/portal/kb/articles/opdracht-hoe-zorg-ik-er-voor-dat-een-opdracht-niet-op-internet-komt",
  MANAGEAGENDACATEGORIES:
    "https://support.kolibri.software/portal/kb/articles/agenda-hoe-beheer-ik-mijn-agenda-categorie%C3%ABn",
  MATCHMAIL:
    "https://support.kolibri.software/portal/kb/articles/matchmail-hoe-zorg-ik-ervoor-dat-zoekers-passend-aanbod-ontvangen",
  COMMISSIONPIPELINE:
    "https://support.kolibri.software/portal/kb/articles/dashboard-hoe-wordt-mijn-courtage-pijplijn-berekend",
  SUBLOCALITY:
    "https://support.kolibri.software/portal/kb/articles/re-re-14538-bij-het-invoeren-van-een-verkoopwoning-zien-wij-dat-er-wat-wijken-missen-kunnen-wij-zelf-wijken-toevoegen-lewenborg-bijvoorbeeld-mist-in-rijtje-wijken",
};
