import {
  EventNotification,
  EventType,
  LinkedContactPerson,
  LinkedObjectAssignment,
} from "@haywork/api/event-center";
import {
  AgendaItemCategorySnapShot,
  TaskCategoryOption,
} from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import LinkedAssignmentComponent from "@haywork/modules/shared/components/timeline/components/linked-assignment";
import LinkedRelationComponent from "@haywork/modules/shared/components/timeline/components/linked-relation";
import { ColorUtil, DateUtil } from "@haywork/util";
import { DiffStatus } from "@haywork/util/date";
import classNames from "classnames";
import first from "lodash-es/first";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedTime } from "react-intl";

const styles = require("./reminder-modal-list-item.component.scss");

interface Props {
  zebra: boolean;
  reminder: EventNotification;
  isSelected: boolean;
  culture: string;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
  onSelect: (selectedItem: EventNotification) => void;
  onSubItemClick: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class ReminderModalListItemComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.onItemClicked = this.onItemClicked.bind(this);
  }

  public render() {
    const listItemStyle = classNames("item", {
      zebra: this.props.zebra,
      selected: this.props.isSelected,
    });
    const { linkedEvent } = this.props.reminder;
    const {
      eventType,
      linkedContactPersons,
      linkedObjectAssignments,
      eventDateTime,
    } = linkedEvent;

    let subject, categoryColor, categoryDisplayName, startDate, endDate;

    switch (eventType) {
      case EventType.AgendaItemReminder: {
        const reminders = linkedEvent.linkedAgendaItems || [];
        const reminder = first(reminders);
        const categories = linkedEvent.linkedAgendaItemCategories || [];
        const linkedAgendaItemCategory = first(categories);

        if (!reminder) return null;
        const { displayName, starDateTime, endDateTime } = reminder;
        const category = this.props.agendaItemCategories.find(
          (category) => category.id === get(linkedAgendaItemCategory, "id")
        );

        if (!!category) {
          categoryColor = category.backColor;
          categoryDisplayName = category.displayName;
        }

        subject = displayName;
        startDate = starDateTime;
        endDate = endDateTime;

        break;
      }
      case EventType.TaskReminder: {
        const reminders = linkedEvent.linkedTasks || [];
        const reminder = first(reminders);
        if (!reminder) return null;
        const { displayName, categoryId } = reminder;
        const category = this.props.taskCategories.find(
          (category) => categoryId === get(category, "value")
        );

        if (!!category) {
          categoryColor = category.categoryBackColor;
          categoryDisplayName = category.displayName;
        }

        subject = displayName;
        break;
      }
      default:
        return null;
    }

    return (
      <div styleName={listItemStyle} onClick={this.onItemClicked}>
        {this.renderReminderIcon(categoryColor, eventType)}
        <div styleName="meta">
          <div styleName="extra">
            {this.renderDateOrTime(
              eventType,
              eventDateTime,
              startDate,
              endDate
            )}
          </div>
          <div styleName="meta__inner">
            <div styleName="subject">{subject}</div>
            <div>
              {!!categoryColor && !!categoryDisplayName && (
                <span styleName="item__category">
                  <span
                    styleName="color"
                    style={{
                      backgroundColor: categoryColor
                        ? ColorUtil.hexToRgb(categoryColor)
                        : "",
                    }}
                  />
                  {categoryDisplayName}
                </span>
              )}
              {this.renderLinkedRelations(linkedContactPersons)}
              {this.renderLinkedAssignments(linkedObjectAssignments)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderLinkedRelations(relations: LinkedContactPerson[]) {
    return relations.map((relation, idx) => (
      <ErrorBoundary key={idx}>
        <LinkedRelationComponent
          linkedRelation={relation}
          onClick={this.props.onSubItemClick}
        />
      </ErrorBoundary>
    ));
  }

  private renderLinkedAssignments(assignments: LinkedObjectAssignment[]) {
    return assignments.map((assignment, idx) => (
      <ErrorBoundary key={idx}>
        <LinkedAssignmentComponent
          linkedAssignment={assignment}
          onClick={this.props.onSubItemClick}
        />
      </ErrorBoundary>
    ));
  }

  private renderReminderIcon(
    color: string,
    type: EventType
  ): React.ReactElement<HTMLDivElement> {
    const borderStyle = {
      borderColor: color ? ColorUtil.hexToRgb(color) : "",
    };
    const iconStyle = classNames(
      "fal",
      "fa-fw",
      this.props.isSelected ? "fa-check" : "fa-square"
    );

    return (
      <div styleName="item__icon" style={borderStyle}>
        <i className={iconStyle} />
      </div>
    );
  }

  private renderDateOrTime(
    type: EventType,
    date: Date,
    startDate: Date,
    endDate: Date
  ) {
    if (type === EventType.AgendaItemReminder) {
      const diffInDays = DateUtil.diffInDays(date, new Date());

      if (diffInDays.status === DiffStatus.Today) {
        return (
          <div>
            <span styleName="startTime">
              <FormattedTime value={startDate} />
            </span>
            <span styleName="endTime">
              <FormattedTime value={endDate} />
            </span>
          </div>
        );
      }
    }

    return (
      <span styleName="date">
        {DateUtil.getTimeAgoText(date, this.props.culture)}
      </span>
    );
  }

  private onItemClicked() {
    this.props.onSelect(this.props.reminder);
  }
}
