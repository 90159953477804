import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import classNames from "classnames";

import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryTypeOption,
  AgendaStandardDurationOption,
  AgendaStandardDuration,
  AgendaItemCategoryType,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  Input,
  FormReturnValue,
  FormReference,
} from "@haywork/modules/form";
import { ResourceText, ButtonLoader } from "@haywork/modules/shared";
import { ColorUtil } from "@haywork/util";

const styles = require("./form.component.scss");

interface Props {
  category?: AgendaItemCategorySnapShot;
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  onChange?: (category: AgendaItemCategorySnapShot) => void;
  onSubmit?: (
    backColor: string,
    displayName: string,
    standardDuration: AgendaStandardDuration,
    needsToBeConfirmed: boolean,
    categoryType: AgendaItemCategoryType
  ) => Promise<void>;
}
interface State {
  loading: boolean;
  validForSave: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerFormComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  private formName: string;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validForSave: false,
    };

    this.formName = !!this.props.category
      ? `settingsScheduler.form_${this.props.category.id}`
      : "settingsScheduler.form";

    this.formControls = {
      backColor: {
        value: ColorUtil.hexToRgb(
          get(this.props.category, "backColor", "#808080")
        ),
      },
      displayName: {
        value: get(this.props.category, "displayName", ""),
        onChange: (ref) => {
          const validForSave = !!ref.value && !!ref.value.trim();
          if (this.state.validForSave !== validForSave) {
            this.setState({ validForSave });
          }
        },
      },
      categoryType: {
        value: get(
          this.props.category,
          "categoryType",
          AgendaItemCategoryType.Other
        ),
      },
      standardDurationInMinutes: {
        value: get(this.props.category, "standardDurationInMinutes", 30),
      },
      needsToBeConfirmed: {
        value: get(this.props.category, "needsToBeConfirmed", false),
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  public render() {
    return (
      <Form
        name={this.formName}
        formControls={this.formControls}
        form={(form) => (this.form = form)}
        onChange={this.onChangeHandler}
        onSubmit={this.onSubmitHandler}
        asSubForm={!!this.props.category}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column">
              {!this.props.category && (
                <label htmlFor="backColor">
                  <ResourceText resourceKey="settingsSchedulerLabel.backColor" />
                </label>
              )}
              <Input.Color name="backColor" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="displayName">
              {!this.props.category && (
                <label htmlFor="displayName">
                  <ResourceText resourceKey="settingsSchedulerLabel.displayName" />
                </label>
              )}
              <Input.Text
                name="displayName"
                placeholder="settingsSchedulerPlaceholder.displayName"
                data-cy="CY-categoryDisplayName"
                disabled={this.state.loading}
                fireAllChanges={!this.props.category}
              />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="categoryType">
              {!this.props.category && (
                <label htmlFor="categoryType">
                  <ResourceText resourceKey="settingsSchedulerLabel.categoryType" />
                </label>
              )}
              <Input.NewSelect
                name="categoryType"
                values={this.props.agendaItemCategoryTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="standardDurationInMinutes">
              {!this.props.category && (
                <label htmlFor="standardDurationInMinutes">
                  <ResourceText resourceKey="settingsSchedulerLabel.standardDurationInMinutes" />
                </label>
              )}
              <Input.NewSelect
                name="standardDurationInMinutes"
                values={this.props.agendaStandardDurationOptions}
                displayProp="displayName"
                valuesProp="durationInMinutes"
              />
            </div>
            <div className="column__spacer" />
            <div
              className={classNames("column", {
                "push-label": !this.props.category,
              })}
            >
              <Input.Switch
                name="needsToBeConfirmed"
                on={true}
                off={false}
                label="settingsSchedulerLabel.needsToBeConfirmed"
                data-cy="CY-needsToBeConfirmed"
                disabled={this.state.loading}
              />
            </div>
          </div>
        </div>

        {!this.props.category && (
          <div className="form__row">
            <button
              type="submit"
              className="btn btn-success"
              disabled={!this.state.validForSave}
              data-cy="CY-save"
            >
              <ButtonLoader
                resourceKey="settingsSchedulerSave"
                loading={this.state.loading}
              />
            </button>
          </div>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    if (!this.props.onChange) return;
    const category: AgendaItemCategorySnapShot = {
      ...this.props.category,
      ...values,
    };

    this.props.onChange(category);
  }

  private async onSubmitHandler(values: FormReturnValue) {
    if (!this.props.onSubmit || !!this.state.loading) return;
    this.setState({ loading: true });
    const duration = this.props.agendaStandardDurationOptions.find(
      (duration) =>
        duration.durationInMinutes === values.standardDurationInMinutes
    );

    try {
      await this.props.onSubmit(
        ColorUtil.rgbToHex(values.backColor),
        values.displayName,
        duration.value,
        values.needsToBeConfirmed,
        values.categoryType
      );

      this.form.update({
        backColor: ColorUtil.hexToRgb("#808080"),
        displayName: "",
        standardDurationInMinutes: 30,
        needsToBeConfirmed: false,
        categoryType: AgendaItemCategoryType.Other,
      });
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }
}
