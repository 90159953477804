import { Dispatch } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { AssignmentInfoComponent } from "./assignment-info";

interface StateProps {}
interface DispatchProps {
  navigate: (route: string) => void;
}

const mapStateToProps = <StateProps, AssignmentInfoComponentProps>(
  state: AppState
) => ({});

const mapDispatchToProps = <DispatchProps, AssignmentInfoComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (route: string) => dispatch(push(route)),
});

export type AssignmentInfoContainerProps = StateProps & DispatchProps;
export const AssignmentInfoContainer = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentInfoComponent);
