import { createSelector } from "reselect";
import get from "lodash-es/get";

import { AppState } from "@haywork/stores";
import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";

const categoriesSelector = (state: AppState) =>
  state.emailV2.templates.categories;
const emailTemplatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;

export const emailTemplates = createSelector(
  emailTemplatesSelector,
  categoriesSelector,
  (templates, categories) => {
    const emailTemplateCategoryIds = categories
      .filter(
        (category) =>
          category.categoryType === TemplateDefinitionCategoryType.EmailTemplate
      )
      .map((category) => category.id);

    return templates.filter((template) => {
      return (
        emailTemplateCategoryIds.indexOf(
          get(template, "linkedTemplateDefinitionCategory.id")
        ) !== -1
      );
    });
  }
);
