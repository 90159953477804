import { Employee } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  EmployeeDossierComponent,
  EmployeeDossierComponentProps
} from "@haywork/modules/settings/modules/employees/components/dossier";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  selectedEmployee?: Employee;
  linkedFolderTreeId: string | null | undefined;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => {
  const selectedEmployee: Employee = state.editable.currentComponentState;

  return {
    selectedEmployee,
    linkedFolderTreeId: get(selectedEmployee, "linkedFolderTree.id", "")
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});

export type EmployeeDossierContainerProps = StateProps & DispatchProps;
export const EmployeeDossierContainer = connect<
  StateProps,
  DispatchProps,
  EmployeeDossierComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDossierComponent);
