const IMAGE = [
  "image/bmp",
  "image/png",
  "image/gif",
  "image/jpeg",
  "image/pjpeg"
];

export const MIME = {
  IMAGE
};
