import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./modal-header.component.scss");

interface ModalHeaderComponentProps {
  title: string;
  titleValues?: { [key: string]: any };
  close?: boolean;
}
interface ModalHeaderComponentState {
  close?: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ModalHeader extends React.Component<
  ModalHeaderComponentProps,
  ModalHeaderComponentState
> {
  public static contextTypes = {
    close: PropTypes.func.isRequired
  };

  constructor(props: ModalHeaderComponentProps) {
    super(props);
    this.state = { close: this.props.close || false };
    this.close = this.close.bind(this);
  }

  public render() {
    return (
      <div styleName="header">
        <div styleName="title">
          <ResourceText
            resourceKey={this.props.title}
            values={this.props.titleValues}
          />
        </div>
        {this.state.close && (
          <div
            styleName="close"
            data-cy={"CY-CloseModalButton"}
            onClick={this.close}
          >
            <i className="fal fa-fw fa-times" />
          </div>
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextState: ModalHeaderComponentProps) {
    this.setState(nextState);
  }

  public close() {
    if (this.context.close) this.context.close();
  }
}
