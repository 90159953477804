import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { Call } from "@haywork/stores/voip/calls";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NewEntity, NewEntityOptions } from "../new-entity";
import SearchRelationModal from "./components/search-relation";
import Widget from "./components/widget";
import { VoipModuleContainerProps } from "./voip.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface VoipModuleProps {}
interface State {
  callToHandle: Call;
  searchRelationVisible: boolean;
  createRelationVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = VoipModuleProps & VoipModuleContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class VoipModule extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      callToHandle: null,
      searchRelationVisible: false,
      createRelationVisible: false,
      newEntityOptions: null
    };

    this.onRemoveCall = this.onRemoveCall.bind(this);
    this.onCreateNewRelation = this.onCreateNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.onSearchRelationModalClose = this.onSearchRelationModalClose.bind(
      this
    );
    this.onSearchRelationModalRelationSelect = this.onSearchRelationModalRelationSelect.bind(
      this
    );
    this.onViewRelation = this.onViewRelation.bind(this);
    this.onSelectRelation = this.onSelectRelation.bind(this);
  }

  public render() {
    return (
      <div styleName="voip">
        {this.props.calls.map((call) => (
          <Widget
            call={call}
            employeeName={this.props.employeeName}
            onToggleCollapsed={this.props.toggleCollapsed}
            onRemove={this.onRemoveCall}
            onSearchRelation={this.props.searchRelation}
            onSearchEmployee={this.props.searchEmployee}
            onBindExistingRelation={(callToHandle) =>
              this.setState({ callToHandle, searchRelationVisible: true })
            }
            onCreateNewRelation={this.onCreateNewRelation}
            onCreateAppointment={this.props.createAgendaItemForRelation}
            onViewRelation={this.onViewRelation}
            onSaveCommunicationLog={this.props.addCommunicationLog}
            onUpdateCommunicationLog={this.props.updateCommunicationLog}
            onSelectRelation={this.onSelectRelation}
            key={call.payload.conversationId}
          />
        ))}

        <NewEntity
          visible={this.state.createRelationVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <SearchRelationModal
          visible={this.state.searchRelationVisible}
          phoneNumber={get(this.state.callToHandle, "payload.phoneNumber")}
          onClose={this.onSearchRelationModalClose}
          onRelationSelect={this.onSearchRelationModalRelationSelect}
        />
      </div>
    );
  }

  private onCreateNewRelation(callToHandle: Call) {
    this.setState({
      callToHandle,
      createRelationVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: { telephone: callToHandle.payload.phoneNumber }
      }
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      createRelationVisible: false,
      callToHandle: null,
      newEntityOptions: null
    });
  }

  private onNewRelationHandler(linkedRelation: RelationSnapShot) {
    const { conversationId } = this.state.callToHandle.payload;
    this.props.setLinkedRelations([linkedRelation], conversationId);
    this.setState({
      createRelationVisible: false,
      callToHandle: null,
      newEntityOptions: null
    });
  }

  private onSelectRelation(
    linkedRelation: RelationSnapShot,
    callToHandle: Call
  ) {
    const { conversationId } = callToHandle.payload;
    this.setState({ callToHandle });
    this.props.setLinkedRelations([linkedRelation], conversationId);
  }

  private onSearchRelationModalClose() {
    this.setState({ callToHandle: null, searchRelationVisible: false });
  }

  private onSearchRelationModalRelationSelect(
    linkedRelation: RelationSnapShot
  ) {
    const { conversationId, phoneNumber } = this.state.callToHandle.payload;
    this.props.setLinkedRelations([linkedRelation], conversationId);
    this.props.updateRelation(
      linkedRelation.id,
      linkedRelation.typeOfRelation,
      phoneNumber
    );
    this.setState({
      searchRelationVisible: false,
      callToHandle: null
    });
  }

  private onViewRelation(relation: RelationSnapShot) {
    const { typeOfRelation, id } = relation;
    switch (typeOfRelation) {
      case RelationType.ContactPerson: {
        const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        return this.props.navigate(path);
      }
      case RelationType.ContactCompany: {
        const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        return this.props.navigate(path);
      }
      default:
        return;
    }
  }

  private onRemoveCall(call: Call) {
    this.props.removeCall(call);
  }
}
