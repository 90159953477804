import { MailHistorySnapShot, SearchAssignment } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentDetailGeneralComponent,
  SearchAssignmentDetailGeneralComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignment: SearchAssignment;
  matchMails: MailHistorySnapShot[];
  hasMatchMail: boolean;
}
interface DispatchProps {
  navigate: (url: string) => void;
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) => void;
  getWidgetSearchAssignmentMatchMails: (searchAssignmentId: string) => void;
}

const mapStateToProps = <
  StateProps,
  SearchAssignmentDetailGeneralComponentProps
>(
  state: AppState
) => {
  const { searchAssignment, matchMails } = state.searchAssignment.single;
  const { hasMatchMail } = state.company?.settings?.matchMailSettings;

  return {
    searchAssignment,
    matchMails,
    hasMatchMail,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SearchAssignmentDetailGeneralComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) =>
    dispatch(
      SearchAssignmentThunks.getMatchMailProperties(
        objectAssignmentIds,
        searchAssignmentId
      )
    ),
  getWidgetSearchAssignmentMatchMails: (searchAssignmentId: string) =>
    dispatch(
      SearchAssignmentThunks.getSearchAssignmentMatchMails(
        searchAssignmentId,
        true
      )
    ),
});

export type SearchAssignmentDetailGeneralContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentDetailGeneralContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentDetailGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentDetailGeneralComponent);
