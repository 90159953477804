import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { AccountComponent, AccountComponentProps } from "./account";

type StateProps = {};
type DispatchProps = {
  removeFolder: (id: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AccountComponentProps,
  AppState
> = (state, ownProps) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AccountComponentProps
> = (dispatch) => ({
  removeFolder: (id: string) => dispatch(EmailThunk.Folders.removeFolder(id))
});

export type AccountContainerProps = StateProps & DispatchProps;
export const AccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountComponent);
