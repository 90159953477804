import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { Column, Row } from "@haywork/components/ui/list";
import Pill from "@haywork/components/ui/pill";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil, ColorUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import LoadingState from "../loading-state";
import Icon from "@haywork/components/ui/icon";
import Actions from "../row-actions";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  data: RelationSnapShot | null;
  zebra: boolean;
  onNavigatePath: (path: string) => void;
  onEmail: (email: string) => void;
};

export const RowComponent: FC<Props> = memo(
  ({ data, zebra, onNavigatePath, onEmail }) => {
    return !data ? (
      <LoadingState zebra={zebra} />
    ) : (
      <DataRowComponent
        data={data}
        zebra={zebra}
        onNavigatePath={onNavigatePath}
        onEmail={onEmail}
      />
    );
  }
);

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ data, zebra, onNavigatePath, onEmail }) => {
      const {
        id,
        typeOfRelation,
        email,
        secondEmailAddress,
        thirdEmailAddress,
        phoneNumber,
        mobileNumber,
        locality,
        streetNameAndNumber,
        displayName,
        avatarUrl,
        letterAvatar,
        isActive
      } = data;

      const phones = useMemo(() => {
        return [phoneNumber, mobileNumber].filter((phone) => !!phone);
      }, [phoneNumber, mobileNumber]);

      const emails = useMemo(() => {
        return [email, secondEmailAddress, thirdEmailAddress].filter(
          (email) => !!email
        );
      }, [email, secondEmailAddress, thirdEmailAddress]);

      const avatar = useMemo(() => {
        const styles: CSSProperties = {
          backgroundImage: !avatarUrl
            ? null
            : `url(${JSON.stringify(avatarUrl)})`,
          backgroundColor: !!avatarUrl
            ? null
            : typeOfRelation === RelationType.ContactCompany
            ? Colors.Secondary
            : Colors.Primary
        };

        return (
          <div styleName="avatar" style={styles}>
            {!avatarUrl && !letterAvatar && (
              <Icon
                name={
                  typeOfRelation === RelationType.ContactCompany
                    ? "building"
                    : "user"
                }
                size={24}
                light
              />
            )}
            {!avatarUrl && letterAvatar && (
              <div styleName="letter-avatar">{letterAvatar}</div>
            )}
          </div>
        );
      }, [avatarUrl, letterAvatar, typeOfRelation]);

      const onRelationClickHandler = useCallback(() => {
        let path: string;

        switch (typeOfRelation) {
          case RelationType.ContactCompany: {
            path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
            break;
          }
          case RelationType.Employee: {
            path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
            break;
          }
          case RelationType.Office: {
            path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
            break;
          }
          default: {
            path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
            break;
          }
        }

        onNavigatePath(path);
      }, [onNavigatePath, id, typeOfRelation]);

      return (
        <Row
          zebra={zebra}
          onClick={onRelationClickHandler}
          className={!isActive ? "archived" : ""}
        >
          <Column index={0}>
            <div styleName="column">{avatar}</div>
          </Column>
          <Column index={1}>
            <div styleName="column">
              {displayName && <span styleName="name">{displayName}</span>}
              <div>
                <Pill
                  label={typeOfRelation.toString()}
                  labelPrefix="relationTypes"
                  color={
                    !isActive
                      ? Colors.Gray
                      : typeOfRelation === RelationType.ContactCompany
                      ? Colors.Secondary
                      : Colors.Primary
                  }
                  tiny
                />
              </div>
            </div>
          </Column>
          <Column index={2}>
            <div styleName="column">
              {emails.map((email, idx) => (
                <Pill
                  label={email}
                  key={idx}
                  icon="envelope"
                  onClick={() => onEmail(email)}
                />
              ))}
            </div>
          </Column>
          <Column index={3}>
            <div styleName="column">
              {phones.map((phone, idx) => (
                <Pill label={phone} key={idx} icon="phone" />
              ))}
            </div>
          </Column>
          <Column index={4}>
            <div styleName="column">
              {streetNameAndNumber && <span>{streetNameAndNumber}</span>}
              {locality && <span styleName="emphasize">{locality}</span>}
            </div>
          </Column>
          <Actions item={data} />
        </Row>
      );
    }
  )
);
