import {
  RelationSnapShot,
  TemplateDefinitionSnapShot,
} from "@haywork/api/kolibri";
import { Account, AccountShare, Folder, ShareType } from "@haywork/api/mail";
import { AccountShareReference } from "@haywork/middleware/thunk/email";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { EmailRequest } from "@haywork/request";
import { FormControlUtil, StringUtil } from "@haywork/util";
import * as equal from "deep-equal";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./edit-modal.component.scss");

interface Props {
  visible: boolean;
  account: Account;
  shares: AccountShare[];
  employees: RelationSnapShot[];
  defaultAccountId: string;
  signatures: TemplateDefinitionSnapShot[];
  employeeId: string;
  onClose: () => void;
  onSubmit: (
    account: Account,
    shares: AccountShareReference[],
    defaultAccountId: string | null
  ) => Promise<void>;
  onDefineNew: (accountId: string) => void;
  onShareDelete: (share: AccountShare) => void;
  getArchiveFolder: (accountId: string, folderId: string) => Promise<Folder>;
}
interface State {
  selectedShareIds: string[];
  loading: boolean;
}
interface SnapshotGroups {
  readShares: RelationSnapShot[];
  readWriteShares: RelationSnapShot[];
  readWriteSendShares: RelationSnapShot[];
}

@CSSModules(styles, { allowMultiple: true })
export class EditModalComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { readShares, readWriteShares, readWriteSendShares } =
      this.mapSharesToSnapshotGroups(this.props.shares);

    this.formControls = {
      name: { value: value(this.props.account, "accountName") },
      from: { value: value(this.props.account, "sentFromName", "") },
      defaultAccount: {
        value:
          value(this.props.account, "id") ===
          value(this.props, "defaultAccountId"),
      },
      defaultSignatureTemplateId: {
        value: value(this.props.account, "defaultSignatureTemplateId"),
      },
      autoRemoveMessageAfterPersist: {
        value: value(
          this.props.account,
          "autoRemoveMessageAfterPersist",
          false
        ),
      },
      archiveFolder: { value: "" },
      readShares: { value: readShares },
      readWriteShares: { value: readWriteShares },
      readWriteSendShares: { value: readWriteSendShares },
    };

    this.state = {
      selectedShareIds: this.props.shares.map((share) => share.personId),
      loading: false,
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderDisableOption = this.renderDisableOption.bind(this);
  }

  public async componentDidUpdate(prevProps: Props) {
    if (!equal(prevProps.account, this.props.account) && !!this.formRef) {
      const { readShares, readWriteShares, readWriteSendShares } =
        this.mapSharesToSnapshotGroups(this.props.shares);
      const selectedShareIds = this.props.shares.map((share) => share.personId);

      this.formRef.update({
        name: value(this.props.account, "accountName"),
        from: value(this.props.account, "sentFromName", ""),
        defaultAccount:
          value(this.props.account, "id") ===
          value(this.props, "defaultAccountId"),
        autoRemoveMessageAfterPersist: value(
          this.props.account,
          "autoRemoveMessageAfterPersist",
          false
        ),
        defaultSignatureTemplateId: value(
          this.props.account,
          "defaultSignatureTemplateId"
        ),
        readShares,
        readWriteShares,
        readWriteSendShares,
      });

      this.setState({
        selectedShareIds,
      });

      if (!!this.props.account.archiveFolderId) {
        const archiveFolder = await this.props.getArchiveFolder(
          this.props.account.id,
          this.props.account.archiveFolderId
        );
        this.formRef.update({
          archiveFolder,
        });
      }
    }
  }

  public render() {
    const disabled = !!this.state.loading;
    const isAccountOwner =
      get(this.props.account, "ownerId") === this.props.employeeId;

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader
          title="editEmailAddress"
          titleValues={{
            email: value(this.props.account, "emailAddress", "..."),
          }}
          close
        />
        <ModalBody noPadding>
          <Form
            name="email-account"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.onSubmitHandler}
            onChange={this.onChangeHandler}
          >
            <div styleName="account-form">
              <div className="form__row">
                <label htmlFor="name">
                  <ResourceText resourceKey="emailAccountName" />
                </label>
                <Input.Text
                  name="name"
                  disabled={disabled || !isAccountOwner}
                />
              </div>

              <div className="form__row">
                <label htmlFor="from">
                  <ResourceText resourceKey="emailAccountFrom" />
                </label>
                <Input.Text
                  name="from"
                  disabled={disabled || !isAccountOwner}
                  placeholder="emailAccountFromPlaceholder"
                />
              </div>

              <div className="form__row">
                <Input.Switch
                  name="defaultAccount"
                  label="emailDefaultAccountLabel"
                  labelPosition={SwitchLabelPosition.Pre}
                  on={true}
                  off={false}
                  disabled={disabled}
                />
              </div>
              <div className="form__row">
                <Input.Switch
                  name="autoRemoveMessageAfterPersist"
                  label="accountAutoRemoveMessageAfterPersist"
                  labelPosition={SwitchLabelPosition.Pre}
                  on={true}
                  off={false}
                  disabled={disabled || !isAccountOwner}
                />
              </div>

              {!!this.props.signatures.length && (
                <div className="form__row">
                  <label htmlFor="name">
                    <ResourceText resourceKey="emailAccount.defaultSignatureTemplateId" />
                  </label>
                  <Input.NewSelect
                    name="defaultSignatureTemplateId"
                    addEmptyOption
                    emptyOptionLabel="noAccountDefaultSignatureTemplateId"
                    values={this.props.signatures}
                    displayProp="name"
                    valuesProp="id"
                    disabled={disabled || !isAccountOwner}
                  />
                </div>
              )}

              <div className="form__row">
                <label htmlFor="archiveFolder">
                  <ResourceText resourceKey="emailAccountArchiveFolder" />
                </label>
                <Input.Query
                  name="archiveFolder"
                  optionValue={this.renderQueryBaseOption}
                  selectedValue={this.renderQueryBaseValue}
                  asyncValues={(term) =>
                    EmailRequest.searchFolder(term, this.props.account.id)
                  }
                  placeholder="emailSearchMoveFolderPlaceholder"
                  disabled={disabled || !isAccountOwner}
                />
              </div>
            </div>

            {isAccountOwner && (
              <div styleName="shared">
                <div styleName="shared__header">
                  <ResourceText resourceKey="shareEmailAccountWith" />
                </div>

                <div styleName="shared__form">
                  <div className="form__row">
                    <label htmlFor="readShares">
                      <ResourceText resourceKey="emailReadSharesLabel" />
                    </label>
                    <Input.Query
                      name="readShares"
                      values={this.props.employees}
                      matchOn={this.matchOnHandler}
                      optionValue={this.renderOptionValue}
                      selectedValue={this.renderSelectedValue}
                      disableOption={this.renderDisableOption}
                      disabled={disabled}
                      multiple
                      placeholder="emailLinkedPersonPlaceholder"
                    />
                  </div>

                  <div className="form__row">
                    <label htmlFor="readWriteShares">
                      <ResourceText resourceKey="emailReadWriteSharesLabel" />
                    </label>
                    <Input.Query
                      name="readWriteShares"
                      values={this.props.employees}
                      matchOn={this.matchOnHandler}
                      optionValue={this.renderOptionValue}
                      selectedValue={this.renderSelectedValue}
                      disableOption={this.renderDisableOption}
                      disabled={disabled}
                      multiple
                      placeholder="emailLinkedPersonPlaceholder"
                    />
                  </div>

                  <div className="form__row">
                    <label htmlFor="readWriteSendShares">
                      <ResourceText resourceKey="emailReadWriteSendSharesLabel" />
                    </label>
                    <Input.Query
                      name="readWriteSendShares"
                      values={this.props.employees}
                      matchOn={this.matchOnHandler}
                      optionValue={this.renderOptionValue}
                      selectedValue={this.renderSelectedValue}
                      disableOption={this.renderDisableOption}
                      disabled={disabled}
                      multiple
                      placeholder="emailLinkedPersonPlaceholder"
                    />
                  </div>
                </div>
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => this.formRef.submit()}
          >
            <ButtonLoader resourceKey="save" loading={disabled} />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private async onSubmitHandler(values: FormReturnValue) {
    if (!!this.state.loading) return;

    this.setState({
      loading: true,
    });

    const account: Account = {
      ...this.props.account,
      sentFromName: values.from,
      accountName: values.name,
      archiveFolderId: !!values.archiveFolder ? values.archiveFolder.id : null,
      defaultSignatureTemplateId: values.defaultSignatureTemplateId,
      autoRemoveMessageAfterPersist: values.autoRemoveMessageAfterPersist,
    };
    const defaultAccountId = !!values.defaultAccount
      ? this.props.account.id
      : this.props.defaultAccountId === this.props.account.id
      ? ""
      : null;

    try {
      await this.props.onSubmit(
        account,
        this.mapSnapshotGroupsToShares(values),
        defaultAccountId
      );
      this.props.onClose();

      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const snapshots = this.mapSnapshotGroupsToShares(values);
    const selectedShareIds = snapshots.map((snapshot) => snapshot.id);

    this.setState({
      selectedShareIds,
    });
  }

  private matchOnHandler(query: string, value: RelationSnapShot): boolean {
    const matchOn = new RegExp(query, "gi");
    return matchOn.test(value.displayName);
  }

  private renderOptionValue(
    value: RelationSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private renderSelectedValue(
    value: RelationSnapShot
  ): QueryResultReturnValue<RelationSnapShot> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private mapSharesToSnapshotGroups(shares: AccountShare[]): SnapshotGroups {
    const accountId = !!this.props.account ? this.props.account.id : null;
    let filteredShares = shares || [];
    filteredShares = filteredShares.filter(
      (share) => share.accountId === accountId
    );

    return filteredShares.reduce(
      (state, share) => {
        const snapshot = this.props.employees.find(
          (employee) => employee.id === share.personId
        );

        if (!!snapshot) {
          switch (share.shareType) {
            case ShareType.Read:
              state.readShares.push(snapshot);
              break;
            case ShareType.ReadWrite:
              state.readWriteShares.push(snapshot);
              break;
            case ShareType.ReadWriteSend:
              state.readWriteSendShares.push(snapshot);
              break;
            default:
              break;
          }
        }

        return state;
      },
      {
        readShares: [],
        readWriteShares: [],
        readWriteSendShares: [],
      }
    );
  }

  private mapSnapshotGroupsToShares(
    values: FormReturnValue
  ): AccountShareReference[] {
    const { readShares, readWriteShares, readWriteSendShares } = values;

    return [
      ...readShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.Read,
      })),
      ...readWriteShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.ReadWrite,
      })),
      ...readWriteSendShares.map((snapshot: RelationSnapShot) => ({
        id: snapshot.id,
        shareType: ShareType.ReadWriteSend,
      })),
    ];
  }

  private renderDisableOption(value: RelationSnapShot) {
    return this.state.selectedShareIds.indexOf(value.id) !== -1;
  }

  private renderQueryBaseOption(
    value: Folder,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div className="result">
        <div
          className="result__name"
          dangerouslySetInnerHTML={StringUtil.highlight(
            value.displayName,
            query
          )}
        />
        <div className="result__path">{value.fullPath}</div>
      </div>
    );
  }

  private renderQueryBaseValue(value: Folder): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }
}
