import * as React from "react";
import { FC, memo, useState, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";

const styles = require("./accounts.component.scss");

export type Props = {
  onClick: () => Promise<void>;
};

export const LoadMore: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ onClick }) => {
    const [loading, setLoading] = useState(false);

    const onClickHandler = useCallback(async () => {
      try {
        setLoading(true);
        await onClick();
      } finally {
        setLoading(false);
      }
    }, [setLoading, onClick]);

    return (
      <div styleName="load-more" onClick={onClickHandler}>
        {loading && (
          <>
            <span className="far fa-spin fa-spinner" />{" "}
          </>
        )}
        <I18n value="loadMoreEmailFolders" />
      </div>
    );
  })
);
