import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { NavLink } from "react-router-dom";
import { ACQUISITIONROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import { ObjectTimestamps } from "@haywork/modules/shared";
import { AcquisitionDetailSidebarContainerProps } from "./detail-sidebar.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquisitionDetailSidebarComponentProps = {};
type Props = AcquisitionDetailSidebarComponentProps &
  AcquisitionDetailSidebarContainerProps;

export const AcquisitionDetailSidebarComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ acquisition }) => {
    const {
      id,
      dateTimeModified,
      linkedModifiedBy,
      dateTimeCreated,
      linkedCreatedBy
    } = acquisition;

    return (
      <div>
        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(ACQUISITIONROUTES.GENERAL.URI, { id })}
        >
          <Icon name="handshake-alt" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="acquisition.menuLabel.general" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(ACQUISITIONROUTES.ACQUISITION_ASSIGNMENTS.URI, {
            id
          })}
        >
          <Icon name="folder-open" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="acquisition.menuLabel.assignments" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(ACQUISITIONROUTES.TIMELINE.URI, {
            id
          })}
        >
          <Icon name="align-left" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="acquisition.menuLabel.timeline" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(ACQUISITIONROUTES.NETWORK.URI, {
            id
          })}
        >
          <Icon name="users" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="acquisition.menuLabel.network" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(ACQUISITIONROUTES.DOSSIER.URI, {
            id
          })}
        >
          <Icon name="archive" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="acquisition.menuLabel.dossier" />
          </div>
        </NavLink>

        <ObjectTimestamps
          linkedCreatedBy={linkedCreatedBy}
          linkedModifiedBy={linkedModifiedBy}
          dateTimeCreated={dateTimeCreated}
          dateTimeModified={dateTimeModified}
        />
      </div>
    );
  })
);
