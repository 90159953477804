import * as React from "react";
import { SettingField } from "@haywork/api/authorization";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  field: SettingField;
}

export const AppXChangeSettingsSectionComponent = CSSModules(styles, {
  allowMultiple: true
})(({ field }: Props) => {
  if (!field || !field.linkedSettingFieldDefinition) return null;
  const { label, description } = field.linkedSettingFieldDefinition;

  return (
    <div styleName="section">
      {!!label && <div styleName="section__label">{label}</div>}
      {!!description && (
        <div styleName="section__description">{description}</div>
      )}
    </div>
  );
});
