import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import head from "lodash-es/head";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import { ResourceText, ButtonLoader } from "@haywork/modules/shared";
import {
  AppClient,
  AppClientTranslation,
  PermissionSnapShot,
  BasicTranslation,
  LinkedPermissionResource,
} from "@haywork/api/authorization";
import { REQUEST } from "@haywork/constants";
import { PermissionCategoryGroup, PermissionGroup } from "./";
import PermissionsListComponent from "./permissions-list.component";

const styles = require("./consent-modal.component.scss");

interface Props {
  client: AppClient;
  translation: AppClientTranslation;
  visible: boolean;
  consentStatus: string;
  culture: string;
  getPermissionsForClient: (id: string) => Promise<PermissionSnapShot[]>;
  onCancel: () => void;
  onAllow: (client: AppClient) => void;
}

interface State {
  numberOfPermissions: number;
  permissionCategories: PermissionCategoryGroup;
}

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeConsentModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      numberOfPermissions: 0,
      permissionCategories: {},
    };

    this.mapPermissions = this.mapPermissions.bind(this);
    this.mapPermissionToTranslatedValues =
      this.mapPermissionToTranslatedValues.bind(this);
    this.getPermissionResourceGroupName =
      this.getPermissionResourceGroupName.bind(this);
    this.getPermissionCategoriesKeys =
      this.getPermissionCategoriesKeys.bind(this);
  }

  public componentDidMount() {
    this.mapPermissions(this.props.client.id);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (get(nextProps, "client.id") !== get(this.props, "client.id")) {
      this.mapPermissions(nextProps.client.id);
    }
  }

  public render() {
    const logoUrl = this.props.translation.logoUrl
      ? {
          backgroundImage: `url(${JSON.stringify(
            this.props.translation.logoUrl
          )})`,
        }
      : null;
    const disabled = this.props.consentStatus === REQUEST.PENDING;

    return (
      <Modal visible={this.props.visible} onClose={this.props.onCancel}>
        <ModalHeader
          title="appXchangeConsentModalTitle"
          titleValues={{ displayName: this.props.client.displayName }}
          close
        />

        <ModalBody>
          <div styleName="merge-logos">
            <div styleName="logo kolibri" />
            <div styleName="logo" style={logoUrl} />
          </div>

          <div styleName="text">
            <ResourceText
              resourceKey="appXchangeConsentModalIntro"
              values={{ displayName: this.props.client.displayName }}
              asHtml
            />
          </div>

          <div styleName="consent-list">
            {this.getPermissionCategoriesKeys().map(
              (item) =>
                this.state.permissionCategories[item] && (
                  <PermissionsListComponent
                    title={item}
                    key={item}
                    permissions={this.state.permissionCategories[item]}
                  />
                )
            )}
          </div>

          <div styleName="text">
            <ResourceText
              resourceKey="appXchangeConsentModalOutro"
              values={{ displayName: this.props.client.displayName }}
              asHtml
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-blank"
            onClick={this.props.onCancel}
          >
            <ResourceText resourceKey="appXchangeDenyConsent" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.props.onAllow(this.props.client)}
            disabled={disabled}
          >
            <ButtonLoader
              resourceKey="appXchangeGiveConsent"
              loading={this.props.consentStatus === REQUEST.PENDING}
            />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private mapPermissionToTranslatedValues(
    permission: PermissionSnapShot
  ): BasicTranslation {
    const translation = (permission.translations || []).find(
      (translation) => translation.languageCulture === this.props.culture
    );
    return translation || head(permission.translations || []);
  }

  private getPermissionResourceGroupName(
    resource: LinkedPermissionResource
  ): string {
    const translation = (resource.translations || []).find(
      (translation) => translation.languageCulture === this.props.culture
    );

    return !!translation
      ? translation.title
      : head(
          !resource.translations || !resource.translations.length
            ? [{ title: "" }]
            : resource.translations
        ).title;
  }

  private getPermissionCategoriesKeys() {
    const categoryKeys = [];
    for (const key in this.state.permissionCategories) {
      categoryKeys.push(key);
    }
    return categoryKeys;
  }

  private async mapPermissions(id: string) {
    try {
      const allPermissions = await this.props.getPermissionsForClient(id);

      const categoriesList: PermissionCategoryGroup = allPermissions.reduce(
        (state, permission) => {
          const currentPermissionCategory =
            permission.linkedPermissionResource.apiResource;

          if (!state[currentPermissionCategory]) {
            state[currentPermissionCategory] = [];
          }

          const ref = state[currentPermissionCategory].find(
            (p) =>
              p.permissionResource.id === permission.linkedPermissionResource.id
          );
          if (!ref) {
            state[currentPermissionCategory].push({
              permissionResource: permission.linkedPermissionResource,
              displayName: this.getPermissionResourceGroupName(
                permission.linkedPermissionResource
              ),
              permissions: [this.mapPermissionToTranslatedValues(permission)],
            });
          } else {
            ref.permissions.push(
              this.mapPermissionToTranslatedValues(permission)
            );
          }

          return state;
        },
        {} as PermissionCategoryGroup
      );
      this.setState({
        numberOfPermissions: allPermissions.length,
        permissionCategories: categoriesList,
      });
    } catch (error) {
      throw error;
    }
  }
}
