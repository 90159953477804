import {
  ErrorCode,
  FolderCategory,
  MessagesClient,
  RelatedMessageLink,
  UserDataElement,
  ShareType,
  SynchronizingMessagesClient,
} from "@haywork/api/mail";
import {
  COUNTS,
  EMAILROUTES,
  LINKED_ASSIGNMENT_KEY,
  LINKED_RELATION_KEY,
  MAINROUTES,
} from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  LoggingActions,
  LogType,
} from "@haywork/stores";
import {
  EmailActionsV2,
  EmailDraft,
  EmailFolder,
  EmailMessage,
} from "@haywork/stores/email-v2";
import { EmailRequestState } from "@haywork/stores/email-v2/main";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { RouteUtil } from "@haywork/util";
import { EmailUtil } from "@haywork/util/email-v2";
import { push } from "connected-react-router";
import differenceBy from "lodash-es/differenceBy";
import findIndex from "lodash-es/findIndex";
import get from "lodash-es/get";
import has from "lodash-es/has";
import uniq from "lodash-es/uniq";
import { v4 as uuid } from "uuid";
import { Dispatch } from "../";
import CommunicationLogs from "./communication-logs";
import head from "lodash-es/head";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;
let prevSearchQuery: string;

const getMessages = (
  startIndex: number,
  accountId: string,
  folderId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      if (!accountId || !folderId) return [];

      dispatch(
        EmailActionsV2.Main.setEmailRequestState(
          EmailRequestState.FetchingMessages
        )
      );

      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { filters, idsMatchingSearchQuery } = state.emailV2.main;
      const { folders } = state.emailV2.folders;
      const messagesClient = new MessagesClient(emailHost);
      const synchronizingMessagesClient = new SynchronizingMessagesClient(
        emailHost
      );
      const queryMatchesPrevQuery = prevSearchQuery === filters.searchQuery;
      const hasSearchQuery = !!filters.searchQuery;
      let skip = startIndex || 0;
      const folderRef = folders.find((folder) => folder.id === folderId);

      if (!!filters.searchQuery) {
        skip = queryMatchesPrevQuery ? idsMatchingSearchQuery.length : 0;
        prevSearchQuery = filters.searchQuery;
      } else if (!!prevSearchQuery) {
        prevSearchQuery = undefined;
      }

      const response = await parseRequest.response(
        folderRef?.category === FolderCategory.SynchronizingMessage
          ? synchronizingMessagesClient.searchSynchronizingMessages(
              { accountId, skip, take: COUNTS.EMAIL_MESSAGES },
              realEstateAgencyId
            )
          : messagesClient.search(
              {
                accountId,
                folderId: hasSearchQuery ? null : folderId,
                skip,
                take: COUNTS.EMAIL_MESSAGES,
                ...filters,
              },
              realEstateAgencyId
            )
      );

      if (!response) throw new Error("E-mail messages request failed");

      const { resultCount, results } = response;
      const { messages: refs } = state.emailV2.messages;

      const messages = (results || []).map((message) =>
        EmailUtil.mapMessageToEmailMessage(
          message,
          accountId,
          folderId,
          refs,
          false,
          !hasSearchQuery
        )
      );

      if (resultCount < COUNTS.EMAIL_MESSAGES) {
        dispatch(
          EmailActionsV2.Folders.setFolderTotalResults(
            folderId,
            skip + resultCount
          )
        );
      }

      if (!!filters.searchQuery) {
        let ids = messages.map((message) => message.id);

        if (queryMatchesPrevQuery) {
          ids = uniq([...idsMatchingSearchQuery, ...ids]);
        }

        dispatch(EmailActionsV2.Main.setIdsMatchingSearchQuery(ids));
      }

      dispatch(EmailActionsV2.Messages.appendMessages(messages));
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Idle)
      );

      return messages;
    } catch (error) {
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Error)
      );
      throw error;
    }
  };
};

const onToggleUnRead = (id: string, isRead: boolean, accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let ref: EmailMessage;
    let folder: EmailFolder;

    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { messages } = state.emailV2.messages;
      const { folders } = state.emailV2.folders;
      const client = new MessagesClient(emailHost);

      ref = messages.find((message) => message.id === id);
      if (!ref) return;

      const message: EmailMessage = {
        ...ref,
        unread: !isRead,
      };
      dispatch(EmailActionsV2.Messages.updateMessage(message));

      folder = folders.find((folder) => folder.id === message.folderId);
      if (!!folder) {
        const { unreadCount } = folder;
        const updatedFolder = {
          ...folder,
          unreadCount: isRead ? unreadCount - 1 || 0 : unreadCount + 1,
        };

        dispatch(EmailActionsV2.Folders.updateFolder(updatedFolder));
      }

      await client.toggleIsRead({ id, isRead, accountId }, realEstateAgencyId);
    } catch (error) {
      dispatch(EmailActionsV2.Messages.updateMessage(ref));
      dispatch(EmailActionsV2.Folders.updateFolder(folder));
      throw error;
    }
  };
};

const getMessage = (
  id: string,
  accountId: string,
  folderId: string,
  blockExternalReferences: boolean,
  markAsRead = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { messages } = state.emailV2.messages;
      const client = new MessagesClient(emailHost);

      const response = await parseRequest.response(
        client
          .read(accountId, id, blockExternalReferences, realEstateAgencyId)
          .then((response) => response.message || null)
      );
      if (!response) throw new Error("Email message not found");
      const message = EmailUtil.mapMessageToEmailMessage(
        response,
        accountId,
        folderId,
        messages,
        markAsRead
      );

      dispatch(EmailActionsV2.Messages.updateMessage(message));

      return message;
    } catch (error) {
      throw error;
    }
  };
};

const checkIfFolderHasMessages = (accountId: string, folderId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new MessagesClient(emailHost);

      const hasMessages = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 1,
              accountId,
              folderId,
            },
            realEstateAgencyId
          )
          .then((response) => !!(response.results || []).length)
      );

      return hasMessages;
    } catch (error) {
      throw error;
    }
  };
};

const moveToFolder = (messageId: string, newFolderId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let message: EmailMessage | null = null;
    let originalFolderId: string | null = null;

    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { messages } = state.emailV2.messages;
      const { folders } = state.emailV2.folders;
      const client = new MessagesClient(emailHost);

      message = messages.find((message) => message.id === messageId);
      if (!message) throw new Error("Message not found");

      const { accountId, id, folderId } = message;
      const siblingMessages = messages.filter((m) => m.folderId === folderId);
      const count = siblingMessages.length;
      const index = findIndex(siblingMessages, (message) => message.id === id);
      const folder = folders.find((folder) => folder.id === folderId);

      originalFolderId = folderId;

      // Move message to new folder
      const updatedMessage = { ...message, folderId: newFolderId };
      dispatch(EmailActionsV2.Messages.updateMessage(updatedMessage));

      if (!folder || folder.selectedMessage === id) {
        // Select next or previous message to display
        const nextIndex =
          count > index + 1 ? index + 1 : index === 0 ? -1 : index - 1;
        const nextMessage =
          nextIndex === -1
            ? undefined
            : siblingMessages[nextIndex].id || undefined;
        dispatch(
          EmailActionsV2.Folders.setCurrentMessage(
            originalFolderId,
            nextMessage
          )
        );
      }

      // In- or decrement inbox counter
      if (message.unread) {
        let originalFolder = folders.find(
          (folder) => folder.id === originalFolderId
        );

        let newFolder = folders.find((folder) => folder.id === newFolderId);

        if (
          !!originalFolder &&
          originalFolder.category === FolderCategory.Inbox
        ) {
          originalFolder = {
            ...originalFolder,
            unreadCount: originalFolder.unreadCount - 1,
          };
          dispatch(EmailActionsV2.Folders.updateFolder(originalFolder));
        }

        if (!!newFolder && newFolder.category === FolderCategory.Inbox) {
          newFolder = {
            ...newFolder,
            unreadCount: newFolder.unreadCount + 1,
          };
          dispatch(EmailActionsV2.Folders.updateFolder(newFolder));
        }
      }

      // Update message request
      parseRequest.response(
        client.move({ accountId, id, newFolderId }, realEstateAgencyId)
      );
    } catch (error) {
      dispatch(undoMoveToFolder(message, originalFolderId, newFolderId));
      throw error;
    }
  };
};

const undoMoveToFolder = (
  message: EmailMessage | null,
  originalFolderId: string | null,
  newFolderId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!!message) {
      dispatch(EmailActionsV2.Messages.updateMessage(message));
    }
    if (!!message && !!originalFolderId) {
      dispatch(
        EmailActionsV2.Folders.setCurrentMessage(originalFolderId, message.id)
      );
    }

    // In- or decrement inbox counter
    if (!!message && message.unread) {
      const state = getState();
      const { folders } = state.emailV2.folders;

      let originalFolder = folders.find(
        (folder) => folder.id === originalFolderId
      );

      let newFolder = folders.find((folder) => folder.id === newFolderId);

      if (
        !!originalFolder &&
        originalFolder.category === FolderCategory.Inbox
      ) {
        originalFolder = {
          ...originalFolder,
          unreadCount: originalFolder.unreadCount + 1,
        };
        dispatch(EmailActionsV2.Folders.updateFolder(originalFolder));
      }

      if (!!newFolder && newFolder.category === FolderCategory.Inbox) {
        newFolder = {
          ...newFolder,
          unreadCount: newFolder.unreadCount - 1,
        };
        dispatch(EmailActionsV2.Folders.updateFolder(newFolder));
      }
    }
  };
};

const onToggleBookmarked = (
  id: string,
  isBookmarked: boolean,
  accountId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let ref: EmailMessage;

    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { messages } = state.emailV2.messages;
      const client = new MessagesClient(emailHost);

      ref = messages.find((message) => message.id === id);
      if (!ref) return;

      const message: EmailMessage = {
        ...ref,
        bookmarked: isBookmarked,
      };
      dispatch(EmailActionsV2.Messages.updateMessage(message));

      await client.toggleBookmark(
        { id, isBookmarked, accountId },
        realEstateAgencyId
      );
    } catch (error) {
      dispatch(EmailActionsV2.Messages.updateMessage(ref));
      throw error;
    }
  };
};

// Refactor candidate
const createDraftFromMessage = (
  message: EmailMessage,
  draftType: "reply" | "reply-all" | "forward"
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { folders } = state.emailV2.folders;
      const { accounts } = state.emailV2.accounts;
      const shares = state.emailV2.shares.shares || [];
      const settings = state.emailV2.main.personSettings;
      const sendAccounts = accounts.filter((account) => {
        const restrictions = shares.find(
          (share) => share.accountId === account.id
        );
        return ![ShareType.Read].includes(restrictions?.shareType);
      });
      const { accountId, _metaData } = message;
      let { to: additionalTo, from, cc, files, date, body, subject } = message;
      const { replyTo } = message;
      const draftFolder = folders.find(
        (folder) =>
          folder.accountId === accountId &&
          folder.category === FolderCategory.Drafts
      );
      const account = accounts.find((account) => account.id === accountId);
      const userData: UserDataElement[] = [];
      const linkedCommunicationLogId = get(
        _metaData,
        "linkedCommunicationLogId"
      );
      let to = [];
      const originalTo = additionalTo || [];

      if (!!linkedCommunicationLogId) {
        const communicationLog = await dispatch(
          CommunicationLogs.getCommunicationLog(linkedCommunicationLogId)
        );
        const { linkedRelations, linkedAssignments } = communicationLog;

        (linkedRelations || []).map((relation) => {
          userData.push({
            key: LINKED_RELATION_KEY,
            value: JSON.stringify(relation),
          });
        });
        (linkedAssignments || []).map((assignment) => {
          userData.push({
            key: LINKED_ASSIGNMENT_KEY,
            value: JSON.stringify(assignment),
          });
        });
      }

      const validAccount =
        (sendAccounts || []).find((account) => account.id === accountId) ||
        (sendAccounts || []).find(
          (account) => account.id === settings?.defaultAccountId
        );
      const validAccountId =
        validAccount?.id || head(sendAccounts)?.id || accountId;

      cc = cc || [];
      from = from || [];
      files = files || [];
      date = date || new Date();

      switch (draftType) {
        case "reply": {
          to = replyTo || from;
          cc = [];
          body = EmailUtil.prepareEmailBody(
            from,
            originalTo,
            cc,
            date,
            subject,
            body
          );
          subject = `RE: ${subject}`;
          files = files.filter((file) => !file.isAttachment);
          userData.push({
            key: "relatedMessage",
            value: JSON.stringify({
              link: RelatedMessageLink.ReplyTo,
              accountId: validAccountId,
              messageId: message.id,
            }),
          });
          break;
        }
        case "reply-all": {
          additionalTo = additionalTo.filter(
            (to) => to.email !== account.emailAddress
          );
          to = [...from];
          if (replyTo) to = [...to, ...replyTo];
          to = [
            ...to,
            ...differenceBy(additionalTo, to, (email) => email.email),
          ];
          body = EmailUtil.prepareEmailBody(
            from,
            originalTo,
            cc,
            date,
            subject,
            body
          );
          subject = `RE: ${subject}`;
          files = files.filter((file) => !file.isAttachment);
          userData.push({
            key: "relatedMessage",
            value: JSON.stringify({
              link: RelatedMessageLink.ReplyTo,
              accountId: validAccountId,
              messageId: message.id,
            }),
          });
          break;
        }
        case "forward": {
          cc = [];
          body = EmailUtil.prepareEmailBody(
            from,
            originalTo,
            [],
            date,
            subject,
            body
          );
          subject = `FW: ${subject}`;
          userData.push({
            key: "relatedMessage",
            value: JSON.stringify({
              link: RelatedMessageLink.ForwardOf,
              accountId: validAccountId,
              messageId: message.id,
            }),
          });
          break;
        }
        default: {
          break;
        }
      }

      const id: string = uuid();
      const path = route(EMAILROUTES.CREATE.URI, { id });
      const componentState: EmailDraft = {
        body,
        subject,
        to,
        cc,
        files,
        temporaryId: id,
        accountId: validAccountId,
        folderId: !draftFolder ? "" : draftFolder.id || "",
        userData,
        searchString: "",
        sendRequested: false,
      };

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAIL.ICON,
          componentState,
          path,
          title: componentState.subject || "...",
          confirm: {
            title: { key: "saveEmailConfirmTitle" },
            body: { key: "saveEmailConfirmBody" },
          },
          entityType: null,
        })
      );
      dispatch(push(path));
    } catch (error) {
      throw error;
    }
  };
};

const checkAndUpdateMessage = (messageId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { messages } = state.emailV2.messages;
      const match = messages.find((message) => message.id === messageId);

      if (!match) {
        dispatch(
          LoggingActions.addLogging({
            logType: LogType.EmailThunk,
            label: "No match for message found in current state",
            payload: JSON.stringify({
              thunk: "emailV2/messages.ts/checkAndUpdateMessage",
              messageId,
            }),
          })
        );
        return;
      }

      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { emailHost } = state.appSettings;
      const client = new MessagesClient(emailHost);
      const { id, accountId, folderId } = match;

      const message = await parseRequest.response(
        client
          .read(accountId, id, true, realEstateAgencyId)
          .then((response) => response.message || null),
        ApiType.Email,
        { displayError: false }
      );

      if (!message) {
        dispatch(
          LoggingActions.addLogging({
            logType: LogType.EmailThunk,
            label: "Message could not be found",
            payload: JSON.stringify({
              thunk: "emailV2/messages.ts/checkAndUpdateMessage",
              messageId,
            }),
          })
        );
        return;
      }

      const { folder } = message;

      let bindToFolderId = folder?.id;
      if (
        (folder && folder.category === FolderCategory.All) ||
        (folder &&
          folder.category === FolderCategory.UserCreated &&
          /alle e(-?)mail/gi.test(folder.displayName || ""))
      ) {
        bindToFolderId = folderId;
      }

      const updatedMessage = EmailUtil.mapMessageToEmailMessage(
        message,
        accountId,
        bindToFolderId,
        messages,
        false
      );

      if (!message.folder) {
        updatedMessage.folder = null;
      }

      dispatch(EmailActionsV2.Messages.updateMessage(updatedMessage));
    } catch (error) {
      dispatch(
        LoggingActions.addLogging({
          logType: LogType.EmailThunk,
          label: "Error",
          payload: JSON.stringify({
            thunk: "emailV2/messages.ts/checkAndUpdateMessage",
            error,
          }),
        })
      );

      if (
        (has(error, "status") && get(error, "status") === 404) ||
        (has(error, "errorCode") &&
          get(error, "errorCode") === ErrorCode.NotFound)
      ) {
        dispatch(EmailActionsV2.Messages.deleteMessage(messageId));
      }

      throw error;
    }
  };
};

const appendMessage = (
  id: string,
  accountId: string,
  folderId: string,
  silent: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { emailHost } = state.appSettings;
      const client = new MessagesClient(emailHost);
      const { messages } = state.emailV2.messages;

      const message = await parseRequest.response(
        client
          .read(accountId, id, true, realEstateAgencyId)
          .then((response) => response.message || null)
      );
      if (!message) {
        dispatch(
          LoggingActions.addLogging({
            logType: LogType.EmailThunk,
            label: "Message could not be found",
            payload: JSON.stringify({
              thunk: "emailV2/messages.ts/appendMessage",
              data: {
                id,
                accountId,
                folderId,
                silent,
              },
            }),
          })
        );
        return;
      }

      const { folder, unread } = message;
      const appendableMessage = EmailUtil.mapMessageToEmailMessage(
        message,
        accountId,
        !!folder?.id ? folder.id : folderId,
        messages,
        !unread
      );

      const appendableMessages = [appendableMessage];

      dispatch(EmailActionsV2.Messages.appendMessages(appendableMessages));

      if (
        !!folder &&
        ![FolderCategory.Trash, FolderCategory.Archive].includes(
          folder.category
        ) &&
        !silent
      ) {
        const toast: ToastProps = {
          value: "emailToastMessageReceived",
          values: { folder: folder.displayName },
          icon: "envelope",
        };

        dispatch(SnackbarActions.addToast(toast));
      }
    } catch (error) {
      dispatch(
        LoggingActions.addLogging({
          logType: LogType.EmailThunk,
          label: "Error",
          payload: JSON.stringify({
            thunk: "emailV2/messages.ts/appendMessage",
            error,
          }),
        })
      );
      throw error;
    }
  };
};

export default {
  getMessages,
  onToggleUnRead,
  getMessage,
  checkIfFolderHasMessages,
  moveToFolder,
  onToggleBookmarked,
  createDraftFromMessage,
  checkAndUpdateMessage,
  appendMessage,
};
