import { combineReducers } from "redux";
import { listReducer, ListState, RelationListActions } from "./list";
import {
  relationDetailAssignmentReducer,
  RelationDetailAssignmentState
} from "./relation-detail-assignment.reducer";
import {
  relationDetailContactlinkReducer,
  RelationDetailContactlinkState
} from "./relation-detail-contactlink.reducer";
import {
  relationDetailTimelineReducer,
  RelationDetailTimelineState
} from "./relation-detail-timeline.reducer";
import {
  relationDetailReducer,
  RelationDetailState
} from "./relation-detail.reducer";
import {
  relationOverviewReducer,
  RelationOverviewState
} from "./relation-overview.reducer";

export * from "./relation.actions";
export { RelationListActions };

export interface RelationState {
  overview: RelationOverviewState;
  detail: RelationDetailState;
  detailAssignment: RelationDetailAssignmentState;
  detailTimeline: RelationDetailTimelineState;
  detailContactlink: RelationDetailContactlinkState;
  list: ListState;
}

export const relationReducer = combineReducers({
  overview: relationOverviewReducer,
  detail: relationDetailReducer,
  detailAssignment: relationDetailAssignmentReducer,
  detailTimeline: relationDetailTimelineReducer,
  detailContactlink: relationDetailContactlinkReducer,
  list: listReducer
});
