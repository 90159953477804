import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { ObjectTypesPublishContainerProps } from "@haywork/modules/object-types";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { OBJECTTYPESROUTES } from "@haywork/constants";

const styles = require("./publish.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ObjectTypesPublishComponentProps {}
interface State {}
type Props = ObjectTypesPublishComponentProps &
  ObjectTypesPublishContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesPublishComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.publishHandler = this.publishHandler.bind(this);
  }

  public render() {
    const { displayName, currentType, objectTypeInfo, projectName } =
      this.props;
    const { photos, videos, id } = currentType;

    const photoCount = !!photos ? photos.length : 0;
    const videoCount = !!videos ? videos.length : 0;
    const firstPhoto: any = photoCount ? photos[0] : null;
    const previewStyles = photoCount
      ? {
          backgroundImage: `url(${JSON.stringify(
            firstPhoto.urlPreview || firstPhoto.thumbUrl
          )})`,
        }
      : null;

    return (
      <div styleName="publish">
        <div className="container-fluid">
          <h1>
            <ResourceText
              resourceKey="projectTypePublishStarting"
              values={{ displayName, projectName }}
            />
          </h1>

          <div styleName="publish__info">
            <div styleName="name">{displayName}</div>
            <div styleName="preview" style={previewStyles}>
              {!photoCount && <i className="fal fa-camera-retro" />}
            </div>
            <div styleName="meta">{objectTypeInfo}</div>
          </div>

          <div styleName="publish__list">
            {this.renderListItem(
              "publishPhotos",
              photoCount,
              route(OBJECTTYPESROUTES.EDIT_MARKETING_PHOTOS.URI, { id })
            )}
            {this.renderListItem(
              "publishVideos",
              videoCount,
              route(OBJECTTYPESROUTES.EDIT_MARKETING_VIDEOS.URI, { id })
            )}
          </div>

          <div styleName="submit">
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.props.publishing}
              onClick={() => this.publishHandler()}
            >
              <ResourceText resourceKey="saveObjectType" />
            </button>

            {/* <button
              type="button"
              className="btn btn-success"
              disabled={this.props.publishing}
              onClick={() => this.publishHandler(true)}
            >
              <ResourceText resourceKey="saveAndCreateNewObjectType" />
            </button> */}
          </div>
        </div>
      </div>
    );
  }

  private renderListItem(
    label: string,
    count: number,
    editRoute: string
  ): React.ReactElement<HTMLDivElement> {
    const iconType = classNames(
      "fal",
      "fa-fw",
      count === 0 ? "fa-exclamation-triangle" : "fa-check-circle"
    );
    const iconStyle = classNames("listitem__icon", { warn: count === 0 });
    const btnStyle = classNames(
      "btn",
      count === 0 ? "btn-warning" : "btn-blank"
    );

    return (
      <div styleName="listitem">
        <div styleName={iconStyle}>
          <i className={iconType} />
        </div>
        <div styleName="listitem__label">
          <ResourceText resourceKey={label} values={{ count }} />
        </div>
        <div styleName="listitem__action">
          <button
            type="button"
            className={btnStyle}
            onClick={() => this.props.navigate(editRoute)}
            disabled={this.props.publishing}
          >
            <ResourceText resourceKey="adjust" />
          </button>
        </div>
      </div>
    );
  }

  private publishHandler(createNew?: boolean) {
    if (this.props.publishing) return;
    this.props.publishType(this.props.currentType, createNew);
  }
}
