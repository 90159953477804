import { AssignmentSnapShot } from "@haywork/api/kolibri";

export enum keyBoardActions {
  AddKeys = "KeyBoard.AddKeys",
  SetStatus = "KeyBoard.SetStatus"
}

interface AddKeys {
  type: keyBoardActions.AddKeys;
  assignments: AssignmentSnapShot[];
}
const addKeys = (assignments: AssignmentSnapShot[]) => ({
  type: keyBoardActions.AddKeys,
  assignments
});

interface SetStatus {
  type: keyBoardActions.SetStatus;
  assignmentsStatus: string;
}
const setStatus = (assignmentsStatus: string) => ({
  type: keyBoardActions.SetStatus,
  assignmentsStatus
});

export type KeyBoardActions = AddKeys | SetStatus;
export const Actions = {
  addKeys,
  setStatus
};
