import {
  DifferentRegistration,
  RealEstatePropertySearchItem,
} from "@haywork/api/mls";
import { MLSROUTES } from "@haywork/constants";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import head from "lodash-es/head";
import sortBy from "lodash-es/sortBy";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import * as CSSModules from "react-css-modules";
import { PublicationHistoryContainerProps } from "./container";
import Publication from "./publication";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type PublicationHistoryProps = {
  visible: boolean;
  data: DifferentRegistration[];
  onCloseHandler: () => void;
};

type Props = PublicationHistoryProps & PublicationHistoryContainerProps;

export const PublicationHistoryComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ onCloseHandler, navigate, visible, data, getPropertiesById }) => {
      const [loading, setLoading] = useState(false);
      const [properties, setProperties] = useState(
        [] as RealEstatePropertySearchItem[]
      );

      const handleRegistrationClick = useCallback(
        (property: RealEstatePropertySearchItem) => {
          const { appClientKeys, bundleId: id } = property;
          const source = head(appClientKeys);
          const path = route(MLSROUTES.DETAIL.URI, { id, source });
          navigate(path);
        },
        [navigate]
      );

      const loadProperties = useCallback(async () => {
        try {
          setLoading(true);
          const ids = (data || []).map((d) => d.bundleId);
          let properties = [] as RealEstatePropertySearchItem[];

          if (!!ids.length) {
            properties = await getPropertiesById(data.map((d) => d.bundleId));
            properties = sortBy(
              properties || [],
              (property) =>
                property?.transaction?.transactionDateTime ||
                property?.propertyInfo?.mandateDate
            ).reverse();
          }

          setProperties(properties);
        } finally {
          setLoading(false);
        }
      }, [data, setLoading, setProperties, getPropertiesById]);

      useEffect(() => {
        loadProperties();
      }, [loadProperties]);

      return (
        <>
          <Modal visible={visible} onClose={onCloseHandler}>
            <ModalHeader
              title="mls.publicationHistory.modal.title"
              titleValues={{ count: data.length }}
              close
            />
            <ModalBody noPadding>
              {!!loading && (
                <div styleName="loader">
                  <Ui.Loaders.List />
                </div>
              )}

              {properties.map((property) => (
                <Publication
                  property={property}
                  onHandleRegistrationClick={handleRegistrationClick}
                  key={property.bundleId}
                />
              ))}
            </ModalBody>
          </Modal>
        </>
      );
    }
  )
);
