import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import {
  SearchAssignment,
  MatchedPropertySnapShot,
  MatchSearchMailHistoryResponse,
  MailHistorySnapShot,
  RealEstateAgency
} from "@haywork/api/kolibri";

import * as ActionType from "./real-estate-agency.types";
import { FolderTreeEntityType } from "@haywork/middleware";

export interface RealEstateAgencyState {
  realEstateAgency: RealEstateAgency;
  realEstateAgencyStatus: string;
}

const INITIAL_STATE: RealEstateAgencyState = {
  realEstateAgency: null,
  realEstateAgencyStatus: REQUEST.IDLE
};

export const realEstateAgencyReducer = (
  state: RealEstateAgencyState = INITIAL_STATE,
  action: Action
): RealEstateAgencyState => {
  switch (action.type) {
    case REDUX.REALESTATE_AGENCY.SET_REALESTATE_AGENCY: {
      const { realEstateAgency } = <ActionType.SingleRealEstateAgency>action;
      return {
        ...state,
        realEstateAgency,
        realEstateAgencyStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.REALESTATE_AGENCY.SET_REALESTATE_AGENCY_STATUS: {
      const { realEstateAgencyStatus } = <ActionType.RealEstateAgencyStatus>(
        action
      );
      return { ...state, realEstateAgencyStatus };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityType, entityId } = <any>action;
      if (
        entityType === FolderTreeEntityType.RealEstateAgency &&
        !!state.realEstateAgency &&
        state.realEstateAgency.id === entityId
      ) {
        return {
          ...state,
          realEstateAgency: {
            ...state.realEstateAgency,
            linkedFolderTree: {
              id
            }
          }
        };
      } else {
        return state;
      }
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
