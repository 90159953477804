import {
  DraftsClient,
  FolderCategory,
  UploadResponse,
  EmailAddress,
  ShareType,
} from "@haywork/api/mail";
import { COUNTS, EMAILROUTES, MAINROUTES } from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EditableActions } from "@haywork/stores";
import { EmailActionsV2, EmailDraft } from "@haywork/stores/email-v2";
import { RouteUtil, UploadUtil } from "@haywork/util";
import { EmailUtil } from "@haywork/util/email-v2";
import { push } from "connected-react-router";
import { v4 as uuid } from "uuid";
import { Dispatch } from "../";
import { EmailRequestState } from "@haywork/stores/email-v2/main";
import { LinkedRelation } from "@haywork/api/kolibri";
import { ExtendedEmailDraft } from "@haywork/util/email";
import head from "lodash-es/head";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getDrafts = (skip: number, accountId: string, folderId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      if (!accountId || !folderId) return [];
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(
          EmailRequestState.FetchingDrafts
        )
      );

      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { filters } = state.emailV2.main;
      const client = new DraftsClient(emailHost);

      const response = await parseRequest.response(
        client.search(
          {
            accountId,
            skip,
            take: COUNTS.EMAIL_MESSAGES,
            ...filters,
          },
          realEstateAgencyId
        )
      );

      if (!response) throw new Error("E-mail drafts request failed");
      const { resultCount, results } = response;
      const { drafts: refs } = state.emailV2.drafts;

      const drafts = (results || []).map((draft) =>
        EmailUtil.mapDraftToEmailDraft(draft, accountId, folderId, refs)
      );

      if (resultCount < COUNTS.EMAIL_MESSAGES) {
        dispatch(
          EmailActionsV2.Folders.setFolderTotalResults(
            folderId,
            skip + resultCount
          )
        );
      }

      dispatch(EmailActionsV2.Drafts.appendDrafts(drafts));
      dispatch(
        EmailActionsV2.Main.setEmailRequestState(EmailRequestState.Idle)
      );

      return drafts;
    } catch (error) {
      throw error;
    }
  };
};

const createNewEmailWithAttachments = (
  attachments: UploadResponse[],
  to?: LinkedRelation[],
  subject?: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { folders } = state.emailV2.folders;
    const { currentAccount: mainCurrentAccount } = state.emailV2.main;
    const accounts = state.emailV2.accounts.accounts || [];
    const shares = state.emailV2.shares.shares || [];
    const settings = state.emailV2.main.personSettings;
    const sendAccounts = accounts.filter((account) => {
      const restrictions = shares.find(
        (share) => share.accountId === account.id
      );
      return ![ShareType.Read].includes(restrictions?.shareType);
    });

    const account = (sendAccounts || []).find(
      (account) => account.id === settings?.defaultAccountId
    );
    const currentAccount =
      account?.id || head(sendAccounts)?.id || mainCurrentAccount || "";

    const id = uuid();

    let folder = folders.find(
      (folder) =>
        folder.accountId === currentAccount &&
        folder.category === FolderCategory.Drafts
    );
    if (!folder) {
      folder = folders.find((folder) => folder.accountId === currentAccount);
    }

    const componentState: EmailDraft = {
      to: to ? to : [],
      files: (attachments || []).map((file) =>
        UploadUtil.mapMailUploadResponseToEmailFile(file, true)
      ),
      temporaryId: id,
      accountId: currentAccount,
      folderId: folder.id,
      subject: subject || "",
      searchString: "",
      sendRequested: false,
    };
    const path = route(EMAILROUTES.CREATE.URI, { id });

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
        entityId: id,
      })
    );

    dispatch(push(path));
  };
};

const openDraft = (draft: EmailDraft) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new DraftsClient(emailHost);
      const { drafts } = state.emailV2.drafts;

      const response = await parseRequest.response(
        client
          .read(draft.accountId, draft.id, realEstateAgencyId)
          .then((response) => response.draft)
      );
      if (!response) return;
      const componentState = EmailUtil.mapDraftToEmailDraft(
        response,
        draft.accountId,
        draft.folderId,
        drafts
      );

      let { id } = componentState;
      id = id || uuid();
      const path = route(EMAILROUTES.CREATE.URI, { id });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAIL.ICON,
          componentState,
          path,
          title: componentState.subject || "...",
          confirm: {
            title: { key: "saveEmailConfirmTitle" },
            body: { key: "saveEmailConfirmBody" },
          },
          entityType: null,
          entityId: id,
        })
      );

      dispatch(push(path));
    } catch (error) {
      throw error;
    }
  };
};

const deleteDraft = (draft: EmailDraft) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new DraftsClient(emailHost);

      dispatch(EmailActionsV2.Drafts.deleteDraft(draft.id));
      await parseRequest.response(
        client.delete(draft.accountId, draft.id, realEstateAgencyId)
      );

      dispatch(getDrafts(0, draft.accountId, draft.folderId));
    } catch (error) {
      throw error;
    }
  };
};

const createNewDraft = (
  to?: EmailAddress[],
  subject?: string,
  body?: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentAccount } = state.emailV2.main;
    const { folders } = state.emailV2.folders;
    const id = uuid();

    let folder = folders.find(
      (folder) =>
        folder.accountId === currentAccount &&
        folder.category === FolderCategory.Drafts
    );
    if (!folder) {
      folder = folders.find((folder) => folder.accountId === currentAccount);
    }

    const componentState: EmailDraft = {
      to: to || [],
      temporaryId: id,
      accountId: currentAccount,
      folderId: folder.id,
      subject,
      searchString: "",
      body,
      sendRequested: false,
    };
    const path = route(EMAILROUTES.CREATE.URI, { id });

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
        entityId: id,
      })
    );

    dispatch(push(path));
  };
};

const refreshDraftFolder = (accountId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const account = state.emailV2.accounts.accounts.find(
      (account) => account.id === accountId
    );
    if (!account) {
      return;
    }

    const draftFolder = state.emailV2.folders.folders.find(
      (folder) =>
        folder.accountId === accountId &&
        folder.category === FolderCategory.Drafts
    );
    if (!draftFolder) {
      return;
    }

    dispatch(getDrafts(0, account.id, draftFolder.id));
    return;
  };
};

const refreshDraft = (accountId: string, draftId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const editableDrafts = (state.editable.states || []).filter(
        (state) => state.entityType === null
      );
      const reference = editableDrafts.find(
        (draft) => draft.entityId === draftId
      );

      if (!reference) {
        return;
      }

      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new DraftsClient(emailHost);
      const path = route(EMAILROUTES.DRAFT.URI, { id: draftId });
      const draft = await parseRequest.response(
        client
          .read(accountId, draftId, realEstateAgencyId)
          .then((response) => response.draft),
        ApiType.Email,
        { displayError: false }
      );

      const componentState: ExtendedEmailDraft = {
        ...reference.componentState,
        sendRequested:
          draft?.sendRequested ||
          reference.componentState?.sendRequested ||
          false,
      };

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getDrafts,
  createNewEmailWithAttachments,
  openDraft,
  deleteDraft,
  createNewDraft,
  refreshDraftFolder,
  refreshDraft,
};
