import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  PresenceWrapperComponent,
  PresenceWrapperProps,
} from "./presence-wrapper";
import { blockDoubleView } from "./selector";

type StateProps = {
  employeeId: string;
  blockDoubleView: boolean;
  features: string[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  PresenceWrapperProps,
  AppState
> = (state, ownProps) => {
  const employeeId = state.account.employee?.id;
  const { features } = state.appSettings;
  const { entityId, entityType, inEditMode } = ownProps;

  return {
    employeeId,
    blockDoubleView: blockDoubleView({ entityId, entityType, inEditMode })(
      state
    ),
    features,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PresenceWrapperProps
> = (dispatch) => ({});

export type PresenceWrapperContainerProps = StateProps & DispatchProps;
export const PresenceWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PresenceWrapperComponent);
