import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  ObjectTypesDetailDossierComponent,
  ObjectTypesDetailDossierComponentProps
} from "@haywork/modules/object-types";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import { fullyBakedDisplayName } from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  linkedFolderTreeId: string | null | undefined;
  displayName: string;
}
interface DispatchProps {
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ObjectTypesDetailDossierComponentProps,
  AppState
> = (state) => {
  const { currentType } = state.project.types;
  const { linkedFolderTree } = currentType;

  return {
    currentType,
    linkedFolderTreeId: get(linkedFolderTree, "id"),
    displayName: fullyBakedDisplayName(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ObjectTypesDetailDossierComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type ObjectTypesDetailDossierContainerProps = StateProps & DispatchProps;
export const ObjectTypesDetailDossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectTypesDetailDossierComponent);
