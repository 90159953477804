import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";
import { AgendaItem } from "@haywork/api/kolibri";

export enum SchedulerAction {
  Confirm = "Confirm",
  Cancel = "Cancel",
  CreateAppointment = "CreateAppointment",
  Remove = "Remove",
  Print = "Print",
  CreateTask = "CreateTask",
}

type Props = {
  agendaItem: AgendaItem;
  loading: boolean;
  onClick: (action: SchedulerAction) => void;
};

export const SchedulerDetailActionsComponent: FC<Props> = memo(
  ({ agendaItem, loading, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const { isCanceled, isConfirmed, isRecurringEvent, isNew } = agendaItem;
    const showConfirm = !isConfirmed && !isRecurringEvent;
    const showCancel = !isNew && !isCanceled && !isRecurringEvent;
    const showCreateAppointment = isCanceled;
    const showRemove = !isNew;

    const onClickHandler = (action: SchedulerAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={loading ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="agendaItem.actions.label.actions" />

          {!isNew && (
            <Action
              label="agendaItem.actions.label.createTask"
              icon={
                <Icon
                  light
                  name="tasks"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SchedulerAction.CreateTask)}
            />
          )}
          <Action
            label="agendaItem.actions.label.print"
            icon={
              <Icon
                light
                name="print"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(SchedulerAction.Print)}
          />

          {showConfirm && !agendaItem.readOnly ? (
            <Action
              label="agendaItem.actions.label.confirm"
              icon={
                <Icon
                  light
                  name="calendar-check"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SchedulerAction.Confirm)}
            />
          ) : null}
          {showCancel && !agendaItem.readOnly ? (
            <Action
              label="agendaItem.actions.label.cancel"
              icon={
                <Icon
                  light
                  name="calendar-times"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SchedulerAction.Cancel)}
            />
          ) : null}
          {showCreateAppointment && !agendaItem.readOnly ? (
            <Action
              label="agendaItem.actions.label.createAppointment"
              icon={
                <Icon
                  light
                  name="calendar-plus"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SchedulerAction.CreateAppointment)}
            />
          ) : null}
          {showRemove && !agendaItem.readOnly ? (
            <Action
              label="agendaItem.actions.label.remove"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SchedulerAction.Remove)}
            />
          ) : null}
        </ActionList>
      </>
    );
  }
);

export default SchedulerDetailActionsComponent;
