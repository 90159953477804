import { intlContext } from "@haywork/app";
import { EditableHocProps } from "@haywork/modules/editable";
import { NotesList } from "@haywork/modules/notes-v3";
import * as React from "react";
import { FC, memo, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import { WithIntlProps } from "react-intl";
import { AcquisitionDetailScreenContainerProps } from "./detail.container";
import { AcquisitionsDetailRouting } from "./detail.routing";

const styles = require("./detail.scss");

export type AcquisitionDetailScreenProps = {};
type Props = AcquisitionDetailScreenProps &
  AcquisitionDetailScreenContainerProps &
  EditableHocProps;

export const AcquisitionDetailScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisition, setTabTitle }) => {
      const { id, isNew, publicReference } = acquisition;

      useEffect(() => {
        setTabTitle(
          publicReference ||
            intlContext.formatMessage({
              id: "acquisition.assignment.emptyTabTitle",
              defaultMessage: "acquisition.assignment.emptyTabTitle",
            })
        );
      }, []);

      return (
        <div styleName="detail">
          {!isNew && <NotesList parentId={id} />}
          <AcquisitionsDetailRouting id={id} />
        </div>
      );
    }
  )
);
