import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { ConfirmComponent } from "@haywork/modules/shared";
import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import Actions, { AcquisitionAssignmentEditAction } from "./actions";
import { AcquisitionEditScreenContainerProps } from "./edit.container";
import { AcquisitionEditRouting } from "./edit.routing";
import Notes from "@haywork/modules/notes-v3";
import ExternalChanges from "@haywork/components/external-changes";
import { RootEntityType } from "@haywork/api/event-center";
import Presence from "@haywork/components/ui/presence";

export type AcquisitionEditScreenProps = {};
type Props = AcquisitionEditScreenProps & AcquisitionEditScreenContainerProps;

const styles = require("./edit.scss");

export const AcquisitionEditScreen: FC<Props> = memo(
  CSSModules(styles, {
    allowMultiple: true
  })(
    ({
      acquisition,
      loading,
      saveAcquisition,
      hasChanges,
      backToDashboard,
      reloadAcquisitionAssignment
    }) => {
      const { isNew, id } = acquisition;
      const [
        backToDashboardConfirmVisible,
        setBackToDashboardConfirmVisible
      ] = useState(false);

      const onSaveClickHandler = useCallback(
        (close: boolean = false) => {
          saveAcquisition(acquisition, close);
        },
        [acquisition]
      );

      const onActionClickHandler = useCallback(
        (action: AcquisitionAssignmentEditAction) => {
          switch (action) {
            case AcquisitionAssignmentEditAction.SaveAndClose: {
              onSaveClickHandler(true);
              break;
            }
            case AcquisitionAssignmentEditAction.BackToDashboard: {
              if (hasChanges) {
                setBackToDashboardConfirmVisible(true);
                return;
              }
              backToDashboard();
            }
            default: {
              return;
            }
          }
        },
        [hasChanges, onSaveClickHandler, setBackToDashboardConfirmVisible]
      );

      const onBackToDashboard = useCallback(() => {
        setBackToDashboardConfirmVisible(false);
        backToDashboard();
      }, [setBackToDashboardConfirmVisible]);

      return (
        <div styleName="edit">
          <PageHeader
            title={"acquisitions.edit.title.new"}
            actions={
              <>
                <Presence
                  entityId={id}
                  entityType={RootEntityType.AcquisitionAssignment}
                />
                {!isNew && <Notes />}
                <Button
                  label="acquisitionAssignment.edit.actions.label.saveAndClose"
                  category="success"
                  disabled={false}
                  onClick={() => onSaveClickHandler(true)}
                />
                {/* {!isNew && (
                  <Actions
                    loading={loading}
                    isNew={isNew}
                    onClick={onActionClickHandler}
                  />
                )} */}
              </>
            }
          />

          <ExternalChanges
            entityId={id}
            entityType={RootEntityType.AcquisitionAssignment}
            onReloadEntity={reloadAcquisitionAssignment}
            onBackToDashboard={backToDashboard}
          />

          <div styleName="edit__body">
            <AcquisitionEditRouting id={acquisition.id} />
          </div>

          <ConfirmComponent
            visible={backToDashboardConfirmVisible}
            titleResourceKey="acquisitionAssignment.backToDashboard.title"
            bodyResourceKey="acquisitionAssignment.backToDashboard.body"
            onClose={() => setBackToDashboardConfirmVisible(false)}
            onConfirm={onBackToDashboard}
          />
        </div>
      );
    }
  )
);
