import * as React from "react";
import * as CSSModules from "react-css-modules";

import { LinkedPublication } from "@haywork/api/event-center";

const styles = require("./body.component.scss");

export interface PublicationBodyProps {
  linkedPublications: LinkedPublication[];
}

export const PublicationBodyComponent = CSSModules(styles, { allowMultiple: true })((props: PublicationBodyProps) => {
  let linkedPublications = props.linkedPublications || [];
  linkedPublications = linkedPublications.filter((publication) => !!publication.displayName);

  if (!linkedPublications.length) return null;

  return (
    <div styleName="body">
      {linkedPublications.map((publication, idx) => (
        <div styleName="row" key={idx}>
          <i className="fa fa-exclamation-triangle" />
          {publication.displayName}
        </div>
      ))}
    </div>
  );
});
