import {
  LinkedAssignment,
  LinkedRelation,
  LinkedPartner,
} from "@haywork/api/kolibri";
import * as React from "react";
import { memo, useState, useRef } from "react";
import * as CSSModules from "react-css-modules";
import { DossierLinkedEntitiesContainerProps } from "./linked-entities.container";
import Pill from "./pill";
import EntityList from "../entity-list";

const styles = require("./style.scss");

export interface DossierLinkedEntitiesComponentProps {
  linkedEntities: (LinkedAssignment | LinkedRelation | LinkedPartner)[];
}
type Props = DossierLinkedEntitiesComponentProps &
  DossierLinkedEntitiesContainerProps;

export const DossierLinkedEntitiesComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ linkedEntities, navigate }: Props) => {
      const [entityListVisible, setEntityListVisible] = useState(false);
      const countRef = useRef(null);

      if (!linkedEntities || !linkedEntities.length) return null;
      const initialyVisible = linkedEntities.slice(0, 2);
      const otherCount = linkedEntities.length - 2;

      return (
        <div styleName="linked-entities">
          {initialyVisible.map((entity) => (
            <Pill entity={entity} onNavigate={navigate} key={entity.id} />
          ))}
          {otherCount > 0 && (
            <>
              <div
                styleName="pill"
                ref={countRef}
                onClick={() => setEntityListVisible(true)}
              >
                +{otherCount}
              </div>
              <EntityList
                visible={entityListVisible}
                parent={countRef}
                onClose={() => setEntityListVisible(false)}
              >
                {linkedEntities.map((entity) => (
                  <Pill
                    entity={entity}
                    onNavigate={navigate}
                    key={entity.id}
                    forList
                  />
                ))}
              </EntityList>
            </>
          )}
        </div>
      );
    }
  )
);
