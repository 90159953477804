import { EmailAddress } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, ZohoActions } from "@haywork/stores";
import first from "lodash-es/first";
import { connect, MapStateToProps } from "react-redux";
import { ZohoAsapComponent, ZohoAsapComponentProps } from "./asap";

interface StatepProps {
  asapStatus: RequestStatus;
  displayName?: string;
  emailAddress?: EmailAddress;
}
interface DispatchProps {
  setStatus: (status: RequestStatus) => void;
  toggleVisibility: (visible: boolean) => void;
}

const mapStateToProps: MapStateToProps<
  StatepProps,
  ZohoAsapComponentProps,
  AppState
> = (state) => {
  const { asapStatus } = state.zoho.asap;
  const { displayName, emailAddresses } = state.account.employee;

  return { asapStatus, displayName, emailAddress: first(emailAddresses || []) };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ZohoAsapComponentProps
> = (dispatch) => ({
  setStatus: (status: RequestStatus) =>
    dispatch(ZohoActions.Asap.setStatus(status)),
  toggleVisibility: (visible: boolean) =>
    dispatch(ZohoActions.Asap.toggleVisibility(visible))
});

export type ZohoAsapContainerProps = StatepProps & DispatchProps;
export const ZohoAsapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ZohoAsapComponent);
