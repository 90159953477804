import { WidgetEntityType } from "@haywork/api/authorization";
import { AssignmentType, TimelineActionType } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ProjectDetailTimelineContainerProps } from "@haywork/modules/project/containers/detail-timeline.container";
import TimelineComponent from "@haywork/modules/shared/components/timeline";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./detail-timeline.component.scss");

export interface ProjectDetailTimelineComponentProps {}
interface State {}
type Props = ProjectDetailTimelineComponentProps &
  ProjectDetailTimelineContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailTimelineComponent extends React.Component<
  Props,
  State
> {
  public componentDidMount() {
    this.props.getTimelineEvents(this.props.projectAssignment.id);
    this.props.getInitializedWidgets(
      this.props.projectAssignment.id,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    const { titleText, id } = this.props.projectAssignment;
    const displayName =
      titleText && titleText.length > 0 ? titleText[0].text : "";

    return (
      <React.Fragment>
        <ErrorBoundary>
          <TimelineComponent
            name={displayName}
            timelineEvents={this.props.events}
            timeLineStatistics={this.props.timelineStatistics}
            relationTimelineStatus={this.props.timelineStatus}
            onTimelineActionTypeChange={(actionType) =>
              this.props.getTimelineEvents(
                this.props.projectAssignment.id,
                actionType
              )
            }
            onScrollEndOfTimeline={(actionType) =>
              this.appendTimelineEvents(actionType)
            }
            emptyStateTitle="emptyStateNoTimelineTitle"
            emptyStateText="emptyStateNoTimelineTextAssignment"
            mainEmptyStateTitle="emptyStateNoTimelineTitle"
            mainEmptyStateText="emptyStateNoTimelineTextAssignment"
            taskCategories={this.props.taskCategories}
            agendaItemCategories={this.props.agendaItemCategories}
            parentId={this.props.projectAssignment.id}
            authorizationWidgetEntityType={WidgetEntityType.ProjectAssignment}
            linkedAssignment={{
              id,
              displayName,
              typeOfAssignment: AssignmentType.Project
            }}
            onRefresh={() =>
              this.props.getTimelineEvents(this.props.projectAssignment.id)
            }
            addNotes={true}
            canLoadMore={this.props.canLoadMore}
          />
        </ErrorBoundary>
      </React.Fragment>
    );
  }

  private appendTimelineEvents(timelineActionTypes: TimelineActionType[]) {
    if (
      !this.props.canLoadMore ||
      this.props.timelineStatus === REQUEST.PENDING
    )
      return;
    this.props.getTimelineEvents(
      this.props.projectAssignment.id,
      timelineActionTypes,
      false
    );
  }
}
