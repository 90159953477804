import {
  AccountShareOrderBy,
  AccountSharesClient,
  SortOrder,
} from "@haywork/api/mail";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();

const getAccountShares = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: personId } = state.account.employee;
      const client = new AccountSharesClient(emailHost);

      const shares = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 100,
              orderBy: AccountShareOrderBy.CreationDate,
              order: SortOrder.Ascending,
              personId,
            },
            realEstateAgencyId
          )
          .then((response) => response.results || [])
      );

      dispatch(EmailActionsV2.Shares.setShares(shares));

      return shares;
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getAccountShares,
};
