import * as React from "react";
import { LinkedRealEstateAgency } from "@haywork/api/kolibri";
import { COUNTS } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  linkedRealEstateAgencies: LinkedRealEstateAgency[];
  realEstateAgencyId: string;
  onRealestateAgencyClick: (id: string) => void;
}
interface State {
  expanded: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class LayoutBackofficeListComponent extends React.Component<
  Props,
  State
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.onRealestateAgencyClick = this.onRealestateAgencyClick.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    if (
      !this.props.linkedRealEstateAgencies ||
      this.props.linkedRealEstateAgencies.length <
        COUNTS.LINKED_REALESTATE_AGENCIES
    )
      return null;

    const totalCount = this.props.linkedRealEstateAgencies.length;

    return (
      <div styleName="backoffice">
        <div
          styleName="backoffice__trigger"
          onClick={() =>
            this.setState(({ expanded }) => ({ expanded: !expanded }))
          }
        >
          <ResourceText resourceKey="showLinkedRealestateAgencies" />
          <span styleName="total-count">{totalCount}</span>
          <i className="fal fa-chevron-down" />
        </div>
        <div
          styleName={classNames("backoffice__list", {
            expanded: this.state.expanded,
          })}
          ref={this.bindRef}
        >
          <div styleName="title">
            <i className="fal fa-exchange" />
            <ResourceText resourceKey="switchRealEstateAgency" />
          </div>
          <div styleName="list">
            {this.props.linkedRealEstateAgencies.map((agency) => (
              <div
                styleName={classNames("realestateAgency", {
                  current: this.props.realEstateAgencyId === agency.id,
                })}
                key={agency.id}
                onClick={() => this.onRealestateAgencyClick(agency.id)}
              >
                {agency.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  private bindRef(ref: HTMLDivElement) {
    if (!this.ref && !!ref) {
      this.ref = ref;
    }
  }

  private onClickOutsideHandler(event: any) {
    if (!!this.ref && !this.ref.contains(event.target) && this.state.expanded)
      this.setState({ expanded: false });
  }

  private onRealestateAgencyClick(id: string) {
    if (id === this.props.realEstateAgencyId) {
      return this.setState({ expanded: false });
    }
    this.props.onRealestateAgencyClick(id);
    this.setState({ expanded: false });
  }
}
