import * as React from "react";
import { FC, memo, useEffect, useRef, useState, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import { GeoLocation } from "@haywork/api/kolibri";
import get from "lodash-es/get";

const styles = require("./style.scss");

type Props = {
  geoLocation?: GeoLocation;
  initialZoom?: number;
  onGeoLocationChange?: (geoLocation: GeoLocation) => void;
};
export const MapComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ initialZoom, geoLocation, onGeoLocationChange }) => {
      const ref = useRef<HTMLDivElement>(null);
      const [map, setMap] = useState<google.maps.Map>(null);
      const [lat, setLat] = useState(get(geoLocation, "latitude", 51.984737));
      const [lng, setLng] = useState(get(geoLocation, "longitude", 5.907681));
      const [zoom, setZoom] = useState(initialZoom || 15);
      const [mapTypeId, setMapTypeId] = useState(google.maps.MapTypeId.ROADMAP);
      const [marker, setMarker] = useState<google.maps.Marker>(null);

      useEffect(() => {
        if (!!geoLocation) {
          const { latitude, longitude } = geoLocation;
          setLat(latitude);
          setLng(longitude);
        }
      }, [geoLocation]);

      useEffect(() => {
        if (!map || !lat || !lng) return;
        const position = new google.maps.LatLng(lat, lng);

        if (!marker) {
          const markerObj = new google.maps.Marker({
            position,
            map,
            draggable: true,
          });

          setMarker(markerObj);
        } else {
          marker.setPosition(position);
        }
      }, [map, lat, lng, setMarker]);

      const dragendMarker = useCallback((e: google.maps.MouseEvent) => {
        const { lat, lng } = e.latLng;
        const latitude = lat();
        const longitude = lng();

        setLat(latitude);
        setLng(longitude);

        if (!!onGeoLocationChange) {
          onGeoLocationChange({ latitude, longitude });
        }
      }, []);

      useEffect(() => {
        if (!marker) return;
        const listener = google.maps.event.addListener(
          marker,
          "dragend",
          dragendMarker
        );

        return () => google.maps.event.removeListener(listener);
      }, [marker]);

      useEffect(() => {
        if (!map) return;
        map.panTo(new google.maps.LatLng(lat, lng));
      }, [lat, lng]);

      useEffect(() => {
        if (!ref.current) return;

        const map = new google.maps.Map(ref.current, {
          zoom,
          clickableIcons: false,
          center: new google.maps.LatLng(lat, lng),
          mapTypeId,
          disableDefaultUI: true,
        });

        setMap(map);
      }, [ref]);

      const setMapType = (mapTypeId: google.maps.MapTypeId) => {
        if (!map) return;
        setMapTypeId(mapTypeId);
      };

      useEffect(() => {
        if (!map) return;
        map.setMapTypeId(mapTypeId);
      }, [mapTypeId]);

      const incrementMapZoom = () => {
        if (!map || zoom === 20) return;
        setZoom(zoom + 1);
      };

      const decrementMapZoom = () => {
        if (!map || zoom === 1) return;
        setZoom(zoom - 1);
      };

      useEffect(() => {
        if (!map) return;
        map.setZoom(zoom);
      }, [zoom]);

      return (
        <div styleName="map">
          <div styleName="map__container" ref={ref} />

          <div styleName="map__controls type">
            <div styleName="map-type">
              <div
                styleName={classNames("button", {
                  active: mapTypeId === google.maps.MapTypeId.ROADMAP,
                })}
                onClick={() => setMapType(google.maps.MapTypeId.ROADMAP)}
              >
                <I18n value="address.mapType.roadmap" />
              </div>
              <div
                styleName={classNames("button", {
                  active: mapTypeId === google.maps.MapTypeId.SATELLITE,
                })}
                onClick={() => setMapType(google.maps.MapTypeId.SATELLITE)}
              >
                <I18n value="address.mapType.satellite" />
              </div>
            </div>
          </div>

          <div styleName="map__controls zoom">
            <div styleName="zoom-control">
              <div
                styleName={classNames("button", { disabled: zoom === 20 })}
                onClick={incrementMapZoom}
              >
                <Icon
                  name="plus"
                  size={20}
                  color={zoom === 20 ? Colors.Gray : Colors.TextBlack}
                  light
                />
              </div>
              <div
                styleName={classNames("button", { disabled: zoom === 1 })}
                onClick={decrementMapZoom}
              >
                <Icon
                  name="minus"
                  size={20}
                  color={zoom === 1 ? Colors.Gray : Colors.TextBlack}
                  light
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);
