import * as React from "react";
import { FC, memo, useState, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import {
  PublicationSnapShot,
  PublicationStatus,
  ListingType,
} from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import I18n from "@haywork/components/i18n";
import { PublicationContainerProps } from "./publication.container";
import { Hint } from "../../../hint/hint.component";

const styles = require("./style.scss");

export type PublicationComponentProps = {
  assignmentId: string;
  isAssignmentConcept: boolean;
  publication: PublicationSnapShot;
  listingType?: ListingType;
  onUpdatedPublication: () => void;
};
type Props = PublicationComponentProps & PublicationContainerProps;

export const PublicationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      publication,
      activate,
      deactivate,
      onUpdatedPublication,
      isAssignmentConcept,
      assignmentId,
      listingType,
    }) => {
      const {
        mediaPartnerName,
        id,
        mediaPartnerId,
        isAutoPublish,
        publicationStatus,
      } = publication;
      const [switchValue, setSwitchValue] = useState(
        publicationStatus !== PublicationStatus.Inactive
      );

      const onChangeHandler = useCallback(async () => {
        const value = !switchValue;
        setSwitchValue(value);

        try {
          if (value) {
            await activate(assignmentId, mediaPartnerId);
          } else {
            await deactivate(assignmentId, mediaPartnerId);
          }
          onUpdatedPublication();
        } catch (error) {
          setSwitchValue(!value);
        }
      }, [
        switchValue,
        setSwitchValue,
        activate,
        deactivate,
        onUpdatedPublication,
      ]);

      const disabled = useMemo(() => !isAssignmentConcept && switchValue, [
        isAssignmentConcept,
        switchValue,
      ]);

      const isParariusAndParking = useMemo(
        () =>
          listingType === ListingType.Parking &&
          /pararius/i.test(mediaPartnerName),
        [mediaPartnerName, listingType]
      );

      const action = useMemo(() => {
        switch (true) {
          case isParariusAndParking: {
            return <I18n value="publishPublications.isParariusAndParking" />;
          }
          case isAutoPublish: {
            return <I18n value="publishPublications.isAutoPublish" />;
          }
          default: {
            return (
              <Hint message="publishPublications.hint" shouldRender={disabled}>
                <Input.Switch
                  name={id + mediaPartnerId}
                  on={true}
                  off={false}
                  value={switchValue}
                  onChange={onChangeHandler}
                  disabled={disabled}
                />
              </Hint>
            );
          }
        }
      }, [
        isAutoPublish,
        isParariusAndParking,
        disabled,
        id,
        mediaPartnerId,
        switchValue,
        onChangeHandler,
      ]);

      return (
        <div styleName="publication">
          <div styleName="name">{mediaPartnerName}</div>
          <div styleName="action">{action}</div>
        </div>
      );
    }
  )
);
