import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";

import {
  CountryOption,
  EmailAddressType,
  EmailAddressTypeOption,
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberTypeOption,
  RelationGroupSnapShot,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { RelationValues } from "./relation.component";

const styles = require("../new-entity.component.scss");

interface Props extends RelationValues {
  emailAddressTypes: EmailAddressTypeOption[];
  phoneNumberTypes: PhoneNumberTypeOption[];
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
  relationGroups: RelationGroupSnapShot[];
  onSubmit: (values: FormReturnValue) => void;
  form: (form: FormReference) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class CompanyFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;

  constructor(props) {
    super(props);

    const emailAddresses = !!this.props.email
      ? [{ address: this.props.email, type: EmailAddressType.Work }]
      : [];
    const phoneNumbers: PhoneNumber[] = [];
    if (!!this.props.telephone)
      phoneNumbers.push({
        type: PhoneNumberType.Work,
        number: this.props.telephone,
      });
    if (!!this.props.mobilePhone)
      phoneNumbers.push({
        type: PhoneNumberType.Mobile,
        number: this.props.mobilePhone,
      });

    this.formControls = {
      displayName: { value: this.props.name },
      linkedRelationGroups: { value: [] },
      webAddress: {
        value: this.props.webAddress || "",
        validators: [Validators.url()],
      },
      emailAddresses: { value: emailAddresses },
      phoneNumbers: { value: phoneNumbers },
      address: { value: this.props.address || "" },
    };

    this.phoneNumbersFormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Work },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Work },
    };
  }

  public render() {
    return (
      <Form
        name="create-simple-relation-company"
        formControls={this.formControls}
        form={this.props.form}
        onSubmit={this.props.onSubmit}
      >
        <h2 styleName="header">
          <ResourceText resourceKey="simpleRelationCompanyHeader" />
        </h2>

        <div className="form__row">
          <label htmlFor="displayName">
            <ResourceText resourceKey="simpleRelationCompanyName" />
          </label>
          <Input.Text
            name="displayName"
            placeholder="simpleRelationCompanyNamePlaceholder"
          />
        </div>

        <div className="form__row">
          <label htmlFor="linkedRelationGroups">
            <ResourceText resourceKey="simpleRelationLinkedRelationGroup" />
          </label>
          <Input.RelationGroupQueryV2
            name="linkedRelationGroups"
            placeholder="simpleRelationLinkedRelationGroupPlaceholder"
            relationGroups={this.props.relationGroups}
          />
        </div>

        <h2 styleName="header">
          <ResourceText resourceKey="simpleRelationContactHeader" />
        </h2>

        <div className="form__row">
          <label htmlFor="webAddress">
            <ResourceText resourceKey="website" />
          </label>
          <Input.Url
            name="webAddress"
            placeholder="simpleRelationWebAddressPlaceholder"
          />
        </div>

        <div className="form__row">
          <label>
            <ResourceText resourceKey="phoneNumbers" />
          </label>
          <Input.Array
            name="phoneNumbers"
            formControls={this.phoneNumbersFormControls}
            max={4}
            className="form__group stretch"
          >
            <div className="column" styleName="array__input">
              <Input.Text name="number" placeholder="phoneNumber" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="array__type">
              <Input.NewSelect
                name="type"
                values={this.props.phoneNumberTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </Input.Array>
        </div>

        <div className="form__row">
          <label>
            <ResourceText resourceKey="emailAddresses" />
          </label>
          <Input.Array
            name="emailAddresses"
            formControls={this.emailAddressesFormControls}
            max={4}
            className="form__group stretch"
          >
            <div className="column" styleName="array__input">
              <Input.Text name="address" placeholder="address" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="array__type">
              <Input.NewSelect
                name="type"
                values={this.props.emailAddressTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </Input.Array>
        </div>
        <div className="form__row">
          <label>
            <ResourceText resourceKey="visitAddress" />
          </label>
          <Input.LocationQueryV2
            name="address"
            countries={this.props.countries}
            countryIso2={this.props.countryIso2}
            culture={this.props.culture}
            showManualAddressOnAddress={true}
          />
        </div>
      </Form>
    );
  }
}
