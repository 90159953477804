import { ReviewSnapShot } from "@haywork/api/authorization";
import { Employee } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as nl2br from "nl2br";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";

const styles = require("./reviews.component.scss");

interface Props {
  review: ReviewSnapShot;
  employee: Employee;
  onEdit: (review: ReviewSnapShot) => void;
  onDelete: (review: ReviewSnapShot) => void;
}

export const Review: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })((props) => {
    const intl = useIntl();

    const passportPhotoUrl = props.review.linkedEmployee.passportPhotoUrl
      ? {
          backgroundImage: `url(${JSON.stringify(
            props.review.linkedEmployee.passportPhotoUrl
          )})`
        }
      : null;

    return (
      <div styleName="review">
        <div styleName="review__avatar" style={passportPhotoUrl} />
        <div styleName="review__body">
          <Ui.Score score={props.review.rate} />
          <div styleName="date">
            <ResourceText
              resourceKey="appXchangeReviewDate"
              values={{
                date: intl.formatDate(props.review.dateTimeCreated, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric"
                }),
                displayName: props.review.linkedEmployee.displayName
              }}
            />
          </div>
          <div
            styleName="description"
            dangerouslySetInnerHTML={{ __html: nl2br(props.review.reviewText) }}
          />
          {props.employee.id === props.review.linkedEmployee.id && (
            <div styleName="review__actions">
              <div
                styleName="action edit"
                onClick={() => props.onEdit(props.review)}
              >
                <i className="fal fa-pencil" />
              </div>
              <div
                styleName="action delete"
                onClick={() => props.onDelete(props.review)}
              >
                <i className="fal fa-times" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  })
);
