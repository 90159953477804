import {
  ActiveFilter,
  MergeRelation,
  SortOrder,
  TemplateDefinition,
  TemplateDefinitionCategoriesClient,
  TemplateDefinitionCategoryOrderByField,
  TemplateDefinitionCategorySnapShot,
  TemplateDefinitionCategoryType,
  TemplateDefinitionOrderByField,
  TemplateDefinitionsClient,
  TemplateDefinitionSnapShot,
} from "@haywork/api/kolibri";
import {
  CompaniesClient,
  DraftsClient,
  EmailAddress,
  FilesClient,
} from "@haywork/api/mail";
import {
  EMAILSIGNATURESROUTES,
  EMAILTEMPLATESROUTES,
  MAINROUTES,
  REQUEST,
  SETTINGSROUTES,
} from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EditableActions, EmailActions } from "@haywork/stores";
import { RouteUtil, FileUtil } from "@haywork/util";
import { Dispatch, EditableThunks } from "../";
import { push } from "connected-react-router";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { EmailThunk } from "../emailV2";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getTemplates = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setTemplatesStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const take = 100;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      await dispatch(getCategories());
      dispatch(getMergeFields());

      const request = {
        orderBy: TemplateDefinitionOrderByField.Name,
        filterByActive: ActiveFilter.ActiveOnly,
        order: SortOrder.Ascending,
        skip: 0,
        take,
      };

      const templatesResponse = await parseRequest.response(
        TemplateDefinitions.search(request, realEstateAgencyId)
      );

      const { totalResults, resultCount, results } = templatesResponse;
      let templates = results;

      if (totalResults > resultCount) {
        const diff = totalResults - resultCount;
        const count = Math.ceil(diff / take);
        const promises: Promise<TemplateDefinitionSnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            TemplateDefinitions.search(
              {
                ...request,
                skip: i * take,
              },
              realEstateAgencyId
            ).then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const snapshots = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <TemplateDefinitionSnapShot[]>[]);

        templates = [...templates, ...snapshots];
      }

      dispatch(EmailActions.Templates.setTemplates(templates));
    } catch (error) {
      dispatch(EmailActions.Templates.setTemplatesStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const setDefaultSignatureTemplateId = (
  defaultSignatureTemplateId: string,
  active: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    let { company } = state.email.settings;

    const Companies = new CompaniesClient(emailHost);

    try {
      company = {
        ...company,
        defaultSignatureTemplateId,
      };
      dispatch(EmailActions.Settings.setSettings({ company }));

      company = await parseRequest.response(
        Companies.save(
          {
            companyId: realEstateAgencyId,
            defaultSignatureTemplateId: active
              ? defaultSignatureTemplateId
              : "",
          },
          realEstateAgencyId
        ).then((response) => response.company)
      );

      dispatch(EmailActionsV2.Main.setCompanySettings(company));
    } catch (error) {
      throw error;
    }
  };
};

const getCategories = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const take = 100;

    const TemplateDefinitionCategories = new TemplateDefinitionCategoriesClient(
      host
    );

    try {
      const request = {
        orderBy: TemplateDefinitionCategoryOrderByField.Name,
        filterByActive: ActiveFilter.ActiveOnly,
        order: SortOrder.Ascending,
        skip: 0,
        take,
      };

      const categoriesResponse = await parseRequest.response(
        TemplateDefinitionCategories.search(request, realEstateAgencyId)
      );

      const { totalResults, resultCount, results } = categoriesResponse;
      let categories = results;

      if (totalResults > resultCount) {
        const diff = totalResults - resultCount;
        const count = Math.ceil(diff / take);
        const promises: Promise<TemplateDefinitionCategorySnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            TemplateDefinitionCategories.search(
              {
                ...request,
                skip: i * take,
              },
              realEstateAgencyId
            ).then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const snapshots = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, <TemplateDefinitionCategorySnapShot[]>[]);

        categories = [...categories, ...snapshots];
      }

      dispatch(EmailActions.Templates.setCategories(categories));
    } catch (error) {
      throw error;
    }
  };
};

const getMergeFields = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      const mergeFields = await parseRequest.response(
        TemplateDefinitions.searchMergeFields(
          {
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.mergeFields)
      );

      dispatch(EmailActions.Templates.setMergeFields(mergeFields));
    } catch (error) {
      throw error;
    }
  };
};

const defineNewSignature = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { categories } = state.emailV2.templates;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      const category = categories.find(
        (category) =>
          category.categoryType ===
          TemplateDefinitionCategoryType.EmailSignature
      );

      const templateDefinition = await parseRequest.response(
        TemplateDefinitions.defineNew(
          { templateDefinitionCategoryId: category.id },
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, {
        id: templateDefinition.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAILSIGNATURE.ICON,
          componentState: templateDefinition,
          path,
          title: "...",
          confirm: {
            title: { key: "emailSignatureConfirmTitle" },
            body: { key: "emailSignatureConfirmBody" },
          },
          entityType: null,
          entityId: templateDefinition.id,
        })
      );
      dispatch(push(path));
      dispatch(EmailActions.Templates.setSingle(templateDefinition));
    } catch (error) {
      throw error;
    }
  };
};

const defineNewTemplate = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { categories } = state.emailV2.templates;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      const category = categories.find(
        (category) =>
          category.categoryType === TemplateDefinitionCategoryType.EmailTemplate
      );

      const templateDefinition = await parseRequest.response(
        TemplateDefinitions.defineNew(
          { templateDefinitionCategoryId: category.id },
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
        id: templateDefinition.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.EMAILTEMPLATE.ICON,
          componentState: templateDefinition,
          path,
          title: "...",
          confirm: {
            title: { key: "emailTemplateConfirmTitle" },
            body: { key: "emailTemplateConfirmBody" },
          },
          entityType: null,
          entityId: templateDefinition.id,
        })
      );
      dispatch(push(path));
      dispatch(EmailActions.Templates.setSingle(templateDefinition));
    } catch (error) {
      throw error;
    }
  };
};

const readSignature = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, {
      id,
    });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    let componentState = null;

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAILSIGNATURE.ICON,
        componentState,
        path,
        title: "...",
        entityType: null,
        entityId: id,
      })
    );

    try {
      const templateDefinition = await parseRequest.response(
        TemplateDefinitions.read(id, realEstateAgencyId).then(
          (response) => response.templateDefinition
        )
      );

      componentState = templateDefinition;

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(EmailActions.Templates.setSingle(templateDefinition));
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const readTemplate = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
      id,
    });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    let componentState = null;

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAILTEMPLATE.ICON,
        componentState,
        path,
        title: "...",
        entityType: null,
        entityId: id,
      })
    );

    try {
      const templateDefinition = await parseRequest.response(
        TemplateDefinitions.read(id, realEstateAgencyId).then(
          (response) => response.templateDefinition
        )
      );

      componentState = templateDefinition;

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState,
          ignoreChanges: true,
        })
      );
      dispatch(EmailActions.Templates.setSingle(templateDefinition));
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveSignature = (templateDefinition: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, {
      id: templateDefinition.id,
    });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      await parseRequest.response(
        TemplateDefinitions.save({ templateDefinition }, realEstateAgencyId)
      );

      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.SUCCESS));
      dispatch(EditableThunks.remove(path));
      dispatch(EmailThunk.Templates.getInitialTemplatesState());
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveTemplate = (templateDefinition: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
      id: templateDefinition.id,
    });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      await parseRequest.response(
        TemplateDefinitions.save({ templateDefinition }, realEstateAgencyId)
      );

      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.SUCCESS));
      dispatch(EditableThunks.remove(path));
      dispatch(EmailThunk.Templates.getInitialTemplatesState());
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export interface TemplateMergeData {
  companyIds?: string[];
  officeIds?: string[];
  employeeIds?: string[];
  tenantIds?: MergeRelation[];
  purchaserIds?: MergeRelation[];
  notaryIds?: MergeRelation[];
  lessorIds?: MergeRelation[];
  vendorIds?: MergeRelation[];
  websiteVisitorIds?: MergeRelation[];
  houseHunterIds?: MergeRelation[];
  relationIds?: MergeRelation[];
  objectAssignmentIds?: string[];
  acquisitionObjectAssignmentIds?: string[];
  agendaItemIds?: string[];
}
const mergeTemplate = (
  template: TemplateDefinitionSnapShot,
  data: TemplateMergeData
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      const templateDefinition = parseRequest.response(
        TemplateDefinitions.merge(
          {
            templateDefinitionId: template.id,
            culture,
            ...data,
          },
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      return templateDefinition;
    } catch (error) {
      throw error;
    }
  };
};

const mergeTextTemplate = (
  data: TemplateMergeData,
  sectionKey: string,
  text: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { culture } = state.main;
    const client = new TemplateDefinitionsClient(host);

    try {
      const mergedSections = parseRequest.response(
        client
          .mergeText(
            {
              culture,
              ...data,
              sections: [
                {
                  sectionKey,
                  culture,
                  text,
                },
              ],
            },
            realEstateAgencyId
          )
          .then((response) => response.mergedSections)
      );

      return mergedSections;
    } catch (error) {
      throw error;
    }
  };
};

const uploadFromUri = (
  accountId: string,
  fileUri: string,
  fileName: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    const Files = new FilesClient(emailHost);

    try {
      const file = await parseRequest.response(
        Files.upload(
          {
            accountId,
            fileUri,
            fileName: FileUtil.sanatizeFilename(fileName),
          },
          realEstateAgencyId
        )
      );

      return file;
    } catch (error) {
      throw error;
    }
  };
};

const deleteTemplate = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      await parseRequest.response(
        TemplateDefinitions.delete(id, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteTemplate = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      await parseRequest.response(
        TemplateDefinitions.undelete({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const deleteTemplateFromState = (id: string, isDefaultTemplate: boolean) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    dispatch(EmailActions.Templates.deleteTemplate(id));
    dispatch(EmailThunk.Templates.getInitialTemplatesState());

    if (isDefaultTemplate) {
      let { company } = state.email.settings;

      const Companies = new CompaniesClient(emailHost);

      try {
        company = {
          ...company,
          defaultSignatureTemplateId: "",
        };
        dispatch(EmailActions.Settings.setSettings({ company }));

        company = await parseRequest.response(
          Companies.save(
            {
              companyId: realEstateAgencyId,
              defaultSignatureTemplateId: "",
            },
            realEstateAgencyId
          ).then((response) => response.company)
        );

        dispatch(EmailActions.Settings.setSettings({ company }));
      } catch (error) {
        throw error;
      }
    }
  };
};

const saveAndCloseSignature = (templateDefinition: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, {
      id: templateDefinition.id,
    });

    try {
      await dispatch(saveSignature(templateDefinition));
      dispatch(EditableThunks.remove(path));
      dispatch(EmailThunk.Templates.getInitialTemplatesState());
    } catch (error) {
      throw error;
    }
  };
};

const saveAndCloseTemplate = (templateDefinition: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, {
      id: templateDefinition.id,
    });

    try {
      await dispatch(saveTemplate(templateDefinition));
      dispatch(EditableThunks.remove(path));
      dispatch(EmailThunk.Templates.getInitialTemplatesState());
    } catch (error) {
      throw error;
    }
  };
};

const getTemplatePreview = (template: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILTEMPLATESROUTES.DETAIL.URI, { id: template.id });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      let templateDefinition = template;
      await parseRequest.response(
        TemplateDefinitions.save({ templateDefinition }, realEstateAgencyId)
      );

      templateDefinition = await parseRequest.response(
        TemplateDefinitions.read(
          templateDefinition.id,
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      const body = await parseRequest.response(
        TemplateDefinitions.merge(
          { templateDefinitionId: template.id },
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: templateDefinition,
          hasChanges: false,
        })
      );
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.SUCCESS));
      return body;
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const sendPreviewEmail = (
  body: string,
  to: EmailAddress[],
  subject: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const { currentAccount } = state.email.accounts;

    const Drafts = new DraftsClient(emailHost);

    try {
      const draft = await parseRequest.response(
        Drafts.saveDraft(
          {
            accountId: currentAccount.id,
            draft: { body, to, subject, sendRequested: false },
          },
          realEstateAgencyId
        ).then((response) => response.draft),
        ApiType.Email
      );

      return await parseRequest.response(
        Drafts.send(
          { accountId: currentAccount.id, id: draft.id },
          realEstateAgencyId
        ),
        ApiType.Email
      );
    } catch (error) {
      throw error;
    }
  };
};

const getSignaturePreview = (template: TemplateDefinition) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(EmailActions.Templates.setSingleStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const path = route(EMAILSIGNATURESROUTES.DETAIL.URI, { id: template.id });

    const TemplateDefinitions = new TemplateDefinitionsClient(host);

    try {
      let templateDefinition = template;
      await parseRequest.response(
        TemplateDefinitions.save({ templateDefinition }, realEstateAgencyId)
      );

      templateDefinition = await parseRequest.response(
        TemplateDefinitions.read(
          templateDefinition.id,
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      const body = await parseRequest.response(
        TemplateDefinitions.merge(
          { templateDefinitionId: template.id },
          realEstateAgencyId
        ).then((response) => response.templateDefinition)
      );

      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: templateDefinition,
          hasChanges: false,
          ignoreChanges: true,
        })
      );
      return body;
    } catch (error) {
      dispatch(EmailActions.Templates.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export const EmailTemplatesThunks = {
  getTemplates,
  setDefaultSignatureTemplateId,
  getCategories,
  getMergeFields,
  defineNewSignature,
  defineNewTemplate,
  readSignature,
  readTemplate,
  saveSignature,
  saveTemplate,
  mergeTemplate,
  uploadFromUri,
  deleteTemplate,
  unDeleteTemplate,
  deleteTemplateFromState,
  saveAndCloseSignature,
  saveAndCloseTemplate,
  getTemplatePreview,
  sendPreviewEmail,
  getSignaturePreview,
  mergeTextTemplate,
};
