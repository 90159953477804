import * as React from "react";
import * as CSSModules from "react-css-modules";

import { Provider } from "@haywork/api/mail";

const styles = require("./accounts.component.scss");

export interface Props {
  providers: Provider[];
  onClick: () => void;
}

export const EmptyState = CSSModules(styles, { allowMultiple: true })((props: Props) => {
  return <div styleName="empty-state">
    { props.providers.map((provider, idx) =>
      <div
        styleName="provider"
        onClick={ props.onClick }
        key={ idx }
      >
        <div
          styleName="provider__avatar"
          style={ { backgroundImage: (!!provider.logoUrl) ? `url(${ JSON.stringify(provider.logoUrl) })` : null } }
        />
        <div styleName="provider__label">
          { provider.displayName }
        </div>
      </div>
    )}
  </div>;
});
