import {
  AssignmentSnapShot,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  PhraseSnapShot,
  Phrase,
} from "@haywork/api/kolibri";

export class Converters {
  public static convertToAssignmentSnapshots(
    linkedAssignments: LinkedAssignment[],
    realEstateAgencyId: string
  ): AssignmentSnapShot[] {
    if (!linkedAssignments) {
      return null;
    }
    const assignmentSnapshots: AssignmentSnapShot[] = linkedAssignments.map(
      (linkedAssignment: LinkedAssignment) => {
        const assignmentSnapShot: AssignmentSnapShot = {
          realEstateAgencyId,
          assignmentPhase: linkedAssignment.assignmentPhase,
          isActive: linkedAssignment.isActive,
          realEstateGroup: linkedAssignment.realEstateGroup,
          availabilityStatus: linkedAssignment.availabilityStatus,
          typeOfAssignment: linkedAssignment.typeOfAssignment,
          forSale: linkedAssignment.forSale,
          forRent: linkedAssignment.forRent,
          id: linkedAssignment.id,
          displayName: linkedAssignment.displayName,
          photo1Url: linkedAssignment.photo1Url,
          locality: linkedAssignment.locality,
          streetNameAndNumber: linkedAssignment.streetNameAndNumber,
          dateTimeCreated: linkedAssignment.dateTimeCreated,
          dateTimeModified: linkedAssignment.dateTimeModified,
        };
        return assignmentSnapShot;
      }
    );
    return assignmentSnapshots;
  }

  public static convertToLinkedAssignments(
    assignmentSnapShots: AssignmentSnapShot[]
  ): LinkedAssignment[] {
    if (!assignmentSnapShots) {
      return null;
    }
    const linkedAssignments: LinkedAssignment[] = assignmentSnapShots.map(
      (assignmentSnapShot: AssignmentSnapShot) => {
        const linkedAssignment: LinkedAssignment = {
          assignmentPhase: assignmentSnapShot.assignmentPhase,
          isActive: assignmentSnapShot.isActive,
          realEstateGroup: assignmentSnapShot.realEstateGroup,
          availabilityStatus: assignmentSnapShot.availabilityStatus,
          typeOfAssignment: assignmentSnapShot.typeOfAssignment,
          forSale: assignmentSnapShot.forSale,
          forRent: assignmentSnapShot.forRent,
          id: assignmentSnapShot.id,
          displayName: assignmentSnapShot.displayName,
          photo1Url: assignmentSnapShot.photo1Url,
          locality: assignmentSnapShot.locality,
          streetNameAndNumber: assignmentSnapShot.streetNameAndNumber,
        };
        return linkedAssignment;
      }
    );
    return linkedAssignments;
  }

  public static convertToRelationSnapShots(
    linkedRelations: LinkedRelation[]
  ): RelationSnapShot[] {
    if (!linkedRelations) {
      return null;
    }

    return linkedRelations.map((linkedRelation: LinkedRelation) => {
      const relationSnapShot: RelationSnapShot = {
        avatarUrl: linkedRelation.avatarUrl,
        dateOfBirth: linkedRelation.dateOfBirth,
        displayName: linkedRelation.displayName,
        email: linkedRelation.email,
        id: linkedRelation.id,
        isActive: linkedRelation.isActive,
        locality: linkedRelation.locality,
        mobileNumber: linkedRelation.phoneMobile,
        typeOfRelation: linkedRelation.typeOfRelation,
        phoneNumber: linkedRelation.phone,
        streetNameAndNumber: linkedRelation.streetNameAndNumber,
        dateTimeCreated: null,
        dateTimeModified: null,
      };
      return relationSnapShot;
    });
  }

  public static convertToLinkedRelations(
    relationSnapshots: RelationSnapShot[]
  ): LinkedRelation[] {
    if (!relationSnapshots) {
      return null;
    }

    const linkedRelations: LinkedRelation[] = relationSnapshots.map(
      (relationSnapShot: RelationSnapShot) => {
        const linkedrelation: LinkedRelation = {
          avatarUrl: relationSnapShot.avatarUrl,
          dateOfBirth: relationSnapShot.dateOfBirth,
          displayName: relationSnapShot.displayName,
          email: relationSnapShot.email,
          id: relationSnapShot.id,
          isActive: relationSnapShot.isActive,
          locality: relationSnapShot.locality,
          phoneMobile: relationSnapShot.mobileNumber,
          typeOfRelation: relationSnapShot.typeOfRelation,
          phone: relationSnapShot.phoneNumber,
          streetNameAndNumber: relationSnapShot.streetNameAndNumber,
        };
        return linkedrelation;
      }
    );
    return linkedRelations;
  }

  public static convertPhraseToPhraseSnapshot(
    phrase: Phrase,
    realEstateAgencyId: string
  ): PhraseSnapShot {
    const { id, title, description, categoryId, isActive } = phrase;

    return {
      id,
      title,
      description,
      categoryId,
      realEstateAgencyId,
      isActive,
    };
  }

  public static getMinimalRelationSnapShot(
    relationSnapShot: RelationSnapShot
  ): RelationSnapShot {
    return {
      id: relationSnapShot.id,
      displayName: relationSnapShot.displayName,
      dateTimeCreated: relationSnapShot.dateTimeCreated,
      dateTimeModified: relationSnapShot.dateTimeModified,
      isActive: relationSnapShot.isActive,
      typeOfRelation: relationSnapShot.typeOfRelation,
    };
  }

  public static getMinimalAssignmentSnapShot(
    assignmentSnapShot: AssignmentSnapShot
  ): AssignmentSnapShot {
    return {
      id: assignmentSnapShot.id,
      displayName: assignmentSnapShot.displayName,
      assignmentPhase: assignmentSnapShot.assignmentPhase,
      forRent: assignmentSnapShot.forRent,
      forSale: assignmentSnapShot.forSale,
      isActive: assignmentSnapShot.isActive,
      realEstateAgencyId: assignmentSnapShot.realEstateAgencyId,
      realEstateGroup: assignmentSnapShot.realEstateGroup,
      typeOfAssignment: assignmentSnapShot.typeOfAssignment,
      dateTimeCreated: assignmentSnapShot.dateTimeCreated,
      dateTimeModified: assignmentSnapShot.dateTimeModified,
    };
  }
}
