import {
  AcquisitionStatus,
  AssignmentType,
  Bid,
  BidType,
  LinkedAssignment,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import FormattedText from "@haywork/components/ui/formatted-text";
import PageHeader from "@haywork/components/ui/page-header";
import { ACQUISITIONOBJECTROUTES, ACQUISITIONROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import Notes from "@haywork/modules/notes-v3";
import { ConfirmComponent } from "@haywork/modules/shared";
import {
  BidAction,
  BidModal,
  BidModalView,
  BidsWidget,
} from "@haywork/modules/shared/components/acquisition-bids";
import { BidsWidgetRef } from "@haywork/modules/shared/components/acquisition-bids/components/bids-widget";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import Actions, {
  AcquisitionObjectAssignmentAction,
} from "../../components/acquisition-object-actions";
import Sidebar from "../../components/assignment-detail-sidebar";
import NetworkWidget from "../../components/network-widget";
import { AcquisitionAssignmentDetailGeneralScreenContainerProps } from "./assignments-detail-general.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquisitionAssignmentDetailGeneralScreenProps = {};
type Props = AcquisitionAssignmentDetailGeneralScreenProps &
  AcquisitionAssignmentDetailGeneralScreenContainerProps;

export const AcquisitionAssignmentDetailGeneralScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionAssignmentObject,
      navigate,
      createNewBid,
      canSendEmail,
      createAgendaItemWithLinkedAssignment,
      createNewEmail,
      createTaskWithLinkedAssignment,
      archiveAcquisitionObject,
      unArchiveAcquisitionObject,
      deleteAcquisitionObject,
      createCounterOffer,
      linkedEmployee,
      updateAcquisitionObject,
    }) => {
      const {
        id,
        forRent,
        isNew,
        linkedVendors,
        linkedClients,
        linkedApplicants,
        linkedNotaries,
        linkedPropertyManagers,
        linkedAppraisers,
        linkedFinancialAdvisors,
        linkedInspectors,
        linkedContactPersons,
        linkedPurchasingBrokers,
        linkedAcquisitionAssignment,
        displayName,
        usableArea,
        otherInsideSpaces,
        buildingRelatedOutdoorSpace,
        externalStorage,
        parcelSurface,
        numberOfRooms,
        numberOfBedRooms,
        contents,
        numberOfFloors,
        yearOfConstruction,
        saleOffer,
        rentOffer,
        status: acquisitionObjectStatus,
        isActive,
        realEstateGroup,
      } = acquisitionAssignmentObject;

      const intl = useIntl();
      const [bidModalVisible, setBidModalVisible] = useState(false);
      const [bidModalView, setBidModalView] = useState<BidModalView>(null);
      const [bid, setBid] = useState<Bid | null>(null);
      const [originalBid, setOriginalBid] = useState<Bid | null>(null);
      const bidsWidgetRef = useRef<BidsWidgetRef>(null);
      const [showBidAcceptedModal, setShowBidAcceptedModal] =
        useState<boolean>(false);

      const isForSale = useMemo(() => {
        return forRent === true ? false : true;
      }, [forRent]);

      const editPath = useMemo(() => {
        return route(ACQUISITIONOBJECTROUTES.EDIT.URI, { id });
      }, [id]);

      const pageTitle = useMemo(() => {
        return !!forRent
          ? "acquisition.assignment.detailGeneral.rentTitle"
          : "acquisition.assignment.detailGeneral.saleTitle";
      }, [forRent]);

      const status = useMemo(() => {
        let color = Colors.TextBlack;

        switch (true) {
          case !isActive: {
            return (
              <FormattedText
                type="header"
                value="archived"
                color={Colors.Gray}
                bold
              />
            );
          }
          case acquisitionObjectStatus === AcquisitionStatus.New: {
            color = Colors.Gray;
            break;
          }
          case acquisitionObjectStatus === AcquisitionStatus.UnderNegociation: {
            color = Colors.Primary;
            break;
          }
          case acquisitionObjectStatus === AcquisitionStatus.Dissolved: {
            color = Colors.Danger;
            break;
          }
          case acquisitionObjectStatus === AcquisitionStatus.Purchased: {
            color = Colors.Success;
            break;
          }
          case acquisitionObjectStatus === AcquisitionStatus.Rented: {
            color = Colors.Success;
            break;
          }
          case acquisitionObjectStatus === AcquisitionStatus.Canceled: {
            color = Colors.Danger;
            break;
          }
          default: {
            color = Colors.Gray;
            break;
          }
        }

        const statusValue = !acquisitionObjectStatus
          ? AcquisitionStatus.New.toString()
          : acquisitionObjectStatus.toString();

        return (
          <FormattedText
            type="header"
            value={`acquisition.object.status.${statusValue.toString()}`}
            color={color}
            bold
          />
        );
      }, [acquisitionObjectStatus, isActive]);

      const showTransfer: boolean = useMemo(() => {
        if (
          get(saleOffer, "dateTransfer") ||
          get(saleOffer, "endDateBankWarranty") ||
          get(saleOffer, "dateReservation") ||
          get(saleOffer, "agreementDate") ||
          get(rentOffer, "rentedFrom") ||
          get(rentOffer, "rentedUntil") ||
          get(rentOffer, "dateSignDeed")
        )
          return true;
        return false;
      }, [acquisitionAssignmentObject]);

      const showObject: boolean = useMemo(() => {
        if (
          get(usableArea, "area") ||
          get(parcelSurface, "area") ||
          get(otherInsideSpaces, "area") ||
          get(buildingRelatedOutdoorSpace, "area") ||
          get(externalStorage, "area") ||
          get(contents, "area") ||
          get(saleOffer, "salePrice") ||
          get(saleOffer, "targetPurchasePrice") ||
          get(rentOffer, "rentPrice") ||
          get(rentOffer, "targetRentPrice") ||
          numberOfRooms ||
          numberOfBedRooms ||
          numberOfFloors ||
          yearOfConstruction
        )
          return true;
        return false;
      }, [acquisitionAssignmentObject]);

      const renderSpecification = (
        specification: boolean | number,
        text: string,
        addition?: string
      ) => {
        switch (typeof specification) {
          case "boolean":
            return renderBasicSpec(specification, text);
          case "number":
            return renderNumberSpec(specification, text, addition);
          default:
            return null;
        }
      };

      const renderBasicSpec = (
        specification,
        text
      ): React.ReactElement<HTMLDivElement> => {
        if (!specification) return null;
        return (
          <>
            <div styleName="icon">
              <i className="fal fa-fw fa-check" />
            </div>
            <div styleName="label" data-cy={"CY-" + text}>
              <I18n value={text} />
            </div>
          </>
        );
      };

      const renderNumberSpec = (
        specificationNumber,
        text,
        addition?: string,
        isDate?: boolean
      ): React.ReactElement<HTMLDivElement> => {
        if (!specificationNumber || specificationNumber === 0) return null;
        return (
          <>
            <div styleName="label">
              <I18n value={text} />
            </div>
            <div styleName="number" data-cy={"CY-" + text}>
              {isDate
                ? moment(specificationNumber).format("DD-MM-YYYY")
                : specificationNumber}
              {!!addition && <I18n value={addition} asHtml />}
            </div>
          </>
        );
      };
      const onActionClickHandler = useCallback(
        async (action: AcquisitionObjectAssignmentAction) => {
          const {
            id,
            displayName,
            linkedAcquisitionAssignment,
            linkedVendors,
          } = acquisitionAssignmentObject;
          const linkedAssignment: LinkedAssignment = {
            id,
            displayName,
            typeOfAssignment: AssignmentType.AcquisitionObject,
          };

          const linkedRelations = [...(linkedVendors || [])];

          switch (action) {
            case AcquisitionObjectAssignmentAction.AddBid: {
              const bid = await createNewBid(id, linkedEmployee);
              setBid(bid);
              setBidModalView("create");
              setBidModalVisible(true);
              return;
            }
            case AcquisitionObjectAssignmentAction.Archive: {
              archiveAcquisitionObject(id);
              return;
            }
            case AcquisitionObjectAssignmentAction.CreateAppointment: {
              createAgendaItemWithLinkedAssignment(
                linkedRelations,
                linkedAssignment
              );
              return;
            }
            case AcquisitionObjectAssignmentAction.CreateEmail: {
              createNewEmail(linkedAssignment, displayName || "");
              return;
            }
            case AcquisitionObjectAssignmentAction.CreateTask: {
              createTaskWithLinkedAssignment(linkedAssignment);
              return;
            }
            case AcquisitionObjectAssignmentAction.Delete: {
              deleteAcquisitionObject(id);
              return;
            }
            case AcquisitionObjectAssignmentAction.OpenParentAssignment: {
              const { id: parentId } = linkedAcquisitionAssignment;
              const path = route(ACQUISITIONROUTES.DETAIL.URI, {
                id: parentId,
              });
              navigate(path);
              return;
            }
            case AcquisitionObjectAssignmentAction.UnArchive: {
              unArchiveAcquisitionObject(id);
              return;
            }
            default:
              return;
          }
        },
        [acquisitionAssignmentObject, linkedEmployee]
      );

      const onBidModalClose = useCallback(() => {
        setBidModalVisible(false);
        setBid(null);
        setOriginalBid(null);
        setBidModalView(null);
      }, []);

      const onBidModalUpdated = useCallback(() => {
        onBidModalClose();
        if (!!bidsWidgetRef.current) {
          const { refresh } = bidsWidgetRef.current;
          refresh();
        }
      }, []);

      const onBidAccepted = useCallback(() => {
        setShowBidAcceptedModal(true);
      }, []);

      const onClose = useCallback(() => {
        setShowBidAcceptedModal(false);
      }, []);

      const onConfirm = useCallback(() => {
        const status = acquisitionAssignmentObject.forRent
          ? AcquisitionStatus.Rented
          : AcquisitionStatus.Purchased;

        const updatedAcquisitionObjectAssignment = {
          ...acquisitionAssignmentObject,
          status,
        };
        updateAcquisitionObject(updatedAcquisitionObjectAssignment);
        setBidModalVisible(false);
        setShowBidAcceptedModal(false);
      }, [acquisitionAssignmentObject]);

      const onBidAction = useCallback(
        async (action: BidAction, bid: Bid) => {
          switch (action) {
            case BidAction.Accept: {
              setBid(bid);
              setBidModalView("accept");
              break;
            }
            case BidAction.CounterOffer: {
              const { type } = bid;
              const { linkedVendors } = acquisitionAssignmentObject;
              const relations =
                type === BidType.Outgoing ? linkedVendors || [] : undefined;
              const counterOffer = await createCounterOffer(bid, relations);
              if (!counterOffer) return;
              setBid(counterOffer);
              setOriginalBid(bid);
              setBidModalView("create");
              break;
            }
            case BidAction.Edit: {
              setBid(bid);
              setBidModalView("edit");
              break;
            }
            case BidAction.Reject: {
              setBid(bid);
              setBidModalView("reject");
              break;
            }
            case BidAction.ShowAllBids: {
              setBidModalView("bids");
              break;
            }
            default:
              return;
          }

          setBidModalVisible(true);
        },
        [acquisitionAssignmentObject]
      );
      const rentResourceKey =
        acquisitionAssignmentObject.realEstateGroup ===
        RealEstateGroup.Agricultural
          ? "forLease"
          : "forRent";

      const renderHouseInfo = (): string => {
        const { listingType, houseSort, houseType } =
          acquisitionAssignmentObject;
        const listingTypeValue = !!listingType
          ? intl.formatMessage({
              id: `listingTypes.${listingType.toString()}`,
              defaultMessage: `listingTypes.${listingType.toString()}`,
            })
          : null;
        const houseSortValue = !!houseSort
          ? intl.formatMessage({
              id: `houseSorts.${houseSort.toString()}`,
              defaultMessage: `houseSorts.${houseSort.toString()}`,
            })
          : null;
        const houseTypeValue = !!houseType
          ? intl.formatMessage({
              id: `houseTypes.${houseType.toString()}`,
              defaultMessage: `houseTypes.${houseType.toString()}`,
            })
          : null;
        const houseInfo = [listingTypeValue, houseSortValue, houseTypeValue];

        return houseInfo.filter((value) => !!value).join(", ");
      };

      const formatFinancialData = (financialData: number) => {
        return intl.formatNumber(financialData || 0, {
          style: "currency",
          currency: "EUR",
        });
      };

      return (
        <>
          <div styleName="sidebar">
            <Sidebar />
          </div>
          <div styleName="body">
            <div styleName="general">
              <PageHeader
                title="acquisition.assignment.detailGeneral.title"
                subTitle={pageTitle}
                actions={
                  <>
                    {!isNew && <Notes />}
                    <Button
                      label="acquisition.assignment.detailGeneral.edit"
                      category="success"
                      onClick={() => navigate(editPath)}
                    />
                    <Actions
                      acquisitionObjectAssignment={acquisitionAssignmentObject}
                      canSendEmail={canSendEmail}
                      onClick={onActionClickHandler}
                    />
                  </>
                }
              />
              <div styleName="info">
                <div styleName="info__data">
                  <div styleName="data__wrapper">
                    <div styleName="section">
                      <FormattedText
                        value={displayName}
                        type="header"
                        bold
                        chain
                        skipTranslation
                      />
                      <h2>
                        <I18n
                          prefix="realEstateGroups"
                          value={realEstateGroup.toString()}
                        />
                        ,{" "}
                        {isForSale ? (
                          <I18n value="forSale" />
                        ) : (
                          <I18n value={rentResourceKey} />
                        )}
                      </h2>
                      <FormattedText
                        value={renderHouseInfo()}
                        type="paragraph"
                        color={Colors.Gray}
                        skipTranslation
                      />
                    </div>
                    <div styleName="section">
                      <FormattedText
                        value="acquisition.sectionTitle.statusNegociation"
                        asSection
                      />
                      {status}
                    </div>
                    <div styleName="section">
                      <BidsWidget
                        assignmentId={id}
                        onAction={onBidAction}
                        ref={bidsWidgetRef}
                      />
                    </div>

                    {showTransfer && (
                      <div styleName="section">
                        <FormattedText
                          value="acquisition.sectionTitle.transfer"
                          asSection
                        />
                        <div styleName="specs">
                          <div styleName="inner">
                            {isForSale && (
                              <>
                                {renderNumberSpec(
                                  get(saleOffer, "dateTransfer"),
                                  "acquisition.object.status.dateOfTransfer",
                                  null,
                                  true
                                )}
                                {renderNumberSpec(
                                  get(saleOffer, "endDateBankWarranty"),
                                  "acquisition.object.status.endDateBankWarranty",
                                  null,
                                  true
                                )}
                                {renderNumberSpec(
                                  get(saleOffer, "dateReservation"),
                                  "acquisition.object.status.dateReservation",
                                  null,
                                  true
                                )}
                                {renderNumberSpec(
                                  get(saleOffer, "agreementDate"),
                                  "acquisition.object.status.agreementDate",
                                  null,
                                  true
                                )}
                              </>
                            )}
                            {!isForSale && (
                              <>
                                {renderNumberSpec(
                                  get(rentOffer, "rentedFrom"),
                                  "acquisition.object.status.rentedFrom",
                                  null,
                                  true
                                )}
                                {renderNumberSpec(
                                  get(rentOffer, "rentedUntil"),
                                  "acquisition.object.status.rentedUntil",
                                  null,
                                  true
                                )}
                                {renderNumberSpec(
                                  get(rentOffer, "dateSignDeed"),
                                  "acquisition.object.status.dateSignDeed",
                                  null,
                                  true
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {showObject && (
                      <div styleName="section object_info">
                        <FormattedText
                          value="acquisition.sectionTitle.objectInfo"
                          asSection
                        />
                        <div styleName="specs">
                          <div styleName="inner">
                            {isForSale && (
                              <>
                                {renderNumberSpec(
                                  formatFinancialData(
                                    acquisitionObjectStatus ===
                                      AcquisitionStatus.Purchased
                                      ? saleOffer.purchasePrice ||
                                          saleOffer.salePrice
                                      : saleOffer.salePrice
                                  ),
                                  acquisitionObjectStatus ===
                                    AcquisitionStatus.Purchased
                                    ? "acquisition.object.status.purchasePrice"
                                    : "acquisition.object.status.salePrice",
                                  null,
                                  false
                                )}
                                {!!saleOffer.targetPurchasePrice &&
                                  renderNumberSpec(
                                    formatFinancialData(
                                      get(saleOffer, "targetPurchasePrice")
                                    ),
                                    "acquisition.object.status.targetPurchasePrice",
                                    null,
                                    false
                                  )}
                              </>
                            )}
                            {!isForSale && (
                              <>
                                {renderNumberSpec(
                                  formatFinancialData(
                                    get(rentOffer, "rentPrice")
                                  ),
                                  "acquisition.object.status.rentPrice",
                                  null,
                                  false
                                )}
                                {renderNumberSpec(
                                  formatFinancialData(
                                    get(rentOffer, "targetRentPrice")
                                  ),
                                  "acquisition.object.status.targetRentPrice",
                                  null,
                                  false
                                )}
                              </>
                            )}

                            {renderSpecification(
                              numberOfRooms,
                              "numberOfRooms"
                            )}
                            {renderSpecification(
                              numberOfBedRooms,
                              "numberOfBedrooms"
                            )}
                            {renderSpecification(
                              numberOfFloors,
                              "numberOfFloors"
                            )}
                            {renderSpecification(
                              get(yearOfConstruction, "constructionYear"),
                              "yearOfConstruction"
                            )}
                          </div>
                        </div>
                        <div styleName="specs">
                          <div styleName="inner">
                            {renderSpecification(
                              get(usableArea, "area"),
                              "usableArea",
                              "squareMeters"
                            )}
                            {renderSpecification(
                              get(parcelSurface, "area"),
                              "parcelSurface",
                              "squareMeters"
                            )}
                            {renderSpecification(
                              get(otherInsideSpaces, "area"),
                              "otherInsideSpaces",
                              "cubicMeters"
                            )}
                            {renderSpecification(
                              get(buildingRelatedOutdoorSpace, "area"),
                              "buildingRelatedOutdoorSpace",
                              "cubicMeters"
                            )}
                            {renderSpecification(
                              get(externalStorage, "area"),
                              "externalStorage",
                              "cubicMeters"
                            )}
                            {renderSpecification(
                              get(contents, "area"),
                              "contents",
                              "cubicMeters"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div styleName="info__sidebar">
                  <NetworkWidget
                    linkedRelations={linkedClients || []}
                    linkedApplicants={linkedApplicants || []}
                    linkedNotaries={linkedNotaries || []}
                    linkedPropertyManagers={linkedPropertyManagers || []}
                    linkedAppraisers={linkedAppraisers || []}
                    linkedFinancialAdvisors={linkedFinancialAdvisors || []}
                    linkedInspectors={linkedInspectors || []}
                    linkedContactPersons={linkedContactPersons || []}
                    linkedPurchasingBrokers={linkedPurchasingBrokers || []}
                    linkedAcquisitionAssignment={linkedAcquisitionAssignment}
                    isAcquisitionAssignment={true}
                    isForSale={isForSale}
                    onNavigate={navigate}
                  />
                </div>
              </div>

              <BidModal
                visible={bidModalVisible}
                bid={bid}
                originalBid={originalBid}
                view={bidModalView}
                linkedVendors={linkedVendors}
                assignmentId={id}
                onClose={onBidModalClose}
                onUpdated={onBidModalUpdated}
                onBidAccepted={onBidAccepted}
              />
              <ConfirmComponent
                visible={showBidAcceptedModal}
                titleResourceKey="acquisition.assignment.modal.confirmObjectStatusChangeTitle"
                bodyResourceKey="acquisition.assignment.modal.confirmObjectStatusChangeBody"
                onClose={onClose}
                onConfirm={onConfirm}
              />
            </div>
          </div>
        </>
      );
    }
  )
);
