import {
  AssignmentSnapShot,
  CommunicationLog,
  CommunicationLogsClient,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import { MessagesClient } from "@haywork/api/mail";
import { ApiType, ParseRequest } from "@haywork/services";
import AbortController from "@haywork/services/abort.service";
import { AppState } from "@haywork/stores";
import { EmailActionsV2, EmailMessage } from "@haywork/stores/email-v2";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { EmailUtil } from "@haywork/util/email-v2";
import get from "lodash-es/get";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();
export const abortCommunicationLog = new AbortController();
const getCommunicationLog = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      await abortCommunicationLog.process();

      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

      const client = new CommunicationLogsClient(
        host,
        abortCommunicationLog.fetch()
      );

      const communicationLog = parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.communicationLog || null)
      );
      if (!communicationLog) return;

      return communicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const saveCommunicationLog = (
  linkedRelations: RelationSnapShot[],
  linkedAssignments: AssignmentSnapShot[],
  message: EmailMessage,
  autoRemoveMessageAfterPersist: boolean,
  communicationLog?: CommunicationLog,
  subject?: string,
  showToast?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    let messageToAppend: EmailMessage;

    try {
      const state = getState();
      const { host, emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const communicationLogClient = new CommunicationLogsClient(host);
      const messagesClient = new MessagesClient(emailHost);

      let isNew = get(communicationLog, "isNew", false);

      if (!communicationLog) {
        isNew = true;
        communicationLog = await parseRequest.response(
          communicationLogClient
            .defineNew({}, realEstateAgencyId)
            .then((response) => response.communicationLog)
        );
      }

      communicationLog = {
        ...communicationLog,
        subject: subject || message.subject,
        date: message.date,
        linkedRelations,
        linkedAssignments,
      };

      const saveResponse = await parseRequest.response(
        communicationLogClient.save({ communicationLog }, realEstateAgencyId),
        ApiType.Email
      );

      if (isNew) {
        const { accountId, folderId } = message;
        const communicationLogId = saveResponse.communicationLog.id;

        try {
          await parseRequest.response(
            messagesClient.persist(
              {
                accountId,
                communicationLogId,
                id: message.id,
                removeMessageAfterPersisted: autoRemoveMessageAfterPersist,
              },
              realEstateAgencyId
            ),
            ApiType.Email
          );

          const { messages } = state.emailV2.messages;

          messageToAppend = EmailUtil.mapMessageToEmailMessage(
            message,
            accountId,
            folderId,
            messages
          );
          messageToAppend = {
            ...message,
            _metaData: {
              ...message._metaData,
              linkedCommunicationLogId: communicationLogId,
            },
          };
          if (autoRemoveMessageAfterPersist) {
            dispatch(EmailActionsV2.Messages.deleteMessage(message.id));
            return;
          }
          dispatch(EmailActionsV2.Messages.updateMessage(messageToAppend));
        } catch (error) {
          deleteCommunicationLog(communicationLogId, messageToAppend);
          throw error;
        }
      }

      if (showToast) {
        const toast: ToastProps = {
          value: "emailToastLinkedItemsSaved",
          icon: "envelope",
        };
        dispatch(SnackbarActions.addToast(toast));
      }

      return saveResponse.communicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const deleteCommunicationLog = (
  communicationLogId: string,
  message?: EmailMessage
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!message) return;

    try {
      const state = getState();
      const { host, emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const communicationLogClient = new CommunicationLogsClient(host);
      const messagesClient = new MessagesClient(emailHost);

      await parseRequest.response(
        communicationLogClient.delete(communicationLogId, realEstateAgencyId)
      );
      await parseRequest.response(
        messagesClient.unlinkCommunicationLog(
          { communicationLogId },
          realEstateAgencyId
        )
      );

      const messageToAppend = {
        ...message,
        _metaData: {
          ...message._metaData,
          linkedCommunicationLogId: null,
        },
      };

      dispatch(EmailActionsV2.Messages.updateMessage(messageToAppend));
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getCommunicationLog,
  saveCommunicationLog,
};
