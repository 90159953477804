import { REDUX } from "@haywork/constants";

import * as ActionType from "./mediapartners.types";
import { Status } from "../helpers";

const setMediaContracts = (payload: ActionType.MediaContracts) => ({
  type: REDUX.MEDIAPARTNERS.SET_MEDIA_CONTRACTS,
  ...payload
});

const appendMediaContracts = (payload: ActionType.MediaContracts) => ({
  type: REDUX.MEDIAPARTNERS.APPEND_MEDIA_CONTRACTS,
  ...payload
});

const setMediaContractsStatus = (payload: Status) => ({
  type: REDUX.MEDIAPARTNERS.SET_MEDIA_CONTRACTS_STATUS,
  ...payload
});

const resetFilters = () => ({
  type: REDUX.MEDIAPARTNERS.RESET_FILTERS
});

const toggleIsActive = () => ({
  type: REDUX.MEDIAPARTNERS.TOGGLE_IS_ACTIVE
});

const toggleIsFavorite = () => ({
  type: REDUX.MEDIAPARTNERS.TOGGLE_IS_FAVORITE
});

const setCategories = (payload: ActionType.Categories) => ({
  type: REDUX.MEDIAPARTNERS.SET_CATEGORIES,
  ...payload
});

export const MediapartnersActions = {
  setMediaContracts,
  appendMediaContracts,
  setMediaContractsStatus,
  resetFilters,
  toggleIsActive,
  toggleIsFavorite,
  setCategories
};
