import { ProjectAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { ProjectEditMarketingComponent } from "@haywork/modules/project/components/edit-marketing/edit-marketing.component";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

export interface ProjectEditMarketingContainerProps {
  projectAssignment: ProjectAssignment;
}

const mapStateToProps = (state: AppState) => ({
  projectAssignment: state.editable.currentComponentState.projectAssignment
});

// noinspection JSUnusedLocalSymbols
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});
export const ProjectEditMarketingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingComponent);
