import { RootEntityType } from "@haywork/api/event-center";
import {
  PhotoBlob,
  RealEstateAgenciesClient,
  RealEstateAgency,
} from "@haywork/api/kolibri";
import {
  MAINROUTES,
  REALESTATEAGENCYROUTES,
  REQUEST,
} from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  RealEstateAgencyActions,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { Dispatch, EditableThunks } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getRealEstateAgency = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      RealEstateAgencyActions.setRealEstateAgencyStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { host } = state.appSettings;

    const RealEstateAgencies = new RealEstateAgenciesClient(host);

    try {
      const realEstateAgency = await parseRequest.response(
        RealEstateAgencies.read(id).then(
          (response) => response.realEstateAgency
        )
      );

      const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
        id: realEstateAgency.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.REALESTATEAGENCY.ICON,
          componentState: realEstateAgency,
          path,
          title: "...",
          entityType: RootEntityType.RealEstateAgency,
          entityId: realEstateAgency.id,
        })
      );
      dispatch(RealEstateAgencyActions.setRealEstateAgency(realEstateAgency));
    } catch (error) {
      dispatch(
        RealEstateAgencyActions.setRealEstateAgencyStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

const updateLogo = (logo: PhotoBlob, realEstateAgencyId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
      id: realEstateAgencyId,
    });
    const componentState = {
      ...currentComponentState,
      logo: !logo ? null : logo,
    };

    const RealEstateAgencies = new RealEstateAgenciesClient(host);

    try {
      if (!!logo) {
        await parseRequest.response(
          RealEstateAgencies.updateLogo(
            {
              fileDataId: logo.fileDataId,
              fileExtension: logo.fileExtension,
              fileName: logo.fileName,
              fileSize: logo.fileSize,
              height: logo.height,
              md5Hash: logo.md5Hash,
              parentId: realEstateAgencyId,
            },
            realEstateAgencyId
          )
        );
      } else {
        await parseRequest.response(
          RealEstateAgencies.removeLogo(
            { parentId: realEstateAgencyId },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const saveRealEstateAgency = (
  realEstateAgency: RealEstateAgency,
  path: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      RealEstateAgencyActions.setRealEstateAgencyStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
      id: realEstateAgency.id,
    });

    const RealEstateAgencies = new RealEstateAgenciesClient(host);

    try {
      const updatedRealEstateAgency = await parseRequest.response(
        RealEstateAgencies.save({ realEstateAgency }, realEstateAgencyId).then(
          (response) => response.realEstateAgency
        )
      );

      dispatch(
        RealEstateAgencyActions.setRealEstateAgency(updatedRealEstateAgency)
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: updatedRealEstateAgency,
          path,
          ignoreChanges: true,
        })
      );

      dispatch(EditableThunks.remove(path));
    } catch (error) {
      dispatch(
        RealEstateAgencyActions.setRealEstateAgencyStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

export const RealEstateAgencyThunks = {
  getRealEstateAgency,
  updateLogo,
  saveRealEstateAgency,
};
