import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { RelationWidgetItem } from "./widget.component";
import { RelationType } from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import { MailTo } from "@haywork/modules/shared";

const styles = require("./linked-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface RelationWidgetLinkedItemComponentProps {
  relationWidgetItem: RelationWidgetItem;
  onNavigate: (url: string) => void;
}
interface RelationWidgetLinkedItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class RelationWidgetLinkedItemComponent extends React.Component<
  RelationWidgetLinkedItemComponentProps,
  RelationWidgetLinkedItemComponentState
> {
  public render() {
    const { relationWidgetItem } = this.props;

    let routeToUse: string;
    if (relationWidgetItem.typeOfRelation === RelationType.ContactPerson) {
      routeToUse = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
        id: relationWidgetItem.id,
      });
    } else {
      routeToUse = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: relationWidgetItem.id,
      });
    }

    return (
      <div styleName="item" onClick={() => this.props.onNavigate(routeToUse)}>
        <div styleName="relationAvatar">
          {relationWidgetItem.avatarUrl ? (
            <div
              styleName="image"
              style={{
                backgroundImage: `url(${JSON.stringify(
                  relationWidgetItem.avatarUrl
                )})`,
              }}
            ></div>
          ) : (
            <div styleName="noImage">{relationWidgetItem.letterAvatar}</div>
          )}
        </div>
        <div styleName="itemMeta">
          <div styleName="meta">
            <span styleName="displayName">
              {relationWidgetItem.displayName}
            </span>
            <span styleName="typeOfRelation">
              {relationWidgetItem.relationType}
            </span>
          </div>
          <div styleName="meta">
            {relationWidgetItem.phone && (
              <span>
                <a href={"tel:" + relationWidgetItem.phone}>
                  {relationWidgetItem.phone}
                </a>
              </span>
            )}
            {relationWidgetItem.email && (
              <span>
                <MailTo email={relationWidgetItem.email} />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
