import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import {
  TimelineEvent,
  TaskCategoryOption,
  Priority,
} from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity,
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import FormattedText from "@haywork/components/ui/formatted-text";
import classNames from "classnames";
import * as moment from "moment";
import { ColorUtil, RouteUtil } from "@haywork/util";
import { TASKROUTES } from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  event: TimelineEvent;
  parentId: string;
  taskCategories: TaskCategoryOption[];
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const TaskItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      event,
      parentId,
      taskCategories,
      onNavigate,
      onShowAllLinkedEntities,
    }) => {
      const {
        id,
        subject,
        linkedAssignments,
        linkedRelations,
        completedPercentage,
        categoryId,
        endDate,
        priority,
      } = event;
      const taskCategory = useMemo(
        () => taskCategories.find((category) => category.value === categoryId),
        [categoryId]
      );
      const backgroundColor = useMemo(
        () =>
          !!taskCategory && !!taskCategory.categoryBackColor
            ? ColorUtil.hexToRgb(taskCategory.categoryBackColor)
            : null,
        [taskCategory]
      );
      const categoryName = useMemo(
        () => taskCategory?.displayName || null,
        [taskCategory]
      );
      const dateField = useMemo(() => {
        const date = !!endDate ? moment(endDate) : null;
        return !date ? null : (
          <div styleName="date">{date.format("DD MMMM YYYY")}</div>
        );
      }, []);
      const postField = useMemo(() => {
        const date = !!endDate ? moment(endDate) : null;
        if (!date) return null;

        const today = moment();

        switch (true) {
          case completedPercentage === 100: {
            return (
              <div styleName="date__info">
                <I18n value="timeline.task.completed" />
              </div>
            );
          }
          case date.isBefore(today, "days"): {
            return (
              <div styleName="date__info">
                <FormattedText
                  value="timeline.task.overdue"
                  values={{ days: Math.abs(date.diff(today, "days")) }}
                  color={Colors.Danger}
                />
              </div>
            );
          }
          case date.isSame(today, "day"): {
            return (
              <div styleName="date__info">
                <FormattedText
                  value="timeline.task.date"
                  values={{ days: date.diff(today, "days") }}
                  color={Colors.Warning}
                />
              </div>
            );
          }
          default: {
            return (
              <div styleName="date__info">
                <FormattedText
                  value="timeline.task.date"
                  values={{ days: Math.ceil(date.diff(today, "days", true)) }}
                />
              </div>
            );
          }
        }
      }, []);
      const path = useMemo(() => {
        return route(TASKROUTES.TASK.URI, { id });
      }, [id]);
      const openTask = () => {
        onNavigate(path);
      };

      return (
        <div styleName="event__item is-clickable" onClick={openTask}>
          <div styleName="event__icon">
            <Icon name="tasks" light size={24} color={Colors.Gray} />
          </div>
          <div styleName="event__divider" style={{ backgroundColor }}></div>
          <div styleName="event__data">
            <div
              styleName={classNames("subject", {
                "strike-through": completedPercentage === 100,
              })}
            >
              {subject || <I18n value="timeline.missingSubject" />}
            </div>
            {!!categoryName && <div styleName="info">{categoryName}</div>}
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          {priority === Priority.High && <div styleName="important">!</div>}
          <div styleName="event__date">
            {dateField}
            {postField}
          </div>
          <div styleName="event__actions"></div>
        </div>
      );
    }
  )
);

export default TaskItemComponent;
