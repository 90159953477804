export const OIDC_CONFIG = {
  authority: null,
  client_id: "k24_app",
  redirect_uri: null,
  response_type: "id_token token",
  scope: null,
  post_logout_redirect_uri: null,
  automaticSilentRenew: true,
  silent_redirect_uri: null,
  accessTokenExpiringNotificationTime: 60
};
