import { Language, OpenHouse } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { FontStyle, ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, StringUtil } from "@haywork/util";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { WithIntlProps } from "react-intl";

const styles = require("./marketing-open-house.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface OpenHouseComponentProps {
  openHouse: OpenHouse;
  languages: Language[];
  defaultLanguage: Language;
  dateTimeModified: Date;
  openHouseChanged: (openHouse: OpenHouse) => void;
}
type Props = OpenHouseComponentProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
export class OpenHouseComponent extends React.Component<Props> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    // Set formcontrols
    const { openHouse } = this.props;
    this.formControls = {
      openHouseDate: { value: value(openHouse, "openHouseDate") },
      openHouseEndTime: {
        value: this.renderTime(value(openHouse, "openHouseEndTime"), "")
      },
      openHouseStartTime: {
        value: this.renderTime(value(openHouse, "openHouseStartTime"), "")
      },
      openHouseText: { value: value(openHouse, "openHouseText") }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.clearOpenHouse = this.clearOpenHouse.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.form &&
      prevProps.dateTimeModified !== this.props.dateTimeModified
    ) {
      const { openHouse } = this.props;
      this.form.update(
        {
          openHouseDate: value(openHouse, "openHouseDate"),
          openHouseEndTime: this.renderTime(
            value(openHouse, "openHouseEndTime"),
            ""
          ),
          openHouseStartTime: this.renderTime(
            value(openHouse, "openHouseStartTime"),
            ""
          ),
          openHouseText: value(openHouse, "openHouseText")
        },
        true
      );
    }
  }

  public render() {
    return (
      <div styleName="open-house" data-cy={this.props["data-cy"]}>
        <h1>
          <ResourceText resourceKey="organiseOpenHouse" />
        </h1>
        <Form
          name="open-house"
          formControls={this.formControls}
          onChange={this.onChangeHandler}
          form={(form) => (this.form = form)}
        >
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <div className="input__helper">
                  <div className="pre full">
                    <ResourceText
                      resourceKey="on"
                      fontStyle={FontStyle.UcFirst}
                    />
                  </div>
                  <Input.Datepicker
                    name="openHouseDate"
                    placeholder="datePlaceholder"
                    data-cy="CY-openHouseDateControl"
                  />
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <div className="pre full">
                    <ResourceText
                      resourceKey="from"
                      fontStyle={FontStyle.LowerCase}
                    />
                  </div>
                  <Input.Time
                    name="openHouseStartTime"
                    placeholder="timePlaceholder"
                    data-cy="CY-openHouseStartTimeControl"
                  />
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <div className="input__helper">
                  <div className="pre full">
                    <ResourceText
                      resourceKey="untill"
                      fontStyle={FontStyle.LowerCase}
                    />
                  </div>
                  <Input.Time
                    name="openHouseEndTime"
                    placeholder="timePlaceholder"
                    data-cy="CY-openHouseEndTimeControl"
                  />
                </div>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <button
                  className="btn btn-default-alt"
                  styleName="clear"
                  onClick={this.clearOpenHouse}
                  type="button"
                >
                  <i className="fal fa-times" />
                </button>
              </div>
            </div>
          </div>
          <div className="form__row">
            <Input.TranslateText
              name="openHouseText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-openHouseText"
            />
          </div>
        </Form>
      </div>
    );
  }

  private renderTime(date: Date | string, defaultValue: string) {
    if (!date) return defaultValue;
    // if received time (00:00)
    if (date.toString().length === 5) {
      return date;
    }
    const parsedDate = moment.utc(date, "DD-MM-YYYY HH:mm").local();

    if (!parsedDate.isValid()) return defaultValue;
    const hour = parsedDate.hours();
    const minute = parsedDate.minutes();

    return `${StringUtil.prependZero(hour, 2)}:${StringUtil.prependZero(
      minute,
      2
    )}`;
  }

  private onChangeHandler(values: FormReturnValue) {
    const openHouseDate = values.openHouseDate;
    const [
      startHours,
      startMinutes
    ] = (values.openHouseStartTime as string).split(":");
    const [endHours, endMinutes] = (values.openHouseEndTime as string).split(
      ":"
    );

    const openHouseStartTime = moment
      .utc(openHouseDate)
      .local()
      .set("hours", parseInt(startHours))
      .set("minutes", parseInt(startMinutes))
      .utc()
      .toDate();
    const openHouseEndTime = moment
      .utc(openHouseDate)
      .local()
      .set("hours", parseInt(endHours))
      .set("minutes", parseInt(endMinutes))
      .utc()
      .toDate();

    const openHouse = {
      ...this.props.openHouse,
      openHouseDate,
      openHouseEndTime,
      openHouseStartTime,
      openHouseText: values.openHouseText
    };

    this.props.openHouseChanged(openHouse);
  }

  private clearOpenHouse() {
    if (!this.form) return;
    this.form.update({
      openHouseDate: "",
      openHouseEndTime: "",
      openHouseStartTime: "",
      openHouseText: ""
    });
  }
}
