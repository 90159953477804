import { REDUX } from "@haywork/constants";
import * as ActionType from "./editable.types";

const addState = (payload: ActionType.Item) => ({
  type: REDUX.EDITABLE.ADD_STATE,
  ...payload,
});

const removeState = (payload: ActionType.Path) => ({
  type: REDUX.EDITABLE.REMOVE_STATE,
  ...payload,
});

const updateComponentState = (payload: ActionType.ComponentStateWithPath) => ({
  type: REDUX.EDITABLE.UPDATE_COMPONENT_STATE,
  ...payload,
});

const updateSingleComponentState = (
  payload: ActionType.ComponentStateWithPath
) => ({
  type: REDUX.EDITABLE.UPDATE_SINGLE_COMPONENT_STATE,
  ...payload,
});

const updateTabTitle = (payload: ActionType.Title) => ({
  type: REDUX.EDITABLE.UPDATE_TAB_TITLE,
  ...payload,
});

const setHasChanges = (payload: ActionType.Changes) => ({
  type: REDUX.EDITABLE.SET_HAS_CHANGES,
  ...payload,
});

const stateSetPreppedForSave = (payload: ActionType.Path) => ({
  type: REDUX.EDITABLE.SET_PREPPED_FOR_SAVE,
  ...payload,
});

const stateDisablePreppedForSave = (payload: ActionType.Path) => ({
  type: REDUX.EDITABLE.DISABLE_PREPPED_FOR_SAVE,
  ...payload,
});

const clearAll = () => ({
  type: REDUX.EDITABLE.CLEAR_ALL,
});

const setCallee = (payload: ActionType.Callee) => ({
  type: REDUX.EDITABLE.SET_CALLEE,
  ...payload,
});

const setCaller = (payload: ActionType.Caller) => ({
  type: REDUX.EDITABLE.SET_CALLER,
  ...payload,
});

const setCalleeId = (payload: ActionType.CalleeId) => ({
  type: REDUX.EDITABLE.SET_CALLEE_ID,
  ...payload,
});

const removeCallee = (path: string) => ({
  type: REDUX.EDITABLE.REMOVE_CALLEE,
  path,
});

const removeCaller = (path: string) => ({
  type: REDUX.EDITABLE.REMOVE_CALLER,
  path,
});

const setCalleeState = (payload: ActionType.CalleeState) => ({
  type: REDUX.EDITABLE.SET_CALLEE_STATE,
  ...payload,
});

const changeEditablePath = (payload: ActionType.ChangePaths) => ({
  type: REDUX.EDITABLE.CHANGE_EDITABLE_PATH,
  ...payload,
});

const setHasExternalChanges = (payload: ActionType.HasExternalChanges) => ({
  type: REDUX.EDITABLE.SET_EXTERNAL_CHANGES,
  ...payload,
});

const removeHasExternalChanges = (
  payload: ActionType.RemoveExternalChanges
) => ({
  type: REDUX.EDITABLE.REMOVE_EXTERNAL_CHANGES,
  ...payload,
});

const updateReferrer = (payload: ActionType.Referrer) => ({
  type: REDUX.EDITABLE.UPDATE_REFERRER,
  ...payload,
});

export const EditableActions = {
  addState,
  removeState,
  updateComponentState,
  updateSingleComponentState,
  updateTabTitle,
  setHasChanges,
  stateSetPreppedForSave,
  stateDisablePreppedForSave,
  clearAll,
  setCallee,
  setCaller,
  setCalleeId,
  removeCallee,
  removeCaller,
  setCalleeState,
  changeEditablePath,
  setHasExternalChanges,
  removeHasExternalChanges,
  updateReferrer,
};
