import {
  SearchAssignmentOrderByField,
  SearchAssignmentSnapShot,
  SortOrder
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  MapDispatchToProps,
  SearchAssignmentThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import {
  SearchAssignmentOrder,
  SearchAssignmentsListActions
} from "@haywork/stores/search-assignment/list";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  SearchAssignmentsListComponent,
  SearchAssignmentsListComponentProps
} from "./list";
import { activeFilterGuidValues } from "./selectors";

type StateProps = {
  searchAssignments: SearchAssignmentSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  order: SearchAssignmentOrder;
  scrollOffset: number;
  activeFilterGuidValues: FilterGuidValues;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  create: () => RouterAction;
  setFilters: (filters: FilterConfig) => void;
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: SearchAssignmentOrderByField
  ) => void;
  setScrollOffset: (offset: number) => void;
  updateListItem: (snapshot: SearchAssignmentSnapShot) => void;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
  remove: (id: string) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SearchAssignmentsListComponentProps,
  AppState
> = (state) => {
  const {
    searchAssignments,
    totalCount,
    filters,
    order,
    scrollOffset
  } = state.searchAssignment.list;

  return {
    searchAssignments,
    totalCount,
    filters,
    order,
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SearchAssignmentsListComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(SearchAssignmentThunks.getListItems(startIndex, stopIndex)),
  create: () => dispatch(push(SEARCHASSIGNMENTROUTES.NEW.URI)),
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: SearchAssignmentOrderByField
  ) =>
    dispatch(SearchAssignmentsListActions.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(SearchAssignmentsListActions.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(SearchAssignmentsListActions.setScrollOffset(offset)),
  updateListItem: (snapshot: SearchAssignmentSnapShot) =>
    dispatch(SearchAssignmentsListActions.updateItem(snapshot)),
  archive: (id: string) => dispatch(SearchAssignmentThunks.archive(id)),
  unArchive: (id: string) => dispatch(SearchAssignmentThunks.unArchive(id)),
  remove: (id: string) =>
    dispatch(SearchAssignmentThunks.removeListSearchAssignment(id))
});

export type SearchAssignmentsListContainerProps = StateProps & DispatchProps;
export const SearchAssignmentsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentsListComponent);
