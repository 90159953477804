import { MapDispatchToProps } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { SaveFilterComponent, SaveFilterProps } from "./save-filter";
import { FilterConfig } from "@haywork/components/ui/list";

type StateProps = {};
type DispatchProps = {
  saveFilters: (
    name: string,
    filterConfig: FilterConfig,
    id: string
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SaveFilterProps,
  AppState
> = (state) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, SaveFilterProps> = (
  dispatch
) => ({
  saveFilters: (name: string, filterConfig: FilterConfig, id: string) =>
    dispatch(MLSThunk.List.saveFilters(name, filterConfig, id)),
});

export type SaveFilterContainerProps = StateProps & DispatchProps;
export const SaveFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveFilterComponent);
