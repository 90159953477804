import { Address, CountryOption } from "@haywork/api/kolibri";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { AddressRequest } from "@haywork/request";
import { AddressUtil, FormControlUtil, StringUtil } from "@haywork/util";
import classNames from "classnames";
import first from "lodash-es/first";
import isEqual from "lodash-es/isEqual";
import * as React from "react";
import { injectIntl, WithIntlProps } from "react-intl";
import { v4 as uuid } from "uuid";
import { InputComponentProps } from "../../input.component";
import {
  BaseQueryComponent,
  QueryResultStringReturnValue,
} from "../base.component";

const value = FormControlUtil.returnObjectPathOrNull;

interface LocationQueryComponentProps {
  countryIso2: string;
  placeholder?: string;
  countries: CountryOption[];
  returnQueryOnError?: boolean;
  manualAddAddress?: boolean;
  culture: string;
}
interface State {
  loading: boolean;
  cannotFindLocation: boolean;
  countryIso2: string;
  countryString: string;
  address: Address;
}
type Props = LocationQueryComponentProps &
  InputComponentProps &
  WithIntlProps<any>;

class LocationQuery extends React.Component<Props, State> {
  private formControls: FormControls;
  private id: string = uuid();
  private addressFormRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cannotFindLocation: false,
      countryIso2: this.props.countryIso2,
      countryString: this.renderCountryString(this.props.countryIso2),
      address: this.props.value || null,
    };

    this.formControls = {
      [`houseNumber${this.id}`]: {
        value: value(this.props.value, "houseNumber"),
      },
      [`houseNumberPostfix${this.id}`]: {
        value: value(this.props.value, "houseNumberPostfix"),
      },
      [`locality${this.id}`]: {
        value: value(this.props.value, "locality.name"),
      },
      [`postalCode${this.id}`]: {
        value: value(this.props.value, "postalCode"),
      },
      [`street${this.id}`]: { value: value(this.props.value, "street.name") },
    };

    this.setManualAddress = this.setManualAddress.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);
    this.onCountryChangeHandler = this.onCountryChangeHandler.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedStringValue = this.renderSelectedStringValue.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onManualAddressChangeHandler =
      this.onManualAddressChangeHandler.bind(this);
    this.bindAddresFormRef = this.bindAddresFormRef.bind(this);
    this.onManualCloseClickHandler = this.onManualCloseClickHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (!!this.addressFormRef && !isEqual(nextProps.value, this.props.value)) {
      this.addressFormRef.update({
        [`houseNumber${this.id}`]: value(nextProps.value, "houseNumber"),
        [`houseNumberPostfix${this.id}`]: value(
          nextProps.value,
          "houseNumberPostfix"
        ),
        [`locality${this.id}`]: value(nextProps.value, "locality.name"),
        [`postalCode${this.id}`]: value(nextProps.value, "postalCode"),
        [`street${this.id}`]: value(nextProps.value, "street.name"),
      });
      this.setState({ address: nextProps.value });
    }
  }

  public render() {
    const inputsStyle = classNames("inputs", {
      hidden: !!this.props.manualAddAddress && !!this.state.address,
    });
    const addressFormStyle = classNames("address-form", {
      hidden: !this.props.manualAddAddress || !this.state.address,
      disabled: this.props.disabled,
    });

    return (
      <div className="location__query">
        {/* Location query */}
        <div className={inputsStyle}>
          <div className="country">
            <Input.NewSelect
              name="country"
              values={this.props.countries}
              valuesProp="iso2CodeValue"
              filterProp="displayName"
              displayValueFn={this.renderCountryValue}
              displayFn={this.renderCountryOption}
              onChange={this.onCountryChangeHandler}
              disabled={this.props.disabled}
            />
          </div>
          <div className="query">
            <BaseQueryComponent
              {...this.props}
              asyncValues={(value) =>
                AddressRequest.addressSearch(value, this.state.countryIso2)
              }
              optionValue={this.renderOptionValue}
              selectedStringValue={this.renderSelectedStringValue}
              onChange={this.onChangeHandler}
              disabled={this.state.loading || this.props.disabled}
              loading={this.state.loading}
              data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Base`}
              blockExternalValues
            />
          </div>
        </div>

        {/* Geo location error */}
        {this.state.cannotFindLocation && (
          <div className="error">
            <ResourceText
              resourceKey="couldNotFindAddress"
              values={{ path: SUPPORTURI.ADDRESSNOTFOUND }}
              asHtml
            />
          </div>
        )}

        {/* Manual address inputs */}
        <div className={addressFormStyle}>
          <Form
            name="add-manual-address"
            formControls={this.formControls}
            asSubForm
            onChange={this.onManualAddressChangeHandler}
            form={this.bindAddresFormRef}
          >
            <div className="form__row">
              <div className="form__group">
                {/* Streetname */}
                <div className="column street">
                  <label htmlFor={`street${this.id}`}>
                    <ResourceText resourceKey="streetName" />
                  </label>
                  <Input.Text
                    name={`street${this.id}`}
                    placeholder="streetName"
                    disabled={this.props.disabled}
                  />
                </div>

                <div className="column__spacer" />

                {/* Housenumber */}
                <div className="column number">
                  <label htmlFor={`houseNumber${this.id}`}>
                    <ResourceText resourceKey="houseNumber" />
                  </label>
                  <Input.Text
                    name={`houseNumber${this.id}`}
                    placeholder="houseNumber"
                    disabled={this.props.disabled}
                  />
                </div>

                <div className="column__spacer" />

                {/* Housenumber suffix */}
                <div className="column suffix">
                  <label htmlFor={`houseNumberPostfix${this.id}`}>
                    <ResourceText resourceKey="houseNumberPostfix" />
                  </label>
                  <Input.Text
                    name={`houseNumberPostfix${this.id}`}
                    placeholder="houseNumberPostfix"
                    disabled={this.props.disabled}
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                {/* Postalcode */}
                <div className="column postalcode">
                  <label htmlFor={`postalCode${this.id}`}>
                    <ResourceText resourceKey="postalCode" />
                  </label>
                  <Input.Text
                    name={`postalCode${this.id}`}
                    placeholder="postalCode"
                    disabled={this.props.disabled}
                  />
                </div>

                <div className="column__spacer" />

                {/* Locality */}
                <div className="column locality">
                  <label htmlFor={`locality${this.id}`}>
                    <ResourceText resourceKey="locality" />
                  </label>
                  <Input.Text
                    name={`locality${this.id}`}
                    placeholder="locality"
                    disabled={this.props.disabled}
                  />
                </div>
              </div>
            </div>
          </Form>
          {!this.props.disabled && (
            <div
              className="close_manual"
              onClick={this.onManualCloseClickHandler}
            >
              <i className="fal fa-minus-circle" />
            </div>
          )}
        </div>
      </div>
    );
  }

  private bindAddresFormRef(form: FormReference) {
    if (!!form && !this.addressFormRef) {
      this.addressFormRef = form;
    }
  }

  private onCountryChangeHandler(countryIso2: string) {
    this.setState({
      countryIso2,
      countryString: this.renderCountryString(countryIso2),
    });
  }

  private onChangeHandler(
    predictions: google.maps.places.AutocompletePrediction[]
  ) {
    if (!predictions || predictions.length === 0) {
      return this.props.onChange("");
    }

    this.setState({ loading: true, cannotFindLocation: false });
    const location = first(predictions);

    if (this.props.manualAddAddress) {
      AddressRequest.getPlaceById(location.place_id)
        .then(this.setManualAddress)
        .catch(() => {
          this.setManualAddress(null);
        });
    }

    if (!this.props.manualAddAddress) {
      AddressRequest.searchAddress(
        location.description,
        this.state.countryIso2,
        this.props.culture
      )
        .then((result) => {
          this.setState({ loading: false });
          this.props.onChange(result.results);
        })
        .catch(() => {
          const cannotFindLocation = !!this.props.returnQueryOnError
            ? false
            : true;
          if (!cannotFindLocation) {
            this.props.onChange(location.description);
          }
          this.setState({ loading: false, cannotFindLocation });
        });
    }
  }

  private onManualCloseClickHandler() {
    this.addressFormRef.update({
      [`houseNumber${this.id}`]: "",
      [`houseNumberPostfix${this.id}`]: "",
      [`locality${this.id}`]: "",
      [`postalCode${this.id}`]: "",
      [`street${this.id}`]: "",
    });
    this.setState({ address: null });
    this.props.onChange("");
  }

  private setManualAddress(result: google.maps.GeocoderResult[] | null) {
    const address = AddressUtil.mapGoogleGeoCoderResultsToAddress(result);

    this.addressFormRef.update({
      [`houseNumber${this.id}`]: value(address, "houseNumber"),
      [`houseNumberPostfix${this.id}`]: value(address, "houseNumberPostfix"),
      [`locality${this.id}`]: value(address, "locality.name"),
      [`postalCode${this.id}`]: value(address, "postalCode"),
      [`street${this.id}`]: value(address, "street.name"),
    });
    this.setState({ address, loading: false });
  }

  private renderCountryString(countryCode: string): string {
    return this.props.intl.formatMessage({
      id: `countryCode${countryCode}`,
      defaultMessage: countryCode,
    });
  }

  private renderOptionValue(
    location: google.maps.places.AutocompletePrediction,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(
          location.description,
          query
        )}
      />
    );
  }

  private renderSelectedStringValue(
    value: any
  ): QueryResultStringReturnValue<any> {
    const resultString = value.description;

    return {
      value,
      resultString,
    };
  }

  private renderFlagClassName(isoCode: string): string {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private onManualAddressChangeHandler(values: FormReturnValue) {
    let address: Address = {
      houseNumber: values[`houseNumber${this.id}`],
      houseNumberPostfix: values[`houseNumberPostfix${this.id}`],
      locality: {
        name: values[`locality${this.id}`],
      },
      postalCode: values[`postalCode${this.id}`],
      street: {
        name: values[`street${this.id}`],
      },
      countryIso2: this.state.countryIso2,
    };

    address = AddressUtil.validateSimpleAddress(address);
    this.props.onChange(address || "");
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}

export const LocationQueryComponent = injectIntl(
  LocationQuery
) as React.ComponentType<any>;
