import { SettingField, SettingsFieldType } from "@haywork/api/authorization";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  ValidatorFn,
  Validators
} from "@haywork/modules/form";
import { Ui } from "@haywork/modules/ui";
import { ColorUtil } from "@haywork/util";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AppXchangeSettingsContainerProps } from "../../containers";
import { Input, Section } from "./components";

const styles = require("./style.scss");
const COMBINEDTIME_PREFIX = "combinedTime.";

export enum AppXchangeSettingsType {
  Employee = "Employee",
  Company = "Company"
}

export interface AppXchangeSettingsComponentProps {
  type: AppXchangeSettingsType;
  appClientId: string;
}
interface State {
  formControls: FormControls;
}
type Props = AppXchangeSettingsComponentProps &
  AppXchangeSettingsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeSettingsComponent extends React.Component<Props, State> {
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      formControls: null
    };

    this.mapFormControls = this.mapFormControls.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public componentDidMount() {
    this.mapFormControls(this.props.fields || []);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.fields.length !== this.props.fields.length) {
      this.mapFormControls(this.props.fields || []);
    }
  }

  public render() {
    const loading =
      !this.state.formControls || this.props.settingsStatus === REQUEST.PENDING;

    return (
      <div styleName="settings">
        <PageHeader
          title="appXchangePartnerSettingsTitle"
          actions={
            <Button
              label="save"
              category="primary"
              disabled={loading}
              onClick={() => this.formRef.submit()}
            />
          }
        />

        <div styleName="settings__body">
          {!!loading && <Ui.Loaders.Fullscreen mask={true} />}

          {!!this.state.formControls && (
            <Form
              name={`AuthorizationSettings.${this.props.type}`}
              formControls={this.state.formControls}
              onSubmit={this.onSubmit}
              form={(form) => (this.formRef = form)}
            >
              {this.props.fields.map((field) => (
                <ErrorBoundary key={field.fieldName}>
                  <div className="form__row">{this.renderRowType(field)}</div>
                </ErrorBoundary>
              ))}
            </Form>
          )}
        </div>
      </div>
    );
  }

  private mapFormControls(fields: SettingField[]) {
    if (!fields || !fields.length) return;

    const formControls: FormControls = {};

    fields.map((field) => {
      const {
        linkedSettingFieldDefinition,
        numericValue,
        stringValue,
        dateTimeValue,
        fieldName,
        booleanValue,
        rgbValue
      } = field;

      if (
        !!linkedSettingFieldDefinition &&
        !!linkedSettingFieldDefinition.type &&
        linkedSettingFieldDefinition.type !== SettingsFieldType.Section
      ) {
        const {
          type,
          required,
          minLength,
          maxLength
        } = linkedSettingFieldDefinition;
        const validators = this.mapValidators(required, minLength, maxLength);

        switch (type) {
          case SettingsFieldType.Decimal:
          case SettingsFieldType.Number:
          case SettingsFieldType.Price: {
            formControls[fieldName] = {
              value: numericValue || "",
              validators
            };
            break;
          }
          case SettingsFieldType.Boolean: {
            formControls[fieldName] = {
              value: booleanValue || false,
              validators
            };
            break;
          }
          case SettingsFieldType.Base64Image:
          case SettingsFieldType.MultiLineText:
          case SettingsFieldType.SingleLineText:
          case SettingsFieldType.SingleSelect: {
            formControls[fieldName] = { value: stringValue || "", validators };
            break;
          }
          case SettingsFieldType.Date: {
            formControls[fieldName] = {
              value: dateTimeValue || "",
              validators
            };
            break;
          }
          case SettingsFieldType.DateTime: {
            formControls[fieldName] = {
              value: dateTimeValue || "",
              validators
            };
            formControls[`${COMBINEDTIME_PREFIX}${fieldName}`] = {
              value: !!dateTimeValue
                ? moment(dateTimeValue).format("HH:mm")
                : "09:00",
              validators,
              onChange: (ref) => {
                if (!ref.value) {
                  return {
                    [`${COMBINEDTIME_PREFIX}${fieldName}`]: !!dateTimeValue
                      ? moment(dateTimeValue).format("HH:mm")
                      : "09:00"
                  };
                }
              }
            };
            break;
          }
          case SettingsFieldType.MultiSelect: {
            formControls[fieldName] = {
              value: (stringValue || "").split(";"),
              validators
            };
            break;
          }
          case SettingsFieldType.RgbValue: {
            formControls[fieldName] = {
              value: ColorUtil.rgbObjectToHex(rgbValue),
              validators
            };
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    this.setState({ formControls });
  }

  private mapValidators(
    required?: boolean,
    minLength?: number,
    maxLength?: number
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (required) {
      validators.push(Validators.required());
    }

    if (!!minLength) {
      validators.push(Validators.minLength(minLength));
    }

    if (!!maxLength) {
      validators.push(Validators.maxLength(maxLength));
    }

    return validators;
  }

  private renderRowType(field: SettingField) {
    const type = get(field.linkedSettingFieldDefinition, "type");

    switch (type) {
      case SettingsFieldType.Section: {
        return <Section field={field} />;
      }
      case SettingsFieldType.Decimal:
      case SettingsFieldType.Number:
      case SettingsFieldType.Price:
      case SettingsFieldType.SingleLineText:
      case SettingsFieldType.MultiLineText:
      case SettingsFieldType.Date:
      case SettingsFieldType.DateTime:
      case SettingsFieldType.SingleSelect:
      case SettingsFieldType.MultiSelect:
      case SettingsFieldType.Boolean:
      case SettingsFieldType.RgbValue:
      case SettingsFieldType.Base64Image: {
        return <Input field={field} />;
      }
      default: {
        return null;
      }
    }
  }

  private onSubmit(values: FormReturnValue) {
    const keys = Object.keys(values);
    const combinedKeys = keys.filter((key) =>
      new RegExp(COMBINEDTIME_PREFIX, "gi").test(key)
    );

    if (!!combinedKeys.length) {
      combinedKeys.map((key) => {
        const parentKey = key.substring(COMBINEDTIME_PREFIX.length);
        const [hours, minutes] = values[key].split(":");
        delete values[key];

        if (!!values[parentKey]) {
          const date = values[parentKey];
          delete values[parentKey];

          values[parentKey] = moment(date)
            .set("hours", hours)
            .set("minutes", minutes)
            .utc()
            .toDate();
        }
      });
    }

    for (const key in values) {
      if (isArray(values[key])) {
        values[key] = values[key].join(";");
      }
    }

    const fields: SettingField[] = this.props.fields
      .filter((field) => {
        const { linkedSettingFieldDefinition } = field;
        return (
          !!linkedSettingFieldDefinition &&
          get(linkedSettingFieldDefinition, "type") !==
            SettingsFieldType.Section
        );
      })
      .map((field) => {
        const { linkedSettingFieldDefinition, fieldName } = field;
        let updatedField: SettingField = {
          fieldName
        };

        if (fieldName in values && !!linkedSettingFieldDefinition.type) {
          switch (linkedSettingFieldDefinition.type) {
            case SettingsFieldType.Date:
            case SettingsFieldType.DateTime: {
              updatedField = {
                ...updatedField,
                dateTimeValue: values[fieldName]
              };
              break;
            }
            case SettingsFieldType.Boolean: {
              updatedField = {
                ...updatedField,
                booleanValue: values[fieldName]
              };
              break;
            }
            case SettingsFieldType.Decimal:
            case SettingsFieldType.Number:
            case SettingsFieldType.Price: {
              updatedField = {
                ...updatedField,
                numericValue: values[fieldName]
              };
              break;
            }
            case SettingsFieldType.RgbValue: {
              updatedField = {
                ...updatedField,
                rgbValue: ColorUtil.hexToRgbObject(values[fieldName])
              };
              break;
            }
            case SettingsFieldType.Section: {
              break;
            }
            default: {
              updatedField = {
                ...updatedField,
                stringValue: values[fieldName]
              };
              break;
            }
          }
        }

        return updatedField;
      });

    const { type, appClientId } = this.props;
    if (type === AppXchangeSettingsType.Company) {
      this.props.saveCompanySettings(appClientId, fields);
    } else {
      this.props.saveEmployeeSettings(appClientId, fields);
    }
  }
}
