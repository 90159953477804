import { CreateAccountRequest, Provider } from "@haywork/api/mail";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  provider: Provider;
  accountName?: string;
  accountData: CreateAccountRequest;
  onChange: (values: FormReturnValue) => void;
  onNext: () => void;
  onBack: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AccountDataIMAPComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.formControls = {
      provider: { value: this.props.provider.type },
      accountName: { value: this.props.accountName || "" },
      emailAddress: {
        value: value(this.props.accountData, "emailAddress"),
        validators: [Validators.required(), Validators.email()],
      },
    };
  }

  public render() {
    return (
      <div styleName="imap-data">
        <div styleName="imap-data__body for-general">
          <div
            styleName="avatar"
            style={{
              backgroundImage: `url(${JSON.stringify(
                this.props.provider.logoUrl
              )})`,
            }}
          />
          <h1>
            <ResourceText resourceKey="imapDataEmailTitle" />
          </h1>
          <Form
            name="imap-account"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onChange={this.props.onChange}
            onSubmit={this.props.onNext}
          >
            <div styleName="centered-row">
              <label htmlFor="accountName">
                <ResourceText resourceKey="emailAccountName" />
              </label>
              <Input.Text
                name="accountName"
                placeholder="emailAccountNamePlaceholder"
              />
            </div>

            <div styleName="centered-row">
              <label htmlFor="emailAddress">
                <ResourceText resourceKey="emailAccountEmailAddress" />
              </label>
              <Input.Email
                name="emailAddress"
                data-cy="CY-emailAddressInput"
                placeholder="emailAccountEmailAddressPlaceholder"
              />
            </div>
          </Form>
        </div>
        <div styleName="footer">
          <div styleName="left">
            <button
              type="button"
              className="btn btn-gray"
              onClick={this.props.onBack}
            >
              <ResourceText resourceKey="back" />
            </button>
          </div>
          <div styleName="right">
            <button
              type="button"
              className="btn btn-primary"
              data-cy="CY-submitButton"
              onClick={this.onSubmitHandler}
            >
              <ResourceText resourceKey="next" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitHandler() {
    this.formRef.submit();
  }
}
