import {
  Filter,
  FilterConfig,
  FilterSection,
  ListFilter,
  ListRefProps
} from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo, MutableRefObject, useCallback, useMemo } from "react";
import { FiltersContainerProps } from "./filters.container";
import { useIntl } from "react-intl";
import { InvoiceStatus } from "@haywork/api/kolibri";

export type FiltersComponentProps = {
  list: MutableRefObject<ListRefProps>;
};
type Props = FiltersComponentProps & FiltersContainerProps;

export const FiltersComponent: FC<Props> = memo(
  ({
    filters,
    list,
    setFilters,
    filterByActiveValues,
    statusFilterValues,
    invoiceDateValue,
    overdueValue
  }) => {
    const intl = useIntl();

    const updateFilters = useCallback(
      (filters: FilterConfig) => {
        if (!list || !list.current) return;
        setFilters(filters);
        list.current.refresh();
      },
      [list, setFilters]
    );

    const statusSubTitle = useMemo(() => {
      const { statusFilter } = filters;
      if (!statusFilter) return;
      const invoiceStatuses: InvoiceStatus[] = statusFilter.value || [];

      return invoiceStatuses
        .map((key) =>
          intl.formatMessage({
            id: `invoiceStatuses.${key.toString()}`,
            defaultMessage: key.toString()
          })
        )
        .join(", ");
    }, [filters.statusFilter]);

    return (
      <ListFilter filters={filters} onChange={updateFilters}>
        <FilterSection title="invoices.filter.title.filterByActive">
          <Filter.CheckboxArray
            configKey="filterByActive"
            values={filterByActiveValues}
          />
        </FilterSection>

        <FilterSection
          title="invoices.filter.title.statusFilter"
          subtitle={statusSubTitle}
          collapsable
          expand="horizontal"
        >
          <Filter.CheckboxArray
            configKey="statusFilter"
            values={statusFilterValues}
          />
        </FilterSection>

        <FilterSection title="assignment.filter.title.invoiceDate">
          <Filter.DateRange configKey="invoiceDate" value={invoiceDateValue} />
        </FilterSection>

        <FilterSection title="assignment.filter.title.overdue">
          <Filter.DateRange configKey="overdue" value={overdueValue} />
        </FilterSection>
      </ListFilter>
    );
  }
);
