import * as React from "react";
import { memo, FC, ReactNode } from "react";
import I18n from "../../i18n";
import Icon from "../icon";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  icon: string;
  title?: string;
  titleValues?: { [key: string]: any };
  subTitle?: string;
  subTitleValues?: { [key: string]: any };
  action?: ReactNode;
  centered?: boolean;
};
export const UiEmptyStateComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      icon,
      title,
      titleValues,
      subTitle,
      subTitleValues,
      action,
      centered,
    }) => {
      return (
        <div styleName={classNames("empty-state", { centered })}>
          <div styleName="inner">
            <div styleName="icon">
              <Icon name={icon} size={96} color={Colors.LightGray} light />
            </div>
            {!!title && (
              <h1>
                <I18n value={title} values={titleValues} />
              </h1>
            )}
            {!!subTitle && (
              <h2>
                <I18n value={subTitle} values={subTitleValues} />
              </h2>
            )}
            {!!action && <div styleName="actions">{action}</div>}
          </div>
        </div>
      );
    }
  )
);
