import { AssignmentSnapShot, Office } from "@haywork/api/kolibri";
import { AssignmentThunks, Dispatch } from "@haywork/middleware/thunk";
import {
  OfficeAssignmentComponent,
  OfficeAssignmentComponentProps
} from "@haywork/modules/settings/modules/offices/components/assignments";
import { AppState, OfficeActions } from "@haywork/stores";
import { AssignmentUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  selectedOffice?: Office;
  officeAssignmentState?: string;
  officeAssignmentInitialState?: string;
  selectedOfficeDisplayName?: string;
  officeAssignments?: AssignmentSnapShot[];
  canLoadMore?: boolean;
}

interface DispatchProps {
  getOfficeAssignments?: (officeId: string, init?: boolean) => void;
  goToAssignmentDetail?: (assignment: AssignmentSnapShot) => void;
  clearAssignmentList?: () => void;
}

const mapStateToProps = <StateProps, OfficeAssignmentComponentProps>(
  state: AppState
) => ({
  selectedOffice: state.offices.selectedOffice,
  officeAssignments: state.offices.officeAssignments,
  officeAssignmentState: state.offices.officeAssignmentsState,
  officeAssignmentInitialState: state.offices.officeAssignmentInitialState,
  canLoadMore:
    state.offices.officeAssignmentPage <
    state.offices.officeAssignmentPageCount,
  selectedOfficeDisplayName: state.offices.selectedOffice.name
});

const mapDispatchToProps = <DispatchProps, OfficeAssignmentComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getOfficeAssignments: (officeId: string, init?: boolean) =>
    dispatch(
      AssignmentThunks.getAssignmentsForOfficeDetail(officeId, init, 10)
    ),
  goToAssignmentDetail: (assignment: AssignmentSnapShot) =>
    dispatch(push(AssignmentUtil.getAssignmentPath(assignment))),
  clearAssignmentList: () => dispatch(OfficeActions.clearAssignmentList())
});

export type OfficeAssignmentContainerProps = StateProps & DispatchProps;
export const OfficeAssignmentContainer = connect<
  StateProps,
  DispatchProps,
  OfficeAssignmentComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(OfficeAssignmentComponent);
