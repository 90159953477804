import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import Time from "../widget-time";
import head from "lodash-es/head";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./style.scss");

interface Props {
  phoneNumber: string | null;
  callStartedOn: number | null;
  callEndedOn: number | null;
  linkedRelation: RelationSnapShot;
}

export const VoipWidgetBodyRelationComponent = CSSModules(styles, {
  allowMultiple: true
})(({ phoneNumber, linkedRelation, callEndedOn, callStartedOn }: Props) => {
  const { avatarUrl, displayName, typeOfRelation } = linkedRelation;

  return (
    <div styleName="body">
      <div
        styleName="body__avatar"
        style={{
          backgroundImage: !!avatarUrl
            ? `url(${JSON.stringify(avatarUrl)})`
            : null
        }}
      >
        {!avatarUrl && <i className="fal fa-user" />}
      </div>
      <div styleName="body__meta">
        <div styleName="display-name">{displayName}</div>
        <div styleName="phone-number">
          {typeOfRelation === RelationType.Employee ? (
            <ResourceText resourceKey="voip.internalCall" />
          ) : (
            phoneNumber
          )}
        </div>
      </div>
      <Time callStartedOn={callStartedOn} callEndedOn={callEndedOn} />
    </div>
  );
});
