import * as React from "react";
import { CSSProperties, FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { PillItem } from "../../email-with-relation-groups";
import I18n from "@haywork/components/i18n";
import { ColorUtil } from "@haywork/util";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  item: PillItem;
  onClick: (item: any) => void;
  onMouseDown: () => void;
  idx: number;
  selected?: boolean;
  dataCy: string;
}
export const FormSuggestionResultComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, onClick, onMouseDown, dataCy, idx, selected }) => {
      const { displayName, email, color, isRelationGroup, isActive } = item;
      if (!displayName && !email) return null;

      const dotStyle: CSSProperties = !!color
        ? { backgroundColor: ColorUtil.hexToRgb(color) }
        : null;

      return (
        <div
          styleName={classNames("item", { selected })}
          onMouseUp={() => onClick(item)}
          onMouseDown={onMouseDown}
          data-cy={dataCy + "-result-" + idx}
        >
          <div styleName="item__display">
            {isRelationGroup && <div styleName="dot" style={dotStyle} />}
            <div styleName="display">
              {!!email && <div styleName="email">{email}</div>}
              {!!displayName && <div styleName="name">{displayName}</div>}
            </div>
          </div>
          {isRelationGroup && (
            <div styleName="relation-group">
              <I18n value="relationGroup" />
            </div>
          )}
          {!isActive && (
            <div styleName="archived">
              <I18n value="archived" />
            </div>
          )}
        </div>
      );
    }
  )
);
