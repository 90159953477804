import { MapDispatchToProps } from "@haywork/middleware";
import { MappedCheckboxValue } from "@haywork/modules/filter";
import { AppState } from "@haywork/stores";
import {
  AcquisitionActions,
  AcquisitionFilters,
} from "@haywork/stores/acquisition";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionListFiltersComponent,
  AcquisitionListFiltersComponentProps,
} from "./list-filters";
import {
  assignmentPhases,
  employees,
  offices,
  realEstateGroups,
} from "./selectors";

type StateProps = {
  filters: AcquisitionFilters;
  realEstateGroupsValues: MappedCheckboxValue[];
  kindOfAssignmentValues: MappedCheckboxValue[];
  assignmentPhasesValues: MappedCheckboxValue[];
  officesValues: MappedCheckboxValue[];
  employeesValues: MappedCheckboxValue[];
};
type DispatchProps = {
  setFilters: (filters: AcquisitionFilters) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionListFiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.acquisition.list;

  return {
    filters,
    realEstateGroupsValues: realEstateGroups(state),
    kindOfAssignmentValues: [
      { label: "sale", value: "sale" },
      { label: "rentLease", value: "rentLease" },
    ],
    assignmentPhasesValues: assignmentPhases(state),
    officesValues: offices(state),
    employeesValues: employees(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionListFiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: AcquisitionFilters) =>
    dispatch(AcquisitionActions.List.setFilters(filters)),
});

export type AcquisitionListFiltersContainerProps = StateProps & DispatchProps;
export const AcquisitionListFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionListFiltersComponent);
