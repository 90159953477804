import { REDUX } from "@haywork/constants";

import * as ActionType from "./error.types";

const setError = (payload: ActionType.Error) => ({
  type: REDUX.ERRORS.SET_ERROR,
  ...payload
});

const removeError = () => ({
  type: REDUX.ERRORS.CLOSE_ERROR
});

const setUnauthorized = (unauthorized: boolean) => ({
  type: REDUX.ERRORS.SET_UNAUTHORIZED,
  unauthorized
});

const addError = (playload: ActionType.ReactError) => ({
  type: REDUX.ERRORS.ADD_ERROR,
  ...playload
});

export const ErrorActions = {
  setError,
  removeError,
  setUnauthorized,
  addError
};
