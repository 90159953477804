import { Dispatch } from ".";
import first from "lodash-es/first";
import * as moment from "moment";

import { AppState, RemindersActions } from "@haywork/stores";
import { EmployeeRole, RemindersClient } from "@haywork/api/kolibri";
import { REQUEST, COUNTS } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  EventNotificationsClient,
  EventNotificationsOrderByField,
  ArchiveFilter,
  EventType,
  SortOrder,
} from "@haywork/api/event-center";

const parseRequest = new ParseRequest();

const getReminders = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { role } = state.account.currentRealestateAgency;
    if (role === EmployeeRole.BackOffice) return;

    const { eventCenterHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: employeeId } = state.account.employee;

    const Reminders = new EventNotificationsClient(eventCenterHost);

    try {
      const reminders = await parseRequest.response(
        Reminders.search(
          {
            employeeId,
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            includeStatistics: false,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Descending,
            skip: 0,
            take: COUNTS.REMINDERS,
            filterByEventTypes: [
              EventType.AgendaItemReminder,
              EventType.TaskReminder,
            ],
            maxEventNotificationDateTime: moment().utc().toDate(),
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(RemindersActions.setReminders({ reminders }));
    } catch (error) {
      dispatch(RemindersActions.setRemindersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deleteAllReminders = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RemindersActions.setRemindersStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { reminders } = state.reminders;

    const Reminders = new RemindersClient(host);

    try {
      const reminderRequests = reminders
        .map((reminder) => {
          const { linkedEvent } = reminder;
          let id;

          switch (linkedEvent.eventType) {
            case EventType.AgendaItemReminder: {
              const reminders = linkedEvent.linkedAgendaItems || [];
              const reminder = first(reminders);

              id = !reminder ? null : reminder.id;
              break;
            }
            case EventType.TaskReminder: {
              const reminders = linkedEvent.linkedTasks || [];
              const reminder = first(reminders);

              id = !reminder ? null : reminder.id;
              break;
            }
            default:
              return;
          }

          return !id
            ? null
            : parseRequest.response(
                Reminders.disableReminder({ id }, realEstateAgencyId)
              );
        })
        .filter((p) => !!p);

      await Promise.all(reminderRequests);

      dispatch(RemindersActions.clearReminders());
    } catch (error) {
      dispatch(RemindersActions.setRemindersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const rescheduleAllReminders = (minutesToPostpone: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { reminders } = state.reminders;

    const Reminders = new RemindersClient(host);

    try {
      const reminderRequests = reminders
        .map((reminder) => {
          const { linkedEvent } = reminder;
          let id;

          switch (linkedEvent.eventType) {
            case EventType.AgendaItemReminder: {
              const reminders = linkedEvent.linkedAgendaItems || [];
              const reminder = first(reminders);

              id = !reminder ? null : reminder.id;
              break;
            }
            case EventType.TaskReminder: {
              const reminders = linkedEvent.linkedTasks || [];
              const reminder = first(reminders);

              id = !reminder ? null : reminder.id;
              break;
            }
            default:
              break;
          }

          return id;
        })
        .filter((id) => !!id)
        .map((id) =>
          parseRequest.response(
            Reminders.rescheduleReminder(
              {
                id,
                minutesToPostpone,
              },
              realEstateAgencyId
            )
          )
        );

      await Promise.all(reminderRequests);

      dispatch(RemindersActions.clearReminders());
    } catch (error) {
      dispatch(RemindersActions.setRemindersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deleteReminder = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RemindersActions.setRemindersStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Reminders = new RemindersClient(host);

    try {
      await parseRequest.response(
        Reminders.disableReminder({ id }, realEstateAgencyId)
      );

      dispatch(RemindersActions.clearReminder(id));
    } catch (error) {
      dispatch(RemindersActions.setRemindersStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export const ReminderThunks = {
  getReminders,
  deleteAllReminders,
  deleteReminder,
  rescheduleAllReminders,
};
