import { RootEntityType } from "@haywork/api/event-center";
import { PhotoBlob, RealEstateAgency } from "@haywork/api/kolibri";
import { REALESTATEAGENCYROUTES } from "@haywork/constants";
import { Dispatch, RealEstateAgencyThunks } from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { RealEstateAgencyDetailComponent } from "@haywork/modules/real-estate-agency";
import { AppState, RealEstateAgencyActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  realEstateAgency: RealEstateAgency;
  realEstateAgencyStatus: string;
  path: string;
}

interface DispatchProps {
  navigate: (url: string) => void;
  updateLogo: (newLogo: PhotoBlob, realEstateAgencyId: string) => void;
  save: (componentState: RealEstateAgency, path: string) => void;
}

const mapStateToProps = <StateProps, RealEstateAgencyDetailComponentProps>(
  state: AppState
) => {
  const { realEstateAgencyStatus } = state.realEstateAgency;
  const realEstateAgency = state.editable.currentComponentState;
  const path = route(REALESTATEAGENCYROUTES.DETAIL.URI, {
    id: realEstateAgency.id,
  });

  return {
    realEstateAgency,
    realEstateAgencyStatus,
    path,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  RealEstateAgencyDetailComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  updateLogo: (newLogo: PhotoBlob, realEstateAgencyId: string) =>
    dispatch(RealEstateAgencyThunks.updateLogo(newLogo, realEstateAgencyId)),
  save: (componentState: RealEstateAgency, path: string) =>
    dispatch(RealEstateAgencyThunks.saveRealEstateAgency(componentState, path)),
});

export type RealEstateAgencyDetailContainerProps = StateProps &
  DispatchProps &
  EditableHocProps &
  RouteComponentProps<any>;
export const RealEstateAgencyDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(RealEstateAgencyDetailComponent)
  ),
  {
    entityType: RootEntityType.RealEstateAgency,
    icon: "building",
    thunk: RealEstateAgencyThunks.getRealEstateAgency,
    status: "realEstateAgency.realEstateAgencyStatus",
    statePath: "realEstateAgency.realEstateAgency",
    action: RealEstateAgencyActions.setRealEstateAgency,
    route: REALESTATEAGENCYROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveRealEstateAgentConfirmTitle" },
      body: { key: "saveRealEstateAgentConfirmBody" },
    },
  }
);
