import { LinkedEmployee, RelationType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { EMPLOYEEROUTES, REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  OfficeEmployeeContainerProps,
  OfficeEmployeeListItemComponent,
} from "@haywork/modules/settings";
import { ButtonLoader, InfiniteScroll } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface OfficeEmployeeComponentProps {}
interface OfficeEmployeeComponentState {
  addingContact: boolean;
  relationName: string;
  showEmptyState: boolean;
}
type Props = OfficeEmployeeComponentProps & OfficeEmployeeContainerProps;

const styles = require("./office-employee.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class OfficeEmployeeComponent extends React.Component<
  Props,
  OfficeEmployeeComponentState
> {
  private contactPersonInput: HTMLInputElement;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.toggleAddingContact = this.toggleAddingContact.bind(this);
    this.onEmployeeClickHandler = this.onEmployeeClickHandler.bind(this);
    this.onAddEmployeeSubmitForContactPerson = this.onAddEmployeeSubmitForContactPerson.bind(
      this
    );

    this.state = {
      addingContact: false,
      relationName: this.props.selectedOfficeDisplayName,
      showEmptyState: true,
    };
  }

  public componentDidMount() {
    this.props.clearEmployeeList(this.props.office);
    this.props.getOfficeInfo(this.props.office.id);
  }

  public render() {
    return (
      <div styleName="office-overview">
        <PageHeader
          title="pageTitle.offices.employees"
          subTitle={this.state.relationName}
          actions={
            <Button
              label="settingsAddEmployee"
              icon={<Icon name="plus" size={18} light />}
              category="primary"
              onClick={this.toggleAddingContact}
            />
          }
        />

        <div styleName="summary">
          <span>
            <I18n
              value="employeeAmount"
              asHtml
              values={{ employeeAmount: this.props.totalEmployeeCount }}
            />
          </span>
        </div>

        {this.props.linkedEmployees.length > 0 ? (
          this.renderEmployees()
        ) : this.state.showEmptyState ? (
          <div styleName="emptyState">
            <div styleName="content">
              <div styleName="icon" />
              <div styleName="text">
                <h2>
                  <I18n value="officeHasNoEmployees" />
                </h2>
              </div>
            </div>
          </div>
        ) : (
          this.renderAddContact()
        )}
      </div>
    );
  }

  private renderEmployees(): JSX.Element {
    return (
      <div styleName="list">
        {this.props.officeEmployeeInitialOverviewState === REQUEST.PENDING && (
          <Ui.Loaders.Fullscreen mask />
        )}

        <InfiniteScroll
          scrollEnd={() => {
            return;
          }}
        >
          {this.props.contactAddError && this.renderContactAddError()}

          {this.state.addingContact && (
            <div styleName="add-contact">
              <div styleName="add-contact__avatar">
                <div styleName="avatar" />
              </div>
              {this.renderAddContact()}
            </div>
          )}

          {this.props.linkedEmployees.map((employee, idx) => (
            <ErrorBoundary key={idx}>
              <OfficeEmployeeListItemComponent
                employee={employee}
                zebra={idx % 2 === 0}
                onEmployeeClickHandler={this.onEmployeeClickHandler}
                onOfficeEmployeeArchive={(employeeid: string) =>
                  this.props.onOfficeEmployeeArchive(
                    this.props.office,
                    employeeid
                  )
                }
                onOfficeEmployeeUnarchive={(employeeid: string) =>
                  this.props.onOfficeEmployeeUnarchive(
                    this.props.office,
                    employeeid
                  )
                }
                removeEmployeeFromList={(employeeid: string) =>
                  this.props.removeEmployeeFromList(
                    this.props.office,
                    employeeid
                  )
                }
              />
            </ErrorBoundary>
          ))}
        </InfiniteScroll>
      </div>
    );
  }

  private toggleAddingContact() {
    this.props.resetSaveStatus();
    this.setState(
      {
        addingContact: !this.state.addingContact,
        showEmptyState: !this.state.showEmptyState,
      },
      () => {
        if (this.contactPersonInput) {
          this.contactPersonInput.focus();
        }
      }
    );
  }

  private renderAddContact(): React.ReactElement<HTMLDivElement> {
    return this.renderAddContactPersonForm();
  }

  private renderAddContactPersonForm() {
    const formControls: FormControls = {
      linkedContacts: { value: [] },
    };

    return (
      <div styleName="add-contact__body">
        <Form
          formControls={formControls}
          name="contactLink"
          onSubmit={this.onAddEmployeeSubmitForContactPerson}
          form={(form) => (this.formRef = form)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.RelationQuery
                  name="linkedContacts"
                  multiple
                  filterByRelationTypes={[RelationType.Employee]}
                  onNavigateToRelation={this.props.navigate}
                />
              </div>
            </div>
            <div styleName="action">
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.props.employeeSaveState === REQUEST.PENDING}
              >
                <ButtonLoader
                  loading={this.props.employeeSaveState === REQUEST.PENDING}
                  resourceKey="add"
                />
                {this.props.employeeSaveState === REQUEST.SUCCESS &&
                  this.toggleAddingContact()}
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private renderContactAddError() {
    return (
      <div styleName="error" className="alert alert-danger">
        <I18n value="contactAlreadyExistsError" />
      </div>
    );
  }

  private onAddEmployeeSubmitForContactPerson(values: FormReturnValue) {
    const office = this.props.office;
    values.linkedContacts.map((contact) => {
      const { id } = contact;

      if (id) {
        this.props.saveContactLink(id, office);
      }
    });
  }

  private onEmployeeClickHandler(employee: LinkedEmployee) {
    const { id } = employee;
    const url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
    this.props.goToEmployeeDetail(url);
  }
}
