import {
  ObjectAssignment,
  PointOfInterest,
  PointOfInterestType,
} from "@haywork/api/kolibri";
import { DistanceFromTo } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { DistanceUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const distance = DistanceUtil.getPoiDistanceFromTo;

interface Props {
  objectAssignment: ObjectAssignment;
  onChange: (values: FormReturnValue) => void;
}
interface PoiAccessibility {
  busStop: DistanceFromTo;
  busHub: DistanceFromTo;
  metroStop: DistanceFromTo;
  metroHub: DistanceFromTo;
  railwayStation: DistanceFromTo;
  nsContent: DistanceFromTo;
  highwayExit: DistanceFromTo;
  tramStop: DistanceFromTo;
  tramHub: DistanceFromTo;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogPoiAccessibilityComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private distanceFromToOptions: DistanceFromTo[] = [
    DistanceFromTo.DistanceNone,
    DistanceFromTo.LessThanFiveHundred,
    DistanceFromTo.FiveHundredUntillOneThousand,
    DistanceFromTo.OneThousandUntillFifteenHundred,
    DistanceFromTo.FifteenHundredUntillTwoThousand,
    DistanceFromTo.TwoThousandUntillThreeThousand,
    DistanceFromTo.ThreeThousandUntillFourThousand,
    DistanceFromTo.FourThousandUntillFiveThousand,
    DistanceFromTo.MoreThanFiveThousand,
  ];
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const values = this.mapPoiValues(objectAssignment.pointsOfInterest);

    this.formControls = {
      busStop: { value: values.busStop },
      busHub: { value: values.busHub },
      metroStop: { value: values.metroStop },
      metroHub: { value: values.metroHub },
      railwayStation: { value: values.railwayStation },
      nsContent: { value: values.nsContent },
      highwayExit: { value: values.highwayExit },
      tramStop: { value: values.tramStop },
      tramHub: { value: values.tramHub },
    };

    this.createPoiControl = this.createPoiControl.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const values = this.mapPoiValues(objectAssignment.pointsOfInterest);

      this.formRef.update(
        {
          busStop: values.busStop,
          busHub: values.busHub,
          metroStop: values.metroStop,
          metroHub: values.metroHub,
          railwayStation: values.railwayStation,
          nsContent: values.nsContent,
          highwayExit: values.highwayExit,
          tramStop: values.tramStop,
          tramHub: values.tramHub,
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="retail-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        {this.createPoiControl("busStop")}
        {this.createPoiControl("busHub")}
        {this.createPoiControl("metroStop")}
        {this.createPoiControl("metroHub")}
        {this.createPoiControl("railwayStation")}
        {this.createPoiControl("nsContent")}
        {this.createPoiControl("highwayExit")}
        {this.createPoiControl("tramStop")}
        {this.createPoiControl("tramHub")}
      </Form>
    );
  }

  private createPoiControl(name: string): React.ReactElement<HTMLDivElement> {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column">
            <label htmlFor={name} styleName="poi-label">
              <ResourceText resourceKey={name} />
            </label>
          </div>
          <div className="column__spacer" />
          <div className="column" styleName="poi-select">
            <Input.NewSelect
              name={name}
              values={this.distanceFromToOptions}
              translate
            />
          </div>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const rawPointsOfInterest = objectAssignment.pointsOfInterest || [];
    const types = [
      PointOfInterestType.BusStop,
      PointOfInterestType.BusHub,
      PointOfInterestType.MetroStop,
      PointOfInterestType.MetroHub,
      PointOfInterestType.RailwayStation,
      PointOfInterestType.NsContent,
      PointOfInterestType.HighwayExit,
      PointOfInterestType.TramStop,
      PointOfInterestType.TramHub,
    ];

    const pointsOfInterest: PointOfInterest[] = rawPointsOfInterest.filter(
      (poi) => {
        return types.indexOf(poi.type) === -1;
      }
    );

    for (const key in values) {
      const value = values[key];
      if (value !== DistanceFromTo.DistanceNone)
        pointsOfInterest.push(this.mapValueToPoi(value, key));
    }

    this.props.onChange({ pointsOfInterest });
  }

  private mapValueToPoi(
    value: DistanceFromTo,
    poiType: string
  ): PointOfInterest {
    let distanceFrom = undefined;
    let distanceTo = undefined;
    let type = undefined;

    switch (value) {
      case DistanceFromTo.LessThanFiveHundred:
        distanceTo = 500;
        break;
      case DistanceFromTo.FiveHundredUntillOneThousand:
        distanceFrom = 500;
        distanceTo = 1000;
        break;
      case DistanceFromTo.OneThousandUntillFifteenHundred:
        distanceFrom = 1000;
        distanceTo = 1500;
        break;
      case DistanceFromTo.FifteenHundredUntillTwoThousand:
        distanceFrom = 1500;
        distanceTo = 2000;
        break;
      case DistanceFromTo.TwoThousandUntillThreeThousand:
        distanceFrom = 2000;
        distanceTo = 3000;
        break;
      case DistanceFromTo.ThreeThousandUntillFourThousand:
        distanceFrom = 3000;
        distanceTo = 4000;
        break;
      case DistanceFromTo.FourThousandUntillFiveThousand:
        distanceFrom = 4000;
        distanceTo = 5000;
        break;
      case DistanceFromTo.MoreThanFiveThousand:
        distanceFrom = 5000;
        break;
      default:
        break;
    }

    switch (poiType) {
      case "busStop":
        type = PointOfInterestType.BusStop;
        break;
      case "busHub":
        type = PointOfInterestType.BusHub;
        break;
      case "metroStop":
        type = PointOfInterestType.MetroStop;
        break;
      case "metroHub":
        type = PointOfInterestType.MetroHub;
        break;
      case "railwayStation":
        type = PointOfInterestType.RailwayStation;
        break;
      case "nsContent":
        type = PointOfInterestType.NsContent;
        break;
      case "highwayExit":
        type = PointOfInterestType.HighwayExit;
        break;
      case "tramStop":
        type = PointOfInterestType.TramStop;
        break;
      case "tramHub":
        type = PointOfInterestType.TramHub;
        break;
      default:
        break;
    }

    return {
      distanceFrom,
      distanceTo,
      type,
    };
  }

  private mapPoiValues(values: PointOfInterest[]): PoiAccessibility {
    const poiValues = values || [];

    const poi = {
      busStop: DistanceFromTo.DistanceNone,
      busHub: DistanceFromTo.DistanceNone,
      metroStop: DistanceFromTo.DistanceNone,
      metroHub: DistanceFromTo.DistanceNone,
      railwayStation: DistanceFromTo.DistanceNone,
      nsContent: DistanceFromTo.DistanceNone,
      highwayExit: DistanceFromTo.DistanceNone,
      tramStop: DistanceFromTo.DistanceNone,
      tramHub: DistanceFromTo.DistanceNone,
    };

    poiValues.map((value) => {
      switch (value.type) {
        case PointOfInterestType.BusStop:
          poi.busStop = distance(value);
          break;
        case PointOfInterestType.BusHub:
          poi.busHub = distance(value);
          break;
        case PointOfInterestType.MetroStop:
          poi.metroStop = distance(value);
          break;
        case PointOfInterestType.MetroHub:
          poi.metroHub = distance(value);
          break;
        case PointOfInterestType.RailwayStation:
          poi.railwayStation = distance(value);
          break;
        case PointOfInterestType.NsContent:
          poi.nsContent = distance(value);
          break;
        case PointOfInterestType.HighwayExit:
          poi.highwayExit = distance(value);
          break;
        case PointOfInterestType.TramStop:
          poi.tramStop = distance(value);
          break;
        case PointOfInterestType.TramHub:
          poi.tramHub = distance(value);
          break;
        default:
          break;
      }
    });

    return poi;
  }
}
