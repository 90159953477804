import * as React from "react";
import { FC, memo, useMemo, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  value: string;
  values?: { [key: string]: string | number };
  prefix?: string;
  fallback?: string;
  type?: "header" | "subheader" | "paragraph";
  asSection?: boolean;
  color?: string;
  bold?: boolean;
  medium?: boolean;
  light?: boolean;
  chain?: boolean;
  textStyle?: "uppercase" | "lowercase" | "capitalized" | "ucfirst";
  skipTranslation?: boolean;
  addStyle?: CSSProperties;
};

export const FormattedTextComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      value,
      values,
      prefix,
      fallback,
      type,
      asSection,
      color,
      bold,
      medium,
      light,
      chain,
      textStyle,
      skipTranslation,
      addStyle,
    }) => {
      const style = useMemo(() => {
        let styles: CSSProperties = {
          ...(addStyle || {}),
          color: Colors.TextBlack,
        };

        if (!!color) {
          styles = {
            ...styles,
            color,
          };
        }

        if (!!bold) {
          styles = {
            ...styles,
            fontWeight: 700,
          };
        }

        if (!!medium) {
          styles = {
            ...styles,
            fontWeight: 600,
          };
        }

        if (!!light) {
          styles = {
            ...styles,
            fontWeight: 300,
          };
        }

        if (!!chain) {
          styles = {
            ...styles,
            marginBottom: 0,
          };
        }

        return styles;
      }, [color, bold, medium, light, chain]);

      return (
        <div
          style={style}
          styleName={classNames("formatted-text", textStyle, type, {
            section: !!asSection,
          })}
        >
          {skipTranslation ? (
            value
          ) : (
            <I18n
              value={value}
              values={values}
              prefix={prefix}
              fallback={fallback}
              asHtml
            />
          )}
        </div>
      );
    }
  )
);
