import { connect, MapStateToProps } from "react-redux";
import { AppState } from "@haywork/stores";
import {
  AuthorizationWidgetsWidgetModalComponent,
  AuthorizationWidgetsWidgetModalComponentProps
} from "./widget-modal";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import { InitializedWidget, WidgetSnapShot } from "@haywork/api/authorization";

interface StateProps {
  widgetModalWidget: WidgetSnapShot;
  widgetModalInitializedWidget: InitializedWidget;
  widgetModalVisibility: boolean;
}
interface DispatchProps {
  hideModal: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AuthorizationWidgetsWidgetModalComponentProps,
  AppState
> = (state) => {
  const {
    widgetModalWidget,
    widgetModalInitializedWidget,
    widgetModalVisibility
  } = state.authorization.widgets;

  return {
    widgetModalWidget,
    widgetModalInitializedWidget,
    widgetModalVisibility
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AuthorizationWidgetsWidgetModalComponentProps
> = (dispatch) => ({
  hideModal: () => dispatch(AuthorizationThunks.Widgets.hideModal())
});

export type AuthorizationWidgetsWidgetModalContainerProps = StateProps &
  DispatchProps;
export const AuthorizationWidgetsWidgetModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizationWidgetsWidgetModalComponent);
