import {
  ActiveFilter,
  AssignmentSnapShot,
  AssignmentType,
  DocumentSession,
  DocumentTemplate,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationTermField,
  RelationType,
} from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { EditableCalleeType, NewEntityType } from "@haywork/enum";
import {
  DynamicDocumentsInfoContainerProps,
  TypeOfDocument,
} from "@haywork/modules/dynamic-documents";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { ConfirmComponent, ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./info.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

export interface DynamicDocumentsInfoComponentProps {}
interface State {
  showCadastreWarning: boolean;
  typeOfDocument: TypeOfDocument;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  linkedAssignment: LinkedAssignment;
  confirmOverwriteVisible: boolean;
}
type Props = DynamicDocumentsInfoComponentProps &
  DynamicDocumentsInfoContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsInfoComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { session } = this.props.currentComponentState;

    this.formControls = {
      linkedAssignment: {
        value: value(session, "linkedAssignment"),
        onChange: (ref) => {
          if (ref.value) {
            return this.linkRelationsFromAssignment(ref);
          } else {
            this.setState({ linkedAssignment: null });
            return {
              linkedVendors: "",
              linkedApplicants: "",
              linkedNotary: "",
            };
          }
        },
      },
      linkedVendors: { value: value(session, "linkedVendors") },
      vendorsActOnBehalfOf: {
        value: value(session.vendorsSigningInfo, "actingOnBehalfOf"),
      },
      linkedApplicants: { value: value(session, "linkedApplicants") },
      applicantsActOnBehalfOf: {
        value: value(session.applicantsSigningInfo, "actingOnBehalfOf"),
      },
      linkedNotary: { value: value(session, "linkedNotary") },
      linkedPropertyManagers: {
        value: value(session, "linkedPropertyManagers"),
      },
    };

    this.state = {
      showCadastreWarning: false,
      typeOfDocument: TypeOfDocument.Other,
      newEntityVisible: false,
      newEntityOptions: null,
      linkedAssignment: null,
      confirmOverwriteVisible: this.props.documentHasExternalChanges,
    };

    this.autoFillAssignmentValues = this.autoFillAssignmentValues.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
    this.addCadastreToObject = this.addCadastreToObject.bind(this);
    this.setDocumentType = this.setDocumentType.bind(this);
    this.linkRelationsFromAssignment = this.linkRelationsFromAssignment.bind(
      this
    );
    this.onCancelOverwrite = this.onCancelOverwrite.bind(this);
    this.onConfirmOverwrite = this.onConfirmOverwrite.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.documentHasExternalChanges &&
      this.props.documentHasExternalChanges
    ) {
      this.setState({ confirmOverwriteVisible: true });
    }
  }

  public componentDidMount() {
    if (
      this.props.currentComponentState &&
      this.props.currentComponentState.session
    ) {
      this.setDocumentType(
        this.props.currentComponentState.session,
        this.props.currentComponentState.template
      );
    }
  }

  public render() {
    const { session, template } = this.props.currentComponentState;

    let linkedVendorsResourceKey = "dynamicDocumentsLinkedProvider";
    let linkedApplicantsResourceKey = "dynamicDocumentsLinkedRequester";

    if (!!template.vendorType) {
      const vendorTypeOption = this.props.vendorTypeOptions.find(
        (option) => option.value === template.vendorType
      );
      linkedVendorsResourceKey = `vendorTypeOptions.${vendorTypeOption.value}`;
    } else {
      switch (this.state.typeOfDocument) {
        case TypeOfDocument.ForSale:
          linkedVendorsResourceKey = "dynamicDocumentsLinkedSellers";
          break;
        case TypeOfDocument.ForRent:
          linkedVendorsResourceKey = "dynamicDocumentsLinkedRenters";
          break;
        default:
          break;
      }
    }

    if (!!template.applicantType) {
      const vendorTypeOption = this.props.applicantTypeOptions.find(
        (option) => option.value === template.applicantType
      );
      linkedVendorsResourceKey = `applicantTypeOptions.${vendorTypeOption.value}`;
    } else {
      switch (this.state.typeOfDocument) {
        case TypeOfDocument.ForSale:
          linkedApplicantsResourceKey = "dynamicDocumentsLinkedBuyers";
          break;
        case TypeOfDocument.ForRent:
          linkedApplicantsResourceKey = "dynamicDocumentsLinkedTenants";
          break;
        default:
          break;
      }
    }

    return (
      <div styleName="info">
        <div className="container-fluid">
          <Form
            name="document-info"
            onChange={this.onFormChangeHandler}
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
          >
            {/* Linked assignment */}
            {template.needsObjectAssignment && (
              <div styleName="linkedAssignment">
                <div className="form__row">
                  <label htmlFor="linkedAssignment">
                    <ResourceText resourceKey="dynamicDocumentsLinkedAssignment" />
                  </label>
                  <Input.AssigmentQuery
                    name="linkedAssignment"
                    realEstateGroups={template.supportedRealEstateGroups}
                    onNavigateToAssignment={this.props.navigate}
                    supportForRent={template.supportForRent}
                    supportForSale={template.supportForSale}
                    filterByAssignmentTypes={[AssignmentType.Object]}
                  />
                  {/* No cadastre warning */}
                  {session.linkedAssignment &&
                    !session.linkedAssignment.hasCadastres && (
                      <div styleName="noCadastre">
                        <a
                          onClick={() =>
                            this.addCadastreToObject(
                              session.linkedAssignment.id
                            )
                          }
                        >
                          <ResourceText resourceKey="noCadastralData" asHtml />
                        </a>
                      </div>
                    )}
                </div>
              </div>
            )}

            {/* Linked property manager */}
            {template.needsPropertyManagers && (
              <div styleName="linkedPropertyManager">
                <div className="form__row">
                  <label htmlFor="linkedPropertyManagers">
                    <ResourceText resourceKey="dynamicDocumentsLinkedPropertyManagers" />
                  </label>
                  <Input.RelationQuery
                    name="linkedPropertyManagers"
                    multiple
                    termFields={[RelationTermField.DisplayName]}
                    filterByActive={ActiveFilter.ActiveOrInactive}
                    filterByRelationTypes={[
                      RelationType.ContactPerson,
                      RelationType.ContactCompany,
                    ]}
                    onAdd={(name: string) =>
                      this.addNewRelation(name, "linkedPropertyManagers")
                    }
                    onNavigateToRelation={this.props.navigate}
                  />
                </div>
              </div>
            )}

            {/* Linked vendors */}
            {template.needsVendors && (
              <div styleName="linkedVendors">
                <div className="form__row">
                  <div className="form__group stretch flex-wrap">
                    <div className="column">
                      <label htmlFor="linkedVendors">
                        <ResourceText resourceKey={linkedVendorsResourceKey} />
                      </label>
                      <Input.RelationQuery
                        name="linkedVendors"
                        withPartnerControl
                        multiple
                        termFields={[RelationTermField.DisplayName]}
                        filterByActive={ActiveFilter.ActiveOrInactive}
                        filterByRelationTypes={[
                          RelationType.ContactPerson,
                          RelationType.ContactCompany,
                        ]}
                        onAdd={(name) =>
                          this.addNewRelation(name, "linkedVendors")
                        }
                        onNavigateToRelation={this.props.navigate}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="fixedWidth">
                      <label htmlFor="vendorsActOnBehalfOf">
                        <ResourceText resourceKey="onBehalfOf" />
                      </label>
                      <Input.Text name="vendorsActOnBehalfOf" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Linked applicants */}
            {template.needsApplicants && (
              <div styleName="linkedApplicants">
                <div className="form__row">
                  <div className="form__group stretch flex-wrap">
                    <div className="column">
                      <label htmlFor="linkedApplicants">
                        <ResourceText
                          resourceKey={linkedApplicantsResourceKey}
                        />
                      </label>
                      <Input.RelationQuery
                        name="linkedApplicants"
                        withPartnerControl
                        multiple
                        termFields={[RelationTermField.DisplayName]}
                        filterByActive={ActiveFilter.ActiveOrInactive}
                        filterByRelationTypes={[
                          RelationType.ContactPerson,
                          RelationType.ContactCompany,
                        ]}
                        onAdd={(name) =>
                          this.addNewRelation(name, "linkedApplicants")
                        }
                        onNavigateToRelation={this.props.navigate}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="fixedWidth">
                      <label htmlFor="applicantsActOnBehalfOf">
                        <ResourceText resourceKey="onBehalfOf" />
                      </label>
                      <Input.Text name="applicantsActOnBehalfOf" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Linked notaries */}
            {template.needsNotary && (
              <div styleName="linkedNotary">
                <div className="form__row">
                  <label htmlFor="linkedNotary">
                    <ResourceText resourceKey="dynamicDocumentsLinkedNotary" />
                  </label>
                  <Input.RelationQuery
                    name="linkedNotary"
                    termFields={[RelationTermField.DisplayName]}
                    filterByActive={ActiveFilter.ActiveOrInactive}
                    filterByRelationTypes={[RelationType.ContactCompany]}
                    onAdd={(name) =>
                      this.addNewRelation(
                        name,
                        "linkedNotary",
                        RelationType.ContactCompany
                      )
                    }
                    onNavigateToRelation={this.props.navigate}
                  />
                </div>
              </div>
            )}
          </Form>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />

        <ConfirmComponent
          visible={this.state.confirmOverwriteVisible}
          titleResourceKey="documentSession.confirmOverwrite.title"
          bodyResourceKey="documentSession.confirmOverwrite.body"
          onClose={this.onCancelOverwrite}
          onConfirm={this.onConfirmOverwrite}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.props.currentComponentState.session ||
      !nextProps.currentComponentState.session
    )
      return;

    const { linkedAssignment } = nextProps.currentComponentState.session;
    const {
      linkedAssignment: oldLinkedAssignment,
    } = this.props.currentComponentState.session;

    if (
      !!linkedAssignment &&
      oldLinkedAssignment &&
      linkedAssignment.id !== oldLinkedAssignment.id
    ) {
      this.formRef.update({
        linkedAssignment,
      });
    }

    const currentVendors = this.props.currentComponentState.session
      .linkedVendors;
    const nextVendors = nextProps.currentComponentState.session.linkedVendors;
    if (
      (!currentVendors && nextVendors) ||
      (currentVendors &&
        nextVendors &&
        currentVendors.length !== nextVendors.length)
    ) {
      this.formRef.update({
        linkedVendors: nextVendors,
      });
    }

    const currentApplicants = this.props.currentComponentState.session
      .linkedApplicants;
    const nextApplicants =
      nextProps.currentComponentState.session.linkedApplicants;
    if (
      (!currentApplicants && nextApplicants) ||
      (currentApplicants &&
        nextApplicants &&
        currentApplicants.length !== nextApplicants.length)
    ) {
      this.formRef.update({
        linkedApplicants: nextApplicants,
      });
    }

    const currentPropertyManagers = this.props.currentComponentState.session
      .linkedPropertyManagers;
    const nextPropertyManagers =
      nextProps.currentComponentState.session.linkedPropertyManagers;
    if (
      (!currentPropertyManagers && nextPropertyManagers) ||
      (currentPropertyManagers &&
        nextPropertyManagers &&
        currentPropertyManagers.length !== nextPropertyManagers.length)
    ) {
      this.formRef.update({
        linkedPropertyManagers: nextPropertyManagers,
      });
    }

    const currentNotary = this.props.currentComponentState.session.linkedNotary;
    const nextNotary = nextProps.currentComponentState.session.linkedNotary;
    if (
      (!currentNotary && nextNotary) ||
      (currentNotary && nextNotary && currentNotary.id !== nextNotary.id)
    ) {
      this.formRef.update({
        linkedNotary: nextNotary,
      });
    }

    this.setDocumentType(
      nextProps.currentComponentState.session,
      nextProps.currentComponentState.template
    );
  }

  private autoFillAssignmentValues(assignment: AssignmentSnapShot) {
    if (!assignment) return;
    const { template } = this.props.currentComponentState;

    let linkedVendors: LinkedRelation[];
    if (assignment.linkedVendors && template.needsVendors) {
      linkedVendors = [...assignment.linkedVendors];
    }

    let linkedApplicants: LinkedRelation[];
    if (assignment.linkedApplicants && template.needsApplicants) {
      linkedApplicants = [...assignment.linkedApplicants];
    }

    let linkedNotary: LinkedRelation;
    if (
      assignment.linkedNotaries &&
      assignment.linkedNotaries.length > 0 &&
      template.needsNotary
    ) {
      linkedNotary = assignment.linkedNotaries[0];
    }

    return {
      linkedVendors: linkedVendors ? linkedVendors : "",
      linkedApplicants: linkedApplicants ? linkedApplicants : "",
      linkedNotary: linkedNotary ? linkedNotary : "",
    };
  }

  private async linkRelationsFromAssignment(control: RawFormControl) {
    if (!!this.state.linkedAssignment) return;

    const linkedAssignment: LinkedAssignment = control.value;

    const assignment = await this.props.getLinkedAssignmentRelations(
      linkedAssignment.id
    );

    let linkedVendors: LinkedRelation[];
    let linkedApplicants: LinkedRelation[];
    let linkedNotary: LinkedRelation;

    const { template } = this.props.currentComponentState;

    if (assignment.linkedVendors && template.needsVendors) {
      linkedVendors = [...assignment.linkedVendors];
    }

    if (assignment.linkedApplicants && template.needsApplicants) {
      linkedApplicants = [...assignment.linkedApplicants];
    }

    if (
      assignment.linkedNotaries &&
      assignment.linkedNotaries.length > 0 &&
      template.needsNotary
    ) {
      linkedNotary = assignment.linkedNotaries[0];
    }
    this.setState({ linkedAssignment: assignment });
    this.formRef.update({ linkedVendors, linkedApplicants, linkedNotary });
  }

  private setDocumentType(
    session: DocumentSession,
    template: DocumentTemplate
  ) {
    if (template.supportForSale && !template.supportForRent) {
      this.setState({ typeOfDocument: TypeOfDocument.ForSale });
    } else if (!template.supportForSale && template.supportForRent) {
      this.setState({ typeOfDocument: TypeOfDocument.ForRent });
    } else if (template.supportForSale && template.supportForRent) {
      if (session.linkedAssignment && session.linkedAssignment.forSale) {
        this.setState({ typeOfDocument: TypeOfDocument.ForSale });
      } else if (session.linkedAssignment && session.linkedAssignment.forRent) {
        this.setState({ typeOfDocument: TypeOfDocument.ForRent });
      }
    }
  }

  private addNewRelation(
    name: string,
    fieldName: string,
    singleFormType: RelationType = null
  ) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        fieldName,
        newRelation: {
          name,
          singleFormType,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot, fieldName: string) {
    switch (fieldName) {
      case "linkedPropertyManagers":
        let { linkedPropertyManagers } = this.formRef.getValues();
        linkedPropertyManagers = linkedPropertyManagers || [];

        this.formRef.update({
          linkedPropertyManagers: [...linkedPropertyManagers, relation],
        });
        break;
      case "linkedVendors":
        let { linkedVendors } = this.formRef.getValues();
        linkedVendors = linkedVendors || [];

        this.formRef.update({
          linkedVendors: [...linkedVendors, relation],
        });
        break;
      case "linkedApplicants":
        let { linkedApplicants } = this.formRef.getValues();
        linkedApplicants = linkedApplicants || [];

        this.formRef.update({
          linkedApplicants: [...linkedApplicants, relation],
        });
        break;
      case "linkedNotary":
        this.formRef.update({
          linkedNotary: relation,
        });
        break;
      default:
        break;
    }

    this.setState({
      newEntityVisible: false,
    });
  }

  private addCadastreToObject(assignmentId: string) {
    const { session } = this.props.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id: session.id });
    const returnPath = route(DYNAMICDOCUMENTROUTES.INFO.URI, {
      id: session.id,
    });

    this.props.navigateToCadastreWithCallback(
      EditableCalleeType.DynamicDocumentCadastre,
      path,
      returnPath,
      assignmentId
    );
  }

  private onFormChangeHandler(values: FormReturnValue) {
    const { session } = this.props.currentComponentState;

    const updatedDocumentSession: DocumentSession = {
      ...session,
      linkedAssignment: values.linkedAssignment,
      linkedVendors: values.linkedVendors,
      vendorsSigningInfo: {
        ...session.vendorsSigningInfo,
        actingOnBehalfOf: values.vendorsActOnBehalfOf,
      },
      linkedApplicants: values.linkedApplicants,
      applicantsSigningInfo: {
        ...session.applicantsSigningInfo,
        actingOnBehalfOf: values.applicantsActOnBehalfOf,
      },
      linkedNotary: values.linkedNotary,
      linkedPropertyManagers: values.linkedPropertyManagers,
    };

    if (values.linkedAssignment) {
      this.setState({
        showCadastreWarning: values.linkedAssignment.hasCadastres,
      });
    } else {
      this.setState({ showCadastreWarning: false });
    }

    const updatedComponentState = {
      ...this.props.currentComponentState,
      session: updatedDocumentSession,
    };

    this.props.updateDocumentSessionCache(
      updatedComponentState,
      this.props.path
    );
  }

  private onCancelOverwrite() {
    const { session } = this.props.currentComponentState;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id: session.id });

    this.setState({ confirmOverwriteVisible: false });
    this.props.closeDocumentSession(path);
  }

  private onConfirmOverwrite() {
    this.setState({ confirmOverwriteVisible: false });
  }
}
