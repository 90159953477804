import {
  FloorType,
  ObjectAssignment,
  ObjectTypeAssignment,
  SpaceType,
} from "@haywork/api/kolibri";
import { EXTERNALROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AssignmentUtil } from "@haywork/util";
import findIndex from "lodash-es/findIndex";
import get from "lodash-es/get";
import maxBy from "lodash-es/maxBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { ExtendedFloor, ExtendedSpace } from "./";
import { DetailComponent, FloorComponent } from "./components";
import { FloorsAndSpacesContainerProps } from "./floors-and-spaces.container";
import * as equal from "deep-equal";

const styles = require("./floors-and-spaces.component.scss");

export interface FloorsAndSpacesComponentProps {
  objectAssignment: ObjectAssignment | ObjectTypeAssignment;
  onChange: (objectAssignment: ObjectAssignment | ObjectTypeAssignment) => void;
  isNotRemovable?: boolean;
}
interface State {
  selectedFloorOrSpace: ExtendedFloor | ExtendedSpace;
}
type Props = FloorsAndSpacesComponentProps & FloorsAndSpacesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class FloorsAndSpacesComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedFloorOrSpace: null,
    };

    this.onSetSelectedFloorOrSpace = this.onSetSelectedFloorOrSpace.bind(this);
    this.onAddFloor = this.onAddFloor.bind(this);
    this.onDeleteFloor = this.onDeleteFloor.bind(this);
    this.onAddSpace = this.onAddSpace.bind(this);
    this.onDuplicateSpace = this.onDuplicateSpace.bind(this);
    this.onDeleteSpace = this.onDeleteSpace.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      get(prevProps.objectAssignment, "id") !==
        get(this.props.objectAssignment, "id") ||
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.setState({ selectedFloorOrSpace: null });
    }

    if (!equal(this.props.objectAssignment.floors, this.props.floors)) {
      this.props.onChange({
        ...this.props.objectAssignment,
        floors: this.props.floors,
      });
    }
  }

  public render() {
    const {
      floorTypeOptions,
      atticOptions,
      livingRoomTypes,
      bathroomFacilities,
      kitchenTypes,
      orientations,
      spaceTypes,
      floors,
      kitchenFacilityOptions,
    } = this.props;
    const { selectedFloorOrSpace } = this.state;

    return (
      <div styleName="floors-and-spaces">
        <div styleName="floors-and-spaces__list">
          <h2>
            <ResourceText resourceKey="floorsAndSpaces.ListTitle" />
            <Ui.InfoLink
              supportLink={EXTERNALROUTES.SUPPORT_FLOORSANDSPACES.URI}
            />
          </h2>
          {floors.map((floor, idx) => (
            <ErrorBoundary key={floor.id}>
              <FloorComponent
                floor={floor}
                idx={idx}
                selectedFloorOrSpace={this.state.selectedFloorOrSpace}
                isLast={idx === floors.length - 1}
                canEditMainFloors={this.props.canEditMainFloors}
                onFloorSelect={this.onSetSelectedFloorOrSpace}
                onAddFloor={this.onAddFloor}
                onDeleteFloor={this.onDeleteFloor}
                onSpaceSelect={this.onSetSelectedFloorOrSpace}
                onAddSpace={this.onAddSpace}
                onDuplicateSpace={this.onDuplicateSpace}
                onDeleteSpace={this.onDeleteSpace}
                isNotRemovable={this.props.isNotRemovable}
              />
            </ErrorBoundary>
          ))}
        </div>
        <div styleName="floors-and-spaces__detail">
          <h2>
            <ResourceText resourceKey={this.renderDetailsTitle()} />
          </h2>
          <DetailComponent
            selectedFloorOrSpace={selectedFloorOrSpace}
            floorTypeOptions={floorTypeOptions}
            atticOptions={atticOptions}
            livingRoomTypes={livingRoomTypes}
            bathroomFacilities={bathroomFacilities}
            kitchenTypes={kitchenTypes}
            orientations={orientations}
            spaceTypes={spaceTypes}
            floors={floors}
            kitchenFacilities={kitchenFacilityOptions}
            canEditMainFloors={this.props.canEditMainFloors}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }

  private renderDetailsTitle() {
    const { selectedFloorOrSpace } = this.state;
    const { floors } = this.props;
    const floor = !selectedFloorOrSpace
      ? null
      : floors.find((floor) => floor.id === selectedFloorOrSpace.id);

    switch (true) {
      case selectedFloorOrSpace === null:
        return "floorsAndSpaces.DetailTitle";
      case !floor:
        return "floorsAndSpaces.DetailSpaceTitle";
      case !!floor:
        return "floorsAndSpaces.DetailFloorTitle";
      default:
        return "";
    }
  }

  private onSetSelectedFloorOrSpace(
    selectedFloorOrSpace: ExtendedFloor | ExtendedSpace
  ) {
    this.setState({ selectedFloorOrSpace });
  }

  private onAddFloor() {
    let { floors } = this.props;
    const highestFloor = maxBy(floors, (floor) => floor.floorNumber);
    const selectedFloorOrSpace: ExtendedFloor = {
      id: uuid(),
      floorType: FloorType.Floor,
      spaces: [],
      floorNumber: highestFloor.floorNumber + 1,
    };
    floors = [...floors, selectedFloorOrSpace];

    this.setState({
      selectedFloorOrSpace,
    });

    this.onFloorsChange(floors);
  }

  private onDeleteFloor(floorToDelete: ExtendedFloor) {
    const floorIdx = findIndex(
      this.props.floors,
      (floor) => floor.id === floorToDelete.id
    );
    const floors = this.props.floors.filter(
      (floor) => floor.id !== floorToDelete.id
    );

    let selectedFloorOrSpace = null;
    switch (true) {
      case floors.length - 1 >= floorIdx:
        selectedFloorOrSpace = floors[floorIdx];
        break;
      default:
        selectedFloorOrSpace = floors[floorIdx - 1];
        break;
    }

    this.setState({
      selectedFloorOrSpace,
    });

    this.onFloorsChange(floors);
  }

  private onDuplicateSpace(
    spaceToDuplicate: ExtendedSpace,
    parent: ExtendedFloor
  ) {
    const selectedFloorOrSpace = {
      ...spaceToDuplicate,
      id: uuid(),
    };

    const floors = this.props.floors.map((floor) => {
      if (floor.id === parent.id) {
        const spaces = [...floor.spaces, selectedFloorOrSpace];
        return {
          ...floor,
          spaces,
        };
      }
      return floor;
    });

    this.setState({
      selectedFloorOrSpace,
    });

    this.onFloorsChange(floors);
  }

  private onDeleteSpace(selectedSpace: ExtendedSpace, parent: ExtendedFloor) {
    let selectedFloorOrSpace: ExtendedFloor | ExtendedSpace;
    const floors = this.props.floors.map((floor) => {
      if (floor.id === parent.id) {
        const spaceIdx = findIndex(
          floor.spaces,
          (space) => space.id === selectedSpace.id
        );
        const spaces = floor.spaces.filter(
          (space) => space.id !== selectedSpace.id
        );

        switch (true) {
          case !!spaces.length && spaces.length - 1 >= spaceIdx:
            selectedFloorOrSpace = spaces[spaceIdx];
            break;
          case !!spaces.length && spaces.length === spaceIdx:
            selectedFloorOrSpace = spaces[spaceIdx - 1];
            break;
          default:
            selectedFloorOrSpace = {
              ...parent,
              spaces,
              numberOfSpaces: spaces.filter(
                (space) =>
                  space.type === SpaceType.Bedroom ||
                  space.type === SpaceType.LivingRoom
              ).length,
              numberOfBedrooms: spaces.filter(
                (space) => space.type === SpaceType.Bedroom
              ).length,
            };
            break;
        }

        return {
          ...floor,
          spaces,
        };
      }
      return floor;
    });

    this.onFloorsChange(floors);
    this.setState({
      selectedFloorOrSpace,
    });
  }

  private onAddSpace(parent: ExtendedFloor) {
    let selectedFloorOrSpace;
    const floors = this.props.floors.map((floor, idx) => {
      if (floor.id === parent.id) {
        let spaces = floor.spaces || [];

        selectedFloorOrSpace = {
          id: uuid(),
          type: SpaceType.Bedroom,
        };

        spaces = [...spaces, selectedFloorOrSpace];

        return {
          ...floor,
          spaces,
        };
      }

      return floor;
    });

    this.setState({
      selectedFloorOrSpace,
    });

    this.onFloorsChange(floors);
  }

  private onChange(selectedFloorOrSpace: ExtendedFloor | ExtendedSpace) {
    const isFloor = AssignmentUtil.isFloor(selectedFloorOrSpace);

    const floors = this.props.floors.map((floor) => {
      if (isFloor) {
        if (floor.id === selectedFloorOrSpace.id) {
          return selectedFloorOrSpace as ExtendedFloor;
        }
        return floor;
      } else {
        const spaces = floor.spaces.map((space) => {
          if (space.id === selectedFloorOrSpace.id) {
            return selectedFloorOrSpace as ExtendedSpace;
          }
          return space;
        });

        return {
          ...floor,
          spaces,
        };
      }
    });

    this.setState({ selectedFloorOrSpace });
    this.onFloorsChange(floors);
  }

  private onFloorsChange(updatedFloors: ExtendedFloor[]) {
    const spaces = updatedFloors.reduce((state, floor) => {
      return [...state, ...(floor.spaces || [])];
    }, [] as ExtendedSpace[]);
    const numberOfFloors = updatedFloors.length;
    const numberOfBathRooms = spaces.filter(
      (space) => space.type === SpaceType.Bathroom
    ).length;
    const numberOfBedRooms = spaces.filter(
      (space) => space.type === SpaceType.Bedroom
    ).length;
    const numberOfKitchens = spaces.filter(
      (space) => space.type === SpaceType.Kitchen
    ).length;
    const numberOfShowers = spaces.filter(
      (space) => space.type === SpaceType.Shower
    ).length;
    const numberOfToilets = spaces.filter(
      (space) => space.type === SpaceType.Toilet
    ).length;
    const numberOfRooms = spaces.filter(
      (space) =>
        [SpaceType.Bedroom, SpaceType.LivingRoom].indexOf(space.type) !== -1
    ).length;

    const floors = updatedFloors.map((floor) => {
      const spaces = (floor.spaces || []).filter(
        (space) =>
          [SpaceType.Bedroom, SpaceType.LivingRoom].indexOf(space.type) !== -1
      );
      const numberOfSpaces = /*!spaces.length
        ? floor.numberOfSpaces || 0
        : */ spaces.filter(
        (space) =>
          space.type === SpaceType.Bedroom ||
          space.type === SpaceType.LivingRoom
      ).length;
      const numberOfBedrooms = /*!spaces.length
        ? floor.numberOfBedrooms || 0
        : */ spaces.filter((space) => space.type === SpaceType.Bedroom).length;

      return {
        ...floor,
        numberOfSpaces,
        numberOfBedrooms,
      } as ExtendedFloor;
    });

    const objectAssignment: ObjectAssignment | ObjectTypeAssignment = {
      ...this.props.objectAssignment,
      floors,
      numberOfFloors,
      numberOfBathRooms,
      numberOfBedRooms,
      numberOfKitchens,
      numberOfRooms,
      numberOfShowers,
      numberOfToilets,
    };

    this.props.onChange(objectAssignment);
  }
}
