import { FormElement, FormElementType } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import { Hint, ResourceText } from "@haywork/modules/shared";
import debounce from "lodash-es/debounce";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface NumberComponentProps {
  formElement: FlattenedFormElement;
  value?: string;
  pre?: string;
  post?: string;
  round?: boolean;
  pretty?: boolean;
  focus: boolean;
  onChange: (formElement: FormElement) => void;
  onFocus: (name: string) => void;
}
interface NumberComponentState {
  value: string;
}

@CSSModules(styles, { allowMultiple: true })
export class NumberComponent extends React.Component<
  NumberComponentProps,
  NumberComponentState
> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
    };

    this.fireOnChange = debounce(this.fireOnChange.bind(this), 500);
    this.onChangehandler = this.onChangehandler.bind(this);
  }

  public render() {
    const { pre, post, round, pretty, formElement } = this.props;
    const {
      name,
      type,
      preText,
      postText,
      maximumLength,
      format,
      disabled,
    } = formElement;

    return (
      <div styleName="element">
        {!!preText && <div styleName="element__pre">{preText}</div>}
        <div styleName="element__input">
          <div className="input__helper">
            {!!pre && (
              <div className="pre">
                <ResourceText resourceKey={pre} />
              </div>
            )}
            <Hint message="fieldNotNeeded" shouldRender={disabled}>
              <Input.Number
                name={name}
                value={this.state.value}
                onChange={this.onChangehandler}
                round={round}
                onFocus={() => this.props.onFocus(name)}
                pretty={pretty}
                fireAllChanges
                focus={this.props.focus}
                disabled={disabled}
              />
            </Hint>
            {!!post && (
              <div className="post">
                <ResourceText resourceKey={post} />
              </div>
            )}
          </div>
        </div>
        {!!postText && <div styleName="element__pre">{postText}</div>}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: NumberComponentProps) {
    if (!nextProps) return false;
    return (
      nextProps.focus !== this.props.focus ||
      nextProps.formElement.disabled !== this.props.formElement.disabled
    );
  }

  private onChangehandler(value: string) {
    if (value === this.state.value) return;

    this.setState({ value });
    this.forceUpdate();

    const { name, type } = this.props.formElement;
    let formElement: FormElement = {
      name,
      type,
    };

    switch (type) {
      case FormElementType.Integer: {
        formElement = {
          ...formElement,
          integerInfo: {
            value: parseInt(value),
          },
        };
        break;
      }
      case FormElementType.Decimal: {
        formElement = {
          ...formElement,
          decimalInfo: {
            value: parseFloat(value),
          },
        };
        break;
      }
      default: {
        break;
      }
    }

    this.fireOnChange(formElement);
  }

  private fireOnChange(formElement: FormElement) {
    this.props.onChange(formElement);
  }
}
