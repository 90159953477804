import {
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  BuildingTypeOption,
  ConditionOption,
  EnergyClassOption,
  FurnishingOption,
  HouseCharacteristicOption,
  HouseSortOption,
  HouseTypeOption,
  LocationPlaceOption,
  OfferType,
  OfferTypeOption,
  OrientationOption,
  ParkingTypeOption,
  SearchAssignment,
  SearchAssignmentFacilityOption,
  SearchAssignmentPeculiarityOption,
  TypeAlvOption,
  TypeAlvOtherOption,
  TypeBOGOption,
  TypePART,
  TypePARTOption,
  TypePARTOtherOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch } from "@haywork/middleware";
import {
  SearchAssignmentEditWhatComponent,
  SearchAssignmentEditWhatComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState, EditableActions } from "@haywork/stores";
import { MastertableUtil, RouteUtil } from "@haywork/util";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { energyClassOptions } from "@haywork/selector";

const route = RouteUtil.mapStaticRouteValues;
const sort = MastertableUtil.sortEnumList;

interface StateProps {
  searchAssignment: SearchAssignment;
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  parkingTypeOptions: ParkingTypeOption[];
  typePARTOtherOptions: TypePARTOtherOption[];
  searchAssignmentPeculiarityOptions: SearchAssignmentPeculiarityOption[];
  conditionOptions: ConditionOption[];
  energyClassOptions: EnergyClassOption[];
  furnishingOptions: FurnishingOption[];
  orientations: OrientationOption[];
  locationPlaces: LocationPlaceOption[];
  offerTypeOptions: OfferTypeOption[];
  alvOfferTypeOptions: OfferTypeOption[];
  buildingTypeOptions: BuildingTypeOption[];
  typePARTOptions: TypePARTOption[];
  typeBOGOptions: TypeBOGOption[];
  typeALVOptions: TypeAlvOption[];
  searchAssignmentFacilityOptions: SearchAssignmentFacilityOption[];
  path: string;
  typeALVOtherOptions: TypeAlvOtherOption[];
}
interface DispatchProps {
  updateSearchAssignmentCache: (
    componentState: SearchAssignment,
    path: string
  ) => void;
}

const mapStateToProps = <StateProps, SearchAssignmentEditWhatComponentProps>(
  state: AppState,
  ownProps: SearchAssignmentEditWhatComponentProps
) => {
  const searchAssignment: SearchAssignment =
    state.editable.currentComponentState;
  const {
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    typePARTOtherOptions,
    conditionOptions,
    typeALVOtherOptions,
    furnishingOptions,
    orientations,
    locationPlaces,
    offerTypeOptions,
    buildingTypeOptions,
    typePARTOptions,
    typeBOGOptions,
    typeALVOptions,
    searchAssignmentPeculiarityOptions,
    searchAssignmentFacilityOptions,
  } = state.main.mastertable.kolibri;
  const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
    id: searchAssignment.id,
  });
  const alvOfferTypeOptions = filterOfferTypeOptions(
    state.main.mastertable.kolibri.offerTypeOptions
  );

  return {
    searchAssignment,
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    typePARTOtherOptions,
    searchAssignmentPeculiarityOptions,
    conditionOptions,
    energyClassOptions: energyClassOptions(state),
    furnishingOptions,
    orientations,
    locationPlaces,
    offerTypeOptions: sort(offerTypeOptions, [OfferType.Sale, OfferType.Rent]),
    alvOfferTypeOptions: sort(alvOfferTypeOptions, [
      OfferType.Sale,
      OfferType.Rent,
    ]),
    buildingTypeOptions,
    typePARTOptions: sort(typePARTOptions, [
      TypePART.ResidentialBuilding,
      TypePART.Apartment,
      TypePART.Parking,
      TypePART.BuildingGround,
      TypePART.Other,
    ]),
    typeBOGOptions,
    typeALVOptions,
    searchAssignmentFacilityOptions,
    path,
    typeALVOtherOptions,
  };
};

const filterOfferTypeOptions = (
  options: OfferTypeOption[]
): OfferTypeOption[] => {
  const filteredTypeOptions = options.filter(
    (offerType) => offerType.value === OfferType.Sale
  );

  const leaseTypeOption = {
    ...options.find((offerType) => offerType.value === OfferType.Rent),
  };
  leaseTypeOption.displayName = intlContext.formatMessage({ id: "lease" });
  filteredTypeOptions.unshift(leaseTypeOption);

  return filteredTypeOptions;
};

const mapDispatchToProps = <
  DispatchProps,
  SearchAssignmentEditWhatComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateSearchAssignmentCache: (
    componentState: SearchAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        path,
        componentState,
      })
    ),
});

export type SearchAssignmentEditWhatContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const SearchAssignmentEditWhatContainer = withRouter<any, any>(
  connect<StateProps, DispatchProps, SearchAssignmentEditWhatComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchAssignmentEditWhatComponent)
);
