import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { RelationDossierContainerProps } from "@haywork/modules/relation";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import { RelationType } from "@haywork/api/kolibri";
import { WidgetEntityType } from "@haywork/api/authorization";
import { FolderTreeEntityType } from "@haywork/middleware";

export interface RelationDossierComponentProps {}
interface State {}
type Props = RelationDossierComponentProps & RelationDossierContainerProps;

export class RelationDossierComponent extends React.PureComponent<
  Props,
  State
> {
  public render() {
    const entityType =
      this.props.selectedRelationType === RelationType.ContactPerson
        ? FolderTreeEntityType.ContactPerson
        : FolderTreeEntityType.ContactCompany;

    return (
      <ErrorBoundary>
        <Dossier
          parentId={this.props.parentId}
          id={this.props.linkedFolderTreeId}
          subject={this.props.dossierTitle}
          entityType={entityType}
          addNotes={true}
        />
      </ErrorBoundary>
    );
  }
}
