import { combineReducers } from "redux";
import {
  SettingsFolderTreesActions,
  settingsFolderTreesReducer,
  SettingsFolderTreesState
} from "./folder-trees";

export interface SettingsState {
  folderTrees: SettingsFolderTreesState;
}

export const settingsReducer = combineReducers({
  folderTrees: settingsFolderTreesReducer
});

export const SettingsActions = {
  FolderTrees: SettingsFolderTreesActions
};
