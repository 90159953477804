import {
  AssignmentType,
  LinkedAssignment,
  UpdateAvailabilityAction,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { OBJECTTYPESROUTES, PROJECTROUTES } from "@haywork/constants";
import Notes, { NotesList } from "@haywork/modules/notes-v3";
import { ObjectTypesDetailContainerProps } from "@haywork/modules/object-types";
import {
  AddBuildnumbers,
  ConfirmComponent,
  SlideShow,
} from "@haywork/modules/shared";
import { AsyncUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { ObjectTypeAction } from "./actions";
import { ObjectTypesDetailRouting } from "./detail.routing";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ObjectTypesDetailComponentProps {}
interface State {
  statusConfirmVisible: boolean;
  statusConfirmAction: ObjectTypeAction;
  statusConfirmTitle: string;
  statusConfirmBody: string;
  deleteConfirmVisible: boolean;
}
type Props = ObjectTypesDetailComponentProps & ObjectTypesDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesDetailComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      statusConfirmVisible: false,
      statusConfirmAction: null,
      statusConfirmTitle: "",
      statusConfirmBody: "",
      deleteConfirmVisible: false,
    };

    this.onDeleteObjectType = this.onDeleteObjectType.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.onSlideClickHandler = this.onSlideClickHandler.bind(this);
    this.onStatusCloseHandler = this.onStatusCloseHandler.bind(this);
    this.onStatusConfirmHandler = this.onStatusConfirmHandler.bind(this);
    this.onAddBuildnumberSuccessHandler = this.onAddBuildnumberSuccessHandler.bind(
      this
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (
      !!this.props.displayName &&
      !!nextProps.displayName &&
      nextProps.displayName !== this.props.displayName
    ) {
      this.props.setTabTitle(nextProps.displayName);
    }

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveAndCloseObjectType();
    }
  }

  public render() {
    if (!this.props.currentType) return null;
    const { isInEditMode, currentType, photos } = this.props;
    const { id, isNew } = currentType;

    return (
      <div styleName="detail" data-cy="CY-objectTypesParentDiv">
        {!isNew && <NotesList parentId={id} />}
        {!isInEditMode && (
          <div styleName="detail__sidebar">
            <div styleName="preview-slider" className="hidden-xs hidden-sm">
              {photos.length > 0 && (
                <SlideShow
                  slides={photos}
                  onSlideClick={this.onSlideClickHandler}
                />
              )}
              {photos.length === 0 && (
                <i className="fal fa-fw fa-camera-retro" />
              )}
            </div>

            <div styleName="menu">
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(OBJECTTYPESROUTES.GENERAL.URI, { id })}
              >
                <i className="fal fa-fw fa-puzzle-piece" />
                <span styleName="text">
                  <I18n value="projectTypesDetailGeneral" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(OBJECTTYPESROUTES.NETWORK.URI, { id })}
              >
                <i className="fal fa-fw fa-users" />
                <span styleName="text">
                  <I18n value="projectTypesDetailNetwork" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(OBJECTTYPESROUTES.DOSSIER.URI, { id })}
              >
                <i className="fal fa-fw fa-archive" />
                <span styleName="text">
                  <I18n value="projectTypesDetailDossier" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(OBJECTTYPESROUTES.BUILD_NUMBERS.URI, { id })}
              >
                <i
                  className="fal fa-fw fa-map-signs"
                  data-cy="CY-projectDetailMenuBuildnumbers"
                />
                <span styleName="text">
                  <I18n value="projectTypesDetailBuildNumbers" />
                </span>
              </NavLink>
            </div>
          </div>
        )}

        <div styleName="detail__body">
          {!isInEditMode && this.props.location.pathname.match(/general/) && (
            <PageHeader
              title="pageTitle.objectType.general"
              subTitle={this.props.displayName}
              actions={
                <>
                  <Notes />
                  <Button
                    label="projectTypesActionEdit"
                    category="success"
                    onClick={() => this.props.edit(id)}
                  />
                  <Actions
                    objectType={this.props.currentType}
                    onClick={this.onActionClick}
                    canSendEmail={this.props.canSendEmail}
                  />
                </>
              }
            />
          )}
          <div styleName="detail__routing">
            <ObjectTypesDetailRouting id={id} />
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.statusConfirmVisible}
          titleResourceKey={this.state.statusConfirmTitle}
          bodyResourceKey={this.state.statusConfirmBody}
          onClose={this.onStatusCloseHandler}
          onConfirm={this.onStatusConfirmHandler}
        />

        <ConfirmComponent
          visible={this.state.deleteConfirmVisible}
          titleResourceKey={"deleteObjectTypeConfirmTitle"}
          bodyResourceKey={"deleteObjectTypeConfirmBody"}
          onClose={() => this.setState({ deleteConfirmVisible: false })}
          onConfirm={this.onDeleteObjectType}
        />

        <AddBuildnumbers
          project={this.props.currentType.parentProject}
          objectTypeId={this.props.currentType.id}
          price={this.props.price}
          onClose={() => this.props.toggleAddBuildnumbers(false)}
          onSuccess={this.onAddBuildnumberSuccessHandler}
        />
      </div>
    );
  }

  public componentDidMount() {
    if (!!this.props.displayName) {
      this.props.setTabTitle(this.props.displayName);
    }
  }

  private onStatusCloseHandler() {
    this.setState(
      {
        statusConfirmVisible: false,
        statusConfirmAction: null,
      },
      async () => {
        await AsyncUtil.wait(500);
        this.setState({
          statusConfirmTitle: "",
          statusConfirmBody: "",
        });
      }
    );
  }

  private onStatusConfirmHandler() {
    const { statusConfirmAction } = this.state;
    let action;

    switch (statusConfirmAction) {
      case ObjectTypeAction.FinalizeRent:
        action = UpdateAvailabilityAction.ToRented;
        break;
      case ObjectTypeAction.FinalizeSale:
        action = UpdateAvailabilityAction.ToSold;
        break;
      case ObjectTypeAction.WithDraw:
        action = UpdateAvailabilityAction.ToWithdrawn;
        break;
      default:
        break;
    }

    if (!!action) {
      const { id } = this.props.currentType;
      this.props.updateAvailability(id, action);
    }

    this.setState(
      {
        statusConfirmVisible: false,
        statusConfirmAction: null,
      },
      async () => {
        await AsyncUtil.wait(500);
        this.setState({
          statusConfirmTitle: "",
          statusConfirmBody: "",
        });
      }
    );
  }

  private onSlideClickHandler(idx: number) {
    this.props.openLightbox(this.props.currentType.photos, idx);
  }

  private onActionClick(action: ObjectTypeAction) {
    const { currentType, displayName } = this.props;
    const {
      id,
      linkedProjectAssignment,
      linkedEmployee,
      linkedVendors,
    } = currentType;
    const linkedAssignment: LinkedAssignment = {
      id,
      displayName,
      typeOfAssignment: AssignmentType.ObjectType,
    };
    const projectId = get(linkedProjectAssignment, "id");

    const linkedRelations = [...(linkedVendors || [])];

    switch (action) {
      case ObjectTypeAction.FinalizeRent: {
        return this.setState({
          statusConfirmVisible: true,
          statusConfirmAction: action,
          statusConfirmTitle: "objectTypeRentStatusTitle",
          statusConfirmBody: "objectTypeRentStatusBody",
        });
      }
      case ObjectTypeAction.FinalizeSale: {
        return this.setState({
          statusConfirmVisible: true,
          statusConfirmAction: action,
          statusConfirmTitle: "objectTypeSaleStatusTitle",
          statusConfirmBody: "objectTypeSaleStatusBody",
        });
      }
      case ObjectTypeAction.Publish:
        return this.props.navigate(
          route(OBJECTTYPESROUTES.PUBLISH.URI, { id })
        );
      case ObjectTypeAction.WithDraw: {
        return this.setState({
          statusConfirmVisible: true,
          statusConfirmAction: action,
          statusConfirmTitle: "objectTypeWithdrawStatusTitle",
          statusConfirmBody: "objectTypeWithdrawStatusBody",
        });
      }
      case ObjectTypeAction.AddBuildNumbers: {
        return this.props.toggleAddBuildnumbers(true);
      }
      case ObjectTypeAction.Remove: {
        return this.setState({ deleteConfirmVisible: true });
      }
      case ObjectTypeAction.CreateTask: {
        return this.props.createTaskWithLinkedAssignment(linkedAssignment);
      }
      case ObjectTypeAction.CreateInvoice: {
        return this.props.createInvoiceWithLinkedAssignment(
          linkedRelations[0] || undefined,
          linkedAssignment
        );
      }
      case ObjectTypeAction.CreateAppointment: {
        return this.props.createAgendaItemWithLinkedAssignment(
          linkedRelations,
          linkedAssignment,
          linkedEmployee
        );
      }
      case ObjectTypeAction.CreateEmail: {
        return this.props.createNewEmail(linkedAssignment, displayName);
      }
      case ObjectTypeAction.OpenParentProject: {
        if (!projectId) return;
        const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });
        return this.props.navigate(path);
      }
      case ObjectTypeAction.Archive: {
        return this.props.archive(id);
      }
      case ObjectTypeAction.UnArchive: {
        return this.props.unArchive(id);
      }
      default:
        return;
    }
  }

  private onAddBuildnumberSuccessHandler() {
    const { id } = this.props.currentType;
    this.props.getBuildnumbers(id);
    this.props.toggleAddBuildnumbers(false);
    this.props.navigate(route(OBJECTTYPESROUTES.BUILD_NUMBERS.URI, { id }));
  }

  private onDeleteObjectType() {
    const { id, parentProject } = this.props.currentType;
    this.setState({ deleteConfirmVisible: false });
    this.props.removeType(id, parentProject.id);
  }
}
