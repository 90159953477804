import { createContext } from "react";
import noop from "lodash-es/noop";

export type ListContextProps = {
  loading: boolean;
  itemCount: number;
  onScrollCallback: (scrollTop: number) => void;
};

export const ListContext = createContext<ListContextProps>({
  loading: false,
  itemCount: 0,
  onScrollCallback: noop
});
export const ListContextConsumer = ListContext.Consumer;
export const ListContextProvider = ListContext.Provider;
