import { CommunicationLog, ErrorResponse } from "@haywork/api/kolibri";
import { Message } from "@haywork/api/mail";
import { DraftType } from "@haywork/enum";
import {
  CommunicationLogThunk,
  Dispatch,
  EmailMessageThunks,
} from "@haywork/middleware";
import { EmailMessageBlobComponentProps } from "@haywork/modules/email";
import EmailMessageBlobComponent from "@haywork/modules/email/components/message-blob/message-blob.component";
import { AppState, ErrorActions } from "@haywork/stores";
import { connect } from "react-redux";
import { defaultSendAccountId } from "./selectors";

interface StateProps {
  currentAccountId: string;
}
interface DispatchProps {
  showError?: (error: ErrorResponse) => void;
  messageToDraft: (
    message: Message,
    type: DraftType,
    accountId: string,
    folderId: string
  ) => Promise<void>;
  saveCommunicationLog: (
    communicationLog: CommunicationLog
  ) => Promise<CommunicationLog>;
}

const mapStateToProps = <StateProps, EmailMessageBlobComponentProps>(
  state: AppState
) => {
  return {
    currentAccountId: defaultSendAccountId(state),
  };
};

const mapDispatchToProps = <DispatchProps, EmailMessageBlobComponentProps>(
  dispatch: Dispatch<any>
) => ({
  showError: (error) => dispatch(ErrorActions.setError(error)),
  messageToDraft: (
    message: Message,
    type: DraftType,
    accountId: string,
    folderId: string
  ) =>
    dispatch(
      EmailMessageThunks.messageToDraft(message, type, accountId, folderId)
    ),
  saveCommunicationLog: (communicationLog: CommunicationLog) =>
    dispatch(CommunicationLogThunk.saveCommunicationLog(communicationLog)),
});

export type EmailMessageBlobContainerProps = StateProps & DispatchProps;
export const EmailMessageBlobContainer = connect<
  StateProps,
  DispatchProps,
  EmailMessageBlobComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailMessageBlobComponent);
