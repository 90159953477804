import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { SearchAssignment } from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";

import { SearchAssignmentWidgetRelationItemComponent } from "./relation-item.component";

const styles = require("./relations.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentWidgetRelationsComponentProps {
  searchAssignment: SearchAssignment;
  onNavigateToRelation: (url: string) => void;
}
interface SearchAssignmentWidgetRelationsComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetRelationsComponent extends React.Component<
  SearchAssignmentWidgetRelationsComponentProps,
  SearchAssignmentWidgetRelationsComponentState
> {
  public render() {
    const { linkedRelations, id } = this.props.searchAssignment;
    const relations = linkedRelations ? linkedRelations.slice(0, 3) : [];
    const url = route(SEARCHASSIGNMENTROUTES.SEARCHERS.URI, { id });

    return (
      <div styleName="relations">
        <h1>
          <ResourceText resourceKey="searchAssignmentFor" />
        </h1>
        {relations.length > 0 && (
          <div>
            <div styleName="relations__list">
              {relations.map((relation, idx) => (
                <ErrorBoundary key={idx}>
                  <SearchAssignmentWidgetRelationItemComponent
                    relation={relation}
                    onNavigateToRelation={this.props.onNavigateToRelation}
                  />
                </ErrorBoundary>
              ))}
            </div>
            <div styleName="relations__action">
              <Link to={url}>
                <ResourceText resourceKey="goToSearchers" />
              </Link>
            </div>
          </div>
        )}
        {relations.length === 0 && (
          <div styleName="empty-state">
            <div styleName="icon" />
            <div styleName="message">
              <ResourceText resourceKey="searchAssignmentSearchersEmptyStateTitle" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
