import {
  EventNotification,
  EventNotificationsOrderByField,
  SortOrder,
} from "@haywork/api/event-center";
import {
  RelationType,
  RelationSnapShot,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import {
  AssignmentThunks,
  MapDispatchToProps,
  RelationThunks,
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { NotificationsActions } from "@haywork/stores/notifications";
import { NotificationsOrder } from "@haywork/stores/notifications/event-center";
import { connect, MapStateToProps } from "react-redux";
import { ListComponent, ListComponentProps } from "./list";
import { activeFilterGuidValues } from "./selectors";
import { NotificationsThunk } from "@haywork/middleware/thunk/notifications";
import { push } from "connected-react-router";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { EmailDraft } from "@haywork/stores/email-v2";

type StateProps = {
  notifications: EventNotification[];
  totalCount: number;
  order: NotificationsOrder;
  filters: FilterConfig;
  scrollOffset: number;
  activeFilterGuidValues: FilterGuidValues;
  processAllVisible: boolean;
  canSendEmail: boolean;
};
type DispatchProps = {
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: EventNotificationsOrderByField
  ) => void;
  setFilters: (filters: FilterConfig) => void;
  setScrollOffset: (offset: number) => void;
  navigate: (path: string) => void;
  toggleAllIsArchived: () => Promise<void>;
  toggleIsArchived: (id: string, isArchived: boolean) => Promise<void>;
  toggleIsRead: (id: string, isRead: boolean) => Promise<void>;
  createNewEmail: (email: string) => void;
  getRelationsWithMatchingEmailAddress: (
    email: string
  ) => Promise<RelationSnapShot[]>;
  updateMessageWithLinkedRelation: (
    id: string,
    linkedRelation: LinkedRelation
  ) => Promise<void>;
  openDraft: (draft: EmailDraft) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListComponentProps,
  AppState
> = (state) => {
  const { notifications, totalCount, order, filters, scrollOffset } =
    state.notifications.eventCenter;
  const isProcessed = filters.typeFilters.value.includes("processed");

  return {
    notifications,
    totalCount,
    order,
    filters,
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state),
    processAllVisible: !isProcessed && totalCount > 0,
    canSendEmail: canSendEmail(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListComponentProps
> = (dispatch) => ({
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(
      NotificationsThunk.EventCenter.getListItems(startIndex, stopIndex)
    ),
  setOrdering: (
    sortOrder: SortOrder,
    sortColumn: EventNotificationsOrderByField
  ) =>
    dispatch(
      NotificationsActions.EventCenter.setOrdering(sortOrder, sortColumn)
    ),
  setFilters: (filters: FilterConfig) =>
    dispatch(NotificationsActions.EventCenter.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(NotificationsActions.EventCenter.setScrollOffset(offset)),
  navigate: (path: string) => dispatch(push(path)),
  toggleAllIsArchived: () =>
    dispatch(NotificationsThunk.EventCenter.toggleAllIsArchived(true)),
  toggleIsArchived: (id: string, isArchived: boolean) =>
    dispatch(NotificationsThunk.EventCenter.toggleIsArchived(id, isArchived)),
  toggleIsRead: (id: string, isRead: boolean) =>
    dispatch(NotificationsThunk.EventCenter.toggleIsRead(id, isRead)),
  createNewEmail: (email: string) =>
    dispatch(EmailThunk.Main.createNewEmail([email])),
  getRelationsWithMatchingEmailAddress: (email: string) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        [email],
        [RelationType.ContactPerson]
      )
    ),
  updateMessageWithLinkedRelation: (
    id: string,
    linkedRelation: LinkedRelation
  ) =>
    dispatch(
      NotificationsThunk.Messages.updateMessageWithLinkedRelation(
        id,
        linkedRelation
      )
    ),
  openDraft: (draft: EmailDraft) =>
    dispatch(EmailThunk.Drafts.openDraft(draft)),
});

export type ListContainerProps = StateProps & DispatchProps;
export const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
