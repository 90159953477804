import { MAINROUTES, REQUEST } from "@haywork/constants";
import { LastChangedAssignmentsContainerProps } from "@haywork/modules/dashboard/containers/widgets";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import { Assignment } from "./assignment.component";

const styles = require("./last-changed-assignments.component.scss");

export type LastChangedAssignmentsComponentProps = {};
type Props = LastChangedAssignmentsComponentProps &
  LastChangedAssignmentsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class LastChangedAssignmentsComponent extends React.Component<Props> {
  public componentDidMount() {
    this.props.getLastChangedAssignments(4);
  }

  public render() {
    const { status, results: assignments } = this.props.lastChangedAssignments;

    return (
      <div className="widget-wrapper">
        <article className="widget">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-folder-open" />
            </span>
            <span className="text">
              <ResourceText resourceKey="lastChangedAssignmentsWidgetTitle" />
            </span>
          </header>

          <div
            className={classNames("content", {
              loading: status === REQUEST.PENDING,
              emptyState:
                status !== REQUEST.PENDING && assignments.length === 0,
            })}
          >
            {status === REQUEST.PENDING && <PageLoader loading />}

            {status !== REQUEST.PENDING && assignments.length === 0 && (
              <ResourceText resourceKey="noLastChangedAssignmentsFound" />
            )}

            {assignments.map((assignment, idx) => (
              <ErrorBoundary key={assignment.id}>
                <Assignment
                  assignment={assignment}
                  onNavigate={this.props.navigate}
                />
              </ErrorBoundary>
            ))}
          </div>

          <footer>
            <Link
              to={MAINROUTES.ASSIGNMENTS.URI}
              className="btn btn-link btn-link-primary"
            >
              <ResourceText resourceKey="lastChangedAssignmentsWidgetLink" />
            </Link>
          </footer>
        </article>
      </div>
    );
  }
}
