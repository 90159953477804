import {
  Address,
  SearchAssignmentLocation,
  SearchForLocationType,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";

export enum SearchAssignmentResultLevel {
  Street = "Street",
  PostalCode = "PostalCode",
  SubLocality = "SubLocality",
  Locality = "Locality",
  AdminAreaLevel2 = "AdminAreaLevel2",
  AdminAreaLevel1 = "AdminAreaLevel1",
  Country = "Country",
}

export class AddressUtil {
  public static mapGoogleGeoCoderResultsToAddress(
    matches: google.maps.GeocoderResult[] | null
  ): Address {
    const address: Address = {
      street: null,
      houseNumber: null,
      houseNumberPostfix: null,
      postalCode: null,
      locality: null,
    };

    if (!matches || matches.length === 0) return address;

    const match = first(matches);
    const types = [
      "street_number",
      "route",
      "street_address",
      "locality",
      "postal_code",
    ];

    match.address_components.map((cmp) => {
      cmp.types.map((t) => {
        const idx = types.indexOf(t);
        switch (types[idx]) {
          case "street_number":
            return (address.houseNumber = parseInt(cmp.long_name));
          case "route":
          case "street_address":
            return (address.street = {
              name: cmp.long_name,
            });
          case "locality":
            return (address.locality = {
              name: cmp.long_name,
            });
          case "postal_code":
            return (address.postalCode = cmp.long_name);
          default:
            return;
        }
      });
    });

    return address;
  }

  public static validateSimpleAddress(address: Address): Address {
    let returnAddress;
    const paths = [
      "houseNumber",
      "houseNumberPostfix",
      "locality.name",
      "postalCode",
      "street.name",
    ];

    paths.map((p) => {
      if (!!get(address, p)) {
        return (returnAddress = address);
      }
    });

    return returnAddress;
  }

  public static mapLocationToAddress(
    location: SearchAssignmentLocation
  ): Address {
    const address: Address = {
      adminAreaLevel1: location.adminAreaLevel1,
      adminAreaLevel2: location.adminAreaLevel2,
      adminAreaLevel3: location.adminAreaLevel3,
      countryIso2: location.countryIso2,
      geoLocation: location.geoLocation,
      locality: location.locality,
      postalCode: location.postalCode,
      street: location.street,
      sublocality: location.sublocality,
    };

    return address;
  }

  public static doesLocationHaveAddress(
    location: SearchAssignmentLocation
  ): boolean {
    if (!location) return false;

    const returnValue =
      has(location, "street.name") ||
      (has(location, "postalCode") && location.postalCode !== "") ||
      has(location, "locality.name") ||
      has(location, "adminAreaLevel1.name") ||
      has(location, "countryIso2");
    return returnValue;
  }

  public static createTranslatedSearchAssignmentLocation(
    location: SearchAssignmentLocation
  ): SearchAssignmentLocation {
    const {
      searchForLocationType,
      postalCode,
      street,
      sublocality,
      locality,
      adminAreaLevel2,
      adminAreaLevel1,
      countryIso2,
    } = location;

    let countryName;
    if (!!countryIso2) {
      countryName = intlContext.formatMessage({
        id: `countryCode${countryIso2}`,
        defaultMessage: countryIso2,
      });
    }

    switch (searchForLocationType) {
      case SearchForLocationType.PostalCode: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.postalCode",
          defaultMessage: "Postalcode: ",
        });
        let displayName = [
          postalCode,
          get(locality, "name"),
          get(adminAreaLevel2, "name"),
          get(adminAreaLevel1, "name"),
          countryName,
        ]
          .filter((d) => !!d)
          .join(", ");
        displayName = prefix + displayName;

        return {
          ...location,
          displayName,
        };
      }
      case SearchForLocationType.Place: {
        let displayName = [
          get(street, "name"),
          get(sublocality, "name"),
          get(locality, "name"),
          get(adminAreaLevel1, "name"),
          countryName,
        ]
          .filter((d) => !!d)
          .join(", ");

        let prefix = "";

        switch (true) {
          case has(sublocality, "name"): {
            prefix = intlContext.formatMessage({
              id: "searchAssignment.prefix.subLocality",
              defaultMessage: "Sublocality: ",
            });
            break;
          }
          case !has(street, "name") && !!locality: {
            prefix = intlContext.formatMessage({
              id: "searchAssignment.prefix.locality",
              defaultMessage: "Locality: ",
            });
            break;
          }
          case !has(street, "name") && !!adminAreaLevel2: {
            displayName = [
              get(adminAreaLevel2, "name"),
              get(adminAreaLevel1, "name"),
              countryName,
            ]
              .filter((d) => !!d)
              .join(", ");

            prefix = intlContext.formatMessage({
              id: "searchAssignment.prefix.adminAreaLevel2",
              defaultMessage: "Municipality: ",
            });
            break;
          }
          case !has(street, "name") && !!adminAreaLevel1: {
            displayName = [get(adminAreaLevel1, "name"), countryName]
              .filter((d) => !!d)
              .join(", ");

            prefix = intlContext.formatMessage({
              id: "searchAssignment.prefix.adminAreaLevel1",
              defaultMessage: "Province: ",
            });
            break;
          }
          default:
            break;
        }

        displayName = prefix + displayName;

        return {
          ...location,
          displayName,
        };
      }
      default: {
        return location;
      }
    }
  }

  public static createSearchLocality(
    address: Address,
    id: number,
    level: SearchAssignmentResultLevel
  ): SearchAssignmentLocation {
    const {
      countryIso2,
      geoLocation,
      street,
      postalCode,
      sublocality,
      locality,
      adminAreaLevel2,
      adminAreaLevel1,
    } = address;

    let countryName;

    if (!!countryIso2) {
      countryName = intlContext.formatMessage({
        id: `countryCode${countryIso2}`,
        defaultMessage: countryIso2,
      });
    }

    let displayName = [
      get(street, "name"),
      postalCode,
      get(sublocality, "name"),
      get(locality, "name"),
      get(adminAreaLevel1, "name"),
      countryName,
    ]
      .filter((d) => !!d)
      .join(", ");

    let location: SearchAssignmentLocation = {
      id,
      searchForLocationType: SearchForLocationType.Place,
      countryIso2,
      geoLocation,
      displayName,
    };

    switch (level) {
      case SearchAssignmentResultLevel.Street: {
        location = {
          ...location,
          street,
          locality,
          adminAreaLevel2,
          adminAreaLevel1,
        };
        break;
      }
      case SearchAssignmentResultLevel.PostalCode: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.postalCode",
          defaultMessage: "Postalcode: ",
        });
        displayName = prefix + displayName;

        location = {
          ...location,
          postalCode,
          locality,
          adminAreaLevel2,
          adminAreaLevel1,
          displayName,
          searchForLocationType: SearchForLocationType.PostalCode,
        };
        break;
      }
      case SearchAssignmentResultLevel.SubLocality: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.subLocality",
          defaultMessage: "Sublocality: ",
        });
        let displayName = [
          get(sublocality, "name"),
          get(locality, "name"),
          get(adminAreaLevel1, "name"),
          countryName,
        ]
          .filter((d) => !!d)
          .join(", ");
        displayName = prefix + displayName;

        location = {
          ...location,
          sublocality,
          locality,
          displayName,
          adminAreaLevel2,
          adminAreaLevel1,
        };
        break;
      }
      case SearchAssignmentResultLevel.Locality: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.locality",
          defaultMessage: "Locality: ",
        });
        let displayName = [
          get(locality, "name"),
          get(adminAreaLevel1, "name"),
          countryName,
        ]
          .filter((d) => !!d)
          .join(", ");
        displayName = prefix + displayName;

        location = {
          ...location,
          locality,
          displayName,
          adminAreaLevel2,
          adminAreaLevel1,
        };
        break;
      }
      case SearchAssignmentResultLevel.AdminAreaLevel2: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.adminAreaLevel2",
          defaultMessage: "Municipality: ",
        });
        let displayName = [
          get(adminAreaLevel2, "name"),
          get(adminAreaLevel1, "name"),
          countryName,
        ]
          .filter((d) => !!d)
          .join(", ");
        displayName = prefix + displayName;

        location = {
          ...location,
          adminAreaLevel2,
          adminAreaLevel1,
          displayName,
        };
        break;
      }
      case SearchAssignmentResultLevel.AdminAreaLevel1: {
        const prefix = intlContext.formatMessage({
          id: "searchAssignment.prefix.adminAreaLevel1",
          defaultMessage: "Province: ",
        });
        let displayName = [get(adminAreaLevel1, "name"), countryName]
          .filter((d) => !!d)
          .join(", ");
        displayName = prefix + displayName;

        location = {
          ...location,
          adminAreaLevel1,
          displayName,
        };
        break;
      }
      default:
        break;
    }

    return location;
  }

  public static getLocationId(location: SearchAssignmentLocation): number {
    const streetId = get(location.street, "id");
    if (!!streetId) return streetId;

    const locationId = get(location.locality, "id");
    if (!!locationId) return locationId;

    const adminAreaLevel2Id = get(location.adminAreaLevel2, "id");
    if (!!adminAreaLevel2Id) return adminAreaLevel2Id;

    const adminAreaLevel1Id = get(location.adminAreaLevel1, "id");
    if (!!adminAreaLevel1Id) return adminAreaLevel1Id;

    return location.id;
  }

  public static searchAssignmentDisplayName(
    location: SearchAssignmentLocation
  ): string {
    const {
      countryIso2,
      street,
      postalCode,
      locality,
      adminAreaLevel1,
    } = location;

    let countryName;

    if (!!countryIso2) {
      countryName = intlContext.formatMessage({
        id: `countryCode${countryIso2}`,
        defaultMessage: countryIso2,
      });
    }

    return [
      get(street, "name"),
      postalCode,
      get(locality, "name"),
      get(adminAreaLevel1, "name"),
      countryName,
    ]
      .filter((d) => !!d)
      .join(", ");
  }
}
