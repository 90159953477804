import { ContactCompany, ContactPerson } from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";
import { Colors } from "@haywork/enum/colors";

export enum RelationAction {
  Remove = "Remove",
  Archive = "Archive",
  UnArchive = "UnArchive",
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAgendaItem = "CreateAgendaItem",
  CreateSearchAssignment = "CreateSearchAssignment",
  CreateInvoice = "CreateInvoice"
}

type Props = {
  relation: ContactPerson | ContactCompany;
  canSendEmail: boolean;
  onClick: (action: RelationAction) => void;
};

export const RelationGeneralActionsComponent: FC<Props> = memo(
  ({ relation, canSendEmail, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const { isActive, emailAddresses } = relation;
    const showArchive = isActive;
    const showUnArchive = !isActive;
    const showEmail = !!(emailAddresses || []).length && canSendEmail;

    const onClickHandler = (action: RelationAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={<Icon name="chevron-down" light size={16} />}
          iconPosition="end"
          category="primary"
          label="actions"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="relation.actions.label.actions" />
          {showEmail && (
            <Action
              label="relation.actions.label.createEmail"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(RelationAction.CreateEmail)}
            />
          )}
          <Action
            label="relation.actions.label.createTask"
            icon={
              <Icon
                light
                name="tasks"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(RelationAction.CreateTask)}
          />
          <Action
            label="relation.actions.label.createAgendaItem"
            icon={
              <Icon
                light
                name="calendar"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(RelationAction.CreateAgendaItem)}
          />
          <Action
            label="relation.actions.label.createInvoice"
            icon={
              <Icon
                light
                name="euro-sign"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(RelationAction.CreateInvoice)}
          />
          <Action
            label="relation.actions.label.createSearchAssignment"
            icon={
              <Icon
                light
                name="search"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(RelationAction.CreateSearchAssignment)
            }
          />
          <Spacer />
          {showArchive && (
            <Action
              label="relation.actions.label.archive"
              icon={
                <Icon
                  light
                  name="archive"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(RelationAction.Archive)}
            />
          )}
          {showUnArchive && (
            <Action
              label="relation.actions.label.unArchive"
              icon={
                <Icon
                  light
                  name="inbox-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(RelationAction.UnArchive)}
            />
          )}
          <Action
            label="relation.actions.label.remove"
            icon={
              <Icon
                light
                name="trash-alt"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(RelationAction.Remove)}
          />
        </ActionList>
      </>
    );
  }
);

export default RelationGeneralActionsComponent;
