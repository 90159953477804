import { OidcService } from "../services/oidc.service";
import { User } from "oidc-client";

let runningRequest: Promise<User | null> | null;

export class Kolibri24BaseRequestClient {
  public async transformOptions(options: RequestInit): Promise<RequestInit> {
    const transformedOptions: RequestInit = options;
    const headers = <Headers>options.headers;
    headers["Accept-Language"] = "nl-NL";

    try {
      const oidc = new OidcService();
      const manager = oidc.OidcUserManager;

      if (!!manager) {
        if (!!runningRequest) {
          const user = await runningRequest;
          const { expired, access_token } = user;

          if (!expired && !!access_token) {
            headers["Authorization"] = `Bearer ${access_token}`;
          }
        } else {
          let user = await manager.getUser();
          if (!user || user.expired || !user.access_token) {
            runningRequest = manager.signinSilent();
            user = await runningRequest;
            runningRequest = null;
          }

          const { expired, access_token } = user;
          if (!expired && !!access_token) {
            headers["Authorization"] = `Bearer ${access_token}`;
          }
        }
      }

      if (!headers["Authorization"]) {
        throw new Error("Request is missing access token");
      }

      return {
        ...transformedOptions,
        headers
      };
    } catch (error) {
      throw error;
    }
  }
}
