import * as React from "react";
import {
  FC,
  memo,
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import * as CSSModules from "react-css-modules";
import { MlsContainerProps } from "./mls.container";
import PageHeader from "@haywork/components/ui/page-header";
import I18n from "@haywork/components/i18n";
import { SearchLocation } from "@haywork/api/mls";
import Location from "./components/search-location";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";
import differenceBy from "lodash-es/differenceBy";
import { Ui } from "@haywork/modules/ui";
import { Input, SwitchLabelPosition } from "@haywork/modules/form";
import * as moment from "moment";
import TrialInfoBar from "./components/info-bar";
import { TutorialComponent } from "@haywork/modules/mls/modals/tutorial/tutorial";

const styles = require("./style.scss");
const equals = require("react-fast-compare");

export type MlsComponentProps = {};
type Props = MlsComponentProps & MlsContainerProps;

export const MlsComponent: FC<Props> = memo(
  CSSModules(styles)(
    ({
      searchLocations,
      mlsAccessData,
      getSearchLocations,
      createNewSearchLocation,
      batchUpdate,
      toggleMlsSubscription,
    }) => {
      const [loading, setLoading] = useState(false);
      const [adding, setAdding] = useState(false);
      const [saving, setSaving] = useState(false);
      const [locations, setLocations] = useState<SearchLocation[]>(
        searchLocations || []
      );
      const [tutorialIsActive, setTutorialIsActive] = useState(false);
      const [infoBar, setInfoBar] = useState({
        isActive: false,
        daysRemaining: null,
      });
      const inited = useRef(false);

      const getInitialLocations = useCallback(async () => {
        if (inited.current || loading || !!searchLocations.length) return;
        inited.current = true;

        setLoading(true);
        await getSearchLocations();
        setLoading(false);
      }, [
        searchLocations,
        getSearchLocations,
        loading,
        setLoading,
        setLocations,
      ]);

      const addNewSearchLocation = useCallback(async () => {
        try {
          setAdding(true);
          const location = await createNewSearchLocation();
          setLocations((locations) => [...locations, location]);
        } catch (error) {
          throw error;
        } finally {
          setAdding(false);
        }
      }, [setLocations, createNewSearchLocation, setAdding]);

      const deleteCallback = useCallback(
        (id: string) => {
          setLocations((locations) =>
            locations.filter((location) => location.id !== id)
          );
        },
        [setLocations]
      );

      const changeCallback = useCallback(
        (searchLocation: SearchLocation) => {
          setLocations((locations) =>
            locations.map((location) =>
              location.id === searchLocation.id ? searchLocation : location
            )
          );
        },
        [setLocations]
      );

      const saveCallback = useCallback(async () => {
        try {
          setSaving(true);
          const deletedLocations = differenceBy(
            searchLocations,
            locations,
            (location) => location.id
          );

          const newLocations = locations.filter((location) => !!location.isNew);

          const updatedLocations = locations.filter((location) => {
            const ref = searchLocations.find((l) => l.id === location.id);
            return !location.isNew && !!ref && !equals(ref, location);
          });

          await batchUpdate(deletedLocations, newLocations, updatedLocations);
        } catch (error) {
          throw error;
        } finally {
          setSaving(false);
        }
      }, [searchLocations, locations, batchUpdate, setSaving]);

      const handleMlsSubscribe = useCallback(async () => {
        setSaving(true);
        try {
          await toggleMlsSubscription();
          setSaving(false);
        } catch (error) {
          setSaving(false);
        }
      }, [toggleMlsSubscription, setSaving]);

      const edited = useMemo(() => {
        return !equals(searchLocations, locations);
      }, [searchLocations, locations]);

      const valid = useMemo(() => {
        const invalidLocations = locations.filter(
          (location) =>
            !location.name || !location.postalCodeMin || !location.postalCodeMax
        );
        return !invalidLocations.length;
      }, [locations]);

      const handleInfoBarClick = useCallback(() => {
        window.open("https://www.youtube.com/watch?v=Z_VjqkfpuPU"); // 5wxvk3gc3nk
      }, []);

      useEffect(() => {
        const daysRemaining = moment(mlsAccessData?.demoUntilDateTime)
          .endOf("day")
          .diff(moment(), "days");
        setInfoBar({
          isActive:
            mlsAccessData?.isActive &&
            moment(mlsAccessData?.demoUntilDateTime).isAfter(),
          daysRemaining,
        });
      }, [setInfoBar, mlsAccessData]);

      useEffect(() => {
        getInitialLocations();
      }, [getInitialLocations]);

      useEffect(() => {
        setLocations(searchLocations);
      }, [searchLocations, setLocations]);

      return (
        <div styleName="mls">
          <PageHeader
            title="mls.settings.title"
            subTitle="mls.settings.subtitle"
            actions={
              <Button
                label="mls.settings.save"
                category="primary"
                disabled={saving || !edited || !valid}
                onClick={saveCallback}
                icon={
                  saving ? (
                    <Icon
                      name="spinner"
                      spin
                      regular
                      containIn={24}
                      color={Colors.Gray}
                    />
                  ) : null
                }
              />
            }
          />

          {(!edited || !!saving) && (
            <div className="alert alert-info">
              <i className="fal fa-fw fa-info-circle" />
              <I18n value="mls.settings.info" asHtml />
            </div>
          )}

          {!!edited && !saving && !!valid && (
            <div className="alert alert-warning">
              <i className="fal fa-fw fa-pencil" />
              <I18n value="mls.settings.hasChanges" asHtml />
            </div>
          )}

          <div styleName="mls__body">
            {!loading && (
              <div styleName="listHeader">
                <I18n value="mls.settings.activateMls" />
              </div>
            )}

            <div styleName="container">
              <div styleName="row">
                <div styleName="column">
                  <Input.Switch
                    name="isActive"
                    label="mls.settings.label.isActive"
                    value={mlsAccessData?.isActive}
                    labelPosition={SwitchLabelPosition.Pre}
                    on={true}
                    off={false}
                    onChange={handleMlsSubscribe}
                    asSingleInput
                  />
                </div>
              </div>
              <div styleName="row">
                {!loading && infoBar?.isActive && (
                  <TrialInfoBar
                    count={infoBar?.daysRemaining}
                    onClick={handleInfoBarClick}
                  />
                )}
              </div>
            </div>

            {saving && <Ui.Loaders.Fullscreen mask />}

            {!!mlsAccessData?.isActive && (
              <>
                {!loading && !!locations.length && (
                  <div styleName="listHeader">
                    <I18n value="mls.settings.workAreas" />
                  </div>
                )}
                <div styleName="container">
                  {locations.map((searchLocation) => (
                    <Location
                      searchLocation={searchLocation}
                      key={searchLocation.id}
                      onChange={changeCallback}
                      onDelete={deleteCallback}
                    />
                  ))}

                  {!!locations.length && (
                    <div>
                      <Button
                        label="mls.settings.action.add"
                        category="primary"
                        onClick={addNewSearchLocation}
                        icon={
                          adding ? (
                            <Icon
                              name="spinner"
                              spin
                              regular
                              containIn={24}
                              color={Colors.Gray}
                            />
                          ) : null
                        }
                        disabled={adding}
                      />
                    </div>
                  )}

                  {!loading && !locations.length && (
                    <EmptyState
                      icon="exchange"
                      title="mls.settings.emptyState.title"
                      subTitle="mls.settings.emptyState.subtitle"
                      action={
                        <Button
                          label="mls.settings.emptyState.action"
                          category="primary"
                          onClick={addNewSearchLocation}
                          icon={
                            adding ? (
                              <Icon
                                name="spinner"
                                spin
                                regular
                                containIn={24}
                                color={Colors.Gray}
                              />
                            ) : null
                          }
                          disabled={adding}
                        />
                      }
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
  )
);
