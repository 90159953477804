import { Bid, LinkedRelation } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Input } from "@haywork/modules/form";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo } from "react";
import RelationsToNotify from "../relations-to-notify";
import { useIntl } from "react-intl";

type Props = {
  bid: Bid;
  loading: boolean;
  relationsToNotify?: LinkedRelation[];
  actionFromModal: boolean;
  onUpdate: (bid: Bid) => void;
  onAccept: () => void;
  onBack: () => void;
};
export const AcceptComponent: FC<Props> = memo(
  ({
    bid,
    loading,
    relationsToNotify,
    onUpdate,
    onAccept,
    onBack,
    actionFromModal
  }) => {
    const intl = useIntl();

    if (!bid) return null;
    const onUpdateBid = (field: string, value: any) => {
      onUpdate({
        ...bid,
        [field]: value
      });
    };

    return (
      <>
        <ModalBody>
          <div className="form__row">
            <I18n
              value="bidsModal.body.accept"
              values={{
                amount: intl.formatNumber(bid.amount || 0, {
                  style: "currency",
                  currency: "EUR"
                })
              }}
              asHtml
            />
          </div>

          <div className="form__row">
            <label htmlFor="description">
              <I18n value="bidsModal.label.description" />
            </label>
            <Input.Textarea
              value={get(bid, "description", "")}
              name="description"
              asSingleInput
              autoSize
              minHeight={64}
              onChange={(value) => onUpdateBid("description", value)}
              disabled={loading}
            />
          </div>

          {!!relationsToNotify && !!relationsToNotify.length && (
            <RelationsToNotify relations={relationsToNotify} />
          )}
        </ModalBody>
        <ModalFooter>
          {actionFromModal && (
            <Button
              label="bidsModal.action.back"
              category="none"
              disabled={loading}
              onClick={onBack}
            />
          )}
          <Button
            label="bid.modal.accept.action"
            disabled={loading}
            onClick={onAccept}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
          />
        </ModalFooter>
      </>
    );
  }
);
