import { ErrorResponse, SwaggerException } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import get from "lodash-es/get";

/**
 * Object interfaces
 */
export interface ObjectStatus {
  status: string;
  error: ErrorResponse;
  refresh: boolean | null;
}

export interface SingleResult<G> extends ObjectStatus {
  item: G | null;
}

export interface PagedResults<G> extends ObjectStatus {
  results: G[];
  page: number;
  pageCount: number;
  total: number;
  canLoadMore: boolean;
}

/**
 * Action interfaces
 */
export interface Action {
  type?: string;
}

export interface Status extends Action {
  status: string;
  refresh?: boolean;
}

export interface IdentifierPayload extends Action {
  id: string;
}

export interface SingleResultResponse<G> extends Action {
  item: G;
}

export interface PagedResultsResponse<G> extends Action {
  results?: G[];
  resultCount: number;
  totalResults: number;
  take: number;
  statistics?: any;
}

/**
 * Helper class
 */
export class StoreHelper {
  /**
   * Initializers methods
   */
  public static createInitialSingleResult<G>(): SingleResult<G> {
    return {
      status: REQUEST.IDLE,
      error: null,
      refresh: null,
      item: null
    };
  }

  public static createInitialPagedResult<G>(): PagedResults<G> {
    return {
      status: REQUEST.IDLE,
      error: null,
      results: [],
      page: 0,
      pageCount: 0,
      total: 0,
      canLoadMore: false,
      refresh: null
    };
  }

  /**
   * Set methods
   */
  public static setObjectStatus(action: Status): Status {
    return {
      status: action.status,
      refresh: action.refresh || null
    };
  }

  public static setSingleResult<G>(
    action: SingleResultResponse<G>
  ): SingleResult<G> {
    return {
      status: REQUEST.SUCCESS,
      error: null,
      refresh: null,
      item: action.item
    };
  }

  public static setPagedResults<G>(
    action: PagedResultsResponse<G>
  ): PagedResults<G> {
    const { results, totalResults, take } = action;
    const pageCount = Math.ceil(totalResults / take);

    return {
      status: REQUEST.SUCCESS,
      error: null,
      results,
      page: 1,
      pageCount,
      total: totalResults,
      canLoadMore: pageCount > 1,
      refresh: null
    };
  }

  /**
   * Update methods
   */
  public static updateSingleResult<G>(
    action: SingleResultResponse<G>
  ): SingleResult<G> {
    return {
      status: REQUEST.SUCCESS,
      error: null,
      refresh: null,
      item: action.item
    };
  }

  public static updatePagedResults<G>(
    action: PagedResultsResponse<G>,
    currentState: PagedResults<G>
  ): PagedResults<G> {
    const { results, totalResults, take } = action;
    const { page: currentPage, results: currentResults } = currentState;
    const pageCount = Math.ceil(totalResults / take);
    const page = currentPage + 1;

    return {
      status: REQUEST.SUCCESS,
      error: null,
      refresh: null,
      results: [...currentResults, ...results],
      page,
      pageCount,
      total: totalResults,
      canLoadMore: page < pageCount
    };
  }

  public static updateSingleResultInResults<G>(
    item: G,
    matchOn: string,
    currentState: PagedResults<G>
  ): PagedResults<G> {
    if (!get(item, matchOn)) return currentState;

    const results = currentState.results.map((result) => {
      if (get(result, matchOn) === get(item, matchOn)) {
        return item;
      }
      return result;
    });

    return {
      ...currentState,
      results
    };
  }

  public static deleteSingleResultInResults<G>(
    id: string,
    matchOn: string,
    currentState: PagedResults<G>
  ): PagedResults<G> {
    const results = currentState.results.filter(
      (result) => get(result, matchOn) !== id
    );

    return {
      ...currentState,
      results
    };
  }
}

export const isSwaggerException = (obj: any): obj is SwaggerException => {
  return get(obj, "isSwaggerException") === true;
};
