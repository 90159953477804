import { Dispatch } from "@haywork/middleware";
import { FormComponent, FormComponentProps } from "@haywork/modules/form";
import { AppState } from "@haywork/stores";
import { FormError, FormErrors } from "@haywork/stores/form";
import escapeRegExp from "lodash-es/escapeRegExp";
import { connect } from "react-redux";

interface StateProps {
  pathname: string;
  errors: FormError[];
}
interface DispatchProps {}

const mapFormErrors = (errors: FormErrors[], pathname: string): FormError[] => {
  const reference = errors.find((error) =>
    new RegExp(escapeRegExp(error.path), "gi").test(pathname)
  );
  const stack = !!reference ? reference.stack : [];
  return stack.filter((s) => s.path === pathname);
};

const mapStateToProps = <StateProps, FormComponentProps>(state: AppState) => {
  const { pathname } = state.router.location;
  const { formErrors } = state.form.errors;

  return {
    pathname,
    errors: mapFormErrors(formErrors, pathname)
  };
};
const mapDispatchToProps = <DispatchProps, FormComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type FormContainerProps = StateProps & DispatchProps;
export const Form = connect<StateProps, DispatchProps, FormComponentProps>(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);
