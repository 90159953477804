import { DefaultFolderTreeSnapShot } from "@haywork/api/kolibri";
import PageHeader from "@haywork/components/ui/page-header";
import {
  COMMON,
  DOSSIERFOLDERROUTES,
  EXTERNALROUTES
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import ListGroup from "../../components/list-group";
import { SettingsDossierFoldersContainerProps } from "./overview.container";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./style.scss");

export interface SettingsDossierFoldersOverviewProps {}
interface State {
  loading: boolean;
}
type Props = SettingsDossierFoldersOverviewProps &
  SettingsDossierFoldersContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsDossierFoldersOverview extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.openDefaultFolderTree = this.openDefaultFolderTree.bind(this);
  }

  public componentDidMount() {
    this.props.searchDefaultFolderTrees();
  }

  public render() {
    return (
      <div styleName="overview">
        <PageHeader
          title="settings.pageTitle.dossierFolder"
          subTitle="settingsMenuUsage"
        />

        <div styleName="info">
          <i className="fal fa-info-circle" />
          <ResourceText
            resourceKey="settings.dossier.info"
            values={{ url: EXTERNALROUTES.SUPPORT_DOSSIER.URI }}
            asHtml
          />
        </div>
        <div styleName="list">
          {this.props.defaultFolderTrees.map((group, idx) => (
            <ListGroup
              group={group}
              openDefaultFolderTree={this.openDefaultFolderTree}
              key={group.type + idx}
            />
          ))}
        </div>
      </div>
    );
  }

  private async openDefaultFolderTree(category: DefaultFolderTreeSnapShot) {
    if (this.state.loading) return;

    const { id } = category;
    if (id === COMMON.EMPTY_GUID) {
      this.setState({ loading: true });
      try {
        const folderTree = await this.props.defineNewDefaultFolderTree(
          category.category
        );
        const path = route(DOSSIERFOLDERROUTES.DETAIL.URI, {
          id: folderTree.id
        });
        this.props.navigate(path);
      } finally {
        this.setState({ loading: false });
      }
      return;
    }

    const path = route(DOSSIERFOLDERROUTES.DETAIL.URI, { id });
    this.props.navigate(path);
  }
}
