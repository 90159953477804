import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./new-widget.component.scss");

interface DashboardNewWidgetComponentProps { }
interface DashboardNewWidgetComponentState { }

@CSSModules(styles, { allowMultiple: true })
export class DashboardNewWidgetComponent extends React.Component<DashboardNewWidgetComponentProps, DashboardNewWidgetComponentState> {
  public render() {
    return <article className="widget" styleName="widgetNew">
      <header>
        <span className="icon"><i className="fal fa-fw fa-lightbulb"></i></span>
        <span className="text"><ResourceText resourceKey="newWidgetTitle" /></span>
      </header>
      <div styleName="contentNew">
        <div styleName="item">
          <div styleName="text">We hebben het nieuwe Dashboard toegevoegd!</div>
        </div>
        <div styleName="item">
          <div styleName="text">Je kunt nu huurobjecten invoeren in Kolibri.</div>
        </div>
        <div styleName="item">
          <div styleName="text">Je kunt nu kadastrale gegevens opvragen bij opdrachten.</div>
        </div>
      </div>
    </article>;
  }
}
