import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { MappedCheckboxValue } from "@haywork/modules/filter";

const realEstateGroupsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.realEstateGroups || [];
const assignmentPhasesSelector = (state: AppState) =>
  state.main.mastertable.kolibri.assignmentPhases || [];
const officesSelector = (state: AppState) => state.account.offices || [];
const employeesSelector = (state: AppState) => state.account.employees || [];

export const realEstateGroups = createSelector(
  realEstateGroupsSelector,
  (realEstateGroups) => {
    return realEstateGroups.map((group) => ({
      label: group.displayName,
      value: group.value,
    })) as MappedCheckboxValue[];
  }
);
export const assignmentPhases = createSelector(
  assignmentPhasesSelector,
  (assignmentPhases) => {
    return assignmentPhases.map((phase) => ({
      label: phase.displayName,
      value: phase.value,
    })) as MappedCheckboxValue[];
  }
);
export const offices = createSelector(officesSelector, (offices) => {
  return offices.map((office) => ({
    label: office.displayName,
    value: office.id,
  })) as MappedCheckboxValue[];
});
export const employees = createSelector(employeesSelector, (employees) => {
  return employees.map((employee) => ({
    label: employee.displayName,
    value: employee.id,
  })) as MappedCheckboxValue[];
});
