import { Dispatch } from "@haywork/middleware";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect } from "react-redux";
import {
  UiOnboardingComponent,
  UiOnboardingComponentProps
} from "./onboarding.component";

interface StateProps {
  onboardingVisibility: boolean;
}
interface DispatchProps {
  hideOnboarding: () => void;
}

const mapStateToProps = <StateProps, UiOnboardingComponentProps>(
  state: AppState
) => ({
  onboardingVisibility: state.layout.onboardingVisibility
});

const mapDispatchToProps = <DispatchProps, UiOnboardingComponentProps>(
  dispatch: Dispatch<any>
) => ({
  hideOnboarding: () => dispatch(LayoutActions.toggleOnboarding(false))
});

export type UiOnboardingContainerProps = StateProps & DispatchProps;
export const UiOnboardingContainer = connect<
  StateProps,
  DispatchProps,
  UiOnboardingComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UiOnboardingComponent);
