/// <reference path="../../definitions/index.d.ts" />

import "@formatjs/intl-pluralrules/locale-data/nl";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/nl";
import "@formatjs/intl-relativetimeformat/polyfill";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import LoadScreen from "@haywork/modules/layout/components/load-screen";
import { AppState, persistor, store } from "@haywork/stores";
import * as moment from "moment";
import "moment/locale/nl";
import * as React from "react";
import { render } from "react-dom";
import { createIntl, createIntlCache, IntlProvider } from "react-intl";
import { connect, MapStateToProps, Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as smoothscroll from "smoothscroll-polyfill";
import "./../default.scss";
import { AppRouting } from "./routes";
import { nlResource as nlTranslations } from "./translations/index";
import WindowMessages from "@haywork/modules/window-messages";

smoothscroll.polyfill();

// Setup supported languages
moment.locale("nl");

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => ({
  locale: state.main.culture,
  defaultLocale: state.main.culture,
  messages: state.main.cultureMessages,
  formats: {
    number: {
      euro: { style: "currency", currency: "EUR" },
      euro_short: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
    date: {
      def: { day: "2-digit", month: "2-digit", year: "numeric" },
    },
  },
});

const ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider);

const cache = createIntlCache();
export const intlContext = createIntl(
  {
    messages: nlTranslations,
    locale: "nl",
    defaultLocale: "nl",
  },
  cache
);

render(
  <Provider store={store}>
    <WindowMessages />
    <PersistGate loading={<LoadScreen />} persistor={persistor}>
      <ConnectedIntlProvider>
        <ErrorBoundary isRoot={true}>
          <AppRouting />
        </ErrorBoundary>
      </ConnectedIntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("app")
);
