export * from "./new/new-document.component";
export * from "./detail/detail.component";
export * from "./download";
export * from "./detail/detail.navbar.component";
export * from "./document/document.component";
export * from "./info/info.component";
export * from "./form/form.component";
export * from "./signatures/signatures.component";
export * from "./preview/preview.component";
export * from "./save-modal/save-modal.component";
export * from "./list-of-goods/list-of-goods.component";

export enum TypeOfDocument {
  Other = "Other",
  ForSale = "ForSale",
  ForRent = "ForRent"
}
