import { AddressSearchState } from "../assignment/single/single.types";
import { Action } from "redux";

import * as ActionType from "./office.types";
import {
  RelationType,
  RelationSnapShot,
  Office,
  PhotoBlob,
  AssignmentSnapShot,
  LanguageSpokenOption,
  LinkedEmployee,
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";

export interface OfficeState {
  officeInitialOverviewState: string;
  officeOverviewState: string;
  officeOverviewCount: number;
  officeOverviewPage: number;
  offices: RelationSnapShot[];
  totalOfficesCount: number;
  officeState: string;
  selectedOffice: Office;
  officeAssignmentsState: string;
  officeAssignmentInitialState: string;
  officeAssignments: AssignmentSnapShot[];
  officeAssignmentPage: number;
  officeAssignmentPageCount: number;
  saveOfficeInfoState: string;
  saveOfficeAddressInfoState: string;
  officeEmployeeInitialOverviewState: string;
  latestSpokenLanguageAdded: LanguageSpokenOption;
  contactAddError: boolean;
  officeEmployeeSaveState: string;
}

const INITIAL_STATE: OfficeState = {
  officeInitialOverviewState: REQUEST.IDLE,
  officeOverviewState: REQUEST.IDLE,
  officeOverviewCount: 0,
  officeOverviewPage: 0,
  offices: [],
  totalOfficesCount: 0,
  officeState: REQUEST.IDLE,
  selectedOffice: null,
  officeAssignmentsState: REQUEST.IDLE,
  officeAssignmentInitialState: REQUEST.IDLE,
  officeAssignments: null,
  officeAssignmentPage: 0,
  officeAssignmentPageCount: 0,
  saveOfficeInfoState: REQUEST.IDLE,
  saveOfficeAddressInfoState: REQUEST.IDLE,
  officeEmployeeInitialOverviewState: REQUEST.IDLE,
  latestSpokenLanguageAdded: null,
  contactAddError: false,
  officeEmployeeSaveState: REQUEST.IDLE,
};

export const officesReducer = (
  state: OfficeState = INITIAL_STATE,
  action: Action
): OfficeState => {
  switch (action.type) {
    case REDUX.OFFICES.SET_FETCH_OFFICE_OVERVIEW_STATE: {
      const { officeOverviewState } = <ActionType.OfficeOverviewState>action;
      return { ...state, officeOverviewState };
    }
    case REDUX.OFFICES.SET_INITIAL_FETCH_OFFICE_OVERVIEW_STATE: {
      const { officeOverviewState } = <ActionType.OfficeOverviewState>action;
      return { ...state, officeInitialOverviewState: officeOverviewState };
    }
    case REDUX.OFFICES.APPEND_OFFICES: {
      const { resultCount, results, statistics, totalResults } = <
        ActionType.OfficeResponse
      >action;
      let officeOverviewCount = Math.ceil(totalResults / resultCount);
      if (officeOverviewCount === Infinity) {
        officeOverviewCount = 0;
      }
      const officeOverviewPage = state.officeOverviewPage + 1;
      return {
        ...state,
        offices: [...state.offices, ...results],
        totalOfficesCount: totalResults,
        officeOverviewCount,
        officeOverviewPage,
      };
    }
    case REDUX.OFFICES.SET_OFFICES: {
      const { resultCount, results, statistics, totalResults } = <
        ActionType.OfficeResponse
      >action;

      const officeOverviewCount = Math.ceil(totalResults / resultCount);
      return {
        ...state,
        offices: results,
        totalOfficesCount: totalResults,
        officeOverviewCount,
        officeOverviewPage: 1,
        officeInitialOverviewState: REQUEST.SUCCESS,
      };
    }
    case REDUX.OFFICES.CLEAR_OFFICES: {
      return {
        ...state,
        offices: [],
        totalOfficesCount: 0,
        officeOverviewCount: 0,
        officeOverviewPage: 0,
      };
    }
    case REDUX.OFFICES.SET_OFFICE: {
      const { office } = <ActionType.SingleOfficeResponse>action;

      if (office.linkedEmployees) {
        const sortedEmployees = office.linkedEmployees.filter(
          ({ isActive }) => isActive === true
        );

        sortedEmployees.sort((a, b) => {
          if (a.displayName && b.displayName) {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
              return -1;
            if (a.displayName.toLowerCase() > b.displayName.toLocaleLowerCase())
              return 1;
            return 0;
          }
        });
        return {
          ...state,
          selectedOffice: { ...office, linkedEmployees: sortedEmployees },
          officeState: REQUEST.SUCCESS,
        };
      } else {
        return {
          ...state,
          selectedOffice: { ...office, linkedEmployees: [] },
          officeState: REQUEST.SUCCESS,
        };
      }
    }
    case REDUX.OFFICES.SET_FETCH_OFFICES_STATE: {
      const { saveOfficeInfoState } = <ActionType.OfficeInfoState>action;
      return { ...state, officeState: saveOfficeInfoState };
    }
    case REDUX.OFFICES.SET_OFFICE_ASSIGNMENT_STATE: {
      const { officeAssignmentsState } = <ActionType.OfficeAssignmentsState>(
        action
      );
      return { ...state, officeAssignmentsState };
    }
    case REDUX.OFFICES.SEARCH_OFFICE_ASSIGNMENTS: {
      const { totalResults, results, resultCount } = <
        ActionType.AssignmentsResponse
      >action;
      const officeAssignmentPageCount = Math.round(totalResults / resultCount);

      return {
        ...state,
        officeAssignments: results,
        officeAssignmentPage: 1,
        officeAssignmentPageCount,
      };
    }
    case REDUX.OFFICES.APPEND_OFFICE_ASSIGNMENTS: {
      const { resultCount, results, totalResults } = <
        ActionType.AssignmentsResponse
      >action;
      const officeAssignmentPageCount = Math.round(totalResults / resultCount);
      const officeAssignmentPage = state.officeAssignmentPage + 1;

      return {
        ...state,
        officeAssignments: [...state.officeAssignments, ...results],
        officeAssignmentPage,
        officeAssignmentPageCount,
      };
    }
    case REDUX.OFFICES.SEARCH_OFFICE: {
      const { office } = <ActionType.SingleOfficeResponse>action;
      return {
        ...state,
        selectedOffice: office,
        officeState: REQUEST.SUCCESS,
        contactAddError: false,
      };
    }
    case REDUX.OFFICES.SET_SAVE_OFFICE_INFO_STATE: {
      const { saveOfficeInfoState } = <ActionType.OfficeInfoState>action;
      return { ...state, saveOfficeInfoState };
    }
    case REDUX.OFFICES.SET_OFFICE_ADDRESS_SEARCH_STATE: {
      const { saveOfficeAddressInfoState } = <
        ActionType.SaveOfficeAddressInfoState
      >action;
      return { ...state, saveOfficeAddressInfoState };
    }
    case REDUX.OFFICES.SET_INITAL_OFFICE_EMPLOYEE_OVERVIEW_STATE: {
      const { officeEmployeeInitialOverviewState } = <
        ActionType.OfficeEmployeeInitialOverviewState
      >action;
      return {
        ...state,
        officeEmployeeInitialOverviewState,
      };
    }
    case REDUX.OFFICES.CLEAR_EMPLOYEE_LIST: {
      const { office } = <ActionType.OfficeReturn>action;
      return {
        ...state,
        selectedOffice: { ...office, linkedEmployees: [] },
        contactAddError: false,
      };
    }
    case REDUX.OFFICES.CLEAR_ASSIGNMENT_LIST: {
      return {
        ...state,
        officeAssignments: [],
      };
    }
    case REDUX.OFFICES.ADD_SPOKEN_LANGUAGE: {
      const { latestSpokenLanguageAdded } = <
        ActionType.OfficeAddSpokenLanguage
      >action;
      return {
        ...state,
        latestSpokenLanguageAdded,
      };
    }
    case REDUX.OFFICES.SET_EMPLOYEE_LIST: {
      const { employeeList, employeeToAdd, office } = <
        ActionType.OfficeEmployeeList
      >action;
      const sortedEmployeeList: LinkedEmployee[] = employeeList;

      sortedEmployeeList.sort((a, b) => {
        if (a.displayName && b.displayName) {
          if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
            return -1;
          if (a.displayName.toLowerCase() > b.displayName.toLocaleLowerCase())
            return 1;
          return 0;
        }
      });

      const toAdd = sortedEmployeeList.find(
        (employee) => employeeToAdd.id === employee.id
      );

      if (!toAdd) {
        sortedEmployeeList.unshift(employeeToAdd);
        return {
          ...state,
          selectedOffice: { ...office, linkedEmployees: sortedEmployeeList },
          contactAddError: false,
        };
      } else {
        return { ...state, contactAddError: true }; // contact is already in list, so return error
      }
    }
    case REDUX.OFFICES.SET_OFFICE_ASSIGNMENT_INITIAL_STATE: {
      const { officeAssignmentInitialState } = <
        ActionType.OfficeAssignmentsInitialState
      >action;
      return { ...state, officeAssignmentInitialState };
    }
    case REDUX.OFFICES.RESET_SEARCH_STATE: {
      return state;
    }
    case REDUX.OFFICES.ARCHIVE_OFFICE: {
      const { office } = <ActionType.OfficeReturn>action;
      const filterdOffices = state.offices.filter(({ id }) => id !== office.id);

      return {
        ...state,
        offices: filterdOffices,
        totalOfficesCount: state.totalOfficesCount - 1,
      };
    }
    case REDUX.OFFICES.ARCHIVE_OFFICE_EMPLOYEE: {
      const { employeeId, office } = <ActionType.ArchiveOfficeEmployee>action;
      const filterdOffices = state.selectedOffice.linkedEmployees.filter(
        ({ id }) => id !== employeeId
      );

      return {
        ...state,
        selectedOffice: { ...office, linkedEmployees: filterdOffices },
      };
    }
    case REDUX.OFFICES.SET_TOTAL_OFFICES: {
      const { totalOffices } = <ActionType.TotalNumberOfOffices>action;
      return {
        ...state,
        totalOfficesCount: totalOffices,
      };
    }
    case REDUX.OFFICES.SET_OFFICE_EMPLOYEE_SAVE_STATE: {
      const { officeEmployeeSaveState } = <ActionType.OfficeEmployeeSaveState>(
        action
      );
      return {
        ...state,
        officeEmployeeSaveState,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
