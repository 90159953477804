import * as React from "react";
import * as nl2br from "nl2br";

import { ModalPortal } from "@haywork/portals";
import { UiPhrasesModal, UiPhrasesEditModal } from "@haywork/modules/ui";

import { EditorPhrasesContainerProps } from "./phrases.container";
import { FormReturnValue } from "@haywork/modules/form";
import { PhraseSnapShot } from "@haywork/api/kolibri";

const EMAIL_BODY_PHRASE_ID = 3003;
const EMAIL_TITLE_PHRASE_ID = 3004;

export interface EditorPhrasesComponentProps {
  phrasesModalVisible: boolean;
  phrasesRef: HTMLDivElement;
  onPhraseAdd: (content: string) => void;
  onClose: () => void;
}
interface State {
  phrasesModalVisible: boolean;
  phrasesEditModalVisible: boolean;
}
type Props = EditorPhrasesComponentProps & EditorPhrasesContainerProps;

export class EditorPhrasesComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      phrasesModalVisible: false,
      phrasesEditModalVisible: false
    };

    this.onPhraseModalClose = this.onPhraseModalClose.bind(this);
    this.onPhraseModalAdd = this.onPhraseModalAdd.bind(this);
    this.onPhraseModalEdit = this.onPhraseModalEdit.bind(this);
    this.onPhraseModalAddClick = this.onPhraseModalAddClick.bind(this);
    this.onPhrasesEditClose = this.onPhrasesEditClose.bind(this);
    this.onPhrasesEditAddPhrase = this.onPhrasesEditAddPhrase.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (nextProps.phrasesModalVisible !== this.state.phrasesModalVisible) {
      if (!!nextProps.phrasesModalVisible) {
        this.props.getPhrases(EMAIL_BODY_PHRASE_ID);
      }
      this.setState({
        phrasesModalVisible: nextProps.phrasesModalVisible
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        {!!this.state.phrasesModalVisible && (
          <ModalPortal>
            <UiPhrasesModal
              onClose={this.onPhraseModalClose}
              onPhraseAdd={this.onPhraseModalAdd}
              onPhraseEdit={this.onPhraseModalEdit}
              onAddClick={this.onPhraseModalAddClick}
              parent={this.props.phrasesRef}
            />
          </ModalPortal>
        )}

        <UiPhrasesEditModal
          visible={this.state.phrasesEditModalVisible}
          onClose={this.onPhrasesEditClose}
          onUpdatedPhrase={this.props.updatePhrase}
          onAddPhrase={this.onPhrasesEditAddPhrase}
          phrase={this.props.phrase}
          phraseStatus={this.props.phraseStatus}
        />
      </React.Fragment>
    );
  }

  private onPhrasesEditClose() {
    this.setState({
      phrasesEditModalVisible: false,
      phrasesModalVisible: false
    });

    this.props.unsetPhrase();
    this.props.onClose();
  }

  private async onPhrasesEditAddPhrase(values: FormReturnValue) {
    await this.props.savePhrase(
      values.title,
      values.description,
      EMAIL_BODY_PHRASE_ID
    );

    this.setState({
      phrasesEditModalVisible: false,
      phrasesModalVisible: false
    });

    this.props.onClose();
  }

  private onPhraseModalClose() {
    this.setState({ phrasesModalVisible: false });

    this.props.clearPhrases();
    this.props.onClose();
  }

  private onPhraseModalEdit(phrase: PhraseSnapShot) {
    this.setState({
      phrasesEditModalVisible: true,
      phrasesModalVisible: false
    });

    this.props.getPhrase(phrase.id);
    this.props.onClose();
  }

  private onPhraseModalAddClick() {
    this.setState({
      phrasesEditModalVisible: true,
      phrasesModalVisible: false
    });

    this.props.onClose();
  }

  private onPhraseModalAdd(value: FormReturnValue) {
    this.setState({
      phrasesModalVisible: false
    });

    this.props.onPhraseAdd(nl2br(value.description));
    this.props.clearPhrases();
    this.props.onClose();
  }
}
