import { Account, ReauthenticateAccountRequest } from "@haywork/api/mail";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./re-authenticate-modal.component.scss");

interface Props {
  account: Account;
  onClose: () => void;
  onReAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
}
interface State {
  loading: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ReAuthGmailComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);

    this.state = {
      loading: false,
    };
  }

  public render() {
    return (
      <div styleName="gmail">
        <div styleName="gmail__body">
          <ResourceText
            resourceKey="emailReAuthGmailInfo"
            values={{ email: this.props.account.emailAddress }}
            asHtml
          />
        </div>
        <div styleName="footer" className="flex">
          <div className="flex justify-start flex-grow-1">
            <button
              type="button"
              className="btn btn-blank"
              onClick={this.props.onClose}
              disabled={this.state.loading}
            >
              <ResourceText resourceKey="IllDoThisLater" />
            </button>
          </div>
          <div className="flex justify-end flex-grow-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSubmitClickHandler}
            >
              <ButtonLoader
                resourceKey="reAuthGoogleAction"
                loading={this.state.loading}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private async onSubmitClickHandler() {
    if (this.state.loading) return;

    this.setState({ loading: true });
    try {
      const authenticated = this.props.onReAuthenticate({
        accountId: this.props.account.id,
      });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }
}
