import {
  ObjectAssignment,
  RentCondition,
  RentConditionOption,
} from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  RenewRentOfferModalComponent,
  RenewRentOfferModalComponentProps,
} from "./renew-rent-offer-modal.component";

interface StateProps {
  rentConditions: RentConditionOption[];
}
interface DispatchProps {
  renewRentOffer: (
    assignment: ObjectAssignment,
    availableFrom: Date,
    availableUntil: Date,
    rentPrice: number,
    rentCondition: RentCondition,
    availabilityIsTemporary: boolean,
    copyKeyNumber: boolean,
    keepKeyNumber: boolean
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  RenewRentOfferModalComponentProps,
  AppState
> = (state) => {
  const { rentConditions } = state.main.mastertable.kolibri;

  return {
    rentConditions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  RenewRentOfferModalComponentProps
> = (dispatch) => ({
  renewRentOffer: (
    assignment: ObjectAssignment,
    availableFrom: Date,
    availableUntil: Date,
    rentPrice: number,
    rentCondition: RentCondition,
    availabilityIsTemporary: boolean,
    copyKeyNumber: boolean,
    keepKeyNumber: boolean
  ) =>
    dispatch(
      AssignmentThunks.renewRentOffer(
        assignment,
        availableFrom,
        availableUntil,
        rentPrice,
        rentCondition,
        availabilityIsTemporary,
        copyKeyNumber,
        keepKeyNumber
      )
    ),
});

export type RenewRentOfferModalContainerProps = StateProps & DispatchProps;
export const RenewRentOfferModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewRentOfferModalComponent);
