import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { ActiveFilter, AssignmentSnapShot } from "@haywork/api/kolibri";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks
} from "@haywork/middleware";
import {
  ProjectDetailTypesComponent,
  ProjectDetailTypesComponentProps
} from "@haywork/modules/project";
import {
  canCreateObjectTypeAssignment,
  objectTypesLoading
} from "@haywork/modules/project/containers/selectors";
import { AppState, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  objectTypes: AssignmentSnapShot[];
  projectId: string;
  loading: boolean;
  canLoadMore: boolean;
  objectTypesTotal: number;
  displayName: string;
  canCreateObjectTypeAssignment: boolean;
}

interface DispatchProps {
  goToObjectTypeDetail?: (id: string) => void;
  onAddNewType: (projectId: string) => void;
  getObjectTypes: (
    projectId: string,
    filterByActive: ActiveFilter,
    reset?: boolean
  ) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, ProjectDetailTypesComponentProps>(
  state: AppState
) => {
  const currentComponentState: SingleProjectState =
    state.editable.currentComponentState;
  const {
    objectTypesCanLoadMore: canLoadMore,
    objectTypes,
    objectTypesTotal
  } = state.project.single;

  return {
    objectTypes,
    projectId: currentComponentState.projectAssignment.id,
    displayName: currentComponentState.projectAssignment.displayName,
    loading: objectTypesLoading(state),
    canLoadMore,
    objectTypesTotal,
    canCreateObjectTypeAssignment: canCreateObjectTypeAssignment(state)
  };
};

const mapDispatchToProps = <DispatchProps, ProjectDetailTypesComponentProps>(
  dispatch: Dispatch<any>
) => ({
  goToObjectTypeDetail: (id: string) =>
    dispatch(push(route(OBJECTTYPESROUTES.DETAIL.URI, { id }))),
  onAddNewType: (projectId: string) =>
    dispatch(ProjectsThunks.Types.newType(projectId)),
  getObjectTypes: (
    projectId: string,
    filterByActive: ActiveFilter,
    reset?: boolean
  ) =>
    dispatch(
      ProjectsThunks.Projects.getObjectTypes(projectId, filterByActive, reset)
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type ProjectDetailTypesContainerProps = StateProps & DispatchProps;
export const ProjectDetailTypesContainer = connect<
  StateProps,
  DispatchProps,
  ProjectDetailTypesComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailTypesComponent);
