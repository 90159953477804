import { ActiveFilter, RelationSnapShot } from "@haywork/api/kolibri";
import { Dispatch, EmployeeFilter, EmployeeThunk } from "@haywork/middleware";
import { MappedDropdownValue } from "@haywork/modules/filter";
import { EmployeeOverviewComponentProps } from "@haywork/modules/settings";
import { EmployeeOverviewComponent } from "@haywork/modules/settings/modules/employees/components/overview/employee-overview.component";
import { AppState } from "@haywork/stores";
import { EmployeeActions } from "@haywork/stores/employee/employee.action";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  totalEmployeeCount: number;
  employees: RelationSnapShot[];
  employeeOverviewState: string;
  employeeInitialOverviewState: string;
  canLoadMore: boolean;
  showEmployeeModal: boolean;
  officeList: MappedDropdownValue[];

  // Filters
  selectedEmployeeFilter?: EmployeeFilter;
  activeFilterOptions?: MappedDropdownValue[];
  officeFilterOptions?: MappedDropdownValue[];
}

interface DispatchProps {
  getEmployees: (
    init?: boolean,
    take?: number,
    employeeFilter?: EmployeeFilter
  ) => void;
  archiveEmployee: (employee: RelationSnapShot) => void;
  unArchiveEmployee: (employee: RelationSnapShot) => void;
  updateFilter: (employeeFilter: EmployeeFilter) => void;
  removeFromList: (employee: RelationSnapShot) => void;
  goToEmployeeDetail: (routePathString) => void;
  toggleEmployeeModal: (status: boolean) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EmployeeOverviewComponentProps,
  AppState
> = (state: AppState) => ({
  totalEmployeeCount: state.employee.totalEmployeeCount,
  employees: state.employee.employees,
  canLoadMore:
    state.employee.employeeOverviewPage < state.employee.employeeOverviewCount,
  showEmployeeModal: state.employee.showEmployeeModal,
  selectedEmployeeFilter: state.employee.selectedEmployeeFilters,
  activeFilterOptions: mapActiveFilters([
    ActiveFilter.ActiveOnly,
    ActiveFilter.InactiveOnly,
    ActiveFilter.ActiveOrInactive,
  ]),
  employeeOverviewState: state.employee.employeeOverviewState,
  employeeInitialOverviewState: state.employee.employeeInitialOverviewState,
  officeFilterOptions: mapOfficeFilters(state.account.offices),
  officeList: mapOffices(state.account.offices),
});

const mapDispatchToProps = <DispatchProps, EmployeeOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getEmployees: (init?: boolean, take?: number) =>
    dispatch(EmployeeThunk.getEmployees(init, take)),
  archiveEmployee: (employee: RelationSnapShot) =>
    dispatch(EmployeeThunk.archiveEmployee(employee)),
  unArchiveEmployee: (employee: RelationSnapShot) =>
    dispatch(EmployeeThunk.unArchiveEmployee(employee)),
  removeFromList: (employee: RelationSnapShot) =>
    dispatch(EmployeeActions.archiveEmployee(employee)),
  updateFilter: (employeeFilter?: EmployeeFilter) =>
    dispatch(EmployeeActions.updateEmployeeFilter(employeeFilter)),
  goToEmployeeDetail: (routePath: string) => dispatch(push(routePath)),
  toggleEmployeeModal: (status: boolean) =>
    dispatch(EmployeeActions.toggleEmployeModal(status)),
});

export type EmployeeOverviewContainerProps = StateProps & DispatchProps;
export const EmployeeOverviewContainer = connect<
  StateProps,
  DispatchProps,
  EmployeeOverviewContainerProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeOverviewComponent);

const mapOfficeFilters = (offices: RelationSnapShot[]) => {
  const mappedOffices: MappedDropdownValue[] = offices.map((val) => ({
    label: val.displayName,
    value: val.id,
  }));
  const defaultOffice: MappedDropdownValue[] = [
    { label: "Alle kantoren", value: null },
  ];
  return [...defaultOffice, ...mappedOffices];
};

const mapActiveFilters = (activeFilters) => {
  return activeFilters.map((activeFilter) => {
    return getSingleActiveFilter(activeFilter);
  });
};

const getSingleActiveFilter = (activeFilter) => {
  switch (activeFilter) {
    case ActiveFilter.ActiveOnly:
      return { label: "relationFilterSentenceActiveOnly", value: activeFilter };
    case ActiveFilter.InactiveOnly:
      return {
        label: "relationFilterSentenceInactiveOnly",
        value: activeFilter,
      };
    default:
      return { label: "all", value: activeFilter };
  }
};

const mapOffices = (offices: RelationSnapShot[]) => {
  const mappedOffices: MappedDropdownValue[] = offices.map((val) => ({
    label: val.displayName,
    value: val.id,
  }));
  return [...mappedOffices];
};
