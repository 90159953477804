import { BidStatus, BidType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import ActionList, { Action } from "@haywork/components/ui/action-list";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { BidDossier } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import head from "lodash-es/head";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import { BidAction } from "../..";

const styles = require("./style.scss");

type BidDossierComponentProps = {
  dossier: BidDossier;
  onAction: (dossier: BidDossier, action: BidAction) => void;
};
type Props = BidDossierComponentProps;

export const IntlBidDossierComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ dossier, onAction }) => {
    const { linkedRelations, latestBid, bids } = dossier;

    const intl = useIntl();
    const actions = useRef<HTMLDivElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const lastestBidString = useMemo(() => {
      const {
        amount,
        bidDateTime,
        type,
        linkedEmployee,
        linkedRelations,
        validUntil,
      } = latestBid;
      const bid = intl.formatNumber(amount, {
        style: "currency",
        currency: "EUR",
      });
      const date = moment(bidDateTime).format("dddd DD-MM");
      const employeeName = get(linkedEmployee, "displayName", "");
      const relation = head(linkedRelations || []);
      const relationName = get(relation, "displayName", "");
      const validUntill = !validUntil
        ? ""
        : moment(validUntil).format("dddd DD-MM");

      const translationKey = !validUntil
        ? "bids.latestBid.object"
        : "bids.latestBid.object.validUntill";

      return (
        <I18n
          value={`${translationKey}.${type.toString()}`}
          values={{ bid, date, employeeName, relationName, validUntill }}
        />
      );
    }, [latestBid, intl]);

    const icon = useMemo(() => {
      const name =
        latestBid.type === BidType.Incoming ? "arrow-left" : "arrow-right";
      const color =
        latestBid.type === BidType.Incoming ? Colors.Success : Colors.Primary;

      return <Icon name={name} size={20} color={color} light />;
    }, [latestBid.type]);

    const statusStyle = useMemo(() => {
      switch (latestBid.status) {
        case BidStatus.Active: {
          return "active";
        }
        case BidStatus.Denied: {
          return "denied";
        }
        case BidStatus.Expired: {
          return "expired";
        }
        case BidStatus.Accepted: {
          return "accepted";
        }
        default: {
          return null;
        }
      }
    }, [latestBid.status]);

    const status = useMemo(() => {
      const { status } = latestBid;

      return (
        <div styleName={classNames("status", statusStyle)}>
          <I18n prefix="bidStatusOptions" value={status.toString()} />
        </div>
      );
    }, [latestBid.status, statusStyle]);

    const toggleActions = useCallback(() => {
      setActionsVisible(!actionsVisible);
    }, [actionsVisible]);

    const onActionClick = useCallback(
      (action: BidAction) => {
        onAction(dossier, action);
        setActionsVisible(false);
      },
      [dossier]
    );

    return (
      <>
        <div
          styleName={classNames("dossier", statusStyle)}
          onClick={toggleActions}
          ref={actions}
        >
          <div styleName="relations">
            {linkedRelations.map((relation) => (
              <div styleName="relation" key={relation.id}>
                {relation.displayName}
              </div>
            ))}
          </div>
          {status}
          <div styleName="count">
            <I18n value="bids.count" values={{ count: bids.length }} />
          </div>
          <div styleName="divider" />
          <div styleName="meta">
            <div styleName="icon">{icon}</div>
            <div styleName="info">{lastestBidString}</div>
          </div>
        </div>
        <ActionList
          parent={actions}
          visible={actionsVisible}
          onHide={toggleActions}
        >
          <Action
            label="bids.action.object.showAllBids"
            icon={
              <Icon light name="eye" color={Colors.ActionListIcon} fixedWidth />
            }
            onClick={() => onActionClick(BidAction.ShowAllBids)}
          />
          <Action
            label="bids.action.object.edit"
            icon={
              <Icon
                light
                name="pencil"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onActionClick(BidAction.Edit)}
          />
          {![BidStatus.Accepted, BidStatus.Denied].includes(
            latestBid.status
          ) && (
            <>
              <Action
                label={
                  latestBid.type === BidType.Incoming
                    ? "bids.action.object.counterOffer"
                    : "bids.action.object.offer"
                }
                icon={
                  <Icon
                    light
                    name={
                      latestBid.type === BidType.Incoming
                        ? "arrow-right"
                        : "arrow-left"
                    }
                    color={Colors.ActionListIcon}
                    fixedWidth
                  />
                }
                onClick={() => onActionClick(BidAction.CounterOffer)}
              />
              <Action
                label="bids.action.object.accept"
                icon={
                  <Icon
                    light
                    name="check"
                    color={Colors.ActionListIcon}
                    fixedWidth
                  />
                }
                onClick={() => onActionClick(BidAction.Accept)}
              />
              <Action
                label={
                  latestBid.type === BidType.Incoming
                    ? "bids.action.object.reject"
                    : "bids.action.object.withdraw"
                }
                icon={
                  <Icon
                    light
                    name="times"
                    color={Colors.ActionListIcon}
                    fixedWidth
                  />
                }
                onClick={() => onActionClick(BidAction.Reject)}
              />
            </>
          )}
        </ActionList>
      </>
    );
  })
);
