import { REDUX } from "@haywork/constants";
import { SearchAssignment, MatchSearchPropertiesResponse, RealEstateAgency } from "@haywork/api/kolibri";

import * as ActionType from "./real-estate-agency.types";

const setRealEstateAgency = (realEstateAgency: RealEstateAgency) => {
  return {
    type: REDUX.REALESTATE_AGENCY.SET_REALESTATE_AGENCY,
    realEstateAgency
  };
};

const setRealEstateAgencyStatus = (realEstateAgencyStatus: string) => ({
  type: REDUX.REALESTATE_AGENCY.SET_REALESTATE_AGENCY_STATUS,
  realEstateAgencyStatus
});

export const RealEstateAgencyActions = {
  setRealEstateAgency,
  setRealEstateAgencyStatus
};
