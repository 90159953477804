import { FolderTreeFolderEntity } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState, MouseEvent } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  folder: FolderTreeFolderEntity;
  parentName: string;
  selectedFolderName: string | null;
  depth: number;
  onToggleSelect: (folderName: string) => void;
};

export const FolderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ folder, parentName, selectedFolderName, depth, onToggleSelect }) => {
      const { folders, name } = folder;

      const canCollapse = useMemo(() => {
        return !!folders && !!folders.length;
      }, [folders]);

      const [collapsed, setCollapsed] = useState(depth > 0 || !canCollapse);

      const toggleCollapsed = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          if (!canCollapse) return;
          setCollapsed(!collapsed);
        },
        [collapsed, setCollapsed, canCollapse]
      );

      const composedName = useMemo(() => {
        return [parentName, name].filter((f) => !!f).join("/");
      }, [parentName, name]);

      const active = useMemo(() => {
        return composedName === selectedFolderName;
      }, [selectedFolderName, composedName]);

      return (
        <div styleName="folder">
          <div
            onClick={() => onToggleSelect(composedName)}
            styleName={classNames("header", `depth-${depth}`, {
              active,
            })}
          >
            {canCollapse && (
              <div styleName="header__icon" onClick={toggleCollapsed}>
                <Icon
                  name={collapsed ? "chevron-right" : "chevron-down"}
                  color={active ? Colors.White : Colors.Primary}
                  regular
                  size={14}
                />
              </div>
            )}
            <div styleName="header__icon">
              <Icon
                name={collapsed ? "folder" : "folder-open"}
                color={active ? Colors.White : Colors.Primary}
                solid
                size={16}
              />
            </div>
            <div styleName="header__label">{name}</div>
          </div>
          {!collapsed && (
            <div>
              {(folders || []).map((folder, idx) => (
                <FolderComponent
                  folder={folder}
                  parentName={composedName}
                  selectedFolderName={selectedFolderName}
                  depth={depth + 1}
                  key={folder.name + idx}
                  onToggleSelect={onToggleSelect}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
