import * as Ably from "ably";
import isString from "lodash-es/isString";
import * as React from "react";
import { Component } from "react";
import Connecter from "./context-connector";
import {
  AnnouncementMessage,
  AnnouncementCategory,
  NotificationEvent,
  RootEntityType,
} from "@haywork/api/event-center";
import { EventCenterContainerProps } from "./event-center.container";
import * as equal from "deep-equal";
import { EventCenterContextProvider } from "./event-center.context";
import noop from "lodash-es/noop";
import * as Fingerprint2 from "fingerprintjs2";
import { PresentEmployee } from "@haywork/stores";
import * as bowser from "bowser";
import { FeatureHelper } from "@haywork/modules/feature-switch";

const equals = require("react-fast-compare");

export type EventCenterComponentProps = {};
type Props = EventCenterComponentProps & EventCenterContainerProps;
type State = {
  companyChannel: Ably.Types.RealtimeChannelCallbacks | null;
  personalChannel: Ably.Types.RealtimeChannelCallbacks | null;
  presenceChannel: Ably.Types.RealtimeChannelCallbacks | null;
  realtime: Ably.Realtime | null;
  refreshMailRealtime: () => void;
  fingerprint: string | null;
};

export class EventCenterComponent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.handleCompanyChannelEvent = this.handleCompanyChannelEvent.bind(this);
    this.handlePersonalChannelEvent = this.handlePersonalChannelEvent.bind(
      this
    );
    this.handlePresenceChannelEvent = this.handlePresenceChannelEvent.bind(
      this
    );
    this.unsubscribe = this.unsubscribe.bind(this);
    this.shouldUpdateEditables = this.shouldUpdateEditables.bind(this);
    this.getFingerPrint = this.getFingerPrint.bind(this);

    this.state = {
      companyChannel: null,
      personalChannel: null,
      presenceChannel: null,
      realtime: null,
      refreshMailRealtime: noop,
      fingerprint: null,
    };
  }

  public render() {
    return (
      <>
        <Connecter onConnectionChange={this.onConnectionChange} />
        <EventCenterContextProvider value={this.state.presenceChannel}>
          {this.props.children}
        </EventCenterContextProvider>
      </>
    );
  }

  public shouldComponentUpdate(props: Props) {
    return !equals(props, this.props);
  }

  public componentDidUpdate(prevProps: Props) {
    this.shouldUpdateEditables(prevProps);
  }

  public componentWillUnmount() {
    this.unsubscribe();
    this.setState({
      companyChannel: null,
      personalChannel: null,
      presenceChannel: null,
    });
  }

  private async shouldUpdateEditables(prevProps: Props) {
    if (
      !!this.state.presenceChannel &&
      (this.props.currentEmailId !== prevProps.currentEmailId ||
        !equal(this.props.editables, prevProps.editables))
    ) {
      const fingerprint = await this.getFingerPrint();
      const browserData = bowser.getParser(window.navigator.userAgent);
      const entities = [...this.props.editables];

      if (!!this.props.currentEmailId) {
        entities.push({
          type: RootEntityType.Unknown,
          id: this.props.currentEmailId,
          openedOn: new Date(),
          inEditMode: false,
        });
      }

      const employee: PresentEmployee = {
        employeeId: this.props.employeeId,
        entities,
        fingerprint,
        guid: this.props.guid,
        appVersion: this.props.version,
        browser: browserData.getBrowserName(),
      };

      this.state.presenceChannel.presence.update(employee);
    }
  }

  private async getFingerPrint() {
    if (!!this.state.fingerprint) return this.state.fingerprint;

    const promise = new Promise<string>((resolve, reject) => {
      try {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const fingerprint: string = Fingerprint2.x64hash128(
            values.join(""),
            31
          );

          resolve(fingerprint);
        });
      } catch (error) {
        reject(error);
      }
    });

    return await promise;
  }

  private onConnectionChange(
    eventCenterChannels: {
      companyChannel: string;
      personalChannel: string;
      presenceChannel: string;
    },
    realtime: Ably.Realtime | null,
    refreshMailRealtime: () => void
  ) {
    this.unsubscribe();

    if (!realtime) {
      this.setState({
        companyChannel: null,
        personalChannel: null,
        presenceChannel: null,
        realtime: null,
      });
      return;
    }

    let companyChannel = null;
    let personalChannel = null;
    let presenceChannel = null;

    if (!!eventCenterChannels.companyChannel) {
      const realtimeChannel = realtime.channels.get(
        eventCenterChannels.companyChannel
      );

      realtimeChannel.subscribe((response) => {
        const data: AnnouncementMessage = isString(response.data)
          ? JSON.parse(response.data)
          : response.data;

        this.handleCompanyChannelEvent(data);
      });

      companyChannel = realtimeChannel;
    }

    if (!!eventCenterChannels.personalChannel) {
      const realtimeChannel = realtime.channels.get(
        eventCenterChannels.personalChannel
      );

      realtimeChannel.subscribe((response) => {
        const data: AnnouncementMessage = isString(response.data)
          ? JSON.parse(response.data)
          : response.data;

        this.handlePersonalChannelEvent(data);
      });

      personalChannel = realtimeChannel;
    }

    if (!!eventCenterChannels.presenceChannel) {
      const realtimeChannel = realtime.channels.get(
        eventCenterChannels.presenceChannel
      );

      realtimeChannel.presence.subscribe(this.handlePresenceChannelEvent);

      realtimeChannel.attach(async (err) => {
        if (err) throw err;

        const fingerprint = await this.getFingerPrint();
        const browserData = bowser.getParser(window.navigator.userAgent);
        const entities = [...this.props.editables];

        if (!!this.props.currentEmailId) {
          entities.push({
            type: RootEntityType.Unknown,
            id: this.props.currentEmailId,
            openedOn: new Date(),
            inEditMode: false,
          });
        }

        const employee: PresentEmployee = {
          employeeId: this.props.employeeId,
          entities,
          fingerprint,
          guid: this.props.guid,
          appVersion: this.props.version,
          browser: browserData.getBrowserName(),
        };

        realtimeChannel.presence.enter(employee, (err) => {
          if (err) throw err;
          realtimeChannel.presence.get((err, members) => {
            if (err) throw err;
            // HAYWORKV4-7280
            // const employees = (members || []).filter(
            //   (member) => member.data.employeeId !== this.props.employeeId
            // );
            members.map((employee) => this.props.enterPresence(employee.data));
          });
        });
      });

      presenceChannel = realtimeChannel;
    }

    this.setState({
      companyChannel,
      personalChannel,
      presenceChannel,
      realtime,
      refreshMailRealtime,
    });
  }

  private handleCompanyChannelEvent(data: AnnouncementMessage) {
    switch (data.category) {
      case AnnouncementCategory.Notification: {
        const details = data.notificationDetails;
        this.props.showNotificationToast(details);
        if (FeatureHelper.executeBlock(this.props.features, "EVENTCENTER_V2")) {
          this.props.getStatistics();
        } else {
          this.props.getUnreadCount();
        }
        return;
      }
      case AnnouncementCategory.Entity: {
        const details = data.entityDetails;
        this.props.handleRootEntityChange(details);
        return;
      }
      default:
        return;
    }
  }

  private handlePersonalChannelEvent(data: AnnouncementMessage) {
    switch (data.category) {
      case AnnouncementCategory.Notification:
        const details = data.notificationDetails;
        this.props.showNotificationToast(details);
        if (FeatureHelper.executeBlock(this.props.features, "EVENTCENTER_V2")) {
          this.props.getStatistics();
        } else {
          this.props.getUnreadCount();
        }

        switch (details.event) {
          case NotificationEvent.AgendaItemReminder:
          case NotificationEvent.TaskReminder:
            this.props.getReminders();
            return;
          default:
            return;
        }
      case AnnouncementCategory.EmailPersonalChange: {
        if (!!this.state.refreshMailRealtime) this.state.refreshMailRealtime();
        this.props.getEmailAccounts();
        return;
      }
      case AnnouncementCategory.EventCenterChange: {
        this.props.handleEventCenterChangeDetails(
          data.eventCenterChangeDetails
        );
        return;
      }
      default:
        return;
    }
  }

  private handlePresenceChannelEvent(message: Ably.Types.PresenceMessage) {
    const { action, data } = message;
    const { employeeId, guid } = data;

    switch (action) {
      case "enter": {
        this.props.enterPresence(data);
        return;
      }
      case "update": {
        this.props.updatePresence(data);
        return;
      }
      case "leave": {
        this.props.removePresence(employeeId, guid);
        return;
      }
      default: {
        return;
      }
    }
  }

  private unsubscribe() {
    const { companyChannel, personalChannel, presenceChannel } = this.state;
    if (!!companyChannel) companyChannel.unsubscribe();
    if (!!personalChannel) personalChannel.unsubscribe();
    if (!!presenceChannel) presenceChannel.unsubscribe();
  }
}
