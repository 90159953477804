import { RealtorSuggestionItem } from "@haywork/api/mls";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import {
  Input,
  QueryOptionReturnValue,
  QueryResultStringReturnValue,
} from "@haywork/modules/form";
import { StringUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { RealtorContainerProps } from "./realtor.container";

const styles = require("./style.scss");

export type RealtorComponentProps = {
  realtor: RealtorSuggestionItem;
  index: string;
  onChange: (realtor: RealtorSuggestionItem, id: string) => void;
  onClear: (id: string) => void;
};
type Props = RealtorComponentProps & RealtorContainerProps;

export const RealtorComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ realtor, index, onChange, querySuggestions, onClear }) => {
      const [realtorValue, setRealtorValue] = useState(realtor);
      const inited = useRef<boolean>(false);

      const updateRealtor = useCallback(
        (realtor: RealtorSuggestionItem) => {
          setRealtorValue(realtor);
        },
        [setRealtorValue]
      );

      const renderQueryBaseStringOption = useCallback(
        (
          value: RealtorSuggestionItem,
          query: string
        ): QueryOptionReturnValue => {
          return (
            <div
              dangerouslySetInnerHTML={StringUtil.highlight(
                value.label || "",
                query
              )}
            />
          );
        },
        []
      );

      const renderQueryBaseStringValue = useCallback(
        (value: RealtorSuggestionItem): QueryResultStringReturnValue<any> => {
          return {
            value,
            resultString: value.label || "",
          };
        },
        []
      );

      const onClearCallback = useCallback(() => {
        onClear(index);
      }, [onClear, index]);

      useEffect(() => {
        if (!inited.current) {
          inited.current = true;
          return;
        }

        onChange(realtorValue, index);
      }, [realtorValue, index, onChange]);

      return (
        <div styleName="row">
          <div styleName="query">
            <Input.Query
              name={`realtor-filter.${index}`}
              asyncValues={querySuggestions}
              optionValue={renderQueryBaseStringOption}
              selectedStringValue={renderQueryBaseStringValue}
              asSingleInput
              onChange={updateRealtor}
              value={realtorValue}
              placeholder="mls.realtor.filter.placeholder"
            />
          </div>
          {!!realtor && (
            <div styleName="clear">
              <div styleName="clear__trigger" onClick={onClearCallback}>
                <Icon name="times" light color={Colors.Gray} />
              </div>
            </div>
          )}
        </div>
      );
    }
  )
);
