import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

const styles = require("./project-form.component.scss");

interface Props {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectFormComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { projectAssignment } = this.props;

    this.formControls = {
      displayName: { value: value(projectAssignment, "displayName") },
      webAddress: { value: value(projectAssignment, "webAddress") }
    };

    this.onDataChangeHandler = this.onDataChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;

      this.formRef.update({
        displayName: value(projectAssignment, "displayName"),
        webAddress: value(projectAssignment, "webAddress")
      }, true);
    }
  }

  public render() {
    return (
      <div styleName="project-form" data-cy="CY-projectProjectContainer">
        <Form
          name="project-form"
          onChange={this.onDataChangeHandler}
          formControls={this.formControls}
          form={(form) => (this.formRef = form)}
        >
          <div className="form__row">
            <label htmlFor="displayName">
              <ResourceText resourceKey="projectDisplayName" />
            </label>
            <Input.Text
              name="displayName"
              data-cy="CY-projectDisplayNameInput"
            />
          </div>

          <div className="form__row">
            <div className="column data-webaddress">
              <label htmlFor="webAddress">
                <ResourceText resourceKey="projectWebAddress" />
              </label>
              <div className="input__helper">
                <div className="pre">
                  <i className="fal fa-globe" />
                </div>
                <Input.Url
                  name="webAddress"
                  placeholder="webAddressPlaceholder"
                  data-cy="CY-projectWebsiteInput"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onDataChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id
    });

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      displayName: values.displayName,
      webAddress: values.webAddress
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, pathname);
  }
}
