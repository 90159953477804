export const LAYOUT = {
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  LIGHTBOX_SHOW: "LIGHTBOX_SHOW",
  LIGHTBOX_NEXT: "LIGHTBOX_NEXT",
  LIGHTBOX_PREVIOUS: "LIGHTBOX_PREVIOUS",
  LIGHTBOX_SET_SLIDE: "LIGHTBOX_SET_SLIDE",
  LIGHTBOX_HIDE: "LIGHTBOX_HIDE",
  CONFIRM_SHOW: "CONFIRM_SHOW",
  CONFIRM_HIDE: "CONFIRM_HIDE",
  TOGGLE_ONBOARDING: "LAYOUT_TOGGLE_ONBOARDING",
  OPEN_WINDOW_FRAME: "OPEN_WINDOW_FRAME",
  CLOSE_WINDOW_FRAME: "CLOSE_WINDOW_FRAME",
  CLOSE_SIDEBAR_MOBILE: "CLOSE_SIDEBAR_MOBILE",
  SET_CREATE_LOADER_VISIBILITY: "SET_CREATE_LOADER_VISIBILITY",
  SET_BLOB_ERROR: "COMMON.SET_BLOB_ERROR"
};
