import { REDUX } from "@haywork/constants";

import * as ActionType from "./drafts.types";
import { ExtendedEmailDraft } from "@haywork/util/email";

const appendDrafts = (payload: ActionType.DraftsPayload) => ({
  type: REDUX.EMAILV2.APPEND_DRAFTS,
  ...payload
});

const deleteDraft = (id: string) => ({
  type: REDUX.EMAILV2.DELETE_DRAFT,
  id
});

const setDraft = (draft: ExtendedEmailDraft) => ({
  type: REDUX.EMAILV2.SET_DRAFT,
  draft
});

const setDraftStatus = (currentDraftStatus: string) => ({
  type: REDUX.EMAILV2.SET_DRAFT_STATUS,
  currentDraftStatus
});

const updateTemporaryId = (temporaryId: string) => ({
  type: REDUX.EMAILV2.UPDATE_TEMPORARY_ID,
  temporaryId
});

export const DraftsActions = {
  appendDrafts,
  deleteDraft,
  setDraft,
  setDraftStatus,
  updateTemporaryId
};
