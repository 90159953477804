import { SortOrder } from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import {
  Group,
  RealEstatePropertySearchItem,
  SearchLocation,
} from "@haywork/api/mls";

export enum ActionType {
  UpdateList = "MLS.List.UpdateList",
  SetOrdering = "MLS.List.SetOrdering",
  SetFilters = "MLS.List.SetFilters",
  SetScrollOffset = "MLS.List.SetScrollOffset",
  SetSelectedIds = "MLS.List.SetSelectedIds",
  SetRealEstateAgencyGroups = "MLS.List.SetRealEstateAgencyGroups",
  SetRealEstateAgencyAppClients = "MLS.List.SetRealEstateAgencyAppClients",
  ResetApp = "RESET_APP",
  UpdateItem = "MLS.List.UpdateItem",
  SetDefaultSearchLocations = "MLS.List.SetDefaultSearchLocations",
}

type UpdateList = {
  type: ActionType.UpdateList;
  startIndex: number;
  stopIndex: number;
  items: RealEstatePropertySearchItem[];
  totalCount: number;
};

const updateList = (
  startIndex: number,
  items: RealEstatePropertySearchItem[],
  totalCount: number
) => ({
  type: ActionType.UpdateList,
  startIndex,
  items,
  totalCount,
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: any;
};

const setOrdering = (sortOrder: SortOrder, sortColumn: any) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn,
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters,
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset,
});

type SetSelectedIds = {
  type: ActionType.SetSelectedIds;
  selectedIds: string[];
};

const setSelectedIds = (selectedIds: string[]) => ({
  type: ActionType.SetSelectedIds,
  selectedIds,
});

type SetRealEstateAgencyGroups = {
  type: ActionType.SetRealEstateAgencyGroups;
  agencyGroups: Group[];
};

const setRealEstateAgencyGroups = (agencyGroups: Group[]) => ({
  type: ActionType.SetRealEstateAgencyGroups,
  agencyGroups,
});

type SetRealEstateAgencyAppClients = {
  type: ActionType.SetRealEstateAgencyAppClients;
  appClients: string[];
};

const setRealEstateAgencyAppClients = (appClients: string[]) => ({
  type: ActionType.SetRealEstateAgencyAppClients,
  appClients,
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: RealEstatePropertySearchItem;
};

const updateItem = (snapshot: RealEstatePropertySearchItem) => ({
  type: ActionType.UpdateItem,
  snapshot,
});

type SetDefaultSearchLocations = {
  type: ActionType.SetDefaultSearchLocations;
  locations: SearchLocation[];
};

const setDefaultSearchLocations = (locations: SearchLocation[]) => ({
  type: ActionType.SetDefaultSearchLocations,
  locations,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | SetSelectedIds
  | SetRealEstateAgencyGroups
  | SetRealEstateAgencyAppClients
  | ResetApp
  | UpdateItem
  | SetDefaultSearchLocations;
export const actions = {
  updateList,
  setOrdering,
  setFilters,
  setScrollOffset,
  setSelectedIds,
  setRealEstateAgencyGroups,
  setRealEstateAgencyAppClients,
  updateItem,
  setDefaultSearchLocations,
};
