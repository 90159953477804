import { MonthStats } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";

const styles = require("./negotiating-position-widget.component.scss");

interface Props {
  statisticsThisMonth: MonthStats;
  statisticsLastMonth: MonthStats;
  state: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DashboardNegotiatingPositionWidgetComponent extends React.Component<Props> {
  public render() {
    let thisMonthNegotiationPosition = 0;
    let lastMonthNegotiationPosition = 0;
    if (this.props.statisticsThisMonth && this.props.statisticsLastMonth) {
      if (
        this.props.statisticsThisMonth.sumSaleRealisedPrice &&
        this.props.statisticsThisMonth.sumSaleAskingPrice
      ) {
        thisMonthNegotiationPosition =
          (this.props.statisticsThisMonth.sumSaleRealisedPrice -
            this.props.statisticsThisMonth.sumSaleAskingPrice) /
          this.props.statisticsThisMonth.sumSaleAskingPrice;
        thisMonthNegotiationPosition = thisMonthNegotiationPosition * 100;
      }
      if (
        this.props.statisticsLastMonth.sumSaleRealisedPrice &&
        this.props.statisticsLastMonth.sumSaleAskingPrice
      ) {
        lastMonthNegotiationPosition =
          (this.props.statisticsLastMonth.sumSaleRealisedPrice -
            this.props.statisticsLastMonth.sumSaleAskingPrice) /
          this.props.statisticsLastMonth.sumSaleAskingPrice;
        lastMonthNegotiationPosition = lastMonthNegotiationPosition * 100;
      }
    }

    const contentSpanClass = classNames("content", {
      negative: thisMonthNegotiationPosition < 0,
      positive: thisMonthNegotiationPosition > 0,
      neutral: thisMonthNegotiationPosition === 0,
    });
    const footerSpanClass = classNames("result", {
      negative: lastMonthNegotiationPosition < 0,
      positive: lastMonthNegotiationPosition > 0,
      neutral: lastMonthNegotiationPosition === 0,
    });

    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetNegotiatingPosition">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-handshake" />
            </span>
            <span className="text">
              <ResourceText resourceKey="negotiationPositionHowWeNegotiate" />
            </span>
          </header>

          {this.props.state === REQUEST.PENDING && (
            <div className="content loading">
              <PageLoader loading />
            </div>
          )}

          {this.props.state === REQUEST.SUCCESS && (
            <div className={contentSpanClass} styleName="content">
              {thisMonthNegotiationPosition === 0 ? (
                <span className="mainValue">-</span>
              ) : (
                <div styleName="main-value">
                  <div styleName="amount">
                    <FormattedNumber
                      value={thisMonthNegotiationPosition}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    %
                  </div>
                </div>
              )}
            </div>
          )}
          <footer>
            <ResourceText resourceKey="negotiationPositionLastMonth" />{" "}
            <span className={footerSpanClass}>
              {lastMonthNegotiationPosition === 0 ? (
                <ResourceText resourceKey="negotiationPositionNoPercentage" />
              ) : (
                <span>
                  <FormattedNumber
                    value={lastMonthNegotiationPosition}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                  %
                </span>
              )}
            </span>
          </footer>
        </article>
      </div>
    );
  }
}
