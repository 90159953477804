import { WidgetEntityType } from "@haywork/api/authorization";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ProjectDetailDossierContainerProps } from "@haywork/modules/project/containers/detail-dossier.container";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FolderTreeEntityType } from "@haywork/middleware";

const styles = require("./detail-dossier.component.scss");

export interface ProjectDetailDossierComponentProps {}
interface State {}
type Props = ProjectDetailDossierComponentProps &
  ProjectDetailDossierContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailDossierComponent extends React.Component<
  Props,
  State
> {
  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.projectId,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    return (
      <ErrorBoundary>
        <Dossier
          parentId={this.props.projectId}
          id={this.props.linkedFolderTreeId}
          subject={this.props.displayName}
          entityType={FolderTreeEntityType.ProjectAssignment}
          blocked={this.props.isInitial}
          addNotes={true}
        />
      </ErrorBoundary>
    );
  }
}
