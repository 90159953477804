import { AgendaItemSnapShot } from "@haywork/api/kolibri";
import { MAINROUTES, REQUEST, SCHEDULERROUTES } from "@haywork/constants";
import { SchedulerWidgetContainerProps } from "@haywork/modules/dashboard/containers/widgets/scheduler-widget.container";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { ColorUtil, RouteUtil } from "@haywork/util";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const styles = require("./scheduler-widget.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export type DashboardSchedulerWidgetComponentProps = {};
type Props = DashboardSchedulerWidgetComponentProps &
  SchedulerWidgetContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DashboardSchedulerWidgetComponent extends React.Component<Props> {
  public componentDidMount() {
    this.props.getAgendaItemsForToday(true);
  }

  public render() {
    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetScheduler">
          <header>
            <span className="dayIcon">
              <i className="fal fa-fw fa-calendar" />
              <span className="day">{moment().format("D")}</span>
            </span>
            <span className="text">
              <ResourceText resourceKey="agendaWidgetTitle" />
            </span>
            <span className="date">{moment().format("D-M-Y")}</span>
          </header>

          {this.props.schedulerWidgetState === REQUEST.PENDING && (
            <div className="content loading">
              <PageLoader loading />
            </div>
          )}
          {this.props.schedulerWidgetState === REQUEST.SUCCESS &&
            this.props.agendaItems.length === 0 &&
            this.renderEmptyState()}
          {this.props.schedulerWidgetState === REQUEST.SUCCESS &&
            this.props.agendaItems.length > 0 &&
            this.renderAppointments()}
          <footer>
            <Link
              to={MAINROUTES.SCHEDULER.URI}
              className="btn btn-link btn-link-primary"
            >
              <ResourceText resourceKey="agendaWidgetMoreForToday" />
            </Link>
          </footer>
        </article>
      </div>
    );
  }

  private renderEmptyState(): JSX.Element {
    return (
      <div className="content emptyState" styleName="contentScheduler">
        <span>
          <ResourceText resourceKey="agendaWidgetNoAppointmentsForToday" />
        </span>
      </div>
    );
  }

  private renderAppointments(): JSX.Element {
    if (this.props.agendaItems.length === 0) return;

    let firstAppointment: AgendaItemSnapShot;
    let otherAppointments: AgendaItemSnapShot[] = [];
    if (this.props.agendaItems.length > 0) {
      firstAppointment = this.props.agendaItems[0];
    }
    if (this.props.agendaItems.length > 1) {
      otherAppointments = [...this.props.agendaItems];
      otherAppointments.shift();
    }

    return (
      <div className="content" styleName="contentScheduler">
        <span className="itemHeader">
          <ResourceText resourceKey="agendaWidgetYourNextAppointment" />
        </span>
        <div
          styleName="item"
          onClick={() => {
            this.props.navigate(
              route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
                id: firstAppointment.id
              })
            );
          }}
        >
          <div
            styleName="duration"
            style={{
              borderColor: firstAppointment.linkedAgendaItemCategory
                ? ColorUtil.hexToRgb(
                    firstAppointment.linkedAgendaItemCategory.backColor
                  )
                : ""
            }}
          >
            {firstAppointment.allDayEvent ? (
              <span styleName="start">
                <ResourceText resourceKey="allDay" />
              </span>
            ) : (
              <div>
                <span styleName="start">
                  {moment(firstAppointment.startDateTime).format("HH:mm")}
                </span>
                <span styleName="end">
                  {moment(firstAppointment.endDateTime).format("HH:mm")}
                </span>
              </div>
            )}
          </div>
          <div styleName="text">
            <span styleName="subject">{firstAppointment.subject}</span>
            <span styleName="location">{firstAppointment.location}</span>
          </div>
          {firstAppointment.isPrivate ? (
            <div styleName="personal">
              <i className="fal fa-fw fa-lock-alt" />
            </div>
          ) : (
            ""
          )}
        </div>
        {otherAppointments.length > 0 && (
          <span className="itemHeader">
            <ResourceText resourceKey="agendaWidgetAfter" />
          </span>
        )}
        {otherAppointments.map((appointment) => {
          return (
            <div
              styleName="item"
              key={appointment.id}
              onClick={() => {
                this.props.navigate(
                  route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
                    id: appointment.id
                  })
                );
              }}
            >
              <div
                styleName="duration"
                style={{
                  borderColor: appointment.linkedAgendaItemCategory
                    ? ColorUtil.hexToRgb(
                        appointment.linkedAgendaItemCategory.backColor
                      )
                    : ""
                }}
              >
                {appointment.allDayEvent ? (
                  <span styleName="start">
                    <ResourceText resourceKey="allDay" />
                  </span>
                ) : (
                  <div>
                    <span styleName="start">
                      {moment(appointment.startDateTime).format("HH:mm")}
                    </span>
                    <span styleName="end">
                      {moment(appointment.endDateTime).format("HH:mm")}
                    </span>
                  </div>
                )}
              </div>
              <div styleName="text">
                <span styleName="subject">{appointment.subject}</span>
                <span styleName="location">{appointment.location}</span>
              </div>
              {appointment.isPrivate ? (
                <div styleName="personal">
                  <i className="fal fa-fw fa-lock-alt" />
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
