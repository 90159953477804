import { Account, FolderCategory } from "@haywork/api/mail";
import {
  Dispatch,
  EmailDraftThunks,
  EmailMessageThunks
} from "@haywork/middleware";
import {
  EmailListComponent,
  EmailListComponentProps
} from "@haywork/modules/email";
import { AppState, PagedResults } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailDraft, ExtendedEmailFolder } from "@haywork/util/email";
import reverse from "lodash-es/reverse";
import sortBy from "lodash-es/sortBy";
import { connect } from "react-redux";

interface StateProps {
  currentAccount: Account;
  accounts: PagedResults<Account>;
  messages: EmailMessage[];
  drafts: ExtendedEmailDraft[];
  currentMessage: EmailMessage;
  unread: boolean;
  currentDraft: ExtendedEmailDraft;
  currentFolder: ExtendedEmailFolder;
  term: string;
  currentFolderIsTrash: boolean;
}
interface DispatchProps {
  getMessages: () => void;
  readMessage: (
    account: Account,
    folder: ExtendedEmailFolder,
    message: EmailMessage,
    setRead: boolean
  ) => void;
  toggleUnread: (accountId: string, message: EmailMessage) => void;
  toggleBookmarked: (
    accountId: string,
    id: string,
    bookmarked: boolean
  ) => void;
  readDraft: (id: string) => void;
  searchMessages: (term: string, folder: ExtendedEmailFolder) => void;
  filterMessages: (unread: boolean | undefined) => void;
  deleteMessage: (accountId: string, message: EmailMessage) => void;
  getDrafts: () => void;
}

const mapStateToProps = <StateProps, EmailListComponentProps>(
  state: AppState
) => {
  const { currentAccount, accounts } = state.email.accounts;
  const { currentFolder } = state.email.folders;
  const {
    messages: unfilteredMessages,
    currentMessage,
    searchFilters
  } = state.email.messages;
  const { drafts: unfilteredDrafts, currentDraft } = state.email.drafts;
  const { unread, term } = searchFilters;

  const messages = reverse(
    sortBy(
      unfilteredMessages.filter(
        (message) =>
          message.folderId === currentFolder.id &&
          message.accountId === currentAccount.id
      ),
      ["date"]
    )
  );
  const drafts = reverse(
    sortBy(
      unfilteredDrafts.filter(
        (draft) =>
          draft.folderId === currentFolder.id &&
          draft.accountId === currentAccount.id
      ),
      ["date"]
    )
  );

  return {
    currentAccount,
    accounts,
    messages,
    drafts,
    currentMessage,
    unread,
    currentDraft,
    currentFolder,
    term,
    currentFolderIsTrash:
      !!currentFolder && currentFolder.category === FolderCategory.Trash
  };
};

const mapDispatchToProps = <DispatchProps, EmailListComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getMessages: () => dispatch(EmailMessageThunks.getMessages()),
  readMessage: (
    account: Account,
    folder: ExtendedEmailFolder,
    message: EmailMessage,
    setRead: boolean
  ) =>
    dispatch(EmailMessageThunks.readMessage(account, folder, message, setRead)),
  toggleUnread: (accountId: string, message: EmailMessage) =>
    dispatch(EmailMessageThunks.toggleUnread(accountId, message)),
  toggleBookmarked: (accountId: string, id: string, bookmarked: boolean) =>
    dispatch(EmailMessageThunks.toggleBookmarked(accountId, id, bookmarked)),
  readDraft: (id: string) => dispatch(EmailDraftThunks.readDraft(id)),
  searchMessages: (term: string, folder: ExtendedEmailFolder) =>
    dispatch(EmailMessageThunks.searchMessages(term, folder)),
  filterMessages: (unread: boolean | undefined) =>
    dispatch(EmailMessageThunks.filterMessages(unread)),
  deleteMessage: (accountId: string, message: EmailMessage) =>
    dispatch(EmailMessageThunks.deleteMessage(accountId, message)),
  getDrafts: () => dispatch(EmailDraftThunks.getDrafts())
});

export type EmailListContainerProps = StateProps & DispatchProps;
export const EmailListContainer = connect<
  StateProps,
  DispatchProps,
  EmailListComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailListComponent);
