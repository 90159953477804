import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";
import Icon from "../icon";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";

const styles = require("./style.scss");
const THRESHOLD = 128;

type Props = {
  label: string;
  labelValues?: Record<string, string>;
};

export const CollapsableTextComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, label, labelValues }) => {
      const [collapsed, setCollapsed] = useState(true);
      const [canToggle, setCanToggle] = useState(false);

      const onClickCallback = useCallback(() => {
        if (!canToggle) return;
        setCollapsed((collapsed) => !collapsed);
      }, [canToggle, setCollapsed]);

      const refCallback = useCallback(
        (ref: HTMLDivElement) => {
          if (!ref) return;
          const { height } = ref.getBoundingClientRect();
          if (height > THRESHOLD) {
            setCanToggle(true);
          }
        },
        [setCanToggle]
      );

      return (
        <div>
          <div onClick={onClickCallback} styleName="title">
            <div styleName="label">
              <I18n value={label} values={labelValues} />
            </div>
          </div>
          <div
            styleName={classNames("wrapper", {
              collapsed,
              "can-toggle": canToggle,
            })}
            style={{ maxHeight: THRESHOLD }}
          >
            <div ref={refCallback} styleName="text">
              {children}
            </div>
            {!!canToggle && (
              <div
                styleName={classNames("trigger", { collapsed })}
                onClick={onClickCallback}
              >
                <I18n value={collapsed ? "readMore" : "readLess"} />
                <Icon
                  name={collapsed ? "chevron-down" : "chevron-up"}
                  color={Colors.Primary}
                  size={10}
                  regular
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
