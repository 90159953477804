import { ActionType, ActionTypes } from "./actions";
import { SearchLocation } from "@haywork/api/mls";

export type State = {
  searchLocations: SearchLocation[];
};

const INITIAL: State = {
  searchLocations: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetSearchLocations: {
      const { searchLocations } = action;

      return {
        ...state,
        searchLocations,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
