import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as deepEqual from "deep-equal";

import {
  Form,
  FormReturnValue,
  FormControls,
  FormReference,
  Input,
  QueryResultReturnValue,
  QueryOptionReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import escapeRegExp from "lodash-es/escapeRegExp";
import { Ui } from "@haywork/modules/ui";
import {
  ObjectAssignment,
  BuyerTypeOption,
  MovingReasonOption,
  HouseholdCompositionOption,
  AgeRangeOption,
  FamilyIncomeOption,
  ChildAgeRangeOption,
  TransactionMetaData,
} from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import { TransactionMetaDataResponse } from "@haywork/middleware";

const styles = require("../../transaction-modal.component.scss");

interface Props {
  assignment: ObjectAssignment;
  transactionMetaData: Partial<TransactionMetaData>;
  buyerTypeOptions: BuyerTypeOption[];
  movingReasonOptions: MovingReasonOption[];
  householdCompositionOptions: HouseholdCompositionOption[];
  ageRangeOptions: AgeRangeOption[];
  childAgeRangeOptions: ChildAgeRangeOption[];
  familyIncomeOptions: FamilyIncomeOption[];
  onUpdate: (data: TransactionMetaDataResponse) => void;
}
interface State {
  typeOfHabitation: any;
}

@CSSModules(styles, { allowMultiple: true })
export class PersonalForm extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    let movingReasons = [];
    if (has(this.props.transactionMetaData, "movingReasons")) {
      movingReasons = this.props.transactionMetaData.movingReasons
        .map((reason) => {
          return this.props.movingReasonOptions.find(
            (option) => option.value === reason
          );
        })
        .filter((option) => !!option);
    }

    this.formControls = {
      buyerType: {
        value: get(this.props.transactionMetaData, "buyerType", ""),
      },
      isResident: {
        value: get(this.props.transactionMetaData, "isResident", true),
      },
      movingReasons: { value: movingReasons },
      householdComposition: {
        value: get(this.props.transactionMetaData, "householdComposition", ""),
      },
      numberOfHouseholdMembers: {
        value: get(
          this.props.transactionMetaData,
          "numberOfHouseholdMembers",
          ""
        ),
      },
      ageMainMoneyMaker: {
        value: get(this.props.transactionMetaData, "ageMainMoneyMaker", ""),
      },
      ageOldestChild: {
        value: get(this.props.transactionMetaData, "ageOldestChild", ""),
      },
      familyIncome: {
        value: get(this.props.transactionMetaData, "familyIncome", ""),
      },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.movingReasonsQueryMatchOn = this.movingReasonsQueryMatchOn.bind(this);
    this.renderSelectedMovingReasonsValue =
      this.renderSelectedMovingReasonsValue.bind(this);
    this.renderQueryBaseStringOption =
      this.renderQueryBaseStringOption.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !deepEqual(nextProps.transactionMetaData, this.props.transactionMetaData)
    ) {
      let movingReasons = [];
      if (has(nextProps.transactionMetaData, "movingReasons")) {
        movingReasons = nextProps.transactionMetaData.movingReasons
          .map((reason) => {
            return nextProps.movingReasonOptions.find(
              (option) => option.value === reason
            );
          })
          .filter((option) => !!option);
      }

      this.formRef.update({
        buyerType: get(nextProps.transactionMetaData, "buyerType", ""),
        movingReasons,
        householdComposition: get(
          nextProps.transactionMetaData,
          "householdComposition",
          ""
        ),
        numberOfHouseholdMembers: get(
          nextProps.transactionMetaData,
          "numberOfHouseholdMembers",
          ""
        ),
        ageMainMoneyMaker: get(
          nextProps.transactionMetaData,
          "ageMainMoneyMaker",
          ""
        ),
        ageOldestChild: get(
          nextProps.transactionMetaData,
          "ageOldestChild",
          ""
        ),
        familyIncome: get(nextProps.transactionMetaData, "familyIncome", ""),
      });
    }
  }

  public render() {
    const { forSale } = this.props.assignment;

    return (
      <Form
        name="transaction-personal"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChangeHandler}
      >
        <div styleName="inner">
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="buyerType">
                  <ResourceText
                    resourceKey={
                      forSale
                        ? "saleTransaction.buyerType"
                        : "rentTransaction.buyerType"
                    }
                  />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.NewSelect
                  name="buyerType"
                  values={this.props.buyerTypeOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.buyerTypeDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="isResident">
                  <ResourceText resourceKey="saleTransaction.isResident" />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.Switch name="isResident" on={true} off={false} />
              </div>
            </div>
          </div>

          <div className="form__row">
            <label htmlFor="movingReasons">
              <ResourceText resourceKey="saleTransaction.movingReasons" />
              <Ui.RequiredForPublish hideHint={true} />
            </label>
            <Input.Query
              name="movingReasons"
              values={this.props.movingReasonOptions}
              multiple={true}
              matchOn={this.movingReasonsQueryMatchOn}
              selectedValue={this.renderSelectedMovingReasonsValue}
              optionValue={this.renderQueryBaseStringOption}
              placeholder="saleTransaction.movingReasonsPlaceholder"
            />
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              <div className="column important">
                <label htmlFor="householdComposition">
                  <ResourceText resourceKey="saleTransaction.householdComposition" />
                </label>
                <Input.NewSelect
                  name="householdComposition"
                  values={this.props.householdCompositionOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.householdCompositionDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="numberOfHouseholdMembers">
                  <ResourceText resourceKey="saleTransaction.numberOfHouseholdMembers" />
                </label>
                <Input.Number name="numberOfHouseholdMembers" />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              <div className="column">
                <label htmlFor="ageMainMoneyMaker">
                  <ResourceText resourceKey="saleTransaction.ageMainMoneyMaker" />
                </label>
                <Input.NewSelect
                  name="ageMainMoneyMaker"
                  values={this.props.ageRangeOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.ageMainMoneyMakerDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="ageOldestChild">
                  <ResourceText resourceKey="saleTransaction.ageOldestChild" />
                </label>
                <Input.NewSelect
                  name="ageOldestChild"
                  values={this.props.childAgeRangeOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.ageOldestChildDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <label htmlFor="familyIncome">
              <ResourceText resourceKey="saleTransaction.familyIncome" />
            </label>
            <Input.NewSelect
              name="familyIncome"
              values={this.props.familyIncomeOptions}
              addEmptyOption
              emptyOptionLabel="saleTransaction.familyIncomeDefault"
              displayProp="displayName"
              valuesProp="value"
            />
          </div>
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private movingReasonsQueryMatchOn(query: string, value: any): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedMovingReasonsValue(
    value: MovingReasonOption
  ): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private renderQueryBaseStringOption(
    value: MovingReasonOption,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const data: TransactionMetaDataResponse = {
      ...values,
      movingReasons: values.movingReasons.map(
        (movingReason) => movingReason.value
      ),
    };

    this.props.onUpdate(data);
  }
}
