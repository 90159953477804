import { Provider } from "@haywork/api/mail";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");

interface SelectProvidersProps {
  providers: Provider[];
  centered?: boolean;
  onSelectProvider: (provider: Provider) => void;
}

export const SelectProviders = CSSModules(styles, { allowMultiple: true })(
  ({ providers, onSelectProvider, centered }: SelectProvidersProps) => (
    <div
      styleName={classNames("providers", { centered })}
      data-cy="CY-selectProvidersModal"
    >
      {providers.map((provider) => (
        <ErrorBoundary key={provider.id}>
          <SelectProvider provider={provider} onClick={onSelectProvider} />
        </ErrorBoundary>
      ))}
    </div>
  )
);

interface SelectProviderProps {
  provider: Provider;
  onClick: (provider: Provider) => void;
}

const SelectProvider = CSSModules(styles, { allowMultiple: true })(
  ({ provider, onClick }: SelectProviderProps) => (
    <div styleName="provider" onClick={() => onClick(provider)}>
      <div
        id={provider.id}
        styleName="provider__avatar"
        style={
          !!provider.logoUrl
            ? { backgroundImage: `url("${provider.logoUrl}")` }
            : null
        }
      />
      <div styleName="provider__name">{provider.displayName}</div>
    </div>
  )
);
