import * as React from "react";
import * as deepEqual from "deep-equal";

import { ExtendedEventNotification, RouteUtil } from "@haywork/util";
import {
  SCHEDULERROUTES,
  RELATIONROUTES,
  EMPLOYEEROUTES,
  ASSIGNMENTROUTES,
  PROJECTROUTES,
  ACQUISITIONOBJECTROUTES,
  MLSROUTES,
} from "@haywork/constants";
import { LinkedBundle } from "@haywork/api/event-center";

const PLACEHOLDER_PATTERN = /\<\@\@(.*?)\@\@\>/g;
const route = RouteUtil.mapStaticRouteValues;

enum LinkedEntityType {
  AgendaItem = "AgendaItem",
  ContactPersons = "ContactPersons",
  Employees = "Employees",
  ObjectAssignments = "ObjectAssignments",
  AcquisitionObjectAssignments = "AcquisitionObjectAssignments",
  ProjectAssignments = "ProjectAssignments",
  Publications = "Publications",
  Bundle = "Bundle",
  None = "None",
}

interface Props {
  message: ExtendedEventNotification;
  onNavigate: (path: string) => void;
}
interface State {}
interface MatchedEntity {
  id: string;
  displayName: string;
  type: LinkedEntityType;
}

export class TitleComponent extends React.Component<Props, State> {
  public render() {
    return (
      <React.Fragment>
        <span>{this.createEnrichedTitleString()}</span>
      </React.Fragment>
    );
  }

  public shouldComponentUpdate(nextProps: Props) {
    return !deepEqual(nextProps.message, this.props.message);
  }

  private getLinkedEntity(id: string): MatchedEntity {
    const {
      linkedAgendaItems,
      linkedContactPersons,
      linkedEmployees,
      linkedObjectAssignments,
      linkedProjectAssignments,
      linkedPublications,
      linkedAcquisitionObjectAssignments,
      linkedBundles,
    } = this.props.message.linkedEvent;
    let entity;

    entity = (linkedAgendaItems || []).find((item) => item.id === id);
    if (!!entity) return { ...entity, type: LinkedEntityType.AgendaItem };

    entity = (linkedContactPersons || []).find((item) => item.id === id);
    if (!!entity) return { ...entity, type: LinkedEntityType.ContactPersons };

    entity = (linkedEmployees || []).find((item) => item.id === id);
    if (!!entity) return { ...entity, type: LinkedEntityType.Employees };

    entity = (linkedObjectAssignments || []).find((item) => item.id === id);
    if (!!entity)
      return { ...entity, type: LinkedEntityType.ObjectAssignments };

    entity = (linkedAcquisitionObjectAssignments || []).find(
      (item) => item.id === id
    );
    if (!!entity)
      return { ...entity, type: LinkedEntityType.AcquisitionObjectAssignments };

    entity = (linkedProjectAssignments || []).find((item) => item.id === id);
    if (!!entity)
      return { ...entity, type: LinkedEntityType.ProjectAssignments };

    entity = (linkedPublications || []).find((item) => item.id === id);
    if (!!entity) return { ...entity, type: LinkedEntityType.Publications };

    entity = (linkedBundles || []).find((item) => item.id === id);
    if (!!entity) return { ...entity, type: LinkedEntityType.Bundle };

    return { id: null, displayName: null, type: LinkedEntityType.None };
  }

  private createEnrichedTitleString() {
    const message =
      this.props.message.messageTemplate || this.props.message.message;
    const matches = message.match(PLACEHOLDER_PATTERN) || [];
    const slices = [];

    let stop = 0;

    matches.map((match, idx) => {
      const start = message.indexOf(match);
      const id = match.replace("<@@", "").replace("@@>", "");
      const entity = this.getLinkedEntity(id);

      slices.push(message.substring(stop, start));
      slices.push(
        <span
          key={idx}
          className="as-link"
          onClick={() => this.onNavigateHandler(entity)}
        >
          {entity.displayName}
        </span>
      );

      stop = start + match.length;
      if (idx === matches.length - 1) {
        slices.push(message.substring(stop, message.length));
      }
    });

    return slices.length === 0 ? message : slices;
  }

  private onNavigateHandler(entity: MatchedEntity) {
    const { id, type } = entity;

    switch (type) {
      case LinkedEntityType.AgendaItem:
        return this.props.onNavigate(
          route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, { id })
        );
      case LinkedEntityType.ContactPersons:
        return this.props.onNavigate(
          route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
        );
      case LinkedEntityType.Employees:
        return this.props.onNavigate(
          route(EMPLOYEEROUTES.EMPLOYEE.URI, { id })
        );
      case LinkedEntityType.ObjectAssignments:
        return this.props.onNavigate(
          route(ASSIGNMENTROUTES.DETAIL.URI, { id })
        );
      case LinkedEntityType.AcquisitionObjectAssignments:
        return this.props.onNavigate(
          route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id })
        );
      case LinkedEntityType.ProjectAssignments:
        return this.props.onNavigate(route(PROJECTROUTES.DETAIL.URI, { id }));
      case LinkedEntityType.Bundle:
        const linkedBundle = entity as LinkedBundle;
        const { appClientKey: source } = linkedBundle;
        return this.props.onNavigate(
          route(MLSROUTES.DETAIL.URI, { id, source })
        );
      default:
        return;
    }
  }
}
