import {
  ConstructionPeriodOption,
  ObjectAssignment,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Ui } from "@haywork/modules/ui";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  constructionPeriods: ConstructionPeriodOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentBogFeaturesAndFiguresComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    this.formControls = {
      constructionYear: {
        value: value(objectAssignment, "yearOfConstruction.constructionYear"),
        onChange: this.setConstructionPeriod.bind(this),
      },
      constructionPeriod: {
        value: value(objectAssignment, "yearOfConstruction.period"),
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      this.formRef.update(
        {
          constructionYear: value(
            objectAssignment,
            "yearOfConstruction.constructionYear"
          ),
          constructionPeriod: value(
            objectAssignment,
            "yearOfConstruction.period"
          ),
        },
        true
      );
    }
  }

  public render() {
    return (
      <div styleName="features-and-figures">
        <Form
          name="features-figures"
          formControls={this.formControls}
          onChange={this.onChangeHandler}
          form={(form) => (this.formRef = form)}
          asSubForm={this.props.asSubForm}
        >
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="constructionYear">
                  <ResourceText resourceKey="constructionYear" />
                  <Ui.RequiredForPublish message="constructionYearOrPeriodRequired" />
                </label>
                <Input.Number
                  name="constructionYear"
                  max={new Date().getFullYear() + 50}
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="constructionPeriod">
                  <ResourceText resourceKey="constructionPeriod" />
                  <Ui.RequiredForPublish message="constructionYearOrPeriodRequired" />
                </label>
                <Input.NewSelect
                  name="constructionPeriod"
                  values={this.props.constructionPeriods}
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const value = {
      yearOfConstruction: {
        ...objectAssignment.yearOfConstruction,
        constructionYear: values.constructionYear,
        period: values.constructionPeriod,
      },
    };

    this.props.onChange(value);
  }

  private setConstructionPeriod(control: RawFormControl) {
    const { value } = control;
    const year = parseInt(value);

    const period = this.props.constructionPeriods.find(
      (p) => p.minYear <= year && p.maxYear >= year
    );
    if (period) return { constructionPeriod: period.value };
  }
}
