import * as React from "react";
import { FC, memo, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import Sidebar from "../../components/detail-sidebar";
import { AcquisitionDetailTimelineScreenContainerProps } from "./timeline.container";
import Timeline from "@haywork/modules/shared/components/timeline-v2";

const styles = require("./style.scss");

export type AcquisitionDetailTimelineScreenProps = {};
type Props = AcquisitionDetailTimelineScreenProps &
  AcquisitionDetailTimelineScreenContainerProps;

export const AcquisitionDetailTimelineScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ acquisition }) => {
    const { id } = acquisition;

    return (
      <div styleName="timeline">
        <div styleName="sidebar">
          <Sidebar />
        </div>
        <div styleName="body">
          <Timeline
            entityId={id}
            entityType="assignment"
            addNotes={true}
            includeTimelineEventsOfChildItems={true}
          />
        </div>
      </div>
    );
  })
);
