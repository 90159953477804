import { ActiveFilter, InvoiceStatus } from "@haywork/api/kolibri";
import {
  CheckboxFilter,
  Filter,
  MappedCheckboxValue,
} from "@haywork/modules/filter";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { InvoiceFiltering } from "@haywork/stores";
import { FormControlUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./sidebar.component.scss");

interface InvoiceFilterSidebarComponentProps {
  filters: InvoiceFiltering;
  filterByActive: ActiveFilter[];
  invoiceStatuses: MappedCheckboxValue[];
  onFilterChange: (filters: InvoiceFiltering) => void;
}
interface InvoiceFilterSidebarComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class InvoiceFilterSidebarComponent extends React.Component<
  InvoiceFilterSidebarComponentProps,
  InvoiceFilterSidebarComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeFilterHandler = this.onChangeFilterHandler.bind(this);
    this.onDatesChangeHandler = this.onDatesChangeHandler.bind(this);
    this.removeStatusFilter = this.removeStatusFilter.bind(this);

    this.formControls = {
      overdueFrom: { value: value(this.props.filters, "overdueFrom") },
      overdueUntil: { value: value(this.props.filters, "overdueUntil") },
      invoiceDateFrom: { value: value(this.props.filters, "invoiceDateFrom") },
      invoiceDateUntil: {
        value: value(this.props.filters, "invoiceDateUntil"),
      },
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: InvoiceFilterSidebarComponentProps
  ) {
    if (!nextProps) return;

    if (!deepEqual(nextProps.filters, this.props.filters) && !!this.formRef) {
      this.formRef.update({
        overdueFrom: value(nextProps.filters, "overdueFrom"),
        overdueUntil: value(nextProps.filters, "overdueUntil"),
        invoiceDateFrom: value(nextProps.filters, "invoiceDateFrom"),
        invoiceDateUntil: value(nextProps.filters, "invoiceDateUntil"),
      });
    }
  }

  public render() {
    return (
      <div styleName="sidebar">
        <Filter changeFilter={this.onChangeFilterHandler}>
          <CheckboxFilter
            name="filterByActive"
            value={this.props.filterByActive}
            values={[
              {
                label: `invoiceActiveFilter.${ActiveFilter.ActiveOnly}`,
                value: ActiveFilter.ActiveOnly,
              },
              {
                label: `invoiceActiveFilter.${ActiveFilter.InactiveOnly}`,
                value: ActiveFilter.InactiveOnly,
              },
            ]}
            title="invoiceFilterActive"
          />

          <CheckboxFilter
            name="statusFilter"
            value={this.props.filters.statusFilter}
            values={this.props.invoiceStatuses}
            title="invoiceFilterStatus"
            canToggle
            visible={false}
            subtitle={
              this.props.filters.statusFilter &&
              this.props.filters.statusFilter.length > 0
                ? this.renderSelectedItemsSubtitle(
                    this.props.invoiceStatuses,
                    this.props.filters.statusFilter,
                    this.removeStatusFilter
                  )
                : this.renderSubtitle(this.props.invoiceStatuses)
            }
            hideCheckedItems
          />

          <Form
            name="invoice-filtering"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onChange={this.onDatesChangeHandler}
          >
            <div styleName="date-filter">
              <label htmlFor="invoiceDateFrom">
                <ResourceText resourceKey="invoiceDateFilterTitle" />
              </label>
              <div styleName="row">
                <div styleName="column">
                  <label htmlFor="invoiceDateFrom">
                    <ResourceText resourceKey="invoiceDateFromFilterLabel" />
                  </label>
                </div>
                <div styleName="column">
                  <Input.Datepicker name="invoiceDateFrom" />
                </div>
              </div>
              <div styleName="row">
                <div styleName="column">
                  <label htmlFor="invoiceDateUntil">
                    <ResourceText resourceKey="invoiceDateUntilFilterLabel" />
                  </label>
                </div>
                <div styleName="column">
                  <Input.Datepicker name="invoiceDateUntil" />
                </div>
              </div>
            </div>

            <div styleName="date-filter">
              <label htmlFor="overdueFrom">
                <ResourceText resourceKey="invoiceDueDateFilterTitle" />
              </label>
              <div styleName="row">
                <div styleName="column">
                  <label htmlFor="overdueFrom">
                    <ResourceText resourceKey="overdueFromFilterLabel" />
                  </label>
                </div>
                <div styleName="column">
                  <Input.Datepicker name="overdueFrom" />
                </div>
              </div>
              <div styleName="row">
                <div styleName="column">
                  <label htmlFor="overdueUntil">
                    <ResourceText resourceKey="overdueUntilFilterLabel" />
                  </label>
                </div>
                <div styleName="column">
                  <Input.Datepicker name="overdueUntil" />
                </div>
              </div>
            </div>
          </Form>
        </Filter>
      </div>
    );
  }

  private onChangeFilterHandler(values: any) {
    const filters = {
      ...this.props.filters,
      ...values,
    };

    this.props.onFilterChange(filters);
  }

  private onDatesChangeHandler(values: FormReturnValue) {
    const filters = {
      ...this.props.filters,
      ...values,
    };

    this.props.onFilterChange(filters);
  }

  private renderSubtitle(
    values: MappedCheckboxValue[]
  ): React.ReactElement<any>[] {
    const mapped = values.slice(0, 10);
    return mapped.map((val, idx) => (
      <span key={idx}>
        <ResourceText resourceKey={val.label} />
        {idx !== mapped.length - 1 ? ", " : ""}
      </span>
    ));
  }

  private renderSelectedItemsSubtitle(
    values: MappedCheckboxValue[],
    selectedValues: any[],
    removeMethod: (value) => void
  ) {
    const selectedMappedValues = values.filter((mappedValue) => {
      const idx = selectedValues.indexOf(mappedValue.value);
      return idx !== -1;
    });

    return (
      <div className="subtitle__selectedItems" styleName="selectedItems">
        {selectedMappedValues.map((value, index) => (
          <span
            key={value + "_" + index}
            onClick={(event) => {
              event.stopPropagation();
              removeMethod(value.value);
            }}
            className={styles.addedResult}
          >
            <span className={styles.remove}>
              <i className="fal fa-check" />
            </span>
            <ResourceText resourceKey={value.label} />
          </span>
        ))}
      </div>
    );
  }

  private removeStatusFilter(value: InvoiceStatus) {
    const { filters: oldFilters } = this.props;
    const statusFilter = oldFilters.statusFilter.filter(
      (status) => status !== value
    );

    const filters = {
      ...oldFilters,
      statusFilter,
    };

    this.props.onFilterChange(filters);
  }
}
