import { LinkedAssignment, LinkedRelation } from "@haywork/api/kolibri";
import {
  FolderCategory,
  FolderOrderBy,
  FoldersClient,
  FolderUnreadCount,
  SortOrder,
  UploadResponse,
  EmailAddress,
} from "@haywork/api/mail";
import { EMAILROUTES, MAINROUTES } from "@haywork/constants";
import { DraftType } from "@haywork/enum";
import { ApiType, ParseRequest } from "@haywork/services";
import { AppState, EditableActions, EmailActions } from "@haywork/stores";
import { RouteUtil, UploadUtil } from "@haywork/util";
import { ExtendedEmailDraft } from "@haywork/util/email";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import { Dispatch } from "../";
import { push } from "connected-react-router";
import { v4 as uuid } from "uuid";
import { EmailAccountThunks } from "./account.thunk";
import { EmailMessageThunks } from "./message.thunk";
import isString from "lodash-es/isString";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getInitialEmailState = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;

    dispatch(EmailAccountThunks.getPersonSettings());
    dispatch(EmailAccountThunks.getProviders());

    const Folders = new FoldersClient(emailHost);
    const accounts = await dispatch(EmailAccountThunks.getAccounts());

    if (accounts.length === 0) return;

    const currentAccount = first(accounts);
    const { folders } = getState().email.folders;
    const inbox = folders.find(
      (folder) =>
        folder.category === FolderCategory.Inbox &&
        folder.accountId === currentAccount.id
    );
    const currentFolder = inbox || first(folders);

    dispatch(
      EmailActions.Accounts.setCurrentAccount({ account: currentAccount })
    );
    dispatch(EmailActions.Folders.setCurrentFolder({ folder: currentFolder }));
    dispatch(EmailMessageThunks.getMessages(true));

    try {
      const statistics = await Promise.all(
        accounts.map((account) =>
          parseRequest.response(
            Folders.search(
              {
                accountId: account.id,
                skip: 0,
                take: 1,
                orderBy: FolderOrderBy.Default,
                order: SortOrder.Ascending,
                flushCache: false,
                includeStatistics: true,
                folderCategory: FolderCategory.Inbox,
              },
              realEstateAgencyId
            ).then((response) => response.statistics),
            ApiType.Email,
            { displayError: false }
          )
        )
      );

      const counts: FolderUnreadCount[] = statistics.reduce(
        (state, statistic) => {
          const counts = statistic.counts || [];
          return [...state, ...counts];
        },
        []
      );

      dispatch(EmailActions.Folders.setUnreadCount({ counts }));
    } catch (error) {
      throw error;
    }
  };
};

const createNewEmail = (
  emails?: string | string[] | EmailAddress[],
  linkedRelations: LinkedRelation[] = [],
  linkedAssignments: LinkedAssignment[] = [],
  subject?: string,
  toBcc?: boolean
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentAccount } = state.email.accounts;
    const { currentFolder } = state.email.folders;
    const userData = [];
    subject = subject || "";

    linkedAssignments.map((linkedAssignment) => {
      userData.push({
        key: "linkedAssignment",
        value: JSON.stringify(linkedAssignment),
      });
    });

    linkedRelations.map((linkedRelation) => {
      userData.push({
        key: "linkedRelation",
        value: JSON.stringify(linkedRelation),
      });
    });

    if (linkedAssignments.length > 0 && !subject) {
      subject = linkedAssignments
        .map((linkedAssignment) => linkedAssignment.displayName)
        .join(", ");
    }

    const id = uuid();
    const to: EmailAddress[] = [];
    let bcc: EmailAddress[] = [];

    if (isArray(emails)) {
      emails.forEach((email) => {
        if (isString(email)) {
          to.push({ email });
        } else {
          to.push(email);
        }
      });
    }

    if (isString(emails)) {
      to.push({ email: emails });
    }

    if (toBcc && to.length > 1) {
      bcc = [...to];
      to.length = 0;
    }

    const componentState: ExtendedEmailDraft = {
      to,
      bcc,
      files: [],
      isNew: true,
      temporaryId: id,
      accountId: !currentAccount ? null : currentAccount.id,
      folderId: !currentFolder ? null : currentFolder.id,
      hasChanges: false,
      type: DraftType.New,
      userData,
      subject,
      sendRequested: false,
    };
    const path = route(EMAILROUTES.CREATE.URI, { id });

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
        entityId: id,
      })
    );

    dispatch(push(path));
  };
};

const createNewEmailWithAttachments = (attachments: UploadResponse[]) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentAccount } = state.email.accounts;
    const { currentFolder } = state.email.folders;
    const id = uuid();

    const componentState: ExtendedEmailDraft = {
      to: [],
      files: (attachments || []).map((file) =>
        UploadUtil.mapMailUploadResponseToEmailFile(file, true)
      ),
      isNew: true,
      temporaryId: id,
      accountId: currentAccount.id,
      folderId: currentFolder.id,
      hasChanges: false,
      type: DraftType.New,
      subject: "",
      sendRequested: false,
    };
    const path = route(EMAILROUTES.CREATE.URI, { id });

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
        entityId: id,
      })
    );

    dispatch(push(path));
  };
};

const createEmailFromEmailAddress = (email: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentAccount } = state.email.accounts;

    if (!currentAccount) {
      location.href = `mailto:${email}`;
    } else {
      dispatch(createNewEmail(email));
    }
  };
};

export const EmailInitialThunks = {
  getInitialEmailState,
  createNewEmail,
  createNewEmailWithAttachments,
  createEmailFromEmailAddress,
};
