import { FolderStatistics } from "@haywork/api/mail";
import { EmailFolder } from ".";

export enum ActionType {
  SetFolders = "EmailV2.Folders.Set",
  SetAccountFolders = "EmailV2.Folders.SetForAccount",
  AppendFolders = "EmailV2.Folders.Append",
  UpdateFolder = "EmailV2.Folders.UpdateFolder",
  SetFoldersCount = "EmailV2.Folders.SetFoldersCount",
  SetFolderPosition = "EmailV2.Folders.SetFolderPosition",
  SetFolderTotalResults = "EmailV2.Folders.SetFolderTotalResults",
  AddFolder = "EmailV2.Folders.AddFolder",
  DeleteFolder = "EmailV2.Folders.DeleteFolder",
  SetCurrentMessage = "EmailV2.Main.SetCurrentMessage",
  ResetApp = "RESET_APP",
  SetAccountInboxUnreadCount = "EmailV2.Folders.SetAccountInboxUnreadCount",
  Clear = "EmailV2.Main.Clear",
}

type SetFolders = {
  type: ActionType.SetFolders;
  folders: EmailFolder[];
};

const setFolders = (folders: EmailFolder[]) => ({
  type: ActionType.SetFolders,
  folders,
});

type SetAccountFolders = {
  type: ActionType.SetAccountFolders;
  folders: EmailFolder[];
  accountId: string;
};

const setAccountFolders = (folders: EmailFolder[], accountId: string) => ({
  type: ActionType.SetAccountFolders,
  folders,
  accountId,
});

type AppendFolders = {
  type: ActionType.AppendFolders;
  folders: EmailFolder[];
  startIndex: number;
};

const appendFolders = (folders: EmailFolder[], startIndex: number) => ({
  type: ActionType.AppendFolders,
  folders,
  startIndex,
});

type UpdateFolder = {
  type: ActionType.UpdateFolder;
  folder: EmailFolder;
  blockInternalCreatedOn: boolean;
};

const updateFolder = (folder: EmailFolder, blockInternalCreatedOn = false) => ({
  type: ActionType.UpdateFolder,
  folder,
  blockInternalCreatedOn,
});

type SetFoldersCount = {
  type: ActionType.SetFoldersCount;
  statistics: FolderStatistics;
};

const setFoldersCount = (statistics: FolderStatistics) => ({
  type: ActionType.SetFoldersCount,
  statistics,
});

type SetFolderPosition = {
  type: ActionType.SetFolderPosition;
  id: string;
  position: number;
};

const setFolderPosition = (id: string, position: number) => ({
  type: ActionType.SetFolderPosition,
  id,
  position,
});

type SetFolderTotalResults = {
  type: ActionType.SetFolderTotalResults;
  id: string;
  totalResults: number;
};

const setFolderTotalResults = (id: string, totalResults: number) => ({
  type: ActionType.SetFolderTotalResults,
  id,
  totalResults,
});

type AddFolder = {
  type: ActionType.AddFolder;
  folder: EmailFolder;
};

const addFolder = (folder: EmailFolder) => ({
  type: ActionType.AddFolder,
  folder,
});

type DeleteFolder = {
  type: ActionType.DeleteFolder;
  id: string;
};

const deleteFolder = (id: string) => ({
  type: ActionType.DeleteFolder,
  id,
});

type SetCurrentMessage = {
  type: ActionType.SetCurrentMessage;
  id: string;
  selectedMessage: string;
};

const setCurrentMessage = (id: string, selectedMessage: string) => ({
  type: ActionType.SetCurrentMessage,
  id,
  selectedMessage,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

type SetAccountInboxUnreadCount = {
  type: ActionType.SetAccountInboxUnreadCount;
  accountId: string;
  unreadCount: number;
};

const setAccountInboxUnreadCount = (
  accountId: string,
  unreadCount: number
) => ({
  type: ActionType.SetAccountInboxUnreadCount,
  accountId,
  unreadCount,
});

type Clear = {
  type: ActionType.Clear;
};

export type ActionTypes =
  | SetFolders
  | AppendFolders
  | UpdateFolder
  | SetFoldersCount
  | SetFolderPosition
  | SetFolderTotalResults
  | AddFolder
  | DeleteFolder
  | SetCurrentMessage
  | ResetApp
  | SetAccountInboxUnreadCount
  | SetAccountFolders
  | Clear;
export const actions = {
  setFolders,
  appendFolders,
  updateFolder,
  setFoldersCount,
  setFolderPosition,
  setFolderTotalResults,
  addFolder,
  deleteFolder,
  setCurrentMessage,
  setAccountInboxUnreadCount,
  setAccountFolders,
};
