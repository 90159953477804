import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, EventCenterActions } from "@haywork/stores";
import {
  EntityListStatusState,
  EntityListStatusTypes
} from "@haywork/stores/event-center/entity-list-status";
import { connect, MapStateToProps } from "react-redux";
import {
  EntityListStatusComponent,
  EntityListStatusComponentProps
} from "./entity-list-status";

type StateProps = {
  entityListStatus: EntityListStatusState;
};
type DispatchProps = {
  onClose: (key: EntityListStatusTypes) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  EntityListStatusComponentProps,
  AppState
> = (state) => ({
  entityListStatus: state.eventCenter.entityListStatus
});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EntityListStatusComponentProps
> = (dispatch) => ({
  onClose: (key: EntityListStatusTypes) =>
    dispatch(EventCenterActions.EntityListStatus.clearListStatus(key))
});

export type EntityListStatusContainerProps = StateProps & DispatchProps;
export const EntityListStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityListStatusComponent);
