import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CCSModules from "react-css-modules";
import { LinkedRelation } from "@haywork/api/kolibri";
import { MailTo } from "@haywork/modules/shared";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  relation: LinkedRelation;
};
const RelationComponent: FC<Props> = memo(
  CCSModules(styles, { allowMultiple: true })(({ relation }) => {
    const {
      avatarUrl,
      letterAvatar,
      displayName,
      phone,
      phoneMobile,
      phoneWork,
      email,
    } = relation;

    const phones = useMemo(() => {
      return [phone, phoneMobile, phoneWork].filter((v) => !!v);
    }, [phone, phoneMobile, phoneWork]);

    const contactData = useMemo(() => {
      if (!phones.length && !email) return null;

      return (
        <div>
          {phones.map((phone, idx) => (
            <span key={idx}>
              <a className="as-link" href={`tel:${phone}`}>
                {phone}
              </a>
              {(idx !== phones.length - 1 || !!email) && ", "}
            </span>
          ))}
          {!!email && <MailTo email={email} />}
        </div>
      );
    }, [phones, email]);

    return (
      <div styleName="relation">
        <div
          styleName={classNames("avatar", { "as-letters": !avatarUrl })}
          style={{
            backgroundImage: !!avatarUrl
              ? `url(${JSON.stringify(avatarUrl)})`
              : null,
          }}
        >
          {!avatarUrl && !!letterAvatar && <span>{letterAvatar}</span>}
        </div>
        <div styleName="meta">
          <div styleName="meta__name">{displayName}</div>
          {contactData}
        </div>
      </div>
    );
  })
);

export default RelationComponent;
