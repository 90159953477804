import { Language, TranslatedText } from "@haywork/api/kolibri";
import { InputComponentProps } from "@haywork/modules/form/components/input/input.component";
import classNames from "classnames";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { intlContext } from "@haywork/app";

type TranslateTextComponentProps = {
  languages: Language[];
  defaultLanguage: Language;
  truncate?: number;
  placeholder?: string;
  maxCount?: number;
};
type State = {
  current: Language;
  languages: Language[];
  id: number;
};
type Props = TranslateTextComponentProps & InputComponentProps;

export class TranslateTextComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const current = this.props.defaultLanguage || Language.Dutch;

    this.state = {
      current,
      languages:
        !this.props.languages || this.props.languages.length === 0
          ? [current]
          : this.props.languages,
      id: uuid(),
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.fireOnChange = this.fireOnChange.bind(this);
    this.renderLanguageButton = this.renderLanguageButton.bind(this);
  }

  public render() {
    const values = this.prerenderValues();
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;

    return (
      <div className="input__translate-text">
        <div className="laguage-select">
          {this.state.languages.map(this.renderLanguageButton)}
        </div>

        <div className="language-textareas">
          {values.map((lang, idx) => {
            const className = this.getCorrectClassName(lang);

            return (
              lang.language === this.state.current && (
                <div key={idx}>
                  <textarea
                    id={`lang-${className}`}
                    name={`lang-${className}`}
                    onChange={(event) =>
                      this.onChangeHandler(event, lang.language)
                    }
                    onBlur={(event) => this.onBlurHandler(event, lang.language)}
                    value={lang.text}
                    placeholder={placeholder}
                    data-cy={
                      this.props["data-cy"] &&
                      `${this.props["data-cy"]}${lang.language}`
                    }
                    data-lpignore="true"
                  />
                  {this.props.maxCount && (
                    <div className="textarea__max-count">
                      <span
                        className={classNames("current-value", {
                          error: lang.text.length > this.props.maxCount,
                          warning:
                            lang.text.length / this.props.maxCount > 0.75,
                        })}
                      >
                        {lang.text.length}
                      </span>
                      /{this.props.maxCount}
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }

  private getCorrectClassName(language: TranslatedText): string {
    let className = language.language.toString().toLowerCase();
    className = className + this.state.id;

    return className;
  }

  private onClickHandler(current: Language) {
    let currentWithId = current;
    this.setState({ current }, () => {
      currentWithId = current + this.state.id;
      const ref = document.getElementById(
        `lang-${currentWithId.toString().toLowerCase()}`
      );
      if (!!ref) ref.focus();
    });
  }

  private onChangeHandler(
    event: React.ChangeEvent<HTMLTextAreaElement>,
    language: Language
  ) {
    const text = event.target.value;
    const value = this.props.value || [];
    const ref = value.find((v) => v.language === language);
    if (!ref) value.push({ language, text: "" });

    const values = value.map((lang) => {
      if (lang.language === language) {
        return { ...lang, text };
      }
      return lang;
    });

    this.fireOnChange(values);
  }

  private onBlurHandler(
    event: React.FocusEvent<HTMLTextAreaElement>,
    language: Language
  ) {
    let text = event.currentTarget.value;
    if (!this.props.truncate || text.length <= this.props.truncate) return;
    text = `${text.substring(0, this.props.truncate - 3).trim()}...`;

    const value = this.props.value || [];
    const ref = value.find((v) => v.language === language);
    if (!ref) value.push({ language, text: "" });

    const values = value.map((lang) => {
      if (lang.language === language) {
        return { ...lang, text: text.replace(/\\u2028|↵/g, "\n") };
      }
      return lang;
    });

    this.fireOnChange(values);
  }

  private fireOnChange(languages: { language: Language; text: string }[]) {
    const values = languages.map(
      (language) =>
        ({
          ...language,
          text: (language.text || "").replace(/\\u2028|↵/g, "\n"),
        } as { language: Language; text: string })
    );

    this.props.onChange(values);
  }

  private renderLanguageButton(
    language,
    idx
  ): React.ReactElement<HTMLDivElement> {
    const btnStyle = classNames(
      "btn",
      language === this.state.current ? "btn-pressed" : "btn-blank"
    );
    let btnIcon = "nl";

    switch (language) {
      case Language.Croatian:
        btnIcon = "hr";
        break;
      case Language.Dutch:
        btnIcon = "nl";
        break;
      case Language.English:
        btnIcon = "gb";
        break;
      case Language.Estonian:
        btnIcon = "ee";
        break;
      case Language.Finnish:
        btnIcon = "fi";
        break;
      case Language.French:
        btnIcon = "fr";
        break;
      case Language.Georgian:
        btnIcon = "ge";
        break;
      case Language.German:
        btnIcon = "de";
        break;
      case Language.Italian:
        btnIcon = "it";
        break;
      case Language.Polish:
        btnIcon = "pl";
        break;
      case Language.Russian:
        btnIcon = "ru";
        break;
      case Language.Spanish:
        btnIcon = "es";
        break;
      case Language.Swedish:
        btnIcon = "se";
        break;
      case Language.Turkish:
        btnIcon = "tr";
        break;
      default:
        break;
    }

    return (
      <div
        className={btnStyle}
        key={idx}
        onClick={() => this.onClickHandler(language)}
        data-cy={
          this.props["data-cy"] && `${this.props["data-cy"]}${language}button`
        }
      >
        <span id={btnIcon} className={`famfamfam-flag-${btnIcon}`} />
      </div>
    );
  }

  private prerenderValues(): TranslatedText[] {
    const values = this.props.value || [];

    return this.state.languages.map((language) => {
      const value = values.find((v) => v.language === language);
      return !value ? { language, text: "" } : value;
    });
  }
}
