import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import {
  EmailFolder,
  EmailMessage,
  EmailActionsV2,
} from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import {
  archiveFolder,
  canPerformEditActions,
  currentFolder,
  currentMessage,
  hasArchiveFolder,
  hasSpamFolder,
  hasTrashFolder,
  spamFolder,
  trashFolder,
} from "../../selectors";
import { HeaderComponent, HeaderComponentProps } from "./header";

type StateProps = {
  currentFolder: EmailFolder;
  currentMessage: EmailMessage;
  hasArchiveFolder: boolean;
  hasSpamFolder: boolean;
  archiveFolder: EmailFolder;
  spamFolder: EmailFolder;
  canSendEmail: boolean;
  canPerformEditActions: boolean;
  hasTrashFolder: boolean;
  trashFolder: EmailFolder;
  accountsAndFoldersVisible: boolean;
};
type DispatchProps = {
  onToggleUnRead: (
    id: string,
    isRead: boolean,
    accountId: string
  ) => Promise<void>;
  onToggleBookmarked: (
    id: string,
    isBookmarked: boolean,
    accountId: string
  ) => Promise<void>;
  moveToFolder: (messageId: string, newFolderId: string) => Promise<void>;
  createDraftFromMessage: (
    message: EmailMessage,
    draftType: "reply" | "reply-all" | "forward"
  ) => Promise<void>;
  createNewEmail: () => void;
  toggleAccountsAndFoldersVisibility: (visible: boolean) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  HeaderComponentProps,
  AppState
> = (state) => {
  const { accountsAndFoldersVisible } = state.emailV2.main;

  return {
    currentFolder: currentFolder(state),
    currentMessage: currentMessage(state),
    hasArchiveFolder: hasArchiveFolder(state),
    hasSpamFolder: hasSpamFolder(state),
    archiveFolder: archiveFolder(state),
    spamFolder: spamFolder(state),
    canSendEmail: canSendEmail(state),
    canPerformEditActions: canPerformEditActions(state),
    hasTrashFolder: hasTrashFolder(state),
    trashFolder: trashFolder(state),
    accountsAndFoldersVisible,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  HeaderComponentProps
> = (dispatch) => ({
  onToggleUnRead: (id: string, isRead: boolean, accountId: string) =>
    dispatch(EmailThunk.Messages.onToggleUnRead(id, isRead, accountId)),
  onToggleBookmarked: (id: string, isBookmarked: boolean, accountId: string) =>
    dispatch(
      EmailThunk.Messages.onToggleBookmarked(id, isBookmarked, accountId)
    ),
  moveToFolder: (messageId: string, newFolderId: string) =>
    dispatch(EmailThunk.Messages.moveToFolder(messageId, newFolderId)),
  createDraftFromMessage: (
    message: EmailMessage,
    draftType: "reply" | "reply-all" | "forward"
  ) => dispatch(EmailThunk.Messages.createDraftFromMessage(message, draftType)),
  createNewEmail: () => dispatch(EmailThunk.Main.createNewEmail()),
  toggleAccountsAndFoldersVisibility: (visible: boolean) =>
    dispatch(EmailActionsV2.Main.toggleAccountsAndFoldersVisibility(visible)),
});

export type HeaderContainerProps = StateProps & DispatchProps;
export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
