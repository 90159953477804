import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";
import get from "lodash-es/get";

const templatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;
const categoriesSelector = (state: AppState) =>
  state.emailV2.templates.categories;

export const categories = createSelector(categoriesSelector, (categories) =>
  categories.filter(
    (category) =>
      category.categoryType === TemplateDefinitionCategoryType.EmailTemplate
  )
);

export const templates = createSelector(
  [templatesSelector, categories],
  (templates, categories) => {
    const categoryIds = categories.map((category) => category.id);
    return templates.filter(
      (template) =>
        categoryIds.indexOf(
          get(template, "linkedTemplateDefinitionCategory.id")
        ) !== -1
    );
  }
);
