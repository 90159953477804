import {
  AgeRangeOption,
  AlvAgriculturalFunctionOption,
  AlvContinuation,
  AlvContinuationOption,
  AlvNonAgriculturalFunction,
  AlvNonAgriculturalFunctionOption,
  BuyerTypeOption,
  HouseholdCompositionOption,
  ObjectAssignment,
  TransactionMetaData,
} from "@haywork/api/kolibri";
import { TransactionMetaDataResponse } from "@haywork/middleware";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../../transaction-modal.component.scss");
const equals = require("react-fast-compare");

interface Props {
  assignment: ObjectAssignment;
  transactionMetaData: Partial<TransactionMetaData>;
  buyerTypeOptions: BuyerTypeOption[];
  householdCompositionOptions: HouseholdCompositionOption[];
  ageRangeOptions: AgeRangeOption[];
  alvContinuationOptions: AlvContinuationOption[];
  alvAgriculturalFunctionOptions: AlvAgriculturalFunctionOption[];
  alvNonAgriculturalFunctionOptions: AlvNonAgriculturalFunctionOption[];
  onUpdate: (data: TransactionMetaDataResponse) => void;
}
interface State {
  showOtherAlvFunctionDescription: boolean;
  alvContinuation: AlvContinuation;
}

@CSSModules(styles, { allowMultiple: true })
export class PersonalFormALV extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const showOtherAlvFunctionDescription =
      this.props.transactionMetaData?.alvNonAgriculturalFunction ===
      AlvNonAgriculturalFunction.Other;

    this.state = {
      showOtherAlvFunctionDescription,
      alvContinuation: this.props.transactionMetaData?.alvContinuation,
    };

    this.formControls = {
      buyerType: {
        value: this.props.transactionMetaData?.buyerType || "",
      },
      householdComposition: {
        value: this.props.transactionMetaData?.householdComposition || "",
      },
      numberOfHouseholdMembers: {
        value: this.props.transactionMetaData?.numberOfHouseholdMembers || "",
      },
      ageMainMoneyMaker: {
        value: this.props.transactionMetaData?.ageMainMoneyMaker || "",
      },
      alvContinuation: {
        value: this.props.transactionMetaData?.alvContinuation || "",
        onChange: ({ value: alvContinuation }) => {
          this.setState({ alvContinuation });

          switch (alvContinuation) {
            case AlvContinuation.NonAgriculturalFunction: {
              return {
                alvAgriculturalFunction: "",
              };
            }
            case AlvContinuation.CurrentFunction: {
              return {
                alvAgriculturalFunction: "",
                alvNonAgriculturalFunction: "",
              };
            }
            default: {
              this.setState({ showOtherAlvFunctionDescription: false });
              return {
                alvNonAgriculturalFunction: "",
                alvAgriculturalFunction: "",
              };
            }
          }
        },
      },
      alvAgriculturalFunction: {
        value: this.props.transactionMetaData?.alvAgriculturalFunction || "",
      },
      alvNonAgriculturalFunction: {
        value: this.props.transactionMetaData?.alvNonAgriculturalFunction || "",
        onChange: ({ value: alvNonAgriculturalFunction }) => {
          const showOtherAlvFunctionDescription =
            alvNonAgriculturalFunction === AlvNonAgriculturalFunction.Other;
          this.setState({ showOtherAlvFunctionDescription });

          if (!showOtherAlvFunctionDescription) {
            return {
              otherAlvFunctionDescription: "",
            };
          }
        },
      },
      otherAlvFunctionDescription: {
        value:
          this.props.transactionMetaData?.otherAlvFunctionDescription || "",
      },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !equals(prevProps.transactionMetaData, this.props.transactionMetaData)
    ) {
      let alvAgriculturalFunction =
        this.props.transactionMetaData?.alvAgriculturalFunction;
      let alvNonAgriculturalFunction =
        this.props.transactionMetaData?.alvNonAgriculturalFunction;

      switch (this.props.transactionMetaData?.alvContinuation) {
        case AlvContinuation.NonAgriculturalFunction: {
          alvAgriculturalFunction = null;
          break;
        }
        default: {
          alvNonAgriculturalFunction = null;
          alvAgriculturalFunction = null;
          break;
        }
      }

      this.formRef.update({
        buyerType: this.props.transactionMetaData?.buyerType || "",
        householdComposition:
          this.props.transactionMetaData?.householdComposition || "",
        numberOfHouseholdMembers:
          this.props.transactionMetaData?.numberOfHouseholdMembers || "",
        ageMainMoneyMaker:
          this.props.transactionMetaData?.ageMainMoneyMaker || "",
        alvContinuation: this.props.transactionMetaData?.alvContinuation || "",
        alvAgriculturalFunction:
          this.props.transactionMetaData?.alvAgriculturalFunction || "",
        alvNonAgriculturalFunction:
          this.props.transactionMetaData?.alvNonAgriculturalFunction || "",
        otherAlvFunctionDescription:
          this.props.transactionMetaData?.otherAlvFunctionDescription || "",
      });

      const showOtherAlvFunctionDescription =
        alvNonAgriculturalFunction === AlvNonAgriculturalFunction.Other;

      this.setState({
        showOtherAlvFunctionDescription,
        alvContinuation: this.props.transactionMetaData?.alvContinuation,
      });
    }
  }

  public render() {
    const { forSale } = this.props.assignment;
    const alvContinuation = this.state.alvContinuation;

    return (
      <Form
        name="transaction-personal"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChangeHandler}
      >
        <div styleName="info-block full">
          <i className="fal fa-info-circle" />
          <ResourceText
            resourceKey={
              forSale
                ? "saleTransaction.tiaraExtraInfo"
                : "rentTransaction.tiaraExtraInfo"
            }
            values={{ url: "" }}
            asHtml={true}
          />
        </div>
        <div styleName="inner">
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="buyerType">
                  <ResourceText
                    resourceKey={
                      forSale
                        ? "saleTransaction.buyerType"
                        : "rentTransaction.buyerType"
                    }
                  />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.NewSelect
                  name="buyerType"
                  values={this.props.buyerTypeOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.buyerTypeDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              <div className="column important">
                <label htmlFor="householdComposition">
                  <ResourceText resourceKey="saleTransaction.householdComposition" />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.NewSelect
                  name="householdComposition"
                  values={this.props.householdCompositionOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.householdCompositionDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="numberOfHouseholdMembers">
                  <ResourceText resourceKey="saleTransaction.numberOfHouseholdMembers" />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.Number name="numberOfHouseholdMembers" />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="ageMainMoneyMaker">
                  <ResourceText resourceKey="saleTransaction.ageMainMoneyMaker" />
                </label>
                <Input.NewSelect
                  name="ageMainMoneyMaker"
                  values={this.props.ageRangeOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.ageMainMoneyMakerDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              <div className="column">
                <label htmlFor="alvContinuation">
                  <ResourceText resourceKey="saleTransaction.alvContinuation" />
                  <Ui.RequiredForPublish hideHint={true} />
                </label>
                <Input.NewSelect
                  name="alvContinuation"
                  values={this.props.alvContinuationOptions}
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.alvContinuationDefault"
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
              {alvContinuation !== AlvContinuation.CurrentFunction &&
                alvContinuation !== null && (
                  <>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="alvAgriculturalFunction">
                        <ResourceText resourceKey="saleTransaction.alvAgriculturalFunction" />
                        <Ui.RequiredForPublish hideHint={true} />
                      </label>

                      <div
                        style={{
                          display:
                            alvContinuation ===
                            AlvContinuation.NonAgriculturalFunction
                              ? "none"
                              : "block",
                        }}
                      >
                        <Input.NewSelect
                          name="alvAgriculturalFunction"
                          values={this.props.alvAgriculturalFunctionOptions}
                          addEmptyOption
                          emptyOptionLabel="saleTransaction.alvAgriculturalFunctionDefault"
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                      <div
                        style={{
                          display:
                            alvContinuation ===
                            AlvContinuation.NonAgriculturalFunction
                              ? "block"
                              : "none",
                        }}
                      >
                        <Input.NewSelect
                          name="alvNonAgriculturalFunction"
                          values={this.props.alvNonAgriculturalFunctionOptions}
                          addEmptyOption
                          emptyOptionLabel="saleTransaction.alvNonAgriculturalFunctionDefault"
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>

          <div
            className="form__row"
            styleName="other-function-description"
            style={{
              display: this.state.showOtherAlvFunctionDescription
                ? "block"
                : "none",
            }}
          >
            <label htmlFor="otherAlvFunctionDescription">
              <ResourceText resourceKey="saleTransaction.otherAlvFunctionDescription" />
              <Ui.RequiredForPublish hideHint={true} />
            </label>
            <Input.Textarea name="otherAlvFunctionDescription" />
          </div>
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    this.props.onUpdate(values);
  }
}
