import { ActiveFilter, RelationType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import {
  EMPLOYEEROUTES,
  KEYCODE,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Loaders } from "@haywork/modules/ui/loaders";
import { ModalPortal } from "@haywork/portals";
import { Relation } from "@haywork/request";
import { AsyncUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import throttle from "lodash-es/throttle";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { v4 as uuid } from "uuid";
import { InputComponentProps } from "..";
import Input from "./components/input";
import Item from "./components/item";
import Pill from "./components/pill";
import Result from "./components/result";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface PillItem {
  id?: string;
  displayName?: string;
  email: string;
  isActive?: boolean;
  isRelationGroup?: boolean;
  color?: string;
  typeOfRelation?: RelationType;
}
export interface FormEmailWithRelationGroupsComponentProps {
  relationTypes?: RelationType[];
  placeholder?: string;
  onAdd?: (query: string) => void;
  onNavigate: (path: string) => void;
}
interface State {
  items: PillItem[];
  collapsed: boolean;
  listStyle: React.CSSProperties;
  query: string;
  results: PillItem[];
  mouseDown: boolean;
  searchInArchive: boolean;
  selectedResult: number;
  loading: boolean;
}
type Props = FormEmailWithRelationGroupsComponentProps & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class FormEmailWithRelationGroupsComponent extends React.PureComponent<
  Props,
  State
> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  private focusRef: React.RefObject<HTMLInputElement> = React.createRef();
  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  private prevInput: HTMLElement;
  private nextInput: HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      items: this.mapItems(this.props.value || []),
      collapsed: true,
      listStyle: null,
      query: "",
      results: [],
      mouseDown: false,
      searchInArchive: false,
      selectedResult: null,
      loading: false,
    };

    this.onRemove = this.onRemove.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
    this.onBlur = throttle(this.onBlur.bind(this), 50, { leading: false });
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.triggerFocus = this.triggerFocus.bind(this);
    this.query = debounce(this.query.bind(this), 250);
    this.onAdd = this.onAdd.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
    this.onAddAsNewRelation = this.onAddAsNewRelation.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.toggleSearchInArchive = this.toggleSearchInArchive.bind(this);
    this.fireOnChange = this.fireOnChange.bind(this);
    this.onQueryClickHandler = this.onQueryClickHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const prevLength = (get(prevProps, "value") || []).length;
    const curLength = (get(this.props, "value") || []).length;
    const intLength = this.state.items.length;

    if (prevLength !== curLength || curLength !== intLength) {
      const items = this.mapItems(this.props.value || []);
      this.setState({ items });
    }
  }

  public componentDidMount() {
    const parent = this.getParentForm(this.wrapperRef.current);
    if (!parent) return null;
    const inputs: NodeListOf<HTMLElement> = parent.querySelectorAll(
      "input, div[contenteditable=true]"
    );
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs.item(i);
      if (input === this.focusRef.current) {
        if (i < inputs.length - 1) {
          this.nextInput = inputs.item(i + 1);
        }
        if (i > 0) {
          this.prevInput = inputs.item(i - 1);
        }
        break;
      }
    }
  }

  public render() {
    const { items, listStyle, query, results, collapsed } = this.state;
    const itemsToDisplay = items.slice(0, 2);
    const remaining = items.length - itemsToDisplay.length;
    const itemIds = items.map((item) => item.id);
    const resultsToDisplay = results.filter(
      (item) => !itemIds.includes(item.id)
    );
    const placeholder = !!this.props.placeholder
      ? this.props.placeholder
      : "emailWithRelationGroupsInput.placeholder";

    return (
      <div
        styleName="suggestion__wrapper"
        ref={this.wrapperRef}
        data-cy={this.props["data-cy"]}
      >
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          ref={this.focusRef}
          onFocus={this.triggerFocus}
          styleName="focus-element"
          className="skip"
          data-lpignore="true"
        />
        <div styleName={classNames("suggestion", { collapsed })}>
          <div styleName="suggestion__value" onClick={this.toggleFocus}>
            {itemsToDisplay.map((item) => (
              <Pill
                displayName={item.displayName}
                id={item.id}
                email={item.email}
                key={item.id}
                onRemove={this.onRemove}
                onClick={this.onClick}
              />
            ))}
            {remaining > 0 && (
              <div styleName="remaining" onClick={this.focusOnInput}>
                +{remaining}
              </div>
            )}
            {!itemsToDisplay.length && (
              <div styleName="placeholder" onClick={this.focusOnInput}>
                <I18n value={placeholder} />
              </div>
            )}
          </div>
          {items.length > 0 && (
            <div styleName="suggestion__clear" onClick={this.onClear}>
              <i className="fal fa-times" />
            </div>
          )}
          <div styleName="suggestion__trigger" onClick={this.toggleFocus}>
            <i className="fal fa-chevron-down" />
          </div>
        </div>

        <ModalPortal>
          <div
            styleName={classNames("suggestions", { collapsed })}
            style={listStyle}
          >
            {!!items.length && (
              <div styleName="suggestions__list">
                {items.map((item, idx) => (
                  <Item
                    item={item}
                    key={item.id}
                    onClick={this.onClick}
                    onRemove={this.onRemove}
                    onMouseDown={this.onMouseDown}
                  />
                ))}
              </div>
            )}

            <div styleName="suggestions__query">
              <Input
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                inputRef={this.inputRef}
                query={query}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                dataCy={this.props["data-cy"]}
              />
              <div
                styleName={classNames("toggle-archive", {
                  active: this.state.searchInArchive,
                })}
                onMouseUp={this.toggleSearchInArchive}
                onMouseDown={this.onMouseDown}
              >
                <I18n value="suggestionComponent.toggleArchive" />
              </div>
              {!this.state.query.length ? (
                <div styleName="query-trigger" onClick={this.focusOnInput}>
                  <i className="fal fa-search" />
                </div>
              ) : (
                <div styleName="query-trigger clear" onClick={this.clearQuery}>
                  <i className="fal fa-times" />
                </div>
              )}
            </div>

            {(!!resultsToDisplay.length || !!query) && !this.state.loading && (
              <div styleName="results__list">
                <div
                  styleName={classNames("default-suggestion", {
                    selected: this.state.selectedResult === null,
                  })}
                  onClick={this.onQueryClickHandler}
                  onMouseDown={() => this.setOnMouseOverState(true)}
                  onMouseUp={() => this.setOnMouseOverState(false)}
                >
                  <I18n
                    value="emailQueryAddAsEmail"
                    values={{ query: this.state.query }}
                    asHtml
                  />
                </div>
                {resultsToDisplay.map((item, idx) => (
                  <ErrorBoundary key={idx}>
                    <Result
                      item={item}
                      key={idx}
                      onClick={this.onAdd}
                      selected={idx === this.state.selectedResult}
                      onMouseDown={this.onMouseDown}
                      idx={idx}
                      dataCy={this.props["data-cy"]}
                    />
                  </ErrorBoundary>
                ))}
                {this.props.onAdd && (
                  <div
                    styleName={classNames("add-new-relation", {
                      selected:
                        this.state.selectedResult === this.state.results.length,
                    })}
                    onMouseUp={this.onAddAsNewRelation}
                    onMouseDown={this.onMouseDown}
                  >
                    <i className="fal fa-plus-circle" />
                    <I18n
                      value="addAsNewRelation"
                      values={{ query }}
                      asHtml={true}
                    />
                  </div>
                )}
              </div>
            )}
            {this.state.loading && (
              <Loaders.Indeterminate></Loaders.Indeterminate>
            )}
          </div>
        </ModalPortal>
      </div>
    );
  }

  private mapItems(itemsToMap: PillItem[]) {
    const items: PillItem[] = itemsToMap.map((item) => ({
      displayName: item.displayName,
      id: item.id || uuid(),
      isActive: item.isActive,
      email: item.email,
      typeOfRelation: item.typeOfRelation,
    }));
    return items;
  }

  private toggleFocus() {
    if (this.state.collapsed) {
      this.focusOnInput();
    } else {
      this.onBlur();
    }
  }

  private clearQuery() {
    this.setState({ query: "", results: [] });
    this.focusOnInput();
  }

  private triggerFocus() {
    if (!this.inputRef.current) return;
    this.inputRef.current.focus();
  }

  private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode, shiftKey } = e;
    const { query } = this.state;

    switch (keyCode) {
      case KEYCODE.TAB: {
        if (!!this.nextInput && !shiftKey) {
          e.preventDefault();
          this.nextInput.focus();
        }
        if (!!this.prevInput && shiftKey) {
          e.preventDefault();
          this.prevInput.focus();
        }
        return;
      }
      case KEYCODE.ENTER: {
        event.preventDefault();
        if (!query || !query) return;

        let items = this.state.items;
        if (this.state.selectedResult !== null) {
          const relation = this.state.results[this.state.selectedResult];

          if (!relation) {
            this.props.onAdd(query);
            return this.setState({
              query: "",
              results: [],
              selectedResult: null,
            });
          }

          this.onAdd(relation);
          if (!relation.isRelationGroup) {
            items = [
              ...items,
              {
                email: relation.email,
                displayName: relation.displayName,
                id: relation.id,
                typeOfRelation: relation.typeOfRelation,
              },
            ];
          }
        } else {
          items = [
            ...items,
            {
              email: query,
              id: uuid(),
            },
          ];
        }

        this.fireOnChange(items);

        return this.setState({
          items,
          query: "",
          results: [],
          selectedResult: null,
        });
      }
      case KEYCODE.DOWN_ARROW: {
        event.preventDefault();
        if (this.state.selectedResult === null) {
          return this.setState({
            selectedResult: 0,
          });
        }
        if (this.state.selectedResult === this.state.results.length) {
          return this.setState({
            selectedResult: null,
          });
        }
        return this.setState({
          selectedResult: this.state.selectedResult + 1,
        });
      }
      case KEYCODE.UP_ARROW: {
        event.preventDefault();
        if (this.state.selectedResult === null) {
          return this.setState({
            selectedResult: this.state.results.length,
          });
        }
        if (this.state.selectedResult === 0) {
          return this.setState({
            selectedResult: null,
          });
        }
        return this.setState({
          selectedResult: this.state.selectedResult - 1,
        });
      }
      default: {
        return;
      }
    }
  }

  private getParentForm(el: HTMLElement): HTMLFormElement | null {
    switch (true) {
      case el.parentElement === document.body: {
        return null;
      }
      case el.parentElement.tagName === "FORM": {
        return el.parentElement as HTMLFormElement;
      }
      default: {
        return this.getParentForm(el.parentElement);
      }
    }
  }

  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value || "";
    this.setState({ query });
    this.query(query.substring(0, 50));
  }

  private onFocus() {
    if (!this.wrapperRef.current) return;
    const { top, height, left, width } =
      this.wrapperRef.current.getBoundingClientRect();

    this.setState({
      collapsed: false,
      listStyle: {
        top: top + height,
        left,
        width,
      },
    });
  }

  private onBlur() {
    if (this.state.mouseDown) return;
    if (this.inputRef.current !== document.activeElement) {
      this.setState({ collapsed: true, results: [], query: "" });
    }
  }

  private focusOnInput() {
    if (!this.inputRef.current) return;
    this.inputRef.current.focus();
  }

  private onRemove(id: string) {
    const items = this.state.items.filter((item) => item.id !== id);
    this.setState({ items, mouseDown: false });
    this.fireOnChange(items);
    if (!this.state.collapsed) {
      this.focusOnInput();
    }
  }

  private onAdd(item: PillItem) {
    this.setState({ mouseDown: false });

    if (item.isRelationGroup) {
      this.getRelationsByGroupId(item.id);
    } else {
      this.addRelation(item);
    }

    this.focusOnInput();
  }

  private addRelation(item: PillItem) {
    let value = this.props.value || [];
    const selectedIds = value.map((item) => item.id);
    if (!selectedIds.includes(item.id)) {
      value = [...value, item];
      this.fireOnChange(value);
    }
  }

  private async getRelationsByGroupId(id: string) {
    this.setState({ loading: true });
    try {
      const relations = await Relation.getRelationsByGroupId(id);

      let value = this.props.value || [];
      const selectedIds = value.map((item) => item.id);

      const mappedAndFilteredRelations = relations
        .filter(
          (relation) => !!relation.email && !selectedIds.includes(relation.id)
        )
        .map((relation) => ({
          id: relation.id,
          displayName: relation.displayName || "",
          email: relation.email || "",
          isActive: relation.isActive,
          isRelationGroup: false,
          color: "",
        }));

      value = [...value, ...mappedAndFilteredRelations];

      this.fireOnChange(value);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  private onMouseDown() {
    this.setState({ mouseDown: true });
  }

  private onClick(id: string) {
    const item = (this.props.value || []).find((item) => item.id === id);
    if (!item) return;

    switch (item.typeOfRelation) {
      case RelationType.ContactCompany: {
        const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
          id,
        });
        this.props.onNavigate(path);
        return;
      }
      case RelationType.ContactPerson: {
        const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
          id,
        });
        this.props.onNavigate(path);
        return;
      }
      case RelationType.Employee: {
        const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, {
          id,
        });
        this.props.onNavigate(path);
        return;
      }
      case RelationType.Office: {
        const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, {
          id,
        });
        this.props.onNavigate(path);
        return;
      }
      default: {
        return;
      }
    }
  }

  private onClear() {
    this.setState({ items: [], results: [], query: "" });
    this.fireOnChange([]);
    if (!this.state.collapsed && !!this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  private async query(query: string) {
    if (query.length < 2) {
      this.setState({ results: [] });
      return;
    }

    this.setState({ loading: true });

    const activeFilter = this.state.searchInArchive
      ? ActiveFilter.InactiveOnly
      : ActiveFilter.ActiveOnly;

    const filterByRelationTypes = this.props.relationTypes || [];
    const relations = await Relation.searchV3(
      query,
      10,
      null,
      activeFilter,
      filterByRelationTypes,
      false
    );

    const relationGroups = await Relation.searchRelationGroups(
      query,
      10,
      activeFilter,
      false
    );

    const mappedRelationGroups: PillItem[] = relationGroups.map(
      (relationGroup) => ({
        id: relationGroup.id,
        displayName: relationGroup.name || "",
        email: "",
        isActive: activeFilter === ActiveFilter.ActiveOnly,
        isRelationGroup: true,
        color: relationGroup.backColor || "",
      })
    );

    const mappedRelations: PillItem[] = relations.map((relation) => ({
      id: relation.id,
      displayName: relation.displayName || "",
      email: relation.email || "",
      isActive: relation.isActive,
      isRelationGroup: false,
      color: "",
      typeOfRelation: relation.typeOfRelation,
    }));

    const results = [...mappedRelationGroups, ...mappedRelations];

    this.setState({ results, loading: false });
  }

  private onAddAsNewRelation() {
    this.setState({ mouseDown: false });
    if (!this.props.onAdd) return;
    this.onBlur();
    this.props.onAdd(this.state.query);
  }

  private toggleSearchInArchive() {
    const { searchInArchive: oldSearchInArchiveState, query } = this.state;
    const searchInArchive = !oldSearchInArchiveState;

    this.setState({
      searchInArchive,
      mouseDown: false,
    });
    this.focusOnInput();

    if (!!query) {
      this.query(query.substring(0, 50));
    }
  }

  private setOnMouseOverState(mouseDown: boolean) {
    this.setState({ mouseDown });
  }

  private onQueryClickHandler() {
    const { query } = this.state;
    const items = [
      ...this.state.items,
      {
        email: query,
        id: uuid(),
      },
    ];

    this.fireOnChange(items);
    this.setState({
      items,
      results: [],
      selectedResult: null,
      query: "",
    });
    this.triggerFocus();
  }

  private async fireOnChange(
    results: (
      | PillItem
      | {
          email: string;
          id: any;
        }
    )[]
  ) {
    this.props.onChange(results);
    if (!this.state.collapsed && !!this.wrapperRef.current) {
      await AsyncUtil.wait(200);
      const { top, height, left, width } =
        this.wrapperRef.current.getBoundingClientRect();

      this.setState({
        listStyle: {
          top: top + height,
          left,
          width,
        },
      });
    }
  }
}
