import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { MAINROUTES } from "@haywork/constants";

const styles = require("./style.scss");

type Props = {
  numberOfNotArchivedEvents: number;
  onNavigate: (path: string) => void;
};

export const TriggerComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ numberOfNotArchivedEvents, onNavigate }) => {
      const navigatetoNotifications = useCallback(() => {
        onNavigate(MAINROUTES.NOTIFICATIONS.URI);
      }, [onNavigate]);

      return (
        <div
          styleName="events-modal__trigger"
          onClick={navigatetoNotifications}
        >
          <i className="fa fa-bell" />
          {numberOfNotArchivedEvents > 0 && (
            <div styleName="unread-count">{numberOfNotArchivedEvents}</div>
          )}
        </div>
      );
    }
  )
);
