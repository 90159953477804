import { AssignmentType, RealEstateGroup } from "@haywork/api/kolibri";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import {
  EmployeeThunk,
  MapDispatchToProps,
  OfficeThunk,
} from "@haywork/middleware";
import { AppState, AssignmentListActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  assignmentPhaseValues,
  availabilityStatusValues,
  availableFromValue,
  availableUntillValue,
  employeeValues,
  furnishingValues,
  keyNumberValues,
  listingTypeValues,
  localityValues,
  numberOfBedroomsValue,
  officeValues,
  priceRangeValue,
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";
import { garageTypeOptions } from "@haywork/modules/mls/screens/list/selectors";

type StateProps = {
  filters: FilterConfig;
  realEstateGroupValues: SingleFilterValue[];
  assignmentTypeValues: SingleFilterValue[];
  saleOrRentValues: SingleFilterValue[];
  listingTypeValues: SingleFilterValue[];
  assignmentPhaseValues: SingleFilterValue[];
  availabilityStatusValues: SingleFilterValue[];
  officeValues: SingleFilterValue[];
  employeeValues: SingleFilterValue[];
  localityValues: SingleFilterValue[];
  furnishingValues: SingleFilterValue[];
  keyNumberValues: SingleFilterValue[];
  archivedValues: SingleFilterValue[];
  garageTypeOptions: SingleFilterValue[];
  priceRangeValue: SingleFilterValue;
  numberOfBedroomsValue: SingleFilterValue;
  availableFromValue: SingleFilterValue;
  availableUntillValue: SingleFilterValue;
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
  getOffices: () => Promise<void>;
  getEmployees: () => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.assignment.list;
  const realEstateGroupValues: SingleFilterValue[] = [
    {
      label: `realEstateGroups.${RealEstateGroup.Residential.toString()}`,
      value: RealEstateGroup.Residential,
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Commercial.toString()}`,
      value: RealEstateGroup.Commercial,
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Agricultural.toString()}`,
      value: RealEstateGroup.Agricultural,
    },
  ];
  const assignmentTypeValues: SingleFilterValue[] = [
    {
      label: `assignmentTypes.${AssignmentType.Object.toString()}`,
      value: AssignmentType.Object,
    },
    {
      label: `assignmentTypes.${AssignmentType.Project.toString()}`,
      value: AssignmentType.Project,
    },
  ];
  const saleOrRentValues: SingleFilterValue[] = [
    { label: "assignment.filter.forSale", value: "forSale" },
    { label: "assignment.filter.forRent", value: "forRent" },
  ];
  const archivedValues: SingleFilterValue[] = [
    { label: "assignment.filter.archived", value: "archived" },
  ];

  return {
    filters,
    realEstateGroupValues,
    assignmentTypeValues,
    saleOrRentValues,
    listingTypeValues: listingTypeValues(state),
    assignmentPhaseValues: assignmentPhaseValues(state),
    availabilityStatusValues: availabilityStatusValues(state),
    officeValues: officeValues(state),
    employeeValues: employeeValues(state),
    localityValues: localityValues(state),
    furnishingValues: furnishingValues(state),
    keyNumberValues: keyNumberValues(state),
    archivedValues,
    priceRangeValue: priceRangeValue(state),
    numberOfBedroomsValue: numberOfBedroomsValue(state),
    availableFromValue: availableFromValue(state),
    availableUntillValue: availableUntillValue(state),
    garageTypeOptions: garageTypeOptions(state),
    mutationsFromValue: garageTypeOptions(state),
    livingAreaValues: garageTypeOptions(state),
    noOfRoomsValues: garageTypeOptions(state),
    noOfBedroomsValues: garageTypeOptions(state),
    contentValues: garageTypeOptions(state),
    floorsValues: garageTypeOptions(state),
    yearOfConstructionValues: garageTypeOptions(state),
    gardenPlotSizeValues: garageTypeOptions(state),
    plotSizeValues: garageTypeOptions(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(AssignmentListActions.setFilters(filters)),
  getOffices: () => dispatch(OfficeThunk.getOffices(true, 100)),
  getEmployees: () => dispatch(EmployeeThunk.getEmployees(true, 100)),
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
