import {
  SearchAssignment,
  SearchAssignmentSnapShot,
} from "@haywork/api/kolibri";

export const mapSearchAssignmentToSearchAssignmentSnapShot = (
  assignment: SearchAssignment
): SearchAssignmentSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    dateAssignment,
    matchMailPeriod,
    linkedRelations,
    realEstateGroup,
    name,
    typesPART,
    typesBOG,
    typesALV,
    offerType,
    priceStart,
    priceEnd,

    isPaid,
  } = assignment;

  return {
    id,
    isActive,
    linkedCreatedBy,
    linkedEmployee: linkedCreatedBy,
    linkedModifiedBy,
    linkedRelations,
    realEstateAgencyId,
    dateAssignment,
    matchMailPeriod,
    offerType,
    name,
    realEstateGroup,
    alvOveType: typesALV,
    bogOveType: typesBOG,
    partOveType: typesPART,
    minPrice: priceStart,
    maxPrice: priceEnd,
    dateTimeCreated,
    dateTimeModified,
    isPaid,
  };
};
