import {
  CompanySettings,
  Employee,
  Language,
  ObjectAssignment
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingWebsiteComponent,
  AssignmentEditMarketingWebsiteComponentProps
} from "@haywork/modules/assignment";
import {
  AppState,
  CurrentRealestateAgency,
  EditableActions,
  SingleAssignmentState
} from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  languages: Language[];
  defaultLanguage: Language;
  settings: CompanySettings;
  employee: Employee;
  currentRealestateAgency: CurrentRealestateAgency;
}
interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingWebsiteComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;
  const { settings } = state.company;
  const { employee, currentRealestateAgency } = state.account;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage,
    settings,
    employee,
    currentRealestateAgency
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingWebsiteComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type AssignmentEditMarketingWebsiteContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingWebsiteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingWebsiteComponent);
