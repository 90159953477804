import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import classNames from "classnames";

const styles = require("./style.scss");

export type Props = {
  min: number | null;
  max: number | null;
  index: string;
  onChange: (min: number | null, max: number | null, id: string) => void;
  onClear: (id: string) => void;
};

export const PostalCodeRangeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ min, max, index, onChange, onClear }) => {
      const [minValue, setMinValue] = useState(min);
      const [maxValue, setMaxValue] = useState(max);
      const inited = useRef<boolean>(false);

      const onClearCallback = useCallback(() => {
        onClear(index);
      }, [onClear, index]);

      useEffect(() => {
        if (!inited.current) {
          inited.current = true;
          return;
        }

        onChange(minValue, maxValue, index);
      }, [minValue, maxValue, index, onChange]);

      return (
        <div styleName="row">
          <div styleName={classNames("text-spacer", "first")}>
            <I18n value="mls.filter.from.postalCodeRanges" />
          </div>
          <div styleName="min">
            <Input.Number
              name={`postal-code-range__min-filter.${index}`}
              value={minValue}
              onChange={setMinValue}
              placeholder="mls.filter.label.postalCodeRanges"
            />
          </div>
          <div styleName="text-spacer">
            <I18n value="mls.filter.to.postalCodeRanges" />
          </div>
          <div styleName="max">
            <Input.Number
              name={`postal-code-range__max-filter.${index}`}
              value={maxValue}
              onChange={setMaxValue}
              placeholder="mls.filter.label.postalCodeRanges"
            />
          </div>
          <div styleName="spacer" />
          {(!!minValue || !!maxValue) && (
            <div styleName="clear">
              <div styleName="clear__trigger" onClick={onClearCallback}>
                <Icon name="times" light color={Colors.Gray} />
              </div>
            </div>
          )}
        </div>
      );
    }
  )
);
