export type EntityListStatusTypes =
  | "assignments"
  | "acquisitions"
  | "tasks"
  | "relations"
  | "searchAssignments"
  | "invoices"
  | "notifications"
  | "mls"
  | string;

export { actions as EntityListStatusActions } from "./actions";
export { EntityListStatusState, entityListStatusReducer } from "./reducer";
