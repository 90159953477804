import {
  FolderTree,
  FolderTreeFileEntity,
  FolderTreeFileType,
  FolderTreeFolderEntity,
  FolderTreeRootFolderEntity,
} from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { FolderTreeEntityType, DeleteDossierItem } from "@haywork/middleware";
import Notes from "@haywork/modules/notes-v3";
import { DossierDragPreview } from "@haywork/modules/shared/components/dossier-v2";
import { Ui } from "@haywork/modules/ui";
import sortBy from "lodash-es/sortBy";
import * as React from "react";
import { createRef, PureComponent, RefObject } from "react";
import * as CSSModules from "react-css-modules";
import { FileUploadContainer, FileUploadIcon, ResourceText } from "../..";
import AddFolder from "../dossier-add-folder";
import Folder from "./components/folder";
import { DossierContainerProps } from "./dossier.container";
import Actions, { DossierBulkAction } from "./actions";
import SelectDossierItems from "./components/select-dossier-items-modal";
import { UploadResponse } from "@haywork/api/mail";
import get from "lodash-es/get";

const styles = require("./style.scss");

export interface DossierComponentProps {
  parentId: string;
  id: string | null | undefined;
  subject: string;
  entityType: FolderTreeEntityType;
  blocked?: boolean;
  addNotes: boolean;
}
interface State {
  loading: boolean;
  folderTree: FolderTree;
  addFolderModalVisible: boolean;
  folderToEdit: FolderTreeFolderEntity | null;
  folderToEditPath: string | null;
  justAddedFiles: string[];
  selectedFiles: { file: FolderTreeFileEntity; pathname: string }[];
  nativeFiles: File[];
  uploadFolder: string;
  refreshing: boolean;
  bulkSelectedFiles: FolderTreeFileEntity[];
  selectItemsModalVisible: boolean;
}
type Props = DossierComponentProps & DossierContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DossierComponent extends PureComponent<Props, State> {
  private itemsRef: RefObject<HTMLDivElement> = createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      folderTree: null,
      addFolderModalVisible: false,
      folderToEdit: null,
      folderToEditPath: null,
      justAddedFiles: [],
      selectedFiles: [],
      nativeFiles: [],
      uploadFolder: null,
      refreshing: false,
      bulkSelectedFiles: [],
      selectItemsModalVisible: false,
    };

    this.fetchFolderTree = this.fetchFolderTree.bind(this);
    this.onFileFolderChange = this.onFileFolderChange.bind(this);
    this.onCloseFolderModal = this.onCloseFolderModal.bind(this);
    this.onUpdateFolderTree = this.onUpdateFolderTree.bind(this);
    this.onOpenFolderModal = this.onOpenFolderModal.bind(this);
    this.onUploadCompleted = this.onUploadCompleted.bind(this);
    this.onDeleteFolder = this.onDeleteFolder.bind(this);
    this.onEditFolder = this.onEditFolder.bind(this);
    this.onToggleSelectFile = this.onToggleSelectFile.bind(this);
    this.clearSelectedFiles = this.clearSelectedFiles.bind(this);
    this.onFilesDropped = this.onFilesDropped.bind(this);
    this.onToggleSelectForBulk = this.onToggleSelectForBulk.bind(this);
    this.onActionClickHandler = this.onActionClickHandler.bind(this);
    this.onCloseSelectItemsModal = this.onCloseSelectItemsModal.bind(this);
    this.onCreateMailWithAttachments =
      this.onCreateMailWithAttachments.bind(this);
  }

  public componentDidMount() {
    if (!this.props.blocked) this.fetchFolderTree();
    document.addEventListener("click", this.clearSelectedFiles, true);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.parentId !== this.props.parentId ||
      (!!prevProps.blocked && !this.props.blocked)
    ) {
      this.setState({ loading: true, folderTree: null });
      this.fetchFolderTree();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.clearSelectedFiles, true);
  }

  public render() {
    const selectedFiles = this.state.selectedFiles.map((file) => file.file.id);
    const actionsVisible = !!this.state.bulkSelectedFiles.length;

    return (
      <div styleName="dossier">
        <PageHeader
          title="dossierTitle"
          subTitle={this.props.subject}
          actions={
            <>
              {this.props.addNotes && <Notes />}
              <Button
                label="dossier.action.addFolder"
                category={actionsVisible ? "success" : "primary"}
                icon={<Icon name="plus" light size={18} />}
                iconPosition="start"
                onClick={this.onOpenFolderModal}
              />
              <Actions
                visible={actionsVisible}
                canEmail={this.props.canEmail}
                onClick={this.onActionClickHandler}
              />
            </>
          }
        />

        <div styleName="upload">
          <FileUploadContainer
            multiple
            icon={FileUploadIcon.Upload}
            title="fileUploadFilesTitle"
            subtitle="fileUploadFilesSubtitle"
            onCompleted={this.onUploadCompleted}
            shouldBePrivate
            clearOutline
            passedFiles={this.state.nativeFiles}
          />
        </div>

        <div styleName="list">
          {!!this.state.refreshing && <Ui.Loaders.Fullscreen mask />}

          <div styleName="column-header">
            <div styleName="spacer" />
            <div styleName="status">
              <ResourceText resourceKey="dossier.column.status" />
            </div>
            <div styleName="amount">
              <ResourceText resourceKey="dossier.column.amount" />
            </div>
            <div styleName="creation-date">
              <ResourceText resourceKey="dossier.column.creationDate" />
            </div>
            <div styleName="entities-description">
              <ResourceText resourceKey="dossier.column.entitiesDescription" />
            </div>
          </div>

          {this.state.loading && (
            <div styleName="loader">
              <Ui.Loaders.List />
            </div>
          )}

          {!!this.state.folderTree && (
            <div styleName="items" ref={this.itemsRef}>
              <Folder
                folder={this.state.folderTree.rootFolderTree.documentSessions}
                parentName=""
                depth={1}
                onFileFolderChange={this.onFileFolderChange}
                onDeleteFolder={this.onDeleteFolder}
                rootId={this.props.parentId}
                justAddedFiles={this.state.justAddedFiles}
                selectedFiles={selectedFiles}
                onRefresh={this.fetchFolderTree}
                onEditFolder={this.onEditFolder}
                onToggleSelect={this.onToggleSelectFile}
                onFilesDropped={this.onFilesDropped}
                bulkSelectedFiles={this.state.bulkSelectedFiles}
                onToggleSelectForBulk={this.onToggleSelectForBulk}
                onCreateMailWithAttachments={this.onCreateMailWithAttachments}
              />
              <Folder
                folder={this.state.folderTree.rootFolderTree.invoices}
                parentName=""
                depth={1}
                onFileFolderChange={this.onFileFolderChange}
                onDeleteFolder={this.onDeleteFolder}
                rootId={this.props.parentId}
                justAddedFiles={this.state.justAddedFiles}
                selectedFiles={selectedFiles}
                onRefresh={this.fetchFolderTree}
                onEditFolder={this.onEditFolder}
                onToggleSelect={this.onToggleSelectFile}
                onFilesDropped={this.onFilesDropped}
                bulkSelectedFiles={this.state.bulkSelectedFiles}
                onToggleSelectForBulk={this.onToggleSelectForBulk}
                onCreateMailWithAttachments={this.onCreateMailWithAttachments}
              />
              <Folder
                folder={this.state.folderTree.rootFolderTree.dossierItems}
                parentName=""
                depth={1}
                onFileFolderChange={this.onFileFolderChange}
                onDeleteFolder={this.onDeleteFolder}
                rootId={this.props.parentId}
                justAddedFiles={this.state.justAddedFiles}
                selectedFiles={selectedFiles}
                onRefresh={this.fetchFolderTree}
                onEditFolder={this.onEditFolder}
                onToggleSelect={this.onToggleSelectFile}
                onFilesDropped={this.onFilesDropped}
                bulkSelectedFiles={this.state.bulkSelectedFiles}
                onToggleSelectForBulk={this.onToggleSelectForBulk}
                onCreateMailWithAttachments={this.onCreateMailWithAttachments}
              />
            </div>
          )}
        </div>

        <AddFolder
          visible={this.state.addFolderModalVisible}
          folderTree={
            !!this.state.folderTree ? this.state.folderTree.rootFolderTree : {}
          }
          folder={this.state.folderToEdit}
          folderPath={this.state.folderToEditPath}
          onClose={this.onCloseFolderModal}
          onUpdateFolderTree={this.onUpdateFolderTree}
        />

        <DossierDragPreview />
        <SelectDossierItems
          visible={this.state.selectItemsModalVisible}
          view="check"
          files={this.state.bulkSelectedFiles}
          onClose={this.onCloseSelectItemsModal}
          onFiles={this.onCreateMailWithAttachments}
        />
      </div>
    );
  }

  private onFilesDropped(nativeFiles: File[], uploadFolder: string) {
    this.setState({
      nativeFiles,
      uploadFolder,
    });
  }

  private clearSelectedFiles(event: MouseEvent) {
    if (
      this.itemsRef.current &&
      !!this.state.selectedFiles.length &&
      !this.itemsRef.current.contains(event.target as Node)
    ) {
      this.setState({ selectedFiles: [] });
    }
  }
  private async fetchFolderTree() {
    try {
      const { parentId, id, entityType } = this.props;
      let folderTree = await this.props.fetchFolderTree(
        parentId,
        entityType,
        id
      );
      folderTree = {
        ...folderTree,
        rootFolderTree: {
          ...(folderTree?.rootFolderTree || {}),
          dossierItems: {
            ...(folderTree?.rootFolderTree?.dossierItems || {
              isSystemFolder: false,
            }),
            files: sortBy(
              folderTree?.rootFolderTree?.dossierItems?.files || [],
              (file) =>
                file.linkedDossierItem?.dateTimeCreated ||
                file.linkedDocumentSession?.dateTimeCreated ||
                file.linkedInvoice?.dateTimeCreated
            ).reverse(),
          },
        },
      };

      this.setState({ folderTree });
    } finally {
      this.setState({ loading: false, refreshing: false });
    }
  }

  private recursivelyUpdateFolderTree(
    tree: FolderTreeFolderEntity,
    parentName: string,
    file: FolderTreeFileEntity,
    oldPathName: string,
    newPathName: string
  ) {
    let { folders, files } = tree;
    folders = folders || [];
    files = files || [];
    const name = [parentName, tree.name || ""].filter((d) => !!d).join("/");

    if (name === newPathName) {
      files = sortBy([...files, file], (file) => {
        switch (file.type) {
          case FolderTreeFileType.DocumentSession: {
            return file.linkedDocumentSession.dateTimeCreated;
          }
          case FolderTreeFileType.DossierItem: {
            return file.linkedDossierItem.dateTimeCreated;
          }
          case FolderTreeFileType.Invoice: {
            return file.linkedInvoice.dateTimeCreated;
          }
          default:
            return null;
        }
      }).reverse();
    }

    if (name === oldPathName) {
      files = files.filter((ref) => ref.id !== file.id);
    }

    folders = folders.map((folder) =>
      this.recursivelyUpdateFolderTree(
        folder,
        name,
        file,
        oldPathName,
        newPathName
      )
    );
    return {
      ...tree,
      folders,
      files,
    };
  }

  private onFileFolderChange(
    file: FolderTreeFileEntity,
    oldPathName: string,
    newPathName: string
  ) {
    const { folderTree } = this.state;
    let updatedFolderTree = { ...folderTree };

    if (this.state.selectedFiles.length) {
      this.state.selectedFiles.map((fileObj) => {
        const { file, pathname } = fileObj;
        updatedFolderTree = this.onSingleFileFolderChange(
          file,
          pathname,
          newPathName,
          updatedFolderTree,
          true
        );
      });

      if (!!updatedFolderTree) {
        this.updateFolderTree(updatedFolderTree, folderTree);
      }
    } else {
      this.onSingleFileFolderChange(file, oldPathName, newPathName, folderTree);
    }
  }

  private onSingleFileFolderChange(
    file: FolderTreeFileEntity,
    oldPathName: string,
    newPathName: string,
    folderTree: FolderTree,
    blockUpdate: boolean = false
  ) {
    if (oldPathName === newPathName) return;

    const { rootFolderTree } = folderTree;
    const documentSessions = this.recursivelyUpdateFolderTree(
      rootFolderTree.documentSessions,
      "",
      file,
      oldPathName,
      newPathName
    );
    const invoices = this.recursivelyUpdateFolderTree(
      rootFolderTree.invoices,
      "",
      file,
      oldPathName,
      newPathName
    );
    const dossierItems = this.recursivelyUpdateFolderTree(
      rootFolderTree.dossierItems,
      "",
      file,
      oldPathName,
      newPathName
    );

    const updatedFolderTree: FolderTree = {
      ...folderTree,
      rootFolderTree: {
        documentSessions,
        invoices,
        dossierItems,
      },
    };

    if (!blockUpdate) {
      this.updateFolderTree(updatedFolderTree, folderTree);
    }
    return updatedFolderTree;
  }

  private async updateFolderTree(
    folderTree: FolderTree,
    oldFolderTree: FolderTree
  ) {
    this.setState({ folderTree });

    try {
      folderTree = await this.props.updateFolderTree(folderTree);
    } catch {
      this.setState({
        folderTree: oldFolderTree,
      });
    } finally {
      this.setState({
        folderToEdit: null,
        folderToEditPath: null,
        selectedFiles: [],
        loading: false,
        refreshing: false,
      });
    }
  }

  private onUpdateFolderTree(rootFolderTree: FolderTreeRootFolderEntity) {
    this.setState({ addFolderModalVisible: false });
    const { folderTree } = this.state;
    const updatedFolderTree = {
      ...folderTree,
      rootFolderTree,
    };

    this.updateFolderTree(updatedFolderTree, folderTree);
  }

  private async onUploadCompleted(items: any[]) {
    this.setState({ refreshing: true });
    const dossierItems: any[] = items.map((item) => ({
      fileDataId: item.fileDataId,
      fileExtension: item.fileExtension,
      fileName: item.fileName,
      fileSize: item.fileSize,
      md5Hash: item.md5Hash,
      name: item.name,
    }));

    let skipUpdate = true;
    try {
      const justAddedFiles = await this.props.uploadDossierItems(
        dossierItems,
        this.props.parentId
      );
      const { uploadFolder } = this.state;
      if (!!uploadFolder) {
        const { parentId, id, entityType } = this.props;
        const folderTree = await this.props.fetchFolderTree(
          parentId,
          entityType,
          id
        );
        const uploadedFiles =
          folderTree.rootFolderTree.dossierItems.files.reduce(
            (state, file) => {
              if (justAddedFiles.includes(file.linkedDossierItem.id)) {
                state.push({
                  file,
                  pathname: folderTree.rootFolderTree.dossierItems.name,
                });
              }
              return state;
            },
            [] as {
              file: FolderTreeFileEntity;
              pathname: string;
            }[]
          );

        let updatedFolderTree = { ...folderTree };
        uploadedFiles.map((fileObj) => {
          const { file, pathname } = fileObj;
          updatedFolderTree = this.onSingleFileFolderChange(
            file,
            pathname,
            uploadFolder,
            updatedFolderTree,
            true
          );
        });

        skipUpdate = !updatedFolderTree;
        if (!skipUpdate) {
          this.updateFolderTree(updatedFolderTree, folderTree);
        }
      }

      this.setState({ justAddedFiles, nativeFiles: [], uploadFolder: null });
    } finally {
      if (!!skipUpdate) this.fetchFolderTree();
    }
  }

  private recursivelyDeleteFolder(
    tree: FolderTreeFolderEntity,
    parentName: string,
    path: string
  ) {
    let { folders } = tree;
    folders = folders || [];

    folders = folders.reduce((state, folder) => {
      const name = [parentName, folder.name || ""].filter((d) => !!d).join("/");

      switch (true) {
        case path === name: {
          return state;
        }
        case !!folder.folders && !!folder.folders.length: {
          state.push(this.recursivelyDeleteFolder(folder, name, path));
          return state;
        }
        default: {
          state.push(folder);
          return state;
        }
      }
    }, [] as FolderTreeFolderEntity[]);

    return {
      ...tree,
      folders,
    };
  }

  private onDeleteFolder(path: string) {
    const { folderTree } = this.state;
    const { rootFolderTree } = folderTree;
    const documentSessions = this.recursivelyDeleteFolder(
      rootFolderTree.documentSessions,
      rootFolderTree.documentSessions.name,
      path
    );
    const invoices = this.recursivelyDeleteFolder(
      rootFolderTree.invoices,
      rootFolderTree.invoices.name,
      path
    );
    const dossierItems = this.recursivelyDeleteFolder(
      rootFolderTree.dossierItems,
      rootFolderTree.dossierItems.name,
      path
    );

    const updatedFolderTree: FolderTree = {
      ...folderTree,
      rootFolderTree: {
        documentSessions,
        invoices,
        dossierItems,
      },
    };

    this.updateFolderTree(updatedFolderTree, folderTree);
  }

  private onEditFolder(
    folderToEdit: FolderTreeFolderEntity,
    folderToEditPath: string
  ) {
    this.setState({
      folderToEdit,
      folderToEditPath,
      addFolderModalVisible: true,
    });
  }

  private onToggleSelectFile(ref: FolderTreeFileEntity, pathname: string) {
    let { selectedFiles } = this.state;
    const ids = selectedFiles.map((file) => file.file.id);
    if (!ids.includes(ref.id)) {
      selectedFiles = [...selectedFiles, { file: ref, pathname }];
    } else {
      selectedFiles = selectedFiles.filter((file) => file.file.id !== ref.id);
    }
    this.setState({ selectedFiles });
  }

  private onOpenFolderModal() {
    this.setState({ addFolderModalVisible: true });
  }

  private onCloseFolderModal() {
    this.setState({ addFolderModalVisible: false });
  }

  private onToggleSelectForBulk(file: FolderTreeFileEntity) {
    let { bulkSelectedFiles: bulkSelectedFileIds } = this.state;
    const hasFileId = !!bulkSelectedFileIds.find((f) => f.id === file.id);
    bulkSelectedFileIds = hasFileId
      ? bulkSelectedFileIds.filter((f) => f.id !== file.id)
      : [...bulkSelectedFileIds, file];
    this.setState({ bulkSelectedFiles: bulkSelectedFileIds });
  }

  private async onActionClickHandler(action: DossierBulkAction) {
    switch (action) {
      case DossierBulkAction.CreateEmail: {
        this.setState({
          selectItemsModalVisible: true,
        });
        return;
      }
      case DossierBulkAction.Delete: {
        const items = this.state.bulkSelectedFiles.map((file) => {
          const { type, id, linkedDossierItem } = file;
          const parentId =
            type === FolderTreeFileType.DossierItem
              ? get(linkedDossierItem, "parentId")
              : undefined;

          return {
            id,
            type,
            parentId,
          } as DeleteDossierItem;
        });

        await this.props.bulkDeleteDossierItems(items, this.fetchFolderTree);
        this.setState({ bulkSelectedFiles: [] });
        this.fetchFolderTree();
        return;
      }
      case DossierBulkAction.Deselect: {
        this.setState({ bulkSelectedFiles: [] });
        return;
      }
      default: {
        return;
      }
    }
  }

  private onCloseSelectItemsModal() {
    this.setState({ selectItemsModalVisible: false });
  }

  private onCreateMailWithAttachments(results: UploadResponse[]) {
    if (!results || !results.length) return;
    this.props.createNewEmailWithAttachments(results);
  }
}
