import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue } from "../../";
import { ListFilterContext } from "../../list-filter.context";
import { Input } from "@haywork/modules/form";
import { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  configKey: string;
  value: SingleFilterValue;
  title?: Translateable;
};

export const PriceRangeComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ configKey, value, title }) => {
    const { filters, onFilterChange } = useContext(ListFilterContext);
    const [minValue, setMinValue] = useState(value.value.min || "");
    const [maxValue, setMaxValue] = useState(value.value.max || "");

    const filter = useMemo(() => {
      return !filters ? undefined : filters[configKey];
    }, [filters, configKey]);

    const label = useMemo(() => {
      if (!title || !filter) return null;
      const { value, values } = isString(title)
        ? { value: title, values: undefined }
        : title;
      return (
        <div styleName="label">
          <I18n value={value} values={values} />
        </div>
      );
    }, [title, filter]);

    const minPlaceholder = useMemo(() => {
      if (!filter) return undefined;
      return `range.placeholder.min.${configKey}`;
    }, [configKey]);

    const maxPlaceholder = useMemo(() => {
      if (!filter) return undefined;
      return `range.placeholder.max.${configKey}`;
    }, [configKey]);

    const handleInputBlur = useCallback(() => {
      if (!filter) return;

      const updatedFilter = {
        ...filter,
        value: {
          min: minValue || undefined,
          max: maxValue || undefined,
        },
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    }, [filter, filters, configKey, onFilterChange, minValue, maxValue]);

    useEffect(() => {
      setMinValue(value.value.min || "");
      setMaxValue(value.value.max || "");
    }, [value, setMinValue, setMaxValue]);

    if (!filter) return null;

    return (
      <div styleName="wrapper">
        {!!label && <h2>{label}</h2>}
        <div styleName="range">
          <div styleName="input">
            <div className="input__helper">
              <div className="pre small">&euro;</div>
              <Input.Number
                name={`${configKey}.min`}
                value={minValue}
                onBlur={handleInputBlur}
                onChange={setMinValue}
                placeholder={minPlaceholder}
                pretty
                asSingleInput
                fireAllChanges
              />
            </div>
          </div>
          <div styleName="input">
            <div className="input__helper">
              <div className="pre small">&euro;</div>
              <Input.Number
                name={`${configKey}.max`}
                value={maxValue}
                onBlur={handleInputBlur}
                onChange={setMaxValue}
                placeholder={maxPlaceholder}
                pretty
                asSingleInput
                fireAllChanges
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);
