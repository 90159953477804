import {
  ActiveFilter,
  RelationGroupSnapShot,
  RelationSnapShot,
  RelationType
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { RelationFilter } from "@haywork/middleware/thunk/relation.thunk";
import { Action } from "redux";
import * as ActionType from "./relation.types";

export interface RelationOverviewState {
  relations: RelationSnapShot[];
  totalRelationCount: number;
  cities: string[];
  relationOverviewState: string;
  relationInitialOverviewState: string;
  relationOverviewPage: number;
  relationOverviewPageCount: number;
  selectedRelationFilters: RelationFilter;
  relationExportUrl: string;
  relationGroups: RelationGroupSnapShot[];
}

const INITIAL_STATE: RelationOverviewState = {
  relations: [],
  totalRelationCount: 0,
  cities: [],
  relationOverviewState: REQUEST.IDLE,
  relationInitialOverviewState: REQUEST.IDLE,
  relationOverviewPage: 0,
  relationOverviewPageCount: 0,
  selectedRelationFilters: {
    activeOrInactiveFilter: ActiveFilter.ActiveOnly,
    relationTypeFilter: [
      RelationType.ContactCompany,
      RelationType.ContactPerson
    ],
    term: null,
    linkedRelationGroupsFilter: [],
    termFieldsFilter: null
  },
  relationExportUrl: null,
  relationGroups: null
};

export const relationOverviewReducer = (
  state: RelationOverviewState = INITIAL_STATE,
  action: Action
): RelationOverviewState => {
  switch (action.type) {
    case REDUX.RELATION.SET_FETCH_RELATION_OVERVIEW_STATE: {
      const { relationOverviewState } = <ActionType.RelationOverviewState>(
        action
      );
      return { ...state, relationOverviewState };
    }
    case REDUX.RELATION.SET_INITIAL_FETCH_RELATION_OVERVIEW_STATE: {
      const { relationOverviewState } = <ActionType.RelationOverviewState>(
        action
      );
      return { ...state, relationInitialOverviewState: relationOverviewState };
    }
    case REDUX.RELATION.SEARCH_RELATION: {
      const { resultCount, results, statistics, totalResults } = <
        ActionType.RelationResponse
      >action;
      const relationOverviewPageCount = Math.ceil(totalResults / resultCount);

      return {
        ...state,
        relations: results,
        totalRelationCount: totalResults,
        cities: statistics.localities,
        relationOverviewPage: 1,
        relationOverviewPageCount,
        relationInitialOverviewState: REQUEST.SUCCESS
      };
    }
    case REDUX.RELATION.APPEND_RELATION: {
      const { resultCount, results, statistics, totalResults } = <
        ActionType.RelationResponse
      >action;
      let relationOverviewPageCount = Math.ceil(totalResults / resultCount);
      if (relationOverviewPageCount === Infinity) {
        relationOverviewPageCount = 0;
      }
      const relationOverviewPage = state.relationOverviewPage + 1;

      return {
        ...state,
        relations: [...state.relations, ...results],
        totalRelationCount: totalResults,
        cities: statistics.localities,
        relationOverviewPage,
        relationOverviewPageCount
      };
    }
    case REDUX.RELATION.UPDATE_RELATION_FILTER: {
      const { relationFilter } = <ActionType.RelationFilterUpdate>action;

      return {
        ...state,
        selectedRelationFilters: relationFilter
      };
    }
    case REDUX.RELATION.ARCHIVE_RELATION: {
      const { relation } = <ActionType.ArchiveRelation>action;
      const filteredRelations = state.relations.filter(
        ({ id }) => id !== relation.id
      );

      return {
        ...state,
        relations: filteredRelations,
        totalRelationCount: state.totalRelationCount - 1
      };
    }
    case REDUX.RELATION.SET_RELATION_EXPORT_URL: {
      const { relationExportUrl } = <ActionType.SetExportUrl>action;

      return {
        ...state,
        relationExportUrl
      };
    }
    case REDUX.RELATION.SEARCH_RELATIONGROUPS: {
      const { results } = <ActionType.RelationGroupsResponse>action;

      return {
        ...state,
        relationGroups: results
      };
    }
    case REDUX.RELATION.UPDATE_LIST_ITEM: {
      const { snapshot } = <ActionType.SnapShot>action;
      const relations = (state.relations || []).map((relation) => {
        if (relation.id === snapshot.id) {
          return snapshot;
        }
        return relation;
      });

      return {
        ...state,
        relations
      };
    }
    case REDUX.RELATION.DELETE_LIST_ITEM: {
      const { id } = <ActionType.Guid>action;
      const relations = (state.relations || []).filter(
        (relation) => relation.id !== id
      );

      return {
        ...state,
        relations
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
