import { LinkedRelation, Message, MessagesClient } from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { Dispatch } from "../";
import EventCenter from "./event-center";

const parseRequest = new ParseRequest();

const getMessage = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const client = new MessagesClient(host);

      return await parseRequest.response(
        client.read(id, realEstateAgencyId).then((response) => response.message)
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateMessageWithLinkedRelation = (
  id: string,
  linkedRelation: LinkedRelation
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host } = state.appSettings;
      const client = new MessagesClient(host);

      const message = await dispatch(getMessage(id));
      if (!message) return;

      const mergedMessage = {
        ...message,
        linkedRelation
      } as Message;

      await parseRequest.response(
        client.save({ message: mergedMessage }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getMessage,
  updateMessageWithLinkedRelation
};
