import {
  LinkedSearchAssignment,
  MatchedPropertySnapShot,
  SearchAssignment,
} from "@haywork/api/kolibri";
import { RealEstatePropertySearchItem } from "@haywork/api/mls";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import {
  SearchAssignmentDetailLinkedAssignmentsComponent,
  SearchAssignmentDetailLinkedAssignmentsComponentProps,
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignment: SearchAssignment;
}
interface DispatchProps {
  getSearchAssignmentMatchingProperties: (
    id: string,
    startIndex: number,
    stopIndex: number
  ) => Promise<{
    items: MatchedPropertySnapShot[];
    total: number;
  }>;
  navigate: (url: string) => void;
  getMlsObjectsMatchingSearchAssignments: (
    linkedSearchAssignments: LinkedSearchAssignment[],
    startIndex: number,
    stopIndex: number
  ) => Promise<{
    items: RealEstatePropertySearchItem[];
    total: number;
  }>;
}

const mapStateToProps = <
  StateProps,
  AssignmentDetailLinkedAssignmentsComponentProps
>(
  state: AppState
) => {
  const { searchAssignment } = state.searchAssignment.single;

  return {
    searchAssignment,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AssignmentDetailLinkedAssignmentsComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getSearchAssignmentMatchingProperties: (
    id: string,
    startIndex: number,
    stopIndex: number
  ) =>
    dispatch(
      SearchAssignmentThunks.getSearchAssignmentMatchingProperties(
        id,
        startIndex,
        stopIndex
      )
    ),
  navigate: (url: string) => dispatch(push(url)),
  getMlsObjectsMatchingSearchAssignments: (
    linkedSearchAssignments: LinkedSearchAssignment[],
    startIndex: number,
    stopIndex: number
  ) =>
    dispatch(
      AcquisitionThunks.Acquisition.getMlsObjectsMatchingSearchAssignments(
        linkedSearchAssignments,
        startIndex,
        stopIndex
      )
    ),
});

export type SearchAssignmentDetailLinkedAssignmentsContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentDetailLinkedAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentDetailLinkedAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentDetailLinkedAssignmentsComponent);
