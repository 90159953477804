import * as React from "react";
import { FC, memo } from "react";
import { useIntl } from "react-intl";

interface FormSuggestionInputComponentProps {
  query: string;
  inputRef: React.RefObject<HTMLInputElement>;
  type: "relation" | "assignment";
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
type Props = FormSuggestionInputComponentProps;
export const FormSuggestionInputComponent: FC<Props> = memo(
  ({ query, inputRef, type, onFocus, onBlur, onChange, onKeyDown }) => {
    const intl = useIntl();

    const translationId =
      type === "relation"
        ? "suggestionInput.searchRelations"
        : "suggestionInput.searchAssignments";
    const placeholder = intl.formatMessage({
      id: translationId,
      defaultMessage: translationId,
    });

    return (
      <input
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        data-lpignore="true"
      />
    );
  }
);
