import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { EntityListStatusTypes } from "@haywork/stores/event-center/entity-list-status";
import * as React from "react";
import { CSSProperties, memo, FC, MouseEvent, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { EntityListStatusContainerProps } from "./entity-list-status.container";

const styles = require("./style.scss");

export type EntityListStatusComponentProps = {
  list: EntityListStatusTypes;
  style?: CSSProperties;
  onClick: () => void;
};
type Props = EntityListStatusComponentProps & EntityListStatusContainerProps;

export const EntityListStatusComponent: FC<Props> = memo(
  CSSModules(styles, {
    allowMultiple: true
  })(({ entityListStatus, list, style, onClose, onClick }) => {
    const visible = !!entityListStatus[list];

    const close = useCallback(
      (event?: MouseEvent<HTMLDivElement>) => {
        if (!!event) event.stopPropagation();
        onClose(list);
      },
      [onClose, list]
    );

    const click = useCallback(() => {
      close();
      onClick();
    }, [onClick, close]);

    return !visible ? null : (
      <div style={style} styleName="wrapper">
        <div styleName="status" onClick={click}>
          <div styleName="label">
            <I18n value="entityListStatus.message" />
          </div>
          <div styleName="close" onClick={close}>
            <Icon name="times" light size={16} />
          </div>
        </div>
      </div>
    );
  })
);
