import { ResourceText } from "@haywork/modules/shared";
import { ModalPortal } from "@haywork/portals";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./confirm.component.scss");

interface Props {
  visible: boolean;
  titleResourceKey: string;
  bodyResourceKey: string;
  bodyValues?: any;
  onClose: () => void;
  onConfirm: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class ConfirmComponent extends React.PureComponent<Props> {
  public render() {
    if (!this.props?.visible) return null;

    const { titleResourceKey, bodyResourceKey, bodyValues } = this.props;

    return (
      <ModalPortal>
        <div styleName="confirm">
          <div styleName="confirm__backdrop" />
          <div styleName="confirm__object">
            <header>
              <ResourceText resourceKey={titleResourceKey} />
              <div
                styleName="confirm__close"
                onClick={() => this.props.onClose()}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.CloseButton`
                }
              >
                <div className="fal fa-times" />
              </div>
            </header>
            <section>
              <ResourceText
                resourceKey={bodyResourceKey}
                values={bodyValues}
                asHtml
              />
            </section>
            <footer>
              <div
                id="yes__"
                className="btn btn-success"
                onClick={() => this.props.onConfirm()}
              >
                <ResourceText resourceKey="yes" />
              </div>
              <div
                id="no__"
                className="btn btn-danger"
                onClick={() => this.props.onClose()}
              >
                <ResourceText resourceKey="no" />
              </div>
            </footer>
          </div>
        </div>
      </ModalPortal>
    );
  }
}
