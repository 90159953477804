import {
  AccountSettingsClient,
  Address,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import {
  FinanceQuery,
  LocationQuery,
  LocationSuggestionItem,
  LocationSuggestionRequest,
  LocationSuggestionType,
  MutationQuery,
  PropertyType,
  PropertyTypeFilter,
  PropertyTypeSubFilter,
  RangeUnit,
  RealEstatePropertyClient,
  RealEstatePropertySearchItem,
  RealtorSuggestionItem,
  StatusType,
  SuggestionClient,
} from "@haywork/api/mls";
import { FilterConfig } from "@haywork/components/ui/list";
import {
  MLS,
  MLS_APARTMENT_SORTS,
  MLS_GARAGE_TYPES,
  MLS_HORECA_TYPES,
  MLS_HOUSE_TYPES,
  MLS_OTHER_TYPES_PART,
} from "@haywork/constants/mls";
import searchLocation from "@haywork/modules/settings/modules/mls/components/search-location";
import { ParseRequest } from "@haywork/services";
import AbortController from "@haywork/services/abort.service";
import { AccountActions, AppState } from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { RelativeDateValue } from "@haywork/stores/mls/list";
import { ParkingPlaceCount } from "@haywork/stores/mls/list/reducer";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { MlsUtil } from "@haywork/util";
import head from "lodash-es/head";
import * as moment from "moment";
import * as PrintJS from "print-js";
import { IntlShape } from "react-intl";
import { Dispatch } from "react-redux";
import { EmailThunk } from "../emailV2";

const parseRequest = new ParseRequest();

export type MlsFilter = {
  name: string;
  filters: Record<string, any>;
  id: string;
};

const abortSearch = new AbortController();
const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      await abortSearch.process();

      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost, abortSearch.fetch());
      const { filters, order, selectedIds: bundleIds } = state.mls.list;

      if (!!bundleIds.length) {
        const response = await parseRequest.response(
          client.search(
            {
              skip: 0,
              take: bundleIds.length,
              orderBy: order.sortColumn,
              sortOrder: order.sortOrder,
              bundleIds,
            },
            realEstateAgencyId
          )
        );

        if (!response) throw new Error("No realestate properties found.");
        const { results, totalCount } = response;

        dispatch(MLSActions.List.updateList(0, results, totalCount));
        return;
      }

      const {
        energyClasses,
        garageTypeOptions,
        garageCapacityValues,
        conditionOptionsInside,
        conditionOptionsOutside,
        mutationTypes,
        mutationsDates,
        priceRangeValues,
        contentValues,
        livingAreaValues,
        noOfRoomsValues,
        noOfBedroomsValues,
        floorsValues,
        gardenPlotSizeValues,
        plotSizeValues,
        yearOfConstructionValues,
        availabilityStatuses,
        outside,
        orientationTypeValues,
        realEstateGroups,
        facilitySearchTypeOptions,
        otherSearchTypeOptions,
        propertyTypeOptions,
        locationPlaces,
        locations,
        realtors,
        searchLocations,
        postalCodeRanges,
        houseTypes,
        houseSorts,
        houseCharacteristics,
        apartmentSorts,
        apartmentCharacteristics,
        garageTypes,
        otherTypes,
        horseCompanySubtypes,
        parkingTypeOptions,
        pigCompanyTypes,
        cattleFarmingSubtypes,
        horticulturalCompanyTypes,
        beverageHospitalitySectorTypes,
        groups,
        appClients,
        forSaleAndOrRent,
        following,
        parkingPlaceCounts,
        floorAreaValues,
        groundType,
        relativeDate,
      } = filters;

      let landAreaMin: number;
      let landAreaMax: number;
      let isResidential: boolean;
      let isCommercial: boolean;
      let isAgricultural: boolean;
      switch (realEstateGroups.value) {
        case RealEstateGroup.Residential: {
          landAreaMin = plotSizeValues.value.min;
          landAreaMax = plotSizeValues.value.max;
          isResidential = true;
          break;
        }
        case RealEstateGroup.Commercial: {
          landAreaMin = plotSizeValues.value.min;
          landAreaMax = plotSizeValues.value.max;
          isCommercial = true;
          break;
        }
        case RealEstateGroup.Agricultural: {
          landAreaMin = !plotSizeValues.value.min
            ? undefined
            : plotSizeValues.value.min * 10000;
          landAreaMax = !plotSizeValues.value.max
            ? undefined
            : plotSizeValues.value.max * 10000;
          isAgricultural = true;
          break;
        }
        default: {
          break;
        }
      }

      let locationsFilter: LocationQuery[] = (locations.value || []).map(
        (item: {
          location: ExtendedLocationSuggestionItem;
          range: number;
          rangeUnit: RangeUnit;
        }) => {
          const { location, range, rangeUnit } = item;
          const {
            countryId,
            adminAreaLevel1Id,
            adminAreaLevel2Id,
            adminAreaLevel3Id,
            localityId,
            sublocalityId,
            streetId,
            addressHash,
            postalCode,
          } = location;

          return {
            countryId,
            adminAreaLevel1Id,
            adminAreaLevel2Id,
            adminAreaLevel3Id,
            localityId,
            sublocalityId,
            streetId,
            addressHash,
            range: range || 0,
            rangeUnit: rangeUnit || RangeUnit.Kilometer,
            postalCode,
          } as LocationQuery;
        }
      );

      const locationRanges = [
        ...(searchLocations.value || []),
        ...(postalCodeRanges.value || []),
      ];

      if (!!locationRanges.length && !locationsFilter.length) {
        locationsFilter = locationRanges.map((location) => ({
          postalCodeMin: location.min,
          postalCodeMax: location.max,
        }));
      }

      const realEstateAgencyForeignId = (realtors.value || [])
        .map((item: { realtor: RealtorSuggestionItem }) => item.realtor.id)
        .filter((d) => !!d);

      const propertyTypeFilters: PropertyTypeFilter[] = [];
      const propertyTypes = propertyTypeOptions.value || [];

      // Main property types with sub types
      if (propertyTypes.includes(PropertyType.HOUSE)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = houseTypes.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_HOUSE_TYPES : values,
        });

        if (!!(houseSorts.value || []).length) {
          subFilters.push({ propertyTypes: houseSorts.value });
        }

        if (!!(houseCharacteristics.value || []).length) {
          subFilters.push({ propertyTypes: houseCharacteristics.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.APARTMENT)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = apartmentSorts.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_APARTMENT_SORTS : values,
        });

        if (!!(apartmentCharacteristics.value || []).length) {
          subFilters.push({ propertyTypes: apartmentCharacteristics.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.PARKING)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = parkingTypeOptions.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_GARAGE_TYPES : values,
        });

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.RESIDENTIAL_OTHERS)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = otherTypes.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_OTHER_TYPES_PART : values,
        });

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.HOTEL_RESTAURANT_CAFE)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = beverageHospitalitySectorTypes.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_HORECA_TYPES : values,
        });

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.GARAGE)) {
        const subFilters: PropertyTypeSubFilter[] = [];
        const values = parkingTypeOptions.value || [];

        subFilters.push({
          propertyTypes: !values.length ? MLS_GARAGE_TYPES : values,
        });

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.HORSES_COMPANY)) {
        const subFilters: PropertyTypeSubFilter[] = [];

        if (!!(horseCompanySubtypes.value || []).length) {
          subFilters.push({ propertyTypes: horseCompanySubtypes.value });
        }

        if (!!(groundType.value || []).length) {
          subFilters.push({ propertyTypes: groundType.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.HORTICULTURAL_COMPANY)) {
        const subFilters: PropertyTypeSubFilter[] = [
          { propertyTypes: [PropertyType.HORTICULTURAL_COMPANY] },
        ];

        if (!!(horticulturalCompanyTypes.value || []).length) {
          subFilters.push({ propertyTypes: horticulturalCompanyTypes.value });
        }

        if (!!(groundType.value || []).length) {
          subFilters.push({ propertyTypes: groundType.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.PIG_HOLDING)) {
        const subFilters: PropertyTypeSubFilter[] = [
          { propertyTypes: [PropertyType.PIG_HOLDING] },
        ];

        if (!!(pigCompanyTypes.value || []).length) {
          subFilters.push({ propertyTypes: pigCompanyTypes.value });
        }

        if (!!(groundType.value || []).length) {
          subFilters.push({ propertyTypes: groundType.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      if (propertyTypes.includes(PropertyType.CATTLE_HUSBANDRY)) {
        const subFilters: PropertyTypeSubFilter[] = [
          { propertyTypes: [PropertyType.CATTLE_HUSBANDRY] },
        ];

        if (!!(cattleFarmingSubtypes.value || []).length) {
          subFilters.push({ propertyTypes: cattleFarmingSubtypes.value });
        }

        if (!!(groundType.value || []).length) {
          subFilters.push({ propertyTypes: groundType.value });
        }

        propertyTypeFilters.push({ subFilters });
      }

      // Single main property types
      const combinedSubFilters: PropertyTypeSubFilter[] = [];
      const combinedPropertyTypes: PropertyType[] = [];
      [
        PropertyType.PLOT,
        PropertyType.COMMERCIAL_OTHERS,
        PropertyType.PRODUCTION_HALL,
        PropertyType.OUTDOOR_AREA,
        PropertyType.OFFICE,
        PropertyType.LOT,
        PropertyType.RETAIL_SPACE,
        PropertyType.WAREHOUSE,
        PropertyType.WAREHOUSE_PRODUCTION,
        PropertyType.SERVICES,
        PropertyType.LEISURE,
        PropertyType.INVESTMENT,
        PropertyType.SOCIAL_PROPERTY,
        PropertyType.PRACTICE_SPACE,
        PropertyType.ARABLE_COMPANY,
        PropertyType.LOOSE_SOIL,
        PropertyType.DAIRY_FARMING_COMPANY,
        PropertyType.POULTRY_COMPANY,
        PropertyType.VEAL_CALVES,
        PropertyType.AGRICULTURAL_HOUSE,
        PropertyType.AGRICULTURAL_OTHERS,
        PropertyType.PREMISES,
      ].forEach((propertyType) => {
        if (propertyTypes.includes(propertyType)) {
          combinedPropertyTypes.push(propertyType);
        }
      });

      if (!!combinedPropertyTypes.length) {
        combinedSubFilters.push({ propertyTypes: combinedPropertyTypes });
      }

      if (!!(groundType.value || []).length) {
        combinedSubFilters.push({ propertyTypes: groundType.value });
      }

      if (!!combinedSubFilters.length) {
        propertyTypeFilters.push({ subFilters: combinedSubFilters });
      }

      let parkingPlacesMin: number;
      let parkingPlacesMax: number;

      switch (parkingPlaceCounts?.value) {
        case ParkingPlaceCount.LessThanFive: {
          parkingPlacesMax = 5;
          break;
        }
        case ParkingPlaceCount.MoreThanFive: {
          parkingPlacesMin = 5;
          break;
        }
        case ParkingPlaceCount.MoreThanTen: {
          parkingPlacesMin = 10;
          break;
        }
        case ParkingPlaceCount.MoreThanTwenty: {
          parkingPlacesMin = 20;
          break;
        }
        case ParkingPlaceCount.MoreThanFifty: {
          parkingPlacesMin = 50;
          break;
        }
        default: {
          break;
        }
      }

      const others = (otherSearchTypeOptions.value || []).filter(
        (item) =>
          [
            "isAccessibleForSeniors",
            "isAccessibleWithDisabilities",
            "isAdaptedHome",
            "hasOpenHouse",
            "isAuction",
          ].indexOf(item) === -1
      );

      const othersAccesibility = (otherSearchTypeOptions.value || []).filter(
        (item) =>
          [
            "isAccessibleForSeniors",
            "isAccessibleWithDisabilities",
            "isAdaptedHome",
            "hasOpenHouse",
            "isAuction",
          ].indexOf(item) > -1
      );

      let finance: FinanceQuery = {};
      if (!!priceRangeValues?.value?.type) {
        switch (priceRangeValues?.value?.type) {
          case "sale": {
            finance = {
              ...finance,
              purchasePriceFrom: priceRangeValues?.value?.min,
              purchasePriceUntil: priceRangeValues?.value?.max,
            };
            break;
          }
          case "rent": {
            finance = {
              ...finance,
              rentPriceFrom: priceRangeValues?.value?.min,
              rentPriceUntil: priceRangeValues?.value?.max,
            };
            break;
          }
          case "transactionPrice": {
            finance = {
              ...finance,
              transactionPriceFrom: priceRangeValues?.value?.min,
              transactionPriceUntil: priceRangeValues?.value?.max,
            };
            break;
          }
          case "pricePerM2": {
            finance = {
              ...finance,
              squareMeterPriceFrom: priceRangeValues?.value?.min,
              squareMeterPriceUntil: priceRangeValues?.value?.max,
            };
            break;
          }
          default:
            break;
        }
      }

      let mutation: MutationQuery = {
        from: mutationsDates.value.min,
        until: mutationsDates.value.max,
        mutationTypes: mutationTypes.value || mutationTypes.emptyValue,
      };

      if (!!relativeDate.value) {
        switch (relativeDate.value) {
          case RelativeDateValue.Today: {
            mutation = {
              ...mutation,
              from: moment().startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          case RelativeDateValue.Yesterday: {
            mutation = {
              ...mutation,
              from: moment().subtract(1, "day").startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          case RelativeDateValue.DayBeforeYesterday: {
            mutation = {
              ...mutation,
              from: moment().subtract(2, "days").startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          case RelativeDateValue.OneWeekAgo: {
            mutation = {
              ...mutation,
              from: moment().subtract(1, "week").startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          case RelativeDateValue.TwoWeeksAgo: {
            mutation = {
              ...mutation,
              from: moment().subtract(2, "weeks").startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          case RelativeDateValue.AMonthAgo: {
            mutation = {
              ...mutation,
              from: moment().subtract(1, "month").startOf("day").toDate(),
              until: moment().endOf("day").toDate(),
            };
            break;
          }
          default: {
            break;
          }
        }
      }

      const response = await parseRequest.response(
        client.search(
          {
            skip: startIndex,
            take: stopIndex - startIndex,
            orderBy: order.sortColumn,
            sortOrder: order.sortOrder,
            locations: locationsFilter,
            mutation,
            realEstateAgencyForeignId,
            finance,
            availabilityStatuses: availabilityStatuses.value,
            isResidential,
            isCommercial,
            isAgricultural,
            propertyTypeFilters,
            locationPlaces: locationPlaces.value,
            energyClasses: energyClasses.value || energyClasses.emptyValue,
            facilities: facilitySearchTypeOptions.value,
            others,
            garage: {
              types: garageTypeOptions.value || garageTypeOptions.emptyValue,
              garageCapacityMin: garageCapacityValues.value.min,
              garageCapacityMax: garageCapacityValues.value.max,
            },
            evaluations: {
              maintenanceInsideMin: conditionOptionsInside.value,
              maintenanceOutsideMin: conditionOptionsOutside.value,
              isAccessibleForSeniors: othersAccesibility.includes(
                "isAccessibleForSeniors"
              )
                ? true
                : undefined,
              isAccessibleWithDisabilities: othersAccesibility.includes(
                "isAccessibleWithDisabilities"
              )
                ? true
                : undefined,
              isAdaptedHome: othersAccesibility.includes("isAdaptedHome")
                ? true
                : undefined,
            },

            ranges: {
              effectiveAreaMin: livingAreaValues.value.min,
              effectiveAreaMax: livingAreaValues.value.max,
              contentMin: contentValues.value.min,
              contentMax: contentValues.value.max,
              numberOfRoomsMin: noOfRoomsValues.value.min,
              numberOfRoomsMax: noOfRoomsValues.value.max,
              numberOfBedroomsMin: noOfBedroomsValues.value.min,
              numberOfBedroomsMax: noOfBedroomsValues.value.max,
              buildingYearMin: yearOfConstructionValues.value.min,
              buildingYearMax: yearOfConstructionValues.value.max,
              landAreaMin,
              landAreaMax,
              floorNumberMin: floorsValues.value.min,
              floorNumberMax: floorsValues.value.max,
              parkingPlacesMin,
              parkingPlacesMax,
              floorAreaMin: floorAreaValues.value.min,
              floorAreaMax: floorAreaValues.value.max,
            },
            outside: {
              hasRoofTerrace: outside.value.includes("hasRoofTerrace")
                ? outside.value.includes("hasRoofTerrace")
                : undefined,
              hasBalcony: outside.value.includes("hasBalcony")
                ? outside.value.includes("hasBalcony")
                : undefined,
              hasGarden: outside.value.includes("hasGarden")
                ? outside.value.includes("hasGarden")
                : undefined,
              gardenSurfaceMin: gardenPlotSizeValues.value.min,
              gardenSurfaceMax: gardenPlotSizeValues.value.max,
              gardenOrientations: orientationTypeValues.value,
            },
            groups: groups.value || groups.emptyValue,
            appClients: appClients.value || appClients.emptyValue,
            isFollowing: following.value.includes("isFollowing")
              ? true
              : undefined,
            offer: {
              isForSale: forSaleAndOrRent.value.includes("forSale")
                ? true
                : undefined,
              isForRent: forSaleAndOrRent.value.includes("forRent")
                ? true
                : undefined,
              isAuction: othersAccesibility.includes("isAuction")
                ? true
                : undefined,
              hasOpenHouse: othersAccesibility.includes("hasOpenHouse")
                ? true
                : undefined,
            },
          },
          realEstateAgencyId
        )
      );

      if (!response) throw new Error("No realestate properties found.");
      const { results, totalCount } = response;

      dispatch(MLSActions.List.updateList(startIndex, results, totalCount));
    } catch (error) {
      throw error;
    }
  };
};

export type ExtendedLocationSuggestionItem = LocationSuggestionItem & {
  type: LocationSuggestionType;
};

const queryLocationSuggestions = (term: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new SuggestionClient(mlsHost);
      const { culture } = state.main;
      const searchLocations = (
        state.mls.list.filters?.searchLocations?.value || []
      ).filter((d) => d?.min && d?.max);
      const postalCodeRanges = (
        state.mls.list.filters?.postalCodeRanges?.value || []
      ).filter((d) => d?.min && d?.max);
      const locations = [...searchLocations, ...postalCodeRanges];
      let request: LocationSuggestionRequest = { term, take: 25, culture };

      if (!!locations.length) {
        request = {
          ...request,
          query: {
            skip: 0,
            take: 25,
            locations: locations.map((searchLocation) => ({
              postalCodeMin: searchLocation.min,
              postalCodeMax: searchLocation.max,
            })),
          },
        };
      }

      const response = await parseRequest.response(
        client.locationSuggestions(request, realEstateAgencyId)
      );

      return (response.suggestions || []).reduce((state, suggestion) => {
        (suggestion.items || []).forEach((item) =>
          state.push({ ...item, type: suggestion.name })
        );
        return state;
      }, [] as ExtendedLocationSuggestionItem[]);
    } catch (error) {
      throw error;
    }
  };
};

const queryRealtorSuggestions = (term: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new SuggestionClient(mlsHost);

      const response = await parseRequest.response(
        client.realtorSuggestions({ term, take: 10 }, realEstateAgencyId)
      );

      if (!response) return [];
      return response.suggestions || [];
    } catch (error) {
      throw error;
    }
  };
};

const saveFilters = (name: string, filterConfig: FilterConfig, id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { accountSettings } = state.account;
      const client = new AccountSettingsClient(host);

      const customUserSettings =
        accountSettings?.customUserSettings !== ""
          ? (JSON.parse(accountSettings.customUserSettings) as {
              key: string;
              value: string;
            }[])
          : [];

      const otherFilters = customUserSettings.filter(
        (setting) => setting.key !== MLS.FILTER_SETTINGS_KEY
      );

      let mlsFilters = customUserSettings
        .filter((setting) => setting.key === MLS.FILTER_SETTINGS_KEY)
        .map((setting) => ({
          ...setting,
          value: JSON.parse(setting.value) as MlsFilter,
        }));

      const cleanedFilter = MlsUtil.cleanFilterConfig(filterConfig);

      // Check if filter exists
      let exists = false;
      mlsFilters = mlsFilters.map((mlsFilter) => {
        if (mlsFilter.value.id === id) {
          exists = true;
          return {
            ...mlsFilter,
            value: {
              ...mlsFilter.value,
              filters: cleanedFilter,
            },
          };
        }

        return mlsFilter;
      });

      if (!exists) {
        mlsFilters = [
          ...mlsFilters,
          {
            key: MLS.FILTER_SETTINGS_KEY,
            value: { name, id, filters: cleanedFilter },
          },
        ];
      }

      const flattenedMlsFilters = mlsFilters.map((filter) => ({
        ...filter,
        value: JSON.stringify(filter.value),
      }));
      const newCustomUserSettings = [...otherFilters, ...flattenedMlsFilters];

      const result = await client.save(
        {
          accountSettings: {
            ...state.account.accountSettings,
            customUserSettings: JSON.stringify(newCustomUserSettings),
          },
        },
        realEstateAgencyId
      );

      dispatch(AccountActions.setAccountSettings(result.accountSettings));
    } catch (error) {
      throw error;
    }
  };
};

const removeFilter = (filter: MlsFilter) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { accountSettings } = state.account;
      const client = new AccountSettingsClient(host);

      const customUserSettings =
        (JSON.parse(accountSettings.customUserSettings) as {
          key: string;
          value: string;
        }[]) || [];
      const otherFilters = customUserSettings.filter(
        (setting) => setting.key !== MLS.FILTER_SETTINGS_KEY
      );
      const mlsFilters = customUserSettings
        .filter((setting) => setting.key === MLS.FILTER_SETTINGS_KEY)
        .map((setting) => ({
          ...setting,
          value: JSON.parse(setting.value) as MlsFilter,
        }))
        .filter((setting) => setting.value.id !== filter.id);

      const flattenedMlsFilters = mlsFilters.map((filter) => ({
        ...filter,
        value: JSON.stringify(filter.value),
      }));
      const newCustomUserSettings = [...otherFilters, ...flattenedMlsFilters];

      const result = await client.save(
        {
          accountSettings: {
            ...state.account.accountSettings,
            customUserSettings: JSON.stringify(newCustomUserSettings),
          },
        },
        realEstateAgencyId
      );

      dispatch(AccountActions.setAccountSettings(result.accountSettings));
      dispatch(
        SnackbarActions.addToast({
          value: "mls.filter.toast.deleted",
          callback: async () => {
            const result = await client.save(
              {
                accountSettings,
              },
              realEstateAgencyId
            );

            dispatch(AccountActions.setAccountSettings(result.accountSettings));
          },
          callbackLabel: "mls.filter.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const mailSelection = (selectedIds: string[], intl: IntlShape) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { assignments } = state.mls.list;
      const selectedAssignments = assignments.filter(
        (assignment) => !!assignment && selectedIds.includes(assignment.id)
      );

      const emailAssignments = selectedAssignments.map((assignment) =>
        MlsUtil.mapPropertySearchItemToEmailAssignment(assignment, intl)
      );

      dispatch(EmailThunk.Main.mailAssignmentSelection(emailAssignments));
    } catch (error) {
      throw error;
    }
  };
};

const bulkFollow = (selectedIds: string[], intl: IntlShape) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { assignments } = state.mls.list;
      const selectedAssignments = assignments.filter(
        (assignment) => !!assignment && selectedIds.includes(assignment.id)
      );
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost);

      const validStatusTypes = [
        StatusType.AVAILABLE,
        StatusType.RENTED_UNDER_CONDITIONS,
        StatusType.SOLD_UNDER_CONDITIONS,
      ];
      const invalidItems = selectedAssignments.filter(
        (assignment) =>
          !validStatusTypes.includes(assignment?.propertyInfo?.availability)
      );
      const validItems = selectedAssignments.filter((assignment) =>
        validStatusTypes.includes(assignment?.propertyInfo?.availability)
      );

      invalidItems.forEach((item) => {
        const { propertyInfo, address } = item;

        if (!propertyInfo?.availability) return;

        const status = intl
          .formatMessage({
            id: `statusTypeOptions.${propertyInfo?.availability.toString()}`,
            defaultMessage: propertyInfo?.availability.toString(),
          })
          .toLowerCase();

        const houseNumber = address?.houseNumber;
        const houseNumberPostfix = address?.houseNumberAddendum;
        const postalCode = address?.postalCode;
        const streetname = address?.street?.name;
        const cityName = address?.locality?.name;

        const adressLine1 = [streetname, houseNumber, houseNumberPostfix]
          .filter((d) => !!d)
          .join(" ");
        const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

        const displayName =
          [adressLine1, adressLine2].filter((d) => !!d).join(", ") || "...";

        dispatch(
          SnackbarActions.addToast({
            value: "mls.listToast.bulkFollowError",
            values: { displayName, status },
            icon: "eye-slash",
          })
        );
      });

      if (!validItems.length) return;

      dispatch(
        SnackbarActions.addToast({
          value: "mls.listToast.startBulkFollow",
          icon: "eye",
        })
      );

      const promises = validItems.map((assignment) =>
        parseRequest.response(
          client.toggleFolow(
            {
              bundleId: assignment.bundleId,
              appClientKey: head(assignment.appClientKeys || []),
              isFollowing: true,
            },
            realEstateAgencyId
          )
        )
      );

      await Promise.all(promises);

      dispatch(
        SnackbarActions.addToast({
          value: "mls.listToast.completeBulkFollow",
          icon: "check",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateRealEstateProperty = (bundleId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { mlsHost } = state.appSettings;

    const ref = (state.mls.list.assignments || []).find(
      (assignment) => !!assignment && assignment.bundleId === bundleId
    );

    if (!ref) return;

    try {
      const client = new RealEstatePropertyClient(mlsHost);
      const assignments = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 1,
              bundleIds: [bundleId],
            },
            realEstateAgencyId
          )
          .then((response) => response.results),
        undefined,
        { displayError: false }
      );

      if (!assignments || !assignments.length) return;
      const snapshot = head(assignments);

      dispatch(MLSActions.List.updateItem(snapshot));
    } catch (error) {
      throw error;
    }
  };
};

const printRealEstateProperties = (
  properties: RealEstatePropertySearchItem[],
  intl: IntlShape
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId, name } =
        state.account.currentRealestateAgency;
      const { mlsHost } = state.appSettings;
      const client = new RealEstatePropertyClient(mlsHost);

      const promises = properties.map((property) => {
        const { bundleId: id, appClientKeys } = property;
        const source = head(appClientKeys);

        return parseRequest
          .response(client.read(id, source, realEstateAgencyId))
          .then((response) => response.realEstateProperty);
      });

      const realEstateProperties = await Promise.all(promises);
      const printable = MlsUtil.createPrintTemplate(
        realEstateProperties,
        name,
        intl
      );

      PrintJS({ printable, type: "raw-html" });
    } catch (error) {
      throw error;
    }
  };
};

const getAddressSuggestions = (
  startIndex: number = 0,
  stopIndex: number = 100,
  address: Address
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost);

      const mapAdressRequest = () => {
        const { houseNumber, houseNumberPostfix, postalCode, countryId } =
          address;

        return {
          postalCode,
          houseNumber,
          houseNumberPostfix,
          countryId,
        } as LocationQuery;
      };

      const response = await parseRequest.response(
        client.search(
          {
            skip: startIndex,
            take: stopIndex - startIndex,
            locations: [mapAdressRequest()],
          },
          realEstateAgencyId
        )
      );

      if (!response) throw new Error("No realestate properties found.");
      const { results } = response;

      return results;
    } catch (error) {
      throw error;
    }
  };
};

const getPropertiesById = (bundleIds: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      await abortSearch.process();

      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost, abortSearch.fetch());
      const { order } = state.mls.list;

      const response = await parseRequest.response(
        client.search(
          {
            skip: 0,
            take: bundleIds.length,
            orderBy: order.sortColumn,
            sortOrder: order.sortOrder,
            bundleIds,
          },
          realEstateAgencyId
        )
      );

      if (!response?.results)
        throw new Error("No realestate properties found.");
      return response?.results || [];
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getListItems,
  queryLocationSuggestions,
  queryRealtorSuggestions,
  saveFilters,
  removeFilter,
  mailSelection,
  bulkFollow,
  updateRealEstateProperty,
  printRealEstateProperties,
  getAddressSuggestions,
  getPropertiesById,
};
