import { SnackbarPortal } from "@haywork/portals";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { SnackbarContainerProps } from "./snackbar.container";
import Toast from "./toast";

const styles = require("./style.scss");

export type SnackbarComponentProps = {};
type Props = SnackbarComponentProps & SnackbarContainerProps;

export const SnackbarComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ toasts, removeToast }) => {
    if (!toasts || !toasts.length) return null;

    return (
      <SnackbarPortal>
        <div styleName="snackbar">
          {toasts.map((toast) => (
            <Toast toast={toast} removeToast={removeToast} key={toast.id} />
          ))}
        </div>
      </SnackbarPortal>
    );
  })
);
