import uniqBy from "lodash-es/uniqBy";

import {
  AppClientSnapShot,
  AppClientCategory,
  RemunerationCategory
} from "@haywork/api/authorization";
import { REQUEST } from "@haywork/constants";

import { AppClientActions, appClientActions } from "./app-clients.actions";

export interface AppClientsState {
  clientsStatus: string;
  clients: AppClientSnapShot[];
  specials: AppClientSnapShot[];
  clientCategories: AppClientCategory[];
  selectedClientCategories: AppClientCategory[];
  remunerationCategories: RemunerationCategory[];
  selectedRemunerationCategories: RemunerationCategory[];
}

const INITIAL_STATE: AppClientsState = {
  clientsStatus: REQUEST.IDLE,
  clients: [],
  specials: [],
  clientCategories: [],
  selectedClientCategories: [],
  remunerationCategories: [],
  selectedRemunerationCategories: []
};

export const appClientsReducer = (
  state: AppClientsState = INITIAL_STATE,
  action: AppClientActions
): AppClientsState => {
  switch (action.type) {
    case appClientActions.SetClientsStatus: {
      const { clientsStatus } = action;

      return {
        ...state,
        clientsStatus
      };
    }
    case appClientActions.SetClients: {
      const { clients, specials } = action;
      const clientCategories = uniqBy(clients, (client) => client.category).map(
        (client) => client.category
      );
      const remunerationCategories = uniqBy(
        clients,
        (client) => client.remunerationCategory
      ).map((client) => client.remunerationCategory);

      return {
        ...state,
        clients,
        specials,
        clientCategories,
        remunerationCategories,
        clientsStatus: REQUEST.SUCCESS
      };
    }
    case appClientActions.ToggleFilter: {
      const { category } = action;
      const idx = state.selectedClientCategories.indexOf(category);

      let selectedClientCategories = [...state.selectedClientCategories];
      if (idx !== -1) {
        selectedClientCategories = selectedClientCategories.filter(
          (selectedCategory) => selectedCategory !== category
        );
      } else {
        selectedClientCategories = [...selectedClientCategories, category];
      }

      return {
        ...state,
        selectedClientCategories
      };
    }
    case appClientActions.ToggleRemuneration: {
      const { category } = action;
      const idx = state.selectedRemunerationCategories.indexOf(category);

      let selectedRemunerationCategories = [
        ...state.selectedRemunerationCategories
      ];
      if (idx !== -1) {
        selectedRemunerationCategories = selectedRemunerationCategories.filter(
          (selectedCategory) => selectedCategory !== category
        );
      } else {
        selectedRemunerationCategories = [
          ...selectedRemunerationCategories,
          category
        ];
      }

      return {
        ...state,
        selectedRemunerationCategories
      };
    }
    case appClientActions.ClearFilters: {
      return {
        ...state,
        selectedClientCategories: [],
        selectedRemunerationCategories: []
      };
    }
    case appClientActions.ResetApp: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
