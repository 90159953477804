import { Account, SyncStatus } from "@haywork/api/mail";
import { MAINROUTES, REQUEST } from "@haywork/constants";
import {
  EmailAccountsContainer,
  EmailDetailContainer,
  EmailLayoutContainerProps,
  EmailListContainer,
  EmailReAuthenticateModalComponent,
  EmailWelcomeContainer,
} from "@haywork/modules/email";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Route, Switch } from "react-router-dom";
import { EmailAccountModalComponent } from "../account-modal/account-modal.component";
import { DetailEmptyState } from "../detail";

const styles = require("./layout.component.scss");

export interface EmailLayoutComponentProps {}
interface State {
  reAuthModalVisible: boolean;
  reAuthAccount: Account;
}
type Props = EmailLayoutComponentProps & EmailLayoutContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailLayoutComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onDeleteMessageHandler = this.onDeleteMessageHandler.bind(this);
    this.onReAuthModalCloseHandler = this.onReAuthModalCloseHandler.bind(this);

    this.state = {
      reAuthModalVisible: false,
      reAuthAccount: null,
    };
  }

  public render() {
    const accounts = this.props.accounts.results || [];
    const reAuthAccounts = accounts.filter(
      (account) => account.syncStatus === SyncStatus.InvalidCredentials
    );

    if (
      accounts.length === 0 &&
      this.props.accounts.status === REQUEST.SUCCESS
    ) {
      return <EmailWelcomeContainer />;
    }

    return (
      <div styleName="layout">
        {reAuthAccounts.length > 0 && (
          <div
            styleName="layout__warning"
            onClick={() =>
              this.setState({
                reAuthModalVisible: true,
                reAuthAccount: reAuthAccounts[0],
              })
            }
          >
            <i className="fa fa-info-circle" />
            <ResourceText
              resourceKey="emailReAuthenticateWarning"
              values={{ email: reAuthAccounts[0].emailAddress }}
              asHtml
            />
          </div>
        )}

        {/* <div styleName="layout__info">
        <i className="fa fa-sync-alt" />
        <ResourceText
          resourceKey="emailSyncingInfo"
        />
      </div> */}

        <div styleName="layout__columns">
          <div styleName="layout__account-column">
            <div styleName="header accounts">
              <ResourceText resourceKey="allEmailAccounts" />
            </div>
            <div styleName="body">
              <EmailAccountsContainer />
            </div>
          </div>
          <div styleName="layout__messages-column">
            <EmailListContainer onDeleteMessage={this.onDeleteMessageHandler} />
          </div>
          <div styleName="layout__detail-column">
            <Switch>
              <Route
                exact
                path={MAINROUTES.EMAIL.URI}
                component={EmailDetailContainer}
              />
              <Route
                render={() => (
                  <DetailEmptyState
                    name={this.props.employeeName}
                    hasAccount={this.props.hasAccount}
                    accountsStatus={this.props.accounts.status}
                    hasAccounts={this.props.accounts.total > 0}
                    onCreateNewEmail={this.props.createNewEmail}
                  />
                )}
              />
            </Switch>
          </div>
        </div>

        <EmailReAuthenticateModalComponent
          visible={this.state.reAuthModalVisible}
          account={this.state.reAuthAccount}
          onClose={this.onReAuthModalCloseHandler}
          onReAuthenticate={this.props.reAuthenticate}
        />

        <EmailAccountModalComponent
          visible={this.props.accountAddedVisibility}
          onClose={() => this.props.setAccountAddedVisibility(false)}
        />
      </div>
    );
  }

  private onDeleteMessageHandler() {
    if (!this.props.currentMessage || !this.props.currentAccount) return;
    this.props.deleteMessage(
      this.props.currentAccount.id,
      this.props.currentMessage
    );
  }

  private onReAuthModalCloseHandler() {
    this.setState(
      {
        reAuthModalVisible: false,
      },
      () =>
        setTimeout(() => {
          this.setState({
            reAuthAccount: null,
          });
        }, 500)
    );
  }
}
