import { Dispatch } from ".";

import { AccountActions, AppState } from "@haywork/stores";
import {
  FinancialAdministration,
  FinancialAdministrationsClient
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";

const parseRequest = new ParseRequest();

const updateFinancialAdministration = (
  financialAdministration: FinancialAdministration
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      AccountActions.setFinancialAdministrationState({
        financialAdministrationState: REQUEST.PENDING
      })
    );

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const FinancialAdministrations = new FinancialAdministrationsClient(host);

    try {
      const result = await parseRequest.response(
        FinancialAdministrations.save(
          { financialAdministration },
          realEstateAgencyId
        )
      );

      dispatch(
        AccountActions.updateFinancialAdministration({
          financialAdministration: result
        })
      );
    } catch (error) {
      dispatch(
        AccountActions.setFinancialAdministrationState({
          financialAdministrationState: REQUEST.ERROR
        })
      );
      throw error;
    }
  };
};

export const FinancialThunks = {
  updateFinancialAdministration
};
