import {
  TemplateDefinitionSnapShot,
  TemplateDefinition,
  TemplateDefinitionCategorySnapShot,
  MergeField
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";

import {
  EmailTemplatesActions,
  emailTemplatesActions
} from "./templates.actions";

export interface EmailTemplatesState {
  templates: TemplateDefinitionSnapShot[];
  templatesStatus: string;
  categories: TemplateDefinitionCategorySnapShot[];
  mergeFields: MergeField[];
  single: TemplateDefinition;
  singleStatus: string;
}

const INITIAL_STATE: EmailTemplatesState = {
  templates: [],
  templatesStatus: REQUEST.IDLE,
  categories: [],
  mergeFields: [],
  single: null,
  singleStatus: REQUEST.IDLE
};

export const emailTemplatesReducer = (
  state: EmailTemplatesState = INITIAL_STATE,
  action: EmailTemplatesActions
): EmailTemplatesState => {
  switch (action.type) {
    case emailTemplatesActions.SetTemplates: {
      const { templates } = action;

      return {
        ...state,
        templates,
        templatesStatus: REQUEST.SUCCESS
      };
    }
    case emailTemplatesActions.SetTemplatesStatus: {
      const { templatesStatus } = action;

      return {
        ...state,
        templatesStatus
      };
    }
    case emailTemplatesActions.SetCategories: {
      const { categories } = action;

      return {
        ...state,
        categories
      };
    }
    case emailTemplatesActions.SetSingle: {
      const { single } = action;

      return {
        ...state,
        single,
        singleStatus: REQUEST.SUCCESS
      };
    }
    case emailTemplatesActions.SetSingleStatus: {
      const { singleStatus } = action;

      return {
        ...state,
        singleStatus
      };
    }
    case emailTemplatesActions.SetMergeFields: {
      const { mergeFields } = action;

      return {
        ...state,
        mergeFields
      };
    }
    case emailTemplatesActions.DeleteTemplate: {
      const { id } = action;

      const templates = state.templates.filter(
        (template) => template.id !== id
      );

      return {
        ...state,
        templates
      };
    }
    default:
      return state;
  }
};
