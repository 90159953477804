export const RELATION_GROUPS = {
  APPEND_RELATION_GROUPS: "APPEND_RELATION_GROUPS",
  SET_RELATION_GROUPS: "SET_RELATION_GROUPS",
  SET_RELATION_GROUP: "SET_RELATION_GROUP",
  NEW_RELATION_GROUP: "NEW_RELATION_GROUP",
  EDIT_RELATION_GROUP: "EDIT_RELATION_GROUP",
  DELETE_RELATION_GROUP: "DELETE_RELATION_GROUP",
  UNDELETE_RELATION_GROUP: "UNDELETE_RELATION_GROUP",
  DELETE_FROM_STATE: "DELETE_FROM_STATE",
  SET_RELATION_GROUPS_STATUS: "SET_RELATION_GROUPS_STATUS",
  SET_RELATION_ADD_GROUPS_STATUS: "SET_RELATION_ADD_GROUPS_STATUS"
};
