import { ObjectAssignment, OpenHouse } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { injectIntl, WithIntlProps } from "react-intl";

const styles = require("./widgets.component.scss");

interface ResidentialSpecificationsProps {
  assignment: ObjectAssignment;
}
type Props = ResidentialSpecificationsProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class ResidentialSpecificationsComponent extends React.Component<Props> {
  public render() {
    const {
      usableArea,
      yearOfConstruction,
      numberOfBedRooms,
      numberOfRooms,
      energyLabel,
      parcelSurface,
      contents,
      forRent,
      rentOffer,
      openHouse
    } = this.props.assignment;

    return (
      <div styleName="residential-specs">
        <div styleName="inner">
          {this.renderSpecification(
            get(usableArea, "area"),
            "usableArea",
            "squareMeters"
          )}
          {this.renderSpecification(
            get(parcelSurface, "area"),
            "parcelSurface",
            "squareMeters"
          )}
          {this.renderSpecification(
            get(contents, "volume"),
            "contents",
            "cubicMeters"
          )}
          {this.renderSpecification(numberOfRooms, "numberOfRooms")}
          {this.renderSpecification(numberOfBedRooms, "numberOfBedrooms")}

          {!!yearOfConstruction && (
            <React.Fragment>
              {!!yearOfConstruction.constructionYear ? (
                <React.Fragment>
                  <div styleName="label">
                    <ResourceText resourceKey="constructionYear" />
                  </div>
                  <div styleName="number" data-cy="CY-constructionYear">
                    {yearOfConstruction.constructionYear}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div styleName="label">
                    <ResourceText resourceKey="constructionPeriod" />
                  </div>
                  <div styleName="number">
                    {!!yearOfConstruction.period && (
                      <ResourceText
                        masterKey="constructionPeriods"
                        resourceKey={yearOfConstruction.period.toString()}
                      />
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {!!energyLabel && !!energyLabel.energyClass && (
            <React.Fragment>
              <div styleName="label">
                <ResourceText resourceKey="energyClass" />
              </div>
              <div styleName="number">
                <ResourceText
                  masterKey="energyClassOptions"
                  resourceKey={energyLabel.energyClass.toString()}
                />
              </div>
            </React.Fragment>
          )}

          {!!forRent && has(rentOffer, "furnishing") && !!rentOffer.furnishing && (
            <React.Fragment>
              <div styleName="label">
                <ResourceText resourceKey="furnishing" />
              </div>
              <div styleName="number">
                <ResourceText
                  masterKey="furnishingOptions"
                  resourceKey={rentOffer.furnishing.toString()}
                />
              </div>
            </React.Fragment>
          )}

          {!!forRent &&
            has(rentOffer, "rentSpecifications") &&
            !!rentOffer.rentSpecifications.length && (
              <React.Fragment>
                <div styleName="label">
                  <ResourceText resourceKey="including" />
                </div>
                <div styleName="number">
                  {rentOffer.rentSpecifications
                    .map((spec) =>
                      this.props.intl.formatMessage({
                        id: `rentSpecifications.${spec.toString()}`
                      })
                    )
                    .join(", ")}
                </div>
              </React.Fragment>
            )}

          {has(openHouse, "openHouseDate") && (
            <React.Fragment>
              <div styleName="label">
                <ResourceText resourceKey="assignment.openHouse" />
              </div>
              <div styleName="number">{this.renderOpenHouse(openHouse)}</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  private renderOpenHouse(openHouse: OpenHouse) {
    let resourceKey;
    const date = openHouse.openHouseDate;
    const from = openHouse.openHouseStartTime || new Date();
    const untill = openHouse.openHouseEndTime || new Date();
    const dateOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric"
    };
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit"
    };

    switch (true) {
      case has(openHouse, "openHouseStartTime") &&
        has(openHouse, "openHouseEndTime"):
        resourceKey = "assignment.openHouse.fromUntill";
        break;
      case has(openHouse, "openHouseStartTime"):
        resourceKey = "assignment.openHouse.from";
        break;
      case has(openHouse, "openHouseEndTime"):
        resourceKey = "assignment.openHouse.untill";
        break;
      default:
        resourceKey = "assignment.openHouse.date";
        break;
    }

    return (
      <ResourceText
        resourceKey={resourceKey}
        values={{
          date: this.props.intl.formatDate(date, dateOptions),
          from: this.props.intl.formatDate(from, timeOptions),
          untill: this.props.intl.formatDate(untill, timeOptions)
        }}
      />
    );
  }

  private renderSpecification(
    specification: boolean | number,
    text: string,
    addition?: string
  ) {
    switch (typeof specification) {
      case "boolean":
        return this.renderBasicSpec(specification, text);
      case "number":
        return this.renderNumberSpec(specification, text, addition);
      default:
        return null;
    }
  }

  private renderBasicSpec(
    specification,
    text
  ): React.ReactElement<HTMLDivElement> {
    if (!specification) return null;
    return (
      <React.Fragment>
        <div styleName="icon">
          <i className="fal fa-fw fa-check" />
        </div>
        <div styleName="label" data-cy={"CY-" + text}>
          <ResourceText resourceKey={text} />
        </div>
      </React.Fragment>
    );
  }

  private renderNumberSpec(
    specificationNumber,
    text,
    addition?: string
  ): React.ReactElement<HTMLDivElement> {
    if (!specificationNumber || specificationNumber === 0) return null;
    return (
      <React.Fragment>
        <div styleName="label">
          <ResourceText resourceKey={text} />
        </div>
        <div styleName="number" data-cy={"CY-" + text}>
          {specificationNumber}
          {!!addition && <ResourceText resourceKey={addition} asHtml />}
        </div>
      </React.Fragment>
    );
  }
}

export const ResidentialSpecifications = injectIntl(
  ResidentialSpecificationsComponent
);
