import { WidgetEntityType } from "@haywork/api/authorization";
import { FolderTreeEntityType } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import Sidebar from "../../components/detail-sidebar";
import { AcquisitionDetailDossierScreenContainerProps } from "./dossier.container";
import get from "lodash-es/get";

const styles = require("./style.scss");

export type AcquisitionDetailDossierScreenProps = {};
type Props = AcquisitionDetailDossierScreenProps &
  AcquisitionDetailDossierScreenContainerProps;

export const AcquisitionDetailDossierScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ acquisition }) => {
    return (
      <div styleName="dossier">
        <div styleName="sidebar">
          <Sidebar />
        </div>
        <div styleName="body">
          <ErrorBoundary>
            <Dossier
              parentId={acquisition.id}
              id={get(acquisition.linkedFolderTree, "id")}
              subject={acquisition.publicReference}
              entityType={FolderTreeEntityType.AcquisitionAssignment}
              blocked={false}
              addNotes={true}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  })
);
