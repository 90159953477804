import { BatchItem, BatchResponseItem } from "@haywork/api/kolibri";
import { Account, UploadResponse } from "@haywork/api/mail";
import { BlobThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SelectDossierItemsModalComponent,
  SelectDossierItemsModalComponentProps
} from "./select-dossier-items-modal";

type StateProps = {
  currentAccount: string;
};
type DispatchProps = {
  createBatchDownload: (items: BatchItem[]) => Promise<BatchResponseItem[]>;
  uploadFilesFromUri: (
    accountId: string,
    files: BatchResponseItem[]
  ) => Promise<UploadResponse[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SelectDossierItemsModalComponentProps,
  AppState
> = (state) => {
  const { currentAccount } = state.emailV2.main;

  return {
    currentAccount
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SelectDossierItemsModalComponentProps
> = (dispatch) => ({
  createBatchDownload: (items: BatchItem[]) =>
    dispatch(BlobThunk.createBatchDownload(items)),
  uploadFilesFromUri: (accountId: string, files: BatchResponseItem[]) =>
    dispatch(BlobThunk.uploadFilesFromUri(accountId, files))
});

export type SelectDossierItemsModalContainerProps = StateProps & DispatchProps;
export const SelectDossierItemsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDossierItemsModalComponent);
