import {
  ActiveFilter,
  BusinessPartnerOrderByField,
  BusinessPartnersClient,
  PaidFilter,
  RelationGroupOrderByField,
  RelationGroupsClient,
  RelationGroupTermField,
  RelationOrderByField,
  RelationsClient,
  RelationsSearchResponse,
  RelationTermField,
  RelationType,
  SearchAssignmentOrderByField,
  SearchAssignmentsClient,
  SortOrder,
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import AbortController from "@haywork/services/abort.service";
import { store } from "@haywork/stores";
import { SearchAssignmentUtil } from "@haywork/util";

const parseRequest = new ParseRequest();

const abortRelationsSearchV3 = new AbortController();
const searchV3 = async (
  term: string,
  take: number = 25,
  terms?: RelationTermField[],
  activeFilter?: ActiveFilter,
  relationTypeFilter?: RelationType[],
  includeStatistics?: boolean,
  orderBy?: RelationOrderByField
) => {
  try {
    await abortRelationsSearchV3.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new RelationsClient(host, abortRelationsSearchV3.fetch());
    const filterByActive = activeFilter || ActiveFilter.ActiveOnly;
    const filterByRelationTypes = relationTypeFilter || [];
    const termFields = terms || [];

    return await parseRequest.response(
      client
        .search(
          {
            orderBy: orderBy || RelationOrderByField.DisplayName,
            includeStatistics: includeStatistics === false ? false : true,
            filterByActive,
            skip: 0,
            take,
            order: SortOrder.Ascending,
            term,
            termFields,
            filterByRelationTypes,
          },
          realEstateAgencyId
        )
        .then((response) => {
          response.results.map((r) => (r["extended"] = true));
          return response.results;
        })
    );
  } catch (error) {
    throw error;
  }
};

const abortRelationsSearch = new AbortController();
const search = async (
  term: string,
  realEstateAgencyId: string,
  take: number,
  host: string
) => {
  try {
    await abortRelationsSearch.process();

    const client = new RelationsClient(host, abortRelationsSearch.fetch());

    return await parseRequest.response(
      client.search(
        {
          orderBy: RelationOrderByField.DisplayName,
          includeStatistics: true,
          filterByActive: ActiveFilter.ActiveOnly,
          skip: 0,
          take,
          order: SortOrder.Ascending,
          term: term.trim(),
        },
        realEstateAgencyId
      )
    );
  } catch (error) {
    throw error;
  }
};

const abortRelationsQuerySearch = new AbortController();
const searchForQueryInput = async (
  term: string,
  termFields: RelationTermField[],
  filterByActive: ActiveFilter,
  filterByRelationTypes: RelationType[],
  realEstateAgencyId: string,
  take: number,
  host: string
): Promise<RelationsSearchResponse> => {
  try {
    await abortRelationsQuerySearch.process();

    const client = new RelationsClient(host, abortRelationsQuerySearch.fetch());

    return await parseRequest.response(
      client.search(
        {
          orderBy: RelationOrderByField.DisplayName,
          includeStatistics: false,
          filterByActive,
          skip: 0,
          take,
          order: SortOrder.Ascending,
          term,
          termFields,
          filterByRelationTypes,
        },
        realEstateAgencyId
      )
    );
  } catch (error) {
    throw error;
  }
};

const abortBusinessPartnerSearch = new AbortController();
const searchBusinessPartners = async (term: string, take: number = 15) => {
  try {
    await abortBusinessPartnerSearch.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new BusinessPartnersClient(
      host,
      abortBusinessPartnerSearch.fetch()
    );

    return await parseRequest.response(
      client
        .search(
          {
            orderBy: BusinessPartnerOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take,
            term,
          },
          realEstateAgencyId
        )
        .then((response) => response.results)
    );
  } catch (error) {
    throw error;
  }
};

const abortRelationGroupsSearch = new AbortController();
const searchRelationGroups = async (
  term: string,
  take: number = 25,
  activeFilter?: ActiveFilter,
  includeStatistics?: boolean
) => {
  try {
    await abortRelationGroupsSearch.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const filterByActive = activeFilter || ActiveFilter.ActiveOnly;
    const client = new RelationGroupsClient(
      host,
      abortRelationGroupsSearch.fetch()
    );

    return await parseRequest.response(
      client
        .search(
          {
            includeStatistics: includeStatistics === false ? false : true,
            orderBy: RelationGroupOrderByField.Name,
            filterByActive,
            skip: 0,
            take: 10,
            term,
            termFields: [RelationGroupTermField.Name],
            order: SortOrder.Descending,
          },
          realEstateAgencyId
        )
        .then((response) => response.results)
    );
  } catch (error) {
    throw error;
  }
};

const abortRelationsByGroupId = new AbortController();
const getRelationsByGroupId = async (id: string) => {
  try {
    await abortRelationsByGroupId.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const take = 100;
    const client = new RelationsClient(host, abortRelationsByGroupId.fetch());

    const response = await parseRequest.response(
      client.search(
        {
          includeStatistics: false,
          orderBy: RelationOrderByField.DisplayName,
          filterByActive: ActiveFilter.ActiveOnly,
          order: SortOrder.Ascending,
          skip: 0,
          take,
          filterByRelationGroupIds: [id],
        },
        realEstateAgencyId
      )
    );

    const { results, totalResults } = response;
    let relations = results || [];

    if (totalResults > take) {
      const times = Math.ceil((totalResults - take) / take);
      for (let i = 0; i < times; i++) {
        const skip = take * (i + 1);
        const response = await parseRequest.response(
          client
            .search(
              {
                includeStatistics: false,
                orderBy: RelationOrderByField.DisplayName,
                filterByActive: ActiveFilter.ActiveOnly,
                order: SortOrder.Ascending,
                skip,
                take,
                filterByRelationGroupIds: [id],
              },
              realEstateAgencyId
            )
            .then((response) => response.results)
        );

        relations = [...relations, ...response];
      }
    }

    return relations;
  } catch (error) {
    throw error;
  }
};

const abortSearchAssignmentSearch = new AbortController();
const abortSearchAssignmentRelationsSearch = new AbortController();
const searchRelationsForSearchAssignments = async (
  term: string,
  take: number = 10
) => {
  try {
    await abortSearchAssignmentSearch.process();
    await abortSearchAssignmentRelationsSearch.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const relationsClient = new RelationsClient(
      host,
      abortSearchAssignmentRelationsSearch.fetch()
    );
    const searchAssignmentsClient = new SearchAssignmentsClient(
      host,
      abortSearchAssignmentSearch.fetch()
    );

    const relationIds = await parseRequest.response(
      relationsClient
        .search(
          {
            includeStatistics: false,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take,
            term,
            termFields: [RelationTermField.DisplayName],
          },
          realEstateAgencyId
        )
        .then((response) => response.results.map((relation) => relation.id))
    );

    const searchAssignments = await parseRequest.response(
      searchAssignmentsClient
        .search(
          {
            orderBy: SearchAssignmentOrderByField.CreationDate,
            filterByPaid: PaidFilter.PaidOrNotPaid,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take,
            relationIds,
          },
          realEstateAgencyId
        )
        .then((response) =>
          response.results.map(SearchAssignmentUtil.extendName)
        )
    );

    return searchAssignments;
  } catch (error) {
    throw error;
  }
};

export const Relation = {
  search,
  searchForQueryInput,
  searchBusinessPartners,
  searchV3,
  searchRelationGroups,
  getRelationsByGroupId,
  searchRelationsForSearchAssignments,
};
