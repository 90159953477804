import { Employee, RelationSnapShot } from "@haywork/api/kolibri";
import {
  Account,
  CreateAccountRequest,
  PersonSettings,
  Provider,
  ProviderType,
} from "@haywork/api/mail";
import { AccountShareReference } from "@haywork/middleware/thunk/email";
import { FormReturnValue } from "@haywork/modules/form";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { Redirect } from "@haywork/services";
import differenceBy from "lodash-es/differenceBy";
import first from "lodash-es/first";
import isString from "lodash-es/isString";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AccountDataExchangeComponent } from "./account-data-exchange.component";
import { AccountDataIMAPComponent } from "./account-data-imap.component";
import { AccountDataOAuthComponent } from "./account-data-oauth.component";
import { AccountDataSimpleComponent } from "./account-data-simple.component";
import { AvailableAccounts } from "./available-accounts.component";
import { IncomingDataIMAPComponent } from "./incoming-data-imap.component";
import { Onboarding } from "./onboarding.component";
import { OutgoingDataIMAPComponent } from "./outgoing-data-imap.component";
import { SelectProviders } from "./select-provider.component";
import { EmailSharesComponent } from "./shares.component";

const styles = require("./create-modal.component.scss");

enum CreateState {
  Onboarding = "Onboarding",
  AvailableAccounts = "AvailableAccounts",
  SelectProvider = "SelectProvider",
  AccountDataExchange = "AccountDataExchange",
  AccountDataSimple = "AccountDataSimple",
  AccountDataOAuth = "AccountDataOAuth",
  AccountDataIMap = "AccountDataIMap",
  IncomingDataIMap = "IncomingDataIMap",
  OutgoingDataIMap = "OutgoingDataIMap",
  Shares = "Shares",
}

interface CreateModalComponentProps {
  visible: boolean;
  providers: Provider[];
  employee: Employee;
  redirectUri: string | null;
  accountData: CreateAccountRequest;
  createStatus: string;
  personSettings: PersonSettings;
  employees: RelationSnapShot[];
  accounts: Account[];
  onSimpleSubmit: (values: FormReturnValue) => Promise<Account>;
  onOAuthSubmit: (type: ProviderType) => Promise<Account>;
  onIMAPAccountChange: (values: FormReturnValue) => void;
  onIMAPAccountSubmit: () => Promise<Account>;
  onClose: () => void;
  clearAccountData: () => void;
  onSharesSubmit: (
    account: Account,
    snapshots: AccountShareReference[]
  ) => Promise<void>;
  getAccounts: () => void;
}
interface CreateModalComponentState {
  createState: CreateState;
  selectedProvider: Provider | null;
  modalTitle: string;
  modalTitleValues: object;
  createdAccount: Account;
}

@CSSModules(styles, { allowMultiple: true })
export class CreateModalComponent extends React.Component<
  CreateModalComponentProps,
  CreateModalComponentState
> {
  constructor(props) {
    super(props);

    let createState = CreateState.AvailableAccounts;
    if (!!this.props.personSettings) {
      const { numberOfAccountsPerCompanyTaken } = this.props.personSettings;
      if (numberOfAccountsPerCompanyTaken === 0) {
        createState = CreateState.Onboarding;
      }
    }

    this.state = {
      createState,
      selectedProvider: null,
      modalTitle: "addANewMailBox",
      modalTitleValues: undefined,
      createdAccount: undefined,
    };

    this.navigate = this.navigate.bind(this);
    this.openGmailVerification = this.openGmailVerification.bind(this);
    this.onProceedHandler = this.onProceedHandler.bind(this);
    this.onSelectProviderHandler = this.onSelectProviderHandler.bind(this);
    this.toSelectProviderRoute = this.toSelectProviderRoute.bind(this);
    this.onSimpleSubmitHandler = this.onSimpleSubmitHandler.bind(this);
    this.toSharesRoute = this.toSharesRoute.bind(this);
    this.toAccountDataIMap = this.toAccountDataIMap.bind(this);
    this.toIncomingDataIMap = this.toIncomingDataIMap.bind(this);
    this.onIMAPAccountSubmitHandler = this.onIMAPAccountSubmitHandler.bind(
      this
    );
    this.onSharesSubmitHandler = this.onSharesSubmitHandler.bind(this);
    this.openOffice365Verification = this.openOffice365Verification.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: CreateModalComponentProps
  ) {
    if (!nextProps) return;
    if (!!nextProps.visible && !this.props.visible) {
      let createState = CreateState.AvailableAccounts;
      let modalTitle = "addANewMailBox";

      if (!!nextProps.personSettings) {
        const { numberOfAccountsPerCompanyTaken } = nextProps.personSettings;
        if (numberOfAccountsPerCompanyTaken === 0) {
          createState = CreateState.Onboarding;
          modalTitle = "addANewMailBoxOnboarding";
        }
      }

      this.setState({
        createState,
        selectedProvider: null,
        modalTitle,
        modalTitleValues: undefined,
        createdAccount: undefined,
      });
    }

    if (nextProps.accounts.length !== this.props.accounts.length) {
      const diffs = differenceBy(
        nextProps.accounts,
        this.props.accounts,
        (account) => account.id
      );
      if (diffs.length > 0) {
        const createdAccount = first(diffs);
        this.setState({
          createdAccount,
          createState: CreateState.Shares,
          modalTitle: "emailSharesModalTitle",
          modalTitleValues: undefined,
        });
      }
    }
  }

  public render() {
    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader
          title={this.state.modalTitle}
          titleValues={this.state.modalTitleValues}
          close
        />
        <ModalBody noPadding>{this.renderBody()}</ModalBody>
      </Modal>
    );
  }

  private renderBody() {
    switch (this.state.createState) {
      case CreateState.Onboarding:
        return (
          <Onboarding
            onClose={this.props.onClose}
            onProceed={this.onProceedHandler}
          />
        );
      case CreateState.AvailableAccounts:
        return (
          <AvailableAccounts
            personSettings={this.props.personSettings}
            onCreateAccount={this.onProceedHandler}
          />
        );
      case CreateState.SelectProvider:
        return (
          <SelectProviders
            providers={this.props.providers}
            onSelectProvider={this.onSelectProviderHandler}
          />
        );
      case CreateState.AccountDataSimple:
        return (
          <AccountDataSimpleComponent
            createStatus={this.props.createStatus}
            provider={this.state.selectedProvider}
            accountName={this.props.employee.displayName}
            onBack={this.toSelectProviderRoute}
            onSubmit={this.onSimpleSubmitHandler}
            onDone={this.toSharesRoute}
          />
        );
      case CreateState.AccountDataExchange:
        return (
          <AccountDataExchangeComponent
            createStatus={this.props.createStatus}
            provider={this.state.selectedProvider}
            accountName={this.props.employee.displayName}
            onBack={this.toSelectProviderRoute}
            onSubmit={this.onSimpleSubmitHandler}
          />
        );
      case CreateState.AccountDataOAuth:
        return (
          <AccountDataOAuthComponent
            redirectUri={this.props.redirectUri}
            provider={this.state.selectedProvider}
          />
        );
      case CreateState.AccountDataIMap:
        return (
          <AccountDataIMAPComponent
            provider={this.state.selectedProvider}
            accountName={this.props.employee.displayName}
            accountData={this.props.accountData}
            onBack={this.toSelectProviderRoute}
            onChange={this.props.onIMAPAccountChange}
            onNext={() => this.navigate(CreateState.IncomingDataIMap)}
          />
        );
      case CreateState.IncomingDataIMap:
        return (
          <IncomingDataIMAPComponent
            accountData={this.props.accountData}
            onBack={this.toAccountDataIMap}
            onChange={this.props.onIMAPAccountChange}
            onNext={() => this.navigate(CreateState.OutgoingDataIMap)}
          />
        );
      case CreateState.OutgoingDataIMap:
        return (
          <OutgoingDataIMAPComponent
            createStatus={this.props.createStatus}
            accountData={this.props.accountData}
            onBack={this.toIncomingDataIMap}
            onChange={this.props.onIMAPAccountChange}
            onSubmit={this.onIMAPAccountSubmitHandler}
          />
        );
      case CreateState.Shares:
        return (
          <EmailSharesComponent
            employees={this.props.employees}
            onSubmit={this.onSharesSubmitHandler}
          />
        );
      default:
        return null;
    }
  }

  private onProceedHandler() {
    this.setState({
      createState: CreateState.SelectProvider,
      modalTitle: "whoIsYourEmailProvider",
    });
  }

  private async onSelectProviderHandler(provider: Provider) {
    let createState = CreateState.SelectProvider;

    switch (provider.type) {
      case ProviderType.Imap: {
        createState = CreateState.AccountDataIMap;
        break;
      }
      case ProviderType.Gmail: {
        this.openGmailVerification();
        break;
      }
      case ProviderType.Office365: {
        this.openOffice365Verification();
        break;
      }
      case ProviderType.Exchange: {
        createState = CreateState.AccountDataExchange;
        break;
      }
      default: {
        createState = CreateState.AccountDataSimple;
        break;
      }
    }

    this.setState({
      createState,
      selectedProvider: provider,
      modalTitle: "connectMailBoxType",
      modalTitleValues: { provider: provider.displayName },
    });
  }

  private async openGmailVerification() {
    const redirect = new Redirect();
    const url = await this.props.onOAuthSubmit(ProviderType.Gmail);

    if (isString(url)) {
      redirect.navigate(url);
      this.setState({
        createState: CreateState.AccountDataOAuth,
      });
    }
  }

  private async openOffice365Verification() {
    const redirect = new Redirect();
    const url = await this.props.onOAuthSubmit(ProviderType.Office365);

    if (isString(url)) {
      redirect.navigate(url);
      this.setState({
        createState: CreateState.AccountDataOAuth,
      });
    }
  }

  private navigate(createState: CreateState) {
    this.setState({
      createState,
    });
  }

  private toSelectProviderRoute() {
    this.setState({
      createState: CreateState.SelectProvider,
      selectedProvider: null,
    });
    this.props.clearAccountData();
  }

  private toSharesRoute() {
    this.setState({
      createState: CreateState.Shares,
      modalTitle: "emailSharesModalTitle",
      modalTitleValues: undefined,
    });
  }

  private toAccountDataIMap() {
    this.setState({
      createState: CreateState.AccountDataIMap,
    });
  }

  private toIncomingDataIMap() {
    this.setState({
      createState: CreateState.IncomingDataIMap,
    });
  }

  private async onSimpleSubmitHandler(values: FormReturnValue) {
    const createdAccount = await this.props.onSimpleSubmit(values);
    this.setState({
      createdAccount,
    });
    this.toSharesRoute();
    return createdAccount;
  }

  private async onIMAPAccountSubmitHandler() {
    const createdAccount = await this.props.onIMAPAccountSubmit();
    this.setState({
      createdAccount,
    });
    this.toSharesRoute();
  }

  private async onSharesSubmitHandler(
    snapshots: AccountShareReference[],
    from: string = ""
  ) {
    const account: Account = {
      ...this.state.createdAccount,
      sentFromName: from,
    };

    await this.props.onSharesSubmit(account, snapshots);
    this.props.onClose();
    this.setState({
      createdAccount: undefined,
    });
  }
}
