import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AcquistionAssignmentEditScreen from "../assignments-edit";
import AcquistionAssignmentDetailGeneralScreen from "../assignments-detail-general";
import { FeatureRoute } from "@haywork/modules/feature-switch";
import { FeatureNotReadyComponent } from "@haywork/modules/shared";
import Dossier from "../assignments-dossier";
import Timeline from "../assignments-timeline";
import Network from "../assignments-network";

const route = RouteUtil.mapStaticRouteValues;

const Dummy = () => <div>Dummy Component</div>;

type Props = { id: string };
export const AcquisitionAssignmentDetailRouting: FC<Props> = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(ACQUISITIONOBJECTROUTES.GENERAL.URI, { id })} />
        )}
      />
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.GENERAL.URI}
        component={AcquistionAssignmentDetailGeneralScreen}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT.URI}
        component={AcquistionAssignmentEditScreen}
      />
      <FeatureRoute
        path={ACQUISITIONOBJECTROUTES.TIMELINE.URI}
        exact
        component={Timeline}
        feature="ACQUISITION_OBJECT_MODULES"
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        path={ACQUISITIONOBJECTROUTES.NETWORK.URI}
        exact
        component={Network}
        feature="ACQUISITION_OBJECT_MODULES"
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        path={ACQUISITIONOBJECTROUTES.DOSSIER.URI}
        exact
        component={Dossier}
        feature="ACQUISITION_OBJECT_MODULES"
        featureComponent={FeatureNotReadyComponent}
      />
    </Switch>
  );
};
