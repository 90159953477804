import { AssignmentType, Bid, LinkedRelation } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { BidDossier, BidUtil } from "@haywork/util";
import * as React from "react";
import { PureComponent } from "react";
import * as CSSModules from "react-css-modules";
import { BidAction } from "../..";
import Dossier from "../bid-dossier";
import BidsModal, { BidsModalView } from "../bids-modal";
import { ObjectWidgetContainerProps } from "./object-widget.container";

const styles = require("./style.scss");

export type BidsObjectWidgetRef = {
  update: () => void;
};
export type ObjectWidgetComponentProps = {
  assignmentId: string;
  linkedVendors?: LinkedRelation[];
  widgetRef?: (ref: BidsObjectWidgetRef) => void;
};
type State = {
  loading: boolean;
  dossiers: BidDossier[];
  bidsModalView: BidsModalView | null;
  bidsModalVisible: boolean;
  bidsModalBid: Bid | null;
  bidsModalOriginalBid: Bid | null;
  bidsModalDossier: BidDossier | null;
};
type Props = ObjectWidgetComponentProps & ObjectWidgetContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectWidgetComponent extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dossiers: [],
      bidsModalView: null,
      bidsModalVisible: false,
      bidsModalBid: null,
      bidsModalOriginalBid: null,
      bidsModalDossier: null
    };

    this.loadInitialData = this.loadInitialData.bind(this);
    this.onAction = this.onAction.bind(this);
    this.createNewBid = this.createNewBid.bind(this);
    this.onCloseBidsModal = this.onCloseBidsModal.bind(this);
    this.onUpdatedBidsModal = this.onUpdatedBidsModal.bind(this);
    this.readBid = this.readBid.bind(this);
  }

  public componentDidMount() {
    this.loadInitialData();

    if (!!this.props.widgetRef) {
      this.props.widgetRef({
        update: this.createNewBid
      });
    }
  }

  public render() {
    const { dossiers } = this.state;
    return (
      <>
        {!!dossiers.length && (
          <div styleName="bids">
            <h2 styleName="title">
              <I18n value="bids.widget.title.object" />
            </h2>

            <div styleName="dossiers">
              {this.state.dossiers.map((dossier) => (
                <Dossier
                  dossier={dossier}
                  key={dossier.id}
                  onAction={this.onAction}
                />
              ))}
            </div>
          </div>
        )}
        <BidsModal
          assignmentId={this.props.assignmentId}
          view={this.state.bidsModalView}
          visible={this.state.bidsModalVisible}
          bid={this.state.bidsModalBid}
          originalBid={this.state.bidsModalOriginalBid}
          typeOfAssignment={AssignmentType.Object}
          linkedVendors={this.props.linkedVendors || []}
          dossier={this.state.bidsModalDossier}
          onClose={this.onCloseBidsModal}
          onUpdated={this.onUpdatedBidsModal}
        />
      </>
    );
  }

  private async loadInitialData(forceReload = false) {
    const { loading, dossiers } = this.state;
    const { getAllBids, assignmentId } = this.props;

    if (loading || (!!dossiers.length && !forceReload)) return;
    try {
      this.setState({ loading: true });
      const bids = await getAllBids(assignmentId);
      const dossiers = BidUtil.mapBidSnapShotsToDossiers(bids);

      let bidsModalDossier = this.state.bidsModalDossier;
      if (!!bidsModalDossier) {
        bidsModalDossier =
          dossiers.find((dossier) => dossier.id === bidsModalDossier.id) ||
          bidsModalDossier;
      }

      this.setState({
        dossiers,
        bidsModalDossier
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  private async onAction(dossier: BidDossier, action: BidAction) {
    const originalBid = await this.readBid(dossier.latestBid.id);

    switch (action) {
      case BidAction.Accept: {
        this.setState({
          bidsModalBid: originalBid,
          bidsModalView: "accept",
          bidsModalVisible: true
        });
        return;
      }
      case BidAction.Edit: {
        this.setState({
          bidsModalBid: originalBid,
          bidsModalView: "edit",
          bidsModalVisible: true
        });
        return;
      }
      case BidAction.CounterOffer: {
        const bid = await this.props.createCounterOffer(
          originalBid,
          originalBid.linkedRelations
        );
        this.setState({
          bidsModalBid: bid,
          bidsModalOriginalBid: originalBid,
          bidsModalView: "create",
          bidsModalVisible: true
        });
        return;
      }
      case BidAction.Reject: {
        this.setState({
          bidsModalBid: originalBid,
          bidsModalView: "reject",
          bidsModalVisible: true
        });
        return;
      }
      case BidAction.ShowAllBids: {
        this.setState({
          bidsModalDossier: dossier,
          bidsModalView: "dossier",
          bidsModalVisible: true
        });
        return;
      }
      default: {
        return;
      }
    }
  }

  private async readBid(id: string) {
    return await this.props.readBid(id);
  }

  private async createNewBid() {
    const bid = await this.props.createNewBid(
      this.props.assignmentId,
      this.props.linkedEmployee
    );

    this.setState({
      bidsModalBid: bid,
      bidsModalView: "create",
      bidsModalVisible: true
    });
  }

  private onCloseBidsModal() {
    this.setState({
      bidsModalBid: null,
      bidsModalView: null,
      bidsModalOriginalBid: null,
      bidsModalDossier: null,
      bidsModalVisible: false
    });
  }

  private onUpdatedBidsModal(close: boolean) {
    if (close) this.onCloseBidsModal();
    this.loadInitialData(true);
  }
}
