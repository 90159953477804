import {
  RealEstateGroup,
  SearchAssignment,
  TypePART
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import { injectIntl, WithIntlProps } from "react-intl";

const styles = require("./details.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentWidgetDetailsProps {
  searchAssignment: SearchAssignment;
}
type Props = SearchAssignmentWidgetDetailsProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class SearchAssignmentWidgetDetails extends React.Component<Props> {
  public render() {
    const {
      offerType,
      id,
      priceStart,
      priceEnd,
      typesPART,
      apartmentTypes,
      houseTypes,
      typeParking,
      typesBOG,
      typesALV,
      typesPARTOther,
      typesALVOther,
      isPaid
    } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.EDIT_WHAT.URI, { id });
    const realEstateGroup =
      this.props.searchAssignment.realEstateGroup ||
      RealEstateGroup.Residential;

    return (
      <div>
        <h2>
          <ResourceText
            masterKey="realEstateGroups"
            resourceKey={realEstateGroup.toString()}
          />
        </h2>

        <div styleName="row">
          <div styleName="row__label">
            <ResourceText resourceKey="searchAssignment.value.isPaid" />
          </div>
          <div styleName="row__value">
            {!!isPaid ? (
              <ResourceText resourceKey="yes" />
            ) : (
              <ResourceText resourceKey="no" />
            )}
          </div>
        </div>

        {(!!priceStart || !!priceEnd) && (
          <div styleName="row">
            <div styleName="row__label">
              <ResourceText
                masterKey="offerTypeOptions"
                resourceKey={offerType.toString()}
              />
            </div>
            <div styleName="row__value">{this.renderPriceRange()}</div>
          </div>
        )}

        {realEstateGroup === RealEstateGroup.Residential &&
          typesPART &&
          typesPART.indexOf(TypePART.ResidentialBuilding) !== -1 &&
          houseTypes &&
          houseTypes.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText
                  masterKey="typePARTOptions"
                  resourceKey={TypePART.ResidentialBuilding.toString()}
                />
              </div>
              <div styleName="row__value">
                {this.renderTypes(houseTypes, "houseTypes")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Residential &&
          typesPART &&
          typesPART.indexOf(TypePART.Apartment) !== -1 &&
          apartmentTypes &&
          apartmentTypes.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText
                  masterKey="typePARTOptions"
                  resourceKey={TypePART.Apartment.toString()}
                />
              </div>
              <div styleName="row__value">
                {this.renderTypes(apartmentTypes, "apartmentSorts")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Residential &&
          typesPART &&
          typesPART.indexOf(TypePART.Parking) !== -1 &&
          typeParking &&
          typeParking.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText
                  masterKey="typePARTOptions"
                  resourceKey={TypePART.Parking.toString()}
                />
              </div>
              <div styleName="row__value">
                {this.renderTypes(typeParking, "parkingTypeOptions")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Residential &&
          typesPART &&
          typesPART.indexOf(TypePART.Other) !== -1 &&
          typesPARTOther &&
          typesPARTOther.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText
                  masterKey="typePARTOptions"
                  resourceKey={TypePART.Other.toString()}
                />
              </div>
              <div styleName="row__value">
                {this.renderTypes(typesPARTOther, "typePARTOtherOptions")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Commercial &&
          typesBOG &&
          typesBOG.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText resourceKey="types" />
              </div>
              <div styleName="row__value">
                {this.renderTypes(typesBOG, "typeBOGOptions")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Agricultural &&
          typesALV &&
          typesALV.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText resourceKey="types" />
              </div>
              <div styleName="row__value">
                {this.renderTypes(typesALV, "typeALVOptions")}
              </div>
            </div>
          )}

        {realEstateGroup === RealEstateGroup.Agricultural &&
          typesALVOther &&
          typesALVOther.length > 0 && (
            <div styleName="row">
              <div styleName="row__label">
                <ResourceText resourceKey="other" />
              </div>
              <div styleName="row__value">
                {this.renderTypes(typesALVOther, "typeALVOtherOptions")}
              </div>
            </div>
          )}

        {!this.props.searchAssignment.foreignId && (
          <p>
            <Link to={url}>
              <ResourceText resourceKey="change" />
            </Link>
          </p>
        )}
      </div>
    );
  }

  private renderPriceRange(): JSX.Element {
    const { priceStart, priceEnd } = this.props.searchAssignment;
    const currencyFormat = { style: "currency", currency: "EUR" };

    if (!!priceStart && !!priceEnd) {
      const min = this.props.intl.formatNumber(priceStart, currencyFormat);
      const max = this.props.intl.formatNumber(priceEnd, currencyFormat);
      return (
        <ResourceText
          resourceKey="searchAssignmentPriceMinMax"
          values={{ min, max }}
        />
      );
    }

    if (!!priceStart) {
      const min = this.props.intl.formatNumber(priceStart, currencyFormat);
      return (
        <ResourceText resourceKey="searchAssignmentPriceMin" values={{ min }} />
      );
    }

    if (!!priceEnd) {
      const max = this.props.intl.formatNumber(priceEnd, currencyFormat);
      return (
        <ResourceText resourceKey="searchAssignmentPriceMax" values={{ max }} />
      );
    }

    return null;
  }

  private renderTypes(types: any[], masterKey?: string): string {
    return types
      .map((t) => {
        const resource = !!masterKey
          ? `${masterKey}.${t.toString()}`
          : t.toString();
        return this.props.intl.formatMessage({
          id: resource,
          defaultMessage: resource
        });
      })
      .join(", ");
  }
}

export const SearchAssignmentWidgetDetailsComponent = injectIntl(
  SearchAssignmentWidgetDetails
);
