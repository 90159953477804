import I18n from "@haywork/components/i18n";
import { ModalPortal } from "@haywork/portals";
import * as React from "react";
import {
  CSSProperties,
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as CssModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  parentRef: MutableRefObject<HTMLElement>;
  label: string;
  onlyOnParentOverflow?: boolean;
};

export const HintComponent: FC<Props> = memo(
  CssModules(styles, { allowMultiple: true })(
    ({ parentRef, label, onlyOnParentOverflow }) => {
      const [visible, setVisible] = useState(false);
      const showTimer = useRef<any>(null);
      const ref = useRef<HTMLDivElement>(null);
      const [styles, setStyles] = useState<CSSProperties>({});

      const renderStyles = useCallback(() => {
        if (!parentRef.current || !ref.current) {
          setStyles({});
          return;
        }

        const {
          top: parentTop,
          left: parentLeft,
          width: parentWidth,
          height: parentHeight,
        } = parentRef.current.getBoundingClientRect();
        const { width } = ref.current.getBoundingClientRect();

        setStyles({
          position: "absolute",
          top: parentTop + parentHeight + 8,
          left: parentLeft + parentWidth / 2 - width / 2,
          visibility: "visible",
        });
      }, [setStyles]);

      const handleMouseEnter = useCallback(() => {
        if (!parentRef.current) return;

        let show = true;
        if (!!onlyOnParentOverflow) {
          const { width } = parentRef.current.getBoundingClientRect();
          const { scrollWidth } = parentRef.current;
          show = Math.round(scrollWidth) > Math.round(width);
        }

        if (show) {
          showTimer.current = setTimeout(() => setVisible(true), 500);
        }
      }, [setVisible, showTimer, onlyOnParentOverflow]);

      const handleMouseLeave = useCallback(() => {
        setVisible(false);
        if (!!showTimer.current) {
          clearTimeout(showTimer.current);
        }
      }, [setVisible, showTimer]);

      useEffect(() => {
        if (!!parentRef.current) {
          parentRef.current.addEventListener("mouseenter", handleMouseEnter);
          parentRef.current.addEventListener("mouseleave", handleMouseLeave);
        }

        return () => {
          if (!!parentRef.current) {
            parentRef.current.removeEventListener(
              "mouseenter",
              handleMouseEnter
            );
            parentRef.current.removeEventListener(
              "mouseleave",
              handleMouseLeave
            );
          }
        };
      }, [handleMouseEnter, handleMouseLeave]);

      useEffect(() => {
        renderStyles();
      }, [visible, label, renderStyles]);

      if (!visible) return null;

      return (
        <ModalPortal>
          <div ref={ref} style={styles} styleName="hint">
            <div styleName="hint__label">
              <I18n value={label} />
            </div>
          </div>
        </ModalPortal>
      );
    }
  )
);
