import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import * as React from "react";
import { CSSProperties, memo, FC, MouseEvent, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { InfoBarContainerProps } from "./container";

const styles = require("./style.scss");

export type InfoBarComponentProps = {
  style?: CSSProperties;
  count?: number;
  onClick: () => void;
};
type Props = InfoBarComponentProps & InfoBarContainerProps;

export const InfoBarComponent: FC<Props> = memo(
  CSSModules(styles, {
    allowMultiple: true,
  })(({ style, onClick, count }) => {
    const click = useCallback(() => {
      close();
      onClick();
    }, [onClick, close]);

    return (
      <div style={style} styleName="wrapper" className="alert alert-info">
        <div onClick={click}>
          <I18n value="infoBar.message" values={{ count }} asHtml />
        </div>
      </div>
    );
  })
);
