import { combineReducers } from "redux";
import { InvoiceActions } from "./invoice.actions";
import {
  InvoiceOverviewState,
  invoiceOverviewReducer,
  InvoiceOverviewActions,
  InvoiceFiltering
} from "./overview";
import {
  SingleInvoiceState,
  singleInvoiceReducer
} from "./invoice-single.reducer";
import {
  InvoiceListState,
  invoiceListReducer,
  InvoiceListActions
} from "./list";

export * from "./invoice.types";

export interface InvoiceState {
  overview: InvoiceOverviewState;
  single: SingleInvoiceState;
  list: InvoiceListState;
}

export const invoiceReducer = combineReducers({
  overview: invoiceOverviewReducer,
  single: singleInvoiceReducer,
  list: invoiceListReducer
});

export {
  InvoiceActions,
  InvoiceOverviewActions,
  InvoiceFiltering,
  InvoiceListActions
};
