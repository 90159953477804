import { Dispatch } from ".";
import { AppState, EventCenterActions } from "@haywork/stores";
import { ParseRequest } from "@haywork/services";
import { MessagesClient, LinkedRelation, Message } from "@haywork/api/kolibri";

const parseRequest = new ParseRequest();

const getMessage = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Messages = new MessagesClient(host);

    try {
      return await parseRequest.response(
        Messages.read(id, realEstateAgencyId).then(
          (response) => response.message
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateMessageWithLinkedRelation = (
  id: string,
  linkedRelation: LinkedRelation
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new MessagesClient(host);

    try {
      const message = await dispatch(getMessage(id));
      if (!message) return;

      const mergedMessage = {
        ...message,
        linkedRelation
      } as Message;

      await parseRequest.response(
        client.save({ message: mergedMessage }, realEstateAgencyId)
      );
      dispatch(
        EventCenterActions.Notifications.setForceReload({ forceReload: true })
      );
    } catch (error) {
      throw error;
    }
  };
};

export const MessageThunk = {
  getMessage,
  updateMessageWithLinkedRelation
};
