import { ActionType, ActionTypes } from "./actions";
import { Account } from "@haywork/api/mail";
import uniqBy from "lodash-es/uniqBy";

export type State = {
  accounts: Account[];
};

const INITIAL: State = {
  accounts: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetAccounts: {
      const { accounts } = action;

      return {
        ...state,
        accounts,
      };
    }
    case ActionType.UpdateAccount: {
      const { account } = action;
      const accounts = state.accounts.map((ref) =>
        ref.id === account.id ? account : ref
      );

      return {
        ...state,
        accounts,
      };
    }
    case ActionType.RemoveAccount: {
      const { id } = action;
      const accounts = state.accounts.filter((ref) => ref.id !== id);

      return {
        ...state,
        accounts,
      };
    }
    case ActionType.SetAccountSyncStatus: {
      const { accountId, syncStatus } = action;
      const accounts = state.accounts.map((account) =>
        account.id !== accountId ? account : { ...account, syncStatus }
      );

      return {
        ...state,
        accounts,
      };
    }
    case ActionType.AppendAccount: {
      const { account } = action;
      const accounts = uniqBy(
        [account, ...state.accounts],
        (account) => account.id
      );

      return {
        ...state,
        accounts,
      };
    }
    case ActionType.Clear:
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
