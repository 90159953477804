import {
  AssignmentSnapShot,
  AssignmentType,
  RentCondition,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Column, Row } from "@haywork/components/ui/list";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import classNames from "classnames";
import head from "lodash-es/head";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber, useIntl } from "react-intl";
import LoadingState from "../loading-state";

const styles = require("./style.scss");

type Props = {
  data: AssignmentSnapShot | null;
  zebra: boolean;
  onNavigate: (assignment: AssignmentSnapShot) => void;
};

export const RowComponent: FC<Props> = memo(({ data, zebra, onNavigate }) => {
  return !data ? (
    <LoadingState zebra={zebra} />
  ) : (
    <DataRowComponent data={data} zebra={zebra} onNavigate={onNavigate} />
  );
});

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ data, zebra, onNavigate }) => {
    const {
      photo1Url,
      photo2Url,
      photo3Url,
      totalNrOfPhotos,
      realEstateGroup,
      forSale,
      listingType,
      keyNr,
      publicReference,
      typeOfAssignment,
      salePrice,
      salePriceMax,
      saleCommissionTotal,
      rentPrice,
      rentPriceMax,
      rentCommissionTotal,
      assignmentPhase,
      availabilityStatus,
      saleCondition,
      rentCondition,
      constructionNumberString,
      isActive,
      rentPricePerUnit,
      salePricePerUnit,
    } = data;

    const intl = useIntl();

    const thumnbail = useMemo(() => {
      return (
        head([photo1Url, photo2Url, photo3Url].filter((photo) => !!photo)) ||
        null
      );
    }, [photo1Url, photo2Url, photo3Url]);

    const numberOfPhotos = useMemo(() => {
      return totalNrOfPhotos || 0;
    }, [totalNrOfPhotos]);

    const pillsRow = useMemo(() => {
      if (
        !keyNr &&
        !publicReference &&
        typeOfAssignment !== AssignmentType.Project &&
        !constructionNumberString &&
        isActive
      )
        return null;

      return (
        <div styleName="info__meta">
          {typeOfAssignment === AssignmentType.Project && (
            <Pill label={typeOfAssignment.toString()} color={Colors.Primary} />
          )}
          {!!publicReference && (
            <Pill label={publicReference} color={Colors.Warning} />
          )}
          {!!constructionNumberString && (
            <Pill
              icon="home"
              label={constructionNumberString}
              labelValues={{ value: constructionNumberString }}
              color={Colors.Primary}
            />
          )}
          {!isActive && <Pill label="archived" color={Colors.Gray} />}
          {!!keyNr && (
            <Pill
              label={keyNr.toString()}
              color={Colors.Info}
              textColor="#805e00"
              icon="key"
              solid
            />
          )}
        </div>
      );
    }, [
      typeOfAssignment,
      keyNr,
      publicReference,
      constructionNumberString,
      isActive,
    ]);

    const priceRow = useMemo(() => {
      if (!forSale) {
        switch (true) {
          case !!rentPricePerUnit: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.rentPrice" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.pricePerUnit"
                    values={{ price: rentPricePerUnit }}
                    asHtml
                  />
                </div>
              </div>
            );
          }
          case !!rentPrice && !!rentPriceMax: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.priceMinMax"
                    values={{ priceMin: rentPrice, priceMax: rentPriceMax }}
                  />
                </div>
              </div>
            );
          }
          case !!rentPrice &&
            !!rentCondition &&
            rentCondition !== RentCondition.PricePerContract: {
            const condition = intl.formatMessage({
              id: `rentConditions.${rentCondition.toString()}`,
              defaultMessage: rentCondition.toString(),
            });

            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.rentPriceWithCondition"
                    values={{ price: rentPrice, condition }}
                  />
                </div>
              </div>
            );
          }
          case !!rentPrice: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <FormattedNumber
                    value={rentPrice}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </div>
            );
          }
          default: {
            return null;
          }
        }
      } else {
        switch (true) {
          case !!salePricePerUnit: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.pricePerUnit"
                    values={{ price: salePricePerUnit }}
                    asHtml
                  />
                </div>
              </div>
            );
          }
          case !!salePrice && !!salePriceMax: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.priceMinMax"
                    values={{ priceMin: salePrice, priceMax: salePriceMax }}
                  />
                </div>
              </div>
            );
          }
          case !!salePrice && !!saleCondition: {
            const condition = intl.formatMessage({
              id: `saleConditions.${saleCondition.toString()}`,
              defaultMessage: saleCondition.toString(),
            });

            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <I18n
                    value="assignments.listItem.salePriceWithCondition"
                    values={{ price: salePrice, condition }}
                  />
                </div>
              </div>
            );
          }
          case !!salePrice: {
            return (
              <div styleName="price-and-commission__row">
                <div styleName="title">
                  <I18n value="assignments.listItem.title.price" />
                </div>
                <div styleName="value">
                  <FormattedNumber
                    value={salePrice}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </div>
            );
          }
          default: {
            return null;
          }
        }
      }
    }, [
      forSale,
      salePrice,
      salePriceMax,
      saleCondition,
      rentPrice,
      rentPriceMax,
      rentCondition,
      rentPricePerUnit,
      salePricePerUnit,
    ]);

    const commissionRow = useMemo(() => {
      if (!forSale) {
        return !rentCommissionTotal ? null : (
          <div styleName="price-and-commission__row">
            <div styleName="title">
              <I18n value="assignments.listItem.title.commission" />
            </div>
            <div styleName="value">
              <FormattedNumber
                value={rentCommissionTotal}
                style="currency"
                currency="EUR"
              />
            </div>
          </div>
        );
      } else {
        return !saleCommissionTotal ? null : (
          <div styleName="price-and-commission__row">
            <div styleName="title">
              <I18n value="assignments.listItem.title.commission" />
            </div>
            <div styleName="value">
              <FormattedNumber
                value={saleCommissionTotal}
                style="currency"
                currency="EUR"
              />
            </div>
          </div>
        );
      }
    }, [forSale, saleCommissionTotal, rentCommissionTotal]);

    return (
      <Row zebra={zebra} onClick={() => onNavigate(data)}>
        <Column index={0}>
          <div
            styleName={classNames("thumbnail__wrapper", {
              stacked: totalNrOfPhotos > 1,
            })}
          >
            <div
              styleName={classNames("thumbnail", {
                // stacked: totalNrOfPhotos > 1
              })}
              style={{
                backgroundImage: !thumnbail
                  ? null
                  : `url(${JSON.stringify(thumnbail)})`,
              }}
            >
              {!thumnbail && (
                <Icon
                  name="camera-alt"
                  color={Colors.MediumGray}
                  size={24}
                  light
                />
              )}
              {/* {numberOfPhotos > 1 && (
                <div styleName="thumbnail__count">{numberOfPhotos}</div>
              )} */}
            </div>
          </div>
        </Column>
        <Column index={1}>
          <div styleName="info">
            {pillsRow}
            <div>
              <I18n
                prefix="realEstateGroups"
                value={realEstateGroup.toString()}
              />
              , <I18n value={!forSale ? "forRent" : "forSale"} />
            </div>
            {!!data.displayName && (
              <div styleName="info__name">{data.displayName}</div>
            )}
            {!!listingType && (
              <div styleName="info__listing">
                <I18n prefix="listingTypes" value={listingType.toString()} />
              </div>
            )}
          </div>
        </Column>
        <Column index={2}>
          <div styleName="price-and-commission">
            {priceRow}
            {commissionRow}
          </div>
        </Column>
        <Column index={3}>
          <div styleName="status">
            {!!assignmentPhase && (
              <div styleName="assignment-phase">
                <I18n
                  prefix="assignmentPhases"
                  value={assignmentPhase.toString()}
                />
              </div>
            )}
            {!!availabilityStatus && (
              <div styleName="availability-status">
                <I18n
                  prefix="availabilityStatuses"
                  value={availabilityStatus.toString()}
                />
              </div>
            )}
          </div>
        </Column>
      </Row>
    );
  })
);
