import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { FilterConfig } from "@haywork/components/ui/list";
import { Colors } from "@haywork/enum/colors";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import { Input } from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useState, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { SaveFilterContainerProps } from "./container";
import { v4 as uuid } from "uuid";
import { MlsUtil } from "@haywork/util";

const styles = require("./style.scss");
const equals = require("react-fast-compare");

export type SaveFilterProps = {
  visible: boolean;
  data: {
    savedFilters: MlsFilter[];
    filters: FilterConfig;
  };
  onClose: () => void;
};

type Props = SaveFilterProps & SaveFilterContainerProps;

export const SaveFilterComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, onClose, data, saveFilters }) => {
      const { savedFilters, filters } = data;
      const [filterName, setFilterName] = useState("");
      const [matchingFilter, setMatchingFilter] = useState<MlsFilter | null>(
        null
      );
      const [loading, setLoading] = useState(false);
      const [overwrite, setOverwrite] = useState(false);
      const [duplicate, setDuplicate] = useState(false);

      const matchesExistingFilterName = useMemo(() => {
        if (!filterName) return false;
        let matches = false;
        setMatchingFilter(null);
        setOverwrite(false);

        savedFilters.forEach((filter) => {
          if (!matches) {
            matches =
              filterName.toLowerCase().trim() ===
              filter.name.toLowerCase().trim();
            if (!!matches) {
              setMatchingFilter(filter);
            }
          }
        });

        return matches;
      }, [filterName, savedFilters, setMatchingFilter, setOverwrite]);

      const matchesExistingFilter = useMemo(() => {
        let matches = false;
        const match = MlsUtil.cleanFilterConfig(filters);

        savedFilters.forEach((filter) => {
          if (!matches) {
            matches = JSON.stringify(filter.filters) === JSON.stringify(match);
          }
        });

        return matches;
      }, [savedFilters, filters]);

      const onCloseCallback = useCallback(() => {
        setFilterName("");
        setMatchingFilter(null);
        onClose();
      }, [setFilterName, setMatchingFilter, onClose]);

      const saveFilter = useCallback(async () => {
        try {
          setLoading(true);

          let id = uuid();
          let name = filterName.trim();

          if (!!matchingFilter) {
            id = matchingFilter.id || id;
            name = matchingFilter.name.trim();
          }

          await saveFilters(name, filters, id);
          onCloseCallback();
        } finally {
          setLoading(false);
        }
      }, [saveFilters, filterName, filters, matchingFilter, onCloseCallback]);

      return (
        <>
          <Modal visible={visible} onClose={onCloseCallback}>
            <ModalHeader close title="mls.savefilter.modal.title" />
            <ModalBody>
              <I18n value="mls.savefilter.modal.body"></I18n>
              <div className="form__row">
                <Input.Text
                  name="filterName"
                  onChange={setFilterName}
                  value={filterName}
                  styleName="filterNameInput"
                  maxLength={95}
                  fireAllChanges
                />
              </div>

              {(!!matchesExistingFilterName || !!matchesExistingFilter) && (
                <div className="form__row">
                  {!!matchesExistingFilterName && (
                    <Input.CheckBox
                      name="overwrite"
                      value={overwrite}
                      onChange={setOverwrite}
                      label="mls.savefilter.modal.overwrite"
                    />
                  )}
                  {!!matchesExistingFilter && (
                    <Input.CheckBox
                      name="duplicate"
                      value={duplicate}
                      onChange={setDuplicate}
                      label="mls.savefilter.modal.duplicate"
                    />
                  )}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                category="primary"
                label="mls.savefilter.modal.footer.save"
                disabled={
                  !filterName ||
                  loading ||
                  (matchesExistingFilterName && !overwrite)
                }
                icon={
                  !loading ? null : (
                    <Icon name="spinner" color={Colors.Gray} spin />
                  )
                }
                onClick={saveFilter}
              />
            </ModalFooter>
          </Modal>
        </>
      );
    }
  )
);
