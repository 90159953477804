import { WidgetEntityType } from "@haywork/api/authorization";
import {
  ActiveFilter,
  ContactLinkSnapShot,
  ContactLinkTypeSuggestion,
  RelationSnapShot,
  RelationTermField,
  RelationType,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  DropdownFilter,
  Filter,
  MappedDropdownValue,
} from "@haywork/modules/filter";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryResultType,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import Notes from "@haywork/modules/notes-v3";
import {
  RelationAroRelationsContainerProps,
  RelationAroRelationsItemComponent,
} from "@haywork/modules/relation";
import {
  InfiniteScroll,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import { Relation } from "@haywork/request";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface RelationLinkedRelationsListComponentProps {}
interface State {
  addingContact: boolean;
  linkType: string;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = RelationLinkedRelationsListComponentProps &
  RelationAroRelationsContainerProps;

const styles = require("./aro-relations-list.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class RelationLinkedRelationsListComponent extends React.Component<
  Props,
  State
> {
  private contactCompanyInput: HTMLInputElement;
  private contactPersonInput: HTMLInputElement;
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      addingContact: false,
      linkType: "",
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.formControls = {
      linkedContact: { value: "" },
    };

    this.searchRelations = this.searchRelations.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.toggleAddingContact = this.toggleAddingContact.bind(this);
    this.onContactLinkEditHandler = this.onContactLinkEditHandler.bind(this);
    this.linkTypeChangeHandler = this.linkTypeChangeHandler.bind(this);
    this.onAddLinkChangeHandler = this.onAddLinkChangeHandler.bind(this);
    this.onAddContactSubmitForContactCompany =
      this.onAddContactSubmitForContactCompany.bind(this);
    this.onAddContactSubmitForContactPerson =
      this.onAddContactSubmitForContactPerson.bind(this);
    this.onAddFilterChangedHandler = this.onAddFilterChangedHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.addNewRelation = this.addNewRelation.bind(this);
  }

  public componentDidMount() {
    this.props.getContactLinks(this.props.selectedRelationId, true);

    const widgetEntityType =
      this.props.selectedRelationType === RelationType.ContactPerson
        ? WidgetEntityType.ContactPerson
        : WidgetEntityType.ContactCompany;

    this.props.getInitializedWidgets(
      this.props.selectedRelationId,
      widgetEntityType
    );
  }

  public render() {
    return (
      <div styleName="body">
        <PageHeader
          title="pageTitle.relation.relations"
          subTitle={this.props.selectedRelationDisplayName}
          actions={
            <>
              <Notes />
              <Button
                label="addContact"
                category="primary"
                icon={<Icon name="plus" light size={18} />}
                iconPosition="start"
                onClick={this.toggleAddingContact}
              />
            </>
          }
        />

        {this.props.relationContactLinkState === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}

        {this.props.contactAddError && this.renderContactAddError()}

        {this.state.addingContact && (
          <div styleName="add-contact">
            <div styleName="add-contact__avatar">
              <div styleName="avatar" />
            </div>
            {this.renderAddContact()}
          </div>
        )}

        {this.props.contactLinks && this.props.contactLinks.length > 0
          ? this.renderListItems()
          : this.renderEmptyState()}

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    const { currentComponentState: newComponentState } = nextProps;
    const { currentComponentState: oldComponentState } = this.props;

    if (
      has(newComponentState, "aroCreatedRelation") &&
      get(newComponentState, "aroCreatedRelation.id") !==
        get(oldComponentState, "aroCreatedRelation.id")
    ) {
      const { aroCreatedRelation: linkedContact } = newComponentState;
      this.setState({ addingContact: true }, () => {
        if (!!this.formRef) {
          switch (this.props.selectedRelationType) {
            case RelationType.ContactCompany:
              this.contactCompanyInput.focus();
              break;
            case RelationType.ContactPerson:
              this.contactPersonInput.focus();
              break;
            default:
              break;
          }
          this.formRef.update({ linkedContact });
        }
      });
    }
  }

  public renderListItems(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="list">
        {this.props.relationContactLinkState === REQUEST.SUCCESS && (
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {this.props.contactLinks.map((contact, idx) => (
              <ErrorBoundary
                key={contact.sourceRelation.id + contact.targetRelation.id}
              >
                <RelationAroRelationsItemComponent
                  contact={contact}
                  linkTypes={this.props.linkTypes}
                  contactLinkEditClick={this.onContactLinkEditHandler}
                  contactLinkDelete={this.props.deleteRelation}
                  contactLinkUnDelete={this.props.unDeleteRelation}
                  contactLinkTypeChange={this.linkTypeChangeHandler}
                  contactLinkRemoveFromList={this.props.removeRelationFromList}
                  zebra={idx % 2 !== 0}
                  relationId={this.props.selectedRelationId}
                  navigate={this.props.navigate}
                />
              </ErrorBoundary>
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }

  public renderEmptyState(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="list">
        <div styleName="emptyState">
          <div styleName="content">
            <div styleName="icon" />
            {this.props.selectedRelationType === RelationType.ContactCompany ? (
              <div styleName="text">
                <h2>
                  <ResourceText resourceKey="emptyStateNoAroRelationsTitle" />
                </h2>
                <p>
                  <ResourceText resourceKey="emptyStateNoAroRelationsNetworkText" />
                </p>
              </div>
            ) : (
              <div styleName="text">
                <h2>
                  <ResourceText resourceKey="emptyStateNoAroRelationsNetworkText" />
                </h2>
                <p>
                  <ResourceText resourceKey="emptyStateNoAroRelationsText" />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private toggleAddingContact() {
    this.setState(
      { addingContact: !this.state.addingContact, linkType: "" },
      () => {
        if (this.contactCompanyInput) {
          this.contactCompanyInput.focus();
        }
        if (this.contactPersonInput) {
          this.contactPersonInput.focus();
        }
      }
    );
  }

  private renderAddContact(): React.ReactElement<HTMLDivElement> {
    switch (this.props.selectedRelationType) {
      case RelationType.ContactCompany:
        return this.renderAddContactCompanyForm();
      case RelationType.ContactPerson:
        return this.renderAddContactPersonForm();
      default:
        return null;
    }
  }

  private renderAddContactCompanyForm() {
    return (
      <div styleName="add-contact__body">
        <Form
          formControls={this.formControls}
          name="contactLink"
          onSubmit={this.onAddContactSubmitForContactCompany}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.QueryLegacy
                  name="linkedContact"
                  placeholder="relationAroAddNewRelation"
                  asyncValues={(value) => this.searchRelations(value)}
                  displayPath="displayName"
                  resultPath="results"
                  setQueryAsValue
                  inputRef={(ref) => {
                    this.contactCompanyInput = ref;
                  }}
                  noItemsFoundText="tagBoxRelationsNoResults"
                  onAddManualOption={this.addNewRelation}
                  resultType={QueryResultType.Relation}
                />
              </div>
              <div styleName="text">
                <ResourceText
                  resourceKey="aroWorkingAtPlaceholder"
                  values={{
                    companyName: this.props.selectedRelationDisplayName,
                  }}
                />
              </div>
              <div styleName="function">
                <input
                  type="text"
                  value={this.state.linkType}
                  onChange={this.onAddLinkChangeHandler}
                  styleName="link-type"
                  placeholder={intlContext.formatMessage({
                    id: "aroFunctionPlaceholder",
                    defaultMessage: "aroFunctionPlaceholder",
                  })}
                  data-lpignore="true"
                />
              </div>
            </div>
            <div styleName="action">
              <button type="submit" className="btn btn-success">
                <ResourceText resourceKey="add" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private renderContactAddError() {
    return (
      <div styleName="error" className="alert alert-danger">
        <ResourceText resourceKey="contactAlreadyExistsError" />
      </div>
    );
  }

  private renderAddContactPersonForm() {
    return (
      <div styleName="add-contact__body">
        <Form
          formControls={this.formControls}
          name="contactLink"
          onSubmit={this.onAddContactSubmitForContactPerson}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="add-contact__row">
            <div styleName="inner">
              <div styleName="query">
                <Input.QueryLegacy
                  name="linkedContact"
                  placeholder="relationAroAddNewRelation"
                  asyncValues={(value) => this.searchRelations(value)}
                  displayPath="displayName"
                  resultPath="results"
                  setQueryAsValue
                  inputRef={(ref) => {
                    this.contactPersonInput = ref;
                  }}
                  noItemsFoundText="tagBoxRelationsNoResults"
                  onAddManualOption={this.addNewRelation}
                  resultType={QueryResultType.Relation}
                />
              </div>
              <div styleName="function">
                <Filter changeFilter={this.onAddFilterChangedHandler}>
                  <ResourceText resourceKey="aroIsPlaceholder" />
                  <DropdownFilter
                    name="linkType"
                    inline
                    values={this.mapLinkTypes(this.props.linkTypes)}
                  />
                  <ResourceText
                    resourceKey="aroOf"
                    values={{
                      name: this.props.selectedRelationDisplayName,
                      isSelf: "yes",
                    }}
                    asHtml
                  />
                </Filter>
              </div>
            </div>
            <div styleName="action">
              <button type="submit" className="btn btn-success">
                <ResourceText resourceKey="add" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private searchRelations(value) {
    if (this.props.selectedRelationType === RelationType.ContactCompany) {
      return Relation.searchForQueryInput(
        value,
        [RelationTermField.DisplayName],
        ActiveFilter.ActiveOrInactive,
        [RelationType.ContactPerson],
        this.props.realEstateAgencyId,
        5,
        this.props.host
      );
    } else if (this.props.selectedRelationType === RelationType.ContactPerson) {
      return Relation.searchForQueryInput(
        value,
        [RelationTermField.DisplayName],
        ActiveFilter.ActiveOrInactive,
        [RelationType.ContactPerson, RelationType.ContactCompany],
        this.props.realEstateAgencyId,
        5,
        this.props.host
      );
    }
  }

  private onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.relationContactLinkState === REQUEST.PENDING
    )
      return;
    this.props.getContactLinks(this.props.selectedRelationId, false);
  }

  private onContactLinkEditHandler(contact: ContactLinkSnapShot) {
    let id: string;
    let relationType: RelationType;

    if (contact.sourceRelation.id === this.props.selectedRelationId) {
      id = contact.targetRelation.id;
      relationType = contact.targetRelation.typeOfRelation;
    } else {
      id = contact.sourceRelation.id;
      relationType = contact.sourceRelation.typeOfRelation;
    }
    this.props.onContactLinkEdit(id, relationType);
  }

  private onAddLinkChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ linkType: event.target.value });
  }

  private onAddFilterChangedHandler(values: any) {
    this.setState({ linkType: values.linkType });
  }

  private onAddContactSubmitForContactPerson(values: FormReturnValue) {
    if (!values.linkedContact || values.linkedContact.length === 0) return;
    let linkType = this.state.linkType;
    const linkedRelation: RelationSnapShot = values.linkedContact;

    let sourceId: string;
    let targetId: string;
    let isPartnerLink: boolean;
    if (linkedRelation.typeOfRelation === RelationType.ContactPerson) {
      sourceId = linkedRelation.id;
      targetId = this.props.selectedRelationId;
      isPartnerLink = this.state.linkType === "partner";
    } else if (linkedRelation.typeOfRelation === RelationType.ContactCompany) {
      targetId = linkedRelation.id;
      sourceId = this.props.selectedRelationId;
      isPartnerLink = false;
    }

    if (!linkType) {
      linkType = this.props.linkTypes[0].displayName;
    }

    this.props.saveContactLink(sourceId, targetId, isPartnerLink, linkType);
    this.setState({ linkType: "", addingContact: false });
  }

  private onAddContactSubmitForContactCompany(values: FormReturnValue) {
    if (!values.linkedContact || values.linkedContact.length === 0) return;

    const sourceId = values.linkedContact.id;
    const targetId = this.props.selectedRelationId;
    const isPartnerLink = this.state.linkType === "partner";

    this.props.saveContactLink(
      sourceId,
      targetId,
      isPartnerLink,
      this.state.linkType
    );
    this.setState({ linkType: "", addingContact: false });
  }

  private linkTypeChangeHandler(
    sourceRelationId: string,
    targetRelationId: string,
    linkType: string
  ) {
    const isPartnerLink = false;
    if (
      this.props.selectedRelationId === targetRelationId &&
      this.props.selectedRelationType === RelationType.ContactPerson
    ) {
      const id = sourceRelationId;
      sourceRelationId = targetRelationId;
      targetRelationId = id;
    }

    this.props.updateContactLink(
      sourceRelationId,
      targetRelationId,
      isPartnerLink,
      linkType
    );
  }

  private mapLinkTypes(linkTypes: ContactLinkTypeSuggestion[]) {
    const mappedLinkTypes: MappedDropdownValue[] = linkTypes.map((val) => ({
      label: val.displayName,
      value: val.displayName,
    }));
    return mappedLinkTypes;
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(linkedContact: RelationSnapShot) {
    this.formRef.update({
      linkedContact,
    });

    this.setState({
      newEntityVisible: false,
    });
  }
}
