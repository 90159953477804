import { AssignmentPhase } from "@haywork/api/kolibri";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { ProjectEditMarketingPartnersContainerProps } from "@haywork/modules/project";
import { MarketingPartnersComponent } from "@haywork/modules/shared";
import Publications from "@haywork/modules/shared/components/publications";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-partners.component.scss");

interface ProjectEditMarketingPartnersComponentProps {}
interface State {}
type Props = ProjectEditMarketingPartnersComponentProps &
  ProjectEditMarketingPartnersContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditMarketingPartnersComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.activateMediaPartner = this.activateMediaPartner.bind(this);
    this.deActivateMediaPartner = this.deActivateMediaPartner.bind(this);
  }

  public render() {
    const {
      assignmentPhase,
      availabilityStatus
    } = this.props.projectAssignment;

    return (
      <div>
        <FeatureSwitch feature="PUBLICATIONS_V2" disable>
          <MarketingPartnersComponent
            activatePublication={this.activateMediaPartner}
            deActivatePublication={this.deActivateMediaPartner}
            assignmentPhase={assignmentPhase}
            availabilityStatus={availabilityStatus}
            mediaPartnerState={this.props.mediaPartnerState}
            publications={this.props.publications}
            mediaPartnerChangedId={this.props.mediaPartnerChangedId}
          />
        </FeatureSwitch>

        <FeatureSwitch feature="PUBLICATIONS_V2">
          <Publications
            assignment={this.props.projectAssignment}
            type="projectAssignment"
          />
        </FeatureSwitch>
      </div>
    );
  }

  private activateMediaPartner(mediaPartnerId: string) {
    this.props.activatePublication(
      this.props.projectAssignment.id,
      mediaPartnerId
    );
  }

  private deActivateMediaPartner(mediaPartnerId: string) {
    this.props.deActivatePublication(
      this.props.projectAssignment.id,
      mediaPartnerId
    );
  }
}
