import { ActionType, ActionTypes } from "./actions";
import { Provider } from "@haywork/api/mail";

export type State = {
  providers: Provider[];
};

const INITIAL: State = {
  providers: []
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetProviders: {
      const { providers } = action;

      return {
        ...state,
        providers
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
