import { RealEstateGroup } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import {
  Filter,
  FilterConfig,
  FilterContextMenu,
  FilterSection,
  FilterSectionColumn,
  ListFilter,
  ListRefProps,
  SingleFilterConfig,
  SingleFilterValue,
} from "@haywork/components/ui/list";
import { SETTINGSROUTES } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { MlsUtil } from "@haywork/util";
import isString from "lodash-es/isString";
import * as React from "react";
import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { FilterModalData } from "../../list";
import { FiltersContainerProps } from "./filters.container";
import { PropertyType } from "@haywork/api/mls";
import { RelativeDateValue } from "@haywork/stores/mls/list";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

export type FiltersComponentProps = {
  list: MutableRefObject<ListRefProps>;
  setSaveFilterModal: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      data: FilterModalData;
    }>
  >;
  showManageFiltersModal: (show: boolean) => void;
  onClearFilters?: () => void;
};
type Props = FiltersComponentProps & FiltersContainerProps;

const relativeDatesValues = [
  RelativeDateValue.Today,
  RelativeDateValue.Yesterday,
  RelativeDateValue.DayBeforeYesterday,
  RelativeDateValue.OneWeekAgo,
  RelativeDateValue.TwoWeeksAgo,
  RelativeDateValue.AMonthAgo,
];
const relativeDates: SingleFilterValue[] = relativeDatesValues.map((value) => ({
  label: `mls.${value}`,
  value,
}));

export const FiltersComponent: FC<Props> = memo(
  ({
    setFilters,
    setSaveFilterModal,
    showManageFiltersModal,
    filters,
    list,
    realEstateGroupValues,
    searchLocationValues,
    realEstateAgencyGroupValues,
    realEstateAgencyAppClientValues,
    mutationTypeValues,
    mutationsDates,
    priceRangeValues,
    availabilityStatusValues,
    listingTypePartValues,
    listingTypeBogValues,
    listingTypeAlvValues,
    houseTypesValues,
    houseSortsValues,
    houseCharacteristicsValues,
    apartmentSortValues,
    apartmentCharasteristicsValues,
    garageTypeValues,
    residentialSubtypeOthersValues,
    horseCompanySubtypes,
    parkingTypeOptions,
    pigCompanyTypes,
    cattleFarmingSubtypes,
    horticulturalCompanyTypes,
    beverageHospitalitySectorTypes,
    locationPlaces,
    livingAreaValues,
    noOfRoomsValues,
    noOfBedroomsValues,
    contentValues,
    floorsValues,
    yearOfConstructionValues,
    plotSizeValues,
    outsideValues,
    gardenPlotSizeValues,
    orientationTypeValues,
    garageCapacityValues,
    energyClassOptions,
    conditionOptionsInside,
    conditionOptionsOutside,
    priceTypeFilters,
    facilitiesValues,
    otherSearchTypeValues,
    garageTypeOptions,
    savedFilters,
    forSaleAndOrRentFilterValues,
    followingValues,
    parkingPlaceCounts,
    floorAreaValues,
    groundTypeValues,
    navigate,
    resetOrdering,
    onClearFilters,
  }) => {
    const subFilterRef = useRef<HTMLElement>();
    const [visibleSubFilter, setVisibleSubFilter] = useState("");
    const [mutationDatesDisabled, setMutationDatesDisabled] = useState(
      !filters.mutationTypes?.value?.length
    );

    const realEstateGroup = useMemo(
      () => filters.realEstateGroups.value as RealEstateGroup,
      [filters.realEstateGroups.value]
    );

    const savedFiltersMap = useMemo(() => {
      return savedFilters.map(
        (filter) =>
          ({
            label: filter.name,
            value: JSON.stringify(filter.filters),
          } as SingleFilterValue)
      );
    }, [savedFilters, filters]);

    const toggleSubFilter = useCallback(
      (ref: MutableRefObject<HTMLElement>, subFilter: string) => {
        subFilterRef.current = ref.current;
        setVisibleSubFilter(subFilter === visibleSubFilter ? "" : subFilter);
      },
      [visibleSubFilter, setVisibleSubFilter]
    );

    const clearSubFilter = useCallback(() => {
      setVisibleSubFilter("");
    }, [setVisibleSubFilter]);

    const updateFilters = useCallback(
      (filters: FilterConfig) => {
        if (!list?.current) return;
        setFilters(filters);
        list.current.refresh();
      },
      [setFilters]
    );

    const handleFilterSave = useCallback(
      (filters: FilterConfig) => {
        setSaveFilterModal({
          isVisible: true,
          data: { savedFilters, filters },
        });
      },
      [setSaveFilterModal, savedFilters, filters]
    );

    const handleSavedFilterChange = useCallback(
      (filter: string) => {
        if (filter === "manageFilters") {
          showManageFiltersModal(true);
        } else {
          if (filter === "") return;
          const incomingFilters: Record<string, any> = JSON.parse(filter);
          const incomingFilterKeys = Object.keys(incomingFilters);
          const updatedFilters: FilterConfig = {};

          Object.keys(filters).forEach((key) => {
            const filter = filters[key];
            if (incomingFilterKeys.includes(key)) {
              let value = incomingFilters[key];
              const dateKeys = ["mutationsDates"];

              if (dateKeys.includes(value)) {
                if (isString(value?.min)) {
                  value = {
                    ...value,
                    min: new Date(value.min),
                  };
                }

                if (isString(value?.max)) {
                  value = {
                    ...value,
                    max: new Date(value.max),
                  };
                }
              }

              updatedFilters[key] = {
                ...filter,
                value,
              };
            } else {
              updatedFilters[key] = { ...filter, value: filter.emptyValue };
            }
          });

          updateFilters(updatedFilters);
          if (!!(updatedFilters?.mutationTypes?.value || []).length) {
            setMutationDatesDisabled(false);
          }
        }
      },
      [
        updateFilters,
        setSaveFilterModal,
        setMutationDatesDisabled,
        savedFilters,
        filters,
      ]
    );

    const handleMutationTypeChange = useCallback(
      (filter) => {
        !!filter?.value.length
          ? setMutationDatesDisabled(false)
          : setMutationDatesDisabled(true);
      },
      [setMutationDatesDisabled]
    );

    const editSearchLocations = useCallback(() => {
      navigate(SETTINGSROUTES.MLS.URI);
    }, [navigate]);

    const handleRealestateGroupChange = useCallback(
      (realEstateGroups: SingleFilterConfig) => {
        let clearedFilters: FilterConfig =
          MlsUtil.resetFilterToDefaultValues(filters);

        clearedFilters = {
          ...clearedFilters,
          realEstateGroups,
        };

        setFilters(clearedFilters);
        resetOrdering();
        list.current?.refresh();
      },
      [filters, setFilters, resetOrdering]
    );

    const handlePropertyTypeChange = useCallback(
      (propertyTypeOptions) => {
        const subFiltersToClear = [];

        const propertyTypes: Array<PropertyType> = !!propertyTypeOptions.value
          .length
          ? propertyTypeOptions.value
          : [];

        if (!propertyTypes.includes(PropertyType.HOUSE)) {
          subFiltersToClear.push(
            "houseTypes",
            "houseSorts",
            "houseCharacteristics"
          );
        }
        if (!propertyTypes.includes(PropertyType.APARTMENT)) {
          subFiltersToClear.push(
            "houseTypes",
            "apartmentSorts",
            "apartmentCharacteristics"
          );
        }
        if (!propertyTypes.includes(PropertyType.PARKING)) {
          subFiltersToClear.push("parkingTypeOptions");
        }
        if (!propertyTypes.includes(PropertyType.RESIDENTIAL_OTHERS)) {
          subFiltersToClear.push("otherTypes");
        }
        if (!propertyTypes.includes(PropertyType.HOTEL_RESTAURANT_CAFE)) {
          subFiltersToClear.push("beverageHospitalitySectorTypes");
        }
        if (!propertyTypes.includes(PropertyType.GARAGE)) {
          subFiltersToClear.push("garageTypes");
        }
        if (!propertyTypes.includes(PropertyType.HORSES_COMPANY)) {
          subFiltersToClear.push("horseCompanySubtypes");
        }
        if (!propertyTypes.includes(PropertyType.HORTICULTURAL_COMPANY)) {
          subFiltersToClear.push("horticulturalCompanyTypes");
        }
        if (!propertyTypes.includes(PropertyType.PIG_HOLDING)) {
          subFiltersToClear.push("pigCompanyTypes");
        }
        if (!propertyTypes.includes(PropertyType.CATTLE_HUSBANDRY)) {
          subFiltersToClear.push("cattleFarmingSubtypes");
        }

        let resettedFilters;
        for (const key in filters) {
          const filter = filters[key];

          if (subFiltersToClear.indexOf(key) > -1) {
            resettedFilters = {
              ...resettedFilters,
              [key]: {
                ...filter,
                value: filter.emptyValue,
              },
            };
          }
        }

        const newFilters = {
          ...filters,
          ...resettedFilters,
          propertyTypeOptions,
        };

        setFilters(newFilters);
        resetOrdering();
        list.current?.refresh();
      },
      [filters, resetOrdering, setFilters]
    );

    return (
      <ListFilter
        filters={filters}
        onChange={updateFilters}
        onClearFilters={onClearFilters}
        clearLabel="mls.list.clearAction"
      >
        <FilterSection title="mls.filter.title.savedFilters">
          <Filter.SavedFilters
            values={savedFiltersMap}
            filterConfig={filters}
            onChange={handleSavedFilterChange}
          />
          <div style={{ marginTop: 4 }}>
            <span className="as-link" onClick={() => handleFilterSave(filters)}>
              <I18n value="mls.filter.action.saveFilters" />
            </span>
          </div>
        </FilterSection>

        <FilterSection title="mls.filter.title.realEstateGroups">
          <Filter.RadioGroup
            configKey="realEstateGroups"
            values={realEstateGroupValues}
            onChange={handleRealestateGroupChange}
            blockUpdate
          />
        </FilterSection>

        <FilterSection title="mls.filter.title.forSaleAndOrRent">
          <Filter.CheckboxArray
            configKey="forSaleAndOrRent"
            values={forSaleAndOrRentFilterValues}
            columnCount={20}
          />
        </FilterSection>

        <FilterSection title="mls.filter.title.following">
          <Filter.CheckboxArray
            configKey="following"
            values={followingValues}
          />
        </FilterSection>

        <FilterSection
          title="mls.filter.title.areaTypes"
          collapsable
          expand="horizontal"
        >
          <FilterSectionColumn>
            <Filter.SearchLocation
              configKey="searchLocations"
              values={searchLocationValues}
              title="mls.filter.title.areaTypes"
            />
            <div style={{ marginTop: 4 }}>
              <span className="as-link" onClick={editSearchLocations}>
                <I18n value="mls.filter.action.editSearchLocations" />
              </span>
            </div>
          </FilterSectionColumn>
        </FilterSection>

        <FilterSection title="mls.filter.title.postalCodeRanges">
          <Filter.PostalCodeRange configKey="postalCodeRanges" />
        </FilterSection>

        <FilterSection
          title="mls.filter.title.locations"
          info="mls.filter.title.locations.info"
        >
          <Filter.LocationRange configKey="locations" />
        </FilterSection>

        <FilterSection title="mls.filter.title.mutations">
          <Filter.CheckboxArray
            configKey="mutationTypes"
            values={mutationTypeValues}
            onChange={handleMutationTypeChange}
            columnCount={20}
          />

          <div
            style={{
              border: "1px solid rgb(230,230,230)",
              padding: 8,
              overflow: "hidden",
              marginTop: 4,
              backgroundColor: "rgb(250,250,250)",
            }}
          >
            <div>
              <Filter.DateRange
                configKey="mutationsDates"
                value={mutationsDates}
                disabled={mutationDatesDisabled}
              />
            </div>

            <div style={{ marginTop: 4, marginBottom: 4, textAlign: "center" }}>
              <I18n value="mls.filter.or.relativeDate" />
            </div>

            <div>
              <Filter.Select
                configKey="relativeDate"
                values={relativeDates}
                disabled={mutationDatesDisabled}
              />
            </div>
          </div>
        </FilterSection>

        <FilterSection title="mls.filter.title.price">
          <Filter.MlsPriceRange
            configKey="priceRangeValues"
            value={priceRangeValues}
            values={priceTypeFilters}
          />
        </FilterSection>

        <FilterSection
          title="mls.filter.title.transactionStatus"
          // subtitle={transactionStatusSubtitle}
          // subtitle="mls.filter.subtitle.transaction"
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              configKey="availabilityStatuses"
              values={availabilityStatusValues}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterSection>

        <FilterSection
          title="mls.filter.title.typeOfObject"
          // subtitle="mls.filter.subTitle.typeOfObject"
        >
          {realEstateGroup === RealEstateGroup.Residential && (
            <Filter.CheckboxArray
              configKey="propertyTypeOptions"
              values={listingTypePartValues}
              onToggleSubfilter={toggleSubFilter}
              onChange={handlePropertyTypeChange}
              columnCount={20}
              blockUpdate
            />
          )}

          {realEstateGroup === RealEstateGroup.Commercial && (
            <Filter.CheckboxArray
              configKey="propertyTypeOptions"
              values={listingTypeBogValues}
              onToggleSubfilter={toggleSubFilter}
              onChange={handlePropertyTypeChange}
              columnCount={20}
              blockUpdate
            />
          )}
          {realEstateGroup === RealEstateGroup.Agricultural && (
            <Filter.CheckboxArray
              configKey="propertyTypeOptions"
              values={listingTypeAlvValues}
              onToggleSubfilter={toggleSubFilter}
              onChange={handlePropertyTypeChange}
              columnCount={20}
              blockUpdate
            />
          )}
        </FilterSection>

        {realEstateGroup === RealEstateGroup.Agricultural && (
          <FilterSection
            title="mls.filter.title.groundType"
            // subtitle="mls.filter.subTitle.groundType"
          >
            <Filter.CheckboxArray
              configKey="groundType"
              values={groundTypeValues}
              columnCount={20}
            />
          </FilterSection>
        )}

        <FilterContextMenu
          visible={visibleSubFilter === "listingTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.houseTypes"
              configKey="houseTypes"
              values={houseTypesValues}
              columnCount={20}
              onChange={handlePropertyTypeChange}
            />
          </FilterSectionColumn>
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.houseSorts"
              configKey="houseSorts"
              values={houseSortsValues}
              columnCount={20}
            />
          </FilterSectionColumn>
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.houseCharacteristics"
              configKey="houseCharacteristics"
              values={houseCharacteristicsValues}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "apartmentTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.apartmentSorts"
              configKey="apartmentSorts"
              values={apartmentSortValues}
              columnCount={20}
            />
          </FilterSectionColumn>

          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.apartmentCharacteristics"
              configKey="apartmentCharacteristics"
              values={apartmentCharasteristicsValues}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "garageTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.garageType"
              configKey="garageTypes"
              values={garageTypeValues}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "otherTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="otherTypes"
              values={residentialSubtypeOthersValues}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "horseCompanySubtypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="horseCompanySubtypes"
              values={horseCompanySubtypes}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "parkingTypeOptions"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="parkingTypeOptions"
              values={parkingTypeOptions}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "pigCompanyTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="pigCompanyTypes"
              values={pigCompanyTypes}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "cattleFarmingSubtypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="cattleFarmingSubtypes"
              values={cattleFarmingSubtypes}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "horticulturalCompanyTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="horticulturalCompanyTypes"
              values={horticulturalCompanyTypes}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        <FilterContextMenu
          visible={visibleSubFilter === "beverageHospitalitySectorTypes"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              title="mls.filter.title.other"
              configKey="beverageHospitalitySectorTypes"
              values={beverageHospitalitySectorTypes}
              columnCount={20}
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.livingArea">
            <Filter.NumberRange
              configKey="livingAreaValues"
              value={livingAreaValues}
              type="squareMeters"
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.noOfRooms">
            <Filter.NumberRange
              configKey="noOfRoomsValues"
              value={noOfRoomsValues}
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.noOfBedrooms">
            <Filter.NumberRange
              configKey="noOfBedroomsValues"
              value={noOfBedroomsValues}
            />
          </FilterSection>
        )}

        {realEstateGroup !== RealEstateGroup.Agricultural && (
          <FilterSection
            title="mls.filter.title.locality"
            collapsable
            expand="horizontal"
            // subtitle={localitySubtitle}
            // subtitle="mls.filter.subtitle.locality"
          >
            <FilterSectionColumn>
              <Filter.CheckboxArray
                configKey="locationPlaces"
                values={locationPlaces}
                title="mls.filter.title.locality"
                columnCount={20}
              />
            </FilterSectionColumn>
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.outside">
            <Filter.CheckboxArray
              configKey="outside"
              values={outsideValues}
              onToggleSubfilter={toggleSubFilter}
              columnCount={20}
            />
          </FilterSection>
        )}

        <FilterContextMenu
          visible={visibleSubFilter === "hasGardenSubFilter"}
          parent={subFilterRef}
          push={{ left: 16 }}
          onClose={clearSubFilter}
        >
          <FilterSectionColumn>
            <Filter.CheckboxArray
              configKey="orientationTypeValues"
              values={orientationTypeValues}
              title="mls.filter.title.orientation"
              columnCount={20}
            />
          </FilterSectionColumn>

          <FilterSectionColumn>
            <Filter.NumberRange
              configKey="gardenPlotSizeValues"
              value={gardenPlotSizeValues}
              type="squareMeters"
              title="mls.filter.title.gardenPlotSize"
            />
          </FilterSectionColumn>
        </FilterContextMenu>

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection
            title="mls.filter.title.garage"
            collapsable
            expand="horizontal"
            // subtitle="mls.filter.subtitle.garage"
          >
            <FilterSectionColumn>
              <Filter.CheckboxArray
                configKey="garageTypeOptions"
                values={garageTypeOptions}
                title="mls.filter.title.garage.type"
                columnCount={20}
              />
            </FilterSectionColumn>
            <FilterSectionColumn>
              <Filter.NumberRange
                configKey="garageCapacityValues"
                value={garageCapacityValues}
                title="mls.filter.title.garage.capacity"
              />
            </FilterSectionColumn>
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.yearOfConstruction">
            <Filter.NumberRange
              configKey="yearOfConstructionValues"
              value={yearOfConstructionValues}
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.plotSize">
            <Filter.NumberRange
              configKey="plotSizeValues"
              value={plotSizeValues}
              type="squareMeters"
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Agricultural && (
          <FilterSection title="mls.filter.title.plotSize">
            <Filter.NumberRange
              configKey="plotSizeValues"
              value={plotSizeValues}
              type="areaHa"
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Commercial && (
          <FilterSection title="mls.filter.title.floorArea">
            <Filter.NumberRange
              configKey="floorAreaValues"
              value={floorAreaValues}
              type="squareMeters"
            />
          </FilterSection>
        )}

        {realEstateGroup !== RealEstateGroup.Agricultural && (
          <FilterSection
            title="mls.filter.title.energyLabel"
            collapsable
            expand="horizontal"
            // subtitle="mls.filter.subtitle.energyLabel"
          >
            <FilterSectionColumn>
              <Filter.CheckboxArray
                configKey="energyClasses"
                values={energyClassOptions}
                title="mls.filter.title.energyLabel"
                columnCount={20}
              />
            </FilterSectionColumn>
          </FilterSection>
        )}

        <FilterSection
          title="mls.filter.title.includes"
          // subtitle="mls.filter.subtitle.includes"
          collapsable={facilitiesValues.length > 5}
          expand={facilitiesValues.length > 5 ? "horizontal" : undefined}
        >
          <Filter.CheckboxArray
            configKey="facilitySearchTypeOptions"
            values={facilitiesValues}
            title={
              facilitiesValues.length > 5
                ? "mls.filter.title.includes"
                : undefined
            }
            columnCount={20}
          />
        </FilterSection>

        {realEstateGroup !== RealEstateGroup.Agricultural && (
          <FilterSection
            title="mls.filter.title.maintenance"
            collapsable
            expand="horizontal"
            // subtitle="mls.filter.subtitle.insideOutside"
          >
            <FilterSectionColumn>
              <Filter.RadioGroup
                configKey="conditionOptionsInside"
                values={conditionOptionsInside}
                title="mls.filter.title.maintenance.inside"
              />
            </FilterSectionColumn>
            <FilterSectionColumn>
              <Filter.RadioGroup
                configKey="conditionOptionsOutside"
                values={conditionOptionsOutside}
                title="mls.filter.title.maintenance.outside"
              />
            </FilterSectionColumn>
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection
            title="mls.filter.title.other"
            // subtitle="mls.filter.subtitle.other"
            collapsable
            expand="horizontal"
          >
            <Filter.CheckboxArray
              configKey="otherSearchTypeOptions"
              values={otherSearchTypeValues}
              title="mls.filter.title.other"
              columnCount={20}
            />
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.content">
            <Filter.NumberRange
              configKey="contentValues"
              value={contentValues}
              type="cubicMeters"
            ></Filter.NumberRange>
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Residential && (
          <FilterSection title="mls.filter.title.floors">
            <Filter.NumberRange
              configKey="floorsValues"
              value={floorsValues}
            ></Filter.NumberRange>
          </FilterSection>
        )}

        {realEstateGroup === RealEstateGroup.Commercial && (
          <FilterSection title="mls.filter.title.parkingPlaceCounts">
            <Filter.Select
              configKey="parkingPlaceCounts"
              values={parkingPlaceCounts}
            />
          </FilterSection>
        )}

        <FilterSection title="mls.filter.title.realtors">
          <Filter.Realtor configKey="realtors" />
        </FilterSection>

        {!!realEstateAgencyGroupValues.length && (
          <FilterSection title="mls.filter.title.groups">
            <Filter.CheckboxArray
              configKey="groups"
              values={realEstateAgencyGroupValues}
              columnCount={20}
            />
          </FilterSection>
        )}

        <FeatureSwitch feature="AUTOTEST">
          {!!realEstateAgencyAppClientValues.length && (
            <FilterSection
              title="mls.filter.title.appClients"
              // subtitle="mls.filter.subtitle.appClients"
            >
              <Filter.CheckboxArray
                configKey="appClients"
                values={realEstateAgencyAppClientValues}
                columnCount={20}
              />
            </FilterSection>
          )}
        </FeatureSwitch>
      </ListFilter>
    );
  }
);
