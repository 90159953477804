import { ObjectAssignment } from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { Section } from "../..";

const styles = require("../detail-complete.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentDetailCompleteResidentialSpecificationComponentProps {
  assignment: ObjectAssignment;
  renderSection: (section: Section) => void;
}
interface State {}
type Props = AssignmentDetailCompleteResidentialSpecificationComponentProps;
export const AssignmentDetailCompleteResidentialSpecificationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignment, renderSection }) => {
      // Render
      return (
        <>
          {renderSection({
            title: "construction",
            data: [
              {
                type: "type",
                prefix: "listingTypes",
                values: [get(assignment, "listingType")],
              },
              {
                type: "houseSort",
                prefix: "houseSorts",
                values: [get(assignment, "houseSort")],
              },
              {
                type: "houseType",
                prefix: "houseTypes",
                values: [get(assignment, "houseType")],
              },
              {
                type: "houseCharacteristics",
                prefix: "houseCharacteristics",
                values: [get(assignment, "houseCharacteristic")],
              },
              {
                type: "roofType",
                prefix: "roofTypeOptions",
                values: [get(assignment.roof, "roofType")],
              },
              ,
              {
                type: "roofMaterialsLabel",
                prefix: "roofMaterialOptions",
                values: get(assignment.roof, "roofMaterials", [null]),
              },
              {
                type: "locationPlaces",
                prefix: "locationPlaces",
                values: get(assignment, "locationPlaces", [null]),
              },
            ],
          })}
          {renderSection({
            title: "featuresAndFigures",
            data: [
              {
                type: "constructionYear",
                prefix: "constructionYear",
                values: [
                  get(assignment.yearOfConstruction, "constructionYear"),
                ],
              },
              {
                type: "usableArea",
                prefix: "usableArea",
                values: [
                  get(assignment.usableArea, "area")
                    ? get(assignment.usableArea, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "otherInsideSpaces",
                prefix: "otherInsideSpaces",
                values: [
                  get(assignment.otherInsideSpaces, "area")
                    ? get(assignment.otherInsideSpaces, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "externalStorage",
                prefix: "externalStorage",
                values: [
                  get(assignment.externalStorage, "area")
                    ? get(assignment.externalStorage, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "parcelSurface",
                prefix: "parcelSurface",
                values: [
                  get(assignment.parcelSurface, "area")
                    ? get(assignment.parcelSurface, "area") + " m2"
                    : null,
                ],
              },
              {
                type: "contents",
                prefix: "contents",
                values: [
                  get(assignment.contents, "volume")
                    ? get(assignment.contents, "volume") + " m3"
                    : null,
                ],
              },
            ],
          })}
          {renderSection({
            title: "layout",
            data: [
              {
                type: "numberOfFloors",
                prefix: "numberOfFloors",
                values: [get(assignment, "numberOfFloors")],
              },
              {
                type: "numberOfRooms",
                prefix: "numberOfRooms",
                values: [get(assignment, "numberOfRooms")],
              },
              {
                type: "numberOfBedRooms",
                prefix: "numberOfBedRooms",
                values: [get(assignment, "numberOfBedRooms")],
              },
              {
                type: "numberOfToilets",
                prefix: "numberOfToilets",
                values: [get(assignment, "numberOfToilets")],
              },
            ],
          })}
          {renderSection({
            title: "energyAndCosts",
            data: [
              {
                type: "energyLabel",
                prefix: "energyClassOptions",
                values: [get(assignment.energyLabel, "energyClass")],
              },
              {
                type: "energyIndex",
                prefix: "energyClassOptions",
                values: [get(assignment.energyLabel, "energyIndex")],
              },
              {
                type: "selectIsolationType",
                prefix: "isolationTypeOptions",
                values: get(assignment, "insulationTypes", [null]),
              },
              {
                type: "warmWater",
                prefix: "heatingWaterMethodOptions",
                values: get(assignment, "warmWaterTypes", [null]),
              },
              {
                type: "heatingLabelv2",
                prefix: "heatingMethodOptions",
                values: get(assignment, "heating", [null]),
              },
            ],
          })}
          {assignment.gardens &&
            assignment.gardens.length > 0 &&
            assignment.gardens.map((garden, idx) => {
              return renderSection({
                title: "outdoor",
                key: idx,
                data: [
                  {
                    type: "garden",
                    prefix: "gardenTypeOptions",
                    values: [get(garden, "gardenType")],
                  },
                  {
                    type: "hasBackEntrance",
                    prefix: "gardenTypeOptions",
                    values: [get(garden, "hasBackEntrance")],
                  },
                  {
                    type: "orientation",
                    prefix: "orientations",
                    values: [get(garden, "orientation")],
                  },
                  {
                    type: "quality",
                    prefix: "gardenQualityOptions",
                    values: [get(garden, "gardenQuality")],
                  },
                  {
                    type: "length",
                    prefix: "length",
                    values: get(garden, "size.length")
                      ? [get(garden, "size.length") + " m"]
                      : null,
                  },
                  {
                    type: "width",
                    prefix: "width",
                    values: get(garden, "size.width")
                      ? [get(garden, "size.width") + " m"]
                      : null,
                  },
                  {
                    type: "area",
                    prefix: "area",
                    values: get(garden, "size.area")
                      ? [get(garden, "size.area") + " m2"]
                      : null,
                  },
                ],
              });
            })}

          {renderSection({
            title: "storage",
            data: [
              {
                type: "storageRoomType",
                prefix: "storageRoomTypeOptions",
                values: [get(assignment, "storageRoomType")],
              },
              {
                type: "storageHasHeating",
                prefix: "storageRoomTypeOptions",
                values: [get(assignment, "storageHasHeating")],
              },
              {
                type: "selectIsolationType",
                prefix: "isolationTypeOptions",
                values: get(assignment, "storageInsulationTypes", [null]),
              },
            ],
          })}
          {assignment.garages &&
            assignment.garages.length > 0 &&
            assignment.garages.map((garage, idx) => {
              return renderSection({
                title: "garage",
                key: idx,
                data: [
                  {
                    type: "numberOfGarages",
                    prefix: "parkingFacilityOptions",
                    values: [get(assignment, "numberOfGarages")],
                  },
                  {
                    type: "garageType",
                    prefix: "garageTypeOptions",
                    values: [get(garage, "garageType")],
                  },
                  {
                    type: "selectIsolationType",
                    prefix: "isolationTypeOptions",
                    values: get(garage, "insulationTypes", [null]),
                  },
                  {
                    type: "length",
                    prefix: "length",
                    values: get(garage, "size.length")
                      ? [get(garage, "size.length") + " m"]
                      : null,
                  },
                  {
                    type: "width",
                    prefix: "width",
                    values: get(garage, "size.width")
                      ? [get(garage, "size.width") + " m"]
                      : null,
                  },
                  {
                    type: "height",
                    prefix: "height",
                    values: get(garage, "size.height")
                      ? [get(garage, "size.height") + " m"]
                      : null,
                  },
                  {
                    type: "volume",
                    prefix: "volume",
                    values: get(garage, "size.volume")
                      ? [get(garage, "size.volume") + " m3"]
                      : null,
                  },
                  {
                    type: "area",
                    prefix: "area",
                    values: get(garage, "size.area")
                      ? [get(garage, "size.area") + " m2"]
                      : null,
                  },
                ],
              });
            })}
          {renderSection({
            title: "parking",
            data: [
              {
                type: "parkingFacilities",
                prefix: "parkingFacilityOptions",
                values: get(assignment, "parkingFacilities", [null]),
              },
              {
                type: "totalParkingCapacity",
                prefix: "parkingFacilityOptions",
                values: [get(assignment, "totalParkingCapacity")],
              },
              {
                type: "indoorParkingCapacity",
                prefix: "parkingFacilityOptions",
                values: [get(assignment, "indoorParkingCapacity")],
              },
            ],
          })}
          {renderSection({
            title: "maintenance",
            data: [
              {
                type: "maintenanceInside",
                prefix: "conditionOptions",
                values: [get(assignment.maintenanceInside, "rating")],
              },
              {
                type: "maintenanceOutside",
                prefix: "conditionOptions",
                values: [get(assignment.maintenanceOutside, "rating")],
              },
            ],
          })}
        </>
      );
    }
  )
);
