export const DYNAMIC_DOCUMENTS = {
  SET_DOCUMENT_TEMPLATES_STATUS: "SET_DOCUMENT_TEMPLATES_STATUS",
  SET_DOCUMENT_TEMPLATES: "SET_DOCUMENT_TEMPLATES",
  APPEND_DOCUMENT_TEMPLATES: "APPEND_DOCUMENT_TEMPLATES",
  SET_DOCUMENT: "DYNDOC_SET_DOCUMENT",
  SET_DOCUMENT_STATUS: "DYNDOC_SET_DOCUMENT_STATUS",
  SET_HTML_PREVIEW: "DYNDOC_SET_HTML_PREVIEW",
  SET_HTML_PREVIEW_STATUS: "DYNDOC_SET_HTML_PREVIEW_STATUS",
  SET_ANCHOR_NAME: "DYNDOC_SET_ANCHOR_NAME",
  SET_DOCUMENT_SESSION: "DYNODOC_SET_DOCUMENT_SESSION",
  TOGGLE_DYNAMIC_DOCUMENTS_SAVE_MODAL:
    "DYNODOC_TOGGLE_DYNAMIC_DOCUMENTS_SAVE_MODAL",
  SET_HTML_PREVIEW_REFRESH_STATUS: "DYNDOC_SET_HTML_PREVIEW_REFRESH_STATUS",
  REFRESH_HTML_PREVIEW: "DYNDOC_REFRESH_HTML_PREVIEW",
  SET_USERFIELD_NAME: "DYNDOC_SET_USERFIELD_NAME",
  CLEAR_HTML_PREVIEW: "DYNDOC_CLEAR_HTML_PREVIEW",
  RESET_FOCUS: "DYNDOC_RESET_FOCUS"
};
