export const OFFICES = {
  SET_OFFICE: "SEARCH_OFFICE",
  SET_FETCH_OFFICES_STATE: "SET_FETCH_OFFICES_STATE",
  SET_FETCH_OFFICE_OVERVIEW_STATE: "SET_FETCH_OFFICE_OVERVIEW_STATE",
  SET_OFFICES: "SET_OFFICES",
  APPEND_OFFICES: "APPEND_OFFICES",
  SET_INITIAL_FETCH_OFFICE_OVERVIEW_STATE: "SET_INITIAL_FETCH_OFFICE_OVERVIEW_STATE",
  SET_OFFICE_ID: "SET_OFFICE_ID",
  SET_LINKED_OFFICES: "SET_LINKED_OFFICES",
  SET_OFFICE_ASSIGNMENT_STATE: "SET_OFFICE_ASSIGNMENT_STATE",
  SEARCH_OFFICE_ASSIGNMENTS: "SEARCH_OFFICE_ASSIGNMENTS",
  APPEND_OFFICE_ASSIGNMENTS: "APPEND_OFFICE_ASSIGNMENTS",
  CLEAR_OFFICES: "CLEAR_OFFICES",
  SEARCH_OFFICE: "SEARCH_OFFICE",
  SET_SAVE_OFFICE_INFO_STATE: "SET_SAVE_OFFICE_INFO_STATE",
  SET_OFFICE_ADDRESS_SEARCH_STATE: "SET_OFFICE_ADDRESS_SEARCH_STATE",
  SET_INITAL_OFFICE_EMPLOYEE_OVERVIEW_STATE: "SET_INITAL_OFFICE_EMPLOYEE_OVERVIEW_STATE",
  CLEAR_EMPLOYEE_LIST: "CLEAR_EMPLOYEE_LIST",
  SET_EMPLOYEE_LIST: "SET_EMPLOYEE_LIST",
  ADD_SPOKEN_LANGUAGE: "ADD_SPOKEN_LANGUAGE",
  RESET_SEARCH_STATE: "RESET_SEARCH_STATE",
  SET_OFFICE_ASSIGNMENT_INITIAL_STATE: "SET_OFFICE_ASSIGNMENT_INITIAL_STATE",
  CLEAR_ASSIGNMENT_LIST: "CLEAR_ASSIGNMENT_LIST",
  ARCHIVE_OFFICE: "ARCHIVE_OFFICE",
  UN_ARCHIVE_OFFICE: "UN_ARCHIVE_OFFICE",
  REMOVE_OFFICE_FROM_LIST: "REMOVE_OFFICE_FROM_LIST",
  ARCHIVE_OFFICE_EMPLOYEE: "ARCHIVE_OFFICE_EMPLOYEE",
  SET_TOTAL_OFFICES: "SET_TOTAL_OFFICES",
  SET_OFFICE_EMPLOYEE_SAVE_STATE: "SET_OFFICE_EMPLOYEE_SAVE_STATE"
};
