import { REDUX } from "@haywork/constants";
import * as ActionType from "./new-entity.types";

const setNewEntity = (payload: ActionType.NewEntityPayload) => ({
  type: REDUX.NEW_ENTITY.SET_NEW_ENTITY,
  ...payload
});

const setEntityResult = (payload: ActionType.EntityResultPayload) => ({
  type: REDUX.NEW_ENTITY.SET_ENTITY_RESULT,
  ...payload
});

const removeEntity = (id: string) => ({
  type: REDUX.NEW_ENTITY.REMOVE_ENTITY,
  id
});

export const NewEntityActions = {
  setNewEntity,
  setEntityResult,
  removeEntity
};
