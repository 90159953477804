import { actions as MainActions } from "./actions";
import {
  reducer as mainReducer,
  State,
  FilterState,
  EmailRequestState,
} from "./reducer";

export type MainState = State;
export type EmailFiltersState = FilterState;
export { mainReducer, MainActions, EmailRequestState };
