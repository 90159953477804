import { WidgetEntityType } from "@haywork/api/authorization";
import { ActiveFilter } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Input } from "@haywork/modules/form";
import Notes from "@haywork/modules/notes-v3";
import { ProjectDetailTypesContainerProps } from "@haywork/modules/project/containers/detail-types.container";
import { InfiniteScroll } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ProjectDetailTypesListItemComponent } from "./detail-types-list-item.component";

const styles = require("./detail-types.component.scss");
const ACTIVEFILTERS = [
  { value: ActiveFilter.ActiveOnly, label: ActiveFilter.ActiveOnly },
  {
    value: ActiveFilter.ActiveOrInactive,
    label: ActiveFilter.ActiveOrInactive,
  },
  { value: ActiveFilter.InactiveOnly, label: ActiveFilter.InactiveOnly },
];

export interface ProjectDetailTypesComponentProps {}
interface State {
  filterByActive: ActiveFilter;
}
type Props = ProjectDetailTypesComponentProps &
  ProjectDetailTypesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailTypesComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.activeFilterChanged = this.activeFilterChanged.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);

    this.state = {
      filterByActive: ActiveFilter.ActiveOnly,
    };
  }

  public componentDidMount() {
    this.props.getObjectTypes(
      this.props.projectId,
      this.state.filterByActive,
      true
    );
    this.props.getInitializedWidgets(
      this.props.projectId,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    const { objectTypes, projectId } = this.props;
    const { filterByActive } = this.state;

    const types = objectTypes.filter((type) => {
      switch (filterByActive) {
        case ActiveFilter.ActiveOnly: {
          return !!type.isActive;
        }
        case ActiveFilter.InactiveOnly: {
          return !type.isActive;
        }
        default: {
          return true;
        }
      }
    });

    return (
      <div styleName="types">
        <PageHeader
          title="pageTitle.project.objectTypes"
          subTitle={this.props.displayName}
          actions={
            <>
              <Notes />
              {this.props.canCreateObjectTypeAssignment && (
                <Button
                  label="projectMenuAddType"
                  icon={<Icon name="plus" size={18} light />}
                  category="primary"
                  onClick={() => this.props.onAddNewType(projectId)}
                />
              )}
            </>
          }
        />

        <div styleName="filters">
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="objectTypes" className="pre">
                  <I18n value="project.filtering.objectTypes.inputLabel" />
                </label>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <Input.NewSelect
                  name="filterByActive"
                  values={ACTIVEFILTERS}
                  value={this.state.filterByActive}
                  valuesProp="value"
                  displayProp="label"
                  onChange={this.activeFilterChanged}
                  translate
                  translatePrefix="ObjectTypeActiveFilter"
                />
              </div>
            </div>
          </div>
        </div>

        <div styleName="body">
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {types.map((objectType, idx) => (
              <ErrorBoundary key={objectType.id}>
                <ProjectDetailTypesListItemComponent
                  objectType={objectType}
                  zebra={idx % 2 === 0}
                  onObjectTypeClick={this.props.goToObjectTypeDetail}
                />
              </ErrorBoundary>
            ))}

            {!this.props.loading &&
              !this.props.canLoadMore &&
              this.props.objectTypes.length === 0 && (
                <Ui.EmptyState
                  type={UiEmptyStateType.List}
                  stickman={UiEmptyStateStickMan.NoAroItems}
                  title="emptyStateNoObjectTypesTitle"
                  subtitle={"emptyStateNoObjectTypesBody"}
                />
              )}

            {this.props.loading && <Ui.Loaders.List />}
          </InfiniteScroll>
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    if (!!this.props.loading || !this.props.canLoadMore) return;
    this.props.getObjectTypes(this.props.projectId, this.state.filterByActive);
  }

  private activeFilterChanged(filterByActive: ActiveFilter) {
    if (this.state.filterByActive === filterByActive) return;

    this.setState({ filterByActive });
    this.props.getObjectTypes(this.props.projectId, filterByActive, true);
  }
}
