import {
  Language,
  PhotoBlob,
  TestimonialTranslation,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import {
  AvatarUploadTriggerComponent,
  Form,
  formControl,
  FormControls,
  FormReturnValue,
  Input,
  InputComponentProps,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./testimonial-input.component.scss");

export interface TestimonialObject {
  iList: TestimonialTranslation[];
  testimonialPhoto: PhotoBlob;
}

interface TranslateTestimonialComponentProps {
  languages: Language[];
}
interface State {
  current: Language;
  testimonialPhoto: PhotoBlob | string;
  iList: TestimonialTranslation[];
}
type Props = TranslateTestimonialComponentProps & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class TranslateTestimonialComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      current: Language.Dutch,
      testimonialPhoto: this.props.value.testimonialPhoto || "",
      iList: this.props.value.iList || [],
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onListingPhotoChange = this.onListingPhotoChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderLanguageButton = this.renderLanguageButton.bind(this);
  }

  public render() {
    const values = this.prerenderValues();

    const formControls: FormControls = {
      testimonialPhoto: { value: this.state.testimonialPhoto },
    };

    return (
      <div styleName="testimonial">
        <div styleName="laguage-select">
          {this.props.languages.map(this.renderLanguageButton)}
        </div>
        <div styleName="image">
          <Form
            name="testimonial-image"
            formControls={formControls}
            asSubForm
            onChange={this.onListingPhotoChange}
          >
            <Input.Avatar name="testimonialPhoto">
              <AvatarUploadTriggerComponent />
            </Input.Avatar>
          </Form>
        </div>
        <div styleName="fields">
          {values.map((lang, idx) => {
            const langToLowercase = lang.language.toString().toLowerCase();
            return (
              lang.language === this.state.current && (
                <div styleName="form-fields" key={idx}>
                  <div className="form__row">
                    <label htmlFor={`testimonial-title-${langToLowercase}`}>
                      <ResourceText resourceKey="testimonialTitle" />
                    </label>
                    <input
                      type="text"
                      id={`testimonial-title-${langToLowercase}`}
                      name={`testimonial-title-${langToLowercase}`}
                      value={lang.title}
                      onChange={(event) =>
                        this.onChangeHandler(event, "title", lang.language)
                      }
                      placeholder={intlContext.formatMessage({
                        id: "testimonialTitlePlaceholder",
                        defaultMessage: "testimonialTitlePlaceholder",
                      })}
                      maxLength={250}
                      data-lpignore="true"
                    />
                  </div>
                  <div className="form__row">
                    <label
                      htmlFor={`testimonial-customerName-${langToLowercase}`}
                    >
                      <ResourceText resourceKey="testimonialCustomerName" />
                    </label>
                    <input
                      type="text"
                      id={`testimonial-customerName-${langToLowercase}`}
                      name={`testimonial-customerName-${langToLowercase}`}
                      value={lang.customerName}
                      onChange={(event) =>
                        this.onChangeHandler(
                          event,
                          "customerName",
                          lang.language
                        )
                      }
                      placeholder={intlContext.formatMessage({
                        id: "testimonialCustomerNamePlaceholder",
                        defaultMessage: "testimonialCustomerNamePlaceholder",
                      })}
                      data-lpignore="true"
                    />
                  </div>
                  <div className="form__row" styleName="description">
                    <label
                      htmlFor={`testimonial-description-${langToLowercase}`}
                    >
                      <ResourceText resourceKey="testimonialDescription" />
                    </label>
                    <textarea
                      id={`testimonial-description-${langToLowercase}`}
                      name={`testimonial-description-${langToLowercase}`}
                      key={idx}
                      onChange={(event) =>
                        this.onChangeHandler(
                          event,
                          "description",
                          lang.language
                        )
                      }
                      value={lang.description}
                      placeholder={intlContext.formatMessage({
                        id: "testimonialDescriptionPlaceholder",
                        defaultMessage: "testimonialDescriptionPlaceholder",
                      })}
                      data-lpignore="true"
                    />
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }

  private onListingPhotoChange(values: FormReturnValue) {
    this.setState({ testimonialPhoto: values.testimonialPhoto });
    this.props.onChange({
      iList: this.state.iList,
      testimonialPhoto: values.testimonialPhoto,
    });
  }

  private onClickHandler(current: Language) {
    this.setState({ current });
  }

  private onChangeHandler(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    propName: string,
    language: Language
  ) {
    const value = this.props.value.iList || [];
    const ref = value.find((v) => v.language === language);
    if (!ref)
      value.push({ language, customerName: "", description: "", title: "" });

    const iList = value.map((lang) => {
      if (lang.language === language) {
        return { ...lang, [propName]: event.target.value };
      }
      return lang;
    });

    this.props.onChange({
      iList,
      testimonialPhoto: this.state.testimonialPhoto,
    });
    this.setState({ iList });
  }

  private renderLanguageButton(
    language,
    idx
  ): React.ReactElement<HTMLDivElement> {
    const btnStyle = classNames(
      "btn",
      language === this.state.current ? "btn-white" : "btn-blank"
    );
    let btnIcon = "nl";

    switch (language) {
      case Language.English:
        btnIcon = "gb";
        break;
      case Language.Estonian:
        btnIcon = "ee";
        break;
      case Language.Georgian:
        btnIcon = "ge";
        break;
      case Language.German:
        btnIcon = "de";
        break;
      default:
        break;
    }

    return (
      <div
        className={btnStyle}
        key={idx}
        onClick={() => this.onClickHandler(language)}
      >
        <span className={`famfamfam-flag-${btnIcon}`} />
      </div>
    );
  }

  private prerenderValues(): TestimonialTranslation[] {
    const values = this.state.iList || [];

    return this.props.languages.map((language) => {
      const value = values.find((v) => v.language === language);
      return !value
        ? { language, customerName: "", description: "", title: "" }
        : value;
    });
  }
}

export const TestimonialComponent = formControl(TranslateTestimonialComponent);
