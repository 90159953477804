import { Wootric } from "@haywork/components/wootric/wootric";
import { COUNTS, EXTERNALROUTES, MAINROUTES } from "@haywork/constants";
import { ConfirmContainer } from "@haywork/modules/confirm";
import { ErrorContainer, UnauthorizedModal } from "@haywork/modules/error";
import { EventsModal } from "@haywork/modules/event-center";
import EventCenterTrigger from "@haywork/modules/event-center-v2/components/trigger";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { GlobalSearchSearchContainer } from "@haywork/modules/global-search";
import {
  CreateMenuContainer,
  DefaultLayoutContainerProps,
  MainMenu,
  UserMenuContainer,
  UserSnapComponent,
} from "@haywork/modules/layout";
import { ReminderModalContainer } from "@haywork/modules/reminder";
import {
  Alert,
  AuthorizationWidgetModal,
  Lightbox,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import Snackbar from "@haywork/modules/snackbar-v2";
import { TabBarContainer } from "@haywork/modules/tab";
import { Ui } from "@haywork/modules/ui";
import VoipModule from "@haywork/modules/voip";
import ZohoModule from "@haywork/modules/zoho";
import { AnalyticsService, Redirect } from "@haywork/services";
import * as bowser from "bowser";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import { BackofficeList } from "../backoffice-list";
import Help from "../help";
import { DefaultLayoutRouting } from "./default-layout.routing";
import I18n from "@haywork/components/i18n";

const styles = require("./default-layout.component.scss");
const analytics = new AnalyticsService();
const kolibriLogoVisual = require("static/Icon_dark.svg");
const kolibriLogoPayoff = require("static/Wordmark_light.svg");
const exchangeIcon = require("static/logo_icon-exchange.svg");
const housenetIcon = require("static/logo_icon-housenet.svg");

export interface DefaultLayoutComponentProps {}
interface State {}
type Props = DefaultLayoutComponentProps & DefaultLayoutContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DefaultLayoutComponent extends React.Component<Props, State> {
  private historyUnListen: any;
  private browserShouldUpgrade: boolean = false;

  constructor(props) {
    super(props);

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onHousenetClickHandler = this.onHousenetClickHandler.bind(this);
    this.onExchangeClickHandler = this.onExchangeClickHandler.bind(this);
    this.handleMainMenuClick = this.handleMainMenuClick.bind(this);

    this.historyUnListen = this.props.history.listen((location) =>
      analytics.view(location.pathname)
    );

    const browser = bowser.getParser(window.navigator.userAgent);
    this.browserShouldUpgrade = browser.satisfies({
      "internet explorer": "<12",
      edge: "<16",
      safari: "<11.2",
      chrome: "<68",
      firefox: "<61",
      opera: "<54",
      mobile: {
        safari: "<11.2",
      },
    });
  }

  public render() {
    const layoutStyle = classNames("default-layout", {
      collapsed: this.props.sidebarCollapsed,
      fullscreen: this.props.fullscreen,
      "show-upgrade-label": this.browserShouldUpgrade,
      "safe-mode": this.props.hasOriginalPersonId,
    });

    return (
      <div styleName={layoutStyle}>
        {!!this.props.hasOriginalPersonId && (
          <div styleName="safe-mode-info">
            <I18n value="safe-mode.body" asHtml />
          </div>
        )}
        <div styleName="sidebar">
          <Link to={MAINROUTES.DASHBOARD.URI}>
            {!!this.props.customLogo ? (
              <div
                styleName="sidebar__custom-logo"
                style={{ backgroundImage: `url(${this.props.customLogo})` }}
              />
            ) : (
              <div styleName="sidebar__logo">
                <img src={kolibriLogoVisual} styleName="visual" />
                <div styleName="text">
                  <img src={kolibriLogoPayoff} styleName="payoff" />
                  {this.props.environment !== "PROD" && (
                    <span styleName="env">{this.props.environment}</span>
                  )}
                </div>
              </div>
            )}
          </Link>
          <div styleName="sidebar__menu">
            <MainMenu
              onLinkClick={(e, type) => this.handleMainMenuClick(e, type)}
              isBackofficeEmployee={this.props.isBackofficeEmployee}
              emailUnreadCount={this.props.emailUnreadCount}
              notificationUnreadCount={this.props.notificationUnreadCount}
              sidebarCollapsed={this.props.sidebarCollapsed}
              onCloseAllTabs={this.props.closeAllTabs}
              realEstateAgencyId={this.props.realEstateAgencyId}
              showPlayground={this.props.showPlayground}
            />
          </div>
          <div styleName="sidebar__footer">
            <UserSnapComponent usersnapApiKey={this.props.usersnapApiKey} />
            <div
              styleName="toggle"
              className="hidden-xs"
              onClick={this.toggleSidebar}
            >
              <div styleName="trigger">
                <i className="fal fa-fw fa-chevron-left" />
              </div>
            </div>
            <div styleName="version">
              {this.props.environment !== "PROD" && (
                <span styleName="env" className="label label-warning">
                  {this.props.environment}
                </span>
              )}
              <span styleName="text">V {this.props.version}</span>
            </div>
          </div>
        </div>
        <div styleName="main-view">
          <div styleName="header">
            <div styleName="toggle" onClick={this.toggleSidebar}>
              <div styleName="trigger">
                <i className="fal fa-fw fa-chevron-left" />
              </div>
            </div>

            <GlobalSearchSearchContainer />

            {!this.props.isBackofficeEmployee && (
              <>
                <FeatureSwitch feature="EVENTCENTER_V2" disable>
                  <EventsModal />
                </FeatureSwitch>
                <FeatureSwitch feature="EVENTCENTER_V2">
                  <EventCenterTrigger
                    numberOfNotArchivedEvents={
                      this.props.notificationUnreadCount
                    }
                    onNavigate={this.props.navigate}
                  />
                </FeatureSwitch>
              </>
            )}

            <span
              styleName="housenet"
              className="hidden-xs"
              onClick={this.onHousenetClickHandler}
            >
              <span styleName="img__icon housenet">
                <img src={housenetIcon} />
              </span>
              <span className="sr-only">
                <ResourceText resourceKey="OutlookbarHousenet" />
              </span>
            </span>

            <FeatureSwitch feature="MLS" disable>
              <span
                styleName="exchange"
                className="hidden-xs"
                onClick={this.onExchangeClickHandler}
              >
                <span styleName="img__icon exchange">
                  <img src={exchangeIcon} />
                </span>
                <span className="sr-only">
                  <ResourceText resourceKey="OutlookbarExchange" />
                </span>
              </span>
            </FeatureSwitch>

            {this.props.linkedRealEstateAgencies.length >
              COUNTS.LINKED_REALESTATE_AGENCIES && (
              <BackofficeList
                realEstateAgencyId={this.props.realEstateAgencyId}
                linkedRealEstateAgencies={this.props.linkedRealEstateAgencies}
                onRealestateAgencyClick={this.props.switchRealEstateAgency}
              />
            )}

            <UserMenuContainer />
            <Help />
          </div>
          <div styleName="sub-header">
            <CreateMenuContainer />
            <TabBarContainer />
          </div>
          <div styleName="body">
            {this.props.createLoaderVisible && (
              <PageLoader loading fullscreen />
            )}
            <DefaultLayoutRouting
              realEstateAgencyId={this.props.realEstateAgencyId}
            />
          </div>
        </div>
        {this.browserShouldUpgrade && (
          <div styleName="upgrade-label">
            <ResourceText
              resourceKey="browserShouldUpgrade"
              asHtml
              values={{ url: EXTERNALROUTES.SUPPORT_BROWSER_UPDATE.URI }}
            />
          </div>
        )}
        <Lightbox />
        <FeatureSwitch feature="NPS">
          <Wootric />
        </FeatureSwitch>
        <ConfirmContainer />
        <Ui.Onboarding />
        <ReminderModalContainer />
        <Ui.WindowFrame />
        <ErrorContainer />
        <Snackbar />
        <UnauthorizedModal />
        <FeatureSwitch feature="VOIP">
          <VoipModule />
        </FeatureSwitch>
        <FeatureSwitch feature="ZOHO_INTEGRATION">
          <ZohoModule />
        </FeatureSwitch>
        <AuthorizationWidgetModal />
        <Alert
          visible={this.props.blobErrorVisible}
          titleResourceKey="blob.error.title"
          bodyResourceKey={this.props.blobError}
          onClose={this.props.closeBlobError}
        />
      </div>
    );
  }

  public componentWillUnmount() {
    if (this.historyUnListen) this.historyUnListen();
  }

  public toggleSidebar() {
    this.props.onToggleSidebar();
  }

  private handleMainMenuClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type?: "MLS"
  ) {
    if (type === "MLS") {
      event.preventDefault();
      event.stopPropagation();
      this.props.navigate(MAINROUTES.MLS.URI);
    }
    this.props.onMainMenuLinkClick();
  }

  private async onExchangeClickHandler() {
    if (!this.props.exchangeEnabled) {
      return window.open(EXTERNALROUTES.EXCHANGE.URI, "_blank");
    }

    const redirect = new Redirect();

    try {
      const url = await this.props.getExchangeSingleSignOnUrl();
      redirect.navigate(url);
    } catch (error) {
      redirect.navigate(EXTERNALROUTES.EXCHANGE.URI);
    }
  }

  private async onHousenetClickHandler() {
    if (!this.props.housenetEnabled) {
      return window.open(EXTERNALROUTES.HOUSENET_INFO.URI, "_blank");
    }

    const redirect = new Redirect();

    try {
      const url = await this.props.getHousenetSingleSignOnUrl();
      redirect.navigate(url);
    } catch (error) {
      redirect.navigate(EXTERNALROUTES.HOUSENET_INFO.URI);
    }
  }
}
