import { RelationSnapShot, AgendaItemCategory } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { REQUEST, SCHEDULERROUTES } from "@haywork/constants";
import {
  CheckboxFilter,
  Filter,
  MappedCheckboxValue,
  RadioGroupFilter,
} from "@haywork/modules/filter";
import { Input } from "@haywork/modules/form";
import {
  AccessDeniedModalComponent,
  SchedulerOverviewContainerProps,
} from "@haywork/modules/scheduler";
import {
  ConfirmComponent,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import { SchedulerFilters } from "@haywork/stores";
import { ColorUtil, RouteUtil, SimpleLabelValue } from "@haywork/util";
import * as equal from "deep-equal";
import isArray from "lodash-es/isArray";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { isMobile } from "react-device-detect";
import DayDateHeaderTemplate from "./utils/day-date-header-template";
import MajorTimeHeaderTemplate from "./utils/major-time-header-template";
import SchedulerControl from "./utils/scheduler-control";
import SchedulerMainEventTemplate from "./utils/scheduler-main-event-template";
import {
  getSchedulerView,
  getSchedulerViewDateRange,
} from "./utils/scheduler-utils";
import TooltipMainTemplate from "./utils/tooltip-main-template";
import WeekDateHeaderTemplate from "./utils/week-date-header-template";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./overview.component.scss");

enum FilterCompletedCanceled {
  All = "All",
  WithoutStatus = "WithoutStatus",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
}

export interface SchedulerOverviewComponentProps {}
interface State {
  activePanelKey?: string;
  wizardFullScreenMode?: boolean;
  scheduler: kendo.ui.Scheduler;
  selectedCategories: SimpleLabelValue[];
  selectedEmployees: SimpleLabelValue[];
  schedulerCantOpenModelIsOpen: boolean;
  agendaItemMovingInitialEmployeeId?: string;
  isPending: boolean;
  triedToDelete: boolean;
  agendaItemMoveConfirmedModalVisible: boolean;
  kendoEvent: kendo.ui.SchedulerMoveEndEvent;
  loadingExport?: boolean;
}
interface CategorySource {
  id: string;
  backColor: string;
  displayName: string;
}
interface DelimiterRule {
  pixels: {
    min?: number;
    max?: number;
  };
  day: number;
  week: number;
  month: number;
}
type Props = SchedulerOverviewComponentProps & SchedulerOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SchedulerOverviewComponent extends React.Component<Props, State> {
  private readonly DAY_VIEW = "day";
  private readonly CATEGORY_RESOURCE = "category_resource";
  private readonly EMPLOYEES_RESOURCE = "employees_resource";
  private scheduler: any = null;
  private isEditRecurrinItem = false;
  private originalStartDateTime: Date = null;
  private originalParentId: string = "";
  private agendaItemMovedAcrossItialEmployeeArea = false;
  private shedulerTooltip = null;
  private onScrollTimeout = null;
  private schedulerTooltipLinks = null;
  private intervalSchedulerTimeColumnHighlight = null;
  private timer = null;

  private delimiters: DelimiterRule[] = [
    {
      pixels: {
        max: 768,
      },
      day: 2,
      week: 1,
      month: 1,
    },
    {
      pixels: {
        min: 768,
        max: 992,
      },
      day: 2,
      week: 1,
      month: 1,
    },
    {
      pixels: {
        min: 992,
        max: 1200,
      },
      day: 3,
      week: 2,
      month: 2,
    },
    {
      pixels: {
        min: 1200,
      },
      day: 4,
      week: 3,
      month: 2,
    },
  ];

  private schedulerResources = [
    {
      field: "linkedAgendaItemCategory.id",
      dataSource: [],
      dataColorField: "backColor",
      dataTextField: "displayName",
      dataValueField: "id",
      title: "Category",
      name: this.CATEGORY_RESOURCE,
    },
    {
      field: "linkedEmployeesIds", // The field of the Scheduler event which contains the resource identifier.
      title: "Employees", // The label displayed in the Scheduler edit form for this resource.
      name: this.EMPLOYEES_RESOURCE,
      dataTextField: "displayName",
      dataValueField: "id",
      multiple: true,
      dataSource: [],
    },
  ];

  private schedulerDataSourceSchema = {
    model: {
      fields: {
        id: { from: "id", type: "string" },
        title: { from: "subject", defaultValue: "No title" },
        start: { type: "date", from: "startDateTime" },
        end: { type: "date", from: "endDateTime" },
        isAllDay: { type: "boolean", from: "allDayEvent" },
        recurrenceRule: { type: "string", from: "recurrencePattern" },
        recurrenceId: { type: "string", from: "parentId" },
        parentId: { type: "string", from: "parentId" },
        recurrenceException: {
          type: "date",
          from: "exceptionDates",
          parse: (dates: Date[]) => {
            // strange bug, can't figure out what's going wrong. When adding an exception to an occurence and reloading the scheduler it
            // fires this event with a lot of strange data that's seemingly coming from nowhere. I'm going to assume it's a Telerik bug for now.
            if (!dates) {
              return "";
            }

            if (isArray(dates)) {
              const formatedDates = dates.reduce((state, date) => {
                const valid = moment(date).isValid();
                if (valid) {
                  const dateToUse = new Date(date);
                  state.push(dateToUse.toISOString());
                }
                return state;
              }, []);
              const result = formatedDates.join(",");
              return result;
            } else {
              return dates;
            }
          },
        },
        linkedAgendaItemCategory: { type: "object", nullable: true },
        linkedEmployees: { type: "object" },
        linkedEmployeesIds: { type: "object" }, // This is just to make 'group' option of the scheduler work.
        linkedAssignments: { type: "object", nullable: true },
        linkedRelations: { type: "object", nullable: true },
        isPrivate: { type: "boolean" },
        isConfirmed: { type: "boolean" },
        isRecurringEvent: { type: "boolean" },
        priority: { type: "string", nullable: true },
        isCanceled: { type: "boolean" },
      },
    },
  };

  private tooltipOptions: any = {
    filter: ".k-event:not(.k-event-drag-hint) > div, .k-task",
    position: "right",
    width: 400,
    content: kendo.template(TooltipMainTemplate),
    showOn: "click mouseenter",
    autoHide: true,
    show: this.onShowSchedulerTooltip.bind(this),
    hide: this.onHideSchedulerTooltip.bind(this),
  };

  constructor(props) {
    super(props);

    this.state = {
      scheduler: null,
      selectedCategories: [],
      selectedEmployees: [
        {
          label: this.props.loggedInEmployee.displayName,
          value: this.props.loggedInEmployee.id,
        },
      ],
      schedulerCantOpenModelIsOpen: false,
      agendaItemMovingInitialEmployeeId: null,
      isPending: false,
      triedToDelete: false,
      agendaItemMoveConfirmedModalVisible: false,
      kendoEvent: null,
      loadingExport: false,
    };

    this.onSchedulerCreated = this.onSchedulerCreated.bind(this);
    this.initTooltip = this.initTooltip.bind(this);
    this.scrollToWorkingHours = this.scrollToWorkingHours.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.onChangeFilterHandler = this.onChangeFilterHandler.bind(this);
    this.onInitializeSchedulerWithData =
      this.onInitializeSchedulerWithData.bind(this);
    this.dateRangeChanged = this.dateRangeChanged.bind(this);
    this.onRemoveAgendaItem = this.onRemoveAgendaItem.bind(this);
    this.moveStartAgendaItem = this.moveStartAgendaItem.bind(this);
    this.agendaItemMoved = this.agendaItemMoved.bind(this);
    this.editAgendaItem = this.editAgendaItem.bind(this);
    this.newAgendaItem = this.newAgendaItem.bind(this);
    this.exportScheduler = this.exportScheduler.bind(this);
    this.resizeAgendaitem = this.resizeAgendaitem.bind(this);
    this.agendaItemMoving = this.agendaItemMoving.bind(this);
    this.onShowSchedulerTooltip = this.onShowSchedulerTooltip.bind(this);
    this.removeSelectedEmployee = this.removeSelectedEmployee.bind(this);
    this.removeSelectedCategory = this.removeSelectedCategory.bind(this);
    this.onSelectMajorTickChangeHandler =
      this.onSelectMajorTickChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps) {
    if (this.props.sideBarToggled !== prevProps.sideBarToggled) {
      if (this.scheduler) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.scheduler.refresh();
        });
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.schedulerOverviewState === REQUEST.PENDING &&
      nextProps.schedulerOverviewState === REQUEST.SUCCESS
    ) {
      this.setState({ isPending: false });
    }

    if (!equal(nextProps.agendaItems, this.props.agendaItems)) {
      const dataSource = this.getDataSource(nextProps.agendaItems);
      if (!dataSource) return;
      this.scheduler.setDataSource(dataSource);
    }

    if (
      !equal(nextProps.agendaItemCategories, this.props.agendaItemCategories)
    ) {
      this.applyCategorySchedulerResourceDataSource(
        nextProps.agendaItemCategories
      );
    }

    if (
      this.props.schedulerFilters &&
      this.props.schedulerFilters.employeeIds.length !==
        nextProps.schedulerFilters.employeeIds.length
    ) {
      this.applyEmployeesSchedulerResource(
        nextProps.schedulerFilters.employeeIds
      );
    }
    this.adaptSchedulerWidth();
  }

  public componentWillUnmount(): void {
    this.clearOnUnmount();
  }

  public render() {
    const shedulerViews = [
      {
        type: "day",
        dateHeaderTemplate: kendo.template(DayDateHeaderTemplate),
        selected: false,
      },
      {
        type: "week",
        dateHeaderTemplate: kendo.template(WeekDateHeaderTemplate),
        selected: true,
      },
      {
        type: "workWeek",
        dateHeaderTemplate: kendo.template(WeekDateHeaderTemplate),
        selected: false,
      },
      { type: "month", selected: false },
      { type: "agenda", selected: false },
    ];
    const { isCanceled, isConfirmed } = this.props.schedulerFilters;
    let filterCompletedCanceled: FilterCompletedCanceled;
    switch (true) {
      case isCanceled === false && isConfirmed === false: {
        filterCompletedCanceled = FilterCompletedCanceled.WithoutStatus;
        break;
      }
      case isCanceled === true: {
        filterCompletedCanceled = FilterCompletedCanceled.Cancelled;
        break;
      }
      case isConfirmed === true: {
        filterCompletedCanceled = FilterCompletedCanceled.Confirmed;
        break;
      }
      default: {
        filterCompletedCanceled = FilterCompletedCanceled.All;
        break;
      }
    }

    return (
      <div styleName="scheduler-overview">
        <AccessDeniedModalComponent
          triedToDelete={this.state.triedToDelete}
          showModal={this.state.schedulerCantOpenModelIsOpen}
          onClose={() => {
            this.setState({ schedulerCantOpenModelIsOpen: false });
          }}
        />
        <ConfirmComponent
          visible={this.state.agendaItemMoveConfirmedModalVisible}
          titleResourceKey={intlContext.formatMessage({
            id: "scheduler.confirmMove.title",
          })}
          bodyResourceKey={intlContext.formatMessage({
            id: "scheduler.confirmMove.message",
          })}
          bodyValues={""}
          onClose={() => {
            this.setState({
              agendaItemMoveConfirmedModalVisible: false,
            });
            return;
          }}
          onConfirm={() => {
            this.agendaItemMoved(this.state.kendoEvent, true);
            this.setState({
              agendaItemMoveConfirmedModalVisible: false,
              kendoEvent: null,
            });
            return;
          }}
        />

        <div styleName="scheduler__body">
          <div
            styleName="scheduler__sidebar"
            className={this.props.schedulerFilters.showFilters ? "active" : ""}
          >
            <Filter changeFilter={this.onChangeFilterHandler}>
              <CheckboxFilter
                name="filterByEmployees"
                disabled={this.state.isPending}
                values={this.props.employeesFilterValues}
                value={this.props.schedulerFilters.employeeIds}
                canToggle
                visible={false}
                subtitle={this.renderEmployeesSubtitle()}
                mustHaveOneValue
                title="employee"
                hideCheckedItems={true}
              />
              <CheckboxFilter
                name="filterByAgendaItemCategories"
                values={this.props.agendaItemCategoriesFilterValues}
                value={this.props.schedulerFilters.categoryIds}
                canToggle
                visible={false}
                subtitle={
                  this.props.schedulerFilters &&
                  this.props.schedulerFilters.categoryIds.length === 0
                    ? this.renderSubtitle(
                        this.props.agendaItemCategoriesFilterValues
                      )
                    : this.renderCategoriesSubtitle()
                }
                title="category"
                hideCheckedItems={true}
              />
              <div styleName="show-per-filter">
                <ResourceText resourceKey="showPer" />
                <Input.NewSelect
                  name="select"
                  values={this.props.majorTickFilters}
                  displayProp="label"
                  valuesProp="value"
                  value={this.props.schedulerFilters.majorTick}
                  onChange={this.onSelectMajorTickChangeHandler}
                />
              </div>

              <RadioGroupFilter
                title="schedulerFilter.filterCompletedCanceled"
                name="filterCompletedCanceled"
                value={filterCompletedCanceled}
              >
                <Input.Radio
                  label={`filterCompletedCanceled.${FilterCompletedCanceled.All.toString()}`}
                  value={FilterCompletedCanceled.All}
                />
                <Input.Radio
                  label={`filterCompletedCanceled.${FilterCompletedCanceled.Confirmed.toString()}`}
                  value={FilterCompletedCanceled.Confirmed}
                />
                <Input.Radio
                  label={`filterCompletedCanceled.${FilterCompletedCanceled.Cancelled.toString()}`}
                  value={FilterCompletedCanceled.Cancelled}
                />
                <Input.Radio
                  label={`filterCompletedCanceled.${FilterCompletedCanceled.WithoutStatus.toString()}`}
                  value={FilterCompletedCanceled.WithoutStatus}
                />
              </RadioGroupFilter>
            </Filter>

            <div styleName="scheduler__export">
              <button
                className="btn btn-block"
                onClick={() => this.exportScheduler()}
                disabled={this.state.loadingExport}
              >
                <Icon
                  name={this.state.loadingExport ? "spinner" : "file-excel"}
                  spin={this.state.loadingExport ? true : undefined}
                  regular
                  size={18}
                  containIn={24}
                  color={Colors.White}
                />
                <ResourceText resourceKey="exportExcel" />
              </button>
            </div>
          </div>
          <div
            styleName="scheduler__view"
            className={this.props.schedulerFilters.showFilters ? "active" : ""}
          >
            {this.props.schedulerOverviewState === REQUEST.PENDING && (
              <PageLoader loading fullscreen />
            )}
            <SchedulerControl
              resources={this.schedulerResources}
              messages={{
                allDay: intlContext.formatMessage({ id: "allDay" }),
                recurrenceMessages: {
                  editRecurring: intlContext.formatMessage({
                    id: "recurrencyBody",
                  }),
                  editWindowTitle: intlContext.formatMessage({
                    id: "recurrencyHeader",
                  }),
                  editWindowOccurrence: intlContext.formatMessage({
                    id: "thisAppointment",
                  }),
                  editWindowSeries: intlContext.formatMessage({
                    id: "entireSeries",
                  }),
                },
                views: {
                  agenda: intlContext.formatMessage({ id: "list" }),
                  workWeek: intlContext.formatMessage({ id: "workWeek" }),
                },
              }}
              onInitialized={this.onSchedulerCreated}
              views={shedulerViews}
              eventTemplate={SchedulerMainEventTemplate}
              allDayEventTemplate={SchedulerMainEventTemplate}
              majorTimeHeaderTemplate={kendo.template(MajorTimeHeaderTemplate)}
              uniqueControlId="scheduler"
              workDayStart={new Date(2017, 1, 1, 9)}
              workDayEnd={new Date(2017, 1, 1, 19)}
              timezone="Etc/UTC"
              dataBound={this.onDataBound}
              navigate={this.dateRangeChanged}
              remove={this.onRemoveAgendaItem}
              moveStart={this.moveStartAgendaItem}
              move={this.agendaItemMoving}
              moveEnd={this.agendaItemMoved}
              edit={this.editAgendaItem}
              add={this.newAgendaItem}
              resizeEnd={this.resizeAgendaitem}
              showWorkHours={this.props.schedulerFilters.showWorkingHours}
              singleClickEvent={(id) => this.singleEventClick(id)}
              majorTick={this.props.schedulerFilters.majorTick}
            />
          </div>
        </div>
      </div>
    );
  }

  private onSelectMajorTickChangeHandler(value: number) {
    if (this.props.schedulerFilters.majorTick === value) return;

    this.scheduler.options.majorTick = value;
    this.scheduler.view(this.scheduler.view().name);

    const filters: SchedulerFilters = {
      ...this.props.schedulerFilters,
      majorTick: value,
    };

    this.props.persistSchedulerFilters(filters);
  }

  private singleEventClick(id: string) {
    if (isMobile) {
      this.props.goToDetail(id);
    }
  }

  private moveStartAgendaItem(e) {
    if (isMobile) {
      e.preventDefault();
    }
  }

  // this method is used to dynamycaly update the scheduler dataSource in case we get new collection of the agenda items
  private getDataSource(data: any) {
    if (!data || data === null) return;

    const mData = data.map((item) => {
      // This is just to make 'group' option of the scheduler work.
      // It splits the scheduler view acording to the resources option, in our case we have to split the view by employees
      item.linkedEmployeesIds = item.linkedEmployees.map((item) => {
        return item.id;
      });
      // This is because some agendaItems do not have the categoryId and scheduler crashes
      item.linkedAgendaItemCategory = item.linkedAgendaItemCategory || {};
      item.parentId = item.parentId || null;
      return item;
    });

    if (mData && mData.length > 0) {
      const mParentData = [];
      mData.forEach((data) => {
        if (data.parentId) {
          const newAgendaItemSnapShot = {
            ...data,
            id: data.parentId,
            isRecurringEvent: true,
            recurrencePattern: "",
            parentId: "",
            startDateTime: null,
            endDateTime: null,
          };
          mParentData.push(newAgendaItemSnapShot);
        }
      });

      mParentData.forEach((element) => {
        mData.push(element);
      });
    }

    return new kendo.data.SchedulerDataSource({
      schema: this.schedulerDataSourceSchema,
      data: mData,
    });
  }

  private initTooltip(scheduler: kendo.ui.Scheduler) {
    const tooltip = $(scheduler.wrapper).kendoTooltip(this.tooltipOptions);
    tooltip.data("intl", intlContext);
    tooltip.data("getAgendaItem", this.props.getAgendaItem);
    this.shedulerTooltip = tooltip.data("kendoTooltip");
  }

  private onShowSchedulerTooltip(e: kendo.ui.TooltipEvent) {
    this.schedulerTooltipLinks = e.sender.popup.element
      .find(".schedulerTooltipLink")
      .click((e) => {
        const url = $(e.target).data("url");
        this.props.navigateToDetail(url);
      });
  }

  private onHideSchedulerTooltip(e: kendo.ui.TooltipEvent) {
    if (this.schedulerTooltipLinks) {
      this.schedulerTooltipLinks.off();
    }
  }

  private clearOnUnmount() {
    clearInterval(this.intervalSchedulerTimeColumnHighlight);
    $(document).off(this.tooltipOptions.showOn, this.tooltipOptions.filter);
    if (this.schedulerTooltipLinks) {
      this.schedulerTooltipLinks.off();
    }
    $("schedulerOverviewContainer").find("*").off();
    $("schedulerOverviewContainer").off();
    if (this.shedulerTooltip) {
      this.shedulerTooltip.destroy();
    }
    if (this.state.scheduler) {
      this.state.scheduler.element.off("dblclick");
    }
    $("div.k-scheduler-content").off("scroll");
    $("#scheduler-wrapper").off("transitionend");
    $(window).off("resize", this.adaptSchedulerWidth);
  }

  private onSchedulerCreated(scheduler: any) {
    this.setState({ ...this.state, scheduler });
    this.initTooltip(scheduler);
    this.initSchedulerTimeColumnHighlight(scheduler);
    $(window).on("resize", this.adaptSchedulerWidth);

    // Add toggle filter button to scheduler footer, bind toggle function
    $(scheduler.toolbar).prepend(
      `<ul class='k-reset k-scheduler-toggle-filter'>
                <li class='k-state-default k-header k-toggle-filter'>
                    <a id="toggle-filter" class='k-link'>
                        <i class="fal fa-fw fa-filter"></i>
                    </a>
                </li>
            </ul>`
    );
    $(scheduler.toolbar)
      .find("#toggle-filter")
      .on("click", () => {
        this.onToggleFilters();
      });

    this.scheduler = scheduler;
    this.onInitializeSchedulerWithData();
    this.applyCategorySchedulerResourceDataSource();

    if (this.props.schedulerFilters.employeeIds) {
      this.applyEmployeesSchedulerResource(
        this.props.schedulerFilters.employeeIds
      );
    }
  }

  private applyCategorySchedulerResourceDataSource(
    agendaItemCategories?: AgendaItemCategory[]
  ) {
    const categoryResource = this.scheduler.resources.find(
      (item) => item.name === this.CATEGORY_RESOURCE
    );
    const categoriesToUse = agendaItemCategories
      ? agendaItemCategories
      : this.props.agendaItemCategories;
    const categories: CategorySource[] = categoriesToUse.map((category) => {
      return {
        id: category.id,
        backColor: ColorUtil.hexToRgb(
          category.backColor ? category.backColor : ""
        ),
        displayName: category.displayName,
      };
    });
    categoryResource.dataSource.data(categories);
    const view = this.scheduler.view();
    if (view) {
      this.scheduler.view(this.props.schedulerFilters.view);
    }
  }

  private onToggleFilters() {
    this.onChangeShowFilter(!this.props.schedulerFilters.showFilters);
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.scheduler.refresh();
    }, 200);
  }

  private adaptSchedulerWidth() {
    if (!this.scheduler || !this.scheduler.view()) {
      return;
    }

    const LEFT_TIMEBAR_WIDTH = 72;
    const layout = $(".k-scheduler-layout:not(.k-scheduler-agendaview)");
    const headerTable = layout.find(".k-scheduler-header .k-scheduler-table");
    const contentTable = layout.find(".k-scheduler-content .k-scheduler-table");
    const timeColumn = $(
      ".k-scheduler-layout:not(.k-scheduler-agendaview) > tbody > tr > td:first-child"
    );

    const baseWidth = layout.width();
    const viewName = this.scheduler.view().name;
    let delimiter = this.getDelimiter(viewName, baseWidth);

    if (
      this.props.schedulerFilters.employeeIds.length > delimiter &&
      viewName !== this.DAY_VIEW
    ) {
      layout.css("table-layout", "fixed");

      // make sure the right amount of employees is shown for the current width of the window.
      if (delimiter > this.props.employees.length) {
        delimiter = this.props.employees.length;
      }
      const baseWidthPerEmployee = (baseWidth - LEFT_TIMEBAR_WIDTH) / delimiter;
      const newWidth =
        baseWidthPerEmployee * this.props.schedulerFilters.employeeIds.length;
      timeColumn.css("width", LEFT_TIMEBAR_WIDTH + "px");
      headerTable.css("width", newWidth + "px");
      contentTable.css("width", newWidth + "px");
    } else {
      layout.css("table-layout", "auto");
      timeColumn.css("width", "auto");
      headerTable.css("width", "100%");
      contentTable.css("width", "100%");
    }
  }

  private getDelimiter(view: string, width: number): number {
    for (let i = 0; i < this.delimiters.length; i++) {
      const delimiter = this.delimiters[i];
      const min = delimiter.pixels.min || 0;
      const max = delimiter.pixels.max || 99999;

      if (width > min && width < max) {
        return delimiter[view];
      }
    }

    return 1;
  }

  private initSchedulerTimeColumnHighlight(scheduler) {
    const selectorString = () => {
      const date = new Date();
      return ".scheduler-major-time-" + kendo.toString(date, "H");
    };

    const isNowBetweenRange = (range) => {
      if (!range || !range.start || !range.end) {
        return false;
      }
      const viewStart = moment(range.start);
      const viewEnd = moment(range.end);
      const now = moment(new Date());
      const isNowInView = now.isBetween(viewStart, viewEnd);
      return isNowInView;
    };

    const currentTimeSlot = $(selectorString());
    getSchedulerViewDateRange(scheduler, (err, range) => {
      const isNowInView = isNowBetweenRange(range);
      if (isNowInView) {
        currentTimeSlot.addClass("active");
      }
    });
  }

  private onDataBound(e: kendo.ui.SchedulerDataBoundEvent) {
    this.scrollToWorkingHours();
    this.adaptSchedulerWidth();

    const scheduler = e.sender;
    $(".k-event").each(function () {
      const uid = $(this).data("uid");
      const event: any = scheduler.occurrenceByUid(uid);
      if (event.readOnly) {
        $(this).find(".k-event-delete").hide();
      }
    });
  }

  private scrollToWorkingHours() {
    const contentDiv = $("div.k-scheduler-content");
    contentDiv.on(
      "scroll",
      this.saveInterfaceStateOnSchedulerScroll.bind(this)
    );
    const row = $(".scheduler-major-time-8"); // 8AM IS A START OF THE WORKING DAY
    if (!row.length) {
      return;
    }
    const top =
      row.offset().top - contentDiv.offset().top + contentDiv.scrollTop();
    contentDiv.scrollTop(top);
  }

  private saveInterfaceStateOnSchedulerScroll(e) {
    clearTimeout(this.onScrollTimeout);
  }

  private onChangeFilterHandler(values: any) {
    const employeeIds = (values.filterByEmployees || []).reduce(
      (state, employeeId) => {
        if (employeeId === this.props.loggedInEmployee.id) {
          state.unshift(employeeId);
        } else {
          state.push(employeeId);
        }
        return state;
      },
      []
    );

    let { isCanceled, isConfirmed } = this.props.schedulerFilters;

    switch (values.filterCompletedCanceled) {
      case FilterCompletedCanceled.Cancelled: {
        isCanceled = true;
        isConfirmed = undefined;
        break;
      }
      case FilterCompletedCanceled.Confirmed: {
        isCanceled = undefined;
        isConfirmed = true;
        break;
      }
      case FilterCompletedCanceled.WithoutStatus: {
        isCanceled = false;
        isConfirmed = false;
        break;
      }
      default: {
        isCanceled = undefined;
        isConfirmed = undefined;
        break;
      }
    }

    const filters: SchedulerFilters = {
      ...this.props.schedulerFilters,
      employeeIds,
      categoryIds: values.filterByAgendaItemCategories,
      majorTick: values.majorTickFilter,
      isCanceled,
      isConfirmed,
    };

    this.props.persistSchedulerFilters(filters);
    this.props.getAgendaItems();

    this.setState({ isPending: true });
  }

  private removeSelectedEmployee(id: string) {
    const filters: SchedulerFilters = { ...this.props.schedulerFilters };
    this.setState({ isPending: true });

    const idx = filters.employeeIds.indexOf(id);
    filters.employeeIds.splice(idx, 1);

    this.props.persistSchedulerFilters(filters);
    this.props.getAgendaItems();
    this.applyEmployeesSchedulerResource(filters.employeeIds);
  }

  private removeSelectedCategory(id: string) {
    const filters: SchedulerFilters = { ...this.props.schedulerFilters };
    this.setState({ isPending: true });

    const idx = filters.categoryIds.indexOf(id);
    filters.categoryIds.splice(idx, 1);

    this.props.persistSchedulerFilters(filters);
    this.props.getAgendaItems();
  }

  private onChangeShowFilter(showFilters: boolean) {
    const filters = { ...this.props.schedulerFilters };
    filters.showFilters = showFilters;
    this.props.persistSchedulerFilters(filters);
  }

  // This method is used to dynamically apply employees resources
  // which will group the items by columns according to selected employees in the filter
  private applyEmployeesSchedulerResource(employeeIds: string[]) {
    getSchedulerView(this.scheduler, (error, view) => {
      let selectedEmployees = this.props.employees.filter(
        (employee) => employeeIds.indexOf(employee.id) !== -1
      );

      if (
        (!selectedEmployees || selectedEmployees.length === 0) &&
        this.props.loggedInEmployee
      ) {
        selectedEmployees = this.props.employees.filter(
          (employee) => employee.id === this.props.loggedInEmployee.id
        );
      }

      let employeesFilterValues: RelationSnapShot[] = selectedEmployees.reduce(
        (state, e) => {
          if (e.id === this.props.loggedInEmployee.id) {
            return [e, ...state];
          }
          state.push(e);
          return state;
        },
        []
      );

      if (!employeesFilterValues || employeesFilterValues.length === 0) {
        employeesFilterValues = this.props.employees.filter(
          (employee) =>
            employeeIds.indexOf(this.props.loggedInEmployee.id) !== -1
        );
      }

      const employeesResource = this.scheduler.resources.filter((item) => {
        return item.name === this.EMPLOYEES_RESOURCE;
      });
      if (!employeesFilterValues.length) {
        this.scheduler.options.group.resources.length = 0;
      } else if (!this.scheduler.options.group.resources.length) {
        this.scheduler.options.group = {
          resources: [this.EMPLOYEES_RESOURCE],
        };
      }

      employeesResource[0].dataSource.data(
        employeesFilterValues.map((item) => {
          return { displayName: item.displayName, id: item.id };
        })
      );

      this.scheduler.view(view.name);
    });
  }

  private dateRangeChanged(e) {
    const filters: SchedulerFilters = { ...this.props.schedulerFilters };

    if (e.action === "changeWorkDay") {
      filters.showWorkingHours = !filters.showWorkingHours;
    }

    getSchedulerViewDateRange(this.scheduler, (error, range) => {
      const start = new Date(range.start);
      const end = new Date(range.end);
      end.setDate(end.getDate() + 1);

      filters.startDateTimeMin = start;
      filters.startDateTimeMax = end;

      if (filters.view !== e.view) {
        filters.view = e.view;
        this.props.persistSchedulerFilters(filters);
      } else {
        this.props.setSchedulerFilters(filters);
      }
      this.props.getAgendaItems();
    });
  }

  private onInitializeSchedulerWithData() {
    const filters: SchedulerFilters = { ...this.props.schedulerFilters };

    if (
      filters.startDateTimeMin &&
      filters.startDateTimeMax &&
      filters.view !== "month"
    ) {
      this.props.getAgendaItems();
      this.scheduler.date(new Date(filters.startDateTimeMin));
    } else {
      getSchedulerViewDateRange(this.scheduler, (error, range) => {
        if (range && range.start) {
          const start = new Date(range.start);
          filters.startDateTimeMin = start;
        }
        if (range && range.end) {
          const end = new Date(range.end);
          end.setDate(end.getDate() + 1);
          filters.startDateTimeMax = end;
        }
        this.props.setSchedulerFilters(filters);
        this.props.getAgendaItems();
      });
    }
  }

  private renderSubtitle(
    values: MappedCheckboxValue[]
  ): React.ReactElement<any>[] {
    const mapped = values.slice(0, 10);
    return mapped.map((val, idx) => (
      <span key={idx}>
        <ResourceText resourceKey={val.label} />,{" "}
      </span>
    ));
  }

  private renderEmployeesSubtitle() {
    const selectedEmployees = this.props.employees.filter((employee) => {
      const idx = this.props.schedulerFilters.employeeIds.indexOf(employee.id);
      return idx !== -1;
    });

    const selectedEmployeesSorted: RelationSnapShot[] =
      selectedEmployees.reduce((state, e) => {
        if (e.id === this.props.loggedInEmployee.id) {
          return [e, ...state];
        }
        state.push(e);
        return state;
      }, []);

    const isDisabledItem = selectedEmployeesSorted.length === 1;
    return (
      <div
        className="subtitle__selectedItems"
        styleName={"selectedItems" + (isDisabledItem ? " disabled" : "")}
      >
        {selectedEmployeesSorted.map((item, index) => {
          return (
            <span
              key={item + "_" + index}
              id="deleteSpan"
              onClick={
                isDisabledItem
                  ? (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      return;
                    }
                  : (event) => {
                      this.removeSelectedEmployee(item.id);
                      event.stopPropagation();
                    }
              }
              styleName={"addedResult" + (isDisabledItem ? " disabled" : "")}
            >
              <span id="deleteLink" styleName="remove">
                <i className="fal fa-check" id="deleteIcon" />
              </span>
              {item.id === this.props.loggedInEmployee.id &&
              !this.props.isBackOfficeEmployee ? (
                <ResourceText resourceKey="myAgenda" />
              ) : (
                <span>{item.displayName}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  }

  private renderCategoriesSubtitle() {
    const selectedCategories = this.props.agendaItemCategories.filter(
      (category) => {
        const idx = this.props.schedulerFilters.categoryIds.indexOf(
          category.id
        );
        return idx !== -1;
      }
    );

    return (
      <div className="subtitle__selectedItems" styleName="selectedItems">
        {selectedCategories.map((item, index) => {
          return (
            <span
              key={item + "_" + index}
              id="deleteSpan"
              onClick={(event) => {
                event.stopPropagation();
                this.removeSelectedCategory(item.id);
              }}
              styleName="addedResult"
            >
              <span id="deleteLink" styleName="remove">
                <i className="fal fa-check" id="deleteIcon" />
              </span>
              {item.displayName}
            </span>
          );
        })}
      </div>
    );
  }

  private onRemoveAgendaItem(e: kendo.ui.SchedulerRemoveEvent) {
    const agendaItem = this.props.agendaItems.find((agendaItem) => {
      return agendaItem.id === e.event.id;
    });

    const parentAgendaItem = this.props.agendaItems.find((agendaItem) => {
      return agendaItem.id === e.event.recurrenceId;
    });

    if (agendaItem && agendaItem.accessDeniedForCurrentUser) {
      e.preventDefault();
      this.setState({
        schedulerCantOpenModelIsOpen: true,
        triedToDelete: true,
      });
      return;
    }

    if (!!parentAgendaItem && e.event.recurrenceId) {
      this.props.addRecurrencyException(
        e.event.recurrenceId,
        e.event.start,
        null,
        null
      );
    } else {
      this.props.deleteAgendaItem(e.event.id);
    }
  }

  private agendaItemMoving(e) {
    // Here we monitor the moving of the agenda item.
    // The scheduler always applies the only one value there, so we can detect when
    // the agenda item is moved to another employee area
    if (
      !this.state.agendaItemMovingInitialEmployeeId &&
      e.event.linkedEmployeesIds[0]
    ) {
      this.agendaItemMovedAcrossItialEmployeeArea = false;
      this.setState({
        agendaItemMovingInitialEmployeeId: e.event.linkedEmployeesIds[0],
      });
      return;
    }
    if (
      this.state.agendaItemMovingInitialEmployeeId !==
      e.resources.linkedEmployeesIds[0]
    ) {
      this.agendaItemMovedAcrossItialEmployeeArea = true;
    } else {
      this.agendaItemMovedAcrossItialEmployeeArea = false;
    }
  }

  private agendaItemMoved(
    e: kendo.ui.SchedulerMoveEndEvent,
    userConfirmedMove = false
  ) {
    if (!e.event) return;
    const event = e.event as any;
    e.preventDefault();
    const agendaItem = this.props.agendaItems.find((agendaItem) => {
      return agendaItem.id === event.id;
    });

    if (agendaItem && agendaItem.accessDeniedForCurrentUser) {
      this.setState({
        schedulerCantOpenModelIsOpen: true,
        triedToDelete: false,
      });
      return;
    }

    // Show warning before moving agenda item if the status is confirmed
    if (agendaItem && agendaItem.isConfirmed && !userConfirmedMove) {
      this.setState({
        agendaItemMoveConfirmedModalVisible: true,
        kendoEvent: e,
      });
      return;
    }

    let employeeIds = event.linkedEmployeesIds;
    if (this.agendaItemMovedAcrossItialEmployeeArea) {
      const initialEmployeeId = this.state.agendaItemMovingInitialEmployeeId;
      if (
        event.linkedEmployeesIds.indexOf(e.resources.linkedEmployeesIds[0]) ===
        -1
      ) {
        // replace original employee where agenda item was moved from with the new employeeid.
        const newEployeesIds = event.linkedEmployeesIds.map((item) => {
          if (item === this.state.agendaItemMovingInitialEmployeeId) {
            return e.resources.linkedEmployeesIds[0];
          }
          return item;
        });
        employeeIds = newEployeesIds;

        this.agendaItemMovedAcrossItialEmployeeArea = false;
        this.setState({ agendaItemMovingInitialEmployeeId: null });
      }
    }

    if (event.isRecurringEvent) {
      this.props.addRecurrencyException(
        event.recurrenceId ? event.recurrenceId : event.id,
        event.start,
        e.start,
        e.end
      );
    } else {
      const currentAgendaItem = this.props.agendaItems.find(
        (agendaItem) => agendaItem.id === e.event.id
      );
      this.props.moveAgendaItem(
        e.event.id,
        e.start,
        e.end,
        employeeIds,
        currentAgendaItem
      );
    }
  }

  private editAgendaItem(e: kendo.ui.SchedulerEditEvent) {
    e.preventDefault();
    // const agendaItem = this.props.agendaItems.find(
    //   (agendaItem) => agendaItem.id === e.event.id
    // );

    // if (agendaItem && agendaItem.accessDeniedForCurrentUser) {
    //   this.setState({
    //     schedulerCantOpenModelIsOpen: true,
    //     triedToDelete: false
    //   });
    //   return;
    // }

    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: e.event.id,
    });
    this.props.navigateToDetail(path);
  }

  private resizeAgendaitem(e) {
    const currentAgendaItem = this.props.agendaItems.find(
      (agendaItem) => agendaItem.id === e.event.id
    );

    if (currentAgendaItem && currentAgendaItem.accessDeniedForCurrentUser) {
      e.preventDefault();
      this.setState({
        schedulerCantOpenModelIsOpen: true,
        triedToDelete: false,
      });
      return;
    }

    if (e.event.isRecurringEvent) {
      e.preventDefault();
      this.props.addRecurrencyException(
        e.event.recurrenceId ? e.event.recurrenceId : e.event.id,
        e.event.start,
        e.start,
        e.end
      );
    } else {
      const employeeIds = currentAgendaItem.linkedEmployees.map(
        (employee) => employee.id
      );
      this.props.moveAgendaItem(
        e.event.id,
        e.start,
        e.end,
        employeeIds,
        currentAgendaItem
      );
    }
  }

  private newAgendaItem(e: any) {
    e.preventDefault(); // prevent popup editing
    const event = e.event;

    let start = new Date();
    let end = new Date();

    if (event.start && event.end) {
      start = new Date(event.start.toISOString());
      end = new Date(event.end.toISOString());
    }

    if (start.toISOString() === end.toISOString()) {
      end.setMinutes(end.getMinutes() + 30);
    }

    if (event.isRecurringEvent) {
      this.isEditRecurrinItem = true;

      const agendaItemId = event.recurrenceId || event.id;
      this.props.addRecurrencyExceptionAndOpenDetail(
        agendaItemId,
        event.start,
        start,
        end,
        event.isAllDay
      );
    } else {
      this.props.navigateToNewScheduler(start, end, e.event.linkedEmployeesIds);
    }
  }

  private exportScheduler() {
    getSchedulerViewDateRange(this.scheduler, (error, range) => {
      let start = this.props.schedulerFilters.startDateTimeMin;
      let end = this.props.schedulerFilters.startDateTimeMax;

      if (range && range.start) {
        start = new Date(range.start);
      }
      if (range && range.end) {
        end = new Date(range.end);
      }

      const { employeeIds, categoryIds } = this.props.schedulerFilters;
      const culture = this.props.culture;

      this.setState({ loadingExport: true });
      this.props
        .exportAgenda(start, end, culture, employeeIds, categoryIds)
        .finally(() => {
          this.setState({ loadingExport: false });
        });
    });
  }
}
