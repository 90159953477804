import * as React from "react";
import { memo, FC, useCallback, useState, useMemo, useReducer } from "react";
import * as CSSModules from "react-css-modules";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import I18n from "@haywork/components/i18n";
import { RealtorInfoContainerProps } from "./container";
import { MailTo } from "@haywork/modules/shared";
import { NewEntity } from "@haywork/modules/new-entity";
import { NewEntityType } from "@haywork/enum";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  initialDuplicateModalState,
  duplicateModalreducer,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import { Address, RelationType } from "@haywork/api/kolibri";
import { GenderType } from "@haywork/api/event-center";
import {
  Agency,
  RealEstateAssociationType,
  BasicAddress,
} from "@haywork/api/mls";

const styles = require("./style.scss");

export type RealtorInfoProps = {
  visible: boolean;
  data: Agency;
  onCloseHandler: () => void;
};

type Props = RealtorInfoProps & RealtorInfoContainerProps;

export const RealtorInfoComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      visible,
      onCloseHandler,
      data,
      getRelationsWithMatchingEmailAddressOrPhoneNumber,
    }) => {
      const {
        name,
        phone,
        email,
        visitAddress,
        postalAddress,
        websiteUrl,
        realEstateAssociation,
      } = data;

      const webAddress = websiteUrl
        ? !websiteUrl.includes("http://") && !websiteUrl.includes("https://")
          ? "https://" + websiteUrl
          : websiteUrl
        : null;

      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] = useState(null);
      const [duplicateModalState, setDuplicateModalState] = useReducer(
        duplicateModalreducer,
        initialDuplicateModalState
      );

      const branchClass = useMemo(() => {
        let branch;
        switch (realEstateAssociation) {
          case RealEstateAssociationType.NL_VBO:
            branch = "vbo";
            break;
          case RealEstateAssociationType.NL_NVM:
            branch = "nvm";
            break;
          case RealEstateAssociationType.NL_VASTGOED_PRO:
            branch = "vgp";
            break;
          case RealEstateAssociationType.INDEPENDENT:
            branch = "idp";
            break;
          case RealEstateAssociationType.UNKNOWN:
            branch = "unk";
            break;
          default:
            branch = "unk";
        }

        return `branch ${branch}`;
      }, [realEstateAssociation]);

      const onCloseDuplicatesModalCallback = useCallback(() => {
        setDuplicateModalState({ type: "reset" });
      }, [setDuplicateModalState]);

      const createNewRelationCallback = useCallback(
        (
          name?: string,
          email?: string,
          telephone?: string,
          mobilePhone?: string,
          gender?: GenderType,
          meta?: { [key: string]: any },
          address?: Address,
          webAddress?: string
        ) => {
          webAddress =
            !webAddress.includes("http://") && !webAddress.includes("https://")
              ? "http://" + webAddress
              : webAddress;

          setNewEntityOptions({
            type: NewEntityType.Relation,
            newRelation: {
              singleFormType: RelationType.ContactCompany,
              name,
              telephone,
              webAddress,
              email,
            },
          });
          setNewEntityVisible(true);
          onCloseDuplicatesModalCallback();
        },
        [
          setNewEntityVisible,
          setNewEntityOptions,
          onCloseDuplicatesModalCallback,
        ]
      );

      const onCreateNewRelationCallback = useCallback(
        async (relation) => {
          if (newEntityVisible) return;

          const { name, telephone, email, webAddress } = relation;
          if (!!email || !!telephone) {
            const relations =
              await getRelationsWithMatchingEmailAddressOrPhoneNumber(
                email ? [email] : undefined,
                telephone
              );
            if (!!relations.length) {
              setDuplicateModalState({
                type: "set",
                payload: {
                  visible: true,
                  view: "duplicates",
                  relations,
                  relationData: {
                    name,
                    email,
                    telephone,
                    webAddress,
                  },
                },
              });
              return;
            }
          }

          createNewRelationCallback(
            name,
            email,
            telephone,
            undefined,
            undefined,
            undefined,
            undefined,
            webAddress
          );
        },
        [newEntityVisible, createNewRelationCallback, setDuplicateModalState]
      );

      const address = useCallback((address: BasicAddress) => {
        const houseNumber = [address.houseNumber, address.houseNumberPostfix]
          .filter((d) => !!d)
          .join("-");
        const addressLine1 = [address.streetName, houseNumber]
          .filter((d) => !!d)
          .join(" ");
        const countryCode = address.countryCode
          ? `(${address.countryCode})`
          : null;
        const addressLine2 = [address.postalCode, address.cityName, countryCode]
          .filter((d) => !!d)
          .join(" ");
        const fullAddress = [addressLine1, addressLine2]
          .filter((d) => !!d)
          .join(", ");

        return !fullAddress ? null : <span>{fullAddress}</span>;
      }, []);

      return (
        <>
          <Modal visible={visible} onClose={onCloseHandler}>
            <ModalHeader title={name} close />
            <ModalBody>
              <ol styleName="mlsModalList">
                <li>
                  <div styleName="realtorName">
                    <span styleName={branchClass}></span>
                    <h4>{name}</h4>
                  </div>
                </li>
                {phone && (
                  <li>
                    <div styleName="label">
                      <I18n value="mls.realtor.phone" />
                    </div>
                    <div>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                  </li>
                )}
                {email && (
                  <li>
                    <div styleName="label">
                      <I18n value="mls.realtor.email" />
                    </div>
                    <div>
                      <MailTo email={email}>{email}</MailTo>
                    </div>
                  </li>
                )}
                {visitAddress && visitAddress.streetName && (
                  <li>
                    <div styleName="label">
                      <I18n value="mls.realtor.visitAddress" />
                    </div>
                    <div styleName="address">{address(visitAddress)}</div>
                  </li>
                )}
                {postalAddress && postalAddress.streetName && (
                  <li>
                    <div styleName="label">
                      <I18n value="mls.realtor.mailAddress" />
                    </div>
                    <div styleName="address">{address(postalAddress)}</div>
                  </li>
                )}
                {webAddress && (
                  <li>
                    <div styleName="label">
                      <I18n value="mls.realtor.website" />
                    </div>
                    <div>
                      <a target="_blank" href={webAddress}>
                        {webAddress}
                      </a>
                    </div>
                  </li>
                )}
              </ol>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-success"
                onClick={() =>
                  onCreateNewRelationCallback({
                    name,
                    telephone: phone,
                    webAddress,
                    email,
                  })
                }
              >
                <I18n value="mls.realtorAction.add" />
              </button>
            </ModalFooter>
          </Modal>
          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={() => setNewEntityVisible(false)}
            onNewRelation={() => {
              setNewEntityVisible(false);
              onCloseHandler();
            }}
          />
          <DuplicatesModal
            visible={duplicateModalState.visible}
            view={duplicateModalState.view}
            relations={duplicateModalState.relations}
            relationData={duplicateModalState.relationData}
            onClose={onCloseDuplicatesModalCallback}
            onCreateNewRelation={createNewRelationCallback}
            mode={"create"}
          />
        </>
      );
    }
  )
);
