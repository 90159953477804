import { GenderType } from "@haywork/api/event-center";
import { Address, RelationType } from "@haywork/api/kolibri";
import { StatusType } from "@haywork/api/mls";
import DuplicatesModal from "@haywork/components/duplicate-contacts-modal";
import {
  duplicateModalreducer,
  initialDuplicateModalState,
} from "@haywork/components/duplicate-contacts-modal/duplicate-contacts-modal";
import I18n from "@haywork/components/i18n";
import CollapsableRow from "@haywork/components/ui/collapsable-rows";
import CollapsableText from "@haywork/components/ui/collapsable-text";
import PageHeader from "@haywork/components/ui/page-header";
import Pill from "@haywork/components/ui/pill";
import { NewEntityType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { MlsUtil } from "@haywork/util";
import head from "lodash-es/head";
import * as moment from "moment";
import * as nl2br from "nl2br";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useReducer, useState } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";
import { useRealEstatePropertyValueGroups } from "../../helpers";
import Actions, { MlsAction } from "./actions";
import PropertyRow from "./components/property-row";
import Realtor from "./components/realtor";
import Status from "./components/status";
import { DetailGeneralContainerProps } from "./detail-general.container";

const styles = require("./style.scss");

export type DetailGeneralComponentProps = {};
type Props = DetailGeneralComponentProps & DetailGeneralContainerProps;

export const DetailGeneralComponent: FC<Props> = memo(
  CSSModules(styles)(
    ({
      toggleFollow,
      copyToObjectAssignment,
      getRelationsWithMatchingEmailAddress,
      realEstateProperty,
      displayName,
      adText,
      following,
      bundleId,
      appClientKey,
      printRealEstateProperty,
      realtorName,
      groupNames,
      groupReleaseDateTime,
      agencyGroups,
    }) => {
      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] =
        useState<NewEntityOptions | null>(null);
      const [duplicateModalState, setDuplicateModalState] = useReducer(
        duplicateModalreducer,
        initialDuplicateModalState
      );
      const intl = useIntl();
      const realEstatePropertyValueGroups = useRealEstatePropertyValueGroups(
        realEstateProperty,
        intl
      );

      const canFollow = useMemo(() => {
        const validStatusTypes = [
          StatusType.AVAILABLE,
          StatusType.RENTED_UNDER_CONDITIONS,
          StatusType.SOLD_UNDER_CONDITIONS,
        ];

        return validStatusTypes.includes(
          realEstateProperty?.propertyInfo?.status
        );
      }, [realEstateProperty?.propertyInfo?.status]);

      const typeTitle = useMemo(() => {
        if (!realEstateProperty?.type || !realEstateProperty?.offer)
          return null;
        const segments = ["mlsTypeTitle"];

        switch (true) {
          case realEstateProperty?.type?.isResidential: {
            segments.push("residential");
            break;
          }
          case realEstateProperty?.type?.isAgricultural: {
            segments.push("agricultural");
            break;
          }
          case realEstateProperty?.type?.isCommercial: {
            segments.push("commercial");
            break;
          }
          default: {
            break;
          }
        }

        switch (true) {
          case !!realEstateProperty?.offer?.isAcquisition: {
            segments.push("acquisition");
          }
          case !!realEstateProperty?.offer?.isForSale: {
            segments.push("forSale");
            break;
          }
          case !!realEstateProperty?.offer?.isForRent: {
            segments.push("forRent");
            break;
          }
          default: {
            break;
          }
        }

        return segments.join(".");
      }, [realEstateProperty?.type, realEstateProperty?.offer]);

      const propertyType = useMemo(() => {
        const propertyTypes = realEstateProperty?.type?.propertyTypes || [];
        const secondaryPropertyTypes =
          realEstateProperty?.type?.secondaryPropertyTypes || [];

        const segments: string[] = [];
        propertyTypes.forEach((propertyType) => {
          segments.push(
            intl.formatMessage({
              id: `propertyTypeOptions.${propertyType.toString()}`,
              defaultMessage: propertyType.toString(),
            })
          );
        });
        secondaryPropertyTypes.forEach((propertyType) => {
          segments.push(
            intl.formatMessage({
              id: `propertyTypeOptions.${propertyType.toString()}`,
              defaultMessage: propertyType.toString(),
            })
          );
        });

        return segments
          .filter((d) => !!d)
          .map((segment, idx) => (idx === 0 ? segment : segment.toLowerCase()))
          .join(", ");
      }, [
        realEstateProperty?.type?.propertyTypes,
        realEstateProperty?.type?.secondaryPropertyTypes,
        intl,
      ]);

      const country = useMemo(() => {
        const locationDetails = realEstateProperty?.locationDetails || [];
        const translation = locationDetails.find(
          (detail) => detail.isO2LanguageCode === "nl"
        );
        return translation?.countryName;
      }, [realEstateProperty?.locationDetails]);

      const onCloseDuplicatesModalCallback = useCallback(() => {
        setDuplicateModalState({ type: "reset" });
      }, [setDuplicateModalState]);

      const createNewRelationCallback = useCallback(
        (
          name?: string,
          email?: string,
          telephone?: string,
          mobilePhone?: string,
          gender?: GenderType,
          meta?: { [key: string]: any },
          address?: Address,
          webAddress?: string
        ) => {
          setNewEntityOptions({
            type: NewEntityType.Relation,
            newRelation: {
              singleFormType: RelationType.ContactCompany,
              name,
              telephone,
              mobilePhone,
              webAddress,
              email,
            },
          });
          setNewEntityVisible(true);
          onCloseDuplicatesModalCallback();
        },
        [
          setNewEntityVisible,
          setNewEntityOptions,
          onCloseDuplicatesModalCallback,
        ]
      );

      const onCreateNewRelationCallback = useCallback(
        async (relation) => {
          if (newEntityVisible) return;
          const { name, telephone, email, webAddress } = relation;
          if (!!email) {
            const relations = await getRelationsWithMatchingEmailAddress(email);
            if (!!relations.length) {
              setDuplicateModalState({
                type: "set",
                payload: {
                  visible: true,
                  view: "duplicates",
                  relations,
                  relationData: {
                    name,
                    email,
                    telephone,
                    webAddress,
                  },
                },
              });
              return;
            }
          }

          createNewRelationCallback(
            name,
            email,
            telephone,
            undefined,
            undefined,
            undefined,
            webAddress
          );
        },
        [newEntityVisible, createNewRelationCallback, setDuplicateModalState]
      );

      const actionClickCallback = useCallback(
        (action: MlsAction) => {
          switch (action) {
            case MlsAction.Follow: {
              toggleFollow({ bundleId, appClientKey, following });
              return;
            }
            case MlsAction.UnFollow: {
              toggleFollow({ bundleId, appClientKey, following });
              return;
            }
            case MlsAction.Copy: {
              copyToObjectAssignment(
                MlsUtil.mapToObjectAssignment(realEstateProperty)
              );
              return;
            }
            case MlsAction.Print: {
              printRealEstateProperty(realEstateProperty, realtorName, intl);
              return;
            }
            default: {
              return;
            }
          }
        },
        [
          realEstateProperty,
          bundleId,
          appClientKey,
          following,
          printRealEstateProperty,
          realtorName,
          intl,
        ]
      );

      const mlsGroupLabel = useMemo(() => {
        if (!realEstateProperty || !groupNames || !groupReleaseDateTime) {
          return;
        }

        const groups = (groupNames || []).map(
          (name) =>
            agencyGroups.find(
              (group) =>
                group.name && group.name.toLowerCase() === name.toLowerCase()
            )?.name || name
        );

        return moment(groupReleaseDateTime).isValid() &&
          moment(groupReleaseDateTime).utc().isAfter(moment().utc()) &&
          groups.length > 0 ? (
          <Pill
            label="mls.label.exclusive"
            labelValues={{ name: head(groups) }}
            color="#c91e24"
            textColor={Colors.White}
            solid
            disableHint
            fullWidth
          />
        ) : null;
      }, [realEstateProperty, groupNames, groupReleaseDateTime, agencyGroups]);

      return (
        <>
          <div styleName="general">
            <PageHeader
              title="mls.pageHeader.general"
              actions={
                <Actions
                  isInitial={false}
                  onClick={actionClickCallback}
                  following={following}
                  canFollow={canFollow}
                />
              }
            />
            <div styleName="body">
              <div styleName="body__content">
                <div styleName="meta">
                  {!!realEstateProperty?.propertyInfo
                    ?.publicReferenceNumber && (
                    <Pill
                      label={
                        realEstateProperty?.propertyInfo?.publicReferenceNumber
                      }
                      color={Colors.Warning}
                      disableHint
                      fullWidth
                    />
                  )}
                  {!!following && (
                    <Pill
                      label="mls.statusPill.following"
                      icon="eye"
                      color={Colors.Primary}
                      disableHint
                      fullWidth
                    />
                  )}
                  {mlsGroupLabel}
                  {!!realEstateProperty?.propertyInfo?.confidential && (
                    <Pill
                      color={Colors.Primary}
                      label="mls.label.confidential"
                      solid
                      disableHint
                      fullWidth
                    />
                  )}
                </div>

                <div styleName="titles">
                  {!!typeTitle && (
                    <h2>
                      <I18n value={typeTitle} />
                    </h2>
                  )}
                  <h1>
                    {displayName}
                    {!!country && <span styleName="country">({country})</span>}
                  </h1>
                  {!!propertyType && <h3>{propertyType}</h3>}
                </div>

                <Status
                  status={realEstateProperty?.propertyInfo?.status}
                  substatus={realEstateProperty?.propertyInfo?.substatus}
                />

                {realEstatePropertyValueGroups.map((group, groupKey) => (
                  <div styleName="property-rows" key={groupKey}>
                    <CollapsableRow label={group.name}>
                      {group.values.map((value, valueKey) => (
                        <PropertyRow
                          value={value}
                          key={`${groupKey}.${valueKey}`}
                        />
                      ))}
                    </CollapsableRow>
                  </div>
                ))}

                {!!adText && (
                  <div styleName="property-rows">
                    <CollapsableText label="mls.propertyGroup.adText">
                      <span
                        dangerouslySetInnerHTML={{ __html: nl2br(adText) }}
                      />
                    </CollapsableText>
                  </div>
                )}
              </div>
              <div styleName="body__sidebar">
                {!!realEstateProperty?.contact?.department?.name && (
                  <div styleName="sidebar__block">
                    <Realtor
                      agency={realEstateProperty?.contact?.department}
                      onCreateNewRelationCallback={onCreateNewRelationCallback}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={() => setNewEntityVisible(false)}
            onNewRelation={() => {
              setNewEntityVisible(false);
            }}
          />
          <DuplicatesModal
            visible={duplicateModalState.visible}
            view={duplicateModalState.view}
            relations={duplicateModalState.relations}
            relationData={duplicateModalState.relationData}
            onClose={onCloseDuplicatesModalCallback}
            onCreateNewRelation={createNewRelationCallback}
            mode={"create"}
          />
        </>
      );
    }
  )
);
