import { createSelector } from "reselect";
import sortBy from "lodash-es/sortBy";

import { AppState } from "@haywork/stores";
import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryTypeOption,
} from "@haywork/api/kolibri";

export interface MappedAgendaItemCategory {
  type: AgendaItemCategoryTypeOption;
  categories: AgendaItemCategorySnapShot[];
}

const standardDurationsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.agendaStandardDurationOptions;

const agendaItemCategoriesSelector = (state: AppState) =>
  state.schedulerCategory.agendaItemCategories;

const agendaItemCategoryTypesSelector = (state: AppState) =>
  state.main.mastertable.kolibri.agendaItemCategoryTypes;

export const standardDurations = createSelector(
  standardDurationsSelector,
  (durations) => {
    return sortBy(durations, (duration) => duration.durationInMinutes);
  }
);

export const mappedAgendaItemCategories = createSelector(
  [agendaItemCategoriesSelector, agendaItemCategoryTypesSelector],
  (categories, types) => {
    const mappedcategories: MappedAgendaItemCategory[] = types.map((type) => ({
      type,
      categories: [],
    }));

    categories.map((category) => {
      const cat = mappedcategories.find(
        (cat) => cat.type.value === category.categoryType
      );
      cat.categories.push(category);
    });

    return mappedcategories.filter(
      (category) => category.categories.length > 0
    );
  }
);
