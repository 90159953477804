import { Action } from "redux";

import { REDUX } from "@haywork/constants";
import { ErrorResponse as KolibriErrorResponse } from "@haywork/api/kolibri";
import { ErrorResponse as MailErrorResponse } from "@haywork/api/mail";
import { isSwaggerException } from "@haywork/stores/helpers";

import * as ActionType from "./error.types";

export interface ErrorState {
  response?: KolibriErrorResponse | MailErrorResponse | any;
  persistent?: boolean;
  unauthorized?: boolean;
  errors?: string[];
}

const INITIAL_STATE: ErrorState = {
  response: null,
  persistent: undefined,
  unauthorized: false,
  errors: [],
};

export const errorReducer = (
  state: ErrorState = INITIAL_STATE,
  action: Action
): ErrorState => {
  switch (action.type) {
    case REDUX.ERRORS.SET_ERROR: {
      const { error, persistent } = <ActionType.Error>action;
      const response = isSwaggerException(error)
        ? JSON.parse(error.response)
        : error;
      const errors = [
        JSON.stringify({ timestamp: new Date().toString(), error: response }),
        ...state.errors,
      ].slice(0, 50);

      return {
        ...state,
        response,
        persistent,
        errors,
      };
    }
    case REDUX.ERRORS.ADD_ERROR: {
      const { error } = <ActionType.ReactError>action;
      const errors = [error, ...state.errors].slice(0, 50);

      return {
        ...state,
        errors,
      };
    }
    case REDUX.ERRORS.SET_UNAUTHORIZED: {
      const { unauthorized } = <ActionType.Unauthorized>action;

      return {
        ...state,
        unauthorized,
      };
    }
    case REDUX.ERRORS.CLOSE_ERROR:
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
