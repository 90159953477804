import * as React from "react";
import {
  FC,
  memo,
  useState,
  useRef,
  ComponentType,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
} from "react";
import * as CSSModules from "react-css-modules";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfinitLoader from "react-window-infinite-loader";
import InnerElementType from "./inner-element";
import { Input } from "@haywork/modules/form";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");
const DEFAULT_TAKE = 25;

export type MultiListRowProps<T = {}> = ListChildComponentProps & {
  data: T;
  zebra: boolean;
};
export type MultiListApiResponse = {
  total: number;
  items: unknown[];
};
type MultiListRowData = {
  api: string;
  data: unknown;
};
export type MultiListApi = {
  name: string;
  endpoint: (
    startIndex: number,
    stopIndex: number
  ) => Promise<MultiListApiResponse>;
  row: ComponentType<MultiListRowProps>;
};
type Props = {
  apis: MultiListApi[];
  emptyRow: ComponentType<MultiListRowProps>;
  emptyState: ReactElement;
  itemSize: number;
  takePerApi?: number;
  initiallySelectedApi?: string;
};

export const MultiListComponent: FC<Props> = memo(
  CSSModules(styles)(
    ({
      apis,
      emptyRow,
      takePerApi,
      itemSize,
      initiallySelectedApi,
      emptyState,
    }) => {
      const [selectedApi, setSelectedApi] = useState(
        initiallySelectedApi || ""
      );
      const [loading, setLoading] = useState(false);
      const [itemCount, setItemCount] = useState(0);
      const [items, setItems] = useState<MultiListRowData[]>([]);
      const cachedItems = useRef<MultiListRowData[]>([]);
      const inited = useRef(false);

      const take = useMemo(() => takePerApi || DEFAULT_TAKE, [takePerApi]);

      const apiValues = useMemo(() => {
        const values = [];
        apis.forEach((api) => {
          values.push({
            value: api.name,
            displayName: `multiList.api.${api.name}`,
          });
        });

        return values;
      }, [apis]);

      const getInitialResults = useCallback(
        async (selectedApiName?: string) => {
          try {
            setLoading(true);
            const promises: Promise<MultiListApiResponse>[] = [];

            apis.forEach((api) => {
              if (!selectedApiName || api.name === selectedApiName) {
                promises.push(api.endpoint(0, take));
              }
            });

            const results = await Promise.all(promises);

            const total = results.reduce((state, item) => {
              return (state = state + (item.total || 0));
            }, 0);

            let items: MultiListRowData[] = [];

            results.forEach((api, idx) => {
              const startIndex = idx === 0 ? 0 : results[idx - 1].total;

              items = [
                ...items,
                ...new Array(api.total).fill(true).map((_) => ({
                  api: selectedApiName || apis[idx].name,
                  data: null,
                })),
              ];

              api.items.forEach((item, idx) => {
                items[startIndex + idx] = {
                  ...items[startIndex + idx],
                  data: item,
                };
              });
            });

            setItems(items);
            cachedItems.current = items;
            setItemCount(total);
          } finally {
            setLoading(false);
          }
        },
        [apis, take, setItemCount, setLoading]
      );

      const loadMoreItems = useCallback(
        async (startIndex: number) => {
          const { api: apiName } = (cachedItems.current || [])[startIndex];
          const api = apis.find((api) => api.name === apiName);
          const countBefore = (cachedItems.current || [])
            .slice(0, startIndex)
            .filter((item) => item.api !== apiName).length;

          const start = startIndex - countBefore;
          const response = await api.endpoint(start, start + take);

          const updatedItems = [...(cachedItems.current || [])];
          (response?.items || []).forEach((item, idx) => {
            const key = startIndex + idx;
            updatedItems[key] = {
              ...updatedItems[key],
              data: item,
            };
          });

          setItems(updatedItems);
          cachedItems.current = updatedItems;
        },
        [apis, take, setItems]
      );

      const isItemLoaded = useCallback(
        (index: number) => {
          return !!items[index]?.data;
        },
        [items]
      );

      const row = (props: ListChildComponentProps) => {
        const { index } = props;
        const { api: name, data } = items[index];
        const api = apis.find((api) => api.name === name);
        const Row = api?.row;
        const EmptyRow = emptyRow;

        return !!data ? (
          <Row {...props} data={data} zebra={index % 2 === 0} key={index} />
        ) : (
          <EmptyRow {...props} zebra={index % 2 === 0} key={index} />
        );
      };

      const innerElementType = useCallback(
        (props) => (
          <InnerElementType
            {...props}
            loading={loading}
            itemCount={itemCount}
            emptyState={emptyState}
          />
        ),
        [loading, emptyState, itemCount]
      );

      const updateSelectedApi = useCallback(
        (selectedApi: string) => {
          setSelectedApi(selectedApi);
          getInitialResults(selectedApi);
        },
        [setSelectedApi, setItems, getInitialResults]
      );

      useEffect(() => {
        if (!!inited.current) return;
        inited.current = true;
        getInitialResults(initiallySelectedApi);
      }, [getInitialResults, initiallySelectedApi]);

      return (
        <div styleName="wrapper">
          <div styleName="header">
            <div styleName="header__label">
              <label htmlFor="api">
                <I18n value="multiList.select.label" />
              </label>
            </div>
            <div styleName="header__input">
              <Input.NewSelect
                name="api"
                values={apiValues}
                value={selectedApi}
                valuesProp="value"
                displayProp="displayName"
                onChange={updateSelectedApi}
                asSingleInput
                addEmptyOption
                emptyOption=""
                emptyOptionLabel="multiList.api.all"
                translate
                translateValue={(v) => v.displayName}
              />
            </div>
          </div>
          <div styleName="body">
            <AutoSizer>
              {({ width, height }) => (
                <InfinitLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  minimumBatchSize={take}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      itemSize={itemSize}
                      height={height}
                      width={width}
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      innerElementType={innerElementType}
                    >
                      {row}
                    </FixedSizeList>
                  )}
                </InfinitLoader>
              )}
            </AutoSizer>
          </div>
        </div>
      );
    }
  )
);
