import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink, Link } from "react-router-dom";

import {
  ContactLinkSnapShot,
  ContactLinkTypeSuggestion,
  RelationType,
} from "@haywork/api/kolibri";
import { TimedButtonComponent, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { RELATIONROUTES } from "@haywork/constants";
import { CompanyLinkItemComponent } from "./company-link-item.component";
import { PersonLinkItemComponent } from "./person-link-item.component";

interface RelationAroRelationsItemComponentProps {
  contact: ContactLinkSnapShot;
  linkTypes: ContactLinkTypeSuggestion[];
  relationId: string;
  zebra: boolean;

  contactLinkEditClick: (contact: ContactLinkSnapShot) => void;
  contactLinkDelete: (contact: ContactLinkSnapShot) => void;
  contactLinkUnDelete: (contact: ContactLinkSnapShot) => void;
  contactLinkRemoveFromList: (contact: ContactLinkSnapShot) => void;
  contactLinkTypeChange: (
    sourceRelationId: string,
    targetRelationId: string,
    linkType: string
  ) => void;
  navigate: (route: string) => void;
}
interface RelationAroRelationsItemComponentState {
  showActions: boolean;
  preppedForRemove: boolean;
  deleteCountdown: number;
  clickedInside: boolean;
}

const styles = require("./aro-relations-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class RelationAroRelationsItemComponent extends React.Component<
  RelationAroRelationsItemComponentProps,
  RelationAroRelationsItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.state = {
      showActions: false,
      preppedForRemove: false,
      deleteCountdown: 5,
      clickedInside: false,
    };

    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.renderAroLink = this.renderAroLink.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onUnDeleteClickHandler = this.onUnDeleteClickHandler.bind(this);
    this.getRelationDetailRoute = this.getRelationDetailRoute.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const { targetRelation } = this.props.contact;
    const innerStyle = classNames("inner", {
      zebra: this.props.zebra,
      "actions-visible": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
      important: this.state.clickedInside,
    });

    return (
      <div styleName="item" ref={(ref) => (this.ref = ref)}>
        <div
          styleName={innerStyle}
          onClick={() => {
            this.props.navigate(this.getRelationDetailRoute());
          }}
        >
          {this.renderAroLink(targetRelation.typeOfRelation)}
          <div styleName="item__toggle" onClick={this.onToggleClickHandler}>
            <span id="toggleContact" className="fal fa-fw fa-ellipsis-v" />
          </div>
          <div
            id="undoDeleteContact"
            styleName="undo-delete"
            onClick={this.onUnDeleteClickHandler}
          >
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="item__actions">
          <div className="btn btn-danger" onClick={this.onDeleteClickHandler}>
            <span id="deleteContact" className="fal fa-times" />
          </div>
          <Link
            className="btn btn-primary"
            to={this.getRelationDetailRoute()}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span id="detailContact" className="fal fa-id-badge" />
          </Link>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    const clickedInside = this.ref.contains(event.target);
    if (!clickedInside && !!this.state.showActions)
      this.setState({ showActions: false, clickedInside });
    this.setState({ clickedInside });
  }

  private renderAroLink(type: RelationType) {
    switch (type) {
      case RelationType.ContactCompany:
        return (
          <CompanyLinkItemComponent
            contact={this.props.contact}
            onLinkTypeChange={this.props.contactLinkTypeChange}
            relationId={this.props.relationId}
            preppedForRemove={this.state.preppedForRemove}
          />
        );
      case RelationType.ContactPerson:
        return (
          <PersonLinkItemComponent
            contact={this.props.contact}
            linkTypes={this.props.linkTypes}
            onLinkTypeChange={this.props.contactLinkTypeChange}
            relationId={this.props.relationId}
            preppedForRemove={this.state.preppedForRemove}
          />
        );
      default:
        return null;
    }
  }

  private onToggleClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ showActions: !this.state.showActions });
  }

  private onUnDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    this.props.contactLinkUnDelete(this.props.contact);
  }

  private onDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.contactLinkDelete(this.props.contact);
    this.setState({ preppedForRemove: true, showActions: false });

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.contactLinkRemoveFromList(this.props.contact);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private getRelationDetailRoute(): string {
    const { sourceRelation, targetRelation } = this.props.contact;
    const person =
      targetRelation.id === this.props.relationId
        ? sourceRelation
        : targetRelation;
    let uri;

    switch (person.typeOfRelation) {
      case RelationType.ContactCompany:
        uri = RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI;
        break;
      case RelationType.ContactPerson:
        uri = RELATIONROUTES.CONTACT_PERSON_DETAIL.URI;
        break;
      default:
        uri = "";
        break;
    }

    return route(uri, { id: person.id });
  }
}
