import { ObjectAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingFloorplansComponent,
  AssignmentEditMarketingFloorplansComponentProps
} from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  SingleAssignmentState
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  path: string;
}
interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingFloorplansComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: currentComponentState.objectAssignment.id
    })
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingFloorplansComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type AssignmentEditMarketingFloorplansContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingFloorplansContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingFloorplansComponent);
