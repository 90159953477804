export default `<div class="eventTemplate" data-cy="CY-agendaItemEvent" # if(recurrenceId) { # data-agenda-item-parent-id="#:recurrenceId#" # } #>
                    <div class="eventText">
                        <span class="eventActions">
                            # if(isRecurringEvent || recurrenceId) { #
                                <i class="eventAction recurring fa fa-undo"></i>
                            # } #
                            # if(isPrivate) { #
                                <i class="eventAction private fa fa-lock"></i>
                            # } #
                            # if(priority && priority === 'High') { #
                                <i class="eventAction highprio fa fa-exclamation-circle"></i>
                            # } #
                            # if(isConfirmed && !isCanceled) { #
                                <i class="eventAction confirmed fa fa-check-circle"></i>
                            # } #
                            # if(isCanceled) { #
                                <i class="eventAction cancelled fa fa-minus-circle"></i>
                            # } #
                            # if(linkedAgendaItemCategory.needsToBeConfirmed && !isConfirmed && !isCanceled) { #
                                <i class="eventAction needsToBeConfirmed fa fa-exclamation-circle"></i>
                            # } #
                        </span>
                        <span class="eventSubject">#: title #</span>
                        #if(linkedAssignments && linkedAssignments[0]){#
                          #if(linkedAssignments[0].keyNr){#
                              <span class="eventAssignmentKeynumber">
                                  <i class="fa fa-key"></i>
                                  #:linkedAssignments[0].keyNr#
                              </span>
                          #}#
                        #}#
                </div>
            </div>`;
