import { AcquisitionObjectAssignment, PhotoBlob } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, LayoutActions } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionAssignmentDetailSidebarComponent,
  AcquisitionAssignmentDetailSidebarComponentProps,
} from "./detail-sidebar";

type StateProps = {
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionAssignmentDetailSidebarComponentProps,
  AppState
> = (state) => {
  const { acquisitionAssignmentObject } = state.acquisition.assignment;

  return {
    acquisitionAssignmentObject,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionAssignmentDetailSidebarComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
});

export type AcquisitionAssignmentDetailSidebarContainerProps = StateProps &
  DispatchProps;
export const AcquisitionAssignmentDetailSidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionAssignmentDetailSidebarComponent);
