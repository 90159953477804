import {
  InitializedWidget,
  WidgetEntityLocation,
  WidgetEntityType,
  WidgetSnapShot,
} from "@haywork/api/authorization";
import PageHeader from "@haywork/components/ui/page-header";
import { COMMON } from "@haywork/constants";
import { ToggleConsent } from "@haywork/modules/app-xchange/components/toggle-consent";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AuthorizationWidgetsPageContainerProps } from "./page.container";
import AutoSizer from "react-virtualized-auto-sizer";

const styles = require("./style.scss");

export interface AuthorizationWidgetsPageComponentProps {
  entityId: string;
  widgets: WidgetSnapShot[];
  type: WidgetEntityType;
}
interface State {
  initializedWidgets: InitializedWidget[];
}
type Props = AuthorizationWidgetsPageComponentProps &
  AuthorizationWidgetsPageContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsPageComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedWidgets: [],
    };
  }

  public async componentDidMount() {
    const initializedWidgets = await this.props.getInitializedWidgets(
      this.props.entityId,
      this.props.type
    );

    this.setState({ initializedWidgets });
  }

  public render() {
    let widget = (this.props.widgets || []).find(
      (widget) => widget.id === this.props.match.params.widgetId
    );
    widget = widget || {
      id: COMMON.EMPTY_GUID,
      height: 0,
      location: WidgetEntityLocation.Menu,
      isActive: true,
    };
    const { id, displayName } = widget;
    const initializedWidget = (this.state.initializedWidgets || []).find(
      (widget) => widget.id === id
    );

    return (
      <div styleName="widget">
        <PageHeader
          title={displayName}
          actions={
            this.props.type === WidgetEntityType.AppXchange ? (
              <ToggleConsent />
            ) : undefined
          }
        />

        <div styleName="widget__body">
          {!!initializedWidget ? (
            <AutoSizer>
              {({ width, height }) => (
                <iframe
                  frameBorder={0}
                  src={initializedWidget.signedCallbackUri}
                  width={width}
                  height={height}
                  style={{ width, height }}
                  // sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin"
                />
              )}
            </AutoSizer>
          ) : (
            <Ui.Loaders.Fullscreen />
          )}
        </div>
      </div>
    );
  }
}
