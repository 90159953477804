import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentDetailDossierComponent,
  AssignmentDetailDossierComponentProps
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  assignmentId?: string;
  displayName?: string;
  linkedFolderTreeId: string | null | undefined;
  isInitial: boolean;
}
interface DispatchProps {
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailDossierComponentProps,
  AppState
> = (state) => ({
  assignmentId: state.assignment.single.objectAssignment.id,
  isInitial: state.assignment.single.isInitial,
  displayName: state.assignment.single.objectAssignment.displayName,
  linkedFolderTreeId: get(
    state.assignment.single.objectAssignment.linkedFolderTree,
    "id"
  )
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailDossierComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type AssignmentDetailDossierContainerProps = StateProps & DispatchProps;
export const AssignmentDetailDossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailDossierComponent);
