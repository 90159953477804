import * as React from "react";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { VoipInfoModalContainerProps } from "./voip-info-modal.container";
import { Ui } from "@haywork/modules/ui";
import {
  CommunicationLog,
  LinkedRelation,
  LinkedEmployee,
  RelationType
} from "@haywork/api/kolibri";
import { ResourceText } from "../resource-text/resource-text.component";
import * as nl2br from "nl2br";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import Visual from "./components/visual";
import { RelationUtil, RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES
} from "@haywork/constants";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface VoipInfoModalComponentProps {
  visible: boolean;
  communicationLogId: string;
  onClose: () => void;
}
interface State {
  loading: boolean;
  log: CommunicationLog | null;
}
type Props = VoipInfoModalComponentProps & VoipInfoModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class VoipInfoModalComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      log: null
    };

    this.getCommunicationLog = this.getCommunicationLog.bind(this);
    this.onNavigateToRelation = this.onNavigateToRelation.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.props.visible &&
      !!this.props.communicationLogId &&
      this.props.communicationLogId !== prevProps.communicationLogId
    ) {
      this.getCommunicationLog();
    }
  }

  public render() {
    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader close={true} title="voipInfoModal.title" />
        <ModalBody noPadding={true}>
          {!!this.state.loading ? (
            <div styleName="loader">
              <Ui.Loaders.List />
            </div>
          ) : (
            <div>
              {!!this.state.log && (
                <div styleName="info">
                  <Visual
                    log={this.state.log}
                    onNavigate={this.onNavigateToRelation}
                  />

                  <div styleName="info__row">
                    <h2>
                      <ResourceText resourceKey="voipInfoModal.date" />
                    </h2>
                    <FormattedDate
                      value={this.state.log.date}
                      day="2-digit"
                      month="long"
                      year="numeric"
                      hour="2-digit"
                      minute="2-digit"
                      second="2-digit"
                    />
                  </div>

                  {!!this.state.log.subject && (
                    <div styleName="info__row">
                      <h2>
                        <ResourceText resourceKey="voipInfoModal.subject" />
                      </h2>
                      {this.state.log.subject}
                    </div>
                  )}

                  {!!this.state.log.description && (
                    <div styleName="info__row">
                      <h2>
                        <ResourceText resourceKey="voipInfoModal.description" />
                      </h2>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: nl2br(this.state.log.description)
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }

  private async getCommunicationLog() {
    if (this.state.loading) return;
    this.setState({ loading: true });

    try {
      const log = await this.props.getCommunicationLog(
        this.props.communicationLogId
      );

      this.setState({ log });
    } catch (error) {
      this.setState({ log: null });
      this.props.onClose();
    } finally {
      this.setState({ loading: false });
    }
  }

  private onNavigateToRelation(id: string, typeOfRelation: RelationType) {
    if (!id) return;
    let path;

    switch (typeOfRelation) {
      case RelationType.ContactCompany: {
        path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        break;
      }
      case RelationType.ContactPerson: {
        path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        break;
      }
      case RelationType.Employee: {
        path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
        break;
      }
      case RelationType.Office: {
        path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
        break;
      }
      default:
        break;
    }

    if (!path) return;
    this.props.onClose();
    this.props.navigate(path);
  }
}
