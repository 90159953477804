import {
  BlobFileDataPointer,
  CompanySettings,
  CompanySettingsClient,
  UploadResponse,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ParseRequest, Redirect } from "@haywork/services";
import { AppState, CompanyActions } from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import Axios, { AxiosRequestConfig } from "axios";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();

const saveCompanySettings = (
  companySettings: CompanySettings,
  showToast = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(CompanyActions.setCompanySettingsStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      const updatedCompanySettings = await parseRequest.response(
        CompanySettings.save({ companySettings }, realEstateAgencyId).then(
          (response) => response.companySettings
        )
      );

      dispatch(CompanyActions.setCompanySettings(updatedCompanySettings));

      if (showToast) {
        const toast: ToastProps = {
          value: "companySettings.toast.settings.success",
          icon: "check",
        };

        dispatch(SnackbarActions.addToast(toast));
      }
    } catch (error) {
      dispatch(CompanyActions.setCompanySettingsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const requestCollegialListingAuthorization = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(CompanyActions.setCollegialListingsStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      const redirect = new Redirect();

      const result = await parseRequest.response(
        CompanySettings.requestCollegialListingAuthorization(realEstateAgencyId)
      );

      redirect.navigate(result);
      redirect.onClose(async () => {
        const companySettings = await parseRequest.response(
          CompanySettings.read(realEstateAgencyId).then(
            (response) => response.companySettings
          )
        );

        dispatch(CompanyActions.setCompanySettings(companySettings));
      });
    } catch (error) {
      dispatch(CompanyActions.setCollegialListingsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deActivateCollegialListings = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(CompanyActions.setCollegialListingsStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { settings } = state.company;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      await parseRequest.response(
        CompanySettings.deactivateCollegialListings(realEstateAgencyId)
      );

      const companySettings = await parseRequest.response(
        CompanySettings.read(realEstateAgencyId).then(
          (response) => response.companySettings
        )
      );

      dispatch(CompanyActions.setCompanySettings(companySettings));
    } catch (error) {
      dispatch(CompanyActions.setCollegialListingsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getHousenetSingleSignOnUrl = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      return await parseRequest.response(
        CompanySettings.createHousenetSingleSignOnUrl(realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getExchangeSingleSignOnUrl = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      return await parseRequest.response(
        CompanySettings.createExchangeSingleSignOnUrl(realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const setCompanyLogo = (companyLogo: string | null) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(CompanyActions.setCompanySettingsStatus(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { settings, customCompanySettings } = state.company;

    const CompanySettings = new CompanySettingsClient(host);

    try {
      let companySettings: CompanySettings = {
        ...settings,
        customCompanySettings: JSON.stringify({
          ...customCompanySettings,
          companyLogo,
        }),
      };

      companySettings = await parseRequest.response(
        CompanySettings.save({ companySettings }, realEstateAgencyId).then(
          (response) => response.companySettings
        )
      );

      dispatch(CompanyActions.setCompanySettings(companySettings));
      const toast: ToastProps = {
        value: "companySettings.toast.success",
        icon: "wrench",
      };

      dispatch(SnackbarActions.addToast(toast));
      return companySettings;
    } catch (error) {
      dispatch(CompanyActions.setCompanySettingsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const setDocumentsLogo = (file: File | null) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { settings, customCompanySettings } = state.company;
    const { token } = state.access;

    const client = new CompanySettingsClient(host);
    let companyLogo: BlobFileDataPointer | null;

    try {
      if (!!file) {
        const formData = new FormData();
        const uploadUrl = `${host}/${apiVersion}/${realEstateAgencyId}/blobs/upload`;

        formData.append("isPrivate", "false");
        formData.append("upload", file);

        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          httpAgent: new HttpAgent({ keepAlive: true }),
          httpsAgent: new HttpsAgent({ keepAlive: true }),
        };

        const response = await parseRequest.response(
          Axios.post(uploadUrl, formData, config).then(
            (response) => response.data as UploadResponse
          )
        );

        companyLogo = {
          fileDataId: response.fileDataId,
          filename: [response.fileName, response.fileExtension].join(""),
        };
      }

      let companySettings: CompanySettings = {
        ...settings,
        documentSettings: {
          companyLogo,
        },
      };

      companySettings = await parseRequest.response(
        client
          .save({ companySettings }, realEstateAgencyId)
          .then((response) => response.companySettings)
      );

      dispatch(CompanyActions.setCompanySettings(companySettings));

      const toast: ToastProps = {
        value: "companySettings.toast.success",
        icon: "wrench",
      };

      dispatch(SnackbarActions.addToast(toast));
    } catch (error) {
      throw error;
    }
  };
};

export const CompanySettingsThunks = {
  saveCompanySettings,
  requestCollegialListingAuthorization,
  deActivateCollegialListings,
  getHousenetSingleSignOnUrl,
  getExchangeSingleSignOnUrl,
  setCompanyLogo,
  setDocumentsLogo,
};
