import get from "lodash-es/get";

export class FormControl {

  public static returnObjectPathOrNull = (object: any, path: string, state?: any): any => {
    let value = get(object, path, (state === undefined) ? "" : state);

    if ([null, ""].indexOf(value) !== -1) {
      value = (state === undefined) ? "" : state;
    }

    return value;
  }
}
