import * as React from "react";
import * as CSSModules from "react-css-modules";

import { DynamicDocumentsListOfGoodsContainerProps } from "@haywork/modules/dynamic-documents";
import { ListOfGoodsType } from "@haywork/api/kolibri";
import { ListOfGoodsValueExtended } from "@haywork/stores/dynamic-documents/single";
import { DynamicDocumentsUtil } from "@haywork/util";

import { HeaderComponent, ItemComponent } from "./elements";

const styles = require("./list-of-goods.component.scss");

export interface DynamicDocumentsListOfGoodsComponentProps {}
interface DynamicDocumentsListOfGoodsComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsListOfGoodsComponent extends React.Component<
  DynamicDocumentsListOfGoodsComponentProps & DynamicDocumentsListOfGoodsContainerProps,
  DynamicDocumentsListOfGoodsComponentState
> {
  private idx: number = 0;
  constructor(props) {
    super(props);
  }

  public render() {
    const { listOfGoods } = this.props.session;
    return (
      <div styleName="listOfGoods">
        <div className="container-fluid">{this.renderListOfGoodsValue(this.props.listOfGoods, false, true)}</div>
      </div>
    );
  }

  private renderListOfGoodsValue(
    listOfGoodsValue: ListOfGoodsValueExtended,
    canDelete: boolean,
    isRoot: boolean = false
  ) {
    if (!listOfGoodsValue) return;
    if (listOfGoodsValue.listOfGoodsType === ListOfGoodsType.Category && !isRoot) {
      return (
        <div styleName="category" key={listOfGoodsValue.id}>
          {this.renderListofGoodsSubComponent(listOfGoodsValue, canDelete, isRoot)}
        </div>
      );
    } else {
      return this.renderListofGoodsSubComponent(listOfGoodsValue, canDelete, isRoot);
    }
  }

  private renderListofGoodsSubComponent(
    listOfGoodsValue: ListOfGoodsValueExtended,
    canDelete: boolean,
    isRoot: boolean
  ) {
    const components = [];
    if (!isRoot) {
      components.push(this.renderComponent(listOfGoodsValue, canDelete));
    }

    if (listOfGoodsValue.extendedSubEntries) {
      listOfGoodsValue.extendedSubEntries.map((subEntry, idx) => {
        const indexOfLastNonCategoryItem = DynamicDocumentsUtil.getIndexOfLastNonCategoryItem(listOfGoodsValue);

        canDelete = listOfGoodsValue.extendedSubEntries.length > 1 && idx !== indexOfLastNonCategoryItem;
        components.push(this.renderListOfGoodsValue(subEntry, canDelete));
      });
    }

    return components;
  }

  private renderComponent(listOfGoodsValue: ListOfGoodsValueExtended, canDelete: boolean): JSX.Element {
    switch (listOfGoodsValue.listOfGoodsType) {
      case ListOfGoodsType.Category:
        return (
          <HeaderComponent
            key={listOfGoodsValue.id}
            listOfGoodsValue={listOfGoodsValue}
            addEmptyValueToListOfGoods={this.props.addEmptyValueToListOfGoods}
          />
        );
      case ListOfGoodsType.Item:
        return (
          <ItemComponent
            key={listOfGoodsValue.id}
            listOfGoodsValue={listOfGoodsValue}
            idx={this.idx}
            onChange={this.props.updateListOfGoods}
            onDelete={this.props.deleteFromListOfGoods}
            canDelete={canDelete}
          />
        );
      default:
        break;
    }
  }
}
