import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  callStartedOn: number | null;
  callEndedOn: number | null;
}
interface State {
  seconds: number;
}

@CSSModules(styles, { allowMultiple: true })
export class VoipWidgetTimeComponent extends React.PureComponent<Props, State> {
  private interval: any;

  constructor(props) {
    super(props);

    this.state = {
      seconds: !this.props.callStartedOn
        ? 0
        : Math.round(
            ((this.props.callEndedOn || new Date().getTime()) -
              this.props.callStartedOn) /
              1000
          ),
    };
  }

  public componentDidMount() {
    if (!!this.props.callEndedOn || !this.props.callStartedOn) return;

    this.interval = setInterval(() => {
      this.setState(({ seconds }) => ({ seconds: seconds + 1 }));
    }, 1000);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.callStartedOn && !!this.props.callStartedOn) {
      this.setState({
        seconds: Math.round(
          (new Date().getTime() - this.props.callStartedOn) / 1000
        ),
      });
      this.interval = setInterval(() => {
        this.setState(({ seconds }) => ({ seconds: seconds + 1 }));
      }, 1000);
    }

    if (!prevProps.callEndedOn && !!this.props.callEndedOn) {
      if (!!this.interval) {
        clearInterval(this.interval);
      }
      this.setState({
        seconds: Math.round(
          (this.props.callEndedOn - this.props.callStartedOn) / 1000
        ),
      });
    }
  }

  public componentWillUnmount() {
    if (!!this.interval) {
      clearInterval(this.interval);
    }
  }

  public render() {
    if (!this.props.callStartedOn) return null;
    return (
      <div
        styleName={classNames("time", {
          active: !!this.props.callStartedOn && !this.props.callEndedOn,
        })}
      >
        {this.renderPrettyTime(this.state.seconds)}
      </div>
    );
  }

  private renderPrettyTime(totalSeconds: number) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}
