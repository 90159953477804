import { actions as LiveDataActions } from "./actions";
import { LiveDataState, liveDataReducer } from "./reducer";

export enum LiveDataConnection {
  Mail = "Mail",
  Voip = "Voip",
  Kolibri = "Kolibri",
  EventCenter = "EventCenter"
}

export { LiveDataActions, LiveDataState, liveDataReducer };
