import I18n from "@haywork/components/i18n";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Upload } from "./file-upload.component";

const styles = require("./file-upload.component.scss");

interface Props {
  visible: boolean;
  uploadedPercentage: number;
  completed: boolean;
  errored: Upload[];
  onClose: (cancelUpload?: boolean) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class FileUploadModalComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
  }

  public render() {
    const barStyles = { width: `${this.props.uploadedPercentage}%` };
    const hasErrors = this.props.completed && this.props.errored.length > 0;
    const barStyle = classNames("bar", {
      processing: this.props.uploadedPercentage === 100 && !hasErrors,
      "has-errors": hasErrors,
    });

    return (
      <Modal
        visible={this.props.visible}
        onClose={this.onCloseHandler}
        data-cy={this.props["data-cy"]}
      >
        <ModalHeader
          title="modalUploadTitle"
          close
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.ModalHeader`
          }
        />
        <ModalBody
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.ModalBody`
          }
        >
          <div styleName="loader">
            <div styleName={barStyle} style={barStyles} />
            <div styleName="label">{this.renderLoaderLabel()}</div>
          </div>
          {hasErrors && this.renderErroredFiles()}
          {!this.props.completed && (
            <div styleName="warning">
              <span className="fal fa-exclamation-triangle" />
              <div styleName="label">
                <I18n value="modalUploadText" />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.ModalFooter`
          }
        >
          {hasErrors && (
            <div
              className="btn btn-primary"
              onClick={this.onCloseHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.CloseButton`
              }
            >
              <I18n value="okay" />
            </div>
          )}
          {!hasErrors && (
            <div
              className="btn btn-danger"
              onClick={this.onCloseHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.CloseButton`
              }
            >
              <I18n value="cancel" />
            </div>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (
      !!nextProps.completed &&
      !this.props.completed &&
      nextProps.errored.length === 0
    ) {
      this.props.onClose();
    }
  }

  private onCloseHandler() {
    this.props.onClose(!this.props.completed);
  }

  private renderLoaderLabel(): React.ReactElement<HTMLSpanElement> {
    if (this.props.completed && this.props.errored.length > 0)
      return <I18n value="uploadErrors" />;
    if (this.props.uploadedPercentage === 100)
      return <I18n value="uploadProcessing" />;
    return <span>{this.props.uploadedPercentage}%</span>;
  }

  private renderErroredFiles(): React.ReactElement<HTMLDivElement> {
    return (
      <div styleName="errors">
        <I18n value="erroredUploadFiles" />
        {this.props.errored.map((file, idx) => (
          <div styleName="error" key={idx}>
            <span className="fal fa-file-image" />
            <div styleName="label">
              <div>{file.file.name}</div>
              {!!file.error && file.error === "Invalid file name." && (
                <div styleName="label__error">
                  <I18n value="file.invalid.name" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
