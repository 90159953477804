import { actions as MessagesActions } from "./actions";
import { reducer as messagesReducer, State } from "./reducer";
import { Message } from "@haywork/api/mail";

export type EmailMessage = Message & {
  accountId: string;
  folderId: string;
  searchString: string;
};
export type MessagesState = State;
export { messagesReducer, MessagesActions };
