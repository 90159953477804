import {
  AssignmentSnapShot,
  GlobalSearchType,
  InvoiceSnapShot,
  RelationSnapShot,
  TaskSnapShot
} from "@haywork/api/kolibri";
import { MAINROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  Dispatch,
  GlobalSearchThunks,
  InvoiceThunk,
  TaskThunks
} from "@haywork/middleware";
import {
  GlobalSearchSearchComponent,
  GlobalSearchSearchComponentProps
} from "@haywork/modules/global-search";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  assignments: AssignmentSnapShot[];
  relations: RelationSnapShot[];
  invoices: InvoiceSnapShot[];
  tasks: TaskSnapShot[];
  assignmentCount: number;
  relationCount: number;
  invoiceCount: number;
  taskCount: number;
  searchStatus: string;
}

interface DispatchProps {
  search: (query: string, type?: GlobalSearchType, take?: number) => any;
  navigate: (pathname: string) => any;
  createNewRelation: (newRelationName: string) => void;
  createAssignment: () => void;
  createInvoice: () => void;
  createTask: () => void;
}

const mapStateToProps = <StateProps, GlobalSearchSearchComponentProps>(
  state: AppState
) => {
  const {
    assignments,
    relations,
    assignmentCount,
    relationCount,
    searchStatus,
    invoices,
    invoiceCount,
    tasks,
    taskCount
  } = state.globalSearch;

  return {
    assignments,
    relations,
    assignmentCount,
    relationCount,
    searchStatus,
    invoices,
    invoiceCount,
    tasks,
    taskCount
  };
};

const mapDispatchToProps = <DispatchProps, GlobalSearchSearchComponentProps>(
  dispatch: Dispatch<any>
) => ({
  search: (query: string, type?: GlobalSearchType, take?: number) =>
    dispatch(GlobalSearchThunks.search(query, type, take)),
  createNewRelation: (newRelationName: string) =>
    dispatch(push(MAINROUTES.RELATIONS.URI + "/new")),
  createInvoice: () => dispatch(InvoiceThunk.createInvoice()),
  navigate: (pathname: string) => dispatch(push(pathname)),
  createAssignment: () => dispatch(AssignmentThunks.createAssignment()),
  createTask: () => dispatch(TaskThunks.createTask())
});

export type GlobalSearchSearchContainerProps = StateProps & DispatchProps;
export const GlobalSearchSearchContainer = connect<
  StateProps,
  DispatchProps,
  GlobalSearchSearchComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchSearchComponent);
