import {
  FolderTreeFileEntity,
  FolderTreeFileType,
  FolderTreeFolderEntity,
  FolderTreeRootFolderEntity,
  FolderTree
} from "@haywork/api/kolibri";
import sortBy from "lodash-es/sortBy";

export class FolderTreeUtil {
  public static sortFiles(folders: FolderTreeFolderEntity[]) {
    return (folders || []).map((folder) => {
      let { files, folders } = folder;
      files = FolderTreeUtil._sort(files);
      folders = FolderTreeUtil.sortFiles(folders);

      return {
        ...folder,
        folders,
        files
      };
    });
  }

  public static sortRootFiles(
    tree: FolderTreeRootFolderEntity
  ): FolderTreeRootFolderEntity {
    let { documentSessions, invoices, dossierItems } = tree;
    documentSessions = {
      ...documentSessions,
      folders: FolderTreeUtil.sortFiles(documentSessions.folders),
      files: FolderTreeUtil._sort(documentSessions.files)
    } || {
      name: "DocumentSession",
      folders: [],
      files: [],
      isSystemFolder: false
    };
    invoices = {
      ...invoices,
      folders: FolderTreeUtil.sortFiles(invoices.folders),
      files: FolderTreeUtil._sort(invoices.files)
    } || {
      name: "Invoices",
      folders: [],
      files: [],
      isSystemFolder: false
    };
    dossierItems = {
      ...dossierItems,
      folders: FolderTreeUtil.sortFiles(dossierItems.folders),
      files: FolderTreeUtil._sort(dossierItems.files)
    } || { name: "Files", folders: [], files: [], isSystemFolder: false };

    return {
      documentSessions,
      invoices,
      dossierItems
    };
  }

  public static placeUploadsInFolderTree(
    folderTree: FolderTree,
    path: string,
    ids: string[]
  ): FolderTree {
    let {
      dossierItems,
      documentSessions,
      invoices
    } = folderTree.rootFolderTree;
    dossierItems = dossierItems || { isSystemFolder: true };
    documentSessions = documentSessions || { isSystemFolder: true };
    invoices = invoices || { isSystemFolder: true };

    dossierItems = FolderTreeUtil.placeUploadsInFolderTreeFolderEntity(
      dossierItems,
      path,
      ids
    );
    documentSessions = FolderTreeUtil.placeUploadsInFolderTreeFolderEntity(
      documentSessions,
      path,
      ids
    );
    invoices = FolderTreeUtil.placeUploadsInFolderTreeFolderEntity(
      invoices,
      path,
      ids
    );

    return {
      ...folderTree,
      rootFolderTree: {
        dossierItems,
        documentSessions,
        invoices
      }
    };
  }

  private static _sort(files: FolderTreeFileEntity[]): FolderTreeFileEntity[] {
    return sortBy(files || [], (file) => {
      switch (file.type) {
        case FolderTreeFileType.DocumentSession: {
          return file.linkedDocumentSession.dateTimeCreated;
        }
        case FolderTreeFileType.Invoice: {
          return (
            file.linkedInvoice.dueDate || file.linkedInvoice.dateTimeCreated
          );
        }
        case FolderTreeFileType.DossierItem: {
          return file.linkedDossierItem.dateTimeCreated;
        }
        default: {
          return null;
        }
      }
    }).reverse();
  }

  private static placeUploadsInFolderTreeFolderEntity(
    folder: FolderTreeFolderEntity,
    path: string,
    ids: string[],
    parentName: string = ""
  ): FolderTreeFolderEntity {
    const { folders, name, files } = folder;
    const composedName = [parentName, name].filter((f) => !!f).join("/");

    if (composedName === path) {
      return {
        ...folder,
        files: [
          ...(files || []),
          ...ids.map((id) => ({ id, type: FolderTreeFileType.DossierItem }))
        ]
      };
    } else {
      return {
        ...folder,
        folders: (folders || []).map((ref) =>
          FolderTreeUtil.placeUploadsInFolderTreeFolderEntity(
            ref,
            path,
            ids,
            composedName
          )
        )
      };
    }
  }
}
