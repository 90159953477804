import { CommunicationLogThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { TimelineComponent, TimelineComponentProps } from "./timeline";
import { CommunicationLog } from "@haywork/api/kolibri";

interface StateProps {}
interface DispatchProps {
  navigate: (path: string) => RouterAction;
  deleteCommunicationLog: (
    id: string,
    entityId: string
  ) => Promise<CommunicationLog>;
  unDeleteCommunicationLog: (
    id: string,
    originalCommunicationLog: CommunicationLog
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  TimelineComponentProps,
  AppState
> = (state) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  TimelineComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  deleteCommunicationLog: (id: string, entityId: string) =>
    dispatch(CommunicationLogThunk.deleteCommunicationLog(id, entityId)),
  unDeleteCommunicationLog: (
    id: string,
    originalCommunicationLog: CommunicationLog
  ) =>
    dispatch(
      CommunicationLogThunk.unDeleteCommunicationLog(
        id,
        originalCommunicationLog
      )
    ),
});

export type TimelineContainerProps = StateProps & DispatchProps;
export const TimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineComponent);
