import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import isString from "lodash-es/isString";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./empty-state.component.scss");

export enum UiEmptyStateType {
  List = "List",
  Widget = "Widget",
}

export enum UiEmptyStateStickMan {
  Error = "Error",
  Dashboard = "Dashboard",
  FeatureNotAvailable = "FeatureNotAvailable",
  NewRelation = "NewRelation",
  NoAroItems = "NoAroItems",
  NoAssignments = "NoAssignments",
  NoAssignmentsOverview = "NoAssignmentsOverview",
  NoFiles = "NoFiles",
  NoPhoto = "NoPhoto",
  NoRelations = "NoRelations",
  NoTasks = "NoTasks",
  NoTimeline = "NoTimeline",
}

interface UiEmptyStateComponentProps {
  type: UiEmptyStateType;
  stickman: UiEmptyStateStickMan;
  title?: { key: string; values?: object } | string;
  subtitle?: { key: string; values?: object } | string;
  onClick?: () => void;
  fullscreen?: boolean;
}
interface UiEmptyStateComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class UiEmptyStateComponent extends React.Component<
  UiEmptyStateComponentProps,
  UiEmptyStateComponentState
> {
  public render() {
    const emptyStateStyle = classNames(
      "empty-state",
      this.props.type.toString().toLowerCase(),
      {
        fullscreen:
          this.props.fullscreen && this.props.type === UiEmptyStateType.List,
      }
    );
    const stickmanStyle = classNames(
      "empty-state__stickman",
      this.props.stickman.toString().toLowerCase()
    );

    return (
      <div styleName={emptyStateStyle} data-cy={this.props["data-cy"]}>
        <div styleName="empty-state__inner">
          <div styleName={stickmanStyle} />
          <div styleName="empty-state__content">
            {this.renderTitle(this.props.title)}
            {this.renderTitle(this.props.subtitle, true)}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  private renderTitle(
    title: { key: string; values?: object } | string,
    isSubtitle: boolean = false
  ): React.ReactElement<HTMLDivElement> {
    if (!title) return null;
    const { key, values } = isString(title)
      ? { key: title, values: {} }
      : title;

    if (!isSubtitle) {
      return (
        <div styleName="empty-state__title">
          <ResourceText resourceKey={key} values={values} asHtml />
        </div>
      );
    } else {
      return (
        <div
          styleName="empty-state__subtitle"
          onClick={this.props.onClick && this.props.onClick}
        >
          <ResourceText resourceKey={key} values={values} asHtml />
        </div>
      );
    }
  }
}
