import { ActionType, ActionTypes } from "./actions";
import {
  EventNotification,
  EventNotificationsOrderByField,
  SortOrder,
  EventNotificationStatistics,
} from "@haywork/api/event-center";
import { FilterConfig } from "@haywork/components/ui/list";
import { NotificationsOrder } from ".";
import { FilterType } from "@haywork/enum/list-filter-types";

export type State = {
  notifications: EventNotification[];
  totalCount: number;
  order: NotificationsOrder;
  filters: FilterConfig;
  scrollOffset: number;
  statistics: EventNotificationStatistics | null;
};

const INITIAL: State = {
  notifications: [],
  totalCount: 0,
  order: {
    sortOrder: SortOrder.Descending,
    sortColumn: EventNotificationsOrderByField.DefaultOrder,
  },
  filters: {
    typeFilters: {
      type: FilterType.Array,
      value: ["personal"],
      emptyValue: [],
      prefix: "typeFilters",
    },
    personalNotifications: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "eventTypes",
    },
    generalNotifications: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "eventTypes",
    },
    eventNotificationDateTime: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
  },
  scrollOffset: 0,
  statistics: null,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, startIndex } = action;

      let notifications = [...state.notifications];
      if (!notifications.length) {
        notifications = new Array(totalCount).fill(null);
      }

      for (let i = 0; i < items.length; i++) {
        notifications[i + startIndex] = items[i];
      }

      return {
        ...state,
        notifications,
        totalCount,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        notifications: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        notifications: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.RemoveByEventId: {
      const { eventId } = action;
      const match = state.notifications.find(
        (notification) => notification?.linkedEvent?.id === eventId
      );

      if (!match) return state;
      const count = state.notifications.length;
      const notifications = state.notifications.filter(
        (notification) => notification?.linkedEvent?.id !== eventId
      );
      const totalCount = state.totalCount - (count - notifications.length);

      return {
        ...state,
        notifications,
        totalCount,
      };
    }
    case ActionType.UpdateListItem: {
      const { notification } = action;
      const notifications = state.notifications.map((item) => {
        return item
          ? item.id === notification.id
            ? notification
            : item
          : null;
      });

      return {
        ...state,
        notifications,
      };
    }
    case ActionType.UpdateStatistics: {
      const { statistics } = action;

      return {
        ...state,
        statistics,
      };
    }
    case ActionType.BulkUpdateListItems: {
      const { notifications: updateables } = action;
      const notifications = state.notifications.map((item) => {
        if (!item) return null;
        const ref = updateables.find((updateable) => updateable.id === item.id);
        return !ref ? item : ref;
      });

      return {
        ...state,
        notifications,
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
