import { combineReducers } from "redux";
import {
  notificationsReducer,
  NotificationsActions,
  NotificationsState
} from "./notifications";
import { settingsReducer, SettingsActions, SettingsState } from "./settings";
import {
  dashboardReducer,
  DashboardState,
  DashboardActions
} from "./dashboard";
import {
  entityListStatusReducer,
  EntityListStatusState,
  EntityListStatusActions
} from "./entity-list-status";
import {
  presenceReducer,
  PresenceState,
  PresenceActions,
  PresentEmployee
} from "./presence";

const eventCenterReducer = combineReducers({
  notifications: notificationsReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  entityListStatus: entityListStatusReducer,
  presence: presenceReducer
});

interface EventCenterState {
  notifications: NotificationsState;
  settings: SettingsState;
  dashboard: DashboardState;
  entityListStatus: EntityListStatusState;
  presence: PresenceState;
}

const EventCenterActions = {
  Notifications: NotificationsActions,
  Settings: SettingsActions,
  Dashboard: DashboardActions,
  EntityListStatus: EntityListStatusActions,
  Presence: PresenceActions
};

export {
  eventCenterReducer,
  EventCenterState,
  EventCenterActions,
  PresentEmployee
};
