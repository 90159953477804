import * as React from "react";
import { MouseEvent, memo } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import head from "lodash-es/head";
import get from "lodash-es/get";

import { ResourceText, FontStyle } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  AppClientSnapShot,
  AppClientTranslationSnapShot,
  RemunerationCategory,
  AppClientStatus,
} from "@haywork/api/authorization";
import { Employee } from "@haywork/api/kolibri";
import { CurrentRealestateAgency } from "@haywork/stores";
import { EXTERNALROUTES } from "@haywork/constants";
import { ZohoFormsService } from "@haywork/services";

const styles = require("./overview.component.scss");

interface Props {
  client: AppClientSnapShot;
  employee: Employee;
  currentRealestateAgency: CurrentRealestateAgency;
  idx: number;
  onClick: (client: AppClientSnapShot) => void;
}

export const Client = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const { client, idx, onClick, employee, currentRealestateAgency } = props;
    const partnerStyle = classNames("partner", {
      first: idx === 0,
      zebra: idx % 2 !== 0,
      clickable: client.status === AppClientStatus.InProduction,
    });
    const isPaid = client.remunerationCategory !== RemunerationCategory.Free;
    const isFree = client.remunerationCategory === RemunerationCategory.Free;

    const isActive =
      !!get(client.linkedConsent, "dateTimeCreated") || client.isFirstParty;
    const paidStyle = classNames("pill", {
      "is-paid": isPaid,
      "is-free": isFree,
    });
    const activeStyle = classNames(
      "btn",
      isActive ? "btn-white" : "btn-primary"
    );

    const clientTranslation = head(client.translationSnapShots);
    const imageStyles: React.CSSProperties = !!clientTranslation.logoUrl
      ? { backgroundImage: `url(${clientTranslation.logoUrl})` }
      : null;

    return (
      <div styleName={partnerStyle} onClick={() => onClick(client)}>
        <div styleName="partner__avatar" style={imageStyles}>
          {!clientTranslation.logoUrl && <i className="fal fa-rocket" />}
        </div>
        <div styleName="partner__data">
          <div styleName="category-score">
            <h2>
              <ResourceText
                masterKey="appClientCategoryOptions"
                resourceKey={client.category.toString()}
              />
            </h2>
            {!!client.rating && <Ui.Score score={client.rating} />}
          </div>
          <h1>{clientTranslation.title}</h1>
          <div styleName="indevelopment-status">
            {client.status === AppClientStatus.InDevelopment &&
              client.developerName && (
                <ResourceText
                  resourceKey="appXchangeSettings.InDevelopmentWithDeveloper"
                  fontStyle={FontStyle.UcFirst}
                  values={{ developer: client.developerName }}
                />
              )}
            {client.status === AppClientStatus.InDevelopment &&
              !client.developerName && (
                <ResourceText
                  resourceKey="appXchangeSettings.InDevelopment"
                  fontStyle={FontStyle.UcFirst}
                />
              )}
          </div>
          <h3>{clientTranslation.introduction}</h3>
        </div>
        <div styleName="partner__action">
          {client.status === AppClientStatus.InDevelopment && (
            <>
              <InformButton
                clientTranslation={clientTranslation}
                employee={employee}
                currentRealestateAgency={currentRealestateAgency}
              />
            </>
          )}
          {client.status === AppClientStatus.InProduction && (
            <>
              <div styleName={paidStyle}>
                <ResourceText
                  masterKey="remunerationCategoryOptions"
                  resourceKey={client.remunerationCategory.toString()}
                />
              </div>
              <button className={activeStyle} type="button">
                {isActive ? (
                  <ResourceText
                    resourceKey="active"
                    fontStyle={FontStyle.UcFirst}
                  />
                ) : (
                  <ResourceText
                    resourceKey="moreInfo"
                    fontStyle={FontStyle.UcFirst}
                  />
                )}
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
);

interface InformButtonProps {
  clientTranslation: AppClientTranslationSnapShot;
  employee: Employee;
  currentRealestateAgency: CurrentRealestateAgency;
}

const InformButton = memo(
  ({
    clientTranslation,
    employee,
    currentRealestateAgency,
  }: InformButtonProps) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      // Prevent the event bubble so we don't open the detail page
      e.stopPropagation();
      const params = {
        wzname: employee.displayName,
        wzcompany: currentRealestateAgency.name,
        wzpartner: clientTranslation.title,
        wzemail: head(employee.emailAddresses).address,
      };

      /**
       * Opens the form with params in a new window
       */
      ZohoFormsService.openFormInNewWindow(
        "KolibriAppXchangeappinteresse",
        "5xVaG0C2mXUfkEkpPd3B1n7tFCxelujwrIbY62_PJR4",
        params
      );
    };

    return (
      <button type="button" className="btn btn-primary" onClick={handleClick}>
        <ResourceText
          resourceKey="appXchangeSettings.KeepMeInformed"
          fontStyle={FontStyle.UcFirst}
        />
      </button>
    );
  }
);
