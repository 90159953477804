import { EmployeeRole, FinancialAdministration } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  SettingsDetailComponent,
  SettingsDetailComponentProps,
} from "@haywork/modules/settings";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  financialAdministrations: FinancialAdministration[];
  realEstateAgencyId: string;
  isBackofficeEmployee: boolean;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, SettingsDetailComponentProps>(
  state: AppState
) => {
  const { financialAdministrations, currentRealestateAgency } = state.account;
  const { id: realEstateAgencyId, role } = currentRealestateAgency;

  return {
    realEstateAgencyId,
    financialAdministrations,
    isBackofficeEmployee: role === EmployeeRole.BackOffice,
  };
};

const mapDispatchToProps = <DispatchProps, SettingsDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type SettingsDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const SettingsDetailContainer = withRouter(
  connect<StateProps, DispatchProps, SettingsDetailComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsDetailComponent)
);
