import { REQUEST } from "@haywork/constants";
import { CompanySettingsThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsCompanySettingsComponent,
  SettingsCompanySettingsComponentProps,
} from "./company-settings";
import { CompanySettings } from "@haywork/api/kolibri";
import { createSelector } from "reselect";

interface StateProps {
  companyLogo: string;
  loading: boolean;
  settings: CompanySettings;
  smsFromFieldShortCompanyName: string;
}
interface DispatchProps {
  setCompanyLogo: (companyLogo: string) => Promise<CompanySettings>;
  saveCompanySettings: (
    companySettings: CompanySettings,
    showToast?: boolean
  ) => void;
}
const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsCompanySettingsComponentProps,
  AppState
> = (state) => {
  const { settings } = state.company;
  return {
    companyLogo: get(state.company.customCompanySettings, "companyLogo", null),
    smsFromFieldShortCompanyName: get(
      state.company.settings,
      "smsFromFieldShortCompanyName",
      null
    ),
    loading: state.company.settingsStatus === REQUEST.PENDING,
    settings,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsCompanySettingsComponentProps
> = (dispatch) => ({
  setCompanyLogo: (companyLogo: string | null) =>
    dispatch(CompanySettingsThunks.setCompanyLogo(companyLogo)),
  saveCompanySettings: (settings: CompanySettings) =>
    dispatch(CompanySettingsThunks.saveCompanySettings(settings, true)),
});

export type SettingsCompanySettingsContainerProps = StateProps & DispatchProps;
export const SettingsCompanySettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsCompanySettingsComponent);
