import {
  AssignmentSnapShot,
  AssignmentType,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import {
  AssignmentListItemComponent,
  AssignmentOverviewContainerProps,
} from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  CheckboxFilter,
  Filter,
  MappedCheckboxValue,
} from "@haywork/modules/filter";
import {
  InfiniteScroll,
  ListLoader,
  ResourceText,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { AssignmentsFilters } from "@haywork/stores";
import { SimpleLabelOriginal } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import SpecFilters from "../spec-filters";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";

const styles = require("./overview.component.scss");

export interface AssignmentOverviewComponentProps {}
interface State {
  toggleShowSidebar: boolean;
}
type Props = AssignmentOverviewComponentProps &
  AssignmentOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentOverviewComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toggleShowSidebar: false,
    };

    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onChangeFilterHandler = this.onChangeFilterHandler.bind(this);
    this.onSelectedFilterClickHandler =
      this.onSelectedFilterClickHandler.bind(this);
    this.onItemClickHandler = this.onItemClickHandler.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.renderSelectedItemsSubtitle =
      this.renderSelectedItemsSubtitle.bind(this);
  }

  public componentDidMount() {
    this.props.getAssignments(true);
  }

  public render() {
    const combined = classNames("assignments ", {
      collapsed: this.state.toggleShowSidebar,
    });
    const toggleIcon = classNames(
      "fal fa-fw",
      this.state.toggleShowSidebar ? "fa-filter" : "fa-arrow-left"
    );

    return (
      <div styleName={combined}>
        <div styleName="assignments__header">
          <div styleName="clear-filters">
            {this.props.selectedFilters.length === 0 ? (
              <ResourceText resourceKey="noActiveFilters" />
            ) : (
              <a
                onClick={this.props.clearAllFilters}
                data-cy="CY-clearAllFilters"
              >
                <ResourceText resourceKey="deleteFilters" />
              </a>
            )}
          </div>
          <div styleName="toggle" onClick={this.toggleSidebar}>
            <div styleName="trigger">
              <i className={toggleIcon} />
            </div>
          </div>
          <div styleName="selected-filters">
            {this.props.selectedFilters.map((selectedFilter, idx) => (
              <Pill
                key={idx}
                label={selectedFilter.label}
                labelValues={{ value: selectedFilter.value }}
                color={Colors.Primary}
                solid
                deletable
                onClick={() =>
                  this.onSelectedFilterClickHandler(selectedFilter)
                }
              />
            ))}
          </div>
          <div styleName="export" className="hidden-xs">
            <button
              className="btn btn-success excel"
              onClick={this.props.exportAssignments}
            >
              <i className="fal fa-fw fa-file-excel" />
              <ResourceText resourceKey="exportExcel" />
            </button>
          </div>
        </div>
        <div styleName="assignments__body">
          <div styleName="assignments__sidebar">
            <Filter changeFilter={this.onChangeFilterHandler}>
              <CheckboxFilter
                name="filterByRealEstateGroups"
                value={this.props.assignmentsFilters.filterByRealEstateGroups}
                values={this.props.realEstateGroups}
                title="typeOfAssignment"
              />

              <FeatureSwitch feature="PROJECTS">
                <CheckboxFilter
                  name="filterByAssignmentTypes"
                  value={this.props.assignmentsFilters.filterByAssignmentTypes}
                  values={this.props.assignmentTypes}
                  title="assignmentType"
                />
              </FeatureSwitch>

              <CheckboxFilter
                name="kindOfAssignment"
                value={this.props.assignmentsFilters.kindOfAssignment}
                values={this.props.kindOfAssignment}
                title="kindOfAssignment"
              />

              {!!this.props.keyNumbers && this.props.keyNumbers.length !== 0 && (
                <FeatureSwitch feature="KEYBOARD">
                  <CheckboxFilter
                    name="filterByKeyNumbers"
                    value={this.props.assignmentsFilters.filterByKeyNumbers}
                    values={this.props.keyNumbers}
                    title="keyNumbers"
                    canToggle
                    visible={false}
                    inline={true}
                    disableSort={true}
                    subtitle={
                      this.props.assignmentsFilters.filterByKeyNumbers &&
                      this.props.assignmentsFilters.filterByKeyNumbers.length >
                        0 ? (
                        this.props.assignmentsFilters.filterByKeyNumbers.join(
                          ", "
                        )
                      ) : (
                        <ResourceText resourceKey="filterByKeyNumbers" />
                      )
                    }
                  />
                </FeatureSwitch>
              )}

              <CheckboxFilter
                name="listingTypes"
                data-cy="CY-listingTypes"
                value={this.props.assignmentsFilters.listingTypes}
                values={this.props.listingTypes}
                title="objectType"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.listingTypes &&
                  this.props.assignmentsFilters.listingTypes.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.listingTypes,
                        this.props.assignmentsFilters.listingTypes,
                        this.removeListingTypeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.listingTypes)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="assignmentPhases"
                value={this.props.assignmentsFilters.assignmentPhases}
                values={this.props.assignmentPhases}
                title="status"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.assignmentPhases &&
                  this.props.assignmentsFilters.assignmentPhases.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.assignmentPhases,
                        this.props.assignmentsFilters.assignmentPhases,
                        this.removeAssignmentPhaseFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.assignmentPhases)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="availabilityStatuses"
                value={this.props.assignmentsFilters.availabilityStatuses}
                values={this.props.availabilityStatuses}
                title="availability"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.availabilityStatuses &&
                  this.props.assignmentsFilters.availabilityStatuses.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.availabilityStatuses,
                        this.props.assignmentsFilters.availabilityStatuses,
                        this.removeAvailabilityStatusFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.availabilityStatuses)
                }
                hideCheckedItems
              />
              <SpecFilters
                filters={this.props.assignmentsFilters}
                furnishings={this.props.furnishingOptions}
              />
              <CheckboxFilter
                name="officeIds"
                value={this.props.assignmentsFilters.officeIds}
                values={this.props.offices}
                title="office"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.officeIds &&
                  this.props.assignmentsFilters.officeIds.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.offices,
                        this.props.assignmentsFilters.officeIds,
                        this.removeOfficeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.offices)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="employeeIds"
                value={this.props.assignmentsFilters.employeeIds}
                values={this.props.employees}
                title="selectEmployees"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.employeeIds &&
                  this.props.assignmentsFilters.employeeIds.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.employees,
                        this.props.assignmentsFilters.employeeIds,
                        this.removeEmployeeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.employees)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="localities"
                value={this.props.assignmentsFilters.localities}
                values={this.props.localities}
                title="city"
                canToggle
                visible={false}
                subtitle={
                  this.props.assignmentsFilters.localities &&
                  this.props.assignmentsFilters.localities.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.localities,
                        this.props.assignmentsFilters.localities,
                        this.removeLocalityFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.localities)
                }
                hideCheckedItems
              />
            </Filter>
          </div>
          <div styleName="assignments__view">
            <div styleName="assignments__statistics">
              <div styleName="assignments-count">
                <ResourceText
                  resourceKey="assignmentsInTotal"
                  values={{ numberOfAssignments: this.props.assignments.total }}
                />
              </div>
              <div styleName="turnover-value">
                <FormattedNumber
                  value={this.props.commissionTotal || 0}
                  style="currency"
                  currency="EUR"
                />{" "}
                <ResourceText resourceKey="turnoverValue" />
              </div>
            </div>
            <div styleName="assignments__list">
              {this.props.assignments.results.length === 0 &&
                [REQUEST.PENDING, REQUEST.ERROR].indexOf(
                  this.props.assignments.status
                ) === -1 && (
                  <Ui.EmptyState
                    type={UiEmptyStateType.List}
                    stickman={UiEmptyStateStickMan.NoAssignmentsOverview}
                    title="emptyStateNoAssignmentsOverviewTitle"
                    subtitle="emptyStateNoAssignmentsOverviewText"
                  />
                )}
              <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
                <EntityListStatus
                  list="assignments"
                  onClick={() => this.props.getAssignments(true)}
                />

                {this.props.assignments.results.map((assignment, idx) => (
                  <ErrorBoundary key={idx}>
                    <AssignmentListItemComponent
                      assignment={assignment}
                      zebra={idx % 2 === 0}
                      onClick={this.onItemClickHandler}
                    />
                  </ErrorBoundary>
                ))}
                {this.props.assignments.canLoadMore && <ListLoader />}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public toggleSidebar() {
    this.setState({ toggleShowSidebar: !this.state.toggleShowSidebar });
  }

  private onScrollEndHandler() {
    if (
      this.props.assignments.status === REQUEST.PENDING ||
      !this.props.assignments.canLoadMore
    )
      return;
    this.props.getAssignments();
  }
  private removeListingTypeFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.listingTypes.indexOf(type);
    filters.listingTypes.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeAssignmentPhaseFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.assignmentPhases.indexOf(type);
    filters.assignmentPhases.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeAvailabilityStatusFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.availabilityStatuses.indexOf(type);
    filters.availabilityStatuses.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeOfficeFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.officeIds.indexOf(type);
    filters.officeIds.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeEmployeeFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.employeeIds.indexOf(type);
    filters.employeeIds.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeLocalityFromFilter(type) {
    const filters: AssignmentsFilters = { ...this.props.assignmentsFilters };
    const idx = filters.localities.indexOf(type);
    filters.localities.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private onChangeFilterHandler(values: any) {
    const filters = {
      ...values,
      employeeIds:
        !!values.employeeIds && values.employeeIds.length === 0
          ? null
          : values.employeeIds,
      filterByRealEstateGroups:
        !!values.filterByRealEstateGroups &&
        values.filterByRealEstateGroups.length === 0
          ? null
          : values.filterByRealEstateGroups,
      kindOfAssignment:
        !!values.kindOfAssignment && values.kindOfAssignment.length === 0
          ? null
          : values.kindOfAssignment,
      listingTypes:
        !!values.listingTypes && values.listingTypes.length === 0
          ? null
          : values.listingTypes,
      localities:
        !!values.localities && values.localities.length === 0
          ? null
          : values.localities,
      officeIds:
        !!values.officeIds && values.officeIds.length === 0
          ? null
          : values.officeIds,
      filterByKeyNumbers:
        !!values.filterByKeyNumbers && values.filterByKeyNumbers.length === 0
          ? null
          : values.filterByKeyNumbers,
    };

    this.props.setAssignmentsFilters(filters);
    this.props.getAssignments(true);
  }

  private onSelectedFilterClickHandler(filter: SimpleLabelOriginal) {
    this.props.clearFilter(filter);
  }

  private onItemClickHandler(
    id: string,
    type: RealEstateGroup,
    snapshot: AssignmentSnapShot
  ) {
    switch (snapshot.typeOfAssignment) {
      case AssignmentType.Object:
        return this.props.openAssignment(id, snapshot);
      case AssignmentType.ObjectType:
        return this.props.openObjectType(id, snapshot);
      case AssignmentType.Project:
        return this.props.openProject(id, snapshot);
      default:
        return this.props.goToFeature();
    }
  }

  private renderSubtitle(
    values: MappedCheckboxValue[]
  ): React.ReactElement<any>[] {
    const mapped = values.slice(0, 10);
    return mapped.map((val, idx) => (
      <span key={idx}>
        <ResourceText resourceKey={val.label} />
        {idx !== mapped.length - 1 ? ", " : ""}
      </span>
    ));
  }

  private renderSelectedItemsSubtitle(
    values: MappedCheckboxValue[],
    selectedValues: any[],
    removeMethod: (value) => void
  ) {
    const selectedMappedValues = values.filter((mappedValue) => {
      const idx = selectedValues.indexOf(mappedValue.value);
      return idx !== -1;
    });

    return (
      <div className="subtitle__selectedItems" styleName="selectedItems">
        {selectedMappedValues.map((value, index) => (
          <span
            key={value + "_" + index}
            onClick={(event) => {
              event.stopPropagation();
              removeMethod(value.value);
            }}
            className={styles.addedResult}
          >
            <span className={styles.remove}>
              <i className="fal fa-check" />
            </span>
            <ResourceText resourceKey={value.label} />
          </span>
        ))}
      </div>
    );
  }
}
