import {
  ObjectAssignment,
  OutdoorAreaFacility,
  PavementTypeOption,
  AcquisitionObjectAssignment
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  pavementTypeOptions: PavementTypeOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}
interface OutdoorArea {
  outdoorAreaCloseable: boolean;
  outdoorAreaFenced: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogOutdoorAreaComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const values = this.mapFacilitiesToValues(objectAssignment.facilities);

    this.formControls = {
      pavedPercentage: { value: value(objectAssignment, "pavedPercentage") },
      outdoorAreaCloseable: { value: values.outdoorAreaCloseable },
      outdoorAreaFenced: { value: values.outdoorAreaFenced },
      pavementType: { value: value(objectAssignment, "pavementType") },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const values = this.mapFacilitiesToValues(objectAssignment.facilities);

      this.formRef.update({
        pavedPercentage: value(objectAssignment, "pavedPercentage"),
        outdoorAreaCloseable: values.outdoorAreaCloseable,
        outdoorAreaFenced: values.outdoorAreaFenced,
        pavementType: value(objectAssignment, "pavementType"),
        parcelSurface: value(objectAssignment, "parcelSurface.area")
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="outdoor-area"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="pavedPercentage">
                <ResourceText resourceKey="pavedPercentage" />
              </label>
              <div className="input__helper">
                <Input.Number name="pavedPercentage" max={100} />
                <div className="post">%</div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="outdoorAreaFenced"
            label="outdoorAreaFenced"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row" styleName="retail-switch">
          <Input.Switch
            name="outdoorAreaCloseable"
            label="outdoorAreaCloseable"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row">
          <label htmlFor="pavementType">
            <ResourceText resourceKey="pavementType" />
          </label>
          <Input.RadioGroup name="pavementType" asButtonList>
            {this.props.pavementTypeOptions.map((pavementTypeOption, idx) => (
              <Input.Radio
                value={pavementTypeOption.value}
                key={idx}
                label={pavementTypeOption.displayName}
              />
            ))}
          </Input.RadioGroup>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const facilities = [];

    if (values.outdoorAreaCloseable)
      facilities.push(OutdoorAreaFacility.Closeable);
    if (values.outdoorAreaFenced) facilities.push(OutdoorAreaFacility.Fence);

    const fields: ObjectAssignment | AcquisitionObjectAssignment = {
      ...objectAssignment,
      facilities,
      pavementType: values.pavementType,
      pavedPercentage: values.pavedPercentage,
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface
      }
    };

    this.props.onChange(fields);
  }

  private mapFacilitiesToValues(
    facilities: OutdoorAreaFacility[]
  ): OutdoorArea {
    const facilityOptions = facilities || [];
    const area: OutdoorArea = {
      outdoorAreaCloseable: false,
      outdoorAreaFenced: false
    };

    if (facilityOptions.indexOf(OutdoorAreaFacility.Closeable) !== -1)
      area.outdoorAreaCloseable = true;
    if (facilityOptions.indexOf(OutdoorAreaFacility.Fence) !== -1)
      area.outdoorAreaFenced = true;

    return area;
  }
}
