import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import {
  FC,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import * as CSSModules from "react-css-modules";
import { ValueProp } from "./select";

const styles = require("./style.scss");

type Props = {
  value: ValueProp;
  index: number;
  query: string;
  selectedIndex: number;
  activeIndex: number;
  expanded: boolean;
  displayFn?: (
    value: any,
    query: string,
    active: boolean,
    selected: boolean
  ) => ReactNode;
  onSelect: (value: any) => void;
  onActiveIndexChange: (activeIndex: number) => void;
};

const OptionComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      onSelect,
      value,
      index,
      displayFn,
      query,
      selectedIndex,
      activeIndex,
      onActiveIndexChange,
      expanded,
    }) => {
      const ref = useRef<HTMLDivElement | null>(null);
      const mouseMove = useRef(false);
      const mouseMoveTimeout = useRef(null);

      const selected = useMemo(
        () => index === selectedIndex,
        [index, selectedIndex]
      );

      const active = useMemo(() => index === activeIndex, [index, activeIndex]);

      const body = useMemo(() => {
        switch (true) {
          case !!displayFn: {
            const escapedQuery = escapeRegExp(query);
            return displayFn(value.value, escapedQuery, active, selected);
          }
          default: {
            return (
              <div
                styleName={classNames("option__value", { active, selected })}
                dangerouslySetInnerHTML={{ __html: value.filter }}
              />
            );
          }
        }
      }, [displayFn, value, query, active, selected]);

      const mouseUpCallback = useCallback(() => {
        onSelect(value.value);
      }, [onSelect, value]);

      const mouseMoveCallback = useCallback(() => {
        mouseMove.current = true;
        if (!!mouseMoveTimeout.current) {
          clearTimeout(mouseMoveTimeout.current);
        }
        mouseMoveTimeout.current = setTimeout(() => {
          mouseMove.current = false;
        }, 100);
      }, []);

      const mouseEnterCallback = useCallback(() => {
        if (active) return;
        onActiveIndexChange(index);
      }, [active, index]);

      useEffect(() => {
        if (!active || !!mouseMove.current || !ref.current) return;
        ref.current.scrollIntoView({ block: "nearest" });
      }, [active]);

      useEffect(() => {
        if (!expanded || !selected || !ref.current) return;
        ref.current.scrollIntoView({ block: "center" });
      }, [expanded, selected]);

      return (
        <div
          styleName="option"
          onMouseUp={mouseUpCallback}
          onMouseMove={mouseMoveCallback}
          onMouseEnter={mouseEnterCallback}
          ref={ref}
        >
          {body}
        </div>
      );
    }
  )
);

export default OptionComponent;
