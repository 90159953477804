import {
  FinancialAdministration,
  FinancialAdministrationTaxRate
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FormReturnValue } from "@haywork/modules/form";
import { SettingsFinancialAdministrationTaxRatesContainerProps } from "@haywork/modules/settings/modules/financial-administrations";
import { ConfirmComponent, Hint } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { TaxRateComponent } from "./tax-rate.component";

const styles = require("./tax-rates.component.scss");

export interface SettingsFinancialAdministrationTaxRatesComponentProps {}
interface State {
  financialAdministration: FinancialAdministration;
  saveConfirmVisible: boolean;
  numberOfEmptyTaxRates: number;
}
type Props = SettingsFinancialAdministrationTaxRatesComponentProps &
  SettingsFinancialAdministrationTaxRatesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsFinancialAdministrationTaxRatesComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onAddTaxRateClickHandler = this.onAddTaxRateClickHandler.bind(this);
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onTaxRateRemoveHandler = this.onTaxRateRemoveHandler.bind(this);
    this.onTaxRateChangeHandler = this.onTaxRateChangeHandler.bind(this);
    this.onSaveCloseHandler = this.onSaveCloseHandler.bind(this);
    this.onSaveConfirmHandler = this.onSaveConfirmHandler.bind(this);

    const financialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    this.state = {
      financialAdministration,
      saveConfirmVisible: false,
      numberOfEmptyTaxRates: 0
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    const nextFinancialAdministration = nextProps.financialAdministrations.find(
      (f) => f.id === nextProps.match.params.id
    );
    const prevFinancialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    if (
      nextProps.match.params.id !== this.props.match.params.id ||
      nextFinancialAdministration.taxRates.length !==
        prevFinancialAdministration.taxRates.length
    ) {
      this.setState({
        financialAdministration: nextFinancialAdministration
      });
    }
  }

  public render() {
    if (!this.state.financialAdministration) {
      return null;
    }
    const loading = this.props.financialAdministrationState === REQUEST.PENDING;

    return (
      <div styleName="vat-rates">
        <PageHeader
          title="settingsMenuFinancialAdministrationTaxeRates"
          subTitle={this.state.financialAdministration.name}
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={this.onSaveClickHandler}
                disabled={loading}
              />
              <Button
                label="addNewTaxRate"
                icon={
                  <Icon
                    name="plus"
                    size={18}
                    light
                    color={loading ? Colors.Gray : Colors.White}
                  />
                }
                category="primary"
                onClick={this.onAddTaxRateClickHandler}
                disabled={loading}
              />
            </>
          }
        />

        <div styleName="vat-rates__list-header">
          <div styleName="column">
            <I18n value="taxRatesDescription" />
          </div>
          <div styleName="column">
            <I18n value="taxRatesVat" />
          </div>
          <div styleName="column centered">
            <Hint message="taxRateVisibilityHint" forceInline>
              <I18n value="taxRatesShow" />
              &nbsp;
              <i className="fal fa-question-circle" />
            </Hint>
          </div>
          <div styleName="column">
            <I18n value="taxRatesVatCode" />
          </div>
          <div styleName="column">
            <I18n value="taxRatesLedgerCode" />
          </div>
          <div styleName="column" />
        </div>

        <div styleName="vat-rates__body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          {this.state.financialAdministration.taxRates.map((taxRate, idx) => (
            <ErrorBoundary key={taxRate.id}>
              <TaxRateComponent
                taxRate={taxRate}
                onRemove={this.onTaxRateRemoveHandler}
                onChange={this.onTaxRateChangeHandler}
              />
            </ErrorBoundary>
          ))}
        </div>

        <ConfirmComponent
          visible={this.state.saveConfirmVisible}
          titleResourceKey="financialAdminEmptyTaxRatesConfirmTitle"
          bodyResourceKey="financialAdminEmptyTaxRatesConfirmBody"
          bodyValues={{ count: this.state.numberOfEmptyTaxRates }}
          onClose={this.onSaveCloseHandler}
          onConfirm={this.onSaveConfirmHandler}
        />
      </div>
    );
  }

  private onTaxRateRemoveHandler(id: number) {
    const taxRates = this.state.financialAdministration.taxRates.filter(
      (r) => r.id !== id
    );

    const financialAdministration = {
      ...this.state.financialAdministration,
      taxRates
    };

    this.setState({
      financialAdministration
    });
  }

  private onTaxRateChangeHandler(values: FormReturnValue) {
    const { id } = values;

    const taxRates = this.state.financialAdministration.taxRates.map((r) => {
      if (r.id === id) {
        return {
          ...r,
          ...values
        };
      }
      return r;
    });

    const financialAdministration = {
      ...this.state.financialAdministration,
      taxRates
    };

    this.setState({
      financialAdministration
    });
  }

  private onAddTaxRateClickHandler() {
    const newTaxRate: FinancialAdministrationTaxRate = {
      id: -1,
      isActive: false,
      taxPercentage: 0
    };

    const taxRates = [
      ...this.state.financialAdministration.taxRates,
      newTaxRate
    ];

    const financialAdministration = {
      ...this.state.financialAdministration,
      taxRates
    };

    this.props.updateFinancialAdministration(financialAdministration);
  }

  private onSaveClickHandler() {
    const count = this.state.financialAdministration.taxRates.length;
    const filteredTaxRates = this.state.financialAdministration.taxRates.filter(
      (t) => {
        return (
          !!t.description ||
          t.taxPercentage !== 0 ||
          !!t.exportTaxCode ||
          !!t.exportLedgerAccountCode
        );
      }
    );

    if (count !== filteredTaxRates.length) {
      this.setState({
        saveConfirmVisible: true,
        numberOfEmptyTaxRates: count - filteredTaxRates.length
      });
    } else {
      this.props.updateFinancialAdministration(
        this.state.financialAdministration
      );
    }
  }

  private onSaveCloseHandler() {
    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyTaxRates: 0
    });
  }

  private onSaveConfirmHandler() {
    const taxRates = this.state.financialAdministration.taxRates.filter((t) => {
      return (
        !!t.description ||
        t.taxPercentage !== 0 ||
        !!t.exportTaxCode ||
        !!t.exportLedgerAccountCode
      );
    });
    const financialAdministration = {
      ...this.state.financialAdministration,
      taxRates
    };

    this.props.updateFinancialAdministration(financialAdministration);

    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyTaxRates: 0
    });
  }
}
