import {
  EmployeeRole,
  LinkedEmployee,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { AccountState } from "@haywork/stores";
import head from "lodash-es/head";
import get from "lodash-es/get";

export class BackOffice {
  public static getEmployeeId(state: AccountState): string {
    const { currentRealestateAgency, employee, employees } = state;
    if (
      currentRealestateAgency.role === EmployeeRole.Standard &&
      !!employee?.id
    ) {
      return employee.id;
    } else {
      const firstEmployee = head(employees || []);
      return firstEmployee?.id;
    }
  }

  public static getLinkedEmployee(state: AccountState): LinkedEmployee {
    const { currentRealestateAgency, employee, employees } = state;
    if (currentRealestateAgency.role === EmployeeRole.Standard) {
      return { id: employee.id, displayName: employee.displayName };
    } else {
      const firstEmployee = head(employees);
      return { id: firstEmployee.id, displayName: firstEmployee.displayName };
    }
  }

  public static getEmployee(state: AccountState): RelationSnapShot {
    const { currentRealestateAgency, employee, employees } = state;
    if (currentRealestateAgency.role === EmployeeRole.Standard) {
      const {
        id,
        dateOfBirth,
        displayName,
        passportPhotoBlob,
        letterAvatar,
        linkedOffices,
        dateTimeCreated,
        dateTimeModified,
        isActive,
        linkedCreatedBy,
        linkedModifiedBy,
      } = employee;

      return {
        id,
        avatarUrl: get(passportPhotoBlob, "urlOriginal"),
        dateOfBirth,
        dateTimeCreated,
        dateTimeModified,
        displayName,
        isActive,
        letterAvatar,
        linkedCreatedBy,
        linkedModifiedBy,
        linkedOffices,
        typeOfRelation: RelationType.Employee,
      };
    } else {
      const employee = head(employees);
      return employee;
    }
  }
}
