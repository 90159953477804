import * as React from "react";
import { FC, memo } from "react";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";

type Props = {};

export const EmptyStateComponent: FC<Props> = memo(({}) => {
  return (
    <EmptyState
      icon="folder-open"
      title="matchingPropertiesEmptyStateTitle"
      subTitle="matchingPropertiesEmptyStateBody"
    />
  );
});
