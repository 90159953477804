import { Message } from "@haywork/api/kolibri";
import { MapDispatchToProps, MessageThunk } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  ContactMeModalComponent,
  ContactMeModalComponentProps
} from "./contact-me-modal";

interface StateProps {}
interface DispatchProps {
  getMessage: (id: string) => Promise<Message>;
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ContactMeModalComponentProps,
  AppState
> = (state) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ContactMeModalComponentProps
> = (dispatch) => ({
  getMessage: (id: string) => dispatch(MessageThunk.getMessage(id)),
  navigate: (path: string) => dispatch(push(path))
});

export type ContactMeModalContainerProps = StateProps & DispatchProps;
export const ContactMeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactMeModalComponent);
