import {
  AmenitiesOption,
  BathroomFacilityOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  IsolationTypeOption,
  KitchenTypeOption,
  LivingRoomTypeOption,
  ObjectAssignment,
  OrientationOption,
  StorageRoomTypeOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect } from "react-redux";
import {
  RoomLayoutComponent,
  RoomLayoutComponentProps,
} from "../components/room-layout/room-layout.component";

interface StateProps {
  livingRoomTypes: LivingRoomTypeOption[];
  bathroomFacilities: BathroomFacilityOption[];
  kitchenTypes: KitchenTypeOption[];
  gardenTypeOptions: GardenTypeOption[];
  gardenQualityOptions: GardenQualityOption[];
  orientations: OrientationOption[];
  garageTypeOptions: GarageTypeOption[];
  isolationTypeOptions: IsolationTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  storageRoomTypeOptions: StorageRoomTypeOption[];
  objectAssignment: ObjectAssignment;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, RoomLayoutComponentProps>(
  state: AppState
) => {
  const {
    livingRoomTypes,
    bathroomFacilities,
    kitchenTypes,
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
    garageTypeOptions,
    isolationTypeOptions,
    amenitiesOptions,
    storageRoomTypeOptions,
  } = state.main.mastertable.kolibri;
  const objectAssignment: ObjectAssignment = get(
    state.editable.currentComponentState,
    "objectAssignment"
  );

  return {
    livingRoomTypes,
    bathroomFacilities,
    kitchenTypes,
    gardenTypeOptions,
    gardenQualityOptions,
    orientations,
    garageTypeOptions,
    isolationTypeOptions,
    amenitiesOptions,
    storageRoomTypeOptions,
    objectAssignment,
  };
};

const mapDispatchToProps = <DispatchProps, RoomLayoutComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type RoomLayoutContainerProps = StateProps & DispatchProps;
export const RoomLayoutContainer = connect<
  StateProps,
  DispatchProps,
  RoomLayoutComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RoomLayoutComponent);
