import { LinkedEmployee } from "@haywork/api/kolibri";
import { Hint, HintAlignment } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { intlContext } from "@haywork/app";

const styles = require("./object-timestamps.component.scss");

interface ObjectTimestampsComponentProps {
  linkedCreatedBy?: LinkedEmployee;
  linkedModifiedBy?: LinkedEmployee;
  dateTimeCreated?: Date;
  dateTimeModified?: Date;
  alignLabels?: HintAlignment;
  className?: string;
}
interface State {}
type Props = ObjectTimestampsComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTimestamps extends React.PureComponent<Props, State> {
  public render() {
    const {
      linkedModifiedBy,
      linkedCreatedBy,
      dateTimeModified,
      dateTimeCreated,
    } = this.props;
    if (
      !linkedModifiedBy ||
      !linkedCreatedBy ||
      !dateTimeModified ||
      !dateTimeCreated
    )
      return;

    const timeModified = intlContext.formatTime(dateTimeModified, {
      hour: "numeric",
      minute: "numeric",
    });
    const dateModified = intlContext.formatDate(dateTimeModified, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const timeCreated = intlContext.formatTime(dateTimeCreated, {
      hour: "numeric",
      minute: "numeric",
    });
    const dateCreated = intlContext.formatDate(dateTimeCreated, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const alignment = this.props.alignLabels || HintAlignment.Right;
    const timestampsStyle = classNames(this.props.className);
    const dontShowModified =
      dateTimeModified === dateTimeCreated &&
      linkedModifiedBy.id === linkedCreatedBy.id;

    return (
      <div
        styleName="timestamps"
        className={timestampsStyle}
        data-cy={this.props["data-cy"]}
      >
        {!dontShowModified && linkedModifiedBy && dateModified && (
          <div styleName="hint">
            <Hint
              align={alignment}
              message="lastChanged"
              values={{
                relationName: linkedModifiedBy.displayName,
                dateChanged: dateModified,
                timeChanged: timeModified,
              }}
              data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Hint`}
            >
              <div styleName="meta">
                <i className="fal fa-fw fa-clock" />
              </div>
            </Hint>
          </div>
        )}
        {this.props.linkedCreatedBy && dateCreated && (
          <div styleName="hint">
            <Hint
              align={alignment}
              message="createdOn"
              values={{
                relationName: linkedCreatedBy.displayName,
                dateChanged: dateCreated,
                timeChanged: timeCreated,
              }}
            >
              <div styleName="meta">
                <i className="fal fa-fw fa-pencil" />
              </div>
            </Hint>
          </div>
        )}
      </div>
    );
  }
}
