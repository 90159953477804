import {
  SortOrder,
  RelationOrderByField,
  RelationSnapShot,
  RelationsStatistics
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum ActionType {
  UpdateList = "Relations.List.UpdateList",
  UpdateListItem = "Relations.List.UpdateListItem",
  DeleteListItem = "Relations.List.DeleteListItem",
  SetOrdering = "Relations.List.SetOrdering",
  SetFilters = "Relations.List.SetFilters",
  SetScrollOffset = "Relations.List.SetScrollOffset",
  UpdateItem = "Relations.List.UpdateItem",
  ResetApp = "RESET_APP"
}

type UpdateList = {
  type: ActionType.UpdateList;
  items: RelationSnapShot[];
  totalCount: number;
  startIndex: number;
  statistics: RelationsStatistics;
};
const updateList = (
  startIndex: number,
  items: RelationSnapShot[],
  totalCount: number,
  statistics: RelationsStatistics
) => ({
  type: ActionType.UpdateList,
  items,
  totalCount,
  startIndex,
  statistics
});

type UpdateListItem = {
  type: ActionType.UpdateListItem;
  snapshot: RelationSnapShot;
};
const updateListItem = (snapshot: RelationSnapShot) => ({
  type: ActionType.UpdateListItem,
  snapshot
});

type DeleteListItem = {
  type: ActionType.DeleteListItem;
  id: string;
};
const deleteListItem = (id: string) => ({
  type: ActionType.DeleteListItem,
  id
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: RelationOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: RelationOrderByField
) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: RelationSnapShot;
};

const updateItem = (snapshot: RelationSnapShot) => ({
  type: ActionType.UpdateItem,
  snapshot
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetFilters
  | UpdateListItem
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | DeleteListItem
  | UpdateItem
  | ResetApp;
export const actions = {
  updateList,
  setFilters,
  updateListItem,
  deleteListItem,
  setOrdering,
  setScrollOffset,
  updateItem
};
