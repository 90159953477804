import {
  DocumentTemplate,
  FormElement,
  FormElementType,
} from "@haywork/api/kolibri";
import { ScrollerWrapperContainer } from "@haywork/components/ui/scroller-wrapper/scroller-wrapper.container";
import { DynamicDocumentsFormContainerProps } from "@haywork/modules/dynamic-documents";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  DateComponent,
  DropDownComponent,
  FlattenedFormElement,
  HeadingComponent,
  NumberComponent,
  TextareaComponent,
} from "./elements";

const getValueOrNull = FormControlUtil.returnObjectPathOrNull;
const styles = require("./form.component.scss");

export interface DynamicDocumentsFormComponentProps {
  template: DocumentTemplate;
}
interface DynamicDocumentsFormComponentState {
  formElements: FlattenedFormElement[];
  userFieldName: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsFormComponent extends React.Component<
  DynamicDocumentsFormComponentProps & DynamicDocumentsFormContainerProps,
  DynamicDocumentsFormComponentState
> {
  constructor(props) {
    super(props);
    this.state = {
      formElements: this.flattenFormElements(this.props.formElements),
      userFieldName: this.props.userFieldName,
    };

    this.renderFormElement = this.renderFormElement.bind(this);
    this.onElementChange = this.onElementChange.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: DynamicDocumentsFormComponentProps &
      DynamicDocumentsFormContainerProps
  ) {
    if (nextProps && nextProps.userFieldName !== this.props.userFieldName) {
      this.setState({ userFieldName: nextProps.userFieldName });
    }

    if (
      nextProps.visibleFormElements &&
      nextProps.visibleFormElements.length > 0
    ) {
      this.setState({
        formElements: this.flattenFormElements(
          nextProps.formElements,
          nextProps.visibleFormElements
        ),
      });
    }
  }

  public render() {
    const { formElements } = this.state;
    return (
      <div styleName="form">
        <ScrollerWrapperContainer scrollKey={this.props.template.id}>
          {formElements.map((formElement, idx) => (
            <ErrorBoundary key={idx}>
              {this.renderFormElement(formElement)}
            </ErrorBoundary>
          ))}
        </ScrollerWrapperContainer>
      </div>
    );
  }

  public componentWillUnmount() {
    this.props.focusOnAnchor(null);
  }

  private onElementChange(formElement: FormElement) {
    const { formElementValues: formElements } = this.props.session;
    let hasReference = false;
    const formElementValues = formElements.map((f) => {
      if (f.name === formElement.name) {
        hasReference = true;
        return {
          ...f,
          ...formElement,
        };
      }
      return f;
    });

    if (!hasReference) {
      formElementValues.push(formElement);
    }

    const session = {
      ...this.props.session,
      formElementValues,
    };

    this.props.updateDocumentSession(session);
  }

  private renderFormElement(formElement: FlattenedFormElement) {
    if (!formElement) return null;
    const { type, name } = formElement;
    const formElementValues = this.props.session.formElementValues || [];
    const formElementValue = formElementValues.find((f) => f.name === name);
    const isFocussed = name === this.state.userFieldName;

    switch (type) {
      case FormElementType.Text: {
        const value = getValueOrNull(formElementValue, "textInfo.value");
        return (
          <TextareaComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            focus={isFocussed}
          />
        );
      }
      case FormElementType.Date: {
        const value = getValueOrNull(formElementValue, "dateInfo.value");
        return (
          <DateComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            focus={isFocussed}
          />
        );
      }
      case FormElementType.Decimal: {
        const value = getValueOrNull(formElementValue, "decimalInfo.value");
        return (
          <NumberComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            pretty
            focus={isFocussed}
          />
        );
      }
      case FormElementType.Integer: {
        const value = getValueOrNull(formElementValue, "integerInfo.value");
        return (
          <NumberComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            round
            focus={isFocussed}
          />
        );
      }
      case FormElementType.DropDown: {
        const index: number =
          get(formElementValue, "dropDownInfo.selectedIndex") !== undefined
            ? get(formElementValue, "dropDownInfo.selectedIndex")
            : -1;
        const value = has(formElement, "values")
          ? formElement.values[index]
          : "";
        return (
          <DropDownComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            focus={isFocussed}
          />
        );
      }
      case FormElementType.Heading: {
        return <HeadingComponent formElement={formElement} />;
      }
      default: {
        const value = getValueOrNull(formElementValue, "regularTextInfo.value");
        return (
          <TextareaComponent
            formElement={formElement}
            value={value}
            onChange={this.onElementChange}
            onFocus={this.props.focusOnAnchor}
            focus={isFocussed}
          />
        );
      }
    }
  }

  private flattenFormElements(
    formElements: FormElement[],
    visibleFormElements: string[] = [],
    state: FlattenedFormElement[] = [],
    depth: number = 0
  ): FlattenedFormElement[] {
    if (!formElements) return state;
    let innerState = [...state];

    formElements.map((formElement) => {
      const children = formElement.formElements;

      let disabled: boolean = false;
      if (
        !/_dontHide/gi.test(formElement.name) &&
        visibleFormElements &&
        visibleFormElements.length > 0 &&
        formElement &&
        formElement.name &&
        visibleFormElements.indexOf(formElement.name) === -1
      ) {
        disabled = true;
      }

      let child: FlattenedFormElement = {
        type: formElement.type,
        depth,
        name: formElement.name,
        preText: formElement.preText,
        postText: formElement.postText,
        disabled,
      };

      switch (formElement.type) {
        case FormElementType.Integer: {
          const { value, format } = formElement.integerInfo;
          child = {
            ...child,
            value,
            format,
          };
          break;
        }
        case FormElementType.Date: {
          const { value } = formElement.dateInfo;
          child = {
            ...child,
            value,
          };
          break;
        }
        case FormElementType.Decimal: {
          const { value, format } = formElement.decimalInfo;
          child = {
            ...child,
            value,
            format,
          };
          break;
        }
        case FormElementType.DropDown: {
          const { dropDownOptions: values } = formElement.dropDownInfo;
          child = {
            ...child,
            values,
          };
          break;
        }
        case FormElementType.Heading: {
          const { title: heading } = formElement.headingInfo;
          child = {
            ...child,
            heading,
          };
          break;
        }
        case FormElementType.Text: {
          const {
            value,
            maxLength: maximumLength,
            phraseCategoryId,
          } = formElement.textInfo;
          child = {
            ...child,
            value,
            maximumLength,
            phraseCategoryId,
          };
          break;
        }
        default:
          break;
      }

      innerState.push(child);
      if (!!children && children.length > 0) {
        innerState = this.flattenFormElements(
          children,
          visibleFormElements,
          innerState,
          depth + 1
        );
      }
    });

    return innerState;
  }
}
