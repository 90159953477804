import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { OFFICESROUTES, REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { OfficeListItemComponent } from "@haywork/modules/settings";
import { InfiniteScroll, ListLoader } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { OfficeOverviewContainerProps } from "../../containers/index";

export interface OfficeOverviewComponentProps {}
interface State {}
type Props = OfficeOverviewComponentProps & OfficeOverviewContainerProps;

const styles = require("./office-overview.component.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class OfficeOverviewComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onOfficeClickHandler = this.onOfficeClickHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getOffices(true);
  }

  public componentWillUnmount() {
    this.props.clearOffices();
  }

  public render() {
    const loading = this.props.officeInitialOverviewState === REQUEST.PENDING;

    return (
      <div styleName="general">
        <PageHeader
          title="settingsMenuOffices"
          subTitle="settingsMenuMyCompany"
          actions={
            <Button
              label="createOffice"
              category="primary"
              icon={
                <Icon
                  name="plus"
                  size={18}
                  light
                  color={loading ? Colors.Gray : Colors.White}
                />
              }
              onClick={this.props.createOffice}
              disabled={loading}
            />
          }
        />

        <div styleName="relation-overview">
          {/*amount of offices*/}
          <div styleName="summary">
            <span>
              <I18n
                value="officeAmount"
                asHtml
                values={{ officeAmount: this.props.totalOffices }}
              />
            </span>
          </div>

          {/* list of offices*/}
          <div styleName="list">
            {loading && <Ui.Loaders.Fullscreen mask />}

            <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
              {this.props.offices.map((office, idx) => (
                <ErrorBoundary key={idx}>
                  <OfficeListItemComponent
                    office={office}
                    zebra={idx % 2 === 0}
                    onOfficeClickHandler={this.onOfficeClickHandler}
                    onOfficeArchive={this.props.archiveOffice}
                    onOfficeUnarchive={this.props.unArchiveOffice}
                    removeOfficeFromList={this.props.removeOfficeFromList}
                  />
                </ErrorBoundary>
              ))}
              {this.props.canLoadMore && <ListLoader />}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }

  public onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.officeOverviewState === REQUEST.PENDING
    )
      return;
    this.props.getOffices();
  }

  public onOfficeClickHandler(officeID: string) {
    let url: string;
    url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id: officeID });
    this.props.goToOfficeDetail(url);
  }
}
