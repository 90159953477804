import { EmailDraft } from ".";

export enum ActionType {
  AppendDrafts = "EmailV2.Drafts.Append",
  UpdateDraft = "EmailV2.Drafts.UpdateDraft",
  DeleteDraft = "EmailV2.Drafts.DeleteDraft",
  Clear = "EmailV2.Main.Clear",
  ResetApp = "RESET_APP",
}

type AppendDrafts = {
  type: ActionType.AppendDrafts;
  drafts: EmailDraft[];
};

const appendDrafts = (drafts: EmailDraft[]) => ({
  type: ActionType.AppendDrafts,
  drafts,
});

type UpdateDraft = {
  type: ActionType.UpdateDraft;
  draft: EmailDraft;
};

const updateDraft = (draft: EmailDraft) => ({
  type: ActionType.UpdateDraft,
  draft,
});

type DeleteDraft = {
  type: ActionType.DeleteDraft;
  id: string;
};

const deleteDraft = (id: string) => ({
  type: ActionType.DeleteDraft,
  id,
});

type Clear = {
  type: ActionType.Clear;
};

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | AppendDrafts
  | UpdateDraft
  | DeleteDraft
  | Clear
  | ResetApp;
export const actions = {
  appendDrafts,
  updateDraft,
  deleteDraft,
};
