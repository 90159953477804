import { Bid, BidType, LinkedRelation } from "@haywork/api/kolibri";
import { Modal, ModalHeader } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { BidAction } from "../bid";
import { BidModalContainerProps } from "./bid-modal.container";
import Accept from "./components/accept";
import Bids from "./components/bids";
import Offer from "./components/offer";
import Reject from "./components/reject";

export type BidModalView = "bids" | "create" | "edit" | "accept" | "reject";
export type BidModalComponentProps = {
  visible: boolean;
  bid: Bid | null;
  originalBid?: Bid;
  view: BidModalView;
  linkedVendors?: LinkedRelation[];
  assignmentId: string;
  onClose: () => void;
  onUpdated: () => void;
  onBidAccepted?: () => void;
};
type Props = BidModalComponentProps & BidModalContainerProps;

export const BidModalComponent: FC<Props> = memo(
  ({
    visible,
    onClose,
    bid: refBid,
    originalBid: originalBidRef,
    saveBid,
    acceptBid,
    rejectBid,
    onUpdated,
    view: refView,
    linkedVendors,
    createCounterOffer,
    readBid,
    assignmentId,
    navigate,
    onBidAccepted
  }) => {
    const [bid, setBid] = useState(refBid);
    const [originalBid, setOriginalBid] = useState(originalBidRef);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState<BidModalView>(refView);
    const [actionFromModal, setActionFromModal] = useState(false);

    useEffect(() => {
      setView(refView);
    }, [refView]);

    useEffect(() => {
      setBid(refBid);
    }, [refBid]);

    useEffect(() => {
      setOriginalBid(originalBidRef);
    }, [originalBidRef]);

    const title = useMemo(() => {
      switch (true) {
        case view === "create" && !originalBid: {
          return "bids.modal.header.addOffer";
        }
        case view === "create" && !!originalBid: {
          return "bids.modal.header.addCounterOffer";
        }
        default: {
          return `bids.modal.header.${view}.acquisition`;
        }
      }
    }, [originalBid, view]);

    const relationsToNotify = useMemo(() => {
      if (!bid || view === "edit") return null;
      const { type } = bid;

      switch (true) {
        case type === BidType.Outgoing && !!originalBid: {
          const { linkedRelations } = originalBid;
          return linkedRelations || [];
        }
        case view === "create" && type === BidType.Outgoing: {
          return linkedVendors || [];
        }
        case view === "reject" && type === BidType.Outgoing: {
          return linkedVendors || [];
        }
        case ["accept", "reject"].includes(view) && type === BidType.Incoming: {
          const { linkedRelations } = bid;
          return linkedRelations || [];
        }
        default: {
          return null;
        }
      }
    }, [bid, originalBid, view, linkedVendors]);

    const onSaveBid = useCallback(async () => {
      try {
        setLoading(true);
        await saveBid(bid);
        if (actionFromModal) {
          setView("bids");
        } else {
          onUpdated();
        }
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal]);

    const onAcceptBid = useCallback(async () => {
      try {
        setLoading(true);
        await saveBid(bid);
        await acceptBid(bid.id);
        if (actionFromModal) {
          setView("bids");
        } else {
          onUpdated();
        }
        if (onBidAccepted) {
          onBidAccepted();
        }
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal]);

    const onRejectBid = useCallback(async () => {
      try {
        setLoading(true);
        await rejectBid(bid.id);
        if (actionFromModal) {
          setView("bids");
        } else {
          onUpdated();
        }
      } finally {
        setLoading(false);
      }
    }, [bid, setLoading, setActionFromModal]);

    const onCloseHandler = useCallback(() => {
      if (actionFromModal) {
        setActionFromModal(false);
        onUpdated();
      } else {
        onClose();
      }
    }, [actionFromModal, setActionFromModal]);

    const onActionHandler = useCallback(
      async (id: string, action: BidAction) => {
        const bid = await readBid(id);
        if (!bid) return;
        setActionFromModal(true);

        switch (action) {
          case BidAction.Accept: {
            setBid(bid);
            setView("accept");
            break;
          }
          case BidAction.CounterOffer: {
            const { type } = bid;
            const relations =
              type === BidType.Outgoing ? linkedVendors || [] : undefined;
            const counterOffer = await createCounterOffer(bid, relations);
            if (!counterOffer) return;
            setBid(counterOffer);
            setOriginalBid(bid);
            setView("create");
            break;
          }
          case BidAction.Edit: {
            setBid(bid);
            setView("edit");
            break;
          }
          case BidAction.Reject: {
            setBid(bid);
            setView("reject");
            break;
          }
          default:
            return;
        }
      },
      []
    );

    const navigateTo = (path: string) => {
      visible = false;
      navigate(path);
    };

    const renderedView = useMemo(() => {
      switch (view) {
        case "create":
        case "edit": {
          return (
            <Offer
              bid={bid}
              originalBid={originalBid}
              loading={loading}
              relationsToNotify={relationsToNotify}
              onUpdate={setBid}
              onSave={onSaveBid}
            />
          );
        }
        case "accept": {
          return (
            <Accept
              bid={bid}
              loading={loading}
              relationsToNotify={relationsToNotify}
              onUpdate={setBid}
              onAccept={onAcceptBid}
            />
          );
        }
        case "reject": {
          return (
            <Reject
              loading={loading}
              type={bid && bid.type}
              relationsToNotify={relationsToNotify}
              onReject={onRejectBid}
            />
          );
        }
        case "bids": {
          return (
            <Bids
              assignmentId={assignmentId}
              onClose={onCloseHandler}
              onAction={onActionHandler}
              onNavigate={(path) => navigateTo(path)}
            />
          );
        }
        default: {
          return null;
        }
      }
    }, [view, bid, originalBid, loading, setBid, onSaveBid, onAcceptBid]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title={title} close />
        {renderedView}
      </Modal>
    );
  }
);
