import { CountryOption } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  AddressExtendedComponent,
  AddressExtendedComponentProps,
} from "./address-extended";

type StateProps = {
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AddressExtendedComponentProps,
  AppState
> = (state) => {
  const { culture, mastertable } = state.main;
  const { countries } = mastertable.kolibri;

  return {
    countries,
    countryIso2: "NL",
    culture,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AddressExtendedComponentProps
> = (dispatch) => ({});

export type AddressExtendedContainerProps = StateProps & DispatchProps;
export const AddressExtendedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressExtendedComponent);
