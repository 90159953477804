import { MapDispatchToProps } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { ManageFiltersComponent, ManageFiltersProps } from "./manage-filters";
import { savedFilters } from "../../screens/list/selectors";

type StateProps = {
  savedFilters: MlsFilter[];
};
type DispatchProps = {
  removeFilter: (filter: MlsFilter) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ManageFiltersProps,
  AppState
> = (state) => {
  return {
    savedFilters: savedFilters(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ManageFiltersProps
> = (dispatch) => ({
  removeFilter: (filter: MlsFilter) =>
    dispatch(MLSThunk.List.removeFilter(filter)),
});

export type ManageFiltersContainerProps = StateProps & DispatchProps;
export const ManageFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageFiltersComponent);
