import { ObjectTypesEditMarketingPhotosContainerProps } from "@haywork/modules/object-types";
import { PhotosComponent } from "@haywork/modules/shared";

import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SortEnd } from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
const styles = require("./marketing-photos.component.scss");

export interface ObjectTypesEditMarketingPhotosComponentProps {}
interface State {}
type Props = ObjectTypesEditMarketingPhotosComponentProps &
  ObjectTypesEditMarketingPhotosContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditMarketingPhotosComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.uploadCompleteHandler = this.uploadCompleteHandler.bind(this);
    this.filesDeletedHandler = this.filesDeletedHandler.bind(this);
    this.sortedHandler = this.sortedHandler.bind(this);
    this.filesEditedHandler = this.filesEditedHandler.bind(this);
  }

  public render() {
    let { photos } = this.props.currentType;
    photos = photos || [];

    return (
      <div>
        <PhotosComponent
          photos={photos}
          path={this.props.path}
          onUploadCompleteHandler={this.uploadCompleteHandler}
          fileDeleted={this.filesDeletedHandler}
          sorted={this.sortedHandler}
          fileEdited={this.filesEditedHandler}
          showMainPhotoLabel={true}
        />
      </div>
    );
  }

  private uploadCompleteHandler(files: any[]) {
    const { currentType } = this.props;
    const photoArray = currentType.photos || [];
    const photos = files.filter((f) => !!f);
    const updatedCurrentType = {
      ...currentType,
      photos: [...photoArray, ...photos],
    };

    this.props.updateCurrentType(updatedCurrentType, this.props.path);
  }

  private filesDeletedHandler(idx: number) {
    const { currentType } = this.props;
    const photos = currentType.photos.filter((p, k) => k !== idx);
    const updatedCurrentType = {
      ...currentType,
      photos,
    };

    this.props.updateCurrentType(updatedCurrentType, this.props.path);
  }

  private sortedHandler(sort: SortEnd) {
    const { currentType } = this.props;
    const photos = arrayMoveImmutable(
      this.props.currentType.photos,
      sort.oldIndex,
      sort.newIndex
    );
    const updatedCurrentType = {
      ...currentType,
      photos,
    };

    this.props.updateCurrentType(updatedCurrentType, this.props.path);
  }

  private filesEditedHandler(photos: any[]) {
    const { currentType } = this.props;

    const updatedCurrentType = {
      ...currentType,
      photos,
    };

    this.props.updateCurrentType(updatedCurrentType, this.props.path);
    this.setState({ showFileEditModal: false });
  }
}
