import * as React from "react";
import { FC, memo, useState, useMemo, useCallback, useEffect } from "react";
import { Modal, ModalHeader } from "@haywork/modules/modal";
import {
  RelationSnapShot,
  PhoneNumber,
  PhoneNumberType,
  EmailAddress,
  LinkedRelation,
  RelationType,
  EmailAddressType
} from "@haywork/api/kolibri";
import { GenderType } from "@haywork/api/event-center";
import Duplicates from "./duplicates";
import UpdateData from "./update-data";
import Other from "./other";
import { DuplicateContactsModalContainerProps } from "./duplicate-contacts-modal.container";

export type DuplicateContactsModalComponentProps = {
  visible: boolean;
  view: "duplicates" | "other" | "update-data" | null;
  relations: RelationSnapShot[];
  relationData: {
    name?: string;
    email?: string;
    telephone?: string;
    mobilePhone?: string;
    gender?: GenderType;
  };
  linkedMessageId?: string;
  onClose: () => void;
  onCreateNewRelation: (
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType
  ) => void;
};
type Props = DuplicateContactsModalComponentProps &
  DuplicateContactsModalContainerProps;

export const DuplicateContactsModalComponent: FC<Props> = memo(
  ({
    visible,
    view: refView,
    relations,
    onClose,
    relationData,
    onCreateNewRelation,
    linkedMessageId,
    updateMessageWithLinkedRelation,
    enrichContactCompany,
    enrichContactPerson
  }) => {
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState<typeof refView>(refView);
    const [phones, setPhones] = useState<PhoneNumber[]>([]);
    const [emails, setEmails] = useState<EmailAddress[]>([]);
    const [
      selectedRelation,
      setSelectedRelation
    ] = useState<RelationSnapShot | null>(null);

    const createNewRelation = useCallback(() => {
      const { name, email, telephone, mobilePhone, gender } = relationData;
      onCreateNewRelation(name, email, telephone, mobilePhone, gender);
    }, [relationData, onCreateNewRelation]);

    const updateMessage = useCallback(
      async (relation?: RelationSnapShot) => {
        if (!linkedMessageId || (!relation && !selectedRelation)) return;
        setLoading(true);

        const linkedRelation: LinkedRelation = {
          id: !!relation ? relation.id : selectedRelation.id,
          typeOfRelation: !!relation
            ? relation.typeOfRelation
            : selectedRelation.typeOfRelation
        };

        try {
          await updateMessageWithLinkedRelation(
            linkedMessageId,
            linkedRelation
          );
        } finally {
          setLoading(false);
          onClose();
        }
      },
      [linkedMessageId, setLoading, selectedRelation, onClose]
    );

    const shouldShowUpdateDataOrUpdateMessage = useCallback(
      (relation: RelationSnapShot) => {
        const { phoneNumber, mobileNumber, email } = relation;
        const { telephone, mobilePhone, email: emailAddress } = relationData;

        const phones: PhoneNumber[] = [];
        const emails: EmailAddress[] = [];
        if (!phoneNumber && !!telephone) {
          phones.push({ number: telephone, type: PhoneNumberType.Home });
        }

        if (!mobileNumber && !!mobilePhone) {
          phones.push({ number: mobilePhone, type: PhoneNumberType.Mobile });
        }

        if (!email && !!emailAddress) {
          emails.push({ address: emailAddress, type: EmailAddressType.Home });
        }

        if (!phones.length && !emails.length) {
          updateMessage(relation);
        } else {
          setPhones(phones);
          setEmails(emails);
          setView("update-data");
        }
      },
      [relationData, setPhones, setEmails, setView, updateMessage]
    );

    const onSelectRelation = useCallback(
      (relation: RelationSnapShot) => {
        setSelectedRelation(relation);
        shouldShowUpdateDataOrUpdateMessage(relation);
      },
      [setSelectedRelation, shouldShowUpdateDataOrUpdateMessage]
    );

    const enrichRelation = useCallback(
      async (phones: PhoneNumber[], emails: EmailAddress[]) => {
        if (!selectedRelation) {
          return updateMessage();
        }

        switch (selectedRelation.typeOfRelation) {
          case RelationType.ContactCompany: {
            try {
              setLoading(true);
              await enrichContactCompany(selectedRelation.id, phones, emails);
            } finally {
              updateMessage();
            }
            return;
          }
          case RelationType.ContactPerson: {
            try {
              setLoading(true);
              await enrichContactPerson(selectedRelation.id, phones, emails);
            } finally {
              updateMessage();
            }
            return;
          }
          default: {
            return updateMessage();
          }
        }
      },
      [
        selectedRelation,
        updateMessage,
        setLoading,
        enrichContactPerson,
        enrichContactCompany
      ]
    );

    const body = useMemo(() => {
      switch (view) {
        case "duplicates": {
          return (
            <Duplicates
              relations={relations}
              onCreateRelation={createNewRelation}
              onSelectRelation={onSelectRelation}
              loading={loading}
            />
          );
        }
        case "other": {
          return (
            <Other onSelectRelation={onSelectRelation} loading={loading} />
          );
        }
        case "update-data": {
          return (
            <UpdateData
              phones={phones}
              emails={emails}
              loading={loading}
              onCancel={updateMessage}
              onEnrichRelation={enrichRelation}
            />
          );
        }
        default: {
          return null;
        }
      }
    }, [
      view,
      relations,
      createNewRelation,
      onSelectRelation,
      phones,
      emails,
      loading,
      updateMessage,
      enrichRelation
    ]);

    const resetState = useCallback(() => {
      setLoading(false);
      setView(null);
      setPhones([]);
      setEmails([]);
      setSelectedRelation(null);
    }, [setLoading, setView, setPhones, setEmails, setSelectedRelation]);

    const onCloseHandler = useCallback(() => {
      if (loading) return;
      onClose();
    }, [onClose, loading]);

    useEffect(() => {
      setView(refView);
    }, [refView, setView]);

    useEffect(() => {
      if (!visible) resetState();
    }, [visible, resetState]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title={`duplicateContactModal.title.${view}`} close />
        {body}
      </Modal>
    );
  }
);
