import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import {
  DynamicDocumentsThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  DynamicDocumentComponent,
  DynamicDocumentComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState, DynamicDocumentsPreviewActions } from "@haywork/stores";
import { DynamicDocumentsSingleState } from "@haywork/stores/dynamic-documents/single";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  session: DocumentSession;
  template: DocumentTemplate;
  previewStatus: string;
  previewRefreshStatus: string;
}
interface DispatchProps {
  getPreview: () => void;
  focusOnUserField: (name: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentComponentProps,
  AppState
> = (state) => {
  const componentState: DynamicDocumentsSingleState =
    state.editable.currentComponentState;
  const { session, template } = componentState;
  const {
    previewStatus,
    previewRefreshStatus
  } = state.dynamicDocuments.preview;

  return {
    session,
    template,
    previewStatus,
    previewRefreshStatus
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentComponentProps
> = (dispatch) => ({
  getPreview: () => dispatch(DynamicDocumentsThunks.getHtmlPreview()),
  focusOnUserField: (name: string) =>
    dispatch(DynamicDocumentsPreviewActions.focusOnUserField({ name }))
});

export type DynamicDocumentContainerProps = StateProps & DispatchProps;
export const DynamicDocumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentComponent);
