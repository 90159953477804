import * as React from "react";
import { useState, memo, useCallback, useEffect, useMemo, useRef } from "react";
import * as CSSModules from "react-css-modules";
import { Input } from "@haywork/modules/form";
import { useIntl } from "react-intl";
import { ResourceText } from "@haywork/modules/shared";
import {
  RentCondition,
  CommissionScale,
  CommissionPaidBy,
  CommissionType,
  RentOffer,
  SaleOffer,
  Size,
} from "@haywork/api/kolibri";
import classNames from "classnames";
import last from "lodash-es/last";
import isNull from "lodash-es/isNull";

const styles = require("./style.scss");

enum IntCommissionType {
  Fixed = "Fixed",
  FixedAndScaled = "FixedAndScaled",
}
export enum CommissionFor {
  Sale = "Sale",
  Rent = "Rent",
}

export interface CommissionResponse {
  commissionContactGross: number;
  commissionContactPercent: number;
  commissionContactMonthsRent?: number;
  commissionOwnerGross: number;
  commissionOwnerPercent: number;
  commissionOwnerMonthsRent?: number;
  commissionScales: CommissionScale[];
  commissionType: CommissionType;
  commissionPaidBy: CommissionPaidBy;
}
interface AssignmentCommissionComponentProps {
  commissionFor: CommissionFor;
  offer: RentOffer | SaleOffer;
  isRentForConsumer?: boolean;
  surface?: Size;
  onChange: (response: CommissionResponse) => void;
}
type Props = AssignmentCommissionComponentProps;

export const AssignmentCommissionComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ commissionFor, offer, isRentForConsumer, onChange, surface }: Props) => {
      const intl = useIntl();
      const initial = useRef(false);

      const float = (value: number | string | null | undefined) => {
        return parseFloat((value || 0).toString());
      };

      const [commissionType, setCommissionType] = useState(
        !!offer.commissionScales?.length
          ? IntCommissionType.FixedAndScaled
          : IntCommissionType.Fixed
      );
      const [fixedCommission, setFixedCommission] = useState(0);
      const [percentualCommission, setPercentualCommission] = useState(0);
      const [commissionOfMonths, setCommissionOfMonths] = useState(0);
      const [scaledPercentage, setScaledPercentage] = useState(0);
      const [amountVendor, setAmountVendor] = useState(
        float(offer.commissionOwnerGross)
      );
      const [amountCustomer, setAmountCustomer] = useState(
        float(offer.commissionContactGross)
      );
      const [percentVendor, setPercentVendor] = useState(
        float(offer.commissionOwnerPercent)
      );
      const [percentCustomer, setPercentCustomer] = useState(
        float(offer.commissionContactPercent)
      );
      const [amountOfMonthsVendor, setAmountOfMonthsVendor] = useState(
        float(
          commissionFor === CommissionFor.Rent
            ? (offer as RentOffer).commissionOwnerMonthsRent
            : undefined
        )
      );
      const [amountOfMonthsCustomer, setAmountOfMonthsCustomer] = useState(
        float(
          commissionFor === CommissionFor.Rent
            ? (offer as RentOffer).commissionContactMonthsRent
            : undefined
        )
      );
      const [scales, setScales] = useState(offer.commissionScales || []);
      const [canAddScale, setCanAddScale] = useState(false);

      const price = useMemo(() => {
        let price = 0;

        if (commissionFor === CommissionFor.Rent) {
          const { rentPricePerUnit, rentPrice } = offer as RentOffer;

          price =
            !!surface?.area && !!rentPricePerUnit
              ? rentPricePerUnit * surface.area
              : rentPrice;
        } else {
          const { purchasePrice, salePrice, pricePerUnit } = offer as SaleOffer;
          price =
            !!surface?.area && !!pricePerUnit
              ? pricePerUnit * surface.area
              : purchasePrice || salePrice || price;
        }

        return price;
      }, [offer, commissionFor, surface]);

      const rentCondition = useMemo(
        () =>
          commissionFor === CommissionFor.Rent
            ? (offer as RentOffer).rentCondition
            : undefined,
        [commissionFor]
      );

      useEffect(() => {
        setFixedCommission(amountVendor + amountCustomer);
        const filteredScales = scales.filter(
          (scale) => !!scale.transactionPriceMax && !!scale.commissionPercentage
        );

        switch (true) {
          case commissionFor === CommissionFor.Sale &&
            commissionType === IntCommissionType.Fixed: {
            setPercentualCommission(
              !price ? 0 : price * ((percentVendor + percentCustomer) / 100)
            );
            break;
          }
          case commissionFor === CommissionFor.Sale &&
            commissionType === IntCommissionType.FixedAndScaled &&
            !filteredScales.length: {
            setPercentualCommission(0);
            break;
          }
          case commissionFor === CommissionFor.Sale &&
            commissionType === IntCommissionType.FixedAndScaled &&
            !!filteredScales.length: {
            const { commissionPercentage } = last(filteredScales);
            const percentage = scales.reduce((state, scale) => {
              const {
                transactionPriceMin,
                transactionPriceMax,
                commissionPercentage,
              } = scale;

              switch (true) {
                case !transactionPriceMin ||
                  !transactionPriceMax ||
                  !commissionPercentage:
                default: {
                  return state;
                }
                case price >= transactionPriceMin &&
                  price < transactionPriceMax: {
                  return commissionPercentage;
                }
              }
            }, commissionPercentage || 0);

            setScaledPercentage(percentage);
            setPercentualCommission(!price ? 0 : price * (percentage / 100));
            break;
          }
          case commissionFor === CommissionFor.Rent: {
            const condition =
              !!surface?.area && !!(offer as RentOffer).rentPricePerUnit
                ? RentCondition.PricePerYear
                : rentCondition || RentCondition.PricePerMonth;
            let priceToCalc = price;

            switch (condition) {
              case RentCondition.PricePerDay: {
                priceToCalc = price * 365;
                break;
              }
              case RentCondition.PricePerHalfYear: {
                priceToCalc = price * 2;
                break;
              }
              case RentCondition.PricePerMonth: {
                priceToCalc = price * 12;
                break;
              }
              case RentCondition.PricePerQuarter: {
                priceToCalc = price * 4;
                break;
              }
              case RentCondition.PricePerWeek: {
                priceToCalc = price * 52;
                break;
              }
              default:
                break;
            }

            setPercentualCommission(
              !priceToCalc
                ? 0
                : priceToCalc * ((percentVendor + percentCustomer) / 100)
            );
            setCommissionOfMonths(
              !priceToCalc
                ? 0
                : (amountOfMonthsVendor + amountOfMonthsCustomer) *
                    (priceToCalc / 12)
            );
            break;
          }
          default: {
            break;
          }
        }
      }, [
        setFixedCommission,
        amountVendor,
        amountCustomer,
        scales,
        commissionFor,
        setPercentualCommission,
        price,
        percentVendor,
        percentCustomer,
        setScaledPercentage,
        rentCondition,
        amountOfMonthsVendor,
        amountOfMonthsCustomer,
        setCommissionOfMonths,
        surface,
        offer,
      ]);

      const onChangeCallback = useCallback(
        (values: {
          amountCustomer?: number;
          percentCustomer?: number;
          amountVendor?: number;
          percentVendor?: number;
          scales?: CommissionScale[];
          amountOfMonthsCustomer?: number;
          amountOfMonthsVendor?: number;
        }) => {
          const commissionScales = (values.scales || scales).filter(
            (scale) =>
              !!scale.transactionPriceMax && !!scale.commissionPercentage
          );

          let response: CommissionResponse = {
            commissionContactGross:
              values.amountCustomer !== undefined
                ? values.amountCustomer
                : amountCustomer,
            commissionContactPercent:
              values.percentCustomer !== undefined
                ? values.percentCustomer
                : percentCustomer,
            commissionOwnerGross:
              values.amountVendor !== undefined
                ? values.amountVendor
                : amountVendor,
            commissionOwnerPercent:
              values.percentVendor !== undefined
                ? values.percentVendor
                : percentVendor,
            commissionScales,
            commissionType: CommissionType.FixedAndPercentage,
            commissionPaidBy: CommissionPaidBy.OwnerAndCustomer,
          };

          if (commissionFor === CommissionFor.Rent) {
            response = {
              ...response,
              commissionContactMonthsRent:
                values.amountOfMonthsCustomer !== undefined
                  ? values.amountOfMonthsCustomer
                  : amountOfMonthsCustomer,
              commissionOwnerMonthsRent:
                values.amountOfMonthsVendor !== undefined
                  ? values.amountOfMonthsVendor
                  : amountOfMonthsVendor,
            };
          }

          onChange(response);
        },
        [
          scales,
          amountCustomer,
          percentCustomer,
          amountVendor,
          percentVendor,
          commissionFor,
          amountOfMonthsCustomer,
          amountOfMonthsVendor,
        ]
      );

      const canAddScaleCallback = useCallback(
        (scales: CommissionScale[]) => {
          if (!scales || !scales.length) {
            setCanAddScale(false);
            return;
          }
          const { transactionPriceMax, commissionPercentage } = last(scales);
          setCanAddScale(!!transactionPriceMax && !!commissionPercentage);
        },
        [setCanAddScale]
      );

      useEffect(() => {
        if (!initial.current) {
          initial.current = true;
          canAddScaleCallback(scales);
        }
      }, [scales, canAddScaleCallback]);

      const format = (value: number) => {
        return intl.formatNumber(value, {
          style: "currency",
          currency: "EUR",
        });
      };

      const updateScale = useCallback(
        (
          key: number,
          type: "max" | "percentage",
          value: number | string | null
        ) => {
          if (!value) return;
          const updatedScales = scales.map((scale, idx) => {
            if (
              key !== scales.length - 1 &&
              type === "max" &&
              idx === key + 1
            ) {
              return {
                ...scale,
                transactionPriceMin: parseFloat((value || 0).toString()),
              };
            }

            if (idx === key) {
              switch (type) {
                case "max": {
                  return {
                    ...scale,
                    transactionPriceMax: isNull(value)
                      ? value
                      : parseFloat((value || "").toString()),
                  };
                }
                case "percentage": {
                  return {
                    ...scale,
                    commissionPercentage: isNull(value)
                      ? value
                      : parseFloat((value || "").toString()),
                  };
                }
                default: {
                  return scale;
                }
              }
            }

            return scale;
          });

          setScales(updatedScales);
          canAddScaleCallback(updatedScales);
          onChangeCallback({ scales: updatedScales });
        },
        [scales, setScales, canAddScaleCallback, onChangeCallback]
      );

      const addScale = useCallback(() => {
        const { transactionPriceMax } = last(scales);
        if (!!transactionPriceMax) {
          const scale: CommissionScale = {
            transactionPriceMin: transactionPriceMax,
            transactionPriceMax: null,
            commissionPercentage: null,
          };
          const updatedScales = [...scales, scale];
          setScales(updatedScales);
          canAddScaleCallback(updatedScales);
          onChangeCallback({ scales: updatedScales });
        }
      }, [scales, setScales, canAddScaleCallback, onChangeCallback]);

      const removeScale = useCallback(() => {
        const updatedScales = scales.slice(0, scales.length - 1);
        setScales(updatedScales);
        canAddScaleCallback(updatedScales);
        onChangeCallback({ scales: updatedScales });
      }, [scales, setScales, canAddScaleCallback, onChangeCallback]);

      const commissionTypeCallback = useCallback(
        (commissionType: IntCommissionType) => {
          setCommissionType(commissionType);
          switch (true) {
            case commissionType === IntCommissionType.FixedAndScaled: {
              if (!scales.length) {
                const scale: CommissionScale = {
                  transactionPriceMin: 0,
                  transactionPriceMax: null,
                  commissionPercentage: null,
                };
                setScales([scale]);
                canAddScaleCallback([scale]);
                onChangeCallback({ scales: [scale] });
              }

              setPercentCustomer(0);
              setPercentVendor(0);
              break;
            }
            case commissionType === IntCommissionType.Fixed: {
              setScales([]);
              setScaledPercentage(0);
              canAddScaleCallback([]);
              onChangeCallback({ scales: [] });
              break;
            }
            default: {
              break;
            }
          }
        },
        [
          setCommissionType,
          scales,
          setScales,
          setScaledPercentage,
          canAddScaleCallback,
          onChangeCallback,
        ]
      );

      const amountOfMonthsVendorCallback = useCallback(
        (value: string | number | null | undefined) => {
          const amountOfMonthsVendor = float(value);
          setAmountOfMonthsVendor(amountOfMonthsVendor);
          onChangeCallback({ amountOfMonthsVendor });
        },
        [setAmountOfMonthsVendor, onChangeCallback]
      );

      const amountOfMonthsCustomerCallback = useCallback(
        (value: string | number | null | undefined) => {
          const amountOfMonthsCustomer = float(value);
          setAmountOfMonthsCustomer(amountOfMonthsCustomer);
          onChangeCallback({ amountOfMonthsCustomer });
        },
        [setAmountOfMonthsCustomer, onChangeCallback]
      );

      const amountVendorCallback = useCallback(
        (value: string | number | null | undefined) => {
          const amountVendor = float(value);
          setAmountVendor(amountVendor);
          onChangeCallback({ amountVendor });
        },
        [setAmountVendor, onChangeCallback]
      );

      const amountCustomerCallback = useCallback(
        (value: string | number | null | undefined) => {
          const amountCustomer = float(value);
          setAmountCustomer(amountCustomer);
          onChangeCallback({ amountCustomer });
        },
        [setAmountCustomer, onChangeCallback]
      );

      const percentVendorCallback = useCallback(
        (value: string | number | null | undefined) => {
          const percentVendor = float(value);
          setPercentVendor(percentVendor);
          onChangeCallback({ percentVendor });
        },
        [setPercentVendor, onChangeCallback]
      );

      const percentCustomerCallback = useCallback(
        (value: string | number | null | undefined) => {
          const percentCustomer = float(value);
          setPercentCustomer(percentCustomer);
          onChangeCallback({ percentCustomer });
        },
        [setPercentCustomer, onChangeCallback]
      );

      return (
        <div
          styleName={classNames("commission", {
            "no-type-switch": commissionFor === CommissionFor.Rent,
          })}
        >
          {commissionFor === CommissionFor.Sale && (
            <div styleName="type">
              <Input.RadioGroup
                name="commissionTypes"
                asButtonList={true}
                value={commissionType}
                asSingleInput={true}
                onChange={commissionTypeCallback}
              >
                <Input.Radio
                  label={`commissionType.${IntCommissionType.Fixed.toString()}`}
                  value={IntCommissionType.Fixed}
                />
                <Input.Radio
                  label={`commissionType.${IntCommissionType.FixedAndScaled.toString()}`}
                  value={IntCommissionType.FixedAndScaled}
                />
              </Input.RadioGroup>
            </div>
          )}

          <div styleName="form-and-calculation">
            <div styleName="form">
              <h2>
                <ResourceText resourceKey="commission.title.fixed" />
              </h2>

              <div styleName="row as-header">
                <div styleName="row__label" />
                <div styleName="row__input">
                  <ResourceText
                    resourceKey={`commission.header.vendor${commissionFor.toString()}`}
                  />
                </div>
                {(commissionFor === CommissionFor.Sale ||
                  !isRentForConsumer) && (
                  <div styleName="row__input">
                    <ResourceText
                      resourceKey={`commission.header.customer${commissionFor.toString()}`}
                    />
                  </div>
                )}
              </div>

              {commissionFor === CommissionFor.Rent && (
                <div styleName="row">
                  <div styleName="row__label">
                    <ResourceText resourceKey="commission.label.amountOfMonths" />
                  </div>

                  <div styleName="row__input" className="input__helper">
                    <Input.Number
                      name="amountOfMonthsVendor"
                      value={amountOfMonthsVendor}
                      asSingleInput={true}
                      onChange={amountOfMonthsVendorCallback}
                    />
                    <div className="post full">
                      <ResourceText resourceKey="commission.label.months" />
                    </div>
                  </div>

                  {!isRentForConsumer && (
                    <div styleName="row__input" className="input__helper">
                      <Input.Number
                        name="amountOfMonthsConstumer"
                        value={amountOfMonthsCustomer}
                        asSingleInput={true}
                        onChange={amountOfMonthsCustomerCallback}
                      />
                      <div className="post full">
                        <ResourceText resourceKey="commission.label.months" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div styleName="row">
                <div styleName="row__label">
                  <ResourceText resourceKey="commission.label.amount" />
                </div>

                <div styleName="row__input" className="input__helper">
                  <div className="pre">&euro;</div>
                  <Input.Number
                    name="amountVendor"
                    value={amountVendor}
                    pretty={true}
                    asSingleInput={true}
                    onChange={amountVendorCallback}
                  />
                </div>

                {(commissionFor === CommissionFor.Sale ||
                  !isRentForConsumer) && (
                  <div styleName="row__input" className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="amountCustomer"
                      value={amountCustomer}
                      pretty={true}
                      asSingleInput={true}
                      onChange={amountCustomerCallback}
                    />
                  </div>
                )}
              </div>

              {commissionType === IntCommissionType.Fixed && (
                <div styleName="row">
                  <div styleName="row__label">
                    <ResourceText resourceKey="commission.label.percentage" />
                  </div>

                  <div styleName="row__input" className="input__helper">
                    <Input.Number
                      name="percentVendor"
                      value={percentVendor}
                      max={100}
                      pretty={true}
                      asSingleInput={true}
                      onChange={percentVendorCallback}
                    />
                    <div className="post">%</div>
                  </div>

                  {(commissionFor === CommissionFor.Sale ||
                    !isRentForConsumer) && (
                    <div styleName="row__input" className="input__helper">
                      <Input.Number
                        name="percentCustomer"
                        value={percentCustomer}
                        max={100}
                        pretty={true}
                        asSingleInput={true}
                        onChange={percentCustomerCallback}
                      />
                      <div className="post">%</div>
                    </div>
                  )}
                </div>
              )}

              {commissionType === IntCommissionType.FixedAndScaled && (
                <>
                  <h2>
                    <ResourceText resourceKey="commission.title.scaled" />
                  </h2>

                  {scales.map((scale, idx) => (
                    <div styleName="scale-row" key={idx}>
                      <div styleName="scale-row__label">
                        {idx === 0 && (
                          <ResourceText resourceKey="commission.label.scale" />
                        )}
                      </div>
                      <div
                        styleName="scale-row__input"
                        className="input__helper"
                      >
                        <div className="pre small">&euro;</div>
                        <Input.Number
                          name={`scaleMin${idx}`}
                          pretty={true}
                          asSingleInput={true}
                          value={scale.transactionPriceMin}
                          disabled
                        />
                      </div>
                      <div styleName="scale-row__text">
                        <ResourceText resourceKey="untill" />
                      </div>
                      <div
                        styleName="scale-row__input"
                        className="input__helper"
                      >
                        <div className="pre small">&euro;</div>
                        <Input.Number
                          name={`scaleMax${idx}`}
                          pretty={true}
                          asSingleInput={true}
                          value={scale.transactionPriceMax}
                          min={scale.transactionPriceMin + 1}
                          onChange={(value) => updateScale(idx, "max", value)}
                        />
                      </div>
                      <div styleName="scale-row__text">=</div>
                      <div
                        styleName="scale-row__input"
                        className="input__helper"
                      >
                        <Input.Number
                          name={`scalePercentage${idx}`}
                          asSingleInput={true}
                          value={scale.commissionPercentage}
                          max={100}
                          pretty={true}
                          onChange={(value) =>
                            updateScale(idx, "percentage", value)
                          }
                        />
                        <div className="post small">%</div>
                      </div>
                      {idx !== 0 && idx === scales.length - 1 && (
                        <div styleName="remove" onClick={removeScale}>
                          <i className="fal fa-times" />
                        </div>
                      )}
                    </div>
                  ))}

                  <div styleName="scale-add">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={addScale}
                      disabled={!canAddScale}
                    >
                      <ResourceText resourceKey="commission.action.addScale" />
                    </button>
                  </div>
                </>
              )}
            </div>

            <div styleName="calculation">
              <h2>
                <ResourceText resourceKey="commission.title.calculation" />
              </h2>

              {commissionFor === CommissionFor.Rent && (
                <div styleName="row">
                  <div styleName="row__label">
                    <ResourceText resourceKey="commission.label.monthsTotal" />
                  </div>
                  <div styleName="row__value">{format(commissionOfMonths)}</div>
                </div>
              )}

              <div styleName="row">
                <div styleName="row__label">
                  <ResourceText resourceKey="commission.label.amount" />
                </div>
                <div styleName="row__value">{format(fixedCommission)}</div>
              </div>

              <div styleName="row">
                <div styleName="row__label">
                  <ResourceText
                    resourceKey={
                      commissionType === IntCommissionType.Fixed
                        ? `commission.label.percentageCalculated${commissionFor.toString()}`
                        : `commission.label.percentageCalculatedScaled${commissionFor.toString()}`
                    }
                    values={{
                      percentage:
                        commissionType === IntCommissionType.Fixed
                          ? percentVendor + percentCustomer
                          : scaledPercentage,
                    }}
                  />
                </div>
                <div styleName="row__value">{format(percentualCommission)}</div>
              </div>

              <div styleName="row total">
                <div styleName="row__label">
                  <ResourceText resourceKey={"commission.label.total"} />
                </div>
                <div styleName="row__value">
                  {format(
                    fixedCommission + percentualCommission + commissionOfMonths
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);
