import { AssignmentSnapShot, BidStatus } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import head from "lodash-es/head";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  assignment: AssignmentSnapShot;
  won?: boolean;
  onNavigate: (path: string) => void;
};

const AssignmentInNegotiationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ assignment, onNavigate }) => {
    const {
      id,
      displayName,
      photo1Url,
      forSale,
      forRent,
      saleCondition,
      rentCondition,
      salePrice,
      rentPrice,
    } = assignment;

    const intl = useIntl();

    const onClickHandler = useCallback((id) => {
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
      onNavigate(path);
    }, []);

    const bid = useMemo(() => {
      const { linkedBids } = assignment;
      if (!linkedBids || !linkedBids.length) return null;
      const bids = sortBy(
        linkedBids.filter((bid) =>
          [BidStatus.Active, BidStatus.Accepted].includes(bid.status)
        ),
        (bid) => bid.bidDateTime
      );
      if (!bids.length) return null;
      const ref = head(bids);

      return (
        <>
          {" "}
          &bull;{" "}
          <I18n
            value="acquisition.assignment.latestOffer"
            values={{
              offer: intl.formatNumber(ref.amount, {
                style: "currency",
                currency: "EUR",
              }),
              offerDate: moment(ref.bidDateTime).format("DD MMMM YYYY"),
            }}
          />
        </>
      );
    }, [id]);

    return (
      <div styleName="assignment" key={id} onClick={() => onClickHandler(id)}>
        <div
          styleName="assignment__photo"
          style={{
            backgroundImage: !!photo1Url
              ? `url(${JSON.stringify(photo1Url)})`
              : null,
          }}
        >
          {!photo1Url && (
            <Icon name="camera-alt" light color={Colors.Gray} size={20} />
          )}
        </div>
        <div styleName="assignment__meta">
          <div styleName="assignment__title">
            {displayName} - {}
          </div>
          <div styleName="assignment__info">
            <I18n
              value="acquisition.assignment.price"
              values={{
                price: intl.formatNumber(
                  forSale ? salePrice || 0 : rentPrice || 0,
                  { style: "currency", currency: "EUR" }
                ),
              }}
            />{" "}
            {!!forRent && <I18n value="acquisition.assignment.per" />}
            {((!!forRent && !!rentCondition) ||
              (!!forSale && !!saleCondition)) && (
              <I18n
                prefix={!!forSale ? "saleConditions" : "rentConditions"}
                value={
                  !!forSale
                    ? saleCondition.toString()
                    : rentCondition.toString()
                }
              />
            )}
            {bid}
          </div>
        </div>
      </div>
    );
  })
);

export default AssignmentInNegotiationComponent;
