import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { url } from "@haywork/modules/form/validators/url";
import { Modal, ModalBody } from "@haywork/modules/modal";
import { ButtonLoader } from "@haywork/modules/shared";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { ConfirmSubscriptionComponent } from "../confirm-subscription/confirm-subscription";
import { OnboardingContainerProps } from "./container";

const styles = require("./style.scss");
const YOUTUBE_ID = "Z_VjqkfpuPU"; // 5wxvk3gc3nk

export type OnboardingProps = {
  visible: boolean;
  onCloseHandler: () => void;
};

type Props = OnboardingProps & OnboardingContainerProps;

export const OnboardingComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, mlsAccessData, onCloseHandler, toggleMlsSubscription }) => {
      const [loading, setLoading] = useState(false);
      const [showConfirmSubscrption, setShowConfirmSubscription] =
        useState(false);
      const iframeRef = useRef<HTMLDivElement>();
      const [player, setPlayer] = useState<any>(null);

      const listItems = useMemo(() => {
        return [
          {
            icon: "street-view",
            text: {
              title: "mls.onboarding.modal.body.list.item1.title",
              text: "mls.onboarding.modal.body.list.item1.text",
            },
            startTimeInSeconds: 8,
          },
          {
            icon: "handshake",
            text: {
              title: "mls.onboarding.modal.body.list.item2.title",
              text: "mls.onboarding.modal.body.list.item2.text",
            },
            startTimeInSeconds: 384,
          },
          {
            icon: "clone",
            text: {
              title: "mls.onboarding.modal.body.list.item3.title",
              text: "mls.onboarding.modal.body.list.item3.text",
            },
            startTimeInSeconds: 451,
          },
        ];
      }, []);

      const handleMlsSubscribe = useCallback(async () => {
        setLoading(true);
        try {
          await toggleMlsSubscription();
          setLoading(false);
          setShowConfirmSubscription(false);
        } catch (error) {
          setLoading(false);
          setShowConfirmSubscription(false);
        }
      }, [toggleMlsSubscription, setLoading]);

      const setPlayerReady = useCallback(
        (event: any) => {
          setPlayer(event.target);
        },
        [setPlayer]
      );

      const seekTo = useCallback(
        (timeInSeconds: Number) => {
          player.seekTo(timeInSeconds, true);
        },
        [player]
      );

      useEffect(() => {
        if (!!player || !window["YT"] || !iframeRef.current) return;
        const _ = new window["YT"].Player(iframeRef.current, {
          height: "100%",
          width: "100%",
          videoId: YOUTUBE_ID,
          events: {
            onReady: setPlayerReady,
          },
        });
      }, [player, setPlayerReady, url]);

      return (
        <>
          <Modal visible={visible} closeOnBackDropClick={true} large>
            <ModalBody noPadding>
              <div styleName="row">
                <div styleName="column text">
                  <div styleName="close" onClick={onCloseHandler}>
                    <i className="fal fa-fw fa-times" />
                  </div>
                  <h2>
                    <I18n value="mls.onboarding.modal.title" asHtml />
                  </h2>
                  <ol>
                    {listItems.map((item, idx) => (
                      <li key={idx}>
                        <div styleName="icon">
                          <Icon name={item.icon} fixedWidth={true} light />
                        </div>
                        <div styleName="text">
                          <h5>
                            <I18n value={item.text.title} />
                          </h5>
                          <p>
                            <I18n value={item.text.text} />
                          </p>
                        </div>
                        <div
                          styleName="link"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            seekTo(item.startTimeInSeconds);
                          }}
                        >
                          <Icon
                            name="play-circle"
                            light={true}
                            color={Colors.Primary}
                            size={30}
                            fixedWidth={true}
                          />
                        </div>
                      </li>
                    ))}
                  </ol>
                  <div styleName="cta">
                    {/* Never activated before */}
                    {!mlsAccessData?.demoUntilDateTime && (
                      <>
                        <p>
                          <I18n value="mls.onboarding.modal.body" />
                        </p>
                        <button
                          styleName="btn"
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return setShowConfirmSubscription(true);
                          }}
                        >
                          <ButtonLoader
                            resourceKey="mls.onboarding.modal.button.activateTrial"
                            loading={loading}
                          />
                        </button>
                        <p styleName="subText">
                          <I18n value="mls.onboarding.modal.body.sub" />
                        </p>
                      </>
                    )}
                    {/* Activated but expired or disabled */}
                    {!mlsAccessData?.isActive &&
                      mlsAccessData?.demoUntilDateTime && (
                        <div styleName="trialExpired">
                          <div>
                            <I18n value="mls.onboarding.modal.body.trialExpired" />
                          </div>
                          <button
                            styleName="btn"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowConfirmSubscription(true);
                            }}
                          >
                            <ButtonLoader
                              resourceKey="mls.onboarding.modal.button.activateMls"
                              loading={loading}
                            />
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div styleName="column video">
                  <div ref={iframeRef} id="onboarding" />
                </div>
              </div>
            </ModalBody>
          </Modal>

          <ConfirmSubscriptionComponent
            visible={showConfirmSubscrption}
            onCloseHandler={() => setShowConfirmSubscription(false)}
            handleMlsSubscribe={handleMlsSubscribe}
          />
        </>
      );
    }
  )
);
