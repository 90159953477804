import { LocationSuggestionType } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";
import { ModalPortal } from "@haywork/portals";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import {
  CSSProperties,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { isMobile } from "react-device-detect";
import { useIntl } from "react-intl";
import { LocationInputContainerProps } from "./location-input.container";

const styles = require("./style.scss");

const sortOrder = [
  LocationSuggestionType.Property,
  LocationSuggestionType.Street,
  LocationSuggestionType.Sublocality,
  LocationSuggestionType.Locality,
  LocationSuggestionType.AdminAreaLevel3,
  LocationSuggestionType.AdminAreaLevel2,
  LocationSuggestionType.AdminAreaLevel1,
];

export type LocationInputComponentProps = {
  value: ExtendedLocationSuggestionItem | null | undefined;
  name: string;
  onChange: (value: ExtendedLocationSuggestionItem) => void;
};
type Props = LocationInputComponentProps & LocationInputContainerProps;
type CategorizedItem = {
  category: LocationSuggestionType;
  items: ExtendedLocationSuggestionItem[];
};

export const LocationInputComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ value, name, onChange, querySuggestions }) => {
      const intl = useIntl();
      const [query, setQuery] = useState(value?.label || "");
      const [querying, setQuerying] = useState(false);
      const [items, setItems] = useState<CategorizedItem[]>([]);
      const ref = useRef<HTMLDivElement | null>(null);
      const inputRef = useRef<HTMLInputElement | null>(null);
      const dropdownRef = useRef<HTMLDivElement | null>(null);
      const [focussed, setFocussed] = useState(false);
      const [mouseDown, setMouseDown] = useState(false);
      const [innerValue, setInnerValue] = useState(value);
      const [style, setStyle] = useState<CSSProperties | null>(null);
      const itemCount = items.length;

      const placeholder = useMemo(() => {
        return intl.formatMessage({
          id: "mls.location.filter.placeholder",
          defaultMessage: "Locality, neighbourhood, address, etc.",
        });
      }, [intl]);

      const fetchSuggestions = useCallback(
        debounce(async (query: string) => {
          try {
            if (querying || query.length < 2) return;
            setItems([]);
            setQuerying(true);

            const results = await querySuggestions(query);
            const items = results.reduce((state, item) => {
              const match = state.find((match) => match.category === item.type);

              if (!!match) {
                match.items.push(item);
              } else {
                state.push({ category: item.type, items: [item] });
              }

              return state;
            }, [] as CategorizedItem[]);

            setItems(items || []);
          } catch (error) {
            throw error;
          } finally {
            setQuerying(false);
          }
        }, 500),
        [querying, setQuerying, querySuggestions, setItems]
      );

      const setQueryAndInnerValue = useCallback(
        (item?: ExtendedLocationSuggestionItem) => {
          setInnerValue(item || innerValue);
          let label = (item || innerValue)?.label || "";
          if (
            (item || innerValue)?.type === LocationSuggestionType.Street &&
            !!(item || innerValue)?.parentComponentName
          ) {
            label = `${label}, ${(item || innerValue).parentComponentName}`;
          }
          setQuery(label);
        },
        [setQuery, innerValue, setInnerValue]
      );

      const onChangeCallback = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setQuery(event.target.value);
          fetchSuggestions(event.target.value);
        },
        [setQuery]
      );

      const fireBlur = useCallback(
        (focus = false, innerValue?: ExtendedLocationSuggestionItem) => {
          if (focus && !!inputRef.current) {
            inputRef.current.focus();
          } else {
            setFocussed(false);
          }
          setItems([]);

          setQueryAndInnerValue(innerValue);
          if (!!innerValue) {
            onChange(innerValue);
          }
        },
        [onChange, setQueryAndInnerValue]
      );

      const calcStyles = useCallback(() => {
        if (!ref.current) return;
        const {
          top: refTop,
          height: refHeight,
          width: minWidth,
          left,
        } = ref.current.getBoundingClientRect();
        const { innerHeight } = window;

        const topSpace = refTop;
        const bottomSpace = innerHeight - (refTop + refHeight);

        let top: number | "auto" = "auto";
        let bottom: number | "auto" = "auto";
        let maxHeight = bottomSpace - 16;

        if (bottomSpace > 336 || bottomSpace > topSpace) {
          top = refTop + refHeight;
        } else {
          bottom = bottomSpace + refHeight;
          maxHeight = topSpace - 16;
        }

        setStyle({
          top,
          bottom,
          left,
          maxHeight,
          minWidth,
        });
      }, [setStyle]);

      const onFocusCallback = useCallback(() => {
        setFocussed(true);
        calcStyles();
      }, [setFocussed, calcStyles]);

      const onBlurCallback = useCallback(() => {
        if (!!mouseDown) return;
        fireBlur();
      }, [fireBlur, mouseDown]);

      const onMouseDownCallback = useCallback(() => {
        setMouseDown(true);
      }, [setMouseDown]);

      const onMouseUpCallback = useCallback(
        (item: ExtendedLocationSuggestionItem) => {
          setMouseDown(false);
          fireBlur(true, item);
        },
        [setMouseDown, fireBlur]
      );

      useEffect(() => {
        setQueryAndInnerValue(value);
      }, [value, setQueryAndInnerValue]);

      useEffect(() => {
        const noContext = itemCount === 0 && query === "";

        const onScroll = (event: Event) => {
          if (event.target === dropdownRef.current) {
            return;
          }

          if (!!ref.current && !isMobile) {
            inputRef.current.blur();
          }
        };

        const onResize = () => {
          if (!!inputRef.current && !noContext) {
            inputRef.current.blur();
          }
        };

        window.addEventListener("resize", onResize, true);
        document.addEventListener("scroll", onScroll, true);

        return () => {
          window.removeEventListener("resize", onResize, true);
          document.removeEventListener("scroll", onScroll, true);
        };
      }, [itemCount, query]);

      return (
        <div styleName={classNames("input", { focussed })} ref={ref}>
          <input
            type="text"
            placeholder={placeholder}
            value={query}
            onChange={onChangeCallback}
            onFocus={onFocusCallback}
            onBlur={onBlurCallback}
            ref={inputRef}
            name={name}
            id={name}
          />
          {!!querying && (
            <div styleName="loader">
              <div styleName="indeterminate" />
            </div>
          )}
          {!!focussed && (
            <ModalPortal>
              <div
                styleName={classNames("dropdown")}
                style={style}
                ref={dropdownRef}
              >
                {!querying &&
                  !!query &&
                  query !== innerValue?.label &&
                  items.length === 0 && (
                    <div styleName="empty-state">
                      <I18n value="noMatchingOption" />
                    </div>
                  )}
                {items.map((item, idx) => (
                  <div key={item.category.toString() + idx}>
                    <div styleName="category">
                      <I18n
                        prefix="locationSuggestionTypeOptions"
                        value={item.category.toString()}
                      />
                    </div>
                    {item.items.map((location, idx) => (
                      <div
                        styleName="item"
                        key={`${location.value}-${idx}`}
                        onMouseDown={onMouseDownCallback}
                        onMouseUp={() => onMouseUpCallback(location)}
                      >
                        {location.label}
                        {location.type === LocationSuggestionType.Street &&
                          !!location.parentComponentName &&
                          `, ${location.parentComponentName}`}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </ModalPortal>
          )}
        </div>
      );
    }
  )
);
