import { SnackbarActions, snackbarActions } from "./snackbar.actions";
import { v4 as uuid } from "uuid";

type Entity = {
  id: string;
};
export type ToastProps = {
  persistFor?: number;
  value: string;
  values?: { [key: string]: any };
  icon?: string;
  spin?: boolean;
  callback?: () => void;
  callbackLabel?: string;
  callbackLabelValues?: { [key: string]: any };
  done?: () => void;
};
export type Toast = ToastProps & Entity;
export type SnackbarState = {
  toasts: Toast[];
};

const INITIAL: SnackbarState = {
  toasts: []
};

export const snackbarReducer = (
  state: SnackbarState = INITIAL,
  action: SnackbarActions
): SnackbarState => {
  switch (action.type) {
    case snackbarActions.AddToast: {
      const {
        persistFor,
        value,
        values,
        icon,
        callback,
        callbackLabel,
        callbackLabelValues,
        spin,
        done
      } = action;
      const toast: Toast = {
        id: uuid(),
        persistFor,
        value,
        values,
        icon,
        callback,
        callbackLabel,
        callbackLabelValues,
        spin,
        done
      };
      const toasts = [toast, ...state.toasts];

      return {
        ...state,
        toasts
      };
    }
    case snackbarActions.RemoveToast: {
      const { id } = action;
      const toasts = state.toasts.filter((toast) => toast.id !== id);

      return {
        ...state,
        toasts
      };
    }
    default: {
      return state;
    }
  }
};
