import { REDUX } from "@haywork/constants";
import * as ActionType from "./settings.types";

const setNotificationSettings = (payload: ActionType.NotificationSettingsResponse) => ({
  type: REDUX.EVENTCENTER.SET_NOTIFICATIONS_SETTINGS,
  ...payload
});

const setNotificationSettingsStatus = (notificationSettingsStatus: string) => ({
  type: REDUX.EVENTCENTER.SET_NOTIFICATIONS_SETTINGS_STATUS,
  notificationSettingsStatus
});

export const SettingsActions = {
  setNotificationSettings,
  setNotificationSettingsStatus
};
