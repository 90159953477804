import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { PlaygroundContainerProps } from "./playground.container";
import Button from "@haywork/components/ui/button";
import { RouteUtil } from "@haywork/util";
import { MLSROUTES } from "@haywork/constants";
import * as moment from "moment";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type PlaygroundComponentProps = {};
type Props = PlaygroundComponentProps & PlaygroundContainerProps;

export const PlaygroundComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ navigate }) => {
    const path = route(MLSROUTES.DETAIL.URI, {
      id: "demo",
      source: "mls_kolibri_sync_alpha",
    });

    return (
      <div styleName="playground">
        <Button
          onClick={() => navigate(path)}
          label="Open demo MLS object"
          category="primary"
        />
      </div>
    );
  })
);
