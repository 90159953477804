import {
  FinancialAdministration,
  FinancialAdministrationGroup,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FormReturnValue } from "@haywork/modules/form";
import { SettingsFinancialAdministrationsGroupsContainerProps } from "@haywork/modules/settings/modules/financial-administrations";
import { ConfirmComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { GroupComponent } from "./group.component";

const styles = require("./groups.component.scss");

export interface SettingsFinancialAdministrationsGroupsComponentProps {}
interface State {
  financialAdministration: FinancialAdministration;
  saveConfirmVisible: boolean;
  numberOfEmptyGroups: number;
}
type Props = SettingsFinancialAdministrationsGroupsComponentProps &
  SettingsFinancialAdministrationsGroupsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsFinancialAdministrationsGroupsComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onAddGroupClickHandler = this.onAddGroupClickHandler.bind(this);
    this.onGroupRemoveHandler = this.onGroupRemoveHandler.bind(this);
    this.onGroupChangeHandler = this.onGroupChangeHandler.bind(this);
    this.onSaveCloseHandler = this.onSaveCloseHandler.bind(this);
    this.onSaveConfirmHandler = this.onSaveConfirmHandler.bind(this);

    const financialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    this.state = {
      financialAdministration,
      saveConfirmVisible: false,
      numberOfEmptyGroups: 0,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    const nextFinancialAdministration = nextProps.financialAdministrations.find(
      (f) => f.id === nextProps.match.params.id
    );
    const prevFinancialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    if (
      nextProps.match.params.id !== this.props.match.params.id ||
      nextFinancialAdministration.groups.length !==
        prevFinancialAdministration.groups.length
    ) {
      this.setState({
        financialAdministration: nextFinancialAdministration,
      });
    }
  }

  public render() {
    if (!this.state.financialAdministration) {
      return null;
    }
    const loading = this.props.financialAdministrationState === REQUEST.PENDING;

    return (
      <div styleName="groups">
        <PageHeader
          title="settingsMenuFinancialAdministrationGroups"
          subTitle={this.state.financialAdministration.name}
          actions={
            <>
              <Button
                label="save"
                category="success"
                onClick={this.onSaveClickHandler}
                disabled={loading}
              />
              <Button
                label="addNewGroup"
                icon={
                  <Icon
                    name="plus"
                    size={18}
                    light
                    color={loading ? Colors.Gray : Colors.White}
                  />
                }
                category="primary"
                onClick={this.onAddGroupClickHandler}
                disabled={loading}
              />
            </>
          }
        />

        <div styleName="groups__body">
          <div styleName="inner">
            <div styleName="groups__list-header">
              <div styleName="column">
                <I18n value="description" />
              </div>
              <div styleName="column">
                <I18n value="budgetedAmountPerMonth" />
              </div>
              <div styleName="column">
                <I18n value="priceExVat" />
              </div>
              <div styleName="column" />
            </div>

            {loading && <Ui.Loaders.Fullscreen mask />}

            {this.state.financialAdministration.groups.map((group, idx) => (
              <ErrorBoundary key={group.id}>
                <GroupComponent
                  group={group}
                  onRemove={this.onGroupRemoveHandler}
                  onChange={this.onGroupChangeHandler}
                />
              </ErrorBoundary>
            ))}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.saveConfirmVisible}
          titleResourceKey="financialAdminEmptyGroupsConfirmTitle"
          bodyResourceKey="financialAdminEmptyGroupsConfirmBody"
          bodyValues={{ count: this.state.numberOfEmptyGroups }}
          onClose={this.onSaveCloseHandler}
          onConfirm={this.onSaveConfirmHandler}
        />
      </div>
    );
  }

  private onAddGroupClickHandler() {
    const newGroup: FinancialAdministrationGroup = {
      id: null,
      budgetedAmount: 0,
      budgetedNumberOfProducts: 0,
    };

    const groups = [newGroup, ...this.state.financialAdministration.groups];

    const financialAdministration = {
      ...this.state.financialAdministration,
      groups,
    };

    this.props.updateFinancialAdministration(financialAdministration);
  }

  private onSaveClickHandler() {
    const count = this.state.financialAdministration.groups.length;
    const filteredGroups = this.state.financialAdministration.groups.filter(
      (g) =>
        g.budgetedAmount !== 0 || g.budgetedNumberOfProducts !== 0 || !!g.name
    );

    if (count !== filteredGroups.length) {
      this.setState({
        saveConfirmVisible: true,
        numberOfEmptyGroups: count - filteredGroups.length,
      });
    } else {
      this.props.updateFinancialAdministration(
        this.state.financialAdministration
      );
    }
  }

  private onGroupRemoveHandler(id: number) {
    const groups = this.state.financialAdministration.groups.filter(
      (g) => g.id !== id
    );

    const financialAdministration = {
      ...this.state.financialAdministration,
      groups,
    };

    this.setState({
      financialAdministration,
    });
  }

  private onGroupChangeHandler(values: FormReturnValue) {
    const { id } = values;

    const groups = this.state.financialAdministration.groups.map((g) => {
      if (g.id === id) {
        return {
          ...g,
          ...values,
        };
      }
      return g;
    });

    const financialAdministration = {
      ...this.state.financialAdministration,
      groups,
    };

    this.setState({
      financialAdministration,
    });
  }

  private onSaveCloseHandler() {
    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyGroups: 0,
    });
  }

  private onSaveConfirmHandler() {
    const groups = this.state.financialAdministration.groups.filter(
      (g) =>
        g.budgetedAmount !== 0 || g.budgetedNumberOfProducts !== 0 || !!g.name
    );
    const financialAdministration = {
      ...this.state.financialAdministration,
      groups,
    };

    this.props.updateFinancialAdministration(financialAdministration);

    this.setState({
      saveConfirmVisible: false,
      numberOfEmptyGroups: 0,
    });
  }
}
