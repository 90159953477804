import { Event } from "@haywork/api/event-center";

export enum DashboardActionTypes {
  SetAssignmentsNeedAttentionResults = "EventCenter.Dashboard.SetAssignmentsNeedAttentionResults",
  SetAssignmentsNeedAttentionStatus = "EventCenter.Dashboard.SetAssignmentsNeedAttentionStatus",
  SetLeadsResults = "EventCenter.Dashboard.SetLeadsResults",
  SetLeadsStatus = "EventCenter.Dashboard.SetLeadsStatus",
  Archive = "EventCenter.Dashboard.Archive"
}

interface SetAssignmentsNeedAttentionResults {
  type: DashboardActionTypes.SetAssignmentsNeedAttentionResults;
  assignmentsNeedAttentionEvents: Event[];
}
const setAssignmentsNeedAttentionResults = (
  assignmentsNeedAttentionEvents: Event[]
) => ({
  type: DashboardActionTypes.SetAssignmentsNeedAttentionResults,
  assignmentsNeedAttentionEvents
});

interface SetAssignmentsNeedAttentionStatus {
  type: DashboardActionTypes.SetAssignmentsNeedAttentionStatus;
  assignmentsNeedAttentionStatus: string;
}
const setAssignmentsNeedAttentionStatus = (
  assignmentsNeedAttentionStatus: string
) => ({
  type: DashboardActionTypes.SetAssignmentsNeedAttentionStatus,
  assignmentsNeedAttentionStatus
});

interface SetLeadsResults {
  type: DashboardActionTypes.SetLeadsResults;
  leadsEvents: Event[];
}
const setLeadsResults = (leadsEvents: Event[]) => ({
  type: DashboardActionTypes.SetLeadsResults,
  leadsEvents
});

interface SetLeadsStatus {
  type: DashboardActionTypes.SetLeadsStatus;
  leadsStatus: string;
}
const setLeadsStatus = (leadsStatus: string) => ({
  type: DashboardActionTypes.SetLeadsStatus,
  leadsStatus
});

interface Archive {
  type: DashboardActionTypes.Archive;
  eventId: string;
}
const archive = (eventId: string) => ({
  type: DashboardActionTypes.Archive,
  eventId
});

export type DashboardAction =
  | SetAssignmentsNeedAttentionResults
  | SetAssignmentsNeedAttentionStatus
  | SetLeadsResults
  | SetLeadsStatus
  | Archive;
export const Actions = {
  setAssignmentsNeedAttentionResults,
  setAssignmentsNeedAttentionStatus,
  setLeadsResults,
  setLeadsStatus,
  archive
};
