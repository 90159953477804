import {
  Bid,
  BidOrderByField,
  BidSnapShot,
  BidType,
  LinkedEmployee,
  LinkedRelation
} from "@haywork/api/kolibri";
import { BidThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { linkedEmployee } from "../selectors";
import {
  ObjectWidgetComponent,
  ObjectWidgetComponentProps
} from "./object-widget";

type StateProps = {
  linkedEmployee: LinkedRelation;
};
type DispatchProps = {
  getAllBids: (assignmentId: string) => Promise<BidSnapShot[]>;
  readBid: (id: string) => Promise<Bid>;
  createNewBid: (
    assignmentId: string,
    linkedEmployee: LinkedEmployee
  ) => Promise<Bid>;
  createCounterOffer: (
    originalBid: Bid,
    linkedRelations?: LinkedRelation[]
  ) => Promise<Bid>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ObjectWidgetComponentProps,
  AppState
> = (state) => {
  return {
    linkedEmployee: linkedEmployee(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ObjectWidgetComponentProps
> = (dispatch) => ({
  getAllBids: (assignmentId: string) =>
    dispatch(BidThunk.getAllBids(assignmentId, BidOrderByField.BidDateTime)),
  readBid: (id: string) => dispatch(BidThunk.readBid(id)),
  createNewBid: (assignmentId: string, linkedEmployee: LinkedEmployee) =>
    dispatch(
      BidThunk.createNewBid(
        assignmentId,
        BidType.Incoming,
        undefined,
        linkedEmployee
      )
    ),
  createCounterOffer: (originalBid: Bid, linkedRelations?: LinkedRelation[]) =>
    dispatch(BidThunk.createCounterOffer(originalBid, linkedRelations))
});

export type ObjectWidgetContainerProps = StateProps & DispatchProps;
export const ObjectWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectWidgetComponent);
