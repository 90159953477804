import * as React from "react";
import { FC, memo, useEffect, useState, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { LinkedSearchAssignmentsContainerProps } from "./linked-search-assignments.container";
import FormattedText from "@haywork/components/ui/formatted-text";
import { LinkedSearchAssignment, SearchAssignment } from "@haywork/api/kolibri";
import { Ui } from "@haywork/modules/ui";
import Item from "./linked-search-assignment";

const styles = require("./style.scss");

export type LinkedSearchAssignmentsComponentProps = {
  linkedSearchAssignments: LinkedSearchAssignment[];
};
type Props = LinkedSearchAssignmentsComponentProps &
  LinkedSearchAssignmentsContainerProps;

export const LinkedSearchAssignmentsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ linkedSearchAssignments, getSearchAssignmentsByIds, navigate }) => {
      const [searchAssignments, setSearchAssignments] = useState<
        SearchAssignment[]
      >([]);
      const [loading, setLoading] = useState(true);

      const fetchSearchAssignments = useCallback(async () => {
        setLoading(true);
        try {
          const searchAssignments = await getSearchAssignmentsByIds(
            linkedSearchAssignments.map((assignment) => assignment.id)
          );
          setSearchAssignments(searchAssignments);
        } finally {
          setLoading(false);
        }
      }, []);

      useEffect(() => {
        fetchSearchAssignments();
      }, []);

      if (!linkedSearchAssignments || !linkedSearchAssignments.length)
        return null;

      return (
        <div styleName="section">
          <FormattedText
            value="acquisition.sectionTitle.searchAssignments"
            asSection
          />
          {loading && <Ui.Loaders.List />}
          {searchAssignments.map((searchAssignment) => (
            <Item
              searchAssignment={searchAssignment}
              key={searchAssignment.id}
              onOpenSearchAssignment={navigate}
            />
          ))}
        </div>
      );
    }
  )
);
