import { ParseRequest } from "@haywork/services";
import { Dispatch } from ".";
import { AppState, LayoutActions } from "@haywork/stores";
import { SessionsClient } from "@haywork/api/blobs";
import {
  BatchItem,
  BlobsClient,
  BatchResponseItem
} from "@haywork/api/kolibri";
import { FilesClient } from "@haywork/api/mail";
import { FileUtil } from "@haywork/util";

declare let window: ExtendedWindow;

const parseRequest = new ParseRequest();

const createDossierItemEditSession = (dossierItemId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { blobsHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { ITHitKey } = state.appSettings;

    const Sessions = new SessionsClient(blobsHost);

    try {
      const session = await parseRequest.response(
        Sessions.createDossierItemEditSession(
          {
            dossierItemId
          },
          realEstateAgencyId
        ).then((response) => response.session)
      );

      if (!window.ITHit.WebDAV.Client.LicenseId) {
        const { WebDAV } = window.ITHit;
        WebDAV.Client.LicenseId = ITHitKey;
      }

      const { WebDAV } = window.ITHit;

      if (WebDAV.Client.DocManager.IsMicrosoftOfficeDocument(session.url)) {
        WebDAV.Client.DocManager.MicrosoftOfficeEditDocument(session.url, () =>
          dispatch(
            LayoutActions.setBlobError({
              blobError: "blob.error.bodyFailedOpening"
            })
          )
        );
      } else {
        dispatch(
          LayoutActions.setBlobError({
            blobError: "blob.error.bodyNotValidDocument"
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const createDocumentSessionEditSession = (documentSessionId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { blobsHost } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { ITHitKey } = state.appSettings;

    const Sessions = new SessionsClient(blobsHost);

    try {
      const session = await parseRequest.response(
        Sessions.createDocumentSessionEditSession(
          {
            documentSessionId
          },
          realEstateAgencyId
        ).then((response) => response.session)
      );

      if (!window.ITHit.WebDAV.Client.LicenseId) {
        const { WebDAV } = window.ITHit;
        WebDAV.Client.LicenseId = ITHitKey;
      }

      const { WebDAV } = window.ITHit;

      if (WebDAV.Client.DocManager.IsMicrosoftOfficeDocument(session.url)) {
        WebDAV.Client.DocManager.MicrosoftOfficeEditDocument(session.url, () =>
          dispatch(
            LayoutActions.setBlobError({
              blobError: "blob.error.bodyFailedOpening"
            })
          )
        );
      } else {
        dispatch(
          LayoutActions.setBlobError({
            blobError: "blob.error.bodyNotValidDocument"
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const createBatchDownload = (batchItems: BatchItem[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new BlobsClient(host);

    try {
      return parseRequest.response(
        client
          .createBatchDownload(
            {
              batchItems
            },
            realEstateAgencyId
          )
          .then((response) => response.batchResponseItems || [])
      );
    } catch (error) {
      throw error;
    }
  };
};

const uploadFilesFromUri = (accountId: string, files: BatchResponseItem[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { emailHost } = state.appSettings;
    const client = new FilesClient(emailHost);

    try {
      const promises = files.map((file) =>
        parseRequest.response(
          client.upload(
            {
              accountId,
              fileUri: file.url,
              fileName: FileUtil.sanatizeFilename(file.fileName)
            },
            realEstateAgencyId
          )
        )
      );
      const attachments = await Promise.all(promises);

      return attachments;
    } catch (error) {
      throw error;
    }
  };
};

export const BlobThunk = {
  createDossierItemEditSession,
  createDocumentSessionEditSession,
  createBatchDownload,
  uploadFilesFromUri
};
