import { REDUX } from "@haywork/constants";
import * as ActionType from "./phrases.types";

const setPhrases = (payload: ActionType.Phrases) => ({
  type: REDUX.PHRASES.SET_PHRASES,
  ...payload
});

const updatePhrases = (payload: ActionType.Phrases) => ({
  type: REDUX.PHRASES.UPDATE_PHRASES,
  ...payload
});

const setPhrasesStatus = (phrasesStatus: string) => ({
  type: REDUX.PHRASES.SET_PHRASES_STATUS,
  phrasesStatus
});

const setPhrase = (payload: ActionType.SinglePhrase) => ({
  type: REDUX.PHRASES.SET_PHRASE,
  ...payload
});

const setPhraseStatus = (phraseStatus: string) => ({
  type: REDUX.PHRASES.SET_PHRASE_STATUS,
  phraseStatus
});

const unsetPhrase = () => ({
  type: REDUX.PHRASES.UNSET_PHRASE
});

export const PhrasesActions = {
  setPhrases,
  updatePhrases,
  setPhrasesStatus,
  setPhrase,
  setPhraseStatus,
  unsetPhrase
};
