import { SearchAssignment } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentEditLinkedAssignmentsComponent,
  SearchAssignmentEditLinkedAssignmentsComponentProps
} from "@haywork/modules/search-assignment";
import { AppState, EditableItem } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  searchAssignment: SearchAssignment;
  saveSearchAssignmentStatus: string;
  hasChanges: boolean;
}
interface DispatchProps {
  saveSearchAssignment: (searchAssignment: SearchAssignment) => void;
}

const hasChanges = (id: string, items: EditableItem[]): boolean => {
  const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });
  const item = items.find((item) => item.path === path);
  return !!item ? item.hasChanges : false;
};

const mapStateToProps = <StateProps, AssignmentEditClientComponentProps>(
  state: AppState
) => {
  const searchAssignment: SearchAssignment =
    state.editable.currentComponentState;

  return {
    searchAssignment,
    saveSearchAssignmentStatus:
      state.searchAssignment.single.saveSearchAssignmentStatus,
    hasChanges: hasChanges(searchAssignment.id, state.editable.states)
  };
};

const mapDispatchToProps = <DispatchProps, AssignmentEditClientComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(
      SearchAssignmentThunks.saveSearchAssignment(
        searchAssignment,
        false,
        false
      )
    )
});

export type SearchAssignmentEditLinkedAssignmentsContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentEditLinkedAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentEditLinkedAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentEditLinkedAssignmentsComponent);
