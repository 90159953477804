import * as React from "react";
import * as CSSModules from "react-css-modules";

import { FormReturnValue } from "@haywork/modules/form";
import { RoomLayoutContainer } from "@haywork/modules/shared";
import { ObjectAssignment } from "@haywork/api/kolibri";

const styles = require("./form.component.scss");

interface Props {
  objectAssignment: ObjectAssignment;
  onChange: (values: FormReturnValue) => void;
}
interface State {
  floors: any[];
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditContentSpaceGardensComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      floors: []
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div>
        <RoomLayoutContainer
          floors={this.state.floors}
          onChange={this.onChangeHandler}
          onStorageChange={this.props.onChange}
          data={{
            floors: this.props.objectAssignment.floors,
            gardens: this.props.objectAssignment.gardens,
            garages: this.props.objectAssignment.garages
          }}
        />
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    this.props.onChange(values);
  }
}
