export const convertToCorrectDateFormat = (date: any) => {
  {
    let dateObject: Date;
    if (date) {
      dateObject = new Date(date);
    } else {
      dateObject = new Date();
    }
    return dateObject.toLocaleDateString();
  }
};

export const isAllDayEvent = (eventStartDate: Date, eventEndDate: Date) => {
  // create copy of eventStartDate
  const startDateDayBegining = new Date(eventStartDate);
  // make it the beginning of the day
  startDateDayBegining.setHours(0, 0, 0, 0);

  // create copy of eventStartDate beginning
  const dateAfterStartDateDayBegining = new Date(startDateDayBegining.getTime());
  // make it the beginning of the next day
  dateAfterStartDateDayBegining.setDate(dateAfterStartDateDayBegining.getDate() + 1);

  // check if event starts in the beginning of the day and ends in the beginning
  // of the next day so it's really 'All day event'
  return eventStartDate.getTime() === startDateDayBegining.getTime() &&
    eventEndDate.getTime() === dateAfterStartDateDayBegining.getTime();
};

export const getSchedulerViewDateRange = (scheduler, cb) => {
  if (!scheduler) {
    return cb(true, null);
  }

  const range: { start: Date; end: Date; } = { start: null, end: null };
  // Strange but only this way we can get actual startDate and endDate of the current view
  setTimeout(() => {
    const view = scheduler.view();

    if (!view) {
      getSchedulerViewDateRange(scheduler, cb);
      return;
    }

    range.start = view.startDate();

    // For 'day' and 'timeline' view scheduler returns the same datetime for startDate and endDate.
    // It can be checked in the example on http://dojo.telerik.com/
    // So we need this hack with using nextDate as endDate for 'day' view
    if (scheduler.viewName() === "day" || scheduler.viewName() === "timeline") {
      range.end = scheduler.view().nextDate();
    } else {
      range.end = scheduler.view().endDate();
    }
    cb(null, range);
  }, 10);
};

export const getSchedulerView = (scheduler, cb) => {
  if (!scheduler) {
    return cb(true, null);
  }

  setTimeout(() => {
    const view = scheduler.view();

    if (!view) {
      getSchedulerView(scheduler, cb);
      return;
    }

    cb(null, view);
  }, 10);
};
