import { FormElementType } from "@haywork/api/kolibri";

export * from "./heading.component";
export * from "./text.component";
export * from "./dropdown.component";
export * from "./date.component";
export * from "./textarea.component";
export * from "./number.component";

export interface FlattenedFormElement {
  type: FormElementType;
  depth: number;
  heading?: string | null;
  name?: string | null;
  value?: string | number | null | Date;
  values?: string[];
  preText?: string | null;
  postText?: string | null;
  maximumLength?: number | null;
  format?: string | null;
  phraseCategoryId?: number;
  disabled: boolean;
}
