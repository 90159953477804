import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  EditorUploadComponent,
  EditorUploadComponentProps
} from "./upload.component";

interface StateProps {
  token: string;
  emailHost: string;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  EditorUploadComponentProps,
  AppState
> = (state) => {
  const { token } = state.access;
  const { emailHost } = state.appSettings;

  return {
    token,
    emailHost
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EditorUploadComponentProps
> = (dispatch) => ({});

export type EditorUploadContainerProps = StateProps & DispatchProps;
export const EditorUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorUploadComponent);
