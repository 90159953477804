// import * as React from "react";
// import { PureComponent, ComponentType } from "react";
// import {
//   ConfirmConfig,
//   AppState,
//   EditableItem,
//   EditableActions
// } from "@haywork/stores";
// import { RootEntityType } from "@haywork/api/event-center";
// import { EditableCalleeType } from "@haywork/enum";
// import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
// import { RouteComponentProps, withRouter } from "react-router-dom";
// import { currentComponentState } from "@haywork/stores/selectors";
// import get from "lodash-es/get";
// import { RouteUtil } from "@haywork/util";

// const route = RouteUtil.mapStaticRouteValues;

// export type EditableHocOptions = {
//   icon: string;
//   thunk: Function;
//   status: string;
//   statePath: string;
//   action: Function;
//   route: string;
//   initialTabTitle?: string;
//   initialState?: any;
//   confirm?: ConfirmConfig;
//   objectName?: string | string[];
//   splitObjectNameAsParams?: boolean;
//   entityType: RootEntityType | null;
// };
// export type EditableHocComponentProps = {};
// type State = {
//   loading: boolean;
// };
// export type EditableHocProps = {
//   preppedForSave: boolean;
//   hasChanges: boolean;
//   callee?: {
//     type: EditableCalleeType;
//     id: string;
//     state: string;
//   };
//   setTabTitle: (title: string) => void;
//   updateCache: (cache: any, pathname: string, ignoreChanges?: boolean) => void;
//   setHasChanges: () => void;
// };
// type ConnectStateProps = {
//   componentState: EditableItem;
//   entityId: string;
// };
// type ConnectDispatchProps = {
//   setTabTitle: (title: string, path: string) => void;
//   dispatch: (action: any) => void;
// };
// type Props = EditableHocComponentProps &
//   RouteComponentProps<{ id: string }> &
//   ConnectStateProps &
//   ConnectDispatchProps;

// const mapStateToProps: MapStateToProps<
//   ConnectStateProps,
//   EditableHocComponentProps & RouteComponentProps<{ id: string }>,
//   AppState
// > = (state, ownProps) => {
//   const entityId = get(ownProps, "match.params.id", "") as string;
//   const componentState = currentComponentState(state, entityId);

//   return { componentState, entityId };
// };
// const mapDispatchToProps: MapDispatchToProps<
//   ConnectDispatchProps,
//   EditableHocComponentProps
// > = (dispatch) => ({
//   setTabTitle: (title: string, path: string) =>
//     dispatch(EditableActions.updateTabTitle({ title, path })),
//   dispatch: (action: any) => dispatch(action)
// });

// export const editable = (
//   Component: ComponentType<any>,
//   options: EditableHocOptions
// ) => {
//   class EditableHoc extends PureComponent<Props, State> {
//     constructor(props: Props) {
//       super(props);

//       this.fetchExternalEntity = this.fetchExternalEntity.bind(this);
//       this.setTabTitle = this.setTabTitle.bind(this);
//       this.updateCache = this.updateCache.bind(this);
//       this.setHasChanges = this.setHasChanges.bind(this);
//       this.setSingleState = this.setSingleState.bind(this);

//       this.state = {
//         loading: false
//       };
//     }

//     public componentDidMount() {
//       if (!this.props.componentState && !this.state.loading) {
//         this.fetchExternalEntity();
//       } else {
//         this.setSingleState();
//       }
//     }

//     public componentDidUpdate(prevProps: Props) {
//       if (prevProps.entityId !== this.props.entityId && !this.state.loading) {
//         this.fetchExternalEntity();
//       }
//     }

//     public render() {
//       const { entityId, componentState } = this.props;

//       if (!entityId || !componentState || this.state.loading) {
//         return <div>...loading</div>;
//       }

//       return (
//         <Component
//           setTabTitle={this.setTabTitle}
//           updateCache={this.updateCache}
//           setHasChanges={this.setHasChanges}
//           key={this.props.match.params.id}
//         />
//       );
//     }

//     private async fetchExternalEntity() {
//       try {
//         const { match, dispatch } = this.props;
//         const { id } = match.params;
//         const { thunk } = options;
//         await dispatch(thunk(id));
//       } catch (error) {
//         throw error;
//       } finally {
//         this.setState({ loading: false });
//       }
//     }

//     private setTabTitle(title: string) {
//       const { route: routeName } = options;
//       const { entityId: id } = this.props;
//       const path = route(routeName, { id });

//       this.props.setTabTitle(title, path);
//     }

//     private updateCache() {
//       // TODO
//     }

//     private setHasChanges() {
//       // TODO
//     }

//     private setSingleState() {
//       const {} = options;
//     }
//   }

//   return withRouter(
//     connect(
//       mapStateToProps,
//       mapDispatchToProps
//     )(EditableHoc)
//   );
// };

import { REQUEST } from "@haywork/constants";
import { EditableCalleeType } from "@haywork/enum";
import { ErrorApiErrorComponent } from "@haywork/modules/error";
import { PageLoader } from "@haywork/modules/shared";
import { ConfirmConfig } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { connect } from "react-redux";
import { match, RouteComponentProps, withRouter } from "react-router-dom";
import {
  EditableHocContainerProps,
  editableMapDispatchToProps,
  editableMapStateToProps,
} from "../../containers/editable-hoc.container";
import { RootEntityType } from "@haywork/api/event-center";
import PresenceWrapper from "../presence-wrapper";

const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

interface EditableHocComponentProps {
  cache: any;
  preppedForSave: boolean;
  hasChanges: boolean;
  callee?: {
    type: EditableCalleeType;
    id: string;
    state: string;
  };
  setTabTitle: (title: string) => void;
  updateCache: (cache: any, pathname: string, ignoreChanges?: boolean) => void;
  setHasChanges: () => void;
}
interface EditableHocComponentState {
  loading: boolean;
  cache: any;
  preppedForSave: boolean;
  hasError: boolean;
  callee?: {
    type: EditableCalleeType;
    id: string;
    state: string;
  };
  hasChanges: boolean;
  match: match<any>;
}
interface EditableHocOptions {
  icon: string;
  thunk: Function;
  status: string;
  statePath: string;
  action: Function;
  route: string;
  initialTabTitle?: string;
  initialState?: any;
  confirm?: ConfirmConfig;
  objectName?: string | string[];
  splitObjectNameAsParams?: boolean;
  entityType: RootEntityType | null;
}

type CombinedEditableHocProps = EditableHocComponentProps &
  EditableHocContainerProps &
  RouteComponentProps<any>;

const editable = <P, S>(
  WrappedComponent: React.ComponentType<any>,
  options: EditableHocOptions
) => {
  class EditableComponent extends React.Component<
    P & CombinedEditableHocProps,
    EditableHocComponentState
  > {
    constructor(props) {
      super(props);

      const { id, source } = this.props.match.params;
      const pathname = route(options.route, { id, source });
      const editable = this.props.states.find((s) => s.path === pathname);

      this.state = {
        loading: true,
        cache: value(editable, "componentState", null),
        preppedForSave: false,
        hasError: false,
        hasChanges: value(editable, "hasChanges", false),
        match: this.props.match,
      };

      this.setTabTitleHandler = this.setTabTitleHandler.bind(this);
      this.updateCacheHandler = this.updateCacheHandler.bind(this);
      this.setHasChangesHandler = this.setHasChangesHandler.bind(this);
      this.closeTab = this.closeTab.bind(this);
    }

    public UNSAFE_componentWillMount() {
      const { id, source } = this.props.match.params;
      const pathname = route(options.route, { id, source });

      const { icon, initialTabTitle, initialState } = options;
      const title = initialTabTitle || "...";
      const state = initialState || null;
      this.props.addEditableItem(
        title,
        icon,
        pathname,
        state,
        options.confirm,
        options.entityType,
        this.props.match.params.id
      );
    }

    public render() {
      if (
        this.state.loading ||
        this.props.currentComponentStateEntityId !== this.props.match.params.id
      )
        return <PageLoader loading fullscreen />;
      if (this.state.hasError) return <ErrorApiErrorComponent />;

      return (
        <PresenceWrapper
          entityId={this.props.match.params.id}
          entityType={options.entityType}
          inEditMode={/\/edit/gi.test(location.pathname)}
          onClose={this.closeTab}
        >
          <WrappedComponent
            {...this.state}
            setTabTitle={this.setTabTitleHandler}
            updateCache={this.updateCacheHandler}
            setHasChanges={this.setHasChangesHandler}
            key={this.props.match.params.id}
          />
        </PresenceWrapper>
      );
    }

    public componentDidMount() {
      if (!this.state.cache) {
        const { id, source } = this.props.match.params;
        this.props.dispatch(options.thunk(id, source));
      } else {
        let cache = this.state.cache;
        if (!!options.objectName) {
          cache = {};

          switch (true) {
            case isArray(options.objectName): {
              (options.objectName as string[]).map((key) => {
                cache[key] = this.state.cache[key];
              });
              break;
            }
            default: {
              cache[options.objectName as string] = this.state.cache;
              break;
            }
          }
        }

        if (!!options.splitObjectNameAsParams) {
          this.props.dispatch(
            options.action(...Object.keys(cache).map((key) => cache[key]))
          );
        } else {
          this.props.dispatch(options.action(cache));
        }
        this.setState({ loading: false });
      }
    }

    public UNSAFE_componentWillReceiveProps(
      nextProps: Readonly<P & CombinedEditableHocProps>
    ) {
      if (
        !!this.state.loading &&
        get(nextProps.store, options.status) === REQUEST.SUCCESS
      ) {
        const cache = get(nextProps.store, options.statePath);
        const { id, source } = nextProps.match.params;
        const pathname = route(options.route, {
          id,
          source,
        });

        const ref = nextProps.states.find((state) => state.path === pathname);
        const ignoreChanges = !!ref && ref.hasChanges ? false : true;

        this.props.setCacheWithPath(cache, pathname, ignoreChanges);
        this.setState({ loading: false, cache, hasError: false });
      }

      if (
        !!this.state.loading &&
        get(nextProps.store, options.status) === REQUEST.ERROR
      ) {
        const { id, source } = nextProps.match.params;
        const pathname = route(options.route, {
          id,
          source,
        });
        this.setState({ loading: false, hasError: true });
        this.props.setTabTitle("couldNotLoadDetail", pathname);
      }

      this.shouldUpdateCache(nextProps);
      this.updatePreppedForSaveStatus(nextProps);
    }

    private setTabTitleHandler(title: string) {
      const { id, source } = this.props.match.params;
      const pathname = route(options.route, { id, source });
      this.props.setTabTitle(title, pathname);
    }

    private updateCacheHandler(
      componentState: any,
      path: string,
      ignoreChanges: boolean = false
    ) {
      const { id, source } = this.props.match.params;
      const pathname = path || route(options.route, { id, source });

      let shouldIgnoreChanges = ignoreChanges;
      if (shouldIgnoreChanges) {
        const editable = this.props.states.find(
          (state) => state.path === pathname
        );
        if (!!editable && editable.hasChanges) {
          shouldIgnoreChanges = false;
        }
      }

      this.props.setCacheWithPath(componentState, pathname, ignoreChanges);
    }

    private setHasChangesHandler() {
      const { id, source } = this.props.match.params;
      const pathname = route(options.route, { id, source });

      this.props.hasChanges(pathname);
    }

    private shouldUpdateCache(
      nextProps: Readonly<P & CombinedEditableHocProps>
    ) {
      const { id, source } = nextProps.match.params;
      const pathname = route(options.route, { id, source });
      const { id: oldId, source: oldSource } = this.props.match.params;
      const oldPathname = route(options.route, {
        id: oldId,
        source: oldSource,
      });
      const ref = nextProps.states.find((state) => state.path === pathname);

      if (!!ref && !!ref.calleeType && !!ref.calleeId) {
        const { calleeId: id, calleeType: type, calleeState: state } = ref;
        this.setState({ callee: { id, type, state } });
      }

      if (pathname !== oldPathname) {
        const componentState = !!ref
          ? ref.componentState
          : options.initialState || null;
        this.setState({ loading: true });

        if (!componentState) {
          const { id, source } = nextProps.match.params;
          this.props.dispatch(options.thunk(id, source));
        } else {
          let cache = componentState;
          if (!!options.objectName) {
            cache = {};

            switch (true) {
              case isArray(options.objectName): {
                (options.objectName as string[]).map((key) => {
                  cache[key] = this.state.cache[key];
                });
                break;
              }
              default: {
                cache[options.objectName as string] = this.state.cache;
                break;
              }
            }
          }

          if (!!options.splitObjectNameAsParams) {
            this.props.dispatch(
              options.action(...Object.keys(cache).map((key) => cache[key]))
            );
          } else {
            this.props.dispatch(options.action(cache));
          }
        }
      }
    }

    private closeTab() {
      const { id, source } = this.props.match.params;
      const pathname = route(options.route, { id, source });
      this.props.closeTab(pathname);
    }

    private updatePreppedForSaveStatus(
      nextProps: Readonly<P & CombinedEditableHocProps>
    ) {
      const { id, source } = nextProps.match.params;
      const pathname = route(options.route, { id, source });
      const { id: oldId, source: oldSource } = this.props.match.params;
      const oldPathname = route(options.route, {
        id: oldId,
        source: oldSource,
      });

      if (pathname === oldPathname) {
        const ref = nextProps.states.find((state) => state.path === pathname);
        if (!ref) return;
        const { preppedForSave, hasChanges } = ref;
        if (!!preppedForSave) this.setState({ preppedForSave, hasChanges });
        if (!preppedForSave && this.state.preppedForSave)
          this.setState({ preppedForSave });
      }
    }
  }

  return withRouter(
    connect<any, any, any>(
      editableMapStateToProps,
      editableMapDispatchToProps
    )(EditableComponent)
  );
};

type EditableHocProps = EditableHocComponentProps;
export {
  EditableHocComponentProps,
  EditableHocOptions,
  editable,
  EditableHocProps,
};
