import * as ActionType from "./app-settings.types";
import { REDUX } from "@haywork/constants";

const setSettings = (payload: ActionType.Settings) => ({
  type: REDUX.APPSETTINGS.SET_SETTINGS,
  ...payload
});

export const AppSettingsActions = {
  setSettings
};
