import {
  AppClientCategory,
  AppClientSnapShot,
  AppClientStatus,
  RemunerationCategory,
} from "@haywork/api/authorization";
import PageHeader from "@haywork/components/ui/page-header";
import { APPXCHANGEROUTES, REQUEST } from "@haywork/constants";
import { AppXchangePartnerWidget } from "@haywork/modules/app-xchange";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { SettingsAppXchangeOverviewContainerProps } from "@haywork/modules/settings/modules/app-xchange";
import {
  FontStyle,
  InfiniteScroll,
  ResourceText,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import noop from "lodash-es/noop";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Client } from "./client.component";

const styles = require("./overview.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsAppXchangeOverviewComponentProps {}
interface State {}
type Props = SettingsAppXchangeOverviewComponentProps &
  SettingsAppXchangeOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsAppXchangeOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.renderFilterCategory = this.renderFilterCategory.bind(this);
    this.renderRemunerationCategory =
      this.renderRemunerationCategory.bind(this);
    this.onPartnerClickHandler = this.onPartnerClickHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getAppClients();
  }

  public render() {
    const {
      selectedClientCategories,
      clientCategories,
      clients,
      remunerationCategories,
      selectedRemunerationCategories,
      specials,
    } = this.props;
    const everythingStyle = classNames("filter", {
      active:
        !selectedClientCategories.length &&
        !selectedRemunerationCategories.length,
    });
    const specialsCount = specials.length || 0;
    const specialsWidth = specialsCount * 240 + (specialsCount + 1) * 16;

    return (
      <div styleName="overview">
        <PageHeader title="appXchangeSettingsTitle" />

        <div styleName="overview__header">
          <div styleName={everythingStyle} onClick={this.props.clearFilters}>
            <ResourceText
              resourceKey="everything"
              fontStyle={FontStyle.UcFirst}
            />
          </div>

          {remunerationCategories.map(this.renderRemunerationCategory)}
          {clientCategories.map(this.renderFilterCategory)}
        </div>

        <div styleName="overview__list">
          {!!specialsCount && (
            <React.Fragment>
              <h2 styleName="section-title">
                <ResourceText resourceKey="appXchangeSettings.SpecialsTitle" />
              </h2>
              <div styleName="overview__specials">
                <div styleName="inner" style={{ width: specialsWidth }}>
                  {specials.map((client) => (
                    <ErrorBoundary key={client.id}>
                      <AppXchangePartnerWidget
                        partner={client}
                        onClick={this.onPartnerClickHandler}
                      />
                    </ErrorBoundary>
                  ))}
                </div>
              </div>
            </React.Fragment>
          )}

          {!!clients.length && (
            <h2 styleName="section-title">
              <ResourceText resourceKey="appXchangeSettings.ClientsTitle" />
            </h2>
          )}

          {clients.map((client, idx) => (
            <ErrorBoundary key={client.id}>
              <Client
                idx={idx}
                client={client}
                employee={this.props.employee}
                currentRealestateAgency={this.props.currentRealestateAgency}
                onClick={this.onPartnerClickHandler}
              />
            </ErrorBoundary>
          ))}

          {this.props.clientsStatus === REQUEST.SUCCESS &&
            !clients.length &&
            !specials.length && (
              <Ui.EmptyState
                type={UiEmptyStateType.List}
                stickman={UiEmptyStateStickMan.NoAssignments}
                title="emptyStateNoAppClientsTitle"
                subtitle="emptyStateNoAppClientsText"
              />
            )}

          {this.props.clientsStatus === REQUEST.PENDING && <Ui.Loaders.List />}
        </div>
      </div>
    );
  }

  private renderFilterCategory(category: AppClientCategory, idx: number) {
    const { selectedClientCategories } = this.props;
    const active = selectedClientCategories.indexOf(category) !== -1;
    const filterStyle = classNames("filter", { active });

    return (
      <div
        styleName={filterStyle}
        onClick={() => this.props.toggleFilter(category)}
        key={idx}
      >
        <ResourceText
          masterKey="appClientCategoryOptions"
          resourceKey={category.toString()}
        />
      </div>
    );
  }

  private renderRemunerationCategory(
    category: RemunerationCategory,
    idx: number
  ) {
    const { selectedRemunerationCategories } = this.props;
    const active = selectedRemunerationCategories.indexOf(category) !== -1;
    const filterStyle = classNames("filter", { active });

    return (
      <div
        styleName={filterStyle}
        onClick={() => this.props.toggleRemuneration(category)}
        key={idx}
      >
        <ResourceText
          masterKey="remunerationCategoryOptions"
          resourceKey={category.toString()}
        />
      </div>
    );
  }

  private onPartnerClickHandler(client: AppClientSnapShot) {
    // Only navigate to partners which are in production
    if (client.status === AppClientStatus.InProduction) {
      const { id } = client;
      const path = route(APPXCHANGEROUTES.DETAIL.URI, { id });

      this.props.navigate(path);
    }
  }
}
