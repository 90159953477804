import * as React from "react";

import { ResourceText } from "@haywork/modules/shared";
import { EventNotificationActionType } from "@haywork/modules/event-center/components/modal-message/footer.component";
import {
  LinkedMessage,
  LinkedObjectAssignment,
  LinkedContactPerson,
  LinkedSearchAssignment,
  LinkedAgendaItem,
  LinkedDraft,
  LinkedAcquisitionObjectAssignment
} from "@haywork/api/event-center";
import get from "lodash-es/get";
import head from "lodash-es/head";

export interface ActionButtonProps {
  type: EventNotificationActionType;
  linkedMessages: LinkedMessage[];
  linkedObjectAssignments: LinkedObjectAssignment[];
  linkedAcquisitionObjectAssignments: LinkedAcquisitionObjectAssignment[];
  linkedContactPersons: LinkedContactPerson[];
  linkedSearchAssignments: LinkedSearchAssignment[];
  linkedAgendaItems: LinkedAgendaItem[];
  linkedDrafts: LinkedDraft[];
  canSendEmail: boolean;
  actionClick: (type: EventNotificationActionType) => void;
}

export const ActionButton = (props: ActionButtonProps) => {
  switch (props.type) {
    case EventNotificationActionType.OpenAppointment:
      if (props.linkedAgendaItems.length !== 1) return null;
      break;
    case EventNotificationActionType.OpenAssignment:
      if (props.linkedObjectAssignments.length !== 1) return null;
      break;
    case EventNotificationActionType.OpenAcquisitionAssignment:
      if (props.linkedAcquisitionObjectAssignments.length !== 1) return null;
      break;
    case EventNotificationActionType.OpenRelation:
      if (!props.linkedContactPersons.length) return null;
      break;
    case EventNotificationActionType.OpenSearchAssignment:
      if (props.linkedSearchAssignments.length !== 1) return null;
      break;
    case EventNotificationActionType.CreateRelation:
      if (!!props.linkedContactPersons.length) return null;
      break;
    case EventNotificationActionType.CreateEmail:
      const contactPersons = !!props.linkedContactPersons
        ? props.linkedContactPersons
        : [];
      const messages = !!props.linkedMessages ? props.linkedMessages : [];

      const personEmails = contactPersons.filter((person) => !!person.email);
      const messageEmails = messages.filter((message) => message.emailAddress);

      if (
        !props.canSendEmail ||
        (!personEmails.length && !messageEmails.length)
      )
        return null;
    case EventNotificationActionType.OpenDraft:
      if (props.linkedDrafts.length !== 1) return null;
      break;
    case EventNotificationActionType.OpenLink:
      if (!get(head(props.linkedMessages), "infoUrl")) return null;
      break;
    default:
      break;
  }

  return (
    <button type="button" onClick={() => props.actionClick(props.type)}>
      <ResourceText
        masterKey="eventNotificationActionType"
        resourceKey={props.type.toString()}
      />
    </button>
  );
};
