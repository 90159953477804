import * as React from "react";
import { FC, memo, useEffect, useState, useMemo } from "react";
import { PublicationsContainerProps } from "./publications.container";
import {
  PublicationSnapShot,
  PublicationStatus,
  ObjectAssignment,
  ProjectAssignment
} from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { Ui } from "@haywork/modules/ui";
import Publication from "./components/publication";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";
import { SETTINGSROUTES } from "@haywork/constants";

const styles = require("./style.scss");

export type PublicationsComponentProps = {
  assignment: ObjectAssignment | ProjectAssignment;
  type?: string;
};
type Props = PublicationsComponentProps & PublicationsContainerProps;

export const PublicationsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ getPublications, assignment, navigate, type }) => {
      const [publications, setPublications] = useState<PublicationSnapShot[]>(
        []
      );
      const [loading, setLoading] = useState(true);
      const { id: assignmentId } = assignment;

      const fetchPublications = async () => {
        try {
          const contracts = await getPublications(assignmentId);
          setPublications(contracts);
        } finally {
          setLoading(false);
        }
      };

      const navigateToMediaPartners = () => {
        navigate(SETTINGSROUTES.MEDIAPARTNERS.URI);
      };

      useEffect(() => {
        fetchPublications();
      }, [assignmentId]);

      const activePublications = useMemo(() => {
        return publications.filter(
          (publication) =>
            publication.mediaPartnerIsActive &&
            publication.publicationStatus !== PublicationStatus.Inactive
        );
      }, [publications]);

      const inActivePublications = useMemo(() => {
        return publications.filter(
          (publication) =>
            publication.mediaPartnerIsActive &&
            publication.publicationStatus === PublicationStatus.Inactive
        );
      }, [publications]);

      const inActiveMediaPartnerCount = useMemo(() => {
        return publications.filter(
          (publication) => !publication.mediaPartnerIsActive
        ).length;
      }, [publications]);

      return (
        <div styleName="publications">
          {/* Active publications */}
          <div styleName="group">
            <h2>
              <I18n value="publications.active.title" />
            </h2>
            {loading && <Ui.Loaders.List />}
            {activePublications.map((publication) => (
              <ErrorBoundary key={publication.id + publication.mediaPartnerId}>
                <Publication
                  publication={publication}
                  assignment={assignment}
                  onUpdatedPublication={fetchPublications}
                  type={type}
                />
              </ErrorBoundary>
            ))}
            {!loading && !activePublications.length && (
              <EmptyState
                icon="cloud-download"
                title="publications.active.emptyState.title"
                subTitle="publications.active.emptyState.subTitle"
              />
            )}
          </div>

          {/* Inactive available publications */}
          {!loading &&
            (!!inActiveMediaPartnerCount || !!inActivePublications.length) && (
              <div styleName="group">
                <h2>
                  <I18n value="publications.inactive.title" />
                </h2>
                {inActivePublications.map((publication) => (
                  <ErrorBoundary
                    key={publication.id + publication.mediaPartnerId}
                  >
                    <Publication
                      publication={publication}
                      assignment={assignment}
                      onUpdatedPublication={fetchPublications}
                    />
                  </ErrorBoundary>
                ))}

                {!!inActivePublications.length && !!inActiveMediaPartnerCount && (
                  <div styleName="extra-media-partners">
                    <span className="as-link" onClick={navigateToMediaPartners}>
                      <I18n
                        value="publications.inactive.navigateToMediaPartners"
                        values={{ count: inActiveMediaPartnerCount }}
                      />
                    </span>
                  </div>
                )}

                {!inActivePublications.length && (
                  <EmptyState
                    icon="cloud-showers"
                    title="publications.inactive.emptyState.title"
                    subTitle="publications.inactive.emptyState.subTitle"
                    subTitleValues={{ count: inActiveMediaPartnerCount }}
                    action={
                      <Button
                        category="primary"
                        label="publications.inactive.emptyState.action"
                        onClick={navigateToMediaPartners}
                      />
                    }
                  />
                )}
              </div>
            )}
        </div>
      );
    }
  )
);
