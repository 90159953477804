import Pill from "@haywork/components/ui/pill";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";
import {
  CheckboxFilter,
  Filter,
  MappedCheckboxValue,
} from "@haywork/modules/filter";
import {
  SearchAssignmentListItemComponent,
  SearchAssignmentOverviewContainerProps,
  SearchAssignmentOverviewHeadersComponent,
} from "@haywork/modules/search-assignment";
import {
  InfiniteScroll,
  ListLoader,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { SearchAssignmentsFilters } from "@haywork/stores/search-assignment";
import { SimpleLabelOriginal } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./overview.component.scss");

export interface SearchAssignmentOverviewComponentProps {}
interface State {
  toggleShowSidebar: boolean;
}
type Props = SearchAssignmentOverviewComponentProps &
  SearchAssignmentOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      toggleShowSidebar: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onChangeFilterHandler = this.onChangeFilterHandler.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  public componentDidMount() {
    this.props.getSearchAssignments(true);
  }

  public render() {
    const combined = classNames("search-assignments ", {
      collapsed: this.state.toggleShowSidebar,
    });
    const toggleIcon = classNames(
      "fal fa-fw",
      this.state.toggleShowSidebar ? "fa-filter" : "fa-arrow-left"
    );

    return (
      <div styleName={combined}>
        <div styleName="search-assignments__header">
          <div styleName="clear-filters">
            {this.props.selectedFilters.length === 0 ? (
              <ResourceText resourceKey="noActiveFilters" />
            ) : (
              <a onClick={this.clearAllFilters}>
                <ResourceText resourceKey="deleteFilters" />
              </a>
            )}
          </div>
          <div styleName="toggle" onClick={this.toggleSidebar}>
            <div styleName="trigger">
              <i className={toggleIcon} />
            </div>
          </div>
          <div styleName="selected-filters">
            {this.props.selectedFilters.map((selectedFilter, idx) => (
              <Pill
                key={idx}
                label={selectedFilter.label}
                labelValues={{ value: selectedFilter.value }}
                color={Colors.Primary}
                solid
                deletable
                onClick={() =>
                  this.onSelectedFilterClickHandler(selectedFilter)
                }
              />
            ))}
          </div>
        </div>
        <div styleName="search-assignments__body">
          <div styleName="search-assignments__sidebar">
            <Filter changeFilter={this.onChangeFilterHandler}>
              <CheckboxFilter
                name="filterByRealEstateGroups"
                value={
                  this.props.searchAssignmentsFilters.filterByRealEstateGroups
                }
                values={this.props.realEstateGroups}
                title="typeOfAssignment"
              />
              <CheckboxFilter
                name="offerTypes"
                value={this.props.searchAssignmentsFilters.offerTypes}
                values={this.props.offerTypes}
                title="kindOfAssignment"
              />
              <CheckboxFilter
                name="partTypes"
                value={this.props.searchAssignmentsFilters.partTypes}
                values={this.props.partTypes}
                title="partType"
                canToggle
                visible={false}
                subtitle={
                  this.props.searchAssignmentsFilters.partTypes &&
                  this.props.searchAssignmentsFilters.partTypes.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.partTypes,
                        this.props.searchAssignmentsFilters.partTypes,
                        this.removePartTypeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.partTypes)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="bogTypes"
                value={this.props.searchAssignmentsFilters.bogTypes}
                values={this.props.bogTypes}
                title="bogType"
                canToggle
                visible={false}
                subtitle={
                  this.props.searchAssignmentsFilters.bogTypes &&
                  this.props.searchAssignmentsFilters.bogTypes.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.bogTypes,
                        this.props.searchAssignmentsFilters.bogTypes,
                        this.removeBogTypeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.bogTypes)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="alvTypes"
                value={this.props.searchAssignmentsFilters.alvTypes}
                values={this.props.alvTypes}
                title="alvType"
                canToggle
                visible={false}
                subtitle={
                  this.props.searchAssignmentsFilters.alvTypes &&
                  this.props.searchAssignmentsFilters.alvTypes.length > 0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.alvTypes,
                        this.props.searchAssignmentsFilters.alvTypes,
                        this.removeAlvTypeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.alvTypes)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="matchmailPeriods"
                value={this.props.searchAssignmentsFilters.matchmailPeriods}
                values={this.props.matchmailPeriods}
                title="matchmailPeriods"
                canToggle
                visible={false}
                subtitle={
                  this.props.searchAssignmentsFilters.matchmailPeriods &&
                  this.props.searchAssignmentsFilters.matchmailPeriods.length >
                    0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.matchmailPeriods,
                        this.props.searchAssignmentsFilters.matchmailPeriods,
                        this.removeMatchmailTypeFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.matchmailPeriods)
                }
                hideCheckedItems
              />
              <CheckboxFilter
                name="assignmentPhases"
                value={this.props.searchAssignmentsFilters.assignmentPhases}
                values={this.props.assignmentPhases}
                title="status"
                canToggle
                visible={false}
                subtitle={
                  this.props.searchAssignmentsFilters.assignmentPhases &&
                  this.props.searchAssignmentsFilters.assignmentPhases.length >
                    0
                    ? this.renderSelectedItemsSubtitle(
                        this.props.assignmentPhases,
                        this.props.searchAssignmentsFilters.assignmentPhases,
                        this.removeAssignmentPhaseFromFilter.bind(this)
                      )
                    : this.renderSubtitle(this.props.assignmentPhases)
                }
                hideCheckedItems
              />
            </Filter>
          </div>
          <div styleName="search-assignments__view">
            <div styleName="search-assignments__list">
              {/* Loader */}
              {this.props.searchAssignmentsStatus === REQUEST.PENDING &&
                this.props.searchAssignments.length === 0 && (
                  <PageLoader loading fullscreen />
                )}

              {/* Empty state */}
              {this.props.searchAssignments &&
                this.props.searchAssignmentsStatus === REQUEST.SUCCESS &&
                this.props.searchAssignmentsTotal === 0 && (
                  <Ui.EmptyState
                    type={UiEmptyStateType.List}
                    stickman={UiEmptyStateStickMan.NoAroItems}
                    title="emptyStateNoSearchAssignmentsTitle"
                    subtitle="emptyStateNoSearchAssignmentsText"
                  />
                )}

              {/* Searchassignments */}
              <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
                <EntityListStatus
                  list="searchAssignments"
                  onClick={() => this.props.getSearchAssignments(true)}
                  style={{ top: 35 }}
                />

                <SearchAssignmentOverviewHeadersComponent
                  selectedSortedHeaderType={this.props.selectedHeaderSort}
                  headerSorted={this.props.setSelectedheaderSort}
                />
                {this.props.searchAssignments.map((searchAssignment, idx) => (
                  <ErrorBoundary key={idx}>
                    <SearchAssignmentListItemComponent
                      searchAssignment={searchAssignment}
                      zebra={idx % 2 === 0}
                      onClick={this.props.goToSearchAssignment}
                    />
                  </ErrorBoundary>
                ))}

                {this.props.canLoadMore && <ListLoader />}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public toggleSidebar() {
    this.setState({ toggleShowSidebar: !this.state.toggleShowSidebar });
  }

  private clearAllFilters() {
    this.props.clearAllFilters();
    this.props.getSearchAssignments(true);
  }

  private onScrollEndHandler() {
    if (
      this.props.searchAssignmentsStatus === REQUEST.PENDING ||
      !this.props.canLoadMore
    )
      return;
    this.props.getSearchAssignments();
  }

  private onChangeFilterHandler(values: any) {
    if (this.props.searchAssignmentsStatus === REQUEST.PENDING) return;
    this.props.setSearchAssignmentsFilters(values);
    this.props.getSearchAssignments(true);
  }

  private onSelectedFilterClickHandler(filter: SimpleLabelOriginal) {
    this.props.clearFilter(filter);
  }

  private removeAssignmentPhaseFromFilter(type) {
    const filters: SearchAssignmentsFilters = {
      ...this.props.searchAssignmentsFilters,
    };
    const idx = filters.assignmentPhases.indexOf(type);
    filters.assignmentPhases.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removePartTypeFromFilter(type) {
    const filters: SearchAssignmentsFilters = {
      ...this.props.searchAssignmentsFilters,
    };
    const idx = filters.partTypes.indexOf(type);
    filters.partTypes.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeBogTypeFromFilter(type) {
    const filters: SearchAssignmentsFilters = {
      ...this.props.searchAssignmentsFilters,
    };
    const idx = filters.bogTypes.indexOf(type);
    filters.bogTypes.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeAlvTypeFromFilter(type) {
    const filters: SearchAssignmentsFilters = {
      ...this.props.searchAssignmentsFilters,
    };
    const idx = filters.alvTypes.indexOf(type);
    filters.alvTypes.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private removeMatchmailTypeFromFilter(type) {
    const filters: SearchAssignmentsFilters = {
      ...this.props.searchAssignmentsFilters,
    };
    const idx = filters.matchmailPeriods.indexOf(type);
    filters.matchmailPeriods.splice(idx, 1);
    this.onChangeFilterHandler(filters);
  }

  private renderSubtitle(
    values: MappedCheckboxValue[]
  ): React.ReactElement<any>[] {
    const mapped = values.slice(0, 10);
    return mapped.map((val, idx) => (
      <span key={idx}>
        <ResourceText resourceKey={val.label} />
        {idx !== mapped.length - 1 ? ", " : ""}
      </span>
    ));
  }

  private renderSelectedItemsSubtitle(
    values: MappedCheckboxValue[],
    selectedValues: any[],
    removeMethod: (value) => void
  ) {
    const selectedMappedValues = values.filter((mappedValue) => {
      const idx = selectedValues.indexOf(mappedValue.value);
      return idx !== -1;
    });

    return (
      <div className="subtitle__selectedItems" styleName="selectedItems">
        {selectedMappedValues.map((value, index) => (
          <span
            key={value + "_" + index}
            onClick={(event) => {
              event.stopPropagation();
              removeMethod(value.value);
            }}
            className={styles.addedResult}
          >
            <span className={styles.remove}>
              <i className="fal fa-check" />
            </span>
            <ResourceText resourceKey={value.label} />
          </span>
        ))}
      </div>
    );
  }
}
