import { MultiListRowProps } from "@haywork/components/ui/multi-list";
import classNames from "classnames";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import {
  ImagePlaceholder,
  TextPlaceholder,
} from "@haywork/components/ui/empty-state";

const styles = require("./style.scss");

type Props = MultiListRowProps;

export const EmptyRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ style, zebra }) => {
    return (
      <div style={style} styleName={classNames("row", { zebra })}>
        <div styleName="column thumb">
          <ImagePlaceholder width={128} height={96} />
        </div>
        <div styleName="column meta">
          <TextPlaceholder
            numberOfLines={3}
            style={{
              maxWidth: 300,
              height: "100%",
              width: "100%",
            }}
          />
        </div>
      </div>
    );
  })
);
