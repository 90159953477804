import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import { ObjectTypesDetailDossierContainer, ObjectTypesDetailGeneralContainer, ObjectTypesEditContainer, ObjectTypesDetailBuildnumbersContainer, ObjectTypesDetailNetworkContainer } from "@haywork/modules/object-types";

const route = RouteUtil.mapStaticRouteValues;

export const ObjectTypesDetailRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ OBJECTTYPESROUTES.DETAIL.URI } render={ () => <Redirect to={ route(OBJECTTYPESROUTES.GENERAL.URI, { id }) } /> } />
    <Route exact path={ OBJECTTYPESROUTES.GENERAL.URI } component={ ObjectTypesDetailGeneralContainer } />
    <Route exact path={ OBJECTTYPESROUTES.TIMELINE.URI } render={ () => <div>Timeline</div> } />
    <Route exact path={ OBJECTTYPESROUTES.NETWORK.URI } component={ ObjectTypesDetailNetworkContainer } />
    <Route exact path={ OBJECTTYPESROUTES.DOSSIER.URI } component={ ObjectTypesDetailDossierContainer } />
    <Route exact path={ OBJECTTYPESROUTES.BUILD_NUMBERS.URI } component={ ObjectTypesDetailBuildnumbersContainer } />
    <Route path={ OBJECTTYPESROUTES.EDIT.URI } component={ ObjectTypesEditContainer } />
  </Switch>;
};
