import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { Ui } from "@haywork/modules/ui";
import * as React from "react";
import { FC, memo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { SettingsCompanySettingsContainerProps } from "./company-settings.container";
import Logo from "./components/logo";
import { Input } from "@haywork/modules/form";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const styles = require("./style.scss");

export interface SettingsCompanySettingsComponentProps {}
type Props = SettingsCompanySettingsComponentProps &
  SettingsCompanySettingsContainerProps;

export const SettingsCompanySettingsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      companyLogo,
      smsFromFieldShortCompanyName: pSmsFromFieldShortCompanyName,
      settings: pSettings,
      loading,
      setCompanyLogo,
      saveCompanySettings,
    }) => {
      const maxChars = 11;

      const [logo, setLogo] = useState(companyLogo);
      const [smsFromFieldShortCompanyName, setsmsFromFieldShortCompanyName] =
        useState(pSmsFromFieldShortCompanyName);
      const canSubmit =
        pSmsFromFieldShortCompanyName !== smsFromFieldShortCompanyName ||
        logo !== companyLogo;

      const onSave = async () => {
        if (loading) return;
        try {
          let updatedSettings = pSettings;

          if (logo !== companyLogo) {
            updatedSettings = await setCompanyLogo(logo);
          }

          const settings = {
            ...updatedSettings,
            smsFromFieldShortCompanyName,
          };

          if (pSmsFromFieldShortCompanyName !== smsFromFieldShortCompanyName) {
            await saveCompanySettings(settings);
          }
        } catch (error) {
          throw error;
        }
      };

      return (
        <div styleName="company-settings">
          <PageHeader
            title="settings.companySettings.title"
            subTitle="settings.companySettings.subTitle"
            actions={
              <Button
                label="save"
                disabled={loading || !canSubmit}
                onClick={onSave}
              />
            }
          />

          <div styleName="listHeader">
            <I18n value="settings.companySettings.logo.title" />
          </div>
          <div styleName="container">
            <div styleName="row">
              <div styleName="column">
                <I18n value="settings.companySettings.logo.body" asHtml />
                <div>
                  <Logo logo={logo} onChange={setLogo} />
                </div>
              </div>
            </div>
          </div>
          <FeatureSwitch feature="SMSSENDER">
            <div styleName="listHeader">
              <I18n value="settings.companySettings.sms.name.title" asHtml />
            </div>
            <div styleName="container">
              <div styleName="row">
                <div styleName="column">
                  <I18n value="settings.companySettings.sms.name.body" asHtml />
                  <div className="form__row">
                    <label htmlFor="settingsSmsender">
                      <I18n
                        value="settings.companySettings.sms.label"
                        values={{ maxChars }}
                      />
                    </label>
                    <div styleName="input">
                      <Input.Text
                        name="settings.companySettings.sms.name.input"
                        value={smsFromFieldShortCompanyName}
                        asSingleInput
                        maxLength={maxChars}
                        onChange={setsmsFromFieldShortCompanyName}
                        fireAllChanges
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FeatureSwitch>
        </div>
      );
    }
  )
);
