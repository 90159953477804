import { MAINROUTES, SETTINGSROUTES } from "@haywork/constants";
import { FeatureRoute } from "@haywork/modules/feature-switch";
import {
  OfficeOverviewContainer,
  RelationGroupsContainer,
  SettingsOverviewContainer,
} from "@haywork/modules/settings";
import { SettingsAppXchangeOverviewContainer } from "@haywork/modules/settings/modules/app-xchange";
import { SettingsAssignmentsContainer } from "@haywork/modules/settings/modules/assignments";
import { SettingsAcquisitionAssignmentsContainer } from "@haywork/modules/settings/modules/acquisition-assignments";
import CompanySettings from "@haywork/modules/settings/modules/company-settings";
import { SettingsDossierFoldersOverview } from "@haywork/modules/settings/modules/dossier-folders";
import { SettingsEmailOverviewContainer } from "@haywork/modules/settings/modules/email";
import { SettingsEmailSignaturesOverview } from "@haywork/modules/settings/modules/email-signatures";
import { SettingsEmailTemplatesOverview } from "@haywork/modules/settings/modules/email-templates";
import { EmployeeOverviewContainer } from "@haywork/modules/settings/modules/employees";
import { SettingsEventCenterContainer } from "@haywork/modules/settings/modules/event-center";
import {
  SettingsFinancialAdministrationProductsContainer,
  SettingsFinancialAdministrationsGeneralContainer,
  SettingsFinancialAdministrationsGroupsContainer,
  SettingsFinancialAdministrationTaxRatesContainer,
} from "@haywork/modules/settings/modules/financial-administrations";
import { SettingsGeneralContainer } from "@haywork/modules/settings/modules/general";
import { SettingsLanguageContainer } from "@haywork/modules/settings/modules/language";
import { SettingsMatchmailContainer } from "@haywork/modules/settings/modules/matchmail";
import { SettingsMediaPartnersOverviewContainer } from "@haywork/modules/settings/modules/mediapartners";
import { SettingsRealEstateAgencyContainer } from "@haywork/modules/settings/modules/real-estate-agency";
import { SettingsSchedulerOverviewContainer } from "@haywork/modules/settings/modules/scheduler";
import { FeatureNotReadyComponent } from "@haywork/modules/shared";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Mls from "@haywork/modules/settings/modules/mls";
import Documents from "@haywork/modules/settings/modules/documents";

export const SettingsDetailRouting = ({ realEstateAgencyId }) => {
  return (
    <Switch>
      <Route
        exact
        path={MAINROUTES.SETTINGS.URI}
        render={() => <Redirect to={SETTINGSROUTES.OVERVIEW.URI} />}
      />
      <Route
        exact
        path={SETTINGSROUTES.OVERVIEW.URI}
        component={SettingsOverviewContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.REAL_ESTATE_AGENCY.URI}
        component={SettingsRealEstateAgencyContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.USERS.URI}
        component={EmployeeOverviewContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.BRANCHES.URI}
        component={OfficeOverviewContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.USERS.URI}
        component={FeatureNotReadyComponent}
      />
      <Route
        exact
        path={SETTINGSROUTES.GENERAL_ADMINISTRATION.URI}
        component={SettingsFinancialAdministrationsGeneralContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.GENERAL.URI}
        component={SettingsGeneralContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.TAXRATES.URI}
        component={SettingsFinancialAdministrationTaxRatesContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.PRODUCTS.URI}
        component={SettingsFinancialAdministrationProductsContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.GROUPS.URI}
        component={SettingsFinancialAdministrationsGroupsContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.SCHEDULER_CATEGORIES.URI}
        component={SettingsSchedulerOverviewContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.RELATIONGROUPS.URI}
        component={RelationGroupsContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.STANDARDLETTERS.URI}
        component={FeatureNotReadyComponent}
      />
      <Route
        exact
        path={SETTINGSROUTES.ASSIGNMENTS.URI}
        component={SettingsAssignmentsContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.EVENTCENTER.URI}
        component={SettingsEventCenterContainer}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.APP_XCHANGE.URI}
        component={SettingsAppXchangeOverviewContainer}
        feature="APP_XCHANGE"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.LANGUAGE.URI}
        component={SettingsLanguageContainer}
        feature="LANGUAGE"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <Route
        exact
        path={SETTINGSROUTES.MEDIAPARTNERS.URI}
        component={SettingsMediaPartnersOverviewContainer}
      />
      <Route
        exact
        path={SETTINGSROUTES.MATCHMAIL.URI}
        component={SettingsMatchmailContainer}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.EMAIL.URI}
        component={SettingsEmailOverviewContainer}
        feature="EMAIL"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.EMAIL_TEMPLATES.URI}
        component={SettingsEmailTemplatesOverview}
        feature="EMAIL_TEMPLATES"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.EMAIL_SIGNATURES.URI}
        component={SettingsEmailSignaturesOverview}
        feature="EMAIL_SIGNATURES"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.DOSSIER_FOLDERS.URI}
        component={SettingsDossierFoldersOverview}
        feature="FOLDERS_V2"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.ACQUISITION_ASSIGNMENTS.URI}
        component={SettingsAcquisitionAssignmentsContainer}
        feature="ACQUISITIONS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
      <Route
        exact
        path={SETTINGSROUTES.COMPANY_SETTINGS.URI}
        component={CompanySettings}
      />
      <Route exact path={SETTINGSROUTES.MLS.URI} component={Mls} />
      <FeatureRoute
        exact
        path={SETTINGSROUTES.DOCUMENTS.URI}
        component={Documents}
        feature="DOCUMENTS_SETTINGS"
        realEstateAgency={realEstateAgencyId}
        featureComponent={FeatureNotReadyComponent}
      />
    </Switch>
  );
};
