import { MonthStats } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { REQUEST } from "@haywork/constants";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";

const styles = require("./avarage-lead-time-widget.component.scss");

interface Props {
  statisticsThisMonth: MonthStats;
  statisticsLastMonth: MonthStats;
  state: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DashboardAverageLeadTimeWidgetComponent extends React.PureComponent<Props> {
  public render() {
    const currentMonthAvgProcessingTimeInDays = Math.floor(
      this.props.statisticsThisMonth?.avgProcessingTimeInDays || 0
    );
    const lastMonthAvgProcessingTimeInDays = Math.floor(
      this.props.statisticsLastMonth?.avgProcessingTimeInDays || 0
    );
    const lessThanLastPeriod =
      currentMonthAvgProcessingTimeInDays < lastMonthAvgProcessingTimeInDays;
    const sameAsLastPeriod =
      currentMonthAvgProcessingTimeInDays === lastMonthAvgProcessingTimeInDays;

    const contentSpanClass = classNames("content", {
      warning: !lessThanLastPeriod && !sameAsLastPeriod,
      positive: lessThanLastPeriod && !sameAsLastPeriod,
      neutral: sameAsLastPeriod,
    });

    const footerSpanClass = classNames("result", {
      negative: !lessThanLastPeriod && !sameAsLastPeriod,
      positive: lessThanLastPeriod && !sameAsLastPeriod,
      neutral: sameAsLastPeriod,
    });
    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetViewing">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-clock" />
            </span>
            <span className="text">
              <ResourceText resourceKey="averageLeadTimeWidgetAverageLeadTime" />
            </span>
          </header>

          {this.props.state === REQUEST.PENDING && (
            <div className="content loading">
              <PageLoader loading />
            </div>
          )}
          {this.props.state === REQUEST.SUCCESS && (
            <div className={contentSpanClass} styleName="content">
              {currentMonthAvgProcessingTimeInDays === 0 ? (
                <span className="mainValue">-</span>
              ) : (
                <div styleName="main-value">
                  <div styleName="amount">
                    <FormattedNumber
                      value={currentMonthAvgProcessingTimeInDays}
                    />
                  </div>
                  <div styleName="label">
                    <ResourceText resourceKey="averageLeadTimeWidgetDaysSimple" />
                  </div>
                </div>
              )}
            </div>
          )}
          <footer>
            <ResourceText resourceKey="averageLeadTimeWidgetLastMonth" />{" "}
            <span className={footerSpanClass}>
              {/* resource keys */}
              {lastMonthAvgProcessingTimeInDays === 0 && (
                <ResourceText resourceKey="averageLeadTimeWidgetNoDays" />
              )}
              {lastMonthAvgProcessingTimeInDays > 0 && (
                <ResourceText
                  resourceKey="averageLeadTimeWidgetDays"
                  values={{
                    count: lastMonthAvgProcessingTimeInDays,
                    prettyCount: intlContext.formatNumber(
                      lastMonthAvgProcessingTimeInDays
                    ),
                  }}
                />
              )}
            </span>
          </footer>
        </article>
      </div>
    );
  }
}
