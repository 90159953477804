import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { MailTo } from "@haywork/modules/shared";
import Button from "@haywork/components/ui/button";
import { RealEstatePropertyContactDepartment } from "@haywork/api/mls";

const styles = require("./style.scss");

type Props = {
  agency: RealEstatePropertyContactDepartment | null | undefined;
  onCreateNewRelationCallback: (relation: {
    name: string;
    telephone: string;
    webAddress: string;
    email: string;
  }) => void;
};

export const RealtorComponent: FC<Props> = memo(
  CSSModules(styles)(({ agency, onCreateNewRelationCallback }) => {
    if (!agency) return null;

    return (
      <div>
        <h2>
          <I18n value="mls.sidebarTitle.realtor" />
        </h2>
        <div styleName="realtor">
          {!!agency.name && (
            <div styleName="realtor__displayName">{agency.name}</div>
          )}
          <div styleName="realtor__contact">
            {!!agency.phone && (
              <span>
                <a href={`tel:${agency.phone}`}>{agency.phone}</a>
              </span>
            )}
            {!!agency.email && (
              <span>
                <MailTo email={agency.email} />
              </span>
            )}
          </div>
          <div styleName="realtor__action">
            <Button
              label="mls.realtorAction.add"
              category="success"
              onClick={() =>
                onCreateNewRelationCallback({
                  name: agency?.name,
                  telephone: agency?.phone,
                  webAddress: agency?.websiteURL,
                  email: agency?.email,
                })
              }
            />
          </div>
        </div>
      </div>
    );
  })
);
