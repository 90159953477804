import { FileUploadObject } from "@haywork/util";
import classNames from "classnames";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EditorAttachmentComponent as Attachment } from "./attachment.component";

const styles = require("./attachments.component.scss");

interface Props {
  files: FileUploadObject[];
  free?: boolean;
  onChange: (files: FileUploadObject[]) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class EditorAttachmentsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onDownload = this.onDownload.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  public render() {
    if (!this.props.files || !this.props.files.length) return null;

    return (
      <div styleName={classNames("attachments", { free: this.props.free })}>
        {this.props.files.map((file) => (
          <Attachment
            key={file.id}
            file={file}
            onDownload={this.onDownload}
            onRemove={this.onRemove}
          />
        ))}
      </div>
    );
  }

  private onDownload(file: FileUploadObject) {
    if (!has(file, "response.uri")) return;
    window.open(file.response.uri, "_blank");
  }

  private onRemove(file: FileUploadObject) {
    const files = this.props.files.filter((ref) => ref.id !== file.id);
    this.props.onChange(files);
  }
}
