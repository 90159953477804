import { AssignmentType, GlobalSearchType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { GlobalSearchResponseItem } from "@haywork/request";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";

type Props = {
  item: GlobalSearchResponseItem;
  onClick: (item: GlobalSearchResponseItem) => void;
  onRemoveClick: (
    event: React.MouseEvent<HTMLDivElement>,
    item: GlobalSearchResponseItem
  ) => void;
};

export const PillComponent: FC<Props> = memo(
  ({ item, onClick, onRemoveClick }) => {
    const { type, assignment, invoice, relation, task } = item;

    const [touched, setTouched] = useState(false);
    const [firstTouch, setFirstTouch] = useState(true);

    const onClickHandler = useCallback(() => {
      if (touched && firstTouch) {
        setFirstTouch(false);
        return;
      }

      onClick(item);
      setTouched(false);
      setFirstTouch(true);
    }, [item, touched, firstTouch, setFirstTouch, setTouched]);

    const name = useMemo(() => {
      switch (type) {
        case GlobalSearchType.Assignments: {
          if (!assignment || !assignment.typeOfAssignment)
            return <I18n value="assignment" />;
          const { typeOfAssignment, displayName, publicReference } = assignment;
          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              return !!publicReference ? <span>{publicReference}</span> : null;
            }
            default: {
              return !!displayName ? <span>{displayName}</span> : null;
            }
          }
        }
        case GlobalSearchType.Invoices: {
          if (!invoice) return null;
          const { displayName } = invoice;
          return !!displayName ? (
            <span>{displayName}</span>
          ) : (
            <I18n value="conceptInvoice" />
          );
        }
        case GlobalSearchType.Relations: {
          if (!relation) return null;
          const { displayName } = relation;
          return !!displayName ? <span>{displayName}</span> : null;
        }
        case GlobalSearchType.Tasks: {
          if (!task) return null;
          const { subject } = task;
          return !!subject ? <span>{subject}</span> : null;
        }
        default:
          return null;
      }
    }, [type, assignment, invoice, relation, task]);

    return (
      <div
        className="pill"
        onTouchStart={() => setTouched(true)}
        onClick={onClickHandler}
      >
        <div className="value">{name}</div>
        <div className="remove" onClick={(event) => onRemoveClick(event, item)}>
          <i className="fal fa-times" />
        </div>
      </div>
    );
  }
);
