import I18n from "@haywork/components/i18n";
import { MAINROUTES, SETTINGSROUTES } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Ui } from "@haywork/modules/ui";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { match, NavLink } from "react-router-dom";

const styles = require("./menu.component.scss");

interface Props {
  isBackofficeEmployee: boolean;
  emailUnreadCount: number;
  notificationUnreadCount: number;
  sidebarCollapsed: boolean;
  realEstateAgencyId: string;
  showPlayground: boolean;
  onLinkClick: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type?: "MLS"
  ) => void;
  onCloseAllTabs: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class MainMenu extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.isAppXchangeActive = this.isAppXchangeActive.bind(this);
  }

  public render() {
    return (
      <ul styleName="menu">
        <li>
          <NavLink
            to={MAINROUTES.DASHBOARD.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuDashboard"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.DASHBOARD.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarDashboard" />
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={MAINROUTES.ASSIGNMENTS.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuAssignments"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.ASSIGNMENTS.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarContracts" />
            </div>
          </NavLink>
        </li>
        <FeatureSwitch feature="ACQUISITIONS">
          <li>
            <NavLink
              to={MAINROUTES.ACQUISITIONS.URI}
              activeClassName={styles.menu__item__active}
              styleName="menu__item"
              onClick={(e) => this.props.onLinkClick(e)}
              data-cy="CY-mainMenuAcquisitions"
            >
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.ACQUISITIONS.ICON
                )}
              />
              <div styleName="label">
                <I18n value="OutlookbarAcquisitions" />
              </div>
            </NavLink>
          </li>
        </FeatureSwitch>
        {!this.props.isBackofficeEmployee && (
          <FeatureSwitch feature="MLS">
            <li>
              <NavLink
                to={MAINROUTES.MLS.URI}
                activeClassName={styles.menu__item__active}
                styleName="menu__item"
                onClick={(e) => this.props.onLinkClick(e, "MLS")}
                data-cy="CY-mainMenuMLS"
              >
                <i
                  className={classNames(
                    "fal",
                    "fa-fw",
                    "fa-" + MAINROUTES.MLS.ICON
                  )}
                />
                <div styleName="label">
                  <I18n value="OutlookbarMLS" />
                </div>
              </NavLink>
            </li>
          </FeatureSwitch>
        )}
        <FeatureSwitch feature="KEYBOARD">
          <li>
            <NavLink
              to={MAINROUTES.KEYBOARD.URI}
              activeClassName={styles.menu__item__active}
              styleName="menu__item"
              onClick={(e) => this.props.onLinkClick(e)}
              data-cy="CY-mainMenuKeyBoard"
            >
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.KEYBOARD.ICON
                )}
              />
              <div styleName="label">
                <I18n value="OutlookbarKeyBoard" />
              </div>
            </NavLink>
          </li>
        </FeatureSwitch>
        <li>
          <NavLink
            to={MAINROUTES.SCHEDULER.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuScheduler"
          >
            <span styleName="dayIcon">
              <span styleName="day">{new Date().getDate()}</span>
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.SCHEDULER.ICON
                )}
              />
            </span>
            <div styleName="label">
              <I18n value="OutlookbarAgenda" />
            </div>
          </NavLink>
        </li>
        {!this.props.isBackofficeEmployee && (
          <li>
            <NavLink
              to={MAINROUTES.EMAIL.URI}
              activeClassName={styles.menu__item__active}
              className="hidden-xs"
              styleName="menu__item"
              onClick={(e) => this.props.onLinkClick(e)}
              data-cy="CY-mainMenuEmail"
            >
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.EMAIL.ICON
                )}
              />
              <div styleName="label">
                <I18n value="OutlookbarMail" />
              </div>
              {this.props.emailUnreadCount > 0 && (
                <div
                  styleName={classNames("unread-count", {
                    collapsed: this.props.sidebarCollapsed,
                  })}
                >
                  {this.props.emailUnreadCount}
                </div>
              )}
            </NavLink>
          </li>
        )}
        {!this.props.isBackofficeEmployee && (
          <FeatureSwitch feature="EVENTCENTER_V2">
            <li>
              <NavLink
                to={MAINROUTES.NOTIFICATIONS.URI}
                activeClassName={styles.menu__item__active}
                className="hidden-xs"
                styleName="menu__item"
                onClick={(e) => this.props.onLinkClick(e)}
              >
                <i
                  className={classNames(
                    "fal",
                    "fa-fw",
                    "fa-" + MAINROUTES.NOTIFICATIONS.ICON
                  )}
                />
                <div styleName="label">
                  <I18n value="OutlookbarNotifications" />
                </div>
                {this.props.notificationUnreadCount > 0 && (
                  <div
                    styleName={classNames("unread-count", {
                      collapsed: this.props.sidebarCollapsed,
                    })}
                  >
                    {this.props.notificationUnreadCount}
                  </div>
                )}
              </NavLink>
            </li>
          </FeatureSwitch>
        )}
        <li>
          <NavLink
            to={MAINROUTES.TASKS.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuTasks"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.TASKS.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarTasks" />
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={MAINROUTES.RELATIONS.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuRelations"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.RELATIONS.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarContacts" />
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={MAINROUTES.SEARCHASSIGNMENT.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuSearchAssignments"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.SEARCHASSIGNMENT.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarSearchProfiles" />
            </div>
          </NavLink>
        </li>
        <li id="invoicesOverview">
          <NavLink
            to={MAINROUTES.FINANCE.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
            onClick={(e) => this.props.onLinkClick(e)}
            data-cy="CY-mainMenuInvoices"
          >
            <i
              className={classNames(
                "fal",
                "fa-fw",
                "fa-" + MAINROUTES.FINANCE.ICON
              )}
            />
            <div styleName="label">
              <I18n value="OutlookbarInvoices" />
            </div>
          </NavLink>
        </li>

        <FeatureSwitch feature="APP_XCHANGE">
          <li>
            <NavLink
              to={SETTINGSROUTES.APP_XCHANGE.URI}
              activeClassName={styles.menu__item__active}
              styleName="menu__item"
              onClick={(e) => this.props.onLinkClick(e)}
              isActive={this.isAppXchangeActive}
              data-cy="CY-mainMenuAppExchange"
            >
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.APP_XCHANGE.ICON
                )}
              />
              <div styleName="label">
                <I18n value="OutlookbarAppXchange" />
                {/* <Ui.HighlightFeature /> */}
              </div>
            </NavLink>
          </li>
        </FeatureSwitch>

        {this.props.showPlayground && (
          <li styleName="playground">
            <NavLink
              to={MAINROUTES.PLAYGROUND.URI}
              activeClassName={styles.menu__item__active}
              styleName="menu__item"
              onClick={(e) => this.props.onLinkClick(e)}
            >
              <i
                className={classNames(
                  "fal",
                  "fa-fw",
                  "fa-" + MAINROUTES.PLAYGROUND.ICON
                )}
              />
              <div styleName="label">
                <I18n value="OutlookbarPlayground" />
              </div>
            </NavLink>
          </li>
        )}
        <FeatureSwitch feature="AUTOTEST">
          <li>
            <div
              styleName="menu__item"
              onClick={this.props.onCloseAllTabs}
              data-cy="CY-closeAllTabs"
            >
              <i className={classNames("fal", "fa-fw", "fa-times-circle")} />
              <div styleName="label">
                <I18n value="closeAllTabs" />
              </div>
            </div>
          </li>
        </FeatureSwitch>
      </ul>
    );
  }

  private isAppXchangeActive<P>(match: match<P>, location: any): boolean {
    if (!location) return false;

    const pathname: string = get(location, "pathname");
    if (!pathname) return false;

    const matchRoute = /app\/app-xchange/g;
    return matchRoute.test(pathname);
  }
}
