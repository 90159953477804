import { RelationSnapShot } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");

interface OfficeListItemComponentProps {
  office?: RelationSnapShot;
  zebra: boolean;
  onOfficeClickHandler: (officeID: string) => void;
  onOfficeArchive: (office: RelationSnapShot) => void;
  onOfficeUnarchive: (office: RelationSnapShot) => void;
  removeOfficeFromList: (office: RelationSnapShot) => void;
}

interface OfficeListItemComponentState {
  showActions: boolean;
  preppedForRemove: boolean;
  deleteCountdown: number;
}

@CSSModules(styles, { allowMultiple: true })
export class OfficeListItemComponent extends React.Component<
  OfficeListItemComponentProps,
  OfficeListItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.state = {
      showActions: false,
      preppedForRemove: false,
      deleteCountdown: 5,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.toggleShowActions = this.toggleShowActions.bind(this);
    this.onUnRemoveClickHandler = this.onUnRemoveClickHandler.bind(this);
    this.onArchiveClickHandler = this.onArchiveClickHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const combined = classNames("item ", {
      zebra: this.props.zebra,
      "show-actions": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
    });

    return (
      <div
        id={this.props.office.displayName}
        styleName={combined}
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="item__data">
          <div styleName="relationAvatar">
            {this.props.office.avatarUrl ? (
              <span
                styleName="image"
                style={{
                  backgroundImage: `url(${JSON.stringify(
                    this.props.office.avatarUrl
                  )})`,
                }}
              />
            ) : (
              <span styleName="noImage">{this.props.office.letterAvatar}</span>
            )}
          </div>
          <div styleName="item__meta__container">
            <div styleName="item__meta" onClick={this.onClickHandler}>
              <div styleName="meta">
                <span styleName="displayName">
                  {this.props.office.displayName}
                  {this.props.office.isMainOffice ? (
                    <span className="label label-primary">
                      <ResourceText resourceKey="headquarters" />
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                <span styleName="typeOfRelation">
                  {this.props.office.locality ? (
                    <span>
                      {this.props.office.streetNameAndNumber},{" "}
                      {this.formatLocality(this.props.office.locality)}
                    </span>
                  ) : (
                    <span>{this.props.office.streetNameAndNumber}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div styleName="toggle" onClick={this.toggleShowActions}>
            <i id="toggleOffice" className="fa fa-fw fa-ellipsis-v" />
          </div>
          <div styleName="undo-delete" onClick={this.onUnRemoveClickHandler}>
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="actions">
          <div styleName="action actions__edit" onClick={this.onClickHandler}>
            <i className="fal fa-fw fa-pencil" />
          </div>
          <div
            styleName="action actions__delete"
            onClick={this.onArchiveClickHandler}
          >
            <i className="fal fa-fw fa-archive" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickHandler() {
    this.props.onOfficeClickHandler(this.props.office.id);
  }

  private onArchiveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ preppedForRemove: true, showActions: false });
    this.props.onOfficeArchive(this.props.office);

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.removeOfficeFromList(this.props.office);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private onUnRemoveClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
    this.props.onOfficeUnarchive(this.props.office);
  }

  private toggleShowActions() {
    this.setState({ showActions: !this.state.showActions });
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.ref &&
      !this.ref.contains(event.target) &&
      !!this.state.showActions
    )
      this.setState({ showActions: false });
  }

  private formatLocality(locality: string) {
    const pattern = /[\s-][a-z]/g;

    let lowercaseLocality = locality.toLowerCase();
    lowercaseLocality =
      lowercaseLocality[0].toUpperCase() + lowercaseLocality.substring(1);
    lowercaseLocality = lowercaseLocality.replace(pattern, (match) => {
      return match[0] + match[1].toUpperCase();
    });

    return lowercaseLocality;
  }
}
