import { arrayMinCount } from "./array-min-count";
import { checked } from "./checked";
import { dateShouldBeAfter, dateShouldBeBefore } from "./date";
import { email } from "./email";
import { iban } from "./iban";
import { minDigits } from "./min-digits";
import { minLength } from "./min-length";
import { minCapitalLetters, minLowercaseLetters } from "./min-letters";
import { required } from "./required";
import { telephone } from "./telephone";
import { schema, url } from "./url";
import { priceDifference } from "./price";
import { buildnumbers } from "./build-numbers";
import { maxLength } from "./max-length";

export const Validators = {
  required,
  minLength,
  email,
  telephone,
  url,
  schema,
  checked,
  iban,
  arrayMinCount,
  minDigits,
  minCapitalLetters,
  minLowercaseLetters,
  dateShouldBeBefore,
  dateShouldBeAfter,
  priceDifference,
  buildnumbers,
  maxLength
};
