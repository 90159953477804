import { PROJECTROUTES } from "@haywork/constants";
import {
  AddressDetailFormComponent,
  DatesFormComponent,
  FinanceFormComponent,
  NumbersFormComponent,
  ProjectEditProjectContainerProps,
  ProjectFormComponent,
} from "@haywork/modules/project";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RouteComponentProps } from "react-router-dom";

const styles = require("./edit-project.component.scss");

const route = RouteUtil.mapStaticRouteValues;

interface ProjectEditProjectComponentProps {}
interface State {}
type Props = ProjectEditProjectComponentProps &
  ProjectEditProjectContainerProps &
  RouteComponentProps<any>;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditProjectComponent extends React.Component<Props, State> {
  private readonly startAtAddress: boolean;

  constructor(props) {
    super(props);

    // Set form controls
    const { projectAssignment } = this.props;

    const { match } = this.props;

    if (!!match.params.caller) {
      const callerUri = atob(match.params.caller);
      this.startAtAddress = true;
      const pathname = route(PROJECTROUTES.DETAIL.URI, {
        id: projectAssignment.id,
      });
      this.props.setCaller(callerUri, pathname);
    }
  }

  public render() {
    return (
      <div styleName="address" id="scroll-to-top">
        <div className="container-fluid">
          <StepperComponent
            initial={this.startAtAddress ? 1 : 0}
            scrollToElementId="scroll-to-top"
          >
            <StepComponent
              title="projectCommonTabTitle"
              data-cy="CY-commonStep"
            >
              <ProjectFormComponent {...this.props} />
            </StepComponent>
            <StepComponent title="addressDataProject" data-cy="CY-addressStep">
              <AddressDetailFormComponent {...this.props} />
            </StepComponent>
            <StepComponent title="numbersData" data-cy="CY-numbersStep">
              <NumbersFormComponent {...this.props} />
            </StepComponent>
            <StepComponent title="dates" data-cy="CY-datesStep">
              <DatesFormComponent {...this.props} />
            </StepComponent>
            <StepComponent
              title="assignmentNavigationFinancial"
              data-cy="CY-financialStep"
            >
              <FinanceFormComponent {...this.props} />
            </StepComponent>
          </StepperComponent>
        </div>
      </div>
    );
  }
}
