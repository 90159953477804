import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import {
  ObjectTypesEditMarketingPhotosContainer, ObjectTypesEditMarketingTextContainer,
  ObjectTypesEditMarketingFloorplansContainer, ObjectTypesEditMarketingVideosContainer
} from "@haywork/modules/object-types";

const route = RouteUtil.mapStaticRouteValues;
export const ObjectTypesEditMarketingRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ OBJECTTYPESROUTES.EDIT_MARKETING.URI } render={ () => <Redirect to={ route(OBJECTTYPESROUTES.EDIT_MARKETING_PHOTOS.URI, { id }) } /> } />
    <Route exact path={ OBJECTTYPESROUTES.EDIT_MARKETING_PHOTOS.URI } component={ ObjectTypesEditMarketingPhotosContainer } />
    <Route exact path={ OBJECTTYPESROUTES.EDIT_MARKETING_TEXT.URI } component={ ObjectTypesEditMarketingTextContainer } />
    <Route exact path={ OBJECTTYPESROUTES.EDIT_MARKETING_MAPS.URI } component={ ObjectTypesEditMarketingFloorplansContainer } />
    <Route exact path={ OBJECTTYPESROUTES.EDIT_MARKETING_VIDEOS.URI } component={ ObjectTypesEditMarketingVideosContainer } />
  </Switch>;
};
