import {
  AppClientCategory,
  AppClientSnapShot,
  RemunerationCategory
} from "@haywork/api/authorization";
import { Employee } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { AuthorizationThunks } from "@haywork/middleware/thunk/authorization";
import {
  SettingsAppXchangeOverviewComponent,
  SettingsAppXchangeOverviewComponentProps
} from "@haywork/modules/settings/modules/app-xchange";
import {
  AppState,
  AuthorizationActions,
  CurrentRealestateAgency
} from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { clients } from "./selectors";

interface StateProps {
  clients: AppClientSnapShot[];
  clientCategories: AppClientCategory[];
  selectedClientCategories: AppClientCategory[];
  selectedRemunerationCategories: RemunerationCategory[];
  clientsStatus: string;
  remunerationCategories: RemunerationCategory[];
  specials: AppClientSnapShot[];
  employee: Employee;
  currentRealestateAgency: CurrentRealestateAgency;
}

interface DispatchProps {
  navigate: (path: string) => void;
  getAppClients: () => Promise<void>;
  toggleFilter: (category: AppClientCategory) => void;
  toggleRemuneration: (category: RemunerationCategory) => void;
  clearFilters: () => void;
}

const mapStateToProps = <StateProps, SettingsAppXchangeOverviewComponentProps>(
  state: AppState
) => {
  const {
    clientCategories,
    clientsStatus,
    selectedClientCategories,
    selectedRemunerationCategories,
    remunerationCategories,
    specials
  } = state.authorization.appClients;
  const employee = state.account.employee;
  const currentRealestateAgency = state.account.currentRealestateAgency;

  return {
    clients: clients(state),
    clientCategories,
    selectedClientCategories,
    selectedRemunerationCategories,
    clientsStatus,
    remunerationCategories,
    specials,
    employee,
    currentRealestateAgency
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SettingsAppXchangeOverviewComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (path: string) => dispatch(push(path)),
  getAppClients: () => dispatch(AuthorizationThunks.AppClient.getAppClients()),
  toggleFilter: (category: AppClientCategory) =>
    dispatch(AuthorizationActions.AppClients.toggleFilter(category)),
  toggleRemuneration: (category: RemunerationCategory) =>
    dispatch(AuthorizationActions.AppClients.toggleRemuneration(category)),
  clearFilters: () => dispatch(AuthorizationActions.AppClients.clearFilters())
});

export type SettingsAppXchangeOverviewContainerProps = StateProps &
  DispatchProps;
export const SettingsAppXchangeOverviewContainer = connect<
  StateProps,
  DispatchProps,
  SettingsAppXchangeOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsAppXchangeOverviewComponent);
