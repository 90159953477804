import { RealEstateGroup } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { ASSIGNMENTROUTES, REQUEST } from "@haywork/constants";
import {
  AssignmentEditContainerProps,
  AssignmentEditRouting,
} from "@haywork/modules/assignment";
import Notes from "@haywork/modules/notes-v3";
import { ConfirmComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  AssignmentUtil,
  ObjectAssignmentValidatedSubRoutes,
  RouteUtil,
} from "@haywork/util";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import Actions, { AssignmentAction } from "./actions";
import ExternalChanges from "@haywork/components/external-changes";
import ConfirmOverwrite from "@haywork/components/confirm-overwrite";
import { RootEntityType } from "@haywork/api/event-center";
import Presence from "@haywork/components/ui/presence";
import { IntlShape } from "react-intl";

const styles = require("./edit.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentEditComponentProps {}
interface State {
  previousRoute: string;
  nextRoute: string;
  validSubRoutes: ObjectAssignmentValidatedSubRoutes;
  backToDashboardConfirmVisible: boolean;
  confirmSave: boolean;
  confirmSaveAndClose: boolean;
}
type Props = AssignmentEditComponentProps & AssignmentEditContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditComponent extends React.Component<Props, State> {
  private routes: string[] = [
    ASSIGNMENTROUTES.EDIT_CLIENT.URI,
    ASSIGNMENTROUTES.EDIT_ADDRESS.URI,
    ASSIGNMENTROUTES.EDIT_FINANCIAL.URI,
    ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI,
    ASSIGNMENTROUTES.EDIT_MARKETING.URI,
    ASSIGNMENTROUTES.PUBLISH.URI,
  ];
  private currentRouteIndex: number = 0;

  constructor(props) {
    super(props);

    this.state = {
      nextRoute: null,
      previousRoute: null,
      validSubRoutes: {
        client: false,
        address: false,
        financial: false,
        assignment: false,
        marketing: false,
      },
      backToDashboardConfirmVisible: false,
      confirmSave: false,
      confirmSaveAndClose: false,
    };

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onPublishClickHandler = this.onPublishClickHandler.bind(this);
    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.backToDashboard = this.backToDashboard.bind(this);
    this.cancelBackToDashboard = this.cancelBackToDashboard.bind(this);
  }

  public componentDidMount() {
    const { id } = this.props.assignment;
    this.routes = this.routes.map((r) => route(r, { id }));
    const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes();
    const validSubRoutes = AssignmentUtil.validateSubRoutes(
      this.props.assignment
    );
    this.setState({ validSubRoutes, nextRoute, previousRoute });
  }

  public render() {
    if (!this.props.assignment) return null;

    const {
      id,
      realEstateGroup,
      forSale,
      displayName,
      isNew,
      publicReference,
    } = this.props.assignment;
    const { validSubRoutes } = this.state;
    const rentResourceKey =
      realEstateGroup === RealEstateGroup.Agricultural ? "forLease" : "forRent";

    const clientRoute = classNames("item", {
      valid: validSubRoutes.client,
    });
    const addressRoute = classNames("item", {
      valid: validSubRoutes.address,
    });
    const financialRoute = classNames("item", {
      disabled: isNew,
      valid: validSubRoutes.financial,
    });
    const assignmentRoute = classNames("item", {
      disabled: isNew,
      valid: validSubRoutes.assignment,
    });
    const marketingRoute = classNames("item", {
      disabled: isNew,
      valid: validSubRoutes.marketing,
    });
    const loading = this.props.saveAssignmentState === REQUEST.PENDING;

    return (
      <div styleName="edit">
        <PageHeader
          title={displayName}
          subTitle={publicReference}
          actions={
            <>
              <Presence
                entityId={id}
                entityType={RootEntityType.ObjectAssignment}
              />
              <Notes />
              <Button
                label="save"
                category="success"
                disabled={loading}
                onClick={() => this.setState({ confirmSave: true })}
              />
              <Actions
                showPublish={this.props.canPublish}
                isFullScreen={this.props.fullscreen}
                loading={loading}
                isNew={isNew}
                onClick={this.onActionClick}
              />
            </>
          }
        >
          <div styleName="info">
            <h2>
              <I18n
                prefix="realEstateGroups"
                value={realEstateGroup.toString()}
              />
              ,{" "}
              {forSale ? (
                <I18n value="forSale" />
              ) : (
                <I18n value={rentResourceKey} />
              )}
            </h2>
            <h3>{this.renderHouseInfo()}</h3>
          </div>
        </PageHeader>

        <ExternalChanges
          entityId={id}
          entityType={RootEntityType.ObjectAssignment}
          onReloadEntity={this.props.reloadAssignment}
          onBackToDashboard={this.props.backToDashboard}
        />

        <div styleName="edit__body">
          {loading && <Ui.Loaders.Fullscreen mask />}
          <AssignmentEditRouting id={id} />
        </div>
        <div styleName="edit__navigation">
          <div styleName="button">
            {this.state.previousRoute && (
              <NavLink
                to={this.state.previousRoute}
                className="btn btn-default-alt icon-left"
              >
                <i className="fal fa-fw fa-chevron-left" />
                <I18n value="previousText" />
              </NavLink>
            )}
          </div>

          <div styleName="navigation">
            <div styleName="navigation__list">
              <NavLink
                styleName={clientRoute}
                to={route(ASSIGNMENTROUTES.EDIT_CLIENT.URI, { id })}
                data-cy="CY-assignmentNavClient"
              >
                <div id="navigationClient" styleName="index">
                  1
                </div>
                <I18n value="assignmentNavigationClient" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={addressRoute}
                to={route(ASSIGNMENTROUTES.EDIT_ADDRESS.URI, { id })}
                data-cy="CY-assignmentNavAddress"
              >
                <div id="navigationAddress" styleName="index">
                  2
                </div>
                <I18n value="assignmentNavigationAddress" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={financialRoute}
                to={route(ASSIGNMENTROUTES.EDIT_FINANCIAL.URI, { id })}
                onClick={this.cancelNavigation}
                data-cy="CY-assignmentNavFinancial"
              >
                <div id="navigationFinancial" styleName="index">
                  3
                </div>
                <I18n value="assignmentNavigationFinancial" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={assignmentRoute}
                to={route(ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI, { id })}
                onClick={this.cancelNavigation}
                data-cy="CY-assignmentNavAssignment"
              >
                <div id="navigationAssignment" styleName="index">
                  4
                </div>
                <I18n value="assignmentNavigationAssignment" />
              </NavLink>
              <div styleName="spacer">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
              <NavLink
                styleName={marketingRoute}
                to={this.getMarketingRoute(id)}
                onClick={this.cancelNavigation}
                data-cy="CY-assignmentNavMarketing"
              >
                <div id="navigationMarketing" styleName="index">
                  5
                </div>
                <I18n value="assignmentNavigationMarketing" />
              </NavLink>
            </div>
          </div>

          <div styleName="button last">
            {this.state.nextRoute &&
              this.currentRouteIndex < this.routes.length - 2 && (
                <NavLink
                  to={this.state.nextRoute}
                  className="btn btn-success icon-right"
                  onClick={this.onNextClickHandler}
                  data-cy="CY-assignmentNavNext"
                  style={
                    this.props.saveAssignmentState === REQUEST.PENDING
                      ? { opacity: 0.65 }
                      : null
                  }
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
            {this.props.canPublish &&
              this.currentRouteIndex === this.routes.length - 2 && (
                <NavLink
                  to={route(ASSIGNMENTROUTES.PUBLISH.URI, { id })}
                  className="btn btn-success icon-right"
                  data-cy="CY-assignmentNavPrev"
                >
                  <I18n value="nextText" />
                  <i className="fal fa-fw fa-chevron-right" />
                </NavLink>
              )}
          </div>
        </div>

        <ConfirmComponent
          visible={this.state.backToDashboardConfirmVisible}
          titleResourceKey="object.backToDashboard.title"
          bodyResourceKey="object.backToDashboard.body"
          onClose={this.cancelBackToDashboard}
          onConfirm={this.backToDashboard}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ObjectAssignment}
          entityId={id}
          saving={this.state.confirmSave}
          onCancel={() => this.setState({ confirmSave: false })}
          onConfirm={() => this.onSaveClickHandler(false)}
        />

        <ConfirmOverwrite
          entityType={RootEntityType.ObjectAssignment}
          entityId={id}
          saving={this.state.confirmSaveAndClose}
          onCancel={() => this.setState({ confirmSaveAndClose: false })}
          onConfirm={() => this.onSaveClickHandler()}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const { nextRoute, previousRoute } = this.getNextAndPreviousRoutes(
        nextProps.location.pathname
      );
      const validSubRoutes = AssignmentUtil.validateSubRoutes(
        nextProps.assignment || this.props.assignment
      );
      this.setState({ validSubRoutes, nextRoute, previousRoute });
    }
  }

  private onNextClickHandler(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.saveAssignmentState === REQUEST.PENDING)
      event.preventDefault();

    if (
      this.props.assignment.isNew &&
      this.currentRouteIndex === 1 &&
      this.props.saveAssignmentState !== REQUEST.PENDING
    ) {
      event.preventDefault();
      if (!this.props.canSave) return this.props.toggleSaveModal(true);
      this.props.saveInitialAssignment();
    }
  }

  private cancelNavigation(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.assignment.isNew) event.preventDefault();
  }

  private async onSaveClickHandler(close: boolean = true) {
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.setState({ confirmSave: false, confirmSaveAndClose: false });
    this.props.saveAssignment(close);
  }

  private onPublishClickHandler() {
    if (!this.props.canPublish) return;
    this.props.publish(this.props.assignment.id);
  }

  private getNextAndPreviousRoutes(pathname?: string): {
    nextRoute: string;
    previousRoute: string;
  } {
    this.currentRouteIndex = this.routes.reduce((state, route, idx) => {
      const path = pathname || this.props.location.pathname;
      if (new RegExp(escapeRegExp(route), "gi").test(path)) return idx;
      return state;
    }, -1);

    let nextRoute =
      this.currentRouteIndex <= this.routes.length
        ? this.routes[this.currentRouteIndex + 1]
        : null;
    const previousRoute =
      this.currentRouteIndex > 0
        ? this.routes[this.currentRouteIndex - 1]
        : null;

    if (
      nextRoute === ASSIGNMENTROUTES.EDIT_MARKETING.URI &&
      !!this.props.lastMarketingRoute
    ) {
      nextRoute = this.props.lastMarketingRoute;
    }

    return {
      nextRoute,
      previousRoute,
    };
  }

  private renderHouseInfo(): string {
    const { listingType, houseSort, houseType } = this.props.assignment;
    const listingTypeValue = !!listingType
      ? this.props.intl.formatMessage({
          id: `listingTypes.${listingType.toString()}`,
          defaultMessage: `listingTypes.${listingType.toString()}`,
        })
      : null;
    const houseSortValue = !!houseSort
      ? this.props.intl.formatMessage({
          id: `houseSorts.${houseSort.toString()}`,
          defaultMessage: `houseSorts.${houseSort.toString()}`,
        })
      : null;
    const houseTypeValue = !!houseType
      ? this.props.intl.formatMessage({
          id: `houseTypes.${houseType.toString()}`,
          defaultMessage: `houseTypes.${houseType.toString()}`,
        })
      : null;
    const houseInfo = [listingTypeValue, houseSortValue, houseTypeValue];

    return houseInfo.filter((value) => !!value).join(", ");
  }

  private getMarketingRoute(id: string): string {
    if (!!this.props.lastMarketingRoute) {
      return route(this.props.lastMarketingRoute, { id });
    }

    return route(ASSIGNMENTROUTES.EDIT_MARKETING.URI, { id });
  }

  private onActionClick(action: AssignmentAction) {
    switch (action) {
      case AssignmentAction.Fullscreen: {
        this.props.toggleFullscreen();
        break;
      }
      case AssignmentAction.Publish: {
        this.onPublishClickHandler();
        break;
      }
      case AssignmentAction.SaveAndClose: {
        this.setState({ confirmSaveAndClose: true });
        break;
      }
      case AssignmentAction.BackToDashboard: {
        if (this.props.hasChanges) {
          this.setState({ backToDashboardConfirmVisible: true });
        } else {
          this.props.backToDashboard();
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private backToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
    this.props.backToDashboard();
  }

  private cancelBackToDashboard() {
    this.setState({ backToDashboardConfirmVisible: false });
  }
}
