import * as ActionType from "./templates.types";
import { REDUX } from "@haywork/constants";

const setPrintTemplates = (payload: ActionType.PrintTemplates) => ({
  type: REDUX.ASSIGNMENT.SET_PRINT_TEMPLATES,
  ...payload
});

const setPrintExportOptions = (payload: ActionType.PrintExportOptions) => ({
  type: REDUX.ASSIGNMENT.SET_PRINT_EXPORT_OPTIONS,
  ...payload
});

export const AssignmentTemplatesActions = {
  setPrintTemplates,
  setPrintExportOptions
};
