import {
  AcquisitionObjectAssignment,
  AcquisitionStatusOption,
  PhotoBlob,
  RelationSnapShot,
  AcquisitionAssignment,
} from "@haywork/api/kolibri";
import { MapDispatchToProps, AcquisitionThunk } from "@haywork/middleware";
import { AppState, EditableActions } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquistionAssignmentEditGeneralScreen,
  AcquistionAssignmentEditGeneralScreenProps,
} from "./assignments-edit-general";
import { acquisitionStatusOptions } from "./selectors";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";

type StateProps = {
  employees: RelationSnapShot[];
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  acquisitionStatusOptions: AcquisitionStatusOption[];
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) => void;
  uploadMlsPhotosForAquisitionObject: (
    photos: PhotoBlob[]
  ) => Promise<PhotoBlob[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquistionAssignmentEditGeneralScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment = state.editable
    .currentComponentState as AcquisitionObjectAssignment;
  const { employees } = state.account;

  return {
    employees,
    acquisitionObjectAssignment,
    acquisitionStatusOptions: acquisitionStatusOptions(
      acquisitionObjectAssignment
    )(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquistionAssignmentEditGeneralScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  uploadMlsPhotosForAquisitionObject: (photos: PhotoBlob[]) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.uploadMlsPhotosForAquisition(photos)
    ),
  updateAcquisitionAssignmentObject: (
    componentState: AcquisitionObjectAssignment,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AcquistionAssignmentEditGeneralScreenContainerProps = StateProps &
  DispatchProps;
export const AcquistionAssignmentEditGeneralScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquistionAssignmentEditGeneralScreen);
