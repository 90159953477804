import { PagedResults, StoreHelper, Action } from "@haywork/stores";
import { DocumentTemplateSnapShot } from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./list.types";
import result from "lodash-es/result";

export interface DynamicDocumentListState {
  documentTemplates: DocumentTemplateSnapShot[];
  documentTemplatesStatus: string;
}

const INITIAL_STATE: DynamicDocumentListState = {
  documentTemplates: [],
  documentTemplatesStatus: REQUEST.IDLE
};

export const listReducer = (state: DynamicDocumentListState = INITIAL_STATE, action: Action): DynamicDocumentListState => {
  switch (action.type) {
    case REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_TEMPLATES_STATUS: {
      const { documentTemplatesStatus } = <ActionType.DocumentTemplatesStatus> action;
      return { ...state, documentTemplatesStatus };
    }
    case REDUX.DYNAMIC_DOCUMENTS.SET_DOCUMENT_TEMPLATES: {
      const { statistics, documentTemplates } = <ActionType.DocumentTemplatesResponse> action;

      return {
        ...state,
        documentTemplates,
        documentTemplatesStatus: REQUEST.SUCCESS
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
