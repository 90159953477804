import { createSelector } from "reselect";
import get from "lodash-es/get";

import { AppState } from "@haywork/stores";
import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";

const templatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;
const categoriesSelector = (state: AppState) =>
  state.emailV2.templates.categories;
const accountsSelector = (state: AppState) =>
  state.emailV2.accounts.accounts || [];
const mailSettingsSelector = (state: AppState) =>
  state.emailV2.main.personSettings;

export const defaultAccount = createSelector(
  accountsSelector,
  mailSettingsSelector,
  (accounts, settings) => {
    return accounts.find((a) => a.id === settings?.defaultAccountId);
  }
);

export const signatures = createSelector(
  [templatesSelector, categoriesSelector],
  (templates, categories) => {
    const signatureCategory = categories.find(
      (category) =>
        category.categoryType === TemplateDefinitionCategoryType.EmailSignature
    );
    if (!signatureCategory) return [];
    return templates.filter(
      (template) =>
        get(template, "linkedTemplateDefinitionCategory.id") ===
        get(signatureCategory, "id")
    );
  }
);
