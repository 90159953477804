import {
  MomentInTimeOption,
  NotificationSettings,
  UnitOfTimeOption,
} from "@haywork/api/event-center";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { EventCenterThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEventCenterComponent,
  SettingsEventCenterComponentProps,
} from "./event-center.component";
import {
  bulkNotificationSettings,
  editableNotificationSettings,
  employees,
  editableMlsNotificationSettings,
} from "./selectors";

interface StateProps {
  notificationSettings: NotificationSettings[];
  mlsNotificationSettings: NotificationSettings[];
  bulkNotificationSettings: NotificationSettings[];
  notificationSettingsStatus: string;
  employees: RelationSnapShot[];
  unitsOfTime: UnitOfTimeOption[];
  momentsInTime: MomentInTimeOption[];
}
interface DispatchProps {
  getNotificationSettings: () => Promise<void>;
  updateNotificationSettings: (
    notificationSettings: NotificationSettings[]
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEventCenterComponentProps,
  AppState
> = (state) => {
  const { notificationSettingsStatus } = state.eventCenter.settings;
  const { unitsOfTime, momentsInTime } = state.main.mastertable.eventCenter;

  return {
    notificationSettings: editableNotificationSettings(state),
    mlsNotificationSettings: editableMlsNotificationSettings(state),
    bulkNotificationSettings: bulkNotificationSettings(state),
    notificationSettingsStatus,
    employees: employees(state),
    unitsOfTime,
    momentsInTime,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEventCenterComponentProps
> = (dispatch) => ({
  getNotificationSettings: () =>
    dispatch(EventCenterThunk.getNotificationSettings()),
  updateNotificationSettings: (notificationSettings: NotificationSettings[]) =>
    dispatch(EventCenterThunk.updateNotificationSettings(notificationSettings)),
});

export type SettingsEventCenterContainerProps = StateProps & DispatchProps;
export const SettingsEventCenterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsEventCenterComponent);
