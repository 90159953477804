import * as React from "react";
import * as CSSModules from "react-css-modules";

import { SearchAssignment, TypeAlvOption, TypeAlvOtherOption } from "@haywork/api/kolibri";
import { Form, FormControls, Input, FormReturnValue } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("../edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface SearchAssignmentWhatAgriculturalComponentProps {
  searchAssignment: SearchAssignment;
  TypeAlvOptions: TypeAlvOption[];
  TypeAlvOtherOptions: TypeAlvOtherOption[];
  onChange: (values: FormReturnValue) => void;
}
interface SearchAssignmentWhatAgriculturalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWhatAgriculturalComponent extends React.Component<
  SearchAssignmentWhatAgriculturalComponentProps,
  SearchAssignmentWhatAgriculturalComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      typesALV: { value: value(this.props.searchAssignment, "typesALV", []) },
      typesALVOther: { value: value(this.props.searchAssignment, "typesALVOther", []) }
    };
  }

  public render() {
    return (
      <Form name="what-agricultural" formControls={this.formControls} onChange={this.props.onChange}>
        <div className="form__row" styleName="inline-value">
          <label htmlFor="typesALV">
            <ResourceText resourceKey="typesALV" />
          </label>
          {/* TODO replace asap */}
          <Input.QueryLegacy
            name="typesALV"
            values={this.props.TypeAlvOptions}
            displayPath="displayName"
            valuePath="value"
            matchPath="displayName"
            placeholder="typesALVPlaceholder"
            asSelect
            multiple
          />
        </div>

        <div className="form__row" styleName="inline-value">
          <label htmlFor="typesALVOther">
            <ResourceText resourceKey="typesALVOther" />
          </label>
          {/* TODO replace asap */}
          <Input.QueryLegacy
            name="typesALVOther"
            values={this.props.TypeAlvOtherOptions}
            displayPath="displayName"
            valuePath="value"
            matchPath="displayName"
            placeholder="typesALVOtherPlaceholder"
            asSelect
            multiple
          />
        </div>
      </Form>
    );
  }
}
