import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import { AssignmentPhase } from "@haywork/api/kolibri";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  assignmentPhase: AssignmentPhase;
  isActive: boolean;
};

export const StatusComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignmentPhase, isActive }) => {
      const statusStyle = useMemo(() => {
        if (!isActive) return "archived";

        switch (assignmentPhase) {
          case AssignmentPhase.Completed: {
            return "completed";
          }
          case AssignmentPhase.Concept: {
            return "concept";
          }
          case AssignmentPhase.Initiated: {
            return "initiated";
          }
          default:
            return null;
        }
      }, [assignmentPhase, isActive]);

      return (
        <div>
          <h2>
            <I18n value="searchAssignment.widgetTitle.status" />
          </h2>

          <div styleName="row">
            <span styleName={classNames("status", statusStyle)}>
              <I18n
                prefix="assignmentPhases"
                value={assignmentPhase.toString()}
              />
            </span>
          </div>
        </div>
      );
    }
  )
);
