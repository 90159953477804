import {
  EventType,
  NotificationSettings,
  UnitOfTime
} from "@haywork/api/event-center";
import {
  Acceptance,
  AssignmentPhase,
  AvailabilityStatus,
  LinkedAssignment,
  LinkedRelation,
  ObjectAssignment,
  RealEstateGroup,
  RelationType
} from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, FormattedNumber } from "react-intl";
import { NavLink } from "react-router-dom";
import { RentAvailability } from "./rent-availability.component";
import { RentTermExpiresComponent } from "./rent-term-expires.component";

const styles = require("./widgets.component.scss");

interface Props {
  assignment: ObjectAssignment;
  notificationSettings: NotificationSettings[];
  isInitial: boolean;
  onRetractClick: () => void;
  onModifyClick: () => void;
  prolongRentTerm: () => void;
  renewRentOffer: () => void;
  onRetractBidClick: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailGeneralWidgetsStatusComponent extends React.Component<
  Props
> {
  constructor(props) {
    super(props);

    this.renderStatus = this.renderStatus.bind(this);
    this.renderRentTermExpires = this.renderRentTermExpires.bind(this);
    this.renderSoldStatus = this.renderSoldStatus.bind(this);
    this.renderRentedStatus = this.renderRentedStatus.bind(this);
    this.renderUnderBid = this.renderUnderBid.bind(this);
    this.renderLinkedApplicants = this.renderLinkedApplicants.bind(this);
    this.renderApplicant = this.renderApplicant.bind(this);
    this.showRentTermExpires = this.showRentTermExpires.bind(this);
  }

  public render() {
    if (!this.props.assignment.availabilityStatus) return null;

    return (
      <div styleName="widget status-widget">
        <h2 styleName="title">
          <ResourceText resourceKey="status" />
        </h2>
        {this.renderStatus()}
        {this.renderRentTermExpires()}
      </div>
    );
  }

  private renderStatus(): React.ReactElement<HTMLDivElement> {
    const {
      availabilityStatus,
      withdrawReason,
      forRent,
      rentOffer,
      acceptanceDetails
    } = this.props.assignment;

    switch (availabilityStatus) {
      case AvailabilityStatus.SoldUnderCondition:
      case AvailabilityStatus.Sold: {
        return this.renderSoldStatus();
      }
      case AvailabilityStatus.RentedUnderCondition:
      case AvailabilityStatus.Rented:
      case AvailabilityStatus.LeasedUnderCondition:
      case AvailabilityStatus.Leased:
      case AvailabilityStatus.FarmedUnderCondition:
      case AvailabilityStatus.Farmed: {
        return this.renderRentedStatus();
      }
      case AvailabilityStatus.Withdrawn: {
        return (
          <div styleName="status danger">
            <div styleName="status__header">
              <h2>
                <ResourceText
                  masterKey="availabilityStatuses"
                  resourceKey={availabilityStatus.toString()}
                />
              </h2>
            </div>
            {!!withdrawReason && (
              <div styleName="status__row">
                <ResourceText
                  masterKey="withdrawReasonOptions"
                  resourceKey={withdrawReason.toString()}
                />
              </div>
            )}
          </div>
        );
      }
      case AvailabilityStatus.UnderBid: {
        return this.renderUnderBid();
      }
      default: {
        return (
          <div styleName="status">
            <div styleName="status__header">
              <div styleName="header-group">
                <h2>
                  <ResourceText
                    masterKey="availabilityStatuses"
                    resourceKey={availabilityStatus.toString()}
                  />
                </h2>
                {!!acceptanceDetails && !!acceptanceDetails.acceptance && (
                  <h3>
                    <ResourceText
                      masterKey="acceptances"
                      resourceKey={acceptanceDetails.acceptance.toString()}
                    />
                    {acceptanceDetails.acceptance === Acceptance.PerDate &&
                      !!acceptanceDetails.date && (
                        <span>
                          {": "}
                          <FormattedDate
                            value={acceptanceDetails.date}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                          />
                        </span>
                      )}
                  </h3>
                )}
              </div>
            </div>
            {!!forRent && <RentAvailability rentOffer={rentOffer} />}
          </div>
        );
      }
    }
  }

  private renderRentTermExpires() {
    const { assignment, isInitial } = this.props;

    if (
      !assignment.forRent ||
      assignment.realEstateGroup !== RealEstateGroup.Residential ||
      !!isInitial ||
      !this.showRentTermExpires()
    )
      return null;

    const {
      linkedObjectAssignments,
      id,
      availabilityStatus,
      assignmentPhase,
      isActive
    } = assignment;
    let canRenewProlong = true;
    if (!!linkedObjectAssignments && !!linkedObjectAssignments.length) {
      const versions = sortBy(
        linkedObjectAssignments,
        (assignment: LinkedAssignment) =>
          moment(assignment.dateTimeCreated).unix()
      ).reverse();
      canRenewProlong = first(versions).id === id;
    }

    const showProlongRentOffer =
      [
        AvailabilityStatus.Rented,
        AvailabilityStatus.Leased,
        AvailabilityStatus.Farmed
      ].indexOf(availabilityStatus) !== -1;

    const showRenewRentOffer =
      [
        AvailabilityStatus.Rented,
        AvailabilityStatus.Leased,
        AvailabilityStatus.Farmed,
        AvailabilityStatus.Withdrawn
      ].indexOf(availabilityStatus) !== -1 ||
      !isActive ||
      assignmentPhase === AssignmentPhase.Completed;

    if (
      !canRenewProlong ||
      !get(assignment, "rentOffer.rentedUntil") ||
      (!showProlongRentOffer && !showRenewRentOffer)
    )
      return null;

    return (
      <RentTermExpiresComponent
        assignment={assignment}
        showProlongRentOffer={showProlongRentOffer}
        showRenewRentOffer={showRenewRentOffer}
        prolongRentTerm={this.props.prolongRentTerm}
        renewRentOffer={this.props.renewRentOffer}
      />
    );
  }

  private renderSoldStatus(): React.ReactElement<HTMLDivElement> {
    const { availabilityStatus, saleOffer } = this.props.assignment;
    const linkedApplicants = this.props.assignment.linkedApplicants || [];

    return (
      <div styleName="status">
        <div styleName="status__header">
          <h2>
            <ResourceText
              masterKey="availabilityStatuses"
              resourceKey={availabilityStatus.toString()}
            />
          </h2>
          {availabilityStatus === AvailabilityStatus.SoldUnderCondition && (
            <React.Fragment>
              <div
                className="btn btn-primary"
                onClick={this.props.onRetractClick}
              >
                <ResourceText resourceKey="retractUnderCondition" />
              </div>
              <div
                className="btn btn-primary"
                onClick={this.props.onModifyClick}
              >
                <ResourceText resourceKey="change" />
              </div>
            </React.Fragment>
          )}
        </div>
        {this.renderLinkedApplicants(linkedApplicants, true)}
        <div styleName="status__row">
          {availabilityStatus === AvailabilityStatus.SoldUnderCondition && (
            <div styleName="column">
              <h3>
                <ResourceText resourceKey="conditionExpiresOn" />
              </h3>
              <FormattedDate
                value={saleOffer.dateReservation || new Date()}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          <div styleName="column">
            <h3>
              <ResourceText resourceKey="realisedSalePrice" />
            </h3>
            <FormattedNumber
              value={saleOffer.purchasePrice || 0}
              style="currency"
              currency="EUR"
            />
          </div>
          <div styleName="column">
            <h3>
              <ResourceText resourceKey="documentSignedOn" />
            </h3>
            <FormattedDate
              value={saleOffer.dateSold || new Date()}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </div>
        </div>
      </div>
    );
  }

  private renderRentedStatus(): React.ReactElement<HTMLDivElement> {
    const { availabilityStatus, rentOffer } = this.props.assignment;
    const linkedApplicants = this.props.assignment.linkedApplicants || [];
    const rentedUnderCondition =
      [
        AvailabilityStatus.RentedUnderCondition,
        AvailabilityStatus.LeasedUnderCondition,
        AvailabilityStatus.FarmedUnderCondition
      ].indexOf(availabilityStatus) !== -1;

    return (
      <div styleName="status">
        <div styleName="status__header">
          <h2>
            <ResourceText
              masterKey="availabilityStatuses"
              resourceKey={availabilityStatus.toString()}
            />
          </h2>
          {rentedUnderCondition && (
            <React.Fragment>
              <div
                className="btn btn-primary"
                onClick={this.props.onRetractClick}
              >
                <ResourceText resourceKey="retractUnderCondition" />
              </div>
              <div
                className="btn btn-primary"
                onClick={this.props.onModifyClick}
              >
                <ResourceText resourceKey="change" />
              </div>
            </React.Fragment>
          )}
        </div>
        {this.renderLinkedApplicants(linkedApplicants)}
        <div styleName="status__row">
          {rentedUnderCondition && (
            <div styleName="column">
              <h3>
                <ResourceText resourceKey="conditionExpiresOn" />
              </h3>
              <FormattedDate
                value={rentOffer.dateReservation || new Date()}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          <div styleName="column">
            <h3>
              <ResourceText resourceKey="realisedRentPrice" />
            </h3>
            <FormattedNumber
              value={rentOffer.realizedPerMonth || 0}
              style="currency"
              currency="EUR"
            />
            &nbsp;
            <ResourceText resourceKey="per" />
            &nbsp;
            <ResourceText
              masterKey="rentConditions"
              resourceKey={rentOffer.rentCondition.toString()}
            />
          </div>
          <div styleName="column">
            <h3>
              <ResourceText resourceKey="rentStartsOn" />
            </h3>
            <FormattedDate
              value={rentOffer.rentedFrom || new Date()}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </div>
          {!!rentOffer.rentedUntil && (
            <div styleName="column">
              <h3>
                <ResourceText resourceKey="rentEndsOn" />
              </h3>
              <FormattedDate
                value={rentOffer.rentedUntil}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderUnderBid() {
    const {
      availabilityStatus,
      acceptanceDetails,
      forRent,
      rentOffer
    } = this.props.assignment;

    return (
      <div styleName="status">
        <div styleName="status__header">
          <div styleName="header-group">
            <h2>
              <ResourceText
                masterKey="availabilityStatuses"
                resourceKey={availabilityStatus.toString()}
              />
            </h2>
            {!!acceptanceDetails && !!acceptanceDetails.acceptance && (
              <h3>
                <ResourceText
                  masterKey="acceptances"
                  resourceKey={acceptanceDetails.acceptance.toString()}
                />
                {acceptanceDetails.acceptance === Acceptance.PerDate &&
                  !!acceptanceDetails.date && (
                    <span>
                      {": "}
                      <FormattedDate
                        value={acceptanceDetails.date}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                      />
                    </span>
                  )}
              </h3>
            )}
          </div>
          <div
            className="btn btn-primary"
            onClick={this.props.onRetractBidClick}
          >
            <ResourceText resourceKey="retractUnderBid" />
          </div>
        </div>
        {!!forRent && <RentAvailability rentOffer={rentOffer} />}
      </div>
    );
  }

  private renderLinkedApplicants(
    applicants: LinkedRelation[],
    forSale: boolean = false
  ): React.ReactElement<HTMLDivElement> {
    if (!applicants || applicants.length === 0) return null;
    const resourceKey = forSale
      ? "assignmentStatusLinkedApplicantsSale"
      : "assignmentStatusLinkedApplicantsRent";

    return (
      <div styleName="applicants">
        <strong>
          <ResourceText
            resourceKey={resourceKey}
            values={{ count: applicants.length }}
          />
        </strong>
        {applicants.map(this.renderApplicant)}
      </div>
    );
  }

  private renderApplicant(
    applicant: LinkedRelation,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { id } = applicant;
    let uri = "";

    switch (applicant.typeOfRelation) {
      case RelationType.ContactCompany: {
        uri = RouteUtil.mapStaticRouteValues(
          RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
          { id }
        );
        break;
      }
      case RelationType.ContactPerson: {
        uri = RouteUtil.mapStaticRouteValues(
          RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
          { id }
        );
        break;
      }
      default: {
        uri = "";
        break;
      }
    }

    return (
      <div styleName="applicant" key={idx}>
        {idx > 0 && ", "}
        <NavLink to={uri}>{applicant.displayName}</NavLink>
      </div>
    );
  }

  private showRentTermExpires(): boolean {
    let showRentTermExpires = false;
    const { notificationSettings, assignment } = this.props;

    if (!!notificationSettings && !!notificationSettings.length) {
      const setting = notificationSettings.find(
        (setting) => setting.eventType === EventType.RentedUntilExpired
      );
      if (!!setting && !!setting.timeSpans && !!setting.timeSpans.length) {
        const timeSpan = first(setting.timeSpans);
        const rentedUntil = moment(assignment.rentOffer.rentedUntil);
        const now = moment();

        switch (true) {
          case rentedUntil <= now: {
            showRentTermExpires = true;
            break;
          }
          default: {
            let diff = 0;
            switch (timeSpan.unitOfTime) {
              case UnitOfTime.Year: {
                diff = rentedUntil.diff(now, "years", true);
                break;
              }
              case UnitOfTime.Month: {
                diff = rentedUntil.diff(now, "months", true);
                break;
              }
              case UnitOfTime.Week: {
                diff = rentedUntil.diff(now, "weeks", true);
                break;
              }
              case UnitOfTime.Day: {
                diff = rentedUntil.diff(now, "days", true);
                break;
              }
              default:
                break;
            }

            showRentTermExpires = diff <= timeSpan.amountOfTime;
          }
        }
      }
    }

    return showRentTermExpires;
  }
}
