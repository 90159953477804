import { Employee, TableResponse } from "@haywork/api/kolibri";
import { Dispatch, EmployeeThunk, OfficeThunk } from "@haywork/middleware";
import {
  SettingsOverviewComponent,
  SettingsOverviewComponentProps,
} from "@haywork/modules/settings/modules/overview/components/overview.component";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  masterTable: TableResponse;
  employee: Employee;
  amountOfOffices: number;
  amountOfEmployees: number;
}

interface DispatchProps {
  getAmountOfOffices: () => void;
  getAmountOfEmployees: () => void;
}

const mapStateToProps = <StateProps, RelationGroupComponentProps>(
  state: AppState
) => {
  const masterTable = state.main.mastertable.kolibri;
  const employee = state.account.employee;
  const amountOfOffices = state.offices.totalOfficesCount;
  const amountOfEmployees = state.employee.totalEmployeeCount;

  return {
    masterTable,
    employee,
    amountOfOffices,
    amountOfEmployees,
  };
};

const mapDispatchToProps = <DispatchProps, TaskOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getAmountOfOffices: () => dispatch(OfficeThunk.getAmountOfOffices()),
  getAmountOfEmployees: () => dispatch(EmployeeThunk.getAmountOfEmployees()),
});

export type OverviewContainerProps = StateProps & DispatchProps;
export const SettingsOverviewContainer = connect<
  StateProps,
  DispatchProps,
  SettingsOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsOverviewComponent);
