import {
  AcceptanceDetails,
  AcceptanceOption,
  BusinessExpenses,
  CommissionPaidByOption,
  CommissionTypeOption,
  Language,
  ObjectAssignment,
  PriceCode,
  PriceCodeOption,
  RealEstateGroup,
  SaleCondition,
  SaleConditionOption,
  SaleOffer,
  VatConditionOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditFinancialSaleComponent,
  AssignmentEditFinancialSaleComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  priceCodes: PriceCodeOption[];
  saleOffer: SaleOffer;
  hidePrice: boolean;
  acceptances: AcceptanceOption[];
  acceptanceDetails: AcceptanceDetails;
  vatConditions: VatConditionOption[];
  saleConditions: SaleConditionOption[];
  commissionPaidByOptions: CommissionPaidByOption[];
  commissionTypeOptions: CommissionTypeOption[];
  businessExpenses: BusinessExpenses;
  languages: Language[];
  defaultLanguage: Language;
  realEstateGroup: RealEstateGroup;
  objectAssignment: ObjectAssignment;
  priceCodesAgricultural: PriceCodeOption[];
  enableHidePriceForObjectAssignmentsOption: boolean;
}
interface DispatchProps {}

const mapSaleConditions = (saleConditions: SaleConditionOption[]) => {
  return saleConditions.filter(
    (saleCondition) => saleCondition.value !== SaleCondition.CostsSplit
  );
};

const reducePriceCodes = (priceCodes: PriceCodeOption[]): PriceCodeOption[] => {
  return priceCodes.filter((code) => {
    switch (code.value) {
      case PriceCode.OnRequest:
      case PriceCode.FixedPrice:
      case PriceCode.AskingPrice:
      case PriceCode.ByTender:
        return true;
      default:
        return false;
    }
  });
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditFinancialSaleComponentProps,
  AppState
> = (state) => {
  const {
    priceCodes,
    acceptances,
    vatConditions,
    saleConditions,
    commissionPaidByOptions,
    commissionTypeOptions,
  } = state.main.mastertable.kolibri;
  const { objectAssignment } = state.editable.currentComponentState;
  const {
    saleOffer,
    hidePrice,
    acceptanceDetails,
    businessExpenses,
    realEstateGroup,
  }: ObjectAssignment = objectAssignment;
  const { enableHidePriceForObjectAssignmentsOption } = state.company.settings;

  const filteredPriceCodes = priceCodes.filter(
    (code) => code.value !== PriceCode.PublicAuction
  );

  return {
    priceCodes: filteredPriceCodes,
    saleOffer,
    hidePrice,
    acceptances,
    acceptanceDetails,
    vatConditions,
    saleConditions: mapSaleConditions(saleConditions),
    commissionPaidByOptions,
    commissionTypeOptions,
    businessExpenses,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage,
    realEstateGroup,
    objectAssignment,
    priceCodesAgricultural: reducePriceCodes(filteredPriceCodes),
    enableHidePriceForObjectAssignmentsOption,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditFinancialSaleComponentProps
> = (dispatch) => ({});

export type AssignmentEditFinancialSaleContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditFinancialSaleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditFinancialSaleComponent);
