import { SearchAssignment } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentDetailSearchersComponent,
  SearchAssignmentDetailSearchersComponentProps
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignment: SearchAssignment;
  host: string;
  realEstateAgencyId: string;
  saveSearchAssignmentStatus: string;
}
interface DispatchProps {
  navigate: (url: string) => void;
  saveSearchAssignment: (searchAssignment: SearchAssignment) => void;
}

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps = <StateProps, AssignmentDetailGeneralComponentProps>(
  state: AppState
) => {
  const {
    searchAssignment,
    saveSearchAssignmentStatus
  } = state.searchAssignment.single;

  return {
    searchAssignment,
    host: state.appSettings.host,
    realEstateAgencyId: state.account.currentRealestateAgency.id,
    saveSearchAssignmentStatus
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AssignmentDetailGeneralComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  saveSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(
      SearchAssignmentThunks.saveSearchAssignment(searchAssignment, false)
    )
});

export type SearchAssignmentDetailSearchersContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentDetailSearchersContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentDetailSearchersComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentDetailSearchersComponent);
