import * as React from "react";
import * as CSSModules from "react-css-modules";

import { SearchAssignment, TypeBOGOption } from "@haywork/api/kolibri";
import { Form, FormControls, Input, FormReturnValue } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";

const styles = require("../edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface SearchAssignmentWhatCommercialComponentProps {
  searchAssignment: SearchAssignment;
  typeBOGOptions: TypeBOGOption[];
  onChange: (values: FormReturnValue) => void;
}
interface SearchAssignmentWhatCommercialComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWhatCommercialComponent extends React.Component<
  SearchAssignmentWhatCommercialComponentProps,
  SearchAssignmentWhatCommercialComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = {
      typesBOG: { value: value(this.props.searchAssignment, "typesBOG", []) }
    };
  }

  public render() {
    return (
      <Form name="what-commercial" formControls={this.formControls} onChange={this.props.onChange}>
        <div className="form__row" styleName="inline-value">
          <label htmlFor="typesBOG">
            <ResourceText resourceKey="typesBOG" />
          </label>
          {/* TODO replace asap */}
          <Input.QueryLegacy
            name="typesBOG"
            values={this.props.typeBOGOptions}
            displayPath="displayName"
            valuePath="value"
            matchPath="displayName"
            placeholder="typesBOGPlaceholder"
            asSelect
            multiple
          />
        </div>
      </Form>
    );
  }
}
