import { createSelector } from "reselect";
import {
  ObjectAssignment,
  FloorType,
  ObjectTypeAssignment,
  EmployeeRole,
} from "@haywork/api/kolibri";
import { v4 as uuid } from "uuid";

import { ExtendedFloor, ExtendedSpace } from "./";
import { AppState } from "@haywork/stores";

const floorsSelector = (assignment: ObjectAssignment | ObjectTypeAssignment) =>
  (assignment.floors || []) as ExtendedFloor[];
const roleSelector = (state: AppState) =>
  state.account.currentRealestateAgency.role;
const isAdministratorSelector = (state: AppState) =>
  state.account.accountSettings.isAdministrator;

export const canEditMainFloors = createSelector(
  roleSelector,
  isAdministratorSelector,
  (role, isAdministrator) => role === EmployeeRole.BackOffice || isAdministrator
);

export const floors = createSelector(floorsSelector, (floors) => {
  const floorTypeArray = [
    FloorType.Basement,
    FloorType.GroundFloor,
    FloorType.Floor,
    FloorType.TopFloor,
    FloorType.Attic,
  ];
  const hasBasement = floors.find(
    (floor) => floor.floorType === FloorType.Basement
  );
  const startLevel = hasBasement ? -1 : 1;

  const updatedFloors: ExtendedFloor[] = floors.map((floor: ExtendedFloor) => {
    let updatedFloor = floor;

    if (!updatedFloor.id) {
      updatedFloor = {
        ...updatedFloor,
        id: uuid(),
      };
    }

    const spaces: ExtendedSpace[] = (updatedFloor.spaces || []).map((space) => {
      if (!space.id) {
        return {
          ...space,
          id: uuid(),
        };
      }
      return space;
    });

    return {
      ...updatedFloor,
      spaces,
    };
  });

  return floorTypeArray
    .reduce((state, type) => {
      const floorsToAppend = updatedFloors.filter(
        (floor) => floor.floorType === type
      );
      return [...state, ...floorsToAppend];
    }, [] as ExtendedFloor[])
    .map((floor, idx) => ({
      ...floor,
      floorNumber: startLevel + (startLevel === -1 && idx > 0 ? idx + 1 : idx),
    }));
});
