import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { SidebarComponent, SidebarComponentProps } from "./sidebar";
import { ReadRealEstatePropertyResponse } from "@haywork/api/mls";
import { PhotoBlob } from "@haywork/api/kolibri";

type StateProps = {
  realEstateResponse: ReadRealEstatePropertyResponse;
};
type DispatchProps = {
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  SidebarComponentProps,
  AppState
> = (state) => {
  const { realEstateResponse } = state.mls.single;

  return {
    realEstateResponse,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SidebarComponentProps
> = (dispatch) => ({
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
});

export type SidebarContainerProps = StateProps & DispatchProps;
export const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarComponent);
