import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { MLSROUTES } from "@haywork/constants";
import { SlideShow } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { SidebarContainerProps } from "./sidebar.container";
import { AttachmentType } from "@haywork/api/mls";
import { PhotoBlob } from "@haywork/api/kolibri";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type SidebarComponentProps = {};
type Props = SidebarComponentProps & SidebarContainerProps;

export const SidebarComponent: FC<Props> = memo(
  CSSModules(styles)(({ openLightbox, realEstateResponse }) => {
    const {
      bundleId: id,
      appClientKey: source,
      realEstateProperty,
    } = realEstateResponse;
    const photos = useMemo(() => {
      if (!realEstateProperty?.attachments) return [];
      const { attachments } = realEstateProperty;
      const photoFiles = attachments.filter(
        (attachment) => attachment.type === AttachmentType.PHOTO
      );

      return photoFiles.map((file) => {
        const {
          urlMediumFile,
          urlNormalizedFile,
          urlOriginalFile,
          urlThumbFile,
        } = file;

        return {
          urlOriginal: urlOriginalFile || urlNormalizedFile || urlMediumFile,
          urlPreview: urlThumbFile || urlMediumFile,
          urlBig: urlNormalizedFile || urlMediumFile,
          urlMedium: urlNormalizedFile || urlMediumFile,
        } as PhotoBlob;
      });
    }, [realEstateProperty]);

    const onSlideClickHandler = useCallback(
      (idx: number) => {
        openLightbox(photos, idx);
      },
      [photos]
    );

    return (
      <>
        <div styleName="preview-slider">
          {!!photos && photos.length > 0 ? (
            <SlideShow slides={photos} onSlideClick={onSlideClickHandler} />
          ) : (
            <i className="fal fa-fw fa-camera-retro" />
          )}
        </div>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(MLSROUTES.GENERAL.URI, { id, source })}
        >
          <Icon name="folder-open" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="mlsDetail.menuLabel.general" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(MLSROUTES.MEDIA.URI, { id, source })}
        >
          <Icon name="photo-video" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="mlsDetail.menuLabel.media" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(MLSROUTES.TIMELINE.URI, { id, source })}
        >
          <Icon name="align-left" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="mlsDetail.menuLabel.timeline" />
          </div>
        </NavLink>

        <NavLink
          styleName="action"
          activeClassName="active"
          to={route(MLSROUTES.SEARCH_ASSIGNMENTS.URI, { id, source })}
        >
          <Icon name="search" size={13} color="inherit" light />
          <div styleName="label">
            <I18n value="mlsDetail.menuLabel.searchAssignments" />
          </div>
        </NavLink>
      </>
    );
  })
);
