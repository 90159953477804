import { Phrase } from "@haywork/api/kolibri";
import { MapDispatchToProps, PhrasesThunks } from "@haywork/middleware/thunk";
import { AppState, PhrasesActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  EditorPhrasesComponent,
  EditorPhrasesComponentProps
} from "./phrases.component";

interface StateProps {
  phrase: Phrase;
  phraseStatus: string;
}
interface DispatchProps {
  getPhrases: (id: number) => Promise<void>;
  getPhrase: (id: string) => Promise<void>;
  clearPhrases: () => void;
  updatePhrase: (phrase: Phrase) => Promise<void>;
  savePhrase: (
    title: string,
    description: string,
    phraseCategoryId: number
  ) => Promise<void>;
  unsetPhrase: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EditorPhrasesComponentProps,
  AppState
> = (state) => {
  const { phrase, phraseStatus } = state.phrases;

  return {
    phrase,
    phraseStatus
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EditorPhrasesComponentProps
> = (dispatch) => ({
  getPhrases: (id: number) => dispatch(PhrasesThunks.getPhrases(id)),
  getPhrase: (id: string) => dispatch(PhrasesThunks.getPhrase(id)),
  clearPhrases: () => dispatch(PhrasesActions.setPhrases({ phrases: [] })),
  updatePhrase: (phrase: Phrase) =>
    dispatch(PhrasesThunks.updatePhrase(phrase)),
  savePhrase: (title: string, description: string, phraseCategoryId: number) =>
    dispatch(PhrasesThunks.savePhrase(title, description, phraseCategoryId)),
  unsetPhrase: () => dispatch(PhrasesActions.unsetPhrase())
});

export type EditorPhrasesContainerProps = StateProps & DispatchProps;
export const EditorPhrasesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorPhrasesComponent);
