import * as React from "react";
import { createPortal } from "react-dom";

export class OnboardingPortal extends React.Component<any, any> {
  private element: HTMLElement;
  private div: HTMLDivElement;

  constructor(props) {
    super(props);

    this.element = document.getElementById("onboarding-portal");
    this.div = document.createElement("div");
  }

  public componentDidMount() {
    this.element.appendChild(this.div);
  }

  public render() {
    return createPortal(this.props.children, this.div);
  }

  public componentWillUnmount() {
    this.element.removeChild(this.div);
  }
}
