import { NoteSnapShot } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";
import { MapDispatchToProps } from "@haywork/middleware";
import { NotesThunk } from "@haywork/middleware/thunk/notes-v3.thunk";
import { AppState } from "@haywork/stores";
import { NotesActions } from "@haywork/stores/notes-v3";
import { connect, MapStateToProps } from "react-redux";
import { NotesListComponent, NotesListComponentProps } from "./list";

interface StateProps {
  notes: NoteSnapShot[];
  notesStatus: RequestStatus;
  totalCount: number;
  currentParentId: string;
  employeeId: string;
  notesVisible: boolean;
}
interface DispatchProps {
  getNotes: (
    parentId: string,
    startIndex?: number,
    reset?: boolean
  ) => Promise<void>;
  addNote: (description: string) => Promise<void>;
  updateNote: (note: NoteSnapShot) => Promise<void>;
  toggleListVisibility: (status: boolean) => void;
  deleteNote: (id: string, description?: string) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  NotesListComponentProps,
  AppState
> = (state) => {
  const {
    notesStatus,
    totalCount,
    parentId: currentParentId,
    notesVisible,
    notes
  } = state.notesV3;
  const { id: employeeId } = state.account.employee;

  return {
    notes,
    notesStatus,
    totalCount,
    currentParentId,
    employeeId,
    notesVisible
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  NotesListComponentProps
> = (dispatch) => ({
  getNotes: (parentId: string, startIndex?: number, reset?: boolean) =>
    dispatch(NotesThunk.getNotes(parentId, startIndex, reset)),
  addNote: (description: string) => dispatch(NotesThunk.addNote(description)),
  updateNote: (note: NoteSnapShot) => dispatch(NotesThunk.updateNote(note)),
  toggleListVisibility: (status: boolean) =>
    dispatch(NotesActions.toggleListVisibility(status)),
  deleteNote: (id: string, description?: string) =>
    dispatch(NotesThunk.deleteNote(id, description))
});

export type NotesListContainerProps = StateProps & DispatchProps;
export const NotesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesListComponent);
