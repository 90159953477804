import PageHeader from "@haywork/components/ui/page-header";
import { CULTURE, REQUEST } from "@haywork/constants";
import { SettingsLanguageContainerProps } from "@haywork/modules/settings/modules/language";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./language.component.scss");

export interface SettingsLanguageComponentProps {}
interface State {}
interface AvailableLanguage {
  label: string;
  culture: string;
}
type Props = SettingsLanguageComponentProps & SettingsLanguageContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsLanguageComponent extends React.Component<Props, State> {
  private languages: AvailableLanguage[] = [
    { label: "dutch", culture: CULTURE.NL },
    { label: "german", culture: CULTURE.DE },
    { label: "english", culture: CULTURE.EN },
  ];

  constructor(props) {
    super(props);

    this.renderLanguageItem = this.renderLanguageItem.bind(this);
  }

  public render() {
    return (
      <div styleName="language">
        {this.props.languageStatus === REQUEST.PENDING && (
          <PageLoader fullscreen loading />
        )}
        <PageHeader title="settingsLanguageTitle" />

        <div styleName="languages">
          {this.languages.map(this.renderLanguageItem)}
        </div>
      </div>
    );
  }

  private renderLanguageItem(
    language: AvailableLanguage,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const selected = language.culture === this.props.culture;
    const itemStyle = classNames("item", { selected });
    const avatarStyle = classNames("avatar", language.label);

    return (
      <div
        styleName={itemStyle}
        onClick={() => this.props.switchLanguage(language.culture)}
        key={idx}
      >
        <div styleName={avatarStyle} />
        <ResourceText resourceKey={`${language.label}Lang`} />
      </div>
    );
  }
}
