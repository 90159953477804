export const EMAILV2 = {
  APPEND_MESSAGES: "EMAILV2_APPEND_MESSAGES",
  APPEND_FOLDERS: "EMAILV2_APPEND_FOLDERS",
  SET_FOLDER_MESSAGES_STATUS: "EMAILV2_SET_FOLDER_MESSAGES_STATUS",
  SET_ACCOUNTS: "EMAILV2_SET_ACCOUNTS",
  SET_ACCOUNTS_STATUS: "EMAILV2_SET_ACCOUNTS_STATUS",
  SET_CURRENT_ACCOUNT: "EMAILV2_SET_CURRENT_ACCOUNT",
  SET_CURRENT_FOLDER: "EMAILV2_SET_CURRENT_FOLDER",
  APPEND_DRAFTS: "EMAILV2_APPEND_DRAFTS",
  DELETE_MESSAGE: "EMAILV2_DELETE_MESSAGE",
  UPDATE_MESSAGE: "EMAILV2_UPDATE_MESSAGE",
  APPEND_MESSAGE: "EMAILV2_APPEND_MESSAGE",
  FILTER_MESSAGES: "EMAILV2_FILTER_MESSAGES",
  SET_FILTER_TERM: "EMAILV2_SET_FILTER_TERM",
  SET_MESSAGE_BOOKMARK_STATUS: "EMAILV2_SET_MESSAGE_BOOKMARK_STATUS",
  SET_MESSAGE_UNREAD_STATUS: "EMAILV2_SET_MESSAGE_UNREAD_STATUS",
  DELETE_DRAFT: "EMAILV2_DELETE_DRAFT",
  SET_DRAFT: "EMAILV2_SET_DRAFT",
  SET_MESSAGE: "EMAILV2_SET_MESSAGE",
  SET_MESSAGE_STATUS: "EMAILV2_SET_MESSAGE_STATUS",
  SET_DRAFT_STATUS: "EMAILV2_SET_DRAFT_STATUS",
  UPDATE_TEMPORARY_ID: "EMAILV2_UPDATE_TEMPORARY_ID",
  UPDATE_FOLDER: "EMAILV2_UPDATE_FOLDER",
  REMOVE_FOLDER: "EMAILV2_REMOVE_FOLDER",
  SET_UNREAD_COUNT: "EMAILV2_SET_UNREAD_COUNT",
  SET_SHARES: "EMAILV2_SET_SHARES",
  SET_SHARES_STATUS: "EMAILV2_SET_SHARES_STATUS",
  REMOVE_ACCOUNT: "EMAILV2_REMOVE_ACCOUNT",
  CLEAR_SHARES: "EMAILV2_CLEAR_SHARES",
  SET_SHARE_VISIBILITY: "EMAILV2_SET_SHARE_VISIBILITY",
  REMOVE_SINGLE_SHARE: "EMAILV2_REMOVE_SINGLE_SHARE",
  ADD_SINGLE_SHARE: "EMAILV2_ADD_SINGLE_SHARE",
  SET_PROVIDERS_STATUS: "EMAILV2_SET_PROVIDERS_STATUS",
  SET_PROVIDERS: "EMAILV2_SET_PROVIDERS",
  SET_ACCOUNT_SYNC_STATUS: "EMAILV2_SET_ACCOUNT_SYNC_STATUS",
  SET_FOLDER_UNREAD_COUNT: "EMAILV2_SET_FOLDER_UNREAD_COUNT",
  APPROVE_MESSAGE_FOR_EXTERNAL_RESOURCES:
    "EMAILV2_APPROVE_MESSAGE_FOR_EXTERNAL_RESOURCES",
  SET_FOLDER_CAN_LOAD_MORE: "EMAILV2_SET_FOLDER_CAN_LOAD_MORE",
  SET_ROOT_CAN_LOAD_MORE: "EMAILV2_SET_ROOT_CAN_LOAD_MORE",
  SET_REDIRECT_URI: "EMAILV2_SET_REDIRECT_URI",
  SET_CREATE_STATUS: "EMAILV2_SET_CREATE_STATUS",
  SET_SETTINGS: "EMAILV2_SET_SETTINGS",
  SET_ACCOUNT_ADDED_VISIBILITY: "EMAILV2_SET_ACCOUNT_ADDED_VISIBILITY",
  UPDATE_UNREAD_COUNT: "EMAILV2_UPDATE_UNREAD_COUNT",
  CLEAR_ALL_MESSAGES: "EMAILV2_CLEAR_ALL_MESSAGES",
  CLEAR_CURRENT_MESSAGE: "EMAILV2_CLEAR_CURRENT_MESSAGE",
  SET_CURRENT_BY_ID: "EMAILV2_SET_CURRENT_BY_ID"
};
