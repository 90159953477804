import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { KEYCODE } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import { Relation } from "@haywork/request";
import { AsyncUtil } from "@haywork/util";
import classNames from "classnames";
import * as equal from "deep-equal";
import debounce from "lodash-es/debounce";
import delay from "lodash-es/delay";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { InputComponentProps } from "../input.component";
import { Pill } from "./pill.component";
import { Suggestion } from "./suggestion.component";

const styles = require("./email-query.component.scss");

interface SelectedEmail {
  email: string;
  name?: string;
  id?: string;
  typeOfRelation?: RelationType;
}
interface Props {
  placeholder?: string;
  onAddRelation: (email: string) => void;
  onRelationSelected?: (relation: RelationSnapShot) => void;
}
interface State {
  query: string;
  emails: SelectedEmail[];
  deleteLastEmail: boolean;
  suggestions: RelationSnapShot[];
  selectedSuggestion: number;
  mouseDownOnOption: boolean;
}

type InputProps = Props & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class InputEmailQueryComponent extends React.Component<
  InputProps,
  State
> {
  private input: HTMLInputElement;

  constructor(props) {
    super(props);

    const emails = this.props.value || [];

    this.state = {
      query: "",
      emails,
      deleteLastEmail: false,
      suggestions: [],
      selectedSuggestion: null,
      mouseDownOnOption: false,
    };

    this.removeEmailPill = this.removeEmailPill.bind(this);
    this.queryRelation = debounce(this.queryRelation.bind(this), 250);
    this.selectSuggestionHandler = this.selectSuggestionHandler.bind(this);
    this.setOnMouseOverState = this.setOnMouseOverState.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onQueryClickHandler = this.onQueryClickHandler.bind(this);
    this.onAddRelationClickHandler = this.onAddRelationClickHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: InputProps) {
    if (!nextProps) return;

    if (!equal(nextProps.value, this.props.value)) {
      const emails = nextProps.value || [];
      this.setState({
        emails,
      });
    }
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;

    return (
      <div styleName="email">
        <div styleName="email__input">
          {this.state.emails.map((email, idx) => (
            <ErrorBoundary key={`${email.name}_${idx}`}>
              <Pill
                email={email}
                last={idx === this.state.emails.length - 1}
                deleteLastEmail={this.state.deleteLastEmail}
                onRemovePill={() => this.removeEmailPill(idx)}
                data-cy={
                  this.props["data-cy"] && `${this.props["data-cy"]}.Pill`
                }
              />
            </ErrorBoundary>
          ))}

          <input
            name={this.props.name}
            id={this.props.name}
            type="text"
            data-cy={this.props["data-cy"]}
            value={this.state.query}
            onChange={this.onChangeHandler}
            onKeyDown={this.onKeyDownHandler}
            onBlur={this.onBlurHandler}
            ref={(ref) => (this.input = ref)}
            placeholder={placeholder}
            data-lpignore="true"
          />
        </div>

        {(this.state.query.length > 0 || this.state.suggestions.length > 0) && (
          <div styleName="email__suggestions">
            <div
              styleName={classNames("default-suggestion", {
                selected: this.state.selectedSuggestion === null,
              })}
              onClick={this.onQueryClickHandler}
              onMouseDown={() => this.setOnMouseOverState(true)}
              onMouseUp={() => this.setOnMouseOverState(false)}
            >
              <ResourceText
                resourceKey="emailQueryAddAsEmail"
                values={{ query: this.state.query }}
                asHtml
              />
            </div>
            {this.state.suggestions.map((suggestion, idx) => (
              <ErrorBoundary key={idx}>
                <Suggestion
                  suggestion={suggestion}
                  selected={idx === this.state.selectedSuggestion}
                  onClick={() => this.selectSuggestionHandler(idx)}
                  onMouseOverState={this.setOnMouseOverState}
                  data-cy={
                    this.props["data-cy"] &&
                    `${this.props["data-cy"]}.EmailSuggestion`
                  }
                />
              </ErrorBoundary>
            ))}

            <div
              styleName={classNames("add-suggestion", {
                selected:
                  this.state.selectedSuggestion ===
                  this.state.suggestions.length,
              })}
              onClick={this.onAddRelationClickHandler}
              onMouseDown={() => this.setOnMouseOverState(true)}
              onMouseUp={() => this.setOnMouseOverState(false)}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.AddRelation`
              }
            >
              <i className="fal fa-plus-circle" />
              <ResourceText
                resourceKey="emailQueryAddAsRelation"
                values={{ query: this.state.query }}
                asHtml
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: query } = event.target;
    this.setState({
      query,
    });

    if (query.length >= 2) {
      this.queryRelation(query);
    } else {
      this.setState({
        suggestions: [],
        selectedSuggestion: null,
      });
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode, shiftKey } = event;
    const { query } = this.state;

    switch (keyCode) {
      case KEYCODE.TAB:
      case KEYCODE.ENTER: {
        if (keyCode === KEYCODE.ENTER) {
          event.preventDefault();
        }

        if (!query || !query.trim()) return;

        let emails = this.state.emails;
        if (this.state.selectedSuggestion !== null) {
          const relation =
            this.state.suggestions[this.state.selectedSuggestion];

          if (!relation) {
            this.props.onAddRelation(query);
            return this.setState({
              query: "",
              suggestions: [],
              selectedSuggestion: null,
            });
          }

          if (this.props.onRelationSelected) {
            this.props.onRelationSelected(relation);
          }

          emails = [
            ...emails,
            {
              email: relation.email,
              name: relation.displayName,
              id: relation.id,
              typeOfRelation: relation.typeOfRelation,
            },
          ];
        } else {
          emails = [
            ...emails,
            {
              email: query,
            },
          ];
        }

        this.props.onChange(emails);
        return this.setState({
          emails,
          query: "",
          suggestions: [],
          selectedSuggestion: null,
        });
      }
      case KEYCODE.BACKSPACE: {
        if (this.state.deleteLastEmail) {
          const emails = this.state.emails.slice(0, -1);

          this.props.onChange(emails);
          return this.setState({
            emails,
            deleteLastEmail: false,
          });
        }
        if (query === "") {
          return this.setState({
            deleteLastEmail: true,
          });
        }
        return;
      }
      case KEYCODE.ESCAPE: {
        return this.setState({
          deleteLastEmail: false,
        });
      }
      case KEYCODE.DOWN_ARROW: {
        event.preventDefault();
        if (this.state.selectedSuggestion === null) {
          return this.setState({
            selectedSuggestion: 0,
          });
        }
        if (this.state.selectedSuggestion === this.state.suggestions.length) {
          return this.setState({
            selectedSuggestion: null,
          });
        }
        return this.setState({
          selectedSuggestion: this.state.selectedSuggestion + 1,
        });
      }
      case KEYCODE.UP_ARROW: {
        event.preventDefault();
        if (this.state.selectedSuggestion === null) {
          return this.setState({
            selectedSuggestion: this.state.suggestions.length,
          });
        }
        if (this.state.selectedSuggestion === 0) {
          return this.setState({
            selectedSuggestion: null,
          });
        }
        return this.setState({
          selectedSuggestion: this.state.selectedSuggestion - 1,
        });
      }
      case 186: {
        if (!shiftKey) {
          event.preventDefault();

          const emails = [
            ...this.state.emails,
            {
              email: query,
            },
          ];

          this.props.onChange(emails);
          return this.setState({
            emails,
            query: "",
            suggestions: [],
            selectedSuggestion: null,
          });
        }
      }
      default:
        return;
    }
  }

  private removeEmailPill(idx: number) {
    const emails = this.state.emails.filter((email, key) => key !== idx);

    this.props.onChange(emails);
    this.input.focus();
    this.setState({
      emails,
    });
  }

  private async queryRelation(query: string) {
    const relations = await Relation.searchV3(query);
    const suggestions: RelationSnapShot[] = relations.reduce(
      (state, relation) => {
        const {
          id,
          displayName,
          email,
          secondEmailAddress,
          thirdEmailAddress,
          dateTimeCreated,
          dateTimeModified,
          isActive,
          typeOfRelation,
        } = relation;

        let snapshot: RelationSnapShot = {
          id,
          displayName,
          email,
          dateTimeCreated,
          dateTimeModified,
          isActive,
          typeOfRelation,
        };

        state.push(snapshot);

        if (!!secondEmailAddress) {
          snapshot = {
            ...snapshot,
            email: secondEmailAddress,
          };

          state.push(snapshot);
        }

        if (!!thirdEmailAddress) {
          snapshot = {
            ...snapshot,
            email: thirdEmailAddress,
          };

          state.push(snapshot);
        }

        return state;
      },
      []
    );

    this.setState({
      selectedSuggestion: null,
      suggestions,
    });
  }

  private selectSuggestionHandler(idx: number) {
    const relation = this.state.suggestions[idx];
    const emails = [
      ...this.state.emails,
      {
        email: relation.email,
        name: relation.displayName,
        id: relation.id,
        typeOfRelation: relation.typeOfRelation,
      },
    ];

    if (this.props.onRelationSelected) {
      this.props.onRelationSelected(relation);
    }

    this.props.onChange(emails);
    this.setState({
      emails,
      suggestions: [],
      query: "",
      selectedSuggestion: null,
    });
  }

  private async onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    event.persist();
    await AsyncUtil.wait(10);
    if (this.state.mouseDownOnOption) return;

    this.props.onChange(this.state.emails);

    delay(
      () =>
        this.setState({
          suggestions: [],
          selectedSuggestion: null,
          query: "",
        }),
      150
    );
  }

  private onQueryClickHandler() {
    const { query } = this.state;
    const emails = [
      ...this.state.emails,
      {
        email: query,
      },
    ];

    this.props.onChange(emails);
    this.setState({
      emails,
      suggestions: [],
      selectedSuggestion: null,
      query: "",
    });
  }

  private onAddRelationClickHandler() {
    const { query } = this.state;

    this.props.onAddRelation(query);
    this.setState({
      suggestions: [],
      selectedSuggestion: null,
      query: "",
    });
  }

  private setOnMouseOverState(mouseDownOnOption: boolean) {
    this.setState({ mouseDownOnOption });
  }
}
