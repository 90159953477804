import { intlContext } from "@haywork/app";
import { KEYCODE } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import isEqual from "lodash-es/isEqual";

interface ExtendedSelectInputComponentProps {
  placeholder?: string;
  values: any[];
  displayPath?: string;
  valuePath?: string;
  firstAsDefault?: boolean;
  emptyText?: string;
  addText?: string;

  onAdd?: (value: string) => void;
  compareFn: (listValue: any, receivedValue: any) => boolean;
  valueCompareFn?: (listValue: any, receivedValue: any) => boolean;
}
interface ExtendedSelectInputComponentState {
  query: string;
  results: string[];
  selectedKey: number;
  activeKey: number;
  expanded: boolean;
}

export class ExtendedSelectComponent extends React.Component<
  ExtendedSelectInputComponentProps & InputComponentProps,
  ExtendedSelectInputComponentState
> {
  private inputRef: HTMLInputElement;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      query: this.getInitialValue(this.props.value),
      results: this.props.values,
      selectedKey: this.props.firstAsDefault ? 0 : null,
      activeKey: 0,
      expanded: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onInputKeyDownHandler = this.onInputKeyDownHandler.bind(this);
    this.bindInputRef = this.bindInputRef.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.renderListValue = this.renderListValue.bind(this);
    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onAddClickHandler = this.onAddClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onItemClickHandler = this.onItemClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const extendedSelectInputStyle = classNames("input__extended-select", {
      disabled: this.props.disabled,
      expanded: this.state.expanded,
    });

    return (
      <div
        className={extendedSelectInputStyle}
        ref={this.bindRef}
        onKeyDown={this.onKeyDownHandler}
      >
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          placeholder={placeholder}
          value={this.state.query}
          onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}
          onKeyDown={this.onInputKeyDownHandler}
          ref={this.bindInputRef}
          disabled={this.props.disabled}
          data-cy={this.props["data-cy"]}
          data-lpignore="true"
        />
        <div
          className="toggle"
          onClick={this.onToggleClickHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.ToggleButton`
          }
        >
          <i className="fal fa-fw fa-chevron-right faIcon" />
        </div>
        <div className="list">
          {this.state.results.map(this.renderListValue)}
          {!this.props.onAdd && this.state.results.length === 0 && (
            <div className="list-item">
              <ResourceText
                resourceKey={this.props.emptyText || "nothingFound"}
              />
            </div>
          )}
          {this.props.onAdd && (
            <div
              className="list-item"
              onClick={this.onAddClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.ListItem`
              }
            >
              <strong>
                <ResourceText
                  resourceKey={this.props.addText || "addItem"}
                  values={{ query: this.state.query }}
                  asHtml
                />
              </strong>
            </div>
          )}
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ExtendedSelectInputComponentProps & InputComponentProps
  ) {
    if (!nextProps) return;
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.inputRef.focus();
    }
    if (nextProps.value !== undefined && nextProps.value !== this.props.value) {
      if (!nextProps.value) return this.setState({ query: "" });
      const compareFn = this.props.valueCompareFn || this.props.compareFn;
      const match = this.props.values.filter((v) =>
        compareFn(v, nextProps.value)
      );
      const query =
        match.length === 0
          ? ""
          : this.props.displayPath
          ? get(match[0], this.props.displayPath)
          : match[0];
      this.setState({ query });
    }

    if (
      nextProps.values.length !== this.props.values.length ||
      !isEqual(nextProps.values[0], this.props.values[0])
    ) {
      this.setState({
        results: nextProps.values,
      });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private getInitialValue(value: any): string {
    if (!value) return "";
    const match = this.props.values.filter((v) =>
      this.props.compareFn(v, value)
    );
    if (match.length === 0) return "";
    return this.props.displayPath
      ? get(match[0], this.props.displayPath)
      : match[0];
  }

  private bindInputRef(ref: HTMLInputElement) {
    if (ref && !this.inputRef) this.inputRef = ref;
  }

  private bindRef(ref: HTMLDivElement) {
    if (ref && !this.ref) this.ref = ref;
  }

  private renderListValue(
    value: any,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const listItemStyle = classNames("list-item", {
      selected: this.state.activeKey === idx,
    });

    const label = this.props.displayPath
      ? get(value, this.props.displayPath)
      : value;
    return (
      <div
        className={listItemStyle}
        key={idx}
        onClick={() => this.onItemClickHandler(idx)}
        data-cy={this.props["data-cy"] && this.props["data-cy"] + "ListItem"}
      >
        {label}
      </div>
    );
  }

  private onClickOutsideHandler(event: any) {
    if (
      this.inputRef &&
      !this.inputRef.contains(event.target) &&
      !!this.state.expanded
    )
      this.setState({ expanded: false });
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
    const { keyCode } = event;

    switch (keyCode) {
      case KEYCODE.ESCAPE: {
        this.setState({
          expanded: false,
          query: "",
          results: this.props.values,
        });
        this.inputRef.blur();
        break;
      }
      case KEYCODE.DOWN_ARROW: {
        event.preventDefault();
        let activeKey = this.state.activeKey + 1;
        if (activeKey >= this.state.results.length)
          activeKey = this.state.results.length - 1;
        this.setState({ activeKey });
        break;
      }
      case KEYCODE.UP_ARROW: {
        event.preventDefault();
        let activeKey = this.state.activeKey - 1;
        if (activeKey < 0) activeKey = 0;
        this.setState({ activeKey });
        break;
      }
      default:
        break;
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ query: value });

    const match = new RegExp(escapeRegExp(value), "gi");
    const results = this.props.values.filter((v) =>
      this.props.displayPath
        ? match.test(get(v, this.props.displayPath))
        : match.test(v)
    );

    this.setState({ results });
  }

  private onFocusHandler(event: React.FocusEvent<HTMLInputElement>) {
    this.setState({ expanded: true });
  }

  private onInputKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    if (keyCode === KEYCODE.ENTER) event.preventDefault();
  }

  private onAddClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    this.props.onAdd(this.state.query);
  }

  private onToggleClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (this.props.disabled) return;
    if (!this.state.expanded) this.inputRef.focus();
    this.setState({ expanded: !this.state.expanded });
  }

  private onItemClickHandler(idx: number) {
    const item = this.state.results[idx];
    const value = this.props.valuePath ? get(item, this.props.valuePath) : item;

    this.props.onChange(value);

    this.setState({
      activeKey: idx,
      expanded: false,
      results: this.props.values,
    });
  }
}
