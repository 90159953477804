import {
  Employee,
  SortOrder,
  TaskOrderByField,
  TaskSnapShot
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum ActionType {
  UpdateList = "Tasks.List.UpdateList",
  UpdateItem = "Tasks.List.UpdateItem",
  SetOrdering = "Tasks.List.SetOrdering",
  SetFilters = "Tasks.List.SetFilters",
  SetScrollOffset = "Tasks.List.SetScrollOffset",
  SetEmployee = "SET_EMPLOYEE",
  ResetApp = "RESET_APP"
}

type UpdateList = {
  type: ActionType.UpdateList;
  items: TaskSnapShot[];
  totalCount: number;
  startIndex: number;
};
const updateList = (
  startIndex: number,
  items: TaskSnapShot[],
  totalCount: number
) => ({
  type: ActionType.UpdateList,
  items,
  startIndex,
  totalCount
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: TaskSnapShot;
};
const updateListItem = (snapshot: TaskSnapShot) => ({
  type: ActionType.UpdateItem,
  snapshot
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: TaskOrderByField;
};

const setOrdering = (sortOrder: SortOrder, sortColumn: TaskOrderByField) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type SetEmployee = {
  type: ActionType.SetEmployee;
  employee: Employee;
};

const setEmployee = (employee: Employee) => ({
  type: ActionType.SetEmployee,
  employee
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetFilters
  | UpdateItem
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | SetEmployee
  | ResetApp;
export const actions = {
  updateList,
  setFilters,
  updateListItem,
  setOrdering,
  setScrollOffset,
  setEmployee
};
