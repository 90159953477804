import * as React from "react";
import * as CSSModules from "react-css-modules";

import { PersonSettings } from "@haywork/api/mail";
import { ResourceText } from "@haywork/modules/shared";
import { EXTERNALROUTES } from "@haywork/constants";

const styles = require("./create-modal.component.scss");

interface Props {
  personSettings: PersonSettings;
  onCreateAccount: () => void;
}

export const AvailableAccounts = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    if (!props.personSettings) return null;

    const {
      maxNumberOfAccountsPerCompany,
      numberOfAccountsPerCompanyTaken
    } = props.personSettings;

    if (maxNumberOfAccountsPerCompany === numberOfAccountsPerCompanyTaken) {
      return (
        <div styleName="available-accounts">
          <div styleName="available-accounts__body">
            <ResourceText resourceKey="emailMaxAvailableAccounts" asHtml />
          </div>
          <div styleName="footer">
            <div styleName="left" />
            <div styleName="right">
              <button
                type="button"
                className="btn btn-primary"
                data-cy="CY-createNewEmailAccountButton"
                onClick={() => {
                  return window.open(EXTERNALROUTES.ORDER_EMAIL.URI, "_blank");
                }}
              >
                <ResourceText resourceKey="createNewEmailAccount" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    const percentage = Math.ceil(
      100 * (numberOfAccountsPerCompanyTaken / maxNumberOfAccountsPerCompany)
    );
    const barStyle = { width: `${percentage}%` };
    const labelStyle = {
      clipPath: `polygon(0 0, ${percentage}% 0, ${percentage}% 100%, 0 100%)`
    };
    const fauxLabelStyle = {
      clipPath: `polygon(${percentage}% 0, 100% 0, 100% 100%, ${percentage}% 100%)`
    };

    return (
      <div styleName="available-accounts">
        <div styleName="available-accounts__body">
          <div styleName="available-accounts__amount">
            <div styleName="bar" style={barStyle} />
            <div styleName="label" style={labelStyle}>
              <ResourceText
                resourceKey="emailCreatedInboxes"
                values={{
                  total: maxNumberOfAccountsPerCompany,
                  count: numberOfAccountsPerCompanyTaken
                }}
              />
            </div>
            <div styleName="faux-label" style={fauxLabelStyle}>
              <ResourceText
                resourceKey="emailCreatedInboxes"
                values={{
                  total: maxNumberOfAccountsPerCompany,
                  count: numberOfAccountsPerCompanyTaken
                }}
              />
            </div>
          </div>

          <ResourceText
            resourceKey="emailAvailableAccountsBody"
            values={{
              count:
                maxNumberOfAccountsPerCompany - numberOfAccountsPerCompanyTaken,
              total: maxNumberOfAccountsPerCompany
            }}
            asHtml
          />
        </div>
        <div styleName="footer">
          <div styleName="left" />
          <div styleName="right">
            <button
              type="button"
              data-cy="CY-createNewEmailAccountButton"
              className="btn btn-primary"
              onClick={props.onCreateAccount}
            >
              <ResourceText resourceKey="createNewEmailAccount" />
            </button>
          </div>
        </div>
      </div>
    );
  }
);
