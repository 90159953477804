import { WithdrawReason, WithdrawReasonOption } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./withdraw-modal.component.scss");

interface AssignmentWithdrawModalComponentProps {
  displayName: string;
  withdrawReasonOptions: WithdrawReasonOption[];
  assignmentId: string;
  withdrawState: string;
  changeAvailabilityStatus: string;
  mandateDateTime: Date;
  onCloseAvailabilityModal: () => void;
  withdrawAssignment: (
    assignmentId: string,
    withdrawReason: WithdrawReason
  ) => void;
  onCancel: () => void;
}
interface AssignmentWithdrawModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentWithdrawModalComponent extends React.Component<
  AssignmentWithdrawModalComponentProps,
  AssignmentWithdrawModalComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;
  private focusRef: HTMLDivElement;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onCancelHandler = this.onCancelHandler.bind(this);
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);

    this.formControls = {
      withdrawReason: {
        value: "",
        validators: [
          Validators.required({ key: "error.chooseWithdrawReason" }),
        ],
      },
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssignmentWithdrawModalComponentProps
  ) {
    if (nextProps.changeAvailabilityStatus === REQUEST.SUCCESS) {
      this.props.onCloseAvailabilityModal();
    }
  }

  public render() {
    return (
      <div styleName="withdraw-modal" ref={(ref) => (this.focusRef = ref)}>
        <Form
          name="sold"
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
          onSubmit={this.onSubmitHandler}
        >
          <h1>
            <ResourceText
              resourceKey="withdrawModalTitle"
              values={{ displayName: this.props.displayName }}
              asHtml
            />
          </h1>

          {/* Withdraw reason */}
          <div className="form__row">
            <label htmlFor="withdrawReason">
              <ResourceText resourceKey="withdrawReason" />
            </label>
            <Input.RadioGroup name="withdrawReason" asButtonList multiline>
              {this.props.withdrawReasonOptions.map(
                (withdrawReasonOption, idx) => (
                  <Input.Radio
                    value={withdrawReasonOption.id}
                    label={withdrawReasonOption.displayName}
                    key={idx}
                  />
                )
              )}
            </Input.RadioGroup>
          </div>
        </Form>

        <div styleName="actions">
          <button
            type="button"
            className="btn"
            onClick={this.onCancelHandler}
            disabled={this.props.withdrawState === REQUEST.PENDING}
          >
            <ResourceText resourceKey="cancel" />
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.onSaveClickHandler}
            disabled={this.props.changeAvailabilityStatus === REQUEST.PENDING}
          >
            <ButtonLoader
              resourceKey="assignmentWithdraw"
              loading={this.props.changeAvailabilityStatus === REQUEST.PENDING}
            />
          </button>
        </div>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    if (this.props.changeAvailabilityStatus === REQUEST.PENDING) return;
    this.props.withdrawAssignment(
      this.props.assignmentId,
      values.withdrawReason
    );
  }

  private onCancelHandler() {
    if (this.props.withdrawState === REQUEST.PENDING) return;
    this.props.onCancel();
  }

  private onSaveClickHandler() {
    if (this.formRef && this.props.changeAvailabilityStatus !== REQUEST.PENDING)
      this.formRef.submit();
  }
}
