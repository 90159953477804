import { RelationSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { presentEmployees } from "../../selectors";
import { PresenceComponent, PresenceComponentProps } from "./presence";

type StateProps = {
  presentEmployees: {
    employee: RelationSnapShot;
    count: number;
    info: {
      appVersion: string;
      browser: string;
    };
  }[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  PresenceComponentProps,
  AppState
> = (state) => {
  return { presentEmployees: presentEmployees(state) };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PresenceComponentProps
> = (dispatch) => ({});

export type PresenceContainerProps = StateProps & DispatchProps;
export const PresenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PresenceComponent);
