import { Call } from "./calls.reducer";
import { VoipDetails } from "@haywork/api/voip";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";

export enum voipCallsActions {
  AddCall = "Voip.Calls.AddCall",
  ToggleCollapsed = "Voip.Calls.ToggleCollapsed",
  RemoveCall = "Voip.Calls.RemoveCall",
  SetLinkedRelations = "Voip.Calls.SetLinkedRelations",
  SetLinkedCommunicationLog = "Voip.Calls.SetLinkedCommunicationLog",
  SetLinkedRelationSearchStatus = "Voip.Calls.SetLinkedRelationSearchStatus"
}

interface AddCall {
  type: voipCallsActions.AddCall;
  call: VoipDetails;
}
const addCall = (call: VoipDetails) => ({
  type: voipCallsActions.AddCall,
  call
});

interface ToggleCollapsed {
  type: voipCallsActions.ToggleCollapsed;
  call: Call;
}
const toggleCollapsed = (call: Call) => ({
  type: voipCallsActions.ToggleCollapsed,
  call
});

interface RemoveCall {
  type: voipCallsActions.RemoveCall;
  call: Call;
}
const removeCall = (call: Call) => ({
  type: voipCallsActions.RemoveCall,
  call
});

interface SetLinkedRelations {
  type: voipCallsActions.SetLinkedRelations;
  linkedRelations: RelationSnapShot[];
  conversationId: string;
}
const setLinkedRelations = (
  linkedRelations: RelationSnapShot[],
  conversationId: string
) => ({
  type: voipCallsActions.SetLinkedRelations,
  linkedRelations,
  conversationId
});

interface SetLinkedCommunicationLog {
  type: voipCallsActions.SetLinkedCommunicationLog;
  linkedCommunicationLogId: string;
  conversationId: string;
}
const setLinkedCommunicationLog = (
  linkedCommunicationLogId: string,
  conversationId: string
) => ({
  type: voipCallsActions.SetLinkedCommunicationLog,
  linkedCommunicationLogId,
  conversationId
});

interface SetLinkedRelationSearchStatus {
  type: voipCallsActions.SetLinkedRelationSearchStatus;
  linkedRelationSearchStatus: RequestStatus;
  conversationId: string;
}
const setLinkedRelationSearchStatus = (
  linkedRelationSearchStatus: RequestStatus,
  conversationId: string
) => ({
  type: voipCallsActions.SetLinkedRelationSearchStatus,
  linkedRelationSearchStatus,
  conversationId
});

export type VoipCallsActions =
  | AddCall
  | ToggleCollapsed
  | RemoveCall
  | SetLinkedRelations
  | SetLinkedCommunicationLog
  | SetLinkedRelationSearchStatus;
export const Actions = {
  addCall,
  toggleCollapsed,
  removeCall,
  setLinkedRelations,
  setLinkedCommunicationLog,
  setLinkedRelationSearchStatus
};
