import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

interface Props {
  linkedRelation: LinkedRelation;
  onClick?: () => void;
}
interface State {}

const styles = require("../list-item/style.scss");
const route = RouteUtil.mapStaticRouteValues;

@CSSModules(styles, { allowMultiple: true })
export class LinkedRelationComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onStopPropagation = this.onStopPropagation.bind(this);
  }

  public render() {
    return (
      <Link to={this.renderRelationLink()} onClick={this.onStopPropagation}>
        <span styleName="linkedRelation">
          <i className="fal fa-fw fa-user-alt" />
          {this.props.linkedRelation.displayName}
        </span>
      </Link>
    );
  }

  private renderRelationLink(): string {
    const { typeOfRelation, id } = this.props.linkedRelation;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        return route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
      case RelationType.ContactCompany:
        return route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
      case RelationType.Employee:
        return route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
      case RelationType.Office:
        return route(OFFICESROUTES.BASE.URI, { id });
      default:
        return "";
    }
  }

  private onStopPropagation(event: React.MouseEvent<any>) {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
