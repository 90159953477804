import { OfferType, RealEstateGroup } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition
} from "@haywork/modules/form";
import { SearchAssignmentEditWhatContainerProps } from "@haywork/modules/search-assignment";
import {
  ResourceText,
  StepComponent,
  StepperComponent,
  StepperType
} from "@haywork/modules/shared";
import { ArrayUtil, FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  SearchAssignmentImportantAgriculturalComponent,
  SearchAssignmentImportantCommercialComponent,
  SearchAssignmentImportantResidentialComponent,
  SearchAssignmentWhatAgriculturalComponent,
  SearchAssignmentWhatCommercialComponent,
  SearchAssignmentWhatResidentialComponent
} from "./components";

const styles = require("./edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface SearchAssignmentEditWhatComponentProps {}
interface SearchAssignmentEditWhatComponentState {
  initialTab: number;
}
type Props = SearchAssignmentEditWhatComponentProps &
  SearchAssignmentEditWhatContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentEditWhatComponent extends React.Component<
  Props,
  SearchAssignmentEditWhatComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      offerType: {
        value: value(this.props.searchAssignment, "offerType", OfferType.Sale)
      },
      priceStart: { value: value(this.props.searchAssignment, "priceStart") },
      priceEnd: { value: value(this.props.searchAssignment, "priceEnd") },
      availableFrom: {
        value: value(this.props.searchAssignment, "availableFrom")
      },
      isPaid: { value: value(this.props.searchAssignment, "isPaid", false) }
    };

    this.state = {
      initialTab:
        this.props.location.hash && this.props.location.hash === "#wishes"
          ? 1
          : 0
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.searchAssignment, "dateTimeModified") !==
        get(this.props.searchAssignment, "dateTimeModified")
    ) {
      this.formRef.update(
        {
          offerType: value(
            this.props.searchAssignment,
            "offerType",
            OfferType.Sale
          ),
          priceStart: value(this.props.searchAssignment, "priceStart"),
          priceEnd: value(this.props.searchAssignment, "priceEnd"),
          availableFrom: value(this.props.searchAssignment, "availableFrom"),
          isPaid: value(this.props.searchAssignment, "isPaid", false)
        },
        true
      );
    }
  }

  public render() {
    const realEstateGroup =
      this.props.searchAssignment.realEstateGroup ||
      RealEstateGroup.Residential;

    const whatTitle =
      realEstateGroup === RealEstateGroup.Residential
        ? "whatAreYouLookingForTitle"
        : "whatAreYouLookingForTitleAlt";
    const saleOrRentResourceKey =
      realEstateGroup === RealEstateGroup.Agricultural
        ? "saleOrLease"
        : "saleOrRent";
    const offerTypeOptions =
      realEstateGroup === RealEstateGroup.Agricultural
        ? this.props.alvOfferTypeOptions
        : this.props.offerTypeOptions;

    return (
      <div styleName="search-assignment">
        <div className="container-fluid">
          <div styleName="price">
            <Form
              name="search-assignment-price"
              formControls={this.formControls}
              onChange={this.onChangeHandler}
              form={(form) => (this.formRef = form)}
            >
              <div className="form__row">
                <Input.Switch
                  name="isPaid"
                  on={true}
                  off={false}
                  label="searchAssignment.label.isPaid"
                  labelPosition={SwitchLabelPosition.Post}
                />
              </div>

              <div styleName="subTitle">
                <ResourceText resourceKey={saleOrRentResourceKey} />
              </div>
              <div className="form__row">
                <label htmlFor="offerType" className="sr-only">
                  <ResourceText resourceKey="saleOrRent" />
                </label>
                <Input.RadioGroup name="offerType" asButtonList canUnselect>
                  {offerTypeOptions.map((offerTypeOption, idx) => (
                    <Input.Radio
                      value={offerTypeOption.value}
                      label={offerTypeOption.displayName}
                      key={idx}
                    />
                  ))}
                </Input.RadioGroup>
              </div>

              <div className="form__row">
                <div className="form__group stretch">
                  {/* Price min/max */}
                  <label htmlFor="priceStart" className="pre">
                    <ResourceText resourceKey="priceIndication" />
                  </label>
                  <div className="column__spacer" />
                  <div className="column">
                    <div styleName="min-max">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number name="priceStart" pretty asPrice />
                      </div>
                      <div styleName="label">
                        <ResourceText resourceKey="untill" />
                      </div>
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number name="priceEnd" pretty asPrice />
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />

                  {/* Availability date */}
                  <label htmlFor="availableFrom" className="pre">
                    <ResourceText resourceKey="availableFromDate" />
                  </label>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.Datepicker name="availableFrom" placeholder="date" />
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <StepperComponent
            initial={this.state.initialTab}
            type={StepperType.Accordion}
            scrollToElementId="scroll-to-top"
          >
            {/* What are you looking for? */}
            <StepComponent title={whatTitle}>
              {this.renderWhatComponent()}
            </StepComponent>

            {/* What do you find important? */}
            <StepComponent title="whatDoYouFindImportant">
              {this.renderImportantComponent()}
            </StepComponent>
          </StepperComponent>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { searchAssignment } = this.props;

    const parsedValues = {};
    for (const key in values) {
      const value = values[key];
      switch (true) {
        case isArray(value): {
          parsedValues[key] = ArrayUtil.removeEmptyValues(value);
          break;
        }
        case !isArray(value): {
          parsedValues[key] = value;
          break;
        }
        default:
          break;
      }
    }

    const updatedSearchAssignment = {
      ...searchAssignment,
      ...parsedValues
    };

    this.props.updateSearchAssignmentCache(
      updatedSearchAssignment,
      this.props.path
    );
  }

  private renderWhatComponent() {
    const realEstateGroup =
      this.props.searchAssignment.realEstateGroup ||
      RealEstateGroup.Residential;

    switch (realEstateGroup) {
      case RealEstateGroup.Residential:
        return (
          <SearchAssignmentWhatResidentialComponent
            searchAssignment={this.props.searchAssignment}
            typePARTOptions={this.props.typePARTOptions}
            houseSorts={this.props.houseSorts}
            houseTypes={this.props.houseTypes}
            houseCharacteristics={this.props.houseCharacteristics}
            apartmentSorts={this.props.apartmentSorts}
            apartmentCharacteristics={this.props.apartmentCharacteristics}
            parkingTypeOptions={this.props.parkingTypeOptions}
            typePARTOtherOptions={this.props.typePARTOtherOptions}
            searchAssignmentPeculiarityOptions={
              this.props.searchAssignmentPeculiarityOptions
            }
            onChange={this.onChangeHandler}
          />
        );
      case RealEstateGroup.Commercial:
        return (
          <SearchAssignmentWhatCommercialComponent
            searchAssignment={this.props.searchAssignment}
            typeBOGOptions={this.props.typeBOGOptions}
            onChange={this.onChangeHandler}
          />
        );
      case RealEstateGroup.Agricultural:
        return (
          <SearchAssignmentWhatAgriculturalComponent
            searchAssignment={this.props.searchAssignment}
            TypeAlvOptions={this.props.typeALVOptions}
            TypeAlvOtherOptions={this.props.typeALVOtherOptions}
            onChange={this.onChangeHandler}
          />
        );
      default:
        return null;
    }
  }

  private renderImportantComponent() {
    const realEstateGroup =
      this.props.searchAssignment.realEstateGroup ||
      RealEstateGroup.Residential;

    switch (realEstateGroup) {
      case RealEstateGroup.Residential:
        return (
          <SearchAssignmentImportantResidentialComponent
            searchAssignment={this.props.searchAssignment}
            conditionOptions={this.props.conditionOptions}
            energyClassOptions={this.props.energyClassOptions}
            furnishingOptions={this.props.furnishingOptions}
            orientations={this.props.orientations}
            locationPlaces={this.props.locationPlaces}
            buildingTypeOptions={this.props.buildingTypeOptions}
            searchAssignmentFacilityOptions={
              this.props.searchAssignmentFacilityOptions
            }
            onChange={this.onChangeHandler}
          />
        );
      case RealEstateGroup.Commercial:
        return (
          <SearchAssignmentImportantCommercialComponent
            searchAssignment={this.props.searchAssignment}
            conditionOptions={this.props.conditionOptions}
            energyClassOptions={this.props.energyClassOptions}
            locationPlaces={this.props.locationPlaces}
            buildingTypeOptions={this.props.buildingTypeOptions}
            searchAssignmentFacilityOptions={
              this.props.searchAssignmentFacilityOptions
            }
            onChange={this.onChangeHandler}
          />
        );
      case RealEstateGroup.Agricultural:
        return (
          <SearchAssignmentImportantAgriculturalComponent
            searchAssignment={this.props.searchAssignment}
            conditionOptions={this.props.conditionOptions}
            energyClassOptions={this.props.energyClassOptions}
            locationPlaces={this.props.locationPlaces}
            buildingTypeOptions={this.props.buildingTypeOptions}
            searchAssignmentFacilityOptions={
              this.props.searchAssignmentFacilityOptions
            }
            onChange={this.onChangeHandler}
          />
        );
      default:
        return null;
    }
  }
}
