export const ACCOUNT = {
  SET_EMPLOYEE: "SET_ACCOUNT_EMPLOYEE",
  SET_EMPLOYEES_AND_OFFICES: "SET_ACCOUNT_EMPLOYEES_AND_OFFICES",
  EMPLOYEE_FAILURE: "ACCOUNT_EMPLOYEE_FAILURE",
  SET_EMPLOYEE_STATE: "SET_RELATION_EMPLOYEE_STATE",
  SET_ONBOARDING: "SET_ONBOARDING",
  SET_ACCOUNT_SETTINGS: "SET_ACCOUNT_SETTINGS",
  SET_FINANCIAL_ADMINISTRATIONS: "SET_FINANCIAL_ADMINISTRATIONS",
  SET_FINANCIAL_ADMINISTRATION_STATE: "SET_FINANCIAL_ADMINISTRATION_STATE",
  UPDATE_FINANCIAL_ADMINISTRATION: "UPDATE_FINANCIAL_ADMINISTRATION",
  SWITCH_REALESTATE_AGENCY: "SWITCH_REALESTATE_AGENCY",
  SET_SHOW_ALL_ASSIGNMENTS: "SET_SHOW_ALL_ASSIGNMENTS",
  SET_SHOW_ALL_KEYBOARD_ASSIGNMENTS: "SET_SHOW_ALL_KEYBOARD_ASSIGNMENTS",
};
