import { ObjectAssignment } from "@haywork/api/kolibri";
import { Group } from "@haywork/api/mls";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingComponent,
  AssignmentEditMarketingComponentProps,
} from "@haywork/modules/assignment";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  mlsGroups: Group[];
}
interface DispatchProps {
  navigate: (path: string, id: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingComponentProps,
  AppState
> = (state) => ({
  objectAssignment: state.editable.currentComponentState.objectAssignment,
  mlsGroups: state.mls.list.agencyGroups,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingComponentProps
> = (dispatch) => ({
  navigate: (path: string, id: string) =>
    dispatch(AssignmentThunks.navigateToMarketingRoute(path, id)),
});

export type AssignmentEditMarketingContainerProps = StateProps & DispatchProps;
export const AssignmentEditMarketingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingComponent);
