import { WidgetEntityType } from "@haywork/api/authorization";
import { RelationType } from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST, SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Notes from "@haywork/modules/notes-v3";
import {
  RelationSearchAssignmentItemComponent,
  RelationSearchAssignmentsContainerProps
} from "@haywork/modules/relation";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./search-assignments.component.scss");

export interface RelationSearchAssignmentsComponentProps {}
interface State {}
type Props = RelationSearchAssignmentsComponentProps &
  RelationSearchAssignmentsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class RelationSearchAssignmentsComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.props.getMatchingSearchAssignments(this.props.relationId);
    this.addNewSearchAssignment = this.addNewSearchAssignment.bind(this);
  }

  public componentDidMount() {
    const widgetEntityType =
      this.props.selectedRelationType === RelationType.ContactPerson
        ? WidgetEntityType.ContactPerson
        : WidgetEntityType.ContactCompany;

    this.props.getInitializedWidgets(this.props.relationId, widgetEntityType);
  }

  public render() {
    return (
      <div styleName="matching-search-assignments">
        <PageHeader
          title="pageTitle.relation.searchAssignments"
          subTitle="searchProfilesForRelation"
          subTitleValues={{ displayName: this.props.relationName }}
          actions={
            <>
              <Notes />
              <Button
                label="relation.searchAssignments.createSearchAssignment"
                category="primary"
                icon={<Icon name="plus" light size={18} />}
                iconPosition="start"
                onClick={this.addNewSearchAssignment}
              />
            </>
          }
        />

        <div styleName="search-assignments__header">
          <div styleName="column search">
            <ResourceText resourceKey="type" />
          </div>
          <div styleName="column is-paid">
            <ResourceText resourceKey="searchAssignment.overviewHeader.isPaid" />
          </div>
          <div styleName="column offertype">
            <ResourceText resourceKey="rentSale" />
          </div>
          <div styleName="column date">
            <ResourceText resourceKey="createdDate" />
          </div>
          <div styleName="column frequency">
            <ResourceText resourceKey="matchmail" />
          </div>
        </div>

        <div styleName="matching-search-assignments__list">
          {this.props.searchAssignments.map((searchAssignment, idx) => (
            <ErrorBoundary key={idx}>
              <RelationSearchAssignmentItemComponent
                searchAssignment={searchAssignment}
                zebra={idx % 2 === 0}
                onNavigateToSearchAssignment={this.props.navigate}
              />
            </ErrorBoundary>
          ))}

          {this.props.relationSearchAssignmentsStatus === REQUEST.PENDING && (
            <PageLoader
              loading
              fullscreen={this.props.searchAssignments.length === 0}
            />
          )}

          {this.props.relationSearchAssignmentsStatus === REQUEST.SUCCESS &&
            this.props.searchAssignments.length === 0 && (
              <div styleName="empty-state">
                <div styleName="content">
                  <div styleName="icon" />
                  <div styleName="text">
                    <h2>
                      <ResourceText resourceKey="relationSearchAssignmentsEmptyStateTitle" />
                    </h2>
                    <p>
                      <Link
                        to={route(
                          SEARCHASSIGNMENTROUTES.NEW_WITH_RELATION.URI,
                          { id: this.props.relationId }
                        )}
                      >
                        <ResourceText resourceKey="relationSearchAssignmentsEmptyStateBody" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }

  private addNewSearchAssignment() {
    const id = this.props.relationId;
    const path = route(SEARCHASSIGNMENTROUTES.NEW_WITH_RELATION.URI, {
      id
    });
    this.props.navigate(path);
  }
}
