import * as React from "react";

/**
 * @param {string} displayName - The text that is displayed on the button.
 * @param {string} eventText - Text that's shown once the button is clicked.
 * @param {number} intervalInSeconds - Interval in which the countdown takes place.
 * @param {number} countdownInSeconds - Total duration of the countdown in seconds.
 * @param {callback func} onButtonClickHandler - Starts the timer and returns void on click.
 * @param {onUndoClickHandler} - Stops and resets the timer and returns void on click.
 * @param {optional callback func} - Returns void when the timer has passed.
 */
interface TimedButtonComponentProps {
  displayName: string;
  eventText: string;
  intervalInSeconds: number;
  countdownInSeconds: number;
  onButtonClickHandler: () => void;
  onUndoClickHandler: () => void;
  onTimerHasPassed?: () => void;
}

interface TimedButtonComponentState {
  timerIsRunning: boolean;
  currentCount: number;
  interval: any;
}

/**
 * This button component starts a timer on click and throws an event when the timer has passed.
 * While the timer runs, a countdown shows up that stops and resets the timer on click.
 * This button stops propagation, which means click events on this button will not bubble up to
 * it's parent component.
 */
export class TimedButtonComponent extends React.Component<TimedButtonComponentProps, TimedButtonComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      timerIsRunning: false,
      currentCount: this.props.countdownInSeconds,
      interval: null
    };

    this.onDeactiveButtonClick = this.onDeactiveButtonClick.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);
    this.timer = this.timer.bind(this);
    this.renderTimer = this.renderTimer.bind(this);
  }

  public render() {
    return (
      <div>
        { (!this.state.timerIsRunning) ?
          <button
            className="btn btn-primary"
            onClick={ this.onDeactiveButtonClick }
            data-cy={ this.props["data-cy"] }>
            <i className="fal fa-fw fa-archive" />
            <span className="sr-only">{ this.props.displayName }</span>
          </button>
          :
          this.renderTimer()
        }
      </div>
    );
  }

  public componentWillUnmount() {
    clearTimeout(this.state.interval);
  }

  public onDeactiveButtonClick(event) {
    event.stopPropagation();
    const timer = setInterval(() => this.timer(), this.props.intervalInSeconds * 1000);
    this.setState({
      timerIsRunning: true,
      interval: timer
    });
    this.props.onButtonClickHandler();
  }

  public timer() {
    if (this.state.currentCount === 0) return this.resetCountState();
    this.setState({
      currentCount: this.state.currentCount - 1
    });
  }

  public renderTimer() {
    return <span
      className="renderTimer"
      onClick={ this.onUndoClick }
      data-cy={this.props["data-cy"]}
    >
        <span>{ this.props.eventText } ({ this.state.currentCount })</span>
    </span>;
  }

  public onUndoClick(event) {
    event.stopPropagation();
    this.resetCountState();
    this.props.onUndoClickHandler();
  }

  private resetCountState() {
    const prevCount = this.state.currentCount;
    this.setState({
      timerIsRunning: false,
      currentCount: this.props.countdownInSeconds
    });
    clearTimeout(this.state.interval);
    if (this.props.onTimerHasPassed && prevCount <= 0) {
      this.props.onTimerHasPassed();
    }
  }
}
