import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { ModalPortal } from "@haywork/portals";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  visible: boolean;
  type: "mls" | "invoice";
};

export const PrintLoaderComponent: FC<Props> = memo(
  CSSModules(styles)(({ visible, type }) => {
    if (!visible) return null;

    const text = useMemo(() => {
      switch (type) {
        case "mls":
          return "mls.print.loading";
        case "invoice":
          return "invoice.mail.loading";
        default:
          break;
      }
    }, []);

    return (
      <ModalPortal>
        <div styleName="print-loader">
          <div styleName="modal">
            <div styleName="animation" />
            <div styleName="body">
              <I18n value={text} />
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  })
);
