import { Provider } from "@haywork/api/mail";
import {
  EmailWelcomeComponent,
  EmailWelcomeComponentProps
} from "@haywork/modules/email";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "@haywork/middleware";

interface StateProps {
  providers: Provider[];
}
interface DispatchProps {
  navigate: (url: string) => void;
}

const mapStateToProps = <StateProps, EmailWelcomeComponentProps>(
  state: AppState
) => {
  const { providers } = state.emailV2.providers;

  return {
    providers
  };
};

const mapDispatchToProps = <DispatchProps, EmailWelcomeComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url))
});

export type EmailWelcomeContainerProps = StateProps & DispatchProps;
export const EmailWelcomeContainer = connect<
  StateProps,
  DispatchProps,
  EmailWelcomeComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailWelcomeComponent);
