import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  AssignmentSnapShot,
  LinkedAssignment,
  LinkedEmployee,
  ProjectAssignment,
  PublicationSnapShot,
  PublicationStatus,
  TimelineEvent,
  UpdateAvailabilityAction,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  AuthorizationThunks,
  Dispatch,
  InvoiceThunk,
  ProjectsThunks,
  SchedulerThunks,
  TaskThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  ProjectDetailGeneralComponent,
  ProjectDetailGeneralComponentProps,
} from "@haywork/modules/project/components/detail-general/detail-general.component";
import { canSendEmail } from "@haywork/selector";
import { AppState, ProjectsActions, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  buildingStartText,
  canCreateObjectAssignment,
  canCreateObjectTypeAssignment,
  contentsRange,
  deliveryStartText,
  livingRoomRange,
  parcelRange,
  priceRange,
  projectPrice,
  publications,
  saleStartText,
} from "./selectors";

export interface Publications {
  activated: PublicationSnapShot[];
  inActivationProcess: PublicationSnapShot[];
  rejected: PublicationSnapShot[];
  offered: PublicationSnapShot[];
  withdrawn: PublicationSnapShot[];
  inActive: PublicationSnapShot[];
}

interface StateProps {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  mediaPartners: Publications;
  pastEvents: TimelineEvent[];
  futureEvents: TimelineEvent[];
  isInitial: boolean;
  path: string;
  saveAssignmentState: string;
  priceRange: NumberRange;
  parcelRange: NumberRange;
  livingRoomRange: NumberRange;
  contentsRange: NumberRange;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;
  addBuildnumbersVisible: boolean;
  price: number;
  objectTypes: AssignmentSnapShot[];
  buildnumbers: AssignmentSnapShot[];
  canSendEmail: boolean;
  canCreateObjectTypeAssignment: boolean;
  canCreateObjectAssignment: boolean;
}

interface DispatchProps {
  edit: (id: string) => void;
  toggleStatusModal: (status: boolean) => void;
  toggleWithdrawModal: (status: boolean) => void;
  deleteAssignment: (id: string) => void;
  updateAvailability: (
    updateAvailabilityAction: UpdateAvailabilityAction,
    id: string
  ) => void;
  navigate: (url: string) => void;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  saveProject: () => void;
  onAddNewType: (projectId: string) => void;
  toggleAddBuildnumbers: (visible: boolean) => void;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  createTaskWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployees: LinkedEmployee[]
  ) => Promise<void>;
  createNewEmail: (
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) => void;
}

const route = RouteUtil.mapStaticRouteValues;

const mapMediaPartners = (
  publications: PublicationSnapShot[]
): Publications => {
  const mappedPublications = publications || [];

  return mappedPublications.reduce(
    (state, publication) => {
      switch (publication.publicationStatus) {
        case PublicationStatus.Published:
          state.activated.push(publication);
          break;
        case PublicationStatus.PublishRequestEnqueued:
        case PublicationStatus.WaitingForExchangeEntityContract:
        case PublicationStatus.WaitingForObligatoryPublications:
          state.inActivationProcess.push(publication);
          break;
        case PublicationStatus.Inactive:
          if (publication.mediaPartnerIsActive) {
            state.inActive.push(publication);
          }
          break;
        case PublicationStatus.Rejected:
          state.rejected.push(publication);
          break;
        case PublicationStatus.Offered:
          state.offered.push(publication);
          break;
        case PublicationStatus.Withdrawn:
          state.withdrawn.push(publication);
          break;
        default:
          break;
      }

      return state;
    },
    {
      activated: [],
      inActivationProcess: [],
      rejected: [],
      offered: [],
      withdrawn: [],
      inActive: [],
    }
  );
};

const mapStateToProps = <StateProps, AssignmentDetailGeneralComponentProps>(
  state: AppState
) => {
  const {
    projectAssignment,
    pastEvents,
    futureEvents,
    isInitial,
    saveAssignmentState,
    objectTypes,
    buildnumbers,
  } = state.project.single;
  const { addBuildnumbersVisible } = state.project.buildnumbers;
  const { currentComponentState } = state.editable;

  return {
    projectAssignment: currentComponentState.projectAssignment,
    currentComponentState,
    mediaPartners: mapMediaPartners(publications(state)),
    pastEvents,
    futureEvents,
    isInitial,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id,
    }),
    saveAssignmentState,
    priceRange: priceRange(state),
    parcelRange: parcelRange(state),
    livingRoomRange: livingRoomRange(state),
    contentsRange: contentsRange(state),
    buildingStartText: buildingStartText(state),
    deliveryStartText: deliveryStartText(state),
    saleStartText: saleStartText(state),
    addBuildnumbersVisible,
    price: projectPrice(state),
    objectTypes,
    buildnumbers,
    canSendEmail: canSendEmail(state),
    canCreateObjectTypeAssignment: canCreateObjectTypeAssignment(state),
    canCreateObjectAssignment: canCreateObjectAssignment(state),
  };
};

const mapDispatchToProps = <DispatchProps, ProjectDetailGeneralComponentProps>(
  dispatch: Dispatch<any>
) => ({
  edit: (id: string) => dispatch(push(route(PROJECTROUTES.EDIT.URI, { id }))),
  toggleStatusModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleStatusModal(status)),
  toggleWithdrawModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleWithdrawModal(status)),
  deleteAssignment: (id: string) =>
    dispatch(ProjectsThunks.Projects.deleteProject(id)),
  updateAvailability: (
    updateAvailabilityAction: UpdateAvailabilityAction,
    id: string
  ) =>
    dispatch(AssignmentThunks.updateAvailability(updateAvailabilityAction, id)),
  navigate: (url: string) => dispatch(push(url)),
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      ProjectsThunks.Projects.updateProjectEditable(componentState, path)
    ),
  saveProject: () => dispatch(ProjectsThunks.Projects.saveProject()),
  onAddNewType: (projectId: string) =>
    dispatch(ProjectsThunks.Types.newType(projectId)),
  toggleAddBuildnumbers: (visible: boolean) =>
    dispatch(ProjectsActions.Buildnumbers.toggleAddBuildnumbers(visible)),
  archive: (id: string) => dispatch(ProjectsThunks.Projects.archive(id)),
  unArchive: (id: string) => dispatch(ProjectsThunks.Projects.unArchive(id)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  createTaskWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(
      TaskThunks.createTaskWithLinkedEntities(undefined, [linkedAssignment])
    ),
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        linkedRelation,
        linkedAssignment,
        undefined,
        undefined,
        "Consumer"
      )
    ),
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployees: LinkedEmployee[]
  ) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities(
        linkedRelations,
        [linkedAssignment],
        linkedEmployees
      )
    ),
  createNewEmail: (linkedAssignment: LinkedAssignment, displayName: string) =>
    dispatch(
      EmailThunk.Main.createNewEmail([], [], [linkedAssignment], displayName)
    ),
});

export type ProjectDetailGeneralContainerProps = StateProps & DispatchProps;
export const ProjectDetailGeneralContainer = connect<
  StateProps,
  DispatchProps,
  ProjectDetailGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailGeneralComponent);
