import { PersonSettings, Company } from "@haywork/api/mail";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "@haywork/stores/helpers";

import * as ActionType from "./settings.types";

export interface SettingsState {
  redirectUri: string;
  createStatus: string;
  personSettings: PersonSettings;
  company: Company;
  accountAddedVisibility: boolean;
}

const INITIAL_STATE: SettingsState = {
  redirectUri: null,
  createStatus: REQUEST.IDLE,
  personSettings: null,
  company: null,
  accountAddedVisibility: false
};

export const settingsReducer = (
  state: SettingsState = INITIAL_STATE,
  action: Action
): SettingsState => {
  switch (action.type) {
    case REDUX.EMAILV2.SET_REDIRECT_URI: {
      const { redirectUri } = <ActionType.RedirectUri>action;

      return {
        ...state,
        redirectUri,
        createStatus: REQUEST.IDLE
      };
    }
    case REDUX.EMAILV2.SET_CREATE_STATUS: {
      const { createStatus } = <ActionType.CreateStatus>action;

      return {
        ...state,
        createStatus
      };
    }
    case REDUX.EMAILV2.SET_SETTINGS: {
      let { personSettings, company } = <ActionType.Settings>action;

      personSettings = personSettings || state.personSettings;
      company = company || state.company;

      return {
        ...state,
        personSettings,
        company
      };
    }
    case REDUX.EMAILV2.SET_ACCOUNT_ADDED_VISIBILITY: {
      const { accountAddedVisibility } = <ActionType.AccountAddedVisibility>(
        action
      );

      return {
        ...state,
        accountAddedVisibility
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
