import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { SingleFilterValue } from "@haywork/components/ui/list";

const masterTableSelector = (state: AppState) => state.main.mastertable;
const filtersSelector = (state: AppState) => state.invoice.list.filters;

export const statusFilterValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { invoiceStatuses } = mastertable.kolibri;
    return (invoiceStatuses || []).map(
      (invoiceStatus) =>
        ({
          label: invoiceStatus.displayName,
          value: invoiceStatus.value,
        } as SingleFilterValue)
    );
  }
);

export const overdueValue = createSelector(filtersSelector, (filters) => {
  return { value: filters.overdue.value } as SingleFilterValue;
});

export const invoiceDateValue = createSelector(filtersSelector, (filters) => {
  return { value: filters.invoiceDate.value } as SingleFilterValue;
});
