import { Dispatch, EditableThunks } from "@haywork/middleware";
import { TabBarComponent, TabBarComponentProps } from "@haywork/modules/tab";
import {
  AppState,
  EditableActions,
  EditableItem,
  LayoutActions,
} from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

type KeyValue = { key: string; values?: { [key: string]: any } };

interface StateProps {
  tabs: EditableItem[];
  currentPath: string;
}

interface DispatchProps {
  removeState: (path: string) => void;
  stateSetPreppedForSave: (path: string) => void;
  showConfirm: (
    title: KeyValue,
    body: KeyValue,
    positiveFn: Function,
    negativeFn: Function,
    negativeLabel: KeyValue,
    positiveLabel: KeyValue
  ) => void;
  navigate: (path: string) => void;
  updateReferrer: (path: string, referrer: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  TabBarComponentProps,
  AppState
> = (state: AppState) => ({
  tabs: state.editable.states,
  currentPath: state.editable.currentPath,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  removeState: (path: string) => dispatch(EditableThunks.remove(path)),
  stateSetPreppedForSave: (path: string) =>
    dispatch(EditableActions.stateSetPreppedForSave({ path })),
  showConfirm: (
    title: KeyValue,
    body: KeyValue,
    positiveFn: Function,
    negativeFn: Function,
    positiveLabel: KeyValue,
    negativeLabel: KeyValue
  ) =>
    dispatch(
      LayoutActions.showConfirm({
        title,
        body,
        positiveFn,
        negativeFn,
        negativeLabel,
        positiveLabel,
      })
    ),
  navigate: (path: string) => dispatch(push(path)),
  updateReferrer: (path: string, referrer: string) =>
    dispatch(EditableActions.updateReferrer({ path, referrer })),
});

export type TabBarContainerProps = StateProps & DispatchProps;
export const TabBarContainer = connect<
  StateProps,
  DispatchProps,
  TabBarComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(TabBarComponent);
