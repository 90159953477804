import { WidgetEntityType } from "@haywork/api/authorization";
import { LinkedAssignment } from "@haywork/api/kolibri";
import { AssignmentDetailVersionsContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ListItem } from "./list-item.component";
import PageHeader from "@haywork/components/ui/page-header";
import Notes from "@haywork/modules/notes-v3";

const styles = require("./detail-versions.component.scss");

export interface AssignmentDetailVersionsComponentProps {}
interface State {}
type Props = AssignmentDetailVersionsComponentProps &
  AssignmentDetailVersionsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailVersionsComponent extends React.Component<
  Props,
  State
> {
  public componentDidMount() {
    this.props.getInitializedWidgets(
      get(this.props.objectAssignment, "id"),
      WidgetEntityType.ObjectAssignment
    );
  }

  public render() {
    let assignments =
      get(this.props.objectAssignment, "linkedObjectAssignments") || [];
    assignments = sortBy(assignments, (assignment: LinkedAssignment) =>
      moment(assignment.dateTimeCreated).unix()
    ).reverse();

    return (
      <div styleName="versions">
        <PageHeader
          title="pageTitle.assignment.versions"
          subTitle={this.props.objectAssignment.displayName}
          actions={<Notes />}
        />

        <div styleName="versions__body">
          {assignments.map((assignment, idx) => (
            <ErrorBoundary key={assignment.id}>
              <ListItem
                assignment={assignment}
                zebra={idx % 2 !== 0}
                isLatest={idx === 0}
                onClick={this.props.navigate}
              />
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }
}
