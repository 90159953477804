import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ModalPortal } from "@haywork/portals";
import {
  Input,
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
} from "@haywork/modules/form";
import { MergeFieldCategory, MergeField } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";

import { MergeFieldsContainerProps } from "./merge-fields.container";
import { Results } from "./results.component";

const styles = require("./merge-fields.component.scss");

export interface MergeFieldsComponentProps {
  visible: boolean;
  parent: HTMLElement;
  categoryWhiteList?: MergeFieldCategory[];
  categoryBlackList?: MergeFieldCategory[];
  onHide: () => void;
  onAdd: (field: MergeField) => void;
}
interface State {
  mergeFieldStyle: React.CSSProperties;
  mergeFieldPointerStyle: React.CSSProperties;
  mergeFieldCategoryValue: string;
  mergeFieldFilterValue: string;
}
type Props = MergeFieldsComponentProps & MergeFieldsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class MergeFieldsComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;
  private formControls: FormControls;
  private form: FormReference;
  private outerRefs: HTMLDivElement[] = [];

  constructor(props) {
    super(props);

    this.state = {
      mergeFieldStyle: null,
      mergeFieldPointerStyle: null,
      mergeFieldCategoryValue: "",
      mergeFieldFilterValue: "",
    };

    this.formControls = {
      mergeFieldCategory: { value: "" },
      mergeFieldSubject: { value: "" },
    };

    this.calculateMergeFieldStyles = this.calculateMergeFieldStyles.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.bindOuterRefs = this.bindOuterRefs.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    if (!this.props.visible || !this.props.parent) return null;
    const { mergeFieldCategoryValue, mergeFieldFilterValue } = this.state;
    const mergeFields = this.props.mergeFields.filter((field) => {
      const inCategory = !!mergeFieldCategoryValue
        ? field.categoryDisplayName === mergeFieldCategoryValue
        : true;
      const hasMatch = !!mergeFieldFilterValue
        ? field.displayName
            .toLowerCase()
            .includes(mergeFieldFilterValue.toLowerCase())
        : true;

      return inCategory && hasMatch;
    });

    return (
      <ModalPortal>
        <div
          styleName="merge-fields"
          style={this.state.mergeFieldStyle}
          ref={(ref) => (this.ref = ref)}
        >
          <div styleName="merge-fields__wrapper">
            <div styleName="filters">
              <Form
                name="mergeFieldsControl"
                asSubForm={true}
                formControls={this.formControls}
                form={(form) => (this.form = form)}
                onChange={this.onFormChange}
              >
                <div className="form__row">
                  <label htmlFor="mergeFieldCategory">
                    <ResourceText resourceKey="mergeFieldsLabel.mergeFieldCategory" />
                  </label>
                  <Input.NewSelect
                    name="mergeFieldCategory"
                    values={this.props.mergeFieldCategories}
                    addEmptyOption
                    emptyOptionLabel="allMergeFields"
                    displayProp="categoryDisplayName"
                    valuesProp="categoryDisplayName"
                    outerRef={this.bindOuterRefs}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="mergeFieldSubject">
                    <ResourceText resourceKey="mergeFieldsLabel.mergeFieldSubject" />
                  </label>
                  <Input.Text
                    name="mergeFieldSubject"
                    placeholder="mergeFieldsPlaceholder.mergeFieldSubject"
                    fireAllChanges={true}
                    data-cy="CY-mergeFieldSubject"
                  />
                </div>
              </Form>
            </div>

            <Results
              results={mergeFields}
              searchString={this.state.mergeFieldFilterValue}
              onClick={this.props.onAdd}
            />
          </div>
          <div
            styleName="merge-fields__pointer"
            style={this.state.mergeFieldPointerStyle}
          />
        </div>
      </ModalPortal>
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!!this.props.visible && !prevProps.visible) {
      this.form.update({
        mergeFieldSubject: "",
      });
      this.setState({
        ...this.calculateMergeFieldStyles(),
      });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onFormChange(values: FormReturnValue) {
    this.setState({
      mergeFieldCategoryValue: values.mergeFieldCategory,
      mergeFieldFilterValue: values.mergeFieldSubject,
    });
  }

  private onClickOutsideHandler(event: any) {
    if (!this.props.visible || !this.ref || !this.props.parent) return;

    let clickedInside =
      this.ref.contains(event.target) ||
      this.props.parent.contains(event.target);

    this.outerRefs.forEach((ref) => {
      if (!clickedInside && ref.contains(event.target)) {
        clickedInside = true;
      }
    });

    if (!clickedInside) {
      this.props.onHide();
    }
  }

  private calculateMergeFieldStyles(): {
    mergeFieldStyle: React.CSSProperties;
    mergeFieldPointerStyle: React.CSSProperties;
  } {
    const windowWidth = window.innerWidth;
    const { width: refWidth } = this.ref.getBoundingClientRect();
    const {
      top: parentTop,
      left: parentLeft,
      width: parentWidth,
      height: parentHeight,
    } = this.props.parent.getBoundingClientRect();
    const offsetWidth = 4;

    let mergeFieldStyle: React.CSSProperties = {
      left: parentLeft + parentWidth + offsetWidth,
      top: parentTop,
    };
    let mergeFieldPointerStyle: React.CSSProperties = {
      left: -5,
      top: parentHeight / 2 - 5,
    };

    if (parentLeft + parentWidth + offsetWidth + refWidth > windowWidth - 16) {
      mergeFieldStyle = {
        ...mergeFieldStyle,
        left: parentLeft - refWidth - offsetWidth,
      };
      mergeFieldPointerStyle = {
        ...mergeFieldPointerStyle,
        left: refWidth - 5,
      };
    }

    return {
      mergeFieldStyle,
      mergeFieldPointerStyle,
    };
  }

  private bindOuterRefs(ref: React.MutableRefObject<HTMLDivElement>) {
    if (!ref || !ref.current) return;
    const outerRef = this.outerRefs.find((el) => el === ref.current);
    if (!!outerRef) return;
    this.outerRefs = [...this.outerRefs, ref.current];
  }
}
