import { BidType, LinkedRelation } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo } from "react";
import RelationsToNotify from "../relations-to-notify";

type Props = {
  type: BidType;
  loading: boolean;
  relationsToNotify?: LinkedRelation[];
  onReject: () => void;
};
export const RejectComponent: FC<Props> = memo(
  ({ loading, type, relationsToNotify, onReject }) => {
    return (
      <>
        <ModalBody>
          <div className="form__row">
            <I18n
              value={
                type === BidType.Incoming
                  ? "bid.modal.decline.body"
                  : "bid.modal.reject.body"
              }
            />
          </div>
          {!!relationsToNotify && !!relationsToNotify.length && (
            <RelationsToNotify relations={relationsToNotify} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label="bid.modal.reject.action"
            disabled={loading}
            onClick={onReject}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
          />
        </ModalFooter>
      </>
    );
  }
);
