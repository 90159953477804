import {
  BidSnapShot,
  LinkedAssignment,
  AssignmentSnapShot,
  AcquisitionStatus,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import FormattedText from "@haywork/components/ui/formatted-text";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import Assignment from "./assignment";

const styles = require("./style.scss");

type Props = {
  assignments: AssignmentSnapShot[];
  onGotoAssignments: () => void;
  onNavigate: (path: string) => void;
};

export const AssignmentsInNegotiationOrWonComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignments, onGotoAssignments, onNavigate }) => {
      const assignmentsInNegotiation = useMemo(
        () =>
          assignments.filter(
            (a) => a.acquisitionStatus === AcquisitionStatus.UnderNegociation
          ),
        [assignments]
      );

      const assignmentsWon = useMemo(
        () =>
          assignments.filter(
            (a) =>
              a.acquisitionStatus === AcquisitionStatus.Purchased ||
              a.acquisitionStatus === AcquisitionStatus.Rented
          ),
        [assignments]
      );

      return (
        <div styleName="section">
          {assignmentsWon && assignmentsWon.length > 0 && (
            <FormattedText value="acquisition.sectionTitle.Won" asSection />
          )}
          {assignmentsWon.map((assignment) => (
            <Assignment
              assignment={assignment}
              key={assignment.id}
              won
              onNavigate={onNavigate}
            />
          ))}

          {assignmentsInNegotiation && assignmentsInNegotiation.length > 0 && (
            <FormattedText
              value="acquisition.sectionTitle.inNegotiation"
              asSection
            />
          )}

          {assignmentsInNegotiation.map((assignment) => (
            <Assignment
              assignment={assignment}
              key={assignment.id}
              onNavigate={onNavigate}
            />
          ))}
          <span className="as-link" onClick={onGotoAssignments}>
            <I18n value="acquisition.gotoAssignments" />
          </span>
        </div>
      );
    }
  )
);
