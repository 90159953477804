import {
  FinancialAdministration,
  FinancialAdministrationGroup,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import noop from "lodash-es/noop";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./add-financial-product.component.scss");

interface ProductFormComponentProps {
  showProductGroupForm: boolean;
  financialAdministrationState: string;
  financialAdministrations: FinancialAdministration[];
  onAddProductGroup: (value: string) => void;
  onFormRef: (form: FormReference) => void;
}
interface ProductFormComponentState {
  currentFinancialAdministration: FinancialAdministration;
}

@CSSModules(styles, { allowMultiple: true })
export class ProductFormComponent extends React.Component<
  ProductFormComponentProps,
  ProductFormComponentState
> {
  private ref: FormReference;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.bindFormRef = this.bindFormRef.bind(this);
    this.compareProductGroupsHandler = this.compareProductGroupsHandler.bind(
      this
    );

    const currentFinancialAdministration = this.props
      .financialAdministrations[0];

    this.state = {
      currentFinancialAdministration,
    };

    this.formControls = {
      financialAdministration: {
        value: currentFinancialAdministration.id,
        onChange: (ref) => {
          const currentFinancialAdministration = !ref.value
            ? this.props.financialAdministrations[0]
            : this.props.financialAdministrations.find(
                (a) => a.id === ref.value
              );
          this.setState({ currentFinancialAdministration });
        },
      },
      descriptionShort: {
        value: "",
        validators: [Validators.required()],
      },
      description: { value: "" },
      unitPriceNet: {
        value: "",
        validators: [Validators.required()],
      },
      isMarketingProduct: { value: true },
      taxRateId: { value: "" },
      exportLedgerAccountCode: { value: "" },
      groupId: { value: "" },
    };
  }

  public render() {
    const disabled =
      this.props.financialAdministrationState === REQUEST.PENDING;

    return (
      <Form
        name="add-product"
        formControls={this.formControls}
        form={this.bindFormRef}
        onSubmit={noop}
      >
        <div className="form__row">
          <label htmlFor="financialAdministration">
            <ResourceText resourceKey="whichAdministration" />
          </label>
          <Input.NewSelect
            name="financialAdministration"
            values={this.props.financialAdministrations}
            displayProp="name"
            valuesProp="id"
            disabled={disabled}
          />
        </div>

        <div className="form__row">
          <label htmlFor="descriptionShort">
            <ResourceText resourceKey="productName" />
          </label>
          <Input.Text
            name="descriptionShort"
            disabled={disabled}
            data-cy={
              this.props["data-cy"] &&
              `${this.props["data-cy"]}.DescriptionShortInput`
            }
          />
        </div>

        <div className="form__row">
          <label htmlFor="description">
            <ResourceText resourceKey="productDescription" />
          </label>
          <Input.Textarea
            name="description"
            disabled={disabled}
            autoSize
            data-cy={
              this.props["data-cy"] &&
              `${this.props["data-cy"]}.DescriptionInput`
            }
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="unit-price">
              <label htmlFor="unitPriceNet">
                <ResourceText resourceKey="productPrice" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number
                  name="unitPriceNet"
                  max={9999999}
                  disabled={disabled}
                  pretty
                  data-cy={
                    this.props["data-cy"] &&
                    `${this.props["data-cy"]}.UnitPriceNetInput`
                  }
                />
                <div className="post full">
                  <ResourceText resourceKey="exVat" />
                </div>
              </div>
            </div>

            <div className="column__spacer" />

            <div className="column push-label" styleName="marketing-product">
              <Input.Switch
                name="isMarketingProduct"
                label="isMarketingProduct"
                on={true}
                off={false}
                disabled={disabled}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.IsMarketingProductInput`
                }
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="tax-rate">
              <label htmlFor="taxRateId">
                <ResourceText resourceKey="taxRateId" />
              </label>
              <Input.NewSelect
                name="taxRateId"
                values={this.state.currentFinancialAdministration.taxRates}
                displayProp="description"
                valuesProp="id"
                disabled={disabled}
              />
            </div>

            <div className="column__spacer" />

            <div className="column" styleName="ledger-account">
              <label htmlFor="exportLedgerAccountCode">
                <ResourceText resourceKey="exportLedgerAccountCode" />
              </label>
              <Input.Text
                name="exportLedgerAccountCode"
                maxLength={32}
                disabled={disabled}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.ExportLedgerAccountCodeInput`
                }
              />
            </div>
          </div>
        </div>

        {!this.props.showProductGroupForm && (
          <div className="form__row">
            <label htmlFor="groupId">
              <ResourceText resourceKey="productGroup" />
            </label>
            <Input.ExtendedSelect
              name="groupId"
              disabled={disabled}
              values={this.state.currentFinancialAdministration.groups}
              displayPath="name"
              valuePath="id"
              firstAsDefault
              compareFn={this.compareProductGroupsHandler}
              addText="addNewProductGroup"
              onAdd={this.props.onAddProductGroup}
              data-cy="CY-groupIdInput"
            />
          </div>
        )}
      </Form>
    );
  }

  private compareProductGroupsHandler(
    listValue: FinancialAdministrationGroup,
    receivedValue: number
  ): boolean {
    return listValue.id === receivedValue;
  }

  private bindFormRef(ref: FormReference) {
    if (!this.ref && !!ref) {
      this.ref = ref;
      this.props.onFormRef(ref);
    }
  }
}
