import {
  AssignmentType,
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  RawFormControl,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { EmailMessage } from "@haywork/stores/email-v2";
import { AssignmentUtil } from "@haywork/util";
import differenceBy from "lodash-es/differenceBy";
import uniq from "lodash-es/uniq";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SaveModalContainerProps } from "./save-modal.container";

export type SaveModalComponentProps = {
  visible: boolean;
  message: EmailMessage;
  communicationLog: CommunicationLog | null;
  onClose: (refresh?: boolean) => void;
  onAddRelation: (query: string, communicationLog: CommunicationLog) => void;
};
type Props = SaveModalComponentProps & SaveModalContainerProps;

export const SaveModalComponent: FC<Props> = memo(
  ({
    visible,
    message,
    communicationLog,
    onClose,
    onAddRelation,
    saveCommunicationLog,
    navigate,
    getRelationsWithMatchingEmailAddress,
    getAssignmentsLinkedToRelations,
    defineNewCommunicationLog,
    getLinkedAssignment,
    getLinkedAcquisition,
    autoRemoveMessageAfterPersist,
  }) => {
    const { subject } = message;

    const [loading, setLoading] = useState(false);
    const [fetchingRelations, setFetchingRelations] = useState(false);
    const [fetchingAssignments, setFetchingAssignments] = useState(false);
    const form = useRef<FormReference>(null);
    const [valid, setValid] = useState(false);
    const selectedLinkedAssignments = useRef(
      communicationLog?.linkedAssignments || []
    );
    const selectedLinkedRelations = useRef(
      communicationLog?.linkedRelations || []
    );
    const init = useRef(false);
    const allowSuggestionSideEffects = useRef(false);

    const handleLinkedRelationsChange = async (control: RawFormControl) => {
      if (!allowSuggestionSideEffects.current) return;

      try {
        setFetchingAssignments(true);

        const newLinkedRelations = differenceBy(
          control.value as (LinkedRelation | RelationSnapShot)[],
          selectedLinkedRelations.current,
          (relation) => relation.id
        ).filter((relation) =>
          [RelationType.ContactCompany, RelationType.ContactPerson].includes(
            relation.typeOfRelation
          )
        );
        let linkedAssignments = selectedLinkedAssignments.current;

        if (!!newLinkedRelations.length) {
          const relationsIds = newLinkedRelations.map(
            (relation) => relation.id
          );
          const assignments = await getAssignmentsLinkedToRelations(
            relationsIds
          );

          linkedAssignments = assignments.map(
            AssignmentUtil.mapAssignmentSnapshotToLinkedAssignment
          );
          linkedAssignments = uniqBy(
            [...selectedLinkedAssignments.current, ...linkedAssignments],
            (assignment) => assignment.id
          );
        }

        selectedLinkedRelations.current = control.value || [];
        selectedLinkedAssignments.current = linkedAssignments;

        return {
          linkedAssignments,
        };
      } finally {
        setFetchingAssignments(false);
      }
    };

    const handleLinkedAssignmentsChange = async (control: RawFormControl) => {
      if (!allowSuggestionSideEffects.current) return;
      try {
        setFetchingRelations(true);

        const newLinkedAssignments = differenceBy(
          control.value as LinkedAssignment[],
          selectedLinkedAssignments.current,
          (assignment) => assignment.id
        ).filter((assignment) =>
          [AssignmentType.Object, AssignmentType.Acquisition].includes(
            assignment.typeOfAssignment
          )
        );
        let linkedRelations = selectedLinkedRelations.current;
        if (!!newLinkedAssignments.length) {
          const objectPromises = newLinkedAssignments
            .filter(
              (assignment) =>
                assignment.typeOfAssignment === AssignmentType.Object
            )
            .map((assignment) => getLinkedAssignment(assignment.id));
          const acquisitionPromises = newLinkedAssignments
            .filter(
              (assignment) =>
                assignment.typeOfAssignment === AssignmentType.Acquisition
            )
            .map((assignment) => getLinkedAcquisition(assignment.id));

          const objects = await Promise.all(objectPromises);
          const acquisitions = await Promise.all(acquisitionPromises);

          const objectLinkedRelations = objects.reduce((state, assignment) => {
            [
              ...(assignment.linkedApplicants || []),
              ...(assignment.linkedVendors || []),
              ...(assignment.linkedClients || []),
            ].map((relation) => state.push(relation));

            return state;
          }, [] as LinkedRelation[]);

          const acquisitionLinkedRelations = acquisitions.reduce(
            (state, assignment) => {
              [
                ...(assignment.linkedApplicants || []),
                ...(assignment.linkedVendors || []),
                ...(assignment.linkedClients || []),
              ].map((relation) => state.push(relation));

              return state;
            },
            [] as LinkedRelation[]
          );

          linkedRelations = uniqBy(
            [
              ...selectedLinkedRelations.current,
              ...objectLinkedRelations,
              ...acquisitionLinkedRelations,
            ],
            (relation) => relation.id
          );
        }

        selectedLinkedRelations.current = linkedRelations;
        selectedLinkedAssignments.current = control.value || [];

        return {
          linkedRelations,
        };
      } finally {
        setFetchingRelations(false);
      }
    };

    const formControls = useMemo(() => {
      return {
        subject: { value: "", validators: [Validators.required()] },
        linkedRelations: { value: [], onChange: handleLinkedRelationsChange },
        linkedAssignments: {
          value: [],
          onChange: handleLinkedAssignmentsChange,
        },
        autoRemoveMessageAfterPersist: {
          value: autoRemoveMessageAfterPersist || false,
        },
      } as FormControls;
    }, [
      handleLinkedRelationsChange,
      handleLinkedAssignmentsChange,
      autoRemoveMessageAfterPersist,
    ]);

    const onCloseHandler = useCallback(() => {
      if (loading) return;
      onClose();
      selectedLinkedRelations.current = [];
      selectedLinkedAssignments.current = [];
      init.current = false;
      allowSuggestionSideEffects.current = false;

      if (!form.current) return;
      const { update } = form.current;
      update({
        subject: "",
        linkedRelations: [],
        linkedAssignments: [],
      });
    }, [loading, onClose]);

    const onSaveHandler = useCallback(async () => {
      if (loading || !form.current) return;
      try {
        setLoading(true);
        const { getValues } = form.current;
        const {
          subject,
          linkedAssignments,
          linkedRelations,
          autoRemoveMessageAfterPersist,
        } = getValues();

        await saveCommunicationLog(
          linkedRelations,
          linkedAssignments,
          message,
          subject,
          autoRemoveMessageAfterPersist,
          communicationLog
        );
        onClose(true);
        init.current = false;
        allowSuggestionSideEffects.current = false;
      } finally {
        setLoading(false);

        const { update } = form.current;

        update({
          subject: "",
          linkedRelations: [],
          linkedAssignments: [],
          autoRemoveMessageAfterPersist,
        });
        selectedLinkedRelations.current = [];
        selectedLinkedAssignments.current = [];
      }
    }, [
      loading,
      setLoading,
      form,
      communicationLog,
      saveCommunicationLog,
      message,
      onClose,
    ]);

    const fetchSuggestions = useCallback(async () => {
      if (!!communicationLog || !form.current) return;

      const { from, replyTo } = message;

      const emailsFrom = (from || [])
        .filter((email) => !!email.email)
        .map((email) => email.email);

      const emailsReplyTo = (replyTo || [])
        .filter((email) => !!email.email)
        .map((email) => email.email);

      const emails = uniq([...emailsFrom, ...emailsReplyTo]);

      if (!emails.length) return;
      const linkedRelations = await getRelationsWithMatchingEmailAddress(
        emails
      );
      const { update } = form.current;

      update({
        linkedRelations,
      });
      selectedLinkedRelations.current = linkedRelations;

      if (!linkedRelations.length) return;
      const relationIds = linkedRelations.map((relation) => relation.id);
      const linkedAssignments = await getAssignmentsLinkedToRelations(
        relationIds
      );

      update({
        linkedAssignments,
      });
      selectedLinkedAssignments.current = linkedAssignments;
    }, [
      communicationLog,
      message,
      getRelationsWithMatchingEmailAddress,
      getAssignmentsLinkedToRelations,
      form,
    ]);

    const updateForm = useCallback(() => {
      if (!form.current) return;

      const { update } = form.current;

      let { linkedRelations, linkedAssignments } = form.current.getValues();
      linkedRelations = linkedRelations || [];
      linkedAssignments = linkedAssignments || [];

      let newSubject = subject || "";

      if (!!communicationLog) {
        const {
          linkedRelations: relations,
          linkedAssignments: assignments,
          subject,
        } = communicationLog;
        linkedRelations = relations || [];
        linkedAssignments = assignments || [];
        newSubject = subject || newSubject;
      }

      update({
        subject: newSubject,
        linkedRelations,
        linkedAssignments,
      });

      if (
        !!newSubject &&
        (!!linkedRelations.length || !!linkedAssignments.length)
      ) {
        setValid(true);
      }

      allowSuggestionSideEffects.current = true;
    }, [form, subject, communicationLog, setValid]);

    const onAddRelationHandler = useCallback(
      async (query: string) => {
        let log = communicationLog;
        if (!log) {
          log = await defineNewCommunicationLog();
        }

        if (!!form.current) {
          const { getValues } = form.current;
          const { linkedRelations, linkedAssignments } = getValues();

          log = {
            ...log,
            linkedRelations,
            linkedAssignments,
          };
        }

        onAddRelation(query, log);
      },
      [form, onAddRelation, communicationLog]
    );

    const formOnChangeHandler = useCallback(
      (values: FormReturnValue) => {
        const { subject, linkedRelations, linkedAssignments } = values;
        const valid =
          !!subject && (!!linkedRelations.length || !!linkedAssignments.length);
        setValid(valid);
      },
      [setValid]
    );

    const onNavigateHandler = useCallback(
      (path: string) => {
        navigate(path);
        onClose();
      },
      [navigate, onClose]
    );

    useEffect(() => {
      if (!!visible && !init.current) {
        init.current = true;
        fetchSuggestions().then(() => {
          updateForm();
        });
      }
      if (!visible) {
        init.current = false;
        selectedLinkedRelations.current = [];
        selectedLinkedAssignments.current = [];
      }
    }, [visible, communicationLog, fetchSuggestions, updateForm]);

    return (
      <Modal visible={visible} onClose={onCloseHandler}>
        <ModalHeader title="emailSaveModal.header.title" close />
        <ModalBody>
          <Form
            name="emailSaveModal"
            formControls={formControls}
            form={(ref) => (form.current = ref)}
            onChange={formOnChangeHandler}
          >
            <div className="form__row">
              <label htmlFor="subject">
                <I18n value="emailSaveModal.label.subject" />
              </label>
              <Input.Text name="subject" disabled={loading} />
            </div>

            <div className="form__row">
              <label htmlFor="linkedRelations">
                <I18n value="emailSaveModal.label.linkedEntities" />
              </label>
              <Input.Suggestion
                name="linkedRelations"
                type="relation"
                relationTypes={[
                  RelationType.ContactCompany,
                  RelationType.ContactPerson,
                ]}
                onAdd={onAddRelationHandler}
                disabled={loading}
                onNavigate={onNavigateHandler}
                searchInArchive
                fetchingResults={fetchingRelations}
              />
            </div>
            <div className="form__row">
              <Input.Suggestion
                name="linkedAssignments"
                type="assignment"
                disabled={loading}
                onNavigate={onNavigateHandler}
                searchInArchive
                fetchingResults={fetchingAssignments}
              />
            </div>
            <div className="form__row">
              <Input.Switch
                name="autoRemoveMessageAfterPersist"
                label="emailAutoRemoveMessageAfterPersist"
                labelPosition={SwitchLabelPosition.Pre}
                on={true}
                off={false}
                disabled={loading}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            category="primary"
            label="emailSaveModal.action.save"
            disabled={loading || !valid}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
            onClick={onSaveHandler}
          />
        </ModalFooter>
      </Modal>
    );
  }
);
