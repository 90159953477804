import { RangeUnit } from "@haywork/api/mls";
import { SingleFilterConfig } from "@haywork/components/ui/list";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";
import last from "lodash-es/last";
import * as React from "react";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { ListFilterContext } from "../..";
import LocationRange from "./location-range.container";

type LocationRangeValue = {
  location: ExtendedLocationSuggestionItem;
  range: number;
  rangeUnit: RangeUnit;
};

type InnerLocationRangeValue = LocationRangeValue & {
  id: string;
  description: string;
};

type Props = {
  configKey: string;
};

export const LocationRangesComponent: FC<Props> = memo(({ configKey }) => {
  const { filters, onFilterChange } = useContext(ListFilterContext);

  const filter = useMemo(() => {
    return !filters ? undefined : filters[configKey];
  }, [filters, configKey]);

  const locations = useMemo(() => {
    if (!filter || !filter.value.length)
      return [
        {
          location: null,
          description: "",
          range: 0,
          rangeUnit: RangeUnit.Kilometer,
          id: uuid(),
        } as InnerLocationRangeValue,
      ];

    const locations = filter.value as LocationRangeValue[];
    const enrichedLocations = locations.map(
      (location) => ({ ...location, id: uuid() } as InnerLocationRangeValue)
    );

    if (!!last(enrichedLocations).location) {
      enrichedLocations.push({
        location: null,
        description: "",
        range: 0,
        rangeUnit: RangeUnit.Kilometer,
        id: uuid(),
      });
    }

    return enrichedLocations;
  }, [filter]);

  const onChangeHandler = useCallback(
    (
      location: ExtendedLocationSuggestionItem,
      range: number,
      rangeUnit: RangeUnit,
      id: any
    ) => {
      if (!location || !filter) return;
      const value = locations
        .map((ref) => {
          if (id === ref.id) {
            return { location, range, rangeUnit };
          } else {
            return {
              location: ref.location,
              range: ref.range,
              rangeUnit: ref.rangeUnit,
            };
          }
        })
        .filter((ref) => !!ref.location);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [locations, onFilterChange, configKey, filter, filters]
  );

  const onClearCallback = useCallback(
    (id: string) => {
      const filteredLocations = locations.filter(
        (location) => location.id !== id
      );
      const value = filteredLocations
        .map((ref) => ({
          location: ref.location,
          range: ref.range,
          rangeUnit: ref.rangeUnit,
        }))
        .filter((ref) => !!ref.location);

      const updatedFilter: SingleFilterConfig = {
        ...filter,
        value,
      };

      onFilterChange({
        ...filters,
        [configKey]: updatedFilter,
      });
    },
    [locations, onFilterChange, filter, filters]
  );

  return (
    <div>
      {locations.map(({ location, range, rangeUnit, id }) => (
        <LocationRange
          location={location}
          range={range}
          rangeUnit={rangeUnit}
          index={id}
          onChange={onChangeHandler}
          onClear={onClearCallback}
          key={id}
        />
      ))}
    </div>
  );
});
