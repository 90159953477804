import {
  MergeField,
  MergeSection,
  Phrase,
  TemplateDefinition,
  TemplateDefinitionSnapShot
} from "@haywork/api/kolibri";
import { Company, UploadResponse } from "@haywork/api/mail";
import {
  EmailTemplatesThunks,
  MapDispatchToProps,
  PhrasesThunks,
  TemplateMergeData
} from "@haywork/middleware/thunk";
import { AppState, PhrasesActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { EditorComponent, EditorComponentProps } from "./editor.component";
import { officeId, signatures } from "./selectors";

interface StateProps {
  phrase: Phrase;
  phraseStatus: string;
  mergeFields: MergeField[];
  employeeId: string;
  currentRealestateAgencyId: string;
  officeId: string;
  company: Company;
  signatures: TemplateDefinitionSnapShot[];
}
interface DispatchProps {
  getPhrases: (id: number) => Promise<void>;
  getPhrase: (id: string) => Promise<void>;
  clearPhrases: () => void;
  updatePhrase: (phrase: Phrase) => Promise<void>;
  savePhrase: (
    title: string,
    description: string,
    phraseCategoryId: number
  ) => Promise<void>;
  unsetPhrase: () => void;
  mergeTemplate: (
    template: TemplateDefinitionSnapShot,
    data: TemplateMergeData
  ) => Promise<TemplateDefinition>;
  uploadFromUri: (
    accountId: string,
    fileUri: string,
    fileName: string
  ) => Promise<UploadResponse>;
  mergeTextTemplate: (
    data: TemplateMergeData,
    sectionKey: string,
    text: string
  ) => Promise<MergeSection[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EditorComponentProps,
  AppState
> = (state) => {
  const { phrase, phraseStatus } = state.phrases;
  const { mergeFields } = state.emailV2.templates;
  const { employee, currentRealestateAgency } = state.account;
  const { companySettings } = state.emailV2.main;

  return {
    phrase,
    phraseStatus,
    mergeFields,
    employeeId: employee.id,
    currentRealestateAgencyId: currentRealestateAgency.id,
    officeId: officeId(state),
    company: companySettings,
    signatures: signatures(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EditorComponentProps
> = (dispatch) => ({
  getPhrases: (id: number) => dispatch(PhrasesThunks.getPhrases(id)),
  getPhrase: (id: string) => dispatch(PhrasesThunks.getPhrase(id)),
  clearPhrases: () => dispatch(PhrasesActions.setPhrases({ phrases: [] })),
  updatePhrase: (phrase: Phrase) =>
    dispatch(PhrasesThunks.updatePhrase(phrase)),
  savePhrase: (title: string, description: string, phraseCategoryId: number) =>
    dispatch(PhrasesThunks.savePhrase(title, description, phraseCategoryId)),
  unsetPhrase: () => dispatch(PhrasesActions.unsetPhrase()),
  mergeTemplate: (
    template: TemplateDefinitionSnapShot,
    data: TemplateMergeData
  ) => dispatch(EmailTemplatesThunks.mergeTemplate(template, data)),
  uploadFromUri: (accountId: string, fileUri: string, fileName: string) =>
    dispatch(EmailTemplatesThunks.uploadFromUri(accountId, fileUri, fileName)),
  mergeTextTemplate: (
    data: TemplateMergeData,
    sectionKey: string,
    text: string
  ) => dispatch(EmailTemplatesThunks.mergeTextTemplate(data, sectionKey, text))
});

export type EditorContainerProps = StateProps & DispatchProps;
export const EditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorComponent);
