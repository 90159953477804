import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditMediaFloorplansScreenContainerProps } from "./assignments-edit-media-floorplans.container";
import { PhotosComponent } from "@haywork/modules/shared";
import { SortEnd } from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
const styles = require("./assignments-edit-media-floorplans.scss");

export type AcquistionAssignmentEditMediaFloorplansScreenProps = {};
type Props = AcquistionAssignmentEditMediaFloorplansScreenProps &
  AcquistionAssignmentEditMediaFloorplansScreenContainerProps;

export const AcquistionAssignmentEditMediaFloorplansScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ currentComponentState, updateAcquisitionAssignmentObject, path }) => {
      let { maps } = currentComponentState;
      maps = maps || [];

      const uploadCompleteHandler = (files: any[]) => {
        const mapArray = currentComponentState.maps || [];
        const maps = files.filter((f) => !!f);
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          maps: [...mapArray, ...maps],
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const filesDeletedHandler = (idx: number) => {
        const maps = currentComponentState.maps.filter((p, k) => k !== idx);
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          maps,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const sortedHandler = (sort: SortEnd) => {
        const maps = arrayMoveImmutable(
          currentComponentState.maps,
          sort.oldIndex,
          sort.newIndex
        );
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          maps,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const filesEditedHandler = (maps: any[]) => {
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          maps,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      return (
        <div>
          <PhotosComponent
            photos={maps}
            path={path}
            onUploadCompleteHandler={uploadCompleteHandler}
            fileDeleted={filesDeletedHandler}
            sorted={sortedHandler}
            fileEdited={filesEditedHandler}
            showMainPhotoLabel={true}
          />
        </div>
      );
    }
  )
);
