import * as React from "react";
import { FC, memo, useState, useCallback } from "react";
import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import { Input } from "@haywork/modules/form";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";

type Props = {
  loading: boolean;
  onSelectRelation: (linkedRelation: RelationSnapShot) => void;
};

const OtherComponent: FC<Props> = memo(({ onSelectRelation, loading }) => {
  const [
    selectedRelation,
    setSelectedRelation
  ] = useState<RelationSnapShot | null>(null);

  const selectRelation = useCallback(() => {
    onSelectRelation(selectedRelation);
  }, [onSelectRelation, selectedRelation]);

  return (
    <>
      <ModalBody>
        <div className="form__row">
          <label htmlFor="linkedRelation">
            <I18n value="duplicateContactModal.other.body" />
          </label>
          <Input.RelationQuery
            name="linkedRelation"
            value={selectedRelation}
            filterByRelationTypes={[RelationType.ContactPerson]}
            onChange={setSelectedRelation}
            valueAsRelationSnapShot={true}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          category="primary"
          label="duplicateContactModal.action.saveMessage"
          onClick={selectRelation}
          disabled={!selectedRelation || loading}
          icon={
            !loading ? null : (
              <Icon
                name="spinner"
                spin
                regular
                containIn={24}
                color={Colors.Gray}
              />
            )
          }
        />
      </ModalFooter>
    </>
  );
});

export default OtherComponent;
