import { AssignmentSnapShot, Employee } from "@haywork/api/kolibri";
import { Dispatch, EmployeeThunk } from "@haywork/middleware/thunk";
import {
  EmployeeAssignmentsComponent,
  EmployeeAssignmentsComponentProps
} from "@haywork/modules/settings/modules/employees/components/assignments";
import { AppState } from "@haywork/stores";
import { AssignmentUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface StateProps {
  selectedEmployeeId?: string;
  selectedEmployee: Employee;
  employeeAssignments?: AssignmentSnapShot[];
  canLoadMore?: boolean;
  employeeAssignmentState?: string;
}

interface DispatchProps {
  getEmployeeAssignments?: (employeeId: string, init?: boolean) => void;
  goToAssignmentDetail?: (assignment: AssignmentSnapShot) => void;
}

const mapStateToProps = <StateProps, EmployeeAssignmentsComponentProps>(
  state: AppState
) => ({
  selectedEmployeeId: state.editable.currentComponentState.id,
  selectedEmployee: state.editable.currentComponentState,
  employeeAssignments: state.employee.employeeAssignments,
  canLoadMore:
    state.employee.employeeAssignmentPage <
    state.employee.employeeAssignmentPageCount,
  employeeAssignmentState: state.employee.employeeState
});

const mapDispatchToProps = <DispatchProps, EmployeeAssignmentsComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getEmployeeAssignments: (employeeId: string, init?: boolean) =>
    dispatch(EmployeeThunk.getAssignmentsForRelationDetail(employeeId, init)),
  goToAssignmentDetail: (assignment: AssignmentSnapShot) =>
    dispatch(push(AssignmentUtil.getAssignmentPath(assignment)))
});

export type EmployeeAssignmentsContainerProps = StateProps & DispatchProps;
export const EmployeeAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  EmployeeAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeAssignmentsComponent);
