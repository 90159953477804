import { REDUX } from "@haywork/constants";

import * as ActionType from "./messages.types";

const appendMessages = (payload: ActionType.MessagesPayload) => ({
  type: REDUX.EMAILV2.APPEND_MESSAGES,
  ...payload
});

const setMessage = (payload: ActionType.MessagePayload) => ({
  type: REDUX.EMAILV2.SET_MESSAGE,
  ...payload
});

const setMessageStatus = (currentMessageStatus: string) => ({
  type: REDUX.EMAILV2.SET_MESSAGE_STATUS,
  currentMessageStatus
});

const appendMessage = (payload: ActionType.MessagePayload) => ({
  type: REDUX.EMAILV2.APPEND_MESSAGE,
  ...payload
});

const updateMessage = (payload: ActionType.MessagePayload) => ({
  type: REDUX.EMAILV2.UPDATE_MESSAGE,
  ...payload
});

const deleteMessage = (id: string) => ({
  type: REDUX.EMAILV2.DELETE_MESSAGE,
  id
});

const setMessageBookmarkStatus = (payload: ActionType.Bookmarked) => ({
  type: REDUX.EMAILV2.SET_MESSAGE_BOOKMARK_STATUS,
  ...payload
});

const setMessageUnreadStatus = (payload: ActionType.Unread) => ({
  type: REDUX.EMAILV2.SET_MESSAGE_UNREAD_STATUS,
  ...payload
});

const setTerm = (term: string) => ({
  type: REDUX.EMAILV2.SET_FILTER_TERM,
  term
});

const filter = (payload: ActionType.Filters) => ({
  type: REDUX.EMAILV2.FILTER_MESSAGES,
  ...payload
});

const clearAll = () => ({
  type: REDUX.EMAILV2.CLEAR_ALL_MESSAGES
});

const clearCurrentMessage = () => ({
  type: REDUX.EMAILV2.CLEAR_CURRENT_MESSAGE
});

export const MessagesActions = {
  appendMessages,
  setMessage,
  setMessageStatus,
  appendMessage,
  updateMessage,
  deleteMessage,
  setMessageBookmarkStatus,
  setMessageUnreadStatus,
  setTerm,
  filter,
  clearAll,
  clearCurrentMessage
};
