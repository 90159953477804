import { Invoice, InvoiceSnapShot } from "@haywork/api/kolibri";

export const mapInvoiceToInvoiceSnapShot = (
  invoice: Invoice
): InvoiceSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    currency,
    dueDate,
    invoiceDate,
    displayName,
    purpose,
    status,
    totalPriceGross,
    isOverdue,
    linkedAssignment,
    linkedRelation,
  } = invoice;

  return {
    id,
    realEstateAgencyId,
    createdById: linkedCreatedBy?.id,
    isActive,
    displayName,
    purpose,
    invoiceTotalAmount: totalPriceGross,
    status,
    dateTimeCreated,
    dateTimeModified,
    dueDate,
    invoiceDate,
    linkedAssignment,
    linkedEmployee: linkedCreatedBy,
    linkedCreatedBy,
    linkedModifiedBy,
    linkedRelations: [linkedRelation],
    currency,
    isOverdue,
  };
};
