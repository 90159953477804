import {
  CertificationOption,
  ConditionOption,
  FurnishingOption,
  ObjectAssignment,
  ParkingFacilityOption,
  TypeOfBusinessLawOption,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  conditionOptions: ConditionOption[];
  certificationOptions: CertificationOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  furnishingOptions: FurnishingOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentBogParticularsComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const { saleOffer, rentOffer } = objectAssignment;
    const offer = this.props.objectAssignment.forSale ? saleOffer : rentOffer;

    this.formControls = {
      maintenanceInside: {
        value: value(objectAssignment, "maintenanceInside.rating"),
      },
      maintenanceOutside: {
        value: value(objectAssignment, "maintenanceOutside.rating"),
      },
      approvalMarks: { value: value(objectAssignment, "approvalMarks", []) },
      parkingFacilities: {
        value: value(objectAssignment, "parkingFacilities", []),
      },
      totalParkingCapacity: {
        value: value(objectAssignment, "totalParkingCapacity"),
      },
      indoorParkingCapacity: {
        value: value(objectAssignment, "indoorParkingCapacity"),
      },
      businessLawType: {
        value: value(objectAssignment, "businessRights.businessLawType"),
      },
      furnishing: { value: value(offer, "furnishing") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const { saleOffer, rentOffer } = objectAssignment;
      const offer = this.props.objectAssignment.forSale ? saleOffer : rentOffer;

      this.formRef.update(
        {
          maintenanceInside: value(
            objectAssignment,
            "maintenanceInside.rating"
          ),
          maintenanceOutside: value(
            objectAssignment,
            "maintenanceOutside.rating"
          ),
          approvalMarks: value(objectAssignment, "approvalMarks", []),
          parkingFacilities: value(objectAssignment, "parkingFacilities", []),
          totalParkingCapacity: value(objectAssignment, "totalParkingCapacity"),
          indoorParkingCapacity: value(
            objectAssignment,
            "indoorParkingCapacity"
          ),
          businessLawType: value(
            objectAssignment,
            "businessRights.businessLawType"
          ),
          furnishing: value(offer, "furnishing"),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="particulars"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <label htmlFor="maintenanceInside">
                <ResourceText resourceKey="maintenanceInside" />
              </label>
              <Input.NewSelect
                name="maintenanceInside"
                placeholder="makeAChoice"
                values={this.props.conditionOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="maintenanceOutside">
                <ResourceText resourceKey="maintenanceOutside" />
              </label>
              <Input.NewSelect
                name="maintenanceOutside"
                placeholder="makeAChoice"
                values={this.props.conditionOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>
        <div className="form__row">
          <label htmlFor="approvalMarks">
            <ResourceText resourceKey="approvalMarks" />
          </label>
          <Input.Multi
            name="approvalMarks"
            values={this.props.certificationOptions}
          />
        </div>
        <div className="form__row">
          <label htmlFor="furnishing">
            <ResourceText resourceKey="furnishing" />
          </label>
          <Input.RadioGroup name="furnishing" asButtonList canUnselect={true}>
            {this.props.furnishingOptions.map((furnishingOption, idx) => (
              <Input.Radio
                value={furnishingOption.value}
                label={furnishingOption.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>
        <div className="form__row">
          <label htmlFor="parkingFacilities">
            <ResourceText resourceKey="parkingFacilities" />
          </label>
          <Input.Multi
            name="parkingFacilities"
            values={this.props.parkingFacilityOptions}
          />
        </div>
        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <label htmlFor="totalParkingCapacity">
                <ResourceText resourceKey="totalParkingCapacity" />
              </label>
              <Input.Number name="totalParkingCapacity" />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="indoorParkingCapacity">
                <ResourceText resourceKey="indoorParkingCapacity" />
              </label>
              <Input.Number name="indoorParkingCapacity" />
            </div>
          </div>

          <div className="form__row">
            <label htmlFor="businessLawType">
              <ResourceText resourceKey="businessLawType" />
            </label>
            <Input.Multi
              name="businessLawType"
              values={this.props.typeOfBusinessLawOptions}
            />
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;

    let value: any = {
      maintenanceInside: {
        ...objectAssignment.maintenanceInside,
        rating: values.maintenanceInside,
      },
      maintenanceOutside: {
        ...objectAssignment.maintenanceOutside,
        rating: values.maintenanceOutside,
      },
      approvalMarks: values.approvalMarks,
      parkingFacilities: values.parkingFacilities,
      totalParkingCapacity: values.totalParkingCapacity,
      indoorParkingCapacity: values.indoorParkingCapacity,
      businessRights: {
        ...objectAssignment.businessRights,
        businessLawType: values.businessLawType,
      },
    };

    let offer = objectAssignment.forSale
      ? objectAssignment.saleOffer
      : objectAssignment.rentOffer;
    offer = {
      ...offer,
      furnishing: values.furnishing,
    };

    if (objectAssignment.forSale) {
      value = {
        ...value,
        saleOffer: offer,
      };
    } else {
      value = {
        ...value,
        rentOffer: offer,
      };
    }

    this.props.onChange(value);
  }
}
