import {
  ObjectAssignment,
  PointOfInterest,
  PointOfInterestType,
} from "@haywork/api/kolibri";
import { DistanceFromTo } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { DistanceUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const distance = DistanceUtil.getPoiDistanceFromTo;

interface Props {
  objectAssignment: ObjectAssignment;
  onChange: (values: FormReturnValue) => void;
}
interface PoiServices {
  bankBranch: DistanceFromTo;
  atm: DistanceFromTo;
  recreation: DistanceFromTo;
  restaurant: DistanceFromTo;
  shop: DistanceFromTo;
  supermarket: DistanceFromTo;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogPoiServicesComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private distanceFromToOptions: DistanceFromTo[] = [
    DistanceFromTo.DistanceNone,
    DistanceFromTo.LessThanFiveHundred,
    DistanceFromTo.FiveHundredUntillOneThousand,
    DistanceFromTo.OneThousandUntillFifteenHundred,
    DistanceFromTo.FifteenHundredUntillTwoThousand,
    DistanceFromTo.TwoThousandUntillThreeThousand,
    DistanceFromTo.ThreeThousandUntillFourThousand,
    DistanceFromTo.FourThousandUntillFiveThousand,
    DistanceFromTo.MoreThanFiveThousand,
  ];
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const values = this.mapPoiValues(objectAssignment.pointsOfInterest);

    this.formControls = {
      bankBranch: { value: values.bankBranch },
      atm: { value: values.atm },
      recreation: { value: values.recreation },
      restaurant: { value: values.restaurant },
      shop: { value: values.shop },
      supermarket: { value: values.supermarket },
    };

    this.createPoiControl = this.createPoiControl.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const values = this.mapPoiValues(objectAssignment.pointsOfInterest);

      this.formRef.update(
        {
          bankBranch: values.bankBranch,
          atm: values.atm,
          recreation: values.recreation,
          restaurant: values.restaurant,
          shop: values.shop,
          supermarket: values.supermarket,
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="retail-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        {this.createPoiControl("bankBranch")}
        {this.createPoiControl("atm")}
        {this.createPoiControl("recreation")}
        {this.createPoiControl("restaurant")}
        {this.createPoiControl("shop")}
        {this.createPoiControl("supermarket")}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const rawPointsOfInterest = objectAssignment.pointsOfInterest || [];
    const types = [
      PointOfInterestType.BankBranch,
      PointOfInterestType.ATM,
      PointOfInterestType.Recreation,
      PointOfInterestType.Restaurant,
      PointOfInterestType.Shop,
      PointOfInterestType.Supermarket,
    ];

    const pointsOfInterest: PointOfInterest[] = rawPointsOfInterest.filter(
      (poi) => {
        return types.indexOf(poi.type) === -1;
      }
    );

    for (const key in values) {
      const value = values[key];
      if (value !== DistanceFromTo.DistanceNone)
        pointsOfInterest.push(this.mapValueToPoi(value, key));
    }

    this.props.onChange({ pointsOfInterest });
  }

  private mapValueToPoi(
    value: DistanceFromTo,
    poiType: string
  ): PointOfInterest {
    let distanceFrom = undefined;
    let distanceTo = undefined;
    let type = undefined;

    switch (value) {
      case DistanceFromTo.LessThanFiveHundred:
        distanceTo = 500;
        break;
      case DistanceFromTo.FiveHundredUntillOneThousand:
        distanceFrom = 500;
        distanceTo = 1000;
        break;
      case DistanceFromTo.OneThousandUntillFifteenHundred:
        distanceFrom = 1000;
        distanceTo = 1500;
        break;
      case DistanceFromTo.FifteenHundredUntillTwoThousand:
        distanceFrom = 1500;
        distanceTo = 2000;
        break;
      case DistanceFromTo.TwoThousandUntillThreeThousand:
        distanceFrom = 2000;
        distanceTo = 3000;
        break;
      case DistanceFromTo.ThreeThousandUntillFourThousand:
        distanceFrom = 3000;
        distanceTo = 4000;
        break;
      case DistanceFromTo.FourThousandUntillFiveThousand:
        distanceFrom = 4000;
        distanceTo = 5000;
        break;
      case DistanceFromTo.MoreThanFiveThousand:
        distanceFrom = 5000;
        break;
      default:
        break;
    }

    switch (poiType) {
      case "bankBranch":
        type = PointOfInterestType.BankBranch;
        break;
      case "atm":
        type = PointOfInterestType.ATM;
        break;
      case "recreation":
        type = PointOfInterestType.Recreation;
        break;
      case "restaurant":
        type = PointOfInterestType.Restaurant;
        break;
      case "shop":
        type = PointOfInterestType.Shop;
        break;
      case "supermarket":
        type = PointOfInterestType.Supermarket;
        break;
      default:
        break;
    }

    return {
      distanceFrom,
      distanceTo,
      type,
    };
  }

  private createPoiControl(name: string): React.ReactElement<HTMLDivElement> {
    return (
      <div className="form__row">
        <div className="form__group">
          <div className="column">
            <label htmlFor={name} styleName="poi-label">
              <ResourceText resourceKey={name} />
            </label>
          </div>
          <div className="column__spacer" />
          <div className="column" styleName="poi-select">
            <Input.NewSelect
              name={name}
              values={this.distanceFromToOptions}
              translate
            />
          </div>
        </div>
      </div>
    );
  }

  private mapPoiValues(values: PointOfInterest[]): PoiServices {
    const poiValues = values || [];

    const poi = {
      bankBranch: DistanceFromTo.DistanceNone,
      atm: DistanceFromTo.DistanceNone,
      recreation: DistanceFromTo.DistanceNone,
      restaurant: DistanceFromTo.DistanceNone,
      shop: DistanceFromTo.DistanceNone,
      supermarket: DistanceFromTo.DistanceNone,
    };

    poiValues.map((value) => {
      switch (value.type) {
        case PointOfInterestType.BankBranch:
          poi.bankBranch = distance(value);
          break;
        case PointOfInterestType.ATM:
          poi.atm = distance(value);
          break;
        case PointOfInterestType.Recreation:
          poi.recreation = distance(value);
          break;
        case PointOfInterestType.Restaurant:
          poi.restaurant = distance(value);
          break;
        case PointOfInterestType.Shop:
          poi.shop = distance(value);
          break;
        case PointOfInterestType.Supermarket:
          poi.supermarket = distance(value);
          break;
        default:
          break;
      }
    });

    return poi;
  }
}
