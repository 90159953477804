import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { Link } from "react-router-dom";

import {
  DocumentSession,
  LinkedRelation,
  RelationType,
  DocumentTemplate,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { RELATIONROUTES, ASSIGNMENTROUTES } from "@haywork/constants";
import {
  DossierLinkType,
  DynamicDocumentMetaLink,
} from "@haywork/modules/dynamic-documents";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./meta.component.scss");

interface DownloadMetaComponentProps {
  link: DynamicDocumentMetaLink;
  template: DocumentTemplate;
  linkedAssignment?: LinkedAssignment;
}
interface DownloadMetaComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DownloadMetaComponent extends React.Component<
  DownloadMetaComponentProps,
  DownloadMetaComponentState
> {
  public render() {
    return (
      <div styleName="meta">
        <div styleName="icon">
          <i className="fal fa-save" />
        </div>
        <div styleName="text">
          <ResourceText resourceKey="dossierOf" />{" "}
          {this.renderDossierLinkAnchors()}
        </div>
      </div>
    );
  }

  private renderDossierLinkAnchors(): React.ReactElement<HTMLSpanElement> {
    switch (this.props.link.type) {
      case DossierLinkType.Assignment: {
        const { id, displayName } = this.props.link.assignment;
        const path = route(ASSIGNMENTROUTES.DOSSIER.URI, { id });

        return (
          <span>
            <ResourceText resourceKey="documentAssignment" />
            <Link to={path}>{displayName}</Link>
          </span>
        );
      }
      case DossierLinkType.Buyers: {
        const { relations } = this.props.link;
        const count = relations.length;
        let resourceKey = "documentAskers";
        if (!!this.props.linkedAssignment) {
          resourceKey = this.props.linkedAssignment.forSale
            ? "documentBuyers"
            : "documentRenters";
        }

        return (
          <span>
            <ResourceText resourceKey={resourceKey} values={{ count }} />
            {relations.map((relation, idx) =>
              this.renderSingleLinkedRelation(relation, count, idx)
            )}
          </span>
        );
      }
      case DossierLinkType.Notary: {
        const { relations } = this.props.link;
        const count = relations.length;
        return (
          <span>
            <ResourceText resourceKey="documentNotary" />
            {relations.map((relation, idx) =>
              this.renderSingleLinkedRelation(relation, count, idx)
            )}
          </span>
        );
      }
      case DossierLinkType.Sellers: {
        const { relations } = this.props.link;
        const count = relations.length;
        let resourceKey = "documentProviders";
        if (!!this.props.linkedAssignment) {
          resourceKey = this.props.linkedAssignment.forSale
            ? "documentSellers"
            : "documentRentees";
        }

        return (
          <span>
            <ResourceText resourceKey={resourceKey} values={{ count }} />
            {relations.map((relation, idx) =>
              this.renderSingleLinkedRelation(relation, count, idx)
            )}
          </span>
        );
      }
      case DossierLinkType.PropertyManagers: {
        const { relations } = this.props.link;
        const count = relations.length;
        return (
          <span>
            <ResourceText
              resourceKey="documentPropertyManagers"
              values={{ count }}
            />
            {relations.map((relation, idx) =>
              this.renderSingleLinkedRelation(relation, count, idx)
            )}
          </span>
        );
      }
      default:
        return null;
    }
  }

  private renderSingleLinkedRelation(
    linkedRelation: LinkedRelation,
    count: number,
    idx: number
  ) {
    const relation = this.renderLinkedRelation(linkedRelation);
    let connector: any = "";

    if (count > 1 && idx < count - 2) {
      connector = ", ";
    }
    if (count > 1 && count - 2 === idx) {
      connector = (
        <span>
          {" "}
          <ResourceText resourceKey="and" />{" "}
        </span>
      );
    }

    return (
      <span key={idx}>
        {relation}
        {connector}
      </span>
    );
  }

  private renderLinkedRelation(linkedRelation: LinkedRelation): JSX.Element {
    const { typeOfRelation, id, displayName } = linkedRelation;
    let relationRoute: string;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        relationRoute = route(RELATIONROUTES.CONTACT_PERSON_DOSSIER.URI, {
          id,
        });
        break;
      case RelationType.ContactCompany:
        relationRoute = route(RELATIONROUTES.CONTACT_COMPANY_DOSSIER.URI, {
          id,
        });
        break;
      default:
        break;
    }

    return (
      <Link
        to={relationRoute}
        key={id}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {displayName}
      </Link>
    );
  }
}
