import { SearchAssignment } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import { SearchAssignmentPublishComponent } from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { SearchAssignmentActions } from "@haywork/stores/search-assignment";
import { AddressUtil, SearchAssignmentUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

export interface SearchAssignmentPublishContainerProps {
  searchAssignment: SearchAssignment;
  publishState: string;
  canSave: boolean;
  navigate: (route: string) => void;
  saveSearchAssignment: (searchAssignment: SearchAssignment) => void;
  toggleSaveModal: (status: boolean) => void;
}

const mapStateToProps = (state: AppState) => {
  let searchAssignment: SearchAssignment = state.editable.currentComponentState;
  const { publishState } = state.searchAssignment.single;

  const searchLocations = searchAssignment.locations || [];
  const locations = searchLocations.filter((location) =>
    AddressUtil.doesLocationHaveAddress(location)
  );
  searchAssignment = {
    ...searchAssignment,
    locations
  };

  return {
    searchAssignment,
    publishState,
    canSave: SearchAssignmentUtil.validForSave(searchAssignment)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  navigate: (route: string) => dispatch(push(route)),
  saveSearchAssignment: (searchAssignment: SearchAssignment) =>
    dispatch(SearchAssignmentThunks.saveSearchAssignment(searchAssignment)),
  toggleSaveModal: (status: boolean) =>
    dispatch(SearchAssignmentActions.toggleSaveModal(status))
});

export const SearchAssignmentPublishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentPublishComponent);
