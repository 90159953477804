import { combineReducers } from "redux";

import { singleReducer, DynamicDocumentsSingleActions, DynamicDocumentsSingleState } from "./single";
import { listReducer, DynamicDocumentListState, DynamicDocumentsListActions } from "./list";
import { previewReducer, DynamicDocumentsPreviewState, DynamicDocumentsPreviewActions } from "./preview";

const dynamicDocumentsReducer = combineReducers({
  single: singleReducer,
  list: listReducer,
  preview: previewReducer
});

interface DynamicDocumentsState {
  single: DynamicDocumentsSingleState;
  list: DynamicDocumentListState;
  preview: DynamicDocumentsPreviewState;
}

export {
  DynamicDocumentsSingleActions,
  DynamicDocumentsListActions,
  DynamicDocumentsPreviewActions,
  dynamicDocumentsReducer,
  DynamicDocumentsState
};
