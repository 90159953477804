import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";
import { EXTERNALROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  Input,
  FormReference,
  FormReturnValue
} from "@haywork/modules/form";
import { MaxRentType, AssignmentUtil } from "@haywork/util";
import { intlContext } from "@haywork/app";

const styles = require("./max-rent.component.scss");

interface Props {}
interface State {
  maxRentPrice: number;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentFinancialMaxRentComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      maxRentPrice: 0
    };

    this.formControls = {
      type: { value: MaxRentType.Indepenent },
      points: { value: 0 }
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);
  }

  public render() {
    const price = intlContext.formatNumber(this.state.maxRentPrice, {
      style: "currency",
      currency: "EUR"
    });

    return (
      <div styleName="max-rent">
        <div styleName="header">
          <span className="fal fa-lightbulb" />
          <ResourceText
            resourceKey="maxRentPriceTitle"
            values={{ link: EXTERNALROUTES.MAXRENT.URI }}
            asHtml
          />
        </div>
        <div styleName="body">
          <Form
            name="max-rent"
            formControls={this.formControls}
            asSubForm
            form={this.bindFormRef}
            onSubmit={this.onSubmitHandler}
          >
            {/* Type */}
            <div className="form__row">
              <div className="form__group">
                <div className="column__textspacer first">
                  <ResourceText resourceKey="maxRentPriceObjectPre" />
                </div>
                <div className="column">
                  <Input.RadioGroup name="type" asButtonList>
                    <Input.Radio
                      value={MaxRentType.Indepenent}
                      label="independent"
                    />
                    <Input.Radio
                      value={MaxRentType.Dependent}
                      label="dependent"
                    />
                  </Input.RadioGroup>
                </div>
                <div className="column__textspacer last">
                  <ResourceText resourceKey="maxRentPriceObjectPost" />
                </div>
              </div>
            </div>

            {/* Points */}
            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <div className="input__helper">
                    <div className="pre full">
                      <ResourceText resourceKey="points" />
                    </div>
                    <Input.Number name="points" />
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column as-input">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onSubmitClickHandler}
                  >
                    <ResourceText resourceKey="calculateMaxRentPrice" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {this.state.maxRentPrice !== 0 && (
          <div styleName="footer">
            <ResourceText
              resourceKey="maxRentPrice"
              values={{ price, value: this.state.maxRentPrice }}
              asHtml
            />
          </div>
        )}
      </div>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!this.formRef && ref) this.formRef = ref;
  }

  private onSubmitClickHandler(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.formRef) this.formRef.submit();
  }

  private onSubmitHandler(values: FormReturnValue) {
    const maxRentPrice = AssignmentUtil.getMaxRent(
      values.type,
      parseInt(values.points)
    );
    this.setState({ maxRentPrice });
  }
}
