import { SortOrder } from "@haywork/api/kolibri";
import {
  Account,
  AccountOrderBy,
  AccountShare,
  CreateAccountRequest,
  ProviderType,
  SyncStatus,
} from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { EmailReAuthenticateModalComponent } from "@haywork/modules/email";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { SettingsEmailOverviewContainerProps } from "@haywork/modules/settings/modules/email";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
  UiTableColumnConfig,
} from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { CreateModalComponent } from "../create-modal/create-modal.component";
import { EditModalComponent } from "../edit-modal/edit-modal.component";
import { ListItem } from "../list-item/list-item.component";
import { SharedListItem } from "../list-item/shared-list-item.component";

const styles = require("./overview.component.scss");

export interface SettingsEmailOverviewComponentProps {}
interface State {
  editModalVisible: boolean;
  createModalVisible: boolean;
  selectedAccount: Account;
  accountData: CreateAccountRequest;
  reAuthModalVisible: boolean;
  reAuthAccount: Account;
}
type Props = SettingsEmailOverviewComponentProps &
  SettingsEmailOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEmailOverviewComponent extends React.Component<
  Props,
  State
> {
  private columnConfig: UiTableColumnConfig[];

  constructor(props) {
    super(props);

    this.onAccountEditHandler = this.onAccountEditHandler.bind(this);
    this.onAddMailboxClickHandler = this.onAddMailboxClickHandler.bind(this);
    this.onEditModalCloseHandler = this.onEditModalCloseHandler.bind(this);
    this.clearAccountDataHandler = this.clearAccountDataHandler.bind(this);
    this.onCreateModalCloseHandler = this.onCreateModalCloseHandler.bind(this);
    this.onSimpleSubmitHandler = this.onSimpleSubmitHandler.bind(this);
    this.onOAuthSubmitHandler = this.onOAuthSubmitHandler.bind(this);
    this.onIMAPAccountChangeHandler = this.onIMAPAccountChangeHandler.bind(
      this
    );
    this.onIMAPAccountSubmitHandler = this.onIMAPAccountSubmitHandler.bind(
      this
    );
    this.onReAuthModalCloseHandler = this.onReAuthModalCloseHandler.bind(this);

    this.state = {
      editModalVisible: false,
      createModalVisible: false,
      selectedAccount: null,
      accountData: null,
      reAuthModalVisible: false,
      reAuthAccount: null,
    };

    this.columnConfig = [
      {
        title: "emailAddress",
        width: 320,
      },
      {
        title: "emailAccountName",
        width: 200,
        isAutoColumn: true,
      },
      {
        title: "emailSharedWith",
        width: 256,
      },
    ];
  }

  public render() {
    const { accounts } = this.props;
    const reAuthAccounts = accounts.filter(
      (account) => account.syncStatus === SyncStatus.InvalidCredentials
    );

    return (
      <div styleName="email">
        {reAuthAccounts.length > 0 && (
          <div
            styleName="email__warning"
            onClick={() =>
              this.setState({
                reAuthModalVisible: true,
                reAuthAccount: reAuthAccounts[0],
              })
            }
          >
            <i className="fa fa-info-circle" />
            <I18n
              value="emailReAuthenticateWarning"
              values={{ email: reAuthAccounts[0].emailAddress }}
              asHtml
            />
          </div>
        )}

        <PageHeader
          title="settings.pageTitle.emailAccounts"
          subTitle="settingsMenuEmail"
          actions={
            <Button
              label="addNewEmailBox"
              icon={<Icon name="plus" size={18} light />}
              category="primary"
              onClick={this.onAddMailboxClickHandler}
            />
          }
        />

        <div styleName="email__body" id="email-body">
          <Ui.Table
            columnConfig={this.columnConfig}
            orderBy={AccountOrderBy.DisplayName}
            defaultOrderBy={AccountOrderBy.DisplayName}
            defaultOrder={SortOrder.Ascending}
            order={SortOrder.Ascending}
            pushActions
          >
            {accounts.map((account, idx) => (
              <ErrorBoundary key={account.id}>
                {account.ownerId === this.props.employee.id ? (
                  <ListItem
                    zebra={idx % 2 === 0}
                    account={account}
                    providers={this.props.providers}
                    isDefaultAccount={
                      account.id === this.props.defaultAccountId
                    }
                    onEdit={this.onAccountEditHandler}
                    onDelete={this.props.deleteAccount}
                  />
                ) : (
                  <SharedListItem
                    zebra={idx % 2 === 0}
                    account={account}
                    share={this.getAccountShare(account)}
                    providers={this.props.providers}
                    isDefaultAccount={
                      account.id === this.props.defaultAccountId
                    }
                    onToggle={this.props.toggleShareVisibility}
                    onEdit={this.onAccountEditHandler}
                  />
                )}
              </ErrorBoundary>
            ))}

            {accounts.length === 0 && status !== REQUEST.PENDING && (
              <Ui.EmptyState
                stickman={UiEmptyStateStickMan.NoRelations}
                type={UiEmptyStateType.List}
                title="emptyStateNoEmailAccountsOverviewTitle"
              >
                <div
                  data-cy="CY-createFirstEmailButton"
                  className="btn btn-primary"
                  styleName="empty-state-button"
                  onClick={this.onAddMailboxClickHandler}
                >
                  <I18n value="emailAccountAddFirstEmailAccount" />
                </div>
              </Ui.EmptyState>
            )}

            {status === REQUEST.PENDING && <Ui.Loaders.Fullscreen />}
          </Ui.Table>
        </div>

        <EditModalComponent
          visible={this.state.editModalVisible}
          account={this.state.selectedAccount}
          employees={this.props.employees}
          shares={this.props.shares}
          defaultAccountId={this.props.defaultAccountId}
          onSubmit={this.props.updateAccountAndShares}
          onClose={this.onEditModalCloseHandler}
          onDefineNew={this.props.defineNewShare}
          onShareDelete={this.props.deleteShare}
          getArchiveFolder={this.props.getFolder}
          signatures={this.props.signatures}
          employeeId={this.props.employee.id}
        />

        <CreateModalComponent
          visible={this.state.createModalVisible}
          providers={this.props.providers}
          redirectUri={this.props.redirectUri}
          employee={this.props.employee}
          accountData={this.state.accountData}
          createStatus={this.props.createStatus}
          personSettings={this.props.personSettings}
          employees={this.props.employees}
          accounts={this.props.accounts}
          clearAccountData={this.clearAccountDataHandler}
          onClose={this.onCreateModalCloseHandler}
          onSimpleSubmit={this.onSimpleSubmitHandler}
          onOAuthSubmit={this.onOAuthSubmitHandler}
          onIMAPAccountChange={this.onIMAPAccountChangeHandler}
          onIMAPAccountSubmit={this.onIMAPAccountSubmitHandler}
          onSharesSubmit={this.props.addInitialShares}
          getAccounts={this.props.getAccounts}
        />

        <EmailReAuthenticateModalComponent
          visible={this.state.reAuthModalVisible}
          account={this.state.reAuthAccount}
          onClose={this.onReAuthModalCloseHandler}
          onReAuthenticate={this.props.reAuthenticate}
        />
      </div>
    );
  }

  private async onAccountEditHandler(account: Account) {
    if (account.ownerId === this.props.employee.id) {
      await this.props.getAccountShares(account.id);
    }

    this.setState({
      editModalVisible: true,
      selectedAccount: account,
    });
  }

  private onEditModalCloseHandler() {
    this.setState({
      editModalVisible: false,
    });
    this.props.clearAccountShares();
  }

  private onAddMailboxClickHandler() {
    this.setState({
      createModalVisible: true,
    });
  }

  private clearAccountDataHandler() {
    this.setState({
      accountData: null,
    });
  }

  private onCreateModalCloseHandler() {
    this.setState({
      createModalVisible: false,
      accountData: null,
    });
  }

  private onSimpleSubmitHandler(values: CreateAccountRequest) {
    return this.props.createAccount(values) as Promise<Account>;
  }

  private onOAuthSubmitHandler(provider: ProviderType) {
    return this.props.createAccount({
      provider,
      personId: this.props.employee.id,
    }) as Promise<Account>;
  }

  private onIMAPAccountChangeHandler(values: CreateAccountRequest) {
    const accountData = {
      ...this.state.accountData,
      ...values,
    };

    this.setState({
      accountData,
    });
  }

  private onIMAPAccountSubmitHandler() {
    return this.props.createAccount(this.state.accountData) as Promise<Account>;
  }

  private onReAuthModalCloseHandler() {
    this.setState(
      {
        reAuthModalVisible: false,
      },
      () =>
        setTimeout(() => {
          this.setState({
            reAuthAccount: null,
          });
        }, 500)
    );
  }

  private getAccountShare(account: Account): AccountShare {
    return this.props.shares.find((share) => share.accountId === account.id);
  }
}
