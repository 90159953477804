import { CompanySettings } from "@haywork/api/kolibri";
import { CompanySettingsThunks, Dispatch } from "@haywork/middleware";
import {
  SettingsAssignmentsComponent,
  SettingsAssignmentsComponentProps
} from "@haywork/modules/settings/modules/assignments";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  settings: CompanySettings;
  settingsStatus: string;
}
interface DispatchProps {
  saveCompanySettings: (companyListing: CompanySettings) => void;
}

const mapStateToProps = <StateProps, SettingsAssignmentsComponentProps>(
  state: AppState
) => {
  const { settings, settingsStatus } = state.company;

  return {
    settings,
    settingsStatus
  };
};

const mapDispatchToProps = <DispatchProps, SettingsAssignmentsComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveCompanySettings: (companyListing: CompanySettings) =>
    dispatch(CompanySettingsThunks.saveCompanySettings(companyListing))
});

export type SettingsAssignmentsContainerProps = StateProps & DispatchProps;
export const SettingsAssignmentsContainer = connect<
  StateProps,
  DispatchProps,
  SettingsAssignmentsComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsAssignmentsComponent);
