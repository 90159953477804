import * as nl2br from "nl2br";
import * as React from "react";
import { FC, memo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { NotesPinnedNotesContainerProps } from "./pinned-notes.container";
import Icon from "@haywork/components/ui/icon";
import I18n from "@haywork/components/i18n";
import * as linkify from "linkifyjs/html";

const styles = require("./style.scss");

export interface NotesPinnedNotesComponentProps {
  max?: number;
}
type Props = NotesPinnedNotesComponentProps & NotesPinnedNotesContainerProps;

export const NotesPinnedNotesComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ notes }) => {
    if (!notes || !notes.length) return null;

    const body = useCallback((description?: string | null) => {
      if (!description) return "";
      let body = nl2br(description);
      body = linkify(body, {
        defaultProtocol: "https",
        target: {
          url: "_blank",
        },
      });

      return body;
    }, []);

    return (
      <div styleName="pinned-notes__wrapper">
        <div styleName="pinned-notes">
          {notes.map((note) => (
            <div styleName="pinned-note__wrapper" key={note.id}>
              <div styleName="pinned-note">
                <div
                  dangerouslySetInnerHTML={{
                    __html: body(note.description),
                  }}
                />
                <div styleName="pin">
                  <Icon name="thumbtack" color="rgba(0, 0, 0, 0.4)" size={16} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  })
);
