import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { NotificationSettings } from "@haywork/api/event-center";
import {
  CompanyListing,
  LinkedAssignment,
  LinkedEmployee,
  LinkedRelation,
  ObjectAssignment,
  PublicationSnapShot,
  PublicationStatus,
  RealEstateGroup,
  ReportTemplate,
  SearchSearchAssignmentsResponse,
  TimelineEvent,
  UpdateAvailabilityAction,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  AuthorizationThunks,
  InvoiceThunk,
  MapDispatchToProps,
  SchedulerThunks,
  SearchAssignmentThunks,
  TaskThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  AssignmentDetailGeneralComponent,
  AssignmentDetailGeneralComponentProps,
} from "@haywork/modules/assignment";
import { linkedEmployee } from "@haywork/modules/shared/components/acquisition-bids/selectors";
import { canSendEmail } from "@haywork/selector";
import {
  AppState,
  AssignmentSingleActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect, MapStateToProps } from "react-redux";
import { canDuplicate } from "./selectors";

export interface Publications {
  activated: PublicationSnapShot[];
  inActivationProcess: PublicationSnapShot[];
  rejected: PublicationSnapShot[];
  offered: PublicationSnapShot[];
  withdrawn: PublicationSnapShot[];
  inActive: PublicationSnapShot[];
}

interface StateProps {
  assignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  mediaPartners: Publications;
  pastEvents: TimelineEvent[];
  futureEvents: TimelineEvent[];
  companyListings: CompanyListing[];
  isInitial: boolean;
  canSendEmail: boolean;
  saveAssignmentState: string;
  path: string;
  notificationSettings: NotificationSettings[];
  printTemplates: ReportTemplate[];
  enabledRealEstateGroups: RealEstateGroup[];
  canDuplicate: boolean;
  linkedEmployee: LinkedEmployee;
}
interface DispatchProps {
  edit: (id: string) => void;
  toggleStatusModal: (status: boolean) => void;
  toggleWithdrawModal: (status: boolean) => void;
  deleteAssignment: (id: string) => void;
  updateAvailability: (
    updateAvailabilityAction: UpdateAvailabilityAction,
    id: string
  ) => void;
  navigate: (url: string) => void;
  getObjectAssignmentSearchAssignments: (
    objectAssignmentId: string,
    isConcept: boolean
  ) => Promise<SearchSearchAssignmentsResponse>;
  createAssignmentEmail: (
    linkedRelations: LinkedRelation[],
    linkedAssigments: LinkedAssignment[]
  ) => void;
  archive: (assignment: ObjectAssignment, keepKeyNumber?: boolean) => void;
  duplicateAssignment: (
    id: string,
    shouldArchive: boolean,
    duplicateAsForSale: boolean,
    shouldCopyKeyNumber: boolean,
    shouldClearKeyNumber: boolean
  ) => Promise<void>;
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  saveAssignment: () => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  createTaskWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployee: LinkedEmployee
  ) => Promise<void>;
  createNewEmail: (
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) => void;
  unArchive: (id: string) => Promise<void>;
}

const route = RouteUtil.mapStaticRouteValues;

const mapMediaPartners = (
  publications: PublicationSnapShot[]
): Publications => {
  const mappedPublications = publications || [];

  return mappedPublications.reduce(
    (state, publication) => {
      switch (publication.publicationStatus) {
        case PublicationStatus.Published:
          state.activated.push(publication);
          break;
        case PublicationStatus.PublishRequestEnqueued:
        case PublicationStatus.WaitingForExchangeEntityContract:
        case PublicationStatus.WaitingForObligatoryPublications:
          state.inActivationProcess.push(publication);
          break;
        case PublicationStatus.Inactive:
          if (publication.mediaPartnerIsActive) {
            state.inActive.push(publication);
          }
          break;
        case PublicationStatus.Rejected:
          state.rejected.push(publication);
          break;
        case PublicationStatus.Offered:
          state.offered.push(publication);
          break;
        case PublicationStatus.Withdrawn:
          state.withdrawn.push(publication);
          break;
        default:
          break;
      }

      return state;
    },
    {
      rejected: [],
      activated: [],
      inActivationProcess: [],
      offered: [],
      withdrawn: [],
      inActive: [],
    }
  );
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailGeneralComponentProps,
  AppState
> = (state) => {
  const {
    objectAssignment,
    publications,
    pastEvents,
    futureEvents,
    companyListings,
    isInitial,
    saveAssignmentState,
  } = state.assignment.single;
  const { currentComponentState } = state.editable;
  const { notificationSettings } = state.eventCenter.settings;
  const { printTemplates } = state.assignment.templates;
  const { enabledRealEstateGroups } = state.company.settings;

  return {
    assignment: objectAssignment,
    currentComponentState,
    mediaPartners: mapMediaPartners(publications),
    pastEvents,
    futureEvents,
    companyListings,
    isInitial,
    canSendEmail: canSendEmail(state),
    saveAssignmentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    }),
    notificationSettings,
    printTemplates,
    enabledRealEstateGroups: enabledRealEstateGroups || [],
    canDuplicate: canDuplicate(state),
    linkedEmployee: linkedEmployee(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailGeneralComponentProps
> = (dispatch) => ({
  edit: (id: string) =>
    dispatch(push(route(ASSIGNMENTROUTES.EDIT.URI, { id }))),
  toggleStatusModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleStatusModal(status)),
  toggleWithdrawModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleWithdrawModal(status)),
  deleteAssignment: (id: string) =>
    dispatch(AssignmentThunks.deleteAssignment(id)),
  updateAvailability: (
    updateAvailabilityAction: UpdateAvailabilityAction,
    id: string
  ) =>
    dispatch(AssignmentThunks.updateAvailability(updateAvailabilityAction, id)),
  navigate: (url: string) => dispatch(push(url)),
  getObjectAssignmentSearchAssignments: (
    objectAssignmentId: string,
    isConcept: boolean
  ) =>
    dispatch(
      SearchAssignmentThunks.getObjectAssignmentSearchAssignments(
        objectAssignmentId,
        isConcept,
        null,
        0,
        3
      )
    ),
  createAssignmentEmail: (
    linkedRelations: LinkedRelation[],
    linkedAssigments: LinkedAssignment[]
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail([], linkedRelations, linkedAssigments)
    ),
  archive: (assignment: ObjectAssignment, keepKeyNumber?: boolean) =>
    dispatch(AssignmentThunks.archive(assignment, keepKeyNumber)),
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
  saveAssignment: () => dispatch(AssignmentThunks.saveAssignment()),
  duplicateAssignment: (
    id: string,
    shouldArchive: boolean,
    duplicateAsForSale: boolean,
    shouldCopyKeyNumber: boolean,
    shouldClearKeyNumber: boolean
  ) =>
    dispatch(
      AssignmentThunks.duplicateAssignment(
        id,
        shouldArchive,
        duplicateAsForSale,
        shouldCopyKeyNumber,
        shouldClearKeyNumber
      )
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  createTaskWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(
      TaskThunks.createTaskWithLinkedEntities(undefined, [linkedAssignment])
    ),
  createInvoiceWithLinkedAssignment: (
    linkedRelation: LinkedRelation,
    linkedAssignment: LinkedAssignment
  ) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        linkedRelation,
        linkedAssignment,
        undefined,
        undefined,
        "Consumer"
      )
    ),
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    linkedEmployee: LinkedEmployee
  ) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities(
        linkedRelations,
        [linkedAssignment],
        [linkedEmployee]
      )
    ),
  createNewEmail: (linkedAssignment: LinkedAssignment, displayName: string) =>
    dispatch(
      EmailThunk.Main.createNewEmail([], [], [linkedAssignment], displayName)
    ),
  unArchive: (id: string) => dispatch(AssignmentThunks.unArchive(id)),
});

export type AssignmentDetailGeneralContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const AssignmentDetailGeneralContainer = injectIntl(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AssignmentDetailGeneralComponent)
);
