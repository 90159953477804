import {
  CommunicationLog,
  CommunicationLogsClient,
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();

const deleteCommunicationLog = (
  id: string,
  entityId?: string,
  undeleteCallback?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const CommunicationLogs = new CommunicationLogsClient(host);
    let originalCommunicationLog: CommunicationLog | undefined;

    try {
      if (!!entityId) {
        originalCommunicationLog = await parseRequest.response(
          CommunicationLogs.read(id, realEstateAgencyId).then(
            (response) => response.communicationLog
          )
        );

        if (!!originalCommunicationLog) {
          let { linkedAssignments, linkedRelations } = originalCommunicationLog;

          linkedAssignments = (linkedAssignments || []).filter(
            (assignment) => assignment.id !== entityId
          );
          linkedRelations = (linkedRelations || []).filter(
            (relation) => relation.id !== entityId
          );

          if (!linkedAssignments.length && !linkedRelations.length) {
            originalCommunicationLog = undefined;
            await parseRequest.response(
              CommunicationLogs.delete(id, realEstateAgencyId)
            );
          } else {
            const communicationLog: CommunicationLog = {
              ...originalCommunicationLog,
              linkedAssignments,
              linkedRelations,
            };

            await parseRequest.response(
              CommunicationLogs.save(
                {
                  communicationLog,
                },
                realEstateAgencyId
              )
            );
          }
        } else {
          await parseRequest.response(
            CommunicationLogs.delete(id, realEstateAgencyId)
          );
        }
      } else {
        await parseRequest.response(
          CommunicationLogs.delete(id, realEstateAgencyId)
        );
      }

      dispatch(
        SnackbarActions.addToast({
          value: "communicationLog.toast.deleted",
          callback: async () => {
            await dispatch(
              unDeleteCommunicationLog(id, originalCommunicationLog)
            );
            if (!!undeleteCallback) {
              undeleteCallback();
            }
          },
          callbackLabel: "communicationLog.toast.action.undelete",
          icon: "trash-alt",
        })
      );

      return originalCommunicationLog;
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteCommunicationLog = (
  id: string,
  communicationLog?: CommunicationLog
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const CommunicationLogs = new CommunicationLogsClient(host);

    try {
      if (!!communicationLog) {
        await parseRequest.response(
          CommunicationLogs.save(
            {
              communicationLog,
            },
            realEstateAgencyId
          )
        );
      } else {
        await parseRequest.response(
          CommunicationLogs.undelete({ id }, realEstateAgencyId)
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const saveCommunicationLog = (communicationLog: CommunicationLog) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    try {
      const client = new CommunicationLogsClient(host);

      return await parseRequest.response(
        client
          .save({ communicationLog }, realEstateAgencyId)
          .then((response) => response.communicationLog)
      );
    } catch (error) {
      throw error;
    }
  };
};

const defineNewCommunicationLog = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    try {
      const client = new CommunicationLogsClient(host);

      return await parseRequest.response(
        client
          .defineNew({}, realEstateAgencyId)
          .then((response) => response.communicationLog)
      );
    } catch (error) {
      throw error;
    }
  };
};

export const CommunicationLogThunk = {
  deleteCommunicationLog,
  unDeleteCommunicationLog,
  saveCommunicationLog,
  defineNewCommunicationLog,
};
