import {
  ExpirationMoment,
  TaskSnapShot,
  TaskStatus,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { DropdownFilter, Filter } from "@haywork/modules/filter";
import {
  InfiniteScroll,
  ListLoader,
  PageLoader,
  ResourceText,
  StickyConditions,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { TaskFilterStatus } from "@haywork/stores";
import { DateUtil, DiffStatus } from "@haywork/util";
import classNames from "classnames";
import { TaskOverviewContainerProps } from "@haywork/modules/task";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";
import TaskListContainer from "../list";
import { TaskListItemComponent } from "../task-list-item/task-list-item.component";

const styles = require("./overview.component.scss");

export interface TaskOverviewComponentProps {}
type Props = TaskOverviewComponentProps & TaskOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class TaskOverviewComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onChangeFilterHandler = this.onChangeFilterHandler.bind(this);
    this.onToggleTaskStatusHandler = this.onToggleTaskStatusHandler.bind(this);
    this.onTaskDeleteHandler = this.onTaskDeleteHandler.bind(this);
    this.onTaskClickHandler = this.onTaskClickHandler.bind(this);
    this.reloadList = this.reloadList.bind(this);
  }

  public componentDidMount() {
    this.props.getTasks(true);
  }

  public render() {
    const selectionStyle = classNames("selection", {
      delegated: this.props.statusFilterMap === TaskFilterStatus.Delegated,
    });

    return (
      <div styleName="task-overview">
        {this.props.taskInitialOverviewStatus === REQUEST.PENDING && (
          <PageLoader loading fullscreen />
        )}
        <div styleName={selectionStyle}>
          <Filter changeFilter={this.onChangeFilterHandler}>
            <ResourceText resourceKey="youreWatching" />
            <DropdownFilter
              name="statusFilterMap"
              inline
              values={this.props.statuses}
              value={this.props.statusFilterMap}
              data-cy="CY-statusFilterMap"
            />
            <div styleName="show-on-delegate">
              <ResourceText resourceKey="delegateTasks" />
            </div>
            <div styleName="hide-on-delegate">
              <ResourceText resourceKey="tasksOf" />
              <DropdownFilter
                name="employeeFilter"
                inline
                values={this.props.employees}
                value={this.props.employeeFilter}
              />
              <ResourceText resourceKey="ofCategory" />
              <DropdownFilter
                name="categoryFilter"
                inline
                values={this.props.categories}
                value={this.props.categoryFilter}
                asArray
              />
            </div>
          </Filter>
        </div>
        {this.props.tasks.length === 0 &&
          [REQUEST.PENDING, REQUEST.ERROR].indexOf(
            this.props.taskOverviewStatus
          ) === -1 && (
            <Ui.EmptyState
              type={UiEmptyStateType.List}
              stickman={UiEmptyStateStickMan.NoTasks}
              title="emptyStateNoTasksTitle"
              data-cy="CY-taskEmptyState"
            >
              <div styleName="empty-state-text">
                <ResourceText resourceKey="emptyStateNoTasksText" />
                <span className="as-link" onClick={this.props.createTask}>
                  <ResourceText resourceKey="createATask" />
                </span>
              </div>
            </Ui.EmptyState>
          )}
        {this.props.tasks.length > 0 && (
          <div styleName="list">
            <InfiniteScroll
              scrollEnd={this.onScrollEndHandler}
              sticky={this.renderStickyConditions()}
            >
              <EntityListStatus
                list="tasks"
                onClick={() => this.props.getTasks(true)}
                style={{ top: 10 }}
              />

              {this.props.tasks.map((task, idx) => (
                <ErrorBoundary key={task.id}>
                  <TaskListItemComponent
                    task={task}
                    isCurrentUser={
                      task.linkedEmployee &&
                      task.linkedEmployee.id === this.props.employeeId
                    }
                    categories={this.props.taskCategories}
                    zebra={idx % 2 === 0}
                    toggleTaskStatus={(task, status) =>
                      this.onToggleTaskStatusHandler(task, status)
                    }
                    removeTask={(id) =>
                      this.onTaskDeleteHandler(id, task.subject)
                    }
                    onClick={this.onTaskClickHandler}
                  />
                </ErrorBoundary>
              ))}
              {this.props.canLoadMore && <ListLoader />}
            </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }

  public onToggleTaskStatusHandler(task: TaskSnapShot, status: TaskStatus) {
    const { id } = task;
    this.props.toggleTaskStatus(id, status, true, true, task);
  }

  public onChangeFilterHandler(values: any) {
    this.props.updateFilters(values);
    this.props.getTasks(true);
  }

  public onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.taskOverviewStatus === REQUEST.PENDING
    )
      return;
    this.props.getTasks(false);
  }

  private onTaskDeleteHandler(id: string, subject: string = "") {
    this.props.deleteTask(id, subject, this.reloadList);
  }

  private onTaskClickHandler(id: string) {
    this.props.openTask(id);
  }

  private renderStickyConditions(): StickyConditions<TaskSnapShot> {
    return {
      main: {
        path: "task",
        matchFn: (task) => ({
          match: task.status,
          hideSubs: task.status === TaskStatus.Completed,
          display: (task) => (
            <ResourceText
              masterKey="taskStatuses"
              resourceKey={task.status.toString()}
            />
          ),
        }),
      },
      sub: {
        path: "task",
        matchFn: (task) => {
          const diff = DateUtil.diffInDays(new Date(), task.endDate);
          const { tasksStatistics } = this.props;
          let display;
          let match;

          switch (diff.status) {
            case DiffStatus.Negative: {
              const count = tasksStatistics.expiredCount;
              match = diff.status.toString();
              display = () => (
                <ResourceText
                  resourceKey="taskGroupNameExpired"
                  values={{ count }}
                />
              );
              break;
            }
            case DiffStatus.Positive:
              if (
                diff.value === 1 &&
                tasksStatistics.expiresTomorrowCount > 0
              ) {
                const count = tasksStatistics.expiresTomorrowCount;
                match = diff.status.toString() + "tommorow";
                display = () => (
                  <ResourceText
                    resourceKey="taskGroupNameTomorrow"
                    values={{ count }}
                  />
                );
              } else if (
                task.expirationMoment === ExpirationMoment.ThisWeek &&
                tasksStatistics.expiresThisWeekCount > 0
              ) {
                const count =
                  tasksStatistics.expiresThisWeekCount -
                  tasksStatistics.expiresTomorrowCount;
                match = diff.status.toString() + "nextWeek";
                display = () => (
                  <ResourceText
                    resourceKey="taskGroupNameThisWeek"
                    values={{ count }}
                  />
                );
              } else if (
                task.expirationMoment === ExpirationMoment.NextWeek &&
                tasksStatistics.expiresNextWeekCount > 0
              ) {
                const count = tasksStatistics.expiresNextWeekCount;
                match = diff.status.toString() + "nextTwoWeeks";
                display = () => (
                  <ResourceText
                    resourceKey="taskGroupNameNextWeek"
                    values={{ count }}
                  />
                );
              } else if (
                task.expirationMoment === ExpirationMoment.AfterNextWeek &&
                tasksStatistics.expiresAfterNextWeekCount > 0
              ) {
                const count = tasksStatistics.expiresAfterNextWeekCount;
                match = diff.status.toString() + "afterNextWeek";
                display = () => (
                  <ResourceText
                    resourceKey="taskGroupNamePastThisWeek"
                    values={{ count }}
                  />
                );
              }
              break;
            case DiffStatus.Today: {
              const count = tasksStatistics.expiresTodayCount;
              match = diff.status.toString();
              display = () => (
                <ResourceText
                  resourceKey="taskGroupNameToday"
                  values={{ count }}
                />
              );
              break;
            }
            default: {
              const count = tasksStatistics.noEndDateCount;
              match = diff.status.toString();
              display = () => (
                <ResourceText
                  resourceKey="taskGroupNameNoDate"
                  values={{ count }}
                />
              );
              break;
            }
          }

          return {
            match,
            display,
          };
        },
      },
    };
  }

  private reloadList() {
    this.props.getTasks(true);
  }
}
