import { AvailabilityStatus, ObjectTypeAssignment } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("../detail-general.component.scss");

interface ObjectTypesDetailGeneralWidgetsStatusComponentProps {
  objectType: ObjectTypeAssignment;
  onHideOnFundaChange: (newDate: Date) => void;
}

interface ObjectTypesDetailGeneralWidgetsStatusComponentState {
  showChangeHideOnFunda: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesDetailGeneralWidgetsStatusComponent extends React.Component<
  ObjectTypesDetailGeneralWidgetsStatusComponentProps,
  ObjectTypesDetailGeneralWidgetsStatusComponentState
> {
  constructor(props) {
    super(props);

    this.onShowHideOnFundaUntil = this.onShowHideOnFundaUntil.bind(this);
    this.onHideOnFundaUntilChanged = this.onHideOnFundaUntilChanged.bind(this);
    this.onHideHideFundaUntil = this.onHideHideFundaUntil.bind(this);

    this.state = {
      showChangeHideOnFunda: false,
    };
  }

  public render() {
    if (!this.props.objectType.availabilityStatus) return null;

    return (
      <div styleName="widget">
        <h2 styleName="title">
          <ResourceText resourceKey="status" />
        </h2>
        {this.renderStatus()}
      </div>
    );
  }

  private renderStatus(): React.ReactElement<HTMLDivElement> {
    const {
      availabilityStatus,
      withdrawReason,
      hideOnFundaUntil,
    } = this.props.objectType;

    switch (availabilityStatus) {
      case AvailabilityStatus.SoldUnderCondition:
      case AvailabilityStatus.Sold: {
        return this.renderSoldStatus();
      }
      case AvailabilityStatus.RentedUnderCondition:
      case AvailabilityStatus.Rented:
      case AvailabilityStatus.LeasedUnderCondition:
      case AvailabilityStatus.Leased:
      case AvailabilityStatus.FarmedUnderCondition:
      case AvailabilityStatus.Farmed: {
        return this.renderRentedStatus();
      }
      case AvailabilityStatus.Withdrawn: {
        return (
          <div styleName="status danger">
            <div styleName="status__header">
              <h2>
                <ResourceText
                  masterKey="availabilityStatuses"
                  resourceKey={availabilityStatus.toString()}
                />
              </h2>
            </div>
            {!!withdrawReason && (
              <div styleName="status__row">
                <ResourceText
                  masterKey="withdrawReasonOptions"
                  resourceKey={withdrawReason.toString()}
                />
              </div>
            )}
          </div>
        );
      }
      default: {
        return (
          <div styleName="status">
            <div styleName="status__header">
              <h2>
                <ResourceText
                  masterKey="availabilityStatuses"
                  resourceKey={availabilityStatus.toString()}
                />
              </h2>
            </div>
            {!!hideOnFundaUntil && (
              <React.Fragment>
                {!this.state.showChangeHideOnFunda && (
                  <div styleName="status__row">
                    <ResourceText resourceKey="hiddenOnFunda" />
                    &nbsp;
                    <strong>
                      <FormattedDate value={hideOnFundaUntil} />
                    </strong>{" "}
                    &nbsp;
                    {!this.state.showChangeHideOnFunda && (
                      <a onClick={this.onShowHideOnFundaUntil}>
                        <ResourceText resourceKey="change" />
                      </a>
                    )}
                  </div>
                )}
                {this.state.showChangeHideOnFunda && (
                  <div styleName="hide-on-funda__date">
                    <Input.Datepicker
                      value={hideOnFundaUntil}
                      onChange={this.onHideOnFundaUntilChanged}
                      hour={0}
                      minutes={0}
                      seconds={0}
                      name="hideOnFunda"
                    />
                    <div styleName="close" onClick={this.onHideHideFundaUntil}>
                      <i className="fal fa-times" />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        );
      }
    }
  }

  private onHideOnFundaUntilChanged(value: Date) {
    const oldDate = moment(
      this.props.objectType.hideOnFundaUntil,
      moment.ISO_8601
    );
    const newDate = moment(value, moment.ISO_8601);

    if (oldDate.unix() !== newDate.unix()) {
      this.props.onHideOnFundaChange(value);
      this.setState({ showChangeHideOnFunda: false });
    }
  }

  private onShowHideOnFundaUntil() {
    this.setState({ showChangeHideOnFunda: true });
  }

  private onHideHideFundaUntil() {
    this.setState({ showChangeHideOnFunda: false });
  }

  private renderSoldStatus(): React.ReactElement<HTMLDivElement> {
    const { availabilityStatus } = this.props.objectType;

    return (
      <div styleName="status">
        <div styleName="status__header">
          <h2>
            <ResourceText
              masterKey="availabilityStatuses"
              resourceKey={availabilityStatus.toString()}
            />
          </h2>
        </div>
        <div styleName="status__row" />
      </div>
    );
  }

  private renderRentedStatus(): React.ReactElement<HTMLDivElement> {
    const { availabilityStatus } = this.props.objectType;

    return (
      <div styleName="status">
        <div styleName="status__header">
          <h2>
            <ResourceText
              masterKey="availabilityStatuses"
              resourceKey={availabilityStatus.toString()}
            />
          </h2>
        </div>
      </div>
    );
  }
}
