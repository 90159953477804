import { SortOrder, TaskOrderByField } from "@haywork/api/kolibri";
import { actions as TaskListActions } from "./actions";

export type TaskOrder = {
  sortOrder: SortOrder;
  sortColumn: TaskOrderByField;
};

export { listReducer, ListState } from "./reducer";
export { TaskListActions };
