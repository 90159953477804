import * as React from "react";
import { PureComponent } from "react";
import * as Ably from "ably";
import Connecter from "./context-connector";
import { Channel, AnnouncementMessage } from "@haywork/api/voip";
import isString from "lodash-es/isString";
import { VoipContainerProps } from "./voip.container";

export type VoipComponentProps = {};
type Props = VoipComponentProps & VoipContainerProps;
type State = {
  channels: Ably.Types.RealtimeChannelCallbacks[];
  realtime: Ably.Realtime | null;
};

export class VoipComponent extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);

    this.state = {
      channels: [],
      realtime: null
    };
  }

  public render() {
    return <Connecter onConnectionChange={this.onConnectionChange} />;
  }

  public componentWillUnmount() {
    this.unsubscribe();
    this.setState({ channels: [] });
  }

  private onConnectionChange(
    voipChannels: Channel[],
    realtime: Ably.Realtime | null
  ) {
    this.unsubscribe();

    if (!realtime) {
      this.setState({ channels: [], realtime: null });
      return;
    }

    const channels = voipChannels.map((channel) => {
      const realtimeChannel = realtime.channels.get(channel.name);

      realtimeChannel.subscribe((response) => {
        const data: AnnouncementMessage = isString(response.data)
          ? JSON.parse(response.data)
          : response.data;

        this.handleEvent(data);
      });

      return realtimeChannel;
    });

    this.setState({ channels, realtime });
  }

  private handleEvent(data: AnnouncementMessage) {
    this.props.addCall(data.voipDetails);
  }

  private unsubscribe() {
    const { channels } = this.state;
    channels.map((channel) => channel.unsubscribe());
  }
}
