import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";

const styles = require("./relation-item.component.scss");

interface GlobalSearchRelationItemComponentProps {
  onRelationClick: (id: string, type: RelationType) => void;
  relation: RelationSnapShot;
  query: string;
  active: boolean;
  list: HTMLDivElement;
}
interface GlobalSearchRelationItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class GlobalSearchRelationItemComponent extends React.Component<
  GlobalSearchRelationItemComponentProps,
  GlobalSearchRelationItemComponentState
> {
  private ref: HTMLDivElement;

  public render() {
    const { avatarUrl, letterAvatar, displayName, typeOfRelation } =
      this.props.relation;
    const avatarStyles = !!avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(avatarUrl)})` }
      : null;
    const relationStyle = classNames("relation", { active: this.props.active });

    if (this.props.active && this.props.list) this.setListPosition();

    return (
      <div
        styleName={relationStyle}
        onClick={() =>
          this.props.onRelationClick(
            this.props.relation.id,
            this.props.relation.typeOfRelation
          )
        }
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="relation__avatar" style={avatarStyles}>
          {!avatarUrl && <div styleName="letters">{letterAvatar}</div>}
        </div>
        <div styleName="relation__name">
          <div
            styleName="section"
            dangerouslySetInnerHTML={StringUtil.highlight(
              displayName,
              this.props.query
            )}
          />
          {this.renderArchived()}
          {this.renderTelephone()}
          {this.renderEmail()}
          {this.renderAddress()}
        </div>
        <div styleName="relation__type">
          <ResourceText
            masterKey="relationTypes"
            resourceKey={typeOfRelation.toString()}
          />
        </div>
      </div>
    );
  }

  private renderArchived(): React.ReactElement<HTMLDivElement> {
    const { isActive } = this.props.relation;
    if (!isActive) {
      return (
        <div styleName="section extra">
          <ResourceText resourceKey="archived" />
        </div>
      );
    }
  }

  private renderTelephone(): React.ReactElement<HTMLDivElement> {
    const { mobileNumber, phoneNumber } = this.props.relation;
    if (!mobileNumber && !phoneNumber) return null;

    const matchPhone = StringUtil.matches(phoneNumber, this.props.query);
    if (matchPhone >= 0)
      return (
        <div styleName="section extra">
          <span className="fal fa-phone" />
          <span
            dangerouslySetInnerHTML={StringUtil.highlight(
              phoneNumber,
              this.props.query
            )}
          />
        </div>
      );

    const matchmobile = StringUtil.matches(mobileNumber, this.props.query);
    if (matchmobile >= 0)
      return (
        <div styleName="section extra">
          <span className="fal fa-mobile" />
          <span
            dangerouslySetInnerHTML={StringUtil.highlight(
              mobileNumber,
              this.props.query
            )}
          />
        </div>
      );

    if (phoneNumber)
      return (
        <div styleName="section extra">
          <span className="fal fa-phone" />
          <span>{phoneNumber}</span>
        </div>
      );

    if (mobileNumber)
      return (
        <div styleName="section extra">
          <span className="fal fa-mobile" />
          <span>{mobileNumber}</span>
        </div>
      );
  }

  private renderEmail(): React.ReactElement<HTMLDivElement> {
    const { email } = this.props.relation;
    if (!email) return;

    return (
      <div styleName="section extra">
        <span className="fal fa-envelope" />
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            email,
            this.props.query
          )}
        />
      </div>
    );
  }

  private renderAddress(): React.ReactElement<HTMLDivElement> {
    const { locality, streetNameAndNumber } = this.props.relation;
    if (!locality && !streetNameAndNumber) return null;

    const values = [locality, streetNameAndNumber];
    const value = values
      .reduce((state, value) => {
        if (!!value) state.push(value);
        return state;
      }, [])
      .join(", ");

    return (
      <div styleName="section extra">
        <span className="fal fa-folder-open" />
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            value,
            this.props.query
          )}
        />
      </div>
    );
  }

  private setListPosition() {
    if (!this.ref) return;
    const { clientHeight, scrollTop } = this.props.list;
    const top = this.ref.offsetTop;
    const bottom = top + this.ref.clientHeight;

    if (bottom > clientHeight + scrollTop || top < scrollTop) {
      this.props.list.scrollTop =
        top + this.ref.clientHeight / 2 - clientHeight / 2;
    }
  }
}
