import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { FloorType } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

import { SpaceComponent } from "./space.component";
import { ExtendedFloor, ExtendedSpace } from "../";

const styles = require("../floors-and-spaces.component.scss");

interface Props {
  floor: ExtendedFloor;
  selectedFloorOrSpace: ExtendedFloor | ExtendedSpace;
  isLast: boolean;
  idx: number;
  canEditMainFloors: boolean;
  isNotRemovable?: boolean;
  onFloorSelect: (floor: ExtendedFloor) => void;
  onAddFloor: () => void;
  onDeleteFloor: (floor: ExtendedFloor) => void;
  onSpaceSelect: (space: ExtendedSpace) => void;
  onAddSpace: (floor: ExtendedFloor) => void;
  onDuplicateSpace: (space: ExtendedSpace, floor: ExtendedFloor) => void;
  onDeleteSpace: (space: ExtendedSpace, floor: ExtendedFloor) => void;
}
interface State {
  actionsVisible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class FloorComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      actionsVisible: false,
    };

    this.bindRef = this.bindRef.bind(this);
    this.toggleActionsVisible = this.toggleActionsVisible.bind(this);
    this.onFloorSelect = this.onFloorSelect.bind(this);
    this.onAddFloor = this.onAddFloor.bind(this);
    this.onDeleteFloor = this.onDeleteFloor.bind(this);
    this.onSpaceSelect = this.onSpaceSelect.bind(this);
    this.onAddSpace = this.onAddSpace.bind(this);
    this.onDuplicateSpace = this.onDuplicateSpace.bind(this);
    this.onDeleteSpace = this.onDeleteSpace.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const { floor, isLast, selectedFloorOrSpace, idx } = this.props;
    const spaces = floor.spaces || [];
    const actionsDisabled =
      (floor.floorType === FloorType.GroundFloor &&
        !this.props.canEditMainFloors) ||
      this.props.isNotRemovable;

    return (
      <div styleName="floor" ref={this.bindRef}>
        <div
          styleName={classNames("floor__title", {
            active:
              !!selectedFloorOrSpace && selectedFloorOrSpace.id === floor.id,
            "actions-visible": this.state.actionsVisible,
          })}
        >
          <div styleName="title__wrapper">
            <div
              data-cy={"CY-livingLayer-" + idx}
              styleName="title"
              onClick={this.onFloorSelect}
            >
              {this.renderNameOrTitle()}
            </div>
            {actionsDisabled ? (
              <div styleName="locked">
                <i className="fa fa-lock" />
              </div>
            ) : (
              <div styleName="trigger" onClick={this.toggleActionsVisible}>
                <i className="fal fa-ellipsis-v" />
              </div>
            )}
          </div>
          <div styleName="title__actions">
            <button
              type="button"
              styleName="delete"
              onClick={this.onDeleteFloor}
            >
              <i className="fal fa-trash-alt" />
            </button>
          </div>
        </div>
        <div styleName="floor__spaces">
          {spaces.map((space) => (
            <ErrorBoundary key={space.id}>
              <SpaceComponent
                space={space}
                floor={floor}
                selectedFloorOrSpace={selectedFloorOrSpace}
                onSpaceSelect={this.onSpaceSelect}
                onDuplicateSpace={this.onDuplicateSpace}
                onDeleteSpace={this.onDeleteSpace}
              />
            </ErrorBoundary>
          ))}
        </div>
        <div styleName="floor__actions">
          {!!isLast && (
            <button
              data-cy={"CY-livingLayerAddFloor"}
              type="button"
              className="btn btn-default squeeze"
              onClick={this.onAddFloor}
            >
              <ResourceText resourceKey="floorsAndSpaces.addFloor" />
            </button>
          )}
          <button
            data-cy={"CY-livingLayerAddSpace"}
            type="button"
            className="btn btn-default squeeze"
            onClick={this.onAddSpace}
          >
            <ResourceText resourceKey="floorsAndSpaces.addSpace" />
          </button>
        </div>
      </div>
    );
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
    }
  }

  private toggleActionsVisible() {
    this.setState(({ actionsVisible }) => ({
      actionsVisible: !actionsVisible,
    }));
  }

  private onClickOutsideHandler(event: any) {
    const clickedInside = this.ref.contains(event.target);
    if (!clickedInside && !!this.state.actionsVisible)
      this.setState({ actionsVisible: false });
  }

  private renderNameOrTitle() {
    const { name, floorType } = this.props.floor;
    if (!!name) return <span>{name} </span>;

    return (
      <ResourceText
        masterKey="floorTypeOptions"
        resourceKey={floorType.toString()}
      />
    );
  }

  private onFloorSelect() {
    const { onFloorSelect, floor } = this.props;
    onFloorSelect(floor);
    this.setState({ actionsVisible: false });
  }

  private onAddFloor() {
    const { onAddFloor } = this.props;
    onAddFloor();
    this.setState({ actionsVisible: false });
  }

  private onDeleteFloor() {
    const { onDeleteFloor, floor } = this.props;
    onDeleteFloor(floor);
    this.setState({ actionsVisible: false });
  }

  private onSpaceSelect(space) {
    const { onSpaceSelect } = this.props;
    onSpaceSelect(space);
  }

  private onAddSpace() {
    const { onAddSpace, floor } = this.props;
    onAddSpace(floor);
  }

  private onDuplicateSpace(space) {
    const { onDuplicateSpace, floor } = this.props;
    onDuplicateSpace(space, floor);
  }

  private onDeleteSpace(space) {
    const { onDeleteSpace, floor } = this.props;
    onDeleteSpace(space, floor);
  }
}
