import {
  DocumentSession,
  DocumentTemplate,
  FormElement,
  FormElementType,
  FormSection,
  ListOfGoodsType,
  ListOfGoodsValue,
} from "@haywork/api/kolibri";
import { ListOfGoodsValueExtended } from "@haywork/stores/dynamic-documents/single";
import get from "lodash-es/get";
import has from "lodash-es/has";
import { v4 as uuid } from "uuid";

export enum DynamicDocumentsSaveError {
  NoLinkedAssignment = "NoLinkedAssignment",
  NoLinkedPropertymanager = "NoLinkedPropertyManager",
}

export class DynamicDocuments {
  public static mapTemplateToFormReturnValues(
    template: DocumentTemplate,
    session: DocumentSession
  ): FormElement[] {
    if (!has(template, "form.sections")) return [];
    const formalizedSections = this.mapSectionsToFormElements(
      template.form.sections
    );
    const formElements = this.flattenAndFilterFormElements(formalizedSections);
    const formElementValues = session.formElementValues || [];

    return formElements.map((formElement) => {
      const reference = formElementValues.find(
        (v) => v.name === formElement.name
      );
      return reference || formElement;
    });
  }

  public static validForSave(
    session: DocumentSession,
    requiresObjectAssignment: boolean
  ): boolean {
    if (!session) return false;
    const linkedAssignment = session.linkedAssignment;

    if (!linkedAssignment && requiresObjectAssignment) return false;

    return true;
  }

  public static getErrors(
    session: DocumentSession,
    template: DocumentTemplate
  ): DynamicDocumentsSaveError[] {
    const errors: DynamicDocumentsSaveError[] = [];
    const linkedAssignment = session.linkedAssignment;
    const linkedPropertyManagers = session.linkedPropertyManagers || [];

    if (!linkedAssignment && template.requiresObjectAssignment)
      errors.push(DynamicDocumentsSaveError.NoLinkedAssignment);
    if (linkedPropertyManagers.length === 0 && template.needsPropertyManagers)
      errors.push(DynamicDocumentsSaveError.NoLinkedPropertymanager);

    return errors;
  }

  public static getExtendedListOfGoods(
    listOfGoodsValue: ListOfGoodsValue
  ): ListOfGoodsValueExtended {
    if (!listOfGoodsValue) return;

    const extendedListOfGoodsValue: ListOfGoodsValueExtended = {
      ...listOfGoodsValue,
      id: uuid(),
      extendedSubEntries: [],
    };

    if (
      extendedListOfGoodsValue.subEntries &&
      extendedListOfGoodsValue.subEntries.length > 0
    ) {
      extendedListOfGoodsValue.extendedSubEntries =
        extendedListOfGoodsValue.subEntries.map((subEntry) => {
          return this.getExtendedListOfGoods(subEntry);
        });
    }

    return extendedListOfGoodsValue;
  }

  public static filterEmptyValues(
    listOfGoodsValue: ListOfGoodsValueExtended
  ): ListOfGoodsValueExtended {
    if (!listOfGoodsValue) return;

    if (
      listOfGoodsValue.extendedSubEntries &&
      listOfGoodsValue.extendedSubEntries.length > 0
    ) {
      listOfGoodsValue.extendedSubEntries =
        listOfGoodsValue.extendedSubEntries.filter(
          (subEntry) =>
            subEntry.name !== "" &&
            subEntry.name !== null &&
            subEntry.name !== undefined
        );
    }

    listOfGoodsValue.extendedSubEntries =
      listOfGoodsValue.extendedSubEntries.map((subEntry) => {
        return this.filterEmptyValues(subEntry);
      });

    return listOfGoodsValue;
  }

  public static addEmptyValue(
    listOfGoodsValue: ListOfGoodsValueExtended,
    listOfGoods: ListOfGoodsValueExtended
  ): ListOfGoodsValueExtended {
    if (listOfGoodsValue.id === listOfGoods.id) {
      const newItem = {
        extendedSubEntries: [],
        id: uuid(),
        name: "",
        listOfGoodsType: ListOfGoodsType.Item,
        subEntries: [],
        takeOverSpecification: null,
      };

      // last item in the subEntries is a category, so we have to add the new item right before. Otherwise add it at the end.
      const index = this.getIndexOfLastNonCategoryItem(listOfGoods);

      if (listOfGoods.extendedSubEntries.length > 0) {
        listOfGoods.extendedSubEntries.splice(index + 1, 0, newItem);
      } else {
        listOfGoods.extendedSubEntries.push(newItem);
      }
    } else {
      listOfGoods.extendedSubEntries = listOfGoods.extendedSubEntries.map(
        (subEntry) => {
          return this.addEmptyValue(listOfGoodsValue, subEntry);
        }
      );
    }
    return listOfGoods;
  }

  public static getLastNonCategoryItem(
    listOfGoodsValue: ListOfGoodsValueExtended
  ): ListOfGoodsValueExtended {
    if (!listOfGoodsValue) return;

    let lastSubEntry: ListOfGoodsValueExtended = null;
    for (let i = listOfGoodsValue.extendedSubEntries.length - 1; i >= 0; i--) {
      const subEntry = listOfGoodsValue.extendedSubEntries[i];

      if (subEntry.listOfGoodsType === ListOfGoodsType.Item) {
        lastSubEntry = subEntry;
        break;
      }
    }
    return lastSubEntry;
  }

  public static getIndexOfLastNonCategoryItem(
    listOfGoodsValue: ListOfGoodsValueExtended
  ): number {
    if (!listOfGoodsValue) return 0;

    let index: number = 0;
    for (let i = listOfGoodsValue.extendedSubEntries.length - 1; i >= 0; i--) {
      const subEntry = listOfGoodsValue.extendedSubEntries[i];

      if (subEntry.listOfGoodsType === ListOfGoodsType.Item) {
        index = i;
        break;
      }
    }
    return index;
  }

  public static convertToNormalListOfGoods(
    listOfGoodsExtendedValue: ListOfGoodsValueExtended
  ): ListOfGoodsValue {
    if (!listOfGoodsExtendedValue) return;

    const listOfGoodsValue: ListOfGoodsValue = {
      listOfGoodsType: listOfGoodsExtendedValue.listOfGoodsType,
      name: listOfGoodsExtendedValue.name,
      takeOverSpecification: listOfGoodsExtendedValue.takeOverSpecification,
    };

    if (
      listOfGoodsExtendedValue.subEntries &&
      listOfGoodsExtendedValue.subEntries.length > 0
    ) {
      listOfGoodsValue.subEntries =
        listOfGoodsExtendedValue.extendedSubEntries.map((subEntry) => {
          return this.convertToNormalListOfGoods(subEntry);
        });
    }

    return listOfGoodsValue;
  }

  public static updateListOfGoods(
    listOfGoodsValue: ListOfGoodsValueExtended,
    listOfGoods: ListOfGoodsValueExtended
  ): ListOfGoodsValueExtended {
    if (listOfGoodsValue.id === listOfGoods.id) {
      listOfGoods = { ...listOfGoodsValue };
    } else {
      listOfGoods.extendedSubEntries = listOfGoods.extendedSubEntries.map(
        (subEntry) => {
          return this.updateListOfGoods(listOfGoodsValue, subEntry);
        }
      );
    }
    return listOfGoods;
  }

  public static removeFromListOfGoods(
    listOfGoodsValue: ListOfGoodsValueExtended,
    listOfGoods: ListOfGoodsValueExtended
  ): ListOfGoodsValueExtended {
    const foundItem = listOfGoods.extendedSubEntries.find(
      (subEntry) => subEntry.id === listOfGoodsValue.id
    );

    if (foundItem) {
      listOfGoods.extendedSubEntries = listOfGoods.extendedSubEntries.filter(
        (foundItem) => foundItem.id !== listOfGoodsValue.id
      );
    } else {
      listOfGoods.extendedSubEntries = listOfGoods.extendedSubEntries.map(
        (subEntry) => {
          return this.removeFromListOfGoods(listOfGoodsValue, subEntry);
        }
      );
    }

    return listOfGoods;
  }

  public static prepareSessionForSave(
    session: DocumentSession
  ): DocumentSession {
    const name = has(session, "linkedDocumentTemplate.displayName")
      ? session.linkedDocumentTemplate.displayName
      : session.name;
    const formElementValues = session.formElementValues.filter(
      (formElement) => {
        const { type } = formElement;

        switch (type) {
          case FormElementType.Integer: {
            return get(formElement, "integerInfo.value") !== undefined;
          }
          case FormElementType.Date: {
            return get(formElement, "dateInfo.value") !== undefined;
          }
          case FormElementType.Decimal: {
            return get(formElement, "decimalInfo.value") !== undefined;
          }
          case FormElementType.DropDown: {
            return get(formElement, "dropDownInfo.selectedIndex") !== undefined;
          }
          case FormElementType.Text: {
            return get(formElement, "textInfo.value") !== undefined;
          }
          default: {
            return false;
          }
        }
      }
    );

    return {
      ...session,
      formElementValues,
      name,
    };
  }

  private static mapSectionsToFormElements(
    sections: FormSection[]
  ): FormElement[] {
    return sections.reduce((state, section) => {
      const elements = section.elements || [];
      elements.map((element) => {
        if (!!element.formElements && element.formElements.length > 0) {
          state.push(element);
        }
      });
      return state;
    }, []);
  }

  private static flattenAndFilterFormElements(
    formElements: FormElement[],
    state: FormElement[] = []
  ): FormElement[] {
    let innerState = [...state];

    formElements.map((formElement) => {
      const children = formElement.formElements;

      if (formElement.type !== FormElementType.Heading) {
        innerState.push(formElement);
      }
      if (!!children && children.length > 0) {
        innerState = this.flattenAndFilterFormElements(children, innerState);
      }
    });

    return innerState;
  }
}
