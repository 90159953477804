import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./list.component.scss");

export const EmptyState = CSSModules(styles, { allowMultiple: true })(() => {
  return <div styleName="list__group">
    <div styleName="group-title">
      <ResourceText resourceKey="emailBucket.Today" />
    </div>
    <div styleName="item unread selected">
      <div styleName="item__unread" />
      <div styleName="item__info">
        <div styleName="name">
          Kolibri
        </div>
        <div styleName="description">
          <ResourceText resourceKey="emailEmptyStateMessageDescription" />
        </div>
        <div styleName="excerpt">
          <ResourceText resourceKey="emailEmptyStateMessageExcerpt" />
        </div>
      </div>
      <div styleName="item__meta">
        <div styleName="flags">
          <div styleName="flag dim">
            <i className="fal fa-flag" />
          </div>
        </div>
        <div styleName="date">
          <FormattedDate
            value={ new Date() }
            hour="2-digit"
            minute="2-digit"
          />
        </div>
      </div>
    </div>
  </div>;
});
