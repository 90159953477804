import {
  AssignmentSnapShot,
  LinkedRelation,
  RelationType
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { Column, Row } from "@haywork/components/ui/list";
import Pill from "@haywork/components/ui/pill";
import {
  ACQUISITIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES
} from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import LoadingState from "../loading-state";

const styles = require("./style.scss");

type Props = {
  data: AssignmentSnapShot | null;
  zebra: boolean;
  onNavigatePath: (path: string) => void;
};

export const RowComponent: FC<Props> = memo(
  ({ data, zebra, onNavigatePath }) => {
    return !data ? (
      <LoadingState zebra={zebra} />
    ) : (
      <DataRowComponent
        data={data}
        zebra={zebra}
        onNavigatePath={onNavigatePath}
      />
    );
  }
);

const DataRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ data, zebra, onNavigatePath }) => {
      const {
        realEstateGroup,
        forSale,
        forRent,
        publicReference,
        id,
        assignmentPhase,
        availabilityStatus,
        isActive,
        linkedClients
      } = data;

      const route = RouteUtil.mapStaticRouteValues;

      const onListClickHandler = useCallback(() => {
        const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        onNavigatePath(path);
      }, [id, onNavigatePath]);

      const onRelationClickHandler = useCallback(
        (relation: LinkedRelation) => {
          const { id, typeOfRelation } = relation;
          let path = "";

          switch (typeOfRelation) {
            case RelationType.ContactCompany: {
              path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
              break;
            }
            case RelationType.Employee: {
              path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
              break;
            }
            case RelationType.Office: {
              path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
              break;
            }
            default: {
              path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
              break;
            }
          }

          onNavigatePath(path);
        },
        [onNavigatePath]
      );

      const onViewMoreClients = useCallback(() => {
        onNavigatePath(route(ACQUISITIONROUTES.NETWORK.URI, { id }));
      }, [onNavigatePath, id]);

      const clients = useMemo(() => {
        let clients = linkedClients || [];
        const count = clients.length;
        clients = clients.slice(0, 5);

        return (
          <>
            {clients.map((relation) => (
              <Pill
                label={relation.displayName}
                key={relation.id}
                color={
                  relation.typeOfRelation === RelationType.ContactCompany
                    ? Colors.Secondary
                    : Colors.Primary
                }
                onClick={() => onRelationClickHandler(relation)}
              />
            ))}
            {count > 5 && (
              <Pill
                label={`+${count - 5}`}
                color={Colors.Primary}
                onClick={onViewMoreClients}
              />
            )}
          </>
        );
      }, [linkedClients, onRelationClickHandler, onViewMoreClients]);

      const kindLabel = useMemo(() => {
        switch (true) {
          case forRent && forSale: {
            return "acquisition.rentAndSale";
          }
          case forRent: {
            return "acquisition.onlyRent";
          }
          case forSale:
          default: {
            return "acquisition.onlySale";
          }
        }
      }, [forSale, forRent]);

      return (
        <Row
          zebra={zebra}
          onClick={() => onListClickHandler()}
          className={!isActive ? "archived" : ""}
        >
          <Column index={0}>
            <div styleName="publicReference">
              <div>{publicReference}</div>
            </div>
          </Column>
          <Column index={1}>
            <div styleName="linkedClients">
              <div>{clients}</div>
            </div>
          </Column>
          <Column index={2}>
            <div styleName="realEstateGroups">
              <I18n
                prefix="realEstateGroups"
                value={realEstateGroup.toString()}
              />
            </div>
          </Column>
          <Column index={3}>
            <div styleName="kindOfAssignment">
              <I18n value={kindLabel} />
            </div>
          </Column>
          <Column index={4}>
            <div styleName="status">
              {!!assignmentPhase && (
                <div styleName="assignment-phase">
                  <I18n
                    prefix="assignmentPhases"
                    value={assignmentPhase.toString()}
                  />
                </div>
              )}
              {!!availabilityStatus && (
                <div styleName="availability-status">
                  <I18n
                    prefix="availabilityStatuses"
                    value={availabilityStatus.toString()}
                  />
                </div>
              )}
            </div>
          </Column>
        </Row>
      );
    }
  )
);
