import { store } from "@haywork/stores";
import { ParseRequest } from "@haywork/services";
import {
  GlobalSearchClient,
  ActiveFilter,
  GlobalSearchSearchRequest,
  AssignmentType,
  RealEstateGroup,
  GlobalSearchType,
  RelationType,
  AssignmentSnapShot,
  RelationSnapShot,
  InvoiceSnapShot,
  TaskSnapShot
} from "@haywork/api/kolibri";
import get from "lodash-es/get";

const parseRequest = new ParseRequest();

type SearchRequest = {
  filterByActive?: ActiveFilter;
  filterByAssignmentTypes?: AssignmentType[];
  filterByRealEstateGroups?: RealEstateGroup[];
  filterByRelationTypes?: RelationType[];
  filterByTypes?: GlobalSearchType[];
  takePerType?: number;
  term: string;
};
export type GlobalSearchResponseItem = {
  type: GlobalSearchType;
  entityId: string;
  assignment?: AssignmentSnapShot;
  relation?: RelationSnapShot;
  invoice?: InvoiceSnapShot;
  task?: TaskSnapShot;
};
const search = async (
  request: SearchRequest
): Promise<GlobalSearchResponseItem[]> => {
  try {
    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new GlobalSearchClient(host);

    const {
      filterByActive,
      filterByAssignmentTypes,
      filterByRealEstateGroups,
      filterByRelationTypes,
      filterByTypes,
      takePerType,
      term
    } = request;
    const globalSearchRequest: GlobalSearchSearchRequest = {
      filterByActive: filterByActive || ActiveFilter.ActiveOnly,
      filterByAssignmentTypes,
      filterByRealEstateGroups,
      filterByRelationTypes,
      filterByTypes,
      takePerType: takePerType || 10,
      term
    };

    const response = await parseRequest.response(
      client.search(globalSearchRequest, realEstateAgencyId)
    );
    const types = filterByTypes || [];

    let searchResponse: GlobalSearchResponseItem[] = [];
    if (!types.length || types.includes(GlobalSearchType.Relations)) {
      searchResponse = [
        ...searchResponse,
        ...(get(response.relations, "results") || []).map((relation) => ({
          type: GlobalSearchType.Relations,
          entityId: relation.id,
          relation
        }))
      ];
    }

    if (!types.length || types.includes(GlobalSearchType.Assignments)) {
      searchResponse = [
        ...searchResponse,
        ...(get(response.assignments, "results") || []).map((assignment) => ({
          type: GlobalSearchType.Assignments,
          entityId: assignment.id,
          assignment
        }))
      ];
    }

    if (!types.length || types.includes(GlobalSearchType.Invoices)) {
      searchResponse = [
        ...searchResponse,
        ...(get(response.invoices, "results") || []).map((invoice) => ({
          type: GlobalSearchType.Invoices,
          entityId: invoice.id,
          invoice
        }))
      ];
    }

    if (!types.length || types.includes(GlobalSearchType.Tasks)) {
      searchResponse = [
        ...searchResponse,
        ...(get(response.tasks, "results") || []).map((task) => ({
          type: GlobalSearchType.Tasks,
          entityId: task.id,
          task
        }))
      ];
    }

    return searchResponse;
  } catch (error) {
    throw error;
  }
};

export const GlobalSearchRequest = {
  search
};
