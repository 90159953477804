import {
  AcquisitionAssignment,
  AcquisitionObjectAssignment,
  AssignmentSnapShot,
  CommunicationLog,
  MergeField,
  ObjectAssignment,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { Account, File, AccountShare } from "@haywork/api/mail";
import { EMAILROUTES, MAINROUTES } from "@haywork/constants";
import {
  AcquisitionThunk,
  AssignmentThunks,
  Dispatch,
  EmailDraftThunks,
  RelationThunks,
} from "@haywork/middleware";
import { AcquisitionObjectThunk } from "@haywork/middleware/thunk/acquisitions/acquisition-object.thunk";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { EmailCreateComponent } from "@haywork/modules/email";
import { AppState, EmailActions } from "@haywork/stores";
import { EmailActionsV2 } from "@haywork/stores/email-v2";
import { ExtendedEmailDraft } from "@haywork/util/email";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  createAccounts,
  defaultSendAccountId,
  relatedMessageId,
} from "./selectors";

interface StateProps {
  token: string;
  accounts: Account[];
  currentDraft: ExtendedEmailDraft;
  currentDraftStatus: string;
  currentAccount: string;
  emailUploadUrl: string;
  mergeFields: MergeField[];
  relatedMessageId: string;
  shares: AccountShare[];
}
interface DispatchProps {
  saveDraft: (
    accountId: string,
    draft: ExtendedEmailDraft,
    confirm?: boolean
  ) => Promise<ExtendedEmailDraft>;
  sendDraft: (
    accountId: string,
    draft: ExtendedEmailDraft,
    communicationLogId?: string,
    tracking?: boolean
  ) => Promise<string>;
  deleteDraft: (accountId: string, draft: ExtendedEmailDraft) => Promise<void>;
  deleteFile: (fileId: string, id: string) => void;
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    draft: ExtendedEmailDraft,
    communicationLog?: CommunicationLog
  ) => Promise<CommunicationLog>;
  navigate: (url: string) => void;
  getRelationsWithMatchingEmailAddress: (
    emailAddress: string[],
    relationTypes: RelationType[]
  ) => Promise<RelationSnapShot[]>;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  saveAndClose: (accountId: string, id: string) => void;
  getLinkedAssignment: (id: string) => Promise<ObjectAssignment>;
  getLinkedAcquisitionObjectAssignment: (
    id: string
  ) => Promise<AcquisitionObjectAssignment>;
  setDraftFiles: (files: File[], id: string) => void;
  setDraftAttachments: (files: File[], id: string) => void;
  setCurrentAccount: (accountId: string) => void;
  getLinkedAcquisition: (id: string) => Promise<AcquisitionAssignment>;
  getRelationsWithMatchingIds: (
    relationIds: string[],
    relationTypes: RelationType[]
  ) => Promise<RelationSnapShot[]>;
}

const mapStateToProps = <StateProps, EmailCreateComponentProps>(
  state: AppState
) => {
  const { token } = state.access;
  const currentAccount = defaultSendAccountId(state);
  const { currentDraftStatus } = state.email.drafts;
  const { currentComponentState: currentDraft } = state.editable;
  const { emailHost, emailApiVersion } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { mergeFields } = state.emailV2.templates;
  const shares = state.emailV2.shares.shares || [];
  const emailUploadUrl = `${emailHost}/${emailApiVersion}/${realEstateAgencyId}/files/upload/${currentAccount}`;

  return {
    token,
    accounts: createAccounts(state),
    currentDraft,
    currentDraftStatus,
    currentAccount,
    emailUploadUrl,
    mergeFields,
    relatedMessageId: relatedMessageId(currentDraft),
    shares,
  };
};

const mapDispatchToProps = <DispatchProps, EmailCreateComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveDraft: (
    accountId: string,
    draft: ExtendedEmailDraft,
    confirm?: boolean
  ) => dispatch(EmailDraftThunks.saveDraft(accountId, draft, confirm)),
  sendDraft: (
    accountId: string,
    draft: ExtendedEmailDraft,
    communicationLogId?: string,
    tracking?: boolean
  ) =>
    dispatch(
      EmailDraftThunks.sendDraft(accountId, draft, communicationLogId, tracking)
    ),
  deleteDraft: (accountId: string, draft: ExtendedEmailDraft) =>
    dispatch(EmailDraftThunks.deleteDraft(accountId, draft)),
  deleteFile: (fileId: string, id: string) =>
    dispatch(EmailDraftThunks.removeFile(fileId, id)),
  saveCommunicationLog: (
    linkedRelations: RelationSnapShot[],
    linkedAssignments: AssignmentSnapShot[],
    draft: ExtendedEmailDraft,
    communicationLog?: CommunicationLog
  ) =>
    dispatch(
      EmailDraftThunks.saveCommunicationLogToDraft(
        linkedRelations,
        linkedAssignments,
        draft,
        communicationLog
      )
    ),

  navigate: (route: string) => dispatch(push(route)),
  getRelationsWithMatchingEmailAddress: (
    emailAddress: string[],
    relationTypes: RelationType[]
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddress(
        emailAddress,
        relationTypes
      )
    ),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  saveAndClose: (accountId: string, id: string) =>
    dispatch(EmailDraftThunks.saveAndCloseDraft(accountId, id)),
  setDraftFiles: (files: File[], id: string) =>
    dispatch(EmailDraftThunks.setDraftFiles(files, id)),
  setDraftAttachments: (files: File[], id: string) =>
    dispatch(EmailDraftThunks.setDraftAttachments(files, id)),
  setCurrentAccount: (accountId: string) =>
    dispatch(EmailActionsV2.Main.setCurrentAccount(accountId)),
  getLinkedAssignment: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
  getLinkedAcquisitionObjectAssignment: (id: string) =>
    dispatch(AcquisitionObjectThunk.getLinkedAcquisitionObjectAssignment(id)),
  getLinkedAcquisition: (id: string) =>
    dispatch(AcquisitionThunk.getLinkedAcquisition(id)),
  getRelationsWithMatchingIds: (
    relationIds: string[],
    relationTypes: RelationType[]
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingIds(relationIds, relationTypes)
    ),
});

export type EmailCreateContainerProps = StateProps &
  DispatchProps &
  EditableHocProps;
export const EmailCreateContainer = editable(
  connect<StateProps, DispatchProps, any>(
    mapStateToProps,
    mapDispatchToProps
  )(EmailCreateComponent),
  {
    entityType: null,
    icon: MAINROUTES.EMAIL.ICON,
    thunk: EmailDraftThunks.getDraft,
    status: "email.drafts.currentDraftStatus",
    statePath: "email.drafts.currentDraft",
    action: EmailActions.Drafts.setDraft,
    route: EMAILROUTES.CREATE.URI,
    confirm: {
      title: { key: "saveEmailConfirmTitle" },
      body: { key: "saveEmailConfirmBody" },
    },
  }
);
