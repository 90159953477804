import { ActionType, ActionTypes } from "./actions";
import { RootEntityType } from "@haywork/api/event-center";
import uniqBy from "lodash-es/uniqBy";
import { v4 as uuid } from "uuid";

export type PresentEmployee = {
  employeeId: string;
  entities: {
    type: RootEntityType;
    id: string;
    openedOn: Date;
    inEditMode: boolean;
  }[];
  fingerprint: string;
  guid: string;
  browser: string;
  appVersion: string;
};

export type PresenceState = {
  presentEmployees: PresentEmployee[];
  guid: string;
};

const INITIAL: PresenceState = {
  presentEmployees: [],
  guid: uuid(),
};

export const presenceReducer = (
  state = INITIAL,
  action: ActionTypes
): PresenceState => {
  switch (action.type) {
    case ActionType.AddEmployee: {
      const { employee } = action;

      const presentEmployees = uniqBy(
        [...state.presentEmployees, employee],
        (employee) => employee.employeeId + employee.fingerprint + employee.guid
      );

      return {
        ...state,
        presentEmployees,
      };
    }
    case ActionType.UpdateEmployee: {
      const { employee } = action;
      const presentEmployees = state.presentEmployees.map((presentEmployee) => {
        if (
          presentEmployee.employeeId === employee.employeeId &&
          presentEmployee.guid === employee.guid
        ) {
          return employee;
        }
        return presentEmployee;
      });

      return {
        ...state,
        presentEmployees,
      };
    }
    case ActionType.RemoveEmployee: {
      const { id, guid } = action;
      const presentEmployees = state.presentEmployees.filter(
        (presentEmployee) =>
          presentEmployee.employeeId !== id || presentEmployee.guid !== guid
      );

      return {
        ...state,
        presentEmployees,
      };
    }
    default: {
      return state;
    }
  }
};
