import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { COMMON } from "@haywork/constants";
import { DashboardContainerProps } from "@haywork/modules/dashboard";
import {
  DashboardAverageLeadTimeWidgetComponent,
  DashboardNegotiatingPositionWidgetComponent,
  DashboardSalesWidgetComponent,
} from "@haywork/modules/dashboard/components";
import { LastChangedAssignmentsContainer } from "@haywork/modules/dashboard/containers/widgets";
import { OutstandingBrokerageWidgetContainer } from "@haywork/modules/dashboard/containers/widgets/outstanding-brokerage-widget.container";
import { SchedulerWidgetContainer } from "@haywork/modules/dashboard/containers/widgets/scheduler-widget.container";
import { TaskWidgetContainer } from "@haywork/modules/dashboard/containers/widgets/task-widget.container";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { OnboardingModalContainer } from "@haywork/modules/onboarding";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentsNeedAttention } from "../widgets/assignments-need-attention";
import HousenetAd from "../widgets/housenet-ad";
import SystemMessages from "../widgets/system-messages";

const styles = require("./dashboard.component.scss");

export type DashboardComponentProps = {};
type Props = DashboardComponentProps & DashboardContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DashboardComponent extends React.Component<Props> {
  public componentDidMount() {
    this.props.getInitializedWidgets(
      COMMON.EMPTY_GUID,
      WidgetEntityType.Dashboard
    );

    if (!this.props.isBackofficeEmployee) {
      this.props.getCompanyStatistics();
    }
  }

  public render() {
    if (!!this.props.isBackofficeEmployee) {
      return (
        <div styleName="dashboard">
          <Ui.EmptyState
            type={UiEmptyStateType.List}
            stickman={UiEmptyStateStickMan.Dashboard}
            title="dashboardEmptyStateBackOffice"
          />
        </div>
      );
    }

    return (
      <div styleName="dashboard">
        <OnboardingModalContainer />

        <div styleName="dashboard-grid">
          <div styleName="main-column">
            <div styleName="column__split">
              <div styleName="column">
                <ErrorBoundary>
                  <SchedulerWidgetContainer />
                </ErrorBoundary>

                <ErrorBoundary>
                  <LastChangedAssignmentsContainer />
                </ErrorBoundary>
              </div>

              <div styleName="column">
                <FeatureSwitch feature="HOUSENET_AD">
                  <ErrorBoundary>
                    <HousenetAd />
                  </ErrorBoundary>
                </FeatureSwitch>

                <ErrorBoundary>
                  <TaskWidgetContainer />
                </ErrorBoundary>

                <FeatureSwitch feature="APP_XCHANGE">
                  <AuthorizationWidgets
                    type={WidgetEntityType.Dashboard}
                    location={WidgetEntityLocation.Dashboard}
                    id={COMMON.EMPTY_GUID}
                  />
                </FeatureSwitch>
              </div>
            </div>
          </div>

          <div styleName="sales-column">
            <FeatureSwitch feature="APP_XCHANGE_ATTENTION_WIDGET">
              <ErrorBoundary>
                <SystemMessages />
              </ErrorBoundary>
            </FeatureSwitch>

            <ErrorBoundary>
              <AssignmentsNeedAttention />
            </ErrorBoundary>

            <div styleName="column__split">
              <div styleName="column">
                <ErrorBoundary>
                  <DashboardSalesWidgetComponent
                    statisticsThisWeek={this.props.statisticsThisWeek}
                    statisticsLastWeek={this.props.statisticsLastWeek}
                    state={this.props.companyStatisticsStatus}
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  <DashboardNegotiatingPositionWidgetComponent
                    statisticsThisMonth={this.props.statisticsThisMonth}
                    statisticsLastMonth={this.props.statisticsLastMonth}
                    state={this.props.companyStatisticsStatus}
                  />
                </ErrorBoundary>
              </div>

              <div styleName="column">
                <ErrorBoundary>
                  <DashboardAverageLeadTimeWidgetComponent
                    statisticsThisMonth={this.props.statisticsThisMonth}
                    statisticsLastMonth={this.props.statisticsLastMonth}
                    state={this.props.companyStatisticsStatus}
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  <OutstandingBrokerageWidgetContainer />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
