import {
  AssignmentSnapShot,
  ProjectAssignment,
  AssignmentType,
  RealEstateGroup,
} from "@haywork/api/kolibri";

export const mapProjectAssignmentToAssignmentSnapshot = (
  assignment: ProjectAssignment
): AssignmentSnapShot => {
  const {
    id,
    dateTimeCreated,
    dateTimeModified,
    isActive,
    linkedCreatedBy,
    linkedModifiedBy,
    realEstateAgencyId,
    assignmentPhase,
    availabilityStatus,
    forRent,
    forSale,
    isConfidential,
    keyNote,
    keyNr,
    linkedCadastres,
    linkedApplicants,
    linkedNotaries,
    linkedPropertyManagers,
    linkedVendors,
    linkedClients,
    mandateDateTime,
    photos,
    rentOffer,
    saleOffer,
    videos,
    withdrawnDateTime,
    withdrawReason,
    address,
    displayName,
    publicReference,
    linkedEmployees,
    linkedOffice,
  } = assignment;

  const houseNumber = [address?.houseNumber, address?.houseNumberPostfix]
    .filter((d) => !!d)
    .join("-");
  const streetNameAndNumber = [address?.street?.name, houseNumber]
    .filter((d) => !!d)
    .join(" ");

  return {
    id,
    assignmentPhase,
    availabilityStatus,
    countryCode: address?.countryIso2,
    dateRentedReservation: !!forRent ? rentOffer?.dateReservation : undefined,
    dateSold: !!forSale ? saleOffer?.dateSold : undefined,
    dateSoldReservation: !!forSale ? saleOffer?.dateReservation : undefined,
    dateTransfer: !!saleOffer ? saleOffer?.dateTransfer : undefined,
    displayName,
    forRent,
    forSale,
    hasCadastres: !!(linkedCadastres || []).length,
    isActive,
    isPartOfProject: false,
    keyNote,
    keyNr,
    linkedApplicants,
    linkedEmployee: (linkedEmployees || [])[0],
    linkedNotaries,
    linkedOffice,
    linkedPropertyManagers,
    linkedVendors,
    linkedClients,
    locality: address?.locality?.name,
    mandateDateTime,
    photo1Url: (photos || [])[0]?.urlPreview,
    photo2Url: (photos || [])[1]?.urlPreview,
    photo3Url: (photos || [])[2]?.urlPreview,
    postalCode: address?.postalCode,
    publicReference,
    realEstateAgencyId,
    realEstateGroup: RealEstateGroup.Residential,
    rentCommissionTotal: !!forRent ? rentOffer?.rentCommissionTotal : undefined,
    rentCondition: !!forRent ? rentOffer?.rentCondition : undefined,
    rentedFrom: !!forRent ? rentOffer?.rentedFrom : undefined,
    rentPrice: !!forRent ? rentOffer?.rentPrice : undefined,
    rentPricePerUnit: !!forRent ? rentOffer?.rentPricePerUnit : undefined,
    rentPriceMax: !!forRent ? rentOffer?.rentPriceMax : undefined,
    rentVatCondition: !!forRent ? rentOffer?.vatCondition : undefined,
    saleCommissionTotal: !!forSale ? saleOffer?.saleCommissionTotal : undefined,
    saleCondition: !!forSale ? saleOffer?.saleCondition : undefined,
    salePrice: !!forSale ? saleOffer?.salePrice : undefined,
    salePricePerUnit: !!forSale ? saleOffer?.pricePerUnit : undefined,
    salePriceMax: !!forSale ? saleOffer?.salePriceMax : undefined,
    saleVatCondition: !!forSale ? saleOffer?.vatCondition : undefined,
    streetNameAndNumber,
    totalNrOfPhotos: (photos || []).length,
    totalNrOfVideos: (videos || []).length,
    typeOfAssignment: AssignmentType.Project,
    video1Url: (videos || [])[0]?.urlOriginal,
    withdrawnDateTime,
    withdrawReason,
    dateTimeCreated,
    latitude: address?.geoLocation?.latitude,
    longitude: address?.geoLocation?.longitude,
    dateTimeModified,
    linkedCreatedBy,
    linkedModifiedBy,
    furnishing: forRent
      ? rentOffer?.furnishing || undefined
      : saleOffer?.furnishing || undefined,
    isConfidential,
    availableFrom: !!rentOffer ? rentOffer?.availableFrom : undefined,
    availableUntil: !!rentOffer ? rentOffer?.availableUntil : undefined,
  };
};
