import {
  CountryOption,
  SearchAssignmentLocation,
  SearchForLocationType,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { Radius } from "@haywork/modules/search-assignment";
import { ResourceText } from "@haywork/modules/shared";
import { AddressRequest } from "@haywork/request";
import { AddressUtil, FormControlUtil, StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { injectIntl, WithIntlProps } from "react-intl";
import classNames from "classnames";

const styles = require("./edit-where.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const doesLocationHaveAddress = AddressUtil.doesLocationHaveAddress;

interface SearchLocationProps {
  addressSearchState: string;
  addressSearchStateLocationId: number;
  radiusOptions: Radius[];
  countries: CountryOption[];
  searchLocation: SearchAssignmentLocation;
  amountOfLocations: number;
  countryIso2: string;
  idx: number;
  dateTimeModified: Date;
  createNewSearchLocation: () => void;
  searchAddress: (value: string, country: string, locationId: number) => void;
  removeSearchLocation: (id: string) => void;
  updateSearchLocation: (
    searchAssignmentLocation: SearchAssignmentLocation
  ) => void;
  addPostalCodeRange: () => void;
}
interface State {
  country: string;
  countryString: string;
  addressResult: google.maps.places.AutocompletePrediction;
  newAddress: boolean;
}
type Props = SearchLocationProps & WithIntlProps<any>;

@CSSModules(styles, { allowMultiple: true })
class SearchLocation extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { searchLocation } = this.props;

    const countryIso2 = value(
      searchLocation,
      "countryIso2",
      this.props.countryIso2
    );

    this.state = {
      country: countryIso2,
      countryString: this.renderCountryString(countryIso2),
      addressResult: null,
      newAddress: false,
    };

    this.formControls = {
      country: { value: countryIso2 },
      addressSearch: {
        value: searchLocation.displayName || "",
        onChange: (ref, get) => {
          if (ref.value && ref.value.length > 0) {
            this.props.searchAddress(
              ref.value[0].description,
              get("country").value,
              this.props.searchLocation.id
            );
          }
        },
      },
      radius: { value: value(searchLocation, "radius").toString() },
    };

    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.renderCountryString = this.renderCountryString.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.renderRemoveSearchAddress = this.renderRemoveSearchAddress.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      this.props.addressSearchState === REQUEST.ERROR &&
      this.props.addressSearchStateLocationId === this.props.searchLocation.id
    ) {
      this.formRef.update({ addressSearch: "" });
    }

    if (
      !!this.formRef &&
      prevProps.dateTimeModified !== this.props.dateTimeModified
    ) {
      const { searchLocation } = this.props;

      const countryIso2 = value(
        searchLocation,
        "countryIso2",
        this.props.countryIso2
      );

      this.setState({
        country: countryIso2,
        countryString: this.renderCountryString(countryIso2),
      });

      this.formRef.update(
        {
          country: countryIso2,
          addressSearch: searchLocation.displayName || "",
          radius: value(searchLocation, "radius").toString(),
        },
        true
      );
    }
  }

  public render() {
    return (
      <div styleName="search__location">
        <Form
          name="address-search"
          onChange={this.onFormChangeHandler}
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="address__header">
            <div styleName="title">
              {this.props.searchLocation.displayName || ""}
            </div>
            <span styleName="line" />
            {this.renderRemoveSearchAddress()}
          </div>
          <div className="form__row">
            <div className="form__group">
              <div className="column" styleName="address-search-country">
                <Input.NewSelect
                  name="country"
                  values={this.props.countries}
                  valuesProp="iso2CodeValue"
                  filterProp="displayName"
                  displayValueFn={this.renderCountryValue}
                  displayFn={this.renderCountryOption}
                  onChange={(value) =>
                    this.setState({
                      countryString: this.renderCountryString(value),
                    })
                  }
                />
              </div>
              <div className="column__spacer" />
              <div className="column" styleName="address-search-query">
                <Input.QueryLegacy
                  name="addressSearch"
                  asyncValues={(value) =>
                    AddressRequest.addressSearchV2(
                      value,
                      this.state.country,
                      true
                    )
                  }
                  placeholder="addressPlaceholderAlt"
                  displayPath="description"
                  disabled={
                    this.props.addressSearchState === REQUEST.PENDING &&
                    this.props.addressSearchStateLocationId ===
                      this.props.searchLocation.id
                  }
                  isLoading={
                    this.props.addressSearchState === REQUEST.PENDING &&
                    this.props.addressSearchStateLocationId ===
                      this.props.searchLocation.id
                  }
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <Input.NewSelect
                  name="radius"
                  values={this.props.radiusOptions}
                  displayProp="displayName"
                  valueOutMapper={(value: Radius) => value.radius.toString()}
                  valueInMapper={(value: string | number) =>
                    typeof value === "string" ? parseFloat(value) : value
                  }
                  valuesProp="radius"
                />
              </div>
            </div>
          </div>
          {this.renderExampleOrError()}

          {this.props.idx === this.props.amountOfLocations && (
            <div styleName="button__row" className="form__row">
              {doesLocationHaveAddress(this.props.searchLocation) && (
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.props.createNewSearchLocation}
                >
                  <ResourceText resourceKey="addExtraSearchLocation" />
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.props.addPostalCodeRange}
              >
                <ResourceText resourceKey="searchAssignment.addPostalCodeRange" />
              </button>
            </div>
          )}
        </Form>
      </div>
    );
  }

  private renderExampleOrError(): JSX.Element {
    if (
      this.props.addressSearchState === REQUEST.ERROR &&
      this.props.addressSearchStateLocationId === this.props.searchLocation.id
    ) {
      return (
        <div styleName="address__error">
          <ResourceText
            resourceKey="couldNotFindAddress"
            values={{ path: SUPPORTURI.ADDRESSNOTFOUND }}
            asHtml
          />
        </div>
      );
    } else if (!doesLocationHaveAddress(this.props.searchLocation)) {
      return (
        <div styleName="addressExample" className="form__row">
          <i className="fa fa-fw fa-info-circle" />
          <ResourceText resourceKey="searchAssignmenAddressExample" asHtml />
        </div>
      );
    }
  }

  private onFormChangeHandler(result: FormReturnValue) {
    if (result.country !== this.state.country) {
      this.setState({ country: result.country });
    }

    let searchLocation: SearchAssignmentLocation = {
      ...this.props.searchLocation,
      radius: parseInt(result.radius),
    };

    if (!result.addressSearch || result.addressSearch.length === 0) {
      searchLocation = this.clearAddressFromLocation(searchLocation);
    }

    this.props.updateSearchLocation(searchLocation);
  }
  private clearAddressFromLocation(
    location: SearchAssignmentLocation
  ): SearchAssignmentLocation {
    const searchLocation: SearchAssignmentLocation = {
      ...location,
      displayName: null,
      adminAreaLevel1: null,
      adminAreaLevel2: null,
      adminAreaLevel3: null,
      countryIso2: null,
      geoLocation: null,
      locality: null,
      postalCode: null,
      searchForLocationType: SearchForLocationType.Place,
      street: null,
      sublocality: null,
    };

    return searchLocation;
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private renderCountryString(countryCode: string): string {
    return this.props.intl.formatMessage({
      id: `countryCode${countryCode}`,
      defaultMessage: countryCode,
    });
  }

  private renderRemoveSearchAddress() {
    const { searchLocation, amountOfLocations } = this.props;
    if (amountOfLocations > 1) {
      return (
        <div
          styleName="addressBarRemoveOption"
          onClick={() => {
            this.props.removeSearchLocation(searchLocation.id.toString());
          }}
        >
          <i className="fa fa-times-circle" />
        </div>
      );
    }
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}

export const SearchLocationComponent = injectIntl(SearchLocation);
