import { Account, SyncStatus } from "@haywork/api/mail";

export enum ActionType {
  SetAccounts = "EmailV2.Accounts.Set",
  UpdateAccount = "EmailV2.Accounts.UpdateAccount",
  RemoveAccount = "EmailV2.Accounts.RemoveAccount",
  SetAccountSyncStatus = "EmailV2.Accounts.SetAccountSyncStatus",
  AppendAccount = "EmailV2.Accounts.AppendAccount",
  Clear = "EmailV2.Main.Clear",
  ResetApp = "RESET_APP",
}

type SetAccounts = {
  type: ActionType.SetAccounts;
  accounts: Account[];
};

const setAccounts = (accounts: Account[]) => ({
  type: ActionType.SetAccounts,
  accounts,
});

type UpdateAccount = {
  type: ActionType.UpdateAccount;
  account: Account;
};

const updateAccount = (account: Account) => ({
  type: ActionType.UpdateAccount,
  account,
});

type RemoveAccount = {
  type: ActionType.RemoveAccount;
  id: string;
};

const removeAccount = (id: string) => ({
  type: ActionType.RemoveAccount,
  id,
});

type SetAccountSyncStatus = {
  type: ActionType.SetAccountSyncStatus;
  accountId: string;
  syncStatus: SyncStatus;
};

const setAccountSyncStatus = (accountId: string, syncStatus: SyncStatus) => ({
  type: ActionType.SetAccountSyncStatus,
  accountId,
  syncStatus,
});

type AppendAccount = {
  type: ActionType.AppendAccount;
  account: Account;
};

const appendAccount = (account: Account) => ({
  type: ActionType.AppendAccount,
  account,
});

type Clear = {
  type: ActionType.Clear;
};

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | SetAccounts
  | UpdateAccount
  | RemoveAccount
  | SetAccountSyncStatus
  | AppendAccount
  | Clear
  | ResetApp;
export const actions = {
  setAccounts,
  updateAccount,
  removeAccount,
  setAccountSyncStatus,
  appendAccount,
};
