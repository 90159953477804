export const MEDIAPARTNERS = {
  SET_MEDIA_CONTRACTS: "SET_MEDIA_CONTRACTS",
  APPEND_MEDIA_CONTRACTS: "APPEND_MEDIA_CONTRACTS",
  SET_MEDIA_CONTRACTS_STATUS: "SET_MEDIA_CONTRACTS_STATUS",
  SET_MEDIA_CONTRACTS_ERROR: "SET_MEDIA_CONTRACTS_ERROR",
  RESET_FILTERS: "MEDIA_CONTRACTS_RESET_FILTERS",
  TOGGLE_IS_ACTIVE: "MEDIA_CONTRACTS_TOGGLE_IS_ACTIVE",
  TOGGLE_IS_FAVORITE: "MEDIA_CONTRACTS_TOGGLE_IS_FAVORITE",
  SET_CATEGORIES: "MEDIA_CONTRACTS_SET_CATEGORIES"
};
