import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import {
  WidgetEntityLocation,
  WidgetEntityType
} from "@haywork/api/authorization";

const consentedWidgetsForEntitySelector = (state: AppState) =>
  state.authorization.widgets.consentedWidgets;

export const consentedWidgetsForEntity = (
  location: WidgetEntityLocation,
  type: WidgetEntityType
) =>
  createSelector(
    consentedWidgetsForEntitySelector,
    (widgets) => {
      return widgets.filter(
        (widget) =>
          widget.entityTypes.indexOf(type) !== -1 &&
          widget.location === location
      );
    }
  );
