import classNames from "classnames";
import noop from "lodash-es/noop";
import * as React from "react";
import { QueryOptionReturnValue } from "./base.component";
import isEqual from "lodash-es/isEqual";

export interface OptionValue<G> {
  disabled: boolean;
  value: G;
}
interface OptionComponentProps<G> {
  option: OptionValue<G>;
  query: string;
  idx: number;
  selectedOptionKey: number;
  optionValue: (value: G, query: string) => QueryOptionReturnValue;
  onClick: (option: OptionValue<G>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
interface OptionComponentState {}

export class OptionComponent<G> extends React.Component<
  OptionComponentProps<G>,
  OptionComponentState
> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.bindRefElement = this.bindRefElement.bind(this);
  }

  public render() {
    const selected = this.props.idx === this.props.selectedOptionKey;
    const optionStyle = classNames("option__value", {
      selected,
      disabled: this.props.option.disabled,
    });

    if (selected) {
      this.scrollParent();
    }

    return (
      <div
        className={optionStyle}
        onClick={this.onClickHandler}
        onMouseDown={this.props.onMouseDown || noop}
        onMouseUp={this.props.onMouseUp || noop}
        ref={this.bindRefElement}
        data-cy="CY-queryOptionItem"
      >
        {this.props.optionValue(this.props.option.value, this.props.query)}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: OptionComponentProps<G>) {
    const {
      selectedOptionKey: newKey,
      idx: newIdx,
      query: newQuery,
      option: newOption,
    } = nextProps;
    const {
      selectedOptionKey: oldKey,
      idx: oldIdx,
      query: oldQuery,
      option: oldOption,
    } = this.props;
    return (
      !!nextProps &&
      (newKey !== oldKey ||
        newIdx !== oldIdx ||
        newQuery !== oldQuery ||
        !isEqual(newOption, oldOption))
    );
  }

  private onClickHandler() {
    if (!!this.props.option.disabled) return;
    this.props.onClick(this.props.option);
  }

  private bindRefElement(ref: HTMLDivElement) {
    if (!this.ref && !!ref) {
      this.ref = ref;
    }
  }

  private scrollParent() {
    if (!this.ref) return;
    const parent = this.ref.parentElement;
    const top = this.ref.offsetTop;
    const { height: parentHeight } = parent.getBoundingClientRect();
    const { height: refHeight } = this.ref.getBoundingClientRect();

    if (refHeight + top > parentHeight + parent.scrollTop) {
      parent.scrollTop = top;
    }
    if (top < parent.scrollTop) {
      parent.scrollTop = top + refHeight - parentHeight;
    }
  }
}
