import { GlobalSearchSearchContainer } from "@haywork/modules/global-search";
import {
  CreateMenuContainer,
  UserMenuContainer
} from "@haywork/modules/layout";
import { ResourceText } from "@haywork/modules/shared";
import { OnboardingPortal } from "@haywork/portals";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { UiOnboardingContainerProps } from "./onboarding.container";

const styles = require("./onboarding.component.scss");

export interface UiOnboardingComponentProps {}
interface State {
  step: number;
  createMenuStyle: React.CSSProperties;
  globalSearchStyle: React.CSSProperties;
  userMenuStyle: React.CSSProperties;
}
type Props = UiOnboardingComponentProps & UiOnboardingContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class UiOnboardingComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.nextStep = this.nextStep.bind(this);

    this.state = {
      step: 0,
      createMenuStyle: null,
      globalSearchStyle: null,
      userMenuStyle: null
    };
  }

  public componentDidMount() {
    this.getPointerStyles();
  }

  public render() {
    if (!this.props.onboardingVisibility) return null;

    return (
      <OnboardingPortal>
        <div styleName="onboarding">
          {/* Create menu */}
          {this.state.step === 0 && (
            <div styleName="step step-1">
              <div
                styleName="element-wrapper"
                style={this.state.createMenuStyle}
              >
                <div styleName="lift">
                  <CreateMenuContainer />
                </div>
                <div styleName="focus" />
              </div>
              <div styleName="placeholder">
                <ResourceText resourceKey="onboardingCreateMenu" asHtml />
                <div className="btn btn-success" onClick={this.nextStep}>
                  <ResourceText resourceKey="next" />
                </div>
              </div>
            </div>
          )}

          {/* Global search */}
          {this.state.step === 1 && (
            <div styleName="step step-2">
              <div
                styleName="element-wrapper"
                style={this.state.globalSearchStyle}
              >
                <div styleName="lift">
                  <GlobalSearchSearchContainer />
                </div>
                <div styleName="focus gs" />
              </div>
              <div styleName="placeholder">
                <ResourceText resourceKey="onboardingGlobalSearch" asHtml />
                <div className="btn btn-success" onClick={this.nextStep}>
                  <ResourceText resourceKey="next" />
                </div>
              </div>
            </div>
          )}

          {/* User menu */}
          {this.state.step === 2 && (
            <div styleName="step step-3">
              <div styleName="element-wrapper" style={this.state.userMenuStyle}>
                <div styleName="lift">
                  <UserMenuContainer />
                </div>
                <div styleName="focus" />
              </div>
              <div styleName="placeholder">
                <ResourceText resourceKey="onboardingUserMenu" asHtml />
                <div
                  className="btn btn-success"
                  id="getStarted"
                  onClick={this.props.hideOnboarding}
                >
                  <ResourceText resourceKey="getStarted" />
                </div>
              </div>
            </div>
          )}
        </div>
      </OnboardingPortal>
    );
  }

  public UNSAFE_componentWillReceiveProps() {
    this.getPointerStyles();
  }

  private nextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  private getPointerStyles() {
    const createMenu = document.getElementById("create-menu");
    let createMenuStyle = null;

    if (createMenu) {
      const { top, left, width } = createMenu.getBoundingClientRect();
      createMenuStyle = { top, left, width };
    }

    const globalSearch = document.getElementById("global-search");
    let globalSearchStyle = null;

    if (globalSearch) {
      const { top, left, width } = globalSearch.getBoundingClientRect();
      globalSearchStyle = { top, left, width };
    }

    const userMenu = document.getElementById("user-menu");
    let userMenuStyle = null;

    if (userMenu) {
      const { top, left, width, height } = userMenu.getBoundingClientRect();
      userMenuStyle = { top, left, width, height };
    }

    this.setState({ createMenuStyle, globalSearchStyle, userMenuStyle });
  }
}
