import {
  AgendaItemCategory,
  AgendaItemCategoryReminder,
  AgendaItemCategorySnapShot,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { SCHEDULERCATEGORIESROUTES } from "@haywork/constants";
import { EditableHocProps } from "@haywork/modules/editable";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil, SchedulerCategoryUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SettingsSchedulerEditContainerProps } from "../../containers/edit.container";
import { ItemForm } from "../form";

enum EmailReminderText {
  EmailConfirmation = "emailConfirmation",
  EmailFirstReminder = "emailFirstReminder",
  EmailSecondReminder = "emailSecondReminder",
  EmailAnnulation = "emailAnnulation",
}

enum SMSReminderText {
  SMSConfirmation = "smsConfirmation",
  SMSFirstReminder = "smsFirstReminder",
  SMSSecondReminder = "smsSecondReminder",
  SMSAnnulation = "smsAnnulation",
}

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./edit.component.scss");
const dummyValues = [
  { value: "E-mail", displayName: "E-mail" },
  { value: "SMS", displayName: "SMS" },
];
const emailReminderTypes = [
  EmailReminderText.EmailConfirmation,
  EmailReminderText.EmailFirstReminder,
  EmailReminderText.EmailSecondReminder,
  EmailReminderText.EmailAnnulation,
];
const smsReminderTypes = [
  SMSReminderText.SMSConfirmation,
  SMSReminderText.SMSFirstReminder,
  SMSReminderText.SMSSecondReminder,
  SMSReminderText.SMSAnnulation,
];

export interface SettingsSchedulerEditComponentProps {}
interface State {
  loading: boolean;
  emailReminderText: EmailReminderText;
  smsReminderText: SMSReminderText;
}
type Props = SettingsSchedulerEditComponentProps &
  SettingsSchedulerEditContainerProps &
  EditableHocProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerEditComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;
  private selection: {
    type: EmailReminderText | SMSReminderText;
    start: number;
    end: number;
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      emailReminderText: EmailReminderText.EmailConfirmation,
      smsReminderText: SMSReminderText.SMSConfirmation,
    };

    const {
      directlyAfterConfirmationTypes,
      daysBeforeMeetingTypes,
      hoursBeforeMeetingTypes,
      directlyAfterAnnulationTypes,
    } = this.mapMessageTypes();

    const {
      emailConfirmationBody,
      emailFirstReminderTitle,
      emailConfirmationTitle,
      emailFirstReminderBody,
      emailSecondReminderTitle,
      emailSecondReminderBody,
      emailAnnulationTitle,
      emailAnnulationBody,
      smsConfirmationBody,
      smsFirstReminderBody,
      smsSecondReminderBody,
      smsAnnulationBody,
    } = this.mapReminderTexts();

    this.formControls = {
      standardDescription: {
        value: get(this.props.agendaItemCategory, "standardDescription", ""),
      },
      directlyAfterConfirmationTypes: { value: directlyAfterConfirmationTypes },
      daysBeforeMeeting: {
        value: get(
          this.props.agendaItemCategory,
          "reminder.firstConfirmation.daysUntilConfirmation",
          ""
        ),
      },
      daysBeforeMeetingTypes: { value: daysBeforeMeetingTypes },
      hoursBeforeMeeting: {
        value: get(
          this.props.agendaItemCategory,
          "reminder.secondConfirmation.daysUntilConfirmation",
          ""
        ),
      },
      hoursBeforeMeetingTypes: { value: hoursBeforeMeetingTypes },
      directlyAfterAnnulationTypes: { value: directlyAfterAnnulationTypes },
      emailConfirmationTitle: { value: emailConfirmationTitle },
      emailConfirmationBody: { value: emailConfirmationBody },
      emailFirstReminderTitle: { value: emailFirstReminderTitle },
      emailFirstReminderBody: { value: emailFirstReminderBody },
      emailSecondReminderTitle: { value: emailSecondReminderTitle },
      emailSecondReminderBody: { value: emailSecondReminderBody },
      emailAnnulationTitle: { value: emailAnnulationTitle },
      emailAnnulationBody: { value: emailAnnulationBody },
      smsConfirmationBody: { value: smsConfirmationBody },
      smsFirstReminderBody: { value: smsFirstReminderBody },
      smsSecondReminderBody: { value: smsSecondReminderBody },
      smsAnnulationBody: { value: smsAnnulationBody },
      mergeField: { value: "" },
    };

    this.onItemChange = this.onItemChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateAgendaItemCategory = this.updateAgendaItemCategory.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setEmailReminderType = this.setEmailReminderType.bind(this);
    this.setSMSReminderType = this.setSMSReminderType.bind(this);
    this.setCurrentSelection = this.setCurrentSelection.bind(this);
    this.insertMergeField = this.insertMergeField.bind(this);
  }

  public componentDidMount() {
    const displayName = get(this.props.agendaItemCategory, "displayName");
    if (!!displayName) this.props.setTabTitle(displayName);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !!this.form &&
      get(nextProps.agendaItemCategory, "id") !==
        get(this.props.agendaItemCategory, "id")
    ) {
      const {
        directlyAfterConfirmationTypes,
        daysBeforeMeetingTypes,
        hoursBeforeMeetingTypes,
        directlyAfterAnnulationTypes,
      } = this.mapMessageTypes();
      const {
        emailConfirmationBody,
        emailFirstReminderTitle,
        emailConfirmationTitle,
        emailFirstReminderBody,
        emailSecondReminderTitle,
        emailSecondReminderBody,
        emailAnnulationTitle,
        emailAnnulationBody,
        smsConfirmationBody,
        smsFirstReminderBody,
        smsSecondReminderBody,
        smsAnnulationBody,
      } = this.mapReminderTexts();

      this.form.update({
        standardDescription: get(
          nextProps.agendaItemCategory,
          "standardDescription",
          ""
        ),
        daysBeforeMeeting: get(
          this.props.agendaItemCategory,
          "reminder.firstConfirmation.daysUntilConfirmation",
          ""
        ),
        hoursBeforeMeeting: get(
          this.props.agendaItemCategory,
          "reminder.secondConfirmation.daysUntilConfirmation",
          ""
        ),
        directlyAfterConfirmationTypes,
        daysBeforeMeetingTypes,
        hoursBeforeMeetingTypes,
        directlyAfterAnnulationTypes,
        emailConfirmationBody,
        emailFirstReminderTitle,
        emailConfirmationTitle,
        emailFirstReminderBody,
        emailSecondReminderTitle,
        emailSecondReminderBody,
        emailAnnulationTitle,
        emailAnnulationBody,
        smsConfirmationBody,
        smsFirstReminderBody,
        smsSecondReminderBody,
        smsAnnulationBody,
      });
    }

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.saveCategory(nextProps.agendaItemCategory);
      this.setState({ loading: true });
    }
  }

  public render() {
    if (!this.props.agendaItemCategory) return null;
    const category = SchedulerCategoryUtil.convertToSnapShot(
      this.props.agendaItemCategory,
      this.props.agendaStandardDurationOptions
    );

    return (
      <div styleName="scheduler-category" data-cy="CY-categoryParent">
        <PageHeader
          title={this.props.agendaItemCategory.displayName || ""}
          subTitle="manageSchedulerCategories"
          actions={
            <Button
              label="save"
              category="success"
              onClick={() => this.form.submit()}
              disabled={this.state.loading}
            />
          }
        />

        <div styleName="scheduler-category__body">
          {this.state.loading && <Ui.Loaders.Fullscreen mask={true} />}
          <Form
            name="edit-scheduler-category"
            formControls={this.formControls}
            form={(form) => (this.form = form)}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          >
            <div className="container-fluid">
              <div styleName="row">
                <h2>
                  <I18n value="settingsSchedulerEditTitle.category" />
                </h2>
                <div className="form__row">
                  <ItemForm
                    agendaItemCategoryTypes={this.props.agendaItemCategoryTypes}
                    agendaStandardDurationOptions={
                      this.props.agendaStandardDurationOptions
                    }
                    category={category}
                    onChange={this.onItemChange}
                  />
                </div>
                <div className="form__row">
                  <Input.Textarea
                    name="standardDescription"
                    placeholder="settingsSchedulerEditPlaceholder.standardDescription"
                    data-cy="CY-standardDescription"
                    maxCount={1024}
                  />
                </div>
              </div>

              <div styleName="row">
                <h2>
                  <I18n value="settingsSchedulerEditTitle.updates" />
                </h2>
                <div styleName="update-row">
                  <div styleName="update-row__column label">
                    <I18n value="settingsSchedulerEditLabel.directlyAfterConfirmationTypes" />
                  </div>
                  <div styleName="update-row__column">
                    <Input.Multi
                      name="directlyAfterConfirmationTypes"
                      values={dummyValues}
                      data-cy="CY-afterConfirmationTypes"
                    />
                  </div>
                  <div styleName="update-row__column">
                    <I18n value="settingsSchedulerEdit.smsCosts" />
                  </div>
                </div>

                <div styleName="update-row">
                  <div styleName="update-row__column label">
                    <div styleName="input">
                      <Input.Number
                        name="daysBeforeMeeting"
                        data-cy="CY-daysBeforeMeeting"
                      />
                    </div>
                    <I18n value="settingsSchedulerEditLabel.daysBeforeMeetingTypes" />
                  </div>
                  <div styleName="update-row__column">
                    <Input.Multi
                      name="daysBeforeMeetingTypes"
                      values={dummyValues}
                      data-cy="CY-daysBeforeMeetingTypes"
                    />
                  </div>
                  <div styleName="update-row__column">
                    <I18n value="settingsSchedulerEdit.smsCosts" />
                  </div>
                </div>

                <div styleName="update-row">
                  <div styleName="update-row__column label">
                    <div styleName="input">
                      <Input.Number
                        name="hoursBeforeMeeting"
                        data-cy="CY-hoursBeforeMeeting"
                      />
                    </div>
                    <I18n value="settingsSchedulerEditLabel.hoursBeforeMeetingTypes" />
                  </div>
                  <div styleName="update-row__column">
                    <Input.Multi
                      name="hoursBeforeMeetingTypes"
                      values={dummyValues}
                      data-cy="hoursBeforeMeetingTypes"
                    />
                  </div>
                  <div styleName="update-row__column">
                    <I18n value="settingsSchedulerEdit.smsCosts" />
                  </div>
                </div>

                <div styleName="update-row">
                  <div styleName="update-row__column label">
                    <I18n value="settingsSchedulerEditLabel.directlyAfterAnnulationTypes" />
                  </div>
                  <div styleName="update-row__column">
                    <Input.Multi
                      name="directlyAfterAnnulationTypes"
                      values={dummyValues}
                      data-cy="directlyAfterAnnulationTypes"
                    />
                  </div>
                  <div styleName="update-row__column">
                    <I18n value="settingsSchedulerEdit.smsCosts" />
                  </div>
                </div>
              </div>

              <div styleName="row">
                <h2>
                  <I18n value="settingsSchedulerEditTitle.emailUpdate" />
                </h2>

                <div
                  styleName={classNames(
                    "merge-control",
                    this.state.emailReminderText.toString()
                  )}
                >
                  <div styleName="merge-control__switch">
                    {emailReminderTypes.map((type) => (
                      <button
                        type="button"
                        onClick={() => this.setEmailReminderType(type)}
                        styleName={classNames({
                          active: type === this.state.emailReminderText,
                        })}
                        key={type.toString()}
                        data-cy={`CY-button${type.toString()}`}
                      >
                        <I18n
                          value={`agendaCategoryMergeButton.${type.toString()}`}
                        />
                      </button>
                    ))}
                  </div>
                  {emailReminderTypes.map((type) => (
                    <div
                      styleName={classNames(
                        "merge-control__form",
                        type.toString()
                      )}
                      key={type.toString()}
                    >
                      <div styleName="row">
                        <Input.Text
                          name={`${type.toString()}Title`}
                          placeholder="agendaCategoryPlaceholder.Title"
                          data-cy={`CY-${type.toString()}Title`}
                        />
                      </div>
                      <div styleName="row">
                        <Input.Textarea
                          name={`${type.toString()}Body`}
                          data-cy={`CY-${type.toString()}Body`}
                          placeholder="agendaCategoryPlaceholder.Body"
                          autoSize={true}
                          disableTrim={true}
                          minHeight={72}
                          onSelectionChange={(start, end) =>
                            this.setCurrentSelection(type, start, end)
                          }
                        />
                      </div>
                      <div styleName="merge-fields">
                        <label>
                          <I18n value="agendaCategoryMergefieldLabel" />
                        </label>
                        <Input.NewSelect
                          name="mergeField"
                          values={
                            this.props.agendaItemReminderMergeFieldOptions
                          }
                          onChange={(value) =>
                            this.insertMergeField(type, value)
                          }
                          addEmptyOption
                          emptyOptionLabel="agendaCategoryAllMergeFields"
                          displayProp="displayName"
                          valuesProp="mergeFieldValue"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div styleName="row">
                <h2>
                  <I18n value="settingsSchedulerEditTitle.smsUpdate" />
                </h2>

                <div
                  styleName={classNames(
                    "merge-control",
                    this.state.smsReminderText.toString()
                  )}
                >
                  <div styleName="merge-control__switch">
                    {smsReminderTypes.map((type) => (
                      <button
                        type="button"
                        onClick={() => this.setSMSReminderType(type)}
                        styleName={classNames({
                          active: type === this.state.smsReminderText,
                        })}
                        key={type.toString()}
                        data-cy={`CY-button${type.toString()}`}
                      >
                        <I18n
                          prefix="agendaCategoryMergeButton"
                          value={type.toString()}
                        />
                      </button>
                    ))}
                  </div>
                  {smsReminderTypes.map((type) => (
                    <div
                      styleName={classNames(
                        "merge-control__form",
                        type.toString()
                      )}
                      key={type.toString()}
                    >
                      <div styleName="row">
                        <Input.Textarea
                          name={`${type.toString()}Body`}
                          data-cy={`CY-${type.toString()}Body`}
                          placeholder="agendaCategoryPlaceholder.Body"
                          autoSize={true}
                          disableTrim={true}
                          minHeight={72}
                          onSelectionChange={(start, end) =>
                            this.setCurrentSelection(type, start, end)
                          }
                          maxCount={160}
                          maxLength={160}
                        />
                      </div>
                      <div styleName="merge-fields">
                        <label>
                          <I18n value="agendaCategoryMergefieldLabel" />
                        </label>
                        <Input.NewSelect
                          name="mergeField"
                          values={
                            this.props.agendaItemReminderMergeFieldOptions
                          }
                          onChange={(value) =>
                            this.insertMergeField(type, value)
                          }
                          addEmptyOption
                          emptyOptionLabel="agendaCategoryAllMergeFields"
                          displayProp="displayName"
                          valuesProp="mergeFieldValue"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  private onItemChange(category: AgendaItemCategorySnapShot) {
    const {
      backColor,
      displayName,
      categoryType,
      standardDurationInMinutes,
      needsToBeConfirmed,
    } = category;

    if (category.displayName !== this.props.agendaItemCategory.displayName) {
      this.props.setTabTitle(category.displayName);
    }

    const standardDuration = SchedulerCategoryUtil.minutesToStandardDuration(
      standardDurationInMinutes,
      this.props.agendaStandardDurationOptions
    );
    const agendaItemCategory: AgendaItemCategory = {
      ...this.props.agendaItemCategory,
      backColor,
      displayName,
      categoryType,
      needsToBeConfirmed,
      standardDuration,
    };

    this.updateAgendaItemCategory(agendaItemCategory);
  }

  private onChange(values: FormReturnValue) {
    const { standardDescription } = values;

    const reminder: AgendaItemCategoryReminder = {
      firstConfirmation: {
        daysUntilConfirmation: get(values, "daysBeforeMeeting"),
        isConfirmationSMSEnabled:
          (get(values, "daysBeforeMeetingTypes") || []).indexOf("SMS") !== -1,
        isConfirmationEmailEnabled:
          (get(values, "daysBeforeMeetingTypes") || []).indexOf("E-mail") !==
          -1,
        emailText: values.emailFirstReminderBody,
        emailSubject: values.emailFirstReminderTitle,
        smsText: values.smsFirstReminderBody,
      },
      secondConfirmation: {
        daysUntilConfirmation: get(values, "hoursBeforeMeeting"),
        isConfirmationSMSEnabled:
          (get(values, "hoursBeforeMeetingTypes") || []).indexOf("SMS") !== -1,
        isConfirmationEmailEnabled:
          (get(values, "hoursBeforeMeetingTypes") || []).indexOf("E-mail") !==
          -1,
        emailText: values.emailSecondReminderBody,
        emailSubject: values.emailSecondReminderTitle,
        smsText: values.smsSecondReminderBody,
      },
      confirmationMessage: {
        isConfirmedEmailEnabled:
          (get(values, "directlyAfterConfirmationTypes") || []).indexOf(
            "E-mail"
          ) !== -1,
        isConfirmedSMSEnabled:
          (get(values, "directlyAfterConfirmationTypes") || []).indexOf(
            "SMS"
          ) !== -1,
        confirmedEmailText: values.emailConfirmationBody,
        confirmedEmailSubject: values.emailConfirmationTitle,
        confirmedSMSText: values.smsConfirmationBody,
      },
      cancellationMessage: {
        isCancelledEmailEnabled:
          (get(values, "directlyAfterAnnulationTypes") || []).indexOf(
            "E-mail"
          ) !== -1,
        isCancelledSMSEnabled:
          (get(values, "directlyAfterAnnulationTypes") || []).indexOf("SMS") !==
          -1,
        cancelledEmailText: values.emailAnnulationBody,
        cancelledEmailSubject: values.emailAnnulationTitle,
        cancelledSmsText: values.smsAnnulationBody,
      },
    };

    const agendaItemCategory: AgendaItemCategory = {
      ...this.props.agendaItemCategory,
      standardDescription,
      reminder,
    };

    this.updateAgendaItemCategory(agendaItemCategory);
  }

  private updateAgendaItemCategory(agendaItemCategory: AgendaItemCategory) {
    const path = route(SCHEDULERCATEGORIESROUTES.DETAIL.URI, {
      id: agendaItemCategory.id,
    });
    this.props.updateCache(agendaItemCategory, path);
  }

  private onSubmit() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.props.saveCategory(this.props.agendaItemCategory).catch(() => {
      this.setState({ loading: false });
    });
  }

  private mapMessageTypes() {
    const directlyAfterConfirmationTypes = [];
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.confirmationMessage.isConfirmedEmailEnabled"
      ) === true
    ) {
      directlyAfterConfirmationTypes.push("E-mail");
    }
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.confirmationMessage.isConfirmedSMSEnabled"
      ) === true
    ) {
      directlyAfterConfirmationTypes.push("SMS");
    }

    const daysBeforeMeetingTypes = [];
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.firstConfirmation.isConfirmationEmailEnabled"
      ) === true
    ) {
      daysBeforeMeetingTypes.push("E-mail");
    }
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.firstConfirmation.isConfirmationSMSEnabled"
      ) === true
    ) {
      daysBeforeMeetingTypes.push("SMS");
    }

    const hoursBeforeMeetingTypes = [];
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.secondConfirmation.isConfirmationEmailEnabled"
      ) === true
    ) {
      hoursBeforeMeetingTypes.push("E-mail");
    }
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.secondConfirmation.isConfirmationSMSEnabled"
      ) === true
    ) {
      hoursBeforeMeetingTypes.push("SMS");
    }

    const directlyAfterAnnulationTypes = [];
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.cancellationMessage.isCancelledEmailEnabled"
      ) === true
    ) {
      directlyAfterAnnulationTypes.push("E-mail");
    }
    if (
      get(
        this.props.agendaItemCategory,
        "reminder.cancellationMessage.isCancelledSMSEnabled"
      ) === true
    ) {
      directlyAfterAnnulationTypes.push("SMS");
    }

    return {
      directlyAfterConfirmationTypes,
      daysBeforeMeetingTypes,
      hoursBeforeMeetingTypes,
      directlyAfterAnnulationTypes,
    };
  }

  private mapReminderTexts() {
    const { reminder } = this.props.agendaItemCategory;

    const emailConfirmationBody = get(
      reminder,
      "confirmationMessage.confirmedEmailText",
      ""
    );
    const emailConfirmationTitle = get(
      reminder,
      "confirmationMessage.confirmedEmailSubject",
      ""
    );
    const emailFirstReminderBody = get(
      reminder,
      "firstConfirmation.emailText",
      ""
    );
    const emailFirstReminderTitle = get(
      reminder,
      "firstConfirmation.emailSubject",
      ""
    );
    const emailSecondReminderBody = get(
      reminder,
      "secondConfirmation.emailText",
      ""
    );
    const emailSecondReminderTitle = get(
      reminder,
      "secondConfirmation.emailSubject",
      ""
    );
    const emailAnnulationBody = get(
      reminder,
      "cancellationMessage.cancelledEmailText",
      ""
    );
    const emailAnnulationTitle = get(
      reminder,
      "cancellationMessage.cancelledEmailSubject",
      ""
    );
    const smsConfirmationBody = get(
      reminder,
      "confirmationMessage.confirmedSMSText",
      ""
    );
    const smsFirstReminderBody = get(reminder, "firstConfirmation.smsText", "");
    const smsSecondReminderBody = get(
      reminder,
      "secondConfirmation.smsText",
      ""
    );
    const smsAnnulationBody = get(
      reminder,
      "cancellationMessage.cancelledSmsText",
      ""
    );

    return {
      emailConfirmationBody,
      emailFirstReminderTitle,
      emailConfirmationTitle,
      emailFirstReminderBody,
      emailSecondReminderTitle,
      emailSecondReminderBody,
      emailAnnulationTitle,
      emailAnnulationBody,
      smsConfirmationBody,
      smsFirstReminderBody,
      smsSecondReminderBody,
      smsAnnulationBody,
    };
  }

  private setEmailReminderType(emailReminderText: EmailReminderText) {
    this.setState({ emailReminderText });
  }

  private setSMSReminderType(smsReminderText: SMSReminderText) {
    this.setState({ smsReminderText });
  }

  private setCurrentSelection(
    type: SMSReminderText | EmailReminderText,
    start: number,
    end: number
  ) {
    this.selection = {
      type,
      start,
      end,
    };
  }

  private insertMergeField(
    type: EmailReminderText | SMSReminderText,
    insertValue: string
  ) {
    if (!this.selection) return;

    const { start, end } = this.selection;
    const values = this.form.getValues();
    const key = `${type}Body`;
    const value: string = values[key];

    if (value === undefined || !insertValue || type !== this.selection.type)
      return;

    const newValue = `${value.substring(
      0,
      start
    )}${insertValue}${value.substring(end, value.length)}`;

    this.form.update({
      [key]: newValue,
    });
  }
}
