import * as React from "react";
import { FC, useRef, useCallback, useState, useMemo, memo } from "react";
import CSSModules = require("react-css-modules");
import { StringUtil } from "@haywork/util";
import { RelationSnapShot } from "@haywork/api/kolibri";
import capitalize from "lodash-es/capitalize";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";
import RelationInfo from "@haywork/components/ui/relation-info";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  relation: RelationSnapShot;
  query: string;
};

export const RelationSelectOptionComponent: FC<Props> = memo(
  CSSModules(styles, {
    allowMultiple: true,
  })(({ relation, query }: Props) => {
    const {
      displayName,
      locality,
      streetNameAndNumber,
      mobileNumber,
      phoneNumber,
      email,
      secondEmailAddress,
      thirdEmailAddress,
    } = relation;

    const timeoutRef = useRef<any>(null);
    const contextMenuParentRef = useRef();
    const [hover, toggleHover] = useState(false);

    const renderAddress = useMemo(() => {
      if (!locality && !streetNameAndNumber) return null;

      const values = [streetNameAndNumber, locality];
      const value = values
        .reduce((state, value) => {
          if (!!value) {
            state.push(capitalize(value));
          }
          return state;
        }, [])
        .join(", ");

      return (
        <div className="section extra">
          <span className="fal fa-folder-open" />
          <span dangerouslySetInnerHTML={StringUtil.highlight(value, query)} />
        </div>
      );
    }, [locality, streetNameAndNumber]);

    const renderTelephone = useMemo(() => {
      if (!mobileNumber && !phoneNumber) return null;

      const matchPhone = StringUtil.matches(phoneNumber, query);
      const matchMobile = StringUtil.matches(mobileNumber, query);

      if (matchPhone === -1 && matchMobile === -1) return null;

      return (
        <React.Fragment>
          {matchPhone >= 0 && (
            <div className="section extra">
              <span className="fal fa-phone" />
              <span
                dangerouslySetInnerHTML={StringUtil.highlight(
                  phoneNumber,
                  query
                )}
              />
            </div>
          )}
          {matchMobile >= 0 && (
            <div className="section extra">
              <span className="fal fa-mobile" />
              <span
                dangerouslySetInnerHTML={StringUtil.highlight(
                  mobileNumber,
                  query
                )}
              />
            </div>
          )}
        </React.Fragment>
      );
    }, [mobileNumber, phoneNumber]);

    const renderEmailAddress =
      useMemo((): React.ReactElement<HTMLDivElement> => {
        if (!email) return null;

        const matchEmail = StringUtil.matches(email, query);
        const matchSecondEmailAddress = StringUtil.matches(
          secondEmailAddress || "",
          query
        );
        const matchThirdEmailAddress = StringUtil.matches(
          thirdEmailAddress || "",
          query
        );

        if (
          matchEmail === -1 &&
          matchSecondEmailAddress === -1 &&
          matchThirdEmailAddress === -1
        )
          return null;

        return (
          <React.Fragment>
            {matchEmail >= 0 && (
              <div className="section extra">
                <span className="fal fa-envelope" />
                <span
                  dangerouslySetInnerHTML={StringUtil.highlight(email, query)}
                />
              </div>
            )}
            {matchSecondEmailAddress >= 0 && (
              <div className="section extra">
                <span className="fal fa-envelope" />
                <span
                  dangerouslySetInnerHTML={StringUtil.highlight(
                    secondEmailAddress,
                    query
                  )}
                />
              </div>
            )}
            {matchThirdEmailAddress >= 0 && (
              <div className="section extra">
                <span className="fal fa-envelope" />
                <span
                  dangerouslySetInnerHTML={StringUtil.highlight(
                    thirdEmailAddress,
                    query
                  )}
                />
              </div>
            )}
          </React.Fragment>
        );
      }, [email, secondEmailAddress, thirdEmailAddress]);

    const mouseEnterCallback = useCallback(() => {
      if (!!timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      toggleHover(true);
    }, [toggleHover]);

    const mouseLeaveCallback = useCallback(() => {
      timeoutRef.current = setTimeout(() => {
        toggleHover(false);
      }, 50);
    }, [toggleHover]);

    return (
      <div className="relation__name">
        <div
          className="section"
          dangerouslySetInnerHTML={StringUtil.highlight(
            displayName || "",
            query
          )}
        />
        {renderAddress}
        {/* {renderTelephone}
      {renderEmailAddress} */}
        <FeatureSwitch feature="RELATION_INFO">
          {relation.hasOwnProperty("typeOfRelation") && (
            <div
              styleName="context-menu-trigger"
              onMouseEnter={mouseEnterCallback}
              onMouseLeave={mouseLeaveCallback}
              ref={contextMenuParentRef}
            >
              <Icon name="info-circle" color={Colors.Primary} size={14} />
            </div>
          )}
          <ContextMenu
            visible={hover}
            parent={contextMenuParentRef}
            width={500}
            position={ContextMenuPosition.TopRight}
          >
            <div
              styleName="context-menu"
              onMouseEnter={mouseEnterCallback}
              onMouseLeave={mouseLeaveCallback}
            >
              <RelationInfo item={relation} visible={hover} />
            </div>
          </ContextMenu>
        </FeatureSwitch>
        {!relation.isActive && (
          <div className="query-component__archived-label">
            <I18n value="archived" />
          </div>
        )}
      </div>
    );
  })
);
