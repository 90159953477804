import { CompanyListing } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { CompanyListingsItemComponent } from "./list-item.component";

const styles = require("./company-listings.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface AssignmentWidgetCompanyListingsComponentProps {
  companyListings: CompanyListing[];
  assignmentId: string;
  onClick: (uri: string) => void;
}
interface AssignmentWidgetCompanyListingsComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentWidgetCompanyListingsComponent extends React.Component<
  AssignmentWidgetCompanyListingsComponentProps,
  AssignmentWidgetCompanyListingsComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    if (!this.props.companyListings || this.props.companyListings.length === 0)
      return null;

    return (
      <div styleName="widget company-listings">
        <h2 styleName="title">
          <ResourceText resourceKey="companyListings" />
        </h2>
        {this.props.companyListings.map((companyListing, idx) => (
          <ErrorBoundary key={idx}>
            <CompanyListingsItemComponent
              companyListing={companyListing}
              idx={idx}
              onClick={this.onClickHandler}
            />
          </ErrorBoundary>
        ))}
      </div>
    );
  }

  private onClickHandler(id: string) {
    const uri = route(
      ASSIGNMENTROUTES.EDIT_ASSIGNMENT.URI,
      { id: this.props.assignmentId },
      "company-listings"
    );

    this.props.onClick(uri);
  }
}
