import { REDUX } from "@haywork/constants";

import * as ActionType from "./errors.types";

const setErrors = (payload: ActionType.Errors) => ({
  type: REDUX.FORM.SET_ERRORS,
  ...payload
});

const resolveError = (payload: ActionType.Error) => ({
  type: REDUX.FORM.RESOLVE_ERROR,
  ...payload
});

const removeErrors = (payload: ActionType.Path) => ({
  type: REDUX.FORM.REMOVE_ERRORS,
  ...payload
});

export const FormErrorsActions = {
  setErrors,
  resolveError,
  removeErrors
};
