import { intlContext } from "@haywork/app";
import { EditableHocProps } from "@haywork/modules/editable";
import { NotesList } from "@haywork/modules/notes-v3";
import * as React from "react";
import { FC, memo, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import { injectIntl, WithIntlProps } from "react-intl";
import { AcquisitionAssignmentDetailScreenContainerProps } from "./assignments-detail.container";
import { AcquisitionAssignmentDetailRouting } from "./detail.routing";

const styles = require("./style.scss");

export type AcquisitionAssignmentDetailScreenProps = {};
type Props = AcquisitionAssignmentDetailScreenProps &
  AcquisitionAssignmentDetailScreenContainerProps &
  EditableHocProps;

export const AcquistionAssignmentDetailScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionObjectAssignment, setTabTitle }) => {
      const { id, isNew, displayName } = acquisitionObjectAssignment;

      useEffect(() => {
        setTabTitle(
          displayName ||
            intlContext.formatMessage({
              id: "acquisition.assignment.emptyTabTitle",
              defaultMessage: "acquisition.assignment.emptyTabTitle",
            })
        );
      }, [displayName]);

      return (
        <div styleName="detail">
          <AcquisitionAssignmentDetailRouting id={id} />
          {!isNew && <NotesList parentId={id} />}
        </div>
      );
    }
  )
);
