import {
  SearchAssignmentLocation,
  SearchForLocationType,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { AddressUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./edit-where.component.scss");

interface Props {
  highestSearchLocationId: number;
  visible: boolean;
  onChange: (locations: SearchAssignmentLocation[]) => void;
  onClose: () => void;
}
interface State {
  error: string;
}

@CSSModules(styles, { allowMultiple: true })
export class PostalCodeRangeComponent extends React.PureComponent<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.formControls = {
      postalCodeStart: {
        value: "",
      },
      postalCodeEnd: {
        value: "",
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public render() {
    return (
      <div
        styleName={classNames("postalcode-range", {
          visible: this.props.visible,
        })}
      >
        <h1>
          <ResourceText
            resourceKey="searchAssignment.addPostalCodeRangeTitle"
            asHtml={true}
          />
        </h1>
        <Form
          name=""
          asSubForm={true}
          formControls={this.formControls}
          onSubmit={this.onSubmit}
          form={(form) => (this.form = form)}
        >
          <div className="form__row">
            <div className="form__group">
              <div className="column__textspacer first">
                <ResourceText resourceKey="from" />
              </div>
              <div className="column" styleName="postalcode-range__input">
                <Input.Number
                  name="postalCodeStart"
                  max={9999}
                  placeholder="searchAssignmentPlaceholder.postalCode"
                />
              </div>
              <div className="column__textspacer">
                <ResourceText resourceKey="untill" />
              </div>
              <div className="column" styleName="postalcode-range__input">
                <Input.Number
                  name="postalCodeEnd"
                  max={9999}
                  placeholder="searchAssignmentPlaceholder.postalCode"
                />
              </div>
              <div className="column__spacer" />
              <div className="column" styleName="postalcode-range__actions">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.form.submit()}
                >
                  <ResourceText resourceKey="add" />
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.onClose}
                >
                  <ResourceText resourceKey="cancel" />
                </button>
              </div>
            </div>
          </div>
        </Form>

        {!!this.state.error && (
          <div styleName="postalcode-range__error">
            <i className="far fa-exclamation-triangle" />
            <ResourceText resourceKey={this.state.error} />
          </div>
        )}
      </div>
    );
  }

  private onSubmit(values: FormReturnValue) {
    if (values.postalCodeStart.length < 4 || values.postalCodeEnd.length < 4) {
      this.setState({
        error: "searchAssignment.addPostalCodeRangeError.invalid",
      });
      return;
    }

    const from = parseInt(values.postalCodeStart);
    const untill = parseInt(values.postalCodeEnd);
    const diff = Math.abs(untill - from);

    if (diff > 10) {
      this.setState({
        error: "searchAssignment.addPostalCodeRangeError.rangeToLarge",
      });
      return;
    }

    const postalCodes = Array(diff + 1)
      .fill(null)
      .map((_, idx) => (from < untill ? from + idx : untill + idx))
      .map((postalCode) => postalCode.toString());

    const searchLocations: SearchAssignmentLocation[] = postalCodes.map(
      (postalCode, idx) =>
        AddressUtil.createTranslatedSearchAssignmentLocation({
          id: this.props.highestSearchLocationId + idx + 1,
          searchForLocationType: SearchForLocationType.PostalCode,
          postalCode,
          countryIso2: "NL",
        })
    );

    this.props.onChange(searchLocations);
    this.setState({ error: null });
    this.form.update({
      postalCodeStart: "",
      postalCodeEnd: "",
    });
  }

  private onClose() {
    this.props.onClose();
    this.setState({ error: null });
    this.form.update({
      postalCodeStart: "",
      postalCodeEnd: "",
    });
  }
}
