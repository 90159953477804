import { KeysThunk, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { KeysComponent, KeysComponentProps } from "./keys";

type StateProps = {};
type DispatchProps = {
  getFirstAvailableKey: () => Promise<number>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  KeysComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  KeysComponentProps
> = (dispatch) => ({
  getFirstAvailableKey: () => dispatch(KeysThunk.getFirstAvailableKey())
});

export type KeysContainerProps = StateProps & DispatchProps;
export const KeysContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeysComponent);
