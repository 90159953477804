import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { Mutation, MutationType } from "@haywork/api/mls";
import { ListChildComponentProps } from "react-window";
import classNames from "classnames";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import { FormattedDate } from "react-intl";

const styles = require("./style.scss");

type RowComponentProps = {
  mutation: Mutation;
  forRentOrSale: "sale" | "rent";
};
type Props = RowComponentProps & ListChildComponentProps;

export const RowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ mutation, forRentOrSale, ...props }) => {
      const { type, mutationDateTime, newPrice, oldPrice } = mutation;
      const icon = useMemo(() => {
        switch (type) {
          case MutationType.Available: {
            return <Icon name="sign" regular color={Colors.Gray} size={18} />;
          }
          case MutationType.DeedSigned: {
            return (
              <Icon
                name="file-signature"
                regular
                color={Colors.Gray}
                size={18}
              />
            );
          }
          case MutationType.New: {
            return (
              <Icon name="sparkles" regular color={Colors.Gray} size={18} />
            );
          }
          case MutationType.PriceChange: {
            return (
              <Icon name="euro-sign" regular color={Colors.Gray} size={18} />
            );
          }
          case MutationType.Transferred: {
            return (
              <Icon name="handshake" regular color={Colors.Gray} size={18} />
            );
          }
          case MutationType.UnderBid: {
            return (
              <Icon
                name="hand-holding-usd"
                regular
                color={Colors.Gray}
                size={18}
              />
            );
          }
          case MutationType.UnderCondition: {
            return <Icon name="times" regular color={Colors.Gray} size={18} />;
          }
          case MutationType.UnderOption: {
            return <Icon name="times" regular color={Colors.Gray} size={18} />;
          }
          case MutationType.Withdrawn: {
            return (
              <Icon name="backspace" regular color={Colors.Gray} size={18} />
            );
          }
          default: {
            return (
              <Icon name="calendar" regular color={Colors.Gray} size={18} />
            );
          }
        }
      }, [type]);

      const description = useMemo(() => {
        switch (type) {
          case MutationType.PriceChange: {
            const old = oldPrice || 0;
            const current = newPrice || 0;

            return (
              <I18n
                value={`mls.timeline.${type.toString()}`}
                values={{ old, current }}
                asHtml
              />
            );
          }
          case MutationType.DeedSigned:
          case MutationType.UnderCondition: {
            return (
              <I18n
                value={`mls.timeline.${type.toString()}`}
                values={{ type: forRentOrSale }}
                asHtml
              />
            );
          }
          default: {
            return <I18n value={`mls.timeline.${type.toString()}`} />;
          }
        }
      }, [type, oldPrice, newPrice, forRentOrSale]);

      return (
        <div
          {...props}
          styleName={classNames("row", { zebra: props.index % 2 !== 0 })}
        >
          <div styleName="icon">{icon}</div>
          <div styleName="description">{description}</div>
          <div styleName="date">
            <FormattedDate
              value={mutationDateTime}
              day="2-digit"
              month="long"
              year="numeric"
            />
          </div>
        </div>
      );
    }
  )
);
