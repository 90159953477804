import { SearchAssignment } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { SearchAssignmentMatchingPropertiesModalContainerProps } from "@haywork/modules/search-assignment";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SearchAssignmentMatchingPropertiesModalItemComponent } from "./list-item.component";

const styles = require("./matching-properties-modal.component.scss");

export type SearchAssignmentMatchingPropertiesModalComponentProps = {
  visible: boolean;
  objectAssignmentIds: string[];
  searchAssignment: SearchAssignment;
  dateTimeSent: Date;
  onClose: () => void;
  onNavigateToProperty: (url: string) => void;
};
type Props = SearchAssignmentMatchingPropertiesModalComponentProps &
  SearchAssignmentMatchingPropertiesModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentMatchingPropertiesModalComponent extends React.Component<
  Props
> {
  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onNavigateToPropertyHandler = this.onNavigateToPropertyHandler.bind(
      this
    );
  }

  public render() {
    const modalMatchingProperties = this.props.modalMatchingProperties || [];

    return (
      <Modal visible={this.props.visible} onClose={this.onCloseHandler}>
        <ModalHeader title={this.renderModalTitle()} close />
        <ModalBody noPadding>
          <div styleName="matching-properties">
            {modalMatchingProperties.map((matchingProperty, idx) => (
              <ErrorBoundary key={idx}>
                <SearchAssignmentMatchingPropertiesModalItemComponent
                  matchingProperty={matchingProperty}
                  zebra={idx % 2 === 0}
                  onNavigateToProperty={this.onNavigateToPropertyHandler}
                />
              </ErrorBoundary>
            ))}
            {this.props.modalMatchingPropertiesState === REQUEST.PENDING && (
              <PageLoader loading fullscreen />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-primary" onClick={this.onCloseHandler}>
            <ResourceText resourceKey="close" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private onCloseHandler() {
    this.props.onClose();

    // Clear properties after modal is out view
    setTimeout(() => this.props.clearModalMatchingProperties(), 500);
  }

  private onNavigateToPropertyHandler(url: string) {
    this.props.onClose();
    this.props.onNavigateToProperty(url);
  }

  private renderModalTitle(): string {
    const date = intlContext.formatDate(this.props.dateTimeSent || new Date(), {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return intlContext.formatMessage(
      {
        id: "matchingPropertiesModalTitle",
        defaultMessage: "matchingPropertiesModalTitle",
      },
      { date }
    );
  }
}
