import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  OfficeAssignmentContainerProps,
  OfficeAssignmentListItemComponent,
} from "@haywork/modules/settings";
import {
  InfiniteScroll,
  ListLoader,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

export interface OfficeAssignmentComponentProps {}
interface State {}
type Props = OfficeAssignmentComponentProps & OfficeAssignmentContainerProps;

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./office-assignment.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class OfficeAssignmentComponent extends React.Component<Props, State> {
  public componentDidMount() {
    this.props.getOfficeAssignments(this.props.selectedOffice.id, true);
  }

  public componentWillUnmount() {
    this.props.clearAssignmentList();
  }

  public render() {
    const assignments = this.props.officeAssignments;
    const relationName = this.props.selectedOfficeDisplayName;
    return (
      <div styleName="assignments">
        <PageHeader title="assignments" subTitle={relationName} />

        <div styleName="body">
          {this.props.officeAssignmentInitialState === REQUEST.PENDING && (
            <PageLoader loading fullscreen />
          )}
          {assignments && assignments.length > 0 ? (
            this.renderAssignments()
          ) : (
            <div styleName="emptyState">
              <div styleName="content">
                <div styleName="icon" />
                <div styleName="text">
                  <h2>
                    <ResourceText resourceKey="emptyStateNoAssignmentsTitleOffice" />
                  </h2>
                  <p>
                    <ResourceText resourceKey="emptyStateNoAssignmentsTextOffice" />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  public renderAssignments() {
    return (
      <div styleName="list">
        <InfiniteScroll scrollEnd={() => this.onScrollEndHandler()}>
          {this.props.officeAssignments.map((assignment, idx) => (
            <ErrorBoundary key={idx}>
              <OfficeAssignmentListItemComponent
                assignment={assignment}
                officeId={this.props.selectedOffice.id}
                zebra={idx % 2 === 0}
                onAssignmentClick={(assignment) =>
                  this.props.goToAssignmentDetail(assignment)
                }
              />
            </ErrorBoundary>
          ))}
          {this.props.canLoadMore && <ListLoader />}
        </InfiniteScroll>
        ;
      </div>
    );
  }

  private onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.officeAssignmentState === REQUEST.PENDING
    )
      return;
    this.props.getOfficeAssignments(this.props.selectedOffice.id, false);
  }
}
