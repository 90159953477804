import { ProviderOrderBy, ProvidersClient, SortOrder } from "@haywork/api/mail";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { Dispatch } from "../";
import { EmailActionsV2 } from "@haywork/stores/email-v2";

const parseRequest = new ParseRequest();

const getProviders = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { emailHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new ProvidersClient(emailHost);

      const providers = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 100,
              orderBy: ProviderOrderBy.DisplayName,
              order: SortOrder.Ascending,
            },
            realEstateAgencyId
          )
          .then((response) => response.results || [])
      );

      dispatch(EmailActionsV2.Providers.setProviders(providers));

      return providers;
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getProviders,
};
