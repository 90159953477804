import { Dispatch } from "@haywork/middleware";
import {
  RealEstateAgencyDetailDossierComponent,
  RealEstateAgencyDetailDossierComponentProps
} from "@haywork/modules/real-estate-agency";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgencyId?: string;
  name?: string;
  linkedFolderTreeId: string | null | undefined;
}
interface DispatchProps {}

const mapStateToProps = <
  StateProps,
  RealEstateAgencyDetailDossierComponentProps
>(
  state: AppState
) => ({
  realEstateAgencyId: state.realEstateAgency.realEstateAgency.id,
  name: state.realEstateAgency.realEstateAgency.name,
  linkedFolderTreeId: get(
    state.realEstateAgency,
    "realEstateAgency.linkedFolderTree.id",
    ""
  )
});

const mapDispatchToProps = <
  DispatchProps,
  RealEstateAgencyDetailDossierComponentProps
>(
  dispatch: Dispatch<any>
) => ({});

export type RealEstateAgencyDetailDossierContainerProps = StateProps &
  DispatchProps;
export const RealEstateAgencyDetailDossierContainer = connect<
  StateProps,
  DispatchProps,
  RealEstateAgencyDetailDossierComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RealEstateAgencyDetailDossierComponent);
