import {
  BogCombinationObjectSituationOption,
  BogCombinationObjectStatusOption,
  BogCombinationObjectTypeOption,
  CertificationOption,
  ComfortQualityOption,
  ConditionOption,
  ConstructionPeriodOption,
  EnergyClassOption,
  EnergySourceOption,
  FurnishingOption,
  HeatingMethodOption,
  HeatingWaterMethodOption,
  IsolationTypeOption,
  ObjectAssignment,
  OwnershipOption,
  ParkingFacilityOption,
  PeculiarityOption,
  ResidentialFacilityOption,
  RoofMaterialOption,
  RoofTypeOption,
  TypeOfBusinessLawOption,
  TypeOfFloorOption,
  TypeOfWindowFrameOption,
  PollutionTypeOption,
  ListingType,
} from "@haywork/api/kolibri";
import { VVEOption } from "@haywork/modules/assignment";
import { FormReturnValue } from "@haywork/modules/form";
import {
  FloorsAndSpaces,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import * as React from "react";
import {
  AssignmentEditContentEnergyAndCostsComponent,
  AssignmentEditContentFeaturesAndFiguresComponent,
  AssignmentEditContentParticularsComponent,
} from "./residential";
import AssignmentEditContentCombinationObject, {
  AssignmentEditContentSpaceGardensComponent,
  FloorLevel,
} from "./shared";

interface Props {
  objectAssignment: ObjectAssignment;
  constructionPeriods: ConstructionPeriodOption[];
  energyClassOptions: EnergyClassOption[];
  isolationTypeOptions: IsolationTypeOption[];
  heatingMethodOptions: HeatingMethodOption[];
  realEstateAgencyId: string;
  certificationOptions: CertificationOption[];
  comfortQualityOptions: ComfortQualityOption[];
  typeOfFloorOptions: TypeOfFloorOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  typeOfWindowFrameOptions: TypeOfWindowFrameOption[];
  conditionOptions: ConditionOption[];
  residentialFacilityOptions: ResidentialFacilityOption[];
  furnishingOptions: FurnishingOption[];
  energySourceOptions: EnergySourceOption[];
  ownershipOptions: OwnershipOption[];
  heatingWaterMethodOptions: HeatingWaterMethodOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  vveOptions: VVEOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  host: string;
  accessibilityOptions: PeculiarityOption[];
  bogCombinationObjectSituationOptions: BogCombinationObjectSituationOption[];
  bogCombinationObjectStatusOptions: BogCombinationObjectStatusOption[];
  bogCombinationObjectTypeOptions: BogCombinationObjectTypeOption[];
  occupancyOptions: PeculiarityOption[];
  distinguishablesOptions: PeculiarityOption[];
  pollutionOptions: PollutionTypeOption[];
  onChange: (values: FormReturnValue) => void;
  navigate: (url: string) => void;
}
interface State {}

export class EditContentResidentialComponent extends React.Component<
  Props,
  State
> {
  public render() {
    const {
      objectAssignment,
      constructionPeriods,
      energyClassOptions,
      isolationTypeOptions,
      heatingMethodOptions,
      certificationOptions,
      realEstateAgencyId,
      comfortQualityOptions,
      typeOfFloorOptions,
      roofTypeOptions,
      roofMaterialOptions,
      typeOfWindowFrameOptions,
      conditionOptions,
      residentialFacilityOptions,
      furnishingOptions,
      energySourceOptions,
      ownershipOptions,
      host,
      heatingWaterMethodOptions,
      parkingFacilityOptions,
      bogCombinationObjectSituationOptions,
      bogCombinationObjectStatusOptions,
      bogCombinationObjectTypeOptions,
      occupancyOptions,
      distinguishablesOptions,
      pollutionOptions,
    } = this.props;

    return (
      <StepperComponent initial={0} scrollToElementId="scroll-to-top">
        {!!objectAssignment.floors && !!objectAssignment.floors.length && (
          <StepComponent
            title="assignment.FloorsAndSpaces"
            data-cy={"CY-LivingLayersAndSpaces"}
          >
            <FloorLevel
              objectAssignment={objectAssignment}
              onChange={this.props.onChange}
            />
            <FloorsAndSpaces
              objectAssignment={objectAssignment}
              onChange={this.props.onChange}
              isNotRemovable={objectAssignment.floors.length === 1}
            />
          </StepComponent>
        )}
        {[ListingType.Parking].indexOf(
          this.props.objectAssignment.listingType
        ) === -1 && (
          <StepComponent
            title="assignmentOtherSpacesTab"
            data-cy={"CY-JardinsGarages"}
          >
            <AssignmentEditContentSpaceGardensComponent
              objectAssignment={objectAssignment}
              onChange={this.props.onChange}
            />
          </StepComponent>
        )}
        <StepComponent
          title="featuresAndFigures"
          data-cy={"CY-FeaturesAndFiguresStepComponent"}
        >
          <AssignmentEditContentFeaturesAndFiguresComponent
            objectAssignment={objectAssignment}
            constructionPeriods={constructionPeriods}
            onChange={this.props.onChange}
          />
        </StepComponent>
        <StepComponent title="energyAndCosts" data-cy={"CY-EnegeryAndCosts"}>
          <AssignmentEditContentEnergyAndCostsComponent
            objectAssignment={objectAssignment}
            energyClassOptions={energyClassOptions}
            isolationTypeOptions={isolationTypeOptions}
            heatingMethodOptions={heatingMethodOptions}
            energySourceOptions={energySourceOptions}
            ownershipOptions={ownershipOptions}
            heatingWaterMethodOptions={heatingWaterMethodOptions}
            onChange={this.props.onChange}
          />
        </StepComponent>
        <StepComponent title="particulars">
          <AssignmentEditContentParticularsComponent
            objectAssignment={objectAssignment}
            certificationOptions={certificationOptions}
            realEstateAgencyId={realEstateAgencyId}
            comfortQualityOptions={comfortQualityOptions}
            typeOfFloorOptions={typeOfFloorOptions}
            roofTypeOptions={roofTypeOptions}
            roofMaterialOptions={roofMaterialOptions}
            typeOfWindowFrameOptions={typeOfWindowFrameOptions}
            conditionOptions={conditionOptions}
            residentialFacilityOptions={residentialFacilityOptions}
            furnishingOptions={furnishingOptions}
            parkingFacilityOptions={parkingFacilityOptions}
            vveOptions={this.props.vveOptions}
            typeOfBusinessLawOptions={this.props.typeOfBusinessLawOptions}
            accessibilityOptions={this.props.accessibilityOptions}
            occupancyOptions={occupancyOptions}
            distinguishablesOptions={distinguishablesOptions}
            host={host}
            pollutionOptions={pollutionOptions}
            onChange={this.props.onChange}
            navigate={this.props.navigate}
          />
        </StepComponent>
        <StepComponent title="combinationObject">
          <AssignmentEditContentCombinationObject
            objectAssignment={objectAssignment}
            bogCombinationObjectSituationOptions={
              bogCombinationObjectSituationOptions
            }
            bogCombinationObjectStatusOptions={
              bogCombinationObjectStatusOptions
            }
            bogCombinationObjectTypeOptions={bogCombinationObjectTypeOptions}
            onChange={this.props.onChange}
          />
        </StepComponent>
      </StepperComponent>
    );
  }
}
