import { ErrorBoundary } from "@haywork/modules/error-boundary";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { PermissionGroup } from ".";
import { PermissionGroupComponent } from "./permission-group.component";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
const styles = require("./consent-modal.component.scss");

type Props = {
  title: string;
  permissions: PermissionGroup[];
};
const PermissionsListComponent: React.FC<Props> = CSSModules(styles, {
  allowMultiple: true,
})(({ permissions, title }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const permissionsStyle = useMemo(() => {
    return classNames("category", {
      collapsed: !isCollapsed,
    });
  }, [isCollapsed]);

  const mappedPermissions = useMemo(() => {
    if (!permissions) return;
    return permissions.map((group) => (
      <ErrorBoundary key={group.permissionResource.id}>
        <PermissionGroupComponent group={group} numberOfPermissions={30} />
      </ErrorBoundary>
    ));
  }, [permissions]);

  return (
    <div styleName={permissionsStyle}>
      <div
        styleName="category__header"
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        {/* <div styleName="category-icon">
          <i className="fal fa-cog"></i>
        </div> */}
        <div styleName="name">{title}</div>
        <div styleName="toggle-expanded">
          <i className="fal fa-chevron-up" />
        </div>
      </div>
      <div styleName="permissions__list">{mappedPermissions}</div>
    </div>
  );
});

export default PermissionsListComponent;
