import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";

const styles = require("./button-loader.component.scss");

interface ButtonLoaderComponentProps {
  resourceKey: string;
  loading: boolean;
  icon?: string;
}
interface ButtonLoaderComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ButtonLoader extends React.Component<
  ButtonLoaderComponentProps,
  ButtonLoaderComponentState
> {
  public render() {
    const componentStyle = classNames("button-loader", {
      loading: this.props.loading,
    });

    return (
      <div styleName={componentStyle}>
        {this.props.icon && <i className={this.props.icon} />}
        <I18n value={this.props.resourceKey} asHtml />
        <div styleName="loader" />
      </div>
    );
  }
}
