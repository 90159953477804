import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./relation-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentWidgetRelationItemComponentProps {
  relation: LinkedRelation;
  onNavigateToRelation: (url: string) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetRelationItemComponent extends React.Component<
  SearchAssignmentWidgetRelationItemComponentProps
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.disablePropagation = this.disablePropagation.bind(this);
  }

  public render() {
    return (
      <div styleName="relation-item" onClick={this.onClickHandler}>
        <div styleName="relation-item__avatar">
          {this.renderAvatarOrLetter()}
        </div>
        <div styleName="relation-item__meta">
          <h2>{this.props.relation.displayName}</h2>
          {this.renderEmailAndPhoneNumber()}
        </div>
      </div>
    );
  }

  private onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    const { typeOfRelation, id } = this.props.relation;
    let url;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        break;
      case RelationType.ContactCompany:
        url = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        break;
      case RelationType.Employee:
        url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
        break;
      case RelationType.Office:
        url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
        break;
      default:
        return;
    }

    this.props.onNavigateToRelation(url);
  }

  private renderAvatarOrLetter(): React.ReactElement<HTMLDivElement> {
    const { avatarUrl, letterAvatar } = this.props.relation;
    if (avatarUrl) {
      const style = { backgroundImage: `url(${JSON.stringify(avatarUrl)})` };
      return <div styleName="avatar" style={style} />;
    }

    return <div styleName="letters">{letterAvatar}</div>;
  }

  private renderEmailAndPhoneNumber(): React.ReactElement<HTMLDivElement> {
    const { relation } = this.props;
    const entries = ["phone", "phoneMobile", "phoneWork"];
    const phone = entries.reduce((state, entry) => {
      if (!state && !!relation[entry]) state = relation[entry];
      return state;
    }, null);

    if (!!relation.email && !!phone) {
      return (
        <div>
          <a href={`tel:${phone}`} onClick={this.disablePropagation}>
            {phone}
          </a>{" "}
          / <MailTo email={relation.email} />
        </div>
      );
    }

    if (!!relation.email) {
      return (
        <div>
          <MailTo email={relation.email} />
        </div>
      );
    }

    if (!!phone) {
      return (
        <div>
          <a href={`tel:${phone}`} onClick={this.disablePropagation}>
            {phone}
          </a>
        </div>
      );
    }

    return null;
  }

  private disablePropagation(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }
}
