import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import has from "lodash-es/has";
import { RentOffer } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { useIntl } from "react-intl";

const styles = require("./widgets.component.scss");

interface Props {
  rentOffer: RentOffer;
}

export const RentAvailability: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })((props) => {
    const intl = useIntl();

    if (
      !props.rentOffer ||
      (!has(props.rentOffer, "availableFrom") &&
        !has(props.rentOffer, "availableUntil") &&
        !has(props.rentOffer, "availabilityIsTemporary"))
    )
      return null;

    let resourceKey;
    switch (true) {
      case has(props.rentOffer, "availabilityIsTemporary") &&
        !props.rentOffer.availabilityIsTemporary:
        resourceKey = "rentOffer.availabilty.availabilityIsPermanent";
        break;
      case has(props.rentOffer, "availableFrom") &&
        has(props.rentOffer, "availableUntil"):
        resourceKey = "rentOffer.availabilty.fromUntill";
        break;
      case has(props.rentOffer, "availableFrom"):
        resourceKey = "rentOffer.availabilty.from";
        break;
      case has(props.rentOffer, "availableUntil"):
        resourceKey = "rentOffer.availabilty.untill";
        break;
      default:
        return null;
    }

    const from = props.rentOffer.availableFrom || new Date();
    const untill = props.rentOffer.availableUntil || new Date();

    return (
      <div>
        <ResourceText
          resourceKey={resourceKey}
          values={{
            from: intl.formatDate(from, {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }),
            untill: intl.formatDate(untill, {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }),
          }}
          asHtml={true}
        />
      </div>
    );
  })
);
