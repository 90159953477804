import { Event } from "@haywork/api/event-center";
import { LinkedAssignment } from "@haywork/api/kolibri";
import {
  EventCenterThunk,
  MapDispatchToProps,
  TaskThunks
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AssignmentsNeedAttentionComponent,
  AssignmentsNeedAttentionComponentProps
} from "./assignments-need-attention.component";
import { events } from "./selectors";

interface StateProps {
  events: Event[];
  status: string;
  showAllAssignmentsBool: boolean;
}
interface DispatchProps {
  getAssignmentsNeedAttentionEvents: (
    showAllAssignments: boolean
  ) => Promise<void>;
  navigate: (path: string) => void;
  eventArchive: (eventId: string) => Promise<void>;
  createTask: (assignment: LinkedAssignment, date?: Date) => Promise<void>;
  showAllAssignments: (showAllAssignments: boolean) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentsNeedAttentionComponentProps,
  AppState
> = (state) => {
  const { assignmentsNeedAttentionStatus } = state.eventCenter.dashboard;
  const showAllAssignmentsBool = state.account.showAllAssignments;

  return {
    events: events(state),
    status: assignmentsNeedAttentionStatus,
    showAllAssignmentsBool
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentsNeedAttentionComponentProps
> = (dispatch) => ({
  getAssignmentsNeedAttentionEvents: (showAllAssignments) =>
    dispatch(
      EventCenterThunk.getAssignmentsNeedAttentionEvents(showAllAssignments)
    ),
  navigate: (path: string) => dispatch(push(path)),
  eventArchive: (eventId: string) =>
    dispatch(EventCenterThunk.eventArchive(eventId)),
  createTask: (assignment: LinkedAssignment, date?: Date) =>
    dispatch(TaskThunks.createTaskFromEvent(assignment, date)),
  showAllAssignments: (showAllAssignments) => {
    return dispatch(
      EventCenterThunk.getAssignmentsNeedAttentionEvents(showAllAssignments)
    );
  }
});

export type AssignmentsNeedAttentionContainerProps = StateProps & DispatchProps;
export const AssignmentsNeedAttentionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentsNeedAttentionComponent);
