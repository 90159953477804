import { FinancialAdministration } from "@haywork/api/kolibri";
import { Dispatch, FinancialThunks } from "@haywork/middleware";
import { SettingsFinancialAdministrationProductsComponent } from "@haywork/modules/settings/modules";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  financialAdministrations: FinancialAdministration[];
  financialAdministrationState: string;
}
interface DispatchProps {
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) => void;
}

const mapStateToProps = <
  StateProps,
  SettingsFinancialAdministrationProductsComponentProps
>(
  state: AppState
) => {
  const { financialAdministrations, financialAdministrationState } =
    state.account;

  return {
    financialAdministrations,
    financialAdministrationState,
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SettingsFinancialAdministrationProductsComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) =>
    dispatch(
      FinancialThunks.updateFinancialAdministration(financialAdministration)
    ),
});

export type SettingsFinancialAdministrationProductsContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const SettingsFinancialAdministrationProductsContainer = withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsFinancialAdministrationProductsComponent)
);
