import { VideoBlob } from "@haywork/api/kolibri";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText, VideoPreviewComponent } from "@haywork/modules/shared";
import { VideoUtil } from "@haywork/util";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditMediaThreeSixtyScreenContainerProps } from "./assignments-edit-media-threesixty.container";
import { useIntl } from "react-intl";

const styles = require("./assignments-edit-media-threesixty.scss");

export type AcquistionAssignmentEditMediaThreeSixtyScreenProps = {};
type Props = AcquistionAssignmentEditMediaThreeSixtyScreenProps &
  AcquistionAssignmentEditMediaThreeSixtyScreenContainerProps;

export const AcquistionAssignmentEditMediaThreeSixtyScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ currentComponentState, updateAcquisitionAssignmentObject, path }) => {
      const intl = useIntl();

      let { videos } = currentComponentState;
      videos = videos || [];
      const placeholder = intl.formatMessage({
        id: "addVideoTitle",
        defaultMessage: "addVideoTitle",
      });
      let formRef: FormReference;
      let formControls: FormControls;

      formControls = {
        video: {
          value: "",
          validators: [Validators.required(), Validators.url()],
        },
      };

      const submitOnEnterHandler = () => {
        if (formRef) formRef.submit();
      };

      const onSubmitHandler = (values: FormReturnValue) => {
        const videoBlob = VideoUtil.createVideoBlob(values.video);
        const videoArray = currentComponentState.videos || [];
        const videos = [...videoArray, videoBlob];
        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          videos,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
        if (formRef) formRef.clear();
      };

      const onDeleteHandler = (idx: number) => {
        const videos = currentComponentState.videos.filter((v, k) => k !== idx);

        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          videos,
        };

        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      const onPreviewClickHandler = (video: VideoBlob) => {
        window.open(video.urlOriginal, "_blank");
      };

      const onVideoChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        idx: number
      ) => {
        const videos = currentComponentState.videos.map((video, key) => {
          if (idx === key) {
            return { ...video, name: event.target.value };
          }
          return video;
        });

        const updatedAcquisitionAssignmentObject = {
          ...currentComponentState,
          videos,
        };
        const newState = {
          ...currentComponentState,
          ...updatedAcquisitionAssignmentObject,
        };

        updateAcquisitionAssignmentObject(newState, path);
      };

      return (
        <div styleName="videos">
          <div className="container-fluid">
            <Form
              name="videos"
              formControls={formControls}
              onSubmit={onSubmitHandler}
              form={(ref) => (formRef = ref)}
            >
              {videos.length === 0 ? (
                <div className="container-fluid">
                  <div className="form__row">
                    <div className="column grow1">
                      <Input.Text
                        name="video"
                        placeholder="addVideoProjectPlaceholder"
                        submitOnEnter={submitOnEnterHandler}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="column as-input">
                      <button type="submit" className="btn btn-success">
                        <ResourceText resourceKey="add" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form__row">
                  <div className="form__group">
                    <div className="column grow1">
                      <Input.Text
                        name="video"
                        placeholder="addVideoProjectPlaceholder"
                        submitOnEnter={submitOnEnterHandler}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column as-input">
                      <button type="submit" className="btn btn-success">
                        <ResourceText resourceKey="add" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Form>
            {videos.map((video, idx) => (
              <ErrorBoundary key={idx}>
                <VideoPreviewComponent
                  urlOriginal={video.urlOriginal}
                  name={`video${idx}`}
                  id={`video${idx}`}
                  video={video}
                  value={video.name}
                  placeholder={placeholder}
                  onPreviewClick={() => onPreviewClickHandler(video)}
                  onDeleteClick={() => onDeleteHandler(idx)}
                  onChange={(event) => onVideoChangeHandler(event, idx)}
                />
              </ErrorBoundary>
            ))}
          </div>
        </div>
      );
    }
  )
);
