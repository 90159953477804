import { createSelector } from "reselect";
import sortBy from "lodash-es/sortBy";

import { AppState } from "@haywork/stores";
import { EventNotificationsFilter } from "@haywork/util";

const eventNotificationsSelector = (state: AppState) => state.eventCenter.notifications.notifications;
const currentFilterSelector = (state: AppState) => state.eventCenter.notifications.currentFilter;
const personalTotalCount = (state: AppState) => state.eventCenter.notifications.personalNotificationTotalCount;
const generalTotalCount = (state: AppState) => state.eventCenter.notifications.generalNotificationTotalCount;
const archivedTotalCount = (state: AppState) => state.eventCenter.notifications.archivedNotificationTotalCount;

export const notifications = createSelector(
  [eventNotificationsSelector, currentFilterSelector],
  (notifications, currentFilter) => {
    return sortBy(
      notifications.filter((notification) => notification.filter === currentFilter),
      "dateTimeNotify"
    ).reverse();
  }
);

export const canLoadMore = createSelector(
  [notifications, currentFilterSelector, personalTotalCount, generalTotalCount, archivedTotalCount],
  (notifications, currentFilter, personalCount, generalCount, archivedCount) => {
    switch (currentFilter) {
      case EventNotificationsFilter.Personal:
        return notifications.length < personalCount;
      case EventNotificationsFilter.General:
        return notifications.length < generalCount;
      case EventNotificationsFilter.Processed:
        return notifications.length < archivedCount;
      default:
        return false;
    }
  }
);
