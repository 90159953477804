import { createSelector } from "reselect";

import { AppState } from "@haywork/stores";
import {
  SaleCondition,
  ListingType,
  HouseCharacteristic,
  HouseSort,
  RealEstateGroup,
} from "@haywork/api/kolibri";

const saleConditionsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.saleConditions;

const listingTypesSelector = (state: AppState) =>
  state.main.mastertable.kolibri.listingTypes;

const houseCharacteristicsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.houseCharacteristics;

const buyerTypeSelector = (state: AppState) =>
  state.main.mastertable.kolibri.buyerTypeOptions;

export const saleConditions = createSelector(
  saleConditionsSelector,
  (saleConditions) =>
    saleConditions.filter(
      (condition) => condition.value !== SaleCondition.CostsSplit
    )
);

export const listingTypes = createSelector(
  listingTypesSelector,
  (listingTypes) =>
    listingTypes.filter(
      (type) =>
        [ListingType.House, ListingType.Apartment].indexOf(type.value) !== -1
    )
);

export const houseCharacteristics = (houseSort: HouseSort) =>
  createSelector(houseCharacteristicsSelector, (houseCharacteristics) =>
    houseCharacteristics.filter(
      (characteristic) =>
        characteristic.value !== HouseCharacteristic.SemiBungalow ||
        houseSort === HouseSort.Bungalow
    )
  );

export const buyerTypes = (realEstateGroup: RealEstateGroup) =>
  createSelector(buyerTypeSelector, (buyerTypes) => {
    if (
      !realEstateGroup ||
      !buyerTypes.length ||
      !buyerTypes[0].realEstateGroup
    )
      return buyerTypes;
    return buyerTypes.filter((buyerType) =>
      buyerType.realEstateGroup.includes(realEstateGroup)
    );
  });
