import { createContext, ReactNode } from "react";
import { ListColumnConfig } from ".";
import { SortOrder } from "@haywork/api/kolibri";
import noop from "lodash-es/noop";

export type ListContextProps = {
  name: string;
  rowHeight: number;
  columnConfig: ListColumnConfig;
  emptyState: ReactNode | null;
  sorting: {
    sortColumn: any;
    sortOrder: any;
  };
  hasError: boolean;
  onSortChangeCallback: (sortColumn: string, order: SortOrder) => void;
  onActionCallback: (item: any, action: any) => void;
  onScrollCallback: (scrollTop: number) => void;
  refresh: () => void;
};

export const ListContext = createContext<ListContextProps>({
  name: "",
  rowHeight: 0,
  columnConfig: { columns: [] },
  emptyState: null,
  sorting: {
    sortColumn: null,
    sortOrder: null
  },
  hasError: false,
  onSortChangeCallback: noop,
  onActionCallback: noop,
  onScrollCallback: noop,
  refresh: noop
});
export const ListContextConsumer = ListContext.Consumer;
export const ListContextProvider = ListContext.Provider;
