export const ASSIGNMENT = {
  VVE_OPTIONS: [
    { NameOfProperty: "isOwnersAssociationChecklistAvailable" },
    { NameOfProperty: "isOwnersAssociationRegisteredKvK" },
    { NameOfProperty: "hasOwnersAssociationYearlyMeeting" },
    { NameOfProperty: "hasOwnersAssociationMultipleYearsMaintenancePlan" },
    { NameOfProperty: "hasOwnersAssociationMaintenanceExpectations" },
    { NameOfProperty: "hasOwnersAssociationBuildingsInsurance" },
    { NameOfProperty: "hasOwnersAssociationPeriodicContribution" },
    { NameOfProperty: "hasOwnersAssociationReserveFund" }
  ]
};
