import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryTypeOption,
  AgendaStandardDurationOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { SCHEDULERCATEGORIESROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ColorUtil, FormControlUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { ColorResult, GithubPicker } from "react-color";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./scheduler-category-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const colorPickerColors = [
  "#808080",
  "#BDC3C7",
  "#A9A9A9",
  "#C0C0C0",
  "#800000",
  "#E74C3C",
  "#BA2700",
  "#D35400",
  "#8E692D",
  "#E9E03F",
  "#808000",
  "#68CC66",
  "#349933",
  "#27AE60",
  "#2ECC71",
  "#16A085",
  "#4FB3B9",
  "#1273DE",
  "#2980B9",
  "#2C3E50",
  "#68A6F6",
  "#000080",
  "#8E44AD",
  "#9B59B6",
];

interface SchedulerCategoryItemComponentProps {
  agendaItemCategory: AgendaItemCategorySnapShot;
  zebra: boolean;
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  shouldHaveFocus: boolean;
  agendaItemCategoryChanged: (
    agendaItemCategory: AgendaItemCategorySnapShot
  ) => void;
  deleteAgendaItemCategoryItem: (
    agendaItemCategory: AgendaItemCategorySnapShot
  ) => void;
  navigate: (path: string) => void;
}
interface SchedulerCategoryItemComponentState {
  showActions: boolean;
  displayColorPicker: boolean;
  categoryName: string;
  selectedColor: string;
  clickedInside: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SchedulerCategoryItemComponent extends React.Component<
  SchedulerCategoryItemComponentProps,
  SchedulerCategoryItemComponentState
> {
  private ref: HTMLDivElement;
  private input: HTMLInputElement;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.state = {
      showActions: false,
      displayColorPicker: false,
      categoryName: this.props.agendaItemCategory.displayName,
      selectedColor: this.props.agendaItemCategory.backColor
        ? ColorUtil.hexToRgb(this.props.agendaItemCategory.backColor)
        : null,
      clickedInside: false,
    };

    const { agendaItemCategory } = this.props;
    const standardDurationInMinutes =
      this.props.agendaStandardDurationOptions.find(
        (a) =>
          a.durationInMinutes === agendaItemCategory.standardDurationInMinutes
      );

    this.formControls = {
      displayName: { value: value(agendaItemCategory, "displayName") },
      standardDurationInMinutes: {
        value: value(standardDurationInMinutes, "value"),
      },
      needsToBeConfirmed: {
        value: value(agendaItemCategory, "needsToBeConfirmed"),
      },
      categoryType: { value: value(agendaItemCategory, "categoryType") },
    };

    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.colorChanged = this.colorChanged.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const innerStyle = classNames("inner", {
      zebra: this.props.zebra,
      "actions-visible": this.state.showActions,
      selected: this.state.displayColorPicker,
      important: this.state.clickedInside,
    });

    return (
      <div styleName="item" ref={(ref) => (this.ref = ref)}>
        <div styleName={innerStyle}>
          <Form
            name={"scheduler-category" + this.props.agendaItemCategory.id}
            onChange={this.onFormChangeHandler}
            formControls={this.formControls}
          >
            <div styleName="colorPicker">
              {/* Color picker start */}
              <div
                styleName="swatch"
                onClick={() => {
                  this.setState({
                    displayColorPicker: !this.state.displayColorPicker,
                  });
                }}
              >
                <div styleName="arrow">
                  <i className="fal fa-chevron-down" />
                </div>
                <div
                  styleName="color"
                  style={{ backgroundColor: this.state.selectedColor }}
                />
              </div>
              {this.state.displayColorPicker ? (
                <div styleName="popover">
                  <div
                    styleName="cover"
                    onClick={() => {
                      this.setState({ displayColorPicker: false });
                    }}
                  />
                  <GithubPicker
                    width="212px"
                    colors={colorPickerColors}
                    color={
                      this.props.agendaItemCategory.backColor
                        ? this.props.agendaItemCategory.backColor
                        : ""
                    }
                    onChange={this.colorChanged}
                  />
                </div>
              ) : null}
              {/* Color picker end */}
            </div>
            <div styleName="item__description">
              <Input.Text
                name="displayName"
                placeholder={intlContext.formatMessage({
                  id: "clickHereToAddDescription",
                })}
                inputRef={(ref) => (this.input = ref)}
              />
            </div>
            <div styleName="item__drop">
              <Input.NewSelect
                name="categoryType"
                values={this.props.agendaItemCategoryTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div styleName="item__drop">
              <Input.NewSelect
                name="standardDurationInMinutes"
                values={this.props.agendaStandardDurationOptions}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div styleName="item__statistics">
              <Input.Switch
                name="needsToBeConfirmed"
                label="needsToBeConfirmed"
                on={true}
                off={false}
              />
            </div>
            <div styleName="item__toggle" onClick={this.onToggleClickHandler}>
              <span className="fal fa-fw fa-ellipsis-v" />
            </div>
          </Form>
        </div>
        <div styleName="item__actions">
          <div className="btn btn-success" onClick={this.onEditClickHandler}>
            <span className="fal fa-edit" />
          </div>
          <div className="btn btn-danger" onClick={this.onDeleteClickHandler}>
            <span className="fal fa-times" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SchedulerCategoryItemComponentProps
  ) {
    if (!this.props.shouldHaveFocus && nextProps.shouldHaveFocus) {
      this.input.focus();
    }
  }

  private onFormChangeHandler(values: FormReturnValue) {
    const standardDurationOption =
      this.props.agendaStandardDurationOptions.find(
        (a) => a.value === values.standardDurationInMinutes
      );

    const agendaItemCategorySnapShot: AgendaItemCategorySnapShot = {
      ...this.props.agendaItemCategory,
      displayName: values.displayName,
      standardDurationInMinutes: standardDurationOption
        ? standardDurationOption.durationInMinutes
        : null,
      useReminder: values.useReminder,
      categoryType: values.categoryType,
      needsToBeConfirmed: values.needsToBeConfirmed,
    };

    const trimmedName = this.state.categoryName.trim();

    if (trimmedName) {
      this.props.agendaItemCategoryChanged(agendaItemCategorySnapShot);
    }
  }

  private colorChanged(color: ColorResult) {
    const newColor = ColorUtil.rgbToHex(color.hex);
    const agendaItemCategorySnapShot: AgendaItemCategorySnapShot = {
      ...this.props.agendaItemCategory,
      backColor: newColor,
    };
    this.setState({ selectedColor: color.hex, displayColorPicker: false });
    this.props.agendaItemCategoryChanged(agendaItemCategorySnapShot);
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      this.input.blur();
    }
  }

  private onToggleClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ showActions: !this.state.showActions });
  }

  private onDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.deleteAgendaItemCategoryItem(this.props.agendaItemCategory);
  }

  private onEditClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.navigate(
      route(SCHEDULERCATEGORIESROUTES.DETAIL.URI, {
        id: this.props.agendaItemCategory.id,
      })
    );
  }

  private onClickOutsideHandler(event: any) {
    const clickedInside = this.ref && this.ref.contains(event.target);
    if (clickedInside && !!this.state.showActions)
      this.setState({ showActions: false });

    this.setState({ clickedInside });
  }
}
