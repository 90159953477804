import { LinkedEmployee } from "@haywork/api/kolibri";
import { EMPLOYEEROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./linked-relation-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface LinkedEmployeeComponentProps {
  employee: LinkedEmployee;
  onClick: (uri: string) => void;
}
interface LinkedEmployeeComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class LinkedEmployeeComponent extends React.Component<
  LinkedEmployeeComponentProps,
  LinkedEmployeeComponentState
> {
  public constructor(props) {
    super(props);

    this.renderEmail = this.renderEmail.bind(this);
    this.renderPhone = this.renderPhone.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }
  public render() {
    const { employee } = this.props;
    const avatar = employee.avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(employee.avatarUrl)})` }
      : null;

    return (
      <div
        styleName="relation"
        onClick={this.onClickHandler}
        data-cy={this.props["data-cy"]}
      >
        <div styleName="avatar" style={avatar}>
          {!avatar && <div styleName="letter">{employee.letterAvatar}</div>}
        </div>
        <div styleName="info">
          <h3>{employee.displayName}</h3>
          <div styleName="contact-items">
            {this.renderPhone(employee)}
            {this.renderEmail(employee)}
          </div>
        </div>
      </div>
    );
  }

  private onClickHandler() {
    const { id } = this.props.employee;
    const uri = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
    this.props.onClick(uri);
  }

  private renderPhone(
    relation: LinkedEmployee
  ): React.ReactElement<HTMLDivElement> {
    const values = [
      relation.phoneWork,
      relation.phoneMobile,
      relation.phoneHome,
    ];
    const filtered = values.filter((val) => !!val);

    if (filtered.length === 0) return null;

    return (
      <React.Fragment>
        {filtered.map((phone, idx) => (
          <ErrorBoundary key={idx}>
            <a
              styleName="contact-item"
              href={`tel:${phone}`}
              onClick={this.stopPropagation}
            >
              {phone}
            </a>
          </ErrorBoundary>
        ))}
      </React.Fragment>
    );
  }

  private renderEmail(relation: LinkedEmployee) {
    if (!relation.email) return null;
    return (
      <span styleName="contact-item">
        <MailTo
          email={relation.email}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.MailToLink`
          }
        />
      </span>
    );
  }

  private stopPropagation(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }
}
