import Button from "@haywork/components/ui/button";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import { BidDossier } from "@haywork/util";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { BidAction } from "../../../..";
import BidItem from "../../../bid";

const styles = require("./style.scss");

type Props = {
  dossier: BidDossier;
  onClose: () => void;
  onNavigate: (path: string) => void;
  onAction: (id: string, action: BidAction) => void;
};

export const DossierComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ dossier, onClose, onNavigate, onAction }) => {
      if (!dossier) return null;
      const { bids, id } = dossier;

      return (
        <>
          <ModalBody noPadding>
            <div styleName="body">
              {bids.map((bid) => (
                <BidItem
                  key={bid.id}
                  bid={bid}
                  canDelete={bids.length === 1 || bid.id !== id}
                  onNavigate={onNavigate}
                  onAction={onAction}
                />
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button label="bidsModal.action.close" onClick={onClose} />
          </ModalFooter>
        </>
      );
    }
  )
);
