import { SortOrder } from "@haywork/api/kolibri";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { HeaderColumn } from "./header-column.component";

const styles = require("./table.component.scss");

export interface UiTableColumnConfig {
  title: string | { key: string; values: object };
  width: number;
  isAutoColumn?: boolean;
  orderBy?: any;
}

interface UiTableComponentProps {
  columnConfig: UiTableColumnConfig[];
  orderBy: any;
  order: SortOrder;
  defaultOrderBy: any;
  defaultOrder: SortOrder;
  pushActions?: boolean;
  blockChanges?: boolean;
  onScrollEnd?: () => void;
  onSortingChange?: (orderBy: any, order: SortOrder) => void;
}
interface UiTableComponentState {
  gridTemplateColumns: React.CSSProperties;
  width: React.CSSProperties;
  currentOrderBy: any;
  currentOrder: SortOrder;
  isIE11: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class UiTableComponent extends React.Component<
  UiTableComponentProps,
  UiTableComponentState
> {
  public static childContextTypes = {
    gridTemplateColumns: PropTypes.object,
    isIE11: PropTypes.bool,
  };

  private ref: HTMLDivElement;
  private scrollTop: number = 0;

  constructor(props) {
    super(props);

    this.state = {
      gridTemplateColumns: null,
      width: null,
      currentOrderBy: this.props.orderBy,
      currentOrder: this.props.order,
      isIE11: !!window["MSInputMethodContext"] && !!document["documentMode"],
    };

    this.onScrollHandler = debounce(this.onScrollHandler.bind(this), 100, {
      leading: true,
      maxWait: 500,
    });
    this.bindRef = this.bindRef.bind(this);
    this.onOrderHandler = this.onOrderHandler.bind(this);
  }

  public getChildContext() {
    return {
      gridTemplateColumns: this.state.gridTemplateColumns,
      isIE11: this.state.isIE11,
    };
  }

  public componentDidMount() {
    const columns = this.props.columnConfig || [];
    const width = { minWidth: this.props.pushActions ? 32 : 0 };
    const columnValues = columns.map((column) => {
      width.minWidth += column.width;
      return !column.isAutoColumn
        ? `${column.width}px`
        : `minmax(${column.width}px, 1fr)`;
    });

    const gridTemplateColumns = this.state.isIE11
      ? { display: "-ms-grid", msGridColumns: columnValues.join(" ") }
      : { display: "grid", gridTemplateColumns: columnValues.join(" ") };
    this.setState({ gridTemplateColumns, width });
  }

  public render() {
    return (
      <div
        styleName="table__wrapper"
        ref={this.bindRef}
        onScroll={this.onScrollHandler}
      >
        <div styleName="table" style={this.state.width}>
          <div
            styleName={classNames("table__header", {
              "push-actions": this.props.pushActions,
            })}
            style={this.state.gridTemplateColumns}
          >
            {this.props.columnConfig.map((column, idx) => (
              <HeaderColumn
                column={column}
                orderBy={this.props.orderBy}
                order={this.props.order}
                onOrder={this.onOrderHandler}
                key={idx}
                idx={idx}
                isIE11={this.state.isIE11}
              />
            ))}
          </div>
          <div styleName="table__list" data-cy="CY-tableParent">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  private onScrollHandler() {
    if (!!this.props.blockChanges) return;

    const { scrollHeight, scrollTop, clientHeight } = this.ref;
    const scrollDown = this.scrollTop < scrollTop;
    this.scrollTop = scrollTop;
    if (!this.ref || !this.props.onScrollEnd || !scrollDown) return;

    if (scrollHeight - (scrollTop + clientHeight) < 500) {
      this.props.onScrollEnd();
    }
  }

  private onOrderHandler(orderBy: any) {
    if (!!this.props.blockChanges) return;

    const { currentOrderBy, currentOrder } = this.state;
    const replaceState = {
      currentOrderBy,
      currentOrder,
    };

    if (orderBy !== currentOrderBy) {
      replaceState.currentOrderBy = orderBy;
      replaceState.currentOrder = SortOrder.Ascending;
    } else {
      switch (currentOrder) {
        case SortOrder.Ascending:
          replaceState.currentOrder = SortOrder.Descending;
          break;
        default:
          replaceState.currentOrderBy = this.props.defaultOrderBy;
          replaceState.currentOrder = this.props.defaultOrder;
          break;
      }
    }

    this.setState(replaceState, () => {
      const { currentOrderBy, currentOrder } = replaceState;
      if (!!this.props.onSortingChange)
        this.props.onSortingChange(currentOrderBy, currentOrder);
    });
  }

  private bindRef(ref: HTMLDivElement) {
    if (!ref || !!this.ref) return;
    this.ref = ref;
  }
}
