import classNames from "classnames";
import * as React from "react";
import first from "lodash-es/first";
import last from "lodash-es/last";

import { ResourceText } from "@haywork/modules/shared";
import { InputComponentProps } from "../input.component";

interface LinkedListValue {
  value: any;
  displayName: string;
  isSelected?: boolean;
  binding?: string;
}
export interface LinkedListObject {
  title: string;
  propName: string;
  values: LinkedListValue[];
}
export interface LinkedValue {
  propName: string;
  value: any;
}

interface LinkedListComponentProps {
  values: LinkedListObject[];
  blockFirstColumn?: boolean;
}
interface LinkedListComponentState {
  value: LinkedValue[];
  lists: LinkedListObject[];
}

export class LinkedListComponent extends React.Component<
  LinkedListComponentProps & InputComponentProps,
  LinkedListComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      lists: this.renderTree(this.props.value),
    };

    this.onOptionClickHandler = this.onOptionClickHandler.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
  }

  public render() {
    const multiListStyle = classNames("form__multilist", {
      disabled: this.props.blockFirstColumn,
    });

    return (
      <div className={multiListStyle}>
        {this.state.lists.map((list, idx) => this.renderList(list, idx))}
      </div>
    );
  }

  private renderList(
    list: LinkedListObject,
    parentIdx: number
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div key={parentIdx} className="list-column">
        <h3>
          <ResourceText resourceKey={list.title} />
        </h3>
        <div className="list">
          {list.values.map((value, idx) =>
            this.renderListItem(value, idx, list.propName, parentIdx)
          )}
        </div>
      </div>
    );
  }

  private renderListItem(
    item: LinkedListValue,
    idx: number,
    propName: string,
    parentIdx: number
  ) {
    const listItemStyle = classNames("list-item", { active: item.isSelected });

    return (
      <div
        key={idx}
        onClick={() =>
          this.onOptionClickHandler(propName, item.value, parentIdx)
        }
        className={listItemStyle}
        data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.ListItem`}
      >
        {item.displayName}
      </div>
    );
  }

  private renderTree(value: LinkedValue[]) {
    const objects = this.props.values;
    if (!value || value.length === 0) return [first(objects)];

    const count = value.length;
    let lists: LinkedListObject[] = value.map((value, idx) => {
      const object = this.props.values.find(
        (val) => val.propName === value.propName
      );
      if (object) {
        const values = object.values.map((v) => ({
          ...v,
          isSelected: v.value === value.value,
        }));
        return { ...object, values };
      }
    });

    const lastObject = last(lists);
    const lastObjectSelectedChildren = lastObject.values.filter(
      (value) => value.isSelected && value.binding
    );
    if (lastObjectSelectedChildren.length > 0) {
      const propName = first(lastObjectSelectedChildren).binding;
      const list = this.props.values.find(
        (value) => value.propName === propName
      );
      lists = [...lists, list];
    }

    return lists;
  }

  private onOptionClickHandler(
    propName: string,
    selectedValue: any,
    parentIdx: number
  ) {
    if (this.props.blockFirstColumn && parentIdx === 0) return;

    let { value } = this.props;
    const count = value.length;

    if (
      value &&
      value[parentIdx] &&
      value[parentIdx].value === selectedValue &&
      parentIdx !== 0
    ) {
      value = value.filter((v, idx) => idx < parentIdx);
      const lists = this.renderTree(value);
      this.setState({ value, lists });
      this.props.onChange(value);
      return;
    }

    if (parentIdx < count) {
      value = value.reduce((state, val, key) => {
        if (key === parentIdx) state.push({ ...val, value: selectedValue });
        if (key < parentIdx) state.push(val);
        return state;
      }, []);
    } else {
      value = [...value, { propName, value: selectedValue }];
    }

    const lists = this.renderTree(value);
    this.setState({ value, lists });
    this.props.onChange(value);
  }
}
