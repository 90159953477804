import {
  AirTreatmentOption,
  AmenitiesOption,
  BogCombinationObjectSituationOption,
  BogCombinationObjectStatusOption,
  BogCombinationObjectTypeOption,
  CertificationOption,
  ComfortQualityOption,
  CompanyListing,
  ConditionOption,
  ConstructionOptionOption,
  ConstructionPeriodOption,
  DrainageOption,
  EnergyClassOption,
  EnergySourceOption,
  FacilityOption,
  FurnishingOption,
  GarageTypeOption,
  GardenQualityOption,
  GardenTypeOption,
  GroundUsageOption,
  HeatingMethodOption,
  HeatingWaterMethodOption,
  HorseTroughLocationOption,
  HouseTypeOption,
  IndustrialBuildingFacilityOption,
  IsolationTypeOption,
  LeisureFacilityOption,
  LeisureType,
  ObjectAssignment,
  OrientationOption,
  OwnershipOption,
  ParkingFacilityOption,
  PavementTypeOption,
  PeculiarityOption,
  PollutionTypeOption,
  PoultryFarmingSubtypeOption,
  PoultryHousingTypeOption,
  RealEstateGroup,
  ResidentialBuildingTypeOption,
  ResidentialFacilityOption,
  RoofMaterialOption,
  RoofTypeOption,
  SituatedTypeOption,
  SocialPropertyFacilityOption,
  SocialPropertyType,
  SoilTypeOption,
  TypeOfBusinessLawOption,
  TypeOfCommunalAreaOption,
  TypeOfFloorOption,
  TypeOfWindowFrameOption,
  WelfareStateOption,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { ASSIGNMENT } from "@haywork/constants";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditContentComponent,
  AssignmentEditContentComponentProps,
} from "@haywork/modules/assignment";
import { AppState, SingleAssignmentState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  accessibilityOptions,
  distinguishablesOptions,
  occupancyOptions,
} from "./selectors";
import { energyClassOptions } from "@haywork/selector";

export interface VVEOption {
  value: string;
  displayName: string;
}

interface StateProps {
  objectAssignment: ObjectAssignment;
  companyListings: CompanyListing[];
  currentComponentState: SingleAssignmentState;
  realEstateAgencyId: string;
  constructionPeriods: ConstructionPeriodOption[];
  energyClassOptions: EnergyClassOption[];
  isolationTypeOptions: IsolationTypeOption[];
  heatingMethodOptions: HeatingMethodOption[];
  certificationOptions: CertificationOption[];
  comfortQualityOptions: ComfortQualityOption[];
  typeOfFloorOptions: TypeOfFloorOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  typeOfWindowFrameOptions: TypeOfWindowFrameOption[];
  conditionOptions: ConditionOption[];
  host: string;
  residentialFacilityOptions: ResidentialFacilityOption[];
  welfareStateOptions: WelfareStateOption[];
  airTreatmentOptions: AirTreatmentOption[];
  industrialBuildingFacilityOptions: IndustrialBuildingFacilityOption[];
  facilityOptions: FacilityOption[];
  constructionOptionOptions: ConstructionOptionOption[];
  leisureFacilityTypeOptions: LeisureFacilityOption[];
  residentialBuildingTypeOptions: ResidentialBuildingTypeOption[];
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  typesOfCommunalArea: TypeOfCommunalAreaOption[];
  pavementTypeOptions: PavementTypeOption[];
  furnishingOptions: FurnishingOption[];
  groundUsageOptions: GroundUsageOption[];
  soilTypeOptions: SoilTypeOption[];
  drainageOptions: DrainageOption[];
  horseTroughLocationOptions: HorseTroughLocationOption[];
  houseTypes: HouseTypeOption[];
  situatedTypeOptions: SituatedTypeOption[];
  orientations: OrientationOption[];
  gardenQualityOptions: GardenQualityOption[];
  garageTypeOptions: GarageTypeOption[];
  amenitiesOptions: AmenitiesOption[];
  gardenTypeOptions: GardenTypeOption[];
  energySourceOptions: EnergySourceOption[];
  ownershipOptions: OwnershipOption[];
  heatingWaterMethodOptions: HeatingWaterMethodOption[];
  vveOptions: VVEOption[];
  typeOfBusinessLawOptions: TypeOfBusinessLawOption[];
  accessibilityOptions: PeculiarityOption[];
  bogCombinationObjectSituationOptions: BogCombinationObjectSituationOption[];
  bogCombinationObjectStatusOptions: BogCombinationObjectStatusOption[];
  bogCombinationObjectTypeOptions: BogCombinationObjectTypeOption[];
  poultryFarmingSubtypes: PoultryFarmingSubtypeOption[];
  poultryHousingTypeOptions: PoultryHousingTypeOption[];
  occupancyOptions: PeculiarityOption[];
  distinguishablesOptions: PeculiarityOption[];
  pollutionOptions: PollutionTypeOption[];
}

interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  removeCompanyListing: (id: string) => void;
  navigate: (route: string) => void;
}

const mapCertificationOptions = (
  certificationOptions: CertificationOption[],
  realEstateGroup: RealEstateGroup
): CertificationOption[] => {
  return certificationOptions.filter((option) => {
    return option.realEstateGroups.indexOf(realEstateGroup) !== -1;
  });
};

const mapSocialPropertyFacilityTypeOptions = (
  socialPropertyFacilityTypeOptions: SocialPropertyFacilityOption[],
  socialPropertyType: SocialPropertyType
): SocialPropertyFacilityOption[] => {
  return socialPropertyFacilityTypeOptions.filter((option) => {
    return option.socialPropertyTypes.indexOf(socialPropertyType) !== -1;
  });
};

const mapLeisureFacilityTypeOptions = (
  leisureFacilityTypeOptions: LeisureFacilityOption[],
  leisureType: LeisureType
): LeisureFacilityOption[] => {
  return leisureFacilityTypeOptions.filter((option) => {
    return option.leisureTypes.indexOf(leisureType) !== -1;
  });
};

const getVVEOptions = (): VVEOption[] => {
  return ASSIGNMENT.VVE_OPTIONS.map((option) => ({
    value: option.NameOfProperty,
    displayName: intlContext.formatMessage({
      id: option.NameOfProperty,
      defaultMessage: option.NameOfProperty,
    }),
    isSelected: false,
  }));
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditContentComponentProps,
  AppState
> = (state) => {
  const {
    constructionPeriods,
    isolationTypeOptions,
    heatingMethodOptions,
    certificationOptions,
    comfortQualityOptions,
    typeOfFloorOptions,
    roofTypeOptions,
    roofMaterialOptions,
    typeOfWindowFrameOptions,
    conditionOptions,
    residentialFacilityOptions,
    welfareStateOptions,
    airTreatmentOptions,
    industrialBuildingFacilityOptions,
    facilityOptions,
    constructionOptionOptions,
    leisureFacilityTypeOptions,
    residentialBuildingTypeOptions,
    socialPropertyFacilityTypeOptions,
    parkingFacilityOptions,
    typesOfCommunalArea,
    pavementTypeOptions,
    furnishingOptions,
    groundUsageOptions,
    soilTypeOptions,
    drainageOptions,
    horseTroughLocationOptions,
    houseTypes,
    situatedTypeOptions,
    orientations,
    gardenQualityOptions,
    garageTypeOptions,
    amenitiesOptions,
    gardenTypeOptions,
    energySourceOptions,
    ownershipOptions,
    heatingWaterMethodOptions,
    typeOfBusinessLawOptions,
    bogCombinationObjectSituationOptions,
    bogCombinationObjectStatusOptions,
    bogCombinationObjectTypeOptions,
    poultryFarmingSubtypes,
    poultryHousingTypeOptions,
    polutionTypeOptions,
  } = state.main.mastertable.kolibri;
  const { currentComponentState } = state.editable;
  const {
    objectAssignment,
    companyListings,
  }: SingleAssignmentState = currentComponentState;

  const vveOptions = getVVEOptions();

  return {
    objectAssignment,
    companyListings,
    currentComponentState,
    realEstateAgencyId: state.account.currentRealestateAgency.id,
    constructionPeriods,
    energyClassOptions: energyClassOptions(state),
    isolationTypeOptions,
    heatingMethodOptions,
    certificationOptions: mapCertificationOptions(
      certificationOptions,
      objectAssignment.realEstateGroup
    ),
    comfortQualityOptions,
    typeOfFloorOptions,
    roofTypeOptions,
    roofMaterialOptions,
    typeOfWindowFrameOptions,
    conditionOptions,
    host: state.appSettings.host,
    residentialFacilityOptions,
    welfareStateOptions,
    airTreatmentOptions,
    industrialBuildingFacilityOptions,
    facilityOptions,
    constructionOptionOptions,
    leisureFacilityTypeOptions: mapLeisureFacilityTypeOptions(
      leisureFacilityTypeOptions,
      objectAssignment.leisureType
    ),
    residentialBuildingTypeOptions,
    socialPropertyFacilityTypeOptions: mapSocialPropertyFacilityTypeOptions(
      socialPropertyFacilityTypeOptions,
      objectAssignment.socialPropertyType
    ),
    parkingFacilityOptions,
    typesOfCommunalArea,
    pavementTypeOptions,
    furnishingOptions,
    groundUsageOptions,
    soilTypeOptions,
    drainageOptions,
    horseTroughLocationOptions,
    houseTypes,
    situatedTypeOptions,
    orientations,
    gardenQualityOptions,
    garageTypeOptions,
    amenitiesOptions,
    gardenTypeOptions,
    energySourceOptions,
    ownershipOptions,
    heatingWaterMethodOptions,
    vveOptions,
    typeOfBusinessLawOptions,
    accessibilityOptions: accessibilityOptions(state),
    bogCombinationObjectSituationOptions,
    bogCombinationObjectStatusOptions,
    bogCombinationObjectTypeOptions,
    poultryFarmingSubtypes,
    poultryHousingTypeOptions,
    occupancyOptions: occupancyOptions(objectAssignment.forRent)(state),
    distinguishablesOptions: distinguishablesOptions(state),
    pollutionOptions: polutionTypeOptions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditContentComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
  removeCompanyListing: (id: string) =>
    dispatch(AssignmentThunks.removeCompanyListing(id)),
  navigate: (route: string) => dispatch(push(route)),
});

export type AssignmentEditContentContainerProps = StateProps & DispatchProps;
export const AssignmentEditContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditContentComponent);
