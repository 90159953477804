import { FolderCategory, Message, ProviderType } from "@haywork/api/mail";
import { EmailBucket } from "@haywork/enum";
import { EmailMessage } from "@haywork/stores/email-v2";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDragSource,
  DragSource,
  DragSourceCollector,
  DragSourceSpec,
} from "react-dnd";
import { FormattedDate } from "react-intl";
import { MessageEvents } from "./events.component";
import { NamesComponent } from "./names.component";

const styles = require("./list.component.scss");

interface Props {
  message: EmailMessage;
  currentMessageId: string;
  bucket: EmailBucket;
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
  folder: ExtendedEmailFolder;
  currentFolderIsTrash: boolean;
  providerType: ProviderType;
  folderCategory: FolderCategory;
  onClick: (message: Message, unread: boolean) => void;
  onToggleUnread: (message: EmailMessage) => void;
  onToggleBookmarked: (id: string, bookmarked: boolean) => void;
  onDeleteMessage: (message: EmailMessage) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class MessageItemComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onUnreadClickHandler = this.onUnreadClickHandler.bind(this);
    this.onBookmarkClickHandler = this.onBookmarkClickHandler.bind(this);
  }

  public render() {
    const { unread, subject, snippet, files, id } = this.props.message;
    const attachments = files || [];
    const itemStyle = classNames("item", {
      unread,
      selected: this.props.currentMessageId === id,
    });

    return this.props.connectDragSource(
      <div
        styleName={itemStyle}
        data-cy="CY-messageListItem"
        onClick={() => this.props.onClick(this.props.message, unread)}
      >
        <div styleName="item__unread" onClick={this.onUnreadClickHandler} />
        <div styleName="item__info">
          <div styleName="name">
            {!!this.props.folder &&
            this.props.folder.category === FolderCategory.Sent ? (
              <NamesComponent names={get(this.props.message, "to", [])} />
            ) : (
              <NamesComponent names={get(this.props.message, "from", [])} />
            )}
          </div>
          <div styleName="description">{subject}</div>
          <div styleName="excerpt">{snippet}</div>
        </div>
        <div styleName="item__meta">
          <div styleName="flags">
            <MessageEvents message={this.props.message} />
            {attachments.filter((attachment) => attachment.isAttachment)
              .length > 0 && (
              <div styleName="flag">
                <i className="fal fa-paperclip" />
              </div>
            )}
            {this.renderBookmarkFlag()}
          </div>
          <div styleName="date">{this.renderDate()}</div>
        </div>
        {!this.props.currentFolderIsTrash && (
          <div
            styleName="item__delete"
            onClick={() => this.props.onDeleteMessage(this.props.message)}
          >
            <i className="far fa-times" />
          </div>
        )}
      </div>
    );
  }

  private onUnreadClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.props.onToggleUnread(this.props.message);
  }

  private onBookmarkClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    const { bookmarked, id } = this.props.message;
    this.props.onToggleBookmarked(id, bookmarked);
  }

  private renderBookmarkFlag() {
    const { bookmarked } = this.props.message;
    const bookmarkStyle = classNames(!!bookmarked ? "fa" : "fal", "fa-flag");
    const flagStyle = classNames("flag dim", { active: bookmarked });

    return (
      <div styleName={flagStyle} onClick={this.onBookmarkClickHandler}>
        <i className={bookmarkStyle} />
      </div>
    );
  }

  private renderDate() {
    switch (this.props.bucket) {
      case EmailBucket.Today:
        return (
          <FormattedDate
            value={this.props.message.date || ""}
            hour="2-digit"
            minute="2-digit"
          />
        );
      case EmailBucket.Yesterday:
        return (
          <FormattedDate
            value={this.props.message.date || ""}
            weekday="short"
            hour="2-digit"
            minute="2-digit"
          />
        );
      case EmailBucket.LastWeek:
        return (
          <FormattedDate
            value={this.props.message.date || ""}
            weekday="short"
            day="2-digit"
            month="short"
          />
        );
      default:
        return (
          <FormattedDate
            value={this.props.message.date || ""}
            weekday="short"
            day="2-digit"
            month="short"
            year="2-digit"
          />
        );
    }
  }
}

const spec: DragSourceSpec<Props, any> = {
  beginDrag(props) {
    const { message } = props;
    return { message };
  },
  canDrag(props) {
    const { providerType, folderCategory } = props;
    return !(
      providerType === ProviderType.Gmail &&
      folderCategory === FolderCategory.Sent
    );
  },
};

const collect: DragSourceCollector<any, any> = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

export const MessageItem = DragSource(
  "EmailMessage",
  spec,
  collect
)(MessageItemComponent);
