import { connect, MapStateToProps } from "react-redux";
import { EntityComponent, EntityComponentProps } from "./entity";
import { AppState, PresentEmployee } from "@haywork/stores";
import { presentEmployee } from "../../selectors";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {
  employee: PresentEmployee;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  EntityComponentProps,
  AppState
> = (state) => {
  return {
    employee: presentEmployee(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EntityComponentProps
> = (dispatch) => ({});

export type EntityContainerProps = StateProps & DispatchProps;
export const EntityContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityComponent);
