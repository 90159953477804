import {
  MediaContractSnapShot,
  MediaPartnerCategory,
  MediaPartnerCategoryOption,
} from "@haywork/api/kolibri";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { SettingsMediaPartnersOverviewContainerProps } from "@haywork/modules/settings/modules/mediapartners";
import {
  ConfirmComponent,
  FontStyle,
  InfiniteScroll,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { MediaContractComponent } from "./media-contract.component";
import * as nl2br from "nl2br";

const styles = require("./overview.component.scss");

export interface SettingsMediaPartnersOverviewComponentProps {}
interface State {
  modalVisible: boolean;
  mediaPartnerName: string;
  mediaPartnerDescription: string;
  mediaPartnerWebsiteUrl: string;
  selectedMediaContract: MediaContractSnapShot;
  paidPartnerConfirmVisible: boolean;
  resetContractId: string;
}
type Props = SettingsMediaPartnersOverviewComponentProps &
  SettingsMediaPartnersOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsMediaPartnersOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      mediaPartnerName: "",
      mediaPartnerDescription: "",
      mediaPartnerWebsiteUrl: "",
      selectedMediaContract: null,
      paidPartnerConfirmVisible: false,
      resetContractId: null,
    };

    this.onCategoryFilterClickHandler =
      this.onCategoryFilterClickHandler.bind(this);
    this.onDeActivateMediaContractHandler =
      this.onDeActivateMediaContractHandler.bind(this);
    this.onPublicationTypeChangeHandler =
      this.onPublicationTypeChangeHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onShowInfoHandler = this.onShowInfoHandler.bind(this);
    this.renderFilterCategory = this.renderFilterCategory.bind(this);
    this.onMediaContractLinkClickHandler =
      this.onMediaContractLinkClickHandler.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onPaidPartnerCloseHandler = this.onPaidPartnerCloseHandler.bind(this);
    this.onPaidPartnerConfirmHandler =
      this.onPaidPartnerConfirmHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getMediaContracts(true);
  }

  public render() {
    const {
      mediaContracts,
      statuses,
      categories,
      isFavorite,
      status,
      refresh,
      mediaPartnerCategories,
      publishValues,
    } = this.props;
    const everythingStyle = classNames("filter", {
      active: statuses.length === 0 && categories.length === 0 && !isFavorite,
    });
    const activeStyle = classNames("filter", { active: statuses.length !== 0 });
    const favoriteStyle = classNames("filter", { active: isFavorite === true });

    return (
      <div styleName="overview">
        <PageHeader title="mediapartners" />

        <div styleName="overview__header">
          <div styleName={everythingStyle} onClick={this.props.resetFilters}>
            <ResourceText
              resourceKey="everything"
              fontStyle={FontStyle.UcFirst}
            />
          </div>
          <div styleName={activeStyle} onClick={this.props.toggleIsActive}>
            <ResourceText resourceKey="active" fontStyle={FontStyle.UcFirst} />
          </div>
          <div styleName={favoriteStyle} onClick={this.props.toggleIsFavorite}>
            <ResourceText
              resourceKey="favorite"
              fontStyle={FontStyle.UcFirst}
            />
          </div>
          {mediaPartnerCategories.map(this.renderFilterCategory)}
        </div>

        <div styleName="overview__list">
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {mediaContracts.map((mediaContract, idx) => (
              <ErrorBoundary key={mediaContract.id}>
                <MediaContractComponent
                  mediaContract={mediaContract}
                  publishValues={publishValues}
                  zebra={idx % 2 !== 0}
                  onShowInfo={this.onShowInfoHandler}
                  onActivateMediaContract={this.props.activateMediaContract}
                  onDeActivateMediaContract={
                    this.onDeActivateMediaContractHandler
                  }
                  onToggleFavorite={this.props.setFavorite}
                  onPublicationTypeChange={this.onPublicationTypeChangeHandler}
                  resetPublicationStateToManual={
                    this.state.resetContractId === mediaContract.id
                  }
                />
              </ErrorBoundary>
            ))}

            {status === REQUEST.SUCCESS && mediaContracts.length === 0 && (
              <Ui.EmptyState
                type={UiEmptyStateType.List}
                stickman={UiEmptyStateStickMan.NoAssignments}
                title="noMediaContractsTitle"
                subtitle="noMediaContractsBody"
              />
            )}

            {status === REQUEST.PENDING && (
              <PageLoader
                loading
                fullscreen={mediaContracts.length === 0 || !!refresh}
              />
            )}
          </InfiniteScroll>
        </div>

        <Modal visible={this.state.modalVisible} onClose={this.onCloseModal}>
          <ModalHeader title={this.state.mediaPartnerName} close />
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: nl2br(this.state.mediaPartnerDescription || ""),
              }}
            />
          </ModalBody>
          <ModalFooter>
            <div styleName="modal-footer">
              <div styleName="left">
                {!!this.state.mediaPartnerWebsiteUrl && (
                  <span
                    className="as-link"
                    onClick={this.onMediaContractLinkClickHandler}
                  >
                    <ResourceText resourceKey="goToWebsite" />
                  </span>
                )}
              </div>
              <div styleName="right">
                <button className="btn btn-primary" onClick={this.onCloseModal}>
                  <ResourceText resourceKey="okay" />
                </button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <ConfirmComponent
          visible={this.state.paidPartnerConfirmVisible}
          titleResourceKey="paidMediaContractTitle"
          bodyResourceKey="paidMediaContractBody"
          bodyValues={
            this.state.selectedMediaContract || { mediaPartnerName: "" }
          }
          onClose={this.onPaidPartnerCloseHandler}
          onConfirm={this.onPaidPartnerConfirmHandler}
        />
      </div>
    );
  }

  private renderFilterCategory(
    category: MediaPartnerCategoryOption,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { categories } = this.props;
    const active = categories.indexOf(category.value) !== -1;
    const filterStyle = classNames("filter", { active });

    return (
      <div
        styleName={filterStyle}
        onClick={() =>
          this.onCategoryFilterClickHandler(category.value, active)
        }
        key={idx}
      >
        {category.displayName}
      </div>
    );
  }

  private onScrollEndHandler() {
    if (!this.props.canLoadMore || this.props.status === REQUEST.PENDING)
      return;
    this.props.getMediaContracts();
  }

  private onCategoryFilterClickHandler(
    category: MediaPartnerCategory,
    isActive: boolean
  ) {
    let categories = [];
    if (isActive) {
      categories = this.props.categories.filter((c) => c !== category);
    } else {
      categories = [...this.props.categories, category];
    }

    this.props.setCategories(categories);
  }

  private onShowInfoHandler(mediaContract: MediaContractSnapShot) {
    this.setState({
      mediaPartnerName: mediaContract.mediaPartnerName,
      mediaPartnerDescription: mediaContract.mediaPartnerDescription,
      mediaPartnerWebsiteUrl: mediaContract.mediaPartnerWebsiteUrl,
      modalVisible: true,
    });
  }

  private onMediaContractLinkClickHandler() {
    this.onCloseModal();
    window.open(this.state.mediaPartnerWebsiteUrl, "_blank");
  }

  private onCloseModal() {
    this.setState({
      modalVisible: false,
    });
  }

  private onDeActivateMediaContractHandler(
    mediaContract: MediaContractSnapShot
  ) {
    this.props.deActivateMediaContract(mediaContract.id);
  }

  private onPublicationTypeChangeHandler(
    selectedMediaContract: MediaContractSnapShot,
    isAutoPublish: boolean
  ) {
    if (selectedMediaContract.isPaid && !!isAutoPublish) {
      this.setState({
        selectedMediaContract,
        paidPartnerConfirmVisible: true,
        resetContractId: null,
      });
    } else {
      this.props.setPublishType(selectedMediaContract.id, isAutoPublish);
    }
  }

  private onPaidPartnerCloseHandler() {
    const { id } = this.state.selectedMediaContract;

    this.setState({
      selectedMediaContract: null,
      paidPartnerConfirmVisible: false,
      resetContractId: id,
    });
  }

  private onPaidPartnerConfirmHandler() {
    this.props.setPublishType(this.state.selectedMediaContract.id, true);
    this.setState({
      selectedMediaContract: null,
      paidPartnerConfirmVisible: false,
    });
  }
}
