import { RootEntityType } from "@haywork/api/event-center";
import {
  AssignmentSnapShot,
  MixedFormOption,
  ProjectAssignment,
  RentOffer,
  SaleOffer,
  WithdrawReason,
  WithdrawReasonOption,
  PhotoBlob,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { ProjectDetailComponent } from "@haywork/modules/project";
import {
  AppState,
  EditableActions,
  LayoutActions,
  ProjectsActions,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import has from "lodash-es/has";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  project: ProjectAssignment;
  objectTypes: AssignmentSnapShot[];
  realEstateAgencyId: string;
  host: string;
  mixedForms: MixedFormOption[];
  saleOffer: SaleOffer;
  rentOffer: RentOffer;
  showStatusModal: boolean;
  statusState: string;
  statusError: string[];
  changeAvailabilityStatus: string;
  withdrawReasonOptions: WithdrawReasonOption[];
  withdrawState: string;
  showWithdrawModal: boolean;
  showSaveModal: boolean;
  saveAssignmentState: string;
  isInitial: boolean;
}

interface DispatchProps {
  updateProject: (componentState: ProjectAssignment, path: string) => void;
  navigate: (url: string) => void;
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
  toggleStatusModal: (status: boolean) => void;
  toggleWithdrawModal: (status: boolean) => void;
  toggleSaveModal: (status: boolean) => void;
  sellProjectAssignment: (projectId: string) => void;
  rentProjectAssignment: (projectId: string) => void;
  clearAvailabilityStatus: () => void;
  withdrawProjectAssignment: (
    assignmentId: string,
    withdrawReason: WithdrawReason,
    withdrawnDateTime: Date
  ) => void;
  saveAndCloseProject: () => void;
  onAddNewType: (projectId: string) => void;
}

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => {
  if (
    !state.project.single ||
    !has(state.editable, "currentComponentState.projectAssignment")
  )
    return {};

  const { currentComponentState } = state.editable;
  const { saveAssignmentState, isInitial } = currentComponentState;
  const { saleOffer, rentOffer } = currentComponentState.projectAssignment;
  const { mixedForms, withdrawReasonOptions } = state.main.mastertable.kolibri;
  const {
    showWithdrawModal,
    showStatusModal,
    changeAvailabilityStatus,
    withdrawState,
    showSaveModal,
  } = state.project.single;

  return {
    project: state.editable.currentComponentState.projectAssignment,
    realEstateAgencyId: state.account.currentRealestateAgency.id,
    host: state.appSettings.host,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: currentComponentState.projectAssignment.id,
    }),
    mixedForms,
    saleOffer,
    rentOffer,
    showStatusModal,
    withdrawReasonOptions,
    withdrawState,
    showWithdrawModal,
    showSaveModal,
    saveAssignmentState,
    changeAvailabilityStatus,
    isInitial,
    objectTypes: currentComponentState.objectTypes,
    currentPath: state.editable.currentPath,
  };
};

const mapDispatchToProps = <DispatchProps, ProjectComponentProps>(
  dispatch: Dispatch<any>
) => ({
  updateProject: (componentState: ProjectAssignment, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  navigate: (url: string) => dispatch(push(url)),
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(
      LayoutActions.showLightbox({
        lightboxSlides,
        lightboxCurrent,
      })
    ),
  toggleStatusModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleStatusModal(status)),
  toggleSaveModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleSaveModal(status)),
  toggleWithdrawModal: (status: boolean) =>
    dispatch(ProjectsActions.Single.toggleWithdrawModal(status)),
  sellProjectAssignment: (projectId: string) =>
    dispatch(ProjectsThunks.Projects.sellProjectAssignment(projectId)),
  rentProjectAssignment: (projectId: string) =>
    dispatch(ProjectsThunks.Projects.rentProjectAssignment(projectId)),
  clearAvailabilityStatus: () =>
    dispatch(ProjectsThunks.Projects.clearAvailabilityStatus()),
  withdrawProjectAssignment: (
    assignmentId: string,
    withdrawReason: WithdrawReason,
    withdrawnDateTime: Date
  ) =>
    dispatch(
      ProjectsThunks.Projects.withdrawProjectAssignment(
        assignmentId,
        withdrawReason,
        withdrawnDateTime
      )
    ),
  saveAndCloseProject: () =>
    dispatch(
      ProjectsThunks.Projects.saveProject(undefined, false, false, true)
    ),
  onAddNewType: (projectId: string) =>
    dispatch(ProjectsThunks.Types.newType(projectId)),
});

export type ProjectDetailContainerProps = StateProps &
  DispatchProps &
  EditableHocProps &
  RouteComponentProps<any>;
export const ProjectDetailContainer = editable(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ProjectDetailComponent)),
  {
    entityType: RootEntityType.ProjectAssignment,
    icon: "sitemap",
    thunk: ProjectsThunks.Projects.getProject,
    status: "project.single.projectAssignmentState",
    statePath: "project.single",
    action: ProjectsActions.Single.setProject,
    route: PROJECTROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveProjectConfirmTitle" },
      body: { key: "saveProjectConfirmBody" },
    },
  }
);
