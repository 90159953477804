import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";
import { EXTERNALROUTES } from "@haywork/constants";

const styles = require("./app-error.component.scss");

export const AppError = CSSModules(styles, { allowMultiple: true })(() => {
  return (
    <div styleName="error">
      <div styleName="error__content">
        <div styleName="error__icon" />
        <div styleName="error__text">
          <h1>
            <ResourceText resourceKey="appErrorTitle" />
          </h1>
          <ResourceText resourceKey="appErrorText" values={{ support: EXTERNALROUTES.SUPPORT.URI }} asHtml />
          <button className="btn btn-primary" onClick={() => location.reload()}>
            <ResourceText resourceKey="appErrorAction" />
          </button>
        </div>
      </div>
    </div>
  );
});
