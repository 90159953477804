import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./modal-footer.component.scss");

interface ModalFooterComponentProps {
  style?: string;
}
interface ModalFooterComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ModalFooter extends React.Component<
  ModalFooterComponentProps,
  ModalFooterComponentState
> {
  public render() {
    const footerStyle = classNames("footer", this.props.style);

    return <div styleName={footerStyle}>{this.props.children}</div>;
  }
}
