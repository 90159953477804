import { ObjectAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  BrochureThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  AssignmentEditMarketingBrochuresComponent,
  AssignmentEditMarketingBrochuresComponentProps
} from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  LayoutActions,
  SingleAssignmentState
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  path: string;
  brochureStatus: string;
  brochureLink: string;
  brochureId: number;
}
interface DispatchProps {
  saveAssignment: () => void;
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  createNewBrochure: (objectAssignmentId: string) => Promise<string>;
  editBrochure: (
    objectAssignmentId: string,
    brochureId: string
  ) => Promise<string>;
  openExternalUrl: (
    path: string,
    title: string | { key: string; values: object }
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingBrochuresComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;
  const { brochureStatus, brochureLink, brochureId } = state.assignment.single;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: currentComponentState.objectAssignment.id
    }),
    brochureStatus,
    brochureLink,
    brochureId
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingBrochuresComponentProps
> = (dispatch) => ({
  saveAssignment: () =>
    dispatch(
      AssignmentThunks.saveAssignment(undefined, false, false, false, true)
    ),
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    ),
  createNewBrochure: (objectAssignmentId: string) =>
    dispatch(BrochureThunks.createNewBrochure(objectAssignmentId)),
  editBrochure: (objectAssignmentId: string, brochureId: string) =>
    dispatch(BrochureThunks.editBrochure(objectAssignmentId, brochureId)),
  openExternalUrl: (
    path: string,
    title: string | { key: string; values: object }
  ) => dispatch(LayoutActions.openWindowFrame({ path, title }))
});

export type AssignmentEditMarketingBrochuresContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingBrochuresContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingBrochuresComponent);
