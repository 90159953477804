import classNames from "classnames";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as PropTypes from "prop-types";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import isEqual from "lodash-es/isEqual";

interface SelectInputComponentProps {
  matchKey?: string;
  returnKey?: string;
  compareKey?: string;
  selectedDisplay?: (value) => React.ReactElement<any>;
  placeHolderWhenEmpty?: () => React.ReactElement<any>;
  showResultsAtTop?: boolean;
}
interface SelectInputComponentState {
  expanded: boolean;
  activeKey: number;
  current: any;
  childCount: number;
}

export class SelectComponent extends React.PureComponent<
  SelectInputComponentProps & InputComponentProps,
  SelectInputComponentState
> {
  public static childContextTypes = {
    onOptionChange: PropTypes.func.isRequired,
    activeKey: PropTypes.number.isRequired,
    list: PropTypes.object,
  };

  private ref: HTMLDivElement;
  private button: HTMLButtonElement;
  private list: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      activeKey: 0,
      current: null,
      childCount: 0,
    };

    // Event bindings
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public componentDidMount() {
    const { activeKey, current } = this.getSelectedOption(this.props.value);
    this.setState({ activeKey, current });
  }

  public getChildContext() {
    return {
      onOptionChange: this.onOptionChange.bind(this),
      activeKey: this.state.activeKey,
      list: this.list,
    };
  }

  public render() {
    let value: any;

    if (!this.props.value && this.props.placeHolderWhenEmpty) {
      value = this.props.placeHolderWhenEmpty();
    } else if (this.props.selectedDisplay) {
      value = this.props.selectedDisplay(this.props.value);
    } else {
      value = this.state.current;
    }

    const selectInputClass = classNames("input__select", {
      expanded: this.state.expanded,
    });
    const selectOptionsClass = classNames("select__options", {
      "at-top": this.props.showResultsAtTop,
    });

    return (
      <div className={selectInputClass} ref={(ref) => (this.ref = ref)}>
        <button
          data-cy={"CY-select-" + this.props.name}
          type="button"
          onClick={this.onClickHandler}
          onKeyDown={this.onKeyDownHandler}
          onFocus={this.props.onFocus}
          id={this.props.name}
          ref={(ref) => (this.button = ref)}
          disabled={this.props.disabled}
        >
          {value}
          <i className="fal fa-fw fa-chevron-down faIcon" />
        </button>
        <div className={selectOptionsClass} ref={(ref) => (this.list = ref)}>
          {this.renderChildren()}
        </div>
      </div>
    );
  }

  public componentDidUpdate() {
    const childCount = React.Children.count(this.props.children);

    if (this.state.childCount !== childCount) {
      this.setSelectedOption(this.props.value);
      this.setState({
        childCount,
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SelectInputComponentProps & InputComponentProps
  ) {
    this.setSelectedOption(nextProps.value);
    if (
      !nextProps.value &&
      !isEqual(nextProps.value, this.props.value) &&
      !this.props.placeHolderWhenEmpty
    ) {
      this.setSelectedOptionByKey(0, true);
    }

    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }

    if (!!nextProps.focus && this.button) {
      this.button.focus();
      this.onClickHandler();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onOptionChange(value: any) {
    if (this.context.blocked || this.props.disabled) return;
    this.fireOnBlur(value);
    this.setSelectedOption(value);
    this.setState({ expanded: false });
    this.button.focus();
  }

  private onClickHandler() {
    if (this.context.blocked || this.props.disabled) return;
    this.setState({ expanded: !this.state.expanded });
  }

  private onKeyDownHandler(event: any) {
    if (this.context.blocked || this.props.disabled) return;
    if ([13, 27, 38, 40].indexOf(event.keyCode) !== -1) event.preventDefault();
    switch (event.keyCode) {
      case 40: {
        if (!this.state.expanded) return this.setState({ expanded: true });
        const activeKey =
          this.state.activeKey >= React.Children.count(this.props.children) - 1
            ? 0
            : this.state.activeKey + 1;
        this.setState({ activeKey });
        break;
      }
      case 38: {
        if (!this.state.expanded) return this.setState({ expanded: true });
        const activeKey =
          this.state.activeKey <= 0
            ? React.Children.count(this.props.children) - 1
            : this.state.activeKey - 1;
        this.setState({ activeKey });
        break;
      }
      case 13:
        this.setSelectedOptionByKey(this.state.activeKey);
        return;
      case 27:
        this.setState({ expanded: false });
        return;
      default:
        return;
    }
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref === undefined) return;
    if (!this.ref.contains(event.target) && !!this.state.expanded)
      this.setState({ expanded: false });
  }

  private renderChildren() {
    return React.Children.map(this.props.children, (child: any, idx) => {
      return React.cloneElement(child, {
        idx,
        "data-cy": "CY-select-" + this.props.name + "-option-" + idx,
      });
    });
  }

  private setSelectedOption(value: any) {
    const { current, activeKey } = this.getSelectedOption(value);
    this.setState({ current, activeKey });
  }

  private getSelectedOption(value: any) {
    const children = React.Children.toArray(this.props.children);
    const active = children.filter((child: any, idx) => {
      const matchOn = this.props.matchKey
        ? get(child.props.value, this.props.matchKey)
        : child.props.value;
      const compareOn = this.props.compareKey
        ? get(value, this.props.compareKey)
        : value;

      return matchOn === compareOn;
    });

    return {
      current: active.length > 0 ? active[0] : children[0],
      activeKey: active.length > 0 ? children.indexOf(active[0]) : 0,
    };
  }

  private setSelectedOptionByKey(key: number, quiet: boolean = false) {
    const children = React.Children.toArray(this.props.children);
    const current: any = children[key];
    if (!current) return false;
    this.setState({ current, expanded: false });
    if (!quiet) {
      this.fireOnBlur(current.props.value);
    }
  }

  private fireOnBlur(value: any) {
    const returnValue =
      this.props.returnKey && has(value, this.props.returnKey)
        ? get(value, this.props.returnKey)
        : value;
    if (this.state.expanded) {
      this.props.onChange(returnValue);
    }
  }
}
