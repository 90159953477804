import { RootEntityType } from "@haywork/api/event-center";
import {
  LinkedAssignment,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import { File } from "@haywork/api/mail";
import PresenceEntity from "@haywork/components/ui/presence/components/entity";
import { REQUEST } from "@haywork/constants";
import { EmailDetailContainerProps } from "@haywork/modules/email";
import { ResourceText } from "@haywork/modules/shared";
import { SaveFilesToDossier } from "@haywork/modules/shared/components/dossier-v2";
import { RelationUtil } from "@haywork/util";
import noop from "lodash-es/noop";
import uniq from "lodash-es/uniq";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EmailDetailHeaderComponent } from "./header.component";
import { EmailPaper } from "./paper.component";

const styles = require("./detail.component.scss");

export interface EmailDetailComponentProps {}
interface State {
  saveToDossierModalVisible: boolean;
  saveToDossierFiles: File[];
  saveToDossierLinkedRelations: LinkedRelation[];
  saveToDossierLinkedAssignments: LinkedAssignment[];
}
type Props = EmailDetailComponentProps & EmailDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailDetailComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onDeleteMessageHandler = this.onDeleteMessageHandler.bind(this);
    this.onArchiveMessageHandler = this.onArchiveMessageHandler.bind(this);
    this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
    this.onSaveAttachmentsToDossier = this.onSaveAttachmentsToDossier.bind(
      this
    );
    this.closeSaveToDossierModal = this.closeSaveToDossierModal.bind(this);

    this.state = {
      saveToDossierModalVisible: false,
      saveToDossierFiles: [],
      saveToDossierLinkedRelations: [],
      saveToDossierLinkedAssignments: [],
    };
  }

  public render() {
    if (!this.props.currentMessage) {
      return (
        <div styleName="detail">
          <div styleName="detail__header">
            <EmailDetailHeaderComponent
              message={null}
              messageStatus={REQUEST.PENDING}
              currentAccount={null}
              currentFolder={null}
              currentFolderIsArchive={false}
              currentFolderIsTrash={false}
              toggleBookmarked={noop}
              toggleUnread={noop}
              moveToFolder={noop}
              onDeleteMessage={noop}
              onMessageToDraft={noop}
              onArchiveMessage={noop}
              onCreateNewEmail={this.props.createNewEmail}
              onDownloadExternalReferences={noop}
            />
          </div>
          <div styleName="detail__body empty">
            <div styleName="empty-state">
              <ResourceText resourceKey="emptyStateEmailDetail" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div styleName="detail" data-cy="CY-emailDetail">
        <PresenceEntity
          entityType={RootEntityType.Unknown}
          entityId={this.props.currentMessage.id}
        />
        <div styleName="detail__header">
          <EmailDetailHeaderComponent
            message={this.props.currentMessage}
            messageStatus={this.props.currentMessageStatus}
            currentAccount={this.props.currentAccount}
            currentFolder={this.props.currentFolder}
            currentFolderIsArchive={this.props.currentFolderIsArchive}
            currentFolderIsTrash={this.props.currentFolderIsTrash}
            toggleBookmarked={this.props.toggleBookmarked}
            toggleUnread={this.props.toggleUnread}
            moveToFolder={this.props.moveToFolder}
            onDeleteMessage={this.onDeleteMessageHandler}
            onMessageToDraft={this.props.messageToDraft}
            onArchiveMessage={this.onArchiveMessageHandler}
            onCreateNewEmail={this.props.createNewEmail}
            onDownloadExternalReferences={() =>
              this.props.loadExternalReferences(this.props.currentMessage)
            }
          />
        </div>
        <div styleName="detail__body">
          <div styleName="detail__paper">
            {!!this.props.currentMessage && (
              <EmailPaper
                message={this.props.currentMessage}
                messageStatus={this.props.currentMessageStatus}
                folder={this.props.currentFolder}
                onDownloadExternalReferences={() =>
                  this.props.loadExternalReferences(this.props.currentMessage)
                }
                onDownloadAttachment={this.onDownloadAttachment}
                onSaveAttachmentsToDossier={this.onSaveAttachmentsToDossier}
              />
            )}
          </div>
        </div>

        <SaveFilesToDossier
          visible={this.state.saveToDossierModalVisible}
          files={this.state.saveToDossierFiles}
          linkedRelations={this.state.saveToDossierLinkedRelations}
          linkedAssignments={this.state.saveToDossierLinkedAssignments}
          onClose={this.closeSaveToDossierModal}
        />
      </div>
    );
  }

  private onDeleteMessageHandler() {
    if (!this.props.currentMessage || !this.props.currentAccount) return;
    return this.props.deleteMessage(
      this.props.currentAccount.id,
      this.props.currentMessage
    );
  }

  private onArchiveMessageHandler() {
    if (!this.props.currentMessage) return;
    return this.props.archiveMessage(
      this.props.currentAccount.id,
      this.props.currentMessage.id
    );
  }

  private onDownloadAttachment(file: File) {
    window.open(file.uri, "_blank");
  }

  private async onSaveAttachmentsToDossier(
    files: File[],
    linkedRelations: LinkedRelation[],
    linkedAssignments: LinkedAssignment[]
  ) {
    this.setState({
      saveToDossierFiles: files,
      saveToDossierModalVisible: true,
      saveToDossierLinkedRelations: linkedRelations,
      saveToDossierLinkedAssignments: linkedAssignments,
    });

    const { from, replyTo } = this.props.currentMessage;
    const emailsFrom = (from || [])
      .map((email) => email.email)
      .filter((d) => !!d);

    const emailsReplyTo = (replyTo || [])
      .map((email) => email.email)
      .filter((d) => !!d);

    const emailAdresses = uniq([...emailsFrom, ...emailsReplyTo]);

    if (!!from && !!from.length) {
      const relations = await this.props.getRelationsWithMatchingEmailAddress(
        emailAdresses,
        [RelationType.ContactPerson, RelationType.ContactCompany]
      );
      const saveToDossierLinkedRelations = uniqBy(
        [
          ...linkedRelations,
          ...relations.map(RelationUtil.mapSnapshotToLinkedRelation),
        ],
        (relation) => relation.id
      );

      this.setState({ saveToDossierLinkedRelations });
    }
  }

  private closeSaveToDossierModal() {
    this.setState({
      saveToDossierFiles: [],
      saveToDossierLinkedRelations: [],
      saveToDossierLinkedAssignments: [],
      saveToDossierModalVisible: false,
    });
  }
}
