import {
  AssignmentType,
  GlobalSearchType,
  RelationType,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import I18n from "@haywork/components/i18n";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  INVOICEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  REQUEST,
  TASKROUTES,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum/entity";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  GlobalSearchAssignmentItemComponent,
  GlobalSearchInvoiceItemComponent,
  GlobalSearchRelationItemComponent,
  GlobalSearchSearchContainerProps,
  GlobalSearchTaskItemComponent,
} from "@haywork/modules/global-search";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { RegexUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./search.component.scss");
const LISTOPTIONS = {
  SHOW_ALL_RELATIONS: "SHOW_ALL_RELATIONS",
  SHOW_ALL_ASSIGNMENTS: "SHOW_ALL_ASSIGNMENTS",
  SHOW_ALL_INVOICES: "SHOW_ALL_INVOICES",
  SHOW_ALL_TASKS: "SHOW_ALL_TASKS",
  ADD_RELATION: "ADD_RELATION",
  ADD_ASSIGNMENT: "ADD_ASSIGNMENT",
  ADD_INVOICE: "ADD_INVOICE",
  ADD_TASK: "ADD_TASK",
};

export interface GlobalSearchSearchComponentProps {}
interface GlobalSearchOptionsState {
  options: string[];
  currentOption: string;
}
interface GlobalSearchSearchComponentState extends GlobalSearchOptionsState {
  expanded: boolean;
  value: string;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}

type relationInformation = "email" | "telephone" | "name";

@CSSModules(styles, { allowMultiple: true })
export class GlobalSearchSearchComponent extends React.Component<
  GlobalSearchSearchComponentProps & GlobalSearchSearchContainerProps,
  GlobalSearchSearchComponentState
> {
  private ref: HTMLDivElement;
  private input: HTMLInputElement;
  private list: HTMLDivElement;

  constructor(props) {
    super(props);

    // Set initial state
    const { options, currentOption } = this.mapOptionsState(this.props, true);
    this.state = {
      expanded: false,
      options,
      currentOption,
      value: "",
      newEntityVisible: false,
      newEntityOptions: null,
    };

    // Event handlers
    this.onQueryChangeHandler = this.onQueryChangeHandler.bind(this);
    this.runQuery = debounce(this.runQuery.bind(this), 500);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onFocushandler = this.onFocushandler.bind(this);
    this.onClearClickHandler = this.onClearClickHandler.bind(this);
    this.showAllAssignmentsClickHandler =
      this.showAllAssignmentsClickHandler.bind(this);
    this.showAllRelationsClickHandler =
      this.showAllRelationsClickHandler.bind(this);
    this.showAllInvoicesClickHandler =
      this.showAllInvoicesClickHandler.bind(this);
    this.showAllTasksClickHandler = this.showAllTasksClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.focusOnDefaultOption = this.focusOnDefaultOption.bind(this);
    this.setListElement = this.setListElement.bind(this);
    this.onRelationClickHandler = this.onRelationClickHandler.bind(this);
    this.onAssignmentClickHandler = this.onAssignmentClickHandler.bind(this);
    this.onInvoiceClickHandler = this.onInvoiceClickHandler.bind(this);
    this.onTaskClickHandler = this.onTaskClickHandler.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const listStyle = classNames("global-search__list", {
      visible:
        this.state.expanded && this.state.value && this.state.value.length >= 2,
    });
    const globalSearchStyle = classNames("global-search", {
      active: this.state.expanded,
    });

    return (
      <div
        styleName={globalSearchStyle}
        ref={(ref) => (this.ref = ref)}
        onKeyDown={this.onKeyDownHandler}
        id="global-search"
      >
        <div styleName="container">
          <div styleName="global-search__input">
            <label htmlFor="global-search">
              <div className="fal fa-search" />
            </label>
            <input
              type="text"
              name="global-search"
              id="global-search"
              value={this.state.value}
              placeholder={intlContext.formatMessage({
                id: "searchbarPlaceHolder",
                defaultMessage: "searchbarPlaceHolder",
              })}
              onChange={this.onQueryChangeHandler}
              onFocus={this.onFocushandler}
              ref={(ref) => (this.input = ref)}
              data-lpignore="true"
            />
            {this.state.value && (
              <div styleName="clear" onClick={this.onClearClickHandler}>
                <div className="fal fa-times" />
              </div>
            )}
            {this.props.searchStatus === REQUEST.PENDING && (
              <div styleName="loader">
                <div styleName="indeterminate" />
              </div>
            )}
          </div>
          <div styleName={listStyle} ref={this.setListElement}>
            <h2>
              {this.props.searchStatus === REQUEST.PENDING ? (
                <I18n value="searchingRelations" />
              ) : (
                <I18n
                  value="relationsFound"
                  values={{ count: this.props.relationCount }}
                />
              )}
            </h2>
            {this.props.relations.map((relation, idx) => (
              <ErrorBoundary key={idx}>
                <GlobalSearchRelationItemComponent
                  relation={relation}
                  query={this.state.value}
                  onRelationClick={this.onRelationClickHandler}
                  active={this.state.currentOption === relation.id}
                  list={this.list}
                />
              </ErrorBoundary>
            ))}
            {this.renderLoadAllRelations()}
            {this.renderCreateRelationItem()}

            <h2>
              {this.props.searchStatus === REQUEST.PENDING ? (
                <I18n value="searchingAssignments" />
              ) : (
                <I18n
                  value="assignmentsFound"
                  values={{ count: this.props.assignmentCount }}
                />
              )}
            </h2>
            {this.props.assignments.map((assignment, idx) => (
              <ErrorBoundary key={idx}>
                <GlobalSearchAssignmentItemComponent
                  assignment={assignment}
                  query={this.state.value}
                  onAssignmentClick={this.onAssignmentClickHandler}
                  active={this.state.currentOption === assignment.id}
                  list={this.list}
                />
              </ErrorBoundary>
            ))}
            {this.renderLoadAllAssignments()}
            {this.renderCreateAssignmentItem()}

            <h2>
              {this.props.searchStatus === REQUEST.PENDING ? (
                <I18n value="searchingInvoices" />
              ) : (
                <I18n
                  value="invoicesFound"
                  values={{ count: this.props.invoiceCount }}
                />
              )}
            </h2>
            {this.props.invoices.map((invoice, idx) => (
              <ErrorBoundary key={idx}>
                <GlobalSearchInvoiceItemComponent
                  invoice={invoice}
                  query={this.state.value}
                  onInvoiceClick={this.onInvoiceClickHandler}
                  active={this.state.currentOption === invoice.id}
                  list={this.list}
                />
              </ErrorBoundary>
            ))}
            {this.renderLoadAllInvoices()}
            {this.renderCreateInvoiceItem()}

            <h2>
              {this.props.searchStatus === REQUEST.PENDING ? (
                <I18n value="searchingTasks" />
              ) : (
                <I18n
                  value="tasksFound"
                  values={{ count: this.props.taskCount }}
                />
              )}
            </h2>
            {this.props.tasks.map((task, idx) => (
              <ErrorBoundary key={idx}>
                <GlobalSearchTaskItemComponent
                  task={task}
                  query={this.state.value}
                  onTaskClick={this.onTaskClickHandler}
                  active={this.state.currentOption === task.id}
                  list={this.list}
                />
              </ErrorBoundary>
            ))}
            {this.renderLoadAllTasks()}
            {this.renderCreateTaskItem()}
          </div>
        </div>
        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<
      GlobalSearchSearchComponentProps & GlobalSearchSearchContainerProps
    >
  ) {
    const state = this.mapOptionsState(nextProps);
    if (!!state) {
      this.setState(state);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
    if (!this.state.expanded || [13, 27, 38, 40].indexOf(event.keyCode) === -1)
      return;
    const currentIndex = this.state.options.indexOf(this.state.currentOption);

    switch (event.keyCode) {
      case 13: {
        return this.onListEnterHandler(this.state.currentOption);
      }
      case 27: {
        return this.onClearClickHandler();
      }
      case 38: {
        if (currentIndex === 0) return;
        const currentOption = this.state.options[currentIndex - 1];
        return this.setState({ currentOption });
      }
      case 40: {
        if (currentIndex === this.state.options.length - 1) return;
        const currentOption = this.state.options[currentIndex + 1];
        return this.setState({ currentOption });
      }
      default:
        return;
    }
  }

  private onFocushandler(event: React.FocusEvent<HTMLInputElement>) {
    this.setState({ expanded: true });
  }

  private onQueryChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ value });
    this.runQuery(value);
  }

  private onRelationClickHandler(id: string, type: RelationType) {
    this.setState({ expanded: false, value: "" });
    switch (type) {
      case RelationType.ContactCompany:
        return this.props.navigate(
          route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id })
        );
      case RelationType.ContactPerson:
        return this.props.navigate(
          route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
        );
      case RelationType.Employee:
        return this.props.navigate(route(EMPLOYEEROUTES.EMPLOYEE.URI, { id }));
      case RelationType.Office:
        return this.props.navigate(
          route(OFFICESROUTES.OFFICE_DETAIL.URI, { id })
        );
      default:
        return;
    }
  }

  private onAssignmentClickHandler(
    id: string,
    typeOfAssignment: AssignmentType
  ) {
    this.setState({ expanded: false, value: "" });

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        return this.props.navigate(route(ACQUISITIONROUTES.DETAIL.URI, { id }));
      }
      case AssignmentType.AcquisitionObject: {
        return this.props.navigate(
          route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id })
        );
      }
      case AssignmentType.Project: {
        return this.props.navigate(route(PROJECTROUTES.DETAIL.URI, { id }));
      }
      case AssignmentType.ObjectType: {
        return this.props.navigate(route(OBJECTTYPESROUTES.DETAIL.URI, { id }));
      }
      case AssignmentType.Object:
      default: {
        return this.props.navigate(route(ASSIGNMENTROUTES.DETAIL.URI, { id }));
      }
    }
  }

  private onInvoiceClickHandler(id: string) {
    this.setState({ expanded: false, value: "" });
    this.props.navigate(route(INVOICEROUTES.DETAIL.URI, { id }));
  }

  private onTaskClickHandler(id: string) {
    this.setState({ expanded: false, value: "" });
    this.props.navigate(route(TASKROUTES.TASK.URI, { id }));
  }

  private onClearClickHandler() {
    this.setState({ value: "" });
    if (this.input && this.state.expanded) this.input.focus();
  }

  private showAllRelationsClickHandler() {
    this.props.search(this.state.value, GlobalSearchType.Relations, 100);
  }

  private showAllAssignmentsClickHandler() {
    this.props.search(this.state.value, GlobalSearchType.Assignments, 100);
  }

  private showAllInvoicesClickHandler() {
    this.props.search(this.state.value, GlobalSearchType.Invoices, 100);
  }

  private showAllTasksClickHandler() {
    this.props.search(this.state.value, GlobalSearchType.Tasks, 100);
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.expanded) {
      // Dont close the dropdown if there is text selected
      if (this.input.selectionStart === this.input.selectionEnd) {
        this.setState({ expanded: false });
      }
    }
  }

  private mapOptionsState(
    props: GlobalSearchSearchComponentProps & GlobalSearchSearchContainerProps,
    init?: boolean
  ): GlobalSearchOptionsState {
    if (
      props.relations.length === this.props.relations.length &&
      props.assignments.length === this.props.assignments.length &&
      props.invoices.length === this.props.invoices.length
    ) {
      return init ? { options: [], currentOption: "" } : undefined;
    }

    // Map relation options
    const relationIds = props.relations.map((r) => r.id);
    if (relationIds.length < props.relationCount && relationIds.length <= 5)
      relationIds.push(LISTOPTIONS.SHOW_ALL_RELATIONS);
    relationIds.push(LISTOPTIONS.ADD_RELATION);

    // Map assignment options
    const assignmentIds = props.assignments.map((a) => a.id);
    if (
      assignmentIds.length < props.assignmentCount &&
      assignmentIds.length <= 5
    )
      assignmentIds.push(LISTOPTIONS.SHOW_ALL_ASSIGNMENTS);
    assignmentIds.push(LISTOPTIONS.ADD_ASSIGNMENT);

    // Map invoice options
    const invoiceIds = props.invoices.map((a) => a.id);
    if (invoiceIds.length < props.invoiceCount && invoiceIds.length <= 5)
      invoiceIds.push(LISTOPTIONS.SHOW_ALL_INVOICES);
    invoiceIds.push(LISTOPTIONS.ADD_INVOICE);

    // Map task options
    const taskIds = props.tasks.map((t) => t.id);
    if (taskIds.length < props.taskCount && taskIds.length <= 5)
      taskIds.push(LISTOPTIONS.SHOW_ALL_TASKS);
    taskIds.push(LISTOPTIONS.ADD_TASK);

    // Check if load more was clicked
    const showAllOptions = [
      LISTOPTIONS.SHOW_ALL_ASSIGNMENTS,
      LISTOPTIONS.SHOW_ALL_RELATIONS,
    ];
    const currentIndex = !init
      ? this.state.options.indexOf(this.state.currentOption)
      : -1;
    const currentOption =
      init || showAllOptions.indexOf(this.state.currentOption) === -1
        ? ""
        : this.state.options[currentIndex - 1];

    return {
      options: [...relationIds, ...assignmentIds, ...invoiceIds, ...taskIds],
      currentOption,
    };
  }

  private setListElement(list: HTMLDivElement) {
    if (!!list && !this.list) this.list = list;
  }

  private runQuery(value: string) {
    this.props.search(value);
  }

  private onListEnterHandler(option: string) {
    if (!!this.input) {
      this.input.blur();
    }

    switch (option) {
      case LISTOPTIONS.ADD_ASSIGNMENT:
        this.props.createAssignment();
        this.setState({ value: "" });
        return;
      case LISTOPTIONS.ADD_INVOICE:
        this.props.createInvoice();
        this.setState({ value: "" });
        return;
      case LISTOPTIONS.ADD_TASK:
        this.props.createTask();
        this.setState({ value: "" });
        return;
      case LISTOPTIONS.ADD_RELATION:
        const uri = route(RELATIONROUTES.NEW_ARO.URI, {
          newName: this.state.value,
          caller: "globalSearch",
        });
        this.props.navigate(uri);
        this.setState({ value: "" });
        return;
      case LISTOPTIONS.SHOW_ALL_ASSIGNMENTS:
        return this.showAllAssignmentsClickHandler();
      case LISTOPTIONS.SHOW_ALL_RELATIONS:
        return this.showAllRelationsClickHandler();
      case LISTOPTIONS.SHOW_ALL_INVOICES:
        return this.showAllInvoicesClickHandler();
      case LISTOPTIONS.SHOW_ALL_TASKS:
        return this.showAllTasksClickHandler();
      default:
        const relation = this.props.relations.find((r) => r.id === option);
        if (relation)
          return this.onRelationClickHandler(
            relation.id,
            relation.typeOfRelation
          );

        const assignment = this.props.assignments.find((a) => a.id === option);
        if (assignment)
          return this.onAssignmentClickHandler(
            assignment.id,
            assignment.typeOfAssignment
          );
        return;
    }
  }

  private renderCreateRelationItem(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.ADD_RELATION;
    const createItemStyle = classNames("create-item", { active });

    return (
      <div
        styleName={createItemStyle}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="create-item__icon">
          <div className="fal fa-plus" />
        </div>
        <div
          styleName="create-item__body"
          onClick={() => {
            const value = this.state.value;

            if (RegexUtil.isNumber(value))
              this.onRelationAddHandler(this.state.value, "telephone");
            if (RegexUtil.isValidEmail(value))
              this.onRelationAddHandler(this.state.value, "email");
            if (!RegexUtil.isNumber(value) && !RegexUtil.isValidEmail(value))
              this.onRelationAddHandler(this.state.value, "name");
            this.setState({ value: "" });
          }}
        >
          <I18n
            value={
              RegexUtil.isNumber(this.state.value)
                ? "createNewRelationPhone"
                : RegexUtil.isValidEmail(this.state.value)
                ? "createNewRelationEmail"
                : "createNewRelation"
            }
            values={
              RegexUtil.isNumber(this.state.value)
                ? { telefoonnummer: this.state.value }
                : RegexUtil.isValidEmail(this.state.value)
                ? { email: this.state.value }
                : { name: this.state.value }
            }
            asHtml
          />
        </div>
      </div>
    );
  }

  private onRelationAddHandler(value: string, typeOfInfo: relationInformation) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          [`${typeOfInfo}`]: value,
        },
      },
    });
  }

  private renderCreateAssignmentItem(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.ADD_ASSIGNMENT;
    const createItemStyle = classNames("create-item", { active });

    return (
      <div
        styleName={createItemStyle}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="create-item__icon">
          <div className="fal fa-plus" />
        </div>
        <div
          styleName="create-item__body"
          onClick={() => {
            this.props.createAssignment();
            this.setState({ value: "" });
          }}
        >
          <I18n
            value="createNewAssignment"
            values={{ name: this.state.value }}
            asHtml
          />
        </div>
      </div>
    );
  }

  private renderCreateInvoiceItem(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.ADD_INVOICE;
    const createItemStyle = classNames("create-item", { active });

    return (
      <div
        styleName={createItemStyle}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="create-item__icon">
          <div className="fal fa-plus" />
        </div>
        <div
          styleName="create-item__body"
          onClick={() => {
            this.props.createInvoice();
            this.setState({ value: "" });
          }}
        >
          <I18n
            value="createNewInvoice"
            values={{ name: this.state.value }}
            asHtml
          />
        </div>
      </div>
    );
  }

  private renderCreateTaskItem(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.ADD_TASK;
    const createItemStyle = classNames("create-item", { active });

    return (
      <div
        styleName={createItemStyle}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="create-item__icon">
          <div className="fal fa-plus" />
        </div>
        <div
          styleName="create-item__body"
          onClick={() => {
            this.props.createTask();
            this.setState({ value: "" });
          }}
        >
          <I18n
            value="createNewTask"
            values={{ name: this.state.value }}
            asHtml
          />
        </div>
      </div>
    );
  }

  private renderLoadAllRelations(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.SHOW_ALL_RELATIONS;
    const showItemsStyle = classNames("show-items", { active });
    const count = this.props.relations.length;

    return this.props.relationCount > count && count <= 5 ? (
      <div
        styleName={showItemsStyle}
        onClick={this.showAllRelationsClickHandler}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="show-items__icon">
          <div className="fal fa-search-plus" />
        </div>
        <div styleName="show-items__body">
          <I18n
            value="showOtherRelations"
            values={{ count: this.props.relationCount - 5 }}
            asHtml
          />
        </div>
      </div>
    ) : null;
  }

  private renderLoadAllAssignments(): React.ReactElement<HTMLDivElement> {
    const active =
      this.state.currentOption === LISTOPTIONS.SHOW_ALL_ASSIGNMENTS;
    const showItemsStyle = classNames("show-items", { active });
    const count = this.props.assignments.length;

    return this.props.assignmentCount > count && count <= 5 ? (
      <div
        styleName={showItemsStyle}
        onClick={this.showAllAssignmentsClickHandler}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="show-items__icon">
          <div className="fal fa-search-plus" />
        </div>
        <div styleName="show-items__body">
          <I18n
            value="showOtherAssignments"
            values={{ count: this.props.assignmentCount - 5 }}
            asHtml
          />
        </div>
      </div>
    ) : null;
  }

  private renderLoadAllInvoices(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.SHOW_ALL_INVOICES;
    const showItemsStyle = classNames("show-items", { active });
    const count = this.props.invoices.length;

    return this.props.invoiceCount > count && count <= 5 ? (
      <div
        styleName={showItemsStyle}
        onClick={this.showAllInvoicesClickHandler}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="show-items__icon">
          <div className="fal fa-search-plus" />
        </div>
        <div styleName="show-items__body">
          <I18n
            value="showOtherInvoices"
            values={{ count: this.props.invoiceCount - 5 }}
            asHtml
          />
        </div>
      </div>
    ) : null;
  }

  private renderLoadAllTasks(): React.ReactElement<HTMLDivElement> {
    const active = this.state.currentOption === LISTOPTIONS.SHOW_ALL_TASKS;
    const showItemsStyle = classNames("show-items", { active });
    const count = this.props.tasks.length;

    return this.props.taskCount > count && count <= 5 ? (
      <div
        styleName={showItemsStyle}
        onClick={this.showAllTasksClickHandler}
        ref={(ref) => this.focusOnDefaultOption(ref, active)}
      >
        <div styleName="show-items__icon">
          <div className="fal fa-search-plus" />
        </div>
        <div styleName="show-items__body">
          <I18n
            value="showOtherTasks"
            values={{ count: this.props.taskCount - 5 }}
            asHtml
          />
        </div>
      </div>
    ) : null;
  }

  private focusOnDefaultOption(ref: HTMLDivElement, active: boolean) {
    if (!active || !ref || !this.list) return;

    const { clientHeight, scrollTop } = this.list;
    const top = ref.offsetTop;
    const bottom = top + ref.clientHeight;

    if (bottom > clientHeight + scrollTop || top < scrollTop) {
      this.list.scrollTop = top + ref.clientHeight / 2 - clientHeight / 2;
    }
  }
}
