import { EmployeeRole } from "@haywork/api/kolibri";
import {
  DYNAMICDOCUMENTROUTES,
  RELATIONROUTES,
  SEARCHASSIGNMENTROUTES
} from "@haywork/constants";
import {
  AssignmentThunks,
  InvoiceThunk,
  SchedulerThunks
} from "@haywork/middleware";
import {
  AcquisitionThunk,
  Dispatch,
  ProjectsThunks,
  TaskThunks
} from "@haywork/middleware/thunk";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  CreateMenuComponent,
  CreateMenuComponentProps
} from "@haywork/modules/layout";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { BackOfficeUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  canCreateObjectAssignment,
  canCreateProjectAssignment
} from "./selectors";

interface StateProps {
  employeeId: string;
  isBackofficeEmployee: boolean;
  canSendEmail: boolean;
  canCreateObjectAssignment: boolean;
  canCreateProjectAssignment: boolean;
}

interface DispatchProps {
  createNewTask: () => void;
  createNewAssignment: () => void;
  createNewAcquisition: () => void;
  createNewSearchAssignment: () => void;
  createNewAgendaItem: (
    startDate: Date,
    endDate: Date,
    employeeId: string
  ) => void;
  createNewRelation: () => void;
  createNewInvoice: () => void;
  createNewDocument: () => void;
  createNewEmail: () => void;
  createNewProject: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  CreateMenuComponentProps,
  AppState
> = (state: AppState) => {
  const employeeId = BackOfficeUtil.getEmployeeId(state.account);
  const { role } = state.account.currentRealestateAgency;

  return {
    employeeId,
    isBackofficeEmployee: role === EmployeeRole.BackOffice,
    canSendEmail: canSendEmail(state),
    canCreateObjectAssignment: canCreateObjectAssignment(state),
    canCreateProjectAssignment: canCreateProjectAssignment(state)
  };
};

const mapDispatchToProps = <DispatchProps, CreateMenuComponentProps>(
  dispatch: Dispatch<any>
) => ({
  createNewTask: () => dispatch(TaskThunks.createTask()),
  createNewAssignment: () => dispatch(AssignmentThunks.createAssignment()),
  createNewAcquisition: () => dispatch(AcquisitionThunk.createAcquisition()),
  createNewSearchAssignment: () =>
    dispatch(push(SEARCHASSIGNMENTROUTES.NEW.URI)),
  createNewAgendaItem: (startDate: Date, endDate: Date, employeeId: string) =>
    dispatch(
      SchedulerThunks.createSchedulerItem(startDate, endDate, [employeeId])
    ),
  createNewRelation: () => dispatch(push(RELATIONROUTES.NEW.URI)),
  createNewInvoice: () => dispatch(InvoiceThunk.createInvoice()),
  createNewDocument: () => dispatch(push(DYNAMICDOCUMENTROUTES.NEW.URI)),
  createNewEmail: () => dispatch(EmailThunk.Main.createNewEmail()),
  createNewProject: () => dispatch(ProjectsThunks.Projects.createProject())
});

export type CreateMenuContainerProps = StateProps & DispatchProps;
export const CreateMenuContainer = connect<
  StateProps,
  DispatchProps,
  CreateMenuComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(CreateMenuComponent);
