import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

import { ResourceText } from "@haywork/modules/shared";
import { RentOffer, AvailabilityStatus } from "@haywork/api/kolibri";

const styles = require("./widgets.component.scss");

interface Props {
  rentOffer: RentOffer;
  availability: AvailabilityStatus;
  onRetractRentOffer: () => void;
}

export const RetractRentData = CSSModules(styles, { allowMultiple: true })(
  ({ rentOffer, onRetractRentOffer, availability }: Props) => {
    if (
      availability !== AvailabilityStatus.Available ||
      !rentOffer ||
      !rentOffer.rentedFrom ||
      !rentOffer.dateSignDeed
    )
      return null;

    return (
      <div styleName="widget retract-sale-data">
        <h2 styleName="title">
          <ResourceText resourceKey="assignment.RetractRentDataTitle" />
        </h2>
        <div styleName="retract-sale-data__dates">
          {!!rentOffer.dateSignDeed && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractRentData.dateSignDeed" />
              </h3>
              <FormattedDate
                value={rentOffer.dateSignDeed}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!rentOffer.rentedFrom && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractRentData.rentedFrom" />
              </h3>
              <FormattedDate
                value={rentOffer.rentedFrom}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!rentOffer.rentedUntil && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractRentData.rentedUntil" />
              </h3>
              <FormattedDate
                value={rentOffer.rentedUntil}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!rentOffer.dateReservation && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractRentData.dateReservation" />
              </h3>
              <FormattedDate
                value={rentOffer.dateReservation}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
        </div>

        <button
          className="btn btn-primary"
          type="button"
          onClick={onRetractRentOffer}
        >
          <ResourceText resourceKey="assignment.RetractRentData" />
        </button>
      </div>
    );
  }
);
