import {
  AcquisitionObjectAssignment,
  ObjectAssignment,
  SoilTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  soilTypeOptions: SoilTypeOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalAgriculturalHouseComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.formControls = {
      isPermanentlyInhabited: {
        value: get(
          this.props.objectAssignment,
          "isPermanentlyInhabited",
          false
        ),
      },
      isRecreational: {
        value: get(this.props.objectAssignment, "isRecreational", false),
      },
      inPark: { value: get(this.props.objectAssignment, "inPark", false) },
      isNewEstate: {
        value: get(this.props.objectAssignment, "isNewEstate", false),
      },
      soilType: { value: get(this.props.objectAssignment, "soilType", "") },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.formRef.update(
        {
          isPermanentlyInhabited: get(
            this.props.objectAssignment,
            "isPermanentlyInhabited",
            false
          ),
          isRecreational: get(
            this.props.objectAssignment,
            "isRecreational",
            false
          ),
          inPark: get(this.props.objectAssignment, "inPark", false),
          isNewEstate: get(this.props.objectAssignment, "isNewEstate", false),
          soilType: get(this.props.objectAssignment, "soilType", ""),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="business-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <Input.Switch
            name="isPermanentlyInhabited"
            on={true}
            off={false}
            label="isPermanentlyInhabited"
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <Input.Switch
                name="isRecreational"
                on={true}
                off={false}
                label="isRecreational"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="inPark"
                on={true}
                off={false}
                label="inPark"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <Input.Switch
            name="isNewEstate"
            on={true}
            off={false}
            label="isNewEstate"
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column column__minwidth">
              <label htmlFor="soilType">
                <ResourceText resourceKey="soilType" />
              </label>
              <Input.NewSelect
                name="soilType"
                placeholder="makeAChoice"
                values={this.props.soilTypeOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const updatedObjectAssignment = {
      ...objectAssignment,
      ...values,
    };

    this.props.onChange(updatedObjectAssignment);
  }
}
