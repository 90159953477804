import {
  BatchItem,
  BatchItemType,
  FolderTreeFileType,
  BatchOption,
  FolderTreeFileEntity
} from "@haywork/api/kolibri";

export type ConvertableFile = {
  file: FolderTreeFileEntity;
  convertToPDF: boolean;
};

export class BlobUtil {
  public static mapConvertableFileToBatchItem(
    item: ConvertableFile
  ): BatchItem | null {
    const { file, convertToPDF } = item;
    const { id, type: fileType } = file;

    let type: BatchItemType;
    switch (fileType) {
      case FolderTreeFileType.DocumentSession: {
        type = BatchItemType.DocumentSession;
        break;
      }
      case FolderTreeFileType.DossierItem: {
        type = BatchItemType.DossierItem;
        break;
      }
      case FolderTreeFileType.Invoice: {
        type = BatchItemType.Invoice;
        break;
      }
      default:
        return null;
    }

    let options = [BatchOption.TempAccessUrl, BatchOption.UseDossierFileName];
    if (!!convertToPDF) {
      options = [...options, BatchOption.ConvertToPdf];
    }

    return {
      id,
      type,
      options
    };
  }

  public static mapFolderTreeFileEntityToConvertableFile(
    file: FolderTreeFileEntity
  ): ConvertableFile {
    return { file, convertToPDF: false };
  }
}
