import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

type Props = {
  maxWidth?: number;
};

export const SectionColumnComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ children, maxWidth }) => {
    return (
      <div styleName="column" style={{ maxWidth }}>
        {children}
      </div>
    );
  })
);
