export const EDITABLE = {
  ADD_STATE: "ADD_EDITABLE_STATE",
  REMOVE_STATE: "REMOVE_EDITABLE_STATE",
  UPDATE_COMPONENT_STATE: "UPDATE_EDITABLE_COMPONENT_STATE",
  UPDATE_SINGLE_COMPONENT_STATE: "UPDATE_EDITABLE_SINGLE_COMPONENT_STATE",
  UPDATE_TAB_TITLE: "UPDATE_EDITABLE_TAB_TITLE",
  REPLACE_STATE_SLICE: "REPLACE_STATE_SLICE",
  SET_HAS_CHANGES: "SET_EDITABLE_HAS_CHANGES",
  SET_PREPPED_FOR_SAVE: "SET_PREPPED_FOR_SAVE",
  DISABLE_PREPPED_FOR_SAVE: "DISABLE_PREPPED_FOR_SAVE",
  CLEAR_ALL: "EDITABLE_CLEAR_ALL",
  UPDATE_COMPONENT_STATE_WITH_PATH: "UPDATE_COMPONENT_STATE_WITH_PATH",
  SET_CALLEE: "EDITABLE_SET_CALLEE",
  SET_CALLER: "EDITABLE_SET_CALLER",
  SET_CALLEE_ID: "EDITABLE_SET_CALLEE_ID",
  REMOVE_CALLEE: "EDITABLE_REMOVE_CALLEE",
  REMOVE_CALLER: "EDITABLE_REMOVE_CALLER",
  SET_CALLEE_STATE: "EDITABLE_SET_CALLEE_STATE",
  CHANGE_EDITABLE_PATH: "CHANGE_EDITABLE_PATH",
  SET_EXTERNAL_CHANGES: "EDITABLE_SET_EXTERNAL_CHANGES",
  REMOVE_EXTERNAL_CHANGES: "EDITABLE_REMOVE_EXTERNAL_CHANGES",
  UPDATE_REFERRER: "EDITABLE_UPDATE_REFERRER",
};
