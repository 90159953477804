import { ErrorMessage } from "@haywork/modules/form";

export const minCapitalLetters = (minLength: number, errorMessage?: ErrorMessage) => (value?: string): null | ErrorMessage => {
  const matched = value.match(/[A-Z]/g);
  if (!!matched && matched.length >= minLength) return null;
  return errorMessage || { key: "validatorNumCapitalLetters", values: { count: minLength } };
};

export const minLowercaseLetters = (minLength: number, errorMessage?: ErrorMessage) => (value?: string): null | ErrorMessage => {
  const matched = value.match(/[a-z]/g);
  if (!!matched && matched.length >= minLength) return null;
  return errorMessage || { key: "validatorNumLowercaseLetters", values: { count: minLength } };
};
