import { NoteSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { NotesActions } from "@haywork/stores/notes-v3";
import { connect, MapStateToProps } from "react-redux";
import {
  NotesPinnedNotesComponent,
  NotesPinnedNotesComponentProps
} from "./pinned-notes";
import { pinnedNotes } from "./selector";

interface StateProps {
  notes: NoteSnapShot[];
  totalCount: number;
}
interface DispatchProps {
  openNotesList: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  NotesPinnedNotesComponentProps,
  AppState
> = (state, ownProps) => ({
  notes: pinnedNotes(ownProps.max)(state),
  totalCount: state.notesV3.totalCount
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  NotesPinnedNotesComponentProps
> = (dispatch) => ({
  openNotesList: () => dispatch(NotesActions.toggleListVisibility(true))
});

export type NotesPinnedNotesContainerProps = StateProps & DispatchProps;
export const NotesPinnedNotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesPinnedNotesComponent);
