import { FolderTree } from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import {
  DeleteDossierItem,
  DossierThunks,
  FolderTreeEntityType,
  FolderTreesThunk,
  MapDispatchToProps
} from "@haywork/middleware/thunk";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { DossierComponent, DossierComponentProps } from "./dossier";

interface StateProps {
  canEmail: boolean;
}
interface DispatchProps {
  fetchFolderTree: (
    parentId: string,
    entityType: FolderTreeEntityType,
    id?: string
  ) => Promise<FolderTree>;
  updateFolderTree: (folderTree: FolderTree) => Promise<FolderTree>;
  uploadDossierItems: (items: any[], parentId: string) => Promise<string[]>;
  createNewEmailWithAttachments: (attachments: UploadResponse[]) => void;
  bulkDeleteDossierItems: (
    items: DeleteDossierItem[],
    undeleteCallback: () => void
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DossierComponentProps,
  AppState
> = (state) => {
  return {
    canEmail: canSendEmail(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DossierComponentProps
> = (dispatch) => ({
  fetchFolderTree: (
    parentId: string,
    entityType: FolderTreeEntityType,
    id?: string
  ) => dispatch(FolderTreesThunk.fetchFolderTree(parentId, entityType, id)),
  updateFolderTree: (folderTree: FolderTree) =>
    dispatch(FolderTreesThunk.updateFolderTree(folderTree)),
  uploadDossierItems: (items: any[], parentId: string) =>
    dispatch(DossierThunks.uploadDossierItems(items, parentId)),
  createNewEmailWithAttachments: (attachments: UploadResponse[]) =>
    dispatch(EmailThunk.Drafts.createNewEmailWithAttachments(attachments)),
  bulkDeleteDossierItems: (
    items: DeleteDossierItem[],
    undeleteCallback: () => void
  ) => dispatch(DossierThunks.bulkDeleteDossierItems(items, undeleteCallback))
});

export type DossierContainerProps = StateProps & DispatchProps;
export const DossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DossierComponent);
