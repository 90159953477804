import I18n from "@haywork/components/i18n";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { ExtendedEventNotification } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ActionButton } from "./action-button.component";

const styles = require("./modal-message.component.scss");

export enum EventNotificationActionType {
  CreateAppointment = "CreateAppointment",
  CreateEmail = "CreateEmail",
  CreateRelation = "CreateRelation",
  CreateTask = "CreateTask",
  OpenAssignment = "OpenAssignment",
  OpenAcquisitionAssignment = "OpenAcquisitionAssignment",
  OpenRelation = "OpenRelation",
  OpenSearchAssignment = "OpenSearchAssignment",
  OpenAppointment = "OpenAppointment",
  RefreshCollegialListingToken = "RefreshCollegialListingToken",
  OpenDraft = "OpenDraft",
  OpenLink = "OpenLink",
  OpenEmail = "OpenEmail"
}

export interface FooterComponentProps {
  message: ExtendedEventNotification;
  canSendEmail: boolean;
  showDuplicateContacts: boolean;
  onActionClick: (type: EventNotificationActionType) => void;
  onUpdateLinkedRelation: () => void;
}

export const FooterComponent = CSSModules(styles, { allowMultiple: true })(
  (props: FooterComponentProps) => {
    if (get(props.message, "actionTypes", []).length === 0) return null;

    const linkedMessages =
      get(props.message, "linkedEvent.linkedMessages") || [];
    const linkedObjectAssignments =
      get(props.message, "linkedEvent.linkedObjectAssignments") || [];
    const linkedAcquisitionObjectAssignments =
      get(props.message, "linkedEvent.linkedAcquisitionObjectAssignments") ||
      [];
    const linkedContactPersons =
      get(props.message, "linkedEvent.linkedContactPersons") || [];
    const linkedSearchAssignments =
      get(props.message, "linkedEvent.linkedSearchAssignments") || [];
    const linkedAgendaItems =
      get(props.message, "linkedEvent.linkedAgendaItems") || [];
    const linkedDrafts = get(props.message, "linkedEvent.linkedDrafts") || [];

    return (
      <div styleName="message__footer">
        {props.message.actionTypes.map((type, idx) => (
          <ErrorBoundary key={idx}>
            <ActionButton
              type={type}
              actionClick={props.onActionClick}
              linkedMessages={linkedMessages}
              linkedObjectAssignments={linkedObjectAssignments}
              linkedAcquisitionObjectAssignments={
                linkedAcquisitionObjectAssignments
              }
              linkedContactPersons={linkedContactPersons}
              linkedSearchAssignments={linkedSearchAssignments}
              linkedAgendaItems={linkedAgendaItems}
              linkedDrafts={linkedDrafts}
              canSendEmail={props.canSendEmail}
            />
          </ErrorBoundary>
        ))}

        <FeatureSwitch feature="DUPLICATE_CONTACTS">
          {!!linkedContactPersons.length && props.showDuplicateContacts && (
            <div styleName="wrong-relation">
              <span className="as-link" onClick={props.onUpdateLinkedRelation}>
                <I18n value="eventCenter.wrongRelation" />
              </span>
            </div>
          )}
        </FeatureSwitch>
      </div>
    );
  }
);
