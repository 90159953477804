import * as React from "react";
import { store } from "@haywork/stores";
import { memo } from "react";
import { Employee } from "@haywork/api/kolibri";

declare let window: ExtendedWindow;

export const Wootric: React.FC = memo(() => {
  if (!!store) {
    const state = store.getState();
    const { wootric } = state.appSettings;
    const { employee }: { employee: Employee } = state.account;
    const lastScript = document.getElementsByTagName("script")[0];

    if (
      !wootric ||
      !employee ||
      !employee.emailAddresses ||
      (employee.emailAddresses && employee.emailAddresses.length === 0)
    )
      return null;

    const setupScript = document.createElement("script");
    setupScript.type = "text/javascript";
    setupScript.async = true;
    setupScript.innerHTML = `
          wootric_no_surveyed_cookie = ${wootric.NO_SURVEYED_COOKIE};
          wootric_survey_immediately = ${wootric.SURVEY_IMMEDIATELY};
          window.wootricSettings = {
            email: "${employee.emailAddresses[0].address}",
            created_at: ${Math.floor(
              new Date(employee.dateTimeCreated).getTime() / 1000
            )},
            account_token: "${wootric.ACCOUNT_TOKEN}"
          };
        `;
    lastScript.parentNode.insertBefore(setupScript, lastScript);

    // Beacon
    const beacon = document.createElement("script");
    beacon.type = "text/javascript";
    beacon.async = true;

    beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
    beacon.onload = () => {
      (window as any).wootric("run");
    };
    lastScript.parentNode.insertBefore(beacon, lastScript);
    return <></>;
  }
});
