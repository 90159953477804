import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import * as deepEqual from "deep-equal";

import {
  Form,
  FormReturnValue,
  FormControls,
  FormReference,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  ObjectAssignment,
  TypeOfHabitationOption,
  TypeOfHabitation,
  TransactionMetaData,
  BuyerType,
} from "@haywork/api/kolibri";
import { TransactionMetaDataResponse } from "@haywork/middleware";

const styles = require("../../transaction-modal.component.scss");

interface Props {
  assignment: ObjectAssignment;
  transactionMetaData: Partial<TransactionMetaData>;
  typeOfHabitationOptions: TypeOfHabitationOption[];
  selectedBuyerType?: BuyerType;
  onUpdate: (data: TransactionMetaDataResponse) => void;
}
interface State {
  typeOfHabitation: TypeOfHabitation;
}

@CSSModules(styles, { allowMultiple: true })
export class ObjectLeftBehindFormALV extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      typeOfHabitation: get(
        this.props.transactionMetaData,
        "leftBehindObject.typeOfHabitation",
        null
      ),
    };

    this.formControls = {
      postalCode: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.postalCode",
          ""
        ),
        validators: [Validators.required()],
      },
      houseNumber: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumber",
          ""
        ),
        validators: [Validators.required()],
      },
      houseNumberPostFix: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumberPostFix",
          ""
        ),
      },
      typeOfHabitation: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          ""
        ),
        onChange: (ref) => {
          this.setState({ typeOfHabitation: ref.value });

          return {
            transactionPrice: "",
            rentalPrice: "",
          };
        },
        validators: [Validators.required()],
      },
      transactionPrice: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.transactionPrice",
          ""
        ),
      },
      rentalPrice: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.rentalPrice",
          ""
        ),
      },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !deepEqual(nextProps.transactionMetaData, this.props.transactionMetaData)
    ) {
      this.setState({
        typeOfHabitation: get(
          nextProps.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          null
        ),
      });

      this.formRef.update({
        postalCode: get(
          nextProps.transactionMetaData,
          "leftBehindObject.postalCode",
          ""
        ),
        houseNumber: get(
          nextProps.transactionMetaData,
          "leftBehindObject.houseNumber",
          ""
        ),
        houseNumberPostFix: get(
          nextProps.transactionMetaData,
          "leftBehindObject.houseNumberPostFix",
          ""
        ),
        typeOfHabitation: get(
          nextProps.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          ""
        ),
        transactionPrice: get(
          nextProps.transactionMetaData,
          "leftBehindObject.transactionPrice",
          ""
        ),
        rentalPrice: get(
          nextProps.transactionMetaData,
          "leftBehindObject.rentalPrice",
          ""
        ),
      });
    }
  }

  public render() {
    const { forSale } = this.props.assignment;
    const { selectedBuyerType } = this.props;
    const showRequiredFields =
      [BuyerType.Starter, BuyerType.MovingOn].indexOf(selectedBuyerType) !== -1;

    return (
      <Form
        name="transaction-personal"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChangeHandler}
      >
        {showRequiredFields && (
          <div styleName="info-block full">
            <i className="fal fa-info-circle" />
            <ResourceText
              resourceKey={
                forSale
                  ? "saleTransaction.tiaraExtraInfo"
                  : "rentTransaction.tiaraExtraInfo"
              }
              values={{ url: "" }}
              asHtml={true}
            />
          </div>
        )}
        <div styleName="inner">
          <div className="form__row">
            <div className="form__group stretch">
              <div className="column fit">
                <label htmlFor="postalCode">
                  <ResourceText resourceKey="saleTransaction.postalCode" />
                  {/* <Ui.RequiredForPublish hideHint={true} /> */}
                </label>
                <Input.Postalcode name="postalCode" />
              </div>
              <div className="column__spacer" />
              <div className="column fit" styleName="house-number">
                <label htmlFor="houseNumber">
                  <ResourceText resourceKey="saleTransaction.houseNumber" />
                  {/* <Ui.RequiredForPublish hideHint={true} /> */}
                </label>
                <Input.Number name="houseNumber" />
              </div>
              <div className="column__spacer" />
              <div className="column fit" styleName="house-number-postfix">
                <label htmlFor="houseNumberPostFix">
                  <ResourceText resourceKey="saleTransaction.houseNumberPostFix" />
                </label>
                <Input.Text name="houseNumberPostFix" />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="buyerType">
                  <ResourceText resourceKey="saleTransaction.typeOfHabitation" />
                  {/* <Ui.RequiredForPublish hideHint={true} /> */}
                </label>
                <Input.NewSelect
                  name="typeOfHabitation"
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.typeOfHabitationDefault"
                  values={this.props.typeOfHabitationOptions}
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>

          {this.state.typeOfHabitation === TypeOfHabitation.Buy && (
            <div className="form__row">
              <label htmlFor="buyerType">
                <ResourceText resourceKey="saleTransaction.transactionPrice" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number name="transactionPrice" pretty={true} />
              </div>
            </div>
          )}

          {this.state.typeOfHabitation === TypeOfHabitation.Rent && (
            <div className="form__row">
              <label htmlFor="buyerType">
                <ResourceText resourceKey="saleTransaction.rentalPrice" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number name="rentalPrice" pretty={true} />
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const data: TransactionMetaDataResponse = {
      leftBehindObject: {
        postalCode: values.postalCode,
        houseNumber: values.houseNumber,
        houseNumberPostFix: values.houseNumberPostFix,
        typeOfHabitation: values.typeOfHabitation,
        transactionPrice: values.transactionPrice,
        rentalPrice: values.rentalPrice,
      },
    };

    this.props.onUpdate(data);
  }
}
