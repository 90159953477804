import * as React from "react";
import classNames from "classnames";

interface PillComponentProps {
  value: React.ReactElement<HTMLDivElement>;
  idx: number;
  markedForDelete: number;
  isCustomPill?: boolean;
  disabled?: boolean;
  onRemove: (idx: number) => void;
}
interface PillComponentState {
  markedForDelete: boolean;
  touched: boolean;
}

export class PillComponent extends React.Component<
  PillComponentProps,
  PillComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      markedForDelete: this.props.idx === this.props.markedForDelete,
      touched: false,
    };
  }

  public render() {
    if (!!this.props.isCustomPill) {
      const pillStyle = classNames("custom-pill", {
        "marked-for-delete": this.state.markedForDelete,
        disabled: this.props.disabled,
      });

      return (
        <div className={pillStyle} data-cy="CY-pillItem">
          {this.props.value}
        </div>
      );
    }

    const pillStyle = classNames("pill", {
      "marked-for-delete": this.state.markedForDelete,
      disabled: this.props.disabled,
      hover: this.state.touched,
    });

    return (
      <div
        className={pillStyle}
        data-cy="CY-pillItem"
        onTouchStart={() => this.setState({ touched: true })}
      >
        <div className="value">{this.props.value}</div>
        <div
          className="remove"
          onClick={() => this.props.onRemove(this.props.idx)}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.RemoveButton`
          }
        >
          <i className="fal fa-times" />
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PillComponentProps) {
    if (
      !!nextProps &&
      nextProps.markedForDelete !== this.props.markedForDelete
    ) {
      this.setState({
        markedForDelete: nextProps.markedForDelete === this.props.idx,
      });
    }
  }
}
