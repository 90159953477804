import { HorseTroughLocation, ObjectAssignment } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./widgets.component.scss");

interface Props {
  assignment: ObjectAssignment;
}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalSpecifications extends React.Component<Props> {
  public render() {
    const {
      isBiologicalCompany,
      isSKALCertified,
      hasHygieneBarrier,
      numberOfMooringPlacesMilkingCattle,
      numberOfMooringPlacesYoungCattle,
      numberOfAnimalPlaces,
      horseTroughLocation,
      stepMill
    } = this.props.assignment;

    return (
      <div styleName="alv-specs">
        {this.renderSpecification(
          isBiologicalCompany,
          "isBiologicalCompanySpec"
        )}
        {this.renderSpecification(isSKALCertified, "isSKALCertifiedSpec")}
        {this.renderSpecification(hasHygieneBarrier, "hasHygieneBarrierSpec")}
        {this.renderSpecification(
          numberOfMooringPlacesMilkingCattle,
          "numberOfMooringPlacesMilkingCattleSpec"
        )}
        {this.renderSpecification(
          numberOfMooringPlacesYoungCattle,
          "numberOfMooringPlacesYoungCattleSpec"
        )}
        {this.renderSpecification(
          numberOfAnimalPlaces,
          "numberOfAnimalPlacesSpec"
        )}
        {horseTroughLocation && (
          <div styleName="alv-spec">
            <div styleName="icon">
              <i className="fal fa-fw fa-check" />
            </div>
            <div styleName="label">
              {horseTroughLocation === HorseTroughLocation.Indoor ? (
                <ResourceText resourceKey="horseTroughLocationIndoorSpec" />
              ) : (
                <ResourceText resourceKey="horseTroughLocationOutdoorSpec" />
              )}
            </div>
          </div>
        )}
        {this.renderSpecification(stepMill, "stepMillSpec")}
      </div>
    );
  }

  private renderSpecification(specification: boolean | number, text: string) {
    switch (typeof specification) {
      case "boolean":
        return this.renderBasicSpec(specification, text);
      case "number":
        return this.renderNumberSpec(specification, text);
      default:
        return null;
    }
  }

  private renderBasicSpec(
    specification,
    text
  ): React.ReactElement<HTMLDivElement> {
    if (!specification) return null;
    return (
      <div styleName="alv-spec">
        <div styleName="icon">
          <i className="fal fa-fw fa-check" />
        </div>
        <div styleName="label">
          <ResourceText resourceKey={text} />
        </div>
      </div>
    );
  }

  private renderNumberSpec(
    specificationNumber,
    text
  ): React.ReactElement<HTMLDivElement> {
    if (!specificationNumber || specificationNumber === 0) return null;
    return (
      <div styleName="alv-spec">
        <div styleName="number">{specificationNumber}</div>
        <div styleName="label">
          <ResourceText resourceKey={text} />
        </div>
      </div>
    );
  }
}
