import { VideoBlob } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { AssignmentEditMarketingVirtualVideoContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText, VideoPreviewComponent } from "@haywork/modules/shared";
import { VideoUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-video.component.scss");

export interface AssignmentEditMarketingVirtualVideoComponentProps {}
interface State {}
type Props = AssignmentEditMarketingVirtualVideoComponentProps &
  AssignmentEditMarketingVirtualVideoContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingVirtualVideoComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      video: {
        value: "",
        validators: [Validators.required(), Validators.url()],
      },
    };

    this.onVideoChangeHandler = this.onVideoChangeHandler.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.submitOnEnterHandler = this.submitOnEnterHandler.bind(this);
    this.onPreviewClickHandler = this.onPreviewClickHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  public render() {
    let { videos } = this.props.objectAssignment;
    videos = videos || [];

    const placeholder = intlContext.formatMessage({
      id: "addVideoTitle",
      defaultMessage: "addVideoTitle",
    });

    return (
      <div styleName="videos">
        <div className="container-fluid">
          <Form
            name="videos"
            formControls={this.formControls}
            onSubmit={this.onSubmitHandler}
            form={(ref) => (this.formRef = ref)}
          >
            {videos.length === 0 ? (
              <div className="container-fluid">
                <div className="form__row">
                  <div className="column grow1">
                    <Input.Text
                      name="video"
                      placeholder="addVideoPlaceholder"
                      submitOnEnter={this.submitOnEnterHandler}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="column as-input">
                    <button type="submit" className="btn btn-blank">
                      <ResourceText resourceKey="add" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="form__row">
                <div className="form__group">
                  <div className="column grow1">
                    <Input.Text
                      name="video"
                      placeholder="addVideoPlaceholder"
                      submitOnEnter={this.submitOnEnterHandler}
                    />
                  </div>
                  <div className="column__spacer" />
                  <div className="column as-input">
                    <button type="submit" className="btn btn-blank">
                      <ResourceText resourceKey="add" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Form>
          {videos.map((video, idx) => (
            <ErrorBoundary key={idx}>
              <VideoPreviewComponent
                urlOriginal={video.urlOriginal}
                name={`video${idx}`}
                id={`video${idx}`}
                video={video}
                value={video.name}
                placeholder={placeholder}
                onPreviewClick={() => this.onPreviewClickHandler(video)}
                onDeleteClick={() => this.onDeleteHandler(idx)}
                onChange={(event) => this.onVideoChangeHandler(event, idx)}
              />
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }

  private submitOnEnterHandler() {
    if (this.formRef) this.formRef.submit();
  }

  private onSubmitHandler(values: FormReturnValue) {
    const { objectAssignment, currentComponentState } = this.props;
    const videoBlob = VideoUtil.createVideoBlob(values.video);
    const videoArray = objectAssignment.videos || [];
    const videos = [...videoArray, videoBlob];
    const updatedObjectAssignment = { ...objectAssignment, videos };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
    if (this.formRef) this.formRef.clear();
  }

  private onDeleteHandler(idx: number) {
    const { objectAssignment, currentComponentState } = this.props;
    const videos = objectAssignment.videos.filter((v, k) => k !== idx);

    const updatedObjectAssignment = { ...objectAssignment, videos };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
  }

  private onPreviewClickHandler(video: VideoBlob) {
    window.open(video.urlOriginal, "_blank");
  }

  private onVideoChangeHandler(
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) {
    const { objectAssignment, currentComponentState } = this.props;
    const videos = objectAssignment.videos.map((video, key) => {
      if (idx === key) {
        return { ...video, name: event.target.value };
      }
      return video;
    });

    const updatedObjectAssignment = { ...objectAssignment, videos };
    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateObjectAssignment(newState, this.props.path);
  }
}
