import { CompanySettings, RealEstateAgency } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";

const setCompanySettings = (settings: CompanySettings) => ({
  type: REDUX.COMPANY.SET_COMPANY_SETTINGS,
  settings
});

const setCompanySettingsStatus = (settingsStatus: string) => ({
  type: REDUX.COMPANY.SET_COMPANY_SETTINGS_STATUS,
  settingsStatus
});

const setCollegialListingsStatus = (collegialListingsStatus: string) => ({
  type: REDUX.COMPANY.SET_COLLEGIAL_SETTINGS_STATUS,
  collegialListingsStatus
});

export const CompanyActions = {
  setCompanySettings,
  setCompanySettingsStatus,
  setCollegialListingsStatus
};
