import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  Address,
  AssignmentSnapShot,
  CivilStateOption,
  ContactCompany,
  ContactPerson,
  CountryOption,
  EmailAddressTypeOption,
  IdentificationTypeOption,
  LinkedRelation,
  PhoneNumberTypeOption,
  RelationType,
  SearchAssignmentSnapShot,
  TimelineEvent,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import {
  AssignmentThunks,
  AuthorizationThunks,
  Dispatch,
  InvoiceThunk,
  RelationThunks,
  SchedulerThunks,
  SearchAssignmentThunks,
  TaskThunks,
} from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import {
  RelationGeneralComponent,
  RelationGeneralComponentProps,
} from "@haywork/modules/relation";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  selectedRelationId?: string;
  selectedRelationType?: RelationType;
  selectedContactPerson?: ContactPerson;
  selectedContactCompany?: ContactCompany;
  relationAssignments?: AssignmentSnapShot[];
  timelineEvents?: TimelineEvent[];
  isLoading: boolean;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
  civilStates: CivilStateOption[];
  identificationTypes: IdentificationTypeOption[];
  searchAssignments: SearchAssignmentSnapShot[];
  searchAssignmentsStatus: string;
  canSendEmail: boolean;
}
interface DispatchProps {
  navigate: (routePath: string) => void;
  getLinkedAssignments: (relation: string) => void;
  getTimelineEvents: (
    relationId: string,
    init?: boolean,
    take?: number
  ) => void;
  getMatchingSearchAssignments: (contactId: string) => void;
  deleteRelation: (
    id: string,
    displayName: string,
    typeOfRelation: RelationType
  ) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  createTaskWithLinkedRelation: (
    linkedRelation: LinkedRelation
  ) => Promise<void>;
  createInvoiceWithLinkedRelation: (
    linkedRelation: LinkedRelation,
    relationType: RelationType,
    customerAddress?: Address
  ) => Promise<void>;
  createAgendaItemWithLinkedRelation: (
    linkedRelation: LinkedRelation
  ) => Promise<void>;
  createNewEmail: (email: string) => void;
  archiveContactPerson: (id: string) => Promise<void>;
  unArchiveContactPerson: (id: string) => Promise<void>;
  archiveContactCompany: (id: string) => Promise<void>;
  unArchiveContactCompany: (id: string) => Promise<void>;
  deleteRelationV2: (
    id: string,
    displayName: string,
    typeOfRelation: RelationType
  ) => Promise<void>;
}

const mapStateToProps = <StateProps, RelationGeneralComponentProps>(
  state: AppState
) => ({
  selectedRelationId: state.relation.detail.selectedRelationId,
  selectedContactCompany: state.relation.detail.selectedContactCompany,
  selectedContactPerson: state.relation.detail.selectedContactPerson,
  selectedRelationType: state.relation.detail.selectedRelationType,
  relationAssignments: state.relation.detailAssignment.relationAssignments,
  timelineEvents: state.relation.detailTimeline.timelineEvents,
  isLoading:
    state.relation.detailAssignment.relationAssignmentState ===
      REQUEST.PENDING ||
    state.relation.detailTimeline.relationTimelineStatus === REQUEST.PENDING,
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  countries: state.main.mastertable.kolibri.countries,
  civilStates: state.main.mastertable.kolibri.civilStates,
  identificationTypes: state.main.mastertable.kolibri.identificationTypes,
  searchAssignments: state.relation.detail.searchAssignments,
  searchAssignmentsStatus:
    state.relation.detail.relationSearchAssignmentsStatus,
  canSendEmail: canSendEmail(state),
});

const mapDispatchToProps = <DispatchProps, RelationGeneralComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getLinkedAssignments: (relationId: string) =>
    dispatch(
      AssignmentThunks.getAssignmentsForRelationDetail(relationId, true)
    ),
  getTimelineEvents: (relationId: string, init?: boolean, take: number = 3) =>
    dispatch(RelationThunks.getTimelineEvents(relationId, init, take)),
  navigate: (routePath) => dispatch(push(routePath)),
  getMatchingSearchAssignments: (contactId: string) =>
    dispatch(SearchAssignmentThunks.getMatchingSearchAssignments(contactId, 3)),
  deleteRelation: (
    id: string,
    displayName: string,
    typeOfRelation: RelationType
  ) =>
    dispatch(
      RelationThunks.deleteRelation(id, displayName, typeOfRelation, true)
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  createTaskWithLinkedRelation: (linkedRelation: LinkedRelation) =>
    dispatch(TaskThunks.createTaskWithLinkedEntities([linkedRelation])),
  createInvoiceWithLinkedRelation: (
    linkedRelation: LinkedRelation,
    relationType: RelationType,
    customerAddress?: Address
  ) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        linkedRelation,
        undefined,
        relationType,
        customerAddress
      )
    ),
  createAgendaItemWithLinkedRelation: (linkedRelation: LinkedRelation) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities([linkedRelation])
    ),
  createNewEmail: (email: string) =>
    dispatch(EmailThunk.Main.createNewEmail(!email ? [] : [email])),
  archiveContactPerson: (id: string) =>
    dispatch(RelationThunks.archiveContactPerson(id)),
  unArchiveContactPerson: (id: string) =>
    dispatch(RelationThunks.unArchiveContactPerson(id)),
  archiveContactCompany: (id: string) =>
    dispatch(RelationThunks.archiveContactCompany(id)),
  unArchiveContactCompany: (id: string) =>
    dispatch(RelationThunks.unArchiveContactCompany(id)),
  deleteRelationV2: (
    id: string,
    displayName: string,
    typeOfRelation: RelationType
  ) =>
    dispatch(RelationThunks.deleteRelationV2(id, displayName, typeOfRelation)),
});

export type RelationGeneralContainerProps = StateProps & DispatchProps;
export const RelationGeneralContainer = connect<
  StateProps,
  DispatchProps,
  RelationGeneralComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationGeneralComponent);
