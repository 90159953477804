import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import {
  EmailActionsV2,
  EmailDraft,
  EmailFolder,
  EmailMessage,
  EmailFiltersState,
} from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import {
  currentFolder,
  currentMessageId,
  items,
  canPerformEditActions,
  hasTrashFolder,
  trashFolder,
} from "../../selectors";
import { ListComponent, ListComponentProps } from "./list";
import { MapDispatchToProps } from "@haywork/middleware";

type StateProps = {
  currentFolder: EmailFolder;
  items: EmailMessage[] | EmailDraft[];
  currentMessageId: string | null;
  filters: EmailFiltersState;
  canPerformEditActions: boolean;
  hasTrashFolder: boolean;
  trashFolder: EmailFolder;
};
type DispatchProps = {
  setFolderPosition: (id: string, position: number) => void;
  onToggleUnRead: (
    id: string,
    isRead: boolean,
    accountId: string
  ) => Promise<void>;
  setCurrentMessage: (message: EmailMessage) => void;
  onToggleBookmarked: (
    id: string,
    isBookmarked: boolean,
    accountId: string
  ) => Promise<void>;
  openDraft: (draft: EmailDraft) => Promise<void>;
  moveToFolder: (messageId: string, newFolderId: string) => Promise<void>;
  deleteDraft: (draft: EmailDraft) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListComponentProps,
  AppState
> = (state) => {
  const { filters } = state.emailV2.main;

  return {
    currentFolder: currentFolder(state),
    items: items(state),
    currentMessageId: currentMessageId(state),
    filters,
    canPerformEditActions: canPerformEditActions(state),
    hasTrashFolder: hasTrashFolder(state),
    trashFolder: trashFolder(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListComponentProps
> = (dispatch) => ({
  setFolderPosition: (id: string, position: number) =>
    dispatch(EmailActionsV2.Folders.setFolderPosition(id, position)),
  onToggleUnRead: (id: string, isRead: boolean, accountId: string) =>
    dispatch(EmailThunk.Messages.onToggleUnRead(id, isRead, accountId)),
  setCurrentMessage: (message: EmailMessage) =>
    dispatch(
      EmailActionsV2.Folders.setCurrentMessage(message.folderId, message.id)
    ),
  onToggleBookmarked: (id: string, isBookmarked: boolean, accountId: string) =>
    dispatch(
      EmailThunk.Messages.onToggleBookmarked(id, isBookmarked, accountId)
    ),
  openDraft: (draft: EmailDraft) =>
    dispatch(EmailThunk.Drafts.openDraft(draft)),
  moveToFolder: (messageId: string, newFolderId: string) =>
    dispatch(EmailThunk.Messages.moveToFolder(messageId, newFolderId)),
  deleteDraft: (draft: EmailDraft) =>
    dispatch(EmailThunk.Drafts.deleteDraft(draft)),
});

export type ListContainerProps = StateProps & DispatchProps;
export const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
