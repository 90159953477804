import { Action } from "redux";

import { REQUEST, REDUX } from "@haywork/constants";
import { MediaContractSnapShot, ActiveFilter, MediaPartnerCategory, MediaContractStatus } from "@haywork/api/kolibri";

import { PagedResults, StoreHelper, Status } from "../helpers";
import * as ActionType from "./mediapartners.types";

export interface MediapartnersState {
  mediaContracts: PagedResults<MediaContractSnapShot>;
  statuses: MediaContractStatus[];
  categories: MediaPartnerCategory[];
  isFavorite: boolean | null;
}

const INITIAL_STATE: MediapartnersState = {
  mediaContracts: StoreHelper.createInitialPagedResult(),
  statuses: [],
  categories: [],
  isFavorite: null
};

export const mediapartnerReducer = (state: MediapartnersState = INITIAL_STATE, action: Action): MediapartnersState => {
  switch (action.type) {
    case REDUX.MEDIAPARTNERS.SET_MEDIA_CONTRACTS: {
      const mediaContracts = StoreHelper.setPagedResults(<ActionType.MediaContracts> action);

      return {
        ...state,
        mediaContracts
      };
    }
    case REDUX.MEDIAPARTNERS.APPEND_MEDIA_CONTRACTS: {
      const mediaContracts = StoreHelper.updatePagedResults(<ActionType.MediaContracts> action, state.mediaContracts);

      return {
        ...state,
        mediaContracts
      };
    }
    case REDUX.MEDIAPARTNERS.SET_MEDIA_CONTRACTS_STATUS: {
      const mediaContracts = {
        ...state.mediaContracts,
        ...StoreHelper.setObjectStatus(<Status> action)
      };

      return {
        ...state,
        mediaContracts
      };
    }
    case REDUX.MEDIAPARTNERS.RESET_FILTERS: {
      return {
        ...state,
        statuses: [],
        categories: [],
        isFavorite: null
      };
    }
    case REDUX.MEDIAPARTNERS.TOGGLE_IS_ACTIVE: {
      return {
        ...state,
        statuses: (state.statuses.length === 0) ? [MediaContractStatus.Active, MediaContractStatus.PendingActivation, MediaContractStatus.PendingDeactivation] : []
      };
    }
    case REDUX.MEDIAPARTNERS.TOGGLE_IS_FAVORITE: {
      return {
        ...state,
        isFavorite: (!!state.isFavorite) ? null : true
      };
    }
    case REDUX.MEDIAPARTNERS.SET_CATEGORIES: {
      const { categories } = <ActionType.Categories> action;

      return {
        ...state,
        categories
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
