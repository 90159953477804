import {
  Address,
  AddressDetail,
  CountryOption,
  GeoLocation,
  LocationPlaceOption,
  ProjectAssignment,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { PROJECTROUTES } from "@haywork/constants";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
} from "@haywork/modules/form";
import { MapComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AddressRequest } from "@haywork/request";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil, StringUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { isString } from "util";

const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;
const styles = require("./address-detail-form.component.scss");

interface Props {
  projectAssignment: ProjectAssignment;
  currentComponentState: SingleProjectState;
  locationPlaces: LocationPlaceOption[];
  countries: CountryOption[];
  countryIso2: string;
  sublocalities: AddressDetail[];
  addressSearchState: string;
  culture: string;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  updateTabTitle: (title: string, path: string) => any;
  setCaller: (caller: string, path: string) => any;
  searchSublocalities: (id: number) => any;
}
interface State {
  addressResult: Address;
  newAddress: boolean;
  showMap: boolean;
  countrySuggestions: AddressDetail[];
  adminAreaOneSuggestions: AddressDetail[];
  adminAreaTwoSuggestions: AddressDetail[];
  localitySuggestions: AddressDetail[];
  sublocalitySuggestions: AddressDetail[];
  streetSuggestions: AddressDetail[];
  enrichAddress: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AddressDetailFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      addressResult: null,
      newAddress: false,
      showMap: true,
      countrySuggestions: [],
      adminAreaOneSuggestions: [],
      adminAreaTwoSuggestions: [],
      localitySuggestions: [],
      sublocalitySuggestions: [],
      streetSuggestions: [],
      enrichAddress: true,
    };

    // Set form controls
    const { projectAssignment } = this.props;
    const locationPlaces = projectAssignment.locationPlaces || [];
    const enrichedLocationPlaces = locationPlaces
      .map((locationPlace) =>
        this.props.locationPlaces.find((place) => place.value === locationPlace)
      )
      .filter((place) => !!place);

    this.formControls = {
      street: { value: value(projectAssignment, "address.street") },
      houseNumber: { value: value(projectAssignment, "address.houseNumber") },
      houseNumberPostfix: {
        value: value(projectAssignment, "address.houseNumberPostfix"),
      },
      constructionNumber: {
        value: value(projectAssignment, "constructionNumber"),
      },
      postalCode: { value: value(projectAssignment, "address.postalCode") },
      locality: {
        value: value(projectAssignment, "address.locality"),
        onChange: (ref) => {
          this.findSublocalitySuggestions(get(ref, "value.id"));
          this.findStreetSuggestions(get(ref, "value.id"));
        },
      },
      sublocality: { value: value(projectAssignment, "address.sublocality") },
      countryIso2: {
        value: value(projectAssignment, "address.countryIso2"),
        onChange: (ref) => {
          this.findAdminAreaOneSuggestions(ref.value);
        },
      },
      adminAreaLevel1: {
        value: value(projectAssignment, "address.adminAreaLevel1"),
        onChange: (ref) => {
          this.findAdminAreaTwoSuggestions(get(ref, "value.id"));
          this.findLocalitySuggestions(get(ref, "value.id"));
        },
      },
      adminAreaLevel2: {
        value: value(projectAssignment, "address.adminAreaLevel2"),
        onChange: (ref, getValue) => {
          this.findLocalitySuggestions(
            get(getValue("adminAreaLevel1"), "value.id"),
            get(ref, "value.id")
          );
        },
      },
      geoLocation: {
        value: value(projectAssignment, "address.geoLocation"),
      },
      locationPlaces: { value: enrichedLocationPlaces },
    };

    // Bind this
    this.onAddressSearchChangeHandler =
      this.onAddressSearchChangeHandler.bind(this);
    this.onAddressChangeHandler = this.onAddressChangeHandler.bind(this);
    this.findAdminAreaOneSuggestions =
      this.findAdminAreaOneSuggestions.bind(this);
    this.findAdminAreaTwoSuggestions =
      this.findAdminAreaTwoSuggestions.bind(this);
    this.findLocalitySuggestions = this.findLocalitySuggestions.bind(this);
    this.findSublocalitySuggestions =
      this.findSublocalitySuggestions.bind(this);
    this.findStreetSuggestions = this.findStreetSuggestions.bind(this);
    this.onMapChangeHandler = this.onMapChangeHandler.bind(this);
  }
  public componentDidMount() {
    (async () => {
      const countrySuggestions = await AddressRequest.getCountries();
      this.setState({ countrySuggestions });

      this.findAdminAreaOneSuggestions(
        get(this.props.projectAssignment, "address.countryIso2")
      );

      this.findAdminAreaTwoSuggestions(
        get(this.props.projectAssignment, "address.adminAreaLevel1.id")
      );

      this.findLocalitySuggestions(
        get(this.props.projectAssignment, "address.adminAreaLevel1.id"),
        get(this.props.projectAssignment, "address.adminAreaLevel2.id")
      );

      this.findSublocalitySuggestions(
        get(this.props.projectAssignment, "address.locality.id")
      );

      this.findStreetSuggestions(
        get(this.props.projectAssignment, "address.locality.id")
      );
    })();
  }

  public render() {
    const { locationPlaces, projectAssignment } = this.props;
    const {
      enrichAddress,
      adminAreaOneSuggestions,
      adminAreaTwoSuggestions,
      localitySuggestions,
      sublocalitySuggestions,
      countrySuggestions,
      streetSuggestions,
    } = this.state;
    const { address, hideAddress } = projectAssignment;
    const geo: GeoLocation = !!this.state.addressResult
      ? get(this.state.addressResult, "geoLocation")
      : get(address, "geoLocation") || {
          latitude: null,
          longitude: null,
        };

    const mapInfo =
      !!geo.latitude && !!geo.longitude ? "mapInfo" : "locationNotProvided";
    const addressSearchFormControls: FormControls = {
      address: { value: "" },
    };
    const countryIso2 = get(address, "countryIso2") || this.props.countryIso2;

    return (
      <div className="container-fluid">
        <div styleName="address-search">
          <Form
            name="address-search"
            onChange={this.onAddressSearchChangeHandler}
            formControls={addressSearchFormControls}
          >
            <div className="form__row">
              <Input.LocationQueryV2
                name="address"
                countries={this.props.countries}
                countryIso2={countryIso2}
                culture={this.props.culture}
                backgroundColor="#f2f2f2"
                disableHousenumber={true}
              />
            </div>
          </Form>
        </div>

        <div styleName="address-form">
          <Form
            name="address"
            onChange={this.onAddressChangeHandler}
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
          >
            {/* Main address */}
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="streetName">
                  <label htmlFor="streetName">
                    <I18n value="streetName" />
                  </label>
                  <Input.Query
                    name="street"
                    placeholder="addressQueryPlaceholder"
                    values={streetSuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: AddressDetail) => ({
                      value: c,
                      resultString: c.name,
                    })}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!enrichAddress}
                  />
                </div>
              </div>
            </div>

            {/* Secondary address */}
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="postalCode">
                  <label htmlFor="postalCode">
                    <I18n value="postalCode" />
                  </label>
                  <Input.Text name="postalCode" data-cy={"CY-PostalCode"} />
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="locality">
                  <label htmlFor="locality">
                    <I18n value="locality" />
                  </label>
                  <Input.Query
                    name="locality"
                    placeholder="addressQueryPlaceholder"
                    values={localitySuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: AddressDetail) => ({
                      value: c,
                      resultString: c.name,
                    })}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!enrichAddress}
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="sublocality">
                  <label htmlFor="sublocality">
                    <span>
                      <I18n value="sublocality" />
                    </span>
                    <Ui.InfoLink supportLink={SUPPORTURI.SUBLOCALITY} />
                  </label>
                  <Input.Query
                    name="sublocality"
                    placeholder="addressQueryPlaceholder"
                    values={sublocalitySuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: AddressDetail) => ({
                      value: c,
                      resultString: c.name,
                    })}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!sublocalitySuggestions.length}
                  />
                </div>

                <div className="column__spacer" />

                <div className="column" styleName="adminAreaLevel2">
                  <label htmlFor="adminAreaLevel2">
                    <I18n value="adminAreaLevel2" />
                  </label>
                  <Input.Query
                    name="adminAreaLevel2"
                    placeholder="addressQueryPlaceholder"
                    values={adminAreaTwoSuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: AddressDetail) => ({
                      value: c,
                      resultString: c.name,
                    })}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!enrichAddress}
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="adminAreaLevel1">
                  <label htmlFor="adminAreaLevel1">
                    <I18n value="adminAreaLevel1" />
                  </label>
                  <Input.Query
                    name="adminAreaLevel1"
                    placeholder="addressQueryPlaceholder"
                    values={adminAreaOneSuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: AddressDetail) => ({
                      value: c,
                      resultString: c.name,
                    })}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!enrichAddress}
                  />
                </div>

                <div className="column__spacer" />

                <div className="column" styleName="countryIso2">
                  <label htmlFor="countryIso2">
                    <I18n value="countryIso2" />
                  </label>
                  <Input.Query
                    name="countryIso2"
                    placeholder="addressQueryPlaceholder"
                    values={countrySuggestions}
                    matchOn={(q, v: AddressDetail) =>
                      new RegExp(escapeRegExp(q), "gi").test(v.name)
                    }
                    selectedStringValue={(c: string | AddressDetail) => {
                      const resultString = isString(c)
                        ? (
                            this.props.countries.find(
                              (country) => country.iso2CodeValue === c
                            ) || { displayName: "" }
                          ).displayName
                        : c.name;

                      return {
                        value: isString(c)
                          ? c
                          : (
                              this.props.countries.find(
                                (country) => country.displayName === c.name
                              ) || { iso2CodeValue: "NL" }
                            ).iso2CodeValue,
                        resultString,
                      };
                    }}
                    optionValue={(v: AddressDetail, q) => <div>{v.name}</div>}
                    multiple={false}
                    disabled={!enrichAddress}
                  />
                </div>
              </div>
            </div>
          </Form>
          {/* Google maps */}
          <div styleName="address-map" className="hidden-xs hidden-sm">
            <h2>
              <span>
                <I18n value={mapInfo} />
              </span>
              <Ui.InfoLink supportLink={SUPPORTURI.ADDRESSDRAGMARKER} />
            </h2>
            <div styleName="map">
              <MapComponent
                lat={geo.latitude}
                lng={geo.longitude}
                addMarker={!!address}
                canAssignNewLocation
                centerChanged={this.onMapChangeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !!this.formRef &&
      !deepEqual(
        this.props.projectAssignment.locationPlaces,
        nextProps.projectAssignment.locationPlaces
      )
    ) {
      const locationPlaces = nextProps.projectAssignment.locationPlaces || [];
      const enrichedLocationPlaces = locationPlaces
        .map((locationPlace) =>
          nextProps.locationPlaces.find(
            (place) => place.value === locationPlace
          )
        )
        .filter((place) => !!place);

      this.formRef.update({
        locationPlaces: enrichedLocationPlaces,
      });
    }
  }

  private onAddressSearchChangeHandler(values: FormReturnValue) {
    if (this.state.addressResult) {
      this.clearCurrentFormValues();
    }

    const addressResult: Address = values.address;
    this.setState({
      addressResult,
      newAddress: true,
      showMap: true,
    });

    this.formRef.update({
      street: addressResult.street || "",
      houseNumber: addressResult.houseNumber || "",
      houseNumberPostfix: addressResult.houseNumberPostfix || "",
      postalCode: addressResult.postalCode || "",
      locality: addressResult.locality || "",
      sublocality: addressResult.sublocality || "",
      countryIso2: addressResult.countryIso2 || "",
      adminAreaLevel1: addressResult.adminAreaLevel1 || "",
      adminAreaLevel2: addressResult.adminAreaLevel2 || "",
      geoLocation: addressResult.geoLocation || "",
    });
  }

  private clearCurrentFormValues() {
    this.formRef.update({
      street: "",
      houseNumber: "",
      houseNumberPostfix: "",
      postalCode: "",
      locality: "",
      sublocality: "",
      countryIso2: "",
      adminAreaLevel1: "",
      adminAreaLevel2: "",
    });

    this.setState({
      showMap: false,
    });
  }

  private onAddressChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id,
    });

    const address: Address = {
      ...projectAssignment.address,
      adminAreaLevel1: values.adminAreaLevel1,
      adminAreaLevel2: values.adminAreaLevel2,
      countryIso2: values.countryIso2,
      houseNumber: values.houseNumber,
      houseNumberPostfix: values.houseNumberPostfix,
      locality: values.locality,
      sublocality: values.sublocality,
      postalCode: values.postalCode,
      street: values.street,
      geoLocation: values.geoLocation,
    };

    const locationPlaces: LocationPlaceOption[] = values.locationPlaces || [];
    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      address,
      keyNote: values.keyNote,
      keyNr: values.keyNr,
      locationPlaces: locationPlaces.map(({ value }) => value),
      hideAddress: values.hideAddress,
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment,
    };

    this.props.updateProject(newState, pathname);
    if (!!get(values, "locality.id")) {
      this.props.searchSublocalities(get(values, "locality.id"));
    }
  }

  private onMapChangeHandler(values: any) {
    const { projectAssignment, currentComponentState } = this.props;
    const pathname = route(PROJECTROUTES.DETAIL.URI, {
      id: projectAssignment.id,
    });

    const address: Address = {
      ...projectAssignment.address,
      geoLocation: {
        latitude: values.lat,
        longitude: values.lng,
      },
    };

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      address,
    };

    const newState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment,
    };

    this.setState({ addressResult: address });
    this.props.updateProject(newState, pathname);
  }

  private renderQueryLocationPlaceOption(
    value: LocationPlaceOption,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private locationPlaceQueryMatchOn(
    query: string,
    value: LocationPlaceOption
  ): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedLocationPlaceValue(
    value: LocationPlaceOption
  ): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private async findAdminAreaOneSuggestions(countryIso2?: string) {
    if (!countryIso2) return;

    const country = this.props.countries.find(
      (country) => country.iso2CodeValue === countryIso2
    );
    if (!country) return;
    const refCountry = this.state.countrySuggestions.find(
      (refCountry) => refCountry.name === country.displayName
    );
    if (!refCountry) return;
    const adminAreaOneSuggestions =
      await AddressRequest.getAdminAreaOneSuggestions(refCountry.id);

    this.setState({ adminAreaOneSuggestions });
  }

  private async findAdminAreaTwoSuggestions(adminAreaLevel1Id?: number) {
    if (!adminAreaLevel1Id) return;

    const adminAreaTwoSuggestions =
      await AddressRequest.getAdminAreaTwoSuggestions(adminAreaLevel1Id);

    this.setState({ adminAreaTwoSuggestions });
  }

  private async findLocalitySuggestions(
    adminAreaLevel1Id?: number,
    adminAreaLevel1Id2?: number
  ) {
    if (!adminAreaLevel1Id) return;

    const localitySuggestions = await AddressRequest.getLocalitySuggestions(
      adminAreaLevel1Id,
      adminAreaLevel1Id2
    );

    this.setState({ localitySuggestions });
  }

  private async findSublocalitySuggestions(localityId?: number) {
    if (!localityId) return;

    const sublocalitySuggestions =
      await AddressRequest.getSublocalitySuggestions(localityId);

    this.setState({ sublocalitySuggestions });
  }

  private async findStreetSuggestions(localityId?: number) {
    if (!localityId) return;

    const streetSuggestions = await AddressRequest.getStreetSuggestions(
      localityId
    );

    this.setState({ streetSuggestions });
  }

  // private onAddFundaAddress(values: FundaAddressFields) {
  //   const { projectAssignment, currentComponentState } = this.props;
  //   const updatedObjectAssigment: ProjectAssignment = {
  //     ...projectAssignment,
  //     ...values
  //   };
  //   const pathname = route(PROJECTROUTES.DETAIL.URI, {
  //     id: projectAssignment.id
  //   });
  //   const newState = {
  //     ...currentComponentState,
  //     objectAssignment: updatedObjectAssigment
  //   };

  //   this.setState({ fundaModalVisible: false });
  // this.props.updateAssignment(newState, pathname);
  // }

  // private hasFullFundaAddress() {
  //   const { fundaLocality, fundaPostalCode, fundaStreet } = pick(
  //     this.props.projectAssignment,
  //     ["fundaPostalCode", "fundaStreet", "fundaLocality"]
  //   );
  //   return !!fundaLocality && !!fundaPostalCode && !!fundaStreet;
  // }

  // private shouldShowFundaControl() {
  //   const countryIso2 = get(this.props.projectAssignment, "address.countryIso2");

  //   return (
  //     countriesToShowFundaControl.includes(countryIso2) &&
  //     !this.hasFullFundaAddress()
  //   );
  // }
}
