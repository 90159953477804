import * as React from "react";
import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import {
  Input,
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
} from "@haywork/modules/form";
import { AsyncUtil } from "@haywork/util";

interface Props {
  visible: boolean;
  phoneNumber: string;
  onClose: () => void;
  onRelationSelect: (linkedRelation: RelationSnapShot) => void;
}
interface State {
  linkedRelation: RelationSnapShot | null;
}

export class VoipSearchRelationComponent extends React.PureComponent<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      linkedRelation: null,
    };

    this.formControls = {
      voipLinkedRelation: { value: "" },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRelationSelect = this.onRelationSelect.bind(this);
  }

  public render() {
    const { visible, onClose, phoneNumber } = this.props;

    return (
      <Modal visible={visible} onClose={onClose}>
        <ModalHeader close={true} title="voipSearchRelationTitle" />
        <ModalBody>
          <ResourceText
            resourceKey="voipSearchRelationbody"
            values={{ phoneNumber }}
            asHtml={true}
          />
          <Form
            name="voipSearchRelation"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.form = form)}
          >
            <Input.RelationQuery
              valueAsRelationSnapShot={true}
              multiple={false}
              filterByRelationTypes={[
                RelationType.ContactCompany,
                RelationType.ContactPerson,
              ]}
              name="voipLinkedRelation"
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disabled={!this.state.linkedRelation}
            onClick={this.onRelationSelect}
          >
            <ResourceText resourceKey="voipSearchRelationAction" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { voipLinkedRelation } = values;
    this.setState({ linkedRelation: voipLinkedRelation || null });
  }

  private async onRelationSelect() {
    this.props.onRelationSelect(this.state.linkedRelation);
    await AsyncUtil.wait(500);
    this.form.update({ voipLinkedRelation: "" });
    this.setState({ linkedRelation: null });
  }
}
