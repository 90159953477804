import { listReducer, ListState, ListActions } from "./list";
import { singleReducer, SingleState, SingleActions } from "./single";
import { combineReducers } from "redux";
import {
  searchLocationsReducer,
  SearchLocationsActions,
  SearchLocationsState,
} from "./search-locations";
import { mainReducer, MainActions, MainState } from "./main";

export const mlsReducer = combineReducers({
  list: listReducer,
  searchLocations: searchLocationsReducer,
  single: singleReducer,
  main: mainReducer,
});

export type MLSState = {
  list: ListState;
  searchLocations: SearchLocationsState;
  single: SingleState;
  main: MainState;
};

export const MLSActions = {
  List: ListActions,
  SearchLocations: SearchLocationsActions,
  Single: SingleActions,
  Main: MainActions,
};
