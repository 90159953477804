import { Action } from "redux";

import { REDUX } from "@haywork/constants";
import { PagedResults, StoreHelper, Status } from "@haywork/stores/helpers";
import { Provider } from "@haywork/api/mail";

import * as ActionType from "./providers.types";

export interface ProvidersState {
  providers: PagedResults<Provider>;
}

const INITIAL_STATE: ProvidersState = {
  providers: StoreHelper.createInitialPagedResult()
};

export const providersReducer = (state: ProvidersState = INITIAL_STATE, action: Action): ProvidersState => {
  switch (action.type) {
    case REDUX.EMAILV2.SET_PROVIDERS_STATUS: {
      const providers = {
        ...state.providers,
        ...StoreHelper.setObjectStatus(<Status> action)
      };

      return {
        ...state,
        providers
      };
    }
    case REDUX.EMAILV2.SET_PROVIDERS: {
      const providers = StoreHelper.setPagedResults(<ActionType.ProvidersResponse> action);

      return {
        ...state,
        providers
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
