import {
  ActiveFilter,
  RelationOrderByField,
  RelationSnapShot,
  RelationsStatistics,
  SortOrder,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { RelationOrder } from ".";
import { ActionType, ActionTypes } from "./actions";
import { FilterType } from "@haywork/enum/list-filter-types";

export type ListState = {
  relations: RelationSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  order: RelationOrder;
  scrollOffset: number;
  statistics: RelationsStatistics;
};

const INITIAL: ListState = {
  relations: [],
  totalCount: 0,
  order: {
    sortOrder: SortOrder.Ascending,
    sortColumn: RelationOrderByField.DisplayName,
  },
  statistics: {
    localities: [],
  },
  filters: {
    relationTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "relationTypes",
    },
    localities: {
      type: FilterType.Select,
      value: undefined,
      emptyValue: undefined,
      prefix: "localities",
    },
    activeInactive: {
      type: FilterType.Array,
      value: [ActiveFilter.ActiveOnly],
      emptyValue: [],
      prefix: "activeInactive",
    },
    relationGroupIds: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "relationGroupIds",
    },
  },
  scrollOffset: 0,
};

export const listReducer = (state = INITIAL, action: ActionTypes) => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, statistics, startIndex } = action;

      let relations = [...state.relations];
      if (!relations.length) {
        relations = new Array(totalCount).fill(null);
      }

      for (let i = 0; i < items.length; i++) {
        relations[i + startIndex] = items[i];
      }

      return {
        ...state,
        relations,
        totalCount,
        statistics: !statistics ? state.statistics : statistics,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        relations: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        relations: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;

      return {
        ...state,
        relations: state.relations.map((relation) =>
          !!relation && relation.id === snapshot.id ? snapshot : relation
        ),
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
