import {
  RealEstateGroup,
  SearchAssignmentSnapShot,
} from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface RelationSearchAssignmentItemComponentProps {
  searchAssignment: SearchAssignmentSnapShot;
  zebra: boolean;
  onNavigateToSearchAssignment: (url: string) => void;
}
interface RelationSearchAssignmentItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class RelationSearchAssignmentItemComponent extends React.Component<
  RelationSearchAssignmentItemComponentProps,
  RelationSearchAssignmentItemComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const { searchAssignment, zebra } = this.props;
    const itemStyle = classNames("item", { zebra });

    return (
      <div styleName={itemStyle} onClick={this.onClickHandler}>
        <div styleName="item__type">
          {this.renderRealEstateGroup(searchAssignment.realEstateGroup)}
        </div>
        <div styleName="item__is-paid">
          {!!searchAssignment.isPaid && <i className="fal fa-check" />}
        </div>
        <div styleName="item__offertype">
          <ResourceText
            masterKey="offerTypeOptions"
            resourceKey={searchAssignment.offerType.toString()}
          />
        </div>
        <div styleName="item__date">
          {searchAssignment.dateAssignment && (
            <FormattedDate
              value={searchAssignment.dateAssignment}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          )}
        </div>
        <div styleName="item__frequency">
          <ResourceText
            masterKey="matchMailPeriodOptions"
            resourceKey={searchAssignment.matchMailPeriod.toString()}
          />
        </div>
      </div>
    );
  }

  private renderRealEstateGroup(realEstateGroup: RealEstateGroup) {
    switch (realEstateGroup) {
      case RealEstateGroup.Residential:
        return <ResourceText resourceKey="forResidential" />;
      case RealEstateGroup.Commercial:
        return <ResourceText resourceKey="forCommercial" />;
      case RealEstateGroup.Agricultural:
        return <ResourceText resourceKey="forAgricultural" />;
      default:
        break;
    }
  }

  private onClickHandler() {
    const { id } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onNavigateToSearchAssignment(url);
  }
}
