import { SearchAssignment } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentDetailMatchmailComponent,
  SearchAssignmentDetailMatchmailComponentProps
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  searchAssignment: SearchAssignment;
  matchMails: any[];
  matchMailsState: string;
  matchMailsTotal: number;
  canLoadMore: boolean;
}
interface DispatchProps {
  navigate: (url: string) => void;
  getSearchAssignmentMatchMails: (
    searchAssignmentId: string,
    init?: boolean
  ) => void;
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) => void;
}

const route = RouteUtil.mapStaticRouteValues;

const mapStateToProps = <StateProps, AssignmentDetailMatchmailComponentProps>(
  state: AppState
) => {
  const {
    searchAssignment,
    matchMails,
    matchMailsPage,
    matchMailsPageCount,
    matchMailsState,
    matchMailsTotal
  } = state.searchAssignment.single;

  return {
    searchAssignment,
    matchMails,
    matchMailsState,
    matchMailsTotal,
    canLoadMore: matchMailsPage < matchMailsPageCount
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AssignmentDetailMatchmailComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  navigate: (url: string) => dispatch(push(url)),
  getSearchAssignmentMatchMails: (searchAssignmentId: string, init?: boolean) =>
    dispatch(
      SearchAssignmentThunks.getSearchAssignmentMatchMails(
        searchAssignmentId,
        init
      )
    ),
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) =>
    dispatch(
      SearchAssignmentThunks.getMatchMailProperties(
        objectAssignmentIds,
        searchAssignmentId
      )
    )
});

export type SearchAssignmentDetailMatchmailContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentDetailMatchmailContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentDetailMatchmailComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentDetailMatchmailComponent);
