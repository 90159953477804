import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { LiveDataContainerProps } from "./live-data.container";
import I18n from "@haywork/components/i18n";
import classNames from "classnames";

const styles = require("./style.scss");

export type LiveDataComponentProps = {};
type Props = LiveDataComponentProps & LiveDataContainerProps;

export const LiveDataComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      kolibriStatus,
      eventCenterStatus,
      mailStatus,
      voipStatus,
      showEmailStatus,
    }) => {
      return (
        <div styleName="live-data">
          <div styleName={classNames("connection", kolibriStatus)}>
            <div styleName="name">
              <I18n value="health.liveData.title.kolibri" />
            </div>
            <div styleName="status">
              <I18n value={`health.liveData.status.${kolibriStatus}`} />
            </div>
          </div>

          <div styleName={classNames("connection", eventCenterStatus)}>
            <div styleName="name">
              <I18n value="health.liveData.title.eventCenter" />
            </div>
            <div styleName="status">
              <I18n value={`health.liveData.status.${eventCenterStatus}`} />
            </div>
          </div>

          {showEmailStatus && (
            <div styleName={classNames("connection", mailStatus)}>
              <div styleName="name">
                <I18n value="health.liveData.title.mail" />
              </div>
              <div styleName="status">
                <I18n value={`health.liveData.status.${mailStatus}`} />
              </div>
            </div>
          )}

          <div styleName={classNames("connection", voipStatus)}>
            <div styleName="name">
              <I18n value="health.liveData.title.voip" />
            </div>
            <div styleName="status">
              <I18n value={`health.liveData.status.${voipStatus}`} />
            </div>
          </div>
        </div>
      );
    }
  )
);
