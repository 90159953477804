import { Action } from "redux";

import { REDUX, REQUEST } from "@haywork/constants";
import * as ActionType from "./relation.types";
import {
  AssignmentSnapShot
} from "@haywork/api/kolibri";

export interface RelationDetailAssignmentState {
  relationAssignments: AssignmentSnapShot[];
  relationAssignmentState: string;
  relationAssignmentPage: number;
  relationAssignmentPageCount: number;
}

const INITIAL_STATE: RelationDetailAssignmentState = {
  relationAssignments: null,
  relationAssignmentState: null,
  relationAssignmentPage: 0,
  relationAssignmentPageCount: 0
};

export const relationDetailAssignmentReducer = (state: RelationDetailAssignmentState = INITIAL_STATE, action: Action): RelationDetailAssignmentState => {
  switch (action.type) {
    case REDUX.RELATION.SET_RELATION_ASSIGNMENT_STATE: {
      const { relationAssignmentState } = <ActionType.RelationAssignmentState> action;

      return { ...state, relationAssignmentState };
    }
    case REDUX.RELATION.SEARCH_ASSIGNMENTS: {
      const { totalResults, results, resultCount } = <ActionType.AssignmentsResponse> action;
      const relationAssignmentPageCount = Math.round(totalResults / resultCount);

      return {
        ...state,
        relationAssignments: results,
        relationAssignmentPage: 1,
        relationAssignmentPageCount
      };
    }
    case REDUX.RELATION.APPEND_ASSIGNMENTS: {
      const { resultCount, results, totalResults } = <ActionType.AssignmentsResponse> action;
      const relationAssignmentPageCount = Math.round(totalResults / resultCount);
      const relationAssignmentPage = state.relationAssignmentPage + 1;

      return {
        ...state,
        relationAssignments: [...state.relationAssignments, ...results],
        relationAssignmentPage,
        relationAssignmentPageCount
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
