import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  Form,
  FormControls,
  Input,
  FormReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import {
  ConditionOption,
  EnergyClassOption,
  FurnishingOption,
  OrientationOption,
  LocationPlaceOption,
  BuildingTypeOption,
  SearchAssignment,
  SearchAssignmentFacilityOption,
} from "@haywork/api/kolibri";
import { FormControlUtil } from "@haywork/util";

const styles = require("../edit-what.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

type Props = {
  searchAssignment: SearchAssignment;
  conditionOptions: ConditionOption[];
  energyClassOptions: EnergyClassOption[];
  furnishingOptions: FurnishingOption[];
  orientations: OrientationOption[];
  locationPlaces: LocationPlaceOption[];
  buildingTypeOptions: BuildingTypeOption[];
  searchAssignmentFacilityOptions: SearchAssignmentFacilityOption[];
  onChange: (values: FormReturnValue) => void;
};

type State = {
  loading?: boolean;
};

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentImportantResidentialComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);

    this.formControls = {
      minNrOfRooms: {
        value: value(this.props.searchAssignment, "minNrOfRooms"),
      },
      maxNrOfRooms: {
        value: value(this.props.searchAssignment, "maxNrOfRooms"),
      },
      minNrOfBedrooms: {
        value: value(this.props.searchAssignment, "minNrOfBedrooms"),
      },
      maxNrOfBedrooms: {
        value: value(this.props.searchAssignment, "maxNrOfBedrooms"),
      },
      minNrOfBathrooms: {
        value: value(this.props.searchAssignment, "minNrOfBathrooms"),
      },
      maxNrOfBathrooms: {
        value: value(this.props.searchAssignment, "maxNrOfBathrooms"),
      },
      minLivableArea: {
        value: value(this.props.searchAssignment, "minLivableArea"),
      },
      maxLivableArea: {
        value: value(this.props.searchAssignment, "maxLivableArea"),
      },
      minConditionInside: {
        value: value(this.props.searchAssignment, "minConditionInside"),
      },
      maxConditionInside: {
        value: value(this.props.searchAssignment, "maxConditionInside"),
      },
      minEnergyClass: {
        value: value(this.props.searchAssignment, "minEnergyClass"),
      },
      maxEnergyClass: {
        value: value(this.props.searchAssignment, "maxEnergyClass"),
      },
      furnishing: { value: value(this.props.searchAssignment, "furnishing") },

      constructionType: {
        value: value(this.props.searchAssignment, "constructionType"),
      },
      constructionYearBegin: {
        value: value(this.props.searchAssignment, "constructionYearBegin"),
      },
      constructionYearEnd: {
        value: value(this.props.searchAssignment, "constructionYearEnd"),
      },
      orientationMainGarden: {
        value: value(this.props.searchAssignment, "orientationMainGarden"),
      },
      minGardenArea: {
        value: value(this.props.searchAssignment, "minGardenArea"),
      },
      maxGardenArea: {
        value: value(this.props.searchAssignment, "maxGardenArea"),
      },
      minConditionOutside: {
        value: value(this.props.searchAssignment, "minConditionOutside"),
      },
      maxConditionOutside: {
        value: value(this.props.searchAssignment, "maxConditionOutside"),
      },
      minBuildableArea: {
        value: value(this.props.searchAssignment, "minBuildableArea"),
      },
      maxBuildableArea: {
        value: value(this.props.searchAssignment, "maxBuildableArea"),
      },
      minParkingCapacity: {
        value: value(this.props.searchAssignment, "minParkingCapacity"),
      },
      maxParkingCapacity: {
        value: value(this.props.searchAssignment, "maxParkingCapacity"),
      },

      facilities: {
        value: value(this.props.searchAssignment, "facilities", []),
      },
      locationPlaces: {
        value: value(this.props.searchAssignment, "locationPlaces", []),
      },
      mustBeQualifiedForSeniors: {
        value: value(this.props.searchAssignment, "mustBeQualifiedForSeniors"),
      },
      mustBeRecentlyModified: {
        value: value(this.props.searchAssignment, "mustBeRecentlyModified"),
      },
      mustBeNewOnMarket: {
        value: value(this.props.searchAssignment, "mustBeNewOnMarket"),
      },
      mustBeTopper: {
        value: value(this.props.searchAssignment, "mustBeTopper"),
      },
      mustBeSpecial: {
        value: value(this.props.searchAssignment, "mustBeSpecial"),
      },
      mustHaveOpenHouse: {
        value: value(this.props.searchAssignment, "mustHaveOpenHouse"),
      },
      mustBeOnTopFloor: {
        value: value(this.props.searchAssignment, "mustBeOnTopFloor"),
      },
      mustBeOnGroundFloor: {
        value: value(this.props.searchAssignment, "mustBeOnGroundFloor"),
      },
    };
  }

  public render() {
    return (
      <Form
        name="important-residential"
        formControls={this.formControls}
        onChange={this.props.onChange}
      >
        <div styleName="important">
          <div styleName="important__inside">
            <h2>
              <ResourceText resourceKey="inside" />
            </h2>

            {/* Number of rooms */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minNrOfRooms">
                <ResourceText resourceKey="numberOfRooms" />
              </label>
              <div styleName="min-max">
                <Input.Number name="minNrOfRooms" max={100} />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="maxNrOfRooms" max={100} />
              </div>
            </div>

            {/* Number of bedrooms */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minNrOfBedrooms">
                <ResourceText resourceKey="numberOfBedrooms" />
              </label>
              <div styleName="min-max">
                <Input.Number name="minNrOfBedrooms" max={100} />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="maxNrOfBedrooms" max={100} />
              </div>
            </div>

            {/* Number of bathrooms */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minNrOfBathrooms">
                <ResourceText resourceKey="numberOfBathrooms" />
              </label>
              <div styleName="min-max">
                <Input.Number name="minNrOfBathrooms" max={100} />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="maxNrOfBathrooms" max={100} />
              </div>
            </div>

            {/* Living space */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minLivableArea">
                <ResourceText resourceKey="livingSpace" />
              </label>
              <div styleName="min-max">
                <div className="input__helper">
                  <Input.Number name="minLivableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <div className="input__helper">
                  <Input.Number name="maxLivableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>

            {/* Maintenance inside */}
            <div className="form__row" styleName="inline-value truncate">
              <label htmlFor="minConditionInside">
                <ResourceText resourceKey="conditionInside" />
              </label>
              <div styleName="min-max">
                <Input.NewSelect
                  name="minConditionInside"
                  values={this.props.conditionOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.NewSelect
                  name="maxConditionInside"
                  values={this.props.conditionOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>

            {/* Energyclass */}
            <div className="form__row" styleName="inline-value truncate">
              <label htmlFor="minEnergyClass">
                <ResourceText resourceKey="energyClass" />
              </label>
              <div styleName="min-max">
                <Input.NewSelect
                  name="minEnergyClass"
                  values={this.props.energyClassOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />

                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.NewSelect
                  name="maxEnergyClass"
                  values={this.props.energyClassOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>

            {/* Furnishing */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="furnishing">
                <ResourceText resourceKey="furnishing" />
              </label>
              <Input.NewSelect
                name="furnishing"
                values={this.props.furnishingOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
          <div styleName="important__outside">
            <h2>
              <ResourceText resourceKey="around" />
            </h2>

            {/* Building type */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="constructionType">
                <ResourceText resourceKey="constructionType" />
              </label>
              <Input.RadioGroup
                name="constructionType"
                asButtonList
                canUnselect
              >
                {this.props.buildingTypeOptions.map(
                  (buildingTypeOption, idx) => (
                    <Input.Radio
                      value={buildingTypeOption.value}
                      label={buildingTypeOption.displayName}
                      key={idx}
                    />
                  )
                )}
              </Input.RadioGroup>
            </div>

            {/* Construction year */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="constructionYearBegin">
                <ResourceText resourceKey="constructionYear" />
              </label>
              <div styleName="min-max">
                <Input.Number name="constructionYearBegin" />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="constructionYearEnd" />
              </div>
            </div>

            {/* Garden orientation */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="orientationMainGarden">
                <ResourceText resourceKey="orientationMainGarden" />
              </label>
              <Input.NewSelect
                name="orientationMainGarden"
                values={this.props.orientations}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>

            {/* Garden space */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minGardenArea">
                <ResourceText resourceKey="gardenSpace" />
              </label>
              <div styleName="min-max">
                <div className="input__helper">
                  <Input.Number name="minGardenArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <div className="input__helper">
                  <Input.Number name="maxGardenArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>

            {/* Maintenance outside */}
            <div className="form__row" styleName="inline-value truncate">
              <label htmlFor="minConditionOutside">
                <ResourceText resourceKey="conditionOutside" />
              </label>
              <div styleName="min-max">
                <Input.NewSelect
                  name="minConditionOutside"
                  values={this.props.conditionOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.NewSelect
                  name="maxConditionOutside"
                  values={this.props.conditionOptions}
                  addEmptyOption
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>

            {/* Parcel surface */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minBuildableArea">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div styleName="min-max">
                <div className="input__helper">
                  <Input.Number name="minBuildableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <div className="input__helper">
                  <Input.Number name="maxBuildableArea" pretty />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml />
                  </div>
                </div>
              </div>
            </div>

            {/* Number of parking spaces */}
            <div className="form__row" styleName="inline-value">
              <label htmlFor="minParkingCapacity">
                <ResourceText resourceKey="parkingSpaces" />
              </label>
              <div styleName="min-max">
                <Input.Number name="minParkingCapacity" max={100} />
                <div styleName="label">
                  <ResourceText resourceKey="untill" />
                </div>
                <Input.Number name="maxParkingCapacity" max={100} />
              </div>
            </div>
          </div>
        </div>
        <div styleName="important">
          <div styleName="important__general">
            <h2>
              <ResourceText resourceKey="insideAndAround" />
            </h2>

            <div styleName="inner">
              <div styleName="important__inside">
                {/* Facilities */}
                <div className="form__row" styleName="inline-value">
                  <label htmlFor="facilities">
                    <ResourceText resourceKey="facilities" />
                  </label>
                  {/* TODO replace asap */}
                  <Input.QueryLegacy
                    name="facilities"
                    placeholder="facilitiesPlaceholder"
                    values={this.props.searchAssignmentFacilityOptions}
                    displayPath="displayName"
                    valuePath="value"
                    matchPath="displayName"
                    asSelect
                    multiple
                    isLoading={this.state.loading}
                  />
                </div>
              </div>

              <div styleName="important__outside">
                {/* Location places */}
                <div className="form__row" styleName="inline-value">
                  <label htmlFor="locationPlaces">
                    <ResourceText resourceKey="locationPlaces" />
                  </label>
                  {/* TODO replace asap */}
                  <Input.QueryLegacy
                    name="locationPlaces"
                    placeholder="locationPlacesPlaceholder"
                    values={this.props.locationPlaces}
                    displayPath="displayName"
                    valuePath="value"
                    matchPath="displayName"
                    asSelect
                    multiple
                    isLoading={this.state.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div styleName="important">
          <div styleName="important__general">
            <h2>
              <ResourceText resourceKey="searchAssignment.other" />
            </h2>

            <div styleName="switches">
              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeQualifiedForSeniors"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeQualifiedForSeniors"
                  />
                </div>
              </div>

              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeRecentlyModified"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeRecentlyModified"
                  />
                </div>
              </div>

              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeNewOnMarket"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeNewOnMarket"
                  />
                </div>
              </div>
            </div>

            <div styleName="switches">
              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeTopper"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeTopper"
                  />
                </div>
              </div>

              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeSpecial"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeSpecial"
                  />
                </div>
              </div>

              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustHaveOpenHouse"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustHaveOpenHouse"
                  />
                </div>
              </div>
            </div>

            <div styleName="switches">
              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeOnTopFloor"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeOnTopFloor"
                  />
                </div>
              </div>

              <div styleName="column">
                <div className="form__row" styleName="inline-value">
                  <Input.Switch
                    name="mustBeOnGroundFloor"
                    on={true}
                    off={undefined}
                    label="searchAssignment.mustBeOnGroundFloor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
