import { CreateAccountRequest } from "@haywork/api/mail";
import { KEYCODE } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  accountData: CreateAccountRequest;
  onChange: (values: FormReturnValue) => void;
  onNext: () => void;
  onBack: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class IncomingDataIMAPComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.formControls = {
      imapHost: {
        value: value(this.props.accountData, "imapHost"),
        validators: [Validators.required()],
      },
      imapPort: { value: value(this.props.accountData, "imapPort", 143) },
      imapUsername: {
        value: value(this.props.accountData, "imapUsername"),
        validators: [Validators.required()],
      },
      imapPassword: {
        value: value(this.props.accountData, "imapPassword"),
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    return (
      <div styleName="imap-data">
        <div styleName="imap-data__info">
          <i className="fal fa-info-circle" />
          <ResourceText resourceKey="incomingEmailIMAPInfo" />
        </div>
        <div styleName="imap-data__body">
          <h2>
            <ResourceText resourceKey="incomingEmailIMAPTitle" />
          </h2>
          <Form
            name="incoming-imap"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onChange={this.props.onChange}
            onSubmit={this.props.onNext}
          >
            {/* imapHost & imapPort */}
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column important">
                  <label htmlFor="imapHost">
                    <ResourceText resourceKey="emailImapHost" />
                  </label>
                  <Input.Text
                    name="imapHost"
                    placeholder="emailImapHostPlaceholder"
                  />
                </div>

                <div className="column__spacer" />

                <div className="column un-important">
                  <label htmlFor="imapPort">
                    <ResourceText resourceKey="emailImapPort" />
                  </label>
                  <Input.Number
                    name="imapPort"
                    placeholder="emailImapPortPlaceholder"
                  />
                </div>
              </div>
            </div>

            {/* imapUsername & imapPassword */}
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column">
                  <label htmlFor="imapUsername">
                    <ResourceText resourceKey="emailImapUsername" />
                  </label>
                  <Input.Text
                    name="imapUsername"
                    placeholder="emailImapUsernamePlaceholder"
                  />
                </div>

                <div className="column__spacer" />

                <div className="column">
                  <label htmlFor="imapPassword">
                    <ResourceText resourceKey="emailImapPassword" />
                  </label>
                  <Input.Password
                    name="imapPassword"
                    placeholder="emailImapPasswordPlaceholder"
                    canToggle
                    onKeyDown={this.onKeyDownHandler}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div styleName="footer">
          <div styleName="left">
            <button
              type="button"
              className="btn btn-gray"
              onClick={this.props.onBack}
            >
              <ResourceText resourceKey="back" />
            </button>
          </div>
          <div styleName="right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSubmitHandler}
            >
              <ResourceText resourceKey="next" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitHandler() {
    this.formRef.submit();
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    if (keyCode === KEYCODE.ENTER) {
      this.onSubmitHandler();
    }
  }
}
