import { ParseRequest } from "@haywork/services";
import { Dispatch } from ".";
import { AppState } from "@haywork/stores";
import {
  BidsClient,
  Bid,
  BidOrderByField,
  ActiveFilter,
  SortOrder,
  BidType,
  LinkedRelation,
  LinkedEmployee,
  AssignmentType,
  BidSnapShot
} from "@haywork/api/kolibri";
import { BackOfficeUtil } from "@haywork/util";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";

const parseRequest = new ParseRequest();

const createNewBid = (
  assignmentId: string,
  type: BidType,
  linkedRelations?: LinkedRelation[],
  linkedEmployee?: LinkedEmployee
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      let bid = await parseRequest.response(
        Bids.defineNew({ assignmentId }, realEstateAgencyId).then(
          (response) => response.bid
        )
      );

      bid = {
        ...bid,
        type,
        linkedRelations,
        linkedEmployee
      };

      return bid;
    } catch (error) {
      throw error;
    }
  };
};

const saveBid = (bid: Bid) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new BidsClient(host);

      await parseRequest.response(client.save({ bid }, realEstateAgencyId));
    } catch (error) {
      throw error;
    }
  };
};

const getBids = (
  assignmentId: string,
  orderBy: BidOrderByField,
  take: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      const bids = await parseRequest.response(
        Bids.search(
          {
            assignmentIds: [assignmentId],
            orderBy,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Descending,
            skip: 0,
            take
          },
          realEstateAgencyId
        )
      );

      return {
        results: bids.results || [],
        total: bids.totalResults || 0
      };
    } catch (error) {
      throw error;
    }
  };
};

const readBid = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      return await parseRequest.response(
        Bids.read(id, realEstateAgencyId).then((response) => response.bid)
      );
    } catch (error) {
      throw error;
    }
  };
};

const acceptBid = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      return await parseRequest.response(
        Bids.accept({ id }, realEstateAgencyId).then((response) => response.bid)
      );
    } catch (error) {
      throw error;
    }
  };
};

const rejectBid = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      return await parseRequest.response(
        Bids.deny({ id }, realEstateAgencyId).then((response) => response.bid)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getLatestBidForAssignments = (assignmentIds: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      return await parseRequest.response(
        Bids.search(
          {
            assignmentIds,
            orderBy: BidOrderByField.BidDateTime,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Descending,
            skip: 0,
            take: 1
          },
          realEstateAgencyId
        ).then((response) => response.results || [])
      );
    } catch (error) {
      throw error;
    }
  };
};

const createCounterOffer = (
  originalBid: Bid,
  linkedRelations?: LinkedRelation[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      const { linkedAssignment, type: originalType } = originalBid;
      if (!linkedAssignment) return;
      const { id: assignmentId } = linkedAssignment;
      const type =
        originalType === BidType.Outgoing ? BidType.Incoming : BidType.Outgoing;
      const linkedEmployee =
        type === BidType.Outgoing
          ? BackOfficeUtil.getLinkedEmployee(state.account)
          : undefined;

      let bid = await parseRequest.response(
        Bids.defineNew({ assignmentId }, realEstateAgencyId).then(
          (response) => response.bid
        )
      );

      bid = {
        ...bid,
        type,
        linkedBid: {
          id: originalBid.id,
          bidDateTime: originalBid.bidDateTime,
          status: originalBid.status
        },
        linkedEmployee,
        linkedRelations
      };

      return bid;
    } catch (error) {
      throw error;
    }
  };
};

const getAllBids = (assignmentId: string, orderBy: BidOrderByField) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const take = 100;

    const Bids = new BidsClient(host);

    try {
      const response = await parseRequest.response(
        Bids.search(
          {
            assignmentIds: [assignmentId],
            orderBy,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Descending,
            skip: 0,
            take
          },
          realEstateAgencyId
        )
      );

      let bids = response.results || [];

      if (response.totalResults > take) {
        const diff = response.totalResults - take;
        const count = Math.ceil(diff / take);
        const promises: Promise<BidSnapShot[]>[] = [];

        for (let i = 1; i <= count; i++) {
          promises.push(
            Bids.search(
              {
                assignmentIds: [assignmentId],
                orderBy,
                filterByActive: ActiveFilter.ActiveOnly,
                order: SortOrder.Descending,
                skip: i * take,
                take
              },
              realEstateAgencyId
            ).then((response) => response.results)
          );
        }

        const results = await Promise.all(promises);
        const snapshots = results.reduce((state, results) => {
          state = [...state, ...results];
          return state;
        }, []);

        bids = [...bids, ...snapshots];
      }

      return bids;
    } catch (error) {
      throw error;
    }
  };
};

const deleteBid = (id: string, undeleteCallback?: () => void) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      await parseRequest.response(Bids.delete(id, realEstateAgencyId));

      dispatch(
        SnackbarActions.addToast({
          value: "bid.toast.deleted",
          callback: () => {
            dispatch(unDeleteBid(id, undeleteCallback));
          },
          callbackLabel: "bid.toast.action.undelete",
          icon: "trash-alt"
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteBid = (id: string, undeleteCallback?: () => void) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Bids = new BidsClient(host);

    try {
      await parseRequest.response(Bids.undelete({ id }, realEstateAgencyId));

      if (undeleteCallback) undeleteCallback();
    } catch (error) {
      throw error;
    }
  };
};

export const BidThunk = {
  createNewBid,
  saveBid,
  getBids,
  readBid,
  acceptBid,
  rejectBid,
  getLatestBidForAssignments,
  createCounterOffer,
  getAllBids,
  deleteBid,
  unDeleteBid
};
