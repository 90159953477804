import {
  AppClient,
  AppClientSnapShot,
  AppClientTranslation,
} from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AppXchangeAboutComponent,
  AppXchangeAboutComponentProps,
} from "@haywork/modules/app-xchange";
import { AppState, LayoutActions } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { recommendations, translation } from "./selectors";
import { PhotoBlob } from "@haywork/api/kolibri";

interface StateProps {
  client: AppClient;
  translation: AppClientTranslation;
  partners: AppClientSnapShot[];
}
interface DispatchProps {
  showLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
  getRecommendations: () => Promise<void>;
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AppXchangeAboutComponentProps,
  AppState
> = (state) => {
  const { client } = state.authorization.singleAppClient;

  return {
    client,
    translation: translation(state),
    partners: recommendations(client.id)(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AppXchangeAboutComponentProps
> = (dispatch) => ({
  showLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
  getRecommendations: () =>
    dispatch(AuthorizationThunks.AppClient.getRecommendations()),
  navigate: (path: string) => dispatch(push(path)),
});

export type AppXchangeAboutContainerProps = StateProps & DispatchProps;
export const AppXchangeAboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppXchangeAboutComponent);
