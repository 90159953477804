import { AssignmentSnapShot, RelationSnapShot } from "@haywork/api/kolibri";
import ContextMenu, {
  ContextMenuPosition,
} from "@haywork/components/context-menu";
import AssignmentInfo from "@haywork/components/ui/assignment-info";
import RelationInfo from "@haywork/components/ui/relation-info";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  type: "relation" | "assignment";
  displayName: string;
  id: string;
  item: any;
  onRemove: (id: string) => void;
  onClick: (id: string) => void;
  emailCallback?: (relation: RelationSnapShot) => void;
}
export const FormSuggestionPillComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      displayName,
      id,
      onRemove,
      onClick,
      emailCallback,
      item,
      type,
    }: Props) => {
      const [hover, toggleHover] = useState(false);
      const contextMenuParentRef = useRef<HTMLDivElement>();
      const contextMenuParentRef1 = useRef<HTMLDivElement>();
      const timeoutRef = useRef<any>(null);

      const onClickHandler = () => {
        onClick(id);
      };

      const assignment = useMemo(
        () =>
          type === "assignment" ? (item as AssignmentSnapShot) : undefined,
        [item, type]
      );

      const onRemoveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onRemove(id);
      };

      const mouseEnterCallback = useCallback(() => {
        if (!!timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        toggleHover(true);
      }, [toggleHover]);

      const mouseLeaveCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          toggleHover(false);
        }, 50);
      }, [toggleHover]);

      return (
        <>
          <div
            styleName="pill"
            onClick={onClickHandler}
            onMouseEnter={mouseEnterCallback}
            onMouseLeave={mouseLeaveCallback}
            ref={contextMenuParentRef}
          >
            <span>{displayName}</span>
            <div styleName="pill__delete" onClick={onRemoveHandler}>
              <i className="far fa-times" />
            </div>
          </div>

          <FeatureSwitch feature="RELATION_INFO">
            <ContextMenu
              visible={hover}
              parent={contextMenuParentRef}
              width={500}
              position={ContextMenuPosition.CenterCenter}
              push={{ top: 6 }}
            >
              <div
                styleName="context-menu"
                onMouseEnter={mouseEnterCallback}
                onMouseLeave={mouseLeaveCallback}
              >
                {type === "relation" && (
                  <RelationInfo
                    item={item}
                    visible={hover}
                    emailCallback={emailCallback ? emailCallback : undefined}
                  />
                )}
                {type === "assignment" && (
                  <AssignmentInfo
                    item={assignment}
                    visible={hover}
                    emailCallback={emailCallback ? emailCallback : undefined}
                  />
                )}
              </div>
            </ContextMenu>
          </FeatureSwitch>
        </>
      );
    }
  )
);
