import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "@haywork/modules/shared";
import { RequestStatus } from "@haywork/enum";
import Time from "../widget-time";

const styles = require("./style.scss");

interface Props {
  phoneNumber: string | null;
  callStartedOn: number | null;
  callEndedOn: number | null;
  linkedRelationSearchStatus: RequestStatus;
}

export const VoipWidgetBodyUnknownComponent = CSSModules(styles, {
  allowMultiple: true
})(
  ({
    phoneNumber,
    linkedRelationSearchStatus,
    callEndedOn,
    callStartedOn
  }: Props) => {
    return (
      <div styleName="body">
        <div styleName="body__avatar">
          <i className="fal fa-user" />
        </div>
        <div styleName="body__meta">
          <div styleName="phone-number">
            {!phoneNumber ? (
              <ResourceText resourceKey="unknownCaller" />
            ) : (
              phoneNumber
            )}
          </div>
          {linkedRelationSearchStatus === RequestStatus.Pending && (
            <div styleName="loading">
              <ResourceText resourceKey="voip.SearchingLinkedRelation" />
            </div>
          )}
        </div>
        <Time callStartedOn={callStartedOn} callEndedOn={callEndedOn} />
      </div>
    );
  }
);
