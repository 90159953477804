import I18n from "@haywork/components/i18n";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import sortBy from "lodash-es/sortBy";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { BaseFilter } from "../base/base-filter.component";

const styles = require("./checkbox.component.scss");

export interface MappedCheckboxValue {
  label: string;
  value: any;
}
interface Props {
  values: MappedCheckboxValue[];
  title: string;
  subtitle?: string | React.ReactElement<any> | React.ReactElement<any>[];
  canToggle?: boolean;
  visible?: boolean;
  mustHaveOneValue?: boolean;
  disabled?: boolean;
  hideCheckedItems?: boolean;
  inline?: boolean;
  disableSort?: boolean;
}
interface State {
  visible: boolean;
  values: string[];
}

@CSSModules(styles, { allowMultiple: true })
export class CheckboxFilter extends BaseFilter<Props, State> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      visible: this.props.visible === undefined ? true : this.props.visible,
      values: this.props.value || [],
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onHeaderClickHandler = this.onHeaderClickHandler.bind(this);
  }

  public render() {
    const checkboxFilterStyle = classNames("checkbox-filter");
    const checkboxFilterHeaderStyles = classNames("checkbox-filter__header", {
      "can-toggle": this.props.canToggle,
    });
    const toggleStyle = classNames("toggle", { visible: this.state.visible });
    const checkboxFilterListStyle = classNames("checkbox-filter__list", {
      visible: this.state.visible,
      inline: this.props.inline,
    });
    const checkboxFilterHeaderSubTitleStyles = classNames("subtitle", {
      visible: this.state.visible,
    });
    const values = !!this.props.disableSort
      ? this.props.values
      : sortBy(this.props.values, (item) => !this.checkStatus(item.value));

    return (
      <div styleName={checkboxFilterStyle} data-cy={this.props["data-cy"]}>
        <div
          styleName={checkboxFilterHeaderStyles}
          onClick={this.onHeaderClickHandler}
        >
          <div styleName="titles">
            <div styleName="title">
              <I18n value={this.props.title} />
            </div>
            {this.props.subtitle && (
              <div styleName={checkboxFilterHeaderSubTitleStyles}>
                {this.props.subtitle}
              </div>
            )}
          </div>
          {this.props.canToggle && (
            <div styleName={toggleStyle}>
              <i className="fal fa-fw fa-chevron-down" />
            </div>
          )}
        </div>
        <div styleName={checkboxFilterListStyle} data-cy="CY-checkBoxContainer">
          {values.map((item, idx) => {
            if (this.props.hideCheckedItems && this.checkStatus(item.value))
              return;
            return (
              <label key={idx}>
                <input
                  type="checkbox"
                  disabled={this.props.disabled}
                  value={item.value}
                  onChange={this.onChangeHandler}
                  checked={this.checkStatus(item.value)}
                  data-cy="CY-checkboxItem"
                />
                <span id={item.value + item.label}>
                  <I18n value={item.label} />
                </span>
              </label>
            );
          })}
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const values = nextProps.value || [];

    if (deepEqual(this.state.values, values)) return;

    this.setState({ values });
    this.context.updateField(this.props.name, values);
  }

  public onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;
    if (
      !!this.props.mustHaveOneValue &&
      !checked &&
      this.state.values.length === 1
    )
      return;

    const idx = this.state.values.indexOf(value);
    let values = null;

    if (checked && idx === -1) {
      values = [...this.state.values, value];
    }
    if (!checked && idx !== -1) {
      values = this.state.values.filter((val) => val !== value);
    }

    this.setState({ values });
    this.context.updateField(this.props.name, values);
  }

  private onHeaderClickHandler() {
    if (!this.props.canToggle) return;
    this.setState({ visible: !this.state.visible });
  }

  private checkStatus(value: string): boolean {
    return this.state.values.indexOf(value) !== -1;
  }
}
