import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { AssignmentPhase } from "@haywork/api/kolibri";
import { ResourceText, FontStyle } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";

const styles = require("./detail-types-list-item.component.scss");
const enumToString = StringUtil.enumToString;

interface ProjectDetailTypesListItemComponentProps {
  objectType: AssignmentSnapShot;
  zebra: boolean;
  onObjectTypeClick: (id: string) => void;
}
interface ProjectDetailTypesListItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailTypesListItemComponent extends React.Component<
  ProjectDetailTypesListItemComponentProps,
  ProjectDetailTypesListItemComponentState
> {
  public render() {
    const { objectType } = this.props;
    const listItemStyle = classNames("list-item", { zebra: this.props.zebra });
    const hasPreview = !!objectType.photo1Url && objectType.photo1Url !== "";
    const previewStyle = hasPreview
      ? { backgroundImage: `url(${JSON.stringify(objectType.photo1Url)})` }
      : null;

    return (
      <div
        styleName={listItemStyle}
        onClick={() => this.props.onObjectTypeClick(objectType.id)}
        data-cy="CY-objectTypeListItem"
      >
        <div styleName="list-item__preview">
          <div styleName="preview" style={previewStyle}>
            {!hasPreview && <i className="fal fa-fw fa-camera-retro" />}
          </div>
        </div>
        <div styleName="list-item__info">
          <div styleName="meta">
            <ResourceText
              masterKey="realEstateGroups"
              resourceKey={enumToString(objectType.realEstateGroup)}
            />
            ,{" "}
            {objectType.forSale ? (
              <ResourceText
                resourceKey="forSale"
                fontStyle={FontStyle.UcFirst}
              />
            ) : (
              <ResourceText
                resourceKey="forRent"
                fontStyle={FontStyle.UcFirst}
              />
            )}
          </div>
          <div styleName="title">{objectType.displayName}</div>
          <div styleName="type">
            <ResourceText
              masterKey="listingTypes"
              resourceKey={enumToString(objectType.listingType)}
            />
          </div>
        </div>
        <div styleName="list-item__status">
          <div styleName="type">
            <ResourceText
              masterKey="assignmentPhases"
              resourceKey={enumToString(objectType.assignmentPhase)}
            />
          </div>
          {objectType.assignmentPhase !== AssignmentPhase.Concept && (
            <div styleName="status">
              <ResourceText
                masterKey="availabilityStatuses"
                resourceKey={enumToString(objectType.availabilityStatus)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
