import { AgendaItemCategorySnapShot } from "@haywork/api/kolibri";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST, SCHEDULERCATEGORIESROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { InfiniteScroll, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AsyncUtil, RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { SettingsSchedulerOverviewContainerProps } from "../containers";
import { DeleteModal } from "./delete-modal";
import { ItemForm } from "./form";
import { ListItem } from "./item";
import noop from "lodash-es/noop";

const styles = require("./overview.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export type SettingsSchedulerOverviewComponentProps = {};
type State = {
  deleteModalVisible: boolean;
  categoryToDelete: AgendaItemCategorySnapShot;
};
type Props = SettingsSchedulerOverviewComponentProps &
  SettingsSchedulerOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsSchedulerOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalVisible: false,
      categoryToDelete: null
    };

    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteCategory = this.onDeleteCategory.bind(this);
    this.onEditHandler = this.onEditHandler.bind(this);
  }

  public componentDidMount() {
    this.props.getCategories(true);
  }

  public render() {
    return (
      <div styleName="overview">
        <PageHeader
          title="manageSchedulerCategories"
          subTitle="settingsMenuUsage"
        />

        <div styleName="overview__body">
          <div styleName="body__form">
            <ItemForm
              agendaItemCategoryTypes={this.props.agendaItemCategoryTypes}
              agendaStandardDurationOptions={
                this.props.agendaStandardDurationOptions
              }
              onSubmit={this.props.newCategory}
            />
          </div>
          <div styleName="body__list">
            <InfiniteScroll scrollEnd={noop}>
              <div styleName="header">
                <ResourceText resourceKey="allSchedulerCategories" />
              </div>
              {this.props.mappedAgendaItemCategories.map(
                (mappedCategory, idx) => (
                  <div key={idx}>
                    <div styleName="subheader">
                      {mappedCategory.type.displayName}
                    </div>
                    {mappedCategory.categories.map((category, idx) => (
                      <ErrorBoundary key={category.id}>
                        <ListItem
                          features={this.props.features}
                          zebra={idx % 2 === 1}
                          category={category}
                          agendaItemCategoryTypes={
                            this.props.agendaItemCategoryTypes
                          }
                          agendaStandardDurationOptions={
                            this.props.agendaStandardDurationOptions
                          }
                          onChange={this.props.saveCategory}
                          onDelete={this.onDeleteHandler}
                          onEdit={this.onEditHandler}
                        />
                      </ErrorBoundary>
                    ))}
                  </div>
                )
              )}
              {this.props.schedulerCategoryStatus === REQUEST.PENDING &&
                !this.props.mappedAgendaItemCategories.length && (
                  <Ui.Loaders.List />
                )}
            </InfiniteScroll>
          </div>
        </div>

        <DeleteModal
          visible={this.state.deleteModalVisible}
          statistics={this.props.agendaItemCategoryStatistics}
          category={this.state.categoryToDelete}
          agendaItemCategories={this.props.agendaItemCategories}
          onClose={this.onCloseDeleteModal}
          onDelete={this.onDeleteCategory}
        />
      </div>
    );
  }

  private onDeleteHandler(categoryToDelete: AgendaItemCategorySnapShot) {
    this.setState({
      deleteModalVisible: true,
      categoryToDelete
    });
  }

  private onCloseDeleteModal() {
    this.setState(
      {
        deleteModalVisible: false
      },
      async () => {
        await AsyncUtil.wait(500);
        this.setState({ categoryToDelete: null });
      }
    );
  }

  private onDeleteCategory(
    categoryToDelete: AgendaItemCategorySnapShot,
    categoryToMoveItemsTo?: AgendaItemCategorySnapShot
  ) {
    this.props.deleteCategory(categoryToDelete, categoryToMoveItemsTo);
    this.onCloseDeleteModal();
  }

  private onEditHandler(category: AgendaItemCategorySnapShot) {
    const path = route(SCHEDULERCATEGORIESROUTES.DETAIL.URI, {
      id: category.id
    });
    this.props.navigate(path);
  }
}
