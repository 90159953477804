import {
  settingsFolderTreesActions,
  SettingsFolderTreesActionTypes
} from "./folder-trees.actions";
import {
  DefaultFolderTreeSnapShot,
  DefaultFolderTree
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";

export interface SettingsFolderTreesState {
  defaultFolderTrees: DefaultFolderTreeSnapShot[];
  singleDefaultFolderTreeStatus: string;
  singleDefaultFolderTree: DefaultFolderTree;
}

const INITIAL: SettingsFolderTreesState = {
  defaultFolderTrees: [],
  singleDefaultFolderTreeStatus: REQUEST.IDLE,
  singleDefaultFolderTree: null
};

export const settingsFolderTreesReducer = (
  state: SettingsFolderTreesState = INITIAL,
  action: SettingsFolderTreesActionTypes
): SettingsFolderTreesState => {
  switch (action.type) {
    case settingsFolderTreesActions.SetDefaultFolderTrees: {
      const { defaultFolderTrees } = action;

      return {
        ...state,
        defaultFolderTrees
      };
    }
    case settingsFolderTreesActions.SetSingleDefaultFolderTreeStatus: {
      const { singleDefaultFolderTreeStatus } = action;

      return {
        ...state,
        singleDefaultFolderTreeStatus
      };
    }
    case settingsFolderTreesActions.SetSingleDefaultFolderTree: {
      const { singleDefaultFolderTree } = action;

      return {
        ...state,
        singleDefaultFolderTree,
        singleDefaultFolderTreeStatus: REQUEST.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};
