import * as React from "react";
import classNames from "classnames";

import { InputComponentProps } from "../input.component";

interface ToggleInputComponentProps {
  on: any;
  off: any;
}
interface ToggleInputComponentState {
  active: boolean;
}

export class ToggleComponent extends React.Component<
  ToggleInputComponentProps & InputComponentProps,
  ToggleInputComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.value === this.props.on,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const toggleInputStyle = classNames("toggle__input", {
      active: this.state.active,
    });

    return (
      <div
        className={toggleInputStyle}
        onClick={this.onClickHandler}
        onFocus={this.onClickHandler}
        data-cy={this.props["data-cy"]}
      >
        {this.props.children}
      </div>
    );
  }

  public onClickHandler() {
    const active = !this.state.active;
    const value = active ? this.props.on : this.props.off;
    this.setState({ active });
    this.props.onChange(value);
  }
}
