import { EmailAddress } from "@haywork/api/mail";
import { PATTERN } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Hint, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./detail.component.scss");

interface Props {
  emails: EmailAddress[];
  large?: boolean;
}
interface State {
  showAll: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class NamesComponent extends React.Component<Props, State> {
  private breakOn = 5;

  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };

    this.renderEmail = this.renderEmail.bind(this);
    this.onToggleNames = this.onToggleNames.bind(this);
  }

  public render() {
    let { emails } = this.props;
    let count = 0;

    if (!emails) return null;

    if (!this.state.showAll) {
      count = emails.length - this.breakOn;
      emails = emails.slice(0, this.breakOn);
    }

    return (
      <div styleName={classNames("names", { large: this.props.large })}>
        {emails.map(this.renderEmail)}
        {(count > 0 || this.state.showAll) && (
          <div styleName="names__toggle" onClick={this.onToggleNames}>
            <ResourceText
              resourceKey={
                this.state.showAll ? "emailsShowLess" : "emailsShowAll"
              }
              values={{ count }}
            />
          </div>
        )}
      </div>
    );
  }

  private renderEmail(emailAddress: EmailAddress, idx: number) {
    let { name } = emailAddress;
    let showHint = true;
    const count =
      this.state.showAll || this.props.emails.length < this.breakOn
        ? this.props.emails.length - 1
        : this.breakOn - 1;

    if (!name) {
      name = emailAddress.email;
      showHint = false;
    }

    if (showHint === true && !!name.match(PATTERN.EMAIL)) {
      showHint = false;
    }

    return (
      <ErrorBoundary key={idx}>
        <div styleName={classNames("names__item", { last: idx === count })}>
          <Hint
            message={emailAddress.email}
            forceInline
            shouldRender={showHint}
          >
            {name}
          </Hint>
        </div>
      </ErrorBoundary>
    );
  }

  private onToggleNames() {
    this.setState({
      showAll: !this.state.showAll,
    });
  }
}
