import {
  FinancialAdministration,
  FinancialAdministrationProduct,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./products.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface ProductComponentProps {
  product: FinancialAdministrationProduct;
  financialAdministration: FinancialAdministration;
  onRemove: (id: number) => void;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class ProductComponent extends React.Component<ProductComponentProps> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);

    this.formControls = {
      id: { value: value(this.props.product, "id") },
      groupId: { value: value(this.props.product, "groupId") },
      descriptionShort: {
        value: value(this.props.product, "descriptionShort"),
      },
      description: { value: value(this.props.product, "description") },
      unitPriceNet: { value: value(this.props.product, "unitPriceNet") },
      taxRateId: { value: value(this.props.product, "taxRateId") },
      isActive: { value: value(this.props.product, "isActive") },
      isMarketingProduct: {
        value: value(this.props.product, "isMarketingProduct"),
      },
      exportLedgerAccountCode: {
        value: value(this.props.product, "exportLedgerAccountCode"),
      },
    };
  }

  public render() {
    const { groups, taxRates } = this.props.financialAdministration;

    return (
      <Form
        name={`product-${this.props.product.id}`}
        formControls={this.formControls}
        onChange={this.props.onChange}
      >
        <div styleName="product">
          <div styleName="column">
            <Input.NewSelect
              name="groupId"
              values={groups}
              displayProp="name"
              valuesProp="id"
            />
          </div>
          <div styleName="column">
            <Input.Text name="descriptionShort" maxLength={128} />
          </div>
          <div styleName="column">
            <Input.Text name="description" maxLength={128} />
          </div>
          <div styleName="column">
            <Input.Number name="unitPriceNet" max={9999} pretty />
          </div>
          <div styleName="column">
            <Input.NewSelect
              name="taxRateId"
              addEmptyOption
              emptyOptionLabel="makeAChoice"
              values={taxRates}
              displayProp="description"
              valuesProp="id"
            />
          </div>
          <div styleName="column centered">
            <Input.CheckBox name="isActive" />
          </div>
          <div styleName="column centered">
            <Input.CheckBox name="isMarketingProduct" />
          </div>
          <div styleName="column">
            <Input.Text
              name="exportLedgerAccountCode"
              maxLength={64}
              placeholder="exportLedgerAccountCodePlaceholder"
            />
          </div>
          <div styleName="column remove">
            <div styleName="trigger" onClick={this.onRemoveClickHandler}>
              <i className="fal fa-minus-circle" />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onRemoveClickHandler() {
    const { id } = this.props.product;
    this.props.onRemove(id);
  }
}
