import { ObjectAssignment, PublicationSnapShot } from "@haywork/api/kolibri";
import { Group } from "@haywork/api/mls";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AppState,
  EditableActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";
import {
  AssignmentEditMarketingMlsComponent,
  AssignmentEditMarketingMlsComponentProps,
} from "../components/marketing-mls/marketing-mls.component";

interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  path: string;
  agencyGroups: Group[];
  publications: PublicationSnapShot[];
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingMlsComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: currentComponentState.objectAssignment.id,
    }),
    agencyGroups: state.mls.list.agencyGroups,
    publications: state.editable.currentComponentState.publications,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingMlsComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
});

export type AssignmentEditMarketingMlsContainerProps = StateProps &
  DispatchProps;

export const AssignmentEditMarketingMlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingMlsComponent);
