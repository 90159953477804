import { keyBoardActions, KeyBoardActions } from "./keyboard.actions";
import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";

export interface KeyBoardState {
  assignments: AssignmentSnapShot[];
  assignmentsStatus: string;
}

const INITIAL_STATE: KeyBoardState = {
  assignments: [],
  assignmentsStatus: REQUEST.IDLE
};

export const keyBoardReducer = (
  state: KeyBoardState = INITIAL_STATE,
  action: KeyBoardActions
): KeyBoardState => {
  switch (action.type) {
    case keyBoardActions.AddKeys: {
      const { assignments } = action;

      return {
        ...state,
        assignments,
        assignmentsStatus: REQUEST.SUCCESS
      };
    }
    case keyBoardActions.SetStatus: {
      const { assignmentsStatus } = action;

      return {
        ...state,
        assignmentsStatus
      };
    }
    default: {
      return state;
    }
  }
};
