import { ActionType, ActionTypes } from "./actions";
import {
  TemplateDefinitionSnapShot,
  TemplateDefinitionCategorySnapShot,
  MergeField
} from "@haywork/api/kolibri";

export type State = {
  templates: TemplateDefinitionSnapShot[];
  categories: TemplateDefinitionCategorySnapShot[];
  mergeFields: MergeField[];
};

const INITIAL: State = {
  templates: [],
  categories: [],
  mergeFields: []
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.SetTemplateCategories: {
      const { categories } = action;

      return {
        ...state,
        categories
      };
    }
    case ActionType.SetTemplateMergeFields: {
      const { mergeFields } = action;

      return {
        ...state,
        mergeFields
      };
    }
    case ActionType.SetTemplates: {
      const { templates } = action;

      return {
        ...state,
        templates
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
