import { ResourceText } from "@haywork/modules/shared";
import { SearchAssignmentSortedHeaderType } from "@haywork/stores/search-assignment/overview.reducer";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./overview.component.scss");

interface SearchAssignmentOverviewHeadersComponentProps {
  headerSorted: (type: SearchAssignmentSortedHeaderType) => void;
  selectedSortedHeaderType: SearchAssignmentSortedHeaderType;
}

interface SearchAssignmentOverviewHeadersComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentOverviewHeadersComponent extends React.Component<
  SearchAssignmentOverviewHeadersComponentProps,
  SearchAssignmentOverviewHeadersComponentState
> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <div styleName="search-assignments__statistics">
        <div styleName="search-assignments-filter-option searchers">
          <ResourceText resourceKey="searchers" />
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.SearchersAsc && (
            <i className="fa fa-fw fa-chevron-down" />
          )}
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.SearchersDesc && (
            <i className="fa fa-fw fa-chevron-up" />
          )}
        </div>
        <div styleName="search-assignments-filter-option rentSale">
          <ResourceText resourceKey="rentSale" />
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.RentSaleAsc && (
            <i className="fa fa-fw fa-chevron-down" />
          )}
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.RentSaleDesc && (
            <i className="fa fa-fw fa-chevron-up" />
          )}
        </div>

        <div styleName="search-assignments-filter-option type">
          <ResourceText resourceKey="type" />
        </div>
        <div styleName="search-assignments-filter-option price">
          <ResourceText resourceKey="price" />
        </div>

        <div styleName="search-assignments-filter-option is-paid">
          <ResourceText resourceKey="searchAssignment.overviewHeader.isPaid" />
        </div>

        <div styleName="search-assignments-filter-option date">
          <ResourceText resourceKey="createdDate" />
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.DateAsc && (
            <i className="fa fa-fw fa-chevron-down" />
          )}
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.DateDesc && (
            <i className="fa fa-fw fa-chevron-up" />
          )}
        </div>
        <div styleName="search-assignments-filter-option matchmail">
          <ResourceText resourceKey="matchmail" />
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.MatchmailAsc && (
            <i className="fa fa-fw fa-chevron-down" />
          )}
          {this.props.selectedSortedHeaderType ===
            SearchAssignmentSortedHeaderType.MatchmailDesc && (
            <i className="fa fa-fw fa-chevron-up" />
          )}
        </div>
      </div>
    );
  }

  // Sorting not being implemented in API yet, code ready for when it's implemented.
  private searcherSortedClick() {
    if (
      this.props.selectedSortedHeaderType ===
      SearchAssignmentSortedHeaderType.SearchersAsc
    ) {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.SearchersDesc);
    } else {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.SearchersAsc);
    }
  }

  private rentSaleSortedClick() {
    if (
      this.props.selectedSortedHeaderType ===
      SearchAssignmentSortedHeaderType.RentSaleAsc
    ) {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.RentSaleDesc);
    } else {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.RentSaleAsc);
    }
  }

  private dateSortedClick() {
    if (
      this.props.selectedSortedHeaderType ===
      SearchAssignmentSortedHeaderType.DateAsc
    ) {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.DateDesc);
    } else {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.DateAsc);
    }
  }

  private matchmailSortedClick() {
    if (
      this.props.selectedSortedHeaderType ===
      SearchAssignmentSortedHeaderType.MatchmailAsc
    ) {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.MatchmailDesc);
    } else {
      this.props.headerSorted(SearchAssignmentSortedHeaderType.MatchmailAsc);
    }
  }
}
