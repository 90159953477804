import { Action } from "redux";

import { REDUX } from "@haywork/constants";
import { PagedResults, StoreHelper, Status } from "@haywork/stores";
import { AssignmentSnapShot } from "@haywork/api/kolibri";

import * as ActionType from "./widgets.types";

export interface AssignmentsWidgetsState {
  lastChangedAssignments: PagedResults<AssignmentSnapShot>;
}

const INITIAL_STATE: AssignmentsWidgetsState = {
  lastChangedAssignments: StoreHelper.createInitialPagedResult()
};

export const widgetsReducer = (state: AssignmentsWidgetsState = INITIAL_STATE, action: Action): AssignmentsWidgetsState => {
  switch (action.type) {
    case REDUX.ASSIGNMENT.SET_WIDGET_LAST_ASSIGNMENTS_STATUS: {
      const lastChangedAssignments = {
        ...state.lastChangedAssignments,
        ...StoreHelper.setObjectStatus(<Status> action)
      };

      return {
        ...state,
        lastChangedAssignments
      };
    }
    case REDUX.ASSIGNMENT.SET_WIDGET_LAST_ASSIGNMENTS: {
      const lastChangedAssignments = StoreHelper.setPagedResults(<ActionType.AssignmentsResponse> action);

      return {
        ...state,
        lastChangedAssignments
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
