import {
  MailHistorySnapShot,
  MatchedPropertySnapShot,
  SearchAssignment,
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { Action } from "redux";
import * as ActionType from "./search-assignment.types";

export interface SingleSearchAssignmentState {
  searchAssignment: SearchAssignment;
  detailStatus: string;
  addressSearchState: string;
  publishState: string;
  addressSearchStateLocationId: number;
  saveSearchAssignmentStatus: string;
  showSaveModal: boolean;
  matchMails: MailHistorySnapShot[];
  matchMailsState: string;
  matchMailsPage: number;
  matchMailsPageCount: number;
  matchMailsTotal: number;
  modalMatchingProperties: MatchedPropertySnapShot[];
  modalMatchingPropertiesState: string;
}

const INITIAL_STATE: SingleSearchAssignmentState = {
  searchAssignment: null,
  detailStatus: REQUEST.IDLE,
  addressSearchState: REQUEST.IDLE,
  publishState: REQUEST.IDLE,
  addressSearchStateLocationId: null,
  saveSearchAssignmentStatus: REQUEST.IDLE,
  showSaveModal: false,
  matchMails: [],
  matchMailsState: REQUEST.IDLE,
  matchMailsPage: 0,
  matchMailsPageCount: 0,
  matchMailsTotal: 0,
  modalMatchingProperties: [],
  modalMatchingPropertiesState: REQUEST.IDLE,
};

export const singleSearchAssignmentReducer = (
  state: SingleSearchAssignmentState = INITIAL_STATE,
  action: Action
): SingleSearchAssignmentState => {
  switch (action.type) {
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT: {
      const { searchAssignment } = <ActionType.SingleSearchAssignment>action;
      return { ...state, searchAssignment, detailStatus: REQUEST.SUCCESS };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT_DETAIL_STATUS: {
      const { searchAssignmentDetailStatus } = <
        ActionType.SearchAssignmentDetailStatus
      >action;
      return { ...state, detailStatus: searchAssignmentDetailStatus };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_ADDRESS_SEARCH_STATUS: {
      const { addressSearchState, locationId } = <
        ActionType.AddressSearchState
      >action;
      return {
        ...state,
        addressSearchState,
        addressSearchStateLocationId: locationId,
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SEARCH_ASSIGNMENT_PUBLISH_STATUS: {
      const { publishState } = <ActionType.PublishState>action;

      return { ...state, publishState };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_SAVE_SEARCH_ASSIGNMENTS_STATUS: {
      const { saveSearchAssignmentStatus } = <
        ActionType.SaveSearchAssignmentStatus
      >action;
      return { ...state, saveSearchAssignmentStatus };
    }
    case REDUX.SEARCH_ASSIGNMENT.UPDATE_SEARCH_ASSIGNMENT: {
      const { searchAssignment } = <ActionType.SingleSearchAssignment>action;

      return { ...state, searchAssignment };
    }
    case REDUX.SEARCH_ASSIGNMENT.TOGGLE_SEARCH_ASSIGNMENT_SAVE_MODAL: {
      const { showSaveModal } = <ActionType.SaveModal>action;
      return { ...state, showSaveModal };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_MATCHMAILS_STATE: {
      const { matchMailsState } = <ActionType.MatchMailsState>action;
      return { ...state, matchMailsState };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_MATCHMAILS: {
      const { result, take } = <ActionType.Matchmails>action;
      const matchMailsPageCount = Math.ceil(result.totalResults / take);

      return {
        ...state,
        matchMails: result.results,
        matchMailsState: REQUEST.SUCCESS,
        matchMailsPage: 1,
        matchMailsPageCount,
        matchMailsTotal: result.totalResults,
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.APPEND_MATCHMAILS: {
      const { result, take } = <ActionType.Matchmails>action;
      const matchMailsPageCount = Math.ceil(result.totalResults / take);
      const matchMailsPage = state.matchMailsPage + 1;

      return {
        ...state,
        matchMails: [...state.matchMails, ...result.results],
        matchMailsState: REQUEST.SUCCESS,
        matchMailsPage,
        matchMailsPageCount,
        matchMailsTotal: result.totalResults,
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.CLEAR_MODAL_MATCHING_PROPERTIES: {
      return {
        ...state,
        modalMatchingProperties: [],
        modalMatchingPropertiesState: REQUEST.IDLE,
      };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_MODAL_MATCHING_PROPERTIES_STATE: {
      const { modalMatchingPropertiesState } = <
        ActionType.ModalMatchingPropertiesState
      >action;
      return { ...state, modalMatchingPropertiesState };
    }
    case REDUX.SEARCH_ASSIGNMENT.SET_MODAL_MATCHING_PROPERTIES: {
      const { result } = <ActionType.MatchGetProperties>action;

      return {
        ...state,
        modalMatchingProperties: result.results,
        modalMatchingPropertiesState: REQUEST.SUCCESS,
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
