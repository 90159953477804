import { AssignmentPhase, SearchAssignment } from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState, useMemo } from "react";

export enum AcquisitionObjectAction {
  New = "New",
  Email = "Email",
  ClearSelection = "ClearSelection",
}

type Props = {
  onClick: (action: AcquisitionObjectAction) => void;
};

const AcquisitionObjectActionsComponent: FC<Props> = memo(({ onClick }) => {
  const actionsRef = useRef<HTMLButtonElement>(null);
  const [actionsVisible, setActionsVisible] = useState(false);

  const onClickHandler = (action: AcquisitionObjectAction) => {
    onClick(action);
    setActionsVisible(false);
  };

  return (
    <>
      <Button
        ref={actionsRef}
        onClick={() => setActionsVisible(true)}
        icon={<Icon name="chevron-down" light size={16} color={Colors.White} />}
        iconPosition="end"
        category="primary"
        label="actions"
      />
      <ActionList
        parent={actionsRef}
        visible={actionsVisible}
        onHide={() => setActionsVisible(false)}
      >
        <Spacer label="actions" />
        <Action
          label="acquisitionObject.actions.new"
          icon={
            <Icon
              light
              name="handshake-alt"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onClickHandler(AcquisitionObjectAction.New)}
        />

        <Action
          label="acquisitionObject.actions.email"
          icon={
            <Icon
              light
              name="envelope"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onClickHandler(AcquisitionObjectAction.Email)}
        />

        <Action
          label="acquisitionObject.actions.clearSelection"
          icon={
            <Icon
              light
              name="eraser"
              color={Colors.ActionListIcon}
              fixedWidth
            />
          }
          onClick={() => onClickHandler(AcquisitionObjectAction.ClearSelection)}
        />
      </ActionList>
    </>
  );
});

export default AcquisitionObjectActionsComponent;
