import * as React from "react";
import { FC, useCallback, useEffect, useMemo } from "react";
import { WindowMessagesContainerProps } from "./window-messages.container";
import uniq from "lodash-es/uniq";
import { RelationType, AssignmentType } from "@haywork/api/kolibri";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  OBJECTTYPESROUTES,
  ASSIGNMENTROUTES,
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
} from "@haywork/constants";
import { EmailAddress } from "@haywork/api/mail";

const route = RouteUtil.mapStaticRouteValues;

export type WindowMessagesComponentProps = {};
type Props = WindowMessagesComponentProps & WindowMessagesContainerProps;

export const WindowMessagesComponent: FC<Props> = ({
  initializedWidgets,
  navigate,
  createNewDraft,
  hideModal,
}) => {
  const widgetUris = useMemo(() => {
    try {
      const uris = initializedWidgets
        .filter((d) => !!d)
        .map((widget) => {
          const url = new URL(widget.signedCallbackUri || "");
          return url.origin;
        })
        .filter((d) => !!d);

      return uniq(uris || []);
    } catch (error) {
      return [] as string[];
    }
  }, [initializedWidgets]);

  const openRelation = useCallback(
    (relationId: string, relationType: RelationType) => {
      switch (relationType) {
        case RelationType.ContactPerson: {
          const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
            id: relationId,
          });
          navigate(path);
          return;
        }
        case RelationType.ContactCompany: {
          const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
            id: relationId,
          });
          navigate(path);
          return;
        }
        case RelationType.Employee: {
          const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, {
            id: relationId,
          });
          navigate(path);
          return;
        }
        case RelationType.Office: {
          const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, {
            id: relationId,
          });
          navigate(path);
          return;
        }
        default: {
          return;
        }
      }
    },
    [navigate]
  );

  const openAssignment = useCallback(
    (assignmentId: string, assignmentType: AssignmentType) => {
      switch (assignmentType) {
        case AssignmentType.Acquisition: {
          const path = route(ACQUISITIONROUTES.DETAIL.URI, {
            id: assignmentId,
          });
          navigate(path);
          return;
        }
        case AssignmentType.AcquisitionObject: {
          const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
            id: assignmentId,
          });
          navigate(path);
          return;
        }
        case AssignmentType.Object: {
          const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
            id: assignmentId,
          });
          navigate(path);
          return;
        }
        case AssignmentType.ObjectType: {
          const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
            id: assignmentId,
          });
          navigate(path);
          return;
        }
        case AssignmentType.Project: {
          const path = route(PROJECTROUTES.DETAIL.URI, {
            id: assignmentId,
          });
          navigate(path);
          return;
        }
        default: {
          return;
        }
      }
    },
    [navigate]
  );

  const createEmail = useCallback(
    (emailAdresses?: string[], subject?: string, body?: string) => {
      const emails = (emailAdresses || []).map(
        (email) => ({ email } as EmailAddress)
      );
      createNewDraft(emails, subject, body);
    },
    [createNewDraft]
  );

  const handleWindowMessages = useCallback(
    (event: MessageEvent) => {
      try {
        const { data: eventData, origin } = event;
        const data =
          !!eventData && typeof eventData === "string"
            ? JSON.parse(eventData)
            : null;

        if (!data?.authorizationCallbackType || !widgetUris.includes(origin)) {
          return;
        }

        switch (data.authorizationCallbackType) {
          case "openRelation": {
            const { relationId, relationType, closeModal } = data;
            if (closeModal) {
              hideModal();
            }
            openRelation(relationId, relationType);
            return;
          }
          case "openAssignment": {
            const { assignmentId, assignmentType, closeModal } = data;
            if (closeModal) {
              hideModal();
            }
            openAssignment(assignmentId, assignmentType);
            return;
          }
          case "createEmail": {
            const { emailAdresses, subject, body, closeModal } = data;
            if (closeModal) {
              hideModal();
            }
            createEmail(emailAdresses, subject, body);
            return;
          }
          case "closeWidgetModal": {
            hideModal();
            return;
          }
          default: {
            return;
          }
        }
      } catch (error) {
        return;
      }
    },
    [widgetUris, openRelation, openAssignment, createEmail, hideModal]
  );

  useEffect(() => {
    window.addEventListener("message", handleWindowMessages);
    return () => window.removeEventListener("message", handleWindowMessages);
  }, [handleWindowMessages]);

  return <></>;
};
