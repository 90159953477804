import {
  DocumentTemplateSnapShot,
  DocumentTemplateType
} from "@haywork/api/kolibri";
import {
  DynamicDocumentsThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  NewDocumentComponent,
  NewDocumentComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { documentCategoryTemplates } from "./selectors";

export interface DocumentTemplateCategory {
  category: DocumentTemplateType;
  templates: DocumentTemplateSnapShot[];
}
interface StateProps {
  realEstateAgencyId: string;
  host: string;
  documentTemplates: DocumentTemplateSnapShot[];
  documentStatus: string;
  documentTemplatesStatus: string;
  documentCategoryTemplates: DocumentTemplateCategory[];
  recentlyUsed: DocumentTemplateSnapShot[];
}
interface DispatchProps {
  getDocumentTemplates: () => void;
  createDynamicDocument: (templateId: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  NewDocumentComponentProps,
  AppState
> = (state) => ({
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  host: state.appSettings.host,
  documentTemplates: state.dynamicDocuments.list.documentTemplates,
  documentCategoryTemplates: documentCategoryTemplates(state),
  documentStatus: state.dynamicDocuments.single.documentStatus,
  documentTemplatesStatus: state.dynamicDocuments.list.documentTemplatesStatus,
  recentlyUsed: state.dynamicDocuments.list.documentTemplates.slice(0, 3)
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  NewDocumentComponentProps
> = (dispatch) => ({
  getDocumentTemplates: () =>
    dispatch(DynamicDocumentsThunks.getDocumentTemplates()),
  createDynamicDocument: (templateId: string) =>
    dispatch(DynamicDocumentsThunks.createDynamicDocument(templateId))
});

export type NewDocumentContainerProps = StateProps & DispatchProps;
export const NewDocumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDocumentComponent);
