import { ActionType, ActionTypes } from "./actions";
import {
  AssignmentSnapShot,
  AssignmentsStatistics,
  SortOrder,
  AssignmentOrderByField,
  AssignmentPhase,
  AvailabilityStatus,
} from "@haywork/api/kolibri";
import { AssignmentOrder } from ".";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterType } from "@haywork/enum/list-filter-types";

export type State = {
  assignments: AssignmentSnapShot[];
  totalCount: number;
  statistics: AssignmentsStatistics;
  order: AssignmentOrder;
  filters: FilterConfig;
  scrollOffset: number;
};

const INITIAL: State = {
  assignments: [],
  totalCount: 0,
  statistics: {
    commissionTotal: 0,
    listingTypes: [],
    localities: [],
    keyNumbers: [],
  },
  order: {
    sortOrder: SortOrder.Ascending,
    sortColumn: AssignmentOrderByField.LocalityStreetNameAndNumber,
  },
  filters: {
    realEstateGroups: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "realEstateGroups",
    },
    assignmentTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "assignmentTypes",
    },
    saleOrRent: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    keyNumbers: {
      type: FilterType.KeyNumberArray,
      value: [],
      emptyValue: [],
    },
    listingTypes: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "listingTypes",
    },
    assignmentPhases: {
      type: FilterType.Array,
      value: [AssignmentPhase.Initiated],
      emptyValue: [],
      prefix: "assignmentPhases",
    },
    availabilityStatuses: {
      type: FilterType.Array,
      value: [AvailabilityStatus.Available],
      emptyValue: [],
      prefix: "availabilityStatuses",
    },
    offices: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "offices",
    },
    employees: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "employees",
    },
    localities: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    priceRange: {
      type: FilterType.PriceRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
    numberOfBedrooms: {
      type: FilterType.NumberRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
    furnishings: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "furnishingOptions",
    },
    archived: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    availableFrom: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
    availableUntill: {
      type: FilterType.DateRange,
      value: { min: undefined, max: undefined },
      emptyValue: { min: undefined, max: undefined },
    },
  },
  scrollOffset: 0,
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.UpdateList: {
      const { items, totalCount, statistics, startIndex } = action;

      let assignments = [...state.assignments];
      if (!assignments.length) {
        assignments = new Array(totalCount).fill(null);
      }

      for (let i = 0; i < items.length; i++) {
        assignments[i + startIndex] = items[i];
      }

      return {
        ...state,
        assignments,
        totalCount,
        statistics: !statistics ? state.statistics : statistics,
      };
    }
    case ActionType.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        assignments: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case ActionType.SetFilters: {
      const { filters } = action;

      return {
        ...state,
        assignments: [],
        totalCount: 0,
        filters,
      };
    }
    case ActionType.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case ActionType.UpdateItem: {
      const { snapshot } = action;

      return {
        ...state,
        assignments: state.assignments.map((assignment) =>
          !!assignment && assignment.id === snapshot.id ? snapshot : assignment
        ),
      };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
