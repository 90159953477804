import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  PlaygroundComponent,
  PlaygroundComponentProps,
} from "./playground.component";
import { push } from "connected-react-router";

interface StateProps {}
interface DispatchProps {
  navigate: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  PlaygroundComponentProps,
  AppState
> = (state) => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PlaygroundComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
});

export type PlaygroundContainerProps = StateProps & DispatchProps;
export const PlaygroundContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaygroundComponent);
