import {
  PublicationSnapShot,
  PublicationStatus,
  ProjectAssignment,
  ObjectAssignment,
  AssignmentPhase,
  AvailabilityStatus,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { PublicationContainerProps } from "./publication.container";
import HideFromMediaPartnerToggleContainer from "../hide-toggle";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { TiaraMediaContractsResidential } from "@haywork/constants/media-contracts";

const styles = require("./style.scss");

export type PublicationComponentProps = {
  publication: PublicationSnapShot;
  assignment: ObjectAssignment | ProjectAssignment;
  type?: string;
  onUpdatedPublication: () => void;
};
type Props = PublicationComponentProps & PublicationContainerProps;

export const PublicationComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      publication,
      activate,
      onUpdatedPublication,
      assignment,
      deactivate,
      type,
    }) => {
      const [loading, setLoading] = useState(false);
      const {
        publicationStatus,
        dateTimeModified,
        mediaPartnerId,
        statusMessage,
        detailsUrl,
        mediaPartnerName,
        category,
        isFavorite,
        isAutoPublish,
      } = publication;

      const {
        id: assignmentId,
        assignmentPhase,
        availabilityStatus,
      } = assignment;
      const assignmentIsConcept = assignmentPhase === AssignmentPhase.Concept;
      const isSoldOrRented: boolean =
        [AvailabilityStatus.Sold, AvailabilityStatus.Rented].indexOf(
          availabilityStatus
        ) > -1;

      const handleActivate = async () => {
        try {
          setLoading(true);
          await activate(assignmentId, mediaPartnerId);
          onUpdatedPublication();
        } finally {
          setLoading(false);
        }
      };

      const handleDeActivate = async () => {
        try {
          setLoading(true);
          await deactivate(assignmentId, mediaPartnerId);
          onUpdatedPublication();
        } finally {
          setLoading(false);
        }
      };

      const action = useMemo(() => {
        if (assignmentPhase === AssignmentPhase.Completed) return null;

        switch (publicationStatus) {
          case PublicationStatus.WaitingForObligatoryPublications:
          default: {
            return null;
          }
          case PublicationStatus.WaitingForExchangeEntityContract: {
            if (
              !!dateTimeModified &&
              moment().diff(moment(dateTimeModified), "hour", true) < 1
            ) {
              return null;
            }

            const icon = loading ? (
              <Icon
                name="spinner"
                spin
                regular
                containIn={24}
                color={Colors.Gray}
              />
            ) : null;
            return (
              <Button
                category="warning"
                icon={icon}
                label="offerAgain"
                disabled={loading}
                onClick={handleActivate}
              />
            );
          }
          case PublicationStatus.PublishRequestEnqueued: {
            if (isAutoPublish || !assignmentIsConcept) {
              return null;
            }

            const icon = loading ? (
              <Icon
                name="spinner"
                spin
                regular
                containIn={24}
                color={Colors.Gray}
              />
            ) : null;
            return (
              <Button
                category="primary"
                icon={icon}
                label="withdraw"
                disabled={loading}
                onClick={handleDeActivate}
              />
            );
          }
          case PublicationStatus.Inactive: {
            if (isSoldOrRented) return;
            const icon = loading ? (
              <Icon
                name="spinner"
                spin
                regular
                containIn={24}
                color={Colors.Gray}
              />
            ) : null;
            return (
              <Button
                category="success"
                icon={icon}
                label="toOffer"
                disabled={loading}
                onClick={handleActivate}
              />
            );
          }
          case PublicationStatus.Rejected: {
            const icon = loading ? (
              <Icon
                name="spinner"
                spin
                regular
                containIn={24}
                color={Colors.Gray}
              />
            ) : null;
            return (
              <Button
                category="danger"
                icon={icon}
                label="offerAgain"
                disabled={loading}
                onClick={handleActivate}
              />
            );
          }
          case PublicationStatus.Withdrawn: {
            return <Button category="primary" label="toOffer" disabled />;
          }
        }
      }, [
        publicationStatus,
        dateTimeModified,
        loading,
        isAutoPublish,
        assignmentIsConcept,
        assignmentPhase,
      ]);

      const status = useMemo(() => {
        let color = Colors.Primary;
        let icon = null;

        switch (publicationStatus) {
          case PublicationStatus.Rejected:
          case PublicationStatus.Withdrawn: {
            color = Colors.Danger;
            icon = <Icon name="exclamation-circle" regular color={color} />;
            break;
          }
          case PublicationStatus.PublishRequestEnqueued: {
            color = Colors.Primary;
            icon = <Icon name="clock" regular color={color} />;
            break;
          }
          case PublicationStatus.Offered:
          default: {
            color = Colors.Success;
            icon = <Icon name="check-circle" regular color={color} />;
            break;
          }
          case PublicationStatus.Inactive: {
            color = Colors.Gray;
            icon = <Icon name="hand-paper" regular color={color} />;
            break;
          }
          case PublicationStatus.WaitingForExchangeEntityContract:
          case PublicationStatus.WaitingForObligatoryPublications: {
            color = Colors.Warning;
            icon = <Icon name="exclamation-triangle" regular color={color} />;
            break;
          }
        }

        return (
          <>
            <div styleName="status__status" style={{ color }}>
              <div styleName="status__icon">{icon}</div>
              {assignmentIsConcept &&
              publicationStatus === PublicationStatus.PublishRequestEnqueued ? (
                <I18n value="publicationStatus.enqueued.inConcept" />
              ) : (
                <I18n
                  value={publicationStatus.toString()}
                  prefix="publicationStatuses"
                />
              )}
            </div>
            {!!dateTimeModified &&
              ![
                PublicationStatus.Inactive,
                PublicationStatus.Rejected,
              ].includes(publicationStatus) &&
              !assignmentIsConcept && (
                <div styleName="status__message">
                  <I18n value="lastTimeOffered" />{" "}
                  {moment(dateTimeModified).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              )}
            {publicationStatus === PublicationStatus.Rejected &&
              !!statusMessage && (
                <div
                  styleName="status__message"
                  dangerouslySetInnerHTML={{ __html: statusMessage }}
                />
              )}
            {!!detailsUrl && <div styleName="status__url">{detailsUrl}</div>}
          </>
        );
      }, [
        publicationStatus,
        statusMessage,
        dateTimeModified,
        detailsUrl,
        assignmentIsConcept,
      ]);

      return (
        <div styleName="publication">
          <div styleName="favorite">
            <Icon
              name="star"
              color={isFavorite ? Colors.Warning : Colors.LightGray}
              solid
              size={14}
            />
          </div>
          <div styleName="info">
            <div styleName="info__name">{mediaPartnerName}</div>
            <div styleName="info__category">
              <I18n
                value={category.toString()}
                prefix="mediaPartnerCategories"
              />
            </div>
          </div>
          <div styleName="status">{status}</div>
          <FeatureSwitch feature="HIDE_ON_MEDIAPARTNER_TOGGLE">
            <div>
              <HideFromMediaPartnerToggleContainer
                assignment={assignment}
                publication={publication}
                type={type}
                showForMediaPartners={TiaraMediaContractsResidential}
              />
            </div>
          </FeatureSwitch>
          {!!action && <div styleName="action">{action}</div>}
        </div>
      );
    }
  )
);
