import * as React from "react";
import { InputComponentProps } from "../input.component";

interface HiddenInputComponentProps {}
interface HiddenInputComponentState {}

export class HiddenComponent extends React.Component<
  HiddenInputComponentProps & InputComponentProps,
  HiddenInputComponentState
> {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <input
        type="hidden"
        name={this.props.name}
        id={this.props.name}
        value={this.props.value}
        onChange={this.onChangeHandler}
      />
    );
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.props.onChange(value);
  }
}
