import * as ActionTypes from "./account.types";

import { AccountSettings, Employee, ActiveFilter } from "@haywork/api/kolibri";
import { REDUX } from "@haywork/constants";

const setEmployee = (employee: Employee) => ({
  type: REDUX.ACCOUNT.SET_EMPLOYEE,
  employee,
});

const setAccountSettings = (accountSettings: AccountSettings) => ({
  type: REDUX.ACCOUNT.SET_ACCOUNT_SETTINGS,
  accountSettings,
});

const setShowOnboarding = (
  accountSettings: AccountSettings,
  employee: Employee
) => {
  const showOnboarding =
    !accountSettings.seenContactFormOn ||
    !employee.firstName ||
    !employee.lastName ||
    (!employee.emailAddresses && employee.emailAddresses.length > 0);

  return {
    type: REDUX.ACCOUNT.SET_ONBOARDING,
    showOnboarding,
  };
};

const hideOnboarding = () => ({
  type: REDUX.ACCOUNT.SET_ONBOARDING,
  showOnboarding: false,
});

const setEmployeesAndOffices = (payload: ActionTypes.Relations) => ({
  type: REDUX.ACCOUNT.SET_EMPLOYEES_AND_OFFICES,
  ...payload,
});

const setAccountError = (error: any) => ({
  type: REDUX.ACCOUNT.EMPLOYEE_FAILURE,
  error,
});

const setRelationEmployeeState = (relationEmployeeState: string) => ({
  type: REDUX.ACCOUNT.SET_EMPLOYEE_STATE,
  relationEmployeeState,
});

const setFinancialAdministrations = (
  payload: ActionTypes.FinancialAdministrations
) => ({
  type: REDUX.ACCOUNT.SET_FINANCIAL_ADMINISTRATIONS,
  ...payload,
});

const setFinancialAdministrationState = (
  payload: ActionTypes.FinancialAdministrationState
) => ({
  type: REDUX.ACCOUNT.SET_FINANCIAL_ADMINISTRATION_STATE,
  ...payload,
});

const updateFinancialAdministration = (
  payload: ActionTypes.FinancialAdministration
) => ({
  type: REDUX.ACCOUNT.UPDATE_FINANCIAL_ADMINISTRATION,
  ...payload,
});

const switchRealEstateAgency = (id: string) => ({
  type: REDUX.ACCOUNT.SWITCH_REALESTATE_AGENCY,
  id,
});

const setShowAllAssignments = (showAllAssignments: boolean) => ({
  type: REDUX.ACCOUNT.SET_SHOW_ALL_ASSIGNMENTS,
  showAllAssignments,
});

const setShowAllKeyBoardAssignments = (
  showAllKeyboardAssignments: ActiveFilter
) => ({
  type: REDUX.ACCOUNT.SET_SHOW_ALL_KEYBOARD_ASSIGNMENTS,
  showAllKeyboardAssignments,
});

export const AccountActions = {
  setEmployee,
  setEmployeesAndOffices,
  setAccountError,
  setRelationEmployeeState,
  setAccountSettings,
  setShowOnboarding,
  hideOnboarding,
  setFinancialAdministrations,
  setFinancialAdministrationState,
  updateFinancialAdministration,
  switchRealEstateAgency,
  setShowAllAssignments,
  setShowAllKeyBoardAssignments,
};
