import {
  Acceptance,
  LeaseTermUnit,
  RentCondition,
  RentOffer,
} from "@haywork/api/kolibri";
import { AssignmentEditFinancialRentContainerProps } from "@haywork/modules/assignment";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  Hint,
  HintAlignment,
  ResourceText,
  StepComponent,
  StepperComponent,
  FontStyle,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import Commission, {
  CommissionFor,
  CommissionResponse,
} from "../../commission";
import { AssignmentFinancialMaxRentComponent } from "./max-rent.component";

const styles = require("./rent.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export type AssignmentEditFinancialRentComponentProps = {
  onChange: (values: FormReturnValue) => void;
};
interface State {
  totalMonthly: number;
  totalYearly: number;
  totalOneTime: number;
  showAcceptanceDate: boolean;
  showAvailabilityDates: boolean;
  showMinimumLeaseTermFields: boolean;
  showMaximumLeaseTermFields: boolean;
  availabilityIsTemporary: boolean;
  tempCalculatedCommissionContactGross: boolean;
  tempCalculatedDepositGross: boolean;
}
type Props = AssignmentEditFinancialRentComponentProps &
  AssignmentEditFinancialRentContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialRentComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { acceptanceDetails, rentOffer, hidePrice } = this.props;
    const now = new Date();
    const dateOfIssue = has(
      rentOffer,
      "valuationOfImmovablePropertyAct.dateOfIssue"
    )
      ? new Date(rentOffer.valuationOfImmovablePropertyAct.dateOfIssue)
      : new Date(now.getFullYear(), 0, 1, 12, 0, 0);

    const totalOneTime =
      parseFloat(value(rentOffer, "commissionContactGross", 0)) +
      parseFloat(value(rentOffer, "deposit", 0)) +
      parseFloat(value(rentOffer, "advance", 0));
    const totalYearly =
      parseFloat(value(rentOffer, "sewerageCharges", 0)) +
      parseFloat(value(rentOffer, "waterAuthorityCharges", 0));
    const totalMonthly =
      parseFloat(value(rentOffer, "basicRent", 0)) +
      parseFloat(value(rentOffer, "indicationGas", 0)) +
      parseFloat(value(rentOffer, "serviceCosts", 0)) +
      parseFloat(value(rentOffer, "indicationWater", 0)) +
      parseFloat(value(rentOffer, "indicationElectra", 0)) +
      parseFloat(value(rentOffer, "indicationTelevision", 0)) +
      parseFloat(value(rentOffer, "indicationInternet", 0)) +
      parseFloat(value(rentOffer, "furniture", 0)) +
      parseFloat(value(rentOffer, "softFurnishings", 0)) +
      parseFloat(value(rentOffer, "indicationHeatingCosts", 0));

    // Set state
    this.state = {
      totalMonthly,
      totalYearly,
      totalOneTime,
      showAcceptanceDate:
        value(acceptanceDetails, "acceptance", Acceptance.InConsultation) ===
        Acceptance.PerDate,
      showAvailabilityDates: value(rentOffer, "availabilityIsTemporary", false),
      showMinimumLeaseTermFields: value(
        rentOffer,
        "minimumLeaseTerm.isApplicable",
        false
      ),
      showMaximumLeaseTermFields: value(
        rentOffer,
        "maximumLeaseTerm.isApplicable",
        false
      ),
      availabilityIsTemporary: value(
        rentOffer,
        "availabilityIsTemporary",
        false
      ),
      tempCalculatedCommissionContactGross: false,
      tempCalculatedDepositGross: false,
    };

    // Set form controls
    this.formControls = {
      // Rent
      priceCode: { value: value(rentOffer, "priceCode") },
      rentPrice: {
        value: value(rentOffer, "rentPrice"),
        onChange: (ref, get) => {
          if (!ref.value) return;
          let updatedFields = {};

          if (this.state.tempCalculatedCommissionContactGross) {
            const months = get("tempCalculatedCommissionContactGross").value;
            updatedFields = {
              ...updatedFields,
              commissionContactGross:
                parseFloat(ref.value) * parseFloat(months || "0"),
            };
          }

          if (this.state.tempCalculatedDepositGross) {
            const months = get("tempCalculatedDepositGross").value;
            updatedFields = {
              ...updatedFields,
              deposit: parseFloat(ref.value) * parseFloat(months || "0"),
            };
          }

          return updatedFields;
        },
      },
      rentCondition: {
        value: value(rentOffer, "rentCondition", RentCondition.PricePerMonth),
        onChange: (ref) => {
          if (ref.value === RentCondition.PricePerContract) {
            this.setState({
              tempCalculatedDepositGross: false,
              tempCalculatedCommissionContactGross: false,
            });
            return {
              toggleCalculatedCommissionContactGross: false,
              toggleCalculatedDepositGross: false,
              tempCalculatedCommissionContactGross: "",
              tempCalculatedDepositGross: "",
            };
          }
        },
      },
      vatCondition: { value: value(rentOffer, "vatCondition") },
      rentSpecifications: { value: value(rentOffer, "rentSpecifications", []) },
      valuationOfImmovablePropertyActValue: {
        value: value(rentOffer, "valuationOfImmovablePropertyAct.value"),
      },
      dateOfIssue: { value: dateOfIssue },
      hidePrice: { value: hidePrice },

      // Acceptance
      acceptance: {
        value: value(
          acceptanceDetails,
          "acceptance",
          Acceptance.InConsultation
        ),
        onChange: (ref) => {
          this.setState({
            showAcceptanceDate: ref.value === Acceptance.PerDate,
          });
          if (ref.value !== Acceptance.PerDate) {
            return { acceptanceDate: "" };
          }
        },
      },
      acceptanceDate: { value: value(acceptanceDetails, "date") },
      availabilityIsTemporary: {
        value: value(rentOffer, "availabilityIsTemporary", false),
        onChange: (ref) => {
          this.setState({ showAvailabilityDates: ref.value === true });
          if (ref.value === false) {
            return { availableFrom: "", availableUntil: "" };
          }
        },
      },
      availableFrom: { value: value(rentOffer, "availableFrom") },
      availableUntil: { value: value(rentOffer, "availableUntil") },
      minimumLeaseTermApplicable: {
        value: value(rentOffer, "minimumLeaseTerm.isApplicable", false),
        onChange: (ref) =>
          this.setState({ showMinimumLeaseTermFields: ref.value === true }),
      },
      minimumLeaseTermUnits: {
        value: value(rentOffer, "minimumLeaseTerm.numberOfUnits"),
      },
      minimumLeaseTermUnit: {
        value: value(rentOffer, "minimumLeaseTerm.unit", LeaseTermUnit.Day),
      },
      maximumLeaseTermApplicable: {
        value: value(rentOffer, "maximumLeaseTerm.isApplicable", false),
        onChange: (ref) =>
          this.setState({ showMaximumLeaseTermFields: ref.value === true }),
      },
      maximumLeaseTermUnits: {
        value: value(rentOffer, "maximumLeaseTerm.numberOfUnits"),
      },
      maximumLeaseTermUnit: {
        value: value(rentOffer, "maximumLeaseTerm.unit", LeaseTermUnit.Day),
      },

      // Single costs
      commissionContactGross: {
        value: value(rentOffer, "commissionContactGross"),
      },
      deposit: { value: value(rentOffer, "deposit") },
      advance: { value: value(rentOffer, "advance") },

      // Monthly costs
      basicRent: { value: value(rentOffer, "basicRent") },
      indicationGas: { value: value(rentOffer, "indicationGas") },
      serviceCosts: { value: value(rentOffer, "serviceCosts") },
      indicationWater: { value: value(rentOffer, "indicationWater") },
      indicationElectra: { value: value(rentOffer, "indicationElectra") },
      indicationTelevision: { value: value(rentOffer, "indicationTelevision") },
      indicationInternet: { value: value(rentOffer, "indicationInternet") },
      furniture: { value: value(rentOffer, "furniture") },
      softFurnishings: { value: value(rentOffer, "softFurnishings") },
      indicationHeatingCosts: {
        value: value(rentOffer, "indicationHeatingCosts"),
      },
      rentPriceIsIndexed: { value: value(rentOffer, "rentPriceIsIndexed") },

      // Yearly costs
      sewerageCharges: { value: value(rentOffer, "sewerageCharges") },
      waterAuthorityCharges: {
        value: value(rentOffer, "waterAuthorityCharges"),
      },

      toggleCalculatedCommissionContactGross: {
        value: false,
        onChange: (ref) => {
          this.setState({ tempCalculatedCommissionContactGross: ref.value });
        },
      },
      toggleCalculatedDepositGross: {
        value: false,
        onChange: (ref) => {
          this.setState({ tempCalculatedDepositGross: ref.value });
        },
      },
      tempCalculatedCommissionContactGross: {
        value: "",
        onChange: (ref, get) => {
          const rentPrice = get("rentPrice");
          if (!rentPrice.value) return;
          return {
            commissionContactGross:
              parseFloat(rentPrice.value) * parseFloat(ref.value || "0"),
          };
        },
      },
      tempCalculatedDepositGross: {
        value: "",
        onChange: (ref, get) => {
          const rentPrice = get("rentPrice");
          if (!rentPrice.value) return;
          return {
            deposit: parseFloat(rentPrice.value) * parseFloat(ref.value || "0"),
          };
        },
      },
    };

    // Bind this
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRentOfferChangesHandler = this.onRentOfferChangesHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { acceptanceDetails, rentOffer, hidePrice } = this.props;
      const dateOfIssue = has(
        rentOffer,
        "valuationOfImmovablePropertyAct.dateOfIssue"
      )
        ? new Date(rentOffer.valuationOfImmovablePropertyAct.dateOfIssue)
        : new Date();

      const totalOneTime =
        parseFloat(value(rentOffer, "commissionContactGross", 0)) +
        parseFloat(value(rentOffer, "deposit", 0)) +
        parseFloat(value(rentOffer, "advance", 0));
      const totalYearly =
        parseFloat(value(rentOffer, "sewerageCharges", 0)) +
        parseFloat(value(rentOffer, "waterAuthorityCharges", 0));
      const totalMonthly =
        parseFloat(value(rentOffer, "basicRent", 0)) +
        parseFloat(value(rentOffer, "indicationGas", 0)) +
        parseFloat(value(rentOffer, "serviceCosts", 0)) +
        parseFloat(value(rentOffer, "indicationWater", 0)) +
        parseFloat(value(rentOffer, "indicationElectra", 0)) +
        parseFloat(value(rentOffer, "indicationTelevision", 0)) +
        parseFloat(value(rentOffer, "indicationInternet", 0)) +
        parseFloat(value(rentOffer, "furniture", 0)) +
        parseFloat(value(rentOffer, "softFurnishings", 0)) +
        parseFloat(value(rentOffer, "indicationHeatingCosts", 0));

      this.setState({
        totalMonthly,
        totalYearly,
        totalOneTime,
        showAcceptanceDate:
          value(acceptanceDetails, "acceptance", Acceptance.InConsultation) ===
          Acceptance.PerDate,
        showAvailabilityDates: value(
          rentOffer,
          "availabilityIsTemporary",
          false
        ),
        showMinimumLeaseTermFields: value(
          rentOffer,
          "minimumLeaseTerm.isApplicable",
          false
        ),
        showMaximumLeaseTermFields: value(
          rentOffer,
          "maximumLeaseTerm.isApplicable",
          false
        ),
        availabilityIsTemporary: value(
          rentOffer,
          "availabilityIsTemporary",
          false
        ),
        tempCalculatedCommissionContactGross: false,
        tempCalculatedDepositGross: false,
      });

      this.formRef.update(
        {
          priceCode: value(rentOffer, "priceCode"),
          rentPrice: value(rentOffer, "rentPrice"),
          rentCondition: value(
            rentOffer,
            "rentCondition",
            RentCondition.PricePerMonth
          ),
          vatCondition: value(rentOffer, "vatCondition"),
          rentSpecifications: value(rentOffer, "rentSpecifications", []),
          valuationOfImmovablePropertyActValue: value(
            rentOffer,
            "valuationOfImmovablePropertyAct.value"
          ),
          dateOfIssue,
          hidePrice,
          acceptance: value(
            acceptanceDetails,
            "acceptance",
            Acceptance.InConsultation
          ),
          acceptanceDate: value(acceptanceDetails, "date"),
          availabilityIsTemporary: value(
            rentOffer,
            "availabilityIsTemporary",
            false
          ),
          availableFrom: value(rentOffer, "availableFrom"),
          availableUntil: value(rentOffer, "availableUntil"),
          minimumLeaseTermApplicable: value(
            rentOffer,
            "minimumLeaseTerm.isApplicable",
            false
          ),
          minimumLeaseTermUnits: value(
            rentOffer,
            "minimumLeaseTerm.numberOfUnits"
          ),
          minimumLeaseTermUnit: value(
            rentOffer,
            "minimumLeaseTerm.unit",
            LeaseTermUnit.Day
          ),
          maximumLeaseTermApplicable: value(
            rentOffer,
            "maximumLeaseTerm.isApplicable",
            false
          ),
          maximumLeaseTermUnits: value(
            rentOffer,
            "maximumLeaseTerm.numberOfUnits"
          ),
          maximumLeaseTermUnit: value(
            rentOffer,
            "maximumLeaseTerm.unit",
            LeaseTermUnit.Day
          ),
          commissionContactGross: value(rentOffer, "commissionContactGross"),
          deposit: value(rentOffer, "deposit"),
          advance: value(rentOffer, "advance"),
          basicRent: value(rentOffer, "basicRent"),
          indicationGas: value(rentOffer, "indicationGas"),
          serviceCosts: value(rentOffer, "serviceCosts"),
          indicationWater: value(rentOffer, "indicationWater"),
          indicationElectra: value(rentOffer, "indicationElectra"),
          indicationTelevision: value(rentOffer, "indicationTelevision"),
          indicationInternet: value(rentOffer, "indicationInternet"),
          furniture: value(rentOffer, "furniture"),
          softFurnishings: value(rentOffer, "softFurnishings"),
          indicationHeatingCosts: value(rentOffer, "indicationHeatingCosts"),
          rentPriceIsIndexed: value(rentOffer, "rentPriceIsIndexed"),
          sewerageCharges: value(rentOffer, "sewerageCharges"),
          waterAuthorityCharges: value(rentOffer, "waterAuthorityCharges"),
          toggleCalculatedCommissionContactGross: false,
          toggleCalculatedDepositGross: false,
          tempCalculatedCommissionContactGross: "",
          tempCalculatedDepositGross: "",
        },
        true
      );
    }
  }

  public render() {
    const {
      acceptances,
      acceptanceDetails,
      hidePrice,
      rentSpecifications,
      vatConditions,
      rentOffer,
    } = this.props;

    return (
      <div styleName="financial" id="scroll-to-top">
        <div className="container-fluid">
          <Form
            name="financial"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.formRef = form)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              <StepComponent title="rent">
                {/* Rent */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <Input.NewSelect
                        name="priceCode"
                        values={this.props.priceCodes}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                    <div className="column__textspacer push-label">
                      <ResourceText
                        resourceKey="from"
                        fontStyle={FontStyle.LowerCase}
                      />
                    </div>
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="rentPrice"
                          pretty
                          step={10}
                          asPrice
                        />
                      </div>
                    </div>
                    <div className="column__textspacer push-label">
                      <ResourceText resourceKey="per" />
                    </div>
                    <div
                      className="column push-label"
                      styleName="rentCondition"
                    >
                      <Input.NewSelect
                        name="rentCondition"
                        values={this.props.rentConditions}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column push-label">
                      <Input.RadioGroup
                        name="vatCondition"
                        asButtonList
                        canUnselect
                      >
                        {vatConditions.map((vatCondition, idx) => (
                          <Input.Radio
                            label={vatCondition.displayName}
                            value={vatCondition.value}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <Input.Switch
                    name="rentPriceIsIndexed"
                    label="rentPriceIsIndexed"
                    labelPosition={SwitchLabelPosition.Pre}
                    on={true}
                    off={false}
                  />
                </div>

                {/* Max rent */}
                <AssignmentFinancialMaxRentComponent />

                {/* Specs */}
                <div className="form__row">
                  <label htmlFor="">
                    <ResourceText resourceKey="rentSpecifications" />
                  </label>
                  <Input.Multi
                    name="rentSpecifications"
                    values={rentSpecifications}
                  />
                </div>

                {/* Hide price */}
                {(!!hidePrice ||
                  !!this.props.enableHidePriceForObjectAssignmentsOption) && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="hidePrice"
                          label="hidePrice"
                          labelPosition={SwitchLabelPosition.Pre}
                          on={true}
                          off={false}
                        />
                      </div>
                      <div className="column__textspacer">
                        <Hint
                          message="showPriceTooltip"
                          align={HintAlignment.Right}
                        >
                          <span className="toolTip">
                            <i className="fa fa-fw fa-info-circle" />{" "}
                            <ResourceText resourceKey="whatIsThis" />
                          </span>
                        </Hint>
                      </div>
                    </div>
                  </div>
                )}

                {/* WOZ */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label
                        htmlFor="valuationOfImmovablePropertyActValue"
                        className="pre"
                      >
                        <ResourceText resourceKey="wozValue" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="valuationOfImmovablePropertyActValue"
                          pretty
                          step={1000}
                          asPrice
                        />
                      </div>
                    </div>
                    <div className="column__textspacer">
                      <ResourceText resourceKey="per" />
                    </div>
                    <div className="column">
                      <Input.Datepicker name="dateOfIssue" />
                    </div>
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="AssignmentFinancialRent.CourtageTab">
                <Commission
                  commissionFor={CommissionFor.Rent}
                  offer={rentOffer}
                  isRentForConsumer={true}
                  onChange={this.onRentOfferChangesHandler}
                />
              </StepComponent>

              <StepComponent title="acceptanceAndAvailability">
                {/* Acceptance */}
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="acceptance" />
                    <Ui.RequiredForPublish />
                  </label>
                  <div className="form__group">
                    <div className="column">
                      <Input.RadioGroup name="acceptance" asButtonList>
                        {acceptances.map((acceptance, idx) => (
                          <Input.Radio
                            label={acceptance.displayName}
                            value={acceptance.value}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                    {this.state.showAcceptanceDate && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="date" />
                        </div>
                        <div className="column">
                          <Input.Datepicker name="acceptanceDate" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Availability */}
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="availabilityIsTemporary" />
                  </label>
                  <div className="form__group">
                    <div className="column">
                      <Input.RadioGroup
                        name="availabilityIsTemporary"
                        asButtonList
                      >
                        <Input.Radio
                          label="availabilityNotTemporary"
                          value={false}
                          checked={this.state.availabilityIsTemporary === false}
                        />
                        <Input.Radio
                          label="availabilityTemporary"
                          value={true}
                          checked={this.state.availabilityIsTemporary === true}
                        />
                      </Input.RadioGroup>
                    </div>
                    {this.state.showAvailabilityDates && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="availableFrom" />
                        </div>
                        <div className="column">
                          <Input.Datepicker name="availableFrom" />
                        </div>
                        <div className="column__textspacer">
                          <ResourceText resourceKey="availableUntil" />
                        </div>
                        <div className="column">
                          <Input.Datepicker name="availableUntil" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Minimum lease term */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="minimumLeaseTermApplicable"
                        on={true}
                        off={false}
                        label="minimumLeaseTermApplicable"
                        labelPosition={SwitchLabelPosition.Pre}
                      />
                    </div>
                    {this.state.showMinimumLeaseTermFields && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="of" />
                        </div>
                        <div className="column">
                          <Input.Number name="minimumLeaseTermUnits" />
                        </div>
                        <div className="column__spacer" />
                        <div className="column">
                          <Input.NewSelect
                            name="minimumLeaseTermUnit"
                            values={this.props.leaseTermUnitOptions}
                            displayProp="displayNamePlural"
                            valuesProp="value"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Max lease term */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="maximumLeaseTermApplicable"
                        on={true}
                        off={false}
                        label="maximumLeaseTermApplicable"
                        labelPosition={SwitchLabelPosition.Pre}
                      />
                    </div>
                    {this.state.showMaximumLeaseTermFields && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="of" />
                        </div>
                        <div className="column">
                          <Input.Number round name="maximumLeaseTermUnits" />
                        </div>
                        <div className="column__spacer" />
                        <div className="column">
                          <Input.NewSelect
                            name="maximumLeaseTermUnit"
                            values={this.props.leaseTermUnitOptions}
                            displayProp="displayNamePlural"
                            valuesProp="value"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="costPostBuilding">
                {/* Value assignment */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="commissionContactGross">
                      <ResourceText resourceKey="customerContactGrossRent" />
                      <Ui.RequiredForPublish />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="commissionContactGross"
                        pretty
                        step={10}
                        asPrice
                        disabled={
                          this.state.tempCalculatedCommissionContactGross
                        }
                      />
                      <div className="post full">
                        <ResourceText resourceKey="oneTime" />
                      </div>
                    </div>
                  </div>

                  <FeatureSwitch feature="CALCULATE_RENT_COSTS">
                    <div className="form__group" styleName="calculate">
                      <div className="column">
                        <Input.CheckBox name="toggleCalculatedCommissionContactGross" />
                      </div>
                      <div className="column__textspacer">
                        <ResourceText resourceKey="toggleCalculatedCommissionContactGross" />
                      </div>
                      <div className="column" styleName="calculate__input">
                        <Input.Number
                          name="tempCalculatedCommissionContactGross"
                          disabled={
                            !this.state.tempCalculatedCommissionContactGross
                          }
                        />
                      </div>
                      <div className="column__textspacer">
                        {this.renderCalculationTerm(
                          "tempCalculatedCommissionContactGross"
                        )}
                      </div>
                    </div>
                  </FeatureSwitch>
                </div>

                {/* Deposit */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="deposit">
                      <ResourceText resourceKey="deposit" />
                      <Ui.RequiredForPublish />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="deposit"
                        pretty
                        step={10}
                        asPrice
                        disabled={this.state.tempCalculatedDepositGross}
                      />
                      <div className="post full">
                        <ResourceText resourceKey="oneTime" />
                      </div>
                    </div>
                  </div>

                  <FeatureSwitch feature="CALCULATE_RENT_COSTS">
                    <div className="form__group" styleName="calculate">
                      <div className="column">
                        <Input.CheckBox name="toggleCalculatedDepositGross" />
                      </div>
                      <div className="column__textspacer">
                        <ResourceText resourceKey="toggleCalculatedDepositGross" />
                      </div>
                      <div className="column" styleName="calculate__input">
                        <Input.Number
                          name="tempCalculatedDepositGross"
                          disabled={!this.state.tempCalculatedDepositGross}
                        />
                      </div>
                      <div className="column__textspacer">
                        {this.renderCalculationTerm(
                          "tempCalculatedDepositGross"
                        )}
                      </div>
                    </div>
                  </FeatureSwitch>
                </div>

                {/* Advance */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="advance">
                      <ResourceText resourceKey="advance" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="advance" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="oneTime" />
                      </div>
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="totalOneTime" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.totalOneTime}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>

                {/* Basic rent */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="basicRent">
                      <ResourceText resourceKey="basicRent" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="basicRent" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gas */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationGas">
                      <ResourceText resourceKey="indicationGas" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationGas"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Service costs */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="serviceCosts">
                      <ResourceText resourceKey="serviceCostsRent" />
                      <Ui.RequiredForPublish />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="serviceCosts"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Water */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationWater">
                      <ResourceText resourceKey="indicationWater" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationWater"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Electra */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationElectra">
                      <ResourceText resourceKey="indicationElectra" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationElectra"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Television */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationTelevision">
                      <ResourceText resourceKey="indicationTelevision" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationTelevision"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Internet */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationInternet">
                      <ResourceText resourceKey="indicationInternet" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationInternet"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Furniture */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="furniture">
                      <ResourceText resourceKey="furniture" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="furniture" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Soft furnishings */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="softFurnishings">
                      <ResourceText resourceKey="softFurnishings" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="softFurnishings"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Heating */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationHeatingCosts">
                      <ResourceText resourceKey="indicationHeatingCosts" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationHeatingCosts"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perMonth" />
                      </div>
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="totalPerMonth" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.totalMonthly}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>

                {/* Sewerage */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="sewerageCharges">
                      <ResourceText resourceKey="sewerageCharges" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="sewerageCharges"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perYear" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Water authority */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="waterAuthorityCharges">
                      <ResourceText resourceKey="waterAuthorityCharges" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="waterAuthorityCharges"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="perYear" />
                      </div>
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="totalPerYear" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.totalYearly}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    let totalMonthly = 0;
    let totalYearly = 0;
    let totalOneTime = 0;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const raw = parseFloat(values[key]);
        const value = isNaN(raw) ? 0 : raw;

        switch (key) {
          case "basicRent":
          case "indicationGas":
          case "serviceCosts":
          case "indicationWater":
          case "indicationElectra":
          case "indicationTelevision":
          case "indicationInternet":
          case "furniture":
          case "softFurnishings":
          case "indicationHeatingCosts":
            totalMonthly = totalMonthly + value;
            break;
          case "sewerageCharges":
          case "waterAuthorityCharges":
            totalYearly = totalYearly + value;
            break;
          case "commissionContactGross":
          case "deposit":
          case "advance":
            totalOneTime = totalOneTime + value;
            break;
          default:
            break;
        }
      }
    }

    this.setState({ totalYearly, totalMonthly, totalOneTime });

    const maximumLeaseTerm = values.maximumLeaseTermApplicable
      ? {
          ...this.props.rentOffer.maximumLeaseTerm,
          isApplicable: values.maximumLeaseTermApplicable,
          unit: values.maximumLeaseTermUnit,
          numberOfUnits: values.maximumLeaseTermUnits,
        }
      : undefined;

    const minimumLeaseTerm = values.minimumLeaseTermApplicable
      ? {
          ...this.props.rentOffer.minimumLeaseTerm,
          isApplicable: values.minimumLeaseTermApplicable,
          unit: values.minimumLeaseTermUnit,
          numberOfUnits: values.minimumLeaseTermUnits,
        }
      : undefined;

    const rentOffer: RentOffer = {
      ...this.props.rentOffer,
      advance: values.advance,
      priceCode: values.priceCode,
      availabilityIsTemporary: values.availabilityIsTemporary,
      availableFrom: values.availableFrom,
      availableUntil: values.availableUntil,
      basicRent: values.basicRent,
      commissionContactGross: values.commissionContactGross,
      deposit: values.deposit,
      furniture: values.furniture,
      indicationElectra: values.indicationElectra,
      indicationGas: values.indicationGas,
      indicationHeatingCosts: values.indicationHeatingCosts,
      indicationInternet: values.indicationInternet,
      indicationTelevision: values.indicationTelevision,
      indicationWater: values.indicationWater,
      maximumLeaseTerm,
      minimumLeaseTerm,
      rentCondition: values.rentCondition,
      rentPrice: values.rentPrice,
      rentSpecifications: values.rentSpecifications,
      serviceCosts: values.serviceCosts,
      sewerageCharges: values.sewerageCharges,
      softFurnishings: values.softFurnishings,
      valuationOfImmovablePropertyAct: {
        ...this.props.rentOffer.valuationOfImmovablePropertyAct,
        value: values.valuationOfImmovablePropertyActValue,
        dateOfIssue: values.dateOfIssue,
      },
      vatCondition: values.vatCondition,
      waterAuthorityCharges: values.waterAuthorityCharges,
      rentPriceIsIndexed: values.rentPriceIsIndexed,
    };

    const acceptanceDetails = {
      acceptance: values.acceptance,
      date: values.acceptanceDate,
    };

    this.props.onChange({
      rentOffer,
      acceptanceDetails,
      hidePrice: values.hidePrice,
    });
  }

  private onRentOfferChangesHandler(values: CommissionResponse) {
    const rentOffer = {
      ...this.props.rentOffer,
      ...values,
    };

    this.props.onChange({ rentOffer });
  }

  private renderCalculationTerm(field: string) {
    if (!this.formRef) return null;
    const values = this.formRef.getValues();
    const value = values[field] || 0;

    const rentCondition = get(
      this.props.objectAssignment,
      "rentOffer.rentCondition",
      RentCondition.PricePerMonth
    );

    switch (rentCondition) {
      case RentCondition.PricePerDay: {
        return (
          <>
            <ResourceText resourceKey="pluralDay" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      case RentCondition.PricePerWeek: {
        return (
          <>
            <ResourceText resourceKey="pluralWeek" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      case RentCondition.PricePerMonth: {
        return (
          <>
            <ResourceText resourceKey="pluralMonth" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      case RentCondition.PricePerQuarter: {
        return (
          <>
            <ResourceText resourceKey="pluralQuarter" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      case RentCondition.PricePerHalfYear: {
        return (
          <>
            <ResourceText resourceKey="pluralHalfYear" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      case RentCondition.PricePerYear: {
        return (
          <>
            <ResourceText resourceKey="pluralYear" values={{ value }} />
            &nbsp;
            <ResourceText resourceKey="calculatedRent" />
          </>
        );
      }
      default:
        return null;
    }
  }
}
