import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  label?: string;
};

export const UiActionListSpacerComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ label }) => {
    return (
      <div styleName={classNames("spacer")}>
        {!!label && <I18n value={label} />}
      </div>
    );
  })
);
