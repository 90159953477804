import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("../loaders.scss");

interface Props {
  mask?: boolean;
}

export const FullscreenLoader = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => (
    <div styleName={classNames("fullscreen-loader", { masked: props.mask })}>
      <div styleName="line-scale">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
);
