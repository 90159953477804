import get from "lodash-es/get";
import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import has from "lodash-es/has";

export interface VVEOption {
  value: string;
  displayName: string;
}

export class Acquisition {
  public static getSelectedVVEOptions(
    objectAssignment: AcquisitionObjectAssignment,
    vVeOptions: VVEOption[]
  ): VVEOption[] {
    if (objectAssignment)
      return vVeOptions.reduce((state, option) => {
        if (get(objectAssignment, option.value)) {
          state.push(option.value);
        }
        return state;
      }, []);
  }

  public static setSelectedVVEOptions(
    selectedVveOptions: string[],
    vVeOptions: VVEOption[]
  ): { [key: string]: boolean } {
    const values = {};

    vVeOptions.map((option) => {
      values[option.value] = selectedVveOptions.indexOf(option.value) !== -1;
    });

    return values;
  }

  public static validateSubRoutes(
    acquisitionObjectAssignment: AcquisitionObjectAssignment
  ) {
    const valid = {
      address: false,
      media: false
    };
    // Adress
    if (has(acquisitionObjectAssignment, "address.postalCode")) {
      valid.address = true;
    }
    // Media
    if (
      (acquisitionObjectAssignment.photos &&
        acquisitionObjectAssignment.photos.length > 0) ||
      has(acquisitionObjectAssignment, "advertisementText") ||
      (acquisitionObjectAssignment.maps &&
        acquisitionObjectAssignment.maps.length > 0) ||
      (acquisitionObjectAssignment.videos &&
        acquisitionObjectAssignment.videos.length > 0) ||
      (acquisitionObjectAssignment.brochures &&
        acquisitionObjectAssignment.brochures.length > 0)
    ) {
      valid.media = true;
    }

    return valid;
  }
}
