import {
  RealEstateAgencyClient,
  ActivateRealEstateAgencyRequest,
  ReadRealEstateAgencyResponse,
  GroupClient,
} from "@haywork/api/mls";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { Dispatch } from "react-redux";
import { MlsAccessData } from "@haywork/stores/mls/main/actions";
import { REQUEST } from "@haywork/constants";

const parseRequest = new ParseRequest();

const getRealEstateAgency = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.PENDING));
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstateAgencyClient(mlsHost);
      const groupClient = new GroupClient(mlsHost);

      const groupsData = await groupClient
        .search({ skip: 0, take: 100 }, realEstateAgencyId)
        .then((response) => response.result || []);

      const response = await parseRequest.response(
        client.read(realEstateAgencyId, realEstateAgencyId)
      );

      if (!response) throw new Error("No agencies found");

      const groups = (response?.realEstateAgency?.allowedGroups || [])
        .map((allowed) => groupsData.find((group) => group.name === allowed))
        .filter((d) => !!d);
      const appClients = response?.realEstateAgency?.allowedAppClients;
      const mlsAccessData: MlsAccessData = {
        demoUntilDateTime: response?.realEstateAgency?.demoUntilDateTime,
        isActive: response?.realEstateAgency?.isActive,
        dateTimeActivated: response?.realEstateAgency?.dateTimeActivated,
        activatedByEmployeeId:
          response?.realEstateAgency?.activatedByEmployeeId,
      };
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.SUCCESS));
      dispatch(MLSActions.Main.setAccessData(mlsAccessData));
      dispatch(MLSActions.List.setRealEstateAgencyGroups(groups));
      dispatch(MLSActions.List.setRealEstateAgencyAppClients(appClients));
    } catch (error) {
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const toggleMlsSubscription = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.PENDING));
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { isActive: stateIsActive } = state.mls.main?.mlsAccessData || {};
      const client = new RealEstateAgencyClient(mlsHost);

      const request: ActivateRealEstateAgencyRequest = {
        realEstateAgencyId,
      };

      const response: ReadRealEstateAgencyResponse =
        await parseRequest.response(
          !stateIsActive
            ? client.activate(request, realEstateAgencyId)
            : client.deactivate(request, realEstateAgencyId)
        );

      const {
        isActive,
        dateTimeActivated,
        demoUntilDateTime,
        activatedByEmployeeId,
        dateTimeModified,
      } = response?.realEstateAgency;

      const mlsAccessData = {
        isActive,
        dateTimeActivated,
        demoUntilDateTime,
        activatedByEmployeeId,
        dateTimeModified,
      };
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.SUCCESS));
      dispatch(MLSActions.Main.setAccessData(mlsAccessData));
    } catch (error) {
      dispatch(MLSActions.Main.setRequestStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

export default {
  getRealEstateAgency,
  toggleMlsSubscription,
};
