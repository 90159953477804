import {
  MergeField,
  TemplateDefinition,
  TemplateDefinitionCategorySnapShot,
} from "@haywork/api/kolibri";
import { EMAILTEMPLATESROUTES } from "@haywork/constants";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { editable } from "@haywork/modules/editable";
import { AppState, EmailActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  SettingsEmailTemplateEditComponent,
  SettingsEmailTemplateEditComponentProps,
} from "../components/edit/edit.component";
import { categories } from "./selectors";

interface StateProps {
  status: string;
  template: TemplateDefinition;
  culture: string;
  uploadUrl: string;
  categories: TemplateDefinitionCategorySnapShot[];
  mergeFields: MergeField[];
}
interface DispatchProps {
  saveTemplate: (template: TemplateDefinition) => Promise<void>;
  saveAndCloseTemplate: (
    templateDefinition: TemplateDefinition
  ) => Promise<void>;
  getTemplatePreview: (
    template: TemplateDefinition
  ) => Promise<TemplateDefinition>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsEmailTemplateEditComponentProps,
  AppState
> = (state) => {
  const { singleStatus: status } = state.email.templates;
  const { mergeFields } = state.emailV2.templates;
  const { currentComponentState: template } = state.editable;
  const { culture } = state.main;
  const { currentRealestateAgency } = state.account;
  const { apiVersion, host } = state.appSettings;

  const uploadUrl = `${host}/${apiVersion}/${currentRealestateAgency.id}/Blobs/Upload`;

  return {
    status,
    template,
    culture,
    uploadUrl,
    categories: categories(state),
    mergeFields,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsEmailTemplateEditComponentProps
> = (dispatch) => ({
  saveTemplate: (template: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.saveTemplate(template)),
  saveAndCloseTemplate: (templateDefinition: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.saveAndCloseTemplate(templateDefinition)),
  getTemplatePreview: (template: TemplateDefinition) =>
    dispatch(EmailTemplatesThunks.getTemplatePreview(template)),
});

export type SettingsEmailTemplateEditContainerProps = StateProps &
  DispatchProps;
export const SettingsEmailTemplateEditContainer = editable(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsEmailTemplateEditComponent),
  {
    entityType: null,
    icon: "file",
    thunk: EmailTemplatesThunks.readTemplate,
    status: "email.templates.singleStatus",
    statePath: "email.templates.single",
    action: EmailActions.Templates.setSingle,
    route: EMAILTEMPLATESROUTES.DETAIL.URI,
    confirm: {
      title: { key: "emailTemplateConfirmTitle" },
      body: { key: "emailTemplateConfirmBody" },
    },
  }
);
