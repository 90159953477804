import { PATTERN } from "@haywork/constants";
import { Hint } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./email-query.component.scss");

interface Props {
  email: {
    email: string;
    name?: string;
  };
  last: boolean;
  deleteLastEmail: boolean;
  onRemovePill: () => void;
}
interface State {
  markedForDelete: boolean;
  valid: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class Pill extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);

    this.state = {
      markedForDelete: false,
      valid: this.isValidEmail(this.props.email.email),
    };
  }

  public render() {
    const { email, name } = this.props.email;
    const pillStyle = classNames("pill", {
      "marked-for-delete":
        this.state.markedForDelete ||
        (this.props.deleteLastEmail && this.props.last),
      warning: !this.state.valid,
    });

    return (
      <div styleName={pillStyle} data-cy="CY-pillItem">
        <Hint
          message="emailQueryMissingEmailAddress"
          shouldRender={!this.state.valid}
        >
          <React.Fragment>
            <div styleName="pill__label" data-cy={this.props["data-cy"]}>
              {!this.state.valid && (
                <i className="fa fa-exclamation-triangle" />
              )}
              {!!name ? (!!email ? `${name} <${email}>` : name) : email}
            </div>
            <div
              styleName="pill__remove"
              onClick={this.props.onRemovePill}
              onMouseEnter={this.onMouseEnterHandler}
              onMouseLeave={this.onMouseLeaveHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.RemoveButton`
              }
            >
              <i className="far fa-times" />
            </div>
          </React.Fragment>
        </Hint>
      </div>
    );
  }

  private onMouseEnterHandler() {
    this.setState({
      markedForDelete: true,
    });
  }

  private onMouseLeaveHandler() {
    this.setState({
      markedForDelete: false,
    });
  }

  private isValidEmail(email: string): boolean {
    return !!email && new RegExp(PATTERN.EMAIL).test(email) === true;
  }
}
