import {
  Dispatch,
  ExtendedRelationSnapShot,
  RelationThunks,
} from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RelationInfoComponent } from "./relation-info";

interface StateProps {}
interface DispatchProps {
  navigate: (route: string) => void;
  extendRelationInfo: (ids: string[]) => Promise<ExtendedRelationSnapShot[]>;
}

const mapStateToProps = <StateProps, RelationInfoComponentProps>(
  state: AppState
) => ({});

const mapDispatchToProps = <DispatchProps, RelationInfoComponentProps>(
  dispatch: Dispatch<any>
) => ({
  navigate: (route: string) => dispatch(push(route)),
  extendRelationInfo: (ids: string[]) =>
    dispatch(RelationThunks.searchRelations(ids)),
});

export type RelationInfoContainerProps = StateProps & DispatchProps;
export const RelationInfoContainer = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(RelationInfoComponent);
