import { createSelector } from "reselect";
import { AppState, PresentEmployee } from "@haywork/stores";
import uniqBy from "lodash-es/uniqBy";
import findIndex from "lodash-es/findIndex";

const presentEmployeesSelector = (state: AppState) =>
  state.eventCenter.presence.presentEmployees;
const employeesSelector = (state: AppState) => state.account.employees;

export const presentEmployees = createSelector(
  presentEmployeesSelector,
  employeesSelector,
  (presentEmployees, employees) => {
    const reducedPresentEmployees = presentEmployees.reduce<PresentEmployee[]>(
      (state, employee) => {
        const idx = findIndex(
          state,
          (item) =>
            item.employeeId === employee.employeeId &&
            item.fingerprint === employee.fingerprint
        );

        if (idx !== -1) {
          state[idx] = {
            ...employee,
            entities: uniqBy(
              [...state[idx].entities, ...employee.entities],
              (id) => id
            )
          };
        } else {
          state.push(employee);
        }

        return state;
      },
      []
    );

    return reducedPresentEmployees
      .map((presentEmployee) => {
        const employee = employees.find(
          (employee) => presentEmployee.employeeId === employee.id
        );
        if (!employee) return null;
        return {
          employee,
          count: presentEmployee.entities.length,
          info: {
            appVersion: presentEmployee.appVersion,
            browser: presentEmployee.browser
          }
        };
      })
      .filter((d) => !!d);
  }
);
