import {
  AcquisitionObjectAssignment,
  Bid,
  BidType,
  LinkedAssignment,
  LinkedEmployee,
  LinkedRelation,
} from "@haywork/api/kolibri";
import {
  BidThunk,
  InvoiceThunk,
  MapDispatchToProps,
  SchedulerThunks,
  TaskThunks,
} from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionAssignmentDetailGeneralScreen,
  AcquisitionAssignmentDetailGeneralScreenProps,
} from "./assignments-detail-general";
import { linkedEmployee } from "./selectors";

type StateProps = {
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
  canSendEmail: boolean;
  linkedEmployee: LinkedEmployee;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  createNewBid: (
    assignmentId: string,
    linkedEmployee: LinkedEmployee
  ) => Promise<Bid>;
  createTaskWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createInvoiceWithLinkedAssignment: (
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment
  ) => Promise<void>;
  createNewEmail: (
    linkedAssignment: LinkedAssignment,
    displayName: string
  ) => void;
  archiveAcquisitionObject: (id: string) => Promise<void>;
  unArchiveAcquisitionObject: (id: string) => Promise<void>;
  deleteAcquisitionObject: (id: string) => Promise<void>;
  createCounterOffer: (
    bid: Bid,
    linkedRelations?: LinkedRelation[]
  ) => Promise<Bid>;
  updateAcquisitionObject: (
    acquisitionAssignmentObject: AcquisitionObjectAssignment
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionAssignmentDetailGeneralScreenProps,
  AppState
> = (state) => {
  const { acquisitionAssignmentObject } = state.acquisition.assignment;

  return {
    acquisitionAssignmentObject,
    canSendEmail: canSendEmail(state),
    linkedEmployee: linkedEmployee(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionAssignmentDetailGeneralScreenProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  createNewBid: (assignmentId: string, linkedEmployee: LinkedEmployee) =>
    dispatch(
      BidThunk.createNewBid(
        assignmentId,
        BidType.Outgoing,
        undefined,
        linkedEmployee
      )
    ),
  createTaskWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(
      TaskThunks.createTaskWithLinkedEntities(undefined, [linkedAssignment])
    ),
  createInvoiceWithLinkedAssignment: (linkedAssignment: LinkedAssignment) =>
    dispatch(
      InvoiceThunk.createInvoiceWithLinkedEntities(
        undefined,
        linkedAssignment,
        undefined,
        undefined,
        "Consumer"
      )
    ),
  createAgendaItemWithLinkedAssignment: (
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment
  ) =>
    dispatch(
      SchedulerThunks.createAgendaItemWithLinkedEntities(linkedRelations, [
        linkedAssignment,
      ])
    ),
  createNewEmail: (linkedAssignment: LinkedAssignment, displayName: string) =>
    dispatch(
      EmailThunk.Main.createNewEmail([], [], [linkedAssignment], displayName)
    ),
  archiveAcquisitionObject: (id: string) =>
    dispatch(AcquisitionThunks.AcquisitionObject.archiveAcquisitionObject(id)),
  unArchiveAcquisitionObject: (id: string) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.unArchiveAcquisitionObject(id)
    ),
  deleteAcquisitionObject: (id: string) =>
    dispatch(AcquisitionThunks.AcquisitionObject.deleteAcquisitionObject(id)),
  createCounterOffer: (bid: Bid, linkedRelations?: LinkedRelation[]) =>
    dispatch(BidThunk.createCounterOffer(bid, linkedRelations)),
  updateAcquisitionObject: (
    acquisitionAssignmentObject: AcquisitionObjectAssignment
  ) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.save(
        acquisitionAssignmentObject,
        false
      )
    ),
});

export type AcquisitionAssignmentDetailGeneralScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionAssignmentDetailGeneralScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionAssignmentDetailGeneralScreen);
