import {
  ObjectAssignment,
  Testimonial,
  WebsiteProvider
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES, HOUSENET_OFFER } from "@haywork/constants";
import { AssignmentEditMarketingWebsiteContainerProps } from "@haywork/modules/assignment";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators
} from "@haywork/modules/form";
import {
  Hint,
  HintAlignment,
  ResourceText,
  StepComponent,
  StepperComponent
} from "@haywork/modules/shared";
import { SingleAssignmentState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import head from "lodash-es/head";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  TestimonialComponent,
  TestimonialObject
} from "./testimonial-input.component";

const styles = require("./marketing-website.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentEditMarketingWebsiteComponentProps {}
interface State {
  showHousenetAd: boolean;
}
type Props = AssignmentEditMarketingWebsiteComponentProps &
  AssignmentEditMarketingWebsiteContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditMarketingWebsiteComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      showHousenetAd: !get(this.props.settings, "housenetEnabled")
    };

    const testimonialObject: TestimonialObject = {
      iList: value(this.props.objectAssignment, "testimonial.iList", []),
      testimonialPhoto: value(
        this.props.objectAssignment,
        "testimonial.testimonialPhoto",
        null
      )
    };
    const showFrom = value(
      this.props.objectAssignment,
      "testimonial.showFrom",
      false
    )
      ? true
      : false;

    this.formControls = {
      titleText: { value: value(this.props.objectAssignment, "titleText") },
      webAddress: {
        value: value(this.props.objectAssignment, "webAddress"),
        validators: [Validators.url(undefined, true)]
      },
      isSpecial: {
        value: value(this.props.objectAssignment, "isSpecial", false)
      },
      isTopper: {
        value: value(this.props.objectAssignment, "isTopper", false)
      },
      testimonial: {
        value: testimonialObject,
        onChange: (ref, get) => {
          const showFrom = get("showFrom").value;
          if (!showFrom && ref.value.iList && ref.value.iList.length > 0)
            return { showFrom: true };
        }
      },
      showFrom: { value: showFrom },
      showNrOfDaysOnMarket: {
        value: value(
          this.props.objectAssignment,
          "testimonial.showNrOfDaysOnMarket"
        )
      }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const testimonialObject: TestimonialObject = {
        iList: value(this.props.objectAssignment, "testimonial.iList", []),
        testimonialPhoto: value(
          this.props.objectAssignment,
          "testimonial.testimonialPhoto",
          null
        )
      };
      const showFrom = value(
        this.props.objectAssignment,
        "testimonial.showFrom",
        false
      )
        ? true
        : false;

      this.formRef.update({
        titleText: value(this.props.objectAssignment, "titleText"),
        webAddress: value(this.props.objectAssignment, "webAddress"),
        isSpecial: value(this.props.objectAssignment, "isSpecial", false),
        isTopper: value(this.props.objectAssignment, "isTopper", false),
        testimonial: testimonialObject,
        showFrom,
        showNrOfDaysOnMarket: value(
          this.props.objectAssignment,
          "testimonial.showNrOfDaysOnMarket"
        )
      }, true);
    }
  }

  public render() {
    const { displayName, emailAddresses, phoneNumbers } = this.props.employee;
    const { name } = this.props.currentRealestateAgency;
    const email = head(
      (emailAddresses || []).map((email) => email.address).filter((d) => !!d)
    );
    const phone = head(
      (phoneNumbers || []).map((phone) => phone.number).filter((d) => !!d)
    );
    const url = `https://forms.zohopublic.eu/wazzup/form/HousenetLightleadform/formperma/vJ4XSczHgW4yAzohusIrsNANWrvgdD0mUDKFq_RWKi0/?WZNAME=${displayName ||
      ""}&WZCOMPANY=${name || ""}&WZPHONE=${phone || ""}&WZEMAIL=${email ||
      ""}`;

    return (
      <>
        <Form
          name="marketing-website"
          formControls={this.formControls}
          onChange={this.onChangeHandler}
          form={(form) => (this.formRef = form)}
        >
          <StepperComponent initial={0} scrollToElementId="scroll-to-top">
            <StepComponent title="titleText">
              <div>
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="titleTextLabel" />
                  </label>
                  <Input.TranslateText
                    name="titleText"
                    languages={this.props.languages}
                    truncate={200}
                    placeholder="titleTextPlaceholder"
                    defaultLanguage={this.props.defaultLanguage}
                  />
                </div>

                {get(
                  this.props.settings,
                  "matchMailSettings.websiteProvider"
                ) !== WebsiteProvider.Housenet3 && (
                  <div className="form__row">
                    <div className="form__group" styleName="web-address">
                      <div className="column grow1">
                        <label htmlFor="webAddress">
                          <ResourceText resourceKey="webAddressLabel" />
                        </label>
                        <Input.Text
                          name="webAddress"
                          placeholder="webAddressPlaceholder"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div
                        className="column push-label grow-none"
                        styleName="tooltip"
                      >
                        <Hint
                          message="webAddressHelp"
                          align={HintAlignment.Right}
                        >
                          <span className="toolTip">
                            <i className="fa fa-fw fa-info-circle" />{" "}
                            <ResourceText resourceKey="whatIsThis" />
                          </span>
                        </Hint>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="isSpecial"
                        label="isSpecial"
                        labelPosition={SwitchLabelPosition.Pre}
                        on={true}
                        off={false}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="tooltip">
                      <Hint message="isSpecialHelp" align={HintAlignment.Right}>
                        <span className="toolTip">
                          <i className="fa fa-fw fa-info-circle" />{" "}
                          <ResourceText resourceKey="whatIsThis" />
                        </span>
                      </Hint>
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="isTopper"
                        label="isTopper"
                        labelPosition={SwitchLabelPosition.Pre}
                        on={true}
                        off={false}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="tooltip">
                      <Hint message="isTopperHelp" align={HintAlignment.Right}>
                        <span className="toolTip">
                          <i className="fa fa-fw fa-info-circle" />{" "}
                          <ResourceText resourceKey="whatIsThis" />
                        </span>
                      </Hint>
                    </div>
                  </div>
                </div>
              </div>
            </StepComponent>
            <StepComponent title="testimonial">
              <div>
                <div className="form__row">
                  <TestimonialComponent
                    name="testimonial"
                    languages={this.props.languages}
                  />
                </div>

                <div className="form__row">
                  <Input.Switch
                    name="showFrom"
                    label="showFrom"
                    labelPosition={SwitchLabelPosition.Pre}
                    on={true}
                    off={false}
                  />
                </div>

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="showNrOfDaysOnMarket"
                        label="showNrOfDaysOnMarket"
                        labelPosition={SwitchLabelPosition.Pre}
                        on={true}
                        off={false}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column" styleName="tooltip">
                      <Hint
                        message="showNrOfDaysOnMarketHelp"
                        align={HintAlignment.Right}
                      >
                        <i className="fa fa-fw fa-info-circle" />{" "}
                        <ResourceText resourceKey="whatIsThis" />
                      </Hint>
                    </div>
                  </div>
                </div>
              </div>
            </StepComponent>
          </StepperComponent>
        </Form>
        <FeatureSwitch feature="HOUSENET_AD">
          {this.state.showHousenetAd && (
            <div styleName="housenet-ad">
              <div
                styleName="housenet-ad__close"
                onClick={() => this.setState({ showHousenetAd: false })}
              >
                <i className="fal fa-times" />
              </div>
              <div styleName="housenet-ad__inner">
                <div className="container-fluid">
                  <ResourceText
                    resourceKey="housenetAd.marketingContent"
                    values={{
                      url,
                      offer: HOUSENET_OFFER
                    }}
                    asHtml={true}
                  />
                </div>
              </div>
            </div>
          )}
        </FeatureSwitch>
      </>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment, currentComponentState } = this.props;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id
    });

    const testimonial: Testimonial = objectAssignment.testimonial || {
      isForRent: objectAssignment.forRent,
      isForSale: objectAssignment.forSale,
      showNrOfDaysOnMarket: values.showNrOfDaysOnMarket || false
    };

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      titleText: values.titleText,
      isSpecial: values.isSpecial,
      isTopper: values.isTopper,
      webAddress: values.webAddress,
      testimonial: {
        ...testimonial,
        ...values.testimonial,
        showFrom: values.showFrom ? new Date() : null,
        showUntil: values.showFrom
          ? null
          : get(objectAssignment.testimonial, "showUntil") || new Date(),
        showNrOfDaysOnMarket: values.showNrOfDaysOnMarket || false
      }
    };

    const newState: SingleAssignmentState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment
    };

    this.props.updateAssignment(newState, path);
  }
}
