import {
  EventTimeSpan,
  MomentInTimeType,
  NotificationSettings,
  UnitOfTime,
} from "@haywork/api/event-center";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./forms.component.scss");

interface Props {
  setting: NotificationSettings;
  onChange: (timeSpans: EventTimeSpan[]) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class MultiTimeSpansFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    let weeks = 1;
    let months = 1;
    let years = 1;

    if (!!get(this.props.setting, "timeSpans")) {
      const week = this.props.setting.timeSpans.find(
        (timeSpan) => timeSpan.unitOfTime === UnitOfTime.Week
      );
      weeks = !!week ? week.amountOfTime : 1;

      const month = this.props.setting.timeSpans.find(
        (timeSpan) => timeSpan.unitOfTime === UnitOfTime.Month
      );
      months = !!month ? month.amountOfTime : 1;

      const year = this.props.setting.timeSpans.find(
        (timeSpan) => timeSpan.unitOfTime === UnitOfTime.Year
      );
      years = !!year ? year.amountOfTime : 1;
    }

    this.formControls = {
      weeks: { value: weeks },
      months: { value: months },
      years: { value: years },
    };
  }

  public render() {
    return (
      <Form
        name={`multiTimeSpans.${this.props.setting.eventType.toString()}`}
        formControls={this.formControls}
        onChange={this.onChangeHandler}
      >
        <div className="form__row" styleName="row">
          <div className="form__group">
            <div className="column">
              <div className="input__helper" styleName="reservation">
                <Input.Number name="weeks" min={1} max={52} />
                <div className="post full">
                  <ResourceText resourceKey="weeks" />
                </div>
              </div>
            </div>

            <div className="column__spacer" />

            <div className="column">
              <div className="input__helper" styleName="reservation">
                <Input.Number name="months" min={1} max={12} />
                <div className="post full">
                  <ResourceText resourceKey="months" />
                </div>
              </div>
            </div>

            <div className="column__spacer" />

            <div className="column">
              <div className="input__helper" styleName="reservation">
                <Input.Number name="years" min={1} max={99} />
                <div className="post full">
                  <ResourceText resourceKey="years" />
                </div>
              </div>
            </div>

            <div className="column__spacer" />

            <div className="column">
              <label className="post">
                <ResourceText
                  resourceKey={`notificationSettingsLabelMultiTimeSpans.${this.props.setting.eventType.toString()}`}
                />
              </label>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const timeSpans: EventTimeSpan[] = [
      {
        unitOfTime: UnitOfTime.Week,
        amountOfTime: values.weeks,
        momentInTime: MomentInTimeType.AfterEvent,
      },
      {
        unitOfTime: UnitOfTime.Month,
        amountOfTime: values.months,
        momentInTime: MomentInTimeType.AfterEvent,
      },
      {
        unitOfTime: UnitOfTime.Year,
        amountOfTime: values.years,
        momentInTime: MomentInTimeType.AfterEvent,
      },
    ];

    this.props.onChange(timeSpans);
  }
}
