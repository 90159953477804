import { InvoiceOrderByField, SortOrder } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Pill from "@haywork/components/ui/pill";
import { REQUEST } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { InvoiceOverviewContainerProps } from "@haywork/modules/invoice";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
  UiTableColumnConfig,
} from "@haywork/modules/ui";
import { InvoiceFiltering } from "@haywork/stores";
import { SimpleLabelOriginal } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ListItem } from "./list-item/list-item.component";
import { InvoiceFilterSidebarComponent } from "./sidebar/sidebar.component";
import EntityListStatus from "@haywork/modules/event-center/components/entity-list-status";
import noop from "lodash-es/noop";

const styles = require("./overview.component.scss");

export interface InvoiceOverviewComponentProps {}
interface State {
  toggleShowSidebar: boolean;
}
type Props = InvoiceOverviewComponentProps & InvoiceOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class InvoiceOverviewComponent extends React.Component<Props, State> {
  private columnConfig: UiTableColumnConfig[];

  constructor(props) {
    super(props);

    this.reloadInvoices = this.reloadInvoices.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onSortingChangeHandler = this.onSortingChangeHandler.bind(this);

    this.state = {
      toggleShowSidebar: false,
    };

    this.columnConfig = [
      {
        title: "invoiceNumberOverview",
        width: 172,
        orderBy: InvoiceOrderByField.InvoiceNumber,
      },
      {
        title: "InvoiceAmount",
        width: 128,
        orderBy: InvoiceOrderByField.InvoiceAmount,
      },
      {
        title: "date",
        width: 100,
        orderBy: InvoiceOrderByField.InvoiceDate,
      },
      {
        title: "ExpirationDate",
        width: 128,
        orderBy: InvoiceOrderByField.InvoiceDueDate,
      },
      {
        title: "status",
        width: 128,
        orderBy: InvoiceOrderByField.InvoiceStatus,
      },
      {
        title: "for",
        width: 200,
        isAutoColumn: true,
      },
      {
        title: "assignment",
        width: 200,
        isAutoColumn: true,
      },
    ];
  }

  public componentDidMount() {
    this.props.getInvoices(true);
  }

  public render() {
    const { results: invoices, canLoadMore, status } = this.props.invoices;
    const toggleStyle = classNames(
      "fal fa-fw",
      this.state.toggleShowSidebar ? "fa-filter" : "fa-arrow-left"
    );
    const overviewStyle = classNames("overview ", {
      collapsed: this.state.toggleShowSidebar,
    });

    return (
      <div styleName={overviewStyle}>
        <div styleName="overview__header">
          <div styleName="clear-filters">
            {this.props.selectedFilters.length === 0 ? (
              <I18n value="noActiveFilters" />
            ) : (
              <a onClick={this.props.clearAllFilters}>
                <I18n value="deleteFilters" />
              </a>
            )}
          </div>
          <div styleName="toggle" onClick={this.toggleSidebar}>
            <div styleName="trigger">
              <i className={toggleStyle} />
            </div>
          </div>
          <div styleName="selected-filters">
            {this.props.selectedFilters.map((selectedFilter, idx) => (
              <Pill
                key={idx}
                label={selectedFilter.label}
                labelValues={{ value: selectedFilter.value }}
                color={Colors.Primary}
                solid
                deletable
                onClick={() =>
                  this.onSelectedFilterClickHandler(selectedFilter)
                }
              />
            ))}
          </div>
          <div styleName="export" className="hidden-xs">
            <button
              className="btn btn-success excel"
              onClick={this.props.exportInvoices}
            >
              <i className="fal fa-fw fa-file-excel" />
              <I18n value="exportExcel" />
            </button>
          </div>
        </div>

        <div styleName="overview__body">
          <div styleName="sidebar">
            <InvoiceFilterSidebarComponent
              filterByActive={this.props.filters.filterByActive}
              invoiceStatuses={this.props.invoiceStatuses}
              filters={this.props.filters}
              onFilterChange={this.props.invoiceFilterChange}
            />
          </div>
          <div styleName="list">
            <EntityListStatus
              list="invoices"
              onClick={() => this.props.getInvoices(true)}
              style={{ top: 47 }}
            />

            <Ui.Table
              columnConfig={this.columnConfig}
              orderBy={this.props.orderBy}
              defaultOrderBy={InvoiceOrderByField.InvoiceOverdue}
              defaultOrder={SortOrder.Ascending}
              order={this.props.order}
              blockChanges={status === REQUEST.PENDING}
              pushActions
              onScrollEnd={this.onScrollEndHandler}
              onSortingChange={this.onSortingChangeHandler}
            >
              {invoices.map((invoice, idx) => (
                <ErrorBoundary key={invoice.id}>
                  <ListItem
                    invoice={invoice}
                    zebra={idx % 2 !== 0}
                    navigate={this.props.navigate}
                    onDelete={(id) =>
                      this.props.deleteInvoice(id, this.reloadInvoices)
                    }
                    onUnDelete={this.props.unDeleteInvoice}
                    onArchive={this.props.archiveInvoice}
                    onUnArchive={this.props.unArchiveInvoice}
                    onDeleteDone={noop}
                    onArchiveDone={this.props.archiveInvoiceFromList}
                    onUnArchiveDone={this.props.unArchiveInvoiceFromList}
                  />
                </ErrorBoundary>
              ))}

              {invoices.length === 0 && status === REQUEST.SUCCESS && (
                <Ui.EmptyState
                  stickman={UiEmptyStateStickMan.NoRelations}
                  type={UiEmptyStateType.List}
                  title="emptyStateNoInvoicesOverviewTitle"
                  subtitle="emptyStateNoInvoicesOverviewText"
                />
              )}

              {!!canLoadMore && invoices.length > 0 && <Ui.Loaders.List />}
            </Ui.Table>
          </div>
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    if (
      !this.props.invoices.canLoadMore ||
      this.props.invoices.status === REQUEST.PENDING
    )
      return;
    this.props.getInvoices();
  }

  private onSortingChangeHandler(
    orderBy: InvoiceOrderByField,
    order: SortOrder
  ) {
    this.props.setInvoicesOrdering(order, orderBy, true);
  }

  private onSelectedFilterClickHandler(selectedFilter: SimpleLabelOriginal) {
    let filters: InvoiceFiltering = {
      ...this.props.filters,
    };

    switch (selectedFilter.filter) {
      case "filterByActive":
        filters = {
          ...filters,
          filterByActive: filters.filterByActive.filter(
            (f) => f !== selectedFilter.value
          ),
        };
        break;
      case "statusFilter":
        filters = {
          ...filters,
          statusFilter: filters.statusFilter.filter(
            (f) => f !== selectedFilter.value
          ),
        };
        break;
      case "invoiceDate":
        filters = {
          ...filters,
          invoiceDateFrom: null,
          invoiceDateUntil: null,
        };
        break;
      case "overdue":
        filters = {
          ...filters,
          overdueFrom: null,
          overdueUntil: null,
        };
        break;
      default:
        break;
    }

    this.props.invoiceFilterChange(filters);
  }

  private toggleSidebar() {
    this.setState({
      toggleShowSidebar: !this.state.toggleShowSidebar,
    });
  }

  private reloadInvoices() {
    this.props.getInvoices(true);
  }
}
