import { AppState } from "@haywork/stores";
import { createSelector } from "reselect";

export type RealEstatePropertyProp = {
  propName: string;
  propValue: string | number | Date | string[];
  propType:
    | "string"
    | "currency"
    | "date"
    | "number"
    | "squaremeters"
    | "cubicmeters"
    | "stringarray"
    | "percentage"
    | "meters"
    | "centimeters"
    | "kilograms"
    | "grams"
    | "kilogramspersquaremeter"
    | "gramspersquaremeter"
    | "months"
    | "translatewithstring";
  propPrefix?: string;
  propValues?: Record<string, any>;
};

const realEstatePropertySelector = (state: AppState) =>
  state.mls.single.realEstateResponse?.realEstateProperty;
const cultureSelector = (state: AppState) => state.main.culture;

export const displayName = createSelector(
  realEstatePropertySelector,
  cultureSelector,
  (realEstateProperty, culture) => {
    const streetnameArray =
      realEstateProperty?.location?.address?.streetname || [];
    const cityNameArray = realEstateProperty?.location?.address?.cityName || [];

    const houseNumber = realEstateProperty?.location?.address?.houseNumber;
    const houseNumberPostfix =
      realEstateProperty?.location?.address?.houseNumberPostfix;
    const postalCode = realEstateProperty?.location?.address?.postalCode;
    const streetname = streetnameArray.find(
      (item) => item.language === culture
    )?.value;
    const cityName = cityNameArray.find(
      (item) => item.language === culture
    )?.value;

    const formattedHouseNumber = [houseNumber, houseNumberPostfix]
      .filter((d) => !!d)
      .join("-");
    const adressLine1 = [streetname, formattedHouseNumber]
      .filter((d) => !!d)
      .join(" ");
    const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

    return [adressLine1, adressLine2].filter((d) => !!d).join(", ");
  }
);

export const adText = createSelector(
  realEstatePropertySelector,
  cultureSelector,
  (realEstateProperty, culture) => {
    const translations = realEstateProperty?.descriptions?.adText || [];
    if (!translations.length) return "";
    const translation = translations.find(
      (translation) => translation.language === culture
    );
    return !!translation ? translation.value || "" : "";
  }
);
