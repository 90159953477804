import { LinkedRealEstateAgency } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { ResourceText } from "@haywork/modules/shared";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./realestate-agencies.component.scss");

interface Props {
  linkedRealEstateAgencies: LinkedRealEstateAgency[];
  onRealestateAgencyClick: (id: string) => void;
}
interface State {
  linkedRealEstateAgencies: LinkedRealEstateAgency[];
  query: string;
}

@CSSModules(styles, { allowMultiple: true })
export class RealEstateAgenciesComponent extends React.Component<Props, State> {
  public state = {
    linkedRealEstateAgencies: this.props.linkedRealEstateAgencies || [],
    query: "",
  };
  private searchInput: HTMLInputElement;

  constructor(props) {
    super(props);

    this.filterRealestateAgencies = this.filterRealestateAgencies.bind(this);
    this.onRealEstateAgencyClickHandler = this.onRealEstateAgencyClickHandler.bind(
      this
    );
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="realestate-agencies">
        <div styleName="filter">
          <div styleName="filter__meta">
            <i className="fal fa-exchange" />
            <ResourceText resourceKey="switchRealEstateAgency" />
          </div>
          {this.props.linkedRealEstateAgencies.length > 4 && (
            <div styleName="filter__input">
              <input
                ref={(inputEl) => (this.searchInput = inputEl)}
                type="text"
                value={this.state.query}
                onChange={this.onChangeHandler}
                placeholder={intlContext.formatMessage({
                  id: "switchRealEstateAgencyPlaceholder",
                  defaultMessage: "switchRealEstateAgencyPlaceholder",
                })}
                data-lpignore="true"
              />
            </div>
          )}
        </div>
        <div styleName="list">
          {this.state.linkedRealEstateAgencies.map((realestateAgency) => (
            <div
              styleName="list__item"
              key={realestateAgency.id}
              onClick={() =>
                this.onRealEstateAgencyClickHandler(realestateAgency)
              }
            >
              {realestateAgency.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  public componentDidUpdate() {
    if (this.props.linkedRealEstateAgencies.length > 4)
      this.searchInput.focus();
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: query } = event.target;
    this.setState({ query });
    this.filterRealestateAgencies(query);
  }

  private onRealEstateAgencyClickHandler(
    realEstateAgency: LinkedRealEstateAgency
  ) {
    this.setState({
      linkedRealEstateAgencies: this.props.linkedRealEstateAgencies || [],
    });
    this.props.onRealestateAgencyClick(realEstateAgency.id);
  }

  private filterRealestateAgencies(query: string) {
    if (!query) {
      this.setState({
        linkedRealEstateAgencies: this.props.linkedRealEstateAgencies || [],
      });
      return;
    }

    const linkedRealEstateAgencies = this.props.linkedRealEstateAgencies.filter(
      (a) => {
        return new RegExp(escapeRegExp(query.toLowerCase()), "gi").test(
          a.name.toLowerCase()
        );
      }
    );

    this.setState({
      linkedRealEstateAgencies,
    });
  }
}
