import {
  AssignmentType,
  LinkedAssignment,
  LinkedRelation,
  Priority,
  RelationType,
  TaskSnapShot,
  TaskStatus,
} from "@haywork/api/kolibri";
import {
  ASSIGNMENTROUTES,
  MAINROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
  REQUEST,
  TASKROUTES,
} from "@haywork/constants";
import { TaskWidgetContainerProps } from "@haywork/modules/dashboard/containers/widgets/task-widget.container";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { ColorUtil, DateUtil, DiffStatus, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const styles = require("./tasks-widget.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export type DashboardTasksWidgetComponentProps = {};
type Props = DashboardTasksWidgetComponentProps & TaskWidgetContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DashboardTasksWidgetComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onToggleCompleted = this.onToggleCompleted.bind(this);
  }

  public componentDidMount() {
    this.props.getUpcomingTasks();
  }

  public render() {
    const tasksOverDate: TaskSnapShot[] = [];
    const tasksToday: TaskSnapShot[] = [];

    if (this.props.taskWidgetState === REQUEST.SUCCESS && this.props.tasks) {
      for (let i = 0; i < this.props.tasks.length; i++) {
        const task = this.props.tasks[i];
        const daysFromNow = DateUtil.diffInDays(new Date(), task.endDate);

        if (
          (!daysFromNow ||
            (daysFromNow && daysFromNow.value > 0) ||
            !task.endDate) &&
          (daysFromNow.status === DiffStatus.Negative ||
            daysFromNow.status === DiffStatus.Unknown)
        ) {
          tasksOverDate.push(task);
        } else if (daysFromNow && daysFromNow.value === 0) {
          tasksToday.push(task);
        }
      }
    }

    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetTasks">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-tasks" />
            </span>
            <span className="text">
              <ResourceText resourceKey="taskWidgetTitle" />
            </span>
          </header>

          {this.props.taskWidgetState === REQUEST.PENDING && (
            <div className="content loading">
              <PageLoader loading />
            </div>
          )}

          {this.props.taskWidgetState === REQUEST.SUCCESS &&
            tasksOverDate.length === 0 &&
            tasksToday.length === 0 &&
            this.renderEmptyState()}
          {this.props.taskWidgetState === REQUEST.SUCCESS &&
            (tasksOverDate.length > 0 || tasksToday.length) > 0 &&
            this.renderTasks(tasksOverDate, tasksToday)}
          {this.props.tasks &&
          this.props.totalResults > this.props.tasks.length ? (
            <footer>
              <Link
                to={MAINROUTES.TASKS.URI}
                className="btn btn-link btn-link-primary"
              >
                <ResourceText
                  resourceKey="moreTasks"
                  values={{
                    extraTasks:
                      this.props.totalResults - this.props.tasks.length,
                  }}
                />
              </Link>
            </footer>
          ) : (
            <footer>
              <Link
                to={MAINROUTES.TASKS.URI}
                className="btn btn-link btn-link-primary"
              >
                <ResourceText resourceKey="taskWidgetOverview" />
              </Link>
            </footer>
          )}
        </article>
      </div>
    );
  }

  private renderEmptyState(): JSX.Element {
    return (
      <div className="content emptyState" styleName="contentTasks">
        <span>
          <ResourceText resourceKey="taskWidgetNoTasks" />
        </span>
      </div>
    );
  }

  private renderTasks(
    tasksOverDate: TaskSnapShot[],
    tasksToday: TaskSnapShot[]
  ): JSX.Element {
    return (
      <div className="content" styleName="contentTasks">
        {tasksOverDate.length > 0 && (
          <span className="itemHeader">
            <ResourceText resourceKey="taskWidgetOverTime" />
          </span>
        )}
        {tasksOverDate.map((task) => {
          return this.renderTaskItem(task);
        })}

        {tasksToday.length > 0 && (
          <span className="itemHeader">
            <ResourceText resourceKey="taskWidgetForToday" />
          </span>
        )}
        {tasksToday.map((task) => {
          return this.renderTaskItem(task);
        })}
      </div>
    );
  }

  private renderTaskItem(task: TaskSnapShot): JSX.Element {
    const taskStyle = classNames("item", {
      completed: task.status === TaskStatus.Completed,
    });
    const category = this.props.mastertable.taskCategories.find(
      (category) => category.value === task.categoryId
    );
    const completedIcon =
      task.status === TaskStatus.Completed ? "fa-check-square" : "fa-square";

    return (
      <div styleName={taskStyle} key={task.id}>
        <div
          styleName="checkbox"
          style={{
            borderColor:
              category && category.categoryBackColor
                ? ColorUtil.hexToRgb(category.categoryBackColor)
                : "",
          }}
          onClick={() => this.onToggleCompleted(task)}
        >
          <i className={"fal fa-fw " + completedIcon} />
        </div>
        <div
          styleName="text"
          onClick={() => {
            this.props.navigate(route(TASKROUTES.TASK.URI, { id: task.id }));
          }}
        >
          <span styleName="subject">{task.subject}</span>
          <div styleName="subTitle">
            {category && (
              <span styleName="category">
                <span
                  styleName="color"
                  style={{
                    backgroundColor:
                      category && category.categoryBackColor
                        ? ColorUtil.hexToRgb(category.categoryBackColor)
                        : "",
                  }}
                />
                {category.displayName}
              </span>
            )}
            {task.linkedRelations &&
              task.linkedRelations.map((linkedRelation) => {
                return this.renderLinkedRelation(linkedRelation);
              })}
            {task.linkedAssignments &&
              task.linkedAssignments.map((linkedAssignment) => {
                return this.renderLinkedAssignment(linkedAssignment);
              })}
          </div>
        </div>
        {task.priority === Priority.High && (
          <div styleName="important">
            <i className="fa fa-fw fa-exclamation" />
          </div>
        )}
      </div>
    );
  }

  private renderLinkedRelation(linkedRelation: LinkedRelation): JSX.Element {
    const relationRoute =
      linkedRelation.typeOfRelation === RelationType.ContactPerson
        ? route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
            id: linkedRelation.id,
          })
        : route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
            id: linkedRelation.id,
          });

    return (
      <Link
        to={relationRoute}
        key={linkedRelation.id}
        styleName="linkedRelation"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <i className="fal fa-user" /> {linkedRelation.displayName}
      </Link>
    );
  }

  private renderLinkedAssignment(
    linkedAssignment: LinkedAssignment
  ): JSX.Element {
    let path;

    switch (linkedAssignment.typeOfAssignment) {
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id: linkedAssignment.id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id: linkedAssignment.id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id: linkedAssignment.id });
        break;
    }

    return (
      <Link
        to={path}
        key={linkedAssignment.id}
        styleName="linkedAssignment"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <i className="fal fa-folder-open" /> {linkedAssignment.displayName}
      </Link>
    );
  }

  private onToggleCompleted(task: TaskSnapShot) {
    const status =
      task.status === TaskStatus.Completed
        ? TaskStatus.NotStarted
        : TaskStatus.Completed;
    this.props.toggleTaskStatus(task.id, status);
  }
}
