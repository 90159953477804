import { RootEntityType } from "@haywork/api/event-center";
import { Office, PhotoBlob } from "@haywork/api/kolibri";
import { OFFICESROUTES } from "@haywork/constants/routes";
import { Dispatch, OfficeThunk } from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { OfficeDetailComponent } from "@haywork/modules/settings/modules/offices/components";
import { AppState, OfficeActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface StateProps {
  selectedOffice: Office;
  selectedOfficeId: string;
  path: string;
}

export interface DispatchProps {
  getOfficeInfo: (id: string) => void;
  updateOfficeLogo: (newAvatar: PhotoBlob, contactCompanyId: string) => void;
  saveAndCloseOffice: (office: Office, path: string) => void;
}

const mapStateToProps = <StateProps, OfficeDetailComponentProps>(
  state: AppState
) => {
  return {
    selectedOffice: state.editable.currentComponentState,
    selectedOfficeId: state.offices.selectedOffice.id,
    path: RouteUtil.mapStaticRouteValues(OFFICESROUTES.OFFICE_DETAIL.URI, {
      id: state.offices.selectedOffice.id
    })
  };
};

const mapDispatchToProps = <DispatchProps, OfficeDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getOfficeInfo: (officeID: string) =>
    dispatch(OfficeThunk.getOfficeInfo(officeID)),
  updateOfficeLogo: (newAvatar: PhotoBlob, contactCompanyId: string) =>
    dispatch(OfficeThunk.updateOfficeLogo(newAvatar, contactCompanyId)),
  saveAndCloseOffice: (office: Office, path: string) =>
    dispatch(OfficeThunk.saveOffice(office, path, true))
});

export type OfficeDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  EditableHocProps;
export const OfficeDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(OfficeDetailComponent)
  ),
  {
    entityType: RootEntityType.Office,
    icon: "building",
    thunk: OfficeThunk.getOfficeInfo,
    status: "offices.officeState",
    statePath: "offices.selectedOffice",
    action: OfficeActions.setOfficeDirectly,
    route: OFFICESROUTES.OFFICE_DETAIL.URI,
    confirm: {
      title: { key: "saveCompanyConfirmTitle" },
      body: { key: "saveCompanyConfirmBody" }
    }
  }
);
