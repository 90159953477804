import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import { Location } from "history";
import escapeRegExp from "lodash-es/escapeRegExp";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./detail.navbar.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface DetailNavbarComponentProps {
  session: DocumentSession;
  template: DocumentTemplate;
  location: Location;
  saveDynamicDocument: (nextRoute: string) => void;
  onCloseDocument: (path: string) => void;
}
interface DetailNavbarComponentState {
  previousRoute: string;
  nextRoute: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DetailNavbarComponent extends React.Component<
  DetailNavbarComponentProps,
  DetailNavbarComponentState
> {
  private routes: string[] = [];

  private currentRouteIndex: number = 0;

  constructor(props, context) {
    super(props, context);

    this.state = {
      nextRoute: null,
      previousRoute: null,
    };

    const { needsListOfGoods, needsSignatures } = this.props.template;
    const documentPath = needsListOfGoods
      ? DYNAMICDOCUMENTROUTES.LIST_OF_GOODS.URI
      : DYNAMICDOCUMENTROUTES.DOCUMENT_FORM.URI;

    this.routes.push(DYNAMICDOCUMENTROUTES.INFO.URI);
    this.routes.push(documentPath);
    if (this.props.template.needsSignatures) {
      this.routes.push(DYNAMICDOCUMENTROUTES.DOCUMENT_SIGNATURES.URI);
    }
    this.routes.push(DYNAMICDOCUMENTROUTES.DOWNLOAD.URI);

    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.navigateToDownload = this.navigateToDownload.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.onCloseClickHandler = this.onCloseClickHandler.bind(this);
  }

  public componentDidMount() {
    const { id } = this.props.session;
    this.routes = this.routes.map((r) => route(r, { id }));
    this.setNextAndPreviousRoutes();
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setNextAndPreviousRoutes(nextProps.location.pathname);
    }
  }

  public render() {
    const valid = this.validateRoutes(this.props.session);
    const { id, isNew } = this.props.session;

    const infoRoute = classNames("item", {
      valid: valid.info,
    });
    const documentRoute = classNames("item", {
      valid: valid.document,
      disabled: isNew,
    });
    const downloadRoute = classNames("item", {
      valid: valid.download,
      disabled: isNew,
    });
    const signaturesRoute = classNames("item", {
      valid: valid.signatures,
      disabled: isNew,
    });

    return (
      <div styleName="navbar">
        <div styleName="button">
          {this.state.previousRoute && (
            <NavLink
              to={this.state.previousRoute}
              className="btn btn-default-alt icon-left"
            >
              <i className="fal fa-fw fa-chevron-left"></i>
              <ResourceText resourceKey="previousText" />
            </NavLink>
          )}
        </div>

        <div styleName="navigation">
          <div styleName="navigation__list">
            <NavLink
              styleName={infoRoute}
              to={route(DYNAMICDOCUMENTROUTES.INFO.URI, { id })}
            >
              <div styleName="index">1</div>
              <ResourceText resourceKey="dynamicDocumentsTheBasics" />
            </NavLink>

            <div styleName="spacer">
              <i className="fal fa-fw fa-chevron-right" />
            </div>

            {this.props.template.needsListOfGoods && (
              <NavLink
                styleName={documentRoute}
                to={route(DYNAMICDOCUMENTROUTES.LIST_OF_GOODS.URI, { id })}
                onClick={this.cancelNavigation}
              >
                <div styleName="index">2</div>
                <ResourceText resourceKey="dynamicDocumentsFillIn" />
              </NavLink>
            )}

            {!this.props.template.needsListOfGoods && (
              <NavLink
                styleName={documentRoute}
                to={route(DYNAMICDOCUMENTROUTES.DOCUMENT_FORM.URI, { id })}
                onClick={this.cancelNavigation}
              >
                <div styleName="index">2</div>
                <ResourceText resourceKey="dynamicDocumentsFillIn" />
              </NavLink>
            )}

            {this.props.template.needsSignatures && (
              <React.Fragment>
                <div styleName="spacer">
                  <i className="fal fa-fw fa-chevron-right" />
                </div>
                <NavLink
                  styleName={signaturesRoute}
                  to={route(DYNAMICDOCUMENTROUTES.DOCUMENT_SIGNATURES.URI, {
                    id,
                  })}
                  onClick={this.cancelNavigation}
                >
                  <div styleName="index">3</div>
                  <ResourceText resourceKey="dynamicDocumentsSignatures" />
                </NavLink>
              </React.Fragment>
            )}

            <div styleName="spacer">
              <i className="fal fa-fw fa-chevron-right" />
            </div>

            <NavLink
              styleName={downloadRoute}
              to={route(DYNAMICDOCUMENTROUTES.DOWNLOAD.URI, { id })}
              onClick={this.navigateToDownload}
            >
              <div styleName="index">
                {this.props.template.needsSignatures ? 4 : 3}
              </div>
              <ResourceText resourceKey="dynamicDocumentsDownload" />
            </NavLink>
          </div>
        </div>
        <div styleName="button last">
          {this.state.nextRoute &&
            this.currentRouteIndex < this.routes.length - 1 && (
              <NavLink
                to={this.state.nextRoute}
                className="btn btn-success icon-right"
                onClick={this.onNextClickHandler}
              >
                <ResourceText resourceKey="nextText" />
                <i className="fal fa-fw fa-chevron-right"></i>
              </NavLink>
            )}
          {!this.state.nextRoute && (
            <div
              className="btn btn-success icon-right"
              onClick={this.onCloseClickHandler}
            >
              <ResourceText resourceKey="close" />
              <i className="fal fa-fw fa-chevron-right"></i>
            </div>
          )}
        </div>
      </div>
    );
  }

  private setNextAndPreviousRoutes(pathname?: string) {
    this.currentRouteIndex = this.routes.reduce((state, route, idx) => {
      const path = pathname || this.props.location.pathname;
      if (new RegExp(escapeRegExp(route), "gi").test(path)) return idx;
      return state;
    }, -1);

    const nextRoute =
      this.currentRouteIndex <= this.routes.length &&
      this.currentRouteIndex !== -1
        ? this.routes[this.currentRouteIndex + 1]
        : null;
    const previousRoute =
      this.currentRouteIndex > 0
        ? this.routes[this.currentRouteIndex - 1]
        : null;

    this.setState({ nextRoute, previousRoute });
  }

  private onNextClickHandler(event: React.MouseEvent<HTMLAnchorElement>) {
    const { id, isNew } = this.props.session;

    if (isNew && this.currentRouteIndex === 0) {
      event.preventDefault();
      const path = this.props.template.needsListOfGoods
        ? route(DYNAMICDOCUMENTROUTES.LIST_OF_GOODS.URI, { id })
        : route(DYNAMICDOCUMENTROUTES.DOCUMENT_FORM.URI, { id });

      this.props.saveDynamicDocument(path);
    }

    const downloadPath = route(DYNAMICDOCUMENTROUTES.DOWNLOAD.URI, { id });
    if (this.routes[this.currentRouteIndex + 1] === downloadPath) {
      event.preventDefault();
      this.props.saveDynamicDocument(downloadPath);
    }
  }

  private onCloseClickHandler() {
    const { id } = this.props.session;
    const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id });

    this.props.onCloseDocument(path);
  }

  private cancelNavigation(event: React.MouseEvent<HTMLAnchorElement>) {
    if (this.props.session.isNew) event.preventDefault();
  }

  private navigateToDownload(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    if (!this.props.session.isNew) {
      const { id } = this.props.session;
      this.props.saveDynamicDocument(
        route(DYNAMICDOCUMENTROUTES.DOWNLOAD.URI, { id })
      );
    }
  }

  private validateRoutes(documentSession: DocumentSession): {
    info: boolean;
    document: boolean;
    download: boolean;
    signatures: boolean;
  } {
    const valid = {
      info: false,
      document: false,
      download: false,
      signatures: false,
    };

    return valid;
  }
}
