import { BidSnapShot, LinkedRelation } from "@haywork/api/kolibri";
import * as moment from "moment";
import sortBy from "lodash-es/sortBy";
import head from "lodash-es/head";
import uniqBy from "lodash-es/uniqBy";

export type BidDossier = {
  id: string;
  linkedRelations: LinkedRelation[];
  bids: BidSnapShot[];
  latestBid: BidSnapShot;
};

export class BidUtil {
  public static mapBidSnapShotsToDossiers(bids: BidSnapShot[]): BidDossier[] {
    const rootBids = bids.filter((bid) => bid.isParentBid);
    const childBids = bids.filter((bid) => !bid.isParentBid);

    return rootBids.map((bid) => {
      const { bids, linkedRelations } = BidUtil.addChildBidsToParentBid(
        bid,
        childBids
      );
      const sortedBids = [
        ...sortBy([bid, ...bids], (bid) =>
          moment(bid.bidDateTime).toDate()
        ).reverse()
      ];

      return {
        id: bid.id,
        linkedRelations,
        bids: sortedBids,
        latestBid: head(sortedBids)
      } as BidDossier;
    });
  }

  private static addChildBidsToParentBid(
    bid: BidSnapShot,
    childBids: BidSnapShot[]
  ): { bids: BidSnapShot[]; linkedRelations: LinkedRelation[] } {
    const { children, remaining } = childBids.reduce(
      (state, childBid) => {
        const linkedBidIds = (childBid.linkedBids || []).map(
          (childBid) => childBid.id
        );
        if (linkedBidIds.includes(bid.id)) {
          state.children.push(childBid);
        } else {
          state.remaining.push(childBid);
        }
        return state;
      },
      { children: [], remaining: [] } as {
        children: BidSnapShot[];
        remaining: BidSnapShot[];
      }
    );

    let bids = children;
    let linkedRelations = bid.linkedRelations || [];

    if (!!bids.length && !!remaining) {
      bids.map((bid) => {
        const {
          bids: newBids,
          linkedRelations: newLinkedRelations
        } = BidUtil.addChildBidsToParentBid(bid, remaining);

        bids = [...bids, ...newBids];
        linkedRelations = uniqBy(
          [...linkedRelations, ...newLinkedRelations],
          (relation) => relation.id
        );
      });
    }

    return {
      bids,
      linkedRelations
    };
  }
}
