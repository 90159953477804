import { ObjectAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditMarketingVirtualVideoComponent,
  AssignmentEditMarketingVirtualVideoComponentProps
} from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  SingleAssignmentState
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  path: string;
}
interface DispatchProps {
  updateObjectAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => any;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditMarketingVirtualVideoComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: currentComponentState.objectAssignment.id
    })
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditMarketingVirtualVideoComponentProps
> = (dispatch) => ({
  updateObjectAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});

export type AssignmentEditMarketingVirtualVideoContainerProps = StateProps &
  DispatchProps;
export const AssignmentEditMarketingVirtualVideoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingVirtualVideoComponent);
