import * as React from "react";
import { ResourceText } from "@haywork/modules/shared";
import { LayoutHelpContainerProps } from "./help.container";
import * as CSSModules from "react-css-modules";
import { EXTERNALROUTES, KEYCODE } from "@haywork/constants";
import { RequestStatus } from "@haywork/enum";

declare let window: ExtendedWindow;

const styles = require("./style.scss");

export interface LayoutHelpComponentProps {}
type Props = LayoutHelpComponentProps & LayoutHelpContainerProps;

export const LayoutHelpComponent = CSSModules(styles, { allowMultiple: true })(
  ({ asapStatus, asapVisible }: Props) => {
    React.useEffect(() => {
      document.addEventListener("keyup", handleEscape, true);
      return () => document.removeEventListener("keyup", handleEscape, true);
    }, [asapStatus, asapVisible]);

    const handleEscape = (ev: KeyboardEvent) => {
      if (
        asapStatus !== RequestStatus.Success ||
        !asapVisible ||
        ev.keyCode !== KEYCODE.ESCAPE
      )
        return;
      window.ZohoHCAsap.Action("close");
    };

    const toggleHelpService = (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (asapStatus === RequestStatus.Success) {
        window.ZohoHCAsap.Action(asapVisible ? "close" : "open");
      } else {
        window.open(EXTERNALROUTES.SUPPORT.URI, "_blank");
      }
    };

    return (
      <div
        styleName="helpLink"
        className="hidden-xs"
        onClick={toggleHelpService}
      >
        <i className="fa fa-lightbulb" />
        <ResourceText resourceKey="helpTrigger" />
      </div>
    );
  }
);
