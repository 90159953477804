import {
  AssignmentPhase,
  AssignmentSnapShot,
  AssignmentType,
  ConstructionPeriodOption,
  Floor,
  FloorType,
  LinkedAssignment,
  LinkedRelation,
  ListingType,
  LocationPlace,
  LocationPlaceOption,
  ObjectAssignment,
  ObjectTypeAssignment,
  PhotoBlob,
  ProjectAssignment,
  RealEstateGroup,
  RelationRole,
  RentOffer,
  SaleOffer,
  Space,
  TypePART,
  AcquisitionAssignment,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
} from "@haywork/constants";
import { VVEOption } from "@haywork/modules/assignment";
import get from "lodash-es/get";
import has from "lodash-es/has";
import sortBy from "lodash-es/sortBy";
import { FormControl } from "./form-control";
import { Route as RouteUtil } from "./route";
import { EmailAssignment } from "./email-v2";
import { IntlShape } from "react-intl";

const value = FormControl.returnObjectPathOrNull;

export enum AssignmentSaveError {
  NoLinkedEmployee = "NoLinkedEmployee",
  NoRealEstateGroup = "NoRealEstateGroup",
  NoTypeOf = "NoTypeOf",
  NoListingType = "NoListingType",
  IncompleteAddress = "IncompleteAddress",
  NoLeisureType = "NoLeisureType",
  IncompleteFloors = "IncompleteFloors",
}

export enum AssignmentPublishError {
  NoParcelSurface = "NoParcelSurface",
  NoBuildPeriodOrYear = "NoBuildPeriodOrYear",
  NoRoomsCount = "NoRoomsCount",
  NoLivingSurface = "NoLivingSurface",
  NoContents = "NoContents",
  NoSaleCondition = "NoSaleCondition",
  NoRentCondition = "NoRentCondition",
  NoAcceptance = "NoAcceptance",
  ConstructionYearMismatch = "ConstructionYearMismatch",
}

export type LinkedRelationWithRole = LinkedRelation & {
  relationRole: RelationRole;
};

export type ObjectAssignmentValidatedSubRoutes = {
  client: boolean;
  address: boolean;
  financial: boolean;
  assignment: boolean;
  marketing: boolean;
};

type RelationTypeAssignment =
  | ObjectAssignment
  | ProjectAssignment
  | ObjectTypeAssignment
  | AcquisitionAssignment
  | AcquisitionObjectAssignment;

type MaxRent = {
  points: number;
  maxRent: number;
};

const maxRentPriceDependent: MaxRent[] = [
  { points: 1, maxRent: 2.18 },
  { points: 2, maxRent: 4.29 },
  { points: 3, maxRent: 6.41 },
  { points: 4, maxRent: 8.55 },
  { points: 5, maxRent: 10.65 },
  { points: 6, maxRent: 12.79 },
  { points: 7, maxRent: 14.91 },
  { points: 8, maxRent: 17.03 },
  { points: 9, maxRent: 19.14 },
  { points: 10, maxRent: 21.27 },
  { points: 11, maxRent: 23.34 },
  { points: 12, maxRent: 25.46 },
  { points: 13, maxRent: 27.57 },
  { points: 14, maxRent: 29.71 },
  { points: 15, maxRent: 31.84 },
  { points: 16, maxRent: 33.92 },
  { points: 17, maxRent: 36.05 },
  { points: 18, maxRent: 38.17 },
  { points: 19, maxRent: 40.3 },
  { points: 20, maxRent: 42.41 },
  { points: 21, maxRent: 44.51 },
  { points: 22, maxRent: 46.61 },
  { points: 23, maxRent: 48.75 },
  { points: 24, maxRent: 50.83 },
  { points: 25, maxRent: 52.97 },
  { points: 26, maxRent: 55.06 },
  { points: 27, maxRent: 57.21 },
  { points: 28, maxRent: 59.34 },
  { points: 29, maxRent: 61.44 },
  { points: 30, maxRent: 63.56 },
  { points: 31, maxRent: 65.66 },
  { points: 32, maxRent: 67.78 },
  { points: 33, maxRent: 69.89 },
  { points: 34, maxRent: 71.99 },
  { points: 35, maxRent: 74.11 },
  { points: 36, maxRent: 76.24 },
  { points: 37, maxRent: 78.35 },
  { points: 38, maxRent: 80.49 },
  { points: 39, maxRent: 82.59 },
  { points: 40, maxRent: 84.71 },
  { points: 41, maxRent: 86.83 },
  { points: 42, maxRent: 88.93 },
  { points: 43, maxRent: 91.05 },
  { points: 44, maxRent: 93.15 },
  { points: 45, maxRent: 95.27 },
  { points: 46, maxRent: 97.39 },
  { points: 47, maxRent: 99.5 },
  { points: 48, maxRent: 101.62 },
  { points: 49, maxRent: 103.75 },
  { points: 50, maxRent: 105.85 },
  { points: 51, maxRent: 107.99 },
  { points: 52, maxRent: 110.08 },
  { points: 53, maxRent: 112.23 },
  { points: 54, maxRent: 114.34 },
  { points: 55, maxRent: 116.45 },
  { points: 56, maxRent: 118.55 },
  { points: 57, maxRent: 120.64 },
  { points: 58, maxRent: 122.78 },
  { points: 59, maxRent: 124.89 },
  { points: 60, maxRent: 127.02 },
  { points: 61, maxRent: 129.13 },
  { points: 62, maxRent: 131.25 },
  { points: 63, maxRent: 133.35 },
  { points: 64, maxRent: 135.49 },
  { points: 65, maxRent: 137.61 },
  { points: 66, maxRent: 139.73 },
  { points: 67, maxRent: 141.79 },
  { points: 68, maxRent: 143.92 },
  { points: 69, maxRent: 146.07 },
  { points: 70, maxRent: 148.16 },
  { points: 71, maxRent: 150.28 },
  { points: 72, maxRent: 152.38 },
  { points: 73, maxRent: 154.5 },
  { points: 74, maxRent: 156.63 },
  { points: 75, maxRent: 158.77 },
  { points: 76, maxRent: 160.86 },
  { points: 77, maxRent: 162.99 },
  { points: 78, maxRent: 165.06 },
  { points: 79, maxRent: 167.21 },
  { points: 80, maxRent: 169.31 },
  { points: 81, maxRent: 171.41 },
  { points: 82, maxRent: 173.57 },
  { points: 83, maxRent: 175.66 },
  { points: 84, maxRent: 177.8 },
  { points: 85, maxRent: 179.92 },
  { points: 86, maxRent: 182.03 },
  { points: 87, maxRent: 184.14 },
  { points: 88, maxRent: 186.26 },
  { points: 89, maxRent: 188.35 },
  { points: 90, maxRent: 190.49 },
  { points: 91, maxRent: 192.59 },
  { points: 92, maxRent: 194.73 },
  { points: 93, maxRent: 196.82 },
  { points: 94, maxRent: 198.94 },
  { points: 95, maxRent: 201.09 },
  { points: 96, maxRent: 203.19 },
  { points: 97, maxRent: 205.31 },
  { points: 98, maxRent: 207.42 },
  { points: 99, maxRent: 209.53 },
  { points: 100, maxRent: 211.66 },
  { points: 101, maxRent: 213.76 },
  { points: 102, maxRent: 215.86 },
  { points: 103, maxRent: 217.98 },
  { points: 104, maxRent: 220.08 },
  { points: 105, maxRent: 222.24 },
  { points: 106, maxRent: 224.33 },
  { points: 107, maxRent: 226.46 },
  { points: 108, maxRent: 228.6 },
  { points: 109, maxRent: 230.67 },
  { points: 110, maxRent: 232.81 },
  { points: 111, maxRent: 234.91 },
  { points: 112, maxRent: 237.02 },
  { points: 113, maxRent: 239.13 },
  { points: 114, maxRent: 241.24 },
  { points: 115, maxRent: 243.37 },
  { points: 116, maxRent: 245.49 },
  { points: 117, maxRent: 247.62 },
  { points: 118, maxRent: 249.73 },
  { points: 119, maxRent: 251.83 },
  { points: 120, maxRent: 253.95 },
  { points: 121, maxRent: 256.07 },
  { points: 122, maxRent: 258.19 },
  { points: 123, maxRent: 260.27 },
  { points: 124, maxRent: 262.4 },
  { points: 125, maxRent: 264.5 },
  { points: 126, maxRent: 266.63 },
  { points: 127, maxRent: 268.76 },
  { points: 128, maxRent: 270.88 },
  { points: 129, maxRent: 273.0 },
  { points: 130, maxRent: 275.11 },
  { points: 131, maxRent: 277.22 },
  { points: 132, maxRent: 279.33 },
  { points: 133, maxRent: 281.47 },
  { points: 134, maxRent: 283.57 },
  { points: 135, maxRent: 285.66 },
  { points: 136, maxRent: 287.79 },
  { points: 137, maxRent: 289.92 },
  { points: 138, maxRent: 292.05 },
  { points: 139, maxRent: 294.16 },
  { points: 140, maxRent: 296.27 },
  { points: 141, maxRent: 298.37 },
  { points: 142, maxRent: 300.5 },
  { points: 143, maxRent: 302.62 },
  { points: 144, maxRent: 304.74 },
  { points: 145, maxRent: 306.81 },
  { points: 146, maxRent: 308.95 },
  { points: 147, maxRent: 311.09 },
  { points: 148, maxRent: 313.19 },
  { points: 149, maxRent: 315.28 },
  { points: 150, maxRent: 317.42 },
  { points: 151, maxRent: 319.51 },
  { points: 152, maxRent: 321.66 },
  { points: 153, maxRent: 323.75 },
  { points: 154, maxRent: 325.88 },
  { points: 155, maxRent: 328.01 },
  { points: 156, maxRent: 330.11 },
  { points: 157, maxRent: 332.24 },
  { points: 158, maxRent: 334.33 },
  { points: 159, maxRent: 336.46 },
  { points: 160, maxRent: 338.59 },
  { points: 161, maxRent: 340.69 },
  { points: 162, maxRent: 342.81 },
  { points: 163, maxRent: 344.92 },
  { points: 164, maxRent: 347.06 },
  { points: 165, maxRent: 349.16 },
  { points: 166, maxRent: 351.26 },
  { points: 167, maxRent: 353.38 },
  { points: 168, maxRent: 355.49 },
  { points: 169, maxRent: 357.63 },
  { points: 170, maxRent: 359.75 },
  { points: 171, maxRent: 361.82 },
  { points: 172, maxRent: 363.95 },
  { points: 173, maxRent: 366.07 },
  { points: 174, maxRent: 368.21 },
  { points: 175, maxRent: 370.29 },
  { points: 176, maxRent: 372.43 },
  { points: 177, maxRent: 374.53 },
  { points: 178, maxRent: 376.68 },
  { points: 179, maxRent: 378.78 },
  { points: 180, maxRent: 380.89 },
  { points: 181, maxRent: 381.98 },
  { points: 182, maxRent: 383.08 },
  { points: 183, maxRent: 384.19 },
  { points: 184, maxRent: 385.29 },
  { points: 185, maxRent: 386.36 },
  { points: 186, maxRent: 387.47 },
  { points: 187, maxRent: 388.55 },
  { points: 188, maxRent: 389.66 },
  { points: 189, maxRent: 390.74 },
  { points: 190, maxRent: 391.84 },
  { points: 191, maxRent: 392.93 },
  { points: 192, maxRent: 394.05 },
  { points: 193, maxRent: 395.12 },
  { points: 194, maxRent: 396.22 },
  { points: 195, maxRent: 397.32 },
  { points: 196, maxRent: 398.41 },
  { points: 197, maxRent: 399.52 },
  { points: 198, maxRent: 400.62 },
  { points: 199, maxRent: 401.72 },
  { points: 200, maxRent: 402.82 },
  { points: 201, maxRent: 403.92 },
  { points: 202, maxRent: 404.99 },
  { points: 203, maxRent: 406.11 },
  { points: 204, maxRent: 407.16 },
  { points: 205, maxRent: 408.29 },
  { points: 206, maxRent: 409.36 },
  { points: 207, maxRent: 410.49 },
  { points: 208, maxRent: 411.57 },
  { points: 209, maxRent: 412.68 },
  { points: 210, maxRent: 413.75 },
  { points: 211, maxRent: 414.87 },
  { points: 212, maxRent: 415.94 },
  { points: 213, maxRent: 417.07 },
  { points: 214, maxRent: 418.15 },
  { points: 215, maxRent: 419.24 },
  { points: 216, maxRent: 420.33 },
  { points: 217, maxRent: 421.41 },
  { points: 218, maxRent: 422.52 },
  { points: 219, maxRent: 423.65 },
  { points: 220, maxRent: 424.74 },
  { points: 221, maxRent: 425.81 },
  { points: 222, maxRent: 426.93 },
  { points: 223, maxRent: 428.02 },
  { points: 224, maxRent: 429.12 },
  { points: 225, maxRent: 430.16 },
  { points: 226, maxRent: 431.29 },
  { points: 227, maxRent: 432.38 },
  { points: 228, maxRent: 433.47 },
  { points: 229, maxRent: 434.57 },
  { points: 230, maxRent: 435.68 },
  { points: 231, maxRent: 436.75 },
  { points: 232, maxRent: 437.88 },
  { points: 233, maxRent: 438.94 },
  { points: 234, maxRent: 440.05 },
  { points: 235, maxRent: 441.14 },
  { points: 236, maxRent: 442.27 },
  { points: 237, maxRent: 443.32 },
  { points: 238, maxRent: 444.43 },
  { points: 239, maxRent: 445.52 },
  { points: 240, maxRent: 446.63 },
  { points: 241, maxRent: 447.71 },
  { points: 242, maxRent: 448.83 },
  { points: 243, maxRent: 449.93 },
  { points: 244, maxRent: 451.03 },
  { points: 245, maxRent: 452.13 },
  { points: 246, maxRent: 453.21 },
  { points: 247, maxRent: 454.3 },
  { points: 248, maxRent: 455.41 },
  { points: 249, maxRent: 456.5 },
  { points: 250, maxRent: 457.58 },
  { points: 251, maxRent: 458.67 },
  { points: 252, maxRent: 459.8 },
  { points: 253, maxRent: 460.87 },
  { points: 254, maxRent: 461.97 },
  { points: 255, maxRent: 463.05 },
  { points: 256, maxRent: 464.16 },
  { points: 257, maxRent: 465.24 },
  { points: 258, maxRent: 466.37 },
  { points: 259, maxRent: 467.44 },
  { points: 260, maxRent: 468.55 },
  { points: 261, maxRent: 469.62 },
  { points: 262, maxRent: 470.74 },
  { points: 263, maxRent: 471.8 },
  { points: 264, maxRent: 472.93 },
  { points: 265, maxRent: 474.05 },
  { points: 266, maxRent: 475.12 },
  { points: 267, maxRent: 476.23 },
  { points: 268, maxRent: 477.33 },
  { points: 269, maxRent: 478.41 },
  { points: 270, maxRent: 479.5 },
  { points: 271, maxRent: 480.62 },
  { points: 272, maxRent: 481.69 },
  { points: 273, maxRent: 482.79 },
  { points: 274, maxRent: 483.88 },
  { points: 275, maxRent: 484.98 },
  { points: 276, maxRent: 486.08 },
  { points: 277, maxRent: 487.17 },
  { points: 278, maxRent: 488.28 },
  { points: 279, maxRent: 489.36 },
  { points: 280, maxRent: 490.47 },
  { points: 281, maxRent: 491.56 },
  { points: 282, maxRent: 492.64 },
  { points: 283, maxRent: 493.75 },
  { points: 284, maxRent: 494.87 },
  { points: 285, maxRent: 495.93 },
  { points: 286, maxRent: 497.04 },
  { points: 287, maxRent: 498.14 },
  { points: 288, maxRent: 499.24 },
  { points: 289, maxRent: 500.34 },
  { points: 290, maxRent: 501.44 },
  { points: 291, maxRent: 502.52 },
  { points: 292, maxRent: 503.62 },
  { points: 293, maxRent: 504.71 },
  { points: 294, maxRent: 505.81 },
  { points: 295, maxRent: 506.92 },
  { points: 296, maxRent: 508.01 },
  { points: 297, maxRent: 509.1 },
  { points: 298, maxRent: 510.19 },
  { points: 299, maxRent: 511.27 },
  { points: 300, maxRent: 512.41 },
  { points: 301, maxRent: 513.47 },
  { points: 302, maxRent: 514.59 },
  { points: 303, maxRent: 515.65 },
  { points: 304, maxRent: 516.76 },
  { points: 305, maxRent: 517.86 },
  { points: 306, maxRent: 518.96 },
  { points: 307, maxRent: 520.04 },
  { points: 308, maxRent: 521.16 },
  { points: 309, maxRent: 522.24 },
  { points: 310, maxRent: 523.34 },
  { points: 311, maxRent: 524.44 },
  { points: 312, maxRent: 525.52 },
  { points: 313, maxRent: 526.65 },
  { points: 314, maxRent: 527.73 },
  { points: 315, maxRent: 528.81 },
  { points: 316, maxRent: 529.88 },
  { points: 317, maxRent: 531.03 },
  { points: 318, maxRent: 532.1 },
  { points: 319, maxRent: 533.19 },
  { points: 320, maxRent: 534.27 },
  { points: 321, maxRent: 535.38 },
  { points: 322, maxRent: 536.48 },
  { points: 323, maxRent: 537.55 },
  { points: 324, maxRent: 538.7 },
  { points: 325, maxRent: 539.76 },
  { points: 326, maxRent: 540.89 },
  { points: 327, maxRent: 541.95 },
  { points: 328, maxRent: 543.07 },
  { points: 329, maxRent: 544.12 },
  { points: 330, maxRent: 545.28 },
  { points: 331, maxRent: 546.33 },
  { points: 332, maxRent: 547.44 },
  { points: 333, maxRent: 548.52 },
  { points: 334, maxRent: 549.65 },
  { points: 335, maxRent: 550.71 },
  { points: 336, maxRent: 551.82 },
  { points: 337, maxRent: 552.93 },
  { points: 338, maxRent: 553.99 },
  { points: 339, maxRent: 555.11 },
  { points: 340, maxRent: 556.2 },
  { points: 341, maxRent: 557.31 },
  { points: 342, maxRent: 558.38 },
  { points: 343, maxRent: 559.51 },
  { points: 344, maxRent: 560.58 },
  { points: 345, maxRent: 561.68 },
  { points: 346, maxRent: 562.81 },
  { points: 347, maxRent: 563.89 },
  { points: 348, maxRent: 564.99 },
  { points: 349, maxRent: 566.08 },
  { points: 350, maxRent: 567.17 },
  { points: 351, maxRent: 568.23 },
  { points: 352, maxRent: 569.38 },
  { points: 353, maxRent: 570.44 },
  { points: 354, maxRent: 571.55 },
  { points: 355, maxRent: 572.63 },
  { points: 356, maxRent: 573.74 },
  { points: 357, maxRent: 574.82 },
  { points: 358, maxRent: 575.91 },
  { points: 359, maxRent: 577.03 },
  { points: 360, maxRent: 578.1 },
  { points: 361, maxRent: 579.22 },
  { points: 362, maxRent: 580.32 },
  { points: 363, maxRent: 581.42 },
  { points: 364, maxRent: 582.48 },
  { points: 365, maxRent: 583.62 },
  { points: 366, maxRent: 584.71 },
  { points: 367, maxRent: 585.8 },
  { points: 368, maxRent: 586.9 },
  { points: 369, maxRent: 588.0 },
  { points: 370, maxRent: 589.09 },
  { points: 371, maxRent: 590.2 },
  { points: 372, maxRent: 591.28 },
  { points: 373, maxRent: 592.38 },
  { points: 374, maxRent: 593.48 },
  { points: 375, maxRent: 594.58 },
  { points: 376, maxRent: 595.66 },
  { points: 377, maxRent: 596.75 },
  { points: 378, maxRent: 597.87 },
  { points: 379, maxRent: 598.97 },
  { points: 380, maxRent: 600.01 },
  { points: 381, maxRent: 601.13 },
  { points: 382, maxRent: 602.23 },
  { points: 383, maxRent: 603.34 },
  { points: 384, maxRent: 604.43 },
  { points: 385, maxRent: 605.53 },
  { points: 386, maxRent: 606.61 },
  { points: 387, maxRent: 607.73 },
  { points: 388, maxRent: 608.81 },
  { points: 389, maxRent: 609.91 },
  { points: 390, maxRent: 611.04 },
  { points: 391, maxRent: 612.11 },
  { points: 392, maxRent: 613.2 },
  { points: 393, maxRent: 614.29 },
  { points: 394, maxRent: 615.41 },
  { points: 395, maxRent: 616.48 },
  { points: 396, maxRent: 617.6 },
  { points: 397, maxRent: 618.66 },
  { points: 398, maxRent: 619.79 },
  { points: 399, maxRent: 620.86 },
  { points: 400, maxRent: 621.98 },
  { points: 401, maxRent: 623.05 },
  { points: 402, maxRent: 624.11 },
  { points: 403, maxRent: 625.24 },
  { points: 404, maxRent: 626.32 },
  { points: 405, maxRent: 627.43 },
  { points: 406, maxRent: 628.51 },
  { points: 407, maxRent: 629.64 },
  { points: 408, maxRent: 630.72 },
  { points: 409, maxRent: 631.85 },
  { points: 410, maxRent: 632.89 },
  { points: 411, maxRent: 634.02 },
  { points: 412, maxRent: 635.12 },
  { points: 413, maxRent: 636.21 },
  { points: 414, maxRent: 637.3 },
  { points: 415, maxRent: 638.42 },
  { points: 416, maxRent: 639.48 },
  { points: 417, maxRent: 640.58 },
  { points: 418, maxRent: 641.69 },
  { points: 419, maxRent: 642.77 },
  { points: 420, maxRent: 643.89 },
  { points: 421, maxRent: 644.95 },
  { points: 422, maxRent: 646.06 },
  { points: 423, maxRent: 647.15 },
  { points: 424, maxRent: 648.24 },
  { points: 425, maxRent: 649.36 },
  { points: 426, maxRent: 650.42 },
  { points: 427, maxRent: 651.55 },
  { points: 428, maxRent: 652.63 },
  { points: 429, maxRent: 653.75 },
  { points: 430, maxRent: 654.83 },
  { points: 431, maxRent: 655.96 },
  { points: 432, maxRent: 657.02 },
  { points: 433, maxRent: 658.13 },
  { points: 434, maxRent: 659.22 },
  { points: 435, maxRent: 660.32 },
  { points: 436, maxRent: 661.4 },
  { points: 437, maxRent: 662.5 },
  { points: 438, maxRent: 663.6 },
  { points: 439, maxRent: 664.69 },
  { points: 440, maxRent: 665.8 },
  { points: 441, maxRent: 666.88 },
  { points: 442, maxRent: 667.98 },
  { points: 443, maxRent: 669.08 },
  { points: 444, maxRent: 670.19 },
  { points: 445, maxRent: 671.25 },
  { points: 446, maxRent: 672.35 },
  { points: 447, maxRent: 673.48 },
  { points: 448, maxRent: 674.54 },
  { points: 449, maxRent: 675.66 },
  { points: 450, maxRent: 676.76 },
  { points: 451, maxRent: 677.85 },
  { points: 452, maxRent: 678.93 },
  { points: 453, maxRent: 680.06 },
  { points: 454, maxRent: 681.12 },
  { points: 455, maxRent: 682.23 },
  { points: 456, maxRent: 683.34 },
  { points: 457, maxRent: 684.43 },
  { points: 458, maxRent: 685.5 },
  { points: 459, maxRent: 686.62 },
  { points: 460, maxRent: 687.72 },
  { points: 461, maxRent: 688.81 },
  { points: 462, maxRent: 689.88 },
  { points: 463, maxRent: 690.98 },
  { points: 464, maxRent: 692.1 },
  { points: 465, maxRent: 693.18 },
  { points: 466, maxRent: 694.3 },
  { points: 467, maxRent: 695.36 },
  { points: 468, maxRent: 696.45 },
  { points: 469, maxRent: 697.57 },
  { points: 470, maxRent: 698.65 },
  { points: 471, maxRent: 699.77 },
  { points: 472, maxRent: 700.86 },
  { points: 473, maxRent: 701.98 },
  { points: 474, maxRent: 703.04 },
  { points: 475, maxRent: 704.16 },
  { points: 476, maxRent: 705.23 },
  { points: 477, maxRent: 706.36 },
  { points: 478, maxRent: 707.42 },
  { points: 479, maxRent: 708.55 },
  { points: 480, maxRent: 709.63 },
  { points: 481, maxRent: 710.74 },
  { points: 482, maxRent: 711.83 },
  { points: 483, maxRent: 712.89 },
  { points: 484, maxRent: 714.0 },
  { points: 485, maxRent: 715.1 },
  { points: 486, maxRent: 716.22 },
  { points: 487, maxRent: 717.28 },
  { points: 488, maxRent: 718.41 },
  { points: 489, maxRent: 719.46 },
  { points: 490, maxRent: 720.57 },
  { points: 491, maxRent: 721.67 },
  { points: 492, maxRent: 722.78 },
  { points: 493, maxRent: 723.84 },
  { points: 494, maxRent: 724.97 },
  { points: 495, maxRent: 726.06 },
  { points: 496, maxRent: 727.15 },
  { points: 497, maxRent: 728.25 },
  { points: 498, maxRent: 729.35 },
  { points: 499, maxRent: 730.43 },
  { points: 500, maxRent: 731.51 },
  { points: 501, maxRent: 732.64 },
  { points: 502, maxRent: 733.71 },
  { points: 503, maxRent: 734.83 },
  { points: 504, maxRent: 735.91 },
  { points: 505, maxRent: 737.01 },
  { points: 506, maxRent: 738.1 },
  { points: 507, maxRent: 739.2 },
  { points: 508, maxRent: 740.3 },
  { points: 509, maxRent: 741.4 },
  { points: 510, maxRent: 742.48 },
  { points: 511, maxRent: 743.58 },
  { points: 512, maxRent: 744.67 },
  { points: 513, maxRent: 745.79 },
  { points: 514, maxRent: 746.86 },
  { points: 515, maxRent: 747.96 },
  { points: 516, maxRent: 749.07 },
  { points: 517, maxRent: 750.17 },
  { points: 518, maxRent: 751.25 },
  { points: 519, maxRent: 752.35 },
  { points: 520, maxRent: 753.44 },
  { points: 521, maxRent: 754.55 },
  { points: 522, maxRent: 755.64 },
  { points: 523, maxRent: 756.74 },
  { points: 524, maxRent: 757.81 },
  { points: 525, maxRent: 758.95 },
  { points: 526, maxRent: 760.03 },
  { points: 527, maxRent: 761.12 },
  { points: 528, maxRent: 762.21 },
  { points: 529, maxRent: 763.33 },
  { points: 530, maxRent: 764.41 },
  { points: 531, maxRent: 765.51 },
  { points: 532, maxRent: 766.58 },
  { points: 533, maxRent: 767.72 },
  { points: 534, maxRent: 768.79 },
  { points: 535, maxRent: 769.9 },
  { points: 536, maxRent: 770.97 },
  { points: 537, maxRent: 772.08 },
  { points: 538, maxRent: 773.19 },
  { points: 539, maxRent: 774.29 },
  { points: 540, maxRent: 775.34 },
  { points: 541, maxRent: 776.48 },
  { points: 542, maxRent: 777.56 },
  { points: 543, maxRent: 778.63 },
  { points: 544, maxRent: 779.75 },
  { points: 545, maxRent: 780.86 },
  { points: 546, maxRent: 781.93 },
  { points: 547, maxRent: 783.04 },
  { points: 548, maxRent: 784.14 },
  { points: 549, maxRent: 785.21 },
  { points: 550, maxRent: 786.32 },
  { points: 551, maxRent: 787.42 },
  { points: 552, maxRent: 788.52 },
  { points: 553, maxRent: 789.59 },
  { points: 554, maxRent: 790.72 },
  { points: 555, maxRent: 791.81 },
  { points: 556, maxRent: 792.91 },
  { points: 557, maxRent: 794.0 },
  { points: 558, maxRent: 795.1 },
  { points: 559, maxRent: 796.17 },
  { points: 560, maxRent: 797.3 },
  { points: 561, maxRent: 798.38 },
  { points: 562, maxRent: 799.48 },
  { points: 563, maxRent: 800.6 },
  { points: 564, maxRent: 801.67 },
  { points: 565, maxRent: 802.74 },
  { points: 566, maxRent: 803.84 },
  { points: 567, maxRent: 804.97 },
  { points: 568, maxRent: 806.04 },
  { points: 569, maxRent: 807.16 },
  { points: 570, maxRent: 808.23 },
  { points: 571, maxRent: 809.33 },
  { points: 572, maxRent: 810.42 },
  { points: 573, maxRent: 811.54 },
  { points: 574, maxRent: 812.63 },
  { points: 575, maxRent: 813.75 },
  { points: 576, maxRent: 814.84 },
  { points: 577, maxRent: 815.93 },
  { points: 578, maxRent: 817.02 },
  { points: 579, maxRent: 818.13 },
  { points: 580, maxRent: 819.21 },
  { points: 581, maxRent: 820.28 },
  { points: 582, maxRent: 821.4 },
  { points: 583, maxRent: 822.49 },
  { points: 584, maxRent: 823.57 },
  { points: 585, maxRent: 824.69 },
  { points: 586, maxRent: 825.79 },
  { points: 587, maxRent: 826.86 },
  { points: 588, maxRent: 827.95 },
  { points: 589, maxRent: 829.04 },
  { points: 590, maxRent: 830.16 },
  { points: 591, maxRent: 831.23 },
  { points: 592, maxRent: 832.35 },
  { points: 593, maxRent: 833.43 },
  { points: 594, maxRent: 834.53 },
  { points: 595, maxRent: 835.64 },
  { points: 596, maxRent: 836.75 },
  { points: 597, maxRent: 837.83 },
  { points: 598, maxRent: 838.93 },
  { points: 599, maxRent: 840.04 },
  { points: 600, maxRent: 841.1 },
  { points: 601, maxRent: 842.2 },
  { points: 602, maxRent: 843.3 },
  { points: 603, maxRent: 844.4 },
  { points: 604, maxRent: 845.52 },
  { points: 605, maxRent: 846.61 },
  { points: 606, maxRent: 847.68 },
  { points: 607, maxRent: 848.78 },
  { points: 608, maxRent: 849.88 },
  { points: 609, maxRent: 850.97 },
  { points: 610, maxRent: 852.08 },
  { points: 611, maxRent: 853.18 },
  { points: 612, maxRent: 854.27 },
  { points: 613, maxRent: 855.35 },
  { points: 614, maxRent: 856.45 },
  { points: 615, maxRent: 857.55 },
  { points: 616, maxRent: 858.67 },
  { points: 617, maxRent: 859.74 },
  { points: 618, maxRent: 860.83 },
  { points: 619, maxRent: 861.94 },
  { points: 620, maxRent: 863.05 },
  { points: 621, maxRent: 864.14 },
  { points: 622, maxRent: 865.22 },
  { points: 623, maxRent: 866.32 },
  { points: 624, maxRent: 867.42 },
  { points: 625, maxRent: 868.5 },
  { points: 626, maxRent: 869.63 },
  { points: 627, maxRent: 870.7 },
  { points: 628, maxRent: 871.82 },
  { points: 629, maxRent: 872.88 },
  { points: 630, maxRent: 873.99 },
  { points: 631, maxRent: 875.08 },
  { points: 632, maxRent: 876.18 },
  { points: 633, maxRent: 877.27 },
  { points: 634, maxRent: 878.37 },
  { points: 635, maxRent: 879.46 },
  { points: 636, maxRent: 880.56 },
  { points: 637, maxRent: 881.67 },
  { points: 638, maxRent: 882.75 },
  { points: 639, maxRent: 883.87 },
  { points: 640, maxRent: 884.94 },
  { points: 641, maxRent: 886.07 },
  { points: 642, maxRent: 887.16 },
  { points: 643, maxRent: 888.26 },
  { points: 644, maxRent: 889.34 },
  { points: 645, maxRent: 890.44 },
  { points: 646, maxRent: 891.53 },
  { points: 647, maxRent: 892.59 },
  { points: 648, maxRent: 893.74 },
  { points: 649, maxRent: 894.8 },
  { points: 650, maxRent: 895.92 },
  { points: 651, maxRent: 897.0 },
  { points: 652, maxRent: 898.12 },
  { points: 653, maxRent: 899.16 },
  { points: 654, maxRent: 900.31 },
  { points: 655, maxRent: 901.37 },
  { points: 656, maxRent: 902.5 },
  { points: 657, maxRent: 903.57 },
  { points: 658, maxRent: 904.69 },
  { points: 659, maxRent: 905.76 },
  { points: 660, maxRent: 906.87 },
  { points: 661, maxRent: 907.97 },
  { points: 662, maxRent: 909.06 },
  { points: 663, maxRent: 910.15 },
  { points: 664, maxRent: 911.25 },
  { points: 665, maxRent: 912.35 },
  { points: 666, maxRent: 913.43 },
  { points: 667, maxRent: 914.55 },
  { points: 668, maxRent: 915.63 },
  { points: 669, maxRent: 916.72 },
  { points: 670, maxRent: 917.81 },
  { points: 671, maxRent: 918.91 },
  { points: 672, maxRent: 920.01 },
  { points: 673, maxRent: 921.1 },
  { points: 674, maxRent: 922.2 },
  { points: 675, maxRent: 923.28 },
  { points: 676, maxRent: 924.39 },
  { points: 677, maxRent: 925.49 },
  { points: 678, maxRent: 926.58 },
  { points: 679, maxRent: 927.66 },
  { points: 680, maxRent: 928.8 },
  { points: 681, maxRent: 929.88 },
  { points: 682, maxRent: 930.96 },
  { points: 683, maxRent: 932.06 },
  { points: 684, maxRent: 933.14 },
  { points: 685, maxRent: 934.26 },
  { points: 686, maxRent: 935.35 },
  { points: 687, maxRent: 936.45 },
  { points: 688, maxRent: 937.54 },
  { points: 689, maxRent: 938.65 },
  { points: 690, maxRent: 939.74 },
  { points: 691, maxRent: 940.83 },
  { points: 692, maxRent: 941.94 },
  { points: 693, maxRent: 943.05 },
  { points: 694, maxRent: 944.13 },
  { points: 695, maxRent: 945.19 },
  { points: 696, maxRent: 946.32 },
  { points: 697, maxRent: 947.39 },
  { points: 698, maxRent: 948.51 },
  { points: 699, maxRent: 949.57 },
  { points: 700, maxRent: 950.71 },
  { points: 701, maxRent: 951.78 },
  { points: 702, maxRent: 952.9 },
  { points: 703, maxRent: 953.97 },
  { points: 704, maxRent: 955.07 },
  { points: 705, maxRent: 956.16 },
  { points: 706, maxRent: 957.27 },
  { points: 707, maxRent: 958.36 },
  { points: 708, maxRent: 959.46 },
  { points: 709, maxRent: 960.56 },
  { points: 710, maxRent: 961.64 },
  { points: 711, maxRent: 962.75 },
  { points: 712, maxRent: 963.85 },
  { points: 713, maxRent: 964.93 },
  { points: 714, maxRent: 966.04 },
  { points: 715, maxRent: 967.13 },
  { points: 716, maxRent: 968.24 },
  { points: 717, maxRent: 969.31 },
  { points: 718, maxRent: 970.44 },
  { points: 719, maxRent: 971.5 },
  { points: 720, maxRent: 972.61 },
  { points: 721, maxRent: 973.7 },
  { points: 722, maxRent: 974.84 },
  { points: 723, maxRent: 975.89 },
  { points: 724, maxRent: 977.02 },
  { points: 725, maxRent: 978.08 },
  { points: 726, maxRent: 979.19 },
  { points: 727, maxRent: 980.28 },
  { points: 728, maxRent: 981.37 },
  { points: 729, maxRent: 982.49 },
  { points: 730, maxRent: 983.57 },
  { points: 731, maxRent: 984.66 },
  { points: 732, maxRent: 985.75 },
  { points: 733, maxRent: 986.87 },
  { points: 734, maxRent: 987.95 },
  { points: 735, maxRent: 989.05 },
  { points: 736, maxRent: 990.13 },
  { points: 737, maxRent: 991.26 },
  { points: 738, maxRent: 992.34 },
  { points: 739, maxRent: 993.43 },
  { points: 740, maxRent: 994.53 },
  { points: 741, maxRent: 995.63 },
  { points: 742, maxRent: 996.74 },
  { points: 743, maxRent: 997.82 },
  { points: 744, maxRent: 998.92 },
  { points: 745, maxRent: 1000.0 },
  { points: 746, maxRent: 1001.13 },
  { points: 747, maxRent: 1002.2 },
  { points: 748, maxRent: 1003.29 },
  { points: 749, maxRent: 1004.38 },
  { points: 750, maxRent: 1005.48 },
];

const maxRentPriceIndependent: MaxRent[] = [
  { points: 40, maxRent: 194.91 },
  { points: 41, maxRent: 199.78 },
  { points: 42, maxRent: 204.64 },
  { points: 43, maxRent: 209.52 },
  { points: 44, maxRent: 214.38 },
  { points: 45, maxRent: 219.24 },
  { points: 46, maxRent: 224.14 },
  { points: 47, maxRent: 228.99 },
  { points: 48, maxRent: 233.88 },
  { points: 49, maxRent: 238.75 },
  { points: 50, maxRent: 243.62 },
  { points: 51, maxRent: 248.46 },
  { points: 52, maxRent: 253.36 },
  { points: 53, maxRent: 258.21 },
  { points: 54, maxRent: 263.1 },
  { points: 55, maxRent: 267.96 },
  { points: 56, maxRent: 272.87 },
  { points: 57, maxRent: 277.68 },
  { points: 58, maxRent: 282.56 },
  { points: 59, maxRent: 287.46 },
  { points: 60, maxRent: 292.33 },
  { points: 61, maxRent: 297.17 },
  { points: 62, maxRent: 302.06 },
  { points: 63, maxRent: 306.91 },
  { points: 64, maxRent: 311.8 },
  { points: 65, maxRent: 316.66 },
  { points: 66, maxRent: 321.55 },
  { points: 67, maxRent: 326.42 },
  { points: 68, maxRent: 331.27 },
  { points: 69, maxRent: 336.13 },
  { points: 70, maxRent: 341.0 },
  { points: 71, maxRent: 345.88 },
  { points: 72, maxRent: 350.77 },
  { points: 73, maxRent: 355.61 },
  { points: 74, maxRent: 360.5 },
  { points: 75, maxRent: 365.36 },
  { points: 76, maxRent: 370.25 },
  { points: 77, maxRent: 375.1 },
  { points: 78, maxRent: 380.0 },
  { points: 79, maxRent: 384.85 },
  { points: 80, maxRent: 389.73 },
  { points: 81, maxRent: 395.06 },
  { points: 82, maxRent: 400.41 },
  { points: 83, maxRent: 405.76 },
  { points: 84, maxRent: 411.09 },
  { points: 85, maxRent: 416.47 },
  { points: 86, maxRent: 421.81 },
  { points: 87, maxRent: 427.14 },
  { points: 88, maxRent: 432.5 },
  { points: 89, maxRent: 437.83 },
  { points: 90, maxRent: 443.2 },
  { points: 91, maxRent: 448.53 },
  { points: 92, maxRent: 453.87 },
  { points: 93, maxRent: 459.24 },
  { points: 94, maxRent: 464.57 },
  { points: 95, maxRent: 469.92 },
  { points: 96, maxRent: 475.27 },
  { points: 97, maxRent: 480.64 },
  { points: 98, maxRent: 485.97 },
  { points: 99, maxRent: 491.33 },
  { points: 100, maxRent: 496.67 },
  { points: 101, maxRent: 502.01 },
  { points: 102, maxRent: 507.34 },
  { points: 103, maxRent: 512.7 },
  { points: 104, maxRent: 518.06 },
  { points: 105, maxRent: 523.38 },
  { points: 106, maxRent: 528.75 },
  { points: 107, maxRent: 534.09 },
  { points: 108, maxRent: 539.43 },
  { points: 109, maxRent: 544.79 },
  { points: 110, maxRent: 550.14 },
  { points: 111, maxRent: 555.5 },
  { points: 112, maxRent: 560.83 },
  { points: 113, maxRent: 566.17 },
  { points: 114, maxRent: 571.54 },
  { points: 115, maxRent: 576.89 },
  { points: 116, maxRent: 582.22 },
  { points: 117, maxRent: 587.57 },
  { points: 118, maxRent: 592.9 },
  { points: 119, maxRent: 598.25 },
  { points: 120, maxRent: 603.6 },
  { points: 121, maxRent: 608.96 },
  { points: 122, maxRent: 614.31 },
  { points: 123, maxRent: 619.65 },
  { points: 124, maxRent: 625.02 },
  { points: 125, maxRent: 630.36 },
  { points: 126, maxRent: 635.69 },
  { points: 127, maxRent: 641.04 },
  { points: 128, maxRent: 646.43 },
  { points: 129, maxRent: 651.72 },
  { points: 130, maxRent: 657.09 },
  { points: 131, maxRent: 662.44 },
  { points: 132, maxRent: 667.77 },
  { points: 133, maxRent: 673.14 },
  { points: 134, maxRent: 678.47 },
  { points: 135, maxRent: 683.84 },
  { points: 136, maxRent: 689.15 },
  { points: 137, maxRent: 694.52 },
  { points: 138, maxRent: 699.88 },
  { points: 139, maxRent: 705.2 },
  { points: 140, maxRent: 710.55 },
  { points: 141, maxRent: 715.92 },
  { points: 142, maxRent: 721.24 },
  { points: 143, maxRent: 726.58 },
  { points: 144, maxRent: 731.95 },
  { points: 145, maxRent: 737.31 },
  { points: 146, maxRent: 742.64 },
  { points: 147, maxRent: 748.0 },
  { points: 148, maxRent: 753.34 },
  { points: 149, maxRent: 758.69 },
  { points: 150, maxRent: 764.03 },
  { points: 151, maxRent: 769.37 },
  { points: 152, maxRent: 774.71 },
  { points: 153, maxRent: 780.06 },
  { points: 154, maxRent: 785.41 },
  { points: 155, maxRent: 790.76 },
  { points: 156, maxRent: 796.09 },
  { points: 157, maxRent: 801.48 },
  { points: 158, maxRent: 806.79 },
  { points: 159, maxRent: 812.17 },
  { points: 160, maxRent: 817.5 },
  { points: 161, maxRent: 822.84 },
  { points: 162, maxRent: 828.21 },
  { points: 163, maxRent: 833.53 },
  { points: 164, maxRent: 838.88 },
  { points: 165, maxRent: 844.24 },
  { points: 166, maxRent: 849.59 },
  { points: 167, maxRent: 854.92 },
  { points: 168, maxRent: 860.26 },
  { points: 169, maxRent: 865.65 },
  { points: 170, maxRent: 870.98 },
  { points: 171, maxRent: 876.32 },
  { points: 172, maxRent: 881.66 },
  { points: 173, maxRent: 887.03 },
  { points: 174, maxRent: 892.35 },
  { points: 175, maxRent: 897.71 },
  { points: 176, maxRent: 903.05 },
  { points: 177, maxRent: 908.4 },
  { points: 178, maxRent: 913.76 },
  { points: 179, maxRent: 919.1 },
  { points: 180, maxRent: 924.42 },
  { points: 181, maxRent: 929.81 },
  { points: 182, maxRent: 935.15 },
  { points: 183, maxRent: 940.48 },
  { points: 184, maxRent: 945.81 },
  { points: 185, maxRent: 951.19 },
  { points: 186, maxRent: 956.54 },
  { points: 187, maxRent: 961.87 },
  { points: 188, maxRent: 967.24 },
  { points: 189, maxRent: 972.57 },
  { points: 190, maxRent: 977.92 },
  { points: 191, maxRent: 983.28 },
  { points: 192, maxRent: 988.61 },
  { points: 193, maxRent: 993.97 },
  { points: 194, maxRent: 999.33 },
  { points: 195, maxRent: 1004.67 },
  { points: 196, maxRent: 1009.99 },
  { points: 197, maxRent: 1015.35 },
  { points: 198, maxRent: 1020.71 },
  { points: 199, maxRent: 1026.03 },
  { points: 200, maxRent: 1031.4 },
  { points: 201, maxRent: 1036.73 },
  { points: 202, maxRent: 1042.08 },
  { points: 203, maxRent: 1047.42 },
  { points: 204, maxRent: 1052.78 },
  { points: 205, maxRent: 1058.13 },
  { points: 206, maxRent: 1063.47 },
  { points: 207, maxRent: 1068.84 },
  { points: 208, maxRent: 1074.18 },
  { points: 209, maxRent: 1079.52 },
  { points: 210, maxRent: 1084.89 },
  { points: 211, maxRent: 1090.21 },
  { points: 212, maxRent: 1095.56 },
  { points: 213, maxRent: 1100.91 },
  { points: 214, maxRent: 1106.25 },
  { points: 215, maxRent: 1111.6 },
  { points: 216, maxRent: 1116.94 },
  { points: 217, maxRent: 1122.3 },
  { points: 218, maxRent: 1127.63 },
  { points: 219, maxRent: 1132.99 },
  { points: 220, maxRent: 1138.36 },
  { points: 221, maxRent: 1143.67 },
  { points: 222, maxRent: 1149.03 },
  { points: 223, maxRent: 1154.38 },
  { points: 224, maxRent: 1159.73 },
  { points: 225, maxRent: 1165.06 },
  { points: 226, maxRent: 1170.42 },
  { points: 227, maxRent: 1175.77 },
  { points: 228, maxRent: 1181.12 },
  { points: 229, maxRent: 1186.48 },
  { points: 230, maxRent: 1191.81 },
  { points: 231, maxRent: 1197.15 },
  { points: 232, maxRent: 1202.51 },
  { points: 233, maxRent: 1207.85 },
  { points: 234, maxRent: 1213.18 },
  { points: 235, maxRent: 1218.55 },
  { points: 236, maxRent: 1223.89 },
  { points: 237, maxRent: 1229.24 },
  { points: 238, maxRent: 1234.57 },
  { points: 239, maxRent: 1239.94 },
  { points: 240, maxRent: 1245.28 },
  { points: 241, maxRent: 1250.63 },
  { points: 242, maxRent: 1255.99 },
  { points: 243, maxRent: 1261.32 },
  { points: 244, maxRent: 1266.68 },
  { points: 245, maxRent: 1272.03 },
  { points: 246, maxRent: 1277.37 },
  { points: 247, maxRent: 1282.69 },
  { points: 248, maxRent: 1288.08 },
  { points: 249, maxRent: 1293.4 },
  { points: 250, maxRent: 1298.74 },
];

export enum MaxRentType {
  Indepenent = "Indepenent",
  Dependent = "Dependent",
}

export class Assignment {
  public static isLocationPlaceOption(
    option: LocationPlaceOption | LocationPlace
  ): option is LocationPlaceOption {
    return (<LocationPlaceOption>option).value !== undefined;
  }

  public static isFloor(floorOrSpace: Floor | Space): floorOrSpace is Floor {
    return (<Floor>floorOrSpace).floorType !== undefined;
  }

  public static validForSave(objectAssignment: ObjectAssignment): boolean {
    if (!objectAssignment) return false;

    const {
      linkedEmployee,
      realEstateGroup,
      listingType,
      address,
      leisureType,
      isNew,
      assignmentPhase,
    } = objectAssignment;

    if (assignmentPhase === AssignmentPhase.Concept) return true;
    if (!linkedEmployee || !realEstateGroup || !listingType) return false;
    if (
      realEstateGroup === RealEstateGroup.Commercial &&
      listingType === ListingType.Leisure &&
      !leisureType
    )
      return false;

    if (
      !address ||
      !has(address, "street.name") ||
      !has(address, "houseNumber") ||
      !has(address, "locality.name") ||
      !has(address, "countryIso2")
    )
      return false;

    return true;
  }

  public static getErrors(
    objectAssignment: ObjectAssignment
  ): AssignmentSaveError[] {
    const errors: AssignmentSaveError[] = [];
    const {
      linkedEmployee,
      realEstateGroup,
      listingType,
      address,
      leisureType,
      isNew,
      assignmentPhase,
    } = objectAssignment;

    if (assignmentPhase === AssignmentPhase.Concept) return [];
    if (!linkedEmployee) errors.push(AssignmentSaveError.NoLinkedEmployee);
    if (!realEstateGroup) errors.push(AssignmentSaveError.NoRealEstateGroup);
    if (!listingType) errors.push(AssignmentSaveError.NoListingType);
    if (
      realEstateGroup === RealEstateGroup.Commercial &&
      listingType === ListingType.Leisure &&
      !leisureType
    )
      errors.push(AssignmentSaveError.NoLeisureType);
    if (
      !address ||
      !has(address, "street.name") ||
      !has(address, "houseNumber") ||
      !has(address, "locality.name") ||
      !has(address, "countryIso2")
    )
      errors.push(AssignmentSaveError.IncompleteAddress);

    return errors;
  }

  public static validateSubRoutes(
    objectAssignment: ObjectAssignment
  ): ObjectAssignmentValidatedSubRoutes {
    const {
      linkedEmployee,
      realEstateGroup,
      listingType,
      photos,
      numberOfRooms,
      acceptanceDetails,
      isSpecial,
      isTopper,
      maps,
      videos,
      forSale,
    } = objectAssignment;

    const valid = {
      client: false,
      address: false,
      financial: false,
      assignment: false,
      marketing: false,
    };

    if (!!linkedEmployee && !!realEstateGroup && !!listingType) {
      valid.client = true;
    }

    if (
      has(objectAssignment, "address.street.name") &&
      has(objectAssignment, "address.houseNumber")
    ) {
      valid.address = true;
    }

    if (acceptanceDetails) {
      valid.financial = true;
    }

    if (
      (has(objectAssignment, "yearOfConstruction.constructionYear") ||
        has(objectAssignment, "yearOfConstruction.period")) &&
      (realEstateGroup !== RealEstateGroup.Residential ||
        [ListingType.Other, ListingType.Plot, ListingType.Garage].includes(
          listingType
        ) ||
        numberOfRooms) &&
      has(objectAssignment, "usableArea.area") &&
      (listingType !== ListingType.House ||
        !forSale ||
        has(objectAssignment, "parcelSurface.area"))
    ) {
      valid.assignment = true;
    }

    if (
      !valid.assignment &&
      [RealEstateGroup.Agricultural, RealEstateGroup.Commercial].indexOf(
        realEstateGroup
      ) !== -1
    ) {
      valid.assignment = true;
    }

    if (
      (photos && photos.length > 0) ||
      has(objectAssignment, "advertisementText") ||
      has(objectAssignment, "groundFloorText") ||
      has(objectAssignment, "firstFloorText") ||
      has(objectAssignment, "secondFloorText") ||
      has(objectAssignment, "otherFloorsText") ||
      has(objectAssignment, "gardensText") ||
      has(objectAssignment, "balconyText") ||
      has(objectAssignment, "peculiaritiesText") ||
      has(objectAssignment, "titleText") ||
      has(objectAssignment, "testimonial.iList") ||
      has(objectAssignment, "testimonial.testimonialPhoto") ||
      isSpecial ||
      isTopper ||
      (maps && maps.length > 0) ||
      (videos && videos.length > 0)
    ) {
      valid.marketing = true;
    }

    return valid;
  }

  public static mapSnapshotToObjectAssignment(
    snapshot: AssignmentSnapShot
  ): ObjectAssignment {
    if (!snapshot) return;
    const {
      assignmentPhase,
      availabilityStatus,
      forRent,
      forSale,
      listingType,
      realEstateGroup,
      linkedEmployee,
      linkedOffice,
      isActive,
      id,
      displayName,
      keyNote,
      keyNr,
      linkedApplicants,
      linkedNotaries,
      linkedPropertyManagers,
      linkedVendors,
      photo1Url,
      photo2Url,
      photo3Url,
      saleCondition,
      salePrice,
      rentCondition,
      rentPrice,
      mandateDateTime,
    } = snapshot;

    const photos: PhotoBlob[] = [];
    if (!!photo1Url) {
      photos.push({
        width: 0,
        height: 0,
        fileSize: 0,
        urlMedium: photo1Url,
        urlPreview: photo1Url,
        urlBig: photo1Url,
        urlOriginal: photo1Url,
      });
    }
    if (!!photo2Url) {
      photos.push({
        width: 0,
        height: 0,
        fileSize: 0,
        urlMedium: photo2Url,
        urlPreview: photo2Url,
        urlBig: photo2Url,
        urlOriginal: photo2Url,
      });
    }
    if (!!photo3Url) {
      photos.push({
        width: 0,
        height: 0,
        fileSize: 0,
        urlMedium: photo3Url,
        urlPreview: photo3Url,
        urlBig: photo3Url,
        urlOriginal: photo3Url,
      });
    }

    const saleOffer: SaleOffer = !forSale
      ? undefined
      : {
          saleCondition,
          salePrice,
        };
    const rentOffer: RentOffer = !forRent
      ? undefined
      : {
          rentCondition,
          rentPrice,
        };

    return {
      dateTimeCreated: null,
      dateTimeModified: null,
      isNew: false,
      realEstateAgencyId: null,
      assignmentPhase,
      availabilityStatus,
      forRent,
      forSale,
      listingType,
      realEstateGroup,
      linkedEmployee,
      linkedOffice,
      isActive,
      id,
      displayName,
      keyNote,
      keyNr,
      linkedApplicants,
      linkedNotaries,
      linkedPropertyManagers,
      linkedVendors,
      photos,
      saleOffer,
      rentOffer,
      mandateDateTime,
    };
  }

  public static preflightPublishObjectAssignment(
    objectAssignment: ObjectAssignment,
    constructionPeriods: ConstructionPeriodOption[]
  ): AssignmentPublishError[] {
    const errors: AssignmentPublishError[] = [];
    const {
      forSale,
      forRent,
      parcelSurface,
      yearOfConstruction,
      numberOfRooms,
      usableArea,
      contents,
      acceptanceDetails,
      saleOffer,
      rentOffer,
      listingType,
    } = objectAssignment;

    if (!!forSale && !value(saleOffer, "saleCondition")) {
      errors.push(AssignmentPublishError.NoSaleCondition);
    }
    if (!!forRent && !value(rentOffer, "rentCondition")) {
      errors.push(AssignmentPublishError.NoRentCondition);
    }
    if (!value(acceptanceDetails, "acceptance")) {
      errors.push(AssignmentPublishError.NoAcceptance);
    }
    if (!numberOfRooms) {
      errors.push(AssignmentPublishError.NoRoomsCount);
    }
    if (
      !value(yearOfConstruction, "constructionYear") &&
      !value(yearOfConstruction, "period")
    ) {
      errors.push(AssignmentPublishError.NoBuildPeriodOrYear);
    }
    if (
      !!value(yearOfConstruction, "constructionYear") &&
      !!value(yearOfConstruction, "period")
    ) {
      const year = parseInt(value(yearOfConstruction, "constructionYear"));
      const period = constructionPeriods.find(
        (p) => p.minYear <= year && p.maxYear >= year
      );

      if (!period || period.value !== value(yearOfConstruction, "period")) {
        errors.push(AssignmentPublishError.ConstructionYearMismatch);
      }
    }
    if (!value(usableArea, "area")) {
      errors.push(AssignmentPublishError.NoLivingSurface);
    }
    if (!value(parcelSurface, "area")) {
      errors.push(AssignmentPublishError.NoParcelSurface);
    }
    if (
      !value(contents, "volume") &&
      ![ListingType.Parking, ListingType.Plot].includes(listingType)
    ) {
      errors.push(AssignmentPublishError.NoContents);
    }

    return errors;
  }

  public static getSelectedVVEOptions(
    objectAssignment: ObjectAssignment,
    vVeOptions: VVEOption[]
  ): VVEOption[] {
    return vVeOptions.reduce((state, option) => {
      if (get(objectAssignment, option.value)) {
        state.push(option.value);
      }
      return state;
    }, []);
  }

  public static setSelectedVVEOptions(
    selectedVveOptions: string[],
    vVeOptions: VVEOption[]
  ): { [key: string]: boolean } {
    const values = {};

    vVeOptions.map((option) => {
      values[option.value] = selectedVveOptions.indexOf(option.value) !== -1;
    });

    return values;
  }

  public static isObjectAssigment(
    assignment: ObjectAssignment | any
  ): assignment is ObjectAssignment {
    return assignment.id !== undefined;
  }

  public static getMaxRent(type: MaxRentType, points: number): number | null {
    let values: MaxRent[] = [];

    switch (type) {
      case MaxRentType.Dependent: {
        values = maxRentPriceDependent;
        break;
      }
      case MaxRentType.Indepenent: {
        values = maxRentPriceIndependent;
        break;
      }
      default:
        break;
    }

    const value = values.filter((v) => v.points === points);
    if (value.length > 0) return value[0].maxRent;
    return -1;
  }

  public static getAssignmentPath(
    assignment: AssignmentSnapShot | LinkedAssignment
  ): string {
    const { id, typeOfAssignment } = assignment;
    const route = RouteUtil.mapStaticRouteValues;
    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return path;
  }

  public static prepareObjectAssignmentFloors(
    objectAssignment: ObjectAssignment
  ): ObjectAssignment {
    let shouldHaveFloors = false;
    if (Assignment.isObjectAssigment(objectAssignment)) {
      const { listingType } = objectAssignment;
      shouldHaveFloors = [ListingType.Apartment, ListingType.House].includes(
        listingType
      );
    } else {
      const { typePART } = objectAssignment as ObjectTypeAssignment;
      shouldHaveFloors = [
        TypePART.Apartment,
        TypePART.ResidentialBuilding,
      ].includes(typePART);
    }

    if (!shouldHaveFloors) {
      return {
        ...objectAssignment,
        floors: null,
      };
    }

    if (!objectAssignment.floors) {
      objectAssignment = {
        ...objectAssignment,
        floors: [],
      };
    }

    if (!objectAssignment.floors.length) {
      objectAssignment = {
        ...objectAssignment,
        floors: [
          {
            floorType: FloorType.GroundFloor,
            floorNumber: 1,
            spaces: [],
          },
        ],
        numberOfFloors: 1,
      };
    }

    return objectAssignment;
  }

  public static mapAssignmentSnapshotToLinkedAssignment(
    snapshot: AssignmentSnapShot
  ): LinkedAssignment {
    const {
      id,
      assignmentPhase,
      availabilityStatus,
      countryCode,
      displayName,
      forRent,
      forSale,
      hasCadastres,
      isActive,
      keyNote,
      keyNr,
      listingType,
      locality,
      photo1Url,
      postalCode,
      publicReference,
      realEstateGroup,
      rentCondition,
      rentPrice,
      saleCondition,
      salePrice,
      streetNameAndNumber,
      typeOfAssignment,
      dateTimeCreated,
      dateTimeModified,
    } = snapshot;

    return {
      id,
      isActive,
      dateTimeCreated,
      dateTimeModified,
      assignmentPhase,
      availabilityStatus,
      countryCode,
      displayName,
      forRent,
      forSale,
      hasCadastres,
      keyNote,
      keyNr,
      listingType,
      locality,
      photo1Url,
      postalCode,
      publicReference,
      realEstateGroup,
      rentCondition,
      rentPrice,
      saleCondition,
      salePrice,
      streetNameAndNumber,
      typeOfAssignment,
    };
  }

  public static getAssignmentAroRelations(
    assignment:
      | ObjectAssignment
      | ProjectAssignment
      | ObjectTypeAssignment
      | AcquisitionAssignment
      | AcquisitionObjectAssignment
  ): LinkedRelationWithRole[] {
    const {
      applicants,
      appraisers,
      clients,
      financialAdvisors,
      inspectors,
      notaries,
      occupants,
      offeringAgencies,
      peopleWhoOpted,
      photographers,
      potentials,
      projectDevelopers,
      propertyManagers,
      purchasingBrokers,
      rentalAgents,
      salesBrokers,
      contactPersons,
      stylists,
      tenantRepresentationBrokers,
      vendors,
    } = Assignment.mapLinkedRelationsToLinkedRelationsWithRoles(assignment);

    return sortBy(
      [
        ...applicants,
        ...appraisers,
        ...clients,
        ...financialAdvisors,
        ...inspectors,
        ...notaries,
        ...occupants,
        ...offeringAgencies,
        ...peopleWhoOpted,
        ...photographers,
        ...potentials,
        ...projectDevelopers,
        ...propertyManagers,
        ...purchasingBrokers,
        ...rentalAgents,
        ...salesBrokers,
        ...contactPersons,
        ...stylists,
        ...tenantRepresentationBrokers,
        ...vendors,
      ].filter((relation) => !!relation.displayName),
      (relation) => relation.displayName
    );
  }

  public static mapLinkedRelationsToLinkedRelationsWithRoles(
    assignment:
      | ObjectAssignment
      | ProjectAssignment
      | ObjectTypeAssignment
      | AcquisitionAssignment
      | AcquisitionObjectAssignment
  ) {
    const {
      linkedApplicants,
      linkedAppraisers,
      linkedClients,
      linkedFinancialAdvisors,
      linkedInspectors,
      linkedNotaries,
      linkedOccupants,
      linkedOfferingAgencies,
      linkedPeopleWhoOpted,
      linkedPhotographers,
      linkedPotentials,
      linkedProjectDevelopers,
      linkedPropertyManagers,
      linkedPurchasingBrokers,
      linkedRentalAgents,
      linkedSalesBrokers,
      linkedStylists,
      linkedTenantRepresentationBrokers,
      linkedVendors,
      linkedContactPersons,
    } = assignment;

    const applicants = (linkedApplicants || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Applicant,
        } as LinkedRelationWithRole)
    );
    const appraisers = (linkedAppraisers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Appraiser,
        } as LinkedRelationWithRole)
    );
    const clients = (linkedClients || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Client,
        } as LinkedRelationWithRole)
    );
    const financialAdvisors = (linkedFinancialAdvisors || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.FinancialAdvisor,
        } as LinkedRelationWithRole)
    );
    const inspectors = (linkedInspectors || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Inspector,
        } as LinkedRelationWithRole)
    );
    const notaries = (linkedNotaries || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Notary,
        } as LinkedRelationWithRole)
    );
    const occupants = (linkedOccupants || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Occupant,
        } as LinkedRelationWithRole)
    );
    const offeringAgencies = (linkedOfferingAgencies || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.OfferingAgency,
        } as LinkedRelationWithRole)
    );
    const peopleWhoOpted = (linkedPeopleWhoOpted || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.SomeoneWhoOpted,
        } as LinkedRelationWithRole)
    );
    const photographers = (linkedPhotographers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Photographer,
        } as LinkedRelationWithRole)
    );
    const potentials = (linkedPotentials || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Potential,
        } as LinkedRelationWithRole)
    );
    const projectDevelopers = (linkedProjectDevelopers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.ProjectDeveloper,
        } as LinkedRelationWithRole)
    );
    const propertyManagers = (linkedPropertyManagers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.PropertyManager,
        } as LinkedRelationWithRole)
    );
    const purchasingBrokers = (linkedPurchasingBrokers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.PurchasingBroker,
        } as LinkedRelationWithRole)
    );
    const rentalAgents = (linkedRentalAgents || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.RentalAgent,
        } as LinkedRelationWithRole)
    );
    const salesBrokers = (linkedSalesBrokers || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.SalesBroker,
        } as LinkedRelationWithRole)
    );
    const contactPersons = (linkedContactPersons || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.ContactPerson,
        } as LinkedRelationWithRole)
    );
    const stylists = (linkedStylists || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Stylist,
        } as LinkedRelationWithRole)
    );
    const tenantRepresentationBrokers = (
      linkedTenantRepresentationBrokers || []
    ).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.TenantRepresentationBroker,
        } as LinkedRelationWithRole)
    );
    const vendors = (linkedVendors || []).map(
      (relation) =>
        ({
          ...relation,
          relationRole: RelationRole.Vendor,
        } as LinkedRelationWithRole)
    );

    return {
      applicants,
      appraisers,
      clients,
      financialAdvisors,
      inspectors,
      notaries,
      occupants,
      offeringAgencies,
      peopleWhoOpted,
      photographers,
      potentials,
      projectDevelopers,
      propertyManagers,
      purchasingBrokers,
      rentalAgents,
      salesBrokers,
      contactPersons,
      stylists,
      tenantRepresentationBrokers,
      vendors,
    };
  }

  public static addLinkedRelation(
    assignment: RelationTypeAssignment,
    relationRole: RelationRole,
    linkedRelation: LinkedRelation
  ): RelationTypeAssignment {
    let updatedAssignment = assignment;

    switch (relationRole) {
      case RelationRole.Applicant: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedApplicants: [
            ...(updatedAssignment.linkedApplicants || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Appraiser: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedAppraisers: [
            ...(updatedAssignment.linkedAppraisers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Client: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedClients: [
            ...(updatedAssignment.linkedClients || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.FinancialAdvisor: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedFinancialAdvisors: [
            ...(updatedAssignment.linkedFinancialAdvisors || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Inspector: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedInspectors: [
            ...(updatedAssignment.linkedInspectors || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Notary: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedNotaries: [
            ...(updatedAssignment.linkedNotaries || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Occupant: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedOccupants: [
            ...(updatedAssignment.linkedOccupants || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.OfferingAgency: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedOfferingAgencies: [
            ...(updatedAssignment.linkedOfferingAgencies || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Photographer: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedPhotographers: [
            ...(updatedAssignment.linkedPhotographers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Potential: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedPotentials: [
            ...(updatedAssignment.linkedPotentials || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.ProjectDeveloper: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedProjectDevelopers: [
            ...(updatedAssignment.linkedProjectDevelopers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.PropertyManager: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedPropertyManagers: [
            ...(updatedAssignment.linkedPropertyManagers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.PurchasingBroker: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedPurchasingBrokers: [
            ...(updatedAssignment.linkedPurchasingBrokers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.RentalAgent: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedRentalAgents: [
            ...(updatedAssignment.linkedRentalAgents || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.SalesBroker: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedSalesBrokers: [
            ...(updatedAssignment.linkedSalesBrokers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.ContactPerson: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedContactPersons: [
            ...(updatedAssignment.linkedContactPersons || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.SomeoneWhoOpted: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedPeopleWhoOpted: [
            ...(updatedAssignment.linkedPeopleWhoOpted || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Stylist: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedStylists: [
            ...(updatedAssignment.linkedStylists || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.TenantRepresentationBroker: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedTenantRepresentationBrokers: [
            ...(updatedAssignment.linkedTenantRepresentationBrokers || []),
            linkedRelation,
          ],
        };
        break;
      }
      case RelationRole.Vendor: {
        updatedAssignment = {
          ...updatedAssignment,
          linkedVendors: [
            ...(updatedAssignment.linkedVendors || []),
            linkedRelation,
          ],
        };
        break;
      }
      default: {
        break;
      }
    }

    return updatedAssignment;
  }

  public static updateLinkedRelation(
    assignment: RelationTypeAssignment,
    relationRole: RelationRole,
    linkedRelation: LinkedRelation
  ): RelationTypeAssignment {
    const updatedAssignment = Assignment.removeLinkedRelation(
      assignment,
      linkedRelation
    );
    return Assignment.addLinkedRelation(
      updatedAssignment,
      relationRole,
      linkedRelation
    );
  }

  public static removeLinkedRelation(
    assignment: RelationTypeAssignment,
    linkedRelation: LinkedRelation
  ): RelationTypeAssignment {
    const {
      linkedApplicants,
      linkedAppraisers,
      linkedClients,
      linkedFinancialAdvisors,
      linkedInspectors,
      linkedNotaries,
      linkedOccupants,
      linkedOfferingAgencies,
      linkedPeopleWhoOpted,
      linkedPhotographers,
      linkedPotentials,
      linkedProjectDevelopers,
      linkedPropertyManagers,
      linkedPurchasingBrokers,
      linkedRentalAgents,
      linkedSalesBrokers,
      linkedContactPersons,
      linkedStylists,
      linkedTenantRepresentationBrokers,
      linkedVendors,
    } = assignment;

    const applicants = (linkedApplicants || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const appraisers = (linkedAppraisers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const clients = (linkedClients || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const financialAdvisors = (linkedFinancialAdvisors || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const inspectors = (linkedInspectors || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const notaries = (linkedNotaries || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const occupants = (linkedOccupants || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const offeringAgencies = (linkedOfferingAgencies || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const peopleWhoOpted = (linkedPeopleWhoOpted || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const photographers = (linkedPhotographers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const potentials = (linkedPotentials || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const projectDevelopers = (linkedProjectDevelopers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const propertyManagers = (linkedPropertyManagers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const purchasingBrokers = (linkedPurchasingBrokers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const rentalAgents = (linkedRentalAgents || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const salesBrokers = (linkedSalesBrokers || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const contactPersons = (linkedContactPersons || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const stylists = (linkedStylists || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );
    const tenantRepresentationBrokers = (
      linkedTenantRepresentationBrokers || []
    ).filter((relation) => relation.id !== linkedRelation.id);
    const vendors = (linkedVendors || []).filter(
      (relation) => relation.id !== linkedRelation.id
    );

    return {
      ...assignment,
      linkedApplicants: applicants,
      linkedAppraisers: appraisers,
      linkedClients: clients,
      linkedFinancialAdvisors: financialAdvisors,
      linkedInspectors: inspectors,
      linkedNotaries: notaries,
      linkedOccupants: occupants,
      linkedOfferingAgencies: offeringAgencies,
      linkedPeopleWhoOpted: peopleWhoOpted,
      linkedPhotographers: photographers,
      linkedPotentials: potentials,
      linkedProjectDevelopers: projectDevelopers,
      linkedPropertyManagers: propertyManagers,
      linkedPurchasingBrokers: purchasingBrokers,
      linkedRentalAgents: rentalAgents,
      linkedSalesBrokers: salesBrokers,
      linkedContactPersons: contactPersons,
      linkedStylists: stylists,
      linkedTenantRepresentationBrokers: tenantRepresentationBrokers,
      linkedVendors: vendors,
    };
  }

  public static mapAssignmentSnapShotToEmailAssignment(
    assignment: AssignmentSnapShot,
    intl: IntlShape
  ): EmailAssignment {
    const {
      id,
      displayName,
      forRent,
      forSale,
      listingType,
      locality,
      photo1Url,
      photo2Url,
      photo3Url,
      postalCode,
      rentCondition,
      rentPrice,
      saleCondition,
      salePrice,
      streetNameAndNumber,
      furnishing,
      numberOfBedrooms,
      numberOfBathRooms,
    } = assignment;

    let addressLine1: string;
    let addressLine2: string;

    if (!!locality && !!postalCode && !!streetNameAndNumber) {
      addressLine1 = streetNameAndNumber;
      addressLine2 = [postalCode, locality].join(" ");
    } else {
      addressLine1 = displayName;
    }

    const image = photo1Url || photo2Url || photo3Url;
    const priceToRender = forSale ? salePrice : rentPrice;
    const price = !priceToRender
      ? undefined
      : intl.formatNumber(priceToRender, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
    const priceCondition =
      !!forSale && !!saleCondition
        ? intl.formatMessage({
            id: `saleConditions.${saleCondition.toString()}`,
            defaultMessage: "",
          })
        : !!forRent && !!rentCondition
        ? intl.formatMessage({
            id: `rentConditions.${rentCondition.toString()}`,
            defaultMessage: "",
          })
        : undefined;
    const propertyTypes = !listingType
      ? undefined
      : [
          intl.formatMessage({
            id: `listingTypes.${listingType.toString()}`,
            defaultMessage: "",
          }),
        ].filter((d) => !!d);
    const numOfBedrooms = !numberOfBedrooms
      ? undefined
      : intl.formatMessage(
          { id: "bedroomCount", defaultMessage: "" },
          { bedrooms: numberOfBedrooms }
        );
    const numOfBathrooms = !numberOfBathRooms
      ? undefined
      : intl.formatMessage(
          { id: "bathroomCount", defaultMessage: "" },
          { bathrooms: numberOfBathRooms }
        );

    const facilities = !furnishing
      ? undefined
      : [
          intl.formatMessage({
            id: `furnishingOptions.${furnishing.toString()}`,
            defaultMessage: "",
          }),
        ].filter((d) => !!d);

    return {
      id,
      image,
      addressLine1,
      addressLine2,
      price,
      priceCondition,
      numberOfBedrooms: numOfBedrooms,
      numberOfBathrooms: numOfBathrooms,
      facilities,
      propertyTypes,
    };
  }
}
