import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentEditFinancialComponent,
  AssignmentEditFinancialComponentProps
} from "@haywork/modules/assignment";
import { AppState, SingleAssignmentState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  currentComponentState: SingleAssignmentState;
  enableHidePriceForObjectAssignmentsOption: boolean;
}
interface DispatchProps {
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentEditFinancialComponentProps,
  AppState
> = (state) => ({
  currentComponentState: state.editable.currentComponentState,
  enableHidePriceForObjectAssignmentsOption:
    state.company.settings.enableHidePriceForObjectAssignmentsOption
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentEditFinancialComponentProps
> = (dispatch) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path))
});

export type AssignmentEditFinancialContainerProps = StateProps & DispatchProps;
export const AssignmentEditFinancialContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditFinancialComponent);
