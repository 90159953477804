import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { intlContext } from "@haywork/app";

const styles = require("./style.scss");

interface VoipActionsUserInstanceProps {
  linkedCommunicationLogId: string | null;
  onViewRelation: () => void;
  onCreateAppointment: () => void;
  onNoteChange: (note: string) => void;
}
interface State {
  note: string;
}
type Props = VoipActionsUserInstanceProps;

@CSSModules(styles, { allowMultiple: true })
export class VoipActionsUserComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      note: "",
    };

    this.onNoteChangeHandler = this.onNoteChangeHandler.bind(this);
  }

  public render() {
    const { onCreateAppointment, onViewRelation } = this.props;
    const placeholder = "voipNotePlaceholder";

    return (
      <>
        <div styleName="actions">
          <button type="button" onClick={() => onViewRelation()}>
            <ResourceText resourceKey="voipAction.viewRelation" />
          </button>
          <button type="button" onClick={() => onCreateAppointment()}>
            <ResourceText resourceKey="voipAction.createAppointment" />
          </button>
        </div>
        {!!this.props.linkedCommunicationLogId && (
          <div styleName="notes">
            <textarea
              value={this.state.note}
              onChange={this.onNoteChangeHandler}
              placeholder={intlContext.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })}
              data-lpignore="true"
            />
          </div>
        )}
      </>
    );
  }

  private onNoteChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = event.target;
    const note = value || "";
    this.setState({ note });
    this.props.onNoteChange(note);
  }
}
