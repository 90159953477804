import { FormElement } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import { Hint, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface DropDownComponentProps {
  formElement: FlattenedFormElement;
  value?: string;
  focus: boolean;
  onChange: (formElement: FormElement) => void;
  onFocus: (name: string) => void;
}
interface DropDownComponentState {
  value: string;
}

@CSSModules(styles, { allowMultiple: true })
export class DropDownComponent extends React.Component<
  DropDownComponentProps,
  DropDownComponentState
> {
  constructor(props) {
    super(props);

    this.onChangehandler = this.onChangehandler.bind(this);

    this.state = {
      value: this.props.value || "",
    };
  }

  public render() {
    const {
      name,
      type,
      preText,
      postText,
      maximumLength,
      format,
      values,
      disabled,
    } = this.props.formElement;

    return (
      <div styleName="element">
        {!!preText && <div styleName="element__pre">{preText}</div>}
        <div styleName="element__input">
          <Hint message="fieldNotNeeded" shouldRender={disabled}>
            <Input.Select
              name={name}
              value={this.state.value}
              onChange={this.onChangehandler}
              placeHolderWhenEmpty={this.getPlaceholder}
              onFocus={() => this.props.onFocus(name)}
              focus={this.props.focus}
              disabled={disabled}
            >
              {values.map((v, idx) => (
                <Input.Option value={v.trim()} key={idx}>
                  {v}
                </Input.Option>
              ))}
            </Input.Select>
          </Hint>
        </div>
        {!!postText && <div styleName="element__pre">{postText}</div>}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: DropDownComponentProps) {
    if (!nextProps) return false;
    return (
      nextProps.focus !== this.props.focus ||
      nextProps.formElement.disabled !== this.props.formElement.disabled
    );
  }

  private onChangehandler(value: string) {
    if (value === this.state.value) return;

    this.setState({ value });
    this.forceUpdate();

    const { name, type, values } = this.props.formElement;
    const selectedIndex = !!value.trim() ? values.indexOf(value) : undefined;
    const formElement: FormElement = {
      name,
      type,
      dropDownInfo: {
        dropDownOptions: values,
        selectedIndex,
      },
    };

    this.props.onChange(formElement);
  }

  private getPlaceholder(): React.ReactElement<HTMLDivElement> {
    return (
      <div className="form__select-option">
        <div>
          <ResourceText resourceKey="makeAChoice" />
        </div>
      </div>
    );
  }
}
