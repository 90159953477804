import * as React from "react";
import { memo } from "react";
import { DefaultFolderTreeSnapShot } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  category: DefaultFolderTreeSnapShot;
  zebra: boolean;
  openDefaultFolderTree: (category: DefaultFolderTreeSnapShot) => void;
}
export const SettingsDossierFoldersListItemComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ category, openDefaultFolderTree, zebra }: Props) => {
      if (!category) return null;

      return (
        <div
          onClick={() => openDefaultFolderTree(category)}
          styleName={classNames("item", { zebra })}
        >
          <ResourceText
            masterKey="defaultFolderTreeCategoryOptions"
            resourceKey={category.category.toString()}
          />
        </div>
      );
    }
  )
);
