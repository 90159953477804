import { Action } from "redux";

import { REDUX } from "@haywork/constants";
import { ReportTemplate, ExtensionInfo } from "@haywork/api/kolibri";

import * as ActionType from "./templates.types";

export interface AssignmentTemplatesState {
  printTemplates: ReportTemplate[];
  printExportOptions: ExtensionInfo[];
}

const INITIAL_STATE: AssignmentTemplatesState = {
  printTemplates: [],
  printExportOptions: []
};

export const templatesReducer = (
  state: AssignmentTemplatesState = INITIAL_STATE,
  action: Action
): AssignmentTemplatesState => {
  switch (action.type) {
    case REDUX.ASSIGNMENT.SET_PRINT_TEMPLATES: {
      const { printTemplates } = <ActionType.PrintTemplates>action;

      return {
        ...state,
        printTemplates
      };
    }
    case REDUX.ASSIGNMENT.SET_PRINT_EXPORT_OPTIONS: {
      const { printExportOptions } = <ActionType.PrintExportOptions>action;

      return {
        ...state,
        printExportOptions
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
