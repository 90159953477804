import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";

const styles = require("./invoice-detail.component.scss");

const InvoiceLineHeader = () => (
  <div styleName="invoiceLine__header">
    <div styleName="option product">
      <ResourceText resourceKey="productOrService" />
    </div>
    <div styleName="option description">
      <ResourceText resourceKey="definition" />
    </div>
    <div styleName="option amount">
      <ResourceText resourceKey="amount" />
    </div>
    <div styleName="option unitPriceNet">
      <ResourceText resourceKey="exVAT" />
    </div>
    <div styleName="option taxPercentage">
      <ResourceText resourceKey="VATRate" />
    </div>
    <div styleName="option totalPriceGross">
      <ResourceText resourceKey="totalInclusive" />
    </div>
  </div>
);

export const InvoiceLineHeaderComponent = CSSModules(
  InvoiceLineHeader,
  styles,
  { allowMultiple: true }
);
