import * as React from "react";
import { ZohoContainerProps } from "./zoho.container";
import * as CSSModules from "react-css-modules";
import Asap from "./components/asap";
import { FeatureSwitch } from "../feature-switch";

const styles = require("./style.scss");

export interface ZohoComponentProps {}
type Props = ZohoComponentProps & ZohoContainerProps;

export const ZohoComponent = CSSModules(styles, { allowMultiple: true })(
  ({  }: Props) => {
    return (
      <>
        <FeatureSwitch feature="ZOHO_ASAP">
          <Asap />
        </FeatureSwitch>
      </>
    );
  }
);
