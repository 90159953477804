import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { SettingsAssignmentsContainerProps } from "@haywork/modules/settings/modules/assignments";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./assignments.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface SettingsAssignmentsComponentProps {}
interface State {}
type Props = SettingsAssignmentsComponentProps &
  SettingsAssignmentsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsAssignmentsComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.formControls = {
      prefix: {
        value: value(this.props.settings, "objectReferenceSettings.prefix")
      },
      nextNumber: {
        value: value(this.props.settings, "objectReferenceSettings.nextNumber")
      },
      fixedNumberWidth: {
        value: value(
          this.props.settings,
          "objectReferenceSettings.fixedNumberWidth"
        )
      }
    };
  }

  public render() {
    return (
      <div styleName="assignments">
        <PageHeader
          title="settingsAssignmentsTitle"
          subTitle="settingsMenuUsage"
          actions={
            <Button
              label="save"
              category="success"
              onClick={this.onSubmitClickHandler}
              disabled={this.props.settingsStatus === REQUEST.PENDING}
            />
          }
        />

        <div className="alert alert-info">
          <i className="fal fa-fw fa-info-circle" />
          <p>
            <ResourceText resourceKey="assignmentReferencesInfoMessage" />
          </p>
        </div>

        <div styleName="assignments__body">
          {this.props.settingsStatus === REQUEST.PENDING && (
            <Ui.Loaders.Fullscreen mask />
          )}

          <div styleName="inner">
            <Form
              name="settingsAssignmentsTitle"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              form={(form) => (this.formRef = form)}
            >
              <div className="form__row">
                <div className="form__group center">
                  <div styleName="form__label">
                    <label htmlFor="prefix">
                      <ResourceText resourceKey="prefix" />
                    </label>
                  </div>
                  <div styleName="form__input">
                    <Input.Text name="prefix" />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__group center">
                  <div styleName="form__label">
                    <label htmlFor="nextNumber">
                      <ResourceText resourceKey="nextNumber" />
                    </label>
                  </div>
                  <div styleName="form__input">
                    <Input.Number name="nextNumber" max={9999999} />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__group center">
                  <div styleName="form__label">
                    <label htmlFor="fixedNumberWidth">
                      <ResourceText resourceKey="fixedNumberWidth" />
                    </label>
                  </div>
                  <div styleName="form__input">
                    <Input.Number name="fixedNumberWidth" max={99} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitHandler(values: FormReturnValue) {
    const settings = {
      ...this.props.settings,
      objectReferenceSettings: {
        ...this.props.settings.objectReferenceSettings,
        ...values
      }
    };

    this.props.saveCompanySettings(settings);
  }

  private onSubmitClickHandler() {
    if (!!this.formRef) {
      this.formRef.submit();
    }
  }
}
