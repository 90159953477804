import * as React from "react";
import { FC, memo } from "react";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";

type Props = {
  onEmptyStateActionClick: () => void;
};

export const EmptyStateComponent: FC<Props> = memo(
  ({ onEmptyStateActionClick }) => {
    return (
      <EmptyState
        icon="folder-open"
        title="acquisitionAssignments.emptyStateList.title"
        subTitle="acquisitionAssignments.emptyStateList.subTitle"
        action={
          <Button
            label="acquisition.emptyStateList.action"
            category="primary"
            onClick={onEmptyStateActionClick}
          />
        }
      />
    );
  }
);
