import * as React from "react";
import * as CSSModules from "react-css-modules";

import { EmployeeFilter } from "@haywork/middleware/thunk/employee.thunk";
import {
  DropdownFilter,
  Filter,
  MappedDropdownValue,
} from "@haywork/modules/filter";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./relation.filter.component.scss");

interface EmployeeFilterComponentProps {
  onFilterChange?: (filter: EmployeeFilter) => void;
  activeFilterOptions?: MappedDropdownValue[];
  officeFilterOptions?: MappedDropdownValue[];
  selectedEmployeeFilter?: EmployeeFilter;
}

interface EmployeeFilterComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class EmployeeFilterComponent extends React.Component<
  EmployeeFilterComponentProps,
  EmployeeFilterComponentState
> {
  constructor(props) {
    super(props);
    this.onFilterChangedHandler = this.onFilterChangedHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="selection">
        <Filter changeFilter={this.onFilterChangedHandler}>
          <ResourceText resourceKey="youreWatching" />
          <DropdownFilter
            name="activeFilter"
            inline={true}
            values={this.props.activeFilterOptions}
            value={this.props.selectedEmployeeFilter.activeOrInactiveFilter}
          />
          <ResourceText resourceKey="employees" />
        </Filter>
      </div>
    );
  }

  private onFilterChangedHandler(values: any) {
    const employeeFilter: EmployeeFilter = {
      activeOrInactiveFilter: values.activeFilter ? values.activeFilter : null,
      officeFilter: values.officeFilter ? values.officeFilter : null,
    };
    this.props.onFilterChange(employeeFilter);
  }
}
