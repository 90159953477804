import {
  ArchiveFilter,
  EventNotificationsClient,
  EventType,
  EventsClient,
  SortOrder,
  EventNotificationsOrderByField,
} from "@haywork/api/event-center";
import {
  GENERAL_EVENT_TYPES,
  PERSONAL_EVENT_TYPES,
  PERSONAL_EVENT_TYPES_IMMUTABLE,
  GENERAL_EVENT_TYPES_IMMUTABLE,
  MLS_EVENT_TYPES,
} from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import { AppState } from "@haywork/stores";
import { NotificationsActions } from "@haywork/stores/notifications";
import { Dispatch } from "../";
import { AsyncUtil } from "@haywork/util";

const parseRequest = new ParseRequest();

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: employeeId } = state.account.employee;
      const { order, filters } = state.notifications.eventCenter;
      const {
        eventNotificationDateTime,
        typeFilters,
        personalNotifications,
        generalNotifications,
      } = filters;
      const isPersonal = typeFilters.value.includes("personal");
      const isGeneral = typeFilters.value.includes("general");
      const isProcessed = typeFilters.value.includes("processed");
      const client = new EventNotificationsClient(eventCenterHost);

      let filterByEventTypes: EventType[] = [];
      if (isPersonal && !isGeneral) {
        filterByEventTypes = [
          ...PERSONAL_EVENT_TYPES,
          ...PERSONAL_EVENT_TYPES_IMMUTABLE,
          ...MLS_EVENT_TYPES,
        ];
      }
      if (isGeneral && !isPersonal) {
        filterByEventTypes = [
          ...GENERAL_EVENT_TYPES,
          ...GENERAL_EVENT_TYPES_IMMUTABLE,
        ];
      }
      if (
        !!personalNotifications.value.length ||
        !!generalNotifications.value.length
      ) {
        filterByEventTypes = [
          ...personalNotifications.value,
          ...generalNotifications.value,
        ];
      }

      const filterByArchived = isProcessed
        ? ArchiveFilter.ArchivedOnly
        : ArchiveFilter.NotArchivedOnly;

      if (startIndex === 0) {
        dispatch(getStatistics());
      }
      const result = await parseRequest.response(
        client.search(
          {
            employeeId,
            order: order.sortOrder,
            orderBy: order.sortColumn,
            includeStatistics: false,
            filterByArchived,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByEventTypes,
            minEventNotificationDateTime: eventNotificationDateTime.value.min,
            maxEventNotificationDateTime: eventNotificationDateTime.value.max,
          },
          realEstateAgencyId
        )
      );

      if (!result) {
        return;
      }

      const { results, totalResults } = result;
      dispatch(
        NotificationsActions.EventCenter.updateList(
          startIndex,
          stopIndex,
          results,
          totalResults
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getStatistics = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: employeeId } = state.account.employee;
      const client = new EventNotificationsClient(eventCenterHost);

      const result = await parseRequest.response(
        client.search(
          {
            employeeId,
            order: SortOrder.Ascending,
            orderBy: EventNotificationsOrderByField.DefaultOrder,
            includeStatistics: true,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            skip: 0,
            take: 0,
          },
          realEstateAgencyId
        )
      );

      if (!result) {
        return;
      }

      const { statistics } = result;
      dispatch(NotificationsActions.EventCenter.updateStatistics(statistics));
    } catch (error) {
      throw error;
    }
  };
};

const toggleIsRead = (eventNotificationId: string, isRead = true) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: employeeId } = state.account.employee;
      const { culture } = state.main;
      const client = new EventNotificationsClient(eventCenterHost);

      await parseRequest.response(
        client.toggleIsRead(
          {
            employeeId,
            eventNotificationId,
            isRead,
          },
          realEstateAgencyId
        )
      );

      const notification = await parseRequest.response(
        client
          .read(employeeId, culture, eventNotificationId, realEstateAgencyId)
          .then((response) => response.eventNotification)
      );

      dispatch(getStatistics());
      if (!!notification) {
        dispatch(NotificationsActions.EventCenter.updateListItem(notification));
      }
    } catch (error) {
      throw error;
    }
  };
};

const toggleAllIsRead = (isRead = true) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { id: employeeId } = state.account.employee;
      const client = new EventNotificationsClient(eventCenterHost);

      await parseRequest.response(
        client.toggleAllIsRead(
          {
            employeeId,
            isRead,
          },
          realEstateAgencyId
        )
      );

      dispatch(getStatistics());
    } catch (error) {
      throw error;
    }
  };
};

const toggleIsArchived = (eventId: string, isArchived = true) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new EventsClient(eventCenterHost);

      await parseRequest.response(
        client.toggleIsArchived(
          {
            eventId,
            isArchived,
          },
          realEstateAgencyId
        )
      );

      dispatch(getStatistics());
      dispatch(NotificationsActions.EventCenter.removeByEventId(eventId));
    } catch (error) {
      throw error;
    }
  };
};

const toggleAllIsArchived = (isArchived = true) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new EventsClient(eventCenterHost);

      await parseRequest.response(
        client.toggleAllIsArchived(
          {
            isArchived,
          },
          realEstateAgencyId
        )
      );

      dispatch(getStatistics());
    } catch (error) {
      throw error;
    }
  };
};

const refreshEventNotification = (eventNotificationId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { eventCenterHost } = state.appSettings;
      const { id: employeeId } = state.account.employee;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { culture } = state.main;
      const client = new EventNotificationsClient(eventCenterHost);

      const notification = await parseRequest.response(
        client
          .read(employeeId, culture, eventNotificationId, realEstateAgencyId)
          .then((response) => response.eventNotification)
      );

      dispatch(getStatistics());
      if (!!notification) {
        dispatch(NotificationsActions.EventCenter.updateListItem(notification));
      }
    } catch (error) {
      throw error;
    }
  };
};

const updateEventNotificationByMessageId = (entityId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { notifications } = state.notifications.eventCenter;
      const ref = notifications.find((notification) =>
        (notification?.linkedEvent?.linkedMessages || [])
          .map((message) => message.id)
          .includes(entityId)
      );

      if (!!ref) {
        await AsyncUtil.wait(500);
        dispatch(refreshEventNotification(ref.id));
      }
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getListItems,
  getStatistics,
  toggleIsRead,
  toggleAllIsRead,
  toggleIsArchived,
  toggleAllIsArchived,
  refreshEventNotification,
  updateEventNotificationByMessageId,
};
