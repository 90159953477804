import { MAINROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./menu.component.scss");

interface Props {}

@CSSModules(styles, { allowMultiple: true })
export class AppMenu extends React.Component<Props> {
  public render() {
    const exchangeIcon = require("static/logo_icon-exchange.svg");
    const housenetIcon = require("static/logo_icon-housenet.svg");

    return (
      <ul styleName="menu">
        <li>
          <NavLink
            to={MAINROUTES.EXCHANGE.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
          >
            <img src={exchangeIcon} />
            <div styleName="label">
              <ResourceText resourceKey="OutlookbarExchange" />
            </div>
          </NavLink>
        </li>
        <li styleName="hayweb">
          <NavLink
            to={MAINROUTES.HOUSENET.URI}
            activeClassName={styles.menu__item__active}
            styleName="menu__item"
          >
            <img src={housenetIcon} />
            <div styleName="label">
              <ResourceText resourceKey="OutlookbarHousenet" />
            </div>
          </NavLink>
        </li>
      </ul>
    );
  }
}
