import { FolderCategory } from "@haywork/api/mail";
import { EmailUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import { FC, memo, MouseEvent, useCallback, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./accounts.component.scss");

export interface Props {
  depth: number;
  displayName: string;
  hasSubFolders: boolean;
  category: FolderCategory;
  selected: boolean;
  expanded: boolean;
  count: number;
  onClick: () => void;
  onExpandClick: (event: MouseEvent<HTMLDivElement>) => Promise<void>;
}

export const StaticTitle: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      selected,
      expanded,
      depth,
      category,
      onClick,
      onExpandClick,
      count,
      hasSubFolders,
      displayName,
    }) => {
      const [loading, setLoading] = useState(false);

      const onExpand = useCallback(
        async (event: MouseEvent<HTMLDivElement>) => {
          try {
            setLoading(true);
            await onExpandClick(event);
          } finally {
            setLoading(false);
          }
        },
        [setLoading, onExpandClick]
      );

      const titleStyle = useMemo(
        () =>
          classNames("folder__title", {
            active: selected,
            expanded,
          }),
        [selected, expanded]
      );
      const folderIconStyle = useMemo(
        () => classNames("folder__icon", `depth${depth}`),
        [depth]
      );
      const iconStyle = useMemo(
        () => classNames("fal", EmailUtil.getFolderIcon(category)),
        [category]
      );
      const expandStyle = useMemo(
        () =>
          classNames("fal", loading ? "fa-spin fa-spinner" : "fa-chevron-down"),
        [loading]
      );

      return (
        <div styleName={titleStyle} onClick={onClick} data-cy="CY-emailFolder">
          <div styleName={folderIconStyle}>
            <i className={iconStyle} />
          </div>
          <div styleName="folder__name">{displayName}</div>
          {count > 0 && <div styleName="folder__count">{count}</div>}
          {!!hasSubFolders && (
            <div styleName="folder__expand" onClick={onExpand}>
              <i className={expandStyle} />
            </div>
          )}
        </div>
      );
    }
  )
);
