import { AssignmentType, LinkedAssignment } from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
} from "@haywork/constants";
import { ExtendedEventNotification, RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./body.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  message: ExtendedEventNotification;
  onLinkClicked?: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentBodyComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onLinkClickedHandler = this.onLinkClickedHandler.bind(this);
  }

  public render() {
    let {
      linkedObjectAssignments,
      linkedProjectAssignments,
    } = this.props.message.linkedEvent;
    linkedObjectAssignments = linkedObjectAssignments || [];
    linkedProjectAssignments = linkedProjectAssignments || [];

    if (!linkedObjectAssignments.length && !linkedProjectAssignments.length)
      return null;

    return (
      <div styleName="body">
        {linkedObjectAssignments.map((assignment, idx) => (
          <div styleName="row" key={idx}>
            <i className="fa fa-folder-open" />
            <NavLink
              to={this.getLinkedAssignmentPath(assignment)}
              onClick={this.onLinkClickedHandler}
            >
              {assignment.displayName}
            </NavLink>
          </div>
        ))}
        {linkedProjectAssignments.map((project, idx) => (
          <div styleName="row" key={idx}>
            <i className="fa fa-folder-open" />
            <NavLink
              to={this.getLinkedAssignmentPath(project)}
              onClick={this.onLinkClickedHandler}
            >
              {project.displayName}
            </NavLink>
          </div>
        ))}
      </div>
    );
  }

  private onLinkClickedHandler() {
    if (this.props.onLinkClicked) this.props.onLinkClicked();
  }

  private getLinkedAssignmentPath({ typeOfAssignment, id }: LinkedAssignment) {
    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return path;
  }
}
