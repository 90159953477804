import * as React from "react";
import * as CSSModules from "react-css-modules";
import uniqBy from "lodash-es/uniqBy";

import { MergeField } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import { ResourceText } from "../resource-text/resource-text.component";

const styles = require("./merge-fields.component.scss");

interface Props {
  results: MergeField[];
  searchString: string;
  onClick: (field: MergeField) => void;
}

export const Results = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    if (!props.results || !props.results.length)
      return (
        <div styleName="no-results">
          <ResourceText resourceKey="noFilteredMergeFields" />
        </div>
      );

    const categories = uniqBy(props.results, (result) => result.categoryDisplayName);
    const categorizedResults = categories.reduce((state, category) => {
      state.push({
        name: category.categoryDisplayName,
        fields: props.results.filter(
          (result) => result.categoryDisplayName === category.categoryDisplayName
        )
      });

      return state;
    }, []);

    return (
      <div styleName="results">
        {categorizedResults.map((category, idx) => (
          <div styleName="category" key={idx}>
            <div styleName="category__title">{category.name}</div>
            <div styleName="category__results">
              {category.fields.map((field, idx) => (
                <div
                  styleName="item"
                  key={idx}
                  data-cy="CY-mergeFieldItem"
                  dangerouslySetInnerHTML={StringUtil.highlight(
                    field.displayName,
                    props.searchString
                  )}
                  onClick={() => props.onClick(field)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
);
