import { EmployeeRole, LinkedRealEstateAgency } from "@haywork/api/kolibri";
import { FolderCategory } from "@haywork/api/mail";
import {
  CompanySettingsThunks,
  Dispatch,
  EditableThunks,
  MainThunks,
} from "@haywork/middleware";
import {
  DefaultLayoutComponent,
  DefaultLayoutComponentProps,
} from "@haywork/modules/layout";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  customLogo,
  linkedRealEstateAgencies,
  hasOriginalPersonId,
} from "./selectors";
import { FeatureHelper } from "@haywork/modules/feature-switch/helpers";
import { push } from "connected-react-router";
import { EventType } from "@haywork/api/event-center";

interface StateProps {
  sidebarCollapsed: boolean;
  fullscreen: boolean;
  environment: string;
  version: string;
  usersnapApiKey: string;
  createLoaderVisible: boolean;
  isBackofficeEmployee: boolean;
  exchangeEnabled: boolean;
  housenetEnabled: boolean;
  realEstateAgencyId: string;
  emailUnreadCount: number;
  notificationUnreadCount: number;
  features: string[];
  linkedRealEstateAgencies: LinkedRealEstateAgency[];
  customLogo: string | null;
  blobErrorVisible: boolean;
  blobError: string | null;
  showPlayground: boolean;
  hasOriginalPersonId: boolean;
}
interface DispatchProps {
  onToggleSidebar: () => void;
  onMainMenuLinkClick: () => void;
  getHousenetSingleSignOnUrl: () => Promise<string>;
  getExchangeSingleSignOnUrl: () => Promise<string>;
  closeAllTabs: () => void;
  switchRealEstateAgency: (id: string) => void;
  closeBlobError: () => void;
  navigate: (path: string) => void;
}

const mapStateToProps = <StateProps, DefaultLayoutComponentProps>(
  state: AppState
) => {
  const { sidebarCollapsed, fullscreen, createLoaderVisible, blobError } =
    state.layout;
  const { environment, version, usersnapApiKey } = state.appSettings;
  const { exchangeEnabled, housenetEnabled } = state.company.settings;
  const { currentRealestateAgency } = state.account;
  const { id: realEstateAgencyId } = currentRealestateAgency;
  const { shares } = state.emailV2.shares;
  const { folders } = state.emailV2.folders;
  const { features } = state.appSettings;

  const hiddenShareIds = shares
    .filter((share) => share.isHidden)
    .map((share) => share.accountId);
  const emailUnreadCount = folders.reduce((state, folder) => {
    if (
      hiddenShareIds.indexOf(folder.accountId) === -1 &&
      folder.category === FolderCategory.Inbox // Unread count is only for items in the inbox as per decision
    ) {
      state = state + folder.unreadCount;
    }
    return state;
  }, 0);

  let notificationUnreadCount = 0;
  if (FeatureHelper.executeBlock(features, "EVENTCENTER_V2")) {
    const { statistics } = state.notifications.eventCenter;
    notificationUnreadCount = statistics?.numberOfNotArchivedEvents || 0;
    (statistics?.numberOfNotArchivedEventsPerType || []).forEach((item) => {
      if (
        [EventType.AgendaItemReminder, EventType.TaskReminder].includes(
          item.eventType
        )
      ) {
        notificationUnreadCount -= item.count;
      }
    });
  }

  return {
    sidebarCollapsed,
    fullscreen,
    environment,
    version,
    usersnapApiKey,
    createLoaderVisible,
    isBackofficeEmployee:
      state.account.currentRealestateAgency.role === EmployeeRole.BackOffice,
    exchangeEnabled,
    housenetEnabled,
    realEstateAgencyId,
    emailUnreadCount,
    notificationUnreadCount,
    features,
    linkedRealEstateAgencies: linkedRealEstateAgencies(state),
    customLogo: customLogo(state),
    blobErrorVisible: !!blobError,
    blobError,
    showPlayground:
      process.env.NODE_ENV === "development" || environment === "Alpha",
    hasOriginalPersonId: hasOriginalPersonId(state),
  };
};

const mapDispatchToProps = <DispatchProps, DefaultLayoutComponentProps>(
  dispatch: Dispatch<any>
) => ({
  onToggleSidebar: () => dispatch(LayoutActions.toggleSidebar()),
  onMainMenuLinkClick: () => dispatch(LayoutActions.autoHideSidebarOnMobile()),
  getHousenetSingleSignOnUrl: () =>
    dispatch(CompanySettingsThunks.getHousenetSingleSignOnUrl()),
  getExchangeSingleSignOnUrl: () =>
    dispatch(CompanySettingsThunks.getExchangeSingleSignOnUrl()),
  closeAllTabs: () => dispatch(EditableThunks.clearAllTabs()),
  switchRealEstateAgency: (id: string) =>
    dispatch(MainThunks.switchRealEstateAgency(id)),
  closeBlobError: () =>
    dispatch(LayoutActions.setBlobError({ blobError: null })),
  navigate: (path: string) => dispatch(push(path)),
});

export type DefaultLayoutContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<{}>;
export const DefaultLayoutContainer = connect<
  StateProps,
  DispatchProps,
  DefaultLayoutComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayoutComponent);
