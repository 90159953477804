export const OFFICE_EXTENSIONS = [
  ".doc",
  ".dot",
  ".wbk",
  ".docx",
  ".docm",
  ".dotx",
  ".dotm",
  ".docb",
  ".xls",
  ".xlt",
  ".xlm",
  ".xlsx",
  ".xlsm",
  ".xlsx",
  ".xlsm",
  ".xlsb",
  ".xla",
  ".xlam",
  ".xll",
  ".xlw",
  ".ppt",
  ".pot",
  ".pps",
  ".pptx",
  ".pptm",
  ".potx",
  ".potm",
  ".ppam",
  ".ppsx",
  ".ppsm",
  ".sldx",
  ".sldm",
  ".pub",
  ".xps",
  ".adn",
  ".accdb",
  ".accdr",
  ".accdt",
  ".accda",
  ".mdw",
  ".accde",
  ".mam",
  ".maq",
  ".mar",
  ".mat",
  ".maf",
  ".laccdb",
  ".ade",
  ".adp",
  ".mdb",
  ".cdb",
  ".mda",
  ".mdn",
  ".mdt",
  ".mdf",
  ".mde",
  ".ldb"
];

export const OFFICE_MOST_USED_REGEX = /docx?|xlsx?|pptx?/gi;
