import { WidgetEntityType } from "@haywork/api/authorization";
import { ActiveFilter } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { Input } from "@haywork/modules/form";
import { ObjectTypesDetailBuildnumbersContainerProps } from "@haywork/modules/object-types";
import {
  AddBuildnumbers,
  BuildnumberOverviewComponent,
} from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Notes from "@haywork/modules/notes-v3";

const styles = require("./detail-buildnumbers.component.scss");
const ACTIVEFILTERS = [
  { value: ActiveFilter.ActiveOnly, label: ActiveFilter.ActiveOnly },
  {
    value: ActiveFilter.ActiveOrInactive,
    label: ActiveFilter.ActiveOrInactive,
  },
  { value: ActiveFilter.InactiveOnly, label: ActiveFilter.InactiveOnly },
];

export interface ObjectTypesDetailBuildnumbersComponentProps {}
interface State {
  filterByActive: ActiveFilter;
}
type Props = ObjectTypesDetailBuildnumbersComponentProps &
  ObjectTypesDetailBuildnumbersContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesDetailBuildnumbersComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      filterByActive: ActiveFilter.ActiveOnly,
    };

    this.activeFilterChanged = this.activeFilterChanged.bind(this);
    this.onAddBuildnumberSuccessHandler = this.onAddBuildnumberSuccessHandler.bind(
      this
    );
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.currentType.id,
      WidgetEntityType.ObjectTypeAssignment
    );
  }

  public render() {
    return (
      <div styleName="body">
        <PageHeader
          title="pageTitle.objectTypes.buildNumbers"
          subTitle={this.props.displayName}
          actions={
            <>
              <Notes />
              <Button
                label="addBuildNumber"
                icon={<Icon name="plus" size={18} light />}
                category="primary"
                onClick={() => this.props.toggleAddBuildnumbers(true)}
              />
            </>
          }
        />

        <div styleName="filters">
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="filterByActive" className="pre">
                  <I18n value="objectTypeBuildnumbersOfWhich" />
                </label>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <Input.NewSelect
                  name="filterByActive"
                  values={ACTIVEFILTERS}
                  value={this.state.filterByActive}
                  valuesProp="value"
                  displayProp="label"
                  onChange={this.activeFilterChanged}
                  translate
                  translatePrefix="BuildNumberActiveFilter"
                />
              </div>
            </div>
          </div>
        </div>

        <div styleName="list">
          <BuildnumberOverviewComponent
            buildnumbers={this.props.buildnumbers}
            canLoadMore={this.props.buildnumbersCanLoadMore}
            loading={this.props.loading}
            totalCount={this.props.buildnumbersTotal}
            onGetBuildnumbers={(reset) =>
              this.props.getBuildnumbers(
                this.props.currentType.id,
                this.state.filterByActive,
                reset
              )
            }
            onBuildnumberClick={this.props.navigate}
            data-cy={"CY-buildNumber"}
          />
        </div>

        <AddBuildnumbers
          project={this.props.currentType.parentProject}
          objectTypeId={this.props.currentType.id}
          price={this.props.price}
          onClose={() => this.props.toggleAddBuildnumbers(false)}
          onSuccess={this.onAddBuildnumberSuccessHandler}
        />
      </div>
    );
  }

  private activeFilterChanged(filterByActive) {
    if (this.state.filterByActive === filterByActive) return;

    this.setState({ filterByActive });
    this.props.getBuildnumbers(this.props.currentType.id, filterByActive, true);
  }

  private onAddBuildnumberSuccessHandler() {
    const { id } = this.props.currentType;
    this.props.toggleAddBuildnumbers(false);
    this.props.getBuildnumbers(id, this.state.filterByActive, true);
  }
}
