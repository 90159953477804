import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  AgendaItem,
  AgendaItemOrderByField,
  AgendaItemsClient,
  AgendaItemSnapShot,
  AgendaItemsSearchRequest,
  AgendaItemsSingleItemResponse,
  LinkedAssignment,
  LinkedEmployee,
  LinkedRelation,
  RelationSnapShot,
  SortOrder,
} from "@haywork/api/kolibri";
import { MAINROUTES, REQUEST, SCHEDULERROUTES } from "@haywork/constants";
import { mapAgendaItemToAgendaItemSnapShot } from "@haywork/mappers/agenda-item";
import { EditableThunks } from "@haywork/middleware";
import { Scheduler } from "@haywork/request";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  LayoutActions,
  NewSchedulerItemValues,
  SchedulerActions,
} from "@haywork/stores";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import {
  DateUtil,
  RelationUtil,
  RouteUtil,
  SchedulerUtil,
} from "@haywork/util";
import Axios, { AxiosRequestConfig } from "axios";
import { push } from "connected-react-router";
import { saveAs } from "file-saver";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import * as moment from "moment";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getAgendaItems = (skip: number = 0) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerState(REQUEST.PENDING));

    const state = getState();

    try {
      const result = await Scheduler.search(state, skip);

      skip === 0
        ? dispatch(SchedulerActions.setAgendaItems(result, result.resultCount))
        : dispatch(
            SchedulerActions.appendAgendaItems(result, result.resultCount)
          );

      if (result.resultCount === 99) {
        dispatch(getAgendaItems(skip + 99));
      }
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
      throw error;
    }
  };
};

const getAgendaItemsForToday = (
  returnOccurencesOfRecurringAgendaItems?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerWidgetState(REQUEST.PENDING));

    try {
      const agendaItems = await Scheduler.searchForAgendaItemsToday(
        getState(),
        returnOccurencesOfRecurringAgendaItems
      );

      dispatch(SchedulerActions.setAgendaItemsForToday(agendaItems));
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
      throw error;
    }
  };
};

const addRecurrencyException = (
  parentId: string,
  originalStartDateTime: Date,
  newEndDateTime?: Date,
  newStartDateTime?: Date,
  goToDetail?: boolean,
  allDayEvent?: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerState(REQUEST.PENDING));
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const originalStartDateTimeUTC = originalStartDateTime
      ? DateUtil.convertToUTC(originalStartDateTime)
      : null;
    const newEndDateTimeUTC = newEndDateTime
      ? DateUtil.convertToUTC(newEndDateTime)
      : null;
    const newStartDateTimeUTC = newStartDateTime
      ? DateUtil.convertToUTC(newStartDateTime)
      : null;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      const recurrenceException = await parseRequest.response(
        AgendaItems.addRecurrenceException(
          {
            originalStartDateTime: originalStartDateTimeUTC,
            newEndDateTime: newEndDateTimeUTC,
            newStartDateTime: newStartDateTimeUTC,
            parentId,
            allDayEvent: allDayEvent ? allDayEvent : false,
          },
          realEstateAgencyId
        )
      );

      if (goToDetail) {
        dispatch(
          push(
            `/app/scheduler/${
              recurrenceException.agendaItem
                ? recurrenceException.agendaItem.id
                : recurrenceException.parentAgendaItem.id
            }`
          )
        );
      } else {
        const agendaItems = await Scheduler.search(state);
        dispatch(SchedulerActions.setAgendaItems(agendaItems, 99));
      }
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
      throw error;
    }
  };
};

const deleteAgendaItem = (id: string, path?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerRemoveState(REQUEST.PENDING));
    dispatch(SchedulerActions.setSchedulerState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      await parseRequest.response(AgendaItems.delete(id, realEstateAgencyId));

      if (path) {
        dispatch(EditableThunks.remove(path));
      }

      const agendaItems = await Scheduler.search(state);
      dispatch(SchedulerActions.setAgendaItems(agendaItems, 99));
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
      dispatch(SchedulerActions.setSchedulerRemoveState(REQUEST.ERROR));
      throw error;
    }
  };
};

const moveAgendaItem = (
  id: string,
  newStartDateTime: Date,
  newEndDateTime: Date,
  linkedEmployeeIds: string[],
  agendaItem: AgendaItemSnapShot
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      startDateTime: originalStartDateTime,
      endDateTime: originalEndDateTime,
      linkedEmployees: originalLinkedEmployees,
    } = agendaItem;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      const agendaItem = await parseRequest.response(
        AgendaItems.move(
          {
            id,
            startDateTime: DateUtil.convertToUTC(newStartDateTime),
            endDateTime: DateUtil.convertToUTC(newEndDateTime),
            linkedEmployeeIds,
          },
          realEstateAgencyId
        )
      );

      const agendaItems = await Scheduler.search(state);
      dispatch(SchedulerActions.setAgendaItem(agendaItem));
      dispatch(SchedulerActions.setAgendaItems(agendaItems, 99));
      dispatch(
        SnackbarActions.addToast({
          value: "scheduler.toast.moved",
          callback: async () => {
            dispatch(SchedulerActions.setSchedulerState(REQUEST.PENDING));
            try {
              const agendaItem = await AgendaItems.move(
                {
                  id,
                  startDateTime: originalStartDateTime,
                  endDateTime: originalEndDateTime,
                  linkedEmployeeIds: originalLinkedEmployees.map(
                    (employee) => employee.id
                  ),
                },
                realEstateAgencyId
              );
              const agendaItems = await Scheduler.search(state);
              dispatch(SchedulerActions.setAgendaItem(agendaItem));
              dispatch(SchedulerActions.setAgendaItems(agendaItems, 99));
            } catch (error) {
              dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
              throw error;
            }
          },
          callbackLabel: "scheduler.toast.action.unmove",
          icon: "expand-arrows",
        })
      );
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerState(REQUEST.ERROR));
      throw error;
    }
  };
};

const getAgendaItem = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    if (state.scheduler.agendaItem && state.scheduler.agendaItem.id === id) {
      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.SUCCESS));
      return state.scheduler.agendaItem;
    }

    try {
      const agendaItem = await parseRequest.response(
        AgendaItems.read(id, realEstateAgencyId)
      );
      dispatch(SchedulerActions.setAgendaItem(agendaItem));
      return agendaItem.agendaItem;
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.ERROR));
      throw error;
    }
  };
};

const createSchedulerItem = (
  startDateTime: Date,
  endDateTime: Date,
  linkedEmployeeIds: string[],
  predefinedAgendaItem?: AgendaItem
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.PENDING));
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let newSchedulerItemValues: NewSchedulerItemValues;
    if (!startDateTime || !endDateTime || !linkedEmployeeIds) {
      newSchedulerItemValues = SchedulerUtil.getDefaultNewSchedulerItemValues(
        state.account
      );
    } else {
      newSchedulerItemValues = {
        startDateTime,
        endDateTime,
        linkedEmployeeIds,
      };
    }

    const AgendaItems = new AgendaItemsClient(host);

    try {
      let agendaItemResult = await parseRequest.response(
        AgendaItems.defineNew(
          {
            startDateTime: newSchedulerItemValues.startDateTime,
            endDateTime: newSchedulerItemValues.endDateTime,
            linkedEmployeeIds: newSchedulerItemValues.linkedEmployeeIds,
          },
          realEstateAgencyId
        )
      );

      if (predefinedAgendaItem) {
        agendaItemResult = {
          ...agendaItemResult,
          agendaItem: {
            ...predefinedAgendaItem,
            id: agendaItemResult.agendaItem.id,
            isCanceled: false,
            isConfirmed: false,
            isNew: true,
            parentId: null,
          },
        };
      }

      const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
        id: agendaItemResult.agendaItem.id,
      });

      dispatch(SchedulerActions.setAgendaItem(agendaItemResult));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.SCHEDULER.ICON,
          componentState: agendaItemResult.agendaItem,
          path,
          title: "...",
          confirm: {
            title: { key: "saveAgendaConfirmTitle" },
            body: { key: "saveAgendaConfirmBody" },
          },
          entityType: RootEntityType.Agendaitem,
          entityId: agendaItemResult.agendaItem.id,
        })
      );

      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
      dispatch(push(path));
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.ERROR));
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );

      throw error;
    }
  };
};

const saveAgendaItem = (agendaItem: AgendaItem, path: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      if (agendaItem.isNew) {
        const existing = await parseRequest.response(
          AgendaItems.search(
            {
              orderBy: AgendaItemOrderByField.StartDateTime,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              agendaItemId: agendaItem.id,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          agendaItem = {
            ...agendaItem,
            isNew: false,
          };
        }
      }

      const updatedAgendaItem = await parseRequest.response(
        AgendaItems.save({ agendaItem }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );

      dispatch(SchedulerActions.setAgendaItemDirectly(updatedAgendaItem));
      dispatch(
        EditableActions.updateComponentState({
          componentState: updatedAgendaItem,
          path,
        })
      );

      dispatch(EditableThunks.remove(path));
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.ERROR));
      throw error;
    }
  };
};

const checkForOverlappingAppointments = (agendaItem: AgendaItem) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    const responsibleEmployeeIds =
      agendaItem.linkedEmployees.map((employeee) => employeee.id) || [];

    try {
      const overlapping = await parseRequest.response(
        AgendaItems.search(
          {
            orderBy: AgendaItemOrderByField.StartDateTime,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take: 99,
            periodDateTimeMin: moment(agendaItem.startDateTime)
              .add(1, "minute")
              .toDate(),
            periodDateTimeMax: moment(agendaItem.endDateTime)
              .subtract(1, "minute")
              .toDate(),
            employeeIds: responsibleEmployeeIds,
            returnOccurencesOfRecurringAgendaItems: true,
          },
          realEstateAgencyId
        )
      );

      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.SUCCESS));

      if (
        !!overlapping &&
        !!overlapping.results &&
        !!overlapping.results.length
      ) {
        const filterAllDayOverlap = overlapping.results.filter((agendaItem) => {
          if (!agendaItem.allDayEvent) return agendaItem;
        });

        if (filterAllDayOverlap.length === 0) {
          return { hasOverlap: false, agendaItems: [] };
        }

        return { hasOverlap: true, agendaItems: filterAllDayOverlap };
      } else {
        return { hasOverlap: false, agendaItems: [] };
      }
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerDetailState(REQUEST.ERROR));
      throw error;
    }
  };
};

const confirmAgendaItem = (agendaItem: AgendaItem) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      if (agendaItem.isNew) {
        const existing = await parseRequest.response(
          AgendaItems.search(
            {
              orderBy: AgendaItemOrderByField.StartDateTime,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              agendaItemId: agendaItem.id,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          agendaItem = {
            ...agendaItem,
            isNew: false,
          };
        }
      }

      await parseRequest.response(
        AgendaItems.save({ agendaItem }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );

      const agendaItemResponse = await parseRequest.response(
        AgendaItems.confirm({ id: agendaItem.id }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );

      dispatch(SchedulerActions.setAgendaItem({ agendaItem }));
      dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState: agendaItemResponse,
          path,
          ignoreChanges: true,
          resetExternalChanges: true,
        })
      );
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.ERROR));
      throw error;
    }
  };
};

const cancelAgendaItem = (agendaItem: AgendaItem) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
      id: agendaItem.id,
    });
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      if (agendaItem.isNew) {
        const existing = await parseRequest.response(
          AgendaItems.search(
            {
              orderBy: AgendaItemOrderByField.StartDateTime,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              agendaItemId: agendaItem.id,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          agendaItem = {
            ...agendaItem,
            isNew: false,
          };
        }
      }

      await parseRequest.response(
        AgendaItems.save({ agendaItem }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );

      const agendaItemResponse = await parseRequest.response(
        AgendaItems.cancel({ id: agendaItem.id }, realEstateAgencyId).then(
          (response) => response.agendaItem
        )
      );

      dispatch(
        SchedulerActions.setAgendaItem({ agendaItem: agendaItemResponse })
      );
      dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState: agendaItemResponse,
          path,
        })
      );
    } catch (error) {
      dispatch(SchedulerActions.setSchedulerConfirmationState(REQUEST.ERROR));
      throw error;
    }
  };
};

const createAgendaItemForRelation = (relation: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: employeeId } = state.account.employee;

    const AgendaItems = new AgendaItemsClient(host);

    try {
      let response = await parseRequest.response(
        AgendaItems.defineNew(
          {
            endDateTime: moment().add(1, "hour").toDate(),
            linkedEmployeeIds: [employeeId],
            startDateTime: moment().toDate(),
          },
          realEstateAgencyId
        )
      );

      response = {
        ...response,
        agendaItem: {
          ...response.agendaItem,
          linkedRelations: [RelationUtil.mapSnapshotToLinkedRelation(relation)],
        },
      };

      const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
        id: response.agendaItem.id,
      });

      dispatch(SchedulerActions.setAgendaItem(response));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.SCHEDULER.ICON,
          componentState: response.agendaItem,
          path,
          title: "...",
          confirm: {
            title: { key: "saveAgendaConfirmTitle" },
            body: { key: "saveAgendaConfirmBody" },
          },
          entityType: RootEntityType.Agendaitem,
          entityId: response.agendaItem.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const createAgendaItemWithLinkedEntities = (
  linkedRelations?: LinkedRelation[],
  linkedAssignments?: LinkedAssignment[],
  linkedEmployees?: LinkedEmployee[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const newSchedulerItemValues: NewSchedulerItemValues =
      SchedulerUtil.getDefaultNewSchedulerItemValues(state.account);

    const AgendaItems = new AgendaItemsClient(host);

    try {
      let agendaItemResult = await parseRequest.response(
        AgendaItems.defineNew(
          {
            startDateTime: newSchedulerItemValues.startDateTime,
            endDateTime: newSchedulerItemValues.endDateTime,
            linkedEmployeeIds:
              linkedAssignments &&
              linkedAssignments.length > 0 &&
              linkedEmployees
                ? linkedEmployees.map((employee) => employee.id)
                : newSchedulerItemValues.linkedEmployeeIds,
          },
          realEstateAgencyId
        )
      );

      const { location, subject } = SchedulerUtil.getSubjectAndLocation(
        undefined,
        (linkedAssignments || [])[0]?.displayName
      );

      agendaItemResult = {
        ...agendaItemResult,
        agendaItem: {
          ...agendaItemResult.agendaItem,
          linkedRelations,
          linkedAssignments,
          location,
          subject,
        },
      };

      const path = route(SCHEDULERROUTES.SCHEDULER_DETAIL.URI, {
        id: agendaItemResult.agendaItem.id,
      });

      dispatch(SchedulerActions.setAgendaItem(agendaItemResult));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.SCHEDULER.ICON,
          componentState: agendaItemResult.agendaItem,
          path,
          title: "...",
          confirm: {
            title: { key: "saveAgendaConfirmTitle" },
            body: { key: "saveAgendaConfirmBody" },
          },
          entityType: RootEntityType.Agendaitem,
          entityId: agendaItemResult.agendaItem.id,
        })
      );
      dispatch(push(path));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: AgendaItem = editable.componentState;
    if (!componentState) return;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    try {
      const client = new AgendaItemsClient(host);
      const agendaItemResponse = await parseRequest.response(
        client.read(entityId, realEstateAgencyId),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: AgendaItem = {
          ...componentState,
          ...agendaItemResponse.agendaItem,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(SchedulerActions.setAgendaItem(agendaItemResponse));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } =
        agendaItemResponse.agendaItem;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: agendaItemResponse,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadAgendaItem = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const agendaItemResponse =
      externalChangesData.updatedEntity as AgendaItemsSingleItemResponse;
    const componentState: AgendaItem = {
      ...editable.componentState,
      ...agendaItemResponse.agendaItem,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(SchedulerActions.setAgendaItem(agendaItemResponse));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateAgendaItem = (agendaItemId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { agendaItems, agendaItemsForToday } = state.scheduler;
    const { id: employeeId } = state.account.employee;

    const ref = [...(agendaItems || []), ...(agendaItemsForToday || [])].find(
      (agendaItem) => agendaItem.id === agendaItemId
    );

    if (!ref) return;

    try {
      const client = new AgendaItemsClient(host);
      const agendaItem = await parseRequest.response(
        client
          .read(agendaItemId, realEstateAgencyId)
          .then((response) => response?.agendaItem)
      );
      if (!agendaItem) return;

      const snapshot = mapAgendaItemToAgendaItemSnapShot(
        agendaItem,
        employeeId
      );

      dispatch(SchedulerActions.updateOverviewItem({ snapshot }));
      if (agendaItem.id === agendaItemId) {
        dispatch(SchedulerActions.setAgendaItem({ agendaItem }));
      }
    } catch (error) {
      throw error;
    }
  };
};

const updateAfterAdd = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { employeeIds } = state.scheduler.schedulerFilters;

    try {
      const client = new AgendaItemsClient(host);
      const agendaItem = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.agendaItem)
      );

      if (!agendaItem) return;
      const { linkedEmployees } = agendaItem;
      const linkedEmployeesIds = (linkedEmployees || []).map(
        (employee) => employee.id
      );

      let shouldUpdate = false;
      employeeIds.map((employeeId) => {
        if (linkedEmployeesIds.includes(employeeId)) {
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        dispatch(getAgendaItems());
      }
    } catch (error) {
      throw error;
    }
  };
};

const exportAgenda = (
  periodDateTimeMin: Date,
  periodDateTimeMax: Date,
  culture: string,
  employeeIds: string[],
  categoryIds: string[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host, apiVersion } = state.appSettings;

      const baseRequest: AgendaItemsSearchRequest = {
        order: SortOrder.Ascending,
        orderBy: AgendaItemOrderByField.StartDateTime,
        filterByActive: ActiveFilter.ActiveOrInactive,
        skip: 0,
        take: 500,
      };

      let updatedRequest = { ...baseRequest };

      if (!!periodDateTimeMin) {
        updatedRequest = {
          ...updatedRequest,
          periodDateTimeMin,
        };
      }
      if (!!periodDateTimeMax) {
        updatedRequest = {
          ...updatedRequest,
          periodDateTimeMax,
        };
      }
      if (!!culture) {
        updatedRequest = { ...updatedRequest, culture };
      }
      if (!!employeeIds.length) {
        updatedRequest = { ...updatedRequest, employeeIds };
      }
      if (!!categoryIds.length) {
        updatedRequest = { ...updatedRequest, categoryIds };
      }

      const { token } = state.access;

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        httpAgent: new HttpAgent({ keepAlive: true }),
        httpsAgent: new HttpsAgent({ keepAlive: true }),
        responseType: "arraybuffer",
      };

      const response = await Axios.post(
        `${host}/${apiVersion}/${realEstateAgencyId}/AgendaItems/Export`,
        updatedRequest,
        config
      ).then((response) => {
        return new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      });

      saveAs(response, `agenda-export-${moment().format("DDMMYYYYHHmm")}.xlsx`);
    } catch (error) {
      throw error;
    }
  };
};

export const SchedulerThunks = {
  addRecurrencyException,
  cancelAgendaItem,
  confirmAgendaItem,
  createSchedulerItem,
  deleteAgendaItem,
  getAgendaItem,
  getAgendaItems,
  getAgendaItemsForToday,
  moveAgendaItem,
  saveAgendaItem,
  createAgendaItemForRelation,
  checkExternalChanges,
  reloadAgendaItem,
  createAgendaItemWithLinkedEntities,
  updateAgendaItem,
  checkForOverlappingAppointments,
  updateAfterAdd,
  exportAgenda,
};
