import {
  ConstructionPeriodOption,
  ProjectAssignment,
  PublicationSnapShot,
  PublicationStatus,
} from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { ProjectPublishComponent } from "@haywork/modules/project/components/publish/publish.component";
import { AppState, SingleProjectState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { ProjectPublishError } from "@haywork/util/project";
import { push } from "connected-react-router";
import { connect } from "react-redux";

export interface ProjectPublishContainerProps {
  projectAssignment: ProjectAssignment;
  publicationCount: number;
  publishState: string;
  constructionPeriods: ConstructionPeriodOption[];
  currentComponentState: SingleProjectState;
  path: string;
  navigate: (route: string) => void;
  saveProject: () => void;
  publishProject: (id: string, date?: Date) => Promise<void>;
  setFormErrors: (errors: ProjectPublishError[], id: string) => void;
  updateProject: (componentState: SingleProjectState, path: string) => void;
  onAddNewType: (projectId: string) => void;
}

const filterActivePublications = (
  publications: PublicationSnapShot[]
): number => {
  if (!publications) return 0;
  return publications.filter(
    (publication) =>
      publication.publicationStatus !== PublicationStatus.Inactive
  ).length;
};

const mapStateToProps = (state: AppState) => {
  const {
    projectAssignment,
    publications,
  }: SingleProjectState = state.editable.currentComponentState;
  const { publishState } = state.project.single;
  const { constructionPeriods } = state.main.mastertable.kolibri;
  const { currentComponentState } = state.editable;

  return {
    projectAssignment,
    publicationCount: filterActivePublications(publications),
    publishState,
    constructionPeriods,
    currentComponentState,
    path: RouteUtil.mapStaticRouteValues(PROJECTROUTES.DETAIL.URI, {
      id: state.editable.currentComponentState.projectAssignment.id,
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  navigate: (route: string) => dispatch(push(route)),
  saveProject: () => dispatch(ProjectsThunks.Projects.saveProject()),
  publishProject: (id: string, date?: Date) =>
    dispatch(ProjectsThunks.Projects.publishProjectAssignment(id, date)),
  setFormErrors: (errors: ProjectPublishError[], id: string) =>
    dispatch(ProjectsThunks.Projects.setPublishFormErrors(errors, id)),
  updateProject: (componentState: SingleProjectState, path: string) =>
    dispatch(
      ProjectsThunks.Projects.updateProjectEditable(componentState, path)
    ),
  onAddNewType: (projectId: string) =>
    dispatch(ProjectsThunks.Types.newType(projectId)),
});

export const ProjectPublishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectPublishComponent);
