import { FormElement } from "@haywork/api/kolibri";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FlattenedFormElement } from "./";

const styles = require("./form-element.component.scss");

interface TextComponentProps {
  formElement: FlattenedFormElement;
  value?: string;
  focus: boolean;
  onChange: (formElement: FormElement) => void;
  onFocus: (name: string) => void;
}
interface TextComponentState {
  value: string;
}

@CSSModules(styles, { allowMultiple: true })
export class TextComponent extends React.Component<
  TextComponentProps,
  TextComponentState
> {
  constructor(props) {
    super(props);

    this.onChangehandler = this.onChangehandler.bind(this);

    this.state = {
      value: this.props.value || "",
    };
  }

  public render() {
    const {
      name,
      type,
      preText,
      postText,
      maximumLength,
      format,
      disabled,
    } = this.props.formElement;

    return (
      <div styleName="element">
        {!!preText && <div styleName="element__pre">{preText}</div>}
        <div styleName="element__input">
          <Input.Text
            name={name}
            value={this.state.value}
            onChange={this.onChangehandler}
            maxLength={maximumLength}
            onFocus={() => this.props.onFocus(name)}
            disabled={disabled}
          />
        </div>
        {!!postText && <div styleName="element__pre">{postText}</div>}
      </div>
    );
  }

  public shouldComponentUpdate(nextProps: TextComponentProps) {
    return nextProps.formElement.disabled !== this.props.formElement.disabled;
  }

  private onChangehandler(value: string) {
    this.setState({ value });
    this.forceUpdate();

    const { name, type } = this.props.formElement;
    const formElement: FormElement = {
      name,
      type,
      textInfo: {
        value,
      },
    };

    this.props.onChange(formElement);
  }
}
