import { LinkedValue } from "@haywork/modules/form";
import differenceWith from "lodash-es/differenceWith";
import escapeRegExp from "lodash-es/escapeRegExp";
import isEmpty from "lodash-es/isEmpty";
import isString from "lodash-es/isString";
import isEqual from "lodash-es/isEqual";

export class ArrayUtil {
  public static isArrayEqual(array1: any[], array2: any[]) {
    return differenceWith(array1, array2, isEqual).length < 1;
  }

  public static arraysNotEmpty(arrays: any[][]): boolean {
    const notEmpty = arrays.filter((array) => !!array && array.length > 0);
    return !isEmpty(notEmpty);
  }

  public static existsAndNotEmpty(array: any[]): boolean {
    return !!array && !isEmpty(array);
  }

  public static linkedValueArrayToProps(linkedValueArray: LinkedValue[]) {
    const values = {};
    linkedValueArray.forEach((linkedValue) => {
      values[linkedValue.propName] = linkedValue.value;
    });

    return values;
  }

  public static hasMatchInArray(value, values) {
    let hasMatch = false;

    values.map((v) => {
      if (new RegExp(escapeRegExp(value), "gi").test(v)) hasMatch = true;
    });

    return hasMatch;
  }

  public static removeEmptyValues(values: any[] = []): any[] {
    const noEmptyValues = [];
    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      if (value !== null && value !== undefined) {
        noEmptyValues.push(value);
      }
    }
    return noEmptyValues;
  }

  public static createRange(
    min: number | string,
    max: number | string,
    step: number = 1
  ): number[] {
    const start = isString(min) ? parseFloat(min) : min;
    const end = isString(max) ? parseFloat(max) : max;
    const cnt = Math.floor((end - start) / step) + 1;

    if (isNaN(start) || isNaN(end) || isNaN(cnt)) return [];

    return Array(cnt)
      .fill(null)
      .map((_, idx) => start + idx * step);
  }
}
