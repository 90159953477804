import { actions as InvoiceListActions } from "./actions";
import {
  reducer as invoiceListReducer,
  State as InvoiceListState
} from "./reducer";
import { SortOrder, InvoiceOrderByField } from "@haywork/api/kolibri";

export type InvoiceOrder = {
  sortOrder: SortOrder;
  sortColumn: InvoiceOrderByField;
};

export { InvoiceListActions, invoiceListReducer, InvoiceListState };
