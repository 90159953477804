import {
  ConstructionPeriodOption,
  ObjectAssignment,
  PublicationSnapShot,
  PublicationStatus,
} from "@haywork/api/kolibri";
import { Group } from "@haywork/api/mls";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentThunks, Dispatch } from "@haywork/middleware";
import { AssignmentPublishComponent } from "@haywork/modules/assignment";
import { AppState, SingleAssignmentState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { AssignmentPublishError } from "@haywork/util/assignment";
import { push } from "connected-react-router";
import { connect } from "react-redux";

export interface AssignmentPublishContainerProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  publicationCount: number;
  publishState: string;
  constructionPeriods: ConstructionPeriodOption[];
  path: string;
  agencyGroups: Group[];
  navigate: (route: string) => void;
  saveAssignment: () => void;
  publishAssignment: (id: string, date?: Date) => void;
  setFormErrors: (errors: AssignmentPublishError[], id: string) => void;
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const filterActivePublications = (
  publications: PublicationSnapShot[]
): number => {
  if (!publications) return 0;
  return publications.filter(
    (publication) =>
      publication.publicationStatus !== PublicationStatus.Inactive
  ).length;
};

const mapStateToProps = (state: AppState) => {
  const { objectAssignment, publications }: SingleAssignmentState =
    state.editable.currentComponentState;
  const { publishState } = state.assignment.single;
  const { constructionPeriods } = state.main.mastertable.kolibri;

  return {
    objectAssignment,
    publicationCount: filterActivePublications(publications),
    publishState,
    constructionPeriods,
    currentComponentState: state.editable.currentComponentState,
    path: RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
      id: state.editable.currentComponentState.objectAssignment.id,
    }),
    agencyGroups: state.mls.list.agencyGroups || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  navigate: (route: string) => dispatch(push(route)),
  saveAssignment: () => dispatch(AssignmentThunks.saveAssignment()),
  publishAssignment: (id: string, date?: Date) =>
    dispatch(AssignmentThunks.publishObjectAssignment(id, date)),
  setFormErrors: (errors: AssignmentPublishError[], id: string) =>
    dispatch(AssignmentThunks.setPublishFormErrors(errors, id)),
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(AssignmentThunks.updateAssignmentEditable(componentState, path)),
});

export const AssignmentPublishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentPublishComponent);
