import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
} from "@haywork/modules/form";
import get from "lodash-es/get";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import isEqual from "lodash-es/isEqual";

interface ObjectInputComponentProps {
  formControls: FormControls;
}
interface ObjectInputComponentState {}

export class ObjectComponent extends React.PureComponent<
  ObjectInputComponentProps & InputComponentProps,
  ObjectInputComponentState
> {
  private ref: FormReference;
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.formControls = this.mapFormControls(this.props.value);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.mapFormControls = this.mapFormControls.bind(this);
  }

  public render() {
    return (
      <div className="input__object">
        <Form
          name={`${this.props.name}-object`}
          asSubForm
          formControls={this.formControls}
          onChange={this.onChangeHandler}
          form={(ref) => (this.ref = ref)}
        >
          {this.props.children}
        </Form>
      </div>
    );
  }

  // public shouldComponentUpdate(nextProps: ObjectInputComponentProps & InputComponentProps) {
  //   return !!get(nextProps, "value") && !isEqual(nextProps.value, this.props.value);
  // }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ObjectInputComponentProps & InputComponentProps
  ) {
    if (!get(nextProps, "value") || isEqual(nextProps.value, this.props.value))
      return;
    this.ref.update(nextProps.value);
  }

  private onChangeHandler(values: FormReturnValue, valid: boolean) {
    this.props.onChange(values, valid);
  }

  private mapFormControls(values?: { [key: string]: any }): FormControls {
    if (!values) return this.props.formControls;
    const { formControls } = this.props;
    const mappedFormControls = {};

    for (const key in formControls) {
      if (values.hasOwnProperty(key)) {
        mappedFormControls[key] = { ...formControls[key], value: values[key] };
      } else {
        mappedFormControls[key] = { ...formControls[key] };
      }
    }

    return mappedFormControls;
  }
}
