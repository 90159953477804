import { UserManager, User, UserManagerSettings } from "oidc-client";

class OidcServiceSingleton {
  private static instance: OidcServiceSingleton;
  public userCallback: (user: User) => void;
  public OidcUserManager: UserManager;

  static get Instance() {
    return this.instance = this.instance || new OidcServiceSingleton();
  }

  public setConfig(config: UserManagerSettings): UserManager {
    return this.OidcUserManager = new UserManager(config);
  }

  public setUserCallback(callback: (user: User) => void) {
    return this.userCallback = callback;
  }
}

export class OidcService {
  public OidcUserManager = OidcServiceSingleton.Instance.OidcUserManager;
  private instance = OidcServiceSingleton.Instance;

  public setConfig(config: UserManagerSettings) {
    if (!this.OidcUserManager) this.OidcUserManager = this.instance.setConfig(config);
    this.bindEvents();
    return this.OidcUserManager;
  }

  public onUserLoaded(callback: (user: User) => void) {
    this.instance.setUserCallback(callback);
  }

  private bindEvents() {
    this.OidcUserManager.events.addUserLoaded((user: User) => {
      if (!!this.instance.userCallback) this.instance.userCallback(user);
    });
  }
}
