import isString from "lodash-es/isString";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { RealEstatePropertyValue } from "../../../../helpers";

type Props = {
  value: RealEstatePropertyValue;
  depth?: number;
};

const styles = require("./style.scss");

export const PropertyRowComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ value, depth }) => {
    const ownDepth = useMemo(() => depth || 0, [depth]);

    const labelOffset = useMemo(() => {
      return {
        paddingLeft: !ownDepth ? undefined : ownDepth * 10,
      } as React.CSSProperties;
    }, [ownDepth]);

    return isString(value.value) ? (
      <div styleName="property-row">
        <div styleName="property-row__label" style={labelOffset}>
          {value.label}
        </div>
        <div
          styleName="property-row__value"
          dangerouslySetInnerHTML={{ __html: value.value as string }}
        />
      </div>
    ) : (
      <>
        <div styleName="property-row">
          <div
            styleName="property-row__label with-children"
            style={labelOffset}
          >
            {value.label}
          </div>
          <div styleName="property-row__value" />
        </div>
        <div styleName="property-row__values">
          {value.value.map((item, idx) => (
            <PropertyRowComponent value={item} depth={ownDepth + 1} key={idx} />
          ))}
        </div>
      </>
    );
  })
);
