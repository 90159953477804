import { FolderCategory } from "@haywork/api/mail";
import * as React from "react";
import { FC, memo, useMemo, useCallback, useState, useRef } from "react";
import * as CSSModules from "react-css-modules";
import { FolderGroupContainerProps } from "./folder-group.container";
import classNames from "classnames";
import first from "lodash-es/first";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import Folder from "../folder";
import { useDrop } from "react-dnd";
import { DragDropType } from "@haywork/enum/drag-drop";
import { EmailFolder } from "@haywork/stores/email-v2";
import uniq from "lodash-es/uniq";
import { ConfirmComponent } from "@haywork/modules/shared";
import sortBy from "lodash-es/sortBy";
import findIndex from "lodash-es/findIndex";

const styles = require("./style.scss");
const FolderCategoryDropBlacklist = [
  FolderCategory.Drafts,
  FolderCategory.Sent,
];

export type FolderGroupComponentProps = {
  category: FolderCategory;
};
type Props = FolderGroupComponentProps & FolderGroupContainerProps;

export const FolderGroupComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      category,
      accounts,
      currentAccount,
      currentFolder,
      folders,
      setCurrentFolder,
      removeFolder,
    }) => {
      const [forceVisible, setForceVisible] = useState(false);
      const forceVisibleTimeout = useRef<any>(null);
      const [folderDeleteVisible, setFolderDeleteVisible] = useState(false);
      const [folderToDelete, setFolderToDelete] = useState({
        id: "",
        displayName: "",
      });

      const showFolderGroup = useMemo(() => {
        return !!accounts.length && !!folders.length;
      }, [accounts, folders]);

      const matches = useMemo(() => {
        return !showFolderGroup
          ? []
          : sortBy(
              folders.filter((folder) => folder.category === category),
              (folder) =>
                findIndex(
                  accounts,
                  (account) => account.id === folder.accountId
                )
            );
      }, [showFolderGroup, folders, category, accounts]);

      const subFolderIds = useMemo(() => {
        const recursive = (folders: EmailFolder[], folderId: string) => {
          if (!folders.length) return [];

          let subFolders = folders.filter(
            (folder) => folder.parentId === folderId
          );

          if (!subFolders.length) return [];
          subFolders.map((folder) => {
            subFolders = [...subFolders, ...recursive(folders, folder.id)];
          });

          return subFolders;
        };

        const ids = matches.reduce((state, parent) => {
          let subFolders = folders.filter(
            (folder) => folder.parentId === parent.id
          );
          if (!subFolders.length) return state;
          subFolders = [...subFolders, ...recursive(folders, parent.id)];
          subFolders.forEach((folder) => state.push(folder.id));
          return state;
        }, [] as string[]);

        return uniq(ids);
      }, [folders, matches]);

      const blacklisted = useMemo(
        () => FolderCategoryDropBlacklist.includes(category),
        [category]
      );

      const [_, drop] = useDrop({
        accept: [DragDropType.Email],
        canDrop: () => !blacklisted && matches.length === 1,
        collect: (monitor) => {
          if (monitor.isOver() && !blacklisted && matches.length > 1) {
            forceVisibleTimeout.current = setTimeout(() => {
              setForceVisible(true);
            }, 500);
          } else {
            if (!!forceVisibleTimeout.current) {
              clearTimeout(forceVisibleTimeout.current);
              forceVisibleTimeout.current = null;
            }
            setForceVisible(false);
          }
        },
      });

      const visible = useMemo(() => {
        return (
          !!matches.find((match) => match.id === currentFolder) ||
          subFolderIds.includes(currentFolder)
        );
      }, [matches, currentFolder, subFolderIds]);

      const groupCount = useMemo(() => {
        return matches.reduce((state, match) => {
          if (match.category !== FolderCategory.Inbox) return;
          return state + match.unreadCount;
        }, 0);
      }, [matches]);

      const icon = useMemo(() => {
        if (!showFolderGroup) return null;
        switch (category) {
          case FolderCategory.Inbox: {
            return (
              <Icon
                name="inbox"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Sent: {
            return (
              <Icon
                name="paper-plane"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Trash: {
            return (
              <Icon
                name="trash-alt"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Archive: {
            return (
              <Icon
                name="archive"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Spam: {
            return (
              <Icon
                name="ban"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Important: {
            return (
              <Icon
                name="exclamation-circle"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.Drafts: {
            return (
              <Icon
                name="edit"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          case FolderCategory.SynchronizingMessage: {
            return (
              <Icon
                name="sync"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
          default: {
            return (
              <Icon
                name="folder"
                size={16}
                containIn={20}
                color={Colors.Gray}
                regular
              />
            );
          }
        }
      }, [showFolderGroup, category]);

      const onGroupClick = useCallback(() => {
        if (!matches.length) return;
        let folder = matches.find(
          (match) => match.accountId === currentAccount
        );
        folder = folder || first(matches);
        setCurrentFolder(folder);
      }, [matches, currentAccount, setCurrentFolder]);

      const onDeleteCallback = useCallback(
        (id: string, displayName: string) => {
          setFolderToDelete({ id, displayName });
          setFolderDeleteVisible(true);
        },
        [setFolderDeleteVisible, setFolderToDelete]
      );

      const folderDeleteCloseCallback = useCallback(() => {
        setFolderToDelete({ id: "", displayName: "" });
        setFolderDeleteVisible(false);
      }, [setFolderDeleteVisible, setFolderToDelete]);

      const folderDeleteConfirmCallback = useCallback(() => {
        removeFolder(folderToDelete.id);
        folderDeleteCloseCallback();
      }, [removeFolder, folderToDelete, folderDeleteCloseCallback]);

      if (!showFolderGroup || !matches.length) return null;

      return (
        <div styleName="group-wrapper" ref={drop}>
          <div
            styleName={classNames("group", {
              expanded: visible || forceVisible,
            })}
            onClick={onGroupClick}
          >
            <div styleName="icon">{icon}</div>
            <div styleName="label">
              <I18n prefix="folderCategory" value={category.toString()} />
            </div>
            {!visible && !!groupCount && (
              <div styleName="count">{groupCount}</div>
            )}
          </div>
          <div
            styleName={classNames("matches", {
              expanded: (!!visible || !!forceVisible) && !!matches.length,
            })}
          >
            {matches.map((match) => (
              <Folder
                folder={match}
                groupedFolder
                key={match.id}
                parentExpanded={true}
                onDelete={onDeleteCallback}
              />
            ))}
          </div>

          <ConfirmComponent
            visible={folderDeleteVisible}
            titleResourceKey="email.confirmFolderRemove.title"
            bodyResourceKey="email.confirmFolderRemove.body"
            bodyValues={{ name: folderToDelete.displayName }}
            onClose={folderDeleteCloseCallback}
            onConfirm={folderDeleteConfirmCallback}
          />
        </div>
      );
    }
  )
);
