import Button from "@haywork/components/ui/button";
import { Hint } from "@haywork/modules/shared";
import * as React from "react";
import { FC, memo } from "react";
import { ToggleConsentContainerProps as Props } from "./toggle-consent.container";

export const ToggleConsentComponent: FC<Props> = memo(
  ({
    hasConsent,
    toggleConsentModal,
    toggleConsentRetractConfirm,
    isFirstParty
  }) => {
    const resourceKey = hasConsent
      ? "appXchangeDeactivatePartner"
      : "appXchangeActivatePartner";

    const onClick = hasConsent
      ? () => toggleConsentRetractConfirm(true)
      : () => toggleConsentModal(true);

    return (
      <Hint
        shouldRender={isFirstParty}
        message="appClientFirstPartyInfo"
        forceInline={true}
      >
        <Button
          label={resourceKey}
          disabled={isFirstParty}
          onClick={onClick}
          category="primary"
        />
      </Hint>
    );
  }
);
