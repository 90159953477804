import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentSnapShot } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  snapshot: AssignmentSnapShot;
};

export const AcquisitionItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ snapshot }) => {
    const {
      publicReference,
      linkedClients,
      isActive,
      forRent,
      forSale
    } = snapshot;

    const displayName = useMemo(() => {
      switch (true) {
        case !!publicReference: {
          return <div styleName="pill pubref">{publicReference}</div>;
        }
        case !!forRent && !!forSale:
        default: {
          return (
            <div styleName="pill pubref">
              <I18n value="acquisition.type.forSaleAndRent" />
            </div>
          );
        }
        case !!forRent: {
          return (
            <div styleName="pill pubref">
              <I18n value="acquisition.type.forRent" />
            </div>
          );
        }
        case !!forSale: {
          return (
            <div styleName="pill pubref">
              <I18n value="acquisition.type.forSale" />
            </div>
          );
        }
      }
    }, [publicReference, forRent, forSale]);

    return (
      <div styleName="item">
        <div styleName="meta">
          {displayName}
          {(linkedClients || []).map((client) => (
            <div styleName="pill" key={client.id}>
              {client.displayName}
            </div>
          ))}
        </div>
        {!isActive && (
          <div styleName="pill">
            <I18n value="archived" />
          </div>
        )}
      </div>
    );
  })
);
