import { Account, FolderCategory } from "@haywork/api/mail";
import { REQUEST, SETTINGSROUTES } from "@haywork/constants";
import { EmailAccountsContainerProps } from "@haywork/modules/email";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Alert } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { ExtendedEmailFolder } from "@haywork/util/email";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AccountComponent } from "./account.component";
import { EmptyState } from "./empty-state.component";

const styles = require("./accounts.component.scss");

export interface EmailAccountsComponentProps {}
interface State {
  folderNotEmptyAlertVisible: boolean;
}
type Props = EmailAccountsComponentProps & EmailAccountsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailAccountsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onLoadFolderMessagesHandler = this.onLoadFolderMessagesHandler.bind(
      this
    );
    this.checkIfFolderEligableForDelete = this.checkIfFolderEligableForDelete.bind(
      this
    );

    this.state = {
      folderNotEmptyAlertVisible: false,
    };
  }

  public render() {
    if (
      this.props.accountsStatus === REQUEST.SUCCESS &&
      this.props.accounts.length === 0
    ) {
      return (
        <EmptyState
          providers={this.props.providers}
          onClick={() => this.props.navigate(SETTINGSROUTES.EMAIL.URI)}
        />
      );
    }

    return (
      <div>
        {this.props.accounts.map((account, idx) => (
          <ErrorBoundary key={account.account.id}>
            <AccountComponent
              isExpanded={
                !!this.props.currentAccount &&
                account.account.id === this.props.currentAccount.id
              }
              account={account.account}
              folders={account.folders}
              currentFolder={this.props.currentFolder}
              rootCanLoadmore={this.props.rootCanLoadmore}
              onLoadSubFolders={this.props.searchFolders}
              onLoadFolderMessages={this.onLoadFolderMessagesHandler}
              onMoveMessageToFolder={this.props.moveToFolder}
              onMoveFolderToFolder={this.props.moveFolder}
              onSaveFolder={this.props.saveFolder}
              checkIfFolderEligableForDelete={
                this.checkIfFolderEligableForDelete
              }
              onRemoveFolder={this.props.removeFolder}
            />
          </ErrorBoundary>
        ))}

        {this.props.accountsStatus === REQUEST.PENDING && <Ui.Loaders.List />}

        <Alert
          visible={this.state.folderNotEmptyAlertVisible}
          titleResourceKey="sadly"
          bodyResourceKey="emailFolderNotEmptyAlertBody"
          btnResourceKey="closeAlert"
          onClose={() => this.setState({ folderNotEmptyAlertVisible: false })}
        />
      </div>
    );
  }

  private onLoadFolderMessagesHandler(
    account: Account,
    folder: ExtendedEmailFolder,
    category: FolderCategory
  ) {
    if (category === FolderCategory.Drafts) {
      this.props.getDraftsForAccountAndFolder(account, folder);
    } else {
      this.props.getMessagesForAccountAndFolder(account, folder);
    }
  }

  private checkIfFolderEligableForDelete(folderId: string, accountId: string) {
    return this.props
      .checkIfFolderEligableForDelete(folderId, accountId)
      .then((canDelete) => {
        if (!canDelete) {
          this.setState({
            folderNotEmptyAlertVisible: true,
          });
        }
        return canDelete;
      });
  }
}
