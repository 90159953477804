import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentEditMarketingPhotosContainer,
  AssignmentEditMarketingTextContainer,
  AssignmentEditMarketingWebsiteContainer,
  AssignmentEditMarketingPartnersContainer,
  AssignmentEditMarketingFloorplansContainer,
  AssignmentEditMarketingOpenHouseContainer,
  AssignmentEditMarketingVideoContainer,
  AssignmentEditMarketingBudgetContainer,
  AssignmentEditMarketingVirtualVideoContainer,
  AssignmentEditMarketingBrochuresContainer,
} from "@haywork/modules/assignment";
import { AssignmentEditMarketingMlsContainer } from "../../containers/marketing-mls.container";

const route = RouteUtil.mapStaticRouteValues;
export const AssignmentEditMarketingRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ASSIGNMENTROUTES.EDIT_MARKETING.URI}
        render={() => (
          <Redirect
            to={route(ASSIGNMENTROUTES.EDIT_MARKETING_PHOTOS.URI, { id })}
          />
        )}
      />
      <Route
        exact
        path={ASSIGNMENTROUTES.EDIT_MARKETING_PHOTOS.URI}
        component={AssignmentEditMarketingPhotosContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_TEXT.URI}
        component={AssignmentEditMarketingTextContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_PARTNERS.URI}
        component={AssignmentEditMarketingPartnersContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_MLS.URI}
        component={AssignmentEditMarketingMlsContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_WEBSITE.URI}
        component={AssignmentEditMarketingWebsiteContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_MAPS.URI}
        component={AssignmentEditMarketingFloorplansContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_VIDEOS.URI}
        component={AssignmentEditMarketingVideoContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_VIRTUAL_VIDEOS.URI}
        component={AssignmentEditMarketingVirtualVideoContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_PRESENTATION.URI}
        component={AssignmentEditMarketingBrochuresContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_OPENHOUSE.URI}
        component={AssignmentEditMarketingOpenHouseContainer}
      />
      <Route
        path={ASSIGNMENTROUTES.EDIT_MARKETING_BUDGET.URI}
        component={AssignmentEditMarketingBudgetContainer}
      />
    </Switch>
  );
};
