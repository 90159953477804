import { DYNAMICDOCUMENTROUTES, REQUEST } from "@haywork/constants";
import { EditableCalleeType } from "@haywork/enum";
import {
  DetailNavbarComponent,
  DynamicDocumentsDetailContainerProps,
  DynamicDocumentsSaveModalComponent,
} from "@haywork/modules/dynamic-documents";
import { PageLoader, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DynamicDocumentsDetailRouting } from "./detail.routing";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface DynamicDocumentsDetailComponentProps {}
interface State {}
type Props = DynamicDocumentsDetailComponentProps &
  DynamicDocumentsDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsDetailComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onSaveClickHandler = this.onSaveClickHandler.bind(this);
    this.onSaveAndContinue = this.onSaveAndContinue.bind(this);
  }

  public componentDidMount() {
    this.props.setTabTitle(this.props.template.name);
  }

  public render() {
    const { session, template } = this.props;
    if (!session || !template) {
      return null;
    }
    const { name } = template;
    const fullscreenStyle = classNames(
      "fa",
      "fa-fw",
      !this.props.fullscreen ? "fa-expand" : "fa-compress"
    );
    const showHeaderActions = !this.props.location.pathname.match(/download/);

    return (
      <div styleName="detail">
        <div styleName="detail__header">
          <h1>{name}</h1>
          {!!showHeaderActions && (
            <div styleName="header__actions">
              <div
                className="btn btn-default-alt icon"
                onClick={this.props.toggleFullscreen}
              >
                <i className={fullscreenStyle} />
                {!this.props.fullscreen && (
                  <span styleName="fullScreenText">
                    <ResourceText resourceKey="fullScreen" />
                  </span>
                )}
              </div>

              <button
                type="button"
                className="btn btn-primary icon-left"
                onClick={this.onSaveClickHandler}
              >
                <i className="fal fa-save" />
                <ResourceText resourceKey="saveAndClose" />
              </button>
            </div>
          )}
        </div>
        <div styleName="detail__body">
          {this.props.documentStatus === REQUEST.PENDING && (
            <PageLoader loading fullscreen />
          )}
          <DynamicDocumentsDetailRouting id={session.id} />
        </div>
        <div styleName="detail__footer">
          <DetailNavbarComponent
            session={session}
            template={template}
            location={this.props.location}
            saveDynamicDocument={this.onSaveAndContinue}
            onCloseDocument={this.props.closeDocument}
          />
        </div>
        <DynamicDocumentsSaveModalComponent
          visible={this.props.showSaveModal}
          documentSession={this.props.session}
          documentTemplate={this.props.template}
          onClose={() => this.props.toggleSaveModal(false)}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: DynamicDocumentsDetailComponentProps &
      DynamicDocumentsDetailContainerProps
  ) {
    if (!nextProps) return;
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      if (!this.props.canSave) {
        const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, {
          id: this.props.session.id,
        });
        this.props.stateDisablePreppedForSave(path);
        return this.props.toggleSaveModal(true);
      }
      this.props.saveAndCloseDynamicDocument();
    }

    if (!!nextProps.callee && nextProps.callee.state !== REQUEST.PENDING) {
      switch (nextProps.callee.type) {
        case EditableCalleeType.DynamicDocumentCadastre:
          this.props.refreshObjectAssignment();
          return;
        default:
          return;
      }
    }
  }

  public componentWillUnmount() {
    this.props.clearHtmlPreview();
  }

  private onSaveAndContinue(path: string) {
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.props.saveInitialDynamicDocument(path);
  }

  private onSaveClickHandler() {
    if (!this.props.canSave) return this.props.toggleSaveModal(true);
    this.props.saveAndCloseDynamicDocument();
  }
}
