import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { EXTERNALROUTES } from "@haywork/constants";
import { ErrorContainerProps } from "@haywork/modules/error/containers";
import { ResourceText } from "@haywork/modules/shared";
import { ErrorPortal } from "@haywork/portals";
import { AnalyticsService, RequestHelper } from "@haywork/services";
import classNames from "classnames";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import isEqual from "lodash-es/isEqual";
import isString from "lodash-es/isString";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./error.component.scss");
const analytics = new AnalyticsService();

export type ErrorComponentProps = {};
type State = {
  showExtraInfoTrigger: boolean;
  showExtraInfo: boolean;
  toggleExtraInfoText: string;
  visible: boolean;
  brokenBusinessRules: string[];
};
type Props = ErrorComponentProps & ErrorContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ErrorComponent extends React.Component<Props, State> {
  private closeTimer: any;

  constructor(props) {
    super(props);

    this.state = {
      showExtraInfoTrigger: false,
      showExtraInfo: false,
      toggleExtraInfoText: "showMore",
      visible: false,
      brokenBusinessRules: [],
    };

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onShowMoreClickHandler = this.onShowMoreClickHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !!nextProps.response &&
      !isEqual(this.props.response, nextProps.response)
    ) {
      const brokenBusinessRules = [];

      if (
        RequestHelper.isKolibriErrorResponse(nextProps.response) &&
        get(nextProps.response, "brokenBusinessRules") &&
        Object.keys(nextProps.response.brokenBusinessRules).length > 0
      ) {
        for (const key in nextProps.response.brokenBusinessRules) {
          const rule = nextProps.response.brokenBusinessRules[key];
          if (
            typeof rule === "object" &&
            "brokenRules" in rule &&
            "fieldName" in rule &&
            isArray(rule["brokenRules"])
          ) {
            const rules: any[] = rule["brokenRules"];
            rules.map((mappedRule) =>
              brokenBusinessRules.push(
                `${rule["fieldName"]}: ${
                  isString(mappedRule) ? mappedRule : JSON.stringify(mappedRule)
                }`
              )
            );
          } else {
            brokenBusinessRules.push(
              isString(rule) ? rule : JSON.stringify(rule)
            );
          }
        }
        analytics.event(
          "Show error message",
          "Broken business rules error",
          isString(nextProps.response)
            ? nextProps.response
            : JSON.stringify(nextProps.response)
        );
      } else {
        analytics.event(
          "Show error message",
          "Generic error",
          isString(nextProps.response)
            ? nextProps.response
            : JSON.stringify(nextProps.response)
        );
      }

      if (!nextProps.persistent) {
        this.closeTimer = setTimeout(this.onCloseHandler, 20000);
      }

      this.setState({
        visible: true,
        brokenBusinessRules,
        showExtraInfoTrigger: brokenBusinessRules.length > 3,
      });
    }
  }

  public render() {
    if (!this.props.response) return null;

    const errorStyle = classNames("error", { visible: this.state.visible });

    return (
      <ErrorPortal>
        <div styleName={errorStyle} data-cy="CY-errorModal">
          <div styleName="error__modal">
            <div styleName="error__header">
              <div styleName="icon">
                <Icon
                  name="exclamation-triangle"
                  size={12}
                  style={{ marginRight: 10 }}
                  fixedWidth={true}
                />
              </div>
              <ResourceText resourceKey="anErrorOccurred" />
              <div styleName="close" onClick={this.onCloseHandler}>
                <i className="fal fa-times" />
              </div>
            </div>
            <div styleName="error__body">
              <div styleName="error__details">
                {this.renderBody()}
                {this.renderBrokenBusinessRules()}

                {this.state.showExtraInfoTrigger && (
                  <span
                    className="as-link"
                    onClick={this.onShowMoreClickHandler}
                  >
                    <ResourceText
                      resourceKey={this.state.toggleExtraInfoText}
                    />
                  </span>
                )}
              </div>
            </div>
            <div styleName="error__footer">
              <I18n value="error.morehelp" asHtml />
              <I18n
                value="error.morehelp2"
                values={{
                  link: EXTERNALROUTES.SUPPORT_LOOM.URI,
                }}
                asHtml
              />
            </div>
          </div>
        </div>
      </ErrorPortal>
    );
  }

  private renderBody(): React.ReactElement<HTMLParagraphElement> {
    const { message } =
      get(this.props.response, "error") || this.props.response;
    return <p data-cy="CY-errorMessage">{message}</p>;
  }

  private renderBrokenBusinessRules(): React.ReactElement<HTMLUListElement> {
    const { brokenBusinessRules } = this.state;
    if (!brokenBusinessRules || brokenBusinessRules.length === 0) return null;

    const rules = this.state.showExtraInfo
      ? brokenBusinessRules
      : brokenBusinessRules.slice(0, 3);

    return (
      <ul>
        {rules.map((rule, idx) => (
          <li key={idx}>{rule}</li>
        ))}
      </ul>
    );
  }

  private onShowMoreClickHandler() {
    if (!!this.closeTimer) {
      clearTimeout(this.closeTimer);
    }

    this.setState({
      showExtraInfo: !this.state.showExtraInfo,
      toggleExtraInfoText: !this.state.showExtraInfo ? "showLess" : "showMore",
    });
  }

  private onCloseHandler() {
    if (!!this.closeTimer) {
      clearTimeout(this.closeTimer);
    }

    this.setState(
      {
        visible: false,
      },
      () => {
        setTimeout(this.props.removeError, 400);
        this.setState({
          showExtraInfoTrigger: false,
          showExtraInfo: false,
          toggleExtraInfoText: "showMore",
          brokenBusinessRules: [],
        });
      }
    );
  }
}
