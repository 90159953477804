import {
  AcquisitionObjectAssignment,
  DrainageOption,
  ObjectAssignment,
  PoultryFarmingSubtypeOption,
  PoultryHousingTypeOption,
  SoilTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../shared/form.component.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  soilTypeOptions: SoilTypeOption[];
  drainageOptions: DrainageOption[];
  poultryFarmingSubtypes: PoultryFarmingSubtypeOption[];
  poultryHousingTypeOptions: PoultryHousingTypeOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalPoultryCompanyComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.formControls = {
      companyName: {
        value: get(this.props.objectAssignment, "companyName", ""),
      },
      poultryFarmingSubtype: {
        value: get(this.props.objectAssignment, "poultryFarmingSubtype", ""),
      },
      hasHygieneBarrier: {
        value: get(this.props.objectAssignment, "hasHygieneBarrier", false),
      },
      numberOfAnimalPlaces: {
        value: get(this.props.objectAssignment, "numberOfAnimalPlaces", ""),
      },
      isBiologicalCompany: {
        value: get(this.props.objectAssignment, "isBiologicalCompany", false),
      },
      isSKALCertified: {
        value: get(this.props.objectAssignment, "isSKALCertified", false),
      },
      hasGreenLabel: {
        value: get(this.props.objectAssignment, "hasGreenLabel", false),
      },
      soilType: { value: get(this.props.objectAssignment, "soilType", "") },
      drainage: { value: get(this.props.objectAssignment, "drainage", "") },
      poultryHousingTypes: {
        value: get(this.props.objectAssignment, "poultryHousingTypes", []),
      },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.formRef.update(
        {
          companyName: get(this.props.objectAssignment, "companyName", ""),
          poultryFarmingSubtype: get(
            this.props.objectAssignment,
            "poultryFarmingSubtype",
            ""
          ),
          hasHygieneBarrier: get(
            this.props.objectAssignment,
            "hasHygieneBarrier",
            false
          ),
          numberOfAnimalPlaces: get(
            this.props.objectAssignment,
            "numberOfAnimalPlaces",
            ""
          ),
          isBiologicalCompany: get(
            this.props.objectAssignment,
            "isBiologicalCompany",
            false
          ),
          isSKALCertified: get(
            this.props.objectAssignment,
            "isSKALCertified",
            false
          ),
          hasGreenLabel: get(
            this.props.objectAssignment,
            "hasGreenLabel",
            false
          ),
          soilType: get(this.props.objectAssignment, "soilType", ""),
          drainage: get(this.props.objectAssignment, "drainage", ""),
          poultryHousingTypes: get(
            this.props.objectAssignment,
            "poultryHousingTypes",
            []
          ),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="business-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <label htmlFor="companyName">
            <ResourceText resourceKey="companyName" />
          </label>
          <Input.Text name="companyName" placeholder="companyName" />
        </div>

        <div className="form__row">
          <div className="form__group stretch">
            <div className="column">
              <label htmlFor="poultryFarmingSubtype">
                <ResourceText resourceKey="poultryFarmingSubtype" />
              </label>
              <Input.NewSelect
                name="poultryFarmingSubtype"
                placeholder="makeAChoice"
                values={this.props.poultryFarmingSubtypes}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="numberOfAnimalPlaces">
                <ResourceText resourceKey="numberOfAnimalPlaces" />
              </label>
              <Input.Number
                name="numberOfAnimalPlaces"
                placeholder="numberOfAnimalPlaces"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <Input.Switch
                name="hasHygieneBarrier"
                on={true}
                off={false}
                label="hasHygieneBarrier"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="isBiologicalCompany"
                on={true}
                off={false}
                label="isBiologicalCompany"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="isSKALCertified"
                on={true}
                off={false}
                label="isSKALCertified"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="hasGreenLabel"
                on={true}
                off={false}
                label="hasGreenLabel"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column column__minwidth">
              <label htmlFor="soilType">
                <ResourceText resourceKey="soilType" />
              </label>
              <Input.NewSelect
                name="soilType"
                placeholder="makeAChoice"
                values={this.props.soilTypeOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column column__minwidth">
              <label htmlFor="drainage">
                <ResourceText resourceKey="drainage" />
              </label>
              <Input.NewSelect
                name="drainage"
                placeholder="makeAChoice"
                values={this.props.drainageOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="poultryHousingTypes">
            <ResourceText resourceKey="poultryHousingTypes" />
          </label>
          <Input.Multi
            name="poultryHousingTypes"
            values={this.props.poultryHousingTypeOptions}
          />
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const updatedObjectAssignment = {
      ...objectAssignment,
      ...values,
    };

    this.props.onChange(updatedObjectAssignment);
  }
}
