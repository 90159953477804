import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { EXTERNALROUTES, REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { SettingsMatchmailContainerProps } from "@haywork/modules/settings/modules/matchmail";
import { ButtonLoader } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

const styles = require("./matchmail.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface SettingsMatchmailComponentProps {}
interface State {}
type Props = SettingsMatchmailComponentProps & SettingsMatchmailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsMatchmailComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.toggleCollegialListings = this.toggleCollegialListings.bind(this);

    this.formControls = {
      hasMatchMail: {
        value: value(
          this.props.settings,
          "matchMailSettings.hasMatchMail",
          false
        )
      },
      websiteProvider: {
        value: value(this.props.settings, "matchMailSettings.websiteProvider")
      },
      collegialListingsLink: {
        value: value(
          this.props.settings,
          "matchMailSettings.collegialListingsLink"
        )
      }
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SettingsMatchmailComponentProps & SettingsMatchmailContainerProps
  ) {
    if (!nextProps) return;

    if (
      nextProps.settings.matchMailSettings.collegialListingsLink !==
      this.props.settings.matchMailSettings.collegialListingsLink
    ) {
      this.formRef.update({
        collegialListingsLink:
          nextProps.settings.matchMailSettings.collegialListingsLink
      });
    }
  }

  public render() {
    const {
      collegialListingsEnabled,
      collegialListingNeedsCompanyAuthorisationToken
    } = this.props.settings.matchMailSettings;
    const collegialListingButtonText = !!collegialListingsEnabled
      ? "deActivateCollegialListings"
      : "activateCollegialListings";
    const loading = this.props.settingsStatus === REQUEST.PENDING;

    return (
      <div styleName="matchmail">
        <PageHeader
          title="matchmail"
          subTitle="settingsMenuUsage"
          actions={
            <Button
              label="save"
              category="success"
              onClick={this.onSubmitClickHandler}
              disabled={loading}
            />
          }
        />

        <div styleName="matchmail__body">
          <div styleName="inner">
            <Form
              name="matchmail"
              formControls={this.formControls}
              onSubmit={this.onSubmitHandler}
              form={(form) => (this.formRef = form)}
            >
              <div className="form__row">
                <label htmlFor="hasMatchMail">
                  <I18n value="hasMatchMail" />
                </label>
                <Input.Switch name="hasMatchMail" on={true} off={false} />
              </div>

              <div className="form__row">
                <label htmlFor="websiteProvider">
                  <I18n value="websiteProvider" />
                </label>
                <Input.RadioGroup
                  name="websiteProvider"
                  asButtonList
                  canUnselect
                >
                  {this.props.websiteProviderOptions.map((option, idx) => (
                    <Input.Radio
                      value={option.value}
                      label={option.displayName}
                      key={idx}
                    />
                  ))}
                </Input.RadioGroup>
              </div>

              <div styleName="matchmail-info">
                <div styleName="icon">
                  <i className="fal fa-lightbulb" />
                </div>
                <div styleName="body">
                  <I18n value="matchmailInfo" />
                  <div styleName="footer">
                    <button
                      type="button"
                      className="btn"
                      onClick={() =>
                        this.onInfoClickHandler(
                          EXTERNALROUTES.MATCHMAIL_INFO.URI
                        )
                      }
                    >
                      <I18n value="moreAboutMatchmail" />
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={() =>
                        this.onInfoClickHandler(
                          EXTERNALROUTES.HOUSENET_INFO.URI
                        )
                      }
                    >
                      <I18n value="moreAboutHousenet" />
                    </button>
                  </div>
                </div>
              </div>

              {!!collegialListingNeedsCompanyAuthorisationToken && (
                <div styleName="collegial-listings">
                  <div styleName="trigger">
                    <div styleName="column">
                      <label htmlFor="collegialListings">
                        <I18n value="collegialListings" />
                      </label>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.toggleCollegialListings}
                        disabled={
                          this.props.collegialListingsStatus === REQUEST.PENDING
                        }
                      >
                        <ButtonLoader
                          loading={
                            this.props.collegialListingsStatus ===
                            REQUEST.PENDING
                          }
                          resourceKey={collegialListingButtonText}
                        />
                      </button>
                    </div>

                    {this.renderCollegialListingDateUntill()}
                    {this.renderRefreshCollegialListing()}
                  </div>

                  <div className="form__row">
                    <label
                      styleName="collegialListingsLinkLabel"
                      htmlFor="collegialListingsLink"
                    >
                      <I18n value="collegialListingsLink" />
                    </label>
                    <Input.Text name="collegialListingsLink" />
                  </div>
                </div>
              )}
            </Form>
          </div>

          {loading && <Ui.Loaders.Fullscreen mask />}
        </div>
      </div>
    );
  }

  private renderRefreshCollegialListing() {
    const { collegialListingsEnabled } = this.props.settings.matchMailSettings;
    if (!collegialListingsEnabled) return null;

    return (
      <div styleName="column">
        <button
          className="btn btn-success"
          type="button"
          onClick={this.props.requestCollegialListingAuthorization}
          disabled={this.props.collegialListingsStatus === REQUEST.PENDING}
        >
          <ButtonLoader
            loading={this.props.collegialListingsStatus === REQUEST.PENDING}
            resourceKey="refreshCollegialListings"
          />
        </button>
      </div>
    );
  }

  private renderCollegialListingDateUntill() {
    const {
      collegialListingsEnabled,
      collegialListingsAuthorisedUntil
    } = this.props.settings.matchMailSettings;

    switch (true) {
      case !collegialListingsEnabled:
        return null;
      case !collegialListingsAuthorisedUntil:
        return (
          <div styleName="column date">
            <I18n value="collegialListingsExpired" />
          </div>
        );
      default:
        const now = moment();
        const date = moment(collegialListingsAuthorisedUntil);
        const diff = date.diff(now, "days");

        switch (true) {
          case diff <= 0:
            return (
              <div styleName="column date">
                <I18n value="collegialListingsExpired" />
              </div>
            );
          default:
            return (
              <div styleName="column date">
                <I18n value="collegialListingsAuthorisedUntil" />
                &nbsp;
                <FormattedDate
                  value={collegialListingsAuthorisedUntil}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              </div>
            );
        }
    }
  }

  private toggleCollegialListings() {
    const { collegialListingsEnabled } = this.props.settings.matchMailSettings;
    if (!!collegialListingsEnabled) {
      this.props.deActivateCollegialListings();
    } else {
      this.props.requestCollegialListingAuthorization();
    }
  }

  private onSubmitClickHandler() {
    if (!!this.formRef) {
      this.formRef.submit();
    }
  }

  private onSubmitHandler(values: FormReturnValue) {
    const settings = {
      ...this.props.settings,
      matchMailSettings: {
        ...this.props.settings.matchMailSettings,
        ...values
      }
    };

    this.props.saveCompanySettings(settings);
  }

  private onInfoClickHandler(path: string) {
    window.open(path, "_blank");
  }
}
