import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as moment from "moment";

import { EmailBucket } from "@haywork/enum";
import { ExtendedEmailFolder, ExtendedEmailDraft } from "@haywork/util/email";
import { InfiniteScroll, ResourceText } from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

import { DraftItem } from "./draft.component";
import { Ui } from "@haywork/modules/ui";
import { REQUEST } from "@haywork/constants";
import { FormControlUtil } from "@haywork/util";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./list.component.scss");

interface Props {
  currentDraft: ExtendedEmailDraft;
  drafts: ExtendedEmailDraft[];
  currentFolder: ExtendedEmailFolder;
  onScrollEnd: () => void;
  onDraftClick: (draft: ExtendedEmailDraft) => void;
}
interface State {
  groups: SortedEmailDrafts[];
}
interface SortedEmailDrafts {
  bucket: EmailBucket;
  drafts: ExtendedEmailDraft[];
}

@CSSModules(styles, { allowMultiple: true })
export class DraftsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      groups: []
    };

    this.mapDraftsToEmailBucket = this.mapDraftsToEmailBucket.bind(this);
  }

  public componentDidMount() {
    this.mapDraftsToEmailBucket(this.props.drafts);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    this.mapDraftsToEmailBucket(nextProps.drafts);
  }

  public render() {
    const draftsStatus = value(
      this.props.currentFolder,
      "canLoadMoreMessagesStatus"
    );
    const currentMessageId = !!this.props.currentDraft
      ? this.props.currentDraft.id || null
      : null;

    return (
      <InfiniteScroll scrollEnd={this.props.onScrollEnd}>
        {this.state.groups.map((group, idx) => (
          <div styleName="list__group" key={idx}>
            <div styleName="group-title">
              <ResourceText
                resourceKey={`emailBucket.${group.bucket.toString()}`}
              />
            </div>
            {group.drafts.map((draft) => (
              <ErrorBoundary key={draft.id}>
                <DraftItem
                  draft={draft}
                  currentMessageId={currentMessageId}
                  bucket={group.bucket}
                  onClick={this.props.onDraftClick}
                />
              </ErrorBoundary>
            ))}
          </div>
        ))}

        {this.state.groups.length === 0 && draftsStatus === REQUEST.SUCCESS && (
          <div styleName="empty-state">
            <ResourceText resourceKey="emptyStateEmailMessageList" />
          </div>
        )}

        {draftsStatus === REQUEST.PENDING &&
          value(this.props.currentFolder, "canLoadMoreMessages", true) && (
            <Ui.Loaders.List />
          )}
      </InfiniteScroll>
    );
  }

  private mapDraftsToEmailBucket(messages: ExtendedEmailDraft[]) {
    if (!messages) {
      return;
    }

    const now = moment().startOf("day");
    const weekNumber = moment().week();
    const groups: SortedEmailDrafts[] = messages.reduce<SortedEmailDrafts[]>(
      (state, draft) => {
        const draftDate = moment(draft.date).startOf("day");
        const isSameDay = now.isSame(draftDate, "day");
        const diffInDays = Math.ceil(now.diff(draftDate, "days", true));
        const messageWeekNumber = moment(draftDate).week();
        let groupBucket;

        switch (true) {
          case isSameDay:
            groupBucket = EmailBucket.Today;
            break;
          case !isSameDay && diffInDays === 1:
            groupBucket = EmailBucket.Yesterday;
            break;
          case messageWeekNumber === weekNumber && diffInDays > 1:
            groupBucket = EmailBucket.ThisWeek;
            break;
          case weekNumber - messageWeekNumber === 1:
            groupBucket = EmailBucket.LastWeek;
            break;
          default:
            groupBucket = EmailBucket.Older;
            break;
        }

        const bucket = state.find((group) => group.bucket === groupBucket);
        if (!bucket) {
          state.push({
            bucket: groupBucket,
            drafts: [draft]
          });
        } else {
          bucket.drafts.push(draft);
        }

        return state;
      },
      []
    );

    this.setState({ groups });
  }
}
