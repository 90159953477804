import { WidgetEntityType } from "@haywork/api/authorization";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { RealEstateAgencyDetailDossierContainerProps } from "@haywork/modules/real-estate-agency/containers/detail-dossier.container";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FolderTreeEntityType } from "@haywork/middleware";

const styles = require("./detail-dossier.component.scss");

export interface RealEstateAgencyDetailDossierComponentProps {}
interface State {}
type Props = RealEstateAgencyDetailDossierComponentProps &
  RealEstateAgencyDetailDossierContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class RealEstateAgencyDetailDossierComponent extends React.Component<
  Props,
  State
> {
  public render() {
    return (
      <ErrorBoundary>
        <Dossier
          parentId={this.props.realEstateAgencyId}
          id={this.props.linkedFolderTreeId}
          subject={this.props.name}
          entityType={FolderTreeEntityType.RealEstateAgency}
          addNotes={false}
        />
      </ErrorBoundary>
    );
  }
}
