import {
  AcquisitionObjectAssignment,
  AssignmentPhase
} from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState } from "react";

export enum AcquisitionObjectAssignmentAction {
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAppointment = "CreateAppointment",
  CreateInvoice = "CreateInvoice",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Delete = "Delete",
  OpenParentAssignment = "OpenParentAssignment",
  AddBid = "AddBid"
}

type Props = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
  canSendEmail: boolean;
  onClick: (action: AcquisitionObjectAssignmentAction) => void;
};

export const AcquisitionObjectAssignmentActionsComponent: FC<Props> = memo(
  ({ acquisitionObjectAssignment, canSendEmail, onClick }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);
    const {
      isActive,
      linkedAcquisitionAssignment,
      assignmentPhase
    } = acquisitionObjectAssignment;

    const showArchive = !!isActive;
    const showUnArchive = !isActive;
    const showCreateEmail = canSendEmail;
    const showDelete = assignmentPhase === AssignmentPhase.Concept;
    const showOpenParentAssignment =
      !!linkedAcquisitionAssignment && !!linkedAcquisitionAssignment.id;

    const onClickHandler = (action: AcquisitionObjectAssignmentAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="actions"
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          {showOpenParentAssignment && (
            <Action
              label="acquisitionObjectAssignment.actions.openParentAssignment"
              icon={
                <Icon
                  light
                  name="handshake-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(
                  AcquisitionObjectAssignmentAction.OpenParentAssignment
                )
              }
            />
          )}

          <Action
            label="aquisitionObjectAssignment.action.AddBid"
            icon={
              <Icon
                light
                name="sign-out"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionObjectAssignmentAction.AddBid)
            }
          />

          {showArchive && (
            <Action
              label="acquisitionObjectAssignment.actions.archive"
              icon={
                <Icon
                  light
                  name="archive"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionObjectAssignmentAction.Archive)
              }
            />
          )}

          {showUnArchive && (
            <Action
              label="acquisitionObjectAssignment.actions.unArchive"
              icon={
                <Icon
                  light
                  name="inbox-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionObjectAssignmentAction.UnArchive)
              }
            />
          )}

          {showDelete && (
            <Action
              label="acquisitionObjectAssignment.actions.delete"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionObjectAssignmentAction.Delete)
              }
            />
          )}

          <Spacer label="assignment.actions.label.other" />
          {showCreateEmail && (
            <Action
              label="acquisitionObjectAssignment.actions.createEmail"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AcquisitionObjectAssignmentAction.CreateEmail)
              }
            />
          )}
          <Action
            label="acquisitionObjectAssignment.actions.createTask"
            icon={
              <Icon
                light
                name="tasks"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(AcquisitionObjectAssignmentAction.CreateTask)
            }
          />
          <Action
            label="acquisitionObjectAssignment.actions.createAppointment"
            icon={
              <Icon
                light
                name="calendar-plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() =>
              onClickHandler(
                AcquisitionObjectAssignmentAction.CreateAppointment
              )
            }
          />
        </ActionList>
      </>
    );
  }
);
