import { RealEstateGroup } from "@haywork/api/kolibri";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditObjectScreenContainerProps } from "./assignments-edit-object.container";
import {
  AcquistionAssignmentEditObjectAgriculturalScreenContainer,
  AcquistionAssignmentEditObjectCommercialScreenContainer,
  AcquistionAssignmentEditObjectResidentialScreenContainer
} from "./components";

const styles = require("./style.scss");

export type AcquistionAssignmentEditObjectScreenProps = {};
type Props = AcquistionAssignmentEditObjectScreenProps &
  AcquistionAssignmentEditObjectScreenContainerProps;

export const AcquistionAssignmentEditObjectScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ acquisitionObjectAssignment }) => {
      const { realEstateGroup } = acquisitionObjectAssignment;

      switch (realEstateGroup) {
        case RealEstateGroup.Residential:
          return <AcquistionAssignmentEditObjectResidentialScreenContainer />;
        case RealEstateGroup.Commercial:
          return <AcquistionAssignmentEditObjectCommercialScreenContainer />;
        case RealEstateGroup.Agricultural:
          return <AcquistionAssignmentEditObjectAgriculturalScreenContainer />;
        default:
          break;
      }
    }
  )
);
