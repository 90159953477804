import { REDUX } from "@haywork/constants";
import * as ActionType from "./layout.types";

const toggleSidebar = () => ({
  type: REDUX.LAYOUT.TOGGLE_SIDEBAR
});

const toggleFullscreen = (status?: boolean) => ({
  type: REDUX.LAYOUT.TOGGLE_FULLSCREEN,
  status
});

const showLightbox = (payload: ActionType.LightboxSlides) => ({
  type: REDUX.LAYOUT.LIGHTBOX_SHOW,
  ...payload
});

const lightboxNext = () => ({
  type: REDUX.LAYOUT.LIGHTBOX_NEXT
});

const lightboxPrevious = () => ({
  type: REDUX.LAYOUT.LIGHTBOX_PREVIOUS
});

const hideLightbox = () => ({
  type: REDUX.LAYOUT.LIGHTBOX_HIDE
});

const setLightboxSlide = (lightboxCurrent: number) => ({
  type: REDUX.LAYOUT.LIGHTBOX_SET_SLIDE,
  lightboxCurrent
});

const showConfirm = (payload: ActionType.Confirm) => ({
  type: REDUX.LAYOUT.CONFIRM_SHOW,
  ...payload
});

const hideConfirm = () => ({
  type: REDUX.LAYOUT.CONFIRM_HIDE
});

const toggleOnboarding = (onboardingVisibility: boolean) => ({
  type: REDUX.LAYOUT.TOGGLE_ONBOARDING,
  onboardingVisibility
});

const openWindowFrame = (payload: ActionType.WindowFrame) => ({
  type: REDUX.LAYOUT.OPEN_WINDOW_FRAME,
  ...payload
});

const closeWindowFrame = () => ({
  type: REDUX.LAYOUT.CLOSE_WINDOW_FRAME
});

const autoHideSidebarOnMobile = () => ({
  type: REDUX.LAYOUT.CLOSE_SIDEBAR_MOBILE
});

const setCreateLoaderVisibility = (
  payload: ActionType.CreateLoaderVisible
) => ({
  type: REDUX.LAYOUT.SET_CREATE_LOADER_VISIBILITY,
  ...payload
});

const setBlobError = (payload: ActionType.BlobError) => ({
  type: REDUX.LAYOUT.SET_BLOB_ERROR,
  ...payload
});

export const LayoutActions = {
  toggleSidebar,
  toggleFullscreen,
  showLightbox,
  lightboxNext,
  lightboxPrevious,
  hideLightbox,
  setLightboxSlide,
  showConfirm,
  hideConfirm,
  toggleOnboarding,
  openWindowFrame,
  closeWindowFrame,
  autoHideSidebarOnMobile,
  setCreateLoaderVisibility,
  setBlobError
};
