import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentNetworkItemComponentProps {
  relation: LinkedRelation;
  zebra: boolean;
  onNavigateToRelation: (url: string) => void;
  onRelationRemove: (relation: LinkedRelation) => void;
}
interface SearchAssignmentNetworkItemComponentState {
  deleteCountdown: number;
  showActions: boolean;
  preppedForRemove: boolean;
  clickedInside: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentNetworkItemComponent extends React.Component<
  SearchAssignmentNetworkItemComponentProps,
  SearchAssignmentNetworkItemComponentState
> {
  private ref: HTMLDivElement;
  private removeCountdown: any;

  constructor(props) {
    super(props);

    this.state = {
      deleteCountdown: 5,
      showActions: false,
      preppedForRemove: false,
      clickedInside: false,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onToggleClickHandler = this.onToggleClickHandler.bind(this);
    this.onUnDeleteClickHandler = this.onUnDeleteClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.disablePropagation = this.disablePropagation.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const innerStyle = classNames("inner", {
      zebra: this.props.zebra,
      "actions-visible": this.state.showActions,
      "prep-for-remove": this.state.preppedForRemove,
    });

    return (
      <div
        styleName="item"
        ref={(ref) => (this.ref = ref)}
        onClick={this.onClickHandler}
      >
        <div styleName={innerStyle}>
          <div styleName="item__meta">
            <div styleName="item__avatar">{this.renderAvatarOrLetter()}</div>
            <div styleName="item__data">
              <h2>{this.props.relation.displayName}</h2>
              {this.renderEmailAndPhoneNumber()}
            </div>
          </div>
          <div styleName="item__toggle" onClick={this.onToggleClickHandler}>
            <span id="toggleSearcher" className="fal fa-fw fa-ellipsis-v" />
          </div>
          <div styleName="undo-delete" onClick={this.onUnDeleteClickHandler}>
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="item__actions">
          <div className="btn btn-success" onClick={this.onEditClickHandler}>
            <span id="editSearcher" className="fal fa-pencil" />
          </div>
          <div className="btn btn-danger" onClick={this.onDeleteClickHandler}>
            <span id="deleteSearcher" className="fal fa-times" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private onClickOutsideHandler(event: any) {
    const clickedInside = this.ref.contains(event.target);
    if (!clickedInside && !!this.state.showActions)
      this.setState({ showActions: false, clickedInside });
    this.setState({ clickedInside });
  }

  private onToggleClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setState({ showActions: !this.state.showActions });
  }

  private onEditClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    const { typeOfRelation, id } = this.props.relation;
    let url;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        url = route(RELATIONROUTES.CONTACT_PERSON_EDIT.URI, { id });
        break;
      case RelationType.ContactCompany:
        url = route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, { id });
        break;
      case RelationType.Employee:
        url = route(EMPLOYEEROUTES.EMPLOYEE_EDIT.URI, { id });
        break;
      case RelationType.Office:
        url = route(OFFICESROUTES.EDIT_OFFICE.URI, { id });
        break;
      default:
        return;
    }

    this.props.onNavigateToRelation(url);
  }

  private onDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    this.setState({ preppedForRemove: true, showActions: false });

    this.removeCountdown = setInterval(() => {
      const deleteCountdown = this.state.deleteCountdown - 1;
      this.setState({ deleteCountdown });
      if (deleteCountdown === 0) {
        clearInterval(this.removeCountdown);
        this.props.onRelationRemove(this.props.relation);
        this.setState({ preppedForRemove: false, deleteCountdown: 5 });
      }
    }, 1000);
  }

  private onUnDeleteClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();

    clearInterval(this.removeCountdown);
    this.setState({ preppedForRemove: false, deleteCountdown: 5 });
  }

  private onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    const { typeOfRelation, id } = this.props.relation;
    let url;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        url = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        break;
      case RelationType.ContactCompany:
        url = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        break;
      case RelationType.Employee:
        url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
        break;
      case RelationType.Office:
        url = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
        break;
      default:
        return;
    }

    this.props.onNavigateToRelation(url);
  }

  private renderAvatarOrLetter(): React.ReactElement<HTMLDivElement> {
    const { avatarUrl, letterAvatar } = this.props.relation;
    if (avatarUrl) {
      const style = { backgroundImage: `url(${JSON.stringify(avatarUrl)})` };
      return <div styleName="avatar" style={style} />;
    }

    return <div styleName="letters">{letterAvatar}</div>;
  }

  private renderEmailAndPhoneNumber(): React.ReactElement<HTMLDivElement> {
    const { relation } = this.props;
    const entries = ["phone", "phoneMobile", "phoneWork"];
    const phone = entries.reduce((state, entry) => {
      if (!state && !!relation[entry]) state = relation[entry];
      return state;
    }, null);

    if (!!relation.email && !!phone) {
      return (
        <div>
          <a href={`tel:${phone}`} onClick={this.disablePropagation}>
            {phone}
          </a>{" "}
          / <MailTo email={relation.email} />
        </div>
      );
    }

    if (!!relation.email) {
      return (
        <div>
          <MailTo email={relation.email} />
        </div>
      );
    }

    if (!!phone) {
      return (
        <div>
          <a href={`tel:${phone}`} onClick={this.disablePropagation}>
            {phone}
          </a>
        </div>
      );
    }

    return null;
  }

  private disablePropagation(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }
}
