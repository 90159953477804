import { WidgetEntityType } from "@haywork/api/authorization";
import {
  AssignmentPhase,
  AvailabilityStatus,
  LinkedAssignment,
  LinkedRelation,
  MatchedSearchAssignmentSnapshot,
  MatchMailPeriod,
} from "@haywork/api/kolibri";
import { EmailAddress } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { RequestStatus } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { AssignmentDetailSearchAssignmentsContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Input } from "@haywork/modules/form";
import Notes from "@haywork/modules/notes-v3";
import {
  InfiniteScroll,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Actions, { SearchAssignmentBulkAction } from "./actions";
import { AssignmentDetailSearchAssignmentsItemComponent } from "./list-item.component";

const styles = require("./detail-search-assignments.component.scss");

export type AssignmentDetailSearchAssignmentsComponentProps = {};
type State = {
  selectedSearchAssignments: MatchedSearchAssignmentSnapshot[];
  selectedPeriod: MatchMailPeriod[] | null;
  exporting: boolean;
  searchAssignments: MatchedSearchAssignmentSnapshot[];
  searchAssignmentsStatus: RequestStatus;
  searchAssignmentsTotal: number;
  searchAssignmentsCanLoadMore: boolean;
  noSearchAssignmentsAvailable: boolean;
};
type Props = AssignmentDetailSearchAssignmentsComponentProps &
  AssignmentDetailSearchAssignmentsContainerProps;

const matchMailPeriods = [
  {
    label: "DailyOrWeekly",
    value: [MatchMailPeriod.Daily, MatchMailPeriod.Weekly],
  },
  {
    label: MatchMailPeriod.Daily.toString(),
    value: [MatchMailPeriod.Daily],
  },
  {
    label: MatchMailPeriod.Weekly.toString(),
    value: [MatchMailPeriod.Weekly],
  },
  {
    label: MatchMailPeriod.Never.toString(),
    value: [MatchMailPeriod.Never],
  },
];

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailSearchAssignmentsComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onActionClickHandler = this.onActionClickHandler.bind(this);
    this.addSelectedId = this.addSelectedId.bind(this);
    this.removeSelectedId = this.removeSelectedId.bind(this);
    this.onBulkChangeHandler = this.onBulkChangeHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.exportList = this.exportList.bind(this);
    this.fetchSearchAssignments = this.fetchSearchAssignments.bind(this);

    this.state = {
      selectedSearchAssignments: [],
      selectedPeriod: null,
      exporting: false,
      searchAssignments: [],
      searchAssignmentsStatus: RequestStatus.Idle,
      searchAssignmentsTotal: 0,
      searchAssignmentsCanLoadMore: false,
      noSearchAssignmentsAvailable: false,
    };
  }

  public async fetchSearchAssignments(
    skip = 0,
    take?: number,
    period?: MatchMailPeriod[]
  ) {
    try {
      this.setState({ searchAssignmentsStatus: RequestStatus.Pending });

      if (
        this.props.objectAssignment.availabilityStatus !==
          AvailabilityStatus.Available &&
        this.props.objectAssignment.assignmentPhase !== AssignmentPhase.Concept
      ) {
        this.setState({
          searchAssignmentsStatus: RequestStatus.Success,
          searchAssignments: [],
          searchAssignmentsTotal: 0,
          searchAssignmentsCanLoadMore: false,
          noSearchAssignmentsAvailable: true,
        });
        return;
      }

      const response = await this.props.getObjectAssignmentSearchAssignments(
        this.props.objectAssignment.id,
        this.props.objectAssignment.assignmentPhase === AssignmentPhase.Concept,
        period || this.state.selectedPeriod,
        skip,
        take
      );

      const { results, totalResults } = response;
      let { searchAssignments } = this.state;

      searchAssignments =
        skip === 0 ? results || [] : [...searchAssignments, ...(results || [])];

      this.setState({
        searchAssignmentsStatus: RequestStatus.Success,
        searchAssignments,
        searchAssignmentsTotal: totalResults,
        searchAssignmentsCanLoadMore: totalResults > searchAssignments.length,
      });
    } catch (error) {
      this.setState({ searchAssignmentsStatus: RequestStatus.Error });
      throw error;
    }
  }

  public async componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.objectAssignment.id,
      WidgetEntityType.ObjectAssignment
    );
    this.fetchSearchAssignments();
  }

  public render() {
    const {
      searchAssignments,
      selectedSearchAssignments,
      selectedPeriod,
      exporting,
      searchAssignmentsStatus,
      noSearchAssignmentsAvailable,
    } = this.state;
    const bulkSelected =
      !!searchAssignments.length &&
      searchAssignments.length === selectedSearchAssignments.length;

    return (
      <div styleName="searchers">
        <PageHeader
          title="pageTitle.assignment.searchAssignments"
          subTitle={this.props.objectAssignment.displayName}
          actions={
            <>
              <Notes />
              <Actions
                visible={!!selectedSearchAssignments.length}
                canEmail={this.props.canEmail}
                selectedSearchAssignments={selectedSearchAssignments}
                onClick={this.onActionClickHandler}
              />
            </>
          }
        />

        {this.props.objectAssignment.assignmentPhase ===
          AssignmentPhase.Concept && (
          <div styleName="concept-info">
            <Icon name="info-circle" solid size={16} color={Colors.Primary} />
            <div styleName="concept-info__body">
              <I18n value="searchAssignments.info.isConcept" />
            </div>
          </div>
        )}

        <FeatureSwitch feature="FILTER_SEARCH_ASSIGNMENTS">
          <div styleName="search-assignment__filter">
            <I18n value="searchAssignments.filter.prefix" />
            <div styleName="filter__input">
              <div styleName="inner">
                <Input.NewSelect
                  name="matchMailPeriodOptions"
                  values={matchMailPeriods}
                  valuesProp="value"
                  displayProp="label"
                  addEmptyOption
                  emptyOptionLabel="matchMailPeriodOptions.All"
                  translate
                  translatePrefix="matchMailPeriodOptions"
                  value={selectedPeriod}
                  onChange={this.onChangeFilter}
                  disabled={noSearchAssignmentsAvailable}
                />
              </div>
            </div>
            {!!searchAssignments.length && (
              <div styleName="filter__export">
                <Button
                  label="objectAssignment.searchers.export"
                  category="export"
                  icon={
                    !exporting ? (
                      <Icon name="file-excel" light color={Colors.White} />
                    ) : (
                      <Icon
                        name="spinner-third"
                        light
                        color={Colors.Gray}
                        spin
                      />
                    )
                  }
                  onClick={this.exportList}
                  disabled={exporting}
                />
              </div>
            )}
          </div>
        </FeatureSwitch>

        <div styleName="searchers__header">
          <div styleName="column checkbox">
            <Input.CheckBox
              name="bulk-select-search-assignments"
              asSingleInput
              value={bulkSelected}
              onChange={this.onBulkChangeHandler}
            />
          </div>
          <div styleName="column search">
            <ResourceText resourceKey="searchers" />
          </div>
          <div styleName="column is-paid">
            <ResourceText resourceKey="searchAssignment.overviewHeader.isPaid" />
          </div>
          <div styleName="column offertype">
            <ResourceText resourceKey="rentSale" />
          </div>
          <div styleName="column date">
            <ResourceText resourceKey="createdDate" />
          </div>
          <div styleName="column frequency">
            <ResourceText resourceKey="matchmail" />
          </div>
        </div>

        <div styleName="searchers__list">
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {searchAssignments.map((searchAssignment, idx) => (
              <ErrorBoundary key={idx}>
                <AssignmentDetailSearchAssignmentsItemComponent
                  zebra={idx % 2 === 0}
                  searchAssignment={searchAssignment}
                  selectedSearchAssignments={selectedSearchAssignments}
                  onSelect={this.addSelectedId}
                  onDeselect={this.removeSelectedId}
                  onNavigateToSearchAssignment={this.props.navigate}
                />
              </ErrorBoundary>
            ))}

            {searchAssignmentsStatus === RequestStatus.Pending && (
              <PageLoader loading fullscreen={searchAssignments.length === 0} />
            )}

            {searchAssignmentsStatus === RequestStatus.Success &&
              searchAssignments.length === 0 &&
              !noSearchAssignmentsAvailable && (
                <div styleName="empty-state">
                  <div styleName="content">
                    <div styleName="icon" />
                    <div styleName="text">
                      <h2>
                        <ResourceText resourceKey="assignmentSearchAssignmentsEmptyStateTitleAlt" />
                      </h2>
                      <p>
                        <ResourceText resourceKey="assignmentSearchAssignmentsEmptyStateBody" />
                      </p>
                    </div>
                  </div>
                </div>
              )}

            {noSearchAssignmentsAvailable && (
              <div styleName="not-available">
                <ResourceText resourceKey="assignmentSearchAssignmentsNotAvailable" />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    if (
      !this.state.searchAssignmentsCanLoadMore ||
      this.state.searchAssignmentsStatus === RequestStatus.Pending
    ) {
      return;
    }

    const { searchAssignments } = this.state;
    this.fetchSearchAssignments(searchAssignments.length);
  }

  private onActionClickHandler(action: SearchAssignmentBulkAction) {
    switch (action) {
      case SearchAssignmentBulkAction.Deselect: {
        this.setState({ selectedSearchAssignments: [] });
        return;
      }
      case SearchAssignmentBulkAction.CreateEmail: {
        const { selectedSearchAssignments } = this.state;
        let relationsWithEmail = selectedSearchAssignments.reduce(
          (state, searchAssignment) => {
            (searchAssignment.linkedRelations || [])
              .filter((relation) => !!relation.email)
              .forEach((relation) => state.push(relation));
            return state;
          },
          [] as LinkedRelation[]
        );

        relationsWithEmail = uniqBy(
          relationsWithEmail,
          (relation) => relation.id
        );

        if (!relationsWithEmail.length) return;
        const emailAddresses = relationsWithEmail.map(
          (relation) =>
            ({
              email: relation.email,
              name: relation.displayName,
            } as EmailAddress)
        );
        const linkedAssignment: LinkedAssignment = {
          id: this.props.objectAssignment.id,
          displayName: this.props.objectAssignment.displayName,
        };
        const subject = this.props.intl.formatMessage(
          { id: "searchAssignment.email.subject", defaultMessage: "" },
          { address: this.props.objectAssignment.displayName }
        );

        this.props.createNewEmail(
          emailAddresses,
          relationsWithEmail,
          linkedAssignment,
          subject,
          true
        );
        this.setState({ selectedSearchAssignments: [] });
        return;
      }
      default: {
        return;
      }
    }
  }

  private addSelectedId(searchAssignment: MatchedSearchAssignmentSnapshot) {
    const selectedSearchAssignments = [
      ...this.state.selectedSearchAssignments,
      searchAssignment,
    ];
    this.setState({ selectedSearchAssignments });
  }

  private removeSelectedId(searchAssignment: MatchedSearchAssignmentSnapshot) {
    const selectedSearchAssignments =
      this.state.selectedSearchAssignments.filter(
        (selectedSearchAssignment) =>
          selectedSearchAssignment.id !== searchAssignment.id
      );
    this.setState({ selectedSearchAssignments });
  }

  private onBulkChangeHandler(checked: boolean) {
    if (checked) {
      this.setState({
        selectedSearchAssignments: this.state.searchAssignments || [],
      });
    } else {
      this.setState({ selectedSearchAssignments: [] });
    }
  }

  private onChangeFilter(selectedPeriod: MatchMailPeriod[]) {
    this.setState({ selectedPeriod, selectedSearchAssignments: [] });
    this.fetchSearchAssignments(undefined, undefined, selectedPeriod);
  }

  private async exportList() {
    try {
      const id = this.props.objectAssignment?.id;
      const isConceptAssignment =
        this.props.objectAssignment?.assignmentPhase ===
        AssignmentPhase.Concept;

      if (!id) return;
      this.setState({ exporting: true });

      const matchMailPeriods = !this.state.selectedPeriod
        ? undefined
        : this.state.selectedPeriod;
      const take = this.state.searchAssignmentsTotal;

      await this.props.exportAssignmentSearchAssignments(
        id,
        take,
        isConceptAssignment,
        matchMailPeriods
      );
    } finally {
      this.setState({ exporting: false });
    }
  }
}
