import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { PresenceContainerProps } from "./presence.container";

const styles = require("./style.scss");

export type PresenceComponentProps = {};
type Props = PresenceComponentProps & PresenceContainerProps;

export const PresenceComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ presentEmployees }) => {
    return (
      <div styleName="presence">
        {presentEmployees.map(({ employee, count, info }) => (
          <div styleName="container" key={employee.id}>
            <div
              styleName="avatar"
              style={{
                backgroundImage: !employee.avatarUrl
                  ? null
                  : `url(${JSON.stringify(employee.avatarUrl)})`
              }}
            >
              {!employee.avatarUrl && !!employee.letterAvatar && (
                <div styleName="letter-avatar">{employee.letterAvatar}</div>
              )}
              <div styleName="count">{count}</div>
            </div>

            <div styleName="info">
              <div styleName="name">{employee.displayName}</div>
              {!!info && (!!info.appVersion || !!info.browser) && (
                <div styleName="data">
                  {info.browser} {info.appVersion}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  })
);
