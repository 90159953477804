import { createSelector } from "reselect";
import get from "lodash-es/get";
import head from "lodash-es/head";
import { AppState } from "@haywork/stores";
import { TemplateDefinitionCategoryType } from "@haywork/api/kolibri";

const templatesSelector = (state: AppState) =>
  state.emailV2.templates.templates;
const categorySelector = (state: AppState) =>
  state.emailV2.templates.categories;
const officesSelector = (state: AppState) => state.account.offices;
const linkedOfficesSelector = (state: AppState) =>
  state.account.employee.linkedOffices;

export const signatures = createSelector(
  [templatesSelector, categorySelector],
  (templates, categories) => {
    const signaturesCategoryIds = categories
      .filter(
        (category) =>
          category.categoryType ===
          TemplateDefinitionCategoryType.EmailSignature
      )
      .map((category) => category.id);

    return templates.filter((template) => {
      return (
        signaturesCategoryIds.indexOf(
          get(template, "linkedTemplateDefinitionCategory.id")
        ) !== -1
      );
    });
  }
);

export const officeId = createSelector(
  [officesSelector, linkedOfficesSelector],
  (offices, linkedOffices) => {
    if (!!linkedOffices && !!linkedOffices.length) {
      const office = linkedOffices.find((office) => !!office.isMainOffice);
      if (!!office) return office.id;
    }

    if (!!offices && !!offices.length) {
      const office = offices.find((office) => !!office.isMainOffice);
      if (!!office) return office.id;
    }

    if (!!linkedOffices && !!linkedOffices.length) {
      const office = head(linkedOffices);
      if (!!office) return office.id;
    }

    if (!officeId && !!offices && !!offices.length) {
      const office = head(offices);
      if (!!office) return office.id;
    }

    return "";
  }
);
