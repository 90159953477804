import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { NotificationsActions } from "@haywork/stores/notifications";
import { connect, MapStateToProps } from "react-redux";
import {
  eventNotificationDateTimeValue,
  generalNotificationValues,
  personalNotificationValues,
  typeFilterValues
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  personalNotificationValues: SingleFilterValue[];
  generalNotificationValues: SingleFilterValue[];
  typeFilterValues: SingleFilterValue[];
  eventNotificationDateTimeValue: SingleFilterValue;
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters, statistics } = state.notifications.eventCenter;

  return {
    filters,
    personalNotificationValues: personalNotificationValues(state),
    generalNotificationValues: generalNotificationValues(state),
    typeFilterValues: typeFilterValues(state),
    eventNotificationDateTimeValue: eventNotificationDateTimeValue(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(NotificationsActions.EventCenter.setFilters(filters))
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
