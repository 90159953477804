import {
  AcquisitionObjectAssignment,
  BusinessRights,
  GroundProperty,
  ListingType as lType,
  LocationPlaceOption,
  Maintenance,
  Peculiarity,
  RentOffer,
  Roof,
  SaleOffer,
  Size,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import ListingType, {
  ListingTypeResponse,
} from "@haywork/modules/acquisition/components/listing-type";
import { AssignmentEditContentFeaturesAndFiguresComponent } from "@haywork/modules/assignment/components/edit-content/residential";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
} from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { AcquisitionUtil, RouteUtil, StringUtil } from "@haywork/util";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { AcquistionAssignmentEditObjectResidentialScreenContainerProps } from "./assignments-edit-object-residential.container";
import { WithIntlProps } from "react-intl";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;
const accessibilityTypes = [
  Peculiarity.AccessibleForTheElderly,
  Peculiarity.AccessibleToDisabledPeople,
];
const occupancyTypes = [
  Peculiarity.DoubleOccupancyAvailable,
  Peculiarity.DoubleOccupancyPossible,
  Peculiarity.PartiallyLeased,
];
const distinguishableTypes = [
  Peculiarity.MonumentalBuilding,
  Peculiarity.NeedsRenovation,
  Peculiarity.DemolitionHouse,
  Peculiarity.Heritage,
];

export type AcquistionAssignmentEditObjectResidentialScreenProps = {};
type Props = AcquistionAssignmentEditObjectResidentialScreenProps &
  AcquistionAssignmentEditObjectResidentialScreenContainerProps;

export const AcquistionAssignmentEditObjectResidentialScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionObjectAssignment,
      gardenTypeOptions,
      garageTypeOptions,
      constructionPeriods,
      locationPlaces,
      groundOwnershipTypeOptions,
      accessibilityOptions,
      certificationOptions,
      vveOptions,
      comfortQualityOptions,
      furnishingOptions,
      typeOfFloorOptions,
      roofTypeOptions,
      roofMaterialOptions,
      typeOfWindowFrameOptions,
      conditionTypeOptions,
      typeOfBusinessLawOptions,
      updateAcquisitionAssignmentObject,
    }) => {
      const {
        numberOfRooms,
        numberOfBedRooms,
        numberOfFloors,
        floorLevel,
        yearOfConstruction,
        usableArea,
        otherInsideSpaces,
        buildingRelatedOutdoorSpace,
        externalStorage,
        parcelSurface,
        contents,
        locationPlaces: locationPlacesAssignment,
        isPermanentlyInhabited,
        isRecreational,
        inPark,
        approvalMarks,
        comfortQuality,
        typeOfFloorGroundFloor,
        typeOfFloorFloors,
        typesOfWindowFrames,
        maintenanceInside,
        maintenanceOutside,
        businessRights,
        roof,
        saleOffer,
        rentOffer,
        peculiarities,
        garages,
        gardens,
        groundProperty,
        forRent,
        realEstateGroup,
        listingType,
        houseSort,
        houseType,
        houseCharacteristic,
        apartmentSort,
        apartmentCharacteristic,
        parkingType,
        residentialSubtypeOther,
        isNew,
      } = acquisitionObjectAssignment;

      const selectedVveOptions = useMemo(() => {
        return AcquisitionUtil.getSelectedVVEOptions(
          acquisitionObjectAssignment,
          vveOptions
        );
      }, [acquisitionObjectAssignment]);

      const accessibility = useMemo(() => {
        return (peculiarities || []).filter((peculiarity) =>
          accessibilityTypes.includes(peculiarity)
        );
      }, [peculiarities]);

      const occupancy = useMemo(() => {
        return (peculiarities || []).filter((peculiarity) =>
          occupancyTypes.includes(peculiarity)
        );
      }, [peculiarities]);

      const distinguishables = useMemo(() => {
        return (peculiarities || []).filter((peculiarity) =>
          distinguishableTypes.includes(peculiarity)
        );
      }, [peculiarities]);

      const isForSale = useMemo(() => {
        return forRent === true ? false : true;
      }, [forRent]);

      const localLocationPlaces = useMemo(() => {
        return (locationPlacesAssignment || [])
          .map((locationPlace) =>
            locationPlaces.find((place) => place.value === locationPlace)
          )
          .filter((place) => !!place);
      }, [locationPlacesAssignment]);

      const formControls = useMemo(() => {
        return {
          forSale: { value: isForSale },
          numberOfRooms: { value: numberOfRooms },
          numberOfBedRooms: { value: numberOfBedRooms },
          numberOfFloors: { value: numberOfFloors },
          floorLevel: { value: floorLevel },
          locationPlaces: { value: localLocationPlaces },
          constructionYear: {
            value: get(yearOfConstruction, "constructionYear", null),
          },
          constructionPeriod: {
            value: get(yearOfConstruction, "period", null),
          },
          usableArea: { value: get(usableArea, "area", null) },
          otherInsideSpaces: {
            value: get(otherInsideSpaces, "area", null),
          },
          buildingRelatedOutdoorSpace: {
            value: get(buildingRelatedOutdoorSpace, "area", null),
          },
          externalStorage: {
            value: get(externalStorage, "area", null),
          },
          parcelSurface: {
            value: get(parcelSurface, "area", null),
          },
          contents: { value: get(contents, "area", null) },
          isPermanentlyInhabited: { value: isPermanentlyInhabited },
          isRecreational: { value: isRecreational },
          inPark: { value: inPark },
          approvalMarks: { value: approvalMarks },
          comfortQuality: { value: comfortQuality },
          typeOfFloorGroundFloor: { value: typeOfFloorGroundFloor },
          typeOfFloorFloors: { value: typeOfFloorFloors },
          typesOfWindowFrames: { value: typesOfWindowFrames },
          maintenanceInside: {
            value: get(maintenanceInside, "conditionType", null),
          },
          maintenanceOutside: {
            value: get(maintenanceOutside, "conditionType", null),
          },
          businessLawType: {
            value: get(businessRights, "businessLawType", null),
          },
          roofMaterials: {
            value: get(roof, "roofMaterials", null),
          },
          roofType: { value: get(roof, "roofType", null) },
          furnishing: {
            value: isForSale
              ? saleOffer && saleOffer.furnishing
                ? saleOffer.furnishing
                : null
              : rentOffer && rentOffer.furnishing
              ? rentOffer.furnishing
              : null,
          },
          vve: {
            value: selectedVveOptions,
          },
          accessibility: { value: accessibility },
          occupancy: { value: occupancy },
          distinguishables: { value: distinguishables },
          garageType: {
            value: get(garages && garages[0], "garageType", null),
          },
          gardenType: {
            value: get(gardens && gardens[0], "gardenType", null),
          },
          groundOwnershipType: {
            value: get(groundProperty, "groundOwnershipType", null),
          },
        } as FormControls;
      }, []);

      const onChangeHandler = (values: FormReturnValue) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id,
        });

        let updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          gardens: !values.gardenType
            ? acquisitionObjectAssignment.gardens
            : [{ gardenType: values.gardenType }],
          garages: !values.garageType
            ? acquisitionObjectAssignment.garages
            : [{ garageType: values.garageType }],
          yearOfConstruction:
            !values.constructionPeriod && !values.constructionYear
              ? acquisitionObjectAssignment.yearOfConstruction
              : {
                  period: values.constructionPeriod,
                  constructionYear: values.constructionYear,
                },
          peculiarities: values.accessibility
            ? [...values.accessibility]
            : null,
          roof: !values.roofType
            ? acquisitionObjectAssignment.roof
            : { roofType: values.roofType },
          numberOfRooms:
            values.numberOfRooms === ""
              ? null
              : !values.numberOfRooms
              ? acquisitionObjectAssignment.numberOfRooms
              : values.numberOfRooms,
          numberOfBedRooms:
            values.numberOfBedRooms === ""
              ? null
              : !values.numberOfBedRooms
              ? acquisitionObjectAssignment.numberOfBedRooms
              : values.numberOfBedRooms,
          numberOfFloors:
            values.numberOfFloors === ""
              ? null
              : !values.numberOfFloors
              ? acquisitionObjectAssignment.numberOfFloors
              : values.numberOfFloors,
          floorLevel:
            values.floorLevel === ""
              ? null
              : !values.floorLevel
              ? acquisitionObjectAssignment.floorLevel
              : values.floorLevel,
          isPermanentlyInhabited: !values.isPermanentlyInhabited
            ? acquisitionObjectAssignment.isPermanentlyInhabited
            : values.isPermanentlyInhabited,
          isRecreational: !values.isRecreational
            ? acquisitionObjectAssignment.isRecreational
            : values.isRecreational,
          inPark: !values.inPark
            ? acquisitionObjectAssignment.inPark
            : values.inPark,
          approvalMarks: !values.approvalMarks
            ? acquisitionObjectAssignment.approvalMarks
            : values.approvalMarks,
          comfortQuality: !values.comfortQuality
            ? acquisitionObjectAssignment.comfortQuality
            : values.comfortQuality,
          typeOfFloorGroundFloor: !values.typeOfFloorGroundFloor
            ? acquisitionObjectAssignment.typeOfFloorGroundFloor
            : values.typeOfFloorGroundFloor,
          typeOfFloorFloors: !values.typeOfFloorFloors
            ? acquisitionObjectAssignment.typeOfFloorFloors
            : values.typeOfFloorFloors,
          typesOfWindowFrames: !values.typesOfWindowFrames
            ? acquisitionObjectAssignment.typesOfWindowFrames
            : values.typesOfWindowFrames,
        };

        let saleOffer: SaleOffer, rentOffer: RentOffer;

        if (isForSale) {
          saleOffer = {
            ...updatedAcquisitionAssignmentObject.saleOffer,
            furnishing: values.furnishing,
          };
        } else {
          rentOffer = {
            ...updatedAcquisitionAssignmentObject.rentOffer,
            furnishing: values.furnishing,
          };
        }

        const roof: Roof = {
          ...acquisitionObjectAssignment.roof,
          roofType: values.roofType,
          roofMaterials: values.roofMaterials,
        };

        const businessRights: BusinessRights = {
          ...acquisitionObjectAssignment.businessRights,
          businessLawType: values.businessLawType
            ? [...values.businessLawType]
            : null,
        };

        const usableArea: Size = {
          ...acquisitionObjectAssignment.usableArea,
          area: values.usableArea ? values.usableArea : null,
        };

        const otherInsideSpaces: Size = {
          ...acquisitionObjectAssignment.otherInsideSpaces,
          area: values.otherInsideSpaces ? values.otherInsideSpaces : null,
        };

        const buildingRelatedOutdoorSpace: Size = {
          ...acquisitionObjectAssignment.buildingRelatedOutdoorSpace,
          area: values.buildingRelatedOutdoorSpace
            ? values.buildingRelatedOutdoorSpace
            : null,
        };

        const externalStorage: Size = {
          ...acquisitionObjectAssignment.externalStorage,
          area: values.externalStorage ? values.externalStorage : null,
        };

        const parcelSurface: Size = {
          ...acquisitionObjectAssignment.parcelSurface,
          area: values.parcelSurface ? values.parcelSurface : null,
        };

        const contents: Size = {
          ...acquisitionObjectAssignment.contents,
          area: values.contents ? values.contents : null,
        };

        const groundProperty: GroundProperty = {
          ...acquisitionObjectAssignment.groundProperty,
          groundOwnershipType: values.groundOwnershipType
            ? values.groundOwnershipType
            : null,
        };

        const maintenanceInside: Maintenance = {
          ...acquisitionObjectAssignment.maintenanceInside,
          conditionType: values.maintenanceInside
            ? values.maintenanceInside
            : null,
        };
        const maintenanceOutside: Maintenance = {
          ...acquisitionObjectAssignment.maintenanceOutside,
          conditionType: values.maintenanceOutside
            ? values.maintenanceOutside
            : null,
        };
        const locationPlaces = values.locationPlaces.map(({ value }) => value);

        const vveValues = AcquisitionUtil.setSelectedVVEOptions(
          values.vve,
          vveOptions
        );

        updatedAcquisitionAssignmentObject = {
          ...updatedAcquisitionAssignmentObject,
          saleOffer,
          rentOffer,
          roof,
          businessRights,
          usableArea,
          otherInsideSpaces,
          buildingRelatedOutdoorSpace,
          externalStorage,
          parcelSurface,
          contents,
          locationPlaces,
          groundProperty,
          maintenanceInside,
          maintenanceOutside,
          ...vveValues,
        };

        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const handleListingChange = (listingTypes: ListingTypeResponse) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id,
        });

        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          ...listingTypes,
        };

        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const subFormChangeHandler = (fields) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id,
        });
        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          ...fields,
        };
        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const renderQueryLocationPlaceOption = (
        value: LocationPlaceOption,
        query: string
      ): QueryOptionReturnValue => {
        return (
          <div
            dangerouslySetInnerHTML={StringUtil.highlight(
              value.displayName,
              query
            )}
          />
        );
      };
      const locationPlaceQueryMatchOn = (
        query: string,
        value: LocationPlaceOption
      ): boolean => {
        const matchOn = new RegExp(escapeRegExp(query), "gi");
        return matchOn.test(value.displayName);
      };
      const renderSelectedLocationPlaceValue = (
        value: LocationPlaceOption
      ): QueryResultReturnValue<any> => {
        return {
          value,
          template: <div>{value.displayName}</div>,
        };
      };

      return (
        <div styleName="edit-detail" id="scroll-to-top">
          <div className="container-fluid">
            <Form
              name="acquisition-object-object"
              formControls={formControls}
              onChange={onChangeHandler}
            >
              <StepperComponent initial={0} scrollToElementId="scroll-to-top">
                <StepComponent title="acquisition.assignment.edit.typeOfBuild">
                  <div className="form__row">
                    <ListingType
                      realEstateGroup={realEstateGroup}
                      listingType={listingType}
                      houseSort={houseSort}
                      houseType={houseType}
                      houseCharacteristic={houseCharacteristic}
                      apartmentSort={apartmentSort}
                      apartmentCharacteristic={apartmentCharacteristic}
                      parkingType={parkingType}
                      residentialSubtypeOther={residentialSubtypeOther}
                      isNew={isNew}
                      onChange={handleListingChange}
                    />
                  </div>
                </StepComponent>
                <StepComponent title="acquisition.assignment.edit.areas">
                  {/* areas */}

                  <div className="form__row">
                    <div className="form__group">
                      <div styleName="column">
                        <label htmlFor="noOfRooms">
                          <I18n value="acquisition.assignment.edit.areas.noOfRooms" />
                        </label>
                        <div styleName="row__input">
                          <Input.Number name="numberOfRooms" />
                        </div>
                      </div>
                      <div styleName="column">
                        <label htmlFor="numberOfBedRooms">
                          <I18n value="acquisition.assignment.edit.areas.ofWhichBedrooms" />
                        </label>
                        <div styleName="row__input">
                          <Input.Number name="numberOfBedRooms" />
                        </div>
                      </div>
                      <div styleName="column">
                        <label htmlFor="numberOfFloors">
                          <I18n value="acquisition.assignment.edit.areas.noOfFloors" />
                        </label>
                        <div styleName="row__input">
                          <Input.Number name="numberOfFloors" />
                        </div>
                      </div>

                      {listingType === lType.Apartment && (
                        <div styleName="column">
                          <label htmlFor="floorLevel">
                            <I18n value="acquisition.assignment.edit.areas.onFloor" />
                          </label>
                          <div styleName="row__input">
                            <Input.Number name="floorLevel" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form__divider"></div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column column__minwidth">
                        <label htmlFor="gardenType">
                          <I18n value="garden" />
                        </label>
                        <Input.RadioGroup
                          name="gardenType"
                          asButtonList
                          canUnselect={true}
                          multiline
                        >
                          {gardenTypeOptions.map((gardenTypeOption, idx) => (
                            <Input.Radio
                              value={gardenTypeOption.value}
                              label={gardenTypeOption.displayName}
                              key={idx}
                            />
                          ))}
                        </Input.RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="form__divider"></div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column column__minwidth">
                        <label htmlFor="garageType">
                          <I18n value="garage" />
                        </label>
                        <div className="form__control">
                          <Input.RadioGroup
                            name="garageType"
                            asButtonList
                            canUnselect={true}
                            multiline
                          >
                            {garageTypeOptions.map((garageTypeOption, idx) => (
                              <Input.Radio
                                value={garageTypeOption.value}
                                label={garageTypeOption.displayName}
                                key={idx}
                              />
                            ))}
                          </Input.RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </StepComponent>
                <StepComponent title="acquisition.assignment.edit.measurementsAndNumbers">
                  <AssignmentEditContentFeaturesAndFiguresComponent
                    objectAssignment={acquisitionObjectAssignment}
                    constructionPeriods={constructionPeriods}
                    onChange={subFormChangeHandler}
                    showRequiredHints={false}
                    asSubForm={true}
                  />
                </StepComponent>
                <StepComponent title="acquisition.assignment.edit.particularities">
                  {/* Location places */}
                  <div className="form__row">
                    <label htmlFor="locationPlaces">
                      <I18n value="locationPlaces" />
                    </label>
                    <div className="input__wrapper">
                      <Input.Query
                        name="locationPlaces"
                        optionValue={renderQueryLocationPlaceOption}
                        values={locationPlaces}
                        matchOn={locationPlaceQueryMatchOn}
                        selectedValue={renderSelectedLocationPlaceValue}
                        multiple
                        placeholder="locationPlacesPlaceholder"
                        openOnDeleteOption={false}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="groundOwnershipType">
                      <I18n value="cadastralOwnershipData" />
                    </label>

                    <Input.NewSelect
                      name="groundOwnershipType"
                      values={groundOwnershipTypeOptions}
                      displayProp="displayName"
                      valuesProp="value"
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="accessibility">
                      <I18n value="accessibilityOptions" />
                    </label>
                    <Input.Multi
                      name="accessibility"
                      values={accessibilityOptions}
                    />
                  </div>
                  <div className="form__row">
                    <label>
                      <I18n value="inhabitType" />
                    </label>
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="isPermanentlyInhabited"
                          label="isPermanentlyInhabited"
                          on={true}
                          off={false}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.Switch
                          name="isRecreational"
                          label="isRecreational"
                          on={true}
                          off={false}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <Input.Switch
                          name="inPark"
                          label="inPark"
                          on={true}
                          off={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="approvalMarks">
                      <I18n value="approvalMarks" />
                    </label>
                    <Input.Multi
                      name="approvalMarks"
                      values={certificationOptions}
                    />
                  </div>
                  {listingType === lType.Apartment && (
                    <div className="form__row">
                      <label htmlFor="vve">
                        <I18n value="vve" />
                      </label>
                      <Input.Multi name="vve" values={vveOptions} />
                    </div>
                  )}
                  <div className="form__row">
                    <label htmlFor="comfortQuality">
                      <I18n value="comfortQuality" />
                    </label>
                    <Input.RadioGroup
                      name="comfortQuality"
                      asButtonList
                      canUnselect={true}
                    >
                      {comfortQualityOptions.map(
                        (comfortQualityOption, idx) => (
                          <Input.Radio
                            value={comfortQualityOption.value}
                            label={comfortQualityOption.displayName}
                            key={idx}
                          />
                        )
                      )}
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">
                    <label htmlFor="furnishing">
                      <I18n value="furnishing" />
                    </label>
                    <Input.RadioGroup
                      name="furnishing"
                      asButtonList
                      canUnselect={true}
                    >
                      {furnishingOptions.map((furnishingOption, idx) => (
                        <Input.Radio
                          value={furnishingOption.value}
                          label={furnishingOption.displayName}
                          key={idx}
                        />
                      ))}
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">
                    <label htmlFor="typeOfFloorGroundFloor">
                      <I18n value="typeOfFloorGroundFloor" />
                    </label>
                    <Input.RadioGroup
                      name="typeOfFloorGroundFloor"
                      asButtonList
                      canUnselect={true}
                    >
                      {typeOfFloorOptions.map((typeOfFloorOption, idx) => (
                        <Input.Radio
                          value={typeOfFloorOption.value}
                          label={typeOfFloorOption.displayName}
                          key={idx}
                        />
                      ))}
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">
                    <label htmlFor="typeOfFloorFloors">
                      <I18n value="typeOfFloorFloors" />
                    </label>
                    <Input.RadioGroup
                      name="typeOfFloorFloors"
                      asButtonList
                      canUnselect={true}
                    >
                      {typeOfFloorOptions.map((typeOfFloorOption, idx) => (
                        <Input.Radio
                          value={typeOfFloorOption.value}
                          label={typeOfFloorOption.displayName}
                          key={idx}
                        />
                      ))}
                    </Input.RadioGroup>
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column column__minwidth">
                        <label htmlFor="roofType">
                          <I18n value="roofType" />
                        </label>

                        <Input.NewSelect
                          name="roofType"
                          values={roofTypeOptions}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="roofMaterials">
                      <I18n value="roofMaterials" />
                    </label>
                    <Input.Multi
                      name="roofMaterials"
                      values={roofMaterialOptions}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="typesOfWindowFrames">
                      <I18n value="typesOfWindowFrames" />
                    </label>
                    <Input.Multi
                      name="typesOfWindowFrames"
                      values={typeOfWindowFrameOptions}
                    />
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column column__minwidth">
                        <label htmlFor="maintenanceInside">
                          <I18n value="maintenanceInside" />
                        </label>
                        <Input.NewSelect
                          name="maintenanceInside"
                          values={conditionTypeOptions}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column column__minwidth">
                        <label htmlFor="maintenanceOutside">
                          <I18n value="maintenanceOutside" />
                        </label>
                        <Input.NewSelect
                          name="maintenanceOutside"
                          values={conditionTypeOptions}
                          displayProp="displayName"
                          valuesProp="value"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="businessLawType">
                      <I18n value="businessLawType" />
                    </label>
                    <Input.Multi
                      name="businessLawType"
                      values={typeOfBusinessLawOptions}
                    />
                  </div>
                </StepComponent>
              </StepperComponent>
            </Form>
          </div>
        </div>
      );
    }
  )
);
