import { Event } from "@haywork/api/event-center";
import { DashboardAction, DashboardActionTypes } from "./dashboard.actions";
import { REQUEST } from "@haywork/constants";

export interface DashboardState {
  assignmentsNeedAttentionEvents: Event[];
  assignmentsNeedAttentionStatus: string;
  leadsEvents: Event[];
  leadsStatus: string;
}

const INITIAL_STATE: DashboardState = {
  assignmentsNeedAttentionEvents: [],
  assignmentsNeedAttentionStatus: REQUEST.IDLE,
  leadsEvents: [],
  leadsStatus: REQUEST.IDLE
};

export const dashboardReducer = (
  state: DashboardState = INITIAL_STATE,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.SetAssignmentsNeedAttentionResults: {
      const { assignmentsNeedAttentionEvents } = action;

      return {
        ...state,
        assignmentsNeedAttentionEvents,
        assignmentsNeedAttentionStatus: REQUEST.SUCCESS
      };
    }
    case DashboardActionTypes.SetAssignmentsNeedAttentionStatus: {
      const { assignmentsNeedAttentionStatus } = action;

      return {
        ...state,
        assignmentsNeedAttentionStatus
      };
    }
    case DashboardActionTypes.SetLeadsResults: {
      const { leadsEvents } = action;

      return {
        ...state,
        leadsEvents,
        leadsStatus: REQUEST.SUCCESS
      };
    }
    case DashboardActionTypes.SetLeadsStatus: {
      const { leadsStatus } = action;

      return {
        ...state,
        leadsStatus
      };
    }
    case DashboardActionTypes.Archive: {
      const { eventId } = action;
      const assignmentsNeedAttentionEvents = state.assignmentsNeedAttentionEvents.filter(
        (event) => event.id !== eventId
      );

      return {
        ...state,
        assignmentsNeedAttentionEvents
      };
    }
    default:
      return state;
  }
};
