import { RootEntityType } from "@haywork/api/event-center";
import { Employee, PhotoBlob } from "@haywork/api/kolibri";
import { EMPLOYEEROUTES } from "@haywork/constants";
import { Dispatch, EmployeeThunk } from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { AppState, EmployeeActions } from "@haywork/stores";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { EmployeeDetailComponent } from "../components/employee-detail/employee-detail.component";

interface StateProps {
  selectedEmployee: Employee;
  selectedEmployeeId: string;
  employeeStatus: string;
}

interface DispatchProps {
  getEmployeeInfo: (id: string) => void;
  saveEmployee: (employee: Employee, path: string) => void;
  updateEmployeePassportPhoto: (
    newAvatar: PhotoBlob,
    employeeId: string
  ) => void;
}

const mapStateToProps = <StateProps, EmployeeDetailComponentProps>(
  state: AppState
) => ({
  selectedEmployee: state.editable.currentComponentState,
  selectedEmployeeId: state.employee.selectedEmployee.id,
  employeeStatus: state.employee.employeeState
});

const mapDispatchToProps = <DispatchProps, EmployeeDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getEmployeeInfo: (employeeId: string) =>
    dispatch(EmployeeThunk.getEmployeeInfo(employeeId)),
  saveEmployee: (employee: Employee, path: string) =>
    dispatch(EmployeeThunk.saveEmployee(employee, path)),
  updateEmployeePassportPhoto: (newAvatar: PhotoBlob, employeeId: string) =>
    dispatch(EmployeeThunk.updateEmployeePassportPhoto(newAvatar, employeeId))
});

export type EmployeeDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  EditableHocProps;

export const EmployeeDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(EmployeeDetailComponent)
  ),
  {
    entityType: RootEntityType.Employee,
    icon: "user-alt",
    thunk: EmployeeThunk.getEmployeeInfo,
    status: "employee.employeeState",
    statePath: "employee.selectedEmployee",
    action: EmployeeActions.setEmployeeDirectly,
    route: EMPLOYEEROUTES.EMPLOYEE.URI,
    confirm: {
      title: { key: "saveEmployeeConfirmTitle" },
      body: { key: "saveEmployeeConfirmBody" }
    }
  }
);
