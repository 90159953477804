import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  ObjectAssignment,
  ObjectAssignmentLinkRelationRequest,
  RelationRoleOption,
  LinkedRelation,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  AuthorizationThunks,
  MapDispatchToProps,
} from "@haywork/middleware";
import {
  AssignmentDetailNetworkComponent,
  AssignmentDetailNetworkComponentProps,
} from "@haywork/modules/assignment";
import { AppState, SingleAssignmentState } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { canSendEmail } from "@haywork/selector";
import { EmailAddress } from "@haywork/api/mail";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";

interface StateProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  relationRoles: RelationRoleOption[];
  realEstateAgencyId: string;
  host: string;
  path: string;
  saveContactState: string;
  canEmail: boolean;
}
interface DispatchProps {
  navigate: (url: string) => void;
  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  saveAssignment: (objectAssignment: ObjectAssignment) => void;
  saveSingleAssignment: (objectAssignment: ObjectAssignment) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
  linkRelation: (
    relation: ObjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ObjectAssignment
  ) => Promise<void>;
  unlinkRelation: (
    relation: ObjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ObjectAssignment
  ) => Promise<void>;
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    subject: string,
    toBcc?: boolean
  ) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentDetailNetworkComponentProps,
  AppState
> = (state) => {
  const { currentComponentState } = state.editable;
  const { relationRoles } = state.main.mastertable.kolibri;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const path = RouteUtil.mapStaticRouteValues(ASSIGNMENTROUTES.DETAIL.URI, {
    id: currentComponentState.objectAssignment.id,
  });

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    relationRoles,
    realEstateAgencyId,
    host,
    path,
    saveContactState: state.assignment.single.saveAssignmentState,
    canEmail: canSendEmail(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentDetailNetworkComponentProps
> = (dispatch) => ({
  navigate: (url: string) => dispatch(push(url)),
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      AssignmentThunks.updateAssignmentEditable(componentState, path, true)
    ),
  saveAssignment: (objectAssignment: ObjectAssignment) =>
    dispatch(AssignmentThunks.saveAssignment(objectAssignment, false)),
  saveSingleAssignment: (objectAssignment: ObjectAssignment) =>
    dispatch(AssignmentThunks.saveSingleObjectAssignment(objectAssignment)),
  linkRelation: (
    relation: ObjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ObjectAssignment
  ) =>
    dispatch(AssignmentThunks.linkRelation(relation, updatedObjectAssignment)),
  unlinkRelation: (
    relation: ObjectAssignmentLinkRelationRequest,
    updatedObjectAssignment: ObjectAssignment
  ) =>
    dispatch(
      AssignmentThunks.unlinkRelation(relation, updatedObjectAssignment)
    ),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
  createNewEmail: (
    emails: EmailAddress[],
    linkedRelations: LinkedRelation[],
    linkedAssignment: LinkedAssignment,
    subject: string,
    toBcc?: boolean
  ) =>
    dispatch(
      EmailThunk.Main.createNewEmail(
        emails,
        linkedRelations,
        [linkedAssignment],
        subject,
        undefined,
        toBcc
      )
    ),
});

export type AssignmentDetailNetworkContainerProps = StateProps & DispatchProps;
export const AssignmentDetailNetworkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentDetailNetworkComponent);
