import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import {
  ActiveFilter,
  AssignmentSnapShot,
  ProjectAssignment
} from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks
} from "@haywork/middleware/thunk";
import {
  ProjectDetailBuildnumbersComponent,
  ProjectDetailBuildnumbersComponentProps
} from "@haywork/modules/project";
import { AppState, ProjectsActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  buildnumbersLoading,
  canCreateObjectAssignment,
  objectTypeOptions,
  objectTypePrice
} from "./selectors";

interface StateProps {
  buildnumbers: AssignmentSnapShot[];
  buildnumbersCanLoadMore: boolean;
  buildnumbersTotal: number;
  loading: boolean;
  project: ProjectAssignment;
  objectTypeOptions: {
    value: string;
    text: string;
  }[];
  price: number;
  canCreateObjectAssignment: boolean;
}
interface DispatchProps {
  getBuildnumbers: (
    projectId: string,
    objectTypeId: string,
    filterByActive?: ActiveFilter,
    skip?: number
  ) => void;
  navigate: (path: string) => void;
  toggleAddBuildnumbers: (visible: boolean) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, ProjectDetailBuildnumbersComponentProps>(
  state: AppState
) => {
  const {
    buildnumbers,
    buildnumbersCanLoadMore,
    buildnumbersTotal,
    projectAssignment
  } = state.project.single;

  return {
    buildnumbers,
    buildnumbersCanLoadMore,
    buildnumbersTotal,
    loading: buildnumbersLoading(state),
    objectTypeOptions: objectTypeOptions(state),
    project: projectAssignment,
    price: objectTypePrice(state),
    canCreateObjectAssignment: canCreateObjectAssignment(state)
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ProjectDetailBuildnumbersComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getBuildnumbers: (
    projectId: string,
    objectTypeId: string,
    filterByActive?: ActiveFilter,
    skip?: number
  ) =>
    dispatch(
      ProjectsThunks.Projects.getBuildnumbers(
        projectId,
        objectTypeId,
        25,
        filterByActive,
        skip
      )
    ),
  navigate: (path: string) => dispatch(push(path)),
  toggleAddBuildnumbers: (visible: boolean) =>
    dispatch(ProjectsActions.Buildnumbers.toggleAddBuildnumbers(visible)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type ProjectDetailBuildnumbersContainerProps = StateProps &
  DispatchProps;
export const ProjectDetailBuildnumbersContainer = connect<
  StateProps,
  DispatchProps,
  ProjectDetailBuildnumbersComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailBuildnumbersComponent);
