import { FolderTreeEntityType } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { EmployeeDossierContainerProps } from "@haywork/modules/settings";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import { FC, memo } from "react";

export interface EmployeeDossierComponentProps {}
type Props = EmployeeDossierComponentProps & EmployeeDossierContainerProps;

export const EmployeeDossierComponent: FC<Props> = memo(
  ({ selectedEmployee, linkedFolderTreeId }) => {
    return (
      <ErrorBoundary>
        <Dossier
          parentId={selectedEmployee.id}
          id={linkedFolderTreeId}
          subject={selectedEmployee.displayName}
          entityType={FolderTreeEntityType.Employee}
          addNotes={false}
        />
      </ErrorBoundary>
    );
  }
);
