import { GenderType } from "@haywork/api/event-center";
import {
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressType,
  EmailAddressTypeOption,
  Gender,
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberTypeOption,
  RelationGroupSnapShot,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { NameUtil, RelationUtil } from "@haywork/util";
import first from "lodash-es/first";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { RelationValues } from "./relation.component";

const styles = require("../new-entity.component.scss");

interface Props extends RelationValues {
  titleSuggestions: ContactPersonTitleSuggestionOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  phoneNumberTypes: PhoneNumberTypeOption[];
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
  relationGroups: RelationGroupSnapShot[];
  onSubmit: (values: FormReturnValue) => void;
  form: (form: FormReference) => void;
}
interface State {
  titles: ContactPersonTitleSuggestionOption[];
  salutation: string;
}

@CSSModules(styles, { allowMultiple: true })
export class PersonFormComponent extends React.Component<Props, State> {
  private formControls: FormControls;
  private phoneNumbersFormControls: FormControls;
  private emailAddressesFormControls: FormControls;

  constructor(props) {
    super(props);

    let gender: Gender;
    switch (this.props.gender) {
      case GenderType.Female:
        gender = Gender.Female;
        break;
      case GenderType.Male:
        gender = Gender.Male;
        break;
      case GenderType.Other:
        gender = Gender.Other;
        break;
      default:
        gender = Gender.Unknown;
        break;
    }

    const titles = this.filterTitleSuggestions(
      this.props.titleSuggestions,
      gender
    );
    const title =
      gender === Gender.Unknown || gender === Gender.Other
        ? ""
        : first(titles).displayName;
    const emailAddresses = !!this.props.email
      ? [{ address: this.props.email, type: EmailAddressType.Home }]
      : [];
    const phoneNumbers: PhoneNumber[] = [];
    if (!!this.props.telephone)
      phoneNumbers.push({
        type: PhoneNumberType.Home,
        number: this.props.telephone,
      });
    if (!!this.props.mobilePhone)
      phoneNumbers.push({
        type: PhoneNumberType.Mobile,
        number: this.props.mobilePhone,
      });
    const nameSegments = !!this.props.name ? this.props.name.split(" ") : [];
    const { firstName, middleName, lastName } =
      this.mapNameSegments(nameSegments);

    this.state = {
      titles,
      salutation: RelationUtil.getFormalAppelation(
        true,
        gender,
        firstName,
        middleName,
        lastName
      ),
    };

    this.formControls = {
      gender: {
        value: gender,
        onChange: (ref) => {
          const titles = this.filterTitleSuggestions(
            this.props.titleSuggestions,
            ref.value
          );
          const title =
            ref.value === Gender.Unknown || ref.value === Gender.Other
              ? ""
              : first(titles).displayName;
          this.setState({
            titles,
          });

          return {
            title,
          };
        },
      },
      title: { value: title },
      firstName: {
        value: firstName,
        onChange: (ref) => {
          if (ref && ref.value) {
            return { nameLetters: this.formatNameLetters(ref.value) };
          }
        },
      },
      middleName: { value: middleName },
      lastName: { value: lastName },
      nameLetters: { value: "" },
      useFormalAppelation: { value: true },
      linkedRelationGroups: { value: [] },
      emailAddresses: {
        value: emailAddresses,
      },
      phoneNumbers: {
        value: phoneNumbers,
      },
      address: { value: this.props.address || "" },
    };

    this.phoneNumbersFormControls = {
      number: { value: "", validators: [Validators.telephone()] },
      type: { value: PhoneNumberType.Mobile },
    };

    this.emailAddressesFormControls = {
      address: { value: "", validators: [Validators.email()] },
      type: { value: EmailAddressType.Home },
    };

    this.updateFormalAppelation = this.updateFormalAppelation.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.formChange = this.formChange.bind(this);
  }

  public render() {
    return (
      <Form
        name="create-simple-relation-person"
        formControls={this.formControls}
        form={this.props.form}
        onSubmit={this.onSubmit}
        onChange={this.formChange}
      >
        <h2 styleName="header">
          <ResourceText resourceKey="simpleRelationPersonHeader" />
        </h2>

        <div className="form__row">
          <Input.RadioGroup name="gender" asButtonList>
            <Input.Radio
              label="genderMale"
              value={Gender.Male}
              data-cy="CY-genderMaleOption"
            />
            <Input.Radio
              label="genderFemale"
              value={Gender.Female}
              data-cy="CY-genderFemaleOption"
            />
            <Input.Radio
              label="genderOther"
              value={Gender.Other}
              data-cy="CY-genderOtherOption"
            />
          </Input.RadioGroup>
        </div>

        <div className="form__row">
          <label htmlFor="title">
            <ResourceText resourceKey="title" />
          </label>
          <Input.NewSelect
            name="title"
            addEmptyOption
            emptyOptionLabel="makeAChoiceExtended"
            values={this.state.titles}
            displayProp="displayName"
            valuesProp="displayName"
          />
        </div>

        <div className="form__row">
          <div className="form__group stretch">
            <div className="column">
              <label htmlFor="firstName">
                <ResourceText resourceKey="simpleRelationFirstName" />
              </label>
              <Input.Text
                name="firstName"
                data-cy="CY-firstName"
                placeholder="simpleRelationFirstNamePlaceholder"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="middleName">
                <ResourceText resourceKey="simpleRelationMiddleName" />
              </label>
              <Input.Text
                name="middleName"
                data-cy="CY-middleName"
                placeholder="simpleRelationMiddleNamePlaceholder"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="lastName">
            <ResourceText resourceKey="simpleRelationLastName" />
          </label>
          <Input.Text
            name="lastName"
            data-cy="CY-lastName"
            placeholder="simpleRelationLastNamePlaceholder"
          />
        </div>

        <div className="form__row">
          <label htmlFor="useFormalAppelation">
            <ResourceText resourceKey="salutationQuickAdd" />
          </label>
          <Input.RadioGroup name="useFormalAppelation" asButtonList>
            <Input.Radio label="formal" value={true} />
            <Input.Radio label="informal" value={false} />
          </Input.RadioGroup>
        </div>

        <div className="form__row">{this.state.salutation}</div>

        <h2 styleName="header">
          <ResourceText resourceKey="simpleRelationLinkedRelationGroup" />
        </h2>

        <div className="form__row">
          <Input.RelationGroupQueryV2
            name="linkedRelationGroups"
            placeholder="simpleRelationLinkedRelationGroupPlaceholder"
            relationGroups={this.props.relationGroups}
          />
        </div>

        <h2 styleName="header">
          <ResourceText resourceKey="simpleRelationContactHeader" />
        </h2>

        <div className="form__row">
          <label>
            <ResourceText resourceKey="phoneNumbers" />
          </label>
          <Input.Array
            name="phoneNumbers"
            formControls={this.phoneNumbersFormControls}
            max={4}
            className="form__group stretch"
          >
            <div className="column" styleName="array__input">
              <Input.Text
                name="number"
                placeholder="phoneNumber"
                fireAllChanges
              />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="array__type">
              <Input.NewSelect
                name="type"
                values={this.props.phoneNumberTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </Input.Array>
        </div>

        <div className="form__row">
          <label>
            <ResourceText resourceKey="emailAddresses" />
          </label>
          <Input.Array
            name="emailAddresses"
            formControls={this.emailAddressesFormControls}
            max={4}
            className="form__group stretch"
          >
            <div className="column" styleName="array__input">
              <Input.Text name="address" placeholder="address" fireAllChanges />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="array__type">
              <Input.NewSelect
                name="type"
                values={this.props.emailAddressTypes}
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </Input.Array>
        </div>

        <div className="form__row">
          <label>
            <ResourceText resourceKey="visitAddress" />
          </label>
          <Input.LocationQueryV2
            name="address"
            countries={this.props.countries}
            countryIso2={this.props.countryIso2}
            culture={this.props.culture}
            showManualAddressOnAddress={true}
          />
        </div>
      </Form>
    );
  }

  private onSubmit(values: FormReturnValue) {
    values.salutation = this.state.salutation;
    this.props.onSubmit(values);
  }

  private formChange(values: FormReturnValue) {
    this.updateFormalAppelation(
      values.useFormalAppelation,
      values.gender,
      values.firstName,
      values.middleName,
      values.lastName
    );
  }

  private formatNameLetters(names: string) {
    let nameLetters = "";
    const splitNames = names.split(" ");
    for (let i = 0; i < splitNames.length; i++) {
      const name = splitNames[i];
      if (name) {
        nameLetters = nameLetters + name[0] + ".";
      }
    }
    return nameLetters;
  }

  private filterTitleSuggestions(
    suggestions: ContactPersonTitleSuggestionOption[],
    gender: Gender
  ): ContactPersonTitleSuggestionOption[] {
    return suggestions.filter((suggestion) => {
      switch (gender) {
        case Gender.Male:
          return suggestion.usedForMales;
        case Gender.Female:
          return suggestion.usedForFemales;
        default:
          return suggestion.usedForUnknownGender;
      }
    });
  }

  private updateFormalAppelation(
    formal: boolean,
    gender: Gender,
    firstName: string,
    middleName: string,
    lastName: string
  ) {
    this.setState({
      salutation: RelationUtil.getFormalAppelation(
        formal,
        gender,
        firstName,
        middleName,
        lastName
      ),
    });
  }

  private mapNameSegments(nameSegments: string[]): {
    firstName: string;
    middleName: string;
    lastName: string;
  } {
    let firstName = "",
      middleName = "",
      lastName = "";

    if (nameSegments.length > 1) {
      firstName = nameSegments[0] || "";
      middleName = nameSegments
        .filter((segment, idx) => idx > 0 && NameUtil.isDutchPrefix(segment))
        .join(" ");
      lastName = nameSegments
        .filter((segment, idx) => idx > 0 && !NameUtil.isDutchPrefix(segment))
        .join(" ");
    }

    if (nameSegments.length === 1) {
      lastName = nameSegments[0] || "";
    }

    return {
      firstName,
      middleName,
      lastName,
    };
  }
}
