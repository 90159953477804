import {
  InvoiceOrderByField,
  InvoiceSnapShot,
  InvoiceStatusOption,
  SortOrder,
} from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { Dispatch, InvoiceThunk } from "@haywork/middleware";
import { MappedCheckboxValue } from "@haywork/modules/filter";
import {
  InvoiceOverviewComponent,
  InvoiceOverviewComponentProps,
} from "@haywork/modules/invoice";
import {
  AppState,
  InvoiceFiltering,
  InvoiceOverviewActions,
  PagedResults,
} from "@haywork/stores";
import { SimpleLabelOriginal } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  invoices: PagedResults<InvoiceSnapShot>;
  order: SortOrder;
  orderBy: InvoiceOrderByField;
  invoiceStatuses: MappedCheckboxValue[];
  filters: InvoiceFiltering;
  selectedFilters: SimpleLabelOriginal[];
}
interface DispatchProps {
  getInvoices: (init?: boolean, take?: number) => void;
  setInvoicesOrdering: (
    order: SortOrder,
    orderBy: InvoiceOrderByField,
    reload?: boolean
  ) => void;
  deleteInvoice: (id: string, undeleteCallback: () => void) => void;
  unDeleteInvoice: (id: string) => void;
  archiveInvoice: (id: string) => void;
  unArchiveInvoice: (id: string) => void;
  deleteInvoiceFromList: (id: string) => void;
  archiveInvoiceFromList: (id: string) => void;
  unArchiveInvoiceFromList: (id: string) => void;
  invoiceFilterChange: (filters: InvoiceFiltering) => void;
  navigate: (path: string) => void;
  clearAllFilters: () => void;
  exportInvoices: () => void;
}

const mapInvoiceStatuses = (
  invoiceStatuses: InvoiceStatusOption[]
): MappedCheckboxValue[] => {
  return invoiceStatuses.map((status) => ({
    label: status.displayName,
    value: status.value,
  }));
};

const mapFiltersToArray = (
  filters: InvoiceFiltering
): SimpleLabelOriginal[] => {
  const selectedFilters = [];
  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const value = filters[key];

      switch (key) {
        case "filterByActive":
          value.map((v) => {
            selectedFilters.push({
              label: `invoiceActiveFilter.${v}`,
              value: v,
              filter: "filterByActive",
            });
          });
          break;
        case "statusFilter":
          value.map((v) => {
            selectedFilters.push({
              label: `invoiceStatuses.${v}`,
              value: v,
              filter: "statusFilter",
            });
          });
          break;
        default:
          break;
      }
    }
  }

  if (!!filters.invoiceDateFrom && !!filters.invoiceDateUntil) {
    const label = [
      intlContext.formatDate(filters.invoiceDateFrom),
      intlContext.formatDate(filters.invoiceDateUntil),
    ].join(" / ");
    selectedFilters.push({ label, value: label, filter: "invoiceDate" });
  } else if (!!filters.invoiceDateFrom) {
    const label = intlContext.formatDate(filters.invoiceDateFrom);
    selectedFilters.push({ label, value: label, filter: "invoiceDate" });
  } else if (!!filters.invoiceDateUntil) {
    const label = intlContext.formatDate(filters.invoiceDateUntil);
    selectedFilters.push({ label, value: label, filter: "invoiceDate" });
  }

  if (!!filters.overdueFrom && !!filters.overdueUntil) {
    const label = [
      intlContext.formatDate(filters.overdueFrom),
      intlContext.formatDate(filters.overdueUntil),
    ].join(" / ");
    selectedFilters.push({ label, value: label, filter: "overdue" });
  } else if (!!filters.overdueFrom) {
    const label = intlContext.formatDate(filters.overdueFrom);
    selectedFilters.push({ label, value: label, filter: "overdue" });
  } else if (!!filters.overdueUntil) {
    const label = intlContext.formatDate(filters.overdueUntil);
    selectedFilters.push({ label, value: label, filter: "overdue" });
  }

  return selectedFilters;
};

const mapStateToProps = <StateProps, InvoiceOverviewComponentProps>(
  state: AppState
) => {
  const { invoices, order, orderBy, filters } = state.invoice.overview;
  const { invoiceStatuses } = state.main.mastertable.kolibri;

  return {
    invoices,
    order,
    orderBy,
    invoiceStatuses: mapInvoiceStatuses(invoiceStatuses),
    filters,
    selectedFilters: mapFiltersToArray(filters),
  };
};

const mapDispatchToProps = <DispatchProps, InvoiceOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getInvoices: (init?: boolean, take?: number) =>
    dispatch(InvoiceThunk.getInvoices(init, take)),
  setInvoicesOrdering: (
    order: SortOrder,
    orderBy: InvoiceOrderByField,
    reload?: boolean
  ) => dispatch(InvoiceThunk.setInvoicesOrdering(order, orderBy, reload)),
  deleteInvoice: (id: string, undeleteCallback: () => void) =>
    dispatch(InvoiceThunk.deleteInvoice(id, undeleteCallback)),
  unDeleteInvoice: (id: string) => dispatch(InvoiceThunk.unDeleteInvoice(id)),
  archiveInvoice: (id: string) => dispatch(InvoiceThunk.archiveInvoice(id)),
  unArchiveInvoice: (id: string) => dispatch(InvoiceThunk.unArchiveInvoice(id)),
  deleteInvoiceFromList: (id: string) =>
    dispatch(InvoiceOverviewActions.deleteInvoiceFromList({ id })),
  archiveInvoiceFromList: (id: string) =>
    dispatch(InvoiceOverviewActions.archiveInvoiceFromList({ id })),
  unArchiveInvoiceFromList: (id: string) =>
    dispatch(InvoiceOverviewActions.unArchiveInvoiceFromList({ id })),
  invoiceFilterChange: (filters: InvoiceFiltering) =>
    dispatch(InvoiceThunk.invoiceFilterChange(filters)),
  navigate: (path: string) => dispatch(push(path)),
  clearAllFilters: () => dispatch(InvoiceThunk.clearAllFilters()),
  exportInvoices: () => dispatch(InvoiceThunk.exportInvoices()),
});

export type InvoiceOverviewContainerProps = StateProps & DispatchProps;
export const InvoiceOverviewContainer = connect<
  StateProps,
  DispatchProps,
  InvoiceOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceOverviewComponent);
