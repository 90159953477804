import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import { MAINROUTES } from "@haywork/constants";

const styles = require("./api-error.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface ErrorApiErrorComponentProps {}
interface ErrorApiErrorComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ErrorApiErrorComponent extends React.Component<ErrorApiErrorComponentProps, ErrorApiErrorComponentState> {
  public render() {
    return (
      <div styleName="api-error">
        <div styleName="api-error__inner">
          <div styleName="icon" />
          <div styleName="body">
            <ResourceText resourceKey="couldNotLoadDetailBody" asHtml />
            <p>
              <Link to={route(MAINROUTES.DASHBOARD.URI, {})}>
                <ResourceText resourceKey="couldNotLoadDetailLink" />
              </Link>
              <ResourceText resourceKey="couldNotLoadDetailLinkPost" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
