import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import {
  RelationRoleOption,
  RelationRole,
  AssignmentType,
} from "@haywork/api/kolibri";
import { DropdownFilter, Filter } from "@haywork/modules/filter";
import {
  ResourceText,
  MailTo,
  AuthorizationWidgets,
} from "@haywork/modules/shared";
import { ExtendedLinkedRelation } from "./detail-network.component";
import { RelationUtil } from "@haywork/util";
import {
  WidgetEntityType,
  WidgetEntityLocation,
} from "@haywork/api/authorization";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Input } from "@haywork/modules/form";

interface ListLinkItemComponentProps {
  contact: ExtendedLinkedRelation;
  preppedForRemove: boolean;
  relationRoles: RelationRoleOption[];
  forSale: boolean;
  assignmentId: string;
  selectedContactLinks: ExtendedLinkedRelation[];
  onLinkTypeChange: (relationRole: RelationRole) => void;
  onSelect: (contact: ExtendedLinkedRelation) => void;
  onDeselect: (contact: ExtendedLinkedRelation) => void;
}
interface ListLinkItemComponentState {}

const styles = require("./link-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class ListLinkItemComponent extends React.Component<
  ListLinkItemComponentProps,
  ListLinkItemComponentState
> {
  constructor(props) {
    super(props);

    this.onFilterChangedHandler = this.onFilterChangedHandler.bind(this);
    this.renderAddressLine = this.renderAddressLine.bind(this);
    this.renderContactLine = this.renderContactLine.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  public render() {
    const { contact, selectedContactLinks, preppedForRemove } = this.props;
    const { avatarUrl, letterAvatar, displayName, relationRole } = contact;
    const avatarStyles = !!avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(avatarUrl)})` }
      : null;
    const contactLinkStyle = classNames("contact-link", {
      "prepped-for-remove": preppedForRemove,
    });
    const selectedIds = selectedContactLinks.map(
      (linkedContact) => linkedContact.id
    );

    return (
      <div styleName={contactLinkStyle}>
        <FeatureSwitch feature="FILTER_NETWORK">
          <div styleName="item__checkbox" onClick={(e) => e.stopPropagation()}>
            <Input.CheckBox
              name="select-search-assignment"
              onChange={this.handleCheckboxChange}
              asSingleInput
              value={selectedIds.includes(contact.id)}
            />
          </div>
        </FeatureSwitch>
        <div styleName="item__avatar">
          <div styleName="avatar" style={avatarStyles}>
            {!avatarStyles && <div styleName="letters">{letterAvatar}</div>}
          </div>
        </div>
        <div styleName="item__body">
          <Filter changeFilter={this.onFilterChangedHandler}>
            <strong>{displayName}</strong>
            &nbsp;
            <ResourceText resourceKey="is" />
            <span
              className="dropdown-filter-wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <DropdownFilter
                name="linkTypeFilter"
                inline
                values={RelationUtil.mapRelationRoles(
                  this.props.relationRoles,
                  AssignmentType.Object,
                  this.props.forSale
                )}
                value={relationRole}
              />
            </span>
          </Filter>
        </div>

        {/* Authorization widgets */}
        <FeatureSwitch feature="APP_XCHANGE">
          <AuthorizationWidgets
            type={WidgetEntityType.ObjectAssignment}
            location={WidgetEntityLocation.Network}
            id={this.props.assignmentId}
          />
        </FeatureSwitch>

        {this.renderContactLine()}
        {this.renderAddressLine()}
      </div>
    );
  }

  private onFilterChangedHandler(values: any) {
    if (values.linkTypeFilter !== this.props.contact.relationRole) {
      this.props.onLinkTypeChange(values.linkTypeFilter);
    }
  }

  private renderAddressLine(): React.ReactElement<HTMLDivElement> {
    /*const { streetNameAndNumber, locality } = this.props.contact;
    if (!streetNameAndNumber && !locality) return null;

    return <div styleName="item__address">
      {streetNameAndNumber && <div styleName="streetname">{streetNameAndNumber}</div>}
      {locality && <div styleName="locality">{locality}</div>}
    </div>;*/
    return null;
  }

  private renderContactLine(): React.ReactElement<HTMLDivElement> {
    const { phone, phoneMobile, phoneWork, email } = this.props.contact;
    if (!phone && !phoneMobile && !phoneWork && !email) return null;
    const phones = [phone, phoneMobile, phoneWork];
    const cleanedPhones = phones.filter((phone) => !!phone);

    return (
      <div styleName="item__contact">
        {cleanedPhones.length > 0 && (
          <div styleName="phone">{cleanedPhones[0]}</div>
        )}
        {email && (
          <div styleName="email">
            <MailTo email={email} />
          </div>
        )}
      </div>
    );
  }

  private handleCheckboxChange(checked: boolean) {
    if (checked) {
      this.props.onSelect(this.props.contact);
    } else {
      this.props.onDeselect(this.props.contact);
    }
  }
}
