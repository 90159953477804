import { WidgetEntityType } from "@haywork/api/authorization";
import { FolderTreeEntityType } from "@haywork/middleware";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ObjectTypesDetailDossierContainerProps } from "@haywork/modules/object-types";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";

export interface ObjectTypesDetailDossierComponentProps {}
interface State {}
type Props = ObjectTypesDetailDossierComponentProps &
  ObjectTypesDetailDossierContainerProps;

export class ObjectTypesDetailDossierComponent extends React.Component<
  Props,
  State
> {
  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.currentType.id,
      WidgetEntityType.ObjectTypeAssignment
    );
  }

  public render() {
    const { id } = this.props.currentType;
    return (
      <ErrorBoundary>
        <Dossier
          parentId={id}
          id={this.props.linkedFolderTreeId}
          subject={this.props.displayName}
          entityType={FolderTreeEntityType.ObjectTypeAssignment}
          addNotes={true}
        />
      </ErrorBoundary>
    );
  }
}
