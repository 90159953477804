import * as React from "react";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { RouteUtil } from "@haywork/util";
import { APPXCHANGEROUTES, ASSIGNMENTROUTES } from "@haywork/constants";
import * as CSSModules from "react-css-modules";
import { WidgetEntityType, WidgetSnapShot } from "@haywork/api/authorization";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./style.scss");

interface Props {
  entityId: string;
  widget: WidgetSnapShot;
  type: WidgetEntityType;
}

export const AuthorizationWidgetsMenuButtonComponent = CSSModules(styles, {
  allowMultiple: true,
})(({ entityId, widget, type }: Props) => {
  const canDisplay = useMemo(() => {
    if (
      type !== WidgetEntityType.AppXchange &&
      widget?.entityTypes?.includes(type)
    ) {
      return true;
    }

    return entityId === widget?.linkedAppClient?.id;
  }, [entityId, type, widget]);

  const icon = useMemo(
    () => <i className={`fa-fw ${widget.icon || "fal fa-chevron-right"}`} />,
    [widget]
  );

  const path = useMemo(() => {
    switch (type) {
      case WidgetEntityType.ObjectAssignment: {
        return ASSIGNMENTROUTES.WIDGET_PAGE.URI;
      }
      default: {
        return APPXCHANGEROUTES.WIDGET_PAGE.URI;
      }
    }
  }, [type]);

  return !canDisplay ? null : (
    <NavLink
      styleName="menu-item"
      activeClassName="active"
      to={route(path, {
        id: entityId,
        widgetId: widget.id,
      })}
    >
      {icon}
      <span styleName="text">{widget.displayName}</span>
    </NavLink>
  );
});
