import {
  ActiveFilter,
  AssignmentSnapShot,
  AssignmentType,
  LinkedAssignment,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
} from "@haywork/constants";
import { InputComponentProps } from "@haywork/modules/form";
import { Assignment } from "@haywork/request";
import { RouteUtil } from "@haywork/util";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { BaseQueryComponent, QueryResultReturnValue } from "../base.component";
import AcquisitionItem from "./components/acquisition-item";
import Item from "./components/item";
import { Pill } from "./pill.component";

const route = RouteUtil.mapStaticRouteValues;

interface AssigmentQueryComponentProps {
  realEstateGroups?: RealEstateGroup[];
  multiple?: boolean;
  placeholder?: string;
  onNavigateToAssignment?: (path: string) => void;
  supportForRent?: boolean;
  supportForSale?: boolean;
  numberOfResults?: number;
  filterByActive?: ActiveFilter;
  filterByAssignmentTypes: AssignmentType[];
}
interface AssigmentQueryComponentState {
  value: LinkedAssignment[];
  placeholder: string;
}

export class AssigmentQueryComponent extends React.Component<
  AssigmentQueryComponentProps & InputComponentProps,
  AssigmentQueryComponentState
> {
  constructor(props) {
    super(props);

    let value = isArray(this.props.value)
      ? this.props.value
      : [this.props.value];
    value = value.filter((v) => !!v);

    this.state = {
      value,
      placeholder: this.props.placeholder || "searchAssignmentPlaceholder",
    };

    this.renderSelectedValuePill.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedValue = this.renderSelectedValue.bind(this);
    this.renderDisabledOption = this.renderDisabledOption.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onAssignmentClickHandler = this.onAssignmentClickHandler.bind(this);
    this.onRemoveAssignmentClickHandler = this.onRemoveAssignmentClickHandler.bind(
      this
    );
  }

  public render() {
    const take = this.props.numberOfResults || 25;

    return (
      <div className="assignment__query">
        <BaseQueryComponent
          {...this.props}
          {...this.state}
          hasCustomPill
          asyncValues={(value) =>
            Assignment.search(
              value,
              take,
              this.props.realEstateGroups,
              this.props.supportForSale,
              this.props.supportForRent,
              this.props.filterByActive,
              this.props.filterByAssignmentTypes
            )
          }
          optionValue={this.renderOptionValue}
          selectedValue={this.renderSelectedValue}
          disableOption={this.renderDisabledOption}
          onChange={this.onChangeHandler}
          data-cy={this.props["data-cy"]}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: AssigmentQueryComponentProps & InputComponentProps
  ) {
    if (!nextProps) return;
    let value = isArray(nextProps.value) ? nextProps.value : [nextProps.value];
    value = value.filter((v) => !!v);

    this.setState({ value });
  }

  private onChangeHandler(value: AssignmentSnapShot[]) {
    this.fireOnChange(value);
  }

  private fireOnChange(value: any[]) {
    const mappedValues = this.prepareValues(value);
    const mappedValue = !!this.props.multiple
      ? mappedValues
      : first(mappedValues);

    this.props.onChange(mappedValue);
  }

  private prepareValues(values: AssignmentSnapShot[]): LinkedAssignment[] {
    return values.map((value) => {
      const {
        id,
        displayName,
        hasCadastres,
        forRent,
        forSale,
        assignmentPhase,
        availabilityStatus,
        typeOfAssignment,
        publicReference,
      } = value;

      const returnValue: LinkedAssignment = {
        id,
        displayName,
        hasCadastres,
        forRent,
        forSale,
        assignmentPhase,
        availabilityStatus,
        typeOfAssignment,
        publicReference,
      };

      return returnValue;
    });
  }

  private renderOptionValue(
    assignment: AssignmentSnapShot,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    const { typeOfAssignment } = assignment;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        return (
          <div className="query-component__option">
            <AcquisitionItem snapshot={assignment} />
          </div>
        );
      }
      default: {
        return (
          <div className="query-component__option">
            <Item snapshot={assignment} query={query} />
          </div>
        );
      }
    }
  }

  private renderSelectedValue(
    value: AssignmentSnapShot
  ): QueryResultReturnValue<LinkedAssignment> {
    const template = this.renderSelectedValuePill(value);

    return {
      value,
      template,
    };
  }

  private renderDisabledOption(
    value: AssignmentSnapShot,
    values: AssignmentSnapShot[]
  ) {
    const ids = values.map((v) => v.id);
    return ids.indexOf(value.id) !== -1;
  }

  private renderSelectedValuePill(
    assignment: AssignmentSnapShot
  ): React.ReactElement<HTMLDivElement> {
    return (
      <Pill
        assignment={assignment}
        onAssignmentClick={this.onAssignmentClickHandler}
        onRemoveAssignmentClick={this.onRemoveAssignmentClickHandler}
        data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Pill`}
      />
    );
  }

  private onAssignmentClickHandler(assignment: AssignmentSnapShot) {
    if (!this.props.onNavigateToAssignment) return;

    const { id, typeOfAssignment } = assignment;
    let path = "";
    switch (typeOfAssignment) {
      case AssignmentType.Acquisition: {
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.AcquisitionObject: {
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.ObjectType: {
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      }
      case AssignmentType.Project: {
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      }
      default: {
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
      }
    }

    this.props.onNavigateToAssignment(path);
  }

  private onRemoveAssignmentClickHandler(
    event: React.MouseEvent<HTMLDivElement>,
    assignment: AssignmentSnapShot
  ) {
    event.stopPropagation();
    const value = this.state.value.filter((v) => v.id !== assignment.id);

    this.setState({ value });
    this.fireOnChange(value);
  }
}
