import * as React from "react";
import { FC, memo, useState, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@haywork/modules/modal";
import Button from "@haywork/components/ui/button";
import { useIntl } from "react-intl";
import I18n from "@haywork/components/i18n";
import { Input } from "@haywork/modules/form";

const styles = require("./style.scss");

type Props = {
  visible: boolean;
  mediaPartnerName: string;
  onClose: () => void;
  onDeactivate: (force: boolean) => void;
};

export const ConfirmDeactivateModalComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, mediaPartnerName, onClose, onDeactivate }) => {
      const [valid, setValid] = useState(false);
      const [confirmValue, setConfirmValue] = useState("");
      const intl = useIntl();

      const stringToMatch = useMemo(() => {
        return intl.formatMessage({
          id: "mediaPartner.deactivate.stringToMatch",
          defaultMessage: "DEACTIVATE"
        });
      }, [intl]);

      const onConfirmValueChangeHandler = useCallback(
        (value) => {
          setConfirmValue(value);

          if (value === stringToMatch) {
            setValid(true);
          } else {
            setValid(false);
          }
        },
        [setValid, setConfirmValue, stringToMatch]
      );

      const resetState = useCallback(() => {
        setValid(false);
        setConfirmValue("");
      }, [setValid, setConfirmValue]);

      const onCloseHandler = useCallback(() => {
        resetState();
        onClose();
      }, [onClose, resetState]);

      const deactivate = useCallback(() => {
        if (!valid) return;
        resetState();
        onDeactivate(true);
      }, [resetState, valid, onDeactivate]);

      return (
        <Modal visible={visible} onClose={onCloseHandler}>
          <ModalHeader title="mediaPartner.deactivate.modalHeader" close />
          <ModalBody noPadding>
            <div styleName="body">
              <I18n
                value="mediaPartner.deactivate.body"
                asHtml
                values={{ mediaPartnerName }}
              />
            </div>
            <div styleName="warning">
              <I18n value="mediaPartner.deactivate.warning" asHtml />
            </div>
            <div className="form__row" styleName="form">
              <label htmlFor="confirm">
                <I18n
                  value="mediaPartner.deactivate.label"
                  asHtml
                  values={{ stringToMatch }}
                />
              </label>
              <Input.Text
                name="confirm"
                asSignleInput
                fireAllChanges
                value={confirmValue}
                onChange={onConfirmValueChangeHandler}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              label="mediaPartner.deactivate.submit"
              disabled={!valid}
              onClick={deactivate}
            />
          </ModalFooter>
        </Modal>
      );
    }
  )
);
