import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

import { AssignmentSnapShot, LinkedAssignment, LinkedRelation, RelationTypeOption, RelationType } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { RELATIONROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

import { RelationWidgetLinkedItemComponent } from "./linked-item.component";

const styles = require("./widget.component.scss");

export interface RelationWidgetItem {
  avatarUrl: string;
  letterAvatar: string;
  displayName: string;
  relationType: string;
  typeOfRelation: RelationType;
  phone: string;
  email: string;
  id: string;
}

interface RelationWidgetComponentProps {
  relationWidgetItems: RelationWidgetItem[];
  headerResourceKey: string;
  emptyStateTitleResourceKey: string;
  emptyStateDescriptionResourceKey: string;
  hideEmptyStateImage: boolean;
  onNavigate: (url: string) => void;
}

interface RelationWidgetComponentState { }

@CSSModules(styles, { allowMultiple: true })
export class RelationWidgetComponent extends React.Component<RelationWidgetComponentProps, RelationWidgetComponentState> {
  public render() {
    return <div styleName="relationWidget">
      <h3 styleName="title">
        <ResourceText resourceKey={this.props.headerResourceKey} />
      </h3>
      { /* No relations, show empty state */}
      {!this.props.relationWidgetItems || (this.props.relationWidgetItems && this.props.relationWidgetItems.length === 0) ?
        <div styleName="emptyState">
          {this.props.hideEmptyStateImage ? "" :
            <div styleName="icon"></div>
          }
          <div styleName="text">
            <h2><ResourceText resourceKey={this.props.emptyStateTitleResourceKey} /></h2>
            <p><ResourceText resourceKey={this.props.emptyStateDescriptionResourceKey} /></p>
          </div>
        </div>
        : ""
      }
      { /* show relations */}
      { this.props.relationWidgetItems && this.props.relationWidgetItems.length > 0 &&
        this.props.relationWidgetItems.map((relationWidgetItem, idx) =>
          <ErrorBoundary key={ idx }>
            <RelationWidgetLinkedItemComponent
              relationWidgetItem={ relationWidgetItem }
              onNavigate={ this.props.onNavigate } />
          </ErrorBoundary>
        )
      }
    </div>;
  }
}

export const convertToRelationWidgetItemFromLinkedRelation = (linkedRelation: LinkedRelation, relationTypes: RelationTypeOption[]): RelationWidgetItem => {
  if (!linkedRelation) {
    return;
  }

  return {
    avatarUrl: linkedRelation.avatarUrl,
    letterAvatar: linkedRelation.letterAvatar,
    displayName: linkedRelation.displayName,
    email: linkedRelation.email,
    phone: linkedRelation.phone,
    relationType: relationTypes ? relationTypes.find((relationType) => relationType.value === linkedRelation.typeOfRelation).displayName : "",
    typeOfRelation: linkedRelation.typeOfRelation,
    id: linkedRelation.id
  };
};
