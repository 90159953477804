import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { AcquisitionFilters } from "./list.reducer";
import { SimpleLabelOriginal } from "@haywork/util";

export enum listActions {
  SetAssignments = "Acquisition.List.SetAssignments",
  SetAssignmentsStatus = "Acquisition.List.SetAssignmentsStatus",
  SetFilters = "Acquisition.List.SetFilters",
  ClearFilter = "Acquisition.List.ClearFilter",
  ClearAllFilters = "Acquisition.List.ClearAllFilters",
  UpdateListItem = "Acquisition.List.UpdateListItem",
  DeleteListItem = "Acquisition.List.DeleteListItem"
}

type SetAssignments = {
  type: listActions.SetAssignments;
  assignments: AssignmentSnapShot[];
  total: number;
  reset: boolean;
};
const setAssignments = (
  assignments: AssignmentSnapShot[],
  total: number,
  reset: boolean
) => ({
  type: listActions.SetAssignments,
  assignments,
  total,
  reset
});

type SetAssignmentsStatus = {
  type: listActions.SetAssignmentsStatus;
  assignmentsStatus: string;
};
const setAssignmentsStatus = (assignmentsStatus: string) => ({
  type: listActions.SetAssignmentsStatus,
  assignmentsStatus
});

type SetFilters = {
  type: listActions.SetFilters;
  filters: AcquisitionFilters;
};
const setFilters = (filters: AcquisitionFilters) => ({
  type: listActions.SetFilters,
  filters
});

type ClearFilter = {
  type: listActions.ClearFilter;
  filter: SimpleLabelOriginal;
};
const clearFilter = (filter: SimpleLabelOriginal) => ({
  type: listActions.ClearFilter,
  filter
});

type ClearAllFilters = {
  type: listActions.ClearAllFilters;
};
const clearAllFilters = () => ({
  type: listActions.ClearAllFilters
});

type UpdateListItem = {
  type: listActions.UpdateListItem;
  snapshot: AssignmentSnapShot;
};
const updateListItem = (snapshot: AssignmentSnapShot) => ({
  type: listActions.UpdateListItem,
  snapshot
});

type DeleteListItem = {
  type: listActions.DeleteListItem;
  id: string;
};
const deleteListItem = (id: string) => ({
  type: listActions.DeleteListItem,
  id
});

export type ListActions =
  | SetAssignments
  | SetAssignmentsStatus
  | SetFilters
  | ClearFilter
  | ClearAllFilters
  | UpdateListItem
  | DeleteListItem;
export const Actions = {
  setAssignments,
  setAssignmentsStatus,
  setFilters,
  clearFilter,
  clearAllFilters,
  updateListItem,
  deleteListItem
};
