import { FontStyle, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import first from "lodash-es/first";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { BaseFilter } from "../base/base-filter.component";
import isEqual from "lodash-es/isEqual";

const styles = require("./dropdown.component.scss");

export interface MappedDropdownValue {
  label: string;
  value: any;
}

interface DropdownFilterComponentProps {
  inline?: boolean;
  values: MappedDropdownValue[];
  asArray?: boolean;
  value?: MappedDropdownValue[];
  showCurlyBraces?: boolean;
}
interface DropdownFilterComponentState {
  values: MappedDropdownValue[];
  visible: boolean;
  current: MappedDropdownValue;
}

@CSSModules(styles, { allowMultiple: true })
export class DropdownFilter extends BaseFilter<
  DropdownFilterComponentProps,
  DropdownFilterComponentState
> {
  public static contextTypes = {
    updateField: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    unRegisterField: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    let current = this.props.value ? this.props.value : this.props.values[0];
    if (this.props.value !== undefined) {
      const value = this.props.asArray
        ? first(this.props.value)
        : this.props.value;
      const filtered = this.props.values.filter(
        (val) => JSON.stringify(val.value) === JSON.stringify(value)
      );
      if (filtered.length) current = first(filtered);
    }

    this.state = {
      ...this.state,
      values: this.props.values,
      visible: false,
      current,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onItemClickHandler = this.onItemClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const combined = classNames("dropdown", {
      inline: this.props.inline || false,
      visible: this.state.visible,
    });
    return (
      <div styleName={combined} data-cy={this.props["data-cy"]}>
        <div
          styleName="current"
          onClick={this.onClickHandler}
          id={this.state.current.label}
        >
          {this.props.showCurlyBraces ? (
            <span>
              (
              <ResourceText
                resourceKey={this.state.current.label}
                fontStyle={this.props.inline ? FontStyle.LowerCase : null}
              />
              )
            </span>
          ) : (
            <ResourceText
              resourceKey={this.state.current.label}
              fontStyle={this.props.inline ? FontStyle.LowerCase : null}
            />
          )}
          <i className="fal fa-fw fa-chevron-down" />
        </div>
        <div styleName="foldout">
          {this.state.values.map((val, idx) => (
            <div
              key={idx}
              styleName="item"
              onClick={() => this.onItemClickHandler(val)}
              id={val.label + "dropdown"}
              data-cy={"CY-dropdownItem-" + val.label}
            >
              <ResourceText resourceKey={val.label} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: DropdownFilterComponentProps
  ) {
    if (nextProps.values.length !== this.state.values.length) {
      this.setState({ ...this.state, values: this.props.values });
    }
  }

  public onClickHandler() {
    this.setState({ visible: true });
  }

  public onItemClickHandler(val: MappedDropdownValue) {
    if (isEqual(val, this.state.current))
      return this.setState({ visible: false });

    this.setState({ visible: false, current: val });
    const value = this.props.asArray && !!val.value ? [val.value] : val.value;
    this.context.updateField(this.props.name, value);
  }

  private onClickOutsideHandler() {
    if (!!this.state.visible) this.setState({ visible: false });
  }
}
