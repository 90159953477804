import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./not-supported.component.scss");

interface Props {
  name: string;
  logo: string;
  url: string;
}

export const Browser = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    return (
      <div
        styleName="browser"
        onClick={(event) => {
          event.preventDefault();
          window.open(props.url);
        }}
      >
        <table cellPadding="0" cellSpacing="0" styleName="browser__table">
          <tbody>
            <tr>
              <td styleName="browser__center">
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  styleName="browser__content"
                >
                  <tbody>
                    <tr>
                      <td>
                        <img src={props.logo} styleName="browser__logo" />
                      </td>
                    </tr>
                    <tr>
                      <td>{props.name}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);
