import { KEYCODE } from "@haywork/constants";
import classNames from "classnames";
import isString from "lodash-es/isString";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import { intlContext } from "@haywork/app";

interface TextInputComponentProps {
  icon?: string;
  large?: boolean;
  placeholder?: string;
  translate?: string;
  maxLength?: number;
  allowSubmitOnEnter?: boolean;
  hasPhrases?: boolean;
  fireOnBlur?: boolean;
  inputRef?: (input: HTMLInputElement) => void;
  onTab?: () => void;
}
interface TextInputComponentState {
  value: string;
}

export class TextComponent extends React.Component<
  TextInputComponentProps & InputComponentProps,
  TextInputComponentState
> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      value:
        [null, undefined].indexOf(this.props.value) === -1
          ? this.props.value
          : "",
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const textInputStyle = classNames("input__text", {
      large: this.props.large,
      "has-icon": this.props.icon,
      "has-phrases": this.props.hasPhrases,
    });

    return (
      <div className={textInputStyle}>
        {this.props.icon && <i className={"icon " + this.props.icon} />}
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          maxLength={this.props.maxLength ? this.props.maxLength : 128}
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          onFocus={this.props.onFocus}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          onKeyDown={this.onKeyDownHandler}
          ref={this.bindRef}
          data-cy={this.props["data-cy"]}
          autoComplete="off"
          data-lpignore="true"
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: TextInputComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }

    const value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    if (this.state.value !== value) {
      this.setState({ value });
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.keyCode) {
      case KEYCODE.ENTER:
        if (!this.props.allowSubmitOnEnter) {
          event.preventDefault();
        }
        break;
      case KEYCODE.TAB:
        if (this.props.onTab && !event.shiftKey) {
          event.preventDefault();
          this.props.onTab();
        }
        break;
      default:
        break;
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (!!this.props.fireAllChanges || !!this.props.allowSubmitOnEnter) {
      this.props.onChange(value);
    } else {
      this.setState({ value });
    }
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    let { value } = this.state;
    if (isString(value) && !this.props.disableTrim) value = value.trim();
    if (this.props.fireOnBlur === undefined || this.props.fireOnBlur === true) {
      this.props.onChange(value);
      this.props.onBlur(event);
    }
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
      if (this.props.inputRef) this.props.inputRef(ref);
    }
  }
}
