import { ChannelType } from "@haywork/api/kolibri";

export const CONFIG = {
  DEFAULT_EVENT_HUB_CHANNELS: [
    // ChannelType.ApiUpdates,
    ChannelType.CompanyEntity,
    // ChannelType.CompanyMessaging,
    // ChannelType.CompanyPresence,
    // ChannelType.CompanyPublications,
    // ChannelType.PersonalMessaging,
    ChannelType.PersonalReminders
    // ChannelType.PersonalVoip
  ],
  PURPOSE_PHRASE_ID_INVOICE: 502,
  REMARKS_PHRASE_ID_INVOICE: 501
};

export const ZOHO = {
  ASAP_URI:
    "https://desk.zoho.eu/portal/api/web/inapp/17121000004513267?orgId=20065186153"
};
