import {
  AssignmentType,
  LinkedAssignment,
  RealEstateGroup,
  UpdateAvailabilityAction,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import FormattedText from "@haywork/components/ui/formatted-text";
import PageHeader from "@haywork/components/ui/page-header";
import {
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  REQUEST,
} from "@haywork/constants";
import {
  AssignmentDetailCompleteContainerProps,
  ProlongRentOfferModal,
  RenewRentOfferModal,
  WindowPresentationPrintContainer,
} from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Notes from "@haywork/modules/notes-v3";
import { ConfirmComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentDetailGeneralWidgetsStatusComponent } from "../detail-general/widgets";
import DuplicateModal from "../duplicate-modal";
import Actions, { AssignmentAction } from "./actions";
import {
  AssignmentDetailCompleteAgriculturalSpecificationComponent,
  AssignmentDetailCompleteCommercialSpecificationComponent,
} from "./widgets";
import { AssignmentDetailCompleteResidentialSpecificationComponent } from "./widgets/residential-specifications.component";

const styles = require("./detail-complete.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AssignmentDetailCompleteComponentProps {}
interface State {
  showConfirmDelete: boolean;
  showConfirmRetract: boolean;
  showConfirmRetractSale: boolean;
  showConfirmRetractRent: boolean;
  prolongRentOfferModalVisible: boolean;
  renewRentOfferModalVisible: boolean;
  printVisible: boolean;
  mediaPartnerAlertVisible: boolean;
  archiveAction: AssignmentAction;
  duplicateModalVisible: boolean;
  duplicateAction: AssignmentAction | null;
}
type Props = AssignmentDetailCompleteComponentProps &
  AssignmentDetailCompleteContainerProps;

export type SectionData = {
  type: string;
  prefix: string;
  values: Array<any>;
};
export type Section = {
  title: string;
  data: SectionData[];
  key?: number;
};

export const AssignmentDetailCompleteComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      assignment,
      isInitial,
      printTemplates,
      edit,
      canSendEmail,
      enabledRealEstateGroups,
      canDuplicate,
      saveAssignmentState,
      toggleStatusModal,
      toggleWithdrawModal,
      deleteAssignment,
      updateAvailability,
      navigate,
      archive,
      duplicateAssignment,
      createTaskWithLinkedAssignment,
      createInvoiceWithLinkedAssignment,
      createAgendaItemWithLinkedAssignment,
      createNewEmail,
      unArchive,
      mediaPartners,
      intl,
      notificationSettings,
    }) => {
      const {
        assignmentPhase,
        displayName,
        keyNr,
        keyNote,
        isActive,
        id,
        realEstateGroup,
        forSale,
        publicReference,
        isImported,
        isConfidential,
        advertisementText,
        address,
      } = assignment;

      const readMoreLimit = 1000;

      const [advertisementTextExpanded, setAdvertisementTextExpanded] =
        useState(false);
      const [showConfirmDelete, setShowConfirmDelete] = useState(false);
      const [showConfirmRetract, setShowConfirmRetract] = useState(false);
      const [showConfirmRetractSale, setShowConfirmRetractSale] =
        useState(false);
      const [showConfirmRetractRent, setShowConfirmRetractRent] =
        useState(false);
      const [prolongRentOfferModalVisible, setProlongRentOfferModalVisible] =
        useState(false);
      const [renewRentOfferModalVisible, setRenewRentOfferModalVisible] =
        useState(false);
      const [printVisible, setPrintVisible] = useState(false);
      const [mediaPartnerAlertVisible, setMediaPartnerAlertModalVisible] =
        useState(false);
      const [archiveAction, setArchiveAction] = useState(null);
      const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
      const [duplicateAction, setDuplicateAction] = useState(null);

      const sublocality = useMemo(() => {
        return address?.sublocality?.name;
      }, [address]);

      const rentResourceKey = useMemo(() => {
        return realEstateGroup === RealEstateGroup.Agricultural
          ? "forLease"
          : "forRent";
      }, [RealEstateGroup]);

      const subTitle = useMemo(() => {
        return !!assignmentPhase
          ? assignmentPhase.toString()
          : displayName || "";
      }, [assignmentPhase]);

      const showAdvertisementText = useMemo(() => {
        return advertisementText && advertisementText.length > 0;
      }, [advertisementText]);

      const renderHouseInfo = useCallback((): string => {
        const { listingType, houseSort, houseType } = assignment;
        const listingTypeValue = !!listingType
          ? intl.formatMessage({
              id: `listingTypes.${listingType.toString()}`,
              defaultMessage: `listingTypes.${listingType.toString()}`,
            })
          : null;
        const houseSortValue = !!houseSort
          ? intl.formatMessage({
              id: `houseSorts.${houseSort.toString()}`,
              defaultMessage: `houseSorts.${houseSort.toString()}`,
            })
          : null;
        const houseTypeValue = !!houseType
          ? intl.formatMessage({
              id: `houseTypes.${houseType.toString()}`,
              defaultMessage: `houseTypes.${houseType.toString()}`,
            })
          : null;
        const houseInfo = [listingTypeValue, houseSortValue, houseTypeValue];

        return houseInfo.filter((value) => !!value).join(", ");
      }, [assignment.listingType, assignment.houseSort, assignment.houseType]);

      const onActionHandler = useCallback(async (action: AssignmentAction) => {
        const {
          id,
          displayName,
          linkedProjectAssignment,
          linkedObjectTypeAssignment,
          linkedEmployee,
          linkedVendors,
        } = assignment;
        const linkedAssignment: LinkedAssignment = {
          id,
          displayName,
          typeOfAssignment: AssignmentType.Object,
        };
        const projectId = get(linkedProjectAssignment, "id");
        const objectTypeId = get(linkedObjectTypeAssignment, "id");

        const linkedRelations = [...(linkedVendors || [])];

        switch (action) {
          case AssignmentAction.FinalizeRent:
          case AssignmentAction.FinalizeSale:
          case AssignmentAction.FinalizeSaleDirectly:
          case AssignmentAction.FinalizeRentDirectly:
          case AssignmentAction.ChangeTransactionData:
            return toggleStatusModal(true);
          case AssignmentAction.WithDraw:
            return toggleWithdrawModal(true);
          case AssignmentAction.Publish:
            return navigate(route(ASSIGNMENTROUTES.PUBLISH.URI, { id }));
          case AssignmentAction.Remove:
            setShowConfirmDelete(true);
            break;
          case AssignmentAction.PlaceUnderOffer:
            return onChangeAvailabilityStatus(
              UpdateAvailabilityAction.ToUnderOffer
            );
          case AssignmentAction.PlaceUnderOption:
            return onChangeAvailabilityStatus(
              UpdateAvailabilityAction.ToUnderOption
            );
          case AssignmentAction.ToAvailable:
            return onChangeAvailabilityStatus(
              UpdateAvailabilityAction.ToAvailable
            );
          case AssignmentAction.Archive:
          case AssignmentAction.DuplicateAsRent:
          case AssignmentAction.DuplicateAsSale:
          case AssignmentAction.RenewRentOffer:
            return checkIfAssignmentCanBeArchived(action);
          case AssignmentAction.UnArchive: {
            return unArchive(id);
          }
          case AssignmentAction.ProlongRentOffer:
            return onToggleProlongRentTerm();
          case AssignmentAction.PrintWindowPresentation:
            return setPrintVisible(true);
          case AssignmentAction.CreateTask: {
            return createTaskWithLinkedAssignment(linkedAssignment);
          }
          case AssignmentAction.CreateInvoice: {
            return createInvoiceWithLinkedAssignment(
              linkedRelations[0] || undefined,
              linkedAssignment
            );
          }
          case AssignmentAction.CreateAppointment: {
            return createAgendaItemWithLinkedAssignment(
              linkedRelations,
              linkedAssignment,
              linkedEmployee
            );
          }
          case AssignmentAction.CreateEmail: {
            return createNewEmail(linkedAssignment, displayName);
          }
          case AssignmentAction.OpenParentObjectType: {
            if (!objectTypeId) return;
            const path = route(OBJECTTYPESROUTES.DETAIL.URI, {
              id: objectTypeId,
            });
            return navigate(path);
          }
          case AssignmentAction.OpenParentProject: {
            if (!projectId) return;
            const path = route(PROJECTROUTES.DETAIL.URI, { id: projectId });
            return navigate(path);
          }
          default:
            return;
        }
      }, []);

      const onChangeAvailabilityStatus = useCallback(
        (status: UpdateAvailabilityAction) => {
          updateAvailability(status, assignment.id);
        },
        [status]
      );

      const checkIfAssignmentCanBeArchived = useCallback(
        (action: AssignmentAction) => {
          let { rejected } = mediaPartners;
          rejected = rejected.filter((partner) =>
            /tiara|atrium/gi.test(partner.mediaPartnerName || "")
          );

          if (!!rejected.length) {
            setMediaPartnerAlertVisible(true, action);
            return;
          }

          forceArchive(action);
        },
        [mediaPartners]
      );

      const setMediaPartnerAlertVisible = useCallback(
        (
          mediaPartnerAlertVisible: boolean,
          archiveAction: AssignmentAction
        ) => {
          setMediaPartnerAlertModalVisible(mediaPartnerAlertVisible);
          setArchiveAction(archiveAction);
        },
        []
      );

      const onToggleProlongRentTerm = useCallback(
        (prolongRentOfferModalVisible: boolean = true) => {
          setProlongRentOfferModalVisible(prolongRentOfferModalVisible);
        },
        []
      );

      const onToggleRenewRentOffer = useCallback(
        (renewRentOfferModalVisible: boolean = true) => {
          setRenewRentOfferModalVisible(renewRentOfferModalVisible);
        },
        []
      );

      const onPrintCloseHandler = useCallback(() => {
        setPrintVisible(false);
      }, []);

      const forceArchive = useCallback(
        (archiveActionParam?: AssignmentAction) => {
          const { id, keyNr, forSale, forRent, isActive } = assignment;
          const action = archiveActionParam || archiveAction;

          switch (action) {
            case AssignmentAction.Archive:
              archive(assignment);
              break;
            case AssignmentAction.DuplicateAsRent:
              if (!!keyNr || (isActive && !!forRent)) {
                setDuplicateModalVisible(true);
                setDuplicateAction(archiveAction);
              } else {
                duplicateAssignment(id, false, false, false, false);
              }
              break;
            case AssignmentAction.DuplicateAsSale:
              if (!!keyNr || (isActive && !!forSale)) {
                setDuplicateModalVisible(true);
                setDuplicateAction(archiveAction);
              } else {
                duplicateAssignment(id, false, true, false, false);
              }
              break;
            case AssignmentAction.RenewRentOffer:
              onToggleRenewRentOffer();
              break;
            default:
              break;
          }

          cancelArchive();
        },
        []
      );

      const cancelArchive = useCallback(() => {
        setMediaPartnerAlertModalVisible(false);
        setArchiveAction(null);
      }, []);

      const onCloseDuplicateModal = useCallback(() => {
        setDuplicateModalVisible(false);
      }, []);

      const onConfirmDeleteCloseHandler = useCallback(() => {
        setShowConfirmDelete(false);
      }, []);

      const onRetractClickHandler = useCallback(() => {
        setShowConfirmRetract(true);
      }, []);

      const onRetractBidClick = useCallback(() => {
        updateAvailability(UpdateAvailabilityAction.ToAvailable, assignment.id);
      }, [updateAvailability, assignment]);

      const onConfirmRetractCloseHandler = useCallback(() => {
        setShowConfirmRetractSale(false);
        setShowConfirmRetract(false);
        setShowConfirmRetractRent(false);
      }, []);

      const onConfirmDeleteHandler = useCallback(() => {
        setShowConfirmDelete(false);
        deleteAssignment(assignment.id);
      }, []);

      const onConfirmRetractHandler = useCallback(() => {
        setShowConfirmRetractSale(false);
        setShowConfirmRetract(false);
        setShowConfirmRetractRent(false);
        updateAvailability(UpdateAvailabilityAction.ToAvailable, assignment.id);
      }, []);

      const format = useCallback((value: number) => {
        return intl.formatNumber(value, {
          style: "currency",
          currency: "EUR",
        });
      }, []);

      const renderSection = useCallback((section: Section) => {
        return (
          <div styleName="section" className="clearfix" key={section?.key}>
            <div styleName="assignment__info">
              <FormattedText
                value={`assignment.sectionTitle.${section.title}`}
                bold
                textStyle={"uppercase"}
                values={{ count: section.key + 1 }}
              />
              <ul styleName="spec_list">
                {section.data.map((item, idx) => {
                  return (
                    <li key={idx}>
                      <span styleName="spec_list_title">
                        <I18n value={item.type}></I18n>
                      </span>
                      <span styleName="spec_list_value">
                        {item.values &&
                          item.values.map((value, index) => {
                            switch (typeof value) {
                              case "number": {
                                item.prefix = "";
                                value = value.toString();
                                break;
                              }
                              case "boolean": {
                                value = value ? "Yes" : "No";
                                item.prefix = "";
                                value = [value];
                                break;
                              }
                              default: {
                                break;
                              }
                            }
                            return (
                              <span key={index}>
                                <I18n
                                  prefix={item.prefix}
                                  value={value || "-"}
                                ></I18n>
                              </span>
                            );
                          })}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      }, []);

      // Render
      return (
        <>
          <div styleName="complete">
            {/* Header */}
            <PageHeader
              title="pageTitle.assignment.complete"
              subTitle={isActive ? subTitle : "archived"}
              subTitlePrefix={
                isActive && !!assignmentPhase ? "assignmentPhases" : null
              }
              actions={
                <>
                  <Notes />
                  <Button
                    label="showEdit"
                    category="success"
                    onClick={() => edit(id)}
                    disabled={isInitial}
                  />
                  <Actions
                    assignment={assignment}
                    printTemplates={printTemplates}
                    isInitial={isInitial}
                    canSendEmail={canSendEmail}
                    enabledRealEstateGroups={enabledRealEstateGroups}
                    canDuplicate={canDuplicate}
                    onClick={onActionHandler}
                  />
                </>
              }
            />
            {/* Content */}
            <div styleName="complete__body">
              {saveAssignmentState === REQUEST.PENDING && (
                <Ui.Loaders.Fullscreen mask />
              )}
              <div styleName="section__main">
                <div styleName="section" className="clearfix">
                  <div styleName="assignment__info">
                    {!!publicReference && (
                      <div styleName="pubref">{publicReference}</div>
                    )}
                    {isImported && (
                      <div styleName="imported">
                        <I18n value="imported" />
                      </div>
                    )}
                    {!!isConfidential && (
                      <div styleName="confidential">
                        <I18n value="confidential" />
                      </div>
                    )}
                    <h2>
                      <I18n
                        prefix="realEstateGroups"
                        value={realEstateGroup.toString()}
                      />
                      ,{" "}
                      {forSale ? (
                        <I18n value="forSale" />
                      ) : (
                        <I18n value={rentResourceKey} />
                      )}
                    </h2>
                    <h1 data-cy="CY-ObjectAssignmentDetailTitle">
                      {displayName}{" "}
                      {sublocality && (
                        <span styleName="subscript">{sublocality}</span>
                      )}
                    </h1>
                    <h3 data-cy="CY-ObjectAssignmentDetailHouseInfo">
                      {renderHouseInfo()}
                    </h3>
                    {!!keyNr && (
                      <div styleName="key">
                        <span styleName="number">
                          <i className="fal fa-key" /> {keyNr}
                        </span>
                        {!!keyNote && <span styleName="note">{keyNote}</span>}
                      </div>
                    )}
                  </div>
                  <ErrorBoundary>
                    <AssignmentDetailGeneralWidgetsStatusComponent
                      assignment={assignment}
                      isInitial={isInitial}
                      notificationSettings={notificationSettings}
                      onRetractClick={onRetractClickHandler}
                      onRetractBidClick={onRetractBidClick}
                      onModifyClick={() => toggleStatusModal(true)}
                      prolongRentTerm={() => onToggleProlongRentTerm()}
                      renewRentOffer={() => onToggleRenewRentOffer()}
                    />
                  </ErrorBoundary>
                </div>
                {showAdvertisementText && (
                  <div styleName="section" className="clearfix">
                    <div styleName="assignment__info">
                      <FormattedText
                        value="assignment.sectionTitle.advertisementText"
                        asSection
                      />
                      {assignment.advertisementText.map((item, idx) => {
                        if (idx === 0) {
                          const showReadMore =
                            item.text && item.text.length > readMoreLimit;
                          return (
                            <div
                              styleName={
                                showReadMore && !advertisementTextExpanded
                                  ? "advertisementText read__more"
                                  : "advertisementText"
                              }
                              key={idx}
                            >
                              {item.text}
                              {showReadMore && (
                                <a
                                  styleName={
                                    advertisementTextExpanded
                                      ? "read__more__link expanded"
                                      : "read__more__link"
                                  }
                                  onClick={() =>
                                    setAdvertisementTextExpanded(
                                      !advertisementTextExpanded
                                    )
                                  }
                                >
                                  <I18n
                                    value={
                                      advertisementTextExpanded
                                        ? "readLess"
                                        : "readMore"
                                    }
                                  ></I18n>
                                </a>
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
                {assignment.forSale &&
                  renderSection({
                    title: "financial",
                    data: [
                      {
                        type: "salePriceLabel",
                        prefix: "salePrice",
                        values: [
                          get(assignment.saleOffer, "salePrice")
                            ? format(get(assignment.saleOffer, "salePrice"))
                            : null,
                        ],
                      },
                      {
                        type: "purchasePriceLabel",
                        prefix: "purchasePrice",
                        values: [
                          get(assignment.saleOffer, "purchasePrice")
                            ? format(get(assignment.saleOffer, "purchasePrice"))
                            : null,
                        ],
                      },
                      {
                        type: "warrantyLabel",
                        prefix: "warrantyLabel",
                        values: [
                          get(assignment.saleOffer, "purchasePrice")
                            ? format(
                                get(assignment.saleOffer, "purchasePrice") * 0.1
                              )
                            : null,
                        ],
                      },
                      {
                        type: "acceptanceDetails",
                        prefix: "acceptances",
                        values: [
                          get(assignment.acceptanceDetails, "acceptance"),
                        ],
                      },
                      {
                        type: "dateReservationLabel",
                        prefix: "acceptances",
                        values: [
                          get(assignment.saleOffer, "dateReservation")
                            ? moment(
                                get(assignment.saleOffer, "dateReservation")
                              ).format("DD-MM-YYYY")
                            : null,
                        ],
                      },
                      {
                        type: "saleCondition",
                        prefix: "saleConditions",
                        values: [get(assignment.saleOffer, "saleCondition")],
                      },
                    ],
                  })}
                {!assignment.forSale &&
                  renderSection({
                    title: "financial",
                    data: [
                      {
                        type: "rentPriceLabel",
                        prefix: "rentPrice",
                        values: [
                          get(assignment.rentOffer, "rentPrice")
                            ? format(get(assignment.rentOffer, "rentPrice"))
                            : null,
                        ],
                      },
                      {
                        type: "realizedPerMonthLabel",
                        prefix: "realizedPerMonth",
                        values: [
                          get(assignment.rentOffer, "realizedPerMonth")
                            ? format(
                                get(assignment.rentOffer, "realizedPerMonth")
                              )
                            : null,
                        ],
                      },
                      {
                        type: "deposit",
                        prefix: "deposit",
                        values: [
                          get(assignment.rentOffer, "deposit")
                            ? format(get(assignment.rentOffer, "deposit"))
                            : null,
                        ],
                      },
                      {
                        type: "serviceCostsRent",
                        prefix: "serviceCostsRent",
                        values: [
                          get(assignment.rentOffer, "serviceCosts")
                            ? format(get(assignment.rentOffer, "serviceCosts"))
                            : null,
                        ],
                      },
                      {
                        type: "acceptanceDetails",
                        prefix: "acceptances",
                        values: [
                          get(assignment.acceptanceDetails, "acceptance"),
                        ],
                      },
                      {
                        type: "dateReservationLabel",
                        prefix: "acceptances",
                        values: [
                          get(assignment.rentOffer, "dateReservation")
                            ? moment(
                                get(assignment.rentOffer, "dateReservation")
                              ).format("DD-MM-YYYY")
                            : null,
                        ],
                      },
                      {
                        type: "rentCondition",
                        prefix: "rentConditions",
                        values: [get(assignment.rentOffer, "rentCondition")],
                      },
                    ],
                  })}
                {realEstateGroup === RealEstateGroup.Residential && (
                  <AssignmentDetailCompleteResidentialSpecificationComponent
                    assignment={assignment}
                    renderSection={renderSection}
                  />
                )}
                {realEstateGroup === RealEstateGroup.Commercial && (
                  <AssignmentDetailCompleteCommercialSpecificationComponent
                    assignment={assignment}
                    renderSection={renderSection}
                  />
                )}
                {realEstateGroup === RealEstateGroup.Agricultural && (
                  <AssignmentDetailCompleteAgriculturalSpecificationComponent
                    assignment={assignment}
                    renderSection={renderSection}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Modals */}
          {!!assignment.forRent && (
            <React.Fragment>
              <ProlongRentOfferModal
                visible={prolongRentOfferModalVisible}
                displayName={assignment.displayName}
                assignment={assignment}
                onClose={() => onToggleProlongRentTerm(false)}
              />

              <RenewRentOfferModal
                visible={renewRentOfferModalVisible}
                displayName={assignment.displayName}
                assignment={assignment}
                onClose={() => onToggleRenewRentOffer(false)}
              />
            </React.Fragment>
          )}

          <WindowPresentationPrintContainer
            assignment={assignment}
            visible={printVisible}
            onClose={onPrintCloseHandler}
          />

          <ConfirmComponent
            visible={showConfirmDelete}
            titleResourceKey="assignmentDeleteModalTitle"
            bodyResourceKey="assignmentDeleteModalMessage"
            onClose={onConfirmDeleteCloseHandler}
            onConfirm={onConfirmDeleteHandler}
          />

          <ConfirmComponent
            visible={showConfirmRetract}
            titleResourceKey="assignmentRetractConfirmTitle"
            bodyResourceKey="assignmentRetractConfirmMessage"
            onClose={onConfirmRetractCloseHandler}
            onConfirm={onConfirmRetractHandler}
          />

          <ConfirmComponent
            visible={showConfirmRetractSale}
            titleResourceKey="assignmentRetractSaleConfirmTitle"
            bodyResourceKey="assignmentRetractSaleConfirmMessage"
            onClose={onConfirmRetractCloseHandler}
            onConfirm={onConfirmRetractHandler}
          />

          <ConfirmComponent
            visible={showConfirmRetractRent}
            titleResourceKey="assignmentRetractRentConfirmTitle"
            bodyResourceKey="assignmentRetractRentConfirmMessage"
            onClose={onConfirmRetractCloseHandler}
            onConfirm={onConfirmRetractHandler}
          />

          <ConfirmComponent
            visible={mediaPartnerAlertVisible}
            titleResourceKey="assignment.error.mediaPartnerErrorOnArchiveTitle"
            bodyResourceKey="assignment.error.mediaPartnerErrorOnArchiveBody"
            onClose={cancelArchive}
            onConfirm={() => forceArchive(archiveAction)}
          />

          <DuplicateModal
            visible={duplicateModalVisible}
            hasKeyNr={!!keyNr}
            duplicateAction={duplicateAction}
            forSale={!!forSale}
            isActive={isActive}
            assignmentId={id}
            onClose={onCloseDuplicateModal}
          />
        </>
      );
    }
  )
);
