import {
  Filter,
  FilterConfig,
  FilterSection,
  ListFilter,
  ListRefProps
} from "@haywork/components/ui/list";
import * as React from "react";
import { FC, memo, MutableRefObject, useCallback } from "react";
import { FiltersContainerProps } from "./filters.container";

export type FiltersComponentProps = {
  list: MutableRefObject<ListRefProps>;
};
type Props = FiltersComponentProps & FiltersContainerProps;

export const FiltersComponent: FC<Props> = memo(
  ({
    filters,
    list,
    setFilters,
    relationTypeFilterOptions,
    localityFilterOptions,
    activeFilterOptions,
    relationGroupFilterOptions
  }) => {
    const updateFilters = useCallback(
      (filters: FilterConfig) => {
        if (!list || !list.current) return;
        setFilters(filters);
        list.current.refresh();
      },
      [list, setFilters]
    );

    return (
      <ListFilter filters={filters} onChange={updateFilters}>
        <FilterSection title="relations.filter.title.relationTypes">
          <Filter.CheckboxArray
            configKey="relationTypes"
            values={relationTypeFilterOptions}
          />
        </FilterSection>

        <FilterSection title="relations.filter.title.activeInactive">
          <Filter.CheckboxArray
            configKey="activeInactive"
            values={activeFilterOptions}
          />
        </FilterSection>

        <FilterSection title="relations.filter.title.localities">
          <Filter.Select
            configKey="localities"
            values={localityFilterOptions}
          />
        </FilterSection>

        <FilterSection
          title="relations.filter.title.relationGroupIds"
          collapsable
          collapsed
        >
          <Filter.CheckboxArray
            configKey="relationGroupIds"
            values={relationGroupFilterOptions}
          />
        </FilterSection>
      </ListFilter>
    );
  }
);
