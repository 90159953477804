import { RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  Address,
  EmployeesClient,
  GeoClient,
  LanguageSpokenOption,
  LinkedEmployee,
  Office,
  OfficesClient,
  PhotoBlob,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { MAINROUTES, OFFICESROUTES, REQUEST } from "@haywork/constants";
import { EditableThunks } from "@haywork/middleware";
import { ParseRequest } from "@haywork/services";
import {
  AccountActions,
  AppState,
  EditableActions,
  OfficeActions,
  OfficeEmployeeList,
} from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { Dispatch } from ".";
import { push } from "connected-react-router";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const EMPTY_ADDRESS: Address = {
  adminAreaLevel1: null,
  adminAreaLevel2: null,
  street: null,
  sublocality: null,
  claimInProgress: false,
  usesHouseNumberRange: false,
  countryIso2: null,
};

const EMPTY_SPOKEN_LANGUAGES: LanguageSpokenOption = {
  iso2CodeValue: null,
  displayName: null,
  id: null,
  value: null,
};

const getOfficeInfo = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      OfficeActions.setOfficeEmployeeInitialOverviewState(REQUEST.PENDING)
    );
    dispatch(OfficeActions.setFetchOfficeState(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Offices = new OfficesClient(host);

    if (
      state.offices.selectedOffice &&
      state.offices.selectedOffice.id === id &&
      state.offices.selectedOffice.isNew
    ) {
      dispatch(OfficeActions.setFetchOfficeState(REQUEST.SUCCESS));
      return;
    }

    try {
      const result = await parseRequest.response(
        Offices.read(id, realEstateAgencyId)
      );

      const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, {
        id: result.office.id,
      });
      dispatch(
        EditableActions.addState({
          icon: "building",
          componentState: result.office,
          path,
          title: result.office.name,
          entityType: RootEntityType.Office,
          entityId: result.office.id,
        })
      );
      dispatch(OfficeActions.setOffice(result));
      dispatch(OfficeActions.setFetchOfficeState(REQUEST.SUCCESS));
      dispatch(
        OfficeActions.setOfficeEmployeeInitialOverviewState(REQUEST.SUCCESS)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getOffices = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(OfficeActions.setFetchOfficeOverviewState(REQUEST.PENDING));
    if (init) {
      dispatch(
        OfficeActions.setInitialFetchOfficeOverviewState(REQUEST.PENDING)
      );
    }
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { officeOverviewPage } = state.offices;
    const { host } = state.appSettings;

    const Relations = new RelationsClient(host);

    try {
      const offices = await parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: true,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: init ? 0 : officeOverviewPage * take,
            take,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Office],
          },
          realEstateAgencyId
        )
      );

      dispatch(
        init
          ? OfficeActions.setOffices(offices)
          : OfficeActions.appendOffice(offices)
      );
      dispatch(OfficeActions.setFetchOfficeOverviewState(REQUEST.SUCCESS));
    } catch (error) {
      throw error;
    }
  };
};

const addOfficeToEmployee = (employeeId: string, office: Office) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(OfficeActions.setOfficeEmployeeSaveState(REQUEST.PENDING));

    const state = getState();

    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const employeeList = office.linkedEmployees;

    const Employees = new EmployeesClient(host);

    try {
      let employee = await parseRequest.response(
        Employees.read(employeeId, realEstateAgencyId).then(
          (response) => response.employee
        )
      );

      employee = !!employee.linkedOffices
        ? {
            ...employee,
            linkedOffices: [...employee.linkedOffices, { id: office.id }],
          }
        : { ...employee, linkedOffices: [{ id: office.id }] };

      const updatedEmployee = await parseRequest.response(
        Employees.save(
          {
            employee,
          },
          realEstateAgencyId
        ).then((response) => response.employee)
      );

      const phoneMobile = updatedEmployee.phoneNumbers[0]
        ? updatedEmployee.phoneNumbers[0].number
        : null;
      const email = updatedEmployee.emailAddresses[0]
        ? updatedEmployee.emailAddresses[0].address
        : null;
      const avatarUrl = updatedEmployee.passportPhotoBlob
        ? updatedEmployee.passportPhotoBlob.urlPreview
        : null;

      const singleEmployee: LinkedEmployee = {
        id: updatedEmployee.id,
        displayName: updatedEmployee.displayName,
        email,
        phoneMobile,
        letterAvatar: updatedEmployee.letterAvatar,
        avatarUrl,
      };

      const response: OfficeEmployeeList = {
        employeeList,
        employeeToAdd: singleEmployee,
        office,
      };

      dispatch(OfficeActions.setOfficeEmployees(response));
      dispatch(OfficeActions.setOfficeEmployeeSaveState(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(OfficeActions.setOfficeEmployeeSaveState(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveOffice = (office: Office, path: string, close: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(OfficeActions.setSaveOfficeInfoState(REQUEST.PENDING));

    const state: AppState = getState();

    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: office.id }
    );

    const Offices: OfficesClient = new OfficesClient(host);
    const RelationOffices: RelationsClient = new RelationsClient(host);

    try {
      const updatedOffice = await parseRequest.response(
        Offices.save({ office }, realEstateAgencyId)
      );

      if (close) {
        dispatch(OfficeActions.setSaveOfficeInfoState(REQUEST.IDLE));
        return dispatch(EditableThunks.remove(route));
      }

      dispatch(OfficeActions.searchOffice(updatedOffice));
      dispatch(OfficeActions.setSaveOfficeInfoState(REQUEST.SUCCESS));
      dispatch(
        EditableActions.updateComponentState({
          componentState: updatedOffice.office,
          path: route,
          ignoreChanges: true,
        })
      );
      dispatch(push(path));

      const relations = await parseRequest.response(
        RelationOffices.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      dispatch(OfficeActions.setSaveOfficeInfoState(REQUEST.ERROR));
      throw error;
    }
  };
};

const searchAddress = (
  location: string,
  countryIso2: string,
  isPostalAddress: boolean,
  officeId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(OfficeActions.setAddressSearchState(REQUEST.PENDING));

    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: officeId }
    );

    const Geo = new GeoClient(host);

    try {
      const address = await parseRequest.response(
        Geo.addressSearch(
          {
            countryIso2,
            location,
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (address === null || !address.street) {
        return dispatch(OfficeActions.setAddressSearchState(REQUEST.ERROR));
      }

      dispatch(OfficeActions.setAddressSearchState(REQUEST.SUCCESS));

      if (isPostalAddress) {
        dispatch(
          EditableActions.updateComponentState({
            componentState: {
              ...currentComponentState,
              postalAddress: address,
            },
            path: route,
          })
        );
      } else {
        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, visitAddress: address },
            path: route,
          })
        );
      }
    } catch (error) {
      dispatch(OfficeActions.setAddressSearchState(REQUEST.ERROR));

      if (isPostalAddress) {
        dispatch(
          EditableActions.updateComponentState({
            componentState: {
              ...currentComponentState,
              postalAddress: {
                ...EMPTY_ADDRESS,
                countryIso2,
                street: { name: location, id: 0 },
              },
            },
            path: route,
          })
        );
      } else {
        dispatch(
          EditableActions.updateComponentState({
            componentState: {
              ...currentComponentState,
              visitAddress: {
                ...EMPTY_ADDRESS,
                countryIso2,
                street: { name: location, id: 0 },
              },
            },
            path: route,
          })
        );
      }

      throw error;
    }
  };
};

const removeOfficeAddress = (isPostalAddress: boolean, officeID: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const { countryIso2 } = currentComponentState.postalAddress;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: officeID }
    );

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            postalAddressDiffersVisitAddress: false,
            postalAddress: { ...EMPTY_ADDRESS, countryIso2 },
          },
          path: route,
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            visitAddress: { ...EMPTY_ADDRESS, countryIso2 },
          },
          path: route,
        })
      );
    }
  };
};

const addManualAddress = (
  newAddress: string,
  isPostalAddress: boolean,
  officeID: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const { countryIso2 } = currentComponentState.postalAddress;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: officeID }
    );

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            postalAddress: {
              ...EMPTY_ADDRESS,
              countryIso2,
              street: { name: newAddress, id: 0 },
            },
          },
          path: route,
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            visitAddress: {
              ...EMPTY_ADDRESS,
              countryIso2,
              street: { name: newAddress, id: 0 },
            },
          },
          path: route,
        })
      );
    }
  };
};

const addManualLanguage = (
  newLanguage: LanguageSpokenOption,
  officeID: string
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: officeID }
    );

    dispatch(
      EditableActions.updateComponentState({
        componentState: {
          ...currentComponentState,
          spokenLanguages: {
            ...EMPTY_SPOKEN_LANGUAGES,
            iso2CodeValue: newLanguage.iso2CodeValue,
            displayName: newLanguage.displayName,
            id: null,
            value: newLanguage.value,
          },
        },
        path: route,
      })
    );
  };
};

const toggleRelationPostalAddress = (relationId: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    const route = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: relationId }
    );

    dispatch(
      EditableActions.updateComponentState({
        componentState: {
          ...currentComponentState,
          postalAddressDiffersVisitAddress:
            !currentComponentState.postalAddressDiffersVisitAddress,
        },
        path: route,
      })
    );
  };
};

const updateOfficeLogo = (avatar: PhotoBlob, officeId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path = RouteUtil.mapStaticRouteValues(
      OFFICESROUTES.OFFICE_DETAIL.URI,
      { id: officeId }
    );
    const componentState = {
      ...currentComponentState,
      logo: !avatar ? null : avatar,
    };

    const Offices = new OfficesClient(host);

    try {
      if (!!avatar) {
        await parseRequest.response(
          Offices.updatePassportPhoto(
            {
              fileDataId: avatar.fileDataId,
              fileExtension: avatar.fileExtension,
              fileName: avatar.fileName,
              fileSize: avatar.fileSize,
              height: avatar.height,
              md5Hash: avatar.md5Hash,
              parentId: officeId,
            },
            realEstateAgencyId
          )
        );
      } else {
        await parseRequest.response(
          Offices.removePassportPhoto(
            { parentId: officeId },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EditableActions.updateComponentState({
          componentState,
          path,
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const archiveOffice = (office: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Offices = new OfficesClient(host);
    const Relations = new RelationsClient(host);

    try {
      await parseRequest.response(
        Offices.archive(
          { forceUnlinkEmployees: true, id: office.id },
          realEstateAgencyId
        )
      );

      const relations = await parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveOffice = (office: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Offices = new OfficesClient(host);
    const Relations = new RelationsClient(host);

    try {
      await parseRequest.response(
        Offices.unarchive({ id: office.id }, realEstateAgencyId)
      );

      const relations = await parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      throw error;
    }
  };
};

const archiveOfficeEmployee = (office: Office, employeeId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const updatedList = office.linkedEmployees.filter(
      ({ id }) => id !== employeeId
    );
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const officeToSave: Office = { ...office, linkedEmployees: updatedList };

    const Offices = new OfficesClient(host);
    const Employees = new EmployeesClient(host);

    try {
      parseRequest.response(
        Offices.save(
          {
            office: officeToSave,
          },
          realEstateAgencyId
        )
      );

      let employee = await parseRequest.response(
        Employees.read(employeeId, realEstateAgencyId).then(
          (response) => response.employee
        )
      );

      const linkedOffices = employee.linkedOffices.filter(
        ({ id }) => id !== office.id
      );
      employee = { ...employee, linkedOffices };

      parseRequest.response(Employees.save({ employee }, realEstateAgencyId));
    } catch (error) {
      throw error;
    }
  };
};

const unarchiveOfficeEmployee = (office: Office, employeeId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Offices = new OfficesClient(host);
    const Employees = new EmployeesClient(host);

    try {
      office.linkedEmployees.push({ id: employeeId });

      parseRequest.response(Offices.save({ office }, realEstateAgencyId));

      let employee = await parseRequest.response(
        Employees.read(employeeId, realEstateAgencyId).then(
          (response) => response.employee
        )
      );

      const linkedOffices = [...employee.linkedOffices, { id: office.id }];
      employee = { ...employee, linkedOffices };

      parseRequest.response(Employees.save({ employee }, realEstateAgencyId));
    } catch (error) {
      throw error;
    }
  };
};
const getAmountOfOffices = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { officeOverviewPage } = state.offices;
    const { host } = state.appSettings;

    const Relations = new RelationsClient(host);

    try {
      const total = await parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: true,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 0,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Office],
          },
          realEstateAgencyId
        ).then((response) => response.totalResults)
      );

      dispatch(OfficeActions.setTotalOffices(total));
    } catch (error) {
      throw error;
    }
  };
};

const defineNewOffice = (
  newName?: string,
  pathForNewRelation?: string,
  propertyToAddNewRelationTo?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Offices = new OfficesClient(host);
    const Relations = new RelationsClient(host);

    try {
      const result = await parseRequest.response(
        Offices.defineNew({}, realEstateAgencyId)
      );

      const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, {
        id: result.office.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.RELATIONS.ICON,
          componentState: result.office,
          path,
          title: "...",
          entityType: RootEntityType.Office,
          entityId: result.office.id,
        })
      );

      dispatch(
        push(route(OFFICESROUTES.EDIT_OFFICE.URI, { id: result.office.id }))
      );

      dispatch(OfficeActions.searchOffice(result));

      const relations = await parseRequest.response(
        Relations.search(
          {
            orderBy: RelationOrderByField.DisplayName,
            includeStatistics: false,
            filterByActive: ActiveFilter.ActiveOnly,
            skip: 0,
            take: 100,
            order: 0,
            filterByRelationGroupIds: null,
            filterByRelationTypes: [RelationType.Employee, RelationType.Office],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(AccountActions.setEmployeesAndOffices({ relations }));
    } catch (error) {
      throw error;
    }
  };
};

export const OfficeThunk = {
  getOfficeInfo,
  getOffices,
  saveOffice,
  searchAddress,
  removeOfficeAddress,
  addManualAddress,
  toggleRelationPostalAddress,
  addOfficeToEmployee,
  addManualLanguage,
  updateOfficeLogo,
  archiveOffice,
  unArchiveOffice,
  archiveOfficeEmployee,
  unarchiveOfficeEmployee,
  getAmountOfOffices,
  defineNewOffice,
};
