import {
  RelationSnapShot,
  TaskCategoryOption,
  TaskSnapShot,
  TasksStatistics,
  TaskStatus,
} from "@haywork/api/kolibri";
import { TASKROUTES } from "@haywork/constants";
import { Dispatch, TaskThunks } from "@haywork/middleware";
import { MappedDropdownValue } from "@haywork/modules/filter";
import {
  TaskOverviewComponent,
  TaskOverviewComponentProps,
} from "@haywork/modules/task";
import { AppState, TaskActions, TaskFilterStatus } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  tasks: TaskSnapShot[];
  tasksStatistics: TasksStatistics;
  employeeId: string;
  taskOverviewStatus: string;
  taskInitialOverviewStatus: string;
  canLoadMore: boolean;
  categories: MappedDropdownValue[];
  statuses: MappedDropdownValue[];
  employees: MappedDropdownValue[];
  taskCategories: TaskCategoryOption[];
  employeeFilter: string;
  categoryFilter: string[];
  statusFilterMap: TaskFilterStatus;
}
interface DispatchProps {
  getTasks: (init?: boolean, take?: number) => void;
  toggleTaskStatus: (
    taskId: string,
    status: TaskStatus,
    refresh: boolean,
    showUndo: boolean,
    task: TaskSnapShot
  ) => void;
  updateFilters: (filters: any) => void;
  deleteTask: (
    id: string,
    subject: string,
    undeleteCallback: () => void
  ) => void;
  openTask: (id: string) => void;
  createTask: () => void;
}

const mapStatusValues = () => {
  return [
    {
      label: "taskFilterSentenceStatusInProgress",
      value: TaskFilterStatus.InProgress,
    },
    {
      label: "taskFilterSentenceStatusExecuted",
      value: TaskFilterStatus.Executed,
    },
    { label: "taskFilterSentenceStatusAll", value: TaskFilterStatus.All },
    {
      label: "taskFilterSentenceStatusDelegated",
      value: TaskFilterStatus.Delegated,
    },
  ];
};

const mapEmployeeValues = (
  employees: RelationSnapShot[],
  employeeId: string
) => {
  const reducedEmployees = employees.reduce((state, employee) => {
    if (employee.id !== employeeId) {
      state.push({ label: employee.displayName, value: employee.id });
    }
    return state;
  }, []);

  return [
    { label: "taskFilterSentenceYourTasks", value: employeeId },
    { label: "taskFilterSentenceEmployeeEveryone", value: null },
    ...reducedEmployees,
  ];
};

const mapCategoryValues = (categories: TaskCategoryOption[]) => {
  const mappedCategories = categories.map((category) => ({
    label: category.displayName,
    value: category.value,
  }));

  return [
    { label: "taskFilterSentenceCategoryAll", value: null },
    ...mappedCategories,
  ];
};

const mapStateToProps = <StateProps, TaskOverviewComponentProps>(
  state: AppState
) => {
  const {
    tasks,
    tasksStatistics,
    taskOverviewStatus,
    taskOverviewPage,
    taskOverviewPageCount,
    tasksFilters,
    taskInitialOverviewStatus,
  } = state.task;
  const { employeeFilter, categoryFilter, statusFilterMap } = tasksFilters;
  const { employeeId } = state.access;

  return {
    tasks,
    tasksStatistics,
    employeeId,
    taskOverviewStatus,
    taskInitialOverviewStatus,
    canLoadMore: taskOverviewPage < taskOverviewPageCount,
    taskCategories: state.main.mastertable.kolibri.taskCategories,
    categories: mapCategoryValues(
      state.main.mastertable.kolibri.taskCategories
    ),
    statuses: mapStatusValues(),
    employees: mapEmployeeValues(state.account.employees, employeeId),
    employeeFilter,
    categoryFilter,
    statusFilterMap,
  };
};

const mapDispatchToProps = <DispatchProps, TaskOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getTasks: (init?: boolean, take?: number) =>
    dispatch(TaskThunks.getTasks(init, take)),
  toggleTaskStatus: (
    taskId: string,
    status: TaskStatus,
    refresh: boolean,
    showUndo: boolean,
    task: TaskSnapShot
  ) =>
    dispatch(TaskThunks.updateStatus(taskId, status, refresh, showUndo, task)),
  updateFilters: (filters: any) =>
    dispatch(TaskActions.updateTasksFilters(filters)),
  deleteTask: (id: string, subject: string, undeleteCallback: () => void) =>
    dispatch(TaskThunks.deleteTask(id, subject, undeleteCallback)),
  openTask: (id: string) =>
    dispatch(push(RouteUtil.mapStaticRouteValues(TASKROUTES.TASK.URI, { id }))),
  createTask: () => dispatch(TaskThunks.createTask()),
});

export type TaskOverviewContainerProps = StateProps & DispatchProps;
export const TaskOverviewContainer = connect<
  StateProps,
  DispatchProps,
  TaskOverviewComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(TaskOverviewComponent);
