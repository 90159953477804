import { RELATIONROUTES } from "@haywork/constants";
import {
  RelationAroRelationsContainer,
  RelationAssignmentContainer,
  RelationDossierContainer,
  RelationGeneralContainer,
  RelationSearchAssignmentsContainer,
  RelationTimelineContainer
} from "@haywork/modules/relation";
import { ContactPersonEditWrapperContainer } from "@haywork/modules/relation/containers/contact-person-edit.container";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
export const ContactPersonDetailRouting = (id) => {
  return (
    <Switch>
      <Route
        exact
        path={"/app/relations/person/:id"}
        render={() => (
          <Redirect to={route(RELATIONROUTES.CONTACT_PERSON_GENERAL.URI, id)} />
        )}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_GENERAL.URI}
        component={RelationGeneralContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_TIMELINE.URI}
        component={RelationTimelineContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_ASSIGNMENT.URI}
        component={RelationAssignmentContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_LINKED_RELATIONS.URI}
        component={RelationAroRelationsContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_EDIT.URI}
        component={ContactPersonEditWrapperContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_DOSSIER.URI}
        component={RelationDossierContainer}
      />
      <Route
        path={RELATIONROUTES.CONTACT_PERSON_SEARCH_ASSIGNMENTS.URI}
        component={RelationSearchAssignmentsContainer}
      />
    </Switch>
  );
};
