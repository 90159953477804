import { WidgetEntityType } from "@haywork/api/authorization";
import { AssignmentDetailDossierContainerProps } from "@haywork/modules/assignment";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import Dossier from "@haywork/modules/shared/components/dossier-v2";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FolderTreeEntityType } from "@haywork/middleware";

const styles = require("./detail-dossier.component.scss");

export type AssignmentDetailDossierComponentProps = {};
type Props = AssignmentDetailDossierComponentProps &
  AssignmentDetailDossierContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentDetailDossierComponent extends React.Component<Props> {
  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.assignmentId,
      WidgetEntityType.ObjectAssignment
    );
  }

  public render() {
    return (
      <ErrorBoundary>
        <Dossier
          parentId={this.props.assignmentId}
          id={this.props.linkedFolderTreeId}
          subject={this.props.displayName}
          entityType={FolderTreeEntityType.ObjectAssignment}
          blocked={this.props.isInitial}
          addNotes={true}
        />
      </ErrorBoundary>
    );
  }
}
