import {
  ErrorResponse,
  MediaContractSnapShot,
  MediaContractStatus,
  MediaPartnerCategory,
  MediaPartnerCategoryOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps, MediapartnersThunks } from "@haywork/middleware";
import {
  SettingsMediaPartnersOverviewComponent,
  SettingsMediaPartnersOverviewComponentProps,
} from "@haywork/modules/settings/modules/mediapartners";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

export enum PublishType {
  Automatic = "Automatic",
  Manual = "Manual",
}

interface StateProps {
  mediaContracts: MediaContractSnapShot[];
  status: string;
  error: ErrorResponse;
  canLoadMore: boolean;
  statuses: MediaContractStatus[];
  categories: MediaPartnerCategory[];
  isFavorite: boolean | null;
  refresh: boolean | null;
  mediaPartnerCategories: MediaPartnerCategoryOption[];
  publishValues: PublishType[];
}
interface DispatchProps {
  getMediaContracts: (init?: boolean, refresh?: boolean, take?: number) => void;
  resetFilters: () => void;
  toggleIsActive: () => void;
  toggleIsFavorite: () => void;
  setCategories: (categories: MediaPartnerCategory[]) => void;
  activateMediaContract: (mediaContractId: string) => void;
  deActivateMediaContract: (mediaContractId: string) => void;
  setFavorite: (mediaContractId: string, isFavorite: boolean) => void;
  setPublishType: (mediaContractId: string, isAutoPublish: boolean) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsMediaPartnersOverviewComponentProps,
  AppState
> = (state) => {
  const {
    mediaContracts: overview,
    statuses,
    categories,
    isFavorite,
  } = state.mediapartners;
  const {
    results: mediaContracts,
    status,
    error,
    canLoadMore,
    refresh,
  } = overview;
  const { mediaPartnerCategories } = state.main.mastertable.kolibri;
  const publishValues = [PublishType.Automatic, PublishType.Manual];

  return {
    mediaContracts,
    status,
    error,
    canLoadMore,
    statuses,
    categories,
    isFavorite,
    refresh,
    mediaPartnerCategories,
    publishValues,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsMediaPartnersOverviewComponentProps
> = (dispatch) => ({
  getMediaContracts: (init?: boolean, refresh?: boolean, take?: number) =>
    dispatch(MediapartnersThunks.getMediaContracts(init, refresh, take)),
  resetFilters: () => dispatch(MediapartnersThunks.resetFilters()),
  toggleIsActive: () => dispatch(MediapartnersThunks.toggleIsActive()),
  toggleIsFavorite: () => dispatch(MediapartnersThunks.toggleIsFavorite()),
  setCategories: (categories: MediaPartnerCategory[]) =>
    dispatch(MediapartnersThunks.setCategories(categories)),
  activateMediaContract: (mediaContractId: string) =>
    dispatch(MediapartnersThunks.activateMediaContract(mediaContractId)),
  deActivateMediaContract: (mediaContractId: string) =>
    dispatch(MediapartnersThunks.deActivateMediaContract(mediaContractId)),
  setFavorite: (mediaContractId: string, isFavorite: boolean) =>
    dispatch(MediapartnersThunks.setFavorite(mediaContractId, isFavorite)),
  setPublishType: (mediaContractId: string, isAutoPublish: boolean) =>
    dispatch(
      MediapartnersThunks.setPublishType(mediaContractId, isAutoPublish)
    ),
});

export type SettingsMediaPartnersOverviewContainerProps = StateProps &
  DispatchProps;
export const SettingsMediaPartnersOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsMediaPartnersOverviewComponent);
