import { CommunicationLog } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { currentMessage } from "../../selectors";
import {
  CommunicationLogComponent,
  CommunicationLogComponentProps,
} from "./communication-log";

type StateProps = {
  currentMessage: EmailMessage;
};
type DispatchProps = {
  getCommunicationLog: (id: string) => Promise<CommunicationLog>;
  navigate: (path: string) => void;
  readMessage: (
    messageId: string,
    accountId: string,
    folderId: string
  ) => Promise<EmailMessage>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  CommunicationLogComponentProps,
  AppState
> = (state) => {
  return {
    currentMessage: currentMessage(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  CommunicationLogComponentProps
> = (dispatch) => ({
  getCommunicationLog: (id: string) =>
    dispatch(EmailThunk.CommunicationLogs.getCommunicationLog(id)),
  navigate: (path: string) => dispatch(push(path)),
  readMessage: (messageId: string, accountId: string, folderId: string) =>
    dispatch(
      EmailThunk.Messages.getMessage(messageId, accountId, folderId, true)
    ),
});

export type CommunicationLogContainerProps = StateProps & DispatchProps;
export const CommunicationLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationLogComponent);
