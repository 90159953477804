import {
  Acceptance,
  RentCondition,
  RentOffer,
  UnitSize,
  ListingType,
} from "@haywork/api/kolibri";
import {
  AssignmentEditFinancialCommissionComponent,
  AssignmentEditFinancialRentBogContainerProps,
} from "@haywork/modules/assignment";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  FontStyle,
  Hint,
  HintAlignment,
  ResourceText,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedNumber } from "react-intl";
import Commission, {
  CommissionFor,
  CommissionResponse,
} from "../../commission";

const styles = require("./rent.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export type AssignmentEditFinancialRentBogComponentProps = {
  onChange: (values: FormReturnValue) => void;
};
interface State {
  totalMonthly: number;
  totalOneTime: number;
  showAcceptanceDate: boolean;
  rentConditionLabel: string;
}
type Props = AssignmentEditFinancialRentBogComponentProps &
  AssignmentEditFinancialRentBogContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialRentBogComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { acceptanceDetails, rentOffer, hidePrice } = this.props;
    const now = new Date();
    const dateOfIssue = has(
      rentOffer,
      "valuationOfImmovablePropertyAct.dateOfIssue"
    )
      ? new Date(rentOffer.valuationOfImmovablePropertyAct.dateOfIssue)
      : new Date(now.getFullYear(), 0, 1, 12, 0, 0);

    const totalOneTime =
      parseFloat(value(rentOffer, "deposit", 0)) +
      parseFloat(value(rentOffer, "advance", 0));
    const totalMonthly =
      parseFloat(value(rentOffer, "basicRent", 0)) +
      parseFloat(value(rentOffer, "indicationGas", 0)) +
      parseFloat(value(rentOffer, "serviceCosts", 0)) +
      parseFloat(value(rentOffer, "indicationWater", 0)) +
      parseFloat(value(rentOffer, "indicationElectra", 0)) +
      parseFloat(value(rentOffer, "indicationTelevision", 0)) +
      parseFloat(value(rentOffer, "indicationInternet", 0)) +
      parseFloat(value(rentOffer, "furniture", 0)) +
      parseFloat(value(rentOffer, "softFurnishings", 0)) +
      parseFloat(value(rentOffer, "indicationHeatingCosts", 0)) +
      parseFloat(value(rentOffer, "retailersContribution", 0));

    // Set state
    this.state = {
      totalMonthly,
      totalOneTime,
      showAcceptanceDate:
        value(acceptanceDetails, "acceptance", Acceptance.InConsultation) ===
        Acceptance.PerDate,
      rentConditionLabel: value(
        rentOffer,
        "rentCondition",
        RentCondition.PricePerMonth
      ),
    };

    // Set form controls
    this.formControls = {
      // Rent
      priceCode: { value: value(rentOffer, "priceCode") },
      rentPrice: { value: value(rentOffer, "rentPrice", 0) },
      rentPriceMax: { value: value(rentOffer, "rentPriceMax", 0) },
      rentCondition: {
        value: value(rentOffer, "rentCondition", RentCondition.PricePerMonth),
        onChange: (ref) => this.setState({ rentConditionLabel: ref.value }),
      },
      rentPricePerUnit: { value: value(rentOffer, "rentPricePerUnit") },
      vatCondition: { value: value(rentOffer, "vatCondition") },
      valuationOfImmovablePropertyActValue: {
        value: value(rentOffer, "valuationOfImmovablePropertyAct.value"),
      },
      dateOfIssue: { value: dateOfIssue },
      hidePrice: { value: hidePrice },

      // Acceptance
      acceptance: {
        value: value(
          acceptanceDetails,
          "acceptance",
          Acceptance.InConsultation
        ),
        onChange: (ref) => {
          this.setState({
            showAcceptanceDate: ref.value === Acceptance.PerDate,
          });
          if (ref.value !== Acceptance.PerDate) {
            return { acceptanceDate: "" };
          }
        },
      },
      acceptanceDate: { value: value(acceptanceDetails, "date") },

      // Single costs
      deposit: { value: value(rentOffer, "deposit") },
      advance: { value: value(rentOffer, "advance") },

      // Monthly costs
      basicRent: { value: value(rentOffer, "basicRent") },
      indicationGas: { value: value(rentOffer, "indicationGas") },
      serviceCosts: { value: value(rentOffer, "serviceCosts") },
      indicationWater: { value: value(rentOffer, "indicationWater") },
      indicationElectra: { value: value(rentOffer, "indicationElectra") },
      indicationTelevision: { value: value(rentOffer, "indicationTelevision") },
      indicationInternet: { value: value(rentOffer, "indicationInternet") },
      furniture: { value: value(rentOffer, "furniture") },
      softFurnishings: { value: value(rentOffer, "softFurnishings") },
      indicationHeatingCosts: {
        value: value(rentOffer, "indicationHeatingCosts"),
      },
      retailersContribution: {
        value: value(rentOffer, "retailersContribution"),
      },
    };

    // Bind this
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRentOfferChangesHandler = this.onRentOfferChangesHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { acceptanceDetails, rentOffer, hidePrice } = this.props;
      const dateOfIssue = has(
        rentOffer,
        "valuationOfImmovablePropertyAct.dateOfIssue"
      )
        ? new Date(rentOffer.valuationOfImmovablePropertyAct.dateOfIssue)
        : new Date();

      const totalOneTime =
        parseFloat(value(rentOffer, "deposit", 0)) +
        parseFloat(value(rentOffer, "advance", 0));
      const totalMonthly =
        parseFloat(value(rentOffer, "basicRent", 0)) +
        parseFloat(value(rentOffer, "indicationGas", 0)) +
        parseFloat(value(rentOffer, "serviceCosts", 0)) +
        parseFloat(value(rentOffer, "indicationWater", 0)) +
        parseFloat(value(rentOffer, "indicationElectra", 0)) +
        parseFloat(value(rentOffer, "indicationTelevision", 0)) +
        parseFloat(value(rentOffer, "indicationInternet", 0)) +
        parseFloat(value(rentOffer, "furniture", 0)) +
        parseFloat(value(rentOffer, "softFurnishings", 0)) +
        parseFloat(value(rentOffer, "indicationHeatingCosts", 0)) +
        parseFloat(value(rentOffer, "retailersContribution", 0));

      this.setState({
        totalMonthly,
        totalOneTime,
        showAcceptanceDate:
          value(acceptanceDetails, "acceptance", Acceptance.InConsultation) ===
          Acceptance.PerDate,
        rentConditionLabel: value(
          rentOffer,
          "rentCondition",
          RentCondition.PricePerMonth
        ),
      });

      this.formRef.update(
        {
          priceCode: value(rentOffer, "priceCode"),
          rentPrice: value(rentOffer, "rentPrice", 0),
          rentPriceMax: value(rentOffer, "rentPriceMax", 0),
          rentCondition: value(
            rentOffer,
            "rentCondition",
            RentCondition.PricePerMonth
          ),
          rentPricePerUnit: value(rentOffer, "rentPricePerUnit"),
          vatCondition: value(rentOffer, "vatCondition"),
          valuationOfImmovablePropertyActValue: value(
            rentOffer,
            "valuationOfImmovablePropertyAct.value"
          ),
          dateOfIssue,
          hidePrice,
          acceptance: value(
            acceptanceDetails,
            "acceptance",
            Acceptance.InConsultation
          ),
          acceptanceDate: value(acceptanceDetails, "date"),
          deposit: value(rentOffer, "deposit"),
          advance: value(rentOffer, "advance"),
          basicRent: value(rentOffer, "basicRent"),
          indicationGas: value(rentOffer, "indicationGas"),
          serviceCosts: value(rentOffer, "serviceCosts"),
          indicationWater: value(rentOffer, "indicationWater"),
          indicationElectra: value(rentOffer, "indicationElectra"),
          indicationTelevision: value(rentOffer, "indicationTelevision"),
          indicationInternet: value(rentOffer, "indicationInternet"),
          furniture: value(rentOffer, "furniture"),
          softFurnishings: value(rentOffer, "softFurnishings"),
          indicationHeatingCosts: value(rentOffer, "indicationHeatingCosts"),
          retailersContribution: value(rentOffer, "retailersContribution"),
        },
        true
      );
    }
  }

  public render() {
    const {
      acceptances,
      rentOffer,
      vatConditions,
      priceCodes,
      commissionTypeOptions,
      languages,
      hidePrice,
      objectAssignment,
    } = this.props;
    const { vVO, parcelSurface, listingType } = objectAssignment;
    const surface = [
      ListingType.Leisure,
      ListingType.OutdoorArea,
      ListingType.Lot,
    ].includes(listingType)
      ? parcelSurface
      : vVO;

    return (
      <div styleName="financial" id="scroll-to-top">
        <div className="container-fluid">
          <Form
            name="financial"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(ref) => (this.formRef = ref)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              <StepComponent title="rent">
                {/* Rent */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <Input.NewSelect
                        name="priceCode"
                        values={priceCodes}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                    <div className="column__textspacer push-label">
                      <ResourceText
                        resourceKey="from"
                        fontStyle={FontStyle.LowerCase}
                      />
                    </div>
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="rentPrice"
                          pretty
                          step={10}
                          asPrice
                        />
                      </div>
                    </div>
                    <div className="column__textspacer push-label">
                      <ResourceText resourceKey="per" />
                    </div>
                    <div
                      className="column push-label"
                      styleName="rentCondition"
                    >
                      <Input.NewSelect
                        name="rentCondition"
                        values={this.props.rentConditions}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column push-label">
                      <Input.RadioGroup
                        name="vatCondition"
                        asButtonList
                        canUnselect
                      >
                        {vatConditions.map((vatCondition, idx) => (
                          <Input.Radio
                            label={vatCondition.displayName}
                            value={vatCondition.value}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                  </div>
                </div>

                {/* Rent price per unit */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label htmlFor="rentPricePerUnit" className="pre">
                        <ResourceText resourceKey="andOr" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="rentPricePerUnit"
                          pretty
                          step={10}
                          asPrice
                        />
                        <div className="post full">
                          <ResourceText
                            resourceKey="perSquareMetersPerYear"
                            asHtml
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Hide price */}
                {(!!hidePrice ||
                  !!this.props.enableHidePriceForObjectAssignmentsOption) && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="hidePrice"
                          label="hidePrice"
                          labelPosition={SwitchLabelPosition.Pre}
                          on={true}
                          off={false}
                        />
                      </div>
                      <div className="column__textspacer">
                        <Hint
                          message="showPriceTooltip"
                          align={HintAlignment.Right}
                        >
                          <span className="toolTip">
                            <i className="fa fa-fw fa-info-circle" />{" "}
                            <ResourceText resourceKey="whatIsThis" />
                          </span>
                        </Hint>
                      </div>
                    </div>
                  </div>
                )}

                {/* WOZ */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label
                        htmlFor="valuationOfImmovablePropertyActValue"
                        className="pre"
                      >
                        <ResourceText resourceKey="wozValue" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="valuationOfImmovablePropertyActValue"
                          pretty
                          step={1000}
                          asPrice
                        />
                      </div>
                    </div>
                    <div className="column__textspacer">
                      <ResourceText resourceKey="per" />
                    </div>
                    <div className="column">
                      <Input.Datepicker name="dateOfIssue" />
                    </div>
                  </div>
                </div>

                {/* Acceptance */}
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="acceptance" />
                    <Ui.RequiredForPublish />
                  </label>
                  <div className="form__group">
                    <div className="column">
                      <Input.RadioGroup name="acceptance" asButtonList>
                        {acceptances.map((acceptance, idx) => (
                          <Input.Radio
                            label={acceptance.displayName}
                            value={acceptance.value}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                    {this.state.showAcceptanceDate && (
                      <div className="column__join">
                        <div className="column__textspacer">
                          <ResourceText resourceKey="date" />
                        </div>
                        <div className="column">
                          <Input.Datepicker name="acceptanceDate" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="costPostBuilding">
                {/* Deposit */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="deposit">
                      <ResourceText resourceKey="deposit" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="deposit" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="oneTime" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Advance */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="advance">
                      <ResourceText resourceKey="advance" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="advance" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="oneTime" />
                      </div>
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="oneTime" />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.totalOneTime}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>

                {/* Basic rent */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="basicRent">
                      <ResourceText resourceKey="basicRent" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="basicRent" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gas */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationGas">
                      <ResourceText resourceKey="indicationGas" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationGas"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Service costs */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="serviceCosts">
                      <ResourceText resourceKey="serviceCostsRent" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="serviceCosts"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" asHtml={true} />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Water */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationWater">
                      <ResourceText resourceKey="indicationWater" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationWater"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Electra */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationElectra">
                      <ResourceText resourceKey="indicationElectra" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationElectra"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Television */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationTelevision">
                      <ResourceText resourceKey="indicationTelevision" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationTelevision"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Internet */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationInternet">
                      <ResourceText resourceKey="indicationInternet" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationInternet"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Furniture */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="furniture">
                      <ResourceText resourceKey="furniture" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number name="furniture" pretty step={10} asPrice />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Soft furnishings */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="softFurnishings">
                      <ResourceText resourceKey="softFurnishings" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="softFurnishings"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Heating */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="indicationHeatingCosts">
                      <ResourceText resourceKey="indicationHeatingCosts" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="indicationHeatingCosts"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Retailers Contribution */}
                <div className="form__row">
                  <div styleName="inline-value">
                    <label htmlFor="retailersContribution">
                      <ResourceText resourceKey="retailersContribution" />
                    </label>
                    <div className="input__helper">
                      <div className="pre">&euro;</div>
                      <Input.Number
                        name="retailersContribution"
                        pretty
                        step={10}
                        asPrice
                      />
                      <div className="post full">
                        <ResourceText resourceKey="per" asHtml={true} />
                        &nbsp;
                        <ResourceText
                          masterKey="rentConditions"
                          resourceKey={this.state.rentConditionLabel}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div styleName="total">
                  <div styleName="label">
                    <ResourceText resourceKey="totalPer" />
                    &nbsp;
                    <ResourceText
                      masterKey="rentConditions"
                      resourceKey={this.state.rentConditionLabel}
                    />
                  </div>
                  <div styleName="value">
                    <FormattedNumber
                      value={this.state.totalMonthly}
                      style="currency"
                      currency="EUR"
                    />
                  </div>
                </div>
              </StepComponent>

              <StepComponent title="courtage">
                <Commission
                  commissionFor={CommissionFor.Rent}
                  offer={rentOffer}
                  surface={surface}
                  onChange={this.onRentOfferChangesHandler}
                />
              </StepComponent>
              <StepComponent title="exchangeCommission">
                <div
                  className="alert alert-info"
                  styleName="exchangeInfoMessage"
                >
                  <i className="fal fa-fw fa-lightbulb" />
                  <ResourceText resourceKey="exchangeCommissionMessage" />
                </div>
                <AssignmentEditFinancialCommissionComponent
                  commissionTypeOptions={commissionTypeOptions}
                  offer={rentOffer}
                  languages={languages}
                  defaultLanguage={this.props.defaultLanguage}
                  objectAssignment={this.props.objectAssignment}
                  onChange={this.onRentOfferChangesHandler}
                />
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>
      </div>
    );
  }

  private onRentOfferChangesHandler(values: CommissionResponse) {
    const rentOffer = {
      ...this.props.rentOffer,
      ...values,
    };

    this.props.onChange({ rentOffer });
  }

  private onChangeHandler(values: FormReturnValue) {
    let totalMonthly = 0;
    let totalOneTime = 0;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const raw = parseFloat(values[key]);
        const value = isNaN(raw) ? 0 : raw;

        switch (key) {
          case "basicRent":
          case "indicationGas":
          case "serviceCosts":
          case "indicationWater":
          case "indicationElectra":
          case "indicationTelevision":
          case "indicationInternet":
          case "furniture":
          case "softFurnishings":
          case "indicationHeatingCosts":
          case "retailersContribution":
            totalMonthly = totalMonthly + value;
            break;
          case "deposit":
          case "advance":
            totalOneTime = totalOneTime + value;
            break;
          default:
            break;
        }
      }
    }

    this.setState({ totalMonthly, totalOneTime });

    let rentOffer: RentOffer = {
      ...this.props.rentOffer,
      advance: values.advance,
      basicRent: values.basicRent,
      deposit: values.deposit,
      furniture: values.furniture,
      indicationElectra: values.indicationElectra,
      indicationGas: values.indicationGas,
      indicationHeatingCosts: values.indicationHeatingCosts,
      indicationInternet: values.indicationInternet,
      indicationTelevision: values.indicationTelevision,
      indicationWater: values.indicationWater,
      rentCondition: values.rentCondition,
      rentPrice: values.rentPrice,
      rentPriceMax: values.rentPriceMax,
      retailersContribution: values.retailersContribution,
      hasRetailersContribution:
        values.retailersContribution &&
        parseFloat(values.retailersContribution) > 0,
      priceCode: values.priceCode,
      serviceCosts: values.serviceCosts,
      softFurnishings: values.softFurnishings,
      valuationOfImmovablePropertyAct: {
        ...this.props.rentOffer.valuationOfImmovablePropertyAct,
        value: values.valuationOfImmovablePropertyActValue,
        dateOfIssue: values.dateOfIssue,
      },
      vatCondition: values.vatCondition,
      rentPricePerUnit: undefined,
      rentPricePerUnitSize: undefined,
    };

    if (!!values.rentPricePerUnit) {
      rentOffer = {
        ...rentOffer,
        rentPricePerUnit: values.rentPricePerUnit,
        rentPricePerUnitSize: UnitSize.M2,
      };
    }

    const acceptanceDetails = {
      acceptance: values.acceptance,
      date: values.acceptanceDate,
    };

    this.props.onChange({
      rentOffer,
      acceptanceDetails,
      hidePrice: values.hidePrice,
    });
  }
}
