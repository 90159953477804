import * as React from "react";
import { FC, memo, useMemo, useRef, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionListContainerProps } from "./list.container";
import List, {
  ListWrapper,
  ListColumnConfig,
  ListRefProps,
  ActiveFilters,
  FilterConfig,
} from "@haywork/components/ui/list";
import {
  AssignmentSnapShot,
  AssignmentOrderByField,
  SortOrder,
} from "@haywork/api/kolibri";
import Row from "./components/row";
import Filters from "./components/filters";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";

const styles = require("./style.scss");

export type AcquisitionListComponentProps = {};
type Props = AcquisitionListComponentProps & AcquisitionListContainerProps;

export const AcquisitionListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      navigate,
      assignments,
      total,
      getListItems,
      createAcquisition,
      filters,
      activeFilterGuidValues,
      setFilters,
      setOrdering,
      order,
      scrollOffset,
      setScrollOffset,
    }) => {
      const listRef = useRef<ListRefProps>();

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: {
                value: "totalCount",
                values: { count: total },
              },
              sortConfig: [AssignmentOrderByField.ActivityAndDateTimeModified],
              width: 150,
            },
            {
              label: {
                value: "issuers",
              },
              width: 300,
              autoFill: true,
            },
            {
              label: {
                value: "type",
              },
              width: 128,
              sortConfig: [AssignmentOrderByField.AssignmentType],
            },
            {
              label: {
                value: "saleAndOrRent",
              },
              width: 128,
            },
            {
              label: {
                value: "status",
              },
              width: 128,
            },
          ],
        } as ListColumnConfig;
      }, [total]);

      const onNavigatePath = useCallback((path: string) => {
        navigate(path);
      }, []);

      const onSortChange = useCallback(
        (sortColumn: AssignmentOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="assignments"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
          >
            <List
              name="assignments"
              rowHeight={64}
              columnConfig={columnConfig}
              data={assignments}
              totalCount={total}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              ref={listRef}
              emptyState={
                <EmptyState
                  icon="folder-open"
                  title="assignments.list.emptyStateTitle"
                  subTitle="assignments.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="assignments.list.emptyState.action"
                      category="primary"
                      onClick={createAcquisition}
                    />
                  }
                />
              }
            >
              {(data: AssignmentSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigatePath={onNavigatePath}
                />
              )}
            </List>
          </ListWrapper>
        </div>
      );
    }
  )
);
