import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  ActiveFilter,
  Address,
  ContactCompaniesClient,
  ContactCompany,
  ContactLinkOrderByField,
  ContactLinksClient,
  ContactLinkSnapShot,
  ContactPerson,
  ContactPersonsClient,
  EmailAddress,
  EmailAddressType,
  GeoClient,
  PhoneNumber,
  PhoneNumberType,
  PhotoBlob,
  RelationGroupOrderByField,
  RelationGroupsClient,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  RelationTermField,
  RelationType,
  SortOrder,
  TimelineActionType,
  TimelineEventsClient,
  TimelineOrderByField,
} from "@haywork/api/kolibri";
import { MAINROUTES, RELATIONROUTES, REQUEST } from "@haywork/constants";
import { mapContactCompanyToRelationSnapShot } from "@haywork/mappers/contact-company";
import { mapContactPersonToRelationSnapShot } from "@haywork/mappers/contact-person";
import { EditableThunks } from "@haywork/middleware";
import { FeatureHelper } from "@haywork/modules/feature-switch";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  ErrorActions,
  LayoutActions,
  RelationActions,
  RelationListActions,
} from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { DateUtil, NameUtil, RouteUtil, StringUtil } from "@haywork/util";
import Axios, { AxiosRequestConfig } from "axios";
import { push } from "connected-react-router";
import { saveAs } from "file-saver";
import { Agent as HttpAgent } from "http";
import { Agent as HttpsAgent } from "https";
import uniqBy from "lodash-es/uniqBy";
import * as moment from "moment";
import { Dispatch } from ".";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const EMPTY_ADDRESS: Address = {
  adminAreaLevel1: null,
  adminAreaLevel2: null,
  street: null,
  sublocality: null,
  claimInProgress: false,
  usesHouseNumberRange: false,
  countryIso2: "NL",
};

export interface RelationFilter {
  relationTypeFilter: RelationType[] | null;
  linkedRelationGroupsFilter: string[];
  termFieldsFilter: RelationTermField[] | null;
  activeOrInactiveFilter: ActiveFilter | null;
  term: string | null;
}

const getRelations = (init: boolean = false, take: number = 25) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationOverviewState(REQUEST.PENDING));
    if (init) {
      dispatch(
        RelationActions.setInitialFetchRelationOverviewState(REQUEST.PENDING)
      );
    }

    const state = getState();
    const { relationOverviewPage } = state.relation.overview;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      relationTypeFilter,
      linkedRelationGroupsFilter,
      termFieldsFilter,
      activeOrInactiveFilter,
      term,
    } = state.relation.overview.selectedRelationFilters;

    const Relations = new RelationsClient(host);

    try {
      const relations = await parseRequest.response(
        Relations.search(
          {
            filterByRelationTypes: relationTypeFilter,
            filterByRelationGroupIds: linkedRelationGroupsFilter,
            termFields: termFieldsFilter,
            filterByActive: activeOrInactiveFilter,
            orderBy: RelationOrderByField.DisplayName,
            term,
            skip: init ? 0 : relationOverviewPage * take,
            take,
            order: SortOrder.Ascending,
            includeStatistics: true,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(RelationActions.searchRelation(relations))
        : dispatch(RelationActions.appendRelation(relations));

      dispatch(RelationActions.setFetchRelationOverviewState(REQUEST.SUCCESS));
    } catch (error) {
      throw error;
    }
  };
};

// TODO check met Nick
export type ExtendedRelationSnapShot = RelationSnapShot & {
  extended?: boolean;
};
const searchRelations = (ids: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new RelationsClient(host);

    try {
      return await parseRequest.response(
        client
          .search(
            {
              relationIds: ids,
              includeStatistics: false,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              orderBy: RelationOrderByField.DisplayName,
              skip: 0,
              take: 1,
            },
            realEstateAgencyId
          )
          .then((response) => {
            return (response?.results || []).map(
              (item) =>
                ({ ...item, extended: true } as ExtendedRelationSnapShot)
            );
          })
      );
    } catch (error) {
      throw error;
    }
  };
};

const getRelationGroups = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const RelationGroups = new RelationGroupsClient(host);

    try {
      const relationGroups = await parseRequest.response(
        RelationGroups.search(
          {
            orderBy: RelationGroupOrderByField.Name,
            filterByActive: ActiveFilter.ActiveOrInactive,
            skip: 0,
            take: 100,
            order: SortOrder.Ascending,
            includeStatistics: true,
          },
          realEstateAgencyId
        )
      );

      dispatch(RelationActions.searchRelationGroups(relationGroups));
    } catch (error) {
      throw error;
    }
  };
};

const getContactInfo = (relationId: string, relationType: RelationType) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      switch (relationType) {
        case RelationType.ContactPerson: {
          let contactPersonResponse = await parseRequest.response(
            ContactPersons.read(relationId, realEstateAgencyId)
          );

          if (!contactPersonResponse.contactPerson.address) {
            contactPersonResponse = {
              ...contactPersonResponse,
              contactPerson: {
                ...contactPersonResponse.contactPerson,
                address: EMPTY_ADDRESS,
              },
            };
          }

          if (!contactPersonResponse.contactPerson.postalAddress) {
            contactPersonResponse = {
              ...contactPersonResponse,
              contactPerson: {
                ...contactPersonResponse.contactPerson,
                postalAddress: EMPTY_ADDRESS,
              },
            };
          }

          dispatch(RelationActions.searchContactPerson(contactPersonResponse));
          break;
        }
        case RelationType.ContactCompany: {
          let contactCompanyResponse = await parseRequest.response(
            ContactCompanies.read(relationId, realEstateAgencyId)
          );

          if (!contactCompanyResponse.contactCompany.address) {
            contactCompanyResponse = {
              ...contactCompanyResponse,
              contactCompany: {
                ...contactCompanyResponse.contactCompany,
                address: EMPTY_ADDRESS,
              },
            };
          }

          if (!contactCompanyResponse.contactCompany.postalAddress) {
            contactCompanyResponse = {
              ...contactCompanyResponse,
              contactCompany: {
                ...contactCompanyResponse.contactCompany,
                postalAddress: EMPTY_ADDRESS,
              },
            };
          }

          dispatch(
            RelationActions.searchContactCompany(contactCompanyResponse)
          );
          break;
        }
        default:
          break;
      }

      dispatch(
        RelationActions.setFetchRelationContactInfoState(REQUEST.SUCCESS)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getContactPersonInfo = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);

    try {
      const contactPersonResponse = await parseRequest.response(
        ContactPersons.read(id, realEstateAgencyId)
      );

      const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
        id: contactPersonResponse.contactPerson.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.RELATIONS.ICON,
          componentState: contactPersonResponse.contactPerson,
          path,
          title: contactPersonResponse.contactPerson.displayName,
          entityType: RootEntityType.ContactPerson,
          entityId: contactPersonResponse.contactPerson.id,
        })
      );

      dispatch(RelationActions.searchContactPerson(contactPersonResponse));
      dispatch(
        RelationActions.setFetchRelationContactInfoState(REQUEST.SUCCESS)
      );

      return contactPersonResponse;
    } catch (error) {
      dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.ERROR));
      throw error;
    }
  };
};

const getContactCompanyInfo = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      const contactCompanyResponse = await parseRequest.response(
        ContactCompanies.read(id, realEstateAgencyId)
      );

      const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: contactCompanyResponse.contactCompany.id,
      });
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.RELATIONS.ICON,
          componentState: contactCompanyResponse.contactCompany,
          path,
          title: contactCompanyResponse.contactCompany.displayName,
          entityType: RootEntityType.ContactCompany,
          entityId: contactCompanyResponse.contactCompany.id,
        })
      );

      dispatch(RelationActions.searchContactCompany(contactCompanyResponse));
      dispatch(
        RelationActions.setFetchRelationContactInfoState(REQUEST.SUCCESS)
      );

      return contactCompanyResponse;
    } catch (error) {
      dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.ERROR));
      throw error;
    }
  };
};

const getContactLinks = (
  relationId: string,
  init: boolean = false,
  take: number = 10
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactLinkState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    let { contactLinkPage } = state.relation.detailContactlink;
    if (init) {
      contactLinkPage = 0;
    }

    const ContactLinks = new ContactLinksClient(host);

    try {
      const contactLinks = await parseRequest.response(
        ContactLinks.search(
          {
            relationId,
            orderBy: ContactLinkOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOrInactive,
            skip: contactLinkPage * take,
            take,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(RelationActions.searchContactLinks(contactLinks))
        : dispatch(RelationActions.appendContactLinks(contactLinks));
      dispatch(
        RelationActions.setFetchRelationContactLinkState(REQUEST.SUCCESS)
      );
    } catch (error) {
      throw error;
    }
  };
};

const getTimelineEvents = (
  relationId: string,
  init: boolean = true,
  take: number = 25,
  filterTypes?: TimelineActionType[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationTimelineStatus(REQUEST.PENDING));

    const state = getState();
    const { timelinePage } = state.relation.detailTimeline;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const filtertypes = filterTypes
      ? filterTypes
      : [
          TimelineActionType.AgendaItem,
          TimelineActionType.Invoice,
          TimelineActionType.Task,
          TimelineActionType.Email,
          TimelineActionType.SearchProfile,
          TimelineActionType.ContactMe,
          TimelineActionType.Website,
          TimelineActionType.Transaction,
          TimelineActionType.CommunicationLog,
          TimelineActionType.Relocation,
          TimelineActionType.Bid,
        ];

    const TimelineEvents = new TimelineEventsClient(host);

    try {
      const timelineEvents = await parseRequest.response(
        TimelineEvents.search(
          {
            relationId,
            assignmentId: "",
            minDateTime: null,
            maxDateTime: null,
            filterByActionTypes: filtertypes,
            termFields: null,
            orderBy: TimelineOrderByField.Date,
            includeStatistics: true,
            filterByActive: ActiveFilter.ActiveOrInactive,
            term: null,
            skip: init ? 0 : timelinePage * take,
            take,
            order: SortOrder.Descending,
          },
          realEstateAgencyId
        )
      );

      !!init
        ? dispatch(RelationActions.searchTimelineItems(timelineEvents, take))
        : dispatch(RelationActions.appendTimelineItems(timelineEvents, take));
      dispatch(RelationActions.setFetchRelationTimelineStatus(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(RelationActions.setFetchRelationTimelineStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const archiveRelation = (relation: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      switch (relation.typeOfRelation) {
        case RelationType.ContactPerson: {
          parseRequest.response(
            ContactPersons.archive({ id: relation.id }, realEstateAgencyId)
          );
          break;
        }
        case RelationType.ContactCompany: {
          parseRequest.response(
            ContactCompanies.archive({ id: relation.id }, realEstateAgencyId)
          );
          break;
        }
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveRelation = (relation: RelationSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      switch (relation.typeOfRelation) {
        case RelationType.ContactPerson: {
          parseRequest.response(
            ContactPersons.unarchive({ id: relation.id }, realEstateAgencyId)
          );
          break;
        }
        case RelationType.ContactCompany: {
          parseRequest.response(
            ContactCompanies.unarchive({ id: relation.id }, realEstateAgencyId)
          );
          break;
        }
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  };
};

const deleteRelation = (
  id: string,
  displayName: string,
  typeOfRelation: RelationType,
  closeWhenDone: boolean = false,
  undeleteCallback?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      switch (typeOfRelation) {
        case RelationType.ContactPerson: {
          parseRequest.response(ContactPersons.delete(id, realEstateAgencyId));
          break;
        }
        case RelationType.ContactCompany: {
          parseRequest.response(
            ContactCompanies.delete(id, realEstateAgencyId)
          );
          break;
        }
        default:
          break;
      }

      if (!!undeleteCallback) {
        dispatch(
          SnackbarActions.addToast({
            value: "relation.toast.deleted",
            values: { displayName: StringUtil.trim(displayName, 48) },
            callback: async () => {
              await dispatch(unDeleteRelation(id, typeOfRelation));
              undeleteCallback();
            },
            callbackLabel: "relation.toast.action.undelete",
            icon: "trash-alt",
          })
        );
      }

      if (closeWhenDone) {
        let path = "";
        if (typeOfRelation === RelationType.ContactCompany) {
          path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        } else if (typeOfRelation === RelationType.ContactPerson) {
          path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        }

        dispatch(EditableThunks.remove(path));
      }
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteRelation = (id: string, typeOfRelation: RelationType) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      switch (typeOfRelation) {
        case RelationType.ContactPerson: {
          parseRequest.response(
            ContactPersons.undelete({ id }, realEstateAgencyId)
          );
          break;
        }
        case RelationType.ContactCompany: {
          parseRequest.response(
            ContactCompanies.undelete({ id }, realEstateAgencyId)
          );
          break;
        }
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  };
};

const exportRelations = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      relationTypeFilter,
      linkedRelationGroupsFilter,
      activeOrInactiveFilter,
      termFieldsFilter,
    } = state.relation.overview.selectedRelationFilters;

    const props = {
      filterByRelationTypes: relationTypeFilter,
      filterByRelationGroupIds: linkedRelationGroupsFilter,
      termFields: termFieldsFilter,
      activeFilter: activeOrInactiveFilter,
      access_token: state.access.token,
    };

    const relationExportUrl = `${host}/${apiVersion}/${realEstateAgencyId}/Relations/SearchAndExport?${RouteUtil.mapObjectToGetParams(
      props
    )}`;

    dispatch(RelationActions.setExportUrl(relationExportUrl));
  };
};

const updateContactCompanyLogo = (
  avatar: PhotoBlob,
  contactCompanyId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      let logo = null;

      if (!!avatar) {
        logo = await parseRequest.response(
          ContactCompanies.updateLogo(
            {
              fileDataId: avatar.fileDataId,
              fileExtension: avatar.fileExtension,
              fileName: avatar.fileName,
              fileSize: avatar.fileSize,
              height: avatar.height,
              md5Hash: avatar.md5Hash,
              parentId: contactCompanyId,
            },
            realEstateAgencyId
          ).then((response) => response.photoBlob)
        );
      } else {
        await parseRequest.response(
          ContactCompanies.removeLogo(
            { parentId: contactCompanyId },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EditableActions.updateComponentState({
          componentState: { ...currentComponentState, logo },
          path: RouteUtil.mapStaticRouteValues(
            RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
            { id: contactCompanyId }
          ),
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateContactPersonPassportPhoto = (
  avatar: PhotoBlob,
  contactPersonId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { currentComponentState } = state.editable;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);

    try {
      let passportPhotoBlob = null;

      if (!!avatar) {
        passportPhotoBlob = await parseRequest.response(
          ContactPersons.updatePassportPhoto(
            {
              fileDataId: avatar.fileDataId,
              fileExtension: avatar.fileExtension,
              fileName: avatar.fileName,
              fileSize: avatar.fileSize,
              height: avatar.height,
              md5Hash: avatar.md5Hash,
              parentId: contactPersonId,
            },
            realEstateAgencyId
          ).then((response) => response.photoBlob)
        );
      } else {
        await parseRequest.response(
          ContactPersons.removePassportPhoto(
            { parentId: contactPersonId },
            realEstateAgencyId
          )
        );
      }

      dispatch(
        EditableActions.updateComponentState({
          componentState: { ...currentComponentState, passportPhotoBlob },
          path: RouteUtil.mapStaticRouteValues(
            RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
            { id: contactPersonId }
          ),
          ignoreChanges: true,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const saveContactCompany = (contactCompany: ContactCompany, path: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { states } = state.editable;
    const uri = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
      id: contactCompany.id,
    });
    const editable = states.find((s) => s.path === uri);

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      const response = await parseRequest.response(
        ContactCompanies.save({ contactCompany }, realEstateAgencyId)
      );

      if (!!editable && !!editable.caller) {
        dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.IDLE));
        dispatch(push(editable.caller));
        dispatch(EditableThunks.remove(uri));
        return;
      }

      dispatch(RelationActions.searchContactCompany(response));
      dispatch(
        EditableActions.updateComponentState({
          componentState: response.contactCompany,
          path: uri,
          ignoreChanges: true,
        })
      );
      dispatch(push(path));

      if (
        contactCompany.isNew &&
        state.relation.detail.pathForNewRelation &&
        state.relation.detail.pathForNewRelation.length > 0
      ) {
        const relationSnapShot: RelationSnapShot = {
          id: response.contactCompany.id,
          displayName: response.contactCompany.displayName,
          typeOfRelation: RelationType.ContactCompany,
          isActive: true,
          dateTimeCreated: response.contactCompany.dateTimeCreated,
          dateTimeModified: response.contactCompany.dateTimeModified,
        };

        updateOriginalItemWithNewRelation(
          state,
          dispatch,
          contactCompany.id,
          relationSnapShot
        );
      }
    } catch (error) {
      dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.ERROR));
      throw error;
    }
  };
};

const saveContactPerson = (
  person: ContactPerson,
  path: string,
  close: boolean = false
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { states } = state.editable;
    const uri = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
      id: person.id,
    });
    const editable = states.find((s) => s.path === uri);
    let contactPerson = {
      ...person,
      displayName: null,
    };

    const ContactPersons = new ContactPersonsClient(host);
    const Relations = new RelationsClient(host);

    try {
      if (contactPerson.isNew) {
        const existing = await parseRequest.response(
          Relations.search(
            {
              orderBy: RelationOrderByField.DateTimeCreated,
              filterByActive: ActiveFilter.ActiveOrInactive,
              order: SortOrder.Ascending,
              skip: 0,
              take: 1,
              relationId: contactPerson.id,
              includeStatistics: false,
            },
            realEstateAgencyId
          )
        );

        if (!!existing && !!existing.results && !!existing.results.length) {
          contactPerson = {
            ...contactPerson,
            isNew: false,
          };
        }
      }

      const response = await parseRequest.response(
        ContactPersons.save({ contactPerson }, realEstateAgencyId)
      );

      if (!!close) {
        dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.IDLE));
        dispatch(EditableThunks.remove(uri));
        return;
      }

      if (!!editable && !!editable.caller) {
        dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.IDLE));
        dispatch(push(editable.caller));
        dispatch(EditableThunks.remove(uri));
        return;
      }

      dispatch(RelationActions.searchContactPerson(response));
      dispatch(
        RelationActions.setSaveRelationContactInfoState(REQUEST.SUCCESS)
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: response.contactPerson,
          path: uri,
          ignoreChanges: true,
        })
      );
      dispatch(push(path));

      if (
        person.isNew &&
        state.relation.detail.pathForNewRelation &&
        state.relation.detail.pathForNewRelation.length > 0
      ) {
        const relationSnapShot: RelationSnapShot = {
          id: response.contactPerson.id,
          displayName: response.contactPerson.displayName,
          typeOfRelation: RelationType.ContactPerson,
          isActive: true,
          dateTimeCreated: response.contactPerson.dateTimeCreated,
          dateTimeModified: response.contactPerson.dateTimeModified,
        };

        updateOriginalItemWithNewRelation(
          state,
          dispatch,
          person.id,
          relationSnapShot
        );
      }
    } catch (error) {
      dispatch(RelationActions.setSaveRelationContactInfoState(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateOriginalItemWithNewRelation = (
  state: AppState,
  dispatch: Dispatch<any>,
  relationId: string,
  relationSnapShot: RelationSnapShot
) => {
  for (let i = 0; i < state.relation.detail.pathForNewRelation.length; i++) {
    const pathForNewRelation = state.relation.detail.pathForNewRelation[i];
    if (pathForNewRelation.id === relationId) {
      const componentStateToUpdate = {
        ...state.editable.states.find(
          (state) => state.path === pathForNewRelation.path
        ).componentState,
      };
      componentStateToUpdate[pathForNewRelation.property] =
        componentStateToUpdate[pathForNewRelation.property]
          ? [
              ...componentStateToUpdate[pathForNewRelation.property],
              relationSnapShot,
            ]
          : [relationSnapShot];

      dispatch(
        EditableActions.updateComponentState({
          componentState: componentStateToUpdate,
          path: pathForNewRelation.path,
        })
      );
    }
  }
};

const newContactPerson = (
  caller?: string,
  newName?: string,
  newEmail?: string,
  newPhone?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);

    try {
      let contactPerson = await parseRequest.response(
        ContactPersons.defineNew({}, realEstateAgencyId).then(
          (response) => response.contactPerson
        )
      );

      if (newName) {
        const names = newName.split(" ");
        const middleName = [];
        const lastName = [];

        for (let i = 0; i < names.length; i++) {
          const name = names[i];
          if (i > 0) {
            if (NameUtil.isDutchPrefix(name)) {
              middleName.push(name);
            } else {
              lastName.push(name);
            }
          }
        }

        contactPerson = {
          ...contactPerson,
          firstName: names[0],
          nickname: names[0],
          middleName: middleName.join(" "),
          lastName: lastName.join(" "),
        };
      }

      if (newEmail) {
        contactPerson = {
          ...contactPerson,
          emailAddresses: [
            {
              address: newEmail,
              type: EmailAddressType.Home,
            },
          ],
        };
      }

      if (newPhone) {
        contactPerson = {
          ...contactPerson,
          phoneNumbers: [{ number: newPhone, type: PhoneNumberType.Home }],
        };
      }

      const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
        id: contactPerson.id,
      });
      const redirect = route(RELATIONROUTES.CONTACT_PERSON_EDIT.URI, {
        id: contactPerson.id,
      });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.RELATIONS.ICON,
          componentState: contactPerson,
          path,
          title: "...",
          caller,
          confirm: {
            title: { key: "savePersonConfirmTitle" },
            body: { key: "savePersonConfirmBody" },
          },
          entityType: RootEntityType.ContactPerson,
          entityId: contactPerson.id,
        })
      );
      dispatch(push(redirect));
      dispatch(RelationActions.setContactPerson({ contactPerson }));
    } catch (error) {
      throw error;
    }
  };
};

const newContactCompany = (
  caller?: string,
  newName?: string,
  newEmail?: string,
  newPhone?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactInfoState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      let contactCompany = await parseRequest.response(
        ContactCompanies.defineNew({}, realEstateAgencyId).then(
          (response) => response.contactCompany
        )
      );

      const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: contactCompany.id,
      });
      const redirect = route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, {
        id: contactCompany.id,
      });

      if (newName) {
        contactCompany = {
          ...contactCompany,
          displayName: newName,
        };
      }

      if (newEmail) {
        contactCompany = {
          ...contactCompany,
          emailAddresses: [
            {
              address: newEmail,
              type: EmailAddressType.Work,
            },
          ],
        };
      }
      if (newPhone) {
        contactCompany = {
          ...contactCompany,
          phoneNumbers: [{ number: newPhone, type: PhoneNumberType.Work }],
        };
      }

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.RELATIONS.ICON,
          componentState: contactCompany,
          path,
          title: "...",
          caller,
          confirm: {
            title: { key: "savePersonConfirmTitle" },
            body: { key: "savePersonConfirmBody" },
          },
          entityType: RootEntityType.ContactCompany,
          entityId: contactCompany.id,
        })
      );
      dispatch(push(redirect));
      dispatch(RelationActions.setContactCompany({ contactCompany }));
    } catch (error) {
      throw error;
    }
  };
};

const deleteContactLink = (contactLink: ContactLinkSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const ContactLinks = new ContactLinksClient(host);

    try {
      parseRequest.response(
        ContactLinks.delete(
          contactLink.sourceRelation.id,
          contactLink.targetRelation.id,
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteContactLink = (contactLink: ContactLinkSnapShot) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactLinks = new ContactLinksClient(host);

    try {
      parseRequest.response(
        ContactLinks.undelete(
          {
            sourceRelationId: contactLink.sourceRelation.id,
            targetRelationId: contactLink.targetRelation.id,
          },
          realEstateAgencyId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const saveContactLink = (
  sourceRelationId: string,
  targetRelationId: string,
  isPartnerLink: boolean,
  linkType?: string,
  linkDescription?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setFetchRelationContactLinkState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactLinks = new ContactLinksClient(host);

    try {
      const contactLink = await parseRequest.response(
        ContactLinks.save(
          {
            sourceRelationId,
            targetRelationId,
            isPartnerLink,
            linkType,
          },
          realEstateAgencyId
        )
      );

      dispatch(RelationActions.appendSingleContactLink(contactLink));
      dispatch(
        RelationActions.setFetchRelationContactLinkState(REQUEST.SUCCESS)
      );

      if (isPartnerLink) {
        dispatch(
          EditableActions.updateComponentState({
            componentState: {
              ...state.editable.currentComponentState,
              linkedPartner: {
                displayName:
                  contactLink.contactLinkSnapShot.sourceRelation.displayName,
                id: contactLink.contactLinkSnapShot.sourceRelation.id,
                keepAddressesInSyncWithPartner: false,
              },
            },
            path: RouteUtil.mapStaticRouteValues(
              RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
              { id: sourceRelationId }
            ),
          })
        );
      }
    } catch (error) {
      dispatch(RelationActions.setFetchRelationContactLinkState(REQUEST.ERROR));
      throw error;
    }
  };
};

const updateContactLink = (
  sourceRelationId: string,
  targetRelationId: string,
  isPartnerLink: boolean,
  linkType?: string,
  linkDescription?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setSaveContactLinkState(REQUEST.PENDING));

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactLinks = new ContactLinksClient(host);

    try {
      const contactLink = await parseRequest.response(
        ContactLinks.save(
          {
            sourceRelationId,
            targetRelationId,
            isPartnerLink,
            linkType,
          },
          realEstateAgencyId
        )
      );

      dispatch(RelationActions.updateContactLink(contactLink));
      dispatch(RelationActions.setSaveContactLinkState(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(RelationActions.setSaveContactLinkState(REQUEST.ERROR));
      throw error;
    }
  };
};

const searchAddress = (
  location: string,
  countryIso2: string,
  isPostalAddress: boolean,
  relationId: string,
  relationType: RelationType
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(RelationActions.setAddressSearchState(REQUEST.PENDING));

    const state = getState();
    const { culture } = state.main;
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { currentComponentState } = state.editable;
    let route = "";

    if (relationType === RelationType.ContactPerson) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
        { id: relationId }
      );
    } else if (relationType === RelationType.ContactCompany) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
        { id: relationId }
      );
    }

    const Geo = new GeoClient(host);

    try {
      const addressResponse = await parseRequest.response(
        Geo.addressSearch(
          {
            countryIso2,
            location,
            culture,
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      if (isPostalAddress) {
        const postalAddress = !!addressResponse
          ? addressResponse
          : {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: location, id: 0 },
            };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, postalAddress },
            path: route,
          })
        );
      } else {
        const address = !!addressResponse
          ? addressResponse
          : {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: location, id: 0 },
            };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, address },
            path: route,
          })
        );
      }

      dispatch(RelationActions.setAddressSearchState(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(RelationActions.setAddressSearchState(REQUEST.ERROR));

      if (isPostalAddress) {
        const postalAddress = {
          ...EMPTY_ADDRESS,
          countryIso2: currentComponentState.address.countryIso2,
          street: { name: location, id: 0 },
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, postalAddress },
            path: route,
          })
        );
      } else {
        const address = {
          ...EMPTY_ADDRESS,
          countryIso2: currentComponentState.address.countryIso2,
          street: { name: location, id: 0 },
        };

        dispatch(
          EditableActions.updateComponentState({
            componentState: { ...currentComponentState, address },
            path: route,
          })
        );
      }

      throw error;
    }
  };
};

const addManualAddress = (
  newAddress: string,
  isPostalAddress: boolean,
  relationId: string,
  relationType: RelationType
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;

    let route = "";
    if (relationType === RelationType.ContactPerson) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
        { id: relationId }
      );
    } else if (relationType === RelationType.ContactCompany) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
        { id: relationId }
      );
    }

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...state.editable.currentComponentState,
            postalAddress: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: newAddress, id: 0 },
            },
          },
          path: route,
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            address: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
              street: { name: newAddress, id: 0 },
            },
          },
          path: route,
        })
      );
    }
  };
};

const removeRelationAddress = (
  isPostalAddress: boolean,
  relationId: string,
  relationType: RelationType
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    let route = "";
    if (relationType === RelationType.ContactPerson) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
        { id: relationId }
      );
    } else if (relationType === RelationType.ContactCompany) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
        { id: relationId }
      );
    }

    if (isPostalAddress) {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...state.editable.currentComponentState,
            postalAddressDiffersVisitAddress: false,
            postalAddress: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
            },
          },
          path: route,
        })
      );
    } else {
      dispatch(
        EditableActions.updateComponentState({
          componentState: {
            ...currentComponentState,
            address: {
              ...EMPTY_ADDRESS,
              countryIso2: currentComponentState.address.countryIso2,
            },
          },
          path: route,
        })
      );
    }
  };
};

const toggleRelationPostalAddress = (
  relationId: string,
  relationType: RelationType
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { currentComponentState } = state.editable;
    let route = "";
    if (relationType === RelationType.ContactPerson) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
        { id: relationId }
      );
    } else if (relationType === RelationType.ContactCompany) {
      route = RouteUtil.mapStaticRouteValues(
        RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI,
        { id: relationId }
      );
    }
    dispatch(
      EditableActions.updateComponentState({
        componentState: {
          ...currentComponentState,
          postalAddressDiffersVisitAddress:
            !currentComponentState.postalAddressDiffersVisitAddress,
        },
        path: route,
      })
    );
  };
};

const getRelationsWithMatchingEmailAddress = (
  emailAddresses: string[],
  relationTypes: RelationType[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Relations = new RelationsClient(host);

    try {
      const response = await Relations.search(
        {
          filterByRelationTypes: relationTypes,
          filterByActive: ActiveFilter.ActiveOnly,
          orderBy: RelationOrderByField.DisplayName,
          skip: 0,
          take: 100,
          emailAddresses,
          order: SortOrder.Ascending,
          includeStatistics: false,
        },
        realEstateAgencyId
      );

      return response.results;
    } catch (error) {
      throw error;
    }
  };
};

const getRelationsWithMatchingIds = (
  relationIds: string[],
  relationTypes: RelationType[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Relations = new RelationsClient(host);

    try {
      const response = await Relations.search(
        {
          filterByRelationTypes: relationTypes,
          filterByActive: ActiveFilter.ActiveOnly,
          orderBy: RelationOrderByField.DisplayName,
          skip: 0,
          take: 100,
          relationIds,
          order: SortOrder.Ascending,
          includeStatistics: false,
        },
        realEstateAgencyId
      );

      return response.results;
    } catch (error) {
      throw error;
    }
  };
};

const getRelationsWithMatchingEmailAddressOrPhoneNumber = (
  emailAddresses: string[],
  relationTypes: RelationType[],
  phoneNumber?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Relations = new RelationsClient(host);

    try {
      const email = !emailAddresses
        ? []
        : await Relations.search(
            {
              filterByRelationTypes: relationTypes,
              filterByActive: ActiveFilter.ActiveOrInactive,
              orderBy: RelationOrderByField.DisplayName,
              skip: 0,
              take: 25,
              emailAddresses,
              order: SortOrder.Ascending,
              includeStatistics: false,
            },
            realEstateAgencyId
          ).then((response) => response.results);

      const phone = !phoneNumber
        ? []
        : await Relations.search(
            {
              filterByRelationTypes: relationTypes,
              filterByActive: ActiveFilter.ActiveOrInactive,
              orderBy: RelationOrderByField.DisplayName,
              skip: 0,
              take: 20,
              order: SortOrder.Ascending,
              includeStatistics: false,
              phoneNumber,
            },
            realEstateAgencyId
          ).then((response) => response.results);

      const results = [
        ...(!!emailAddresses?.length ? email : []),
        ...(!!phoneNumber ? phone : []),
      ];

      return uniqBy(results, (relation) => relation.id);
    } catch (error) {
      throw error;
    }
  };
};

const addPhoneNumber = (
  id: string,
  typeOfRelation: RelationType,
  phoneNumber: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      const toast: ToastProps = {
        value: "toastAddedRelationPhonenumber",
        icon: "phone",
      };

      switch (typeOfRelation) {
        case RelationType.ContactPerson: {
          let contactPerson = await parseRequest.response(
            ContactPersons.read(id, realEstateAgencyId).then(
              (response) => response.contactPerson
            )
          );

          contactPerson = {
            ...contactPerson,
            phoneNumbers: [
              ...(contactPerson.phoneNumbers || []),
              { type: PhoneNumberType.Home, number: phoneNumber },
            ],
          };

          await parseRequest.response(
            ContactPersons.save({ contactPerson }, realEstateAgencyId)
          );

          dispatch(SnackbarActions.addToast(toast));
          return;
        }
        case RelationType.ContactCompany: {
          let contactCompany = await parseRequest.response(
            ContactCompanies.read(id, realEstateAgencyId).then(
              (response) => response.contactCompany
            )
          );

          contactCompany = {
            ...contactCompany,
            phoneNumbers: [
              ...(contactCompany.phoneNumbers || []),
              { type: PhoneNumberType.Home, number: phoneNumber },
            ],
          };

          await parseRequest.response(
            ContactCompanies.save({ contactCompany }, realEstateAgencyId)
          );

          dispatch(SnackbarActions.addToast(toast));
          return;
        }
        default:
          return;
      }
    } catch (error) {
      throw error;
    }
  };
};

const archiveContactPerson = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);

    try {
      await parseRequest.response(
        ContactPersons.archive({ id }, realEstateAgencyId)
      );
      const contactPerson = await parseRequest.response(
        ContactPersons.read(id, realEstateAgencyId).then(
          (response) => response.contactPerson
        )
      );
      const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
        id: contactPerson.id,
      });

      dispatch(RelationActions.setContactPerson({ contactPerson }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: contactPerson,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const unArchiveContactPerson = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);

    try {
      await parseRequest.response(
        ContactPersons.unarchive({ id }, realEstateAgencyId)
      );
      const contactPerson = await parseRequest.response(
        ContactPersons.read(id, realEstateAgencyId).then(
          (response) => response.contactPerson
        )
      );
      const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
        id: contactPerson.id,
      });

      dispatch(RelationActions.setContactPerson({ contactPerson }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: contactPerson,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const archiveContactCompany = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      await parseRequest.response(
        ContactCompanies.archive({ id }, realEstateAgencyId)
      );
      const contactCompany = await parseRequest.response(
        ContactCompanies.read(id, realEstateAgencyId).then(
          (response) => response.contactCompany
        )
      );
      const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: contactCompany.id,
      });

      dispatch(RelationActions.setContactCompany({ contactCompany }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: contactCompany,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const unArchiveContactCompany = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      await parseRequest.response(
        ContactCompanies.unarchive({ id }, realEstateAgencyId)
      );
      const contactCompany = await parseRequest.response(
        ContactCompanies.read(id, realEstateAgencyId).then(
          (response) => response.contactCompany
        )
      );
      const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: contactCompany.id,
      });

      dispatch(RelationActions.setContactCompany({ contactCompany }));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: contactCompany,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const deleteRelationV2 = (
  id: string,
  displayName: string = "",
  typeOfRelation: RelationType
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const path =
      typeOfRelation === RelationType.ContactPerson
        ? route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
        : route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      await parseRequest.response(
        typeOfRelation === RelationType.ContactPerson
          ? ContactPersons.delete(id, realEstateAgencyId)
          : ContactCompanies.delete(id, realEstateAgencyId)
      );

      dispatch(EditableThunks.remove(path));
      dispatch(
        SnackbarActions.addToast({
          value: "relation.toast.deleted",
          values: { displayName: StringUtil.trim(displayName, 48) },
          callback: async () => {
            await dispatch(unDeleteRelationV2(id, typeOfRelation));
            dispatch(push(path));
          },
          callbackLabel: "relation.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const unDeleteRelationV2 = (id: string, typeOfRelation: RelationType) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const ContactPersons = new ContactPersonsClient(host);
    const ContactCompanies = new ContactCompaniesClient(host);

    try {
      await parseRequest.response(
        typeOfRelation === RelationType.ContactPerson
          ? ContactPersons.undelete({ id }, realEstateAgencyId)
          : ContactCompanies.undelete({ id }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const checkExternalChangesCompany = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
      modifiedBy,
    } = entityDetails;
    if (!entityId) return;

    const componentState: ContactCompany = editable.componentState;
    if (!componentState) return;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new ContactCompaniesClient(host);

    try {
      const contactCompany = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.contactCompany),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: ContactCompany = {
          ...componentState,
          ...contactCompany,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(RelationActions.setContactCompany({ contactCompany }));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = contactCompany;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: contactCompany,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadContactCompany = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const contactCompany = externalChangesData.updatedEntity as ContactCompany;
    const componentState: ContactCompany = {
      ...editable.componentState,
      ...contactCompany,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(RelationActions.setContactCompany({ contactCompany }));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const checkExternalChangesPerson = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: ContactPerson = editable.componentState;
    if (!componentState) return;

    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new ContactPersonsClient(host);

    try {
      const contactPerson = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.contactPerson),
        ApiType.Kolibri,
        { displayError: false }
      );

      const { componentState, hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: ContactPerson = {
          ...componentState,
          ...contactPerson,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(RelationActions.setContactPerson({ contactPerson }));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = contactPerson;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: contactPerson,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadContactPerson = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const contactPerson = externalChangesData.updatedEntity as ContactPerson;
    const componentState: ContactPerson = {
      ...editable.componentState,
      ...contactPerson,
    };

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(RelationActions.setContactPerson({ contactPerson }));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const enrichContactPerson = (
  id: string,
  phoneNumbers: PhoneNumber[],
  emailAddresses: EmailAddress[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new ContactPersonsClient(host);

    try {
      let contactPerson = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.contactPerson)
      );
      if (!contactPerson) return;

      contactPerson = {
        ...contactPerson,
        emailAddresses: [
          ...(contactPerson.emailAddresses || []),
          ...emailAddresses,
        ],
        phoneNumbers: [...(contactPerson.phoneNumbers || []), ...phoneNumbers],
      };

      await parseRequest.response(
        client.save({ contactPerson }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const enrichContactCompany = (
  id: string,
  phoneNumbers: PhoneNumber[],
  emailAddresses: EmailAddress[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new ContactCompaniesClient(host);

    try {
      let contactCompany = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.contactCompany)
      );
      if (!contactCompany) return;

      contactCompany = {
        ...contactCompany,
        emailAddresses: [
          ...(contactCompany.emailAddresses || []),
          ...emailAddresses,
        ],
        phoneNumbers: [...(contactCompany.phoneNumbers || []), ...phoneNumbers],
      };

      await parseRequest.response(
        client.save({ contactCompany }, realEstateAgencyId)
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateContactCompanyListItem = (relationId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { relations } = state.relation.overview;
    const { features } = state.appSettings;

    let ref: RelationSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.relation.list.relations || []).find(
        (relation) => !!relation && relation.id === relationId
      );
    } else {
      ref = (relations || []).find((relation) => relation.id === relationId);
    }

    if (!ref) return;

    try {
      const client = new ContactCompaniesClient(host);
      const relation = await parseRequest.response(
        client
          .read(relationId, realEstateAgencyId)
          .then((response) => response?.contactCompany)
      );
      if (!relation) return;

      const snapshot = mapContactCompanyToRelationSnapShot(relation);

      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(RelationListActions.updateItem(snapshot));
      } else {
        dispatch(RelationActions.updateListItem({ snapshot }));
      }
    } catch (error) {
      throw error;
    }
  };
};

const updateContactPersonListItem = (relationId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { relations } = state.relation.overview;
    const { features } = state.appSettings;

    let ref: RelationSnapShot;

    if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
      ref = (state.relation.list.relations || []).find(
        (relation) => !!relation && relation.id === relationId
      );
    } else {
      ref = (relations || []).find((relation) => relation.id === relationId);
    }

    if (!ref) return;

    try {
      const client = new ContactPersonsClient(host);
      const relation = await parseRequest.response(
        client
          .read(relationId, realEstateAgencyId)
          .then((response) => response?.contactPerson)
      );
      if (!relation) return;

      const snapshot = mapContactPersonToRelationSnapShot(relation);

      if (FeatureHelper.executeBlock(features, "VIRTUALIZED_LISTS")) {
        dispatch(RelationListActions.updateItem(snapshot));
      } else {
        dispatch(RelationActions.updateListItem({ snapshot }));
      }
    } catch (error) {
      throw error;
    }
  };
};

const exportSearchAssignmentRelations = (relationIds: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { token } = state.access;

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
      responseType: "arraybuffer",
    };

    const response = await Axios.post(
      `${host}/${apiVersion}/${realEstateAgencyId}/Relations/Export`,
      { relationIds },
      config
    )
      .then((response) => {
        return new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
      });

    saveAs(response, `relatie-export-${moment().format("DDMMYYYYHHmm")}.xlsx`);
  };
};

const getListItems = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { host } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { filters, order } = state.relation.list;
      const { localities, relationTypes, activeInactive, relationGroupIds } =
        filters;
      const client = new RelationsClient(host);

      const filterByActive =
        activeInactive.value.length === 1
          ? activeInactive.value[0]
          : ActiveFilter.ActiveOrInactive;
      const filterByRelationTypes = !relationTypes.value.length
        ? [RelationType.ContactCompany, RelationType.ContactPerson]
        : relationTypes.value;

      const response = await parseRequest.response(
        client.search(
          {
            includeStatistics: startIndex === 0,
            orderBy: order.sortColumn,
            filterByActive,
            order: order.sortOrder,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByRelationTypes,
            filterByRelationGroupIds: relationGroupIds.value,
            termFields: !!localities.value
              ? [RelationTermField.Locality]
              : undefined,
            term: localities.value,
          },
          realEstateAgencyId
        )
      );

      if (!response) {
        return;
      }

      const { results, totalResults, statistics } = response;
      dispatch(
        RelationListActions.updateList(
          startIndex,
          results,
          totalResults,
          statistics
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const exportListToExcel = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host, apiVersion } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { culture } = state.main;
    const { token: access_token } = state.access;
    const { filters } = state.relation.list;
    const { localities, relationTypes, activeInactive, relationGroupIds } =
      filters;

    const filterByActive =
      activeInactive.value.length === 1
        ? activeInactive.value[0]
        : ActiveFilter.ActiveOrInactive;
    const filterByRelationTypes = !relationTypes.value.length
      ? [RelationType.ContactCompany, RelationType.ContactPerson]
      : relationTypes.value;

    let props: { [key: string]: any } = {
      filterByActive,
      filterByRelationTypes,
      filterByRelationGroupIds: relationGroupIds.value,
      culture,
      access_token,
    };

    if (!!localities.value) {
      props = {
        ...props,
        termFields: [RelationTermField.Locality],
        term: localities.value,
      };
    }

    // const url = `${host}/${apiVersion}/${realEstateAgencyId}/Relations/SearchAndExport?${RouteUtil.mapObjectToGetParams(
    //   props
    // )}`;

    // window.open(url, "_blank");

    const { token } = state.access;

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      httpAgent: new HttpAgent({ keepAlive: true }),
      httpsAgent: new HttpsAgent({ keepAlive: true }),
      responseType: "arraybuffer",
    };

    const response = await Axios.post(
      `${host}/${apiVersion}/${realEstateAgencyId}/Relations/Export`,
      props,
      config
    ).then((response) => {
      return new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    });

    saveAs(response, `relaties-export-${moment().format("DDMMYYYYHHmm")}.xlsx`);
  };
};

export const RelationThunks = {
  getListItems,
  addManualAddress,
  archiveRelation,
  deleteContactLink,
  exportRelations,
  getContactCompanyInfo,
  getContactInfo,
  getContactLinks,
  getContactPersonInfo,
  getRelationGroups,
  getRelations,
  getTimelineEvents,
  removeRelationAddress,
  saveContactCompany,
  saveContactLink,
  saveContactPerson,
  searchAddress,
  toggleRelationPostalAddress,
  unArchiveRelation,
  unDeleteContactLink,
  updateContactCompanyLogo,
  updateContactLink,
  updateContactPersonPassportPhoto,
  newContactPerson,
  newContactCompany,
  getRelationsWithMatchingEmailAddress,
  getRelationsWithMatchingEmailAddressOrPhoneNumber,
  deleteRelation,
  unDeleteRelation,
  addPhoneNumber,
  archiveContactPerson,
  unArchiveContactPerson,
  archiveContactCompany,
  unArchiveContactCompany,
  deleteRelationV2,
  checkExternalChangesCompany,
  checkExternalChangesPerson,
  reloadContactCompany,
  reloadContactPerson,
  enrichContactPerson,
  enrichContactCompany,
  updateContactCompanyListItem,
  exportSearchAssignmentRelations,
  exportListToExcel,
  searchRelations,
  updateContactPersonListItem,
  getRelationsWithMatchingIds,
};
