import * as React from "react";
import { memo, FC, useCallback, useState, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import I18n from "@haywork/components/i18n";
import { PriceHistoryContainerProps } from "./container";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import { useIntl } from "react-intl";
import { MutationType, Mutation } from "@haywork/api/mls";
import * as moment from "moment";

const styles = require("./style.scss");

interface $Mutation extends Mutation {
  arrow?: string;
  arrowColor?: string;
}

export type PriceHistoryProps = {
  visible: boolean;
  data: $Mutation[];
  onCloseHandler: () => void;
};

const lowerPrice = {
  arrow: "long-arrow-alt-down",
  arrowColor: Colors.Warning,
};

const higherPrice = {
  arrow: "long-arrow-alt-up",
  arrowColor: Colors.Success,
};

const samePrice = {
  arrow: "equals",
  arrowColor: Colors.Gray,
};

const currentPrice = {
  arrow: "euro-sign",
  arrowColor: Colors.Primary,
};

type Props = PriceHistoryProps & PriceHistoryContainerProps;

export const PriceHistoryComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ visible, onCloseHandler, data }) => {
      const intl = useIntl();

      const orderedByDate = useMemo(() => {
        return [...data]
          .filter((mutation) => mutation.type === MutationType.PriceChange)
          .sort((a, b) => {
            return a.mutationDateTime < b.mutationDateTime ? 1 : -1;
          });
      }, [data]);

      const setPriceArrow = useMemo(() => {
        return orderedByDate.map((value, index) => {
          switch (true) {
            case value.newPrice === value.oldPrice:
              value = { ...value, ...samePrice };
              break;
            case value.newPrice > value.oldPrice:
              value = { ...value, ...higherPrice };
              break;
            case value.newPrice < value.oldPrice:
              value = { ...value, ...lowerPrice };
              break;
            default:
              break;
          }
          return value;
        });
      }, [orderedByDate]);

      const price = useCallback((price: number) => {
        return intl.formatNumber(price, {
          style: "currency",
          currency: "EUR",
          maximumSignificantDigits: 6,
        });
      }, []);

      const date = useCallback((date: Date) => {
        return moment(date).format("DD-MM-YYYY");
      }, []);

      return (
        <>
          <Modal visible={visible} onClose={onCloseHandler}>
            <ModalHeader
              title="mls.priceHistory.modal.title"
              titleValues={{ count: orderedByDate ? orderedByDate.length : 0 }}
              close
            />
            <ModalBody>
              <ol styleName="mlsModalList">
                {setPriceArrow.map((mutation) => {
                  return (
                    <li key={mutation.mutationDateTime.toString()}>
                      <div styleName="mlsPriceHistoryItem">
                        <span styleName="icon">
                          <Icon
                            size={15}
                            name={mutation.arrow}
                            color={mutation.arrowColor}
                            fixedWidth
                          />
                        </span>
                        <I18n
                          value="mls.priceHistory.modal.priceWas"
                          values={{
                            oldPrice: price(mutation.oldPrice),
                            newPrice: price(mutation.newPrice),
                            date: date(mutation.mutationDateTime),
                          }}
                          asHtml
                        />
                      </div>
                    </li>
                  );
                })}
              </ol>
            </ModalBody>
          </Modal>
        </>
      );
    }
  )
);
