import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { PROJECTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";

import { ProjectEditMarketingRouting } from "@haywork/modules/project/components/edit-marketing/edit-marketing.routing";
import { ProjectEditMarketingContainerProps } from "@haywork/modules/project/containers/edit-marketing.container";

const styles = require("./edit-marketing.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface ProjectEditMarketingComponentProps {
}

interface ProjectEditMarketingComponentState {
}

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditMarketingComponent extends React.Component<ProjectEditMarketingComponentProps & ProjectEditMarketingContainerProps, ProjectEditMarketingComponentState> {
  public render() {
    const { id } = this.props.projectAssignment;

    return <div styleName="marketing" id="scroll-to-top">
      <div styleName="marketing__body">
        <div className="container-fluid">
          <ProjectEditMarketingRouting id={ id } />
        </div>
      </div>
      <div styleName="marketing__sidebar">
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_PHOTOS.URI, { id }) } activeClassName="active" data-cy="CY-marketingPhotos">
          <div styleName="section">
            <i className="fal fa-image" />
            <ResourceText resourceKey="assignmentMediaLinkPhotos" />
          </div>
        </NavLink>
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_TEXT.URI, { id }) } activeClassName="active" data-cy="CY-marketingText">
          <div styleName="section">
            <i className="fal fa-align-left" />
            <ResourceText resourceKey="assignmentMediaLinkText" />
          </div>
        </NavLink>
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_PARTNERS.URI, { id }) } activeClassName="active" data-cy="CY-marketingPartners">
          <div styleName="section">
            <i className="fal fa-cloud" />
            <ResourceText resourceKey="assignmentMediaLinkPartners" />
          </div>
        </NavLink>
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_MAPS.URI, { id }) } activeClassName="active" data-cy="CY-marketingMaps">
          <div styleName="section">
            <i className="fal fa-map" />
            <ResourceText resourceKey="assignmentMediaLinkMaps" />
          </div>
        </NavLink>
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_VIDEOS.URI, { id }) } activeClassName="active" data-cy="CY-marketingVideo">
          <div styleName="section">
            <i className="fal fa-video" />
            <ResourceText resourceKey="projectMediaLinkVideos" />
          </div>
        </NavLink>
        <NavLink to={ route(PROJECTROUTES.EDIT_MARKETING_OPENHOUSE.URI, { id }) } activeClassName="active" data-cy="CY-marketingOpenHouse">
          <div styleName="section">
            <i className="fal fa-home" />
            <ResourceText resourceKey="assignmentMediaLinkOpenhouse" />
          </div>
        </NavLink>
      </div>
    </div>;
  }
}
