import { Event } from "@haywork/api/mail";
import Iframe from "@haywork/components/iframe";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./detail.component.scss");

type Props = {
  body: string;
  events: Event[];
};

export const EmailBody: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ body, events }) => {
    const eventSummary = useMemo(() => {
      if (!isArray(events) || !events.length) return null;
      const event = first(events);
      return event.eventSummary;
    }, [events]);

    const extendedBody = useMemo(() => {
      let extendedBody = body || "";
      if (!!eventSummary) {
        extendedBody = `
        <p>${eventSummary}</p>
        ${extendedBody}
      `;
      }

      return extendedBody;
    }, [body, eventSummary]);

    return (
      <Iframe linkify autoHeight>
        <div
          styleName="email-body"
          dangerouslySetInnerHTML={{ __html: extendedBody }}
        />
      </Iframe>
    );
  })
);
