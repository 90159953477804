import {
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentsClient,
  AssignmentSnapShot,
  AssignmentType,
  CadastresClient,
  RealEstateGroup,
  SortOrder,
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import AbortController from "@haywork/services/abort.service";
import { store } from "@haywork/stores";

const parseRequest = new ParseRequest();

const abortCadastreSearch = new AbortController();
const buyAndSaveMunicipalities = async (
  term: string,
  realEstateAgencyId: string,
  take: number,
  host: string
) => {
  try {
    await abortCadastreSearch.process();

    const client = new CadastresClient(host, abortCadastreSearch.fetch());

    return parseRequest.response(
      client
        .buyAndSaveMunicipalities(
          {
            term,
            take,
            skip: 0,
            countryIso2: "nl",
          },
          realEstateAgencyId
        )
        .then((response) => response.results)
    );
  } catch (error) {
    throw error;
  }
};

export type ExtendedAssignmentSnapshot = AssignmentSnapShot & {
  extended?: boolean;
};

const abortAssignmentSearch = new AbortController();
const search = async (
  term: string,
  take: number = 5,
  realEstateGroups: RealEstateGroup[] = [
    RealEstateGroup.Residential,
    RealEstateGroup.Commercial,
    RealEstateGroup.Agricultural,
  ],
  forSale: boolean = true,
  forRent: boolean = true,
  filterByActive: ActiveFilter = ActiveFilter.ActiveOnly,
  filterByAssignmentTypes?: AssignmentType[],
  assignmentIds?: string[],
  orderBy?: AssignmentOrderByField
): Promise<AssignmentSnapShot[]> => {
  try {
    await abortAssignmentSearch.process();

    const state = store.getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const client = new AssignmentsClient(host, abortAssignmentSearch.fetch());

    return await parseRequest.response(
      client
        .search(
          {
            forSale,
            forRent,
            orderBy: orderBy || AssignmentOrderByField.AssignmentType,
            includeStatistics: true,
            filterByActive,
            skip: 0,
            take,
            order: SortOrder.Descending,
            term,
            filterByRealEstateGroups: realEstateGroups,
            filterByAssignmentTypes,
            assignmentIds:
              assignmentIds && assignmentIds.length ? assignmentIds : undefined,
          },
          realEstateAgencyId
        )
        .then((response) =>
          (response.results || [])
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .map(
              (item) =>
                ({ ...item, extended: true } as ExtendedAssignmentSnapshot)
            )
        )
    );
  } catch (error) {
    throw error;
  }
};

export const Assignment = {
  buyAndSaveMunicipalities,
  search,
};
