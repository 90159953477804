import * as React from "react";
import classNames from "classnames";
import * as CSSModules from "react-css-modules";

const styles = require("./modal-body.component.scss");

interface ModalBodyComponentProps {
  noPadding?: boolean;
}
interface ModalBodyComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ModalBody extends React.Component<
  ModalBodyComponentProps,
  ModalBodyComponentState
> {
  public render() {
    const bodyStyle = classNames("body", {
      "no-padding": this.props.noPadding,
    });

    return <div styleName={bodyStyle}>{this.props.children}</div>;
  }
}
