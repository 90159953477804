import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  snapshot: AssignmentSnapShot;
  query: string;
};

export const ItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ snapshot, query }) => {
    const { displayName, isActive } = snapshot;

    return (
      <div styleName="item">
        <div
          dangerouslySetInnerHTML={StringUtil.highlight(displayName, query)}
          styleName="displayname"
        />
        {!isActive && (
          <div styleName="archived">
            <I18n value="archived" />
          </div>
        )}
      </div>
    );
  })
);
