import classNames from "classnames";
import * as React from "react";
import { CSSProperties, forwardRef, memo, ReactNode, Ref } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "../../i18n";

const styles = require("./style.scss");

interface Props {
  icon?: ReactNode;
  iconPosition?: "start" | "end";
  label?: string;
  labelValues?: Record<string, any>;
  type?: "button" | "submit" | "reset";
  href?: string;
  disabled?: boolean;
  category?:
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "dark"
    | "white"
    | "none"
    | "export";
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const UiButtonComponent = memo(
  forwardRef(
    CSSModules(styles, { allowMultiple: true })(
      (
        {
          icon,
          iconPosition,
          label,
          labelValues,
          type,
          href,
          disabled,
          category,
          style,
          onClick,
        }: Props,
        ref: Ref<HTMLButtonElement>
      ) => {
        const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
          if (!!href) {
            window.open(href, "_blank");
          }

          if (!!onClick && typeof onClick === "function") {
            onClick(e);
          }
        };

        return (
          <button
            type={type || "button"}
            disabled={disabled}
            styleName={classNames("button", category || "primary", {
              "icon-only": !!icon && !label,
            })}
            onClick={onClickHandler}
            style={style}
            ref={ref}
          >
            <div styleName="button__inner">
              {!!icon && iconPosition !== "end" && icon}
              {!!label && (
                <span
                  styleName={classNames({
                    "push-icon-left": !!icon && iconPosition !== "end",
                    "push-icon-right": !!icon && iconPosition === "end",
                  })}
                >
                  <I18n value={label} values={labelValues} />
                </span>
              )}
              {!!icon && iconPosition === "end" && icon}
            </div>
          </button>
        );
      }
    )
  )
);
