import { MapDispatchToProps, EmailAccountThunks } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import {
  EmailActionsV2,
  EmailDraft,
  EmailMessage,
} from "@haywork/stores/email-v2";
import { Account, ReauthenticateAccountRequest } from "@haywork/api/mail";
import { connect, MapStateToProps } from "react-redux";
import { EmailComponent, EmailComponentProps } from "./email";
import { currentMessageId } from "./selectors";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { EmailRequestState } from "@haywork/stores/email-v2/main";

type StateProps = {
  currentMessageId: string;
  accounts: Account[];
  accountsAndFoldersVisible: boolean;
  loggedInEmployeeId: string;
  employees: RelationSnapShot[];
  requestState: EmailRequestState;
};
type DispatchProps = {
  getMessages: (
    skip: number,
    accountId: string,
    folderId: string
  ) => Promise<EmailMessage[]>;
  getDrafts: (
    skip: number,
    accountId: string,
    folderId: string
  ) => Promise<EmailDraft[]>;
  setCurrentMessage: (message: EmailMessage) => void;
  reAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
  clearAndReloadEmailState: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  EmailComponentProps,
  AppState
> = (state) => {
  const { accountsAndFoldersVisible, requestState } = state.emailV2.main;
  const { accounts } = state.emailV2.accounts;
  const {
    employees,
    employee: { id: loggedInEmployeeId },
  } = state.account;

  return {
    currentMessageId: currentMessageId(state),
    accounts,
    accountsAndFoldersVisible,
    loggedInEmployeeId,
    employees,
    requestState,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EmailComponentProps
> = (dispatch) => ({
  getMessages: (skip: number, accountId: string, folderId: string) =>
    dispatch(EmailThunk.Messages.getMessages(skip, accountId, folderId)),
  getDrafts: (skip: number, accountId: string, folderId: string) =>
    dispatch(EmailThunk.Drafts.getDrafts(skip, accountId, folderId)),
  setCurrentMessage: (message: EmailMessage) =>
    dispatch(
      EmailActionsV2.Folders.setCurrentMessage(message.folderId, message.id)
    ),
  reAuthenticate: (request: ReauthenticateAccountRequest) =>
    dispatch(EmailAccountThunks.reAuthenticate(request)),
  clearAndReloadEmailState: () =>
    dispatch(EmailThunk.Main.clearAndReloadEmailState()),
});

export type EmailContainerProps = StateProps & DispatchProps;
export const EmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailComponent);
