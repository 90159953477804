import * as React from "react";
import { FC, memo, useMemo, useRef, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { ListContainerProps } from "./list.container";
import List, {
  ListWrapper,
  ListColumnConfig,
  ListRefProps,
  ActiveFilters,
  FilterConfig
} from "@haywork/components/ui/list";
import {
  AssignmentSnapShot,
  AssignmentOrderByField,
  SortOrder,
  AssignmentType
} from "@haywork/api/kolibri";
import Row from "./components/row";
import Filters from "./components/filters";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";
import { Colors } from "@haywork/enum/colors";
import Icon from "@haywork/components/ui/icon";

const styles = require("./style.scss");

export type ListComponentProps = {};
type Props = ListComponentProps & ListContainerProps;

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      getListItems,
      assignments,
      totalCount,
      commissionTotal,
      order,
      setOrdering,
      createNewAssignment,
      filters,
      setFilters,
      scrollOffset,
      setScrollOffset,
      activeFilterGuidValues,
      openAssignment,
      openObjectType,
      openProject,
      exportListToExcel
    }) => {
      const listRef = useRef<ListRefProps>();

      const columnConfig = useMemo(() => {
        return {
          columns: [
            {
              label: {
                value: "totalCount",
                values: { count: totalCount }
              },
              width: 172
            },
            {
              label: "",
              width: 300,
              autoFill: true,
              sortConfig: [
                AssignmentOrderByField.LocalityStreetNameAndNumber,
                AssignmentOrderByField.AssignmentType,
                AssignmentOrderByField.ConstructionNumber,
                AssignmentOrderByField.KeyNumber
              ]
            },
            {
              label: {
                value: "revenue",
                values: { revenue: commissionTotal }
              },
              width: 280
            },
            {
              label: "status",
              width: 180,
              sortConfig: [AssignmentOrderByField.ModificationDate]
            }
          ]
        } as ListColumnConfig;
      }, [commissionTotal, totalCount]);

      const onSortChange = useCallback(
        (sortColumn: AssignmentOrderByField, sortOrder: SortOrder) => {
          if (!listRef || !listRef.current) return;
          setOrdering(sortOrder, sortColumn);
          listRef.current.refresh();
        },
        [listRef, setOrdering]
      );

      const onActiveFiltersChange = useCallback(
        (filters: FilterConfig) => {
          if (!listRef || !listRef.current) return;
          setFilters(filters);
          listRef.current.refresh();
        },
        [listRef, setFilters]
      );

      const onNavigate = useCallback(
        (assignment: AssignmentSnapShot) => {
          const { typeOfAssignment, id } = assignment;

          switch (typeOfAssignment) {
            case AssignmentType.Object: {
              return openAssignment(id, assignment);
            }
            case AssignmentType.ObjectType: {
              return openObjectType(id, assignment);
            }
            case AssignmentType.Project: {
              return openProject(id, assignment);
            }
            default: {
              return;
            }
          }
        },
        [openAssignment, openObjectType, openProject]
      );

      return (
        <div styleName="list">
          <ListWrapper
            sidebar={<Filters list={listRef} />}
            filters={
              <ActiveFilters
                prefix="assignments"
                filters={filters}
                guidValues={activeFilterGuidValues}
                onChange={onActiveFiltersChange}
              />
            }
            actions={
              <Button
                label="assignments.list.export"
                category="export"
                icon={<Icon name="file-excel" light color={Colors.White} />}
                onClick={exportListToExcel}
              />
            }
          >
            <List
              name="assignments"
              rowHeight={132}
              columnConfig={columnConfig}
              data={assignments}
              totalCount={totalCount}
              sortColumn={order.sortColumn}
              sortOrder={order.sortOrder}
              loadMore={getListItems}
              minimumBatchSize={50}
              onSortChange={onSortChange}
              initialScrollOffset={scrollOffset}
              onScroll={setScrollOffset}
              ref={listRef}
              emptyState={
                <EmptyState
                  icon="folder-open"
                  title="assignments.list.emptyStateTitle"
                  subTitle="assignments.list.emptyStateSubtitle"
                  action={
                    <Button
                      label="assignments.list.emptyState.action"
                      category="primary"
                      onClick={createNewAssignment}
                    />
                  }
                />
              }
            >
              {(data: AssignmentSnapShot | null, idx) => (
                <Row
                  data={data}
                  zebra={idx % 2 === 0}
                  onNavigate={onNavigate}
                />
              )}
            </List>
          </ListWrapper>
        </div>
      );
    }
  )
);
