import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import AcquistionAssignmentEditGeneralScreenContainer from "../assignments-edit-general";
import { AcquistionAssignmentEditMediaPhotosScreenContainer } from "../assignments-edit-media-photos/assignments-edit-media-photos.container";
import { AcquistionAssignmentEditMediaTextScreenContainer } from "../assignments-edit-media-text/assignments-edit-media-text.container";
import { AcquistionAssignmentEditMediaThreeSixtyScreenContainer } from "../assignments-edit-media-threesixty/assignments-edit-media-threesixty.container";
import { AcquistionAssignmentEditMediaFloorplansScreenContainer } from "../assignments-edit-media-floorplans/assignments-edit-media-floorplans.container";
import { AcquistionAssignmentEditMediaBrochuresScreenContainer } from "../assignments-edit-media-brochures/assignments-edit-media-brochures.container";

const route = RouteUtil.mapStaticRouteValues;
export const AcquisitionAssignmentObjectEditMediaRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA.URI}
        render={() => (
          <Redirect
            to={route(ACQUISITIONOBJECTROUTES.EDIT_MEDIA_PHOTOS.URI, { id })}
          />
        )}
      />
      <Route
        exact
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_PHOTOS.URI}
        component={AcquistionAssignmentEditMediaPhotosScreenContainer}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_TEXT.URI}
        component={AcquistionAssignmentEditMediaTextScreenContainer}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_VIDEO.URI}
        component={AcquistionAssignmentEditGeneralScreenContainer}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_360.URI}
        component={AcquistionAssignmentEditMediaThreeSixtyScreenContainer}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_FLOORPLANS.URI}
        component={AcquistionAssignmentEditMediaFloorplansScreenContainer}
      />
      <Route
        path={ACQUISITIONOBJECTROUTES.EDIT_MEDIA_BROCHURES.URI}
        component={AcquistionAssignmentEditMediaBrochuresScreenContainer}
      />
    </Switch>
  );
};
