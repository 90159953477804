import * as React from "react";
import classNames from "classnames";
import isInt from "lodash-es/isInteger";

import { InputComponentProps } from "../input.component";

interface ReviewInputComponentProps {}
interface ReviewInputComponentState {
  value: number;
  hoverValue: number;
}

export class InputReviewComponent extends React.Component<
  ReviewInputComponentProps & InputComponentProps,
  ReviewInputComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      value:
        [null, undefined].indexOf(this.props.value) === -1
          ? parseInt(this.props.value)
          : 0,
      hoverValue: null,
    };

    this.onStarClick = this.onStarClick.bind(this);
    this.onStarEnterHandler = this.onStarEnterHandler.bind(this);
    this.onStarLeaveHandler = this.onStarLeaveHandler.bind(this);
    this.getStarClassName = this.getStarClassName.bind(this);
    this.getIconClassName = this.getIconClassName.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ReviewInputComponentProps & InputComponentProps
  ) {
    const value =
      [null, undefined].indexOf(nextProps.value) === -1 ? nextProps.value : "";
    if (this.state.value !== value) {
      this.setState({ value });
    }
  }

  public render() {
    return (
      <div className="input__review">
        <div
          className={this.getStarClassName(1)}
          onClick={() => this.onStarClick(1)}
          onMouseEnter={() => this.onStarEnterHandler(1)}
          onMouseLeave={this.onStarLeaveHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.OneRatingButton`
          }
        >
          <i className={this.getIconClassName(1)} />
        </div>
        <div
          className={this.getStarClassName(2)}
          onClick={() => this.onStarClick(2)}
          onMouseEnter={() => this.onStarEnterHandler(2)}
          onMouseLeave={this.onStarLeaveHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.TwoRatingButton`
          }
        >
          <i className={this.getIconClassName(2)} />
        </div>
        <div
          className={this.getStarClassName(3)}
          onClick={() => this.onStarClick(3)}
          onMouseEnter={() => this.onStarEnterHandler(3)}
          onMouseLeave={this.onStarLeaveHandler}
          data-cy={
            this.props["data-cy"] &&
            `${this.props["data-cy"]}.ThreeRatingButton`
          }
        >
          <i className={this.getIconClassName(3)} />
        </div>
        <div
          className={this.getStarClassName(4)}
          onClick={() => this.onStarClick(4)}
          onMouseEnter={() => this.onStarEnterHandler(4)}
          onMouseLeave={this.onStarLeaveHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.FourRatingButton`
          }
        >
          <i className={this.getIconClassName(4)} />
        </div>
        <div
          className={this.getStarClassName(5)}
          onClick={() => this.onStarClick(5)}
          onMouseEnter={() => this.onStarEnterHandler(5)}
          onMouseLeave={this.onStarLeaveHandler}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.FiveRatingButton`
          }
        >
          <i className={this.getIconClassName(5)} />
        </div>
      </div>
    );
  }

  private onStarClick(value: number) {
    this.props.onChange(value);

    this.setState({
      value,
    });
  }

  private onStarEnterHandler(hoverValue: number) {
    this.setState({
      hoverValue,
    });
  }

  private onStarLeaveHandler() {
    this.setState({
      hoverValue: null,
    });
  }

  private getStarClassName(value: number): string {
    let className = "review__star";

    if (
      !this.state.hoverValue &&
      !!this.state.value &&
      value <= this.state.value
    ) {
      className = classNames("review__star", "active");
    }
    if (!!this.state.hoverValue && value <= this.state.hoverValue) {
      className = classNames("review__star", "hover");
    }

    return className;
  }

  private getIconClassName(value: number): string {
    let className = "fal fa-star";

    if (!!this.state.value && value <= this.state.value) {
      className = "fa fa-star";
    }
    if (!!this.state.hoverValue && value > this.state.hoverValue) {
      className = "fal fa-star";
    }

    return className;
  }
}
