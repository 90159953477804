import {
  InvoiceSnapShot,
  InvoicesStatistics,
  SortOrder,
  InvoiceOrderByField
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum ActionType {
  ResetList = "Invoices.List.ResetList",
  UpdateList = "Invoices.List.UpdateList",
  SetOrdering = "Invoices.List.SetOrdering",
  SetFilters = "Invoices.List.SetFilters",
  SetScrollOffset = "Invoices.List.SetScrollOffset",
  UpdateItem = "Invoices.List.UpdateItem",
  ResetApp = "RESET_APP"
}

type ResetList = {
  type: ActionType.ResetList;
};

const resetList = () => ({
  type: ActionType.ResetList
});

type UpdateList = {
  type: ActionType.UpdateList;
  startIndex: number;
  stopIndex: number;
  items: InvoiceSnapShot[];
  totalCount: number;
  statistics?: InvoicesStatistics | null | undefined;
};

const updateList = (
  startIndex: number,
  items: InvoiceSnapShot[],
  totalCount: number,
  statistics: InvoicesStatistics | null | undefined
) => ({
  type: ActionType.UpdateList,
  startIndex,
  items,
  totalCount,
  statistics
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: InvoiceOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: InvoiceOrderByField
) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: InvoiceSnapShot;
};

const updateItem = (snapshot: InvoiceSnapShot) => ({
  type: ActionType.UpdateItem,
  snapshot
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | ResetList
  | UpdateList
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | UpdateItem
  | ResetApp;
export const actions = {
  resetList,
  updateList,
  setOrdering,
  setFilters,
  setScrollOffset,
  updateItem
};
