import * as React from "react";
import * as CSSModules from "react-css-modules";
import debounce from "lodash-es/debounce";

import { TakeOverSpecification } from "@haywork/api/kolibri";
import {
  Input,
  Form,
  FormReturnValue,
  FormControls,
  FormReference,
} from "@haywork/modules/form";
import { FormControlUtil } from "@haywork/util";
import { ListOfGoodsValueExtended } from "@haywork/stores/dynamic-documents/single";

const styles = require("./item.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface ItemComponentProps {
  listOfGoodsValue: ListOfGoodsValueExtended;
  idx: number;
  canDelete: boolean;
  onChange: (value: ListOfGoodsValueExtended) => void;
  onDelete: (value: ListOfGoodsValueExtended) => void;
}
interface ItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class ItemComponent extends React.Component<
  ItemComponentProps,
  ItemComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      takeOverSpecification: {
        value: value(this.props.listOfGoodsValue, "takeOverSpecification"),
      },
      name: { value: value(this.props.listOfGoodsValue, "name") },
    };

    this.onFormChangeHandler = debounce(
      this.onFormChangeHandler.bind(this),
      300
    );
  }

  public render() {
    return (
      <div styleName="list-of-goods-item">
        <Form
          name={"item" + this.props.idx}
          onChange={this.onFormChangeHandler}
          formControls={this.formControls}
          form={(ref) => (this.formRef = ref)}
        >
          <div styleName="name">
            <Input.Text
              name="name"
              placeholder="listOfGoodsItemPlaceholder"
              fireAllChanges
            />
          </div>
          <div styleName="takeOverSpecification">
            <Input.RadioGroup name="takeOverSpecification">
              <Input.Radio
                value={TakeOverSpecification.StaysBehind}
                label="listOfGoodsStaysBehind"
              />
              <Input.Radio
                value={TakeOverSpecification.GoesAlong}
                label="listOfGoodsGoesALong"
              />
              <Input.Radio
                value={TakeOverSpecification.ForAcquisition}
                label="listOfGoodsForAcquisition"
              />
              <Input.Radio
                value={TakeOverSpecification.DoesNotApply}
                label="listOfGoodsDoesNotApply"
              />
            </Input.RadioGroup>
          </div>
          <div styleName="remove">
            {(this.props.canDelete ||
              this.props.listOfGoodsValue.takeOverSpecification) && (
              <div
                styleName="remove-option"
                onClick={() => {
                  this.props.onDelete(this.props.listOfGoodsValue);
                }}
              >
                <i className="fal fa-fw fa-times" />
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }

  private onFormChangeHandler(values: FormReturnValue) {
    const newValue: ListOfGoodsValueExtended = {
      ...this.props.listOfGoodsValue,
      takeOverSpecification: values.takeOverSpecification,
      name: values.name,
    };

    this.props.onChange(newValue);
  }
}
