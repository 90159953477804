import * as React from "react";
import * as deepEqual from "deep-equal";
import * as CSSModules from "react-css-modules";

import {
  FormControls,
  FormReference,
  Form,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Space,
  Size,
  UnitOfMeasurement,
  SpaceTypeOption,
  OrientationOption,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { NumberUtil } from "@haywork/util";

const styles = require("../../floors-and-spaces.component.scss");
const parseFloatExtended = NumberUtil.parseFloatExtended;

interface Props {
  space: Space;
  spaceTypes: SpaceTypeOption[];
  orientations: OrientationOption[];
  onChange: (space: Space) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class BalconyTerraceSpaceFormComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { type, size, orientation } = this.props.space;
    const { length, width, area } = size || ({} as Size);

    this.formControls = {
      type: { value: type },
      length: { value: length || "", onChange: this.calculateSizeObject },
      width: { value: width || "", onChange: this.calculateSizeObject },
      area: { value: area || "" },
      orientation: { value: orientation || "" },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.calculateSizeObject = this.calculateSizeObject.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.space, this.props.space)) {
      const { type, size, orientation } = this.props.space;
      const { length, width, area } = size || ({} as Size);

      this.formRef.update({
        type,
        length: length || "",
        width: width || "",
        area: area || "",
        orientation: orientation || "",
      });
    }
  }

  public render() {
    const { spaceTypes, orientations } = this.props;

    return (
      <Form
        name="livingroom-space-details"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChange}
        asSubForm={true}
      >
        <div className="form__row" styleName="limit-form-row">
          <label htmlFor="type">
            <ResourceText resourceKey="spaceDetails.label.type" />
          </label>
          <Input.NewSelect
            name="type"
            values={spaceTypes}
            displayProp="displayName"
            valuesProp="value"
          />
        </div>

        <div className="form__row" styleName="limit-form-row">
          <div className="form__group stretch">
            <div className="column grow1">
              <div className="form__row">
                <label htmlFor="width">
                  <ResourceText resourceKey="spaceDetails.label.width" />
                </label>
                <div className="input__helper">
                  <Input.Number name="width" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="length">
                  <ResourceText resourceKey="spaceDetails.label.length" />
                </label>
                <div className="input__helper">
                  <Input.Number name="length" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="meterShort" />
                  </div>
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column grow1">
              <div className="form__row">
                <label htmlFor="area">
                  <ResourceText resourceKey="spaceDetails.label.area" />
                </label>
                <div className="input__helper">
                  <Input.Number name="area" pretty={true} />
                  <div className="post">
                    <ResourceText resourceKey="squareMeters" asHtml={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="orientation">
            <ResourceText resourceKey="spaceDetails.label.orientation" />
          </label>
          <Input.RadioGroup
            name="orientation"
            asButtonList={true}
            multiline={true}
            canUnselect={true}
          >
            {orientations.map((orientation, idx) => (
              <Input.Radio
                value={orientation.value}
                label={orientation.displayName}
                key={idx}
              />
            ))}
          </Input.RadioGroup>
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private calculateSizeObject(ref, get) {
    const width = get("width").value;
    const length = get("length").value;

    switch (true) {
      case !!width && !!length:
        return {
          area: parseFloatExtended(parseFloat(width) * parseFloat(length)),
        };
      default:
        return;
    }
  }

  private onChange(values: FormReturnValue) {
    const space: Space = {
      ...this.props.space,
      type: values.type,
      size: {
        ...(this.props.space.size || {}),
        width: values.width,
        length: values.length,
        area: values.area,
        unitOfMeasurement: UnitOfMeasurement.Meter,
      },
      orientation: values.orientation,
    };

    this.props.onChange(space);
  }
}
