import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";
import classnames from "classnames";
import { Group } from "@haywork/api/mls";
import * as moment from "moment";

const styles = require("./publish.component.scss");

type Props = {
  group: Group | null | undefined;
  mlsGroup: string | null | undefined;
  mlsReleaseDate: Date | null | undefined;
  onNavigate: () => void;
};

const MlsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ group, mlsGroup, mlsReleaseDate, onNavigate }) => {
      const refMlsReleaseDate =
        mlsReleaseDate ||
        moment()
          .endOf("day")
          .add(group.defaultReleaseDaysInFuture || 0, "days")
          .toDate();
      const dateInThePast = moment(refMlsReleaseDate).isBefore(moment());

      const iconType = classnames(
        "fal",
        "fa-fw",
        dateInThePast ? "fa-exclamation-triangle" : "fa-check-circle"
      );
      const iconStyle = classnames("listitem__icon", { warn: dateInThePast });
      const btnStyle = classnames(
        "btn",
        dateInThePast ? "btn-warning" : "btn-blank"
      );

      const shouldRender =
        (group.autoPublish && !mlsGroup && !mlsReleaseDate) ||
        (!!mlsGroup && !!mlsReleaseDate);

      return !shouldRender ? null : (
        <div styleName="listitem">
          <div styleName={iconStyle}>
            <i className={iconType} />
          </div>
          <div styleName="listitem__label">
            <I18n
              value="mls.label.publish"
              values={{
                group: group.name || mlsGroup,
                date: moment(refMlsReleaseDate).format("DD MMMM YYYY"),
              }}
            />
          </div>
          <div styleName="listitem__action">
            <div className={btnStyle} onClick={() => onNavigate()}>
              <I18n value="adjust" />
            </div>
          </div>
        </div>
      );
    }
  )
);

export default MlsComponent;
