import { WidgetEntityType } from "@haywork/api/authorization";
import { ActiveFilter } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import PageHeader from "@haywork/components/ui/page-header";
import { PROJECTROUTES } from "@haywork/constants";
import { Input } from "@haywork/modules/form";
import Notes from "@haywork/modules/notes-v3";
import { ProjectDetailBuildnumbersContainerProps } from "@haywork/modules/project";
import {
  AddBuildnumbers,
  BuildnumberOverviewComponent,
} from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./detail-buildnumbers.component.scss");
const route = RouteUtil.mapStaticRouteValues;

const ACTIVEFILTERS = [
  { value: ActiveFilter.ActiveOnly, label: ActiveFilter.ActiveOnly },
  {
    value: ActiveFilter.ActiveOrInactive,
    label: ActiveFilter.ActiveOrInactive,
  },
  { value: ActiveFilter.InactiveOnly, label: ActiveFilter.InactiveOnly },
];

export interface ProjectDetailBuildnumbersComponentProps {}
interface State {
  objectTypeId: string;
  filterByActive: ActiveFilter;
}
type Props = ProjectDetailBuildnumbersComponentProps &
  ProjectDetailBuildnumbersContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectDetailBuildnumbersComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      objectTypeId: "",
      filterByActive: ActiveFilter.ActiveOnly,
    };

    this.objectTypeChanged = this.objectTypeChanged.bind(this);
    this.addBuildNumber = this.addBuildNumber.bind(this);
    this.onAddBuildnumberSuccessHandler = this.onAddBuildnumberSuccessHandler.bind(
      this
    );
    this.activeFilterChanged = this.activeFilterChanged.bind(this);
  }

  public componentDidMount() {
    this.props.getInitializedWidgets(
      this.props.project.id,
      WidgetEntityType.ProjectAssignment
    );
  }

  public render() {
    const { objectTypeOptions } = this.props;

    return (
      <div styleName="body">
        <PageHeader
          title="pageTitle.project.buildNumbers"
          subTitle={this.props.project.displayName}
          actions={
            <>
              <Notes />
              {this.props.canCreateObjectAssignment && (
                <Button
                  label="addBuildNumber"
                  icon={<Icon name="plus" size={18} light />}
                  category="primary"
                  onClick={this.addBuildNumber}
                />
              )}
            </>
          }
        />

        <div styleName="filters">
          <div className="form__row">
            <div className="form__group">
              <div className="column">
                <label htmlFor="objectTypes" className="pre">
                  <I18n value="projectDetailLookingAtBuildNumbers" />
                </label>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <Input.NewSelect
                  name="objectTypes"
                  values={objectTypeOptions}
                  value={this.state.objectTypeId}
                  displayProp="text"
                  valuesProp="value"
                  onChange={this.objectTypeChanged}
                />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="filterByActive" className="pre">
                  <I18n value="projectDetailBuildnumbersOfWhich" />
                </label>
              </div>
              <div className="column__spacer" />
              <div className="column">
                <Input.NewSelect
                  name="filterByActive"
                  values={ACTIVEFILTERS}
                  value={this.state.filterByActive}
                  valuesProp="value"
                  displayProp="label"
                  onChange={this.activeFilterChanged}
                  translate
                  translatePrefix="BuildNumberActiveFilter"
                />
              </div>
            </div>
          </div>
        </div>

        <div styleName="list">
          <BuildnumberOverviewComponent
            buildnumbers={this.props.buildnumbers}
            canLoadMore={this.props.buildnumbersCanLoadMore}
            loading={this.props.loading}
            totalCount={this.props.buildnumbersTotal}
            onGetBuildnumbers={(reset) =>
              this.props.getBuildnumbers(
                this.props.project.id,
                this.state.objectTypeId,
                this.state.filterByActive,
                reset ? 0 : null
              )
            }
            onBuildnumberClick={this.props.navigate}
            data-cy={"CY-buildNumber"}
          />
        </div>

        <AddBuildnumbers
          project={this.props.project}
          objectTypeId={this.state.objectTypeId}
          price={this.props.price}
          onClose={() => this.props.toggleAddBuildnumbers(false)}
          onSuccess={this.onAddBuildnumberSuccessHandler}
        />
      </div>
    );
  }

  private objectTypeChanged(objectTypeId) {
    if (this.state.objectTypeId === objectTypeId) return;

    this.setState({ objectTypeId });
    this.props.getBuildnumbers(
      this.props.project.id,
      objectTypeId,
      this.state.filterByActive,
      0
    );
  }

  private activeFilterChanged(filterByActive) {
    if (this.state.filterByActive === filterByActive) return;

    this.setState({ filterByActive });
    this.props.getBuildnumbers(
      this.props.project.id,
      this.state.objectTypeId,
      filterByActive,
      0
    );
  }

  private addBuildNumber() {
    this.props.toggleAddBuildnumbers(true);
  }

  private onAddBuildnumberSuccessHandler(objectTypeId: string) {
    const { id } = this.props.project;
    this.props.toggleAddBuildnumbers(false);
    this.props.getBuildnumbers(id, objectTypeId, 0);
    this.setState({ objectTypeId });
    this.props.navigate(route(PROJECTROUTES.BUILD_NUMBERS.URI, { id }));
  }
}
