import * as React from "react";
import { v4 as uuid } from "uuid";

import {
  ErrorMessage,
  FormControlInstance,
  FormControls,
  FormReturnValue,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { InputComponentProps } from "../input.component";
import { ArrayItemComponent } from "./array-item.component";

interface FormArrayValue {
  id: string;
  values: FormReturnValue;
}

interface KeyValueObject {
  [key: string]: any;
}

export interface FormArrayRow {
  id: string;
  formControls: FormControls;
}

interface ArrayInputComponentProps {
  formControls: FormControls;
  showActions?: boolean;
  max?: number;
  canBeEmpty?: boolean;
  emptyTriggerLabel?: { key: string; values?: { [key: string]: any } };
  onItemRemoved?: (values: FormReturnValue) => void;
}
interface ArrayInputComponentState {
  rows: FormArrayRow[];
  value: KeyValueObject[];
}

export class ArrayComponent extends React.Component<
  ArrayInputComponentProps & InputComponentProps,
  ArrayInputComponentState
> {
  private values: FormArrayValue[] = [];
  private count: number = 0;

  constructor(props, context) {
    super(props, context);

    this.state = {
      rows: this.mapRowFormControls(this.props.value),
      value: this.getInitialValue(this.props.value),
    };

    this.onAddClickHandler = this.onAddClickHandler.bind(this);
    this.onSubtractClickHandler = this.onSubtractClickHandler.bind(this);
    this.onFormChangeHandler = this.onFormChangeHandler.bind(this);
    this.mapRowFormControls = this.mapRowFormControls.bind(this);
  }

  public render() {
    const emptyTriggerLabel = this.props.emptyTriggerLabel || {
      key: "addArrayItem",
    };

    return (
      <div className="input__array">
        {this.props.canBeEmpty && this.state.rows.length === 0 && (
          <div
            className="btn btn-success icon-left"
            onClick={this.onAddClickHandler}
          >
            <i className="fal fa-plus" />
            <ResourceText
              resourceKey={emptyTriggerLabel.key}
              values={emptyTriggerLabel.values}
            />
          </div>
        )}
        {this.state.rows.map((row, idx) => (
          <ArrayItemComponent
            row={row}
            name={`${this.props.name}[${idx}]`}
            showActions={this.props.showActions !== false}
            showAddAction={idx === this.state.rows.length - 1}
            onAddClick={this.onAddClickHandler}
            onSubtractClick={this.onSubtractClickHandler}
            onFormChange={this.onFormChangeHandler}
            key={row.id}
            idx={idx}
            className={this.props.className}
            data-cy={this.props["data-cy"]}
          >
            {this.props.children}
          </ArrayItemComponent>
        ))}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ArrayInputComponentProps & InputComponentProps
  ) {
    if (!nextProps || !nextProps.value || nextProps.value.length === 0) return;
    this.setState({
      value: nextProps.value,
    });
  }

  private onAddClickHandler() {
    if (
      this.props.max &&
      this.state.value &&
      this.state.value.length >= this.props.max
    )
      return;

    const value = [...this.state.value, this.createEmptyValue()];
    const rows = [
      ...this.state.rows,
      ...this.mapRowFormControls(undefined, true),
    ];

    this.setState({ value, rows });

    this.props.onChange(value);
  }

  private onSubtractClickHandler(
    idx: number,
    id: string,
    values?: FormReturnValue
  ) {
    if (this.props.onItemRemoved) this.props.onItemRemoved(values);
    if (this.state.value.length === 1 && !this.props.canBeEmpty) return;

    const value = this.state.value.filter((v, k) => k !== idx);
    const rows = this.state.rows.filter((r) => id !== r.id);

    let isArrayValid = true;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.formControls.hasOwnProperty(this.props.name)) {
        const control = row.formControls[this.props.name];
        const { value, validators, onChange } = control;
        const { valid } = this.checkInstanceValidity(value, control);

        if (!valid) {
          isArrayValid = false;
        }
      }
    }

    this.setState({ value, rows });
    this.props.onChange(value, isArrayValid);
  }

  private onFormChangeHandler(values: FormReturnValue, valid, idx: number) {
    const value = this.state.rows.map((row, idx) => {
      const ref = this.state.value[idx];
      const { arrayId, ...formValues } = values;

      if (row.id === values.arrayId) {
        return {
          ...ref,
          ...formValues,
        };
      }
      return ref;
    });

    this.props.onChange(value, valid);
  }

  private checkInstanceValidity(
    value: any,
    control: FormControlInstance
  ): { valid: boolean; errors: ErrorMessage[] } {
    const { validators } = control;
    if (!validators || validators.length === 0)
      return { valid: true, errors: [] };

    const errorArray = validators
      .map((validator) => validator(value))
      .filter((error) => !!error);

    return { valid: errorArray.length === 0, errors: errorArray };
  }

  private mapRowFormControls(
    value?: KeyValueObject[],
    force?: boolean
  ): FormArrayRow[] {
    const values =
      value && value.length > 0
        ? value
        : this.props.canBeEmpty && !force
        ? []
        : [this.createEmptyValue()];
    const rows = values.map((value) => {
      return { id: uuid(), formControls: this.mapValuesToFormControls(value) };
    });

    return rows;
  }

  private createEmptyValue(): KeyValueObject {
    const formControls = this.props.formControls;
    const formArrayRow = {};

    for (const key in formControls) {
      formArrayRow[key] = formControls[key].value;
    }

    return formArrayRow;
  }

  private mapValuesToFormControls(values: {
    [key: string]: any;
  }): FormControls {
    const formControls: FormControls = {};

    for (const key in this.props.formControls) {
      let value = "";
      if (values.hasOwnProperty(key)) {
        value = values[key];
      }

      formControls[key] = { ...this.props.formControls[key], value };
    }

    return formControls;
  }

  private getInitialValue(value: KeyValueObject[]): KeyValueObject[] {
    return !!value && value.length > 0
      ? value
      : this.props.canBeEmpty
      ? []
      : [this.createEmptyValue()];
  }
}
