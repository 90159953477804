import {
  InvoiceOrderByField,
  InvoiceSnapShot,
  SortOrder,
  BatchItem,
  BatchResponseItem,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import {
  InvoiceThunk,
  MapDispatchToProps,
  BlobThunk,
} from "@haywork/middleware";
import { AppState, InvoiceListActions } from "@haywork/stores";
import { InvoiceOrder } from "@haywork/stores/invoice/list";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { ListComponent, ListComponentProps } from "./list";
import { UploadResponse } from "@haywork/api/mail";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";

type StateProps = {
  invoices: InvoiceSnapShot[];
  totalCount: number;
  order: InvoiceOrder;
  filters: FilterConfig;
  scrollOffset: number;
  currentAccount: string;
};
type DispatchProps = {
  setOrdering: (sortOrder: SortOrder, sortColumn: InvoiceOrderByField) => void;
  setFilters: (filters: FilterConfig) => void;
  setScrollOffset: (offset: number) => void;
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  navigate: (path: string) => CallHistoryMethodAction<[string, any?]>;
  createNewInvoice: () => Promise<void>;
  updateListItem: (snapshot: InvoiceSnapShot) => void;
  archive: (id: string) => Promise<void>;
  unArchive: (id: string) => Promise<void>;
  deleteListInvoice: (id: string) => Promise<void>;
  exportListToExcel: () => Promise<void>;
  downloadInvoice: (id: string) => Promise<void>;
  createBatchDownload: (items: BatchItem[]) => Promise<BatchResponseItem[]>;
  uploadFilesFromUri: (
    accountId: string,
    files: BatchResponseItem[]
  ) => Promise<UploadResponse[]>;
  createNewEmailWithAttachments: (
    attachments: UploadResponse[],
    to?: LinkedRelation[] | undefined,
    subject?: string
  ) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListComponentProps,
  AppState
> = (state) => {
  const {
    invoices,
    totalCount,
    order,
    filters,
    scrollOffset,
  } = state.invoice.list;
  const { currentAccount } = state.emailV2.main;

  return {
    invoices,
    totalCount,
    order,
    filters,
    scrollOffset,
    currentAccount,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListComponentProps
> = (dispatch) => ({
  setOrdering: (sortOrder: SortOrder, sortColumn: InvoiceOrderByField) =>
    dispatch(InvoiceListActions.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(InvoiceListActions.setFilters(filters)),
  setScrollOffset: (offset: number) =>
    dispatch(InvoiceListActions.setScrollOffset(offset)),
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(InvoiceThunk.getListItems(startIndex, stopIndex)),
  navigate: (path: string) => dispatch(push(path)),
  createNewInvoice: () => dispatch(InvoiceThunk.createInvoice()),
  updateListItem: (snapshot: InvoiceSnapShot) =>
    dispatch(InvoiceListActions.updateItem(snapshot)),
  archive: (id: string) => dispatch(InvoiceThunk.archiveInvoice(id)),
  unArchive: (id: string) => dispatch(InvoiceThunk.unArchiveInvoice(id)),
  deleteListInvoice: (id: string) =>
    dispatch(InvoiceThunk.deleteListInvoice(id)),
  exportListToExcel: () => dispatch(InvoiceThunk.exportListToExcel()),
  downloadInvoice: (id: string) => dispatch(InvoiceThunk.downloadInvoice(id)),
  createBatchDownload: (items: BatchItem[]) =>
    dispatch(BlobThunk.createBatchDownload(items)),
  uploadFilesFromUri: (accountId: string, files: BatchResponseItem[]) =>
    dispatch(BlobThunk.uploadFilesFromUri(accountId, files)),
  createNewEmailWithAttachments: (
    attachments: UploadResponse[],
    to?: LinkedRelation[] | undefined,
    subject?: string
  ) =>
    dispatch(
      EmailThunk.Drafts.createNewEmailWithAttachments(attachments, to, subject)
    ),
});

export type ListContainerProps = StateProps & DispatchProps;
export const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
