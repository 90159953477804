import * as React from "react";
import { store } from "@haywork/stores";

export const FeatureHOC = (
  FeatureComponent: any,
  FallbackComponent: any,
  feature: string
) => {
  return class extends React.Component {
    private features: string[];

    constructor(props) {
      super(props);
      if (!!store) {
        const state = store.getState();
        this.features = state.appSettings.features;
      }
    }

    public render() {
      return this.validateFeature();
    }

    private validateFeature() {
      if ((this.features || []).includes(feature)) {
        return <FeatureComponent {...this.props} />;
      } else {
        return <FallbackComponent {...this.props} />;
      }
    }
  };
};
