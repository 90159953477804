import { CreateAccountRequest } from "@haywork/api/mail";
import { KEYCODE, REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  accountData: CreateAccountRequest;
  createStatus: string;
  onChange: (values: FormReturnValue) => void;
  onSubmit: () => void;
  onBack: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class OutgoingDataIMAPComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.formControls = {
      smtpHost: {
        value: value(this.props.accountData, "smtpHost"),
        validators: [Validators.required()],
      },
      smtpPort: { value: value(this.props.accountData, "smtpPort", 587) },
      smtpUsername: {
        value: value(this.props.accountData, "smtpUsername"),
        validators: [Validators.required()],
      },
      smtpPassword: {
        value: value(this.props.accountData, "smtpPassword"),
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    const disabled = this.props.createStatus === REQUEST.PENDING;

    return (
      <div styleName="imap-data">
        <div styleName="imap-data__info">
          <i className="fal fa-info-circle" />
          <ResourceText resourceKey="outgoingEmailSMTPInfo" />
        </div>
        <div styleName="imap-data__body">
          <h2>
            <ResourceText resourceKey="outgoingEmailSMTPTitle" />
          </h2>
          <Form
            name="incoming-imap"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onChange={this.props.onChange}
            onSubmit={this.props.onSubmit}
          >
            {/* smtpHost & smtpPort */}
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column important">
                  <label htmlFor="smtpHost">
                    <ResourceText resourceKey="emailSmtpHost" />
                  </label>
                  <Input.Text
                    name="smtpHost"
                    placeholder="emailSmtpHostPlaceholder"
                    disabled={disabled}
                  />
                </div>

                <div className="column__spacer" />

                <div className="column un-important">
                  <label htmlFor="smtpPort">
                    <ResourceText resourceKey="emailSmtpPort" />
                  </label>
                  <Input.Number
                    name="smtpPort"
                    placeholder="emailSmtpPortPlaceholder"
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            {/* smtpUsername & smtpPassword */}
            <div className="form__row">
              <div className="form__group stretch">
                <div className="column">
                  <label htmlFor="smtpUsername">
                    <ResourceText resourceKey="emailSmtpUsername" />
                  </label>
                  <Input.Text
                    name="smtpUsername"
                    placeholder="emailSmtpUsernamePlaceholder"
                    disabled={disabled}
                  />
                </div>

                <div className="column__spacer" />

                <div className="column">
                  <label htmlFor="smtpPassword">
                    <ResourceText resourceKey="emailSmtpPassword" />
                  </label>
                  <Input.Password
                    name="smtpPassword"
                    placeholder="emailSmtpPasswordPlaceholder"
                    canToggle
                    disabled={disabled}
                    onKeyDown={this.onKeyDownHandler}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div styleName="footer">
          <div styleName="left">
            <button
              type="button"
              className="btn btn-gray"
              onClick={this.props.onBack}
              disabled={disabled}
            >
              <ResourceText resourceKey="back" />
            </button>
          </div>
          <div styleName="right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSubmitHandler}
            >
              <ButtonLoader resourceKey="bindMailBox" loading={disabled} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitHandler() {
    this.formRef.submit();
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    if (keyCode === KEYCODE.ENTER) {
      this.onSubmitHandler();
    }
  }
}
