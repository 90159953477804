export const INVOICES = {
  SET_INVOICES: "SET_INVOICES",
  APPEND_INVOICES: "APPEND_INVOICES",
  SET_INVOICES_STATUS: "SET_INVOICES_STATUS",
  UPDATE_INVOICE_FILTER: "UPDATE_INVOICE_FILTER",
  SET_SINGLE_INVOICE_STATUS: "SET_SINGLE_INVOICE_STATUS",
  SET_SINGLE_INVOICE: "SET_SINGLE_INVOICE",
  SET_INVOICE_ADDRESS_SEARCH_STATUS: "SET_ADDRESS_SEARCH_STATUS",
  SET_INVOICE_FORMAL_NAME: "SET_FORMAL_NAME",
  SET_FINANCIAL_ADMINISTRATIONS_INVOICE:
    "SET_FINANCIAL_ADMINISTRATIONS_INVOICE",
  SET_FINANCIAL_ADMINISTRATION_INVOICE: "SET_FINANCIAL_ADMINISTRATION_INVOICE",
  SET_LINKED_ASSIGMENT: "INVOICE_SET_LINKED_ASSIGMENT",
  SET_ASSIGNEE: "INVOICE_SET_ASSIGNEE",
  UPDATE_INVOICE_PRICES: "UPDATE_INVOICE_PRICES",
  SET_INITIAL_INVOICE_OVERVIEW_STATUS: "SET_INITIAL_INVOICE_OVERVIEW_STATUS",
  SET_PRINT_TEMPLATES: "INVOICE_SET_PRINT_TEMPLATES",
  SET_PRINT_EXPORT_OPTIONS: "INVOICE_SET_PRINT_EXPORT_OPTIONS",
  REMOVE_INVOICE_FROM_LIST: "REMOVE_INVOICE_FROM_LIST",
  SET_INVOICES_ORDERING: "SET_INVOICES_ORDERING",
  DELETE_INVOICE_FROM_LIST: "DELETE_INVOICE_FROM_LIST",
  ARCHIVE_INVOICE_FROM_LIST: "ARCHIVE_INVOICE_FROM_LIST",
  UNARCHIVE_INVOICE_FROM_LIST: "UNARCHIVE_INVOICE_FROM_LIST",
  SET_INVOICE_FILTERING: "SET_INVOICE_FILTERING",
  CLEAR_ALL_FILTERS: "INVOICE_CLEAR_ALL_FILTERS",
  UPDATE_LIST_ITEM: "INVOICE_UPDATE_LIST_ITEM",
  DELETE_LIST_ITEM: "INVOICE_DELETE_LIST_ITEM"
};
