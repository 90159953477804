import { Action } from "redux";

import { REDUX } from "@haywork/constants";

import * as ActionType from "./errors.types";

export interface FormError {
  fieldName: string;
  path: string;
  error: string;
}

export interface FormErrors {
  path: string;
  stack: FormError[];
}

export interface FormErrorsState {
  formErrors: FormErrors[];
}

const INITIAL_STATE: FormErrorsState = {
  formErrors: []
};

export const errorsReducer = (state: FormErrorsState = INITIAL_STATE, action: Action): FormErrorsState => {
  switch (action.type) {
    case REDUX.FORM.SET_ERRORS: {
      const { formError } = <ActionType.Errors> action;
      const reference = state.formErrors.find((e) => e.path === formError.path);
      let updated = false;

      let formErrors = state.formErrors.map((f) => {
        if (f.path === formError.path) {
          updated = true;
          return {
            ...f,
            stack: formError.stack
          };
        }
        return f;
      });

      if (!updated) {
        formErrors = [...formErrors, formError];
      }

      return {
        ...state,
        formErrors
      };
    }
    case REDUX.FORM.RESOLVE_ERROR: {
      const { fieldName, path } = <ActionType.Error> action;
      const formErrors = state.formErrors.map((f) => {
        if (f.path === path) {
          const stack = f.stack.filter((s) => s.fieldName !== fieldName);
          return {
            ...f,
            stack
          };
        }
        return f;
      });

      return {
        ...state,
        formErrors
      };
    }
    case REDUX.FORM.REMOVE_ERRORS: {
      const { path } = <ActionType.Path> action;
      const formErrors = state.formErrors.filter((f) => f.path !== path);

      return {
        ...state,
        formErrors
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
