import { SortOrder, SearchAssignmentOrderByField } from "@haywork/api/kolibri";
import { actions as SearchAssignmentsListActions } from "./actions";

export type SearchAssignmentOrder = {
  sortOrder: SortOrder;
  sortColumn: SearchAssignmentOrderByField;
};

export { listReducer, ListState } from "./reducer";
export { SearchAssignmentsListActions };
