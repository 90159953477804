import * as React from "react";
import { memo, FC } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./style.scss");

type Props = {
  first: boolean;
  last: boolean;
};

export const LoadingStateComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ first, last }) => {
    return (
      <div styleName={classNames("loading-state", { first, last })}>
        <div styleName="inner">
          <div styleName="header">
            <div styleName="header__icon" />
            <div styleName="header__info">
              <div styleName="title" />
              <div styleName="subtitle" />
            </div>
          </div>
        </div>
      </div>
    );
  })
);
