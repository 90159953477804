import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { EditableItem } from "@haywork/stores";
import { ResourceText } from "@haywork/modules/shared";

interface ExtraTabComponentProps {
  tab: EditableItem;
  onTabClick: (path: string) => void;
}

const styles = require("./tab-bar.component.scss");

export const ExtraTabComponent = CSSModules(styles, { allowMultiple: true })(
  ({ tab, onTabClick }: ExtraTabComponentProps) => {
    const { title, active, hasChanges, currentPath, icon } = tab;

    return (
      <div
        styleName={classNames("extra-tab", { active })}
        onClick={() => onTabClick(currentPath)}
      >
        <i className={classNames("fal", `fa-${icon}`)} />
        <ResourceText resourceKey={title} />
      </div>
    );
  }
);
