import * as React from "react";
import { FC, memo, useState, useEffect, useCallback, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import { BidsContainerProps } from "./bids.container";
import Button from "@haywork/components/ui/button";
import Pill from "@haywork/components/ui/pill";
import { BidSnapShot, BidOrderByField } from "@haywork/api/kolibri";
import BidItem from "../../../bid";
import { Ui } from "@haywork/modules/ui";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import { BidAction } from "../../../..";

const styles = require("./style.scss");

export type BidsComponentProps = {
  assignmentId: string;
  onClose: () => void;
  onNavigate: (path: string) => void;
  onAction: (id: string, action: BidAction) => void;
};
type Props = BidsComponentProps & BidsContainerProps;

export const BidsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ assignmentId, onClose, onNavigate, onAction, getBids, deleteBid }) => {
      const [bids, setBids] = useState<BidSnapShot[]>([]);
      const [loading, setLoading] = useState(false);
      const [orderBy, setOrderBy] = useState(BidOrderByField.BidDateTime);

      const fetchBids = useCallback(async () => {
        try {
          setLoading(true);
          const bids = await getBids(assignmentId, orderBy);
          setBids(bids);
        } finally {
          setLoading(false);
        }
      }, [assignmentId, orderBy, setLoading, setBids]);

      const onActionHandler = useCallback(
        async (id: string, action: BidAction) => {
          if (action === BidAction.Delete) {
            await deleteBid(id, fetchBids);
            fetchBids();
            return;
          }
          onAction(id, action);
        },
        [onAction, fetchBids, deleteBid]
      );

      const amountFilter = useMemo(() => {
        const active = orderBy === BidOrderByField.Amount;
        return (
          <Pill
            label="bids.widget.sortBy.amount"
            color={active ? Colors.Primary : Colors.Gray}
            solid={active}
            onClick={() => setOrderBy(BidOrderByField.Amount)}
          />
        );
      }, [orderBy]);
      const bidDateTimeFilter = useMemo(() => {
        const active = orderBy === BidOrderByField.BidDateTime;
        return (
          <Pill
            label="bids.widget.sortBy.bidDateTime"
            color={active ? Colors.Primary : Colors.Gray}
            solid={active}
            onClick={() => setOrderBy(BidOrderByField.BidDateTime)}
          />
        );
      }, [orderBy]);

      useEffect(() => {
        fetchBids();
      }, [orderBy]);

      return (
        <>
          <ModalBody noPadding>
            <div styleName="filters">
              <span>
                <I18n value="bids.widget.label.show" />
              </span>
              {amountFilter}
              {bidDateTimeFilter}
            </div>
            <div styleName="body">
              {bids.map((bid) => (
                <BidItem
                  key={bid.id}
                  bid={bid}
                  canDelete={true}
                  onNavigate={onNavigate}
                  onAction={onActionHandler}
                />
              ))}
              {!bids.length && loading && <Ui.Loaders.List />}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button label="bid.modal.close" onClick={onClose} />
          </ModalFooter>
        </>
      );
    }
  )
);
