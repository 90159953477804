import {
  RelationOrderByField,
  RelationSnapShot,
  SortOrder
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { RELATIONROUTES } from "@haywork/constants";
import {
  EmailInitialThunks,
  MapDispatchToProps,
  RelationThunks
} from "@haywork/middleware";
import { AppState, RelationListActions } from "@haywork/stores";
import { RelationOrder } from "@haywork/stores/relation/list";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { RelationsListComponent, RelationsListComponentProps } from "./list";
import { activeFilterGuidValues } from "./selectors";

type StateProps = {
  relations: RelationSnapShot[];
  totalCount: number;
  filters: FilterConfig;
  activeFilterGuidValues: FilterGuidValues;
  order: RelationOrder;
  scrollOffset: number;
};
type DispatchProps = {
  navigate: (path: string) => RouterAction;
  getListItems: (startIndex: number, stopIndex: number) => Promise<void>;
  create: () => RouterAction;
  setFilters: (filters: FilterConfig) => void;
  setOrdering: (sortOrder: SortOrder, sortColumn: RelationOrderByField) => void;
  setScrollOffset: (offset: number) => void;
  createNewEmail: (email: string) => void;
  exportListToExcel: () => void;
  updateListItem: (snapshot: RelationSnapShot) => void;
  archive: (snapshot: RelationSnapShot) => Promise<void>;
  unArchive: (snapshot: RelationSnapShot) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  RelationsListComponentProps,
  AppState
> = (state) => {
  const {
    relations,
    totalCount,
    filters,
    order,
    scrollOffset
  } = state.relation.list;

  return {
    relations,
    totalCount,
    filters,
    order,
    scrollOffset,
    activeFilterGuidValues: activeFilterGuidValues(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  RelationsListComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getListItems: (startIndex: number, stopIndex: number) =>
    dispatch(RelationThunks.getListItems(startIndex, stopIndex)),
  create: () => dispatch(push(RELATIONROUTES.NEW.URI)),
  setOrdering: (sortOrder: SortOrder, sortColumn: RelationOrderByField) =>
    dispatch(RelationListActions.setOrdering(sortOrder, sortColumn)),
  setFilters: (filters: FilterConfig) =>
    dispatch(RelationListActions.setFilters(filters)),
  setScrollOffset: (offset: number) => {
    dispatch(RelationListActions.setScrollOffset(offset));
  },
  createNewEmail: (email: string) =>
    dispatch(EmailInitialThunks.createEmailFromEmailAddress(email)),
  exportListToExcel: () => dispatch(RelationThunks.exportListToExcel()),
  updateListItem: (snapshot: RelationSnapShot) =>
    dispatch(RelationListActions.updateItem(snapshot)),
  archive: (snapshot: RelationSnapShot) =>
    dispatch(RelationThunks.archiveRelation(snapshot)),
  unArchive: (snapshot: RelationSnapShot) =>
    dispatch(RelationThunks.unArchiveRelation(snapshot))
});

export type RelationsListContainerProps = StateProps & DispatchProps;
export const RelationsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationsListComponent);
