import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { MlsComponent, MlsComponentProps } from "./mls";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { SearchLocation } from "@haywork/api/mls";
import { MlsAccessData } from "@haywork/stores/mls/main/actions";
import { createSelector } from "reselect";

const mlsAccessDataSelector = (state: AppState) => state.mls.main.mlsAccessData;
const mlsAccessData = createSelector(mlsAccessDataSelector, (mlsAccessData) => {
  return mlsAccessData;
});

interface StateProps {
  searchLocations: SearchLocation[];
  mlsAccessData: MlsAccessData;
}
interface DispatchProps {
  getSearchLocations: () => Promise<SearchLocation[]>;
  createNewSearchLocation: () => Promise<SearchLocation>;
  batchUpdate: (
    deletedItems: SearchLocation[],
    newItems: SearchLocation[],
    updatedItems: SearchLocation[]
  ) => Promise<void>;
  toggleMlsSubscription: () => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  MlsComponentProps,
  AppState
> = (state) => {
  const { searchLocations } = state.mls.searchLocations;

  return {
    mlsAccessData: mlsAccessData(state),
    searchLocations,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  MlsComponentProps
> = (dispatch) => ({
  getSearchLocations: () =>
    dispatch(MLSThunk.SearchLocations.getSearchLocations()),
  createNewSearchLocation: () =>
    dispatch(MLSThunk.SearchLocations.createNewSearchLocation()),
  batchUpdate: (
    deletedItems: SearchLocation[],
    newItems: SearchLocation[],
    updatedItems: SearchLocation[]
  ) =>
    dispatch(
      MLSThunk.SearchLocations.batchUpdate(deletedItems, newItems, updatedItems)
    ),
  toggleMlsSubscription: () =>
    dispatch(MLSThunk.RealEstateAgencies.toggleMlsSubscription()),
});

export type MlsContainerProps = StateProps & DispatchProps;
export const MlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MlsComponent);
