import { PhotoBlob } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  LightboxComponent,
  LightboxComponentProps
} from "@haywork/modules/shared";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  visible?: boolean;
  slides?: PhotoBlob[];
  current?: number;
  count?: number;
}

interface DispatchProps {
  next?: () => void;
  previous?: () => void;
  setSlide?: (idx: number) => void;
  close?: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  LightboxComponentProps,
  AppState
> = (state: AppState) => ({
  visible: state.layout.lightboxVisible,
  slides: state.layout.lightboxSlides,
  current: state.layout.lightboxCurrent,
  count: state.layout.lightboxCount
});

const mapDispatchToProps = <DispatchProps, LightboxComponentProps>(
  dispatch: Dispatch<any>
) => ({
  next: () => dispatch(LayoutActions.lightboxNext()),
  previous: () => dispatch(LayoutActions.lightboxPrevious()),
  setSlide: (idx: number) => dispatch(LayoutActions.setLightboxSlide(idx)),
  close: () => dispatch(LayoutActions.hideLightbox())
});

export type LightboxContainerProps = StateProps & DispatchProps;
export const Lightbox = connect<
  StateProps,
  DispatchProps,
  LightboxComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(LightboxComponent);
