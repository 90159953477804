import * as React from "react";
import * as CSSModules from "react-css-modules";
import { PATTERN } from "@haywork/constants";
import { useMemo, useState } from "react";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  displayName: string;
  id: string;
  email: string;
  onRemove: (id: string) => void;
  onClick: (id: string) => void;
}
export const FormSuggestionPillComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ displayName, id, email, onRemove, onClick }: Props) => {
      const onClickHandler = () => {
        onClick(id);
      };

      const onRemoveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onRemove(id);
      };
      const checkValidEmail = (email: string): boolean => {
        return !!email && new RegExp(PATTERN.EMAIL).test(email) === true;
      };
      const isValidEmail = useMemo(() => checkValidEmail(email), [email]);

      const pillStyle = classNames("pill", {
        warning: !isValidEmail,
      });

      return (
        <>
          <div styleName={pillStyle} onClick={onClickHandler}>
            {!isValidEmail && <i className="fa fa-exclamation-triangle" />}
            <span>
              {!!email && email}
              {!!displayName && `<${displayName}>`}
            </span>
            <div styleName="pill__delete" onClick={onRemoveHandler}>
              <i className="far fa-times" />
            </div>
          </div>
        </>
      );
    }
  )
);
