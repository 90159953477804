import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import {
  EMPLOYEEROUTES,
  OFFICESROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./linked-relation-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface LinkedRelationComponentProps {
  relation: LinkedRelation;

  onClick: (uri: string) => void;
}
interface LinkedRelationComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class LinkedRelationComponent extends React.Component<
  LinkedRelationComponentProps,
  LinkedRelationComponentState
> {
  public constructor(props) {
    super(props);
    this.renderEmail = this.renderEmail.bind(this);
    this.renderPhone = this.renderPhone.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const { relation } = this.props;
    const avatar = relation.avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(relation.avatarUrl)})` }
      : null;

    return (
      <div
        styleName="relation"
        onClick={this.onClickHandler}
        data-cy={this.props["data-cy"]}
      >
        <div styleName="avatar" style={avatar}>
          {!avatar && <div styleName="letter">{relation.letterAvatar}</div>}
        </div>
        <div styleName="info">
          <h3>{relation.displayName}</h3>
          <div styleName="contact-items">
            {this.renderPhone(relation)}
            {this.renderEmail(relation)}
          </div>
        </div>
      </div>
    );
  }

  private onClickHandler() {
    const { typeOfRelation, id } = this.props.relation;
    let uri = "";

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        uri = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
        break;
      case RelationType.ContactCompany:
        uri = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
        break;
      case RelationType.Employee:
        uri = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
        break;
      case RelationType.Office:
        uri = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
        break;
      default:
        break;
    }

    this.props.onClick(uri);
  }

  private renderPhone(
    relation: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    const values = [relation.phone, relation.phoneMobile, relation.phoneWork];
    const filtered = values.filter((val) => !!val);

    if (filtered.length === 0) return null;

    return (
      <React.Fragment>
        {filtered.map((phone, idx) => (
          <ErrorBoundary key={idx}>
            <a
              styleName="contact-item"
              href={`tel:${phone}`}
              onClick={this.stopPropagation}
            >
              {phone}
            </a>
          </ErrorBoundary>
        ))}
      </React.Fragment>
    );
  }

  private renderEmail(relation: LinkedRelation) {
    if (!relation.email) return null;
    return (
      <span styleName="contact-item">
        <MailTo
          email={relation.email}
          data-cy={
            this.props["data-cy"] && `${this.props["data-cy"]}.MailToLink`
          }
        />
      </span>
    );
  }

  private stopPropagation(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }
}
