import { ErrorMessage } from "@haywork/modules/form";

export const priceDifference = (
  oldPrice: number,
  errorMessage?: ErrorMessage
) => (value?: any): null | ErrorMessage => {
  if (oldPrice < 100) return;

  if (value > oldPrice * 2 || value < oldPrice / 2) {
    return errorMessage || { key: "priceDifferenceToLarge" };
  }
  return null;
};
