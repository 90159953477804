import * as React from "react";
import * as CSSModules from "react-css-modules";
import PageHeader from "@haywork/components/ui/page-header";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { EMPLOYEEROUTES } from "@haywork/constants/routes";
import { EmployeeFilter } from "@haywork/middleware/thunk";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import {
  EmployeeFilterComponent,
  EmployeeListItemComponent,
  EmployeeOverviewContainerProps,
  EmployeeModalComponent,
} from "@haywork/modules/settings";
import {
  InfiniteScroll,
  ListLoader,
  PageLoader,
  ResourceText,
} from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { RouteUtil } from "@haywork/util";

const styles = require("./employee-overview.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface EmployeeOverviewComponentProps {}
interface State {}
type Props = EmployeeOverviewComponentProps & EmployeeOverviewContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmployeeOverviewComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onEmployeeFilterChange = this.onEmployeeFilterChange.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onEmployeeClickHandler = this.onEmployeeClickHandler.bind(this);
    this.onAddUserClickHandler = this.onAddUserClickHandler.bind(this);
    this.onCloseEmployeeModal = this.onCloseEmployeeModal.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedEmployeeFilter !== nextProps.selectedEmployeeFilter
    ) {
      this.props.getEmployees(true);
    }
  }

  public componentDidMount() {
    this.props.getEmployees(true);
  }

  public render() {
    const modalVisible = this.props.showEmployeeModal;
    const modalTitle = "Medewerkers toevoegen";

    return (
      <div styleName="relation-overview">
        <PageHeader
          title="settingsMenuEmployees"
          subTitle="settingsMenuMyCompany"
        />

        <div styleName="header">
          <div styleName="filter">
            <EmployeeFilterComponent
              onFilterChange={this.onEmployeeFilterChange}
              activeFilterOptions={this.props.activeFilterOptions}
              selectedEmployeeFilter={this.props.selectedEmployeeFilter}
            />
          </div>
        </div>
        <div styleName="summary">
          <span>
            <ResourceText
              resourceKey="employeeAmount"
              asHtml
              values={{ employeeAmount: this.props.totalEmployeeCount }}
            />
          </span>
        </div>
        <div styleName="list">
          {this.props.employeeInitialOverviewState === REQUEST.PENDING && (
            <PageLoader loading fullscreen />
          )}
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {this.props.employees
              ? this.props.employees.map((employee, index) => (
                  <ErrorBoundary key={index}>
                    <EmployeeListItemComponent
                      employee={employee}
                      zebra={index % 2 === 0}
                      onEmployeeClickHandler={this.onEmployeeClickHandler}
                      officeFilterOptions={this.props.officeFilterOptions}
                      onArchiveEmployee={this.props.archiveEmployee}
                      onUnArchiveEmployee={this.props.unArchiveEmployee}
                      onEditButtonClick={this.onEditClickHandler}
                      removeFromList={this.props.removeFromList}
                    />
                  </ErrorBoundary>
                ))
              : ""}
            {this.props.canLoadMore && <ListLoader />}
          </InfiniteScroll>
        </div>
        <Modal visible={modalVisible} onClose={this.onCloseEmployeeModal}>
          <ModalHeader title={modalTitle} close />
          <ModalBody>
            <EmployeeModalComponent
              officeFilterOptions={this.props.officeList}
            />
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.onCloseEmployeeModal}
              >
                Terug
              </button>
              <button type="button" className="btn btn-success">
                Medewerker(s) toevoegen
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  public onEmployeeFilterChange(employeeFilter: EmployeeFilter) {
    this.props.updateFilter(employeeFilter);
  }

  public onEmployeeClickHandler(employee: RelationSnapShot) {
    const { id } = employee;
    const url = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
    this.props.goToEmployeeDetail(url);
  }

  public onEditClickHandler(employee: RelationSnapShot) {
    const { id } = employee;
    const url = route(EMPLOYEEROUTES.EMPLOYEE_EDIT.URI, { id });
    this.props.goToEmployeeDetail(url);
  }

  public onScrollEndHandler() {
    if (
      !this.props.canLoadMore ||
      this.props.employeeOverviewState === REQUEST.PENDING
    )
      return;
    this.props.getEmployees();
  }

  private onCloseEmployeeModal() {
    this.props.toggleEmployeeModal(false);
  }

  private onAddUserClickHandler() {
    this.props.toggleEmployeeModal(true);
  }
}
