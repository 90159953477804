import {
  AssignmentType,
  Condition,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { SortOrder } from "@haywork/api/mls";
import { FilterConfig, SingleFilterValue } from "@haywork/components/ui/list";
import { MlsFilter } from "@haywork/middleware/thunk/mls/list";
import {
  availableFromValue,
  availableUntillValue,
} from "@haywork/modules/assignment/components/list/selectors";
import { AppState } from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { ParkingPlaceCount } from "@haywork/stores/mls/list/reducer";
import { push } from "connected-react-router";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import {
  availabilityStatusValues,
  conditionOptionsInside,
  conditionOptionsOutside,
  contentValues,
  energyClassOptions,
  facilitiesValues,
  floorAreaValues,
  floorsValues,
  garageCapacityValues,
  garageTypeOptions,
  gardenPlotSizeValues,
  listingTypes,
  livingAreaValues,
  locationPlaces,
  mutationsDates,
  mutationTypeOptions,
  noOfBedroomsValues,
  noOfRoomsValues,
  orientationTypeValues,
  otherSearchTypeOptions,
  plotSizeValues,
  priceRangeValues,
  realEstateAgencyAppClientValues,
  realEstateAgencyGroupValues,
  savedFilters,
  searchLocationValues,
  yearOfConstructionValues,
} from "../../selectors";
import { FiltersComponent, FiltersComponentProps } from "./filters";

type StateProps = {
  filters: FilterConfig;
  forSaleAndOrRentFilterValues: SingleFilterValue[];
  realEstateGroupValues: SingleFilterValue[];
  assignmentTypeValues: SingleFilterValue[];
  conditionValues: SingleFilterValue[];
  mutationTypeValues: SingleFilterValue[];
  availabilityStatusValues: SingleFilterValue[];
  listingTypePartValues: SingleFilterValue[];
  listingTypeBogValues: SingleFilterValue[];
  listingTypeAlvValues: SingleFilterValue[];
  houseTypesValues: SingleFilterValue[];
  houseSortsValues: SingleFilterValue[];
  houseCharacteristicsValues: SingleFilterValue[];
  apartmentSortValues: SingleFilterValue[];
  apartmentCharasteristicsValues: SingleFilterValue[];
  garageTypeValues: SingleFilterValue[];
  residentialSubtypeOthersValues: SingleFilterValue[];
  horseCompanySubtypes: SingleFilterValue[];
  parkingTypeOptions: SingleFilterValue[];
  pigCompanyTypes: SingleFilterValue[];
  cattleFarmingSubtypes: SingleFilterValue[];
  horticulturalCompanyTypes: SingleFilterValue[];
  beverageHospitalitySectorTypes: SingleFilterValue[];
  locationPlaces: SingleFilterValue[];
  outsideValues: SingleFilterValue[];
  orientationTypeValues: SingleFilterValue[];
  energyClassOptions: SingleFilterValue[];
  conditionOptionsInside: SingleFilterValue[];
  conditionOptionsOutside: SingleFilterValue[];
  priceTypeFilters: SingleFilterValue[];
  facilitiesValues: SingleFilterValue[];
  otherSearchTypeValues: SingleFilterValue[];
  searchLocationValues: SingleFilterValue[];
  realEstateAgencyGroupValues: SingleFilterValue[];
  realEstateAgencyAppClientValues: SingleFilterValue[];
  garageTypeOptions: SingleFilterValue[];
  savedFilters: MlsFilter[];
  mutationsDates: SingleFilterValue;
  priceRangeValues: SingleFilterValue;
  livingAreaValues: SingleFilterValue;
  noOfRoomsValues: SingleFilterValue;
  noOfBedroomsValues: SingleFilterValue;
  contentValues: SingleFilterValue;
  floorsValues: SingleFilterValue;
  yearOfConstructionValues: SingleFilterValue;
  plotSizeValues: SingleFilterValue;
  gardenPlotSizeValues: SingleFilterValue;
  garageCapacityValues: SingleFilterValue;
  followingValues: SingleFilterValue[];
  parkingPlaceCounts: SingleFilterValue[];
  floorAreaValues: SingleFilterValue;
  groundTypeValues: SingleFilterValue[];
};
type DispatchProps = {
  setFilters: (filters: FilterConfig) => void;
  navigate: (path: string) => void;
  resetOrdering: () => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  FiltersComponentProps,
  AppState
> = (state) => {
  const { filters } = state.mls.list;
  const realEstateGroupValues: SingleFilterValue[] = [
    {
      label: `realEstateGroups.${RealEstateGroup.Residential.toString()}`,
      value: RealEstateGroup.Residential,
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Commercial.toString()}`,
      value: RealEstateGroup.Commercial,
    },
    {
      label: `realEstateGroups.${RealEstateGroup.Agricultural.toString()}`,
      value: RealEstateGroup.Agricultural,
    },
  ];
  const assignmentTypeValues: SingleFilterValue[] = [
    {
      label: `assignmentTypes.${AssignmentType.Object.toString()}`,
      value: AssignmentType.Object,
    },
    {
      label: `assignmentTypes.${AssignmentType.Project.toString()}`,
      value: AssignmentType.Project,
    },
  ];
  const conditionValues: SingleFilterValue[] = [
    {
      label: `conditionOptions.${Condition.Poor.toString()}`,
      value: Condition.Poor,
    },
    {
      label: `conditionOptions.${Condition.PoorToModerate.toString()}`,
      value: Condition.PoorToModerate,
    },
    {
      label: `conditionOptions.${Condition.Moderate.toString()}`,
      value: Condition.Moderate,
    },
    {
      label: `conditionOptions.${Condition.ModerateToHabitable.toString()}`,
      value: Condition.ModerateToHabitable,
    },
    {
      label: `conditionOptions.${Condition.Habitable.toString()}`,
      value: Condition.Habitable,
    },
    {
      label: `conditionOptions.${Condition.HabitableToGood.toString()}`,
      value: Condition.HabitableToGood,
    },
    {
      label: `conditionOptions.${Condition.Good.toString()}`,
      value: Condition.Good,
    },
    {
      label: `conditionOptions.${Condition.GoodToExcellent.toString()}`,
      value: Condition.GoodToExcellent,
    },
    {
      label: `conditionOptions.${Condition.Excellent.toString()}`,
      value: Condition.Excellent,
    },
  ];

  const outsideValues: SingleFilterValue[] = [
    {
      label: `outsideValues.hasBalcony`,
      value: "hasBalcony",
    },
    {
      label: `outsideValues.hasRoofTerrace`,
      value: "hasRoofTerrace",
    },
    {
      label: `outsideValues.hasGarden`,
      value: "hasGarden",
    },
  ];
  const priceTypeFilters = [
    {
      label: "priceType.forSale",
      value: "sale",
    },
    {
      label: "priceType.forRent",
      value: "rent",
    },
    {
      label: "priceType.transactionPrice",
      value: "transactionPrice",
    },
    {
      label: "priceType.pricePerM2",
      value: "pricePerM2",
    },
  ];

  const forSaleAndOrRentFilterValues = [
    {
      label: "forSaleAndOrRent.forSale",
      value: "forSale",
    },
    {
      label: "forSaleAndOrRent.forRent",
      value: "forRent",
    },
  ];

  const followingValues = [
    {
      label: "isFollowing",
      value: "isFollowing",
    },
  ];

  const parkingPlaceCounts = [
    {
      label: "parkingPlaceCount.LessThanFive",
      value: ParkingPlaceCount.LessThanFive,
    },
    {
      label: "parkingPlaceCount.MoreThanFive",
      value: ParkingPlaceCount.MoreThanFive,
    },
    {
      label: "parkingPlaceCount.MoreThanTen",
      value: ParkingPlaceCount.MoreThanTen,
    },
    {
      label: "parkingPlaceCount.MoreThanTwenty",
      value: ParkingPlaceCount.MoreThanTwenty,
    },
    {
      label: "parkingPlaceCount.MoreThanFifty",
      value: ParkingPlaceCount.MoreThanFifty,
    },
  ];

  return {
    filters,
    realEstateGroupValues,
    assignmentTypeValues,
    conditionValues,
    priceTypeFilters,
    followingValues,
    forSaleAndOrRentFilterValues,
    parkingPlaceCounts,
    savedFilters: savedFilters(state),
    searchLocationValues: searchLocationValues(state),
    realEstateAgencyGroupValues: realEstateAgencyGroupValues(state),
    realEstateAgencyAppClientValues: realEstateAgencyAppClientValues(state),
    mutationTypeValues: mutationTypeOptions(state),
    mutationsDates: mutationsDates(state),
    priceRangeValues: priceRangeValues(state),
    availabilityStatusValues: availabilityStatusValues(state),
    listingTypePartValues: listingTypes(state, "listingTypesPart"),
    listingTypeBogValues: listingTypes(state, "listingTypesBog"),
    listingTypeAlvValues: listingTypes(state, "listingTypesAlv"),
    groundTypeValues: listingTypes(state, "groundTypes"),
    houseTypesValues: listingTypes(state, "houseTypes"),
    houseSortsValues: listingTypes(state, "houseSorts"),
    houseCharacteristicsValues: listingTypes(state, "houseCharacteristics"),
    apartmentSortValues: listingTypes(state, "apartmentSorts"),
    apartmentCharasteristicsValues: listingTypes(
      state,
      "apartmentCharacteristics"
    ),
    garageTypeValues: listingTypes(state, "garageTypes"),
    residentialSubtypeOthersValues: listingTypes(state, "otherPartTypes"),
    horseCompanySubtypes: listingTypes(state, "horseCompanyTypes"),
    parkingTypeOptions: listingTypes(state, "parkingTypes"),
    pigCompanyTypes: listingTypes(state, "pigCompanyTypes"),
    cattleFarmingSubtypes: listingTypes(state, "beefTypes"),
    horticulturalCompanyTypes: listingTypes(state, "horticulturalTypes"),
    beverageHospitalitySectorTypes: listingTypes(state, "horecaTypes"),
    garageTypeOptions: garageTypeOptions(state),
    locationPlaces: locationPlaces(state),
    orientationTypeValues: orientationTypeValues(state),
    energyClassOptions: energyClassOptions(state),
    conditionOptionsInside: conditionOptionsInside(state),
    conditionOptionsOutside: conditionOptionsOutside(state),
    outsideValues,
    livingAreaValues: livingAreaValues(state),
    noOfRoomsValues: noOfRoomsValues(state),
    noOfBedroomsValues: noOfBedroomsValues(state),
    contentValues: contentValues(state),
    floorsValues: floorsValues(state),
    yearOfConstructionValues: yearOfConstructionValues(state),
    plotSizeValues: plotSizeValues(state),
    gardenPlotSizeValues: gardenPlotSizeValues(state),
    garageCapacityValues: garageCapacityValues(state),
    facilitiesValues: facilitiesValues(state),
    otherSearchTypeValues: otherSearchTypeOptions(state),
    floorAreaValues: floorAreaValues(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  FiltersComponentProps
> = (dispatch) => ({
  setFilters: (filters: FilterConfig) =>
    dispatch(MLSActions.List.setFilters(filters)),
  navigate: (path: string) => dispatch(push(path)),
  resetOrdering: () =>
    dispatch(MLSActions.List.setOrdering(SortOrder.Descending, "")),
});

export type FiltersContainerProps = StateProps & DispatchProps;
export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
