import * as React from "react";
import { memo, useState, MouseEvent } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import {
  FolderTreeFolderEntity,
  DefaultFolderTreeFolderEntity,
} from "@haywork/api/kolibri";
import { ResourceText } from "../../..";

const styles = require("./style.scss");

interface Props {
  tree: FolderTreeFolderEntity | DefaultFolderTreeFolderEntity;
  depth: number;
  parentName: string;
  selectedPath: string;
  onClick: (name) => void;
}
export const DossierAddFolderFolderComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ tree, depth, parentName, selectedPath, onClick }: Props) => {
      const [collapsed, setCollapsed] = useState(false);

      const folders = tree.folders || [];
      const expandable = depth <= 3 && !!folders.length;
      const name = [parentName, tree.name].filter((d) => !!d).join("/");

      const toggleSelected = () => {
        if (depth === 4) return;
        onClick(name);
      };

      const toggleCollapsed = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!expandable) return;
        setCollapsed(!collapsed);
      };

      return (
        <div styleName={classNames("folder", { collapsed })}>
          <div
            styleName={classNames("folder__header", `depth-${depth}`, {
              selected: selectedPath === name,
            })}
            onClick={toggleSelected}
          >
            {!!expandable && (
              <div onClick={toggleCollapsed} styleName="icon">
                <i className="fal fa-fw fa-chevron-right" />
              </div>
            )}
            <div styleName="icon">
              <i
                className={classNames(
                  "fa",
                  "fa-fw",
                  collapsed ? "fa-folder" : "fa-folder-open"
                )}
              />
            </div>
            <div styleName="name">
              <ResourceText
                masterKey="rootDossierFolder"
                resourceKey={tree.name}
                fallback={tree.name}
              />
            </div>
          </div>
          {!!expandable && (
            <div styleName="sub-folders">
              {folders.map((folder, idx) => (
                <DossierAddFolderFolderComponent
                  tree={folder}
                  depth={depth + 1}
                  parentName={name}
                  key={name + idx}
                  onClick={onClick}
                  selectedPath={selectedPath}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  )
);
