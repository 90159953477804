import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  BatchItem,
  BatchItemType,
  BatchOption,
  DocumentSessionSnapShot,
  FolderTreeFileEntity,
} from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import { Input } from "@haywork/modules/form";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import {
  ChangeEvent,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import { AuthorizationWidgets, ResourceText } from "../../..";
import ActionList, { Action } from "../action-list";
import LinkedEntities from "../linked-entities";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  file: FolderTreeFileEntity;
  rootId: string;
  bulkSelectedFiles: FolderTreeFileEntity[];
  canSendEmail: boolean;
  onNavigate: (path: string) => void;
  onDownloadDocument: (id: string) => void;
  onRefresh: () => void;
  onArchive: (id: string) => Promise<void>;
  onUnArchive: (id: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  createEditSession: (dossierItemId: string) => Promise<void>;
  onToggleSelectForBulk: (file: FolderTreeFileEntity) => void;
  onEmail: (file: BatchItem) => void;
  onUpdateDocumentSession: (snapshot: DocumentSessionSnapShot) => Promise<void>;
}
const DossierItemDocumentSessionComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      file,
      rootId,
      onNavigate,
      onDownloadDocument,
      onArchive,
      onUnArchive,
      onDelete,
      onRefresh,
      createEditSession,
      bulkSelectedFiles,
      onToggleSelectForBulk,
      onEmail,
      canSendEmail,
      onUpdateDocumentSession,
    }: Props) => {
      const { linkedDocumentSession, id } = file;
      const [nameInputVisible, setNameInputVisible] = useState(false);
      const [actionListVisible, setActionListVisible] = useState(false);
      const actionRef = useRef(null);
      const nameInputRef = useRef(null);
      const [name, setName] = useState(get(linkedDocumentSession, "name"));
      const [orgName, setOrgName] = useState(
        get(linkedDocumentSession, "name", "")
      );

      const linkedEntities = useMemo(() => {
        if (!linkedDocumentSession) return [];
        const { linkedApplicants, linkedVendors, linkedAssignment } =
          linkedDocumentSession;
        const linkedEntities = [
          ...(linkedApplicants || []),
          ...(linkedVendors || []),
          linkedAssignment,
        ].filter((d) => !!d && d.id !== rootId);
        return linkedEntities;
      }, [linkedDocumentSession]);

      const isListOfGoods = useMemo(() => {
        const name = linkedDocumentSession?.name || "";
        return /lijst van zaken/gi.test(name);
      }, [linkedDocumentSession]);

      const onActionListClose = useCallback(() => {
        setActionListVisible(false);
      }, [setActionListVisible]);

      const onDownloadClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedDocumentSession) return;
        const { id } = linkedDocumentSession;
        onDownloadDocument(id);
      }, [linkedDocumentSession, setActionListVisible]);

      const onEmailClickHandler = useCallback(
        (asPDF: boolean = false) => {
          setActionListVisible(false);
          if (!linkedDocumentSession) return;
          const { id } = linkedDocumentSession;
          let options = [BatchOption.TempAccessUrl];
          if (asPDF) {
            options = [...options, BatchOption.ConvertToPdf];
          }
          onEmail({ id, type: BatchItemType.DocumentSession, options });
        },
        [linkedDocumentSession, onEmail]
      );

      const onEditClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedDocumentSession) return;
        const { id } = linkedDocumentSession;
        const path = route(DYNAMICDOCUMENTROUTES.DETAIL.URI, { id });
        onNavigate(path);
      }, [linkedDocumentSession, setActionListVisible]);

      const onEditWithOfficeClickHandler = useCallback(() => {
        setActionListVisible(false);
        if (!linkedDocumentSession || isListOfGoods) return;
        const { id } = linkedDocumentSession;
        createEditSession(id);
      }, [linkedDocumentSession, setActionListVisible, isListOfGoods]);

      const onArchiveClickHandler = useCallback(async () => {
        setActionListVisible(false);
        if (!linkedDocumentSession) return;
        const { id } = linkedDocumentSession;
        await onArchive(id);
        onRefresh();
      }, [linkedDocumentSession, setActionListVisible]);

      const onUnarchiveClickHandler = useCallback(async () => {
        setActionListVisible(false);
        if (!linkedDocumentSession) return;
        const { id } = linkedDocumentSession;
        await onUnArchive(id);
        onRefresh();
      }, [linkedDocumentSession, setActionListVisible]);

      const onDeleteHandler = useCallback(async () => {
        if (!linkedDocumentSession) return;
        const { id } = linkedDocumentSession;
        await onDelete(id);
        onRefresh();
      }, [linkedDocumentSession]);

      const onEditName = useCallback(() => {
        setNameInputVisible(true);
        setTimeout(() => {
          if (!!nameInputRef.current) {
            nameInputRef.current.focus();
          }
        }, 5);
      }, [setNameInputVisible, nameInputRef]);

      const onNameInputChangeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setName(value);
        },
        [setName]
      );

      const onNameInputBlurHandler = useCallback(async () => {
        setNameInputVisible(false);
        if (name === orgName) return;
        const snapshot = {
          ...linkedDocumentSession,
          name,
        };
        await onUpdateDocumentSession(snapshot);
        onRefresh();
        if (!name) {
          setName(linkedDocumentSession.name);
        }
      }, [
        setNameInputVisible,
        name,
        orgName,
        linkedDocumentSession,
        setName,
        onUpdateDocumentSession,
      ]);

      const bulkSelected = useMemo(() => {
        return !!bulkSelectedFiles.find((f) => f.id === id);
      }, [id, bulkSelectedFiles]);

      if (!linkedDocumentSession) return null;
      const { dateTimeCreated, isActive } = linkedDocumentSession;

      return (
        <div styleName={classNames("file", "document-session")}>
          <div styleName="bulk-select">
            <Input.CheckBox
              name={`bulk-${id}`}
              asSingleInput
              value={bulkSelected}
              onChange={() => onToggleSelectForBulk(file)}
            />
          </div>
          <div styleName="icon">
            <i className="fal fa-file-contract" />
          </div>
          <div styleName="meta">
            <div styleName="meta__name">
              {nameInputVisible ? (
                <input
                  type="text"
                  value={name}
                  onChange={onNameInputChangeHandler}
                  onBlur={onNameInputBlurHandler}
                  ref={nameInputRef}
                  data-lpignore="true"
                />
              ) : (
                <div styleName="name__wrapper">
                  {!isActive && (
                    <div styleName="not-active">
                      <ResourceText resourceKey="archived" />
                    </div>
                  )}
                  <div styleName="filename">
                    <FeatureSwitch feature="WEBDAV" disable>
                      <span styleName="faux-link" onClick={onEditClickHandler}>
                        {name}
                      </span>
                    </FeatureSwitch>
                    <FeatureSwitch feature="WEBDAV">
                      <span
                        styleName="faux-link"
                        onClick={
                          isListOfGoods
                            ? onEditClickHandler
                            : onEditWithOfficeClickHandler
                        }
                      >
                        {name}
                      </span>
                    </FeatureSwitch>
                  </div>
                </div>
              )}
            </div>

            {!nameInputVisible && (
              <div styleName="meta__edit" onClick={onEditName}>
                <i className="fal fa-pencil" />
              </div>
            )}

            <div styleName="meta__creation-date">
              {moment(dateTimeCreated).format("DD-MM-YYYY")}
            </div>
            <div styleName="meta__entities-description">
              <LinkedEntities linkedEntities={linkedEntities} />
            </div>
          </div>
          <div
            styleName="action"
            onClick={() => setActionListVisible(!actionListVisible)}
            ref={actionRef}
          >
            <i className="fal fa-ellipsis-v" />
          </div>

          <ActionList
            visible={actionListVisible}
            onClose={onActionListClose}
            parent={actionRef}
          >
            <FeatureSwitch feature="WEBDAV" disable>
              <Action
                label="dossier.documentSessionAction.edit"
                icon="fal fa-pencil"
                onClick={onEditClickHandler}
              />
            </FeatureSwitch>

            <FeatureSwitch feature="WEBDAV">
              <>
                <Action
                  label="dossier.documentSessionAction.editInKolibri"
                  icon="fal fa-pencil"
                  onClick={onEditClickHandler}
                />

                {!isListOfGoods && (
                  <Action
                    label="dossier.documentSessionAction.editWithOffice"
                    icon="fal fa-pen-alt"
                    onClick={onEditWithOfficeClickHandler}
                  />
                )}
              </>
            </FeatureSwitch>

            <Action
              label="dossier.documentSessionAction.download"
              icon="fal fa-download"
              onClick={onDownloadClickHandler}
            />

            {canSendEmail && (
              <>
                <Action
                  label="dossier.documentSessionAction.email"
                  icon="fal fa-paper-plane"
                  onClick={() => onEmailClickHandler()}
                />

                <Action
                  label="dossier.documentSessionAction.emailAsPDF"
                  icon="fal fa-envelope-open-text"
                  onClick={() => onEmailClickHandler(true)}
                />
              </>
            )}

            {isActive ? (
              <Action
                label="dossier.documentSessionAction.archive"
                icon="fal fa-archive"
                onClick={onArchiveClickHandler}
              />
            ) : (
              <Action
                label="dossier.documentSessionAction.unarchive"
                icon="fal fa-inbox-out"
                onClick={onUnarchiveClickHandler}
              />
            )}

            <Action
              label="dossier.documentSessionAction.delete"
              icon="fal fa-trash"
              onClick={onDeleteHandler}
            />

            <FeatureSwitch feature="APP_XCHANGE">
              <AuthorizationWidgets
                type={WidgetEntityType.DynamicDocument}
                location={WidgetEntityLocation.Dossier}
                id={id}
                onActionClick={() => setActionListVisible(false)}
              />
            </FeatureSwitch>
          </ActionList>
        </div>
      );
    }
  )
);

export default DossierItemDocumentSessionComponent;
