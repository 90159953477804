import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  LastChangedAssignmentsComponent,
  LastChangedAssignmentsComponentProps
} from "@haywork/modules/dashboard/components/widgets";
import { AppState, PagedResults } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";

interface StateProps {
  lastChangedAssignments: PagedResults<AssignmentSnapShot>;
}
interface DispatchProps {
  getLastChangedAssignments: (take?: number) => void;
  navigate: (path: string) => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  LastChangedAssignmentsComponentProps,
  AppState
> = (state) => {
  const { lastChangedAssignments } = state.assignment.widgets;

  return {
    lastChangedAssignments
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LastChangedAssignmentsComponentProps
> = (dispatch) => ({
  getLastChangedAssignments: (take?: number) =>
    dispatch(AssignmentThunks.getLastChangedAssignments(take)),
  navigate: (path: string) => dispatch(push(path))
});

export type LastChangedAssignmentsContainerProps = StateProps & DispatchProps;
export const LastChangedAssignmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LastChangedAssignmentsComponent);
