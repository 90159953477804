import { EventType, NotificationSettings } from "@haywork/api/event-center";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { Input } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bulk-items.component.scss");

interface Props {
  settings: NotificationSettings[];
  onChange: (settings: NotificationSettings[]) => void;
}
interface State {
  toggleAll: boolean;
  settings: NotificationSettings[];
}

@CSSModules(styles, { allowMultiple: true })
export class BulkItemsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onToggleAllChangeHandler = this.onToggleAllChangeHandler.bind(this);

    let toggleAll = true;
    this.props.settings.map((setting) => {
      if (!setting.enabled) toggleAll = false;
    });

    this.state = {
      toggleAll,
      settings: this.props.settings,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (nextProps.settings.length !== this.props.settings.length) {
      let toggleAll = true;
      nextProps.settings.map((setting) => {
        if (!setting.enabled) toggleAll = false;
      });

      this.setState({
        toggleAll,
        settings: nextProps.settings,
      });
    }
  }

  public render() {
    if (this.props.settings.length === 0) return null;

    return (
      <div styleName="bulk-settings">
        <div styleName="bulk-settings__header">
          <div styleName="input">
            <Input.Switch
              name="toggleAllBulkNotificationSettings"
              on={true}
              off={false}
              value={this.state.toggleAll}
              onChange={this.onToggleAllChangeHandler}
            />
          </div>
          <h2>
            <ResourceText resourceKey="toggleAllBulkNotificationSettings" />
          </h2>
        </div>

        <div styleName="bulk-settings__body">
          {this.state.settings.map((setting, idx) => (
            <ErrorBoundary key={idx}>
              <div styleName="setting">
                <div styleName="input">
                  <Input.Switch
                    name={`enabled.${setting.eventType.toString()}`}
                    on={true}
                    off={false}
                    value={setting.enabled}
                    onChange={(enabled: boolean) =>
                      this.onToggleChangeHandler(enabled, setting.eventType)
                    }
                  />
                </div>
                <ResourceText
                  masterKey="eventTypes"
                  resourceKey={setting.eventType.toString()}
                />
              </div>
            </ErrorBoundary>
          ))}
        </div>
      </div>
    );
  }

  private onToggleAllChangeHandler(toggleAll: boolean) {
    const settings = this.state.settings.map((setting) => ({
      ...setting,
      enabled: toggleAll,
    }));

    this.setState({
      toggleAll,
      settings,
    });

    this.props.onChange(settings);
  }

  private onToggleChangeHandler(enabled: boolean, eventType: EventType) {
    const settings = this.state.settings.map((setting) => {
      return setting.eventType === eventType
        ? { ...setting, enabled }
        : setting;
    });

    let toggleAll = true;
    settings.map((settings) => {
      if (!settings.enabled) toggleAll = false;
    });

    this.setState({
      toggleAll,
      settings,
    });

    this.props.onChange(settings);
  }
}
