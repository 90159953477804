import {
  BaseAlvStructure,
  ObjectAssignment,
  UnitOfMeasurement,
  AcquisitionObjectAssignment
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText, StepComponent } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentAgriculturalGlassStructuresComponent extends React.PureComponent<
  Props
> {
  private formControls: FormControls;
  private arrayFormControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const glassStructures: BaseAlvStructure[] = get(
      this.props.objectAssignment,
      "glassStructures",
      []
    );
    const mappedGlassStructures = glassStructures.map((glassStructure) => ({
      ...glassStructure,
      surface: get(glassStructure, "surface.area", "")
    }));

    this.formControls = {
      glassStructures: {
        value: mappedGlassStructures
      }
    };

    this.arrayFormControls = {
      buildingYear: {
        value: new Date().getFullYear(),
        onChange: (ref) => {
          if (!ref.value) {
            return { buildingYear: new Date().getFullYear() };
          }
        }
      },
      surface: {
        value: "",
        onChange: (ref, get) => {
          if (!get("buildingYear").value) {
            return { buildingYear: new Date().getFullYear() };
          }
        }
      },
      description: {
        value: "",
        onChange: (ref, get) => {
          if (!get("buildingYear").value) {
            return { buildingYear: new Date().getFullYear() };
          }
        }
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const glassStructures: BaseAlvStructure[] = get(
        this.props.objectAssignment,
        "glassStructures",
        []
      );
      const mappedGlassStructures = glassStructures.map((glassStructure) => ({
        ...glassStructure,
        surface: get(glassStructure, "surface.area", "")
      }));

      this.formRef.update({
        glassStructures: mappedGlassStructures
      }, true);
    }
  }

  public render() {
    return (
      <StepComponent title="horticultural.glassStructures">
        <Form
          name="glass-structures"
          formControls={this.formControls}
          onChange={this.onChange}
          form={(form) => (this.formRef = form)}
          asSubForm={this.props.asSubForm}
        >
          <Input.Array
            name="glassStructures"
            formControls={this.arrayFormControls}
            className="glass-structures-stretch"
          >
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="surface">
                  <label htmlFor="surface">
                    <ResourceText resourceKey="glassStructures.label.surface" />
                  </label>
                  <div className="input__helper">
                    <Input.Number
                      name="surface"
                      placeholder="glassStructures.label.surface"
                      pretty={true}
                    />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml={true} />
                    </div>
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="building-year">
                  <label htmlFor="buildingYear">
                    <ResourceText resourceKey="glassStructures.label.buildingYear" />
                    <Ui.RequiredForPublish hideHint={true} />
                  </label>
                  <Input.Number
                    name="buildingYear"
                    placeholder="glassStructures.label.buildingYear"
                    max={new Date().getFullYear() + 10}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="description">
                  <label htmlFor="description">
                    <ResourceText resourceKey="glassStructures.label.description" />
                  </label>
                  <Input.Text
                    name="description"
                    placeholder="glassStructures.label.description"
                  />
                </div>
              </div>
            </div>
          </Input.Array>
        </Form>
      </StepComponent>
    );
  }

  private onChange(values: FormReturnValue) {
    const glassStructures: BaseAlvStructure[] = values.glassStructures
      .map((glassStructure) => {
        return {
          ...glassStructure,
          surface: {
            area: glassStructure.surface,
            unitOfMeasurement: UnitOfMeasurement.Meter
          }
        };
      })
      .filter((glassStructure: BaseAlvStructure) => {
        return !!glassStructure.buildingYear;
      });

    const updatedObjectAssignment = {
      ...this.props.objectAssignment,
      glassStructures
    };

    this.props.onChange(updatedObjectAssignment);
  }
}
