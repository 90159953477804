import * as React from "react";
import { Draft, Folder, Message, EmailAddress } from "@haywork/api/mail";
import {
  EmailDraft,
  EmailFolder,
  EmailMessage,
} from "@haywork/stores/email-v2";
import { intlContext } from "@haywork/app";
import * as moment from "moment";
import { fromString } from "html-to-text";
import { v4 as uuid } from "uuid";
import { Colors } from "@haywork/enum/colors";
import { renderToStaticMarkup } from "react-dom/server";

export type EmailAssignment = {
  id: string;
  image?: string;
  addressLine1?: string;
  addressLine2?: string;
  price?: string;
  priceCondition?: string;
  numberOfRooms?: string;
  numberOfBedrooms?: string;
  numberOfBathrooms?: string;
  facilities?: string[];
  propertyTypes?: string[];
  insideArea?: string;
  outsideArea?: string;
};

export class EmailUtil {
  public static mapFolderToEmailFolder(
    folder: Folder,
    accountId: string,
    folders: EmailFolder[]
  ): EmailFolder {
    const ref = folders.find((f) => f.id === folder.id);
    return !ref
      ? {
          ...folder,
          accountId,
          unreadCount: 0,
          position: 0,
        }
      : {
          ...ref,
          ...folder,
          totalResults: undefined,
        };
  }

  public static mapEmailFolderToFolder(emailFolder: EmailFolder): {
    folder: Folder;
    accountId: string;
  } {
    const { accountId, ...folder } = emailFolder;
    return {
      folder,
      accountId,
    };
  }

  public static mapMessageToEmailMessage(
    message: Message,
    accountId: string,
    folderId: string,
    messages: EmailMessage[],
    markAsRead = false,
    isMixedResult = false
  ): EmailMessage {
    const ref = messages.find((m) => m.id === message.id);
    const { to, from, cc, bcc, subject, body, snippet } = message;
    const participants = [
      ...(to || []),
      ...(from || []),
      ...(cc || []),
      ...(bcc || []),
    ]
      .map((email) => `${email.email} ${email.name}`.trim())
      .join(" ");

    const flattenedBody = fromString(body, {
      ignoreHref: true,
      ignoreImage: true,
    });

    const searchString = [participants, subject, snippet, flattenedBody]
      .filter((d) => !!d)
      .join(" ");

    return !ref
      ? {
          ...message,
          accountId,
          folderId: isMixedResult ? folderId : message?.folder?.id || folderId,
          unread: !markAsRead ? message.unread : false,
          searchString,
        }
      : {
          ...ref,
          ...message,
          folderId: isMixedResult ? folderId : message?.folder?.id || folderId,
          unread: !markAsRead ? message.unread : false,
          searchString,
        };
  }

  public static mapEmailMessageToMessage(emailMessage: EmailMessage): {
    message: Message;
    accountId: string;
    folderId: string;
  } {
    const { accountId, folderId, ...message } = emailMessage;
    return {
      message,
      accountId,
      folderId: message?.folder?.id || folderId,
    };
  }

  public static mapDraftToEmailDraft(
    draft: Draft,
    accountId: string,
    folderId: string,
    drafts: EmailDraft[]
  ): EmailDraft {
    const ref = drafts.find((drafts) => drafts.id === draft.id);
    const { to, cc, bcc, subject, body, snippet } = draft;
    const participants = [...(to || []), ...(cc || []), ...(bcc || [])]
      .map((email) => `${email.email} ${email.name}`.trim())
      .join(" ");
    const searchString = [participants, subject, snippet, body]
      .filter((d) => !!d)
      .join(" ");

    return !ref
      ? {
          ...draft,
          accountId,
          folderId,
          searchString,
        }
      : {
          ...ref,
          ...draft,
          searchString,
        };
  }

  public static mapEmailDraftToDraft(emailDraft: EmailDraft): {
    draft: Draft;
    accountId: string;
    folderId: string;
  } {
    const { accountId, folderId, ...draft } = emailDraft;
    return {
      draft,
      accountId,
      folderId,
    };
  }

  public static parseEmailsAsString(
    emailAddresses: EmailAddress[]
  ): string | null {
    if (!emailAddresses || emailAddresses.length === 0) return null;

    return emailAddresses
      .map((address) => {
        return !!address.name && address.name !== address.email
          ? `${address.name} <${address.email}>`
          : address.email;
      })
      .join(", ");
  }

  public static prepareEmailBody(
    from: EmailAddress[],
    to: EmailAddress[],
    cc: EmailAddress[],
    date: Date,
    subject: string,
    body: string
  ): string {
    const filteredBody = (body || "").replace(
      /<\/?haywork(template|signature|body)>/gi,
      ""
    );

    const fromString = EmailUtil.parseEmailsAsString(from);
    const toString = EmailUtil.parseEmailsAsString(to);
    const ccString = EmailUtil.parseEmailsAsString(cc);

    const messageData = [];
    messageData.push(
      `<p style="font-family: Arial, sans-serif; font-size: 14px; color: #000000">`
    );
    messageData.push(
      `<strong>${intlContext.formatMessage({
        id: "email.info.sent",
        defaultMessage: "Sent",
      })}</strong>: ${moment(date).format("DD MMMM YYYY, HH:mm:ss")}<br />`
    );
    if (!!fromString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.from",
          defaultMessage: "From",
        })}</strong>: ${fromString}<br />`
      );
    }
    if (!!toString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.to",
          defaultMessage: "To",
        })}</strong>: ${toString}<br />`
      );
    }
    if (!!ccString) {
      messageData.push(
        `<strong>${intlContext.formatMessage({
          id: "email.info.cc",
          defaultMessage: "CC",
        })}</strong>: ${ccString}<br />`
      );
    }
    messageData.push(
      `<strong>${intlContext.formatMessage({
        id: "email.info.subject",
        defaultMessage: "Subject",
      })}</strong>: ${subject}<br />`
    );

    if (!/<body[^>]*>/gi.test(filteredBody)) {
      return [
        `<hayworktemplate><p>&nbsp;</p></hayworktemplate>`,
        `<hayworksignature></hayworksignature>`,
        `<hr style="border: none; height: 1px; background-color: #cccccc; margin: 24px 0;" />`,
        `<hayworkbody>${messageData.join("") + filteredBody}</hayworkbody>`,
      ].join("");
    }

    const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/gim;
    const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/gim;

    let result;
    const styles = [];
    while ((result = styleRegex.exec(filteredBody)) !== null) {
      styles.push((result[0] || "").replace(/body/gi, "contentbody"));
    }

    const bodyParts = [];
    while ((result = bodyRegex.exec(filteredBody)) !== null) {
      let part = result[0] || "";
      part = part.replace(/<(\s+)?body/gi, "<contentbody");
      part = part.replace(/<(\s+)?\/(\s+)?body/gi, "</contentbody");

      bodyParts.push(part);
    }

    const newBody = [...bodyParts, ...styles]
      .join("")
      .replace(/body/g, "contentbody");

    return [
      `<hayworktemplate><p>&nbsp;</p></hayworktemplate>`,
      `<hayworksignature></hayworksignature>`,
      `<hr style="border: none; height: 1px; background-color: #cccccc; margin: 24px 0;" />`,
      `<hayworkbody>`,
      messageData.join(""),
      newBody,
      `</hayworkbody>`,
    ].join("");
  }

  public static createAssignmentsEmailTemplate(assignments: EmailAssignment[]) {
    const Row = ({
      assignment,
      idx,
      id,
    }: {
      assignment: EmailAssignment;
      idx: number;
      id: string;
    }) => {
      const {
        image,
        addressLine1,
        addressLine2,
        price,
        priceCondition,
        numberOfBathrooms,
        numberOfBedrooms,
        numberOfRooms,
        facilities,
        propertyTypes,
        insideArea,
        outsideArea,
      } = assignment;

      const types = (propertyTypes || []).join(", ");
      const areas = [insideArea, outsideArea].filter((d) => !!d).join(" / ");
      const property = [types, areas].filter((d) => !!d).join(" &bull; ");

      const other = [
        numberOfRooms,
        numberOfBedrooms,
        numberOfBathrooms,
        (facilities || []).join(", "),
      ]
        .filter((d) => !!d)
        .join(" &bull; ");

      const meta = (
        <>
          {!!addressLine1 && (
            <p
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: 700,
                color: Colors.TextBlack,
                textTransform: "capitalize",
              }}
            >
              {addressLine1}
            </p>
          )}
          {!!addressLine2 && (
            <p style={{ margin: 0, fontSize: 14, color: Colors.Gray }}>
              {addressLine2}
            </p>
          )}
          {!!property && (
            <p
              style={{ margin: 0, marginTop: 8, fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: property }}
            />
          )}
          {!!other && (
            <p
              style={{ margin: 0, fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: other }}
            />
          )}
          {!!price && (
            <p
              style={{
                margin: 0,
                marginTop: 8,
                fontWeight: 700,
                fontSize: 14,
              }}
            >
              {price}{" "}
              {!!priceCondition && (
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    textTransform: "lowercase",
                  }}
                >
                  {priceCondition}
                </span>
              )}
            </p>
          )}
        </>
      );

      return (
        <>
          {idx !== 0 && (
            <hr
              style={{
                margin: 0,
                marginTop: 12,
                marginBottom: 12,
                backgroundColor: Colors.LightGray,
              }}
            />
          )}
          <table
            style={{
              width: "100%",
              border: "none",
            }}
          >
            <tr>
              <td
                style={{ width: 200, verticalAlign: "top" }}
                className={`preview-${id}`}
              >
                {!image ? (
                  <div
                    style={{
                      width: 200,
                      height: 150,
                      backgroundColor: Colors.LightGray,
                    }}
                  />
                ) : (
                  <img
                    style={{
                      display: "block",
                      margin: 0,
                      width: "100%",
                      height: "auto",
                    }}
                    src={image}
                  />
                )}
              </td>
              <td
                className={`mobile-hidden-${id}`}
                style={{ verticalAlign: "top", paddingLeft: 12 }}
              >
                {meta}
              </td>
            </tr>
            <tr
              style={{ display: "none" }}
              className={`tr-mobile-visible-${id}`}
            >
              <td style={{ paddingTop: 8 }}>{meta}</td>
            </tr>
          </table>
        </>
      );
    };

    const id = uuid();
    const html = (
      <>
        <table
          style={{ width: 600, border: "none", margin: 0 }}
          className={`wrapper-${id}`}
        >
          <tr>
            <td>
              {(assignments || []).map((assignment, idx) => (
                <Row assignment={assignment} idx={idx} id={id} key={idx} />
              ))}
            </td>
          </tr>
        </table>
        <style type="text/css">
          {`
          @media screen and (max-width: 600px) {
            .wrapper-${id} {
              max-width: 480px !important;
            }
            .preview-${id} {
              width: 100% !important;
            }
            .mobile-hidden-${id} {
              display: none;
            }
            .tr-mobile-visible-${id} {
              display: table-row !important;
            }
            .td-mobile-visible-${id} {
              display: table-cell !important;
            }
          }
        `}
        </style>
      </>
    );

    return renderToStaticMarkup(html);
  }
}
