import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { RealEstateAgencyDetailDossierContainer, RealEstateAgencyEditContainer } from "@haywork/modules/real-estate-agency";
import { REALESTATEAGENCYROUTES } from "@haywork/constants";

const route = RouteUtil.mapStaticRouteValues;
export const RealEstateAgencyDetailRouting = ({ id }) => {
  return <Switch>
    <Route exact path={ REALESTATEAGENCYROUTES.DETAIL.URI } render={ () => <Redirect to={ route(REALESTATEAGENCYROUTES.EDIT.URI, { id }) } /> } />
    <Route path={ REALESTATEAGENCYROUTES.DOSSIER.URI } component={ RealEstateAgencyDetailDossierContainer } />
    <Route path={ REALESTATEAGENCYROUTES.EDIT.URI } component={ RealEstateAgencyEditContainer } />
  </Switch>;
};
