import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import {
  ContactLinkSnapShot,
  ContactLinkTypeSuggestion,
  ContactPerson,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { MAINROUTES, RELATIONROUTES, REQUEST } from "@haywork/constants";
import {
  AuthorizationThunks,
  Dispatch,
  RelationThunks,
} from "@haywork/middleware/thunk";
import {
  RelationLinkedRelationsListComponent,
  RelationLinkedRelationsListComponentProps,
} from "@haywork/modules/relation";
import { AppState, RelationActions } from "@haywork/stores";
import { RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

const route = RouteUtil.mapStaticRouteValues;

interface ExtendedContactPerson extends ContactPerson {
  aroCreatedRelation: RelationSnapShot;
}

interface StateProps {
  currentComponentState: ExtendedContactPerson;
  selectedRelationId: string;
  contactLinks: ContactLinkSnapShot[];
  relationContactLinkState: string;
  selectedRelationDisplayName: string;
  selectedRelationType: RelationType;
  saveContactLinkState: string;
  linkTypes: ContactLinkTypeSuggestion[];
  realEstateAgencyId: string;
  canLoadMore: boolean;
  isLoading: boolean;
  host: string;
  contactAddError;
}
interface DispatchProps {
  getContactLinks: (relationId: string, init?: boolean) => void;
  onContactLinkEdit: (id: string, relationType: RelationType) => void;
  deleteRelation: (contact: ContactLinkSnapShot) => void;
  unDeleteRelation: (contact: ContactLinkSnapShot) => void;
  removeRelationFromList: (contact: ContactLinkSnapShot) => void;
  saveContactLink: (
    sourceRelationId: string,
    targetRelationId: string,
    isPartnerLink: boolean,
    linkType?: string,
    linkDescription?: string
  ) => void;
  updateContactLink: (
    sourceRelationId: string,
    targetRelationId: string,
    isPartnerLink: boolean,
    linkType?: string,
    linkDescription?: string
  ) => void;
  navigate: (route: string) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, RelationLinkedRelationsListComponentProps>(
  state: AppState
) => ({
  currentComponentState: state.editable.currentComponentState,
  selectedRelationId: state.relation.detail.selectedRelationId,
  contactLinks: state.relation.detailContactlink.contactLinks,
  relationContactLinkState:
    state.relation.detailContactlink.relationContactLinkState,
  selectedRelationDisplayName:
    state.relation.detail.selectedRelationDisplayName,
  selectedRelationType: state.relation.detail.selectedRelationType,
  saveContactLinkState:
    state.relation.detailContactlink.relationSaveContactLinkState,
  linkTypes: state.main.mastertable.kolibri.contactLinkTypes,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  canLoadMore:
    state.relation.detailContactlink.contactLinkPage <
    state.relation.detailContactlink.contactLinkPageCount,
  isLoading:
    state.relation.detailContactlink.relationContactLinkState ===
    REQUEST.PENDING,
  host: state.appSettings.host,
  contactAddError: state.relation.detailContactlink.contactAddError,
});

const mapDispatchToProps = <
  DispatchProps,
  RelationLinkedRelationsListComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getContactLinks: (relationId: string, init?: boolean) =>
    dispatch(RelationThunks.getContactLinks(relationId, init)),
  onContactLinkEdit: (id: string, relationType: RelationType) =>
    dispatch(push(getRelationEditPageUrl(id, relationType))),
  deleteRelation: (contact: ContactLinkSnapShot) =>
    dispatch(RelationThunks.deleteContactLink(contact)),
  unDeleteRelation: (contact: ContactLinkSnapShot) =>
    dispatch(RelationThunks.unDeleteContactLink(contact)),
  removeRelationFromList: (contactLink: ContactLinkSnapShot) =>
    dispatch(RelationActions.removeRelationFromList({ contactLink })),
  saveContactLink: (
    sourceRelationId: string,
    targetRelationId: string,
    isPartnerLink: boolean,
    linkType?: string,
    linkDescription?: string
  ) =>
    dispatch(
      RelationThunks.saveContactLink(
        sourceRelationId,
        targetRelationId,
        isPartnerLink,
        linkType,
        linkDescription
      )
    ),
  updateContactLink: (
    sourceRelationId: string,
    targetRelationId: string,
    isPartnerLink: boolean,
    linkType?: string,
    linkDescription?: string
  ) =>
    dispatch(
      RelationThunks.updateContactLink(
        sourceRelationId,
        targetRelationId,
        isPartnerLink,
        linkType,
        linkDescription
      )
    ),
  navigate: (route: string) => dispatch(push(route)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

const getRelationEditPageUrl = (id: string, relationType: RelationType) => {
  let url: string;

  switch (relationType) {
    case RelationType.ContactCompany: {
      url = route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, { id });
      break;
    }
    case RelationType.ContactPerson: {
      url = route(RELATIONROUTES.CONTACT_PERSON_EDIT.URI, { id });
      break;
    }
    default: {
      url = MAINROUTES.RELATIONS.URI;
    }
  }
  return url;
};

export type RelationAroRelationsContainerProps = StateProps & DispatchProps;
export const RelationAroRelationsContainer = connect<
  StateProps,
  DispatchProps,
  RelationLinkedRelationsListComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationLinkedRelationsListComponent);
