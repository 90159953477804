import { Provider } from "@haywork/api/mail";
import { KEYCODE, REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");

interface Props {
  provider: Provider;
  accountName?: string;
  createStatus: string;
  onBack: () => void;
  onSubmit: (values: FormReturnValue) => void;
}
interface State {
  advancedSettingsVisible: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AccountDataExchangeComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onToggleAdvancedSettings = this.onToggleAdvancedSettings.bind(this);
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);

    this.formControls = {
      provider: { value: this.props.provider.type },
      accountName: { value: this.props.accountName || "" },
      emailAddress: {
        value: "",
        validators: [Validators.required(), Validators.email()],
      },
      password: {
        value: "",
        validators: [Validators.required()],
      },
      username: {
        value: "",
      },
      activeSyncHost: {
        value: "",
      },
    };

    this.state = {
      advancedSettingsVisible: false,
    };
  }

  public render() {
    const disabled = this.props.createStatus === REQUEST.PENDING;
    const advancedTriggerStyle = classNames("advanced-trigger", {
      collapsed: this.state.advancedSettingsVisible,
    });

    return (
      <div styleName="simple-data">
        <div styleName="simple-data__body">
          <div
            styleName="avatar"
            style={{
              backgroundImage: `url(${JSON.stringify(
                this.props.provider.logoUrl
              )})`,
            }}
          />
          <h1>
            <ResourceText resourceKey="simpleDataEmailTitle" />
          </h1>
          <Form
            name="simple-account"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.props.onSubmit}
          >
            <div styleName="centered-row">
              <label htmlFor="accountName">
                <ResourceText resourceKey="emailAccountName" />
              </label>
              <Input.Text
                name="accountName"
                placeholder="emailAccountNamePlaceholder"
                disabled={disabled}
              />
            </div>

            <div styleName="centered-row">
              <label htmlFor="emailAddress">
                <ResourceText resourceKey="emailAccountEmailAddress" />
              </label>
              <Input.Email
                name="emailAddress"
                data-cy="CY-emailAddressInput"
                placeholder="emailAccountEmailAddressPlaceholder"
                disabled={disabled}
              />
            </div>

            <div styleName="centered-row">
              <label htmlFor="password">
                <ResourceText resourceKey="emailAccountPassword" />
              </label>
              <Input.Password
                name="password"
                data-cy="CY-passwordInput"
                placeholder="emailAccountPasswordPlaceholder"
                canToggle
                disabled={disabled}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>

            <div styleName="centered-row">
              <div
                styleName={advancedTriggerStyle}
                onClick={this.onToggleAdvancedSettings}
              >
                <ResourceText resourceKey="advancedExchangeSettings" />
                <i className="fal fa-chevron-down" />
              </div>
            </div>

            {this.state.advancedSettingsVisible && (
              <React.Fragment>
                <div styleName="centered-row">
                  <label htmlFor="username">
                    <ResourceText resourceKey="emailAccountUsername" />
                  </label>
                  <Input.Text
                    name="username"
                    placeholder="emailAccountUsernamePlaceholder"
                    disabled={disabled}
                  />
                </div>

                <div styleName="centered-row">
                  <label htmlFor="activeSyncHost">
                    <ResourceText resourceKey="emailAccountActiveSyncHost" />
                  </label>
                  <Input.Text
                    name="activeSyncHost"
                    placeholder="emailAccountActiveSyncHostPlaceholder"
                    disabled={disabled}
                  />
                </div>
              </React.Fragment>
            )}
          </Form>
        </div>
        <div styleName="footer">
          <div styleName="left">
            <button
              type="button"
              className="btn btn-gray"
              onClick={this.props.onBack}
              disabled={disabled}
            >
              <ResourceText resourceKey="back" />
            </button>
          </div>
          <div styleName="right">
            <button
              type="button"
              data-cy="CY-submitButton"
              className="btn btn-primary"
              onClick={this.onSubmitClickHandler}
            >
              <ButtonLoader resourceKey="bindMailBox" loading={disabled} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitClickHandler() {
    this.formRef.submit();
  }

  private onToggleAdvancedSettings() {
    const advancedSettingsVisible = !this.state.advancedSettingsVisible;

    this.setState({
      advancedSettingsVisible,
    });

    if (!advancedSettingsVisible) {
      this.formRef.update({
        username: "",
        activeSyncHost: "",
      });
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    if (keyCode === KEYCODE.ENTER) {
      this.onSubmitClickHandler();
    }
  }
}
