import * as React from "react";
import { FC, memo, useCallback } from "react";
import Pill from "@haywork/components/ui/pill";
import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES
} from "@haywork/constants";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  relation: LinkedRelation;
  isActive: boolean;
  onNavigate: (path: string) => void;
};

const RelationPillComponent: FC<Props> = memo(
  ({ relation, onNavigate, isActive }) => {
    const { typeOfRelation, id } = relation;
    const onClick = useCallback(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case RelationType.ContactPerson: {
          const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        case RelationType.Employee: {
          const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
          onNavigate(path);
          break;
        }
        case RelationType.Office: {
          const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
          onNavigate(path);
          break;
        }
        default: {
          break;
        }
      }
    }, [typeOfRelation, id, onNavigate]);

    return (
      <Pill
        label={relation.displayName}
        color={
          !isActive
            ? Colors.Gray
            : typeOfRelation === RelationType.ContactCompany
            ? Colors.Secondary
            : Colors.Primary
        }
        onClick={onClick}
      />
    );
  }
);

export default RelationPillComponent;
