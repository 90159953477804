import * as React from "react";
import * as CSSModules from "react-css-modules";
import { intlContext } from "@haywork/app";

const styles = require("./search-bar.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class SearchBar extends React.Component {
  public render() {
    return (
      <div styleName="searchbar">
        <input
          type="search"
          placeholder={intlContext.formatMessage({
            id: "searchbarPlaceHolder"
          })}
        />
      </div>
    );
  }
}
