import { MapDispatchToProps } from "@haywork/middleware";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { AppState } from "@haywork/stores";
import { EmailMessage } from "@haywork/stores/email-v2";
import { connect, MapStateToProps } from "react-redux";
import { currentMessage } from "../../selectors";
import { DetailComponent, DetailComponentProps } from "./detail";

type StateProps = {
  currentMessage: EmailMessage | null;
};
type DispatchProps = {
  getMessage: (
    messageId: string,
    accountId: string,
    folderId: string,
    blockExternalReferences?: boolean,
    markAsRead?: boolean
  ) => Promise<EmailMessage>;
  createNewEmail: (emails: string[]) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DetailComponentProps,
  AppState
> = (state) => {
  return {
    currentMessage: currentMessage(state)
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DetailComponentProps
> = (dispatch) => ({
  getMessage: (
    messageId: string,
    accountId: string,
    folderId: string,
    blockExternalReferences?: boolean,
    markAsRead?: boolean
  ) =>
    dispatch(
      EmailThunk.Messages.getMessage(
        messageId,
        accountId,
        folderId,
        blockExternalReferences,
        markAsRead
      )
    ),
  createNewEmail: (emails: string[]) =>
    dispatch(EmailThunk.Main.createNewEmail(emails))
});

export type DetailContainerProps = StateProps & DispatchProps;
export const DetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);
