import { PhotoBlob } from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import {
  AvatarUploadTriggerComponent,
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  ContactCompanyDetailContainerProps,
  ContactCompanyDetailRouting,
} from "@haywork/modules/relation";
import { ObjectTimestamps, ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { NotesList } from "@haywork/modules/notes-v3";
import { intlContext } from "@haywork/app";

const styles = require("./contact-company-detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface ContactCompanyDetailComponentProps {}
type Props = ContactCompanyDetailComponentProps &
  ContactCompanyDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ContactCompanyDetailComponent extends React.Component<Props> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.avatarChange = this.avatarChange.bind(this);

    this.formControls = {
      relationAvatar: { value: this.props.selectedRelationAvatar },
    };

    this.onDisabledClick = this.onDisabledClick.bind(this);
  }

  public componentDidMount() {
    this.props.setTabTitle(
      this.props.contactCompany.displayName
        ? get(this.props.contactCompany, "displayName")
        : intlContext.formatMessage({
            id: "newRelation",
            defaultMessage: "",
          })
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.contactCompany &&
      nextProps.contactCompany &&
      this.props.contactCompany.displayName !==
        nextProps.contactCompany.displayName &&
      this.props.contactCompany.id === nextProps.contactCompany.id
    ) {
      this.props.setTabTitle(
        nextProps.contactCompany.displayName
          ? nextProps.contactCompany.displayName
          : intlContext.formatMessage({
              id: "newRelation",
              defaultMessage: "",
            })
      );
    }

    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      const { contactCompany } = nextProps;
      const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
        id: contactCompany.id,
      });
      this.props.saveContactCompany(contactCompany, path);
    }
  }

  public render() {
    if (!this.props.contactCompany) return null;

    const { id, isNew } = this.props.contactCompany;
    const inEditMode = this.props.location.pathname.match(/edit/);
    const navItemClassName = classNames({ disabled: isNew });

    return (
      <div styleName="detailContainer">
        {!isNew && <NotesList parentId={id} />}
        <div styleName="sideBar">
          <div styleName="avatar" className="hidden-xs hidden-sm">
            <Form
              name="relationDetailAvatar"
              formControls={this.formControls}
              onChange={this.avatarChange}
            >
              <Input.Avatar name="relationAvatar">
                <AvatarUploadTriggerComponent />
              </Input.Avatar>
            </Form>
          </div>
          <div styleName="menu">
            <div styleName="menu">
              {!inEditMode &&
                this.renderMenuLink(
                  route(RELATIONROUTES.CONTACT_COMPANY_GENERAL.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral"
                )}
              {!!inEditMode &&
                this.renderMenuLink(
                  route(RELATIONROUTES.CONTACT_COMPANY_EDIT.URI, { id }),
                  "fa-user-circle",
                  "tabGeneral",
                  true
                )}
              {this.renderMenuLink(
                route(RELATIONROUTES.CONTACT_COMPANY_TIMELINE.URI, { id }),
                "fa-align-left",
                "tabTimeline"
              )}
              {this.renderMenuLink(
                route(RELATIONROUTES.CONTACT_COMPANY_LINKED_RELATIONS.URI, {
                  id,
                }),
                "fa-users",
                "tabContactPersons"
              )}

              {/* deals, coming soon */}
              {/* <span styleName="item" className="disabled">
                <i className="fal fa-fw fa-handshake" />
                <span styleName="text">
                  <ResourceText resourceKey="tabDeals" />
                </span>
                <span className="pull-right label label-success">
                  <ResourceText resourceKey="soon" />
                </span>
              </span> */}

              {this.renderMenuLink(
                route(RELATIONROUTES.CONTACT_COMPANY_ASSIGNMENT.URI, { id }),
                "fa-folder-open",
                "tabAssignments"
              )}
              {this.renderMenuLink(
                route(RELATIONROUTES.CONTACT_COMPANY_DOSSIER.URI, { id }),
                "fa-archive",
                "tabDossier"
              )}
              {this.renderMenuLink(
                route(RELATIONROUTES.CONTACT_COMPANY_SEARCH_ASSIGNMENTS.URI, {
                  id,
                }),
                "fa-search",
                "tabSearchProfile"
              )}
            </div>
          </div>
          <ObjectTimestamps
            linkedCreatedBy={this.props.contactCompany.linkedCreatedBy}
            linkedModifiedBy={this.props.contactCompany.linkedModifiedBy}
            dateTimeCreated={this.props.contactCompany.dateTimeCreated}
            dateTimeModified={this.props.contactCompany.dateTimeModified}
          />
        </div>
        <div styleName="detailContent">
          <ContactCompanyDetailRouting id={id} />
        </div>
      </div>
    );
  }

  private onDisabledClick(event: React.MouseEvent<HTMLElement>) {
    if (this.props.contactCompany.isNew) {
      event.preventDefault();
    }
  }

  private avatarChange(values: FormReturnValue) {
    const avatar: PhotoBlob = values.relationAvatar;
    this.props.updateContactCompanyLogo(
      avatar ? avatar : null,
      this.props.contactCompany.id
    );
  }

  private renderMenuLink(
    path: string,
    icon: string,
    label: string,
    overrideDisabled: boolean = false
  ) {
    const isNewOrEdit =
      !overrideDisabled &&
      (this.props.contactCompany.isNew ||
        this.props.location.pathname.match(/edit/));
    const itemStyle = classNames({ disabled: isNewOrEdit });
    const iconStyle = classNames("fal fa-fw", icon);

    return !isNewOrEdit ? (
      <NavLink
        styleName="item"
        className={itemStyle}
        activeClassName="active"
        to={path}
      >
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </NavLink>
    ) : (
      <div styleName="item" className={itemStyle}>
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </div>
    );
  }
}
