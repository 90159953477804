import {
  AgriculturalSubtypeOther,
  AgriculturalSubtypeOtherOption,
  AlvBuildingPlotDestinationOption,
  CattleFarmingSubtypeOption,
  HorseCompanySubtypeOption,
  HorticulturalCompanyTypeOption,
  HouseCharacteristicOption,
  HouseSortOption,
  HouseTypeOption,
  ListingType,
  ListingTypeOption,
  ObjectAssignment,
  PigCompanyTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
  RawFormControl,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;

const styles = require("./edit-client.component.scss");

interface AssignmentAlvTypesComponentProps {
  listingTypes: ListingTypeOption[];
  objectAssignmentUpdated: (objectAssignment: ObjectAssignment) => void;
  objectAssignment: ObjectAssignment;
  horseCompanySubtypes: HorseCompanySubtypeOption[];
  cattleFarmingSubtypes: CattleFarmingSubtypeOption[];
  horticulturalCompanyTypes: HorticulturalCompanyTypeOption[];
  pigCompanyTypes: PigCompanyTypeOption[];
  agriculturalSubtypeOthers: AgriculturalSubtypeOtherOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  alvBuildingPlotDestinationOptions: AlvBuildingPlotDestinationOption[];
}
interface AssignmentAlvTypesComponentState {
  selectedListingType: ListingType;
  showHorseCompanySubTypes: boolean;
  showCattleFarmingSubtypes: boolean;
  showHorticulturalCompanyTypes: boolean;
  showPigCompanyTypes: boolean;
  showTypeAlvOtherOptions: boolean;
  showHouseSort: boolean;
  showHouseType: boolean;
  showHouseCharacteristic: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentAlvTypesComponent extends React.Component<
  AssignmentAlvTypesComponentProps,
  AssignmentAlvTypesComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);
    const listingType = this.props.objectAssignment.listingType;
    this.state = {
      selectedListingType: listingType,
      showHorseCompanySubTypes: listingType === ListingType.HorsesCompany,
      showCattleFarmingSubtypes: listingType === ListingType.CattleFarming,
      showHorticulturalCompanyTypes: listingType === ListingType.Horticultural,
      showPigCompanyTypes: listingType === ListingType.PigHolding,
      showTypeAlvOtherOptions: listingType === ListingType.Other,
      showHouseSort: listingType === ListingType.AgriculturalHouse,
      showHouseType:
        !!this.props.objectAssignment.houseType ||
        !!this.props.objectAssignment.houseSort,
      showHouseCharacteristic:
        !!this.props.objectAssignment.houseCharacteristic ||
        !!this.props.objectAssignment.houseType,
    };

    this.formControls = {
      listingType: {
        value: value(
          this.props.objectAssignment,
          "listingType",
          ListingType.ArableCompany
        ),
        onChange: this.listingTypeChange.bind(this),
      },
      secondaryListingTypes: {
        value: value(this.props.objectAssignment, "secondaryListingTypes"),
      },
      horseCompanySubtype: {
        value: value(this.props.objectAssignment, "horseCompanySubtype"),
      },
      cattleFarmingSubtype: {
        value: value(this.props.objectAssignment, "cattleFarmingSubtype"),
      },
      horticulturalCompanyType: {
        value: value(this.props.objectAssignment, "horticulturalCompanyType"),
      },
      pigCompanyType: {
        value: value(this.props.objectAssignment, "pigCompanyType"),
      },
      agriculturalSubtypeOther: {
        value: value(this.props.objectAssignment, "agriculturalSubtypeOther"),
      },
      houseSort: {
        value: value(this.props.objectAssignment, "houseSort"),
        onChange: (ref) => {
          if (!!ref.value) {
            this.setState({ showHouseType: true });
          } else {
            this.setState({
              showHouseType: false,
              showHouseCharacteristic: false,
            });
          }
          return !ref.value ? { houseType: "", houseCharacteristic: "" } : null;
        },
      },
      houseType: {
        value: value(this.props.objectAssignment, "houseType"),
        onChange: (ref) => {
          this.setState({ showHouseCharacteristic: !!ref.value });
          return !ref.value ? { houseCharacteristic: "" } : null;
        },
      },
      houseCharacteristic: {
        value: value(this.props.objectAssignment, "houseCharacteristic"),
      },
      alvMainPlotDestination: {
        value: value(this.props.objectAssignment, "alvMainPlotDestination"),
      },
    };

    this.listingTypeChange = this.listingTypeChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    const { listingTypes, objectAssignment } = this.props;
    return (
      <div>
        <Form
          name="alvListingType"
          onChange={this.onChangeHandler}
          formControls={this.formControls}
          asSubForm
        >
          {/* ALV listing type */}
          <div className="form__row" styleName="alv-types">
            <div className="form__group">
              <div className="column">
                <label htmlFor="listingType">
                  <ResourceText resourceKey="assignmentWhatIsOffered" />
                </label>
                <Input.List
                  name="listingType"
                  values={listingTypes}
                  displayPath="displayName"
                  comparePath="value"
                  resultPath="value"
                  isRoot
                  disabled={!objectAssignment.isNew}
                />
              </div>

              {/* Horse company subtypes */}
              {this.state.showHorseCompanySubTypes && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="horseCompanySubtype">
                      <ResourceText resourceKey="horseCompanySubType" />
                    </label>
                    <Input.List
                      name="horseCompanySubtype"
                      values={this.props.horseCompanySubtypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* Veal cattle subtypes */}
              {this.state.showCattleFarmingSubtypes && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="cattleFarmingSubtype">
                      <ResourceText resourceKey="cattleFarmingSubType" />
                    </label>
                    <Input.List
                      name="cattleFarmingSubtype"
                      values={this.props.cattleFarmingSubtypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* Horticultural subtypes */}
              {this.state.showHorticulturalCompanyTypes && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="horticulturalCompanyType">
                      <ResourceText resourceKey="HorticulturalCompanySubType" />
                    </label>
                    <Input.List
                      name="horticulturalCompanyType"
                      values={this.props.horticulturalCompanyTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* pig company subtypes */}
              {this.state.showPigCompanyTypes && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="pigCompanyType">
                      <ResourceText resourceKey="pigCompanyType" />
                    </label>
                    <Input.List
                      name="pigCompanyType"
                      values={this.props.pigCompanyTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* type ALV other subtypes */}
              {this.state.showTypeAlvOtherOptions && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="agriculturalSubtypeOther">
                      <ResourceText resourceKey="agriculturalSubtypeOther" />
                      <Ui.RequiredForPublish />
                    </label>
                    <Input.List
                      name="agriculturalSubtypeOther"
                      values={this.props.agriculturalSubtypeOthers}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* House */}
              {this.state.showHouseSort && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="houseSort">
                      <ResourceText resourceKey="houseSort" />
                    </label>
                    <Input.List
                      name="houseSort"
                      values={this.props.houseSorts}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
              {this.state.showHouseType && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="houseType">
                      <ResourceText resourceKey="houseType" />
                    </label>
                    <Input.List
                      name="houseType"
                      values={this.props.houseTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
              {this.state.showHouseCharacteristic && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="houseCharacteristic">
                      <ResourceText resourceKey="houseCharacteristic" />
                    </label>
                    <Input.List
                      name="houseCharacteristic"
                      values={this.props.houseCharacteristics}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {/* ALV listing type */}
              {this.props.objectAssignment.agriculturalSubtypeOther !==
                AgriculturalSubtypeOther.Lot && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="secondaryListingTypes">
                      <ResourceText resourceKey="secondaryListingTypes" />
                    </label>
                    <Input.Multi
                      name="secondaryListingTypes"
                      values={listingTypes}
                      asList
                      disabledValues={this.state.selectedListingType}
                    />
                  </div>
                </div>
              )}

              {/* ALV main plot destination */}
              {this.props.objectAssignment.agriculturalSubtypeOther ===
                AgriculturalSubtypeOther.Lot && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <label htmlFor="alvMainPlotDestination">
                      <ResourceText resourceKey="alvMainPlotDestination" />
                    </label>
                    <Input.List
                      name="alvMainPlotDestination"
                      values={this.props.alvBuildingPlotDestinationOptions}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const updatedObjectAssignment: ObjectAssignment = {
      ...this.props.objectAssignment,
      ...values,
    };

    this.props.objectAssignmentUpdated(updatedObjectAssignment);
  }

  private listingTypeChange(ref: RawFormControl) {
    this.setState({ selectedListingType: ref.value });
    const secondaryListingTypes = this.props.objectAssignment
      .secondaryListingTypes
      ? this.props.objectAssignment.secondaryListingTypes.filter(
          (listingType) => listingType !== ref.value
        )
      : [];

    switch (ref.value) {
      case ListingType.HorsesCompany: {
        this.setState({
          showHorseCompanySubTypes: true,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: false,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          cattleFarmingSubtype: "",
          horticulturalCompanyType: "",
          pigCompanyType: "",
          agriculturalSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
      case ListingType.CattleFarming: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: true,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: false,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          horticulturalCompanyType: "",
          pigCompanyType: "",
          agriculturalSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
      case ListingType.Horticultural: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: true,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: false,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          cattleFarmingSubtype: "",
          pigCompanyType: "",
          agriculturalSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
      case ListingType.PigHolding: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: true,
          showTypeAlvOtherOptions: false,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          cattleFarmingSubtype: "",
          horticulturalCompanyType: "",
          agriculturalSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
      case ListingType.Other: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: true,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          cattleFarmingSubtype: "",
          horticulturalCompanyType: "",
          pigCompanyType: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
      case ListingType.AgriculturalHouse: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: false,
          showHouseSort: true,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          cattleFarmingSubtype: "",
          horticulturalCompanyType: "",
          pigCompanyType: "",
          agriculturalSubtypeOther: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }

      default: {
        this.setState({
          showHorseCompanySubTypes: false,
          showCattleFarmingSubtypes: false,
          showHorticulturalCompanyTypes: false,
          showPigCompanyTypes: false,
          showTypeAlvOtherOptions: false,
          showHouseSort: false,
          showHouseType: false,
          showHouseCharacteristic: false,
        });
        return {
          horseCompanySubtype: "",
          cattleFarmingSubtype: "",
          horticulturalCompanyType: "",
          pigCompanyType: "",
          agriculturalSubtypeOther: "",
          houseSort: "",
          houseType: "",
          houseCharacteristic: "",
          secondaryListingTypes,
        };
      }
    }
  }
}
