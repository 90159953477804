import { EmailMessage } from ".";

export enum ActionType {
  AppendMessages = "EmailV2.Messages.Append",
  UpdateMessage = "EmailV2.Messages.UpdateMessage",
  DeleteMessage = "EmailV2.Messages.DeleteMessage",
  Clear = "EmailV2.Main.Clear",
  ResetApp = "RESET_APP",
}

type AppendMessages = {
  type: ActionType.AppendMessages;
  messages: EmailMessage[];
};

const appendMessages = (messages: EmailMessage[]) => ({
  type: ActionType.AppendMessages,
  messages,
});

type UpdateMessage = {
  type: ActionType.UpdateMessage;
  message: EmailMessage;
};

const updateMessage = (message: EmailMessage) => ({
  type: ActionType.UpdateMessage,
  message,
});

type DeleteMessage = {
  type: ActionType.DeleteMessage;
  id: string;
};

const deleteMessage = (id: string) => ({
  type: ActionType.DeleteMessage,
  id,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

type Clear = {
  type: ActionType.Clear;
};

export type ActionTypes =
  | AppendMessages
  | UpdateMessage
  | DeleteMessage
  | ResetApp
  | Clear;
export const actions = {
  appendMessages,
  updateMessage,
  deleteMessage,
};
