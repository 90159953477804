import { ObjectAssignment, RentCondition } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { AsyncUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ProlongRentOfferModalContainerProps } from "./prolong-rent-offer-modal.container";

const styles = require("./update-rent-offer-modal.component.scss");

export interface ProlongRentOfferModalComponentProps {
  visible: boolean;
  displayName: string;
  assignment: ObjectAssignment;
  onClose: () => void;
}
interface State {
  loading: boolean;
}
type Props = ProlongRentOfferModalComponentProps &
  ProlongRentOfferModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProlongRentOfferModalComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.state = {
      loading: false,
    };

    const date = moment();

    this.formControls = {
      rentedUntil: { value: date.add(1, "year").toDate() },
      realizedPerMonth: {
        value: get(this.props.assignment, "rentOffer.realizedPerMonth", 0),
      },
      rentCondition: {
        value: get(
          this.props.assignment,
          "rentOffer.rentCondition",
          RentCondition.PricePerMonth
        ),
      },
    };
  }

  public async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (!!this.props.visible && !nextProps.visible) {
      await AsyncUtil.wait(500);

      const date = moment();
      this.form.update({
        rentedUntil: date.add(1, "year").toDate(),
        realizedPerMonth: get(
          nextProps.assignment,
          "rentOffer.realizedPerMonth",
          0
        ),
        rentCondition: get(
          nextProps.assignment,
          "rentOffer.rentCondition",
          RentCondition.PricePerMonth
        ),
      });
    }

    if (!this.props.visible && !!nextProps.visible) {
      this.form.update({
        realizedPerMonth: get(
          nextProps.assignment,
          "rentOffer.realizedPerMonth",
          0
        ),
      });
    }
  }

  public render() {
    const disabled = this.state.loading;

    return (
      <Modal visible={this.props.visible} onClose={this.onCloseHandler}>
        <ModalHeader
          title="prolongRentOfferModalTitle"
          titleValues={{ displayName: this.props.displayName }}
          close={true}
        />
        <ModalBody>
          <Form
            name="prolong-rent-offer"
            formControls={this.formControls}
            form={(form) => (this.form = form)}
            onSubmit={this.onFormSubmit}
          >
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="pre-label">
                  <label htmlFor="rentedUntil" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.rentedUntil" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="input">
                  <Input.Datepicker name="rentedUntil" disabled={disabled} />
                </div>
              </div>
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="pre-label">
                  <label htmlFor="realizedPerMonth" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.realizedPerMonth" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="input">
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="realizedPerMonth"
                      min={0}
                      max={99999}
                      pretty={true}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="column" styleName="label">
                  <label htmlFor="rentCondition" className="pre">
                    <ResourceText resourceKey="updateRentOfferModal.rentCondition" />
                  </label>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="input">
                  <Input.NewSelect
                    name="rentCondition"
                    values={this.props.rentConditions}
                    displayProp="displayName"
                    valuesProp="value"
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.form.submit()}
            disabled={disabled}
          >
            <ButtonLoader
              resourceKey="prolongRentOfferModalSave"
              loading={this.state.loading}
            />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private onCloseHandler() {
    if (!!this.state.loading) return;
    this.props.onClose();
  }

  private async onFormSubmit(values: FormReturnValue) {
    if (!this.form || !!this.state.loading) return;
    this.setState({ loading: true });
    const { id } = this.props.assignment;

    try {
      await this.props.prolongRentOffer(
        id,
        values.rentedUntil,
        values.realizedPerMonth,
        values.rentCondition
      );
      this.props.onClose();
    } catch (error) {
      throw error;
    } finally {
      this.setState({ loading: false });
    }
  }
}
