import { REDUX } from "@haywork/constants";
import { Status } from "@haywork/stores";

import * as ActionType from "./accounts.types";

const setAccounts = (payload: ActionType.AccountsPayload) => ({
  type: REDUX.EMAILV2.SET_ACCOUNTS,
  ...payload
});

const setAccountsStatus = (payload: Status) => ({
  type: REDUX.EMAILV2.SET_ACCOUNTS_STATUS,
  ...payload
});

const setCurrentAccount = (payload: ActionType.AccountPayload) => ({
  type: REDUX.EMAILV2.SET_CURRENT_ACCOUNT,
  ...payload
});

const removeAccount = (id: string) => ({
  type: REDUX.EMAILV2.REMOVE_ACCOUNT,
  id
});

const setAccountSyncStatus = (payload: ActionType.SyncStatusPayload) => ({
  type: REDUX.EMAILV2.SET_ACCOUNT_SYNC_STATUS,
  ...payload
});

const setCurrentAccountById = (payload: ActionType.AccountIdPayload) => ({
  type: REDUX.EMAILV2.SET_CURRENT_BY_ID,
  ...payload
});

export const AccountsActions = {
  setAccounts,
  setAccountsStatus,
  setCurrentAccount,
  removeAccount,
  setAccountSyncStatus,
  setCurrentAccountById
};
