import { ProjectAssignment } from "@haywork/api/kolibri";
import { PROJECTROUTES } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ProjectEditMarketingTextContainerProps } from "@haywork/modules/project/containers/marketing-text.container";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { SingleProjectState } from "@haywork/stores";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./marketing-text.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

interface ProjectEditMarketingTextComponentProps {}
type Props = ProjectEditMarketingTextComponentProps &
  ProjectEditMarketingTextContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class ProjectEditMarketingTextComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    // Set FormControls
    const { projectAssignment } = this.props;
    this.formControls = {
      advertisementText: {
        value: value(projectAssignment, "advertisementText", "")
      },
      environmentText: {
        value: value(projectAssignment, "environmentText", "")
      },
      registrationConditions: {
        value: value(projectAssignment, "registrationConditions", "")
      },
      partiesDescriptionText: {
        value: value(projectAssignment, "partiesDescriptionText", "")
      }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.projectAssignment, "dateTimeModified") !==
        get(this.props.projectAssignment, "dateTimeModified")
    ) {
      const { projectAssignment } = this.props;

      this.formRef.update({
        advertisementText: value(projectAssignment, "advertisementText", ""),
        environmentText: value(projectAssignment, "environmentText", ""),
        registrationConditions: value(
          projectAssignment,
          "registrationConditions",
          ""
        ),
        partiesDescriptionText: value(
          projectAssignment,
          "partiesDescriptionText",
          ""
        )
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="marketing-text"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <StepperComponent initial={0} scrollToElementId="scroll-to-top">
          <StepComponent
            title="advertisementText"
            data-cy="CY-stepComponentAdvertisementText"
          >
            <Input.TranslateText
              name="advertisementText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-advertisementText"
            />
          </StepComponent>

          <StepComponent
            title="environmentText"
            data-cy="CY-stepComponentenvironmentText"
          >
            <Input.TranslateText
              name="environmentText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-environmentText"
            />
          </StepComponent>
          <StepComponent
            title="registrationConditions"
            data-cy="CY-stepComponentregistrationConditions"
          >
            <Input.TranslateText
              name="registrationConditions"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-registrationConditions"
            />
          </StepComponent>

          <StepComponent
            title="partiesDescriptionText"
            data-cy="CY-stepComponentDescriptionText"
          >
            <Input.TranslateText
              name="partiesDescriptionText"
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
              data-cy="CY-partiesDescriptionText"
            />
          </StepComponent>
        </StepperComponent>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { projectAssignment, currentComponentState } = this.props;
    const path = route(PROJECTROUTES.DETAIL.URI, { id: projectAssignment.id });

    const updatedProjectAssignment: ProjectAssignment = {
      ...projectAssignment,
      ...values
    };

    const newState: SingleProjectState = {
      ...currentComponentState,
      projectAssignment: updatedProjectAssignment
    };

    this.props.updateProject(newState, path);
  }
}
