import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import {
  FC,
  memo,
  useMemo,
  useRef,
  useContext,
  useCallback,
  useState,
} from "react";
import * as CSSModules from "react-css-modules";
import Search from "../search";
import { HeaderContainerProps } from "./header.container";
import Hint from "@haywork/components/ui/hint";
import { EmailContext } from "../../email.context";
import { FolderCategory } from "@haywork/api/mail";
import { Print } from "@haywork/services";
import { AsyncUtil } from "@haywork/util";
import FolderContext from "../folder-context";
import I18n from "@haywork/components/i18n";
import classNames from "classnames";

const styles = require("./style.scss");

export type HeaderComponentProps = {};
type Props = HeaderComponentProps & HeaderContainerProps;

export const HeaderComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      currentMessage,
      hasArchiveFolder,
      hasSpamFolder,
      currentFolder,
      onToggleUnRead,
      onToggleBookmarked,
      moveToFolder,
      archiveFolder,
      spamFolder,
      createDraftFromMessage,
      canSendEmail,
      createNewEmail,
      canPerformEditActions,
      trashFolder,
      hasTrashFolder,
      accountsAndFoldersVisible,
      toggleAccountsAndFoldersVisibility,
    }) => {
      const fetchRef = useRef<HTMLButtonElement>();
      const createRef = useRef<HTMLButtonElement>();
      const archiveRef = useRef<HTMLButtonElement>();
      const deleteRef = useRef<HTMLButtonElement>();
      const spamRef = useRef<HTMLButtonElement>();
      const replyRef = useRef<HTMLButtonElement>();
      const replyAllRef = useRef<HTMLButtonElement>();
      const forwardRef = useRef<HTMLButtonElement>();
      const bookmarkRef = useRef<HTMLButtonElement>();
      const unreadRef = useRef<HTMLButtonElement>();
      const moveRef = useRef<HTMLButtonElement>();
      const printRef = useRef<HTMLButtonElement>();
      const toggleRef = useRef<HTMLDivElement>();
      const [moveContextVisible, setMoveContextVisible] = useState(false);
      const disabled = useMemo(
        () =>
          !currentMessage ||
          currentMessage.folder?.category ===
            FolderCategory.SynchronizingMessage ||
          !canPerformEditActions,
        [currentMessage, canPerformEditActions]
      );
      const bookmarked = useMemo(
        () => !!currentMessage && !!currentMessage.bookmarked,
        [currentMessage]
      );
      const isRead = useMemo(
        () => !currentMessage || !currentMessage.unread,
        [currentMessage]
      );
      const accountId = useMemo(
        () => (!!currentMessage ? currentMessage.accountId : null),
        [currentMessage]
      );
      const { loading, getMessages, getDrafts } = useContext(EmailContext);
      const currentFolderIsDrafts =
        currentFolder?.category === FolderCategory.Drafts;

      const fetchCallback = useCallback(() => {
        if (!currentFolder || loading) return;
        const { accountId, id: folderId, category } = currentFolder;

        if (category === FolderCategory.Drafts) {
          getDrafts(0, accountId, folderId);
        } else {
          getMessages(0, accountId, folderId);
        }
      }, [getDrafts, getMessages, currentFolder, loading]);

      const toggleUnreadCallback = useCallback(() => {
        if (!currentMessage) return;
        const { id, accountId, unread } = currentMessage;

        onToggleUnRead(id, unread, accountId);
      }, [currentMessage, onToggleUnRead]);

      const toggleBookmarkedCallback = useCallback(() => {
        if (!currentMessage) return;
        const { id, accountId, bookmarked } = currentMessage;

        onToggleBookmarked(id, !bookmarked, accountId);
      }, [currentMessage, onToggleBookmarked]);

      const archiveCallback = useCallback(() => {
        if (!archiveFolder || !currentMessage) return;
        const { id } = currentMessage;
        const { id: folderId } = archiveFolder;

        moveToFolder(id, folderId);
      }, [currentMessage, moveToFolder, archiveFolder]);

      const spamCallback = useCallback(() => {
        if (!spamFolder || !currentMessage) return;
        const { id } = currentMessage;
        const { id: folderId } = spamFolder;

        moveToFolder(id, folderId);
      }, [currentMessage, moveToFolder, spamFolder]);

      const trashCallback = useCallback(() => {
        if (!trashFolder || !currentMessage) return;
        const { id } = currentMessage;
        const { id: folderId } = trashFolder;

        moveToFolder(id, folderId);
      }, [currentMessage, moveToFolder, trashFolder]);

      const printCallback = useCallback(async () => {
        if (!currentMessage) return;
        try {
          const { subject } = currentMessage;
          const print = new Print();
          const html = print.createMailTemplate(currentMessage);
          print.print(html, subject);
        } catch (error) {
          throw error;
        }
      }, [currentMessage]);

      const replyCallback = useCallback(() => {
        createDraftFromMessage(currentMessage, "reply");
      }, [currentMessage, createDraftFromMessage]);

      const replyAllCallback = useCallback(() => {
        createDraftFromMessage(currentMessage, "reply-all");
      }, [currentMessage, createDraftFromMessage]);

      const forwardCallback = useCallback(() => {
        createDraftFromMessage(currentMessage, "forward");
      }, [currentMessage, createDraftFromMessage]);

      const toggleMoveContextVisible = useCallback(() => {
        setMoveContextVisible(!moveContextVisible);
      }, [moveContextVisible, setMoveContextVisible]);

      const moveContextCloseCallback = useCallback(() => {
        setMoveContextVisible(false);
      }, [setMoveContextVisible]);

      const moveToFolderCallback = useCallback(
        (folderId: string) => {
          setMoveContextVisible(false);
          if (!currentMessage) return;
          const { id } = currentMessage;
          moveToFolder(id, folderId);
        },
        [currentMessage, setMoveContextVisible, moveToFolder]
      );

      const createCallback = useCallback(() => {
        createNewEmail();
      }, [createNewEmail]);

      const toggleAccountsAndFoldersVisibilityCallback = useCallback(() => {
        toggleAccountsAndFoldersVisibility(!accountsAndFoldersVisible);
      }, [accountsAndFoldersVisible, toggleAccountsAndFoldersVisibility]);

      return (
        <div
          styleName={classNames("header", {
            "accounts-and-folders-hidden": !accountsAndFoldersVisible,
          })}
        >
          <div styleName="section1">
            <div
              styleName={classNames("toggle", {
                active: accountsAndFoldersVisible,
              })}
              onClick={toggleAccountsAndFoldersVisibilityCallback}
              ref={toggleRef}
            >
              <Icon
                name="stream"
                color={!accountsAndFoldersVisible ? Colors.White : Colors.Gray}
                regular
                size={14}
              />
            </div>
            <Hint
              parentRef={toggleRef}
              label={
                accountsAndFoldersVisible
                  ? "email.action.hideAccountsAndFolders"
                  : "email.action.showAccountsAndFolders"
              }
            />

            <button type="button" ref={fetchRef} onClick={fetchCallback}>
              <Icon name="inbox-in" color={Colors.Gray} size={14} regular />
            </button>
            <Hint parentRef={fetchRef} label="email.action.fetch" />

            <button
              type="button"
              ref={createRef}
              disabled={!canSendEmail}
              onClick={createCallback}
              styleName="text-button"
            >
              <Icon
                name="pencil"
                color={!canSendEmail ? Colors.Gray : Colors.White}
                size={14}
                regular
              />
              &nbsp;&nbsp;
              <I18n value="email.action.create" />
            </button>
          </div>

          <div styleName="section2">
            <Search disabled={currentFolderIsDrafts} />
          </div>

          <div styleName="section3">
            <div styleName="actions">
              {!!hasArchiveFolder && (
                <>
                  <button
                    type="button"
                    disabled={disabled}
                    ref={archiveRef}
                    onClick={archiveCallback}
                  >
                    <Icon
                      name="archive"
                      color={Colors.Gray}
                      size={14}
                      regular
                    />
                  </button>
                  <Hint parentRef={archiveRef} label="email.action.archive" />
                </>
              )}

              <div styleName="button-group">
                {!!hasTrashFolder && (
                  <>
                    <button
                      type="button"
                      disabled={
                        disabled ||
                        currentFolder?.category === FolderCategory.Trash ||
                        trashFolder?.id === currentFolder?.id
                      }
                      ref={deleteRef}
                      onClick={trashCallback}
                    >
                      <Icon
                        name="trash-alt"
                        color={Colors.Gray}
                        size={14}
                        regular
                      />
                    </button>
                    <Hint parentRef={deleteRef} label="email.action.delete" />
                  </>
                )}

                {!!hasSpamFolder && (
                  <>
                    <button
                      type="button"
                      disabled={disabled}
                      ref={spamRef}
                      onClick={spamCallback}
                    >
                      <Icon name="ban" color={Colors.Gray} size={14} regular />
                    </button>
                    <Hint parentRef={spamRef} label="email.action.spam" />
                  </>
                )}
              </div>

              <div styleName="button-group">
                <button
                  type="button"
                  disabled={disabled}
                  ref={replyRef}
                  onClick={replyCallback}
                >
                  <Icon name="reply" color={Colors.Gray} size={14} regular />
                </button>
                <Hint parentRef={replyRef} label="email.action.reply" />

                <button
                  type="button"
                  disabled={disabled}
                  ref={replyAllRef}
                  onClick={replyAllCallback}
                >
                  <Icon
                    name="reply-all"
                    color={Colors.Gray}
                    size={14}
                    regular
                  />
                </button>
                <Hint parentRef={replyAllRef} label="email.action.replyAll" />

                <button
                  type="button"
                  disabled={disabled}
                  ref={forwardRef}
                  onClick={forwardCallback}
                >
                  <Icon name="share" color={Colors.Gray} size={14} regular />
                </button>
                <Hint parentRef={forwardRef} label="email.action.forward" />
              </div>

              <div styleName="button-group">
                <button
                  type="button"
                  disabled={disabled}
                  ref={bookmarkRef}
                  onClick={toggleBookmarkedCallback}
                >
                  <Icon
                    name="pennant"
                    color={bookmarked ? Colors.Danger : Colors.Gray}
                    size={14}
                    regular={!bookmarked}
                    solid={bookmarked}
                  />
                </button>
                <Hint parentRef={bookmarkRef} label="email.action.bookmark" />

                <button
                  type="button"
                  disabled={disabled}
                  ref={unreadRef}
                  onClick={toggleUnreadCallback}
                >
                  <Icon
                    name={isRead ? "envelope-open" : "envelope"}
                    color={Colors.Gray}
                    size={14}
                    regular
                  />
                </button>
                <Hint
                  parentRef={unreadRef}
                  label={isRead ? "email.action.unread" : "email.action.read"}
                />
              </div>

              <button
                type="button"
                disabled={disabled}
                ref={moveRef}
                onClick={toggleMoveContextVisible}
              >
                <Icon
                  name="folder-open"
                  color={Colors.Gray}
                  size={14}
                  regular
                />
              </button>
              <Hint parentRef={moveRef} label="email.action.move" />
              <FolderContext
                parent={moveRef}
                visible={moveContextVisible}
                accountId={accountId}
                onClose={moveContextCloseCallback}
                onFolderSelect={moveToFolderCallback}
              />

              <button
                type="button"
                disabled={disabled}
                ref={printRef}
                onClick={printCallback}
              >
                <Icon name="print" color={Colors.Gray} size={14} regular />
              </button>
              <Hint parentRef={printRef} label="email.action.print" />
            </div>
          </div>
        </div>
      );
    }
  )
);
