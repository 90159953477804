import { REDUX } from "@haywork/constants";
import { EventNotificationsFilter } from "@haywork/util";

import * as ActionType from "./notifications.types";
import { LinkedEmployee } from "@haywork/api/event-center";

const setFilter = (currentFilter: EventNotificationsFilter) => ({
  type: REDUX.EVENTCENTER.SET_NOTIFICATIONS_FILTER,
  currentFilter
});

const setUnreadCount = (
  numberOfPersonalUnprocessedNotifications: number,
  numberOfGeneralUnprocessedNotifications: number
) => ({
  type: REDUX.EVENTCENTER.SET_UNREAD_COUNT,
  numberOfPersonalUnprocessedNotifications,
  numberOfGeneralUnprocessedNotifications
});

const setNotifications = (payload: ActionType.Notifications) => ({
  type: REDUX.EVENTCENTER.SET_NOTIFCATIONS,
  ...payload
});

const setNotificationsStatus = (notificationsStatus: string) => ({
  type: REDUX.EVENTCENTER.SET_NOTIFCATIONS_STATUS,
  notificationsStatus
});

const updateNotification = (payload: ActionType.SingleNotification) => ({
  type: REDUX.EVENTCENTER.UPDATE_NOTIFICATION,
  ...payload
});

const toggleAllIsRead = () => ({
  type: REDUX.EVENTCENTER.TOGGLE_ALL_IS_READ
});

const processAll = (archivedBy: LinkedEmployee) => ({
  type: REDUX.EVENTCENTER.PROCESS_ALL,
  archivedBy
});

const setForceReload = (payload: ActionType.ForceReload) => ({
  type: REDUX.EVENTCENTER.FORCE_RELOAD,
  ...payload
});

export const NotificationsActions = {
  setFilter,
  setUnreadCount,
  setNotifications,
  setNotificationsStatus,
  updateNotification,
  toggleAllIsRead,
  processAll,
  setForceReload
};
