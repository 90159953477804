export class String {
  public static truncate(
    value: string,
    max: number = 64,
    show: number = 8,
    delimiter: string = "..."
  ): string {
    const length = value.length;
    if (length > max) {
      const start = value.slice(0, show);
      const end = value.slice(-show);
      return `${start}${delimiter}${end}`;
    }
    return value;
  }

  public static trim(
    value: string,
    max: number = 128,
    delimiter: string = "..."
  ): string {
    if (!value || value.length <= max) return value;

    let trimmed = value.substring(0, max);
    const last = trimmed.substring(trimmed.length - 1);
    const match = /[\.\?!\s]/gi;

    if ([".", "!", "?", " "].indexOf(last) !== -1) {
      trimmed = trimmed.substring(0, trimmed.length - 1);
    }

    return `${trimmed}${delimiter}`;
  }

  public static enumToString(value: any): string {
    if (!value) return null;
    return value.toString();
  }

  public static highlight(
    value: string,
    highlight: string
  ): { __html: string } {
    if (!value || !highlight) return { __html: `<span>${value}</span>` };

    const lowered = value.toLowerCase();
    const idx = this.matches(value, highlight);
    const hightlightLength = highlight.length;

    if (idx >= 0)
      return {
        __html: `${value.substring(
          0,
          idx
        )}<span class="highlight">${value.substring(
          idx,
          idx + hightlightLength
        )}</span>${value.substring(idx + hightlightLength)}`
      };
    return { __html: value };
  }

  public static matches(value: string, highlight: string): number {
    if (value === undefined || value === null) return;
    if (!highlight) return;

    const lowered = value.toLowerCase();
    const idx = lowered.indexOf(highlight.toLowerCase());

    return idx;
  }

  public static prependZero(value: number, minLength: number = 2): string {
    const raw = value.toString();
    const length = raw.length;
    let leading = "";

    while (leading.length < minLength - length) {
      leading = 0 + leading;
    }

    return leading + raw;
  }

  public static capitalize(value: string): string {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public static stripHTML(value: string): string {
    const div = document.createElement("div");
    div.innerHTML = value;

    const text = div.textContent || div.innerText || "";

    return text.replace(/&nbsp;/gi, " ");
  }
}
