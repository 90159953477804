import * as React from "react";
import { FC, memo, useState, useCallback, useEffect } from "react";
import * as CSSModules from "react-css-modules";
import { SelectContainerProps } from "./select.container";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import Button from "@haywork/components/ui/button";
import { Input } from "@haywork/modules/form";
import {
  GlobalSearchType,
  AssignmentType,
  RelationType,
  FolderTree,
  FolderTreeFileEntity,
  LinkedRelation,
  LinkedAssignment
} from "@haywork/api/kolibri";
import { GlobalSearchResponseItem } from "@haywork/request";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import { Ui } from "@haywork/modules/ui";
import Folder from "../folder";
import get from "lodash-es/get";
import uniqBy from "lodash-es/uniqBy";

const styles = require("./style.scss");

type SuggestedDossierItem = {
  displayName: string;
  id: string;
  type: GlobalSearchType;
  typeOfAssignment?: AssignmentType;
  typeOfRelation?: RelationType;
};
export type SelectComponentProps = {
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  onSetFiles: (files: FolderTreeFileEntity[]) => void;
};
type Props = SelectComponentProps & SelectContainerProps;

export const SelectComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ readFolderTree, linkedRelations, linkedAssignments, onSetFiles }) => {
      const [entityId, setEntityId] = useState<string | null | undefined>(null);
      const [loading, setLoading] = useState(false);
      const [suggestions, setSuggestions] = useState<SuggestedDossierItem[]>(
        []
      );
      const [folderTree, setFolderTree] = useState<FolderTree | null>(null);
      const [selectedFiles, setSelectedFiles] = useState<
        FolderTreeFileEntity[]
      >([]);

      const setExternalSuggestions = useCallback(
        (
          relations: LinkedRelation[] = [],
          assignments: LinkedAssignment[] = []
        ) => {
          const newRelations = relations.map(
            (relation) =>
              ({
                displayName: relation.displayName,
                id: relation.id,
                type: GlobalSearchType.Relations,
                typeOfRelation: relation.typeOfRelation
              } as SuggestedDossierItem)
          );

          const newAssignments = assignments.map(
            (assignment) =>
              ({
                displayName: assignment.displayName,
                id: assignment.id,
                type: GlobalSearchType.Assignments,
                typeOfAssignment: assignment.typeOfAssignment
              } as SuggestedDossierItem)
          );

          setSuggestions(
            uniqBy([...newRelations, ...newAssignments], (s) => s.id)
          );
        },
        [suggestions, setSuggestions]
      );

      useEffect(() => {
        setExternalSuggestions(linkedRelations, linkedAssignments);
      }, [linkedRelations, linkedAssignments]);

      const onSearchChange = useCallback(
        (value: GlobalSearchResponseItem | undefined) => {
          if (!value) return;
          const ids = suggestions.map((suggestion) => suggestion.id);
          const { type, entityId: id, assignment, relation } = value;

          if (!ids.includes(id)) {
            let displayName = "";
            let typeOfRelation: RelationType;
            let typeOfAssignment: AssignmentType;
            switch (type) {
              case GlobalSearchType.Assignments: {
                if (!assignment || !assignment.typeOfAssignment) break;
                typeOfAssignment = assignment.typeOfAssignment;
                const { displayName: name, publicReference } = assignment;
                displayName =
                  typeOfAssignment === AssignmentType.Acquisition
                    ? publicReference || ""
                    : name || "";
                break;
              }
              case GlobalSearchType.Relations: {
                if (!relation || !relation.typeOfRelation) break;
                typeOfRelation = relation.typeOfRelation;
                displayName = relation.displayName || "";
              }
              default:
                break;
            }

            setSuggestions([
              { displayName, id, type, typeOfRelation, typeOfAssignment },
              ...suggestions
            ]);
          }
          setEntityId(id);
        },
        [setEntityId, suggestions, setSuggestions]
      );

      const onSelectEntityId = useCallback(
        (id: string) => {
          if (loading) return;
          setEntityId(id);
        },
        [loading, setEntityId]
      );

      const fetchFolderTrees = useCallback(
        async (entityId: string | undefined) => {
          if (!entityId || loading) return;

          const ref = suggestions.find(
            (suggestion) => suggestion.id === entityId
          );
          if (!ref) return;

          try {
            setSelectedFiles([]);
            setFolderTree(null);
            setLoading(true);
            const { id, type, typeOfRelation, typeOfAssignment } = ref;
            const folderTree = await readFolderTree(
              id,
              type,
              typeOfRelation,
              typeOfAssignment
            );
            setFolderTree(folderTree);
          } catch (_) {
            setFolderTree(null);
          } finally {
            setLoading(false);
          }
        },
        [loading, setLoading, suggestions]
      );

      const onToggleSelect = useCallback(
        (file: FolderTreeFileEntity) => {
          const ids = selectedFiles.map((f) => f.id);
          if (ids.includes(file.id)) {
            setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
          } else {
            setSelectedFiles([...selectedFiles, file]);
          }
        },
        [selectedFiles, setSelectedFiles]
      );

      const handleSetFiles = useCallback(() => {
        if (loading || !selectedFiles.length) return;
        onSetFiles(selectedFiles);
      }, [loading, selectedFiles, onSetFiles]);

      useEffect(() => {
        fetchFolderTrees(entityId);
      }, [entityId]);

      return (
        <>
          <ModalBody noPadding>
            <div styleName="form">
              <div className="form__row">
                <label htmlFor="folderTree.search">
                  <I18n value="dossier.selectedFilesModal.folderTree.searchLabel" />
                </label>
                <Input.GlobalSearchQuery
                  name="folderTree.search"
                  asSingleInput
                  filterByTypes={[
                    GlobalSearchType.Assignments,
                    GlobalSearchType.Relations
                  ]}
                  takePerType={5}
                  onChange={onSearchChange}
                  placeholder="dossier.selectedFilesModal.searchPlaceholder"
                  disabled={loading}
                />
              </div>
              <div className="form__row">
                {suggestions.map((suggestion) => (
                  <Pill
                    label={suggestion.displayName}
                    key={suggestion.id}
                    color={loading ? Colors.Gray : Colors.Primary}
                    solid={entityId === suggestion.id}
                    onClick={() => onSelectEntityId(suggestion.id)}
                  />
                ))}
              </div>

              {!!loading && (
                <div className="form__row">
                  <Ui.Loaders.List />
                </div>
              )}

              {!folderTree && !loading && !!entityId && (
                <div className="form__row" styleName="empty-state">
                  <I18n value="dossier.selectedFilesModal.suggestions.emptyState" />
                </div>
              )}
            </div>

            {!!folderTree && (
              <div styleName="folders">
                <Folder
                  folder={get(folderTree.rootFolderTree, "documentSessions")}
                  depth={0}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                />
                <Folder
                  folder={get(folderTree.rootFolderTree, "invoices")}
                  depth={0}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                />
                <Folder
                  folder={get(folderTree.rootFolderTree, "dossierItems")}
                  depth={0}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                />
              </div>
            )}
          </ModalBody>
          <ModalFooter style={!folderTree ? null : "no-border"}>
            <Button
              label="dossier.selectedFilesModal.action.next"
              category="primary"
              disabled={loading || !selectedFiles.length}
              onClick={handleSetFiles}
            />
          </ModalFooter>
        </>
      );
    }
  )
);
