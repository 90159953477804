import {
  CadastralLotSizeOption,
  Cadastre,
  FixedVariableOption,
  GroundLeasePeriodOption,
  LeaseholdOwnerTypeOption,
  ObjectAssignment,
  OwnershipOption,
  OwnershipTypeOption,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import {
  AssignmentCadastreComponent,
  AssignmentCadastreComponentProps,
} from "@haywork/modules/assignment";
import {
  AppState,
  AssignmentSingleActions,
  EditableActions,
  SingleAssignmentState,
} from "@haywork/stores";
import { AssignmentUtil } from "@haywork/util";
import { connect, MapStateToProps } from "react-redux";
import { validForCadastreFetch } from "./selectors";

interface StateProps {
  ownershipTypeOptions: OwnershipTypeOption[];
  groundLeasePeriodOptions: GroundLeasePeriodOption[];
  cadastralLotSizeOptions: CadastralLotSizeOption[];
  fixedVariableOptions: FixedVariableOption[];
  cadastres: Cadastre[];
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  cadastreState: string;
  saveAssignmentState: string;
  realEstateAgencyId: string;
  host: string;
  idToken: string;
  ownershipOptions: OwnershipOption[];
  canSave: boolean;
  leaseholdOwnerTypeOptions: LeaseholdOwnerTypeOption[];
  validForCadastreFetch: boolean;
}
interface DispatchProps {
  getCadastre: (
    countryIso2: string,
    houseNumber: number,
    houseNumberPostfix: string,
    postalCode: string
  ) => void;
  updateComponentState: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
  createCadastre: (id: string) => void;
  getExtraCadastre: (
    countryIso2: string,
    municipalityId: string,
    municipalityName: string,
    parcelNumber: string,
    sectionNumber: string
  ) => void;
  toggleSaveModal: (status: boolean) => void;
}

const filterCadastralLotSizeOptions = (
  options: CadastralLotSizeOption[],
  realEstateGroup: RealEstateGroup
): CadastralLotSizeOption[] => {
  return options.filter(
    (option) => option.realEstateGroups.indexOf(realEstateGroup) !== -1
  );
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AssignmentCadastreComponentProps,
  AppState
> = (state) => {
  const {
    ownershipTypeOptions,
    groundLeasePeriodOptions,
    cadastralLotSizeOptions,
    fixedVariableOptions,
    ownershipOptions,
    leaseholdOwnerTypeOptions,
  } = state.main.mastertable.kolibri;
  const { cadastres, objectAssignment } = state.editable.currentComponentState;
  const { cadastreState, saveAssignmentState } = state.assignment.single;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { host } = state.appSettings;
  const { token } = state.access;

  return {
    ownershipTypeOptions,
    groundLeasePeriodOptions,
    cadastralLotSizeOptions: filterCadastralLotSizeOptions(
      cadastralLotSizeOptions,
      objectAssignment.realEstateGroup
    ),
    fixedVariableOptions,
    cadastres,
    objectAssignment,
    currentComponentState: state.editable.currentComponentState,
    cadastreState,
    saveAssignmentState,
    realEstateAgencyId,
    host,
    idToken: token,
    ownershipOptions,
    canSave: AssignmentUtil.validForSave(objectAssignment),
    leaseholdOwnerTypeOptions,
    validForCadastreFetch: validForCadastreFetch(objectAssignment),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AssignmentCadastreComponentProps
> = (dispatch) => ({
  getCadastre: (
    countryIso2: string,
    houseNumber: number,
    houseNumberPostfix: string,
    postalCode: string
  ) =>
    dispatch(
      AssignmentThunks.getCadastre(
        countryIso2,
        houseNumber,
        houseNumberPostfix,
        postalCode
      )
    ),
  getExtraCadastre: (
    countryIso2: string,
    municipalityId: string,
    municipalityName: string,
    parcelNumber: string,
    sectionNumber: string
  ) =>
    dispatch(
      AssignmentThunks.getExtraCadastre(
        countryIso2,
        municipalityId,
        municipalityName,
        parcelNumber,
        sectionNumber
      )
    ),
  updateComponentState: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  createCadastre: (id: string) => dispatch(AssignmentThunks.createCadastre(id)),
  toggleSaveModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleSaveModal(status)),
});

export type AssignmentCadastreContainerProps = StateProps & DispatchProps;
export const AssignmentCadastreContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentCadastreComponent);
