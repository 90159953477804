import { EventNotification } from "@haywork/api/event-center";
import {
  AgendaItemCategorySnapShot,
  ReminderMinutesOption,
  TaskCategoryOption,
} from "@haywork/api/kolibri";
import { Dispatch, ReminderThunks } from "@haywork/middleware";
import {
  ReminderModalComponent,
  ReminderModalComponentProps,
} from "@haywork/modules/reminder";
import { AppState, RemindersActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  reminders: EventNotification[];
  showReminders: boolean;
  reminderMinutesOptions: ReminderMinutesOption[];
  culture: string;
  taskCategories: TaskCategoryOption[];
  agendaItemCategories: AgendaItemCategorySnapShot[];
}
interface DispatchProps {
  deleteAllReminders: () => void;
  deleteReminder: (id: string) => void;
  rescheduleAllReminders: (minutes: number) => void;
  clearAllReminders: () => void;
  navigate: (route: string) => void;
}

const filterReminderMinuteOptions = (
  options: ReminderMinutesOption[]
): ReminderMinutesOption[] => {
  const filteredTypeOptions = options.filter(
    (reminderMinuteType) => reminderMinuteType.nrOfMinutes !== 0
  );

  return filteredTypeOptions;
};

const mapStateToProps = <StateProps, ReminderModalComponentProps>(
  state: AppState
) => ({
  reminders: state.reminders.reminders,
  showReminders: state.reminders.remindersTotal > 0,
  reminderMinutesOptions: filterReminderMinuteOptions(
    state.main.mastertable.kolibri.reminderMinutesOptions
  ),
  culture: state.main.culture,
  taskCategories: state.main.mastertable.kolibri.taskCategories,
  agendaItemCategories: state.scheduler.agendaItemCategories,
});

const mapDispatchToProps = <DispatchProps, ReminderModalComponentProps>(
  dispatch: Dispatch<any>
) => ({
  deleteAllReminders: () => dispatch(ReminderThunks.deleteAllReminders()),
  deleteReminder: (id: string) => dispatch(ReminderThunks.deleteReminder(id)),
  rescheduleAllReminders: (minutes: number) =>
    dispatch(ReminderThunks.rescheduleAllReminders(minutes)),
  clearAllReminders: () => dispatch(RemindersActions.clearReminders()),
  navigate: (route: string) => dispatch(push(route)),
});

export type ReminderModalContainerProps = StateProps & DispatchProps;
export const ReminderModalContainer = connect<
  StateProps,
  DispatchProps,
  ReminderModalComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ReminderModalComponent);
