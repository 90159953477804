import { AssignmentPhase, SearchAssignment } from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import * as React from "react";
import { FC, memo, useRef, useState, useMemo } from "react";

export enum SearchAssignmentAction {
  Activate = "Activate",
  Cancel = "Cancel",
  Publish = "Publish",
  Archive = "Archive",
  UnArchive = "UnArchive",
  Remove = "Remove",
  Email = "Email",
  ClearSelection = "ClearSelection",
}

type Props = {
  loading: boolean;
  searchAssignment: SearchAssignment;
  hasSelectedEmailAssignments: boolean;
  canSendEmail: boolean;
  onClick: (action: SearchAssignmentAction) => void;
};

const SearchAssignmentActionsComponent: FC<Props> = memo(
  ({
    loading,
    searchAssignment,
    hasSelectedEmailAssignments,
    canSendEmail,
    onClick,
  }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const { assignmentPhase, isActive } = searchAssignment;

    const showActivateAndArchive = useMemo(
      () => !!isActive && assignmentPhase === AssignmentPhase.Completed,
      [isActive, assignmentPhase]
    );

    const showCancel = useMemo(
      () => !!isActive && assignmentPhase === AssignmentPhase.Initiated,
      [isActive, assignmentPhase]
    );

    const showPublish = useMemo(
      () => !!isActive && assignmentPhase === AssignmentPhase.Concept,
      [isActive, assignmentPhase]
    );

    const onClickHandler = (action: SearchAssignmentAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon name="chevron-down" light size={16} color={Colors.White} />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={loading}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          {showActivateAndArchive && (
            <Action
              label="searchAssignment.actions.activate"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Activate)}
            />
          )}
          {showCancel && (
            <Action
              label="searchAssignment.actions.cancel"
              icon={
                <Icon
                  light
                  name="ban"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Cancel)}
            />
          )}
          {showPublish && (
            <Action
              label="searchAssignment.actions.publish"
              icon={
                <Icon
                  light
                  name="globe"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Publish)}
            />
          )}
          {showActivateAndArchive && (
            <Action
              label="searchAssignment.actions.archive"
              icon={
                <Icon
                  light
                  name="archive"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Archive)}
            />
          )}
          {!isActive && (
            <Action
              label="searchAssignment.actions.unArchive"
              icon={
                <Icon
                  light
                  name="arrow-from-bottom"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.UnArchive)}
            />
          )}

          {!isActive && (
            <Action
              label="searchAssignment.actions.remove"
              icon={
                <Icon
                  light
                  name="trash-alt"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Remove)}
            />
          )}

          {!!hasSelectedEmailAssignments && canSendEmail && (
            <Action
              label="searchAssignment.actions.email"
              icon={
                <Icon
                  light
                  name="envelope"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(SearchAssignmentAction.Email)}
            />
          )}

          {!!hasSelectedEmailAssignments && (
            <Action
              label="searchAssignment.actions.clearSelection"
              icon={
                <Icon
                  light
                  name="eraser"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(SearchAssignmentAction.ClearSelection)
              }
            />
          )}
        </ActionList>
      </>
    );
  }
);

export default SearchAssignmentActionsComponent;
