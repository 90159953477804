import { Account, Provider, ProviderType } from "@haywork/api/mail";
import { EXTERNALROUTES, KEYCODE, REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./create-modal.component.scss");

interface Props {
  provider: Provider;
  accountName?: string;
  createStatus: string;
  onBack: () => void;
  onSubmit: (values: FormReturnValue) => Promise<string | Account>;
  onDone: () => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AccountDataSimpleComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);

    this.formControls = {
      provider: { value: this.props.provider.type },
      accountName: { value: this.props.accountName || "" },
      emailAddress: {
        value: "",
        validators: [Validators.required(), Validators.email()],
      },
      password: {
        value: "",
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    const disabled = this.props.createStatus === REQUEST.PENDING;

    return (
      <div styleName="simple-data">
        {this.props.provider.type === ProviderType.Yahoo && (
          <div styleName="provider-warning">
            <ResourceText
              resourceKey="emailProviderYahooWarning"
              values={{ url: EXTERNALROUTES.EMAIL_PROVIDER_YAHOO_HELP.URI }}
              asHtml
            />
          </div>
        )}
        {this.props.provider.type === ProviderType.Icloud && (
          <div styleName="provider-warning">
            <ResourceText
              resourceKey="emailProviderIcloudWarning"
              values={{ url: EXTERNALROUTES.EMAIL_PROVIDER_ICLOUD_HELP.URI }}
              asHtml
            />
          </div>
        )}
        <div styleName="simple-data__body">
          <div
            styleName="avatar"
            style={{
              backgroundImage: `url(${JSON.stringify(
                this.props.provider.logoUrl
              )})`,
            }}
          />
          <h1>
            <ResourceText resourceKey="simpleDataEmailTitle" />
          </h1>
          <Form
            name="simple-account"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.onSubmitHandler}
          >
            <div styleName="centered-row">
              <label htmlFor="accountName">
                <ResourceText resourceKey="emailAccountName" />
              </label>
              <Input.Text
                name="accountName"
                placeholder="emailAccountNamePlaceholder"
                disabled={disabled}
              />
            </div>

            <div styleName="centered-row">
              <label htmlFor="emailAddress">
                <ResourceText resourceKey="emailAccountEmailAddress" />
              </label>
              <Input.Email
                name="emailAddress"
                data-cy="CY-emailAddressInput"
                placeholder="emailAccountEmailAddressPlaceholder"
                disabled={disabled}
              />
            </div>

            <div styleName="centered-row">
              <label htmlFor="password">
                <ResourceText resourceKey="emailAccountPassword" />
              </label>
              <Input.Password
                name="password"
                data-cy="CY-passwordInput"
                placeholder="emailAccountPasswordPlaceholder"
                canToggle
                disabled={disabled}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </Form>
        </div>
        <div styleName="footer">
          <div styleName="left">
            <button
              type="button"
              className="btn btn-gray"
              onClick={this.props.onBack}
              disabled={disabled}
            >
              <ResourceText resourceKey="back" />
            </button>
          </div>
          <div styleName="right">
            <button
              type="button"
              className="btn btn-primary"
              data-cy="CY-submitButton"
              onClick={this.onSubmitClickHandler}
            >
              <ButtonLoader resourceKey="bindMailBox" loading={disabled} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitClickHandler() {
    this.formRef.submit();
  }

  private async onSubmitHandler(values: FormReturnValue) {
    await this.props.onSubmit(values);
    this.props.onDone();
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const { keyCode } = event;
    if (keyCode === KEYCODE.ENTER) {
      this.onSubmitClickHandler();
    }
  }
}
