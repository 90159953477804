import { SaveFolderRequest } from "@haywork/api/mail";
import { KEYCODE } from "@haywork/constants";
import {
  Form,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { EmailUtil, FormControlUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./accounts.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  depth: number;
  folder: ExtendedEmailFolder;
  selected: boolean;
  accountId: string;
  onClick: () => void;
  onUpdate: (request: SaveFolderRequest) => Promise<void>;
  checkIfFolderEligableForDelete: (folderId: string) => Promise<boolean>;
  onRemoveFolder: (folder: ExtendedEmailFolder) => void;
}
interface State {
  actionsVisible: boolean;
  formVisible: boolean;
  loading: boolean;
  deleteVisible: boolean;
  deleteCountdown: number;
}

@CSSModules(styles, { allowMultiple: true })
export class EditableTitle extends React.Component<Props, State> {
  private ref: HTMLDivElement;
  private deleteInterval: any;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      actionsVisible: false,
      formVisible: false,
      loading: false,
      deleteVisible: false,
      deleteCountdown: 5,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onDocumentKeyDownHandler = this.onDocumentKeyDownHandler.bind(this);
    this.onUnDeleteHandler = this.onUnDeleteHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.onToggleHandler = this.onToggleHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
    document.addEventListener("keydown", this.onDocumentKeyDownHandler, true);
  }

  public render() {
    const { selected, depth, folder } = this.props;
    const { category, displayName } = folder;
    const depthStyle = depth < 4 ? `depth${depth}` : "depth-deep";

    const wrapperStyle = classNames("editable-title__wrapper", depthStyle, {
      "actions-visible": this.state.actionsVisible,
      "delete-visible": this.state.deleteVisible,
      active: selected,
    });
    const titleStyle = classNames("folder__title", { active: selected });
    const folderIconStyle = classNames("folder__icon", depthStyle);
    const iconStyle = classNames("fal", EmailUtil.getFolderIcon(category));

    const formControls = {
      id: { value: this.props.folder.id },
      accountId: { value: this.props.accountId },
      parentId: { value: value(this.props.folder, "parentId") },
      displayName: {
        value: this.props.folder.displayName,
        validators: [Validators.required()],
      },
    };

    return (
      <div ref={this.bindRef} styleName={wrapperStyle}>
        <div
          styleName="delete"
          onClick={this.onUnDeleteHandler}
          data-cy="CY-emailFolderUndelete"
        >
          <div styleName="delete__text">
            <ResourceText resourceKey="undoDelete" /> (
            {this.state.deleteCountdown})
          </div>
        </div>
        <div styleName="inner">
          {!this.state.formVisible && (
            <React.Fragment>
              <div
                styleName={titleStyle}
                onClick={this.props.onClick}
                data-cy="CY-emailFolder"
              >
                <div styleName={folderIconStyle}>
                  <i className={iconStyle} />
                </div>
                <div styleName="folder__name">{displayName}</div>
              </div>

              <div
                styleName="editable-title__toggle"
                data-cy="CY-emailFolderToggle"
                onClick={this.onToggleHandler}
              >
                <i className="fal fa-ellipsis-v" />
              </div>
            </React.Fragment>
          )}
          {this.state.formVisible && (
            <Form
              name="update-folder"
              formControls={formControls}
              onSubmit={this.onSubmitHandler}
              form={(form) => (this.formRef = form)}
            >
              <div styleName="update-folder__form">
                <Input.Text
                  name="displayName"
                  data-cy="CY-editFolderInput"
                  placeholder="emailFolderDisplaynamePlaceholder"
                  disabled={this.state.loading}
                />
                <div styleName="divider" />
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-cy="CY-emailFolderEditSubmitButton"
                  disabled={this.state.loading}
                >
                  <i className="fal fa-check" />
                </button>
              </div>
            </Form>
          )}
        </div>

        <div styleName="actions">
          <div
            className="btn btn-danger"
            data-cy="CY-emailFolderDeleteAction"
            onClick={this.onDeleteClickHandler}
          >
            <i className="fal fa-times" />
          </div>
          <div
            className="btn btn-success"
            data-cy="CY-emailFolderEditAction"
            onClick={this.onEditClickHandler}
          >
            <i className="fal fa-pencil" />
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
    document.removeEventListener(
      "keydown",
      this.onDocumentKeyDownHandler,
      true
    );
  }

  private onToggleHandler() {
    this.setState({
      actionsVisible: !this.state.actionsVisible,
    });
  }

  private onEditClickHandler() {
    this.setState({
      actionsVisible: false,
      formVisible: true,
    });
  }

  private onDeleteClickHandler() {
    this.setState({
      deleteVisible: true,
      deleteCountdown: 5,
      actionsVisible: false,
    });

    this.props
      .checkIfFolderEligableForDelete(this.props.folder.id)
      .then((canDelete) => {
        if (!canDelete) {
          this.onUnDeleteHandler();
        }
      })
      .catch(this.onUnDeleteHandler);

    this.deleteInterval = setInterval(() => {
      let { deleteCountdown } = this.state;
      deleteCountdown = deleteCountdown - 1;

      this.setState({
        deleteCountdown,
      });

      if (deleteCountdown === 0) {
        clearInterval(this.deleteInterval);
        this.setState({ deleteVisible: false });
        this.props.onRemoveFolder(this.props.folder);
      }
    }, 1000);
  }

  private onUnDeleteHandler() {
    this.setState({
      deleteVisible: false,
    });

    clearInterval(this.deleteInterval);
  }

  private onDocumentKeyDownHandler(event: KeyboardEvent) {
    if (!this.state.formVisible) return;
    if (event.keyCode === KEYCODE.ESCAPE) {
      this.setState({
        formVisible: false,
      });
    }
  }

  private onClickOutsideHandler(event: any) {
    const clickedInside = this.ref.contains(event.target);
    if (!clickedInside && !!this.state.actionsVisible)
      this.setState({ actionsVisible: false });
  }

  private async onSubmitHandler(values: FormReturnValue) {
    if (this.state.loading) return;

    if (this.props.folder.displayName === values.displayName) {
      return this.setState({
        formVisible: false,
      });
    }

    this.setState({
      loading: true,
    });

    try {
      await this.props.onUpdate({
        accountId: values.accountId,
        displayName: values.displayName,
        id: values.id,
        parentId: values.parentId,
      });

      this.setState({
        formVisible: false,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
    }
  }
}
