import { Provider } from "@haywork/api/mail";

export enum ActionType {
  SetProviders = "EmailV2.Providers.SetProviders",
  ResetApp = "RESET_APP"
}

type SetProviders = {
  type: ActionType.SetProviders;
  providers: Provider[];
};

const setProviders = (providers: Provider[]) => ({
  type: ActionType.SetProviders,
  providers
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes = SetProviders | ResetApp;
export const actions = {
  setProviders
};
