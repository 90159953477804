import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import some from "lodash-es/some";

export interface ValidatedObjectTypesSubRoutes {
  type: boolean;
  general: boolean;
  specifications: boolean;
  marketing: boolean;
}

export class ObjectTypes {
  public static validateSubRoutes(
    projectType: ObjectTypeAssignment
  ): ValidatedObjectTypesSubRoutes {
    const {
      titleText,
      typePART,
      usableArea,
      livingRoomSurface,
      parcelSurface,
      contents,
      amountUnits,
      amountFreeUnits,
      buildingTypeStart,
      deliveryStart,
      saleStart,
      saleOffer,
      rentOffer,
      floors,
      garages,
      gardens,
      photos,
      videos,
      advertisementText,
      environmentText,
      registrationConditions,
      partiesDescriptionText,
      maps,
      numberOfFloors,
      insulationTypes,
      heating,
      warmWaterTypes,
      boilerYearOfConstruction,
      boilerType,
      hasCombiBoiler,
      boilerFuel,
      boilerProperty,
      comfortQuality,
      roof,
      residentialFacilities,
      parkingFacilities,
      currentDestination,
      currentUsage,
      isRecreational,
      inPark,
      isBuildingReady
    } = projectType;

    const valid: ValidatedObjectTypesSubRoutes = {
      type: false,
      general: false,
      specifications: false,
      marketing: false
    };

    if (isArray(titleText) && !!titleText.length && !!typePART) {
      valid.type = true;
    }

    const generalSizeKeys = ["minArea", "maxArea", "minVolume", "maxVolume"];
    const generalSizeFields = [
      usableArea,
      livingRoomSurface,
      parcelSurface,
      contents
    ];
    const generalAmountKeys = [
      "salePrice",
      "salePriceMax",
      "rentPrice",
      "rentPriceMax"
    ];
    const generalAmountFields = [saleOffer, rentOffer];
    if (
      amountUnits ||
      amountFreeUnits ||
      buildingTypeStart ||
      deliveryStart ||
      saleStart ||
      some(generalSizeFields, (value) =>
        some(value, (v, k) => generalSizeKeys.indexOf(k) !== -1 && !!v)
      ) ||
      some(generalAmountFields, (value) =>
        some(value, (v, k) => generalAmountKeys.indexOf(k) !== -1 && !!v)
      )
    ) {
      valid.general = true;
    }

    if (
      !!numberOfFloors ||
      (isArray(insulationTypes) && !!insulationTypes.length) ||
      (isArray(heating) && !!heating.length) ||
      (isArray(warmWaterTypes) && !!warmWaterTypes.length) ||
      (isArray(residentialFacilities) && !!residentialFacilities.length) ||
      (isArray(parkingFacilities) && !!parkingFacilities.length) ||
      !!boilerYearOfConstruction ||
      !!boilerType ||
      !!hasCombiBoiler ||
      !!boilerFuel ||
      !!boilerProperty ||
      !!comfortQuality ||
      !!roof ||
      (isArray(floors) && !!floors.length) ||
      (isArray(gardens) && !!gardens.length) ||
      (isArray(garages) && !!garages.length) ||
      (isArray(currentDestination) && !!currentDestination.length) ||
      (isArray(currentUsage) && !!currentUsage.length) ||
      !!isRecreational ||
      !!inPark ||
      !isBuildingReady
    ) {
      valid.specifications = true;
    }

    if (
      (isArray(photos) && !!photos.length) ||
      (isArray(videos) && !!videos.length) ||
      (isArray(advertisementText) && !!advertisementText.length) ||
      (isArray(environmentText) && !!environmentText.length) ||
      (isArray(registrationConditions) && !!registrationConditions.length) ||
      (isArray(partiesDescriptionText) && !!partiesDescriptionText.length) ||
      (isArray(maps) && !!maps.length)
    ) {
      valid.marketing = true;
    }

    return valid;
  }
}
