import { DocumentSession } from "@haywork/api/kolibri";
import { DynamicDocumentsSignaturesContainerProps } from "@haywork/modules/dynamic-documents";
import {
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./signatures.component.scss");

export interface DynamicDocumentsSignaturesComponentProps {}
interface DynamicDocumentsSignaturesComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsSignaturesComponent extends React.Component<
  DynamicDocumentsSignaturesComponentProps &
    DynamicDocumentsSignaturesContainerProps,
  DynamicDocumentsSignaturesComponentState
> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.formControls = {
      numberOfSignings: {
        value: value(this.props.session, "numberOfSignings"),
      },
      vendorsDateSigning: {
        value: value(this.props.session, "vendorsSigningInfo.dateTimeSigning"),
      },
      vendorsSigningLocality: {
        value: value(this.props.session, "vendorsSigningInfo.locality"),
      },
      applicantsDateSigning: {
        value: value(
          this.props.session,
          "applicantsSigningInfo.dateTimeSigning"
        ),
      },
      applicantsSigningLocality: {
        value: value(this.props.session, "applicantsSigningInfo.locality"),
      },
      propertyManagerDateSigning: {
        value: value(
          this.props.session,
          "propertyManagerSigningInfo.dateTimeSigning"
        ),
      },
      propertyManagerSigningLocality: {
        value: value(this.props.session, "propertyManagerSigningInfo.locality"),
      },
    };
  }

  public render() {
    const {
      needsApplicants,
      needsVendors,
      needsPropertyManagers,
      needsObjectAssignment,
    } = this.props.template;
    const { linkedAssignment } = this.props.session;
    const showLabelAsClients =
      !needsObjectAssignment || !has(linkedAssignment, "id");

    return (
      <Form
        name="signatures"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
      >
        <div styleName="inline">
          <div styleName="text">
            <ResourceText resourceKey="numberOfSigningsPre" />
          </div>
          <div styleName="input">
            <Input.Number
              name="numberOfSignings"
              placeholder="numberOfSignings"
              round
            />
          </div>
          <div styleName="text">
            <ResourceText resourceKey="numberOfSigningsPost" />
          </div>
        </div>

        {needsVendors && (
          <div className="form__row">
            <label htmlFor="vendorsSigningLocality">
              <ResourceText
                resourceKey={
                  showLabelAsClients
                    ? "clientsSigningLabel"
                    : linkedAssignment.forSale
                    ? "vendorsSigningLabel"
                    : "vendorsSigningLabelRent"
                }
              />
            </label>
            <div styleName="inline">
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPre" />
              </div>
              <div styleName="input locality">
                <Input.Text
                  name="vendorsSigningLocality"
                  placeholder="signingLocality"
                />
              </div>
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPost" />
              </div>
              <div styleName="input date">
                <Input.Datepicker
                  name="vendorsDateSigning"
                  placeholder="dateSigning"
                />
              </div>
            </div>
          </div>
        )}

        {needsApplicants && (
          <div className="form__row">
            <label htmlFor="applicantsSigningLocality">
              <ResourceText
                resourceKey={
                  showLabelAsClients
                    ? "askersSigningLabel"
                    : linkedAssignment.forSale
                    ? "applicantsSigningLabel"
                    : "applicantsSigningLabelRent"
                }
              />
            </label>
            <div styleName="inline">
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPre" />
              </div>
              <div styleName="input locality">
                <Input.Text
                  name="applicantsSigningLocality"
                  placeholder="signingLocality"
                />
              </div>
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPost" />
              </div>
              <div styleName="input date">
                <Input.Datepicker
                  name="applicantsDateSigning"
                  placeholder="dateSigning"
                />
              </div>
            </div>
          </div>
        )}

        {needsPropertyManagers && (
          <div className="form__row">
            <label htmlFor="propertyManagerSigningLocality">
              <ResourceText resourceKey="propertyManagerSigningLabel" />
            </label>
            <div styleName="inline">
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPre" />
              </div>
              <div styleName="input locality">
                <Input.Text
                  name="propertyManagerSigningLocality"
                  placeholder="signingLocality"
                />
              </div>
              <div styleName="text">
                <ResourceText resourceKey="signingLocalityPost" />
              </div>
              <div styleName="input date">
                <Input.Datepicker
                  name="propertyManagerDateSigning"
                  placeholder="dateSigning"
                />
              </div>
            </div>
          </div>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { session: previousSession, template } = this.props;
    const { needsApplicants, needsVendors, needsPropertyManagers } = template;

    let session: DocumentSession = {
      ...previousSession,
      numberOfSignings: values.numberOfSignings,
    };

    if (needsApplicants) {
      session = {
        ...session,
        applicantsSigningInfo: {
          ...previousSession.applicantsSigningInfo,
          dateTimeSigning: values.applicantsDateSigning,
          locality: values.applicantsSigningLocality,
        },
      };
    }

    if (needsVendors) {
      session = {
        ...session,
        vendorsSigningInfo: {
          ...previousSession.vendorsSigningInfo,
          dateTimeSigning: values.vendorsDateSigning,
          locality: values.vendorsSigningLocality,
        },
      };
    }

    /*if (needsPropertyManagers) {
      session = {
        ...session,
        propertyManagerSigningInfo: {
          ...previousSession.propertyManagerSigningInfo,
          dateTimeSigning: values.propertyManagerDateSigning,
          locality: values.propertyManagerSigningLocality
        }
      };
    }*/

    this.props.updateDocumentSession(session);
  }
}
