import { Action } from "redux";

import { REDUX } from "@haywork/constants";

import * as ActionType from "./new-entity.types";
import { NewEntity } from "./";
import { IdentifierPayload } from "../helpers";

export interface NewEntityState {
  entities: NewEntity[];
}

const INITIAL_STATE: NewEntityState = {
  entities: []
};

export const newEntityReducer = (state: NewEntityState = INITIAL_STATE, action: Action): NewEntityState => {
  switch (action.type) {
    case REDUX.NEW_ENTITY.SET_NEW_ENTITY: {
      const { entity } = <ActionType.NewEntityPayload> action;
      const entities = [...state.entities, entity];

      return {
        ...state,
        entities
      };
    }
    case REDUX.NEW_ENTITY.SET_ENTITY_RESULT: {
      const { id, entity: value } = <ActionType.EntityResultPayload> action;
      const entities = state.entities.map((entity) => {
        if (entity.id === id) {
          return {
            ...entity,
            entity: value
          };
        }
        return entity;
      });

      return {
        ...state,
        entities
      };
    }
    case REDUX.NEW_ENTITY.REMOVE_ENTITY: {
      const { id } = <IdentifierPayload> action;
      const entities = state.entities.filter((entity) => entity.id !== id);

      return {
        ...state,
        entities
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
