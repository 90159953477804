import {
  AcquisitionObjectAssignment,
  DrainageOption,
  HorseTroughLocationOption,
  ObjectAssignment,
  SoilTypeOption,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./horses-company.component.scss");

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  soilTypeOptions: SoilTypeOption[];
  drainageOptions: DrainageOption[];
  horseTroughLocationOptions: HorseTroughLocationOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AgriculturalHorsesCompanyComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.formControls = {
      companyName: {
        value: get(this.props.objectAssignment, "companyName", ""),
      },
      numberOfAnimalPlaces: {
        value: get(this.props.objectAssignment, "numberOfAnimalPlaces", ""),
      },
      numberOfHorseBoxes: {
        value: get(this.props.objectAssignment, "numberOfHorseBoxes", ""),
      },
      horseTroughLocation: {
        value: get(this.props.objectAssignment, "horseTroughLocation", ""),
      },
      drainageHorseRack: {
        value: get(this.props.objectAssignment, "drainageHorseRack", false),
      },
      stepMill: { value: get(this.props.objectAssignment, "stepMill", false) },
      soilType: { value: get(this.props.objectAssignment, "soilType", "") },
      drainage: { value: get(this.props.objectAssignment, "drainage", "") },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.formRef.update(
        {
          companyName: get(this.props.objectAssignment, "companyName", ""),
          numberOfAnimalPlaces: get(
            this.props.objectAssignment,
            "numberOfAnimalPlaces",
            ""
          ),
          numberOfHorseBoxes: get(
            this.props.objectAssignment,
            "numberOfHorseBoxes",
            ""
          ),
          horseTroughLocation: get(
            this.props.objectAssignment,
            "horseTroughLocation",
            ""
          ),
          drainageHorseRack: get(
            this.props.objectAssignment,
            "drainageHorseRack",
            false
          ),
          stepMill: get(this.props.objectAssignment, "stepMill", false),
          soilType: get(this.props.objectAssignment, "soilType", ""),
          drainage: get(this.props.objectAssignment, "drainage", ""),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="business-space"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="companyName">
              <label htmlFor="companyName">
                <ResourceText resourceKey="companyName" />
              </label>
              <Input.Text name="companyName" placeholder="companyName" />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column">
              <label htmlFor="numberOfAnimalPlaces">
                <ResourceText resourceKey="numberOfAnimalPlaces" />
              </label>
              <Input.Number
                name="numberOfAnimalPlaces"
                placeholder="numberOfAnimalPlaces"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="numberOfHorseBoxes">
                <ResourceText resourceKey="numberOfHorseBoxes" />
              </label>
              <Input.Number
                name="numberOfHorseBoxes"
                placeholder="numberOfHorseBoxes"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group flex-end flex-wrap">
            <div className="column">
              <label htmlFor="horseTroughLocation">
                <ResourceText resourceKey="horseTroughLocation" />
              </label>
              <Input.RadioGroup name="horseTroughLocation" asButtonList>
                {this.props.horseTroughLocationOptions.map(
                  (horseTroughLocationOption, idx) => (
                    <Input.Radio
                      value={horseTroughLocationOption.value}
                      label={horseTroughLocationOption.displayName}
                      key={idx}
                    />
                  )
                )}
              </Input.RadioGroup>
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="drainageHorseRack"
                on={true}
                off={false}
                label="drainageHorseRack"
              />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <Input.Switch
                name="stepMill"
                on={true}
                off={false}
                label="stepMill"
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column column__minwidth">
              <label htmlFor="soilType">
                <ResourceText resourceKey="soilType" />
              </label>
              <Input.NewSelect
                name="soilType"
                placeholder="makeAChoice"
                values={this.props.soilTypeOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
            <div className="column__spacer" />
            <div className="column column__minwidth">
              <label htmlFor="drainage">
                <ResourceText resourceKey="drainage" />
              </label>
              <Input.NewSelect
                name="drainage"
                placeholder="makeAChoice"
                values={this.props.drainageOptions}
                addEmptyOption
                displayProp="displayName"
                valuesProp="value"
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const updatedObjectAssignment = {
      ...objectAssignment,
      ...values,
    };

    this.props.onChange(updatedObjectAssignment);
  }
}
