import { DocumentSession, DocumentTemplate } from "@haywork/api/kolibri";
import { DYNAMICDOCUMENTROUTES } from "@haywork/constants";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import {
  DynamicDocumentsSaveError,
  DynamicDocumentsUtil,
  RouteUtil,
} from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./save-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface DynamicDocumentsSaveModalComponentProps {
  visible: boolean;
  documentSession: DocumentSession;
  documentTemplate: DocumentTemplate;
  onClose: () => void;
}
interface DynamicDocumentsSaveModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentsSaveModalComponent extends React.Component<
  DynamicDocumentsSaveModalComponentProps,
  DynamicDocumentsSaveModalComponentState
> {
  constructor(props) {
    super(props);

    this.mapError = this.mapError.bind(this);
  }

  public render() {
    const errors = DynamicDocumentsUtil.getErrors(
      this.props.documentSession,
      this.props.documentTemplate
    );

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="saveDynamicDocumentsMissingFields" close />
        <ModalBody>
          <ResourceText
            resourceKey="saveDynamicDocumentsMissingFieldsBody"
            values={{ count: errors.length }}
          />
          {errors.map(this.mapError)}
        </ModalBody>
        <ModalFooter>
          <div className="btn" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private mapError(
    error: DynamicDocumentsSaveError,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { id } = this.props.documentSession;
    let link;

    switch (error) {
      case DynamicDocumentsSaveError.NoLinkedAssignment:
      case DynamicDocumentsSaveError.NoLinkedPropertymanager:
        link = route(DYNAMICDOCUMENTROUTES.INFO.URI, { id });
        break;
      default:
        break;
    }

    return (
      <div styleName="error" onClick={this.props.onClose} key={idx}>
        <NavLink to={link}>
          <ResourceText resourceKey={error.toString()} />
        </NavLink>
      </div>
    );
  }
}
