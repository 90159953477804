import * as React from "react";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import * as CSSModules from "react-css-modules";
import { SingleFilterValue } from "../../";
import { ListFilterContext } from "../../list-filter.context";
import { Input } from "@haywork/modules/form";
import { Translateable } from "@haywork/components/i18n";
import isString from "lodash-es/isString";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  configKey: string;
  value: SingleFilterValue;
  title?: Translateable;
};

export const DatePickerComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ configKey, value, title }) => {
    const { filters, onFilterChange } = useContext(ListFilterContext);
    const [date, setDate] = useState(value.value || "");

    const filter = useMemo(() => {
      return !filters ? undefined : filters[configKey];
    }, [filters, configKey]);

    const label = useMemo(() => {
      if (!title || !filter) return null;
      const { value, values } = isString(title)
        ? { value: title, values: undefined }
        : title;
      return (
        <div styleName="label">
          <I18n value={value} values={values} />
        </div>
      );
    }, [title, filter]);

    const placeholder = useMemo(() => {
      if (!filter) return undefined;
      return `datePicker.placeholder.${configKey}`;
    }, [configKey]);

    const handleChange = useCallback(
      (value: Date) => {
        if (!filter) return;

        setDate(value || undefined);
        const updatedFilter = {
          ...filter,
          value,
        };

        onFilterChange({
          ...filters,
          [configKey]: updatedFilter,
        });
      },
      [filter, filters, configKey, onFilterChange, setDate]
    );

    if (!filter) return null;

    return (
      <div styleName="wrapper">
        {!!label && <h2>{label}</h2>}
        <div styleName="input">
          <Input.Datepicker
            name={`${configKey}`}
            value={date}
            onChange={handleChange}
            placeholder={placeholder}
            asSingleInput
          />
        </div>
      </div>
    );
  })
);
