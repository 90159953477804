import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { OBJECTTYPESROUTES } from "@haywork/constants";
import {
  ObjectTypesEditTypeContainer,
  ObjectTypesEditGeneralContainer,
  ObjectTypesEditSpecsContainer,
  ObjectTypesEditMarketingContainer,
  ObjectTypesPublishContainer
} from "@haywork/modules/object-types";

const route = RouteUtil.mapStaticRouteValues;

export const ObjectTypesEditRouting = ({ id }) => {
  return (
    <Switch>
      <Route
        exact
        path={OBJECTTYPESROUTES.EDIT.URI}
        render={() => <Redirect to={route(OBJECTTYPESROUTES.EDIT_TYPE.URI, { id })} />}
      />
      <Route exact path={OBJECTTYPESROUTES.EDIT_TYPE.URI} component={ObjectTypesEditTypeContainer} />
      <Route exact path={OBJECTTYPESROUTES.EDIT_GENERAL.URI} component={ObjectTypesEditGeneralContainer} />
      <Route exact path={OBJECTTYPESROUTES.EDIT_SPECIFICATIONS.URI} component={ObjectTypesEditSpecsContainer} />
      <Route path={OBJECTTYPESROUTES.EDIT_MARKETING.URI} component={ObjectTypesEditMarketingContainer} />
      <Route exact path={OBJECTTYPESROUTES.PUBLISH.URI} component={ObjectTypesPublishContainer} />
    </Switch>
  );
};
