import {
  InitializedWidget,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { ObjectTypeAssignment } from "@haywork/api/kolibri";
import {
  AuthorizationThunks,
  Dispatch,
  ProjectsThunks,
} from "@haywork/middleware";
import {
  ObjectTypesDetailGeneralComponent,
  ObjectTypesDetailGeneralComponentProps,
} from "@haywork/modules/object-types";
import { AppState, ProjectsActions } from "@haywork/stores";
import { injectIntl, WithIntlProps } from "react-intl";
import { connect } from "react-redux";
import {
  buildingStartText,
  contentsRange,
  deliveryStartText,
  fullyBakedDisplayName,
  livingRoomRange,
  parcelRange,
  priceRange,
  projectName,
  saleStartText,
  usableAreaRange,
} from "./selectors";

interface StateProps {
  currentType: ObjectTypeAssignment;
  buildingStartText: string;
  deliveryStartText: string;
  saleStartText: string;
  priceRange: NumberRange;
  parcelRange: NumberRange;
  livingRoomRange: NumberRange;
  usableAreaRange: NumberRange;
  contentsRange: NumberRange;
  displayName: string;
  projectName: string;
}
interface DispatchProps {
  toggleAddBuildnumbers: (visible: boolean) => void;
  saveObjectType: (objectType: ObjectTypeAssignment) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, ObjectTypesDetailGeneralComponentProps>(
  state: AppState
) => {
  const { currentType } = state.project.types;

  return {
    currentType,
    buildingStartText: buildingStartText(state),
    deliveryStartText: deliveryStartText(state),
    saleStartText: saleStartText(state),
    priceRange: priceRange(state),
    parcelRange: parcelRange(state),
    livingRoomRange: livingRoomRange(state),
    usableAreaRange: usableAreaRange(state),
    contentsRange: contentsRange(state),
    displayName: fullyBakedDisplayName(state),
    projectName: projectName(state),
  };
};

const mapDispatchToProps = <
  DispatchProps,
  ObjectTypesDetailGeneralComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  toggleAddBuildnumbers: (visible: boolean) =>
    dispatch(ProjectsActions.Buildnumbers.toggleAddBuildnumbers(visible)),
  saveObjectType: (objectType: ObjectTypeAssignment) =>
    dispatch(ProjectsThunks.Types.saveType(objectType, false)),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    ),
});

export type ObjectTypesDetailGeneralContainerProps = StateProps &
  DispatchProps &
  WithIntlProps<any>;
export const ObjectTypesDetailGeneralContainer = injectIntl(
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ObjectTypesDetailGeneralComponent)
);
