import * as React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "@haywork/stores";

interface ComponentProps {
  feature: string;
  realEstateAgency?: string;
  disable?: boolean;
}
interface StateProps {
  features: string[];
}

type Props = StateProps & ComponentProps;

export class FeatureSwitchComponent extends React.PureComponent<Props, {}> {
  public render() {
    const { features, feature, children, disable } = this.props;

    if (
      (!disable && !features.includes(feature)) ||
      (!!disable && features.includes(feature))
    ) {
      return null;
    }

    return children;
  }
}

const features = createSelector(
  (state: AppState) => state.appSettings.features,
  (features) => features
);
const mapStateToProps = <StateProps, ComponentProps>(state: AppState) => {
  return {
    features: features(state),
  };
};

export const FeatureSwitch = connect<StateProps, {}, ComponentProps>(
  mapStateToProps,
  {}
)(FeatureSwitchComponent);
