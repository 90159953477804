import * as React from "react";
import get from "lodash-es/get";
import { Modal, ModalBody } from "@haywork/modules/modal";
import { AuthorizationWidgetsWidgetModalContainerProps } from "./widget-modal.container";
import * as CSSModules from "react-css-modules";
import ModalHeader from "../widget-modal-header";
import { AppClient } from "@haywork/api/authorization";
import Autosizer from "react-virtualized-auto-sizer";

const styles = require("./style.scss");

export interface AuthorizationWidgetsWidgetModalComponentProps {}
interface State {
  appClient: AppClient;
}
type Props = AuthorizationWidgetsWidgetModalComponentProps &
  AuthorizationWidgetsWidgetModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AuthorizationWidgetsWidgetModalComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      appClient: null,
    };
  }

  public render() {
    const height = this.props.widgetModalWidget?.height || 0;
    const maxHeight = this.props.widgetModalWidget?.maxHeight || 0;
    const maxWidth = this.props.widgetModalWidget?.maxWidth || 0;

    const displayName = this.props.widgetModalWidget?.displayName || "";
    const signedCallbackUri =
      this.props.widgetModalInitializedWidget?.signedCallbackUri || "";

    const style: React.CSSProperties = {
      height: !maxHeight ? height : `calc(100vh - 48px)`,
      width: !maxWidth ? undefined : `calc(100vw - 48px)`,
      maxHeight,
      maxWidth,
    };

    if (!signedCallbackUri) return;

    return (
      <Modal
        visible={this.props.widgetModalVisibility}
        onClose={this.props.hideModal}
        style={style}
      >
        <ModalHeader
          title={displayName}
          appClientId={
            get(this.props.widgetModalWidget, "linkedAppClient.id") || null
          }
        />
        <ModalBody noPadding={true}>
          <Autosizer>
            {({ height, width }) => (
              <iframe
                style={{ height, minHeight: height, width }}
                styleName="iframe"
                src={signedCallbackUri}
                frameBorder={0}
                // sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin"
              />
            )}
          </Autosizer>
        </ModalBody>
      </Modal>
    );
  }
}
