import { ToastProps } from "./snackbar.reducer";

export enum snackbarActions {
  AddToast = "Snackbar.AddToast",
  RemoveToast = "Snackbar.RemoveToast"
}

interface AddToast extends ToastProps {
  type: snackbarActions.AddToast;
}
const addToast = ({
  persistFor,
  value,
  values,
  icon,
  callback,
  callbackLabel,
  callbackLabelValues,
  spin
}: ToastProps) => ({
  type: snackbarActions.AddToast,
  persistFor,
  value,
  values,
  icon,
  callback,
  callbackLabel,
  callbackLabelValues,
  spin
});

interface RemoveToast {
  type: snackbarActions.RemoveToast;
  id: string;
}
const removeToast = (id: string) => ({
  type: snackbarActions.RemoveToast,
  id
});

export type SnackbarActions = AddToast | RemoveToast;
export const Actions = {
  addToast,
  removeToast
};
