import { listActions, ListActions } from "./actions";
import {
  AssignmentSnapShot,
  RealEstateGroup,
  AssignmentPhase,
  AvailabilityStatus,
  SortOrder,
  AssignmentOrderByField,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { FilterConfig } from "@haywork/components/ui/list";
import { AssignmentOrder } from ".";
import { FilterType } from "@haywork/enum/list-filter-types";

export type ListState = {
  assignments: AssignmentSnapShot[];
  total: number;
  assignmentsStatus: string;
  filters: FilterConfig;
  order: AssignmentOrder;
  scrollOffset: number;
};

const INITIAL: ListState = {
  assignments: [],
  total: 0,
  assignmentsStatus: REQUEST.IDLE,
  order: {
    sortOrder: SortOrder.Descending,
    sortColumn: AssignmentOrderByField.ActivityAndDateTimeModified,
  },
  filters: {
    realEstateGroups: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "realEstateGroups",
    },
    assignmentPhases: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "assignmentPhases",
    },
    saleOrRent: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
    employees: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "employees",
    },
    offices: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
      prefix: "offices",
    },
    archived: {
      type: FilterType.Array,
      value: [],
      emptyValue: [],
    },
  },
  scrollOffset: 0,
};

export const listReducer = (state = INITIAL, action: ListActions) => {
  switch (action.type) {
    case listActions.SetAssignments: {
      const { assignments, total, reset } = action;

      return {
        ...state,
        assignments: reset
          ? assignments
          : [...state.assignments, ...assignments],
        total,
        assignmentsStatus: REQUEST.SUCCESS,
      };
    }
    case listActions.SetAssignmentsStatus: {
      const { assignmentsStatus } = action;

      return {
        ...state,
        assignmentsStatus,
      };
    }
    case listActions.SetOrdering: {
      const { sortColumn, sortOrder } = action;

      return {
        ...state,
        assignments: [],
        totalCount: 0,
        order: {
          sortColumn,
          sortOrder,
        },
      };
    }
    case listActions.SetFilters: {
      const { filters } = action;
      return {
        ...state,
        filters,
        assignments: [],
        assignmentsStatus: REQUEST.PENDING,
        total: 0,
      };
    }

    case listActions.UpdateListItem: {
      const { snapshot } = action;
      const assignments = state.assignments.map((assignment) => {
        if (assignment.id === snapshot.id) {
          return snapshot;
        }
        return assignment;
      });

      return {
        ...state,
        assignments,
      };
    }
    case listActions.DeleteListItem: {
      const { id } = action;
      const assignments = state.assignments.filter(
        (assignment) => assignment.id !== id
      );

      return {
        ...state,
        assignments,
      };
    }
    case listActions.SetScrollOffset: {
      const { scrollOffset } = action;

      return {
        ...state,
        scrollOffset,
      };
    }
    case listActions.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
