import { RootEntityType } from "@haywork/api/event-center";
import {
  AgendaItem,
  AgendaItemCategory,
  AgendaItemCategorySnapShot,
  AgendaItemSnapShot,
  AssignmentSnapShot,
  CountryOption,
  ObjectAssignment,
  RelationSnapShot,
  RelationTypeOption,
  AcquisitionAssignment,
  LinkedRelation,
  LinkedAssignment,
} from "@haywork/api/kolibri";
import { SCHEDULERROUTES } from "@haywork/constants";
import {
  AssignmentThunks,
  Dispatch,
  EditableThunks,
  SchedulerThunks,
  AcquisitionThunk,
  TaskThunks,
} from "@haywork/middleware";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import { SchedulerDetailComponent } from "@haywork/modules/scheduler";
import { AppState, EditableItem, SchedulerActions } from "@haywork/stores";
import { push } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithIntlProps, injectIntl } from "react-intl";

export interface ReminderOptions {
  id: number;
  value: number;
  text: string;
}

export interface OverlapInterface {
  hasOverlap: boolean;
  agendaItems: AgendaItemSnapShot[];
}

interface StateProps {
  agendaItem?: AgendaItem;
  schedulerDetailState?: string;
  agendaItemCategories?: AgendaItemCategorySnapShot[];
  employees?: RelationSnapShot[];
  realEstateAgencyId?: string;
  host: string;
  selectedAgendaItemCategory: AgendaItemCategory;
  culture: string;
  reminderOptions?: ReminderOptions[];
  country: CountryOption;
  schedulerConfirmationState: string;
  schedulerCancelState: string;
  schedulerRemoveState: string;
  relationTypes: RelationTypeOption[];
  countries: CountryOption[];
}

interface DispatchProps {
  getAgendItem: (id: string) => void;
  save: (agendaItem: AgendaItem, path: string) => void;
  checkForOverlappingAppointments: (
    agendaItem: AgendaItem
  ) => Promise<OverlapInterface>;
  confirmAgendaItem: (agendaItem: AgendaItem) => void;
  cancelAgendaItem: (agendaItem: AgendaItem) => void;
  closeAgendaItem: (path: string) => void;
  deleteAgendaItem: (id: string, path: string) => void;
  setAgendaItem: (agendaItem: AgendaItem) => void;
  removeRecurrency: () => void;
  navigateToNewScheduler: (
    startDateTime?: Date,
    endDateTime?: Date,
    linkedEmployeeIds?: string[],
    agendaItem?: AgendaItem
  ) => void;
  navigate: (route: string) => void;
  getLinkedAssignment: (id: string) => Promise<ObjectAssignment>;
  reloadAgendaItem: (editable: EditableItem) => Promise<void>;
  getAssignmentsLinkedToRelations: (
    relationIds: string[]
  ) => Promise<AssignmentSnapShot[]>;
  getLinkedAcquisition: (id: string) => Promise<AcquisitionAssignment>;
  createTaskFromAppointment: (appointment: AgendaItem, intl) => Promise<void>;
}

const mapStateToProps: MapStateToProps<any, any, AppState> = <
  StateProps,
  SchedulerDetailComponentProps
>(
  state: AppState
) => ({
  agendaItem: state.editable.currentComponentState,
  schedulerDetailState: state.scheduler.schedulerDetailState,
  schedulerConfirmationState: state.scheduler.schedulerConfirmationState,
  schedulerCancelState: state.scheduler.schedulerCancelState,
  schedulerRemoveState: state.scheduler.schedulerRemoveState,
  agendaItemCategories: state.scheduler.agendaItemCategories,
  employees: state.account.employees,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  reminderOptions: getReminderOptions(),
  country: state.main.mastertable.kolibri.countries.find(
    (country) => country.iso2CodeValue === "NL"
  ),
  relationTypes: state.main.mastertable.kolibri.relationTypes,
  culture: state.main.culture,
  host: state.appSettings.host,
  countries: state.main.mastertable.kolibri.countries,
});

const mapDispatchToProps = <DispatchProps, SchedulerDetailComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getAgendItem: (id: string) => dispatch(SchedulerThunks.getAgendaItem(id)),
  save: (agendaItem: AgendaItem, path: string) =>
    dispatch(SchedulerThunks.saveAgendaItem(agendaItem, path)),
  checkForOverlappingAppointments: (agendaItem: AgendaItem) =>
    dispatch(SchedulerThunks.checkForOverlappingAppointments(agendaItem)),
  confirmAgendaItem: (agendaItem: AgendaItem) =>
    dispatch(SchedulerThunks.confirmAgendaItem(agendaItem)),
  cancelAgendaItem: (agendaItem: AgendaItem) =>
    dispatch(SchedulerThunks.cancelAgendaItem(agendaItem)),
  closeAgendaItem: (path: string) => dispatch(EditableThunks.remove(path)),
  deleteAgendaItem: (id: string, path: string) =>
    dispatch(SchedulerThunks.deleteAgendaItem(id, path)),
  setAgendaItem: (agendaItem: AgendaItem) =>
    dispatch(SchedulerActions.setAgendaItemDirectly(agendaItem)),
  removeRecurrency: () =>
    dispatch(SchedulerActions.setRecurrency({ recurrency: "" })),
  navigateToNewScheduler: (
    startDateTime?: Date,
    endDateTime?: Date,
    linkedEmployeeIds?: string[],
    agendaItem?: AgendaItem
  ) =>
    dispatch(
      SchedulerThunks.createSchedulerItem(
        startDateTime,
        endDateTime,
        linkedEmployeeIds,
        agendaItem
      )
    ),
  navigate: (route: string) => dispatch(push(route)),
  getLinkedAssignment: (id: string) =>
    dispatch(AssignmentThunks.getLinkedAssignment(id)),
  getLinkedAcquisition: (id: string) =>
    dispatch(AcquisitionThunk.getLinkedAcquisition(id)),
  reloadAgendaItem: (editable: EditableItem) =>
    dispatch(SchedulerThunks.reloadAgendaItem(editable)),
  getAssignmentsLinkedToRelations: (relationIds: string[]) =>
    dispatch(AssignmentThunks.getAssignmentsByRelation(relationIds)),
  createTaskFromAppointment: (appointment: AgendaItem, intl) =>
    dispatch(TaskThunks.createTaskFromAppointment(appointment, intl)),
});

const getReminderOptions = (): ReminderOptions[] => {
  const options: ReminderOptions[] = [
    { id: 1, value: null, text: "Nooit" },
    { id: 2, value: 5, text: "5 Minuten" },
    { id: 3, value: 10, text: "10 minuten" },
    { id: 4, value: 15, text: "15 minuten" },
    { id: 5, value: 30, text: "30 minuten" },
    { id: 6, value: 60, text: "1 uur" },
    { id: 7, value: 120, text: "2 uren" },
    { id: 8, value: 240, text: "4 uren" },
    { id: 9, value: 480, text: "8 uren" },
  ];

  return options;
};

export type SchedulerDetailContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  EditableHocProps;
export const SchedulerDetailContainer = editable(
  withRouter(
    connect<any, any, any>(
      mapStateToProps,
      mapDispatchToProps
    )(SchedulerDetailComponent)
  ),
  {
    entityType: RootEntityType.Agendaitem,
    icon: "calendar",
    thunk: SchedulerThunks.getAgendaItem,
    status: "scheduler.schedulerDetailState",
    statePath: "scheduler.agendaItem",
    action: SchedulerActions.setAgendaItemDirectly,
    route: SCHEDULERROUTES.SCHEDULER_DETAIL.URI,
    confirm: {
      title: { key: "saveAgendaConfirmTitle" },
      body: { key: "saveAgendaConfirmBody" },
    },
  }
);
