import {
  AgendaItemCategorySnapShot,
  AgendaItemCategoryStatistics,
  AgendaItemCategoryTypeOption,
  AgendaStandardDurationOption,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  SchedulerCategoryComponent,
  SchedulerCategoryComponentProps,
} from "@haywork/modules/settings/modules/scheduler-categories";
import { AppState } from "@haywork/stores/";
import { connect } from "react-redux";

interface StateProps {
  agendaItemCategories: AgendaItemCategorySnapShot[];
  deletedSchedulerCategoryIds: string[];
  schedulerCategoryStatus: string;
  schedulerAddCategoryStatus: string;
  agendaStandardDurationOptions: AgendaStandardDurationOption[];
  agendaItemCategoryTypes: AgendaItemCategoryTypeOption[];
  agendaItemCategoryStatistics: AgendaItemCategoryStatistics;
  latestAgendaItemCategoryAdded: AgendaItemCategorySnapShot;
}
interface DispatchProps {}

const mapStateToProps = <StateProps, RelationGroupComponentProps>(
  state: AppState
) => {
  const {
    agendaStandardDurationOptions,
    agendaItemCategoryTypes,
  } = state.main.mastertable.kolibri;
  const {
    agendaItemCategories,
    deletedSchedulerCategoryIds,
    schedulerCategoryStatus,
    schedulerAddCategoryStatus,
    agendaItemCategoryStatistics,
    latestAgendaItemCategoryAdded,
  } = state.schedulerCategory;

  return {
    agendaItemCategories,
    deletedSchedulerCategoryIds,
    schedulerCategoryStatus,
    schedulerAddCategoryStatus,
    agendaStandardDurationOptions,
    agendaItemCategoryTypes,
    agendaItemCategoryStatistics,
    latestAgendaItemCategoryAdded,
  };
};

const mapDispatchToProps = <DispatchProps, TaskOverviewComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type SchedulerCategoryContainerProps = StateProps & DispatchProps;
export const SchedulerCategoryContainer = connect<
  StateProps,
  DispatchProps,
  SchedulerCategoryComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerCategoryComponent);
