import { SortOrder, RelationOrderByField } from "@haywork/api/kolibri";
import { actions as RelationListActions } from "./actions";

export type RelationOrder = {
  sortOrder: SortOrder;
  sortColumn: RelationOrderByField;
};

export { listReducer, ListState } from "./reducer";
export { RelationListActions };
