import {
  AcquisitionObjectAssignment,
  HorseCompanySubtype,
  ListingType as lType
} from "@haywork/api/kolibri";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import ListingType, {
  ListingTypeResponse
} from "@haywork/modules/acquisition/components/listing-type";
import {
  AgriculturalAgriculturalHouseComponent,
  AgriculturalArableCompanyComponent,
  AgriculturalBusinessSpaceComponent,
  AgriculturalCattleFarmingComponent,
  AgriculturalDairyFarmingComponent,
  AgriculturalHorsesCompanyComponent,
  AgriculturalHorticulturalComponent,
  AgriculturalLooseSoilComponent,
  AgriculturalOtherComponent,
  AgriculturalPigHoldingComponent,
  AgriculturalPoultryCompanyComponent,
  AgriculturalSizesAndNumbersComponent,
  AgriculturalVealCalvesFarmingComponent
} from "@haywork/modules/assignment/components/edit-content/agricultural";
import { Form, FormControls, FormReturnValue } from "@haywork/modules/form";
import { StepComponent, StepperComponent } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import GlassStructures from "../../../../../assignment/components/edit-content/agricultural/components/glass-structures";
import RidingHalls from "../../../../../assignment/components/edit-content/agricultural/components/riding-halls";
import { AcquistionAssignmentEditObjectAgriculturalScreenContainerProps } from "./assignments-edit-object-agricultural.container";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export type AcquistionAssignmentEditObjectAgriculturalScreenProps = {};
type Props = AcquistionAssignmentEditObjectAgriculturalScreenProps &
  AcquistionAssignmentEditObjectAgriculturalScreenContainerProps;

export const AcquistionAssignmentEditObjectAgriculturalScreen: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      acquisitionObjectAssignment,
      updateAcquisitionAssignmentObject,
      constructionPeriods,
      soilTypeOptions,
      drainageOptions,
      groundUsageOptions,
      horseTroughLocationOptions,
      poultryFarmingSubtypes,
      poultryHousingTypeOptions
    }) => {
      const {
        realEstateGroup,
        listingType,
        houseSort,
        houseType,
        houseCharacteristic,
        apartmentSort,
        apartmentCharacteristic,
        parkingType,
        residentialSubtypeOther,
        forRent,
        yearOfConstruction,
        isNew
      } = acquisitionObjectAssignment;

      const isForSale = useMemo(() => {
        return forRent === true ? false : true;
      }, [forRent]);

      const formControls = useMemo(() => {
        return {
          forSale: { value: isForSale },
          constructionYear: {
            value: get(yearOfConstruction, "constructionYear", null)
          },
          constructionPeriod: {
            value: get(yearOfConstruction, "period", null)
          }
        } as FormControls;
      }, []);

      const handleListingChange = (listingTypes: ListingTypeResponse) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });

        const updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          ...listingTypes
        };

        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const onChangeHandler = (values: FormReturnValue) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });

        let updatedAcquisitionAssignmentObject: AcquisitionObjectAssignment = {
          ...acquisitionObjectAssignment,
          yearOfConstruction:
            !values.constructionPeriod && !values.constructionYear
              ? acquisitionObjectAssignment.yearOfConstruction
              : {
                  period: values.constructionPeriod,
                  constructionYear: values.constructionYear
                }
        };

        updatedAcquisitionAssignmentObject = {
          ...updatedAcquisitionAssignmentObject
        };

        updateAcquisitionAssignmentObject(
          updatedAcquisitionAssignmentObject,
          path
        );
      };

      const onChangeHandlerSubForm = (updatedObjectAssignment) => {
        const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
          id: acquisitionObjectAssignment.id
        });
        updateAcquisitionAssignmentObject(updatedObjectAssignment, path);
      };

      return (
        <div styleName="edit-detail" id="scroll-to-top">
          <div className="container-fluid">
            <Form
              name="acquisition-object-object-agricultural"
              formControls={formControls}
              onChange={onChangeHandler}
            >
              <StepperComponent initial={0} scrollToElementId="scroll-to-top">
                <StepComponent title="acquisition.assignment.edit.typeOfBuild">
                  <div className="form__row">
                    <ListingType
                      realEstateGroup={realEstateGroup}
                      listingType={listingType}
                      houseSort={houseSort}
                      houseType={houseType}
                      houseCharacteristic={houseCharacteristic}
                      apartmentSort={apartmentSort}
                      apartmentCharacteristic={apartmentCharacteristic}
                      parkingType={parkingType}
                      residentialSubtypeOther={residentialSubtypeOther}
                      isNew={isNew}
                      onChange={handleListingChange}
                    />
                  </div>
                </StepComponent>
                <StepComponent title="acquisition.assignment.edit.measurementsAndNumbers">
                  <AgriculturalSizesAndNumbersComponent
                    objectAssignment={acquisitionObjectAssignment}
                    constructionPeriods={constructionPeriods}
                    asSubForm={true}
                    onChange={onChangeHandlerSubForm}
                  />
                </StepComponent>
                {acquisitionObjectAssignment.listingType ===
                  lType.ArableCompany && (
                  <StepComponent title="listingTypes.ArableCompany">
                    <AgriculturalArableCompanyComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}
                {acquisitionObjectAssignment.listingType ===
                  lType.BusinessSpace && (
                  <StepComponent title="listingTypes.BusinessSpace">
                    <AgriculturalBusinessSpaceComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}
                {acquisitionObjectAssignment.listingType ===
                  lType.LooseSoil && (
                  <StepComponent title="listingTypes.LooseSoil">
                    <AgriculturalLooseSoilComponent
                      objectAssignment={acquisitionObjectAssignment}
                      groundUsageOptions={groundUsageOptions}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.DairyFarmingCompany && (
                  <StepComponent title="listingTypes.DairyFarmingCompany">
                    <AgriculturalDairyFarmingComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {/* ProductionRights, VealCalvesFarming */}
                {acquisitionObjectAssignment.listingType === lType.Other && (
                  <StepComponent title="listingTypes.Other">
                    <AgriculturalOtherComponent
                      objectAssignment={acquisitionObjectAssignment}
                      groundUsageOptions={groundUsageOptions}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.HorsesCompany && (
                  <>
                    <StepComponent title="listingTypes.HorsesCompany">
                      <AgriculturalHorsesCompanyComponent
                        objectAssignment={acquisitionObjectAssignment}
                        horseTroughLocationOptions={horseTroughLocationOptions}
                        soilTypeOptions={soilTypeOptions}
                        drainageOptions={drainageOptions}
                        asSubForm={true}
                        onChange={onChangeHandlerSubForm}
                      />
                    </StepComponent>
                    {[
                      HorseCompanySubtype.Pension,
                      HorseCompanySubtype.RidingSchool
                    ].includes(
                      acquisitionObjectAssignment.horseCompanySubtype
                    ) && (
                      <RidingHalls
                        objectAssignment={acquisitionObjectAssignment}
                        asSubForm={true}
                        onChange={onChangeHandlerSubForm}
                      />
                    )}
                  </>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.PoultryCompany && (
                  <StepComponent title="listingTypes.PoultryCompany">
                    <AgriculturalPoultryCompanyComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      poultryFarmingSubtypes={poultryFarmingSubtypes}
                      poultryHousingTypeOptions={poultryHousingTypeOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.CattleFarming && (
                  <StepComponent title="listingTypes.CattleFarming">
                    <AgriculturalCattleFarmingComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.VealCalvesFarming && (
                  <StepComponent title="listingTypes.VealCalvesFarming">
                    <AgriculturalVealCalvesFarmingComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.Horticultural && (
                  <>
                    <StepComponent title="listingTypes.Horticultural">
                      <AgriculturalHorticulturalComponent
                        objectAssignment={acquisitionObjectAssignment}
                        soilTypeOptions={soilTypeOptions}
                        drainageOptions={drainageOptions}
                        asSubForm={true}
                        onChange={onChangeHandlerSubForm}
                      />
                    </StepComponent>
                    <GlassStructures
                      objectAssignment={acquisitionObjectAssignment}
                      onChange={onChangeHandlerSubForm}
                      asSubForm={true}
                    />
                  </>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.PigHolding && (
                  <StepComponent title="listingTypes.PigHolding">
                    <AgriculturalPigHoldingComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      drainageOptions={drainageOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}

                {acquisitionObjectAssignment.listingType ===
                  lType.AgriculturalHouse && (
                  <StepComponent title="listingTypes.AgriculturalHouse">
                    <AgriculturalAgriculturalHouseComponent
                      objectAssignment={acquisitionObjectAssignment}
                      soilTypeOptions={soilTypeOptions}
                      asSubForm={true}
                      onChange={onChangeHandlerSubForm}
                    />
                  </StepComponent>
                )}
              </StepperComponent>
            </Form>
          </div>
        </div>
      );
    }
  )
);
