import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import {
  RealEstateGroup,
  VatCondition,
  InvoiceLine,
} from "@haywork/api/kolibri";

const linkedAssignmentSelector = (state: AppState) =>
  state.invoice.single.linkedAssignment;
const invoiceLinesSelector = (state: AppState) =>
  get(state.editable.currentComponentState, "lines", []);

export const deposit = createSelector(
  linkedAssignmentSelector,
  (linkedAssignment) => {
    return !!linkedAssignment && linkedAssignment.forRent
      ? (get(linkedAssignment, "rentOffer.deposit", 0) as number)
      : 0;
  }
);

export const rentPrice = createSelector(
  linkedAssignmentSelector,
  (linkedAssignment) => {
    return !!linkedAssignment && linkedAssignment.forRent
      ? (get(linkedAssignment, "rentOffer.realizedPerMonth", 0) as number) ||
          (get(linkedAssignment, "rentOffer.rentPrice", 0) as number)
      : 0;
  }
);

export const vatCondition = createSelector(
  linkedAssignmentSelector,
  (linkedAssignment) => {
    return !!linkedAssignment
      ? linkedAssignment.forSale
        ? get(
            linkedAssignment,
            "saleOffer.vatCondition",
            linkedAssignment.realEstateGroup === RealEstateGroup.Residential
              ? VatCondition.Included
              : VatCondition.Excluded
          )
        : get(
            linkedAssignment,
            "rentOffer.vatCondition",
            linkedAssignment.realEstateGroup === RealEstateGroup.Residential
              ? VatCondition.Included
              : VatCondition.Excluded
          )
      : VatCondition.Included;
  }
);

// export const invoiceTotalGross = createSelector(
//   invoiceLinesSelector,
//   (lines: InvoiceLine[]) => {
//     return lines.reduce((state, line) => {
//       const { totalPriceGross } = line;
//       return state + (totalPriceGross || 0);
//     }, 0);
//   }
// );

export const invoiceTotalNet = createSelector(
  invoiceLinesSelector,
  (lines: InvoiceLine[]) => {
    return lines.reduce((state, line) => {
      const { totalPriceNet, totalPriceGross, taxPercentage } = line;
      let price = totalPriceNet;
      if (!price) {
        const multiplier = parseFloat(
          `1.${taxPercentage.toString().padStart(2, "0")}`
        );
        price = totalPriceGross / multiplier;
      }
      return state + Math.round(price * 100) / 100;
    }, 0);
  }
);

export const invoiceTotalTax = createSelector(
  invoiceLinesSelector,
  (lines: InvoiceLine[]) => {
    return lines.reduce((state, line) => {
      let { totalPriceNet } = line;
      const { totalPriceGross, taxPercentage } = line;
      const multiplier = parseFloat(
        `1.${taxPercentage.toString().padStart(2, "0")}`
      );

      if (
        totalPriceNet === undefined ||
        totalPriceNet === null ||
        totalPriceNet === 0
      ) {
        totalPriceNet = totalPriceGross / multiplier;
      }

      const totalTax =
        Math.round((totalPriceGross - totalPriceNet) * 100) / 100;

      return state + totalTax;
    }, 0);
  }
);

export const invoiceTotalGross = createSelector(
  invoiceTotalNet,
  invoiceTotalTax,
  (net: number, tax: number) => {
    return net + tax;
  }
);
