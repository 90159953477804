import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { InfiniteScroll, ResourceText } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { BuildnumberItem } from "./list-item.component";

const styles = require("./buildnumber-overview.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface Props {
  buildnumbers: AssignmentSnapShot[];
  totalCount: number;
  canLoadMore: boolean;
  loading: boolean;
  emptyStateSubtitle?: string;
  onGetBuildnumbers: (reset?: boolean) => void;
  onBuildnumberClick: (path: string) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class BuildnumberOverviewComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onBuildnumberClickHandler = this.onBuildnumberClickHandler.bind(this);
  }

  public componentDidMount() {
    this.props.onGetBuildnumbers(true);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      nextProps.buildnumbers.length !== this.props.buildnumbers.length &&
      nextProps.buildnumbers.length === 0
    ) {
      this.props.onGetBuildnumbers();
    }
  }

  public render() {
    return (
      <div styleName="buildnumbers">
        <div styleName="buildnumbers__header">
          <ResourceText
            resourceKey="buildnumbersTotal"
            values={{ count: this.props.totalCount }}
          />
        </div>
        <div styleName="buildnumbers__body" data-cy={this.props["data-cy"]}>
          <InfiniteScroll scrollEnd={this.onScrollEndHandler}>
            {this.props.buildnumbers.map((buildnumber, idx) => (
              <ErrorBoundary key={buildnumber.id}>
                <BuildnumberItem
                  buildnumber={buildnumber}
                  zebra={idx % 2 === 0}
                  onClick={this.onBuildnumberClickHandler}
                  data-cy={
                    this.props["data-cy"] &&
                    `${this.props["data-cy"]}.BuildNumberItem`
                  }
                />
              </ErrorBoundary>
            ))}

            {!this.props.loading &&
              !this.props.canLoadMore &&
              this.props.buildnumbers.length === 0 && (
                <Ui.EmptyState
                  type={UiEmptyStateType.List}
                  stickman={UiEmptyStateStickMan.NoAroItems}
                  title="emptyStateBuildnumbersOverviewTitle"
                  subtitle={
                    this.props.emptyStateSubtitle ||
                    "emptyStateBuildnumbersOverviewText"
                  }
                />
              )}

            {this.props.loading && <Ui.Loaders.List />}
          </InfiniteScroll>
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    if (!!this.props.loading || !this.props.canLoadMore) return;
    this.props.onGetBuildnumbers();
  }

  private onBuildnumberClickHandler(buildnumber: AssignmentSnapShot) {
    const { id } = buildnumber;
    const path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onBuildnumberClick(path);
  }
}
