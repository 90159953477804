import * as React from "react";
import { FC, memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { AppTitleContainerProps } from "./app-title.container";

export type AppTitleComponentProps = {};
type Props = AppTitleComponentProps & AppTitleContainerProps;

export const AppTitleComponent: FC<Props> = memo(
  ({ notificationCount, appTitle }) => {
    const title = useMemo(() => {
      return !notificationCount
        ? appTitle
        : `(${notificationCount}) ${appTitle}`;
    }, [appTitle, notificationCount]);

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }
);
