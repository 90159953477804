import { RootEntityType } from "@haywork/api/event-center";
import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { ACQUISITIONOBJECTROUTES } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { editable } from "@haywork/modules/editable";
import { AppState } from "@haywork/stores";
import { Actions } from "@haywork/stores/acquisition/assignment/assignment.action";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AcquisitionAssignmentDetailScreenProps,
  AcquistionAssignmentDetailScreen
} from "./assignments.detail";

type StateProps = {
  acquisitionObjectAssignment: AcquisitionObjectAssignment;
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionAssignmentDetailScreenProps,
  AppState
> = (state) => {
  const acquisitionObjectAssignment: AcquisitionObjectAssignment =
    state.editable.currentComponentState;

  return {
    acquisitionObjectAssignment
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionAssignmentDetailScreenProps
> = (dispatch) => ({});

export type AcquisitionAssignmentDetailScreenContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const AcquisitionDetailScreenContainer = editable(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AcquistionAssignmentDetailScreen)
  ),
  {
    icon: "fal fa-handshake",
    thunk: AcquisitionThunks.AcquisitionObject.getAcquisitionObject,
    status: "acquisition.assignment.acquisitionAssignmentObjectStatus",
    statePath: "acquisition.assignment.acquisitionAssignmentObject",
    action: Actions.setAcquisitionObjectAssignment,
    route: ACQUISITIONOBJECTROUTES.DETAIL.URI,
    entityType: RootEntityType.AcquisitionObjectAssignment,
    confirm: {
      title: { key: "acquisitionObject.saveConfirm.title" },
      body: { key: "acquisitionObject.saveConfirm.body" }
    }
  }
);
