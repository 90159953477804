import { AssignmentPhase } from "@haywork/api/kolibri";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST, SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import Notes, { NotesList } from "@haywork/modules/notes-v3";
import {
  SearchAssignmentDetailContainerProps,
  SearchAssignmentDetailRouting,
  SearchAssignmentSaveModalComponent,
} from "@haywork/modules/search-assignment";
import { ObjectTimestamps, ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink, RouteComponentProps } from "react-router-dom";
import Actions, { SearchAssignmentAction } from "./actions";
import { EditableHocProps } from "@haywork/modules/editable";
import { EmailAssignment } from "@haywork/util/email-v2";
import { intlContext } from "@haywork/app";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export type SearchAssignmentDetailComponentProps = {};
type Props = SearchAssignmentDetailComponentProps &
  SearchAssignmentDetailContainerProps &
  EditableHocProps &
  RouteComponentProps<any>;
type State = {
  selectedAssignments: EmailAssignment[];
};

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentDetailComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      selectedAssignments: [],
    };

    this.onEditClickHandler = this.onEditClickHandler.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.toggleSelectedAssignment = this.toggleSelectedAssignment.bind(this);
    this.clearSelectedAssignments = this.clearSelectedAssignments.bind(this);
  }

  public render() {
    const { searchAssignment, saveSearchAssignmentStatus } = this.props;
    const {
      id,
      isNew,
      linkedCreatedBy,
      linkedModifiedBy,
      dateTimeCreated,
      dateTimeModified,
      isActive,
    } = searchAssignment;
    const inEditMode = this.props.location.pathname.match(/edit/);
    const loading = saveSearchAssignmentStatus === REQUEST.PENDING;
    const selectedAssignmentsIds = this.state.selectedAssignments.map(
      (assignment) => assignment.id
    );

    return (
      <div styleName="detail">
        {!isNew && <NotesList parentId={id} />}

        {!inEditMode && (
          <div styleName="sideBar">
            <div styleName="menu">
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(SEARCHASSIGNMENTROUTES.GENERAL.URI, { id })}
              >
                <i className="fal fa-fw fa-search" />
                <span styleName="text" id="searchAssignmentTabGeneral">
                  <ResourceText resourceKey="tabGeneral" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(SEARCHASSIGNMENTROUTES.SEARCHERS.URI, { id })}
              >
                <i className="fal fa-fw fa-users" />
                <span styleName="text" id="searchAssignmentTabSearchers">
                  <ResourceText resourceKey="tabSearchers" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(SEARCHASSIGNMENTROUTES.LINKED_ASSIGNMENTS.URI, {
                  id,
                })}
              >
                <i className="fal fa-fw fa-folder-open" />
                <span styleName="text" id="searchAssignmentTabAssignments">
                  <ResourceText resourceKey="tabLinkedAssignments" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(SEARCHASSIGNMENTROUTES.MATCHMAIL.URI, { id })}
              >
                <i className="fal fa-fw fa-envelope-open" />
                <span styleName="text" id="searchAssignmentTabMatchmail">
                  <ResourceText resourceKey="tabMatchmail" />
                </span>
              </NavLink>
            </div>
            <ObjectTimestamps
              linkedCreatedBy={linkedCreatedBy}
              linkedModifiedBy={linkedModifiedBy}
              dateTimeCreated={dateTimeCreated}
              dateTimeModified={dateTimeModified}
            />
          </div>
        )}

        <div styleName="body">
          {!inEditMode && (
            <PageHeader
              title="searchAssignment"
              subTitle={!isActive ? "archived" : undefined}
              actions={
                <>
                  <Notes />
                  <Button
                    label="showEdit"
                    category="success"
                    onClick={this.onEditClickHandler}
                    disabled={loading}
                  />
                  <Actions
                    searchAssignment={this.props.searchAssignment}
                    canSendEmail={this.props.canSendEmail}
                    hasSelectedEmailAssignments={
                      !!this.state.selectedAssignments.length
                    }
                    loading={loading}
                    onClick={this.handleActionClick}
                  />
                </>
              }
            />
          )}
          <div styleName="body__routing">
            {loading && <Ui.Loaders.Fullscreen mask />}
            <SearchAssignmentDetailRouting
              id={id}
              selectedAssignmentsIds={selectedAssignmentsIds}
              clearSelectedAssignments={this.clearSelectedAssignments}
              onToggleSelected={this.toggleSelectedAssignment}
            />
          </div>
        </div>

        <SearchAssignmentSaveModalComponent
          visible={this.props.showSaveModal}
          searchAssignment={this.props.searchAssignment}
          onClose={() => this.props.toggleSaveModal(false)}
        />
      </div>
    );
  }

  public componentDidMount() {
    this.props.searchAssignment.isNew
      ? this.props.setTabTitle(
          intlContext.formatMessage({
            id: "newSearchAssignment",
            defaultMessage: "newSearchAssignment",
          })
        )
      : this.props.setTabTitle(
          intlContext.formatMessage({
            id: "searchAssignment",
            defaultMessage: "searchAssignment",
          })
        );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      if (!this.props.canSave) {
        const path = route(SEARCHASSIGNMENTROUTES.DETAIL.URI, {
          id: nextProps.searchAssignment.id,
        });
        this.props.stateDisablePreppedForSave(path);
        return this.props.toggleSaveModal(true);
      }
      this.props.saveAndCloseSearchAssignment(this.props.searchAssignment);
    }
  }

  private onEditClickHandler() {
    const url = route(SEARCHASSIGNMENTROUTES.EDIT.URI, {
      id: this.props.searchAssignment.id,
    });
    this.props.navigate(url);
  }

  private async handleActionClick(action: SearchAssignmentAction) {
    const { searchAssignment } = this.props;
    const { id } = searchAssignment;

    switch (action) {
      case SearchAssignmentAction.Activate: {
        await this.props.saveSearchAssignment({
          ...searchAssignment,
          assignmentPhase: AssignmentPhase.Initiated,
        });
        return;
      }
      case SearchAssignmentAction.Archive: {
        await this.props.archive(id);
        return;
      }
      case SearchAssignmentAction.Cancel: {
        await this.props.saveSearchAssignment({
          ...searchAssignment,
          assignmentPhase: AssignmentPhase.Completed,
        });
        return;
      }
      case SearchAssignmentAction.Publish: {
        const url = route(SEARCHASSIGNMENTROUTES.PUBLISH.URI, {
          id,
        });
        this.props.navigate(url);
        return;
      }
      case SearchAssignmentAction.Remove: {
        await this.props.remove(id);
        return;
      }
      case SearchAssignmentAction.UnArchive: {
        await this.props.unArchive(id);
        return;
      }
      case SearchAssignmentAction.Email: {
        this.props.mailSelection(
          this.state.selectedAssignments,
          searchAssignment.linkedRelations
        );
        this.clearSelectedAssignments();
        return;
      }
      case SearchAssignmentAction.ClearSelection: {
        this.clearSelectedAssignments();
        return;
      }
      default: {
        return;
      }
    }
  }

  private clearSelectedAssignments() {
    this.setState({ selectedAssignments: [] });
  }

  private toggleSelectedAssignment(assignment: EmailAssignment) {
    const ref = this.state.selectedAssignments.find(
      (selectedAssignment) => selectedAssignment.id === assignment.id
    );
    if (!!ref) {
      const selectedAssignments = this.state.selectedAssignments.filter(
        (selectedAssignment) => selectedAssignment.id !== assignment.id
      );
      this.setState({ selectedAssignments });
    } else {
      const selectedAssignments = [
        ...this.state.selectedAssignments,
        assignment,
      ];
      this.setState({ selectedAssignments });
    }
  }
}
