import { voipCallsActions, VoipCallsActions } from "./calls.actions";
import { RelationSnapShot } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";
import { VoipDetails, VoipConversationStatus } from "@haywork/api/voip";

export interface Call {
  payload: VoipDetails;
  collapsed: boolean;
  linkedRelations: RelationSnapShot[];
  linkedRelationSearchStatus: RequestStatus;
  callStartedOn: number;
  callEndedOn: number;
  linkedCommunicationLogId: string;
}

export interface VoipCallsState {
  calls: Call[];
}

const INITIAL_STATE: VoipCallsState = {
  calls: []
};

export const voipCallsReducer = (
  state: VoipCallsState = INITIAL_STATE,
  action: VoipCallsActions
): VoipCallsState => {
  switch (action.type) {
    case voipCallsActions.AddCall: {
      const { call: incomingCall } = action;
      let matched = false;
      let calls = state.calls.map((call) => {
        if (call.payload.conversationId === incomingCall.conversationId) {
          matched = true;
          let { callStartedOn, callEndedOn } = call;

          switch (true) {
            case call.payload.status === VoipConversationStatus.Ringing &&
              [
                VoipConversationStatus.Answered,
                VoipConversationStatus.Transferred
              ].includes(incomingCall.status): {
              callStartedOn = new Date().getTime();
              break;
            }
            case [
              VoipConversationStatus.Answered,
              VoipConversationStatus.Transferred
            ].includes(call.payload.status) &&
              incomingCall.status === VoipConversationStatus.Disconnected: {
              callEndedOn = new Date().getTime();
              break;
            }
            case call.payload.status === VoipConversationStatus.Ringing &&
              incomingCall.status === VoipConversationStatus.Disconnected: {
              return null;
            }
            default:
              break;
          }

          return {
            ...call,
            payload: incomingCall,
            callStartedOn,
            callEndedOn
          };
        }
        return call;
      });

      calls = calls.filter((call) => !!call);

      if (!matched && [VoipConversationStatus.Ringing, VoipConversationStatus.Transferred].includes(incomingCall.status)) {
        const call: Call = {
          payload: incomingCall,
          collapsed: false,
          linkedRelations: [],
          linkedRelationSearchStatus: RequestStatus.Idle,
          callStartedOn: null,
          callEndedOn: null,
          linkedCommunicationLogId: null
        };
        calls = [...calls, call];
      }

      return {
        ...state,
        calls
      };
    }
    case voipCallsActions.ToggleCollapsed: {
      const { call: incomingCall } = action;
      const calls = state.calls.map((call) => {
        if (
          call.payload.conversationId === incomingCall.payload.conversationId
        ) {
          return {
            ...call,
            collapsed: !call.collapsed
          };
        }
        return call;
      });

      return {
        ...state,
        calls
      };
    }
    case voipCallsActions.RemoveCall: {
      const { call: incomingCall } = action;
      const calls = state.calls.filter(
        (call) =>
          call.payload.conversationId !== incomingCall.payload.conversationId
      );

      return {
        ...state,
        calls
      };
    }
    case voipCallsActions.SetLinkedRelations: {
      const { conversationId, linkedRelations } = action;
      const calls = state.calls.map((call) => {
        if (call.payload.conversationId === conversationId) {
          return {
            ...call,
            linkedRelations,
            linkedRelationSearchStatus: RequestStatus.Success
          };
        }
        return call;
      });

      return {
        ...state,
        calls
      };
    }
    case voipCallsActions.SetLinkedCommunicationLog: {
      const { linkedCommunicationLogId, conversationId } = action;

      const calls = state.calls.map((call) => {
        if (call.payload.conversationId === conversationId) {
          return {
            ...call,
            linkedCommunicationLogId
          };
        }
        return call;
      });

      return {
        ...state,
        calls
      };
    }
    case voipCallsActions.SetLinkedRelationSearchStatus: {
      const { linkedRelationSearchStatus, conversationId } = action;

      const calls = state.calls.map((call) => {
        if (call.payload.conversationId === conversationId) {
          return {
            ...call,
            linkedRelationSearchStatus
          };
        }
        return call;
      });

      return {
        ...state,
        calls
      };
    }
    default: {
      return state;
    }
  }
};
