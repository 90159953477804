import * as React from "react";
import {
  memo,
  FC,
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback
} from "react";
import { Input } from "@haywork/modules/form";
import { v4 as uuid } from "uuid";
import { FilterContext } from "../filter/filter.component";
import * as CSSModules from "react-css-modules";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

type Props = {
  title: string;
  name: string;
  value: any;
};

export const RadioComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, name, title, value: passedValue }) => {
      const id = useMemo(() => uuid(), []);
      const { updateField, registerField, unRegisterField } = useContext(
        FilterContext
      );
      const [value, setValue] = useState(passedValue);

      const handleOnChange = useCallback(
        (value: any) => {
          updateField(name, value);
          setValue(value);
        },
        [setValue, updateField, name]
      );

      useEffect(() => {
        registerField(name, passedValue);
        return () => unRegisterField(name);
      }, [name, registerField, unRegisterField]);

      return (
        <div styleName="checkbox-filter">
          <div styleName="title">
            <I18n value={title} />
          </div>

          <Input.RadioGroup
            name={id}
            value={value}
            asSingleInput
            onChange={handleOnChange}
          >
            {children}
          </Input.RadioGroup>
        </div>
      );
    }
  )
);
