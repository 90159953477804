import { MapDispatchToProps } from "@haywork/middleware";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  LocationRangeComponent,
  LocationRangeComponentProps,
} from "./location-range";
import { ExtendedLocationSuggestionItem } from "@haywork/middleware/thunk/mls/list";

type StateProps = {};
type DispatchProps = {
  querySuggestions: (term: string) => Promise<ExtendedLocationSuggestionItem[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  LocationRangeComponentProps,
  AppState
> = (state, ownProps) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  LocationRangeComponentProps
> = (dispatch) => ({
  querySuggestions: (term: string) =>
    dispatch(MLSThunk.List.queryLocationSuggestions(term)),
});

export type LocationRangeContainerProps = StateProps & DispatchProps;
const LocationRangeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRangeComponent);

export default LocationRangeContainer;
