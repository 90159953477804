import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  MatchingPropertiesOnAddressComponent,
  MatchingPropertiesOnAddressComponentProps,
} from "./matching-properties-on-address";
import { push } from "connected-react-router";
import {
  RealEstatePropertySearchItem,
  RealEstateProperty,
} from "@haywork/api/mls";
import { MLSThunk } from "@haywork/middleware/thunk/mls";
import { Address } from "@haywork/api/kolibri";

type StateProps = {
  mlsIsActive: boolean;
};
type DispatchProps = {
  navigate: (path: string) => void;
  getMlsSuggestions: (
    address: Address
  ) => Promise<RealEstatePropertySearchItem[]>;
  getMlsProperty: (id: string, source: string) => Promise<RealEstateProperty>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  MatchingPropertiesOnAddressComponentProps,
  AppState
> = (state) => {
  const mlsIsActive = state.mls.main?.mlsAccessData?.isActive;

  return {
    mlsIsActive,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  MatchingPropertiesOnAddressComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  getMlsSuggestions: (address: Address) =>
    dispatch(
      MLSThunk.List.getAddressSuggestions(undefined, undefined, address)
    ),
  getMlsProperty: (id: string, source: string) =>
    dispatch(MLSThunk.Single.getRealEstateProperty(id, source)),
});

export type MatchingPropertiesOnAddressContainerProps = StateProps &
  DispatchProps;
export const MatchingPropertiesOnAddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchingPropertiesOnAddressComponent);
