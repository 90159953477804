import { BidStatus, TimelineEvent } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { useIntl } from "react-intl";

const styles = require("./style.scss");

type Props = {
  event: TimelineEvent;
  parentId: string;
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const BidItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ event, parentId, onNavigate, onShowAllLinkedEntities }) => {
      const {
        id,
        linkedAssignments,
        linkedRelations,
        dateTimeCreated,
        price,
        bidStatus,
        bidValidUntil,
        deniedDateTime,
        acceptedDateTime,
        bidDateTime,
        dateTimeModified
      } = event;

      const intl = useIntl();
      const dateField = useMemo(
        () =>
          !!dateTimeCreated ? (
            <div styleName="date">
              {moment(dateTimeCreated).format("DD MMMM YYYY HH:mm")}
            </div>
          ) : null,
        []
      );

      const icon = useMemo(() => {
        switch (bidStatus) {
          case BidStatus.Active:
            return "comments-dollar";
          case BidStatus.Accepted:
            return "thumbs-up";
          case BidStatus.Denied:
            return "thumbs-down";
          default:
            return "comments-dollar";
        }
      }, [bidStatus]);

      const bidDate = useMemo(() => {
        let date = new Date();
        switch (bidStatus) {
          case BidStatus.Accepted: {
            date = acceptedDateTime || dateTimeModified;
            break;
          }
          case BidStatus.Active: {
            date = bidDateTime || dateTimeModified;
            break;
          }
          case BidStatus.Denied: {
            date = deniedDateTime || dateTimeModified;
            break;
          }
          case BidStatus.Expired: {
            date = bidValidUntil || dateTimeModified;
            break;
          }
          default:
            break;
        }

        return moment(date).format("DD MMMM YYYY HH:mm");
      }, [
        bidStatus,
        bidValidUntil,
        deniedDateTime,
        acceptedDateTime,
        bidDateTime,
        dateTimeModified
      ]);

      return (
        <div styleName="event__item">
          <div styleName="event__icon">
            <Icon name={icon} light size={24} color={Colors.Gray} />
          </div>
          <div styleName="event__divider"></div>
          <div styleName="event__data">
            <div styleName="subject">
              <I18n
                value="timeline.bid.subject"
                values={{
                  price: intl.formatNumber(price || 0, {
                    style: "currency",
                    currency: "EUR"
                  }),
                  status: intl.formatMessage({
                    id: `bidStatus.${bidStatus.toString()}`,
                    defaultMessage: bidStatus.toString()
                  }),
                  date: bidDate
                }}
              />
            </div>
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          <div styleName="event__date">{dateField}</div>
          <div styleName="event__actions"></div>
        </div>
      );
    }
  )
);

export default BidItemComponent;
