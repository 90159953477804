import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";

import { ResourceText } from "@haywork/modules/shared";
import { SaleOffer, AvailabilityStatus } from "@haywork/api/kolibri";

const styles = require("./widgets.component.scss");

interface Props {
  saleOffer: SaleOffer;
  availability: AvailabilityStatus;
  onRetractSaleOffer: () => void;
}

export const RetractSaleData = CSSModules(styles, { allowMultiple: true })(
  ({ saleOffer, onRetractSaleOffer, availability }: Props) => {
    if (
      availability !== AvailabilityStatus.Available ||
      !saleOffer ||
      !saleOffer.dateSold ||
      !saleOffer.dateTransfer
    )
      return null;

    return (
      <div styleName="widget retract-sale-data">
        <h2 styleName="title">
          <ResourceText resourceKey="assignment.RetractSaleDataTitle" />
        </h2>
        <div styleName="retract-sale-data__dates">
          {!!saleOffer.dateReservation && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractSaleData.dateReservation" />
              </h3>
              <FormattedDate
                value={saleOffer.dateReservation}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!saleOffer.dateSold && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractSaleData.dateSold" />
              </h3>
              <FormattedDate
                value={saleOffer.dateSold}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!saleOffer.endDateBankWarranty && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractSaleData.endDateBankWarranty" />
              </h3>
              <FormattedDate
                value={saleOffer.endDateBankWarranty}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
          {!!saleOffer.dateTransfer && (
            <div styleName="date">
              <h3>
                <ResourceText resourceKey="assignment.RetractSaleData.dateTransfer" />
              </h3>
              <FormattedDate
                value={saleOffer.dateTransfer}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          )}
        </div>

        <button
          className="btn btn-primary"
          type="button"
          onClick={onRetractSaleOffer}
        >
          <ResourceText resourceKey="assignment.RetractSaleData" />
        </button>
      </div>
    );
  }
);
