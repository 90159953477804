import * as React from "react";
import { memo, ReactNode, useMemo, MouseEvent } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import I18n from "../../i18n";

const styles = require("./style.scss");

interface Props {
  label: string;
  labelValues?: { [key: string]: string };
  secondaryLabel?: string;
  secondaryLabelValues?: { [key: string]: string };
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}
export const UiActionListItemComponent = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      label,
      labelValues,
      icon,
      disabled,
      secondaryLabel,
      secondaryLabelValues,
      onClick,
    }: Props) => {
      const clickable = useMemo(
        () => !!onClick && typeof onClick === "function",
        [onClick]
      );

      const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
        if (clickable) {
          event.stopPropagation();
          onClick();
        }
      };

      return (
        <div
          onClick={onClickHandler}
          styleName={classNames("action-list-item", { disabled, clickable })}
        >
          {!!icon && <div styleName="action-list-item__icon">{icon}</div>}
          <div styleName="action-list-item__label">
            <div styleName="primary">
              <I18n value={label} values={labelValues} />
            </div>
            {!!secondaryLabel && (
              <div styleName="secondary">
                <I18n value={secondaryLabel} values={secondaryLabelValues} />
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
