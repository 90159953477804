import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { EXTERNALROUTES } from "@haywork/constants";

const styles = require("./account-modal.component.scss");

interface Props {
  visible: boolean;
  onClose: () => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class EmailAccountModalComponent extends React.Component<Props, State> {
  public render() {
    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="emailAccountAddedModalTitle" close />

        <ModalBody>
          <div styleName="account-modal__body">
            <ResourceText
              resourceKey="emailAccountAddedModalBody"
              values={{ url: EXTERNALROUTES.EMAIL_PROVIDER_VIDEO.URI }}
              asHtml
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            data-cy="CY-getToWorkButton"
            className="btn btn-primary"
            onClick={this.props.onClose}
          >
            <ResourceText resourceKey="getToWork" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
