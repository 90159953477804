import * as React from "react";

const COMPONENT_ID_PREFIX = "sheduler-";
// Extended by default scheduler options interface.
interface ISchedulerControlProps extends kendo.ui.SchedulerOptions {
  uniqueControlId: string;
  onInitialized?: (scheduler: kendo.ui.Scheduler) => any;
  singleClickEvent?: (e: any) => void;
}

interface ISchedulerControlState {
  componentFullId: string;
  scheduler: any;
}

const styles = require("./scheduler-control.scss");

class SchedulerControl extends React.Component<
  ISchedulerControlProps,
  ISchedulerControlState
> {
  constructor(props: ISchedulerControlProps) {
    super(props);
    this.state = {
      componentFullId: COMPONENT_ID_PREFIX + this.props.uniqueControlId,
      scheduler: null,
    };
  }

  public componentDidMount(): void {
    kendo.culture("nl-NL");
    kendo.culture().calendar.firstDay = 1;
    const scheduler = $("#" + this.state.componentFullId)
      .kendoScheduler(this.props)
      .data("kendoScheduler");
    this.props.onInitialized(scheduler);
    this.setState({ ...this.state, scheduler });
    scheduler.wrapper.on(
      "mouseup touchend",
      ".k-scheduler-table td, .k-event",
      (e) => {
        if (this.props.singleClickEvent) {
          const target = $(e.currentTarget);

          if (target.hasClass("k-event")) {
            const agendaItemEvent = scheduler.occurrenceByUid(
              target.data("uid")
            );
            if (agendaItemEvent) {
              this.props.singleClickEvent(agendaItemEvent.id);
            }
          }
        }
      }
    );

    this.setWeekNumbersOnCalender(scheduler);
  }

  public shouldComponentUpdate(nextProps, nextState) {
    if (this.state.componentFullId !== nextState.componentFullId) {
      return true;
    }
    return false;
  }

  public componentWillUnmount() {
    // Sometimes it happens that destroy process fires in a middle of internal scheduler processes
    // like when we redirect to edit screen on dblclick on agenda item, so when the scheduler
    // unexpectedly destroyed the code crashes. So to prevent such behaviour we move destroying from
    // the main flow to give an ability to the scheduler to finish all it's internal processes.
    // if (this.state.scheduler) {
    //   this.state.scheduler.destroy();
    // }
  }

  public render(): JSX.Element {
    return (
      <div className={styles.schedulerControl} data-cy="CY-schedulerControl">
        <div className={styles.scheduler} id={this.state.componentFullId} />
      </div>
    );
  }

  private setWeekNumbersOnCalender(scheduler: any) {
    // Work-around supplied by Telerik to add week numbers to the calender...
    // https://www.telerik.com/account/support-tickets/view-ticket?threadid=1167983
    scheduler._showCalendar();
    $(".k-animation-container").css("display", "none");
    scheduler.popup.close();
    const calendar = scheduler.calendar;
    calendar.setOptions({ weekNumber: true });
  }
}

export default SchedulerControl;
