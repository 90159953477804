import { ReadRealEstatePropertyResponse } from "@haywork/api/mls";

export enum ActionType {
  SetSingle = "MLS.Single.SetSingle",
  SetSingleStatus = "MLS.Single.SetSingleStatus",
  ResetApp = "RESET_APP",
}

type SetSingle = {
  type: ActionType.SetSingle;
  realEstateResponse: ReadRealEstatePropertyResponse;
};

const setSingle = (realEstateResponse: ReadRealEstatePropertyResponse) => ({
  type: ActionType.SetSingle,
  realEstateResponse,
});

type SetSingleStatus = {
  type: ActionType.SetSingleStatus;
  status: string;
};

const setSingleStatus = (status: string) => ({
  type: ActionType.SetSingleStatus,
  status,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes = SetSingle | SetSingleStatus | ResetApp;
export const actions = {
  setSingle,
  setSingleStatus,
};
