import * as React from "react";
import * as CSSModules from "react-css-modules";

import { ResourceText } from "@haywork/modules/shared";
import { AppXchangePartnerWidget } from "@haywork/modules/app-xchange";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { AppClientSnapShot } from "@haywork/api/authorization";

const styles = require("./about.component.scss");

interface Props {
  partners: AppClientSnapShot[];
  onOpenPartner: (client: AppClientSnapShot) => void;
}

export const Related = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    if (!props.partners || !props.partners.length) return null;

    return (
      <React.Fragment>
        <h2 styleName="section-title">
          <ResourceText resourceKey="appXchangeRelated" />
        </h2>
        <div styleName="related">
          <div styleName="related__wrapper">
            {props.partners.map((partner) => (
              <ErrorBoundary key={partner.id}>
                <AppXchangePartnerWidget
                  partner={partner}
                  onClick={(partner) => props.onOpenPartner(partner)}
                />
              </ErrorBoundary>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
);
