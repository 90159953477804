import { MatchedPropertySnapShot } from "@haywork/api/kolibri";
import { Dispatch, SearchAssignmentThunks } from "@haywork/middleware";
import {
  SearchAssignmentMatchingPropertiesModalComponent,
  SearchAssignmentMatchingPropertiesModalComponentProps
} from "@haywork/modules/search-assignment";
import { AppState } from "@haywork/stores";
import { SearchAssignmentActions } from "@haywork/stores/search-assignment";
import { connect } from "react-redux";

interface StateProps {
  modalMatchingProperties: MatchedPropertySnapShot[];
  modalMatchingPropertiesState: string;
}
interface DispatchProps {
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) => void;
  clearModalMatchingProperties: () => void;
}

const mapStateToProps = <
  StateProps,
  SearchAssignmentMatchingPropertiesModalComponentProps
>(
  state: AppState
) => {
  const {
    modalMatchingProperties,
    modalMatchingPropertiesState
  } = state.searchAssignment.single;

  return {
    modalMatchingProperties,
    modalMatchingPropertiesState
  };
};

const mapDispatchToProps = <
  DispatchProps,
  SearchAssignmentMatchingPropertiesModalComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) =>
    dispatch(
      SearchAssignmentThunks.getMatchMailProperties(
        objectAssignmentIds,
        searchAssignmentId
      )
    ),
  clearModalMatchingProperties: () =>
    dispatch(SearchAssignmentActions.clearModalMatchingProperties())
});

export type SearchAssignmentMatchingPropertiesModalContainerProps = StateProps &
  DispatchProps;
export const SearchAssignmentMatchingPropertiesModalContainer = connect<
  StateProps,
  DispatchProps,
  SearchAssignmentMatchingPropertiesModalComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchAssignmentMatchingPropertiesModalComponent);
