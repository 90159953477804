import { Phrase } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import isEqual from "lodash-es/isEqual";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const value = FormControlUtil.returnObjectPathOrNull;
const styles = require("./edit-modal.component.scss");

interface EditModalComponentProps {
  visible: boolean;
  phrase: Phrase;
  phraseStatus: string;
  onClose: () => void;
  onUpdatedPhrase: (phrase: Phrase) => void;
  onAddPhrase: (values: FormReturnValue) => void;
}
interface EditModalComponentState {
  submitting: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class EditModalComponent extends React.Component<
  EditModalComponentProps,
  EditModalComponentState
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);

    this.state = {
      submitting: false,
    };

    this.formControls = {
      title: {
        value: value(this.props.phrase, "title"),
        validators: [Validators.required()],
      },
      description: {
        value: value(this.props.phrase, "description"),
        validators: [Validators.required()],
      },
    };
  }

  public render() {
    const title = !!this.props.phrase ? "editPhrase" : "addPhrase";
    const titleValues = this.props.phrase || {};

    return (
      <Modal visible={this.props.visible} onClose={this.onCloseHandler}>
        <ModalHeader title={title} titleValues={titleValues} close />
        <ModalBody>
          <Form
            name="phrase"
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
            onSubmit={this.onSubmitHandler}
          >
            <div className="form__row">
              <label htmlFor="title">
                <ResourceText resourceKey="phraseTitle" />
              </label>
              <Input.Text name="title" disabled={this.state.submitting} />
            </div>

            <div className="form__row">
              <label htmlFor="description">
                <ResourceText resourceKey="phraseDescription" />
              </label>
              <Input.Textarea
                name="description"
                autoSize
                disabled={this.state.submitting}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-success" onClick={this.onSubmitClickHandler}>
            <ResourceText resourceKey="savePhrase" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: EditModalComponentProps) {
    if (!nextProps) return;

    // Have we received a new phrase
    if (!!this.formRef && !isEqual(nextProps.phrase, this.props.phrase)) {
      // Do we need to empty the form
      if (!nextProps.phrase) {
        this.formRef.update({
          title: "",
          description: "",
        });
      } else {
        const { title, description } = nextProps.phrase;
        this.formRef.update({
          title: title || "",
          description: description || "",
        });
      }
    }

    if (
      !!nextProps.phraseStatus &&
      nextProps.phraseStatus !== REQUEST.PENDING &&
      nextProps.phraseStatus !== this.props.phraseStatus &&
      this.state.submitting
    ) {
      if (nextProps.phraseStatus === REQUEST.SUCCESS) {
        this.props.onClose();
      }
      this.setState({ submitting: false });
    }
  }

  private onCloseHandler() {
    if (this.state.submitting) return;
    this.props.onClose();
  }

  private onSubmitClickHandler() {
    if (!this.formRef || this.state.submitting) return;
    this.formRef.submit();
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.setState({ submitting: true });
    if (!!this.props.phrase) {
      const { id, categoryId } = this.props.phrase;
      this.props.onUpdatedPhrase({
        ...this.props.phrase,
        title: values.title,
        description: values.description,
      });
    } else {
      this.props.onAddPhrase(values);
    }
  }
}
