export interface RgbObject {
  red: number;
  green: number;
  blue: number;
}

export class Color {
  public static hexToRgb(hex: string): string {
    if (!hex || hex.length === 0) return "";

    let ref: string = hex;
    if (hex.length === 9) {
      ref = `#${hex.slice(3)}`;
      const alpha = hex.slice(1, 3);
    }

    /*const replaced = ref.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (r, g, b) => '#' + r + r + g + g + b + b);
    const sub = replaced.substring(0).match(/.{2}/g);
    const final = sub.map(x => parseInt(x, 16));*/

    return ref; // `rgb(${final[0]},${final[1]},${final[2]})`;
  }

  public static rgbToHex(rbg: string): string {
    if (!rbg || rbg.length === 0) return "";
    let ref: string = rbg;

    if (rbg.length === 7) {
      ref = ref.slice(1, 7);
      ref = "#ff" + ref;
    }

    return ref;
  }

  public static rgbObjectToHex(rgb: RgbObject): string {
    if (!rgb) return "";
    return (
      "#" +
      Color.componentToHex(rgb.red) +
      Color.componentToHex(rgb.green) +
      Color.componentToHex(rgb.blue)
    );
  }

  public static hexToRgbObject(hex: string): RgbObject {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          red: parseInt(result[1], 16),
          green: parseInt(result[2], 16),
          blue: parseInt(result[3], 16)
        }
      : null;
  }

  private static componentToHex(channel: number): string {
    const hex = channel.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
}
