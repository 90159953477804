import { intlContext } from "@haywork/app";
import { NumberUtil } from "@haywork/util";
import classNames from "classnames";
import isNaN from "lodash-es/isNaN";
import * as React from "react";
import { FormattedNumber } from "react-intl";
import { InputComponentProps } from "../input.component";

interface NumberComponentProps {
  icon?: string;
  large?: boolean;
  placeholder?: string;
  step?: number;
  showActions?: boolean;
  min?: number;
  max?: number;
  pretty?: boolean;
  asPrice?: boolean;
  round?: boolean;
  blockedStyle?: boolean;
  maxLength?: number;
}
interface NumberComponentState {
  value: string;
}

export class NumberComponent extends React.PureComponent<
  NumberComponentProps & InputComponentProps,
  NumberComponentState
> {
  private ref: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      value: this.parseValue(this.props.value),
    };

    this.onAddClickHandler = this.onAddClickHandler.bind(this);
    this.onSubtractClickHandler = this.onSubtractClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
  }

  public render() {
    let { min, max, step } = this.props;
    const { icon, large, showActions, name, pretty } = this.props;
    const numberInputClass = classNames("input__number", {
      "has-icon": icon,
      large,
      "has-actions": showActions,
      pretty,
    });
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const { value } = this.state;

    min = min === undefined ? 0 : min;
    max = min === undefined ? undefined : max;
    step = step || 1;

    return (
      <div className={numberInputClass}>
        {this.renderFauxValue()}
        <div className="input__number-wrapper">
          <input
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            value={value}
            onBlur={this.onBlurHandler}
            onChange={this.onChangeHandler}
            disabled={this.props.disabled}
            readOnly={this.props.readOnly}
            onKeyDown={this.onKeyDownHandler}
            onFocus={this.props.onFocus}
            ref={this.bindRef}
            data-cy={this.props["data-cy"]}
            autoComplete="off"
            data-lpignore="true"
            maxLength={this.props.maxLength}
          />
        </div>
        {showActions && (
          <div className="number__triggers">
            <div
              className="number__trigger"
              onClick={this.onAddClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.AddButton`
              }
            >
              <i className="fal fa-fw fa-plus faIcon" />
            </div>
            <div
              className="number__trigger"
              onClick={this.onSubtractClickHandler}
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.SubtractButton`
              }
            >
              <i className="fal fa-fw fa-times faIcon" />
            </div>
          </div>
        )}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: NumberComponentProps & InputComponentProps
  ) {
    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }

    let value = this.parseValue(nextProps.value);

    if (this.state.value !== value) {
      if (this.props.round && !!value) {
        value = Math.round(parseFloat(value));
      }
      this.setState({ value });
    }
    if (!!nextProps.focus && !!this.ref) {
      this.ref.focus();
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER = 13;
    switch (event.keyCode) {
      case ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const match = /^[\d\.\,\-]+$/g;

    if (value !== "" && !match.test(value)) return;
    this.setState({ value });
    if (!!this.props.fireAllChanges) {
      const trimmedValue =
        !!value && !this.props.disableTrim ? `${value}`.trim() : value;
      this.props.onChange(trimmedValue);
    }
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    let { value } = this.state;
    if (!!value && !this.props.disableTrim) value = `${value}`.trim();
    this.props.onChange(this.renderValue(value));
    this.props.onBlur(event);
  }

  private onAddClickHandler() {
    const initialValue = this.props.value || 0;
    const raw = parseFloat(initialValue) + (this.props.step || 1);
    const value = this.renderValue(raw.toString());
    this.props.onChange(value);
    this.setState({ value });
  }

  private onSubtractClickHandler() {
    const initialValue = this.props.value || 0;
    const raw = parseFloat(initialValue) - (this.props.step || 1);
    const value = this.renderValue(raw.toString());
    this.props.onChange(value);
    this.setState({ value });
  }

  private renderValue(val: string): string {
    if (val === "") return "";
    let parsedNumber = NumberUtil.parseString(val);
    if (isNaN(parsedNumber)) return "";

    if (this.props.min !== undefined && parsedNumber < this.props.min)
      parsedNumber = this.props.min;
    if (this.props.max !== undefined && parsedNumber > this.props.max)
      parsedNumber = this.props.max;
    if (this.props.min === undefined && parsedNumber < 0) parsedNumber = 0;
    if (this.props.round) parsedNumber = Math.round(parsedNumber);

    return parsedNumber.toString();
  }

  private renderFauxValue(): React.ReactElement<HTMLDivElement> {
    if (!this.props.pretty || this.state.value === "") return null;
    const fauxStyle = classNames("input__number-faux", {
      blocked: this.props.blockedStyle,
    });
    const value =
      ["", null, undefined].indexOf(this.state.value) !== -1 ||
      isNaN(parseFloat(this.state.value))
        ? 0
        : parseFloat(this.state.value);

    return (
      <div className={fauxStyle}>
        <FormattedNumber
          value={value}
          style="decimal"
          minimumFractionDigits={2}
          maximumFractionDigits={this.props.asPrice ? 2 : 20}
        />
      </div>
    );
  }

  private bindRef(ref: HTMLInputElement) {
    if (!!ref && !this.ref) {
      this.ref = ref;
      if (this.props.shouldFocusOnError) ref.focus();
    }
  }

  private parseValue(value) {
    return !isNaN(value)
      ? [null, undefined, NaN].indexOf(value) === -1
        ? value
        : ""
      : "";
  }
}
