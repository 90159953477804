import {
  AppClient,
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { APPXCHANGEROUTES } from "@haywork/constants";
import {
  AppXchangeConsentModal,
  AppXchangeDetailContainerProps,
} from "@haywork/modules/app-xchange";
import {
  AuthorizationWidgets,
  ConfirmComponent,
  ResourceText,
} from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import head from "lodash-es/head";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import { AppXchangeDetailRouting } from "./detail.routing";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface AppXchangeDetailComponentProps {}
interface State {
  consentGiven: boolean;
  visual: string;
}
type Props = AppXchangeDetailComponentProps & AppXchangeDetailContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeDetailComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      consentGiven: false,
      visual: null,
    };

    this.onAllowConsent = this.onAllowConsent.bind(this);
    this.onRetractConsent = this.onRetractConsent.bind(this);
    this.hideConsentGivenInfo = this.hideConsentGivenInfo.bind(this);
  }

  public componentDidMount() {
    if (!this.props.client || !has(this.props.client, "displayName")) return;

    const companySettingsId = get(
      this.props.companySettings,
      "linkedAppClient.id"
    );
    const employeeSettingsId = get(
      this.props.employeeSettings,
      "linkedAppClient.id"
    );

    if (
      !!this.props.client.linkedConsent &&
      ((!companySettingsId && !employeeSettingsId) ||
        (!!companySettingsId && companySettingsId !== this.props.client.id) ||
        (!!employeeSettingsId && employeeSettingsId !== this.props.client.id))
    ) {
      this.props.getSettings(this.props.client.id);
    }

    const { displayName, translations } = this.props.client;
    this.props.setTabTitle(displayName);

    let translation = (translations || []).find(
      (translation) => translation.languageCultureName === this.props.culture
    );
    translation = translation || head(translations || []);

    if (!!translation) {
      this.setState({ visual: translation.logoUrl || "" });
    }
  }

  public render() {
    if (!this.props.client) return null;
    const { id } = this.props.client;

    return (
      <div styleName="detail">
        <div styleName="detail__sidebar">
          <div
            styleName="visual"
            style={{
              backgroundImage: !!this.state.visual
                ? `url(${this.state.visual})`
                : null,
            }}
          >
            {!this.state.visual && (
              <div styleName="inner">
                <i className="fal fa-rocket" />
              </div>
            )}
          </div>
          <div styleName="menu">
            <NavLink
              styleName="item"
              activeClassName="active"
              to={route(APPXCHANGEROUTES.ABOUT.URI, { id })}
            >
              <i className="fal fa-fw fa-rocket" />
              <span styleName="text">
                <ResourceText resourceKey="appXchangeDetailRouteAbout" />
              </span>
            </NavLink>
            <NavLink
              styleName="item"
              activeClassName="active"
              to={route(APPXCHANGEROUTES.REVIEWS.URI, { id })}
            >
              <i className="fal fa-fw fa-star" />
              <span styleName="text">
                <ResourceText resourceKey="appXchangeDetailRouteReviews" />
              </span>
            </NavLink>
            <NavLink
              styleName="item"
              activeClassName="active"
              to={route(APPXCHANGEROUTES.COSTS.URI, { id })}
            >
              <i className="fal fa-fw fa-euro-sign" />
              <span styleName="text">
                <ResourceText resourceKey="appXchangeDetailRouteCosts" />
              </span>
            </NavLink>
            <NavLink
              styleName="item"
              activeClassName="active"
              to={route(APPXCHANGEROUTES.SUPPORT.URI, { id })}
            >
              <i className="fa fa-fw fa-question" />
              <span styleName="text">
                <ResourceText resourceKey="appXchangeDetailRouteSupport" />
              </span>
            </NavLink>
            {!!get(this.props.companySettings, "fields", []).length && (
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(APPXCHANGEROUTES.COMPANY_SETTINGS.URI, { id })}
              >
                <i className="fal fa-fw fa-cog" />
                <span styleName="text">
                  <ResourceText resourceKey="appXchangeDetailRouteCompanySettings" />
                </span>
              </NavLink>
            )}
            {!!get(this.props.employeeSettings, "fields", []).length && (
              <NavLink
                styleName="item"
                activeClassName="active"
                to={route(APPXCHANGEROUTES.EMPLOYEE_SETTINGS.URI, { id })}
              >
                <i className="fal fa-fw fa-cogs" />
                <span styleName="text">
                  <ResourceText resourceKey="appXchangeDetailRouteEmployeeSettings" />
                </span>
              </NavLink>
            )}
            <AuthorizationWidgets
              location={WidgetEntityLocation.Menu}
              type={WidgetEntityType.AppXchange}
              menuType="menu-button"
              id={id}
            />
          </div>
        </div>

        <div styleName="detail__body">
          <AppXchangeDetailRouting
            id={id}
            consentGiven={this.state.consentGiven}
            hideConsentGivenInfo={this.hideConsentGivenInfo}
          />
        </div>

        {!this.props.client.isFirstParty && (
          <React.Fragment>
            <AppXchangeConsentModal
              client={this.props.client}
              translation={this.props.translation}
              visible={this.props.consentModalVisible}
              consentStatus={this.props.consentStatus}
              culture={this.props.culture}
              getPermissionsForClient={this.props.getPermissionsForClient}
              onAllow={this.onAllowConsent}
              onCancel={() => this.props.toggleConsentModal(false)}
            />

            <ConfirmComponent
              visible={this.props.retractConsentConfirm}
              titleResourceKey="retractConsentConfirmTitle"
              bodyResourceKey="retractConsentConfirmMessage"
              bodyValues={{ displayName: this.props.client.displayName }}
              onClose={() => this.props.toggleConsentRetractConfirm(false)}
              onConfirm={this.onRetractConsent}
            />
          </React.Fragment>
        )}
      </div>
    );
  }

  private async onAllowConsent(client: AppClient) {
    try {
      await this.props.toggleConsent(client.id, true);
      this.props.toggleConsentModal(false);
      this.setState({ consentGiven: true });
    } catch (error) {
      throw error;
    }
  }

  private async onRetractConsent() {
    try {
      this.props.toggleConsentRetractConfirm(false);
      await this.props.toggleConsent(this.props.client.id, false);
      this.setState({ consentGiven: false });
    } catch (error) {
      throw error;
    }
  }

  private hideConsentGivenInfo() {
    this.setState({ consentGiven: false });
  }
}
