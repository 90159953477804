import { PhotoBlob } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { REALESTATEAGENCYROUTES } from "@haywork/constants";
import {
  AvatarUploadTriggerComponent,
  Form,
  FormControls,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import {
  RealEstateAgencyDetailContainerProps,
  RealEstateAgencyDetailRouting,
} from "@haywork/modules/real-estate-agency";
import { ObjectTimestamps, ResourceText } from "@haywork/modules/shared";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

export interface RealEstateAgencyDetailComponentProps {}
type Props = RealEstateAgencyDetailComponentProps &
  RealEstateAgencyDetailContainerProps;

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;
const value = FormControlUtil.returnObjectPathOrNull;

@CSSModules(styles, { allowMultiple: true })
export class RealEstateAgencyDetailComponent extends React.Component<Props> {
  private formControls: FormControls;

  constructor(props) {
    super(props);

    this.logoChange = this.logoChange.bind(this);

    this.formControls = {
      logo: { value: value(this.props.realEstateAgency, "logo") },
    };
  }

  public render() {
    const { realEstateAgency } = this.props;

    return (
      <div styleName="detail">
        <div styleName="sideBar">
          <div styleName="logo" className="hidden-xs hidden-sm">
            <Form
              name="relationDetailLogo"
              formControls={this.formControls}
              onChange={this.logoChange}
            >
              <Input.Avatar name="logo">
                <AvatarUploadTriggerComponent customText="fileUploadLogo" />
              </Input.Avatar>
            </Form>
          </div>

          <div styleName="menu">
            {this.renderMenuLink(
              route(REALESTATEAGENCYROUTES.EDIT.URI, {
                id: realEstateAgency.id,
              }),
              "fa-user-circle",
              "tabGeneral",
              true
            )}
            {this.renderMenuLink(
              route(REALESTATEAGENCYROUTES.DOSSIER.URI, {
                id: realEstateAgency.id,
              }),
              "fal fa-fw fa-archive",
              "tabDossier"
            )}
          </div>

          <ObjectTimestamps
            linkedCreatedBy={realEstateAgency.linkedCreatedBy}
            linkedModifiedBy={realEstateAgency.linkedModifiedBy}
            dateTimeCreated={realEstateAgency.dateTimeCreated}
            dateTimeModified={realEstateAgency.dateTimeModified}
          />
        </div>

        <div styleName="body">
          <div styleName="body__routing">
            <RealEstateAgencyDetailRouting id={realEstateAgency.id} />
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.props.setTabTitle(
      intlContext.formatMessage({
        id: "realEstateAgency",
        defaultMessage: "realEstateAgency",
      })
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;
    if (!!nextProps.preppedForSave && !this.props.preppedForSave) {
      this.props.save(this.props.realEstateAgency, this.props.path);
    }
  }

  private renderMenuLink(
    path: string,
    icon: string,
    label: string,
    overrideDisabled: boolean = false
  ) {
    const iconStyle = classNames("fal fa-fw", icon);

    return (
      <NavLink styleName="item" activeClassName="active" to={path}>
        <i className={iconStyle} />
        <span styleName="text">
          <ResourceText resourceKey={label} />
        </span>
      </NavLink>
    );
  }

  private logoChange(values: FormReturnValue) {
    const avatar: PhotoBlob = values.logo;
    this.props.updateLogo(
      avatar ? avatar : null,
      this.props.realEstateAgency.id
    );
  }

  private onEditClickHandler() {
    const url = route(REALESTATEAGENCYROUTES.EDIT.URI, {
      id: this.props.realEstateAgency.id,
    });
    this.props.navigate(url);
  }
}
