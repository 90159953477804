import { PhotoBlob } from "@haywork/api/kolibri";
import { urlToPhotoBlob } from "@haywork/util/url";
import classNames from "classnames";
import * as marked from "marked";
import * as React from "react";

interface Props {
  markdown: string | null;
  onImageClick?: (image: PhotoBlob) => void;
}

export class MarkDown extends React.PureComponent<Props> {
  private renderer = new marked.Renderer();

  constructor(props) {
    super(props);

    const linkRenderer = this.renderer.link;
    this.renderer.link = (href, title, text) => {
      const html = linkRenderer.call(this.renderer, href, title, text);
      return html.replace(/^<a /, `<a target="_blank" rel="nofollow" `);
    };

    this.onClick = this.onClick.bind(this);
  }

  public render() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: marked(this.props.markdown || "", {
            renderer: this.renderer,
          }),
        }}
        onClick={this.onClick}
        className={classNames({
          "clickable-images": !!this.props.onImageClick,
        })}
      />
    );
  }

  private onClick(event: React.MouseEvent<HTMLSpanElement>) {
    const target = event.target as HTMLImageElement;
    if (!this.props.onImageClick || target.tagName !== "IMG") return;
    this.props.onImageClick(urlToPhotoBlob(target.src));
  }
}
