import { NoteSnapShot } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";
import { NotesActions, notesActions } from "./notes.actions";

export interface NotesV3State {
  parentId: string;
  notes: NoteSnapShot[];
  totalCount: number;
  notesStatus: RequestStatus;
  notesVisible: boolean;
}

const INITIAL: NotesV3State = {
  parentId: null,
  notes: [],
  totalCount: 0,
  notesStatus: RequestStatus.Idle,
  notesVisible: false
};

export const notesV3Reducer = (
  state: NotesV3State = INITIAL,
  action: NotesActions
): NotesV3State => {
  switch (action.type) {
    case notesActions.SetParentId: {
      const { parentId } = action;
      if (parentId !== state.parentId) {
        return {
          ...INITIAL,
          parentId
        };
      } else {
        return state;
      }
    }
    case notesActions.SetNotes: {
      const { parentId, notes: appendables, totalCount, reset } = action;
      const notes = reset ? appendables : [...state.notes, ...appendables];

      return {
        ...state,
        parentId,
        notes,
        notesStatus: RequestStatus.Success,
        totalCount
      };
    }
    case notesActions.SetNotesStatus: {
      const { notesStatus } = action;

      return {
        ...state,
        notesStatus
      };
    }
    case notesActions.ToggleListVisibility: {
      const { notesVisible } = action;

      return {
        ...state,
        notesVisible
      };
    }
    default: {
      return state;
    }
  }
};
