import { FinancialAdministration } from "@haywork/api/kolibri";
import { Dispatch, FinancialThunks } from "@haywork/middleware";
import {
  AddFinancialAdministrationProductModalComponent,
  AddFinancialAdministrationProductModalComponentProps
} from "@haywork/modules/shared";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";

interface StateProps {
  financialAdministrations: FinancialAdministration[];
  financialAdministrationState: string;
}
interface DispatchProps {
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) => void;
}

const mapStateToProps = <
  StateProps,
  AddFinancialAdministrationProductModalComponentProps
>(
  state: AppState
) => {
  const {
    financialAdministrations,
    financialAdministrationState
  } = state.account;

  return {
    financialAdministrations,
    financialAdministrationState
  };
};

const mapDispatchToProps = <
  DispatchProps,
  AddFinancialAdministrationProductModalComponentProps
>(
  dispatch: Dispatch<any>
) => ({
  updateFinancialAdministration: (
    financialAdministration: FinancialAdministration
  ) =>
    dispatch(
      FinancialThunks.updateFinancialAdministration(financialAdministration)
    )
});

export type AddFinancialAdministrationProductModalContainerProps = StateProps &
  DispatchProps;
export const AddFinancialAdministrationProductModalContainer = connect<
  StateProps,
  DispatchProps,
  AddFinancialAdministrationProductModalComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(AddFinancialAdministrationProductModalComponent);
