import {
  DefaultFolderTree,
  DefaultFolderTreeCategoryOption
} from "@haywork/api/kolibri";
import { DOSSIERFOLDERROUTES } from "@haywork/constants";
import { FolderTreesThunk, MapDispatchToProps } from "@haywork/middleware";
import { editable } from "@haywork/modules/editable";
import { AppState, SettingsActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { defaultFolderCategories } from "../../selectors";
import {
  SettingsDossierFoldersDetailScreen,
  SettingsDossierFoldersDetailScreenProps
} from "./detail";

interface StateProps {
  folderTree: DefaultFolderTree;
  defaultFolderCategories: DefaultFolderTreeCategoryOption[];
}
interface DispatchProps {
  saveDefaultFolderTree: (folderTree: DefaultFolderTree) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SettingsDossierFoldersDetailScreenProps,
  AppState
> = (state) => {
  const { currentComponentState: folderTree } = state.editable;

  return {
    folderTree,
    defaultFolderCategories: defaultFolderCategories(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  SettingsDossierFoldersDetailScreenProps
> = (dispatch) => ({
  saveDefaultFolderTree: (folderTree: DefaultFolderTree) =>
    dispatch(FolderTreesThunk.saveDefaultFolderTree(folderTree))
});

export type SettingsDossierFoldersDetailScreenContainerProps = StateProps &
  DispatchProps;
export const SettingsDossierFoldersDetailScreenContainer = editable(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsDossierFoldersDetailScreen),
  {
    entityType: null,
    icon: "archive",
    thunk: FolderTreesThunk.readDefaultFolderTree,
    status: "settings.folderTrees.singleDefaultFolderTreeStatus",
    statePath: "settings.folderTrees.singleDefaultFolderTree",
    action: SettingsActions.FolderTrees.setSingleDefaultFolderTree,
    route: DOSSIERFOLDERROUTES.DETAIL.URI,
    confirm: {
      title: { key: "dossierFolderConfirmTitle" },
      body: { key: "dossierFolderConfirmBody" }
    }
  }
);
