import { ObjectTypesThunks as Types } from "./types.thunk";
import { ProjectsThunks as Projects } from "./projects.thunk";
import { BuildnumbersThunks as Buildnumbers, FailedBuildNumberRequest } from "./buildnumbers.thunk";

export const ProjectsThunks = {
  Types,
  Projects,
  Buildnumbers
};

export {
  FailedBuildNumberRequest
};
