import * as React from "react";
import { ZOHO } from "@haywork/constants";
import { ZohoAsapContainerProps } from "./asap.container";
import { RequestStatus } from "@haywork/enum";

declare let window: ExtendedWindow;

export interface ZohoAsapComponentProps {}
type Props = ZohoAsapComponentProps & ZohoAsapContainerProps;

export class ZohoAsapComponent extends React.PureComponent<Props> {
  public componentDidMount() {
    if (!window.ZohoHCAsap) {
      window.ZohoHCAsap =
        window.ZohoHCAsap || ((key, val) => (window.ZohoHCAsap[key] = val));

      let salesIQOptions: any = {};
      if (!!this.props.displayName) {
        salesIQOptions = {
          ...salesIQOptions,
          name: this.props.displayName
        };
      }
      if (!!this.props.emailAddress && !!this.props.emailAddress.address) {
        salesIQOptions = {
          ...salesIQOptions,
          email: this.props.emailAddress.address
        };
      }

      window.ZohoHCAsapSettings = {
        zohoChatSettings: {
          salesIQOptions
        }
      };

      (() => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "text/javascript";
        scriptTag.defer = true;
        scriptTag.src = ZOHO.ASAP_URI;
        document.getElementsByTagName("head")[0].appendChild(scriptTag);
      })();
    }

    if (!window.ZohoHCAsapReady) {
      window.ZohoHCAsapReady = (callback) => {
        const asynCalls = (window.ZohoHCAsap__asyncalls =
          window.ZohoHCAsap__asyncalls || []);
        if (!!callback) {
          asynCalls.push(callback);
        }

        if (!!window.ZohoHCAsapReadyStatus) {
          asynCalls.map((callback) => !!callback && callback());
        }
      };
    }

    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action("hideLauncher");
      window.ZohoHCAsap.Events.Subscribe("onAppOpen", () =>
        this.props.toggleVisibility(true)
      );
      window.ZohoHCAsap.Events.Subscribe("onAppClose", () =>
        this.props.toggleVisibility(false)
      );
      this.props.setStatus(RequestStatus.Success);
    });
  }

  public componentWillUnmount() {
    if (this.props.asapStatus === RequestStatus.Success) {
      window.ZohoHCAsap.Events.UnSubscribe("onAppOpen");
      window.ZohoHCAsap.Events.UnSubscribe("onAppClose");
    }
  }

  public render() {
    return null;
  }
}
