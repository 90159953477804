import { store } from "@haywork/stores";
import { GeoClient, AddressDetail } from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import differenceBy from "lodash-es/differenceBy";

const parseRequest = new ParseRequest();

const addressSearchV2 = (
  value: string,
  country?: string,
  allowPostalCodes: boolean = false
): Promise<google.maps.places.AutocompletePrediction[]> => {
  const autocomplete = new google.maps.places.AutocompleteService();
  const geocoder = new google.maps.Geocoder();

  return new Promise(async (resolve, reject) => {
    try {
      let placesRequest: google.maps.places.AutocompletionRequest = {
        input: `${value}`,
        types: ["geocode"],
      };

      if (!!country) {
        placesRequest = {
          ...placesRequest,
          componentRestrictions: {
            country,
          },
        };
      }

      const places = <google.maps.places.AutocompletePrediction[]>(
        await new Promise((resolve, reject) => {
          autocomplete.getPlacePredictions(placesRequest, (results, status) => {
            switch (status) {
              case google.maps.places.PlacesServiceStatus.OK:
                return resolve(filterResults(results));
              case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                return resolve([]);
              default:
                return reject(JSON.stringify(status));
            }
          });
        })
      );

      if (!allowPostalCodes || value.length < 3) {
        return resolve(places);
      }

      let geoCoderRequest: google.maps.GeocoderRequest = {
        address: `${value}`,
      };

      if (!!country) {
        geoCoderRequest = {
          ...geoCoderRequest,
          componentRestrictions: {
            country,
          },
        };
      }

      const geocoderResponse = await new Promise<google.maps.GeocoderResult[]>(
        (resolve, reject) => {
          geocoder.geocode(geoCoderRequest, (results, status) => {
            switch (status) {
              case google.maps.GeocoderStatus.OK:
                const filteredResults = results.filter(
                  (result) => result.types.indexOf("postal_code") !== -1
                );
                return resolve(filteredResults);
              case google.maps.GeocoderStatus.ZERO_RESULTS:
                return resolve([]);
              default:
                return reject(JSON.stringify(status));
            }
          });
        }
      );

      const mappedGeocoderResults: google.maps.places.AutocompletePrediction[] = geocoderResponse.map(
        (geo) => {
          const forbidden = [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
          ];
          const adressComponents: string[] = geo.address_components.reduce(
            (state, cmp) => {
              const diff = differenceBy(forbidden, cmp.types);
              if (diff.length === 3) {
                state.push(cmp.long_name);
              }
              return state;
            },
            []
          );

          return {
            id: geo.place_id,
            description: adressComponents.join(", "),
            matched_substrings: [],
            place_id: geo.place_id,
            reference: null,
            structured_formatting: null,
            terms: [],
            types: geo.types,
          } as google.maps.places.AutocompletePrediction;
        }
      );

      resolve([...mappedGeocoderResults, ...places]);
    } catch (error) {
      reject(error);
    }
  });
};

const addressSearch = (value: string, country?: string) => {
  const autocomplete = new google.maps.places.AutocompleteService();

  return new Promise<google.maps.places.AutocompletePrediction[]>(
    (resolve, reject) => {
      let request: google.maps.places.AutocompletionRequest = {
        input: `${value}`,
        types: ["address"],
      };

      if (!!country) {
        request = {
          ...request,
          componentRestrictions: {
            country,
          },
        };
      }

      autocomplete.getPlacePredictions(request, (results, status) => {
        switch (status) {
          case google.maps.places.PlacesServiceStatus.OK:
            return resolve(filterResults(results));
          case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            return resolve([]);
          default:
            return reject(JSON.stringify(status));
        }
      });
    }
  );
};

const searchAddress = async (
  location: string,
  countryIso2: string,
  culture: string
) => {
  const state = store.getState();
  const { host } = state.appSettings;

  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const Geo = new GeoClient(host);

  try {
    return await parseRequest.response(
      Geo.addressSearch(
        {
          countryIso2,
          location,
          culture,
        },
        realEstateAgencyId
      )
    );
  } catch (error) {
    throw error;
  }
};

const searchAddressByPostalCode = async (
  postalCode: string,
  houseNumber: number,
  countryIso2: string,
  culture: string
) => {
  const state = store.getState();
  const { host } = state.appSettings;

  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const Geo = new GeoClient(host);

  try {
    return await Geo.addressSearch(
      {
        countryIso2,
        postalCode,
        houseNumber,
        culture,
      },
      realEstateAgencyId
    );
  } catch (error) {
    throw error;
  }
};

const getGeocodes = (value: string, country: string): Promise<any> => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address: `${value}`,
        componentRestrictions: {
          country,
        },
      },
      (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            return resolve(results);
          case google.maps.GeocoderStatus.ZERO_RESULTS:
            return resolve([]);
          default:
            return reject(JSON.stringify(status));
        }
      }
    );
  });
};

const getPlaceById = (
  placeId: string
): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        placeId,
      },
      (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            return resolve(results);
          case google.maps.GeocoderStatus.ZERO_RESULTS:
            return resolve([]);
          default:
            return reject(JSON.stringify(status));
        }
      }
    );
  });
};

const filterResults = (
  arrayToFilter: google.maps.places.AutocompletePrediction[]
): google.maps.places.AutocompletePrediction[] => {
  return arrayToFilter.filter(
    (a) =>
      a.types.filter(
        (types) =>
          ["route", "street_address", "political", "premise"].indexOf(types) !==
          -1
      ).length > 0
  );
};

const getCountries = () => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.countrySearch({ culture }, realEstateAgencyId).then(
      (response) => response.results
    );
  } catch {
    return [] as AddressDetail[];
  }
};

const getAdminAreaOneSuggestions = (countryId: number) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.adminAreaLevel1Search(
      { countryId, culture },
      realEstateAgencyId
    ).then((response) => response.results);
  } catch {
    return [] as AddressDetail[];
  }
};

const getAdminAreaTwoSuggestions = (adminAreaLevel1Id: number) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.adminAreaLevel2Search(
      { adminAreaLevel1Id, culture },
      realEstateAgencyId
    ).then((response) => response.results);
  } catch {
    return [] as AddressDetail[];
  }
};

const getLocalitySuggestions = (
  adminAreaLevel1Id: number,
  adminAreaLevel2Id?: number
) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.localitySearch(
      { adminAreaLevel2Id, adminAreaLevel1Id, culture },
      realEstateAgencyId
    ).then((response) => response.results);
  } catch {
    return [] as AddressDetail[];
  }
};

const getSublocalitySuggestions = (localityId: number) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.sublocalitySearch(
      { localityId, culture },
      realEstateAgencyId
    ).then((response) => response.results);
  } catch {
    return [] as AddressDetail[];
  }
};

const getStreetSuggestions = (localityId: number) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { culture } = state.main;

  const Geo = new GeoClient(host);

  try {
    return Geo.streetSearch({ localityId, culture }, realEstateAgencyId).then(
      (response) => response.results
    );
  } catch {
    return [] as AddressDetail[];
  }
};

export const AddressRequest = {
  addressSearch,
  getGeocodes,
  searchAddress,
  getPlaceById,
  addressSearchV2,
  searchAddressByPostalCode,
  getCountries,
  getAdminAreaOneSuggestions,
  getAdminAreaTwoSuggestions,
  getLocalitySuggestions,
  getSublocalitySuggestions,
  getStreetSuggestions,
};
