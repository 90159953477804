import {
  FolderCategory,
  PersonSettingsClient,
  CompaniesClient,
  EmailAddress,
  ShareType,
} from "@haywork/api/mail";
import { AppState, EditableActions } from "@haywork/stores";
import {
  EmailActionsV2,
  EmailFiltersState,
  EmailFolder,
  EmailDraft,
} from "@haywork/stores/email-v2";
import first from "lodash-es/first";
import { Dispatch } from "../";
import Accounts from "./accounts";
import Folders from "./folders";
import Providers from "./providers";
import Shares from "./shares";
import Templates from "./templates";
import { ApiType, ParseRequest } from "@haywork/services";
import { v4 as uuid } from "uuid";
import { RouteUtil } from "@haywork/util";
import { EMAILROUTES, MAINROUTES } from "@haywork/constants";
import { push } from "connected-react-router";
import {
  LinkedRelation,
  LinkedAssignment,
  EmployeeRole,
} from "@haywork/api/kolibri";
import { EmailAssignment, EmailUtil } from "@haywork/util/email-v2";
import { head } from "lodash-es";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getInitialEmailState = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const accounts = await dispatch(Accounts.getAccounts());
      const folders = await dispatch(Folders.getFolders(accounts, true));

      if (!!accounts && !!accounts.length) {
        const accountId = first(accounts).id || null;
        const accountFolders = (folders || []).filter(
          (folder) => folder.accountId === accountId
        );
        dispatch(EmailActionsV2.Main.setCurrentAccount(accountId));

        const removeAfterPersist =
          first(accounts).autoRemoveMessageAfterPersist || false;
        dispatch(
          EmailActionsV2.Main.setAutoRemoveAfterMessagePersists(
            removeAfterPersist
          )
        );

        if (!!accountFolders.length) {
          const ref = accountFolders.find(
            (folder) => folder.category === FolderCategory.Inbox
          );
          const folderId = !!ref
            ? ref.id || null
            : first(accountFolders).id || null;
          dispatch(
            EmailActionsV2.Main.setInitialAccountAndFolder(accountId, folderId)
          );
        }
      }

      dispatch(Providers.getProviders());
      dispatch(Shares.getAccountShares());
      await dispatch(Folders.getFolderCounts());
    } catch (error) {
      throw error;
    }
  };
};

const getSecondaryEmailState = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { role } = state.account.currentRealestateAgency;

      dispatch(Templates.getInitialTemplatesState());
      if (role === EmployeeRole.Standard) {
        dispatch(getEmailSettings());
      }
    } catch (error) {
      throw error;
    }
  };
};

const setFilters = (filters: EmailFiltersState) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { currentFolder } = state.emailV2.main;
      const { folders } = state.emailV2.folders;

      dispatch(EmailActionsV2.Main.setFilters(filters));
      dispatch(EmailActionsV2.Main.setIdsMatchingSearchQuery([]));

      const folder = folders.find((folder) => folder.id === currentFolder);
      if (!!folder) {
        const resetFolder: EmailFolder = {
          ...folder,
          totalResults: undefined,
        };

        dispatch(EmailActionsV2.Folders.updateFolder(resetFolder));
      }
    } catch (error) {
      throw error;
    }
  };
};

const resetFilters = () => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { currentFolder } = state.emailV2.main;
      const { folders } = state.emailV2.folders;

      const folder = folders.find((folder) => folder.id === currentFolder);
      if (!!folder) {
        const resetFolder: EmailFolder = {
          ...folder,
          totalResults: undefined,
        };

        dispatch(EmailActionsV2.Folders.updateFolder(resetFolder));
      }

      dispatch(EmailActionsV2.Main.resetFilters());
    } catch (error) {
      throw error;
    }
  };
};

const setCurrentFolder = (folder: EmailFolder) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const autoRemoveMessageAfterPersist =
        state.emailV2.accounts.accounts.find(
          (account) => account.id === folder.accountId
        )?.autoRemoveMessageAfterPersist || false;

      dispatch(
        EmailActionsV2.Main.setAutoRemoveAfterMessagePersists(
          autoRemoveMessageAfterPersist
        )
      );
      dispatch(EmailActionsV2.Main.resetFilters());
      dispatch(EmailActionsV2.Main.setCurrentFolder(folder));
      dispatch(
        EmailActionsV2.Folders.updateFolder(
          {
            ...folder,
            totalResults: undefined,
          },
          true
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getEmailSettings = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { emailHost } = state.appSettings;

      const personClient = new PersonSettingsClient(emailHost);
      const companyClient = new CompaniesClient(emailHost);

      const personSettings = await parseRequest.response(
        personClient
          .read(realEstateAgencyId)
          .then((response) => response.personSettings || null),
        ApiType.Email
      );
      dispatch(EmailActionsV2.Main.setPersonSettings(personSettings));

      const companySettings = await parseRequest.response(
        companyClient
          .read(realEstateAgencyId, realEstateAgencyId)
          .then((r) => r.company),
        ApiType.Email
      );
      dispatch(EmailActionsV2.Main.setCompanySettings(companySettings));
    } catch (error) {
      throw error;
    }
  };
};

const createNewEmail = (
  emailAdresses = [] as (string | EmailAddress)[],
  linkedRelations = [] as LinkedRelation[],
  linkedAssignments = [] as LinkedAssignment[],
  subject = "",
  body: string | undefined = undefined,
  toBcc = false
) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const accounts = state.emailV2.accounts.accounts || [];
    const shares = state.emailV2.shares.shares || [];
    const settings = state.emailV2.main.personSettings;
    const sendAccounts = accounts.filter((account) => {
      const restrictions = shares.find(
        (share) => share.accountId === account.id
      );
      return ![ShareType.Read].includes(restrictions?.shareType);
    });

    const account = (sendAccounts || []).find(
      (account) => account.id === settings?.defaultAccountId
    );
    const currentAccount = account?.id || head(sendAccounts)?.id || "";
    const { currentFolder } = state.email.folders;
    const id = uuid();

    let bcc = [];
    let to = emailAdresses.map((email) =>
      typeof email === "string"
        ? ({ email } as EmailAddress)
        : (email as EmailAddress)
    );

    const userData = [];

    linkedAssignments.map((linkedAssignment) => {
      userData.push({
        key: "linkedAssignment",
        value: JSON.stringify(linkedAssignment),
      });
    });

    linkedRelations.map((linkedRelation) => {
      userData.push({
        key: "linkedRelation",
        value: JSON.stringify(linkedRelation),
      });
    });

    if (linkedAssignments.length > 0 && !subject) {
      subject = linkedAssignments
        .map((linkedAssignment) => linkedAssignment.displayName)
        .join(", ");
    }

    if (toBcc && !!to.length) {
      bcc = [...to];
      to = [];
    }

    const componentState: EmailDraft = {
      to,
      bcc,
      body,
      files: [],
      temporaryId: id,
      accountId: currentAccount || null,
      folderId: !currentFolder ? null : currentFolder.id,
      userData,
      subject: "",
      searchString: "",
      sendRequested: false,
    };
    const path = route(EMAILROUTES.CREATE.URI, { id });

    dispatch(
      EditableActions.addState({
        icon: MAINROUTES.EMAIL.ICON,
        componentState,
        path,
        title: "...",
        confirm: {
          title: { key: "saveEmailConfirmTitle" },
          body: { key: "saveEmailConfirmBody" },
        },
        entityType: null,
        entityId: id,
      })
    );

    dispatch(push(path));
  };
};

const clearAndReloadEmailState = () => {
  return (dispatch: Dispatch<any>) => {
    try {
      dispatch(EmailActionsV2.Main.clear());
      dispatch(getInitialEmailState());
    } catch (error) {
      throw error;
    }
  };
};

const mailAssignmentSelection = (
  assignments: EmailAssignment[],
  linkedRelations?: LinkedRelation[]
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const relations = linkedRelations || [];
      const emailAdresses = relations
        .map((relation) => relation.email)
        .filter((d) => !!d);
      const body = EmailUtil.createAssignmentsEmailTemplate(assignments);

      dispatch(
        createNewEmail(emailAdresses, relations, undefined, undefined, body)
      );
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getInitialEmailState,
  getSecondaryEmailState,
  setFilters,
  resetFilters,
  setCurrentFolder,
  createNewEmail,
  clearAndReloadEmailState,
  mailAssignmentSelection,
};
