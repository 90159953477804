import { NoteSnapShot } from "@haywork/api/kolibri";
import { RequestStatus } from "@haywork/enum";

export enum notesActions {
  SetParentId = "Notes.SetParentId",
  SetNotes = "Notes.SetNotes",
  SetNotesStatus = "Notes.SetNotesStatus",
  ToggleListVisibility = "Notes.ToggleListVisibility"
}

interface SetParentId {
  type: notesActions.SetParentId;
  parentId: string;
}
const setParentId = (parentId: string) => ({
  type: notesActions.SetParentId,
  parentId
});

interface SetNotes {
  type: notesActions.SetNotes;
  parentId: string;
  notes: NoteSnapShot[];
  totalCount: number;
  reset: boolean;
}
const setNotes = (
  parentId: string,
  notes: NoteSnapShot[],
  totalCount: number,
  reset: boolean = false
) => ({
  type: notesActions.SetNotes,
  parentId,
  notes,
  totalCount,
  reset
});

interface SetNotesStatus {
  type: notesActions.SetNotesStatus;
  notesStatus: RequestStatus;
}
const setNotesStatus = (notesStatus: RequestStatus) => ({
  type: notesActions.SetNotesStatus,
  notesStatus
});

interface ToggleListVisibility {
  type: notesActions.ToggleListVisibility;
  notesVisible: boolean;
}
const toggleListVisibility = (notesVisible: boolean) => ({
  type: notesActions.ToggleListVisibility,
  notesVisible
});

export type NotesActions =
  | SetParentId
  | SetNotes
  | SetNotesStatus
  | ToggleListVisibility;
export const Actions = {
  setParentId,
  setNotes,
  setNotesStatus,
  toggleListVisibility
};
