import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText, MarkDown } from "@haywork/modules/shared";
import { Ui, UiScoreSize } from "@haywork/modules/ui";
import { AppClient, AppClientTranslation } from "@haywork/api/authorization";

const styles = require("./about.component.scss");

interface Props {
  client: AppClient;
  translation: AppClientTranslation;
}

export const Info = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    return (
      <div styleName="info">
        <div styleName="meta">
          <h2>
            <ResourceText resourceKey={props.client.category.toString()} />
          </h2>
          <h1>{props.client.displayName}</h1>
          <MarkDown markdown={props.translation.introduction} />
          {/* {props.translation.introduction} */}
        </div>

        <div styleName="widgets">
          <div styleName="widget green">
            <div styleName="inner">
              <div styleName="installations">
                {props.client.appClientStatistics.installations}
              </div>
            </div>
            <div styleName="label">
              <ResourceText
                resourceKey="appXchangeInstallations"
                values={{
                  installations: props.client.appClientStatistics.installations,
                }}
              />
            </div>
          </div>

          <div styleName="widget yellow">
            <div styleName="inner">
              <Ui.Score
                score={props.client.appClientStatistics.averageRating}
                size={UiScoreSize.Small}
              />
            </div>
            <div styleName="label">
              <ResourceText
                resourceKey="appXchangeReviews"
                values={{
                  reviews: props.client.appClientStatistics.amountOfReviews,
                }}
              />
            </div>
          </div>

          <div styleName="widget orange">
            <div styleName="inner">
              <div styleName="ispaid">
                <i className="far fa-euro-sign" />
              </div>
            </div>
            <div styleName="label">
              <ResourceText
                masterKey="remunerationCategoryOptions"
                resourceKey={props.client.remunerationCategory.toString()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
