import {
  InvestmentType,
  ObjectAssignment,
  ResidentialBuildingType,
  ResidentialBuildingTypeOption,
  VatCondition,
  AcquisitionObjectAssignment,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  residentialBuildingTypeOptions: ResidentialBuildingTypeOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}
interface State {
  hideResidentialFields: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogInvestmentComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;
    const vatPath = objectAssignment.forSale
      ? "saleOffer.vatCondition"
      : "rentOffer.vatCondition";
    const hideResidentialFields =
      objectAssignment.investmentType !== InvestmentType.Residential ||
      !has(objectAssignment, "residentialBuilding.buildingType") ||
      objectAssignment.residentialBuilding.buildingType !==
        ResidentialBuildingType.House;

    this.state = {
      hideResidentialFields,
    };

    this.formControls = {
      buildingType: {
        value: value(objectAssignment, "residentialBuilding.buildingType"),
        onChange: (ref) =>
          this.setState({
            hideResidentialFields: ref.value !== ResidentialBuildingType.House,
          }),
      },
      vVO: { value: value(objectAssignment, "vVO.area") },
      portefeuille: { value: value(objectAssignment, "portefeuille", false) },
      countOfSeparatelyLettableHousingUnits: {
        value: value(objectAssignment, "countOfSeparatelyLettableHousingUnits"),
      },
      countOfTenants: { value: value(objectAssignment, "countOfTenants") },
      grossRentalIncome: {
        value: value(objectAssignment, "grossRentalIncome"),
      },
      grossInitialYield: {
        value: value(objectAssignment, "grossInitialYield"),
      },
      vacancyRentalValue: {
        value: value(objectAssignment, "vacancyRentalValue"),
      },
      vacancyArea: { value: value(objectAssignment, "vacancyArea.area") },
      remainingTermOfAContract: {
        value: value(objectAssignment, "remainingTermOfAContract"),
      },
      vatCondition: {
        value: value(objectAssignment, vatPath, VatCondition.Included),
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;
      const vatPath = objectAssignment.forSale
        ? "saleOffer.vatCondition"
        : "rentOffer.vatCondition";
      const hideResidentialFields =
        objectAssignment.investmentType !== InvestmentType.Residential ||
        !has(objectAssignment, "residentialBuilding.buildingType") ||
        objectAssignment.residentialBuilding.buildingType !==
          ResidentialBuildingType.House;

      this.setState({
        hideResidentialFields,
      });

      this.formRef.update(
        {
          buildingType: value(
            objectAssignment,
            "residentialBuilding.buildingType"
          ),
          vVO: value(objectAssignment, "vVO.area"),
          portefeuille: value(objectAssignment, "portefeuille", false),
          countOfSeparatelyLettableHousingUnits: value(
            objectAssignment,
            "countOfSeparatelyLettableHousingUnits"
          ),
          countOfTenants: value(objectAssignment, "countOfTenants"),
          grossRentalIncome: value(objectAssignment, "grossRentalIncome"),
          grossInitialYield: value(objectAssignment, "grossInitialYield"),
          vacancyRentalValue: value(objectAssignment, "vacancyRentalValue"),
          vacancyArea: value(objectAssignment, "vacancyArea.area"),
          remainingTermOfAContract: value(
            objectAssignment,
            "remainingTermOfAContract"
          ),
          vatCondition: value(objectAssignment, vatPath, VatCondition.Included),
          parcelSurface: value(objectAssignment, "parcelSurface.area"),
        },
        true
      );
    }
  }

  public render() {
    return (
      <Form
        name="investment"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        {this.props.objectAssignment.investmentType ===
          InvestmentType.Residential && (
          <div className="form__row">
            <label htmlFor="buildingType">
              <ResourceText resourceKey="buildingType" />
            </label>
            <Input.RadioGroup name="buildingType" asButtonList>
              {this.props.residentialBuildingTypeOptions.map(
                (residentialBuildingTypeOption, idx) => (
                  <Input.Radio
                    value={residentialBuildingTypeOption.value}
                    key={idx}
                    label={residentialBuildingTypeOption.displayName}
                  />
                )
              )}
            </Input.RadioGroup>
          </div>
        )}

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="vVO">
                <ResourceText resourceKey="surface" />
              </label>
              <div className="input__helper">
                <Input.Number name="vVO" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <Input.Switch
            name="portefeuille"
            label="portefeuille"
            on={true}
            off={false}
            labelPosition={SwitchLabelPosition.Pre}
          />
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="countOfSeparatelyLettableHousingUnits">
                <ResourceText resourceKey="countOfSeparatelyLettableHousingUnits" />
              </label>
              <Input.Number name="countOfSeparatelyLettableHousingUnits" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="countOfTenants">
                <ResourceText resourceKey="countOfTenants" />
              </label>
              <Input.Number name="countOfTenants" />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="grossRentalIncome">
                <ResourceText resourceKey="grossRentalIncome" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number
                  name="grossRentalIncome"
                  step={50}
                  pretty
                  asPrice
                />
              </div>
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="grossInitialYield">
                <ResourceText resourceKey="grossInitialYield" />
              </label>
              <div className="input__helper">
                <Input.Number name="grossInitialYield" pretty asPrice round />
                <div className="post">%</div>
              </div>
            </div>
          </div>
        </div>

        {this.state.hideResidentialFields && (
          <div>
            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="size-field">
                  <label htmlFor="vacancyRentalValue">
                    <ResourceText resourceKey="vacancyRentalValue" />
                  </label>
                  <div className="input__helper">
                    <div className="pre">&euro;</div>
                    <Input.Number
                      name="vacancyRentalValue"
                      step={50}
                      pretty
                      asPrice
                    />
                  </div>
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="size-field">
                  <label htmlFor="vacancyArea">
                    <ResourceText resourceKey="vacancyArea" />
                  </label>
                  <div className="input__helper">
                    <Input.Number name="vacancyArea" step={50} pretty />
                    <div className="post">
                      <ResourceText resourceKey="squareMeters" asHtml />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form__row" styleName="size-field">
              <label htmlFor="remainingTermOfAContract">
                <ResourceText resourceKey="remainingTermOfAContract" />
              </label>
              <div className="input__helper">
                <Input.Number name="remainingTermOfAContract" />
                <div className="post full">
                  <ResourceText resourceKey="months" />
                </div>
              </div>
            </div>

            <div className="form__row">
              <Input.Switch
                name="vatCondition"
                label="vatConditionIncluded"
                on={VatCondition.Included}
                off={VatCondition.Excluded}
                labelPosition={SwitchLabelPosition.Pre}
              />
            </div>
          </div>
        )}
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let fields: any = {
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface,
      },
      vVO: {
        ...objectAssignment.vVO,
        area: values.vVO,
      },
      portefeuille: values.portefeuille,
      countOfSeparatelyLettableHousingUnits:
        values.countOfSeparatelyLettableHousingUnits,
      countOfTenants: values.countOfTenants,
      grossRentalIncome: values.grossRentalIncome,
      grossInitialYield: values.grossInitialYield,
    };

    if (!this.state.hideResidentialFields) {
      fields = {
        ...fields,
        residentialBuilding: {
          ...objectAssignment.residentialBuilding,
          buildingType: values.buildingType,
          hasResidentialBuilding: true,
        },
      };
    } else {
      fields = {
        ...fields,
        vacancyRentalValue: values.vacancyRentalValue,
        vacancyArea: {
          ...objectAssignment.vacancyArea,
          area: values.vacancyArea,
        },
        remainingTermOfAContract: values.remainingTermOfAContract,
        residentialBuilding: {
          ...objectAssignment.residentialBuilding,
          buildingType: values.buildingType,
          hasResidentialBuilding: false,
        },
      };
    }

    if (
      objectAssignment.forSale &&
      objectAssignment.investmentType !== InvestmentType.Residential
    ) {
      const saleOffer = {
        ...objectAssignment.saleOffer,
        vatCondition: values.vatCondition,
      };
      fields = {
        ...fields,
        saleOffer,
      };
    }

    if (
      !objectAssignment.forSale &&
      objectAssignment.investmentType !== InvestmentType.Residential
    ) {
      const rentOffer = {
        ...objectAssignment.rentOffer,
        vatCondition: values.vatCondition,
      };
      fields = {
        ...fields,
        rentOffer,
      };
    }

    this.props.onChange(fields);
  }
}
