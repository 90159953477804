import { Channel } from "@haywork/api/mail";
import * as Ably from "ably";
import * as React from "react";
import { FC, memo, useContext, useEffect } from "react";
import { LiveDataContext } from "../../live-data.context";

type Props = {
  onConnectionChange: (channels: Channel[], realtime: Ably.Realtime) => void;
};

const Connecter: FC<Props> = memo(({ onConnectionChange }) => {
  const { mailChannels, mailRealtime } = useContext(LiveDataContext);

  useEffect(() => {
    onConnectionChange(mailChannels, mailRealtime);
  }, [mailChannels, mailRealtime, onConnectionChange]);

  return null;
});

export default Connecter;
