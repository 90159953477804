import { VideoBlob } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import Axios from "axios";
import includes from "lodash-es/includes";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./video-preview.component.scss");

interface Props {
  urlOriginal: string;
  name: string;
  id: string;
  video: VideoBlob;
  value: string;
  placeholder: string;

  onPreviewClick: (video: VideoBlob) => void;
  onDeleteClick: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
interface State {
  thumbnail: string;
}

@CSSModules(styles, { allowMultiple: true })
export class VideoPreviewComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.onPreviewClickHandler = this.onPreviewClickHandler.bind(this);

    this.state = {
      thumbnail: "",
    };
  }

  public componentDidMount() {
    this.renderVideoPreview(this.props.urlOriginal);
  }

  public render() {
    return (
      <div styleName="video">
        <div styleName="preview" onClick={this.onPreviewClickHandler}>
          <div styleName="icon">
            {!!this.state.thumbnail ? (
              <img src={this.state.thumbnail} />
            ) : (
              <i className="fal fa-play" />
            )}
          </div>
        </div>
        <div styleName="title">
          <label htmlFor={this.props.name}>
            <ResourceText resourceKey="title" />
          </label>
          <input
            type="text"
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            data-lpignore="true"
          />
        </div>
        <div
          styleName="delete"
          onClick={this.props.onDeleteClick}
          id={this.props.id}
        >
          <i className="fal fa-times" />
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.props.urlOriginal &&
      prevProps.urlOriginal !== this.props.urlOriginal
    ) {
      this.renderVideoPreview(this.props.urlOriginal);
    }
  }

  private renderVideoPreview(url: string) {
    const regex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/g;
    const isYoutube = regex.test(url);

    if (isYoutube) {
      const videoId = url.match(
        /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      );
      if (videoId != null) {
        const youtubeSource =
          "https://img.youtube.com/vi/" + videoId[1] + "/0.jpg";
        this.setState({ thumbnail: youtubeSource });
      }
    } else if (includes(url, "dailymotion.")) {
      const videoId = url.split("video/").pop();
      const dailyMotionSource =
        "http://www.dailymotion.com/thumbnail/video/" + videoId;
      this.setState({ thumbnail: dailyMotionSource });
    } else if (includes(url, "vimeo.")) {
      Axios.get(`http://vimeo.com/api/oembed.json?url=${encodeURI(url)}`).then(
        (response) => {
          this.setState({ thumbnail: response.data.thumbnail_url });
        }
      );
    }
  }
  private onPreviewClickHandler() {
    this.props.onPreviewClick(this.props.video);
  }
}
