import {
  Language,
  ObjectTypeAssignment,
  TranslatedText,
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../edit-specs.component.scss");

export interface Props {
  currentType: ObjectTypeAssignment;
  currentDestinationText: string;
  currentUsageText: string;
  onChange: (type: ObjectTypeAssignment) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditSpecsPlotComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    const {
      currentType,
      currentDestinationText,
      currentUsageText,
    } = this.props;
    const { isRecreational, inPark, isBuildingReady } = currentType;

    this.formControls = {
      currentDestination: { value: currentDestinationText },
      currentUsage: { value: currentUsageText },
      isRecreational: { value: isRecreational },
      inPark: { value: inPark },
      isBuildingReady: { value: isBuildingReady },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.currentType, "dateTimeModified") !==
        get(this.props.currentType, "dateTimeModified")
    ) {
      const {
        currentType,
        currentDestinationText,
        currentUsageText,
      } = this.props;
      const { isRecreational, inPark, isBuildingReady } = currentType;

      this.formRef.update(
        {
          currentDestination: currentDestinationText,
          currentUsage: currentUsageText,
          isRecreational,
          inPark,
          isBuildingReady,
        },
        true
      );
    }
  }

  public render() {
    return (
      <div styleName="specs" id="scroll-to-top">
        <div className="container-fluid">
          <Form
            name="project-type-general"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.formRef = form)}
          >
            <div className="form__row">
              <label htmlFor="currentDestination">
                <ResourceText resourceKey="objectTypeSpecsPlot.currentDestination" />
              </label>
              <Input.Textarea
                name="currentDestination"
                autoSize={true}
                placeholder="objectTypeSpecsPlotPlaceholder.currentDestination"
              />
            </div>

            <div className="form__row">
              <label htmlFor="currentUsage">
                <ResourceText resourceKey="objectTypeSpecsPlot.currentUsage" />
              </label>
              <Input.Textarea
                name="currentUsage"
                autoSize={true}
                placeholder="objectTypeSpecsPlotPlaceholder.currentUsage"
              />
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column">
                  <Input.Switch
                    name="isRecreational"
                    label="objectTypeSpecsPlot.isRecreational"
                    on={true}
                    off={false}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Switch
                    name="inPark"
                    label="objectTypeSpecsPlot.inPark"
                    on={true}
                    off={false}
                  />
                </div>
                <div className="column__spacer" />
                <div className="column">
                  <Input.Switch
                    name="isBuildingReady"
                    label="objectTypeSpecsPlot.isBuildingReady"
                    on={true}
                    off={false}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    let { currentType } = this.props;

    let currentDestinationText: TranslatedText[];
    if (values.currentDestination) {
      let ref = false;
      currentDestinationText = get(currentType, "currentDestination", []);
      currentDestinationText = currentDestinationText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.currentDestination,
          };
        }
        return text;
      });

      if (!ref) {
        currentDestinationText.push({
          language: Language.Dutch,
          text: values.currentDestination,
        });
      }
    }

    let currentUsageText: TranslatedText[];
    if (values.currentUsage) {
      let ref = false;
      currentUsageText = get(currentType, "currentUsage", []);
      currentUsageText = currentUsageText.map((text) => {
        if (text.language === Language.Dutch) {
          ref = true;
          return {
            ...text,
            text: values.currentUsage,
          };
        }
        return text;
      });

      if (!ref) {
        currentUsageText.push({
          language: Language.Dutch,
          text: values.currentUsage,
        });
      }
    }

    currentType = {
      ...currentType,
      currentDestination: currentDestinationText,
      currentUsage: currentUsageText,
      isRecreational: values.isRecreational,
      inPark: values.inPark,
      isBuildingReady: values.isBuildingReady,
    };

    this.props.onChange(currentType);
  }
}
