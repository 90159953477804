import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import escapeRegExp from "lodash-es/escapeRegExp";

import { SETTINGSROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";
import {
  SettingsDetailContainerProps,
  SettingsDetailRouting,
} from "@haywork/modules/settings";
import {
  ResourceText,
  CollapsableComponent,
  FontStyle,
} from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { FinancialAdministration } from "@haywork/api/kolibri";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const styles = require("./detail.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface SettingsDetailComponentProps
  extends SettingsDetailContainerProps {}
interface SettingsDetailComponentState {
  toggleShowSidebar: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class SettingsDetailComponent extends React.Component<
  SettingsDetailComponentProps,
  SettingsDetailComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      toggleShowSidebar: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  public render() {
    const combined = classNames("detail ", {
      collapsed: this.state.toggleShowSidebar,
    });
    const toggleIcon = classNames(
      "fal fa-fw",
      this.state.toggleShowSidebar ? "fa-times" : "fa-sliders-h"
    );

    return (
      <div styleName={combined}>
        <div styleName="toggle" onClick={this.toggleSidebar}>
          <div styleName="trigger">
            <i className={toggleIcon} />
          </div>
        </div>
        <div styleName="sideBar">
          <div styleName="menu">
            <NavLink
              styleName="item"
              activeClassName="active"
              to={SETTINGSROUTES.OVERVIEW.URI}
              data-cy="CY-menuItemOverview"
            >
              <span styleName="text">
                <ResourceText resourceKey="settingsMenuOverview" />
              </span>
            </NavLink>

            <NavLink
              styleName="item"
              activeClassName="active"
              to={SETTINGSROUTES.GENERAL.URI}
              data-cy="CY-menuItemGeneral"
            >
              <span styleName="text">
                <ResourceText resourceKey="settingsMenuGeneral" />
              </span>
            </NavLink>

            <CollapsableComponent
              title="settingsMenuMyCompany"
              className="settings"
              initiallyCollapsed
              data-cy="CY-menuItemMyCompany"
            >
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.REAL_ESTATE_AGENCY.URI}
                data-cy="CY-menuItemOfficeData"
              >
                <i className="fal fa-fw fa-building" />
                <span styleName="text">
                  <ResourceText resourceKey="officeData" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.BRANCHES.URI}
                data-cy="CY-menuItemOffices"
              >
                <i className="fal fa-fw fa-building" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuOffices" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.USERS.URI}
                data-cy="CY-menuItemEmployees"
              >
                <i className="fal fa-fw fa-user-alt" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuEmployees" />
                </span>
              </NavLink>
            </CollapsableComponent>

            {this.props.financialAdministrations.map(
              (financialAdministration, idx) => (
                <ErrorBoundary key={idx}>
                  <CollapsableComponent
                    title={financialAdministration.name}
                    className="settings"
                    initiallyCollapsed={
                      !this.isCurrentAdministration(financialAdministration)
                    }
                    fontStyle={FontStyle.None}
                    data-cy={
                      "CY-menuItem" +
                      financialAdministration.name.replace(" ", "")
                    }
                  >
                    <NavLink
                      styleName="item"
                      activeClassName="active"
                      to={route(SETTINGSROUTES.GENERAL_ADMINISTRATION.URI, {
                        id: financialAdministration.id,
                      })}
                      data-cy="CY-menuItemFinancialAdministrationGeneral"
                    >
                      <i className="fal fa-fw fa-briefcase" />
                      <span styleName="text">
                        <ResourceText resourceKey="settingsMenuFinancialAdministrationGeneral" />
                      </span>
                    </NavLink>
                    <NavLink
                      styleName="item"
                      activeClassName="active"
                      to={route(SETTINGSROUTES.TAXRATES.URI, {
                        id: financialAdministration.id,
                      })}
                      data-cy="CY-menuItemFinancialAdministrationTaxeRates"
                    >
                      <i className="fal fa-fw fa-percent" />
                      <span styleName="text">
                        <ResourceText resourceKey="settingsMenuFinancialAdministrationTaxeRates" />
                      </span>
                    </NavLink>
                    <NavLink
                      styleName="item"
                      activeClassName="active"
                      to={route(SETTINGSROUTES.PRODUCTS.URI, {
                        id: financialAdministration.id,
                      })}
                      data-cy="CY-menuItemFinancialAdministrationProductsServices"
                    >
                      <i className="fal fa-fw fa-shopping-cart" />
                      <span styleName="text">
                        <ResourceText resourceKey="settingsMenuFinancialAdministrationProductsServices" />
                      </span>
                    </NavLink>
                    <NavLink
                      styleName="item"
                      activeClassName="active"
                      to={route(SETTINGSROUTES.GROUPS.URI, {
                        id: financialAdministration.id,
                      })}
                      data-cy="CY-menuItemFinancialAdministrationGroups"
                    >
                      <i className="fal fa-fw fa-sitemap" />
                      <span styleName="text">
                        <ResourceText resourceKey="settingsMenuFinancialAdministrationGroups" />
                      </span>
                    </NavLink>
                  </CollapsableComponent>
                </ErrorBoundary>
              )
            )}

            <CollapsableComponent
              title="settingsMenuUsage"
              className="settings"
              initiallyCollapsed
              data-cy="CY-menuItemUsage"
            >
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.COMPANY_SETTINGS.URI}
              >
                <i className="fal fa-fw fa-wrench" />
                <span styleName="text">
                  <ResourceText resourceKey="settings.companySettings.menuItem" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.SCHEDULER_CATEGORIES.URI}
                data-cy="CY-menuItemgenda"
              >
                <i className="fal fa-fw fa-calendar-alt" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuAgenda" />
                </span>
              </NavLink>

              {!this.props.isBackofficeEmployee && (
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.EVENTCENTER.URI}
                  data-cy="CY-menuItemEventNotifications"
                >
                  <i className="fal fa-fw fa-bell" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuEventNotifications" />
                  </span>
                </NavLink>
              )}

              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.RELATIONGROUPS.URI}
                data-cy="CY-menuItemRelationGroups"
              >
                <i className="fal fa-fw fa-users" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuRelationGroups" />
                </span>
              </NavLink>

              <FeatureSwitch feature="FOLDERS_V2">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.DOSSIER_FOLDERS.URI}
                >
                  <i className="fal fa-fw fa-archive" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuFolders" />
                  </span>
                </NavLink>
              </FeatureSwitch>

              <FeatureSwitch feature="STANDARDLETTERS">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.STANDARDLETTERS.URI}
                  data-cy="menuItemStandardLetters"
                >
                  <i className="fal fa-fw fa-file-alt" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuStandardLetters" />
                  </span>
                </NavLink>
              </FeatureSwitch>

              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.MATCHMAIL.URI}
                data-cy="CY-menuItemMatchmail"
              >
                <i className="fal fa-fw fa-envelope" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuMatchmail" />
                </span>
              </NavLink>
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.ASSIGNMENTS.URI}
                data-cy="CY-menuItemAssignments"
              >
                <i className="fal fa-fw fa-folder-open" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuAssignments" />
                </span>
              </NavLink>

              {!this.props.isBackofficeEmployee && (
                <FeatureSwitch feature="MLS">
                  <NavLink
                    styleName="item"
                    activeClassName="active"
                    to={SETTINGSROUTES.MLS.URI}
                  >
                    <i className="fal fa-fw fa-exchange" />
                    <span styleName="text">
                      <ResourceText resourceKey="settingsMenuMls" />
                    </span>
                  </NavLink>
                </FeatureSwitch>
              )}

              <FeatureSwitch feature="ACQUISITIONS">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.ACQUISITION_ASSIGNMENTS.URI}
                >
                  <i className="fal fa-fw fa-handshake-alt" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuAcquisitionAssignments" />
                  </span>
                </NavLink>
              </FeatureSwitch>

              <FeatureSwitch feature="DOCUMENTS_SETTINGS">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.DOCUMENTS.URI}
                >
                  <i className="fal fa-fw fa-file-alt" />
                  <span styleName="text">
                    <ResourceText resourceKey="settings.documents.menuItem" />
                  </span>
                </NavLink>
              </FeatureSwitch>

              <FeatureSwitch feature="LANGUAGE_SWITCH">
                <NavLink
                  styleName="item"
                  activeClassName="active"
                  to={SETTINGSROUTES.LANGUAGE.URI}
                  data-cy="CY-menuItemLanguage"
                >
                  <i className="fal fa-fw fa-globe" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuLanguage" />
                  </span>
                </NavLink>
              </FeatureSwitch>
            </CollapsableComponent>

            <NavLink
              styleName="item"
              activeClassName="active"
              to={SETTINGSROUTES.MEDIAPARTNERS.URI}
              data-cy="CY-menuItemMediaPartners"
            >
              <span styleName="text">
                <ResourceText resourceKey="mediapartners" />
              </span>
            </NavLink>

            <CollapsableComponent
              title="settingsMenuEmail"
              className="settings"
              initiallyCollapsed
              data-cy="CY-menuItemEmail"
            >
              <NavLink
                data-cy="CY-menuItemEmailAccounts"
                styleName="item"
                activeClassName="active"
                className="hidden-xs"
                to={SETTINGSROUTES.EMAIL.URI}
              >
                <i className="fal fa-fw fa-envelope" />
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuEmailAccounts" />
                </span>
              </NavLink>

              <FeatureSwitch feature="EMAIL_TEMPLATES">
                <NavLink
                  data-cy="CY-menuItemEmailTemplates"
                  styleName="item"
                  activeClassName="active"
                  className="hidden-xs"
                  to={SETTINGSROUTES.EMAIL_TEMPLATES.URI}
                >
                  <i className="fal fa-fw fa-file" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuEmailTemplates" />
                  </span>
                </NavLink>
              </FeatureSwitch>

              <FeatureSwitch feature="EMAIL_SIGNATURES">
                <NavLink
                  data-cy="CY-menuItemEmailSignatures"
                  styleName="item"
                  activeClassName="active"
                  className="hidden-xs"
                  to={SETTINGSROUTES.EMAIL_SIGNATURES.URI}
                >
                  <i className="fal fa-fw fa-file-signature" />
                  <span styleName="text">
                    <ResourceText resourceKey="settingsMenuEmailSignatures" />
                  </span>
                </NavLink>
              </FeatureSwitch>
            </CollapsableComponent>

            <FeatureSwitch feature="APP_XCHANGE">
              <NavLink
                styleName="item"
                activeClassName="active"
                to={SETTINGSROUTES.APP_XCHANGE.URI}
                data-cy="menuItemIntegration"
              >
                <span styleName="text">
                  <ResourceText resourceKey="settingsMenuIntegration" />
                </span>
              </NavLink>
            </FeatureSwitch>
          </div>
        </div>

        <div styleName="body">
          <SettingsDetailRouting
            realEstateAgencyId={this.props.realEstateAgencyId}
          />
        </div>
      </div>
    );
  }

  public toggleSidebar() {
    this.setState({ toggleShowSidebar: !this.state.toggleShowSidebar });
  }

  private isCurrentAdministration(
    financialAdministration: FinancialAdministration
  ): boolean {
    const { id } = financialAdministration;
    const path = route(SETTINGSROUTES.ADMINISTRATION.URI, { id });
    const location = this.props.location.pathname;

    return new RegExp(escapeRegExp(path), "gi").test(location);
  }
}
