import { TableResponse } from "@haywork/api/kolibri";
import { TableResponse as EventCenterTableResponse } from "@haywork/api/event-center";
import { MasterTableResponse as AuthorizationTableResponse } from "@haywork/api/authorization";
import { ReadMasterTableResponse as MlsTableResponse } from "@haywork/api/mls";

export enum ActionType {
  SetAppStatus = "Main.SetAppStatus",
  SetMasterTableValueKolibri = "Main.SetMasterTableValueKolibri",
  SetMasterTableValueEventCenter = "Main.SetMasterTableValueEventCenter",
  SetMasterTableValueAuthorization = "Main.SetMasterTableValueAuthorization",
  SetMasterTableValueMls = "Main.SetMasterTableValueMls",
  SetLanguageStatus = "Main.SetLanguageStatus",
  SwitchLanguage = "Main.SwitchLanguage",
  SetTranslations = "Main.SetTranslations",
  ResetApp = "RESET_APP",
}

type SetAppStatus = {
  type: ActionType.SetAppStatus;
  appStatus: string;
};

const setAppStatus = (appStatus: string) => ({
  type: ActionType.SetAppStatus,
  appStatus,
});

type SetMasterTableValueKolibri = {
  type: ActionType.SetMasterTableValueKolibri;
  kolibri: TableResponse;
};

const setMasterTableValueKolibri = (kolibri: TableResponse) => ({
  type: ActionType.SetMasterTableValueKolibri,
  kolibri,
});

type SetMasterTableValueEventCenter = {
  type: ActionType.SetMasterTableValueEventCenter;
  eventCenter: EventCenterTableResponse;
};

const setMasterTableValueEventCenter = (
  eventCenter: EventCenterTableResponse
) => ({
  type: ActionType.SetMasterTableValueEventCenter,
  eventCenter,
});

type SetMasterTableValueAuthorization = {
  type: ActionType.SetMasterTableValueAuthorization;
  authorization: AuthorizationTableResponse;
};

const setMasterTableValueAuthorization = (
  authorization: AuthorizationTableResponse
) => ({
  type: ActionType.SetMasterTableValueAuthorization,
  authorization,
});

type SetMasterTableValueMls = {
  type: ActionType.SetMasterTableValueMls;
  mls: MlsTableResponse;
};

const setMasterTableValueMls = (mls: MlsTableResponse) => ({
  type: ActionType.SetMasterTableValueMls,
  mls,
});

type SetLanguageStatus = {
  type: ActionType.SetLanguageStatus;
  languageStatus: string;
};

const setLanguageStatus = (languageStatus: string) => ({
  type: ActionType.SetLanguageStatus,
  languageStatus,
});

type SwitchLanguage = {
  type: ActionType.SwitchLanguage;
  culture: string;
};

const switchLanguage = (culture: string) => ({
  type: ActionType.SwitchLanguage,
  culture,
});

type SetTranslations = {
  type: ActionType.SetTranslations;
  cultureMessages: Record<string, string>;
};

const setTranslations = (cultureMessages: Record<string, string>) => ({
  type: ActionType.SetTranslations,
  cultureMessages,
});

type ResetApp = {
  type: ActionType.ResetApp;
};

const resetApp = () => ({
  type: ActionType.ResetApp,
});

export type ActionTypes =
  | SetAppStatus
  | SetMasterTableValueKolibri
  | SetMasterTableValueEventCenter
  | SetMasterTableValueAuthorization
  | SetMasterTableValueMls
  | SetLanguageStatus
  | SwitchLanguage
  | SetTranslations
  | ResetApp;
export const MainActions = {
  setAppStatus,
  setMasterTableValueKolibri,
  setMasterTableValueEventCenter,
  setMasterTableValueAuthorization,
  setMasterTableValueMls,
  switchLanguage,
  setLanguageStatus,
  setTranslations,
  resetApp,
};
