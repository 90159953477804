import { intlContext } from "@haywork/app";
import { ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import escapeRegExp from "lodash-es/escapeRegExp";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { InputComponentProps } from "../input.component";

type DisplayFn = (
  value: any,
  query: string
) => React.ReactElement<HTMLDivElement>;
interface RelationGroupQueryInputComponentProps {
  values?: any[];
  asyncValues?: (value: string) => Promise<any>;
  multiple?: boolean;
  valuePath?: string;
  displayPath: string;
  partnerDisplayPath: string;
  optionView?: DisplayFn;
  displayView?: DisplayFn;
  matchPath?: string;
  resultPath?: string;
  placeholder?: string;
  minLength?: number;
  setQueryAsValue?: boolean;
  useQueryAsOption?: boolean;
  inputRef?: (ref: HTMLInputElement) => void;
  noItemsFoundText?: string;
  onAddManualOption?: (value: string) => void;
  isLoading?: boolean;
  asSelect?: boolean;
  compareKey: string;
  cantDeleteItems?: any[];
}
interface RelationPartnerQueryInputComponentState {
  query: string;
  values: any[];
  expanded: boolean;
  loading: boolean;
  selectedValues: any[];
  hasFocus: boolean;
  selectedKey: number;
  minLength: number;
}
const styles = require("./relation-partner-query.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class RelationPartnerQueryComponent extends React.Component<
  RelationGroupQueryInputComponentProps & InputComponentProps,
  RelationPartnerQueryInputComponentState
> {
  private input: HTMLInputElement;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);
    // Initial state
    let query = "";
    if (this.props.setQueryAsValue && this.props.value) {
      const value = isArray(this.props.value)
        ? first(this.props.value)
        : this.props.value;
      query = this.props.displayPath
        ? get(value, this.props.displayPath, "")
        : value;
    }

    let selectedValues = this.props.value || [];
    selectedValues =
      !this.props.multiple && !isArray(selectedValues)
        ? [selectedValues]
        : selectedValues;

    this.state = {
      values: [],
      query,
      expanded: false,
      loading: false,
      selectedValues,
      hasFocus: false,
      minLength: this.props.minLength || 2,
      selectedKey: 0,
    };

    // Event bindings
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.selectListValue = this.selectListValue.bind(this);
    this.onRemoveSelectedValue = this.onRemoveSelectedValue.bind(this);
    this.onInputFocusHandler = this.onInputFocusHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.createOption = this.createOption.bind(this);
    this.onInputKeyDownHandler = this.onInputKeyDownHandler.bind(this);
    this.getAsyncValues = debounce(this.getAsyncValues.bind(this), 500);
    this.renderQueryResultPill = this.renderQueryResultPill.bind(this);
    this.onEmptyStateClickHandler = this.onEmptyStateClickHandler.bind(this);
    this.onTriggerClickHandler = this.onTriggerClickHandler.bind(this);
    this.bindInputRef = this.bindInputRef.bind(this);

    // Document event bindings
    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public render() {
    const formInputClass = classNames("input__select", {
      expanded: this.state.expanded,
      focus: this.state.hasFocus && !this.props.disabled,
    });
    const selectInputQuery = classNames("input__select-query", {
      focus: this.state.hasFocus,
    });

    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;

    return (
      <div
        onKeyDown={this.onKeyDownHandler}
        ref={(ref) => (this.ref = ref)}
        className={formInputClass}
      >
        <div className={selectInputQuery} onClick={this.onInputFocusHandler}>
          {!this.props.setQueryAsValue &&
            this.state.selectedValues.map(this.renderQueryResultPill)}
          {(this.props.setQueryAsValue ||
            this.props.multiple ||
            this.state.selectedValues.length === 0) && (
            <input
              type="text"
              name={this.props.name}
              id={this.props.name}
              placeholder={placeholder}
              value={this.state.query}
              onChange={this.onChangeHandler}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              onKeyDown={this.onInputKeyDownHandler}
              ref={this.bindInputRef}
              disabled={this.props.disabled}
              data-lpignore="true"
            />
          )}
          {(this.state.loading || this.props.isLoading) && (
            <div className="form__query-loader">
              <div className="indeterminate" />
            </div>
          )}
          {this.props.asSelect && (
            <div className="trigger" onClick={this.onTriggerClickHandler}>
              <i className="fal fa-fw fa-chevron-down faIcon" />
            </div>
          )}
        </div>
        <div className="select__options">
          {this.state.values && this.state.values.map(this.createOption)}
          {this.state.values && this.state.values.length === 0 && (
            <div
              className="form__select-option"
              onClick={this.onEmptyStateClickHandler}
            >
              {this.props.noItemsFoundText ? (
                <ResourceText
                  resourceKey={this.props.noItemsFoundText}
                  values={{ value: this.state.query }}
                  asHtml
                />
              ) : (
                <ResourceText resourceKey="queryInputNoResults" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps || !nextProps.value || !nextProps.values) return;

    const values = nextProps.values || [];
    let selectedValues: any[] = [];

    for (let i = 0; i < nextProps.value.length; i++) {
      const selectedValue = nextProps.value[i];
      const group = nextProps.values.find(
        (value) => value.id === selectedValue.id
      );

      if (group) {
        selectedValues.push(group);
      }
    }

    selectedValues =
      !this.props.multiple && !isArray(selectedValues)
        ? [selectedValues]
        : selectedValues;
    if (!this.props.setQueryAsValue) {
      this.setState({ selectedValues, expanded: false });
    } else {
      if (nextProps.value) {
        const value = isArray(nextProps.value)
          ? first(nextProps.value)
          : nextProps.value;
        const query = nextProps.displayPath
          ? get(value, nextProps.displayPath, "")
          : value;
        this.setState({ query });
      }
    }

    if (this.props.values.length !== nextProps.values.length) {
      this.setState({ values: nextProps.values });
    }
  }

  public onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ query: value });
    if (this.props.asyncValues) {
      this.getAsyncValues(value);
    } else {
      this.getValues(value);
    }

    if (this.props.setQueryAsValue && !value) {
      this.props.onChange("", true);
    }
  }

  public onBlurHandler() {
    this.setState({ hasFocus: false });
  }

  private bindInputRef(ref: HTMLInputElement) {
    if (!ref || this.input) return;
    this.input = ref;
    if (this.props.inputRef) this.props.inputRef(ref);
  }

  private createOption(value: any, idx: number) {
    const matchingValues = this.state.selectedValues.filter((selectedValue) => {
      if (this.props.matchPath) {
        return (
          get(selectedValue, this.props.matchPath) ===
          get(value, this.props.matchPath)
        );
      }
      return selectedValue === value;
    });

    const optionStyle = classNames("form__select-option", {
      active: idx === this.state.selectedKey,
      selected: matchingValues.length > 0,
    });
    const view = this.props.optionView ? (
      this.props.optionView(value, this.state.query)
    ) : (
      <span
        dangerouslySetInnerHTML={StringUtil.highlight(
          get(value, this.props.displayPath),
          this.state.query
        )}
      />
    );

    return (
      <div
        className={optionStyle}
        key={idx}
        onClick={() => this.selectListValue(value)}
      >
        <span />
        {view}
      </div>
    );
  }

  private createManualOption(value: string) {
    return (
      <div className="form__select-option">
        <ResourceText
          resourceKey="manualOptionLabel"
          values={{ value }}
          asHtml
        />
      </div>
    );
  }

  private onFocusHandler() {
    this.setState({ hasFocus: true });
  }

  private onInputKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
    if (this.state.selectedValues.length === 0) return;
    if (event.keyCode === 27 || event.currentTarget.value.trim().length > 0) {
      const selectedValues = this.state.selectedValues.map((value) => ({
        ...value,
        taggedForDelete: false,
      }));
      return this.setState({ selectedValues });
    }
    if (event.keyCode !== 8) return;

    const hasTaggedOption = this.state.selectedValues.filter(
      (value) => !!value.taggedForDelete
    );
    if (hasTaggedOption.length > 0) {
      const selectedValues = this.state.selectedValues.filter(
        (value) => !value.taggedForDelete
      );
      this.mapValues(selectedValues);
      return this.setState({ selectedValues });
    }

    const count = this.state.selectedValues.length;
    const selectedValues = this.state.selectedValues.map((value, idx) => {
      if (idx === count - 1) {
        return { ...value, taggedForDelete: true };
      }
      return value;
    });

    this.mapValues(selectedValues);
    this.setState({ selectedValues });
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
    if (!this.state.expanded) return;
    const ENTER_KEY = 13,
      ESCAPE_KEY = 27,
      UP_ARROW = 38,
      DOWN_ARROW = 40;

    if (
      [ENTER_KEY, ESCAPE_KEY, UP_ARROW, DOWN_ARROW].indexOf(event.keyCode) !==
      -1
    )
      event.preventDefault();

    switch (event.keyCode) {
      case DOWN_ARROW: {
        const selectedKey =
          this.state.selectedKey >= this.state.values.length - 1
            ? 0
            : this.state.selectedKey + 1;
        this.setState({ selectedKey });
        break;
      }
      case UP_ARROW: {
        const selectedKey =
          this.state.selectedKey <= 0
            ? this.state.values.length - 1
            : this.state.selectedKey - 1;
        this.setState({ selectedKey });
        break;
      }
      case ENTER_KEY:
        const value = this.state.values[this.state.selectedKey];
        if (value !== null && value !== undefined) {
          this.selectListValue(value);
        }
        event.preventDefault();
        return;
      case ESCAPE_KEY:
        this.input.blur();
        return;
      default:
        return;
    }
  }

  private onInputFocusHandler() {
    if (!this.input) return;
    this.input.focus();
    if (this.state.query && this.state.query.length >= this.state.minLength) {
      this.setState({ expanded: true });
    }
  }

  private onEmptyStateClickHandler() {
    this.setState({ expanded: false });

    if (this.props.onAddManualOption) {
      this.props.onAddManualOption(this.state.query);
      this.setState({ query: "" });
    }
  }

  private onClickOutsideHandler(event: any) {
    if (this.ref && !this.ref.contains(event.target) && !!this.state.expanded)
      this.setState({ expanded: false, loading: false, hasFocus: false });
  }

  private renderQueryResultPill(
    value: any,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    if (value === null || value === undefined) return;
    const view = this.getQueryResultPillView(value);
    const partner = this.getPartner(value);
    const formQueryPillStyle = classNames("form__query-pill", {
      selected: !!value.taggedForDelete,
    });

    return (
      <span key={idx}>
        <div className={formQueryPillStyle}>
          <div className="query-pill__label" data-cy="CY-queryPill">
            {typeof view === "string" ? (
              <ResourceText resourceKey={view} />
            ) : (
              view
            )}
          </div>

          <div
            className="query-pill__remove"
            onClick={(event) => this.onPillRemoveHandler(event, idx)}
          >
            <i className="fal fa-fw fa-times faIcon" id={view.toString()} />
          </div>
        </div>
        {partner !== null && partner !== undefined && partner !== "" && (
          <div className={formQueryPillStyle}>
            <div className="query-pill__label" data-cy="CY-queryPill">
              {typeof partner === "string" ? (
                <ResourceText resourceKey={partner} />
              ) : (
                partner
              )}
            </div>

            <div
              className="query-pill__remove"
              onClick={(event) => this.onPillRemoveHandler(event, idx)}
            >
              <i className="fal fa-fw fa-times faIcon" id={view.toString()} />
            </div>
          </div>
        )}
      </span>
    );
  }

  private onPillRemoveHandler(
    event: React.MouseEvent<HTMLElement>,
    key: number
  ) {
    event.stopPropagation();

    const selectedValues = this.state.selectedValues.filter(
      (val, k) => key !== k
    );

    this.setState({ selectedValues });
    this.mapValues(selectedValues);
  }

  private getQueryResultPillView(
    value: any
  ): string | React.ReactElement<HTMLDivElement> {
    if (this.props.displayView)
      return this.props.displayView(value, this.state.query);
    if (this.props.valuePath && this.props.values) {
      const view = this.props.values.find(
        (v) => get(v, this.props.valuePath) === value
      );
      return !!view
        ? get(view, this.props.displayPath)
        : get(value, this.props.displayPath);
    }
    return has(value, this.props.displayPath)
      ? get(value, this.props.displayPath)
      : value;
  }

  private getPartner(value: any): string | React.ReactElement<HTMLDivElement> {
    // if (this.props.valuePath && this.props.values) {
    //   const view = this.props.values.find((v) => get(v, this.props.valuePath) === value);
    //   return (!!view) ? get(view, this.props.partnerDisplayPath) : get(value, this.props.partnerDisplayPath);
    // }
    // return (has(value, this.props.partnerDisplayPath)) ? get(value, this.props.partnerDisplayPath) : value;

    return "Martin Kip";
  }

  private getAsyncValues(value: string) {
    if (!value || value.length < this.state.minLength)
      return this.setState({ values: [], expanded: false });
    this.setState({ loading: true });

    this.props.asyncValues(value).then((result) => {
      const values =
        this.props.resultPath && has(result, this.props.resultPath)
          ? get(result, this.props.resultPath)
          : result;
      if (this.props.useQueryAsOption) {
        values.push({
          [this.props.displayPath]: this.state.query,
          manualOption: true,
        });
      }
      this.setState({ values, expanded: true, loading: false, selectedKey: 0 });
    });
  }

  private getValues(value: string) {
    if (!this.props.asSelect && (!value || value.length < this.state.minLength))
      return this.setState({ values: [], expanded: false });

    const values = this.props.values.filter((val) => {
      const match = new RegExp(escapeRegExp(value), "gi");
      const testValue: string = this.props.matchPath
        ? get(val, this.props.matchPath)
        : val;
      return match.test(testValue);
    });

    this.setState({ values, expanded: true, selectedKey: 0 });
  }

  private selectListValue(value: any) {
    const hasValue = this.state.selectedValues.find((v) => {
      return !!this.props.displayPath
        ? v[this.props.displayPath] === value[this.props.displayPath]
        : v === value;
    });

    if (!!hasValue) return this.setState({ expanded: false, query: "" });

    const selectedValues = [...this.state.selectedValues, value];
    if (this.props.setQueryAsValue) {
      this.setState({ query: value[this.props.displayPath], expanded: false });
      this.props.onChange(value);
    } else {
      this.setState({ selectedValues, expanded: false, query: "" });
      this.mapValues(selectedValues);
    }
    this.input.focus();
  }

  private onRemoveSelectedValue(value: any) {
    const selectedValues = this.state.selectedValues.filter((val) =>
      this.props.valuePath
        ? get(val, this.props.valuePath) !== get(value, this.props.valuePath)
        : val !== value
    );
    this.setState({ selectedValues });
    this.mapValues(selectedValues);
  }

  private onTriggerClickHandler() {
    this.setState({ expanded: !this.state.expanded });
  }

  private mapValues(selectedValues: any[]) {
    const values = selectedValues.map((val) =>
      this.props.valuePath ? get(val, this.props.valuePath) : val
    );
    this.props.onChange(values);
  }

  private renderDisplayPathValues(values: any) {
    values.forEach((value) => {
      this.selectListValue(value);
    });
  }
}
