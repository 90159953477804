import * as React from "react";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import * as deepEqual from "deep-equal";

import {
  Form,
  FormReturnValue,
  FormControls,
  FormReference,
  Input,
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import {
  ObjectAssignment,
  ListingTypeOption,
  HouseSortOption,
  ListingType,
  HouseTypeOption,
  HouseCharacteristicOption,
  ApartmentSortOption,
  ApartmentCharacteristicOption,
  TypeOfHabitationOption,
  TypeOfHabitation,
  TransactionMetaData,
  BuyerType,
} from "@haywork/api/kolibri";
import { TransactionMetaDataResponse } from "@haywork/middleware";

const styles = require("../../transaction-modal.component.scss");

interface Props {
  assignment: ObjectAssignment;
  transactionMetaData: Partial<TransactionMetaData>;
  listingTypes: ListingTypeOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  typeOfHabitationOptions: TypeOfHabitationOption[];
  selectedBuyerType?: BuyerType;
  onUpdate: (data: TransactionMetaDataResponse) => void;
}
interface State {
  listingType: ListingType | null;
  typeOfHabitation: TypeOfHabitation;
  showHouseSort: boolean;
  showHouseType: boolean;
  showHouseCharacteristic: boolean;
  showApartmentSort: boolean;
  showApartmentCharacteristic: boolean;
}

@CSSModules(styles, { allowMultiple: true })
export class ObjectLeftBehindForm extends React.Component<Props, State> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const showHouseSort = !!get(
      this.props.transactionMetaData,
      "leftBehindObject.houseSort"
    );
    const showHouseType =
      !!get(this.props.transactionMetaData, "leftBehindObject.houseSort") ||
      !!get(this.props.transactionMetaData, "leftBehindObject.houseType");
    const showHouseCharacteristic =
      !!get(this.props.transactionMetaData, "leftBehindObject.houseType") ||
      !!get(
        this.props.transactionMetaData,
        "leftBehindObject.houseCharacteristic"
      );

    const showApartmentSort = !!get(
      this.props.transactionMetaData,
      "leftBehindObject.apartmentSort"
    );
    const showApartmentCharacteristic =
      !!get(this.props.transactionMetaData, "leftBehindObject.apartmentSort") ||
      !!get(
        this.props.transactionMetaData,
        "leftBehindObject.apartmentCharacteristic"
      );

    const listingType = showHouseSort
      ? ListingType.House
      : showApartmentSort
      ? ListingType.Apartment
      : null;

    this.state = {
      listingType,
      typeOfHabitation: get(
        this.props.transactionMetaData,
        "leftBehindObject.typeOfHabitation",
        null
      ),
      showHouseSort,
      showHouseType,
      showHouseCharacteristic,
      showApartmentSort,
      showApartmentCharacteristic,
    };

    this.formControls = {
      postalCode: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.postalCode",
          ""
        ),
      },
      houseNumber: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumber",
          ""
        ),
      },
      houseNumberPostFix: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumberPostFix",
          ""
        ),
      },
      typeOfHabitation: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          ""
        ),
        onChange: (ref) => {
          this.setState({ typeOfHabitation: ref.value });

          return {
            transactionPrice: "",
            rentalPrice: "",
          };
        },
      },
      listingType: {
        value: listingType || "",
        onChange: (ref) => {
          this.setState({
            listingType: ref.value,
          });

          return {
            houseSort: "",
            houseType: "",
            houseCharacteristic: "",
            apartmentSort: "",
            apartmentCharacteristic: "",
          };
        },
      },
      houseSort: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseSort",
          ""
        ),
        onChange: () => {
          return {
            houseType: "",
            houseCharacteristic: "",
          };
        },
      },
      houseType: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseType",
          ""
        ),
        onChange: () => {
          return {
            houseCharacteristic: "",
          };
        },
      },
      houseCharacteristic: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseCharacteristic",
          ""
        ),
      },
      apartmentSort: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentSort",
          ""
        ),
        onChange: () => {
          return {
            apartmentCharacteristic: "",
          };
        },
      },
      apartmentCharacteristic: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentCharacteristic",
          ""
        ),
      },
      transactionPrice: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.transactionPrice",
          ""
        ),
      },
      rentalPrice: {
        value: get(
          this.props.transactionMetaData,
          "leftBehindObject.rentalPrice",
          ""
        ),
      },
    };

    this.bindFormRef = this.bindFormRef.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps) return;

    if (
      prevState.listingType !== this.state.listingType ||
      !deepEqual(prevProps.transactionMetaData, this.props.transactionMetaData)
    ) {
      const listingType = !!get(
        this.props.transactionMetaData,
        "leftBehindObject.houseSort"
      )
        ? ListingType.House
        : !!get(
            this.props.transactionMetaData,
            "leftBehindObject.apartmentSort"
          )
        ? ListingType.Apartment
        : this.state.listingType;

      const showHouseSort =
        listingType === ListingType.House ||
        !!get(this.props.transactionMetaData, "leftBehindObject.houseSort");
      const showHouseType =
        !!get(this.props.transactionMetaData, "leftBehindObject.houseSort") ||
        !!get(this.props.transactionMetaData, "leftBehindObject.houseType");
      const showHouseCharacteristic =
        !!get(this.props.transactionMetaData, "leftBehindObject.houseType") ||
        !!get(
          this.props.transactionMetaData,
          "leftBehindObject.houseCharacteristic"
        );
      const showApartmentSort =
        listingType === ListingType.Apartment ||
        !!get(this.props.transactionMetaData, "leftBehindObject.apartmentSort");
      const showApartmentCharacteristic =
        !!get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentSort"
        ) ||
        !!get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentCharacteristic"
        );

      this.setState({
        typeOfHabitation: get(
          this.props.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          null
        ),
        showHouseSort,
        showHouseType,
        showHouseCharacteristic,
        showApartmentSort,
        showApartmentCharacteristic,
      });

      this.formRef.update({
        postalCode: get(
          this.props.transactionMetaData,
          "leftBehindObject.postalCode",
          ""
        ),
        houseNumber: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumber",
          ""
        ),
        houseNumberPostFix: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseNumberPostFix",
          ""
        ),
        typeOfHabitation: get(
          this.props.transactionMetaData,
          "leftBehindObject.typeOfHabitation",
          ""
        ),
        // listingType,
        houseSort: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseSort",
          ""
        ),
        houseType: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseType",
          ""
        ),
        houseCharacteristic: get(
          this.props.transactionMetaData,
          "leftBehindObject.houseCharacteristic",
          ""
        ),
        apartmentSort: get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentSort",
          ""
        ),
        apartmentCharacteristic: get(
          this.props.transactionMetaData,
          "leftBehindObject.apartmentCharacteristic",
          ""
        ),
        transactionPrice: get(
          this.props.transactionMetaData,
          "leftBehindObject.transactionPrice",
          ""
        ),
        rentalPrice: get(
          this.props.transactionMetaData,
          "leftBehindObject.rentalPrice",
          ""
        ),
      });
    }
  }

  public render() {
    const { selectedBuyerType } = this.props;
    const showRequiredFields =
      [BuyerType.Starter, BuyerType.MovingOn].indexOf(selectedBuyerType) !== -1;

    return (
      <Form
        name="transaction-personal"
        formControls={this.formControls}
        form={this.bindFormRef}
        onChange={this.onChangeHandler}
      >
        <div styleName="inner">
          <div className="form__row">
            <div className="form__group stretch">
              <div className="column fit">
                <label htmlFor="postalCode">
                  <ResourceText resourceKey="saleTransaction.postalCode" />
                  {showRequiredFields && (
                    <Ui.RequiredForPublish hideHint={true} />
                  )}
                </label>
                <Input.Postalcode name="postalCode" />
              </div>
              <div className="column__spacer" />
              <div className="column fit" styleName="house-number">
                <label htmlFor="houseNumber">
                  <ResourceText resourceKey="saleTransaction.houseNumber" />
                  {showRequiredFields && (
                    <Ui.RequiredForPublish hideHint={true} />
                  )}
                </label>
                <Input.Number name="houseNumber" />
              </div>
              <div className="column__spacer" />
              <div className="column fit" styleName="house-number-postfix">
                <label htmlFor="houseNumberPostFix">
                  <ResourceText resourceKey="saleTransaction.houseNumberPostFix" />
                </label>
                <Input.Text name="houseNumberPostFix" />
              </div>
              <div className="column__spacer" />
              <div className="column">
                <label htmlFor="buyerType">
                  <ResourceText resourceKey="saleTransaction.typeOfHabitation" />
                  {showRequiredFields && (
                    <Ui.RequiredForPublish hideHint={true} />
                  )}
                </label>
                <Input.NewSelect
                  name="typeOfHabitation"
                  addEmptyOption
                  emptyOptionLabel="saleTransaction.typeOfHabitationDefault"
                  values={this.props.typeOfHabitationOptions}
                  displayProp="displayName"
                  valuesProp="value"
                />
              </div>
            </div>
          </div>

          {this.state.typeOfHabitation === TypeOfHabitation.Buy && (
            <div className="form__row">
              <label htmlFor="buyerType">
                <ResourceText resourceKey="saleTransaction.transactionPrice" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number name="transactionPrice" pretty={true} />
              </div>
            </div>
          )}

          {this.state.typeOfHabitation === TypeOfHabitation.Rent && (
            <div className="form__row">
              <label htmlFor="buyerType">
                <ResourceText resourceKey="saleTransaction.rentalPrice" />
              </label>
              <div className="input__helper">
                <div className="pre">&euro;</div>
                <Input.Number name="rentalPrice" pretty={true} />
              </div>
            </div>
          )}

          <div className="form__row">
            <label htmlFor="listingType">
              <ResourceText resourceKey="saleTransaction.listingType" />
            </label>
            <Input.RadioGroup name="listingType" asButtonList>
              {this.props.listingTypes.map((listingType, idx) => (
                <Input.Radio
                  label={listingType.displayName}
                  value={listingType.value}
                  key={idx}
                />
              ))}
            </Input.RadioGroup>
          </div>

          <div className="form__row">
            <div className="form__group stretch">
              {this.state.showHouseSort && (
                <div className="column__join">
                  <div className="column">
                    <Input.List
                      name="houseSort"
                      values={this.props.houseSorts}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
              {this.state.showHouseType && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.List
                      name="houseType"
                      values={this.props.houseTypes}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
              {this.state.showHouseCharacteristic && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.List
                      name="houseCharacteristic"
                      values={this.props.houseCharacteristics}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}

              {this.state.showApartmentSort && (
                <div className="column__join">
                  <div className="column">
                    <Input.List
                      name="apartmentSort"
                      values={this.props.apartmentSorts}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                    />
                  </div>
                </div>
              )}
              {this.state.showApartmentCharacteristic && (
                <div className="column__join">
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.List
                      name="apartmentCharacteristic"
                      values={this.props.apartmentCharacteristics}
                      displayPath="displayName"
                      comparePath="value"
                      resultPath="value"
                      data-cy="CY-apartmentCharacteristicsListingType"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) {
      this.formRef = ref;
    }
  }

  private onChangeHandler(values: FormReturnValue) {
    const data: TransactionMetaDataResponse = {
      leftBehindObject: {
        postalCode: values.postalCode,
        houseNumber: values.houseNumber,
        houseNumberPostFix: values.houseNumberPostFix,
        typeOfHabitation: values.typeOfHabitation,
        houseSort: values.houseSort,
        houseType: values.houseType,
        houseCharacteristic: values.houseCharacteristic,
        apartmentSort: values.apartmentSort,
        apartmentCharacteristic: values.apartmentCharacteristic,
        transactionPrice: values.transactionPrice,
        rentalPrice: values.rentalPrice,
      },
    };

    this.props.onUpdate(data);
  }
}
