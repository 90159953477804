import { createSelector } from "reselect";
import uniqBy from "lodash-es/uniqBy";

import { AppState } from "@haywork/stores";
import { MergeFieldCategory } from "@haywork/api/kolibri";

export const mergeFields = (
  state: AppState,
  categoryWhiteList: MergeFieldCategory[],
  categoryBlackList: MergeFieldCategory[]
) => {
  return !categoryWhiteList.length && !categoryBlackList.length
    ? state.emailV2.templates.mergeFields
    : !!categoryWhiteList.length
    ? state.emailV2.templates.mergeFields.filter(
        (mergeField) => categoryWhiteList.indexOf(mergeField.category) !== -1
      )
    : state.emailV2.templates.mergeFields.filter(
        (mergeField) => categoryBlackList.indexOf(mergeField.category) === -1
      );
};

export const mergeFieldCategories = createSelector(
  mergeFields,
  (mergeFields) => {
    return uniqBy(mergeFields, (field) => field.categoryDisplayName);
  }
);
