import * as React from "react";
import * as CSSModules from "react-css-modules";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("./overview.component.scss");

interface AccessDeniedModalComponentProps {
  showModal: boolean;
  triedToDelete: boolean;
  onClose: () => void;
}
interface AccessDeniedModalComponentState {
}

@CSSModules(styles, { allowMultiple: true })
export class AccessDeniedModalComponent extends React.Component<AccessDeniedModalComponentProps, AccessDeniedModalComponentState> {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  public render() {
    return <Modal visible={this.props.showModal} onClose={() => this.props.onClose()}>
      <ModalHeader title={this.props.triedToDelete ? "cantDeletePersonalAppointmentsHeader" : "cantChangePersonalAppointmentsHeader"} close />
      <ModalBody>
        <p><ResourceText resourceKey={this.props.triedToDelete ? "cantDeletePersonalAppointmentsBody" : "cantChangePersonalAppointmentsBody"}/></p>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={this.props.onClose}>
          <ResourceText resourceKey="ok" />
        </button>
      </ModalFooter>
    </Modal>;
  }
}
