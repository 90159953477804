import { createSelector } from "reselect";

import { AppState } from "@haywork/stores";

const eventsWithTypeAndAssignments = (state: AppState) =>
  state.eventCenter.dashboard.assignmentsNeedAttentionEvents;

export const events = createSelector(
  eventsWithTypeAndAssignments,
  (events) =>
    events.filter(
      (event) =>
        !!event.eventType &&
        !!event.linkedObjectAssignments &&
        !!event.linkedObjectAssignments.length
    )
);
