import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { APPXCHANGEROUTES } from "@haywork/constants";
import {
  AppXchangeAboutContainer,
  AppXchangeReviewsContainer,
  AppXchangeCostsContainer,
  AppXchangeSupportContainer,
  AppXchangeSettingsContainer,
} from "@haywork/modules/app-xchange";
import { AppXchangeSettingsType } from "../settings";
import { AuthorizationWidgets } from "@haywork/modules/shared";
import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";

const route = RouteUtil.mapStaticRouteValues;

export const AppXchangeDetailRouting = ({
  id,
  consentGiven,
  hideConsentGivenInfo,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={APPXCHANGEROUTES.DETAIL.URI}
        render={() => (
          <Redirect to={route(APPXCHANGEROUTES.ABOUT.URI, { id })} />
        )}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.ABOUT.URI}
        render={() => (
          <AppXchangeAboutContainer
            consentGiven={consentGiven}
            hideConsentGivenInfo={hideConsentGivenInfo}
          />
        )}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.REVIEWS.URI}
        component={AppXchangeReviewsContainer}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.COSTS.URI}
        component={AppXchangeCostsContainer}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.SUPPORT.URI}
        component={AppXchangeSupportContainer}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.COMPANY_SETTINGS.URI}
        render={() => (
          <AppXchangeSettingsContainer
            type={AppXchangeSettingsType.Company}
            appClientId={id}
            key={id + AppXchangeSettingsType.Company.toString()}
          />
        )}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.EMPLOYEE_SETTINGS.URI}
        render={() => (
          <AppXchangeSettingsContainer
            type={AppXchangeSettingsType.Employee}
            appClientId={id}
            key={id + AppXchangeSettingsType.Employee.toString()}
          />
        )}
      />
      <Route
        exact
        path={APPXCHANGEROUTES.WIDGET_PAGE.URI}
        render={({ match }) => (
          <AuthorizationWidgets
            location={WidgetEntityLocation.Menu}
            type={WidgetEntityType.AppXchange}
            menuType="page"
            id={id}
            widgetId={match.params.widgetId}
          />
        )}
      />
    </Switch>
  );
};
