import {
  FolderTreeRootFolderEntity,
  DefaultFolderTreeRootFolderEntity,
  FolderTreeFolderEntity,
  DefaultFolderTreeFolderEntity
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators
} from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@haywork/modules/modal";
import * as React from "react";
import { PureComponent } from "react";
import * as CSSModules from "react-css-modules";
import { ResourceText } from "../resource-text/resource-text.component";
import Folder from "./components/folder";
import get from "lodash-es/get";

const styles = require("./style.scss");

interface Props {
  visible: boolean;
  folderTree: FolderTreeRootFolderEntity | DefaultFolderTreeRootFolderEntity;
  folder?: FolderTreeFolderEntity | DefaultFolderTreeFolderEntity;
  folderPath?: string;
  onClose: () => void;
  onUpdateFolderTree: (
    folderTree: FolderTreeRootFolderEntity | DefaultFolderTreeRootFolderEntity
  ) => void;
}
interface State {
  valid: boolean;
  selectedPath: string;
  paths: string[];
}

@CSSModules(styles, { allowMultiple: true })
export class DossierAddFolderComponent extends PureComponent<Props, State> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      selectedPath: null,
      paths: []
    };

    this.formControls = {
      mapName: { value: "", validators: [Validators.required()] }
    };

    this.onSave = this.onSave.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.setSelectedPath = this.setSelectedPath.bind(this);
    this.cleanup = this.cleanup.bind(this);
    this.renderPathNames = this.renderPathNames.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.visible && !this.props.visible) {
      setTimeout(this.cleanup, 500);
    }

    if (!prevProps.visible && !!this.props.visible) {
      const { folderTree } = this.props;
      const { documentSessions, invoices, dossierItems } = folderTree;
      const documentSessionsPaths = this.renderPathNames(
        documentSessions.folders || [],
        documentSessions.name || ""
      );
      const invoicesPaths = this.renderPathNames(
        invoices.folders || [],
        invoices.name || ""
      );
      const dossierItemsPaths = this.renderPathNames(
        dossierItems.folders || [],
        dossierItems.name || ""
      );
      const paths = [
        ...documentSessionsPaths,
        ...invoicesPaths,
        ...dossierItemsPaths
      ];
      this.setState({ paths });
    }

    if (!prevProps.visible && !!this.props.visible && !!this.props.folder) {
      const { folder, folderPath } = this.props;
      this.form.update({ mapName: folder.name || "" });
      this.setState({ selectedPath: folderPath || null });
    }
  }

  public render() {
    let { dossierItems, invoices, documentSessions } = this.props.folderTree;
    if (!!this.props.folder) {
      dossierItems = {
        ...dossierItems,
        folders: this.clearEditPath(
          dossierItems.folders,
          dossierItems.name || ""
        )
      };
      invoices = {
        ...invoices,
        folders: this.clearEditPath(invoices.folders, invoices.name || "")
      };
      documentSessions = {
        ...documentSessions,
        folders: this.clearEditPath(
          documentSessions.folders,
          documentSessions.name || ""
        )
      };
    }

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="dossierAddFolder.modalTitle" close />
        <ModalBody noPadding>
          <div styleName="form">
            <Form
              name="add-new-dossier-folder"
              formControls={this.formControls}
              form={(form) => (this.form = form)}
              onSubmit={this.onSave}
              onChange={this.onFormChange}
            >
              <div className="form__row">
                <label htmlFor="mapName">
                  <ResourceText resourceKey="dossierAddFolder.label.mapName" />
                </label>
                <Input.Text name="mapName" fireAllChanges={true} />
              </div>

              <div className="form__row">
                <ResourceText resourceKey="dossierAddFolder.title.list" />
              </div>
            </Form>
          </div>
          <div styleName="list">
            {!!documentSessions && (
              <Folder
                tree={documentSessions}
                depth={1}
                parentName=""
                selectedPath={this.state.selectedPath}
                onClick={this.setSelectedPath}
              />
            )}
            {!!invoices && (
              <Folder
                tree={invoices}
                depth={1}
                parentName=""
                selectedPath={this.state.selectedPath}
                onClick={this.setSelectedPath}
              />
            )}
            {!!dossierItems && (
              <Folder
                tree={dossierItems}
                depth={1}
                parentName=""
                selectedPath={this.state.selectedPath}
                onClick={this.setSelectedPath}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.submitForm}
            disabled={!this.state.valid}
          >
            <ResourceText resourceKey="save" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private renderPathNames(
    folders: (DefaultFolderTreeFolderEntity | FolderTreeFolderEntity)[],
    parentName: string,
    initialState: string[] = []
  ): string[] {
    return folders.reduce((state, folder) => {
      const { folders, name } = folder;
      const pathname = [parentName, name].filter((d) => !!d).join("/");
      state.push(pathname);

      if (!!folders && !!folders.length) {
        state = this.renderPathNames(folders, pathname, state);
      }

      return state;
    }, initialState);
  }

  private clearEditPath(
    folders: (FolderTreeFolderEntity | DefaultFolderTreeFolderEntity)[],
    parentPath: string
  ) {
    const { folder, folderPath } = this.props;
    const pathname = [folderPath, folder.name].filter((d) => !!d).join("/");

    return (folders || []).reduce(
      (state, folder) => {
        const { name, folders } = folder;
        const path = [parentPath, name].filter((d) => !!d).join("/");

        switch (true) {
          case path === pathname: {
            return state;
          }
          case !!folders && !!folders.length: {
            state.push({
              ...folder,
              folders: this.clearEditPath(folders, path)
            });
            return state;
          }
          default: {
            state.push(folder);
            return state;
          }
        }
      },
      [] as (FolderTreeFolderEntity | DefaultFolderTreeFolderEntity)[]
    );
  }

  private cleanup() {
    this.setState({ selectedPath: null, valid: false });
    if (!!this.form) {
      this.form.update({ mapName: "" });
      this.form.clearErrors();
    }
  }

  private setSelectedPath(name: string) {
    const trimmedName = (name || "").trim();
    const selectedPath =
      trimmedName === this.state.selectedPath ? null : trimmedName;
    this.setState({ selectedPath });
    if (!this.form) return;
    const { mapName } = this.form.getValues();
    const pathname = [selectedPath, mapName].filter((d) => !!d).join("/");
    const valid =
      this.form.isValid() &&
      !!selectedPath &&
      !this.state.paths.includes(pathname);
    this.setState({ valid });
  }

  private onFormChange(values: FormReturnValue) {
    if (!this.form) return;
    const { mapName } = values;
    const pathname = [this.state.selectedPath, mapName]
      .filter((d) => !!d)
      .join("/");
    const valid =
      this.form.isValid() &&
      !!this.state.selectedPath &&
      !this.state.paths.includes(pathname);
    this.setState({ valid });
  }

  private submitForm() {
    if (!this.form) return;
    this.form.submit();
  }

  private onSave(values: FormReturnValue) {
    if (!this.state.valid) return;

    const { selectedPath } = this.state;
    const { dossierItems, documentSessions, invoices } = this.props.folderTree;
    const newEntity:
      | FolderTreeFolderEntity
      | DefaultFolderTreeFolderEntity = !!this.props.folder
      ? {
          ...this.props.folder,
          name: values.mapName
        }
      : {
          name: values.mapName,
          folders: []
        };
    const dossierItemsFolders = dossierItems.folders || [];
    const documentSessionsFolders = documentSessions.folders || [];
    const invoicesFolders = invoices.folders || [];

    let folderTree = {
      documentSessions: {
        ...documentSessions,
        folders:
          get(documentSessions, "name") === selectedPath
            ? [...documentSessionsFolders, newEntity]
            : this.updateChildren(
                documentSessionsFolders,
                documentSessions.name || "",
                selectedPath,
                newEntity
              )
      },
      invoices: {
        ...invoices,
        folders:
          get(invoices, "name") === selectedPath
            ? [...invoicesFolders, newEntity]
            : this.updateChildren(
                invoicesFolders,
                invoices.name || "",
                selectedPath,
                newEntity
              )
      },
      dossierItems: {
        ...dossierItems,
        folders:
          get(dossierItems, "name") === selectedPath
            ? [...dossierItemsFolders, newEntity]
            : this.updateChildren(
                dossierItemsFolders,
                dossierItems.name || "",
                selectedPath,
                newEntity
              )
      }
    };

    if (!!this.props.folder) {
      const { folder, folderPath } = this.props;
      const pathname = [folderPath, folder.name].filter((d) => !!d).join("/");

      folderTree = {
        documentSessions: this.recursivelyDeleteFolder(
          folderTree.documentSessions,
          folderTree.documentSessions.name,
          pathname
        ),
        invoices: this.recursivelyDeleteFolder(
          folderTree.invoices,
          folderTree.invoices.name,
          pathname
        ),
        dossierItems: this.recursivelyDeleteFolder(
          folderTree.dossierItems,
          folderTree.dossierItems.name,
          pathname
        )
      };
    }

    this.props.onUpdateFolderTree(folderTree);
  }

  private recursivelyDeleteFolder(
    tree: FolderTreeFolderEntity | DefaultFolderTreeFolderEntity,
    parentName: string,
    path: string
  ) {
    let { folders } = tree;
    folders = (folders || []).reduce(
      (state, folder) => {
        const name = [parentName, folder.name || ""]
          .filter((d) => !!d)
          .join("/");

        switch (true) {
          case path === name: {
            return state;
          }
          case !!folder.folders && !!folder.folders.length: {
            state.push(this.recursivelyDeleteFolder(folder, name, path));
            return state;
          }
          default: {
            state.push(folder);
            return state;
          }
        }
      },
      [] as (FolderTreeFolderEntity | DefaultFolderTreeFolderEntity)[]
    );

    return {
      ...tree,
      folders
    };
  }

  private updateChildren(
    folders: (FolderTreeFolderEntity | DefaultFolderTreeFolderEntity)[],
    parentName: string,
    pathname: string,
    newEntity: FolderTreeFolderEntity | DefaultFolderTreeFolderEntity
  ) {
    return folders.map((folder) => {
      const { name, folders } = folder;
      const path = [parentName, name].filter((d) => !!d).join("/");

      switch (true) {
        case path === pathname: {
          return {
            ...folder,
            folders: [...(folder.folders || []), newEntity]
          };
        }
        case !!folders && !!folders.length: {
          return {
            ...folder,
            folders: this.updateChildren(folders, path, pathname, newEntity)
          };
        }
        default: {
          return folder;
        }
      }
    });
  }
}
