import {
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentsClient,
  AssignmentType,
  ErrorResponse,
  ObjectAssignment,
  ObjectAssignmentsClient,
  ObjectAssignmentsSingleItemResponse,
  RealEstateGroup,
  SortOrder,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import { AppState, ProjectsActions } from "@haywork/stores";
import { AssignmentUtil, BackOfficeUtil } from "@haywork/util";
import first from "lodash-es/first";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();

const getObjectTypes = (projectId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      ProjectsActions.Buildnumbers.setObjectTypesStatus(REQUEST.PENDING)
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Assignments = new AssignmentsClient(host);

    try {
      const objectTypes = await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ModificationDate,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            filterByProjectAssignmentIds: [projectId],
            filterByAssignmentTypes: [AssignmentType.ObjectType],
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(ProjectsActions.Buildnumbers.setObjectTypes({ objectTypes }));
    } catch (error) {
      dispatch(
        ProjectsActions.Buildnumbers.setObjectTypesStatus(REQUEST.ERROR)
      );
      throw error;
    }
  };
};

export interface FailedBuildNumberRequest {
  buildNumber: number;
  error: ErrorResponse;
}
const defineAndSaveNewFromBatch = (
  buildnumbers: number[],
  objectTypeId: string,
  price: number,
  projectId: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    const { offices } = state.account;
    let officeId = "";
    if (offices.length > 0) {
      officeId = first(offices).id;
    }

    const ObjectAssignments = new ObjectAssignmentsClient(host);

    try {
      const newObjectAssignments = [];

      for (const buildNumber of buildnumbers) {
        const constructionNr = buildNumber.toString();
        const result = await parseRequest.response(
          ObjectAssignments.defineNewFromObjectType(
            {
              employeeId,
              officeId,
              objectTypeId,
              projectId,
              realEstateGroup: RealEstateGroup.Residential,
              constructionNr,
            },
            realEstateAgencyId
          )
            .then((response) => response.objectAssignment)
            .catch((error) => ({ buildNumber, error }))
        );

        newObjectAssignments.push(result);
      }

      const reduceState: {
        failed: FailedBuildNumberRequest[];
        succeeded: ObjectAssignmentsSingleItemResponse[];
      } = {
        failed: [],
        succeeded: [],
      };

      const { failed, succeeded } = newObjectAssignments.reduce(
        (state, objectAssignment) => {
          if (AssignmentUtil.isObjectAssigment(objectAssignment)) {
            state.succeeded.push(objectAssignment);
          } else {
            state.failed.push(objectAssignment);
          }

          return state;
        },
        reduceState
      );

      for (const objectAssignment of succeeded) {
        let assignment: ObjectAssignment = {
          ...objectAssignment,
        };

        switch (true) {
          case !!price && assignment.forRent: {
            assignment = {
              ...assignment,
              rentOffer: {
                ...assignment.rentOffer,
                rentPrice: price,
              },
            };
            break;
          }
          case !!price && assignment.forSale: {
            assignment = {
              ...assignment,
              saleOffer: {
                ...assignment.saleOffer,
                salePrice: price,
              },
            };
            break;
          }
          default: {
            break;
          }
        }

        try {
          await parseRequest.response(
            ObjectAssignments.save(
              {
                objectAssignment: assignment,
              },
              realEstateAgencyId
            )
          );
        } catch (error) {
          await parseRequest.response(
            ObjectAssignments.save(
              {
                objectAssignment: assignment,
              },
              realEstateAgencyId
            )
          );
        }
      }

      return failed;
    } catch (error) {
      throw error;
    }
  };
};

export const BuildnumbersThunks = {
  getObjectTypes,
  defineAndSaveNewFromBatch,
};
