import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { RelationSnapShot } from "@haywork/api/kolibri";
import Avatar from "../avatar";
import I18n from "@haywork/components/i18n";
import { RootEntityType } from "@haywork/api/event-center";

const styles = require("../../style.scss");

type Props = {
  relations: RelationSnapShot[];
  entityType: RootEntityType;
};

export const ListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(({ relations, entityType }) => {
    const title = useMemo(() => {
      switch (entityType) {
        case RootEntityType.ObjectAssignment:
        case RootEntityType.ObjectTypeAssignment:
        case RootEntityType.ProjectAssignment:
        case RootEntityType.AcquisitionAssignment:
        case RootEntityType.AcquisitionObjectAssignment:
        case RootEntityType.Agendaitem:
        case RootEntityType.Unknown:
        case RootEntityType.Task:
        case RootEntityType.ContactCompany:
        case RootEntityType.ContactPerson:
        case RootEntityType.SearchAssignment:
        case RootEntityType.Invoice: {
          return `presence.list.title.${entityType.toString()}`;
        }
        default: {
          return "presence.list.title";
        }
      }
    }, [entityType]);

    return !relations.length ? null : (
      <div styleName="list">
        <div styleName="list__header">
          <I18n value={title} values={{ count: relations.length }} />
        </div>
        {relations.map((relation) => (
          <div styleName="list__item" key={relation.id}>
            <Avatar relation={relation} small />
            <div styleName="list__displayname">{relation.displayName}</div>
          </div>
        ))}
      </div>
    );
  })
);
