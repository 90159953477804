import { RentCondition, RentConditionOption } from "@haywork/api/kolibri";
import { AssignmentThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  ProlongRentOfferModalComponent,
  ProlongRentOfferModalComponentProps,
} from "./prolong-rent-offer-modal.component";

interface StateProps {
  rentConditions: RentConditionOption[];
}
interface DispatchProps {
  prolongRentOffer: (
    id: string,
    rentedUntil: Date,
    realizedPerMonth: number,
    rentCondition: RentCondition
  ) => Promise<void>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  ProlongRentOfferModalComponentProps,
  AppState
> = (state) => {
  const { rentConditions } = state.main.mastertable.kolibri;

  return {
    rentConditions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ProlongRentOfferModalComponentProps
> = (dispatch) => ({
  prolongRentOffer: (
    id: string,
    rentedUntil: Date,
    realizedPerMonth: number,
    rentCondition: RentCondition
  ) =>
    dispatch(
      AssignmentThunks.prolongRentOffer(
        id,
        rentedUntil,
        realizedPerMonth,
        rentCondition
      )
    ),
});

export type ProlongRentOfferModalContainerProps = StateProps & DispatchProps;
export const ProlongRentOfferModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProlongRentOfferModalComponent);
