import PageHeader from "@haywork/components/ui/page-header";
import { AppXchangeCostsContainerProps } from "@haywork/modules/app-xchange";
import { MarkDown } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { ToggleConsent } from "../toggle-consent";

const styles = require("./costs.component.scss");

export type AppXchangeCostsComponentProps = {};
type Props = AppXchangeCostsComponentProps & AppXchangeCostsContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AppXchangeCostsComponent extends React.Component<Props> {
  public render() {
    const { financeDescription } = this.props.translation;

    return (
      <div styleName="costs">
        <PageHeader title="appXchangeCostsTitle" actions={<ToggleConsent />} />

        <div styleName="costs__body">
          <div styleName="pricing">
            <MarkDown markdown={financeDescription} />
          </div>
        </div>
      </div>
    );
  }
}
