import { RelationGroupSnapShot } from "@haywork/api/kolibri";
import { ColorUtil } from "@haywork/util";
import * as React from "react";

const color = ColorUtil.hexToRgb;

export interface ExtendedRelationGroupSnapShot extends RelationGroupSnapShot {
  isReadOnly: boolean;
  markedForDelete: boolean;
  displayName?: string;
}

interface PillComponentProps {
  group: ExtendedRelationGroupSnapShot;
  onRemove: (id: string) => void;
}
interface PillComponentState {
  styles: React.CSSProperties;
}

export class PillComponent extends React.Component<
  PillComponentProps,
  PillComponentState
> {
  private defaultStyle: React.CSSProperties;
  private hoverStyle: React.CSSProperties;

  constructor(props) {
    super(props);

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);

    this.defaultStyle = {
      backgroundColor: "#ffffff",
      color: color(this.props.group.backColor),
      borderColor: color(this.props.group.backColor),
    };

    this.hoverStyle = {
      backgroundColor: color(this.props.group.backColor),
      color: "#ffffff",
      borderColor: color(this.props.group.backColor),
    };

    this.state = {
      styles: this.defaultStyle,
    };
  }

  public render() {
    const iconClass = !this.props.group.isReadOnly
      ? "fal fa-times"
      : "fal fa-lock-alt";
    const { displayName, name } = this.props.group;
    const renderName = displayName || name;

    return (
      <div
        className="pill"
        style={this.state.styles}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        <div className="value">{renderName}</div>
        <div
          className="remove"
          onClick={() => this.props.onRemove(this.props.group.id)}
        >
          <i className={iconClass} />
        </div>
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PillComponentProps) {
    if (!nextProps) return;

    const styles = !!nextProps.group.markedForDelete
      ? this.hoverStyle
      : this.defaultStyle;
    this.setState({ styles });
  }

  private onMouseEnterHandler() {
    this.setState({ styles: this.hoverStyle });
  }

  private onMouseLeaveHandler() {
    this.setState({ styles: this.defaultStyle });
  }
}
