import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import {
  AssignmentSnapShot,
  AssignmentType,
  RelationType,
} from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";
import I18n from "@haywork/components/i18n";

const styles = require("./assignment-item.component.scss");

interface Props {
  onAssignmentClick: (id: string, typeOfAssignment: AssignmentType) => void;
  assignment: AssignmentSnapShot;
  query: string;
  active: boolean;
  list: HTMLDivElement;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class GlobalSearchAssignmentItemComponent extends React.Component<
  Props,
  State
> {
  private ref: HTMLDivElement;

  public render() {
    const {
      displayName,
      photo1Url,
      typeOfAssignment,
      id,
      isActive,
      publicReference,
      linkedClients,
    } = this.props.assignment;
    const avatarStyles = !!photo1Url
      ? { backgroundImage: `url(${JSON.stringify(photo1Url)})` }
      : null;
    const assignmentStyle = classNames("assignment", {
      active: this.props.active,
    });

    if (this.props.active && this.props.list) this.setListPosition();

    return (
      <div
        styleName={assignmentStyle}
        onClick={() => this.props.onAssignmentClick(id, typeOfAssignment)}
        ref={(ref) => (this.ref = ref)}
      >
        <div styleName="assignment__avatar" style={avatarStyles}>
          {!photo1Url ? (
            typeOfAssignment === AssignmentType.Acquisition ? (
              <i className="fal fa-handshake-alt" />
            ) : (
              <i className="fal fa-home" />
            )
          ) : null}
        </div>
        <div styleName="assignment__name">
          {!!publicReference && (
            <div
              styleName={classNames("section", "pubref", {
                inactive: !isActive,
              })}
            >
              {publicReference}
            </div>
          )}

          {(linkedClients || []).map((client) => (
            <div styleName="section extra" key={client.id}>
              <span
                className={
                  client.typeOfRelation === RelationType.ContactCompany
                    ? "fal fa-company"
                    : "fal fa-user"
                }
              />
              <span>{client.displayName}</span>
            </div>
          ))}

          {!!displayName && (
            <div styleName="section">
              <span
                dangerouslySetInnerHTML={StringUtil.highlight(
                  displayName,
                  this.props.query
                )}
              />
            </div>
          )}

          {!isActive && (
            <div styleName="section extra">
              <ResourceText resourceKey="archived" />
            </div>
          )}
        </div>
        <div styleName="assignment__type">
          {this.renderTypeText(typeOfAssignment)}
        </div>
      </div>
    );
  }

  private setListPosition() {
    if (!this.ref) return;
    const { clientHeight, scrollTop } = this.props.list;
    const top = this.ref.offsetTop;
    const bottom = top + this.ref.clientHeight;

    if (bottom > clientHeight + scrollTop || top < scrollTop) {
      this.props.list.scrollTop =
        top + this.ref.clientHeight / 2 - clientHeight / 2;
    }
  }

  private renderTypeText(typeOfAssignment: AssignmentType) {
    switch (typeOfAssignment) {
      case AssignmentType.Object: {
        const { listingType } = this.props.assignment;
        return (
          <ResourceText
            masterKey="listingTypes"
            resourceKey={listingType.toString()}
          />
        );
      }
      default: {
        return (
          <I18n prefix="assignmentTypes" value={typeOfAssignment.toString()} />
        );
      }
    }
  }
}
