import { RealEstateAgency, CountryOption } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { RealEstateAgencyEditContainerProps } from "@haywork/modules/real-estate-agency";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil, StringUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

const styles = require("./edit.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface RealEstateAgencyEditComponentProps {}
interface RealEstateAgencyEditComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class RealEstateAgencyEditComponent extends React.Component<
  RealEstateAgencyEditComponentProps & RealEstateAgencyEditContainerProps,
  RealEstateAgencyEditComponentState
> {
  private formControls: FormControls;
  private form: FormReference;
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    const { realEstateAgency } = this.props;

    this.formControls = {
      name: {
        value: value(realEstateAgency, "name"),
        validators: [Validators.required()],
      },
      legalRegistrationName: {
        value: value(realEstateAgency, "legalRegistration.name"),
      },
      description: {
        value: value(realEstateAgency, "description"),
      },
      legalRegistrationNumber: {
        value: value(realEstateAgency, "legalRegistration.number"),
      },
      legalRegistrationDate: {
        value: value(realEstateAgency, "legalRegistration.date"),
      },
      legalRegistrationLocality: {
        value: value(realEstateAgency, "legalRegistration.locality"),
      },
      legalRegistrationCountryIso2: {
        value: value(realEstateAgency, "legalRegistration.countryIso2"),
      },
      bankAccountLocalityName: {
        value: value(realEstateAgency, "bankAccount.localityName"),
      },
      bankAccountNumber: {
        value: value(realEstateAgency, "bankAccount.number"),
      },
      bankAccountName: {
        value: value(realEstateAgency, "bankAccount.name"),
      },
    };

    // this.countrySelectedDisplay = this.countrySelectedDisplay.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);
    this.renderFlagClassName = this.renderFlagClassName.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFormSubmitHandler = this.onFormSubmitHandler.bind(this);
  }

  public render() {
    const { realEstateAgency, realEstateAssociations } = this.props;
    const realEstateAccociation = realEstateAssociations.find(
      (info) =>
        info.value === realEstateAgency.associationInfo.realEstateAssociation
    );
    const loading = this.props.realEstateAgencyStatus === REQUEST.PENDING;

    return (
      <div
        ref={(ref) => (this.ref = ref)}
        styleName="real-estate-agency"
        id="scroll-to-top"
      >
        <PageHeader
          title="pageTitle.realEstateAgency.general"
          subTitle={realEstateAgency.name || ""}
          actions={
            <Button
              label="save"
              category="success"
              onClick={() => this.form.submit()}
            />
          }
        />

        <div styleName="body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <div className="form__row">
            <Form
              formControls={this.formControls}
              name="office"
              onChange={this.onChangeHandler}
              onSubmit={this.onFormSubmitHandler}
              model={this.props.realEstateAgency}
              form={(formRef) => (this.form = formRef)}
            >
              <div styleName="real-estate-agency-form">
                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="displayName">
                        <I18n value="realEstateAdministrationCompanyName" />
                      </label>
                      <Input.Text
                        name="name"
                        placeholder="realEstateAdministrationCompanyName"
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="legalRegistrationName">
                        <I18n value="realEstateAdministrationStatutoryName" />
                      </label>
                      <Input.Text
                        name="legalRegistrationName"
                        placeholder="realEstateAdministrationCompanyName"
                      />
                    </div>
                  </div>
                </div>
                <h2>
                  <I18n value="aboutTheOffice" />
                </h2>
                <div styleName="general__meta">
                  <div styleName="row">
                    <div styleName="column">
                      <h3>
                        <I18n value="branchOrganisation" />
                      </h3>
                      {!!realEstateAccociation &&
                        realEstateAccociation.displayName}
                    </div>
                    <div styleName="column">
                      <h3>
                        <I18n value="membershipNumber" />
                      </h3>
                      {realEstateAgency.associationInfo.membershipNumber}
                    </div>
                  </div>

                  <span className="tool-tip">
                    <i className="fa fa-fw fa-info-circle" />
                    <I18n value="contactBranchOrganisation" />
                  </span>
                </div>

                <h2>
                  <I18n value="chamberOfCommerce" />
                </h2>

                <div className="form__row">
                  <label htmlFor="legalRegistrationNumber">
                    <I18n value="commerceNumber" />
                  </label>
                  <Input.Text name="legalRegistrationNumber" />
                </div>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="legalRegistrationDate">
                        <I18n value="realEstateAgencyRegisteredOn" />
                      </label>
                      <Input.Datepicker name="legalRegistrationDate" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="legalRegistrationLocality">
                        <I18n value="realEstateAgencyRegisteredAt" />
                      </label>
                      <Input.Text name="legalRegistrationLocality" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="legalRegistrationCountryIso2">
                        <I18n value="realEstateAdministrationIn" />
                      </label>
                      <Input.NewSelect
                        name="legalRegistrationCountryIso2"
                        values={this.props.countries}
                        valuesProp="iso2CodeValue"
                        filterProp="displayName"
                        displayFn={this.renderCountryValue}
                      />
                    </div>
                  </div>
                </div>

                <h2>
                  <I18n value="bankInfo" />
                </h2>

                <div className="form__row">
                  <div className="form__group stretch">
                    <div className="column">
                      <label htmlFor="bankAccountNumber">
                        <I18n value="realEstateAgencyIBAN" />
                      </label>
                      <Input.Text name="bankAccountNumber" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="bankAccountName">
                        <I18n value="realEstateAgencyAppointment" />
                      </label>
                      <Input.Text name="bankAccountName" />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label htmlFor="bankAccountLocalityName">
                        <I18n value="realEstateAgencyLocality" />
                      </label>
                      <Input.Text name="bankAccountLocalityName" />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return "famfamfam-flag-" + isoCode.toLowerCase();
  }

  // private countrySelectedDisplay(value: any) {
  //   const country = this.props.countries.find((c) => c.iso2CodeValue === value);
  //   return (
  //     <div className="form__select-option">
  //       <span className={this.renderFlagClassName(value)} />{" "}
  //       {!!country && country.displayName}
  //     </div>
  //   );
  // }

  private getRealEstateAgencyFromFormValues(
    values: FormReturnValue
  ): RealEstateAgency {
    const { realEstateAgency } = this.props;

    const updatedRealEstateAgency = {
      ...realEstateAgency,
      name: values.name,
      legalRegistration: {
        name: values.legalRegistrationName,
        number: values.legalRegistrationNumber,
        date: values.legalRegistrationDate,
        locality: values.legalRegistrationLocality,
        countryIso2: values.legalRegistrationCountryIso2,
      },
      description: values.description,
      bankAccount: {
        number: values.bankAccountNumber,
        name: values.bankAccountName,
        localityName: values.bankAccountLocalityName,
      },
    };

    return updatedRealEstateAgency;
  }

  private onChangeHandler(values: FormReturnValue) {
    const updatedRealEstateAgency =
      this.getRealEstateAgencyFromFormValues(values);
    this.props.updateRealEstateAgencyCache(
      updatedRealEstateAgency,
      this.props.path
    );
  }

  private onFormSubmitHandler(values: FormReturnValue) {
    const updatedRealEstateAgency =
      this.getRealEstateAgencyFromFormValues(values);
    this.props.save(updatedRealEstateAgency, this.props.path);
  }

  private renderCountryValue(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { selected, active })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}
