import {
  CommunicationLog,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Pill, {
  AssignmentPill,
  RelationPill,
} from "@haywork/components/ui/pill";
import { NewEntityType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { abortCommunicationLog } from "@haywork/middleware/thunk/emailV2/communication-logs";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";
import SaveModal from "../save-modal";
import { CommunicationLogContainerProps } from "./communication-log.container";

const styles = require("./style.scss");

export type CommunicationLogComponentProps = {
  communicationLogId: string | null | undefined;
  onUpdated: (communicationLog: CommunicationLog) => void;
};
type Props = CommunicationLogComponentProps & CommunicationLogContainerProps;

export const CommunicationLogComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      communicationLogId,
      getCommunicationLog,
      navigate,
      currentMessage,
      onUpdated,
      readMessage,
    }) => {
      const [relations, setRelations] = useState([] as LinkedRelation[]);
      const [assignments, setAssignments] = useState([] as LinkedAssignment[]);
      const [showExtraEntities, setShowExtraEntities] = useState(false);
      const [loading, setLoading] = useState(false);
      const [saveModalVisible, setSaveModalVisible] = useState(false);
      const [newEntityVisible, setNewEntityVisible] = useState(false);
      const [newEntityOptions, setNewEntityOptions] = useState(
        null as NewEntityOptions
      );
      const [communicationLog, setCommunicationLog] =
        useState<CommunicationLog | null>(null);

      const toggleExtraEntities = useCallback(() => {
        setShowExtraEntities((showExtraEntities) => !showExtraEntities);
      }, []);

      const clearLinkedEntities = useCallback(() => {
        setRelations([]);
        setAssignments([]);
        setShowExtraEntities(false);
      }, []);

      const fetchCommunicationLog = useCallback(async () => {
        clearLinkedEntities();

        if (!communicationLogId) {
          setCommunicationLog(null);
          return;
        }

        try {
          setLoading(true);

          const log = await getCommunicationLog(communicationLogId);
          if (!log) return;
          setCommunicationLog(log);

          const { linkedRelations, linkedAssignments } = log;
          setRelations(linkedRelations || []);
          setAssignments(linkedAssignments || []);

          if (!!onUpdated && !!log) {
            onUpdated(log);
          }

          return log;
        } finally {
          setLoading(false);
        }
      }, [
        communicationLogId,
        getCommunicationLog,
        clearLinkedEntities,
        onUpdated,
      ]);

      const onOpenSaveModal = useCallback(async () => {
        if (!communicationLog) {
          const response = await readMessage(
            currentMessage.id,
            currentMessage.accountId,
            currentMessage.folderId
          );
          if (!!response?._metaData?.linkedCommunicationLogId) {
            const log = await getCommunicationLog(
              response._metaData.linkedCommunicationLogId
            );
            setCommunicationLog(log);
          }
        }

        setSaveModalVisible(true);
      }, [currentMessage, communicationLog, readMessage, getCommunicationLog]);

      const onCloseSaveModal = useCallback(
        async (refresh = false) => {
          setSaveModalVisible(false);
          if (refresh) {
            const communicationLog = await fetchCommunicationLog();
            if (!!onUpdated && !!communicationLog) {
              onUpdated(communicationLog);
            }
          }
        },
        [communicationLogId, fetchCommunicationLog, onUpdated]
      );

      const linkedEntities = useMemo(() => {
        const totalCount = relations.length + assignments.length;
        if (!totalCount) return null;

        let extraCount = 0;
        let slicedRelations = [...relations];
        let slicedAssignments = [...assignments];

        if (totalCount > 4) {
          extraCount = totalCount - 3;
          if (!showExtraEntities) {
            if (slicedRelations.length >= 3) {
              slicedRelations = slicedRelations.slice(0, 3);
              slicedAssignments = [];
            } else {
              slicedAssignments = slicedAssignments.slice(
                0,
                3 - slicedRelations.length
              );
            }
          }
        }

        return (
          <div>
            {slicedRelations.map((relation) => (
              <RelationPill
                relation={relation}
                key={relation.id}
                onClick={navigate}
              />
            ))}
            {slicedAssignments.map((assignment) => (
              <AssignmentPill
                assignment={assignment}
                key={assignment.id}
                onClick={navigate}
              />
            ))}
            {!!extraCount && (
              <Pill
                label={
                  showExtraEntities
                    ? "email.linkedEntities.collapse"
                    : "email.linkedEntities.expand"
                }
                labelValues={{ count: extraCount }}
                color={Colors.Primary}
                onClick={toggleExtraEntities}
              />
            )}
            <Pill
              label="email.linkedEntities.edit"
              color={Colors.Primary}
              onClick={onOpenSaveModal}
            />
          </div>
        );
      }, [
        relations,
        assignments,
        navigate,
        showExtraEntities,
        toggleExtraEntities,
        onOpenSaveModal,
      ]);

      const onSaveModalAddRelation = useCallback(
        (name: string, communicationLog: CommunicationLog) => {
          setNewEntityOptions({
            type: NewEntityType.Relation,
            newRelation: {
              name,
            },
          });

          setSaveModalVisible(false);
          setNewEntityVisible(true);
          setCommunicationLog(communicationLog);
        },
        []
      );

      const onNewEntityCloseHandler = useCallback(() => {
        setNewEntityVisible(false);
        setNewEntityOptions(null);
      }, []);

      const onNewRelationHandler = useCallback(
        (relation: RelationSnapShot) => {
          onNewEntityCloseHandler();
          setCommunicationLog((communicationLog) => ({
            ...communicationLog,
            linkedRelations: [
              ...(communicationLog.linkedRelations || []),
              relation,
            ],
          }));
          setSaveModalVisible(true);
        },
        [onNewEntityCloseHandler]
      );

      useEffect(() => {
        fetchCommunicationLog();
        return () => {
          abortCommunicationLog.abort();
        };
      }, [fetchCommunicationLog]);

      if (loading) return null;

      return (
        <div styleName="linked-entities">
          {!linkedEntities ? (
            <Pill
              label="email.linkedEntities.add"
              color={Colors.Success}
              icon="save"
              solid
              onClick={onOpenSaveModal}
            />
          ) : (
            <>
              <div styleName="title">
                <I18n value="email.linkedEntities.title" />
              </div>
              {linkedEntities}
            </>
          )}

          {saveModalVisible && (
            <SaveModal
              visible
              message={currentMessage}
              communicationLog={communicationLog}
              onClose={onCloseSaveModal}
              onAddRelation={onSaveModalAddRelation}
            />
          )}

          <NewEntity
            visible={newEntityVisible}
            options={newEntityOptions}
            onClose={onNewEntityCloseHandler}
            onNewRelation={onNewRelationHandler}
          />
        </div>
      );
    }
  )
);
