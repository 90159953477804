import { intlContext } from "@haywork/app";
import { ModalPortal } from "@haywork/portals";
import classNames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { InputComponentProps } from "../input.component";
import { Calender } from "./../../calender/calender.component";

interface DatepickerInputComponentProps {
  showOnFocus?: boolean;
  placeholder?: string;
  hour?: number;
  minutes?: number;
  seconds?: number;
}
interface DatepickerInputComponentState {
  date: Date;
  expanded: boolean;
  pretty: string;
  position: ClientRect;
}

export class DatepickerComponent extends React.Component<
  DatepickerInputComponentProps & InputComponentProps,
  DatepickerInputComponentState
> {
  private input: HTMLInputElement;
  private ref: HTMLDivElement;

  public constructor(props) {
    super(props);

    // Initial state
    const date = this.renderDate(this.props.value || new Date());
    this.state = {
      date,
      expanded: false,
      pretty: this.props.value ? this.prettyDate(date) : "",
      position: null,
    };

    // Event bindings
    this.onTriggerClickHandler = this.onTriggerClickHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onDateSelectHandler = this.onDateSelectHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.bindRef = this.bindRef.bind(this);
    this.onCalenderCloseHandler = this.onCalenderCloseHandler.bind(this);
    this.bindInputRef = this.bindInputRef.bind(this);
  }

  public render() {
    const placeholder = this.props.placeholder
      ? intlContext.formatMessage({
          id: this.props.placeholder,
          defaultMessage: this.props.placeholder,
        })
      : null;
    const triggerStyle = classNames("trigger", {
      expanded: this.state.expanded,
    });

    return (
      <div
        className="datepicker__input"
        ref={this.bindRef}
        onKeyDown={this.onKeyDownHandler}
      >
        <div className={triggerStyle} onClick={this.onTriggerClickHandler}>
          <i
            className="far fa-fw fa-calendar-alt faIcon datepicker"
            style={{ fontSize: 20 }}
          />
        </div>
        <ModalPortal>
          <Calender
            expanded={this.state.expanded}
            date={this.state.date}
            onDateSelect={this.onDateSelectHandler}
            inputPosition={this.state.position}
            onClose={this.onCalenderCloseHandler}
            data-cy={this.props["data-cy"] && `${this.props["data-cy"]}.Cal`}
          />
        </ModalPortal>
        <input
          type="text"
          name={this.props.name}
          id={this.props.name}
          placeholder={placeholder}
          value={this.state.pretty}
          onFocus={this.onFocusHandler}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          ref={this.bindInputRef}
          disabled={this.props.disabled}
          data-cy={this.props["data-cy"]}
          data-lpignore="true"
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: DatepickerInputComponentProps & InputComponentProps
  ) {
    if (!nextProps) return;

    if (nextProps.value !== this.props.value) {
      this.setState({
        pretty: this.prettyDate(nextProps.value),
        date: this.renderDate(nextProps.value),
      });
    }

    if (
      nextProps.shouldFocusOnError !== this.props.shouldFocusOnError &&
      nextProps.shouldFocusOnError
    ) {
      this.ref.focus();
    }

    if (!!nextProps.focus && !!this.ref) {
      this.input.focus();
    }
  }

  public onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ pretty: value });
  }

  private onCalenderCloseHandler() {
    this.setState({ expanded: false, position: null });
  }

  private onTriggerClickHandler(event: React.MouseEvent<HTMLElement>) {
    if (this.props.disabled) return;
    const expanded = !this.state.expanded;
    const position =
      !!expanded && this.input ? this.input.getBoundingClientRect() : null;
    this.setState({ expanded, position });
    if (this.props.onFocus) {
      this.props.onFocus(null);
    }
  }

  private onFocusHandler(event: React.FocusEvent<HTMLInputElement>) {
    if (!!this.props.onFocus) {
      this.props.onFocus(event);
    }
    const expanded = this.props.showOnFocus && !this.state.expanded;
    const position =
      !!expanded && this.input ? this.input.getBoundingClientRect() : null;
    return this.setState({ expanded, position });
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    if (this.state.expanded) return this.input.focus();
    const { value } = event.currentTarget;
    if (!value) {
      return this.props.onChange("");
    }

    const date = this.renderDate(value, true);

    this.setState({ date, pretty: this.prettyDate(date) });
    this.props.onChange(date);
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
    switch (event.keyCode) {
      case 9: {
        if (this.state.expanded) {
          event.preventDefault();
          const date: Date = this.state.date;
          const month = event.shiftKey
            ? date.getMonth() - 1
            : date.getMonth() + 1;
          const newDate = new Date(date.getFullYear(), month, date.getDate());
          this.setState({ date: newDate });
        }
        break;
      }
      case 37: {
        if (this.state.expanded) {
          event.preventDefault();
          const date: Date = this.state.date;
          const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - 1
          );
          this.setState({ date: newDate });
        }
        break;
      }
      case 38: {
        if (this.state.expanded) {
          event.preventDefault();
          const date: Date = this.state.date;
          const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - 7
          );
          this.setState({ date: newDate });
        }
        break;
      }
      case 39: {
        if (this.state.expanded) {
          event.preventDefault();
          const date: Date = this.state.date;
          const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1
          );
          this.setState({ date: newDate });
        }
        break;
      }
      case 40: {
        if (this.state.expanded) {
          event.preventDefault();
          const date: Date = this.state.date;
          const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 7
          );
          this.setState({ date: newDate });
        }
        break;
      }
      case 13:
        event.preventDefault();
        if (this.state.expanded)
          return this.onDateSelectHandler(this.state.date);
        const position = this.input ? this.input.getBoundingClientRect() : null;
        return this.setState({
          expanded: true,
          date: this.renderDate(this.props.value),
          position,
        });
      case 27:
        return this.setState({ expanded: false, position: null });
      default:
        return;
    }
  }

  private onDateSelectHandler(value: Date) {
    this.input.focus();

    const date = this.renderDate(value);

    this.props.onChange(date);
    this.setState({
      date,
      pretty: this.prettyDate(date),
      expanded: false,
      position: null,
    });
  }

  private bindRef(ref: HTMLDivElement) {
    if (!!ref && !this.ref) this.ref = ref;
  }

  private bindInputRef(ref: HTMLInputElement) {
    if (!!ref && !this.input) {
      this.input = ref;
      this.setState({ position: ref.getBoundingClientRect() });
    }
  }

  private renderDate(date?: Date | string, fromBlur: boolean = false): Date {
    try {
      const refDate = date || this.props.value;
      const format = fromBlur ? "DD-MM-YYYY" : moment.ISO_8601;
      const parsedDate = moment(refDate, format);

      if (!refDate || !parsedDate.isValid()) {
        return null;
      }

      const hour = this.props.hour !== undefined ? this.props.hour : 0;
      const minutes = this.props.minutes !== undefined ? this.props.minutes : 0;
      const seconds = this.props.seconds !== undefined ? this.props.seconds : 0;

      const utcDate = parsedDate
        .set("hours", hour)
        .set("minutes", minutes)
        .set("seconds", seconds)
        .utc()
        .toDate();

      return utcDate;
    } catch (error) {
      return null;
    }
  }

  private prettyDate(date: Date): string {
    if (!date) return "";
    return moment(date).locale("nl").format("dd DD-MM-YYYY");
  }
}
