import { createSelector } from "reselect";
import get from "lodash-es/get";
import * as moment from "moment";
import isArray from "lodash-es/isArray";
import has from "lodash-es/has";

import { AppState } from "@haywork/stores";
import {
  ProjectAssignment,
  Language,
  PublicationSnapShot,
  AssignmentType,
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { intlContext } from "@haywork/app";

const projectSelector = (state: AppState) =>
  state.project.single.projectAssignment;
const editableProjectSelector = (state: AppState) =>
  state.editable.currentComponentState.projectAssignment;
const loadingBuildnumbersSelector = (state: AppState) =>
  state.project.single.buildnumbersStatus;
const loadingObjectTypesSelector = (state: AppState) =>
  state.project.single.objectTypesStatus;
const objectTypeSelector = (state: AppState) =>
  state.project.single.objectTypes;
const publicationsSelector = (state: AppState) =>
  state.editable.currentComponentState.publications;
const realEstateAgencySelector = (state: AppState) =>
  state.realEstateAgency.realEstateAgency;
const enabledAssignmentTypesSelector = (state: AppState) =>
  state.company.settings.enabledAssignmentTypes || [];
const editableStatesSelector = (state: AppState) => state.editable.states || [];

const subtractBuildingStartText = (project: ProjectAssignment) => {
  let text: string;
  if (isArray(project.buildingStartText)) {
    text = get(
      project.buildingStartText.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const buildingStartText = createSelector(
  projectSelector,
  subtractBuildingStartText
);
export const editableBuildingStartText = createSelector(
  editableProjectSelector,
  subtractBuildingStartText
);

const subtractDeliveryStartText = (project: ProjectAssignment) => {
  let text: string;
  if (isArray(project.deliveryStartText)) {
    text = get(
      project.deliveryStartText.find(
        (text) => text.language === Language.Dutch
      ),
      "text"
    );
  }
  return text;
};
export const deliveryStartText = createSelector(
  projectSelector,
  subtractDeliveryStartText
);
export const editableDeliveryStartText = createSelector(
  editableProjectSelector,
  subtractDeliveryStartText
);

const subtractsaleStartText = (project: ProjectAssignment) => {
  let text: string;
  if (isArray(project.saleStartText)) {
    text = get(
      project.saleStartText.find((text) => text.language === Language.Dutch),
      "text"
    );
  }
  return text;
};
export const saleStartText = createSelector(
  projectSelector,
  subtractsaleStartText
);
export const editableSaleStartText = createSelector(
  editableProjectSelector,
  subtractsaleStartText
);

export const priceRange = createSelector(projectSelector, (project) => {
  let range: NumberRange;

  switch (true) {
    case project.forSale:
      if (!!project.saleOffer.salePrice || project.saleOffer.salePriceMax) {
        range = {
          min: project.saleOffer.salePrice,
          max: project.saleOffer.salePriceMax,
        };
      }
      break;
    case project.forRent:
      if (!!project.rentOffer.rentPrice || project.rentOffer.rentPriceMax) {
        range = {
          min: project.rentOffer.rentPrice,
          max: project.rentOffer.rentPriceMax,
        };
      }
      break;
    default:
      break;
  }
  return range;
});

export const parcelRange = createSelector(projectSelector, (project) => {
  let range: NumberRange;
  const parcelSurface = get(project, "parcelSurface");
  if (
    !!parcelSurface &&
    (has(parcelSurface, "minArea") || has(parcelSurface, "maxArea"))
  ) {
    range = {
      min: parcelSurface.minArea,
      max: parcelSurface.maxArea,
    };
  }
  return range;
});

export const livingRoomRange = createSelector(projectSelector, (project) => {
  let range: NumberRange;
  const livingRoomSurface = get(project, "usageArea");
  if (
    !!livingRoomSurface &&
    (has(livingRoomSurface, "minArea") || has(livingRoomSurface, "maxArea"))
  ) {
    range = {
      min: livingRoomSurface.minArea,
      max: livingRoomSurface.maxArea,
    };
  }
  return range;
});

export const contentsRange = createSelector(projectSelector, (project) => {
  let range: NumberRange;
  const contents = get(project, "content");
  if (!!contents && (has(contents, "minArea") || has(contents, "maxArea"))) {
    range = {
      min: contents.minVolume,
      max: contents.maxVolume,
    };
  }
  return range;
});

export const projectPrice = createSelector(projectSelector, (project) => {
  const { forSale, saleOffer, rentOffer } = project;
  let price = 0;

  if (!!forSale) {
    price = get(saleOffer, "salePrice", 0);
  } else {
    price = get(rentOffer, "rentPrice", 0);
  }

  return price;
});

export const buildnumbersLoading = createSelector(
  loadingBuildnumbersSelector,
  (loadingStatus) => loadingStatus === REQUEST.PENDING
);
export const objectTypesLoading = createSelector(
  loadingObjectTypesSelector,
  (loadingStatus) => loadingStatus === REQUEST.PENDING
);
export const objectTypeOptions = createSelector(
  objectTypeSelector,
  (objectTypes) => {
    return [
      {
        value: "",
        text: intlContext.formatMessage({
          id: "everyType",
          defaultMessage: "All types",
        }),
      },
      ...objectTypes.map((objectType) => ({
        value: objectType.id,
        text: objectType.displayName,
      })),
    ];
  }
);

export const objectTypePrice = createSelector(
  projectSelector,
  (currentType) => {
    const { forSale, saleOffer, rentOffer } = currentType;
    let price = 0;

    if (!!forSale) {
      price = get(saleOffer, "salePrice", 0);
    } else {
      price = get(rentOffer, "rentPrice", 0);
    }

    return price;
  }
);

export const publications = createSelector(
  editableProjectSelector,
  publicationsSelector,
  realEstateAgencySelector,
  (
    project: ProjectAssignment,
    publications: PublicationSnapShot[],
    realEstateAgency
  ) => {
    if (
      !project.mandateDateTime ||
      /nvm/gi.test(
        get(realEstateAgency, "associationInfo.realEstateAssociation", "")
      )
    )
      return publications;
    const targetDate = moment(new Date(2019, 0, 31));
    const mandateTime = moment(project.mandateDateTime);

    if (mandateTime.isAfter(targetDate)) return publications;

    return publications.filter((publication) => {
      return (
        !/tiara/gi.test(publication.mediaPartnerName || "") ||
        /nvm/gi.test(publication.mediaPartnerName || "")
      );
    });
  }
);

export const canCreateObjectTypeAssignment = createSelector(
  enabledAssignmentTypesSelector,
  (enabledAssignmentTypes) =>
    enabledAssignmentTypes.includes(AssignmentType.ObjectType)
);

export const canCreateObjectAssignment = createSelector(
  enabledAssignmentTypesSelector,
  (enabledAssignmentTypes) =>
    enabledAssignmentTypes.includes(AssignmentType.Object)
);

export const hasChanges = createSelector(editableStatesSelector, (states) => {
  let hasChanges = false;

  states.map((state) => {
    if (state.active) {
      hasChanges = state.hasChanges;
    }
  });

  return hasChanges;
});
