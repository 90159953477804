import { MapDispatchToProps } from "@haywork/middleware";
import { AppState, ErrorActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  UnauthorizedModalComponent,
  UnauthorizedModalComponentProps
} from "../components";

interface StateProps {
  visible: boolean;
}
interface DispatchProps {
  hideModal: () => void;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  UnauthorizedModalComponentProps,
  AppState
> = (state) => {
  const { unauthorized } = state.error;

  return {
    visible: unauthorized
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  UnauthorizedModalComponentProps
> = (dispatch) => ({
  hideModal: () => dispatch(ErrorActions.setUnauthorized(false))
});

export type UnauthorizedModalContainerProps = StateProps & DispatchProps;
export const UnauthorizedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedModalComponent);
