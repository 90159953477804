import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Input } from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import { AddFolderContainerProps } from "./add-folder.container";

export type AddFolderComponentProps = {
  visible: boolean;
  accountId: string;
  folderId: string | null;
  onClose: () => void;
};
type Props = AddFolderComponentProps & AddFolderContainerProps;

export const AddFolderComponent: FC<Props> = memo(
  ({ visible, onClose, account, folders, saveFolder, folderId }) => {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    const onCloseCallback = useCallback(() => {
      if (loading) return;
      setName("");
      onClose();
    }, [loading, onClose, setName]);

    const hasMinimalData = useMemo(() => !!account && !!name, [account, name]);

    const folder = useMemo(() => {
      return folders.find((folder) => folder.id === folderId);
    }, [folderId, folders]);

    const label = useMemo(() => {
      switch (true) {
        case !!account && !!folder && !!folder.displayName: {
          const { accountName } = account;
          const { displayName: folderName } = folder;
          return (
            <I18n
              value="email.addFolderModal.nameLabel.accountAndFolder"
              values={{ accountName, folderName }}
            />
          );
        }
        case !!account: {
          const { accountName } = account;
          return (
            <I18n
              value="email.addFolderModal.nameLabel.account"
              values={{ accountName }}
            />
          );
        }
        default: {
          return <I18n value="email.addFolderModal.nameLabel" />;
        }
      }
    }, [account, folder]);

    const onSaveCallback = useCallback(async () => {
      try {
        setLoading(true);
        await saveFolder(account.id, !folder ? null : folder.id || null, name);
        setName("");
        onClose();
      } finally {
        setLoading(false);
      }
    }, [saveFolder, setLoading, account, folder, name, setName, onClose]);

    return (
      <Modal visible={visible} onClose={onCloseCallback}>
        <ModalHeader title="email.addFolderModal.title" close />
        <ModalBody>
          <div className="form__row">
            <label htmlFor="name">{label}</label>
            <Input.Text
              name="name"
              value={name}
              asSingleInput
              onChange={setName}
              placeholder="email.addFolderModal.namePlaceholder"
              disabled={loading}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            label="email.addFolderModal.action"
            disabled={!hasMinimalData || loading}
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
            onClick={onSaveCallback}
          />
        </ModalFooter>
      </Modal>
    );
  }
);
