import { AcquisitionAssignment } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState, EditableItem } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { AcquisitionEditScreen, AcquisitionEditScreenProps } from "./edit";
import { hasChanges } from "./selectors";

type StateProps = {
  acquisition: AcquisitionAssignment;
  hasChanges: boolean;
  loading: boolean;
};
type DispatchProps = {
  saveAcquisition: (
    acquisition: AcquisitionAssignment,
    close: boolean
  ) => Promise<void>;
  backToDashboard: () => Promise<void>;
  reloadAcquisitionAssignment: (editable: EditableItem) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionEditScreenProps,
  AppState
> = (state) => {
  const { currentComponentState: acquisition } = state.editable;
  const { acquisitionStatus } = state.acquisition.single;

  return {
    acquisition,
    hasChanges: hasChanges(state),
    loading: acquisitionStatus === REQUEST.PENDING
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionEditScreenProps
> = (dispatch) => ({
  saveAcquisition: (acquisition: AcquisitionAssignment, close: boolean) =>
    dispatch(AcquisitionThunks.Acquisition.saveAcquisition(acquisition, close)),
  backToDashboard: () =>
    dispatch(AcquisitionThunks.Acquisition.backToDashboard()),
  reloadAcquisitionAssignment: (editable: EditableItem) =>
    dispatch(
      AcquisitionThunks.Acquisition.reloadAcquisitionAssignment(editable)
    )
});

export type AcquisitionEditScreenContainerProps = StateProps & DispatchProps;
export const AcquisitionEditScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionEditScreen);
