import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { FormattedNumber } from "react-intl";

import {
  AssignmentSnapShot,
  AssignmentType,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import { ResourceText, FontStyle } from "@haywork/modules/shared";
import { StringUtil } from "@haywork/util";

const styles = require("./list-item.component.scss");
const enumToString = StringUtil.enumToString;

interface AssignmentListItemComponentProps {
  assignment: AssignmentSnapShot;
  zebra: boolean;
  onClick: (
    id: string,
    type: RealEstateGroup,
    snapshot: AssignmentSnapShot
  ) => void;
}
interface AssignmentListItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentListItemComponent extends React.PureComponent<
  AssignmentListItemComponentProps,
  AssignmentListItemComponentState
> {
  constructor(props) {
    super(props);

    this.itemOnClickHandler = this.itemOnClickHandler.bind(this);
  }

  public render() {
    const assignment = this.props.assignment;
    const listItemStyle = classNames("list-item", {
      zebra: this.props.zebra,
      archived: !assignment.isActive,
    });
    const hasPreview = !!assignment.photo1Url && assignment.photo1Url !== "";
    const previewStyle = hasPreview
      ? { backgroundImage: `url(${JSON.stringify(assignment.photo1Url)})` }
      : null;
    const realEstateLabel =
      assignment.realEstateGroup === RealEstateGroup.Agricultural
        ? "leasePrice"
        : "rentalPrice";
    const rentResourceKey =
      assignment.realEstateGroup === RealEstateGroup.Agricultural
        ? "forLease"
        : "forRent";

    return (
      <div styleName={listItemStyle} onClick={this.itemOnClickHandler}>
        <div styleName="list-item__preview">
          <div styleName="preview" style={previewStyle}>
            {!hasPreview && <i className="fal fa-fw fa-camera-retro fa-2x" />}
          </div>
        </div>
        <div styleName="list-item__info">
          <div styleName="extras">
            {!!assignment.publicReference && (
              <div styleName="pubref">{assignment.publicReference}</div>
            )}
            {!!assignment.keyNr && (
              <div styleName="keynumber">
                <i className="fa fa-key" />
                {assignment.keyNr}
              </div>
            )}
          </div>
          {assignment.typeOfAssignment === AssignmentType.Project && (
            <div styleName="assignment-type">
              <ResourceText resourceKey="assignmentTypes.Project" />
            </div>
          )}
          <div styleName="meta">
            <ResourceText
              masterKey="realEstateGroups"
              resourceKey={enumToString(assignment.realEstateGroup)}
            />
            ,{" "}
            {assignment.forSale ? (
              <ResourceText resourceKey="forSale" />
            ) : (
              <ResourceText resourceKey={rentResourceKey} />
            )}
          </div>
          <div styleName="title">{assignment.displayName}</div>
          <div styleName="type">
            <ResourceText
              masterKey="listingTypes"
              resourceKey={enumToString(
                assignment.typeOfAssignment === AssignmentType.ObjectType
                  ? assignment.typeOfAssignment
                  : assignment.listingType
              )}
            />
          </div>
        </div>
        <div styleName="list-item__meta">
          {assignment.forSale && (
            <div styleName="meta">
              <span styleName="label">
                <ResourceText resourceKey="askingPrice" />
              </span>
              {this.renderPrice(assignment.salePrice, assignment.salePriceMax)}
            </div>
          )}
          {assignment.forRent && (
            <div styleName="meta">
              <span styleName="label">
                <ResourceText resourceKey={realEstateLabel} />
              </span>
              {this.renderPrice(assignment.rentPrice, assignment.rentPriceMax)}
            </div>
          )}
          {assignment.typeOfAssignment === AssignmentType.Object && (
            <div styleName="meta">
              <span styleName="label">
                <ResourceText resourceKey="courtage" />
              </span>
              {assignment.forSale &&
                this.renderCommision(assignment.saleCommissionTotal)}
              {assignment.forRent &&
                this.renderCommision(assignment.rentCommissionTotal)}
            </div>
          )}
        </div>
        {!!assignment.isActive && (
          <div styleName="list-item__status">
            <div styleName="type">
              <ResourceText
                masterKey="assignmentPhases"
                resourceKey={enumToString(assignment.assignmentPhase)}
              />
            </div>
            <div styleName="status">
              <ResourceText
                masterKey="availabilityStatuses"
                resourceKey={enumToString(assignment.availabilityStatus)}
              />
            </div>
          </div>
        )}
        {!assignment.isActive && (
          <div styleName="list-item__status">
            <div styleName="status">
              <ResourceText resourceKey="archived" />
            </div>
          </div>
        )}
      </div>
    );
  }

  private itemOnClickHandler() {
    const { id, realEstateGroup } = this.props.assignment;
    this.props.onClick(id, realEstateGroup, this.props.assignment);
  }

  private renderPrice(price: number, priceMax: number) {
    if (!!price && price > 0) {
      return (
        <div styleName="value">
          <FormattedNumber
            value={price}
            style="currency"
            currency="EUR"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {!!priceMax && (
            <span>
              &nbsp;-&nbsp;
              <FormattedNumber
                value={priceMax}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </span>
          )}
          {this.props.assignment.forSale && (
            <span>
              &nbsp;
              <ResourceText
                masterKey="saleConditions"
                resourceKey={enumToString(this.props.assignment.saleCondition)}
              />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <ResourceText resourceKey="none" fontStyle={FontStyle.LowerCase} />
        </div>
      );
    }
  }

  private renderCommision(total: number) {
    if (!!total && total > 0) {
      return (
        <div styleName="value positive">
          <FormattedNumber value={total} style="currency" currency="EUR" />
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <ResourceText resourceKey="none" fontStyle={FontStyle.LowerCase} />
        </div>
      );
    }
  }
}
