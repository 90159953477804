import { Action } from "redux";

import { ActiveFilter, AssignmentSnapShot, Employee, RelationSnapShot } from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { EmployeeFilter } from "@haywork/middleware/thunk/employee.thunk";
import * as ActionType from "./employee.types";

export interface EmployeeFilterState {
  selectedEmployeeFilters: EmployeeFilter;
  employeeOverviewState: string;
  employees: RelationSnapShot[];
  totalEmployeeCount: number;
  employeeOverviewCount: number;
  employeeOverviewPage: number;
  employeeInitialOverviewState: string;
  employeeUpdateState: string;
  showEmployeeModal: boolean;
  selectedEmployee: Employee;
  selectedEmployeeId: string;
  employeeState: string;
  employeeAssignmentState: string;
  employeeAssignments: AssignmentSnapShot[];
  employeeAssignmentPage: number;
  employeeAssignmentPageCount: number;
  employeeAddressSearchState: string;
  employeeSaveState: string;
}

const INITIAL_STATE: EmployeeFilterState = {
  selectedEmployeeFilters: {
    activeOrInactiveFilter: ActiveFilter.ActiveOnly,
    officeFilter: null
  },
  employeeOverviewState: REQUEST.IDLE,
  employees: [],
  totalEmployeeCount: 0,
  employeeOverviewCount: 0,
  employeeOverviewPage: 0,
  employeeInitialOverviewState: REQUEST.IDLE,
  employeeUpdateState: REQUEST.IDLE,
  showEmployeeModal: false,
  selectedEmployee: null,
  selectedEmployeeId: "",
  employeeState: REQUEST.IDLE,
  employeeAssignmentState: REQUEST.IDLE,
  employeeAssignmentPage: 0,
  employeeAssignmentPageCount: 0,
  employeeAssignments: null,
  employeeAddressSearchState: REQUEST.IDLE,
  employeeSaveState: REQUEST.IDLE
};

export const employeeReducer = (state: EmployeeFilterState = INITIAL_STATE, action: Action): EmployeeFilterState => {
  switch (action.type) {
    case REDUX.EMPLOYEE.UPDATE_EMPLOYEE_FILTER: {
      const { employeeFilter } = <ActionType.EmployeeFilterUpdate> action;
      return {
        ...state,
        selectedEmployeeFilters: employeeFilter
      };
    }
    case REDUX.EMPLOYEE.SET_FETCH_EMPLOYEE_OVERVIEW_STATE: {
      const { employeeOverviewState } = <ActionType.EmployeeOverviewState> action;
      return { ...state, employeeOverviewState };
    }
    case REDUX.EMPLOYEE.SET_INITIAL_FETCH_EMPLOYEE_OVERVIEW_STATE: {
      const { employeeOverviewState } = <ActionType.EmployeeOverviewState> action;
      return { ...state, employeeInitialOverviewState: employeeOverviewState };
    }
    case REDUX.EMPLOYEE.APPEND_EMPLOYEES: {
      const { resultCount, results, statistics, totalResults } = <ActionType.EmployeeResponse> action;
      let employeeOverviewCount = Math.ceil(totalResults / resultCount);
      if (employeeOverviewCount === Infinity) {
        employeeOverviewCount = 0;
      }
      const employeeOverviewPage = state.employeeOverviewPage + 1;
      return {
        ...state,
        employees: [...state.employees, ...results],
        totalEmployeeCount: totalResults,
        employeeOverviewCount,
        employeeOverviewPage
      };
    }
    case REDUX.EMPLOYEE.SEARCH_EMPLOYEES: {
      const { resultCount, results, statistics, totalResults } = <ActionType.EmployeeResponse> action;
      const employeeOverviewCount = Math.ceil(totalResults / resultCount);
      return {
        ...state,
        employees: results,
        totalEmployeeCount: totalResults,
        employeeOverviewCount,
        employeeOverviewPage: 1,
        employeeInitialOverviewState: REQUEST.SUCCESS
      };
    }
    case REDUX.EMPLOYEE.ARCHIVE_EMPLOYEE: {
      const { employee } = <ActionType.ArchiveEmployee> action;
      const filteredEmployees = state.employees.filter(({ id }) => id !== employee.id);

      return {
        ...state,
        employees: filteredEmployees,
        totalEmployeeCount: state.totalEmployeeCount - 1
      };
    }
    case REDUX.EMPLOYEE.TOGGLE_EMPLOYEE_MODAL: {
      const { showEmployeeModal } = <ActionType.EmployeeModal> action;
      return { ...state, showEmployeeModal };
    }
    case REDUX.EMPLOYEE.GET_EMPLOYEE: {
      const { employee } = <ActionType.SingleEmployeeResponse> action;
      return {
        ...state,
        selectedEmployee: employee,
        selectedEmployeeId: employee.id
      };
    }
    case REDUX.EMPLOYEE.SET_EMPLOYEE: {
      const { employee } = <ActionType.SingleEmployeeResponse> action;
      return {
        ...state,
        selectedEmployee: employee,
        selectedEmployeeId: employee.id,
        employeeState: REQUEST.SUCCESS
      };
    }
    case REDUX.EMPLOYEE.SET_EMPLOYEE_STATE: {
      const { employeeState } = <ActionType.EmployeeState> action;
      return {
        ...state,
        employeeState
      };
    }
    case REDUX.EMPLOYEE.SET_EMPLOYEE_ASSIGNMENT_STATE: {
      const { employeeAssignmentState } = <ActionType.EmployeeAssignmentState> action;
      return { ...state, employeeAssignmentState };
    }
    case REDUX.EMPLOYEE.SEARCH_EMPLOYEE_ASSIGNMENTS: {
      const { totalResults, results, resultCount } = <ActionType.AssignmentsResponse> action;
      const employeeAssignmentPageCount = Math.round(totalResults / resultCount);

      return {
        ...state,
        employeeAssignments: results,
        employeeAssignmentPage: 1,
        employeeAssignmentPageCount
      };
    }
    case REDUX.EMPLOYEE.APPEND_EMPLOYEE_ASSIGNMENTS: {
      const { resultCount, results, totalResults } = <ActionType.AssignmentsResponse> action;
      const employeeAssignmentPageCount = Math.round(totalResults / resultCount);
      const employeeAssignmentPage = state.employeeAssignmentPage + 1;

      return {
        ...state,
        employeeAssignments: [...state.employeeAssignments, ...results],
        employeeAssignmentPage,
        employeeAssignmentPageCount
      };
    }
    case REDUX.EMPLOYEE.SET_EMPLOYEE_ADDRESS_SEARCH_STATE: {
      const { employeeAddressSearchState } = <ActionType.EmployeeAddressSearchState> action;
      return { ...state, employeeAddressSearchState };
    }
    case REDUX.EMPLOYEE.SET_SAVE_EMPLOYEE_STATE: {
      const { employeeSaveState } = <ActionType.EmployeeSaveState> action;
      return { ...state, employeeSaveState };
    }
    case REDUX.EMPLOYEE.CLEAR_EMPLOYEES: {
      return {
        ...state,
        employees: [],
        totalEmployeeCount: 0,
        employeeOverviewCount: 0,
        employeeOverviewPage: 0
      };
    }
    case REDUX.EMPLOYEE.SET_TOTAL_AMOUNT_OF_EMPLOYEES: {
      const {amountOfEmployees} = <ActionType.TotalAmountOfEmployees> action;
      return {
        ...state,
        totalEmployeeCount: amountOfEmployees
      };
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
