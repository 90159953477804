import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { TimelineEvent } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";
import { INVOICEROUTES } from "@haywork/constants";
import { RouteUtil } from "@haywork/util";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  event: TimelineEvent;
  parentId: string;
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const InvoiceItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ event, parentId, onNavigate, onShowAllLinkedEntities }) => {
      const {
        id,
        subject: invoiceSubject,
        linkedAssignments,
        linkedRelations,
        invoiceNumber,
        dateTimeCreated
      } = event;
      const dateField = useMemo(
        () =>
          !!dateTimeCreated ? (
            <div styleName="date">
              {moment(dateTimeCreated).format("DD MMMM YYYY HH:mm")}
            </div>
          ) : null,
        []
      );
      const path = useMemo(() => {
        return route(INVOICEROUTES.DETAIL.URI, { id });
      }, [id]);
      const openInvoice = () => {
        onNavigate(path);
      };

      return (
        <div styleName="event__item" onClick={openInvoice}>
          <div styleName="event__icon">
            <Icon name="euro-sign" light size={24} color={Colors.Gray} />
          </div>
          <div styleName="event__divider"></div>
          <div styleName="event__data">
            <div styleName="subject">
              {!invoiceNumber ? (
                <I18n value="concept" />
              ) : (
                <I18n value="invoiceNumber" values={{ invoiceNumber }} />
              )}
              {!!invoiceSubject && (
                <I18n value="dashInvoiceSubject" values={{ invoiceSubject }} />
              )}
            </div>
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          <div styleName="event__date">{dateField}</div>
          <div styleName="event__actions"></div>
        </div>
      );
    }
  )
);

export default InvoiceItemComponent;
