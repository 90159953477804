import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Hint } from "@haywork/modules/shared";

const styles = require("./required-for-publish.component.scss");

interface UiRequiredForPublishProps {
  message?: string;
  hideHint?: boolean;
}

export const UiRequiredForPublish = CSSModules(styles, { allowMultiple: true })(
  ({ message, hideHint }: UiRequiredForPublishProps) => {
    return (
      <div styleName="required">
        <Hint
          message={message || "requiredForPublish"}
          shouldRender={!hideHint}
        >
          <i className="fa fa-asterisk" />
        </Hint>
      </div>
    );
  }
);
