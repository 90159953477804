import { LinkedAssignment, LinkedRelation } from "@haywork/api/kolibri";
import { File } from "@haywork/api/mail";
import I18n from "@haywork/components/i18n";
import { SaveFilesToDossier } from "@haywork/modules/shared/components/dossier-v2";
import { EmailMessage } from "@haywork/stores/email-v2";
import { saveAs } from "file-saver";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import Attachment, { EmailAttachmentAction } from "./attachment";

const styles = require("./style.scss");

type Props = {
  attachments: File[];
  linkedRelations?: LinkedRelation[];
  linkedAssignments?: LinkedAssignment[];
  reloadMessage: () => Promise<EmailMessage>;
};

export const AttachmentsComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ attachments, linkedRelations, linkedAssignments, reloadMessage }) => {
      const [saveModalVisible, setSaveModalVisible] = useState(false);
      const [filesToSave, setFilesToSave] = useState<File[]>([]);

      const saveAllAttachments = useCallback(() => {
        setFilesToSave(attachments);
        setSaveModalVisible(true);
      }, [attachments, setSaveModalVisible, setFilesToSave]);

      const getParameterByName = useCallback((name, url) => {
        if (!url) return null;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
        if (!results || !results[2]) return null;
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }, []);

      const downloadAttachmentCallback = useCallback(
        async (file: File) => {
          if (!file) return;
          let validUntill: string;
          const { id, uri } = file;

          if ("URLSearchParams" in window) {
            const params = new URLSearchParams(uri);
            validUntill = params.get("_v");
          } else {
            validUntill = getParameterByName("_v", uri);
          }

          if (!!validUntill) {
            const date = moment.unix(parseFloat(validUntill));
            if (!date.isValid() || date.isBefore(moment())) {
              const message = await reloadMessage();
              const { files } = message;
              file = (files || []).find((file) => file.id === id) || file;
            }
          }

          const { fileName, uri: downloadUri } = file;
          saveAs(downloadUri, fileName);
        },
        [getParameterByName, reloadMessage]
      );

      const onActionCallback = useCallback(
        (action: EmailAttachmentAction, file: File) => {
          switch (action) {
            case EmailAttachmentAction.Download: {
              downloadAttachmentCallback(file);
              return;
            }
            case EmailAttachmentAction.Save: {
              setFilesToSave([file]);
              setSaveModalVisible(true);
              return;
            }
            default: {
              return;
            }
          }
        },
        [downloadAttachmentCallback]
      );

      const saveModalCloseHandler = useCallback(() => {
        setSaveModalVisible(false);
        setFilesToSave([]);
      }, [setSaveModalVisible, setFilesToSave]);

      if (!attachments || !attachments.length) return null;

      return (
        <div styleName="attachments">
          {attachments.map((attachment) => (
            <Attachment
              file={attachment}
              key={attachment.id}
              onAction={onActionCallback}
            />
          ))}

          {attachments.length > 1 && (
            <div styleName="attachments__saveall">
              <span className="as-link" onClick={saveAllAttachments}>
                <I18n value="email.attachment.saveAll" />
              </span>
            </div>
          )}

          <SaveFilesToDossier
            visible={saveModalVisible}
            files={filesToSave}
            linkedRelations={linkedRelations}
            linkedAssignments={linkedAssignments}
            onClose={saveModalCloseHandler}
          />
        </div>
      );
    }
  )
);
