import * as React from "react";
import classNames from "classnames";

import { InputComponentProps } from "../input.component";

interface MultiItem {
  value: any;
  displayName: string;
  selected: boolean;
  disabled: boolean;
}
interface MultiInputComponentProps {
  values: any[];
  asList?: boolean;
  disabledValues?: any;
}
interface MultiInputComponentState {
  items: MultiItem[];
}

export class MultiComponent extends React.Component<
  MultiInputComponentProps & InputComponentProps,
  MultiInputComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      items: this.mapValues(),
    };

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const inputStyle = classNames(
      this.props.asList ? "input__list" : "input__multi",
      { disabled: this.props.disabled }
    );

    return (
      <div className={inputStyle}>
        {this.state.items.map((item, idx) => {
          let style;
          style = this.props.asList
            ? classNames(
                "list-item",
                { active: item.selected },
                { disabled: item.disabled }
              )
            : classNames("btn", item.selected ? "btn-primary" : "btn-blank");
          return (
            <div
              className={style}
              key={idx}
              onClick={() => this.onClickHandler(item)}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.ListItem`
              }
            >
              {item.displayName}
            </div>
          );
        })}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.setSelectedValues(nextProps.value, nextProps.disabledValues);
  }

  private onClickHandler(item: MultiItem) {
    if (this.props.disabled || item.disabled) return;

    const items = this.state.items.map((selectedItem) => {
      if (selectedItem.value === item.value) {
        return { ...selectedItem, selected: !selectedItem.selected };
      }
      return selectedItem;
    });
    const values = items
      .filter((selectedItem) => selectedItem.selected)
      .map((item) => item.value);

    this.props.onChange(values);
  }

  private mapValues(): MultiItem[] {
    const values = this.props.value || [];
    const disabledValues = this.props.disabledValues || [];
    return this.props.values.map((item) => {
      const value = item.value;
      const displayName = item.displayName;
      const selected = values.indexOf(value) !== -1;
      const disabled = disabledValues && disabledValues.indexOf(value) !== -1;

      return { value, displayName, selected, disabled };
    });
  }

  private setSelectedValues(values: any[], disabledValues: any[]) {
    if (!values) return;
    const items = this.props.values.map((item) => {
      const value = item.value;
      const displayName = item.displayName;
      const selected = values.indexOf(value) !== -1;
      const disabled = disabledValues && disabledValues.indexOf(value) !== -1;

      return { value, displayName, selected, disabled };
    });

    this.setState({ items });
  }
}
