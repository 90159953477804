import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { Attachment, AttachmentType } from "@haywork/api/mls";

const attachmentsSelector = (state: AppState) =>
  state.mls.single.realEstateResponse?.realEstateProperty?.attachments || [];

export type GroupedAttachments = {
  type: AttachmentType;
  attachments: Attachment[];
};
export const attachments = createSelector(
  attachmentsSelector,
  (attachments) => {
    return attachments.reduce((state, attachment) => {
      const ref = state.find((s) => s.type === attachment.type);

      if (!ref && !!attachment.type) {
        state.push({ type: attachment.type, attachments: [attachment] });
      }

      if (!!ref) {
        ref.attachments.push(attachment);
      }

      return state;
    }, [] as GroupedAttachments[]);
  }
);
