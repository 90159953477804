import { RootEntityType } from "@haywork/api/event-center";
import {
  BlobsClient,
  EmployeeRole,
  ListingType,
  ObjectAssignment,
  ObjectAssignmentsClient,
  PhotoBlob,
  RealEstateGroup,
  UploadResponse,
} from "@haywork/api/kolibri";
import { RealEstateProperty, RealEstatePropertyClient } from "@haywork/api/mls";
import {
  ASSIGNMENTROUTES,
  MAINROUTES,
  MLSROUTES,
  REQUEST,
} from "@haywork/constants";
import { ParseRequest } from "@haywork/services";
import {
  AppState,
  AssignmentSingleActions,
  EditableActions,
  ErrorActions,
  LayoutActions,
} from "@haywork/stores";
import { MLSActions } from "@haywork/stores/mls";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { BackOfficeUtil, MlsUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import * as PrintJS from "print-js";
import { IntlShape } from "react-intl";
import { Dispatch } from "react-redux";
import { realEstateProperty as demoRealEstateProperty } from "@haywork/modules/mls/mls-data";
import head from "lodash-es/head";

const route = RouteUtil.mapStaticRouteValues;
const parseRequest = new ParseRequest();

const getRealEstateResponse = (id: string, source: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(MLSActions.Single.setSingleStatus(REQUEST.PENDING));

      if (id === "demo") {
        const response = {
          bundleId: id,
          appClientKey: source,
          realEstateProperty: demoRealEstateProperty,
          differentRegistrations: [],
          mutations: [],
          following: true,
        };
        const path = route(MLSROUTES.DETAIL.URI, { id, source });

        dispatch(
          EditableActions.addState({
            icon: MAINROUTES.MLS.ICON,
            componentState: response,
            path,
            title: "...",
            entityType: RootEntityType.Unknown,
            entityId: id,
          })
        );
        dispatch(MLSActions.Single.setSingle(response));
        return;
      }

      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost);
      const { culture } = state.main;

      const response = await parseRequest.response(
        client.read(id, source, realEstateAgencyId)
      );

      if (!response) throw new Error("Realestate property could not be found");

      const path = route(MLSROUTES.DETAIL.URI, { id, source });

      const { realEstateProperty } = response;
      const streetnameArray =
        realEstateProperty?.location?.address?.streetname || [];
      const cityNameArray =
        realEstateProperty?.location?.address?.cityName || [];

      const houseNumber = realEstateProperty?.location?.address?.houseNumber;
      const houseNumberPostfix =
        realEstateProperty?.location?.address?.houseNumberPostfix;
      const postalCode = realEstateProperty?.location?.address?.postalCode;
      const streetname = streetnameArray.find(
        (item) => item.language === culture
      )?.value;
      const cityName = cityNameArray.find((item) => item.language === culture)
        ?.value;

      const adressLine1 = [streetname, houseNumber, houseNumberPostfix]
        .filter((d) => ![null, undefined].includes(d))
        .join(" ");
      const adressLine2 = [postalCode, cityName].filter((d) => !!d).join(" ");

      const title =
        [adressLine1, adressLine2].filter((d) => !!d).join(", ") || "...";

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.MLS.ICON,
          componentState: response,
          path,
          title,
          entityType: RootEntityType.Unknown,
          entityId: id,
        })
      );

      dispatch(MLSActions.Single.setSingle(response));
    } catch (error) {
      dispatch(MLSActions.Single.setSingleStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getRealEstateProperty = (id: string, source: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(MLSActions.Single.setSingleStatus(REQUEST.PENDING));

      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost);

      const response = await parseRequest.response(
        client.read(id, source, realEstateAgencyId)
      );

      if (!response) throw new Error("Realestate property could not be found");

      const { realEstateProperty } = response;

      return realEstateProperty;
    } catch (error) {
      throw error;
    }
  };
};

const toggleFollow = ({ bundleId, appClientKey, following }) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const single = state.mls.single.realEstateResponse;
      const client = new RealEstatePropertyClient(mlsHost);

      await parseRequest.response(
        client.toggleFolow(
          { bundleId, appClientKey, isFollowing: !following },
          realEstateAgencyId
        )
      );

      const updatedSingle = { ...single, following: !following };

      dispatch(MLSActions.Single.setSingle(updatedSingle));

      dispatch(
        SnackbarActions.addToast({
          value: `mls.singleToast.following.${!following}`,
          icon: !following ? "eye" : "eye-slash",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const copyToAssignment = (mappedOjectAssignment: ObjectAssignment) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
    );

    const state = getState();
    const {
      id: realEstateAgencyId,
      role,
    } = state.account.currentRealestateAgency;
    const { offices, employee } = state.account;
    const { enabledRealEstateGroups } = state.company.settings;

    let linkedOffice;
    if (!!employee.linkedOffices && role === EmployeeRole.Standard) {
      linkedOffice = employee.linkedOffices.find(
        (office) => office.isMainOffice
      );
      if (!linkedOffice) {
        linkedOffice = head(employee.linkedOffices);
      }
    } else {
      linkedOffice = head(offices);
    }

    const { host } = state.appSettings;

    const ObjectAssignments = new ObjectAssignmentsClient(host);
    const blobsClient = new BlobsClient(host);

    // TODO CHECK ALLOWED??
    const typesInfo = [
      {
        realEstateGroup: RealEstateGroup.Residential,
        listingType: ListingType.House,
      },
      {
        realEstateGroup: RealEstateGroup.Commercial,
        listingType: ListingType.Other,
      },
      {
        realEstateGroup: RealEstateGroup.Agricultural,
        listingType: ListingType.ArableCompany,
      },
    ].filter((type) =>
      (enabledRealEstateGroups || []).includes(type.realEstateGroup)
    );

    const typeInfo =
      {
        realEstateGroup: mappedOjectAssignment.realEstateGroup,
        listingType: mappedOjectAssignment.listingType,
      } || head(typesInfo);

    const employeeId = BackOfficeUtil.getEmployeeId(state.account);
    if (!employeeId) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({
          createLoaderVisible: false,
        })
      );
      return;
    }

    ObjectAssignments.defineNew(
      {
        employeeId,
        forRent: mappedOjectAssignment.forRent || false,
        forSale: mappedOjectAssignment.forSale || false,
        officeId: linkedOffice.id,
        ...typeInfo,
      },
      realEstateAgencyId
    )
      .then(async (response) => {
        let { objectAssignment } = response;

        if (mappedOjectAssignment?.photos?.length > 0) {
          try {
            const promises: Array<Promise<UploadResponse>> = [];
            for (let i = 0; i < 1; i++) {
              const file = mappedOjectAssignment?.photos[i];
              const fileName = file.fileName
                ? file.fileName.replace(/\.[0-9a-z]+$/i, "")
                : file.md5Hash;
              const fileExtension = (file.fileExtension || "").toLowerCase();
              promises.push(
                parseRequest.response(
                  blobsClient.uploadFromUri(
                    {
                      uri: file.urlOriginal,
                      isPrivate: false,
                      fileName,
                      fileExtension,
                    },
                    realEstateAgencyId
                  )
                )
              );
            }

            const photoBlobs = await Promise.all(promises);

            const photos: PhotoBlob[] = photoBlobs.map((photo) => ({
              ...photo,
              height: photo.height || 0,
              width: photo.height || 0,
              thumbUrl: photo.thumbUrl,
            }));

            mappedOjectAssignment = {
              ...mappedOjectAssignment,
              ...{ photos },
            };
          } catch (error) {
            throw error;
          }
        }

        // Set default value
        objectAssignment = {
          ...mappedOjectAssignment,
          ...objectAssignment,
        };

        const route = RouteUtil.mapStaticRouteValues(
          ASSIGNMENTROUTES.DETAIL.URI,
          {
            id: objectAssignment.id,
          }
        );
        const redirect = RouteUtil.mapStaticRouteValues(
          ASSIGNMENTROUTES.EDIT_CLIENT.URI,
          { id: objectAssignment.id }
        );
        const componentState = {
          yisualLinks: null,
          cadastres: [],
          companyListings: [],
          publications: [],
          pastEvents: [],
          futureEvents: [],
          objectAssignment,
          searchAssignments: [],
          isInitial: false,
          lastMarketingRoute: null,
          transactionMetaData: null,
          receivedDataFromMLS: true,
        };

        dispatch(AssignmentSingleActions.setAssignment(componentState));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
        dispatch(
          EditableActions.addState({
            hasChanges: true,
            icon: MAINROUTES.ASSIGNMENTS.ICON,
            componentState,
            path: route,
            title: "...",
            confirm: {
              title: { key: "saveAssignmentConfirmTitle" },
              body: { key: "saveAssignmentConfirmBody" },
            },
            entityType: RootEntityType.ObjectAssignment,
            entityId: objectAssignment.id,
          })
        );
        dispatch(push(redirect));
      })
      .catch((error) => {
        dispatch(ErrorActions.setError({ error }));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
      });
  };
};

const printRealEstateProperty = (
  realEstateProperty: RealEstateProperty,
  realtorName: string,
  intl: IntlShape
) => {
  return async () => {
    try {
      const printable = MlsUtil.createPrintTemplate(
        [realEstateProperty],
        realtorName,
        intl
      );

      PrintJS({
        printable,
        type: "raw-html",
      });
    } catch (error) {
      throw error;
    }
  };
};

const getRealEstatePropertySearchResponse = (
  bundleId: string,
  appClient: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      if (!bundleId || !appClient) return null;

      const state = getState();
      const { mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const client = new RealEstatePropertyClient(mlsHost);

      const response = await parseRequest.response(
        client
          .search(
            {
              skip: 0,
              take: 1,
              bundleIds: [bundleId],
              appClients: [appClient],
            },
            realEstateAgencyId
          )
          .then((response) => response.results || [])
      );

      return head(response);
    } catch (error) {
      throw error;
    }
  };
};

export default {
  getRealEstateResponse,
  toggleFollow,
  copyToAssignment,
  printRealEstateProperty,
  getRealEstateProperty,
  getRealEstatePropertySearchResponse,
};
