import { v4 as uuid } from "uuid";
import {
  UploadResponse as ApiUploadResponse,
  TemplateDefintionAttachmentBlob
} from "@haywork/api/kolibri";
import {
  UploadResponse as MailUploadResponse,
  File as EmailFile
} from "@haywork/api/mail";

interface FileUploadResponse {
  id: string;
  name: string;
  size: number;
  uri: string;
  isPrivate: boolean;
  isAttachment: boolean;
}

export interface FileUploadObject {
  file: File | null;
  uploaded: number;
  completed: boolean;
  id: string;
  error?: any;
  isAttachment: boolean;
  response: FileUploadResponse;
}

export class Upload {
  public static createFileUploadObject(
    file: File,
    isAttachment: boolean = false
  ): FileUploadObject {
    return {
      file,
      uploaded: 0,
      completed: false,
      id: uuid(),
      response: null,
      isAttachment
    };
  }

  public static createFileUploadObjectFromAttachmentBlob(
    blob: TemplateDefintionAttachmentBlob
  ): FileUploadObject {
    return {
      file: new File([], blob.fileName),
      uploaded: 100,
      completed: true,
      id: blob.fileDataId,
      isAttachment: true,
      response: {
        id: blob.fileDataId,
        name: blob.fileName,
        size: blob.fileSize,
        uri: blob.signedUrl,
        isPrivate: true,
        isAttachment: true
      }
    };
  }

  public static createFileUploadObjectFromEmailFile(
    file: EmailFile,
    isPrivate: boolean = true
  ): FileUploadObject {
    let createdFile: File;
    try {
      createdFile = new File([], file.fileName);
    } catch {
      createdFile = null;
    }

    return {
      file: createdFile,
      uploaded: 100,
      completed: true,
      id: uuid(),
      isAttachment: file.isAttachment || false,
      response: {
        id: file.id,
        name: file.fileName,
        size: file.size,
        uri: file.uri,
        isPrivate,
        isAttachment: file.isAttachment
      }
    };
  }

  public static mapMailResponseToResponse(
    response: MailUploadResponse,
    isAttachment: boolean = false,
    isPrivate: boolean = true
  ): FileUploadResponse {
    return {
      id: response.fileId,
      name: response.fileName,
      size: response.size,
      uri: response.uri,
      isPrivate,
      isAttachment
    };
  }

  public static mapApiResponseToResponse(
    response: ApiUploadResponse,
    isAttachment: boolean = false
  ): FileUploadResponse {
    return {
      id: response.fileDataId,
      name: response.fileName + response.fileExtension,
      size: response.fileSize,
      uri: response.url,
      isPrivate: response.isPrivate,
      isAttachment
    };
  }

  public static staticResponseToTemplateDefintionAttachmentBlob(
    response: FileUploadResponse
  ): TemplateDefintionAttachmentBlob {
    return {
      fileName: response.name,
      fileSize: response.size,
      signedUrl: response.uri,
      fileDataId: response.id
    };
  }

  public static mapResponseToEmailFile(
    response: FileUploadResponse
  ): EmailFile {
    return {
      isAttachment: response.isAttachment,
      fileName: response.name,
      size: response.size,
      uri: response.uri,
      id: response.id
    };
  }

  public static mapFileUploadObjectToFile(file: FileUploadObject): EmailFile {
    return {
      id: file.response.id,
      fileName: file.response.name,
      size: file.response.size,
      isAttachment: true,
      uri: file.response.uri
    };
  }

  public static mapMailUploadResponseToEmailFile(
    response: MailUploadResponse,
    isAttachment: boolean = false
  ): EmailFile {
    return {
      id: response.fileId,
      fileName: response.fileName,
      size: response.size,
      isAttachment,
      uri: response.uri
    };
  }
}
