import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";

declare let window: ExtendedWindow;
let usersnap: any;

const styles = require("./usersnap.component.scss");

type Props = {
  usersnapApiKey: string;
};

@CSSModules(styles, { allowMultiple: true })
export class UserSnapComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onUsersnapClickHandler = this.onUsersnapClickHandler.bind(this);
  }

  public componentDidMount() {
    if (process.env.NODE_ENV !== "development") {
      window.onUsersnapCXLoad = (api) => {
        api.init({
          button: { isHidden: true },
        });
        usersnap = api;
      };

      const script = document.createElement("script");
      script.async = true;
      script.src = `https://widget.usersnap.com/load/${this.props.usersnapApiKey}?onload=onUsersnapCXLoad`;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }

  public render() {
    if (process.env.NODE_ENV === "development") {
      return null;
    }

    return (
      <div styleName="apps">
        <div styleName="menu__item" onClick={this.onUsersnapClickHandler}>
          <i className="fal fa-fw fa-bullhorn" />
          <div styleName="label">
            <ResourceText resourceKey="usersnap" />
          </div>
        </div>
      </div>
    );
  }

  private onUsersnapClickHandler() {
    usersnap?.open();
  }
}
