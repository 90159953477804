import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Input } from "@haywork/modules/form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState, useEffect } from "react";
import { UpdateFolderContainerProps } from "./update-folder.container";
import { ProviderType } from "@haywork/api/mail";

export type UpdateFolderComponentProps = {
  visible: boolean;
  folderId: string | null;
  onClose: () => void;
};
type Props = UpdateFolderComponentProps & UpdateFolderContainerProps;

export const UpdateFolderComponent: FC<Props> = memo(
  ({ visible, onClose, folders, saveFolder, folderId, accounts }) => {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    const folder = useMemo(() => {
      return folders.find((folder) => folder.id === folderId);
    }, [folderId, folders]);

    const subFolders = useMemo(() => {
      return folders.filter((f) => f.parentId === folder?.id);
    }, [folder, folders]);

    const account = useMemo(() => {
      return accounts.find((account) => account.id === folder?.accountId);
    }, [folder, accounts]);

    const onCloseCallback = useCallback(() => {
      if (loading) return;
      setName("");
      onClose();
    }, [loading, onClose, setName]);

    const label = useMemo(() => {
      switch (true) {
        case !!folder && !!folder.displayName: {
          const { displayName: folderName } = folder;
          return (
            <I18n
              value="email.updateFolderModal.nameLabel.folder"
              values={{ folderName }}
            />
          );
        }
        default: {
          return <I18n value="email.updateFolderModal.nameLabel" />;
        }
      }
    }, [folder]);

    const folderName = useMemo(
      () => (!folder ? "" : folder.displayName || ""),
      [folder]
    );

    const onSaveCallback = useCallback(async () => {
      try {
        setLoading(true);
        const { id, accountId, parentId } = folder;
        await saveFolder(accountId, parentId, name, id);
        setName("");
        onClose();
      } finally {
        setLoading(false);
      }
    }, [saveFolder, setLoading, folder, name, setName, onClose]);

    const setInitialValues = useCallback(() => {
      if (!visible || !folderName) return;
      setName(folderName);
    }, [folderName, visible, setName]);

    useEffect(() => {
      setInitialValues();
    }, [setInitialValues]);

    return (
      <Modal visible={visible} onClose={onCloseCallback}>
        <ModalHeader title="email.updateFolderModal.title" close />
        <ModalBody>
          <div className="form__row">
            <label htmlFor="name">{label}</label>
            <Input.Text
              name="name"
              value={name}
              asSingleInput
              onChange={setName}
              placeholder="email.updateFolderModal.namePlaceholder"
              disabled={
                loading ||
                (!!subFolders.length && account?.type === ProviderType.Gmail)
              }
            />
          </div>
          {!!subFolders.length && account?.type === ProviderType.Gmail && (
            <div className="form__row">
              <div className="warning-box">
                <I18n value="email.error.gmailRenameParentFolder" />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label="email.updateFolderModal.action"
            disabled={
              loading ||
              (!!subFolders.length && account?.type === ProviderType.Gmail)
            }
            icon={
              loading ? (
                <Icon name="spinner" spin regular containIn={24} />
              ) : null
            }
            onClick={onSaveCallback}
          />
        </ModalFooter>
      </Modal>
    );
  }
);
