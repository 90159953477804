import { EnergyClassOption, ObjectAssignment } from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment;
  energyClassOptions: EnergyClassOption[];
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentBogEnergyAndCostsComponent extends React.Component<
  Props
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { energyLabel } = this.props.objectAssignment;

    this.formControls = {
      energyClass: { value: value(energyLabel, "energyClass") },
      energyEndDate: { value: value(energyLabel, "endDate") },
      energyCertificateNumber: {
        value: value(energyLabel, "certificateNumber")
      },
      energyIndex: { value: value(energyLabel, "energyIndex") }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { energyLabel } = this.props.objectAssignment;

      this.formRef.update({
        energyClass: value(energyLabel, "energyClass"),
        energyEndDate: value(energyLabel, "endDate"),
        energyCertificateNumber: value(energyLabel, "certificateNumber"),
        energyIndex: value(energyLabel, "energyIndex")
      }, true);
    }
  }

  public render() {
    return (
      <Form
        name="energy-costs"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
      >
        <div className="form__row">
          <label htmlFor="energyClass">
            <ResourceText resourceKey="energyClass" />
          </label>
          <Input.RadioGroup
            name="energyClass"
            asButtonList
            styleName="energy-class"
          >
            {this.props.energyClassOptions.map((energyClass, idx) => (
              <Input.Radio
                value={energyClass.value}
                label={energyClass.displayName}
                key={idx}
                allowUnCheck={true}
              />
            ))}
          </Input.RadioGroup>
        </div>

        <div className="form__row">
          <div className="form__group stretch">
            <div className="column">
              <label htmlFor="energyEndDate">
                <ResourceText resourceKey="energyEndDate" />
              </label>
              <Input.Datepicker name="energyEndDate" />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="energyIndex">
                <ResourceText resourceKey="energyIndex" />
              </label>
              <Input.Number name="energyIndex" max={10} />
            </div>
            <div className="column__spacer" />
            <div className="column">
              <label htmlFor="energyCertificateNumber">
                <ResourceText resourceKey="energyCertificateNumber" />
              </label>
              <Input.Text
                name="energyCertificateNumber"
                placeholder="certificateNumberPlaceHolder"
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    const value = {
      energyLabel: {
        ...objectAssignment.energyLabel,
        energyClass: values.energyClass,
        endDate: values.energyEndDate,
        certificateNumber: values.energyCertificateNumber,
        energyIndex: values.energyIndex
      }
    };

    this.props.onChange(value);
  }
}
