import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

import { WeekStats } from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { PageLoader, ResourceText } from "@haywork/modules/shared";

const styles = require("./sales-widget.component.scss");

interface DashboardSalesWidgetComponentProps {
  statisticsThisWeek: WeekStats;
  statisticsLastWeek: WeekStats;
  state: string;
}
interface DashboardSalesWidgetComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class DashboardSalesWidgetComponent extends React.Component<
  DashboardSalesWidgetComponentProps,
  DashboardSalesWidgetComponentState
> {
  public render() {
    if (this.props.state === REQUEST.PENDING) {
      return (
        <div className="widget-wrapper">
          <article className="widget" styleName="widgetSales">
            <header>
              <span className="icon">
                <i className="fal fa-fw fa-truck" />
              </span>
              <span className="text">
                <ResourceText resourceKey="salesWidgetsTransactionsThisWeek" />
              </span>
            </header>
            <div className="content loading">
              <PageLoader loading />
            </div>
          </article>
        </div>
      );
    }

    const currentWeekNrOfTransactions = this.props.statisticsThisWeek
      ? this.props.statisticsThisWeek.nrOfTransactions
      : 0;
    const lastWeekNrOfTransactions = this.props.statisticsLastWeek
      ? this.props.statisticsLastWeek.nrOfTransactions
      : 0;

    if (!currentWeekNrOfTransactions && !lastWeekNrOfTransactions) {
      return (
        <div className="widget-wrapper">
          <article className="widget" styleName="widgetSales">
            <header>
              <span className="icon">
                <i className="fal fa-fw fa-truck" />
              </span>
              <span className="text">
                <ResourceText resourceKey="salesWidgetsTransactionsThisWeek" />
              </span>
            </header>
            <div className="content neutral" styleName="content">
              <span className="mainValue">-</span>
            </div>
            <footer>
              <ResourceText resourceKey="lastWeek" />:{" "}
              <span className="result neutral">
                {<ResourceText resourceKey="salesWidgetNoTransactions" />}
              </span>
            </footer>
          </article>
        </div>
      );
    }

    let lessThanLastPeriod: boolean = false;
    let sameAsLastPeriod: boolean = false;

    if (currentWeekNrOfTransactions < lastWeekNrOfTransactions) {
      lessThanLastPeriod = true;
    } else if (currentWeekNrOfTransactions === lastWeekNrOfTransactions) {
      sameAsLastPeriod = true;
    }

    const contentSpanClass = classNames("content", {
      warning: lessThanLastPeriod && !sameAsLastPeriod,
      positive: !lessThanLastPeriod && !sameAsLastPeriod,
      neutral: sameAsLastPeriod,
    });
    const footerSpanClass = classNames("result", {
      negative: lessThanLastPeriod && !sameAsLastPeriod,
      positive: !lessThanLastPeriod && !sameAsLastPeriod,
      neutral: sameAsLastPeriod,
    });
    return (
      <div className="widget-wrapper">
        <article className="widget" styleName="widgetSales">
          <header>
            <span className="icon">
              <i className="fal fa-fw fa-truck" />
            </span>
            <span className="text">
              <ResourceText resourceKey="salesWidgetsTransactionsThisWeek" />
            </span>
          </header>
          <div className={contentSpanClass} styleName="content">
            {currentWeekNrOfTransactions === 0 ? (
              <span className="mainValue">-</span>
            ) : (
              <span className="mainValue">{currentWeekNrOfTransactions}</span>
            )}
          </div>
          <footer>
            <ResourceText resourceKey="lastWeek" />:{" "}
            <span className={footerSpanClass}>
              {/* resource keys */}
              {lastWeekNrOfTransactions === 0 && (
                <ResourceText resourceKey="salesWidgetNoTransactions" />
              )}
              {lastWeekNrOfTransactions === 1 && (
                <ResourceText resourceKey="salesWidgetOneTransactions" />
              )}
              {lastWeekNrOfTransactions > 1 && (
                <ResourceText
                  resourceKey="salesWidgetMoreTransactions"
                  values={{ transactionCount: lastWeekNrOfTransactions }}
                />
              )}
            </span>
          </footer>
        </article>
      </div>
    );
  }
}
