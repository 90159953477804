import {
  BatchItem,
  BatchResponseItem,
  DossierItemSnapShot,
  DocumentSessionSnapShot,
} from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import {
  BlobThunk,
  DossierThunks,
  DynamicDocumentsThunks,
  InvoiceThunk,
  MapDispatchToProps,
} from "@haywork/middleware";
import { canSendEmail } from "@haywork/selector";
import { AppState } from "@haywork/stores";
import { SnackbarActions, ToastProps } from "@haywork/stores/snackbar-v2";
import { push, RouterAction } from "connected-react-router";
import {
  ConnectDragSource,
  DragElementWrapper,
  DragPreviewOptions,
  DragSource,
  DragSourceCollector,
  DragSourceSpec,
} from "react-dnd";
import { connect, MapStateToProps } from "react-redux";
import { DossierItemComponent, DossierItemComponentProps } from "./item";
import { defaultSendAccountId } from "./selectors";

interface StateProps {
  token: string;
  canSendEmail: boolean;
  currentAccount: string;
}
interface DispatchProps {
  navigate: (path: string) => RouterAction;
  downloadDocument: (id: string) => void;
  updateDossierItem: (snapshot: DossierItemSnapShot) => Promise<void>;
  archiveDocumentSession: (id: string) => Promise<void>;
  unArchiveDocumentSession: (id: string) => Promise<void>;
  deleteDocumentSession: (
    id: string,
    undeleteCallback: () => void
  ) => Promise<void>;
  archiveInvoice: (id: string) => Promise<void>;
  unArchiveInvoice: (id: string) => Promise<void>;
  deleteInvoice: (id: string, undeleteCallback: () => void) => Promise<void>;
  deleteDossier: (
    id: string,
    parentId: string,
    undeleteCallback: () => void
  ) => Promise<void>;
  createDossierItemEditSession: (dossierItemId: string) => Promise<void>;
  createDocumentSessionEditSession: (
    documentSessionId: string
  ) => Promise<void>;
  createBatchDownload: (batchItem: BatchItem) => Promise<BatchResponseItem[]>;
  uploadFilesFromUri: (
    accountId: string,
    files: BatchResponseItem[]
  ) => Promise<UploadResponse[]>;
  downloadInvoice: (id: string) => Promise<void>;
  updateDocumentSession: (snapshot: DocumentSessionSnapShot) => Promise<void>;
}

const toast: ToastProps = {
  value: "toastEditFileWithOffice",
  icon: "spinner",
  spin: true,
};

const mapStateToProps: MapStateToProps<
  StateProps,
  DossierItemComponentProps,
  AppState
> = (state) => {
  const { token } = state.access;

  return {
    token,
    canSendEmail: canSendEmail(state),
    currentAccount: defaultSendAccountId(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DossierItemComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path)),
  downloadDocument: (id: string) =>
    dispatch(DynamicDocumentsThunks.downloadDocument(id)),
  updateDossierItem: (snapshot: DossierItemSnapShot) =>
    dispatch(DossierThunks.updateDossierItem(snapshot)),
  archiveDocumentSession: (id: string) =>
    dispatch(DynamicDocumentsThunks.archiveDocument(id)),
  unArchiveDocumentSession: (id: string) =>
    dispatch(DynamicDocumentsThunks.unArchiveDocument(id)),
  deleteDocumentSession: (id: string, undeleteCallback: () => void) =>
    dispatch(DynamicDocumentsThunks.deleteDocument(id, undeleteCallback)),
  archiveInvoice: (id: string) => dispatch(InvoiceThunk.archiveInvoice(id)),
  unArchiveInvoice: (id: string) => dispatch(InvoiceThunk.unArchiveInvoice(id)),
  deleteInvoice: (id: string, undeleteCallback: () => void) =>
    dispatch(InvoiceThunk.deleteInvoice(id, undeleteCallback)),
  deleteDossier: (id: string, parentId: string, undeleteCallback: () => void) =>
    dispatch(DossierThunks.deleteDossier(id, parentId, undeleteCallback)),
  createDossierItemEditSession: (dossierItemId: string) => {
    dispatch(SnackbarActions.addToast(toast));
    return dispatch(BlobThunk.createDossierItemEditSession(dossierItemId));
  },
  createDocumentSessionEditSession: (documentSessionId: string) => {
    dispatch(SnackbarActions.addToast(toast));
    return dispatch(
      BlobThunk.createDocumentSessionEditSession(documentSessionId)
    );
  },
  createBatchDownload: (batchItem: BatchItem) =>
    dispatch(BlobThunk.createBatchDownload([batchItem])),
  uploadFilesFromUri: (accountId: string, files: BatchResponseItem[]) =>
    dispatch(BlobThunk.uploadFilesFromUri(accountId, files)),
  downloadInvoice: (id: string) => dispatch(InvoiceThunk.downloadInvoice(id)),
  updateDocumentSession: (snapshot: DocumentSessionSnapShot) =>
    dispatch(DynamicDocumentsThunks.updateDocumentSessionMeta(snapshot)),
});

export type DossierItemContainerProps = StateProps &
  DispatchProps & {
    connectDragSource?: ConnectDragSource;
    isDragging?: boolean;
    connectDragPreview?: DragElementWrapper<DragPreviewOptions>;
  };
const DossierItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DossierItemComponent);

const dragSpec: DragSourceSpec<DossierItemComponentProps, any> = {
  beginDrag(props) {
    const { file, pathname, selectedFiles } = props;
    return {
      file,
      pathname,
      selectedFilesCount: !!selectedFiles ? selectedFiles.length : 0,
    };
  },
};

const dragCollect: DragSourceCollector<any, any> = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  connectDragPreview: connect.dragPreview(),
});

export const DraggableDossierItemComponent = DragSource(
  "DossierFile",
  dragSpec,
  dragCollect
)(DossierItemContainer);
