import {
  DocumentSession,
  DocumentTemplate,
  LinkedAssignment,
  LinkedRelation
} from "@haywork/api/kolibri";
import {
  BlobThunk,
  DynamicDocumentsThunks,
  EditableThunks,
  MapDispatchToProps
} from "@haywork/middleware";
import {
  DossierLinkType,
  DynamicDocumentsDownloadComponent,
  DynamicDocumentsDownloadComponentProps
} from "@haywork/modules/dynamic-documents";
import { AppState, LayoutActions } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { links } from "./selectors";

interface StateProps {
  session: DocumentSession;
  template: DocumentTemplate;
  links: DynamicDocumentMetaLink[];
  documentStatus: string;
}
interface DispatchProps {
  downloadDocument: (id: string) => void;
  closeDocument: (path: string) => void;
  createDocumentSessionEditSession: (id: string) => void;
}

export interface DynamicDocumentMetaLink {
  type: DossierLinkType;
  relations?: LinkedRelation[];
  assignment?: LinkedAssignment;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  DynamicDocumentsDownloadComponentProps,
  AppState
> = (state) => {
  const { session, template, documentStatus } = state.dynamicDocuments.single;

  return {
    session,
    template,
    links: links(state),
    documentStatus
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  DynamicDocumentsDownloadComponentProps
> = (dispatch) => ({
  downloadDocument: (id: string) =>
    dispatch(DynamicDocumentsThunks.downloadDocument(id)),
  closeDocument: (path: string) => {
    dispatch(EditableThunks.remove(path));
    dispatch(LayoutActions.toggleFullscreen(false));
  },
  createDocumentSessionEditSession: (id: string) =>
    dispatch(BlobThunk.createDocumentSessionEditSession(id))
});

export type DynamicDocumentsDownloadContainerProps = StateProps & DispatchProps;
export const DynamicDocumentsDownloadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentsDownloadComponent);
