import * as React from "react";
import { FC, memo, useMemo, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import { TimelineEvent } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import { StringUtil } from "@haywork/util";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";
import ActionList, { Action } from "@haywork/components/ui/action-list";

const styles = require("./style.scss");

type Props = {
  event: TimelineEvent;
  parentId: string;
  onNavigate: (path: string) => void;
  onClick: (event: TimelineEvent) => void;
  onDelete: (event: TimelineEvent) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const CommunicationPhoneItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      event,
      parentId,
      onNavigate,
      onClick,
      onDelete,
      onShowAllLinkedEntities
    }) => {
      const { subject, linkedAssignments, linkedRelations, date } = event;
      const actionRef = useRef<HTMLDivElement>(null);
      const [actionsVisible, setActionsVisible] = useState(false);
      const dateField = useMemo(
        () =>
          !!date ? (
            <div styleName="date">
              {moment(date).format("DD MMMM YYYY HH:mm")}
            </div>
          ) : null,
        []
      );
      const onCommunicationPhoneClick = () => {
        onClick(event);
      };
      const toggleActions = () => {
        setActionsVisible(!actionsVisible);
      };
      const onDeleteClickHandler = () => {
        onDelete(event);
        setActionsVisible(false);
      };

      return (
        <div styleName="event__item" onClick={onCommunicationPhoneClick}>
          <div styleName="event__icon">
            <Icon name="phone" light size={24} color={Colors.Gray} />
          </div>
          <div styleName="event__divider"></div>
          <div styleName="event__data">
            <div styleName="subject">
              {!!subject ? (
                StringUtil.stripHTML(subject)
              ) : (
                <I18n value="timeline.missingSubject" />
              )}
            </div>
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          <div styleName="event__date">{dateField}</div>
          <div styleName="event__actions">
            <div
              styleName="trigger"
              ref={actionRef}
              onClick={(event) => {
                event.stopPropagation();
                setActionsVisible(true);
              }}
            >
              <Icon name="ellipsis-v" color={Colors.Gray} size={24} light />
            </div>
          </div>

          <ActionList
            parent={actionRef}
            visible={actionsVisible}
            onHide={toggleActions}
          >
            <Action
              label="timeline.action.delete"
              icon={<Icon name="trash-alt" color={Colors.Gray} fixedWidth />}
              onClick={onDeleteClickHandler}
            />
          </ActionList>
        </div>
      );
    }
  )
);

export default CommunicationPhoneItemComponent;
