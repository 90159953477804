import {
  ComfortQualityOption,
  ConditionOption,
  EnergySourceOption,
  HeatingMethod,
  HeatingMethodOption,
  HeatingWaterMethod,
  HeatingWaterMethodOption,
  IsolationTypeOption,
  ObjectTypeAssignment,
  OwnershipOption,
  ParkingFacilityOption,
  ResidentialFacilityOption,
  RoofMaterialOption,
  RoofTypeOption,
  TypePART,
} from "@haywork/api/kolibri";
import { FloorLevel } from "@haywork/modules/assignment/components/edit-content/shared";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  FloorsAndSpaces,
  ResourceText,
  RoomLayoutContainer,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("../edit-specs.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface Props {
  currentType: ObjectTypeAssignment;
  isolationTypeOptions: IsolationTypeOption[];
  heatingMethodOptions: HeatingMethodOption[];
  heatingWaterMethodOptions: HeatingWaterMethodOption[];
  energySourceOptions: EnergySourceOption[];
  ownershipOptions: OwnershipOption[];
  comfortQualityOptions: ComfortQualityOption[];
  roofTypeOptions: RoofTypeOption[];
  roofMaterialOptions: RoofMaterialOption[];
  conditionOptions: ConditionOption[];
  parkingFacilityOptions: ParkingFacilityOption[];
  residentialFacilityOptions: ResidentialFacilityOption[];
  onChange: (type: ObjectTypeAssignment) => void;
}
interface State {
  floors: number[];
}

@CSSModules(styles, { allowMultiple: true })
export class ObjectTypesEditSpecsDefaultComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onContentChangeHandler = this.onContentChangeHandler.bind(this);

    const { currentType } = this.props;
    const {
      numberOfFloors,
      floorLevel,
      isBarnStorageAvailable,
      insulationTypes,
      heating,
      warmWaterTypes,
      boilerYearOfConstruction,
      boilerType,
      hasCombiBoiler,
      boilerFuel,
      boilerProperty,
      isPermanentlyInhabited,
      isRecreational,
      inPark,
      comfortQuality,
      roof,
      residentialFacilities,
      parkingFacilities,
      isOfficeSpaceAvailable,
    } = currentType;

    this.state = {
      floors: this.renderFloorsArray(
        parseInt(value(currentType, "numberOfFloors", 1))
      ),
    };

    this.formControls = {
      numberOfFloors: {
        value: numberOfFloors || 1,
        onChange: (ref) => {
          const floors = this.renderFloorsArray(parseInt(ref.value));
          this.setState({ floors });
        },
      },
      floorLevel: { value: floorLevel },
      isBarnStorageAvailable: { value: isBarnStorageAvailable },
      isOfficeSpaceAvailable: { value: isOfficeSpaceAvailable },

      insulationTypes: { value: insulationTypes },
      heating: { value: heating },
      warmWaterTypes: { value: warmWaterTypes },
      boilerYearOfConstruction: { value: boilerYearOfConstruction },
      boilerType: { value: boilerType },
      hasCombiBoiler: { value: hasCombiBoiler },
      boilerFuel: { value: boilerFuel },
      boilerProperty: { value: boilerProperty },

      isPermanentlyInhabited: { value: isPermanentlyInhabited },
      isRecreational: { value: isRecreational },
      inPark: { value: inPark },
      comfortQuality: { value: comfortQuality },
      roofType: { value: value(roof, "roofType") },
      roofMaterials: { value: value(roof, "roofMaterials") },
      residentialFacilities: { value: residentialFacilities },
      parkingFacilities: { value: parkingFacilities },
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.currentType, "dateTimeModified") !==
        get(this.props.currentType, "dateTimeModified")
    ) {
      const { currentType } = this.props;
      const {
        numberOfFloors,
        floorLevel,
        isBarnStorageAvailable,
        insulationTypes,
        heating,
        warmWaterTypes,
        boilerYearOfConstruction,
        boilerType,
        hasCombiBoiler,
        boilerFuel,
        boilerProperty,
        isPermanentlyInhabited,
        isRecreational,
        inPark,
        comfortQuality,
        roof,
        residentialFacilities,
        parkingFacilities,
        isOfficeSpaceAvailable,
      } = currentType;

      this.setState({
        floors: this.renderFloorsArray(
          parseInt(value(currentType, "numberOfFloors", 1))
        ),
      });

      this.formRef.update(
        {
          numberOfFloors: numberOfFloors || 1,
          floorLevel,
          isBarnStorageAvailable,
          isOfficeSpaceAvailable,
          insulationTypes,
          heating,
          warmWaterTypes,
          boilerYearOfConstruction,
          boilerType,
          hasCombiBoiler,
          boilerFuel,
          boilerProperty,
          isPermanentlyInhabited,
          isRecreational,
          inPark,
          comfortQuality,
          roofType: value(roof, "roofType"),
          roofMaterials: value(roof, "roofMaterials"),
          residentialFacilities,
          parkingFacilities,
        },
        true
      );
    }
  }

  public render() {
    let { heating, warmWaterTypes } = this.props.currentType;

    heating = heating || [];
    warmWaterTypes = warmWaterTypes || [];

    const hasBoiler =
      heating.indexOf(HeatingMethod.Boiler) !== -1 ||
      warmWaterTypes.indexOf(HeatingWaterMethod.Boiler) !== -1;

    return (
      <div styleName="specs" id="scroll-to-top">
        <div className="container-fluid">
          <Form
            name="project-type-general"
            formControls={this.formControls}
            onChange={this.onChangeHandler}
            form={(form) => (this.formRef = form)}
          >
            <StepperComponent initial={0} scrollToElementId="scroll-to-top">
              <StepComponent title="assignment.FloorsAndSpaces">
                <FloorLevel
                  objectAssignment={this.props.currentType}
                  onChange={this.props.onChange}
                />
                <FloorsAndSpaces
                  objectAssignment={this.props.currentType}
                  onChange={this.props.onChange}
                />
              </StepComponent>

              <StepComponent title="assignmentOtherSpacesTab">
                {this.props.currentType.typePART === TypePART.Apartment && (
                  <div className="form__row">
                    <div className="column__join">
                      <div className="column__spacer" />
                      <div className="column" styleName="input">
                        <label htmlFor="floorLevel">
                          <ResourceText resourceKey="floorLevel" />
                        </label>
                        <Input.Number name="floorLevel" min={1} max={99} />
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="isBarnStorageAvailable"
                        on={true}
                        off={false}
                        label="projectTypesLabel.isBarnStorageAvailable"
                        labelPosition={SwitchLabelPosition.Post}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Switch
                        name="isOfficeSpaceAvailable"
                        on={true}
                        off={false}
                        label="projectTypesLabel.isOfficeSpaceAvailable"
                        labelPosition={SwitchLabelPosition.Post}
                      />
                    </div>
                  </div>
                </div>

                <RoomLayoutContainer
                  floors={this.state.floors}
                  data={{
                    floors: this.props.currentType.floors || [],
                    gardens: this.props.currentType.gardens || [],
                    garages: this.props.currentType.garages || [],
                  }}
                  onChange={this.onContentChangeHandler}
                  onStorageChange={this.onContentChangeHandler}
                />
              </StepComponent>

              <StepComponent title="projectTypesEnergyTab">
                <div className="form__row">
                  <label htmlFor="insulationTypes">
                    <ResourceText resourceKey="insulationTypes" />
                  </label>
                  <Input.Multi
                    name="insulationTypes"
                    values={this.props.isolationTypeOptions}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="heating">
                    <ResourceText resourceKey="heating" />
                  </label>
                  <Input.Multi
                    name="heating"
                    values={this.props.heatingMethodOptions}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="warmWaterTypes">
                    <ResourceText resourceKey="warmWaterTypes" />
                  </label>
                  <Input.Multi
                    name="warmWaterTypes"
                    values={this.props.heatingWaterMethodOptions}
                  />
                </div>

                {hasBoiler && (
                  <React.Fragment>
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column">
                          <label htmlFor="boilerYearOfConstruction">
                            <ResourceText resourceKey="boilerYearOfConstruction" />
                          </label>
                          <Input.Number name="boilerYearOfConstruction" />
                        </div>
                        <div className="column__spacer" />
                        <div className="column push-label">
                          <Input.Switch
                            name="hasCombiBoiler"
                            label="hasCombiBoiler"
                            labelPosition={SwitchLabelPosition.Pre}
                            on={true}
                            off={false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form__row">
                      <label htmlFor="boilerType">
                        <ResourceText resourceKey="boilerType" />
                      </label>
                      <Input.Text name="boilerType" />
                    </div>

                    <div className="form__row">
                      <div className="form__group">
                        <div className="column no-wrap">
                          <label htmlFor="boilerFuel">
                            <ResourceText resourceKey="boilerFuel" />
                          </label>
                          <Input.RadioGroup
                            name="boilerFuel"
                            canUnselect
                            asButtonList
                          >
                            {this.props.energySourceOptions.map(
                              (energySourceOption, idx) => (
                                <Input.Radio
                                  value={energySourceOption.value}
                                  label={energySourceOption.displayName}
                                  key={idx}
                                />
                              )
                            )}
                          </Input.RadioGroup>
                        </div>
                        <div className="column__spacer" />
                        <div className="column no-wrap">
                          <label htmlFor="boilerProperty">
                            <ResourceText resourceKey="boilerProperty" />
                          </label>
                          <Input.RadioGroup
                            name="boilerProperty"
                            asButtonList
                            canUnselect
                          >
                            {this.props.ownershipOptions.map(
                              (ownershipOption, idx) => (
                                <Input.Radio
                                  value={ownershipOption.value}
                                  label={ownershipOption.displayName}
                                  key={idx}
                                />
                              )
                            )}
                          </Input.RadioGroup>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </StepComponent>

              <StepComponent title="projectTypesPeculiaritiesTab">
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="inhabitType" />
                  </label>
                  <div className="form__group">
                    <div className="column">
                      <Input.Switch
                        name="isPermanentlyInhabited"
                        label="isPermanentlyInhabited"
                        on={true}
                        off={false}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Switch
                        name="isRecreational"
                        label="isRecreational"
                        on={true}
                        off={false}
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <Input.Switch
                        name="inPark"
                        label="inPark"
                        on={true}
                        off={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <label htmlFor="comfortQuality">
                    <ResourceText resourceKey="comfortQuality" />
                  </label>
                  <Input.RadioGroup name="comfortQuality" asButtonList>
                    {this.props.comfortQualityOptions.map(
                      (comfortQualityOption, idx) => (
                        <Input.Radio
                          value={comfortQualityOption.value}
                          label={comfortQualityOption.displayName}
                          key={idx}
                        />
                      )
                    )}
                  </Input.RadioGroup>
                </div>

                <div className="form__row">
                  <div className="form__group">
                    <div className="column column__minwidth">
                      <label htmlFor="roofType">
                        <ResourceText resourceKey="roofType" />
                      </label>
                      <Input.NewSelect
                        name="roofType"
                        placeholder="makeAChoice"
                        values={this.props.roofTypeOptions}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row">
                  <label htmlFor="roofMaterials">
                    <ResourceText resourceKey="roofMaterials" />
                  </label>
                  <Input.Multi
                    name="roofMaterials"
                    values={this.props.roofMaterialOptions}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="parkingFacilities">
                    <ResourceText resourceKey="parkingFacilities" />
                  </label>
                  <Input.Multi
                    name="parkingFacilities"
                    values={this.props.parkingFacilityOptions}
                  />
                </div>

                <div className="form__row">
                  <label htmlFor="residentialFacilities">
                    <ResourceText resourceKey="residentialFacilities" />
                  </label>
                  <Input.Multi
                    name="residentialFacilities"
                    values={this.props.residentialFacilityOptions}
                  />
                </div>
              </StepComponent>
            </StepperComponent>
          </Form>
        </div>
      </div>
    );
  }

  private onContentChangeHandler(values: FormReturnValue) {
    let { currentType } = this.props;

    currentType = {
      ...currentType,
      ...values,
    };

    this.props.onChange(currentType);
  }

  private renderFloorsArray(count: number): number[] {
    const numbers = [];

    for (let i = 0; i < count; i++) {
      numbers.push(i);
    }

    return numbers;
  }

  private onChangeHandler(values: FormReturnValue) {
    let { currentType } = this.props;

    currentType = {
      ...currentType,
      boilerFuel: values.boilerFuel,
      boilerProperty: values.boilerProperty,
      boilerType: values.boilerType,
      boilerYearOfConstruction: values.boilerYearOfConstruction,
      comfortQuality: values.comfortQuality,
      floorLevel: values.floorLevel,
      hasCombiBoiler: values.hasCombiBoiler,
      heating: values.heating,
      inPark: values.inPark,
      insulationTypes: values.insulationTypes,
      isBarnStorageAvailable: values.isBarnStorageAvailable,
      isOfficeSpaceAvailable: values.isOfficeSpaceAvailable,
      isPermanentlyInhabited: values.isPermanentlyInhabited,
      isRecreational: values.isRecreational,
      numberOfFloors: values.numberOfFloors,
      parkingFacilities: values.parkingFacilities,
      residentialFacilities: values.residentialFacilities,
      roof: {
        ...currentType.roof,
        roofType: values.roofType,
        roofMaterials: values.roofMaterials,
      },
      warmWaterTypes: values.warmWaterTypes,
    };

    this.props.onChange(currentType);
  }
}
