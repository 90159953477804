import { PublicationSnapShot } from "@haywork/api/kolibri";
import { MapDispatchToProps, PublicationsThunk } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  PublishPublicationsComponent,
  PublishPublicationsComponentProps
} from "./publish-publications";

type StateProps = {};
type DispatchProps = {
  getPublications: (id: string) => Promise<PublicationSnapShot[]>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  PublishPublicationsComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  PublishPublicationsComponentProps
> = (dispatch) => ({
  getPublications: (id: string) =>
    dispatch(PublicationsThunk.getPublications(id))
});

export type PublishPublicationsContainerProps = StateProps & DispatchProps;
export const PublishPublicationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishPublicationsComponent);
