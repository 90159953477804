import {
  AcquisitionAssignment,
  AssignmentSnapShot,
  LinkedSearchAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AcquisitionThunks } from "@haywork/middleware/thunk/acquisitions";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  AcquisitionDetailAssignmentsScreen,
  AcquisitionDetailAssignmentsScreenProps,
} from "./assignments";
import { RealEstatePropertySearchItem } from "@haywork/api/mls";
import { EmailThunk } from "@haywork/middleware/thunk/emailV2";
import { EmailAssignment } from "@haywork/util/email-v2";

type StateProps = {
  acquisition: AcquisitionAssignment;
};
type DispatchProps = {
  createNew: (acquisition?: AcquisitionAssignment) => void;
  navigate: (path: string) => RouterAction;
  getAcquisitionObjects: (
    id: string,
    startIndex: number,
    stopIndex: number
  ) => Promise<{
    items: AssignmentSnapShot[];
    total: number;
  }>;
  getMlsObjectsMatchingSearchAssignments: (
    linkedSearchAssignments: LinkedSearchAssignment[],
    startIndex: number,
    stopIndex: number
  ) => Promise<{ items: RealEstatePropertySearchItem[]; total: number }>;
  createNewAcquisitionObjectAssignmentFromMLS: (
    bundleId: string,
    appClientKey: string,
    acquisition: AcquisitionAssignment
  ) => Promise<void>;
  mailAssignmentSelection: (
    assignments: EmailAssignment[],
    linkedRelations?: LinkedRelation[]
  ) => Promise<void>;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  AcquisitionDetailAssignmentsScreenProps,
  AppState
> = (state) => {
  const { acquisition } = state.acquisition.single;

  return {
    acquisition,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AcquisitionDetailAssignmentsScreenProps
> = (dispatch) => ({
  createNew: (acquisition?: AcquisitionAssignment) =>
    dispatch(AcquisitionThunks.AcquisitionObject.createNew(acquisition)),
  navigate: (path: string) => dispatch(push(path)),
  getAcquisitionObjects: (id: string, startIndex: number, stopIndex: number) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.getAcquisitionObjects(
        id,
        startIndex,
        stopIndex
      )
    ),
  getMlsObjectsMatchingSearchAssignments: (
    linkedSearchAssignments: LinkedSearchAssignment[],
    startIndex: number,
    stopIndex: number
  ) =>
    dispatch(
      AcquisitionThunks.Acquisition.getMlsObjectsMatchingSearchAssignments(
        linkedSearchAssignments,
        startIndex,
        stopIndex
      )
    ),
  createNewAcquisitionObjectAssignmentFromMLS: (
    bundleId: string,
    appClientKey: string,
    acquisition: AcquisitionAssignment
  ) =>
    dispatch(
      AcquisitionThunks.AcquisitionObject.createNewAcquisitionObjectAssignmentFromMLS(
        bundleId,
        appClientKey,
        acquisition
      )
    ),
  mailAssignmentSelection: (
    assignments: EmailAssignment[],
    linkedRelations?: LinkedRelation[]
  ) =>
    dispatch(
      EmailThunk.Main.mailAssignmentSelection(assignments, linkedRelations)
    ),
});

export type AcquisitionDetailAssignmentsScreenContainerProps = StateProps &
  DispatchProps;
export const AcquisitionDetailAssignmentsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisitionDetailAssignmentsScreen);
