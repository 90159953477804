export const RELATION = {
  APPEND_ASSIGNMENTS: "APPEND_RELATION_ASSIGNMENTS",
  APPEND_CONTACT_LINKS: "APPEND_CONTACT_LINK",
  APPEND_SINGLE_CONTACT_LINK: "APPEND_SINGLE_CONTACT_LINK",
  APPEND_RELATION: "APPEND_RELATION",
  APPEND_TIMELINE_EVENTS: "RELATION_APPEND_TIMELINE_EVENTS",
  ARCHIVE_RELATION: "ARCHIVE_RELATION",
  SEARCH_ASSIGNMENTS: "SEARCH_ASSIGNMENTS",
  SEARCH_CONTACT_COMPANY: "SEARCH_CONTACT_COMPANY",
  SEARCH_CONTACT_LINKS: "SEARCH_CONTACT_LINKS",
  SEARCH_CONTACT_PERSON: "SEARCH_CONTACT_PERSON",
  SEARCH_TIMELINE_EVENTS: "RELATION_SEARCH_TIMELINE_EVENTS",
  SEARCH_RELATION: "SEARCH_RELATION",
  SELECT_RELATION: "SELECT_RELATION",
  SEARCH_RELATIONGROUPS: "SEARCH_RELATIONGROUPS",
  SET_CONTACT_COMPANY_UPDATE_STATE: "SET_CONTACT_COMPANY_UPDATE_STATE",
  SET_RELATION_ASSIGNMENT_STATE: "SET_RELATION_ASSIGNMENT_STATE",
  SET_RELATION_EXPORT_URL: "SET_RELATION_EXPORT_URL",
  SET_FETCH_RELATION_GENERAL_CONTACT_INFO_STATE:
    "SET_RELATION_GENERAL_CONTACT_INFO_STATE",
  SET_PATH_FOR_NEW_RELATION: "SET_PATH_FOR_NEW_RELATION",
  SET_FETCH_RELATION_CONTACT_LINK_STATE:
    "SET_FETCH_RELATION_CONTACT_LINK_STATE",
  SET_RELATION_SAVE_CONTACT_LINK_STATE: "SET_RELATION_SAVE_CONTACT_LINK_STATE",
  SET_RELATION_ADDRESS_SEARCH_STATE: "SET_RELATION_ADDRESS_SEARCH_STATE",
  SET_FETCH_RELATION_OVERVIEW_STATE: "SET_FETCH_RELATION_OVERVIEW_STATE",
  SET_INITIAL_FETCH_RELATION_OVERVIEW_STATE:
    "SET_INITIAL_FETCH_RELATION_OVERVIEW_STATE",
  SET_FETCH_RELATION_TIMELINE_STATE: "SET_FETCH_RELATION_TIMELINE_STATE",
  SET_SAVE_CONTACT_INFO_STATE: "SET_SAVE_CONTACT_INFO_STATE",
  SET_SELECTED_CONTACT_COMPANY: "SET_SELECTED_CONTACT_COMPANY",
  SET_SELECTED_CONTACT_PERSON: "SET_SELECTED_CONTACT_PERSON",
  UPDATE_CONTACTLINK: "UPDATE_CONTACTLINK",
  UPDATE_RELATION_FILTER: "UPDATE_RELATION_FILTER",
  REMOVE_FROM_LIST: "RELATION_REMOVE_CL_FROM_LIST",
  SET_FETCH_RELATION_SEARCH_ASSIGNMENTS_STATUS:
    "SET_FETCH_RELATION_SEARCH_ASSIGNMENTS_STATUS",
  SET_RELATION_SEARCH_ASSIGNMENTS: "SET_RELATION_SEARCH_ASSIGNMENTS",

  SET_CONTACT_PERSON: "RELATIONS_SET_CONTACT_PERSON",
  SET_CONTACT_COMPANY: "RELATIONS_SET_CONTACT_COMPANY",
  ADD_PARTNER_TO_CONTACT_PERSON: "ADD_PARTNER_TO_CONTACT_PERSON",
  UPDATE_LIST_ITEM: "RELATIONS_UPDATE_LIST_ITEM",
  DELETE_LIST_ITEM: "RELATIONS_DELETE_LIST_ITEM"
};
