import debounce from "lodash-es/debounce";
import * as React from "react";
import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import * as CSSModules from "react-css-modules";
import { EmailContext } from "../../email.context";
import { SearchContainerProps } from "./search.container";
import { useIntl } from "react-intl";
import Icon from "@haywork/components/ui/icon";
import classNames from "classnames";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

export type SearchComponentProps = {
  disabled: boolean;
};
type Props = SearchComponentProps & SearchContainerProps;

export const SearchComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ filters, setFilters, disabled }) => {
      const { searchQuery } = filters;
      const { loading } = useContext(EmailContext);
      const [query, setQuery] = useState(searchQuery || "");
      const intl = useIntl();
      const nextSearch = useRef<string>("");

      const updateSearchQuery = useCallback(
        debounce(
          (value: string) => {
            if (loading) {
              nextSearch.current = value;
            } else {
              nextSearch.current = "";
              setFilters({
                ...filters,
                searchQuery: !value ? undefined : value,
              });
            }
          },
          500,
          { trailing: true }
        ),
        [filters, loading]
      );

      const onSearchQueryChangeCallback = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const query = event.target.value;
          setQuery(query);
          updateSearchQuery(query);
        },
        [updateSearchQuery]
      );

      const nextSearchCallback = useCallback(() => {
        if (!!loading || !nextSearch.current) return;
        setFilters({ ...filters, searchQuery: nextSearch.current });
        nextSearch.current = "";
      }, [loading, filters]);

      const onClearCallback = useCallback(() => {
        const { searchQuery, ...otherFilters } = filters;
        setFilters(otherFilters);
      }, [filters]);

      useEffect(() => {
        setQuery(searchQuery || "");
      }, [searchQuery]);

      useEffect(() => {
        nextSearchCallback();
      }, [loading, nextSearchCallback]);

      const placeholder = useMemo(() => {
        return (
          intl.formatMessage({
            id: "email.search.placeholder",
            defaultMessage: "Search",
          }) || "Search"
        );
      }, [intl]);

      const cancelable = useMemo(() => !!query.length, [query]);

      return (
        <div styleName={classNames("search", { cancelable })}>
          <input
            type="text"
            value={query}
            placeholder={placeholder}
            onChange={onSearchQueryChangeCallback}
            data-lpignore="true"
            disabled={disabled}
          />
          {!!cancelable && (
            <div styleName="clear" onClick={onClearCallback}>
              <Icon name="times" light color={Colors.Gray} />
            </div>
          )}
        </div>
      );
    }
  )
);
