import {
  AssignmentThunks,
  MapDispatchToProps
} from "@haywork/middleware/thunk";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { KolibriComponent, KolibriComponentProps } from "./kolibri";

type StateProps = {};
type DispatchProps = {
  addReceivedBrochures: (id: string) => void;
};

const mapStateToProps: MapStateToProps<
  StateProps,
  KolibriComponentProps,
  AppState
> = (state) => ({});
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  KolibriComponentProps
> = (dispatch) => ({
  addReceivedBrochures: (id: string) =>
    dispatch(AssignmentThunks.addReceivedBrochures(id))
});

export type KolibriContainerProps = StateProps & DispatchProps;
export const KolibriContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KolibriComponent);
