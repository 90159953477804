import { RelationSnapShot, RelationType } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { RELATIONROUTES } from "@haywork/constants";
import { Colors } from "@haywork/enum/colors";
import { ModalBody, ModalFooter } from "@haywork/modules/modal";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import { RouterAction } from "connected-react-router";
import * as React from "react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  relations: RelationSnapShot[];
  loading: boolean;
  mode: "events" | "create";
  onCreateRelation: () => void;
  onSelectRelation: (relation: RelationSnapShot) => void;
  navigate?: (path: string) => RouterAction;
};

const DuplicatesComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      relations,
      onCreateRelation,
      onSelectRelation,
      navigate,
      loading,
      mode,
    }) => {
      const [selectedId, setSelectedId] = useState("");

      const toggleSelectedId = useCallback(
        (id: string) => {
          setSelectedId(selectedId === id ? "" : id);
        },
        [selectedId, setSelectedId]
      );

      const createMode = useMemo(() => {
        return mode === "create";
      }, [mode]);

      const selectRelation = useCallback(() => {
        const relation = relations.find(
          (relation) => relation.id === selectedId
        );
        onSelectRelation(relation);
      }, [selectedId, relations]);

      const createRelationLink = useCallback(
        (relation: RelationSnapShot): string => {
          const { id, typeOfRelation } = relation;
          let uri;

          switch (typeOfRelation) {
            case RelationType.ContactCompany:
              uri = RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI;
              break;
            case RelationType.ContactPerson:
              uri = RELATIONROUTES.CONTACT_PERSON_DETAIL.URI;
              break;
            default:
              uri = "";
              break;
          }

          return route(uri, { id });
        },
        []
      );

      return (
        <>
          <ModalBody noPadding>
            <div styleName="info">
              <I18n
                value={
                  createMode
                    ? "duplicateContactModal.duplicates.body.create"
                    : "duplicateContactModal.duplicates.body"
                }
              />
            </div>
            <div styleName="body">
              <div styleName="relations">
                {relations.map((relation) => (
                  <div
                    key={relation.id}
                    styleName={classNames("relation", {
                      active: relation.id === selectedId,
                    })}
                    onClick={() => {
                      toggleSelectedId(relation.id);
                    }}
                  >
                    <div styleName="relation__dot" />
                    <div
                      styleName="relation__avatar"
                      style={{
                        backgroundImage: !!relation.avatarUrl
                          ? `url(${JSON.stringify(relation.avatarUrl)})`
                          : null,
                      }}
                    >
                      {!relation.avatarUrl && relation.letterAvatar}
                    </div>
                    <div styleName="relation__data">
                      <div styleName="relation__name">
                        {relation.displayName}
                      </div>
                      <div styleName="relation__contact">
                        {!!relation.email && (
                          <div styleName="contact-data">
                            <Icon
                              name="envelope"
                              solid
                              color={Colors.Gray}
                              size={14}
                            />
                            <div styleName="data">{relation.email}</div>
                          </div>
                        )}
                        {!!relation.phoneNumber && (
                          <div styleName="contact-data">
                            <Icon
                              name="phone"
                              solid
                              color={Colors.Gray}
                              size={14}
                            />
                            <div styleName="data">{relation.phoneNumber}</div>
                          </div>
                        )}
                        {!!relation.mobileNumber && (
                          <div styleName="contact-data">
                            <Icon
                              name="mobile-alt"
                              solid
                              color={Colors.Gray}
                              size={14}
                            />
                            <div styleName="data">{relation.mobileNumber}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              category="white"
              label="duplicateContactModal.action.createRelation"
              disabled={loading}
              onClick={onCreateRelation}
            />

            <Button
              category="primary"
              label={
                !createMode
                  ? "duplicateContactModal.action.selectRelationEvents"
                  : "duplicateContactModal.action.selectRelation"
              }
              onClick={selectRelation}
              disabled={!selectedId || loading}
              icon={
                !loading ? null : (
                  <Icon
                    name="spinner"
                    spin
                    regular
                    containIn={24}
                    color={Colors.Gray}
                  />
                )
              }
            />
          </ModalFooter>
        </>
      );
    }
  )
);

export default DuplicatesComponent;
