import { ActionType, ActionTypes } from "./main.actions";
import { TableResponse } from "@haywork/api/kolibri";
import { TableResponse as EventCenterTableResponse } from "@haywork/api/event-center";
import { MasterTableResponse as AuthorizationTableResponse } from "@haywork/api/authorization";
import { ReadMasterTableResponse as MlsTableResponse } from "@haywork/api/mls";
import { REQUEST, CULTURE } from "@haywork/constants";
import { nlResource, deResource, enResource } from "@haywork/translations";

export type MainState = {
  mastertable: {
    kolibri: TableResponse;
    eventCenter: EventCenterTableResponse;
    authorization: AuthorizationTableResponse;
    mls: MlsTableResponse;
  };
  appStatus: string;
  culture: string;
  defaultCulture: string;
  cultureMessages: Record<string, string>;
  languageStatus: string;
};

const INITIAL: MainState = {
  mastertable: {
    kolibri: null,
    eventCenter: null,
    authorization: null,
    mls: null,
  },
  appStatus: REQUEST.PENDING,
  culture: CULTURE.NL,
  defaultCulture: CULTURE.NL,
  cultureMessages: nlResource,
  languageStatus: REQUEST.IDLE,
};

export const mainReducer = (
  state = INITIAL,
  action: ActionTypes
): MainState => {
  switch (action.type) {
    case ActionType.SetAppStatus: {
      const { appStatus } = action;
      return { ...state, appStatus };
    }
    case ActionType.SetMasterTableValueKolibri: {
      const { kolibri } = action;
      return { ...state, mastertable: { ...state.mastertable, kolibri } };
    }
    case ActionType.SetMasterTableValueEventCenter: {
      const { eventCenter } = action;
      return { ...state, mastertable: { ...state.mastertable, eventCenter } };
    }
    case ActionType.SetMasterTableValueAuthorization: {
      const { authorization } = action;
      return { ...state, mastertable: { ...state.mastertable, authorization } };
    }
    case ActionType.SetMasterTableValueMls: {
      const { mls } = action;
      return { ...state, mastertable: { ...state.mastertable, mls } };
    }
    case ActionType.SetTranslations: {
      const { cultureMessages } = action;
      return {
        ...state,
        cultureMessages: { ...cultureMessages, ...nlResource },
      };
    }
    // case ActionType.SetApp REDUX.MAIN.SET_APP_LANGUAGE: {
    //   const { culture, mastertable } = <ActionType.Culture>action;
    //   const messages = MastertableUtil.mapValuesToTranslatables(mastertable);
    //   let localCultureMessages;

    //   switch (culture) {
    //     case CULTURE.DE:
    //       localCultureMessages = deResource;
    //       break;
    //     case CULTURE.EN:
    //       localCultureMessages = enResource;
    //       break;
    //     default:
    //       localCultureMessages = nlResource;
    //       break;
    //   }

    //   const cultureMessages = { ...messages, ...localCultureMessages };

    //   return {
    //     ...state,
    //     culture,
    //     cultureMessages,
    //     languageStatus: REQUEST.IDLE,
    //   };
    // }
    case ActionType.SetLanguageStatus: {
      const { languageStatus } = action;

      return { ...state, languageStatus };
    }
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
