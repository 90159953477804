import {
  RelationGroupsClient,
  RelationGroupsSaveRequest
} from "@haywork/api/kolibri";
import { ParseRequest } from "@haywork/services";
import { store } from "@haywork/stores";

const parseRequest = new ParseRequest();

const addRelationGroup = async (name: string) => {
  const state = store.getState();
  const { host } = state.appSettings;
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

  const RelationGroups = new RelationGroupsClient(host);

  try {
    const relationGroup = await parseRequest.response(
      RelationGroups.defineNew({}, realEstateAgencyId).then((response) => ({
        ...response.relationGroup,
        name
      }))
    );

    const request: RelationGroupsSaveRequest = {
      relationGroup
    };

    return await parseRequest.response(
      RelationGroups.save(request, realEstateAgencyId).then(
        (response) => response.relationGroup
      )
    );
  } catch (error) {
    throw error;
  }
};

export const RelationGroupsRequests = {
  addRelationGroup
};
