import { Dispatch } from ".";

import { AppState, PhrasesActions } from "@haywork/stores";
import {
  ActiveFilter,
  Phrase,
  PhraseOrderByField,
  PhrasesClient,
  SortOrder
} from "@haywork/api/kolibri";
import { REQUEST } from "@haywork/constants";
import { ConvertersUtil } from "@haywork/util";
import { ParseRequest } from "@haywork/services";

const parseRequest = new ParseRequest();

const getPhrases = (categoryId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhrasesStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      const phrases = await parseRequest.response(
        Phrases.search(
          {
            orderBy: PhraseOrderByField.DateTimeCreated,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            categoryId
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(PhrasesActions.setPhrases({ phrases }));
    } catch (error) {
      dispatch(PhrasesActions.setPhrasesStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getPhrase = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhraseStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      const phrase = await parseRequest.response(
        Phrases.read(id, realEstateAgencyId).then((response) => response.phrase)
      );

      dispatch(PhrasesActions.setPhrase({ phrase }));
    } catch (error) {
      dispatch(PhrasesActions.setPhraseStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const updatePhrase = (phrase: Phrase) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhraseStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      const updatedPhrase = await parseRequest.response(
        Phrases.save({ phrase }, realEstateAgencyId).then(
          (response) => response.phrase
        )
      );

      const { phrases: oldPhrases } = state.phrases;
      const phrases = oldPhrases.map((p) => {
        if (!!p && p.id === updatedPhrase.id) {
          return {
            ...p,
            title: updatedPhrase.title,
            description: updatedPhrase.description
          };
        }
        return p;
      });

      dispatch(PhrasesActions.setPhrase({ phrase: updatedPhrase }));
      dispatch(PhrasesActions.setPhrases({ phrases }));
    } catch (error) {
      dispatch(PhrasesActions.setPhraseStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const savePhrase = (title: string, description: string, categoryId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhraseStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      let phrase = await parseRequest.response(
        Phrases.defineNew({ categoryId }, realEstateAgencyId).then(
          (response) => response.phrase
        )
      );

      phrase = {
        ...phrase,
        title,
        description
      };

      const updatedPhrase = await parseRequest.response(
        Phrases.save({ phrase }, realEstateAgencyId).then(
          (response) => response.phrase
        )
      );

      const { phrases: oldPhrases } = state.phrases;
      const phrases = [
        ...oldPhrases,
        ConvertersUtil.convertPhraseToPhraseSnapshot(
          updatedPhrase,
          realEstateAgencyId
        )
      ];

      dispatch(PhrasesActions.setPhrase({ phrase: updatedPhrase }));
      dispatch(PhrasesActions.setPhrases({ phrases }));
    } catch (error) {
      dispatch(PhrasesActions.setPhraseStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deletePhrase = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhrasesStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      await parseRequest.response(Phrases.delete(id, realEstateAgencyId));

      dispatch(PhrasesActions.setPhrasesStatus(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(PhrasesActions.setPhrasesStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const unDeletePhrase = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(PhrasesActions.setPhrasesStatus(REQUEST.PENDING));

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const Phrases = new PhrasesClient(host);

    try {
      await parseRequest.response(Phrases.undelete({ id }, realEstateAgencyId));

      dispatch(PhrasesActions.setPhrasesStatus(REQUEST.SUCCESS));
    } catch (error) {
      dispatch(PhrasesActions.setPhrasesStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const deletePhraseFromList = (id: string) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { phrases: oldPhrases } = state.phrases;
    const phrases = oldPhrases.filter((p) => !!p && p.id !== id);

    dispatch(PhrasesActions.setPhrases({ phrases }));
  };
};

export const PhrasesThunks = {
  getPhrases,
  getPhrase,
  updatePhrase,
  savePhrase,
  deletePhrase,
  unDeletePhrase,
  deletePhraseFromList
};
