import { FinancialAdministration, ReportTemplate } from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Button from "@haywork/components/ui/button";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import {
  AvatarUploadTriggerComponent,
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
  Validators,
} from "@haywork/modules/form";
import { SettingsFinancialAdministrationsGeneralContainerProps } from "@haywork/modules/settings/modules";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./general.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface SettingsFinancialAdministrationsGeneralComponentProps {}
interface State {
  financialAdministration: FinancialAdministration;
  printTemplates: ReportTemplate[];
  b2bPrintTemplates: ReportTemplate[];
}
type Props = SettingsFinancialAdministrationsGeneralComponentProps &
  SettingsFinancialAdministrationsGeneralContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsFinancialAdministrationsGeneralComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);
    this.bindFormRef = this.bindFormRef.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    const financialAdministration = this.props.financialAdministrations.find(
      (f) => f.id === this.props.match.params.id
    );

    this.state = {
      financialAdministration,
      printTemplates: [],
      b2bPrintTemplates: [],
    };

    this.formControls = this.mapFormControls(financialAdministration);
    this.fetchTemplates = this.fetchTemplates.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (nextProps.match.params.id !== this.props.match.params.id) {
      const financialAdministration = nextProps.financialAdministrations.find(
        (f) => f.id === nextProps.match.params.id
      );

      this.setState({
        financialAdministration,
      });

      if (!this.formRef) return;
      this.formRef.update(this.mapFormControls(financialAdministration, true));
    }
  }

  public componentDidMount() {
    this.fetchTemplates();
  }

  public render() {
    if (!this.state.financialAdministration) {
      return null;
    }
    const loading = this.props.financialAdministrationState === REQUEST.PENDING;

    return (
      <div styleName="general">
        <PageHeader
          title="administrationGeneralHeader"
          subTitle={this.state.financialAdministration.name}
          actions={
            <Button
              label="save"
              category="success"
              onClick={this.onSubmitClickHandler}
              disabled={loading}
            />
          }
        />

        <div styleName="general__body">
          {loading && <Ui.Loaders.Fullscreen mask />}

          <div styleName="inner">
            <Form
              name="financial-administration"
              formControls={this.formControls}
              form={this.bindFormRef}
              onSubmit={this.onSubmitHandler}
            >
              <div className="form__row">
                <label htmlFor="name">
                  <I18n value="name" />
                </label>
                <Input.Text name="name" maxLength={128} />
              </div>

              <h2>
                <I18n value="invoiceNumbering" />
              </h2>

              <div styleName="invoice-numbers">
                <div styleName="row">
                  <div styleName="label" />
                  <div styleName="invoice">
                    <h3>
                      <I18n value="invoice" />:
                    </h3>
                  </div>
                  <div styleName="debtor">
                    <h3>
                      <I18n value="debtor" />:
                    </h3>
                  </div>
                </div>

                {/* Prefixes */}
                <div styleName="row">
                  <div styleName="label">
                    <label htmlFor="invoiceNumberFormatPre">
                      <I18n value="prefix" />
                    </label>
                  </div>
                  <div styleName="invoice">
                    <Input.Text name="invoiceNumberFormatPre" maxLength={64} />
                  </div>
                  <div styleName="debtor">
                    <Input.Text name="debtorNumberFormatPre" maxLength={64} />
                  </div>
                </div>

                {/* Next number */}
                <div styleName="row">
                  <div styleName="label">
                    <label htmlFor="nextInvoiceNumber">
                      <I18n value="nextNumber" />
                    </label>
                  </div>
                  <div styleName="invoice">
                    <Input.Text name="nextInvoiceNumber" maxLength={64} />
                  </div>
                  <div styleName="debtor">
                    <Input.Text name="nextDebtorNumber" maxLength={64} />
                  </div>
                </div>

                {/* Postfixes */}
                <div styleName="row">
                  <div styleName="label">
                    <label htmlFor="invoiceNumberFormatPost">
                      <I18n value="postfix" />
                    </label>
                  </div>
                  <div styleName="invoice">
                    <Input.Text name="invoiceNumberFormatPost" maxLength={64} />
                  </div>
                  <div styleName="debtor">
                    <Input.Text name="debtorNumberFormatPost" maxLength={64} />
                  </div>
                </div>
              </div>

              <h2>
                <I18n value="letterHeads" />
              </h2>

              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label>
                      <I18n value="backGroundImageFirstPage" />
                    </label>
                    <div styleName="letter-head">
                      <div styleName="inner">
                        <Input.Avatar
                          name="backGroundImageFirstPage"
                          largePreview
                        >
                          <AvatarUploadTriggerComponent
                            icon="fa fa-cloud-upload"
                            customText="backGroundImageFirstPageUploadText"
                          />
                        </Input.Avatar>
                      </div>
                    </div>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label>
                      <I18n value="backGroundImageFollowingPages" />
                    </label>
                    <div styleName="letter-head">
                      <div styleName="inner">
                        <Input.Avatar
                          name="backGroundImageFollowingPages"
                          largePreview
                        >
                          <AvatarUploadTriggerComponent
                            icon="fa fa-cloud-upload"
                            customText="backGroundImageFollowingPagesUploadText"
                          />
                        </Input.Avatar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__row">
                <Input.Switch
                  name="useBackGroundImagesByDefault"
                  label="useBackGroundImagesByDefault"
                  labelPosition={SwitchLabelPosition.Pre}
                  on={true}
                  off={false}
                />
              </div>

              <div className="form__row">
                <div className="form__group stretch">
                  <div className="column">
                    <label htmlFor="defaultConsumerInvoiceTemplate">
                      <ResourceText resourceKey="defaultConsumerInvoiceTemplate" />
                    </label>
                    <Input.NewSelect
                      name="defaultConsumerInvoiceTemplate"
                      addEmptyOption
                      emptyOptionLabel="makeAChoiceExtended"
                      values={this.state.printTemplates}
                      displayProp="displayName"
                      valuesProp="key"
                    />
                  </div>
                  <div className="column__spacer"></div>
                  <div className="column">
                    <label htmlFor="defaultBusinessInvoiceTemplate">
                      <ResourceText resourceKey="defaultBusinessInvoiceTemplate" />
                    </label>
                    <Input.NewSelect
                      name="defaultBusinessInvoiceTemplate"
                      addEmptyOption
                      emptyOptionLabel="makeAChoiceExtended"
                      values={this.state.b2bPrintTemplates}
                      displayProp="displayName"
                      valuesProp="key"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  private onSubmitClickHandler() {
    if (!this.formRef) return;
    this.formRef.submit();
  }

  private onSubmitHandler(values: FormReturnValue) {
    const financialAdministration: FinancialAdministration = {
      ...this.state.financialAdministration,
      ...values,
    };

    this.props.updateFinancialAdministration(financialAdministration);
  }

  private mapFormControls(
    financialAdministration: FinancialAdministration,
    flat: boolean = false
  ): FormControls {
    return {
      name: flat
        ? value(financialAdministration, "name")
        : {
            value: value(financialAdministration, "name"),
            validators: [Validators.required()],
          },
      invoiceNumberFormatPre: flat
        ? value(financialAdministration, "invoiceNumberFormatPre")
        : { value: value(financialAdministration, "invoiceNumberFormatPre") },
      nextInvoiceNumber: flat
        ? value(financialAdministration, "nextInvoiceNumber")
        : { value: value(financialAdministration, "nextInvoiceNumber") },
      invoiceNumberFormatPost: flat
        ? value(financialAdministration, "invoiceNumberFormatPost")
        : { value: value(financialAdministration, "invoiceNumberFormatPost") },
      debtorNumberFormatPre: flat
        ? value(financialAdministration, "debtorNumberFormatPre")
        : { value: value(financialAdministration, "debtorNumberFormatPre") },
      nextDebtorNumber: flat
        ? value(financialAdministration, "nextDebtorNumber")
        : { value: value(financialAdministration, "nextDebtorNumber") },
      debtorNumberFormatPost: flat
        ? value(financialAdministration, "debtorNumberFormatPost")
        : { value: value(financialAdministration, "debtorNumberFormatPost") },
      backGroundImageFirstPage: flat
        ? value(financialAdministration, "backGroundImageFirstPage")
        : { value: value(financialAdministration, "backGroundImageFirstPage") },
      backGroundImageFollowingPages: flat
        ? value(financialAdministration, "backGroundImageFollowingPages")
        : {
            value: value(
              financialAdministration,
              "backGroundImageFollowingPages"
            ),
          },
      useBackGroundImagesByDefault: flat
        ? value(financialAdministration, "useBackGroundImagesByDefault")
        : {
            value: value(
              financialAdministration,
              "useBackGroundImagesByDefault"
            ),
          },
      defaultBusinessInvoiceTemplate: flat
        ? value(financialAdministration, "defaultBusinessInvoiceTemplate")
        : {
            value: value(
              financialAdministration,
              "defaultBusinessInvoiceTemplate"
            ),
          },
      defaultConsumerInvoiceTemplate: flat
        ? value(financialAdministration, "defaultConsumerInvoiceTemplate")
        : {
            value: value(
              financialAdministration,
              "defaultConsumerInvoiceTemplate"
            ),
          },
    };
  }

  private async fetchTemplates() {
    const printTemplates = await this.props.getPrintTemplates(false);
    const b2bPrintTemplates = await this.props.getPrintTemplates(true);

    this.setState({
      printTemplates,
      b2bPrintTemplates,
    });
  }

  private bindFormRef(ref: FormReference) {
    if (!!ref && !this.formRef) this.formRef = ref;
  }
}
