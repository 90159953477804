import { REDUX } from "@haywork/constants";

import * as ActionType from "./settings.types";

const setRedirectUri = (redirectUri: string | null) => ({
  type: REDUX.EMAILV2.SET_REDIRECT_URI,
  redirectUri
});

const setCreateStatus = (createStatus: string) => ({
  type: REDUX.EMAILV2.SET_CREATE_STATUS,
  createStatus
});

const setSettings = (payload: ActionType.Settings) => ({
  type: REDUX.EMAILV2.SET_SETTINGS,
  ...payload
});

const setAccountAddedVisibility = (accountAddedVisibility: boolean) => ({
  type: REDUX.EMAILV2.SET_ACCOUNT_ADDED_VISIBILITY,
  accountAddedVisibility
});

export const SettingsActions = {
  setRedirectUri,
  setCreateStatus,
  setSettings,
  setAccountAddedVisibility
};
