import { Phrase, PhraseSnapShot } from "@haywork/api/kolibri";
import { FormReturnValue } from "@haywork/modules/form";
import {
  UiPhrasesContainerProps,
  UiPhrasesModalContainer
} from "@haywork/modules/ui/phrases";
import { ModalPortal } from "@haywork/portals";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { EditModalComponent } from "./edit-modal.component";

const styles = require("./phrases.component.scss");

export interface UiPhrasesComponentProps {
  phraseCategoryId: number;
  onPhraseAdd: (phrase: string) => void;
  onPhraseClick?: () => void;
}
interface State {
  modalVisble: boolean;
  editModalVisible: boolean;
}
type Props = UiPhrasesComponentProps & UiPhrasesContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class UiPhrasesComponent extends React.Component<Props, State> {
  private ref: HTMLDivElement;

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onModalCloseHandler = this.onModalCloseHandler.bind(this);
    this.onModalPhraseAddHandler = this.onModalPhraseAddHandler.bind(this);
    this.onModalPhraseEditHandler = this.onModalPhraseEditHandler.bind(this);
    this.onModalAddClickHandler = this.onModalAddClickHandler.bind(this);
    this.onEditModalCloseHandler = this.onEditModalCloseHandler.bind(this);
    this.onUpdatedPhraseHandler = this.onUpdatedPhraseHandler.bind(this);
    this.onAddPhraseHandler = this.onAddPhraseHandler.bind(this);

    this.state = {
      modalVisble: false,
      editModalVisible: false
    };
  }

  public render() {
    return (
      <div styleName="wrapper" ref={(ref) => (this.ref = ref)}>
        <div styleName="trigger" onClick={this.onClickHandler}>
          <span className="fal fa-comment-alt" />
        </div>
        {this.state.modalVisble && (
          <ModalPortal>
            <UiPhrasesModalContainer
              onClose={this.onModalCloseHandler}
              onPhraseAdd={this.onModalPhraseAddHandler}
              onPhraseEdit={this.onModalPhraseEditHandler}
              onAddClick={this.onModalAddClickHandler}
              parent={this.ref}
            />
          </ModalPortal>
        )}
        <EditModalComponent
          visible={this.state.editModalVisible}
          onClose={this.onEditModalCloseHandler}
          onUpdatedPhrase={this.onUpdatedPhraseHandler}
          onAddPhrase={this.onAddPhraseHandler}
          phrase={this.props.phrase}
          phraseStatus={this.props.phraseStatus}
        />
      </div>
    );
  }

  private onClickHandler() {
    this.props.getPhrases(this.props.phraseCategoryId);
    this.setState({ modalVisble: true });
    if (this.props.onPhraseClick) this.props.onPhraseClick();
  }

  private onModalCloseHandler() {
    this.setState({ modalVisble: false });
    this.props.clearPhrases();
  }

  private onModalPhraseAddHandler(phrase: PhraseSnapShot) {
    this.setState({ modalVisble: false });
    this.props.onPhraseAdd(phrase.description);
  }

  private onModalPhraseEditHandler(phrase: PhraseSnapShot) {
    this.setState({ editModalVisible: true, modalVisble: false });
    this.props.getPhrase(phrase.id);
  }

  private onModalAddClickHandler() {
    this.setState({ editModalVisible: true, modalVisble: false });
  }

  private onEditModalCloseHandler() {
    this.setState({ editModalVisible: false, modalVisble: true });
    this.props.unsetPhrase();
  }

  private onUpdatedPhraseHandler(phrase: Phrase) {
    this.props.updatePhrase(phrase);
  }

  private onAddPhraseHandler(values: FormReturnValue) {
    this.props.savePhrase(
      values.title,
      values.description,
      this.props.phraseCategoryId
    );
  }
}
