import { SingleFilterValue } from "@haywork/components/ui/list";
import { FilterGuidValues } from "@haywork/components/ui/list/components/list-filter";
import { AppState } from "@haywork/stores";
import { createSelector } from "reselect";

const masterTableSelector = (state: AppState) => state.main.mastertable;
const officesSelector = (state: AppState) => state.offices.offices;
const employeesSelector = (state: AppState) => state.employee.employees;

export const matchmailPeriodsValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { matchMailPeriodOptions } = mastertable.kolibri;
    return (matchMailPeriodOptions || []).map(
      (matchMailPeriodOption) =>
        ({
          label: matchMailPeriodOption.displayName,
          value: matchMailPeriodOption.value,
        } as SingleFilterValue)
    );
  }
);

export const partTypesValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { typePARTOptions } = mastertable.kolibri;
    return (typePARTOptions || []).map(
      (typePARTOption) =>
        ({
          label: typePARTOption.displayName,
          value: typePARTOption.value,
        } as SingleFilterValue)
    );
  }
);

export const bogTypesValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { typeBOGOptions } = mastertable.kolibri;
    return (typeBOGOptions || []).map(
      (typeBOGOption) =>
        ({
          label: typeBOGOption.displayName,
          value: typeBOGOption.value,
        } as SingleFilterValue)
    );
  }
);

export const alvTypesValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { typeALVOptions } = mastertable.kolibri;
    return (typeALVOptions || []).map(
      (typeALVOption) =>
        ({
          label: typeALVOption.displayName,
          value: typeALVOption.value,
        } as SingleFilterValue)
    );
  }
);

export const listingTypeValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { listingTypes } = mastertable.kolibri;
    return (listingTypes || []).map(
      (listingType) =>
        ({
          label: listingType.displayName,
          value: listingType.value,
        } as SingleFilterValue)
    );
  }
);

export const assignmentPhaseValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { assignmentPhases } = mastertable.kolibri;
    return (assignmentPhases || []).map(
      (assignmentPhase) =>
        ({
          label: assignmentPhase.displayName,
          value: assignmentPhase.value,
        } as SingleFilterValue)
    );
  }
);

export const availabilityStatusValues = createSelector(
  masterTableSelector,
  (mastertable) => {
    const { availabilityStatuses } = mastertable.kolibri;
    return (availabilityStatuses || []).map(
      (availabilityStatus) =>
        ({
          label: availabilityStatus.displayName,
          value: availabilityStatus.value,
        } as SingleFilterValue)
    );
  }
);

export const activeFilterGuidValues = createSelector(
  employeesSelector,
  officesSelector,
  (employees, offices) => {
    return {
      employees: (employees || []).map((employee) => ({
        value: employee.displayName,
        id: employee.id,
      })),
      offices: (offices || []).map((office) => ({
        value: office.displayName,
        id: office.id,
      })),
    } as FilterGuidValues;
  }
);
