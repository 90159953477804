import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDropTarget,
  DropTargetCollector,
  DropTarget,
  DropTargetSpec,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import classNames from "classnames";

const styles = require("./styles.scss");

type Props = {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  onDrop: (file: File) => void;
};

const DropComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ connectDropTarget, isOver }) => {
      if (!connectDropTarget) return null;

      return connectDropTarget(
        <div styleName={classNames("drop", { hovering: !!isOver })} />
      );
    }
  )
);

const dropSpec: DropTargetSpec<Props> = {
  drop: (props, monitor) => {
    const { files } = monitor.getItem() as { files: File[] };
    props.onDrop(files[0]);
  },
};

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default DropTarget(
  [NativeTypes.FILE],
  dropSpec,
  dropCollect
)(DropComponent);
