import { GenderType } from "@haywork/api/event-center";
import {
  Address,
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressTypeOption,
  PhoneNumberTypeOption,
  RelationGroupSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import { FormReference, FormReturnValue } from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { CompanyFormComponent } from "./company-form.component";
import { PersonFormComponent } from "./person-form.component";

const styles = require("../new-entity.component.scss");

export interface RelationValues {
  name?: string;
  email?: string;
  telephone?: string;
  mobilePhone?: string;
  gender?: GenderType;
  webAddress?: string;
  singleFormType?: RelationType;
  address?: Address;
}
interface Props extends RelationValues {
  loading: boolean;
  titleSuggestions: ContactPersonTitleSuggestionOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  phoneNumberTypes: PhoneNumberTypeOption[];
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
  relationGroups: RelationGroupSnapShot[];
  onSubmit: (type: RelationType, values: FormReturnValue) => void;
}
interface State {
  relationType: RelationType;
}

@CSSModules(styles, { allowMultiple: true })
export class RelationComponent extends React.Component<Props, State> {
  private personFormRef: FormReference;
  private companyFormRef: FormReference;

  constructor(props) {
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this);

    this.state = {
      relationType: this.props.singleFormType || RelationType.ContactPerson,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (this.props.singleFormType !== nextProps.singleFormType) {
      this.setState({
        relationType: nextProps.singleFormType,
      });
    }
  }

  public render() {
    const { relationType } = this.state;

    return (
      <React.Fragment>
        <div styleName="body__inner">
          {this.props.loading && <Ui.Loaders.Fullscreen mask />}
          {!this.props.singleFormType && (
            <div styleName="switch-relation-type">
              <div
                styleName={classNames("trigger", {
                  active: relationType === RelationType.ContactPerson,
                })}
                onClick={() =>
                  this.setState({ relationType: RelationType.ContactPerson })
                }
              >
                <ResourceText resourceKey="newQuickRelationContactPersonTrigger" />
              </div>
              <div
                styleName={classNames("trigger", {
                  active: relationType === RelationType.ContactCompany,
                })}
                onClick={() =>
                  this.setState({ relationType: RelationType.ContactCompany })
                }
              >
                <ResourceText resourceKey="newQuickRelationContactCompanyTrigger" />
              </div>
            </div>
          )}
          {relationType === RelationType.ContactPerson ? (
            <PersonFormComponent
              name={this.props.name}
              email={this.props.email}
              telephone={this.props.telephone}
              mobilePhone={this.props.mobilePhone}
              address={this.props.address}
              titleSuggestions={this.props.titleSuggestions}
              emailAddressTypes={this.props.emailAddressTypes}
              phoneNumberTypes={this.props.phoneNumberTypes}
              form={(form) => (this.personFormRef = form)}
              onSubmit={this.onSubmitHandler}
              countries={this.props.countries}
              countryIso2={this.props.countryIso2}
              culture={this.props.culture}
              relationGroups={this.props.relationGroups}
            />
          ) : (
            <CompanyFormComponent
              name={this.props.name}
              email={this.props.email}
              telephone={this.props.telephone}
              mobilePhone={this.props.mobilePhone}
              emailAddressTypes={this.props.emailAddressTypes}
              webAddress={this.props.webAddress}
              address={this.props.address}
              phoneNumberTypes={this.props.phoneNumberTypes}
              form={(form) => (this.companyFormRef = form)}
              onSubmit={this.onSubmitHandler}
              countries={this.props.countries}
              countryIso2={this.props.countryIso2}
              culture={this.props.culture}
              relationGroups={this.props.relationGroups}
            />
          )}
        </div>
        <div styleName="body__footer">
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.props.loading}
            onClick={this.onSubmitClickHandler}
            data-cy="CY-saveQuickAddRelationButton"
          >
            <ResourceText resourceKey="save" />
          </button>
        </div>
      </React.Fragment>
    );
  }

  private onSubmitClickHandler() {
    switch (this.state.relationType) {
      case RelationType.ContactPerson:
        return this.personFormRef.submit();
      case RelationType.ContactCompany:
        return this.companyFormRef.submit();
      default:
        return;
    }
  }

  private onSubmitHandler(values: FormReturnValue) {
    this.props.onSubmit(this.state.relationType, values);
  }
}
