import { REQUEST } from "@haywork/constants";
import {
  DynamicDocumentContainerProps,
  DynamicDocumentsPreviewContainer,
} from "@haywork/modules/dynamic-documents";
import { PageLoader } from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DynamicDocumentsDocumentRouting } from "./document.routing";

const styles = require("./document.component.scss");

export interface DynamicDocumentComponentProps {}
interface DynamicDocumentComponentState {
  style: React.CSSProperties;
  resizing: boolean;
  scrollable: HTMLDivElement;
}

@CSSModules(styles, { allowMultiple: true })
export class DynamicDocumentComponent extends React.Component<
  DynamicDocumentComponentProps & DynamicDocumentContainerProps,
  DynamicDocumentComponentState
> {
  private resizeStartX: number;
  private formSection: HTMLDivElement;
  private formSectionWidth: number;

  constructor(props) {
    super(props);

    this.onMouseMoveHandler = this.onMouseMoveHandler.bind(this);
    this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.bindFormSectionRef = this.bindFormSectionRef.bind(this);
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
    this.bindScrollableRef = this.bindScrollableRef.bind(this);

    this.state = {
      style: { width: "50%" },
      resizing: false,
      scrollable: null,
    };

    this.props.getPreview();
  }

  public render() {
    const dividerStyle = classNames("document__divider", {
      resizing: this.state.resizing,
    });
    const { needsListOfGoods } = this.props.template;

    return (
      <div
        styleName="document"
        onMouseMove={this.onMouseMoveHandler}
        onMouseUp={this.onMouseUpHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        <div
          styleName="document__form"
          style={this.state.style}
          ref={this.bindFormSectionRef}
        >
          <div styleName="form__routing">
            <DynamicDocumentsDocumentRouting id={this.props.session.id} />
          </div>
        </div>
        <div styleName={dividerStyle}>
          <div styleName="handle" onMouseDown={this.onMouseDownHandler} />
        </div>

        <div styleName="document__preview">
          <div styleName="preview" ref={this.bindScrollableRef}>
            {this.props.previewStatus === REQUEST.PENDING && (
              <PageLoader fullscreen loading />
            )}
            <DynamicDocumentsPreviewContainer
              scrollable={this.state.scrollable}
              onMergefieldClick={this.props.focusOnUserField}
            />
          </div>
          {this.props.previewRefreshStatus === REQUEST.PENDING && (
            <div styleName="loader">
              <div styleName="indeterminate" />
            </div>
          )}
        </div>
      </div>
    );
  }

  private onMouseDownHandler(event: React.MouseEvent<HTMLDivElement>) {
    this.resizeStartX = event.clientX;
    const width = (this.formSectionWidth = this.formSection.clientWidth);
    this.setState({ style: { width } });
  }

  private onMouseMoveHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (!this.resizeStartX) return;
    event.preventDefault();
    const moved = event.clientX - this.resizeStartX;
    let width = this.formSectionWidth + moved;
    if (width < 320) width = 320;
    this.setState({ style: { width }, resizing: true });
  }

  private onMouseUpHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (!this.resizeStartX) return;
    event.preventDefault();
    const moved = event.clientX - this.resizeStartX;
    const width = this.formSectionWidth + moved;
    this.resizeStartX = undefined;
    this.setState({ style: { width }, resizing: false });
  }

  private onMouseLeaveHandler(event: React.MouseEvent<HTMLDivElement>) {
    if (!this.resizeStartX) return;
    event.preventDefault();
    this.resizeStartX = undefined;
    this.setState({ resizing: false });
  }

  private bindFormSectionRef(ref: HTMLDivElement) {
    if (!!ref && !this.formSection) this.formSection = ref;
  }

  private bindScrollableRef(scrollable: HTMLDivElement) {
    if (!!scrollable && !this.state.scrollable) {
      this.setState({ scrollable });
    }
  }
}
