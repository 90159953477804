import { Draft, FolderCategory } from "@haywork/api/mail";
import { REQUEST } from "@haywork/constants";
import { EmailListContainerProps } from "@haywork/modules/email";
import { EmailMessage } from "@haywork/stores/email-v2";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { DraftsComponent } from "./drafts.component";
import { EmptyState } from "./empty-state.component";
import { EmailListHeaderComponent } from "./header.component";
import { MessagesComponent } from "./messages.component";

const styles = require("./list.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface EmailListComponentProps {
  onDeleteMessage: () => void;
}
interface State {}
type Props = EmailListComponentProps & EmailListContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailListComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onDraftClickHandler = this.onDraftClickHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onMessageClickHandler = this.onMessageClickHandler.bind(this);
    this.onMessageToggleBookmarkedHandler = this.onMessageToggleBookmarkedHandler.bind(
      this
    );
    this.onMessageToggleUnreadHandler = this.onMessageToggleUnreadHandler.bind(
      this
    );
  }

  public render() {
    if (
      this.props.accounts.status === REQUEST.SUCCESS &&
      this.props.accounts.total === 0
    ) {
      return <EmptyState />;
    }

    return (
      <div styleName="list">
        <div styleName="list__header">
          <EmailListHeaderComponent
            loading={
              value(this.props.currentFolder, "canLoadMoreMessagesStatus") ===
              REQUEST.PENDING
            }
            currentFolder={this.props.currentFolder}
            unread={this.props.unread}
            term={this.props.term}
            onSetSearchValues={this.props.searchMessages}
            onToggleUnread={this.props.filterMessages}
          />
        </div>
        <div styleName="list__body">
          {!!this.props.currentFolder &&
          this.props.currentFolder.category === FolderCategory.Drafts ? (
            <DraftsComponent
              currentDraft={this.props.currentDraft}
              drafts={this.props.drafts}
              currentFolder={this.props.currentFolder}
              onDraftClick={this.onDraftClickHandler}
              onScrollEnd={this.onScrollEndHandler}
            />
          ) : (
            <MessagesComponent
              currentMessage={this.props.currentMessage}
              messages={this.props.messages}
              currentFolder={this.props.currentFolder}
              currentFolderIsTrash={this.props.currentFolderIsTrash}
              providerType={get(this.props.currentAccount, "type")}
              onScrollEnd={this.onScrollEndHandler}
              onMessageClick={this.onMessageClickHandler}
              onMessageToggleBookmarked={this.onMessageToggleBookmarkedHandler}
              onMessageToggleUnread={this.onMessageToggleUnreadHandler}
              onMessageDelete={(message: EmailMessage) =>
                this.props.deleteMessage(this.props.currentAccount.id, message)
              }
            />
          )}
        </div>
      </div>
    );
  }

  private onScrollEndHandler() {
    if (
      this.props.currentFolder.canLoadMoreMessagesStatus === REQUEST.PENDING ||
      !this.props.currentFolder.canLoadMoreMessages ||
      !this.props.messages.length
    ) {
      return;
    }

    switch (this.props.currentFolder.category) {
      case FolderCategory.Drafts:
        return this.props.getDrafts();
      default:
        return this.props.getMessages();
    }
  }

  private onMessageClickHandler(message: EmailMessage) {
    this.props.readMessage(
      this.props.currentAccount,
      this.props.currentFolder,
      message,
      message.unread
    );
  }

  private onDraftClickHandler(draft: Draft) {
    this.props.readDraft(draft.id);
  }

  private onMessageToggleUnreadHandler(message: EmailMessage) {
    this.props.toggleUnread(this.props.currentAccount.id, message);
  }

  private onMessageToggleBookmarkedHandler(id: string, bookmarked: boolean) {
    this.props.toggleBookmarked(this.props.currentAccount.id, id, bookmarked);
  }
}
