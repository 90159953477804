import {
  LeisureFacilityOption,
  LeisureType,
  ObjectAssignment,
  AcquisitionObjectAssignment
} from "@haywork/api/kolibri";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition
} from "@haywork/modules/form";
import { ResourceText } from "@haywork/modules/shared";
import { FormControlUtil } from "@haywork/util";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./bog.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  objectAssignment: ObjectAssignment | AcquisitionObjectAssignment;
  leisureFacilityTypeOptions: LeisureFacilityOption[];
  asSubForm?: boolean;
  onChange: (values: FormReturnValue) => void;
}

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditBogLeisureComponent extends React.Component<Props> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { objectAssignment } = this.props;

    this.formControls = {
      countOfPitches: { value: value(objectAssignment, "countOfPitches") },
      countOfAccommodations: {
        value: value(objectAssignment, "countOfAccommodations")
      },
      capacityInNumberOfPersons: {
        value: value(objectAssignment, "capacityInNumberOfPersons")
      },
      hasAnnualPitches: {
        value: value(objectAssignment, "hasAnnualPitches", false)
      },
      leisureFacilityTypes: {
        value: value(objectAssignment, "leisureFacilityTypes", [])
      },
      parcelSurface: { value: value(objectAssignment, "parcelSurface.area") },
      numberOfFloors: { value: value(objectAssignment, "numberOfFloors", 0) }
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      const { objectAssignment } = this.props;

      this.formRef.update({
        countOfPitches: value(objectAssignment, "countOfPitches"),
        countOfAccommodations: value(objectAssignment, "countOfAccommodations"),
        capacityInNumberOfPersons: value(
          objectAssignment,
          "capacityInNumberOfPersons"
        ),
        hasAnnualPitches: value(objectAssignment, "hasAnnualPitches", false),
        leisureFacilityTypes: value(
          objectAssignment,
          "leisureFacilityTypes",
          []
        ),
        parcelSurface: value(objectAssignment, "parcelSurface.area"),
        numberOfFloors: value(objectAssignment, "numberOfFloors", 0)
      }, true);
    }
  }

  public render() {
    const { leisureType } = this.props.objectAssignment;
    const { leisureFacilityTypeOptions } = this.props;

    return (
      <Form
        name="leisure"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        form={(form) => (this.formRef = form)}
        asSubForm={this.props.asSubForm}
      >
        <div className="form__row">
          <div className="form__group">
            {/* Pitches count */}
            {leisureType === LeisureType.Camping && (
              <div className="column__join">
                <div className="column" styleName="size-field">
                  <label htmlFor="countOfPitches">
                    <ResourceText resourceKey="countOfPitches" />
                  </label>
                  <Input.Number name="countOfPitches" />
                </div>
                <div className="column__spacer" />
              </div>
            )}

            {/* Accommodations count */}
            {(leisureType === LeisureType.BungalowPark ||
              leisureType === LeisureType.Other) && (
              <div className="column__join">
                <div className="column" styleName="size-field">
                  <label htmlFor="countOfAccommodations">
                    <ResourceText resourceKey="countOfAccommodations" />
                  </label>
                  <Input.Number name="countOfAccommodations" />
                </div>
                <div className="column__spacer" />
              </div>
            )}

            {/* Person capacity */}
            {(leisureType === LeisureType.DayRecriation ||
              leisureType === LeisureType.Wellness ||
              leisureType === LeisureType.Other) && (
              <div className="column__join">
                <div className="column" styleName="size-field">
                  <label htmlFor="capacityInNumberOfPersons">
                    <ResourceText resourceKey="capacityInNumberOfPersons" />
                  </label>
                  <Input.Number name="capacityInNumberOfPersons" />
                </div>
                <div className="column__spacer" />
              </div>
            )}

            {/* Annual pitches */}
            {leisureType === LeisureType.Camping && (
              <div className="column push-label">
                <Input.Switch
                  name="hasAnnualPitches"
                  on={true}
                  off={false}
                  label="hasAnnualPitches"
                  labelPosition={SwitchLabelPosition.Pre}
                />
              </div>
            )}
          </div>
        </div>

        <div className="form__row">
          <div className="form__group">
            <div className="column" styleName="size-field">
              <label htmlFor="numberOfFloors">
                <ResourceText resourceKey="numberOfFloorsBog" />
              </label>
              <Input.Number name="numberOfFloors" />
            </div>
            <div className="column__spacer" />
            <div className="column" styleName="size-field">
              <label htmlFor="parcelSurface">
                <ResourceText resourceKey="parcelSurface" />
              </label>
              <div className="input__helper">
                <Input.Number name="parcelSurface" pretty />
                <div className="post">
                  <ResourceText resourceKey="squareMeters" asHtml />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <label htmlFor="leisureFacilityTypes">
            <ResourceText resourceKey="leisureFacilityTypes" />
          </label>
          <Input.Multi
            name="leisureFacilityTypes"
            values={leisureFacilityTypeOptions}
          />
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { objectAssignment } = this.props;
    let fields: any = {
      parcelSurface: {
        ...objectAssignment.parcelSurface,
        area: values.parcelSurface
      },
      leisureFacilityTypes: values.leisureFacilityTypes
    };

    if (objectAssignment.leisureType === LeisureType.Camping) {
      fields = {
        ...fields,
        countOfPitches: values.countOfPitches,
        hasAnnualPitches: values.hasAnnualPitches
      };
    }

    if (
      objectAssignment.leisureType === LeisureType.BungalowPark ||
      objectAssignment.leisureType === LeisureType.Other
    ) {
      fields = {
        ...fields,
        countOfAccommodations: values.countOfAccommodations
      };
    }

    if (
      objectAssignment.leisureType === LeisureType.DayRecriation ||
      objectAssignment.leisureType === LeisureType.Wellness ||
      objectAssignment.leisureType === LeisureType.Other
    ) {
      fields = {
        ...fields,
        capacityInNumberOfPersons: values.capacityInNumberOfPersons
      };
    }

    this.props.onChange(fields);
  }
}
