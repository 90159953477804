import * as ActionType from "@haywork/stores/project/single/single.types";
import { REDUX } from "@haywork/constants";

const setProject = (payload: ActionType.SingleProject) => ({
  type: REDUX.PROJECT.SET_PROJECT,
  ...payload
});

const updateSingleProject = (payload: ActionType.SingleProject) => ({
  type: REDUX.PROJECT.UPDATE_SINGLE_PROJECT,
  ...payload
});

const setProjectStatus = (objectAssignmentState: string) => ({
  type: REDUX.PROJECT.SET_PROJECT_STATUS,
  objectAssignmentState
});

const updateProject = (payload: ActionType.SingleProjectAssignment) => ({
  type: REDUX.PROJECT.UPDATE_PROJECT,
  ...payload
});

const setSaveProjectStatus = (saveAssignmentState: string) => ({
  type: REDUX.PROJECT.SET_SAVE_PROJECTS_STATUS,
  saveAssignmentState
});

const setPublishStatus = (payload: ActionType.PublishState) => ({
  type: REDUX.PROJECT.SET_PUBLISH_STATUS,
  ...payload
});

const toggleStatusModal = (showStatusModal: boolean) => ({
  type: REDUX.PROJECT.TOGGLE_STATUS_MODAL,
  showStatusModal
});

const toggleWithdrawModal = (showWithdrawModal: boolean) => ({
  type: REDUX.PROJECT.TOGGLE_WITHDRAW_MODAL,
  showWithdrawModal
});

const toggleSaveModal = (showSaveModal: boolean) => ({
  type: REDUX.PROJECT.TOGGLE_SAVE_MODAL,
  showSaveModal
});

const setTimelineEventsStatus = (timelineStatus: string) => ({
  type: REDUX.PROJECT.SET_TIMELINE_STATUS,
  timelineStatus
});

const setTimelineEvents = (payload: ActionType.Events) => ({
  type: REDUX.PROJECT.SET_TIMELINE_EVENTS,
  ...payload
});

const appendTimelineEvents = (payload: ActionType.Events) => ({
  type: REDUX.PROJECT.APPEND_TIMELINE_EVENTS,
  ...payload
});

const setMediaPartnerState = (payload: ActionType.MediaPartnerState) => ({
  type: REDUX.PROJECT.SET_MEDIAPARTNER_STATE,
  ...payload
});

const setChangeAvailabilityStatus = (payload: ActionType.ChangeAvailabilityStatus) => ({
  type: REDUX.PROJECT.SET_CHANGE_AVAILABILITY_STATUS,
  ...payload
});

const setBuildNumbers = (payload: ActionType.Buildnumbers) => ({
  type: REDUX.PROJECT.SET_BUILDNUMBERS,
  ...payload
});

const addBuildNumbers = (payload: ActionType.Buildnumbers) => ({
  type: REDUX.PROJECT.ADD_BUILDNUMBERS,
  ...payload
});

const setBuildnumbersStatus = (buildnumbersStatus: string) => ({
  type: REDUX.PROJECT.SET_BUILDNUMBERS_STATUS,
  buildnumbersStatus
});

const setObjectTypesStatus = (objectTypesStatus: string) => ({
  type: REDUX.PROJECT.SET_OBJECTTYPES_STATUS,
  objectTypesStatus
});

const setObjectTypes = (payload: ActionType.ObjectTypes) => ({
  type: REDUX.PROJECT.SET_OBJECTTYPES,
  ...payload
});

const addObjectTypes = (payload: ActionType.ObjectTypes) => ({
  type: REDUX.PROJECT.ADD_OBJECTTYPES,
  ...payload
});

export const ProjectSingleActions = {
  setProject,
  updateSingleProject,
  setProjectStatus,
  updateProject,
  setPublishStatus,
  toggleStatusModal,
  toggleWithdrawModal,
  toggleSaveModal,
  setSaveProjectStatus,
  setTimelineEventsStatus,
  setTimelineEvents,
  appendTimelineEvents,
  setMediaPartnerState,
  setChangeAvailabilityStatus,
  setBuildNumbers,
  addBuildNumbers,
  setBuildnumbersStatus,
  setObjectTypesStatus,
  setObjectTypes,
  addObjectTypes
};
