import {
  ContactCompany,
  CountryOption,
  EmailAddressTypeOption,
  PhoneNumberTypeOption,
  SocialMediaType
} from "@haywork/api/kolibri";
import { MailTo, ResourceText } from "@haywork/modules/shared";
import { ColorUtil } from "@haywork/util";
import has from "lodash-es/has";
import * as moment from "moment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate } from "react-intl";
import { PinnedNotes } from "@haywork/modules/notes-v3";

interface ContactCompanyReadonlyComponentProps {
  company: ContactCompany;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  countries: CountryOption[];
}
interface ContactCompanyReadonlyComponentState {}

const styles = require("./relation-readonly.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class ContactCompanyReadonlyComponent extends React.Component<
  ContactCompanyReadonlyComponentProps,
  ContactCompanyReadonlyComponentState
> {
  constructor(props) {
    super(props);

    this.hasAddressOrPostalOrFutureAddress = this.hasAddressOrPostalOrFutureAddress.bind(
      this
    );
    this.hasAddress = this.hasAddress.bind(this);
    this.hasPostalAddress = this.hasPostalAddress.bind(this);
    this.hasFutureAddress = this.hasFutureAddress.bind(this);
  }

  public render() {
    const country = has(this.props.company, "address.countryIso2")
      ? this.props.countries.find(
          (country) =>
            country.iso2CodeValue === this.props.company.address.countryIso2
        ).displayName
      : "";

    const facebook = this.props.company.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Facebook
    );
    const linkedIn = this.props.company.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.LinkedIn
    );
    const youtube = this.props.company.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.YouTube
    );
    const twitter = this.props.company.socialMedia.find(
      (socialMedia) => socialMedia.type === SocialMediaType.Twitter
    );

    return (
      <div styleName="readMode">
        <div styleName="name">
          <h1 className={styles.title}>{this.props.company.displayName}</h1>
          <div styleName="relationPillz">
            {this.props.company &&
              this.props.company.linkedRelationGroups &&
              this.props.company.linkedRelationGroups.map(
                (linkedRelationGroup, idx) => {
                  const color = ColorUtil.hexToRgb(
                    linkedRelationGroup.backColor
                  );
                  const style = { borderColor: color, color };
                  return (
                    <div styleName="relationPill" key={idx} style={style}>
                      <div styleName="relationPill__label">
                        {linkedRelationGroup.displayName}
                      </div>
                    </div>
                  );
                }
              )}
          </div>

          <PinnedNotes />
        </div>
        {this.props.company.phoneNumbers.length > 0 && (
          <div styleName="itemContainer">
            {this.props.company.phoneNumbers.map((phoneNumber, index) => {
              const type = phoneNumber.type
                ? this.props.phoneNumberTypes.find(
                    (phoneNumberType) =>
                      phoneNumber.type === phoneNumberType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <a href={"tel:" + phoneNumber.number}>{phoneNumber.number}</a>
                </div>
              );
            })}
          </div>
        )}

        {this.props.company.emailAddresses.length > 0 && (
          <div styleName="itemContainer" className={styles.emailAddresses}>
            {this.props.company.emailAddresses.map((emailAddress, index) => {
              const type = emailAddress.type
                ? this.props.emailAddressTypes.find(
                    (emailAddressType) =>
                      emailAddress.type === emailAddressType.value
                  ).displayName
                : "";
              return (
                <div styleName="item" key={index}>
                  <span className={styles.label}>{type}</span>
                  <MailTo email={emailAddress.address} />
                </div>
              );
            })}
          </div>
        )}
        {this.hasAddressOrPostalOrFutureAddress() && (
          <div styleName={"itemContainer" + " " + "addresses"}>
            {this.hasAddress() && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <ResourceText resourceKey="visitAddress" />
                </span>
                <div>
                  {!!this.props.company.address.street && (
                    <span styleName="line1">
                      {this.props.company.address.street.name}{" "}
                      {this.props.company.address.houseNumber}{" "}
                      {this.props.company.address.houseNumberPostfix}
                    </span>
                  )}
                  {this.props.company.address.locality && (
                    <span styleName="line2">
                      {this.props.company.address.postalCode}{" "}
                      {this.props.company.address.locality.name}
                      {country && (
                        <span styleName="country">&nbsp; ({country})</span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}

            {this.hasPostalAddress() &&
              this.props.company.postalAddressDiffersVisitAddress && (
                <div styleName={"item" + " " + "address"}>
                  <span styleName="label">
                    <ResourceText resourceKey="postalAddress" />
                  </span>
                  <div>
                    {!!this.props.company.postalAddress.street && (
                      <span styleName="line1">
                        {this.props.company.postalAddress.street.name}{" "}
                        {this.props.company.postalAddress.houseNumber}{" "}
                        {this.props.company.postalAddress.houseNumberPostfix}
                      </span>
                    )}
                    {this.props.company.postalAddress.locality && (
                      <span styleName="line2">
                        {this.props.company.postalAddress.postalCode}{" "}
                        {this.props.company.postalAddress.locality.name}
                        {country && (
                          <span styleName="country">&nbsp; ({country})</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )}

            {this.hasFutureAddress() && (
              <div styleName={"item" + " " + "address"}>
                <span styleName="label">
                  <ResourceText
                    resourceKey="futureAddress.title"
                    values={{
                      date: moment(
                        this.props.company.futureAddressActivationDateTime
                      ).format("DD MMMM YYYY")
                    }}
                  />
                </span>
                <div>
                  {!!this.props.company.futureAddress.street && (
                    <span styleName="line1">
                      {this.props.company.futureAddress.street.name}{" "}
                      {this.props.company.futureAddress.houseNumber}{" "}
                      {this.props.company.futureAddress.houseNumberPostfix}
                    </span>
                  )}
                  {this.props.company.futureAddress.locality && (
                    <span styleName="line2">
                      {this.props.company.futureAddress.postalCode}{" "}
                      {this.props.company.futureAddress.locality.name}
                      {country && (
                        <span styleName="country">&nbsp; ({country})</span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* WebAddress */}
        {this.props.company.webAddress ? (
          <div styleName="itemContainer">
            <div styleName={"item"}>
              <span styleName="label">
                <ResourceText resourceKey="website" />
              </span>
              <a href={this.props.company.webAddress} target="_blank">
                {this.props.company.webAddress}
              </a>
            </div>
          </div>
        ) : (
          ""
        )}

        {(this.props.company.legalRegistration &&
          (this.props.company.legalRegistration.number ||
            this.props.company.legalRegistration.date ||
            this.props.company.legalRegistration.locality)) ||
        this.props.company.taxNumber ||
        (this.props.company.bankAccount &&
          this.props.company.bankAccount.number) ? (
          <div styleName="itemContainer companyDetails">
            {this.props.company.legalRegistration &&
            this.props.company.legalRegistration.number ? (
              <div styleName="item companyRegisteredItem">
                <span styleName="label">
                  <ResourceText resourceKey="kvkNumber" />
                </span>
                <div>
                  <span styleName="companyRegisteredNumber">
                    {this.props.company.legalRegistration.number}
                  </span>
                  {this.props.company.legalRegistration &&
                  this.props.company.legalRegistration.date ? (
                    <span styleName="noStyling">
                      <span>
                        <ResourceText resourceKey="registeredOn" />
                      </span>
                      <span styleName="registrationDate">
                        {this.props.company.legalRegistration &&
                        this.props.company.legalRegistration.date ? (
                          <FormattedDate
                            value={this.props.company.legalRegistration.date}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                  {this.props.company.legalRegistration &&
                  this.props.company.legalRegistration.locality ? (
                    <span styleName="noStyling">
                      <span>
                        <ResourceText resourceKey="te" />
                      </span>
                      <span styleName="registrationCity">
                        {this.props.company.legalRegistration.locality}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.company.taxNumber ? (
              <div styleName="item">
                <span styleName="label">
                  <ResourceText resourceKey="BTWNumber" />
                </span>
                <span>{this.props.company.taxNumber}</span>
              </div>
            ) : (
              ""
            )}
            {this.props.company.bankAccount &&
            this.props.company.bankAccount.number ? (
              <div styleName="item">
                <span styleName="label">
                  <ResourceText resourceKey="bankInformation" />
                </span>
                <span>{this.props.company.bankAccount.number}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {this.props.company.socialMedia.length > 0 && (
          <div styleName={"itemContainer" + " " + "socialMedia"}>
            <div className={styles.item}>
              <span className={styles.label}>
                <ResourceText resourceKey="socialMedia" />
              </span>
              {facebook && facebook.account && (
                <a
                  className={styles.facebook}
                  href={facebook.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-facebook-f" />
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseFacebook" />
                  </span>
                </a>
              )}
              {linkedIn && linkedIn.account && (
                <a
                  className={styles.linkedin}
                  href={linkedIn.account}
                  target="_blank"
                >
                  <i className="fab fa-fw fa-linkedin-in" />
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {youtube && youtube.account && (
                <a
                  className={styles.youtube}
                  href={youtube.account}
                  target="_blank"
                >
                  <i className="fab fa-youtube" />
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseYoutube" />
                  </span>
                </a>
              )}
              {twitter && twitter.account && (
                <a
                  className={styles.twitter}
                  href={twitter.account}
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                  <span className="sr-only">
                    <ResourceText resourceKey="lowercaseTwitter" />
                  </span>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private hasAddressOrPostalOrFutureAddress(): boolean {
    return (
      this.hasAddress() || this.hasPostalAddress() || this.hasFutureAddress()
    );
  }

  private hasAddress(): boolean {
    const { address } = this.props.company;
    if (!address) return false;
    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }

  private hasPostalAddress(): boolean {
    const { postalAddress: address } = this.props.company;
    if (!address) return false;
    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }

  private hasFutureAddress(): boolean {
    const {
      futureAddress: address,
      futureAddressActivationDateTime
    } = this.props.company;
    if (
      !address ||
      !futureAddressActivationDateTime ||
      moment().isAfter(moment(futureAddressActivationDateTime))
    )
      return false;

    if (
      (address.street && address.street.name) ||
      (address.locality && address.locality.name) ||
      (address.postalCode && address.postalCode)
    ) {
      return true;
    }

    return false;
  }
}
