export * from "./task.actions";
export * from "./task.reducer";

import { Actions as Single, singleReducer, SingleState } from "./single";
import { TaskListActions, listReducer, ListState } from "./list";
import { combineReducers } from "redux";

type TaskStateV2 = {
  list: ListState;
  single: SingleState;
};

const taskReducerV2 = combineReducers({
  list: listReducer,
  single: singleReducer
});

const taskActionsV2 = {
  List: TaskListActions,
  Single
};

export { taskReducerV2, TaskStateV2, taskActionsV2 };
