import { Address, AssignmentSnapShot } from "@haywork/api/kolibri";
import {
  RealEstateProperty,
  RealEstatePropertySearchItem,
} from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import { Ui } from "@haywork/modules/ui";
import head from "lodash-es/head";
import * as React from "react";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import MlsRow from "./components/mls-row";
import ObjectRow from "./components/object-row";
import { MatchingPropertiesOnAddressContainerProps } from "./matching-properties-on-address.container";

export type MatchingPropertiesOnAddressComponentProps = {
  address: Address;
  onSelectAssignment: (assignment: RealEstateProperty) => void;
};
type Props = MatchingPropertiesOnAddressComponentProps &
  MatchingPropertiesOnAddressContainerProps;
type MatchingProperties = {
  type: "object" | "mls";
  assignment: AssignmentSnapShot | RealEstatePropertySearchItem;
};

const styles = require("./style.scss");

export const MatchingPropertiesOnAddressComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      address,
      navigate,
      getMlsSuggestions,
      getMlsProperty,
      onSelectAssignment,
      mlsIsActive,
    }) => {
      const [assignments, setAssignments] = useState<MatchingProperties[]>([]);
      const [loading, setLoading] = useState(false);
      const [propertyLoading, setPropertyLoading] = useState(false);
      const inited = useRef<boolean>(false);

      const fetchSuggestions = useCallback(
        async (address: Address) => {
          try {
            if (
              !mlsIsActive ||
              !address ||
              !address?.postalCode ||
              !address.houseNumber
            )
              return;
            if (!inited.current) {
              inited.current = true;
            }
            setLoading(true);
            const results = await getMlsSuggestions(address);
            const mappedResults: MatchingProperties[] = (results || []).map(
              (r) => {
                return {
                  type: "mls",
                  assignment: r,
                };
              }
            );
            setAssignments(mappedResults);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },
        [setAssignments, mlsIsActive, getMlsSuggestions, setLoading]
      );

      const useMlsSuggestionCallback = useCallback(
        async (assignment: RealEstatePropertySearchItem) => {
          try {
            setPropertyLoading(true);
            const realEstateProperty = await getMlsProperty(
              assignment.id,
              head(assignment.appClientKeys)
            );
            onSelectAssignment(realEstateProperty);
          } finally {
            setPropertyLoading(false);
          }
        },
        [getMlsProperty, onSelectAssignment, setPropertyLoading]
      );

      const useObjectSuggestionCallback = useCallback(
        async (assignment: AssignmentSnapShot) => {
          // Not yet required
        },
        []
      );

      useEffect(() => {
        setAssignments([]);
        fetchSuggestions(address);
      }, [address, fetchSuggestions]);

      if (!inited.current) return null;

      return (
        <div styleName="wrapper">
          <div styleName="header">
            <I18n
              value={
                loading
                  ? "address.matchingProperties.title.loading"
                  : !loading && !assignments.length
                  ? "address.matchingProperties.title.noMatches"
                  : "address.matchingProperties.title.matches"
              }
            />
          </div>
          <div styleName={!assignments.length ? "body noPadding" : "body"}>
            {loading && <Ui.Loaders.List />}
            {!loading &&
              assignments.map((item, idx) =>
                item.type === "object" ? (
                  <ObjectRow
                    key={item.assignment.id || idx}
                    assignment={item.assignment as AssignmentSnapShot}
                    onAction={useObjectSuggestionCallback}
                    onNavigate={navigate}
                    loading={propertyLoading}
                  />
                ) : (
                  <MlsRow
                    key={item.assignment.id || idx}
                    assignment={item.assignment as RealEstatePropertySearchItem}
                    onAction={useMlsSuggestionCallback}
                    onNavigate={navigate}
                    loading={propertyLoading}
                  />
                )
              )}
          </div>
        </div>
      );
    }
  )
);
