import * as React from "react";
import * as CSSModules from "react-css-modules";
import { PillItem } from "../../email-with-relation-groups";
import I18n from "@haywork/components/i18n";
import classNames from "classnames";
import { PATTERN } from "@haywork/constants";
import { useMemo } from "react";

const styles = require("./style.scss");

interface Props {
  item: PillItem;
  selected?: boolean;
  onRemove: (id: string) => void;
  onClick: (id: string) => void;
  onMouseDown: () => void;
}
export const FormSuggestionItemComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, onClick, onMouseDown, onRemove, selected }: Props) => {
      const checkValidEmail = (email: string): boolean => {
        return !!email && new RegExp(PATTERN.EMAIL).test(email) === true;
      };

      const isValidEmail = useMemo(
        () => checkValidEmail(item.email),
        [item.email]
      );

      const nameStyle = classNames("name", {
        warning: !isValidEmail,
      });

      return (
        <div styleName={classNames("item", { selected })}>
          <div
            styleName="item__toggle"
            onMouseDown={onMouseDown}
            onMouseUp={() => onRemove(item.id)}
          >
            <i className="fa fa-check-square" />
          </div>
          <div
            styleName="item__name"
            onMouseDown={onMouseDown}
            onMouseUp={() => onClick(item.id)}
          >
            {!!item.email && <div>{item.email}</div>}
            {!!item.displayName && (
              <div styleName={nameStyle}>{item.displayName}</div>
            )}

            {item.isActive === false && (
              <div styleName="archived">
                <I18n value="archived" />
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);
