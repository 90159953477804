import * as React from "react";
import {
  Form,
  FormControls,
  Input,
  FormReference,
  Validators
} from "@haywork/modules/form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import * as CSSModules from "react-css-modules";
import get from "lodash-es/get";
import { EXTERNALROUTES } from "@haywork/constants";

const styles = require("./style.scss");

export interface FundaAddressFields {
  fundaPostalCode?: string | null;
  fundaStreet?: string | null;
  fundaLocality?: string | null;
}

interface Props {
  visible: boolean;
  fields: FundaAddressFields;
  hasFullAddress: boolean;
  onClose: () => void;
  onAddFundaAddress: (values: FundaAddressFields) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class AssigmentEditAddressFundaModalComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.formControls = {
      fundaPostalCode: {
        value: get(this.props.fields, "fundaPostalCode", ""),
        validators: [Validators.required(), Validators.maxLength(7)]
      },
      fundaStreet: {
        value: get(this.props.fields, "fundaStreet", ""),
        validators: [Validators.required()]
      },
      fundaLocality: {
        value: get(this.props.fields, "fundaLocality", ""),
        validators: [Validators.required()]
      }
    };

    this.submitForm = this.submitForm.bind(this);
    this.removeAddress = this.removeAddress.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
  }

  public render() {
    return (
      <Modal onClose={this.closeHandler} visible={this.props.visible}>
        <ModalHeader close={true} title="addFundaAddress.modalTitle" />
        <ModalBody>
          <Form
            name="funda-address"
            formControls={this.formControls}
            form={(form) => (this.formRef = form)}
            onSubmit={this.props.onAddFundaAddress}
          >
            <div className="form__row">
              <ResourceText
                resourceKey="addFundaAddress.modalBody"
                values={{ path: EXTERNALROUTES.KOLIBRI_BORDER_ADDRESS.URI }}
                asHtml={true}
              />
            </div>

            <div className="form__row">
              <div className="form__group">
                <div className="column" styleName="postal-code">
                  <label htmlFor="fundaPostalCode">
                    <ResourceText resourceKey="addFundaAddress.fundaPostalCode" />
                  </label>
                  <Input.Text
                    name="fundaPostalCode"
                    placeholder="addFundaAddress.placeholder.fundaPostalCode"
                  />
                </div>
                <div className="column__spacer" />
                <div className="column" styleName="locality">
                  <label htmlFor="fundaLocality">
                    <ResourceText resourceKey="addFundaAddress.fundaLocality" />
                  </label>
                  <Input.Text
                    name="fundaLocality"
                    placeholder="addFundaAddress.placeholder.fundaLocality"
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <label htmlFor="fundaStreet">
                <ResourceText resourceKey="addFundaAddress.fundaStreet" />
              </label>
              <Input.Text
                name="fundaStreet"
                placeholder="addFundaAddress.placeholder.fundaStreet"
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          {!!this.props.hasFullAddress && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.removeAddress}
            >
              <ResourceText resourceKey="addFundaAddress.removeAction" />
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.submitForm}
          >
            <ResourceText resourceKey="addFundaAddress.submitAction" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private closeHandler() {
    this.props.onClose();
    if (!this.formRef) return;
    this.formRef.clearErrors();
  }

  private submitForm() {
    if (!this.formRef) return;
    this.formRef.submit();
  }

  private removeAddress() {
    this.props.onAddFundaAddress({
      fundaLocality: undefined,
      fundaPostalCode: undefined,
      fundaStreet: undefined
    });
    if (!this.formRef) return;
    this.formRef.clear();
    this.formRef.clearErrors();
  }
}
