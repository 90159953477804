import {
  AssignmentType,
  InvoiceSnapShot,
  InvoiceStatus,
  LinkedAssignment,
  LinkedRelation,
  RelationType,
} from "@haywork/api/kolibri";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  EMPLOYEEROUTES,
  INVOICEROUTES,
  OBJECTTYPESROUTES,
  OFFICESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { Ui, uiTableRow, UiTableRowProps } from "@haywork/modules/ui";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { FormattedDate, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";

const route = RouteUtil.mapStaticRouteValues;
const styles = require("./list-item.component.scss");

enum Action {
  Archive = "Archive",
  Delete = "Delete",
}

interface ListItemComponentProps {
  invoice: InvoiceSnapShot;
  zebra: boolean;
  navigate: (path: string) => void;
  onDelete: (id: string) => void;
  onUnDelete: (id: string) => void;
  onArchive: (id: string) => void;
  onUnArchive: (id: string) => void;
  onDeleteDone: (id: string) => void;
  onArchiveDone: (id: string) => void;
  onUnArchiveDone: (id: string) => void;
}
interface ListItemComponentState {
  showActionOverlay: boolean;
  actionTitle: string;
  currentAction: Action;
}

@CSSModules(styles, { allowMultiple: true })
class ListItemComponent extends React.Component<
  ListItemComponentProps & UiTableRowProps,
  ListItemComponentState
> {
  constructor(props) {
    super(props);

    this.onActionDoneHandler = this.onActionDoneHandler.bind(this);
    this.onActionCancelHandler = this.onActionCancelHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onArchiveClickHandler = this.onArchiveClickHandler.bind(this);

    this.state = {
      showActionOverlay: false,
      actionTitle: "cancelInvoiceDelete",
      currentAction: null,
    };
  }

  public render() {
    const { invoice } = this.props;

    return (
      <Ui.ActionListItem
        actions={this.renderListActions()}
        zebra={this.props.zebra}
        actionTitle={this.state.actionTitle}
        actionVisible={this.state.showActionOverlay}
        onActionDone={this.onActionDoneHandler}
        onActionCancel={this.onActionCancelHandler}
      >
        <div
          styleName={classNames("invoice", { archived: !invoice.isActive })}
          style={this.props.gridTemplateColumns}
          onClick={this.onEditClickHandler}
        >
          <div styleName="column">{invoice.displayName}</div>
          <div styleName="column">
            {invoice.currency}
            &nbsp;
            <FormattedNumber
              value={invoice.invoiceTotalAmount}
              style="decimal"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </div>
          <div styleName="column">
            <FormattedDate value={invoice.invoiceDate} />
          </div>
          <div styleName="column">
            <FormattedDate value={invoice.dueDate} />
            {!!invoice.isOverdue && !!invoice.isActive && (
              <i
                styleName="overdue-icon"
                className="fas fa-fw fa-exclamation-triangle"
              />
            )}
          </div>
          <div styleName="column">
            {!invoice.isActive ? (
              <ResourceText resourceKey="archived" />
            ) : (
              <ResourceText
                masterKey="invoiceStatuses"
                resourceKey={invoice.status.toString()}
              />
            )}
          </div>
          <div styleName="column">
            {!!invoice.linkedRelations &&
              invoice.linkedRelations.map((relation, idx) => (
                <Link
                  key={relation.id}
                  to={this.getLinkedRelationRoute(relation)}
                  onClick={this.onStopPropagation}
                >
                  {relation.displayName}
                </Link>
              ))}
          </div>
          <div styleName="column">
            {!!invoice.linkedAssignment && (
              <Link
                to={this.getLinkedAssignmentRoute(invoice.linkedAssignment)}
                onClick={this.onStopPropagation}
              >
                {invoice.linkedAssignment.displayName}
              </Link>
            )}
          </div>
        </div>
      </Ui.ActionListItem>
    );
  }

  private onActionDoneHandler() {
    this.setState({
      showActionOverlay: false,
    });

    switch (this.state.currentAction) {
      case Action.Archive:
        return this.props.onArchiveDone(this.props.invoice.id);
      case Action.Delete:
        return this.props.onDeleteDone(this.props.invoice.id);
      default:
        return;
    }
  }

  private onActionCancelHandler() {
    this.setState({
      showActionOverlay: false,
    });

    switch (this.state.currentAction) {
      case Action.Archive:
        return this.props.onUnArchive(this.props.invoice.id);
      case Action.Delete:
        return this.props.onUnDelete(this.props.invoice.id);
      default:
        return;
    }
  }

  private onDeleteClickHandler() {
    this.setState({
      actionTitle: "cancelInvoiceDelete",
      currentAction: Action.Delete,
    });

    this.props.onDelete(this.props.invoice.id);
  }

  private onArchiveClickHandler() {
    if (!this.props.invoice.isActive) {
      this.props.onUnArchive(this.props.invoice.id);
      this.props.onUnArchiveDone(this.props.invoice.id);
      return;
    }

    this.setState({
      showActionOverlay: true,
      actionTitle: "cancelInvoiceArchive",
      currentAction: Action.Archive,
    });

    this.props.onArchive(this.props.invoice.id);
  }

  private onEditClickHandler() {
    const path = route(INVOICEROUTES.DETAIL.URI, { id: this.props.invoice.id });
    this.props.navigate(path);
  }

  private onStopPropagation(event: React.MouseEvent<{}>) {
    event.stopPropagation();
  }

  private getLinkedRelationRoute(linkedRelation: LinkedRelation): string {
    const { id, typeOfRelation } = linkedRelation;

    switch (typeOfRelation) {
      case RelationType.ContactPerson:
        return route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id });
      case RelationType.ContactCompany:
        return route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id });
      case RelationType.Employee:
        return route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
      case RelationType.Office:
        return route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
      default:
        return "";
    }
  }

  private getLinkedAssignmentRoute(linkedAssignment: LinkedAssignment): string {
    const { id, typeOfAssignment } = linkedAssignment;

    let path;

    switch (typeOfAssignment) {
      case AssignmentType.Acquisition:
        path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.AcquisitionObject:
        path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.ObjectType:
        path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
        break;
      case AssignmentType.Project:
        path = route(PROJECTROUTES.DETAIL.URI, { id });
        break;
      default:
        path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return path;
  }

  private renderListActions(): React.StatelessComponent[] {
    const deleteButton = () => (
      <button className="btn btn-danger" onClick={this.onDeleteClickHandler}>
        <i className="fal fa-times" />
      </button>
    );

    const archiveButton = () => (
      <button className="btn btn-primary" onClick={this.onArchiveClickHandler}>
        <i
          className={classNames(
            "fal",
            this.props.invoice.isActive ? "fa-archive" : "fa-inbox-out"
          )}
        />
      </button>
    );

    const editButton = () => (
      <button className="btn btn-success" onClick={this.onEditClickHandler}>
        <i className="fal fa-pencil" />
      </button>
    );

    if (this.props.invoice.status !== InvoiceStatus.Draft) {
      return [archiveButton, editButton];
    }

    return [deleteButton, archiveButton, editButton];
  }
}

export const ListItem = uiTableRow(ListItemComponent);
