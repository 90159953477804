import { NotificationSettings } from "@haywork/api/event-center";
import PageHeader from "@haywork/components/ui/page-header";
import { REQUEST } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { ResourceText } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { BulkItems } from "./bulk-items";
import { SettingsEventCenterContainerProps } from "./event-center.container";
import { ListItem } from "./list-item";
import I18n from "@haywork/components/i18n";

const styles = require("./event-center.component.scss");

export interface SettingsEventCenterComponentProps {}
interface State {
  loading: boolean;
}
type Props = SettingsEventCenterComponentProps &
  SettingsEventCenterContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SettingsEventCenterComponent extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onSettingChangedHandler = this.onSettingChangedHandler.bind(this);
  }

  public componentDidMount() {
    if (this.props.notificationSettings.length === 0) {
      this.props.getNotificationSettings();
    }
  }

  public componentDidUpdate(previousProps, previousState) {
    if (
      previousProps.notificationSettingsStatus !==
      this.props.notificationSettingsStatus
    ) {
      if (this.props.notificationSettingsStatus === REQUEST.PENDING) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  public render() {
    return (
      <div styleName="event-center">
        <PageHeader title="eventCenter" subTitle="settingsMenuUsage" />

        <div className="alert alert-info">
          <i className="fal fa-fw fa-info-circle" />
          <I18n value="eventCenterInfoMessage" asHtml />
        </div>

        <div styleName="event-center__body">
          {this.state.loading && <Ui.Loaders.List />}

          <div styleName="list">
            {!this.state.loading &&
              this.props.notificationSettings.length === 0 && (
                <Ui.EmptyState
                  type={UiEmptyStateType.List}
                  stickman={UiEmptyStateStickMan.NoTasks}
                  title="eventCenterSettingsEmptyStateTitle"
                />
              )}
          </div>

          {!this.state.loading && (
            <div styleName="listHeader">
              <I18n value="notifications.settings.general" />
            </div>
          )}

          {!this.state.loading && (
            <div styleName="list">
              {this.props.notificationSettings.map((setting, idx) => (
                <ErrorBoundary key={idx}>
                  <ListItem
                    setting={setting}
                    employees={this.props.employees}
                    unitsOfTime={this.props.unitsOfTime}
                    momentsInTime={this.props.momentsInTime}
                    onChange={this.onSettingChangedHandler.bind(this)}
                  />
                </ErrorBoundary>
              ))}
            </div>
          )}

          {!this.state.loading && (
            <div styleName="listHeader">
              <I18n value="notifications.settings.mls" />
            </div>
          )}

          {!this.state.loading && (
            <div styleName="list">
              {this.props.mlsNotificationSettings.map((setting, idx) => (
                <ErrorBoundary key={idx}>
                  <ListItem
                    setting={setting}
                    employees={this.props.employees}
                    unitsOfTime={this.props.unitsOfTime}
                    momentsInTime={this.props.momentsInTime}
                    onChange={this.onSettingChangedHandler.bind(this)}
                  />
                </ErrorBoundary>
              ))}
            </div>
          )}

          {!this.state.loading && (
            <div styleName="listHeader">
              <I18n value="notifications.settings.forEveryone" />
            </div>
          )}

          {!this.state.loading && (
            <div styleName="list">
              <BulkItems
                settings={this.props.bulkNotificationSettings}
                onChange={this.props.updateNotificationSettings}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  private onSettingChangedHandler(setting: NotificationSettings) {
    this.props.updateNotificationSettings([setting]);
  }
}
