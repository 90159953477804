import {
  DefaultFolderTreeSnapShot,
  DefaultFolderTree
} from "@haywork/api/kolibri";

export enum settingsFolderTreesActions {
  SetDefaultFolderTrees = "Settings.FolderTrees.SetDefaultFolderTrees",
  SetSingleDefaultFolderTree = "Settings.FolderTrees.SetSingleDefaultFolderTree",
  SetSingleDefaultFolderTreeStatus = "Settings.FolderTrees.SetSingleDefaultFolderTreeStatus"
}

interface SetDefaultFolderTrees {
  type: settingsFolderTreesActions.SetDefaultFolderTrees;
  defaultFolderTrees: DefaultFolderTreeSnapShot[];
}
const setDefaultFolderTrees = (
  defaultFolderTrees: DefaultFolderTreeSnapShot[]
) => ({
  type: settingsFolderTreesActions.SetDefaultFolderTrees,
  defaultFolderTrees
});

interface SetSingleDefaultFolderTree {
  type: settingsFolderTreesActions.SetSingleDefaultFolderTree;
  singleDefaultFolderTree: DefaultFolderTree;
}
const setSingleDefaultFolderTree = (
  singleDefaultFolderTree: DefaultFolderTree
) => ({
  type: settingsFolderTreesActions.SetSingleDefaultFolderTree,
  singleDefaultFolderTree
});

interface SetSingleDefaultFolderTreeStatus {
  type: settingsFolderTreesActions.SetSingleDefaultFolderTreeStatus;
  singleDefaultFolderTreeStatus: string;
}
const setSingleDefaultFolderTreeStatus = (
  singleDefaultFolderTreeStatus: string
) => ({
  type: settingsFolderTreesActions.SetSingleDefaultFolderTreeStatus,
  singleDefaultFolderTreeStatus
});

export type SettingsFolderTreesActionTypes =
  | SetDefaultFolderTrees
  | SetSingleDefaultFolderTree
  | SetSingleDefaultFolderTreeStatus;

export const SettingsFolderTreesActions = {
  setDefaultFolderTrees,
  setSingleDefaultFolderTree,
  setSingleDefaultFolderTreeStatus
};
