import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  AcquisitionAssignment,
  AcquisitionObjectAssignment,
  AcquisitionObjectAssignmentsClient,
  AcquisitionStatus,
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentsClient,
  AssignmentType,
  BlobsClient,
  ListingType,
  PhotoBlob,
  RealEstateGroup,
  SortOrder,
  UploadResponse,
} from "@haywork/api/kolibri";
import { RealEstatePropertyClient } from "@haywork/api/mls";
import {
  ACQUISITIONOBJECTROUTES,
  MAINROUTES,
  REQUEST,
} from "@haywork/constants";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  LayoutActions,
} from "@haywork/stores";
import { AcquisitionActions } from "@haywork/stores/acquisition";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { AsyncUtil, DateUtil, MlsUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import get from "lodash-es/get";
import * as moment from "moment";
import { Dispatch } from "../";
import { EditableThunks } from "../editable.thunk";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const createNew = (acquisition: AcquisitionAssignment) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { id: acquisitionId, realEstateGroup } = acquisition;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );

      let listingType: ListingType;
      switch (realEstateGroup) {
        case RealEstateGroup.Residential:
          listingType = ListingType.House;
          break;
        case RealEstateGroup.Commercial:
          listingType = ListingType.Office;
          break;
        case RealEstateGroup.Agricultural:
          listingType = ListingType.AgriculturalHouse;
          break;
        default:
          break;
      }

      const acquisitionResponse = await parseRequest.response(
        AcquisitionObjectAssignments.defineNew(
          {
            forRent:
              acquisition.forRent && acquisition.forSale
                ? true
                : acquisition.forRent,
            forSale:
              acquisition.forRent && acquisition.forSale
                ? false
                : acquisition.forSale,
            realEstateGroup,
            listingType,
            linkedAcquisitionAssignment: {
              id: acquisitionId,
            },
          },
          realEstateAgencyId
        ).then((response) => response.acquisitionObjectAssignment)
      );

      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
        id: acquisitionResponse.id,
      });
      const redirect = route(ACQUISITIONOBJECTROUTES.EDIT.URI, {
        id: acquisitionResponse.id,
      });

      const componentState = {
        icon: MAINROUTES.ACQUISITIONS.ICON,
        componentState: acquisitionResponse,
        path,
        title: "...",
        hasChanges: false,
        entityType: RootEntityType.AcquisitionObjectAssignment,
        entityId: acquisitionResponse.id,
        confirm: {
          title: { key: "saveObjectTypeConfirmTitle" },
          body: { key: "saveObjectTypeConfirmBody" },
        },
      };

      dispatch(EditableActions.addState(componentState));
      dispatch(push(redirect));
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const save = (
  acquisitionObjectAssignment: AcquisitionObjectAssignment,
  close: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    const status = !acquisitionObjectAssignment.status
      ? AcquisitionStatus.New
      : acquisitionObjectAssignment.status;

    const updatedAcquisitionObjectAssignment = {
      ...acquisitionObjectAssignment,
      status,
    };

    try {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );

      const response = await parseRequest.response(
        AcquisitionObjectAssignments.save(
          {
            acquisitionObjectAssignment: updatedAcquisitionObjectAssignment,
          },
          realEstateAgencyId
        ).then((response) => response.acquisitionObjectAssignment)
      );

      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
        id: response.id,
      });

      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(response)
      );
      dispatch(
        EditableActions.updateComponentState({
          componentState: response,
          hasChanges: false,
          ignoreChanges: true,
          path,
        })
      );

      if (close) {
        dispatch(push(path));
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const getAcquisitionObjects = (
  id: string,
  startIndex: number,
  stopIndex: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Assignments = new AssignmentsClient(host);

    try {
      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByAssignmentTypes: [AssignmentType.AcquisitionObject],
            filterByAcquisitionAssignmentIds: [id],
          },
          realEstateAgencyId
        )
      );

      const { results, totalResults } = response;
      return {
        items: results,
        total: totalResults,
      };
    } catch (error) {
      throw error;
    }
  };
};

const getAcquisitionObject = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      dispatch(AcquisitionActions.Assignment.setStatus(REQUEST.PENDING));

      const acquisitionObjectAssignment = await parseRequest.response(
        AcquisitionObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionObjectAssignment
        )
      );
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.ACQUISITIONS.ICON,
          componentState: acquisitionObjectAssignment,
          path,
          title: acquisitionObjectAssignment.displayName || "...",
          entityType: RootEntityType.AcquisitionObjectAssignment,
          entityId: acquisitionObjectAssignment.id,
          confirm: {
            title: { key: "acquisitionObject.saveConfirm.title" },
            body: { key: "acquisitionObject.saveConfirm.body" },
          },
        })
      );

      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
          acquisitionObjectAssignment
        )
      );
    } catch (error) {
      dispatch(AcquisitionActions.Assignment.setStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getAcquisitionObjectsInNegotiationOrWon = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Assignments = new AssignmentsClient(host);

    try {
      return await parseRequest.response(
        Assignments.search(
          {
            forRent: true,
            forSale: true,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: 0,
            take: 3,
            filterByAssignmentTypes: [AssignmentType.AcquisitionObject],
            filterByAcquisitionAssignmentIds: [id],
            acquisitionStatuses: [
              AcquisitionStatus.UnderNegociation,
              AcquisitionStatus.Purchased,
              AcquisitionStatus.Rented,
            ],
          },
          realEstateAgencyId
        ).then((response) => response.results || [])
      );
    } catch (error) {
      throw error;
    }
  };
};

const archiveAcquisitionObject = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      dispatch(AcquisitionActions.Assignment.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionObjectAssignments.archive({ id }, realEstateAgencyId)
      );
      const acquisitionObjectAssignment = await parseRequest.response(
        AcquisitionObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionObjectAssignment
        )
      );
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });

      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
          acquisitionObjectAssignment
        )
      );
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: acquisitionObjectAssignment,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveAcquisitionObject = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      dispatch(AcquisitionActions.Assignment.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionObjectAssignments.unarchive({ id }, realEstateAgencyId)
      );
      const acquisitionObjectAssignment = await parseRequest.response(
        AcquisitionObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionObjectAssignment
        )
      );
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });

      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
          acquisitionObjectAssignment
        )
      );
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: acquisitionObjectAssignment,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const deleteAcquisitionObject = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      dispatch(AcquisitionActions.Assignment.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionObjectAssignments.delete(id, realEstateAgencyId)
      );
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });

      dispatch(EditableThunks.remove(path));
      dispatch(
        SnackbarActions.addToast({
          value: "acquisitionObjectAssignment.toast.deleted",
          callback: () => {
            dispatch(unDeleteAcquisitionObject(id));
          },
          callbackLabel: "acquisitionObjectAssignment.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteAcquisitionObject = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      await parseRequest.response(
        AcquisitionObjectAssignments.undelete({ id }, realEstateAgencyId)
      );
      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });

      dispatch(push(path));
    } catch (error) {
      throw error;
    }
  };
};

const getLinkedAcquisitionObjectAssignmentRelations = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      return await parseRequest.response(
        AcquisitionObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionObjectAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const backToDashboard = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { assignment } = state.acquisition;
    const currentComponentState: AcquisitionObjectAssignment =
      state.editable.currentComponentState;

    if (
      get(assignment.acquisitionAssignmentObject, "id") !==
      get(currentComponentState, "id")
    )
      return;

    try {
      const { id } = assignment.acquisitionAssignmentObject;
      dispatch(updateLocalState(id, true));
    } catch (error) {
      throw error;
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: AcquisitionObjectAssignment = editable.componentState;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new AcquisitionObjectAssignmentsClient(host);

    try {
      const refAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.acquisitionObjectAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );
      const { hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: AcquisitionObjectAssignment = {
          ...componentState,
          ...refAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(
            AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
              newComponentState
            )
          );
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadAcquisitionObjectAssignment = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const acquisitionObjectAssignment = externalChangesData.updatedEntity as AcquisitionObjectAssignment;

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState: acquisitionObjectAssignment,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
          acquisitionObjectAssignment
        )
      );
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateLocalState = (id: string, navigateToDashboard = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new AcquisitionObjectAssignmentsClient(host);

    try {
      const acquisitionObjectAssignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.acquisitionObjectAssignment)
      );

      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
        id: acquisitionObjectAssignment.id,
      });

      if (navigateToDashboard) {
        dispatch(push(path));
      }

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: acquisitionObjectAssignment,
          ignoreChanges: true,
          resetExternalChanges: true,
        })
      );
      dispatch(
        AcquisitionActions.Assignment.setAcquisitionObjectAssignment(
          acquisitionObjectAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const uploadMlsPhotosForAquisition = (MlsPhotos: PhotoBlob[]) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;

    const blobsClient = new BlobsClient(host);

    if (MlsPhotos && MlsPhotos.length > 0) {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );
      try {
        const promises: Array<Promise<UploadResponse>> = [];
        for (let i = 0; i < 1; i++) {
          const file = MlsPhotos[i];
          const fileName = file.fileName
            ? file.fileName.replace(/\.[0-9a-z]+$/i, "")
            : file.md5Hash;
          const fileExtension = (file.fileExtension || "").toLowerCase();
          promises.push(
            parseRequest.response(
              blobsClient.uploadFromUri(
                {
                  uri: file.urlOriginal,
                  isPrivate: false,
                  fileName,
                  fileExtension,
                },
                realEstateAgencyId
              )
            )
          );
        }

        const photoBlobs = await Promise.all(promises);

        const photos: PhotoBlob[] = photoBlobs.map((photo) => ({
          ...photo,
          height: photo.height || 0,
          width: photo.height || 0,
          thumbUrl: photo.thumbUrl,
        }));
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
        dispatch(
          SnackbarActions.addToast({
            value: "mls.copy.data.added",
            icon: "exchange",
          })
        );
        return photos;
      } catch (error) {
        dispatch(
          LayoutActions.setCreateLoaderVisibility({
            createLoaderVisible: false,
          })
        );
        throw error;
      }
    }
  };
};

const createNewAcquisitionObjectAssignmentFromMLS = (
  bundleId: string,
  appClientKey: string,
  acquisition: AcquisitionAssignment
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );
      const state = getState();
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const { host, mlsHost } = state.appSettings;
      const { id: acquisitionId, realEstateGroup } = acquisition;
      const aquisitionObjectClient = new AcquisitionObjectAssignmentsClient(
        host
      );
      const realEstatePropertyClient = new RealEstatePropertyClient(mlsHost);

      let listingType: ListingType;
      switch (realEstateGroup) {
        case RealEstateGroup.Residential:
          listingType = ListingType.House;
          break;
        case RealEstateGroup.Commercial:
          listingType = ListingType.Office;
          break;
        case RealEstateGroup.Agricultural:
          listingType = ListingType.AgriculturalHouse;
          break;
        default:
          break;
      }

      let aquisitionObject = await parseRequest.response(
        aquisitionObjectClient
          .defineNew(
            {
              forRent:
                acquisition.forRent && acquisition.forSale
                  ? true
                  : acquisition.forRent,
              forSale:
                acquisition.forRent && acquisition.forSale
                  ? false
                  : acquisition.forSale,
              realEstateGroup,
              listingType,
              linkedAcquisitionAssignment: {
                id: acquisitionId,
              },
            },
            realEstateAgencyId
          )
          .then((response) => response.acquisitionObjectAssignment)
      );

      const realEstateProperty = await parseRequest.response(
        realEstatePropertyClient
          .read(bundleId, appClientKey, realEstateAgencyId)
          .then((response) => response.realEstateProperty)
      );
      const mappedRealEstateProperty = MlsUtil.mapToAcquisitionAssignment(
        realEstateProperty
      );
      aquisitionObject = {
        ...mappedRealEstateProperty,
        ...aquisitionObject,
      };

      const path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, {
        id: aquisitionObject.id,
      });
      const redirect = route(ACQUISITIONOBJECTROUTES.EDIT.URI, {
        id: aquisitionObject.id,
      });

      const componentState = {
        icon: MAINROUTES.ACQUISITIONS.ICON,
        componentState: aquisitionObject,
        path,
        title: "...",
        hasChanges: false,
        entityType: RootEntityType.AcquisitionObjectAssignment,
        entityId: aquisitionObject.id,
        confirm: {
          title: { key: "saveObjectTypeConfirmTitle" },
          body: { key: "saveObjectTypeConfirmBody" },
        },
      };

      dispatch(EditableActions.addState(componentState));
      dispatch(push(redirect));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const getLinkedAcquisitionObjectAssignment = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionObjectAssignments = new AcquisitionObjectAssignmentsClient(
      host
    );

    try {
      return await parseRequest.response(
        AcquisitionObjectAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionObjectAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

export const AcquisitionObjectThunk = {
  createNew,
  getAcquisitionObjects,
  save,
  getAcquisitionObject,
  getAcquisitionObjectsInNegotiationOrWon,
  getLinkedAcquisitionObjectAssignmentRelations,
  getLinkedAcquisitionObjectAssignment,
  archiveAcquisitionObject,
  unArchiveAcquisitionObject,
  deleteAcquisitionObject,
  unDeleteAcquisitionObject,
  backToDashboard,
  checkExternalChanges,
  reloadAcquisitionObjectAssignment,
  uploadMlsPhotosForAquisition,
  createNewAcquisitionObjectAssignmentFromMLS,
};
