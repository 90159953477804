import * as React from "react";
import { FC, memo, forwardRef } from "react";
import * as CSSModules from "react-css-modules";
import { AcquisitionListContainerProps } from "./list.container";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import classNames from "classnames";
import I18n from "@haywork/components/i18n";
import ListItem from "../list-item";
import InfinitLoader from "react-window-infinite-loader";
import { REQUEST } from "@haywork/constants";
import { Ui } from "@haywork/modules/ui";
import EmptyState from "@haywork/components/ui/empty-state";
import Button from "@haywork/components/ui/button";

const styles = require("./style.scss");

const InnerElementType = forwardRef<any, any>(
  CSSModules(styles, { allowMultiple: true })(
    (
      { children, style, loading, total, onEmptyStateActionClick, ...rest },
      ref
    ) => {
      return (
        <div
          {...rest}
          ref={ref}
          style={{ ...style, height: style.height + 30 }}
        >
          <div styleName={classNames("header", "columnized")}>
            <div styleName="column">
              <I18n value="acquisitions.listColumn.assignment" />
            </div>
            <div styleName="column">
              <I18n value="acquisitions.listColumn.issuers" />
            </div>
            <div styleName="column">
              <I18n value="acquisitions.listColumn.type" />
            </div>
            <div styleName="column">
              <I18n value="acquisitions.listColumn.saleAndOrRent" />
            </div>
            <div styleName="column">
              <I18n value="acquisitions.listColumn.status" />
            </div>
          </div>

          {loading && total === 0 && (
            <div styleName="loader">
              <Ui.Loaders.List />
            </div>
          )}
          {!loading && total === 0 && (
            <div styleName="empty-state">
              <EmptyState
                icon="handshake-alt"
                title="acquisition.emptyStateList.title"
                subTitle="acquisition.emptyStateList.subTitle"
                action={
                  <Button
                    label="acquisition.emptyStateList.action"
                    category="primary"
                    onClick={onEmptyStateActionClick}
                  />
                }
              />
            </div>
          )}
          {children}
        </div>
      );
    }
  )
);

export type AcquisitionListComponentProps = {};
type Props = AcquisitionListComponentProps & AcquisitionListContainerProps;

export const AcquisitionListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({
      navigate,
      assignments,
      assignmentsStatus,
      total,
      getAcquisitions,
      createAcquisition,
    }) => {
      const row: FC<ListChildComponentProps> = memo((props) => {
        const { index } = props;
        const rowData = assignments[index];
        if (!rowData) return null;

        return !rowData ? null : (
          <ListItem {...props} acquisition={rowData} onNavigate={navigate} />
        );
      });

      const isItemLoaded = (index: number) => !!assignments[index];
      const loadMoreItems = (startIndex: number, stopIndex: number) => {
        return getAcquisitions(startIndex, stopIndex);
      };

      return (
        <AutoSizer>
          {({ width, height }) => (
            <InfinitLoader
              isItemLoaded={isItemLoaded}
              itemCount={total}
              loadMoreItems={loadMoreItems}
              minimumBatchSize={100}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  innerElementType={(props) => (
                    <InnerElementType
                      {...props}
                      loading={assignmentsStatus === REQUEST.PENDING}
                      total={total}
                      onEmptyStateActionClick={createAcquisition}
                    />
                  )}
                  itemSize={60}
                  itemCount={total}
                  height={height}
                  width={width}
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                >
                  {row}
                </FixedSizeList>
              )}
            </InfinitLoader>
          )}
        </AutoSizer>
      );
    }
  )
);
