import { Office } from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import get from "lodash-es/get";
import { connect, MapStateToProps } from "react-redux";
import {
  OfficeDetailDossierComponent,
  OfficeDetailDossierComponentProps
} from "./detail-dossier";

interface StateProps {
  officeId?: string;
  name?: string;
  linkedFolderTreeId: string | null | undefined;
}
interface DispatchProps {}

const mapStateToProps: MapStateToProps<
  StateProps,
  OfficeDetailDossierComponentProps,
  AppState
> = (state) => {
  const office: Office = state.editable.currentComponentState;
  const { id: officeId, name, linkedFolderTree } = office;

  return {
    officeId,
    name,
    linkedFolderTreeId: get(linkedFolderTree, "id", "")
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OfficeDetailDossierComponentProps
> = (dispatch) => ({});

export type OfficeDetailDossierContainerProps = StateProps & DispatchProps;
export const OfficeDetailDossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfficeDetailDossierComponent);
