import { REDUX } from "@haywork/constants";
import * as ActionType from "./scheduler-category.types";
import { AgendaItemCategory } from "@haywork/api/kolibri";

const setSchedulerCategories = (payload: ActionType.SchedulerCategories) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORIES,
  ...payload
});

const setSchedulerCategory = (
  payload: ActionType.SingleAgendaItemCategory
) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY,
  ...payload
});

const addSchedulerCategory = (
  payload: ActionType.AgendaItemCategorySnapShotPayload
) => ({
  type: REDUX.SCHEDULER_CATEGORIES.NEW_SCHEDULER_CATEGORY,
  ...payload
});

const deleteSchedulerCategory = (payload: ActionType.Id) => ({
  type: REDUX.SCHEDULER_CATEGORIES.DELETE_SCHEDULER_CATEGORY,
  ...payload
});

const setSchedulerCategoriesStatus = (status: string) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORIES_STATUS,
  status
});

const setAgendaItemCategory = (
  payload: ActionType.AgendaItemCategoryPayload
) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY_ITEM,
  ...payload
});

const updateAgendaItemCategory = (agendaItemCategory: AgendaItemCategory) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY_ITEM,
  agendaItemCategory
});

const setAgendaItemCategoryStatus = (status: string) => ({
  type: REDUX.SCHEDULER_CATEGORIES.SET_SCHEDULER_CATEGORY_STATUS,
  status
});

export const SchedulerCategoryActions = {
  setSchedulerCategories,
  setSchedulerCategory,
  addSchedulerCategory,
  deleteSchedulerCategory,
  setSchedulerCategoriesStatus,
  setAgendaItemCategory,
  setAgendaItemCategoryStatus,
  updateAgendaItemCategory
};
