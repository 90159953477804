import I18n from "@haywork/components/i18n";
import { EditableItem } from "@haywork/stores";
import classNames from "classnames";
import * as React from "react";
import { FC, memo } from "react";
import * as CSSModules from "react-css-modules";

type Props = {
  tab: EditableItem;
  onTabClose: (tab: EditableItem) => void;
  onTabClick: (path: string) => void;
};

const styles = require("./tab.component.scss");

export const TabComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ tab, onTabClose, onTabClick }) => {
      if (!tab) return null;
      const { title, active, hasChanges, icon } = tab;

      return (
        <div
          styleName={classNames("tab", { active })}
          data-cy="CY-editableTab"
          onClick={() => onTabClick(tab.currentPath)}
        >
          <div styleName="icon">
            <i className={classNames("fal", `fa-${icon}`)} />
          </div>
          <div styleName="label">
            <I18n value={title} />
          </div>
          {!!hasChanges && <div styleName="changes" />}
          <div
            styleName="close"
            data-cy="CY-editableCloseTab"
            onClick={(e) => {
              e.stopPropagation();
              onTabClose(tab);
            }}
          >
            <i className="fal fa-times" />
          </div>
        </div>
      );
    }
  )
);
