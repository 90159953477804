import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { AssignmentSnapShot, RelationType } from "@haywork/api/kolibri";
import {
  AssignmentThunks,
  AuthorizationThunks,
  Dispatch
} from "@haywork/middleware/thunk";
import {
  RelationAssignmentComponent,
  RelationAssignmentComponentProps
} from "@haywork/modules/relation";
import { AppState } from "@haywork/stores";
import { AssignmentUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  relationAssignmentState?: string;
  selectedRelationId?: string;
  selectedRelationDisplayName?: string;
  relationAssignments?: AssignmentSnapShot[];
  canLoadMore?: boolean;
  selectedRelationType: RelationType;
}

interface DispatchProps {
  getRelationAssignments: (relationId: string, init?: boolean) => void;
  goToAssignmentDetail: (assignment: AssignmentSnapShot) => void;
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps = <StateProps, RelationAssignmentComponentProps>(
  state: AppState
) => ({
  relationAssignmentState:
    state.relation.detailAssignment.relationAssignmentState,
  selectedRelationId: state.relation.detail.selectedRelationId,
  selectedRelationDisplayName:
    state.relation.detail.selectedRelationDisplayName,
  relationAssignments: state.relation.detailAssignment.relationAssignments,
  canLoadMore:
    state.relation.detailAssignment.relationAssignmentPage <
    state.relation.detailAssignment.relationAssignmentPageCount,
  selectedRelationType: state.relation.detail.selectedRelationType
});

const mapDispatchToProps = <DispatchProps, RelationAssignmentComponentProps>(
  dispatch: Dispatch<any>
) => ({
  getRelationAssignments: (relationId: string, init?: boolean) =>
    dispatch(
      AssignmentThunks.getAssignmentsForRelationDetail(relationId, init)
    ),
  goToAssignmentDetail: (assignment: AssignmentSnapShot) =>
    dispatch(push(AssignmentUtil.getAssignmentPath(assignment))),
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type RelationAssignmentContainerProps = StateProps & DispatchProps;
export const RelationAssignmentContainer = connect<
  StateProps,
  DispatchProps,
  RelationAssignmentComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(RelationAssignmentComponent);
