import {
  FinancialAdministration,
  FinancialAdministrationProduct,
  FinancialAdministrationTaxRate,
  Invoice,
  InvoiceLine,
} from "@haywork/api/kolibri";
import { MappedDropdownValue } from "@haywork/modules/filter";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
} from "@haywork/modules/form";
import { FormControlUtil, StringUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import isString from "lodash-es/isString";
import sortBy from "lodash-es/sortBy";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./invoice-line.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface InvoiceLineComponentProps {
  invoiceLine: InvoiceLine;
  financialAdministration: FinancialAdministration;
  invoice: Invoice;
  idx: number;
  addedProduct: FinancialAdministrationProduct;
  updateInvoiceLine: (invoiceLine: InvoiceLine, index: number) => void;
  removeInvoiceLine: (idx: number) => void;
  showAddProductModal: () => void;
}

interface InvoiceLineComponentState {
  showAddProductModal: boolean;
  selectedProductId: number;
  taxRates: FinancialAdministrationTaxRate[];
  product: FinancialAdministrationProduct;
  taxPercentage: number;
}

@CSSModules(styles, { allowMultiple: true })
export class InvoiceLineComponent extends React.Component<
  InvoiceLineComponentProps,
  InvoiceLineComponentState
> {
  private formControls: FormControls;
  private form: FormReference;

  constructor(props) {
    super(props);

    this.getTaxPercentage = this.getTaxPercentage.bind(this);
    this.getTotalPrice = this.getTotalPrice.bind(this);
    this.getMappedProducts = this.getMappedProducts.bind(this);
    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    const taxRates = sortBy(
      this.props.financialAdministration.taxRates,
      "taxPercentage"
    );
    const product = this.getProductById(this.props.invoiceLine.productID);

    this.state = {
      showAddProductModal: false,
      selectedProductId: value(this.props.invoiceLine, "productID"),
      taxRates,
      product,
      taxPercentage: !!product
        ? this.getTaxPercentage(product.taxRateId)
        : this.props.invoiceLine.taxPercentage || 0,
    };

    this.formControls = {
      productID: {
        value: value(this.props.invoiceLine, "productID"),
        onChange: (ref) => {
          this.setState({ selectedProductId: ref.value });
          if (!!ref.value) {
            const product = this.getProductById(ref.value);
            this.setState({
              product,
              taxPercentage: !!product
                ? this.getTaxPercentage(product.taxRateId)
                : this.props.invoiceLine.productID === -1
                ? this.props.invoiceLine.taxPercentage
                : 0,
            });
            if (!!product) {
              return {
                productID: ref.value,
                description: product.description,
                amount: 1,
                unitPriceNet: product.unitPriceNet,
                totalPriceGross: this.getTotalPrice(
                  product.unitPriceNet,
                  product.taxRateId,
                  1
                ),
              };
            }
          }
        },
      },
      description: { value: value(this.props.invoiceLine, "description") },
      amount: {
        value: value(this.props.invoiceLine, "amount"),
        onChange: (ref, get) => {
          const { taxPercentage } = this.state;
          const unitPriceNet = get("unitPriceNet");
          if (
            [null, undefined, NaN].indexOf(taxPercentage) === -1 &&
            !!unitPriceNet &&
            !!ref.value
          ) {
            const totalPriceGross =
              ((taxPercentage / 100) * parseFloat(unitPriceNet.value) +
                parseFloat(unitPriceNet.value)) *
              parseFloat(ref.value);
            return { totalPriceGross };
          }
          return { totalPriceGross: 0, amount: "" };
        },
      },
      unitPriceNet: {
        value: value(this.props.invoiceLine, "unitPriceNet"),
        onChange: (ref, get) => {
          const { taxPercentage } = this.state;
          const amount = get("amount");
          if (
            [null, undefined, NaN].indexOf(taxPercentage) === -1 &&
            !!amount &&
            !!ref.value
          ) {
            const totalPriceGross =
              ((taxPercentage / 100) * parseFloat(ref.value) +
                parseFloat(ref.value)) *
              parseFloat(amount.value);
            return { totalPriceGross };
          }
          return { totalPriceGross: 0, unitPriceNet: "" };
        },
      },
      totalPriceGross: {
        value: value(this.props.invoiceLine, "totalPriceGross", 0),
        onChange: (ref, get) => {
          const amount = parseFloat(get("amount").value || "0");
          const taxPercentage = parseFloat(
            `1.${StringUtil.prependZero(this.state.taxPercentage) || 0}`
          );
          const value = parseFloat(ref.value);

          return {
            unitPriceNet: value / taxPercentage / amount,
          };
        },
      },
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: InvoiceLineComponentProps
  ) {
    if (!nextProps) return;

    let productId;

    if (
      !!this.form &&
      !deepEqual(nextProps.invoiceLine, this.props.invoiceLine)
    ) {
      this.form.update(
        {
          productID: value(nextProps.invoiceLine, "productID"),
          description: value(nextProps.invoiceLine, "description"),
          amount: value(nextProps.invoiceLine, "amount"),
          unitPriceNet: value(nextProps.invoiceLine, "unitPriceNet"),
          totalPriceGross: value(nextProps.invoiceLine, "totalPriceGross", 0),
        },
        true
      );
      productId = value(nextProps.invoiceLine, "productID");
    }
    if (!!nextProps.addedProduct) {
      this.form.update({
        productID: nextProps.addedProduct.id,
      });
      productId = nextProps.addedProduct.id;
    }
    if (
      nextProps.financialAdministration.id !==
      this.props.financialAdministration.id
    ) {
      const taxRates = sortBy(
        nextProps.financialAdministration.taxRates,
        "taxPercentage"
      );
      this.setState({ taxRates });
    }

    const product = this.getProductById(productId);
    if (!!product) {
      const taxPercentage = this.getTaxPercentage(product.taxRateId);
      this.setState({
        product,
        taxPercentage,
      });
    }
  }

  public render() {
    const products: MappedDropdownValue[] = this.getMappedProducts(
      this.props.financialAdministration.products
    );
    const disabled =
      !this.state.selectedProductId && this.state.selectedProductId !== -1;

    return (
      <Form
        name="invoiceLine"
        formControls={this.formControls}
        onChange={this.onChangeHandler}
        asSubForm
        form={(ref) => (this.form = ref)}
      >
        <div styleName="invoiceLine">
          {/* product/service */}
          <div styleName="option product">
            <Input.NewSelect
              name="productID"
              addEmptyOption
              emptyOptionLabel="chooseAnProduct"
              emptyOption={""}
              disabled={this.props.invoiceLine.productID === -1}
              values={products}
              valuesProp="value"
              displayProp="label"
              addActionOption
              actionOptionClick={this.props.showAddProductModal}
              actionOptionLabel="addNewProduct"
            />
          </div>
          {/*discription*/}
          <div styleName="option description">
            <Input.Text
              name="description"
              placeholder="definitionPlaceholder"
              disabled={disabled}
              maxLength={512}
            />
          </div>
          {/*amount*/}
          <div styleName="option amount">
            <Input.Number
              name="amount"
              disabled={disabled || this.props.invoiceLine.productID === -1}
              min={-999999}
              max={999999}
            />
          </div>
          {/*net price*/}
          <div styleName="option unitPriceNet">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name="unitPriceNet"
                disabled={disabled}
                pretty
                min={-999999}
                max={999999}
                asPrice
              />
            </div>
          </div>
          {/*VAT rates*/}
          <div styleName="option taxPercentage">
            <div styleName="percentage">{this.state.taxPercentage}%</div>
          </div>
          {/*Total price*/}
          <div styleName="option totalPriceGross">
            <div className="input__helper">
              <div className="pre">&euro;</div>
              <Input.Number
                name="totalPriceGross"
                pretty
                min={-999999}
                max={999999}
                disabled={disabled}
                asPrice
              />
            </div>
          </div>
          <div styleName="option delete" onClick={this.onRemoveClickHandler}>
            <i className="fal fa-minus-circle" />
          </div>
        </div>
      </Form>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { invoiceLine } = this.props;
    const { productID, description, amount, unitPriceNet, totalPriceGross } =
      values;
    const { taxPercentage } = this.state;

    const updatedInvoiceLine: InvoiceLine = {
      ...invoiceLine,
      productID,
      description,
      amount: parseFloat(amount),
      unitPriceNet: parseFloat(unitPriceNet),
      taxPercentage,
      totalPriceGross: parseFloat(totalPriceGross),
      totalPriceNet: parseFloat(amount) * parseFloat(unitPriceNet),
    };

    this.props.updateInvoiceLine(updatedInvoiceLine, this.props.idx);
  }

  private getMappedProducts(
    products: FinancialAdministrationProduct[]
  ): MappedDropdownValue[] {
    const mappedProducts: MappedDropdownValue[] = products.map((product) => {
      return {
        label: product.descriptionShort,
        value: product.id,
      };
    });

    return mappedProducts;
  }

  private getTotalPrice(price: number, taxId: number, amount: number) {
    const taxRate = this.getTaxPercentage(taxId);
    return price * amount + price * amount * (taxRate / 100);
  }

  private onRemoveClickHandler() {
    this.props.removeInvoiceLine(this.props.idx);
  }

  private getTaxPercentage(id: number): number {
    if (this.props.invoiceLine.productID === -1)
      return this.props.invoiceLine.taxPercentage;

    const taxRates = value(this.props.financialAdministration, "taxRates", []);
    const taxRate = taxRates.find((t) => t.id === id);

    return !!taxRate ? taxRate.taxPercentage : 0;
  }

  private getProductById(
    id?: number | string
  ): FinancialAdministrationProduct | null {
    if (!id) return null;
    const productId = isString(id) ? parseInt(id) : id;
    const product = this.props.financialAdministration.products.find(
      (p) => p.id === productId
    );
    return product || null;
  }
}
