class AnalyticsSingleton {
  private static instance: AnalyticsSingleton;
  public tracker: UniversalAnalytics.Tracker;
  private ga: UniversalAnalytics.ga;

  static get Instance() {
    return (this.instance = this.instance || new AnalyticsSingleton());
  }

  public setUserAndAnalyticsApp(userId: string, uaId: string) {
    if (ga) {
      this.ga = ga;
      this.ga("create", uaId, "auto", { userId });
      this.ga((tracker: UniversalAnalytics.Tracker) => {
        this.tracker = tracker;
      });
    }
  }

  public sendException(exDescription: string, exFatal: boolean = false) {
    if (!this.tracker) return;
    this.tracker.send("exception", {
      exDescription,
      exFatal,
    });
  }

  public sendTiming(
    timingCategory: string,
    timingVar: string,
    timingValue: number
  ) {
    if (!this.tracker) return;
    this.tracker.send("timing", {
      timingCategory,
      timingVar,
      timingValue,
    });
  }

  public sendEvent(
    eventCategory: string,
    eventAction?: string,
    eventLabel?: string,
    eventValue?: number
  ) {
    if (!this.tracker) return;
    this.tracker.send("event", {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    });
  }

  public sendView(page: string) {
    if (!this.tracker) return;
    this.tracker.send("pageview", {
      page,
    });
  }
}

export class AnalyticsService {
  private analytics = AnalyticsSingleton.Instance;

  public setUserAndAnalyticsApp(userId: string, uaId: string) {
    this.analytics.setUserAndAnalyticsApp(userId, uaId);
  }

  public exception(exDescription: string, exFatal: boolean = false) {
    this.analytics.sendException(exDescription, exFatal);
  }

  public timing(
    timingCategory: string,
    timingVar: string,
    timingValue: number
  ) {
    this.analytics.sendTiming(timingCategory, timingVar, timingValue);
  }

  public event(
    eventCategory: string,
    eventAction?: string,
    eventLabel?: string,
    eventValue?: number
  ) {
    this.analytics.sendEvent(
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    );
  }

  public view(page: string) {
    this.analytics.sendView(page);
  }
}
