import { GenderType } from "@haywork/api/event-center";
import {
  AgendaItem,
  LinkedAssignment,
  LinkedRelation,
  RelationSnapShot,
  Task,
} from "@haywork/api/kolibri";
import {
  MAINROUTES,
  RELATIONROUTES,
  REQUEST,
  SETTINGSROUTES,
} from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { EventsModalContainerProps } from "@haywork/modules/event-center";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import {
  InfiniteScroll,
  InfiniteScrollScrollToTopFn,
  ResourceText,
} from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import {
  EventNotificationsFilter,
  RouteUtil,
  ExtendedEventNotification,
} from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import DuplicatesModal from "./duplicate-contacts-modal";
import { ModalMessage } from "./modal-message";

const styles = require("./events-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface EventsModalComponentProps {}
interface State {
  visible: boolean;
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
  duplicatesModalVisible: boolean;
  duplicatesModalView: "duplicates" | "other" | null;
  duplicatesModalRelations: RelationSnapShot[];
  duplicatesModalRelationData: {
    name?: string;
    email?: string;
    telephone?: string;
    mobilePhone?: string;
    gender?: GenderType;
  };
  duplicatesModalLinkedMessageId: string | undefined | null;
}
type Props = EventsModalComponentProps & EventsModalContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EventsModalComponent extends React.Component<Props, State> {
  private wrapper: HTMLDivElement;
  private onScrollToTop: InfiniteScrollScrollToTopFn;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      newEntityVisible: false,
      newEntityOptions: null,
      duplicatesModalVisible: false,
      duplicatesModalView: null,
      duplicatesModalRelations: [],
      duplicatesModalRelationData: {},
      duplicatesModalLinkedMessageId: null,
    };

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.setNotificationsFilter = this.setNotificationsFilter.bind(this);
    this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
    this.openDraft = this.openDraft.bind(this);
    this.bindWrapperRef = this.bindWrapperRef.bind(this);
    this.toggleModalVisibilityClick =
      this.toggleModalVisibilityClick.bind(this);
    this.navigateToSettings = this.navigateToSettings.bind(this);
    this.onScrollEndHandler = this.onScrollEndHandler.bind(this);
    this.onNavigateHandler = this.onNavigateHandler.bind(this);
    this.onEmailHandler = this.onEmailHandler.bind(this);
    this.onCreateNewTaskEntity = this.onCreateNewTaskEntity.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);
    this.onNewTaskHandler = this.onNewTaskHandler.bind(this);
    this.onNewAppointmentHandler = this.onNewAppointmentHandler.bind(this);
    this.onCheckRelationForDuplicates =
      this.onCheckRelationForDuplicates.bind(this);
    this.onCloseDuplicatesModal = this.onCloseDuplicatesModal.bind(this);
    this.onCreateNewRelationEntity = this.onCreateNewRelationEntity.bind(this);
    this.onCreateNewAppointmentEntity =
      this.onCreateNewAppointmentEntity.bind(this);
    this.onUpdateLinkedRelation = this.onUpdateLinkedRelation.bind(this);

    document.addEventListener("click", this.onClickOutsideHandler, true);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      nextProps.notificationsStatus === REQUEST.SUCCESS &&
      this.props.numberOfNotArchivedEvents === 0 &&
      nextProps.numberOfNotArchivedEvents > this.props.numberOfNotArchivedEvents
    ) {
      this.props.getNotifications();
    }
  }

  public render() {
    const personalFilterStyle = classNames("personal", {
      active: this.props.currentFilter === EventNotificationsFilter.Personal,
    });
    const allFilterStyle = classNames("general", {
      active: this.props.currentFilter === EventNotificationsFilter.General,
    });
    const processedFilterStyle = classNames("processed", {
      active: this.props.currentFilter === EventNotificationsFilter.Processed,
    });
    const eventsModalStyle = classNames("events-modal", {
      visible: this.state.visible,
    });

    return (
      <div
        styleName="events-modal__wrapper"
        className="hidden-xs"
        ref={this.bindWrapperRef}
      >
        <div
          styleName="events-modal__trigger"
          onClick={this.toggleModalVisibilityClick}
        >
          <i className="fa fa-bell" />
          {this.props.numberOfNotArchivedEvents > 0 && (
            <div styleName="unread-count">
              {this.props.numberOfNotArchivedEvents}
            </div>
          )}
        </div>
        <div styleName={eventsModalStyle}>
          <div styleName="events-modal__header">
            <h2>
              <ResourceText resourceKey="eventCenterModalTitle" />
            </h2>
            <button
              styleName={personalFilterStyle}
              type="button"
              onClick={() =>
                this.setNotificationsFilter(EventNotificationsFilter.Personal)
              }
            >
              <ResourceText resourceKey="eventCenterModalFilterPersonal" />
              {this.props.numberOfPersonalUnprocessedNotifications > 0 && (
                <span styleName="unread-count">
                  {this.props.numberOfPersonalUnprocessedNotifications}
                </span>
              )}
            </button>
            <button
              styleName={allFilterStyle}
              type="button"
              onClick={() =>
                this.setNotificationsFilter(EventNotificationsFilter.General)
              }
            >
              <ResourceText resourceKey="eventCenterModalFilterGeneral" />
              {this.props.numberOfGeneralUnprocessedNotifications > 0 && (
                <span styleName="unread-count">
                  {this.props.numberOfGeneralUnprocessedNotifications}
                </span>
              )}
            </button>
            <div styleName="spacer" />
            <button
              styleName={processedFilterStyle}
              type="button"
              onClick={() =>
                this.setNotificationsFilter(EventNotificationsFilter.Processed)
              }
            >
              <ResourceText resourceKey="eventCenterModalFilterProcessed" />
            </button>
            <div styleName="settings-trigger" onClick={this.navigateToSettings}>
              <i className="fa fa-cog" />
            </div>
          </div>
          <div styleName="events-modal__body">
            <InfiniteScroll
              scrollEnd={this.onScrollEndHandler}
              scrollTop={(scrollTop) => (this.onScrollToTop = scrollTop)}
            >
              {this.props.currentFilter !==
                EventNotificationsFilter.Processed &&
                this.props.notifications.length > 3 && (
                  <div styleName="process-all" onClick={this.props.processAll}>
                    <div styleName="inner">
                      <ResourceText resourceKey="eventCenterProcessAllNotifications" />
                    </div>
                  </div>
                )}

              {this.props.notifications.map((notification) => (
                <ErrorBoundary key={notification.id}>
                  <ModalMessage
                    message={notification}
                    canSendEmail={this.props.canSendEmail}
                    currentOfficeName={this.props.currentOfficeName}
                    features={this.props.features}
                    navigate={this.onNavigateHandler}
                    archive={this.props.archive}
                    unArchive={this.props.unArchive}
                    onLinkClicked={() => this.toggleModalVisibility(false)}
                    onEmail={this.onEmailHandler}
                    createNewRelation={this.onCreateNewRelationEntity}
                    createNewTask={this.onCreateNewTaskEntity}
                    createNewAppointment={this.onCreateNewAppointmentEntity}
                    openDraft={this.openDraft}
                    onCheckRelationForDuplicates={
                      this.onCheckRelationForDuplicates
                    }
                    onUpdateLinkedRelation={this.onUpdateLinkedRelation}
                  />
                </ErrorBoundary>
              ))}

              {this.props.notifications.length === 0 &&
                this.props.notificationsStatus === REQUEST.SUCCESS && (
                  <Ui.EmptyState
                    type={UiEmptyStateType.List}
                    stickman={UiEmptyStateStickMan.NoTasks}
                    title={`emptyStateEventNotifications.${this.props.currentFilter.toString()}`}
                  />
                )}

              {this.props.notificationsStatus === REQUEST.PENDING && (
                <Ui.Loaders.List />
              )}
            </InfiniteScroll>
          </div>
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
          onNewTask={this.onNewTaskHandler}
          onNewAppoinment={this.onNewAppointmentHandler}
        />

        <DuplicatesModal
          visible={this.state.duplicatesModalVisible}
          view={this.state.duplicatesModalView}
          relations={this.state.duplicatesModalRelations}
          relationData={this.state.duplicatesModalRelationData}
          linkedMessageId={this.state.duplicatesModalLinkedMessageId}
          onClose={this.onCloseDuplicatesModal}
          onCreateNewRelation={this.onCreateNewRelationEntity}
        />
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutsideHandler, true);
  }

  private toggleModalVisibility(visible: boolean) {
    this.setState({
      visible,
    });

    if (!visible) return;
    this.props.fetchNotificationsIfNeeded();
  }

  private toggleModalVisibilityClick() {
    this.toggleModalVisibility(!this.state.visible);
  }

  private bindWrapperRef(ref: HTMLDivElement) {
    if (!this.wrapper && !!ref) {
      this.wrapper = ref;
    }
  }

  private onClickOutsideHandler(event: any) {
    if (!this.wrapper) return;
    const clickedInside = this.wrapper.contains(event.target);
    if (!clickedInside && !!this.state.visible) {
      this.toggleModalVisibility(false);
    }
  }

  private onScrollEndHandler() {
    if (
      this.props.notificationsStatus === REQUEST.PENDING ||
      !this.props.canLoadMore
    )
      return;
    this.props.getNotifications();
  }

  private navigateToSettings() {
    this.props.navigate(SETTINGSROUTES.EVENTCENTER.URI);
    this.toggleModalVisibility(false);
  }

  private onNavigateHandler(path: string) {
    this.setState({
      newEntityVisible: false,
    });
    this.props.navigate(path);
    this.toggleModalVisibility(false);
  }

  private onEmailHandler(
    emails: string | string[],
    linkedRelations?: LinkedRelation[],
    linkedAssigmnents?: LinkedAssignment[]
  ) {
    const mappedEmails = typeof emails === "string" ? [emails] : emails;
    this.props.createNewEmail(mappedEmails, linkedRelations, linkedAssigmnents);
    this.toggleModalVisibility(false);
  }

  private onCreateNewRelationEntity(
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType
  ) {
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
          email,
          telephone,
          mobilePhone,
          gender,
        },
      },
    });
    this.toggleModalVisibility(false);
    this.onCloseDuplicatesModal();
  }

  private onCreateNewTaskEntity(
    subject: string,
    linkedAssignments?: LinkedAssignment[],
    linkedRelations?: LinkedRelation[]
  ) {
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Task,
        newTask: {
          subject,
          linkedAssignments,
          linkedRelations,
        },
      },
    });
    this.toggleModalVisibility(false);
  }

  private onCreateNewAppointmentEntity(
    subject: string,
    linkedAssignments?: LinkedAssignment[],
    linkedRelations?: LinkedRelation[]
  ) {
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Appointment,
        newAppointment: {
          linkedAssignments,
          linkedRelations,
        },
      },
    });
    this.toggleModalVisibility(false);
  }

  private openDraft(id: string, accountId: string) {
    this.props.readDraft(id, accountId);
    this.toggleModalVisibility(false);
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    const { id } = relation;
    this.onNavigateHandler(
      route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id })
    );
  }

  private onNewTaskHandler(task: Task) {
    this.onNavigateHandler(MAINROUTES.TASKS.URI);
  }

  private onNewAppointmentHandler(appointment: AgendaItem) {
    this.onNavigateHandler(MAINROUTES.SCHEDULER.URI);
  }

  private setNotificationsFilter(filter: EventNotificationsFilter) {
    this.props.setNotificationsFilter(filter);
    if (typeof this.onScrollToTop === "function") this.onScrollToTop();
  }

  private async onCheckRelationForDuplicates(
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType,
    duplicatesModalLinkedMessageId?: string
  ) {
    if (!email) {
      this.onCreateNewRelationEntity(
        name,
        email,
        telephone,
        mobilePhone,
        gender
      );
      return;
    }

    const duplicatesModalRelations =
      await this.props.getRelationsWithMatchingEmailAddress(email);

    if (!duplicatesModalRelations || !duplicatesModalRelations.length) {
      this.onCreateNewRelationEntity(
        name,
        email,
        telephone,
        mobilePhone,
        gender
      );
      return;
    }

    this.toggleModalVisibility(false);
    this.setState({
      duplicatesModalRelations,
      duplicatesModalView: "duplicates",
      duplicatesModalVisible: true,
      duplicatesModalRelationData: {
        name,
        email,
        telephone,
        mobilePhone,
        gender,
      },
      duplicatesModalLinkedMessageId,
    });
  }

  private onCloseDuplicatesModal() {
    this.setState({
      duplicatesModalRelations: [],
      duplicatesModalView: null,
      duplicatesModalVisible: false,
      duplicatesModalRelationData: {},
      duplicatesModalLinkedMessageId: null,
    });
  }

  private onUpdateLinkedRelation(
    name?: string,
    email?: string,
    telephone?: string,
    mobilePhone?: string,
    gender?: GenderType,
    linkedMessageId?: string
  ) {
    this.toggleModalVisibility(false);
    this.setState({
      duplicatesModalRelations: [],
      duplicatesModalView: "other",
      duplicatesModalVisible: true,
      duplicatesModalRelationData: {
        name,
        email,
        telephone,
        mobilePhone,
        gender,
      },
      duplicatesModalLinkedMessageId: linkedMessageId,
    });
  }
}
