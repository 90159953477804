import * as React from "react";
import {
  CommunicationLog,
  CommunicationLogDirection,
  RelationType,
} from "@haywork/api/kolibri";
import * as CSSModules from "react-css-modules";
import head from "lodash-es/head";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  log: CommunicationLog;
  onNavigate: (id: string, typeOfRelation: RelationType) => void;
}

export const VoipInfoModalVisualComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(({ log, onNavigate }: Props) => {
    if (
      !log.linkedCreatedBy &&
      (!log.linkedRelations || !log.linkedRelations.length)
    )
      return null;

    const employee = !!(log.linkedEmployees || []).length
      ? head(log.linkedEmployees)
      : log.linkedCreatedBy;

    const {
      avatarUrl: employeeAvatar,
      letterAvatar: employeeLetter,
      displayName: employeeName,
    } = employee;
    const {
      avatarUrl: userAvatar,
      letterAvatar: userLetter,
      displayName: userName,
      id: userId,
      typeOfRelation: userType,
    } = head(
      log.linkedRelations || [
        {
          avatarUrl: "",
          letterAvatar: "",
          displayName: "",
          id: "",
          typeOfRelation: RelationType.ContactPerson,
        },
      ]
    );
    const direction = log.direction || CommunicationLogDirection.Incoming;
    const directionArrowStyle = classNames(
      "fal",
      direction === CommunicationLogDirection.Outgoing
        ? "fa-arrow-left"
        : "fa-arrow-right"
    );

    return (
      <div styleName="visual">
        <div styleName="avatars">
          <div
            styleName="avatar"
            style={{
              backgroundImage: !!userAvatar
                ? `url(${JSON.stringify(userAvatar)})`
                : null,
            }}
          >
            {!userAvatar && !!userLetter && (
              <div styleName="avatar__letter">{userLetter}</div>
            )}
            {!userAvatar && !userLetter && (
              <div styleName="avatar__icon">
                <i className="fal fa-user" />
              </div>
            )}
          </div>

          <div styleName="direction">
            <i className={directionArrowStyle} styleName="arrow first" />
            <i className="fa fa-phone" />
            <i className={directionArrowStyle} styleName="arrow last" />
          </div>

          <div
            styleName="avatar"
            style={{
              backgroundImage: !!employeeAvatar
                ? `url(${JSON.stringify(employeeAvatar)})`
                : null,
            }}
          >
            {!employeeAvatar && !!employeeLetter && (
              <div styleName="avatar__letter">{employeeLetter}</div>
            )}
            {!employeeAvatar && !employeeLetter && (
              <div styleName="avatar__icon">
                <i className="fal fa-user" />
              </div>
            )}
          </div>
        </div>
        {(!!userName || !!employeeName) && (
          <div styleName="names">
            <div styleName="name" onClick={() => onNavigate(userId, userType)}>
              {userName}
            </div>
            <div
              styleName="name"
              onClick={() =>
                onNavigate(log.linkedCreatedBy.id, RelationType.Employee)
              }
            >
              {employeeName}
            </div>
          </div>
        )}
      </div>
    );
  })
);
