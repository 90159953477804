import * as React from "react";
import first from "lodash-es/first";
import * as CSSModules from "react-css-modules";
import { Event, LinkedObjectAssignment } from "@haywork/api/event-center";
import { ResourceText, Hint } from "@haywork/modules/shared";
import * as moment from "moment";
import { LinkedAssignment, AssignmentType } from "@haywork/api/kolibri";

const styles = require("./assignments-need-attention.component.scss");

interface Props {
  event: Event;
  assignments: LinkedObjectAssignment[];
  onClick: (id: string) => void;
  onArchive: (id: string) => void;
  onCreateTask: (assignment: LinkedAssignment, date?: Date) => void;
}

export const Assignment = CSSModules(styles, { allowMultiple: true })(
  (props: Props) => {
    const assignment = first(props.assignments);
    const hasImage = !!assignment.photoUrl && assignment.photoUrl !== "";
    const photoStyles = !!hasImage
      ? { backgroundImage: `url(${JSON.stringify(assignment.photoUrl)})` }
      : null;
    const { eventType, eventDateTime, id } = props.event;
    const date = moment(eventDateTime).format("DD-MM-YYYY");

    const archiveEvent = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      props.onArchive(id);
    };

    const createTask = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      props.onCreateTask(
        {
          id: assignment.id,
          displayName: assignment.displayName,
          typeOfAssignment: AssignmentType.Object
        },
        eventDateTime
      );
    };

    return (
      <div styleName="assignment" onClick={() => props.onClick(assignment.id)}>
        <div styleName="assignmment__avatar" style={photoStyles}>
          {!hasImage && (
            <div styleName="icon">
              <i className="fal fa-camera-retro" />
            </div>
          )}
        </div>
        <div styleName="assignment__meta">
          <h2>{assignment.displayName}</h2>
          <h3>
            <ResourceText
              masterKey="datedEventTypes"
              resourceKey={eventType.toString()}
              values={{ date }}
            />
          </h3>
        </div>
        <div styleName="assignment__actions">
          <button
            type="button"
            className="btn btn-primary squeeze"
            onClick={createTask}
          >
            <ResourceText resourceKey="dashboardWidget.action.assignmentsNeedAttention.addTask" />
          </button>

          <Hint message="processEvent" forceInline={true}>
            <div styleName="action" onClick={archiveEvent}>
              <i className="fal fa-clipboard-check" />
            </div>
          </Hint>
        </div>
      </div>
    );
  }
);
