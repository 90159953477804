import List from "./list";
import Single from "./single";
import SearchLocations from "./search-locations";
import RealEstateAgencies from "./real-estate-agency";

export const MLSThunk = {
  List,
  RealEstateAgencies,
  SearchLocations,
  Single,
};
