import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import { TimelineEvent } from "@haywork/api/kolibri";
import Icon from "@haywork/components/ui/icon";
import LinkedEntities, {
  LinkedEntity
} from "@haywork/components/ui/linked-entities";
import { Colors } from "@haywork/enum/colors";
import I18n from "@haywork/components/i18n";
import * as moment from "moment";

const styles = require("./style.scss");

type Props = {
  event: TimelineEvent;
  parentId: string;
  onNavigate: (path: string) => void;
  onShowAllLinkedEntities: (linkedEntities: LinkedEntity[]) => void;
};

const SearchprofileItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ event, parentId, onNavigate, onShowAllLinkedEntities }) => {
      const { subject, linkedAssignments, linkedRelations, date } = event;
      const dateField = useMemo(() => {
        const sendDate = !!date ? moment(date) : null;
        return !date ? null : (
          <div styleName="date">
            <I18n
              value="timeline.sentOn"
              values={{
                date: sendDate.format("DD MMMM YYYY"),
                time: sendDate.format("HH:mm")
              }}
            />
          </div>
        );
      }, []);

      return (
        <div styleName="event__item">
          <div styleName="event__icon">
            <Icon name="search" light size={24} color={Colors.Gray} />
          </div>
          <div styleName="event__divider"></div>
          <div styleName="event__data">
            <div styleName="subject">
              {subject || <I18n value="timeline.missingSubject" />}
            </div>
            <LinkedEntities
              linkedAssignments={linkedAssignments}
              linkedRelations={linkedRelations}
              parentId={parentId}
              onNavigate={onNavigate}
              onShowAllLinkedEntities={onShowAllLinkedEntities}
            />
          </div>
          <div styleName="event__date">{dateField}</div>
          <div styleName="event__actions"></div>
        </div>
      );
    }
  )
);

export default SearchprofileItemComponent;
