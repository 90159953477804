import {
  AssignmentType,
  LinkedAssignment,
  RelationType,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import FormattedText from "@haywork/components/ui/formatted-text";
import {
  ACQUISITIONOBJECTROUTES,
  ACQUISITIONROUTES,
  ASSIGNMENTROUTES,
  OBJECTTYPESROUTES,
  PROJECTROUTES,
  RELATIONROUTES,
} from "@haywork/constants";
import { MailTo } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import * as CSSModules from "react-css-modules";
import { RelationInfoContainerProps } from "./relation-info.container";
import { Colors } from "@haywork/enum/colors";
import Pill from "@haywork/components/ui/pill";
import head from "lodash-es/head";
import { ExtendedRelationSnapShot } from "@haywork/middleware/thunk";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

export interface RelationInfoComponentProps {
  item: ExtendedRelationSnapShot;
  visible: boolean;
  emailCallback?: (relation: RelationSnapShot) => void;
}
type Props = RelationInfoComponentProps & RelationInfoContainerProps;

export const RelationInfoComponent = React.memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ item, navigate, extendRelationInfo, emailCallback, visible }: Props) => {
      const [stateItem, setStateItem] = useState(item);

      const assignmentClick = useCallback(
        (item: LinkedAssignment) => {
          const { id, typeOfAssignment } = item;

          let path = "";
          switch (typeOfAssignment) {
            case AssignmentType.Acquisition: {
              path = route(ACQUISITIONROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.AcquisitionObject: {
              path = route(ACQUISITIONOBJECTROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.ObjectType: {
              path = route(OBJECTTYPESROUTES.DETAIL.URI, { id });
              break;
            }
            case AssignmentType.Project: {
              path = route(PROJECTROUTES.DETAIL.URI, { id });
              break;
            }
            default: {
              path = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
              break;
            }
          }

          navigate(path);
        },
        [navigate]
      );

      const wrapAddress = useCallback((address: string) => {
        if (!address) return false;
        const addressArray = address.split(",");
        const wrappedStrings = addressArray.map((str) => `<span>${str}</span>`);
        const addressString = wrappedStrings.join("");
        return addressString;
      }, []);

      const extendRelationInfoCallback = useCallback(
        async (id) => {
          try {
            if (!stateItem.id || stateItem.extended) return;

            const results = await extendRelationInfo([id]);
            const result = head(results);
            if (!result) {
              setStateItem((item) => ({ ...item, extended: true }));
            } else {
              setStateItem((item) => ({ ...item, ...result }));
            }
          } catch (error) {
            throw error;
          }
        },
        [extendRelationInfo, setStateItem, stateItem]
      );

      useEffect(() => {
        if (!visible) return;
        extendRelationInfoCallback(item.id);
      }, [extendRelationInfoCallback, visible, item]);

      useEffect(() => {
        setStateItem((previousState) => {
          return { ...item, extended: previousState.extended };
        });
      }, [item]);

      return (
        <div styleName="relation-info">
          <div styleName="header">
            <div styleName="name">
              <FormattedText
                value={stateItem.displayName}
                bold
                addStyle={{ fontSize: 14 }}
                skipTranslation
              />
            </div>
            {stateItem.typeOfRelation && (
              <Pill
                label={stateItem.typeOfRelation.toString()}
                labelPrefix="relationTypes"
                color={
                  !stateItem.isActive
                    ? Colors.Gray
                    : stateItem.typeOfRelation === RelationType.ContactCompany
                    ? Colors.Secondary
                    : Colors.Primary
                }
                tiny
              />
            )}
            <div styleName="contact-data">
              {stateItem.phoneNumber && (
                <span>
                  <a className="as-link" href={`tel:${stateItem.phoneNumber}`}>
                    {stateItem.phoneNumber}
                  </a>
                </span>
              )}
              {stateItem.mobileNumber && (
                <span>
                  <a className="as-link" href={`tel:${stateItem.mobileNumber}`}>
                    {stateItem.mobileNumber}
                  </a>
                </span>
              )}
              {stateItem.email && !emailCallback && (
                <span>{<MailTo email={stateItem.email} />}</span>
              )}
              {stateItem.email && emailCallback && (
                <span
                  className="as-link"
                  onClick={() => emailCallback(stateItem)}
                >
                  {stateItem.email}
                </span>
              )}
            </div>
          </div>
          <div styleName="body">
            {stateItem.streetNameAndNumber && (
              <div styleName="address">
                <FormattedText
                  value="address"
                  addStyle={{ display: "inline" }}
                />
                <span>{stateItem.streetNameAndNumber}</span>
                <span>{stateItem.postalCode}</span>
                <span>{stateItem.locality}</span>
              </div>
            )}
            {stateItem.futureStreetNameAndNumber && (
              <div styleName="address">
                <FormattedText
                  value="futureAddress"
                  addStyle={{ display: "inline" }}
                />
                <span>{stateItem.futureStreetNameAndNumber}</span>
                <span>{stateItem.futurePostalCode}</span>
                <span>{stateItem.futureLocality}</span>
              </div>
            )}
            {stateItem.linkedPartner && stateItem.linkedPartner.id && (
              <div styleName="partner">
                <FormattedText
                  value="partner"
                  addStyle={{ display: "inline" }}
                />
                <span>
                  <a
                    onClick={() =>
                      navigate(
                        route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
                          id: stateItem.linkedPartner.id,
                        })
                      )
                    }
                  >
                    {stateItem.linkedPartner.displayName}
                  </a>
                </span>
              </div>
            )}
            {stateItem.linkedVendorAssignments &&
              stateItem.linkedVendorAssignments.length > 0 && (
                <div styleName="linkedItems">
                  {" "}
                  {stateItem.linkedVendorAssignments.map((linkedVendorItem) => {
                    if (
                      !linkedVendorItem.displayName &&
                      !linkedVendorItem.publicReference
                    )
                      return;
                    return (
                      <div key={linkedVendorItem.id}>
                        <FormattedText
                          value={
                            linkedVendorItem.forSale
                              ? "linkedVendorAssignment"
                              : "linkedVendorAssignmentRent"
                          }
                          addStyle={{ display: "inline" }}
                        />
                        <span styleName="linkedAssignment">
                          <a
                            onClick={() => assignmentClick(linkedVendorItem)}
                            dangerouslySetInnerHTML={{
                              __html:
                                wrapAddress(linkedVendorItem.displayName) ||
                                linkedVendorItem.publicReference,
                            }}
                          ></a>
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            {stateItem.linkedApplicantAssignments &&
              stateItem.linkedApplicantAssignments.length > 0 && (
                <div styleName="linkedItems">
                  {" "}
                  {stateItem.linkedApplicantAssignments.map(
                    (linkedApplicantItem) => {
                      if (
                        !linkedApplicantItem.displayName &&
                        !linkedApplicantItem.publicReference
                      )
                        return;
                      return (
                        <div key={linkedApplicantItem.id}>
                          <FormattedText
                            value={
                              linkedApplicantItem.forSale
                                ? "linkedApplicantAssignment"
                                : "linkedApplicantAssignmentRent"
                            }
                            addStyle={{ display: "inline" }}
                          />
                          <span styleName="linkedAssignment">
                            <a
                              onClick={() => assignmentClick(item)}
                              dangerouslySetInnerHTML={{
                                __html:
                                  wrapAddress(
                                    linkedApplicantItem.displayName
                                  ) || linkedApplicantItem.publicReference,
                              }}
                            ></a>
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
          </div>
        </div>
      );
    }
  )
);
