import { MatchedPropertySnapShot, OriginType } from "@haywork/api/kolibri";
import { intlContext } from "@haywork/app";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./list-item.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentMatchingPropertiesModalItemComponentProps {
  matchingProperty: MatchedPropertySnapShot;
  zebra: boolean;
  onNavigateToProperty: (url: string) => void;
}
interface SearchAssignmentMatchingPropertiesModalItemComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentMatchingPropertiesModalItemComponent extends React.Component<
  SearchAssignmentMatchingPropertiesModalItemComponentProps,
  SearchAssignmentMatchingPropertiesModalItemComponentState
> {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    const { photo1Url, listingType, displayName, originType } =
      this.props.matchingProperty;
    const itemStyle = classNames("item", { zebra: this.props.zebra });
    const hasThumb = !!photo1Url && photo1Url !== "";
    const thumbStyle = classNames("item__thumb", { empty: !hasThumb });
    const thumbStyles = hasThumb
      ? { backgroundImage: `url(${this.getPhotoUrl(photo1Url)})` }
      : null;

    return (
      <div styleName={itemStyle} onClick={this.onClickHandler}>
        <div styleName={thumbStyle} style={thumbStyles}>
          {!hasThumb && <i className="fal fa-fw fa-camera-retro fa-2x" />}
        </div>
        <div styleName="item__meta">
          {!!listingType && (
            <h2>
              <ResourceText
                masterKey="listingTypes"
                resourceKey={listingType.toString()}
              />
            </h2>
          )}
          <div>{displayName}</div>
          {originType === OriginType.Exchange && (
            <div styleName="origin">
              <ResourceText resourceKey="originExchange" />
            </div>
          )}
        </div>
        <div styleName="item__price">{this.renderPrice()}</div>
      </div>
    );
  }

  private onClickHandler() {
    const { id, originType } = this.props.matchingProperty;
    if (originType === OriginType.Exchange) return;

    const url = route(ASSIGNMENTROUTES.DETAIL.URI, { id });

    this.props.onNavigateToProperty(url);
  }

  private renderPrice(): React.ReactElement<HTMLSpanElement> {
    const { forSale, rentPrice, salePrice, saleCondition } =
      this.props.matchingProperty;

    if (!forSale) {
      const price = intlContext.formatNumber(rentPrice, {
        style: "currency",
        currency: "EUR",
      });
      return (
        <ResourceText
          resourceKey="matchingPropertyRentPrice"
          values={{ price }}
          asHtml
        />
      );
    }

    const price = intlContext.formatNumber(salePrice, {
      style: "currency",
      currency: "EUR",
    });
    return (
      <span>
        <ResourceText
          resourceKey="matchingPropertySalePrice"
          values={{ price }}
          asHtml
        />{" "}
        {!!saleCondition && (
          <ResourceText
            masterKey="saleConditions"
            resourceKey={saleCondition.toString()}
          />
        )}
      </span>
    );
  }

  private getPhotoUrl(photoUrl: any): string {
    let photoString = JSON.stringify(photoUrl);
    const sizeMatch = /beeld_thumb/g;
    const httpMatch = /http:/g;

    if (!!sizeMatch.test(photoString)) {
      photoString = photoString.replace("beeld_thumb", "beeld_medium");
    }

    if (!!httpMatch.test(photoString)) {
      photoString = photoString.replace("http", "https");
    }

    return photoString;
  }
}
