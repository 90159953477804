import { Language, ObjectAssignment } from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import { AssignmentEditMarketingTextComponent } from "@haywork/modules/assignment";
import {
  AppState,
  EditableActions,
  SingleAssignmentState
} from "@haywork/stores";
import { connect } from "react-redux";

export interface AssignmentEditMarketingTextContainerProps {
  objectAssignment: ObjectAssignment;
  currentComponentState: SingleAssignmentState;
  languages: Language[];
  defaultLanguage: Language;

  updateAssignment: (
    componentState: SingleAssignmentState,
    path: string
  ) => void;
}

const mapStateToProps = (state: AppState) => {
  const { currentComponentState } = state.editable;

  return {
    objectAssignment: currentComponentState.objectAssignment,
    currentComponentState,
    languages: state.company.settings.enabledLanguages,
    defaultLanguage: state.company.settings.defaultLanguage
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  updateAssignment: (componentState: SingleAssignmentState, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path
      })
    )
});
export const AssignmentEditMarketingTextContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentEditMarketingTextComponent);
