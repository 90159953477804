import * as React from "react";
import { FC, memo, useMemo, useCallback } from "react";
import * as CSSModules from "react-css-modules";
import { LinkedRelation, RelationType } from "@haywork/api/kolibri";
import Pill from "@haywork/components/ui/pill";
import { Colors } from "@haywork/enum/colors";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES
} from "@haywork/constants";

const route = RouteUtil.mapStaticRouteValues;

type Props = {
  relation: LinkedRelation;
  onNavigate: (path: string) => void;
};

export const LinkedRelationComponent: FC<Props> = memo(
  ({ relation, onNavigate }) => {
    const { displayName, id, typeOfRelation } = relation;

    const color = useMemo(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          return Colors.Secondary;
        }
        default:
          return Colors.Primary;
      }
    }, [typeOfRelation]);

    const icon = useMemo(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          return "building";
        }
        default:
          return "user";
      }
    }, [typeOfRelation]);

    const handleNavigate = useCallback(() => {
      switch (typeOfRelation) {
        case RelationType.ContactCompany: {
          onNavigate(route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, { id }));
          return;
        }
        case RelationType.ContactPerson: {
          onNavigate(route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, { id }));
          return;
        }
        case RelationType.Employee: {
          onNavigate(route(EMPLOYEEROUTES.EMPLOYEE.URI, { id }));
          return;
        }
        case RelationType.Office: {
          onNavigate(route(OFFICESROUTES.OFFICE_DETAIL.URI, { id }));
          return;
        }
        default:
          return;
      }
    }, [id, typeOfRelation]);

    return (
      <Pill
        label={displayName}
        color={color}
        icon={icon}
        onClick={handleNavigate}
      />
    );
  }
);
