import { createSelector } from "reselect";
import { AppState } from "@haywork/stores";
import { Peculiarity, RealEstateGroup } from "@haywork/api/kolibri";

const peculiaritiesSelector = (state: AppState) =>
  state.main.mastertable.kolibri.peculiarityOptions;
const withdrawReasonsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.withdrawReasonOptions;
const realEstateGroupsSelector = (state: AppState) =>
  state.main.mastertable.kolibri.realEstateGroups;
const enabledRealEstateGroupsSelector = (state: AppState) =>
  state.company.settings.enabledRealEstateGroups || [];
const editableStatesSelector = (state: AppState) => state.editable.states || [];

export const accessibilityOptions = createSelector(
  peculiaritiesSelector,
  (peculiarities) => {
    const validOptions = [
      Peculiarity.AccessibleForTheElderly,
      Peculiarity.AccessibleToDisabledPeople,
    ];
    return peculiarities.filter(
      (peculiarity) => validOptions.indexOf(peculiarity.value) !== -1
    );
  }
);

export const occupancyOptions = (forRent?: boolean) =>
  createSelector(peculiaritiesSelector, (peculiarities) => {
    const validOptions = [
      Peculiarity.DoubleOccupancyAvailable,
      Peculiarity.DoubleOccupancyPossible,
    ];

    if (!!forRent) {
      validOptions.push(Peculiarity.PartiallyLeased);
    }

    return peculiarities.filter(
      (peculiarity) => validOptions.indexOf(peculiarity.value) !== -1
    );
  });

export const distinguishablesOptions = createSelector(
  peculiaritiesSelector,
  (peculiarities) => {
    const validOptions = [
      Peculiarity.MonumentalBuilding,
      Peculiarity.NeedsRenovation,
      Peculiarity.DemolitionHouse,
      Peculiarity.Heritage,
    ];
    return peculiarities.filter(
      (peculiarity) => validOptions.indexOf(peculiarity.value) !== -1
    );
  }
);

export const withdrawReasonOptions = (realEstateGroup: RealEstateGroup) =>
  createSelector(withdrawReasonsSelector, (reasons) => {
    return reasons.filter((reason) =>
      (reason.realEstateGroups || []).includes(realEstateGroup)
    );
  });

export const realEstateGroups = createSelector(
  realEstateGroupsSelector,
  enabledRealEstateGroupsSelector,
  (realEstateGroups, enabledRealEstateGroups) => {
    if (!enabledRealEstateGroups.length) return realEstateGroups;
    return realEstateGroups.filter((group) =>
      enabledRealEstateGroups.includes(group.value)
    );
  }
);

export const hasChanges = createSelector(editableStatesSelector, (states) => {
  let hasChanges = false;

  states.map((state) => {
    if (state.active) {
      hasChanges = state.hasChanges;
    }
  });

  return hasChanges;
});
