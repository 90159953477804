import { EntityDetails, RootEntityType } from "@haywork/api/event-center";
import {
  AcquisitionAssignment,
  AcquisitionAssignmentsClient,
  ActiveFilter,
  AssignmentOrderByField,
  AssignmentsClient,
  AssignmentType,
  EmployeeRole,
  LinkedSearchAssignment,
  RealEstateGroup,
  SearchAssignmentsClient,
  SortOrder,
} from "@haywork/api/kolibri";
import { OrderBy, RealEstatePropertyClient } from "@haywork/api/mls";
import { ACQUISITIONROUTES, MAINROUTES, REQUEST } from "@haywork/constants";
import { mapAcquisitionAssignmentToAssignmentSnapshot } from "@haywork/mappers/acquisition-assignment";
import { EditableThunks } from "@haywork/middleware";
import { ApiType, ParseRequest } from "@haywork/services";
import {
  AppState,
  EditableActions,
  EditableItem,
  LayoutActions,
} from "@haywork/stores";
import { AcquisitionActions } from "@haywork/stores/acquisition";
import { SnackbarActions } from "@haywork/stores/snackbar-v2";
import { BackOfficeUtil, DateUtil, MlsUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import first from "lodash-es/first";
import get from "lodash-es/get";
import * as moment from "moment";
import { Dispatch } from "../";

const parseRequest = new ParseRequest();
const route = RouteUtil.mapStaticRouteValues;

const getAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.PENDING));

      const acquisition = await parseRequest.response(
        AcquisitionAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionAssignment
        )
      );
      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });

      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.ACQUISITIONS.ICON,
          componentState: acquisition,
          path,
          title: acquisition.publicReference || "...",
          entityType: RootEntityType.AcquisitionAssignment,
          entityId: acquisition.id,
          confirm: {
            title: { key: "acquisition.saveConfirm.title" },
            body: { key: "acquisition.saveConfirm.body" },
          },
        })
      );

      dispatch(AcquisitionActions.Single.setAcquisition(acquisition));
    } catch (error) {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getAcquisitions = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { filters } = state.acquisition.list;

    const Assignments = new AssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.List.setAssignmentsStatus(REQUEST.PENDING));

      const {
        filterByRealEstateGroups,
        kindOfAssignment,
        assignmentPhases,
        employeeIds,
        officeIds,
      } = filters;
      const forRent = kindOfAssignment.includes("rentLease");
      const forSale = kindOfAssignment.includes("sale");

      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent,
            forSale,
            includeStatistics: false,
            orderBy: AssignmentOrderByField.ActivityAndDateTimeModified,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Descending,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByRealEstateGroups,
            assignmentPhases,
            employeeIds,
            officeIds,
            filterByAssignmentTypes: [AssignmentType.Acquisition],
          },
          realEstateAgencyId
        )
      );

      const { results, totalResults } = response;
      dispatch(
        AcquisitionActions.List.setAssignments(
          results || [],
          totalResults,
          startIndex === 0
        )
      );
    } catch (error) {
      dispatch(AcquisitionActions.List.setAssignmentsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const getList = (startIndex: number, stopIndex: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { filters, order } = state.acquisition.listV2;

    const Assignments = new AssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.List.setAssignmentsStatus(REQUEST.PENDING));

      const {
        realEstateGroups,
        assignmentPhases,
        saleOrRent,
        employees,
        offices,
        archived,
      } = filters;

      const forRent = saleOrRent.value.includes("forRent");
      const forSale = saleOrRent.value.includes("forSale");
      const filterByActive = !archived.value.length
        ? ActiveFilter.ActiveOnly
        : ActiveFilter.InactiveOnly;

      const response = await parseRequest.response(
        Assignments.search(
          {
            forRent,
            forSale,
            includeStatistics: false,
            orderBy:
              order.sortColumn ||
              AssignmentOrderByField.ActivityAndDateTimeModified,
            filterByActive,
            order: order.sortOrder || SortOrder.Descending,
            skip: startIndex,
            take: stopIndex - startIndex,
            filterByRealEstateGroups: realEstateGroups.value,
            assignmentPhases: assignmentPhases.value,
            employeeIds: employees.value,
            officeIds: offices.value,
            filterByAssignmentTypes: [AssignmentType.Acquisition],
          },
          realEstateAgencyId
        )
      );

      const { results, totalResults } = response;
      dispatch(
        AcquisitionActions.List.setAssignments(
          results || [],
          totalResults,
          startIndex === 0
        )
      );
    } catch (error) {
      dispatch(AcquisitionActions.List.setAssignmentsStatus(REQUEST.ERROR));
      throw error;
    }
  };
};

const createAcquisition = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId, role } =
      state.account.currentRealestateAgency;
    const { offices } = state.account;
    const employee = BackOfficeUtil.getEmployee(state.account);

    let linkedOffice;
    if (!!employee.linkedOffices && role === EmployeeRole.Standard) {
      linkedOffice = employee.linkedOffices.find(
        (office) => office.isMainOffice
      );
      if (!linkedOffice) {
        linkedOffice = first(employee.linkedOffices);
      }
    } else {
      linkedOffice = first(offices);
    }

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );

      const acquisition = await parseRequest.response(
        AcquisitionAssignments.defineNew(
          {
            employeeId: employee.id,
            forRent: false,
            forSale: true,
            officeId: linkedOffice.id,
            realEstateGroup: RealEstateGroup.Residential,
          },
          realEstateAgencyId
        ).then((response) => response.acquisitionAssignment)
      );

      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id: acquisition.id });
      const redirect = route(ACQUISITIONROUTES.EDIT.URI, {
        id: acquisition.id,
      });

      dispatch(AcquisitionActions.Single.setAcquisition(acquisition));
      dispatch(
        EditableActions.addState({
          icon: MAINROUTES.ACQUISITIONS.ICON,
          componentState: acquisition,
          path,
          title: "...",
          entityType: RootEntityType.AcquisitionAssignment,
          entityId: acquisition.id,
          confirm: {
            title: { key: "acquisition.saveConfirm.title" },
            body: { key: "acquisition.saveConfirm.body" },
          },
        })
      );
      dispatch(push(redirect));
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const saveAcquisition = (
  acquisitionAssignment: AcquisitionAssignment,
  close: boolean
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.PENDING));
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: true })
      );

      let mappedAcquisitionAssignment = acquisitionAssignment;
      const { forSale, forRent } = mappedAcquisitionAssignment;

      switch (true) {
        case !!forSale && !!forRent:
        default: {
          break;
        }
        case !!forSale: {
          mappedAcquisitionAssignment = {
            ...mappedAcquisitionAssignment,
            commissionGrossRent: null,
            commissionPercentRent: null,
          };
          break;
        }
        case !!forRent: {
          mappedAcquisitionAssignment = {
            ...mappedAcquisitionAssignment,
            commissionGross: null,
            commissionPercent: null,
          };
          break;
        }
      }

      const acquisition = await parseRequest.response(
        AcquisitionAssignments.save(
          { acquisitionAssignment: mappedAcquisitionAssignment },
          realEstateAgencyId
        ).then((response) => response.acquisitionAssignment)
      );

      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id: acquisition.id });

      dispatch(AcquisitionActions.Single.setAcquisition(acquisition));
      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: acquisition,
          ignoreChanges: true,
        })
      );

      if (close) {
        dispatch(push(path));
      }
    } catch (error) {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.ERROR));
      throw error;
    } finally {
      dispatch(
        LayoutActions.setCreateLoaderVisibility({ createLoaderVisible: false })
      );
    }
  };
};

const archiveAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionAssignments.archive({ id }, realEstateAgencyId)
      );
      const acquisition = await parseRequest.response(
        AcquisitionAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionAssignment
        )
      );
      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });

      dispatch(AcquisitionActions.Single.setAcquisition(acquisition));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: acquisition,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unArchiveAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionAssignments.unarchive({ id }, realEstateAgencyId)
      );
      const acquisition = await parseRequest.response(
        AcquisitionAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionAssignment
        )
      );
      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });

      dispatch(AcquisitionActions.Single.setAcquisition(acquisition));
      dispatch(
        EditableActions.updateComponentState({
          ignoreChanges: true,
          path,
          componentState: acquisition,
          hasChanges: false,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const deleteAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      dispatch(AcquisitionActions.Single.setStatus(REQUEST.PENDING));

      await parseRequest.response(
        AcquisitionAssignments.delete(id, realEstateAgencyId)
      );
      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });

      dispatch(EditableThunks.remove(path));
      dispatch(
        SnackbarActions.addToast({
          value: "acquisitionAssignment.toast.deleted",
          callback: () => {
            dispatch(unDeleteAcquisition(id));
          },
          callbackLabel: "acquisitionAssignment.toast.action.undelete",
          icon: "trash-alt",
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

const unDeleteAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      await parseRequest.response(
        AcquisitionAssignments.undelete({ id }, realEstateAgencyId)
      );
      const path = route(ACQUISITIONROUTES.DETAIL.URI, { id });

      dispatch(push(path));
    } catch (error) {
      throw error;
    }
  };
};

const getLinkedAcquisitionAssignmentRelations = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      return await parseRequest.response(
        AcquisitionAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const backToDashboard = () => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { single } = state.acquisition;
    const currentComponentState: AcquisitionAssignment =
      state.editable.currentComponentState;

    if (get(single.acquisition, "id") !== get(currentComponentState, "id"))
      return;

    try {
      const { id } = single.acquisition;
      dispatch(updateLocalState(id, true));
    } catch (error) {
      throw error;
    }
  };
};

const updateAcquisitionListItem = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const { assignments } = state.acquisition.list;
    const ref = (assignments || []).find((assignment) => assignment.id === id);

    if (!ref) return;

    // Change to get
    try {
      const client = new AcquisitionAssignmentsClient(host);
      const assignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response?.acquisitionAssignment)
      );
      if (!assignment) return;

      const snapshot = mapAcquisitionAssignmentToAssignmentSnapshot(assignment);

      dispatch(AcquisitionActions.List.updateListItem(snapshot));
    } catch (error) {
      throw error;
    }
  };
};

const checkExternalChanges = (
  editable: EditableItem,
  entityDetails?: EntityDetails
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (!editable || !entityDetails) return;

    const {
      entityId,
      entityType,
      dateTimeModified: externalDateTimeModified,
    } = entityDetails;
    if (!entityId) return;

    const componentState: AcquisitionAssignment = editable.componentState;
    const { dateTimeModified } = componentState;

    if (DateUtil.sameSecond(externalDateTimeModified, dateTimeModified)) {
      return;
    }

    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new AcquisitionAssignmentsClient(host);

    try {
      const refAssignment = await parseRequest.response(
        client
          .read(entityId, realEstateAgencyId)
          .then((response) => response.acquisitionAssignment),
        ApiType.Kolibri,
        { displayError: false }
      );
      const { hasChanges, active, path } = editable;

      if (!hasChanges) {
        const newComponentState: AcquisitionAssignment = {
          ...componentState,
          ...refAssignment,
        };

        dispatch(
          EditableActions.updateComponentState({
            path,
            componentState: newComponentState,
            ignoreChanges: true,
            hasChanges: false,
          })
        );

        if (active) {
          dispatch(AcquisitionActions.Single.setAcquisition(newComponentState));
        }

        return;
      }

      const { dateTimeModified, linkedModifiedBy } = refAssignment;
      if (!!dateTimeModified && !!linkedModifiedBy) {
        dispatch(
          EditableActions.setHasExternalChanges({
            entityId,
            entityType,
            dateTimeModified: moment.utc(dateTimeModified).toDate(),
            modifiedBy: linkedModifiedBy.displayName,
            updatedEntity: refAssignment,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
};

const reloadAcquisitionAssignment = (editable: EditableItem) => {
  return async (dispatch: Dispatch<any>) => {
    if (
      !editable ||
      !editable.externalChangesData ||
      !editable.externalChangesData.updatedEntity
    )
      return;

    const { entityId, entityType, externalChangesData, active } = editable;
    const acquisitionAssignment =
      externalChangesData.updatedEntity as AcquisitionAssignment;

    dispatch(
      EditableActions.updateComponentState({
        path: editable.path,
        componentState: acquisitionAssignment,
        ignoreChanges: true,
        hasChanges: false,
      })
    );

    if (active) {
      dispatch(AcquisitionActions.Single.setAcquisition(acquisitionAssignment));
    }

    dispatch(
      EditableActions.removeHasExternalChanges({ entityId, entityType })
    );

    return;
  };
};

const updateLocalState = (id: string, navigateToDashboard = false) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { host } = state.appSettings;
    const client = new AcquisitionAssignmentsClient(host);

    try {
      const acquisitionAssignment = await parseRequest.response(
        client
          .read(id, realEstateAgencyId)
          .then((response) => response.acquisitionAssignment)
      );

      const path = route(ACQUISITIONROUTES.DETAIL.URI, {
        id: acquisitionAssignment.id,
      });

      if (navigateToDashboard) {
        dispatch(push(path));
      }

      dispatch(
        EditableActions.updateComponentState({
          path,
          componentState: acquisitionAssignment,
          ignoreChanges: true,
          resetExternalChanges: true,
        })
      );
      dispatch(AcquisitionActions.Single.setAcquisition(acquisitionAssignment));
    } catch (error) {
      throw error;
    }
  };
};

const getLinkedAcquisition = (id: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const AcquisitionAssignments = new AcquisitionAssignmentsClient(host);

    try {
      return await parseRequest.response(
        AcquisitionAssignments.read(id, realEstateAgencyId).then(
          (response) => response.acquisitionAssignment
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getMlsObjectsMatchingSearchAssignments = (
  linkedSearchAssignments: LinkedSearchAssignment[],
  startIndex: number,
  stopIndex: number
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const isActive = state.mls.main?.mlsAccessData?.isActive;
      if (!isActive || !(linkedSearchAssignments || []).length)
        return { items: [], total: 0 };

      const { host, mlsHost } = state.appSettings;
      const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
      const searchAssignmentClient = new SearchAssignmentsClient(host);
      const realEstatePropertyClient = new RealEstatePropertyClient(mlsHost);

      const promises = linkedSearchAssignments.map((searchAssignment) => {
        return searchAssignmentClient
          .read(searchAssignment.id, realEstateAgencyId)
          .then((response) => response.searchAssignment);
      });

      const searchAssignments = await Promise.all(promises);
      if (!searchAssignments.length) return;

      const query =
        MlsUtil.searchAssignmentsToMlsPropertyQuery(searchAssignments);
      const realEstateProperties = await parseRequest.response(
        realEstatePropertyClient.search(
          {
            ...query,
            skip: startIndex,
            take: stopIndex - startIndex,
            sortOrder: SortOrder.Descending,
            orderBy: OrderBy.ModificationDate,
          },
          realEstateAgencyId
        )
      );

      return {
        items: realEstateProperties?.results || [],
        total: realEstateProperties?.totalCount || 0,
      };
    } catch (error) {
      throw error;
    }
  };
};

export const AcquisitionThunk = {
  getAcquisition,
  getAcquisitions,
  getList,
  createAcquisition,
  saveAcquisition,
  archiveAcquisition,
  unArchiveAcquisition,
  deleteAcquisition,
  unDeleteAcquisition,
  getLinkedAcquisitionAssignmentRelations,
  backToDashboard,
  updateAcquisitionListItem,
  checkExternalChanges,
  reloadAcquisitionAssignment,
  getLinkedAcquisition,
  getMlsObjectsMatchingSearchAssignments,
};
