import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./style.scss");

export const UiHightlightFeature = CSSModules(styles, { allowMultiple: true })(
  () => {
    return (
      <div styleName="highlight-feature">
        <i className="fal fa-asterisk" />
        <i className="fal fa-asterisk" />
        <i className="fal fa-asterisk" />
      </div>
    );
  }
);
