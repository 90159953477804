import * as React from "react";
import * as CSSModules from "react-css-modules";

import { Hint, HintAlignment, ResourceText } from "@haywork/modules/shared";
import { ListOfGoodsValueExtended } from "@haywork/stores/dynamic-documents/single";
import { DynamicDocumentsUtil } from "@haywork/util";

const styles = require("./header.component.scss");

interface HeaderComponentProps {
  listOfGoodsValue: ListOfGoodsValueExtended;
  addEmptyValueToListOfGoods: (value: ListOfGoodsValueExtended) => void;
}
interface HeaderComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {
  constructor(props) {
    super(props);

    this.checkForEmptyValue = this.checkForEmptyValue.bind(this);
  }

  public render() {
    return (
      <div className="listOfGoods__header">
        <div className="title">{this.props.listOfGoodsValue.name}</div>
        <div className="takeOverSpecification">
          <Hint message="listOfGoodsStaysBehindMessage" align={HintAlignment.Bottom}>
            <ResourceText resourceKey="listOfGoodsStaysBehind" />
          </Hint>
          <Hint message="listOfGoodsGoesALongMessage" align={HintAlignment.Bottom}>
            <ResourceText resourceKey="listOfGoodsGoesALong" />
          </Hint>
          <Hint message="listOfGoodsForAcquisitionMessage" align={HintAlignment.Bottom}>
            <ResourceText resourceKey="listOfGoodsForAcquisition" />
          </Hint>
          <Hint message="listOfGoodsDoesNotApplyMessage" align={HintAlignment.Bottom}>
            <ResourceText resourceKey="listOfGoodsDoesNotApply" />
          </Hint>
        </div>
        <div className="remove" />
      </div>
    );
  }

  public componentDidMount() {
    this.checkForEmptyValue(this.props.listOfGoodsValue);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: HeaderComponentProps) {
    this.checkForEmptyValue(nextProps.listOfGoodsValue);
  }

  private checkForEmptyValue(listOfGoodsValue: ListOfGoodsValueExtended) {
    const lastSubEntry = DynamicDocumentsUtil.getLastNonCategoryItem(listOfGoodsValue);

    if (!lastSubEntry || lastSubEntry.name) {
      this.props.addEmptyValueToListOfGoods({ ...listOfGoodsValue });
    }
  }
}
