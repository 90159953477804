import {
  AddressDetail,
  Cadastre,
  CompanyListing,
  ObjectAssignment,
  PublicationSnapShot,
  RelationSnapShot,
  TimelineEvent,
  TimelineEventsStatistics,
  TransactionMetaData,
} from "@haywork/api/kolibri";
import { REDUX, REQUEST } from "@haywork/constants";
import { FolderTreeEntityType } from "@haywork/middleware";
import { Action } from "redux";
import * as ActionType from "./single.types";

export interface SingleAssignmentState {
  objectAssignment: ObjectAssignment;
  isInitial: boolean;
  objectAssignmentState: string;
  cadastres: Cadastre[];
  companyListings: CompanyListing[];
  publications: PublicationSnapShot[];
  pastEvents: TimelineEvent[];
  futureEvents: TimelineEvent[];
  sublocalities: AddressDetail[];
  transactionMetaData: TransactionMetaData;
  receivedDataFromMLS: boolean;
  events: TimelineEvent[];
  timelinePage: number;
  timelinePageCount: number;
  timelineStatus: string;
  timelineStatistics: TimelineEventsStatistics;
  publishState: string;
  changeAvailabilityStatus: string;
  showStatusModal: boolean;
  saveAssignmentState: string;
  addressSearchState: string;
  showWithdrawModal: boolean;
  withdrawState: string;
  withdrawError: string[];
  showSaveModal: boolean;
  mediaPartnerState: string;
  mediaPartnerChangedId: string;
  aroCreatedRelation: RelationSnapShot;
  cadastreState: string;
  brochureLink: string;
  brochureStatus: string;
  brochureId: number;
  lastMarketingRoute: string;
}

const INITIAL_STATE: SingleAssignmentState = {
  objectAssignment: null,
  objectAssignmentState: REQUEST.IDLE,
  isInitial: true,
  cadastres: [],
  companyListings: [],
  publications: [],
  pastEvents: [],
  futureEvents: [],
  sublocalities: [],
  transactionMetaData: null,
  receivedDataFromMLS: false,
  events: [],
  timelinePage: 0,
  timelinePageCount: 0,
  timelineStatus: REQUEST.IDLE,
  timelineStatistics: null,
  publishState: REQUEST.IDLE,
  changeAvailabilityStatus: REQUEST.IDLE,
  showStatusModal: false,
  saveAssignmentState: REQUEST.IDLE,
  addressSearchState: REQUEST.IDLE,
  showWithdrawModal: false,
  withdrawState: REQUEST.IDLE,
  withdrawError: [],
  showSaveModal: false,
  mediaPartnerState: "",
  mediaPartnerChangedId: "",
  aroCreatedRelation: null,
  cadastreState: REQUEST.IDLE,
  brochureLink: null,
  brochureStatus: REQUEST.IDLE,
  brochureId: undefined,
  lastMarketingRoute: null,
};

export const singleAssignmentReducer = (
  state: SingleAssignmentState = INITIAL_STATE,
  action: Action
): SingleAssignmentState => {
  switch (action.type) {
    case REDUX.ASSIGNMENT.SET_ASSIGNMENT: {
      const {
        objectAssignment,
        publications,
        cadastres,
        companyListings,
        pastEvents,
        futureEvents,
        isInitial,
        lastMarketingRoute,
        transactionMetaData,
        receivedDataFromMLS,
      } = <ActionType.SingleAssignment>action;

      return {
        ...INITIAL_STATE,
        objectAssignment,
        objectAssignmentState: !!objectAssignment
          ? REQUEST.SUCCESS
          : REQUEST.PENDING,
        cadastres,
        companyListings,
        publications,
        pastEvents,
        futureEvents,
        isInitial,
        lastMarketingRoute,
        transactionMetaData,
        receivedDataFromMLS,
      };
    }
    case REDUX.ASSIGNMENT.UPDATE_SINGLE_ASSIGNMENT: {
      const {
        objectAssignment,
        publications,
        cadastres,
        companyListings,
        pastEvents,
        futureEvents,
        isInitial,
        transactionMetaData,
        receivedDataFromMLS,
      } = <ActionType.SingleAssignment>action;
      return {
        ...state,
        objectAssignment,
        objectAssignmentState: !!objectAssignment
          ? REQUEST.SUCCESS
          : REQUEST.PENDING,
        cadastres,
        companyListings,
        publications,
        pastEvents,
        futureEvents,
        isInitial,
        transactionMetaData,
        receivedDataFromMLS:
          receivedDataFromMLS === undefined
            ? state.receivedDataFromMLS
            : receivedDataFromMLS,
      };
    }
    case REDUX.ASSIGNMENT.SET_ASSIGNMENT_STATUS: {
      const { objectAssignmentState } = <ActionType.AssignmentState>action;

      return { ...state, objectAssignmentState };
    }
    case REDUX.ASSIGNMENT.SET_ADDRESS: {
      const { address } = <ActionType.SingleAddress>action;

      return {
        ...state,
        objectAssignment: { ...state.objectAssignment, address },
      };
    }
    case REDUX.ASSIGNMENT.UPDATE_ASSIGNMENT: {
      const { objectAssignment, transactionMetaData } = <
        ActionType.SingleObjectAssignment
      >action;

      if (!transactionMetaData) {
        return { ...state, objectAssignment };
      } else {
        return { ...state, objectAssignment, transactionMetaData };
      }
    }
    case REDUX.ASSIGNMENT.UPDATE_COMPANY_LISTINGS: {
      const { companyListings } = <ActionType.CompanyListings>action;

      return {
        ...state,
        companyListings,
      };
    }
    case REDUX.ASSIGNMENT.SET_PUBLISH_STATUS: {
      const { publishState } = <ActionType.PublishState>action;

      return { ...state, publishState };
    }
    case REDUX.ASSIGNMENT.SET_CHANGE_AVAILABILITY_STATUS: {
      const { changeAvailabilityStatus } = <
        ActionType.ChangeAvailabilityStatus
      >action;

      return { ...state, changeAvailabilityStatus };
    }
    case REDUX.ASSIGNMENT.TOGGLE_STATUS_MODAL: {
      const { showStatusModal } = <ActionType.StatusModal>action;

      return { ...state, showStatusModal };
    }
    case REDUX.ASSIGNMENT.SET_SAVE_ASSIGNMENTS_STATUS: {
      const { saveAssignmentState } = <ActionType.SaveAssignmentState>action;
      return { ...state, saveAssignmentState };
    }
    case REDUX.ASSIGNMENT.SET_ADDRESS_SEARCH_STATUS: {
      const { addressSearchState } = <ActionType.AddressSearchState>action;
      return { ...state, addressSearchState };
    }
    case REDUX.ASSIGNMENT.TOGGLE_WITHDRAW_MODAL: {
      const { showWithdrawModal } = <ActionType.WithdrawModal>action;
      return { ...state, showWithdrawModal };
    }
    case REDUX.ASSIGNMENT.SET_WITHDRAW_STATE: {
      const { withdrawState } = <ActionType.WithdrawState>action;
      return { ...state, withdrawState };
    }
    case REDUX.ASSIGNMENT.TOGGLE_SAVE_MODAL: {
      const { showSaveModal } = <ActionType.SaveModal>action;
      return { ...state, showSaveModal };
    }
    case REDUX.ASSIGNMENT.SET_TIMELINE_EVENTS: {
      const { response } = <ActionType.Events>action;
      const timelinePageCount = Math.ceil(
        response.totalResults / response.resultCount
      );

      return {
        ...state,
        events: response.results,
        timelineStatistics: response.statistics,
        timelineStatus: REQUEST.IDLE,
        timelinePageCount,
        timelinePage: 1,
      };
    }
    case REDUX.ASSIGNMENT.APPEND_TIMELINE_EVENTS: {
      const { response } = <ActionType.Events>action;

      return {
        ...state,
        events: [...state.events, ...response.results],
        timelineStatistics: response.statistics,
        timelineStatus: REQUEST.IDLE,
        timelinePage: state.timelinePage + 1,
      };
    }
    case REDUX.ASSIGNMENT.SET_TIMELINE_STATUS: {
      const { timelineStatus } = <ActionType.TimelineStatus>action;
      return { ...state, timelineStatus };
    }
    case REDUX.ASSIGNMENT.SET_MEDIAPARTNER_STATE: {
      const { mediaPartnerState, mediaPartnerChangedId } = <
        ActionType.MediaPartnerState
      >action;
      return { ...state, mediaPartnerState, mediaPartnerChangedId };
    }
    case REDUX.ASSIGNMENT.SET_CADASTRE_STATE: {
      const { cadastreState } = <ActionType.CadastreState>action;
      return { ...state, cadastreState };
    }
    case REDUX.ASSIGNMENT.REMOVE_COMPANY_LISTING: {
      const { id } = <ActionType.Id>action;
      const companyListings = state.companyListings.filter((c) => c.id !== id);

      return {
        ...state,
        companyListings,
      };
    }
    case REDUX.ASSIGNMENT.SET_BROCHURE_STATUS: {
      const { brochureStatus, brochureId } = <ActionType.BrochureStatus>action;

      return {
        ...state,
        brochureId,
        brochureStatus,
      };
    }
    case REDUX.ASSIGNMENT.SET_BROCHURE_LINK: {
      const { brochureLink } = <ActionType.BrochureLink>action;

      return {
        ...state,
        brochureLink,
        brochureStatus: REQUEST.SUCCESS,
        brochureId: undefined,
      };
    }
    case REDUX.ASSIGNMENT.SET_LAST_MARKETING_ROUTE: {
      const { lastMarketingRoute } = <ActionType.LastMarketingRoute>action;

      return {
        ...state,
        lastMarketingRoute,
      };
    }
    case REDUX.DOSSIER.SET_ENTITY_FOLDER_TREE_ID: {
      const { id, entityType, entityId } = <any>action;
      if (
        entityType === FolderTreeEntityType.ObjectAssignment &&
        !!state.objectAssignment &&
        state.objectAssignment.id === entityId
      ) {
        return {
          ...state,
          objectAssignment: {
            ...state.objectAssignment,
            linkedFolderTree: {
              id,
            },
          },
        };
      } else {
        return state;
      }
    }
    case REDUX.MAIN.RESET_APP: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
