import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUtil } from "@haywork/util";
import { PROJECTROUTES } from "@haywork/constants";
import { ProjectEditMarketingContainer } from "@haywork/modules/project/containers/edit-marketing.container";
import { ProjectEditProjectContainer } from "@haywork/modules/project";
import { ProjectEditClientContainer } from "@haywork/modules/project/containers/edit-client.container";
import { ProjectPublishContainer } from "@haywork/modules/project/containers/publish.container";

const route = RouteUtil.mapStaticRouteValues;
export const ProjectEditRouting = ({id}) => {
  return <Switch>
    <Route exact path={PROJECTROUTES.EDIT.URI}
           render={() => <Redirect to={route(PROJECTROUTES.EDIT_CLIENT.URI, {id})}/>}/>
    <Route exact path={PROJECTROUTES.EDIT_CLIENT.URI} component={ProjectEditClientContainer}/>
    <Route path={PROJECTROUTES.EDIT_ADDRESS.URI} exact component={ProjectEditProjectContainer}/>
    <Route path={PROJECTROUTES.EDIT_ADDRESS_PARAMS.URI} exact component={ProjectEditProjectContainer}/>
    <Route path={PROJECTROUTES.EDIT_MARKETING.URI} component={ProjectEditMarketingContainer}/>
    <Route path={PROJECTROUTES.PUBLISH.URI} component={ProjectPublishContainer}/>
  </Switch>;
};
