import { AcquisitionObjectAssignment } from "@haywork/api/kolibri";
import { FolderTreeEntityType } from "@haywork/middleware";

export enum assignmentActions {
  SetAcquisitionObjectAssignment = "Acquisition.Assignment.SetAcquisitionAssignmentObject",
  SetStatus = "Acquisition.Assignment.SetStatus",
  SetBrochureStatus = "Acquisition.Assignment.SetBrochureStatus",
  SetBrochureLink = "Acquisition.Assignment.SetBrochureLink",
  SetEntityFolderTreeId = "DOSSIER_SET_ENTITY_FOLDER_TREE_ID"
}

type SetAcquisitionObjectAssignment = {
  type: assignmentActions.SetAcquisitionObjectAssignment;
  acquisitionAssignmentObject: AcquisitionObjectAssignment;
};
const setAcquisitionObjectAssignment = (
  acquisitionAssignmentObject: AcquisitionObjectAssignment
) => ({
  type: assignmentActions.SetAcquisitionObjectAssignment,
  acquisitionAssignmentObject
});

type SetStatus = {
  type: assignmentActions.SetStatus;
  acquisitionAssignmentObjectStatus: string;
};
const setStatus = (acquisitionAssignmentObjectStatus: string) => ({
  type: assignmentActions.SetStatus,
  acquisitionAssignmentObjectStatus
});

type SetBrochureStatus = {
  type: assignmentActions.SetBrochureStatus;
  brochureStatus: string;
  brochureId: number;
};
const setBrochureStatus = (brochureStatus: string, brochureId?: string) => ({
  type: assignmentActions.SetBrochureStatus,
  brochureStatus,
  brochureId
});

type SetBrochureLink = {
  type: assignmentActions.SetBrochureLink;
  brochureLink: string;
};
const setBrochureLink = (brochureLink: string) => ({
  type: assignmentActions.SetBrochureLink,
  brochureLink
});

type SetEntityFolderTreeId = {
  type: assignmentActions.SetEntityFolderTreeId;
  id: string;
  entityType: FolderTreeEntityType;
  entityId: string;
};

export type AssignmentActions =
  | SetAcquisitionObjectAssignment
  | SetStatus
  | SetBrochureStatus
  | SetBrochureLink
  | SetEntityFolderTreeId;
export const Actions = {
  setAcquisitionObjectAssignment,
  setStatus,
  setBrochureStatus,
  setBrochureLink
};
