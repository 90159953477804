import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";

import { TransactionState } from "../transaction-modal.component";
import { ResourceText } from "@haywork/modules/shared";

const styles = require("../transaction-modal.component.scss");

interface Props {
  currentState: TransactionState;
  validStates: TransactionState[];
  forSale: boolean;
  onStateChange: (state: TransactionState) => void;
}

export const Breadcrumbs = CSSModules(styles, { allowMultiple: true })(
  ({ currentState, validStates, onStateChange, forSale }: Props) => {
    const transactionStates = [
      TransactionState.Transaction,
      TransactionState.PersonalData,
      TransactionState.ObjectLeftBehind,
    ];

    return (
      <ul styleName="breadcrumbs">
        {transactionStates.map((state, idx) => (
          <li
            key={idx}
            styleName={classNames("breadcrumb", {
              active: currentState === state,
              valid: validStates.indexOf(state) !== -1,
            })}
            onClick={() => onStateChange(state)}
          >
            <div styleName="number">{idx + 1}</div>
            <ResourceText
              resourceKey={`TransactionState${
                !forSale && state === TransactionState.PersonalData
                  ? ".Rent"
                  : ""
              }.${state.toString()}`}
            />
            <i className="far fa-chevron-right" />
          </li>
        ))}
      </ul>
    );
  }
);
