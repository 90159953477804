import {
  Account,
  Folder,
  FolderCategory,
  ProviderType,
} from "@haywork/api/mail";
import { REQUEST } from "@haywork/constants";
import { DraftType } from "@haywork/enum";
import { EmailMoveModalComponent } from "@haywork/modules/email";
import { ConfirmComponent, Hint, ResourceText } from "@haywork/modules/shared";
import { Print } from "@haywork/services";
import { EmailMessage } from "@haywork/stores/email-v2";
import { AsyncUtil } from "@haywork/util";
import { ExtendedEmailFolder } from "@haywork/util/email";
import classNames from "classnames";
import get from "lodash-es/get";
import * as React from "react";
import * as CSSModules from "react-css-modules";

const styles = require("./header.component.scss");

interface EmailDetailHeaderComponentProps {
  message: EmailMessage;
  messageStatus: string;
  currentAccount: Account;
  currentFolder: ExtendedEmailFolder;
  currentFolderIsArchive: boolean;
  currentFolderIsTrash: boolean;
  toggleBookmarked: (
    accountId: string,
    id: string,
    bookmarked: boolean
  ) => void;
  toggleUnread: (accountId: string, message: EmailMessage) => void;
  moveToFolder: (
    accountId: string,
    message: EmailMessage,
    folder: Folder
  ) => Promise<void> | void;
  onDeleteMessage: () => Promise<void> | void;
  onArchiveMessage: () => Promise<void> | void;
  onMessageToDraft: (
    message: EmailMessage,
    type: DraftType,
    accountId: string,
    folderId: string
  ) => void;
  onCreateNewEmail: () => void;
  onDownloadExternalReferences: (messageId: string) => void;
}
interface State {
  folderMoveModalVisible: boolean;
  downloadExternalReferencesConfirmVisible: boolean;
  currentDraftType: DraftType;
  loading: boolean;
}
type Props = EmailDetailHeaderComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailDetailHeaderComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      folderMoveModalVisible: false,
      loading: false,
      currentDraftType: null,
      downloadExternalReferencesConfirmVisible: false,
    };

    this.onMessageToDraft = this.onMessageToDraft.bind(this);
    this.onBookmarkedClickHandler = this.onBookmarkedClickHandler.bind(this);
    this.onUnreadClickHandler = this.onUnreadClickHandler.bind(this);
    this.onMoveClickHandler = this.onMoveClickHandler.bind(this);
    this.onPrintClickHandler = this.onPrintClickHandler.bind(this);
    this.onArchiveMessageHandler = this.onArchiveMessageHandler.bind(this);
    this.onDeleteMessageHandler = this.onDeleteMessageHandler.bind(this);
    this.onMoveModalCloseHandler = this.onMoveModalCloseHandler.bind(this);
    this.onMoveModalSelectFolder = this.onMoveModalSelectFolder.bind(this);
    this.onConfirmDownloadExternalReferencesHandler =
      this.onConfirmDownloadExternalReferencesHandler.bind(this);
    this.onConfirmDownloadExternalReferencesCloseHandler =
      this.onConfirmDownloadExternalReferencesCloseHandler.bind(this);
  }

  public render() {
    const { message, currentAccount, currentFolder } = this.props;
    const disabled =
      !message ||
      this.state.loading ||
      this.props.messageStatus === REQUEST.PENDING;
    const bookmarkStyle = classNames({ active: !!get(message, "bookmarked") });
    const unreadIconStyle = classNames(
      "fal",
      !message || !!get(message, "unread") ? "fa-envelope" : "fa-envelope-open"
    );
    const showGmailSentFolderRestrictions =
      !!currentAccount &&
      currentAccount.type === ProviderType.Gmail &&
      !!currentFolder &&
      currentFolder.category === FolderCategory.Sent;

    return (
      <div styleName="detail-actions">
        <div styleName="flex">
          <div styleName="left">
            <div styleName="action-group">
              <button
                type="button"
                styleName={bookmarkStyle}
                disabled={disabled}
                onClick={this.onBookmarkedClickHandler}
                data-cy="CY-messageBookmark"
              >
                <Hint
                  message="emailHintBookmark"
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className="fal fa-flag" />
              </button>

              <button
                type="button"
                disabled={disabled}
                onClick={this.onUnreadClickHandler}
                data-cy="CY-messageMarkUnread"
              >
                <Hint
                  message={
                    !!this.props.message && this.props.message.unread
                      ? "emailHintRead"
                      : "emailHintUnread"
                  }
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className={unreadIconStyle} />
              </button>

              <button
                type="button"
                disabled={disabled || showGmailSentFolderRestrictions}
                onClick={this.onMoveClickHandler}
                data-cy="CY-messageMoveToFolder"
              >
                <Hint
                  message={
                    showGmailSentFolderRestrictions
                      ? "emailHint.gmailFolderRestrictions"
                      : "emailHintMoveToFolder"
                  }
                  fill
                  shouldRender={!disabled || showGmailSentFolderRestrictions}
                  actionCursor={true}
                />
                <i className="fal fa-folder-open" />
              </button>

              <button
                type="button"
                disabled={disabled || !this.props.message.body}
                onClick={this.onPrintClickHandler}
              >
                <Hint
                  message="emailHintPrint"
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className="fal fa-print" />
              </button>
            </div>
          </div>

          <div styleName="center">
            <div styleName="action-group">
              <button
                type="button"
                disabled={disabled}
                onClick={() => this.onMessageToDraft(DraftType.Reply)}
              >
                <Hint
                  message="emailHintReply"
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className="fal fa-reply" />
              </button>

              <button
                type="button"
                disabled={disabled}
                onClick={() => this.onMessageToDraft(DraftType.ReplyAll)}
              >
                <Hint
                  message="emailHintReplyAll"
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className="fal fa-reply-all" />
              </button>

              <button
                type="button"
                disabled={disabled}
                onClick={() => this.onMessageToDraft(DraftType.Forward)}
              >
                <Hint
                  message="emailHintForward"
                  fill
                  shouldRender={!disabled}
                  actionCursor={true}
                />
                <i className="fal fa-share" />
              </button>
            </div>
          </div>

          <div styleName="right">
            <div styleName="action-group">
              {!this.props.currentFolderIsArchive && (
                <button
                  type="button"
                  disabled={disabled || showGmailSentFolderRestrictions}
                  onClick={this.onArchiveMessageHandler}
                  data-cy="CY-messageArchive"
                >
                  <Hint
                    message={
                      showGmailSentFolderRestrictions
                        ? "emailHint.gmailFolderRestrictions"
                        : "emailHintArchive"
                    }
                    fill
                    shouldRender={!disabled || showGmailSentFolderRestrictions}
                    actionCursor={true}
                  />
                  <i className="fal fa-archive" />
                </button>
              )}
              {!this.props.currentFolderIsTrash && (
                <button
                  type="button"
                  disabled={disabled || showGmailSentFolderRestrictions}
                  onClick={this.onDeleteMessageHandler}
                  data-cy="CY-messageDelete"
                >
                  <Hint
                    message={
                      showGmailSentFolderRestrictions
                        ? "emailHint.gmailFolderRestrictions"
                        : "emailHintDelete"
                    }
                    fill
                    shouldRender={!disabled || showGmailSentFolderRestrictions}
                    actionCursor={true}
                  />
                  <i className="fal fa-trash-alt" />
                </button>
              )}
            </div>

            <button
              type="button"
              className="btn btn-primary icon-left"
              styleName="create-new-email"
              data-cy="CY-createEmailButton"
              onClick={() => this.props.onCreateNewEmail()}
            >
              <i className="fal fa-plus" />
              <ResourceText resourceKey="createNewEmail" />
            </button>
          </div>
        </div>

        <EmailMoveModalComponent
          visible={this.state.folderMoveModalVisible}
          account={this.props.currentAccount}
          onClose={this.onMoveModalCloseHandler}
          onSelectFolder={this.onMoveModalSelectFolder}
        />

        <ConfirmComponent
          visible={this.state.downloadExternalReferencesConfirmVisible}
          titleResourceKey="emailDownloadExternalReferencesConfirmTitle"
          bodyResourceKey="emailDownloadExternalReferencesConfirmBody"
          bodyValues={this.props.message || {}}
          onClose={this.onConfirmDownloadExternalReferencesCloseHandler}
          onConfirm={this.onConfirmDownloadExternalReferencesHandler}
        />
      </div>
    );
  }

  private onMessageToDraft(type: DraftType) {
    if (!!this.props.message.hasBlockedExternalReferences) {
      this.setState({
        downloadExternalReferencesConfirmVisible: true,
        currentDraftType: type,
      });
    } else {
      this.props.onMessageToDraft(
        this.props.message,
        type,
        this.props.currentAccount.id,
        this.props.currentFolder.id
      );
    }
  }

  private onConfirmDownloadExternalReferencesCloseHandler() {
    this.setState({
      downloadExternalReferencesConfirmVisible: false,
    });
    this.props.onMessageToDraft(
      this.props.message,
      this.state.currentDraftType,
      this.props.currentAccount.id,
      this.props.currentFolder.id
    );
  }

  private async onConfirmDownloadExternalReferencesHandler() {
    this.setState({
      downloadExternalReferencesConfirmVisible: false,
    });

    await this.props.onDownloadExternalReferences(this.props.message.id);
    this.props.onMessageToDraft(
      this.props.message,
      this.state.currentDraftType,
      this.props.currentAccount.id,
      this.props.currentFolder.id
    );
  }

  private onMoveModalCloseHandler() {
    this.setState({
      folderMoveModalVisible: false,
    });
  }

  private async onMoveModalSelectFolder(folder: Folder) {
    if (this.state.loading) return;

    this.setState({
      folderMoveModalVisible: false,
      loading: true,
    });

    try {
      await this.props.moveToFolder(
        this.props.currentAccount.id,
        this.props.message,
        folder
      );
      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  }

  private onBookmarkedClickHandler() {
    const { currentAccount } = this.props;
    const { id, bookmarked } = this.props.message;

    this.props.toggleBookmarked(currentAccount.id, id, bookmarked);
  }

  private onUnreadClickHandler() {
    const { currentAccount } = this.props;
    this.props.toggleUnread(currentAccount.id, this.props.message);
  }

  private onMoveClickHandler() {
    this.setState({
      folderMoveModalVisible: true,
    });
  }

  private async onPrintClickHandler() {
    const { subject } = this.props.message;

    const print = new Print();
    const html = print.createMailTemplate(this.props.message);
    print.print(html, subject);
  }

  private async onArchiveMessageHandler() {
    if (this.state.loading) return;

    this.setState({
      loading: true,
    });

    try {
      await this.props.onArchiveMessage();
      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  }

  private async onDeleteMessageHandler() {
    if (this.state.loading) return;

    try {
      await this.props.onDeleteMessage();
      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  }
}
