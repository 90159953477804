import { SETTINGSROUTES } from "@haywork/constants";
import { EmailWelcomeContainerProps } from "@haywork/modules/email";
import { SelectProviders } from "@haywork/modules/settings/modules/email";
import { ResourceText } from "@haywork/modules/shared";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";

const styles = require("./welcome.component.scss");

export type EmailWelcomeComponentProps = {};
type Props = EmailWelcomeComponentProps & EmailWelcomeContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class EmailWelcomeComponent extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onSelectProviderHandler = this.onSelectProviderHandler.bind(this);
  }

  public render() {
    return (
      <div styleName="welcome">
        <div styleName="welcome__box">
          <ResourceText resourceKey="welcomeToKolibriMailIntro" asHtml />

          <Link to={SETTINGSROUTES.EMAIL.URI} styleName="empty-state__cta">
            <ResourceText resourceKey="welcomeToKolibriMailButton" />
          </Link>

          <div styleName="providers">
            <SelectProviders
              providers={this.props.providers}
              centered
              onSelectProvider={this.onSelectProviderHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  private onSelectProviderHandler() {
    this.props.navigate(SETTINGSROUTES.EMAIL.URI);
  }
}
