import * as React from "react";
import { memo, ReactNode, CSSProperties, FC } from "react";
import * as CSSModules from "react-css-modules";
import { context, InjectedContextProps } from "../../context-hoc";
import classNames from "classnames";

const styles = require("./style.scss");

interface Props {
  children?: ReactNode;
  style?: CSSProperties;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  float?: boolean;
}
export const UiActionListComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, style, minWidth, maxWidth, width, float }) => {
      const listStyle: CSSProperties = {
        ...style,
        minWidth,
        maxWidth: width || maxWidth,
        width,
      };

      return (
        <div styleName={classNames("action-list", { float })} style={listStyle}>
          {children}
        </div>
      );
    }
  )
);

export const UiActionListComponentWithContext = context()(
  memo((props: Props & InjectedContextProps) => {
    const { children, ...rest } = props;

    return (
      <UiActionListComponent {...rest} float>
        {children}
      </UiActionListComponent>
    );
  })
);
