import {
  CommunicationLog,
  CommunicationLogBlobType,
  LinkedAssignment,
  LinkedRelation,
} from "@haywork/api/kolibri";
import { File, Message } from "@haywork/api/mail";
import Icon from "@haywork/components/ui/icon";
import { KEYCODE, REQUEST } from "@haywork/constants";
import { DraftType } from "@haywork/enum";
import { Colors } from "@haywork/enum/colors";
import { EmailMessageBlobContainerProps } from "@haywork/modules/email";
import { EmailPaper } from "@haywork/modules/email/components/detail/paper.component";
import { Modal, ModalBody, ModalHeader } from "@haywork/modules/modal";
import { Hint, PageLoader } from "@haywork/modules/shared";
import {
  Ui,
  UiEmptyStateStickMan,
  UiEmptyStateType,
} from "@haywork/modules/ui";
import { EmailRequest } from "@haywork/request";
import { Print } from "@haywork/services";
import noop from "lodash-es/noop";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { AsyncUtil } from "@haywork/util";
import { EmailMessage } from "@haywork/stores/email-v2";
import { EmailUtil } from "@haywork/util/email-v2";

const styles = require("./message-blob.component.scss");

export interface EmailMessageBlobComponentProps {
  modalVisible: boolean;
  communicationLogId: string;
  onModalCloseHandler: () => void;
  onSaveAttachmentsToDossier: (
    file: File[],
    linkedRelations: LinkedRelation[],
    linkedAssignments: LinkedAssignment[]
  ) => void;
  onUpdatedCommunicationLog: () => void;
}
interface State {
  message: EmailMessage | null;
  messageStatus: string;
  linkedRelations: LinkedRelation[];
  linkedAssignments: LinkedAssignment[];
  notReadyYet: boolean;
  communicationLog: CommunicationLog | null;
  subjectFormVisible: boolean;
  subject: string;
  loading: boolean;
}
type Props = EmailMessageBlobComponentProps & EmailMessageBlobContainerProps;

@CSSModules(styles, { allowMultiple: true })
export default class EmailMessageBlobComponent extends React.PureComponent<
  Props,
  State
> {
  private subjectRef: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      message: null,
      messageStatus: REQUEST.PENDING,
      linkedRelations: [],
      linkedAssignments: [],
      notReadyYet: false,
      communicationLog: null,
      subjectFormVisible: false,
      subject: "",
      loading: false,
    };

    this.onMessageToDraft = this.onMessageToDraft.bind(this);
    this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
    this.getMessageBlobData = this.getMessageBlobData.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleSubjectBlur = this.handleSubjectBlur.bind(this);
    this.handleSubjectKeyUp = this.handleSubjectKeyUp.bind(this);
    this.saveUpdatedCommunicationLog =
      this.saveUpdatedCommunicationLog.bind(this);
    this.showSubjectInput = this.showSubjectInput.bind(this);
    this.onPrintClickHandler = this.onPrintClickHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.modalVisible &&
      this.props.modalVisible &&
      this.props.communicationLogId
    ) {
      this.getMessageBlobData(this.props.communicationLogId);
    }
  }

  public render() {
    const printDisabled = !this.state.message;

    return (
      <Modal
        visible={this.props.modalVisible}
        onClose={() => {
          this.setState({ message: null });
          this.props.onModalCloseHandler();
        }}
      >
        <ModalHeader title="email" close />
        <ModalBody noPadding>
          <div styleName="email-wrapper">
            {this.state.loading && !this.state.notReadyYet && (
              <div styleName="empty-state-loader">
                <PageLoader loading />
              </div>
            )}
            {!this.state.notReadyYet && !!this.state.message && (
              <>
                <div styleName="action-group">
                  <button
                    type="button"
                    onClick={() => this.onMessageToDraft(DraftType.Reply)}
                  >
                    <Hint message="emailHintReply" fill actionCursor={true} />
                    <i className="fal fa-reply" />
                  </button>

                  <button
                    type="button"
                    onClick={() => this.onMessageToDraft(DraftType.ReplyAll)}
                  >
                    <Hint
                      message="emailHintReplyAll"
                      fill
                      actionCursor={true}
                    />
                    <i className="fal fa-reply-all" />
                  </button>

                  <button
                    type="button"
                    onClick={() => this.onMessageToDraft(DraftType.Forward)}
                  >
                    <Hint message="emailHintForward" fill actionCursor={true} />
                    <i className="fal fa-share" />
                  </button>

                  <button
                    type="button"
                    disabled={printDisabled}
                    onClick={this.onPrintClickHandler}
                  >
                    <Hint
                      message="emailHintPrint"
                      fill
                      actionCursor={true}
                      shouldRender={!printDisabled}
                    />
                    <i className="fal fa-print" />
                  </button>
                </div>
                <div>
                  {!this.state.subjectFormVisible ? (
                    <div styleName="subject" onClick={this.showSubjectInput}>
                      <div styleName="subject__label">{this.state.subject}</div>
                      <div styleName="subject__action">
                        <Icon name="pencil" light color={Colors.Gray} />
                      </div>
                    </div>
                  ) : (
                    <div styleName="subject-input">
                      <input
                        type="text"
                        name="subject"
                        value={this.state.subject}
                        onChange={this.handleSubjectChange}
                        onBlur={this.handleSubjectBlur}
                        onKeyUp={this.handleSubjectKeyUp}
                        ref={(ref) => (this.subjectRef = ref)}
                        data-lpignore="true"
                      />
                    </div>
                  )}
                </div>
                <div styleName="body">
                  <EmailPaper
                    message={this.state.message}
                    messageStatus={this.state.messageStatus}
                    linkedRelations={this.state.linkedRelations}
                    linkedAssignments={this.state.linkedAssignments}
                    onDownloadExternalReferences={noop}
                    isMessageBlob={true}
                    onDownloadAttachment={this.onDownloadAttachment}
                    onSaveAttachmentsToDossier={
                      this.props.onSaveAttachmentsToDossier
                    }
                  />
                </div>
              </>
            )}

            {this.state.notReadyYet && (
              <div styleName="empty-state">
                <Ui.EmptyState
                  type={UiEmptyStateType.List}
                  stickman={UiEmptyStateStickMan.NoAssignmentsOverview}
                  title={"noAssignmentBlobTitle"}
                  subtitle={"noAssignmentBlobDescription"}
                />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }

  private onMessageToDraft(type: DraftType) {
    this.props.messageToDraft(
      this.state.message,
      type,
      this.props.currentAccountId,
      ""
    );
  }

  private async getMessageBlobData(communicationLogId: string) {
    try {
      this.setState({ loading: true });
      const communicationLog = await EmailRequest.getCommunicationLog(
        communicationLogId
      );
      const blobs = await EmailRequest.getCommunicationBlobs(communicationLog);

      const attachmentBlobs = blobs.filter(
        (blob) =>
          blob.blobType === CommunicationLogBlobType.Attachment &&
          blob.isUploaded
      );
      const files: File[] = [];
      attachmentBlobs.map((blob) => {
        files.push({
          id: blob.id,
          fileName: `${blob.fileName}${blob.fileExtension}`,
          uri: blob.downloadLink,
          isAttachment: true,
          contentType: blob.contentType,
          size: blob.fileSize,
        });
      });

      const mailMessageBlob = blobs.find(
        (blob) => blob.blobType === CommunicationLogBlobType.MailMessage
      );
      if (mailMessageBlob) {
        const communicationBobMailMessage =
          await EmailRequest.getCommunicationBlobMailMessage(
            mailMessageBlob.id
          );

        const email: Message = {
          subject: communicationBobMailMessage.subject,
          from: communicationBobMailMessage.from,
          to: communicationBobMailMessage.to,
          cc: communicationBobMailMessage.cc,
          bcc: communicationBobMailMessage.bcc,
          replyTo: communicationBobMailMessage.replyTo,
          date: communicationBobMailMessage.date,
          body: communicationBobMailMessage.body,
          unread: false,
          bookmarked: false,
          hasBlockedExternalReferences: false,
          files,
        };

        const message = EmailUtil.mapMessageToEmailMessage(email, "", "", []);

        this.setState({
          message,
          messageStatus: REQUEST.SUCCESS,
          linkedRelations: communicationLog.linkedRelations,
          linkedAssignments: communicationLog.linkedAssignments,
          notReadyYet: false,
          communicationLog,
          subject: communicationLog.subject || "",
        });
        this.setState({ loading: false });
      } else {
        this.setState({ notReadyYet: true });
      }
    } catch (exception) {
      this.setState({ loading: false });
      this.props.showError(exception);
    }
  }

  private onDownloadAttachment(file: File) {
    window.open(file.uri, "_blank");
  }

  private handleSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      subject: event.target.value,
    });
  }

  private handleSubjectBlur() {
    this.setState({ subjectFormVisible: false });
    this.saveUpdatedCommunicationLog();
  }

  private handleSubjectKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.keyCode) {
      case KEYCODE.ENTER: {
        event.preventDefault();

        this.setState({
          subjectFormVisible: false,
        });

        this.saveUpdatedCommunicationLog();
        break;
      }
      case KEYCODE.ESCAPE: {
        event.preventDefault();

        this.setState({
          subjectFormVisible: false,
          subject: this.state.communicationLog.subject || "",
        });
        break;
      }
      default:
        break;
    }
  }

  private async saveUpdatedCommunicationLog() {
    const { subject, communicationLog } = this.state;
    if (communicationLog.subject === subject) return;
    if (!subject) {
      this.setState({
        subject: this.state.communicationLog.subject || "",
      });
      return;
    }

    try {
      const log = {
        ...communicationLog,
        subject,
      } as CommunicationLog;

      const updatedLog = await this.props.saveCommunicationLog(log);

      this.setState({
        communicationLog: updatedLog,
        subject: updatedLog.subject || "",
      });

      this.props.onUpdatedCommunicationLog();
    } catch (error) {
      this.setState({
        subject: this.state.communicationLog.subject || "",
      });
    }
  }

  private showSubjectInput() {
    this.setState(
      {
        subjectFormVisible: true,
      },
      () => {
        setTimeout(() => {
          if (!!this.subjectRef) this.subjectRef.focus();
        }, 10);
      }
    );
  }

  private async onPrintClickHandler() {
    if (!this.state.message) return;
    const { subject } = this.state.message;

    const print = new Print();
    const html = print.createMailTemplate(this.state.message);
    print.print(html, subject);
  }
}
