import { InitializedWidget, WidgetSnapShot } from "@haywork/api/authorization";

export enum widgetsActions {
  SetConsentedWidgets = "Authorization.Widgets.SetConsentedWidgets",
  ShowModal = "Authorization.Widgets.ShowModal",
  HideModal = "Authorization.Widgets.HideModal",
  ResetModal = "Authorization.Widgets.ResetModal",
  SetInitializedWidgets = "Authorization.Widgets.SetInitializedWidgets",
  ResetApp = "RESET_APP",
}

interface ResetApp {
  type: widgetsActions.ResetApp;
}

interface SetConsentedWidgets {
  type: widgetsActions.SetConsentedWidgets;
  widgets: WidgetSnapShot[];
}
const setConsentedWidgets = (widgets: WidgetSnapShot[]) => ({
  type: widgetsActions.SetConsentedWidgets,
  widgets,
});

interface ShowModal {
  type: widgetsActions.ShowModal;
  widgetModalWidget: WidgetSnapShot;
  widgetModalInitializedWidget: InitializedWidget;
}
const showModal = (
  widgetModalWidget: WidgetSnapShot,
  widgetModalInitializedWidget: InitializedWidget
) => ({
  type: widgetsActions.ShowModal,
  widgetModalWidget,
  widgetModalInitializedWidget,
});

interface HideModal {
  type: widgetsActions.HideModal;
}
const hideModal = () => ({
  type: widgetsActions.HideModal,
});

interface ResetModal {
  type: widgetsActions.ResetModal;
}
const resetModal = () => ({
  type: widgetsActions.ResetModal,
});

interface SetInitializedWidgets {
  type: widgetsActions.SetInitializedWidgets;
  initializedWidgets: InitializedWidget[];
  initializedWidgetsEntityId: string;
}
const setInitializedWidgets = (
  initializedWidgets: InitializedWidget[],
  initializedWidgetsEntityId: string | string[]
) => ({
  type: widgetsActions.SetInitializedWidgets,
  initializedWidgets,
  initializedWidgetsEntityId,
});

export type WidgetsActions =
  | SetConsentedWidgets
  | ShowModal
  | HideModal
  | ResetModal
  | SetInitializedWidgets
  | ResetApp;
export const Actions = {
  setConsentedWidgets,
  showModal,
  hideModal,
  resetModal,
  setInitializedWidgets,
};
