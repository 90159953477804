import * as React from "react";
import { FC, memo, useState, ChangeEvent, CSSProperties } from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import DropArea from "./drop-area";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");
const maxWidth = 348;
const maxHeight = 108;
const ratio = maxHeight / maxWidth;
const dimensions: CSSProperties = {
  width: maxWidth,
  height: maxHeight,
};

type Props = {
  logo: string | null;
  onChange: (logo: string | null) => void;
};

export const SettingsCompanySettingsLogoComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ logo: receivedLogo, onChange }) => {
      const [logo, setLogo] = useState(receivedLogo);

      const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files.item(0);
        onHandleFile(file);
      };

      const onHandleFile = (file: File) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d"),
            img = new Image();

          img.onload = () => {
            const { width, height } = img;
            const childRatio = height / width;
            let newHeight = maxHeight,
              newWidth = maxWidth;

            if (childRatio > ratio) {
              newWidth = Math.floor(newHeight / childRatio);
            } else {
              newHeight = Math.floor(newWidth * childRatio);
            }

            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const logo = canvas.toDataURL() || null;
            setLogo(logo);
            onChange(logo);
          };

          img.src = reader.result as string;
        };

        reader.readAsDataURL(file);
      };

      const clearLogo = () => {
        setLogo(null);
        onChange(null);
      };

      return (
        <div styleName="logo__wrapper">
          <div styleName="logo">
            <div
              styleName={classNames("logo__placeholder", {
                "has-logo": !!logo,
              })}
              style={dimensions}
            >
              <DropArea onDrop={onHandleFile}>
                {!logo ? (
                  <>
                    <label>
                      <input type="file" onChange={onChangeHandler} />
                    </label>
                    <div styleName="empty-state">
                      <I18n value="settings.companySettings.logo.emptyState" />
                    </div>
                  </>
                ) : (
                  <div>
                    <img src={logo} />
                  </div>
                )}
              </DropArea>
            </div>
          </div>
          {!!logo && (
            <div styleName="clear">
              <span className="as-link" onClick={clearLogo}>
                <I18n value="settings.companySettings.logo.clear" />
              </span>
            </div>
          )}
        </div>
      );
    }
  )
);
