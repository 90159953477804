import classNames from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";

interface OptionInputComponentProps {
  value: any;
  idx?: number;
  default?: boolean;
  empty?: boolean;
  disabled?: boolean;
}
interface OptionInputComponentState {}

export class Option extends React.Component<
  OptionInputComponentProps,
  OptionInputComponentState
> {
  public static contextTypes = {
    onOptionChange: PropTypes.func.isRequired,
    activeKey: PropTypes.number.isRequired,
    list: PropTypes.object,
  };

  private ref: HTMLDivElement;

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  public render() {
    const active = this.props.idx === this.context.activeKey;
    const selectStyle = classNames("form__select-option", {
      active,
      empty: this.props.empty || this.props.disabled,
    });

    return (
      <div
        id={this.props.idx && this.props.idx.toString()}
        className={selectStyle}
        onClick={this.onClick}
        ref={this.setRef}
        data-cy={this.props["data-cy"]}
      >
        {this.props.children}
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext.activeKey === nextProps.idx && !!this.ref) {
      setTimeout(() => {
        const { clientHeight, scrollTop } = nextContext.list;
        const top = this.ref.offsetTop;
        const bottom = top + this.ref.clientHeight;

        if (bottom > clientHeight + scrollTop || top < scrollTop) {
          nextContext.list.scrollTop =
            top + this.ref.clientHeight / 2 - clientHeight / 2;
        }
      }, 5);
    }
  }

  public onClick() {
    if (this.props.default || this.props.disabled) return;
    this.context.onOptionChange(this.props.value);
  }

  private setRef(ref: HTMLDivElement) {
    if (!this.ref && !!ref) this.ref = ref;
  }
}
