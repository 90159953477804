import * as React from "react";
import { memo } from "react";

export const LoadScreenComponent = memo(() => {
  return (
    <div className="loadscreen">
      <div className="loadscreen__inner">
        <div className="loadscreen__logo" />
        <div className="loadscreen__loader">
          <div className="indeterminate" />
        </div>
      </div>
    </div>
  );
});
