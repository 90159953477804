import {
  AssignmentPhase,
  AvailabilityStatus,
  LinkedAssignment,
  ObjectAssignment,
  RealEstateGroup,
  ReportTemplate,
} from "@haywork/api/kolibri";
import ActionList, { Action, Spacer } from "@haywork/components/ui/action-list";
import Button from "@haywork/components/ui/button";
import Icon from "@haywork/components/ui/icon";
import { Colors } from "@haywork/enum/colors";
import first from "lodash-es/first";
import sortBy from "lodash-es/sortBy";
import * as moment from "moment";
import * as React from "react";
import { FC, memo, useMemo, useRef, useState } from "react";
import { FeatureSwitch } from "@haywork/modules/feature-switch";

const AVAILABLE_STATUSES = [
  AvailabilityStatus.Available,
  AvailabilityStatus.UnderBid,
  AvailabilityStatus.UnderOption,
];
const SOLD_STATUSES = [
  AvailabilityStatus.Farmed,
  AvailabilityStatus.Leased,
  AvailabilityStatus.Rented,
  AvailabilityStatus.Sold,
  AvailabilityStatus.Withdrawn,
];

export enum AssignmentAction {
  FinalizeRent = "FinalizeRent",
  FinalizeSale = "FinalizeSale",
  FinalizeSaleDirectly = "FinalizeSaleDirectly",
  FinalizeRentDirectly = "FinalizeRentDirectly",
  WithDraw = "WithDraw",
  Publish = "Publish",
  Remove = "Remove",
  PlaceUnderOffer = "PlaceUnderOffer",
  PlaceUnderOption = "PlaceUnderOption",
  ToAvailable = "ToAvailable",
  Archive = "Archive",
  UnArchive = "UnArchive",
  DuplicateAsRent = "DuplicateAsRent",
  DuplicateAsSale = "DuplicateAsSale",
  ProlongRentOffer = "ProlongRentOffer",
  RenewRentOffer = "RenewRentOffer",
  ChangeTransactionData = "ChangeTransactionData",
  PrintWindowPresentation = "PrintWindowPresentation",
  CreateEmail = "CreateEmail",
  CreateTask = "CreateTask",
  CreateAppointment = "CreateAppointment",
  CreateInvoice = "CreateInvoice",
  OpenParentProject = "OpenParentProject",
  OpenParentObjectType = "OpenParentObjectType",
  AddBid = "AddBid",
}

type Props = {
  assignment: ObjectAssignment;
  printTemplates: ReportTemplate[];
  isInitial: boolean;
  canSendEmail: boolean;
  enabledRealEstateGroups: RealEstateGroup[];
  canDuplicate: boolean;
  onClick: (action: AssignmentAction) => void;
};

export const AssignmentGeneralActionsComponent: FC<Props> = memo(
  ({
    assignment,
    printTemplates,
    canSendEmail,
    isInitial,
    enabledRealEstateGroups,
    canDuplicate,
    onClick,
  }) => {
    const actionsRef = useRef<HTMLButtonElement>(null);
    const [actionsVisible, setActionsVisible] = useState(false);

    const {
      assignmentPhase,
      availabilityStatus,
      forRent,
      forSale,
      isActive,
      linkedObjectAssignments,
      id,
      realEstateGroup,
      linkedProjectAssignment,
      linkedObjectTypeAssignment,
    } = assignment;

    const showFinalizeRent =
      !!isActive &&
      !!forRent &&
      assignmentPhase === AssignmentPhase.Initiated &&
      AVAILABLE_STATUSES.indexOf(availabilityStatus) !== -1;

    const showFinalizeSale =
      !!isActive &&
      !!forSale &&
      assignmentPhase === AssignmentPhase.Initiated &&
      AVAILABLE_STATUSES.indexOf(availabilityStatus) !== -1;

    const showFinalizeSaleDirectly =
      !!isActive && !!forSale && assignmentPhase === AssignmentPhase.Concept;

    const showFinalizeRentDirectly =
      !!isActive && !!forRent && assignmentPhase === AssignmentPhase.Concept;

    const showWithdraw =
      !!isActive &&
      assignmentPhase === AssignmentPhase.Initiated &&
      AVAILABLE_STATUSES.indexOf(availabilityStatus) !== -1;

    const showPublish =
      !!isActive && assignmentPhase === AssignmentPhase.Concept;

    const showRemove = assignmentPhase === AssignmentPhase.Concept;

    const showPlaceUnderOffer =
      !!isActive &&
      assignmentPhase === AssignmentPhase.Initiated &&
      [AvailabilityStatus.Available, AvailabilityStatus.UnderOption].includes(
        availabilityStatus
      );

    const showPlaceUnderOfferRent =
      !!isActive &&
      !!forRent &&
      assignmentPhase === AssignmentPhase.Initiated &&
      [AvailabilityStatus.Available, AvailabilityStatus.UnderOption].includes(
        availabilityStatus
      );

    const showPlaceUnderOption =
      !!isActive &&
      !!forSale &&
      assignmentPhase === AssignmentPhase.Initiated &&
      [AvailabilityStatus.UnderBid, AvailabilityStatus.Available].includes(
        availabilityStatus
      );

    const showPlaceUnderOptionRent =
      !!isActive &&
      !!forRent &&
      assignmentPhase === AssignmentPhase.Initiated &&
      [AvailabilityStatus.UnderBid, AvailabilityStatus.Available].includes(
        availabilityStatus
      );

    const showToAvailable =
      !!isActive &&
      assignmentPhase === AssignmentPhase.Initiated &&
      [AvailabilityStatus.UnderBid, AvailabilityStatus.UnderOption].indexOf(
        availabilityStatus
      ) !== -1;

    const showArchive =
      !!isActive &&
      ([AssignmentPhase.Concept, AssignmentPhase.Completed].indexOf(
        assignmentPhase
      ) !== -1 ||
        SOLD_STATUSES.indexOf(availabilityStatus) !== -1) &&
      !!isActive;

    const showUnArchive = !isActive;

    const showDuplicateAsSale =
      canDuplicate &&
      enabledRealEstateGroups.includes(assignment.realEstateGroup) &&
      (!!forRent ||
        assignmentPhase === AssignmentPhase.Completed ||
        [
          AvailabilityStatus.Farmed,
          AvailabilityStatus.Leased,
          AvailabilityStatus.Sold,
          AvailabilityStatus.Withdrawn,
        ].includes(availabilityStatus));

    const showDuplicateAsRent =
      canDuplicate &&
      enabledRealEstateGroups.includes(assignment.realEstateGroup) &&
      (!!forSale ||
        assignmentPhase === AssignmentPhase.Completed ||
        [
          AvailabilityStatus.Farmed,
          AvailabilityStatus.Leased,
          AvailabilityStatus.Rented,
          AvailabilityStatus.Withdrawn,
        ].includes(availabilityStatus));

    const canRenewProlong = useMemo(() => {
      let value = true;
      if (!!linkedObjectAssignments && !!linkedObjectAssignments.length) {
        const versions = sortBy(
          linkedObjectAssignments,
          (assignment: LinkedAssignment) =>
            moment(assignment.dateTimeCreated).unix()
        ).reverse();
        value = first(versions).id === id;
      }

      return value;
    }, [linkedObjectAssignments]);

    const isResidential = realEstateGroup === RealEstateGroup.Residential;
    const showOpenParentProject =
      !!linkedProjectAssignment && !!linkedProjectAssignment.id;
    const showOpenParentObjectType =
      !!linkedObjectTypeAssignment && !!linkedObjectTypeAssignment.id;

    const showProlongRentOffer =
      !isInitial &&
      !!forRent &&
      !!isResidential &&
      canRenewProlong &&
      [
        AvailabilityStatus.Rented,
        AvailabilityStatus.Leased,
        AvailabilityStatus.Farmed,
      ].indexOf(availabilityStatus) !== -1;

    const showRenewRentOffer =
      !isInitial &&
      !!forRent &&
      canRenewProlong &&
      ([
        AvailabilityStatus.Rented,
        AvailabilityStatus.Leased,
        AvailabilityStatus.Farmed,
        AvailabilityStatus.Withdrawn,
      ].indexOf(availabilityStatus) !== -1 ||
        assignmentPhase === AssignmentPhase.Completed);

    const isSoldOrRented =
      [
        AvailabilityStatus.Rented,
        AvailabilityStatus.RentedUnderCondition,
        AvailabilityStatus.Sold,
        AvailabilityStatus.SoldUnderCondition,
      ].indexOf(availabilityStatus) !== -1;

    const showPrintWindowPresentation =
      !!printTemplates.length && !isSoldOrRented;
    const showCreateEmail = canSendEmail;

    const onClickHandler = (action: AssignmentAction) => {
      onClick(action);
      setActionsVisible(false);
    };

    return (
      <>
        <Button
          ref={actionsRef}
          onClick={() => setActionsVisible(true)}
          icon={
            <Icon
              name="chevron-down"
              light
              size={16}
              color={isInitial ? Colors.Gray : Colors.White}
            />
          }
          iconPosition="end"
          category="primary"
          label="actions"
          disabled={isInitial}
        />
        <ActionList
          parent={actionsRef}
          visible={actionsVisible}
          onHide={() => setActionsVisible(false)}
        >
          <Spacer label="actions" />
          {showFinalizeRent && (
            <Action
              label="assignmentMenuFinalizeRent"
              icon={
                <Icon
                  light
                  name="forward"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.FinalizeRent)}
            />
          )}
          {showProlongRentOffer && (
            <Action
              label="assignmentMenuProlongRentOffer"
              icon={
                <Icon
                  light
                  name="arrows-h"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.ProlongRentOffer)}
            />
          )}
          {showRenewRentOffer && (
            <Action
              label="assignmentMenuRenewRentOffer"
              icon={
                <Icon
                  light
                  name="sync"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.RenewRentOffer)}
            />
          )}
          {showFinalizeSale && (
            <Action
              label="assignmentMenuFinalize"
              icon={
                <Icon
                  light
                  name="forward"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.FinalizeSale)}
            />
          )}
          {showFinalizeSaleDirectly && (
            <Action
              label="assignmentMenuDirectFinalizeSale"
              icon={
                <Icon
                  light
                  name="fast-forward"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AssignmentAction.FinalizeSaleDirectly)
              }
            />
          )}
          {showFinalizeRentDirectly && (
            <Action
              label="assignmentMenuDirectFinalizeRent"
              icon={
                <Icon
                  light
                  name="fast-forward"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AssignmentAction.FinalizeRentDirectly)
              }
            />
          )}
          {showWithdraw && (
            <Action
              label="withDrawAssignment"
              icon={
                <Icon
                  light
                  name="arrow-left"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.WithDraw)}
            />
          )}
          {showPublish && (
            <Action
              label="assignmentMenuPublish"
              icon={
                <Icon
                  light
                  name="globe"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.Publish)}
            />
          )}
          {showPlaceUnderOffer && (
            <Action
              label="assignmentMenuUnderBid"
              icon={
                <Icon
                  light
                  name="sign-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.PlaceUnderOffer)}
            />
          )}
          {showPlaceUnderOption && (
            <Action
              label="assignmentMenuUnderOption"
              icon={
                <Icon
                  light
                  name="sign-in"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.PlaceUnderOption)}
            />
          )}

          <FeatureSwitch feature="UNDER_BID_OR_OPTION_RENT">
            {!forSale && (
              <>
                {showPlaceUnderOfferRent && (
                  <Action
                    label="assignmentMenuUnderBid"
                    icon={
                      <Icon
                        light
                        name="sign-out"
                        color={Colors.ActionListIcon}
                        fixedWidth
                      />
                    }
                    onClick={() =>
                      onClickHandler(AssignmentAction.PlaceUnderOffer)
                    }
                  />
                )}
                {showPlaceUnderOptionRent && (
                  <Action
                    label="assignmentMenuUnderOption"
                    icon={
                      <Icon
                        light
                        name="sign-in"
                        color={Colors.ActionListIcon}
                        fixedWidth
                      />
                    }
                    onClick={() =>
                      onClickHandler(AssignmentAction.PlaceUnderOption)
                    }
                  />
                )}
              </>
            )}
          </FeatureSwitch>

          <FeatureSwitch feature="BIDS">
            <Action
              label="assignment.action.addBid"
              icon={
                <Icon
                  light
                  name="sign-out"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.AddBid)}
            />
          </FeatureSwitch>

          {showToAvailable && (
            <Action
              label="assignmentMenuAvailable"
              icon={
                <Icon
                  light
                  name="arrow-up"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.ToAvailable)}
            />
          )}
          {showDuplicateAsRent && (
            <Action
              label="assignmentMenuDuplicateAsRent"
              icon={
                <Icon
                  light
                  name="copy"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.DuplicateAsRent)}
            />
          )}
          {showDuplicateAsSale && (
            <Action
              label="assignmentMenuDuplicateAsSale"
              icon={
                <Icon
                  light
                  name="copy"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.DuplicateAsSale)}
            />
          )}
          {isSoldOrRented && (
            <Action
              label="assignmentMenuChangeAssignmentData"
              icon={
                <Icon
                  light
                  name="pencil"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AssignmentAction.ChangeTransactionData)
              }
            />
          )}

          <Spacer label="assignment.actions.label.other" />
          {showOpenParentObjectType && (
            <Action
              label="assignment.actions.label.openParentObjectType"
              icon={
                <Icon
                  light
                  name="puzzle-piece"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AssignmentAction.OpenParentObjectType)
              }
            />
          )}
          {showOpenParentProject && (
            <Action
              label="assignment.actions.label.openParentProject"
              icon={
                <Icon
                  light
                  name="folder-open"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.OpenParentProject)}
            />
          )}

          {showPrintWindowPresentation && (
            <Action
              label="assignmentMenuPrintWindowPresentation"
              icon={
                <Icon
                  light
                  name="print"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() =>
                onClickHandler(AssignmentAction.PrintWindowPresentation)
              }
            />
          )}
          {showCreateEmail && (
            <Action
              label="assignment.actions.label.email"
              icon={
                <Icon
                  light
                  name="paper-plane"
                  color={Colors.ActionListIcon}
                  fixedWidth
                />
              }
              onClick={() => onClickHandler(AssignmentAction.CreateEmail)}
            />
          )}
          <Action
            label="assignment.actions.label.task"
            icon={
              <Icon
                light
                name="tasks"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(AssignmentAction.CreateTask)}
          />
          <Action
            label="assignment.actions.label.appointment"
            icon={
              <Icon
                light
                name="calendar-plus"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(AssignmentAction.CreateAppointment)}
          />
          <Action
            label="assignment.actions.label.invoice"
            icon={
              <Icon
                light
                name="euro-sign"
                color={Colors.ActionListIcon}
                fixedWidth
              />
            }
            onClick={() => onClickHandler(AssignmentAction.CreateInvoice)}
          />

          {(showArchive || showUnArchive || showRemove) && (
            <>
              <Spacer />
              {showArchive && (
                <Action
                  label="assignmentMenuArchive"
                  icon={
                    <Icon
                      light
                      name="archive"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(AssignmentAction.Archive)}
                />
              )}

              {showUnArchive && (
                <Action
                  label="assignmentMenuUnArchive"
                  icon={
                    <Icon
                      light
                      name="inbox-out"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(AssignmentAction.UnArchive)}
                />
              )}

              {showRemove && (
                <Action
                  label="assignmentMenuRemove"
                  icon={
                    <Icon
                      light
                      name="trash-alt"
                      color={Colors.ActionListIcon}
                      fixedWidth
                    />
                  }
                  onClick={() => onClickHandler(AssignmentAction.Remove)}
                />
              )}
            </>
          )}
        </ActionList>
      </>
    );
  }
);

export default AssignmentGeneralActionsComponent;
