import {
  Address,
  AddressDetail,
  GeoLocation,
  LocationPlaceOption,
  ObjectAssignment,
  RealEstateGroup,
} from "@haywork/api/kolibri";
import I18n from "@haywork/components/i18n";
import Icon from "@haywork/components/ui/icon";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import { Colors } from "@haywork/enum/colors";
import {
  AssignmentCadastreContainer,
  AssignmentEditAddressContainerProps,
} from "@haywork/modules/assignment";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  QueryOptionReturnValue,
  QueryResultReturnValue,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  MapComponent,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { AddressRequest } from "@haywork/request";
import { SingleAssignmentState } from "@haywork/stores";
import { FormControlUtil, RouteUtil, StringUtil, MlsUtil } from "@haywork/util";
import * as deepEqual from "deep-equal";
import escapeRegExp from "lodash-es/escapeRegExp";
import get from "lodash-es/get";
import isString from "lodash-es/isString";
import pick from "lodash-es/pick";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Keys from "../keys";
import FundaModal, { FundaAddressFields } from "./components/funda-modal";
import MatchingProperties from "@haywork/modules/mls/components/matching-properties-on-address";
import { RealEstateProperty } from "@haywork/api/mls";
import { HouseNumberWarningComponent } from "./components/house-number-warning/house-number-warning";

const styles = require("./edit-address.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;
const countriesToShowFundaControl = ["DE", "BE", "LU"];

export interface AssignmentEditAddressComponentProps {}
interface State {
  addressResult: Address;
  newAddress: boolean;
  showMap: boolean;
  countrySuggestions: AddressDetail[];
  adminAreaOneSuggestions: AddressDetail[];
  adminAreaTwoSuggestions: AddressDetail[];
  localitySuggestions: AddressDetail[];
  sublocalitySuggestions: AddressDetail[];
  streetSuggestions: AddressDetail[];
  enrichAddress: boolean;
  fundaModalVisible: boolean;
  hideHouseNumberWarningVisible: boolean;
}
type Props = AssignmentEditAddressComponentProps &
  AssignmentEditAddressContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditAddressComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;
  private startAtCadastre: boolean;

  constructor(props) {
    super(props);

    this.state = {
      addressResult: null,
      newAddress: false,
      showMap: true,
      countrySuggestions: [],
      adminAreaOneSuggestions: [],
      adminAreaTwoSuggestions: [],
      localitySuggestions: [],
      sublocalitySuggestions: [],
      streetSuggestions: [],
      enrichAddress: true,
      fundaModalVisible: false,
      hideHouseNumberWarningVisible: false,
    };

    // Set form controls
    const { objectAssignment } = this.props;
    const locationPlaces = objectAssignment.locationPlaces || [];
    const enrichedLocationPlaces = locationPlaces
      .map((locationPlace) =>
        this.props.locationPlaces.find((place) => place.value === locationPlace)
      )
      .filter((place) => !!place);

    this.formControls = {
      street: { value: value(objectAssignment, "address.street") },
      houseNumber: {
        value: value(objectAssignment, "address.houseNumber"),
        onChange: () => {
          this.shouldShowHouseNumberWarning();
        },
      },
      houseNumberPostfix: {
        value: value(objectAssignment, "address.houseNumberPostfix"),
        onChange: () => {
          this.shouldShowHouseNumberWarning();
        },
      },
      constructionNumber: {
        value: value(objectAssignment, "constructionNumber"),
      },
      postalCode: { value: value(objectAssignment, "address.postalCode") },
      locality: {
        value: value(objectAssignment, "address.locality"),
        onChange: (ref) => {
          this.findSublocalitySuggestions(get(ref, "value.id"));
          this.findStreetSuggestions(get(ref, "value.id"));
        },
      },
      sublocality: { value: value(objectAssignment, "address.sublocality") },
      countryIso2: {
        value: value(objectAssignment, "address.countryIso2"),
        onChange: (ref) => {
          this.findAdminAreaOneSuggestions(ref.value);
        },
      },
      adminAreaLevel1: {
        value: value(objectAssignment, "address.adminAreaLevel1"),
        onChange: (ref) => {
          this.findAdminAreaTwoSuggestions(get(ref, "value.id"));
          this.findLocalitySuggestions(get(ref, "value.id"));
        },
      },
      adminAreaLevel2: {
        value: value(objectAssignment, "address.adminAreaLevel2"),
        onChange: (ref, getValue) => {
          this.findLocalitySuggestions(
            get(getValue("adminAreaLevel1"), "value.id"),
            get(ref, "value.id")
          );
        },
      },
      geoLocation: {
        value: value(objectAssignment, "address.geoLocation"),
      },
      locationPlaces: { value: enrichedLocationPlaces },
      hideHouseNumber: {
        value: value(objectAssignment, "hideHouseNumber", false),
        onChange: (ref) => {
          if (this.shouldShowHouseNumberWarning()) return;
          this.setState({ hideHouseNumberWarningVisible: get(ref, "value") });
        },
      },
      nameOfBuilding: { value: value(objectAssignment, "nameOfBuilding") },
      hideAddress: {
        value: value(this.props.objectAssignment, "hideAddress", false),
      },
    };

    const { match } = this.props;

    if (!!match.params.caller) {
      const callerUri = atob(match.params.caller);
      this.startAtCadastre = true;
      const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
        id: objectAssignment.id,
      });
      this.props.setCaller(callerUri, pathname);
    }

    // Bind this
    this.onAddressSearchChangeHandler =
      this.onAddressSearchChangeHandler.bind(this);
    this.onAddressChangeHandler = this.onAddressChangeHandler.bind(this);
    this.findAdminAreaOneSuggestions =
      this.findAdminAreaOneSuggestions.bind(this);
    this.findAdminAreaTwoSuggestions =
      this.findAdminAreaTwoSuggestions.bind(this);
    this.findLocalitySuggestions = this.findLocalitySuggestions.bind(this);
    this.findSublocalitySuggestions =
      this.findSublocalitySuggestions.bind(this);
    this.findStreetSuggestions = this.findStreetSuggestions.bind(this);
    this.onAddFundaAddress = this.onAddFundaAddress.bind(this);
    this.hasFullFundaAddress = this.hasFullFundaAddress.bind(this);
    this.onKeyChangeHandler = this.onKeyChangeHandler.bind(this);
    this.onMapChangeHandler = this.onMapChangeHandler.bind(this);
    this.renderSelectedLocationPlaceValue =
      this.renderSelectedLocationPlaceValue.bind(this);
    this.onSelectAssignment = this.onSelectAssignment.bind(this);
    this.shouldShowHouseNumberWarning =
      this.shouldShowHouseNumberWarning.bind(this);
  }

  public componentDidMount() {
    (async () => {
      const countrySuggestions = await AddressRequest.getCountries();
      this.setState({ countrySuggestions });

      this.findAdminAreaOneSuggestions(
        get(this.props.objectAssignment, "address.countryIso2")
      );

      this.findAdminAreaTwoSuggestions(
        get(this.props.objectAssignment, "address.adminAreaLevel1.id")
      );

      this.findLocalitySuggestions(
        get(this.props.objectAssignment, "address.adminAreaLevel1.id"),
        get(this.props.objectAssignment, "address.adminAreaLevel2.id")
      );

      this.findSublocalitySuggestions(
        get(this.props.objectAssignment, "address.locality.id")
      );

      this.findStreetSuggestions(
        get(this.props.objectAssignment, "address.locality.id")
      );
    })();

    this.shouldShowHouseNumberWarning();
  }

  public render() {
    const { locationPlaces, objectAssignment } = this.props;
    const {
      enrichAddress,
      adminAreaOneSuggestions,
      adminAreaTwoSuggestions,
      localitySuggestions,
      sublocalitySuggestions,
      countrySuggestions,
      streetSuggestions,
    } = this.state;
    const {
      address,
      hideAddress,
      hideHouseNumber,
      linkedObjectTypeAssignment,
      isNew,
    } = objectAssignment;
    const geo: GeoLocation = !!this.state.addressResult
      ? get(this.state.addressResult, "geoLocation")
      : get(address, "geoLocation") || {
          latitude: null,
          longitude: null,
        };

    const mapInfo =
      !!geo?.latitude && !!geo?.longitude ? "mapInfo" : "locationNotProvided";
    const addressSearchFormControls: FormControls = {
      address: { value: "" },
    };
    const countryIso2 = get(address, "countryIso2") || this.props.countryIso2;
    const suggestionAddress = this.state.addressResult || address;

    return (
      <div styleName="address" id="scroll-to-top">
        <div className="container-fluid">
          <StepperComponent
            initial={this.startAtCadastre ? 1 : 0}
            scrollToElementId="scroll-to-top"
          >
            <StepComponent title="addressData">
              <div styleName="address-search">
                <Form
                  name="address-search"
                  onChange={this.onAddressSearchChangeHandler}
                  formControls={addressSearchFormControls}
                >
                  <div className="form__row">
                    <Input.LocationQueryV2
                      name="address"
                      countries={this.props.countries}
                      countryIso2={countryIso2}
                      culture={this.props.culture}
                      backgroundColor="#f2f2f2"
                    />
                  </div>
                </Form>
              </div>

              <FeatureSwitch feature="MLS">
                {!this.props.currentComponentState?.receivedDataFromMLS &&
                  (isNew || this.state.newAddress) && (
                    <MatchingProperties
                      address={suggestionAddress}
                      onSelectAssignment={this.onSelectAssignment}
                    />
                  )}
              </FeatureSwitch>

              <div styleName="address-form">
                <Form
                  name="address"
                  onChange={this.onAddressChangeHandler}
                  formControls={this.formControls}
                  form={(ref) => (this.formRef = ref)}
                >
                  <FeatureSwitch feature="BORDER_ADDRESS">
                    {!!this.hasFullFundaAddress() && (
                      <div className="form__row">
                        <label>
                          <I18n value="hasFundaAddress.addressLabel" />
                        </label>
                        <div
                          styleName="funda-address"
                          onClick={() =>
                            this.setState({ fundaModalVisible: true })
                          }
                        >
                          <div styleName="inner">{`${this.props.objectAssignment.fundaStreet}, ${this.props.objectAssignment.fundaPostalCode}, ${this.props.objectAssignment.fundaLocality}`}</div>
                          <i className="fal fa-pencil" />
                        </div>
                      </div>
                    )}
                  </FeatureSwitch>

                  {/* Main address */}
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="streetName">
                        <label htmlFor="streetName">
                          <I18n value="streetName" />
                        </label>
                        <Input.Query
                          name="street"
                          placeholder="addressQueryPlaceholder"
                          values={streetSuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: AddressDetail) => ({
                            value: c,
                            resultString: c.name,
                          })}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!enrichAddress}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="houseNumber">
                        <label htmlFor="houseNumber">
                          <I18n value="houseNumber" />
                        </label>
                        <Input.Number
                          name="houseNumber"
                          data-cy="CY-addressHouseNumberInput"
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="houseNumberPostfix">
                        <label htmlFor="houseNumberPostfix">
                          <I18n value="houseNumberPostfix" />
                        </label>
                        <Input.Text
                          name="houseNumberPostfix"
                          data-cy="CY-addressHouseNumberPostfixInput"
                        />
                      </div>

                      {!!linkedObjectTypeAssignment && (
                        <>
                          <div className="column__spacer" />
                          <div
                            className="column"
                            styleName="constructionNumber"
                          >
                            <label htmlFor="constructionNumber">
                              <I18n value="constructionNumber" />
                            </label>
                            <Input.Text name="constructionNumber" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Housenumber visibility */}
                  {(!!hideHouseNumber ||
                    !!this.props
                      .enableHideHouseNumberForObjectAssignmentsOption) && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column">
                          <Input.Switch
                            name="hideHouseNumber"
                            on={true}
                            off={false}
                            label="hideHouseNumber"
                            labelPosition={SwitchLabelPosition.Pre}
                          />
                        </div>
                        <div className="column__spacer" />
                        <div className="column as-input">
                          <Ui.InfoLink
                            supportLink={SUPPORTURI.HIDEHOUSENUMBER}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <HouseNumberWarningComponent
                    isResidential={
                      this.props.objectAssignment.realEstateGroup ===
                      RealEstateGroup.Residential
                    }
                    isRecreational={this.props.objectAssignment.isRecreational}
                    constructionNumber={
                      this.props.objectAssignment.constructionNumber
                    }
                    hideHouseNumberWarningVisible={
                      this.state.hideHouseNumberWarningVisible
                    }
                  />

                  {/* Secondary address */}
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="postalCode">
                        <label htmlFor="postalCode">
                          <I18n value="postalCode" />
                        </label>
                        <Input.Text
                          name="postalCode"
                          data-cy={"CY-PostalCode"}
                        />
                      </div>
                      <div className="column__spacer" />
                      <div className="column" styleName="locality">
                        <label htmlFor="locality">
                          <I18n value="locality" />
                        </label>
                        <Input.Query
                          name="locality"
                          placeholder="addressQueryPlaceholder"
                          values={localitySuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: AddressDetail) => ({
                            value: c,
                            resultString: c.name,
                          })}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!enrichAddress}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="sublocality">
                        <label htmlFor="sublocality">
                          <span>
                            <I18n value="sublocality" />
                          </span>
                          <Ui.InfoLink supportLink={SUPPORTURI.SUBLOCALITY} />
                        </label>
                        <Input.Query
                          name="sublocality"
                          placeholder="addressQueryPlaceholder"
                          values={sublocalitySuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: AddressDetail) => ({
                            value: c,
                            resultString: c.name,
                          })}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!sublocalitySuggestions.length}
                        />
                      </div>

                      <div className="column__spacer" />

                      <div className="column" styleName="adminAreaLevel2">
                        <label htmlFor="adminAreaLevel2">
                          <I18n value="adminAreaLevel2" />
                        </label>
                        <Input.Query
                          name="adminAreaLevel2"
                          placeholder="addressQueryPlaceholder"
                          values={adminAreaTwoSuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: AddressDetail) => ({
                            value: c,
                            resultString: c.name,
                          })}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!enrichAddress}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__group">
                      <div className="column" styleName="adminAreaLevel1">
                        <label htmlFor="adminAreaLevel1">
                          <I18n value="adminAreaLevel1" />
                        </label>
                        <Input.Query
                          name="adminAreaLevel1"
                          placeholder="addressQueryPlaceholder"
                          values={adminAreaOneSuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: AddressDetail) => ({
                            value: c,
                            resultString: c.name,
                          })}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!enrichAddress}
                        />
                      </div>

                      <div className="column__spacer" />

                      <div className="column" styleName="countryIso2">
                        <label htmlFor="countryIso2">
                          <I18n value="countryIso2" />
                          &nbsp;
                          <Ui.RequiredForPublish />
                        </label>
                        <Input.Query
                          name="countryIso2"
                          placeholder="addressQueryPlaceholder"
                          values={countrySuggestions}
                          matchOn={(q, v: AddressDetail) =>
                            new RegExp(escapeRegExp(q), "gi").test(v.name)
                          }
                          selectedStringValue={(c: string | AddressDetail) => {
                            const resultString = isString(c)
                              ? (
                                  this.props.countries.find(
                                    (country) => country.iso2CodeValue === c
                                  ) || { displayName: "" }
                                ).displayName
                              : c.name;

                            return {
                              value: isString(c)
                                ? c
                                : (
                                    this.props.countries.find(
                                      (country) =>
                                        country.displayName === c.name
                                    ) || { iso2CodeValue: "NL" }
                                  ).iso2CodeValue,
                              resultString,
                            };
                          }}
                          optionValue={(v: AddressDetail, q) => (
                            <div>{v.name}</div>
                          )}
                          multiple={false}
                          disabled={!enrichAddress}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Building name */}
                  {this.props.objectAssignment.realEstateGroup ===
                    RealEstateGroup.Commercial && (
                    <div className="form__row">
                      <label htmlFor="nameOfBuilding">
                        <I18n value="nameOfBuilding" />
                      </label>
                      <Input.Text
                        name="nameOfBuilding"
                        placeholder="bogListingPlaceholder"
                      />
                    </div>
                  )}

                  {/* Location places */}
                  <div className="form__row">
                    <label htmlFor="locationPlaces">
                      <I18n value="locationPlaces" />
                    </label>
                    <div className="input__wrapper">
                      <Input.Query
                        name="locationPlaces"
                        optionValue={this.renderQueryLocationPlaceOption}
                        values={locationPlaces}
                        matchOn={this.locationPlaceQueryMatchOn}
                        selectedValue={this.renderSelectedLocationPlaceValue}
                        multiple
                        placeholder="locationPlacesPlaceholder"
                        openOnDeleteOption={false}
                        disableOption={(
                          value: LocationPlaceOption,
                          values: LocationPlaceOption[]
                        ) => {
                          return values.includes(value);
                        }}
                      />
                    </div>
                  </div>

                  {/* Hide address */}
                  {(!!hideAddress ||
                    !!this.props
                      .enableHideAddressForObjectAssignmentsOption) && (
                    <div className="form__row">
                      <div className="form__group">
                        <div className="column">
                          <Input.Switch
                            name="hideAddress"
                            labelPosition={SwitchLabelPosition.Pre}
                            on={true}
                            off={false}
                            label="hideAddress"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <Keys
                    keyNr={this.props.objectAssignment.keyNr}
                    keyNote={this.props.objectAssignment.keyNote}
                    onChange={this.onKeyChangeHandler}
                  />
                </Form>
                {/* Google maps */}
                <div styleName="address-map" className="hidden-xs hidden-sm">
                  <h2>
                    <span>
                      <I18n value={mapInfo} />
                    </span>
                    <Ui.InfoLink supportLink={SUPPORTURI.ADDRESSDRAGMARKER} />
                  </h2>
                  <div styleName="map">
                    <MapComponent
                      lat={geo.latitude}
                      lng={geo.longitude}
                      addMarker={!!address}
                      canAssignNewLocation
                      centerChanged={this.onMapChangeHandler}
                    />
                  </div>
                  {!!hideHouseNumber && (
                    <div styleName="hide-housenumber-info">
                      <Icon
                        name="info-circle"
                        size={16}
                        color={Colors.White}
                        solid
                        style={{ marginRight: 6 }}
                      />
                      <I18n value="hideHouseNumberInfo" />
                    </div>
                  )}
                </div>
              </div>
            </StepComponent>

            <StepComponent title="cadastralData" data-cy="CY-stepCadastre">
              <AssignmentCadastreContainer />
            </StepComponent>
          </StepperComponent>
        </div>

        <FundaModal
          visible={this.state.fundaModalVisible}
          fields={pick(this.props.objectAssignment, [
            "fundaPostalCode",
            "fundaStreet",
            "fundaLocality",
          ])}
          hasFullAddress={this.hasFullFundaAddress()}
          onClose={() => this.setState({ fundaModalVisible: false })}
          onAddFundaAddress={this.onAddFundaAddress}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps) return;

    if (
      !!this.formRef &&
      !deepEqual(
        this.props.objectAssignment.locationPlaces,
        nextProps.objectAssignment.locationPlaces
      )
    ) {
      const locationPlaces = nextProps.objectAssignment.locationPlaces || [];
      const enrichedLocationPlaces = locationPlaces
        .map((locationPlace) =>
          nextProps.locationPlaces.find(
            (place) => place.value === locationPlace
          )
        )
        .filter((place) => !!place);

      this.formRef.update({
        locationPlaces: enrichedLocationPlaces,
      });
    }

    if (
      !!this.formRef &&
      get(this.props.objectAssignment, "dateTimeModified") !==
        get(nextProps.objectAssignment, "dateTimeModified")
    ) {
      const locationPlaces = nextProps.objectAssignment.locationPlaces || [];
      const enrichedLocationPlaces = locationPlaces
        .map((locationPlace) =>
          this.props.locationPlaces.find(
            (place) => place.value === locationPlace
          )
        )
        .filter((place) => !!place);

      this.formRef.update(
        {
          street: value(nextProps.objectAssignment, "address.street"),
          houseNumber: value(nextProps.objectAssignment, "address.houseNumber"),
          houseNumberPostfix: value(
            nextProps.objectAssignment,
            "address.houseNumberPostfix"
          ),
          constructionNumber: value(
            nextProps.objectAssignment,
            "constructionNumber"
          ),
          postalCode: value(nextProps.objectAssignment, "address.postalCode"),
          locality: value(nextProps.objectAssignment, "address.locality"),
          sublocality: value(nextProps.objectAssignment, "address.sublocality"),
          countryIso2: value(nextProps.objectAssignment, "address.countryIso2"),
          adminAreaLevel1: value(
            nextProps.objectAssignment,
            "address.adminAreaLevel1"
          ),
          adminAreaLevel2: value(
            nextProps.objectAssignment,
            "address.adminAreaLevel2"
          ),
          geoLocation: value(nextProps.objectAssignment, "address.geoLocation"),
          locationPlaces: enrichedLocationPlaces,
          hideHouseNumber: value(
            nextProps.objectAssignment,
            "hideHouseNumber",
            false
          ),
          nameOfBuilding: value(nextProps.objectAssignment, "nameOfBuilding"),
          hideAddress: value(nextProps.objectAssignment, "hideAddress", false),
        },
        true
      );
    }
  }

  private shouldShowHouseNumberWarning(): boolean {
    const { houseNumber, houseNumberPostfix, hideHouseNumber } =
      this.formRef.getValues();
    const showWarning =
      (houseNumber.toString() === "0" &&
        !!houseNumberPostfix &&
        houseNumberPostfix.toLowerCase() === "ong") ||
      hideHouseNumber;

    this.setState({ hideHouseNumberWarningVisible: showWarning });
    return showWarning;
  }

  private onAddressSearchChangeHandler(values: FormReturnValue) {
    if (this.state.addressResult) {
      this.clearCurrentFormValues();
    }

    const addressResult: Address = values.address;
    this.setState({
      addressResult,
      newAddress: true,
      showMap: true,
    });

    this.formRef.update({
      street: addressResult.street || "",
      houseNumber: addressResult.houseNumber || "",
      houseNumberPostfix: addressResult.houseNumberPostfix || "",
      postalCode: addressResult.postalCode || "",
      locality: addressResult.locality || "",
      sublocality: addressResult.sublocality || "",
      countryIso2: addressResult.countryIso2 || "",
      adminAreaLevel1: addressResult.adminAreaLevel1 || "",
      adminAreaLevel2: addressResult.adminAreaLevel2 || "",
      geoLocation: addressResult.geoLocation || "",
    });
  }

  private clearCurrentFormValues() {
    this.formRef.update({
      street: "",
      houseNumber: "",
      houseNumberPostfix: "",
      postalCode: "",
      locality: "",
      sublocality: "",
      countryIso2: "",
      adminAreaLevel1: "",
      adminAreaLevel2: "",
    });

    this.setState({
      showMap: false,
    });
  }

  private onKeyChangeHandler(
    keyNr: number | undefined,
    keyNote: string | undefined
  ) {
    const { objectAssignment, currentComponentState } = this.props;
    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    });

    const newState: SingleAssignmentState = {
      ...currentComponentState,
      objectAssignment: {
        ...currentComponentState.objectAssignment,
        keyNr,
        keyNote,
      },
    };

    this.props.updateAssignment(newState, pathname);
  }

  private onAddressChangeHandler(values: FormReturnValue) {
    const { objectAssignment, currentComponentState } = this.props;
    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    });

    const address: Address = {
      ...objectAssignment.address,
      adminAreaLevel1: values.adminAreaLevel1,
      adminAreaLevel2: values.adminAreaLevel2,
      countryIso2: values.countryIso2,
      houseNumber: values.houseNumber,
      houseNumberPostfix: values.houseNumberPostfix,
      locality: values.locality,
      sublocality: values.sublocality,
      postalCode: values.postalCode,
      street: values.street,
      geoLocation: values.geoLocation,
    };

    const locationPlaces: LocationPlaceOption[] = values.locationPlaces || [];
    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      address,
      hideHouseNumber: values.hideHouseNumber,
      locationPlaces: locationPlaces.map(({ value }) => value),
      nameOfBuilding: values.nameOfBuilding,
      hideAddress: values.hideAddress,
      constructionNumber: values.constructionNumber,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.props.updateAssignment(newState, pathname);
    if (!!get(values, "locality.id")) {
      this.props.searchSublocalities(get(values, "locality.id"));
    }
  }

  private onMapChangeHandler(values: any) {
    const { objectAssignment, currentComponentState } = this.props;
    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    });

    const address: Address = {
      ...objectAssignment.address,
      geoLocation: {
        latitude: values.lat,
        longitude: values.lng,
      },
    };

    const updatedObjectAssignment: ObjectAssignment = {
      ...objectAssignment,
      address,
    };

    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssignment,
    };

    this.setState({ addressResult: address });
    this.props.updateAssignment(newState, pathname);
  }

  private renderQueryLocationPlaceOption(
    value: LocationPlaceOption,
    query: string
  ): QueryOptionReturnValue {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(value.displayName, query)}
      />
    );
  }

  private locationPlaceQueryMatchOn(
    query: string,
    value: LocationPlaceOption
  ): boolean {
    const matchOn = new RegExp(escapeRegExp(query), "gi");
    return matchOn.test(value.displayName);
  }

  private renderSelectedLocationPlaceValue(
    value: LocationPlaceOption
  ): QueryResultReturnValue<any> {
    return {
      value,
      template: <div>{value.displayName}</div>,
    };
  }

  private async findAdminAreaOneSuggestions(countryIso2?: string) {
    if (!countryIso2) return;

    const country = this.props.countries.find(
      (country) => country.iso2CodeValue === countryIso2
    );
    if (!country) return;
    const refCountry = this.state.countrySuggestions.find(
      (refCountry) => refCountry.name === country.displayName
    );
    if (!refCountry) return;
    const adminAreaOneSuggestions =
      await AddressRequest.getAdminAreaOneSuggestions(refCountry.id);

    this.setState({ adminAreaOneSuggestions });
  }

  private async findAdminAreaTwoSuggestions(adminAreaLevel1Id?: number) {
    if (!adminAreaLevel1Id) return;

    const adminAreaTwoSuggestions =
      await AddressRequest.getAdminAreaTwoSuggestions(adminAreaLevel1Id);

    this.setState({ adminAreaTwoSuggestions });
  }

  private async findLocalitySuggestions(
    adminAreaLevel1Id?: number,
    adminAreaLevel1Id2?: number
  ) {
    if (!adminAreaLevel1Id) return;

    const localitySuggestions = await AddressRequest.getLocalitySuggestions(
      adminAreaLevel1Id,
      adminAreaLevel1Id2
    );

    this.setState({ localitySuggestions });
  }

  private async findSublocalitySuggestions(localityId?: number) {
    if (!localityId) return;

    const sublocalitySuggestions =
      await AddressRequest.getSublocalitySuggestions(localityId);

    this.setState({ sublocalitySuggestions });
  }

  private async findStreetSuggestions(localityId?: number) {
    if (!localityId) return;

    const streetSuggestions = await AddressRequest.getStreetSuggestions(
      localityId
    );

    this.setState({ streetSuggestions });
  }

  private onAddFundaAddress(values: FundaAddressFields) {
    const { objectAssignment, currentComponentState } = this.props;
    const updatedObjectAssigment: ObjectAssignment = {
      ...objectAssignment,
      ...values,
    };
    const pathname = route(ASSIGNMENTROUTES.DETAIL.URI, {
      id: objectAssignment.id,
    });
    const newState = {
      ...currentComponentState,
      objectAssignment: updatedObjectAssigment,
    };

    this.setState({ fundaModalVisible: false });
    this.props.updateAssignment(newState, pathname);
  }

  private hasFullFundaAddress() {
    const { fundaLocality, fundaPostalCode, fundaStreet } = pick(
      this.props.objectAssignment,
      ["fundaPostalCode", "fundaStreet", "fundaLocality"]
    );
    return !!fundaLocality && !!fundaPostalCode && !!fundaStreet;
  }

  private async onSelectAssignment(realEstateProperty: RealEstateProperty) {
    this.props.mapValuesToObjectAssignment(
      MlsUtil.mapToObjectAssignment(realEstateProperty)
    );
  }
}
