import * as React from "react";
import { FC, memo, MutableRefObject } from "react";
import ContextMenu, {
  ContextMenuPosition
} from "@haywork/components/context-menu";
import Icon from "@haywork/components/ui/icon";
import * as CSSModules from "react-css-modules";
import { Colors } from "@haywork/enum/colors";

const styles = require("./style.scss");

type Props = {
  visible: boolean;
  parent: MutableRefObject<HTMLElement>;
  push?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  onClose: () => void;
};

export const FilterContextMenu: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, visible, parent, push, onClose }) => {
      return (
        <ContextMenu
          visible={visible}
          parent={parent}
          position={ContextMenuPosition.TopRight}
          push={push}
          onClickOutside={onClose}
          onScroll={onClose}
        >
          <div styleName="context-menu">
            <div styleName="body">{children}</div>
            <div styleName="close" onClick={onClose}>
              <Icon name="times" color={Colors.Primary} size={16} light />
            </div>
          </div>
        </ContextMenu>
      );
    }
  )
);
