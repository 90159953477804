import {
  CivilStateOption,
  ContactPerson,
  ContactPersonTitleSuggestionOption,
  CountryOption,
  EmailAddressTypeOption,
  Gender,
  IdentificationTypeOption,
  PhoneNumberTypeOption,
  RelationGroupSnapShot,
  RelationType,
  RelationSnapShot,
} from "@haywork/api/kolibri";
import { COLOR_PICKER, RELATIONROUTES } from "@haywork/constants";
import { RelationGroupsThunk } from "@haywork/middleware";
import { Dispatch, RelationThunks } from "@haywork/middleware/thunk";
import {
  ContactPersonEditComponent,
  ContactPersonEditComponentProps,
} from "@haywork/modules/relation/components/edit/contactperson-edit.component";
import { AppState, EditableActions, EditableItem } from "@haywork/stores";
import { ColorUtil, RouteUtil } from "@haywork/util";
import { push } from "connected-react-router";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgencyId: string;
  contactPerson: ContactPerson;
  selectedRelationType: RelationType;
  phoneNumberTypes: PhoneNumberTypeOption[];
  emailAddressTypes: EmailAddressTypeOption[];
  contactPersonTitleSuggestions: ContactPersonTitleSuggestionOption[];
  civilStateOptions: CivilStateOption[];
  identificationTypeOptions: IdentificationTypeOption[];
  saveRelationContactInfoState: string;
  relationAddressSearchState: string;
  countries: CountryOption[];
  host: string;
  path: string;
  relationGroups: RelationGroupSnapShot[];
  relationAddGroupsStatus: string;
  latestRelationGroupAdded: RelationGroupSnapShot;
  countryIso2: string;
  culture: string;
}
interface DispatchProps {
  saveContactPerson: (person: ContactPerson, path: string) => void;
  navigate: (routePath: string) => void;
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) => void;
  removeAddress: (isPostalAddress: boolean, id: string) => void;
  togglePostalAddress: (id: string) => void;
  updateContactPerson: (componentState: ContactPerson, path: string) => void;
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    relationId: string,
    relationType: RelationType
  ) => void;
  addNewRelationGroup: (name: string) => void;
  reloadContactPerson: (editable: EditableItem) => Promise<void>;
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    email: string[],
    phoneNumber?: string
  ) => Promise<RelationSnapShot[]>;
}

const mapStateToProps = <StateProps, ContactPersonEditComponentProps>(
  state: AppState
) => ({
  contactPerson: state.editable.currentComponentState,
  selectedRelationType: state.relation.detail.selectedRelationType,
  countries: state.main.mastertable.kolibri.countries,
  phoneNumberTypes: state.main.mastertable.kolibri.phoneNumberTypes,
  emailAddressTypes: state.main.mastertable.kolibri.emailAddressTypes,
  contactPersonTitleSuggestions: reduceContactPersonTitleSuggestionOptions(
    state.main.mastertable.kolibri.contactPersonTitleSuggestions,
    state.editable.currentComponentState.gender
  ),
  civilStateOptions: state.main.mastertable.kolibri.civilStates,
  realEstateAgencyId: state.account.currentRealestateAgency.id,
  identificationTypeOptions: state.main.mastertable.kolibri.identificationTypes,
  saveRelationContactInfoState:
    state.relation.detail.saveRelationContactInfoState,
  relationAddressSearchState: state.relation.detail.relationAddressSearchState,
  host: state.appSettings.host,
  path: RouteUtil.mapStaticRouteValues(
    RELATIONROUTES.CONTACT_PERSON_DETAIL.URI,
    {
      id: state.relation.detail.selectedContactPerson.id,
    }
  ),
  relationGroups: state.relationGroups.relationGroups,
  relationAddGroupsStatus: state.relationGroups.relationAddGroupsStatus,
  latestRelationGroupAdded: state.relationGroups.latestRelationGroupAdded,
  countryIso2: "NL", // work around, default country needs to be selectable in settings.
  culture: state.main.culture,
});

const mapDispatchToProps = <DispatchProps, ContactPersonEditComponentProps>(
  dispatch: Dispatch<any>
) => ({
  saveContactPerson: (person: ContactPerson, path: string) =>
    dispatch(RelationThunks.saveContactPerson(person, path)),
  navigate: (routePath: string) => dispatch(push(routePath)),
  searchAddress: (
    value: string,
    country: string,
    isPostalAddress: boolean,
    id: string
  ) =>
    dispatch(
      RelationThunks.searchAddress(
        value,
        country,
        isPostalAddress,
        id,
        RelationType.ContactPerson
      )
    ),
  removeAddress: (isPostalAddress: boolean, id: string) =>
    dispatch(
      RelationThunks.removeRelationAddress(
        isPostalAddress,
        id,
        RelationType.ContactPerson
      )
    ),
  addManualAddress: (
    newAddress: string,
    isPostalAddress: boolean,
    relationId: string,
    relationType: RelationType
  ) =>
    dispatch(
      RelationThunks.addManualAddress(
        newAddress,
        isPostalAddress,
        relationId,
        relationType
      )
    ),
  togglePostalAddress: (id: string) =>
    dispatch(
      RelationThunks.toggleRelationPostalAddress(id, RelationType.ContactPerson)
    ),
  updateContactPerson: (componentState: ContactPerson, path: string) =>
    dispatch(
      EditableActions.updateComponentState({
        componentState,
        path,
      })
    ),
  addNewRelationGroup: (name: string) =>
    dispatch(
      RelationGroupsThunk.addNewRelationGroup(
        ColorUtil.rgbToHex(COLOR_PICKER.DEFAULT_COLOR),
        name
      )
    ),
  reloadContactPerson: (editable: EditableItem) =>
    dispatch(RelationThunks.reloadContactCompany(editable)),
  getRelationsWithMatchingEmailAddressOrPhoneNumber: (
    email: string[],
    phoneNumber?: string
  ) =>
    dispatch(
      RelationThunks.getRelationsWithMatchingEmailAddressOrPhoneNumber(
        email,
        [RelationType.ContactPerson],
        phoneNumber
      )
    ),
});

export type ContactPersonEditWrapperContainerProps = StateProps & DispatchProps;
export const ContactPersonEditWrapperContainer = connect<
  StateProps,
  DispatchProps,
  ContactPersonEditComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ContactPersonEditComponent);

const reduceContactPersonTitleSuggestionOptions = (
  titles: ContactPersonTitleSuggestionOption[],
  gender: Gender
): ContactPersonTitleSuggestionOption[] => {
  switch (gender) {
    case Gender.Female:
      return titles.filter((option) => option.usedForFemales === true);
    case Gender.Male:
      return titles.filter((option) => option.usedForMales === true);
    case Gender.Other:
      return titles.filter((option) => option.usedForUnknownGender === true);
    default:
      return titles;
  }
};
