import * as React from "react";
import * as CSSModules from "react-css-modules";

import WidgetListItem from "../widget-list-item";
import { TimelineEvent } from "@haywork/api/kolibri";
import { ResourceText } from "@haywork/modules/shared";
import { ErrorBoundary } from "@haywork/modules/error-boundary";

const styles = require("./style.scss");

interface Props {
  timelineEvents: TimelineEvent[];
  headerResourceKey: string;
  emptyStateTitleResourceKey: string;
  emptyStateDescriptionResourceKey: string;
  hideEmptyStateImage: boolean;
  onNavigate: (path: string) => void;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class WidgetComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.getUpcomingTimelinEvents = this.getUpcomingTimelinEvents.bind(this);
    this.getPastTimelinEvents = this.getPastTimelinEvents.bind(this);
  }

  public render() {
    const timelineEvents = this.props.timelineEvents;

    if (!timelineEvents || (timelineEvents && timelineEvents.length === 0)) {
      return (
        <div styleName="timelineWidget">
          <h3 styleName="title">
            <ResourceText resourceKey={this.props.headerResourceKey} />
          </h3>
          <div styleName="emptyState">
            {this.props.hideEmptyStateImage ? "" : <div styleName="icon" />}
            <div styleName="text">
              <h2>
                <ResourceText
                  resourceKey={this.props.emptyStateTitleResourceKey}
                />
              </h2>
              <p>
                <ResourceText
                  resourceKey={this.props.emptyStateDescriptionResourceKey}
                />
              </p>
            </div>
          </div>
        </div>
      );
    }

    const upcomingTimelineEvents = this.getUpcomingTimelinEvents();
    const pastTimelineEvents = this.getPastTimelinEvents();

    return (
      <div styleName="timelineWidget">
        {upcomingTimelineEvents && upcomingTimelineEvents.length > 0 && (
          <div>
            <h3 styleName="title">
              <ResourceText resourceKey="aroTimeLineWidgetTitle" />
            </h3>
            <div styleName="timelineItems">
              <ul>
                {upcomingTimelineEvents.map((timelineEvent, idx) => (
                  <ErrorBoundary key={idx}>
                    <WidgetListItem
                      timelineEvent={timelineEvent}
                      onClick={this.props.onNavigate}
                    />
                  </ErrorBoundary>
                ))}
              </ul>
            </div>
          </div>
        )}
        {pastTimelineEvents && pastTimelineEvents.length > 0 && (
          <div>
            <h3 styleName="title">
              <ResourceText resourceKey="pastEvents" />
            </h3>
            <div styleName="timelineItems">
              <ul>
                {pastTimelineEvents.map((timelineEvent, idx) => (
                  <ErrorBoundary key={idx}>
                    <WidgetListItem
                      timelineEvent={timelineEvent}
                      onClick={this.props.onNavigate}
                    />
                  </ErrorBoundary>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }

  private getUpcomingTimelinEvents(): TimelineEvent[] {
    const timelineEvents: TimelineEvent[] = [];

    for (let i = 0; i < this.props.timelineEvents.length; i++) {
      const timelineEvent = this.props.timelineEvents[i];
      let date: Date;

      if (timelineEvent.date) {
        date = new Date(timelineEvent.date);
      } else if (timelineEvent.endDate) {
        date = new Date(timelineEvent.endDate);
      }
      if (date >= new Date()) {
        timelineEvents.push(timelineEvent);
      }
    }
    return timelineEvents;
  }

  private getPastTimelinEvents(): TimelineEvent[] {
    return this.props.timelineEvents.map((timelineEvent) => {
      let date: Date;
      if (timelineEvent.date) {
        date = new Date(timelineEvent.date);
      } else if (timelineEvent.endDate) {
        date = new Date(timelineEvent.endDate);
      }

      if (date < new Date()) {
        return timelineEvent;
      }
    });
  }
}
