import { MutationType } from "@haywork/api/mls";
import I18n from "@haywork/components/i18n";
import EmptyState from "@haywork/components/ui/empty-state";
import PageHeader from "@haywork/components/ui/page-header";
import { Input } from "@haywork/modules/form";
import * as React from "react";
import { FC, forwardRef, memo, useCallback, useState } from "react";
import * as CSSModules from "react-css-modules";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import Row from "./components/row";
import { TimelineContainerProps } from "./timeline.container";

const styles = require("./style.scss");

export type TimelineComponentProps = {};
type Props = TimelineComponentProps & TimelineContainerProps;

const InnerElementType = forwardRef<any, any>(
  CSSModules(styles, { allowMultiple: true })(
    ({ children, total, ...rest }, ref) => {
      return (
        <div {...rest} ref={ref}>
          {total === 0 && (
            <div styleName="empty-state">
              <EmptyState
                icon="align-left"
                title="timeline.emptyStateList.title"
                subTitle="timeline.emptyStateList.subTitle"
              />
            </div>
          )}
          {children}
        </div>
      );
    }
  )
);

export const TimelineComponent: FC<Props> = memo(
  CSSModules(styles)(({ mutationTypeOptions, mutations, forRentOrSale }) => {
    const [timelineActionType, setTimelineActionType] = useState<
      MutationType | ""
    >("");
    const [items, setItems] = useState(mutations);

    const onChangeFilter = useCallback(
      (type: MutationType | "") => {
        setTimelineActionType(type);
        setItems(mutations.filter((item) => !type || item.type === type));
      },
      [setTimelineActionType, setItems, mutations]
    );

    const row: FC<ListChildComponentProps> = (props) => {
      const { index } = props;
      const rowData = items[index];

      return !rowData ? null : (
        <Row mutation={rowData} forRentOrSale={forRentOrSale} {...props} />
      );
    };

    return (
      <>
        <div styleName="timeline">
          <PageHeader title="timeline" subTitle="mls.pageHeader.general" />

          <div styleName="timeline__filter">
            <I18n value="timeline.filter.prefix" />
            <div styleName="filter__input">
              <Input.NewSelect
                name="timelineActionType"
                values={mutationTypeOptions}
                valuesProp="value"
                displayProp="displayName"
                addEmptyOption
                emptyOptionLabel="timelineActionType.All"
                value={timelineActionType}
                onChange={onChangeFilter}
              />
            </div>
          </div>

          <div styleName="timeline__list">
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  innerElementType={(props) => (
                    <InnerElementType {...props} total={items.length} />
                  )}
                  itemSize={50}
                  itemCount={items.length}
                  height={height}
                  width={width}
                >
                  {row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </>
    );
  })
);
