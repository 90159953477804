import { ParseRequest } from "@haywork/services";
import { Dispatch } from "../";
import { AppState, VoipActions } from "@haywork/stores";
import {
  RelationsClient,
  RelationOrderByField,
  ActiveFilter,
  SortOrder,
  RelationSnapShot,
  CommunicationLogsClient,
  CommunicationLogType,
  CommunicationLogDirection,
  LinkedRelation,
  LinkedAssignment,
  EmployeesClient,
  RelationType
} from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import * as moment from "moment";
import { RequestStatus } from "@haywork/enum";
import get from "lodash-es/get";

const parseRequest = new ParseRequest();

const searchRelation = (phoneNumber: string, conversationId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      VoipActions.Calls.setLinkedRelationSearchStatus(
        RequestStatus.Pending,
        conversationId
      )
    );

    if (!phoneNumber || phoneNumber.length < 6) {
      dispatch(VoipActions.Calls.setLinkedRelations([], conversationId));
      return;
    }

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Relation = new RelationsClient(host);

    try {
      const relations = await parseRequest.response(
        Relation.search(
          {
            includeStatistics: false,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take: 25,
            phoneNumber
          },
          realEstateAgencyId
        ).then((response) => response.results)
      );

      dispatch(VoipActions.Calls.setLinkedRelations(relations, conversationId));
    } catch (error) {
      dispatch(
        VoipActions.Calls.setLinkedRelationSearchStatus(
          RequestStatus.Error,
          conversationId
        )
      );
      throw error;
    }
  };
};

const addCommunicationLog = (
  relation: RelationSnapShot,
  conversationId: string,
  direction: CommunicationLogDirection,
  subject: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const { employee } = state.account;

    const CommunicationLogs = new CommunicationLogsClient(host);

    try {
      let communicationLog = await parseRequest.response(
        CommunicationLogs.defineNew({}, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );

      communicationLog = {
        ...communicationLog,
        date: moment()
          .utc()
          .toDate(),
        linkedRelations: [
          {
            id: relation.id,
            typeOfRelation: relation.typeOfRelation
          }
        ],
        linkedEmployees: [
          {
            id: employee.id
          }
        ],
        type: CommunicationLogType.PhoneCall,
        direction,
        subject
      };

      const savedCommunicationLog = await parseRequest.response(
        CommunicationLogs.save({ communicationLog }, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );

      dispatch(
        VoipActions.Calls.setLinkedCommunicationLog(
          savedCommunicationLog.id,
          conversationId
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const updateCommunicationLog = (
  communicationLogId: string,
  subject: string,
  note: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CommunicationLogs = new CommunicationLogsClient(host);

    try {
      let communicationLog = await parseRequest.response(
        CommunicationLogs.read(communicationLogId, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );

      communicationLog = {
        ...communicationLog,
        subject,
        description: note,
        summary: StringUtil.trim(note, 128)
      };

      await parseRequest.response(
        CommunicationLogs.save({ communicationLog }, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const getCommunicationLog = (communicationLogId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const CommunicationLogs = new CommunicationLogsClient(host);

    try {
      return await parseRequest.response(
        CommunicationLogs.read(communicationLogId, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

export interface AddManualCommunicationLogRequest {
  relation: LinkedRelation;
  direction: CommunicationLogDirection;
  subject: string;
  description: string;
  employee: LinkedRelation;
  assignment: LinkedAssignment;
}
const addManualCommunicationLog = (
  request: AddManualCommunicationLogRequest
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
    const {
      relation,
      direction,
      subject,
      description,
      employee,
      assignment
    } = request;

    const CommunicationLogs = new CommunicationLogsClient(host);

    try {
      let communicationLog = await parseRequest.response(
        CommunicationLogs.defineNew({}, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );

      communicationLog = {
        ...communicationLog,
        date: moment()
          .utc()
          .toDate(),
        linkedRelations: [relation],
        linkedEmployees: [employee],
        type: CommunicationLogType.PhoneCall,
        direction,
        subject,
        description
      };

      if (!!assignment) {
        communicationLog = {
          ...communicationLog,
          linkedAssignments: [assignment]
        };
      }

      await parseRequest.response(
        CommunicationLogs.save({ communicationLog }, realEstateAgencyId).then(
          (response) => response.communicationLog
        )
      );
    } catch (error) {
      throw error;
    }
  };
};

const searchEmployee = (employeeId: string, conversationId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(
      VoipActions.Calls.setLinkedRelationSearchStatus(
        RequestStatus.Pending,
        conversationId
      )
    );

    const state = getState();
    const { host } = state.appSettings;
    const { id: realEstateAgencyId } = state.account.currentRealestateAgency;

    const Employees = new EmployeesClient(host);

    try {
      const employee = await parseRequest.response(
        Employees.read(employeeId, realEstateAgencyId).then(
          (response) => response.employee
        )
      );

      const relation: RelationSnapShot = {
        dateTimeCreated: employee.dateTimeCreated,
        dateTimeModified: employee.dateTimeModified,
        isActive: employee.isActive,
        typeOfRelation: RelationType.Employee,
        id: employee.id,
        displayName: employee.displayName,
        avatarUrl: get(employee, "passportPhotoBlob.urlPreview"),
        letterAvatar: employee.letterAvatar
      };

      dispatch(
        VoipActions.Calls.setLinkedRelations([relation], conversationId)
      );
    } catch (error) {
      dispatch(
        VoipActions.Calls.setLinkedRelationSearchStatus(
          RequestStatus.Error,
          conversationId
        )
      );
      throw error;
    }
  };
};

export const Calls = {
  searchRelation,
  addCommunicationLog,
  updateCommunicationLog,
  getCommunicationLog,
  addManualCommunicationLog,
  searchEmployee
};
