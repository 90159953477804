import {
  FolderTreeFileEntity,
  FolderTreeFolderEntity,
} from "@haywork/api/kolibri";
import { UploadResponse } from "@haywork/api/mail";
import classNames from "classnames";
import * as React from "react";
import { memo, MouseEvent, useEffect, useRef, useState } from "react";
import * as CSSModules from "react-css-modules";
import {
  ConnectDropTarget,
  DropTarget,
  DropTargetCollector,
  DropTargetSpec,
} from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { Alert, ConfirmComponent, Hint, HintAlignment } from "../../../";
import { ResourceText } from "../../../resource-text/resource-text.component";
import ActionList, { Action } from "../action-list";
import Item from "../item";

const styles = require("./style.scss");

interface DossierFolderComponentProps {
  folder: FolderTreeFolderEntity;
  depth: number;
  parentName: string;
  rootId: string;
  justAddedFiles: string[];
  selectedFiles: string[];
  bulkSelectedFiles: FolderTreeFileEntity[];
  onFileFolderChange: (
    file: FolderTreeFileEntity,
    oldPathName: string,
    newPathName: string
  ) => void;
  onDeleteFolder: (path: string) => void;
  onRefresh: () => void;
  onEditFolder: (folder: FolderTreeFolderEntity, path: string) => void;
  onToggleSelect: (file: FolderTreeFileEntity, pathname: string) => void;
  onFilesDropped: (files: File[], pathname: string) => void;
  onToggleSelectForBulk: (file: FolderTreeFileEntity) => void;
  onCreateMailWithAttachments: (attachments: UploadResponse[]) => void;
}
type Props = DossierFolderComponentProps & {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
};

const dropSpec: DropTargetSpec<DossierFolderComponentProps> = {
  drop: (props, monitor) => {
    switch (monitor.getItemType()) {
      case NativeTypes.FILE: {
        const data = monitor.getItem() as { files: File[] };
        const { folder, parentName, onFilesDropped } = props;
        const pathname = [parentName, folder.name || ""]
          .filter((d) => !!d)
          .join("/");

        onFilesDropped(data.files, pathname);
        break;
      }
      case "DossierFile": {
        const { file, pathname } = monitor.getItem() as {
          file: FolderTreeFileEntity;
          pathname: string;
          selectedFilesCount: number;
        };
        const { onFileFolderChange, folder, parentName } = props;
        const newPathName = [parentName, folder.name || ""]
          .filter((d) => !!d)
          .join("/");
        onFileFolderChange(file, pathname, newPathName);
        break;
      }
      default:
        break;
    }
  },
};

const dropCollect: DropTargetCollector<any, any> = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

export const DroppableDossierFolderComponent = memo(
  DropTarget(
    [NativeTypes.FILE, "DossierFile"],
    dropSpec,
    dropCollect
  )(
    CSSModules(styles, { allowMultiple: true })(
      ({
        folder,
        depth,
        isOver,
        connectDropTarget,
        parentName,
        rootId,
        onFileFolderChange,
        onDeleteFolder,
        onRefresh,
        onEditFolder,
        justAddedFiles,
        selectedFiles,
        onToggleSelect,
        onFilesDropped,
        bulkSelectedFiles,
        onToggleSelectForBulk,
        onCreateMailWithAttachments,
      }: Props) => {
        if (!folder) return null;
        const { isSystemFolder } = folder;
        const root = depth === 1;

        const [collapsed, setCollapsed] = useState(!root);
        const [actionListVisible, setActionListVisible] = useState(false);
        const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
        const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
        const actionRef = useRef(null);

        let { name, folders, files } = folder;
        name = name || "";
        folders = folders || [];
        files = files || [];
        const expandable = !!folders.length || !!files.length;
        const pathname = [parentName, name].filter((d) => !!d).join("/");

        useEffect(() => {
          if (!folders.length && !files.length && !collapsed) {
            setCollapsed(true);
          }
        }, [folders.length, files.length]);

        const toggleCollapsed = () => {
          if (!expandable) return;
          setCollapsed(!collapsed);
        };

        const onActionListClose = () => {
          setActionListVisible(false);
        };

        const handleActionClick = (ev: MouseEvent) => {
          ev.stopPropagation();
          if (isSystemFolder || root || actionListVisible) return;
          setActionListVisible(true);
        };

        const onDeleteClickHandler = () => {
          setActionListVisible(false);
          if (!!folders.length || !!files.length) {
            setDeleteAlertVisible(true);
          } else {
            setDeleteConfirmVisible(true);
          }
        };
        const onEditClickHandler = () => {
          setActionListVisible(false);
          onEditFolder(folder, parentName);
        };

        return (
          <div
            styleName={classNames("folder", {
              collapsed,
              root,
              "system-folder": isSystemFolder,
            })}
          >
            {connectDropTarget(
              <div
                styleName={classNames("header", `depth-${depth}`, {
                  drop: isOver,
                })}
                onClick={toggleCollapsed}
              >
                {expandable && (
                  <div styleName="icon toggle-collapsed">
                    <i className="fal fa-fw fa-chevron-right" />
                  </div>
                )}
                <div styleName="icon">
                  <i
                    className={classNames(
                      "fa",
                      "fa-fw",
                      collapsed ? "fa-folder" : "fa-folder-open"
                    )}
                  />
                </div>
                <div styleName="name">
                  {!root ? (
                    name
                  ) : (
                    <ResourceText
                      masterKey="rootDossierFolder"
                      resourceKey={name}
                      fallback={name}
                    />
                  )}
                </div>
                <div
                  styleName="actions"
                  onClick={handleActionClick}
                  ref={actionRef}
                >
                  <Hint
                    message="dossier.systemOrRootFolderHint"
                    shouldRender={isSystemFolder || root}
                    align={HintAlignment.Left}
                    forceInline
                  >
                    <i
                      className={classNames(
                        "fal",
                        isSystemFolder || root ? "fa-lock" : "fa-ellipsis-v"
                      )}
                    />
                  </Hint>
                </div>
              </div>
            )}
            <div styleName="list">
              {folders.map((folder, idx) => (
                <DroppableDossierFolderComponent
                  folder={folder}
                  depth={depth + 1}
                  parentName={pathname}
                  rootId={rootId}
                  key={(folder.name || "") + idx}
                  onFileFolderChange={onFileFolderChange}
                  onDeleteFolder={onDeleteFolder}
                  onRefresh={onRefresh}
                  onEditFolder={onEditFolder}
                  justAddedFiles={justAddedFiles}
                  selectedFiles={selectedFiles}
                  onToggleSelect={onToggleSelect}
                  onFilesDropped={onFilesDropped}
                  bulkSelectedFiles={bulkSelectedFiles}
                  onToggleSelectForBulk={onToggleSelectForBulk}
                  onCreateMailWithAttachments={onCreateMailWithAttachments}
                />
              ))}
              {files.map((file) => (
                <Item
                  file={file}
                  key={file.id}
                  depth={depth}
                  rootId={rootId}
                  pathname={pathname}
                  onRefresh={onRefresh}
                  justAddedFiles={justAddedFiles}
                  selectedFiles={selectedFiles}
                  bulkSelectedFiles={bulkSelectedFiles}
                  onToggleSelect={onToggleSelect}
                  onToggleSelectForBulk={onToggleSelectForBulk}
                  onCreateMailWithAttachments={onCreateMailWithAttachments}
                />
              ))}
            </div>

            <ActionList
              visible={actionListVisible}
              onClose={onActionListClose}
              parent={actionRef}
            >
              <Action
                label="dossier.folderAction.move"
                onClick={onEditClickHandler}
                icon="fal fa-arrows-v"
              />
              <Action
                label="dossier.folderAction.rename"
                onClick={onEditClickHandler}
                icon="fal fa-pencil"
              />
              <Action
                label="dossier.folderAction.delete"
                onClick={onDeleteClickHandler}
                icon="fal fa-trash"
              />
            </ActionList>

            <Alert
              visible={deleteAlertVisible}
              titleResourceKey="sadly"
              bodyResourceKey="dossierFolderNotEmptyAlertBody"
              btnResourceKey="closeAlert"
              onClose={() => setDeleteAlertVisible(false)}
            />

            <ConfirmComponent
              visible={deleteConfirmVisible}
              titleResourceKey="dossier.deleteFolderTitle"
              bodyResourceKey="dossier.deleteFolderBody"
              onClose={() => setDeleteConfirmVisible(false)}
              onConfirm={() => {
                onDeleteFolder(pathname);
                setDeleteConfirmVisible(false);
              }}
            />
          </div>
        );
      }
    )
  )
);
