import * as React from "react";
import { FC, memo, useMemo } from "react";
import * as CSSModules from "react-css-modules";
import * as bowser from "bowser";
import I18n from "@haywork/components/i18n";

const styles = require("./style.scss");

export const BrowserDataComponent: FC = memo(
  CSSModules(styles, { allowMultiple: true })(() => {
    const browserData = useMemo(() => {
      return bowser.getParser(window.navigator.userAgent);
    }, []);

    const dimensions = useMemo(() => {
      const { width, height } = screen;
      return {
        width,
        height
      };
    }, []);

    const {
      type: platformType,
      vendor: platformVendor,
      model: platformModel
    } = browserData.getPlatform();
    const {
      name: engineName,
      version: engineVersion
    } = browserData.getEngine();

    return (
      <div styleName="browser-data">
        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.browserName" />
          </div>
          <div styleName="value">{browserData.getBrowserName()}</div>
        </div>

        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.browserVersion" />
          </div>
          <div styleName="value">{browserData.getBrowserVersion()}</div>
        </div>

        {engineName && (
          <div styleName="row">
            <div styleName="label">
              <I18n value="health.browserData.label.engineName" />
            </div>
            <div styleName="value">{engineName}</div>
          </div>
        )}

        {engineVersion && (
          <div styleName="row">
            <div styleName="label">
              <I18n value="health.browserData.label.engineVersion" />
            </div>
            <div styleName="value">{engineVersion}</div>
          </div>
        )}

        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.OSName" />
          </div>
          <div styleName="value">{browserData.getOSName()}</div>
        </div>

        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.OSVersion" />
          </div>
          <div styleName="value">{browserData.getOSVersion()}</div>
        </div>

        {platformVendor && (
          <div styleName="row">
            <div styleName="label">
              <I18n value="health.browserData.label.platformVendor" />
            </div>
            <div styleName="value">{platformVendor}</div>
          </div>
        )}

        {platformType && (
          <div styleName="row">
            <div styleName="label">
              <I18n value="health.browserData.label.platformType" />
            </div>
            <div styleName="value">{platformType}</div>
          </div>
        )}

        {platformModel && (
          <div styleName="row">
            <div styleName="label">
              <I18n value="health.browserData.label.platformModel" />
            </div>
            <div styleName="value">{platformModel}</div>
          </div>
        )}

        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.screenWidth" />
          </div>
          <div styleName="value">{dimensions.width}px</div>
        </div>

        <div styleName="row">
          <div styleName="label">
            <I18n value="health.browserData.label.screenHeight" />
          </div>
          <div styleName="value">{dimensions.height}px</div>
        </div>
      </div>
    );
  })
);
