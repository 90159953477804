import { createSelector } from "reselect";
import head from "lodash-es/head";
import take from "lodash-es/take";
import get from "lodash-es/get";

import { AppState } from "@haywork/stores";
import { AppClientSnapShot, SettingField } from "@haywork/api/authorization";
import { AppXchangeSettingsType } from "../components/settings";

const translationsSelector = (state: AppState) =>
  state.authorization.singleAppClient.client.translations || [];
const cultureSelector = (state: AppState) => state.main.culture;
const recommendationsSelector = (state: AppState) =>
  state.authorization.singleAppClient.recommendations;
const authorizationCompanySettingsSelector = (state: AppState) =>
  state.authorization.singleAppClient.companySettings;
const authorizationEmployeeSettingsSelector = (state: AppState) =>
  state.authorization.singleAppClient.employeeSettings;

export const translation = createSelector(
  translationsSelector,
  cultureSelector,
  (translations, culture) => {
    const translation = (translations || []).find(
      (translation) => translation.languageCultureName === culture
    );
    return translation || head(translations || []);
  }
);

export const recommendations = (clientId: string) =>
  createSelector(
    recommendationsSelector,
    cultureSelector,
    (recommendations, culture) => {
      const items = recommendations
        .filter((client) => client.id !== clientId)
        .map((client) => {
          let translationSnapShots = client.translationSnapShots || [];
          const translationSnapShot = translationSnapShots.find(
            (snapshot) => snapshot.languageCultureName === culture
          );

          if (!!translationSnapShot) {
            translationSnapShots = [translationSnapShot];
          }

          return {
            ...client,
            translationSnapShots
          } as AppClientSnapShot;
        });

      return take(items, 5);
    }
  );

export const settingsFields = (type: AppXchangeSettingsType) =>
  createSelector(
    authorizationCompanySettingsSelector,
    authorizationEmployeeSettingsSelector,
    (companySettings, employeeSettings) => {
      return type === AppXchangeSettingsType.Company
        ? get(companySettings, "fields", [] as SettingField[])
        : get(employeeSettings, "fields", [] as SettingField[]);
    }
  );
