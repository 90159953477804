import {
  Acceptance,
  BusinessExpenses,
  RealEstateGroup,
  SaleCondition,
  SaleOffer,
  UnitSize,
  ListingType,
} from "@haywork/api/kolibri";
import {
  AssignmentEditFinancialCommissionComponent,
  AssignmentEditFinancialSaleContainerProps,
} from "@haywork/modules/assignment";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  SwitchLabelPosition,
} from "@haywork/modules/form";
import {
  Hint,
  HintAlignment,
  ResourceText,
  StepComponent,
  StepperComponent,
} from "@haywork/modules/shared";
import { Ui } from "@haywork/modules/ui";
import { FormControlUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import Commission, {
  CommissionFor,
  CommissionResponse,
} from "../../commission";
import { AssignmentEditFinancialAdditionalComponent } from "./additional.component";
import { AssignmentEditFinancialOneTimeComponent } from "./one-time.component";
import get from "lodash-es/get";

const styles = require("./sale.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

export interface AssignmentEditFinancialSaleComponentProps {
  onChange: (values: FormReturnValue) => void;
}
interface State {
  businessExpenses: BusinessExpenses;
  showAcceptanceDate: boolean;
}
type Props = AssignmentEditFinancialSaleComponentProps &
  AssignmentEditFinancialSaleContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class AssignmentEditFinancialSaleComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    const { saleOffer, acceptanceDetails, hidePrice, businessExpenses } =
      this.props;

    this.state = {
      businessExpenses: businessExpenses || {},
      showAcceptanceDate:
        value(acceptanceDetails, "acceptance", Acceptance.InConsultation) ===
        Acceptance.PerDate,
    };

    const now = new Date();
    const dateOfIssue = value(
      saleOffer,
      "valuationOfImmovablePropertyAct.dateOfIssue",
      new Date(now.getFullYear(), 0, 1, 12, 0, 0)
    );

    this.formControls = {
      priceCode: { value: value(saleOffer, "priceCode") },
      salePrice: { value: value(saleOffer, "salePrice") },
      saleCondition: { value: saleOffer.saleCondition },
      vatCondition: { value: saleOffer.vatCondition },
      pricePerUnit: { value: value(saleOffer, "pricePerUnit") },
      hidePrice: { value: hidePrice },
      valuationOfImmovablePropertyActValue: {
        value: value(saleOffer, "valuationOfImmovablePropertyAct.value"),
      },
      dateOfIssue: { value: dateOfIssue },
      acceptance: {
        value: value(
          acceptanceDetails,
          "acceptance",
          Acceptance.InConsultation
        ),
        onChange: (ref) =>
          this.setState({
            showAcceptanceDate: ref.value === Acceptance.PerDate,
          }),
      },
      date: { value: value(acceptanceDetails, "date") },
      excludingCapitalizedInterest: {
        value: value(saleOffer, "excludingCapitalizedInterest"),
      },
    };

    this.onSaleOfferChangesHandler = this.onSaleOfferChangesHandler.bind(this);
    this.onAdditionalChangesHandler =
      this.onAdditionalChangesHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.objectAssignment, "dateTimeModified") !==
        get(this.props.objectAssignment, "dateTimeModified")
    ) {
      this.setState({
        businessExpenses: this.props.businessExpenses || {},
        showAcceptanceDate:
          value(
            this.props.acceptanceDetails,
            "acceptance",
            Acceptance.InConsultation
          ) === Acceptance.PerDate,
      });

      const { saleOffer, acceptanceDetails, hidePrice } = this.props;
      const dateOfIssue = value(
        saleOffer,
        "valuationOfImmovablePropertyAct.dateOfIssue",
        new Date()
      );

      this.formRef.update(
        {
          priceCode: value(saleOffer, "priceCode"),
          salePrice: value(saleOffer, "salePrice"),
          saleCondition: saleOffer.saleCondition,
          vatCondition: saleOffer.vatCondition,
          pricePerUnit: value(saleOffer, "pricePerUnit"),
          hidePrice,
          valuationOfImmovablePropertyActValue: value(
            saleOffer,
            "valuationOfImmovablePropertyAct.value"
          ),
          dateOfIssue,
          acceptance: value(
            acceptanceDetails,
            "acceptance",
            Acceptance.InConsultation
          ),
          date: value(acceptanceDetails, "date"),
          excludingCapitalizedInterest: value(
            saleOffer,
            "excludingCapitalizedInterest"
          ),
        },
        true
      );
    }
  }

  public render() {
    const {
      priceCodes,
      saleOffer,
      hidePrice,
      acceptances,
      vatConditions,
      saleConditions,
      commissionTypeOptions,
      businessExpenses,
      languages,
      objectAssignment,
    } = this.props;
    const { vVO, parcelSurface, listingType, realEstateGroup } =
      objectAssignment;
    const surface =
      realEstateGroup === RealEstateGroup.Commercial
        ? [
            ListingType.Leisure,
            ListingType.OutdoorArea,
            ListingType.Lot,
          ].includes(listingType)
          ? parcelSurface
          : vVO
        : undefined;

    const realEstatePriceCodes =
      this.props.realEstateGroup === RealEstateGroup.Agricultural
        ? this.props.priceCodesAgricultural
        : priceCodes;

    const showVat =
      (this.props.realEstateGroup === RealEstateGroup.Residential &&
        saleOffer.saleCondition !== SaleCondition.CostsBuyer) ||
      this.props.realEstateGroup === RealEstateGroup.Commercial;

    return (
      <div styleName="financial" id="scroll-to-top">
        <div className="container-fluid">
          <StepperComponent initial={0} scrollToElementId="scroll-to-top">
            <StepComponent title="price">
              <Form
                name="financial"
                formControls={this.formControls}
                onChange={this.onChangeHandler}
                form={(ref) => (this.formRef = ref)}
              >
                {/* Main price */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <Input.NewSelect
                        name="priceCode"
                        values={realEstatePriceCodes}
                        displayProp="displayName"
                        valuesProp="value"
                      />
                    </div>
                    <div className="column__textspacer push-label">
                      <ResourceText resourceKey="of" />
                    </div>
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <Input.Number
                        name="salePrice"
                        data-cy={"CY-SalePriceInput"}
                        pretty
                        step={1000}
                        asPrice
                      />
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <label>
                        <Ui.RequiredForPublish />
                      </label>
                      <Input.RadioGroup name="saleCondition" asButtonList>
                        {saleConditions.map((saleCondition, idx) => (
                          <Input.Radio
                            label={saleCondition.displayName}
                            value={saleCondition.value}
                            data-cy={
                              "CY-" + saleCondition.value + "Radiobutton"
                            }
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>

                    {showVat && (
                      <React.Fragment>
                        <div className="column__spacer" />
                        <div className="column push-label">
                          <Input.RadioGroup
                            name="vatCondition"
                            asButtonList
                            canUnselect
                          >
                            {vatConditions.map((vatCondition, idx) => (
                              <Input.Radio
                                label={vatCondition.displayName}
                                value={vatCondition.value}
                                data-cy={
                                  "CY-" + vatCondition.value + "Radiobutton"
                                }
                                key={idx}
                              />
                            ))}
                          </Input.RadioGroup>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                {/* Sale price per unit */}
                {this.props.objectAssignment.realEstateGroup ===
                  RealEstateGroup.Commercial && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <label htmlFor="pricePerUnit" className="pre">
                          <ResourceText resourceKey="andOr" />
                        </label>
                      </div>
                      <div className="column__spacer" />
                      <div className="column">
                        <div className="input__helper">
                          <div className="pre">&euro;</div>
                          <Input.Number
                            name="pricePerUnit"
                            pretty
                            step={10}
                            asPrice
                          />
                          <div className="post full">
                            <ResourceText
                              resourceKey="perSquareMetersSale"
                              asHtml
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Excluding Capitalized Interest */}
                {this.props.objectAssignment.realEstateGroup ===
                  RealEstateGroup.Agricultural && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="excludingCapitalizedInterest"
                          label="excludingCapitalizedInterest"
                          labelPosition={SwitchLabelPosition.Pre}
                          on={true}
                          off={false}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Hide price */}
                {(!!hidePrice ||
                  !!this.props.enableHidePriceForObjectAssignmentsOption) && (
                  <div className="form__row">
                    <div className="form__group">
                      <div className="column">
                        <Input.Switch
                          name="hidePrice"
                          label="hidePrice"
                          labelPosition={SwitchLabelPosition.Pre}
                          on={true}
                          off={false}
                        />
                      </div>
                      <div className="column__textspacer">
                        <Hint
                          message="showPriceTooltip"
                          align={HintAlignment.Right}
                        >
                          <span className="toolTip">
                            <i className="fa fa-fw fa-info-circle" />{" "}
                            <ResourceText resourceKey="whatIsThis" />
                          </span>
                        </Hint>
                      </div>
                    </div>
                  </div>
                )}

                {/* WOZ */}
                <div className="form__row">
                  <div className="form__group">
                    <div className="column">
                      <label
                        htmlFor="valuationOfImmovablePropertyActValue"
                        className="pre"
                      >
                        <ResourceText resourceKey="wozValue" />
                      </label>
                    </div>
                    <div className="column__spacer" />
                    <div className="column">
                      <div className="input__helper">
                        <div className="pre">&euro;</div>
                        <Input.Number
                          name="valuationOfImmovablePropertyActValue"
                          pretty
                          step={1000}
                          asPrice
                        />
                      </div>
                    </div>
                    <div className="column__textspacer">
                      <ResourceText resourceKey="per" />
                    </div>
                    <div className="column">
                      <Input.Datepicker
                        name="dateOfIssue"
                        data-cy="CY-dateOfIssue"
                      />
                    </div>
                  </div>
                </div>

                {/* Acceptance details */}
                <div className="form__row">
                  <label>
                    <ResourceText resourceKey="acceptance" />
                    <Ui.RequiredForPublish />
                  </label>
                  <div className="form__group">
                    <div className="column">
                      <Input.RadioGroup name="acceptance" asButtonList>
                        {acceptances.map((acceptance, idx) => (
                          <Input.Radio
                            label={acceptance.displayName}
                            value={acceptance.value}
                            data-cy={"CY-" + acceptance.value + "Radiobutton"}
                            key={idx}
                          />
                        ))}
                      </Input.RadioGroup>
                    </div>
                    {this.state.showAcceptanceDate && (
                      <div className="column__textspacer">
                        <ResourceText resourceKey="date" />
                      </div>
                    )}
                    {this.state.showAcceptanceDate && (
                      <div className="column">
                        <Input.Datepicker name="date" />
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </StepComponent>

            <StepComponent title="courtage">
              <Commission
                commissionFor={CommissionFor.Sale}
                offer={saleOffer}
                surface={surface}
                onChange={this.onSaleOfferChangesHandler}
              />
            </StepComponent>
            <StepComponent title="exchangeCommission">
              <div className="alert alert-info" styleName="exchangeInfoMessage">
                <i className="fal fa-fw fa-lightbulb" />
                <ResourceText resourceKey="exchangeCommissionMessage" />
              </div>
              <AssignmentEditFinancialCommissionComponent
                commissionTypeOptions={commissionTypeOptions}
                offer={saleOffer}
                languages={languages}
                defaultLanguage={this.props.defaultLanguage}
                objectAssignment={this.props.objectAssignment}
                onChange={this.onSaleOfferChangesHandler}
              />
            </StepComponent>
            {this.props.realEstateGroup === RealEstateGroup.Commercial && (
              <StepComponent title="oneTimeAssignmentCosts">
                <AssignmentEditFinancialOneTimeComponent
                  objectAssignment={this.props.objectAssignment}
                  onChange={this.props.onChange}
                />
              </StepComponent>
            )}
            {this.props.realEstateGroup !== RealEstateGroup.Agricultural && (
              <StepComponent title="assignmentCosts">
                <AssignmentEditFinancialAdditionalComponent
                  saleOffer={saleOffer}
                  businessExpenses={businessExpenses}
                  onChange={this.onAdditionalChangesHandler}
                />
              </StepComponent>
            )}
          </StepperComponent>
        </div>
      </div>
    );
  }

  private onSaleOfferChangesHandler(values: CommissionResponse) {
    const saleOffer = {
      ...this.props.saleOffer,
      ...values,
    };

    this.props.onChange({ saleOffer });
  }

  private onAdditionalChangesHandler(values: FormReturnValue) {
    const saleOffer: SaleOffer = {
      ...this.props.saleOffer,
      serviceCosts: values.serviceCosts,
      indicationGas: values.indicationGas,
      indicationWater: values.indicationWater,
      indicationElectra: values.indicationElectra,
      indicationHeatingCosts: values.indicationHeatingCosts,
    };
    const businessExpenses: BusinessExpenses = {
      ...this.state.businessExpenses,
      oZBOccupiersShare: values.oZBOccupiersShare,
      oZBBusinessShare: values.oZBBusinessShare,
      sewerageCharges: values.sewerageCharges,
      waterAuthorityCharges: values.waterAuthorityCharges,
      landConsolidationRent: values.landConsolidationRent,
    };

    this.setState({ businessExpenses }, () => {
      this.props.onChange({ saleOffer, businessExpenses });
    });
  }

  private onChangeHandler(values: FormReturnValue) {
    let saleOffer: SaleOffer = {
      ...this.props.saleOffer,
      priceCode: values.priceCode,
      salePrice: values.salePrice,
      saleCondition: values.saleCondition,
      vatCondition: values.vatCondition,
      valuationOfImmovablePropertyAct: {
        value: values.valuationOfImmovablePropertyActValue,
        dateOfIssue: values.dateOfIssue,
      },
      excludingCapitalizedInterest: values.excludingCapitalizedInterest,
      pricePerUnit: undefined,
      pricePerUnitSize: undefined,
    };

    if (!!values.pricePerUnit) {
      saleOffer = {
        ...saleOffer,
        pricePerUnit: values.pricePerUnit,
        pricePerUnitSize: UnitSize.M2,
      };
    }

    const acceptanceDetails = {
      acceptance: values.acceptance,
      date: values.date,
    };
    this.props.onChange({
      saleOffer,
      businessExpenses: this.state.businessExpenses,
      acceptanceDetails,
      hidePrice: values.hidePrice,
    });
  }
}
