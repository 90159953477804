import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { LightboxContainerProps } from "../../containers/lightbox.container";
import { PhotoBlob } from "@haywork/api/kolibri";
import { ModalPortal } from "@haywork/portals";
import Slide from "./slide";
import I18n from "@haywork/components/i18n";
import head from "lodash-es/head";
import { saveAs } from "file-saver";

const styles = require("./lightbox.component.scss");

export enum LightboxDirection {
  Right = "Right",
  Left = "Left",
}

export type LightboxComponentProps = {};
type State = {
  direction: LightboxDirection;
  currentSlide: PhotoBlob;
};
type Props = LightboxComponentProps & LightboxContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class LightboxComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      direction: LightboxDirection.Right,
      currentSlide: this.props.slides[this.props.current],
    };

    this.onPreviousClickHandler = this.onPreviousClickHandler.bind(this);
    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onDownloadHandler = this.onDownloadHandler.bind(this);

    document.addEventListener("keydown", this.onKeyDownHandler, true);
  }

  public render() {
    const { current, count, slides, visible } = this.props;
    const { direction, currentSlide } = this.state;
    const lightboxStyle = classNames("lightbox", {
      visible: this.props.visible,
    });
    const triggerPreviousStyle = classNames("trigger", "previous", {
      visible: current !== 0 && count !== 1,
    });
    const triggerNextStyle = classNames("trigger", "next", {
      visible: current !== count - 1 && count !== 1,
    });
    const slidesStyle = classNames("slides", {
      left: direction === LightboxDirection.Left,
      right: direction === LightboxDirection.Right,
    });
    const indexOfCount = { index: current + 1, count };

    const image =
      currentSlide?.urlBig ||
      currentSlide?.urlMedium ||
      currentSlide?.urlOriginal;
    const isVideoUrl = new RegExp(/(youtube.com|youtu.be)/gi).test(image);

    return (
      <ModalPortal>
        <div styleName={lightboxStyle} data-cy={this.props["data-cy"]}>
          <div styleName="background" />
          <div styleName="panorama">
            <div
              styleName={triggerPreviousStyle}
              onClick={this.onPreviousClickHandler}
              data-cy={
                this.props["data-cy"] &&
                `${this.props["data-cy"]}.PreviousButton`
              }
            >
              <i className="fal fa-fw fa-chevron-left faIcon" />
            </div>

            <div styleName="meta">
              <div styleName="meta__section highlight">
                <I18n value="indexOfCount" values={indexOfCount} />
              </div>
              {!!currentSlide?.description && (
                <div styleName="meta__section">{currentSlide.description}</div>
              )}
              {!isVideoUrl && (
                <div
                  styleName="meta__section download"
                  onClick={this.onDownloadHandler}
                >
                  <I18n value="lightbox.downloadFile" />
                </div>
              )}
              <div
                styleName="meta__section close"
                onClick={this.props.close}
                data-cy={
                  this.props["data-cy"] &&
                  `${this.props["data-cy"]}.CloseButton`
                }
              >
                <I18n value="close" />
                <i className="fal fa-fw fa-times faIcon" />
              </div>
            </div>

            <div styleName={slidesStyle}>
              {slides.map((slide, idx) => (
                <Slide
                  direction={direction}
                  current={current}
                  index={idx}
                  url={image}
                  lightBoxVisible={visible}
                  key={idx}
                />
              ))}
            </div>

            <div
              styleName={triggerNextStyle}
              onClick={this.onNextClickHandler}
              data-cy={
                this.props["data-cy"] && `${this.props["data-cy"]}.NextButton`
              }
            >
              <i className="fal fa-fw fa-chevron-right faIcon" />
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ currentSlide: nextProps.slides[nextProps.current] });
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDownHandler, true);
  }

  private onPreviousClickHandler() {
    this.props.previous();
    this.setState({ direction: LightboxDirection.Left });
  }

  private onNextClickHandler() {
    this.props.next();
    this.setState({ direction: LightboxDirection.Right });
  }

  private onKeyDownHandler(event: KeyboardEvent) {
    if (!this.props.visible) return;
    switch (event.keyCode) {
      case 27: {
        return this.props.close();
      }
      case 37:
      case 38: {
        this.setState({ direction: LightboxDirection.Left });
        return this.props.previous();
      }
      case 39:
      case 40: {
        this.setState({ direction: LightboxDirection.Right });
        return this.props.next();
      }
      default:
        return;
    }
  }

  private onDownloadHandler() {
    const slide = this.props.slides[this.props.current];
    if (!slide) return;
    const { urlOriginal, urlBig, urlMedium } = slide;
    const file = head([urlOriginal, urlBig, urlMedium].filter((d) => !!d));
    if (!file) return;
    saveAs(file);
  }
}
