import { MailHistorySnapShot, SearchAssignment } from "@haywork/api/kolibri";
import { SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import { ErrorBoundary } from "@haywork/modules/error-boundary";
import { SearchAssignmentMatchingPropertiesModalContainer } from "@haywork/modules/search-assignment";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil } from "@haywork/util";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import { SearchAssignmentWidgetMatchMailItemComponent } from "./matchmail-item.component";

const styles = require("./matchmails.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentWidgetMatchMailsComponentProps {
  searchAssignment: SearchAssignment;
  matchMails: MailHistorySnapShot[];
  getWidgetSearchAssignmentMatchMails: (searchAssignmentId: string) => void;
  getMatchMailProperties: (
    objectAssignmentIds: string[],
    searchAssignmentId: string
  ) => void;
  navigate: (url: string) => void;
}
interface SearchAssignmentWidgetMatchMailsComponentState {
  matchingPropertiesModalVisible: boolean;
  objectAssignmentIds: string[];
  dateTimeSent: Date;
}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentWidgetMatchMailsComponent extends React.Component<
  SearchAssignmentWidgetMatchMailsComponentProps,
  SearchAssignmentWidgetMatchMailsComponentState
> {
  constructor(props) {
    super(props);

    this.onMailClickHandler = this.onMailClickHandler.bind(this);
    this.onModalCloseHandler = this.onModalCloseHandler.bind(this);

    this.state = {
      matchingPropertiesModalVisible: false,
      objectAssignmentIds: [],
      dateTimeSent: new Date(),
    };
  }

  public componentDidMount() {
    const { id } = this.props.searchAssignment;
    this.props.getWidgetSearchAssignmentMatchMails(id);
  }

  public render() {
    const { id } = this.props.searchAssignment;
    const url = route(SEARCHASSIGNMENTROUTES.MATCHMAIL.URI, { id });
    const matchMails = this.props.matchMails || [];
    const cappedMatchMails = matchMails.slice(0, 3);

    return (
      <div styleName="matchmails">
        <h1>
          <ResourceText resourceKey="lastSentMatchMails" />
        </h1>
        {cappedMatchMails.length > 0 && (
          <div>
            <div styleName="matchmails__list">
              {cappedMatchMails.map((matchMail, idx) => (
                <ErrorBoundary key={idx}>
                  <SearchAssignmentWidgetMatchMailItemComponent
                    matchMail={matchMail}
                    onMailClick={this.onMailClickHandler}
                  />
                </ErrorBoundary>
              ))}
            </div>
            <div styleName="matchmails__action">
              <Link to={url}>
                <ResourceText resourceKey="goToMatchMails" />
              </Link>
            </div>
          </div>
        )}
        {cappedMatchMails.length === 0 && (
          <div styleName="empty-state">
            <div styleName="icon" />
            <div styleName="message">
              <ResourceText resourceKey="matchMailsEmptyStateTitle" />
            </div>
          </div>
        )}

        {/* Matching properties modal */}
        <SearchAssignmentMatchingPropertiesModalContainer
          visible={this.state.matchingPropertiesModalVisible}
          onClose={this.onModalCloseHandler}
          onNavigateToProperty={this.props.navigate}
          objectAssignmentIds={this.state.objectAssignmentIds}
          searchAssignment={this.props.searchAssignment}
          dateTimeSent={this.state.dateTimeSent}
        />
      </div>
    );
  }

  private onMailClickHandler(
    objectAssignmentIds: string[],
    dateTimeSent: Date
  ) {
    this.props.getMatchMailProperties(
      objectAssignmentIds,
      this.props.searchAssignment.id
    );
    this.setState({
      matchingPropertiesModalVisible: true,
      objectAssignmentIds,
      dateTimeSent,
    });
  }

  private onModalCloseHandler() {
    this.setState({ matchingPropertiesModalVisible: false });
  }
}
