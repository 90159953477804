import {
  CommunicationLogDirection,
  RelationSnapShot,
  RelationType,
} from "@haywork/api/kolibri";
import {
  VoipConversationDirection,
  VoipConversationStatus,
} from "@haywork/api/voip";
import { RequestStatus } from "@haywork/enum";
import { ResourceText } from "@haywork/modules/shared";
import { Call } from "@haywork/stores/voip/calls";
import classNames from "classnames";
import get from "lodash-es/get";
import head from "lodash-es/head";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import NoUserActions from "../actions-no-user";
import UserActions from "../actions-user";
import RelationBody from "../widget-body-relation";
import RelationsBody from "../widget-body-relations";
import UnknowBody from "../widget-body-unknown";
import { intlContext } from "@haywork/app";

const styles = require("./style.scss");

interface VoipWidgetInstanceProps {
  call: Call;
  employeeName: string;
  onToggleCollapsed: (call: Call) => void;
  onRemove: (call: Call) => void;
  onSearchRelation: (phoneNumber: string, conversationId: string) => void;
  onSearchEmployee: (employeeId: string, conversationId: string) => void;
  onCreateNewRelation: (call: Call) => void;
  onBindExistingRelation: (call: Call) => void;
  onCreateAppointment: (relation: RelationSnapShot) => void;
  onViewRelation: (relation: RelationSnapShot) => void;
  onSelectRelation: (relation: RelationSnapShot, callToHandle: Call) => void;
  onSaveCommunicationLog: (
    relation: RelationSnapShot,
    conversationId: string,
    direction: CommunicationLogDirection,
    subject: string
  ) => Promise<void>;
  onUpdateCommunicationLog: (
    communicationLogId: string,
    subject: string,
    note: string
  ) => void;
}
interface State {
  communicationLogStatus: RequestStatus;
  note: string;
}
type Props = VoipWidgetInstanceProps;

@CSSModules(styles, { allowMultiple: true })
export class VoipWidgetComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      communicationLogStatus: RequestStatus.Idle,
      note: "",
    };

    this.saveCommunicationLog = this.saveCommunicationLog.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  public componentDidMount() {
    const { phoneNumber, conversationId, conversationEmployeeId } =
      this.props.call.payload;
    if (!!conversationEmployeeId) {
      this.props.onSearchEmployee(conversationEmployeeId, conversationId);
      return;
    }
    if (!!phoneNumber) {
      this.props.onSearchRelation(phoneNumber, conversationId);
      return;
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      linkedCommunicationLogId,
      linkedRelations,
      payload,
      linkedRelationSearchStatus,
    } = this.props.call;

    if (
      !linkedCommunicationLogId &&
      [
        VoipConversationStatus.Answered,
        VoipConversationStatus.Transferred,
      ].includes(payload.status) &&
      linkedRelations.length === 1 &&
      head(linkedRelations).typeOfRelation !== RelationType.Employee
    ) {
      this.saveCommunicationLog();
    }

    if (
      !!get(prevProps.call, "payload.conversationEmployeeId") &&
      !get(payload, "conversationEmployeeId") &&
      !!payload.phoneNumber &&
      linkedRelationSearchStatus !== RequestStatus.Pending
    ) {
      this.props.onSearchRelation(payload.phoneNumber, payload.conversationId);
      return;
    }
  }

  public render() {
    const { collapsed, payload } = this.props.call;

    let callStatus;
    switch (payload.status) {
      case VoipConversationStatus.Answered:
      case VoipConversationStatus.Transferred: {
        callStatus = "calling";
        break;
      }
      case VoipConversationStatus.Disconnected: {
        callStatus = "call-ended";
        break;
      }
      case VoipConversationStatus.Ringing: {
        callStatus = "ringing";
        break;
      }
      default: {
        return null;
      }
    }

    let direction;
    switch (payload.direction) {
      case VoipConversationDirection.Outgoing: {
        direction = "outgoing";
        break;
      }
      case VoipConversationDirection.Incoming: {
        direction = "incoming";
        break;
      }
      default: {
        return null;
      }
    }

    return (
      <div styleName={classNames("widget", { collapsed })}>
        <div styleName="widget__header">
          <div styleName={classNames("call-icon", callStatus)}>
            <i className="fa fa-phone" />
          </div>
          <div styleName="status">
            <ResourceText
              resourceKey={`voip.callStatus.${callStatus}.${direction}`}
            />
          </div>
          <div styleName="actions">
            {![
              VoipConversationStatus.Answered,
              VoipConversationStatus.Transferred,
            ].includes(payload.status) && (
              <div styleName="action" onClick={this.onRemove}>
                <i className="fal fa-times" />
              </div>
            )}
            <div
              styleName="action"
              onClick={() => this.props.onToggleCollapsed(this.props.call)}
            >
              <i className="fal fa-chevron-down" />
            </div>
          </div>
        </div>
        <div styleName="widget__body">
          {this.renderBodyType()}
          {this.renderActions()}
        </div>
      </div>
    );
  }

  private renderBodyType() {
    const {
      linkedRelationSearchStatus,
      linkedRelations,
      payload,
      callEndedOn,
      callStartedOn,
    } = this.props.call;

    switch (true) {
      case linkedRelationSearchStatus === RequestStatus.Success &&
        linkedRelations.length === 1: {
        return (
          <RelationBody
            phoneNumber={payload.phoneNumber}
            linkedRelation={head(linkedRelations)}
            callStartedOn={callStartedOn}
            callEndedOn={callEndedOn}
          />
        );
      }
      case linkedRelationSearchStatus === RequestStatus.Success &&
        linkedRelations.length > 1: {
        return (
          <RelationsBody
            phoneNumber={payload.phoneNumber}
            linkedRelations={linkedRelations}
            conversationStatus={get(payload, "status")}
            onSelectRelation={(relation) =>
              this.props.onSelectRelation(relation, this.props.call)
            }
          />
        );
      }
      default: {
        return (
          <UnknowBody
            phoneNumber={payload.phoneNumber}
            linkedRelationSearchStatus={linkedRelationSearchStatus}
            callStartedOn={callStartedOn}
            callEndedOn={callEndedOn}
          />
        );
      }
    }
  }

  private renderActions() {
    const {
      linkedRelationSearchStatus,
      linkedRelations,
      linkedCommunicationLogId,
    } = this.props.call;

    switch (true) {
      case linkedRelationSearchStatus === RequestStatus.Pending:
      default: {
        return null;
      }
      case linkedRelationSearchStatus === RequestStatus.Success &&
        !linkedRelations.length: {
        return (
          <NoUserActions
            onCreateNewRelation={() =>
              this.props.onCreateNewRelation(this.props.call)
            }
            onBindExistingRelation={() =>
              this.props.onBindExistingRelation(this.props.call)
            }
          />
        );
      }
      case linkedRelationSearchStatus === RequestStatus.Success &&
        linkedRelations.length === 1 &&
        head(linkedRelations).typeOfRelation !== RelationType.Employee: {
        return (
          <UserActions
            onCreateAppointment={() =>
              this.props.onCreateAppointment(head(linkedRelations))
            }
            onViewRelation={() =>
              this.props.onViewRelation(head(linkedRelations))
            }
            onNoteChange={(note) => this.setState({ note })}
            linkedCommunicationLogId={linkedCommunicationLogId}
          />
        );
      }
    }
  }

  private async saveCommunicationLog() {
    if (this.state.communicationLogStatus !== RequestStatus.Idle) return;
    this.setState({ communicationLogStatus: RequestStatus.Pending });
    const { call } = this.props;

    const subjectKey = "voipCommunicationLogSubjectInitial";
    const direction =
      get(call, "payload.direction") === VoipConversationDirection.Incoming
        ? "IncomingCall"
        : "OutgoingCall";

    const subject = intlContext.formatMessage(
      {
        id: subjectKey,
        defaultMessage: subjectKey,
      },
      {
        direction: intlContext.formatMessage({
          id: direction,
          defaultMessage: "IncomingCall",
        }),
        employeeName: this.props.employeeName,
      }
    );

    try {
      const { linkedRelations, payload } = this.props.call;

      await this.props.onSaveCommunicationLog(
        head(linkedRelations),
        payload.conversationId,
        payload.direction === VoipConversationDirection.Incoming
          ? CommunicationLogDirection.Incoming
          : CommunicationLogDirection.Outgoing,
        subject
      );
      this.setState({ communicationLogStatus: RequestStatus.Success });
    } catch (error) {
      this.setState({ communicationLogStatus: RequestStatus.Error });
      throw error;
    }
  }

  private onRemove() {
    const { call } = this.props;
    let { note } = this.state;
    note = (note || "").trim();

    if (!!call.linkedCommunicationLogId) {
      const { callEndedOn, callStartedOn } = call;
      const subjectKey = "voipCommunicationLogSubject";
      const direction =
        get(call, "payload.direction") === VoipConversationDirection.Incoming
          ? "IncomingCall"
          : "OutgoingCall";
      const subject = intlContext.formatMessage(
        {
          id: subjectKey,
          defaultMessage: subjectKey,
        },
        {
          direction: intlContext.formatMessage({
            id: direction,
            defaultMessage: "IncomingCall",
          }),
          duration: this.renderPrettyTime(
            Math.round((callEndedOn - callStartedOn) / 1000)
          ),
          employeeName: this.props.employeeName,
        }
      );

      this.props.onUpdateCommunicationLog(
        call.linkedCommunicationLogId,
        subject,
        note || ""
      );
    }

    this.props.onRemove(call);
  }

  private renderPrettyTime(totalSeconds: number) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}
