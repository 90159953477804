import { SearchAssignment } from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES, SEARCHASSIGNMENTROUTES } from "@haywork/constants";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@haywork/modules/modal";
import { ResourceText } from "@haywork/modules/shared";
import { RouteUtil, SearchAssignmentUtil } from "@haywork/util";
import { SearchAssignmentSaveError } from "@haywork/util/search-assignment";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { NavLink } from "react-router-dom";

const styles = require("./save-modal.component.scss");
const route = RouteUtil.mapStaticRouteValues;

interface SearchAssignmentSaveModalComponentProps {
  visible: boolean;
  searchAssignment: SearchAssignment;
  onClose: () => void;
}
interface SearchAssignmentSaveModalComponentState {}

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentSaveModalComponent extends React.Component<
  SearchAssignmentSaveModalComponentProps,
  SearchAssignmentSaveModalComponentState
> {
  constructor(props) {
    super(props);

    this.mapError = this.mapError.bind(this);
  }

  public render() {
    const errors = SearchAssignmentUtil.getErrors(this.props.searchAssignment);

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader title="saveSearchAssignmentMissingFields" close />
        <ModalBody>
          <ResourceText
            resourceKey="saveSearchAssignmentMissingFieldsBody"
            values={{ count: errors.length }}
          />
          {errors.map(this.mapError)}
        </ModalBody>
        <ModalFooter>
          <div className="btn" onClick={this.props.onClose}>
            <ResourceText resourceKey="cancel" />
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  private mapError(
    error: SearchAssignmentSaveError,
    idx: number
  ): React.ReactElement<HTMLDivElement> {
    const { id } = this.props.searchAssignment;
    let link;

    switch (error) {
      case SearchAssignmentSaveError.NoLinkedRelations:
        link = route(SEARCHASSIGNMENTROUTES.EDIT_CONTACTS.URI, { id });
        break;
      case SearchAssignmentSaveError.NoSearchLocations:
        link = route(SEARCHASSIGNMENTROUTES.EDIT_WHERE.URI, { id });
        break;
      default:
        link = route(ASSIGNMENTROUTES.DETAIL.URI, { id });
        break;
    }

    return (
      <div styleName="error" onClick={this.props.onClose} key={idx}>
        <NavLink to={link}>
          <ResourceText resourceKey={error.toString()} />
        </NavLink>
      </div>
    );
  }
}
