import { PATTERN } from "@haywork/constants";
import { PhotoBlob } from "@haywork/api/kolibri";

export const urlToPhotoBlob = (url: string): PhotoBlob => {
  return {
    urlBig: url,
    urlMedium: url,
    urlOriginal: url,
    urlPreview: url,
    width: 0,
    height: 0,
    fileSize: 0,
  };
};

export const addHttp = (url: string) => {
  const hasUrl = !!url;
  const doesntMatchPattern = !new RegExp(PATTERN.SCHEMA).test(url);

  if (hasUrl && doesntMatchPattern) {
    return `http://${url}`;
  }

  return url;
};
