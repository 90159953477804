import {
  InitializedWidget,
  WidgetEntityType
} from "@haywork/api/authorization";
import { AuthorizationThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AuthorizationWidgetsPageComponent,
  AuthorizationWidgetsPageComponentProps
} from "./page";

interface StateProps {}

interface DispatchProps {
  getInitializedWidgets: (
    entityId: string,
    entityType: WidgetEntityType
  ) => Promise<InitializedWidget[]>;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  AuthorizationWidgetsPageComponentProps,
  AppState
> = (state) => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  AuthorizationWidgetsPageComponentProps
> = (dispatch) => ({
  getInitializedWidgets: (entityId: string, entityType: WidgetEntityType) =>
    dispatch(
      AuthorizationThunks.Widgets.getInitializedWidgets(entityId, entityType)
    )
});

export type AuthorizationWidgetsPageContainerProps = StateProps &
  DispatchProps &
  RouteComponentProps<any>;
export const AuthorizationWidgetsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthorizationWidgetsPageComponent));
