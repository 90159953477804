import isString from "lodash-es/isString";

export enum SurfaceSizeTypes {
  SquareMeters = "SquareMeters",
  Hectare = "Hectare",
  Are = "Are",
  Centiare = "Centiare"
}

export interface SurfacesObject {
  centiare: number;
  hectare: number;
  are: number;
}

export class Number {
  public static parseString(value): number {
    if (!value) return 0;
    if (typeof value === "number") return value;

    const seperators = value.match(/[\,\.]/g);
    if (
      seperators &&
      seperators.length > 1 &&
      !!seperators.reduce((a, b) => (a === b ? a : NaN))
    ) {
      value = value.replace(new RegExp("[,.]", "g"), "");
      return parseFloat(value);
    }

    value = value
      .replace(new RegExp("\\.", "g"), ",")
      .replace(new RegExp(",([^,]*)$"), ".$1")
      .replace(new RegExp("\\,", "g"), "");

    return parseFloat(value);
  }

  public static formatBytes(bytes: number, decimal: number = 0): string {
    if (0 === bytes) return "0 Bytes";
    const c = 1e3,
      e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      f = Math.floor(Math.log(bytes) / Math.log(c));

    return parseFloat((bytes / Math.pow(c, f)).toFixed(decimal)) + " " + e[f];
  }

  public static addLeadingZeros(
    value: number | string,
    minLength: number = 2
  ): string {
    const raw = isString(value) ? value : value.toString();
    const length = raw.length;
    let leading = raw;

    while (leading.length <= minLength - length) {
      leading = `0${leading}`;
    }

    return leading;
  }

  public static prettyNumber(
    value: number,
    minLength: number = 3
  ): { __html: string } {
    const raw = value.toString();
    const length = raw.length;
    let leading = "";

    while (leading.length < minLength - length) {
      leading = 0 + leading;
    }

    return {
      __html: `<span class="pretty-number">
      <span class="pre">${leading}</span>${raw}
    </span>`
    };
  }

  public static surfacesToSurface(
    ha: number | string,
    a: number | string,
    ca: number | string
  ): number {
    const are = this.addLeadingZeros(a || 0, 2);
    const centiare = this.addLeadingZeros(ca || 0, 2);

    return parseInt(`${ha}${are}${centiare}`);
  }

  public static surfaceToSurfacesObject(surface: number): SurfacesObject {
    const safeValue = isNaN(surface) ? 0 : surface;
    const stringValue = safeValue.toString();

    let ha = 0,
      ca = 0,
      a = 0;

    switch (stringValue.length) {
      case 0:
      case 1:
      case 2:
        ca = parseInt(stringValue);
        break;
      case 3:
      case 4:
        ca = parseInt(stringValue.slice(-2));
        a = parseInt(stringValue.slice(-4, -2));
        break;
      default:
        ca = parseInt(stringValue.slice(-2));
        a = parseInt(stringValue.slice(-4, -2));
        ha = parseInt(stringValue.slice(0, -4));
        break;
    }

    return {
      are: a,
      centiare: ca,
      hectare: ha
    };
  }

  public static parseFloatExtended(value: number | string): number {
    const val = isString(value) ? value : value.toString();
    return parseFloat(parseFloat(val).toPrecision(12));
  }
}
