import * as React from "react";
import { FC, memo, useMemo, useCallback, MouseEvent } from "react";
import * as CSSModules from "react-css-modules";
import {
  LinkedRelation,
  LinkedEmployee,
  RelationType,
} from "@haywork/api/kolibri";
import classNames from "classnames";
import { RouteUtil } from "@haywork/util";
import {
  RELATIONROUTES,
  EMPLOYEEROUTES,
  OFFICESROUTES,
} from "@haywork/constants";
import { MailTo } from "@haywork/modules/shared";

const styles = require("./style.scss");
const route = RouteUtil.mapStaticRouteValues;

type Props = {
  relation: LinkedRelation | LinkedEmployee;
  relationType: RelationType;
  onNavigate: (path: string) => void;
};
export const NetworkWidgetItemComponent: FC<Props> = memo(
  CSSModules(styles, { allowMultiple: true })(
    ({ relation, relationType, onNavigate }) => {
      const { avatarUrl, letterAvatar, displayName, email, id } = relation;
      const phones = useMemo(() => {
        let phones: string[];
        if (relationType === RelationType.Employee) {
          const { phoneHome, phoneMobile, phoneWork } =
            relation as LinkedEmployee;
          phones = [phoneHome, phoneMobile, phoneWork].filter((v) => !!v);
        } else {
          const { phone, phoneMobile, phoneWork } = relation as LinkedRelation;
          phones = [phone, phoneMobile, phoneWork].filter((v) => !!v);
        }

        return phones;
      }, [relation]);

      const stopPropagation = useCallback(
        (event: MouseEvent<HTMLSpanElement>) => {
          event.stopPropagation();
        },
        []
      );

      const renderContactData = useMemo(() => {
        if (!phones.length && !email) return null;

        return (
          <div>
            {phones.map((phone, idx) => (
              <span key={idx}>
                <a
                  className="as-link"
                  onClick={stopPropagation}
                  href={`tel:${phone}`}
                >
                  {phone}
                </a>
                {(idx !== phones.length - 1 || !!email) && ", "}
              </span>
            ))}
            {!!email && <MailTo email={email} />}
          </div>
        );
      }, [email, phones]);

      const onClickHandler = useCallback(() => {
        switch (relationType) {
          case RelationType.ContactPerson: {
            const path = route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
              id,
            });
            return onNavigate(path);
          }
          case RelationType.ContactCompany: {
            const path = route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
              id,
            });
            return onNavigate(path);
          }
          case RelationType.Employee: {
            const path = route(EMPLOYEEROUTES.EMPLOYEE.URI, { id });
            return onNavigate(path);
          }
          case RelationType.Office: {
            const path = route(OFFICESROUTES.OFFICE_DETAIL.URI, { id });
            return onNavigate(path);
          }
          default:
            return;
        }
      }, [relationType, id, onNavigate]);

      return (
        <div styleName="linked-relation" onClick={onClickHandler}>
          <div
            styleName={classNames("avatar", { "as-letters": !avatarUrl })}
            style={{
              backgroundImage: !!avatarUrl
                ? `url(${JSON.stringify(avatarUrl)})`
                : null,
            }}
          >
            {!avatarUrl && !!letterAvatar && <span>{letterAvatar}</span>}
          </div>
          <div styleName="meta">
            <div styleName="name">{displayName}</div>
            {renderContactData}
          </div>
        </div>
      );
    }
  )
);
