import { RootEntityType } from "@haywork/api/event-center";
import {
  LinkedRelation,
  MixedFormOption,
  ObjectAssignment,
  RentOffer,
  SaleOffer,
  TransactionMetaData,
  WithdrawReason,
  WithdrawReasonOption,
  PhotoBlob,
} from "@haywork/api/kolibri";
import { ASSIGNMENTROUTES } from "@haywork/constants";
import { AssignmentThunks, Dispatch } from "@haywork/middleware";
import { AssignmentDetailComponent } from "@haywork/modules/assignment";
import { editable, EditableHocProps } from "@haywork/modules/editable";
import {
  AppState,
  AssignmentSingleActions,
  LayoutActions,
} from "@haywork/stores";
import { push } from "connected-react-router";
import has from "lodash-es/has";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withdrawReasonOptions } from "./selectors";

export interface AssignmentDetailContainerProps
  extends RouteComponentProps<any>,
    EditableHocProps {
  objectAssignment: ObjectAssignment;
  assignment: ObjectAssignment;
  realEstateAgencyId: string;
  host: string;
  mixedForms: MixedFormOption[];
  saleOffer: SaleOffer;
  rentOffer: RentOffer;
  showStatusModal: boolean;
  statusState: string;
  statusError: string[];
  changeAvailabilityStatus: string;
  withdrawReasonOptions: WithdrawReasonOption[];
  withdrawState: string;
  showWithdrawModal: boolean;
  showSaveModal: boolean;
  saveAssignmentState: string;
  isInitial: boolean;
  transactionMetaData: TransactionMetaData;

  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) => void;
  toggleStatusModal: (status: boolean) => void;
  toggleWithdrawModal: (status: boolean) => void;
  toggleSaveModal: (status: boolean) => void;
  sellAssignment: (
    assignmentId: string,
    dateTransfer: Date,
    dateSold: Date,
    salePrice: number,
    endDateBankWarranty: Date,
    linkedApplicants: LinkedRelation[],
    isSaleAndLeaseBack: boolean,
    dateAgreement: Date,
    dateReservation: Date,
    isTransactionInformationConfidential: boolean
  ) => void;
  rentAssignment: (
    assignmentId: string,
    dateRentedFrom: Date,
    dateRentedUntil: Date,
    rentPrice: number,
    linkedApplicants: LinkedRelation[],
    isVacancyLaw: boolean,
    dateReservation: Date,
    dateSignDeed: Date,
    isTransactionInformationConfidential: boolean
  ) => void;
  clearAvailabilityStatus: () => void;
  withdrawAssignment: (
    assignmentId: string,
    withdrawReason: WithdrawReason
  ) => void;
  saveAndCloseAssignment: () => void;
  navigate: (route: string) => void;
}

const mapStateToProps: MapStateToProps<any, any, AppState> = (
  state: AppState
) => {
  if (
    !state.assignment.single ||
    !has(state.editable, "currentComponentState.objectAssignment")
  )
    return {};

  const { single } = state.assignment;
  const { saveAssignmentState, isInitial } = single;
  const { saleOffer, rentOffer } = state.editable.currentComponentState
    .objectAssignment || {
    saleOffer: undefined,
    rentOffer: undefined,
  };
  const { mixedForms } = state.main.mastertable.kolibri;

  return {
    objectAssignment: state.editable.currentComponentState.objectAssignment,
    transactionMetaData: single.transactionMetaData,
    assignment: single.objectAssignment,
    realEstateAgencyId: state.account.currentRealestateAgency.id,
    host: state.appSettings.host,
    mixedForms,
    saleOffer,
    rentOffer,
    showStatusModal: single.showStatusModal,
    withdrawReasonOptions: withdrawReasonOptions(
      single.objectAssignment.realEstateGroup
    )(state),
    withdrawState: single.withdrawState,
    showWithdrawModal: single.showWithdrawModal,
    showSaveModal: single.showSaveModal,
    saveAssignmentState,
    changeAvailabilityStatus: single.changeAvailabilityStatus,
    isInitial,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  openLightbox: (lightboxSlides: PhotoBlob[], lightboxCurrent: number) =>
    dispatch(LayoutActions.showLightbox({ lightboxSlides, lightboxCurrent })),
  toggleStatusModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleStatusModal(status)),
  toggleSaveModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleSaveModal(status)),
  toggleWithdrawModal: (status: boolean) =>
    dispatch(AssignmentSingleActions.toggleWithdrawModal(status)),
  sellAssignment: (
    assignmentId: string,
    dateTransfer: Date,
    dateSold: Date,
    salePrice: number,
    endDateBankWarranty: Date,
    linkedApplicants: LinkedRelation[],
    isSaleAndLeaseBack: boolean,
    dateAgreement: Date,
    dateReservation: Date,
    isTransactionInformationConfidential: boolean
  ) =>
    dispatch(
      AssignmentThunks.sellObjectAssignment(
        assignmentId,
        dateTransfer,
        dateSold,
        salePrice,
        endDateBankWarranty,
        linkedApplicants,
        isSaleAndLeaseBack,
        dateAgreement,
        dateReservation,
        isTransactionInformationConfidential
      )
    ),
  rentAssignment: (
    assignmentId: string,
    dateRentedFrom: Date,
    dateRentedUntil: Date,
    rentPrice: number,
    linkedApplicants: LinkedRelation[],
    isVacancyLaw: boolean,
    dateReservation: Date,
    dateSignDeed: Date,
    isTransactionInformationConfidential: boolean
  ) =>
    dispatch(
      AssignmentThunks.rentObjectAssignment(
        assignmentId,
        dateRentedFrom,
        dateRentedUntil,
        rentPrice,
        linkedApplicants,
        isVacancyLaw,
        dateReservation,
        dateSignDeed,
        isTransactionInformationConfidential
      )
    ),
  clearAvailabilityStatus: () =>
    dispatch(AssignmentThunks.clearAvailabilityStatus()),
  withdrawAssignment: (assignmentId: string, withdrawReason: WithdrawReason) =>
    dispatch(
      AssignmentThunks.withdrawObjectAssignment(assignmentId, withdrawReason)
    ),
  saveAndCloseAssignment: () =>
    dispatch(AssignmentThunks.saveAssignment(undefined, false, false, true)),
  navigate: (route: string) => dispatch(push(route)),
});

export const AssignmentDetailContainer = editable(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AssignmentDetailComponent)),
  {
    entityType: RootEntityType.ObjectAssignment,
    icon: "folder-open",
    thunk: AssignmentThunks.getAssignment,
    status: "assignment.single.objectAssignmentState",
    statePath: "assignment.single",
    action: AssignmentSingleActions.setAssignment,
    route: ASSIGNMENTROUTES.DETAIL.URI,
    confirm: {
      title: { key: "saveAssignmentConfirmTitle" },
      body: { key: "saveAssignmentConfirmBody" },
    },
  }
);
