import * as React from "react";
import {
  PureComponent,
  ComponentType,
  RefObject,
  createRef,
  CSSProperties,
} from "react";
import { ModalPortal } from "@haywork/portals";
import debounce from "lodash-es/debounce";

export interface InjectedContextProps {
  minWidth?: number;
  maxWidth?: number;
}
interface Props {
  parent: RefObject<HTMLElement>;
  positionOn?: RefObject<HTMLElement>;
  visible: boolean;
  onHide: () => void;
}
interface State {
  style: CSSProperties;
}

const defaultStyle: CSSProperties = {
  position: "absolute",
  zIndex: 115,
};
const fromEdge = 16;

export const context = () => <P extends object>(
  Component: ComponentType<P & InjectedContextProps>
) => {
  return class Context extends PureComponent<P & Props, State> {
    private ref: RefObject<HTMLDivElement> = createRef();

    constructor(props) {
      super(props);

      this.state = {
        style: null,
      };

      this.resizeAndScrollEventListener = debounce(
        this.resizeAndScrollEventListener.bind(this),
        250,
        { leading: true }
      );
      this.documentClickListener = this.documentClickListener.bind(this);
      this.calculatePosition = this.calculatePosition.bind(this);
    }

    public componentDidMount() {
      window.addEventListener("resize", this.resizeAndScrollEventListener);
      document.addEventListener("scroll", this.resizeAndScrollEventListener);
      document.addEventListener("click", this.documentClickListener);
    }

    public componentWillUnmount() {
      window.removeEventListener("resize", this.resizeAndScrollEventListener);
      document.removeEventListener("scroll", this.resizeAndScrollEventListener);
      document.removeEventListener("click", this.documentClickListener);
    }

    public componentDidUpdate(prevProps: Props) {
      if (prevProps.visible !== this.props.visible) {
        this.calculatePosition();
      }
    }

    public render() {
      if (!this.props.visible) return null;
      const style = {
        ...defaultStyle,
        ...this.state.style,
      };

      return (
        <ModalPortal>
          <div style={style} ref={this.ref}>
            <Component {...this.props} />
          </div>
        </ModalPortal>
      );
    }

    private resizeAndScrollEventListener() {
      if (!this.props.visible) return;
      this.props.onHide();
    }

    private documentClickListener(event: MouseEvent) {
      if (
        !!this.props.visible &&
        (!this.ref.current ||
          !this.ref.current.contains(event.target as Node)) &&
        (!this.props.parent.current ||
          !this.props.parent.current.contains(event.target as Node))
      ) {
        this.props.onHide();
      }
    }

    private calculatePosition() {
      const { parent, visible, positionOn } = this.props;
      if (!visible) {
        this.setState({ style: null });
        return;
      }

      const { innerHeight, innerWidth } = window;

      if (!parent.current || !this.ref.current) return;
      let top: number, left: number, height: number;

      const calculateOn =
        !!positionOn && !!positionOn.current ? positionOn : parent;

      const {
        width: clientWidth,
        height: clientHeight,
      } = this.ref.current.getBoundingClientRect();

      const {
        top: parentTop,
        left: parentLeft,
        width: parentWidth,
        height: parentHeight,
      } = calculateOn.current.getBoundingClientRect();

      const maxHeight = innerHeight - 2 * fromEdge;
      left = parentLeft + parentWidth / 2 - clientWidth;

      if (left < fromEdge) {
        left = fromEdge;
      }

      if (clientHeight > maxHeight) {
        top = fromEdge;
        height = maxHeight;
      } else {
        top = parentTop + parentHeight / 2;

        const bottomOverflow = this.getBottomOverflow(
          top,
          clientHeight,
          innerHeight
        );
        if (bottomOverflow > 0) {
          top = top - bottomOverflow;
        }
      }

      const style: CSSProperties = { top, left, height };
      this.setState({ style });
    }

    private getBottomOverflow(
      top: number,
      height: number,
      windowHeight: number
    ) {
      return top + fromEdge + height - windowHeight;
    }
  };
};
