import { EmailDraft } from ".";
import { ActionType, ActionTypes } from "./actions";
import * as moment from "moment";
import uniqBy from "lodash-es/uniqBy";

type DeleteAction = {
  id: string;
  dateTimeReceived: Date;
};

export type State = {
  drafts: EmailDraft[];
  deleteActions: DeleteAction[];
};

const INITIAL: State = {
  drafts: [],
  deleteActions: [],
};

export const reducer = (state = INITIAL, action: ActionTypes): State => {
  switch (action.type) {
    case ActionType.AppendDrafts: {
      let { drafts } = action;
      const deleteableIds = state.deleteActions.map((a) => a.id);
      const now = moment();

      drafts = uniqBy([...drafts, ...state.drafts], (draft) => draft.id);
      drafts = drafts.filter((draft) => !deleteableIds.includes(draft.id));

      const deleteActions = state.deleteActions.filter((a) => {
        const date = moment(a.dateTimeReceived);
        return Math.abs(date.diff(now, "minute", true)) < 1;
      });

      return {
        ...state,
        drafts,
        deleteActions,
      };
    }
    case ActionType.DeleteDraft: {
      const { id } = action;
      const deleteAction: DeleteAction = { id, dateTimeReceived: new Date() };
      const now = moment();

      let deleteActions = state.deleteActions.filter((a) => a.id !== id);
      deleteActions = [...deleteActions, deleteAction];

      const deleteableIds = deleteActions.map((a) => a.id);
      const drafts = state.drafts.filter(
        (draft) => !deleteableIds.includes(draft.id)
      );

      deleteActions = deleteActions.filter((a) => {
        const date = moment(a.dateTimeReceived);
        return Math.abs(date.diff(now, "minute", true)) < 1;
      });

      return {
        ...state,
        drafts,
        deleteActions,
      };
    }
    case ActionType.Clear:
    case ActionType.ResetApp: {
      return INITIAL;
    }
    default: {
      return state;
    }
  }
};
