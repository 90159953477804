import {
  LinkedRelation,
  RelationSnapShot,
  RelationType,
  SearchAssignment,
  MatchMailTrigger,
} from "@haywork/api/kolibri";
import { RELATIONROUTES } from "@haywork/constants";
import { NewEntityType } from "@haywork/enum";
import {
  Form,
  FormControls,
  FormReference,
  FormReturnValue,
  Input,
  Validators,
} from "@haywork/modules/form";
import { NewEntity, NewEntityOptions } from "@haywork/modules/new-entity";
import { SearchAssignmentEditContactContainerProps } from "@haywork/modules/search-assignment";
import { FormControlUtil, RouteUtil } from "@haywork/util";
import get from "lodash-es/get";
import has from "lodash-es/has";
import isArray from "lodash-es/isArray";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { Link } from "react-router-dom";
import I18n from "@haywork/components/i18n";

const styles = require("./edit-contact.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;
const route = RouteUtil.mapStaticRouteValues;

export interface SearchAssignmentEditContactComponentProps {}
interface State {
  newEntityVisible: boolean;
  newEntityOptions: NewEntityOptions;
}
type Props = SearchAssignmentEditContactComponentProps &
  SearchAssignmentEditContactContainerProps;

@CSSModules(styles, { allowMultiple: true })
export class SearchAssignmentEditContactComponent extends React.Component<
  Props,
  State
> {
  private formControls: FormControls;
  private formRef: FormReference;

  constructor(props) {
    super(props);

    this.addNewRelation = this.addNewRelation.bind(this);
    this.onNewEntityCloseHandler = this.onNewEntityCloseHandler.bind(this);
    this.onNewRelationHandler = this.onNewRelationHandler.bind(this);

    const { searchAssignment } = this.props;
    const matchMailTrigger =
      !searchAssignment.matchMailTrigger ||
      searchAssignment.matchMailTrigger === MatchMailTrigger.AllChanges
        ? MatchMailTrigger.PictureAndPriceChanges
        : searchAssignment.matchMailTrigger;

    this.formControls = {
      linkedRelations: {
        value: value(searchAssignment, "linkedRelations", []),
        validators: [
          Validators.arrayMinCount(1, {
            key: "missingRelationSearchAssignment",
          }),
        ],
      },
      matchMailPeriod: { value: value(searchAssignment, "matchMailPeriod") },
      sendEmailStartDate: {
        value: value(searchAssignment, "sendEmailStartDate"),
      },
      sendEmailEndDate: { value: value(searchAssignment, "sendEmailEndDate") },
      matchMailTrigger: { value: matchMailTrigger },
    };

    this.state = {
      newEntityVisible: false,
      newEntityOptions: null,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !!this.formRef &&
      get(prevProps.searchAssignment, "dateTimeModified") !==
        get(this.props.searchAssignment, "dateTimeModified")
    ) {
      const { searchAssignment } = this.props;
      const matchMailTrigger =
        !searchAssignment.matchMailTrigger ||
        searchAssignment.matchMailTrigger === MatchMailTrigger.AllChanges
          ? MatchMailTrigger.PictureAndPriceChanges
          : searchAssignment.matchMailTrigger;

      this.formRef.update(
        {
          linkedRelations: value(searchAssignment, "linkedRelations", []),
          matchMailPeriod: value(searchAssignment, "matchMailPeriod"),
          sendEmailStartDate: value(searchAssignment, "sendEmailStartDate"),
          sendEmailEndDate: value(searchAssignment, "sendEmailEndDate"),
          matchMailTrigger,
        },
        true
      );
    }
  }

  public render() {
    return (
      <div styleName="client">
        <div className="container-fluid">
          <Form
            name="contact"
            onChange={this.onChangeHandler}
            formControls={this.formControls}
            form={(ref) => (this.formRef = ref)}
          >
            <div className="form__row">
              <div styleName="subTitle">
                <I18n value="thisSearchProfileIsFor" />
              </div>
              {/* Linked Relations */}
              <div className="form__row">
                <label htmlFor="subject" className="sr-only">
                  <I18n value="thisSearchProfileIsFor" />
                </label>
                <div className="input__wrapper">
                  <Input.RelationQuery
                    name="linkedRelations"
                    onAdd={this.addNewRelation}
                    returnAsArray={true}
                    onNavigateToRelation={this.props.navigate}
                    filterByRelationTypes={[
                      RelationType.ContactCompany,
                      RelationType.ContactPerson,
                    ]}
                  />
                </div>
                {/* Warning */}
                {this.renderNoEmailWarnings()}
              </div>
              <div styleName="subHeader" className="form__row">
                <div styleName="subTitle">
                  <I18n value="alwaysNotifiedPerMail" />
                </div>
              </div>
              {/* Matchmail period */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label
                      styleName="fixedWidth"
                      className="pre"
                      htmlFor="sendMatchmail"
                    >
                      <I18n value="sendMatchmail" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.RadioGroup name="matchMailPeriod" asButtonList>
                      {this.props.matchmailPeriods.map(
                        (matchmailPeriod, idx) => (
                          <Input.Radio
                            value={matchmailPeriod.value}
                            label={matchmailPeriod.label}
                            key={idx}
                          />
                        )
                      )}
                    </Input.RadioGroup>
                  </div>
                </div>
              </div>

              {/* Matchmail dates */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label
                      styleName="fixedWidth"
                      className="pre"
                      htmlFor="sendEmailStartDate"
                    >
                      <I18n value="sendEmailStartDate" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.Datepicker name="sendEmailStartDate" />
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <label className="pre" htmlFor="sendEmailEndDate">
                      <I18n value="sendEmailEndDate" />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.Datepicker name="sendEmailEndDate" />
                  </div>
                </div>
              </div>

              {/* Send Matchmail For */}
              <div className="form__row">
                <div className="form__group">
                  <div className="column">
                    <label
                      styleName="fixedWidth"
                      className="pre"
                      htmlFor="matchMailTrigger"
                    >
                      <I18n value="matchMailTrigger" asHtml />
                    </label>
                  </div>
                  <div className="column__spacer" />
                  <div className="column">
                    <Input.RadioGroup name="matchMailTrigger" asButtonList>
                      {this.props.matchmailTriggers.map(
                        (matchmailTrigger, idx) => (
                          <Input.Radio
                            value={matchmailTrigger.value}
                            label={matchmailTrigger.label}
                            key={idx}
                          />
                        )
                      )}
                    </Input.RadioGroup>
                  </div>
                </div>
              </div>

              <div styleName="info">
                <I18n value="matchmail.info.initial" asHtml />
              </div>
            </div>
          </Form>
          <div />
        </div>

        <NewEntity
          visible={this.state.newEntityVisible}
          options={this.state.newEntityOptions}
          onClose={this.onNewEntityCloseHandler}
          onNewRelation={this.onNewRelationHandler}
        />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: SearchAssignmentEditContactComponentProps &
      SearchAssignmentEditContactContainerProps
  ) {
    const { searchAssignment: newSearchAssignment } = nextProps;
    const { searchAssignment: oldSearchAssignment } = this.props;

    if (
      has(newSearchAssignment, "linkedRelations") &&
      get(newSearchAssignment, "linkedRelations", []).length !==
        get(oldSearchAssignment, "linkedRelations", []).length &&
      !!this.formRef
    ) {
      const { linkedRelations } = newSearchAssignment;
      this.formRef.update({ linkedRelations });
    }
  }

  private renderNoEmailWarnings() {
    if (!this.props.searchAssignment.linkedRelations) return null;
    return this.props.searchAssignment.linkedRelations.map(
      (linkedRelation, idx) => {
        if (!linkedRelation.email) {
          return (
            <div styleName="email__error" key={idx}>
              <strong>
                <I18n value="watchOut" />
              </strong>{" "}
              {this.renderRelationLink(linkedRelation)}{" "}
              <I18n value="hasNoEmailAddress" />
            </div>
          );
        }
      }
    );
  }

  private addNewRelation(name: string) {
    if (this.state.newEntityVisible) return;
    this.setState({
      newEntityVisible: true,
      newEntityOptions: {
        type: NewEntityType.Relation,
        newRelation: {
          name,
        },
      },
    });
  }

  private onNewEntityCloseHandler() {
    this.setState({
      newEntityVisible: false,
    });
  }

  private onNewRelationHandler(relation: RelationSnapShot) {
    let { linkedRelations } = this.formRef.getValues();
    linkedRelations = linkedRelations || [];

    this.formRef.update({
      linkedRelations: [...linkedRelations, relation],
    });

    this.setState({
      newEntityVisible: false,
    });
  }

  private renderRelationLink(linkedRelation: LinkedRelation) {
    const relationRoute =
      linkedRelation.typeOfRelation === RelationType.ContactPerson
        ? route(RELATIONROUTES.CONTACT_PERSON_DETAIL.URI, {
            id: linkedRelation.id,
          })
        : route(RELATIONROUTES.CONTACT_COMPANY_DETAIL.URI, {
            id: linkedRelation.id,
          });

    return (
      <Link
        to={relationRoute}
        key={linkedRelation.id}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {linkedRelation.displayName}
      </Link>
    );
  }

  private onChangeHandler(values: FormReturnValue) {
    const { searchAssignment } = this.props;

    const linkedRelations = !!values.linkedRelations
      ? isArray(values.linkedRelations)
        ? values.linkedRelations
        : [values.linkedRelations]
      : [];

    const updatedFields = {
      ...values,
      linkedRelations,
    };

    const newState: SearchAssignment = {
      ...searchAssignment,
      ...updatedFields,
    };

    this.props.updateSearchAssignment(newState, this.props.path);
  }
}
