import {
  ApartmentCharacteristicOption,
  ApartmentSortOption,
  HouseCharacteristicOption,
  HouseSortOption,
  HouseTypeOption,
  ListingTypeOption,
  ParkingTypeOption,
  ResidentialSubtypeOtherOption,
} from "@haywork/api/kolibri";
import { MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { connect, MapStateToProps } from "react-redux";
import {
  ListingTypeComponent,
  ListingTypeComponentProps,
} from "./listing-type";

type StateProps = {
  listingTypes: ListingTypeOption[];
  houseSorts: HouseSortOption[];
  houseTypes: HouseTypeOption[];
  houseCharacteristics: HouseCharacteristicOption[];
  apartmentSorts: ApartmentSortOption[];
  apartmentCharacteristics: ApartmentCharacteristicOption[];
  parkingTypeOptions: ParkingTypeOption[];
  residentialSubtypeOthers: ResidentialSubtypeOtherOption[];
};
type DispatchProps = {};

const mapStateToProps: MapStateToProps<
  StateProps,
  ListingTypeComponentProps,
  AppState
> = (state) => {
  const {
    listingTypes,
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
  } = state.main.mastertable.kolibri;

  return {
    listingTypes,
    houseSorts,
    houseTypes,
    houseCharacteristics,
    apartmentSorts,
    apartmentCharacteristics,
    parkingTypeOptions,
    residentialSubtypeOthers,
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  ListingTypeComponentProps
> = (dispatch) => ({});

export type ListingTypeContainerProps = StateProps & DispatchProps;
export const ListingTypeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingTypeComponent);
