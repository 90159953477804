import { Address, CountryOption } from "@haywork/api/kolibri";
import { KEYCODE } from "@haywork/constants";
import { SUPPORTURI } from "@haywork/constants/support-uris";
import { ButtonLoader, ResourceText } from "@haywork/modules/shared";
import { AddressRequest } from "@haywork/request";
import { AsyncUtil, StringUtil } from "@haywork/util";
import classNames from "classnames";
import * as deepEqual from "deep-equal";
import first from "lodash-es/first";
import get from "lodash-es/get";
import has from "lodash-es/has";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { InputComponentProps } from "../../input.component";
import Select from "../../select-v2";
import {
  BaseQueryComponent,
  QueryResultStringReturnValue,
} from "../base.component";

const styles = require("./location.component.scss");

export enum LocationSearchType {
  PostalCode = "PostalCode",
  Google = "Google",
}

export interface LocationQueryV2ComponentProps {
  disableCountrySelection?: boolean;
  disablePostalCodeSearch?: boolean;
  searchType?: LocationSearchType;
  countries: CountryOption[];
  countryIso2: string;
  culture: string;
  backgroundColor?: string;
  showManualAddressOnAddress?: boolean;
  canSearchWithoutPostalLetters?: boolean;
  canSearchWithoutHousenumber?: boolean;
  disableHousenumber?: boolean;
}
interface State {
  searchType: LocationSearchType;
  postalCodeDigits: string;
  postalCodeLetters: string;
  houseNumber: string;
  requestingPostalCode: boolean;
  postalCodeHasFocus: boolean;
  countryIso2: string;
  requestingGoogle: boolean;
  noLocationFound: boolean;
  addressResult: Address;
  clearGoogleSearch: () => void;
}
type Props = LocationQueryV2ComponentProps & InputComponentProps;

@CSSModules(styles, { allowMultiple: true })
export class LocationQueryV2Component extends React.Component<Props, State> {
  private postalCodeDigitsRef: HTMLInputElement;
  private postalCodeLettersRef: HTMLInputElement;
  private houseNumberRef: HTMLInputElement;
  private queryInputRef: HTMLInputElement;

  constructor(props) {
    super(props);

    const addressResult =
      !!this.props.showManualAddressOnAddress && !!this.props.value
        ? this.parseReceivedValue(this.props.value)
        : null;

    let countryIso2 = "NL";
    if (!!this.props.countryIso2) {
      countryIso2 = this.props.countryIso2;
    }

    this.state = {
      searchType:
        !!this.props.disablePostalCodeSearch || countryIso2 !== "NL"
          ? LocationSearchType.Google
          : !!this.props.searchType
          ? this.props.searchType
          : LocationSearchType.PostalCode,
      postalCodeDigits: "",
      postalCodeLetters: "",
      houseNumber: "",
      requestingPostalCode: false,
      postalCodeHasFocus: false,
      countryIso2: this.props.countryIso2,
      requestingGoogle: false,
      clearGoogleSearch: null,
      noLocationFound: false,
      addressResult,
    };

    this.onManualAddressChange = this.onManualAddressChange.bind(this);
    this.onManualAddressBlur = this.onManualAddressBlur.bind(this);
    this.onClearManualForm = this.onClearManualForm.bind(this);
    this.onCountryChangeHandler = this.onCountryChangeHandler.bind(this);
    this.renderCountryOption = this.renderCountryOption.bind(this);
    this.renderCountryValue = this.renderCountryValue.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.renderSelectedStringValue = this.renderSelectedStringValue.bind(this);
    this.onGoogleQueryChange = this.onGoogleQueryChange.bind(this);
    this.onPostalCodeDigitsChange = this.onPostalCodeDigitsChange.bind(this);
    this.onPostalCodeFocus = this.onPostalCodeFocus.bind(this);
    this.onPostalCodeBlur = this.onPostalCodeBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPostalCodeLettersChange = this.onPostalCodeLettersChange.bind(this);
    this.onPostalCodeFocus = this.onPostalCodeFocus.bind(this);
    this.onPostalCodeBlur = this.onPostalCodeBlur.bind(this);
    this.onHouseNumberChange = this.onHouseNumberChange.bind(this);
    this.onPostalCodeSubmit = this.onPostalCodeSubmit.bind(this);
    this.toggleSearchType = this.toggleSearchType.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.value, this.props.value)) {
      this.setState({
        addressResult: this.parseReceivedValue(this.props.value),
      });
    }

    if (prevProps.countryIso2 !== this.props.countryIso2) {
      this.setState(({ searchType }) => ({
        countryIso2: this.props.countryIso2,
        searchType:
          this.props.countryIso2 !== "NL"
            ? LocationSearchType.Google
            : searchType,
      }));
    }
  }

  public render() {
    const canSubmit = this.canSubmit();
    const locationStyle = classNames(
      "location",
      `show-${this.state.searchType.toString()}`,
      {
        "postalcode-has-focus":
          this.state.postalCodeHasFocus &&
          !this.state.requestingPostalCode &&
          !this.props.disabled,
        "show-form":
          !!this.props.showManualAddressOnAddress && !!this.state.addressResult,
      }
    );
    const toggleResource =
      this.state.searchType === LocationSearchType.Google
        ? "locationInputToggleToPostalCode"
        : "locationInputToggleToGoogle";
    const postalCodeBackgroundStyle = {
      backgroundColor: this.props.backgroundColor || "#ffffff",
    };

    return (
      <div styleName={locationStyle}>
        <div styleName="location__search">
          {!this.props.disableCountrySelection && (
            <div styleName="country-select">
              <Select
                name="country"
                values={this.props.countries}
                valuesProp="iso2CodeValue"
                filterProp="displayName"
                displayValueFn={this.renderCountryValue}
                displayFn={this.renderCountryOption}
                onChange={this.onCountryChangeHandler}
                value={this.state.countryIso2}
                disabled={
                  this.state.requestingGoogle ||
                  this.state.requestingPostalCode ||
                  this.props.disabled
                }
              />
            </div>
          )}
          <div styleName="google-search" className="google-search-container">
            <BaseQueryComponent
              name={`${this.props.name}GoogleSearch`}
              value=""
              asyncValues={(value) =>
                AddressRequest.addressSearch(value, this.state.countryIso2)
              }
              optionValue={this.renderOptionValue}
              selectedStringValue={this.renderSelectedStringValue}
              onChange={this.onGoogleQueryChange}
              disabled={this.state.requestingGoogle || this.props.disabled}
              loading={this.state.requestingGoogle}
              inputRef={(ref) => (this.queryInputRef = ref)}
              blockExternalValues
              placeholder="addressPlaceholder"
              clear={(clearGoogleSearch) =>
                this.setState({ clearGoogleSearch })
              }
            />
          </div>
          <div styleName="postalcode-search">
            <div styleName="digits" style={postalCodeBackgroundStyle}>
              <input
                type="text"
                name={`${this.props.name}PostalCodeDigits`}
                value={this.state.postalCodeDigits}
                onChange={this.onPostalCodeDigitsChange}
                onFocus={this.onPostalCodeFocus}
                onBlur={this.onPostalCodeBlur}
                onKeyDown={this.onKeyDown}
                disabled={this.state.requestingPostalCode}
                ref={(ref) => (this.postalCodeDigitsRef = ref)}
                placeholder="6811"
                data-cy="CY-postalCodeDigits"
                autoComplete="off"
                data-lpignore="true"
              />
            </div>
            <div styleName="letters">
              <input
                type="text"
                name={`${this.props.name}PostalCodeLetters`}
                value={this.state.postalCodeLetters}
                onChange={this.onPostalCodeLettersChange}
                onFocus={this.onPostalCodeFocus}
                onBlur={this.onPostalCodeBlur}
                onKeyDown={this.onKeyDown}
                disabled={this.state.requestingPostalCode}
                ref={(ref) => (this.postalCodeLettersRef = ref)}
                placeholder="AA"
                data-cy="CY-postalCodeLetters"
                autoComplete="off"
                data-lpignore="true"
              />
            </div>
            {!this.props.disableHousenumber && (
              <div styleName="housenumber">
                <input
                  type="text"
                  name={`${this.props.name}HouseNumber`}
                  value={this.state.houseNumber}
                  onChange={this.onHouseNumberChange}
                  onKeyDown={this.onKeyDown}
                  disabled={this.state.requestingPostalCode}
                  ref={(ref) => (this.houseNumberRef = ref)}
                  placeholder="7"
                  data-cy="CY-houseNumber"
                  autoComplete="off"
                  data-lpignore="true"
                />
              </div>
            )}
            <button
              className="btn btn-primary"
              type="button"
              disabled={!canSubmit || this.state.requestingPostalCode}
              onClick={this.onPostalCodeSubmit}
              data-cy="CY-submitAddressSearch"
            >
              <ButtonLoader
                resourceKey="search"
                loading={this.state.requestingPostalCode}
              />
            </button>
          </div>
          {!this.props.disablePostalCodeSearch &&
            this.state.countryIso2 === "NL" && (
              <div
                styleName="toggle-search-type"
                onClick={this.toggleSearchType}
              >
                <ResourceText resourceKey={toggleResource} />
              </div>
            )}
        </div>
        <div styleName="location__form">
          <div styleName="row">
            <div styleName="column streetname">
              <label htmlFor={`${this.props.name}StreetName`}>
                <ResourceText resourceKey="locationControlStreetName" />
              </label>
              <input
                type="text"
                name={`${this.props.name}StreetName`}
                id={`${this.props.name}StreetName`}
                onChange={(event) =>
                  this.onManualAddressChange(event, "street")
                }
                onBlur={this.onManualAddressBlur}
                value={get(this.state.addressResult, "street.name") || ""}
                data-lpignore="true"
              />
            </div>
            <div styleName="column streetnumber">
              <label htmlFor={`${this.props.name}StreetNumber`}>
                <ResourceText resourceKey="locationControlStreetNumber" />
              </label>
              <input
                type="text"
                name={`${this.props.name}StreetNumber`}
                id={`${this.props.name}StreetNumber`}
                onChange={(event) =>
                  this.onManualAddressChange(event, "housenumber")
                }
                onBlur={this.onManualAddressBlur}
                value={get(this.state.addressResult, "houseNumber") || ""}
                data-lpignore="true"
              />
            </div>
            <div styleName="column postfix">
              <label htmlFor={`${this.props.name}StreetNumberPostfix`}>
                <ResourceText resourceKey="locationControlStreetNumberPostfix" />
              </label>
              <input
                type="text"
                name={`${this.props.name}StreetNumberPostfix`}
                id={`${this.props.name}StreetNumberPostfix`}
                onChange={(event) =>
                  this.onManualAddressChange(event, "postfix")
                }
                onBlur={this.onManualAddressBlur}
                value={
                  get(this.state.addressResult, "houseNumberPostfix") || ""
                }
                data-lpignore="true"
              />
            </div>
          </div>
          <div styleName="row last">
            <div styleName="column postalcode">
              <label htmlFor={`${this.props.name}PostalCode`}>
                <ResourceText resourceKey="locationControlPostalCode" />
              </label>
              <input
                type="text"
                name={`${this.props.name}PostalCode`}
                id={`${this.props.name}PostalCode`}
                onChange={(event) =>
                  this.onManualAddressChange(event, "postalcode")
                }
                onBlur={this.onManualAddressBlur}
                value={get(this.state.addressResult, "postalCode") || ""}
                data-lpignore="true"
              />
            </div>
            <div styleName="column locality">
              <label htmlFor={`${this.props.name}Locality`}>
                <ResourceText resourceKey="locationControlLocality" />
              </label>
              <input
                type="text"
                name={`${this.props.name}Locality`}
                id={`${this.props.name}Locality`}
                onChange={(event) =>
                  this.onManualAddressChange(event, "locality")
                }
                onBlur={this.onManualAddressBlur}
                value={get(this.state.addressResult, "locality.name") || ""}
                data-lpignore="true"
              />
            </div>
            <div styleName="column">
              <div
                styleName="clear-manual-form"
                onClick={this.onClearManualForm}
              >
                <ResourceText resourceKey="locationControlClearManualForm" />
              </div>
            </div>
          </div>
        </div>
        {!!this.state.noLocationFound && (
          <div styleName="location-not-found">
            <ResourceText
              resourceKey="couldNotFindAddress"
              values={{ path: SUPPORTURI.ADDRESSNOTFOUND }}
              asHtml
            />
          </div>
        )}
      </div>
    );
  }

  private canSubmit(): boolean {
    const { postalCodeDigits, postalCodeLetters, houseNumber } = this.state;
    const {
      canSearchWithoutHousenumber,
      canSearchWithoutPostalLetters,
      disableHousenumber,
    } = this.props;

    return (
      !!postalCodeDigits &&
      postalCodeDigits.length === 4 &&
      (!!postalCodeLetters || !!canSearchWithoutPostalLetters) &&
      (!!houseNumber ||
        !!disableHousenumber ||
        !!canSearchWithoutHousenumber) &&
      !this.props.disabled
    );
  }

  private toggleSearchType() {
    const searchType =
      this.state.searchType === LocationSearchType.Google
        ? LocationSearchType.PostalCode
        : LocationSearchType.Google;

    this.setState({ searchType }, () => {
      switch (this.state.searchType) {
        case LocationSearchType.PostalCode: {
          this.postalCodeDigitsRef.focus();
          this.state.clearGoogleSearch();
        }
        default: {
          this.queryInputRef.focus();
          this.setState({
            postalCodeDigits: "",
            postalCodeLetters: "",
            houseNumber: "",
          });
        }
      }
    });
  }

  private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.keyCode) {
      case KEYCODE.ENTER:
        this.onPostalCodeSubmit();
        break;
      default:
        break;
    }
  }

  private async onPostalCodeDigitsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    const filteredValue = (value || "").replace(/\D/gi, "");
    const postalCodeDigits = filteredValue.slice(0, 4);

    this.setState({ postalCodeDigits });

    if (filteredValue.length >= 4) {
      await AsyncUtil.wait(5);
      this.postalCodeLettersRef.focus();
    }
  }

  private async onPostalCodeLettersChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    const filteredValue = (value || "").replace(/\d/gi, "");
    const postalCodeLetters = filteredValue.slice(0, 2).toUpperCase();

    this.setState({ postalCodeLetters });

    if (value.length >= 2 && !!this.houseNumberRef) {
      await AsyncUtil.wait(5);
      this.houseNumberRef.focus();
    }
  }

  private onHouseNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const houseNumber = (value || "").replace(/\D/gi, "");

    this.setState({ houseNumber });
  }

  private onPostalCodeFocus() {
    this.setState({ postalCodeHasFocus: true });
  }

  private onPostalCodeBlur() {
    this.setState({ postalCodeHasFocus: false });
  }

  private onCountryChangeHandler(countryIso2: string) {
    let state = {
      ...this.state,
      countryIso2,
    };

    switch (countryIso2) {
      case "NL": {
        state = {
          ...state,
          searchType: this.state.searchType,
        };
        break;
      }
      default: {
        state = {
          ...state,
          searchType: LocationSearchType.Google,
          postalCodeDigits: "",
          postalCodeLetters: "",
          houseNumber: "",
        };
        break;
      }
    }

    this.setState(state);
  }

  private renderFlagClassName(isoCode: string) {
    if (!isoCode) return null;
    return `famfamfam-flag-${isoCode.toLowerCase()}`;
  }

  private renderOptionValue(
    location: google.maps.places.AutocompletePrediction,
    query: string
  ): React.ReactElement<HTMLDivElement> {
    return (
      <div
        dangerouslySetInnerHTML={StringUtil.highlight(
          location.description,
          query
        )}
      />
    );
  }

  private renderSelectedStringValue(
    value: any
  ): QueryResultStringReturnValue<any> {
    const resultString = value.description;

    return {
      value,
      resultString,
    };
  }

  private async onGoogleQueryChange(
    predictions: google.maps.places.AutocompletePrediction[]
  ) {
    if (this.state.requestingGoogle || !predictions || !predictions.length) {
      return;
    }

    this.setState({ requestingGoogle: true, noLocationFound: false });

    const prediction = first(predictions);

    try {
      const address = await AddressRequest.searchAddress(
        prediction.description,
        this.state.countryIso2,
        this.props.culture
      ).then((response) => response.results);

      if (!address) {
        return this.setState({ noLocationFound: true });
      }

      this.state.clearGoogleSearch();

      this.props.onChange(address);
      if (!!this.props.showManualAddressOnAddress) {
        this.setState({ addressResult: address });
      }
    } catch (error) {
      this.setState({ noLocationFound: true });
      throw error;
    } finally {
      this.setState({ requestingGoogle: false });
    }
  }

  private async onPostalCodeSubmit() {
    if (this.state.requestingPostalCode || !this.canSubmit()) return;

    this.setState({
      requestingPostalCode: true,
      postalCodeHasFocus: false,
      noLocationFound: false,
    });

    try {
      const { postalCodeDigits, postalCodeLetters, houseNumber, countryIso2 } =
        this.state;
      const address = await AddressRequest.searchAddressByPostalCode(
        `${postalCodeDigits} ${postalCodeLetters}`,
        parseInt(houseNumber),
        countryIso2,
        this.props.culture
      ).then((response) => response.results);

      if (!address) {
        return this.setState({ noLocationFound: true });
      }

      this.props.onChange(address);
      this.setState({
        postalCodeDigits: "",
        postalCodeLetters: "",
        houseNumber: "",
      });

      if (!!this.props.showManualAddressOnAddress) {
        this.setState({ addressResult: address });
      }
    } catch (error) {
      this.setState({ noLocationFound: true });
      throw error;
    } finally {
      this.setState({ requestingPostalCode: false });
    }
  }

  private onManualAddressChange(
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    let addressResult = this.state.addressResult;
    const value: any = event.target.value;

    switch (name) {
      case "street":
        addressResult = {
          ...addressResult,
          street: { ...addressResult.street, name: value },
        };
        break;
      case "housenumber":
        addressResult = {
          ...addressResult,
          houseNumber: value,
        };
        break;
      case "postfix":
        addressResult = {
          ...addressResult,
          houseNumberPostfix: value,
        };
        break;
      case "postalcode":
        addressResult = {
          ...addressResult,
          postalCode: value,
        };
        break;
      case "locality":
        addressResult = {
          ...addressResult,
          locality: { ...addressResult.locality, name: value },
        };
        break;
      default:
        break;
    }

    this.setState({ addressResult });
  }

  private onManualAddressBlur() {
    if (!this.state.addressResult) return;
    this.props.onChange(this.state.addressResult);
  }

  private onClearManualForm() {
    this.setState({
      addressResult: null,
    });
    this.props.onChange("");
  }

  private parseReceivedValue(value: Address): Address {
    return !has(value, "street.name") &&
      !has(value, "houseNumber") &&
      !has(value, "houseNumberPostfix") &&
      !has(value, "postalCode") &&
      !has(value, "locality.name")
      ? null
      : value;
  }

  private renderCountryValue(country: CountryOption) {
    return (
      <div className="form__select-option">
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
      </div>
    );
  }

  private renderCountryOption(
    country: CountryOption,
    query: string,
    active: boolean,
    selected: boolean
  ) {
    return (
      <div className={classNames("form__select-option", { active, selected })}>
        <span className={this.renderFlagClassName(country.iso2CodeValue)} />{" "}
        <span
          dangerouslySetInnerHTML={StringUtil.highlight(
            country.displayName,
            query
          )}
        />
      </div>
    );
  }
}
