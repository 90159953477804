import {
  AppClient,
  ReviewSnapShot,
  AppClientSnapShot,
  EmployeeSettingsSingleItemResponse,
  CompanySettingsSingleItemResponse
} from "@haywork/api/authorization";

export enum singleAppClienActions {
  SetStatus = "Authorization.SingleAppClient.SetStatus",
  SetClient = "Authorization.SingleAppClient.SetClient",
  ToggleConsentModal = "Authorization.SingleAppClient.ToggleConsentModal",
  SetReviewsStatus = "Authorization.SingleAppClient.SetReviewsStatus",
  SetReviews = "Authorization.SingleAppClient.SetReviews",
  SetConsentStatus = "Authorization.SingleAppClient.SetConsentStatus",
  ToggleConsentRetractConfirm = "Authorization.SingleAppClient.ToggleConsentRetractConfirm",
  SetRecommendations = "Authorization.SingleAppClient.SetRecommendations",
  SetSettings = "Authorization.SingleAppClient.SetSettings",
  UpdateCompanySettings = "Authorization.SingleAppClient.UpdateCompanySettings",
  UpdateEmployeeSettings = "Authorization.SingleAppClient.UpdateEmployeeSettings",
  SetSettingsStatus = "Authorization.SingleAppClient.SetSettingsStatus",
  ResetApp = "RESET_APP",
  ClearSettings = "Authorization.SingleAppClient.ClearSettings"
}

interface ResetApp {
  type: singleAppClienActions.ResetApp;
}

interface SetStatus {
  type: singleAppClienActions.SetStatus;
  clientStatus: string;
}
const setStatus = (clientStatus: string) => ({
  type: singleAppClienActions.SetStatus,
  clientStatus
});

interface SetClient {
  type: singleAppClienActions.SetClient;
  client: AppClient;
}
const setClient = (client: AppClient) => ({
  type: singleAppClienActions.SetClient,
  client
});

interface ToggleConsentModal {
  type: singleAppClienActions.ToggleConsentModal;
  consentModalVisible: boolean;
}
const toggleConsentModal = (consentModalVisible: boolean) => ({
  type: singleAppClienActions.ToggleConsentModal,
  consentModalVisible
});

interface SetReviewsStatus {
  type: singleAppClienActions.SetReviewsStatus;
  reviewsStatus: string;
}
const setReviewsStatus = (reviewsStatus: string) => ({
  type: singleAppClienActions.SetReviewsStatus,
  reviewsStatus
});

interface SetReviews {
  type: singleAppClienActions.SetReviews;
  reviews: ReviewSnapShot[];
}
const setReviews = (reviews: ReviewSnapShot[]) => ({
  type: singleAppClienActions.SetReviews,
  reviews
});

interface SetConsentStatus {
  type: singleAppClienActions.SetConsentStatus;
  consentStatus: string;
}
const setConsentStatus = (consentStatus: string) => ({
  type: singleAppClienActions.SetConsentStatus,
  consentStatus
});

interface ToggleConsentRetractConfirm {
  type: singleAppClienActions.ToggleConsentRetractConfirm;
  retractConsentConfirm: boolean;
}
const toggleConsentRetractConfirm = (retractConsentConfirm: boolean) => ({
  type: singleAppClienActions.ToggleConsentRetractConfirm,
  retractConsentConfirm
});

interface SetRecommendations {
  type: singleAppClienActions.SetRecommendations;
  recommendations: AppClientSnapShot[];
}
const setRecommendations = (recommendations: AppClientSnapShot[]) => ({
  type: singleAppClienActions.SetRecommendations,
  recommendations
});

interface SetSettings {
  type: singleAppClienActions.SetSettings;
  employeeSettings: EmployeeSettingsSingleItemResponse;
  companySettings: CompanySettingsSingleItemResponse;
}
const setSettings = (
  employeeSettings: EmployeeSettingsSingleItemResponse,
  companySettings: CompanySettingsSingleItemResponse
) => ({
  type: singleAppClienActions.SetSettings,
  employeeSettings,
  companySettings
});

interface UpdateCompanySettings {
  type: singleAppClienActions.UpdateCompanySettings;
  companySettings: CompanySettingsSingleItemResponse;
}
const updateCompanySettings = (
  companySettings: CompanySettingsSingleItemResponse
) => ({
  type: singleAppClienActions.UpdateCompanySettings,
  companySettings
});

interface UpdateEmployeeSettings {
  type: singleAppClienActions.UpdateEmployeeSettings;
  employeeSettings: EmployeeSettingsSingleItemResponse;
}
const updateEmployeeSettings = (
  employeeSettings: EmployeeSettingsSingleItemResponse
) => ({
  type: singleAppClienActions.UpdateEmployeeSettings,
  employeeSettings
});

interface SetSettingsStatus {
  type: singleAppClienActions.SetSettingsStatus;
  settingsStatus: string;
}
const setSettingsStatus = (settingsStatus: string) => ({
  type: singleAppClienActions.SetSettingsStatus,
  settingsStatus
});

interface ClearSettings {
  type: singleAppClienActions.ClearSettings;
}
const clearSettings = () => ({
  type: singleAppClienActions.ClearSettings
});

export type SingleAppClientActions =
  | SetStatus
  | SetClient
  | ToggleConsentModal
  | SetReviewsStatus
  | SetReviews
  | SetConsentStatus
  | ToggleConsentRetractConfirm
  | SetRecommendations
  | SetSettings
  | UpdateCompanySettings
  | UpdateEmployeeSettings
  | SetSettingsStatus
  | ResetApp
  | ClearSettings;
export const Actions = {
  setStatus,
  setClient,
  toggleConsentModal,
  setReviewsStatus,
  setReviews,
  setConsentStatus,
  toggleConsentRetractConfirm,
  setRecommendations,
  setSettings,
  updateCompanySettings,
  updateEmployeeSettings,
  setSettingsStatus,
  clearSettings
};
