import { TemplateDefinitionSnapShot } from "@haywork/api/kolibri";
import { EmailTemplatesThunks, MapDispatchToProps } from "@haywork/middleware";
import { AppState } from "@haywork/stores";
import { push, RouterAction } from "connected-react-router";
import { connect, MapStateToProps } from "react-redux";
import {
  EmailTemplatesComponent,
  EmailTemplatesComponentProps
} from "./email-templates.component";
import { emailTemplates } from "./selectors";

interface StateProps {
  emailTemplates: TemplateDefinitionSnapShot[];
}
interface DispatchProps {
  navigate: (path: string) => RouterAction;
}

const mapStateToProps: MapStateToProps<
  StateProps,
  EmailTemplatesComponentProps,
  AppState
> = (state) => {
  return {
    emailTemplates: emailTemplates(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  EmailTemplatesComponentProps
> = (dispatch) => ({
  navigate: (path: string) => dispatch(push(path))
});

export type EmailTemplatesContainerProps = StateProps & DispatchProps;
export const EmailTemplatesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplatesComponent);
