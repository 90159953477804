import {
  AssignmentSnapShot,
  AssignmentsStatistics,
  SortOrder,
  AssignmentOrderByField
} from "@haywork/api/kolibri";
import { FilterConfig } from "@haywork/components/ui/list";

export enum ActionType {
  UpdateList = "Assignments.List.UpdateList",
  SetOrdering = "Assignments.List.SetOrdering",
  SetFilters = "Assignments.List.SetFilters",
  SetScrollOffset = "Assignments.List.SetScrollOffset",
  UpdateItem = "Assignments.List.UpdateItem",
  ResetApp = "RESET_APP"
}

type UpdateList = {
  type: ActionType.UpdateList;
  startIndex: number;
  stopIndex: number;
  items: AssignmentSnapShot[];
  totalCount: number;
  statistics?: AssignmentsStatistics | null;
};

const updateList = (
  startIndex: number,
  items: AssignmentSnapShot[],
  totalCount: number,
  statistics: AssignmentsStatistics | null | undefined
) => ({
  type: ActionType.UpdateList,
  startIndex,
  items,
  totalCount,
  statistics
});

type SetOrdering = {
  type: ActionType.SetOrdering;
  sortOrder: SortOrder;
  sortColumn: AssignmentOrderByField;
};

const setOrdering = (
  sortOrder: SortOrder,
  sortColumn: AssignmentOrderByField
) => ({
  type: ActionType.SetOrdering,
  sortOrder,
  sortColumn
});

type SetFilters = {
  type: ActionType.SetFilters;
  filters: FilterConfig;
};

const setFilters = (filters: FilterConfig) => ({
  type: ActionType.SetFilters,
  filters
});

type SetScrollOffset = {
  type: ActionType.SetScrollOffset;
  scrollOffset: number;
};

const setScrollOffset = (scrollOffset: number) => ({
  type: ActionType.SetScrollOffset,
  scrollOffset
});

type UpdateItem = {
  type: ActionType.UpdateItem;
  snapshot: AssignmentSnapShot;
};

const updateItem = (snapshot: AssignmentSnapShot) => ({
  type: ActionType.UpdateItem,
  snapshot
});

type ResetApp = {
  type: ActionType.ResetApp;
};

export type ActionTypes =
  | UpdateList
  | SetOrdering
  | SetFilters
  | SetScrollOffset
  | UpdateItem
  | ResetApp;
export const actions = {
  updateList,
  setOrdering,
  setFilters,
  setScrollOffset,
  updateItem
};
