import * as React from "react";
import { FC, memo, useState, useCallback, useEffect } from "react";
import { ConfirmComponent } from "@haywork/modules/shared";
import { RootEntityType } from "@haywork/api/event-center";
import { ConfirmOverwriteContainerProps } from "./confirm-overwrite.container";

export type ConfirmOverwriteComponentProps = {
  entityId: string;
  entityType: RootEntityType;
  saving: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
type Props = ConfirmOverwriteComponentProps & ConfirmOverwriteContainerProps;

export const ConfirmOverwriteComponent: FC<Props> = memo(
  ({ onConfirm, onCancel, saving, externalChangesData }) => {
    const [confirmVisible, setConfirmVisible] = useState(false);

    const confirm = useCallback(() => {
      setConfirmVisible(false);
      onConfirm();
    }, [onConfirm, setConfirmVisible]);

    const cancel = useCallback(() => {
      setConfirmVisible(false);
      onCancel();
    }, [onCancel, setConfirmVisible]);

    const handleSaveCall = useCallback(() => {
      if (!saving) return;
      if (!externalChangesData) {
        confirm();
        return;
      }
      setConfirmVisible(true);
    }, [saving, externalChangesData, confirm, setConfirmVisible]);

    useEffect(() => {
      handleSaveCall();
    }, [saving]);

    if (!externalChangesData) return null;

    return (
      <>
        <ConfirmComponent
          visible={confirmVisible}
          titleResourceKey="confirmOverwrite.title"
          bodyResourceKey="confirmOverwrite.body"
          bodyValues={{ displayName: externalChangesData.modifiedBy }}
          onClose={cancel}
          onConfirm={confirm}
        />
      </>
    );
  }
);
