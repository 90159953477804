import { ProjectAssignment, PublicationSnapShot } from "@haywork/api/kolibri";
import { Dispatch, ProjectsThunks } from "@haywork/middleware";
import { ProjectEditMarketingPartnersComponent } from "@haywork/modules/project/components/marketing-partners/marketing-partners.component";
import { AppState } from "@haywork/stores";
import { connect } from "react-redux";
import { publications } from "./selectors";

export interface ProjectEditMarketingPartnersContainerProps {
  publications: PublicationSnapShot[];
  projectAssignment: ProjectAssignment;
  mediaPartnerState: string;
  mediaPartnerChangedId: string;

  activatePublication: (assignmentId: string, mediaPartnerId: string) => void;
  deActivatePublication: (assignmentId: string, mediaPartnerId: string) => void;
}

const mapStateToProps = (state: AppState) => ({
  publications: publications(state),
  projectAssignment: state.editable.currentComponentState.projectAssignment,
  mediaPartnerState: state.project.single.mediaPartnerState,
  mediaPartnerChangedId: state.project.single.mediaPartnerChangedId
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  activatePublication: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(
      ProjectsThunks.Projects.activatePublication(assignmentId, mediaPartnerId)
    ),
  deActivatePublication: (assignmentId: string, mediaPartnerId: string) =>
    dispatch(
      ProjectsThunks.Projects.deActivatePublication(
        assignmentId,
        mediaPartnerId
      )
    )
});
export const ProjectEditMarketingPartnersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditMarketingPartnersComponent);
