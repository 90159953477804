import {
  WidgetEntityLocation,
  WidgetEntityType,
} from "@haywork/api/authorization";
import { ContactLinkSnapShot, LinkedRelation } from "@haywork/api/kolibri";
import { FeatureSwitch } from "@haywork/modules/feature-switch";
import {
  AuthorizationWidgets,
  MailTo,
  ResourceText,
} from "@haywork/modules/shared";
import classNames from "classnames";
import * as React from "react";
import * as CSSModules from "react-css-modules";
import { intlContext } from "@haywork/app";

interface Props {
  contact: ContactLinkSnapShot;
  relationId: string;
  preppedForRemove: boolean;
  onLinkTypeChange: (
    sourceRelationId: string,
    targetRelationId: string,
    linkType: string
  ) => void;
}
interface State {
  linkType: string;
}

const styles = require("./link-item.component.scss");

@CSSModules(styles, { allowMultiple: true })
export class CompanyLinkItemComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      linkType: this.props.contact.linkType,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.renderAddressLine = this.renderAddressLine.bind(this);
    this.renderContactLine = this.renderContactLine.bind(this);
  }

  public render() {
    const { sourceRelation, targetRelation } = this.props.contact;
    const person =
      sourceRelation.id === this.props.relationId
        ? targetRelation
        : sourceRelation;
    const avatarStyles = !!person.avatarUrl
      ? { backgroundImage: `url(${JSON.stringify(person.avatarUrl)})` }
      : null;
    const contactLinkStyle = classNames("contact-link", {
      "prepped-for-remove": this.props.preppedForRemove,
    });

    return (
      <div styleName={contactLinkStyle}>
        <div styleName="item__avatar">
          <div styleName="avatar" style={avatarStyles}>
            {!avatarStyles && (
              <div styleName="letters">{person.letterAvatar}</div>
            )}
          </div>
        </div>
        <div styleName="item__body">
          <ResourceText
            resourceKey="aroWorkingAt"
            values={{
              companyName: targetRelation.displayName,
            }}
            asHtml
          />
          <input
            type="text"
            value={this.state.linkType}
            onChange={this.onChangeHandler}
            onBlur={this.onBlurHandler}
            onClick={this.onClickHander}
            styleName="link-type"
            placeholder={intlContext.formatMessage({
              id: "aroFunctionPlaceholder",
              defaultMessage: "aroFunctionPlaceholder",
            })}
            data-lpignore="true"
          />
          <ResourceText
            resourceKey="aroWorkingAt2"
            values={{
              name: sourceRelation.displayName,
            }}
            asHtml
          />
        </div>

        {/* Authorization widgets */}
        <FeatureSwitch feature="APP_XCHANGE">
          <AuthorizationWidgets
            type={WidgetEntityType.ContactCompany}
            location={WidgetEntityLocation.Network}
            id={this.props.relationId}
          />
        </FeatureSwitch>

        {this.renderContactLine(person)}
        {this.renderAddressLine(person)}
      </div>
    );
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ linkType: event.target.value });
  }

  private onClickHander(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
  }

  private onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
    const { sourceRelation, targetRelation } = this.props.contact;
    this.props.onLinkTypeChange(
      sourceRelation.id,
      targetRelation.id,
      this.state.linkType
    );
  }

  private renderAddressLine(
    person: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    /*if (!person.streetNameAndNumber && !person.locality) return null;

    return <div styleName="item__address">
      { person.streetNameAndNumber && <div styleName="streetname">{ person.streetNameAndNumber }</div> }
      { person.locality && <div styleName="locality">{ person.locality }</div> }
    </div>;*/
    return null;
  }

  private renderContactLine(
    person: LinkedRelation
  ): React.ReactElement<HTMLDivElement> {
    if (
      !person.phone &&
      !person.phoneMobile &&
      !person.phoneWork &&
      !person.email
    )
      return null;
    const phones = [person.phone, person.phoneMobile, person.phoneWork];
    const cleanedPhones = phones.filter((phone) => !!phone);

    return (
      <div styleName="item__contact">
        {cleanedPhones.length > 0 && (
          <div styleName="phone">{cleanedPhones[0]}</div>
        )}
        {person.email && (
          <div styleName="email">
            <MailTo email={person.email} />
          </div>
        )}
      </div>
    );
  }
}
