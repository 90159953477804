import {
  ExtensionInfo,
  FinancialAdministration,
  ReportTemplate,
} from "@haywork/api/kolibri";
import { Dispatch } from "@haywork/middleware";
import {
  InvoicePrintComponent,
  InvoicePrintComponentProps,
} from "@haywork/modules/invoice";
import { AppState } from "@haywork/stores";
import { FormControlUtil } from "@haywork/util";
import { connect } from "react-redux";

interface StateProps {
  realEstateAgencyId: string;
  token: string;
  apiVersion: string;
  host: string;
  printExportOptions: ExtensionInfo[];
  printTemplates: ReportTemplate[];
  urlLetterheadPaperFirstPage: string;
  urlLetterheadPaperFollowingPages: string;
  useLetterHead: boolean;
  financialAdministration: FinancialAdministration;
}
interface DispatchProps {}

const value = FormControlUtil.returnObjectPathOrNull;

const mapStateToProps = <StateProps, InvoicePrintComponentProps>(
  state: AppState
) => {
  const { id: realEstateAgencyId } = state.account.currentRealestateAgency;
  const { token } = state.access;
  const { reportsApiVersion: apiVersion, reportsHost: host } =
    state.appSettings;
  const { printExportOptions, printTemplates, financialAdministration } =
    state.invoice.single;

  return {
    realEstateAgencyId,
    token,
    apiVersion,
    host,
    printExportOptions,
    printTemplates,
    financialAdministration,
    urlLetterheadPaperFirstPage: value(
      financialAdministration,
      "backGroundImageFirstPage.urlOriginal",
      null
    ),
    urlLetterheadPaperFollowingPages: value(
      financialAdministration,
      "backGroundImageFollowingPages.urlOriginal",
      null
    ),
    useLetterHead: value(
      financialAdministration,
      "useBackGroundImagesByDefault",
      false
    ),
  };
};

const mapDispatchToProps = <DispatchProps, InvoicePrintComponentProps>(
  dispatch: Dispatch<any>
) => ({});

export type InvoicePrintContainerProps = StateProps & DispatchProps;
export const InvoicePrintContainer = connect<
  StateProps,
  DispatchProps,
  InvoicePrintComponentProps
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoicePrintComponent);
