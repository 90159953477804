import * as React from "react";
import * as CSSModules from "react-css-modules";
import classNames from "classnames";
import { FormattedNumber } from "react-intl";

import { AssignmentSnapShot } from "@haywork/api/kolibri";
import { StringUtil } from "@haywork/util";
import { ResourceText, FontStyle } from "@haywork/modules/shared";

interface OfficeAssignmentListItemComponentProps {
  assignment: AssignmentSnapShot;
  officeId: string;
  zebra: boolean;
  onAssignmentClick?: (assignment: AssignmentSnapShot) => void;
}
interface OfficeAssignmentListItemComponentState {}

const styles = require("./office-assignment-list-item.component.scss");
const enumToString = StringUtil.enumToString;

@CSSModules(styles, { allowMultiple: true })
export class OfficeAssignmentListItemComponent extends React.Component<
  OfficeAssignmentListItemComponentProps,
  OfficeAssignmentListItemComponentState
> {
  public render() {
    const assignment = this.props.assignment;
    const listItemStyle = classNames("list-item", { zebra: this.props.zebra });
    const hasPreview = !!assignment.photo1Url && assignment.photo1Url !== "";
    const previewStyle = hasPreview
      ? { backgroundImage: `url(${JSON.stringify(assignment.photo1Url)})` }
      : null;
    const enumToString = StringUtil.enumToString;

    return (
      <div
        styleName={listItemStyle}
        onClick={() => this.props.onAssignmentClick(this.props.assignment)}
      >
        <div styleName="list-item__preview">
          <div styleName="preview" style={previewStyle}>
            {!hasPreview && <i className="fal fa-fw fa-camera-retro fa-2x" />}
          </div>
        </div>
        <div styleName="list-item__info">
          <div styleName="meta">
            <ResourceText
              masterKey="realEstateGroups"
              resourceKey={enumToString(assignment.realEstateGroup)}
            />
            ,{" "}
            {assignment.forSale ? (
              <ResourceText resourceKey="forSale" />
            ) : (
              <ResourceText resourceKey="forRent" />
            )}
          </div>
          <div styleName="title">{assignment.displayName}</div>
          <div styleName="type">
            <ResourceText
              masterKey="listingTypes"
              resourceKey={enumToString(assignment.listingType)}
            />
          </div>
        </div>
        <div styleName="list-item__meta">
          {assignment.forSale && (
            <div styleName="meta">
              <span styleName="label">
                <ResourceText resourceKey="askingPrice" />
              </span>
              {this.renderPrice(assignment.salePrice)}
            </div>
          )}
          {assignment.forRent && (
            <div styleName="meta">
              <span styleName="label">
                <ResourceText resourceKey="rentalPrice" />
              </span>
              {this.renderPrice(assignment.rentPrice)}
            </div>
          )}
          <div styleName="meta">
            <span styleName="label">
              <ResourceText resourceKey="courtage" />
            </span>
            {assignment.forSale &&
              this.renderCommision(assignment.saleCommissionTotal)}
            {assignment.forRent &&
              this.renderCommision(assignment.rentCommissionTotal)}
          </div>
        </div>
        <div styleName="list-item__status">
          <div styleName="type">
            <ResourceText
              masterKey="assignmentPhases"
              resourceKey={enumToString(assignment.assignmentPhase)}
            />
          </div>
          <div styleName="status">
            <ResourceText
              masterKey="availabilityStatuses"
              resourceKey={enumToString(assignment.availabilityStatus)}
            />
          </div>
        </div>
      </div>
    );
  }

  private renderPrice(price: number) {
    if (!!price && price > 0) {
      return (
        <div styleName="value">
          <FormattedNumber value={price} style="currency" currency="EUR" />
          {this.props.assignment.forSale &&
            !!this.props.assignment.saleCondition && (
              <span>
                &nbsp;
                <ResourceText
                  masterKey="saleConditions"
                  resourceKey={enumToString(
                    this.props.assignment.saleCondition
                  )}
                />
              </span>
            )}
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <ResourceText resourceKey="none" fontStyle={FontStyle.LowerCase} />
        </div>
      );
    }
  }

  private renderCommision(total: number) {
    if (!!total && total > 0) {
      return (
        <div styleName="value positive">
          <FormattedNumber value={total} style="currency" currency="EUR" />
        </div>
      );
    } else {
      return (
        <div styleName="value">
          <ResourceText resourceKey="none" fontStyle={FontStyle.LowerCase} />
        </div>
      );
    }
  }
}
