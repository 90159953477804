import * as React from "react";
import * as CSSModules from "react-css-modules";

import {
  Account,
  ProviderType,
  ReauthenticateAccountRequest,
} from "@haywork/api/mail";
import { Modal, ModalHeader, ModalBody } from "@haywork/modules/modal";
import { FormControlUtil } from "@haywork/util";
import { ReAuthGmailComponent } from "./re-auth-gmail.component";
import { ReAuthOffice365Component } from "./re-auth-office365.component";
import { ReAuthOtherComponent } from "./re-auth-other.component";
import { ReAuthImapComponent } from "./re-auth-imap.component";

const styles = require("./re-authenticate-modal.component.scss");
const value = FormControlUtil.returnObjectPathOrNull;

interface Props {
  visible: boolean;
  account: Account | null;
  onClose: () => void;
  onReAuthenticate: (request: ReauthenticateAccountRequest) => Promise<void>;
}
interface State {}

@CSSModules(styles, { allowMultiple: true })
export class EmailReAuthenticateModalComponent extends React.Component<
  Props,
  State
> {
  public render() {
    const email = value(this.props.account, "emailAddress");
    const type = value(this.props.account, "type");

    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        <ModalHeader
          title="emailSyncModalTitle"
          titleValues={{ email }}
          close
        />

        <ModalBody noPadding>{this.renderBody()}</ModalBody>
      </Modal>
    );
  }

  private renderBody() {
    const type = value(this.props.account, "type");

    if (!type) return null;

    switch (type) {
      case ProviderType.Gmail:
        return (
          <ReAuthGmailComponent
            account={this.props.account}
            onClose={this.props.onClose}
            onReAuthenticate={this.props.onReAuthenticate}
          />
        );
      case ProviderType.Office365:
        return (
          <ReAuthOffice365Component
            account={this.props.account}
            onClose={this.props.onClose}
            onReAuthenticate={this.props.onReAuthenticate}
          />
        );
      case ProviderType.Imap:
        return (
          <ReAuthImapComponent
            account={this.props.account}
            onClose={this.props.onClose}
            onReAuthenticate={this.props.onReAuthenticate}
          />
        );
      default:
        return (
          <ReAuthOtherComponent
            account={this.props.account}
            onClose={this.props.onClose}
            onReAuthenticate={this.props.onReAuthenticate}
          />
        );
    }
  }
}
