import { createSelector } from "reselect";
import sortBy from "lodash-es/sortBy";

import { AppState } from "@haywork/stores";
import {
  PERSONAL_EVENT_TYPES,
  GENERAL_EVENT_TYPES,
  MLS_EVENT_TYPES,
} from "@haywork/constants";

const notificationSettingsSelector = (state: AppState) =>
  state.eventCenter.settings.notificationSettings;
const notificationSettingsEmployees = (state: AppState) =>
  state.account.employees;
const notificationSettingsEmployee = (state: AppState) =>
  state.account.employee;

export const editableNotificationSettings = createSelector(
  notificationSettingsSelector,
  (notifications) => {
    return sortBy(
      notifications.filter(
        (notification) =>
          PERSONAL_EVENT_TYPES.indexOf(notification.eventType) !== -1
      ),
      "eventType"
    );
  }
);

export const editableMlsNotificationSettings = createSelector(
  notificationSettingsSelector,
  (notifications) => {
    return sortBy(
      notifications.filter(
        (notification) => MLS_EVENT_TYPES.indexOf(notification.eventType) !== -1
      ),
      "eventType"
    );
  }
);

export const bulkNotificationSettings = createSelector(
  notificationSettingsSelector,
  (notifications) => {
    return sortBy(
      notifications.filter(
        (notification) =>
          GENERAL_EVENT_TYPES.indexOf(notification.eventType) !== -1
      ),
      "eventType"
    );
  }
);

export const employees = createSelector(
  [notificationSettingsEmployees, notificationSettingsEmployee],
  (employees, self) => {
    return employees.filter((employee) => employee.id !== self.id);
  }
);
